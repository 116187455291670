import { useEffect } from 'react';

const useMetaTags = (metaData: any) => {
  const setMetaTag = (name: any, content: any) => {
    let tag = document.head.querySelector(`meta[name="${name}"]`);
    if (!tag) {
      tag = document.createElement('meta');
      tag.setAttribute('name', name);
      document.head.appendChild(tag);
    }
    tag.setAttribute('content', content);
  };

  useEffect(() => {
    Object.keys(metaData).forEach((key) => {
      setMetaTag(key, metaData[key]);
    });

    return () => {
      // Clean up meta tags on component unmount
      Object.keys(metaData).forEach((key) => {
        const tag = document.head.querySelector(`meta[name="${key}"]`);
        if (tag) {
          document.head.removeChild(tag);
        }
      });
    };
  }, [metaData]);
};

export default useMetaTags;