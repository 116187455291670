/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserConversation = /* GraphQL */ `mutation CreateUserConversation(
  $input: CreateUserConversationInput!
  $condition: ModelUserConversationConditionInput
) {
  createUserConversation(input: $input, condition: $condition) {
    id
    lastReadAt
    _version
    conversation {
      id
      createdAt
      userIds
      lastMessageCreatedAt
      lastMessageText
      lastMessageFileUrl
      lastMessageFileMimeType
      lastMessageUserId
      _version
      lastMessageFileType
      lastMessageFileUser
      updatedAt
      __typename
    }
    userId
    createdAt
    updatedAt
    userConversationConversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserConversationMutationVariables,
  APITypes.CreateUserConversationMutation
>;
export const updateUserConversation = /* GraphQL */ `mutation UpdateUserConversation(
  $input: UpdateUserConversationInput!
  $condition: ModelUserConversationConditionInput
) {
  updateUserConversation(input: $input, condition: $condition) {
    id
    lastReadAt
    _version
    conversation {
      id
      createdAt
      userIds
      lastMessageCreatedAt
      lastMessageText
      lastMessageFileUrl
      lastMessageFileMimeType
      lastMessageUserId
      _version
      lastMessageFileType
      lastMessageFileUser
      updatedAt
      __typename
    }
    userId
    createdAt
    updatedAt
    userConversationConversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserConversationMutationVariables,
  APITypes.UpdateUserConversationMutation
>;
export const deleteUserConversation = /* GraphQL */ `mutation DeleteUserConversation(
  $input: DeleteUserConversationInput!
  $condition: ModelUserConversationConditionInput
) {
  deleteUserConversation(input: $input, condition: $condition) {
    id
    lastReadAt
    _version
    conversation {
      id
      createdAt
      userIds
      lastMessageCreatedAt
      lastMessageText
      lastMessageFileUrl
      lastMessageFileMimeType
      lastMessageUserId
      _version
      lastMessageFileType
      lastMessageFileUser
      updatedAt
      __typename
    }
    userId
    createdAt
    updatedAt
    userConversationConversationId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserConversationMutationVariables,
  APITypes.DeleteUserConversationMutation
>;
export const createConversation = /* GraphQL */ `mutation CreateConversation(
  $input: CreateConversationInput!
  $condition: ModelConversationConditionInput
) {
  createConversation(input: $input, condition: $condition) {
    id
    createdAt
    userIds
    lastMessageCreatedAt
    lastMessageText
    lastMessageFileUrl
    lastMessageFileMimeType
    lastMessageUserId
    _version
    messages {
      nextToken
      __typename
    }
    lastMessageFileType
    lastMessageFileUser
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConversationMutationVariables,
  APITypes.CreateConversationMutation
>;
export const updateConversation = /* GraphQL */ `mutation UpdateConversation(
  $input: UpdateConversationInput!
  $condition: ModelConversationConditionInput
) {
  updateConversation(input: $input, condition: $condition) {
    id
    createdAt
    userIds
    lastMessageCreatedAt
    lastMessageText
    lastMessageFileUrl
    lastMessageFileMimeType
    lastMessageUserId
    _version
    messages {
      nextToken
      __typename
    }
    lastMessageFileType
    lastMessageFileUser
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConversationMutationVariables,
  APITypes.UpdateConversationMutation
>;
export const deleteConversation = /* GraphQL */ `mutation DeleteConversation(
  $input: DeleteConversationInput!
  $condition: ModelConversationConditionInput
) {
  deleteConversation(input: $input, condition: $condition) {
    id
    createdAt
    userIds
    lastMessageCreatedAt
    lastMessageText
    lastMessageFileUrl
    lastMessageFileMimeType
    lastMessageUserId
    _version
    messages {
      nextToken
      __typename
    }
    lastMessageFileType
    lastMessageFileUser
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConversationMutationVariables,
  APITypes.DeleteConversationMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    conversationId
    text
    createdAt
    userId
    fileUrl
    fileMimeType
    file {
      id
      bucket
      region
      key
      mimeType
      type
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    messageFileId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    conversationId
    text
    createdAt
    userId
    fileUrl
    fileMimeType
    file {
      id
      bucket
      region
      key
      mimeType
      type
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    messageFileId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    conversationId
    text
    createdAt
    userId
    fileUrl
    fileMimeType
    file {
      id
      bucket
      region
      key
      mimeType
      type
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    messageFileId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createS3Object = /* GraphQL */ `mutation CreateS3Object(
  $input: CreateS3ObjectInput!
  $condition: ModelS3ObjectConditionInput
) {
  createS3Object(input: $input, condition: $condition) {
    id
    bucket
    region
    key
    mimeType
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateS3ObjectMutationVariables,
  APITypes.CreateS3ObjectMutation
>;
export const updateS3Object = /* GraphQL */ `mutation UpdateS3Object(
  $input: UpdateS3ObjectInput!
  $condition: ModelS3ObjectConditionInput
) {
  updateS3Object(input: $input, condition: $condition) {
    id
    bucket
    region
    key
    mimeType
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateS3ObjectMutationVariables,
  APITypes.UpdateS3ObjectMutation
>;
export const deleteS3Object = /* GraphQL */ `mutation DeleteS3Object(
  $input: DeleteS3ObjectInput!
  $condition: ModelS3ObjectConditionInput
) {
  deleteS3Object(input: $input, condition: $condition) {
    id
    bucket
    region
    key
    mimeType
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteS3ObjectMutationVariables,
  APITypes.DeleteS3ObjectMutation
>;
