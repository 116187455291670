export interface Position {
  id: number;
  sport: string;
  category: string;
  name: string;
  symbol: string;
}
const positions: Position[] = [
  {
    id: 21,
    sport: 'football',
    category: '',
    name: 'Athlete',
    symbol: 'ATH',
  },
  {
    id: 0,
    sport: 'football',
    category: 'offense',
    name: 'Center',
    symbol: 'C',
  },
  {
    id: 11,
    sport: 'football',
    category: 'defense',
    name: 'Cornerback',
    symbol: 'CB',
  },
  {
    id: 8,
    sport: 'football',
    category: 'defense',
    name: 'Defensive end',
    symbol: 'DE',
  },
  {
    id: 7,
    sport: 'football',
    category: 'defense',
    name: 'Defensive tackle',
    symbol: 'DT',
  },
  {
    id: 13,
    sport: 'football',
    category: 'defense',
    name: 'Dimeback',
    symbol: 'DB',
  },
  {
    id: 22,
    sport: 'football',
    category: 'offense',
    name: 'Fullback',
    symbol: 'FB',
  },
  {
    id: 23,
    sport: 'football',
    category: 'offense',
    name: 'Halfback',
    symbol: 'HB',
  },
  {
    id: 17,
    sport: 'football',
    category: 'special teams',
    name: 'Holder',
    symbol: 'H',
  },
  {
    id: 19,
    sport: 'football',
    category: 'special teams',
    name: 'Kick returner',
    symbol: 'KR',
  },
  {
    id: 14,
    sport: 'football',
    category: 'special teams',
    name: 'Kicker',
    symbol: 'K',
  },
  {
    id: 15,
    sport: 'football',
    category: 'special teams',
    name: 'Kickoff specialist',
    symbol: 'KOS',
  },
  {
    id: 24,
    sport: 'football',
    category: 'defense',
    name: 'Linebacker',
    symbol: 'LB',
  },
  {
    id: 18,
    sport: 'football',
    category: 'special teams',
    name: 'Long snapper',
    symbol: 'LS',
  },
  {
    id: 9,
    sport: 'football',
    category: 'defense',
    name: 'Middle linebacker',
    symbol: 'MLB',
  },
  {
    id: 12,
    sport: 'football',
    category: 'defense',
    name: 'Nickelback',
    symbol: 'NB',
  },
  {
    id: 1,
    sport: 'football',
    category: 'offense',
    name: 'Offensive guard',
    symbol: 'OG',
  },
  {
    id: 2,
    sport: 'football',
    category: 'offense',
    name: 'Offensive tackle',
    symbol: 'OT',
  },
  {
    id: 10,
    sport: 'football',
    category: 'defense',
    name: 'Outside linebacker',
    symbol: 'OLB',
  },
  {
    id: 20,
    sport: 'football',
    category: 'special teams',
    name: 'Punt returner',
    symbol: 'PR',
  },
  {
    id: 16,
    sport: 'football',
    category: 'special teams',
    name: 'Punter',
    symbol: 'P',
  },
  {
    id: 3,
    sport: 'football',
    category: 'offense',
    name: 'Quarterback',
    symbol: 'QB',
  },
  {
    id: 25,
    sport: 'football',
    category: 'offense',
    name: 'Running Back',
    symbol: 'RB',
  },
  {
    id: 24,
    sport: 'football',
    category: 'defense',
    name: 'Safety',
    symbol: 'S',
  },
  {
    id: 6,
    sport: 'football',
    category: 'offense',
    name: 'Tight end',
    symbol: 'TE',
  },
  {
    id: 5,
    sport: 'football',
    category: 'offense',
    name: 'Wide receiver',
    symbol: 'WR',
  },
  { id: 30, sport: 'basketball', category: '', name: 'Center', symbol: 'C' },
  {
    id: 26,
    sport: 'basketball',
    category: '',
    name: 'Point guard',
    symbol: 'PG',
  },
  {
    id: 29,
    sport: 'basketball',
    category: '',
    name: 'Power forward',
    symbol: 'PF',
  },
  {
    id: 27,
    sport: 'basketball',
    category: '',
    name: 'Shooting guard',
    symbol: 'SG',
  },
  {
    id: 28,
    sport: 'basketball',
    category: '',
    name: 'Small forward',
    symbol: 'SF',
  },
  {
    id: 39,
    sport: 'baseball',
    category: 'fielding',
    name: 'Catcher',
    symbol: 'C',
  },
  {
    id: 32,
    sport: 'baseball',
    category: 'fielding',
    name: 'Center Fielder',
    symbol: 'CF',
  },
  {
    id: 74,
    sport: 'baseball',
    category: 'hitting',
    name: 'Designated Hitter',
    symbol: 'DH',
  },
  {
    id: 38,
    sport: 'baseball',
    category: 'fielding',
    name: 'First Baseman',
    symbol: '1B',
  },
  {
    id: 72,
    sport: 'baseball',
    category: 'fielding',
    name: 'Infielder',
    symbol: 'IF',
  },
  {
    id: 31,
    sport: 'baseball',
    category: 'fielding',
    name: 'Left Fielder',
    symbol: 'LF',
  },
  {
    id: 73,
    sport: 'baseball',
    category: 'fielding',
    name: 'Outfielder',
    symbol: 'OF',
  },
  {
    id: 37,
    sport: 'baseball',
    category: 'fielding',
    name: 'Pitcher',
    symbol: 'P',
  },
  {
    id: 33,
    sport: 'baseball',
    category: 'fielding',
    name: 'Right Fielder',
    symbol: 'RF',
  },
  {
    id: 35,
    sport: 'baseball',
    category: 'fielding',
    name: 'Second Baseman',
    symbol: '2B',
  },
  {
    id: 34,
    sport: 'baseball',
    category: 'fielding',
    name: 'Short Stop',
    symbol: 'SS',
  },
  {
    id: 36,
    sport: 'baseball',
    category: 'fielding',
    name: 'Third Baseman',
    symbol: '3B',
  },
  {
    id: 75,
    sport: 'soccer',
    category: '',
    name: 'Attacking Midfielder',
    symbol: 'AM',
  },
  {
    id: 43,
    sport: 'soccer',
    category: '',
    name: 'Center Back',
    symbol: 'CB',
  },
  {
    id: 79,
    sport: 'soccer',
    category: '',
    name: 'Center Forward',
    symbol: 'CF',
  },
  {
    id: 47,
    sport: 'soccer',
    category: '',
    name: 'Center Midfielder',
    symbol: 'CM',
  },
  {
    id: 45,
    sport: 'soccer',
    category: '',
    name: 'Defensive Center Midfielder',
    symbol: 'DCM',
  },
  {
    id: 40,
    sport: 'soccer',
    category: '',
    name: 'Goalkeeper',
    symbol: 'G',
  },
  {
    id: 77,
    sport: 'soccer',
    category: '',
    name: 'Left Forward',
    symbol: 'LF',
  },
  {
    id: 42,
    sport: 'soccer',
    category: '',
    name: 'Left Fullback',
    symbol: 'LF',
  },
  {
    id: 76,
    sport: 'soccer',
    category: '',
    name: 'Left Midfielder/Winger',
    symbol: 'LM',
  },
  {
    id: 78,
    sport: 'soccer',
    category: '',
    name: 'Right Forward',
    symbol: 'RF',
  },
  {
    id: 41,
    sport: 'soccer',
    category: '',
    name: 'Right Fullback',
    symbol: 'RF',
  },
  {
    id: 46,
    sport: 'soccer',
    category: '',
    name: 'Right Midfielder/Winger',
    symbol: 'RM',
  },
  {
    id: 80,
    sport: 'soccer',
    category: '',
    name: 'Striker',
    symbol: 'ST',
  },
  {
    id: 44,
    sport: 'soccer',
    category: '',
    name: 'Sweeper',
    symbol: 'SW',
  },
  { id: 48, sport: 'lacrosse', category: '', name: 'Attack', symbol: 'A' },
  { id: 49, sport: 'lacrosse', category: '', name: 'Defense', symbol: 'D' },
  { id: 50, sport: 'lacrosse', category: '', name: 'Face-Off', symbol: 'FO' },
  {
    id: 51,
    sport: 'lacrosse',
    category: '',
    name: 'Goalie',
    symbol: 'G',
  },
  {
    id: 52,
    sport: 'lacrosse',
    category: '',
    name: 'Long Stick Midfielder',
    symbol: 'LSM',
  },
  { id: 53, sport: 'lacrosse', category: '', name: 'Midfield', symbol: 'M' },
  {
    id: 54,
    sport: 'softball',
    category: 'fielding',
    name: 'Pitcher',
    symbol: 'P',
  },
  {
    id: 55,
    sport: 'softball',
    category: 'fielding',
    name: 'Catcher',
    symbol: 'C',
  },
  {
    id: 56,
    sport: 'softball',
    category: 'fielding',
    name: 'First Baseman',
    symbol: '1B',
  },
  {
    id: 57,
    sport: 'softball',
    category: 'fielding',
    name: 'Second Baseman',
    symbol: '2B',
  },
  {
    id: 58,
    sport: 'softball',
    category: 'fielding',
    name: 'Shortstop',
    symbol: 'SS',
  },
  {
    id: 59,
    sport: 'softball',
    category: 'fielding',
    name: 'Third Baseman',
    symbol: '3B',
  },
  {
    id: 60,
    sport: 'softball',
    category: 'fielding',
    name: 'Left Fielder',
    symbol: 'LF',
  },
  {
    id: 61,
    sport: 'softball',
    category: 'fielding',
    name: 'Center Fielder',
    symbol: 'CF',
  },
  {
    id: 62,
    sport: 'softball',
    category: 'fielding',
    name: 'Right Fielder',
    symbol: 'RF',
  },
  {
    id: 63,
    sport: 'volleyball',
    category: '',
    name: 'Defensive Specialist',
    symbol: 'DS',
  },
  { id: 64, sport: 'volleyball', category: '', name: 'Libero', symbol: 'L' },
  {
    id: 65,
    sport: 'volleyball',
    category: '',
    name: 'Middle Blocker',
    symbol: 'MB',
  },
  {
    id: 66,
    sport: 'volleyball',
    category: '',
    name: 'Middle Hitter',
    symbol: 'MH',
  },
  {
    id: 67,
    sport: 'volleyball',
    category: '',
    name: 'Opposite',
    symbol: 'O',
  },
  {
    id: 68,
    sport: 'volleyball',
    category: '',
    name: 'Outside Hitter',
    symbol: 'OH',
  },
  {
    id: 69,
    sport: 'volleyball',
    category: '',
    name: 'Rightside Hitter',
    symbol: 'RH',
  },
  {
    id: 70,
    sport: 'volleyball',
    category: '',
    name: 'Serving Specialist',
    symbol: 'SS',
  },
  { id: 71, sport: 'volleyball', category: '', name: 'Setter', symbol: 'S' },
];

export const getPositions = () => positions;
export const getSportPosition = (sport: string, symbol: string) => {
  return positions.find(p => p.sport === sport && p.symbol === symbol)?.name ?? symbol;
};
