import { IonPage, IonButton, IonContent } from '@ionic/react';
import './URP.css';
import Login from './Login';
import { isPlatform } from '@ionic/react';

const Logo: React.FC = () => {
  const btnColor = '#00816D';
  const isMobileWeb = isPlatform('mobileweb');

  return (
    <IonContent id="register-page" className="logo-page">
      {!isMobileWeb && (
        <div className="logo-page-login-container">
          <Login></Login>
        </div>
      )}
      <div className="logo-page-explore-container">
        <div className="logo-page-explore">
          <a href="mailto:info@urpplus.com">
            <img src="/assets/images/urp_logo_large.png" alt="URP+" style={{ width: '300px' }} />
          </a>
          <div
            style={{
              maxWidth: '300px',
              color: '#ffffff',
              textAlign: 'justify',
              lineHeight: '25px',
              textDecoration: 'none',
            }}
          >
            <p style={{ marginTop: 0 }}>
              URP+, Ultimate Recruiting Profile is your sports-centered professional social network.
              Athletes of all ages can create an all encompassing recruiting focused profile to
              network and connect amongst coaches and trainers who are also able to create profiles!
              Sign up, promote yourself and join us to Discover Who's Next! Download our app today,
              sign up and create a profile, and start building up the future of sports networking!
            </p>
          </div>
          <div className="app-download-buttons">
            <a
              href="https://apps.apple.com/us/app/urp/id1631130459"
              className="app-download-button-ios"
            >
              <img
                className="app-download-button-ios"
                src="assets/images/ios-download-button.png"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.urp.urp"
              className="app-download-button-android"
            >
              <img
                className="app-download-button-android"
                src="assets/images/android-download-button.png"
              />
            </a>
          </div>
          <div
            style={{
              margin: '1rem 0',
              display: 'flex',
              justifyContent: 'space-between',
              width: '300px',
            }}
          >
            <div>
              <a target="_blank" href="https://www.instagram.com/ultimaterecruitingprofile">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.50167 3.65419C5.3734 3.65419 3.65672 5.37125 3.65672 7.5C3.65672 9.62875 5.3734 11.3458 7.50167 11.3458C9.62995 11.3458 11.3466 9.62875 11.3466 7.5C11.3466 5.37125 9.62995 3.65419 7.50167 3.65419ZM7.50167 10.0003C6.12633 10.0003 5.00195 8.879 5.00195 7.5C5.00195 6.121 6.12298 4.99972 7.50167 4.99972C8.88037 4.99972 10.0014 6.121 10.0014 7.5C10.0014 8.879 8.87702 10.0003 7.50167 10.0003ZM12.4007 3.49688C12.4007 3.99559 11.9992 4.3939 11.5039 4.3939C11.0053 4.3939 10.6071 3.99225 10.6071 3.49688C10.6071 3.00151 11.0086 2.59985 11.5039 2.59985C11.9992 2.59985 12.4007 3.00151 12.4007 3.49688ZM14.9473 4.40729C14.8904 3.20568 14.616 2.1413 13.7359 1.26436C12.8592 0.387426 11.795 0.112964 10.5937 0.0527167C9.35555 -0.0175722 5.64445 -0.0175722 4.4063 0.0527167C3.20831 0.109617 2.14417 0.384079 1.26408 1.26102C0.383993 2.13796 0.112939 3.20233 0.052705 4.40394C-0.0175683 5.64236 -0.0175683 9.35429 0.052705 10.5927C0.109593 11.7943 0.383993 12.8587 1.26408 13.7356C2.14417 14.6126 3.20496 14.887 4.4063 14.9473C5.64445 15.0176 9.35555 15.0176 10.5937 14.9473C11.795 14.8904 12.8592 14.6159 13.7359 13.7356C14.6127 12.8587 14.8871 11.7943 14.9473 10.5927C15.0176 9.35429 15.0176 5.64571 14.9473 4.40729ZM13.3477 11.9215C13.0867 12.5775 12.5814 13.083 11.9222 13.3474C10.935 13.739 8.59258 13.6486 7.50167 13.6486C6.41076 13.6486 4.06497 13.7356 3.08115 13.3474C2.42526 13.0863 1.91997 12.5809 1.65561 11.9215C1.26408 10.9341 1.35443 8.59115 1.35443 7.5C1.35443 6.40885 1.26743 4.06253 1.65561 3.07849C1.91662 2.42246 2.42192 1.91705 3.08115 1.65263C4.06832 1.26102 6.41076 1.35139 7.50167 1.35139C8.59258 1.35139 10.9384 1.26436 11.9222 1.65263C12.5781 1.9137 13.0834 2.41911 13.3477 3.07849C13.7393 4.06588 13.6489 6.40885 13.6489 7.5C13.6489 8.59115 13.7393 10.9375 13.3477 11.9215Z"
                    fill="#ffffff"
                  />
                </svg>
              </a>
            </div>
            <div>
              <a target="_blank" href="https://www.twitter.com/urpplus">
                <svg
                  width="24"
                  height="20"
                  viewBox="0 0 19 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5703 3.73827C16.582 3.90232 16.582 4.06641 16.582 4.23047C16.582 9.23435 12.7735 15 5.81251 15C3.66796 15 1.67579 14.3789 0 13.3008C0.304699 13.3359 0.597638 13.3477 0.91406 13.3477C2.68356 13.3477 4.31249 12.75 5.61328 11.7305C3.94922 11.6953 2.55468 10.6055 2.0742 9.10547C2.3086 9.1406 2.54296 9.16405 2.78907 9.16405C3.12891 9.16405 3.46878 9.11715 3.78516 9.03516C2.05079 8.68357 0.749969 7.16015 0.749969 5.32031V5.27345C1.25386 5.5547 1.83984 5.73048 2.46089 5.75389C1.44136 5.07419 0.773416 3.91405 0.773416 2.60154C0.773416 1.89842 0.960881 1.25389 1.28903 0.691389C3.15232 2.98826 5.95311 4.48824 9.09371 4.65233C9.03513 4.37108 8.99996 4.07814 8.99996 3.78516C8.99996 1.6992 10.6875 0 12.7851 0C13.875 0 14.8593 0.45703 15.5507 1.19531C16.4062 1.03126 17.2265 0.714835 17.9531 0.281252C17.6718 1.16018 17.0742 1.89846 16.289 2.36718C17.0508 2.28519 17.789 2.0742 18.4687 1.78126C17.9532 2.53123 17.3086 3.19917 16.5703 3.73827Z"
                    fill="#ffffff"
                  />
                </svg>
              </a>
            </div>
            <div>
              <a target="_blank" href="https://www.TikTok.com/@urpplus">
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 13 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 6.16651C11.6713 6.16651 10.4395 5.73899 9.43444 5.01271V10.2536C9.43444 12.8712 7.31809 15 4.71722 15C3.71216 15 2.77977 14.6812 2.01386 14.14C0.797592 13.2806 0 11.8592 0 10.2536C0 7.63594 2.11635 5.50648 4.71784 5.50648C4.93401 5.50648 5.14521 5.52461 5.35331 5.55273V6.16338V8.18534C5.15205 8.12221 4.9396 8.08534 4.71784 8.08534C3.53015 8.08534 2.5636 9.05788 2.5636 10.2536C2.5636 11.0861 3.03321 11.8092 3.71899 12.1724C4.01778 12.3305 4.35756 12.4211 4.71846 12.4211C5.87882 12.4211 6.8255 11.4924 6.86898 10.3348L6.87084 0H9.43382C9.43382 0.223759 9.45556 0.441893 9.49407 0.654402C9.67484 1.63694 10.2544 2.4801 11.0576 3.007C11.6166 3.37389 12.2838 3.58827 12.9994 3.58827V6.16651H13Z"
                    fill="#ffffff"
                  />
                </svg>
              </a>
            </div>
            <div>
              <a target="_blank" href="https://www.facebook.com/ultimaterecruitingprofile">
                <svg
                  fill="#ffffff"
                  height="20"
                  width="20"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 310 310"
                >
                  <g id="XMLID_834_">
                    <path
                      id="XMLID_835_"
                      d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
  		c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
  		V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
  		C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
  		c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"
                    />
                  </g>
                </svg>
              </a>
            </div>
            <div>
              <a target="_blank" href="https://www.LinkedIn.com/company/urpplus">
                <svg
                  fill="#ffffff"
                  height="20"
                  width="20"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 310 310"
                >
                  <g id="XMLID_801_">
                    <path
                      id="XMLID_802_"
                      d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
  		C77.16,101.969,74.922,99.73,72.16,99.73z"
                    />
                    <path
                      id="XMLID_803_"
                      d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
  		c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                    />
                    <path
                      id="XMLID_804_"
                      d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
  		c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
  		c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
  		C310,145.43,300.549,94.761,230.454,94.761z"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div style={{ paddingTop: '2rem' }}>
            <a
              style={{
                fontSize: '20px',
                textAlign: 'center',
                color: '#FFFFFF',
                textDecoration: 'none',
              }}
              href="/home/explore"
            >
              <IonButton
                color={btnColor}
                expand="block"
                style={{
                  color: '#398175',
                  backgroundColor: '#ffffff',
                  borderRadius: '6px',
                  height: '2rem',
                }}
              >
                Click here to Explore Users
              </IonButton>
            </a>
          </div>
        </div>
      </div>
      {isMobileWeb && (
        <div className="logo-page-login-container">
          <Login></Login>
        </div>
      )}
    </IonContent>
  );
};

export default Logo;
