import { normalizeSportGender } from '../util';

export const Softball: any = {
  sections: [
    {
      name: 'Offense',
      // https://www.maxpreps.com/in/franklin/franklin-community-grizzly-cubs/athletes/max-clark/baseball/stats/?careerid=tcj1vjieki899
      categories: [
        {
          name: 'Batting',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Plate Appearances',
              fieldName: 'plateAppearances',
            },
            {
              name: 'At Bats',
              fieldName: 'atBats',
            },
            {
              name: 'Runs',
              fieldName: 'runs',
            },
            {
              name: 'Hits',
              fieldName: 'hits',
            },
            {
              name: 'RBI',
              fieldName: 'rbi',
            },
            {
              name: 'Doubles',
              fieldName: 'doubles',
            },
            {
              name: 'Triples',
              fieldName: 'triples',
            },
            {
              name: 'Home Runs',
              fieldName: 'homeRuns',
            },
            {
              name: 'Grand Slams',
              fieldName: 'grandSlams',
            },
            {
              name: 'Sacrifice Fly',
              fieldName: 'sacrificeFly',
            },
            {
              name: 'Sacrifice Bunt',
              fieldName: 'sacrificeBunt',
            },
            {
              name: 'Base on Balls',
              fieldName: 'baseOnBalls',
            },
            {
              name: 'Strike Outs',
              fieldName: 'strikeOuts',
            },
            {
              name: 'Hit by Pitch',
              fieldName: 'hitByPitch',
            },
            {
              name: 'Reached on Error',
              fieldName: 'reachedOnError',
            },
            {
              name: "Fielder's Choice",
              fieldName: 'fieldersChoice',
            },
            {
              name: 'Left on Base',
              fieldName: 'leftOnBase',
            },
          ],
        },
        {
          name: 'Baserunning',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Stolen Base',
              fieldName: 'stolenBase',
            },
            {
              name: 'Stolen Base Attempts',
              fieldName: 'stolenBaseAttempts',
            },
          ],
        },
      ],
    },
    {
      name: 'Defense',
      // https://www.maxpreps.com/in/franklin/franklin-community-grizzly-cubs/athletes/max-clark/baseball/stats/?careerid=tcj1vjieki899
      categories: [
        {
          name: 'Fielding',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Total Chances',
              fieldName: 'totalChances',
            },
            {
              name: 'Put Outs',
              fieldName: 'putOuts',
            },
            {
              name: 'Assists',
              fieldName: 'assists',
            },
            {
              name: 'Errors',
              fieldName: 'errors',
            },
            {
              name: 'Double Plays',
              fieldName: 'doublePlays',
            },
            {
              name: 'Triple Plays',
              fieldName: 'triplePlays',
            },
            {
              name: 'Passed Balls',
              fieldName: 'passedBalls',
            },
            {
              name: 'Stolen Base Attempts Catcher',
              fieldName: 'stolenBaseAttemptsCatcher',
            },
            {
              name: 'Stolen Base Catcher',
              fieldName: 'stolenBaseCatcher',
            },
            {
              name: 'Caught Stealing',
              fieldName: 'caughtStealing',
            },
            {
              name: 'Caught Stealing Percentage',
              fieldName: 'caughtStealingPercentage',
            },
          ],
        },
        {
          name: 'Pitching',
          stats: [
            {
              name: 'Wins',
              fieldName: 'wins',
            },
            {
              name: 'Loses',
              fieldName: 'loses',
            },
            {
              name: 'Appearances',
              fieldName: 'appearances',
            },
            {
              name: 'Game Started',
              fieldName: 'gamesStarted',
            },
            {
              name: 'Complete Games',
              fieldName: 'completeGames',
            },
            {
              name: 'Shut Out',
              fieldName: 'shutOut',
            },
            {
              name: 'Save',
              fieldName: 'save',
            },
            {
              name: 'No Hitter',
              fieldName: 'noHitter',
            },
            {
              name: 'Perfect Game',
              fieldName: 'perfectGame',
            },
            {
              name: 'Innings Pitched',
              fieldName: 'inningsPitched',
            },
            {
              name: 'Hits Against',
              fieldName: 'hitsAgainst',
            },
            {
              name: 'Runs Against',
              fieldName: 'runsAgainst',
            },
            {
              name: 'Earned Runs',
              fieldName: 'earnedRuns',
            },
            {
              name: 'Base on Balls Against',
              fieldName: 'baseOnBallsAgainst',
            },
            {
              name: 'Batters Struck Out',
              fieldName: 'battersStruckOut',
            },
            {
              name: 'Doubles Against',
              fieldName: 'doublesAgainst',
            },
            {
              name: 'Triples Against',
              fieldName: 'triplesAgainst',
            },
            {
              name: 'Home Runs Against',
              fieldName: 'homeRunsAgainst',
            },
            {
              name: 'Batters Faced',
              fieldName: 'battersFaced',
            },
            {
              name: 'At Bats Pitcher',
              fieldName: 'atBatsPitcher',
            },
            {
              name: 'Wild Pitches',
              fieldName: 'wildPitches',
            },
            {
              name: 'Hit Batter',
              fieldName: 'hitBatter',
            },
            {
              name: 'Sacrifice Fly Against',
              fieldName: 'sacrificeFlyAgainst',
            },
            {
              name: 'Sacrifice Bunt Against',
              fieldName: 'sacrificeBuntAgainst',
            },
            {
              name: 'Number of Pitches',
              fieldName: 'numberOfPitches',
            },
            {
              name: 'Balks',
              fieldName: 'balks',
            },
            {
              name: 'Pick Offs',
              fieldName: 'pickOffs',
            },
            {
              name: 'Stolen Bases Pitcher',
              fieldName: 'stolenBasesPitcher',
            },
          ],
        },
      ],
    },
  ],
};

export const Baseball: any = {
  sections: [
    {
      name: 'Offense',
      // https://www.maxpreps.com/in/franklin/franklin-community-grizzly-cubs/athletes/max-clark/baseball/stats/?careerid=tcj1vjieki899
      categories: [
        {
          name: 'Batting',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Plate Appearances',
              fieldName: 'plateAppearances',
            },
            {
              name: 'At Bats',
              fieldName: 'atBats',
            },
            {
              name: 'Runs',
              fieldName: 'runs',
            },
            {
              name: 'Hits',
              fieldName: 'hits',
            },
            {
              name: 'RBI',
              fieldName: 'rbi',
            },
            {
              name: 'Doubles',
              fieldName: 'doubles',
            },
            {
              name: 'Triples',
              fieldName: 'triples',
            },
            {
              name: 'Home Runs',
              fieldName: 'homeRuns',
            },
            {
              name: 'Grand Slams',
              fieldName: 'grandSlams',
            },
            {
              name: 'Sacrifice Fly',
              fieldName: 'sacrificeFly',
            },
            {
              name: 'Sacrifice Bunt',
              fieldName: 'sacrificeBunt',
            },
            {
              name: 'Base on Balls',
              fieldName: 'baseOnBalls',
            },
            {
              name: 'Strike Outs',
              fieldName: 'strikeOuts',
            },
            {
              name: 'Hit by Pitch',
              fieldName: 'hitByPitch',
            },
            {
              name: 'Reached on Error',
              fieldName: 'reachedOnError',
            },
            {
              name: "Fielder's Choice",
              fieldName: 'fieldersChoice',
            },
            {
              name: 'Left on Base',
              fieldName: 'leftOnBase',
            },
          ],
        },
        {
          name: 'Baserunning',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Stolen Base',
              fieldName: 'stolenBase',
            },
            {
              name: 'Stolen Base Attempts',
              fieldName: 'stolenBaseAttempts',
            },
          ],
        },
      ],
    },
    {
      name: 'Defense',
      // https://www.maxpreps.com/in/franklin/franklin-community-grizzly-cubs/athletes/max-clark/baseball/stats/?careerid=tcj1vjieki899
      categories: [
        {
          name: 'Fielding',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Total Chances',
              fieldName: 'totalChances',
            },
            {
              name: 'Put Outs',
              fieldName: 'putOuts',
            },
            {
              name: 'Assists',
              fieldName: 'assists',
            },
            {
              name: 'Errors',
              fieldName: 'errors',
            },
            {
              name: 'Double Plays',
              fieldName: 'doublePlays',
            },
            {
              name: 'Triple Plays',
              fieldName: 'triplePlays',
            },
            {
              name: 'Passed Balls',
              fieldName: 'passedBalls',
            },
            {
              name: 'Stolen Base Attempts Catcher',
              fieldName: 'stolenBaseAttemptsCatcher',
            },
            {
              name: 'Stolen Base Catcher',
              fieldName: 'stolenBaseCatcher',
            },
            {
              name: 'Caught Stealing',
              fieldName: 'caughtStealing',
            },
            {
              name: 'Caught Stealing Percentage',
              fieldName: 'caughtStealingPercentage',
            },
          ],
        },
        {
          name: 'Pitching',
          stats: [
            {
              name: 'Wins',
              fieldName: 'wins',
            },
            {
              name: 'Loses',
              fieldName: 'loses',
            },
            {
              name: 'Appearances',
              fieldName: 'appearances',
            },
            {
              name: 'Game Started',
              fieldName: 'gamesStarted',
            },
            {
              name: 'Complete Games',
              fieldName: 'completeGames',
            },
            {
              name: 'Shut Out',
              fieldName: 'shutOut',
            },
            {
              name: 'Save',
              fieldName: 'save',
            },
            {
              name: 'No Hitter',
              fieldName: 'noHitter',
            },
            {
              name: 'Perfect Game',
              fieldName: 'perfectGame',
            },
            {
              name: 'Innings Pitched',
              fieldName: 'inningsPitched',
            },
            {
              name: 'Hits Against',
              fieldName: 'hitsAgainst',
            },
            {
              name: 'Runs Against',
              fieldName: 'runsAgainst',
            },
            {
              name: 'Earned Runs',
              fieldName: 'earnedRuns',
            },
            {
              name: 'Base on Balls Against',
              fieldName: 'baseOnBallsAgainst',
            },
            {
              name: 'Batters Struck Out',
              fieldName: 'battersStruckOut',
            },
            {
              name: 'Doubles Against',
              fieldName: 'doublesAgainst',
            },
            {
              name: 'Triples Against',
              fieldName: 'triplesAgainst',
            },
            {
              name: 'Home Runs Against',
              fieldName: 'homeRunsAgainst',
            },
            {
              name: 'Batters Faced',
              fieldName: 'battersFaced',
            },
            {
              name: 'At Bats Pitcher',
              fieldName: 'atBatsPitcher',
            },
            {
              name: 'Wild Pitches',
              fieldName: 'wildPitches',
            },
            {
              name: 'Hit Batter',
              fieldName: 'hitBatter',
            },
            {
              name: 'Sacrifice Fly Against',
              fieldName: 'sacrificeFlyAgainst',
            },
            {
              name: 'Sacrifice Bunt Against',
              fieldName: 'sacrificeBuntAgainst',
            },
            {
              name: 'Number of Pitches',
              fieldName: 'numberOfPitches',
            },
            {
              name: 'Balks',
              fieldName: 'balks',
            },
            {
              name: 'Pick Offs',
              fieldName: 'pickOffs',
            },
            {
              name: 'Stolen Bases Pitcher',
              fieldName: 'stolenBasesPitcher',
            },
          ],
        },
      ],
    },
  ],
};

export const Lacrosse: any = {
  sections: [
    {
      name: 'Offense',
      // https://www.maxpreps.com/id/meridian/rocky-mountain-grizzlies/athletes/ray-den-alliance-wise/lacrosse/stats/?careerid=pe4hmulce22j3
      categories: [
        {
          name: 'Scoring',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Goals',
              fieldName: 'goals',
            },
            {
              name: 'Assists',
              fieldName: 'assists',
            },
            {
              name: 'Points',
              fieldName: 'points',
            },
            {
              name: 'Total Shots',
              fieldName: 'totalShots',
            },
            {
              name: 'Shots on Goal',
              fieldName: 'shotsOnGoal',
            },
          ],
        },
        {
          name: 'Fielding',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Ground Balls',
              fieldName: 'groundBalls',
            },
            {
              name: 'Turnovers',
              fieldName: 'turnovers',
            },
            {
              name: 'Takeaways',
              fieldName: 'takeaways',
            },
            {
              name: 'Unforced Errors',
              fieldName: 'unforcedErrors',
            },
          ],
        },
        {
          name: 'Faceoffs',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Faceoff Attempts',
              fieldName: 'faceoffAttempts',
            },
            {
              name: 'Faceoff Wins',
              fieldName: 'faceoffWins',
            },
          ],
        },
      ],
    },
    {
      name: 'Defense',
      // https://www.maxpreps.com/id/meridian/rocky-mountain-grizzlies/athletes/ray-den-alliance-wise/lacrosse/stats/?careerid=pe4hmulce22j3
      categories: [
        {
          name: 'Goaltending',
          stats: [
            {
              name: 'Minutes Played',
              fieldName: 'minutesPlayed',
            },
            {
              name: 'Goals Against',
              fieldName: 'goalsAgainst',
            },
            {
              name: 'Goals Against Average',
              fieldName: 'goalsAgainstAverage',
            },
            {
              name: 'Keeper Shots on Goal',
              fieldName: 'keeperShotsOnGoal',
            },
            {
              name: 'Saves',
              fieldName: 'saves',
            },
            {
              name: 'Shut Outs',
              fieldName: 'shutOuts',
            },
            {
              name: 'Wins',
              fieldName: 'wins',
            },
            {
              name: 'Loses',
              fieldName: 'losses',
            },
            {
              name: 'Ties',
              fieldName: 'ties',
            },
          ],
        },
      ],
    },
  ],
};

export const Soccer: any = {
  sections: [
    {
      name: 'Offense',
      // https://www.maxpreps.com/fl/arcadia/desoto-county-bulldogs/athletes/auselio-guti%c3%a9rrez/soccer/stats/?careerid=1s54e2vt6joue
      categories: [
        {
          name: 'Field Stats',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Field Minutes',
              fieldName: 'fieldMinutes',
            },
            {
              name: 'Goals',
              fieldName: 'goals',
            },
            {
              name: 'Assists',
              fieldName: 'assists',
            },
            {
              name: 'Points',
              fieldName: 'points',
            },
            {
              name: 'Steals',
              fieldName: 'steals',
            },
            {
              name: 'Corner Kicks',
              fieldName: 'cornerKicks',
            },
            {
              name: 'Shots on Goal',
              fieldName: 'shotsOnGoal',
            },
            {
              name: 'Shots',
              fieldName: 'shots',
            },
            {
              name: 'Penalty Kick Attempts',
              fieldName: 'penaltyKickAttempts',
            },
            {
              name: 'Penalty Kicks Made',
              fieldName: 'penaltyKicksMade',
            },
            {
              name: 'Game Winning Goal',
              fieldName: 'gameWinningGoal',
            },
            {
              name: 'Yellow Cards',
              fieldName: 'yellowCards',
            },
            {
              name: 'Red Cards',
              fieldName: 'redCards',
            },
          ],
        },
      ],
    },
    {
      name: 'Defense',
      // https://www.maxpreps.com/fl/vero-beach/st-edwards-pirates/athletes/alex-lee/soccer/stats/?careerid=pjsq4an9r2ctd
      categories: [
        {
          name: 'Goaltending',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Game Minutes',
              fieldName: 'gameMinutes',
            },
            {
              name: 'OT Minutes',
              fieldName: 'otMinutes',
            },
            {
              name: 'Opponent Shots on Goal',
              fieldName: 'opponentShotsOnGoal',
            },
            {
              name: 'Goals Against',
              fieldName: 'goalsAgainst',
            },
            {
              name: 'Saves',
              fieldName: 'saves',
            },
            {
              name: 'Goals Against Average',
              fieldName: 'goalsAgainstAverage',
            },
            {
              name: 'Penalty Kicks Against',
              fieldName: 'penaltyKicksAgainst',
            },
            {
              name: 'Penalty Kick Saves',
              fieldName: 'penaltyKickSaves',
            },
            {
              name: 'Shut Outs',
              fieldName: 'shutOuts',
            },
            {
              name: 'Wins',
              fieldName: 'wins',
            },
            {
              name: 'Losses',
              fieldName: 'losses',
            },
            {
              name: 'Ties',
              fieldName: 'ties',
            },
          ],
        },
      ],
    },
  ],
};

export const Volleyball: any = {
  sections: [
    {
      name: 'Offense',
      // https://www.maxpreps.com/nc/smithfield/neuse-charter-cougars/athletes/julia-bridges/volleyball/stats/?careerid=vum55mrenkv22
      categories: [
        {
          name: 'Attacking',
          stats: [
            {
              name: 'Sets Played',
              fieldName: 'setsPlayed',
            },
            {
              name: 'Kills',
              fieldName: 'kills',
            },
            {
              name: 'Attack Attempts',
              fieldName: 'attackAttempts',
            },
            {
              name: 'Attack Errors',
              fieldName: 'attackErrors',
            },
          ],
        },
        {
          name: 'Serving',
          stats: [
            {
              name: 'Sets Played',
              fieldName: 'setsPlayed',
            },
            {
              name: 'Serving Aces',
              fieldName: 'servingAces',
            },
            {
              name: 'Total Serves',
              fieldName: 'totalServes',
            },
            {
              name: 'Serving Errors',
              fieldName: 'servingErrors',
            },
            {
              name: 'Serving Points',
              fieldName: 'servingPoints',
            },
          ],
        },
      ],
    },
    {
      name: 'Defense',
      // https://www.maxpreps.com/nc/smithfield/neuse-charter-cougars/athletes/julia-bridges/volleyball/stats/?careerid=vum55mrenkv22
      categories: [
        {
          name: 'Blocking',
          stats: [
            {
              name: 'Sets Played',
              fieldName: 'setsPlayed',
            },
            {
              name: 'Matches Played',
              fieldName: 'matchesPlayed',
            },
            {
              name: 'Solo Blocks',
              fieldName: 'soloBlocks',
            },
            {
              name: 'Assisted Blocks',
              fieldName: 'assistedBlocks',
            },
            {
              name: 'Block Errors',
              fieldName: 'blockErrors',
            },
          ],
        },
        {
          name: 'Digging',
          stats: [
            {
              name: 'Sets Played',
              fieldName: 'setsPlayed',
            },
            {
              name: 'Matches Played',
              fieldName: 'matchesPlayed',
            },
            {
              name: 'Digs',
              fieldName: 'digs',
            },
            {
              name: 'Dig Errors',
              fieldName: 'digErrors',
            },
          ],
        },
        {
          name: 'Ball Handling',
          stats: [
            {
              name: 'Sets Played',
              fieldName: 'setsPlayed',
            },
            {
              name: 'Assists',
              fieldName: 'assists',
            },
            {
              name: 'Ball Handling Attempt',
              fieldName: 'ballHandlingAttempt',
            },
            {
              name: 'Ball Handling Errors',
              fieldName: 'ballHandlingErrors',
            },
          ],
        },
        {
          name: 'Serve Receiving',
          stats: [
            {
              name: 'Sets Played',
              fieldName: 'setsPlayed',
            },
            {
              name: 'Matches Played',
              fieldName: 'matchesPlayed',
            },
            {
              name: 'Receptions',
              fieldName: 'receptions',
            },
            {
              name: 'Reception Errors',
              fieldName: 'receptionErrors',
            },
          ],
        },
      ],
    },
  ],
};

// Player Statistics

export const Basketball: any = {
  sections: [
    {
      name: 'General',
      // https://www.maxpreps.com/tx/duncanville/duncanville-panthers-and-pantherettes/athletes/ronald-holland/basketball/stats/?careerid=nd9n2gkvnv0h1
      categories: [
        {
          name: 'Game Stats',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Minutes Played',
              fieldName: 'minutesPlayed',
            },
            {
              name: 'Points',
              fieldName: 'points',
            },
            {
              name: 'Defensive Rebounds',
              fieldName: 'defensiveRebounds',
            },
            {
              name: 'Offensive Rebounds',
              fieldName: 'offensiveRebounds',
            },
            {
              name: 'Assists',
              fieldName: 'assists',
            },
            {
              name: 'Steals',
              fieldName: 'steals',
            },
            {
              name: 'Blocks',
              fieldName: 'blocks',
            },
            {
              name: 'Turnovers',
              fieldName: 'turnovers',
            },
            {
              name: 'Fouls',
              fieldName: 'fouls',
            },
          ],
        },
        {
          name: 'Shooting',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Minutes Played',
              fieldName: 'minutesPlayed',
            },
            {
              name: '3 Point Attempts',
              fieldName: 'threePointAttempts',
            },
            {
              name: '3 Points Made',
              fieldName: 'threePointsMade',
            },
            {
              name: 'Two Point Attempts',
              fieldName: 'twoPointAttempts',
            },
            {
              name: 'Two Points Made',
              fieldName: 'twoPointsMade',
            },
            {
              name: 'Free Throw Attempts',
              fieldName: 'freeThrowAttempts',
            },
            {
              name: 'Free Throws Made',
              fieldName: 'freeThrowsMade',
            },
          ],
        },
      ],
    },
  ],
};

export const Football: any = {
  sections: [
    {
      name: 'Offense',
      // https://www.maxpreps.com/tx/austin/westlake-chaparrals/athletes/jaden-greathouse/football/stats/?careerid=0qhtelmn73jcc
      categories: [
        {
          name: 'Passing',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Attempts',
              fieldName: 'attempts',
            },
            {
              name: 'Completions',
              fieldName: 'completions',
            },
            {
              name: 'Yards',
              fieldName: 'yards',
            },
            {
              name: 'Touch Downs',
              fieldName: 'touchDowns',
            },
            {
              name: 'Interceptions',
              fieldName: 'interceptions',
            },
            {
              name: 'Long',
              fieldName: 'long',
            },
            {
              name: 'QB Rating',
              fieldName: 'qbRating',
            },
          ],
        },
        {
          name: 'Rushing',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Carries',
              fieldName: 'carries',
            },
            {
              name: 'Yards',
              fieldName: 'yards',
            },
            {
              name: 'Long',
              fieldName: 'long',
            },
            {
              name: '100 Plus',
              fieldName: 'over100',
            },
            {
              name: 'Touch Downs',
              fieldName: 'touchDowns',
            },
          ],
        },
        {
          name: 'Receiving',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Receptions',
              fieldName: 'receptions',
            },
            {
              name: 'Yards',
              fieldName: 'yards',
            },
            {
              name: 'Long',
              fieldName: 'long',
            },
            {
              name: 'Touch Downs',
              fieldName: 'touchDowns',
            },
          ],
        },
        {
          name: 'Fumbles and Pancake Blocks',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Fumbles',
              fieldName: 'fumbles',
            },
            {
              name: 'Lost',
              fieldName: 'lost',
            },
            {
              name: 'Pancake Blocks',
              fieldName: 'pancakeBlocks',
            },
          ],
        },
      ],
    },
    {
      name: 'Defense',
      // https://www.maxpreps.com/az/scottsdale/saguaro-sabercats/athletes/zaccheus-cooper/football/stats/?careerid=g8mk9h8r9ir33
      // https://www.maxpreps.com/tx/austin/westlake-chaparrals/athletes/colton-vasek/football/stats/?careerid=k83qov2ga2e99
      categories: [
        {
          name: 'Tackles',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Solo',
              fieldName: 'solo',
            },
            {
              name: 'Assists',
              fieldName: 'assists',
            },
            {
              name: 'Tackles for Loss',
              fieldName: 'tacklesForLoss',
            },
          ],
        },
        {
          name: 'Sacks',
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Sacks',
              fieldName: 'sacks',
            },
            {
              name: 'Yards Lost',
              fieldName: 'yardsLost',
            },
            {
              name: 'QB Hurries',
              fieldName: 'qbHurries',
            },
          ],
        },
      ],
    },
    {
      name: 'Special Teams',
      categories: [
        {
          name: 'Kickoff and Punt Returns',
          // https://www.maxpreps.com/tx/austin/westlake-chaparrals/athletes/jaden-greathouse/football/stats/?careerid=0qhtelmn73jcc
          stats: [
            {
              name: 'Games Played',
              fieldName: 'gamesPlayed',
            },
            {
              name: 'Kickoff Returns',
              fieldName: 'koReturns',
            },
            {
              name: 'Kickoff Return Yards',
              fieldName: 'koReturnYards',
            },
            {
              name: 'Kickoff Returns Long',
              fieldName: 'koLong',
            },
            {
              name: 'Punt Returns',
              fieldName: 'puntReturns',
            },
            {
              name: 'Punt Return Yards',
              fieldName: 'puntReturnYards',
            },
            {
              name: 'Punt Returns Long',
              fieldName: 'puntLong',
            },
            {
              name: 'Punt Return Fair Catches',
              fieldName: 'puntFairCatches',
            },
          ],
        },
      ],
    },
  ],
};

export function getSportStats(sport: string) {
  //
  switch (normalizeSportGender(sport)) {
    case 'football':
      return Football;
    case 'lacrosse':
      return Lacrosse;
    case 'softball':
      return Softball;
    case 'soccer':
      return Soccer;
    case 'baseball':
      return Baseball;
    case 'basketball':
      return Basketball;
    case 'volleyball':
      return Volleyball;
    case '':
      return {};
    default:
      console.error('Unknown sport:', normalizeSportGender(sport), sport);
      return {};
  }
}
