export const schools = [
  '1 December University of Alba Iulia',
  '1 Lt Charles W. Whitcomb School',
  '10th Street School',
  '112 Alc Independent Study',
  '112 Alc Middle School',
  '112 Alc Seat-based',
  '123 YOU N ME PRESCHOOL',
  '12th Street Elementary',
  '1408 S. GOLIAD ST.',
  '18+ Program',
  '1ST BAPTIST CHURCH PRESCHOOL',
  '1ST CEREBRAL PALSY OF NJ',
  '1ST CLASS MONTESSORI',
  '1ST UNITED METHODIST PRESCHOOL & KINDERGARTEN',
  '1r Elementary',
  '20 De Septiembre De 1988',
  '21 CENTURY STEM ACADEMY',
  '21st Century Academy',
  '21st Century Charter Sch Of Gary',
  '21st Century Cyber Cs',
  '21st Century Early Learning Foundations Academy',
  '21st Century Eschool',
  '21st Century Learning Academy/kiowa County',
  '21st Century Learning Institute',
  '21st Century Preparatory School',
  '21st Century Primary Center',
  '21st Century Skills Academy',
  '2450 CHILDCARE INC DBA HAPPY DAYS CHILDCARE',
  '247 LEARNING ACADEMY',
  '25 E Sw 4',
  '270 - Hap - Is',
  '270 Hopkins Alternative',
  '270 Hopkins Is',
  '270 Hopkins North Jr High Alc',
  '270 Hopkins West Jr High Alc',
  '272 Central Middle Sch. Alt',
  '276 Minnetonka Compass',
  '276 Minnetonka Compass Ext Yr',
  '276 Minnetonka Hs Is',
  '277 Westonka Area Learning Academy',
  '277-grandview Middle - Alc',
  '283 St Louis Pk Independent Study',
  '283-ind Stdy 15 And Under - I.s.',
  '283-st. Louis Park Alc',
  '2ND NATURE ACADEMY',
  '2nd Military Medical University',
  '3088 E 800 N',
  '30th Avenue School (the)',
  '360 High School',
  '37 M W 4',
  '3b Juvenile Detention Center',
  '3d Academy',
  '3rd H S',
  '3rd Military Medical University',
  '4 OUR KIDS LEARNING ACADEMY',
  '4-j Elementary School',
  '40 SYLVAN PETERSHEIM',
  '4092 - Watershed High School Alc',
  '4122 - Eagle Ridge Academy - Is',
  '4126 - Prairie Seeds Academy - Is',
  '47 American Sign Language And English Lower School (the)',
  '49-e-w-4',
  '49th Street Academy',
  '4POINTS ACADEMY',
  '4k Center For Literacy',
  '4k Community School',
  '4k Community-based',
  '4k Mcfarland',
  '4k Pk Off Site',
  '4th Military Medical University',
  '500 Reach',
  '51st Avenue Academy (the Path To Academic Excellence)',
  '54th Street Academy',
  '565 ACADEMY',
  '5th And 6th Grade Ctr.',
  '622 Alternative Middle/high School',
  '63RD ST MULTICULTURAL ACAD OF ACADEMIC EXCELLENCE',
  '6th Grade Center',
  '7 Rivers Community High',
  '70 Online',
  '72 E W 7',
  '7th And 8th Grade Academy',
  '7th And 8th Grade Center',
  '7th And 8th Grade Ctr.',
  '7th Grade Academy',
  '7th H S - Northeast',
  '800 W Broadway',
  '800 W Broadway Extended',
  '8th Grade Academy',
  '9 FRUITS LEARNING CENTER',
  '916 Mahtomedi Academy',
  '917 Dash',
  '917 Intra-dakota Educational Alt',
  '917 Paces',
  '917 Sun',
  '917 Transitional Education Serv Alt',
  '91ST PSALM CHRISTIAN SCHOOL',
  '9THBRIDGE SCHOOL',
  '9th Grade Academy',
  'A & M Cons H S',
  'A & M Consolidated Middle',
  'A A Anastasia Elementary School',
  'A A Gates Elementary School',
  'A A Kingston Middle School',
  'A B C LITTLE SCHOOL',
  'A B Chandler Elementary School',
  'A B Duncan El',
  'A B Mcbay El',
  'A B Mcdonald Elementary School',
  'A B Shepard High Sch (campus)',
  'A BETTER CHANCE SCHOOL/CAL AUTISM FOUNDATION',
  'A BOOKS CHRISTIAN ACADEMY',
  'A BRIGHT BEGINNING',
  'A C Blunt Middle',
  'A C Houghton Elementary School',
  'A C Jones H S',
  'A C Thompson Elem School',
  'A CARING TOUCH CHRISTIAN FAMILY CENTER',
  'A CHILD S PLACE',
  'A CHILD S PLACE SCHOOL',
  "A CHILD'S CHOICE MONTESSORI SCHOOL",
  "A CHILD'S GARDEN",
  "A CHILD'S PLACE DAY SCHOOL",
  "A CHILD'S TOUCH",
  "A CHILDREN'S HABITAT MONTESSORI SCHOOL",
  'A CHILDRENS PLACE LEARNING CENTER',
  'A CHILDS GARDEN MONTESSORI SCHOOL',
  'A CHILDS PLACE TOO',
  'A CHILDS UNIVERSITY',
  'A CHILDS WORLD',
  'A COUNTRY GARDEN MONTESSORI',
  'A Chester Redshaw School',
  'A D Oliver Middle School',
  'A DARE TO CARE LEARNING CENTER',
  'A DIFFERENT WORLD ACADEMY',
  'A Drewicz Elementary',
  'A E Angier',
  'A E Burling Elementary School',
  'A E Butler Int',
  'A E R O Educational Center',
  'A English Language School',
  'A F Ames Elem School',
  'A F Maloney',
  'A F Palmer Elementary School / Windsor Central Middle School',
  'A G Cox Middle',
  'A G DAY CARE LEARNING CENTER',
  'A G Elder El',
  'A G West Black Hills High School',
  'A GROWING PLACE MONTESSORI',
  'A H Bush Elementary School',
  'A H Roberts Elementary',
  'A H Snipes Academy Of Arts/des',
  'A HABITAT FOR LEARNING',
  'A J Jordak Elementary School',
  'A J Katzenmaier Academy',
  'A J Mcmullen Sch',
  'A J Mitchell Elementary School',
  'A J Schmidt Elementary School',
  'A J West Elementary',
  'A J Winters Elementary School',
  'A K Smith Area Career Center',
  "A KID'S PLACE",
  'A L Brown High',
  'A L Hagen Junior High School',
  'A L Lotts Elementary',
  'A L Stanback Middle',
  'A L Wilson El Sch',
  'A LEARNING DAY CARE CENTER',
  'A Leal Jr Middle',
  'A M Cosgrove Middle School',
  'A M Ochoa El',
  'A M Pate El',
  'A M Windham Elementary School',
  'A M Yealey Elementary School',
  "A MOTHER'S TOUCH CREATIVE LEARNING CENTER",
  'A MOTHERS TOUCH LEARNING ACADEMY',
  'A Macarthur Barr Middle School',
  'A Macarthur Barr Middle School 5-6 Academy For Excellence',
  'A Maceo Smith New Tech H S',
  'A N Rico El',
  'A NEW WORLD OF MONTESSORI',
  'A O Marshall Elem School',
  'A P Beutel El',
  'A P Solis Middle',
  'A PERFECT PLACE 4 KIDZ',
  'A PLUS LEARNING ACADEMY',
  'A PLUS PREPARATORY LEARNING ACADEMY',
  'A Philip Randolph Campus High School',
  'A Philip Randolph Technical High School',
  'A Place To Grow',
  'A RAINBOW OF FRIENDS PRESCHOOL',
  'A STEP AHEAD',
  'A STEP AHEAD LEARNING CENTER',
  'A STEP UP @ IMMOKALEE',
  'A STEP UP ACADEMY',
  'A STEP UP INC.',
  'A T Allen Elementary',
  'A UNIQUE LEARNING ACADEMY',
  'A V Cato El',
  'A VILLAGE GREEN MONTESSORI',
  'A Vito Martinez Middle School',
  'A W Beattie Career Center',
  'A W Brown - F L A Early Childhood Campus',
  'A W James Elementary School',
  'A W Johnson Elementary School',
  'A WORLD DISCOVERY MONTESSORI SCHOOL',
  'A WORLD OF KNOWLEDGE PRESCHOOL AND LEARNING CENTER',
  'A+ Academy El',
  'A+ Arts Academy',
  "A+ Children's Academy",
  'A+ Secondary School',
  'A+ Unlimited Potential',
  'A+up University',
  'A-3 Multiagency Adolescent Prog',
  'A-c Central Elementary School',
  'A-c Central High School',
  'A-c Central Middle School',
  'A. A. Nelson Elementary School',
  'A. B. Hill Elementary',
  'A. Baldwin Middle School',
  'A. C. Corcoran Elementary',
  'A. C. E.',
  'A. C. Flora High',
  'A. C. Moore Elementary',
  'A. C. Whelan Elementary School',
  'A. Crawford Mosley High School',
  'A. Crosby Kennett Middle School',
  'A. D. Stowell Elem.',
  'A. E. Arnold Elementary',
  'A. E. Phillips Laboratory School',
  'A. G. A. P. E. Teen Parent',
  'A. G. Currie Middle',
  'A. Henderson Elementary',
  'A. I. Prince Technical High School',
  'A. I. Root Middle School',
  'A. Irvin Studley Elementary School',
  'A. J. Cook Elementary',
  'A. J. Dorsa Elementary',
  'A. J. Lewis Greenview Elementary',
  'A. J. Martin Elem.',
  'A. K. Suter Elementary School',
  'A. L. Conner Elementary',
  'A. L. Mebane Middle School',
  "A. Linwood Holton Governor's School",
  'A. M. Thomas Middle',
  'A. M. Winn Waldorf-inspired',
  'A. Maceo Walker Middle',
  'A. Mario Loiederman Middle',
  'A. Montoya Elementary',
  'A. Philip Randolph Academies',
  'A. R. Rucker Middle',
  'A. Russell Knight Elementary School',
  'A. T. Allison Elementary School',
  'A. W. Cox School',
  'A. Ward Spaulding School',
  'A. Z. Kelley Elementary',
  'A.b. Combs Elementary School',
  'A.b. Skane Center',
  'A.c. Alexander Elementary School',
  'A.c. Steere Elementary School',
  'A.c.e. Academy',
  'A.c.g.c. Elementary',
  'A.c.g.c. Secondary',
  'A.d. Henderson University School & Fau High School',
  'A.g. Richardson Elementary',
  'A.i. Jedlicka Middle School',
  'A.j. Whittenburg Elementary',
  'A.l. Burruss Elementary School',
  'A.m. Davis Elementary',
  'A.p Lutali',
  'A.p. Hill Elementary',
  'A.p. Morris Early Childhood Center',
  'A.quinn Jones Center',
  'A.s. Rhodes Elementary',
  'A.w.e. Bassette Elementary',
  'A/o Program',
  'AAA ACADEMY',
  'AARDVARK CHILD CARE & LEARNING CENTER',
  'AARON SCHOOL',
  'ABA ACADEMY',
  'ABACUS MONTESSORI ACADEMY',
  'ABACUS SCHOOL OF AUSTIN',
  'ABBA INDEPENDENT LEARNING CENTER',
  'ABBEVILLE CHRISTIAN ACADEMY',
  'ABBOTSFORD CHRISTIAN ACADEMY',
  'ABC ACADEMY',
  'ABC CHILDCARE CENTER',
  'ABC DAYCARE AND PRESCHOOL',
  'ABC INTERNATIONAL MONTESSORI ACADEMY',
  'ABC LEARNING CENTRE, INC (DBA ABC-STEWART SCHOOL)',
  'ABC LEARNING PREP',
  'ABC LITTLE SCHOOL',
  'ABC LITTLE SCHOOLS - WEST HOLLYWOOD',
  'ABC MONTESSORI ACADEMY FOR THE BRILLIANT CHILD',
  'ABC OF NC CHILD DEVELOPMENT CENTER',
  'ABC PLAYHOUSE',
  'ABC SCHOOL',
  "ABC'S OF LEARNING",
  "ABC'S OF LEARNING AND GROWING INC",
  'ABCD DAYCARE CENTER',
  'ABELS ACADEMY',
  'ABERCROMBIE ACADEMY',
  'ABERDEEN CATHOLIC SCHOOLS',
  'ABERDEEN CHRISTIAN SCHOOL, INC',
  'ABF LEARNING CENTER',
  'ABI CHRISTIAN ACADEMY',
  'ABIDING HOPE PRESCHOOL',
  'ABIDING PEACE ACADEMY',
  'ABIDING SAVIOR ACADEMY',
  'ABIDING SAVIOR LUTHERAN CHURCH',
  'ABIDING SAVIOR LUTHERAN SCHOOL',
  'ABIDING WORD LUTHERAN SCHOOL',
  'ABILENE BAPTIST ACADEMY',
  'ABILITY SCHOOL',
  'ABINGTON FRIENDS SCHOOL',
  'ABINTRA MONTESSORI SCHOOL',
  'ABIS PLACE',
  'ABLE ACADEMY',
  'ABLE CENTER',
  'ABM MINISTRIES-LIGHTHOUSE CHRISTIAN ACADEMY',
  'ABM University College',
  'ABM VIETNAMESE SCHOOL',
  'ABNER MONTESSORI SCHOOL',
  'ABOITE CHRISTIAN SCHOOL',
  'ABRAHAM JOSHUA HESCHEL DAY SCHOOL',
  'ABRAHAM JOSHUA HESCHEL SCHOOL',
  'ABRAXAS ACADEMY',
  'ABRAXAS I ARLENE LISSNER HIGH SCHOOL',
  'ABRAXAS LEADERSHIP DEVELOPMENT PROGRAM',
  'ABRAXAS YOUTH CENTER',
  'ABTI American University of Nigeria',
  'ABUNDANT LIFE ACADEMY',
  'ABUNDANT LIFE CHRISTIAN ACADEMY',
  'ABUNDANT LIFE CHRISTIAN LRNG CENTER /NEW JERUSALEM',
  'ABUNDANT LIFE CHRISTIAN SCHOOL',
  'ABUNDANT LIFE MINISTRIES',
  'ABUNDANT LIFE SCHOOL',
  'ABUNDANTCRESCENT CITY CHRISTIAN ACADEMY',
  'ACACIA ACADEMY',
  'ACACIA LEARNING CENTER',
  'ACACIAWOOD SCHOOL',
  'ACADEME OF THE OAKS',
  'ACADEMIA DE LA RECTA PORTA ICDS',
  'ACADEMIA PRIVATE SCHOOL',
  'ACADEMIC ALTERNATIVE EDUCATION',
  'ACADEMIC HIGH SCHOOL',
  'ACADEMIC MASTERY ACADEMY',
  'ACADEMICS PLUS',
  'ACADEMIE DE MONTESSORI',
  'ACADEMY AT DOUBLE H RANCH',
  'ACADEMY AT NEW LIFE',
  'ACADEMY AT ST. SABINA',
  'ACADEMY AT THE BEACH',
  'ACADEMY FOR ACADEMIC EXCELLENCE',
  'ACADEMY FOR ADVANCEMENT OF CHILDREN WITH AUTISM',
  'ACADEMY FOR AUTISM',
  'ACADEMY FOR INDIVIDUAL EXCELLENCE',
  'ACADEMY FOR KIDS',
  'ACADEMY FOR LEARNING',
  'ACADEMY FOR PRECISION LEARNING',
  'ACADEMY FOR YOUNG LEARNERS INC',
  'ACADEMY HILL SCHOOL',
  'ACADEMY INNOVATION',
  'ACADEMY MONTESSORI INTERNATIONALE',
  'ACADEMY MONTESSORI PRESCHOOL',
  'ACADEMY NORTHWEST',
  'ACADEMY OF CHRISTIAN EDUCATION',
  'ACADEMY OF COASTAL CAROLINA',
  'ACADEMY OF EARLY LEARNING',
  'ACADEMY OF EXCELLENCE',
  'ACADEMY OF GREATNESS AND EXCELLENCE',
  'ACADEMY OF MONTESSORI INTERNATIONAL',
  'ACADEMY OF MOUNT ST URSULA',
  'ACADEMY OF NOTRE DAME',
  'ACADEMY OF OUR LADY',
  'ACADEMY OF OUR LADY OF GRACE',
  'ACADEMY OF OUR LADY OF MERCY, LAURALTON HALL',
  'ACADEMY OF OUR LADY OF MT CARMEL',
  'ACADEMY OF OUR LADY OF PEACE',
  'ACADEMY OF SAINT ELIZABETH',
  'ACADEMY OF SAINT LOUIS',
  'ACADEMY OF SAINT PAUL',
  'ACADEMY OF SCHOLASTIC ACHIEVEMENT',
  'ACADEMY OF ST ADALBERT',
  'ACADEMY OF ST BENEDICT THE AFRICAN',
  'ACADEMY OF ST JAMES OF THE MARCHES',
  'ACADEMY OF ST JOSEPH',
  'ACADEMY OF THE HOLY ANGELS',
  'ACADEMY OF THE HOLY CROSS',
  'ACADEMY OF THE HOLY FAMILY',
  'ACADEMY OF THE HOLY NAMES',
  'ACADEMY OF THE IMMACULATE HEART OF MARY',
  'ACADEMY OF THE MOST BLESSED SACRAMENT',
  'ACADEMY OF THE NEW CHURCH BOYS SCHOOL',
  'ACADEMY OF THE NEW CHURCH GIRLS SCHOOL',
  'ACADEMY OF THE SACRED HEART',
  'ACADEMY OF WHOLE LEARNING',
  'ACADEMY ON THE HILL',
  'ACADEMY PREP CENTER OF ST PETERSBURG',
  'ACADEMY PREP CENTER OF TAMPA',
  'ACADEMY PRIMARY SCHOOL & CHILD DEVELOPMENT CENTER',
  'ACADEMY SCHOOLS',
  'ACADEMY-NOTRE DAME DE NAMUR',
  'ACADEMY360 UPPER SCHOOL',
  'ACARATH MONTESSORI CENTER',
  'ACCEL',
  'ACCELERATED SCHOOLS',
  'ACCELERATED SCHOOLS OF OVERLAND PARK',
  'ACCELERATIONS LEARNING CENTER',
  'ACCESS FUTURES CHILD CARE CENTER',
  'ACCOTINK ACADEMY',
  'ACE ACADEMY SOUTH',
  'ACERA SCHOOL',
  'ACHIEVE KIDS',
  'ACHIEVEMENT CENTER OF SARASOTA THE',
  'ACHIEVER CHRISTIAN SCHOOL',
  "ACHIEVER'S CHRISTIAN ACADEMY",
  'ACHIEVERS CENTER FOR EDUCATION',
  'ACHIEVING STARS ACADEMY',
  'ACLD SCHOOL',
  'ACLD TILLOTSON SCHOOL',
  'ACORN HILL WALDORF KINDERGARTEN & NURSERY',
  'ACORN LEARNING CENTER',
  'ACORN MONTESSORI SCHOOL',
  'ACORN SCHOOL',
  'ACRES OF FUN CHILD CARE CENTER INC.',
  'ACS EDUCATIONAL RESOURCES AND CONSULTANT LLC',
  'ACT',
  'ACTION BASED ENTERPRISES SCHOOL',
  'ACTION DAY PRIMARY PLUS ALLEN SCHOOL',
  'ACTIVE LEARNING CENTER',
  'ACTON ACADEMY',
  'ACTON ACADEMY ALBUQUERQUE',
  'ACTON ACADEMY VENICE BEACH',
  'ACTS ACADEMY',
  'ACTS CHRISTIAN ACADEMY',
  "ACU ARKANSAS CHILDREN'S UNIVERSITY",
  'AD ASTRA SCHOOL',
  'ADA CHRISTIAN SCHOOL',
  'ADAMS COUNTY CHRISTIAN ACADEMY',
  'ADAMS COUNTY CHRISTIAN SCHOOL',
  'ADAMS INTERNATIONAL SCHOOL',
  'ADAMS MONTESSORI SCHOOL',
  'ADAMS PROTESTANT REFORMED CHRISTIAN SCHOOL',
  'ADAMS SCHOOL',
  'ADAT ARI EL DAY SCHOOL',
  'ADDA CLEVENGER SCHOOL',
  'ADDLESTONE HEBREW ACADEMY',
  'ADELAIDE EDUCATIONAL CENTER',
  'ADELBROOK-THE LEARNING CENTER OF MANCHESTER',
  'ADELPHIAN JR. ACADEMY',
  'ADELPHOI ACADEMY AT HARTFORD HEIGHTS',
  'ADELPHOI EDUCATION AT ALTOONA',
  'ADELPHOI EDUCATION AT BUTLER',
  'ADELPHOI EDUCATION AT MILLVALE',
  'ADELPHOI EDUCATION PARTIAL HOSPITALIZATION',
  'ADIRONDACK CHRISTIAN SCHOOL',
  'ADMINISTRATOR',
  'ADMIRAL FARRAGUT ACADEMY',
  'ADOLPH SCHREIBER HEBREW ACADEMY',
  'ADONAI ACADEMY',
  'ADULLAM HOUSE CHRISTIAN ACADEMY',
  'ADVANCE ACADEMY AT MATTHEW REARDON CTR FOR AUTISM',
  'ADVANCE CHRISTIAN ACADEMY',
  'ADVANCE LEARNING ACADEMY',
  'ADVANCE LEARNING CENTER',
  'ADVANCED CHRISTIAN ACADEMY',
  'ADVANCED PREPARATORY INTERNATIONAL',
  'ADVENT EPISCOPAL DAY SCHOOL',
  'ADVENT LUTHERAN PRESCHOOL',
  'ADVENT LUTHERAN SCHOOL',
  'ADVENTIST ACADEMY OF SANTA FE',
  'ADVENTIST CHRISTIAN ACADEMY',
  'ADVENTIST CHRISTIAN ELEMENTARY',
  'ADVENTIST CHRISTIAN ELEMENTARY SCHOOL',
  'ADVENTIST CHRISTIAN SCHOOL',
  'ADVENTIST CHRISTIAN SCHOOL OF MARYVILLE',
  'ADVENTIST MALAMA ELEMENTARY SCHOOL',
  'ADVENTIST UNION SCHOOL',
  'ADVENTURE CHRISTIAN ACADEMY',
  'ADVENTURE CHRISTIAN SCHOOL',
  'ADVENTURE ISLAND CHRISTIAN SCHOOL',
  'ADVENTURE MONTESSORI LEARNING CENTER',
  'ADVENTURES IN LEARNING',
  'AEF - WESTHAM ACADEMY',
  'AEF SCHOOLS',
  'AESA PREP ACADEMY',
  'AFA ACADEMY/HOWARD PARK CENTER',
  'AFRICA-CARE ACADEMY',
  'AFTON CHRISTIAN SCHOOL',
  'AG MONTESSORI SCHOOL',
  'AGAPE ACADEMY & CHILD DEVELOPMENT CENTER',
  'AGAPE CHRISTIAN ACADEMY',
  'AGAPE CHRISTIAN HIGH SCHOOL',
  'AGAPE CHRISTIAN SCHOOL',
  'AGAPE CHRISTIAN SCHOOL OF SARASOTA',
  'AGAPE GARDEN MONTESSORI SCHOOL',
  'AGAPE JR ACADEMY',
  'AGAPE LEARNING CENTER',
  'AGAPE MONTESSORI SCHOOL',
  'AGAPE SCHOOL FOR GIRLS',
  'AGAPE SCHOOL OF CHRISTIAN EDUCATION',
  'AGAPELAND CHRISTIAN ACADEMY',
  'AGATHOS CLASSICAL SCHOOL',
  'AGBU MANOOGIAN-DEMIRDJIAN SCHOOL',
  'AGBU VATCHE AND TAMAR MANOUKIAN HIGH SCHOOL',
  'AGETS',
  'AGGIELAND COUNTRY SCHOOL',
  'AGH University of Science and Technology',
  'AGIA SOPHIA ACADEMY',
  'AGNUS DEI CHRISTIAN SCHOOL',
  'AHB COMMUNITY SCHOOL',
  'AHOSKIE CHRISTIAN SCHOOL',
  'AHRENS CHILD CARE CENTER',
  'AHWATUKEE FOOTHILLS MONTESSORI',
  'AI XIN LEARNING CENTER',
  'AICHHORN SCHOOL (THE)',
  'AIDAN MONTESSORI SCHOOL',
  'AIKENS FBC PRESCHOOL',
  'AIM ACADEMY',
  'AISECT University',
  'AISTEDA',
  'AKAD Hochschulen für Berufstätige Fachhochschule Leipzig',
  'AKAULA SCHOOL',
  'AKIBA ACADEMY OF DALLAS',
  'AKIBA-SCHECHTER JEWISH DAY SCHOOL',
  'AKIVA ACADEMY',
  'AKIVA SCHOOL',
  'AKRON FIRST ACADEMY & PRESCHOOL',
  'AKRON MONTESSORI SCHOOL',
  'AKTIV LEARNING ACADEMY',
  'AKWESASNE FREEDOM SCHOOL',
  'AL FATIH ACADEMY',
  'AL HUDA ACADEMY',
  'AL HUDA ISLAMIC SCHOOL',
  'AL IHSAN SCHOOL',
  'AL MADINA SCHOOL OF RICHMOND',
  'AL MADRASA AL ISLAMIYA',
  'AL MOSHEH POSTIVE ED',
  'AL-AMAL SCHOOL',
  'AL-ANSAR ACADEMY',
  'AL-ARQAM ISLAMIC SCHOOL',
  'AL-FALAH ACADEMY',
  'AL-FURQAN ACADEMY',
  'AL-FURQUAN ACADEMY',
  'AL-HADI SCHOOL OF ACCELERATIVE LEARNING',
  'AL-HAMRA ACADEMY',
  'AL-HIKMAH ELEMENTARY SCHOOL',
  'AL-HUDA',
  'AL-HUDA ACADEMY',
  'AL-HUDA ISLAMIC SCHOOL',
  'AL-HUDA SCHOOL',
  'AL-IHSAN ACADEMY',
  'AL-IMAN ACADEMY OF MOBILE',
  'AL-IMAN SCHOOL',
  'AL-MADINAH SCHOOL',
  'AL-MAMOOR SCHOOL',
  'AL-NOOR ACADEMY',
  'AL-NOOR SCHOOL',
  'AL-QALAM ACADEMY',
  'AL-RAHMAH SCHOOL',
  'AL-SALAM DAY SCHOOL',
  'ALABAMA CHRISTIAN ACADEMY',
  'ALABAMA WALDORF SCHOOL',
  'ALAMANCE CHRISTIAN SCHOOL',
  'ALAMEDA CHRISTIAN SCHOOL',
  'ALANE ACADEMY',
  'ALAZHAR SCHOOL',
  'ALBANY ACADEMIES',
  'ALBANY CHRISTIAN SCHOOL',
  'ALBANY HILLS AMISH SCHOOL',
  'ALBANY JCC',
  "ALBEMARLE MONTESSORI CHILDREN'S COMMUNITY",
  "ALBERTO'S DREAM CHILDCARE AND LEARNING CENTER",
  'ALBERTUS MAGNUS HIGH SCHOOL',
  'ALBRIGHT EARLY LEARNING CENTER',
  'ALBUQUERQUE ACADEMY',
  'ALBUQUERQUE CHRISTIAN SCHOOL',
  'ALC MOSAIC (MOSAIC SCHOOL)',
  'ALCUIN MONTESSORI SCHOOL',
  'ALCUIN SCHOOL',
  'ALDAR ACADEMY',
  'ALDEA MONTESSORI',
  'ALDEN MONTESSORI SCHOOL',
  'ALDERBROOK SCHOOL',
  'ALDERSGATE ACADEMY',
  'ALDERSGATE CHILD DEVELOPMENT CENTER',
  'ALDERSGATE CHRISTIAN ACADEMY',
  'ALDERSGATE UMC KINDER PREP',
  'ALDO LEOPOLD NATURE CENTER',
  'ALEESA COATS',
  'ALEF BET MONTESSORI SCHOOL INC.',
  'ALEGRIA MONTESSORI SCHOOL',
  'ALETHEIA CHRISTIAN ACADEMY',
  'ALETHEIA SCHOOL',
  'ALEXANDER AMISH SCHOOL',
  'ALEXANDER DAWSON SCHOOL',
  'ALEXANDER GRAHAM BELL MONTESSORI SCHOOL',
  'ALEXANDER MONTESSORI SCHOOL',
  'ALEXANDER ROBERTSON SCHOOL',
  'ALEXANDER YOUTH NETWORK',
  'ALEXANDER-SMITH ACADEMY',
  'ALEXANDRIA ACADEMY- THE',
  'ALEXANDRIA COUNTRY DAY SCHOOL',
  'ALEXANDRIA MONTESSORI',
  'ALEXANDRIA MONTESSORI SCHOOL',
  'ALEXSANDER ACADEMY',
  'ALFAJER SCHOOL',
  'ALGOMA CHRISTIAN SCHOOL',
  'ALGOOD CHRISTIAN ELEMENTARY',
  'ALHADI SCHOOL',
  'ALHAMBRA HEIGHTS CHALLENGER SCHOOL',
  'ALHUDA ACADEMY',
  'ALICE AND NAHUM LAINER SCHOOL',
  'ALICE BLOUNT ACADEMY',
  'ALICE CHRISTIAN SCHOOL',
  "ALICE'S MONTESSORI LEARNING CENTER",
  'ALIF MUHAMMADS N/A SCHOOL',
  'ALISO VIEJO CHRISTIAN SCHOOL',
  'ALIYAH ACADEMY',
  'ALKIN ACADEMY',
  'ALL ABOUT CHILDREN LEARNING CENTER',
  'ALL ABOUT KIDS MONTESSORI SCHOOL',
  'ALL ANGELS ACADEMY',
  'ALL ARE FRIENDS MONTESSORI',
  'ALL DAY MONTESSORI',
  "ALL GOD'S CHILDREN CHRISTIAN SCHOOL",
  'ALL HALLOWS ACADEMY',
  'ALL HALLOWS HIGH SCHOOL',
  'ALL HOPE CHRISTIAN ACADEMY',
  'ALL MY CHILDREN LEARNING CENTER',
  'ALL NATIONS CHRISTIAN ACADEMY',
  'ALL SAINTS',
  'ALL SAINTS ACADEMY',
  'ALL SAINTS ACADEMY-ST CLOUD CAMPUS',
  'ALL SAINTS CATHOLIC ACADEMY',
  'ALL SAINTS CATHOLIC EAST SCHL',
  'ALL SAINTS CATHOLIC ELEMENTARY',
  'ALL SAINTS CATHOLIC SCHOOL',
  'ALL SAINTS CENTRAL MIDDLE AND HIGH SCHOOL',
  'ALL SAINTS CHURCH SCHOOL',
  'ALL SAINTS ELEMENTARY OF TIPPERARY HILL',
  'ALL SAINTS ELEMENTARY SCHOOL',
  'ALL SAINTS EPISCOPAL DAY SCHOOL',
  'ALL SAINTS EPISCOPAL SCHOOL',
  'ALL SAINTS PARISH SCHOOL',
  'ALL SAINTS REGIONAL CATHOLIC SCHOOL',
  'ALL SAINTS SCHOOL',
  'ALL SAINTS SCHOOL SPOKANE',
  "ALL SAINTS' EPISCOPAL CHURCH",
  "ALL SAINTS' EPISCOPAL DAY SCHOOL",
  "ALL SAINTS' EPISCOPAL SCHOOL",
  'ALL SEASONS CHILDRENS LEARNING CENTER',
  'ALL SOULS CATHOLIC SCHOOL',
  'ALL SOULS ELEMENTARY SCHOOL',
  'ALL START ACADEMY',
  'ALL THE CHILDREN OF THE WORLD ACADEMY',
  'ALLAN COTT SCHOOL',
  'ALLEGANY BOYS CAMP',
  'ALLEGHENY CHRISTIAN SCHOOL',
  'ALLEGHENY VALLEY CHRISTIAN SCHOOL',
  'ALLELUIA COMMUNITY SCHOOL',
  'ALLEMAN HIGH SCHOOL',
  'ALLEN ACADEMY',
  'ALLEN CHRISTIAN DAY SCHOOL',
  'ALLENDALE CHRISTIAN SCHOOL',
  'ALLENDALE COLUMBIA SCHOOL',
  'ALLENTOW CENTRAL CATHOLIC HIGH SCHOOL.',
  'ALLISON ACADEMY',
  'ALLISON GAP SCHOOL',
  'ALMA HEIGHTS CHRISTIAN SCHOOL',
  'ALMADEN COUNTRY SCHOOL',
  'ALMANSOR ACADEMY (INSTITUTE FOR REDESIGN OF LEARNI',
  'ALMONDALE ACADEMY',
  'ALPERT FAMILY ALEPH BET JEWISH DAY SCHOOL',
  'ALPHA & OMEGA CHRISTIAN ACADEMY',
  'ALPHA & OMEGA CHRISTIAN SCHOOL',
  'ALPHA AND OMEGA ACADEMY',
  'ALPHA BITS LEARNING CENTER II',
  'ALPHA EDUCATIONAL LEARNING CENTER',
  'ALPHA HOUSE NURSERY & KINDERGARTEN',
  'ALPHA LEARNING ACADEMY',
  'ALPHA LERANING',
  'ALPHA MONTESSORI SCHOOL',
  'ALPHA OMEGA ACADEMY',
  'ALPHA-KIDZ INTERNATIONAL',
  'ALPHA-SHEN PRESCHOOL & KINDERGARTEN',
  'ALPHABETLAND',
  'ALPHARETTA CHRISTIAN ACADEMY',
  'ALPHARETTA INTERNATIONAL ACADEMY',
  'ALPHONSUS ACADEMY & CENTER FOR THE ARTS',
  'ALPINE ACADEMY',
  'ALPINE CHRISTIAN ACADEMY',
  'ALPINE CHRISTIAN SCHOOL',
  'ALPINE LEARNING GROUP',
  'ALPINE MEADOW SCHOOL',
  'ALPINE MONTESSORI - SPARTA',
  'ALPINE MONTESSORI SCHOOL',
  'ALPINE VALLEY SCHOOL',
  'ALT SCHOOL',
  'ALTA INDEPENDENT',
  'ALTA LOMA CHRISTIAN SCHOOL',
  'ALTA VISTA MONTESSORI SCHOOL',
  'ALTA VISTA SCHOOL',
  'ALTAMONT LUTHERAN INTERPARISH SCHOOL',
  'ALTAMONTE CHRISTIAN SCHOOL',
  'ALTAPASS CHRISTIAN SCHOOL',
  'ALTERCREST DAY TREATMENT',
  'ALTERNATIVE EDUCATIONAL SERVICES',
  'ALTERNATIVE PATHS TRAINING SCHOOL - ALEXANDRIA',
  'ALTERNATIVE PATHS TRAINING SCHOOL -STAFF/FRED',
  'ALTERNATIVE SCHOOL FOR MATH & SCIENCE',
  'ALTERNATIVE YOUTH ACTIVITIES',
  'ALTHOFF CATHOLIC HIGH SCHOOL',
  'ALTIAR SCHOOL',
  'ALTO CHRISTIAN ACADEMY',
  'ALTO INTERNATIONAL SCHOOL',
  'ALTONA CHRISTIAN SCHOOL',
  'ALTURAS MENNONITE SCHOOL',
  'ALTUS ACADEMY',
  'ALTUS CHRISTIAN ACADEMY',
  'ALVARADO SCHOOL',
  'ALVERNO HEIGHTS ACADEMY',
  'ALZAR SCHOOL',
  'AMARE MONTESSORI',
  'AMARILLO MONTESSORI ACADEMY',
  'AMAZING GRACE ACADEMY',
  'AMAZING GRACE CHRISTIAN SCHOOL',
  'AMAZING KIDZ CHRISTIAN ACADEMY',
  'AMAZING LEARNING ACADEMY',
  'AMAZING LEARNING ACADEMY II',
  'AMBASSADOR ACADEMY',
  'AMBASSADOR CHRISTIAN ACADEMY',
  'AMBASSADOR CHRISTIAN DAYCARE & SCHOOL',
  'AMBASSADOR HIGH SCHOOL',
  'AMBERWOOD ACADEMY',
  'AMBLESIDE SCHOOL',
  'AMBLESIDE SCHOOL OF FREDERICKSBURG',
  'AMBLESIDE SCHOOL OF OCALA',
  'AMBLESIDE SCHOOL OF SAN ANGELO',
  'AMBROSE AMISH PAROCHIAL SCHOOL',
  'AMEEN PEOPLE MONTESSORI',
  'AMEGO',
  'AMELIA ACADEMY',
  'AMELIA ISLAND MONTESSORI SCHOOL',
  'AMERIBELGE CENTER FOR EDUCATION',
  "AMERICA'S CHRISTIAN FUTURE PRESCHOOL ELEMENTARY",
  "AMERICA'S LITTLE LEADERS",
  'AMERICAN ACADEMY OF PINECREST',
  'AMERICAN CHRISTIAN ACADEMY',
  'AMERICAN CHRISTIAN MILITARY ACADEMY',
  'AMERICAN CHRISTIAN SCHOOL',
  'AMERICAN CHRISTIAN SCHOOL & ART CENTER',
  'AMERICAN ENGLISH INSTITUTE',
  'AMERICAN HEBREW ACADEMY',
  'AMERICAN HERITAGE',
  'AMERICAN HERITAGE ACADEMY',
  'AMERICAN HERITAGE OF SOUTH JORDAN',
  'AMERICAN HERITAGE SCHOOL',
  'AMERICAN HERITAGE SCHOOL - BOCA/DELRAY',
  'AMERICAN HIGH SCHOOL ACADEMY INC.',
  'AMERICAN HIGH SCHOOL LLC',
  'AMERICAN INDIAN CHRISTIAN MISSON SCHOOL',
  'AMERICAN MARTYRS SCHOOL',
  'AMERICAN MASTER ACADEMY',
  'AMERICAN MONTESSORI ACADEMY',
  'AMERICAN MONTESSORI CAMPUS',
  'AMERICAN MONTESSORI PRESCHOOL & ELEMENTARY',
  'AMERICAN PREPARATORY ACADEMY',
  'AMERICAN RENAISSANCE ACADEMY',
  'AMERICAN SCHOOL',
  'AMERICAN SCHOOL FOR THE DEAF',
  'AMERICAN SCHOOL OF LEADERSHIP',
  'AMERICAN UNIVERSITY PREPARATORY SCHOOL',
  'AMERICAN WORLDWIDE ACADEMY',
  'AMERICAN YOUTH ACADEMY',
  'AMERICAS CHILD MONTESSORI',
  'AMERIMONT ACADEMY',
  'AMES CHRISTIAN SCHOOL',
  'AMHERST MONTESSORI SCHOOL',
  'AMISH PAROCHIAL SCHOOL C/O DANNIE BURKHOLDER',
  'AMISH PAROCHIAL SCHOOP',
  'AMISH PRIVATE 3',
  'AMISH PRIVATE SCHOOL #1',
  'AMISH ROAD SCHOOL',
  'AMISH SCHOOL #1',
  'AMITE CHRISTIAN SCHOOL',
  'AMITE SCHOOL CENTER',
  'AMORY CHRISTIAN ACADEMY',
  'AMOS AND CELIA HEILICHER MINNEAPOLIS JEWISH DAY SC',
  'AMOS S ESH',
  "AMPLIFY CHILDREN'S ACADEMY",
  'AMY MONTESSORI SCHOOL',
  'AN-NOOR ACADEMY',
  'AN-NOOR SCHOOL',
  'ANAHEIM HILLS MONTESSORI',
  'ANAMI MONTESSORI SCHOOL',
  'ANANDA LIVING WISDOM SCHOOL',
  'ANASTASIS ACADEMY',
  'ANCHOR ACADEMY',
  'ANCHOR BAPTIST ACADEMY',
  'ANCHOR CHRISTIAN ACADEMY',
  'ANCHOR CHRISTIAN SCHOOL',
  'ANCHOR CHRISTIAN SCHOOL/ANCHOR MILITARY MINISTRIES',
  'ANCHOR EDUCATION',
  'ANCHOR LUTHERAN SCHOOL',
  'ANCHORAGE CHRISTIAN SCHOOLS',
  'ANCHORAGE JUNIOR ACADEMY',
  'ANCHORAGE MONTESSORI SCHOOL',
  'ANCHORAGE WALDORF SCHOOL',
  'ANCHORED CHRISTIAN SCHOOL',
  'ANCHORPOINT CHRISTIAN SCHOOL',
  'ANCILLAE-ASSUMPTA ACADEMY',
  'ANCONA MONTESSORI',
  'ANCONA MONTESSORI SCHOOL-DREXEL HILL',
  'ANCONA SCHOOL',
  'ANDALUSIA SCHOOL',
  'ANDERSON ACADEMY',
  'ANDERSON ACADEMY OF MATHEMATICS AND SCIENCE',
  'ANDERSON CENTER FOR AUTISM',
  'ANDERSON CHRISTIAN SCHOOL',
  'ANDERSON PRIVATE SCHOOL FOR THE GIFTED & TALENTED',
  'ANDERSON SCHOOL',
  'ANDERSON VALLEY SCHOOL',
  'ANDOVER SCHOOL OF MONTESSORI',
  'ANDREAN HIGH SCHOOL',
  'ANDREW JACKSON ACADEMY',
  'ANDREW STAUFFER, TREASURER',
  'ANDREWS ACADEMY',
  'ANDROSCOGGIN LEARNING & TRANSITION CENTER',
  'ANGELA PRESCHOOL & KINDERGARTEN',
  'ANGELA PRESCHOOL KINDERGARTEN',
  "ANGELA'S WORKSHOP",
  'ANGELDEER LEARNING CENTER',
  'ANGELL JCC',
  'ANGELO CATHOLIC SCHOOL',
  'ANGELS ACADEMY',
  'ANGELS ACADEMY LLC',
  'ANGELS CHRISTIAN ACADEMY OF POMPANO BEACH',
  'ANGELS LOVE CARE',
  'ANGELS MONTESSORI PRESCHOOL',
  'ANGELS REACH ACADEMY FOR DIVERGENT LEARNERS',
  'ANGELUS ACADEMY',
  'ANGLETON CHRISTIAN SCHOOL',
  'ANKENY CHRISTIAN ACADEMY',
  'ANN ARBOR ACADEMY',
  'ANN ARBOR ADVENTIST ELEMENTARY',
  'ANN ARBOR CHRISTIAN SCHOOL',
  'ANNA B LAWTHER ACADEMY',
  'ANNA JULIA COOPER EPISCOPAL SCHOOL',
  'ANNAPOLIS AREA CHRISTIAN',
  'ANNAPOLIS AREA CHRISTIAN SCHL',
  'ANNAPOLIS AREA CHRISTIAN SCHOOL',
  'ANNE CARLSEN CENTER',
  "ANNE MCLAUGHLIN'S CHILD CARE",
  'ANNE SULLIVAN PRESCHOOL & KINDERGARTEN',
  "ANNELIESE'S SCHOOLS",
  'ANNIE WRIGHT SCHOOLS',
  "ANNIE'S KINDERGARTEN AND DAY CARE",
  'ANNOOR ACADEMY OF FORT SMITH',
  'ANNOOR ISLAMIC SCHOOL',
  'ANNUNCIATA SCHOOL',
  'ANNUNCIATION',
  'ANNUNCIATION CATHOLIC ACADEMY',
  'ANNUNCIATION CATHOLIC SCHOOL',
  'ANNUNCIATION CATHOLIC SCHOOLNTARY SCHOOL',
  'ANNUNCIATION OF THE BVM SCHOOL',
  'ANNUNCIATION ORTHODOX SCHOOL',
  'ANNUNCIATION SCHOOL',
  'ANNUNZIATA SCHOOL',
  'ANNUR ISLAMIC SCHOOL',
  'ANOINTED WORD CHRISTIAN SCHOOLS INTERNATIONAL',
  'ANOINTED WORD FAITH ACADEMY',
  'ANOKA ADVENTIST CHRISTIAN SCHOOL',
  'ANOVA CENTER FOR EDUCATION',
  'ANOVA CENTER FOR EDUCATION SONOMA COUNTY',
  'ANSHEI LUBAVITCH DAYCARE CENTER',
  'ANTELOPE CHRISTIAN ACADEMY',
  'ANTHEM CHRISTIAN SCHOOL',
  'ANTIOCH BAPTIST CHRISTIAN ACADEMY',
  'ANTIOCH CHRISTIAN ACADEMY',
  'ANTIOCH SCHOOL',
  'ANTRIM MENNONITE SCHOOL',
  'ANZA VALLEY CHRISTIAN SCHOOL',
  'APOPKA CHRISTIAN ACADEMY',
  'APOSTLES LUTHERAN SCHOOL',
  'APOSTOLIC BIBLE INSTITUTE CHRISTIAN ACADEMY',
  'APOSTOLIC CHRISTIAN ACADEMY',
  'APOSTOLIC CHRISTIAN SCHOOL',
  'APOSTOLIC INSTIT OF MISSOURI',
  'APOSTOLIC LIGHTHOUSE CHRISTIAN SCHOOL',
  'APOSTOLIC PRAISE CHRISTIAN ACADEMY',
  'APOSTOLIC REVIVAL CENTER CHRISTIAN SCHOOL',
  'APOSTOLIC WORSHOP CHILD DEVELOPMENT, INC.',
  'APPALACHIAN CHRISTIAN ACADEMY',
  'APPLE BLOSSOMS CHILD CENTER',
  'APPLE GROVE',
  'APPLE GROVE SCHOOL',
  'APPLE HILL',
  'APPLE MONTESSORI OF HOBOKEN',
  'APPLE MONTESSORI OF METUCHEN',
  'APPLE MONTESSORI OF WAYNE',
  'APPLE MONTESSORI SCHOOL',
  'APPLE MONTESSORI SCHOOL OF EDGEWATER',
  'APPLE MONTESSORI SCHOOL OF MORRIS PLAINS',
  'APPLE MONTESSORI SCHOOL OF OAKLAND',
  'APPLE MONTESSORI SCHOOL OF RANDOLPH',
  'APPLE MONTESSORI SCHOOLS - HOBOKEN',
  'APPLE MONTESSORISCHOOL OF MAHWAH',
  'APPLE ORCHARD SCHOOL',
  'APPLE RIDGE MONTESSORI SCHOOL',
  'APPLE TREE FAITH COMM CHRISTIAN ACAD-DC CENTER',
  'APPLE TREE LEARNING CENTER',
  'APPLE TREE MONTESSORI SCHOOL',
  'APPLE TREE SCHOOL',
  'APPLE VALLEY CHRISTIAN SCHOOL',
  'APPLEGATE ADVENTIST JR ACADEMY',
  'APPLES & BOOKS LEARNING CENTER',
  'APPLES OF GOLD EDUCATIONAL CENTER',
  'APPLESEED ACADEMY',
  'APPLESEED ALMADEN MONTESSORI SCHOOL',
  'APPLESEED MONTESSORI SCHOOL',
  'APPLESEED MONTESSORI SCHOOL - WARNER',
  'APPLETREE CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'APPLETREE MONTESSORI',
  'APPLETREE SCHOOL',
  'APPLETREE SCHOOL II',
  'APPLEWILD SCHOOL',
  'APPLEWOOD CHRISTIAN SCHOOL',
  'APPLIED SCHOLASTICS ONLINE ACADEMY',
  'APPLING CHRISTIAN ACADEMY',
  'APPOMATTOX CHRISTIAN ACADEMY',
  'APPRENDE PRIVATE SCHOOL',
  'AQUIDNECK ISLAND CHRISTIAN ACADEMY',
  'AQUIN CATHOLIC ELEMENTARY SCHOOL',
  'AQUIN CATHOLIC JR SR HIGH SCHOOL',
  'AQUIN ELEMENTARY SCHOOL',
  'AQUINAS ACADEMY',
  'AQUINAS CATHOLIC COMMUNITY SCHOOL',
  'AQUINAS CATHOLIC SCHOOLS',
  'AQUINAS HIGH SCHOOL',
  'AQUINAS INSTITUTE',
  'AQUINAS MIDDLE HIGH SCHOOL',
  'ARBOR BAY SCHOOL',
  'ARBOR MONTISORRI SCHOOL',
  'ARBOR SCHOOL OF ARTS & SCIENCES',
  'ARBOR SCHOOL OF CENTA',
  'ARBOR SCHOOLS',
  'ARBOR VIEW MONTESSORI',
  'ARBORBROOK CHRISTIAN ACADEMY',
  'ARBORCREEK MONTESSORI SCHOOL',
  'ARBORLAND MONTESSORI-HUGHES CAMPUS',
  'ARC ORANGE COUNTY CHAPTER',
  "ARCADIA CHILDREN'S EDUCATIONAL CENTER",
  'ARCADIA CHRISTIAN SCHOOL',
  'ARCADIA FIRST BAPTIST CHRISTIAN SCHOOL',
  'ARCADIA LEARNING CENTER',
  'ARCADIA MONTESSORI SCHOOL',
  'ARCATA CHRISTIAN SCHOOL',
  'ARCH BISHOP MOELLER HIGH SCHOOL',
  'ARCHBISHOP ALTER HIGH SCHOOL',
  'ARCHBISHOP BERGAN CATHOLIC SCHOOL',
  'ARCHBISHOP BORDERS SCHOOL',
  'ARCHBISHOP CHAPELLE HIGH SCHOOL',
  'ARCHBISHOP CURLEY HIGH SCHOOL',
  'ARCHBISHOP HANNA HIGH SCHOOL',
  'ARCHBISHOP HANNAN HIGH SCHOOL',
  'ARCHBISHOP HOBAN HIGH SCHOOL',
  'ARCHBISHOP LYKE-ST TIMOTHY CAMPUS',
  'ARCHBISHOP MCCARTHY HIGH SCHOOL',
  'ARCHBISHOP MCNICHOLAS HIGH SCHOOL',
  'ARCHBISHOP MITTY HIGH SCHOOL',
  'ARCHBISHOP MOLLOY HIGH SCHOOL',
  'ARCHBISHOP MURPHY HIGH SCHOOL',
  'ARCHBISHOP NEALE SCHOOL',
  'ARCHBISHOP RIORDAN HIGH SCHOOL',
  'ARCHBISHOP RUMMEL HIGH SCHOOL',
  'ARCHBISHOP RYAN ACADEMY FOR THE DEAF',
  'ARCHBISHOP RYAN HIGH SCHOOL',
  'ARCHBISHOP SHAW HIGH SCHOOL',
  'ARCHBISHOP SPALDING HIGH SCHOOL',
  'ARCHBISHOP STEPINAC HIGH SCHOOL',
  'ARCHBISHOP WILLIAMS HIGH SCHOOL',
  'ARCHBISHOP WOOD HIGH SCHOOL',
  'ARCHER SCHOOL FOR GIRLS',
  'ARCHES ACADEMY',
  'ARCHWAY',
  'ARDEN CAHILL ACADEMY',
  'ARDEN CHRISTIAN SCHOOL',
  'ARDMORE ADVENTIST ACADEMY',
  'ARENDELL PARROTT ACADEMY',
  'ARETE PREPARATORY ACADEMY',
  'ARETE PREPATORY SCHOOL',
  'ARI GUIRAGOS MINASSIAN ARMENIAN SCHOOL',
  'ARISTA PREP NURSERY SCHOOL',
  'ARIZONA CULTURAL ACADEMY & COLLEGE PREP',
  'ARIZONA INTERNATIONAL ACADEMY',
  'ARIZONA LUTHERAN ACADEMY',
  'ARK CHRISTIAN ACADEMY',
  'ARK OF SAFETY CHRISTIAN ACADEMY',
  'ARLINGTON ACADEMY OF MIAMI LLC',
  'ARLINGTON BAPTIST SCHOOL',
  'ARLINGTON CATHOLIC HIGH SCHOOL',
  'ARLINGTON CHRISTIAN ACADEMY',
  'ARLINGTON CHRISTIAN SCHOOL',
  'ARLINGTON FAITH ACADEMY',
  'ARLINGTON HEIGHTS CHRISTIAN SCHOOL',
  'ARLINGTON KINDERGARTEN OF JACKSONVILLE, INC',
  'ARLINGTON MONTESSORI HOUSE',
  'ARLINGTON MONTESSORI SCHOOL',
  'ARLINGTON SCHOOL',
  'ARLYN DAY SCHOOL',
  'ARMA DEI ACADEMY',
  'ARMAND BAYOU MONTESSORI',
  'ARMBRUST CHRISTIAN ACADEMY',
  'ARMENIAN MESROBIAN SCHOOL',
  'ARMENIAN SISTERS ACADEMY',
  'ARMONA UNION ACADEMY',
  'ARMSTRONG VALLEY CHRISTIAN SCHOOL',
  'ARMY & NAVY ACADEMY',
  'ARNOLD CHRISTIAN ACADEMY',
  'AROUND THE WORLD CHILDREN CENTER',
  'ARRIS MONTESSORI',
  'ARROW ACADEMY',
  'ARROW CENTER FOR EDUCATION TANGRAM',
  'ARROW MONTESSORI SCHOOL OF SAN DIMAS',
  'ARROWHEAD CHRISTIAN ACADEMY',
  'ARROWHEAD MONTESSORI',
  'ARROYO GRANDE MONTESSORI SCHOOL',
  'ARROYO PACIFIC ACADEMY',
  'ARRUPE JESUIT HIGH SCHOOL',
  "ART'S LEARNING ACADEMY",
  'ARTHUR I MEYER JEWISH ACADEMY',
  'ARTHUR MORGAN SCHOOL',
  'ARTS & IDEAS SUDBURY SCHOOL',
  'ARTS THEREAFTER CHRISTIAN SCHOOL',
  'ARTSTECH',
  'ARUNDEL CHRISTIAN SCHOOL',
  'ARYA Institute of Engineering & Technology',
  'AS YOU ARE PRESCHOOL',
  'AS-SABEEL ACADEMY',
  'AS-SAFA INSTITUTE/AS-SAFA ACADEMY',
  'ASA College',
  'ASBURY CHILD DEVELOPMENT CENTER',
  'ASBURY KINDERGARTEN & PRESCHOOL',
  'ASCENSION ACADEMY',
  'ASCENSION CATHOLIC DIOCESAN REGIONAL SCHOOL',
  'ASCENSION CATHOLIC SCHOOL',
  'ASCENSION ELEMENTARY SCHOOL',
  'ASCENSION EPISCOPAL KINDERGARTEN',
  'ASCENSION EPISCOPAL SCHOOL',
  'ASCENSION LUTHERAN SCHOOL',
  'ASCENSION OF OUR LORD CATHOLIC SCHOOL',
  'ASCENSION SCHOOL',
  'ASCENSON',
  'ASCENT SCHOOL FOR INDIVIDUALS WITH AUTISM',
  'ASELTINE SCHOOL',
  'ASHBROOK INDEPENDENT SCHOOL',
  'ASHCREEK RANCH ACADEMY',
  'ASHERY',
  'ASHEVILLE CATHOLIC SCHOOL',
  'ASHEVILLE CHRISTIAN ACADEMY',
  'ASHEVILLE MONTESSORI SCHOOL',
  'ASHEVILLE SCHOOL',
  'ASHEVILLE-PISGAH CHRISTIAN SCHOOL',
  'ASHLAND MONTESSORI SCHOOL',
  'ASHLEY ACADEMY',
  'ASHLEY HALL SCHOOL',
  'ASHVILLE SCHOOL',
  'ASHWOOD WALDORF SCHOOL',
  'ASIA PACIFIC LANGUAGE SCHOOL',
  'ASPEN ACADEMY',
  'ASPIRATIONS SCHOOL OF LEARNING',
  'ASPIRE OF WNY/CTR FOR LEARNING',
  'ASSEMBLY CHRISTIAN SCHOOL',
  'ASSEMBLY OF FAITH CHR. SCHOOL',
  'ASSETS SCHOOL',
  'ASSOCIATED BETH RIVKAH SCHOOLS',
  'ASSUMPTION ACADEMY',
  'ASSUMPTION BVM ELEMENTARY SCHOOL',
  'ASSUMPTION BVM SCHOOL',
  'ASSUMPTION CATHOLIC SCHOOL',
  'ASSUMPTION EARLY CHILDHOOD CENTER',
  'ASSUMPTION HIGH SCHOOL',
  'ASSUMPTION MIDDLE SCHOOL',
  'ASSUMPTION OF THE BVM ELEMENTARY SCHOOL',
  'ASSUMPTION OF THE BVM SCHOOL',
  'ASSUMPTION PARISH SCHOOL',
  'ASSUMPTION SCHOOL',
  'ASSUMPTION-ST BRIDGET SCHOOL',
  'ASSURANCE ACADEMY CHRISTIAN SCHOOL',
  'ASTORIA LUTHERAN SCHOOL',
  'ATASCOCITA MONTESSORI SCHOOL',
  'ATERES BAIS YAAKOV',
  'ATERES BAIS YAAKOV ACADEMY OF ROCKLAND',
  'ATERES TZIPORA',
  'ATHENA ACADEMY',
  'ATHENA DAY SCHOOL',
  'ATHENA MONTESSORI ACADEMY',
  'ATHENS ACADEMY',
  'ATHENS BIBLE SCHOOL',
  'ATHENS CHRISTIAN ACADEMY',
  'ATHENS CHRISTIAN PREPARATORY ACADEMY',
  'ATHENS MONTESSORI SCHOOL',
  'ATLANTA ADVENTIST ACADEMY',
  'ATLANTA ADVENTIST INTERNATIONAL SCHOOL',
  'ATLANTA COUNTRY DAY SCHOOL',
  'ATLANTA GIRLS SCHOOL',
  'ATLANTA INTERNATIONAL SCHOOL',
  'ATLANTA JEWISH ACADEMY',
  'ATLANTA JEWISH ACADEMY UPPER SCHOOL',
  'ATLANTA MONTESSORI INTERNATIONAL SCHOOL',
  'ATLANTA NORTH SCHOOL OF SEVENTH-DAY ADVENTISTS',
  'ATLANTA SPEECH SCHOOL',
  'ATLANTA TRILINGUAL ACADEMY',
  'ATLANTA YOUTH ACADEMY',
  'ATLANTIC AMISH SCHOOL',
  'ATLANTIC CHRISTIAN ACADEMY OF THE PALM BEAC',
  'ATLANTIC CHRISTIAN SCHOOL',
  'ATLANTIC SHORES CHRISTIAN SCHOOL',
  'ATLANTIS ACADEMY',
  'ATLANTIS ACADEMY PALM BEACHES',
  'ATLANTIS PREP SCHOOL',
  'ATLAS INTERNATIONAL SCHOOL',
  'ATLEE CHRISTIAN ACADEMY',
  'ATMORE CHRISTIAN SCHOOL',
  'ATONEMENT ACADEMY',
  'ATONEMENT CHRISTIAN DAY SCHOOL',
  'ATONEMENT LUTHERAN',
  'ATONEMENT LUTHERAN SCHOOL',
  'ATRIUM SCHOOL',
  'ATT-P TACH SPECIAL EDUCATION PROG',
  'ATTAIN CHILDHOOD DEVELOPMENT CENTER',
  'ATTEN. SCHOOL TREASURER',
  'ATTN: TERESA DEBARSCHMIDT',
  'AUBURN ADVENTIST ACADEMY',
  'AUBURN AREA CATHOLIC SCHOOL',
  'AUBURN CLASSICAL ACADEMY INC.',
  'AUBURN HILLS CHRISTIAN SCHOOL',
  'AUBURN HILLS SCHOOL',
  'AUBURN MONTESSORI SCHOOL',
  'AUCILLA CHRISTIAN ACADEMY',
  'AUCOCISCO SCHOOL',
  'AUGSBURG PARK MONTESSORI SCHOOL',
  'AUGUSTA AMISH PAROCHIAL SCHOOLS',
  'AUGUSTA CHRISTIAN',
  'AUGUSTA FIRST SEVENTH DAY ADVENTIST SCHOOL',
  'AUGUSTA MENNONITE SCHOOL',
  'AUGUSTA PREPARATORY DAY SCHOOL',
  'AUGUSTINE CLASSICAL ACADEMY',
  'AUGUSTINE SCHOOL',
  'AUGUSTINIAN ACADEMY',
  'AUKELA CHRISTIAN MILITARY ACADEMY',
  'AULDERN ACADEMY',
  'AULTMAN BAPTIST CHURCH ACADEMY',
  'AUNTIE B PRESCHOOL & KINDERGARTEN',
  "AUNTY JEAN'S PL INC D/B/A PACCOR KID PRESCHOOL",
  'AURARIA EARLY LEARNING CENTER',
  'AURORA CENTRAL CATHOLIC HIGH SCHOOL',
  'AURORA CHRISTIAN ACADEMY K4-12',
  'AURORA DAY SCHOOL',
  'AURORA MONTESSORI SCHOOL',
  'AURORA SCHOOL',
  'AURORA TUTORING SCHOOL & RESOURCE CENTER',
  'AURORA WALDORF SCHOOL',
  'AUSOME ACADEMY',
  'AUSTIN CAREER EDUCATION CENTER',
  'AUSTIN CATHOLIC ACADEMY HIGH SCHOOL',
  "AUSTIN CHILDREN'S MONTESSORI",
  'AUSTIN CHRISTIAN VOCATIONAL ACADEMY',
  'AUSTIN CLASSICAL SCHOOL',
  'AUSTIN INTERNATIONAL SCHOOL',
  'AUSTIN JEWISH ACADEMY',
  'AUSTIN MONTESSORI SCHOOL INC',
  'AUSTIN PEACE ACADEMY',
  'AUSTIN PREPARATORY SCHOOL',
  'AUTAUGA ACADEMY',
  'AUTISM ACADEMY FOR ED & DEVELOPMENT',
  'AUTISM SERVICES',
  'AUTISM TREATMENT CENTER DAY SCHOOL PROGRAM',
  'AVALON SCHOOL',
  'AVANT SCHOOL OF EXCELLENCE',
  'AVANT SCHOOL OF EXCELLENCE INC',
  'AVE MARIA ACADEMY',
  'AVE MARIA CATHOLIC ACADEMY',
  'AVE MARIA CATHOLIC SCHOOL',
  'AVE MARIA MONTESSORI',
  'AVENTURA LEARNING CENTER',
  'AVENUES THE WORLD SCHOOL',
  'AVERROES HIGH SCHOOL',
  'AVERY COONLEY SCHOOL',
  'AVICENNA ACADEMY',
  'AVILLA CHRISTIAN ACADEMY',
  'AVON GROVE NAZARENE ACADEMY',
  'AVON MONTESSORI ACADEMY',
  'AVON OLD FARMS SCHOOL',
  'AVONDALE HOUSE',
  'AVONDALE SDA ELEMENTARY SCHOOL',
  'AWAKENING SEED SCHOOL',
  'AXEVILLE SCHOOL',
  'AYLETT COUNTRY DAY SCHOOL',
  'AZALEA MOUNTAIN SCHOOL',
  'AZALEA PARK BAPTIST SCHOOL',
  'AZLE CHRISTIAN SCHOOLS',
  'Aa Rather School',
  'Aaec - Paradise Valley',
  'Aaec - Smcc Campus',
  'Aalborg Business College',
  'Aalborg University',
  'Aaniiih Nakoda College',
  'Aarhus School of Architecture',
  'Aarhus School of Business',
  'Aarhus Technical College',
  'Aarhus University',
  'Aaron Cohn Middle School',
  'Aaron Cohn Regional Youth Detention Center',
  'Aaron Decker School',
  'Aaron Parker El',
  'Ab Davis Steam High School',
  'Ab Harrison Int',
  'Aba Autism Program',
  'Aba Nichols',
  'Aba Second Hill Lane',
  'Aba Whitney',
  'Abai State University',
  'Abant Izzet Baysal University',
  'Abasyn University Peshawar',
  "Abb's Valley-boissevain Elementary",
  'Abbett El',
  'Abbeville County Career Center',
  'Abbeville Elementary School',
  'Abbeville High',
  'Abbeville High School',
  'Abbey Lane School',
  'Abbot Elementary',
  'Abbot School',
  'Abbot-downing School',
  'Abbotsford Elementary',
  'Abbotsford Middle/senior High',
  'Abbott House Elementary - 06',
  'Abbott House High School - 07',
  'Abbott Loop Elementary',
  'Abbott Memorial',
  'Abbott Middle',
  'Abbott Middle School',
  'Abbott School',
  'Abbotts Creek Elementary',
  'Abbotts Hill Elementary School',
  'Abbottston Elementary',
  'Abby Kelley Foster Charter Public School',
  'Abby Reinke Elementary',
  'Abc / Forbes School',
  'Abc / Torringford',
  'Abc Classroom',
  'Abc Elementary',
  'Abc Preschool',
  'Abc Secondary (alternative)',
  'Abdullah Gul University',
  'Abdus Salam international centre for theoretical physics',
  'Abe Diploma Program',
  'Abe Hubert Elementary School',
  'Abe Lincoln Elem School',
  'Abelardo Diaz Alfaro',
  'Abelardo Diaz Alfaro (int. Pinas)',
  'Abelardo Diaz Morales',
  'Abelardo Martinez Otero',
  'Abell J H',
  'Aberdeen Alternative Learning Center- 12',
  'Aberdeen Elementary',
  'Aberdeen Elementary School',
  'Aberdeen High',
  'Aberdeen High School',
  'Aberdeen Middle',
  'Aberdeen Middle School',
  'Aberdeen Primary',
  'Abernathy Daep',
  'Abernathy El',
  'Abernathy H S',
  'Abernathy Middle',
  'Abernethy Elementary School',
  'Abess Park Elementary School',
  'Abia Judd Elementary School',
  'Abia State Polytechnic',
  'Abia State University',
  'Abigail Adams Middle School',
  'Abilene Christian University',
  'Abilene Elem',
  'Abilene H S',
  'Abilene High School',
  'Abilene Middle School',
  'Abilities In Motion (aim) Program',
  'Abingdon Elementary',
  'Abingdon High',
  'Abingdon-avon High Sch',
  'Abingdon-avon Middle Sch',
  'Abington Avenue School',
  'Abington Early Education Program',
  'Abington Heights Hs',
  'Abington Heights Ms',
  'Abington High',
  'Abington Jhs',
  'Abington Middle School',
  'Abington Shs',
  'Abiquiu Elementary',
  'Abita Springs Elementary School',
  'Abita Springs Middle School',
  'Able',
  'Able Charter School 6th-8th',
  'Able Charter School 9th-12th',
  'Able Program',
  'Abner Creek Academy',
  'Abner Gibbs',
  'Abney Elementary Early Childhood Center',
  'Abo Akademi University',
  'Aboite Elementary School',
  'Aborn',
  'About Face Jjaep',
  'About Face Program El',
  'Abq Charter Academy',
  'Abq School Of Excellence',
  'Abq Sign Language Academy',
  'Abraham Clark High School',
  'Abraham Kazen Middle',
  'Abraham Lincoln',
  'Abraham Lincoln Alternative',
  'Abraham Lincoln Continuation',
  'Abraham Lincoln El Sch',
  'Abraham Lincoln Elem School',
  'Abraham Lincoln Elementary',
  'Abraham Lincoln Elementary School',
  'Abraham Lincoln High',
  'Abraham Lincoln High School',
  'Abraham Lincoln Middle',
  'Abraham Lincoln Middle School',
  'Abraham Lincoln Middle School No. 4',
  'Abraham Lincoln School',
  'Abraham Lincoln School #6',
  'Abraham Lincoln School No. 14',
  'Abraham Lincoln Senior High',
  'Abraham Lincoln Traditional School',
  'Abraham Mallinson Elementary',
  'Abraham Pierson School',
  'Abraham Wing School',
  'Abram Lansing School',
  'Abrams Elementary',
  'Abrams Elementary School',
  'Abramson Sci Academy',
  'Abraxas Continuation High',
  'Abraxis Charter',
  'Absarokee 6-8',
  'Absarokee High School',
  'Absarokee School',
  'Absegami High School',
  'Abu Dhabi University',
  'Abubakar Tafawa Balewa University',
  'Abylai Khan University',
  'Ac Edgerton Elementary School',
  'Ac Moore Elementary School',
  'Ac Prep Elementary',
  'Acacia Elementary',
  'Acacia Elementary Charter',
  'Acacia Elementary School',
  'Acacia Middle',
  'Acacia Middle Charter',
  'Acad For College Prep And Career Exp',
  'Acad For Lifelong Learning',
  'Acad For Multilingual Arts And Sci At Mervyn M. Dymally High',
  'Acad Of Envt Sci/math Elem',
  'Acad Of Envt Sci/math Middle',
  'Academia Ana Marie Sandoval',
  'Academia Antonia Alonso',
  'Academia Avance Charter',
  'Academia Cesar Chavez Chtr School',
  'Academia Moderna',
  'Academia Nacional Superior de Orquesta',
  'Academia Tehnica Militara',
  'Academia de Studii Economice din Bucuresti',
  'Academic And Career Education Academy',
  'Academic Arts High School',
  'Academic Behavior School East',
  'Academic Behavior School West',
  'Academic Behavior&community Acad',
  'Academic Center For Excellence',
  'Academic Excellence Online',
  'Academic Leadership Charter School',
  'Academic Magnet High',
  'Academic Recovery Ombudsman',
  'Academic Solutions Academy A',
  'Academic Solutions High School',
  'Academic medical center at State University of New York at Stony Brook',
  'Academie Da Vinci Charter School',
  'Academie Lafayette-cherry',
  'Academie Lafayette-oak',
  'Academies Of Education And Empowerment At Carson High',
  'Academies Of The Antelope Valley',
  'Academir Charter School Middle',
  'Academir Charter School Preparatory',
  'Academir Charter School West',
  'Academir Preparatory Academy',
  'Academy (the)- Sf @mcateer',
  'Academy 21',
  'Academy 360',
  'Academy Adventures Mid-town',
  'Academy Adventures Midtown',
  'Academy Adventures Primary School',
  'Academy At Carrie F Thomas',
  'Academy At Larragoite',
  'Academy At Palumbo',
  'Academy At Smith',
  'Academy At The Farm',
  'Academy At Virginia Randolph',
  'Academy At Westinghouse',
  'Academy Avenue',
  'Academy Calvert K-8 Online School',
  'Academy Central Es',
  'Academy Charter High School',
  'Academy Charter School',
  'Academy Cs',
  'Academy Del Sol',
  'Academy Del Sol - Hope',
  'Academy Del Sol Alt.',
  'Academy El',
  'Academy Endeavour Elementary School',
  'Academy Es',
  'Academy For Academic Excellence',
  'Academy For Academics Arts',
  'Academy For Advanced And Creative Learning',
  'Academy For Allied Health Sciences',
  'Academy For Arts/science/technology',
  'Academy For Biotechnology',
  'Academy For Business And Technology Elementary',
  'Academy For Business And Technology High School',
  'Academy For Career Exploration',
  'Academy For Careers And Technology',
  'Academy For Careers In Television And Film',
  'Academy For Change',
  'Academy For Character Education',
  'Academy For Classical Education',
  'Academy For College And Career Exploration',
  'Academy For Community Education (ace)',
  'Academy For Conservation And The Environment',
  'Academy For Discovery At Lakewood',
  'Academy For Enriched Sciences',
  'Academy For Environmental Leadership',
  'Academy For Environmental Science',
  'Academy For Excellence Through The Arts (the)',
  'Academy For Global Citizenship',
  'Academy For Health Careers',
  'Academy For Individualized Study/independent Study/home Scho',
  'Academy For Information Technology',
  'Academy For Innovative Studies',
  'Academy For Integrated Arts',
  'Academy For International Education Charter School',
  'Academy For Language And Technology',
  'Academy For Law And Public Safety',
  'Academy For Learning',
  'Academy For Math Engineering & Science',
  'Academy For Mathematics Science And Engineering',
  'Academy For Multilingual Immersion Studies',
  'Academy For New Americans',
  'Academy For Performing Arts',
  'Academy For Personal Leadership And Excellence',
  'Academy For Positive Learning',
  'Academy For Scholarship And Entrepreneurship',
  'Academy For Science And Design Charter (h)',
  'Academy For Science And Design Charter (m)',
  'Academy For Science Foreign Language',
  'Academy For Social Action-a College Board School',
  'Academy For Software Engineering',
  'Academy For Teaching And Learning',
  'Academy For Tech & Class',
  'Academy For Technology And Academics',
  'Academy For Technology Engineering Math & Science',
  'Academy For Urban Leadership Charter School',
  'Academy For Urban Scholars Youngstown',
  'Academy For Young Writers',
  'Academy H S',
  'Academy High School',
  'Academy Hill School',
  'Academy I',
  'Academy Int',
  'Academy International Elementary School',
  'Academy J H',
  'Academy Jjaep',
  'Academy Learning Center',
  'Academy Of Accelerated Learning',
  'Academy Of Advanced Learning',
  'Academy Of Aerospace And Engineering',
  'Academy Of Aerospace And Engineering Elementary',
  'Academy Of Allied Health And Science',
  'Academy Of American Studies',
  'Academy Of Applied Mathematics And Technology',
  'Academy Of Art Careers And Technology',
  'Academy Of Arts And Academics',
  'Academy Of Arts And Letters',
  'Academy Of Arts And Sciences: Sonoma',
  'Academy Of Arts And Sciences: Thousand Oaks & Simi Valley',
  'Academy Of Building Industries',
  'Academy Of Career Education Charter School',
  'Academy Of Careers And Exploration',
  'Academy Of Careers And Technology',
  'Academy Of Charter Schools',
  'Academy Of Choice',
  'Academy Of Citizenship And Empowerment',
  'Academy Of Const And Engineering',
  'Academy Of Construction/engineering',
  'Academy Of Creative Ed',
  'Academy Of Dallas',
  'Academy Of Dover Charter School',
  'Academy Of Educational Excellence',
  'Academy Of Engineering And Technology',
  'Academy Of Environmental Science',
  'Academy Of Excellence',
  'Academy Of Finance And Enterprise',
  'Academy Of Health Sciences At Pgcc',
  'Academy Of Hope Charter',
  'Academy Of Hospitality And Tourism',
  'Academy Of Innovative Technology',
  'Academy Of International Studies',
  'Academy Of International Studies (at Woodburn)',
  'Academy Of Law & Public Safety',
  'Academy Of Math And Science',
  'Academy Of Math And Science Camelback',
  'Academy Of Mathematics And Science South',
  'Academy Of Medical Arts At Carson High',
  'Academy Of Personalized Excellence',
  'Academy Of Personalized Learning',
  'Academy Of Public Relations',
  'Academy Of Richmond County High School',
  'Academy Of Science And Engineering',
  'Academy Of Science And Innovation',
  'Academy Of Science Math And Technology Early College High Sc',
  'Academy Of Talented Scholars (the)',
  'Academy Of Technology & Leadership At Saticoy',
  'Academy Of Technology Design',
  'Academy Of The Americas',
  'Academy Of The Americas High School',
  'Academy Of The Arts (the)',
  'Academy Of The Canyons',
  'Academy Of The City Charter School',
  'Academy Of The Pacific Rim Charter Public School',
  'Academy Of The Redwoods',
  'Academy Of Trades And Tech',
  'Academy Of Tucson Elementary School',
  'Academy Of Tucson High School',
  'Academy Of Tucson Middle School',
  'Academy Of Urban Learning',
  'Academy Of Urban Planning And Engineering',
  'Academy Of Warren',
  'Academy Of World Languages Elementary School',
  'Academy Online',
  'Academy Park Hs',
  'Academy Park School',
  'Academy Performing Preschool',
  'Academy School (brattleboro)',
  'Academy Street Elementary School',
  'Academy West Alternative Education',
  'Academy With Community Partners',
  'Academy of Arts',
  'Academy of Arts "George Enescu" Iasi',
  'Academy of Arts in Banská Bystrica',
  'Academy of Drama and Film',
  'Academy of Economic Studies of Moldova',
  'Academy of Economics "Dimitur A. Tscenov"',
  'Academy of Economics in Cracow',
  'Academy of Economics in Katowice',
  'Academy of Economics in Poznan',
  'Academy of Economics in Wroclaw',
  'Academy of Fine Arts',
  'Academy of Fine Arts and Design in Bratislava',
  'Academy of Humanities and Economics in Lodz',
  'Academy of International Economic and Political Relations Gdynia',
  'Academy of Management and Entrepreneurship',
  'Academy of Music "Georghe Dima" Cluj-Napoca',
  'Academy of Music Dance and Fine Arts',
  'Academy of Performing Arts Film and TV Fakulty',
  'Academy of Public Administration of Belarus',
  'Academy of Sports and Physical Training',
  'Academy of the Ministry of Internal Affairs of the Republic of Belarus',
  'Academy/full-time Programs',
  'Acadey Of Medical Technology - A College Board School',
  'Acadia Academy School',
  'Acadia Middle School',
  'Acadia University',
  'Acadian Elementary',
  'Acadian Elementary School',
  'Acadian Middle School',
  'Acadiana High School',
  'Acadiana Renaissance Charter Academy',
  'Acalanes Center For Independent Study',
  'Acalanes High',
  'Accawmacke Elementary',
  'Acce',
  'Accelerated',
  'Accelerated Achievement Academy',
  'Accelerated Advanced Learning Program',
  'Accelerated Charter Elementary',
  'Accelerated Charter High',
  'Accelerated Int Charter School',
  'Accelerated Interdisciplinary Acad',
  'Accelerated Learning Academy',
  'Accelerated Learning Center',
  'Accelerated Learning Laboratory',
  'Acceleration Academies Llc',
  'Acceleration Academy',
  'Acceleration Day And Evening Academy',
  'Acceleration East',
  'Acceleration Middle Charter School',
  'Acceleration West',
  'Accelere',
  'Access',
  'Access Charter',
  'Access County Community',
  'Access Juvenile Hall',
  'Access To Education',
  'Accident Elementary',
  'Accion Academy',
  'Acclaim Academy',
  'Accokeek Academy',
  'Accompsett Elementary School',
  'Accompsett Middle School',
  'Accra Polytechnic',
  'Acdemic Center for Law and Business',
  'Ace Academy For Scholars At The Geraldine Ferraro Campus',
  'Ace Academy Jefferson Site',
  'Ace Academy Sda Glendale Lincoln Woodward',
  'Ace Alliance Charter Elementary',
  'Ace Charter High',
  'Ace Community Challenge School',
  'Ace Day & Night School - Closed',
  'Ace Empower Academy',
  'Ace Esperanza Middle',
  'Ace Inspire Academy',
  'Ace Leadership High School',
  'Ace Preparatory Academy',
  'Ace Technical Charter High School',
  'Acequia Elementary School',
  'Acequia Madre Elementary',
  'Acero Chtr - Bartolome De Las',
  'Acero Chtr - Carlos Fuentes Cmpus',
  'Acero Chtr - Esmera Santiago Cmps',
  'Acero Chtr - Octavio Paz Campus',
  'Acero Chtr - Officer Marquez Cmps',
  'Acero Chtr - Rufino Tamayo Campus',
  'Acero Chtr - Sandra Cisneros Cmps',
  'Acero Chtr - Spc Zizumbo Campus',
  'Acero Chtr Netrk - Major Garcia',
  'Acero Chtr Network - Jovita Idar',
  'Acero Chtr Network - Pfc Torress',
  'Acero Chtr Roberto Clemente Cmps',
  'Acero Chtr Sch Netwrk- Victoria',
  'Acero Chtr Sor Juana Ines De La',
  'Acero Chtr-brighton Park Campus',
  'Aces High School',
  'Acgc Elementary Grades 5 And 6',
  'Acharya Nagarjuna University',
  'Acharya Ranga Agricultural University',
  'Achievable Dream Academy',
  'Achievable Dream Middle/high',
  'Achieve',
  'Achieve Academy',
  'Achieve Blended Learning Academy',
  'Achieve Career Preparatory Academy',
  'Achieve Center Of Pasco',
  'Achieve Charter Academy',
  'Achieve Charter School Of Paradise Inc.',
  'Achieve Community Charter School',
  'Achieve Early College H S',
  'Achieve Language Academy',
  'Achieve Online',
  'Achieve Virtual Education Academy',
  'Achievement Academy',
  'Achievement Academy @ Harbor City High',
  'Achievement First Apollo Charter School',
  'Achievement First Aspire Charter School',
  'Achievement First Bridgeport Academy',
  'Achievement First Brownsville Charter School',
  'Achievement First Crown Heights Charter School',
  'Achievement First East New York Charter School',
  'Achievement First Endeavor Charter School',
  'Achievement First Hartford Academy Inc.',
  'Achievement First Iluminar',
  'Achievement First Linden Charter School',
  'Achievement First North Brooklyn Preparatory Charter School',
  'Achievement First Providence',
  'Achievement First Voyager Charter School',
  'Achievement First-bushwick Charter School',
  'Achievement High School',
  'Achievement House Cs',
  'Achievement Integrity And Maturity',
  'Achievement Preparatory Pcs Elementary',
  'Achievement Preparatory Pcs Middle School',
  'Achille Es',
  'Achille Hs',
  'Achilles Elementary',
  'Achziger El',
  'Acibadem University',
  'Ackerly',
  'Ackerman Elem',
  'Ackerman Elementary School',
  'Ackerman Sch.',
  'Ackerson Lake Community Education',
  'Acme Delco Elementary',
  'Acme Delco Middle',
  'Acme Elementary',
  'Acmetonia Primary Sch',
  'Acorn Community High School',
  'Acorn Elementary School',
  'Acorn High School',
  'Acorn Montessori',
  'Acorn Montessori Charter School',
  'Acorn Montessori Charter School Inc. - West',
  'Acorn Woodland Elementary',
  'Acquinton Elementary',
  'Acreage Pines Elementary School',
  'Acres Green Elementary School',
  'Act Academy Cyber Cs',
  'Actech High School',
  'Actions Program',
  'Active Learning Elementary School (the)',
  'Acton El',
  'Acton Elementary School',
  'Acton Middle',
  'Acton-boxborough Regional High',
  'Acts',
  'Acushnet Elementary School',
  'Acworth Elementary School',
  'Acworth Intermediate School',
  'Ad Johnston Jrsr High School',
  'Ad Prima Cs',
  'Ad Wheat Middle',
  'Ada Clegg Elementary',
  'Ada County Juvenile Center',
  'Ada Ec Ctr',
  'Ada Elementary',
  'Ada Elementary School',
  'Ada Givens Elementary',
  'Ada High School',
  'Ada Hs',
  'Ada Jhs',
  'Ada Mae Faubion El',
  'Ada Merritt K-8 Center',
  'Ada Professional-technical Center',
  'Ada S. Nelson Elementary',
  'Ada Vista Elementary School',
  'Ada W. Harris Elementary',
  'Ada-borup Area Learning Program',
  'Ada-borup Secondary',
  'Adacao Elementary School',
  'Adair Co. Elem.',
  'Adair Co. High',
  'Adair County Elementary School',
  'Adair County High School',
  'Adair County Middle School',
  'Adair County Primary Center',
  'Adair Hs',
  'Adair Learning Academy',
  'Adair Ms',
  'Adair-casey Elementary School',
  'Adair-casey Jr. High School',
  'Adaire Alexander Sch',
  'Adairsville Elementary School',
  'Adairsville High School',
  'Adairsville Middle School',
  'Adairville Elementary School',
  'Adak School',
  'Adalberto Sanchez Morales (elem. Bo. Ancones)',
  'Adaline E. Kent Middle',
  'Adam (william Laird) Elementary',
  'Adam El',
  'Adam Mickiewicz University of Poznan',
  'Adama Science and Technology University',
  'Adamawa State University',
  'Adams (j. Douglas) Middle',
  'Adams Central Elementary School',
  'Adams Central High School',
  'Adams Central Jr-sr High Sch',
  'Adams Central Middle School',
  'Adams City High School',
  'Adams City Middle School',
  'Adams Co Early Learning Cntr',
  'Adams Co Juvenile Detention Cntr',
  'Adams County Rssp',
  'Adams El',
  'Adams Elem',
  'Adams Elem School',
  'Adams Elem.',
  'Adams Elementary',
  'Adams Elementary School',
  'Adams Es',
  'Adams High School',
  'Adams Hill El',
  'Adams Magnet Elementary',
  'Adams Middle',
  'Adams Middle School',
  'Adams Primary School',
  'Adams School',
  'Adams Traditional Academy',
  'Adams Upper Elementary School',
  'Adams Youth Service Center',
  'Adams-friendship Elementary',
  'Adams-friendship High',
  'Adams-friendship Middle',
  'Adams12 Five Star Preschool',
  'Adamson Middle School',
  'Adamson University',
  'Adamston Elementary School',
  'Adamstown El Sch',
  'Adamsville Elementary',
  'Adamsville Elementary School',
  'Adamsville Junior / Senior High School',
  'Adamsville Primary School',
  'Adana Science and Technology University',
  'Addams Elem School',
  'Addams Elementary',
  'Addams Elementary And Ecc',
  'Addaville Elementary School',
  'Addeliar Guy Elementary School',
  'Addenbrooke Classical Academy',
  'Addenbrooke Classical Grammar School',
  'Addie R. Lewis School',
  'Addis Ababa Science & Technology University',
  'Addis Ababa University',
  'Addison Aerospace Magnet Middle',
  'Addison Central School',
  'Addison Early Learning Center',
  'Addison Elementary',
  'Addison Elementary School',
  'Addison High School',
  'Addison Middle School',
  'Addison Middle/high School',
  'Addison Mizner Elementary School',
  'Addison Trail High School',
  'Adekunle Ajasin University',
  'Adel Elementary School',
  'Adela Brenes Texidor',
  'Adela Rolon Fuentes',
  'Adelaide De Vaney Elementary Sch',
  'Adelaide Elementary School',
  'Adelaide School',
  'Adelante Charter',
  'Adelante Dual Language Academy',
  'Adelante Dual Language Academy Ii',
  'Adelante High',
  'Adelante High (continuation)',
  'Adelante Spanish Immersion',
  'Adelanto Elementary',
  'Adelanto High',
  'Adele Harrison Middle',
  'Adeleke University',
  'Adelfa Botello Callejo El',
  'Adeline C. Marston School',
  'Adelle Turner El',
  'Adelphi Elementary',
  'Adelphi University',
  'Adelphia Elementary School',
  'Adena Elementary School',
  'Adena High School',
  'Adena Middle School',
  'Adena Pre-school',
  'Adeyemi College of Education',
  'Adiban Higher Education Institue',
  'Adigrat University',
  'Adirondack Community College',
  'Adirondack High School',
  'Adirondack Middle School',
  'Adiyaman University',
  'Adjudicated Youth Facility',
  'Adkins Middle',
  'Adkins Pre-k Center',
  'Adlai E Stevenson High School',
  'Adlai E. Stevenson',
  'Adlai Stevenson Elem School',
  'Adlai Stevenson Elementary School',
  'Adlai Stevenson High School',
  'Adlai Stevenson Middle School',
  'Adlai Stevenson School',
  'Adler Park School',
  'Adm Middle School',
  'Adm Richard E Byrd Elem School',
  'Adm Senior High School',
  'Admin Services',
  'Admiral Arthur W Radford High School',
  'Admiral Chester W Nimitz Elementary School',
  'Admiral King Elementary School',
  'Admiral Peary Avts',
  'Admiral Richard E. Byrd Middle',
  'Admiral William F. Halsey Jr. Health & Public Safety Academy',
  'Adna Elementary School',
  'Adna Middle/high School',
  'Adnan Menderes University',
  'Adobe Acres Elementary',
  'Adobe Bluffs Elementary',
  'Adobe Middle School',
  'Adobe Mountain School',
  'Adolescent Day Treatment',
  'Adolfina Irizarry De Puig',
  'Adolfo Camarillo High',
  'Adolfo Eguen',
  'Adolfo Grana Rivera',
  'Adolph Link Elem School',
  'Adrian Burnett Elementary',
  'Adrian Community High School',
  'Adrian Elem.',
  'Adrian Elementary',
  'Adrian Elementary School',
  'Adrian High School',
  'Adrian Martinez Gandia',
  'Adrian Medina',
  'Adrian Middle',
  'Adrian Middle School 56 Building',
  'Adrian School',
  'Adrian Secondary',
  'Adrian Sr. High',
  'Adrian Torres Torres',
  'Adrian Wilcox High',
  'Adrianne Serrano',
  'Adult & Career Education Center',
  'Adult Academics',
  'Adult And Community Ed Building',
  'Adult Basic Ed',
  'Adult Diagnostic And Treatment Center',
  'Adult Ed',
  'Adult Ed Department',
  'Adult Education',
  'Adult Education - Indiantown',
  'Adult Education Center',
  'Adult Education Center Of Palm Beach',
  'Adult Education Program',
  'Adult Education-martin Co High',
  'Adult Learning Center Osceola',
  'Adult Learning Ctr',
  'Adult Learning Ctr.',
  'Adult Satellite East',
  'Adult Satellite West',
  'Adult Transition Program',
  'Adult Transition Services',
  'Adult Virtual Academy',
  'Adult/community Education',
  'Adult/community Lrn Ctr',
  'Adult/vocational/community Education',
  'Advance Academy',
  'Advance Elem.',
  'Advance High',
  'Advance Tertiary College',
  'Advanced Career Education Center At Hermitage',
  'Advanced Career Education Center At Highland Springs',
  'Advanced Community Experience Program',
  'Advanced Learning Academy',
  'Advanced Learning Academy Of Wisconsin',
  'Advanced Math And Science Academy Charter School',
  'Advanced Technologies Academy',
  'Advanced Technology Academy',
  'Advanced Technology Center',
  'Advanced Virtual Academy',
  'Advantage Academy',
  'Advantage Academy Middle School',
  'Advantage Academy Of Hillsborough',
  'Advantage Academy Of Math And Science At Waterstone',
  'Advantage Academy Santa Fe',
  'Advantage Alternative Program',
  'Advantage Arts Academy',
  'Advantage Charter Academy',
  'Adventist University of Africa',
  'Adventist University of Central Africa',
  'Adventist University of the Philippines',
  'Adventure Elementary',
  'Adventure School',
  'Adygeja State University',
  'Aegean University',
  'Aeolian Elementary',
  'Aep Campus',
  'Aerospace/hydrospace Engineering And Physical Sciences Hs',
  'Af Providence Mayoral Middle',
  'Afe Babalola University',
  'Afeka Tel Aviv Academic College of Engineering',
  'Afflerbach Elementary',
  'Afflerbaugh-paige Camp',
  'Affton High',
  'Afghan University',
  'Afonotele Elementary School',
  'Africa International University',
  'Africa Nazarene University',
  'Africa University',
  'African Road Elementary School',
  'African University of Science and Technology',
  'African Virtual University',
  'Afsa High School',
  'Afsa Middle School',
  'Afternoon Group Learning Centers',
  'Afterschool Program',
  'Afton El Sch',
  'Afton Elementary',
  'Afton Elementary School',
  'Afton Es',
  'Afton Hs',
  'Afton Junior/senior High School',
  'Afton-lakeland Elementary',
  'Afya Public Charter School',
  'Afyon Kocatepe University',
  'Aga Khan University',
  'Agamim Classical Academy',
  'Agana Heights Elementary School',
  'Agapito Rosario Rosario',
  'Agassiz Elem School',
  'Agate Elementary School',
  'Agave Middle And High School',
  'Agawam Early Childhood Center',
  'Agawam High',
  'Agawam Junior High',
  'Agbu Alexmarie Manoogian School',
  'Agder University College',
  'Agee Lierly Life Preparation Services School',
  'Agenoria S Paschal/olinda Elementary School',
  'Aggeler Community Day',
  'Aggie Roberts Elementary School',
  'Agnes B. Hennessey School',
  'Agnes Cotton Academy',
  'Agnes E. Little School',
  'Agnes Gray School',
  'Agnes J. Johnson Elementary',
  'Agnes L. Smith Elementary',
  'Agnes M. Baptist Elementary',
  'Agnes Risley Elementary School',
  'Agnes Scott College',
  'Agnes Stewart Middle School',
  'Agnes Ware Stanley Elementary',
  'Agnew Middle',
  'Agnor-hurt Elementary',
  'Agora Cyber Cs',
  'Agoura High',
  'Agra Es',
  'Agra Hs',
  'Agra Jhs',
  'Agri İbrahim Cecen University',
  'Agricola Elementary School',
  'Agricultural University of Athens',
  'Agricultural University of Cracow',
  'Agricultural University of Lublin',
  'Agricultural University of Norway',
  'Agricultural University of Plovdiv',
  'Agricultural University of Poznan',
  'Agricultural University of Szczecin',
  'Agricultural University of Tirane',
  'Agricultural University of Warsaw',
  'Agricultural University of Wroclaw',
  'Agricultural-Technical Academy in Olsztyn',
  'Agriculture And Science Early College',
  'Agriculture and Forestry University',
  'Agripina Seda',
  'AgroParisTech',
  'AgroSup Dijon',
  'Agua Caliente Elementary',
  'Agua Caliente School',
  'Agua Dulce Elementary School',
  'Agua Dulce H S',
  'Agua Fria High School',
  'Agueda Johnston Middle School',
  'Aguila Elementary School',
  'Aguila Pre-school',
  'Aguilar Elementary School',
  'Aguilar Junior-senior High School',
  'Aguilar School',
  'Aguirre J H',
  'Agustin Balseiro',
  'Agustin Cabrera',
  'Agustin Dueno',
  'Agustin Fernandez Colon',
  'Agustin Ortiz Rivera',
  'Agwsr Elementary At Wellsburg',
  'Agwsr Elementary School',
  'Agwsr High School',
  'Agwsr Middle School',
  'Ah Watwood Elementary School',
  'Ahfachkee Day School',
  'Ahfad University for Women',
  'Ahi Evran University',
  'Ahlf Junior High School',
  'Ahlulbait International University',
  'Ahmadu Bello University',
  'Ahmedabad University',
  'Ahoskie Elementary',
  'Ahrens Educational Resource Center',
  'Ahsanullah University of Science & Technology',
  'Ahstw Elementary School',
  'Ahstw Secondary School',
  'Ahtanum Valley Elementary',
  'Ahuimanu Elementary School',
  'Ahvaz University of Medical Sciences',
  'Ahwahnee High',
  'Ahwahnee Middle',
  'Ahwaz Jondishapour University of Medical Sciences',
  'Aia Lancaster El',
  'Aia Lancaster Middle',
  'Aibonito Beltran',
  'Aichi Bunkyo University',
  'Aichi Gakuin University',
  'Aichi Gakusen University',
  'Aichi Institute of Technology',
  'Aichi Medical University',
  'Aichi Prefectural University',
  'Aichi Prefectural University of Fine Arts & Music',
  'Aichi Sangyo University',
  'Aichi Shukutoku University',
  'Aichi University',
  'Aichi University of Education',
  'Aida C Escobar El',
  'Aiea Elementary School',
  'Aiea High School',
  'Aiea Intermediate School',
  'Aikahi Elementary School',
  'Aiken County Career Center',
  'Aiken El',
  'Aiken Elementary',
  'Aiken Elementary School',
  'Aiken High',
  'Aiken High School',
  'Aiken Middle',
  'Aiken Performing Arts Academy Charter',
  'Aiken School',
  'Aiken Technical College',
  'Aikin El',
  'Aikman El',
  'Aikoku Gakuen University',
  'Aileen Colburn Elementary',
  'Aim',
  'Aim Center H S',
  'Aim College & Career Prep',
  'Aim Global',
  'Aim High School',
  'Aim Higher College Prep Academy',
  'Aims Community College',
  'Aimst University',
  'Ain Shams University',
  'Aina Haina Elementary School',
  'Ainsworth Elementary School',
  'Ainsworth High School',
  'Ainsworth Little Paws Preschool',
  'Ainsworth Middle School',
  'Air Academy High School',
  'Air Base K-8 Center For International Education',
  'Air Force Acad High School',
  'Air Force Academy',
  'Air Force Institute of Technology',
  'Air University',
  'Aire Libre Elementary School',
  'Airline Community School',
  'Airline High School',
  'Airline Park Academy For Advanced Studies',
  'Airport El',
  'Airport Elem.',
  'Airport Heights Elementary',
  'Airport High',
  'Airport Middle College',
  'Airport Road Intermediate School',
  'Airport Senior High School',
  'Airport Virtual Academy',
  'Aitkin Alternative Learning Program',
  'Aitkin Secondary School',
  'Aiton Es',
  'Ajayi Crowther University',
  'Ajman University of Science & Technology',
  'Ajo Elementary School',
  'Ajo High School',
  'Ajou University',
  'Akademia Podlaska',
  'Akademie der bildenden Künste Wien',
  'Akaki Tsereteli State University',
  'Akanu Ibiam Federal Polytechnic Unwana',
  'Akdeniz University',
  'Akers Elementary',
  'Akhbar El Yom Academy',
  'Akhiok School',
  'Aki Kurose Middle School',
  'Akiachak School',
  'Akiak School',
  'Akili Academy Of New Orleans',
  "Akimel O'otham Pee Posh (3-5)",
  "Akimel O'otham Pee Posh (k-2)",
  'Akin Comm Cons Elem School',
  'Akin El',
  'Akin Elementary School',
  'Akin Road Elementary',
  'Akins H S',
  'Akira Yokomi Elementary',
  'Akita University',
  'Akita University of Economics and Law',
  'Akiuk Memorial School',
  'Akrofi-Christaller Institute of Theeology Mission and  Culture',
  'Akron Alternative Academy',
  'Akron Digital Academy',
  'Akron Early College High School',
  'Akron El Sch',
  'Akron Elementary School',
  'Akron High School',
  'Akron Middle School',
  'Akron Preparatory School',
  'Akron Stem High School',
  'Akron Westfield Elementary School',
  'Akron Westfield Middle School',
  'Akron Westfield Senior High School',
  'Akronfairgrove Elem School',
  'Akronfairgrove Jrsr High School',
  'Akros Middle School',
  'Aksaray University',
  'Aksum University',
  'Aktau State University',
  'Akteach',
  'Akula Elitnaurvik School',
  'Akutan School',
  'Akwa Ibom State University of Technology',
  'Al Ahlia University',
  'Al Akhawayn University',
  'Al Azhar University',
  'Al Behrman Elementary',
  'Al Fashir University',
  'Al Ghurair University',
  'Al Hussein Bin Talal University',
  'Al Imam Al-Ouzai University',
  'Al Kennedy High School',
  'Al Khawarizmi International College',
  'Al Maarif University College',
  'Al Madinah International University',
  'Al Mamon University College',
  'Al Mansour University College',
  'Al Muthanna University',
  'Al Nahrain University',
  'Al Rafidain University College',
  'Al Rasheed University College',
  'Al Seeliger Elementary School',
  'Al Turath University College',
  'Al Yarmouk University College',
  'Al al-Bayt University',
  'Al-Ahliyya Amman University',
  'Al-Aqsa University',
  'Al-Asmarya University for Islamic Studies',
  'Al-Azhar University of Gaza',
  'Al-Baath University',
  'Al-Balqa Applied University',
  'Al-Batterjee Medical College',
  'Al-Birony University',
  'Al-Bukhari International University',
  'Al-Buraimi University College',
  'Al-Eman University',
  'Al-Farabi Kazakh National University',
  'Al-Imam Mohamed Ibn Saud Islamic University',
  'Al-Islah University',
  'Al-Isra University',
  'Al-Jabal Al-Gharbi University',
  'Al-Khair University',
  'Al-Manar University',
  'Al-Nasser University',
  'Al-Quds Open University',
  'Al-Quds University - The Arab University in Jerusalem',
  'Al-Wataniya Private University',
  'Al-Yamamah College',
  'Al-Zaiem Al-Azhari University',
  'Al-Zaytoonah University',
  'Al-baha University',
  'Al-hikmah University',
  'Ala Mesa',
  'Ala Qc Elem',
  'Ala San Tan',
  'Ala Wai Elementary School',
  'Alabama A&M University',
  'Alabama Southern Community College',
  'Alabama State University',
  'Alabama Virtual Academy At Eufaula City Schools',
  'Alachua Academy',
  'Alachua County Jail',
  'Alachua Elementary School',
  'Alachua Eschool (virtual Franchise)',
  'Alachua Learning Academy Elementary',
  'Alachua Learning Academy Middle',
  'Alachua Mycroschool Of Integrated Academics And Technologies',
  'Alachua Regional Juvenile Detention Center',
  'Alachua Virtual Instruction Program',
  'Alachua Virtual Instruction Program (district Provided)',
  'Alafia Elementary School',
  'Alagappa University',
  'Alahgaff University',
  'Alaiedon Elementary School',
  'Alain Leroy Locke College Preparatory Academy',
  'Alain University of Science and Technology',
  'Alak School',
  "Alaka'i O Kaua'i Charter School",
  'Alakanuk School',
  'Alamance Community College',
  'Alamance Elementary',
  'Alamance-burlington Middle/early College',
  'Alameda Community Learning Center',
  'Alameda County Community',
  'Alameda County Juvenile Hall/court',
  'Alameda County Special Education',
  'Alameda Early Childhood Center',
  'Alameda Elementary',
  'Alameda Elementary School',
  'Alameda High',
  'Alameda International Junior/senior High School',
  'Alameda Middle School',
  'Alameda Science And Technology Institute',
  'Alamitos Intermediate',
  'Alamo Colleges',
  'Alamo El',
  'Alamo Elementary',
  'Alamo Elementary School',
  'Alamo Heights H S',
  'Alamo Heights J H',
  'Alamo J H',
  'Alamo Middle',
  'Alamo Navajo Community School',
  'Alamogordo High',
  'Alamos Elementary',
  'Alamosa Elementary',
  'Alamosa Elementary School',
  'Alamosa High School',
  'Alamosa Ombudsman School Of Excellence',
  'Alamosa Park Elementary',
  'Alan B Shepard Elem School',
  'Alan B Shepard Middle',
  'Alan B Shepard Middle School',
  'Alan B. Shepard Elementary School',
  'Alan Shawn Feinstein Elem.',
  'Alan Shawn Feinstein Ms Of Cov',
  'Alan Shepard Elementary School',
  'Alanson Public School',
  'Alanton Elementary',
  'Alanya Alaaddin Keykubat University',
  'Alanya Hamdullah Emin Pasa University',
  'Alaska Middle College School',
  'Alaska Native Cultural Charter School',
  'Alaska Reach Academy',
  'Alaska State School For Deaf And Hard Of Hearing',
  'Alataua-lua Elementary School',
  'Alba',
  'Alba - Academia De Lenguaje Y Bellas Artes',
  'Alba School',
  'Alba-golden El',
  'Alba-golden H S',
  'Alban Elementary School',
  'Albany Area Schools Early Childhood',
  'Albany Avenue Elementary School',
  'Albany Avenue School',
  'Albany Community Charter School',
  'Albany Community Middle',
  'Albany Community School',
  'Albany Elementary',
  'Albany Elementary School',
  'Albany High',
  'Albany High School',
  'Albany Jr-sr H S',
  'Albany Leadership Charter High School For Girls',
  'Albany Lower Elementary School',
  'Albany Middle',
  'Albany Middle School',
  'Albany Options School',
  'Albany Park Elementary',
  'Albany Park Multicultural Elem',
  'Albany Regional Youth Detention Center',
  'Albany School Of Humanities',
  'Albany Senior High',
  'Albany State University',
  'Albany Technical College',
  'Albany Upper Elementary School',
  'Albany-schoharie-schenectady-saratoga Boces',
  'Albemarle County Community Public Charter School',
  'Albemarle High',
  'Albemarle Middle',
  'Albemarle Road Elementary',
  'Albemarle Road Middle',
  'Albemarle Road Relief School',
  'Albers Elem School',
  'Albert & Iola Lee Davis Malvern El',
  'Albert A. Michelson Elementary',
  'Albert Baxter Elementary',
  'Albert Bridge School (west Windsor)',
  'Albert C Wagner Correctional Facility',
  'Albert C Williams El',
  'Albert Cammon Middle School',
  'Albert Cassens Elementary',
  'Albert Chapman Elementary School',
  'Albert City-truesdale Elementary School',
  'Albert D. Griswold Middle School',
  'Albert D. Lawton School',
  'Albert E Grice Middle School',
  'Albert Einstein',
  'Albert Einstein Acad For Ltrs Arts & Scis - Odyssey',
  'Albert Einstein Acad Ltrs Arts Scis - Aqua Dulce Part. Acad',
  'Albert Einstein Academy Charter Elementary',
  'Albert Einstein Academy Charter Middle',
  'Albert Einstein Academy For Letters Arts And Sciences',
  'Albert Einstein Academy For Letters Arts And Sciences-ohio',
  'Albert Einstein Academy For Letters Arts And Sciences-steam',
  'Albert Einstein Continuation',
  'Albert Einstein Elem School',
  'Albert Einstein High',
  'Albert Einstein Middle',
  'Albert Einstein Middle School',
  'Albert F Ford Middle School',
  'Albert F. Argenziano School At Lincoln Park',
  'Albert F. Biella Elementary',
  'Albert G Prodell Middle School',
  'Albert Gallatin Area Shs',
  'Albert Gallatin North Ms',
  'Albert Gallatin South Ms',
  'Albert H Bangert Elementary',
  'Albert Harris Elementary',
  'Albert Hill Middle',
  'Albert Lea Area Learning Center',
  'Albert Lea Senior High',
  'Albert Leonard Middle School',
  'Albert M Lowry High School',
  'Albert P Brewer High School',
  'Albert P Brewer Vocational School',
  'Albert Payson Terhune Elementary',
  'Albert Pike Elementary School',
  'Albert Powell Continuation',
  'Albert R. Lyman Middle',
  'Albert S Hall School',
  'Albert S. Woodward Memorial School',
  'Albert Schweitzer Elementary',
  'Albert Schweitzer Elementary School',
  'Albert Shanker School For Visual And Performing Arts',
  'Albert Sidney Johnston El',
  'Albert-Ludwigs-Universität Freiburg',
  'Alberta Martone Elementary',
  'Alberta Rider Elementary School',
  'Alberta Smith Elementary',
  'Alberto Melendez Torres',
  'Alberton 7-8',
  'Alberton High School',
  'Alberton School',
  'Albertson College of Idaho',
  'Albertus W Becker School',
  'Albertville Elementary School',
  'Albertville High School',
  'Albertville Middle School',
  'Albertville Primary',
  'Albertville Primary School',
  'Albia High School',
  'Albia Middle School',
  'Albin Elementary',
  'Albio Sires Elementary School',
  'Albion College',
  'Albion Elementary',
  'Albion Elementary School',
  'Albion Grade School',
  'Albion Middle',
  'Albion Public School',
  'Albion Street Elementary',
  'Albright Middle',
  'Albuquerque Collegiate Charter School',
  'Albuquerque High',
  'Albuquerque Institute Of Math & Science',
  'Alburg Community Education Center',
  'Alburnett Elementary School',
  'Alburnett Middle School',
  'Alburnett Senior High School',
  'Alburtis El Sch',
  'Alc - Eden Prairie Hs - Is',
  'Alc - Gateway - Ey',
  'Alc - Neca - Ey',
  'Alc - Seca - Ey',
  'Alc - W-alt - Ey',
  'Alc At Bryant',
  'Alc At Burke',
  'Alc At Montrose',
  'Alc At Mountain View',
  'Alc Bms Middle Year Program',
  'Alc Connections',
  'Alc Credit Recovery',
  'Alc Eden Prairie Hs-is',
  'Alc Edina Hs Alternative - Is',
  'Alc Evening High School',
  'Alc Extended Day',
  'Alc Gateway To College',
  'Alc Independent Study',
  'Alc Mgms Middle Year Program',
  'Alc Night School',
  'Alc Nvms Middle Year Program',
  'Alc Oms Middle Year Program',
  'Alc On Track',
  'Alc Online School',
  'Alc Richfield - Success At 15',
  'Alc Richfield Hs-is',
  'Alc Secondary Extended Programs',
  'Alc Secondary Special Sites',
  'Alc Senior High',
  'Alc Summer Credit Recovery',
  'Alc Westonka - Is',
  'Alc-orono High School Is',
  'Alcalde Elementary',
  'Alcester-hudson Elementary - 04',
  'Alcester-hudson High School - 01',
  'Alcester-hudson Jr. High - 03',
  'Alchesay High School',
  'Alcoa Elementary',
  'Alcoa High School',
  'Alcoa Intermediate School',
  'Alcoa Middle School',
  'Alcona Community High School',
  'Alcona Elementary School',
  'Alcorn Alternative School',
  'Alcorn Central Elementary School',
  'Alcorn Central High School',
  'Alcorn Central Middle School',
  'Alcorn Co Voc Complex',
  'Alcorn Middle',
  'Alcott',
  'Alcott El',
  'Alcott Elem School',
  'Alcott Elementary',
  'Alcott Elementary School',
  'Alcott Humanities High School',
  'Alcott Ms',
  'Alcott School',
  'Alcova Elementary',
  'Alcova Elementary School',
  'Alcovy High School',
  'Alcy Elementary',
  'Aldama Elementary',
  'Aldan El Sch',
  'Aldeane Comito Ries Elementary',
  'Alden Elementary School',
  'Alden Hebron Elem School',
  'Alden Intermediate School',
  'Alden Middle School',
  'Alden Place Elementary School',
  'Alden Primary At Townline',
  'Alden R Brown Elementary School',
  'Alden Road Excep. Student Center',
  'Alden School',
  'Alden Senior High School',
  'Alden Terrace School',
  'Alden-conger Elementary',
  'Alden-conger Secondary',
  'Alden-hebron High School',
  'Alden-hebron Middle School',
  'Alder Avenue Middle School',
  'Alder Creek Middle',
  'Alder Creek Middle School',
  'Alder Elementary School',
  'Alder Grove Charter',
  'Alder Middle',
  'Alder School',
  'Alderman Road Elementary',
  'Alderson El',
  'Alderson Elementary',
  'Alderwood Elementary',
  'Alderwood Elementary School',
  'Alderwood Middle School',
  'Aldie Elementary',
  'Aldine Education Center',
  'Aldine H S',
  'Aldine J J A E P',
  'Aldine Middle',
  'Aldine Ninth Grade School',
  'Aldo Leopold Charter',
  'Aldo Leopold Community School K-8',
  'Aldo Leopold Middle School',
  'Aldrich Elementary',
  'Aldrich Elementary School',
  'Aldrich Intermediate',
  'Aldridge El',
  'Aldridge Elem School',
  'Aldrin Elementary',
  'Aledo H S',
  'Aledo Middle',
  'Alee Academy Charter School',
  'Alegent/immanuel Rtc',
  'Alejandro Jr Cruz',
  'Alejandro Jr Cruz (galateo Parcelas)',
  'Alejandro Tapia Y Rivera',
  'Aleknagik School',
  'Aleksander Gieysztor School of Humanities in Pultusk',
  'Aleksander Zelwerowicz State Theatre Academy',
  'Alessandro',
  'Alessandro High',
  'Aletheia University',
  'Aletta Crichton',
  'Alex Es',
  'Alex Green Elementary',
  'Alex Hs',
  'Alex M Martino Jr High School',
  'Alex R. Kennedy Elementary',
  'Alex Sanger El',
  'Alex Sanger Preparatory Middle',
  'Alex W Spence Talented/gifted Academy',
  'Alexander B Bruce',
  'Alexander Batcho Intermediate School',
  'Alexander Central High',
  'Alexander City Middle School',
  'Alexander D. Sullivan School',
  'Alexander Denbo',
  'Alexander Doniphan Elem.',
  'Alexander Early College',
  'Alexander El',
  'Alexander Elem School',
  'Alexander Elem.',
  'Alexander Elementary',
  'Alexander Elementary School',
  'Alexander Es',
  'Alexander Fleming Middle',
  'Alexander Graham Middle',
  'Alexander Hamilton',
  'Alexander Hamilton Academy',
  'Alexander Hamilton Elementary',
  'Alexander Hamilton Elementary Sch',
  'Alexander Hamilton Elementary School',
  'Alexander Hamilton High School',
  'Alexander Hamilton Preparatory Academy',
  'Alexander Hamilton School',
  'Alexander Hamilton Senior High',
  'Alexander Henderson Elementary School',
  'Alexander High School',
  'Alexander Ii Magnet School',
  'Alexander Jr. / Sr. High School',
  'Alexander Junior High School',
  'Alexander Learning Academy',
  'Alexander Learning Center',
  'Alexander Middle',
  'Alexander Middle School-high School',
  'Alexander Rose Elementary',
  'Alexander Valley Elementary',
  'Alexander W Dreyfoos Junior School Of The Arts',
  'Alexander Wilson Elementary',
  'Alexandra House',
  'Alexandria Area High School',
  'Alexandria Avenue Elementary',
  'Alexandria Central Elementary School',
  'Alexandria Central High School',
  'Alexandria Educational Center',
  'Alexandria Elementary School',
  'Alexandria High School',
  'Alexandria Middle Magnet School',
  'Alexandria Middle School',
  'Alexandria Senior High School',
  'Alexandria Technical & Community College',
  'Alexandria University',
  'Alexandria-monroe Elementary',
  'Alexandria-monroe High School',
  'Alexandria-monroe Intermediate',
  'Alfaisal University',
  'Alfaretta House',
  'Alfonso B. Perez Special Education Center',
  'Alfonso Borrego Sr El',
  'Alfonso Casta Martinez',
  'Alfonso Diaz Lebron',
  "Alfonso Lopez O'neill",
  'Alfonso Ramirez El',
  'Alfonza W Davis Middle School',
  'Alford Elementary',
  'Alfred Adler Elementary School',
  'Alfred B. Nobel Charter Middle',
  'Alfred Benesch',
  'Alfred C. Mackinnon Middle School',
  'Alfred Cramer College Preparatory Lab School',
  'Alfred E Smith Career And Technical High School',
  'Alfred E Upton Elementary School',
  'Alfred Elementary School',
  'Alfred F Garcia School',
  'Alfred G. Zanetti Montessori Magnet School',
  'Alfred I. Dupont Middle School',
  'Alfred J Gomes',
  'Alfred L. Renner Elem.',
  'Alfred Lima Sr. El School',
  'Alfred M Chaffee',
  'Alfred M. Barbe High School',
  'Alfred Nobel University of Economics and Law',
  'Alfred Rush Academy',
  'Alfred S. Faust',
  'Alfred S. Forrest Elementary',
  'Alfred S. Madrid Middle',
  'Alfred Sorensen El',
  'Alfred University',
  'Alfred Vail School',
  'Alfred W. Hanmer School',
  'Alfred Zampella School',
  'Alfred-almond Elementary School',
  'Alfred-almond Junior-senior High School',
  'Alfredo Andrews Elementary School',
  'Alger B. Wilkins High School',
  'Alger Maximum Correctional Facility',
  'Alger Middle School',
  'Algiers Technology Academy',
  'Algoa Correctional Center',
  'Algodones Elementary',
  'Algoma Elementary',
  'Algoma High',
  'Algona High School',
  'Algona Middle School',
  'Algonac Alternative Education',
  'Algonac Juniorsenior High School',
  'Algonkian Elementary',
  'Algonquin College',
  'Algonquin Elementary School',
  'Algonquin Lakes Elem Sch',
  'Algonquin Middle School',
  'Algonquin Primary Center',
  'Algonquin Regional High',
  'Algonquin Road Elem School',
  'Algood Elementary',
  'Algood Middle School',
  'Alhambra High',
  'Alhambra High School',
  'Alhambra Primary School',
  'Alhambra Senior High',
  'Alhambra Traditional School',
  'Alhosn University',
  'Aliamanu Elementary School',
  'Aliamanu Middle School',
  'Alianza Charter',
  'Alicante Avenue Elementary',
  'Alice A Macomber',
  'Alice B Beal Elementary',
  'Alice B. Landrum Middle School',
  'Alice Birney Elementary',
  'Alice Birney Elementary School',
  'Alice Birney Waldorf-inspired',
  'Alice Buffett Magnet Ms',
  'Alice Byrne Elementary School',
  'Alice C. Stelle Middle',
  'Alice Carlson Applied Lrn Ctr',
  'Alice Coachman Elementary School',
  'Alice Costello Elementary School',
  'Alice D Contreras',
  'Alice Drive Elementary',
  'Alice Drive Middle',
  'Alice E Grady Elementary School',
  'Alice Gustafson Elem School',
  'Alice H S',
  'Alice Johnson J H',
  'Alice King Community School',
  'Alice L Smith Elementary School',
  'Alice M Barrows',
  'Alice M Birney K8 School',
  'Alice M. Ellington Elementary',
  'Alice M. Harte Elementary Charter School',
  'Alice M. Worsley',
  'Alice Maxwell Elementary School',
  'Alice N. Boucher Elementary School',
  'Alice N. Stroud Elementary',
  'Alice Ott Middle School',
  'Alice Peck Learning Center',
  'Alice Ponder El',
  'Alice Robertson Jhs',
  'Alice Shaw Elementary',
  'Alice Smith Elementary',
  'Alice Terry Elementary School',
  'Alice Vail Middle School',
  'Alice W Douse El',
  'Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin',
  'Aliceville Elementary School',
  'Aliceville High School',
  'Aliceville Middle School',
  'Alicia Cortez Elementary',
  'Alicia R Chacon',
  'Alicia Reyes Elementary',
  'Alicia Sanchez International School',
  'Alief Early College H S',
  'Alief Isd J J A E P',
  'Alief Learning Ctr (6-12)',
  'Alief Learning Ctr (k-6)',
  'Alief Middle',
  'Alief Montessori Community School',
  'Aligarh Muslim University',
  'Aliiolani Elementary School',
  'Alila',
  'Alimacani Elementary School',
  'Aline Bingham Elementary School',
  'Aline-cleo Es',
  'Aline-cleo Hs',
  'Aliquippa El Sch',
  'Aliquippa Jshs',
  'Alisal Community',
  'Alisal Elementary',
  'Alisal High',
  'Aliso Elementary',
  'Aliso Niguel High',
  'Aliso Viejo Middle',
  'Aljouf University',
  'Alkali Creek School',
  'Alkek El',
  'Alkharj University',
  'Alki Elementary School',
  'Alki Middle School',
  'All Aboard Charter School',
  'All City Elementary - 50',
  'All City Leadership Secondary School',
  'All District 4 Year Old Kindergarten',
  'All India Institute of Medical Sciences',
  'All Nations University College',
  'All Tribes Charter',
  'All Tribes Elementary Charter',
  'Allahabad Agricultural Institute Deemed University',
  'Allahabad University',
  'Allakaket School',
  'Allama Iqbal Open University',
  'Allamanda Elementary School',
  'Allameh Tabatabaie University',
  'Allamuchy Township School',
  'Allan El',
  'Allan Hancock College',
  'Allan K & Carolyn H Bird Education Center',
  'Allan Orrenmaa Elementary',
  'Allan Peterson Elementary',
  'Allapattah Flats K-8',
  'Allardt Elementary',
  'Allatoona Elementary School',
  'Allatoona High School',
  'Allegan Alternative High School',
  'Allegan County Area Technical And Education Center',
  'Allegan High School',
  'Allegany Co Sheriff C/o Treasurer',
  'Allegany College of Maryland',
  'Allegany High',
  'Allegany-limestone Elementary School',
  'Allegany-limestone Middle-high School',
  'Alleghany Avenue School',
  'Alleghany High',
  'Allegheny College',
  'Allegheny-clarion Valley Elem',
  'Allegheny-clarion Valley Hs',
  'Allegro Charter School Of Music',
  'Allemands Elementary School',
  'Allen & William Arnold El',
  'Allen (decima M.) Elementary',
  'Allen (ella B.) Elementary',
  'Allen At Steinbeck',
  'Allen Avenue Elementary',
  'Allen Central High School',
  'Allen Central Middle School',
  'Allen Co Juvenile Center',
  'Allen Co Youth Services Center',
  'Allen Correctional Center',
  'Allen County Career And Technical Center',
  'Allen County Community College',
  'Allen County Intermediate Center',
  'Allen County Primary Center',
  'Allen County-scottsville High School',
  'Allen Creek Elementary School',
  'Allen Creek School',
  'Allen D Nease Senior High School',
  'Allen Dale Elementary School',
  'Allen Dr Ethel Sch',
  'Allen Early Learning Academy',
  'Allen East Elementary School',
  'Allen East High School',
  'Allen East Middle School',
  'Allen El',
  'Allen Elem',
  'Allen Elementary',
  'Allen Elementary School',
  'Allen Ellender School',
  'Allen Es',
  'Allen Ethan Sch',
  'Allen Frear Elementary School',
  'Allen H S',
  'Allen High School',
  'Allen Hs',
  'Allen J Warren Elementary School',
  'Allen Jay Elementary',
  'Allen Jay Middle - A Preparatory Academy',
  'Allen Junior High School',
  'Allen Middle',
  'Allen Ms',
  'Allen Park Community School',
  'Allen Park Elementary School',
  'Allen Park High School',
  'Allen Park Middle School',
  'Allen Road Elementary School',
  'Allen Thornton Career Technical Center',
  'Allen Village High School',
  'Allen Village School',
  'Allen W. Roberts School',
  'Allen-bowden Public School',
  'Allen-field Elementary',
  'Allenbrook Elementary',
  'Allendale',
  'Allendale Elementary',
  'Allendale Elementary School',
  'Allendale Fairfax High',
  'Allendale High School',
  'Allendale Middle School',
  'Allendale School',
  'Allendale-fairfax Middle',
  'Allenstown Elementary School',
  'Allensworth Elementary',
  'Allenton Elementary',
  'Allentown College of Saint Francis de Sales',
  'Allentown Elementary School',
  'Allentown High School',
  'Allenwood Elementary',
  'Allenwood Elementary School',
  'Allesandro Elementary',
  'Allgood Alternative School',
  'Allgood Elementary School',
  'Allgrove School',
  'Alliance Academy',
  'Alliance Academy Of Cincinnati',
  'Alliance Alice M. Baxter College-ready High',
  'Alliance Charter Academy',
  'Alliance Cindy And Bill Simon Technology Academy High',
  'Alliance College-ready Middle Academy 12',
  'Alliance College-ready Middle Academy 4',
  'Alliance College-ready Middle Academy 5',
  'Alliance College-ready Middle Academy 8',
  'Alliance Collins Family College-ready High',
  'Alliance Dr. Olga Mohan High',
  'Alliance Early Child Ed Prog',
  'Alliance Early Learning School At South Lincoln',
  'Alliance For Progress Cs',
  'Alliance Gertz-ressler Richard Merkin 6-12 Complex',
  'Alliance High School',
  'Alliance Jack H. Skirball Middle',
  'Alliance Judy Ivie Burton Technology Academy High',
  'Alliance Kory Hunter Middle',
  'Alliance Leadership Middle Academy',
  'Alliance Leichtman-levine Family Foundation Env Sci High',
  'Alliance Marc & Eva Stern Math And Science',
  'Alliance Margaret M. Bloomfield Technology Academy High',
  'Alliance Marine - Innovation And Technology 6-12 Complex',
  'Alliance Middle School',
  'Alliance Morgan Mckinzie High',
  "Alliance Ouchi-o'donovan 6-12 Complex",
  'Alliance Patti And Peter Neuwirth Leadership Academy',
  'Alliance Piera Barbaglia Shaheen Health Services Academy',
  'Alliance Renee And Meyer Luskin Academy High',
  'Alliance School Of Milwaukee',
  'Alliance Susan And Eric Smidt Technology High',
  'Alliance Ted K. Tajima High',
  'Alliance Tennenbaum Family Technology High',
  'Allianze College of Medical Sciences (ACMS)',
  'Alliene Mullendore El',
  'Allies',
  'Allina Php Ct',
  'Allis Elementary',
  'Allison El',
  'Allison Elementary',
  'Allison L Steele E L C',
  'Allison Park El Sch',
  'Allison Traditional Magnet Middle',
  'Allisonville Elementary School',
  'Allons Elementary',
  'Alloway Creek El Sch',
  'Alloway Township School',
  'Alma Brewer Strawn El',
  'Alma Bryant High School',
  'Alma College',
  "Alma D'arte Charter",
  'Alma Del Mar Charter School',
  'Alma E. Pagels School',
  'Alma Elementary',
  'Alma Elementary At Alma',
  'Alma Elementary School',
  'Alma Fuerte Public',
  'Alma High',
  'Alma High School',
  'Alma Intermediate School',
  'Alma Middle School',
  'Alma O Easom Elementary',
  'Alma Preschool',
  'Alma Primary School',
  'Alma Redwine Elementary',
  'Alma Schrader Elem.',
  'Alma Senior High School',
  'Alma Spikes Elementary School',
  'Almacigo Bajo I Y Ii',
  'Almaden Elementary',
  'Almanor High (continuation)',
  'Almeda El',
  'Almena Elem',
  'Almena Elementary',
  'Almeria Middle',
  'Almira',
  'Almira Coulee Hartline High School',
  'Almira Elementary School',
  'Almirante Sur 2',
  'Almo Elementary School',
  'Almond Acres Charter Academy',
  'Almond Elementary',
  'Almond Grove Elementary',
  'Almond Road Preschool',
  'Almond Tree Middle',
  'Almond-bancroft Elementary',
  'Almond-bancroft High',
  'Almond-bancroft Middle',
  'Almondale Elementary',
  'Almont High School',
  'Almont Middle School',
  'Almor West Es',
  'Almustafa Open University',
  'Aloe El',
  'Alofau Elementary School',
  'Aloha Elementary',
  'Aloha High School',
  'Aloha-huber Park School',
  'Aloma D Johnson Charter School',
  'Aloma Elementary',
  'Aloma High Charter',
  'Alondra Middle',
  'Alonso High School',
  'Alonso S Perales El',
  'Alonzo And Tracy Mourning Senior High Biscayne Bay Campus',
  'Alop Academic Learning Program',
  'Alp @ Capitol View Center',
  'Alp @ Vccs',
  'Alp Credit Recovery',
  'Alpac Elementary School',
  'Alpaugh Elementary',
  'Alpaugh Junior-senior High',
  'Alpena Community College',
  'Alpena Elementary School',
  'Alpena High School',
  'Alpenglow Elementary',
  'Alpha',
  'Alpha Academy',
  'Alpha Borough School',
  'Alpha Charter',
  'Alpha Charter Of Excellence',
  'Alpha Charter School',
  'Alpha Cindy Avitia High',
  'Alpha Elementary',
  'Alpha Hart Lewis',
  'Alpha International Academy',
  'Alpha Omega University',
  'Alpha Technology Middle',
  'Alpha: Blanca Alvarado Middle',
  'Alpha: Cornerstone Academy Preparatory',
  'Alpha: Jose Hernandez',
  'Alphabet Land-margate',
  'Alpharetta Elementary School',
  'Alpharetta High School',
  'Alpine Academy High School',
  'Alpine County Opportunity',
  'Alpine County Secondary Community Day',
  'Alpine Crest Elementary',
  'Alpine El',
  'Alpine Elementary',
  'Alpine Elementary School',
  'Alpine H S',
  'Alpine Middle',
  'Alpine Online School',
  'Alpine School',
  'Alpine Vista',
  'Alps Road Elementary School',
  'Alps View High (continuation)',
  'Alsadrain University',
  'Alsea Charter School',
  'Alsea Elementary School',
  'Alstead Primary School',
  'Alston Middle',
  'Alston Ridge Elementary',
  'Alston-bailey Elementary',
  'Alsup Elementary School',
  'Alt. Resource Ctr.',
  'Alta Brown Elem',
  'Alta California Elementary',
  'Alta E Butler School',
  'Alta Elementary',
  'Alta Elementary School',
  'Alta Heights Elementary',
  'Alta High',
  'Alta Loma El',
  'Alta Loma Elementary',
  'Alta Loma High',
  'Alta Loma Junior High',
  'Alta Loma Middle',
  'Alta Loma School',
  'Alta Mesa Elementary',
  'Alta Murrieta Elementary',
  'Alta Sierra Elementary',
  'Alta Sierra Intermediate',
  'Alta View School',
  'Alta Vista Academy',
  'Alta Vista Alternative High',
  'Alta Vista Alternative Junior High',
  'Alta Vista Charter School',
  'Alta Vista Community Charter',
  'Alta Vista Early College High School',
  'Alta Vista El',
  'Alta Vista Elementary',
  'Alta Vista Elementary School',
  'Alta Vista High',
  'Alta Vista High (continuation)',
  'Alta Vista High School',
  'Alta Vista Innovation High',
  'Alta Vista Intermediate',
  'Alta Vista Public',
  'Alta Vista South Public Charter',
  'Alta-aurelia High School School',
  'Alta-dutch Flat Elementary',
  'Altadena Elementary',
  'Altai State Medical University',
  'Altai State Technical University',
  'Altai State University',
  'Altama Elementary School',
  'Altamaha Elementary School',
  'Altamaha Technical College',
  'Altamahaw Ossipee Elem',
  'Altamira Academy',
  'Altamont Creek Elementary',
  'Altamont Elem',
  'Altamont Elementary',
  'Altamont Elementary School',
  'Altamont Grade School',
  'Altamont High',
  'Altamont High School',
  'Altamont School',
  'Altamonte Elementary School',
  'Altar Valley Middle School',
  'Altara School',
  'Altavista Elementary',
  'Altavista High',
  'Altenburg Elem.',
  'Alter Discipline Campus',
  'Alter Ed Ctr',
  'Alter Ed Prog',
  'Alter H S',
  'Alter Impact Ctr',
  'Alter Learning Ctr',
  'Alter Middle',
  'Alter Sch',
  'Alter School',
  'Altern Ed Prgm/behav Disord Youth/montgomery',
  'Alternative Agencies',
  'Alternative Center',
  'Alternative Center For Education',
  'Alternative Center For Excellence',
  'Alternative Computerized Education (ace) Charter High School',
  'Alternative Cooperative Education Charter',
  'Alternative Education Academy',
  'Alternative Education Center',
  'Alternative Education Center At Lamar',
  'Alternative Education Program',
  'Alternative Education School',
  'Alternative Education-san Joaquin High',
  'Alternative Educational Academy Of Iosco County',
  'Alternative Educational Academy Of Ogemaw County',
  'Alternative Educational Developmental Program',
  'Alternative El',
  'Alternative Family Education',
  'Alternative H S',
  'Alternative High School',
  'Alternative High School (indian Oasis High School)',
  'Alternative High School At 4',
  'Alternative High School Program',
  'Alternative Learning Academy At Conley-caraballo High',
  'Alternative Learning Center',
  'Alternative Learning Ctr - West',
  'Alternative Learning Ctr-east',
  'Alternative Learning Program',
  'Alternative Learning School',
  'Alternative Learning Systems',
  'Alternative Middle',
  'Alternative Middle & High School',
  'Alternative Middle School (indian Oasis Middle School)',
  'Alternative Middle South',
  'Alternative Northeast Community Center Preschool',
  'Alternative Opportunity Programs',
  'Alternative Outreach Program',
  'Alternative Program Central',
  'Alternative Program North',
  'Alternative Programs',
  'Alternative Resource Ctr.',
  'Alternative School',
  'Alternative School At Levitt',
  'Alternative Spcl Needs Div Occ',
  'Alternative Special Education Program (asep)',
  'Alternative Tamarack School',
  'Alternatives Academy/journeys',
  'Alternatives For Success Sch',
  'Alternatives In Action',
  'Alterntive Learning Cent',
  'Altha Public School',
  'Althea Gibson Academy',
  'Altimira Middle',
  'Altinencia Valle',
  'Altizer Elementary School',
  'Altmar-parish-williamstown Elementary School',
  'Altmar-parish-williamstown Jr/sr High School',
  'Alto Bonito El',
  'Alto El',
  'Alto Elementary School',
  'Alto H S',
  'Alto Middle',
  'Alto Park Elementary School',
  'Alton B Parker School',
  'Alton Bowen El',
  'Alton Boyd El',
  'Alton Central School (elem)',
  'Alton Darby Elementary School',
  'Alton El',
  'Alton Elementary',
  'Alton Elementary School',
  'Alton Hall Elementary School',
  'Alton High',
  'Alton High School',
  'Alton Memorial J H',
  'Alton Middle School',
  'Altona Middle School',
  'Altoona Area Hs',
  'Altoona Area Jr Hs',
  'Altoona Elementary',
  'Altoona High',
  'Altoona Intermediate',
  'Altoona Middle',
  'Altoona School',
  'Altoona-midway Elementary',
  'Altoona-midway Middle/high School',
  'Altruria Elementary',
  'Altura Elementary School',
  'Altura Preparatory School',
  'Alturas Elementary',
  'Alturas Elementary School',
  'Alturas International Academy',
  'Altus Early Childhood Center',
  'Altus Es',
  'Altus Hs',
  'Altus Intermediate School',
  'Altus Jhs',
  'Altus Primary School',
  'Alum Creek Elementary School',
  'Alva Hs',
  'Alva Ms',
  'Alvah A Scott Elementary School',
  'Alvarado El-north',
  'Alvarado El-south',
  'Alvarado Elementary',
  'Alvarado H S',
  'Alvarado Int',
  'Alvarado Intermediate',
  'Alvarado J H',
  'Alvarez El',
  'Alvaton Elementary',
  'Alverno College',
  'Alverta B Gray Schultz Middle School',
  'Alview Elementary',
  'Alvin C. York Institute',
  'Alvin Community College',
  'Alvin El',
  'Alvin Elementary',
  'Alvin H S',
  'Alvin J H',
  'Alvin M. Dunn Elementary',
  'Alvin Pri',
  'Alvin S. Hatch Elementary',
  'Alvin V Anderson Rise Academy',
  'Alvina Elementary Charter',
  'Alvirne High School',
  'Alvis C Story El',
  'Alvis Elementary',
  'Alvord Alternative Continuation High',
  'Alvord Continuation High',
  'Alvord El',
  'Alvord H S',
  'Alvord Middle',
  'Alward Elementary School',
  'Alwood Elem School',
  'Alwood Middle/high School',
  'Alyce Savage Taylor Elementary School',
  'Alzada School',
  'Ama International University',
  'Amador County Rop',
  'Amador County Special Education',
  'Amador High',
  'Amador R Rodriguez Juvenile Boot Camp',
  'Amador Valley High',
  'Amagansett School',
  'Amalia Exposito',
  'Amalia H. Mangual',
  'Amalia Lopez De Avila',
  'Amalia Lopez De Vila',
  'Amalia Marin',
  'Amana Academy School',
  'Amana Elementary School',
  'Amanda Arnold Elem',
  'Amanda E Stout El Sch',
  'Amanda Elementary School',
  'Amanda Elzy High School',
  'Amanda Elzy Junior High School',
  'Amanda Garza-pena El',
  'Amanda Gist Elementary School',
  'Amanda Moore Elementary School',
  'Amanda Rochell El',
  'Amanda-clearcreek Elementary School',
  'Amanda-clearcreek High School',
  'Amanda-clearcreek Middle School',
  'Amanda-clearcreek Primary School',
  'Amandla Charter School',
  'Amanecer Primary Center',
  'Amani Public Charter School',
  'Amargosa Creek Middle',
  'Amargosa Middle School',
  'Amargosa Valley Elementary School',
  'Amarillo Area Ctr For Advanced Lrn',
  'Amarillo College',
  'Amarillo Collegiate Academy',
  'Amarillo H S',
  'Amasya University',
  'Amazonia Elem.',
  'Ambassador Academy',
  'Ambassador Phillip V. Sanchez Ii Public Charter',
  'Ambassador Phillip V. Sanchez Public Charter',
  'Ambassador School-global Education',
  'Ambassador University',
  'Ambassador-global Leadership',
  'Ambassadors Preparatory Academy',
  'Amber Charter School',
  'Amber Charter School Ii',
  'Amber Terrace Discovery & Design Early Childhood',
  'Amber-pocasset Es',
  'Amber-pocasset Hs',
  'Amber-pocasset Jhs',
  'Amberlea Elementary School',
  'Amberly Elementary School',
  'Ambler Avenue Elementary',
  'Ambler Elementary',
  'Ambler School',
  'Ambo University',
  'Amboy Central Elem School',
  'Amboy Elementary School',
  'Amboy High School',
  'Amboy Jr High School',
  'Amboy Middle School',
  'Ambridge Area Hs',
  'Ambridge Area Jhs',
  'Ambrose Alli University',
  'Ambrose Elementary',
  'Ambrose Elementary School',
  'Amcs At Anthem Dba Caurus Academy',
  'Amelia County Elementary',
  'Amelia County High',
  'Amelia County Middle',
  'Amelia Earhart Continuation',
  'Amelia Earhart Elementary',
  'Amelia Earhart Elementary School',
  'Amelia Earhart Elementary School Of International Studies',
  'Amelia Earhart Learning Center',
  'Amelia Earhart Middle',
  'Amelia Earhart School',
  'Amelia El',
  'Amelia Elementary School',
  'Amelia High School',
  'Amelia L Johnson High School',
  'Amelia Street Special Education',
  'Amelia V Carriel Jr High',
  'Amelia-nottoway Voc Ctr',
  'Amelio Della Chiesa Early Childhood Center',
  'Amelon Elementary',
  "America's Finest Charter",
  'American Academy',
  'American Academy Of Innovation',
  'American Academy of Dramatic Arts-​New York',
  'American Canyon High',
  'American Canyon Middle',
  'American Charter Academy',
  'American Coastline University',
  'American College Of Dubai',
  'American College of Greece',
  'American College of Thessaloniki',
  'American Dream Charter School',
  'American Elementary',
  'American Falls Academy',
  'American Falls High School',
  'American Falls Intermediate School',
  'American Fork High',
  'American Fork Jr High',
  'American Graduate School in Paris',
  'American Heritage Academy - Camp Verde',
  'American Heritage Academy - Cottonwood',
  'American Heritage Charter School',
  'American High',
  'American History High School',
  'American Horse Elem.',
  'American Indian Magnet School',
  'American Indian Oic',
  'American Indian Oic Extended',
  'American Indian Public Charter',
  'American Indian Public Charter Ii',
  'American Indian Public High',
  'American InterContinental University - London',
  'American International Academy Elementary',
  'American International Academy Middlehigh',
  'American International College',
  'American International School Of Utah',
  'American International University - Bangladesh',
  'American International University West Africa',
  'American Lakes',
  'American Leadership Academy',
  'American Leadership Academy - Ironwood',
  'American Leadership Academy - Queen Creek',
  'American Leadership Academy Anthem South Campus',
  'American Leadership Academy Gilbert',
  'American Leadership Academy Higley',
  'American Leadership Academy North Las Vegas',
  'American Leadership Academy Signal Butte',
  'American Legion High (continuation)',
  'American Montessori Academy Elementary',
  'American Montessori Academy Upper Elementary',
  'American Preparatory Academy - Accelerated School',
  'American Preparatory Academy - Draper #1',
  'American Preparatory Academy - Draper #2',
  'American Preparatory Academy - Draper #3',
  'American Preparatory Academy - Salem',
  'American Preparatory Academy - The School For New Americans',
  'American Preparatory Academy Lv',
  'American Public University System',
  'American Renaissance School',
  'American River Charter',
  'American River College',
  'American Senior High Adult Education',
  'American Senior High School',
  'American Sign Language & English Secondary School',
  'American Union Elementary',
  'American University',
  'American University College of Technology',
  'American University Extension Okinawa',
  'American University In Kosovo',
  'American University in Bulgaria',
  'American University in Cairo',
  'American University in Dubai',
  'American University in the Emirates',
  'American University of Afghanistan',
  'American University of Antigua',
  'American University of Armenia',
  'American University of Beirut',
  'American University of Central Asia',
  'American University of Hawaii Punjab Campus',
  'American University of Iraq Sulaimani (Kurdistan Region)',
  'American University of Kuwait',
  'American University of Middle East',
  'American University of Paris',
  'American University of Science and Technology',
  'American University of Sharjah',
  'American University of Tirana',
  'American University of the Caribbean',
  'American University of the Caribbean School of Medicine',
  'American University of the Caribbean Sint Maarten',
  'American Way Middle',
  'Americana Intermediate School',
  'Americanos College',
  'Americas H S',
  'Americus Elementary / Nlc Junior High',
  'Americus Sumter 9th Grade Academy',
  'Americus Sumter High School (old Americus-sumter Hs S)',
  'Amerischools Academy - Camelback',
  'Amerischools Academy - Country Club',
  'Amerischools Academy - Yuma',
  'Amerischools Academy North',
  'Amerman Elementary School',
  'Amery High',
  'Amery Intermediate',
  'Amery Middle',
  'Ames Elementary School',
  'Ames High School',
  'Ames Middle School',
  'Ames Visual/perf. Arts',
  'Amesbury Elementary',
  'Amesbury High',
  'Amesbury Innovation High School',
  'Amesbury Middle',
  'Amesse Elementary School',
  'Amesti Elementary',
  'Amestoy Elementary',
  'Amesville Elementary School',
  'Amherst Central High School',
  'Amherst College',
  'Amherst County High',
  'Amherst Elementary',
  'Amherst Elementary School',
  'Amherst High',
  'Amherst High School',
  'Amherst Junior High School',
  'Amherst Middle',
  'Amherst Middle School',
  'Amherst Regional High',
  'Amherst Regional Middle School',
  'Amherst School',
  'Amherst Street School',
  'Ami Kids Center For Personal Growth',
  'Ami Kids Emerald Coast',
  'Ami Kids Rio Grande Valley (fka Rgmi)',
  'Ami Kids Tampa',
  'Ami Kids Yes',
  'Amidon Bowen Es',
  'Amidon Elementary School',
  'Amigos Por Vida-friends For Life Charter School',
  'Amigos School',
  'Amikids',
  'Amikids Acadiana',
  'Amikids Baton Rouge',
  'Amikids Caddo',
  'Amikids Clay County',
  'Amikids Jacksonville',
  'Amikids Manatee',
  'Amikids Miami-dade North',
  'Amikids Miami-dade South',
  'Amikids Of Greater Fort Lauderdale',
  'Amikids Orlando',
  'Amikids Southwest Florida',
  'Amikids Volusia',
  'Amina Tio De Malaret',
  'Amirkabir College of Managmant & Technology',
  'Amirkabir University of Technology',
  'Amistad Academy',
  'Amistad Dual Language School',
  'Amistad Elementary School',
  'Amistad High (continuation)',
  'Amite County Elementary',
  'Amite County High School',
  'Amite County Voc Complex',
  'Amite Elementary Magnet School',
  'Amite High Magnet',
  'Amite Westside Middle Magnet',
  'Amity Creek Elementary School',
  'Amity El Ctr',
  'Amity Elementary School',
  'Amity High School',
  'Amity Middle School',
  'Amity Middle School: Bethany',
  'Amity Middle School: Orange',
  'Amity Regional High School',
  'Amity University',
  'Amityville Memorial High School',
  'Amman Arab University for Higher Studies',
  'Amman University',
  'Ammon Elementary School',
  'Amory Career Technical Center',
  'Amory High School',
  'Amory Middle School',
  'Amos Alonzo Stagg High School',
  'Amos El',
  'Amos K Hutchinson El Sch',
  'Amos P. Godby High School',
  'Amosland El Sch',
  'Amoud University',
  'Ampark Neighborhood',
  'Amparo Gutierrez El',
  'Amphi Academy At Donaldson',
  'Amphi Academy Online',
  'Amphitheater High School',
  'Amphitheater Middle School',
  'Amqui Elementary',
  'Amravati University',
  'Amrita Vishwa Vidyapeetham (Deemed University)',
  'Amsterdam Elementary School',
  'Amsterdam High School',
  'Amsterdam School',
  'Amundsen High School',
  'Amur State University',
  'Amvet Boulevard',
  'Amy At Martin',
  'Amy B. Seibert Elementary',
  'Amy Belle Elementary',
  'Amy Beverland Elementary',
  'Amy Biehl Charter High School',
  'Amy Biehl Community School At Rancho Viejo',
  'Amy L. Houston Academy',
  'Amy Nw',
  'Amy Parks-heath El',
  'An-Najah National University',
  'Ana Delia Flores Santana Voc',
  'Ana Grace Academy Of The Arts Elementary School',
  'Ana Hernandez Usera (coqui - Elemental)',
  'Ana J. Candelas',
  'Ana L Rosa Tricoche (velazquez)',
  'Ana M. Javariz',
  'Ana Maria Negron',
  'Ana Pagan De Rodriguez',
  'Ana Roque De Duprey',
  'Ana Valldejuly (jauca)',
  'Anacapa Middle',
  'Anacoco Elementary School',
  'Anacoco High School',
  'Anaconda Jr High School',
  'Anaconda Sr High School',
  'Anacortes High School',
  'Anacortes Middle School',
  'Anacostia Hs',
  'Anadarko East Es',
  'Anadarko Hs',
  'Anadarko Ms',
  'Anadolu University',
  'Anaheim High',
  'Anaheim Hills Elementary',
  'Anahuac El',
  'Anahuac H S',
  'Anahuac Middle',
  'Anahuacalmecac International Univ Prep Of N. America',
  'Analy High',
  'Anambra State University of Science and Technology',
  'Anamoose Elementary School',
  'Anamosa High School',
  'Anamosa Middle School',
  'Anansi Charter School',
  'Anasazi Elementary',
  'Anatola Avenue Elementary',
  'Anaverde Hills',
  'Anawalt Elementary School',
  'Anchor',
  'Anchor Bay High School',
  'Anchor Bay Middle School North',
  'Anchor Bay Middle School South',
  'Anchorage Independent Public School',
  'Anchorage Stream Academy',
  'Ancilla College',
  'Anclote Elementary School',
  'Anclote High Adult Education',
  'Anclote High School',
  'Ancora Psychiatric Hospital',
  'Andale Elem-middle',
  'Andale High',
  'Andalucia Middle School',
  'Andalusia Elem School',
  'Andalusia Elementary School',
  'Andalusia High School',
  'Andalusia Junior High',
  'Andasol Avenue Elementary',
  'Andersen Community',
  'Andersen Elementary',
  'Andersen Es',
  'Anderson',
  'Anderson (alex) Elementary',
  'Anderson (linford L.) Elementary',
  'Anderson 7-8',
  'Anderson Academy',
  'Anderson Accelerated H S',
  'Anderson Add B Sch',
  'Anderson Community Day',
  'Anderson County High School',
  'Anderson County Jr/sr High School',
  'Anderson County Middle School',
  'Anderson Creek Academy',
  'Anderson Creek Primary',
  'Anderson Early Childhood',
  'Anderson El',
  'Anderson Elem',
  'Anderson Elem School',
  'Anderson Elem.',
  'Anderson Elementary',
  'Anderson Elementary School',
  'Anderson Es',
  'Anderson Five Career Campus',
  'Anderson Five Charter School',
  'Anderson Grove Elementary Sch',
  'Anderson H S',
  'Anderson Heights Elementary',
  'Anderson High',
  'Anderson High School',
  'Anderson Island Elementary',
  'Anderson Middle',
  'Anderson Middle School',
  'Anderson Mill El',
  'Anderson Mill Elementary',
  'Anderson New Technology High',
  'Anderson Preparatory Academy',
  'Anderson Public School',
  'Anderson School',
  'Anderson School (the)',
  'Anderson Valley Elementary',
  'Anderson Valley Junior-senior High',
  'Anderson W. Clark Magnet High',
  'Anderson-livsey Elementary School',
  'Anderson-shiro El',
  'Anderson-shiro Jr/sr H S',
  'Andersonville Elementary',
  'Andersonville Elementary School',
  'Andes Central Elementary - 02',
  'Andes Central High School - 01',
  'Andes Central Jr. High - 04',
  'Andes Central School',
  'Andhra University',
  'Andong National University',
  'Andover Central High School',
  'Andover Central Middle School',
  'Andover Ecademy',
  'Andover Elementary',
  'Andover Elementary School',
  'Andover High',
  'Andover High School',
  'Andover Middle School',
  'Andover School',
  'Andover West Middle',
  'Andrada Polytechnic High School',
  'Andrassy Gyula German Speaking University Budapest',
  'Andre Agassi College Preparatory Academy',
  'Andre El',
  'Andres And Maria Cardenas Elementary',
  'Andres C. Gonzalez',
  'Andres Duarte Arts Academy (tk-7)',
  'Andres Grillasca Salas',
  'Andres Valcarcel',
  'Andress H S',
  'Andrew A. Robinson Elementary School',
  'Andrew Avenue School',
  'Andrew B. Cushman School',
  'Andrew Carnegie Middle',
  'Andrew College',
  'Andrew Cooke Magnet Elem School',
  'Andrew Douglas School',
  'Andrew Elementary School',
  'Andrew G Schmidt Middle School',
  'Andrew G. Wright Middle',
  'Andrew J Brown Academy',
  'Andrew J Rickoff',
  'Andrew J Smith Elementary School',
  'Andrew Jackson Academy',
  'Andrew Jackson Elementary',
  'Andrew Jackson Elementary School',
  'Andrew Jackson High',
  'Andrew Jackson High School',
  'Andrew Jackson Middle',
  'Andrew Jackson Middle School',
  'Andrew Johnson Elementary School',
  'Andrew K. Demoski School',
  'Andrew Lewis Middle',
  'Andrew Maier El Sch',
  'Andrew Middle School',
  'Andrew Mitchell Elementary School',
  'Andrew Muller Primary School',
  'Andrew N. Christensen Middle',
  'Andrew P. Hill High',
  'Andrew T Morrow School',
  'Andrews Education Center',
  'Andrews El',
  'Andrews Elementary',
  'Andrews Elementary School',
  'Andrews H S',
  'Andrews Heights Elementary School',
  'Andrews High',
  'Andrews High School',
  'Andrews Middle',
  'Andrews University',
  'Andries Hudde School',
  'Andros Karperos',
  'Andrzej Frycz Modrzewski Cracow College',
  'Andy Dekaney H S',
  'Aneth Community School',
  'Angel A. Maldonado Bula',
  'Angel G Quintero Alfaro',
  'Angel Gelo Morales Morales (marias Iii)',
  'Angel Guerrero Lugo (aibonito Guerrero)',
  'Angel Millan Huertas',
  'Angel Oak Elementary',
  'Angel P. Millan Rohena',
  'Angel Rafael Diaz Colon',
  'Angel Ramos',
  'Angel Sandin Martinez',
  "Angel's Gate (continuation)",
  'Angela Calvani Alvarado',
  'Angela Cordero Bernard',
  'Angela Gerusa Leal El',
  'Angeles El Corcho',
  'Angeles Mesa Elementary',
  'Angeles Pastor (san Martin)',
  'Angeles University',
  'Angelica Gomez De Betancourt',
  'Angelica Toro (nueva)',
  'Angelina College',
  'Angell Elementary School',
  'Angell School',
  'Angelo Giaudrone Middle School',
  'Angelo L. Tomaso School',
  'Angelo Patri Middle School (the)',
  'Angelo Rodriguez High',
  'Angelo State University',
  'Angevine Middle School',
  'Angie Debo Es',
  'Angie Grant Elementary School',
  'Angier Elementary',
  'Angkor University',
  'Angle Inlet Elementary',
  'Angleton H S',
  'Angleton J H School',
  'Anglia Ruskin University',
  'Angling Road Elementary School',
  'Anglo-American University',
  'Angola High School',
  'Angola Middle School',
  'Angoon School',
  'Angus Elementary School',
  'Angus Valley Es',
  'Anhui Medical University',
  'Anhui Normal University',
  'Anhui Technical College of Water Resources and Hydroelectric Power',
  'Anhui University',
  'Anhui University of Finance and Economics',
  'Anhui University of Traditional Chinese Medicine',
  'Aniak Jr/sr High School',
  'Aniguiin School',
  'Animas 7-12 School',
  'Animas Elementary',
  'Animas High School',
  'Animas Middle',
  'Animas Valley Elementary School',
  'Animo City Of Champions Charter High',
  'Animo College Preparatory Academy',
  'Animo Ellen Ochoa Charter Middle',
  'Animo Florence-firestone Charter Middle',
  'Animo Inglewood Charter High',
  'Animo Jackie Robinson High',
  'Animo James B. Taylor Charter Middle',
  'Animo Jefferson Charter Middle',
  'Animo Leadership High',
  'Animo Mae Jemison Charter Middle',
  'Animo Pat Brown',
  'Animo Phillis Wheatley Charter Middle',
  'Animo Ralph Bunche Charter High',
  'Animo South Los Angeles Charter',
  'Animo Venice Charter High',
  'Animo Watts College Preparatory Academy',
  'Animo Western Charter Middle',
  'Animo Westside Charter Middle',
  'Anishinabe Academy',
  'Anita Scott El',
  'Anita White Carson Middle School',
  'Ankara Social Science University',
  'Ankara University',
  'Ankeny Centennial High School',
  'Ankeny High School',
  'Ann A. Mullen Middle School',
  'Ann Antolini School',
  'Ann Arbor Learning Community',
  'Ann Arbor Open At Mack School',
  'Ann Arbor Preschool And Family Center',
  'Ann Arbor Trail Magnet School',
  'Ann B. Leavenworth',
  'Ann Blanche Smith',
  'Ann Brock El At Oak Grove',
  'Ann G Mcguinness Elementar School',
  'Ann Hawkins Gentry Middle',
  'Ann J Kellogg School',
  'Ann Klein Forensic Center',
  'Ann L Dolsen Elementary School',
  'Ann Lynch Elementary School',
  'Ann M Garcia-enriquez Middle',
  'Ann Parish Elementary',
  'Ann Reid Early Childhood Center',
  'Ann Richards Middle',
  'Ann Simpson Davis Middle School',
  'Ann Smith Elementary',
  'Ann Sobrato High',
  'Ann Soldo Elementary',
  'Ann Storck Center Inc.',
  'Ann Street Elementary',
  'Ann Street School',
  'Ann Visger K5 Preparatory Academy',
  'Ann Whitney El',
  'Anna A. Borba Fundamental Elementary',
  'Anna Brochhausen School 88',
  'Anna C. Scott Elementary School',
  'Anna E. Norris School',
  'Anna Elementary School',
  'Anna F Booth Elementary School',
  'Anna H S',
  'Anna H. Rockwell School',
  'Anna Hause Elementary',
  'Anna Henry Elementary School',
  'Anna High School',
  'Anna Isd Early Childhood Center',
  'Anna Jarvis Elementary School',
  'Anna Jeffries Elementary',
  'Anna Junior High School',
  'Anna K. Davie Elementary',
  'Anna Kirchgater Elementary',
  'Anna Kyle Elementary',
  'Anna L. Klein',
  'Anna Lawrence Intermediate School',
  'Anna M. Glazier Elementary',
  'Anna M. Mccabe School',
  'Anna Maria Elementary School',
  'Anna Marie Ayers Elementary School',
  'Anna Marie Jacobson Elementary School',
  'Anna May Daulton El',
  'Anna Mcdonald Elem School',
  'Anna Mckenney Intermediate',
  'Anna Merritt Elementary School',
  'Anna Middle',
  'Anna Middle School',
  'Anna Reynolds School',
  'Anna S Kuhl Elementary School',
  'Anna Smith School',
  'Anna Strong Intermediate School',
  'Anna Tobeluk Memorial School',
  'Anna University',
  'Anna University of Technology Tirunelveli',
  'Anna Ware Jackson',
  'Anna Westin House',
  'Anna Willson School',
  'Anna Yates Elementary',
  'Anna-jonesboro High School',
  'Annabel C. Perry Pk-8',
  'Annalee Avenue Elementary',
  'Annamalai University',
  'Annandale Elementary',
  'Annandale Elementary School',
  'Annandale High',
  'Annandale Middle School',
  'Annandale Senior High',
  'Annandale Terrace Elementary',
  'Annapolis Elementary',
  'Annapolis High',
  'Annapolis High School',
  'Annapolis Middle',
  'Annapolis Road Academy',
  'Annawan Grade School',
  'Annawan High School',
  'Anne Arundel Community College',
  'Anne Arundel Evening High',
  'Anne Bailey Elementary School',
  'Anne Chesnutt Middle',
  'Anne Darling Elementary',
  'Anne E. Moncure Elementary',
  'Anne Fox Elem School',
  'Anne Frank El',
  'Anne Frank Inspire Academy',
  'Anne Hutchinson School',
  'Anne L Magee El',
  'Anne M Dorner Middle School',
  'Anne M Jeans Elem School',
  'Anne Mason Elementary School',
  'Anne Mccormick Sullivan El',
  'Anne Street Elementary School',
  'Anne Sullivan Elem School',
  'Anne Sullivan Elementary - 20',
  'Anne T. Dunphy School',
  'Anne Watson Elementary School',
  'Anne Wien Elementary',
  'Annehurst Elementary School',
  'Annette Officer Elementary',
  'Annette P. Edwins Elementary School',
  'Annette Perry El',
  'Annette Winn Elementary School',
  'Annex Charter School',
  'Annie Belle Clark Primary School',
  'Annie Burnside Elementary',
  'Annie Camp Jr. High School',
  'Annie E Fales',
  'Annie E. Cheney Kindergarten',
  'Annie E. Vinton School',
  'Annie Kellond Elementary School',
  'Annie L Sargent School',
  'Annie Lucy Williams Elementary School',
  'Annie Pennycook Elementary',
  'Annie Purl El',
  'Annie R. Mitchell',
  'Annie R. Morgan Elementary School',
  'Annie Sims El',
  'Annie Sullivan Middle School',
  'Annie Webb Blanton El',
  'Anniston Avenue Elementary School',
  'Anniston City Boot Camp School',
  'Anniston High School',
  'Anniston Middle School',
  'Annistown Elementary School',
  'Annsville Area School',
  'Annunciation Maternity Home',
  'Annville Cleona Hs',
  'Annville El Sch',
  'Annville-cleona Ms',
  'Anoka High School',
  'Anoka Middle School For The Arts',
  'Anoka Technical College',
  'Anoka-hennepin Regional High School',
  'Anoka-hennepin Regn Hs Summer Prog',
  'Anoka-hennepin Regn Night School',
  'Anoka-hennepin Specialty Program',
  'Anoka-hennepin Tech High School',
  'Anoka-​Ramsey Community College',
  'Anona Elementary School',
  'Another Choice Virtual Charter',
  'Another Course To College',
  'Ansel Adams',
  'Anselmo Rivera Matos',
  'Anselmo Villarubia',
  'Anselmo-merna Elementary-merna',
  'Anselmo-merna High School',
  'Anser Charter School',
  'Ansgar Larsen Elementary',
  'Ansley Elementary School',
  'Ansley High School',
  'Anson Academy',
  'Anson Charter Academy',
  'Anson Co. Early College High',
  'Anson El',
  'Anson Elementary School',
  'Anson H S',
  'Anson High School',
  'Anson Jones El',
  'Anson Middle',
  'Anson New Tech High',
  'Ansonia Elementary School',
  'Ansonia High School',
  'Ansonia Middle School',
  'Ansonia Pre-school At Ansonia Methodist',
  'Ansonville Elementary',
  'Ansted Elementary',
  'Ansted Middle',
  'Antalya international University',
  'Antelope Community Day',
  'Antelope Creek Elementary',
  'Antelope Crossing Middle',
  'Antelope Elementary',
  'Antelope High',
  'Antelope Hills Elementary',
  'Antelope Meadows Elementary',
  'Antelope Ridge Elementary School',
  'Antelope School',
  'Antelope Trails Elementary School',
  'Antelope Union High School',
  'Antelope Valley College',
  'Antelope Valley High',
  'Antelope Valley Learning Academy',
  'Antelope Valley Rop',
  'Antera Rosado Fuentes',
  'Anthem Elementary School',
  'Anthem School',
  'Anthon El',
  'Anthon Elementary',
  'Anthony A. Andrews School',
  'Anthony Burns Elementary',
  'Anthony C. Traina Elementary',
  'Anthony Carnevale Elementary',
  'Anthony Center',
  'Anthony Charter School',
  'Anthony El',
  'Anthony Elem',
  'Anthony Elementary',
  'Anthony Elementary School',
  'Anthony G. Bacich Elementary',
  'Anthony H S',
  'Anthony J. Infante School',
  'Anthony Louis Center',
  'Anthony Louis House',
  'Anthony Middle',
  'Anthony Middle School',
  'Anthony On-track Prek Center',
  'Anthony P. Mehfoud Elementary',
  'Anthony Rossi Middle School',
  'Anthony Saville Middle School',
  'Anthony Spangler Elementary',
  'Anthony V. Ceres Elementary School',
  'Anthony W. Ochoa Middle',
  'Anthony Wayne Elementary School',
  'Anthony Wayne High School',
  'Anthony Wayne Junior High School',
  'Anthony Wayne Middle School',
  'Antietam Elementary',
  'Antietam Ms/hs',
  'Antigo High',
  'Antigo Middle',
  'Antimony School',
  'Antioch Charter Academy',
  'Antioch Charter Academy Ii',
  'Antioch Comm High School',
  'Antioch Elem School',
  'Antioch Elementary',
  'Antioch Elementary School',
  'Antioch High',
  'Antioch High School',
  'Antioch Middle',
  'Antioch New England',
  'Antioch University',
  'Antioch University - Los Angeles',
  'Antioch University - Seattle',
  'Antioch Upper Grade School',
  'Antlers Hs',
  'Anton Chico Middle',
  'Anton Grdina',
  'Anton P E P',
  'Anton School',
  'Anton de Kom University',
  'Antonia B. Caimary',
  'Antonia Crater Elementary School',
  'Antonia Elem.',
  'Antonia Martinez',
  'Antonia Middle School',
  'Antonia Pantoja Community Charter School',
  'Antonia Pantoja Preparatory Academy',
  'Antonia Saez',
  'Antonia Saez Irizarry',
  'Antonio Badillo Hernandez',
  'Antonio Del Buono Elementary',
  'Antonio Dominguez Nieves',
  'Antonio Fernos Isern',
  'Antonio Geigel Paredes',
  'Antonio Gonzalez Middle',
  'Antonio Gonzalez Suarez (especializada)',
  'Antonio Pagan',
  'Antonio Paoli',
  'Antonio R. Barcelo',
  'Antonio Reyes',
  'Antonio Rivera',
  'Antonio Rosa Guzman (bo. Mariana)',
  'Antonio S Paoli (musica)',
  'Antonio S Pedreira (pre-tec)',
  'Antonio S. Pedreira',
  'Antonio Sarriera Egozcue',
  'Antonio Tulla Torres',
  'Antonio Valero Bernabe',
  'Antonio Vazquez Ramos (su La Loma)',
  'Antonio Velez Alvarado',
  'Antonito High School',
  'Antonito Middle School',
  'Antrim Elementary School',
  'Antwerp Local Elementary School',
  'Antwerp Local High School',
  'Antwerp Local Middle School',
  'Antwerp Primary School',
  'Anuenue School',
  'Anvil City Science Academy',
  'Anwatin Middle Com & Spanish D I',
  'Anza Elementary',
  'Anza Trail',
  'Anzar High',
  'Aoc-cochise County Juvenile Dentention Centre',
  'Aoc-graham County Juvenile Detention Center',
  'Aoc-pinal County Juvenile Detention Center',
  'Aoc-santa Cruz County Juvenile Detention Center',
  'Aoc-yavapai County Juvenile Detention Center',
  'Aoc-yuma County Juvenile Detention Center',
  'Aoc_gila County Juvenile Detention Center',
  'Aomori Chuoh Gakuin University',
  'Aomori Public College',
  'Aomori University',
  'Aomori University of Health and Welfare',
  'Aoy El',
  'Aoyama Gakuin University',
  'Apache El',
  'Apache Elem',
  'Apache Elementary',
  'Apache Elementary School',
  'Apache Es',
  'Apache Hs',
  'Apache Junction High School',
  'Apache Ms',
  'Apache Trail High School',
  'Apalachee Elementary School',
  'Apalachee High School',
  'Apalachicola Bay Charter School',
  'Apalachicola Forest Youth Academy',
  'Apalachin Elementary School',
  'Aparicio-levy Technical College',
  'Apex Academy',
  'Apex Elementary',
  'Apex Friendship High',
  'Apex Friendship Middle',
  'Apex High',
  'Apex Home School Enrichment Program',
  'Apex Middle',
  'Apison Elementary School',
  'Aplington / Parkersburg Middle School',
  'Aplington Elementary School',
  'Aplington Middle School',
  'Aplington Parkersburg High School',
  'Apollo',
  'Apollo Beach Elementary School',
  'Apollo Career Center',
  'Apollo Center',
  'Apollo Elem School',
  'Apollo Elementary',
  'Apollo Elementary School',
  'Apollo Es',
  'Apollo High',
  'Apollo High School',
  'Apollo J H',
  'Apollo Middle',
  'Apollo Middle School',
  'Apollo Senior High',
  'Apollo-ridge Elem Sch',
  'Apollo-ridge Hs',
  'Apollo-ridge Ms',
  'Apolo San Antonio',
  'Apolonia Valentin (felicia)',
  'Apopka Elementary',
  'Apopka High',
  'Apopka Middle',
  'Appalachia Elementary',
  'Appalachian School',
  'Appalachian State University',
  'Appanoose Elementary School',
  'Apperson Street Elementary',
  'Appex Collegiate Academy Charter School',
  'Apple Academy Charter Public',
  'Apple Blossom',
  'Apple Creek Elementary School',
  'Apple Creek Es',
  'Apple Glen Elementary School',
  'Apple Grove Elementary',
  'Apple Pie Preschool',
  'Apple Pie Ridge Elementary',
  'Apple Springs El',
  'Apple Springs H S',
  'Apple Valley Elementary',
  'Apple Valley High',
  'Apple Valley Middle',
  'Apple Valley School',
  'Apple Valley Senior High',
  'Apple Valley Unified Preschool Special Education',
  'Applegarth Elementary School',
  'Applegate Elementary School',
  'Appleman El Sch',
  'Apples Prek',
  'Appleton Bilingual School',
  'Appleton City Elem.',
  'Appleton City High',
  'Appleton Community 4k',
  'Appleton Cooperative Educational Center',
  'Appleton Elementary',
  'Appleton Elementary School',
  'Appleton Eschool',
  'Appleton Public Montessori',
  'Appleton Technical Academy',
  'Appleton Village School',
  'Appletree Early Learning Center Pcs Columbia Heights',
  'Appletree Early Learning Center Pcs Lincoln Park',
  'Appletree Early Learning Center Pcs Oklahoma Avenue',
  'Appletree Early Learning Center Pcs Southeast',
  'Appletree Early Learning Center Pcs Southwest',
  'Appleview',
  'Appleview Elementary School',
  'Applewood Elementary School',
  'Applications And Research Laboratory',
  'Applied Learning Acad',
  'Applied Science University',
  'Applied Technology Center',
  'Applied Technology Education Campus',
  'Applied Technology High School',
  'Appling County Elementary School',
  'Appling County High School',
  'Appling County Middle School',
  'Appling County Primary School',
  'Appling Middle School',
  'Appomattox County High',
  'Appomattox Elementary',
  'Appomattox Middle',
  'Appomattox Primary',
  "Appomattox Regional Governor's School",
  'Apponequet Regional High',
  'Appoquinimink High School',
  'Appoquinimink Preschool Center',
  'Apprentice Academy Hs Of Nc',
  'Apricot Valley Elementary',
  'April Lane Elementary',
  'Aps Early Beginnings - A Zoom Site',
  'Aps Online School',
  'Aps Preschool',
  'Aps-forrest Hills Academy',
  'Apshawa Elementary School',
  'Aptakisic Junior High School',
  'Aptitud Community Academy At Goss',
  'Aptos High',
  'Aptos Junior High',
  'Aptos Middle',
  'Aqqaluk High/noorvik Elementary',
  'Aquadale Elementary',
  'Aquarian Charter School',
  'Aquebogue Elementary School',
  'Aquidneck School',
  'Aquila Elementary',
  'Aquilino Caban',
  'Aquilla School',
  'Aquinas College',
  'Aquinas University',
  'Ar-we-va Elementary Community School',
  'Arab Academy for Banking and Financial Sciences',
  'Arab Academy for Science & Technology',
  'Arab American University - Jenin',
  'Arab Elementary School',
  'Arab European University',
  'Arab High School',
  'Arab Junior High School',
  'Arab Open University',
  'Arab Open University Kuwait Branch',
  'Arab Primary School',
  'Arabi Elementary School',
  'Arabia Mountain High School - Academy Of Engineering- Medic',
  'Arabian Gulf University',
  'Arabic Immersion Magnet School',
  'Aragon Avenue Elementary',
  'Aragon Elementary School',
  'Aragon High',
  'Aragon Middle',
  'Arak University',
  'Arak University of Medical Sciences',
  'Arak University of Technology',
  'Aransas Pass H S',
  'Aransas Pass Jjaep',
  'Arapaho Classical Magnet',
  'Arapaho-butler Es',
  'Arapaho-butler Hs',
  'Arapahoe Charter High School',
  'Arapahoe Charter School',
  'Arapahoe Community College',
  'Arapahoe Elementary',
  'Arapahoe Elementary School',
  'Arapahoe High School',
  'Arapahoe Ridge Elementary School',
  'Arapahoe Ridge High School',
  'Ararat Charter',
  'Araullo University',
  'Arba Minch University',
  'Arboga Elementary',
  'Arbolita Elementary',
  'Arbon Elementary School',
  'Arbor Academy',
  'Arbor Creek Elementary',
  'Arbor Creek Middle',
  'Arbor Elementary School',
  'Arbor Grove Es',
  'Arbor Heights Elementary School',
  'Arbor Hill Elementary School',
  'Arbor Park Middle School',
  'Arbor Preparatory High School',
  'Arbor Ridge K-8',
  'Arbor Springs Elementary',
  'Arbor Station Elementary School',
  'Arbor View Ech. Special Ed.',
  'Arbor View Elem School',
  'Arbor View Elementary School',
  'Arbor View High School',
  'Arbor Vitae-woodruff Elementary',
  'Arboretum Elementary',
  'Arborwood Elementary School',
  'Arbuckle Alternative High (continuation)',
  'Arbuckle Elementary',
  'Arbury Hills Elem School',
  'Arbutus Elementary',
  'Arbutus Middle',
  'Arc',
  'Arc Birth-3',
  'Arc Broward Inc.',
  'Arcade Elementary School',
  'Arcade Fundamental Middle',
  'Arcadia Charter School',
  'Arcadia College',
  'Arcadia Elem School',
  'Arcadia Elementary',
  'Arcadia Elementary School',
  'Arcadia High',
  'Arcadia High School',
  'Arcadia Middle',
  'Arcadia Middle School',
  'Arcadia Park El',
  'Arcadia School',
  'Arcadia Valley Career Tech Ctr',
  'Arcadia Valley Elem.',
  'Arcadia Valley High',
  'Arcadia Valley Middle',
  'Arcado Elementary School',
  'Arcanum Early Learning Center',
  'Arcanum Elementary School',
  'Arcanum High School',
  'Arcata Elementary',
  'Arcata High',
  'Archbold Elementary School',
  'Archbold High School',
  'Archbold Middle School',
  'Archcreek Elementary School',
  'Archdale Elementary',
  'Archdale-trinity Middle',
  'Archer City El',
  'Archer City H S',
  'Archer Elementary',
  'Archer Elementary School',
  'Archer Glen Elementary School',
  'Archer High School',
  'Archer Lodge Middle',
  'Archer Street School',
  'Archie Clayton Pre-ap Academy Middle School',
  'Archie High',
  'Archie R. Cole Ms',
  'Archie T Morrison',
  'Archimedean Academy',
  'Archimedean Middle Conservatory',
  'Archimedean Upper Conservatory',
  'Archimedes Acad-math Sci Tech',
  'Architecture Construction & Engineering Charter High (ace)',
  'Arco Elementary School',
  'Arco Iris Spanish Immersion School',
  'Arcohe Elementary',
  'Arcola Bridges/beacons Rssp',
  'Arcola Elementary',
  'Arcola Elementary School',
  'Arcola High School',
  'Arcola Intrmd Sch',
  'Arcola Lake Elementary School',
  'Arcola School',
  'Arctic Light Elementary',
  'Arctic Village School',
  'Ardabil University of Medical Sciences',
  'Ardahan University',
  'Arden Elementary',
  'Arden Middle',
  'Arden Road El',
  'Ardena Elementary School',
  'Ardenwald Elementary School',
  'Ardenwood Elementary',
  'Ardis G. Egan Junior High',
  'Ardmore Avenue Sch',
  'Ardmore Elem School',
  'Ardmore Elementary',
  'Ardmore Elementary School',
  'Ardmore High School',
  'Ardmore Hs',
  'Ardmore Ms',
  'Ardrey Kell High',
  'Ardsley High School',
  'Ardsley Middle School',
  'Area 30 Career Center',
  'Area 31 Career & Tech Center',
  'Area Adult Learning Cooperative',
  'Area Career Center',
  'Area Learning Center Summer',
  'Arellanes (don Juan Bautista) Elementary',
  'Arellanes Junior High',
  'Arellano University',
  'Arena Elementary',
  'Arena High (continuation)',
  'Arenac Eastern Elementarymiddle School',
  'Arenac Eastern High School',
  'Arendtsville El Sch',
  'Arete Charter Academy',
  'Argent Preparatory Academy',
  'Argenta-oreana Elementary School',
  'Argenta-oreana High School',
  'Argenta-oreana Middle School',
  'Argentine Elementary School',
  'Argentine Middle',
  'Argillite Elementary School',
  'Argo Community High School',
  'Argonaut Elementary',
  'Argonaut High',
  'Argonia Elem',
  'Argonia High',
  'Argonne Elementary',
  'Argos Comm Jr-sr High School',
  'Argos Community Elementary',
  'Argosy Collegiate Charter School',
  'Argus High (continuation)',
  'Argyle Elementary',
  'Argyle Elementary School',
  'Argyle H S',
  'Argyle High',
  'Argyle Int',
  'Argyle Junior/senior High School',
  'Argyle Middle',
  'Arhangelsk State Technical University',
  'Aria Institute of Higher Education',
  'Arickaree Elementary School',
  'Arickaree Undivided High School',
  'Arid Agriculture University',
  'Ariel Elem Community Academy',
  'Ariel University Center of Samaria',
  'Aris',
  'Arise Academy',
  'Arise Academy Charter High School',
  'Arise High',
  'Arise Shelter Care - 71',
  'Arise Virtual Academy',
  'Aristides Cales Quiros',
  'Aristoi Classical Academy',
  'Aristoi Classical Upper School',
  'Aristotle Academy',
  'Aristotle Preparatory Academy',
  'Aristotle University of Thessaloniki',
  'Ariton School',
  'Arizona Academy Of Science And Technology',
  'Arizona Agribusiness & Equine Center - Estrella',
  'Arizona Agribusiness & Equine Center Inc. - Mesa',
  'Arizona Agribusiness & Equine Center Inc. - Prescott Valley',
  'Arizona Agribusiness & Equine Center Inc. - Red Mountain',
  'Arizona Autism Charter School',
  'Arizona Autism Charter School Upper School Campus',
  'Arizona Call-a-teen Center For Excellence',
  'Arizona Charter Academy',
  'Arizona City Elementary School',
  'Arizona College Prep Academy',
  'Arizona College Prep Erie Campus',
  'Arizona College Prep Oakland Campus',
  'Arizona Collegiate High School',
  'Arizona Connections Academy',
  'Arizona Conservatory For Arts And Academics',
  'Arizona Conservatory For Arts And Academics Elementary Schoo',
  'Arizona Conservatory For Arts And Academics Middle School',
  'Arizona Desert Elementary School',
  'Arizona Fleming El',
  'Arizona Insight Academy',
  'Arizona Izone Institute Online Program',
  'Arizona Language Preparatory',
  'Arizona Middle',
  'Arizona Preparatory Academy',
  'Arizona School For The Arts',
  'Arizona State University',
  'Arizona Virtual Academy',
  'Arizona Western College',
  'Arizona World School',
  'Ark Consolidated High School',
  'Ark Independent Studies',
  'Ark. School For The Blind Elem',
  'Ark. School For The Blind H.s.',
  'Ark. School For The Deaf Elem.',
  'Ark. School For The Deaf H.s.',
  'Arkadelphia High School',
  'Arkansas Arts Academy Elementary/middle School',
  'Arkansas Arts Academy High School',
  'Arkansas City High',
  'Arkansas City Middle Sch',
  'Arkansas Connections Academy Elementary',
  'Arkansas Connections Academy High',
  'Arkansas Consolidated High School - Dermott #3',
  'Arkansas Elementary School',
  'Arkansas High School',
  'Arkansas Northeastern College',
  'Arkansas State University',
  'Arkansas State University-​Beebe',
  'Arkansas State University-​Mountain Home',
  'Arkansas State University-​Newport',
  'Arkansas Tech University',
  'Arkansas Virtual Academy',
  'Arkansas Virtual Academy High School',
  'Arkansas Virtual Academy Jr',
  'Arkoma Hs',
  'Arkoma Jhs',
  'Arkport Central School',
  'Arland D Williams Jr Elem Sch',
  'Arlanza Elementary',
  'Arlee 7-8',
  'Arlee Elementary',
  'Arlee High School',
  'Arlene Hein Elementary',
  'Arlene Welch Elementary School',
  'Arleta Elementary School',
  'Arleta High',
  'Arlie Boggs Elementary School',
  'Arlie F. Hutchinson Middle',
  'Arlington',
  'Arlington Classics Academy - Middle',
  'Arlington Classics Academy - Pri',
  'Arlington Classics Academy -intermediate',
  'Arlington Collegiate H S',
  'Arlington Community Charter School',
  'Arlington Community High',
  'Arlington Community Middle School',
  'Arlington Elementary',
  'Arlington Elementary - 02',
  'Arlington Elementary School',
  'Arlington Elementary/middle',
  'Arlington H S',
  'Arlington Heights El Sch',
  'Arlington Heights Elem School',
  'Arlington Heights Elementary',
  'Arlington Heights Elementary Sch',
  'Arlington Heights Elementary School',
  'Arlington Heights H S',
  'Arlington High',
  'Arlington High School',
  'Arlington High School - 01',
  'Arlington Jr. High - 03',
  'Arlington Local Elementary School',
  'Arlington Local High School',
  'Arlington Memorial',
  'Arlington Middle',
  'Arlington Middle School',
  'Arlington Open Doors',
  'Arlington Park Early Childhood Center',
  'Arlington Preparatory Academy',
  'Arlington Preschool - 04',
  'Arlington School',
  'Arlington Science Focus School',
  'Arlington Special Educ School',
  'Arlington Traditional',
  'Arlington Woods School 99',
  'Arlon R Seay El',
  'Arlyne & Alan Weber El',
  'Arma J. Shull Elementary',
  'Armada Continuing Education Center',
  'Armada Elementary',
  'Armada High School',
  'Armada Middle School',
  'Armadillo Technical Institute',
  'Armand Bayou El',
  'Armand Larive Middle School',
  'Armand R. Dupont School',
  'Armando Cerna El',
  'Armando Chapa Middle',
  'Armando Cuellar Middle',
  'Armatage Elementary',
  'Armed Forces Academy of General Milan Rastislav Štefánik',
  'Armel Elementary',
  'Armendariz Middle',
  'Armenian State Agrarian University',
  'Armenian State University of Economics',
  'Armijo Elementary',
  'Armijo High',
  'Armin Jahr Elementary',
  'Arminta Street Elementary',
  'Armistead Gardens Elementary',
  'Armona Elementary',
  'Armor Elementary School',
  'Armorel Elementary School',
  'Armorel High School',
  'Armour Elem School',
  'Armour Elementary - 02',
  'Armour High School - 01',
  'Armour Middle School - 03',
  'Armstrong Credit Recovery',
  'Armstrong El',
  'Armstrong Elem.',
  'Armstrong Elementary',
  'Armstrong Elementary School',
  'Armstrong G Elem Intl Studies',
  'Armstrong High',
  'Armstrong High School',
  'Armstrong Jshs',
  'Armstrong Middle',
  'Armstrong Middle School',
  'Armstrong Ms',
  'Armstrong School',
  'Armstrong State College',
  'Armstrong-ellis Elem School',
  'Armstrong-oakview Elem School',
  'Armuchee Elementary School',
  'Armuchee High School',
  'Armuchee Middle School',
  'Armwood High School',
  'Army Trail Elem School',
  'Arnall Middle School',
  'Arnaudville Elementary School',
  'Arnco-sargent Elementary School',
  'Arnett C Lines Elem School',
  'Arnett Elementary School',
  'Arnett Es',
  'Arnett Hs',
  'Arno Elementary School',
  'Arnold Adreani Elementary',
  'Arnold Drive Elementary School',
  'Arnold El',
  'Arnold Elementary',
  'Arnold Elementary School',
  'Arnold High School',
  'Arnold J Tyler School',
  'Arnold Memorial Elementary',
  'Arnold Middle',
  'Arnold Middle School',
  'Arnold Mill Elementary School',
  'Arnold O. Beckman High',
  'Arnold W Kruse Ed Center',
  'Arnoldo Cantu Sr El',
  'Arnoldsburg School',
  'Aromas',
  'Arongen Elementary School',
  'Aronimink El Sch',
  'Arovista Elementary',
  'Arp El',
  'Arp Elementary',
  'Arp H S',
  'Arp J H',
  'Arredondo Elementary School',
  'Arrey Elementary',
  'Arrie Goforth Elementary School',
  'Arrington Middle School',
  'Arrow Academy - Champions Academy',
  'Arrow Academy - Harvest Preparatory Academy',
  'Arrow Academy - Liberation Academy',
  'Arrow Academy - Save Our Streets Center',
  'Arrow Academy Of Excellence',
  'Arrow Creek Elem School',
  'Arrow High (continuation)',
  'Arrow Springs Early Childhood',
  'Arrowhead 7-8',
  'Arrowhead El Sch',
  'Arrowhead Elementary',
  'Arrowhead Elementary School',
  'Arrowhead Es',
  'Arrowhead High',
  'Arrowhead Juvenile Center',
  'Arrowhead Middle',
  'Arrowhead Park Medical Academy',
  'Arrowhead Primary Elementary School',
  'Arrowhead School',
  'Arrowood Elementary',
  'Arrowpoint Elem.',
  'Arrowview Middle',
  'Arrowwood Elementary School',
  'Arroyo',
  'Arroyo Del Norte Elementary',
  'Arroyo Del Oso Elementary',
  'Arroyo Elementary',
  'Arroyo Elementary School',
  'Arroyo Grande High',
  'Arroyo High',
  'Arroyo Paseo Charter High',
  'Arroyo Seco Elementary',
  'Arroyo Seco Junior High',
  'Arroyo Seco Museum Science',
  'Arroyo Valley High',
  'Arroyo Verde Elementary',
  'Arroyo Vista Charter',
  'Arroyo Vista Elementary',
  'Arroyo Vista Middle',
  'Arroyo West Elementary',
  'Arsenal Technical High School',
  'Arsenio Martinez',
  'Art & Science Academy Middle School',
  'Art And Design High School',
  'Art And Pat Goforth El',
  'Art And Science Academy',
  'Art City School',
  'Art Freiler',
  'Art Goad Es',
  'Art Haycox Elementary',
  'Art University',
  'Arte I Rptcs (regional Professional Technical Charter School',
  'Artec Charter School',
  'Artemus Ward',
  'Artesia High',
  'Artesia Park Junior High',
  'Artesia Zia Intermediate',
  'Arthur A. Benjamin Health Professions High',
  'Arthur A. Richards Junior High School',
  'Arthur Academy',
  'Arthur And Polly Mays Conservatory Of The Arts',
  'Arthur Ashe Charter School',
  'Arthur Ashe Jr. Elementary',
  'Arthur C Clarke Institute of Modern Technologies',
  'Arthur C Newby Elementary School 2',
  'Arthur C. Butler Elementary',
  'Arthur Chester A Sch',
  'Arthur Circle Elementary School',
  'Arthur County Elementary School',
  'Arthur County High School',
  'Arthur D Healey',
  'Arthur E. Mills Intermediate',
  'Arthur E. Wright Middle',
  'Arthur Eddy Academy',
  'Arthur Elementary School',
  'Arthur Es',
  'Arthur F. Corey Elementary',
  'Arthur F. Smith Middle Magnet School',
  'Arthur Froberg Elem School',
  'Arthur Grade School',
  'Arthur H Mcneil El',
  'Arthur Hapgood Elementary',
  'Arthur Hill High School',
  'Arthur I Boreman Elementary School',
  'Arthur Jacobsen Elementary',
  'Arthur Kramer El',
  'Arthur L Davila Middle',
  'Arthur L. Johnson High School',
  'Arthur M Hamilton School',
  'Arthur M Longsjo Middle School',
  'Arthur M. Judd',
  'Arthur Middleton Elementary School',
  'Arthur Miller Career Center',
  'Arthur P Momot Elementary School',
  'Arthur P Schalick High School',
  'Arthur R. Gould Sch--lcydc',
  'Arthur R. Ware Elementary',
  'Arthur Rann Elementary School',
  'Arthur Road Elementary School',
  'Arthur Robert Ashe Center',
  'Arthur S May School',
  'Arthur S. Dudley Elementary',
  'Arthur Stanlick Elementary School',
  'Arthur Street El Sch',
  'Arthur T Talmadge',
  'Arthur T. Cummings Elementary School',
  'Arthur W Coolidge Middle',
  'Arthur W Edwards Elementary',
  'Arthur W Watson Jr Elementary Sch',
  'Arthur Williams Middle School',
  'Arthur-lovington-atwood-hammond',
  'Artie L Henry Middle',
  'Artman El Sch',
  'Artondale Elementary School',
  'Arts & Academics Academy',
  'Arts & College Preparatory Academy',
  'Arts & Humanities Focus Prgm',
  'Arts Academy At Bauer Speck Elementary',
  'Arts Academy At Bella Vista',
  'Arts Academy At Benjamin Rush',
  'Arts Academy At Estrella Mountain',
  'Arts Academy Cs',
  'Arts Academy Elementary Charter School',
  'Arts Academy In The Woods',
  'Arts Academy Of Excellence',
  'Arts And Communication Magnet Academy',
  'Arts And Media Preparatory Academy',
  'Arts And Technology Academy At Jefferson',
  'Arts And Technology Academy Of Pontiac',
  'Arts And Technology High School',
  'Arts At The Capitol Theater Magnet School (act)',
  'Arts Communications Academy At Fair Plain',
  'Arts High School',
  'Arts Impact Middle School (aims)',
  'Arts In Action Community Charter',
  'Arts In Action Community Middle',
  'Arts Sciences and Technology University',
  'Artspace Charter School',
  'Arturo A Schomburg Satellite Academy Bronx',
  'Arturo Cambeiro Elementary School',
  'Arturo Lluberas',
  'Arturo Salazar El',
  'Arturo Sanchez Elementary',
  'Arturo Somohano',
  'Artvin Coruh University',
  'Artz Fox Elementary',
  'Arundel Elementary',
  'Arundel Elementary/middle',
  'Arundel High',
  'Arundel Middle',
  'Arvada Elementary',
  'Arvada High School',
  'Arvada K-8',
  'Arvada West High School',
  'Arvida Middle School',
  'Arvin High',
  'Arviq School',
  'Arvon Township School',
  'Asa C Adams School',
  'Asa Charter',
  'Asa Clark Middle',
  'Asa E Low Jr Int',
  'Asa Hilliard Elementary School',
  'Asa Messer El. School',
  'Asa Packer El Sch',
  'Asa University Bangladesh',
  'Asahi University',
  'Asahikawa Medical College',
  'Asahikawa University',
  'Asawa (ruth) Sf Sch Of The Arts A Public School',
  'Asbell Elementary School',
  'Asbury Academy',
  'Asbury El Sch',
  'Asbury Elementary School',
  'Asbury High School',
  'Asbury Park High School',
  'Ascarate El',
  'Ascend',
  'Ascend Career Academy',
  'Ascend Leadership Academy: Lee County',
  'Ascent Academies Of Utah - West Valley',
  'Ascent Academies Of Utah Farmington',
  'Ascent Academies Of Utah Lehi',
  'Ascent Academies Of Utah West Jordan',
  'Ascent High School',
  'Ascher Silberstein El',
  'Ascot Avenue Elementary',
  'Asd Lab',
  'Asd Program',
  'Asdb/phoenix Day School',
  'Asdb/tucson Blind School',
  'Asdb/tucson Deaf School',
  'Ash Creek Elementary',
  'Ash Creek Elementary School',
  'Ash Fork Elementary School',
  'Ash Fork High School',
  'Ash Fork Middle School',
  'Ash Grove Elem.',
  'Ash Grove High',
  'Ash H S',
  'Ashaway Elementary School',
  'Ashbel Smith El',
  'Ashbrook Elementary School',
  'Ashbrook High',
  'Ashburn Community Elem School',
  'Ashburn Elementary',
  'Ashburton Elementary',
  'Ashby Elementary',
  'Ashby Lee Elementary',
  'Ashby Secondary',
  'Ashdown High School',
  'Ashdown Junior High School',
  'Ashe County Early College High',
  'Ashe County High',
  'Ashe County Middle',
  'Ashe Elem School',
  'Asheboro High',
  'Asher Es',
  'Asher Holmes Elementary School',
  'Asher Hs',
  'Asher School',
  'Asherton El',
  'Ashesi University',
  'Asheville High',
  'Asheville Middle',
  'Asheville Primary',
  'Asheville-​Buncombe Technical Community College',
  'Ashfield Middle School',
  'Ashford El',
  'Ashford Elementary School',
  'Ashford High School',
  'Ashford Park Elementary School',
  'Ashford School',
  'Ashford-rumble Elementary',
  'Ashgrove Elementary',
  'Ashikaga Institute of Technology',
  'Ashiya University',
  'Ashkelon Academic College',
  'Ashkum Early Literacy Center',
  'Ashland 7-8',
  'Ashland City Elementary',
  'Ashland Community and Technical College',
  'Ashland County Community Academy',
  'Ashland District School',
  'Ashland Elem',
  'Ashland Elem. And Br.',
  'Ashland Elementary',
  'Ashland Elementary School',
  'Ashland Head Start',
  'Ashland High',
  'Ashland High School',
  'Ashland Junior High School',
  'Ashland Middle',
  'Ashland Middle School',
  'Ashland Park/robbins Elem Sch',
  'Ashland Ridge Elementary',
  'Ashland School',
  'Ashland Technical College',
  'Ashland University',
  'Ashland-greenwood Elem School',
  'Ashland-greenwood High School',
  'Ashland-greenwood Middle Sch',
  'Ashland-w Holmes Career Center',
  'Ashlawn Elementary',
  'Ashley Academy',
  'Ashley Chapel Educational Center',
  'Ashley Com Consolidated School',
  'Ashley El',
  'Ashley Elementary',
  'Ashley Elementary School',
  'Ashley Falls Elementary',
  'Ashley High School',
  'Ashley Middle School',
  'Ashley Park Prek-8 School',
  'Ashley Ridge High',
  'Ashley River Creative Arts Elementary',
  'Ashley School',
  'Ashley Valley Educ. Ctr.',
  'Ashman School',
  'Ashmore Elem School',
  'Ashtabula County Technical And Career Campus',
  'Ashton College',
  'Ashton Elementary School',
  'Ashton Ranch Elementary School',
  'Ashton School',
  'Ashton-franklin Center Elem Sch',
  'Ashton-franklin Center Hs',
  'Ashton-franklin Cntr Middle Sch',
  'Ashville Elementary School',
  'Ashville High School',
  'Ashville Middle School',
  'Ashwaubenon High',
  'Ashwood Elementary School',
  'Ashworth El',
  'Ashworth Middle School',
  'Asia E University',
  'Asia Europe University',
  'Asia Pacific Institute of Information Technology (APIIT)',
  'Asia Pacific Institute of Information Technology Pakistan (APIIT PAK)',
  'Asia University',
  'Asian Human Srvcs-passage Chrtr',
  'Asian Institute of Technology',
  'Asian Management Institute',
  'Asian Medical Institute',
  'Asian Studies Academy At Bellizzi School',
  'Asian University of Bangladesh',
  'Asian University of Science and Technology',
  'Askew El',
  'Asnuntuck Community College',
  'Asotin Elementary',
  'Asotin Jr Sr High',
  'Asp - Florence West/a.c.i.',
  'Asp - Marana O.c.t.f./a.c.i.',
  'Asp - Phoenix West/a.c.i.',
  'Aspc - Douglas/a.c.i.',
  'Aspc - Safford/ft. Grant/a.c.i.',
  'Aspc-eyman',
  'Aspc-florence',
  'Aspc-phoenix/globe',
  'Aspc-winslow',
  'Aspen Academy',
  'Aspen Community Charter School',
  'Aspen Community Magnet School',
  'Aspen Creek Ec Center',
  'Aspen Creek Elementary School',
  'Aspen Creek Es',
  'Aspen Creek K-8 School',
  'Aspen Creek Middle School',
  'Aspen Crossing Elementary School',
  'Aspen Early Learning Center',
  'Aspen Elementary',
  'Aspen Elementary School',
  'Aspen High School',
  'Aspen Meadow Public',
  'Aspen Middle School',
  'Aspen Pre-school',
  'Aspen Ridge Elementary School',
  'Aspen Ridge Preparatory School',
  'Aspen School',
  'Aspen Valley Campus',
  'Aspen Valley Prep Academy',
  'Aspen View Academy',
  'Aspermont El',
  'Aspermont H S',
  'Aspira Arts Deco Charter',
  'Aspira Bilingual Cyber Charter School',
  'Aspira Charter - Bus And Fin',
  'Aspira Charter - Early College Pr',
  'Aspira Charter - Haugan Campus',
  'Aspira Charter Schools',
  'Aspira Leadership And College Preparatory Academy',
  'Aspira Raul Arnaldo Martinez Charter School',
  'Aspirations Diploma Plus High School',
  'Aspire Academy',
  'Aspire Academy Charter',
  'Aspire Academy High School',
  'Aspire Alexander Twilight College Preparatory Academy',
  'Aspire Alexander Twilight Secondary Academy',
  'Aspire Alternative High School',
  'Aspire Antonio Maria Lugo Academy',
  'Aspire Apex Academy',
  'Aspire Benjamin Holt College Preparatory Academy',
  'Aspire Benjamin Holt Middle School',
  'Aspire Berkley Maynard Academy',
  'Aspire Capitol Heights Academy',
  'Aspire Centennial College Preparatory Academy',
  'Aspire Charter Academy',
  'Aspire Coleman',
  'Aspire College Academy',
  'Aspire Community Day',
  'Aspire East Academy',
  'Aspire East Palo Alto Charter',
  'Aspire Eres Academy',
  'Aspire Firestone Academy Charter',
  'Aspire Gateway Academy Charter',
  'Aspire Golden State College Preparatory Academy',
  'Aspire Hanley Elementary',
  'Aspire High School',
  'Aspire Inskeep Academy Charter',
  'Aspire Juanita Tate Academy Charter',
  'Aspire Junior Collegiate Academy',
  'Aspire Langston Hughes Academy',
  'Aspire Leadership Academy',
  'Aspire Lionel Wilson College Preparatory Academy',
  'Aspire Middle School',
  'Aspire Monarch Academy',
  'Aspire Ollin University Preparatory Academy',
  'Aspire Pacific Academy',
  'Aspire Port City Academy',
  'Aspire Richmond Ca. College Preparatory Academy',
  'Aspire Richmond Technology Academy',
  'Aspire River Oaks Charter',
  'Aspire Rosa Parks Academy',
  'Aspire Slauson Academy Charter',
  'Aspire Summit Charter Academy',
  'Aspire Titan Academy',
  'Aspire Triumph Technology Academy',
  'Aspire University Charter',
  'Aspire Vanguard College Preparatory Academy',
  'Aspire Vincent Shalvey Academy',
  'Assabet Valley Vocational High School',
  'Assam Agricultural University',
  'Assam University',
  'Assata High',
  'Assawompset Elementary School',
  'Assessment Center Elem',
  'Assessment Center Sec',
  'Assessment Ctr',
  'Assets',
  'Assist Rssp 12',
  'Assist Taoep 12',
  'Assiut University',
  'Assosa University',
  'Assumption College',
  'Assumption College for Sisters',
  'Assumption High School',
  'Assumption University',
  'Assumption University of Thailand',
  'Assurance Learning Academy',
  'Astatula Elementary School',
  'Astec Charter Hs',
  'Astec Charter Ms',
  'Aston El Sch',
  'Aston University',
  'Astor Collegiate Academy',
  'Astor Elementary School',
  'Astoria Elem School',
  'Astoria High School',
  'Astoria Junior High School',
  'Astoria Middle School',
  'Astoria Park Elementary School',
  'Astoria Senior High School',
  'Astrahan State Medical Academy',
  'Astrahan State Technical University',
  'Astrakhan State University',
  'Astronaut High School',
  'Astumbo Elementary School',
  'Astumbo Middle School',
  'Asu Academy At Middle Fork',
  'Asu Area Career Center',
  'Asu Preparatory Academy - Casa Grande Hs',
  'Asu Preparatory Academy - Phoenix Middle School',
  'Asu Preparatory Academy Digital',
  'Asu Preparatory Academy Tempe',
  'Asu Preparatory Academy- Phoenix Elementary',
  'Asu Preparatory Academy- Phoenix High School',
  'Asu Preparatory Academy-polytechnic Elementary',
  'Asu Preparatory Academy-polytechnic High School',
  'Asu Preparatory Academy-polytechnic Middle School',
  'Asuncion Rodriguez De Sala',
  'Atalaya Elementary',
  'Atall Elementary - 17',
  'Atascadero High',
  'Atascadero Middle',
  'Atascocita H S',
  'Atascocita Middle',
  'Atascocita Springs El',
  'Atascosa Co Alter',
  'Atascosa Co Juvenile Unit',
  'Atascosa County Jjaep',
  'Atascosa County Juvenile Justice Center',
  'Atascosa Juvenile Detention Ctr',
  'Atatürk University',
  'Atchison County Community Elementary School',
  'Atchison County Community Jr/sr High',
  'Atchison Elementary School',
  'Atchison High School',
  'Atchison Middle School',
  'Atco Elementary',
  'Ateneo de Davao University',
  'Ateneo de Manila University',
  'Ateneo de Naga University',
  'Ateneo de Zamboanga University',
  'Atha Road Elementary School',
  'Athabasca University',
  'Athena Elem.',
  'Athena Elementary School',
  'Athena High School',
  'Athena Middle School',
  'Athenaeum Pontificium Regina Apostolorum',
  'Atheneum Elementary',
  'Athenian Academy',
  'Athenian Academy Charter School',
  'Athenian Academy Of Pasco County',
  'Athenian Eacademy',
  'Athenour Early Childhood Education Center',
  'Athens Area Hs',
  'Athens City Middle School',
  'Athens Community School',
  'Athens Drive High',
  'Athens El',
  'Athens Elementary',
  'Athens Elementary School',
  'Athens Graduate School of Management (AGSM)',
  'Athens H S',
  'Athens High',
  'Athens High School',
  'Athens Intermediate School',
  'Athens Junior High School',
  'Athens Juniorsenor High School',
  'Athens Laboratory of Business Administration (ALBA)',
  'Athens Middle',
  'Athens Middle School',
  'Athens Renaissance School',
  'Athens School of Fine Arts',
  'Athens Sr High School',
  'Athens Technical College',
  'Athens University of Economics and Business',
  'Athens-chilesburg Elementary',
  'Atherton El',
  'Atherton Elementary School',
  'Atherton High',
  'Atherton Hough',
  'Atherton Jr Sr High School',
  'Atherton Vern Van Y Educational Center',
  'Atherwood Elementary',
  'Athey Creek Middle School',
  'Athlone Institute of Technology',
  'Athlos Academy Of Saint Cloud',
  'Athlos Academy Of Utah',
  'Athlos Leadership Academy',
  'Athol Community Elementary School',
  'Athol Elementary School',
  'Athol High',
  'Athol-royalston Middle School',
  'Atholton Elementary',
  'Atholton High',
  'Atilim University',
  'Atish Dipankar University',
  'Atkins Academic & Tech High',
  'Atkins Elementary',
  'Atkins Elementary School',
  'Atkins High School',
  'Atkins Middle',
  'Atkins Middle School',
  'Atkins Technology Elementary School',
  'Atkinson',
  'Atkinson Academy',
  'Atkinson Academy Charter',
  'Atkinson County High School',
  'Atkinson County Middle School',
  'Atkinson El',
  'Atkinson Elementary',
  'Atkinson Elementary School',
  'Atlanta Area School For The Deaf',
  'Atlanta Classical Academy',
  'Atlanta Community Schools',
  'Atlanta El',
  'Atlanta Elem.',
  'Atlanta H S',
  'Atlanta Heights Charter School',
  'Atlanta High',
  'Atlanta High School',
  'Atlanta Middle',
  'Atlanta Neighborhood Charter - Elementary',
  'Atlanta Neighborhood Charter - Middle',
  'Atlanta Pri',
  'Atlanta Technical College',
  'Atlanta Youth Detention Center',
  'Atlantic Ave',
  'Atlantic Beach Elementary School',
  'Atlantic Cape Community College',
  'Atlantic City High School',
  'Atlantic Co Juv Det Ctr',
  'Atlantic Coast High School',
  'Atlantic Community Charter School',
  'Atlantic County Alternative High School',
  'Atlantic County Elementary And Middle Schools',
  'Atlantic County High School',
  'Atlantic County Institute Of Technology',
  'Atlantic Elementary',
  'Atlantic High Adult Education Center',
  'Atlantic High School',
  'Atlantic Highlands Elementary School',
  'Atlantic Middle',
  'Atlantic Middle School',
  'Atlantic Montessori Charter School',
  'Atlantic Montessori Charter School West Campus',
  'Atlantic Regional School',
  'Atlantic Technical College',
  'Atlantic Transitional Education Center',
  'Atlantic West Elementary School',
  'Atlantis Charter School',
  'Atlantis Elementary School',
  'Atlas Elementary School',
  'Atlas Preparatory High School',
  'Atlas Preparatory Middle School',
  'Atlee High',
  'Atmosphere Academy Public Charter School',
  'Atoka Elementary School',
  'Atoka Es',
  'Atoka Hs',
  'Atomi College',
  'Ator Es',
  'Atrisco Elementary',
  'Atrisco Heritage Academy Hs',
  'Atsa Biyaazh Community School',
  'Attain',
  'Attalla Elementary School',
  'Attea Middle School',
  'Attica Elementary School',
  'Attica High',
  'Attica High School',
  'Attica Junior High School',
  'Attica Senior High School',
  'Attleboro Community Academy',
  'Attleboro High',
  'Attucks Middle',
  'Attucks Middle School',
  'Attwood School',
  'Atwater Avenue Elementary',
  'Atwater Elementary',
  'Atwater High',
  'Atwater Senior Academy',
  'Atwood Elementary School',
  'Atwood Mcdonald El',
  'Atwood-hammond Grade School',
  'Atyrau State University',
  'Au Gressims Elementary School',
  'Au Gressims High School',
  'Au Gressims Middle School',
  'Aua Elementary School',
  'Aubrey H S',
  'Aubrey Middle',
  'Aubry Bend Middle School',
  'Auburn Early Education Center',
  'Auburn Elem School',
  'Auburn Elementary',
  'Auburn Elementary School',
  'Auburn High',
  'Auburn High School',
  'Auburn Jr High At Divernon',
  'Auburn Junior High School',
  'Auburn Middle',
  'Auburn Middle School',
  'Auburn Mountainview High School',
  'Auburn Riverside High School',
  'Auburn Senior High',
  'Auburn Senior High School',
  'Auburn University',
  'Auburn University at Montgomery',
  'Auburn Village School',
  'Auburn Vocational',
  'Auburndale Central Elementary School',
  'Auburndale Elementary',
  'Auburndale Elementary School',
  'Auburndale High',
  'Auburndale Intermediate',
  'Auburndale Senior High School',
  'Auchard Creek School',
  'Auchi Polytechnic',
  'Auckland University of Technology',
  'Audelia Creek El',
  'Audeo Charter',
  'Audeo Charter Ii',
  'Audie Murphy Middle',
  'Audre And Bernard Rapoport Academy',
  'Audrey & Robert Ryan Elementary',
  'Audrielle Lynch-ellen Bustin El Sch',
  'Audubon Charter School',
  'Audubon El Sch',
  'Audubon Elem School',
  'Audubon Elementary',
  'Audubon Elementary School',
  'Audubon Junior/senior High School',
  'Audubon K-8',
  'Audubon Middle',
  'Audubon Middle-high School',
  'Audubon Park Elementary',
  'Audubon Technology And Communication High',
  'Audubon Technology And Communication Middle',
  'Audubon Traditional Elementary',
  'Audubon Youth Development Center',
  'Aue El',
  'Auer Avenue Elementary',
  'Auglaize County Educational Academy',
  'Augsburg College',
  'Augsburg Fairview Academy',
  'August Ahrens Elementary School',
  'August Boeger Middle',
  'August Elementary',
  'August Knodt Elementary',
  'August Martin High School',
  'August Schilling Elementary',
  'Augusta 7-8',
  'Augusta Academy',
  'Augusta Circle Elementary',
  'Augusta Elem.',
  'Augusta Elementary',
  'Augusta Elementary School',
  'Augusta Evans School',
  'Augusta Fells Savage Institute Of Visual Arts',
  'Augusta High',
  'Augusta High School',
  'Augusta Independent School',
  'Augusta Lewis Troup School',
  'Augusta Middle',
  'Augusta Middle School',
  'Augusta Preschool Academy',
  'Augusta Ranch Elementary',
  'Augusta Regional Youth Detention Center',
  'Augusta Sr High',
  'Augusta Technical College',
  'Augusta University',
  'Augusta Youth Development Campus',
  'Augustana College (IL)',
  'Augustana College (SD)',
  'Augustana Hochschule Neuendettelsau',
  'Augustana University College',
  'Augusto Cohen',
  'Augusto Guerra El',
  'Augustus F. Hawkins High A Critical Design And Gaming',
  'Augustus F. Hawkins High B Community Health Advocates',
  'Augustus F. Hawkins High C Rspnsbl Indigenous Soc Entrepr',
  'Augustus H. Shaw Montessori',
  'Auhs Online Acadamy',
  'Auke Bay Elementary',
  'Aulander Elementary',
  'Ault El',
  'Aumsville Elementary School',
  'Auntie Mary Nicoli Elementary',
  'Aura A Cole Elementary School',
  'Aura Elementary School',
  'Aurea E. Quiles Claudio',
  'Aurea E. Rivera Collazo',
  'Aurea Fuentes Mendez (calabazas Infantes)',
  'Aurelia Elementary',
  'Aurelia Elementary School',
  'Aurelia Middle School',
  'Aurelia Pennekamp Elementary',
  'Aurelia Quintero Laboy',
  'Aurelian Springs Institute Of Global Lea',
  'Aurelio Perez Martinez',
  'Aurora Academy',
  'Aurora Academy Charter School',
  'Aurora Borealis Charter School',
  'Aurora Central High School',
  'Aurora Charter School',
  'Aurora Collegiate Academy',
  'Aurora Elementary',
  'Aurora Elementary School',
  'Aurora Frontier K-8',
  'Aurora Heights Elementary School',
  'Aurora High',
  'Aurora High (continuation)',
  'Aurora High School',
  'Aurora Hills Middle School',
  'Aurora Jr. High',
  'Aurora Mendez Charneco',
  'Aurora Middle School',
  'Aurora Plains Academy High School - 05',
  'Aurora Plains Academy Middle School - 04',
  'Aurora Preschool',
  'Aurora Quest K-8',
  'Aurora School',
  'Aurora University',
  'Aurora West College Preparatory Academy',
  'Ausable Forks Elementary School',
  'Ausable Valley High School',
  'Ausable Valley Middle School',
  'Austell Elementary School',
  'Austin Acad For Excell',
  'Austin Achieve Public Schools',
  'Austin Albert Lea Area Sp Ed Coop',
  'Austin Area El Sch',
  'Austin Area Jshs',
  'Austin Area Learning Center',
  'Austin Area Learning Cntr. - Summer',
  'Austin Bus & Entrepreneurship Hs',
  'Austin Can Academy',
  'Austin College',
  'Austin College And Career Acad Hs',
  'Austin Community College',
  'Austin Community Learning Center',
  'Austin Creek Elementary',
  'Austin Discovery Sch',
  'Austin East High/magnet',
  'Austin El',
  'Austin Elem',
  'Austin Elementary - 03',
  'Austin Elementary School',
  'Austin H S',
  'Austin High School',
  'Austin Isd Child Development Center',
  'Austin Middle',
  'Austin Middle School',
  'Austin Montessori Magnet',
  'Austin Oaks',
  'Austin Parkway El',
  'Austin Peay Elementary School',
  'Austin Peay State University',
  'Austin Polytechnical Academy Hs',
  'Austin Road Elementary School',
  'Austin Road Middle School',
  'Austin School',
  'Austin Senior High',
  'Austin St Hospital',
  'Austin T. Levy School',
  'Austin Tracy Elementary School',
  'Austinburg Elementary School',
  'Austintown Elementary School',
  'Austintown Intermediate School',
  'Austintown Middle School',
  'Austinville Elementary School',
  'Australian Catholic University',
  'Australian Correspondence Schools',
  'Australian Defence Force Academy',
  'Australian Lutheran College',
  'Australian Maritime College',
  'Australian National University',
  'Australlian College of Kuwait',
  'Austwell-tivoli El',
  'Austwell-tivoli H S',
  'Autauga County Alternative School',
  'Autauga County Technology Center',
  'Autaugaville School',
  'Auten Road Intermediate School',
  'Autism Class',
  'Autism Model School',
  'Autism Program - Burr School',
  'Autism Program - Hooker School',
  'Autism Program - Naylor School',
  'Autism Program - Rawson School',
  'Autism Program - Simpson-waverly School',
  'Autistic',
  'Automotive High School',
  'Autrainonota Public School',
  'Autrey Mill Middle School',
  'Autumn Creek Elem Sch',
  'Autumn Hill School',
  'Autumn Lane Elementary School',
  'Aux Sable',
  'Aux Sable Middle Sch',
  'Auxiliary Preschool And Services Center',
  'Auxvasse Elem.',
  'Ava Elem.',
  'Ava High',
  'Ava Middle',
  'Avail School',
  'Avalon El Sch',
  'Avalon Elementary',
  'Avalon Elementary School',
  'Avalon Gardens Elementary',
  'Avalon High',
  'Avalon K-12',
  'Avalon Middle',
  'Avalon Middle School',
  'Avalon Park Elem School',
  'Avalon School',
  'Avant Garde Academy',
  'Avant Garde Academy K-8 Broward',
  'Avant Garde Academy K8 Osceola',
  'Avant Garde Academy Of Broward',
  'Avant Grade Academy Westchase',
  'Avant Public School',
  'Avanti High School',
  'Avaxat Elementary',
  'Avelino Pena Reyes',
  'Avella Area Jshs',
  'Avella El Center',
  'Avenal Elementary',
  'Avenal High',
  'Avenel Middle School',
  'Avenel Street Elementary School',
  'Aventis School of Management',
  'Aventura City Of Excellence School',
  'Aventura Waterways K-8 Center',
  'Avenue A Elementary',
  'Avenue B School - Closed',
  'Avenue City Elem.',
  'Averett College',
  'Averill Park High School',
  'Aversboro Elementary',
  'Avery',
  'Avery County High',
  'Avery El',
  'Avery Elem.',
  'Avery Elementary School',
  'Avery H S',
  'Avery High Stem Academy',
  'Avery High Viking Academy',
  'Avery Middle',
  'Avery Trace Middle School',
  "Avery's Creek Elementary",
  'Avery/parsons Elementary School',
  'Aveson Global Leadership Academy',
  'Aveson School Of Leaders',
  'Aviara Oaks Elementary',
  'Aviara Oaks Middle',
  'Aviation Career And Technical High School',
  'Avicenna International College',
  'Avila College',
  'Avilla Elem.',
  'Avilla Elementary School',
  'Avinashilingam Institute for Home Science and Higher Education for Women',
  'Avinger School',
  'Avis El Sch',
  'Avis Huff Student Support Service',
  'Aviston Elem School',
  'Avoca Central School',
  'Avoca Elementary',
  'Avoca Elementary School',
  'Avoca West Elem School',
  'Avocado Elementary',
  'Avocado Elementary School',
  'Avon Center Elem School',
  'Avon Early Learning Center',
  'Avon East Elementary School',
  'Avon Elem Sch',
  'Avon Elementary',
  'Avon Elementary - 02',
  'Avon Elementary School',
  'Avon Grove Cs',
  'Avon Grove Hs',
  'Avon Grove Intrmd Sch',
  'Avon Heritage Elementary School',
  'Avon High School',
  'Avon High School - 01',
  'Avon Intermediate School East',
  'Avon Intermediate School West',
  'Avon Jr. High - 05',
  'Avon Lake High School',
  'Avon Middle High School',
  'Avon Middle School',
  'Avon Middle School North',
  'Avon Middle School South',
  'Avon Park High School',
  'Avon Park Middle School',
  'Avon Preschool - 06',
  'Avon Primary School',
  'Avon Public Schools Pre-k',
  'Avon School',
  'Avona El Sch',
  'Avondale Academy',
  'Avondale College',
  'Avondale El',
  'Avondale Elementary',
  'Avondale Elementary School',
  'Avondale High School',
  'Avondale Meadows Academy',
  'Avondale Meadows Middle School',
  'Avondale Middle School',
  'Avondale Montessori Prek',
  'Avondale West',
  'Avonworth El Sch',
  'Avonworth Hs',
  'Avonworth Ms',
  'Avonworth Primary Center',
  'Avoyelles High School',
  'Avoyelles Public Charter School',
  'Avrasya University',
  'Aw Brown-f L A Int Campus',
  'Awadhesh Pratap Singh University',
  'Aware',
  'Awbrey Park Elementary School',
  'Awtrey Middle School',
  'Axl Academy',
  'Axtell Bruceville-eddy Learning Center',
  'Axtell El',
  'Axtell Elementary School',
  'Axtell H S',
  'Axtell High',
  'Axtell High School',
  'Axtell Middle School',
  'Axtell/be Daep',
  'Axtell/bruceville-eddy Learning Center',
  'Axton Elementary',
  "Ayagina'ar Elitnaurvik",
  'Ayaprun Elitnaurvik',
  'Ayaprun School',
  'Aycock Elementary',
  'Ayden Elementary',
  'Ayden Middle',
  'Ayden-grifton High',
  'Ayer Elementary',
  'Ayer Elementary School',
  'Ayer Shirley Regional High School',
  'Ayer Shirley Regional Middle School',
  'Ayers Elementary',
  'Ayers School',
  'Ayers/ryal Side School',
  'Ayersville Elementary School',
  'Ayersville High School',
  'Aylen Jr High',
  'Aynesworth Elementary',
  'Aynor Elementary',
  'Aynor High',
  'Aynor Middle',
  'Ayres Elementary School',
  'Az Compass Prep School',
  'Az Kelsey Academy',
  'Azabu University',
  'Azad Jammu and Kashmir University',
  'Azalea Elementary School',
  'Azalea Gardens Middle',
  'Azalea Middle School',
  'Azalea Park Elementary',
  'Azerbaijan Diplomatic Academy',
  'Azerbaijan Medical University',
  'Azerbaijan National Conservatorie',
  'Azerbaijan State Agricultural Academy',
  'Azerbaijan State Customs Committee Academy',
  'Azerbaijan State Marine Academy',
  'Azerbaijan State Pedagogic University',
  'Azerbaijan State University of Culture and Arts',
  'Azerbaijan State University of Economics',
  'Azerbaijan State University of Oil and Industry',
  'Azerbaijan Technical University',
  'Azerbaijan Toursim Institute',
  'Azerbaijan University',
  'Azerbaijan University of Architecture and Construction',
  'Azerbaijan University of Languages',
  'Azle El',
  'Azle H S',
  'Azle Hornet Academy',
  'Azle J H South',
  'Azleway - Big Sandy',
  'Azleway - Chapel Hill',
  'Azleway - Willow Bend',
  'Aztec Elementary School',
  'Aztec High',
  'Aztec High School',
  'Aztec Youth Academy',
  'Azuela Elem School',
  'Azusa High',
  'Azusa Pacific University',
  'Azzahra University',
  'B & G PRESCHOOL, INC',
  'B A Winans School',
  'B B Owen El',
  'B Beck Education Center',
  'B C CAMPUS SCHOOL',
  'B C Cate Elementary School',
  'B C Ed Tech Center',
  'B D Billinghurst Middle School',
  'B Everett Jordan Elem',
  'B F Day Elementary School',
  'B F Grady Elementary',
  'B F Kitchen Elementary School',
  'B F Liddell Elementary School',
  'B F Liddell Middle School',
  'B F Morey El Sch',
  'B F Terry H S',
  'B G Hudson Middle',
  'B H Hamblen El',
  'B H Macon El',
  'B H R A Cooperative Hs',
  'B J Hooper Elem School',
  'B L Garza Middle School',
  'B L Gray J H',
  'B L Miller Elementary School',
  'B M C Durfee High',
  'B Mahlon Brown Junior High School',
  'B Mcdaniel Int',
  'B O Barnes Elementary',
  'B R Miller Middle School',
  'B T Washington Primary',
  'B T Wilson Sixth Grade School',
  'B W Robinson School',
  'B W Sheperd School',
  'B-success Academy - Aec',
  'B-u Middle School',
  'B. Banneker Academy',
  'B. Banneker Elem.',
  'B. Bernice Young Elementary School',
  'B. D. Lee Elementary',
  'B. Edward Boudreaux Middle School',
  'B. Gale Wilson Elementary',
  'B. Michael Caudill Middle School',
  'B. R. Ambedkar Bihar University',
  'B. R. Foreign Language Acad. Immersion Magnet',
  'B. Roberto Cruz Leadership Academy',
  'B. S. Abdur Rahman University',
  'B. T. Washington High',
  'B. W. Tinker School',
  'B. WRIGHT LEADERSHIP ACADEMY',
  'B.P.Koirala Institute of Health Sciences',
  'B.c. Charles Elementary',
  'B.d. Gullett Elementary School',
  'B.e.s.t Academy',
  'B.e.s.t.',
  'B.f. Butler Middle School',
  'B.f. Norton Elementary School',
  'B.m. Williams Primary',
  'B.p. Carbullido Elementary School',
  'B.r.i.c.k. Avon Academy',
  'B.r.i.c.k. Peshine Academy',
  'B.t. Washington Elementary',
  'B.t. Washington Middle',
  'B.w. Tinker Bdlc',
  'BACK CREEK CHRISTIAN ACADEMY',
  'BACOT ACADEMY',
  'BADGER STATE BAPTIST SCHOOL',
  'BADR SCHOOL',
  "BAILEY'S GROVE BAPTIST SCHOOL",
  'BAIRD SCHOOL',
  'BAIS CHAYA ESTHER',
  'BAIS CHAYA MUSHKA',
  'BAIS HAMEDRASH & MESIVTA OF BALTIMORE',
  'BAIS KAILA TORAH PREPARATORY HIGH SCHOOL FOR GIRLS',
  'BAIS LEAH HIGH SCHOOL',
  'BAIS MENACHEM YESHIVA DAY SCHOOL',
  'BAIS ROCHEL',
  'BAIS ROCHEL SCHOOL',
  'BAIS RUCHEL DSATMAR INC DBA CENTRAL UTA',
  'BAIS SARAH-EDUC SCHOOL FOR GIRLS',
  'BAIS SHAINDEL HIGH SCHOOL FOR GIRLS',
  'BAIS SHIFRA',
  'BAIS YAAKOV ATERES MIRIAM',
  'BAIS YAAKOV CHOFETZ CHAIM OF POMONA',
  "BAIS YAAKOV D'RAV HIRSCH",
  'BAIS YAAKOV DGUR HIGH SCHOOL',
  'BAIS YAAKOV ELEMENTARY SCHOOL OF ROCKLAND',
  'BAIS YAAKOV EVA WINER HIGH SCHOOL',
  'BAIS YAAKOV HIGH SCHOOL',
  'BAIS YAAKOV HIGH SCHOOL OF SOUTH FALLSBURG',
  'BAIS YAAKOV OF 18TH AVE',
  'BAIS YAAKOV OF BOSTON HIGH SCHOOL',
  'BAIS YAAKOV OF INDIANA',
  'BAIS YAAKOV OF RAMAPO',
  'BAIS YAAKOV OF WATERBURY HIGH SCHOOL',
  'BAIS YAAKOV SCHOOL FOR GIRLS',
  'BAIS YITZCHAK YESHIVA',
  'BAISYAAKOV SECONDARY SCHOOL',
  'BAKER DEMONSTRATION SCHOOL',
  'BAKER VIEW CHRISTIAN SCHOOL',
  'BAKERSFIELD CHRISTIAN HIGH SCHOOL',
  'BAL-PERAZIM CHRISTIAN ACADEMY',
  'BALBOA SCHOOL',
  'BALCH SPRINGS CHRISTIAN ACADEMY',
  'BALD EAGLE SCHOOL',
  'BALD EAGLE WILDERNESS BOYS CAMP SCHOOL',
  'BALDWIN OAKS ACADEMY',
  "BALTIMORE ACTOR'S THEATRE - THE DUMBARTON HOUSE",
  'BALTIMORE JUNIOR ACADEMY',
  'BALTIMORE LAB SCHOOL',
  'BALTIMORE MONTESSORI',
  'BALTIMORE SCHOOL CANTON THE',
  'BALTIMORE WHITE MARSH ADVENTIST SCHOOL',
  'BAMBI LAND DAY CARE',
  "BAMBY'S DAY CARE CENTER",
  'BANCROFT NEUROHEALTH SCHOOL CHERRY HILL',
  'BANCROFT SCHOOL',
  'BANCROFT SCHOOL AT VOORMEES PEDIATRIC FACILITY',
  'BANGOR CHRISTIAN SCHOOLS',
  'BANGOR MONTESSORI SCHOOL',
  'BANK STREET SCHOOL FOR CHILDREN',
  "BANNER CHILDREN'S ACADEMY",
  'BANNERVILLE SCHOOL',
  'BANNOCKBURN CHRISTIAN ACADEMY',
  'BANSBACH ACADEMY AT DENVER CHILDRENS HOME',
  'BANYAN DAY SCHOOL',
  'BANYAN SCHOOL',
  'BANYAN TREE FOUNDATIONS ACADEMY',
  'BANYAN UPPER SCHOOL',
  'BARAT ACADEMY',
  'BARBARA K. LIPMAN EARLY LEARNING CENTER',
  "BARBARA'S KITCHEN SCHOOL OF EXCELLENCE",
  'BARKAI YESHIVAH',
  'BARNES ACADEMY',
  'BARNES CORNER',
  'BARNESVILLE SCHOOL OF ARTS & SCIENCES',
  'BARNHART SCHOOL',
  'BARNSTABLE ACADEMY',
  'BARNWELL CHRISTIAN SCHOOL',
  'BARRETT RANCH',
  'BARRIE SCHOOL',
  'BARRINGTON CHRISTIAN ACADEMY',
  'BARRON CHRISTIAN SCHOOL',
  'BARRVILLE AMISH SCHOOL',
  'BARRY COUNTY CHRISTIAN SCHOOL',
  'BARSTOW CHRISTIAN SCHOOL',
  'BARTLETT BAPTIST KINDERGARTEN & PRESCHOOL',
  'BARTVILLE AMISH SCHOOL',
  'BAS MIKROH GIRLS SCHOOL',
  'BASELINE BILINGUAL SCHOOL',
  'BASHOR ALTERNATIVE SCHOOL',
  'BASICS & BEYOND PRIVATE ED',
  'BASICS PRIMARY SCHOOL & DAYCARE',
  'BASILICA SCHOOL OF ST PAUL',
  'BASIS INDEPENDENT BROOKLYN',
  'BASIS INDEPENDENT FREMONT',
  'BASIS INDEPENDENT MANHATTAN',
  'BASIS INDEPENDENT SILICON VALLEY',
  'BASS CHRISTIAN ELEMENTARY SCHOOL',
  'BASS MEMORIAL ACADEMY',
  'BATON ROUGE INTERNATIONAL SCHOOL',
  'BATON ROUGE LUTHERAN SCHOOL',
  'BATT PRIVATE SCHOOL',
  'BATTALION CHRISTIAN ACADEMY',
  'BATTLE CREEK AREA CATHOLIC SCHOOL ST JOSEPH ELEMEN',
  'BATTLE GROUND ACADEMY',
  'BAY AREA CHRISTIAN SCHOOL',
  'BAY AREA MONTESSORI HOUSE',
  'BAY CITY CHRISTIAN SCHOOL',
  'BAY COVE ACADEMY',
  'BAY FARM MONTESSORI ACADEMY',
  'BAY KNOLL SDA SCHOOL',
  'BAY MEADOWS KINDERCARE',
  'BAY MONTESSORI SCHOOL',
  'BAY PINES LUTHERAN SCHOOL',
  'BAY RIDGE CHRISTIAN ACADEMY',
  'BAYAAN ACADEMY',
  'BAYBERRY CHRISTIAN SCHOOL',
  'BAYER PRIVATE SCHOOL',
  'BAYHILL HIGH SCHOOL',
  'BAYLOR SCHOOL',
  'BAYMEADOWS BAPTIST DAY SCHOOL',
  'BAYMONTE CHRISTIAN SCHOOL',
  'BAYOU ACADEMY',
  'BAYOU VILLAGE SCHOOL',
  'BAYSHORE CHRISTIAN SCHOOL',
  'BAYSIDE ACADEMY',
  'BAYSIDE ADVENTIST CHRISTIAN SCHOOL',
  'BAYSIDE MONTESSORI ASSOCIATION',
  'BAYSIDE PRESBYTERIAN PRESCHOOL AND KINDERGARDEN',
  'BAYTOWN CHRISTIAN ACADEMY',
  'BAYTULIMAN ACADEMY',
  'BB C CHRISTIAN ACADEMY',
  'BB INTERNATIONAL SCHOOL',
  "BE'ER HAGOLAH INSTITUTES (B & G)",
  'BEACH CITIES CHILD DEVELOPMENT CENTER',
  'BEACH CITIES LEARNING CENTER',
  'BEACH DAY SCHOOL',
  'BEACH MANOR SCHOOL',
  'BEACH MONTESSORI TECH',
  'BEACH PARK SCHOOL',
  'BEACHES EPISCOPAL SCHOOL',
  'BEACHSIDE MONTESSORI SCHOOL',
  'BEACON ACADEMY',
  'BEACON CHRISTIAN ACADEMY',
  'BEACON CHRISTIAN SCHOOL',
  'BEACON DAY SCHOOL',
  'BEACON HIGH SCHOOL',
  'BEACON HILL PREPARATORY SCHOOL',
  'BEACON HILL SCHOOL',
  'BEACON OF HOPE CHRISTIAN SCHOOL',
  'BEACON SCHOOL',
  'BEACON STAR AMISH SCHOOL',
  'BEACON STAR SCHOOL',
  'BEAR CREEK ACADEMY',
  'BEAR CREEK SCHOOL',
  'BEAR LAKE CHRISTIAN ACADEMY',
  'BEAR LAKE MONTESSORI ACADEMY',
  'BEAR LANE SCHOOL',
  'BEAR RUN SCHOOL',
  'BEARDSTOWN CHRISTIAN ACADEMY',
  'BEARTOWN SCHOOL',
  'BEATITUDES OF OUR LORD CATHOLIC SCHOOL',
  'BEAUFORT ACADEMY',
  'BEAUTIFUL SAVIOR ACADEMY',
  'BEAUTIFUL SAVIOR EARLY LEARNING CENTER',
  'BEAUTIFUL SAVIOR LUTHERAN SCHOOL',
  'BEAUTIFUL SAVIOUR LUTHERAN SCHOOL',
  'BEAUVOIR THE NATIONAL CATHEDRAL ELEMENTARY SCHOOL',
  'BEAVER CO CHRISTIAN SCHOOL -UPPER',
  'BEAVER COUNTRY DAY SCHOOL',
  'BEAVER COUNTY CHRISTIAN SCHOOL',
  'BEAVER CREEK CHILDCARE & KINDERGARTEN',
  'BEAVER CREEK SCHOOL',
  'BEAVER DAM AMISH SCHOOL/ABNEW KAUFFMAN, CHAIRMAN',
  'BEAVER VALLEY SCHOOL',
  'BEAVERCREEK CHRISTIAN LEARNING CENTER',
  'BECKMAN CATHOLIC JR-SR HIGH SCHOOL',
  'BECKS MONTESSORI ACCELERATED LEARNING CENTER',
  'BEDDOW HIGH SCHOOL',
  'BEDDOW SCHOOL - FT WASHINGTON CAMPUS',
  'BEDDOW SCHOOL - WALDORF CAMPUS',
  'BEDFORD BIBLE CHURCH SCHOOL',
  'BEDFORD MONTESSORI SCHOOL',
  'BEDFORD MONTESSORI SCHOOL INC',
  'BEDFORD VILLAGE MORNING SCHOOL',
  'BEE CREATIVE ACADEMY',
  'BEEBE CHRISTIAN SCHOOL',
  'BEECH GROVE',
  'BEECHWOOD SCHOOL',
  'BEEMAN PARK PREPARATORY SCHOOL',
  'BEGINNING DISCOVERIES LEARNING CENTER',
  'BEGINNINGS CHILD DEVELOPMENT CENTER',
  'BEGINNINGS MONTESSORI SCHOOL',
  'BEHAVIOR EDUCATION CENTER',
  'BEHAVIORAL CARE SPECIALISTS LEARNING CENTER',
  'BEIT RABBAN DAY SCHOOL',
  'BEL-AIR CHRISTIAN SCHOOL',
  'BEL-RED BILINGUAL ACADEMY',
  'BELAIR BAPTIST CHRISTIAN ACADEMY',
  'BELDEN HILL MONTESSORI',
  'BELEN JESUIT PREPARATORY SCHOOL, INC.',
  'BELIEVERS CHRISTIAN ACADEMY',
  'BELIEVERS IN CHRIST CHRISTIAN ACADEMY',
  'BELIEVERS MENNONITE FELLOWSHIP CHRISTIAN SCHOOL',
  'BELL CANYON MONTESSORI SCHOOL',
  'BELL SHOALS BAPTIST ACADEMY',
  'BELL TOWER TRILINGUAL SCHOOL',
  'BELLA MONTESSORI PRESCHOOL',
  'BELLA VISTA CHRISTIAN ACADEMY',
  'BELLAIRE RIDGE SCHOOL',
  'BELLARMINE PREPARATORY SCHOOL',
  'BELLE MEADE MONTESSORI SCHOOL',
  'BELLES & BEAUS ACADEMY',
  'BELLEVIEW CHRISTIAN ACADEMY / LFCS',
  'BELLEVILLE MENNONITE SCHOOL',
  "BELLEVUE CHILDREN'S ACADEMY",
  'BELLEVUE CHRISTIAN MACK ELEMENTARY',
  'BELLEVUE CHRISTIAN SCHOOL',
  'BELLEVUE MONTESSORI SCHOOL',
  'BELLINGHAM CHRISTIAN SCHOOL',
  'BELLTOWER MONTESSORI ACADEMY',
  'BELLWOOD CHRISTIAN ACADEMY',
  'BELMONT CHRISTIAN ACADEMY',
  'BELMONT DAY SCHOOL',
  'BELMONT HILL SCHOOL',
  'BELMONT OAKS ACADEMY',
  'BELMONT SPECIAL EDUCATION SCHOOL',
  'BELMONT UNION CHRISTIAN ACADEMY',
  'BELTSVILLE SDA SCHOOL',
  'BELVOIR CHRISTIAN ACADEMY',
  'BEN BRONZ ACADEMY',
  'BEN FRANKLIN ACADEMY',
  'BEN LIPPEN SCHOOL',
  'BEN PORAT YOSEF',
  'BENCHMARK SCHOOL',
  'BENDING OAKS HIGH SCHOOL',
  'BENEDICT DAY SCHOOL',
  'BENEDICTINE ACADEMY',
  'BENEDICTINE COLLEGE PREPARATORY',
  'BENEDICTINE HIGH SCHOOL',
  'BENHAVEN ACADEMY SITE 2',
  'BENHAVEN SCHOOL',
  'BENNETT FAMILY CENTER',
  'BENS FORD CHRISTIAN SCHOOL',
  'BENSALEM BAPTIST SCHOOL',
  'BENT MOUNTAIN CHRISTIAN ACADEMY',
  'BENTLEY SCHOOL',
  'BENTON ACADEMY',
  'BENTON HALL ACADEMY',
  'BENTONVILLE SDA SCHOOL',
  'BENWAY SCHOOL',
  'BEREA CHRISTIAN SCHOOL',
  'BEREA JUNIOR ACADEMY',
  'BEREA SDA ACADEMY',
  'BEREAN ACADEMY',
  'BEREAN BAPTIST ACADEMY',
  'BEREAN BAPTIST CHRISTIAN SCHOOL',
  'BEREAN BIBLE BAPTIST ACADEMY',
  'BEREAN CHRISTIAN ACADEMY',
  'BEREAN CHRISTIAN HIGH SCHOOL',
  'BEREAN CHRISTIAN JR ACADEMY',
  'BEREAN CHRISTIAN SCHOOL',
  'BEREAN FELLOWSHIP CHRISTIAN SCHOOL',
  'BEREAN JR ACADEMY',
  'BEREGEN-LAFAYETTE MONTESSORI SCHOOL',
  'BERGAMO MONTESSORI SCHOOL',
  'BERGEN CATHOLIC HIGH SCHOOL',
  'BERGEN CENTER FOR CHILD DEVELOPMENT',
  'BERGEN COUNTY CHRISTIAN ACADEMY',
  'BERKELEY CARROLL ELEMENTARY SCHOOL',
  'BERKELEY CARROLL SCHOOL',
  'BERKELEY HALL SCHOOL',
  'BERKELEY ROSE WALDORF SCHOOL',
  'BERKELEY SCHOOL',
  'BERKS CATHOLIC HIGH SCHOOL',
  'BERKS CHRISTIAN SCHOOL',
  'BERKS COUNTY MONTESSORI COUNTRY DAY SCHOOL',
  'BERKSHIRE CHRISTIAN SCHOOLS',
  'BERKSHIRE COUNTRY DAY SCHOOL',
  'BERKSHIRE HILLS SDA SCHOOL',
  'BERKSHIRE MEADOWS',
  'BERKSHIRE SCHOOL',
  'BERKSHIRE WALDORF HIGH SCHOOL',
  'BERKWOOD HEDGE SCHOOL',
  'BERNARD ZELL ANSHE EMET DAY SCHOOL',
  'BERNIKLAU EDUCATION SOLUTIONS TEAM',
  'BERTSCHI SCHOOL',
  'BERWICK ACADEMY',
  'BERWYN MONTESSORI',
  'BERYL WISDOM ADVENTIST SCHOOL',
  'BEST ACADEMY',
  'BET SHRAGA HEBREW ACADEMY',
  'BET YAAKOV ATERET TORAH',
  'BET YAAKOV OF THE JERSEY SHORE',
  'BET YAAKOV OHR SARRAH',
  'BETEL CHRISTIAN SCHOOL',
  'BETESDA CHRISTIAN SCHOOL',
  'BETH EDEN BAPTIST SCHOOL',
  'BETH EL CHRISTIAN DAY SCHOOL',
  'BETH EL EARLY CHILDHOOD CENTER',
  'BETH HAVEN ACADEMY',
  'BETH HAVEN BAPTIST ACADEMY',
  'BETH HAVEN CHRISTIAN ACADEMY',
  'BETH HAVEN CHRISTIAN SCHOOL',
  'BETH HILLEL DAY SCHOOL',
  'BETH ISRAEL KINDERGARTEN',
  'BETH ISRAEL RELIGIOUS SCHOOL',
  'BETH JACOB 1-8',
  'BETH JACOB DAY SCHOOL',
  'BETH JACOB HIGH SCHOOL',
  'BETH JACOB OF BORO PARK',
  'BETH JACOB PAROCHIAL SCHOOL',
  'BETH MONTESSORI',
  'BETH RACHEL SCHOOL FOR GIRLS',
  'BETH RIVKA HIGH SCHOOL',
  'BETH RIVKAH ELEMENTARY SCHOOL',
  'BETH TFILOH DAHAN COMMUNITY SCHOOL',
  'BETHABARA BAPTIST CHURCH',
  'BETHANY ACADEMY',
  'BETHANY BAPTIST CHRISTIAN SCHOOL',
  'BETHANY CHILD DEVELOPMENT CENTER',
  'BETHANY CHRISTIAN ACADEMY',
  'BETHANY CHRISTIAN SCHOOL',
  'BETHANY CHRISTIAN SCHOOLS',
  'BETHANY DAYCARE CENTER & KINDERGARTEN',
  'BETHANY EVANGELICAL LUTHERAN SCHOOL',
  'BETHANY LEARNING ACADEMY',
  'BETHANY LUTHERAN',
  'BETHANY LUTHERAN ELEMENTARY SCHOOL',
  'BETHANY LUTHERAN SCHOOL',
  'BETHANY SCHOOL',
  'BETHANY VILLAGE MONTESSORI',
  'BETHEL APOSTOLIC ACADEMY',
  'BETHEL ASSEMBLY CHRISTIAN ACADEMY',
  'BETHEL BAPTIST ACADEMY',
  'BETHEL BAPTIST CHRISTIAN ACADEMY',
  'BETHEL BAPTIST CHRISTIAN SCHOOL',
  'BETHEL BAPTIST CHURCH AND SCHOOL',
  'BETHEL BAPTIST SCHOOL',
  'BETHEL CHRISTIAN ACADEMY',
  'BETHEL CHRISTIAN ACADEMY OF WYNCOTE',
  'BETHEL CHRISTIAN SCHOOL',
  'BETHEL CHRISTIAN SCHOOLS',
  'BETHEL COMMUNITY CHRISTIAN SCHOOL',
  'BETHEL DUNKARD BRETHREN CHURCH SCHOOL',
  'BETHEL ELEMENTARY SCHOOL',
  'BETHEL HOLINESS ACADEMY',
  'BETHEL JUNIOR ACADEMY',
  'BETHEL LEARNING CENTER',
  'BETHEL LUTHERAN SCHOOL',
  'BETHEL MENNONITE SCHOOL',
  'BETHEL SDA ELEMENTARY SCHOOL',
  'BETHEL SDA SCHOOL',
  'BETHESDA ACADEMY',
  'BETHESDA CHRISTIAN ACADEMY',
  'BETHESDA CHRISTIAN SCHOOL',
  'BETHESDA COUNTRY DAY SCHOOL',
  'BETHESDA JUNIOR ACADEMY',
  'BETHESDA LUTHERAN SCHOOL',
  'BETHLEHEM ACADEMY',
  'BETHLEHEM CATHOLIC HIGH SCHOOL',
  "BETHLEHEM CHILDREN'S CENTER & KINDERGARTEN",
  'BETHLEHEM CHRISTIAN ACADEMY',
  'BETHLEHEM CHRISTIAN SCHOOL',
  'BETHLEHEM EVANGELICAL LUTHERAN SCHOOL',
  'BETHLEHEM JUNIOR ACADEMY',
  'BETHLEHEM LUTHERAN CHILD DEVELOPMENT CENTER',
  'BETHLEHEM LUTHERAN SCHOOL',
  'BETNANY ELEMENTARY',
  'BETTER LIFE ACADEMY',
  'BETTER LIFE K4C CHRISTIAN ACADEMY',
  'BETTON HILLS PREPARATORY SCHOOL',
  'BETTON HILLS SCHOOL',
  'BEULAH BAPTIST CHRISTIAN DAY SCHOOL',
  'BEULAH BAPTIST CHRISTIAN SCHOOL',
  'BEULAH LAND CHRISTIAN ACADEMY',
  'BEVERLY CHRISTIAN ACADEMY',
  'BEVERLY HILLS ACADEMY',
  'BEVERLY MONTESSORI SCHOOL',
  'BEVERLY SCHOOL FOR THE DEAF',
  'BEYMER UNITED METHODIST PRESCHOOL & KINDERGARTEN',
  'BEYOND EXPECTATIONS ACADEMY',
  'BEYOND THE SPECTRUM EDUCATION CENTER',
  'BFH - Bern University of Applied Sciences',
  'BGC Trust University Bangladesh',
  'BHAKTIVEDANTA ACADEMY',
  'BIBLE BAPTIST ACADEMY',
  'BIBLE BAPTIST CHRISTIAN ACADEMY',
  'BIBLE BAPTIST CHRISTIAN SCHOOL',
  'BIBLE BAPTIST SCHOOL',
  'BIBLE BAPTIST SCHOOLS',
  'BIBLE BELIEVERS CHRISTIAN SCHOOL',
  'BIBLE CENTER SCHOOL',
  'BIBLE CHURCH ACADEMY',
  'BIBLE METHODIST CHRISTIAN SCHOOL',
  'BIBLE TRUTH MINISTRIES ACADEMY',
  'BIBLE WESLEYAN CHRISTIAN SCHOOL',
  'BIBLEWAY ROYAL CHRISTIAN ACADEMY',
  'BIG APPLE ACADEMY',
  'BIG COVE CHRISTIAN ACADEMY',
  "BIG HORN BASIN CHILDREN'S CENTER",
  'BIG HORN VALLEY CHRISTIAN SCHOOL',
  'BIG LEAF MONTESSORI SCHOOL',
  'BIG MINDS',
  'BIG SKY DISCOVERY SCHOOL',
  'BIG SPRINGS CENTER & SCHOOL',
  'BIG STAR PRIVATE SCHOOL',
  'BIG TREE LITTLE PRESCHOOL KINDERGARTEN',
  'BILINGUAL BUDS INC',
  'BILL EGLY SEVENTH-DAY ADVENTIST SCHOOL',
  'BILL RICE CHRISTIAN ACADEMY',
  'BILLINGS CENTRAL CATHOLIC HIGH SCHOOL',
  'BILLINGS CHRISTIAN SCHOOL',
  'BILLINGS MIDDLE SCHOOL',
  'BINA HIGH SCHOOL',
  'BINGHAM ROAD BAPTIST CHILD DEVELOPMENT CENTER',
  'BINGHAMPTON CHRISTIAN ACADEMY',
  'BIOLA YOUTH ACADEMICS PRIVATE SATELLITE PROGRAM',
  'BIRCH MOUNTAIN DAY SCHOOL',
  'BIRCH SCHOOL',
  'BIRCHES SCHOOL',
  'BIRCHWOOD CHRISTIAN SCHOOL',
  'BIRDELL SCHOOL',
  'BIRDSONG ECHO',
  'BISHOP ALEMANY HIGH SCHOOL',
  'BISHOP AMAT MEMORIAL HIGH SCHOOL',
  'BISHOP BARAGA CATHOLIC SCHOOL',
  'BISHOP BLANCHET HIGH SCHOOL',
  'BISHOP BRADY HIGH SCHOOL',
  'BISHOP BROSSART HIGH SCHOOL',
  'BISHOP CANEVIN HIGH SCHOOL',
  'BISHOP CARROLL CATHOLIC HIGH SCHOOL',
  'BISHOP CHATARD',
  'BISHOP CONATY-OUR LADY OF LORETTO HIGH SCHOOL',
  'BISHOP CONNOLLY HIGH SCHOOL',
  'BISHOP DUNN MEMORIAL',
  'BISHOP DUNNE CATHOLIC SCHOOL',
  'BISHOP DWENGER HIGH SCHOOL',
  'BISHOP EUSTACE PREP SCHOOL',
  'BISHOP FEEHAN',
  'BISHOP FENWICK HIGH SCHOOL',
  'BISHOP FENWICK SCHOOL',
  'BISHOP FLAGET SCHOOL',
  'BISHOP FOLEY CATHOLIC HIGH SCHOOL',
  'BISHOP GARCIA DIEGO HIGH SCHOOL',
  'BISHOP GARRIGAN HIGH SCHOOL',
  'BISHOP GEORGE AHR HIGH SCHOOL',
  'BISHOP GORMAN HIGH SCHOOL',
  'BISHOP GRIMES JR-SR HIGH SCHOOL',
  'BISHOP GUERTIN HIGH SCHOOL',
  'BISHOP GUILFOYLE CATHOLIC HIGH SCHOOL',
  'BISHOP HARTLEY HIGH SCHOOL',
  'BISHOP HEELAN CATHOLIC SCHOOLS',
  'BISHOP HENDRICKEN HIGH SCHOOL',
  'BISHOP HOFFMAN CATHOLIC SCHOOL',
  'BISHOP HOGAN MEMORIAL SCHOOL',
  'BISHOP IRETON HIGH SCHOOL',
  'BISHOP JOHN A MARSHALL SCHOOL',
  'BISHOP JOHN CARROLL SCHOOL',
  'BISHOP JOHN J SNYDER HIGH SCHOOL',
  'BISHOP JOHN KING MUSSIO CENTRAL JUNIOR HIGH SCHOOL',
  'BISHOP JOHN KING MUSSIO ELEMENTARY',
  'BISHOP JOHN T WALKER SCHOOL FOR BOYS',
  'BISHOP JOHN T. WALKER SCHOOL FOR BOYS',
  'BISHOP KEARNEY HIGH SCHOOL',
  'BISHOP KELLEY CATHOLIC SCHOOL',
  'BISHOP KELLEY HIGH SCHOOL, INC.',
  'BISHOP KELLY HIGH SCHOOL',
  'BISHOP KENNY HIGH SCHOOL',
  'BISHOP LARKIN CATHOLIC SCHOOL',
  'BISHOP LEIBOLD SCHOOL',
  'BISHOP LOUGHLIN MEMORIAL HIGH SCHOOL',
  'BISHOP LUDDEN JUNIOR SENIOR HIGH SCHOOL',
  'BISHOP LUERS HIGH SCHOOL',
  'BISHOP MAGINN HIGH SCHOOL',
  'BISHOP MANOGUE CATHOLIC HIGH SCHOOL',
  'BISHOP MC CORT CATHOLIC HIGH SCHOOL',
  'BISHOP MCDEVITT HIGH SCHOOL',
  'BISHOP MCGANN-MERCY DIOCESAN HIGH SCHOOL',
  'BISHOP MCGUINNESS CATHOLIC HIGH SCHOOL',
  'BISHOP MCHUGH REGIONAL CATHOLIC',
  'BISHOP MCLAUGHLIN CATHOLIC HIGH SCHOOL',
  'BISHOP MCMANUS ACADEMY',
  'BISHOP MCNAMARA HIGH SCHOOL',
  'BISHOP MIEGE HIGH SCHOOL',
  'BISHOP NEUMANN HIGH SCHOOL',
  'BISHOP NOLAND EPISCOPAL DAY SCHOOL',
  'BISHOP O DOWD HIGH SCHOOL',
  'BISHOP PATRICK V AHERN HIGH SCHOOL',
  'BISHOP READY HIGH SCHOOL',
  'BISHOP ROSECRANS HIGH SCHOOL',
  'BISHOP RYAN CATHOLIC SCHOOL',
  'BISHOP SCHAD REGIONAL SCHOOL',
  'BISHOP SEABURY ACADEMY',
  'BISHOP SHANAHAN HIGH SCHOOL',
  'BISHOP SULLIVAN CATHOLIC HIGH SCHOOL',
  'BISHOP TIMON-ST JUDE HIGH SCHOOL',
  'BISHOP VEROT CATHOLIC HIGH SCHOOL',
  'BISHOP WALSH SCHOOL',
  'BISHOP WARD HIGH SCHOOL',
  'BISHOP WATTERSON HIGH SCHOOL',
  'BITTINGER MENNONITE SCHOOL',
  'BIXBY SCHOOL',
  'BLACK CREEK AMISH SCHOOL',
  "BLACK HILLS CHILDREN'S HOME",
  'BLACK HILLS CHRISTIAN ACADEMY',
  'BLACK HILLS LUTHERAN SCHOOLS',
  'BLACK MOUNTAIN ACADEMY & PRESCHOOL',
  'BLACK PINE CIRCLE SCHOOL',
  'BLACK RIVER MENNONITE SCHOOL',
  'BLACK RIVER PAROCHIAL SCHOOL',
  'BLACK ROCK SCHOOL',
  'BLACKBERRY SDA SCHOOL',
  'BLACKHORSE SCHOOL',
  'BLACKSBURG NEW SCHOOL',
  'BLAIR ACADEMY',
  'BLAIR CHRISTIAN ACADEMY',
  'BLAIR COUNTY CHRISTIAN SCHOOL',
  'BLAIR KIDS ACADEMY',
  'BLAKE MEMORIAL DAY SCHOOL',
  'BLANCHET CATHOLIC SCHOOL',
  'BLESSED MARIA ASSUNTA PALLOTTA MIDDLE SCHOOL',
  'BLESSED PEDIATRIC CARE',
  'BLESSED SACRAMENT',
  'BLESSED SACRAMENT CATHOLIC ELEMENTARY',
  'BLESSED SACRAMENT CATHOLIC SCHOOL',
  'BLESSED SACRAMENT ELEMENTARY SCHOOL',
  'BLESSED SACRAMENT HUGUENOT CATHOLIC SCHOOL',
  'BLESSED SACRAMENT PARISH SCHOOL',
  'BLESSED SACRAMENT PRESCHOOL & KINDERGARTEN',
  'BLESSED SACRAMENT SCHOOL',
  'BLESSED SACRAMENT SCHOOL & EARLY CHILDHOOD CTR',
  'BLESSED SAVIOR CATHOLIC SCHOOL - EAST CAMPUS',
  'BLESSED SAVIOR CATHOLIC SCHOOL - SOUTH CAMPUS',
  'BLESSED SAVIOR SCHOOL-WEST CAMPUS',
  'BLESSED STAR MONTESSORI CHRISTIAN SCHOOL',
  'BLESSED TRINITY CATHOLIC HIGH SCHOOL',
  'BLESSED TRINITY CATHOLIC SCHOOL',
  'BLESSED TRINITY REGIONAL CATHOLIC SCHOOL',
  'BLESSED TRINITY SCHOOL',
  'BLESSED TRINITY-PENN CAMPUS',
  'BLESSED VIRGIN MARY SCHOOL',
  'BLESSED VIRGIN MARY-MOTHER OF GOD ACADEMY',
  'BLESSING ACRES',
  "BLIND CHILDREN'S CENTER",
  "BLIND CHILDREN'S LEARNING CENTER",
  'BLODGETT VIEW CHRISTIAN SCHOOL',
  'BLOOMFIELD MAPLES MONTESSORI',
  'BLOOMING GROVE MONTESSORI',
  'BLOOMING SCHOLARS MONTESSORI SCHOOL',
  'BLOOMING VALLEY MENNONITE SCHOOL',
  'BLOOMINGDALE CHILDRENS HOUSE',
  'BLOOMINGTON LUTHERAN SCHOOL',
  'BLOOMINGTON MONTESSORI SCHOOL',
  'BLOSE HILL AMISH SCHOOL',
  'BLOSSOM GARDEN FRIENDS SCHOOL',
  'BLOSSOM MONTESSORI SCHOOL FOR THE DEAF',
  'BLOSSOMING HILL MONTESSORI',
  'BLUE ASH EDUCATIONAL BLDG ELEMENTARY',
  'BLUE BELL MONTESSORI CHILDRENS HOUSE',
  'BLUE BIRD MEADOW SCHOOL',
  'BLUE BIRD OF ALEXANDRIA',
  'BLUE CAP SCHOOL',
  'BLUE CREEK SCHOOL',
  'BLUE HILL HARBOR SCHOOL',
  'BLUE JAY ACADEMY',
  'BLUE MOUNTAIN ACADEMY',
  'BLUE MOUNTAIN CHRISTIAN DAY SCHOOL',
  'BLUE MOUNTAIN MONTESSORI',
  'BLUE MOUNTAIN SCHOOL',
  'BLUE MOUNTAIN VIEW PAROCH SCHOOL',
  'BLUE MT CHRISTIAN SCHOOL',
  'BLUE OAK SCHOOL',
  'BLUE RIDGE AUTISM & ACHIEVEMENT CENTER',
  'BLUE RIDGE CHRISTIAN SCHOOL',
  'BLUE RIDGE MENNONITE SCHOOL',
  'BLUE RIDGE MONTESSORI SCHOOL',
  'BLUE RIDGE SCHOOL',
  'BLUE ROCK MENNONITE SCHOOL',
  'BLUE ROCK SCHOOL',
  'BLUE SCHOOL',
  'BLUE SPRINGS CHRISTIAN ACADEMY',
  'BLUE WATER CHRISTIAN ACADEMY',
  'BLUEBONNET MONTESSORI SCHOOL OF LAKEWAY',
  'BLUEGRASS CHRISTIAN ACADEMY',
  'BLUFF VIEW CHRISTIAN SCHOOL',
  'BLVD ADVENTIST ACADEMY',
  'BLVD CHRISTIAN SCHOOL',
  'BLYTH TEMPLETON ACADEMY',
  'BMF CHRISTIAN',
  'BNAI SHALOM DAY SCHOOL',
  'BNEI SHIMON YISROEL OF SOPRON',
  'BNOIS SPINKA',
  'BNOS BAIS YAAKOV-FAR ROCKAWAY',
  'BNOS BINA GIRLS SCHOOL',
  'BNOS CHAYIL',
  'BNOS CHOMESH',
  'BNOS DEVORAH',
  'BNOS DEVORAH HIGH SCHOOL',
  'BNOS ESTHER',
  'BNOS MALKA ACADEMY',
  'BNOS MARGULIA VIZNITZ',
  'BNOS MENACHEM',
  'BNOS ORCHOS CHAIM',
  'BNOS PENINA',
  'BNOS RABBEINU HIGH SCHOOL',
  'BNOS SQUARE OF WILLIAMSBURG',
  'BNOS TZIPPA',
  'BNOS YAAKOV',
  'BNOS YAAKOV EDUCATION CENTER',
  'BNOS YAAKOV ELEMENTARY SCHOOL',
  'BNOS YERUSHALAVIM',
  'BNOS YISROEL',
  'BNOT CHAYA ACADEMY',
  'BNOT RACHEL HIGH SCHOOL',
  'BOB JONES ACADEMY',
  "BOBBIE NOONAN'S CHILD CARE",
  'BOBBIE NOONANS CHILD CARE',
  'BOBBIE NOONANS CHILD CARE CENTER',
  'BOCA PREP INTERNATIONAL SCHOOL',
  'BOCA RATON CHRISTIAN SCHOOL',
  'BOCA RATON PRESCHOOL',
  'BOISE GIRLS ACADEMY-TEEN CHALLENGE PACIFIC NORTHWE',
  'BOLA CHILDCARE LEARNING CENTER',
  'BOLDER PATHWAY SCHOOL',
  'BONITA COUNTRY DAY SCHOOL',
  'BONITA LEARNING ACADEMY',
  'BONNER ACADEMY',
  'BONNIE BRAE SCHOOL',
  'BOOK OF LIFE ACADEMY',
  'BOOKCLIFF CHRISTIAN SCHOOL',
  'BOOMER CHRISTIAN ACADEMY',
  'BOOTH SCHOOL',
  'BORCULO CHRISTIAN SCHOOL',
  'BORKHOLDER PAROCHIAL SCHOOL',
  'BORN AGAIN CHRISTIAN ACADEMY',
  'BORN LEARNERS SCHOOL',
  'BORN TO THE GOLDEN MOUNTAIN MONTESSORI',
  'BORNBLUM JEWISH COMMUNITY SCHOOL',
  'BOSCO CATHOLIC SCHOOL SYSTEM',
  'BOSQUE SCHOOL',
  "BOSTON CHILDREN'S SCHOOL",
  'BOSTON COLLEGE HIGH SCHOOL',
  'BOSTON HIGASHI SCHOOL',
  'BOSTON TRINITY ACADEMY',
  'BOSTON UNIVERSITY ACADEMY',
  'BOULDER COUNTRY DAY SCHOOL',
  'BOULDER CREEK ACADEMY',
  'BOULDER KNOLL MONTESSORI SCHOOL',
  'BOULEVARD BAPTIST CHRISTIAN SCHOOL',
  'BOURGADE CATHOLIC HIGH SCHOOL',
  'BOUTTE CHRISTIAN ACADEMY',
  'BOWEN CREEK SCHOOL',
  'BOWER HOLLOW SCHOOL',
  'BOWIE READING AND LEARNING CENTER',
  'BOWLING GREEN CHRISTIAN ACADEMY',
  'BOWMAN HILLS SCHOOL',
  'BOWMAN SCHOOL',
  'BOXBERRY SCHOOL',
  'BOYD BUCHANAN SCHOOL',
  'BOYS & GIRLS HOME FAMILY SERVICES',
  'BOYS HOME',
  'BOYS RANCH LEARNING CENTER',
  'BOZEMAN SUMMIT SCHOOOL',
  'BR RICE HIGH SCHOOL',
  'BRACKEN CHRISTIAN SCHOOL',
  'BRADENTON CHRISTIAN SCHOOL',
  'BRADFIELD CENTER AULT ACADEMY',
  'BRADFORD ACADEMY',
  'BRADFORD AREA CHRISTIAN ACADEMY',
  'BRADFORD CHRISTIAN ACADEMY',
  'BRADLEY SCHOOL - WESTERLY SITE',
  'BRADLEY SCHOOL PROVIDENCE',
  'BRADSHAW CHRISTIAN HIGH SCHOOL',
  'BRAINERD BAPTIST SCHOOL',
  'BRAINTREE ST COLETTA DAY SCHOOL',
  'BRANDEIS MARIN',
  'BRANDON FIRST UMC KINDERGARTEN',
  'BRANDON HALL SCHOOL',
  'BRANDON SCHOOL & RESIDENTAL TREATMENT',
  "BRANDYWINE CHILDREN'S HOUSE",
  'BRANDYWINE VALLEY CHRISTIAN PRESCH',
  'BRASELTON CHRISTIAN ACADEMY',
  'BRAUSER MAIMONIDES ACADEMY',
  'BRAWERMAN WEST ELEMENTARY OF WILSHIRE BLVD TEMPLE',
  'BRAWLEY CHRISTIAN ACADEMY',
  'BRAZOS CHRISTIAN SCHOOL',
  'BRAZOS VALLEY CORNERSTONE CHRISTIAN ACADEMY',
  'BREAD OF LIFE CHURCH KINDERGARTEN',
  'BREAKWATER SCHOOL',
  'BREATH OF LIFE PREPARATORY PRESCHOOL',
  'BREBEUF JESUIT PREPARATORY SCHOOL',
  'BREC ACADEMY',
  'BRECK SCHOOL',
  'BRECKINRIDGE MONTESSORI SCHOOL',
  'BREEZEWAY AMISH SCHOOL',
  'BREEZEWAY SCHOOL',
  'BREEZEWOOD AMISH SCHOOL',
  'BREEZY HOLLOW AMISH SCHOOL',
  'BREEZY KNOLL SCHOOL',
  'BREEZY POINT DAY SCHOOL',
  'BREHM PREPARATORY SCHOOL',
  'BREMEN HOLINESS SCHOOL',
  'BRENTWOOD ACADEMY',
  'BRENTWOOD ADVENTIST CHRISTIAN SCHOOL',
  'BRENTWOOD CHRISTIAN SCHOOL',
  'BRENTWOOD SCHOOL',
  'BRENWOOD ACADEMY',
  'BRETHREN HERITAGE SCHOOL',
  'BREVARD ACADEMY',
  'BREVARD ADVENTIST CHRISTIAN ACADEMY',
  'BREVARD PRIVATE ACADEMY',
  'BREWSTER ACADEMY',
  'BRIARFIELD ACADEMY',
  'BRIARWOOD ACADEMY',
  'BRICE CHRISTIAN ACADEMY',
  'BRICK CHURCH SCHOOL',
  'BRICKHOUSE ACADEMY',
  'BRICKTON MONTESSORI SCHOOL',
  'BRIDGE BUILDER ACADEMY',
  'BRIDGE WAY',
  'BRIDGE WAY SCHOOL',
  'BRIDGEPORT CATHOLIC ACADEMY',
  'BRIDGEPORT HOPE SCHOOL',
  'BRIDGEPORT INTERNATIONAL ACADEMY',
  'BRIDGES ACADEMY',
  'BRIDGES ACADEMY (THE)',
  'BRIDGES MIDDLE SCHOOL',
  'BRIDGES MNONTESSORI',
  'BRIDGES MONTESSORI',
  'BRIDGES TREATMENT CENTER',
  'BRIDGETON CHRISTIAN SCHOOL',
  'BRIDGEVIEW AMISH SCHOOL',
  'BRIDGEVIEW MONTESSORI SCHOOL',
  'BRIDGEVILLE SCHOOL',
  'BRIDGEWAY ACADEMY',
  'BRIDGEWAY CHRISTIAN ACADEMY',
  'BRIDGEWOOD EDUCATION',
  'BRIDGTON ACADEMY',
  'BRIGHT ACADEMY',
  'BRIGHT BEGINNINGS',
  'BRIGHT BEGINNINGS ACADEMY',
  'BRIGHT BEGINNINGS CHRISTIAN ACADEMY',
  'BRIGHT BEGINNINGS CHRISTIAN PRESCHOOL',
  'BRIGHT BEGINNINGS ELEMENTARY',
  'BRIGHT BEGINNINGS PRE SCHOOL LEARNING CENTER',
  'BRIGHT BEGINNINGS PRESCHOOL',
  'BRIGHT CHILDREN MONTESSORI ACADEMY',
  'BRIGHT EYES ACADEMY',
  'BRIGHT FUTURE EDUCARE CENTER',
  'BRIGHT FUTURE SCHOOL OF EXCELLENCE',
  'BRIGHT FUTURES ACADEMY',
  'BRIGHT FUTURES ACADEMY RIVERSIDE',
  'BRIGHT FUTURES ACADEMY-APPLE VALLEY',
  'BRIGHT HORIZONS',
  'BRIGHT HORIZONS ACADEMY',
  'BRIGHT HORIZONS AT CARNEGIE CENTER',
  'BRIGHT HORIZONS AT COLLEGEVILLE',
  'BRIGHT HORIZONS AT KING OF PRUSSIA',
  'BRIGHT HORIZONS AT ST. MARY CHILD DEV.CENTER',
  'BRIGHT HORIZONS CHILD CARE & EARLY EDUCATION',
  'BRIGHT LEARNING - CYBER HIGH SCHOOL',
  'BRIGHT MONTESSORI SCHOOL, INC.',
  'BRIGHT PRAIRIE AMISH SCHOOL',
  'BRIGHT SPOT CHILD CARE',
  'BRIGHT STAR MONTESSORI ACADEMY',
  'BRIGHT STAR MONTESSORI SCHOOL',
  'BRIGHT STARS ACADEMY',
  'BRIGHT STEPS FORWARD',
  'BRIGHT TIME CHILD CARE LEARNING CENTER',
  'BRIGHT WATER WALDORF SCHOOL',
  'BRIGHTEN ACADEMY',
  'BRIGHTEN ACADEMY PRESCHOOL',
  'BRIGHTER CHILDREN LEARNING CENTER',
  'BRIGHTER DAYS MONTESSORI SCHOOL',
  'BRIGHTER HORIZON SCHOOL',
  'BRIGHTER HORIZONS ACADEMY',
  'BRIGHTMONT ACADEMY',
  'BRIGHTMONT ACADEMY - NORTHVILLE',
  'BRIGHTMONT ACADEMY - PLYMOUTH',
  'BRIGHTMONT ACADEMY - REDMOND CAMPUS',
  'BRIGHTMONT ACADEMY - SAMMAMISH',
  'BRIGHTMONT ACADEMY BIRMINGHAM',
  'BRIGHTON ADVENTIST ACADEMY',
  'BRIGHTON HALL',
  'BRIGHTON PRIVATE SCHOOL',
  'BRIGHTON SCHOOL',
  'BRIGHTWORKS',
  'BRILLIANT BEGINNING LEARNING CENTER',
  'BRIMMER & MAY SCHOOL',
  'BRINKLEY HEIGHTS URBAN ACADEMY',
  'BRISTLECONE MONTESSORI SCHOOL',
  'BRISTOL BAY CHRISTIAN LEARNING CENTER',
  'BRISTOW ADVENTIST SCHOOL',
  'BRISTOW MONTESSORI SCHOOL',
  "BRITISH INT'L SCHL WASHINGTON",
  'BRITISH INTERNATIONAL SCHOOL OF BOSTON',
  'BRITISH INTERNATIONAL SCHOOL OF CHICAGO, LINCOLN P',
  'BRITISH INTERNATIONAL SCHOOL OF NY',
  'BRITISH SCHOOL OF HOUSTON',
  'BRITO MIAMI PRIVATE SCHOOL',
  'BRITTAIN ACADEMY',
  'BRITTON RUN AMISH SCHOOL',
  'BRIXHAM MONTESSORI FRIENDS SCHOOL',
  'BROACH SCHOOL',
  'BROACH SCHOOL OF JACKSONVILLE-WEST CAMPUS',
  'BROACH SCHOOL SOUTH',
  'BROACH SCHOOL-FREE 2 BE ME',
  'BROACH WEST ELEMENTARY SCHOOL',
  "BROAD BRANCH CHILDREN'S HOUSE",
  'BROADFORDING CHRISTIAN ACADEMY',
  'BROADOAKS SCHOOL OF WHITTIER COLLEGE',
  'BROADWATER ACADEMY',
  'BROADWAY CHRISTIAN SCHOOL',
  "BROCK'S ACADEMY",
  'BROCKTON AREA SDA ACADEMY',
  'BROCKWAY CHRISTIAN ACADEMY',
  'BROOK LANE SCHOOL',
  'BROOK ROAD ACADEMY',
  'BROOKESIDE MONTESSORI SCHOOL',
  'BROOKFIELD ACADEMY',
  'BROOKFIELD ACADEMY - ROCHESTER HILLS',
  'BROOKFIELD ACADEMY - TROY',
  'BROOKFIELD ACADEMY - WEST BLOOMFIELD',
  'BROOKFIELD CHRISTIAN SCHOOL',
  'BROOKFIELD SCHOOL',
  'BROOKHAVEN ACADEMY EDUCATIONAL FOUNDATION',
  'BROOKHOLLOW CHRISTIAN ACADEMY',
  'BROOKINGS HARBOR CHRISTIAN SCHOOL',
  'BROOKLAKE CHRISTIAN SCHOOL',
  'BROOKLYN AMITY SCHOOL',
  'BROOKLYN AUTISM CENTER ACADEMY',
  'BROOKLYN BLUE FEATHER SCHOOL',
  'BROOKLYN FREE SCHOOL',
  'BROOKLYN FRIENDS SCHOOL',
  'BROOKLYN HEIGHTS MONTESSORI SCHOOL',
  'BROOKLYN JESUIT PREP',
  'BROOKLYN SEVENTH-DAY ADVENTIST SCHOOL',
  'BROOKS SCHOOL',
  'BROOKSFIELD SCHOOL',
  'BROOKSHIRE INTERNATIONAL ACADEMY',
  'BROOKSIDE BAPTIST ACADEMY',
  'BROOKSIDE CHRISTIAN SCHOOL',
  'BROOKSIDE MONTESSORI SCHOOL',
  'BROOKSIDE SCHOOL',
  'BROOKSTONE SCHOOL',
  'BROOKSTONE SCHOOLS',
  'BROOKVIEW SCHOOL',
  'BROOKVILLE CENTER FOR CHILDRENS SERVICES',
  'BROOKWOOD CHRISTIAN LANGUAGE SCHOOL',
  'BROOKWOOD SCHOOL',
  'BROOKWOOD SCHOOL (THE)',
  'BROOMFIELD ACADEMY',
  'BROPHY COLLEGE PREPARATORY',
  'BROTHER RICE HIGH SCHOOL',
  "BROWARD CHILDREN'S CENTER INC",
  'BROWARD JUNIOR ACADEMY',
  'BROWN MEMORIAL WEEKDAY SCHOOL',
  'BROWN SCHOOL',
  'BROWNE ACADEMY',
  'BROWNELL MOUNTAIN SCHOOL',
  'BROWNELL TALBOT SCHOOLS',
  'BROWNSTONE PRIVATE CHILD CARE',
  'BROWNSVILLE MONTESSORI ACADEMY',
  'BRTISH INTERNATIONAL SCHOOL OF CHARLOTTE',
  'BRUCE MOUND PAROCHIAL SCHOOL',
  'BRUNSWICK ACADEMY',
  'BRUNSWICK CHRISTIAN ACADEMY',
  'BRURIAH H.S. OF THE JEWISH EDUCATIONAL CENTER',
  'BRURIAH HIGH SCHOOL FOR GIRLS',
  'BRUSH ARBOR CHRISTIAN SCHOOL',
  'BRUSH CREEK MONTESSORI',
  'BRUSH RUN',
  'BRUSH RUN AMISH SCHOOL',
  'BRUSHY CREEK MONTESSORI SCHOOL',
  'BRYANS EDUCATIONAL CENTER',
  'BRYANT WOODS MONTESSORI CHILDRENS HOUSE',
  'BRYN ATHYN CHURCH SCHOOL',
  'BUCKEYE',
  'BUCKINGHAM BROWNE & NICHOLS SCHOOL',
  'BUCKINGHAM FRIENDS SCHOOL',
  'BUCKS RUN',
  'BUCKSPORT CHRISTIAN SCHOOL',
  'BUDDS CREEK AMISH PAROCHIAL SCHOOL',
  'BUENA VISTA',
  'BUENA VISTA AMISH SCHOOL ELAM H STOLTZFUS',
  'BUENA VISTA SDA SCHOOL',
  'BUFFALO ACADEMY OF SCHOLARS',
  'BUFFALO GROVE MONTESSORI SCHOOL',
  'BUFFALO HEARING & SPEECH CENTER',
  'BUFFALO SEMINARY',
  'BUFFALO SPRINGS PARISH SCHOOL',
  'BUFFALO SUBURBAN CHRISTIAN ACADEMY',
  'BUILDERS OF THE FAITH CHRISTIAN ACADEMY',
  'BUILDING BLOCKS',
  'BUILDING BLOCKS CTR FOR CHILDREN WITH AUTISM',
  'BUILDING BLOCKS LEARNING CENTER',
  'BUILDING BLOCKS MONTESSORI SCHOOL',
  'BUILDING BRIDGES',
  'BUILDING TOOLS LEARNING CENTER',
  'BULLFROGS AND BUTTERFLIES',
  'BULLIS SCHOOL',
  'BULLOCH ACADEMY',
  'BUMBLE BEE DAYCARE LEARNING CENTER',
  'BUNDY CANYON CHRISTIAN SCHOOL',
  'BURDICK RD AMISH SCHOOL',
  'BURGE TERRACE CHRISTIAN SCHOOL',
  'BURGUNDY FARM COUNTRY DAY SCHOOL',
  'BURKE MOUNTAIN ACADEMY',
  'BURLESON ADVENTIST SCHOOL',
  'BURLEY CHRISTIAN SCHOOL',
  'BURLINGTON CHRISTIAN ACADEMY',
  'BURLINGTON MONTESSORI SCHOOL',
  'BURLINGTON NOTRE DAME',
  'BURLINGTON NOTRE DAME JR/SR HIGH SCHOOL',
  'BURNHAM BROOK SCHOOL',
  'BURNS MEMORIAL UMC PRESCHOOL',
  'BURR & BURTON ACADEMY',
  'BURTON ADVENTIST ACADEMY',
  "BUSY BEE'S PLAYHOUSE",
  'BUSY BEES WONDERLAND PRESCHOOL',
  'BUTCHERS RUN AMISH SCHOOL',
  'BUTLER CATHOLIC SCHOOL',
  'BUTLER CHRISTIAN ACADEMY',
  'BUTLER COUNTY CHILDRENS CENTER',
  'BUTLER MONTESSORI',
  'BUTLER MONTESSORI SCHOOL',
  'BUTLER SCHOOL',
  'BUTLER WESLEYAN ACADEMY',
  'BUTTE CENTRAL CATHOLIC HIGH SCHOOL',
  'BUTTONS N BOWS',
  'BUXMONT ACADEMY',
  'BUXTON SCHOOL',
  'BYNE CHRISTIAN SCHOOL',
  'BYNUM SCHOOL',
  'BYRON CENTER CHRISTIAN SCHOOL',
  'BYRON CHRISTIAN ACADEMY',
  'Ba Kennedy School',
  'Babasaheb Bhimrao Ambedkar University',
  'Babb Middle School',
  'Babb School',
  'Babbitt Elementary',
  'Babcock Neighborhood School',
  'Babcock University',
  'Babeck Early Childhood School',
  'Babes-Bolyai University of Cluj-Napoca',
  'Babies/tap',
  'Babler Elem.',
  'Babler Lodge',
  'Babol Noshirvani University of Technology',
  'Babol University of Medical Sciences',
  'Baboquivari High School',
  'Baboquivari Middle School',
  'Babson College',
  'Baby Boomers',
  'Babylon Elementary School',
  'Babylon Junior-senior High School',
  'Babylon Memorial Grade School',
  "Baca/dlo'ay Azhi Community School",
  'Baccalaureate School For Global Education',
  'Bach Elementary School',
  'Bache-martin Sch',
  'Bachrodt Charter Academy',
  'Back Bay High',
  'Back Creek Elementary',
  'Back Creek Valley Elementary School',
  'Back El',
  'Back Of The Yards Ib Hs',
  'Backman School',
  'Backpack Early Learning Academy',
  'Bacon Academy',
  'Bacon County Elementary School',
  'Bacon County High School',
  'Bacon County Middle School',
  'Bacon County Primary School',
  'Bacon District Elementary',
  'Bacon Elementary School',
  'Baconton Community Charter School',
  'Bad Axe Elementary School',
  'Bad Axe High School',
  'Bad Axe Middle School',
  'Badakhshan University',
  'Baden Academy Cs',
  'Baden Elementary',
  'Baden High (continuation)',
  'Badger Academy',
  'Badger Clark Elementary - 05',
  'Badger Elementary',
  'Badger Elementary School',
  'Badger High',
  'Badger High School',
  'Badger Middle',
  'Badger Middle School',
  'Badger Mountain Elementary',
  'Badger Ridge Middle',
  'Badger Rock Middle',
  'Badger Secondary',
  'Badger Springs Middle',
  'Badger Vocational Education Center - North',
  'Badger Vocational Education Center - South',
  'Badillo Elementary',
  'Badin Elementary',
  'Baechtel Grove Middle',
  'Bagby Elementary',
  'Bagdad El',
  'Bagdad Elementary School',
  'Bagdad Middle / Senior High School',
  'Baggaley El Sch',
  'Baggett Elementary School',
  'Baggs Elementary',
  'Baghdad College of Economic Sciences University',
  'Baghdad College of Pharmacy',
  'Baghlan University',
  'Baghyatoolah Medical Sciences University',
  'Bagley Alp',
  'Bagley Elementary',
  'Bagley Elementary School',
  'Bagley Middle School',
  'Bagley Secondary',
  "Baha'i Institute for Higher Education",
  'Bahauddin Zakariya University Multan',
  'Bahcesehir University',
  'Bahia Vista Elementary',
  'Bahir Dar University',
  'Bahria University',
  "Baika Women's College",
  'Baikal National University of Economics and Law',
  "Baiko Women's College",
  'Bailey Avenue School',
  'Bailey Bridge Middle',
  'Bailey Charter School',
  'Bailey Early Childhood Center',
  'Bailey El',
  'Bailey Elementary',
  'Bailey Elementary School',
  'Bailey Gatzert Elementary School',
  'Bailey Inglish Early Childhood Center',
  'Bailey Int',
  'Bailey J H',
  'Bailey Lake Elementary School',
  'Bailey M S',
  'Bailey Middle',
  'Bailey Middle Apac School',
  'Bailey Park Elementary School',
  'Bailey Station Elementary School',
  "Bailey's Elementary School For The Arts And Sciences",
  "Bailey's Upper Elementary School For The Arts And Sciences",
  'Baileysville Elementary & Middle School',
  'Baileyton Elementary',
  'Baileywick Road Elementary',
  'Bailly Elementary School',
  'Bailly Preparatory Academy',
  'Bain Elementary',
  'Bainbridge El Sch',
  'Bainbridge Elementary',
  'Bainbridge Elementary School',
  'Bainbridge High School',
  'Bainbridge Middle School',
  'Bainbridge Special Education Services',
  'Bainbridge State College',
  'Bainbridge-guilford High School',
  'Bains Elementary School',
  'Bains Lower Elementary School',
  'Bainville 7-8',
  'Bainville High School',
  'Bainville School',
  'Bair Middle School',
  'Baird El',
  'Baird Elementary',
  'Baird H S',
  'Baird Middle',
  'Baitulmal Management Institute (IPB)',
  'Bak Middle School Of The Arts',
  'Baker',
  'Baker 7-8',
  'Baker College',
  'Baker County Adult Center',
  'Baker County K12 School',
  'Baker County Learning Center',
  'Baker County Middle School',
  'Baker County Senior High School',
  'Baker County Virtual Franchise',
  'Baker Early College',
  'Baker El',
  'Baker El Sch',
  'Baker Elementary',
  'Baker Elementary School',
  'Baker Heights Elementary School',
  'Baker High',
  'Baker High School',
  'Baker Interdistrict Elem. Sch.',
  'Baker Junior High',
  'Baker Middle',
  'Baker Middle School',
  'Baker Place Elementary',
  'Baker Prairie Middle School',
  'Baker School',
  'Baker University',
  'Baker Virtual Instruction Program',
  'Baker Virtual Instruction Program (district Provided)',
  'Baker Web Academy',
  'Baker-butler Elem',
  'Baker-koonce Int',
  'Baker-ripley Charter School',
  'Baker/head Start',
  'Bakerfield Elementary',
  'Bakerfield Elementary School',
  'Bakersfield College',
  'Bakersfield Elem.',
  'Bakersfield High',
  'Bakersfield School',
  'Bakersville School',
  'Bakerville Consolidated School',
  'Bakht Er-Ruda University',
  'Bakhtar University',
  'Bakken Elementary School',
  'Bakker Elementary School',
  'Baku Academy of Music',
  'Baku Business University',
  'Baku Slavic University',
  'Baku State University',
  'Bala-cynwyd Ms',
  'Balamand University',
  'Balboa Elementary',
  'Balboa Gifted/high Ability Magnet Elementary',
  'Balboa High',
  'Balboa Middle',
  'Balch',
  'Bald Creek Elementary',
  'Bald Eagle Area Jshs',
  'Bald Knob High School',
  'Bald Knob Middle School',
  'Baldi C C A Ms',
  'Baldwin (julia) Elementary',
  'Baldwin Academy',
  'Baldwin Art And Academics Magnet',
  'Baldwin County High School',
  'Baldwin County Virtual School',
  'Baldwin Creek Elementary',
  'Baldwin Early Learning Center',
  'Baldwin El',
  'Baldwin Elementary',
  'Baldwin Elementary Intermediate Center',
  'Baldwin Elementary Primary Center',
  'Baldwin Elementary School',
  'Baldwin Heights School',
  'Baldwin High School',
  'Baldwin Hills Elementary',
  'Baldwin Intermediate',
  'Baldwin Intermediate School',
  'Baldwin Junior High School',
  'Baldwin Lane Elementary',
  'Baldwin Middle School',
  'Baldwin Middle-senior High School',
  'Baldwin Park High',
  'Baldwin Road Junior High School',
  'Baldwin Senior High School',
  'Baldwin Shs',
  'Baldwin Stocker Elementary',
  'Baldwin Street Middle School',
  'Baldwin-Wallace College',
  'Baldwin-woodville High',
  'Baldwinville Elementary',
  'Baldwyn Career Advancement Center',
  'Baldwyn Elementary School',
  'Baldwyn High School',
  'Baldwyn Middle School',
  'Baldy Mesa Elementary',
  'Baldy View Elementary',
  'Baldy View Rop',
  'Bale Elementary School',
  'Bales Elementary School',
  'Balfour Elementary',
  'Balikesir University',
  'Balkh University',
  'Balko Es',
  'Balko Hs',
  'Ball Camp Elementary',
  'Ball Charter Schools (val Vista)',
  'Ball Early Childhood Center',
  'Ball Elementary School',
  'Ball Ground Elementary School',
  'Ball H S',
  'Ball Junior High',
  'Ball State University',
  "Ball's Bluff Elementary",
  'Ballantyne Elementary',
  'Ballard Brady Middle School',
  'Ballard Co. Preschool/headstart Center',
  'Ballard County Career And Technology Ctr',
  'Ballard County Elementary School',
  'Ballard County Middle School',
  'Ballard East Elementary',
  'Ballard Elem.',
  'Ballard Elementary',
  'Ballard Elementary School',
  'Ballard High',
  'Ballard High School',
  'Ballard Hudson Middle School',
  'Ballard Memorial High School',
  'Ballard Middle School',
  'Ballard School',
  'Ballard West Elementary',
  'Ballast Point Elementary School',
  'Ballenger Creek Elementary',
  'Ballenger Creek Middle',
  'Ballentine Elementary',
  'Ballet Tech/nyc Public School For Dance',
  'Ballico Elementary',
  'Balliet Middle School',
  'Ballinger El',
  'Ballinger H S',
  'Ballinger J H',
  'Ballington Acad For The Arts And Scis - San Bernardino',
  'Ballington Academy For The Arts And Sciences',
  'Ballman Elementary School',
  'Ballou Hs',
  'Ballou Jr High',
  'Ballou Stay Hs',
  'Balls Creek Elementary',
  'Ballston Spa Middle School',
  'Ballston Spa Senior High School',
  'Ballwin Elem.',
  'Balmoral Elem Sch',
  'Balmoral/ridgeway Elementary',
  'Balmorhea School',
  'Balmville School',
  'Balochistan University of Engineering and Technology Khuzdar',
  'Balochistan University of Information Technology & Management Sciences',
  'Balsz Elementary School',
  'Balti State University "Alecu Russo"',
  'Baltic Elementary - 02',
  'Baltic Elementary School',
  'Baltic High School - 01',
  'Baltic International Academy',
  'Baltic Middle School - 03',
  'Baltic State Technical University',
  'Baltimore City College',
  'Baltimore City Community College',
  'Baltimore Collegiate School For Boys',
  'Baltimore County Home & Hospital',
  'Baltimore Design School',
  'Baltimore Highlands Elementary',
  'Baltimore I.t. Academy',
  'Baltimore International Academy',
  'Baltimore Leadership School For Young Women',
  'Baltimore Montessori Public Charter School',
  'Baltimore Polytechnic Institute',
  'Baltimore School For The Arts',
  'Baltz (austin D.) Elementary School',
  'Bamber Valley Elementary',
  'Bamberg-ehrhardt High',
  'Bamberg-ehrhardt Middle',
  'Bamenda University of Science & Technology',
  'Bamiyan University',
  'Bammel El',
  'Bammel Middle',
  'Banaadir Academy',
  'Banaadir South',
  'Banaras Hindu University',
  'Banasthali University',
  "Banat's University of Agricultural Sciences",
  'Bancroft El Sch',
  'Bancroft Elementary',
  'Bancroft Elementary School',
  'Bancroft Es At Sharpe',
  'Bancroft Middle',
  'Bancroft-rosalie Elementary School',
  'Bancroft-rosalie Jr/sr High School',
  'Bandelier Elementary',
  'Bandera H S',
  'Bandera Middle',
  'Bandini Elementary',
  'Bandini Street Elementary',
  'Bandirma ONYEDİ Eylul University',
  'Bandon Senior High School',
  'Bandys High',
  'Bane El',
  'Banfield Elementary',
  'Bang El',
  'Bangabandhu Sheikh Mujibur Rahman Agricultural University',
  'Bangabandhu Sheikh Mujibur Rahman Medical University',
  'Bangalore University',
  'Bangkok University',
  'Bangladesh Agricultural University',
  'Bangladesh Open University',
  'Bangladesh University',
  'Bangladesh University of Business & Technology',
  'Bangladesh University of Engineering and Technology',
  'Bangladesh University of Professionals',
  'Bangladesh University of Textiles',
  'Bangor Area Hs',
  'Bangor Area Ms',
  'Bangor Career Academy',
  'Bangor Central School',
  'Bangor Elementary',
  'Bangor High School',
  'Bangor Lincoln School',
  'Bangor Middle School',
  'Bangor Middle/high',
  'Bangor Township Virtual School',
  'Bangor West Central School',
  'Bangs H S',
  'Bangs Middle',
  'Bankbridge Regional School',
  'Bankhead Middle School',
  'Banking University of Ho Chi Minh City',
  'Banks County Elementary School',
  'Banks County High School',
  'Banks County Middle School',
  'Banks County Primary School',
  'Banks Elementary',
  'Banks Elementary School',
  'Banks High School',
  'Banks Middle School',
  'Banks Road Elementary',
  'Banks School',
  'Banks Trail Middle',
  'Bankscaddell Elementary School',
  'Bankston Elementary School',
  'Bankura University',
  'Bannach Elementary',
  'Banneker (benjamin) Elementary School',
  'Banneker Blake Academy For Arts And Sciences',
  'Banneker Elem',
  'Banneker Elementary',
  'Banneker High School',
  'Banner County Elementary School',
  'Banner County High School',
  'Banner Elementary School',
  'Banner Elk Elementary',
  'Banner Preparatory School Of Milwaukee',
  'Banner Public School',
  'Banner West Academy Hs',
  'Banning Acads Of Creative And Innovative Scis (bacis)',
  'Banning High',
  'Banning Independent Study',
  'Banning Lewis Ranch Academy',
  'Banning Prek',
  'Bannockburn Elementary',
  'Bannockburn School',
  'Bannon Creek',
  'Banoak Elementary',
  'Banquete El',
  'Banquete H S',
  'Banquete J H',
  'Banquete Jjaep',
  'Banta Elementary',
  'Banta School',
  'Banting Elementary',
  'Banuelos El',
  'Banyan Creek Elementary School',
  'Banyan Elementary',
  'Banyan Elementary School',
  'Baptist Bible College',
  'Baptist Hill High',
  'Baqai Medical University',
  'Bar Nunn Elementary',
  'Bar-Ilan University',
  'Bar-o',
  'Baraboo Early Learning Cooperative',
  'Baraboo High',
  'Barack And Michelle Obama Academy',
  'Barack H Obama Learning Academy',
  'Barack H. Obama Elementary Magnet School Of Technology',
  'Barack Obama Academy For Academic & Civic Development',
  'Barack Obama Charter',
  'Barack Obama El Male Leadership Academy',
  'Barack Obama Elementary',
  'Barack Obama Elementary School',
  'Barack Obama Global Preparation Academy',
  'Barack Obama Male Leadership Aca At Bf Darrell H S',
  'Barack Obama Male Leadership Aca At Bf Darrell Mid',
  'Barack Obama Sch Of Ldership',
  'Barack Obama School',
  'Baraga Maximum Correctional Facility',
  'Baraga School',
  'Barahona Elemental',
  'Baranof Elementary',
  'Baranoff El',
  'Barbara A Sizemore Campus',
  'Barbara And Hank Greenspun Junior High School',
  'Barbara B Rose Elem School',
  'Barbara B. Robey Elementary School',
  'Barbara Benson Elementary',
  'Barbara Bush El',
  'Barbara Cardwell Career Preparatory Center',
  'Barbara Chilton Middle',
  'Barbara Cockrell El',
  'Barbara Comstock Morse Elementary',
  'Barbara Culver Juvenile Detention Center',
  'Barbara Esselborn School (the)',
  'Barbara F Schreiber Elementary School',
  'Barbara Fasken El',
  'Barbara Gilligan 7-8',
  'Barbara Gilligan School',
  'Barbara Goleman Senior High',
  'Barbara Hawkins Elementary School',
  'Barbara Ingram School For The Arts',
  'Barbara Jordan El',
  'Barbara Jordan Elem.',
  'Barbara Jordan Int',
  'Barbara M Manns Education Center',
  'Barbara Mcclintock Stem Elementary',
  'Barbara Morgan Stem Academy',
  'Barbara R Morgan Elementary',
  'Barbara Spratling Middle',
  'Barbara Walker El',
  'Barbara Webster Elementary',
  'Barbara Worth Junior High',
  'Barbara Yarbrough El',
  'Barbe Elementary School',
  'Barbee Elementary',
  'Barber Community Learning Center',
  'Barber Elementary School',
  'Barber Middle School',
  'Barbers Hill El North',
  'Barbers Hill El South',
  'Barbers Hill H S',
  'Barbers Hill Kindergarten Center',
  'Barbers Hill Middle North',
  'Barbers Hill Middle South',
  'Barbers Hill Pri',
  'Barbers Point Elementary School',
  'Barberton Elementary School East',
  'Barberton Elementary School West',
  'Barberton High School',
  'Barberton Middle School',
  'Barberton Preschool',
  'Barbieri Elementary',
  'Barbour County High School',
  'Barbour County Intermediate School',
  'Barbour County Primary School',
  'Barbour Two-way Lang Immersion',
  'Barboursville Middle School',
  'Barboursville School',
  'Barbourville City School',
  'Barcelona Elementary',
  'Barcelona Graduate School of Economics',
  'Barcelona Middle School',
  'Barclay Brook School',
  'Barclay Early Childhood Center',
  'Barclay El Sch',
  'Barclay Elementary School',
  'Barclay Elementary/middle',
  'Barclay Hills Education Center',
  'Barcroft Elementary',
  'Bard College',
  'Bard Early College Cleveland',
  'Bard Early College High School',
  'Bard High Early College Cleveland East Campus',
  'Bard High School Early College',
  'Bard High School Early College Queens',
  'Bardin Elementary',
  'Bardmoor Elementary School',
  'Bardonia Elementary School',
  'Bardstown Alternative School',
  'Bardstown Early Childhood Center',
  'Bardstown Elementary School',
  'Bardstown High School',
  'Bardstown Middle School',
  'Bardstown Primary School',
  'Barely Bears',
  'Baresville El Sch',
  'Barfield Elementary',
  'Barge-lincoln Elementary School',
  'Barger Academy',
  'Bargerton Elementary',
  'Barhitte Elementary School',
  'Bark Riverharris Elementary School',
  'Bark Riverharris Jrsr High School',
  'Barkatullah University',
  'Barkdull Faulk Elementary School',
  'Barkell Elementary School',
  'Barker Creek Community School',
  'Barker Junior/senior High School',
  'Barker Middle School',
  'Barker Road Middle School',
  'Barkers Mill Elementary',
  'Barkhamsted Elementary School',
  'Barkley Bridge Elementary School',
  'Barkley El Sch',
  'Barksdale El',
  'Barksdale Elementary',
  'Barksdale Elementary School',
  'Barley Sheaf Elementary School',
  'Barling Elementary School',
  'Barlow Mountain Elementary School',
  'Barlow Park Elementary',
  'Barnabus Home High School',
  'Barnabus Home Middle School',
  'Barnaby Manor Elementary',
  'Barnard Academy',
  'Barnard College',
  'Barnard Elem Comp Math & Sci Ctr',
  'Barnard Elementary',
  'Barnard Elementary School',
  'Barnard Environmental Magnet School',
  'Barnard Es',
  'Barnardsville Elementary',
  'Barnegat High School',
  'Barnes Butte Elementary',
  'Barnes County North Elementary School',
  'Barnes County North High School',
  'Barnes Early Childhood Center',
  'Barnes El',
  'Barnes Elementary',
  'Barnes Elementary School',
  'Barnes Es',
  'Barnes Learning Center (alc)',
  'Barnesville Elementary',
  'Barnesville Elementary School',
  'Barnesville High School',
  'Barnesville Middle School',
  'Barnesville Secondary',
  'Barnet Elementary School',
  'Barnett Elementary',
  'Barnett J H',
  'Barnett School',
  'Barnett Shoals Elementary School',
  'Barnette Elementary',
  'Barnette Magnet School',
  'Barneveld Elementary',
  'Barneveld High',
  'Barnsdale Road School',
  'Barnsdall Es',
  'Barnsdall Hs',
  'Barnsdall Jhs',
  'Barnstable Community Horace Mann Charter Public School',
  'Barnstable High',
  'Barnstable Intermediate School',
  'Barnstable United Elementary School',
  'Barnstead Elementary School',
  'Barnum Elementary',
  'Barnum Elementary School',
  'Barnum School',
  'Barnum Secondary',
  'Barnum Woods School',
  'Barnwell County Career Center',
  'Barnwell Elementary',
  'Barnwell Elementary School',
  'Barnwell High',
  'Barnwell Middle',
  'Barnwell Primary',
  'Baron Dekalb Elementary',
  'Barona Indian Charter',
  'Barone Middle School',
  'Barr Elementary School',
  'Barr Middle School',
  'Barr Reeve Elementary School',
  'Barr Reeve Middle/high School',
  'Barr Reeve Primary Grade School',
  'Barrackville Elementary/middle School',
  'Barranca Elementary',
  'Barranca Mesa Elementary',
  'Barratt School',
  'Barre City Elementary/middle School',
  'Barre Town Elementary School',
  'Barrel Springs Elementary',
  'Barren County Area Technology Center',
  'Barren County High School',
  'Barren County Middle School',
  'Barret Traditional Middle',
  "Barret's Chapel Elementary/middle",
  'Barrett El',
  'Barrett El Sch',
  'Barrett Elementary',
  'Barrett Elementary School',
  'Barrett Ranch Elementary',
  'Barrett Russell Early Childhood Center',
  'Barretts Elem.',
  'Barriada Caban',
  'Barrick El',
  'Barrie Elementary',
  'Barringer Academic Center',
  'Barringer Academy Of S.t.e.a.m.',
  'Barringer Arts High School',
  'Barringer Road Elementary School',
  'Barrington Early Learning Center',
  'Barrington El',
  'Barrington Elem.',
  'Barrington Elementary School',
  'Barrington High School',
  'Barrington Mdle Sch- Prairie Cmps',
  'Barrington Middle Sch Station',
  'Barrington Middle School',
  'Barrington Place El',
  'Barrington Road Elementary School',
  'Barron Area Montessori School',
  'Barron Collier High School',
  'Barron El',
  'Barron Elementary',
  'Barron High',
  'Barron Park Elementary',
  'Barrow El',
  'Barrow Elementary School',
  'Barrow High School',
  'Barrows Adult Education',
  'Barry',
  'Barry Comm John Sch',
  'Barry Craig Stewart Kasaan School',
  'Barry Elem School',
  'Barry Elementary',
  'Barry Goldwater High School',
  'Barry Isd Special Education',
  'Barry Sch.',
  'Barry University',
  'Barryton Elementary School',
  'Barstow Community College',
  'Barstow Elementary',
  'Barstow High',
  'Barstow Junior High',
  'Barstow Memorial School',
  'Barstow Stem Academy',
  'Bart-colerain El Sch',
  'Bartelso Elem School',
  'Barth El Sch',
  'Barth Elementary School',
  'Bartin University',
  'Bartle Elementary School',
  'Bartlesville Hs',
  'Bartlett 9th Grade Academy',
  'Bartlett Community Partnership',
  'Bartlett Educational Center',
  'Bartlett Elem',
  'Bartlett Elem School',
  'Bartlett Elementary School',
  'Bartlett High School',
  'Bartlett Middle',
  'Bartlett Schools',
  'Bartlett Yancey High',
  'Bartley Elem.',
  'Bartolo Caussade Gonzalez',
  'Bartolome De Las Casas',
  'Barton (clara) Elementary',
  'Barton Chapel Elementary School',
  'Barton Clara Sch',
  'Barton County Community College',
  'Barton Creek El',
  'Barton El',
  'Barton Elem School',
  'Barton Elementary',
  'Barton Elementary School',
  'Barton Graded School',
  'Barton High School',
  'Barton Hill Elementary',
  'Barton Hills El',
  'Barton Jr. High School',
  'Barton Open Elementary',
  'Barton Township Elementary',
  'Bartonville Elem School',
  'Bartow Elementary Academy',
  'Bartow Middle School',
  'Bartow Senior High School',
  'Bartow Youth Academy',
  'Bartram John - Main',
  'Bartram Springs Elementary',
  'Bartram Trail High School',
  'Baruch College Campus High School',
  'Barwell Road Elementary',
  'Barwise Middle',
  'Basalt Elementary School',
  'Basalt High School',
  'Basalt Middle School',
  'Bascom Elementary School',
  'Bascomb Elementary School',
  'Basehor Elementary School',
  'Basehor-linwood High School',
  'Basehor-linwood Middle School',
  'Baseline Elementary School',
  'Baseline Middle School',
  'Basha Elementary',
  'Basha High School',
  'Bashkir State Agrarian University',
  'Bashkir State Medical University',
  'Bashkir State Pedagogical University named After M. Akmullah',
  'Bashkir State University',
  "Basic Academy Of Int'l Studies Hs",
  'Basic Skills-4',
  'Basile High School',
  'Basilicata University Potenza',
  'Basilio Milan Hernandez',
  'Basin Alternative High School',
  'Basin City Elem',
  'Basin Elementary School',
  'Basin School',
  'Basis Ahwatukee',
  'Basis Chandler',
  'Basis Chandler Primary - North Campus',
  'Basis Chandler Primary- South Campus',
  'Basis Dc Pcs',
  'Basis Flagstaff',
  'Basis Goodyear',
  'Basis Goodyear Primary',
  'Basis Mesa',
  'Basis Oro Valley',
  'Basis Oro Valley Primary',
  'Basis Peoria',
  'Basis Peoria Primary',
  'Basis Phoenix',
  'Basis Phoenix Central Primary',
  'Basis Phoenix Primary',
  'Basis Phoenix South Primary',
  'Basis Prescott',
  'Basis San Antonio Pri- Medical Center Campus',
  'Basis San Antonio- Pri North Central Campus',
  'Basis San Antonio- Shavano Campus',
  'Basis Scottsdale',
  'Basis Scottsdale Primary',
  'Basis Tucson North',
  'Basis Tucson Primary',
  'Baskent University',
  'Baskerville Elementary',
  'Baskin El',
  'Baskin School',
  'Bass Elem School',
  'Bass River Township Elementary School',
  'Bass-hoover Elementary',
  'Bassett Elementary School',
  'Bassett Grade School',
  'Bassett High',
  'Bassett Middle',
  'Bassett Senior High',
  'Bassett Street Elementary',
  'Bassetti El',
  'Bassick High School',
  'Basswood El',
  'Basswood Elementary',
  'Bastian El',
  'Bastian School',
  'Bastrop County Juvenile Boot Camp',
  'Bastrop H S',
  'Bastrop High School',
  'Bastrop Int',
  'Bastrop Middle',
  'Bastyr University',
  'Bataan Elementary',
  'Bataan Memorial Intermediate School',
  'Bataan Memorial Primary School',
  'Batavia Elementary School',
  'Batavia High School',
  'Batavia Middle School',
  'Batavia Sr High School',
  'Batchelder School',
  'Batcheller Early Education Center',
  'Batchelor Institute of Indigenous Tertiary Education',
  'Bateman Elem School',
  'Bates',
  'Bates Academy',
  'Bates College',
  'Bates Elementary',
  'Bates Elementary School',
  'Bates Middle',
  'Bates School',
  'Bates Technical College',
  'Bates Technical College - Open Doors',
  'Bates Technical High School',
  'Batesburg-leesville Elementary',
  'Batesburg-leesville High',
  'Batesburg-leesville Middle',
  'Batesburg-leesville Primary',
  'Batesland School - 01',
  'Batesville Elementary School',
  'Batesville High School',
  'Batesville Intermediate School',
  'Batesville Junior High School',
  'Batesville Middle School',
  'Batesville Primary School',
  'Batesville School',
  'Bath County High',
  'Bath County High School',
  'Bath County Middle School',
  'Bath Elementary',
  'Bath Elementary School',
  'Bath High School',
  'Bath Middle School',
  'Bath Regional Career & Technical',
  'Bath Village School',
  'Bathgate Elementary',
  'Batman University',
  'Baton Elementary',
  'Baton Rouge Bridge Academy Inc.',
  'Baton Rouge College Prep',
  'Baton Rouge Community College',
  'Baton Rouge Magnet High School',
  'Baton Rouge University Preparatory Elementary',
  'Baton Rouge Visual And Performing Arts Center',
  'Battell Elementary School',
  'Batterjee Medical College',
  'Battery Creek High',
  'Battery Park City School',
  'Battiest Es',
  'Battiest Hs',
  'Battle Academy For Teaching Learning',
  'Battle Creek Area Learning Center',
  'Battle Creek Central High School',
  'Battle Creek Elementary School',
  'Battle Creek High School',
  'Battle Creek Mathsci Center',
  'Battle Creek Middle',
  'Battle Creek Montessori Academy',
  'Battle Creek-ida Grove Senior High School',
  'Battle Ground Elementary School',
  'Battle Ground High School',
  'Battle Ground Middle School',
  'Battle Grove Elementary',
  'Battle Hill',
  'Battle Lake Elementary',
  'Battle Lake Secondary',
  'Battle Monument School',
  'Battle Mountain Early College High School',
  'Battle Mountain Elementary School',
  'Battle Mountain High School',
  'Battle Mountain Junior High School',
  'Battle Rock Charter School',
  'Battlefield Elementary',
  'Battlefield Elementary School',
  'Battlefield High',
  'Battlefield Middle',
  'Battlefield Park Elementary',
  'Battlefield Primary',
  'Battleground Elementary',
  'Battles (washington) Elementary',
  'Baty El',
  'Bauchi State University Gadau',
  'Baucom Elementary',
  'Bauder Elementary School',
  'Baudhuin Oral School-nova University',
  'Bauer Elementary School',
  'Bauerwood School',
  'Bauhaus Universität Weimar',
  'Bauman Moscow State Technical University',
  'Bautista Creek Elementary',
  'Bauxite High School',
  'Bauxite Middle School',
  'Bavel',
  'Baxter Academy For Tech & Sciences',
  'Baxter Elementary',
  'Baxter Elementary School',
  'Baxter High School',
  'Baxter Primary',
  'Baxter Springs High',
  'Baxterville School',
  'Bay Area Technology',
  'Bay City Academy Farragut Campus',
  'Bay City Academy Madison Arts Campus',
  'Bay City Adult Educationctp',
  'Bay City Central High School',
  'Bay City H S',
  'Bay City J H',
  'Bay City Western High School',
  'Bay Colony El',
  'Bay Creek Elementary School',
  'Bay Creek Middle School',
  'Bay Crest Elementary School',
  'Bay El',
  'Bay Elementary',
  'Bay Elementary School',
  'Bay Farm',
  'Bay Harbor Elementary',
  'Bay Haven Charter Academy',
  'Bay Haven Charter Middle School',
  'Bay Haven School Of Basics Plus',
  'Bay Head Elementary',
  'Bay High School',
  'Bay Lake Elementary',
  'Bay Lane Middle',
  'Bay Laurel Elementary',
  'Bay Meadows Elementary',
  'Bay Middle School',
  'Bay Mills Community College',
  'Bay Minette Elementary School',
  'Bay Minette Intermediate School',
  'Bay Minette Middle School',
  'Bay Park Elementary',
  'Bay Path Regional Vocational Technical High School',
  'Bay Pines Center',
  'Bay Point Elementary School',
  'Bay Point Middle School',
  'Bay Port High',
  'Bay Regional Juvenile Detention Center',
  'Bay Ridge Elementary',
  'Bay Shore Middle School',
  'Bay Shore Senior High School',
  'Bay Springs Elem Sch',
  'Bay Springs High School',
  'Bay Springs Middle Sch',
  'Bay Springs Middle School',
  'Bay Trail Middle School',
  'Bay View',
  'Bay View Academy',
  'Bay View Elementary',
  'Bay View High',
  'Bay View Middle',
  'Bay View Montessori School',
  'Bay Virtual Franchise',
  'Bay Virtual Instruction (course Offerings)',
  'Bay Virtual Instruction Program',
  'Bay Vista Fundamental Elementary School',
  'Bay Waveland Middle School',
  'Bay de Noc Community College',
  'Bay-brook Elementary',
  'Bayan College for Science & Technology',
  'Bayard Elementary',
  'Bayard Elementary School',
  'Bayard Middle School',
  'Bayard Secondary School',
  'Bayarenac Community High School',
  'Bayberry School',
  'Bayburt University',
  'Baycare Behavioral Health',
  'Baychester Academy',
  'Baychester Middle School',
  'Bayerische Julius-Maximilians-Universität Würzburg',
  'Bayero University Kano',
  'Bayfield Elementary',
  'Bayfield Elementary School',
  'Bayfield High',
  'Bayfield High School',
  'Bayfield Middle',
  'Bayfield Middle School',
  'Bayles El',
  'Bayless El',
  'Bayless Elementary',
  'Bayless Junior High',
  'Bayless Senior High',
  'Baylis Elementary School',
  'Baylor College Of Medicine Academy At Ryan',
  'Baylor College Of Medicine Biotech Academy At Rusk',
  'Baylor College of Medicine',
  'Baylor University',
  'Bayonet Point Middle School',
  'Bayonne Academy',
  'Bayonne High School',
  'Bayou Black Elementary School',
  'Bayou Blue Elementary School',
  'Bayou Blue Middle School',
  'Bayou Boeuf Elementary School',
  'Bayou Chicot Elementary School',
  'Bayou Community Academy Charter School',
  "Bayou L'ourse Primary School",
  'Bayou Lacombe Middle School',
  'Bayou Meto Elementary School',
  'Bayou View Elementary School',
  'Bayou View Middle School',
  'Bayou Vista Elementary School',
  'Bayou Woods Elementary School',
  'Baypoint Preparatory Academy',
  'Bayport-blue Point High School',
  'Bayshore',
  'Bayshore Community Academy',
  'Bayshore El',
  'Bayshore Elementary',
  'Bayshore Elementary School',
  'Bayshore High School',
  'Bayshore Middle School',
  'Bayshore Preparatory Charter',
  'Bayside 6th Grade Campus',
  'Bayside Academy',
  'Bayside Community Day',
  'Bayside Elementary',
  'Bayside Elementary School',
  'Bayside High',
  'Bayside High School',
  'Bayside Int',
  'Bayside Martin Luther King Jr. Academy',
  'Bayside Middle',
  'Bayside Steam Academy',
  'Baystate Academy Charter Public School',
  'Baytown J H',
  'Bayvale Elementary School',
  'Bayview Avenue School',
  'Bayview Elementary',
  'Bayview Elementary School',
  'Bayville Elementary School',
  'Baywood Elementary',
  'Bayyari Elementary School',
  'Baze University Abuja',
  'Bazoline E. Usher/collier Heights Elmentary School',
  'Bb Comer Memorial Elementary School',
  'Bb Comer Memorial High School',
  'Bbe Learning Center',
  'Bcdc Educational Center',
  'Bcig Elementary School',
  'Bcluw Elementary School',
  'Bcluw High School',
  'Bcluw Middle School',
  'Bdote Learning Center',
  'Bdusd4kids',
  'Be.tech',
  'Bea Underwood Elementary School',
  'Beach Court Elementary School',
  'Beach Elem',
  'Beach Elementary',
  'Beach Elementary School',
  'Beach Haven School',
  'Beach High School',
  'Beach High-intensive Learning Program',
  'Beach Middle School',
  'Beach Park Middle School',
  'Beach Street Middle School',
  'Beachland Elementary School',
  'Beachmont Veterans Memorial School',
  'Beachside Montessori Village',
  'Beachwood Elementary School',
  'Beachwood High School',
  'Beachwood Middle School',
  'Beachy Avenue Elementary',
  'Beacon Academy',
  'Beacon Academy Esy',
  'Beacon Academy Of Nevada',
  'Beacon Alp',
  'Beacon Alp Is',
  'Beacon Avenue Elementary School',
  'Beacon Charter School',
  'Beacon Classical Academy National City',
  'Beacon College Preparatory',
  'Beacon Cove Intermediate School',
  'Beacon Day Treatment Center',
  'Beacon Elementary School',
  'Beacon Heights Elementary',
  'Beacon Heights School',
  'Beacon High School',
  'Beacon Hill Academy',
  'Beacon Hill Elementary',
  'Beacon Hill International School',
  'Beacon Middle School',
  'Beacon Park',
  'Beacon Program W Central Ed. Dist.',
  'Beacon Tree Elementary School',
  'Beadle Elementary - 03',
  'Beadle Lake Elementary School',
  'Beadle Middle School',
  'Beal City Elementary School',
  'Beal City High School',
  'Beal School',
  'Beale Elementary School',
  'Beall El',
  'Beall Elementary',
  'Beallsville Elementary School',
  'Beallsville High School',
  'Beals Elem School',
  'Beals Elementary School',
  'Beamer Elementary',
  'Bean El',
  'Bean Elementary School',
  'Bean Station Elementary',
  'Bear Branch El',
  'Bear Branch Int',
  'Bear Branch J H',
  'Bear Canyon Elementary School',
  'Bear Cave Intermediate School',
  'Bear Creek Community Cs',
  'Bear Creek El',
  'Bear Creek Elementary',
  'Bear Creek Elementary School',
  'Bear Creek High',
  'Bear Creek High School',
  'Bear Creek Int',
  'Bear Creek K-8 School',
  'Bear Creek Middle School',
  'Bear Creek School',
  'Bear Elementary School',
  'Bear Exploration Center',
  'Bear Grass Charter School',
  'Bear Gulch Elementary',
  'Bear Lake Elementary School',
  'Bear Lake High School',
  'Bear Lake Middle School',
  'Bear Lakes Middle School',
  'Bear Lodge High School',
  'Bear Mountain Elementary',
  'Bear Path School',
  'Bear River',
  'Bear River Charter School',
  'Bear River High',
  'Bear River Middle',
  'Bear Tavern Elementary School',
  'Bear Valley Elementary',
  'Bear Valley International School',
  'Bear Valley Middle',
  'Beard Early Childhood',
  'Beard El',
  'Beard Elem School',
  'Beard Elementary School',
  'Bearden Elementary',
  'Bearden Elementary School',
  'Bearden High School',
  'Bearden Middle School',
  'Bearden Public School',
  'Beardslee Academy (tk-7)',
  'Beardsley Elementary',
  'Beardsley Elementary School',
  'Beardsley Junior High',
  'Beardsley School',
  'Beardstown Jr/sr High School',
  'Bearfield Primary',
  'Beartooth School',
  'Beasley El',
  'Beasley Elem Magnet Academic Ctr',
  'Beasley Elem.',
  'Beat Four Elementary School',
  'Beatrice Community Preschool',
  'Beatrice Gilmore School',
  'Beatrice H Wood Elementary',
  'Beatrice High School',
  'Beatrice Mayes Institute Charter School',
  'Beatrice Middle School',
  'Beatrice Rafferty School',
  'Beatriz G Garza Middle',
  'Beatriz Rodriguez',
  'Beattie Elementary School',
  'Beattie Middle',
  'Beatty Elementary School',
  'Beatty High School',
  'Beatty Middle School',
  'Beatty Park Elementary School',
  'Beaty Early Childhood School',
  'Beaty-warren Ms',
  'Beau Chene High School',
  'Beaubien Elem School',
  'Beauclerc Elementary School',
  'Beaufort Co Early College High',
  'Beaufort County Community College',
  'Beaufort Elem.',
  'Beaufort Elementary',
  'Beaufort High',
  'Beaufort Middle',
  'Beaufort-jasper Academy For Career Excellence',
  'Beaumont Cte High School',
  'Beaumont El Sch',
  'Beaumont Elementary',
  'Beaumont Elementary School',
  'Beaumont Elementary/magnet',
  'Beaumont Isd Early College H S',
  'Beaumont Middle School',
  'Beaumont Senior High',
  'Beaumont Usd Preschool',
  'Beauregard Elementary School',
  'Beauregard High School',
  'Beaver Acres Elementary School',
  'Beaver Area Academic Cs',
  'Beaver Area Ms',
  'Beaver Area Shs',
  'Beaver Brook Elementary',
  'Beaver County Ctc',
  'Beaver Creek El Sch',
  'Beaver Creek Elem School',
  'Beaver Creek Elementary School',
  'Beaver Creek School',
  'Beaver Dam Elementary',
  'Beaver Dam Elementary School',
  'Beaver Dam High',
  'Beaver Dam High School',
  'Beaver Dam Middle',
  'Beaver Elementary',
  'Beaver Es',
  'Beaver Falls Area Shs',
  'Beaver Falls Ms',
  'Beaver High',
  'Beaver Hs',
  'Beaver Island Community School',
  'Beaver Lake Middle School',
  'Beaver Lake Voluntary Pre-k',
  'Beaver Local Elementary School',
  'Beaver Local High School',
  'Beaver Local Middle School',
  'Beaver Meadow School',
  'Beaver Preschool',
  'Beaver Ridge Elementary School',
  'Beaver River Elementary School',
  'Beaver River Middle School',
  'Beaver River Senior High School',
  'Beaver Run School',
  'Beaver Technology Center',
  'Beaver Valley Elementary School',
  'Beaver Valley School',
  'Beaver-main El Sch',
  'Beaverbrook Elementary School',
  'Beavercreek City School Preschool',
  'Beavercreek Elementary School',
  'Beavercreek High School',
  'Beaverdale Elementary School',
  'Beaverdam Elementary',
  'Beaverhead Co High School',
  'Beaverton Elementary School',
  'Beaverton High School',
  'Beaverton Juniorsenior High School',
  'Beavertown Elementary School',
  'Bebensee El',
  'Becc',
  'Becep Center',
  'Beck Academy',
  'Beck Centennial Elem School',
  'Beckemeyer Elem School',
  'Beckendorff J H',
  'Becker Alternative Learning Program',
  'Becker El',
  'Becker Intermediate Elementary',
  'Becker Intermediate School - Snw',
  'Becker Middle',
  'Becker Primary',
  'Becker Senior High',
  'Becket Washington School',
  'Beckford Charter For Enriched Studies',
  'Beckham El',
  'Beckley Center',
  'Beckley Elementary',
  'Beckley-stratton Middle School',
  'Beckman Elementary',
  'Beckville Jr-sr High School',
  'Beckville Sunset El',
  'Beckwourth (jim) High (continuation)',
  'Becky-david Elem.',
  'Beclabito Day School',
  'Beddingfield High',
  'Beder University',
  'Bedford (gunning) Middle School',
  'Bedford Academy High School',
  'Bedford Communityfamily Learning Center',
  'Bedford County Alternative Education Center',
  'Bedford County Technical Center',
  'Bedford El Sch',
  'Bedford Elementary',
  'Bedford Elementary School',
  'Bedford Heights El',
  'Bedford High',
  'Bedford High School',
  'Bedford Hills Elementary',
  'Bedford Hills Elementary School',
  'Bedford J H',
  'Bedford Junior High School',
  'Bedford Middle',
  'Bedford Middle School',
  'Bedford Ms',
  'Bedford Park Elementary School',
  'Bedford Primary',
  'Bedford Road School',
  'Bedford School',
  'Bedford Science And Technology Center',
  'Bedford Senior High School',
  'Bedford Shs',
  'Bedford Stuyvesant Collegiate Charter School',
  'Bedford Stuyvesant New Beginnings Charter School',
  'Bedford Stuyvesant Preparatory High School',
  'Bedford Village Elementary School',
  'Bedford-north Lawrence High School',
  'Bedichek Middle',
  'Bedington Elementary School',
  'Bedminster El Sch',
  'Bedminster Township Public School',
  'Bednarcik Junior High School',
  'Bee Cave El',
  'Bee Log Elementary',
  'Bee Meadow School',
  'Beebe',
  'Beebe Early Childhood',
  'Beebe Elem School',
  'Beebe Elementary School',
  'Beebe High School',
  'Beebe Junior High School',
  'Beebe Middle School',
  'Beeber Dimner Ms',
  'Beech Avenue Elementary',
  'Beech Bottom Primary',
  'Beech Elem',
  'Beech Elementary',
  'Beech Grove Middle School',
  'Beech Grove Sr High School',
  'Beech Hill Elementary',
  'Beech Hill School',
  'Beech Springs Intermediate',
  'Beech Sr High School',
  'Beech Street Preschool',
  'Beech Street School',
  'Beech Tree Elementary',
  'Beechcroft High School',
  'Beecher Adult Education Center',
  'Beecher City Grade School',
  'Beecher City Jr-sr High School',
  'Beecher Elem School',
  'Beecher High School',
  'Beecher Hills Elementary School',
  'Beecher Junior High School',
  'Beecher Road School',
  'Beecher School',
  'Beechfield Elementary',
  'Beechgrove Elementary School',
  'Beechnau Elementary School',
  'Beechnut Academy',
  'Beechview Elementary School',
  'Beechwood Elementary',
  'Beechwood Elementary School',
  'Beechwood High School',
  'Beechwood Knoll Elementary',
  'Beechwood School',
  'Beehive School',
  'Beehive Science & Technology Academy',
  'Beekman Center',
  'Beekman Charter School',
  'Beekman School',
  'Beekmantown Elementary School',
  'Beekmantown High School',
  'Beekmantown Middle School',
  'Beeman Elementary School',
  'Beeman Memorial',
  'Beemer Elementary School',
  'Beer Middle School',
  'Beers Es',
  'Beers Street School',
  'Beeson Elementary',
  'Beethoven',
  'Beethoven Elem School',
  'Beethoven Street Elementary',
  'Beezley Springs Elementary',
  'Beggs Es',
  'Beggs Hs',
  'Beggs Ms',
  'Beginning With Children Charter School Ii',
  'Beginnings Academy',
  'Begum Rokeya University Rangpur',
  'Behavior Disorder Program Coop',
  'Behavior Support (holmes)',
  'Behavioral Education Center',
  'Behaviorally Disordered',
  'Behlau El',
  'Beidler Elem School',
  'Beiger Elementary School',
  'Beijing Foreign Studies University',
  'Beijing Forestry University',
  'Beijing Information Science and Technology University',
  'Beijing Institute of Technology',
  'Beijing International Studies University',
  'Beijing Language and Culture University',
  'Beijing Medical University',
  'Beijing New Asia University',
  'Beijing Normal University',
  'Beijing Petroleum University',
  'Beijing Polytechnic University',
  'Beijing Sport University',
  'Beijing Union University',
  'Beijing University of Aeronautics and Astronautics',
  'Beijing University of Agriculture',
  'Beijing University of Chemical Technology',
  'Beijing University of Chinese Medicine and Pharmacology',
  'Beijing University of Posts and Telecommunications',
  'Beijing University of Science and Technology',
  'Beiriger Elementary School',
  'Beirut Arab University',
  'Beitel Elementary',
  'Bel Air El',
  'Bel Air Elementary',
  'Bel Air Elementary School',
  'Bel Air H S',
  'Bel Air High',
  'Bel Air Middle',
  'Bel Aire Elementary',
  'Bel Aire Elementary School',
  'Bel Aire Park Elementary',
  'Bel Pre Elementary',
  'Bel-air Elementary',
  'Bel-aire Elementary School',
  'Belair Elem.',
  'Belair Middle School',
  'Belaire El',
  'Belaire High School',
  'Belarusian-Russian University',
  'Belarussian National Technical University',
  'Belarussian State Academy of Music',
  'Belarussian State Agrarian Technical University',
  'Belarussian State Agricultural Academy',
  'Belarussian State Economic University',
  'Belarussian State Medical University',
  'Belarussian State Pedagogical University M. Tanka',
  'Belarussian State Polytechnical Academy',
  'Belarussian State Technological University',
  'Belarussian State University',
  'Belarussian State University of Culture and Arts',
  'Belarussian State University of Informatics and Radioelectronics',
  'Belarussian State University of Transport',
  'Belc--renewal Head Start',
  'Belcher',
  'Belcher Elementary School',
  'Belchertown High',
  'Belden Elementary School',
  'Belding Elem School',
  'Belding High School',
  'Belding Middle School',
  'Belding Prek Programs',
  'Belen Blanco De Zequeira',
  'Belen High',
  'Belen Infinity High',
  'Belen Middle',
  'Belfair Elementary',
  'Belfair Montessori School',
  'Belfast Area High School',
  'Belfast Elk Garden Elementary',
  'Belfast School',
  'Belfield Elementary',
  'Belfield Elementary School',
  'Belfield High School',
  'Belfonte Bell Es (adair Cnty)',
  'Belfonte Public School',
  'Belfry 7-8',
  'Belfry Area Technology Center',
  'Belfry Elementary',
  'Belfry High School',
  'Belfry Middle School',
  'Belfry School',
  'Belgorod State Agricultural Academy',
  'Belgorod State Technical University',
  'Belgorod State University',
  'Belgrade Central School',
  'Belgrade Elem.',
  'Belgrade High School',
  'Belgrade Middle School',
  'Belgrade-brooten-elrosa Elementary',
  'Belgrade-brooten-elrosa Sec.',
  'Belgreen High School',
  'Belhaven Middle School',
  'Believers Academy',
  'Belinder Elem',
  'Belington Elementary',
  'Belington Middle School',
  'Belknap School',
  'Bell Academy',
  'Bell Avenue Elementary',
  'Bell Avenue School',
  'Bell Central School Center',
  'Bell City Elem.',
  'Bell City High',
  'Bell City High School',
  'Bell Co J J A E P',
  'Bell Co Jjaep',
  'Bell County Alternative School',
  'Bell County Area Technology Center',
  'Bell County Daep',
  'Bell County High School',
  'Bell County Jjaep',
  'Bell County Juv Det Ctr',
  'Bell Creek Academy',
  'Bell Creek Academy High School',
  'Bell Creek Intermediate School',
  'Bell El',
  'Bell Elem School',
  'Bell Elementary',
  'Bell Elementary School',
  'Bell Es',
  'Bell Field Elementary School',
  'Bell Fork Elementary',
  'Bell Gardens Elementary',
  'Bell Gardens High',
  'Bell Gardens Intermediate',
  'Bell H S',
  'Bell High School',
  'Bell Hill Academy',
  'Bell Intermediate Academy',
  'Bell Manor El',
  'Bell Middle',
  'Bell Middle School',
  'Bell Mountain Middle',
  'Bell Oaks Upper Elementary School',
  'Bell Prairie Elementary',
  'Bell Senior High',
  'Bell Top School',
  'Bell Twp El Sch',
  'Bell View School',
  "Bell's Crossing Elementary",
  "Bell's Hill El",
  'Bell-graham Elementary School',
  'Bell-herron Middle School',
  'Bella Academy Of Excellence',
  'Bella Mente Montessori Academy',
  'Bella Romero Academy Of Applied Technology',
  'Bella Vista',
  'Bella Vista Academy',
  'Bella Vista Elementary',
  'Bella Vista Elementary School',
  'Bella Vista High',
  'Bella Vista Middle',
  'Bella Vista School',
  'Bellair Elementary School',
  'Bellaire Education Center',
  'Bellaire El',
  'Bellaire El Sch',
  'Bellaire Elementary School',
  'Bellaire H S',
  'Bellaire High School',
  'Bellaire Middle School',
  'Bellaire Middlehigh School',
  'Bellaire School (the)',
  'Bellalago Charter Academy',
  'Bellamy Creek Correctional Facility',
  'Bellamy Elementary School',
  'Bellamy Middle',
  'Bellas Artes De Ponce (especializada)',
  'Bellbrook High School',
  'Bellbrook Middle School',
  'Bellbrown Career Technical Center',
  'Belle Air Elementary',
  'Belle Aire Elem School',
  'Belle Aire Intermediate',
  'Belle Alexander Elem School',
  'Belle Chasse Academy',
  'Belle Chasse High School',
  'Belle Chasse Middle School',
  'Belle Chasse Primary School',
  'Belle Creek Charter School',
  'Belle Elem.',
  'Belle Elementary School',
  'Belle Forest Community School',
  'Belle Fourche Education Connection - 09',
  'Belle Fourche High School - 01',
  'Belle Fourche Middle School - 07',
  'Belle Glade Elementary School',
  'Belle Glade Excel Charter School',
  'Belle Grove Elementary',
  'Belle Hall Elementary',
  'Belle Haven Elementary',
  'Belle Haven Prek-6 School',
  'Belle Heth Elementary',
  'Belle High',
  'Belle Isle Ms',
  'Belle Morris Elementary',
  'Belle Place Middle School',
  'Belle Plaine Elem',
  'Belle Plaine High',
  'Belle Plaine Jr/sr High School',
  'Belle Plaine Junior High',
  'Belle Plaine Middle',
  'Belle Plaine Senior High',
  'Belle Plaine Transitions/work Exper',
  'Belle Point Alternative Center',
  'Belle River Elementary School',
  'Belle Rose Middle School',
  'Belle Rose Primary School',
  'Belle Ryan Elem School',
  'Belle Sherman School',
  'Belle Shivers Middle School',
  'Belle Stone Elementary School',
  'Belle Terre Elementary School',
  'Belle Valley El Sch',
  'Belle Valley School',
  'Belle Vernon Area Hs',
  'Belle Vernon Area Ms',
  'Belle View Elementary',
  'Belleair Elementary School',
  'Belleaire Elementary School',
  'Bellefontaine Elementary School',
  'Bellefontaine High School',
  'Bellefontaine Intermediate School',
  'Bellefontaine Middle School',
  'Bellefonte Area Hs',
  'Bellefonte Area Ms',
  'Bellefonte El Sch',
  'Bellehaven Elementary',
  'Bellerive Elem.',
  'Bellerose Elementary School',
  'Belleview Elem.',
  'Belleview Elementary',
  'Belleview Elementary School',
  'Belleview High School',
  'Belleview Middle School',
  'Belleview-santos Elementary School',
  'Belleville East Elementary',
  'Belleville Elementary',
  'Belleville Henderson Central School',
  'Belleville High',
  'Belleville High School',
  'Belleville High School-east',
  'Belleville High School-west',
  'Belleville Intermediate',
  'Belleville Middle',
  'Belleville Middle School',
  'Belleville Ps10',
  'Belleville Ps3',
  'Belleville Ps4',
  'Belleville Ps5',
  'Belleville Ps7',
  'Belleville Ps8',
  'Belleville Ps9',
  'Belleville Twp Hs-night/alt Sch',
  'Bellevue Big Picture School',
  'Bellevue East Sr High School',
  'Bellevue El Sch',
  'Bellevue Elementary',
  'Bellevue Elementary School',
  'Bellevue High School',
  'Bellevue Jrsr High School',
  'Bellevue Middle',
  'Bellevue Middle School',
  'Bellevue Mission Middle School',
  'Bellevue School',
  'Bellevue Senior Elementary',
  'Bellevue University',
  'Bellevue West Sr High School',
  'Bellevue-santa Fe Charter',
  'Bellewood',
  'Bellflower Alternative Education Center',
  'Bellflower Elementary School',
  'Bellflower High',
  'Bellfort Early Childhood Center',
  'Bellingham Early Childhood Center',
  'Bellingham Elementary',
  'Bellingham Family Partnership Program',
  'Bellingham High School',
  'Bellingham Memorial School',
  'Bellingham Re-engagement Program',
  'Bellingham Technical College',
  'Bellingrath Hills Elementary School',
  'Bellingrath Middle School',
  'Bellmawr Park Elementary School',
  'Bellmont Middle School',
  'Bellmont Senior High School',
  'Bellows Falls Middle School',
  'Bellows Falls Uhsd #27',
  'Bellows Free Academy Uhsd #48',
  'Bellows Spring Elementary',
  'Bellport Middle School',
  'Bellport Senior High School',
  'Bells El',
  'Bells Elementary',
  'Bells Elementary School',
  'Bells Ferry Elementary School',
  'Bells H S',
  'Bells Mill Elementary',
  'Bells University of Technology',
  'Bellshire Elementary',
  'Bellview Elementary',
  'Bellview Elementary School',
  'Bellview Middle School',
  'Bellville Elementary School',
  'Bellville H S',
  'Bellville J H',
  'Bellwood Antis Ms',
  'Bellwood Elementary',
  'Bellwood Elementary School',
  'Bellwood-antis Hs',
  'Belmar El',
  'Belmar Elementary',
  'Belmar Elementary School',
  'Belmond-klemme Alternative School',
  'Belmond-klemme Community Jr-sr High School',
  'Belmont Academy',
  'Belmont Career Center',
  'Belmont Central Elementary',
  'Belmont Charter School',
  'Belmont College',
  'Belmont Elementary',
  'Belmont Elementary School',
  'Belmont High',
  'Belmont High School',
  'Belmont Hills El Sch',
  'Belmont Hills Elementary School',
  'Belmont Middle',
  'Belmont Middle School',
  'Belmont Preparatory High School',
  'Belmont Ridge Middle',
  'Belmont Runyon Elementary School',
  'Belmont School',
  'Belmont Senior High',
  'Belmont Station Elementary',
  'Belmont Street Community',
  'Belmont University',
  'Belmont-cragin Elem School',
  'Belmonte Saugus Middle',
  'Beloit College',
  'Beloit Early Learning',
  'Beloit Elem',
  'Beloit Jr-sr High',
  'Beloit Learning Academy',
  'Beloit Virtual School',
  'Beloved Community Charter School',
  'Belpre Elementary School',
  'Belpre High School',
  'Belridge Elementary',
  'Belshaw Elementary',
  'Belt 7-8',
  'Belt High School',
  'Belt Line El',
  'Belt School',
  'Belton Early Childhood School',
  'Belton Elementary',
  'Belton H S',
  'Belton High',
  'Belton Honea Path High',
  'Belton Middle',
  'Belton Middle School/fc',
  'Belton New Tech H S At Waskow',
  'Belton Preparatory Academy',
  'Beltsville Academy',
  'Belvedere Elementary',
  'Belvedere Elementary School',
  'Belvedere Middle',
  'Belvidere Central Middle School',
  'Belvidere Elem.',
  'Belvidere High School',
  'Belvidere North High Sch',
  'Belvidere South Middle School',
  'Belview Elementary',
  'Belview Learning Center',
  'Belville Elementary',
  'Belvoir Elementary',
  'Belwood Elementary School',
  'Belzer Middle School',
  'Bement Elementary School',
  'Bement High School',
  'Bement Middle School',
  'Bemidji Area Learning Center',
  'Bemidji Bylaw',
  'Bemidji Middle',
  'Bemidji Middle Alp',
  'Bemidji Regional Interdist. Council',
  'Bemidji Senior High',
  'Bemidji Senior Lumberjack Alc',
  'Bemidji State University',
  'Bemis Elementary',
  'Bemis Elementary School',
  'Bemis Junior High School',
  'Bemiss Elementary',
  'Bemus Point Elementary School',
  'Ben Alexander Elementary',
  'Ben Bolt Middle',
  'Ben Bolt-palito Blanco El',
  'Ben Bolt-palito Blanco H S',
  'Ben Bowen Early Childhood',
  'Ben C Rain High School',
  'Ben D Quinn Elementary',
  'Ben Davis High School',
  'Ben Davis Ninth Grade Center',
  'Ben Davis University High School',
  'Ben Eielson Jr/sr High School',
  'Ben Franklin Academy',
  'Ben Franklin Career Center',
  'Ben Franklin El Sch',
  'Ben Franklin Elementary',
  'Ben Franklin Elementary School',
  'Ben Franklin Middle School',
  'Ben Franklin Sch',
  'Ben Gamla Charter School',
  'Ben Gamla Charter School North Campus',
  'Ben Gamla Charter School South Broward',
  'Ben Gamla Preparatory Charter High School',
  'Ben Gamla Preparatory School',
  'Ben Gamla-palm Beach',
  'Ben Hazel Primary',
  'Ben Hill County Middle School',
  'Ben Hill County Primary School',
  'Ben Hill Elementary School',
  'Ben Hill Griffin Jr Elementary School',
  'Ben Hulse Elementary',
  'Ben Johnson Elementary School',
  'Ben L. Smith High School',
  'Ben Lomond Elementary',
  'Ben Lomond High',
  'Ben Milam El',
  'Ben Painter Elementary',
  'Ben Sheppard Elementary School',
  'Ben Steele Middle School',
  'Ben-Gurion University of the Negev',
  'Ben-gil Elementary School',
  'Benadir University',
  'Benavides El',
  'Benavides Heights El',
  'Benavides Secondary',
  'Benavides Steam Academy',
  'Benavidez El',
  'Benbrook El',
  'Benbrook Middle/high School',
  'Bench School',
  'Benchley/weinberger Elementary',
  'Benchmark School',
  'Bend Elementary',
  'Bend Gate Elementary School',
  'Bend International School',
  'Bend Senior High School',
  'Bendersville El Sch',
  'Bendle High School',
  'Bendle Middle School',
  'Bendlecarmanainsworth Alternative Education',
  'Bendwood School',
  'Benedict A. Cucinella School',
  'Benedictine College',
  'Benefield Elementary School',
  'Beneke El',
  'Benemerita Universidad Autónoma de Puebla',
  'Benfer El',
  'Benfield Elementary',
  'Bengal Engineering College',
  'Benge Elementary',
  'Benguet State University',
  'Benha University',
  'Benhaven Elementary',
  'Beni Suef University',
  'Benicia High',
  'Benicia Middle',
  'Benicia Velez',
  'Benigna Ines Caratini Esparra',
  'Benigno Carrion',
  'Benigno Fernandez Garcia',
  'Benignus El',
  'Benita Gonzalez Quinones',
  'Benito Cerezo Marquez',
  'Benito Juarez Middle',
  'Benito Martinez El',
  'Benito Middle School',
  'Benjamin Banneker Academy',
  'Benjamin Banneker Achievement Ctr',
  'Benjamin Banneker Career And Transition Center',
  'Benjamin Banneker Charter Public School',
  'Benjamin Banneker Elementary',
  'Benjamin Banneker Hs',
  'Benjamin Banneker Middle',
  'Benjamin Banneker Preparatory Charter School',
  'Benjamin Bosse High School',
  'Benjamin Bubb Elementary',
  'Benjamin Carson School For Science And Medicine',
  'Benjamin Chambers El Sch',
  'Benjamin Corchado Juarbe',
  'Benjamin Cosor Elementary School',
  'Benjamin D. Foulois Academy',
  'Benjamin Davis Elementary School',
  'Benjamin E Mays Magnet',
  'Benjamin Eaton Elementary School',
  'Benjamin Elem School',
  'Benjamin F. Beswick Elementary',
  'Benjamin F. Yancey Elementary',
  'Benjamin Foxen Elementary',
  'Benjamin Franklin',
  'Benjamin Franklin Charter School - Crismon',
  'Benjamin Franklin Charter School - Gilbert',
  'Benjamin Franklin Charter School - Power',
  'Benjamin Franklin Classical Charter Public School',
  'Benjamin Franklin El Sch',
  'Benjamin Franklin Elem School',
  'Benjamin Franklin Elem. Math And Science',
  'Benjamin Franklin Elementary',
  'Benjamin Franklin Elementary And Early Learning Center',
  'Benjamin Franklin Elementary Sch',
  'Benjamin Franklin Elementary School',
  'Benjamin Franklin High School',
  'Benjamin Franklin High School At Masonville Cove',
  'Benjamin Franklin Hs-finance-info',
  'Benjamin Franklin Intermediate',
  'Benjamin Franklin Junior High',
  'Benjamin Franklin K-8 Center',
  'Benjamin Franklin Middle',
  'Benjamin Franklin Middle School',
  'Benjamin Franklin Middle-east',
  'Benjamin Franklin Middle-west',
  'Benjamin Franklin Sch',
  'Benjamin Franklin School',
  'Benjamin Franklin School No. 13',
  'Benjamin Franklin Science Acad',
  'Benjamin Franklin Senior High',
  'Benjamin Friedman Middle',
  'Benjamin G Brown',
  'Benjamin Harrison',
  'Benjamin Harrison Elementary Sch',
  'Benjamin Harrison Elementary School',
  'Benjamin J Martin Elementary',
  'Benjamin J Phelps',
  'Benjamin Jepson Magnet School',
  'Benjamin Logan Elementary School',
  'Benjamin Logan High School',
  'Benjamin Logan Middle School',
  'Benjamin Middle School',
  'Benjamin N Cardozo High School',
  'Benjamin Rush El Sch',
  'Benjamin Rush Middle School',
  'Benjamin Russell High School',
  'Benjamin School',
  'Benjamin Stoddert Middle',
  'Benjamin Stoddert Middle School',
  'Benjamin Syms Middle',
  'Benjamin Tasker Middle School',
  'Benjamin Turner Middle School',
  'Benkelman Elementary School',
  'Benndale Elementary School',
  'Benner El Sch',
  'Bennett',
  'Bennett Academy',
  'Bennett Academy - Venture Site',
  'Bennett C Russell Elementary School',
  'Bennett County High School - 01',
  'Bennett County Jr. High - 06',
  'Bennett El',
  'Bennett Elem School',
  'Bennett Elementary',
  'Bennett Elementary School',
  'Bennett Es',
  'Bennett High School',
  'Bennett Middle',
  'Bennett Middle School',
  'Bennett Mountain High School',
  'Bennett Park Montessori School',
  'Bennett Preschool',
  'Bennett School',
  'Bennett Venture Academy',
  'Bennett Woods Elementary School',
  "Bennett's Mill Middle School",
  'Bennett-hemenway',
  'Bennett/kew Elementary',
  'Bennetts Valley El Sch',
  'Bennettsville Intermediate',
  'Bennettsville Primary',
  'Bennie Dover Jackson Middle School',
  'Bennie Elementary School',
  'Bennington College',
  'Bennington Elem',
  'Bennington Elementary School',
  'Bennington Es',
  'Bennington Hs',
  'Bennington Junior High/high School',
  'Bennington Middle School',
  'Bennington Secondary School',
  'Bennion Jr High',
  'Bennion School',
  'Benny C. Bills Elementary School',
  'Benoist Farms Elementary School',
  'Bens Branch El',
  'Bensalem Twp Hs',
  'Bensley Elementary',
  'Benson Area Learning Center',
  'Benson Day Treatment',
  'Benson El',
  'Benson Elementary',
  'Benson High School',
  'Benson Hill Elementary School',
  'Benson Idahosa University',
  'Benson Magnet High School',
  'Benson Middle',
  'Benson Middle School',
  'Benson Polytechnic High School',
  'Benson Primary School',
  'Benson Secondary',
  'Benson Secondary/s.e.a.r.c.h.',
  'Benson Village School',
  'Benson West Elem School',
  'Bent Elem School',
  'Bent Tree Elementary School',
  'Benteen Elementary School',
  'Bentheim Elementary School',
  'Bentley Academy Charter School',
  'Bentley Bright Beginnings',
  'Bentley College',
  'Bentley El',
  'Bentley Elem School',
  'Bentley Elementary School',
  'Bentley High School Bold Academy',
  'Bentley Junior High School',
  'Bentley Primary School',
  'Bentley Senior High School',
  'Benton Area Mshs',
  'Benton Central Jr-sr High School',
  'Benton Co Regional Vocational Cen',
  'Benton Community Middle School',
  'Benton Community Senior High School',
  'Benton Cons High School',
  'Benton County Jail',
  'Benton Elem',
  'Benton Elem.',
  'Benton Elementary',
  'Benton Elementary School',
  'Benton Grade Sch 5-8',
  'Benton Grade Sch K-4',
  'Benton Hall Academy',
  'Benton Harbor Charter School Academy',
  'Benton Harbor High School',
  'Benton Heights Elementary',
  'Benton High',
  'Benton High School',
  'Benton Junior High School',
  'Benton Lake School',
  'Benton Middle',
  'Benton Middle School',
  'Benton Stearns Education District 1',
  'Benton-stearns Ed District Ec',
  'Benton-stearns Ed. District',
  'Benton-stearns Education Center',
  'Benton/franklin Juvenile Justice Center',
  'Bentonia Gibbs School',
  'Bentonville High School',
  'Bentonville West High School',
  'Bentwood Elem',
  'Bentworth El Ctr',
  'Bentworth Ms',
  'Bentworth Shs',
  'Benue State University',
  'Benvenue Elementary',
  'Benzie Academy',
  'Benzie Central Middle School',
  'Benzie Central Sr High School',
  'Beppu University',
  'Berclair Elementary',
  'Berea College',
  'Berea Community Elementary School',
  'Berea Community High School',
  'Berea Community Middle School',
  'Berea Elementary',
  'Berea Elementary School',
  'Berea High',
  'Berea Middle',
  'Berea-midpark High School',
  'Berean Academy',
  'Berenda Elementary',
  'Berendo Middle',
  'Berenece Carlson Home Hospital',
  'Beresford Elementary',
  'Beresford Elementary - 02',
  'Beresford High School - 01',
  'Beresford Middle School - 03',
  'Berewick Elementary',
  'Berg Jones Elementary School',
  'Berg Middle School',
  'Bergen Arts And Sciences Charter School',
  'Bergen Boulevard School',
  'Bergen Co Juvenile Detention Center',
  'Bergen Community College',
  'Bergen County Academies',
  'Bergen County Technical High School - Paramus',
  'Bergen County Technical High School - Teterboro',
  'Bergen Meadow Primary School',
  'Bergen Regional School',
  'Bergen University College',
  'Bergen Valley Intermediate School',
  'Bergenfield High School',
  'Berger-vandenberg Elem School',
  'Bergische Universität Gesamthochschule Wuppertal',
  'Bergman Elementary School',
  'Bergman High School',
  'Bergman Middle School',
  'Berhampur University',
  'Berino Elementary',
  'Berkeley Arts Magnet At Whittier',
  'Berkeley City College',
  'Berkeley County Middle College High',
  'Berkeley Elementary',
  'Berkeley Glenn Elementary',
  'Berkeley Heights Elementary School',
  'Berkeley High',
  'Berkeley Intermediate',
  'Berkeley L Bunker Elementary School',
  'Berkeley Lake Elementary School',
  'Berkeley Middle',
  'Berkeley Special Education Preschool',
  'Berkeley Springs High School',
  'Berkeley Technology Academy',
  'Berkeley Terrace',
  'Berkeley Township Elementary School',
  'Berkey Sch',
  'Berklee College of Music',
  'Berkley Accelerated Middle School',
  'Berkley Community School',
  'Berkley Elementary School',
  'Berkley High School',
  'Berkley Middle School',
  'Berkley Street Elementary School',
  'Berkley/campostella Early Childhood Education Center',
  'Berkmar High School',
  'Berkmar Middle School',
  'Berkner H S',
  'Berks Ctc-east Campus',
  'Berks Ctc-west Campus',
  'Berkshire Arts And Technology Charter Public School',
  'Berkshire Community College',
  'Berkshire Elementary',
  'Berkshire Elementary School',
  'Berkshire High School',
  'Berkshire Junior-senior High School',
  'Berkshire Middle School',
  'Berlin Brothersvalley El Sch',
  'Berlin Brothersvalley Ms',
  'Berlin Brothersvalley Shs',
  'Berlin Community School',
  'Berlin Elementary School',
  'Berlin High',
  'Berlin High School',
  'Berlin Intermediate',
  'Berlin Mckinney Elementary School',
  'Berlin Memorial',
  'Berlin Middle',
  'Berlin Middle School',
  'Berlin Middle School/high School',
  'Berlin Senior High School',
  'Berlyn Elementary',
  'Bermuda College',
  'Bermuda Elem.',
  'Bermudian Springs El Sch',
  'Bermudian Springs Hs',
  'Bermudian Springs Ms',
  'Bernadine Sitts Intermediate Ctr',
  'Bernal Intermediate',
  'Bernal Middle',
  'Bernalillo Elementary',
  'Bernalillo High',
  'Bernalillo Middle',
  'Bernard Black Elementary School',
  'Bernard C. Campbell Middle',
  'Bernard Elementary',
  'Bernard J Ward Elem School',
  'Bernard L. Hughes Elementary',
  'Bernard Long Elem Sch',
  'Bernard Middle',
  'Bernard Terrace Elementary School',
  'Bernard W Flinn Middle School',
  'Bernarda Jaime J H',
  'Bernarda Robles De Hevia',
  'Bernardino Cordero Bernard',
  'Bernardo Elementary',
  'Bernardo Gonzalez Colon',
  'Bernardo Heights Middle',
  'Bernardo Huyke',
  'Bernardo Yorba Middle',
  'Bernards High School',
  'Bernardston Elementary',
  'Bernardsville Middle School',
  'Bernd Elementary School',
  'Berne Union Elementary School',
  'Berne Union High School',
  'Berne-knox-westerlo Elementary School',
  'Berne-knox-westerlo Junior-senior High School',
  'Berner Middle School',
  'Berney Elementary School',
  'Bernhard Marks Elementary',
  'Bernheim Middle School',
  'Bernice A. Ray School',
  'Bernice Ayer Middle',
  'Bernice J Causey Middle School',
  'Bernice Mathews Elementary School',
  'Bernice Mcdowell Elem School',
  'Bernice Young Elementary',
  'Bernie Elem.',
  'Bernie High',
  'Bernita Hughes Es',
  'Bernon Heights School',
  'Bernshausen El',
  'Berrendo Elementary',
  'Berrendo Middle',
  'Berrendos Middle',
  'Berrian School',
  'Berrien Academy Performance Learning Center',
  'Berrien County Juvenile Center',
  'Berrien County Truancy Academy',
  'Berrien Elementary School',
  'Berrien High School',
  'Berrien Middle School',
  'Berrien Primary School',
  'Berrien Springs Adult Education',
  'Berrien Springs Discovery Academy',
  'Berrien Springs High School',
  'Berrien Springs Middle School',
  'Berrien Springs Virtual Academy',
  'Berry Creek Elementary',
  'Berry Creek Middle School',
  'Berry El',
  'Berry Elementary',
  'Berry Elementary School',
  'Berry High School',
  'Berry Hill Elementary School',
  'Berry Intermediate School',
  'Berry Middle',
  'Berry Middle School',
  'Berry Miller J H',
  'Berry Shoals Intermediate',
  'Berryessa Union Elementary',
  'Berryhill Administrative Complex',
  'Berryhill Elementary School',
  'Berryhill Es South',
  'Berryhill Hs',
  'Berryhill Ms',
  'Berryhill School',
  'Berryton Elem',
  'Berryville Elementary School',
  'Berryville High School',
  'Berryville Intermediate Sch',
  'Berryville Middle School',
  'Bert Corona Charter',
  'Bert Corona Charter High',
  'Bert Goens Learning Center',
  'Bert H Fulton School',
  'Bert M. Lynn Middle',
  'Bert Raney Elementary School',
  'Berta Cabaza Middle',
  'Berta S Palacios El',
  'Berta Weathersbee Elementary School',
  'Berta Zalduondo',
  'Bertha Barber Elementary Sch',
  'Bertha Boschulte Junior High',
  'Bertha Elementary',
  'Bertha Godfrey Elementary School',
  'Bertha Neal School',
  'Bertha Ronzone Elementary School',
  'Bertha Secondary',
  'Berthold Elementary School',
  'Berthold High School',
  'Berthoud Elementary School',
  'Berthoud High School',
  'Bertie Early College High',
  'Bertie High',
  'Bertie Middle',
  'Bertie Stem High',
  'Bertram Caruthers Elem',
  'Bertram El',
  'Bertrand Avenue Elementary',
  'Bertrand Elementary School',
  'Bertrand F Gibbs Elementary School',
  'Bertrand High School',
  'Berwick Alternative K-8 School',
  'Berwick Area Hs',
  'Berwick Area Ms',
  'Berwick Elementary School',
  'Berwick Elementary Schools',
  'Berwick High School',
  'Berwick Junior High School',
  'Berwyn East Academy',
  'Berwyn Heights Elementary',
  'Beryl Heights Elementary',
  'Beryl Henry Upper Elem. School',
  'Berylwood Elementary',
  'Beryozova School',
  'Bes Transition Program',
  'Bess Brannen El',
  'Bess Campbell El',
  'Bess Herian Elementary',
  'Bess Maxwell Elementary',
  'Bess Race El',
  'Bess T Shepherd Elementary',
  'Bess Truman Primary Ctr.',
  'Bessemer Center For Technology',
  'Bessemer City Central Elem',
  'Bessemer City High',
  'Bessemer City High School',
  'Bessemer City Middle',
  'Bessemer City Middle School',
  'Bessemer City Primary',
  'Bessemer Elementary',
  'Bessemer Elementary School',
  'Besser Elementary School',
  'Bessey Creek Elementary School',
  'Bessie Allen Middle',
  'Bessie B Baker School',
  'Bessie Buker Elementary',
  'Bessie Coleman Middle',
  'Bessie E. Owens Intermediate',
  'Bessie E. Owens Primary',
  'Bessie L. Baggett Elementary',
  'Bessie Weller Elementary',
  'Best Academy East At Olson',
  'Best Academy K-8 At Penn',
  'Best El',
  'Besteiro Middle',
  'Bester Elementary',
  'Beta',
  'Beta Academy',
  'Betances Early Reading Lab Magnet School',
  'Betances Stem Magnet School',
  'Bethalto East Primary School',
  'Bethany Based School',
  'Bethany Charter School',
  'Bethany College',
  'Bethany Community Middle School',
  'Bethany Community School',
  'Bethany Crises Shelter',
  'Bethany El',
  'Bethany Elementary',
  'Bethany Elementary School',
  'Bethany Hs',
  'Bethany Ms',
  'Bethel College (KS)',
  'Bethel El Sch',
  'Bethel Elem',
  'Bethel Elementary',
  'Bethel Elementary Learning Academy',
  'Bethel Elementary School',
  'Bethel Es',
  'Bethel Grade School',
  'Bethel Grove Elementary',
  'Bethel High',
  'Bethel High School',
  'Bethel Hill Charter',
  'Bethel Hs',
  'Bethel Manor Elementary',
  'Bethel Memorial El Sch',
  'Bethel Middle',
  'Bethel Middle School',
  'Bethel Ms',
  'Bethel Park Hs',
  'Bethel Regional High School',
  'Bethel Springs El Sch',
  'Bethel Springs Elementary',
  'Bethel Tate High School',
  'Bethel University',
  'Bethel Youth Facility',
  'Bethel-hanberry Elementary',
  'Bethel-tate Middle School',
  'Bethesda Elementary',
  'Bethesda Elementary School',
  'Bethesda-chevy Chase High',
  'Bethke El',
  'Bethke Elementary School',
  'Bethlehem Avts',
  'Bethlehem Central Middle School',
  'Bethlehem Central Senior High School',
  'Bethlehem Elementary',
  'Bethlehem Elementary School',
  'Bethlehem High School',
  'Bethlehem University',
  'Bethlehem-center El Sch',
  'Bethlehem-center Ms',
  'Bethlehem-center Shs',
  'Bethpage Elementary',
  'Bethpage Senior High School',
  'Bethune Academy',
  'Bethune Adult Education',
  'Bethune Elementary',
  'Bethune Elementary School',
  'Bethune K-8',
  'Bethune Mary M Elementary School',
  'Bethune Mary Mcleod Sch',
  'Bethune Middle School',
  'Bethune Public Schools',
  'Bethune-bowman Elementary',
  'Bethune-bowman Middle/high',
  'Bethware Elementary',
  'Bethzaida Velazquez Superior',
  'Betsey B Winslow',
  'Betsie Valley School',
  'Betsy A Rhodes Elementary',
  'Betsy Layne Elementary School',
  'Betsy Layne High School',
  'Betsy Ross Arts Magnet School',
  'Betsy Ross Elem School',
  'Betsy Ross Elementary',
  'Bette Perot El',
  'Bettendorf High School',
  'Bettendorf Middle School',
  'Bettes Elementary School',
  'Bettie E Woolfolk Middle School',
  'Bettie Weaver Elementary',
  'Bettis Preparatory Leadership Academy',
  'Betts El',
  'Betty Fairfax High School',
  'Betty Harwell Middle',
  'Betty Jane Community Learning Center',
  'Betty Kiefer Elementary School',
  'Betty Maddalena Early Learning Center',
  'Betty Mcelmon Elementary',
  'Betty Plasencia Elementary',
  'Betty Rosado De Vega',
  'Betty Shabazz Internl Charter Sch',
  'Bettye Mae Jack Middle School',
  'Bettye Myers Middle',
  'Betz Elementary',
  'Betz Elementary School',
  'Beulah Academy Of Science',
  'Beulah Elementary',
  'Beulah Elementary School',
  'Beulah Heights Elementary School',
  'Beulah High School',
  'Beulah Middle School',
  'Beulah Park Elem School',
  'Beulah Payne Elementary',
  'Beulah Ralph Elementary',
  'Beulaville Elementary',
  'Beutler Middle School',
  'Beveridge Elementary School',
  'Beveridge Magnet Middle School',
  'Beverley Manor Elementary',
  'Beverley Manor Middle',
  'Beverly Cheatham El',
  'Beverly City School',
  'Beverly Cleary School',
  'Beverly El',
  'Beverly Elementary',
  'Beverly Elementary School',
  'Beverly Farms Elementary',
  'Beverly Gardens Elementary School',
  'Beverly High',
  'Beverly Hills Elementary',
  'Beverly Hills High',
  'Beverly Hills Int',
  'Beverly Hills Ms',
  'Beverly J Martin Elementary School',
  'Beverly Manor Elementary School',
  'Beverly Park Elem At Glendale',
  'Beverly Shores Elementary School',
  'Beverly Vista Elementary',
  'Beverly Woods Elementary',
  'Beverly-center Elementary School',
  'Beverlye Magnet School',
  'Bevier Elem.',
  'Bevier High',
  'Bevill State Community College',
  'Bevins Elementary School',
  'Bevis Elementary School',
  'Bexar Co J J A E P',
  'Bexar County Academy',
  'Bexar County Juvenile Justice Academy (bcjja)',
  'Bexar County Lrn Ctr',
  'Bexley Elementary School',
  'Bexley High School',
  'Bexley Middle School',
  'Beyer Elem School',
  'Beykent University',
  'Bezalel Academy of Art and Design',
  'Bezmialem Vakif University',
  'Bfa Elementary/middle School',
  'Bfa High School - Fairfax',
  'Bglig-shirley Rodriguez-remeneski Cs',
  'Bh Colony Alternative High School - 19',
  'Bhagwant University',
  'Bharath Institue Of Higher Education & Research',
  'Bharathiar University',
  'Bharathidasan University',
  'Bharati Vidyapeeth University',
  'Bhavnagar University',
  'Bhupendra Narayan Mandal University',
  'Bi-county Sp Ed Preschool Program',
  'BiTS - Business and Information Technology School gGmbH',
  'Bibb County Career Academy',
  'Bibb County High School',
  'Bicentennial Elem School',
  'Bicentennial Elementary School',
  'Bicentennial North School',
  'Bicentennial South School',
  'Bick Primary Elementary School',
  'Bickel Elementary School',
  'Bickerdyke Elem',
  'Bickleton Elementary & High Schl',
  'Bicol University',
  'Bicycle Path Kindergarten-pre K Center',
  'Biddeford High School',
  'Biddeford Intermediate School',
  'Biddeford Middle School',
  'Biddeford Primary School',
  'Biddeford Regional Ctr Of Tech',
  'Biddle School',
  'Bidhan Chandra Agricultural University',
  'Bidwell Continuation High',
  'Bidwell Elementary',
  'Bidwell Junior High',
  'Bidwell Point High (continuation)',
  'Bielefield School',
  'Bienville Educational Center',
  'Bienville High School',
  'Bierbaum Elem.',
  'Bifrost School of Business',
  'Big A Elementary School',
  'Big Bay De Noc School',
  'Big Bear Elementary',
  'Big Bear High',
  'Big Bear Middle',
  'Big Beaver El Sch',
  'Big Bend Community College',
  'Big Bend Elementary',
  'Big Bend H S',
  'Big Bend Technical College',
  'Big Burning School',
  'Big Country El',
  'Big Creek Elementary',
  'Big Creek Elementary School',
  'Big Cross Street School',
  'Big Cypress Elementary School',
  'Big Cypress Wilderness Institute',
  'Big Elm Elementary School',
  'Big Flats School',
  'Big Foot High',
  'Big Hollow Elem School',
  'Big Hollow Middle School',
  'Big Hollow Primary School',
  "Big Horn Basin Children's Center",
  'Big Horn Elementary',
  'Big Horn High School',
  'Big Horn Middle School',
  'Big Island Elementary',
  'Big Jackson Public School',
  'Big Lagoon Elementary',
  'Big Lake Elementary',
  'Big Lake Elementary School',
  'Big Lake Middle School',
  'Big Lake Senior High',
  'Big Muddy Elementary',
  'Big Otter Elementary School',
  'Big Park Community School',
  'Big Pasture Es',
  'Big Pasture Hs',
  'Big Picture College And Career Academy',
  'Big Picture Educational Academy',
  'Big Picture School',
  'Big Picture Twin Cities',
  'Big Pine Academy',
  'Big Pine Elementary',
  'Big Pine High',
  'Big Piney Elementary',
  'Big Piney High School',
  'Big Piney Middle School',
  'Big Rapids High School',
  'Big Rapids Middle School',
  'Big Rapids Virtual School',
  'Big Ridge Elementary',
  'Big Rock Elementary School',
  'Big Sandy 7-8',
  'Big Sandy Community and Technical College',
  'Big Sandy El',
  'Big Sandy Elementary',
  'Big Sandy H S',
  'Big Sandy High School',
  'Big Sandy J H',
  'Big Sandy School',
  'Big Shanty Elementary School',
  'Big Sky Elementary',
  'Big Sky High School',
  'Big Sky School',
  'Big Spring Aep',
  'Big Spring H S',
  'Big Spring Hs',
  'Big Spring Int',
  'Big Spring J H',
  'Big Spring Lake Kindergarten School',
  'Big Spring Ms',
  'Big Springs Charter School',
  'Big Springs El',
  'Big Springs Elementary',
  'Big Stone City Elementary - 01',
  'Big Stone City Middle School - 03',
  'Big Stone School',
  'Big Sur Charter',
  'Big Thompson Elementary School',
  'Big Timber 7-8',
  'Big Timber School',
  'Big Tree Elementary School',
  'Big Valley Elementary',
  'Big Valley Jr. Sr. High',
  'Big Walnut Elementary School',
  'Big Walnut High School',
  'Big Walnut Intermediate School',
  'Big Walnut Middle School',
  'Big Water High',
  'Big Water School',
  'Big White Elementary - 08',
  'Big Woods Elementary',
  'Bigelow High School',
  'Bigelow Hill Elementary School',
  'Bigelow Middle',
  'Bigfork 7-8',
  'Bigfork Elementary',
  'Bigfork High School',
  'Bigfork Secondary',
  'Biggersville Elementary',
  'Biggersville High School',
  'Biggs Early Childhood Education Ctr',
  'Biggs Elementary',
  'Biggs High',
  'Biggs Secondary Community Day',
  'Biglerville El Sch',
  'Biglerville Hs',
  'Bijou Community',
  'Biju Pattnaik University of Technology',
  'Bila Cerkva State Agrarian University',
  'Bilecik Seyh Edebali University',
  'Bilecik University',
  'Bilingual Bicultural School (the)',
  'Bilingual Center',
  'Bilkent University',
  'Bill Arp Elementary School',
  'Bill Brown El',
  'Bill Burden El',
  'Bill Childress El',
  'Bill Duncan Opportunity Center',
  'Bill E. Young Jr. Middle',
  'Bill Hasse El',
  'Bill Hefner Elementary',
  'Bill J Elliott El',
  'Bill L. Williams Elementary',
  'Bill Logue Detention Center',
  'Bill Logue Juvenile Detention Center',
  'Bill M. Manes High',
  'Bill Metz Elementary School',
  'Bill Reed Middle School',
  'Bill Sybert School',
  'Bill Wallace Ec Ctr',
  'Bill Y Tomiyasu Elementary School',
  'Billerica Memorial High School',
  'Billie Stevenson El',
  'Billings Elem.',
  'Billings Es',
  'Billings Hs',
  'Billings Sr High School',
  'Billings Sr. High',
  'Billings West High School',
  'Billingsley High School',
  'Billingsport Elementary School',
  'Billingsville Elementary',
  'Billy And Rosemary Vassiliadis Elementary School',
  'Billy Baines Middle',
  'Billy Earl Dade Middle',
  'Billy Lane Lauffer Middle School',
  'Billy Mitchell Elementary',
  'Biloxi High School',
  'Biloxi Junior High',
  'Biloxi Upper Elementary',
  'Bilquist Elementary School',
  'Biltmore Continuation Hs',
  'Biltmore Elementary School',
  'Biltmore Preparatory Academy',
  'Binary University College of Managemant & Entrepreneurship',
  'Bindura University of Science Education',
  'Binet School',
  'Binford Elementary School',
  'Binford Middle',
  'Bing Elementary School',
  'Bing Wong Elementary',
  'Binger-oney Es',
  'Binger-oney Hs',
  'Bingham Academy',
  'Bingham Elem.',
  'Bingham Farms Elementary School',
  'Bingham High',
  'Bingham Memorial School',
  'Bingham Middle',
  'Bingham University',
  'Binghamton High School',
  'Bingman Pk',
  'Bingol University',
  'Binh Duong University',
  'Binkley Elementary Charter',
  'Binks Forest Elementary School',
  'Binns Elementary School',
  'Binus University',
  'Bio Med Academy',
  'Bio-med Science Academy Stem School',
  'Biola University',
  'Biola-pershing Elementary',
  'Biotech@richmond Heights 9-12 High School',
  'Biotechnology High School',
  'Biotechnology Research And Zoological Studies Hs At The Fa',
  'Birch Creek School',
  'Birch El',
  'Birch Elementary School',
  'Birch Grove Community School',
  'Birch Grove Intermediate',
  'Birch Grove Primary',
  'Birch Grove Primary School',
  'Birch Grove School For The Arts',
  'Birch Hill Elementary School',
  'Birch Lake Elementary',
  'Birch Lane Elementary',
  'Birch Lane Elementary School',
  'Birch Meadow',
  'Birch River Elementary School',
  'Birch Run High School',
  'Birch Run Post Secondary',
  'Birch Run Progressive High School',
  'Birch School',
  'Birch Street Elementary School',
  'Birch Tree Elem.',
  'Bircham International University',
  'Birchcrest Elementary School',
  'Birches Elementary School',
  'Birchland Park',
  'Birchtree Charter School',
  'Birchview Elementary',
  'Birchview School',
  'Birchwood Abc Elementary',
  'Birchwood Blue Hills Charter School',
  'Birchwood Elementary',
  'Birchwood Elementary School',
  'Birchwood High',
  'Birchwood Intermediate School',
  'Birchwood Middle',
  'Birchwood Middle School',
  'Birchwood Public Montessori',
  'Birchwood School',
  'Bird Elementary School',
  'Bird Middle',
  'Bird Rock Elementary',
  'Bird Street Elementary',
  'Birdie Alexander El',
  'Birdielee V. Bright Elementary',
  'Birdneck Elementary',
  'Birdsboro El Ctr',
  'Birdville El',
  'Birdville H S',
  'Birdwell El',
  'Birjand University',
  'Birjand University of Medical Sciences',
  'Birkbeck College University of London',
  'Birkes El',
  'Birla Institute of Technology Ranchi',
  'Birla Institute of Technology and Science',
  'Birmingham City University',
  'Birmingham Community Charter High',
  'Birmingham Covington School',
  'Birmingham Elementary School',
  'Birmingham Falls Elementary School',
  'Birmingham-Southern College',
  'Birnamwood Elementary',
  'Birney',
  'Birney Elementary',
  'Birney Elementary School',
  'Birney School',
  'Birney Tech Academy',
  'Birnham Woods El',
  'Birsa Agricultural University',
  'Birsk State Pedagogical Institute',
  'Birth To 2 Preschool',
  'Birth To 3 Contracts',
  'Birth To Age 2',
  'Birth To Three',
  'Birth To Three Development Center',
  'Birth To Two Ecse',
  'Biruni University',
  'Birzeit University',
  'Bisbee High School',
  'Bisbee Middle School',
  'Biscayne Elementary School',
  'Biscayne Gardens Elementary',
  'Biscayne High School',
  'Bishkek Humanities University',
  'Bishop El',
  'Bishop Elementary',
  'Bishop Elementary School',
  'Bishop H S',
  'Bishop Hall Charter School',
  'Bishop Independent Study',
  'Bishop Pri',
  'Bishop Public School',
  'Bishop School Early Learning Center',
  'Bishop State Community College',
  'Bishop Union Elementary Community Day',
  'Bishop Union Elementary Community Day Ii',
  'Bishop Union High',
  'Bishop Woods Architecture And Design Magnet School',
  "Bishop's Peak Elementary",
  "Bishop's University",
  'Bishopville Primary',
  'Bismarck Career Acad',
  'Bismarck Elementary School',
  'Bismarck High School',
  'Bismarck Middle School',
  'Bismarck R-v Elem.',
  'Bismarck R-v High',
  'Bismarck Spec Ed Unit',
  'Bismarck Tech Center',
  'Bismarck-henning Elem School',
  'Bismarck-henning Jr High School',
  'Bison Elementary - 02',
  'Bison High School - 01',
  'Bison Jr. High - 04',
  'Bissell 7-8',
  'Bissell Hall',
  'Bissell School',
  'Bissett Elem.',
  'Bissonet Plaza Elementary School',
  'Bitely (arlene) Elementary',
  'Bitlis Eren University',
  'Bitney Prep High',
  'Bitterroot School',
  'Bittersweet Elementary School',
  'Bitterwater-tully Elementary',
  'Bivins El',
  'Bixby Elementary',
  'Bixby Hs',
  'Bixby Ms',
  'Bixby North Es',
  'Biyaagozhoo Center',
  'Biysk State Padagogical University after V.M. Shukshin',
  'Black Bob Elem',
  'Black Butte Elementary',
  'Black Butte Elementary School',
  'Black Butte High School',
  'Black Butte Junior High',
  'Black Canyon High School',
  'Black Canyon School',
  'Black Creek Elementary',
  'Black Diamond Elementary',
  'Black Diamond High (continuation)',
  'Black Diamond Middle',
  'Black Earth Elementary',
  'Black El',
  'Black Elementary School',
  'Black Elk Elementary School',
  'Black Forest Hills Elementary School',
  'Black Fox Elementary',
  'Black Fox Elementary School',
  'Black Hawk Area Special Ed Ctr',
  'Black Hawk College',
  'Black Hawk Elem School',
  'Black Hawk Elem.',
  'Black Hawk Elementary',
  'Black Hawk Elementary - 03',
  'Black Hawk Elementary School',
  'Black Hawk High',
  'Black Hawk Middle',
  'Black Hills Academy At Canyon Hills - 08',
  'Black Hills Academy At Canyon Hills High School - 07',
  'Black Hills Education Connections - 06',
  'Black Hills State University',
  'Black Lake Elementary',
  'Black Magnet Elem School',
  'Black Mesa Community School',
  'Black Middle',
  'Black Mountain Elementary',
  'Black Mountain Elementary School',
  'Black Mountain Middle',
  'Black Mountain Primary',
  'Black Oak Elementary',
  'Black Ridge School',
  'Black River Correctional Center',
  'Black River Education Center Elementary School',
  'Black River Education Center Middle School',
  'Black River Falls High',
  'Black River Falls Middle',
  'Black River High School',
  'Black River Middle School',
  'Black River Public School Elementary',
  'Black River Public School Middlehigh',
  'Black River School',
  'Black River Technical College',
  'Black River Usd #39',
  'Black Rock Alternative/continuation',
  'Black Rock Elementary',
  'Black Rock School',
  'Black Street Early Childhood Center',
  'Black Traditional Magnet Elem',
  'Black Water Middle',
  "Black's Mill Elementary School",
  'Blackberry Elementary School',
  'Blackbird Elementary School',
  'Blackburn Alternative Program',
  'Blackburn Education Center',
  'Blackburn El',
  'Blackburn Elem.',
  'Blackburn Elementary',
  'Blackburn Elementary School',
  'Blackburn Middle School',
  'Blackduck Elementary',
  'Blackduck Secondary',
  'Blackfeet Community College',
  'Blackfoot Charter Community',
  'Blackfoot Heritage Sixth Grade',
  'Blackfoot High School',
  'Blackford Elementary',
  'Blackford High School',
  'Blackford Junior High School',
  'Blackham School',
  'Blackhawk Elem School',
  'Blackhawk Elementary School',
  'Blackhawk Hs',
  'Blackhawk Intermediate Sch',
  'Blackhawk Middle School',
  'Blackhawk Primary Center',
  'Blackhawk Technical College',
  'Blackhurst Elementary',
  'Blackland Prairie El',
  'Blacklick Elementary School',
  'Blacklick Valley El Ctr',
  'Blacklick Valley Jshs',
  'Blackman Elementary School',
  'Blackman High School',
  'Blackman Middle School',
  'Blackmon Road Middle School',
  'Blackridge School',
  'Blackrock School',
  'Blacksburg Elementary',
  'Blacksburg High',
  'Blacksburg Middle',
  'Blacksburg Primary',
  'Blackshear El',
  'Blackshear Elementary School',
  'Blackshear Head Start',
  'Blackshere Elementary School',
  'Blackstone',
  'Blackstone Academy Charter',
  'Blackstone Elem School',
  'Blackstone Millville Rhs',
  'Blackstone Primary',
  'Blackstone Valley',
  'Blackstone Valley Prep',
  'Blackstone Valley Prep E. 2',
  'Blackstone Valley Prep Element',
  'Blackstone Valley Prep High',
  'Blackstone Valley Prep Mid2',
  'Blackville-hilda High',
  'Blackville-hilda Middle',
  'Blackwater Community School',
  'Blackwater Elem.',
  'Blackwell Elementary',
  'Blackwell Elementary School',
  'Blackwell Es',
  'Blackwell Hs',
  'Blackwell Institute',
  'Blackwell Ms',
  'Blackwell School',
  'Blackwood Elementary School',
  'Bladen Community College',
  'Bladen Early College',
  'Bladen Lakes Primary',
  'Bladenboro Middle',
  'Bladenboro Primary',
  'Bladensburg Elementary',
  'Bladensburg High',
  'Blades Elem.',
  'Blades Elementary School',
  'Blain El Sch',
  'Blaine Elem School',
  'Blaine Elementary School',
  'Blaine High School',
  'Blaine Home Connections',
  'Blaine James G Sch',
  'Blaine Middle School',
  'Blaine Primary School',
  'Blaine Re-engagement',
  'Blair Arbor Park Intermediate School',
  'Blair Early Childhood Center',
  'Blair El',
  'Blair Elementary',
  'Blair Elementary School',
  'Blair Es',
  'Blair High',
  'Blair High School',
  'Blair Hs',
  'Blair Middle',
  'Blair Mill El Sch',
  'Blair Moody Elementary School',
  'Blair North Elementary School',
  'Blair Oaks Elem.',
  'Blair Oaks High',
  'Blair Oaks Middle School',
  'Blair Pointe Upper Elementary',
  'Blair South Elementary School',
  'Blair-taylor Elementary',
  'Blair-taylor Middle/high',
  'Blairstown Elementary School',
  'Blairsville El Sch',
  'Blairsville Ms',
  'Blairsville Shs',
  'Blairwood Elementary School',
  'Blake Elementary',
  'Blake High School',
  'Blake Manor El',
  'Blake Primary School',
  'Blakemore Middle',
  'Blakeney Elementary',
  'Blaker-kinser Junior High',
  'Blakesburg Elementary',
  'Blakewood Elementary',
  'Blalack Middle',
  'Blanca E Sanchez',
  'Blanca Malaret',
  'Blanchard Elem.',
  'Blanchard Elementary',
  'Blanchard Elementary School',
  'Blanchard Es',
  'Blanchard Hs',
  'Blanchard Intermediate Es',
  'Blanchard Memorial School',
  'Blanchard Middle',
  'Blanchard Ms',
  'Blanche Charles Elementary',
  'Blanche Dodd Int',
  'Blanche E Fuqua Elementary School',
  'Blanche Ely High School',
  'Blanche H. Daughtrey Elementary',
  'Blanche Kelso Bruce Academy Bowman Site',
  'Blanche Kelso Bruce Academy Depaul',
  'Blanche Kelso Bruce Academy St Antoine',
  'Blanche Kelso Bruce Academy West Selden Site',
  'Blanche Pope Elementary School',
  'Blanche Reynolds Elementary',
  'Blanche School',
  'Blanche Sims Elementary School',
  'Blanche Sprentz Elementary',
  'Blanche Thomas Hs',
  'Blanchester High School',
  'Blanchester Intermediate School',
  'Blanchester Middle School',
  'Blanchette El',
  'Blanco El',
  'Blanco Elementary',
  'Blanco H S',
  'Blanco Middle',
  'Blanco Vista El',
  'Bland County Elementary',
  'Bland County High',
  'Bland El',
  'Bland H S',
  'Bland Middle',
  'Blandford Elementary',
  'Blandford Elementary School',
  'Blandford Nature Center',
  'Blanding School',
  'Blandy Hills Elementary School',
  'Blaney Elementary',
  'Blankenburg Rudolph Sch',
  'Blanket School',
  'Blankner K-8',
  'Blanton El',
  'Blanton Elementary School',
  'Blaschke/sheldon El',
  'Blasdell Elementary School',
  'Blatchley Middle School',
  'Blattman El',
  'Blazier El',
  'Blc School Readiness Plus',
  'Bldg 21 Allentown',
  'Bleckley County Elementary School',
  'Bleckley County High School',
  'Bleckley County Primary School',
  'Bleckley County Success Academy',
  'Bleckley Middle School',
  'Bledsoe County High School',
  'Bledsoe County Middle School',
  'Bledsoe El',
  'Blekinge Institute of Technology',
  'Blended Academy',
  'Blended Learning Academies Credit Recovery High School',
  'Blendon Middle School',
  'Blenheim Elementary/middle',
  'Blenman Elementary School',
  'Blennerhassett Elementary School',
  'Blennerhassett Middle School',
  'Blesch Intermediate School',
  'Blessing El',
  'Blevins Elem.',
  'Blevins Elementary School',
  'Blevins High School',
  'Blevins Middle School',
  'Bleyl Middle',
  'Blind Brook High School',
  'Blind Brook-rye Middle School',
  'Blind Elementary School (fsdb)',
  'Blind High School (fsdb)',
  'Blind Pk-8 School (fsdb)',
  'Blind Region 2',
  'Blind South',
  'Blinn College',
  'Bliss El',
  'Bliss School',
  'Blissfield Elementary',
  'Blissfield High School',
  'Blissfield Middle School',
  'Blix Elementary',
  'Block High School',
  'Block House Creek El',
  'Block Island School',
  'Blocker Middle',
  'Blodgett Elementary School',
  'Bloom Carroll Intermediate School',
  'Bloom Carroll Primary School',
  'Bloom Elementary',
  'Bloom High School',
  'Bloom Trail High School',
  'Bloom-carroll High School',
  'Bloom-carroll Middle School',
  'Bloom-vernon Elementary School',
  'Bloomburg El',
  'Bloomburg H S',
  'Bloomer Elementary',
  'Bloomer Elementary School',
  'Bloomer High',
  'Bloomer Middle',
  'Bloomfield Early Childhood Center',
  'Bloomfield Elem.',
  'Bloomfield Elementary School',
  'Bloomfield High',
  'Bloomfield High School',
  'Bloomfield Hills High School',
  'Bloomfield Hills Middle School',
  'Bloomfield Jr-sr High School',
  'Bloomfield Middle',
  'Bloomfield Middle School',
  'Bloomfield School',
  'Bloomfield Tech',
  'Blooming Grove El',
  'Blooming Grove H S',
  'Blooming Grove Jh',
  'Blooming Prairie Elementary',
  'Blooming Prairie Secondary',
  'Bloomingdale Avenue School',
  'Bloomingdale Elementary School',
  'Bloomingdale High School',
  'Bloomingdale Middle And High School',
  'Bloomingdale School',
  'Bloomington Career And College Acad',
  'Bloomington Ecse',
  'Bloomington El',
  'Bloomington Graduation School',
  'Bloomington H S',
  'Bloomington High',
  'Bloomington High School',
  'Bloomington High School North',
  'Bloomington High School South',
  'Bloomington Hills School',
  'Bloomington J H',
  'Bloomington Jr High School',
  'Bloomington School',
  'Bloomington Shared Time',
  'Bloomington Transition Center',
  'Bloomsburg Area Hs',
  'Bloomsburg Area Ms',
  'Bloomsburg University of Pennsylvania',
  'Bloomsbury School',
  'Bloomsdale Elem.',
  'Blossburg El Sch',
  'Blosser Lane Elementary',
  'Blossom El',
  'Blossom Gulch Elementary School',
  'Blossom Hill Elementary',
  'Blossom Valley Elementary',
  'Blossomland Learning Center',
  'Blossomwood Elementary School',
  'Blount County Career Technical Center',
  'Blount County Learning Center',
  'Blount Elementary School',
  'Blountstown Elementary School',
  'Blountstown High School',
  'Blountstown Middle School',
  'Blountsville Elementary School',
  'Blountville Elementary',
  'Blountville Middle School',
  'Blowing Rock Elementary',
  'Blue Academy',
  'Blue Angels Elementary School',
  'Blue Ash Elementary',
  'Blue Ball El Sch',
  'Blue Bell El Sch',
  'Blue Creek Elementary',
  'Blue Creek School',
  'Blue Diamond Elementary School',
  'Blue Earth Area Early Childhood Spe',
  'Blue Earth Area Elementary',
  'Blue Earth Area Middle',
  'Blue Earth Area Secondary',
  'Blue Elementary School',
  'Blue Eye Elem.',
  'Blue Eye High',
  'Blue Eye Middle',
  'Blue Grass Elementary',
  'Blue Grass Elementary School',
  'Blue Haze El',
  'Blue Heron',
  'Blue Heron Elementary',
  'Blue Heron Elementary School',
  'Blue Heron Middle School',
  'Blue Hill Consolidated School',
  'Blue Hill Elementary School',
  'Blue Hill High School',
  'Blue Hills Elem.',
  'Blue Hills Elementary',
  'Blue Hills Regional Vocational Technical',
  'Blue Lake Elementary School',
  'Blue Lake Union Elementary',
  'Blue Lakes Elementary School',
  'Blue Lick Elementary',
  'Blue Mound Learning Center',
  'Blue Mountain Community College',
  'Blue Mountain El Cressona Sch',
  'Blue Mountain El East Sch',
  'Blue Mountain El West Sch',
  'Blue Mountain Elementary',
  'Blue Mountain High School',
  'Blue Mountain Hs',
  'Blue Mountain Middle School',
  'Blue Mountain Ms',
  'Blue Mountain Usd #21',
  'Blue Mountains Hotel School',
  'Blue Nile University',
  'Blue Oak Academy',
  'Blue Oak Charter',
  'Blue Oak Elementary',
  'Blue Oaks Elementary',
  'Blue Peak High',
  'Blue Point Elementary School',
  'Blue Point School',
  'Blue Ridge',
  'Blue Ridge Academy',
  'Blue Ridge Community College',
  'Blue Ridge Community and Technical College',
  "Blue Ridge Crossroads Governor's Academy For Technical Educa",
  'Blue Ridge Early College',
  'Blue Ridge El',
  'Blue Ridge El Sch',
  'Blue Ridge Elem',
  'Blue Ridge Elem.',
  'Blue Ridge Elementary',
  'Blue Ridge Elementary School',
  "Blue Ridge Governor's School",
  'Blue Ridge H S',
  'Blue Ridge High',
  'Blue Ridge High School',
  'Blue Ridge High School Online',
  'Blue Ridge Hs',
  'Blue Ridge Intermediate-jr Hs',
  'Blue Ridge Jr High School',
  'Blue Ridge Juvenile Detention Home',
  'Blue Ridge Middle',
  'Blue Ridge Middle School',
  'Blue Ridge Ms',
  'Blue Ridge Primary Elementary Sch',
  'Blue Ridge School',
  'Blue River Career Ctr',
  'Blue River Elementary',
  'Blue River Valley Elementary Sch',
  'Blue River Valley Jr-sr High Sch',
  'Blue Sky Colony - 04',
  'Blue Springs Elementary School',
  'Blue Springs High',
  'Blue Springs South High',
  'Blue Star Elementary School',
  'Blue Valley High',
  'Blue Valley Middle',
  'Blue Valley North High',
  'Blue Valley Northwest High',
  'Blue Valley Southwest High School',
  'Blue Valley West High',
  'Blue Water Middle College Academy',
  'Blueberry Hill',
  'Bluebonnet El',
  'Bluebonnet Trail El',
  'Bluefield Alternative Learning Center',
  'Bluefield High School',
  'Bluefield Intermediate School',
  'Bluefield Middle School',
  'Bluegrass Challenge Academy',
  'Bluegrass Community and Technical College',
  'Bluegrass Discovery Academy High',
  'Bluegrass Discovery Academy Middle',
  'Bluegrass Middle School',
  'Bluejacket Es',
  'Bluejacket Hs',
  'Bluejacket Ms',
  'Bluejacket-flint',
  'Bluemont Elementary School',
  'Blueprint High School',
  'Blueprint Middle School',
  'Bluesky Charter School',
  'Bluestem Elementary School',
  'Bluestem Jr/sr High',
  'Bluestone Elementary',
  'Bluestone Elementary School',
  'Bluestone High',
  'Bluestone Middle',
  'Bluewater Elementary',
  'Bluewater Elementary School',
  'Bluewell Elementary School',
  'Bluff City Elementary',
  'Bluff City High School',
  'Bluff City Middle School',
  'Bluff Creek Elementary',
  'Bluff Dale El',
  'Bluff Elementary School',
  'Bluff Park Elementary School',
  'Bluff Ridge School',
  'Bluff School',
  'Bluff View Elementary',
  'Bluff View Junior High',
  'Bluff View Middle',
  'Bluffdale Middle School #1',
  'Bluffdale School',
  'Bluffdale School #2',
  'Bluffs Elementary School',
  'Bluffs High School',
  'Bluffs Junior High School',
  'Bluffs Middle School',
  'Bluffsview Elementary School',
  'Bluffton College',
  'Bluffton Elementary',
  'Bluffton Elementary School',
  'Bluffton High',
  'Bluffton High School',
  'Bluffton Middle',
  'Bluffton Middle School',
  'Bluffton-harrison Elementary Sch',
  'Bluffton-harrison Middle School',
  'Bluffview Elem School',
  'Bluffview Elementary',
  'Bluffview Montessori',
  'Bluford Drew Jemison Stem Academy West',
  'Bluford Elementary',
  'Bluford Grade School',
  'Blum El',
  'Blum Isd',
  'Blum Middle',
  'Blumengard Colony Elementary - 06',
  'Blumfield Elementary School',
  'Blunt Elementary - 03',
  'Blythe Academy',
  'Blythe District Preschool',
  'Blythe Elementary',
  'Blythe Elementary School',
  'Blythe Middle',
  'Blythe Park Elem School',
  'Blythe Street Elementary',
  'Blythe-bower Elementary',
  'Blythedale School',
  'Blytheville Elementary School',
  'Blytheville High School-a New Tech School',
  'Blytheville Middle School',
  'Blytheville Primary School',
  'Blythewood High',
  'Blythewood Middle',
  'Bm Hanson Elementary School',
  'Bo Peep School',
  'Boals El',
  'Board Of Child Care',
  'Boardman Center Intermediate School',
  'Boardman Glenwood Junior High',
  'Boardman High School',
  'Boaz Elementary School',
  'Boaz High School',
  'Boaz Intermediate School',
  'Boaz Middle School',
  'Bob And Betty Nelson El',
  'Bob Callies Elementary School',
  'Bob Courtway Middle School',
  'Bob Folsom Elementary School',
  'Bob Graham Education Center',
  'Bob Hardingshawmut Elementary',
  'Bob Holcomb Elementary',
  'Bob Hope El',
  'Bob Hope School',
  'Bob Hope School - El Campus',
  'Bob Jones High School',
  'Bob Jones University',
  'Bob Mathis Elementary School',
  'Bob Miller Middle School',
  'Bob Richards Regional Youth Detention Center',
  'Bob Sikes Elementary School',
  'Bobbie Smith Elementary',
  'Bobby Duke Middle',
  'Bobby F. Grigsby Intermediate School',
  'Bobby Ray Memorial',
  'Bobby Shaw Middle',
  "Bobby's Run Elementary School",
  'Bobcat Virtual Academy',
  'Bobier Elementary',
  'Bobtown El Sch',
  'Boca Ciega High School',
  'Boca Raton Charter School',
  'Boca Raton Community High School',
  'Boca Raton Community Middle School',
  'Boca Raton Elementary School',
  'Boca Raton High Adult Education Center',
  'Boca Raton Middle Adult Education Center',
  'Bocconi University',
  'Bode Middle',
  'Bodega Bay Elementary',
  'Bodine Es',
  'Bodine William W Hs',
  'Bodkin Elementary',
  'Bodo Regional University',
  'Boeckman Creek Primary School',
  'Boerne - Samuel V Champion H S',
  'Boerne Alter Sch',
  'Boerne H S',
  'Boerne Middle North',
  'Boerne Middle South',
  'Boerum Hill School For International Studies (the)',
  'Boevers Es',
  'Bogalusa High School',
  'Bogan Elementary',
  'Bogan High School',
  'Bogazici University',
  'Boggess El',
  'Boggy Creek Elementary School',
  'Boght Hills School',
  'Bogle Junior High School',
  'Bogor Agricultural University',
  'Bogota Jr./sr. High School',
  'Bogue Chitto Elementary School',
  'Bogue Chitto School',
  'Bogue Sound Elementary',
  'Bogus Elementary',
  'Bohai University',
  'Bohannon Middle',
  'Bohemia Elementary School',
  'Bohemia Manor High',
  'Bohemia Manor Middle',
  'Bohumil Shimek Elementary School',
  'Boiling Springs Elementary',
  'Boiling Springs High',
  'Boiling Springs High Ninth Grade Campus',
  'Boiling Springs Hs',
  'Boiling Springs Intermediate',
  'Boiling Springs Middle',
  'Bois Blanc Pines School',
  "Bois D'arc Elem.",
  'Boise City Es',
  'Boise City Hs',
  'Boise City Jhs',
  'Boise Senior High School',
  'Boise State University',
  'Boise-eliot Elementary School',
  'Boistfort Elem',
  'Bok Academy',
  'Bokenkamp',
  'Bokoshe Es',
  'Bokoshe Hs',
  'Bokoshe Jhs',
  'Bold Alc',
  'Bold Senior High',
  'Bold Step Infant Care',
  'Bold-bird Island Elementary',
  'Boldt El',
  'Boles El',
  'Boles H S',
  'Boles J H',
  'Boles Middle',
  'Boley Elementary School',
  'Bolich Middle School',
  'Bolin El',
  'Bolinas-stinson Elementary',
  'Boling H S',
  'Bolingbrook High School',
  'Bolivar Elementary',
  'Bolivar High',
  'Bolivar Intermediate Sch.',
  'Bolivar Middle',
  'Bolivar Middle School',
  'Bolivar Pagan',
  'Bolivar Primary',
  'Bolivar Road Elementary School',
  'Bolivar-richburg Elementary School',
  'Bolivar-richburg Junior-senior High School',
  'Bolivar-richburg Pre-k Program',
  'Bolivia Elementary',
  'Bollinger Canyon Elementary',
  'Bollman Bridge Elementary',
  'Bolsa Grande High',
  'Bolsa Knolls Middle',
  'Bolt Academy',
  'Bolton',
  'Bolton Academy',
  'Bolton Center School',
  'Bolton Central School',
  'Bolton Crossing Elementary',
  'Bolton Elementary',
  'Bolton High',
  'Bolton High School',
  'Bolton Primary School',
  'Bolton-edwards Elem./middle School',
  'Boltz Middle School',
  'Bon Air El Sch',
  'Bon Air Elementary',
  'Bon Air Elementary School',
  'Bon Air Middle School',
  'Bon De Croft Elementary',
  'Bon Homme High School - 01',
  'Bon Homme Middle School - 02',
  'Bon Homme Pk - 08',
  'Bon Lin Elementary School',
  'Bon Lin Middle School',
  'Bon Meade El Sch',
  'Bon View Elementary',
  'Bonaire Elementary School',
  'Bonaire Middle School',
  'Bonanza Elementary School',
  'Bonanza High School',
  'Bonanza Junior/senior High School',
  'Boncl Elem.',
  'Bond Cty Comm Unit 2 High School',
  'Bond Elem School',
  'Bond Elementary School',
  'Bond Hill Academy Elementary School',
  'Bond Mill Elementary',
  'Bond Primary',
  'Bond University',
  'Bonduel Elementary',
  'Bonduel High',
  'Bonduel Middle',
  'Bondurant - Farrar Middle School',
  'Bondurant Elementary',
  'Bondurant Middle School',
  'Bondurant-farrar High School',
  'Bondy Int',
  'Bonella A. St. Ville Elementary School',
  'Bonfield Grade School',
  'Bonham Academy',
  'Bonham Ees',
  'Bonham El',
  'Bonham H S',
  'Bonham Middle',
  'Bonham Pre-k School',
  'Bonifacio Sanchez Jimenez (gandara)',
  'Bonifay Elementary School',
  'Bonifay K-8 School',
  'Bonifay Middle School',
  'Bonillas Elementary Basic Curriculum Magnet School',
  'Bonita Canyon Elementary',
  'Bonita Elementary',
  'Bonita Elementary School',
  'Bonita High',
  'Bonita Springs Charter School',
  'Bonita Springs Elementary School',
  'Bonita Springs High School',
  'Bonita Springs Middle Center For The Arts',
  'Bonita Street Elementary',
  'Bonita Vista Middle',
  'Bonita Vista Senior High',
  'Bonlee School',
  'Bonnabel Magnet Academy High School',
  'Bonne Ecole Elementary School',
  'Bonner 7-8',
  'Bonner El',
  'Bonner Elementary',
  'Bonner School',
  'Bonner Springs Elementary',
  'Bonner Springs High',
  'Bonners Ferry High School',
  'Bonnette J H',
  'Bonneville Academy',
  'Bonneville Elementary',
  'Bonneville Elementary School',
  'Bonneville High',
  'Bonneville High School',
  'Bonneville Jr High',
  'Bonneville Online Elementary',
  'Bonneville Online School',
  'Bonneville School',
  'Bonney Lake Elementary',
  'Bonney Lake High School',
  'Bonnie Brae',
  'Bonnie Brae Elementary',
  'Bonnie Branch Middle',
  'Bonnie Brennan School',
  'Bonnie Grimes Elem. School',
  'Bonnie Holland El',
  'Bonnie L Garcia El',
  'Bonnie Mcbeth Learning Ctr',
  'Bonnieville Elementary School',
  'Bonny Doon Elementary',
  'Bonny Eagle High School',
  'Bonny Eagle Middle School',
  'Bonny Kate Elementary',
  'Bonny Slope Elementary School',
  'Bonny View Elementary',
  'Bonsack Elementary',
  'Bonsall Elementary',
  'Bonsall High',
  'Bonsall West Elementary',
  'Book Cliff School',
  'Bookcliff Middle School',
  'Booker Arts Magnet Elem. Sch.',
  'Booker Elementary',
  'Booker High School',
  'Booker Jh/h S',
  'Booker Middle School',
  'Booker T Washington Alt Sch',
  'Booker T Washington El',
  'Booker T Washington Elementary School',
  'Booker T Washington High',
  'Booker T Washington Intern. Studies',
  'Booker T Washington Magnet High School',
  'Booker T Washington Middle School',
  'Booker T Washington Spva Magnet',
  'Booker T. Washington',
  'Booker T. Washington Academy',
  'Booker T. Washington Elementary',
  'Booker T. Washington Elementary School',
  'Booker T. Washington High School',
  'Booker T. Washington Hs',
  'Booker T. Washington Middle',
  'Booker T. Washington New Technology High School',
  'Booker T. Washington Senior High',
  'Bookman Road Elementary',
  'Booksin Elementary',
  'Boomer-ferguson Elementary',
  'Boone Career-technical Center',
  'Boone Central Elem-albion',
  'Boone Central High School',
  'Boone Central Middle School',
  'Boone County Alternative Center',
  'Boone County Area Technology Center',
  'Boone County Center',
  'Boone County High School',
  'Boone El',
  'Boone Elem School',
  'Boone Elem.',
  'Boone Elementary',
  'Boone Grove Elementary School',
  'Boone Grove High School',
  'Boone Grove Middle School',
  'Boone High School',
  'Boone Meadow',
  'Boone Middle School',
  'Boone Park Elementary School',
  'Boone Trail Elem.',
  'Boone Trail Elementary',
  'Boones Creek Elementary',
  'Boones Creek Middle School',
  'Boones Ferry Primary School',
  'Boones Mill Elementary',
  'Booneville Elementary School',
  'Booneville High School',
  'Booneville Jr High School',
  'Boonsboro Elementary',
  'Boonsboro High',
  'Boonsboro Middle',
  'Boonslick School',
  'Boonslick Technical Educ. Ctr.',
  'Boonton High School',
  'Boonville Correctional Center',
  'Boonville Elementary',
  'Boonville Elementary School',
  'Boonville High',
  'Boonville High School',
  'Boonville Middle School',
  'Boonville Treatment Center',
  'Booth Elementary School',
  'Booth Free School',
  'Booth Hill School',
  'Booth Middle School',
  'Booth Tarkington Elem School',
  'Booth-fickett Math/science Magnet School',
  'Boothbay Region Elem School',
  'Boothbay Region High School',
  'Bootheel School',
  'Boothville-venice Elementary School',
  'Boothwyn El Sch',
  'Borah Elementary School',
  'Borah Senior High School',
  'Borchardt El',
  'Bordeaux Elementary School',
  'Borden County School',
  'Bordentown Regional High School',
  'Bordentown Regional Middle School',
  'Border Area Learning Center',
  'Border Star Montessori',
  'Boreal Sun Charter School',
  'Borel Middle',
  'Borger H S',
  'Borger Int',
  'Borger Middle',
  'Boring Middle School',
  'Borland Elementary School',
  'Borland Manor El Sch',
  'Borman El',
  'Borman Elementary School',
  'Boron Junior-senior High',
  'Boronda Elementary',
  'Boronda Meadows',
  'Borrego Pass',
  'Borrego Springs Elementary',
  'Borrego Springs High',
  'Borrego Springs Middle',
  'Borton Primary Magnet School',
  'Bosaso College',
  'Boscawen Elementary School',
  'Boscobel Elementary',
  'Boscobel High',
  'Boscobel Junior High',
  'Bose Elementary',
  'Bosque Farms Elementary',
  'Bosqueville El',
  'Bosqueville H S',
  'Bosqueville Middle',
  'Bossier Elementary School',
  'Bossier High School',
  'Bossier Parish Community College',
  'Bossier Ps For Technology & Innovative Learning',
  'Bost University',
  'Bostian Elementary',
  'Bostic Traditional Magnet Elem',
  'Boston Adult Academy',
  'Boston Arts Academy',
  'Boston Collaborative High School',
  'Boston College',
  'Boston Collegiate Charter School',
  'Boston Community Leadership Academy',
  'Boston Day And Evening Academy Charter School',
  'Boston Elementary School',
  'Boston Graduate School of Psychoanalysis',
  'Boston Green Academy Horace Mann Charter School',
  'Boston Harbor Elementary',
  'Boston International High School',
  'Boston K-8 School',
  'Boston Latin',
  'Boston Latin Academy',
  'Boston Preparatory Charter Public School',
  'Boston Renaissance Charter Public School',
  'Boston School',
  'Boston Teachers Union School',
  'Boston University',
  'Boston Valley Elementary School',
  'Bostonia Language Academy',
  'Bostrom Alternative Center',
  'Boswell Elem.',
  'Boswell Elementary School',
  'Boswell Es',
  'Boswell H S',
  'Boswell Hs',
  'Boswell Ms',
  'Bosworth Elem.',
  'Bosworth High',
  'Botelle Elementary School',
  'Botetourt Elementary',
  'Botetourt Technical Education Center',
  'Bothell High School',
  'Botho University',
  'Bothwell Middle School',
  'Botkins Elementary School',
  'Botkins High School',
  'Botsford Elementary School',
  'Botswana Accountancy College',
  'Botswana College of Agriculture',
  'Botswana International University of Science & Technology',
  'Bottenfield Elem School',
  'Bottineau Elementary School',
  'Bottineau Junior-senior High School',
  'Botts Elementary School',
  'Bouchet Elem Math & Science Acad',
  'Boude Storey Middle',
  'Boulan Park Middle School',
  'Boulder 7-8',
  'Boulder Bluff Elementary',
  'Boulder City High School',
  'Boulder Colony School - 06',
  'Boulder Community School/integrated Studies',
  'Boulder Creek Elementary',
  'Boulder Creek Elementary School',
  'Boulder Creek High School',
  'Boulder Elementary School',
  'Boulder Explore',
  'Boulder High School',
  'Boulder Hill Elem School',
  'Boulder Oaks Elementary',
  'Boulder Prep Charter High School',
  'Boulder Ridge Elementary',
  'Boulder School',
  'Boulder Universal',
  'Boulevard Elementary School',
  'Boulevard Heights',
  'Boulevard Heights Elementary',
  'Boulevard School',
  'Boulter Middle',
  'Boulton School',
  'Boulware Springs Charter',
  'Bound Brook High School',
  'Boundary County Middle School',
  'Boundary St. Elementary',
  'Bountiful High',
  'Bountiful Jr High',
  'Bountiful School',
  'Bourbon Central Elementary School',
  'Bourbon County High School',
  'Bourbon County Middle School',
  'Bourbon County Preschool Headstart',
  'Bourbon Elementary School',
  'Bourbon High School',
  'Bourbon Middle School',
  'Bourbonnais Upper Grade Center',
  'Bourg Elementary School',
  'Bourgas Free University',
  'Bourgas University "Prof. Assen Zlatarov"',
  'Bourne High School',
  'Bourne Middle School',
  'Bournedale Elementary School',
  'Bournemouth University',
  'Bouse Elementary School',
  'Boutwell',
  'Boutwell School',
  'Bovill Elementary School',
  'Bovina El',
  'Bovina Elementary School',
  'Bovina H S',
  'Bovina Middle',
  'Bow Elementary School',
  'Bow Elementarymiddle School',
  'Bow High School',
  'Bow Lake Elementary',
  'Bow Memorial School',
  'Bow Valley College',
  'Bowbells Elementary School',
  'Bowbells High School',
  'Bowden Academy',
  'Bowdish Middle School',
  'Bowditch Middle',
  'Bowdle Elementary - 02',
  'Bowdle High School - 01',
  'Bowdle Jr. High - 03',
  'Bowdoin Central School',
  'Bowdoin College',
  'Bowdoinham Community School',
  'Bowdon Elementary School',
  'Bowdon High School',
  'Bowdon Middle School',
  'Bowe',
  'Bowen',
  'Bowen Elementary',
  'Bowen Elementary School',
  'Bowen High School',
  'Bowen University',
  "Bowen's Corner Elementary",
  'Bower Elem School',
  'Bower Hill El Sch',
  'Bowerman Elem.',
  'Bowers Elementary',
  'Bowers School',
  'Bowers-whitley Career Center',
  'Bowers/whitley Adult & Community Center',
  'Bowie',
  'Bowie 6th Grade Campus',
  'Bowie El',
  'Bowie Elementary School',
  'Bowie H S',
  'Bowie High',
  'Bowie High School',
  'Bowie Int',
  'Bowie J H',
  'Bowie Middle',
  'Bowie State University',
  'Bowlegs Es',
  'Bowlegs Hs',
  'Bowler Elementary',
  'Bowler High',
  'Bowles Elem.',
  'Bowlesburg Elem School',
  'Bowling Green Elem.',
  'Bowling Green Elementary',
  'Bowling Green Elementary School',
  'Bowling Green High',
  'Bowling Green High School',
  'Bowling Green Junior High',
  'Bowling Green Middle',
  'Bowling Green Middle School',
  'Bowling Green Preschool',
  'Bowling Green School',
  'Bowling Green State University',
  'Bowling Green Technical - Glasgow',
  'Bowling Green Technical College',
  'Bowman',
  'Bowman (jereann) High (continuation)',
  'Bowman Ashe/doolin K-8 Academy',
  'Bowman Charter',
  'Bowman Co Elementary School',
  'Bowman Co High School',
  'Bowman Elementary',
  'Bowman Middle',
  'Bowman Primary School',
  'Bowman Woods Elementary School',
  'Bowmar Avenue School',
  'Bowne-munro Elementary School',
  'Bowring Public School',
  'Bowsher High School',
  'Box Elder 7-8',
  'Box Elder High',
  'Box Elder High School',
  'Box Elder Middle',
  'Box Elder School',
  'Box Springs Elementary',
  'Boxelder Elementary',
  'Boyce Elementary',
  'Boyce Ms',
  'Boyceville High',
  'Boyceville Middle',
  'Boyd Co Career & Tech Educ Ctr Voc Sch',
  'Boyd Co Early Childhood Learning Ctr Nor',
  'Boyd Co Early Childhood Learning Ctr Sou',
  'Boyd County Elementary (butte)',
  'Boyd County Elementary (lynch)',
  'Boyd County High School',
  'Boyd County High School (lynch)',
  'Boyd County High School (spencer)',
  'Boyd County Middle School',
  'Boyd County Middle School (lynch)',
  'Boyd County Middle School (spencer)',
  'Boyd E Smith Elementary School',
  'Boyd El',
  'Boyd Elem School',
  'Boyd Elem.',
  'Boyd Elementary',
  'Boyd Elementary School',
  'Boyd H S',
  'Boyd H. Anderson High School',
  'Boyd Int',
  'Boyd Middle',
  'Boyd W Arthurs Middle School',
  'Boyden',
  'Boyden-hull Elementary School',
  'Boyden-hull High School',
  'Boyds Creek Elementary School',
  'Boyer Valley Elementary School',
  'Boyer Valley Middle/high School',
  'Boyertown Area Ms-east',
  'Boyertown Area Ms-west',
  'Boyertown Area Shs',
  'Boyertown El Sch',
  'Boyet Junior High School',
  'Boyette Springs Elementary School',
  'Boyle County Day Treatment',
  'Boyle County High School',
  'Boyle County Middle School',
  'Boyle Heights Continuation',
  'Boyle Heights Stem High',
  'Boyle Road Elementary School',
  'Boylston Elementary',
  'Boyne City Education Center',
  'Boyne City Elementary School',
  'Boyne City High School',
  'Boyne City Middle School',
  'Boyne Falls Public School',
  'Boynton Beach Community Adult',
  'Boynton Beach Community High',
  'Boynton Elementary School',
  'Boynton High',
  'Boynton Middle School',
  'Boys & Girls Haven',
  'Boys Academic Leadership Academy',
  'Boys And Girls High School',
  'Boys Latin Of Philadelphia Cs',
  'Boys Preparatory Charter School Of New York',
  'Boys Ranch H S',
  'Boys Republic High',
  'Boys Totem Town',
  'Boys Town',
  'Boys Town Intervention/assessment Interim Program',
  'Boystown',
  'Boze',
  'Bozeman High School',
  'Bozman Int',
  'Bozok University',
  'Bozymowski Center',
  'Brac University',
  'Braceville Elem School',
  'Bracher Elementary',
  'Bracken County High School',
  'Bracken County Middle School',
  'Brackenridge H S',
  'Brackett',
  'Brackett H S',
  'Brackett J H',
  'Bradbury Heights Elementary',
  'Braddock Drive Elementary',
  'Braddock Elementary',
  'Braddock Middle',
  'Braden River Elementary School',
  'Braden River High School',
  'Braden River Middle School',
  'Brader (henry M.) Elementary School',
  'Bradfield Center - Ault Academy',
  'Bradfield El',
  'Bradford Academy',
  'Bradford Area Hs',
  'Bradford Central School',
  'Bradford El',
  'Bradford Elementary',
  'Bradford Elementary School',
  'Bradford Grade School',
  'Bradford Hgts El Sch',
  'Bradford High',
  'Bradford High School',
  'Bradford Intervention Center',
  'Bradford Jr High School',
  'Bradford K8 North',
  'Bradford K8 South',
  'Bradford Middle School',
  'Bradford Preparatory School',
  'Bradford Virtual Franchise',
  'Bradford Virtual Instruction Program',
  'Bradford Virtual Instruction Program (district Provided)',
  'Bradford Woodbridge Fundamental Elementary',
  'Bradford Woods El Sch',
  'Bradford-union Technical Center',
  'Bradie Shrum Elementary',
  'Bradley Academy - An Arts Integrated School',
  'Bradley Academy Of Excellence',
  'Bradley Beach Elementary School',
  'Bradley Central High School',
  'Bradley Central Middle School',
  'Bradley County Virtual School',
  'Bradley Creek Elementary',
  'Bradley East Elem School',
  'Bradley El',
  'Bradley Elem',
  'Bradley Elementary',
  'Bradley Elementary School',
  'Bradley Es',
  'Bradley Gardens Primary School',
  'Bradley High School',
  'Bradley Hills Elementary',
  'Bradley International School',
  'Bradley Middle',
  'Bradley School',
  'Bradley Technology High',
  'Bradley University',
  'Bradley West Elem School',
  'Bradley-bourbonnais C High School',
  'Bradleyville Elem.',
  'Bradleyville High',
  'Bradoaks Elementary',
  'Bradshaw Elementary School',
  'Bradshaw Mountain High School',
  'Bradshaw Mountain Middle School',
  'Bradshaw Mountain Online Academy',
  'Bradwell Comm Arts & Sci Elem Sch',
  'Bradwell Institute',
  'Brady El',
  'Brady Elementary School',
  'Brady Exploration School',
  'Brady H S',
  'Brady High School',
  'Brady Middle',
  'Braeburn El',
  'Braeburn Learning Center',
  'Braeburn School',
  'Braelinn Elementary School',
  'Braeside Elem School',
  'Bragg Elementary',
  'Bragg Elementary School',
  'Bragg Middle School',
  'Bragg Street Academy',
  'Braggs Es',
  'Braggs Hs',
  'Braham Area Secondary',
  'Braham Elementary',
  'Brainard Elementary',
  'Brainerd Early Childhood Sp. Ed.',
  'Brainerd High School',
  'Brainerd Senior High',
  'Braintree High',
  'Braintree School',
  'Braly Elementary',
  'Brambleton Middle School',
  'Bramlett Elementary School',
  'Bramlette El',
  'Bramlette Steam Academy',
  'Branch Area Careers Center',
  'Branch Brook Elementary School',
  'Branch Brook School',
  'Branch Elementary',
  'Branch Elementary School',
  'Branch Line School',
  'Branch Out Transitional Services',
  'Branchburg Central Middle School',
  'Branchville Elementary School',
  'Branchville High',
  'Branciforte Middle',
  'Brandeis Elementary',
  'Brandeis H S',
  'Brandeis University',
  'Brandenburg El',
  'Brandenburg High School',
  'Brandenburg Primary School',
  'Brandenburgische Technische Universität Cottbus',
  'Brandon Academy Of Art And Science',
  'Brandon Adult Transition Program',
  'Brandon Adult/community Center',
  'Brandon El',
  'Brandon Elementary',
  'Brandon Elementary - 03',
  'Brandon Elementary School',
  'Brandon Epic 3 Center',
  'Brandon Fletcher Intermediate School',
  'Brandon High School',
  'Brandon Middle',
  'Brandon Middle School',
  'Brandon University',
  'Brandon Valley High School - 01',
  'Brandon Valley Intermediate - 07',
  'Brandon Valley Middle School - 02',
  'Brandon-evansville High School',
  'Brandon-evansville Middle School',
  'Brandyine Site',
  'Brandywine Elementary',
  'Brandywine Elementary School',
  'Brandywine Heights El Sch',
  'Brandywine Heights Hs',
  'Brandywine Heights Intrmd/ms',
  'Brandywine High School',
  'Brandywine Innovation Academy',
  'Brandywine Middle School',
  'Brandywine Senior High School',
  'Brandywine Springs School',
  'Brandywine-wallace El Sch',
  'Branford Elementary School',
  'Branford High School',
  'Branham High',
  'Branscomb Elementary',
  'Bransford El',
  'Bransford Elementary',
  'Bransom El',
  'Branson High',
  'Branson Jr. High',
  'Branson School',
  'Branson School Online',
  'Brantley County High School',
  'Brantley County Middle School',
  'Brantley Elementary School',
  'Brantley High School',
  'Brantly Es',
  'Brantner Elementary School',
  'Brantner Lane Elementary School',
  'Brantwood Elementary School',
  'Brashier Middle College',
  'Brashier Middle College Charter',
  'Brass Castle School',
  'Brass City Charter School',
  'Brass Community School',
  'Brassfield Elementary',
  'Bratislava International School of Liberal Arts',
  'Bratsk State Technical University',
  'Bratt Elementary School',
  'Brattleboro Area Middle School',
  'Brattleboro Senior Uhsd #6',
  'Brauchle El',
  'Braulio Ayala Perez (mambiche Blanco)',
  'Braulio Dueno Colon',
  'Braun Educational Center',
  'Braun Station El',
  'Brave Center Academy',
  'Bravo Lake High',
  'Brawley Middle',
  'Brawley Union High',
  'Braxton County High School',
  'Braxton County Middle School',
  'Braxton Craven Middle',
  'Bray El',
  'Bray-doyle Es',
  'Bray-doyle Hs',
  'Braymer Elem.',
  'Braymer High',
  'Brayton',
  'Brayton Elementary School',
  'Brazoria Co Alter Ed Ctr',
  'Brazoria Co J J A E P',
  'Brazoria Co Juvenile Detention',
  'Brazos Bend El',
  'Brazos Co Juvenile Detention Center',
  'Brazos County Jjaep',
  'Brazos El',
  'Brazos H S',
  'Brazos Middle',
  'Brazos River Charter School',
  'Brazos School For Inquiry And Creativity Bryan/col',
  'Brazos School For Inquiry And Creativity Pinemont',
  'Brazos School For Inquiry And Creativity Tidwell',
  'Brazosport H S',
  'Brazoswood H S',
  'Brea Canyon High (continuation)',
  'Brea Country Hills Elementary',
  'Brea Junior High',
  'Brea-olinda High',
  'Bread Springs Day School',
  'Break-o-day Elementary School',
  'Breakfast Point Academy',
  'Breaking Barriers Alternative Ed/henry Co',
  'Breakthrough Magnet School North',
  'Breakthrough Magnet School South',
  'Breakthrough Montessori Pcs',
  'Breathitt County Area Technology Center',
  'Breathitt County High School',
  'Breathitt Regional Juvenile Det Ctr',
  'Breathitt Residential Treatment Center',
  'Breaux Bridge Elementary School',
  'Breaux Bridge High School',
  'Breaux Bridge Junior High School',
  'Breaux Bridge Primary School',
  'Brecht Sch',
  'Breckenridge Area Learning Program',
  'Breckenridge Elem.',
  'Breckenridge Elementary',
  'Breckenridge Elementary School',
  'Breckenridge H S',
  'Breckenridge High',
  'Breckenridge High School',
  'Breckenridge J H',
  'Breckenridge Middle',
  'Breckenridge Senior High',
  'Breckinridge County Area Technology Ctr',
  'Breckinridge County High School',
  'Breckinridge County Middle School',
  'Breckinridge Elementary',
  'Breckinridge Metropolitan High',
  'Breckinridge Middle',
  'Breckinridge-franklin Elementary',
  'Brecknock El Sch',
  'Brecksville-broadview Heights High School',
  'Brecksville-broadview Heights Middle School',
  'Breed Middle School',
  'Breed Street Elementary',
  'Breeden El',
  'Breen Elementary',
  'Breese Elem School',
  'Breeze Hill Elementary',
  'Breezewood El Sch',
  'Bregy F Amedee Sch',
  'Brehms Lane Public Charter School',
  'Breithaupt Career And Technical Center',
  'Breitling Elementary School',
  'Bremen 4th & 5th Grade Academy',
  'Bremen Elementary School',
  'Bremen Elementary/middle School',
  'Bremen High School',
  'Bremen Middle School',
  'Bremen Senior High School',
  'Bremerton High School',
  'Bremerton Home Link Program',
  'Bremond El',
  'Bremond H S',
  'Bremond Middle',
  'Bren Mar Park Elementary',
  'Brenau University',
  'Brenda Scott Academy For Theatre Arts',
  'Brendel Elementary School',
  'Brener Elementary',
  'Brenham Early Childhood Learning Center',
  'Brenham El',
  'Brenham H S',
  'Brenham J H',
  'Brenham Middle',
  'Brenkwitz High',
  'Brennan H S',
  'Brennan Rogers School',
  'Brennan Woods Elem.',
  'Brennemann Elem School',
  'Brennen Elementary',
  'Brennen School (the)',
  'Brent Elementary School',
  'Brent Es',
  'Brentano Elem Math & Science Acad',
  'Brentfield El',
  'Brentmoor Elementary School',
  'Brentsville District High',
  'Brentwood Academy',
  'Brentwood Colony Elementary - 05',
  'Brentwood Early Childhd Center',
  'Brentwood El',
  'Brentwood Elem School',
  'Brentwood Elementary',
  'Brentwood Elementary School',
  'Brentwood High',
  'Brentwood High School',
  'Brentwood Middle',
  'Brentwood Middle School',
  'Brentwood Ms',
  'Brentwood Residential Center',
  'Brentwood Science',
  'Brentwood Shs',
  'Brescia College',
  'Brest State Technical University',
  'Brest State University',
  'Bret Harte Elem School',
  'Bret Harte Elementary',
  'Bret Harte Elementary School',
  'Bret Harte Middle',
  'Bret Harte Preparatory Middle',
  'Bret Harte Union High',
  'Bret R. Tarver',
  'Bret Tarver Education Complex',
  'Brethren High School',
  'Brethren Middle School',
  'Breton Downs School',
  'Bretton Woods Elementary School',
  'Brevard Academy',
  'Brevard Achievement Center',
  'Brevard County Jail Complex',
  'Brevard County Juvenile Detention Center',
  'Brevard Elementary',
  'Brevard Group Treatment Home',
  'Brevard High',
  'Brevard Middle',
  'Brevard Virtual Eschool',
  'Brevard Virtual Franchise',
  'Brevard Virtual Instruction (course Offerings)',
  'Brevard Virtual Instruction Program',
  'Brevig Mission School',
  'Brewbaker Intermediate School',
  'Brewbaker Middle School',
  'Brewbaker Primary School',
  'Brewbaker Technology Magnet High School',
  'Brewer Academy',
  'Brewer Community School',
  'Brewer Elementary School',
  'Brewer Elementarymiddle School',
  'Brewer H S',
  'Brewer High School',
  'Brewer Island Elementary',
  'Brewer Middle',
  'Brewerton Elementary School',
  'Brewster Alternative School',
  'Brewster El',
  'Brewster Elem',
  'Brewster Elementary School',
  'Brewster High',
  'Brewster High School',
  'Brewster Middle School',
  'Brewster Pierce School',
  'Brewster Technical College',
  'Brewton Elementary School',
  'Brewton Middle School',
  'Brexgata University Academy',
  'Brian & Teri Cram Middle School',
  'Briar Cliff College',
  'Briar Glen Elem School',
  'Briar Middle School',
  'Briar Vista Elementary School',
  'Briar Woods High',
  'Briarcliff Elem.',
  'Briarcliff Elementary',
  'Briarcliff High School',
  'Briarcliff Middle School',
  'Briarcrest Elementary',
  'Briargate El',
  'Briargate Elem School',
  'Briargate Preschool',
  'Briarglen Es',
  'Briargrove El',
  'Briarhill Middle',
  'Briarlake Elementary School',
  'Briarmeadow Charter',
  'Briarwood Elem',
  'Briarwood Elementary',
  'Briarwood Elementary School',
  'Briarwood Es',
  'Briarwood School',
  'Briceville Elementary',
  'Brick Church: A Lead Public School',
  'Brick Elementary School',
  'Brick Mill Elementary School',
  'Brick Township High School',
  'Brick Township Memorial High School',
  'Bricker Elementary School',
  'Brickett Elementary',
  'Brickey Mccloud Elementary',
  'Brickfields Asia College',
  'Bricolage Academy',
  'Bridesburg Sch',
  'Bridge',
  'Bridge Academy',
  'Bridge Academy East',
  'Bridge Academy West',
  'Bridge Alternative High School',
  'Bridge Assoc',
  'Bridge Boston Charter School',
  'Bridge Charter Academy',
  'Bridge City El',
  'Bridge City H S',
  'Bridge City Int',
  'Bridge City Middle',
  'Bridge Creek Early Childhood',
  'Bridge Creek Elementary',
  'Bridge Creek Es',
  'Bridge Creek Hs',
  'Bridge Creek Ies',
  'Bridge Creek Ms',
  'Bridge Elem School',
  'Bridge Elementary School',
  'Bridge Gate Community School',
  'Bridge Point El',
  'Bridge Street',
  'Bridge Street Elementary',
  'Bridge Street Middle School',
  'Bridge Transition Program',
  'Bridge Valley El Sch',
  'Bridge View Special Education',
  'Bridgedale Elementary School',
  'Bridgeforth Middle School',
  'Bridgehampton School',
  'Bridgeland H S',
  'Bridgemont Community and Technical College',
  'Bridgepoint Elementary School',
  'Bridgepoint High (continuation)',
  'Bridgeport Aurora High School',
  'Bridgeport Correctional Center',
  'Bridgeport El',
  'Bridgeport El Sch',
  'Bridgeport Elementary',
  'Bridgeport Elementary School',
  'Bridgeport Grade School',
  'Bridgeport H S',
  'Bridgeport Head Start',
  'Bridgeport High School',
  'Bridgeport Int',
  'Bridgeport Learning Center',
  'Bridgeport Middle',
  'Bridgeport Middle School',
  'Bridgeport Military Academy',
  'Bridgeportspaulding Middle Schoolschrah',
  'Bridgeprep Academy Broward County',
  'Bridgeprep Academy Charter',
  'Bridgeprep Academy Collier',
  'Bridgeprep Academy Duval County',
  'Bridgeprep Academy Interamerican Campus',
  'Bridgeprep Academy Of Advanced Studies',
  'Bridgeprep Academy Of Arts & Minds',
  'Bridgeprep Academy Of Greater Miami',
  'Bridgeprep Academy Of Hollywood Hills',
  'Bridgeprep Academy Of Palm Beach',
  'Bridgeprep Academy Of Riverview',
  'Bridgeprep Academy Of Tampa',
  'Bridgeprep Academy Of Village Green',
  'Bridgeprep Academy Osceola County',
  'Bridgeprep Academy South',
  'Bridger 7-8',
  'Bridger Elementary School',
  'Bridger High School',
  'Bridger Middle',
  'Bridger School',
  'Bridges',
  'Bridges Academy',
  'Bridges At Barkhamsted Elementary School',
  'Bridges At Horace Mann - 52',
  'Bridges Btc Inc',
  'Bridges Career And Technical High School',
  'Bridges Charter',
  'Bridges Community Academy',
  'Bridges Community Based School North County Consortium',
  'Bridges Community Day',
  'Bridges Community Elementary',
  'Bridges Elementary',
  'Bridges Elementary School',
  'Bridges Learning Center',
  'Bridges Nebo Transition Center',
  'Bridges Pcs',
  'Bridges Preparatory School',
  'Bridges School',
  'Bridges Transition',
  'Bridges Virtual Academy',
  'Bridges/wyandot Academy',
  'Bridgescape Academy',
  'Bridgestone Es',
  'Bridgeton Elementary',
  'Bridgeton High School',
  'Bridgeton Public Charter School',
  'Bridgetown Middle School',
  'Bridgeview Elem School',
  'Bridgeview Elementary School',
  'Bridgeville Elementary',
  'Bridgewater Academy Charter',
  'Bridgewater College',
  'Bridgewater Elementary',
  'Bridgewater Elementary School',
  'Bridgewater Middle',
  'Bridgewater Middle School',
  'Bridgewater-emery Elementary - 02',
  'Bridgewater-emery High School - 01',
  'Bridgewater-emery Middle School - 04',
  'Bridgewater-hebron Village School',
  'Bridgewater-raritan High School',
  'Bridgewater-raritan Middle School',
  'Bridgewater-raynham Regional',
  'Bridgeway Elem.',
  'Bridgeway Island Elementary',
  'Bridgeway Preparatory Academy',
  'Bridging Communities Reg Career & Technical Center',
  'Bridging Communities Stem Academy',
  'Bridgman Elementary School',
  'Bridgman High School',
  'Bridle Path El Sch',
  'Bridle Ridge Elementary',
  'Bridlemile Elementary School',
  'Bridlewood El',
  'Bridport Central School',
  'Brielle Elementary School',
  'Brien Mcmahon High School',
  'Brier Creek Elementary',
  'Brier Elementary',
  'Brier Terrace Middle School',
  'Briesemeister Middle',
  'Brigadoon Elementary School',
  'Brigantine Elementary School',
  'Brigantine North Middle School',
  'Briggs Chaney Middle',
  'Briggs Elementary',
  'Briggs Elementary School',
  'Briggs High School',
  'Briggs Middle School',
  'Briggs Public School',
  'Briggsdale Elementary School',
  'Briggsdale Undivided High School',
  'Brigham Elementary',
  'Brigham Young University',
  'Brigham Young University - Idaho',
  'Brigham Young University Hawaii',
  'Bright Beginnings Academic Center',
  'Bright Beginnings Learning Center',
  'Bright Beginnings School #1',
  'Bright El',
  'Bright Elem School',
  'Bright Elementary School',
  'Bright Field Middle School',
  'Bright Futures Academy',
  'Bright Futures Pre-k Prog',
  'Bright Futures Preschool',
  'Bright Horizons',
  'Bright Horizons Pre-kindergarten School',
  'Bright Star Elementary School',
  'Bright Star Secondary Charter Academy',
  'Bright Water Elementary',
  'Brighten Academy',
  'Brighter Choice Charter School For Boys',
  'Brighter Choice Charter School For Girls',
  'Brighter Choice Community School',
  'Brighter Day Preschool',
  'Brighton Avenue School',
  'Brighton Elementary',
  'Brighton Elementary School',
  'Brighton Heritage Academy',
  'Brighton High',
  'Brighton High School',
  'Brighton Middle School',
  'Brighton North Primary School',
  'Brighton Park Elem School',
  'Brighton School',
  'Brightwood',
  'Brightwood Ec',
  'Brightwood Elementary',
  'Brill El',
  'Brilla College Preparatory Charter School',
  'Brilla College Preparatory Charter School Veritas',
  'Brilliant Elementary School',
  'Brilliant High School',
  'Brillion Elementary',
  'Brillion High',
  'Brillion Middle',
  'Brimfield Elementary',
  'Brimfield Elementary School',
  'Brimfield Grade School',
  'Brimfield High School',
  'Brimhall Elementary',
  'Brimley Elementary School',
  'Brimley Jrsr High',
  'Brinckerhoff Elementary School',
  'Brindlee Mountain Elementary School',
  'Brindlee Mountain High School',
  'Brindlee Mountain Middle School',
  'Brindlee Mountain Primary School',
  'Brink Jhs',
  'Brinker El',
  'Brinkley High School',
  'Brinkley Middle School',
  'Brinnon Elementary',
  'Brinson Memorial Elementary',
  'Brinton Elementary',
  'Briones (alternative)',
  'Brisbane Elementary',
  'Briscoe El',
  'Briscoe J H',
  'Briscoe Middle',
  'Bristol Bay Correspondence',
  'Bristol Bay Elem Sch',
  'Bristol Bay Middle/high School',
  'Bristol Central High School',
  'Bristol Community College',
  'Bristol Consolidated School',
  'Bristol County Agricultural High',
  'Bristol Early Childhood Center-becc',
  'Bristol Eastern High School',
  'Bristol Elem.',
  'Bristol Elementary',
  'Bristol Elementary School',
  'Bristol Grade School',
  'Bristol High School',
  'Bristol Hs',
  'Bristol Ms',
  'Bristol-plymouth Vocational Technical',
  'Bristow Elementary',
  'Bristow Hs',
  'Bristow Middle School',
  'Bristow Ms',
  'Bristow Run Elementary',
  'Britain El',
  'Brite El',
  'British College of Osteopathic Medicine',
  'British Columbia Institute of Technology',
  'British Columbia Open University',
  'British Institute in Paris University of London',
  'British Malaysian Institute',
  'British Royal University',
  'British University in Dubai',
  'Britt David Elementary Computer Magnet Academy',
  'Britt Elementary School',
  'Brittan Acres Elementary',
  'Brittan Elementary',
  'Brittany Hill Middle',
  'Brittany Woods',
  "Brittany's Place",
  'Britton Deerfield Schooldeerfield Building',
  'Britton Deerfield Schoolsbritton Building',
  'Britton Elementary School',
  'Britton Es',
  "Britton's Neck Elementary",
  'Britton-hecla Elementary - 02',
  'Britton-hecla High School - 01',
  'Britton-hecla Jr. High - 03',
  'Brixner Junior High School',
  'Brjansk State Technical University',
  'Brno University of Technology',
  'Broad Avenue Elementary',
  'Broad Brook Elementary School',
  'Broad Creek Middle',
  'Broad Ford Elementary',
  'Broad Meadows Middle',
  'Broad Ripple Mgnt Hs For Prfm Arts',
  'Broad Ripple Mgnt Jr Hs-prfm Arts',
  'Broad River Elementary',
  'Broad Rock Elementary',
  'Broad Rock Middle School',
  'Broad Run High',
  'Broad Street El Sch',
  'Broad Street Elementary School',
  'Broad Street High School',
  'Broad Street School',
  'Broadacres Avenue Elementary',
  'Broadalbin-perth High School',
  'Broadalbin-perth Intermediate School',
  'Broadalbin-perth Middle School',
  'Broaddus El',
  'Broaddus H S',
  'Broadleigh Elementary School',
  'Broadmeadow',
  'Broadmeadow Elem School',
  'Broadmoor Elementary',
  'Broadmoor Elementary School',
  'Broadmoor Junior High School',
  'Broadmoor Middle Laboratory School',
  'Broadmoor Middle School',
  'Broadmoor Senior High School',
  'Broadmoore Es',
  'Broadmor Elementary School',
  'Broadneck Elementary',
  'Broadneck High',
  'Broadus 7-8',
  'Broadus School',
  'Broadus Wood Elementary',
  'Broadview 7-8',
  'Broadview Elementary',
  'Broadview Elementary School',
  'Broadview High School',
  'Broadview Middle',
  'Broadview Middle School',
  'Broadview School',
  'Broadview-thomson K-8 School',
  'Broadwater High School',
  'Broadwater School',
  'Broadway Academy',
  'Broadway El',
  'Broadway Elementary',
  'Broadway Elementary School',
  'Broadway High',
  'Broadway Learning Center',
  'Broadway Middle School',
  'Brock Bridge Elementary',
  'Brock El',
  'Brock Elliott Elementary',
  'Brock H S',
  'Brock J H',
  'Brock Road Elementary',
  'Brock University',
  'Brockbank School',
  'Brockett Elementary School',
  'Brockington Elementary',
  'Brockman Elementary',
  'Brockport High School',
  'Brocks Gap Intermediate School',
  'Brockton Avenue Elementary',
  'Brockton Champion High School',
  'Brockton High',
  'Brockton High School',
  'Brockway Area Elementary Sch',
  'Brockway Area Jshs',
  'Brockway Elementary School',
  'Brocton Elementary School',
  'Brocton Middle High School',
  'Brodhead Elementary School',
  'Brodhead High',
  'Brodhead Middle',
  'Brody Middle School',
  'Brogden Middle',
  'Brogden Primary',
  'Brokaw Early Learning Center',
  'Broken Arrow Elem',
  'Broken Arrow Freshman Academy',
  'Broken Arrow Hs',
  'Broken Bow High School',
  'Broken Bow Hs',
  'Broken Bow Middle School',
  'Broken Ground School',
  'Bromfield',
  'Bromley East Charter School',
  'Brompton School',
  'Bromwell Elementary School',
  'Bronaugh Elem.',
  'Bronaugh High',
  'Bronson Elementary School',
  'Bronson Jrsr High School',
  'Bronson Middle/high School',
  'Bronte Isd',
  'Bronx Academy For Software Engineering (base)',
  'Bronx Academy Of Health Careers',
  'Bronx Academy Of Promise Charter School',
  'Bronx Aerospace High School',
  'Bronx Alliance Middle School',
  'Bronx Arena High School',
  'Bronx Bridges High School',
  'Bronx Career And College Preparatory High School',
  'Bronx Center For Science And Mathematics',
  'Bronx Charter School For Better Learning',
  'Bronx Charter School For Better Learning Ii',
  'Bronx Charter School For Children (the)',
  'Bronx Charter School For Excellence',
  'Bronx Charter School For Excellence 2',
  'Bronx Charter School For Excellence 3',
  'Bronx Charter School For The Arts',
  'Bronx Collaborative High School',
  'Bronx Collelgiate Academy',
  'Bronx Community Charter School',
  'Bronx Community High School',
  'Bronx Compass High School',
  'Bronx Dance Academy School',
  'Bronx Delta School',
  'Bronx Design And Construction Academy',
  'Bronx Early College Academy For Teaching And Learning',
  'Bronx Engineering And Technology Academy',
  'Bronx Envision Academy',
  'Bronx Green Middle School',
  'Bronx Haven High School',
  'Bronx Health Sciences High School',
  'Bronx High School For Medical Science',
  'Bronx High School For The Visual Arts',
  'Bronx High School For Writing And Communication Arts',
  'Bronx High School Of Business',
  'Bronx High School Of Science',
  'Bronx High School-law And Community Service',
  'Bronx International High School',
  'Bronx Lab School',
  'Bronx Latin School',
  'Bronx Leadership Academy High School',
  'Bronx Leadership Academy Ii High School',
  'Bronx Lighthouse Charter School',
  'Bronx Little School',
  'Bronx Mathematics Preparatory School (the)',
  'Bronx Park Middle School',
  'Bronx Preparatory Charter School',
  'Bronx Regional High School',
  'Bronx River High School',
  'Bronx School For Continuous Learning (the)',
  'Bronx School For Law Government And Justice',
  'Bronx School Of Law And Finance',
  'Bronx Studio School For Writers And Artists',
  'Bronx Theatre High School',
  'Bronx Writing Academy',
  'Bronxdale High School',
  'Bronxville Elementary School',
  'Bronxville High School',
  'Bronxville Middle School',
  'Bronxwood Preparatory Academy (the)',
  'Bronzeville Academy Charter Sch',
  'Bronzeville Scholastic Hs',
  'Brook Avenue El',
  'Brook Avenue Elementary School',
  'Brook Forest Elem School',
  'Brook Glenn Elementary',
  'Brook Haven Elementary',
  'Brook Hill Elementary',
  'Brook Intermediate School',
  'Brook Knoll Elementary',
  'Brook Park Elem School',
  'Brook Park Elementary School',
  'Brook Park Memorial Elementary School',
  'Brook Valley School',
  'Brook Village Early Childhood',
  'Brookdale Avenue School',
  'Brookdale Community College',
  'Brookdale Elementary',
  'Brookdale Elementary School',
  'Brooke Charter School',
  'Brooke El',
  'Brooke El Sch',
  'Brooke Grove Elementary',
  'Brooke High School',
  'Brooke Intermediate School North',
  'Brooke Intermediate School South',
  'Brooke Middle School',
  'Brooke Point High',
  'Brooke Primary School North',
  'Brooke Primary School South',
  'Brookeland El',
  'Brookeland H S',
  'Brooker Creek Elementary School',
  'Brooker Elementary School',
  'Brooker Learning Center',
  'Brookesmith School',
  'Brookfield',
  'Brookfield Central School',
  'Brookfield Elem.',
  'Brookfield Elementary',
  'Brookfield Elementary School',
  'Brookfield High',
  'Brookfield High School',
  'Brookfield Middle',
  'Brookfield Middle School',
  'Brookfield School',
  'Brookhaven College',
  'Brookhaven El',
  'Brookhaven Elementary',
  'Brookhaven Elementary School',
  'Brookhaven High School',
  'Brookhaven Innovation Academy',
  'Brookhaven Middle School',
  'Brookhaven Technical Center',
  'Brookhill Elementary School',
  'Brookhollow El',
  'Brookhurst Elementary',
  'Brookhurst Junior High',
  'Brookings High School - 01',
  'Brookings-harbor High School',
  'Brooklake School',
  'Brookland Cayce Grammar School No. 1',
  'Brookland Elementary School',
  'Brookland High School',
  'Brookland Junior High School',
  'Brookland Middle',
  'Brookland Middle School',
  'Brookland Ms',
  'Brookland-cayce Senior High',
  'Brooklands Elementary School',
  'Brooklawn',
  'Brooklawn Middle School',
  'Brooklet Elementary School',
  'Brooklin School',
  'Brookline Early Education Program At Beacon',
  'Brookline Early Education Program At Putterham',
  'Brookline El',
  'Brookline High',
  'Brooklyn Academy High School',
  'Brooklyn Academy Of Global Finance (the)',
  'Brooklyn Academy Of Science And The Environment',
  'Brooklyn Arbor Elementary School',
  'Brooklyn Arts And Science Elementary School',
  'Brooklyn Ascend Charter School',
  'Brooklyn Avenue Elementary',
  'Brooklyn Avenue School',
  'Brooklyn Bridge Academy',
  'Brooklyn Brownstone School',
  'Brooklyn Center Early College Acad',
  'Brooklyn Center Ecse Age 3 - 5',
  'Brooklyn Center High School',
  'Brooklyn Center Independent Study',
  'Brooklyn Center Middle School',
  'Brooklyn Center Middle School Alc',
  'Brooklyn Center Summer School',
  'Brooklyn Charter School (the)',
  'Brooklyn College Academy',
  'Brooklyn Collegiate-a College Board School',
  'Brooklyn Community Arts And Media High School',
  'Brooklyn Correctional Institution',
  'Brooklyn Democracy Academy',
  'Brooklyn Dreams Charter School',
  'Brooklyn East Collegiate Charter School',
  'Brooklyn Elementary',
  'Brooklyn Elementary School',
  'Brooklyn Emerging Leaders Academy Charter School',
  'Brooklyn Environmental Exploration School (bees)',
  'Brooklyn Excelsior Charter School',
  'Brooklyn Frontiers High School',
  'Brooklyn Gardens Elementary School',
  'Brooklyn Generation School',
  'Brooklyn High School',
  'Brooklyn High School For Law And Technology',
  'Brooklyn High School For Leadership And Community',
  'Brooklyn High School Of The Arts',
  'Brooklyn Institute For Liberal Arts',
  'Brooklyn Intermediate School',
  'Brooklyn International High School At Waters Edge',
  'Brooklyn Lab School',
  'Brooklyn Laboratory Charter School',
  'Brooklyn Landmark Elementary School',
  'Brooklyn Latin School (the)',
  'Brooklyn Middle School',
  'Brooklyn Middle Steam School',
  'Brooklyn New School',
  'Brooklyn Park Elementary',
  'Brooklyn Park Middle',
  'Brooklyn Preparatory High School',
  'Brooklyn Primary School',
  'Brooklyn Prospect Charter School-csd 13',
  'Brooklyn Prospect Charter School-csd 15',
  'Brooklyn Scholars Charter School',
  'Brooklyn School For Global Studies',
  'Brooklyn School For Math And Research (the)',
  'Brooklyn School For Music & Theater',
  'Brooklyn School For Social Justice (the)',
  'Brooklyn School Of Inquiry',
  'Brooklyn Science And Engineering Academy',
  'Brooklyn Secondary School For Collaborative Studies',
  'Brooklyn Springs Elementary',
  'Brooklyn Studio Secondary School',
  'Brooklyn Technical High School',
  'Brooklyn Theatre Arts High School',
  'Brooklyn Urban Garden Charter School',
  'Brooklyn-guernsey-malcom Elementary School',
  'Brooklyn-guernsey-malcom Jr-sr High School',
  'Brookneal Elementary',
  'Brookpark Middle School',
  'Brookport Elementary School',
  'Brookridge Elem',
  'Brooks Academy Lonestar',
  'Brooks Academy Oaks',
  'Brooks Academy Of Science And Engineering',
  'Brooks College Prep Academy Hs',
  'Brooks Collegiate Academy',
  'Brooks Correctional Facility',
  'Brooks County Early Learning Center',
  'Brooks County High School',
  'Brooks County Middle School',
  'Brooks Crossing Elementary School',
  'Brooks Debartolo Collegiate High School',
  'Brooks Elem School',
  'Brooks Elementary',
  'Brooks Elementary School',
  'Brooks Global Elementary',
  'Brooks Harbor Elementary School',
  'Brooks High School',
  'Brooks Hill School',
  'Brooks Magnet Middle School',
  'Brooks Middle School',
  'Brooks School',
  'Brooks School Elementary',
  'Brooks Wester Middle',
  'Brooks-quinn-jones El',
  'Brookshire Elementary',
  'Brookside',
  'Brookside Charter Elementary',
  'Brookside Charter Middle Schl',
  'Brookside Early Childhood Sp Ed',
  'Brookside Elementary',
  'Brookside Elementary School',
  'Brookside Elementary School Campus',
  'Brookside High School',
  'Brookside Int',
  'Brookside Intermediate School',
  'Brookside Learning Center',
  'Brookside Middle School',
  'Brookside Place School',
  'Brookside School',
  'Brookside School 54',
  'Brookstown Middle Magnet Academy',
  'Brooksville Elementary School',
  'Brooksville Engineering Science And Technology (b.e.s.t.) A',
  'Brooktree Elementary',
  'Brookvale Elementary',
  'Brookview Elem School',
  'Brookview Elementary',
  'Brookview Elementary School',
  'Brookview School',
  'Brookville Elementary School',
  'Brookville High',
  'Brookville High School',
  'Brookville Intermediate School',
  'Brookville Jshs',
  'Brookville Middle',
  'Brookwood Academy',
  'Brookwood El',
  'Brookwood El Sch',
  'Brookwood Elem',
  'Brookwood Elementary',
  'Brookwood Elementary School',
  'Brookwood Forest El',
  'Brookwood Forest Elementary School',
  'Brookwood High',
  'Brookwood High School',
  'Brookwood Jr High School',
  'Brookwood Middle',
  'Brookwood Middle School',
  'Brookwood School',
  'Brookwood Secure Center',
  'Broome Developmental Center',
  'Broome High',
  'Broome Street Academy Charter High School',
  'Broome-delaware-tioga Boces',
  'Broomfield Heights Middle School',
  'Broomfield High School',
  'Brophy',
  'Brorson School',
  'Brosville Elementary',
  'Brothers Elementary School',
  'Broughal Ms',
  'Brougham Elem',
  'Brouillet Elementary',
  'Broussard Middle School',
  "Broward Children's Center North",
  "Broward Children's Center South",
  'Broward College',
  'Broward Detention Center',
  'Broward Elementary School',
  'Broward Estates Elementary School',
  'Broward Girls Academy',
  'Broward Math And Science Schools',
  'Broward Virtual Franchise',
  'Broward Virtual Instruction Program',
  'Broward Virtual Instruction Program (district Provided)',
  'Broward Youth Treatment Center',
  'Browerville Elementary',
  'Browerville Secondary',
  'Brown',
  'Brown Avenue School',
  'Brown Barge Middle School',
  'Brown City Alternative Hs',
  'Brown City Elementary School',
  'Brown City High School',
  'Brown County Elementary School',
  'Brown County High School',
  'Brown County Intermediate School',
  'Brown County Junior High',
  'Brown County Middle School',
  'Brown Deer Elementary',
  'Brown Deer Middle/high',
  'Brown Early Childhood Center',
  'Brown El',
  'Brown Elem.',
  'Brown Elementary',
  'Brown Elementary School',
  'Brown Henry A Sch',
  'Brown Intermediate Center',
  'Brown Intermediate School',
  'Brown International Academy',
  'Brown Joseph H Sch',
  'Brown Jr. (willie L) Middle',
  'Brown Middle',
  'Brown Middle School',
  'Brown Pri',
  'Brown R Elem Community Acad',
  'Brown River Middle School',
  'Brown Ronald Academy',
  'Brown School',
  'Brown Station Elementary',
  'Brown Street Academy',
  'Brown Summit Middle',
  'Brown University',
  'Brown Upper Elementary School',
  'Brown W Elem School',
  "Brown's Chapel Elementary School",
  "Brown's Ferry Elementary",
  'Browne Ec',
  'Browne Elementary',
  'Browne Middle',
  'Brownell Elem School',
  'Brownell Elementary School',
  'Brownell Middle',
  'Brownell Middle School',
  'Brownell Stem Academy',
  'Brownfield Education Center',
  'Brownfield H S',
  'Brownfield Middle',
  'Brownfields Elementary School',
  'Browning El',
  'Browning Elementary',
  'Browning Elementary School',
  'Browning High School',
  'Browning Middle School',
  'Browning Road Elementary',
  'Browning Springs Middle School',
  'Browning-pearce Elementary School',
  'Brownington Central School',
  'Browns Elementary',
  'Browns Mill Elementary School',
  'Browns Point',
  'Browns Valley Elementary',
  'Browns Valley Middle',
  'Brownsboro El',
  'Brownsboro H S',
  'Brownsboro Int',
  'Brownsboro J H',
  'Brownsburg Early Childhood Center',
  'Brownsburg East Middle School',
  'Brownsburg High School',
  'Brownsburg West Middle School',
  'Brownstone Intermediate School',
  'Brownstown Central High School',
  'Brownstown Central Middle School',
  'Brownstown El Sch',
  'Brownstown Elem School',
  'Brownstown Elementary School',
  'Brownstown High School',
  'Brownstown Jr High School',
  'Brownstown Middle School',
  'Brownsville Academic Center',
  'Brownsville Academy High School',
  'Brownsville Area El Sch',
  'Brownsville Area Hs',
  'Brownsville Area Ms',
  'Brownsville Ascend Charter School',
  'Brownsville Attendance Center',
  'Brownsville Collaborative Middle School',
  'Brownsville Collegiate Charter School',
  'Brownsville Early College H S',
  'Brownsville Elementary',
  'Brownsville Learning Acad H S',
  'Brownsville Learning Academy Middle',
  'Brownsville Middle School',
  'Brownsville Road Elementary',
  'Brownville Elementary School',
  'Brownville School',
  'Brownwood Accelerated H S',
  'Brownwood Daep',
  'Brownwood Elementary School',
  'Brownwood H S',
  'Brownwood Int',
  'Brownwood Middle',
  'Broxton-mary Hayes Elementary',
  'Brubaker Elementary School',
  'Bruce (robert) Elementary',
  'Bruce Collins Elementary School',
  'Bruce Drysdale Elementary',
  'Bruce El',
  'Bruce Elementary',
  'Bruce Elementary School',
  'Bruce F Vento Elementary',
  'Bruce Guadalupe',
  'Bruce H Tharrington Primary',
  'Bruce High',
  'Bruce High School',
  'Bruce J H',
  'Bruce K Craig Elementary School',
  'Bruce M Whittier Middle School',
  'Bruce Middle',
  'Bruce Monroe Es At Park View',
  'Bruce Randolph School',
  'Bruce Shulkey El',
  'Bruce Street School For The Deaf',
  'Bruce Upper Elementary School',
  'Bruceton School',
  'Bruceville-eddy El',
  'Bruceville-eddy H S',
  'Bruceville-eddy Int',
  'Bruceville-eddy J H',
  'Brucie Ball Educational Center',
  'Bruin Point School',
  'Brule El',
  'Brumby Elementary School',
  'Brummitt Elementary School',
  'Brundrett Middle',
  'Bruneau Elementary School',
  'Brunel University Uxbridge',
  'Bruni El',
  'Bruni H S',
  'Bruni Middle',
  'Bruning Elem School',
  'Bruning-davenport Elem/bruning',
  'Bruning-davenport Elem/davenpt',
  'Bruning-davenport High School',
  'Bruning-davenport Middle Sch',
  'Bruno M Ponterio Ridge Street School',
  'Bruno-pyatt Elementary School',
  'Bruno-pyatt High School',
  'Bruno-pyatt K-12 School',
  'Bruns Academy',
  'Brunson Elementary',
  'Brunson Math & Sci Specialty Elem',
  'Brunson New Vision Charter School',
  'Brunson-dargan Elementary',
  'Brunson-lee Elementary School',
  'Brunswick Acres Elementary School',
  'Brunswick Community College',
  'Brunswick County Academy',
  'Brunswick County Early College',
  'Brunswick Elem.',
  'Brunswick Elementary',
  'Brunswick High',
  'Brunswick High School',
  'Brunswick Jr High School',
  'Brunswick Memorial Elementary School',
  'Brunswick Middle',
  'Brush College Elementary School',
  'Brush Creek Elementary',
  'Brush Creek Elementary School',
  'Brush High School',
  'Brush Middle School',
  'Brushfork Elementary School',
  'Brushton Grade School',
  'Brushton-moira High School',
  'Brushy Creek El',
  'Brushy Creek Elementary',
  'Brushy Public School',
  'Brusly Elementary School',
  'Brusly High School',
  'Brusly Middle School',
  'Brussels Grade School',
  'Brussels High School',
  'Brussels Management School (ICHEC)',
  'Brussels School of International Studies',
  'Bruton High',
  'Bryan',
  'Bryan Adams H S',
  'Bryan City Schools Preschool',
  'Bryan Collegiate H S',
  'Bryan Community School',
  'Bryan County Elementary School',
  'Bryan County High School',
  'Bryan County Middle School',
  'Bryan El',
  'Bryan Elem.',
  'Bryan Elementary',
  'Bryan Elementary School',
  'Bryan High School',
  'Bryan Hill Elem.',
  'Bryan Middle',
  'Bryan Middle School',
  'Bryan Middle School/high School',
  'Bryan Road Elementary',
  'Bryan Station High School',
  'Bryan Station Middle School',
  'Bryant',
  'Bryant Center',
  'Bryant College',
  'Bryant El',
  'Bryant Elem School',
  'Bryant Elem.',
  'Bryant Elementary',
  'Bryant Elementary School',
  'Bryant Es',
  'Bryant High',
  'Bryant High School',
  'Bryant Middle',
  'Bryant Middle School',
  'Bryant Ranch Elementary',
  'Bryant School',
  'Bryant Webster Dual Language Ece-8 School',
  'Bryant William C Sch',
  'Bryant Woods Elementary',
  'Bryantville Elementary',
  'Bryce Ash Grove Ed Ctr',
  'Bryce Valley High',
  'Bryce Valley School',
  'Bryceville Elementary School',
  'Bryden Elementary School',
  'Bryker Woods El',
  'Bryn Mawr',
  'Bryn Mawr College',
  'Bryn Mawr Elementary',
  'Bryn Mawr Elementary School',
  'Bryson Avenue Elementary',
  'Bryson El',
  'Bryson Elementary',
  'Bryson Middle',
  'Bryson School',
  'Brywood Elementary',
  'Bsd Preschool',
  'Bt Bullock Elementary',
  'Bu Ali Sina University',
  'Bubbling Wells Elementary',
  'Buchanan Co. Academy',
  'Buchanan County Technology Career & Higher Learning Center',
  'Buchanan El Sch',
  'Buchanan Elementary',
  'Buchanan Elementary - 03',
  'Buchanan Elementary School',
  'Buchanan Es',
  'Buchanan High',
  'Buchanan High School',
  'Buchanan Intermediate',
  'Buchanan K-1 Center - 18',
  'Buchanan Middle School',
  'Buchanan Primary School',
  'Buchanan School',
  'Buchanan Step Up',
  'Buchanan Street Elementary',
  'Buchanan Virtual Academy',
  'Buchanan-verplanck Elementary School',
  'Bucher Sch',
  'Buchser Middle',
  'Buchtel High School',
  'Buck El Sch',
  'Buck Lake Elementary School',
  'Buck Lodge Middle',
  'Buckalew El',
  'Buckatunna Elementary School',
  'Buckeye Career Center',
  'Buckeye Career Center Annex-hiland Satellite',
  'Buckeye Central Elementary School',
  'Buckeye Central High School',
  'Buckeye Central Middle School',
  'Buckeye Elementary',
  'Buckeye Elementary School',
  'Buckeye High School',
  'Buckeye Hills Career Center',
  'Buckeye Intermediate School',
  'Buckeye Junior High School',
  'Buckeye Local High School',
  'Buckeye Local Junior High',
  'Buckeye Middle School',
  'Buckeye North Elementary School',
  'Buckeye On-line School For Success',
  'Buckeye Preparatory Academy',
  'Buckeye Primary School',
  'Buckeye School Of The Arts',
  'Buckeye South Elementary School',
  'Buckeye Trail Elementary',
  'Buckeye Trail High School',
  'Buckeye Trail Middle School',
  'Buckeye Union High School',
  'Buckeye Valley East Elementary School',
  'Buckeye Valley Local High School',
  'Buckeye Valley Local Middle School',
  'Buckeye Valley West Elementary School',
  'Buckeye West Elementary School',
  'Buckeye Woods Elementary School',
  'Buckfield Jr-sr High School',
  'Buckhannon Academy Elementary School',
  'Buckhannon Upshur High School',
  'Buckholts School',
  'Buckhorn Childrens Center',
  'Buckhorn Creek Elementary',
  'Buckhorn Elementary School',
  'Buckhorn High School',
  'Buckhorn Middle School',
  'Buckhorn School',
  'Buckingham Co Elementary',
  'Buckingham Co Prekindergarten Center',
  'Buckingham Co Primary',
  'Buckingham County High',
  'Buckingham County Middle',
  'Buckingham El Sch',
  'Buckingham Elementary',
  'Buckingham Elementary School',
  'Buckingham Exceptional Student Center',
  'Buckinghamshire New University',
  'Buckland Elementary',
  'Buckland Mills Elementary',
  'Buckland School',
  'Buckland-shelburne Regional',
  'Buckley Community Schools',
  'Buckley School',
  'Buckley-carpenter Elementary School',
  'Bucklin Elem',
  'Bucklin Elem.',
  'Bucklin High',
  'Buckman Elementary School',
  'Buckman Heights Elementary School',
  'Bucknell Elementary',
  'Bucknell University',
  'Buckner Alternative High School',
  'Buckner Elem.',
  'Buckner Elementary School',
  'Buckner Performing Arts Magnet Elem',
  'Bucks County Community College',
  'Bucks County Montessori Cs',
  'Bucks County Technical High School',
  'Bucks Hill School',
  'Buckshutem Road School',
  'Buckskin Elementary School',
  'Bucksport High School',
  'Bucksport Middle School',
  'Bucktail Area Ms',
  'Bucktail Hs',
  'Bucyrus Elementary School',
  'Bucyrus Secondary School',
  'Bud Beasley Elementary School',
  'Bud Carlson Academy',
  'Bud Carson Middle',
  'Bud Rank Elementary',
  'Buda El',
  'Budapest Buddhist University',
  'Budapest University of Economic Sciences and Public Administration',
  'Budd Elementary School',
  'Buddhasravaka Bhikshu University',
  'Buddhist Acamedy of China',
  'Buddhist and Pali University of Sri Lanka',
  'Buddy Taylor Middle School',
  'Buder Elem.',
  'Budewig Int',
  'Budlong Avenue Elementary',
  'Budlong Elem School',
  'Bueker Middle',
  'Buell Central Daep',
  'Buena High',
  'Buena High School',
  'Buena Park High',
  'Buena Park Junior High',
  'Buena Regional High School',
  'Buena Regional Middle School',
  'Buena Terra Elementary',
  'Buena Vista Arts-integrated',
  'Buena Vista Community Day',
  'Buena Vista Continuation High',
  'Buena Vista El',
  'Buena Vista Elementary',
  'Buena Vista Elementary School',
  'Buena Vista High',
  'Buena Vista High (continuation)',
  'Buena Vista High School',
  'Buena Vista Middle',
  'Buena Vista School',
  'Buena Vista University',
  'Buena Vista/ Horace Mann K-8',
  'Buff Intermediate School',
  'Buffalo Academy For The Visual & Performing Arts',
  'Buffalo Academy Of Science Charter School',
  'Buffalo Campus',
  'Buffalo Community Middle',
  'Buffalo Creek El',
  'Buffalo Creek Middle School',
  'Buffalo Early Child. Sp. Ed Program',
  'Buffalo El',
  'Buffalo El Sch',
  'Buffalo Elementary',
  'Buffalo Elementary - 02',
  'Buffalo Elementary School',
  'Buffalo Elementary School Of Technology',
  'Buffalo Es',
  'Buffalo Gap El',
  'Buffalo Gap High',
  'Buffalo Grove High School',
  'Buffalo H S',
  'Buffalo High',
  'Buffalo High School',
  'Buffalo Hills Elementary',
  'Buffalo Hs',
  'Buffalo Is. Central East Elem.',
  'Buffalo Is. Central Hs',
  'Buffalo Is. Central Jhs',
  'Buffalo Is. Central West Elem.',
  'Buffalo J H',
  'Buffalo Jones Elem',
  'Buffalo Lake-hector Elementary',
  'Buffalo Lake-hector Secondary',
  'Buffalo Middle',
  'Buffalo Middle School',
  'Buffalo Point School',
  'Buffalo Ridge Elementary',
  'Buffalo Ridge Elementary School',
  'Buffalo Senior High',
  'Buffalo Trail Elementary',
  'Buffalo Trail Elementary School',
  'Buffalo United Charter School',
  'Buffalo Valley Es',
  'Buffalo Valley Hs',
  'Buford Academy',
  'Buford Elementary',
  'Buford Elementary School',
  'Buford Garner Elementary',
  'Buford High',
  'Buford High School',
  'Buford Middle',
  'Buford Middle School',
  'Bug-o-nay-ge-shig',
  'Bugbee School',
  'Bugema University',
  'Bugg Elementary',
  'Buhach Colony High',
  'Buhl Elementary School',
  'Buhl High School',
  'Buhl Middle School',
  'Buhler Elem',
  'Buhler High',
  'Buhrer',
  'Buhsd Institute Of Online Learning',
  'Buies Creek Elementary',
  'Build Academy',
  'Build Bright University',
  'Building 21',
  'Building 21 Allentown',
  'Buinger Cte Academy',
  'Buist Academy',
  'Bukkyo University',
  'Bulacan State University',
  'Bule Hora  University',
  'Bulent Ecevit University',
  'Bulkeley High School',
  'Bulkeley Middle School',
  'Bull Run E.c. Resource Ctr.',
  'Bull Run Elementary',
  'Bull Run Middle',
  'Bullard Early Childhood',
  'Bullard El',
  'Bullard Elementary School',
  'Bullard H S',
  'Bullard High',
  'Bullard Int',
  'Bullard Ms',
  'Bullard Pri',
  'Bullard Talent Project',
  'Bullard-havens Technical High School',
  'Bullen Middle',
  'Bullhead City Jr High School',
  'Bullis Charter',
  'Bullitt Alternative Center',
  'Bullitt Central High School',
  'Bullitt County Area Technology Center',
  'Bullitt East High School',
  'Bullitt Lick Middle School',
  'Bullock County Career Technical Center',
  'Bullock County High School',
  'Bullock Creek Adult Education',
  'Bullock Creek Elementary School',
  'Bullock Creek High School',
  'Bullock Creek Middle School',
  'Bullock El',
  'Bulls Gap School',
  'Bullskin El Sch',
  'Bulverde Creek',
  'Buna El',
  'Buna H S',
  'Buna J H',
  'Bunceton Elem.',
  'Bunceton High',
  'Bunche Elementary School',
  'Bunche Elementarymiddle School',
  'Bunche Middle',
  'Bunche Middle School',
  'Bunche Park Elementary School',
  'Buncombe Cons School',
  'Bundelkhand University',
  'Bundy Elementary School',
  'Bungay School',
  'Bunger Middle School',
  "Bunka Women's University",
  'Bunker Elem.',
  'Bunker High',
  'Bunker Hill Bdlc',
  'Bunker Hill Community College',
  'Bunker Hill El',
  'Bunker Hill Elementary',
  'Bunker Hill Elementary School',
  'Bunker Hill Es',
  'Bunker Hill High',
  'Bunker Hill High School',
  'Bunker Hill Middle School',
  'Bunker Hill School',
  'Bunkie Elementary School',
  'Bunkie Magnet High School',
  'Bunkyo University',
  'Bunn Elementary',
  'Bunn High',
  'Bunn Middle',
  'Bunnell Elementary School',
  'Bunnell High School',
  'Bunsold Middle School',
  'Burapha University',
  'Buraydah College for Applied Medical Sciences',
  'Burbank (luther) Elementary',
  'Burbank Boulevard Elementary',
  'Burbank El',
  'Burbank Elem School',
  'Burbank Elementary',
  'Burbank H S',
  'Burbank High',
  'Burbank Middle',
  'Burbank Preschool Center',
  'Burbank Unified Independent Learning Academy (buila)',
  'Burbank Usd Community Day',
  'Burch Charter School Of Excellence',
  'Burch Creek School',
  'Burch Pk8',
  'Burcham Elementary',
  'Burcham Es',
  'Burchard A Dunn School',
  'Burchell High School',
  'Burchfield Elementary',
  'Burchfield Primary Sch',
  'Burckhalter Elementary',
  'Burdell Elementary School',
  'Burdick Elementary',
  'Bureau Valley Elemntry-wyanet',
  'Bureau Valley High School',
  'Bureau Valley North',
  'Bureau Valley South',
  'Burgard El Sch',
  'Burgard High School',
  'Burgaw Elementary',
  'Burgaw Middle',
  'Burger Development Center',
  'Burges H S',
  'Burgess El',
  'Burgess Elementary',
  'Burgess-peterson Elementary School',
  'Burgettstown El Ctr',
  'Burgettstown Ms/hs',
  'Burgin El',
  'Burgin Independent School',
  'Buri Buri Elementary',
  'Burjat State University',
  'Burk Elementary School',
  'Burk Horizon Southwest Sunset Hs',
  'Burkburnett H S',
  'Burkburnett Middle',
  'Burke Basic School',
  'Burke Central Elementary School',
  'Burke Central High School',
  'Burke County High School',
  'Burke County Middle School',
  'Burke El',
  'Burke Elem School',
  'Burke Elementary - 02',
  'Burke High',
  'Burke High School',
  'Burke High School - 01',
  'Burke Jr. High - 04',
  'Burke Middle College',
  'Burke School',
  'Burke Street Elementary School',
  'Burke Town School',
  'Burke/memorial Elementary School',
  'Burkeville El',
  'Burkeville Elementary',
  'Burkeville H S',
  'Burkeville Middle',
  'Burks El',
  'Burks Elementary',
  'Burleigh County Spec Ed Unit',
  'Burleigh Elementary',
  'Burleigh Manor Middle School',
  'Burleson Centennial H S',
  'Burleson Center',
  'Burleson Collegiate H S',
  'Burleson El',
  'Burleson H S',
  'Burley Elem School',
  'Burley Glenwood Elementary',
  'Burley Junior High School',
  'Burley Pri',
  'Burley Senior High School',
  'Burlingame Elementary',
  'Burlingame High',
  'Burlingame Intermediate',
  'Burlingame Junior/senior High',
  'Burlington Center',
  'Burlington City High School',
  'Burlington Co Juven Det Ctr',
  'Burlington Community High School',
  'Burlington County Alternative High School',
  'Burlington County College',
  'Burlington County Inst Of Tech - Medford',
  'Burlington County Inst Of Tech - Westhampton',
  'Burlington Edison High School',
  'Burlington Elementary',
  'Burlington Elementary School',
  'Burlington Es',
  'Burlington High',
  'Burlington High School',
  'Burlington Hs',
  'Burlington Middle School',
  'Burlington Middle School 5 - 8',
  'Burlington Primary',
  'Burlington Regional School',
  'Burlington School',
  'Burlington Senior High School',
  'Burlington Technical Center',
  'Burlington Township High School',
  'Burlington Township Middle School At Springside',
  'Burlington Trans Ed Center',
  'Burlington-des Lacs Elementary School',
  'Burlington-edison Alternative School',
  'Burncoat Middle School',
  'Burncoat Senior High',
  'Burncoat Street',
  'Burnet El',
  'Burnet H S',
  'Burnet Hill Elementary School',
  'Burnet M S',
  'Burnet Middle',
  'Burnet Middle School',
  'Burnett Creek Elementary School',
  'Burnett Elementary',
  'Burnett Elementary School',
  'Burnett Middle School',
  'Burnett-bayland Rehabilitation Center',
  'Burnette Elementary School',
  'Burney Community Day',
  'Burney Elementary',
  'Burney Elementary Community Day',
  'Burney Elementary School',
  'Burney Junior-senior High',
  'Burney-harris-lyons Middle School',
  'Burnham Elem Inclusive Academy',
  'Burnham Elem School',
  'Burning Springs Elementary',
  'Burning Tree Elementary',
  'Burnley-moran Elementary',
  'Burns Alternative School',
  'Burns Avenue School',
  'Burns El',
  'Burns Elementary',
  'Burns Elementarymiddle School',
  'Burns Flat-dill City Hs',
  'Burns High',
  'Burns High School',
  'Burns Jr & Sr High School',
  'Burns Latino Studies Academy',
  'Burns Middle',
  'Burns Middle School',
  'Burns Park Elementary School',
  'Burns Science And Technology Charter School',
  'Burns Valley',
  'Burnside Elem Scholastic Academy',
  'Burnside Elementary School',
  'Burnside Elementary.',
  'Burnsville Alternative High School',
  'Burnsville Area Learning Center',
  'Burnsville Elementary',
  'Burnsville Elementary School',
  'Burnsville High School',
  'Burnsville Sr High Extended Day Pro',
  'Burnt Bridge Creek Elementary Sch',
  'Burnt Chimney Elementary',
  'Burnt Hickory Elementary School',
  'Burnt Hills-ballston Lake Senior High School',
  'Burnt Mills Elementary',
  'Burnt Ranch Elementary',
  'Burnt River School',
  'Burr District Elementary School',
  'Burr Elem School',
  'Burr Elementary School',
  'Burr Intermediate School',
  'Burr Oak Academy',
  'Burr Oak Elem School',
  'Burr Oak Elementary School',
  'Burr Oak High School',
  'Burr Oak Virtual Academy',
  'Burr Ridge Middle School',
  'Burr School',
  'Burrel Elementary',
  'Burrell Hs',
  'Burrillville High School',
  'Burrillville Middle School',
  'Burris Elementary School',
  'Burris Laboratory School',
  'Burroughs Elem School',
  'Burroughs Elementary',
  'Burroughs Elementary School',
  'Burroughs Es',
  'Burroughs High',
  'Burroughs-molette Elementary School',
  'Burrowes Sch',
  'Burrton Elem',
  'Burrton Ms/hs',
  'Burrus El',
  'Burrville Es',
  'Bursa Tecnical University',
  'Bursch Elementary',
  'Bursley School',
  'Burt Elementary',
  'Burt Township School',
  'Burton (phillip And Sala) Academic High',
  'Burton Center For Arts And Technology',
  'Burton Community Day',
  'Burton El',
  'Burton Elementary',
  'Burton Elementary School',
  'Burton Glen Charter Academy',
  'Burton H S',
  'Burton Hill El',
  'Burton Horizon Academy',
  'Burton International School',
  'Burton Middle',
  'Burton Middle School',
  'Burton Pack Elementary',
  'Burton Street Elementary',
  'Burton Street Elementary School',
  'Burton Valley Elementary',
  'Burtonsville Elementary',
  'Burwell Elementary School',
  'Burwell Jr-sr High School',
  'Busbee Corbett Elementary Middle',
  'Busch Ms Character Athletics',
  'Busd School Of Opportunity',
  'Bush (charles W.) Pre-school',
  'Bush El',
  'Bush Elem.',
  'Bush Elementary',
  'Bush Elementary School',
  'Bush Hill Elementary',
  'Bush Hills Academy',
  'Bush K8',
  'Bush Middle',
  'Bushehr University of Medical Sciences',
  'Bushkill El Sch',
  'Bushland El',
  'Bushland H S',
  'Bushland Middle',
  'Bushnell Elementary School',
  'Bushnell Way Elementary',
  'Bushnell-prairie City Elem Sch',
  'Bushnell-prairie City High Sch',
  'Bushnell-prairie City Jr High Sch',
  'Bushwick Ascend Charter School',
  'Bushwick Community High School',
  'Bushwick Leaders Hs For Academic Excellence',
  'Bushy Park Elementary',
  'Business & Computer University College',
  'Business & Technology Complex',
  'Business And Economics Academy Of Milwaukee',
  'Business Of Sports School',
  'Business School Lausanne (BSL)',
  'Business Technology Early College High School',
  'Business and Hotel Management School',
  'Busitema University',
  'Busoga University',
  'Bussey El',
  'Bussey Middle',
  'Butcher-greene Elem.',
  'Butler Acres Elementary',
  'Butler Area Ihs',
  'Butler Area Shs',
  'Butler Avenue Elementary',
  'Butler Co. Area Technology Center',
  'Butler Community College',
  'Butler County Area Vocational School',
  'Butler County Avts',
  'Butler County Community College',
  'Butler County High School',
  'Butler County Learning Center',
  'Butler County Middle School',
  'Butler Creek Elementary School',
  'Butler Early Childhood',
  'Butler El',
  'Butler El Sch',
  'Butler Elem School',
  'Butler Elem.',
  'Butler Elementary School',
  'Butler High',
  'Butler High School',
  'Butler Junior High School',
  'Butler Middle',
  'Butler Middle School',
  'Butler Ms',
  'Butler School',
  'Butler Tech Bioscience Center',
  'Butler Tech Natural Science Center',
  'Butler Tech School Of The Arts',
  'Butler Traditional High',
  'Butler University',
  'Butner Es',
  'Butner Hs',
  'Butner-stem Elementary',
  'Butner-stem Middle',
  'Butte College',
  'Butte County Community - Lead',
  'Butte County Middle School',
  'Butte County Middle/high School',
  'Butte County Rop',
  'Butte County Special Education',
  'Butte Creek Elementary School',
  'Butte Des Morts Elementary',
  'Butte Elementary',
  'Butte Falls Charter School',
  'Butte High School',
  'Butte Valley Elementary',
  'Butte Valley High',
  'Butte View High',
  'Butte Vista Elementary',
  'Buttercup Pre-school',
  'Butterfield Canyon School',
  'Butterfield Charter High',
  'Butterfield El',
  'Butterfield Elem School',
  'Butterfield Elementary',
  'Butterfield Elementary School',
  'Butterfield Junior High School',
  'Butterfield Ranch Elementary',
  'Butterfield School',
  'Butterfield Secondary',
  'Butternut Creek Elementary School',
  'Butternut Elementary',
  'Butternut Elementary School',
  'Butternut High',
  'Butterworth Elem School',
  'Butteville Elementary',
  'Button Gwinnett Elementary School',
  'Buttonball Lane School',
  'Buttonwillow Elementary',
  'Butts Road Intermediate',
  'Butts Road Primary',
  'Buxton Center Elementary School',
  'Buzz Aldrin Elem School',
  'Buzz Aldrin Middle School',
  'Byam School',
  'Byars Dowdy Elementary',
  'Byck Elementary',
  'Byers Elementary School',
  'Byers Junior-senior High School',
  'Byesville Elementary School',
  'Byhalia Elementary School (k-5)',
  'Byhalia High School (9-12)',
  'Byhalia Middle School (6-8)',
  'Byng Es',
  'Byng Hs',
  'Byng Jhs',
  'Bynum School',
  'Byram Hills High School',
  'Byram Intermediate School',
  'Byram Lakes Elementary School',
  'Byram Middle School',
  'Byrd Avenue Primary School',
  'Byrd Elementary',
  'Byrd First Class Early Learning Center',
  'Byrne Elem School',
  'Byrnedale Elementary School',
  'Byrneville Elementary School Inc.',
  'Byrns Darden Elementary',
  'Byron A. Barry School',
  'Byron Area Elementary School',
  'Byron Area High School',
  'Byron Area Middle School',
  'Byron Center Charter School',
  'Byron Center Early Childhood Center',
  'Byron Center High School',
  'Byron Center West Middle School',
  'Byron Elementary School',
  'Byron High School 9-12',
  'Byron Intermediate School',
  'Byron Kibler Elementary School',
  'Byron Middle School',
  'Byron Nelson H S',
  'Byron P Steele Ii H S',
  'Byron Primary School',
  'Byron Senior High School',
  'Byron Syring Delta Center',
  'Byron-bergen Elementary School',
  'Byron-bergen Junior/senior High School',
  'Bywood El Sch',
  'C 4 Elem',
  'C A Farley',
  'C A Henning School',
  'C A Mcmillen H S',
  'C A Tatum Jr El',
  'C B Eller Elementary',
  'C B Noblin School',
  'C B Smith Elem School',
  'C B Thompson Middle',
  'C C Burr',
  'C C Griffin Middle',
  'C C Hardy El',
  'C C Lee Elementary - 05',
  'C C Mason El',
  'C C Meneley Elementary School',
  'C C Ronnow Elementary School',
  'C C Spaulding Elementary',
  'C C Washington Academy',
  'C C Winn High School',
  'C C Wright Elementary',
  'C D Fulkes Middle',
  'C D Landolt El',
  'C E Cole Intermediate Sch',
  'C E Ellison H S',
  'C E Jordan High',
  'C E King H S',
  'C E King Middle',
  'C E Mccall Ms',
  'C E Miller Elem School',
  'C E Rose Elementary School',
  'C E Vail El',
  'C E Walters School',
  'C F Carr El',
  'C F Holliday Elementary School',
  'C F Simmons Middle School',
  'C G & N ACADEMY & SCHOOL',
  'C G Johnson El Sch',
  'C G Sivells El',
  'C Grant Grimshaw School',
  'C H Bird Elementary',
  'C H Campbell Elementary School',
  'C H Decker Elementary School',
  'C Hal Henard Elementary',
  'C Henry Bloom Elem School',
  'C I Johnson Elem School',
  'C I T Y Program',
  'C I Waggoner School',
  'C J Harris El',
  'C J Hooker Middle School',
  'C J Jorgensen School',
  'C Joyner Greene Education Center',
  'C K Burns School',
  'C L Jones Middle School',
  'C L Salter Elementary School',
  'C M Bardwell Elem School',
  'C M Eppes Middle',
  'C M Rice Middle',
  'C M Russell High School',
  'C O Bastian (caliente Youth Center)',
  'C O Chandler El',
  'C O Greenfield School',
  'C O Harrison Elementary School',
  'C O R E Academy',
  'C O Sorenson',
  'C P Carmichael Alternative School',
  'C P Squires Elementary School',
  'C R Anderson Middle Schl',
  'C R Clements/hollie Parsons El',
  'C R Hanna Elem School',
  'C R Sundwall Center',
  'C R Towslee Elementary School',
  'C R Weeks Elementary School',
  'C Ray Williams Early Childhood Center',
  'C S Driver Middle School',
  'C Stainke El',
  'C T Eddins El',
  'C T Sewell Elementary School',
  'C V Starr Intermediate School',
  'C V T Gilbert Elementary School',
  'C W Cline El',
  'C W Harris Es',
  'C W Mcgraw Elementary School',
  'C W Woodbury Middle School',
  'C Wayne Collier Elementary',
  'C Y Middle School',
  'C-bar-v Ranch',
  'C. A. Jacobs Intermediate',
  'C. A. Johnson High',
  'C. A. Roberts Elementary School',
  'C. A. Weis Elementary School',
  'C. Alton Lindsay Middle',
  'C. B. Eaton Elementary',
  'C. B. Watson Primary School',
  'C. C. Violette Elementary',
  'C. E. Murray High',
  'C. E. Murray Middle',
  'C. E. Utt Middle',
  'C. E. Williams Middle School For Creative And Scientific Art',
  'C. Elizabeth Rieg Regional Center',
  'C. G. Bethel High School',
  'C. G. Credle Elementary',
  'C. H. Price Middle School',
  'C. Hunter Ritchie Elementary',
  'C. K. Mcclatchy High',
  'C. L. Overturf Jr 6th Grade Center',
  'C. Milton Wright High',
  'C. Morley Sellery Special Education Center',
  'C. P. Smith School',
  'C. Paul Barnhart Elementary School',
  'C. Richard Applegate School',
  'C. Roy Carmichael Elementary',
  'C. T. English Middle',
  'C. W. Davis Middle School',
  'C. W. Dillard Elementary',
  'C. W. Haman Elementary',
  'C. W. Norton Elementary School',
  'C. W. Ruckel Middle School',
  'C. W. Shipley Elementary School',
  'C.F.RICHARDS CHRISTIAN SCHOOL',
  'C.L. PREP ACADEMY',
  'C.a. Sinclair Elementary',
  'C.b. Jennings Dual Language & International Elementary Magn',
  'C.b. Partee Elementary School',
  'C.c. Wells Elementary',
  'C.d. Hylton High',
  'C.e. Byrd High School',
  'C.e. Curtis Elementary',
  'C.e. Jacobson Elementary',
  'C.f. Rowley Alternative School',
  'C.l. Taitano Elementary School',
  'C.m. Bradley Elementary',
  'C.o.m.p.a.s.s.',
  'C.s. Lewis Academy',
  'C.t. Douglas Elementary School',
  'C.v. Koogler Middle',
  'C/O DAN HERSHBERGER',
  'C/O EDWARD BEACHY',
  'C/O JOHN MILLER',
  'C/O NORMAN MAST',
  'C/O SAM BRUBAKER',
  'C/O SAM BRUBAKER , CHAIRMAN',
  'C/O SAM BRUBAKER, CHAIRMAN',
  'C/O STEVEN MAST',
  'C/O WYMAN HERSHBERGER',
  'C3 CONCORD CHRISTIAN SCHOOL',
  'C4L ACADEMY',
  'CABLE LINE SCHOOL',
  'CABRINI ELEMENTARY & MIDDLE SCHOOL',
  'CABRINI HIGH SCHOOL',
  'CACC MONTESSORI SCHOOL',
  'CACHUMA ELEMENTARY AND MIDDLE SCHOOL',
  'CADILLAC HERITAGE CHRISTIAN SCHOOL',
  'CAHAWBA CHRISTIAN ACADEMY',
  'CAI LEARNING ACADEMY',
  'CAIR PARAVEL LATIN SCHOOL',
  'CAL COAST ACADEMY',
  'CALAIS SCHOOL',
  'CALAVERAS MONTESSORI SCHOOL',
  'CALDWELL ACADEMY',
  'CALDWELL CHRISTIAN ACADEMY',
  'CALDWELL SCHOOL',
  'CALEDONIA CHRISTIAN SCHOOL',
  'CALEXICO MISSION SCHOOL',
  'CALFORINA CROSSPOINT ACADEMY',
  'CALHOUN ACADEMY',
  'CALHOUN CHRISTIAN SCHOOL',
  'CALHOUN SCHOOL',
  'CALIFORNIA CROSSPOINT HIGH SCHOOL',
  'CALIFORNIA KIDS CENTERS',
  'CALIFORNIA LUTHERAN HIGH SCHOOL',
  'CALIFORNIA RANCH SCHOOL',
  'CALIFORNIA UNIVERSITY FCE',
  'CALIFORNIA UNIVERSITY FCE HIGH SCHOOL',
  'CALLICORP DBA FRIENDS OF THE FAMILY CHILD CARE CEN',
  'CALMOUTIER',
  'CALUMET CHRISTIAN SCHOOL',
  'CALUSA PREPARATORY SCHOOL',
  'CALVARY ACADEMY',
  'CALVARY ACADEMY- THE',
  'CALVARY BAPTIST ACADEMY',
  'CALVARY BAPTIST CHRISTIAN ACADEMY',
  'CALVARY BAPTIST CHRISTIAN SCHOOL',
  'CALVARY BAPTIST CHURCH ACADEMY',
  'CALVARY BAPTIST CHURCH SCHOOL',
  'CALVARY BAPTIST KINDERGARTEN',
  'CALVARY BAPTIST KINDERGARTEN & PRESCHOOL',
  'CALVARY BAPTIST PRESCHOOL & KINDERGARTEN',
  'CALVARY BAPTIST SCHOOL',
  'CALVARY BAPTIST SCHOOLS',
  'CALVARY BAPTIST WEEKDAY MINISTRIES',
  'CALVARY BY THE SEA MONTESSORI SCHOOL',
  'CALVARY CHAPEL ACADEMY',
  'CALVARY CHAPEL CHRISTIAN ACADEMY',
  'CALVARY CHAPEL CHRISTIAN SCHOOL',
  'CALVARY CHAPEL CHRISTIAN SCHOOL OF CYPRESS',
  'CALVARY CHAPEL CHRISTIAN SCHOOL OF EL CENTRO',
  'CALVARY CHAPEL GREEN VALLEY CHRISTIAN ACADEMY',
  'CALVARY CHAPEL HIGH/MARANATHA CHRISTIAN ACADEMY',
  'CALVARY CHAPEL SCHOOL',
  'CALVARY CHILD DVLPMENT CENTER',
  'CALVARY CHRISTIAN',
  'CALVARY CHRISTIAN ACADEMY',
  'CALVARY CHRISTIAN ACADEMY & PRESCHOOL',
  'CALVARY CHRISTIAN HIGH SCHOOL',
  'CALVARY CHRISTIAN LEARNING ACADEMY',
  'CALVARY CHRISTIAN SCHOOL',
  'CALVARY CHRISTIAN SCHOOLS',
  'CALVARY CITY CHRISTIAN ACADEMY & PRESCHOOL',
  'CALVARY CLASSICAL ACADEMY',
  'CALVARY CLASSICAL SCHOOL',
  'CALVARY DAY SCHOOL',
  'CALVARY EARLY LEARNING CENTER',
  'CALVARY EDUCATION CENTER',
  'CALVARY EPISCOPAL SCHOOL',
  'CALVARY FELLOWSHIP MENNONITE',
  'CALVARY INDIAN MISSION SCHOOL',
  'CALVARY KIDS',
  'CALVARY KIDS LEARNING CENTER',
  'CALVARY LUTHERAN HIGH SCHOOL',
  'CALVARY LUTHERAN PRESCHOOL & KINDERGARTEN',
  'CALVARY LUTHERAN SCHOOL',
  'CALVARY MENNONITE SCHOOL',
  'CALVARY MURRIETA CHRISTIAN SCHOOLS',
  'CALVARY PRESCHOOL CENTER',
  'CALVARY RESURRECTION CHRISTIAN ACADEMY',
  'CALVARY ROAD BAPTIST ACADEMY',
  'CALVARY ROAD CHRISTIAN SCHOOL',
  'CALVARY SCHOOLS OF HOLLAND',
  'CALVARY SEVENTH-DAY ADVENTIST SCHOOL',
  'CALVARY TEMPLE CHRISTIAN ACADEMY',
  'CALVARY WEEKDAY SCHOOL',
  'CALVARY WEST CHRISTIAN SCHOOL',
  'CALVERT CATHOLIC SCHOOL',
  'CALVERT HALL COLLEGE HIGH SCHOOL',
  'CALVERT SCHOOL',
  'CALVIN CENTER SCHOOL',
  'CALVIN CHRISTIAN HIGH SCHOOL',
  'CALVIN CHRISTIAN SCHOOL',
  'CALVIN CHRISTIAN SCHOOL - BLAINE',
  'CALVIN CHRISTIAN SCHOOL - EDINA',
  'CALVIN HILL DAYCARE CENTER & KLF KINDERGARTEN',
  'CAMARILLO PROGRESSIVE MONTESSORI',
  'CAMAS CHRISTIAN ACADEMY',
  'CAMAS MONTESSORI SCHOOL',
  'CAMBRIA CENTER FOR THE GIFTED CHILD',
  'CAMBRIA COUNTY CHRISTIAN SCHOOL',
  'CAMBRIDGE ACADEMY',
  'CAMBRIDGE CHRISTIAN SCHOOL',
  'CAMBRIDGE FRIENDS SCHOOL',
  'CAMBRIDGE MONTESSORI SCHOOL',
  'CAMBRIDGE PREP ACADEMY',
  'CAMBRIDGE SCHOOL',
  'CAMBRIDGE SCHOOL OF CHICAGO',
  'CAMDEN CHRISTIAN ACADEMY',
  'CAMDEN MILITARY ACADEMY',
  'CAMDEN UNITED CHRISTIAN ACADEMY',
  'CAMELBACK CHRISTIAN SCHOOL',
  'CAMELBACK DESERT SCHOOL',
  'CAMELLIA BAPTIST WEM',
  'CAMELLIA WALDORF SCHOOL',
  'CAMELOT EDUCATION',
  'CAMELOT SCHOOL',
  'CAMELOT-NWCA',
  'CAMINO DE PAZ SCHOOL',
  'CAMP OLYMPIA COUNTRY DAY SCHOOL',
  'CAMPBELL HALL (EPISCOPAL)',
  'CAMPBELLSVILLE CHRISTIAN CHURCH CTR FOR EDUCATION',
  'CAMPERDOWN ACADEMY',
  'CAMPHILL SPECIAL SCHOOLS',
  'CAMPION ACADEMY',
  'CAMPUS KINDER HAUS',
  'CAMPUS MIDDLE SCHOOL FOR GIRLS',
  'CANAAN ACADEMY',
  'CANAANLAND CHRISTIAN SCHOOL',
  'CANANDAIGUA MONTESSORI SCHOOL',
  'CANDIL HALL EARLY CHILDHOOD EDUCATION',
  'CANDLELIGHT CHRISTIAN ACADEMY',
  'CANDY LANE CHILD CARE CENTER',
  'CANISIUS HIGH SCHOOL',
  'CANNON SCHOOL',
  'CANOE RIDGE AMISH SCHOOL',
  'CANON CHRISTIAN ACADEMY',
  'CANONGATE CATHOLIC HIGH SCHOOL',
  'CANOPE SCHOOL',
  'CANTALICIAN CENTER FOR LEARNING',
  'CANTERBURY PREPARATORY SCHOOL',
  'CANTERBURY SCHOOL',
  'CANTON COUNTRY DAY SCHOOL',
  'CANTON COUNTRY SCHOOL',
  'CANTON MONTESSORI SCHOOL',
  'CANTWELL SACRED HEART OF MARY',
  'CANYON CHRISTIAN SCHOOL',
  'CANYON CREEK DAY SCHOOL',
  'CANYON HEIGHTS ACADEMY',
  'CANYON OAKS SCHOOL',
  'CANYON RIDGE CHRISTIAN PREP',
  'CANYON VIEW ACADEMY',
  'CANYON VIEW SCHOOL',
  'CANYONCITO MONTESSORI SCHOOL & CHILD CARE CENTER',
  'CANYONVILLE ADVENTIST ELEMENTARY SCHOOL',
  'CANYONVILLE CHRISTIAN ACADEMY',
  'CAPE CHARLES CHRISTIAN SCHOOL',
  'CAPE CHRISTIAN ACADEMY',
  'CAPE CHRISTIAN SCHOOL',
  'CAPE COD ACADEMY',
  'CAPE CORAL CHRISTIAN SCHOOL',
  'CAPE FEAR ACADEMY',
  'CAPE FEAR CHRISTIAN ACADEMY',
  'CAPE HENRY COLLEGIATE SCHOOL',
  'CAPE TRINITY CATHOLIC SCHOOL',
  'CAPISTRANO VALLEY CHRISTIAN SCHOOLS',
  'CAPITAL ACADEMY',
  'CAPITAL CHRISTIAN SCHOOL',
  'CAPITAL CITY ADVENTIST CHRISTIAN SCHOOL',
  'CAPITAL CITY CHRISTIAN ACADEMY',
  'CAPITAL DAY SCHOOL',
  'CAPITAL INNOVATIONS ACADEMY',
  'CAPITAL MONTESSORI SCHOOL',
  'CAPITOL ACADEMY',
  'CAPITOL CHRISTIAN ACADEMY',
  'CAPITOL CITY BAPTIST SCHOOL',
  'CAPITOL CITY SEVENTH-DAY ADVENTIST SCHOOL',
  'CAPITOL SCHOOL OF AUSTIN',
  'CAPSTONE ACADEMY',
  'CAPTAIN GILMER CHRISTIAN SCHOOL',
  'CARBONDALE NEW SCHOOL',
  'CARDEN ACADEMY OF ALMADEN',
  'CARDEN ACADEMY OF BASIC EDUCATION',
  'CARDEN ACADEMY OF MAUI',
  'CARDEN CHRISTIAN ACADEMY CENTRAL',
  'CARDEN CONEJO SCHOOL',
  'CARDEN CONSERVATORY',
  'CARDEN COUNTRY SCHOOL',
  'CARDEN DAY SCHOOL',
  'CARDEN HALL',
  'CARDEN MEMORIAL SCHOOL',
  'CARDEN SCHOOL',
  'CARDEN SCHOOL OF FRESNO',
  'CARDEN SCHOOL OF SACRAMENTO',
  'CARDEN SCHOOL OF TEHACHAPI',
  'CARDEN WHITTIER SCHOOL',
  'CARDIGAN MOUNTAIN SCHOOL',
  'CARDINAL BERNARDIN EARLY CHILDHOOD CENTER',
  'CARDINAL CUSHING CENTERS',
  'CARDINAL GIBBONS HIGH SCHOOL',
  'CARDINAL HAYES HIGH SCHOOL',
  'CARDINAL HAYES SCHOOL FOR SPECIAL CHILDREN',
  'CARDINAL HICKEY ACADEMY',
  'CARDINAL JOHN FOLEY REGIONAL CA',
  'CARDINAL JOSEPH BERNARDIN CATHOLIC SCHOOL',
  'CARDINAL MAIDA ACADEMY',
  'CARDINAL MCCLOSKEY SCHOOL',
  'CARDINAL MONTESSORI SCHOOL',
  'CARDINAL MOONEY CATHOLIC',
  'CARDINAL MOONEY CATHOLIC HIGH SCHOOL',
  'CARDINAL MOONEY HIGH SCHOOL',
  'CARDINAL NEWMAN HIGH SCHOOL',
  'CARDINAL NEWMAN SCHOOL',
  "CARDINAL O'HARA HIGH SCHOOL",
  'CARDINAL RITTER COLLEGE PREP HIGH SCHOOL',
  'CARDINAL RITTER HIGH SCHOOL',
  'CARDINAL SHEHAN SCHOOL',
  'CARDINAL SPELLMAN HIGH SCHOOL',
  'CARDINAL STRITCH HIGH SCHOOL',
  'CARDINAL WING SCHOOL',
  'CARE ELEMENTARY SCHOOL',
  'CARE FOR KIDS',
  'CAREER PREP ACADEMY OF SOUTH',
  'CAREER PREP ACADEMY OF SOUTH FLORIDA',
  'CAREER PREP ACADEMY OF SOUTH FLORIDA KENDALL',
  'CARENCRO CATHOLIC SCHOOL',
  'CARES SCHOOL',
  'CARING HANDS MONTESSORI',
  'CARING HANDS MONTESSORI NORTH',
  'CARLETON PROJECT',
  'CARLISLE CHRISTIAN ACADEMY',
  'CARLISLE EARLY EDUCATION CENTER',
  'CARLISLE MONTESSORI SCHOOL OF BUCKHEAD',
  'CARLISLE SCHOOL',
  'CARLISLE VALLEY C/O FREDA MILLER',
  'CARLSBAD COUNTRY DAY SCHOOL',
  'CARLTHORP SCHOOL',
  'CARLTON AMISH SCHOOL',
  'CARLTON PALMS EDUCATIONAL CENTER',
  'CARMAN ADVENTIST SCHOOL',
  'CARMEL CATHOLIC HIGH SCHOOL',
  "CARMEL MONTESSORI ACADEMY & CHILDREN'S HOUSE",
  'CARNEGIE MELLON CYERT CENTER FOR EARLY EDUCATION',
  "CARNEGIE MELLON UNIV CHILDREN'S SCHOOL",
  'CARNEGIE SCHOOLS-RIVERSIDE',
  'CAROLINA ADVENTIST ACADEMY',
  'CAROLINA BAPTIST ACADEMY',
  'CAROLINA CHRISTIAN ACADEMY',
  'CAROLINA CHRISTIAN SCHOOL',
  'CAROLINA DAY SCHOOL',
  'CAROLINE EDUCATION COMMUNITY INTERNATIONAL PSA',
  'CARONDELET CATHOLIC',
  'CARONDELET HIGH SCHOOL',
  'CAROUSEL LEARNING ACADEMY',
  'CARPE DIEM ACADEMY',
  'CARPE DIEM ACADEMY HAMMOCKS',
  'CARR VALLEY AMISH SCHOOL',
  'CARRA ACADEMY',
  'CARRABASSETT VALLEY ACADEMY',
  'CARRIAGE HOUSE CHILDRENS CENTER',
  'CARRIE BRAZER CENTER FOR AUTISM',
  'CARRIERS OF LIGHT',
  'CARRIG MONTESSORI SCHOOL',
  'CARRINGTON ACADEMY WINDERMERE',
  'CARROLL ACADEMY',
  'CARROLL CHRISTIAN SCHOOLS',
  'CARROLL COUNTY CHRISTIAN ACADEMY',
  'CARROLL HIGH SCHOOL',
  'CARROLL LUTHERAN SCHOOL',
  'CARROLLTON SCHOOL OF THE SACRED HEART',
  'CARROLLTON SCHOOL-SACRED HEART',
  'CARROLLWOOD DAY SCHOOL',
  'CARSON CHRISTIAN SCHOOL',
  'CARSON LONG MILITARY ACADEMY',
  'CARTER CHRISTIAN ACADEMY',
  "CARTER'S CHRISTIAN ACADEMY",
  'CARVER ACADEMY',
  'CARY ACADEMY',
  'CARY CHRISTIAN SCHOOL',
  'CASA DE MONTESSORI',
  'CASA DEI BAMBINI',
  'CASA DEI BAMBINI MONTESSORI SCHOOL',
  'CASA DEL BAMBINI SCHOOL',
  'CASA DEL NINO BILINGUAL MONTESSORI SCHOOL',
  'CASA DI MIR MONTESSORI ELEMENTARY SCHOOL',
  'CASA DIA MONTESSORI',
  'CASA MONTESSORI',
  'CASA MONTESSORI ACADEMY',
  'CASA MONTESSORI CHILD DEVELOPMENT CENTER',
  'CASA MONTESSORI PALM COAST',
  'CASA MONTESSORI SCHOOL',
  'CASA NINOS SCHOOL OF MONTESSORI - EAST CAMPUS',
  'CASA PACIFICA NONPUBLIC SCHOOL',
  'CASADY SCHOOL',
  'CASAS CHRISTIAN SCHOOL',
  'CASCADE CANYON SCHOOL',
  'CASCADE CHRISTIAN - PUYALLUP ELEMENTARY',
  'CASCADE CHRISTIAN -JUNIOR HIGH/HIGH SCHOOL',
  'CASCADE CHRISTIAN SCHOOL MCALDER ELEMENTARY',
  'CASCADE CHRISTIAN SCHOOLS - FREDRICKSON ELEMENTARY',
  'CASCADE VISTA BAPTIST SCHOOL',
  'CASCADES ACADEMY',
  'CASCADES MONTESSORI MIDDLE',
  'CASCADIA MONTESSORI SCHOOL',
  'CASCADIA SCHOOL',
  'CASCADILLA SCHOOL',
  'CASCIA HALL PREPARATORY SCHOOL',
  'CASEY AMISH MENNONITE SCHOOL',
  'CASSATA HIGH SCHOOL',
  'CASSELMAN VALLEY SCHOOL',
  'CASTILE CHRISTIAN ACADEMY',
  'CASTLE ACADEMY',
  'CASTLE ELEMENTARY SCHOOL',
  'CASTLE ISLAND BILINGUAL MONTESSORI',
  'CASTLE MOUND MONTESSORI',
  'CASTLE ROCK CHRISTIAN ACADEMY',
  'CASTLEHILL COUNTRY DAY SCHOOL',
  'CASTOR RIDGE SCHOOL',
  'CATALYST PREPARATORY ACADEMY',
  'CATE SCHOOL',
  'CATHEDRAL',
  'CATHEDRAL ACADEMY',
  'CATHEDRAL ACADEMY AT POMPEI',
  'CATHEDRAL BAPTIST SCHOOL',
  'CATHEDRAL CARMEL SCHOOL',
  'CATHEDRAL CATHOLIC HIGH SCHOOL',
  'CATHEDRAL CHAPEL SCHOOL',
  'CATHEDRAL ELEMENTARY SCHOOL',
  'CATHEDRAL HIGH SCHOOL',
  'CATHEDRAL HILL MONTESSORI SCHOOL',
  'CATHEDRAL OF ANNUNCIATION SCHOOL',
  'CATHEDRAL OF FAITH CHRISTIAN SCHOOL',
  'CATHEDRAL OF ST RAYMOND SCHOOL',
  'CATHEDRAL OF THE HOLY SPIRIT SCHOOL',
  'CATHEDRAL OF THE PALMS CHRISTIAN SCHOOL',
  'CATHEDRAL OF THE RISEN CHRIST SCHOOL',
  'CATHEDRAL PARISH SCHOOL',
  'CATHEDRAL PREPARATORY SCHOOL',
  'CATHEDRAL PREPARATORY SCHOOL AND SEMINARY',
  'CATHEDRAL SCHOOL',
  'CATHEDRAL SCHOOL FOR BOYS',
  'CATHEDRAL SCHOOL OF ST MARY',
  'CATHOLIC ACADEMY OF BRIDGEPORT/ST RAPHAEL ACADEMY',
  'CATHOLIC ACADEMY WEST BUFFALO',
  'CATHOLIC CENTRAL HIGH SCHOOL',
  'CATHOLIC CENTRAL SCHOOL',
  'CATHOLIC EAST ELEMENTARY SCHOOL',
  'CATHOLIC HIGH POINT COUPEE',
  'CATHOLIC HIGH SCHOOL',
  'CATHOLIC HIGH SCHOOL FOR BOYS',
  'CATHOLIC HIGH SCHOOL OF KEARNEY',
  'CATHOLIC HIGH SCHOOL OF POINTE COUPEE',
  'CATHOLIC MEMORIAL',
  'CATHOLIC MEMORIAL HIGH SCHOOL',
  'CATHOLIC SCHOOLS OF FAIRBANKS',
  'CATS ACADEMY BOSTON',
  'CATTAIL PAROCHIAL SCHOOL',
  'CAVE CREEK MONTESSORI',
  'CAYUGA MENNONITE SCHOOL',
  'CB COMMUNITY SCHOOLS',
  'CCLC AT HERSHEY',
  'CCMC SCHOOL',
  'CCMT ROSARY CATHEDRAL CATHOLIC SCHOOL',
  'CCS Haryana Agricultural University',
  'CDM CHRISTIAN ELEMENTARY SCHOOL',
  'CDS-MELVIN J LARSON EDUCATION CENTER',
  'CDW AT PLAY CHILD CARE CENTER',
  'CEDAR CATHOLIC HIGH SCHOOL',
  'CEDAR CHRISTIAN SCHOOL',
  'CEDAR CREEK AMISH SCHOOL',
  'CEDAR CREEK CHRISTIAN SCHOOL',
  'CEDAR CREEK SCHOOL',
  'CEDAR CREST ACADEMY',
  'CEDAR CREST ACADEMY BELLEWOOD',
  'CEDAR FALLS SCHOOL',
  'CEDAR FOREST CHRISTIAN SCHOOL',
  'CEDAR FORK MENNONITE SCHOOL',
  'CEDAR GROVE AMISH PAROCHIAL SCHOOL',
  'CEDAR GROVE AMISH SCHOOL',
  'CEDAR GROVE CHRISTIAN ACADEMY',
  'CEDAR GROVE SCHOOL',
  'CEDAR HALL SCHOOL',
  'CEDAR HILL PREP SCHOOL',
  'CEDAR HILL PREPARTORY ACADEMY',
  'CEDAR HILLS BAPTIST CHRISTIAN SCHOOL',
  'CEDAR KNOLL PAROCHIAL',
  'CEDAR LAKE CHRISTIAN ACADEMY',
  'CEDAR LAKE ELEMENTARY SCHOOL',
  'CEDAR PARK CHRISTIAN SCHOOL - EVERETT CAMPUS',
  'CEDAR PARK CHRISTIAN SCHOOLS',
  'CEDAR RIDGE ACADEMY',
  'CEDAR RIDGE SCHOOL',
  'CEDAR RIVER MONTESSORI SCHOOL',
  'CEDAR ROAD CHRISTIAN ACADEMY',
  'CEDAR SPRINGS PAROCHIAL SCHOOL',
  'CEDAR SPRINGS SCHOOL',
  'CEDAR SPRINGS WALDORF SCHOOL',
  'CEDAR SPRINGS WEEKDAY SCHOOL',
  'CEDAR TREE CLASSICAL CHRISTIAN SCHOOL',
  'CEDAR TREE MONTESSORI',
  'CEDAR VALLEY CATHOLIC SCHOOLS',
  'CEDAR VALLEY CHRISTIAN SCHOOL',
  'CEDAR VALLEY SCHOOL',
  'CEDAR VIEW CHRISTIAN SCHOOL',
  'CEDAR VIEW SCHOOL',
  'CEDAR WOOD',
  'CEDARBROOK ADVENTIST CHRISTIAN',
  'CEDARCREEK SCHOOLHOUSE ACADEMY',
  'CEDARCREST SCHOOL',
  'CEDARHURST SCHOOL',
  'CEDARS OF LEBANON ACADEMY',
  'CEDARS PREPARATORY ACADEMY',
  'CEDARWOOD WALDORF SCHOOL',
  'CELANDINE LIFE-PREP ACADEMY INC',
  'CELEBRATION ACADEMY',
  'CELEBRATION BAPTIST KINDERSCHOOL',
  'CELEBRATION LUTHERAN SCHOOL',
  'CELERYVILLE CHRISTIAN SCHOOL',
  'CENTENNIAL ACADEMY',
  'CENTENNIAL BAPTIST SCHOOL',
  'CENTENNIAL MONTESSORI SCHOOL',
  'CENTENNIAL SCHOOL LEHIGH UNIV',
  'CENTER',
  'CENTER ACADEMY',
  'CENTER ACADEMY - MANDARIN',
  'CENTER ACADEMY - ORANGE PARK',
  'CENTER ACADEMY - RIVERVIEW',
  'CENTER ACADEMY - WATERFORD LAKES',
  'CENTER ACADEMY SMYRNA',
  'CENTER FOR ACHIEVEMENT',
  'CENTER FOR APPLIED BEHAVIORAL INSTRUCTION SCHOOL',
  'CENTER FOR CHILDREN TO HEAL AND ACHIEVE (CCHA)',
  'CENTER FOR EARLY EDUCATION',
  'CENTER FOR HEARING AND SPEECH',
  'CENTER FOR LEARNING INC',
  'CENTER FOR LEARNING UNLIMITED',
  'CENTER FOR SELF DEVELOPMENT',
  'CENTER FOR SPECTRUM SERVICES',
  'CENTER FOR TEACHING & LEARNING',
  'CENTER FOR TEACHING AND LEARNING',
  'CENTER FOR YOUNG CHILDREN',
  'CENTER HILL SCHOOL',
  'CENTER MONTESSORI SCHOOL',
  'CENTER OF LIFE ACADEMY',
  'CENTER PAROCHIAL SCHOOL',
  'CENTER PLACE RESTORATION SCHOOL',
  'CENTER RIDGE SCHOOL',
  'CENTER ROAD CHRISTIAN ACADEMY',
  'CENTER SCHOOL',
  'CENTER SQUARE AMISH SCHOOL',
  'CENTER VIEW AMISH SCHOOL',
  'CENTER VIEW PAROCIAL SCHOOL',
  'CENTERTOWN CHRISTIAN ACADEMY',
  'CENTERVIEW THERAPEUTIC SCHOOL',
  'CENTERVILLE AMISH SCHOOL',
  'CENTERVILLE LAYTON SCHOOL',
  'CENTRAL ACRES CHRISTIAN SCHOOL',
  'CENTRAL ARKANSAS CHRISTIAN SCHOOL',
  'CENTRAL BAPTIST ACADEMY',
  'CENTRAL BAPTIST CHRISTIAN ACADEMY',
  'CENTRAL BAPTIST CHRISTIAN SCHOOL',
  'CENTRAL BAPTIST SCHOOL',
  'CENTRAL CATHOLIC',
  'CENTRAL CATHOLIC HIGH SCHOOL',
  'CENTRAL CATHOLIC HIGH SCHOOL -SAN ANTONIO',
  'CENTRAL CATHOLIC JR SR HIGH SCHOOL',
  'CENTRAL CATHOLIC MIDDLE & HIGH SCHOOL',
  'CENTRAL CHRISTIAN ACADEMY',
  'CENTRAL CHRISTIAN SCHOOL',
  'CENTRAL CHRISTIAN SCHOOLS',
  'CENTRAL COAST INTERNATIONAL SCHOOL',
  'CENTRAL COLLEGE CHRISTIAN ACADEMY',
  'CENTRAL DE CHRISTIAN ACADEMY',
  'CENTRAL FELLOWSHIP CHRISTIAN ACADEMY',
  'CENTRAL HINDS ACADEMY',
  'CENTRAL HOLMES CHRISTIAN SCHOOL',
  'CENTRAL INDIANA TEEN CHALLENGE',
  'CENTRAL INSTITUTE FOR THE DEAF',
  'CENTRAL IOWA CHRISTIAN SCHOOL',
  'CENTRAL KANSAS CHRISTIAN ACADEMY',
  'CENTRAL KITSAP MONTESSORI',
  'CENTRAL LUTHERAN SCHOOL',
  'CENTRAL MAINE LEARNING CENTER',
  'CENTRAL MINNESOTA CHRISTIAN SCHOOL',
  'CENTRAL MONTESSORI ACADEMY',
  'CENTRAL MONTESSORI SCHOOL OF VIRGINIA',
  'CENTRAL OREGON INTERGOVRNMENTAL COUNCIL SKILLS LAB',
  'CENTRAL PARK CHRISTIAN SCHOOLS',
  'CENTRAL PRESBYTERIAN DAY SCHOOL',
  'CENTRAL PRESBYTERIAN PRESCHOOL',
  'CENTRAL PRIVATE SCHOOL',
  'CENTRAL SCHOOL CORPORATION',
  'CENTRAL TEXAS CHRISTIAN SCHOOL',
  'CENTRAL VALLEY CHRISTIAN MOLOKAN SCHOOL',
  'CENTRAL VALLEY CHRISTIAN SCHOOL',
  'CENTRAL VALLEY CHRISTIAN SCHOOLS',
  'CENTRAL WISCONSIN CHRISTIAN SCHOOLS PK-12',
  'CENTRALIA CHRISTIAN SCHOOL',
  'CENTRE COUNTY CHRISTIAN ACADEMY',
  'CENTRE SQUARE ACADEMY',
  'CENTREVILLE ACADEMY',
  'CENTRO ARMONIA SCHOOL',
  'CEREBRAL PALSY OF WESTCHESTER- INC',
  'CETYS Universidad',
  'CFA ACADEMY',
  'CFS CATHOLIC SCHOOL',
  'CHA',
  'CHABAD AADEMY OF ARTS & SCIENCES',
  'CHABAD ESFORMES HEBREW ACADEMY',
  'CHABAD HEBREW ACADEMY',
  'CHABAD JEWISH ACADEMY',
  'CHABAD LUBAVITCH OF STATEN ISLAND',
  'CHADDOCK',
  'CHADWICK SCHOOL',
  'CHALLENGER SCHOOL-HOLLADAY',
  'CHAMBERS ACADEMY',
  'CHAMBLEE METHODIST KINDERGARTEN',
  'CHAMINADE COLLEGE PREPARATORY HIGH SCHOOL',
  'CHAMINADE COLLEGE PREPARETORY',
  'CHAMINADE COLLEGE PREPATORY SCHOOL',
  'CHAMINADE JULIENNE HIGH SCHOOL',
  'CHAMINADE MADONNA COLLEGE PREPARATORY',
  'CHAMINADE UNIVERSITY MONTESSORI LAB SCHOOL-L ROBER',
  'CHAMPAGNAT CATHOLIC SCHOOL',
  'CHAMPION BAPTIST ACADEMY',
  'CHAMPION CHRISTIAN SCHOOL NORTH CAMPUS',
  'CHAMPION EDUCATIONAL CENTER',
  'CHAMPION KINDER INTERNATIONAL SCHOOL',
  'CHAMPIONS ACADEMY',
  'CHAMPIONS CHRISTIAN ACADEMY',
  'CHAMPLAIN VALLEY CHRISTIAN SCHOOL',
  'CHANCE AFTER CHANCE COLLEGE PREPARATORY',
  'CHANCELLOR ACADEMY',
  'CHANDLER CHRISTIAN ACADEMY',
  'CHANDLER CHRISTIAN SCHOOL',
  'CHANDLER SCHOOL',
  'CHANGING HEARTS BOARDING SCHOOL',
  'CHANGING LIVES ACADEMY INC.',
  'CHANGING LIVES CHRISTIAN ACADEMY',
  'CHANTY LEARNING CENTER INC/WE LOVE KIDS',
  'CHANUTE CHRISTIAN ACADEMY',
  'CHAPARRAL CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'CHAPEL HAVEN SCHOOL',
  'CHAPEL HILL ACADEMY',
  'CHAPEL HILL CHAUNCY HALL SCHOOL',
  'CHAPEL HILL CHRISTIAN SCHOOL',
  'CHAPEL HILL CHRISTIAN SCHOOL - GREEN CAMPUS',
  'CHAPEL SCHOOL',
  'CHAPELGATE CHRISTIAN ACADEMY',
  'CHAPIN SCHOOL',
  'CHAPMAN FARM SCHOOL',
  'CHAPTER 126 CLASSROOM',
  'CHARACTER COMMUNITY SERVICES',
  'CHARIS ACADEMY',
  'CHARIS CHRISTIAN ACADEMY',
  'CHARIS CLASSICAL ACADEMY',
  'CHARIS YOUTH CENTER',
  'CHARITY CHRISTIAN SCHOOL',
  'CHARLES ARMSTRONG SCHOOL',
  'CHARLES CHURN CHRISTIAN ACADEMY',
  'CHARLES E SMITH JEWISH DAY SCHOOL',
  'CHARLES F HAYDEN SCHOOL- BOYS & GIRLS VILLAGE',
  'CHARLES FINNEY SCHOOL (THE)',
  'CHARLES RIVER SCHOOL',
  'CHARLES TOWNE MONTESSORI SCHOOL',
  'CHARLESTON CATHOLIC HIGH SCHOOL',
  'CHARLESTON COLLEGIATE SCHOOL',
  'CHARLESTON DAY SCHOOL',
  'CHARLESTON MONTESSORI INC.',
  'CHARLESTON SDA SCHOOL',
  'CHARLESTOWN PLAYHOUSE',
  'CHARLIE KEYAN ARMENIAN COMMUNITY SCHOOL',
  'CHARLOTTE CATHOLIC HIGH SCHOOL',
  'CHARLOTTE CHRISTIAN SCHOOL',
  'CHARLOTTE COUNTRY DAY SCHOOL',
  'CHARLOTTE ISLAMIC ACADEMY',
  'CHARLOTTE JEWISH DAY SCHOOL',
  'CHARLOTTE LATIN SCHOOL',
  'CHARLOTTE LEADERSHIP ACADEMY',
  'CHARLOTTE MASON COMMUNITY SCHOOL',
  'CHARLOTTE MONTESSORI SCHOOL',
  'CHARLOTTE PREPARATORY SCHOOL',
  'CHARLOTTESVILLE CATHOLIC SCHOOL',
  'CHARLOTTESVILLE DAY SCHOOL',
  'CHARLOTTESVILLE WALDORF SCHOOL',
  'CHARM VIEW',
  'CHARTER OAK EDUCATION DBA STERLING EAST',
  'CHARTERHOUSE SCHOOL - EDINBURG',
  'CHARTWELL SCHOOL',
  'CHASE RANCH MONTESSORI SCHOOL',
  'CHASE RD SCHOOL',
  "CHASE'S PLACE",
  'CHATHAM ACADEMY',
  'CHATHAM DAY SCHOOL',
  'CHATHAM HALL',
  'CHATMANS EARLY CHRISTIAN ACADEMY',
  'CHATSWORTH HILLS ACADEMY',
  'CHATTAHOOCHEE CHRISTIAN SCHOOL',
  'CHATTANOOGA CHRISTIAN SCHOOL',
  'CHATTERBOX DAY SCHOOL',
  'CHEDER AT THE OHEL',
  'CHEDER CHABAD',
  'CHEDER CHABAD OF MONSEY',
  'CHEDER LUBAVITCH',
  'CHEDER LUBAVITCH HEBREW DAY SCHOOL',
  'CHEDER MENACHEM',
  'CHEERFUL HELPERS THARUPEUTIC SCHOOL',
  'CHELSEA HOUSE MONTESSORI PRESCHOOL/KDGN',
  'CHELSEA SCHOOL',
  'CHELTEN CHRISTIAN CRUSADE LITTLE PEOPLE DAY SCHOOL',
  'CHEMEKETA CHILD DEVELOPMENT CENTER',
  'CHEMUNG VALLEY MONTESSORI SCHOOL',
  'CHENAL VALLEY MONTESSORI SCHOOL',
  'CHEROKEE CHRISTIAN SCHOOLS',
  'CHEROKEE CREEK BOYS SCHOOL',
  'CHEROKEE ROSE CHRISTIAN SCHOOL',
  'CHERRY BLOSSOM MONTESSORI SCHOOL',
  'CHERRY GROVE PAROCHIAL SCHOOL',
  'CHERRY GULCH',
  'CHERRY HILL AMISH SCHOOL',
  'CHERRY HILL MONTESSORI SCHOOL',
  'CHERRY HILL SCHOOL',
  'CHERRY HILLS CHRISTIAN',
  'CHERRY LANE SCHOOL',
  'CHERRY RIDGE SCHOOL',
  'CHERRY VALLEY SCHOOL',
  'CHERRYTOWN SCHOOL',
  'CHERRYWOOD ACADEMY AND PRIVATE PRESCHOOL',
  'CHESAPEAKE ACADEMY',
  'CHESAPEAKE BAY ACADEMY',
  'CHESAPEAKE CHRISTIAN SCHOOL',
  'CHESAPEAKE MONTESSORI SCHOOL',
  'CHESS CHRISTIAN SCHOOL',
  'CHESTER RIVER ADVENTIST SCHOOL',
  'CHESTERBROOK ACADEMY',
  'CHESTERBROOK ACADEMY #1160',
  'CHESTERBROOK ACADEMY AT BIRKDALE',
  'CHESTERBROOK ACADEMY KIDS CAMPUS',
  'CHESTERBROOK ACADEMY#15',
  'CHESTERBROOK ACADEMY-NORTH RALEIGH',
  'CHESTERBROOK ACADEMY-PRESCHOOL AND ELEMENTARY',
  'CHESTERBROOK MONTESSORI SCHOOL',
  'CHESTERBROOK MONTESSORI SCHOOL-ARLINGTON FOREST',
  'CHESTERFIELD DAY SCHOOL',
  'CHESTERFIELD TOM THUMB DAYCARE CENTER',
  'CHESTERTON ACADEMY',
  'CHESTERTON ACADEMY OF HOLY FAMILY',
  'CHESTERTON MONTESSORI SCHOOL',
  'CHESTERTOWN CHRISTIAN ACADEMY',
  'CHESTNUT CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'CHESTNUT HILL ACADEMY',
  'CHESTNUT MONTESSORI SCHOOL',
  'CHESTNUT MOUNTAIN RANCH',
  'CHESTNUT RIDGE CHRISTIAN ACADEMY',
  'CHESTNUT RIDGE PAROCHIAL SCHOOL',
  'CHESTNUT RIDGE SCHOOL',
  'CHESTNUT RUN SCHOOL',
  'CHESTNUT STREET CHRISTIAN SCHOOL',
  'CHESTNUT VALLEY',
  'CHEVERUS CATHOLIC SCHOOL',
  'CHEVERUS HIGH SCHOOL',
  'CHI MONTESSORI ACADEMY INC',
  'CHIARAVALLE MONTESSORI SCHOOL',
  'CHICAGO FREE SCHOOL',
  'CHICAGO FRIENDS SCHOOL',
  'CHICAGO FUTABAKAI JAPANESE SCHOOL',
  'CHICAGO GRAMMAR SCHOOL',
  'CHICAGO HOPE ACADEMY',
  'CHICAGO JESUIT ACADEMY',
  'CHICAGO LIGHTHOUSE',
  'CHICAGO MONTESSORI',
  'CHICAGO SDA ELEMENTARY SCHOOL',
  'CHICAGO WALDORF SCHOOL',
  'CHICO OAKS ADVENTIST SCHOOL',
  'CHILD & FAMILY RESEARCH CENTER',
  'CHILD DEVELOPMENT CENTER AT BRISTOL MYERS SQUIBB',
  'CHILD ENRICHMENT CENTER',
  'CHILD GARDEN TOTAL ENVIRONMENT MONTESSORI SCHOOLS',
  'CHILD GUIDANCE EARLY LEARNING CENTER',
  'CHILD GUIDANCE ELEMENTARY ED',
  'CHILD OF GOD LUTHERAN SCHOOL',
  "CHILD'S VIEW MONTESSORI",
  "CHILD'S VIEW MONTESSORI SCHOOL",
  'CHILDHELP SCHOOL',
  'CHILDLIGHT MONTESSORI SCHOOL',
  'CHILDPEACE MONTESSORI SCHOOL',
  'CHILDREN FIRST LEARNING CENTER, INC.',
  'CHILDREN FIRST MONTESSORI',
  'CHILDREN IN MOTION',
  'CHILDREN OF AMERICA WARMINSTER',
  'CHILDREN OF MARY NURSERY & KINDERGARTEN',
  'CHILDREN OF PEACE SCHOOL',
  'CHILDREN S HOUSE MONTESSORI',
  'CHILDREN S RAINBOW DAY SCHOOL',
  "CHILDREN'S ACADEMY",
  "CHILDREN'S ACADEMY OF SUCCESS",
  "CHILDREN'S CENTER",
  "CHILDREN'S CENTER FOR NEURODEVELOPMENTAL STUDIES",
  "CHILDREN'S CHOICE EDUCARE",
  "CHILDREN'S COMMUNITY SCHOOL",
  "CHILDREN'S CORNER AT BRIDGEWATER UMC",
  "CHILDREN'S COURTYARD",
  "CHILDREN'S DAY MONTESSORI SCHOOL",
  "CHILDREN'S DAY SCHOOL",
  "CHILDREN'S DISCOVERY",
  "CHILDREN'S GARDEN MONTESSORI",
  "CHILDREN'S GARDEN MONTESSORI SCHOOL OF POULSBO",
  "CHILDREN'S GROWING CENTER-FIRST UNITED METHODIST",
  "CHILDREN'S HOME DIAGNOSTIC CENTER SCHOOL",
  "CHILDREN'S HOME OF WYOMING CONFERENCE",
  "CHILDREN'S HOME SOCIETY LOVING SCHOOL",
  "CHILDREN'S HOURS SCHOOL",
  "CHILDREN'S HOUSE",
  "CHILDREN'S HOUSE MONTESSORI",
  "CHILDREN'S HOUSE MONTESSORI SCHOOL",
  "CHILDREN'S HOUSE MONTESSORI SCHOOL OF RESTON",
  "CHILDREN'S HOUSE OF BOCA RATON",
  "CHILDREN'S HOUSE OF BUCKS COUNTY",
  "CHILDREN'S HOUSE OF DURANGO",
  "CHILDREN'S HOUSE OF MONTESSORI",
  "CHILDREN'S HOUSE OF NASHVILLE",
  "CHILDREN'S HOUSE OF NORMAN",
  "CHILDREN'S HOUSE OF THE WINDSORS",
  "CHILDREN'S INSTITUTE FOR LEARNING DIFFERENCES",
  "CHILDREN'S KINGDOM ENRICHMENT CENTER",
  "CHILDREN'S LEARNING PROGRAM",
  "CHILDREN'S MANOR MONTESSORI",
  "CHILDREN'S MANOR MONTESSORI SCHOOL & DAYCARE",
  "CHILDREN'S MONTESSORI ACADEMY",
  "CHILDREN'S MONTESSORI SCHOOL",
  "CHILDREN'S MONTESSORI SCHOOL OF GEORGETOWN",
  "CHILDREN'S PARADISE LEARNING CENTER",
  "CHILDREN'S PARADISE LEARNING CENTER I #4",
  "CHILDREN'S PARK LEARNING CENTER",
  "CHILDREN'S PLACE LEARNING CENTER",
  "CHILDREN'S SCHOOL OF OAK PARK",
  "CHILDREN'S UNIT FOR TREATMENT & EVALUATION",
  "CHILDREN'S VILLAGE AT DOYLESTOWN HOSPITAL",
  "CHILDREN'S VILLAGE CHRISTIAN SCHOOL",
  "CHILDREN'S WORKSHOP",
  "CHILDREN'S WORLD BILINGUAL MONTESSORI",
  "CHILDREN'S WORLD DAYCARE CENTER",
  'CHILDRENS CENTER OF MONMOUTH COUNTY',
  'CHILDRENS CHRISTIAN LEARNING CENTER',
  'CHILDRENS CHRISTIAN SHOOLHOUSE',
  'CHILDRENS CLASSIC',
  'CHILDRENS CLASSIC #2',
  'CHILDRENS COMMUNITY SCHOOL',
  'CHILDRENS CREATIVE LEARNING CENTER AT FIU',
  'CHILDRENS CREATIVE/PERFORMING ARTS ACAD SAN DIEGO',
  'CHILDRENS DEVELOPMENT CENTER OF BOZEMAN',
  'CHILDRENS DISCOVERY CENTER',
  'CHILDRENS DISCOVERY SCHOOL',
  'CHILDRENS ENRICHMENT CENTER/ARTES DE MONTESSORI',
  'CHILDRENS GARDEN MONTESSORI',
  'CHILDRENS GATE MONTESSORI SCHOOL',
  'CHILDRENS HARBOR MONTESSORI SCHOOL',
  'CHILDRENS HOME OF STOCKTON',
  'CHILDRENS HOUSE 1',
  'CHILDRENS HOUSE MONTESSORI',
  'CHILDRENS HOUSE MONTESSORI OF COLUMBIA',
  'CHILDRENS HOUSE MONTESSORI SCHOOL',
  'CHILDRENS HOUSE MONTESSORI SCHOOL,INC.',
  'CHILDRENS HOUSE OF OLD TOWN MONTESSORI SCHOOL',
  'CHILDRENS HOUSE OF UNION',
  'CHILDRENS LEARNING CENTER',
  'CHILDRENS LEARNING PATH MONTESSORI',
  'CHILDRENS LEARNING WORLD',
  'CHILDRENS LIGHTHOUSE LEARNING CENTER',
  'CHILDRENS MEETING HOUSE',
  'CHILDRENS MONTESSORI',
  'CHILDRENS MONTESSORI CENTER',
  'CHILDRENS MONTESSORI CENTER OF VIA VERDE',
  'CHILDRENS OWN SCHOOL',
  'CHILDRENS PALACE',
  'CHILDRENS PARADISE LEARNING CENTER #3 UNIT 1',
  'CHILDRENS PLACE MONTESSORI',
  'CHILDRENS PRESCHOOL LEARNING CENTER',
  'CHILDRENS RESOURCES',
  'CHILDRENS SCHOOL AT ST JOHNS',
  'CHILDRENS TECHNOLOGICAL ACADEMY',
  'CHILDRENS UNIVERSITY',
  'CHILDRENS WORKSHOP MONTESSORI',
  'CHILDRENVILLE LEARING CENTER',
  'CHILDS WORLD SCHOOL-ENCINO',
  'CHILDTIME',
  "CHILDTIME CHILDREN'S CENTER",
  'CHILDTOWNE MONTESSORI SCHOOL',
  'CHILDWAY',
  'CHILDWORKS INC.',
  'CHILEDA',
  'CHILI VALLEY SCHOOL',
  "CHILREN'S HABILITATION CENTER",
  'CHILTON CHRISTIAN ACADEMY',
  'CHINESE AMERICAN INTERNATIONAL SCHOOL',
  'CHINESE CHRISTIAN SCHOOLS',
  'CHINO CHILD DEVELOPMENT CENTER',
  'CHINO MONTESSORI SCHOOL',
  'CHINOOK SPIRIT CHILDRENS ACADEMY',
  'CHINQUAPIN PREPARATORY SCHOO',
  'CHISAGO LAKES BAPTIST SCHOOL',
  'CHISHOLM TRAIL ACADEMY',
  'CHOATE ROSEMARY HALL',
  'CHOCHMA REISHIS',
  'CHOFETZ CHAIM OF LOS ANGELES',
  'CHOICE ACADEMY',
  'CHOICES LEADERSHIP ACADEMY',
  'CHOP POINT SCHOOL',
  'CHOR DAY ACADEMY',
  'CHOSEN GENERATION CHRISTIAN ACADEMY',
  'CHOWAN MULTI-PURPOSE JUVENILE SCHOOL',
  'CHOWCHILLA SDA SCHOOL',
  'CHRIST ACADEMY',
  'CHRIST ALONE LUTHERAN SCHOOL',
  'CHRIST CATHEDRAL ACADEMY',
  'CHRIST CENTERED ACADEMICS',
  'CHRIST CENTRAL SCHOOL',
  'CHRIST CHAPEL ACADEMY',
  'CHRIST CHILD ACADEMY',
  'CHRIST CHILDREN ACADEMY',
  "CHRIST CHILDREN'S ACADEMY - SITE 1",
  'CHRIST CHURCH DAY SCHOOL',
  'CHRIST CHURCH EPISCOPAL PRESCHOOL',
  'CHRIST CHURCH PRESCHOOL AND KINDERGARTEN',
  'CHRIST CHURCH SCHOOL',
  'CHRIST CLASSICAL ACADEMY',
  'CHRIST COMM LUTHERAN SCHOOL',
  'CHRIST COMMUNITY LUTHERAN SCHOOL',
  'CHRIST COMMUNITY SCHOOL',
  'CHRIST COVENANT SCHOOL',
  'CHRIST ELEMENTARY SCHOOL',
  'CHRIST EPISCOPAL PRESCHOOL',
  'CHRIST EPISCOPAL SCHOOL',
  'CHRIST FELLOWSHIP ACADEMY',
  'CHRIST LIGHT OF THE NATIONS CATHOLIC SCHOOL',
  'CHRIST LUTHERAN CHILD DEVELOPMENT CENTER',
  'CHRIST LUTHERAN CHRISTIAN DAY SCHOOL',
  'CHRIST LUTHERAN CHURCH & SCHOOL',
  'CHRIST LUTHERAN EARLY CHILDHOOD CENTER',
  'CHRIST LUTHERAN HIGH SCHOOL',
  'CHRIST LUTHERAN PRESCHOOL AND KINDERGARTEN',
  'CHRIST LUTHERAN SCHOOL',
  'CHRIST MEMORIAL LUTHERAN SCHOOL',
  'CHRIST METHODIST DAY SCHOOL',
  'CHRIST OUR KING-STELLA MARIS SCHOOL',
  'CHRIST OUR REDEEMER LUTHERAN SCHOOL',
  'CHRIST OUR ROCK LUTHERAN HIGH SCHOOL',
  'CHRIST OUR SAVIOR CATHOLIC SCHOOL',
  'CHRIST OUR SAVIOR LUTHERAN HIGH SCHOOL',
  'CHRIST PRESBYTERIAN ACADEMY',
  'CHRIST PRINCE OF PEACE SCHOOL',
  'CHRIST SCHOOL',
  'CHRIST SCHOOL LLC',
  'CHRIST TEMPLE CHRISTIAN ACADEMY',
  'CHRIST THE CORNERSTONE ACADEMY',
  'CHRIST THE CORNERSTONE LUTHERAN ACADEMY',
  'CHRIST THE DIVINE TEACHER SCHOOL',
  'CHRIST THE KING CATHEDRAL SCHOOL',
  'CHRIST THE KING CATHOLIC HIGH SCHOOL',
  'CHRIST THE KING CATHOLIC SCHOOL',
  'CHRIST THE KING CHILD DEVELOPMENT CTR & KINDERG',
  'CHRIST THE KING CHRISTIAN ACADEMY',
  'CHRIST THE KING ELEMENTARY SCHOOL',
  'CHRIST THE KING HIGH SCHOOL',
  'CHRIST THE KING LUTHERAN PRESCHOOL & KINDERGARTEN',
  'CHRIST THE KING LUTHERAN SCHOOL',
  'CHRIST THE KING PREPARATORY ACADEMY',
  'CHRIST THE KING PREPATORY SCHOOL',
  'CHRIST THE KING REGIONAL SCHOOL',
  'CHRIST THE KING ROMAN CATHOLIC SCHOOL',
  'CHRIST THE KING SCHOOL',
  'CHRIST THE REDEEMER',
  'CHRIST THE TEACHER',
  'CHRIST THE TEACHER CATHOLIC SCHOOL',
  'CHRIST TRINITY CHRISTIAN ACADEMY',
  "CHRIST'S CHURCH ACADEMY",
  "CHRIST'S CHURCH CHRISTIAN ACADEMY",
  "CHRIST'S GREENFIELD LUTHERAN SCHOOL",
  "CHRIST'S HOUSEHOLD OF FAITH",
  "CHRIST'S LEGACY ACADEMY",
  'CHRIST-MAR SCHOOL',
  "CHRIST-ST JOHN'S LUTHERAN SCHOOL",
  'CHRIST-ST PETER LUTHERAN SCHOOL',
  'CHRISTCHURCH SCHOOL',
  'CHRISTI ACADEMY',
  'CHRISTIAM CENTRAL ACADEMY',
  'CHRISTIAN ACADEMY',
  'CHRISTIAN ACADEMY IN THE SMOKIES',
  'CHRISTIAN ACADEMY OF AMERICA',
  'CHRISTIAN ACADEMY OF CARROLLTON',
  'CHRISTIAN ACADEMY OF GREATER ST LOUIS',
  'CHRISTIAN ACADEMY OF HOPE',
  'CHRISTIAN ACADEMY OF INDIANA',
  'CHRISTIAN ACADEMY OF KNOXVILLE',
  'CHRISTIAN ACADEMY OF LAWRENCEBURG',
  'CHRISTIAN ACADEMY OF LOUISVILLE - SOUTHWEST',
  'CHRISTIAN ACADEMY OF LOUISVILLE ENGLISH CAMPUS',
  'CHRISTIAN ACADEMY OF MADISON',
  'CHRISTIAN ACADEMY OF MYRTLE BEACH',
  'CHRISTIAN ACADEMY OF OAK RIDGE',
  'CHRISTIAN ACADEMY OF PRESCOTT',
  'CHRISTIAN ACADEMY OF SAN ANTONIO',
  'CHRISTIAN ACADEMY OF THE CUMBERLANDS',
  'CHRISTIAN ACADEMY OF WESTERN NEW YORK',
  'CHRISTIAN BROTHERS ACADEMY',
  'CHRISTIAN BROTHERS COLLEGE HIGH SCHOOL',
  'CHRISTIAN BROTHERS HIGH SCHOOL',
  'CHRISTIAN BROTHERS SCHOOL',
  'CHRISTIAN CENTER SCHOOL',
  'CHRISTIAN CHAPEL ACADEMY',
  'CHRISTIAN CHILD DEVELOPMENT CENTER',
  'CHRISTIAN CLASSICAL ACADEMY',
  'CHRISTIAN COLLEGIATE ACADEMY',
  'CHRISTIAN COMM SCHOOL - EAST CAMPUS',
  'CHRISTIAN COMMUNITY SCHOOL',
  "CHRISTIAN CORNER CHILDREN'S CENTER",
  'CHRISTIAN COTTAGE PREP',
  'CHRISTIAN CROSSINGS ACADEMY',
  'CHRISTIAN EDUCATION LEADERSHIP ACADEMYCELA',
  'CHRISTIAN ELEMENTARY SCHOOL - WEST CAMPUS',
  'CHRISTIAN ELEMENTARY SCHOOL-EAST CAMPUS',
  'CHRISTIAN ENCOUNTER HIGH SCHOOL',
  'CHRISTIAN FAITH ACADEMY',
  'CHRISTIAN FAITH SCHOOL',
  'CHRISTIAN FAMILY LEARNING CENTER',
  'CHRISTIAN FAMILY MONTESSORI',
  'CHRISTIAN FELLOWSHIP ACADEMY',
  'CHRISTIAN FELLOWSHIP SCHOOL',
  'CHRISTIAN HERITAGE ACADEMY',
  'CHRISTIAN HERITAGE CLASSICAL SCHOOL',
  'CHRISTIAN HERITAGE SCHOOL',
  'CHRISTIAN HOME ACADEMY',
  'CHRISTIAN HOSPITAL CHILD DEVELOPMENT CENTER',
  'CHRISTIAN LEARNING CENTER',
  'CHRISTIAN LIFE ACADEMY',
  'CHRISTIAN LIFE CENTER ACADEMY',
  'CHRISTIAN LIFE SCHOOL',
  'CHRISTIAN LIGHT SCHOOL',
  'CHRISTIAN MISSION LEARNING CENTER II',
  'CHRISTIAN MONTESSORI ACADEMY',
  'CHRISTIAN MONTESSORI SCHOOL OF ANN ARBOR',
  'CHRISTIAN PRESCHOOL & KINDERGARTEN PROGRAM',
  'CHRISTIAN S T E M SCHOLARS',
  'CHRISTIAN SCHOOL OF NORTHWEST HOUSTON',
  'CHRISTIAN SCHOOL OF YORK',
  'CHRISTIAN SCHOOLS OF EL PASO',
  'CHRISTIAN STAR ACADEMY',
  'CHRISTIAN TRADITIONAL SCHOOL',
  'CHRISTIAN WORSHIP CENTER ACADEMY',
  'CHRISTINA SEIX ACADEMY',
  'CHRISTOPHER ACADEMY',
  'CHRISTOPHER COLUMBUS HIGH SCHOOL',
  'CHRYSALIS ACAD DAY SCHOOL FOR CHILDREN WITH AUTISM',
  'CHRYSALIS SCHOOL',
  'CHUBB HOLLOW MENNONITE SCHOOL',
  'CHURCH ALIVE CHRISTIAN SCHOOL',
  'CHURCH HILL ACADEMY',
  'CHURCH LANE AMISH SCHOOL',
  'CHURCH OF GOD OF CHICAGO',
  'CHURCH OF SCIENTOLOGY CADET SCHOOL',
  'CHURCH OF THE REDEEMER CHRISTIAN SCHOOL',
  'CHURCH OF THE REDEEMER PARISH DAY SCHOOL',
  'CHURCHILL ACADEMY',
  'CHURCHILL CENTER & SCHOOL',
  'CHUV - University Hospital Lausanne',
  'CICERO SDA ELEMENTARY',
  'CINCINNATI CHRISTIAN SCHOOLS',
  'CINCINNATI HEBREW DAY SCHOOL',
  'CINCINNATI HILLS CHRISTIAN ACAD-OTTO ARMLEDER ELEM',
  'CINCINNATI HILLS CHRISTIAN MIDDLE',
  'CINCINNATI WALDORF SCHOOL',
  'CINCO RANCH MONTESSORI',
  'CINDERELLA SCHOOL',
  'CINNAMON STICKS LEARNING CENTER',
  'CIRCLE CHRISTIAN SCHOOL',
  'CISTERCIAN PREPARATORY SCHOOL',
  'CITI CHRISTIAN ACADEMY',
  'CITI FAMILY CENTER',
  'CITIZEN LEARNING ACADEMY',
  'CITIZENS CHRISTIAN ACADEMY',
  "CITIZENS' HIGH SCHOOL",
  'CITRUS HEIGHTS ACADEMY',
  'CITRUS PARK CHRISTIAN SCHOOL',
  'CITY AND COUNTRY SCHOOL',
  'CITY BAPTIST SCHOOLS',
  'CITY CHURCH ACADEMY',
  'CITY ELEMENTARY',
  'CITY GARDEN GRADE SCHOOL',
  'CITY GATE SCHOOL',
  'CITY KIDS CHRISTIAN SCHOOL',
  'CITY LIGHT CHRISTIAN SCHOOL',
  'CITY OF DHARMA REALM INSTIL GOODNESS EL & DEV',
  'CITY OF FAITH CHRISTIAN SCHOOL',
  'CITY OF LAKES WALDORF SCHOOL',
  'CITY SCHOOL',
  'CITY TREE CHRISTIAN SCHOOL',
  'CITYGATE SCHOOL OF ADVANCED LEARNING/PREPARATORY',
  'CLAIBORNE ACADEMY',
  'CLAIBORNE CHRISTIAN',
  'CLAIRBOURN SCHOOL',
  'CLARA FIELDS SCHOOL OF EXCELLENCE CFSE',
  'CLARA MOHAMMED SCHOOL INC.',
  'CLARA MOHAMMED SCHOOL OF MIAMI',
  'CLARA MUHAMMAD SCHOOL',
  'CLARA MUHAMMAD SCHOOL OF MASJID KHALIFAH',
  'CLARA MUMAMMAD SCHOOL',
  'CLARE WOODS ACADEMY',
  'CLAREMONT CHRISTIAN ACADEMY',
  'CLAREMORE CHRISTIAN SCHOOL',
  'CLARENDON HALL SCHOOL',
  'CLARINDA ACADEMY',
  'CLARINDA LUTHERAN SCHOOL',
  'CLARION CENTER SCHOOL',
  'CLARK COUNTY EARLY LEARNING CENTER',
  'CLARK FORK SCHOOL',
  'CLARK SCHOOL',
  'CLARK VALLEY',
  'CLARK VALLEY SCHOOL',
  'CLARKE SCHOOLS FOR HEARING & SPEECH -JACKSONVILLE',
  'CLARKE SCHOOLS FOR HEARING AND SPEECH',
  'CLARKS RUN SCHOOL',
  'CLARKSVILLE CHRISTIAN SCHOOL',
  'CLASS ACADEMY',
  'CLASSICAL CHRISTIAN ACADEMY',
  'CLASSICAL CHRISTIAN SCHOOL FOR THE ARTS',
  'CLASSICAL SCHOOL OF WICHITA',
  'CLAY CHRISTIAN ACADEMY',
  'CLAY COUNTY CHRISTIAN ACADEMY',
  'CLAY MEADOW SCHOOL',
  'CLAY MONTESSORI SCHOOL OF MAIN STREET CARMEL',
  'CLAY RIDGE SCHOOL',
  'CLAY ROAD BAPTIST SCHOOL',
  'CLAY-PLATTE MONTESSORI SCHOOL',
  'CLAYS MILL ROAD CHRISTIAN ACADEMY',
  'CLAYTON-BRADLEY ACADEMY',
  'CLEAR CREEK CHRISTIAN SCHOOL',
  'CLEAR LAKE CHRISTIAN SCHOOL',
  'CLEAR LAKES CHRISTIAN ACADEMY',
  'CLEAR SPRING SCHOOL',
  'CLEAR SPRINGS',
  'CLEAR VIEW SCHOOL',
  'CLEAR VIEW SCHOOL DAY TREATMENT',
  'CLEARFIELD PAROCHIAL SCHOOL',
  'CLEARLY KIDS CHRISTIAN ACADEMY',
  'CLEARVIEW',
  'CLEARVIEW AMISH SCHOOL',
  'CLEARVIEW HOLLOW',
  'CLEARVIEW MENNONITE SCHOOL',
  'CLEARVIEW SCHOOL',
  'CLEARVIEW SUDBURY SCHOOL',
  'CLEARWATER ACADEMY',
  'CLEARWATER ACADEMY INTERNATIONAL',
  'CLEARWATER CENTRAL CATHOLIC HIGH SCHOOL',
  'CLEARWAY SCHOOL',
  'CLEARY SCHOOL FOR THE DEAF',
  'CLEBURNE ADVENTIST CHRISTIAN SCHOOL',
  'CLEBURNE CHRISTIAN ACADEMY',
  'CLEBURNE COUNTY CHRISTIAN SCHOOL (SORRY MISSED FIR',
  'CLELIAN HEIGHTS SCHOOL FOR EXCEPTIONAL CHILDREN',
  'CLEMENTINE MONTESSORI',
  'CLEMENTS AMISH SCHOOL',
  'CLEMSON MONTESSORI SCHOOL',
  'CLEOPHAS OLIVER LEARNING ACADEMY',
  'CLEVELAND ACADEMY',
  'CLEVELAND AMISH SCHOOL',
  'CLEVELAND CENTRAL CATHOLIC HIGH SCHOOL',
  'CLEVELAND CHRISTIAN SCHOOL',
  'CLEVELAND CORRECTIONAL FACILITY',
  'CLEVELAND MONTESSORI',
  'CLEWISTON CHRISTIAN SCHOOL',
  'CLIFF VALLEY SCHOOL',
  'CLIFTON CHEDER, INC.',
  'CLIFTON CHRISTIAN ACADEMY',
  'CLINICAL DAY SCHOOL - MANCHESTER MEMORIAL HOSPITAL',
  'CLINTON CENTER AMISH',
  'CLINTON CHRISTIAN ACADEMY',
  'CLINTON CHRISTIAN SCHOOL',
  'CLONLARA SCHOOL',
  'CLOUD OF LIGHT CHRISTIAN ACADEMY',
  'CLOVER HILL SCHOOL',
  'CLOVER PASS CHRISTIAN SCHOOL',
  'CLOVER TRINITY LUTHERAN SCHOOL',
  'CLOVERDALE CHRISTIAN DS',
  'CLOVERDALE SCHOOL',
  'CLOVERLEAF SCHOOL',
  'CLOVIS CHRISTIAN SCHOOLS',
  'CLOVIS CHRISTIAN SCHOOLS, LLC',
  'CLUB MED ACADEMIES',
  'CLUBHOUSE CHILD CARE INC',
  'CLYDE MENNONITE PAROCHIAL SCHOOL',
  'CMJ University',
  'CNS/PATHWAYS ACADEMY',
  'COAL VALLEY SCHOOL',
  'COAST EPISCOPAL SCHOOL',
  'COASTAL CHRISTIAN HIGH SCHOOL',
  'COASTAL CHRISTIAN PREPARATORY SCHOOL',
  'COASTAL CHRISTIAN SCHOOL',
  'COASTAL LEARNING CENTER - MONMOUTH',
  'COASTAL LEARNING CENTER ATLANTIC',
  'COASTAL MIDDLE & SR HIGH SCHOOL',
  'COASTLINE CHRISTIAN ACADEMY',
  'COBB MEADOW SCHOOL',
  'COBB SCHOOL',
  'COCONUT GROVE MONTESSORI SCHOOL - MAIN CAMPUS',
  'COCOONS',
  "COEUR D'ALENE CHRISTIAN SCHOOL",
  'COFFEE RUN SCHOOL',
  'COFFROATH SCHOOL',
  'COGGIN MEMORIAL SCHOOL',
  'COIC - BEND',
  'COIC - LA PINE',
  'COIC - PRINEVILLE',
  'COIC -REDMOND',
  'COJO OF FLATBUSH',
  'COLD SPRING SCHOOL',
  'COLD SPRINGS MENNONITE SCHOOL',
  'COLE HARRINGTON SCHOOL AGE ENRICHMENT',
  'COLE VALLEY CHRISTIAN SCHOOL',
  'COLE VALLEY CHRISTIAN SCHOOLS',
  'COLLEGE DRIVE SDA CHRISTIAN SCHOOL',
  'COLLEGE HEIGHTS CHRISTIAN SCHOOL',
  'COLLEGE HEIGHTS KINDERGARTEN',
  'COLLEGE OF ST CATHERINE UNIVERSITY EARLY CHILDHOOD',
  'COLLEGE PARK CHRISTIAN ACADEMY',
  'COLLEGE PARK KIDDIE KAMPUS',
  'COLLEGE PARK NURSERY SCHOOL',
  'COLLEGE PREPARATORY SCHOOL OF AMERICA',
  'COLLEGE VIEW ACADEMY',
  'COLLEGEDALE ACADEMY',
  'COLLEGEDALE ADVENTIST MIDDLE SCHOOL',
  'COLLEGIATE SCHOOL',
  'COLLEGIATE SCHOOL OF MEMPHIS',
  'COLLETON PREPARATORY ACADEMY',
  'COLLIER SCHOOL',
  'COLLIN COUNTY CORNERSTONE CHRISTIAN ACADEMY',
  'COLLINS HILL CHRISTIAN SCHOOL',
  'COLONIAL AMISH SCHOOL',
  'COLONIAL CHRISTIAN ACADEMY',
  'COLONIAL CHRISTIAN SCHOOL',
  'COLONIAL HILLS CHRISTIAN ACADEMY',
  'COLONIAL HILLS UNITED METHODIST SCHOOL',
  'COLORADO ACADEMY',
  'COLORADO HERITAGE EDUCATION SCHOOL SYSTEM',
  'COLORADO INTERNATIONAL SCHOOL',
  'COLORADO ROCKY MOUNTAIN SCHOOL',
  'COLORADO SPRINGS CHRISTIAN SCHOOL',
  'COLORADO SPRINGS CHRISTIAN SCHOOLS',
  'COLORADO TIMBERLINE ACADEMY',
  'COLQUITT CHRISTIAN ACADEMY',
  'COLUMBIA ACADEMY',
  'COLUMBIA ACADEMY AT SPRING HILL',
  'COLUMBIA ADVENTIST ACADEMY',
  'COLUMBIA CHRISTIAN SCHOOL',
  'COLUMBIA CHRISTIAN SCHOOLS',
  'COLUMBIA COUNTY CHRISTIAN SCHOOL',
  'COLUMBIA INDEPENDENT SCHOOL',
  'COLUMBIA LUTHERAN SCHOOL',
  'COLUMBIA MONTESSORI SCHOOL',
  'COLUMBIA RIVER CHRISTIAN ACADEMY',
  'COLUMBINE CHRISTIAN SCHOOL',
  'COLUMBUS ADVENTIST ACADEMY',
  'COLUMBUS CATHOLIC MIDDLE & HIGH SCHOOL',
  'COLUMBUS CHRISTIAN SCHOOL',
  'COLUMBUS GIRLS ACADEMY',
  'COLUMBUS MONTESSORI EDUCATION CENTER',
  'COLUMBUS SCHOOL FOR GIRLS',
  'COLUMBUS TORAH ACADEMY',
  'COLVILLE VALLEY JUNIOR ACADEMY',
  'COMMISSION LEADERSHIP ACADEMY',
  'COMMON THREADS FAMILY RESOURCE CENTER',
  'COMMONWEALTH ACADEMY',
  'COMMUNION OF SAINTS SCHOOL',
  'COMMUNITY ACADEMIC PREP',
  'COMMUNITY ALTERNATIVE RENEWAL EXPERIENCE',
  'COMMUNITY BAPTIST ACADEMY',
  'COMMUNITY BAPTIST CHRISTIAN SCHOOL',
  'COMMUNITY BAPTIST SCHOOL',
  'COMMUNITY CHILD GUIDANCE CLINIC',
  'COMMUNITY CHRISTIAN ACADEMY',
  'COMMUNITY CHRISTIAN ACADEMY & PRESCHOOL',
  'COMMUNITY CHRISTIAN ACADEMY/NORTHWEST CHRISTIAN HS',
  'COMMUNITY CHRISTIAN DAY SCHOOL',
  'COMMUNITY CHRISTIAN LEARNING CTR , CORP - WINTER',
  'COMMUNITY CHRISTIAN SCHOOL',
  'COMMUNITY CHRISTIAN SCHOOL OF BARABOO',
  'COMMUNITY CHRISTIAN SCHOOL OF FIVE OAKS',
  'COMMUNITY CHRISTIAN SCHOOL OF WESTERN NEBRASKA',
  'COMMUNITY CHRISTIAN SCHOOLS',
  'COMMUNITY COUNTRY DAY SCHOOL',
  'COMMUNITY DAY SCHOOL',
  'COMMUNITY DEVELOPMENT INC. DBA MCDONALDS PLAYWORLD',
  'COMMUNITY EDUCATIONAL CENTER',
  'COMMUNITY HIGH SCHOOL',
  'COMMUNITY INITIATIVES ACADEMY',
  'COMMUNITY LEADERSHIP ACADEMY',
  'COMMUNITY LEARNING CENTER',
  'COMMUNITY LEARNING CENTER ACADEMY',
  'COMMUNITY LUTHERAN SCHOOL',
  'COMMUNITY MONTESSORI',
  'COMMUNITY MONTESSORI SCHOOL',
  'COMMUNITY MONTESSORI SCHOOL CIVIC',
  'COMMUNITY MONTESSORI SCHOOL WEBB',
  'COMMUNITY OF FAITH CHRISTIAN SCHOOL',
  'COMMUNITY PARTNERSHIP SCHOOL',
  'COMMUNITY PREPARATORY SCHOOL',
  'COMMUNITY PRESBYTERIAN PRESCHOOL & KINDERGARTEN',
  'COMMUNITY SCHOOL',
  'COMMUNITY SCHOOL #1',
  'COMMUNITY SCHOOL OF MARYLAND',
  'COMMUNITY SCHOOL OF NAPLES',
  'COMMUNITY SCHOOLHOUSE',
  'COMMUNITY THERAPEUTIC DAY SCHOOL',
  'COMMUNITY TRANSITIONAL SCHOOL',
  'COMPASS',
  'COMPASS HIGH SCHOOL',
  'COMPASS LEARNING CENTER',
  'COMPASS MONTESSORI SCHOOL',
  'COMPASS POINT ACADEMY',
  'COMPASS PREP ACADEMY',
  'COMPASS ROSE ACADEMY INC',
  'COMPASS SCHOOL',
  'COMPASS STAR MONTESSORI',
  'COMPASSION HOUSE OUTREACH MNSTR & CHRSTAN EDUC CTR',
  'COMPREHENSIVE ED SVS DBA ACES ACADEMY',
  'COMPREHENSIVE LEARNING CENTER',
  'COMPUHIGH/WHITMORE SCHOOL',
  'COMSATS Institute of Information Technology',
  'COMSATS Institute of Information Technology Abbottabad',
  'COMSATS Institute of Information Technology Attock',
  'COMSATS Institute of Information Technology Lahore',
  'COMSATS Institute of Information Technology Wah',
  'CONCHITA ESPINOSA ACADEMY',
  'CONCOCHEAGUE CREEK AMISH SCHOOL',
  'CONCORD ACADEMY INC',
  'CONCORD CHRISTIAN ACADEMY',
  'CONCORD CHRISTIAN SCHOOL',
  'CONCORD HILL SCHOOL',
  'CONCORD LUTHERAN ACADEMY',
  'CONCORD LUTHERAN SCHOOL',
  'CONCORD PRESCHOOL AND CHILDCARE',
  'CONCORDIA ACADEMY',
  'CONCORDIA CHRISTIAN ACADEMY',
  'CONCORDIA CHRISTIAN DAY SCHOOL',
  'CONCORDIA CLASSICAL ACADEMY',
  'CONCORDIA HIGH SCHOOL',
  'CONCORDIA JR/SR HIGH SCHOOL',
  'CONCORDIA JR/SR. HIGH SCHOOL',
  'CONCORDIA LUTHERAN CHURCH-SCHL',
  'CONCORDIA LUTHERAN HIGH SCHOOL',
  'CONCORDIA LUTHERAN NORTH',
  'CONCORDIA LUTHERAN PRESCHOOL',
  'CONCORDIA LUTHERAN SCHOOL',
  'CONCORDIA PREPARATORY SCHOOL',
  'CONCORDIA SCHOOL - GRANADA HILLS',
  'CONDELL DAY CENTER',
  'CONDUCTIVE ED CENTER OF ORLANDO',
  'CONECUH SPRINGS CHRISTIAN SCHOOL',
  'CONEJO ADVENTIST ELEMENTARY SCHOOL',
  'CONESTEE BAPTIST DAY SCHOOL',
  'CONG KHAL YEREIM YESH BAIS HILLEL',
  'CONG. DAMESEK ELEIZER',
  'CONG. DARKEI TSHIVO OF DINOV',
  'CONG. OHR SHRAGA DVERETZKY',
  'CONGREGATION ALBERT EARLY CHILDHOOD CENTER',
  'CONGREGATION BNOS CHAYA',
  'CONGREGATION CHASIDEI BELZ BETH MALKA',
  'CONGREGATION KOLEL CHASIDEL RACHMISTRIVKA',
  "CONGREGATION M'KOR SHALOM PRESCHOOL & KINDERGARTEN",
  'CONGREGATION MACHNA SHALVA',
  'CONGREGATION MACHON TIFERES BACHURIM',
  'CONGREGATION MESIFTA',
  'CONGREGATION NOAM E LIZENSK',
  'CONGREGATION OHR MENACHEM',
  'CONGREGATION YESHIVA BNEI TORAH INC',
  'CONGREGATIONAL HOLINESS CHURCH SCHOOL',
  'CONGRESSIONAL SCHOOL',
  'CONN AREA CATHOLIC SCHOOL',
  'CONNECTICUT EXPERIENTIAL LEARNING CENTER (CELC)',
  'CONNECTICUT JUNIOR REPUBLIC',
  'CONNECTICUT RIVER ACADEMY',
  'CONNECTING GENERATIONS 2ND NATIONS ACADEMY AND SCH',
  'CONNECTION POINTE CHRISTIAN ACADEMY',
  'CONNECTIONS EDUCATION CENTER OF THE PALM BEACHES',
  'CONNELLY SCHOOL OF THE HOLY CHILD',
  'CONQUERING WORD CHRISTIAN ACADEMY',
  'CONQUEST PAROCHIAL SCHOOL',
  'CONSERVATORY PREP SCHOOLS',
  'CONSERVE SCHOOL',
  'CONTINENTAL ACADEMY',
  'CONTRA COSTA CHRISTIAN SCHOOL',
  'CONTRA COSTA JEWISH DAY SCHOOL',
  'CONVENT OF THE SACRED HEART HIGH SCHOOL',
  'CONVENT OF THE VISITATION SCHOOL',
  'CONWAY CHRISTIAN SCHOOL',
  'CONWELL EGAN CATHOLIC HIGH SCHOOL',
  'COOK INLET ACADEMY',
  'COOKE SCHOOL',
  'COOKSON HILLS CHRISTIAN SCHOOL',
  'COOL SPRINGS MONTESSORI',
  'COON RAPIDS CHRISTIAN',
  'COOPER AMISH SCHOOL',
  'COOPER CITY CHRISTIAN ACADEMY',
  'COOPERATIVE SCHOOL AT NORTHLAND',
  'COOPERS CORNER SCHOOL',
  'COORDINATED YOUTH & HUMAN SERVICES',
  'COOSA CHRISTIAN SCHOOL',
  'COOSA VALLEY ACADEMY',
  'COPIAGUE CHRISTIAN ACADEMY',
  'COPIAH EDUCATIONAL FOUNDATION',
  'COPPER HILLS YOUTH CENTER',
  'COR JESU ACADEMY',
  'CORAL SPRING MONTESSORI SCHOOL',
  'CORAL SPRINGS CHRISTIAN ACADEMY',
  'CORAM DEO ACADEMY',
  'CORBETT PREPARATORY SCHOOL OF IDS',
  'CORDOVA BAPTIST PRESCHOOL & KINDERGARTEN',
  'CORDOVA CHRISTIAN ACADEMY',
  'CORINTH MONTESSORI SCHOOL',
  'CORKRAN UNITED METHODIST PRESCHOOL AND KINDERGARTE',
  'CORKSCREW CHRISTIAN SCHOOL',
  'CORLEARS SCHOOL',
  'CORNELIA CONNELLY CENTER',
  'CORNELIA CONNELLY SCHOOL',
  'CORNELL ABRAXAS GROUP',
  'CORNER VIEW PAROCHIAL SCHOOL',
  'CORNERSTON CHRISTIAN SCHOOL',
  'CORNERSTONE',
  'CORNERSTONE ACADEMY',
  'CORNERSTONE ACADEMY OF THE OZARKS',
  'CORNERSTONE ACADEMY-CAMBRIDGE CAMPUS',
  'CORNERSTONE BAPTIST ACADEMY',
  'CORNERSTONE CHRISTIAN ACADEMY',
  'CORNERSTONE CHRISTIAN ACADEMY OF MCLEAN COUNTY',
  'CORNERSTONE CHRISTIAN MONTESSORI SCHOOL',
  'CORNERSTONE CHRISTIAN SCHOOL',
  'CORNERSTONE CHRISTIAN SCHOOLS',
  'CORNERSTONE CHURCH ACADEMY',
  'CORNERSTONE CLASSICAL SCHOOL',
  'CORNERSTONE CROSSROADS ACADEMY',
  'CORNERSTONE DAY SCHOOL',
  'CORNERSTONE DAYCARE LEARNING CENTER',
  'CORNERSTONE FELLOWSHIP ACADEMY',
  'CORNERSTONE LEARNING FOUNDATION',
  'CORNERSTONE MENNONITE SCHOOL',
  'CORNERSTONE MIDDLE HIGH SCHOOL',
  'CORNERSTONE MONTESSORI',
  'CORNERSTONE MONTESSORI SCHOOL',
  'CORNERSTONE PREPARATORY ACADEMY',
  'CORNERSTONE PRESCHOOL AND LIFE ACADEMY',
  'CORNERSTONE PRIVATE SCHOOL',
  'CORNERSTONE SCHOOL',
  'CORNERSTONE SCHOOL OF SUMMIT',
  'CORNERSTONE SCHOOLS OF ALABAMA',
  'CORNERSTONE SCHOOLS OF WASHINGTON, DC',
  'CORNING CHRISTIAN ACADEMY',
  'CORPORATE TODDLERS',
  'CORPUS CHRISTI ACADEMY',
  'CORPUS CHRISTI CATHOLIC SCHOOL',
  'CORPUS CHRISTI CLASSICAL ACADEMY',
  'CORPUS CHRISTI ELEMENTARY SCHOOL',
  'CORPUS CHRISTI HOLY ROSARY SCHOOL',
  'CORPUS CHRISTI SCHOOL',
  'CORPUS CHRISTY CATHOLIC SCHOOL',
  'CORRY ALLIANCE ACADEMY',
  'CORRYVILLE CATHOLIC ELEMENTARY SCHOOL',
  'CORTELYOU EARLY CHILDHOOD CENTER',
  'CORTEZ ADVENTIST CHRISTIAN SCHOOL',
  'CORTLAND CHRISTIAN ACADEMY',
  'CORVALLIS MONTESSORI SCHOOL',
  'CORVALLIS WALDORF SCHOOL',
  'CORWIN-RUSSELL SCHOOL AT BROCCOLI HALL',
  'COSHOCTON CHRISTIAN SCHOOL',
  'COSMIC MONTESSORI COMMUNITY',
  'COSMIQ Institute of Technology',
  'COSTAL COMMUNITY SCHOOL',
  'COTTAGE HILL CHRISTIAN ACADEMY AT WEST CAMPUS',
  'COTTER SCHOOLS',
  'COTTING SCHOOL',
  'COTTONWOOD GROVE SCHOOL',
  'COTTONWOOD MONTESSORI',
  'COTTONWOOD MONTESSORI SCHOOL',
  'COULEE REGION CHRISTIAN SCHOOL',
  'COUNCIL OAK MONTESSORI SCHOOL',
  'COUNTERPANE SCHOOL',
  'COUNTRY BEAR PRESCHOOL & CHILD CARE',
  'COUNTRY BROOK MONTESSORI SCHOOL',
  'COUNTRY CHRISTIAN SCHOOL',
  'COUNTRY CLASSROOM',
  'COUNTRY CORNER',
  'COUNTRY DAY MONTESSORI',
  'COUNTRY DAY SCHOOL',
  'COUNTRY ECHOS',
  'COUNTRY HILL MONTESSORI',
  'COUNTRY HILLS EAST MONTESSORI',
  'COUNTRY HILLS MONTESSORI',
  'COUNTRY HILLS MONTESSORI FT THOMAS',
  'COUNTRY HILLSIDE SCHOOL',
  'COUNTRY HOME LEARNING CENTER #1 LTD',
  'COUNTRY HOME LEARNING CENTER NO. 5',
  'COUNTRY HOME LEARNING CENTER NO. 6',
  'COUNTRY KIDS CLUB',
  'COUNTRY LANE',
  'COUNTRY LANE SCHOOL',
  'COUNTRY MEADOW',
  'COUNTRY MEADOW SCHOOL',
  'COUNTRY MEADOWS',
  'COUNTRY MEADOWS MONTESSORI SCHOOL',
  'COUNTRY MONTESSORI PRESCHOOL OF SUTTON',
  'COUNTRY VIEW',
  'COUNTRY VIEW SCHOOL',
  'COUNTRY VILLAGE MONTESSORI SCHOOL',
  'COUNTRYSIDE ADVENTIST ELEMENTARY',
  'COUNTRYSIDE CHRISTIAN ACADEMY',
  'COUNTRYSIDE CHRISTIAN SCHOOL',
  'COUNTRYSIDE MONTESSORI ACADEMY',
  'COUNTRYSIDE MONTESSORI CENTER.',
  'COUNTRYSIDE MONTESSORI DAY SCHOOL',
  'COUNTRYSIDE MONTESSORI SCHOOL',
  'COUNTRYSIDE MONTESSORI SCHOOL INC.',
  'COUNTRYSIDE PRIVATE SCHOOL INC',
  'COUNTRYSIDE SCHOOL',
  'COUNTRYVIEW SCHOOL',
  'COUNTRYWOOD ELEMENTARY SCHOOL',
  'COUNTY CHRISTIAN SCHOOL',
  'COUNTY CURVE SCHOOL',
  'COUNTY LINE AMISH PAROCHIAL SCHOOL',
  'COUNTY LINE PAROCHIAL SCHOOL',
  'COUNTY LINE SCHOOL',
  'COURT STREET ACADEMY',
  'COURTHOUSE ACADEMY',
  'COURTHOUSE MONTESSORI SCHOOL',
  'COURTNEY CHRISTIAN SCHOOL INC',
  'COURTYARD PRIVATE SCHOOL',
  'COUTS CHRISTIAN ACADEMY',
  'COVENANT ACADEMY',
  'COVENANT CHILD CARE CENTER',
  'COVENANT CHILDRENS ACADEMY',
  'COVENANT CHRISTIAN ACADEMY',
  'COVENANT CHRISTIAN ACADEMY OF THE LEE HIGH VALLEY',
  'COVENANT CHRISTIAN HIGH SCHOOL',
  'COVENANT CHRISTIAN SCHOOL',
  'COVENANT CLASSICAL ACADEMY',
  'COVENANT CLASSICAL CHRISTIAN SCHOOL',
  'COVENANT CLASSICAL SCHOOL',
  'COVENANT COMMUNITY CHRISTIAN SCHOOL',
  'COVENANT COMMUNITY SCHOOL',
  'COVENANT DAY SCHOOL',
  'COVENANT GRACE ACADEMY',
  'COVENANT HEART ACADEMY',
  'COVENANT LIFE SCHOOL',
  'COVENANT LOVE COMMUNITY SCHOOL',
  'COVENANT PREPARATORY SCHOOL',
  'COVENANT PRESBYTERIAN PRESCHOOL',
  'COVENANT PRESCHOOL & CHILDRENS DAY OUT',
  'COVENANT PROMISE CHRISTIAN ACADEMY',
  'COVENANT SCHOOL',
  'COVENTRY CHRISTIAN SCHOOLS',
  'COVERED BRIDGE MONTESSORI SCHOOL',
  'COVINGTON ACADEMY',
  'COVINGTON CATHOLIC HIGH SCHOOL',
  'COVINGTON CHRISTIAN SCHOOL',
  'COVINGTON LATIN SCHOOL',
  'COVINGTON MONTESSORI SCHOOL',
  'COWBOY JUNCTION CHRISTIAN SCHOOL',
  'COX MEADOW SCHOOL',
  'COYLE & CASSIDY HIGH SCHOOL & MIDDLE SCHOOL',
  'COYOTE RIDGE AMISH SCHOOL',
  'COZY CORNERS AMISH SCHOOL',
  'COZY KIDS CHILD CARE',
  'CPC HIGH POINT SCHOOL',
  'CPC WEEKDAY NURSERY SCHOOL & KINDERGARTEN',
  'CRABAPPLE MONTESSORI SCHOOL',
  'CRAMERTON CHRISTIAN ACADEMY',
  'CRANBROOK LEARNING CONNECTION',
  'CRANBROOK SCHOOLS',
  'CRANE COUNTRY DAY SCHOOL',
  'CRANIUM ACADEMY',
  'CRAYONS CHRISTIAN SCHOOL',
  'CREAM SCHOOL',
  'CREAN LUTHERAN HIGH SCHOOL',
  'CREATE',
  'CREATING LANDSCAPES LEARNING CENTER',
  'CREATION KIDS VILLAGE',
  'CREATIVE ALTERNATIVES SCHOOL',
  'CREATIVE ARTS SCHOOL',
  'CREATIVE BEGINNINGS',
  'CREATIVE BEGINNINGS ELEMENTARY INC',
  'CREATIVE BEGINNINGS MONTESSORI-ELEMENTARY',
  'CREATIVE CHILD DEVELOPMENT CENTER',
  'CREATIVE CHILDRENS LEARNING CTR.',
  'CREATIVE CORNER',
  'CREATIVE CORNERS SCHOOL',
  'CREATIVE DAY PRESCHOOL & ACADEMY',
  'CREATIVE ENVIRONMENT DAY SCHOOL',
  'CREATIVE GARDEN NURSERY SCHOOL & KINDERGARTEN',
  'CREATIVE INSPIRATIONS JOURNEY SCHOOL LLC',
  'CREATIVE KIDS CLUB',
  'CREATIVE KIDS LEARNING CENTER',
  'CREATIVE LEARNING',
  'CREATIVE LEARNING ACADEMY',
  'CREATIVE LEARNING ACADEMY INC',
  'CREATIVE LEARNING CENTER',
  'CREATIVE LEARNING CHRISTIAN SCHOOL',
  'CREATIVE LEARNING MONTESSORI SCHOOL',
  'CREATIVE LEARNING PRE SCHOOL',
  'CREATIVE LIFE PREPARATORY SCHOOL',
  'CREATIVE MINDS MONTESSORI SCHOOL',
  'CREATIVE MONTESSORI CENTER',
  'CREATIVE MONTESSORI LEARNING CENTER',
  'CREATIVE MONTESSORI SCHOOL',
  'CREATIVE PRE SCHOOL',
  'CREATIVE WORLD MONTESSORI SCHOOL',
  'CREATIVE WORLD OF MONTESSORI',
  'CREATIVE WORLD OF MONTESSORI BEAVERCREEK',
  'CREATIVE YEARS CHILD DEVELOPMENT & LEARNING CTR',
  'CREEKSIDE CHRISTIAN ACADEMY',
  'CREEKSIDE CHRISTIAN SCHOOL',
  'CREEKSIDE KIDS ACADEMY',
  'CREEKSIDE LEARNING LAB',
  'CREEKSIDE MAPLE SCHOOL',
  'CREEKSIDE SCHOOL',
  'CREIGHTON PREPARATORY SCHOOL',
  'CREME DE LA CREME',
  'CREME DE LA CREME - PLANO',
  'CREME DE LA CREME - WESTMONT',
  'CREME DE LA CREME COLORADO',
  'CREME DE LA CREME GLENVIEW',
  'CREME DE LA CREME- COLLEYVILLE',
  'CREME DE LE CREME',
  'CRENSHAW CHRISTIAN ACADEMY',
  'CREO SCHOOL',
  'CRESCENT ACADEMY INTERNATIONAL',
  'CRESCENT CITY CHRISTIAN SCHOOL',
  'CRESCENT CITY MONTESSORI SCHOOL',
  'CRESCENT CITY SEVENTH-DAY ADVENTIST SCHOOL',
  'CRESCENT MONTESSORI',
  'CRESCENT SCHOOL',
  'CRESCENT VIEW ACADEMY',
  'CRESCO MONTESSORI',
  'CRESPI CARMELITE HIGH SCHOOL',
  'CRESSET CHRISTIAN ACADEMY',
  'CRESSWELL MENNONITE SCHOOL',
  'CREST HILL CHRISTIAN SCHOOL',
  'CRESTMONT SCHOOL',
  'CRESTVIEW ACADEMY',
  'CRESTVIEW BAPTIST EARLY CHILDHOOD CENTER',
  'CRESTVIEW CHRISTIAN ACADEMY',
  'CRESTVIEW CHRISTIAN SCHOOL & BRITE BEGINNINGS',
  'CRESTVIEW MENNONITE SCHOOL',
  'CRESTVIEW PREPARATORY SCHOOL',
  'CRESTWELL SCHOOL (CRESTWELL HIGHER LEARNING)',
  'CRESTWOOD CHRISTIAN ACADEMY',
  'CRESTWOOD COUNTRY DAY SCHOOL',
  'CRESWELL CHRISTIAN SCHOOL',
  'CRETIN DERHAM HALL',
  'CRIBS 2 CRAYONS DAYCARE & LEARNING CENTER',
  'CRIMSON CHRISTIAN ACADEMY',
  'CRISDA CHRISTIAN ACADEMY INC.',
  'CRISMAN SCHOOL',
  'CRISP ACADEMY',
  'CRISTO REY ATLANTA JESUIT HIGH SCHOOL',
  'CRISTO REY BOSTON HIGH SCHOOL',
  'CRISTO REY BROOKLYN HIGH SCHOOL',
  'CRISTO REY COLUMBUS HIGH SCHOOL',
  'CRISTO REY DALLAS COLLEGE PREP',
  'CRISTO REY HIGH SCHOOL',
  'CRISTO REY JESUIT COLLEGE PREP SCHOOL OF HOUSTON',
  'CRISTO REY JESUIT HIGH SCHOOL',
  'CRISTO REY JESUIT HIGH SCHOOL TWIN CITIES',
  'CRISTO REY JESUIT MILWAUKEE HIGH SCHOOL',
  'CRISTO REY KANSAS CITY',
  'CRISTO REY PHILADELPHIA HIGH SCHOOL',
  'CRISWELL',
  'CROCKETT CHRISTIAN SCHOOL',
  'CROFTON AMISH PAROCHIAL SCHOOL',
  'CROPS CORNERS AMISH SCHOOL',
  'CROSS & CROWN LUTHERAN SCHOOL',
  'CROSS CREEK CHRISTIAN ACADEMY',
  'CROSS CREEK CHRISTIAN SCHOOL',
  'CROSS EVANGELICAL LUTHERAN SCHOOL',
  'CROSS LANES CHRISTIAN SCHOOL',
  'CROSS LUTHERAN SCHOOL',
  'CROSS OF CHRIST LUTHERAN SCHOOL',
  'CROSS OF CHRIST PRESCHOOL & KINDERGARTEN',
  'CROSS OF GLORY LUTHERAN SCHOOL',
  'CROSS OF HOPE LUTHERAN ELEMENTARY SCHOOL',
  'CROSS OF LIFE MONTESSORI SCHOOL',
  'CROSS POINT BAPTIST SCHOOL',
  'CROSS ROAD SCHOOL',
  'CROSS ROAD SPECIAL SCHOOL',
  'CROSS ROADS PRESCHOOL & KINDERGARTEN',
  'CROSS SCHOOLS',
  'CROSS STREET CHRISTIAN SCHOOL',
  'CROSS TRAINERS ACADEMY',
  'CROSSHILL CHRISTIAN SCHOOL',
  'CROSSING NATIONAL, INC',
  'CROSSINGS CHRISTIAN SCHOOL',
  'CROSSPOINT',
  "CROSSPOINT CHILDREN'S CENTER",
  'CROSSPOINT CHRISTIAN SCHOOL',
  'CROSSPOINTE CHRISTIAN ACADEMY',
  'CROSSPOINTE PREPARATORY',
  'CROSSRIDGE MONTESSORI',
  'CROSSROAD ACADEMY',
  'CROSSROADS ACADEMY',
  'CROSSROADS BAPTIST KINDERGARTEN',
  'CROSSROADS BAPTIST SCHOOL',
  'CROSSROADS CENTER FOR CHILDREN',
  'CROSSROADS CHILD DEVELOPMENT CENTER',
  'CROSSROADS CHRISTIAN ACADEMY',
  'CROSSROADS CHRISTIAN SCHOOL',
  'CROSSROADS CHRISTIAN SCHOOL OF STATESVILLE',
  'CROSSROADS CHRISTIAN SCHOOLS HOMESCHOOL',
  'CROSSROADS COLLEGE PREPARATORY SCHOOL',
  'CROSSROADS PRIVATE ACADEMIC SCHOOL',
  'CROSSROADS SCHOOL',
  'CROSSROADS SCHOOL FOR ARTS & SCIENCES',
  'CROSSWAY ACADEMY',
  'CROSSWAY CHRISTIAN ACADEMY',
  'CROSSWAY COMMUNITY MONTESSORI SCHOOL',
  'CROSSWIND COMM CHURCH-ADVENTURELAND PRESCHOOL',
  'CROTCHED MOUNTAIN SCHOOL',
  'CROWFIELD ACADEMY',
  "CROWLEY'S RIDGE ACADEMY",
  'CROWN CHRISTIAN SCHOOL',
  'CROWN OF LIFE CHRISTIAN ACADEMY',
  'CROWN OF LIFE LUTHERAN',
  'CROWN OF LIFE LUTHERAN SCHOOL',
  'CROWN POINT CHRISTIAN SCHOOL',
  'CRYSTAL LAKE MONTESSORI SCHOOL',
  'CRYSTAL LIGHT MENNONITE SCHOOL',
  'CRYSTAL SPRINGS INC.',
  'CRYSTAL SPRINGS SCHOOL AMISH',
  'CRYSTAL SPRINGS UPLANDS SCHOOL',
  'CS ACADEMY',
  'CS LEWIS ACADEMY',
  'CT CHRISTIAN ACADEMY',
  'CTC',
  'CTC ACADEMY',
  'CTR FOR EDUCATION SCHOOL OF THE ARTS AND SCIENC',
  'CTR FOR INDIVIDUALIZED TRAINING & EDUCATION',
  'CUBA MENNONITE SCHOOL',
  'CULBERTSON MENNONITE SCHOOL',
  'CULLMAN CHRISTIAN SCHOOL',
  'CULPEPER CHRISTIAN SCHOOL',
  'CULVER ACADEMICS',
  'CULVER CITY CHRISTIAN SCHOOL',
  'CUMBERLAND ACADEMY',
  'CUMBERLAND ACADEMY OF GEORGIA',
  'CUMBERLAND CHRISTIAN ACADEMY',
  'CUMBERLAND CHRISTIAN SCHOOL',
  'CUMBERLAND MENNONITE SCHOOL',
  'CUMBERLAND MOUNTAIN SCHOOL',
  'CUMBERLAND VALLEY CHRISTIAN SCHOOL',
  'CUMMING FIRST UNITED METHODIST CHURCH PRESCHOOL',
  'CUMORAH KIDS',
  'CUNAE INTERNATIONAL SCHOOL',
  'CUNNINGHAM',
  'CUNY Borough of Manhattan Community College',
  'CUNY Bronx Community College',
  'CUNY Hostos Community College',
  'CUNY Kingsborough Community College',
  'CUNY LaGuardia Community College',
  'CUNY Queensborough Community College',
  'CURREY INGRAM ACADEMY',
  'CURTIS BAPTIST SCHOOL (PK-12)',
  'CURTIS BLAKE DAY SCHOOL',
  'CURTIS SCHOOL',
  'CURTIS WILSON PRIMARY SCHOOL O',
  'CURVE CREEK',
  'CUSSEWAGO MENNONITE SCHOOL',
  'CUSTER CITY PRIVATE SCHOOL',
  'CUSTODIO HOME ACADEMY',
  'CUTSWOOD SCHOOL',
  'CUTTINGEDGE LEARNING ACADEMY INC.',
  'CUVILLY ARTS & EARTH CENTER',
  'CYNTHIA S PRESCHOOL & KINDERGARTEN',
  'CYPRESS ADVENTIST SCHOOL',
  'CYPRESS BEND ADVENTIST ELEMENTARY SCHOOL',
  'CYPRESS CHRISTIAN SCHOOL',
  "CYPRESS CREEK CHILDREN'S MONTESSORI SCHOOL",
  'CYPRESS MONTESSORI SCHOOL',
  'CYPRESS PRIMARY SCHOOL',
  'CYPRESS SCHOOL',
  'CYPRESSWOOD MONTESSORI',
  'CYZNER INSTITUTE',
  'Ca Early Learning Center',
  'Ca Frost Environmental Science Academy',
  'Ca Gray Junior High School',
  'Caaep Extended Day Program',
  'Caaep Setting 4',
  'Cabarrus Charter Academy',
  'Cabarrus Co Opportunity School',
  'Cabarrus Early College Of Technology',
  'Cabarrus-kannapolis Early Coll',
  'Cabazon Elementary',
  'Cabe Middle School',
  'Cabell Alternative School',
  'Cabell County Career Technology Center',
  'Cabell Midland High School',
  'Cabin John Middle School',
  'Cable El',
  'Cabool Elem.',
  'Cabool High',
  'Cabool Middle',
  'Cabot',
  'Cabot Freshman Academy',
  'Cabot High School',
  'Cabot Junior High North',
  'Cabot Junior High South',
  'Cabot Middle School North',
  'Cabot Middle School South',
  'Cabot School',
  'Cabot Yerxa Elementary',
  'Cabrillo Avenue Elementary',
  'Cabrillo College',
  'Cabrillo Elementary',
  'Cabrillo High',
  'Cabrillo Middle',
  'Cache 5th And 6th Grade Ms',
  'Cache Creek High (continuation)',
  'Cache High',
  'Cache Hs',
  'Cache Intermediate Es',
  'Cache La Poudre Elementary School',
  'Cache La Poudre Middle School',
  'Cache Ms',
  'Cache Preschool',
  'Cache Primary Es',
  'Cacique Agueybana',
  'Cactus Canyon Junior High',
  'Cactus El',
  'Cactus High School',
  'Cactus Middle',
  'Cactus Middle School',
  'Cactus Ranch El',
  'Cactus Shadows High School',
  'Cactus Valley Elementary School',
  'Cactus View Elementary School',
  'Cactus Wren Elementary School',
  'Caddie Woodlawn Elementary',
  'Caddo Career & Technical Center',
  'Caddo Es',
  'Caddo Grove El',
  'Caddo Heights Math/science Elementary School',
  'Caddo Hills Elementary School',
  'Caddo Hills High School',
  'Caddo Hs',
  'Caddo Middle Career And Technology School',
  'Caddo Mills Bau',
  'Caddo Mills H S',
  'Caddo Mills Middle',
  'Caddo Parish Magnet High School',
  'Caddo Parish Middle Magnet School',
  'Caddo Virtual Academy',
  'Cadet Leadership Education Program',
  'Cadillac Innovation High School',
  'Cadillac Junior High School',
  'Cadillac Schools Adult Education',
  'Cadillac Senior High School',
  'Cadman Elementary',
  'Cadott Elementary',
  'Cadott High',
  'Cadott Junior High',
  'Cadwallader Elementary',
  'Caesar Rodney High School',
  'Cag University',
  'Cagayan State University',
  'Cage El',
  'Cahaba Elementary School',
  'Cahill School',
  'Cahokia High School',
  'Cahoon Elementary Magnet School',
  'Cahuenga Elementary',
  'Cahuilla Desert Academy Junior High',
  'Cahuilla Elementary',
  'Cain Elementary',
  'Cainhoy Elementary',
  'Cainsville Elem.',
  'Cainsville High',
  'Cairnbrook El Sch',
  'Cairo Elementary School',
  'Cairo High School',
  'Cairo Jr/sr High School',
  'Cairo University',
  'Cairo-durham Elementary School',
  'Cairo-durham High School',
  'Cairo-durham Middle School',
  'Cajon High',
  'Cajon Park Elementary',
  'Cajon Valley Community Day',
  'Cajon Valley Home',
  'Cajon Valley Middle',
  'Cal Aero Preserve Academy',
  'Cal Burke High',
  'Cal Community High School',
  'Cal Elementary School',
  'Cal Young Middle School',
  'Calabasas Elementary',
  'Calabasas High',
  'Calabasas School',
  'Calabash Charter Academy',
  'Calahan Community Charter',
  'Calais Elementary School',
  'Calais Middle/high School',
  'Calallen Charter H S',
  'Calallen East El',
  'Calallen H S',
  'Calallen Middle',
  'Calallen Wood River El',
  'Calamus-wheatland Elem Attendance Center',
  'Calamus-wheatland Sec Attendance Center',
  'Calapooia Middle School',
  'Calavera Hills Elementary',
  'Calavera Hills Middle',
  'Calaveras County Special Education (selpa)',
  'Calaveras Elementary',
  'Calaveras High',
  'Calaveras Hills',
  'Calaveras River Academy',
  'Calaveras Unified Alternative-sierra Hills Education Center',
  'Calc Transition',
  'Calcedeaver Elementary School',
  'Calcium Primary School',
  'Calcutt Middle School',
  'Calder Road El',
  'Calder School',
  'Caldwell Career Cen Mid. Coll.',
  'Caldwell Community College and Technical Institute',
  'Caldwell County Area Technology Center',
  'Caldwell County Elementary School',
  'Caldwell County High School',
  'Caldwell County Middle School',
  'Caldwell County Primary School',
  'Caldwell Daep',
  'Caldwell Early College',
  'Caldwell El',
  'Caldwell El Arts Academy',
  'Caldwell Elem',
  'Caldwell Elem Acad Of Math & Sci',
  'Caldwell Elementary',
  'Caldwell Elementary School',
  'Caldwell H S',
  'Caldwell Heights El',
  'Caldwell High School',
  'Caldwell Int',
  'Caldwell Middle',
  'Caldwell Middle School',
  'Caldwell Parish High School',
  'Caldwell Parish Junior High School',
  'Caldwell Secondary School',
  'Caldwell Senior High School',
  'Caldwood El',
  'Caleb Dustin Hunking',
  'Caleb Dustin Hunking School',
  'Caleb Greenwood Elementary',
  'Caleb University',
  "Caleb's Creek Elementary",
  'Caledonia Elem School',
  'Caledonia Elem.',
  'Caledonia Elementary',
  'Caledonia Elementary School',
  'Caledonia High School',
  'Caledonia Middle',
  'Caledonia Middle School',
  'Caledonia Resource Center',
  'Caledonia Senior High',
  'Caledonia-mumford Elementary School',
  'Caledonia-mumford High School',
  'Caledonia-mumford Middle School',
  'Calera Elementary',
  'Calera Es',
  'Calera High',
  'Calera Hs',
  'Calera Intermediate School',
  'Calera Middle',
  'Calero High',
  'Calexico High',
  'Caley El Sch',
  'Calf Pen Meadow School',
  'Calhan Elementary School',
  'Calhan High School',
  'Calhan Middle School',
  'Calhoun Academy Of The Arts',
  'Calhoun Area Career Center',
  'Calhoun City Elementary School',
  'Calhoun City High School',
  'Calhoun City Middle School',
  'Calhoun Co Career & Tech Ctr',
  'Calhoun County Adult Education Center',
  'Calhoun County Alternative School',
  'Calhoun County Career Technical Center',
  'Calhoun County Elementary School',
  'Calhoun County High',
  'Calhoun County High School',
  'Calhoun County Juvenile Home',
  'Calhoun County Middle School',
  'Calhoun Early Childhood Ctr.',
  'Calhoun Elem.',
  'Calhoun Elementary',
  'Calhoun Elementary School',
  'Calhoun Elementary/ Jr High Sch',
  'Calhoun Falls Charter School',
  'Calhoun H S',
  'Calhoun High',
  'Calhoun High School',
  'Calhoun Middle',
  'Calhoun Middle School',
  'Calhoun Middle/high School',
  'Calhoun Primary School',
  'Calhoun Virtual Franchise',
  'Calhoun Virtual Instruction (course Offerings)',
  'Calhoun Virtual Instruction Program',
  'Calhoun Virtual Instruction Program (district Provided)',
  'Calhoun-gilmer Career Center',
  'Cali Calmecac Language Academy',
  'Caliber: Beta Academy',
  'Caliber: Changemakers Academy',
  'Calibre Academy Surprise',
  'Caliche Elementary School',
  'Caliche Junior-senior High School',
  'Calico Continuation High',
  'Calico Rock Elementary School',
  'Calico Rock High School',
  'Caliente Elementary School',
  'Califon Elementary',
  'California Academy Of Mathematics And Science',
  'California Academy Of Sports Science',
  'California Academy Of Sports Science Fresno',
  'California Advancing Pathways For Students In Los Angeles Co',
  'California Area El Sch',
  'California Area Intermediate Ms',
  'California Area Ms',
  'California Area Shs',
  'California Avenue Elementary',
  'California Avenue Elementary School',
  'California Baptist University',
  'California City High',
  'California City Middle',
  'California Coast University',
  'California Collegiate Charter',
  'California Connections Academy @ North Bay',
  'California Connections Academy @ Ripon',
  'California Connections Academy@central',
  'California Elem.',
  'California Elementary',
  'California Heritage Youthbuild Academy Ii',
  'California High',
  'California Institute of Technology',
  'California Lutheran University',
  'California Maritime Academy',
  'California Middle',
  'California Military Institute',
  'California Montessori Project - Capitol Campus',
  'California Montessori Project - Elk Grove Campus',
  'California Montessori Project-san Juan Campus',
  'California Montessori Project-shingle Springs Campus',
  'California National University',
  'California Pacific Charter - San Diego',
  'California Polytechnic State University Pomona',
  'California Polytechnic State University San Luis Obispo',
  'California Prep Sutter 8-12',
  'California Prep Sutter K-7',
  'California Preparatory Academy',
  'California School For The Blind',
  'California School For The Deaf-fremont',
  'California School For The Deaf-riverside',
  'California School Of The Arts - San Gabriel Valley',
  'California Southern University',
  'California State University Bakersfield',
  'California State University Chico',
  'California State University Dominguez Hills',
  'California State University Fresno',
  'California State University Fullerton',
  'California State University Hayward',
  'California State University Long Beach',
  'California State University Los Angeles',
  'California State University Monterey Bay',
  'California State University Northridge',
  'California State University Sacramento',
  'California State University San Bernardino',
  'California State University San Jose',
  'California State University San Marcos',
  'California State University Stanislaus',
  'California State University System',
  'California Steam San Bernardino',
  'California Steam Santa Barbara',
  'California Steam Sonoma',
  'California Steam Sonoma Ii',
  'California Trail Middle School',
  'California University of Pennsylvania',
  'California Virtual Academy @ Jamestown',
  'California Virtual Academy @ Los Angeles',
  'California Virtual Academy @ Maricopa',
  'California Virtual Academy @ San Diego',
  'California Virtual Academy @ San Joaquin',
  'California Virtual Academy @ Sonoma',
  'California Virtual Academy At Fresno',
  'California Virtual Academy At Kings',
  'California Virtual Academy At Sutter',
  'California Virtual Academy San Mateo',
  'Calimesa Elementary',
  'Calipatria High',
  'Calistoga Elementary',
  'Calistoga Junior-senior High',
  'Calk El',
  'Calk-wilson El',
  'Calkins Road Middle School',
  'Calla High',
  'Callaghan Elementary',
  'Callahan Elementary School',
  'Callahan Intermediate School',
  'Callahan Middle School',
  'Callanan Middle School',
  'Callao Elem.',
  'Callaway Elementary',
  'Callaway Elementary School',
  'Callaway High School',
  'Callaway Hills Elem.',
  'Callaway Middle School',
  'Calle Mayor Middle',
  'Callie Kirkpatrick Elementary',
  'Callier Center Pre-school Deaf (0-5)',
  'Callisburg El',
  'Callisburg H S',
  'Callisburg Middle',
  'Calloway (cab) School Of The Arts',
  'Calloway County Alternative Program',
  'Calloway County Day Treatment',
  'Calloway County High School',
  'Calloway County Middle School',
  'Calloway County Preschool Center',
  'Calloway Smith Middle School',
  'Calmeca Acad Elem School',
  'Caln El Sch',
  'Caloosa Elementary School',
  'Caloosa Middle School',
  'Calpella Elementary',
  'Calumet Elem School',
  'Calumet Es',
  'Calumet High School',
  'Calumet Hs',
  'Calumet New Tech High School',
  'Calusa Elementary School',
  'Calusa Park Elementary School',
  'Calvary Elementary School',
  'Calvert Charter For Enriched Studies',
  'Calvert City Elementary School',
  'Calvert Country School',
  'Calvert County Alternative School',
  'Calvert El',
  'Calvert Elementary',
  'Calvert Elementary School',
  'Calvert High',
  'Calvert Middle',
  'Calvert School',
  'Calverton',
  'Calverton Elementary',
  'Calvin A. Hunsinger School',
  'Calvin Capshaw Middle',
  'Calvin College',
  'Calvin Coolidge',
  'Calvin Coolidge Elementary School',
  'Calvin Coolidge Middle School',
  'Calvin Coolidge School',
  'Calvin Donaldson Environmental Science Academy',
  'Calvin Es',
  'Calvin High School',
  'Calvin Hs',
  'Calvin Leete School',
  'Calvin M. Rodwell Elementary',
  'Calvin Nelms H S',
  'Calvin Nelms Middle',
  'Calvin S. Smith School',
  'Calvin U Smith Elementary School',
  'Calvin Vincent Pre-k Head Start',
  'Calvine High',
  'Calwa Elementary',
  'Calypso El Sch',
  'Calzada',
  'Cam Academy',
  'Cam High School',
  'Cam Middle School',
  'Cam North Elementary',
  'Cam Rose School',
  'Cam South Elementary School',
  'Camanche Elementary',
  'Camanche High School',
  'Camanche Middle School',
  'Camarena (enrique S.) Elementary',
  'Camargo Elementary School',
  'Camarillo Academy Of Progressive Education',
  'Camarillo Heights Elementary',
  'Camas County Elem/jr High School',
  'Camas County High School',
  'Camas Elementary',
  'Camas High School',
  'Camas Prairie Elementary',
  'Camas Ridge Community Elementary',
  'Camas Valley School',
  'Camberwell College of Arts',
  'Cambodia University of Specialties',
  'Cambodian Mekong University',
  'Cambria Community Day',
  'Cambria El Sch',
  'Cambria Friesland Elementary',
  'Cambria Friesland Middle/high',
  'Cambria Grammar',
  'Cambria Heights Academy',
  'Cambria Heights El Sch',
  'Cambria Heights Ms',
  'Cambria Heights Shs',
  'Cambridge Academy East',
  'Cambridge Comm Grade School',
  'Cambridge Continuation High',
  'Cambridge El',
  'Cambridge Elem.',
  'Cambridge Elementary',
  'Cambridge Elementary Magnet School',
  'Cambridge Elementary School',
  'Cambridge Heights Elementary',
  'Cambridge High',
  'Cambridge High School',
  'Cambridge Intermediate School',
  'Cambridge Jr/sr High School',
  'Cambridge Junior-senior High School',
  'Cambridge Lakes Charter School',
  'Cambridge Middle School',
  'Cambridge Middle/high School',
  'Cambridge Park Elementary School',
  'Cambridge Primary School',
  'Cambridge Rindge And Latin',
  'Cambridge Springs El Sch',
  'Cambridge Springs Jshs',
  'Cambridge Street Upper School',
  'Cambridge-isanti High School',
  'Cambridge-south Dorchester High School',
  'Cambridgeport',
  'Camden Big Picture Learning Academy',
  'Camden Central High School',
  'Camden Co Youth Ctr',
  'Camden Community Charter School',
  'Camden Community Day',
  'Camden County College',
  'Camden County High',
  'Camden County High School',
  'Camden County Technical School-gtc',
  'Camden County Technical School-pennsauken',
  'Camden Early College High',
  'Camden Elementary',
  'Camden Elementary Of The Creative Arts',
  'Camden Elementary School',
  'Camden Fairview High School',
  'Camden Fairview Intermediate',
  'Camden Fairview Middle School',
  'Camden High',
  'Camden High School',
  'Camden Hills Regional H S',
  'Camden Intermediate',
  'Camden Jr High School',
  'Camden Middle',
  'Camden Middle School',
  'Camden Prep Inc.',
  'Camden Primary School',
  'Camden School Of Arts Technology',
  'Camden Senior High School',
  'Camden Station Elementary School',
  'Camden Street Elementary School',
  'Camden Virtua Excel?',
  'Camden Virtua Insight?',
  'Camden Virtua Kids In Transition',
  "Camden's Promise Charter School",
  'Camden-rockport Elementary School',
  'Camden-rockport Middle School',
  'Camdenfrontier High School',
  'Camdenfrontier K8 School',
  'Camdenton High',
  'Camdenton Middle',
  'Camelback Academy',
  'Camelback High School',
  'Camellia Avenue Elementary',
  'Camellia Elementary',
  'Camelot Academy Of Escambia County',
  'Camelot El',
  'Camelot Elementary',
  'Camelot Elementary School',
  'Camelot Intermediate - 06',
  'Camelot Safe Acad - Garfield Park',
  'Camelot Safe Academy School',
  'Camels Hump Middle School',
  'Cameo Elementary',
  'Camerado Springs Middle',
  'Cameron',
  'Cameron Academy Of Virtual Education',
  'Cameron Co J J A E P',
  'Cameron Co Juvenile Detention Ctr',
  'Cameron College Preparatory',
  'Cameron Colony Elementary - 04',
  'Cameron County Jjaep',
  'Cameron County Jshs',
  'Cameron Dual Language Magnet',
  'Cameron El',
  'Cameron Elem School',
  'Cameron Elementary',
  'Cameron Elementary School',
  'Cameron Es',
  'Cameron High',
  'Cameron High School',
  'Cameron Hs',
  'Cameron Intermediate School',
  'Cameron Middle',
  'Cameron Middle School',
  'Cameron Park Elementary',
  'Cameron Ranch Elementary',
  'Cameron University',
  'Cameron Veterans Middle',
  'Cameron Yoe H S',
  'Camey El',
  'Camillus Middle School',
  'Camilo Valles Matienzo',
  'Caminiti Exceptional Center',
  'Camino Elementary',
  'Camino Grove Elementary',
  'Camino Montessori',
  'Camino Nuevo Academy #2',
  'Camino Nuevo Charter Academy',
  'Camino Nuevo Charter Academy #4',
  'Camino Nuevo Charter High',
  'Camino Nuevo Elementary #3',
  'Camino Nuevo High #2',
  'Camino Nuevo Youth',
  'Camino Pablo Elementary',
  'Camino Polytechnic',
  'Camino Real El',
  'Camino Real Elementary',
  'Camino Real Middle',
  'Camosun College',
  'Camp Allen Elementary',
  'Camp Avenue School',
  'Camp Avery Park Camp',
  'Camp Creek Elementary',
  'Camp Creek Elementary School',
  'Camp Creek Middle School',
  'Camp Crook Elementary - 08',
  'Camp Curtin Academy',
  'Camp Dick Robinson Elementary School',
  'Camp Douglas Elementary',
  'Camp Ernst Middle School',
  'Camp Glenwood',
  'Camp Ground Elementary School',
  'Camp Hill Ms',
  'Camp Hill Shs',
  'Camp Lockett Middle',
  'Camp Mohave Elementary School',
  'Camp Road Middle',
  'Camp Summit Juvenile Facility',
  'Camp Taylor Elementary',
  'Camp Verde Accommodation School',
  'Camp Verde Elementary School',
  'Camp Verde High School',
  'Camp Verde Middle School',
  'Campanelli Elem School',
  'Campbell County Area Technology Center',
  'Campbell County Comprehensive High School',
  'Campbell County High School',
  'Campbell County Middle School',
  'Campbell County Technical Center',
  'Campbell Court Elementary',
  'Campbell Drive K-8 Center',
  'Campbell El',
  'Campbell Elem.',
  'Campbell Elementary',
  'Campbell Elementary School',
  'Campbell H S',
  'Campbell High',
  'Campbell High Community Day',
  'Campbell High School',
  'Campbell Hill Elementary School',
  'Campbell Middle',
  'Campbell Middle School',
  'Campbell Park Elementary School',
  'Campbell Ridge Elementary',
  'Campbell Stem Elementary',
  'Campbell University',
  'Campbell-savona Elementary School',
  'Campbell-savona Junior/senior High School',
  'Campbell-tintah Elementary',
  'Campbell-tintah Secondary',
  'Campbellsburg Elementary School',
  'Campbellsport Elementary',
  'Campbellsport High',
  'Campbellsport Middle',
  'Campbellsville College',
  'Campbellsville Eagle Academy',
  'Campbellsville Elementary School',
  'Campbellsville High School',
  'Campbellsville Middle School',
  'Campbellsville Preschool',
  'Campbellton Elem.',
  'Campestre El',
  'Campfield Early Childhood Center',
  'Campion College',
  'Campo Bello Elementary School',
  'Campo Elementary',
  'Campo Elementary School',
  'Campo High (continuation)',
  'Campo Undivided High School',
  'Campo Verde High School',
  'Campobello-gramling School',
  'Campolindo High',
  'Campton Elementary School',
  'Camptonville Elementary',
  'Campus At Lee',
  'Campus Bio-Medico University of Rome',
  'Campus Canyon Elementary',
  'Campus Charter School',
  'Campus Community School',
  'Campus Elementary',
  'Campus Elementary School',
  'Campus High Haysville',
  'Campus International High School',
  'Campus International School',
  'Campus Middle School',
  'Campus Park Elementary',
  'Camras Elem School',
  'Camridge Colony School - 04',
  'Camrose Colony Elementary - 06',
  'Can-Tho University',
  'Canaan Community Academy',
  'Canaan Elementary School',
  'Canaan Middle School',
  'Canaan Schools',
  'Canabon Abajo',
  'Canada College',
  'Canadian College of Business & Computers',
  'Canadian El',
  'Canadian Es',
  'Canadian H S',
  'Canadian Hs',
  'Canadian Mennonite University',
  'Canadian Middle',
  'Canadian Sudanese College',
  'Canadochly El Sch',
  'Canajoharie Middle School',
  'Canajoharie Senior High School',
  'Canakkale (18th March) University',
  'Canal Street/oak Grove Schools',
  'Canal View Elementary School',
  'Canal Winchester High School',
  'Canal Winchester Middle School',
  'Canales El',
  'Canalino Elementary',
  'Canandaigua Academy',
  'Canandaigua Middle School',
  'Canandaigua Primary-elementary School',
  'Canarsie Ascend Charter School',
  'Canaseraga School',
  'Canastota High School',
  'Canby Elementary',
  'Canby High School',
  'Canby Lane Elementary School',
  'Canby Secondary',
  'Cancryn Junior High School',
  'Candalaria Elementary School',
  'Candeo Peoria',
  'Candido Berrios',
  'Candlebrook El Sch',
  'Candler Elementary',
  'Candlewood El',
  'Candlewood Elementary',
  'Candlewood Middle School',
  'Candor Elementary',
  'Candor Elementary School',
  'Candor Junior-senior High School',
  'Candy Lane Elementary School',
  'Cane Bay Elementary',
  'Cane Bay High',
  'Cane Bay Middle',
  'Cane Creek Elementary',
  'Cane Creek Middle',
  'Cane Ridge Elementary',
  'Cane Ridge Elementary School',
  'Cane Ridge High School',
  'Cane River Middle',
  'Cane Run Elementary',
  'Caneview Elementary School',
  'Caney Creek H S',
  'Caney Es',
  'Caney Hs',
  'Caney Valley Charter Academy',
  'Caney Valley Es',
  'Caney Valley High',
  'Caney Valley Hs',
  'Caney Valley Ms',
  'Caneyville Elementary School',
  'Canfield Avenue Elementary',
  'Canfield Avenue School',
  'Canfield High School',
  'Canfield Middle School',
  'Canfield Village Middle School',
  'Caniff Liberty Academy',
  'Canisius College',
  'Canisteo-greenwood Elementary/middle School',
  'Canisteo-greenwood High School',
  'Canistota Elementary - 02',
  'Canistota High School - 01',
  'Canistota Middle School - 03',
  'Cankaya University',
  'Cankdeska Cikana Community College',
  'Cankiri karatekin University',
  'Cankton Elementary School',
  'Cannaday El',
  'Cannella Elementary School',
  'Cannelton Elementary & High School',
  'Cannon Ball Elementary School',
  'Cannon County High School',
  'Cannon El',
  'Cannon Falls Alc High School',
  'Cannon Falls Elementary',
  'Cannon Falls Hs Credit Recovery',
  'Cannon Falls Secondary',
  'Cannon River Stem School',
  'Cannon Road Elementary',
  'Cannons Elementary',
  'Cannonsburg Elementary School',
  'Cano-gonzalez El',
  'Canoas Elementary',
  'Canoe Creek Charter Academy',
  'Canoga Park Elementary',
  'Canoga Park Senior High',
  'Canon City High School',
  'Canon City Middle School',
  'Canon Elementary School',
  'Canon Exploratory School',
  'Canon Online Academy',
  'Canon School',
  'Canon-mcmillan Shs',
  'Canongate Elementary School',
  'Canonsburg Ms',
  'Canopy Oaks Elementary School',
  'Cantara Street Elementary',
  'Canterbury',
  'Canterbury Avenue Elementary',
  'Canterbury Christ Church University',
  'Canterbury El',
  'Canterbury Elem School',
  'Canterbury Elementary',
  'Canterbury Elementary School',
  'Canterbury Woods Elementary',
  'Cantiague Elementary School',
  'Canton Area El Sch',
  'Canton Arts Academy @ Summit',
  'Canton Career Center',
  'Canton Charter Academy',
  'Canton City Digital Academy',
  'Canton City Early College Academy',
  'Canton College Preparatory School',
  'Canton Educational Services Center',
  'Canton El',
  'Canton Elem.',
  'Canton Elementary',
  'Canton Elementary School',
  'Canton Es',
  'Canton H S',
  'Canton Harbor High School',
  'Canton High',
  'Canton High School',
  'Canton High School - 01',
  'Canton Hs',
  'Canton Int',
  'Canton Intermediate School',
  'Canton J H',
  'Canton Jshs',
  'Canton Middle',
  'Canton Middle School',
  'Canton Middle School - 04',
  'Canton Preparatory High School',
  'Canton Public 9th Grade School',
  'Canton Public High School',
  'Canton South High School',
  'Canton-galva Elementary',
  'Canton-galva Jr./sr. High',
  'Cantrall Elem School',
  'Cantrall Intermediate School',
  'Cantril',
  'Cantu El',
  'Cantua Elementary',
  'Cantwell School',
  'Canty Elem School',
  'Canute Es',
  'Canute Hs',
  'Canutillo El',
  'Canutillo H S',
  'Canutillo Jjaep',
  'Canutillo Middle',
  'Canyon Breeze Elementary',
  'Canyon Charter Elementary',
  'Canyon Creek 7-8',
  'Canyon Creek El',
  'Canyon Creek Elementary',
  'Canyon Creek Elementary School',
  'Canyon Creek Middle School',
  'Canyon Creek School',
  'Canyon Crest Academy',
  'Canyon Crest Elementary',
  'Canyon Crest School',
  'Canyon Day Junior High School',
  'Canyon De Chelly Elementary School',
  'Canyon Del Oro High School',
  'Canyon Early Learning Center',
  'Canyon Elementary',
  'Canyon Elementary School',
  'Canyon Grove Academy',
  'Canyon H S',
  'Canyon High',
  'Canyon Hills',
  'Canyon Hills Junior High',
  'Canyon Hills Middle',
  'Canyon Int',
  'Canyon J H',
  'Canyon Lake Elementary - 04',
  'Canyon Lake H S',
  'Canyon Lake Middle',
  'Canyon Middle',
  'Canyon Oaks Elementary',
  'Canyon Oaks High',
  'Canyon Oaks Youth Center',
  'Canyon Park Middle School',
  'Canyon Pointe Academy',
  'Canyon Pointe El',
  'Canyon Ridge El',
  'Canyon Ridge High',
  'Canyon Ridge High School',
  'Canyon Ridge Ies',
  'Canyon Ridge Middle',
  'Canyon Ridge School',
  'Canyon Rim Academy',
  'Canyon Rim Elementary',
  'Canyon Rose Academy',
  'Canyon Rose Academy-east',
  'Canyon School',
  'Canyon Springs',
  'Canyon Springs Community Elementary',
  'Canyon Springs High',
  'Canyon Springs High School',
  'Canyon Springs High School And The Leadership And Law Prepar',
  'Canyon View Elementary',
  'Canyon View Elementary School',
  'Canyon View Group Home',
  'Canyon View High',
  'Canyon View High School',
  'Canyon View Jr High',
  'Canyon View Middle',
  'Canyon View Prep Academy',
  'Canyon View Preschool',
  'Canyon View School',
  'Canyon Vista Elementary',
  'Canyon Vista Middle',
  'Canyons Technical Education Center',
  'Canyons Transition Academy',
  'Canyons Virtual High School',
  'Canyonville School',
  'Cap Dist Juvenile Secure Detention',
  'Cap Sante High School',
  'Capac Elementary School',
  'Capac High School',
  'Capac Virtual Education Program',
  'Capay Joint Union Elementary',
  'Cape Coast Polytechnic',
  'Cape Cod Community College',
  'Cape Cod Hill Elem School',
  'Cape Cod Lighthouse Charter School',
  'Cape Cod Region Vocational Technical',
  'Cape Coral Charter School',
  'Cape Coral High School',
  'Cape Coral Technical College',
  'Cape Elementary School',
  'Cape Elizabeth High School',
  'Cape Elizabeth Middle School',
  'Cape Fear Center For Inquiry',
  'Cape Fear Community College',
  'Cape Fear Elementary',
  'Cape Fear High',
  'Cape Fear Middle',
  'Cape Flattery Preschool',
  'Cape Girardeau Car Tech Ctr.',
  'Cape Hatteras Elementary School',
  'Cape Hatteras Secondary School',
  'Cape Henlopen High School',
  'Cape Horn Skye Elementary',
  'Cape May City Elementary School',
  'Cape May County High School',
  'Cape May County Technical High School',
  'Cape May Regional School',
  'Cape May Transitional Education Center',
  'Cape Peninsula University of Technology',
  'Cape Romain Environmental Education Charter School',
  'Cape School-detention',
  'Cape School-jail',
  'Cape St. Claire Elementary',
  'Cape View Elementary School',
  'Cape Vincent Elementary School',
  'Capella University',
  'Caperton Center For Applied Technology',
  'Capestone Academy Charter Schoolmayfield',
  'Capilano College',
  'Capistrano Avenue Elementary',
  'Capistrano Connections Academy',
  'Capistrano El',
  'Capistrano Elementary',
  'Capistrano Valley High',
  'Capistrano Virtual/home',
  'Capital Academy',
  'Capital Area School For The Arts Charter',
  'Capital Area Technical Center',
  'Capital City',
  'Capital City Alternative School',
  'Capital City Independent Study',
  'Capital City Pcs High',
  'Capital City Pcs Lower',
  'Capital City Pcs Middle',
  'Capital City Public Charter School (e)',
  'Capital City Public Charter School (h)',
  'Capital Community College',
  'Capital Community College Magnet Academy',
  'Capital High',
  'Capital High School',
  'Capital Normal University',
  'Capital Preparatory (cp) Harlem Charter School',
  'Capital Preparatory Harbor School Inc.',
  'Capital Preparatory Magnet School',
  'Capital Senior High School',
  'Capital University of Economics and Business',
  'Capital University of Medical Sciences',
  'Capitan Elementary',
  'Capitan High',
  'Capitan Middle',
  'Capitanejo',
  'Capitol City Lighthouse Lower Academy',
  'Capitol City Lighthouse Upper Academy',
  'Capitol Collegiate Academy',
  'Capitol El',
  'Capitol Elementary School',
  'Capitol Heights Elementary',
  'Capitol Heights Middle School',
  'Capitol High School',
  'Capitol Hill Elementary School',
  'Capitol Hill Es',
  'Capitol Hill Hs',
  'Capitol Hill Magnet/rondo',
  'Capitol Hill Montessori School At Logan',
  'Capitol Middle School',
  'Capitol Technology University',
  'Capitol University',
  'Capitol View Elementary School',
  'Capitol West Academy',
  'Capon Bridge Elementary School',
  'Capon Bridge Middle School',
  'Capps Elementary School',
  'Cappy Culver Elementary',
  'Capri Elementary',
  'Caprock Academy',
  'Caprock El',
  'Caprock H S',
  'Capron Elem School',
  'Capron Elementary',
  'Capshaw Elementary',
  'Capstone Academy',
  'Capstone Academy Charter School Sda South Campus',
  'Capstone Academy Milton Charter School',
  'Capstone Classical Academy',
  'Capstonewayne Co Juvenile Detention Fac',
  'Capt D Salinas Ii El',
  'Capt Johnston Blakely Elem Sch',
  'Capt Walter E Clarke Middle',
  'Capt William G Shoemaker',
  'Capt. Charles Wilkes Elem School',
  'Capt. Isaac Paine El. School',
  'Capt. Nathan Hale School',
  'Captain Albert W. Stevens School',
  'Captain Cooper Elementary',
  'Captain Gray Stem Elementary',
  'Captain James E. Daly Elementary',
  'Captain James Lawrence Elementary School',
  'Captain Jason M. Dahl Elementary',
  'Captain John Continuation High',
  'Captain John Smith Elementary',
  'Captain Leland Norton Elementary',
  'Captain Raymond Collins',
  'Captain Samuel Brown',
  'Captain Samuel Douglass Academy',
  'Captain Shreve High School',
  'Captain Strong',
  'Captain Vernon A Richards Hs-fire',
  'Captain Walter Francis Duke Elementary',
  'Capuano Early Childhood Center',
  'Capuchino High',
  'Car. Tech. Ctr. At Ft. Osage',
  'Caravel Middle School',
  'Caraway Int',
  'Carbon Career & Technical Institute',
  'Carbon High',
  'Carbon Hill Elementaryjunior High School',
  'Carbon Hill High School',
  'Carbon Valley Academy',
  'Carbonado Historical School 19',
  'Carbondale Area Jshs',
  'Carbondale Attendance Center',
  'Carbondale Comm H S',
  'Carbondale Community Charter School',
  'Carbondale El Sch',
  'Carbondale Middle School',
  'Carbone School/east Haven Academy',
  'Carden Of Tucson',
  'Carden Park Elem',
  'Cardenas Center',
  'Cardenas Elem School',
  'Carderock Springs Elementary',
  'Cardiff Elementary',
  'Cardiff J H',
  'Cardiff University',
  'Cardinal Academy',
  'Cardinal Charter Academy',
  'Cardinal Charter Academy West Campus',
  'Cardinal Community Academy Charter School',
  'Cardinal Elementary',
  'Cardinal Elementary Elementary',
  'Cardinal Elementary School',
  'Cardinal Forest Elementary',
  'Cardinal Heights Upper Middle',
  'Cardinal High School',
  'Cardinal High School High School',
  'Cardinal Junior High',
  'Cardinal Middle School',
  'Cardinal Ridge Elementary',
  'Cardinal Stefan Wyszynski University in Warsaw',
  'Cardinal Valley Elementary School',
  'Cardington Intermediate School',
  'Cardington-lincoln Elementary School',
  'Cardington-lincoln High School',
  'Cardington-lincoln Middle School',
  'Cardozo Ec',
  'Cardozo Middle',
  'Cardozo Middle School',
  'Cards Program',
  'Cardwell School',
  'Care & Treatment Hospital/homebound',
  'Care & Treatment Jdc/von Wald',
  'Care Concerned About Reaching Everyone',
  'Care Program',
  'Career & Academic Re-engagement Center',
  'Career & Tech Ed Ctr',
  'Career & Technical Center',
  'Career Academic Placement Program',
  'Career Academy And Technical School',
  'Career Academy At Truman High School',
  'Career Academy High School',
  'Career Academy Middle School',
  'Career Academyadult Education',
  'Career And Innovation Academy Of Orange',
  'Career And Life Trans Prg Tin Lake',
  'Career And Life Transition Program',
  'Career And Technical Center',
  'Career And Technical Education',
  'Career And Technical Education Center',
  'Career And Technology Academy',
  'Career And Technology Center',
  'Career And Technology Center Of Anderson 1 & 2',
  'Career And Technology Educational Centers',
  'Career Building Academy',
  'Career Center',
  'Career Center East',
  'Career Center Preschool',
  'Career College of Northern Nevada',
  'Career Connections Academy',
  'Career Development Center',
  'Career Development School',
  'Career Education Center',
  'Career Education Center Early College',
  'Career Enrichment',
  'Career Institute Of Technology',
  'Career Link',
  'Career Magnet Academy',
  'Career Opportunities Center',
  'Career Pathways',
  'Career Pathways Institute',
  'Career Pathways To Success Community School',
  'Career Prep Alternative',
  'Career Preparation',
  'Career Preparation Center',
  'Career Readiness Academy',
  'Career Readiness Academy At Mosley Plc',
  'Career Success High School - Glendale',
  'Career Success High School - Main Campus',
  'Career Success Jr/sr High School - North Phoenix',
  'Career Success School - Sage Campus',
  'Career Technical Center',
  'Career Technical Education Center',
  'Careerline Tech Center',
  'Careers In Sports High School',
  'Carencro Heights Elementary School',
  'Carencro High School',
  'Carencro Middle School',
  'Carey Elementary School',
  'Carey High School',
  'Carey Junior High School',
  'Carey Public School',
  'Carey Ridge Elementary School',
  'Caribbean K-8 Center',
  'Caribbean University',
  'Caribou High School',
  'Caribou Middle School',
  'Caribou Regionaltechnology Ctr',
  'Carillon Elementary School',
  'Caring & Sharing Learning School',
  'Carinos De Los Ninos',
  'Caritas University',
  'Carl A Furr Elementary',
  'Carl A Swanson Elementary Sch',
  'Carl Albert Hs',
  'Carl Albert Ms',
  'Carl Albert State College',
  'Carl B. Hutcherson Building',
  'Carl B. Munck Elementary',
  'Carl Ben Eielson Middle School',
  'Carl C Waitz El',
  'Carl C. Cutler Middle School',
  'Carl Cozier Elementary School',
  'Carl D Perkins Comprehensive Rehab Ctr',
  'Carl E Schluter El',
  'Carl E. Gilbert Elementary',
  'Carl G Fisher Elementary School 1',
  'Carl G Renn El Sch',
  'Carl G. Lauro El. School',
  'Carl H. Kumpf School',
  'Carl H. Sundahl Elementary',
  'Carl Hankey Elementary',
  'Carl Hankey Middle',
  'Carl Harvey Elementary',
  'Carl Hayden High School',
  'Carl I Bergerson Middle School',
  'Carl J Lamb School',
  'Carl J Polk Elementary School',
  'Carl Junction High School',
  'Carl Junction Intermediate',
  'Carl Junction Jr. High',
  'Carl Junction Primary 2-3',
  'Carl Junction Primary K-1',
  'Carl Junction Satellite School',
  'Carl Keen Voc',
  'Carl L Dixson Elementary School',
  'Carl Sandburg Center',
  'Carl Sandburg College',
  'Carl Sandburg Elem School',
  'Carl Sandburg Elementary',
  'Carl Sandburg High School',
  'Carl Sandburg Jr High School',
  'Carl Sandburg Middle Sch',
  'Carl Sandburg Middle School',
  'Carl Sandburg School',
  'Carl Schurz El',
  'Carl Scoggins Sr. Middle School',
  'Carl Smith Middle',
  'Carl Stuart Middle School',
  'Carl T Renton Jr High School',
  'Carl T. Mitnick School',
  'Carl W. Goetz Middle School',
  'Carl Wilde School 79',
  'Carl Wunsche Sr H S',
  'Carl von Ossietzky Universität Oldenburg',
  'Carle (william C.) High (continuation)',
  'Carle Place Middle Senior High School',
  'Carlena Chandler El',
  'Carleton College',
  'Carleton Elementary School',
  'Carleton Middle School',
  'Carleton P. Lightfoot Elementary',
  'Carleton University',
  'Carleton W Washburne School',
  'Carlile Elementary School',
  'Carlin C. Coppin Elementary',
  'Carlin Elementary School',
  'Carlin High School',
  'Carlin Junior High School',
  'Carlin Park Elementary School',
  'Carlin Springs Elementary',
  'Carlinville High School',
  'Carlinville Intermediate School',
  'Carlinville Middle School',
  'Carlinville Primary School',
  'Carlisle Area Hs',
  'Carlisle County Elementary School',
  'Carlisle County High School',
  'Carlisle County Middle School',
  'Carlisle El',
  'Carlisle Elementary School',
  'Carlisle High School',
  'Carlisle Middle School',
  'Carlisle School',
  "Carlisle-foster's Grove Elementary",
  'Carlmont High',
  'Carlock Elem School',
  'Carlos Alverio Pimentel',
  'Carlos Coon El',
  'Carlos E. Haile Middle School',
  'Carlos Elementary',
  'Carlos Escobar Lopez',
  'Carlos F Daniels (voc. De Area)',
  'Carlos F. Vigil Middle',
  'Carlos Gilbert Elementary',
  'Carlos Orama Padilla',
  'Carlos Pacheco',
  'Carlos Rey Elementary',
  'Carlos Rivera Ufret',
  'Carlos Santana Arts Academy',
  'Carlos Truan J H',
  'Carlota Matienzo',
  'Carlow College',
  'Carlow Institute of Technology',
  'Carlsbad Early College High',
  'Carlsbad High',
  'Carlsbad Intermediate School',
  'Carlsbad Seaside Academy',
  'Carlsbad Sixth Grade Academy',
  'Carlsbad Village Academy',
  'Carlson Elementary School',
  'Carlstadt Public School',
  'Carlton',
  'Carlton Elementary',
  'Carlton Hills Elementary',
  'Carlton Landing Academy Es',
  'Carlton Landing Academy Hs',
  'Carlton M. Viveiros Elementary School',
  'Carlton Oaks Elementary',
  'Carlton Palmore Elementary School',
  'Carlton Secondary',
  'Carlyle C Ring Elementary School',
  'Carlyle Elementary School',
  'Carlyle High School',
  'Carlyle Junior High School',
  'Carlynton Jshs',
  'Carmack',
  'Carman Park Early Childhood',
  'Carman Trails Elem.',
  'Carman-buckner Elem School',
  'Carmanainsworth Baker Career Academy',
  'Carmanainsworth High School',
  'Carmanainsworth Middle School',
  'Carmel Child Development Center',
  'Carmel Creek Elementary',
  'Carmel Del Mar Elementary',
  'Carmel Elementary',
  'Carmel Elementary School',
  'Carmel High',
  'Carmel High School',
  'Carmel Middle',
  'Carmel Middle School',
  'Carmel River Elementary',
  'Carmel Valley High',
  'Carmel Valley Middle',
  'Carmela Elementary',
  'Carmen Anaya El',
  'Carmen Aponte Santiago (cayuco)',
  'Carmen Arace Intermediate School',
  'Carmen Arace Middle School',
  'Carmen Arzuaga De Rivera',
  'Carmen Barroso Morales',
  'Carmen Belen Veiga',
  'Carmen Benitez',
  'Carmen Borras Batistini',
  'Carmen Casasus Marti (elem Urbana)',
  'Carmen D Ortiz Ortiz (su Sumidero)',
  'Carmen Dragon Elementary',
  'Carmen Feliciano Carrera (intermedia Bo Palmer)',
  'Carmen Flores',
  'Carmen Gomez Tejera',
  'Carmen Hi Southeast Campus',
  'Carmen High School Of Science And Technology South Campus',
  'Carmen Mid/hi Northwest Campus',
  'Carmen Middle School Of Science And Technology South Campus',
  'Carmen Noelia Peraza Toledo',
  'Carmen Pilar Santos',
  'Carmen Sanabria De Figueroa',
  'Carmen Sola De Pedreira',
  'Carmen V Avila El',
  'Carmenita Middle',
  'Carmi White Cnty Jr High Sch',
  'Carmi-white County High School',
  'Carmichael (bessie)/fec',
  'Carmichael El',
  'Carmichael Elementary',
  'Carmichael Elementary Preschool',
  'Carmichael Elementary School',
  'Carmichael Middle School',
  'Carmichaels Area El Ctr',
  'Carmichaels Area Hs',
  'Carmichaels Area Ms',
  'Carminati School',
  'Carmody Hills Elementary',
  'Carmody Middle School',
  'Carnage Middle',
  'Carnahan El',
  'Carnahan Sch. Of The Future',
  'Carnall Elementary School',
  'Carnation Elementary School',
  'Carnegie El Sch',
  'Carnegie Elem School',
  'Carnegie Es',
  'Carnegie Hs',
  'Carnegie Mellon University',
  'Carnegie Ms',
  'Carnegie Vanguard H S',
  'Carnelian Elementary',
  'Carnell Laura H Sch',
  'Carnes Elementary',
  'Carnesville Elementary Intermediate School',
  'Carnesville Elementary Primary School',
  'Carney Elementary',
  'Carney Es',
  'Carney Hs',
  'Carneynadeau School',
  'Caro Alternative High School',
  'Caro Center',
  'Caro High School',
  'Caro Middle School',
  'Carol City Elementary School',
  'Carol City Middle School',
  'Carol G. Peck Elementary School',
  'Carol Holt El',
  'Carol Huebner Early Childhood Program',
  'Carol Martin Gatton Academy',
  'Carol Moseley Braun School',
  'Carol Rae Ranch Elementary',
  'Carol Stream Elem School',
  'Caroldale Learning Community',
  'Carole Highlands Elementary',
  'Carolee Booker Elementary',
  'Carolina Academy (high)',
  'Carolina Beach Elementary',
  'Carolina Charter Academy: Cfa',
  'Carolina Elementary',
  'Carolina Forest Elementary',
  'Carolina Forest High',
  'Carolina International School',
  'Carolina Park Elementary',
  'Carolina School For Inquiry',
  'Carolina Springs Elementary',
  'Carolina Springs Middle',
  'Carolina Voyager Charter',
  'Carolinas College of Health Sciences',
  'Caroline Career & Technology Center',
  'Caroline Dormon Junior High School',
  'Caroline Elementary School',
  'Caroline G Atkinson School',
  'Caroline Harris Elementary',
  'Caroline High',
  'Caroline L. Reutter School',
  'Caroline Middle',
  'Caroline Sibley Elem School',
  'Caroline Street Elementary School',
  'Caroline Wenzel Elementary',
  'Carollton Elementary',
  'Carolyn A. Clark Elementary',
  'Carolyn And Vernon Madden El',
  'Carolyn G Bukhair El',
  'Carolyn Lewis Elementary School',
  'Carolyn Park Middle School',
  'Carolyn S Reedom Elementary School',
  'Carolyn Wenz Elem School',
  'Carondelet Leadership Academy',
  'Carpe Diem - Meridian Campus',
  'Carpe Diem - Northwest',
  'Carpe Diem - Shadeland',
  'Carpe Diem Collegiate High School Dba Carpe Diem E-learning',
  'Carpe Diem High (continuation)',
  'Carpe Diem Schools',
  'Carpenter Community Charter',
  'Carpenter Elementary',
  'Carpenter Elementary School',
  'Carpenter Hill El',
  'Carpenter Middle',
  'Carpenter School',
  'Carpenter Year Round Elem School',
  'Carpenters Elementary School',
  'Carpenters Middle School',
  'Carpentersville Middle School',
  'Carpinteria Family',
  'Carpinteria Middle',
  'Carpinteria Senior High',
  'Carquinez Middle',
  'Carr Creek Elementary School',
  'Carr Elementary',
  'Carr Elementary & Middle School',
  'Carr Lake Community Day',
  'Carr Lane Vpa Middle',
  'Carr Middle',
  'Carr Middle School',
  'Carrabec Community School',
  'Carrabec High School',
  'Carrboro Elementary',
  'Carrboro High',
  'Carrcroft Elementary School',
  'Carriage Crest Elementary School',
  'Carriage Drive Elementary',
  'Carriage Hill Elementary Sch',
  'Carriage Hills Es',
  'Carrie Busey Elem School',
  'Carrie E Tompkins School',
  'Carrie Gosch Pk Center',
  'Carrie Knause Early Childhood Learning Center',
  'Carrie L Lovejoy El',
  'Carrie Lee Elementary',
  'Carrie Martin Elementary School',
  'Carrie P. Meek/westview K-8 Center',
  'Carrie Palmer Weber Middle School',
  'Carrie Ricker School',
  'Carrier Es',
  'Carrier Mills-stonefort Elem Sch',
  'Carrier Mills-stonefort H S',
  'Carrigan 5/6 Intermediate School',
  'Carrillo El',
  'Carrillo Elementary',
  'Carrillo Intermediate Magnet School',
  'Carrillo Ranch Elementary',
  'Carrington College California-​Sacramento',
  'Carrington Elementary School',
  'Carrington Esc',
  'Carrington High School',
  'Carrington School',
  'Carrisa Plains Elementary',
  'Carrithers Middle',
  'Carrizo Springs El',
  'Carrizo Springs H S',
  'Carrizo Springs Int',
  'Carrizo Springs J H',
  'Carrizozo Elementary',
  'Carrizozo High',
  'Carrizozo Middle',
  'Carroll Academy',
  'Carroll Bell El',
  'Carroll Co Childhood Development Center',
  'Carroll Co Tech Center',
  'Carroll College',
  'Carroll Community College',
  'Carroll County Alt Learning Center',
  'Carroll County Area Technology Center',
  'Carroll County Career & Technology Center',
  'Carroll County Education Center',
  'Carroll County High',
  'Carroll County High School',
  'Carroll County Middle',
  'Carroll County Middle School',
  'Carroll Creek Montessori Public Charter School',
  'Carroll Early Childhood Campus',
  'Carroll El',
  'Carroll El Sch',
  'Carroll Elem School',
  'Carroll Elementary',
  'Carroll Elementary School',
  'Carroll Fowler Elementary',
  'Carroll H S',
  'Carroll High School',
  'Carroll High School Career Center',
  'Carroll Hill School',
  'Carroll Junior High School',
  'Carroll M Johnston Middle School',
  'Carroll Manor Elementary',
  'Carroll Middle',
  'Carroll Middle School',
  'Carroll Oakland Elementary',
  'Carroll Peak El',
  'Carroll Senior H S',
  'Carroll Senior High School',
  'Carroll Springs School',
  'Carroll T Welch El',
  'Carroll University',
  'Carroll-galax Reg Alt Ed Prgm/the Rae Ctr',
  'Carrolls Elementary',
  'Carrollton Area Career Center',
  'Carrollton Classical Academy',
  'Carrollton Early Childhood Center',
  'Carrollton El',
  'Carrollton Elementary',
  'Carrollton Elementary School',
  'Carrollton Grade School',
  'Carrollton High School',
  'Carrollton Jr. High School',
  'Carrollton Middle School',
  'Carrollton Middle-upper Elementary School',
  'Carrollton Senior High',
  'Carrolltowne Elementary',
  'Carrollwood Elementary School',
  'Carrsville Elementary',
  'Carruthers Elementary School',
  'Carson Academy',
  'Carson Adult Education Corrections',
  'Carson Adult Education Rg',
  'Carson City Correctional Facility',
  'Carson City Elementary School',
  'Carson City Montessori Charter School',
  'Carson Citycrystal Alternative Academy',
  'Carson Citycrystal High School',
  'Carson Citycrystal Upper Elementarymiddle School',
  'Carson El',
  'Carson Elem School',
  'Carson Elementary',
  'Carson Elementary School',
  'Carson High',
  'Carson High School',
  'Carson Junior High School',
  'Carson Middle',
  'Carson Middle School',
  'Carson Ms',
  'Carson School',
  'Carson Senior High',
  'Carson Street Elementary',
  'Carson Valley Middle School',
  'Carson-Newman College',
  'Carson-gore Academy Of Enviornmental Studies',
  'Carsonvilleport Sanilac Elementary School',
  'Carsonvilleport Sanilac Hs',
  'Carsonvilleport Sanilac Learning Center',
  'Carstens Elementarymiddle School',
  'Carter Academy',
  'Carter And Macrae El Sch',
  'Carter C. Raymond Junior High School',
  'Carter City Elementary School',
  'Carter Community Charter',
  'Carter County Career And Technical Center',
  'Carter County High School',
  'Carter Developmental Center',
  'Carter Elem School',
  'Carter Elementary',
  'Carter Elementary School',
  'Carter G Woodson Elementary',
  'Carter G Woodson School',
  'Carter G. Woodson Academy',
  'Carter G. Woodson Elementary School',
  'Carter G. Woodson Middle',
  'Carter G. Woodson Public Charter',
  'Carter High School',
  'Carter J H',
  'Carter Lake Elementary School',
  'Carter Lomax Middle',
  'Carter Middle School',
  'Carter Park El',
  'Carter Parramore Academy',
  'Carter School',
  'Carter Traditional Elementary',
  'Carter Virtual Academy',
  "Carter's Valley Elementary",
  'Carter-lawrence Elementary',
  'Carter-riverside H S',
  'Carteret Community College',
  'Carteret Elementary',
  'Carteret High School',
  'Carteret Middle School',
  'Cartersville Elementary School',
  'Cartersville High School',
  'Cartersville Middle School',
  'Cartersville Primary School',
  'Carterville Elem.',
  'Carterville High School',
  'Carterville Intermediate Sch',
  'Carterville Jr High Sch',
  'Carthage College',
  'Carthage Elementary',
  'Carthage Elementary School',
  'Carthage H S',
  'Carthage High School',
  'Carthage Intermediate Center',
  'Carthage J H',
  'Carthage Jr. High',
  'Carthage Middle School',
  'Carthage Pri',
  'Carthage Primary School',
  'Carthage Senior High School',
  'Carthage Technical Ctr.',
  'Carthay Elementary Of Environmental Studies Magnet',
  'Cartmell Elementary',
  'Cartoogechaye Elementary',
  'Cartwright Early Childhood Center - Closed',
  'Cartwright School',
  'Carus School',
  'Caruthers Elementary',
  'Caruthers High',
  'Caruthersville Elementary',
  'Caruthersville High',
  'Caruthersville Middle',
  'Carvajal Early Childhood Campus',
  'Carver (charles J.) Elementary',
  'Carver (g.w.) Educational Center',
  'Carver (george Washington) Elementary',
  'Carver Academy',
  'Carver Alter Lrn Ctr',
  'Carver Career Center',
  'Carver Center',
  'Carver College And Career Academy',
  'Carver Dual Language School',
  'Carver Early Childhood Academy',
  'Carver Early Childhood Ctr',
  'Carver Early Ee-kg',
  'Carver Edisto Middle',
  'Carver El',
  'Carver El Academy',
  'Carver Elementary',
  'Carver Elementary School',
  'Carver Elementarymiddle School',
  'Carver Exceptional Center',
  'Carver H S For Applied Tech/engineering/arts',
  'Carver Heights Elementary',
  'Carver High',
  'Carver High School',
  'Carver Magnet Elem. School',
  'Carver Magnet School',
  'Carver Middle',
  'Carver Middle School',
  'Carver Middle/high School',
  'Carver Military Academy Hs',
  'Carver Ms',
  'Carver Primary School',
  'Carver Road Middle School',
  'Carver Senior High School',
  'Carver Vocational-technical High',
  'Carver-lyon Elementary',
  'Carvers Bay High',
  'Carvers Bay Middle',
  'Cary Elementary',
  'Cary High',
  'Cary Jr High School',
  'Cary Reynolds Elementary School',
  'Cary Woods Elementary School',
  'Cary-grove Community High School',
  'Caryl E Adams Primary School',
  'Carylwood Intermediate School',
  'Caryn Elementary',
  'Carysbrook Elementary',
  'Caryville Elementary',
  'Casa',
  'Casa Academy',
  'Casa Blanca Community School',
  'Casa De Oro Elementary',
  'Casa Esperanza Montessori Charter School',
  'Casa Esperanza Recovery Home',
  'Casa Grande High',
  'Casa Grande Middle School',
  'Casa Grande Union High School',
  'Casa Loma Elementary',
  'Casa Ramona Academy For Technology Community And Education',
  'Casa Roble Fundamental High',
  'Casa Verde High School',
  'Casa View El',
  'Casals Elem School',
  'Casar Elementary',
  'Cascade 7-8',
  'Cascade Brook School',
  'Cascade Colony School',
  'Cascade Community Day',
  'Cascade Elementary',
  'Cascade Elementary School',
  'Cascade Heights Public Charter School',
  'Cascade High',
  'Cascade High School',
  'Cascade Home-link',
  'Cascade Jr/sr High School',
  'Cascade Junior High School',
  'Cascade Junior-senior High School',
  'Cascade K-8 Community School',
  'Cascade Locks School',
  'Cascade Middle School',
  'Cascade Opportunity Center',
  'Cascade Parent Partnership Program',
  'Cascade Ridge Elementary',
  'Cascade School',
  'Cascade Senior High School',
  'Cascade View Elementary',
  'Cascade View Elementary School',
  'Cascades High School',
  'Cascades School',
  'Cascadia Community College',
  'Cascadia Elementary',
  'Cascadia Technical Academy Skills Center',
  'Casco Bay High School',
  'Case',
  'Case Avenue El Sch',
  'Case Elementary School',
  'Case High',
  'Case Middle School',
  'Case Street Early Learning Center At Dtz',
  'Case Western Reserve University',
  'Caseville School K12',
  'Casey County Area Technology Center',
  'Casey County High School',
  'Casey County Middle School',
  'Casey El',
  'Casey Elementary',
  'Casey Elementary School',
  'Casey Middle School',
  'Casey Park Elementary School',
  'Casey-westfield Jr/sr Hs',
  'Caseyville Elementary School',
  'Cash El',
  'Cash Elementary',
  'Cash Valley Elementary',
  'Cashell Donahoe Elementary',
  'Cashell Elementary',
  'Cashion Es',
  'Cashion Hs',
  'Cashion Ms',
  'Cashmere High School',
  'Cashmere Middle School',
  'Cashton Elementary',
  'Cashton Middle/high',
  'Casiano Cepeda (intermedia)',
  'Casiano Cepeda (superior)',
  'Casiano Cepeda Pre Vocacional',
  'Casillas (joseph) Elementary',
  'Casimer M. Dallago Jr. Impact Center',
  'Casimir Middle',
  'Casimir Pulaski',
  'Casimir Pulaski School',
  'Casimir Pulaski School No. 8',
  'Casis El',
  'Casita Center For Science/math/technology',
  'Casman Alternative Academy',
  'Cason Lane Academy',
  'Casper Classical Academy',
  'Casper College',
  'Cass Career Ctr.',
  'Cass City Elementary',
  'Cass City Jr And Sr High School',
  'Cass Co. Elem.',
  'Cass County Career And Tech Center',
  'Cass Elementary School',
  'Cass High School',
  'Cass Jr High School',
  'Cass Lake-bena Area Lrng. Cntr.',
  'Cass Lake-bena Elementary',
  'Cass Lake-bena Middle School',
  'Cass Lake-bena Secondary',
  'Cass Middle School',
  'Cass Street Elementary',
  'Cass Technical High School',
  'Cassadaga Valley Middle/high School',
  'Cassady Alternative Elementary School',
  'Cassavant Elementary School',
  'Casselberry Elementary School',
  'Cassell Elem School',
  'Cassell Elementary',
  'Cassia County Day Treatment Center',
  'Cassia Jr/sr High School',
  'Cassidy Elementary School',
  'Cassingham Elementary School',
  'Cassopolis Alternative Ed',
  'Cassville Elementary',
  'Cassville High',
  'Cassville Intermediate',
  'Cassville Middle',
  'Cast Stem H S',
  'Cast Tech H S',
  'Castaic Elementary',
  'Castaic Middle',
  'Castaneda El',
  'Castelar Elementary School',
  'Castelar Street Elementary',
  'Castellanos Elem School',
  'Casterlin Elementary',
  'Castille Elementary',
  'Castillero Middle',
  'Castillo',
  'Castle Annex',
  'Castle Bridge School',
  'Castle Creek Elementary',
  'Castle Dale School',
  'Castle Dome Middle School',
  'Castle Elementary',
  'Castle Hayne Elementary',
  'Castle Heights Elementary',
  'Castle Heights Middle',
  'Castle Heights School',
  'Castle High School',
  'Castle Hill Elementary School',
  'Castle Hills El',
  'Castle Hills Elementary School',
  'Castle North Middle School',
  'Castle Park Elementary',
  'Castle Park Middle',
  'Castle Park Senior High',
  'Castle Rock',
  'Castle Rock Elementary',
  'Castle Rock Elementary School',
  'Castle Rock High School',
  'Castle Rock Middle School',
  'Castle South Middle School',
  'Castle Valley Center',
  'Castle View Elementary',
  'Castle View High School',
  'Castlebay Lane Charter',
  'Castleberry El',
  'Castleberry H S',
  'Castleford School',
  'Castleman Creek El',
  'Castlemont Elementary',
  'Castlemont High',
  'Castlemont Primary Academy',
  'Castleton Academy High School Of Oceanside',
  'Castleton Elementary School',
  'Castleton State University',
  'Castleton-hubbarton Usd #42',
  'Castlewood Elementary',
  'Castlewood Elementary - 02',
  'Castlewood High',
  'Castlewood High School - 01',
  'Castlewood Jr. High - 03',
  'Castlio Elem.',
  'Caston Elementary School',
  'Caston Jr-sr High School',
  'Castor High School',
  'Castro El',
  'Castro Elementary School',
  'Castro Valley Elementary',
  'Castro Valley High',
  'Castro Valley Virtual Academy',
  'Castroville El',
  'Castroville Elementary',
  'Caswell Elementary',
  'Caswell State Preschool',
  'Catahoula Elementary School',
  'Catalayah Es',
  'Catalina Elementary',
  'Catalina Foothills High School',
  'Catalina Foothills Valley View Early Learning Center',
  'Catalina High School',
  'Catalina Ventura School',
  'Catalyst Academy',
  'Catalyst Charter - Maria Es',
  'Catalyst Charter Middle',
  'Catalyst Charter-circle Rock Es',
  'Catamount School',
  'Catapult Academy',
  'Cataract Elementary',
  'Cataract Elementary School',
  'Catasauqua Ms',
  'Catasauqua Shs',
  'Catawba Elementary School',
  'Catawba Heights Elementary',
  'Catawba Rosenwald Education Center',
  'Catawba Springs Elementary',
  'Catawba Trail Elementary',
  'Catawba Valley Community College',
  'Catch Prep Charter High Inc.',
  'Cate Center Pre K',
  'Cater El',
  'Cates Academy',
  'Catharine Blaine K-8 School',
  'Catharine Joseph Sch',
  'Cathcart Elementary',
  'Cathedral City Elementary',
  'Cathedral City High',
  'Cathedral Home For Children',
  'Cathelene Thomas El',
  'Cather Elem School',
  'Catherine A Dwyer Elementary School',
  'Catherine A. Miller Elementary',
  'Catherine And Count Basie Middle School 72',
  'Catherine Bell El',
  'Catherine E. Doyle Elementary School',
  'Catherine Everett Elementary',
  'Catherine Kolnaski Magnet School',
  'Catherine L. Zane Middle',
  'Catherine M Mcnamara Elementary School',
  'Catherine M. Mcgee Middle School',
  'Catherine Strehle Elementary School',
  'Catherine T. Reed Elementary',
  'Catheryn Gates Elementary',
  'Cathey Middle',
  'Catholic University in Ruzomberok',
  'Catholic University in Zimbabwe',
  'Catholic University of America',
  'Catholic University of Eastern Africa',
  'Catholic University of Korea',
  'Catholic University of Lublin',
  'Catholic University of Malawi',
  'Catholic University of Pusan',
  'Catholic University of Taegu-Hyosung',
  'Catholic University of the Sacred Heart',
  'Catlettsburg Elementary School',
  'Catlin Elementary',
  'Catlin Magnet Center',
  'Cato Elementary School',
  'Cato Middle College High',
  'Cato-meridian Elementary School',
  'Cato-meridian Middle School',
  'Cato-meridian Senior High School',
  'Catoctin Elementary',
  'Catoctin High',
  'Catoma Elementary School',
  'Caton Middle',
  'Catons Chapel Elementary',
  'Catonsville Center For Alternative Studies',
  'Catonsville Elementary',
  'Catonsville High',
  'Catonsville Middle',
  'Catoosa Hs',
  'Catskill Avenue Elementary',
  'Catskill Elementary School',
  'Catskill Middle School',
  'Catskill Senior High School',
  'Cattaraugus-allegany-erie-wyoming Boces',
  'Cattaraugus-little Valley Elementary School',
  'Cattaraugus-little Valley High School',
  'Cattaraugus-little Valley Middle School',
  'Cattell Elementary School',
  'Caucasus University',
  'Caughlin Ranch Elementary School',
  'Caughman Road Elementary',
  'Cavalier Elementary School',
  'Cavalier High School',
  'Cavanaugh Elementary School',
  'Cavanaugh School',
  'Cavazos El',
  'Cavazos Middle',
  'Cave City Elementary School',
  'Cave City High Career & Collegiate Preparatory School',
  'Cave City Middle School',
  'Cave Hill School',
  'Cave Language Academy',
  'Cave Spring Elementary',
  'Cave Spring Elementary School',
  'Cave Spring High',
  'Cave Spring Middle',
  'Cave Springs Es',
  'Cave Springs Hs',
  'Cave Springs Ms',
  'Cavelero Mid High School',
  'Cavendish Town Elementary School',
  'Cavendish University',
  'Cavendish-teakean Elementary School',
  'Caverna Elementary School',
  'Caverna High School',
  'Caverna Middle School',
  'Cavett Elementary School',
  'Caviat - Ccc Central Campus',
  'Caviat - Coconino High School',
  'Caviat - Ecampus',
  'Caviat - Flagstaff Arts And Leadership Academy',
  'Caviat - Flagstaff Central Campus',
  'Caviat - Flagstaff High School',
  'Caviat - Fredonia High School',
  'Caviat - Grand Canyon High School',
  'Caviat - Page Central Campus',
  'Caviat - Page High School',
  'Caviat - Williams High School',
  'Caviat-williams Central Program',
  'Cavit - Casa Grande Union High School',
  'Cavit - Casa Verde High School',
  'Cavit - Central Arizona Valley Institute Of Technology',
  'Cavit - Coolidge High School',
  'Cavit - Florence Unified',
  'Cavit - Maricopa Unified',
  'Cavit - Poston Butte High School',
  'Cavit - San Tan Foothills High School',
  'Cavit - Santa Cruz Valley Union High School',
  'Cavit - Vista Grande',
  'Cawood Elementary School',
  'Cawston Elementary',
  'Cayce Elementary',
  'Cayetano Sanchez',
  'Cayucos Elementary',
  'Cayuga County Community College',
  'Cayuga El',
  'Cayuga Elementary School',
  'Cayuga H S',
  'Cayuga Heights Elementary School',
  'Cayuga Middle',
  'Cayuga School',
  'Cayuga-onondaga Boces',
  'Cayuse Prairie 7-8',
  'Cayuse Prairie School',
  'Caze Elementary School',
  'Cazenovia High School',
  'Cazenovia Middle School',
  'Cb Sabbath 68 Preparatory Academy',
  'Ccca - Fair Bluff /southeastern Campuses',
  'Ccjjaep',
  'Ccps Online',
  'Ccsd 93 Early Childhood Cntr',
  'Ccsd Virtual High School',
  'Ccsi Alternative School',
  'Cda Juvenile Detention Center',
  'Cds Elementary',
  'Cds Secondary',
  'Ce Hanna School',
  'Ceasar Cone Elementary',
  'Cecelia Hazelton School',
  'Cecelia Snyder Ms',
  'Cecil Avenue Math And Science Academy',
  'Cecil B Rigsby Elementary',
  'Cecil College',
  'Cecil County School Of Technology',
  'Cecil D Andrus Elementary',
  'Cecil El Sch',
  'Cecil Elementary',
  'Cecil Everett El',
  'Cecil Floyd Elem.',
  'Cecil H Parker School',
  'Cecil Intrmd Sch',
  'Cecil Manor Elementary',
  'Cecil Picard Elementary School At Maurice',
  'Cecil S Collins Elementary',
  'Cecile Trost Elementary School',
  'Cecilia High School',
  'Cecilia Junior High School',
  'Cecilia Lucero Solorio Elementary',
  'Cecilia Primary School',
  'Cecilio Lebron Ramos',
  'Cecilton Elementary',
  'Cecos Univeristy of Information Technology',
  'Cedar Bayou J H',
  'Cedar Bluff Elementary',
  'Cedar Bluff High School',
  'Cedar Bluff Middle School',
  'Cedar Bluffs Elementary School',
  'Cedar Bluffs High School',
  'Cedar Bluffs Middle School',
  'Cedar Bluffs Preschool',
  'Cedar Brook El',
  'Cedar Canyon Elementary School',
  'Cedar Canyon School',
  'Cedar Chapel Special School',
  'Cedar City High',
  'Cedar Cliff Hs',
  'Cedar Creek Community School',
  'Cedar Creek El',
  'Cedar Creek Elem',
  'Cedar Creek Elem.',
  'Cedar Creek Elementary School',
  'Cedar Creek H S',
  'Cedar Creek High School',
  'Cedar Creek Int',
  'Cedar Creek Middle',
  'Cedar Crest',
  'Cedar Crest Elementary School',
  'Cedar Crest Hs',
  'Cedar Crest Intermediate School',
  'Cedar Crest Ms',
  'Cedar Drive Middle School',
  'Cedar East School',
  'Cedar Elementary',
  'Cedar Elementary School',
  'Cedar Falls High School',
  'Cedar Forest Elementary',
  'Cedar Fork Elementary',
  'Cedar Grove Colony Elementary - 14',
  'Cedar Grove El',
  'Cedar Grove Elementary',
  'Cedar Grove Elementary School',
  'Cedar Grove High School',
  'Cedar Grove Middle',
  'Cedar Grove Middle School',
  'Cedar Grove-belgium Elementary',
  'Cedar Grove-belgium High',
  'Cedar Grove-belgium Middle',
  'Cedar Hall Community School',
  'Cedar Heights Elementary School',
  'Cedar Heights Es',
  'Cedar Heights Middle School',
  'Cedar Hill Collegiate Academy',
  'Cedar Hill Collegiate H S',
  'Cedar Hill Collegiate Prep',
  'Cedar Hill Elem.',
  'Cedar Hill Elementary School',
  'Cedar Hill H S',
  'Cedar Hill School',
  'Cedar Hills Elementary',
  'Cedar Hills Elementary School',
  'Cedar Hills School',
  'Cedar Hollow School',
  'Cedar Island Elementary',
  'Cedar Key High School',
  'Cedar Lane Early Childhood Center',
  'Cedar Lane Elementary',
  'Cedar Lane Elementary School',
  'Cedar Lane School',
  'Cedar Lane Special Center',
  'Cedar Lee Middle',
  'Cedar Middle',
  'Cedar Mill Elementary School',
  'Cedar Mountain Elementary',
  'Cedar Mountain H S',
  'Cedar Mountain Primary School',
  'Cedar Mountain Secondary',
  'Cedar North School',
  'Cedar Park Charter Academy',
  'Cedar Park Elementary School',
  'Cedar Park Elementary Stem School',
  'Cedar Park H S',
  'Cedar Park Middle',
  'Cedar Park Middle School',
  'Cedar Place Elementary School',
  'Cedar Point Elementary',
  'Cedar Ridge El',
  'Cedar Ridge Elem School',
  'Cedar Ridge Elem.',
  'Cedar Ridge Elementary',
  'Cedar Ridge Elementary School',
  'Cedar Ridge Es',
  'Cedar Ridge H S',
  'Cedar Ridge High',
  'Cedar Ridge High School',
  'Cedar Ridge Intermediate',
  'Cedar Ridge Middle',
  'Cedar Ridge Middle School',
  'Cedar Ridge Primary',
  'Cedar Ridge School',
  'Cedar River Elementary',
  'Cedar Riverside Community School',
  'Cedar Road Elementary',
  'Cedar Shoals High School',
  'Cedar South School',
  'Cedar Springs Academy',
  'Cedar Springs Elem.',
  'Cedar Springs High School',
  'Cedar Springs Middle School',
  'Cedar Street Community And Family Center',
  'Cedar Trails Elementary School',
  'Cedar Tree Academy Pcs',
  'Cedar Vale Elem',
  'Cedar Vale High',
  'Cedar Valley College',
  'Cedar Valley Community School',
  'Cedar Valley El',
  'Cedar Valley Elementary School',
  'Cedar Valley High',
  'Cedar Valley Middle',
  'Cedar Valley School',
  'Cedar View Elementary School',
  'Cedar Way Elementary',
  'Cedar Wood Elementary',
  'Cedarbrook Elementary School',
  'Cedarbrook Ms',
  'Cedarburg High',
  'Cedarcreek Elementary',
  'Cedarcrest High School',
  'Cedarcrest Middle School',
  'Cedarcrest School',
  'Cedarcrest-southmoor Elementary School',
  'Cedaredge Elementary School',
  'Cedaredge High School',
  'Cedaredge Middle School',
  'Cedargrove Elementary',
  'Cedarhome Elementary School',
  'Cedarhurst Elementary',
  'Cedarlane Academy',
  'Cedarmere Elementary',
  'Cedaroak Park Primary School',
  'Cedars Academy Next Generation H S At Highland',
  'Cedars International Academy',
  'Cedartown High School',
  'Cedartown Middle School',
  'Cedarville College',
  'Cedarville Elementary School',
  'Cedarville High School',
  'Cedarville Middle School',
  'Cedarville School',
  'Cedarwood Alternative Elementary School',
  'Cedarwood Elementary',
  'Cedric C Smith',
  'Ceferino Colon Lucca',
  'Ceiba College Preparatory Academy',
  'Celada Carretera',
  'Celal Bayar University',
  'Cele Middle',
  'Celebration High School',
  'Celebration School',
  'Celentano Biotech Health And Medical Magnet School',
  'Celerity Achernar Charter',
  'Celerity Cardinal Charter',
  'Celerity Crestworth Charter School',
  'Celerity Dalton Charter School',
  'Celerity Himalia Charter',
  'Celerity Lanier Charter School',
  'Celerity Nascent Charter',
  'Celerity Octavia Charter',
  'Celerity Palmati Charter',
  'Celerity Rolas Charter',
  'Celerity Woodmere Charter School',
  'Celeste El',
  'Celeste H S',
  'Celeste Henkel Elementary',
  'Celeste J H',
  'Celestine Elementary School',
  'Celestino Mauricio Soto Jr El',
  'Celia Clinton Es',
  'Celia Cruz Bronx High School Of Music (the)',
  'Celia Hays El',
  'Celina El',
  'Celina Elementary School',
  'Celina H S',
  'Celina High School',
  'Celina Int',
  'Celina Intermediate Elementary School',
  'Celina J H',
  'Celina K-8',
  'Celina Middle',
  'Celina Middle School',
  'Celina Pri',
  'Celina Primary School',
  'Celso Gonzalez Vaillant',
  'Cement Es',
  'Cement Hs',
  'Centauri High School',
  'Centauri Middle School',
  'Centaurus High School',
  'Centenary College of Louisiana',
  'Centennial A School For Expeditionary Learning',
  'Centennial Academy',
  'Centennial Academy Of Fine Arts Education',
  'Centennial Area Learning Center',
  'Centennial Arts Academy',
  'Centennial Avenue Elementary School',
  'Centennial Boces High School',
  'Centennial Campus Middle',
  'Centennial College',
  'Centennial Continuation High',
  'Centennial El',
  'Centennial Elem School',
  'Centennial Elementary',
  'Centennial Elementary Bethel',
  'Centennial Elementary Olympia',
  'Centennial Elementary School',
  'Centennial Elementary School Mt Vernon',
  'Centennial Es',
  'Centennial H S',
  'Centennial High',
  'Centennial High School',
  'Centennial Independent Study',
  'Centennial Jr High',
  'Centennial Jr-sr High School',
  'Centennial Lane Elementary',
  'Centennial Middle',
  'Centennial Middle School',
  'Centennial Ms',
  'Centennial Park School',
  'Centennial School',
  'Center',
  'Center Alternative',
  'Center At Riverport',
  'Center Avenue Sch',
  'Center City Pcs Brightwood',
  'Center City Pcs Capitol Hill',
  'Center City Pcs Congress Heights',
  'Center City Pcs Petworth',
  'Center City Pcs Shaw',
  'Center City Pcs Trinidad',
  'Center Drive School',
  'Center El',
  'Center El Sch',
  'Center Elem School',
  'Center Elem.',
  'Center Elementary',
  'Center Elementary School',
  'Center For Academic Success #4',
  'Center For Academic Success #5',
  'Center For Academic Success The #1',
  'Center For Academic Success The #2',
  'Center For Academic Success The #3',
  'Center For Advanced Learning',
  'Center For Advanced Technical Studies',
  'Center For Alternative Education',
  'Center For Alternative Learning',
  'Center For Alternative Programs',
  'Center For Autism',
  'Center For Autism Spectrum And Development Disorders',
  'Center For Career & Technical Education',
  'Center For Countywide Programs',
  'Center For Creative Learning',
  'Center For Diversified Studies',
  'Center For Early Lrning-north',
  'Center For Economicology',
  'Center For Educ Opp',
  'Center For Educational Excellence',
  'Center For Educational Opportunity',
  'Center For Forensic Psychiatry',
  'Center For Gifted Education',
  'Center For Global Studies',
  'Center For Innovative Learning',
  'Center For Inquiry School 2',
  'Center For Inquiry School 27',
  'Center For Inquiry School 70',
  'Center For Inquiry School 84',
  'Center For International Education A Cambridge Associate Sch',
  'Center For Learning And Educational Success',
  'Center For Lifelong Learning',
  'Center For Student Achievement',
  'Center For Student Lrng Cs Pennsbury',
  'Center For Technical Education Innovation',
  'Center Grange Primary School',
  'Center Grove Elementary School',
  'Center Grove High School',
  'Center Grove Middle School Central',
  'Center Grove Middle School North',
  'Center Grove School',
  'Center H S',
  'Center High',
  'Center High School',
  'Center Hill Elementary School',
  'Center Hill High School',
  'Center Hill Middle',
  'Center Int',
  'Center Line High School',
  'Center Mcmechen Elementary School',
  'Center Middle',
  'Center Moriches High School',
  'Center Moriches Middle School',
  'Center Of Applied Technology-north',
  'Center Of Applied Technology-south',
  'Center Point El',
  'Center Point Elementary School',
  'Center Point H S',
  'Center Point High School',
  'Center Point Middle',
  'Center Point-urbana High School',
  'Center Point-urbana Intermediate School',
  'Center Point-urbana Middle School',
  'Center Point-urbana Primary School',
  'Center Road School',
  'Center School',
  'Center School Extended',
  'Center Square School',
  'Center Sr. High',
  'Center Street Elem School',
  'Center Street Elementary',
  'Center Street Elementary School',
  'Center Street School',
  'Center Twp Sch',
  'Center Valley Elem. School',
  'Center Virtual Academy',
  'Center Woods School',
  'Center for Entrepreneurship and Small Business Management',
  'Center-stanton Elementary School',
  'Center-stanton High School',
  'Centerburg Elementary School',
  'Centerburg High School',
  'Centerburg Middle School',
  'Centereach High School',
  'Centerfield Elementary School',
  'Centerpoint Elementary School',
  'Centerpoint High School',
  'Centerpoint Intermediate Sch',
  'Centerpoint Primary School',
  'Centerra Mirage Stem Academy',
  'Centerton Elementary School',
  'Centerton Gamble Elementary',
  'Centertown Elementary',
  'Centerview Elementary',
  'Centerville 7-8',
  'Centerville Community Preschool Bldg',
  'Centerville El',
  'Centerville El Sch',
  'Centerville Elem.',
  'Centerville Elementary',
  'Centerville Elementary - 02',
  'Centerville Elementary School',
  'Centerville H S',
  'Centerville High (continuation)',
  'Centerville High School',
  'Centerville High School - 01',
  'Centerville Intermediate School',
  'Centerville Jr High',
  'Centerville Jr-sr H S',
  'Centerville Junior High',
  'Centerville Middle School - 03',
  'Centerville Ms',
  'Centerville Primary Village North',
  'Centerville Primary Village South',
  'Centerville School',
  'Centerville Sr High School',
  'Centerville-abington Elem School',
  'Centerville-abington Jr High Sch',
  'Centinela Elementary',
  'Centinela Valley Independent Study',
  'Central',
  'Central - Denison Elementary',
  'Central 3-4 Middle School',
  'Central 9th Grade Ctr',
  'Central A & M High School',
  'Central A & M Middle School',
  'Central Academy',
  'Central Academy High School',
  'Central Academy Middle',
  'Central Academy Nongraded Elementary School',
  'Central Academy Of Excellence',
  'Central Academy Of Ohio',
  'Central Academy Of Technology And Arts',
  'Central Academy School',
  'Central Academy of  Fine Art',
  'Central Agricultural University',
  'Central Alabama Community College',
  'Central America Health Sciences University (Belize Medical College)',
  'Central Area',
  'Central Area Adult Education Day-program',
  'Central Area Adult Education-night Program',
  'Central Area Learning Center',
  'Central Arizona College',
  'Central Aroostook Jr-sr H S',
  'Central Artes Visuales (especializada)',
  'Central Athens',
  'Central Avenue Elementary',
  'Central Avenue Elementary School',
  'Central Avenue Preschool',
  'Central Avenue School',
  'Central Baldwin Middle School',
  'Central Bay High (continuation)',
  'Central Boulevard Elementary School',
  'Central Brooklyn Ascend Charter School',
  'Central Bucks Hs-east',
  'Central Bucks Hs-south',
  'Central Bucks Hs-west',
  'Central Buganda University',
  'Central Cabarrus High',
  'Central Cambria Hs',
  'Central Cambria Ms',
  'Central Campus School',
  'Central Canyon Elementary School',
  'Central Career & Technical Sch',
  'Central Carolina Community College',
  'Central Carolina Technical College',
  'Central Cass Elementary School',
  'Central Cass High School',
  'Central Cass Middle School',
  'Central Charter School',
  'Central Child Development Center',
  'Central China Normal University',
  'Central City Cyberschool',
  'Central City Elem School',
  'Central City Elementary',
  'Central City Elementary School',
  'Central City High School',
  'Central City Middle School',
  'Central City Value',
  'Central Coast High',
  'Central Coast New Tech High',
  'Central College',
  'Central Columbia El Sch',
  'Central Columbia Ms',
  'Central Columbia Shs',
  'Central Comm High School',
  'Central Community College',
  'Central Community Elementary School',
  'Central Connecticut State University',
  'Central County Special Education Programs',
  'Central Crossing High School',
  'Central Dauphin East Ms',
  'Central Dauphin East Shs',
  'Central Dauphin Ms',
  'Central Dauphin Shs',
  'Central Davidson High',
  'Central Davidson Middle',
  'Central Davie Academy',
  'Central Davis Jr High',
  'Central Decatur Ms/hs High School',
  'Central Dewitt High School',
  'Central Dewitt Intermediate School',
  'Central Dewitt Middle School',
  'Central Early Childhood Center',
  'Central Education Campus',
  'Central Education Center',
  'Central Educational Services',
  'Central El',
  'Central El Sch',
  'Central Elem',
  'Central Elem School',
  'Central Elem.',
  'Central Elementary',
  'Central Elementary Grade School',
  'Central Elementary School',
  'Central Elementary/high School',
  'Central Es',
  'Central European University',
  'Central Falls Sr High',
  'Central Family Center',
  'Central Florida Leadership Academy Charter',
  'Central Freedom School',
  'Central Freshman Academy',
  'Central Freshman Campus',
  'Central Gaither Elementary',
  'Central Georgia Technical College',
  'Central Grade School',
  'Central Gwinnett High School',
  'Central H S',
  'Central Hamilton Elementary School',
  'Central Hardin High School',
  'Central Haywood High',
  'Central Head Start',
  'Central Heights El',
  'Central Heights Elem',
  'Central Heights Elementary',
  'Central Heights H S',
  'Central Heights High',
  'Central Heights Middle',
  'Central Heights School',
  'Central High',
  'Central High (continuation)',
  'Central High Area Learning Center',
  'Central High East Campus',
  'Central High Es',
  'Central High Hs',
  'Central High Jhs',
  'Central High Magnet Career Academy',
  'Central High School',
  'Central High School - 01',
  'Central High School - 41',
  'Central High School Of Clay County',
  'Central Howell Elementary School',
  'Central Hs',
  'Central Institute of English and Foreign Languages',
  'Central Institute of Fisheries Education',
  'Central Institute of Higher Tibetan Studies',
  'Central Intermediate Es',
  'Central Intermediate Sch',
  'Central Intermediate School',
  'Central Islip Early Childhood Center',
  'Central Islip Senior High School',
  'Central J H',
  'Central Jersey College Prep Charter School',
  'Central Jhs',
  'Central Jr High School',
  'Central Jr High School Of Clay County',
  'Central Jr-sr High',
  'Central Jr-sr High School',
  'Central Jr. High',
  'Central Junior High',
  'Central Junior High School',
  'Central Juvenile Hall',
  'Central Kitsap High School',
  'Central Kitsap Middle School',
  'Central Ky Technical College',
  'Central Lafourche High School',
  'Central Lake Public Schools',
  'Central Lakes College-​Brainerd',
  'Central Language Academy',
  'Central Learning Center',
  'Central Learning Center South',
  'Central Lee Elementary School',
  'Central Lee High School',
  'Central Lee Middle School',
  'Central Linn Elementary School',
  'Central Linn High School',
  'Central Luzon State University',
  'Central Lyon Elementary School',
  'Central Lyon Middle School',
  'Central Lyon Senior High School',
  'Central Magnet Math & Science',
  'Central Magnet School',
  'Central Maine Community College',
  'Central Maine Medical Center College of Nursing and Health Professions',
  'Central Manor El Sch',
  'Central Medford High School',
  'Central Methodist College',
  'Central Michigan University',
  'Central Middle',
  'Central Middle Alternative (cmap)',
  'Central Middle School',
  'Central Middle School Of Science',
  'Central Mindanao University',
  'Central Minnesota Jt. Powers Dist.',
  'Central Mn Alc Holdingford',
  'Central Mn Alc Kimball',
  'Central Mn Alc Sartell',
  'Central Mn Area Learn Cntr - Foley',
  'Central Mn. Alc - Sauk Rapids',
  'Central Montcalm Adult Education',
  'Central Montcalm Elementary School',
  'Central Montcalm High School',
  'Central Montcalm Middle School',
  'Central Montcalm Upper Elem School',
  'Central Montco Technical High School',
  'Central Montessori Elementary',
  'Central Mountain Hs',
  'Central Mountain Ms',
  'Central Ms',
  'Central Ne Support Service Program',
  'Central New Mexico Community College',
  'Central Nine Career Center',
  'Central Nm Correction',
  'Central Noble Junior Senior Hs',
  'Central Oak Es',
  'Central Oaks Academy',
  'Central Ohio Technical College',
  'Central Orange County Cte Partnership (ctep)',
  'Central Oregon Community College',
  'Central Ostrobothnia University of Applied Sciences',
  'Central Pa Digital Lrng Foundation Cs',
  'Central Pa Institute Of Science & Techno',
  'Central Park At Morning Star',
  'Central Park East High School',
  'Central Park East I',
  'Central Park East Ii',
  'Central Park Elem School',
  'Central Park Elem.',
  'Central Park Elementary',
  'Central Park Elementary School',
  'Central Park Middle School',
  'Central Park School For Child',
  'Central Pasco Girls Academy',
  'Central Philippine University',
  'Central Piedmont Community College',
  'Central Plains Elementary School - Holyrood',
  'Central Plains High School - Claflin',
  'Central Plains Middle School - Bushton',
  'Central Point Elementary School',
  'Central Police University',
  'Central Prep Academy',
  'Central Preston Middle School',
  'Central Primary',
  'Central Primary Sch',
  'Central Primary School',
  'Central Queens Academy Charter School',
  'Central Queensland University',
  'Central Radio and TV University',
  'Central Rayne Kindergarten School',
  'Central Region Deaf',
  'Central Regional High School',
  'Central Regional Middle School',
  'Central Ridge Elementary School',
  'Central Riverside Elementary School',
  'Central Road Elem School',
  'Central Saint Martins College of Art & Design',
  'Central School',
  'Central School (the)',
  'Central School Program',
  'Central Senior H S',
  'Central Senior High',
  'Central Sierra Rop',
  'Central South Forestry University',
  'Central South University',
  'Central Special School',
  'Central Springs Elem. Manly Campus',
  'Central Springs Elem. School - Nora Springs',
  'Central Springs High School',
  'Central Springs Middle School',
  'Central Square Intermediate School',
  'Central Square Middle School',
  'Central St. Hosp. Ed. Pr.',
  'Central State Academy',
  'Central Texas College',
  'Central Trail Elementary School',
  'Central Tree Middle',
  'Central Unified Alternative/opportunity',
  'Central Union High',
  'Central University College',
  'Central University for Nationalities',
  'Central University of Finance and Economics',
  'Central University of Technology Free State',
  'Central Va. Training Ctr.',
  'Central Valley Academy',
  'Central Valley Early Learning Center',
  'Central Valley Elem - Scotia',
  'Central Valley Elementary School',
  'Central Valley High',
  'Central Valley High (continuation)',
  'Central Valley High School',
  'Central Valley Home',
  'Central Valley Hs',
  'Central Valley Kindergarten Center',
  'Central Valley Ms',
  'Central Valley Pk - Greeley',
  'Central Valley Pk - Wolbach',
  'Central Vermont Career Center',
  'Central View Elementary',
  'Central Virginia Community College',
  "Central Virginia Governor's School",
  'Central Visual/perf. Arts High',
  'Central Wake Charter High',
  'Central Ward Elementary School',
  'Central Washington University',
  'Central Westmoreland Ctc',
  'Central Wilkes Middle',
  'Central Wisconsin Center',
  'Central Wisconsin Stem Academy',
  'Central Woodlands 56 School',
  'Central Wyoming College',
  'Central York Hs',
  'Central York Ms',
  'Centralhatchee Elementary School',
  'Centralia Alternative School',
  'Centralia Elem',
  'Centralia Elementary',
  'Centralia High',
  'Centralia High School',
  'Centralia Intermediate',
  'Centralia Jr High School',
  'Centralia Middle School',
  'Centralia Pre-kindergarten Ctr',
  'Centre',
  'Centre Avenue Elementary School',
  'Centre City Elementary School',
  'Centre College',
  'Centre Elementary School',
  'Centre Hall-potter El Sch',
  'Centre Learning Community Cs',
  'Centre Middle School',
  "Centre National d'Etudes Agronomiques des Régions Chaudes",
  'Centre Ridge Elementary',
  "Centre Universitaire d'Oum El Bouaghi",
  'Centre Universitaire de Jijel',
  'Centre Universitaire de Tebessa',
  'Centre Universitaire de Technologie',
  'Centre Universitaire de Tiaret',
  "Centre d'Etudes Supérieures Industrielles Paris",
  "Centre d'Etudes Supérieures des Techniques Industrielles",
  'Centre de Formation et de Perfectionnement des Journalistes',
  'Centredale School',
  'Centreville Adult Education',
  'Centreville Elementary',
  'Centreville Elementary School',
  'Centreville High',
  'Centreville Jr Sr High School',
  'Centreville Middle School',
  'Centro Adiest. Voc. Gabriel Bibiloni',
  'Centro Comunal',
  'Centro De Adiestramiento',
  'Centro De Adiestramiento Y Bellas Artes',
  'Centro Escolar University',
  'Centro Eugenio Maria De Hostos',
  'Centro Pre-voc. Jose Limon Arce',
  'Centro Regional Universitário de Espiríto Santo do Pinhal',
  'Centro Residencial De Oportunidades Educativas De Ceiba',
  'Centro Residencial De Oportunidades Educativas De Mayaguez',
  'Centro Residencial De Oportunidades Educativas De Villalba',
  'Centro Universitario Ciudad Vieja',
  'Centro Universitario Ixtlahuaca',
  'Centro Universitario Villanueva',
  'Centro Universitario de Occidente',
  'Centro Universitário Barao de Maua',
  'Centro Universitário Claretiano',
  'Centro Universitário Monte Serrat',
  'Centro Universitário Newton Paiva',
  'Centro Universitário Plinio Leite',
  'Centro Universitário Senac',
  'Centro Universitário Serra dos Órgãos',
  'Centro Universitário de Araraquara',
  'Centro Universitário de João Pessoa',
  'Centro Vocacional Especial',
  'Centro de Enseñanza Técnica Industrial',
  'Centro de Estudios Avanzados de Puerto Rico y el Caribe',
  'Centro de Estudios Investigación y Tecnología (CEIT)',
  'Centro de Estudios Universitarios Monterrey',
  'Centro de Estudios Universitarios Xochicalco',
  'Centura Elementary School',
  'Centura Secondary School',
  'Century Academy',
  'Century Academy For Excellence',
  'Century Alt High',
  'Century Career Center',
  'Century College',
  'Century Community Charter',
  'Century Elem.',
  'Century Elementary',
  'Century Elementary School',
  'Century High',
  'Century High School',
  'Century High School An Integrated Global Studies Acad',
  'Century Jr/sr High School',
  'Century Junior High',
  'Century Junior High School',
  'Century Middle School',
  'Century Middle School Within A Sch',
  'Century Oaks Elem School',
  'Century Park Elementary',
  'Century School',
  'Century Senior High',
  'Cerbat Elementary',
  'Ceredo Elementary School',
  'Ceredo-kenova Elementary',
  'Ceredo-kenova Middle School',
  'Ceres Elementary',
  'Ceres Elementary School',
  'Ceres High',
  'Ceres Special Education',
  'Cerra Vista Elementary',
  'Cerrillos Hoyos',
  'Cerritos College',
  'Cerritos Elementary',
  'Cerritos High',
  'Cerro Coso Community College',
  'Cerro Gordo Elem School',
  'Cerro Gordo Elementary',
  'Cerro Gordo Jr And Sr High Sch',
  'Cerro Gordo Medina',
  'Cerro Villa Middle',
  'Ces Segregated',
  'Cesar Batalla School',
  'Cesar Chavez Academy',
  'Cesar Chavez Academy Denver',
  'Cesar Chavez Academy Elementary',
  'Cesar Chavez Academy Elementary East',
  'Cesar Chavez Academy Intermediate',
  'Cesar Chavez Academy Jjaep',
  'Cesar Chavez College Preparatory School',
  'Cesar Chavez Community',
  'Cesar Chavez Community School',
  'Cesar Chavez Continuation High',
  'Cesar Chavez El',
  'Cesar Chavez Elementary',
  'Cesar Chavez Elementary School',
  'Cesar Chavez Es',
  'Cesar Chavez High',
  'Cesar Chavez High School',
  'Cesar Chavez Intermediate',
  'Cesar Chavez Junior High',
  'Cesar Chavez K-8 School',
  'Cesar Chavez Language Academy',
  'Cesar Chavez Learning Center',
  'Cesar Chavez Middle',
  'Cesar Chavez Middle School',
  'Cesar Chavez Pcs For Public Policy Capitol Hill',
  'Cesar Chavez Pcs For Public Policy Chavez Prep',
  'Cesar Chavez Pcs For Public Policy Parkside High',
  'Cesar Chavez Pcs For Public Policy Parkside Middle',
  'Cesar Chavez Pri',
  'Cesar E Chavez Academy',
  'Cesar E Chavez Community School',
  'Cesar E Chavez Elem',
  'Cesar E Chavez Elementary',
  'Cesar E. Chavez Elementary',
  'Cesar E. Chavez High',
  'Cesar E. Chavez Lrng Acad - Arts/theatre/entertain Mag',
  'Cesar E. Chavez Lrng Acads-acad Of Scientific Explr (ase)',
  'Cesar E. Chavez Lrng Acads-soc Just Humanitas Acad',
  'Cesar E. Chavez Lrng Acads-tech Prep Acad',
  'Cesar E. Chavez Middle',
  'Cessna Elem',
  'Cetronia Sch',
  'Cevro Institut College',
  'Cf Taylor Alternative School',
  'Cf Vigor High School',
  "Ch'ooshgai Community School",
  'Ch. Charan Singh University',
  'Chabot College',
  'Chabot Elementary',
  'Chaboya Middle',
  'Chace Street',
  'Chackbay Elementary School',
  'Chadbourn Elementary',
  'Chadbourn Middle',
  'Chadds Ford El Sch',
  'Chadron Intermediate',
  'Chadron Middle School',
  'Chadron Primary',
  'Chadron Senior High School',
  'Chadron State College',
  'Chadwell Elementary',
  'Chadwick Elem School',
  'Chadwick Elementary',
  'Chadwick High',
  'Chadwick Junior High School',
  'Chaffee County High School',
  'Chaffee Elem.',
  'Chaffee Jr.-sr. High',
  'Chaffee Trail Elementary',
  'Chaffey College',
  'Chaffey Community Day',
  'Chaffey District Online High',
  'Chaffey High',
  'Chagrin Falls High School',
  'Chagrin Falls Intermediate Elementary School',
  'Chagrin Falls Middle School',
  'Chahta-ima Elementary School',
  'Chain Exploration Center',
  'Chain Lake Elementary School',
  'Chain O Lakes Elementary',
  'Chain Of Lakes Collegiate High',
  'Chain Of Lakes Elementary School',
  'Chain Of Lakes Middle',
  'Chaires Elementary School',
  'Chairville Elementary School',
  'Chalk Butte School',
  'Chalker Elementary School',
  'Chalker High School',
  'Chalkville Elementary School',
  'Challand Middle School',
  'Challenge Academy',
  'Challenge Center - 51',
  'Challenge Charter School',
  'Challenge Early College H S',
  'Challenge Elementary',
  'Challenge H S Sheffield',
  'Challenge Preparatory Charter School',
  'Challenge School',
  'Challenge To Excellence Charter School',
  'Challenger 7 Elementary School',
  'Challenger Basic School',
  'Challenger Early College High',
  'Challenger El',
  'Challenger Elementary',
  'Challenger Elementary School',
  'Challenger High School',
  'Challenger Intermediate School',
  'Challenger K-8 School Of Science And Math',
  'Challenger Middle',
  'Challenger Middle School',
  'Challenger School Of Sports And Fitness',
  'Challenges Community Day',
  'Challis Elementary School',
  'Challis Jr/sr High School',
  'Chalmers Elem Specialty School',
  'Chalmers University of Technology',
  'Chalmette Elementary School',
  'Chalmette High School',
  'Chalone Peaks Middle',
  'Chaloner Middle School',
  'Chalybeate Elementary School',
  'Chama Elementary',
  'Chama Middle',
  'Chamberlain Adult/community Center',
  'Chamberlain Elementary - 03',
  'Chamberlain Elementary School',
  'Chamberlain High School',
  'Chamberlain High School - 01',
  'Chamberlain Jr. High - 02',
  'Chamberlain Middle School',
  'Chamberlain Ms',
  'Chamberlain Street School',
  'Chamberlayne Elementary',
  'Chamberlin El',
  'Chamberlin Elementary School',
  'Chamberlin Hill Elementary School',
  'Chamberlin School',
  'Chambers County Career Technology Center',
  'Chambers El',
  'Chambers Elementary',
  'Chambers Elementary School',
  'Chambers High School',
  'Chambers Hill El Sch',
  'Chambers Prairie Elementary School',
  'Chambers School',
  'Chambersburg Area Career Magnet Sch',
  'Chambersburg Area Ms - North',
  'Chambersburg Area Ms - South',
  'Chambersburg Area Shs',
  'Chamblee Charter High School',
  'Chamblee Middle School',
  'Chamisa Elementary',
  'Chamiza Elementary',
  'Chamois High',
  'Champ Cooper Elementary School',
  'Champaign Early Chldhd Cntr',
  'Champion Central Elementary School',
  'Champion Chandler',
  'Champion El',
  'Champion Elementary School',
  'Champion High School',
  'Champion Middle School',
  'Champion San Tan',
  'Champion Schools',
  'Championship Academy Of Distinction At Davie',
  'Championship Academy Of Distinction At Hollywood',
  'Championship Academy Of Distinction High School',
  'Championship Academy Of Distinction Middle School',
  'Championship Academy Of Distinction West Broward',
  'Champlain College',
  'Champlain School',
  'Champlain Valley Union High School',
  'Champlin Park High School',
  'Champlin/brooklyn Pk Acd Math Ensci',
  'Champs - Charter Hs Of Arts-multimedia & Performing',
  'Chamreun University of Poly Technology',
  'Chana Educ Center/rock River',
  'Chance Elem.',
  'Chancellor Avenue School',
  'Chancellor El',
  'Chancellor Elementary',
  'Chancellor High',
  'Chancellor Livingston Elementary School',
  'Chancellor Middle',
  'Chancery High Charter',
  'Chancey Elementary',
  'Chandler Academy',
  'Chandler Creek Elementary',
  'Chandler El',
  'Chandler Elementary',
  'Chandler Elementary Community',
  'Chandler Elementary School',
  'Chandler High School',
  'Chandler Hs',
  'Chandler Int',
  'Chandler Jhs',
  'Chandler Learning Academy',
  'Chandler Magnet',
  'Chandler Oaks El',
  'Chandler Online Academy',
  'Chandler Park Academy Elementary',
  'Chandler Park Academy High School',
  'Chandler Park Academy Middle School',
  'Chandler Traditional Academy - Goodman',
  'Chandler Traditional Academy - Independence',
  'Chandler Traditional Academy - Liberty Campus',
  'Chandler Traditional Academy-freedom',
  'Chandler Traditional Academy-humphrey',
  'Chandler View Elem School',
  'Chandler Woods Charter Academy',
  'Chandler-​Gilbert Community College',
  'Chandlers Elementary School',
  'Chandra Shekhar Azad University of Agriculture and Technology',
  'Chaney High',
  'Chaney-monge School',
  'Chang Gung University',
  'Chang Jung University',
  'Changchun Teachers College',
  'Changchun University of Technology',
  'Changemaker High School',
  'Chango Elementary School',
  'Changsha Railway University',
  'Changsha University of Electric Power',
  'Changwon National University',
  'Chanhassen Elementary',
  'Chanhassen High School',
  'Channahon Junior High School',
  'Channel Islands High',
  'Channel View School For Research',
  'Channelside Academy Middle School',
  'Channelside Academy Of Math And Science',
  'Channelview H S',
  'Channing Hall',
  'Channing Memorial Elem School',
  'Channing School',
  "Chantilly Governor's Stem Academy",
  'Chantilly High',
  'Chantilly High School Academy',
  'Chantilly Montessori',
  'Chanute Elementary School',
  'Chanute High',
  'Chao Yang University of Science and Technology',
  'Chaopraya University',
  'Chap Center',
  'Chaparral Elementary',
  'Chaparral Elementary School',
  'Chaparral High',
  'Chaparral High (continuation)',
  'Chaparral High School',
  'Chaparral Hills Elementary',
  'Chaparral Jr/sr High',
  'Chaparral Middle',
  'Chaparral Middle School',
  'Chaparral On-track Prek Center',
  'Chaparral Star Academy',
  'Chapel District Elementary',
  'Chapel Forge Early Childhood Center',
  'Chapel Glen Elementary School',
  'Chapel Grove Elementary',
  'Chapel Hill 7th & 8th Grade Center',
  'Chapel Hill Academy',
  'Chapel Hill El',
  'Chapel Hill Elem.',
  'Chapel Hill Elementary',
  'Chapel Hill Elementary School',
  'Chapel Hill H S',
  'Chapel Hill High',
  'Chapel Hill High School',
  'Chapel Hill J H',
  'Chapel Hill Middle',
  'Chapel Hill Middle School',
  'Chapel Lakes Elem.',
  'Chapel School',
  'Chapel Trail Elementary School',
  'Chapelfield Elementary School',
  'Chapelside Cleveland Academy',
  'Chapelwood Elementary School',
  'Chapin Elementary',
  'Chapin H S',
  'Chapin High',
  'Chapin Intermediate',
  'Chapin Middle',
  'Chapin Street Elementary School',
  'Chaplain Charles Watters School',
  'Chaplin Elementary School',
  'Chapman Elem',
  'Chapman Elementary',
  'Chapman Elementary School',
  'Chapman Heights Elementary',
  'Chapman High',
  'Chapman Hill Elementary School',
  'Chapman Hills Elementary',
  'Chapman Middle School',
  'Chapman Partnership Early Childhood Center N',
  'Chapman Partnership Early Childhood Center South',
  'Chapman School',
  'Chapman University',
  'Chapman Vocational Education Center',
  "Chapman's Retreat Elementary",
  'Chapmanville East Elementary School',
  'Chapmanville Middle School',
  'Chapmanville Regional High School',
  'Chappell Elem School',
  'Chappell Elementary',
  'Chappelow K-8 Magnet School',
  'Chaputnguak School',
  'Chardon High School',
  'Chardon Hills Elementary School',
  'Chardon Middle School',
  'Charem Alternative Schools',
  'Charem Special Education Schools',
  'Chariho Career And Tech Center',
  'Chariho High School',
  'Chariho Regional Middle School',
  'Charisma University',
  'Chariton High School',
  'Chariton Middle School',
  'Charity Adams Earley Girls Academy',
  'Charity Middle',
  'Charjean Elementary',
  'Charlemagne French Immersion Elementary School',
  'Charleroi Area El Ctr',
  'Charleroi Area Hs',
  'Charleroi Area Ms',
  'Charles & Phyllis Frias Elementary School',
  'Charles A Bernazzani Elementary',
  'Charles A Brown Elementary School',
  'Charles A Forbes Middle',
  'Charles A Gill El',
  'Charles A Huston Ms',
  'Charles A Lindbergh Elementary School',
  'Charles A Lindbergh Middle School',
  'Charles A Mooney School',
  'Charles A Mulligan Middle School',
  'Charles A Poupard Elem School',
  'Charles A Snow School',
  'Charles A Tindley Accelerated Sch',
  'Charles A Upson Elementary School',
  'Charles Acton El',
  'Charles Arthur Hughes Middle School',
  'Charles B Aycock High',
  'Charles B. Dubose Middle',
  'Charles Barnum School',
  'Charles Barrett Elementary',
  'Charles Baxter J H',
  'Charles Blackstock Junior High',
  'Charles Borchers El',
  'Charles Britt Academy',
  'Charles Bursch Elementary',
  'Charles C Ball Academy',
  'Charles C Cashman Elementary',
  'Charles C Mcglinnen School',
  'Charles Campagne School',
  'Charles Carroll Barrister Elementary',
  'Charles Carroll Middle',
  'Charles City County Elementary',
  'Charles City County High',
  'Charles City High School',
  'Charles City Middle Sch.',
  'Charles D Harrington',
  "Charles D'amico High School",
  'Charles D. Jones Junior High',
  'Charles Darwin University',
  'Charles David Wyche Jr Elementary School',
  'Charles Dewolf Middle School',
  'Charles Dickens School',
  'Charles Drew Charter School Ja/sa',
  'Charles Drew Elementary School',
  'Charles Drew Middle',
  'Charles E Boger Elementary',
  'Charles E Brown Middle',
  'Charles E Patterson Middle',
  'Charles E Riley Elementary School',
  'Charles E Roderick',
  'Charles E Schwarting School',
  'Charles E. Bennett Elementary School',
  'Charles E. Mack Elementary',
  'Charles E. Miner',
  'Charles E. Shea High School',
  'Charles E. Trefurt School',
  'Charles Elementary School',
  'Charles England Elementary School',
  'Charles Evans Es',
  'Charles F Fernandez Center Alt Learning',
  'Charles F Hard Elementary School',
  'Charles F Johnson Jr Elementary School',
  'Charles F Patton Ms',
  'Charles F Tigard Elementary School',
  'Charles F. Seabrook School',
  'Charles Francis Adams High School',
  'Charles G. Emery Elementary',
  'Charles Graebner El',
  'Charles H Boehm Ms',
  'Charles H Darden Middle',
  'Charles H Roth Junior High School',
  'Charles H Taylor',
  'Charles H Tuttle Elementary',
  'Charles H. Barrows Stem Academy',
  'Charles H. Bullock School',
  'Charles H. Castle Elementary',
  'Charles H. Chipman Elementary',
  'Charles H. Kelly Elementary School',
  'Charles H. Kim Elementary',
  'Charles H. Lee Elementary',
  'Charles H. Stillman Elementary School',
  'Charles Haskell Es',
  'Charles Hay World School',
  'Charles Helmers Elementary',
  'Charles Henderson High School',
  'Charles Henderson Middle',
  'Charles Herbert Flowers High',
  'Charles Hoffman Elementary',
  'Charles Huber Elementary School',
  'Charles J Caruso Middle School',
  'Charles J Prescott',
  'Charles J Riley School 9',
  'Charles J Sahs Elem School',
  'Charles J. Colgan Sr. High',
  'Charles J. Hudson School No. 25',
  'Charles Jaworek School',
  'Charles Kelly El Sch',
  'Charles L Bowers Farm',
  'Charles L Seipelt Elementary School',
  'Charles L Smith Elementary School',
  'Charles L Warstler Elementary School',
  'Charles L. Spragg School',
  'Charles Leroy Lowman Special Education Center',
  'Charles M. Burke Elementary School',
  'Charles M. Johnson Elementary',
  'Charles M. Schenck (cms) Community School',
  'Charles Maclay Middle',
  'Charles Mccann Vocational Technical',
  'Charles Middle',
  'Charles N Scott Middle School',
  'Charles N. Fortes Academy',
  'Charles Nash El',
  'Charles O Dickerson High School',
  'Charles O Stones Intermediate Ctr',
  'Charles Olbon School',
  'Charles P Murray Middle',
  'Charles Page Hs',
  'Charles Peck Elementary',
  'Charles Pinckney Elementary',
  'Charles R Drew K-8 Center',
  'Charles R Drew Middle School',
  'Charles R Drew Transition Center',
  'Charles R Hadley Elementary School',
  'Charles R Smith Early Chilhood Center',
  'Charles R. Drew Charter School',
  'Charles R. Drew High School',
  'Charles R. Leask Sr. Middle School',
  'Charles Reed Elementary Sch',
  'Charles Rice Learning Center',
  'Charles Russell Elementary School',
  'Charles S Ashley',
  'Charles S Pierce Middle',
  'Charles S. Rushe Middle School',
  'Charles School At Ohio Dominican University',
  'Charles Silvestri Junior High School',
  'Charles Spencer Elementary School',
  'Charles Straub Elementary School',
  'Charles Street School',
  'Charles Sturt University',
  'Charles Sumner',
  'Charles Sumner #18',
  'Charles Sumner Elementary School',
  'Charles T. Irizarry',
  'Charles T. Kranz Intermediate',
  'Charles Taylor Elementary',
  'Charles Town Middle School',
  'Charles University Prague',
  'Charles W Baker High School',
  'Charles W Eliot School',
  'Charles W Flanagan High School',
  'Charles W Longer El Sch',
  'Charles W Mccrary Elementary',
  'Charles W Morey',
  'Charles W Sechrist Elementary School',
  'Charles W Springmyer Elementary School',
  'Charles W Stanford Middle',
  'Charles W. Barrett Elementary',
  'Charles W. Eliot Middle',
  'Charles W. Harris School',
  'Charles W. Lewis Middle School',
  'Charles W. Tewinkle Middle',
  'Charles Wagner Elementary',
  'Charles Warren Fairbanks Sch 105',
  'Charles White Elementary',
  'Charles Wright Academy',
  'Charles Wright Elementary',
  'Charles Wright School',
  'Charlesmont Elementary',
  'Charleston Charter School For Math And Science',
  'Charleston Development Academy',
  'Charleston Elementary',
  'Charleston Elementary School',
  'Charleston High',
  'Charleston High School',
  'Charleston Middle',
  'Charleston Middle School',
  'Charleston Progressive',
  'Charleston School Of The Arts',
  'Charlestown El Sch',
  'Charlestown Elementary',
  'Charlestown Elementary School',
  'Charlestown High',
  'Charlestown Middle School',
  'Charlestown Primary School',
  'Charlestown Senior High School',
  'Charlevoix Elementary School',
  'Charlevoix Middle High School',
  'Charlevoix Montessori Academy For The Arts',
  'Charlie Marshall El',
  'Charlie Y. Brown Alt',
  'Charlo 7-8',
  'Charlo Elementary',
  'Charlo High School',
  'Charlotte & Jerry Keller Elementary',
  'Charlotte A Dunning',
  'Charlotte Amalie High School',
  'Charlotte Anderson El',
  'Charlotte Ave Elementary School',
  'Charlotte Avenue Elementary School',
  'Charlotte Central School',
  'Charlotte Choice Charter',
  'Charlotte Cox El',
  'Charlotte Early Middle College',
  'Charlotte El',
  'Charlotte Elementary',
  'Charlotte Elementary School',
  'Charlotte Engineering Early College-uncc',
  'Charlotte H S',
  'Charlotte Harbor School',
  'Charlotte High School',
  'Charlotte Hill Elementary School',
  'Charlotte Lab School',
  'Charlotte Lappla El Sch',
  'Charlotte Learning Academy',
  'Charlotte M Murkland Elementary',
  'Charlotte Middle',
  'Charlotte Middle School',
  'Charlotte N. Werner Elementary',
  'Charlotte Park Elementary',
  'Charlotte Patterson Elementary',
  'Charlotte Secondary',
  'Charlotte Senior High School',
  'Charlotte Sidway School',
  'Charlotte Teacher Early College',
  'Charlotte Technical College',
  'Charlotte Upper Elementary',
  'Charlotte Valley School',
  'Charlotte Virtual Franchise',
  'Charlotte Virtual Instruction Program',
  'Charlotte Wood Middle',
  'Charlottesville Alternative',
  'Charlottesville High',
  'Charlottesville Hosp. Ep.',
  'Charlottesville-albemarle Tech',
  'Charlton County High School',
  'Charlton Elementary',
  'Charlton Heights Elementary School',
  'Charlton Heston Academy',
  'Charlton Middle School',
  'Charlton Street',
  'Charlton-pollard El',
  'Charm Elementary School',
  'Charnock Road Elementary',
  'Charter Alternative Program (cap)',
  'Charter Alternatives Academy',
  'Charter Community School Home Study Academy',
  'Charter Day School',
  'Charter High School For Architecture And',
  'Charter High School For Law And Social Justice',
  'Charter High School Of The Americas',
  'Charter Home School Academy',
  'Charter Montessori Valley View Campus',
  'Charter Oak High',
  'Charter Oak International Academy',
  'Charter Oak Primary School',
  'Charter Oak-ute Elementary School',
  'Charter Oak-ute High School',
  'Charter Oak-ute Junior High School',
  'Charter School For Applied Technologies',
  'Charter School Of Educational Excellence',
  'Charter School Of Excellence',
  'Charter School Of Excellence 2',
  'Charter School Of Excellence At Davie',
  'Charter School Of Excellence At Riverland',
  'Charter School Of Excellence Davie 2',
  'Charter School Of Excellence Tamarac 1',
  'Charter School Of Excellence Tamarac 2',
  'Charter School Of Inquiry',
  'Charter School Of Morgan Hill',
  'Charter School Of New Castle',
  'Charter School Of San Diego',
  'Charter School Of The Dunes',
  'Charter School Of Wilmington',
  'Charter Schools Of Excellence Riverland',
  'Chartertech High School For The Performing Arts',
  'Chartiers Valley Hs',
  'Chartiers Valley Intrmd School',
  'Chartiers Valley Ms',
  'Chartiers Valley Primary Sch',
  'Chartiers-houston Jshs',
  'Charyl Stockwell Academy',
  'Charyl Stockwell Academy High School',
  'Chasco Elementary School',
  'Chasco Middle School',
  'Chase Avenue Elementary',
  'Chase City Elementary',
  'Chase County Elementary School',
  'Chase County High School',
  'Chase County Junior Senior High School',
  'Chase County Middle School',
  'Chase Elem',
  'Chase Elem School',
  'Chase Elementary',
  'Chase Elementary School',
  'Chase High',
  'Chase High School',
  'Chase Lake Elementary',
  'Chase Middle School',
  'Chase Stem Academy',
  'Chase Street Elementary',
  'Chase Street Elementary School',
  'Chaska Elementary',
  'Chaska High School',
  'Chaska Middle School East',
  'Chaska Middle School West',
  'Chassell K12 School',
  'Chastain Middle School',
  'Chastain Road Elementary',
  'Chastangfournier Middle School',
  'Chataignier Elementary School',
  'Chateau Estates Elementary School',
  'Chateaugay Elementary School',
  'Chateaugay High School',
  'Chateaux School',
  'Chatfield College',
  'Chatfield Elementary',
  'Chatfield Elementary School',
  'Chatfield High School',
  'Chatfield School',
  'Chatfield Secondary',
  'Chatfield-lopresti School',
  'Chatham Central High',
  'Chatham Charter',
  'Chatham College',
  'Chatham Correspondence',
  'Chatham Elem School',
  'Chatham Elementary',
  'Chatham Elementary School',
  'Chatham High',
  'Chatham High School',
  'Chatham Middle',
  'Chatham Middle School',
  'Chatham Park El Sch',
  'Chatham School Of Science & Engineering',
  'Chatom Elementary',
  'Chatom Elementary School',
  'Chatsworth Avenue School',
  'Chatsworth Charter High',
  'Chatsworth Elementary School',
  'Chatsworth Park Elementary',
  'Chatsworth School',
  'Chatt High Center For Creative Arts',
  'Chattahoochee County Education Center',
  'Chattahoochee County High School',
  'Chattahoochee County Middle School',
  'Chattahoochee Elementary School',
  'Chattahoochee High School',
  'Chattahoochee Hills Charter School',
  'Chattahoochee Technical College',
  'Chattahoochee Valley Community College',
  'Chattanooga Charter School Of Excellence',
  'Chattanooga Charter School Of Excellence Middle School',
  'Chattanooga Es',
  'Chattanooga Girls Leadership Academy',
  'Chattanooga Hs',
  'Chattanooga School For Arts And Sciences Csas Upper',
  'Chattanooga School For The Arts And Science Csas Lower',
  'Chattanooga School For The Liberal Arts',
  'Chattanooga State Community College',
  'Chattanooga Valley Elementary School',
  'Chattanooga Valley Middle School',
  'Chattaroy Elementary',
  'Chatterton School',
  'Chattooga Academy',
  'Chattooga High School',
  'Chaudhary Charan Singh Haryana Agricultural University',
  'Chaudhary Charan Singh University',
  'Chauncey Davis Elementary',
  'Chauncey Early-learning Center',
  'Chauncey H Duker School',
  'Chautauqua Charter School',
  'Chautauqua County Jail',
  'Chautauqua Elementary',
  'Chautauqua High (continuation)',
  'Chautauqua Lake Elementary School',
  'Chautauqua Lake Secondary School',
  'Chavez (cesar) Elementary',
  'Chavez Elem Multicultural Acad Ct',
  'Chavez Elementary',
  'Chavez Excel Academy',
  'Chavez H S',
  'Chavez Learning Station',
  'Chavez/huerta K-12 Preparatory Academy',
  'Chavies Elementary School',
  'Chawanakee Academy Charter',
  'Chazy Central Rural Elementary School',
  'Chazy Central Rural Junior-senior High School',
  'Cheat Lake Elementary School',
  'Cheatham Co Central',
  'Cheatham El',
  'Cheatham Elementary School',
  'Cheatham Hill Elementary School',
  'Cheatham Middle School',
  'Cheatham Park Elementary',
  'Chebanse Elem School',
  'Chebeague Island School',
  'Cheboygan Area High School',
  'Cheboygan Intermediate School',
  'Cheboygan Virtual Academy',
  'Check Elementary',
  'Checotah Hs',
  'Checotah Intermediate Es',
  'Checotah Ms',
  'Chee Dodge Elementary',
  'Cheektowaga High School',
  'Cheektowaga Middle School',
  'Cheesequake Elementary School',
  'Chegwin Elementary',
  'Chehalem Elementary School',
  'Chehalem Valley Middle School',
  'Chehalis Middle School',
  'Cheju National University',
  'Cheju National University of Education',
  'Chelan County Juvenile Detention Center',
  'Chelan High School',
  'Chelan Middle School',
  'Chelan School Of Innovation',
  'Cheldelin Middle School',
  'Cheljabinsk State Institute of Teacher Training',
  'Cheljabinsk State University',
  'Cheljabinsk University of Agricultural Engineering',
  'Chelmsford High',
  'Chelsea Career And Technical Education High School',
  'Chelsea College of Art and Design',
  'Chelsea Elem',
  'Chelsea Elem School',
  'Chelsea Elementary High School',
  'Chelsea Elementary School',
  'Chelsea Heights Elementary',
  'Chelsea Heights School',
  'Chelsea High',
  'Chelsea High School',
  'Chelsea Hs',
  'Chelsea Middle School',
  'Chelsea Ms',
  'Chelsea Park Elementary School',
  'Cheltenham El Sch',
  'Cheltenham Elementary School',
  'Cheltenham Hs',
  'Chelwood Elementary',
  'Chemawa Indian School',
  'Chemawa Middle',
  'Chemehuevi Valley Elementary',
  'Chemeketa Community College',
  'Chenal Elementary School',
  'Chenango Bridge Elementary School',
  'Chenango Forks Elementary School',
  'Chenango Forks High School',
  'Chenango Forks Middle School',
  'Chenango Valley High School',
  'Chenango Valley Middle School',
  'Chenega Bay School',
  'Cheney Elem',
  'Cheney Elementary',
  'Cheney High',
  'Cheney High School',
  'Cheney Middle School',
  'Cheney Middle School 6-8',
  'Cheney Open Doors',
  'Chengdu Institute of Sichuan International Studies University',
  'Chengdu University',
  'Chengdu University of Technology',
  'Chengdu University of Traditional Chinese Medicine',
  'Chenoweth Elementary',
  'Chenowith Elementary School',
  'Chequamegon High',
  'Chequamegon Middle',
  'Cheraw High',
  'Cheraw Intermediate',
  'Cheraw Primary',
  'Cheraw School',
  'Cheremoya Avenue Elementary',
  'Cheri Cox El',
  'Chernivci National University',
  'Cherokee',
  'Cherokee Bend Elementary School',
  'Cherokee Central Elementary School',
  'Cherokee Central High School',
  'Cherokee Charter Academy',
  'Cherokee County Career Technology Center',
  'Cherokee County High School',
  'Cherokee Elem School',
  'Cherokee Elementary',
  'Cherokee Elementary School',
  'Cherokee Es',
  'Cherokee Extension School',
  'Cherokee Heights Elementary School',
  'Cherokee Heights Middle',
  'Cherokee High School',
  'Cherokee Hs',
  'Cherokee Immersion Charter Sch',
  'Cherokee Lane Elementary',
  'Cherokee Middle',
  'Cherokee Middle School',
  'Cherokee Ms',
  'Cherokee Park Elementary School',
  'Cherokee Point Elementary',
  'Cherokee Ridge Elementary',
  'Cherokee School',
  'Cherokee Street Elementary School',
  'Cherokee Technology Center',
  'Cherokee Trail Elementary',
  'Cherokee Trail Elementary School',
  'Cherokee Trail High School',
  'Cherrelyn Elementary School',
  'Cherrington Elementary School',
  'Cherry Avenue Elementary School',
  'Cherry Avenue Middle',
  'Cherry Brook Primary School',
  'Cherry Chase Elementary',
  'Cherry Creek Charter Academy',
  'Cherry Creek Elementary School',
  'Cherry Creek High School',
  'Cherry Creek School',
  'Cherry Crest Elementary School',
  'Cherry Drive Elementary School',
  'Cherry El',
  'Cherry Elementary',
  'Cherry Hill Elem And Ec Center',
  'Cherry Hill Elementary/middle',
  'Cherry Hill Gt Program',
  'Cherry Hill High School East',
  'Cherry Hill High School West',
  'Cherry Hill Middle',
  'Cherry Hill Primary',
  'Cherry Hill Regional School',
  'Cherry Hill School',
  'Cherry Hill Tran-education Center',
  'Cherry Hill Transportation Education Program(tep)',
  'Cherry Hills Village Elementary School',
  'Cherry Knoll Elementary School',
  'Cherry Lane Elementary School',
  'Cherry Lane School',
  'Cherry Park Elementary School',
  'Cherry River Elementary School',
  'Cherry Road Elementary School',
  'Cherry Run Elementary',
  'Cherry Secondary',
  'Cherry Street Intermediate School',
  'Cherry Street School',
  'Cherry Tree Elementary School',
  'Cherry Valley Elem School',
  'Cherry Valley Elementary School',
  'Cherry Valley School',
  'Cherry Valley-springfield Central School',
  'Cherry View Elementary',
  'Cherrydale Elementary',
  'Cherryfield Elementary',
  'Cherryland Elementary',
  'Cherryland Middle School',
  'Cherrylee Elementary',
  'Cherryvale Elementary',
  'Cherryvale Sr / Middle School',
  'Cherryville Elementary',
  'Cherryville High',
  'Cherrywood Elementary',
  'Chesak Elementary School',
  'Chesaning Middle School',
  'Chesaning Union High School',
  'Chesaning Union Schools Alternative Education',
  'Chesapeake Alternative',
  "Chesapeake Bay Governor's School",
  'Chesapeake Bay Middle',
  'Chesapeake Career Center',
  'Chesapeake Charter School',
  'Chesapeake City Elementary',
  'Chesapeake College',
  'Chesapeake Elementary School',
  'Chesapeake High',
  'Chesapeake High School',
  'Chesapeake Math And It Elementary Public Charter',
  'Chesapeake Math And It Public Charter',
  'Chesapeake Math And It South Public Charter',
  'Chesapeake Middle School',
  'Chesapeake Science Point',
  'Chesapeake Secep Center',
  'Chesapeake Terrace Elementary',
  'Chesbrough Elementary School',
  'Cheshire Correctional Institution',
  'Cheshire Elementary',
  'Cheshire High School',
  'Cheshire Quinnipiac University Transition Collaborative',
  'Chesnee Elementary',
  'Chesnee High',
  'Chesnee Middle',
  'Chesney Elementary School',
  'Chesnut Elementary School',
  'Chesnut High (continuation)',
  'Chestatee Elementary',
  'Chestatee High School',
  'Chestatee Middle School',
  'Chester A. Moore Elementary School',
  'Chester Academy',
  'Chester Academy-middle/high School',
  'Chester Area Cyber School - 92',
  'Chester Boren Middle',
  'Chester Co Family Academy Cs',
  'Chester Community Cs',
  'Chester County Career Center',
  'Chester County High School',
  'Chester County Junior High School',
  'Chester County Middle School',
  'Chester County Tech College Hs Brandywine',
  'Chester County Tech College Hs Pennocks',
  'Chester County Tech College Hs Pickering',
  'Chester Creek Academy',
  'Chester Cs For The Arts',
  'Chester E Jordan',
  'Chester El',
  'Chester Elem School',
  'Chester Elementary',
  'Chester Elementary -02',
  'Chester Elementary School',
  'Chester F Miller School',
  'Chester Goodridge Elementary School',
  'Chester H S',
  'Chester H Thompson Elementary',
  'Chester High School',
  'Chester High School - 01',
  'Chester Hs',
  'Chester Junior/senior High',
  'Chester M. Stephens Elementary School',
  'Chester Middle',
  'Chester Middle School - 03',
  'Chester Park Elementary School For The Arts',
  'Chester Park Elementary School Of Literacy And Technology',
  'Chester Park School Elementary Of Inquiry',
  'Chester Senior High',
  'Chester Shell Elementary School',
  'Chester Street El Sch',
  'Chester Upland Sch Of Arts',
  'Chester Valley Elementary',
  'Chester W. Barrows School',
  'Chester W. Morrison Elementary',
  'Chester W. Nimitz Elementary',
  'Chester W. Nimitz Middle',
  'Chester W. Taylor Jr. Elementary School',
  'Chester-andover Usd #29',
  'Chester-east Lincoln Elem School',
  'Chester-joplin-inverness 7-8',
  'Chester-joplin-inverness Hs',
  'Chester-joplin-inverness Schl',
  'Chesterbrook Elementary',
  'Chesterfield Academy Elementary',
  'Chesterfield Career & Technical Center At Hull',
  'Chesterfield Career And Technical Center At Courthouse',
  'Chesterfield Central School',
  "Chesterfield Co Governor's Health/science Academy",
  'Chesterfield Elem.',
  'Chesterfield Elementary',
  'Chesterfield High',
  'Chesterfield Juvenile Detention Home',
  'Chesterfield Township Elementary School',
  'Chesterfield-ruby Middle',
  'Chesterton Elementary',
  'Chesterton Middle School',
  'Chesterton Senior High School',
  'Chestnut Academy',
  'Chestnut Accelerated Middle School (talented And Gifted)',
  'Chestnut Elementary School',
  'Chestnut Elementary School For Science And Engineering',
  'Chestnut Grove Elementary School',
  'Chestnut Grove Middle',
  'Chestnut Hill Community School',
  'Chestnut Hill El Sch',
  'Chestnut Hill Elementary School',
  'Chestnut Hill Middle School',
  'Chestnut Hill School',
  'Chestnut Intermediate Elementary School',
  'Chestnut Log Middle School',
  'Chestnut Mountain Elementary School',
  'Chestnut Oaks Middle',
  'Chestnut Ridge Central El Sch',
  'Chestnut Ridge Elementary School',
  'Chestnut Ridge Middle School',
  'Chestnut Ridge Midle School',
  'Chestnut Ridge Ms',
  'Chestnut Ridge Shs',
  'Chestnut Street School',
  'Chestnutwold El Sch',
  'Cheston El Sch',
  'Chet Burchett El',
  'Chet F. Harritt Elementary',
  "Chet's Creek Elementary School",
  'Chetek-weyerhaeuser High',
  'Chetek-weyerhaeuser Middle',
  'Chetek-weyerhaeuser Roselawn Elementary',
  'Chetopa Elem',
  'Chetopa High',
  'Chevak School',
  'Cheviot Elementary School',
  'Cheviot Hills Continuation',
  'Chevy Chase Elementary',
  'Chewelah Alternative',
  'Chews Elementary School',
  'Cheyenne Elementary - 06',
  'Cheyenne Elementary School',
  'Cheyenne Es',
  'Cheyenne High School',
  'Cheyenne Hs',
  'Cheyenne Mountain Elementary School',
  'Cheyenne Mountain High School',
  'Cheyenne Mountain Junior High School',
  'Cheyenne Ms',
  'Cheyenne Traditional School',
  'Cheyenne Wells Elementary School',
  'Cheyenne Wells Junior/high School',
  'Cheyenne-eagle Butte School',
  'Cheylin Elementary',
  'Cheylin Jr/sr High',
  'Cheyney University',
  'Chhatrapati Shahu Ji Maharaj University',
  'Chi Chi Rodriquez Academy',
  "Chi Chil'tah Comm School",
  'Chia Nana College of Pharmacy and Science',
  'Chiang Mai Rajabhat University',
  'Chiang Mai University',
  'Chiang Mai Vocational College',
  'Chiaramonte Elementary School',
  'Chiawana High School',
  'Chiba Institute of Technology',
  'Chiba Keizai University',
  'Chiba University',
  'Chiba University of Commerce',
  'Chicago Academy Elem School',
  'Chicago Academy High School',
  'Chicago Collegiate Charter School',
  'Chicago Excel Academy Hs',
  'Chicago Hs For Agricult Sciences',
  'Chicago Hs For The Arts',
  'Chicago International Charter',
  'Chicago Math & Sci Elem Charter',
  'Chicago Military Academy Hs',
  'Chicago Park Community Charter',
  'Chicago Park Elementary',
  'Chicago School of Professional Psychology',
  'Chicago Technology Academy Hs',
  'Chicago Virtual Charter School',
  'Chicago Vocational Career Acad Hs',
  'Chichester Central School',
  'Chichester Ms',
  'Chichester Shs',
  'Chickahominy Middle',
  'Chickamauga Elementary School',
  'Chickasaw City Elementary School',
  'Chickasaw City High School',
  'Chickasaw Elementary',
  'Chickasaw Elementary School',
  'Chickasaw Middle',
  'Chickasaw Middle School',
  'Chickasha Hs',
  'Chickasha Ms',
  'Chickering',
  'Chico Country Day',
  'Chico El',
  'Chico H S',
  'Chico High',
  'Chico Junior High',
  'Chico Middle',
  'Chicod',
  'Chicopee Academy',
  'Chicopee Comprehensive High School',
  'Chicopee Elementary School',
  'Chicopee High',
  'Chicora El Sch',
  'Chicora Elementary',
  'Chicot Elementary School',
  'Chiddix Jr High School',
  'Chief Brodie Elementary School',
  'Chief Charlo School',
  'Chief Dull Knife College',
  'Chief Ivan Blunka School',
  'Chief Joseph Elementary School',
  'Chief Joseph Middle School',
  'Chief Joseph School',
  'Chief Joseph School Of The Arts',
  'Chief Kamiakin Elementary School',
  'Chief Kanim Middle School',
  'Chief Kitsap Academy',
  'Chief Leschi School',
  'Chief Manuelito Middle',
  'Chief Moses Middle School',
  'Chief Paul Memorial School',
  'Chief Sealth International High School',
  'Chief Shikellamy Sch',
  'Chief Tahgee Elementary Academy',
  'Chief Umtuch Middle',
  'Chiefess Kamakahelei Middle School',
  'Chiefess Kapiolani Elementary School',
  'Chiefland Elementary School',
  'Chiefland Middle High School',
  'Chieftain Elementary School',
  'Chignik Bay School',
  'Chignik Lagoon School',
  'Chignik Lake School',
  'Chikaming Elementary School',
  'Chikushi Jogakuen University',
  'Chilchinbeto Community School',
  'Child Count Only',
  'Child Development Center',
  'Child Development Center At Woods Road',
  'Child Family Center',
  'Child Guidance Cntr Tgh School',
  'Child Haven',
  'Child Haven 6-12',
  'Child Service Center Preschool',
  'Childers Elementary',
  'Childers Ms',
  'Childersburg Elementary School',
  'Childersburg High School',
  'Childersburg Middle School',
  'Children Behavior Therapy Unit',
  'Children First',
  'Children First Academy - Tempe',
  'Children First Leadership Academy',
  'Children Of Promise Preparatory Academy',
  'Children Reaching For The Sky Preparatory',
  "Children's Aid College Preparatory Charter School",
  "Children's Center For Education",
  "Children's Charter School",
  "Children's Community Charter",
  "Children's Ctr",
  "Children's Kiva Montessori School",
  "Children's Lab School (the)",
  "Children's Medical Ctr",
  "Children's Place School",
  "Children's Shelter Of Sa",
  "Children's Village",
  "Children's Village Academy",
  "Children's Village At Hoover",
  "Children's Village Hayes",
  "Children's Village West",
  "Children's Workshop School (the)",
  'Childrens Center',
  'Childrens Crisis Stabilization Unit',
  'Childrens Psychiatry',
  'Childrens Village School',
  'Childress El',
  'Childress H S',
  'Childress J H',
  'Childs Elem School',
  'Childs Elementary School',
  'Childs George W Sch',
  'Childs Way Charter School',
  'Chiles Elementary School',
  'Chiles Middle School',
  'Chilhowee Elem.',
  'Chilhowee High',
  'Chilhowee Intermediate',
  'Chilhowee Middle School',
  'Chilhowie Elementary',
  'Chilhowie High',
  'Chilhowie Middle',
  'Chillicothe El',
  'Chillicothe Elementary Center',
  'Chillicothe H S',
  'Chillicothe High',
  'Chillicothe High School',
  'Chillicothe Jr High',
  'Chillicothe Middle',
  'Chillicothe Middle School',
  'Chillicothe School',
  'Chillum Elementary',
  'Chilmark Elementary',
  'Chiloquin Elementary School',
  'Chiloquin High School',
  'Chilton County Alternative School',
  'Chilton County High School',
  'Chilton Elementary',
  'Chilton High',
  'Chilton Middle',
  'Chilton School',
  'Chimacum Creek Primary School',
  'Chimacum Elementary School',
  'Chimacum High School',
  'Chimacum Middle School',
  'Chimayo Elementary',
  'Chimborazo Elementary',
  "Chime Institute's Schwarzenegger Community",
  'Chimney Lakes Elementary School',
  'Chimneyrock Elementary School',
  'Chin (john Yehall) Elementary',
  'China Academy of Art',
  'China Agricultural University',
  'China Agriculture University East',
  'China El',
  'China Foreign Affairs University',
  'China Grove Elementary',
  'China Grove Middle',
  'China Medical College',
  'China Medical University Shenyang',
  'China Middle School',
  'China Pharmaceutical University Nanjing',
  'China Primary School',
  'China Spring El',
  'China Spring H S',
  'China Spring Int',
  'China Spring Middle',
  'China Three Gorges University',
  'China USA Business University',
  'China University of Geoscience Beijing',
  'China University of Geosciences Wuhan',
  'China University of Mining Technology - Beijing',
  'China University of Mining Technology - Xuzhou',
  'China University of Political Science and Law',
  'China youth college for political science',
  'Chincoteague Elementary',
  'Chincoteague High',
  'Chinese Camp Elementary',
  'Chinese Culture University',
  'Chinese Education Center',
  'Chinese Immersion School At Deavila',
  'Chinese Language Immersion School',
  "Chinese People's Public Security University",
  'Chinese University of Hong Kong',
  'Ching Kuo Institue of Management & Health',
  'Chinhoyi University of Technology',
  'Chiniak School',
  'Chinju National University',
  'Chinju National University of Education',
  'Chinle Elementary School',
  'Chinle High School',
  'Chinle Junior High School',
  'Chino High',
  'Chino Hills High',
  'Chino Valley High School',
  'Chino Valley Learning Academy',
  'Chinook 7-8',
  'Chinook Elementary',
  'Chinook Elementary School',
  'Chinook High School',
  'Chinook Middle School',
  'Chinook Montessori Charter School',
  'Chinook Trail Elementary School',
  'Chinquapin Elementary',
  'Chip Richarte H S',
  'Chipeta Elementary School',
  'Chipley High School',
  'Chipman (w.t.) Middle School',
  'Chipman Junior High',
  'Chippens Hill Middle School',
  'Chippens Hill Middle School Functional Program',
  'Chipperfield El Sch',
  'Chippewa Correctional Facility',
  'Chippewa Elem School',
  'Chippewa Elementary School',
  'Chippewa Falls High',
  'Chippewa Falls Middle',
  'Chippewa High School',
  'Chippewa Hills High School',
  'Chippewa Hills Intermediate School',
  'Chippewa Middle School',
  'Chippewa Valley Adult And Mohegan Alt Educ',
  'Chippewa Valley High School',
  'Chippewa Valley Montessori Charter School',
  'Chippewa Valley Technical College',
  'Chireno El',
  'Chireno H S',
  "Chisago County Schools' Life Work C",
  'Chisago Lakes El.',
  'Chisago Lakes Family Center',
  'Chisago Lakes Hs Alt Learning Prog',
  'Chisago Lakes Middle',
  'Chisago Lakes Senior High',
  'Chisholm Elementary',
  'Chisholm Elementary School',
  'Chisholm Es',
  'Chisholm Hs',
  'Chisholm Life Skills Center',
  'Chisholm Middle School',
  'Chisholm Ms',
  'Chisholm Ridge',
  'Chisholm Secondary',
  'Chisholm Trail El',
  'Chisholm Trail Elem',
  'Chisholm Trail H S',
  'Chisholm Trail Int',
  'Chisholm Trail Middle',
  'Chisholm Trail Middle School',
  'Chisum El',
  'Chisum H S',
  'Chisum Middle',
  'Chitimacha Tribal School',
  'Chittagong University of Engineering and Technology',
  'Chittenango High School',
  'Chittenango Middle School',
  'Chittick Elementary School',
  'Chloe Clark Elementary',
  'Chms Goal',
  'Chocachatti Elementary School',
  'Chocksett Middle School',
  'Choconut Valley El Sch',
  'Chocowinity Middle',
  'Chocowinity Primary',
  'Choctaw Central High School',
  'Choctaw Central Middle School',
  'Choctaw Co Vocational Complex',
  'Choctaw County Elementary',
  'Choctaw County High School',
  'Choctaw Es',
  'Choctaw Hs',
  'Choctaw Ms',
  'Choctawhatchee Senior High School',
  'Chodang University',
  'Choffin Career Cntr',
  'Choice',
  'Choice Academy',
  'Choice Alternative High School',
  'Choice Alternative High School Is',
  'Choice Alternative School',
  'Choice Technical Academy',
  'Choices Alternative School',
  'Choices Educational Center',
  'Choices In Learning Charter',
  'Chokio-alberta Elementary',
  'Chokio-alberta Secondary',
  'Cholee Lake Elementary School',
  'Cholla Complex',
  'Cholla Elementary School',
  'Cholla High School',
  'Cholla Middle School',
  'Chollas/mead Elementary',
  'Chonbuk National University',
  'Chonbuk Sanup University of Technology (Howon University)',
  'Chongju National University of Education',
  'Chongju University',
  'Chongqing Education College',
  'Chongqing Medical University',
  'Chongqing Normal University',
  'Chongqing Normal University Foreign Trade and Business College',
  'Chongqing Technology and Business University',
  'Chongqing Telecommunication College',
  'Chongqing Three Gorges University',
  'Chongqing University',
  'Chongqing University of Communications',
  'Chongqing University of Post and Telecommunications',
  'Chongqing University of Science and Technology',
  'Chongqing University of Technology',
  'Chongqing Vocational College of Public Transportation',
  'Chongqing Wenli University',
  'Chonju National University of Education',
  'Chonnam National University',
  'Chopin Elem School',
  'Choptank Elementary School',
  'Chopticon High',
  'Chosun University',
  'Choteau 7-8',
  'Choteau High School',
  'Choteau School',
  'Choudrant Elementary School',
  'Choudrant High School',
  'Chouteau Elem.',
  'Chouteau Es',
  'Chouteau-mazie Ec Ctr',
  'Chouteau-mazie Hs',
  'Chouteau-mazie Ms',
  'Chowan Middle',
  'Chowchilla Union High',
  'Chris Yung Elementary',
  'Chrisman Elem School',
  'Chrisman High School',
  'Chrisman-scottland Jr High School',
  'Chrismont Safe School',
  'Chrisney Elementary School',
  'Christ University',
  'Christa Mcauliffe Academy',
  'Christa Mcauliffe Charter Elementary School',
  'Christa Mcauliffe Charter Public School',
  'Christa Mcauliffe Elem',
  'Christa Mcauliffe Elementary',
  'Christa Mcauliffe Elementary School',
  'Christa Mcauliffe Learning Center',
  'Christa Mcauliffe Middle',
  'Christa Mcauliffe Middle School',
  'Christa Mcauliffe School',
  'Christchurch Polytechnic Institute of Technology',
  'Christel House Academy South',
  'Christel House Academy West',
  'Christel House Dors South',
  'Christel House Dors West',
  'Christelijke Hogeschool Windesheim',
  'Christen Middle',
  'Christenberry Elementary',
  'Christene C Moss El',
  'Christensen Elementary',
  'Christian Acres Alternative School',
  'Christian Brothers University',
  'Christian County Alternative School',
  'Christian County Day Treatment',
  'Christian County High School',
  'Christian County Home/hospital',
  'Christian County Middle School',
  'Christian Park School 82',
  'Christian School',
  'Christian Sorensen Elementary',
  'Christian Theological Academy in Warszaw',
  'Christian Theological Seminary',
  'Christian-Albrechts-Universität Kiel',
  'Christiana Elementary',
  'Christiana High School',
  'Christiana Middle School',
  'Christiansburg Elementary',
  'Christiansburg High',
  'Christiansburg Middle',
  'Christiansburg Primary',
  'Christie El',
  'Christina Early Education Center',
  'Christina M. Eve Elementary School',
  'Christine Camacho El',
  'Christine Donnell School Of The Arts',
  'Christine Duncan Heritage Academy',
  'Christine Sipherd Elementary',
  'Christopher Avenue Community School',
  'Christopher C. Kraft Elementary',
  'Christopher Columbus',
  'Christopher Columbus Cs',
  'Christopher Columbus Elementary School',
  'Christopher Columbus Middle',
  'Christopher Columbus Middle School',
  'Christopher Columbus School #8',
  'Christopher Columbus School No. 15',
  'Christopher Dena Elementary',
  'Christopher Elem School',
  'Christopher Elementary',
  'Christopher Farms Elementary',
  'Christopher High',
  'Christopher House Chrt Es',
  'Christopher Newport University',
  'Christoval El',
  'Christoval H S',
  'Christus Santa Rosa',
  'Chrysalis Alternative',
  'Chrysalis Charter',
  'Chrysler Elementary School',
  'Chs Drop-out Reengagement Program',
  'Chshorizons School',
  'Chu Hai College',
  'Chualar Elementary',
  'Chubbuck Elementary School',
  "Chubu Gakuin University & Chubu Women's College",
  'Chubu University',
  'Chuckey Doak High School',
  'Chuckey Doak Middle School',
  'Chuckey Elementary',
  'Chugach Optional Elementary',
  'Chugiak Elementary',
  'Chugiak High School',
  'Chugwater Elementary',
  'Chugwater High School',
  'Chugwater Junior High School',
  'Chuka University',
  'Chukker Creek Elementary',
  'Chukyo Gakuin University',
  'Chukyo University',
  "Chukyo Women's University",
  'Chula Vista Hills Elementary',
  'Chula Vista Learning Community Charter',
  'Chula Vista Middle',
  'Chula Vista Senior High',
  'Chulalongkorn University',
  'Chumuckla Elementary School',
  'Chunchon National University of Education',
  'Chung Hua University',
  'Chung Shan Medical and Dental College',
  'Chung Yuan Christian University',
  'Chung-Ang University',
  'Chung-Ju National University',
  'Chungbuk National University',
  'Chungnam National University',
  'Chuo Gakuin University',
  'Chuo University',
  'Church Creek Elementary',
  'Church Hill Elementary',
  'Church Hill Elementary School',
  'Church Hill Intermediate School',
  'Church Hill Middle',
  'Church Hill Middle School',
  'Church Lane Elementary Technology',
  'Church Point Elementary School',
  'Church Point High School',
  'Church Point Middle School',
  'Church School',
  'Church Street Elementary School',
  'Church Street School',
  'Churchill',
  'Churchill Community Ed Center',
  'Churchill County Adult',
  'Churchill County High School',
  'Churchill County Middle School',
  'Churchill Elem School',
  'Churchill Elementary',
  'Churchill Elementary School',
  'Churchill H S',
  'Churchill High School',
  'Churchill Jr High',
  'Churchill Jr High School',
  'Churchill Park School',
  'Churchill Road Elementary',
  'Churchland Academy Elementary',
  'Churchland Elementary',
  'Churchland High',
  'Churchland Middle',
  'Churchland Preschool Center',
  'Churchland Primary & Intermediate',
  'Churchville El Sch',
  'Churchville Elementary',
  'Churchville Elementary School',
  'Churchville Middle School',
  'Churchville-chili Middle School',
  'Churchville-chili Senior High School',
  'Churubusco Elementary School',
  'Churubusco Jr-sr High School',
  'Chute Middle School',
  'Chuvash State University',
  'Cibola High',
  'Cibola High School',
  'Cibolo Creek El',
  'Cibolo Green',
  'Cibolo Valley El',
  'Cicely L. Tyson Community Elementary School',
  'Cicely L. Tyson Community Middle/high School',
  'Cicero East Elem School',
  'Cicero Elementary School',
  'Cicero West Elementary School',
  'Cicero-north Syracuse High School',
  'Cics - Avalon/south Shore',
  'Cics - Basil Campus',
  'Cics - Bond Campus',
  'Cics - Bucktown Campus',
  'Cics - Irving Park Campus',
  'Cics - Longwood Campus',
  'Cics - Loomis Primary Campus',
  'Cics - Northtown Campus',
  'Cics - Prairie Campus',
  'Cics - Quest North Campus',
  'Cics - Ralph Ellison Campus',
  'Cics - Washington Pk Campus',
  'Cics - West Belden Campus',
  'Cics - Wrightwood',
  'Cider Mill School',
  'Cielo Azul Elementary',
  'Cielo Vista Charter',
  'Cielo Vista El',
  'Cielo Vista Elementary',
  'Cien Aguas International',
  'Cienega Elementary',
  'Cienega High School',
  'Cihan University',
  'Cimarron Avenue Elementary',
  'Cimarron El',
  'Cimarron Elem',
  'Cimarron Elementary',
  'Cimarron Elementary School',
  'Cimarron Es',
  'Cimarron High',
  'Cimarron Hs',
  'Cimarron Memorial High School',
  'Cimarron Middle',
  'Cimarron Ms',
  'Cimarron Springs Elementary',
  'Cimino Elementary School',
  'Cincinnati College Preparatory Academy',
  'Cincinnati Generation Academy',
  'Cincinnati Learning Schools',
  'Cincinnati State Technical and Community College',
  'Cincinnati Technology Academy',
  'Cincinnatus Elementary School',
  'Cincinnatus High School',
  'Cincinnatus Middle School',
  'Cinco Ranch H S',
  'Cinco Ranch J H',
  'Cinema School (the)',
  'Cinnabar Charter',
  'Cinnabar Elementary',
  'Cinnaminson High School',
  'Cinnaminson Middle School',
  'Cinnamon Elementary',
  'Cip',
  'Cipriani Elementary',
  'Cipriano Manrique',
  'Circle Benton Elementary',
  'Circle Center Grade School',
  'Circle City Prep Charter School',
  'Circle Cross K8 Stem Academy',
  'Circle Greenwich Elementary',
  'Circle High',
  'Circle High School',
  'Circle Middle School',
  'Circle Of Independent Learning',
  'Circle Of Life Academy',
  'Circle Of Nations',
  'Circle Of Seasons Charter School',
  'Circle Oil Hill Elementary',
  'Circle School',
  'Circle Towanda Elementary',
  'Circle View Elementary',
  'Circles Of Success Learning Academy',
  'Circleville Elementary School',
  'Circleville High School',
  'Circleville Middle School',
  'Circleville Preschool',
  'Circleville School',
  'Circuit Breaker School',
  'Cirilo Santiago Plaud',
  'Cirrus Charter Academy',
  'Cirrus Charter High',
  'Cis Academy',
  'Cisco College',
  'Cisco El',
  'Cisco H S',
  'Cisco J H',
  'Cisco Learning Center',
  'Cisne High School',
  'Cisne Middle School',
  'Cisneros Pre-k Ctr',
  'Cissna Park Elementary School',
  'Cissna Park Jr High School',
  'Cissna Park Sr High School',
  'Citadel School',
  'Citi High School',
  'Citizen Edmond Genet School',
  'Citizens Academy',
  'Citizens Academy East',
  'Citizens Academy Southeast',
  'Citizens Leadership Academy',
  'Citizens Leadership Academy East',
  'Citizens Memorial School',
  'Citizens Of The World Charter',
  'Citizens Of The World Charter School 2',
  'Citizens Of The World Charter School Hollywood',
  'Citizens Of The World Charter School Mar Vista',
  'Citizens Of The World Charter School New York 1',
  'Citizens Of The World Charter School Silver Lake',
  'Citronelle High School',
  'Citrus Avenue Elementary',
  'Citrus College',
  'Citrus County Renaissance Center',
  'Citrus Cove Elementary School',
  'Citrus Elementary',
  'Citrus Elementary School',
  'Citrus Glen Elementary',
  'Citrus Grove Elementary',
  'Citrus Grove Elementary School',
  'Citrus Grove Middle School',
  'Citrus Health Sipp/crisis',
  'Citrus High',
  'Citrus High (continuation)',
  'Citrus High School',
  'Citrus Hill High',
  'Citrus Hills Intermediate',
  'Citrus Middle',
  'Citrus Mycroschool Of Integrated Academics And Technologies',
  'Citrus Park Elementary School',
  'Citrus Ridge A Civics Academy',
  'Citrus Springs Charter',
  'Citrus Springs Elementary School',
  'Citrus Springs Middle School',
  'Citrus Valley High',
  'Citrus Virtual Instruction (course Offerings)',
  'Citrus Virtual Instruction Program',
  'City Academy',
  'City Arts And Prep Pcs',
  'City Arts And Tech High',
  'City Chs',
  'City College Academy Of The Arts',
  'City College of San Francisco',
  'City Colleges of Chicago-​Harold Washington College',
  'City Colleges of Chicago-​Harry S Truman College',
  'City Colleges of Chicago-​Kennedy-​King College',
  'City Colleges of Chicago-​Malcolm X College',
  'City Colleges of Chicago-​Olive-​Harvey College',
  'City Colleges of Chicago-​Richard J Daley College',
  'City Colleges of Chicago-​Wilbur Wright College',
  'City Day Community School',
  'City Elementary',
  'City Garden Montessori School',
  'City Heights Elementary School',
  'City Heights Preparatory Charter',
  'City High School',
  'City Hill Middle School',
  'City Honors College Preparatory Academy',
  'City Honors School',
  'City Knoll Middle School',
  'City Language Immersion Charter',
  'City Middlehigh School',
  'City Neighbors Charter School',
  'City Neighbors Hamilton',
  'City Neighbors High School',
  'City Of Angels',
  'City Of Coral Springs Charter',
  'City Of Hialeah Education Academy',
  'City Of Medicine Academy',
  'City Of Palms Charter High School',
  'City Of Pembroke Pines Charter',
  'City On A Hill Charter Public School Circuit Street',
  'City On A Hill Charter Public School Dudley Square',
  'City On A Hill Charter Public School New Bedford',
  'City Park Elementary',
  'City Park Elementary School',
  'City Polytechnic High School',
  'City Springs Elementary',
  'City Terrace Elementary',
  'City University',
  'City University Athens',
  'City University Boys Preparatory',
  'City University College of Science and Technology',
  'City University Programs in Bulgaria',
  'City University School Girls Preparatory',
  'City University School Of Independence',
  'City University School Of Liberal Arts',
  'City University of Hong Kong',
  'City University of New York',
  'City View',
  'City View Charter School',
  'City View El',
  'City View Junior/senior High',
  'City/pembroke Pines Charter High School',
  'City/pembroke Pines Charter Middle School',
  'Citylab H S',
  'Cityside Middle School',
  'Cityview Community',
  'Civa Charter Academy',
  'Civano Charter School',
  'Civic Leadership Academy',
  'Civic Memorial High School',
  'Civicorps Corpsmember Academy',
  'Civil Aviation University of China',
  'Cj & Anne Hyman El',
  'Ck Schickler Elementary School',
  'Cl Home School Partnership',
  'Cl Scarborough Model Middle School',
  'Clack Middle',
  'Clackamas Academy Of Industrial Sciences',
  'Clackamas Community College',
  'Clackamas High School',
  'Clackamas Middle College',
  'Clackamas River Elementary School',
  'Clackamas Web Academy',
  'Claggett Creek Middle School',
  'Claggett Middle School',
  'Clague Middle School',
  'Claiborne County Voc Complex',
  'Claiborne Elementary School',
  'Claiborne Fundamental Elementary School',
  'Claiborne High School',
  'Claiborne Pell Elementary',
  'Claiborne School',
  'Clair-mel Elementary School',
  'Clairemont Elementary School',
  'Clairemont High',
  'Clairfield Elementary',
  'Clairmont Elementary',
  'Clairton El Sch',
  'Clairton Ms/hs',
  'Clallam Bay High & Elementary',
  'Clallam Co Juvenile Detention',
  'Clancy 7-8',
  'Clancy School',
  'Clanton Elementary School',
  'Clanton Intermediate School',
  'Clanton Middle School',
  'Clara B Bolen Elementary School',
  'Clara B Ford Academy Sda',
  'Clara B. Worth Elementary School',
  'Clara Barton',
  'Clara Barton El Sch',
  'Clara Barton Elementary',
  'Clara Barton Elementary School',
  'Clara Barton Hawthorne Elementary School',
  'Clara Barton High School',
  'Clara Brownell Middle School',
  'Clara Byrd Baker Elementary',
  'Clara E Coleman School',
  'Clara E Westropp School',
  'Clara E. Metz Elementary School',
  'Clara Elementary School',
  'Clara H Carlson School',
  'Clara Hall Accelerated School',
  'Clara J Peck Elementary',
  'Clara J. King Elementary',
  'Clara Love El',
  'Clara Oliver El',
  'Clara Peterson Elem School',
  'Clara Reynolds Es',
  'Clardy El',
  'Clardy Elem.',
  'Clare High School',
  'Clare Middle School',
  'Clare Pioneer High School',
  'Clare Primary School',
  'Claregladwin Area School',
  'Claremont Academy',
  'Claremont Academy Elem School',
  'Claremont Avenue Elementary School',
  'Claremont Colony Elementary - 04',
  'Claremont Elementary',
  'Claremont Es',
  'Claremont Graduate School',
  'Claremont High',
  'Claremont Immersion',
  'Claremont International High School',
  'Claremont McKenna College',
  'Claremont Middle',
  'Claremont Middle School',
  'Claremont Preschool Center',
  'Claremont School',
  'Claremore Hs',
  'Clarence B. Lamb Elementary School',
  'Clarence Boswell Elementary School',
  'Clarence Cannon Elem.',
  'Clarence Center Elementary School',
  'Clarence E Culver School',
  'Clarence Elem.',
  'Clarence Farrington School 61',
  'Clarence Lawson Elem.',
  'Clarence Lobo Elementary',
  'Clarence M Gockley El Sch',
  'Clarence Middle School',
  'Clarence R Edwards Middle',
  'Clarence Randall Elem School',
  'Clarence Ruth Elementary',
  'Clarence Senior High School',
  'Clarenceville High School',
  'Clarenceville Middle School',
  'Clarendon Alternative Elementary',
  'Clarendon Avenue Elementary',
  'Clarendon College',
  'Clarendon El',
  'Clarendon Elementary School',
  'Clarendon H S',
  'Clarendon High School',
  'Clarendon Hills Middle School',
  'Clarendon J H',
  'Clarendon No 4',
  'Clarendon School',
  'Clarinda Academy',
  'Clarinda High School',
  'Clarinda Middle School',
  'Clarion Area El Sch',
  'Clarion Area Jshs',
  'Clarion County Career Center',
  'Clarion University of Pennsylvania',
  'Clarion-goldfield-dows Elementary School',
  'Clarion-goldfield-dows High School',
  'Clarion-goldfield-dows Middle School',
  'Clarion-limestone Area Jshs',
  'Clarion-limestone El Sch',
  'Clark Acad Prep Magnet High Schl',
  'Clark Academy',
  'Clark Adult Program',
  'Clark Advanced Learning Center',
  'Clark Avenue School',
  'Clark Center',
  'Clark Co. High',
  'Clark Co. Middle',
  'Clark College',
  'Clark Colony Elementary - 05',
  'Clark County Area Technology Center',
  'Clark County Detention Center',
  'Clark County Jr/sr High School',
  'Clark County Juvenile Detention School',
  'Clark County Middle/high School',
  'Clark County Preschool',
  'Clark Creek Elementary School',
  'Clark Davidson Elem',
  'Clark Early Childhood Center',
  'Clark El',
  'Clark El Sch',
  'Clark Elem',
  'Clark Elem.',
  'Clark Elementary',
  'Clark Elementary - 02',
  'Clark Elementary School',
  'Clark Es',
  'Clark Fork Jr/sr High School',
  'Clark G R Elem School',
  'Clark H S',
  'Clark High School - 01',
  'Clark Int',
  'Clark Intermediate',
  'Clark J H',
  'Clark Je Preparatory Academy',
  'Clark Lane Middle School',
  'Clark Memorial School',
  'Clark Middle',
  'Clark Middle School',
  'Clark Middle School - 03',
  'Clark Mills School',
  'Clark Montessori High School',
  'Clark Moores Middle School',
  'Clark Pleasant Middle School',
  'Clark Preparatory Academy',
  'Clark Preschool Annex',
  'Clark Pri',
  'Clark School',
  'Clark St Community',
  'Clark State Community College',
  'Clark Street Community School',
  'Clark University',
  'Clark Wood El Sch',
  'Clark-vitt Elem.',
  'Clark-wilkins School',
  'Clarkdale Elementary School',
  'Clarkdale High School',
  'Clarkdale Middle School',
  'Clarkdale-jerome Elementary School',
  'Clarke',
  'Clarke Central High School',
  'Clarke Co Career & Technology Ctr',
  'Clarke College',
  'Clarke Community Elementary School',
  'Clarke Community High School',
  'Clarke County High',
  'Clarke County High School',
  'Clarke El',
  'Clarke El Ctr',
  'Clarke Elementary',
  'Clarke Middle',
  'Clarke Middle School',
  'Clarke Middle School School',
  'Clarke N. Johnsen Jr High',
  'Clarke School',
  'Clarke Street Elementary',
  'Clarkes Elementary School',
  'Clarkesville Elementary School',
  'Clarkfield Charter School',
  'Clarkrange High School',
  'Clarks Creek Elementary',
  'Clarks Point School',
  'Clarks Summit El Sch',
  'Clarksburg Elem.',
  'Clarksburg Elementary',
  'Clarksburg High',
  'Clarksburg Middle',
  'Clarksburg School',
  'Clarksdale High School',
  'Clarkshaw Magnet School',
  'Clarkson Elementary School',
  'Clarkson Jr-sr High School',
  'Clarkson University',
  'Clarkston Early Childhood Center',
  'Clarkston El',
  'Clarkston Elementary School',
  'Clarkston High School',
  'Clarkston Junior High School',
  'Clarkstown North Senior High School',
  'Clarkstown South Senior High School',
  'Clarksville Charter',
  'Clarksville Elementary',
  'Clarksville Elementary School',
  'Clarksville High',
  'Clarksville High School',
  'Clarksville Junior High School',
  'Clarksville Middle',
  'Clarksville Middle And H S',
  'Clarksville Middle School',
  'Clarksville Primary School',
  'Clarksville Senior High School',
  'Clarkton Elem.',
  'Clarkton High',
  'Clarkton School Of Discovery',
  'Clarmar Elementary School',
  'Clary Middle School',
  'Class Academy',
  'Class Act Charter',
  'Classen Hs Of Advanced Studies',
  'Classen Ms Of Advanced Studies',
  'Classic City High School',
  'Classical Academy',
  'Classical Academy Charter School',
  'Classical Academy High',
  'Classical Center At Brandenburg Middle',
  'Classical Center At Vial El',
  'Classical High',
  'Classical High School',
  'Classical Magnet School',
  'Classical Preparatory School',
  'Classical School',
  'Classical Studies Academy',
  'Clatskanie Elementary School',
  'Clatskanie Middle/high School',
  'Clatsop Community College',
  'Claude & Stella Parson Elementary School',
  'Claude A Huyck Elem',
  'Claude A Wilcox Elementary School',
  'Claude A. Taylor Elementary',
  'Claude Berkman El',
  'Claude Brown Elementary',
  'Claude Chester School',
  'Claude El',
  'Claude H S',
  'Claude O. Markoe Elementary School',
  'Claude Pepper Elementary School',
  'Claude Thompson Elementary',
  'Claudia Landeen',
  'Claudine F Brown Elementary School',
  'Claudio Ferrer Cotto',
  'Clausell Elementary School',
  'Clawson Elementary',
  'Clawson High School',
  'Clawson Middle School',
  'Clawson School',
  'Claxton Elementary',
  'Claxton Elementary School',
  'Claxton High School',
  'Claxton Middle School',
  'Claxton Regional Youth Detention Center',
  'Clay Academy',
  'Clay Avenue Community School',
  'Clay Center Community High',
  'Clay Center Community Middle',
  'Clay Central-everly Elementary',
  'Clay Central-everly Jr. Sr. High School',
  'Clay Charter Academy',
  'Clay City Elem School',
  'Clay City Elementary School',
  'Clay City High School',
  'Clay City Jr High School',
  'Clay City Jr-sr High School',
  'Clay Classical Academy',
  'Clay Co Learning Center - Fairfield',
  'Clay County Academies',
  'Clay County Area Technology Center',
  'Clay County Center For Adult & Community Education',
  'Clay County Elementary',
  'Clay County Headstart School',
  'Clay County High School',
  'Clay County Middle School',
  'Clay County Outreach Center',
  'Clay County Shelter Care',
  'Clay Court',
  'Clay El Sch',
  'Clay Elem School',
  'Clay Elem.',
  'Clay Elementary',
  'Clay Elementary School',
  'Clay High School',
  'Clay Hill Elementary',
  'Clay Hill Elementary School',
  'Clay Intermediate Center',
  'Clay Lamberton Elementary',
  'Clay Middle School',
  'Clay Springs Elementary',
  'Clay Virtual Academy',
  'Clay Virtual Franchise',
  'Clay Virtual Instruction (course Offerings)',
  'Clay Virtual Instruction Program',
  'Clay-battelle High School',
  'Claychalkville High School',
  'Claychalkville Middle School',
  'Claymont Elem.',
  'Claymont Elementary School',
  'Claymont High School',
  'Claymont Intermediate School',
  'Claymont Middle School',
  'Claymont Preschool',
  'Claymont Primary School',
  'Claypit Hill School',
  'Claypool Elementary School',
  'Clays Mill Elementary',
  'Clays Mill Elementary School',
  'Claysburg-kimmel El Sch',
  'Claysburg-kimmel Hs',
  'Claysville El Sch',
  'Claysville School',
  'Clayton (john M.) Elementary School',
  'Clayton A Bouton High School',
  'Clayton A Lodoen Kindergarten Center',
  'Clayton A. Record Jr. Elementary',
  'Clayton Avenue Elementary School',
  'Clayton Downing Middle',
  'Clayton El',
  'Clayton Elementary',
  'Clayton Elementary School',
  'Clayton H Symons Elementary School',
  'Clayton High',
  'Clayton High School',
  'Clayton Hs',
  'Clayton Huey Elementary School',
  'Clayton Intermediate School',
  'Clayton J. Davenport Elementary School',
  'Clayton Junior High',
  'Clayton Middle',
  'Clayton Middle School',
  'Clayton Partnership School',
  'Clayton Ridge Elementary School',
  'Clayton Ridge High School',
  'Clayton Ridge Middle School',
  'Clayton State College',
  'Clayton Valley Charter High',
  'Clayville School',
  'Claywell Elementary School',
  'Clc - Pre-kindergarten',
  'Cle Elum Roslyn Elementary',
  'Cle Elum Roslyn High School',
  'Clear Brook H S',
  'Clear Creek Amana High School',
  'Clear Creek Amana Middle School',
  'Clear Creek El',
  'Clear Creek Elem',
  'Clear Creek Elementary',
  'Clear Creek Elementary School',
  'Clear Creek H S',
  'Clear Creek High School',
  'Clear Creek Int',
  'Clear Creek Middle School',
  'Clear Falls H S',
  'Clear Fork District Elementary',
  'Clear Fork El',
  'Clear Fork High School',
  'Clear Fork Middle School',
  'Clear Fork Valley Preschool',
  'Clear Horizons Early College H S',
  'Clear Lake City El',
  'Clear Lake Elementary - 02',
  'Clear Lake Elementary School',
  'Clear Lake H S',
  'Clear Lake High',
  'Clear Lake High School',
  'Clear Lake Int',
  'Clear Lake Junior High',
  'Clear Lake Middle School - 04',
  'Clear Lake Middle School School',
  'Clear Passage Educational Center',
  'Clear Path Alternative School',
  'Clear Run El Ctr',
  'Clear Run Intrmd Sch',
  'Clear Sky Elementary',
  'Clear Spring El',
  'Clear Spring Elementary',
  'Clear Spring High',
  'Clear Spring Middle',
  'Clear Springs Elementary',
  'Clear Springs H S',
  'Clear View',
  'Clear View H S',
  'Clearbrook Elementary',
  'Clearbrook-gonvick Elementary',
  'Clearbrook-gonvick Secondary',
  'Clearcreek Elementary School',
  'Clearfield Area El Sch',
  'Clearfield Area Jshs',
  'Clearfield Colony Elementary - 07',
  'Clearfield County Ctc',
  'Clearfield Elementary School',
  'Clearfield High',
  'Clearfork El',
  'Clearlake Creativity',
  'Clearmont Elem School',
  'Clearmont Elementary',
  'Clearmont K-12 School',
  'Clearmount Elementary School',
  'Clearspring Elementary',
  'Clearstream Avenue School',
  'Clearview Adult Education Center',
  'Clearview Early Childhood Center',
  'Clearview El Sch',
  'Clearview Elem School',
  'Clearview Elem.',
  'Clearview Elementary',
  'Clearview High School',
  'Clearview Regional High School',
  'Clearview Regional Middle School',
  'Clearwater Adult Education Center',
  'Clearwater Creek Elementary',
  'Clearwater Elementary',
  'Clearwater Elementary School',
  'Clearwater Elementary West',
  'Clearwater Fundamental Middle School',
  'Clearwater High',
  'Clearwater High School',
  'Clearwater Intermediate',
  'Clearwater Intermediate Center',
  'Clearwater Middle',
  'Clearwater Middle School',
  'Clearwater Valley Elementary',
  'Clearwater Valley Jr/sr',
  'Clearwater/orchard High School',
  'Clearwater/orchard Middle School',
  'Clearwood Junior High School',
  'Cleaveland Traditional Magnet Elementary',
  'Cleburne County Alternative School',
  'Cleburne County Career Technical School',
  'Cleburne County Elementary School',
  'Cleburne County High School',
  'Cleburne County Middle School',
  'Cleburne H S',
  'Cleckler/heald El',
  'Clegern Es',
  'Clemencia Melendez',
  'Clemens Crossing Elementary',
  'Clemens Elementary',
  'Clemens Primary School',
  'Clement Avenue Elementary',
  'Clement Elementary',
  'Clement Middle',
  'Clemente Charter',
  'Clemente Community Academy Hs',
  'Clemente Ramirez Arellano',
  'Clemente Roberto Academy',
  'Clemente Roberto Ms',
  'Clementon Elementary School',
  'Clements H S',
  'Clements High School',
  'Clements Middle School',
  'Cleminson Elementary',
  'Clemmons Elementary',
  'Clemmons Middle School',
  'Clemson Elementary',
  'Clemson University',
  'Clendenin El',
  'Clendenin Elementary School',
  'Cleo Gordon Elementary',
  'Cleona El Sch',
  'Cleora Public School',
  'Cler-mont Elem.',
  'Clermont El Sch',
  'Clermont Elementary',
  'Clermont Elementary School',
  'Clermont Middle School',
  'Clermont Northeastern Elementary School',
  'Clermont Northeastern High School',
  'Clermont Northeastern Middle School',
  'Cleveland Academy For Scholarship Technology And Leadership',
  'Cleveland Arts And Social Sciences Academy',
  'Cleveland Bailey Es',
  'Cleveland Central High School',
  'Cleveland Central Middle School',
  'Cleveland College Preparatory School',
  'Cleveland Community College',
  'Cleveland Court Elementary School',
  'Cleveland Early College High',
  'Cleveland Echs',
  'Cleveland Eighteenth Avenue School',
  'Cleveland El Sch',
  'Cleveland Elem School',
  'Cleveland Elementary',
  'Cleveland Elementary - 14',
  'Cleveland Elementary School',
  'Cleveland Entrepreneurship Preparatory School',
  'Cleveland Es',
  'Cleveland H S',
  'Cleveland Heights High School',
  'Cleveland High',
  'Cleveland High School',
  'Cleveland High School For The Digital Arts',
  'Cleveland High School Stem',
  'Cleveland Hill Elementary School',
  'Cleveland Hill High School',
  'Cleveland Hill Middle School',
  'Cleveland Hs',
  'Cleveland Intermediate Es',
  'Cleveland Middle',
  'Cleveland Ms',
  'Cleveland Njrotc Academy',
  'Cleveland Prek-6 School',
  'Cleveland Preparatory Academy',
  'Cleveland Primary Es',
  'Cleveland Road Elementary School',
  'Cleveland School',
  'Cleveland School Of Architecture & Design',
  'Cleveland School Of Arts Lower Campus',
  'Cleveland School Of Science & Medicine',
  'Cleveland School Of The Arts High School',
  'Cleveland Secondary',
  'Cleveland State Community College',
  'Cleveland State University',
  'Cleveland Street Elementary School',
  'Cleveland Voc Tech Complex',
  'Clever Elem.',
  'Clever High',
  'Clewiston Adult School',
  'Clewiston High School',
  'Clewiston Middle School',
  'Clewiston Youth Developmental Academy',
  'Clifdale Elementary',
  'Cliff Elementary',
  'Cliff High',
  'Cliff Island School',
  'Cliff Park High School',
  'Cliffdale Elementary',
  'Clifford Crone Middle School',
  'Clifford D. Murray Elementary',
  'Clifford Davis El',
  'Clifford E Bryant Comm School',
  'Clifford Elementary',
  'Clifford H Marshall Elementary',
  'Clifford H Smart Middle School',
  'Clifford H. Nowlin Middle',
  'Clifford J Lawrence Junior High School',
  'Clifford Johnson School',
  'Clifford M Granger',
  'Clifford Meigs Middle School',
  'Clifford N Pritts El Sch',
  'Clifford O Findlay Middle',
  'Clifford O Taylor/kirklane Elementary',
  'Clifford P Carlson Elem School',
  "Clifford S. Hardison Governor's Health Sciences Academy",
  'Clifford Street Elementary',
  'Clifford Wise Intermediate/middle School',
  'Cliffside Elementary School',
  'Cliffside Park High School',
  'Cliffwood Elementary School',
  'Clifton Ave Grade School',
  'Clifton E Lawrence',
  'Clifton Early Childhood',
  'Clifton El',
  'Clifton Elementary School',
  'Clifton H S',
  'Clifton High School',
  'Clifton Hills Elementary',
  'Clifton Middle',
  'Clifton Park El',
  'Clifton Ridge Middle School',
  'Clifton T. Barkalow School',
  'Clifton-clyde Grade School K-3',
  'Clifton-clyde Middle School',
  'Clifton-clyde Sr High',
  'Clifton-fine Elementary School',
  'Clifton-fine Junior-senior High School',
  'Cliftondale Elementary School',
  'Clifty Creek Elementary School',
  'Climax Elementary',
  'Climax Secondary',
  'Climax Springs Elem.',
  'Climax Springs High',
  'Climaxscotts Elementary School',
  'Climaxscotts High School',
  'Climaxscotts Virtual Academy',
  'Clinch County Elementary School',
  'Clinch County High School',
  'Clinch County Middle School',
  'Clinch River Community School',
  'Clinch School',
  'Clinch Valley College',
  'Clint H S',
  'Clint Isd Early College Academy',
  'Clint Isd Jjaep',
  'Clint J H School',
  'Clint Q Smith El',
  'Clinton 7-8',
  'Clinton Alternative School',
  'Clinton Avenue School',
  'Clinton Central Elementary School',
  'Clinton Central Junior-senior Hs',
  'Clinton Co. R-iii Middle',
  'Clinton Community College',
  'Clinton Community Schools Preschool Latchkey Center',
  'Clinton County Area Technology Center',
  'Clinton County Early Childhood Center',
  'Clinton County Education Center',
  'Clinton County High School',
  'Clinton County Middle School',
  'Clinton County Sheriffs Department',
  'Clinton Early Childhood Center',
  'Clinton Elem School',
  'Clinton Elementary',
  'Clinton Elementary School',
  'Clinton Grove Elementary',
  'Clinton High',
  'Clinton High School',
  'Clinton Hs',
  'Clinton Intermediate School',
  'Clinton Jr Hi School',
  'Clinton Jr High School',
  'Clinton Middle',
  'Clinton Middle School',
  'Clinton Ms',
  'Clinton P Russell El',
  'Clinton Park Elem School',
  'Clinton Prairie Elementary School',
  'Clinton Prairie Jr-sr High School',
  'Clinton Public School',
  'Clinton Rosette Middle School',
  'Clinton School',
  'Clinton Senior High',
  'Clinton Senior High School',
  'Clinton Sr. High',
  'Clinton Technical Sch.',
  'Clinton Township Middle School',
  'Clinton V Bush Elementary School',
  'Clinton Valley Elementary School',
  'Clinton Virtual Jrsr High School',
  'Clinton Voc Complex',
  'Clinton West Es',
  'Clinton William Jefferson',
  'Clinton Young Elementary School',
  'Clinton-essex-warren-washington Boces',
  'Clinton-graceville-beardsley El.',
  'Clinton-graceville-beardsley Sr.',
  'Clinton-massie Elementary School',
  'Clinton-massie High School',
  'Clinton-massie Middle School',
  'Clintondale High School',
  'Clintondale Middle School',
  'Clintondale Virtual Academy',
  'Clintonville Elementary School',
  'Clintonville High',
  'Clintonville Middle',
  'Clintwood Elementary',
  'Clio Area High School',
  'Clio Community High School',
  'Clio Elementary',
  'Clio Learning Center',
  'Clip',
  'Clippard Elem.',
  'Clippert Academy',
  'Clissold Elem School',
  'Clive Learning Academy',
  'Clk Elementary School',
  'Clnton/mrn/wshngtn Pre-kind Prog',
  'Cloonan School',
  'Clopper Mill Elementary',
  'Clopton Elem.',
  'Clopton High',
  'Cloquet Area Alt. Ed. Programs',
  'Cloquet Early Childhood Education',
  'Cloquet Middle',
  'Cloquet Senior',
  'Cloud County Community College',
  'Cloud Elem',
  'Cloud Peak Elementary School',
  'Cloud Peak Middle School',
  'Cloud Preschool',
  'Cloud Springs Elementary School',
  'Cloudcroft Elementary',
  'Cloudcroft High',
  'Cloudcroft Middle',
  'Cloudland Elementary School',
  'Cloudland High School',
  'Clough Pike Elementary School',
  'Clough Prek Center',
  'Cloutierville Elementary School',
  'Clover Avenue Elementary',
  'Clover Creek Elementary',
  'Clover Creek High School',
  'Clover Flat Elementary',
  'Clover Garden',
  'Clover High',
  'Clover Hill Elementary',
  'Clover Hill High',
  'Clover Middle',
  'Clover Park Early Learning Program',
  'Clover Park High School',
  'Clover Park Technical College',
  'Clover Ridge Elementary',
  'Clover Ridge Elementary School',
  'Clover Street School',
  'Clover Valley High (continuation)',
  'Cloverbank Elementary School',
  'Clovercroft Elementary School',
  'Cloverdale Elem School',
  'Cloverdale Elementary',
  'Cloverdale Elementary School',
  'Cloverdale High',
  'Cloverdale High School',
  'Cloverdale Middle School',
  'Cloverland Elementary',
  'Cloverleaf El',
  'Cloverleaf Elementary',
  'Cloverleaf Elementary School',
  'Cloverleaf High School',
  'Cloverleaf Home Education',
  'Cloverleaf Middle School',
  'Cloverly Elementary',
  'Cloves C Campbell Sr Elementary School',
  'Clovis Community College',
  'Clovis Community Day Elementary',
  'Clovis Community Day Secondary',
  'Clovis East High',
  'Clovis Elementary',
  'Clovis Grove Elementary',
  'Clovis High',
  'Clovis Hs Freshman Academy',
  'Clovis North High',
  'Clovis Online Charter',
  'Clovis Point',
  'Clovis West High',
  'Club Boulevard Elementary',
  'Club Estates',
  'Club Hill El',
  'Clubview Elementary School',
  'Cluster Springs Early Learning Center',
  'Cluster Springs Elementary',
  'Cluster V Sp Ed',
  'Clute Int',
  'Clyattville Elementary School',
  'Clyde Arbuckle Elementary',
  'Clyde Boyd Ms',
  'Clyde C Cox Elementary School',
  'Clyde C Hadden Elementary School',
  'Clyde Campbell Elementary',
  'Clyde D Mease Elementary School',
  'Clyde El',
  'Clyde Elementary',
  'Clyde Elementary School',
  'Clyde Erwin Elementary',
  'Clyde F Brown',
  'Clyde H S',
  'Clyde Hamrick Elem.',
  'Clyde High School',
  'Clyde Hill Elementary',
  'Clyde Int',
  'Clyde J H',
  'Clyde L. Fischer Middle',
  'Clyde Miller K-8',
  'Clyde Reeves',
  'Clyde Riggs Elementary',
  'Clyde S. Jennings Elementary School',
  'Clyde W. Needham Elementary',
  'Clyde Woodworth Elementary',
  'Clyde-savannah Elementary School',
  'Clyde-savannah High School',
  'Clyde-savannah Middle School',
  'Clymer Central School',
  'Cm West',
  'Cmsd Alternative School',
  'Cne Early Childhood Academy',
  'Cnusd Hybrid School Of Innovation',
  'Coachella Valley High',
  'Coachella Valley Usd Special Ed Prek',
  'Coahoma Community College',
  'Coahoma County Jr/sr High School',
  'Coahoma Early College High School',
  'Coahoma El',
  'Coahoma H S',
  'Coahoma J H',
  'Coahulla Creek High School',
  'Coakley Middle',
  'Coal Canyon Hs',
  'Coal City Early Childhood Center',
  'Coal City Elem School',
  'Coal City Elementary',
  'Coal City High School',
  'Coal City Intermediate School',
  'Coal City Middle School',
  'Coal Creek Canyon K-8 Elementary School',
  'Coal Creek Elementary School',
  'Coal Mountain Elementary School',
  'Coal Ridge High School',
  'Coal Ridge Middle School',
  'Coalfield School',
  'Coalgate Hs',
  'Coalinga High',
  'Coalinga Middle',
  'Coalition School For Social Change',
  'Coalmont Elementary',
  'Coalton Elementary School',
  'Coarsegold Elementary',
  'Coast Colleges',
  'Coast High',
  'Coast Union High',
  'Coastal Academy',
  'Coastal Academy Charter',
  'Coastal Alternative Program',
  'Coastal Alternative Program (cap)',
  'Coastal Bend College',
  'Coastal Buttes',
  'Coastal Carolina Community College',
  'Coastal Carolina University',
  'Coastal Empire Montessori Charter School',
  'Coastal Grove Charter',
  'Coastal Leadership Academy',
  'Coastal Middle School',
  'Coastal Montessori Charter',
  'Coastal Plains Charter High School - Candler Campus',
  'Coastal Preparatory Academy',
  'Coastal Ridge Elementary',
  'Coastal Village El',
  'Coastal Village Middle',
  'Coastal Wash Cty Inst Of Tech',
  'Coastline Community College',
  'Coastline Rop',
  'Coates Elementary',
  'Coatesville Area Sd Cyber Academy',
  'Coatesville Area Shs',
  'Coatesville Intermediate Hs',
  'Coatimundi Middle School',
  'Coats Elementary',
  'Coats-erwin Middle',
  'Cobalt Institute Of Math And Science Academy',
  'Cobb (william L.) Elementary',
  'Cobb 6th Grade Campus',
  'Cobb Middle',
  'Cobb Mountain Elementary',
  'Cobb Prek Academy',
  'Cobbet Elementary',
  'Cobble Hill School Of American Studies',
  'Cobbles Elementary School',
  'Cobblestone Elementary',
  'Cobden Elem School',
  'Cobden High School',
  'Cobden Jr High School',
  'Cobleskill-richmondville High School',
  'Cobre High',
  'Cobre Valley Central Programs',
  'Coburg Community Charter School',
  'Cocalico Ms',
  'Cocalico Shs',
  'Cocheco Academy For The Arts',
  'Cochin University of Science and Technology',
  'Cochise College',
  'Cochise Elementary School',
  'Cochiti Elementary',
  'Cochiti Middle',
  'Cochran Elementary',
  'Cochran Primary Sch',
  'Cochrane Collegiate Academy',
  'Cochrane Elementary',
  'Cochrane-fountain City Elementary',
  'Cochrane-fountain City High',
  'Cochranton El Sch',
  'Cochranton Jshs',
  'Cocke Co High School',
  'Cockeysville Middle',
  'Cockrell Hill El',
  'Cockrill Elementary',
  'Cocoa Beach Junior/senior High School',
  'Cocoa High School',
  'Coconino Community College',
  'Coconino High School',
  'Coconut Creek Elementary School',
  'Coconut Creek High School',
  'Coconut Grove Elementary School',
  'Coconut Palm Elementary School',
  'Coconut Palm K-8 Academy',
  'Cocopah Middle School',
  'Coddle Creek Elementary',
  'Coder El',
  'Coderva Regional High',
  'Codman Academy Charter Public School',
  'Codwell El',
  'Cody Academy Of Public Leadership',
  'Cody El',
  'Cody Elementary School',
  'Cody High School',
  'Cody Middle School',
  'Cody-kilgore Elementary School',
  'Cody-kilgore High School',
  'Cody-kilgore Middle School',
  'Coe College',
  'Coe-brown Northwood Academy',
  'Coebourn El Sch',
  'Coeburn Middle',
  'Coeburn Primary',
  'Coesse School',
  "Coeur D' Alene Tribal School",
  "Coeur D'alene Avenue Elementary",
  "Coeur D'alene Charter Academy School",
  "Coeur D'alene High School",
  'Coffee County Central High School',
  'Coffee County High School',
  'Coffee County Middle School',
  'Coffee County Raider Academy',
  'Coffee Creek Elementary',
  'Coffee Middle School',
  'Coffeen Elem School',
  'Coffeeville Elementary School',
  'Coffeeville High School',
  'Coffenberry Middle School',
  'Coffeyville Community College',
  'Coffin School',
  'Cogburn Woods Elementary School',
  'Coggin Int',
  'Coginchaug Regional High School',
  'Cogswell Elementary',
  'Cohagen School',
  'Cohasset Elementary',
  'Cohasset Middle/high School',
  'Cohasset Street Elementary',
  'Cohen Elementary School',
  'Cohen Middle School',
  'Cohn Elementary School',
  'Cohocton Elementary School',
  'Cohoes High School',
  'Cohoes Middle School',
  'Cohutta Elementary School',
  'Coile Middle School',
  'Coit Arts Academy',
  'Coit/frankford Road New El',
  'Cokato Elementary',
  'Coker College',
  'Coker Creek Elementary',
  'Coker El',
  'Coker Elementary School',
  'Coker-wimberly Elementary',
  'Cokeville Elementary',
  'Cokeville High School',
  'Col Crawford High School',
  'Col John O Ensor Middle',
  'Col John Robinson',
  'Col Moses Parker School',
  'Col Santos Benavides El',
  'Col. E. Brooke Lee Middle',
  'Col. J. K. Tuffree Middle',
  'Col. John B. Wyman Elem.',
  'Col. Zadok Magruder High',
  'Colbert County High School',
  'Colbert El',
  'Colbert Elementary School',
  'Colbert Heights Elementary School',
  'Colbert Heights High School',
  'Colbert Hs',
  'Colbert Ms',
  'Colby College',
  'Colby Community College',
  'Colby Elem',
  'Colby Elementary',
  'Colby High',
  'Colby Middle',
  'Colby Middle School',
  'Colby Senior High',
  'Colchester Elementary School',
  'Colchester High School',
  'Colchester Middle School',
  'Colcord Es',
  'Colcord Hs',
  'Cold Harbor Elementary',
  'Cold Hill Elementary School',
  'Cold Hollow Career Center',
  'Cold Spring',
  'Cold Spring Early Learning Center',
  'Cold Spring El Sch',
  'Cold Spring Elementary',
  'Cold Spring Harbor High School',
  'Cold Spring Harbor Laboratory',
  'Cold Spring School',
  'Cold Springs Elementary School',
  'Cold Springs High',
  'Cold Springs High School',
  'Cold Springs Middle Schools',
  'Cold Springs School',
  'Cold Stream Alternative',
  'Cold Water Elem.',
  'Colden Elementary School',
  'Coldspring Int',
  'Coldspring-oakhurst H S',
  'Coldstream Park Elementary',
  'Coldwater Attendance Center',
  'Coldwater Canyon Elementary',
  'Coldwater Elementary School',
  'Coldwater High School',
  'Coldwater Middle School',
  'Coldwell El',
  'Cole Academy',
  'Cole Arts And Science Academy',
  'Cole Camp Elem.',
  'Cole Camp High',
  'Cole Camp Middle',
  'Cole Camp Preschool',
  'Cole Canyon Elementary',
  'Cole Co. R-i Elem.',
  'Cole Co. R-i Middle',
  'Cole El',
  'Cole Elem.',
  'Cole Elementary',
  'Cole Manor El Sch',
  'Colebrook Academy',
  'Colebrook Consolidated School',
  'Colebrook Elementary School',
  'Colebrook School',
  'Colebrookdale El Sch',
  'Coleen Vazquez Urrutia',
  'Colegio Petite Arizona',
  'Colegio de Estudios Superiores de Administración (CESA)',
  'Colegio de San Juan de Letran',
  'Colegrove Park Elementary',
  'Coleman El',
  'Coleman Elem School',
  'Coleman Elem.',
  'Coleman Elementary',
  'Coleman Elementary School',
  'Coleman Es',
  'Coleman F. Brown Elementary',
  'Coleman H S',
  'Coleman High',
  'Coleman Hs',
  'Coleman J H',
  'Coleman Juniorsenior High School',
  'Coleman Middle',
  'Coleman Middle School',
  'Coleman Place Elementary',
  'Colemon J Elem Academy',
  'Colene Hoose Elem School',
  'Colerain Elementary',
  'Colerain Elementary School',
  'Colerain High School',
  'Colerain Middle School',
  'Coleridge Elementary',
  'Coleridge-taylor Montessori Elementary',
  'Coles Elem Language Academy',
  'Coles Elementary',
  'Coles Ferry Elementary',
  'Coles H S And Educational Center',
  'Coleville High',
  'Coleytown Elementary School',
  'Coleytown Middle School',
  'Colfax Charter Elementary',
  'Colfax Elementary',
  'Colfax Elementary School',
  'Colfax High',
  'Colfax High School',
  'Colfax Upper El Sch',
  'Colfax-mingo Elementary School',
  'Colfax-mingo High School',
  'Colgate Elementary',
  'Colgate University',
  'Colham Ferry Elementary School',
  'Colin L Powell El',
  'Colin Powell El',
  'Colin Powell Elementary School',
  'Colin Powell Middle Sch',
  'Colina Middle',
  'Colinas Del Norte Elementary',
  'Coliseum College Prep Academy',
  'Coliseum Street Elementary',
  'Collaborative Arts Middle School',
  'Collaborative College For Technology',
  'College & Career Technical Inst.',
  'College Academy (the)',
  'College Academy At Broward College',
  'College Achieve Central Charter School',
  'College Achieve Greater Asbury Park Charter School',
  'College Achieve Paterson Charter School',
  'College And Career Academy',
  'College And Career Advantage',
  'College And Career High School',
  'College And Career Preparatory Academy',
  'College Bound Academy Charter',
  'College Bridge Academy',
  'College Career & Technology Acad',
  'College Career Technology Academy (ccta)',
  'College Connection Academy',
  'College Corner Union Elem School',
  'College Corner Union Elementary School',
  'College Elementary',
  'College Gardens Elementary',
  'College Gate Elementary',
  'College Grove Elementary',
  'College Heights Elementary',
  'College Hill El',
  'College Hill Elem',
  'College Hill Elementary School',
  'College Hill Fundamental Academy',
  'College Hill Middle',
  'College Hills El',
  'College Lakes Elementary',
  'College Lane Elementary',
  'College Oaks Elementary School',
  'College Of So. Nv Hs East',
  'College Of So. Nv Hs South',
  'College Of So. Nv Hs West',
  'College Park Academy',
  'College Park El',
  'College Park Elem',
  'College Park Elementary',
  'College Park Elementary School',
  'College Park H S',
  'College Park High',
  'College Park Middle',
  'College Place Elementary',
  'College Place High School',
  'College Place Middle School',
  'College Prep Academy',
  'College Prep And Leadership Academy',
  'College Prep High',
  'College Preparatory Elementary',
  'College Preparatory Middle - East County',
  'College Road Early Childhood Center',
  'College Square El Sch',
  'College St El',
  'College Station Elem. School',
  'College Station H S',
  'College Station Jjaep',
  'College Station Middle',
  'College Street Campus',
  'College Street El',
  'College Street Elementary',
  'College View',
  'College View Campus',
  'College View Elementary',
  'College View Elementary School',
  'College View H S',
  'College View Middle School',
  'College View School',
  'College Wood Elementary School',
  'College in Sládkovičovo',
  'College of Alameda',
  'College of Business Management ( CBM )',
  'College of Busniess Admnistration',
  'College of Charleston',
  'College of Computer Science in Lodz',
  'College of DuPage',
  'College of Dunaujvaros',
  'College of Eastern Utah',
  'College of Education Ikere',
  'College of Education Oju',
  'College of Europe',
  'College of Lake County',
  'College of Management',
  'College of Marin',
  'College of New Caledonia',
  'College of New Rochelle',
  'College of Nursing and Allied Health Scinces',
  'College of Saint Benedict',
  'College of Saint Catherine',
  'College of Saint Rose',
  'College of San Mateo',
  'College of Science Baghdad University',
  'College of Southern Idaho',
  'College of Southern Maryland',
  'College of St. Francis',
  'College of St. Scholastica',
  'College of Technology at Abha',
  'College of Technology at Dammam',
  'College of Technology at Jazan',
  'College of Technology at Jeddah',
  'College of Technology at Kharj',
  'College of Technology at Riyadh',
  'College of Telecommunication & Information',
  'College of Western Idaho',
  'College of William and Mary',
  'College of Wooster',
  'College of the Albemarle',
  'College of the Atlantic',
  'College of the Canyons',
  'College of the Desert',
  'College of the Holy Cross',
  'College of the Holy Spirit',
  'College of the Mainland',
  'College of the Ouachitas',
  'College of the Redwoods',
  'College of the Rockies',
  'College of the Sequoias',
  'College of the Siskiyous',
  'Collegeville Elementary',
  'Collegeville Elementary School',
  'Collegewood Elementary',
  'Collegiate Academy',
  'Collegiate Academy Of Colorado',
  'Collegiate Academy-math-personal Awa',
  'Collegiate Charter High Of Los Angeles',
  'Collegiate Charter School Of Lowell',
  'Collegiate H S',
  'Collegiate Hall Charter School',
  'Collegiate High School At Northwest Florida State College',
  'Collegiate Institute For Math And Science',
  'Collegiate Preparatory Academy',
  'Collegiate School Of Med/bio',
  'Collegium Civitas',
  'Collegium Cs',
  'Collet Park Elementary',
  'Colleton County High',
  'Colleton County Middle',
  'Collett Elementary',
  'Collettsville School',
  'Colleyville El',
  'Colleyville Heritage H S',
  'Colleyville Middle',
  'Collicot',
  'Collier Adult & Community Education Center',
  'Collier Charter Academy',
  'Collier Daep',
  'Collier El',
  'Collier Elementary School',
  'Collier Juvenile Detention Center',
  'Collier Virtual Instruction Course Offerings',
  'Collier Virtual Instruction Program',
  'Colliers Primary School',
  'Collierville Elementary School',
  'Collierville High School',
  'Collierville Middle School',
  'Collin Co J J A E P',
  'Collin County Community College District',
  'Collings Lakes Elementary School',
  'Collingswood High School',
  'Collingswood Middle School',
  'Collington Square Elementary',
  'Collins Academy High School',
  'Collins Colony Elementary - 06',
  'Collins El',
  'Collins Elementary',
  'Collins Elementary School',
  'Collins Es',
  'Collins Garden El',
  'Collins High School',
  'Collins Hill High School',
  'Collins Int',
  'Collins Intermediate School',
  'Collins Lane',
  'Collins Middle',
  'Collins Middle School',
  'Collins-maxwell Elementary School',
  'Collins-maxwell Middle/high School',
  'Collins-parr El',
  'Collinsrhodes Elementary School',
  'Collinsriverside Middle School',
  'Collinsville 6th Grade Center',
  'Collinsville Ec Ctr',
  'Collinsville El',
  'Collinsville H S',
  'Collinsville High School',
  'Collinsville Hs',
  'Collinsville Middle School',
  'Collinsville Ms',
  'Collinsville Primary',
  'Collinsville Upper Es',
  'Collinswood Language Academy',
  'Collinwood Elementary',
  'Collinwood High School',
  'Collinwood Middle School',
  'Collister Elementary School',
  'Collège Boréal',
  'Colman Elementary - 03',
  'Colman-egan High School - 01',
  'Colman-egan Jr. High - 02',
  'Colmesneil El',
  'Colmesneil Jh/hs',
  'Colo-nesco Elementary School',
  'Colo-nesco Middle/high School',
  'Cologne Academy',
  'Cologne Business School',
  'Coloma Elementary',
  'Coloma Elementary School',
  'Coloma High School',
  'Coloma Intermediate School',
  'Coloma Junior High School',
  'Colome Elementary - 02',
  'Colome High School - 01',
  'Colome Middle School - 03',
  'Colon Elementary School',
  'Colon High School',
  'Colona Grade School',
  'Colonel Donald Mcmonagle Elementary School',
  'Colonel Fred Cherry Middle School',
  'Colonel Howard Nichols Elementary',
  'Colonel John Siegfried El Sch',
  'Colonel John Wheeler Middle School',
  'Colonel Johnston Elementary School',
  'Colonel Joseph C. Rodriguez Prep Academy',
  'Colonel Mitchell Paige Middle',
  'Colonel Richardson High School',
  'Colonel Richardson Middle School',
  'Colonel Smith Middle School',
  'Colonel Wright Elementary School',
  'Colonia High School',
  'Colonia Middle School',
  'Colonial Acres Elementary',
  'Colonial Beach Elementary',
  'Colonial Beach High',
  'Colonial Drive Elementary School',
  'Colonial El Sch',
  'Colonial Elementary',
  'Colonial Elementary School',
  'Colonial Forge High',
  'Colonial Heights',
  'Colonial Heights El',
  'Colonial Heights High',
  'Colonial Heights Middle',
  'Colonial Heights Technical Center',
  'Colonial High',
  'Colonial Hills El',
  'Colonial Hills Elementary School',
  'Colonial Middle',
  'Colonial Ms',
  'Colonial Park',
  'Colonial Prep Academy',
  'Colonial Road School',
  'Colonial School',
  'Colonial Trail Elementary',
  'Colonial Village Elementary School',
  'Colonie Central High School',
  'Colonies North El',
  'Colony Basic Skills Alternative High',
  'Colony Bend El',
  'Colony Elementary School',
  'Colony High',
  'Colony High School',
  'Colony Meadows El',
  'Colony Middle School',
  'Colony Oak Elementary',
  'Colorado Christian University',
  'Colorado City High School',
  'Colorado City Jr High School',
  'Colorado College',
  'Colorado Community College System',
  'Colorado Connections Academy',
  'Colorado Connections Academy @ Durango',
  'Colorado Digital Academy - Elementary',
  'Colorado Digital Academy - Middle',
  'Colorado Early College Fort Collins',
  'Colorado Early Colleges - Parker',
  'Colorado Early Colleges Aurora',
  'Colorado El And Middle',
  'Colorado Elementary School',
  'Colorado H S',
  'Colorado High School Charter',
  'Colorado High School Charter - Ges',
  'Colorado Isd Daep',
  'Colorado Mesa University',
  'Colorado Middle',
  'Colorado Military Academy',
  'Colorado Northwestern Community College',
  'Colorado Prep Academy',
  'Colorado River Collegiate Academy',
  'Colorado School For The Deaf And Blind',
  'Colorado School of Mines',
  'Colorado Springs Charter Academy',
  'Colorado Springs Early Colleges',
  'Colorado State University',
  'Colorado State University - Global Campus',
  'Colorado Stem Academy',
  'Colorado Technical University',
  'Colorado Virtual Academy (cova)',
  "Colorado's Finest High School Of Choice",
  'Colorow Elementary School',
  'Colquitt County Achievement Center',
  'Colquitt County High School',
  'Colrain Central',
  'Colson Elementary School',
  'Colstrip High School',
  'Colt Andrews School',
  'Colt El',
  'Colt Jtc School',
  'Colter Elementary',
  'Colton Elementary School',
  'Colton High',
  'Colton High School',
  'Colton Middle',
  'Colton Middle School',
  'Colton School',
  'Colton-pierrepont Central School',
  'Colton-redlands-yucaipa Rop',
  'Coltrane-webb Elementary',
  'Colts Neck High School',
  'Columbia 6th Grade Academy',
  'Columbia Academy',
  'Columbia Academy Anchor Program',
  'Columbia Alternative Education',
  'Columbia Alternative School',
  'Columbia Area Career Ctr.',
  'Columbia Basin Technical Skills Center',
  'Columbia Central High School',
  'Columbia Central School',
  'Columbia City Elementary School',
  'Columbia City High School',
  'Columbia City School',
  'Columbia College',
  'Columbia College Chicago',
  'Columbia Correctional Institution',
  'Columbia County Education Campus',
  'Columbia Crest A-stem Academy',
  'Columbia Elem.',
  'Columbia Elem. Comm. Ed. Ctr.',
  'Columbia Elementary',
  'Columbia Elementary School',
  'Columbia Explorers Elem Academy',
  'Columbia Falls High Schl',
  'Columbia Falls Jr Hs',
  'Columbia Gorge Community College',
  'Columbia H S',
  'Columbia Heights Ec',
  'Columbia Heights El',
  'Columbia Heights Elementary',
  'Columbia Heights Senior High',
  'Columbia Heights Transition Program',
  'Columbia High',
  'Columbia High And Elementary',
  'Columbia High School',
  'Columbia Hs',
  'Columbia Intermediate School',
  'Columbia Junior High School',
  'Columbia Middle',
  'Columbia Middle School',
  'Columbia Ms',
  'Columbia Park Elementary',
  'Columbia Primary School',
  'Columbia Ridge Elementary',
  'Columbia River Gorge Elementary School',
  'Columbia River High',
  'Columbia School',
  'Columbia Secondary School',
  'Columbia Secure Center For Girls',
  'Columbia Southern University',
  'Columbia State Community College',
  'Columbia Step Up',
  'Columbia Union College',
  'Columbia University',
  'Columbia Upper Elementary School',
  'Columbia Valley Elementary',
  'Columbia Valley Garden Elem Schl',
  'Columbia Virtual Academy',
  'Columbia Virtual Academy - Kettle Falls',
  'Columbia Virtual Academy - Sultan',
  'Columbia Virtual Academy-orient',
  'Columbia Virtual Franchise',
  'Columbia Virtual Instruction Program',
  'Columbia Virtual Instruction Program (district Provided)',
  'Columbia-east Valley K-6 Community Day',
  'Columbia-montour Avts',
  'Columbia-​Greene Community College',
  'Columbian Elem School',
  'Columbian Elem.',
  'Columbian Elementary School',
  'Columbian High School',
  'Columbiana County',
  'Columbiana High School',
  'Columbiana Middle School',
  'Columbiaville Elementary School',
  'Columbine Elementary',
  'Columbine Elementary School',
  'Columbine High School',
  'Columbine Hills Elementary School',
  'Columbine Middle School',
  'Columbus Africentric Early College',
  'Columbus Africentric Early College Elementary School',
  'Columbus Alternative High School',
  'Columbus Alternative School',
  'Columbus Alternative School New Ulm Campus',
  'Columbus Area Career Connection',
  'Columbus Arts & Technology Academy',
  'Columbus Avenue',
  'Columbus Avenue School',
  'Columbus Bilingual Academy-north',
  'Columbus Charter School',
  'Columbus City Preparatory School For Boys',
  'Columbus City Preparatory School For Girls',
  'Columbus Collegiate Academy',
  'Columbus Collegiate Academy - West',
  'Columbus Community Center',
  'Columbus Community High School',
  'Columbus Community Junior High School',
  'Columbus Continuation',
  'Columbus Downtown High School',
  'Columbus East Elem School',
  'Columbus East High School',
  'Columbus El',
  'Columbus Elem School',
  'Columbus Elementary',
  'Columbus Elementary School',
  'Columbus Es',
  'Columbus Family Academy',
  'Columbus Grove Elementary School',
  'Columbus Grove High School',
  'Columbus Grove Middle School',
  'Columbus H S',
  'Columbus High',
  'Columbus High School',
  'Columbus Humanities Arts And Technology Academy',
  'Columbus Intermediate School',
  'Columbus J H',
  'Columbus Magnet School',
  'Columbus Manor Elem School',
  'Columbus Middle',
  'Columbus Middle School',
  'Columbus North High School',
  'Columbus North International School (7-12)',
  'Columbus Park',
  'Columbus Performance Academy',
  'Columbus Preparatory Academy',
  'Columbus Preparatory And Fitness Academy',
  'Columbus Residential Juvenile Facility',
  'Columbus School',
  'Columbus School At The Franko Building',
  'Columbus Scioto 6-12',
  'Columbus Spanish Immersion K-6 School',
  'Columbus State Community College',
  'Columbus State University',
  'Columbus Technical College',
  'Columbus Tustin Middle',
  'Columbus University',
  'Columbus West Elementary School',
  'Colusa Alternative High (continuation)',
  'Colusa Alternative Home',
  'Colusa High',
  'Colvill Family Education Center',
  'Colville Junior High School',
  'Colville Senior High School',
  'Colvin Elem',
  'Colvin Run Elementary',
  'Colwich Elem',
  'Colwyn El Sch',
  'Comal Academy',
  'Comal Discipline Center',
  'Coman Hill School',
  'Comanche Early Childhood Center',
  'Comanche El',
  'Comanche Elem',
  'Comanche Elementary',
  'Comanche Es',
  'Comanche H S',
  'Comanche Hs',
  'Comanche Middle School',
  'Comanche Ms',
  'Comanche Springs El',
  'Combee Academy Of Design And Engineering',
  'Combre-fondel Elementary School',
  'Combs Elem.',
  'Combs Elementary',
  'Combs High School',
  'Combs Traditional Academy',
  'Come Back Butte Charter',
  'Come Back Kids',
  'Comegys Benjamin B Sch',
  'Comenius University in Bratislava',
  'Comer Elementary School',
  'Comfort El',
  'Comfort Elementary',
  'Comfort H S',
  'Comfort Middle',
  'Comfrey Elementary',
  'Comfrey Secondary',
  'Comilla University',
  'Comly Watson Sch',
  'Comm Based Trans Program',
  'Comm H S Dist 99 - North H S',
  'Comm H S Dist 99 - South High Sch',
  'Commack High School',
  'Commack Middle School',
  'Commack Road Elementary School',
  'Commander Academy',
  'Commerce El',
  'Commerce Elementary School',
  'Commerce H S',
  'Commerce High School',
  'Commerce Hs',
  'Commerce Middle',
  'Commerce Middle School',
  'Commerce Ms',
  'Commerce Primary',
  'Commodore John Rogers Elementary',
  'Commodore Perry Jshs',
  'Commodore Perry Sch',
  'Commodore Stockton Skills',
  'Common Ground High School',
  'Commons Lane Elem.',
  'Commonwealth Avenue Elementary',
  'Commonwealth Charter Academy Cs',
  'Commonwealth Community Development Academy',
  'Commonwealth Ctr For Children & Adolescents',
  'Commonwealth El',
  'Commonwealth Elementary',
  "Commonwealth Governor's School",
  'Commonwealth High',
  'Communication And Media Arts Hs',
  'Communication And Tech At Diego Rivera Lrng Complex',
  'Communication University of China',
  'Communications High School',
  'Community',
  'Community Academy',
  'Community Academy Of Philadelphia Cs',
  'Community Academy Of Science And Health',
  'Community Access Job Training',
  'Community Action School-ms 258',
  'Community Alternative High School',
  'Community Base Service Students',
  'Community Based Classrooms',
  'Community Based Early Childhood',
  'Community Based Early Childhood Services',
  'Community Based Education',
  'Community Based Instruction Zeeland',
  'Community Based Pk',
  'Community Campus - 41',
  'Community Charter School',
  'Community Charter School Of Cambridge',
  'Community Charter School Of Excellence',
  'Community Charter School Of Paterson',
  'Community Classroom Collaborative-meriden School District',
  'Community Collaborative Charter',
  'Community Collaborative Virtual - Keppel Partnership Acad',
  'Community Collaborative Virtual - Sage Oak Chtr',
  'Community College of Allegheny County',
  'Community College of Aurora',
  'Community College of Baltimore County',
  'Community College of Beaver County',
  'Community College of Denver',
  'Community College of Philadelphia',
  'Community College of Rhode Island',
  'Community College of Vermont',
  'Community Connections',
  'Community Cons School',
  'Community Day',
  'Community Day Arlington',
  'Community Day Charter Public School - Gateway',
  'Community Day Charter Public School - Prospect',
  'Community Day Charter Public School - R. Kingman Webster',
  'Community Day High',
  'Community Day Middle',
  'Community Day School Iii',
  'Community Ed',
  'Community Ed Resource Center',
  'Community Education Center',
  'Community Elem.',
  'Community Elementary',
  'Community Elementary School',
  'Community For Kids',
  'Community H S',
  'Community Haven For Adults And Children With Disabilities I',
  'Community Health Academy Of The Heights',
  'Community High',
  'Community High (continuation)',
  'Community High School',
  'Community House Middle',
  'Community Leadership Academy',
  'Community Learning Center',
  'Community Learning Ctr.',
  'Community Magnet Charter Elementary',
  'Community Math And Science Prep',
  'Community Middle',
  'Community Middle School',
  'Community Montessori',
  'Community Montessori Charter',
  'Community Montessori School',
  'Community Of Peace Academy Elem',
  'Community Of Peace Academy High Sch',
  'Community Of Peace Academy Middle',
  'Community Outreach Academy',
  'Community Park School',
  'Community Partnership Charter School',
  'Community Prep Charter School',
  'Community Preparatory Academy',
  'Community Roots Academy',
  'Community Roots Charter School',
  'Community School',
  'Community School #53',
  'Community School For Apprenticeship Learning',
  'Community School For Creative Education',
  'Community School For Social Justice',
  'Community School North',
  'Community School North (fort Lauderdale School)',
  'Community School Of Davidson',
  'Community School Of Excellence',
  'Community School South',
  'Community School/independent Alternative Education',
  'Community Service Center',
  'Community Services West High Sch',
  'Community Services-sec',
  'Community Transition Campus',
  'Community United Elementary',
  'Community Voices Middle School',
  'Community Youth Services',
  'Community/vocational Program',
  'Communitybased Transition Center',
  'Como El',
  'Como Elementary',
  'Como Elementary School',
  'Como Montessori',
  'Como Park Elementary',
  'Como Park Elementary School',
  'Como Park Senior High',
  'Como-pickton School',
  'Compadre High School',
  'Compass Academy',
  'Compass Academy Charter School',
  'Compass Alternative School',
  'Compass Center',
  'Compass Charter School',
  'Compass Charter Schools Of Fresno',
  'Compass Charter Schools Of Los Angeles',
  'Compass Charter Schools Of San Diego',
  'Compass Classical Academy Charter School',
  'Compass Elementary',
  'Compass High School',
  'Compass Learning Center',
  'Compass Learning Community',
  'Compass Middle Charter School',
  'Compass Montessori - Golden Charter School',
  'Compass Montessori - Wheat Ridge Charter School',
  'Compass On-line',
  'Compass Pointe',
  'Compass Public Charter School',
  'Compass Public Charter School Jr/sr High',
  'Compass Rose Academy',
  'Compass-bell Center',
  'Compassion Road Academy',
  'Competitive Edge Charter Academy (ceca)',
  'Comprehensive Grammar School',
  'Comprehensive Model School Project Ms 327',
  'Comptche Elementary',
  'Compton Avenue Elementary',
  'Compton Community Day High',
  'Compton Community Day Middle',
  'Compton Early College High',
  'Compton Elementary School',
  'Compton High',
  'Compton Junior High',
  'Compton Middle Opportunity',
  'Compton Unified Rop',
  'Compton-drew Ilc Middle',
  'Computer Academy Toppenish High School',
  'Comquest Academy',
  'Comrat State University',
  'Comsewogue High School',
  'Comstock Compass High School',
  'Comstock El',
  'Comstock Elementary School',
  'Comstock High School',
  'Comstock Park High School',
  'Comstock School',
  'Con Amore School',
  'Conackamack Middle School',
  'Conant Elementary School',
  'Conant High School',
  'Conard High School',
  'Concepcion Mendez Cano',
  'Concepcion Perez Hernandez',
  'Concho Elementary School',
  'Concord Academy Boyne',
  'Concord Academy Petoskey',
  'Concord Carlisle High',
  'Concord Community High School',
  'Concord East Side Elementary Sch',
  'Concord El Sch',
  'Concord Elem School',
  'Concord Elem. School',
  'Concord Elementary',
  'Concord Elementary School',
  'Concord Graded/middle School',
  'Concord High',
  'Concord High School',
  'Concord Intermediate School',
  'Concord International School',
  'Concord Junior High School',
  'Concord Middle',
  'Concord Middle School',
  'Concord Ox-bow Elementary School',
  'Concord Road Elementary School',
  'Concord South Side Elementary Sch',
  'Concord West Side Elem School',
  'Concordia',
  'Concordia Charter School',
  'Concordia Charter School- Navajo Mission',
  'Concordia College - Ann Arbor',
  'Concordia College - Moorhead',
  'Concordia College - Seward',
  'Concordia College - St. Paul',
  'Concordia Education Center',
  'Concordia Elem.',
  'Concordia Elementary',
  'Concordia High',
  'Concordia Jr-sr High',
  'Concordia Middle',
  'Concordia University',
  'Concordia University College of Alberta',
  'Concourse Village Elementary School',
  'Concow Elementary',
  'Concrete Elementary',
  'Concrete High School',
  'Concrete Primary',
  'Condit El',
  'Condit Elementary',
  'Condon Elementary School',
  'Condon High School',
  'Condor Elementary',
  'Condor High',
  'Conecuh County Junior High School',
  'Conehatta Elementary School',
  'Conejo Elementary',
  'Conejo Middle',
  'Conejo Valley High (continuation)',
  'Conemaugh Twp Area El Sch',
  'Conemaugh Twp Area Ms/shs',
  'Conemaugh Valley El Sch',
  'Conemaugh Valley Jshs',
  'Conerly Road School',
  'Conestoga College',
  'Conestoga El Sch',
  'Conestoga Elementary',
  'Conestoga Elementary School',
  'Conestoga Jr/sr High School',
  'Conestoga Magnet Elem School',
  'Conestoga Middle School',
  'Conestoga Shs',
  'Conestoga Valley Shs',
  'Conesville Elementary School',
  'Conewago El Sch',
  'Conewago Twp El Sch',
  'Conewago Valley Intrmd Sch',
  'Coney Island Preparatory Public Charter School',
  'Confidence Elementary',
  'Confluence Continuation High',
  'Confluence Preparatory Academy',
  'Congaree Elementary',
  'Congaree/wood Early Childhood Center',
  'Congdon Elementary',
  'Congerville Elem School',
  'Congetta Trippe Janet Elementary School',
  'Congin School',
  'Congress Community Middle School',
  'Congress Elementary',
  'Congress Elementary School',
  'Congress Middle',
  'Congress Park Elem School',
  'Congress School',
  'Congressman Jerry Lewis Elementary',
  'Congressman Silvestre & Carolina School',
  'Conifer Senior High School',
  'Conklin Elem School',
  'Conlee Elementary',
  'Conley El',
  'Conley Elementary',
  'Conley Elementary School',
  'Conley Hills Elementary School',
  'Conn Elementary',
  'Conn-west Elem.',
  'Connally Early Childhood Center',
  'Connally El',
  'Connally High School',
  'Connally J H',
  'Connally Middle',
  'Connally Pri',
  'Conneaut Area Senior High',
  'Conneaut Elementary School',
  'Conneaut High School',
  'Conneaut Lake Ms',
  'Conneaut Lake-sadsbury El Sch',
  'Conneaut Middle School',
  'Conneaut Valley El Sch',
  'Conneaut Valley Ms',
  'Connect Community Charter',
  'Connecticut College',
  'Connecticut Farms',
  'Connecticut Ib Academy',
  'Connecticut River Academy',
  'Connecticut State University System',
  'Connecticut Valley Hospital',
  'Connecting Waters Charter',
  'Connecting Waters Charter - East Bay',
  'Connections',
  'Connections - New Century Pcs',
  'Connections Academy',
  'Connections Education Center Of The Palm Beaches',
  'Connections Learning Center On The Earle Johnson C',
  'Connections Program',
  'Connections Visual And Performing Arts Academy',
  'Connell Elem',
  'Connell High School',
  'Connell Preschool',
  'Connellsville Area Career & Technical Ce',
  'Connellsville Area Ms',
  'Connellsville Area Shs',
  'Connellsville Twp El Sch',
  'Conner Creek Academy East Elementary',
  'Conner Creek Academy East Mi Collegiate Middle',
  'Conner Creek Academy East Michigan Collegiate',
  'Conner High School',
  'Conner Middle School',
  'Conner Street Elementary',
  'Conners-emerson School',
  'Connersville Middle School',
  'Connersville Sr High School',
  'Connerton Elementary School',
  'Connetquot Alternative Preschool At Premm',
  'Connetquot Elementary School',
  'Connetquot High School',
  'Connexions: A Community Based Arts School',
  'Connie Dugan Elementary School',
  'Conniston Middle School',
  'Connolly Middle School',
  'Connolly School',
  'Connoquenessing El Sch',
  'Connoquenessing Valley El Sch',
  'Connor Academy',
  'Connor Consolidated School',
  'Connor Shaw Center',
  'Connors State College',
  'Conotton Valley Elementary',
  'Conotton Valley High School',
  'Conover Road Elementary School',
  'Conover Road Primary School',
  'Conover Special Education',
  'Conowingo Elementary',
  'Conrad Ball Middle School',
  'Conrad Early Learning Center',
  'Conrad Elvehjem Early Learning Center',
  'Conrad Fischer Elem School',
  'Conrad High School',
  'Conrad Schools Of Science',
  'Conrad Weiser East El Sch',
  'Conrad Weiser Hs',
  'Conrad Weiser Ms',
  'Conrad Weiser West El Sch',
  'Conrado Rodriguez',
  'Conroe H S',
  'Consejo',
  'Conselyea Preparatory School',
  'Consentino Annex At Bartlett School',
  'Consentino Middle School',
  'Conservation Corps Of Long Beach Gateway Cities Charter',
  'Conservatoire National des Arts et Métiers',
  'Conservatorio del Tolima',
  'Conservatory Lab Charter School',
  'Conservatory Of The Arts',
  'Conservatory Of Vocal/instrumental Arts',
  'Conservatory Of Vocal/instrumental Arts High',
  'Conshohocken El Sch',
  'Consolidated Community School Services',
  'Consolidated Elementary',
  'Consolidated School',
  'Constable Elementary School',
  'Constance Hulbert El',
  'Constantin Brancoveanu University Pitesti',
  'Constantine Alternative Education',
  'Constantine High School',
  'Constantine Middle School',
  'Constellation Schools: Collinwood Village Academy',
  'Constellation Schools: Eastside Arts Academy',
  'Constellation Schools: Elyria Community',
  'Constellation Schools: Lorain Community Elementary',
  'Constellation Schools: Lorain Community Middle',
  'Constellation Schools: Madison Community Elementary',
  'Constellation Schools: Old Brooklyn Community Elementary',
  'Constellation Schools: Old Brooklyn Community Middle',
  'Constellation Schools: Outreach Academy For Students With Di',
  'Constellation Schools: Parma Community',
  'Constellation Schools: Puritas Community Elementary',
  'Constellation Schools: Puritas Community Middle',
  'Constellation Schools: Stockyard Community Elementary',
  'Constellation Schools: Stockyard Community Middle',
  'Constellation Schools: Westpark Community Elementary',
  'Constellation Schools: Westpark Community Middle',
  'Constellation Schools: Westside Community School Of The Arts',
  'Constitution Elementary School',
  'Constitution Hs',
  'Consuelo Lopez Benet',
  'Conte/west Hills Magnet School',
  'Contemporary Learning Academy',
  'Contentnea-savannah School',
  'Continental Colony Elementary School',
  'Continental Elementary School',
  'Continental Local Elementary School',
  'Continental Local High School',
  'Continental Theological Seminary',
  'Continuation School',
  'Continued Hope High Schools',
  'Continuing Education Center',
  'Continuous Curriculum School',
  'Continuous Learning Center',
  'Contra Costa College',
  'Contra Costa Community College District',
  'Contra Costa County Rop',
  'Contra Costa School Of Performing Arts',
  'Contract',
  'Contract - 02',
  'Contract Learning Center',
  'Contracted Residential Services',
  'Contracted Schools',
  'Contracted Services',
  'Contracted Special Education',
  'Contractual Schools',
  'Contreras Learning Center-academic Leadership Community',
  'Contreras Learning Center-school Of Social Justice',
  'Contreras Lrng Center-los Angeles Sch Of Global Studies',
  'Conval Regional High School',
  'Converse El',
  'Converse Elementary',
  'Converse Elementary School',
  'Converse High School',
  'Converse Middle',
  'Conway Adult Education Center',
  'Conway Area Career Center',
  'Conway Elem.',
  'Conway Elementary',
  'Conway Elementary School',
  'Conway Grammar',
  'Conway High',
  'Conway High Schools',
  'Conway High West',
  'Conway Junior High School',
  'Conway Middle',
  'Conway School',
  'Conway Springs High School',
  'Conway Springs Kyle Trueblood',
  'Conway Springs Middle School',
  'Conway Vocational Center',
  'Conwell Russell Ms',
  'Cony',
  'Conyer Elementary',
  'Conyers Middle School',
  'Cook (hazel Goes) Elementary',
  'Cook County Alternative',
  'Cook County Middle',
  'Cook County Middle School',
  'Cook County Senior High',
  'Cook El',
  'Cook Elem School',
  'Cook Elementary School',
  'Cook High School',
  'Cook Hill School',
  'Cook Jr El',
  'Cook Literacy Model School',
  'Cook Middle',
  'Cook Primary School',
  'Cook School',
  'Cook-wissahickon Sch',
  'Cooke City School',
  'Cooke El',
  'Cooke Elementary School',
  'Cooke Jay Ms',
  'Cooke School',
  'Cooke/fannin/grayson Co Juvenile Post Ajud Fac',
  'Cookeville High School',
  'Cooks Corners Elementary School',
  'Cookson Elementary School',
  'Cool Spring Colony School',
  'Cool Spring Elementary',
  'Cool Spring Primary',
  'Cool Valley Elem.',
  'Cooleemee Elementary',
  'Cooley El',
  'Cooley Elementary School',
  'Cooley Middle School',
  'Cooley Ranch Elementary',
  'Cooley Springs-fingerville Elementary',
  'Coolidge Alternative Program',
  'Coolidge El',
  'Coolidge Elementary',
  'Coolidge Elementary School',
  'Coolidge Es',
  'Coolidge H S',
  'Coolidge High School',
  'Coolidge Hs',
  'Coolidge Jr. High School',
  'Coolidge Junior High Sch',
  'Coolidge Middle School',
  'Coolidge Rssp',
  'Coolspring Elementary School',
  'Coolville Elementary School',
  'Coon Rapids High School',
  'Coon Rapids Middle School',
  'Coon Rapids-bayard 5-12 Attendance Center',
  'Coon School',
  'Coon Valley Elementary',
  'Coonley Elem School',
  'Coop Ctr For Excep Children',
  'Coop El',
  'Coop Hs 1',
  'Coop Hs 7',
  'Cooper (sarah B.) Children Center',
  'Cooper Academy',
  'Cooper Academy At Navarro',
  'Cooper City Elementary School',
  'Cooper City High School',
  'Cooper Correctional Facility',
  'Cooper Credit Recovery',
  'Cooper El',
  'Cooper Elem Dual Language Academy',
  'Cooper Elementary',
  'Cooper Elementary School',
  'Cooper Es',
  'Cooper H S',
  'Cooper J H',
  'Cooper Landing School',
  'Cooper Lane Elementary',
  'Cooper Middle',
  'Cooper Middle School',
  'Cooper Mountain Elementary School',
  'Cooper Ms',
  'Cooper Union for the Advancement of Science and Art',
  'Cooper Upper Elementary School',
  "Cooper's Poynt Family School",
  'Cooper-carver Elementary School',
  'Cooperative Area Learning Prg.',
  'Cooperative High',
  'Cooperative High School - Inter-district Magnet',
  'Cooperative Middle School',
  'Coopers Elementary',
  'Cooperstown Elementary School',
  'Cooperstown Junior/senior High School',
  'Coopersville East Elementary',
  'Coopersville High School',
  'Coopersville Middle School',
  'Coopersville South Elementary School',
  'Coopersville West Early Childhood Center',
  'Coopertown El Sch',
  'Coopertown Elementary',
  'Coopertown Middle School',
  'Coor Cte',
  'Coor Early Childhood Services',
  'Coor Special Educational Center',
  'Coosa County Career Tech Center',
  'Coosa Elementary',
  'Coosa High School',
  'Coosa Middle School',
  'Coosa Valley Elementary School',
  'Coosada Elementary School',
  'Cooter Elem.',
  'Cooter High',
  'Cop Esd Programs',
  'Copan Es',
  'Copan Hs',
  'Cope Alternative School',
  'Cope Area Career Center',
  'Cope Center North',
  'Cope Middle',
  'Cope Middle School',
  'Copeland El',
  'Copeland Elem',
  'Copeland Elementary',
  'Copeland Elementary School',
  'Copeland Manor Elem School',
  'Copeland Middle School',
  'Copenhagen Business School',
  'Copenhagen Central School',
  'Copenhagen University',
  'Copiague Middle School',
  'Copiah-​Lincoln Community College',
  'Cople Elementary',
  'Copley High School',
  'Copley-fairlawn Middle School',
  'Copopa Elementary School',
  'Coppell Classical Academy',
  'Coppell H S',
  'Coppell Middle East',
  'Coppell Middle North',
  'Coppell Middle West',
  'Copper Basin',
  'Copper Basin Elementary School',
  'Copper Basin High School',
  'Copper Beech Sch',
  'Copper Canyon Academy',
  'Copper Canyon Elementary School',
  'Copper Canyon High School',
  'Copper Canyon School',
  'Copper Country Learning Center',
  'Copper Creek Elementary',
  'Copper Creek Elementary School',
  'Copper Hill Elementary School',
  'Copper Hills Elementary',
  'Copper Hills High',
  'Copper Hills School',
  'Copper King Elementary',
  'Copper Lake School',
  'Copper Mesa Elementary School',
  'Copper Mill Elementary/middle School',
  'Copper Mountain Community College',
  'Copper Mountain Middle',
  'Copper Point High School',
  'Copper Ridge Elementary',
  'Copper Ridge Math And Science Academy',
  'Copper Ridge Middle School',
  'Copper Ridge School',
  'Copper Rim Elementary School',
  'Copper Trails',
  'Copper View Elementary School',
  'Copperas Cove H S',
  'Copperas Cove J H',
  'Copperbelt University',
  'Copperfield El',
  'Coppergate Elementary School',
  'Copperopolis Elementary',
  'Copperview School',
  'Copperwood School',
  'Coppin Academy',
  'Coppin State University',
  'Coppinville School',
  'Coqui Intermedia',
  'Coquillard Traditional School',
  'Coquille Junior Senior High',
  'Coquille Valley Elementary',
  'Coquina Elementary School',
  'Cora B Darling Elementary',
  'Cora Castlen Elementary',
  'Cora Howe School',
  'Cora Kelly Magnet Elementary',
  'Cora L. Rice Elementary',
  'Cora Spencer El',
  'Cora Thomas El',
  'Coral Academy Elementary',
  'Coral Academy High School',
  'Coral Academy Middle School',
  'Coral Academy Of Science Las Vegas Centennial Hills',
  'Coral Academy Of Science Las Vegas Nellis Afb',
  'Coral Academy Of Science Las Vegas Sandy Ridge',
  'Coral Academy Of Science Las Vegas Tamarus',
  'Coral Academy Of Science Las Vegas Windmill',
  'Coral Canyon School',
  'Coral Cliffs School',
  'Coral Community Charter',
  'Coral Cove Elementary School',
  'Coral Gables Preparatory Academy',
  'Coral Gables Senior High Adult Education',
  'Coral Gables Senior High School',
  'Coral Glades High School',
  'Coral Mountain Academy',
  'Coral Park Elementary School',
  'Coral Reef Elementary School',
  'Coral Reef Montessori Academy Charter',
  'Coral Reef Senior High School',
  'Coral Ridge Elementary',
  'Coral Shores High School',
  'Coral Springs High School',
  'Coral Springs Middle School',
  'Coral Springs Pk-8',
  'Coral Sunset Elementary School',
  'Coral Terrace Elementary School',
  'Coral Way K-8 Center',
  'Coralwood Education Center',
  'Coram Elementary School',
  'Corbell El',
  'Corbett School',
  'Corbin Area Technology Center',
  'Corbin Educational Center',
  'Corbin Elementary',
  'Corbin High School',
  'Corbin Intermediate',
  'Corbin Middle School',
  'Corbin Preschool',
  'Corbin Primary',
  'Corbin School Of Innovation',
  'Corcoran Academy',
  'Corcoran High',
  'Corcoran High School',
  'Cord-charlotte Elem. School',
  'Cordata Elementary School',
  'Cordelia Hills Elementary',
  'Cordell Es',
  'Cordell Hs',
  'Cordell Jhs',
  'Cordello Avenue Elementary School',
  'Cordia School',
  'Cordill-mason Elem.',
  'Cordillera Elementary',
  'Cordley Elem',
  'Cordova Elementary',
  'Cordova Elementary School',
  'Cordova Gardens Elementary',
  'Cordova High',
  'Cordova High School',
  'Cordova Jr/sr High School',
  'Cordova Lane Center',
  'Cordova Meadows Elementary',
  'Cordova Middle',
  'Cordova Park Elementary School',
  'Cordova School',
  'Cordova School District Innovative Learning',
  'Cordova Villa Elementary',
  'Cordua Elementary',
  'Core Butte Charter',
  'Core Charter',
  'Core Knowledge Charter School',
  'Core Learning Academy At Conley-caraballo High',
  'Core Placer Charter',
  'Core4',
  'Coretta Scott King Magnet Sch',
  'Corey Fine Arts / Dual Language Academy',
  'Corina Pena El',
  'Corinna Elementary School',
  'Corinne Early Learning Center',
  'Corinth Classical Academy',
  'Corinth Classical Upper School',
  'Corinth El',
  'Corinth Elem',
  'Corinth Elementary',
  'Corinth Elementary School',
  'Corinth High School',
  'Corinth Holders High',
  'Corinth Middle School',
  'Corinth-holders Elementary',
  'Cork Elementary School',
  'Cork Institute of Technology',
  'Corkery Elem School',
  'Corkran Middle School',
  'Corkscrew Elementary School',
  'Corkscrew Middle School',
  'Corl Street El Sch',
  'Corley Elementary School',
  'Corliss High School',
  'Cormier School And Early Learning Center',
  'Cornatzer Elementary',
  'Cornel Young & Charlotte Woods',
  'Cornelia Elementary',
  'Cornelia Elementary School',
  'Cornelia F. Bradford School',
  'Cornelius El',
  'Cornelius Elementary',
  'Cornelius Elementary School',
  'Cornelius Hedges School',
  'Cornelius M Callahan',
  'Cornell College',
  'Cornell El Sch',
  'Cornell Elem School',
  'Cornell Elementary',
  'Cornell Elementary School',
  'Cornell High',
  'Cornell Hs',
  'Cornell Middle',
  'Cornell University',
  'Cornelsen Es',
  'Corner Canyon High',
  'Corner High School',
  'Corner Lake Middle',
  'Corner Middle School',
  'Cornerstone',
  'Cornerstone Academy',
  'Cornerstone Academy Charter',
  'Cornerstone Academy Community School',
  'Cornerstone Academy For Social Action',
  'Cornerstone Academy For Social Action Middle School',
  'Cornerstone At Pedregal Elementary',
  'Cornerstone Charter Academy High School',
  'Cornerstone Charter Academy-cfa',
  'Cornerstone Charter School',
  'Cornerstone El',
  'Cornerstone Elementary',
  'Cornerstone Elementary School',
  'Cornerstone Health And Technology School',
  'Cornerstone Jeffersondouglass Academy',
  'Cornerstone Learning Center',
  'Cornerstone Montessori Elementary',
  'Cornerstone Prep - Lester Campus',
  'Cornerstone Prep Denver Campus',
  'Cornerstones Program',
  'Cornersville Elementary',
  'Cornersville School',
  'Corning Achievement Elementary',
  'Corning Community College',
  'Corning Elementary School',
  'Corning High',
  'Corning High School',
  'Corning Painted Post High School Learning Center',
  'Corning-painted Post High School',
  'Corning-painted Post Middle School',
  'Cornish Elementary School',
  'Cornville Downtown Campus',
  'Cornville Early Childhood Campus',
  'Cornville Regional Charter School',
  'Cornwall Central High School',
  'Cornwall Consolidated School',
  'Cornwall El Sch',
  'Cornwall Elementary School',
  'Cornwall Middle School',
  'Cornwall Terrace El Sch',
  'Cornwall-on-hudson Elementary School',
  'Cornwell Avenue School',
  'Cornwells El Sch',
  'Corona Arts And Sciences Academy',
  'Corona Avenue Elementary',
  'Corona Creek Elementary',
  'Corona Del Mar High',
  'Corona Del Sol High School',
  'Corona Elementary',
  'Corona Foothills Middle School',
  'Corona Fundamental Intermediate',
  'Corona High',
  'Corona Ranch Elementary',
  'Corona Vista High',
  'Corona-norco Alternative',
  'Coronado Beach Elementary School',
  'Coronado El',
  'Coronado Elem',
  'Coronado Elementary',
  'Coronado Elementary School',
  'Coronado H S',
  'Coronado Heights Es',
  'Coronado High',
  'Coronado High (continuation)',
  'Coronado High School',
  'Coronado Hills Elementary School',
  'Coronado K-8 School',
  'Coronado Middle',
  'Coronado Unified Special Education Pre-k',
  'Coronado Village El',
  'Coronado Village Elementary',
  'Coronita Elementary',
  'Corporación Educativa Mayor del Desarrollo "Simón Bolivar"',
  'Corporación Universitaria Tecnológica de Bolivar',
  'Corporación Universitaria de Ibagué (CORUNIVERSITARIA)',
  'Corporación Universitaria de Santander (UDES)',
  'Corporación Universitaria de la Costa (UNICOSTA)',
  'Corporate Landing Elementary',
  'Corporate Landing Middle',
  'Corpus Christi College Prep H S',
  'Corpus Christi Montessori School',
  'Corr Elementary School',
  'Corral Drive Elementary - 21',
  'Corrales Elementary',
  'Corrales International',
  'Correctional Services',
  'Corrective Behavior Ctr',
  'Correia Middle',
  "Corretta Scott King Womens' Leadership Academy",
  'Corridor Elementary School',
  'Corrigan-camden El',
  'Corrigan-camden H S',
  'Corrigan-camden J H',
  'Corrigan/radgowski Correctional Institution',
  'Corriher Lipe Middle',
  'Corron Elementary School',
  'Corry Area Hs',
  'Corry Area Intrmd Sch',
  'Corry Area Ms',
  'Corry Area Primary Sch',
  'Corryton Elementary',
  'Corse Elementary School',
  'Corsica-stickney Elementary - 02',
  'Corsica-stickney High School - 01',
  'Corsica-stickney Jr. High - 03',
  'Corsicana H Igh School',
  'Corsicana Middle',
  'Cortada Elementary',
  'Corte Madera',
  'Corte Sierra Elementary School',
  'Cortez Elementary',
  'Cortez High School',
  'Cortez Middle School',
  'Cortina Elementary',
  'Cortland Elementary School',
  'Cortland High School',
  'Cortland Junior High School',
  'Cortlandt V.r. Creed Health And Sport Sciences High School',
  'Corunna High School',
  'Corunna Middle School',
  'Corvallis 7-8',
  'Corvallis Elementary',
  'Corvallis High School',
  'Corvallis Middle',
  'Corvian Community School',
  'Corwin International Magnet School',
  'Cory Elementary School',
  'Cory-rawson Elementary School',
  'Cory-rawson High School',
  'Corydon Central High School',
  'Corydon Central Jr High School',
  'Corydon Elementary School',
  'Corydon Intermediate School',
  'Cos Cob School',
  'Cosby Elementary',
  'Cosby High',
  'Cosby High School',
  'Coshocton County Career Center',
  'Coshocton Elementary',
  'Coshocton High School',
  'Coshocton Opportunity School',
  'Cosmopolis Elementary School',
  'Cosmos Learning Center',
  'Cosmos Learning Center-transitions',
  'Cossa Academy',
  'Cossatot Community College of the University of Arkansas',
  'Cossatot River High School',
  'Cossitt Ave Elem School',
  'Costa Mesa High',
  'Costano Elementary',
  'Costanoa Continuation High',
  'Costello Elementary School',
  'Costello Prep',
  'Costello School',
  'Costner Elementary',
  'Coston El',
  'Cosumnes Oaks High',
  'Cosumnes River College',
  'Cosumnes River Elementary',
  'Cotaco School',
  'Cotati-rohnert Park Preschool',
  'Cote Brilliante Elem.',
  'Coteau Elementary School',
  'Coteau-bayou Blue Elementary School',
  'Cotee River Elementary School',
  'Cotopaxi Elementary School',
  'Cotopaxi Junior-senior High School',
  'Cotswold Elementary',
  'Cottage Elementary',
  'Cottage Grove Elementary',
  'Cottage Grove High School',
  'Cottage Grove Middle School',
  'Cottage Grove Upper Grade Center',
  'Cottage Hill Elementary',
  'Cottage Lake Elementary',
  'Cottage Lane Elementary School',
  'Cottage Street',
  'Cottageville Elementary',
  'Cottageville Elementary School',
  'Cotter Early Childhood Center',
  'Cotter High School',
  'Cotteral Es',
  'Cotto Anexo',
  'Cotton Belt Elementary',
  'Cotton Boll School',
  'Cotton Center School',
  'Cotton Correctional Facility',
  'Cotton Creek Elementary School',
  'Cotton Creek School',
  'Cotton Indian Elementary School',
  'Cottondale Elementary School',
  'Cottondale High School',
  'Cottonport Elementary',
  'Cottonwood Canyon Elementary',
  'Cottonwood Classical Prep',
  'Cottonwood Community Day',
  'Cottonwood Creek Charter',
  'Cottonwood Creek El',
  'Cottonwood Creek Elementary',
  'Cottonwood Creek Elementary School',
  'Cottonwood Day School',
  'Cottonwood Elem',
  'Cottonwood Elementary',
  'Cottonwood Elementary School',
  'Cottonwood High',
  'Cottonwood High School',
  'Cottonwood Middle School',
  'Cottonwood Plains Elementary School',
  'Cottonwood Point Elementary',
  'Cottonwood Preschool',
  'Cottonwood Public School',
  'Cottonwood School',
  'Cottonwood Valley Charter',
  'Cotulla H S',
  'Couch Elem.',
  'Couch High',
  'Couch Middle School',
  'Coudersport Area El Sch',
  'Coudersport Area Jshs',
  'Cougar Academy',
  'Cougar Creek Elementary School',
  'Cougar Elementary',
  'Cougar Mountain Middle School',
  'Cougar Ridge Elementary',
  'Cougar Run Elementary School',
  'Cougar Valley Elementary',
  'Coulee City Elementary',
  'Coulee City Ms',
  'Coulee Montessori Charter School',
  'Coulston Elementary School',
  'Coulter Grove Intermediate School',
  'Coulterville Elementary School',
  'Coulterville High',
  'Coulterville High School',
  'Coulterville Junior High School',
  'Coulwood Stem Academy',
  'Council Elementary School',
  'Council Elementary/middle School',
  'Council Grove Elementary School',
  'Council Grove Es',
  'Council Grove Junior Senior High School',
  'Council High',
  'Council Jr/sr High School',
  'Council Rock Hs North',
  'Council Rock Hs South',
  'Council Rock Primary School',
  'Country',
  'Country Club Elementary',
  'Country Club Middle School',
  'Country Dale Elementary',
  'Country Elementary School',
  'Country Estates Es',
  'Country Gardens Charter School',
  'Country Heights Elementary School',
  'Country High',
  'Country Hills Elementary School',
  'Country Isles Elementary School',
  'Country Lane Elementary',
  'Country Lane Intermediate Es',
  'Country Lane Primary Es',
  'Country Meadow Elementary School',
  'Country Meadows Elem School',
  'Country Meadows Elementary',
  'Country Meadows Elementary School',
  'Country Oaks Elementary School',
  'Country Parkway Elementary School',
  'Country Place El',
  'Country Place Elementary',
  'Country Springs Elementary',
  'Country Trails Elem',
  'Country View Elem',
  'Country View Elementary',
  'Country View School',
  'Countryside',
  'Countryside Academy Elementary',
  'Countryside Academy Middlehigh School',
  'Countryside Community Day',
  'Countryside Elem School',
  'Countryside Elementary',
  'Countryside Elementary School',
  'Countryside High School',
  'Countryside Montessori Academy',
  'Countrywood Primary Center',
  'County Administrative Annex',
  'County Collaborative Charter',
  'County College of Morris',
  'County Community',
  'County Jail Education Program',
  'County Line El',
  'County Line Elementary',
  'County Line Elementary School',
  'County Line High School',
  'County Of Nassau/correctional Center',
  'County Prep High School',
  'County Residential Center',
  'County Road School',
  'County Wide Exceptional Child Programs',
  'County Wide Exceptional Student Education',
  'Coupeville Elementary School',
  'Coupeville High School',
  'Coupeville Middle School',
  'Coupland El',
  'Courreges (roch) Elementary',
  'Court Street Elementary School',
  'Courtade Elementary School',
  'Courtauld Institute of Art University of London',
  'Courtenay Elem Language Arts Ctr',
  'Courthouse Academy Program',
  'Courthouse Road Elementary',
  'Courtland Elementary',
  'Courtland High',
  'Courtney Elementary',
  'Cousino Senior High School',
  'Cousins Middle School',
  'Cove',
  'Cove Charter Academy',
  'Cove Charter School',
  'Cove Creek Elementary',
  'Cove Day School',
  'Cove Elementary',
  'Cove Road School',
  'Covedale Elementary School',
  'Covel D. Searcy Elem.',
  'Covenant House Academy Detroit Central Site',
  'Covenant House Academy Detroit East Site',
  'Covenant House Academy Detroit Southwest Site',
  'Covenant House Academy Grand Rapids',
  'Covenant Keepers Charter',
  'Covenant University',
  'Coventry Elem School',
  'Coventry Elementary',
  'Coventry Elementary School',
  'Coventry Grammar School',
  'Coventry High School',
  'Coventry Middle School',
  'Coventry Oak Elementary',
  'Coventry University',
  'Coventry Village School',
  'Coverdell Elem.',
  'Covered Bridge School',
  'Covered Bridge Spec Ed Dist',
  'Covert Avenue School',
  'Covert Elementary School',
  'Covert High School',
  'Covert Middle School',
  'Covillaud Elementary',
  'Covina High',
  'Covington Adult High School',
  'Covington Co Vocational Technical',
  'Covington Community High School',
  'Covington County Alternative School',
  'Covington Elementary',
  'Covington Elementary School',
  'Covington High',
  'Covington High School',
  'Covington Integrated Arts Academy',
  'Covington Junior High School',
  'Covington Middle',
  'Covington Middle School',
  'Covington School',
  'Covington Street Elementary',
  'Covington-douglas Es',
  'Covington-douglas Hs',
  'Covington-harper Elementary School',
  'Cowan Academic Center',
  'Cowan Avenue Elementary',
  'Cowan El',
  'Cowan Elementary',
  'Cowan Elementary School',
  'Cowan High School',
  'Cowan Road Elementary School',
  'Cowan Road Middle School',
  'Cowan Sunset Southeast Hs',
  'Cowanesque Valley Jshs',
  'Cowden Elem.',
  'Cowden-herrick Grade School',
  'Cowden-herrick Jr/sr High School',
  'Coweeman Middle School',
  'Cowell Elementary School',
  'Cowen Early Childhood Center',
  'Cowern Elementary',
  'Coweta Charter Academy',
  'Coweta Hs',
  'Coweta Intermediate Hs',
  'Cowgill Elem.',
  'Cowing Early Childhood',
  'Cowles Elementary School',
  'Cowley County Community College',
  'Cowlitz County Youth Services Center',
  'Cowlitz Prairie Academy',
  'Cowpens Elementary',
  'Cowpens Middle',
  'Cowsert Elementary School',
  'Cox (james H.) Elementary',
  'Cox Academy',
  'Cox Bar Elementary',
  'Cox Elementary School',
  'Cox Int',
  'Cox Landing Elementary School',
  'Cox Mill Elementary',
  'Cox Mill High School',
  'Coxs Creek Elementary School',
  'Coxsackie Elementary School',
  'Coxsackie-athens High School',
  'Coxsackie-athens Middle School',
  'Coy Elementary School',
  'Coyle Avenue Elementary',
  'Coyle Es',
  'Coyle Hs',
  'Coyle Middle',
  'Coyote Canyon Elementary',
  'Coyote Canyon School',
  'Coyote Creek Elementary',
  'Coyote Creek Elementary School',
  'Coyote Hills Elementary School',
  'Coyote Ridge',
  'Coyote Ridge El',
  'Coyote Ridge Elementary',
  'Coyote Ridge Elementary School',
  'Coyote Springs Elementary School',
  'Coyote Trail Elementary School',
  'Coyote Valley Elementary',
  'Coyote Willow',
  'Cozad Alternative Ed Center',
  'Cozad Early Education Center',
  'Cozad Elementary School',
  'Cozad High School',
  'Cozad Middle School',
  'Cozy Lake Elementary School',
  'Cpsd Open Doors Program',
  'Cr-b Elementary School',
  'Crab Orchard Elementary',
  'Crab Orchard Elementary School',
  'Crab Orchard High School',
  'Crabapple Crossing Elementary School',
  'Crabapple Lane Elementary School',
  'Crabapple Middle School',
  'Crabbe Elementary School',
  'Cracker Trail Elementary School',
  'Craddock Elementary School',
  'Cradleboard School',
  'Cradlerock Elementary',
  'Cradock Middle',
  'Cradock Technical & Career Center',
  'Craft Elementary',
  'Crafton El Sch',
  'Crafton Elementary',
  'Crafton Hills College',
  'Craftsbury Schools',
  'Cragin Elementary School',
  'Cragmont Elementary',
  'Craig County High',
  'Craig Elem.',
  'Craig Elementary',
  'Craig Elementary School',
  'Craig High',
  'Craig High School',
  'Craig Hill Elementary School',
  'Craig Middle',
  'Craig Middle School',
  'Craig Montessori School',
  'Craig Williams Elementary',
  'Craig-houghton Elementary School',
  'Craigmont High',
  'Craigmont Middle',
  'Craigsville Elementary',
  'Crain El',
  'Crain Es',
  "Crain's Creek Middle",
  'Cram Elementary',
  'Cramer Junior High School',
  'Cramerton Middle',
  'Cramp William Sch',
  'Cranberry Area Jshs',
  'Cranberry El Sch',
  'Cranberry Elementary School',
  'Cranberry Isles School',
  'Cranberry Middle',
  'Cranberry Pines Elementary',
  'Cranberry Station Elementary',
  'Cranberry-prosperity Elementary',
  'Cranbrook Elementary School',
  'Cranbury Elementary School',
  'Cranbury School',
  'Crandall Compass Academy',
  'Crandall H S',
  'Crandall Middle',
  'Crandon Elementary',
  'Crandon High',
  'Crandon Middle',
  'Crane El',
  'Crane Elem.',
  'Crane Elementary School',
  'Crane H S',
  'Crane High',
  'Crane Ilearning Academy',
  'Crane Medical Prep Hs',
  'Crane Middle',
  'Crane Middle School',
  'Crane Union High School',
  'Craneville',
  'Cranfield University',
  'Cranfills Gap School',
  'Cranford High School',
  'Cranston Career & Tech Center',
  'Cranston Early Learning Center',
  'Cranston High School East',
  'Cranston High School West',
  'Crater Academy Of Health And Public Services',
  'Crater Juvenile Detention Home',
  'Crater Lake Charter Academy',
  'Crater Renaissance Academy',
  'Crater School Of Business Innovation And Science',
  'Craven Community College',
  'Craven Early College High',
  'Cravens Elementary School',
  'Craver Middle School',
  'Crawford County Ctc',
  'Crawford County Elementary School',
  'Crawford County High School',
  'Crawford County Middle School',
  'Crawford El',
  'Crawford Elementary',
  'Crawford Elementary School',
  'Crawford H S',
  'Crawford High',
  'Crawford High School',
  'Crawford Middle School',
  'Crawford School',
  'Crawford University',
  'Crawford Woods Elementary School',
  'Crawford-rodriguez Elementary School',
  'Crawfordsville Middle School',
  'Crawfordsville Sr High School',
  'Crawfordville Elementary School',
  'Craycroft Elementary School',
  'Crayton Middle',
  'Crazy Horse School',
  'Crcc-open Doors',
  'Create Academy',
  'Creative Alternatives Program',
  'Creative And Performing Arts',
  'Creative Arts Charter',
  'Creative Arts Morgan Village Academy',
  'Creative Arts Secondary School',
  'Creative Center For Children',
  'Creative City Public Charter School',
  'Creative Communications Acad',
  'Creative Connections Arts Academy',
  'Creative Inspiration Journey School Of St Cloud',
  'Creative Learning Center Dearborn',
  'Creative Learning Center Highland Park',
  'Creative Learning Center Madison Heights',
  'Creative Learning Center Warren',
  'Creative Minds',
  'Creative Minds International Pcs',
  'Creative Montessori Academy',
  'Creative Performing And Media Arts',
  'Creative Science School',
  'Creative Technologies Academy',
  'Creativity Challenge Community',
  'Credit Recovery',
  'Credit Recovery Academy',
  'Credo High',
  'Creech Road Elementary',
  'Creed Collins Elementary',
  'Creede School',
  'Creedmoor El',
  'Creedmoor Elementary',
  'Creeds Elementary',
  'Creek Bridge High',
  'Creek Es',
  'Creek Valley Elementary',
  'Creek Valley Elementary School',
  'Creek Valley High School',
  'Creek Valley Middle',
  'Creek Valley Middle School',
  'Creek View El',
  'Creek View Elementary',
  'Creek View Elementary School',
  'Creek Wood High School',
  'Creekland Middle School',
  'Creekmoor Elem.',
  'Creekside Charter',
  'Creekside Charter Academy',
  'Creekside Early Childhood School',
  'Creekside Early Learning Center',
  'Creekside El',
  'Creekside Elem',
  'Creekside Elementary',
  'Creekside Elementary - 07',
  'Creekside Elementary School',
  'Creekside Elementary School At Martin Park',
  'Creekside Forest El',
  'Creekside High',
  'Creekside High School',
  'Creekside Int',
  'Creekside Intermediate School',
  'Creekside Junior High',
  'Creekside Middle',
  'Creekside Middle School',
  'Creekside Oaks Elementary',
  'Creekside Park Elementary',
  'Creekside Park J H',
  'Creekside Primary School',
  'Creekside School',
  'Creekview El',
  'Creekview Elementary School',
  'Creekview H S',
  'Creekview High School',
  'Creekview Intermediate Elementary School',
  'Creekview Middle',
  'Creekview Ranch',
  'Creekview School',
  'Creekwood Early Childhood Ctr',
  'Creekwood Es',
  'Creekwood Middle',
  'Creighton Community High Sch',
  'Creighton El',
  'Creighton Elementary School',
  'Creighton Middle School',
  'Creighton University',
  "Creighton's Corner Elementary",
  'Crellin Elementary',
  'Cremer Therapeutic Comm Center',
  'Crenshaw County Area Vocational',
  'Crenshaw El',
  'Crenshaw El And Middle',
  'Crenshaw Elementary School',
  'Crenshaw Middle School',
  'Crenshaw Sci Tech Engr Math And Med Magnet',
  'Cresaptown Elementary',
  'Crescent Academy Elementary',
  'Crescent Academy High School',
  'Crescent Academy Middle School',
  'Crescent Academy Pk1',
  'Crescent City Grade School',
  'Crescent City High School',
  'Crescent Elementary',
  'Crescent Elementary School',
  'Crescent Elementary/junior High School',
  'Crescent Elk Middle',
  'Crescent Es',
  'Crescent Harbor Elem',
  'Crescent Heights',
  'Crescent Heights Boulevard Elementary',
  'Crescent High',
  'Crescent Hs',
  'Crescent Lake School',
  'Crescent Leadership Academy',
  'Crescent Ms',
  'Crescent Park School',
  'Crescent Road Elementary School',
  'Crescent School',
  'Crescent University',
  'Crescent Valley Elementary School',
  'Crescent Valley High School',
  'Crescent Valley Public Charter',
  'Crescent Valley Public Charter Ii',
  'Crescent View South Charter',
  'Crescent View South Ii',
  'Crescent View West Public Charter',
  'Crescenta Valley High',
  'Crescentwood Elementary School',
  'Cresco Early Childhood Development Center',
  'Creslane Elementary School',
  'Crespi Junior High',
  'Crespo El',
  'Cressey Elementary',
  'Cresskill High School',
  'Cresskill Middle School',
  'Cresson Elementary',
  'Cresson Elementary School',
  'Crest Academy',
  'Crest Elementary',
  'Crest High',
  'Crest Hill Elementary',
  'Crest Memorial School',
  'Crest Middle',
  'Crest Ridge Elem.',
  'Crest Ridge High',
  'Crest School',
  'Crest View Elementary',
  'Crest View Elementary School',
  'Crestdale Elementary School',
  'Crestdale Middle',
  'Crested Butte Elementary School',
  'Crested Butte Secondary School',
  'Cresthaven Academy Charter School',
  'Cresthaven Elementary',
  'Cresthaven Elementary School',
  'Cresthill Middle School',
  'Crestline Elementary',
  'Crestline Elementary School',
  'Crestline High School',
  'Crestline Preschool',
  'Crestmont Elementary',
  'Crestmont Elementary School',
  'Crestmore Elementary',
  'Creston Academy',
  'Creston Early Childhood Center',
  'Creston Elem School',
  'Creston Elementary',
  'Creston Elementary School',
  'Creston High School',
  'Creston Intermediate Academy',
  'Creston Jr-sr High School',
  'Creston Middle School',
  'Creston School',
  'Crestone Charter School',
  'Crestridge Magnet Center',
  'Crestview College Preparatory High School',
  'Crestview El',
  'Crestview El Sch',
  'Crestview Elem',
  'Crestview Elem.',
  'Crestview Elementary',
  'Crestview Elementary School',
  'Crestview Heights School',
  'Crestview High School',
  'Crestview Middle',
  'Crestview Middle School',
  'Crestview School',
  'Crestview School Of Inquiry',
  'Crestview Youth Academy',
  'Crestwood Accelerated Program',
  'Crestwood Community Middle',
  'Crestwood Elem School',
  'Crestwood Elem.',
  'Crestwood Elementary',
  'Crestwood Elementary School',
  'Crestwood High',
  'Crestwood High School',
  'Crestwood Hs',
  'Crestwood Intermediate',
  'Crestwood Intermediate School',
  'Crestwood Jr High School',
  'Crestwood Middle',
  'Crestwood Middle School',
  'Crestwood Ms',
  'Crestwood Primary School',
  'Crestwood Street Elementary',
  'Crestwood/larlham',
  'Crestworth Elementary School',
  'Creswell Elementary',
  'Creswell Elementary School',
  'Creswell High',
  'Creswell High School',
  'Creswell Middle Prep School Of The Arts',
  'Creswell Middle School',
  'Crete Academy',
  'Crete Elementary School',
  'Crete High School',
  'Crete Intermediate School',
  'Crete Middle School',
  'Crete-monee Early Childhood Cntr',
  'Crete-monee High School',
  'Crete-monee Middle School',
  'Crewe Primary',
  'Crews Lake Middle School.',
  'Crews Middle School',
  'Crichton Elementary',
  'Cridersville Elementary School',
  'Crieve Hall Elementary',
  'Crim Elementary School',
  'Crim High School',
  'Crim Primary School',
  'Crimea State Medical University',
  'Crimson Point Elementary School',
  'Crimson View School',
  'Cripple Creek-victor Junior-senior High School',
  'Crisfield Academy And High School',
  'Crisman Elementary School',
  'Crismon Elementary School',
  'Crisp County Elementary School',
  'Crisp County High School',
  'Crisp County Middle School',
  'Crisp County Pre-k',
  'Crisp County Primary School',
  'Crisp Rydc',
  'Crispell Middle School',
  'Crispus Attucks Cs',
  'Crispus Attucks Med Mgnt Middle Sc',
  'Crispus Attucks Medical Magnet Hs',
  'Criss Elementary School',
  'Crissey Elementary School',
  'Crissman Elementary School',
  'Cristina (amada) Martinez Martinez',
  'Cristobal Colon',
  'Cristobal Del Campo',
  'Cristobal Santana Melecio (nueva)',
  'Cristobal Vicens',
  'Crittenden County Elementary School',
  'Crittenden County High School',
  'Crittenden County Middle School',
  'Crittenden Middle',
  'Crittenden-mt. Zion Elementary',
  'Crittenton Treatment Center',
  'Critzer Elementary',
  'Crivitz Elementary',
  'Crivitz High',
  'Crivitz Middle',
  'Croatan High',
  'Crocker Elem.',
  'Crocker Elementary',
  'Crocker Elementary School',
  'Crocker Farm Elementary',
  'Crocker High',
  'Crocker Highlands Elementary',
  'Crocker Middle',
  'Crocker/riverside Elementary',
  'Crockett Career And Technical Center',
  'Crockett County High School',
  'Crockett County Middle School',
  'Crockett Early Education School',
  'Crockett Early Headstart',
  'Crockett El',
  'Crockett Elementary',
  'Crockett H S',
  'Crockett Int',
  'Crockett J H',
  'Crockett Middle',
  'Croft Community Elementary',
  'Croft Middle',
  'Crofton Elementary',
  'Crofton Elementary School',
  'Crofton High School',
  'Crofton Meadows Elementary',
  'Crofton Middle',
  'Crofton Woods Elementary',
  'Croghan Elementary School',
  'Croissant Park Elementary School',
  'Cromack El',
  'Cromie Elementary School',
  'Crompond School',
  'Cromwell Elementary',
  'Cromwell High School',
  'Cromwell Middle School',
  'Cromwell Valley Elementary Technology',
  'Cromwell-wright Elementary',
  'Cromwell-wright Secondary',
  'Crook County High School',
  'Crook County Middle School',
  'Crooked Billet El Sch',
  'Crooked Creek Elementary School',
  'Crooked Lake Elementary',
  'Crooked Oak Hs',
  'Crooked Oak Ms',
  'Crooked River Elementary School',
  'Crookshank Elementary School',
  'Crookston Pre-k Program',
  'Crookston Secondary',
  'Crooksville Elementary School',
  'Crooksville High School',
  'Crooksville Middle School',
  'Croom High',
  'Crooms Academy Of Information Technology',
  'Crosby',
  'Crosby Crossroads Academy',
  'Crosby El',
  'Crosby Elem Sch',
  'Crosby Elementary School',
  'Crosby H S',
  'Crosby High School',
  'Crosby Kindergarten Center',
  'Crosby Middle',
  'Crosby Park Es',
  'Crosby-ironton Secondary',
  'Crosbyton Daep',
  'Crosbyton El',
  'Crosbyton Secondary',
  'Crosley Elementary School',
  'Cross Bayou Elementary School',
  'Cross Cnty High A New Tech Sch',
  'Cross Country Elementary',
  'Cross County Elementary School',
  'Cross County Elementary Tech Academy',
  'Cross County High School',
  'Cross County Middle School',
  'Cross Creek Charter Academy',
  'Cross Creek Early College',
  'Cross Creek Elementary School',
  'Cross Creek High School',
  'Cross Creek School',
  'Cross Elementary',
  'Cross High',
  'Cross Hill Academy',
  'Cross Keys High School',
  'Cross Keys Middle',
  'Cross Lanes Elementary School',
  'Cross Oaks El',
  'Cross Plains El',
  'Cross Plains H S',
  'Cross River University of Science and Technology',
  'Cross Roads El',
  'Cross Roads H S',
  'Cross Roads J H',
  'Cross Street Intermediate School',
  'Cross Timbers El',
  'Cross Timbers Es',
  'Cross Timbers Int',
  'Cross Timbers Middle',
  'Crossan Kennedy C Sch',
  'Crosscreek Charter School',
  'Crossett Brook Middle School',
  'Crossett Elementary School',
  'Crossett High School',
  'Crossett Middle School',
  'Crossfield Elementary',
  'Crossgates Pre-school',
  'Crosslake Community Charter School',
  'Crosslake Community High School',
  'Crossland Evening/saturday High',
  'Crossland High',
  'Crossler Middle School',
  'Crossnore Elementary',
  'Crosspointe Elementary School',
  'Crossroad Academy',
  'Crossroad Learning Center',
  'Crossroads',
  'Crossroads - Central Street',
  'Crossroads - Quality Hill',
  'Crossroads Academy',
  'Crossroads Alternative Education Center',
  'Crossroads Alternative High School',
  'Crossroads Alternative/bristol City',
  'Crossroads Area Learning Center',
  'Crossroads Arts Sciences Early College',
  'Crossroads Center',
  'Crossroads Charter',
  'Crossroads Charter Academy 712',
  'Crossroads Charter Academy Pk6',
  'Crossroads Community Day',
  'Crossroads Community School',
  'Crossroads Elem.',
  'Crossroads Elementary',
  'Crossroads Elementary School',
  'Crossroads Flex',
  'Crossroads H S',
  'Crossroads High (alternative)',
  'Crossroads High School',
  'Crossroads Hope Academy',
  'Crossroads Intermediate',
  'Crossroads Middle School',
  'Crossroads Montessori',
  'Crossroads Ms',
  'Crossroads North Middle School',
  'Crossroads Park Elementary School',
  'Crossroads School',
  'Crossroads School & Voc. Center',
  'Crossroads Science Program',
  'Crossroads South',
  'Crossroads South Middle School',
  'Crossroads Treatment Center',
  'Crosstimbers Academy',
  'Crossville Elementary School',
  'Crossville High School',
  'Crossville Middle School',
  'Crosswell Drive Elementary',
  'Crosswell Elementary',
  'Crosswind Elementary School',
  'Crosswinds Arts And Science High',
  'Crosswinds Arts And Science Middle',
  'Crosswinds H S',
  'Croswell Elementary School',
  'Croswelllexington High School',
  'Croswelllexington Middle School',
  'Crothers Elementary School',
  'Crothersville Elementary School',
  'Crothersville Jr-sr High School',
  'Croton Elementary School',
  'Croton Pre-kdg Ctr',
  'Croton-harmon High School',
  'Crotona Academy High School',
  'Crotona International High School',
  'Crouch El',
  'Crouse Community Learning Center',
  'Crow Agency School',
  'Crow Creek Reservation High School',
  'Crow Creek Sioux Tribal Elem School',
  'Crow El',
  'Crow Island Elem School',
  'Crow Middle/high School',
  'Crow River Area Learning Center',
  'Crow Village Sam School',
  'Crowder Avts',
  'Crowder College',
  'Crowder Es',
  'Crowder Hs',
  'Crowders Creek Elementary',
  'Crowell',
  'Crowell Elementary',
  'Crowell School',
  'Crowheart Elementary',
  'Crowley Alternative School',
  'Crowley County Elementary K-6',
  'Crowley County Junior And Senior High School',
  'Crowley County Primary',
  'Crowley Elementary',
  'Crowley H S',
  'Crowley High School',
  'Crowley Kindergarten School',
  'Crowley Middle',
  'Crowley Middle School',
  'Crowley Ridge School',
  'Crown Charter School',
  'Crown Elem Comm Acd Fine Arts Ctr',
  'Crown King Elementary School',
  'Crown Point Central School',
  'Crown Point Elementary',
  'Crown Point Elementary School',
  'Crown Point High School',
  'Crown Pointe Charter Academy',
  'Crown Preparatory Academy',
  'Crowne Hill Elementary',
  'Crownhill Elementary School',
  'Crownover Middle',
  'Crownpoint Elementary',
  'Crownpoint High',
  'Crownpoint Middle',
  'Crowville School',
  'Croydon Village School',
  'Crozet Elementary',
  'Crozier (george W.) Middle',
  'Cruhsd Academy',
  'Cruikshank School',
  'Cruiser Academy',
  'Crum Elementary School',
  'Crum Middle School',
  'Crum Pre K-8 School',
  'Crumly Chapel Elementary School',
  'Crump Elementary',
  'Crump Elementary School',
  'Crums Lane Elementary',
  'Crutchfield El',
  'Crutcho Public School',
  'Cruz Rosa Rivas',
  'Cruz Salguero Torres',
  'Cryar Intermediate',
  'Crystal Boarding School',
  'Crystal City Elem.',
  'Crystal City H S',
  'Crystal City High',
  'Crystal Hill Elementary',
  'Crystal Lake Central High School',
  'Crystal Lake Elementary School',
  'Crystal Lake Middle School',
  'Crystal Lake School',
  'Crystal Lake South High School',
  'Crystal Lakes Elementary School',
  'Crystal Lawns Elem School',
  'Crystal Middle',
  'Crystal River Elementary School',
  'Crystal River High School',
  'Crystal River Middle School',
  'Crystal River Primary School',
  'Crystal Spring Elementary',
  'Crystal Springs Elementary',
  'Crystal Springs Elementary School',
  'Crystal Springs High School',
  'Crystal Springs Middle School',
  'Cs Brown High',
  'Csa Fodrea Campus',
  'Csa Lincoln Campus',
  'Csi Charter School',
  'Csi High School For International Studies',
  'Ctc Of Lackawanna County',
  'Ctd - Benson High School',
  'Ctd - Bisbee High School',
  'Ctd - Bowie High School',
  'Ctd - Buena High School',
  'Ctd - Cochise Technology School',
  'Ctd - Douglas High School',
  'Ctd - San Simon High School',
  'Ctd - St. David High School',
  'Ctd - Tombstone High School',
  'Ctd - Valley Union High School',
  'Ctd - Willcox High School',
  'Cte - 37',
  'Cte Virtual Center',
  'Ctr. For Educ. Development',
  'Cub Run Elementary',
  'Cub Run Elementary School',
  'Cuba City Elementary',
  'Cuba City High',
  'Cuba Elem School',
  'Cuba Elem.',
  'Cuba Elementary',
  'Cuba High',
  'Cuba Middle',
  'Cuba Middle School',
  'Cuba Sr High School',
  'Cuba-rushford Elementary School',
  'Cuba-rushford High School',
  'Cuba-rushford Middle School',
  'Cubberley Elementary',
  'Cubberley K-8',
  'Cubero Elementary',
  'Cubidor University Switzerland',
  'Cucamonga Elementary',
  'Cucamonga Middle',
  'Cuchillas',
  'Cudahy High',
  'Cudahy Middle',
  'Cuddeback Elementary',
  'Cuero H S',
  'Cuero J H',
  'Cuesta College',
  'Cuesta De Piedras',
  'Cuffe Math-sci Tech Elem Academy',
  'Cuivre Park Elementary',
  'Cukurova University',
  'Culbertson 7-8',
  'Culbertson El Sch',
  'Culbertson High School',
  'Culbertson School',
  'Culbreth Middle',
  'Culdesac School',
  'Culinary Arts Academy At Weaver High School',
  'Cullen Elem School',
  'Cullen Elementary',
  'Cullen Middle',
  'Culleoka Unit School',
  'Culler Middle School',
  'Cullman Area Resource Education',
  'Cullman Area Technology Academy',
  'Cullman Child Development Center',
  'Cullman City Career Tech',
  'Cullman City Primary School',
  'Cullman Community Comprehensive Learning Center',
  'Cullman High School',
  'Cullman Middle School',
  'Culloden Elementary School',
  'Cullowhee Valley School',
  'Culpeper County High',
  'Culpeper Middle',
  'Cultural Academy For The Arts And Sciences',
  'Cultural Arts Academy Charter School At Spring Creek',
  'Culver City High',
  'Culver City Middle',
  'Culver City Unified School District Iacademy',
  'Culver Community Middle/high Sch',
  'Culver Elementary School',
  'Culver Family Learning Center',
  'Culver High School',
  'Culver Middle School',
  'Culver Park High',
  'Culverdale Elementary',
  'Cumberland Academy',
  'Cumberland Academy Middle',
  'Cumberland Campus',
  'Cumberland Co Juv Ctr',
  'Cumberland County College',
  'Cumberland County Elementary School',
  'Cumberland County High School',
  'Cumberland County Middle School',
  'Cumberland Elem School',
  'Cumberland Elementary',
  'Cumberland Elementary School',
  'Cumberland Gap High School',
  'Cumberland H S',
  'Cumberland Hall School',
  'Cumberland Head Elementary School',
  'Cumberland Heights Elementary',
  'Cumberland High',
  'Cumberland High School',
  'Cumberland International Early Collegehs',
  'Cumberland Middle',
  'Cumberland Middle School',
  'Cumberland Mills Elementary',
  'Cumberland Perry Avts',
  'Cumberland Polytechnic',
  'Cumberland Preschool Center',
  'Cumberland Regional High School',
  'Cumberland Regional School',
  'Cumberland Road Elem School',
  'Cumberland Road Elementary',
  'Cumberland School',
  'Cumberland Tech - Harlan Campus',
  'Cumberland Tech - Southeast Campus',
  'Cumberland Technical College',
  'Cumberland Trace Elementary',
  'Cumberland Trans Ed Center',
  'Cumberland Valley Health Tech Center',
  'Cumberland Valley Hs',
  'Cumby El',
  'Cumby H S',
  'Cumhuriyet (Republic) University',
  'Cumming Elementary School',
  'Cummings El',
  'Cummings Elementary School',
  'Cummings Elementary/middle',
  'Cummings Middle',
  'Cummings Valley Elementary',
  'Cumru El Sch',
  'Cunniff',
  'Cunningham Creek Elementary School',
  'Cunningham El',
  'Cunningham Elem',
  'Cunningham Elementary',
  'Cunningham High',
  'Cunningham Intermediate',
  'Cunningham Middle',
  'Cunningham Park Elementary',
  'Cunningham School',
  'Cunningham Special Ed Center',
  'Cupertino High',
  'Cupertino Middle',
  'Curie Elementary',
  'Curie Metropolitan High School',
  'Curiel School',
  'Curington El',
  'Curlew Creek Elementary School',
  'Curlew Elem & High School',
  'Curlew Parent Partner',
  'Curley K-8 School',
  'Curran Middle',
  'Curren Elementary',
  'Current River Career Center',
  'Current River School',
  'Currie Elementary School',
  'Currier Elementary School',
  'Currier Memorial Usd #23',
  'Currituck County High',
  'Currituck County Middle',
  'Curry College',
  'Curry Elementary School',
  'Curry High School',
  'Curry Middle School',
  'Curtin Elementary',
  'Curtin Intrmd Sch',
  'Curtin University of Technology',
  'Curtin University of Technology Sarawak Campus',
  'Curtis Bay Elementary',
  'Curtis Corner Middle School',
  'Curtis Creek Elementary',
  'Curtis El',
  'Curtis Elem School',
  'Curtis Elementary School',
  'Curtis Fundamental Elementary',
  'Curtis Guild',
  'Curtis High School',
  'Curtis Inge Ms',
  'Curtis Junior High',
  'Curtis Middle',
  'Curtis Middle School',
  'Curtis Senior High',
  'Curtis-tufts',
  'Curtistene S Mccowan Middle (isteam)',
  'Curtisville Pri Ctr',
  'Curtner Elementary',
  'Curtsinger El',
  'Curvin-mccabe School',
  'Curwensville Area El Sch',
  'Curwensville Area Jshs',
  'Cushing Community School',
  'Cushing El',
  'Cushing Elementary',
  'Cushing Hs',
  'Cushing Lower Es',
  'Cushing Ms',
  'Cushing Pre-kindergarten Schl',
  'Cushing School',
  'Cushing Upper Es',
  'Cusick Jr Sr High School',
  'Custer 7-8',
  'Custer Baker Intermediate School',
  'Custer Co District High',
  'Custer County Elementary School',
  'Custer County High School',
  'Custer Elem',
  'Custer Elementary - 02',
  'Custer Elementary School',
  'Custer High School',
  'Custer High School - 01',
  'Custer Jr. High - 05',
  'Custer Middle School',
  'Custer School',
  'Customized Alternative Solutions Fo',
  'Cut Bank High School',
  'Cut Bank Middle School',
  'Cut Off Elementary School',
  'Cutcomb Lake School',
  'Cuthbertson High',
  'Cuthbertson Middle',
  'Cutler Bay Middle',
  'Cutler Bay Senior High School',
  'Cutler Elementary',
  'Cutler Ridge Elementary School',
  'Cutler School',
  'Cutler-orosi Community Day',
  'Cutten Elementary',
  'Cutter-morning Star Elem. Sch.',
  'Cutter-morning Star High Sch.',
  'Cuttyhunk Elementary',
  'Cuyahoga Community College District',
  'Cuyahoga Falls High School',
  'Cuyahoga Hts Elementary School',
  'Cuyahoga Hts High School',
  'Cuyahoga Hts Middle School',
  'Cuyahoga Valley Career Center',
  'Cuyama Elementary',
  'Cuyama Valley High',
  'Cuyamaca College',
  'Cuyuna Range Elementary',
  'Cva - Onalaska',
  'Cva-lopez Island',
  'Cvit - Globe High School',
  'Cvit - Hayden High School',
  'Cvit - Miami High School',
  'Cvit - Ray High School',
  'Cvit - San Carlos High School',
  'Cvit - Superior High School',
  'Cvsd Open Doors Programs',
  'Cvsec Step',
  'Cw Neff Early Childhood And Kindergarten Center',
  'Cy-fair H S',
  'Cyber Academy Of South Carolina',
  'Cyber High School',
  'Cyber Village Academy',
  'Cyberjaya University College of Medical Science',
  'Cyberlynx Correspondence Program',
  'Cyd Lash Academy',
  'Cynthia A Cockrell El',
  'Cynthia Cunningham Elementary School',
  'Cynthia Heights Elementary School',
  'Cynthia Jenkins School',
  'Cynthia Mann Elementary School',
  'Cynwyd Sch',
  'Cypress Academy',
  'Cypress Bay High School',
  'Cypress Charter High',
  'Cypress College',
  'Cypress Cove Elementary School',
  'Cypress Creek Academy',
  'Cypress Creek Elementary School',
  'Cypress Creek H S',
  'Cypress Creek High',
  'Cypress Creek High Adult Education',
  'Cypress Creek Middle/high School',
  'Cypress El',
  'Cypress Elem School',
  'Cypress Elementary',
  'Cypress Elementary School',
  'Cypress Falls H S',
  'Cypress Grove Int',
  'Cypress High',
  'Cypress Hills Collegiate Preparatory School',
  'Cypress Junction Montessori',
  'Cypress Juvenile Residential Facility',
  'Cypress K-8 Center',
  'Cypress Lake High School',
  'Cypress Lake Middle School',
  'Cypress Lakes H S',
  'Cypress Palm Middle School',
  'Cypress Park Elementary',
  'Cypress Park Elementary School',
  'Cypress Park H S',
  'Cypress Point Elementary School',
  'Cypress Ranch H S',
  'Cypress Ridge Elementary School',
  'Cypress Ridge H S',
  'Cypress Run Alternative/ese',
  'Cypress School Of The Arts',
  'Cypress Springs Elementary',
  'Cypress Springs Elementary School',
  'Cypress Springs H S',
  'Cypress Trails Elementary School',
  'Cypress Village Elementary',
  'Cypress Woods Elementary School',
  'Cypress Woods H S',
  'Cypress-fairbanks J J A E P',
  'Cypresswood El',
  'Cyprus College',
  'Cyprus High',
  'Cyprus International Institute of Management (CIIM)',
  'Cyprus International University',
  'Cyprus University of Technology',
  'Cyril B. Busbee Creative Arts Academy',
  'Cyril Es',
  'Cyril Hs',
  'Cyril Jhs',
  'Cyril K. Brennan Middle School',
  'Cyril Spinelli Elementary',
  'Cyril Wengert Elementary School',
  'Cyrus E Dallin',
  'Cyrus J. Morris Elementary',
  'Cyrus P Frazier Elementary',
  'Cyrus Peirce',
  'Cyryx College',
  'Czech Technical University of Prague',
  'Czech University of Agriculture Prague',
  'D A E P',
  'D A Hulcy Steam Middle',
  'D A Smith Middle School',
  'D B Bunce Elementary School',
  'D C Cannon El',
  'D C Everest 4k Community Partnership',
  'D C Everest High',
  'D C Everest Idea School',
  'D C Everest Junior High',
  'D C Everest Middle',
  'D C Everest Multi-age',
  'D D Eisenhower Middle',
  'D D Hachar El',
  'D F Walker Elementary',
  'D H Brent El',
  'D H Conley High',
  'D J Red Simon Middle',
  'D L Dusty Dickens Elementary School',
  'D L Rountree El',
  'D P Morris El',
  'D Russel Lee Career-technology Center',
  'D S Johnson Elementary',
  'D T Cox Elementary School',
  "D'arbonne Woods Charter School",
  "D'evelyn Junior/senior High School",
  "D'hanis School",
  "D'vorre & Hall Ober Elementary School",
  "D'youville-porter Campus",
  'D. D. Johnston Elementary',
  'D. H. White Elementary',
  'D. J. Sedgwick Elementary',
  'D. M. Smith Middle',
  'D. P. Cooper Charter School',
  'D. R. Hill Middle',
  'D. Russell Parks Junior High',
  'D. S. Parrott Middle School',
  'D. W. Babcock Elementary',
  'D. W. Daniel High',
  'D.A.V. MONTESSORI SCHOOL',
  'D.B.A. TUNICA ACADEMY',
  'D.a. Dorsey Technical College',
  'D.c. Reeves Elementary School',
  'D.c. Virgo Preparatory Academy',
  'D.f. Huddle Elementary',
  'D.g. Cooley Elementary',
  'D.j. Montague Elementary',
  'D.l. Perez Elementary School',
  'D.m. Smith Elementary',
  'D.p. Yates Primary',
  'DA VINCI CENTER FOR GIFTED CHILDREN',
  'DA VINCI WALDORF SCHOOL',
  'DACULA CLASSICAL ACADEMY',
  'DAKOTA ADVENTIST ACADEMY',
  'DAKOTA MEMORIAL HIGH SCHOOL',
  'DAKOTA MEMORIAL SCHOOL- BISMARCK',
  'DALE CITY CHRISTIAN CHURCH CHILD DEVT CTR & ACADEM',
  'DALET SCHOOL',
  'DALHART CHRISTIAN ACADEMY',
  'DALLAS ACADEMY',
  'DALLAS CENTER CHRISTIAN SCHOOL',
  'DALLAS CHRISTIAN SCHOOL',
  'DALLAS LUTHERAN SCHOOL',
  'DALTON LEARNING CENTER INC.',
  'DALTON SCHOOL',
  'DAMARISCOTTA MONTESSORI SCHOOL',
  'DAMASCUS CHRISTIAN SCHOOL',
  'DAMIEN HIGH SCHOOL',
  'DAMIEN MEMORIAL SCHOOL',
  'DANA MONTESSORI SCHOOL',
  'DANBURY CATHOLIC SCHOOL',
  'DANCING MOOSE MONTESSORI RIVER PARK',
  'DANCING MOOSE MONTESSORI SCHOOL',
  'DANDY LION MONTESSORI SCHOOL',
  'DANIEL ACADEMY',
  'DANIELS ACADEMY',
  'DANIELS CHRISTIAN SCHOOLS',
  'DANVILLE CHRISTIAN ACADEMY',
  'DANVILLE MONTESSORI SCHOOL',
  'DAR-UN-NOOR ATLANTA SCIENCE ACADEMY',
  'DARCHAI MENACHEM INC',
  'DARCHEI TORAH',
  'DARKEI CHAIM',
  'DARLINGTON EARLY CHILDHOOD CENTER',
  'DARLINGTON SCHOOL',
  'DARNELL SCHOOL FOR EDUCATIONAL & BEHAVIORAL SERVIC',
  'DARREN PATTERSON CHRISTIAN ACADEMY',
  'DARROW SCHOOL',
  'DARTMOOR SCHOOL',
  'DARTMOOR SCHOOLS',
  'DARTMOUTH EARLY LEARNING CENTER',
  'DARUL ARQAM SCHOOL',
  'DARUL ARQAM SOUTHWEST SCHOOL',
  'DARUL ULOOM AL MADANIA',
  'DARUL ULOOM OF OCALA FL INC.',
  'DATZYK MONTESSORI SCHOOL',
  'DAUGHTER OF ZION JUNIOR ACADEMY',
  'DAUSMAN PRAIRIE SCHOOL',
  'DAVID EMANUEL ACADEMY',
  'DAVID GREGORY SCHOOL',
  'DAVID POSNACK JEWISH DAY SCHOOL',
  "DAVID'S ARMY LEARNING CENTER",
  'DAVIDS STAR EVANGELICAL LUTHERAN SCHOOL',
  'DAVIDSON ACADEMY',
  'DAVIDSON DAY SCHOOL',
  'DAVIDSON GREEN SCHOOL',
  'DAVIS ACADEMY',
  'DAVIS WALDORF SCHOOL',
  'DAWSON CHRISTIAN ACADEMY',
  'DAY STAR MONTESSORI SCHOOL',
  'DAYBROOK SCHOOL',
  'DAYCROFT MONTESSORI SCHOOL',
  'DAYSPRING ACADEMY',
  'DAYSPRING CHRISTIAN ACADEMY',
  'DAYSPRING CHRISTIAN SCHOOL',
  'DAYSPRING PRESCHOOL & KINDERGARTEN',
  'DAYSTAR ADVENTIST ACADEMY',
  'DAYSTAR CHRISTIAN ACADEMY',
  'DAYSTAR CHRISTIAN SCHOOL',
  'DAYSTAR SCHOOL',
  'DAYTON ISLAMIC SCHOOL',
  'DAYTON MONTESSORI SOCIETY',
  'DAYTONA BEACH CHRISTIAN ACADEMY',
  'DAYTOP PREP SCHOOL',
  'DBA A BLESSED ACADEMY',
  'DBA CHRISTIAN MONTESSORI',
  'DBA CITY GARDEN WALDORF SCHOOL',
  'DBA FELICIAN CHILDRENS CENTER',
  'DBA LEADERSHIP PREPARATORY ACADEMY',
  'DBA MISSION HILLS CHRISTIAN SCHOOL',
  'DBA MOUNT DORA CHRISTIAN ACADEMY',
  'DBA/ALEXANDER PREPARATORY SCHOOL',
  'DBA: WEST CHESTNUT ACADEMY',
  'DBA:CLARKE SCHOOL BOSTON',
  "DBC CHILDREN'S CENTER",
  'DCN CHRISTIAN ACADEMY.',
  'DCT International Hotel & Business Management School',
  'DE LA SALLE ACADEMY',
  'DE LA SALLE ACADEMY - A DIVISION OF DE LA SALLE HS',
  'DE LA SALLE BLACKFEET SCHOOL',
  'DE LA SALLE COLLEGIATE HIGH SCHOOL',
  'DE LA SALLE ELEMENTARY SCHOOL',
  'DE LA SALLE HIGH SCHOOL',
  'DE LA SALLE INSTITUTE - INSTITUTE CAMPUS',
  'DE LA SALLE NORTH CATHOLIC HIGH SCHOOL',
  'DE LA SALLE SCHOOL',
  'DE LA SALLE VOCATIONAL SCHOOL',
  'DE LASALLE ACADEMY OF FORT MYERS',
  'DE MARILLAC ACADEMY',
  'DE MOTTE CHRISTIAN SCHOOL',
  'DE PAUL CATHOLIC HIGH SCHOOL',
  'DE PAUL SCHOOL FOR HEARING & SPEECH',
  'DE SALES HIGH SCHOOL',
  'DE SMET JESUIT HIGH SCHOOL',
  'DE TOLDEO HIGH SCHOOL',
  'DEAMUDE ADVENTIST CHRISTIAN SCHOOL',
  'DEARBORN ACADEMY',
  'DEARBORN HEIGHTS MONTESSORI CENTER',
  'DEARHAVEN CHILDCARE & LEARNING CENTER',
  'DEBBIE SCHOOL',
  'DECATUR ADVENTIST JR ACADEMY',
  'DECATUR FIRST UMC WEEKDAY CHILDRENS MINISTRIES',
  'DECATUR HERITAGE CHRISTIAN ACADEMY',
  "DECATUR PRESBYTERIAN CHILDREN'S COMMUNITY",
  'DECOLORES MONTESSORI SCHOOL',
  'DECROLY LEARNING CHILDCARE CENTER',
  'DEDHAM COUNTRY DAY SCHOOL',
  'DEEP CREEK HUTTERITE SCHOOL',
  'DEEPER LIFE ACADEMY',
  'DEEPER ROOT ACADEMY',
  'DEER CREEK',
  'DEER CREEK CHRISTIAN SCHOOL',
  'DEER CREEK SCHOOL',
  'DEER CROSSING AMISH SCHOOL',
  'DEER HOLLOW AMISH SCHOOL',
  'DEER HOLLOW SCHOOL',
  'DEER RUN AMISH SCHOOL',
  'DEER RUN SCHOOL',
  'DEER VIEW SCHOOL',
  'DEERFIELD',
  'DEERVIEW SCHOOL',
  'DEI Bachelor & Master Degrees',
  'DEL MAR HILLS NURSERY SCHOOL',
  'DEL MAR PINES SCHOOL',
  'DEL RIO ACADEMY',
  'DEL ROSA CHRISTIAN SCHOOL',
  'DEL SOL SCHOOL',
  'DELASALLE HIGH SCHOOL',
  'DELAT SCHOOL',
  'DELAVAN CHRISTIAN SCHOOL',
  'DELAWARE CHRISTIAN ACADEMY',
  'DELAWARE COUNTY CHRISTIAN SCHOOL',
  'DELAWARE COUNTY CHRISTIAN SCHOOL - ELEMENTARY',
  'DELAWARE TARBIYAH SCHOOL',
  'DELAWARE VALLEY FRIENDS SCHOOL',
  'DELAWARE VALLEY HIGH SCHOOL, CC',
  'DELAWARE VALLEY SCHOOL FOR EXCEPTIONAL CHILDREN',
  'DELBARTON SCHOOL',
  'DELLS CHRISTIAN ACADEMY',
  'DELMAR ARTS ACADEMY',
  'DELMARVA CHRISTIAN HIGH SCHOOL',
  'DELMARVA CHRISTIAN SCHOOLS MILTON CAMPUS',
  'DELONE CATHOLIC HIGH SCHOOL',
  'DELPHI ACADEMY',
  'DELPHI ACADEMY OF CAMPBELL',
  'DELPHI ACADEMY OF FLORIDA',
  'DELPHI ACADEMY OF LOS ANGELES',
  'DELPHIAN SCHOOL',
  'DELREY SCHOOL',
  'DELTA ACADEMY',
  'DELTA LEARNING & CHILD CARE CENTER LLC',
  'DELTA SDA SCHOOL',
  'DELTA STREETS ACADEMY',
  'DELTONA CHRISTIAN SCHOOL',
  'DEMATHA CATHOLIC HIGH SCHOOL',
  'DEMI/GOOD SHEPHERD SCHOOL & DAY CARE',
  'DENBIGH BAPTIST CHRISTIAN SCHOOL',
  'DENBIGH CHRISTIAN ACADEMY',
  'DENMART MENNONITE SCHOOL',
  'DENTON RUN AMISH PAROCHIAL SCHOOL',
  'DENVER ACADEMY OF TORAH',
  'DENVER CHRISTIAN ACADEMY',
  'DENVER CHRISTIAN SCHOOL',
  'DENVER JEWISH DAY SCHOOL',
  'DENVER STREET SCHOOL-EAST CAMPUS',
  'DENVER WALDORF SCHOOL',
  'DEPAUL COLLEGE PREP',
  'DEPAUL CRISTO REY HIGH SCHOOL',
  'DEPAUL SCHOOL',
  'DEPAUL SCHOOL OF NORTHEAST FLORIDA',
  'DEPICAM CHRISTIAN SCHOOLS',
  'DER KINDERHUIS MONTESSORI',
  'DERECH EMUNAH',
  'DERECH EMUNAH DBA SEATTLE JEWISH GIRLS HIGH SCHOOL',
  'DERECH HATORAH-ROCHESTER',
  'DES MOINES ADVENTIST SCHOOL',
  'DES MOINES CHRISTIAN SCHOOL',
  'DESALES CATHOLIC HIGH SCHOOL',
  'DESCHUTES RIVER MONTESSORI SCHOOL',
  'DESERT ACADEMY',
  'DESERT CHAPEL CHRISTIAN SCHOOL',
  'DESERT CHRISTIAN ACADEMY',
  'DESERT CHRISTIAN SCHOOL',
  'DESERT CHRISTIAN SCHOOLS',
  'DESERT GARDEN MONTESSORI',
  'DESERT MONTESSORI ACADEMY',
  'DESERT PREPARATEORY',
  'DESERT SHADOWS MONTESSORI',
  'DESERT SUN CHILD DEVELOPMENT CENTER',
  'DESERT TORAH ACADEMY',
  'DESERT VALLEY CHRISTIAN SCHOOL',
  'DESERT VIEW CHRISTIAN SCHOOL',
  'DESERT VOICES ORAL LEARNING CENTER',
  'DESHLER LUTHERAN SCHOOL',
  'DESIGN FOR LEARNING DIFFERENCES SCHOOL',
  'DESMOND T. DOSS CHRISTIAN ACADEMY',
  'DESOTO CHRISTIAN SCHOOL',
  'DESOTO PRIVATE SCHOOL',
  'DESOTO SCHOOL',
  'DESTINY ACADEMY',
  'DESTINY CHRISTIAN ACADEMY',
  'DESTINY CHRISTIAN PRESCHOOL',
  'DESTINY HIGH SCHOOL',
  'DESTINY NOW ACADEMY',
  'DETROIT CHRISTIAN SCHOOLS OF EXCELLENCE',
  'DETROIT COUNTRY DAY JUNIOR',
  'DETROIT COUNTRY DAY MIDDLE',
  'DETROIT COUNTRY DAY SCHOOL',
  'DETROIT CRISTO REY HIGH SCHOOL',
  'DETROIT WALDORF SCHOOL',
  'DEVELOPING MINDS PRESCHOOL AND LEARNING CENTER',
  'DEVELOPMENTAL DISABILITIES INSTITUTE',
  'DEVELOPMENTAL LEARNING CENTER',
  'DEVELOPMENTAL PRESCHOOL & KINDERGARTEN',
  'DEVEREUX DAY SCHOOL',
  'DEVEREUX DAY SCHOOL-CARES',
  'DEVEREUX IN NY',
  'DEVEREUX SCHOOL',
  "DEVIN A. GONZALES II ACADEMY OF THE ART'S AND SC",
  'DEVON PREPARATORY SCHOOL',
  'DEVONSHIRE MONTESSORI',
  'DIABLO VALLEY MONTESSORI SCHOOL',
  'DIAMANTE MONTESSORI SCHOOL',
  'DIAMOND BAR MONTESSORI ACADEMY',
  'DIAMOND CANYON CHRISTIAN PRESCHOOL',
  'DIAMOND ROAD PAROCHIAL SCHOOL',
  'DIAMOND VIEW C/O ALLEN COBLENTZ',
  'DIAMOND VIEW MENNONITE CHRISTIAN DAY SCHOOL',
  'DIBACCO SCHOOL',
  'DICKENS SANOMI ACADEMY',
  'DICKINSON CATHOLIC SCHOOLS',
  'DICKINSON COLLEGE KINDERGARTEN',
  'DICKSON ADVENTIST SCHOOL',
  'DILLINGHAM ADVENTIST SCHOOL',
  'DILLON CHRISTIAN SCHOOL',
  'DIMITRIOS & GEORGIA KALOIDIS PAROCHIAL SCHOOL',
  'DINUBA JR ACADEMY',
  'DIPLOMA-Fachhochschule Ölsnitz/Vogtland',
  'DISCOVER CHRISTIAN SCHOOL',
  'DISCOVER LEARNING CENTER',
  'DISCOVER MONTESSORI - LEARNING CENTER',
  'DISCOVER MONTESSORI ACADEMY',
  'DISCOVER MONTESSORI ACADEMY-PSN',
  'DISCOVERY ACADEMY',
  'DISCOVERY ACHIEVEMENT CENTER',
  'DISCOVERY CENTRAL',
  'DISCOVERY CHRISTIAN DAYSCHOOL',
  'DISCOVERY CHRISTIAN SCHOOL',
  'DISCOVERY CORNER CHILDCARE AND ENRICHMENT CENTER',
  'DISCOVERY DAY ACADEMY',
  'DISCOVERY DAYS INSTITUTE OF LEARNING',
  'DISCOVERY DEPOT',
  'DISCOVERY EXPRESS SCHOOL ELEMENTARY',
  'DISCOVERY GARDEN MONTESSORI',
  'DISCOVERY ISLE',
  'DISCOVERY ISLE - CARLSBAD',
  'DISCOVERY ISLE CHILD DEVELOPMENT CENTER',
  'DISCOVERY MONTESSORI',
  'DISCOVERY MONTESSORI ACADEMY',
  'DISCOVERY MONTESSORI ACADEMY CORP',
  'DISCOVERY MONTESSORI PRESCHOOL KINDERGARTEN',
  'DISCOVERY MONTESSORI SCHOOL',
  'DISCOVERY POINT 62',
  'DISCOVERY SCHOOL',
  'DISCOVERY SCHOOL OF VIRGINIA FOR GIRLS',
  'DISCOVERY WORLD MONTESSORI',
  'DISTINCT ABILITIES',
  'DIVINE ACADEMY',
  'DIVINE CHILD ELEMENTARY SCHOOL',
  'DIVINE CHILD HIGH SCHOOL',
  'DIVINE DESTINY SCHOOL',
  'DIVINE GRACE LUTHERAN SCHOOL',
  'DIVINE INFANT JESUS SCHOOL',
  'DIVINE MERCY CATHOLIC ACADAMY',
  'DIVINE MERCY CATHOLIC ACADEMY',
  'DIVINE MERCY CATHOLIC SCHOOL',
  'DIVINE MERCY SCHOOL',
  'DIVINE PEACE ELEMENTARY SCHOOL',
  'DIVINE PROVIDENCE ACADEMY RAVENNA',
  'DIVINE PROVIDENCE ACADEMY ST JOESPH',
  'DIVINE PROVIDENCE PRESCHOOL & KINDERGARTEN',
  'DIVINE PROVIDENCE SCHOOL',
  'DIVINE REDEEMER CATHOLIC SCHOOL',
  'DIVINE REDEEMER LUTHERAN SCHOOL',
  'DIVINE REDEEMER SCHOOL',
  'DIVINE SAVIOR ACADEMY',
  'DIVINE SAVIOR CATHOLIC SCHOOL',
  'DIVINE SAVIOR HOLY ANGELS HIGH SCHOOL',
  'DIVINE SAVIOUR SCHOOL',
  'DIVINE WISDOM CATHOLIC ACADEMY',
  'DIXON CHRISTIAN SCHOOL OF THE ARTS',
  'DIXONS TOTAL TOUCH CHILD DEVELOPMENT CENTER, INC.',
  "DJ'S COMMUNITY CHRISTIAN ACADEMY",
  'DKH ACADEMY',
  'DO RE ME CHILD DEVELOPMENT CENTER',
  'DOANE ACADEMY',
  'DOANE STUART SCHOOL',
  'DOBSON-MONTESSORI SCHOOL',
  'DOCK MENNONITE ACADEMY',
  'DOCTOR FRANKLIN PERKINS SCHOOL',
  'DODGE HOUSE SCHOOL',
  'DOGWOOD CHRISTIAN ACADEMY',
  'DOGWOOD GROVE SCHOOL',
  'DOMINICAN ACADEMY',
  'DOMINICAN HIGH SCHOOL',
  'DOMINION ACADEMY',
  'DOMINION CHRISTIAN HIGH SCHOOL',
  'DOMINION CHRISTIAN SCHOOL',
  'DON BOSCO CRISTO REY HIGH SCHOOL',
  'DON BOSCO PREPARATORY HIGH SCHOOL',
  'DONNA RO SCHOOLS',
  'DOON CHRISTIAN SCHOOL',
  'DOOR PRAIRIE ADVENTIST CHRISTIAN SCHOOL',
  'DORA FIRST BAPTIST KINDERGARTEN',
  'DORCHESTER ACADEMY',
  'DORIS TODD CHRISTIAN ACADEMY',
  'DORSONS CORNER SCHOOL',
  'DOUBLE HELIX STEAM SCHOOL',
  'DOUBLE R PRIVATE SCHOOL',
  'DOUGHTY MONTESSORI SCHOOL',
  'DOUGHTY VALLEY SCHOOL',
  'DOUGHTY VIEW C/O FREDA YODER',
  'DOUGLASVILLE SDA ELEMENTARY SCHOOL',
  'DOVE DAY SCHOOL- EDUCATIONAL PROGRAMS- INC.',
  "DOVE'S LANDING SCHOOL",
  'DOVER FIRST CHRISTIAN SCHOOL',
  'DOWLING CATHOLIC HIGH SCHOOL',
  'DOWN TO EARTH SCHOOL',
  'DOWNERS GROVE ADVENTIST SCHOOL',
  'DOWNERS GROVE CHRISTIAN SCHOOL',
  'DOWNEY CHRISTIAN SCHOOL',
  'DOWNTOWN ACADEMY',
  'DP CHRISTIAN SCHOOL',
  'DR GERTRUDE A BARBER NATIONAL INSTITUTE',
  'DREAM BIG CHRISTIAN ACADEMY K-12',
  'DREAMERS VISION ACADEMY, INC.',
  'DREAMLAND PRESCHOOL',
  'DREAMLIGHT HIGH SCHOOL',
  'DREAMY ISLAMIC ACADEMY',
  'DREW SCHOOL',
  'DREXEL NEUMANN ACADEMY',
  'DRUSHEL KNOLL C/O LEAH MAST',
  'DRY HILL PAROCHIAL SCHOOL',
  'DRYKNOB AMISH PAROCHIAL SCHOOL',
  'DT PREPARATORY ACADEMY',
  'DU BOIS CHRISTIAN SCHOOLS',
  'DUANE LAKE ACADEMY',
  'DUBLIN',
  'DUBLIN CHRISTIAN ACADEMY',
  'DUBLIN LEARNING ACADEMY',
  'DUBLIN MENNONITE SCHOOL',
  'DUBLIN MONTESSORI ACADEMY',
  'DUBLIN SCHOOL',
  'DUBNOFF CENTER FOR CHILD DEVELOPMENT',
  'DUBUQUE LUTHERAN SCHOOL',
  'DUCHESNE ACADEMY OF THE SACRED HEART',
  'DUCHESNE HIGH SCHOOL',
  'DUCKLINGS EARLY LEARNING CENTER',
  'DUKE SCHOOL',
  'DULLES MONTESSORI SCHOOL',
  'DULUTH ADVENTIST CHRISTIAN SCHOOL',
  'DULUTH MONTESSORI SCHOOL',
  'DUNCAN ACADEMY',
  'DUNDEE SOUTH SCHOOL',
  'DUNEDIN ACADEMY',
  'DUNLAP SDA SCHOOL',
  'DUNN SCHOOL',
  'DUNNAMS PRIVATE SCHOOL',
  'DUNWOODY CHRISTIAN ACADEMY',
  'DUPAGE MONTESSORI SCHOOL',
  'DUPAGE MONTESSORI SCHOOL - WHEATON',
  'DUPONT BAPTIST ACADEMY',
  'DURHAM ACADEMY',
  'DURHAM ACADEMY MIDDLE SCHOOL DIV',
  'DURHAM NATIVITY SCHOOL',
  'DURLACH PAROCHIAL SCHOOL',
  'DUSCHAM AMISH SCHOOL',
  'DUSCHEE KNOB SCHOOL',
  'DUTCH HILL PAROCHIAL SCHOOL',
  'DUTCH LANE SCHOOL',
  'DUTCHESS DAY SCHOOL',
  'DUTTON CHRISTIAN SCHOOL',
  'DWIGHT BAPTIST ACADEMY',
  'DWIGHT SCHOOL',
  'DWIGHT-ENGLEWOOD SCHOOL',
  'DYNAMIC FOUNDATIONS FOR CHILDREN',
  'DYNAMITE MONTESSORI',
  'DYNE ROAD SCHOOL',
  'Da Vinci Charter Academy',
  'Da Vinci Communications High',
  'Da Vinci Design',
  'Da Vinci Innovation Academy',
  'Da Vinci Institute 912',
  'Da Vinci Institute K8',
  'Da Vinci Middle School',
  'Da Vinci Science',
  'Da-Yeh University',
  'Dabney Elementary',
  'Dabney S Lancaster Community College',
  'Dac (dodge County Achievement Center)',
  'Dacula Elementary School',
  'Dacula High School',
  'Dacula Middle School',
  'Dacusville Elementary',
  'Dacusville Middle',
  'Dadabhoy Institute of Higher Education',
  'Dade County High School',
  'Dade Elementary School',
  'Dade Juvenile Residential Facility',
  'Dade Middle School',
  'Dadeville Elem.',
  'Dadeville Elementary School',
  'Dadeville High School',
  'Dadeville Sr. High',
  'Daebul University',
  'Daemen College',
  'Daep',
  'Daep At Holmgreen',
  'Daep- El',
  'Daffodil International University',
  'Daffodil Valley Elementary',
  'Daffron El',
  'Dag Hammarskjold Middle School',
  'Dagestan State University',
  'Daggett El',
  'Daggett Middle',
  'Daggett Montessori',
  'Daggett Preschool',
  'Dahlgren Elem School',
  'Dahlia Heights Elementary',
  'Dahlonegah Public School',
  'Dai Ichi University College of Technology',
  'Daiches El',
  'Daido Institute of Technology',
  'Daiichi College of Pharmaceutical Sciences',
  'Daiichi University of Economics',
  'Dailard Elementary',
  'Dailey Elementary School',
  'Dailey Middle',
  'Daily (allan F.) High (continuation)',
  'Daingerfield H S',
  'Daingerfield J H',
  'Dairyland Elementary',
  'Daisy Bronson Junior High School',
  'Daisy Brook Elementary School',
  'Daisy Elementary',
  'Daisy Gibson Elementary',
  'Daisy Ingraham School',
  'Daito Bunka University',
  'Dake Junior High School',
  'Dakota City Elementary School',
  'Dakota College at Bottineau',
  'Dakota County Technical College',
  'Dakota Cty. Alc',
  'Dakota Elementary School',
  'Dakota High School',
  'Dakota Hills Middle',
  'Dakota Jr Sr High School',
  'Dakota Meadows Middle School',
  'Dakota Prairie Area Learning Center',
  'Dakota Prairie Elementary - 07',
  'Dakota Prairie Elementary School',
  'Dakota Prairie High School',
  'Dakota Ridge School',
  'Dakota Ridge Senior High School',
  'Dakota State University',
  'Dakota Valley Elementary - 02',
  'Dakota Valley Elementary School',
  'Dakota Valley High School - 01',
  'Dakota Valley Learning Center',
  'Dakota Valley Middle School - 03',
  'Dakota Valley Upper Elementary - 04',
  'Dakota Wesleyan University',
  'Dalanj University',
  'Dalarna University College',
  'Dale Avenue',
  'Dale Cassens Education Complex',
  'Dale City Elementary',
  'Dale County High School',
  'Dale Es',
  'Dale Hs',
  'Dale Jackson Career Ctr',
  'Dale Junior High',
  'Dale M Thompson/trails West S',
  'Dale Ms',
  'Dale R Fair Babson Park Elementary',
  'Dale Street',
  'Dale Young Community High',
  'Daleville Elementary School',
  'Daleville High School',
  'Daleville Jr-sr High School',
  'Dalewood Middle School',
  'Daley Elem Academy',
  'Dalhart El',
  'Dalhart H S',
  'Dalhart Int',
  'Dalhart J H',
  'Dalhousie University',
  'Dalian Martime University',
  'Dalian Medical University',
  'Dalian University',
  'Dalian University of Foreign Language',
  'Dalian University of Technology',
  'Dalila Torres',
  'Dallas Baptist University',
  'Dallas Can Academy - Grant East',
  'Dallas Can Academy At Carrollton-farmers Branch',
  'Dallas Can Academy At Pleasant Grove',
  'Dallas Can Academy Charter',
  'Dallas Can Academy Charter-oak Cliff',
  'Dallas Center Elementary',
  'Dallas Center-grimes High School',
  'Dallas Center-grimes Middle School',
  'Dallas City Elem School',
  'Dallas Co School For Accelerated Lrng',
  'Dallas Community Charter',
  'Dallas County Career Technical Center',
  'Dallas County Community College',
  'Dallas County High School',
  'Dallas County Jjaep',
  'Dallas County Jjaep Cfb Campus',
  'Dallas County Jjaep Desoto Campus',
  'Dallas County Juvenile Justice',
  'Dallas County Technical Center',
  'Dallas El Sch',
  'Dallas Elementary School',
  'Dallas Environmental Science Academy',
  'Dallas F. Nicholas Sr. Elementary',
  'Dallas High School',
  'Dallas Ms',
  'Dallas Park El',
  'Dallas Ranch Middle',
  'Dallas Shs',
  'Dallastown Area Intermediate Sch',
  'Dallastown Area Ms',
  'Dallastown Area Shs',
  'Dallastown El Sch',
  'Dallesport Elementary',
  'Dalraida Elementary School',
  'Dalton El',
  'Dalton Elementary School',
  'Dalton High School',
  'Dalton Mcmichael High',
  'Dalton Middle School',
  'Dalton-nunda Intermediate School',
  'Dalton-nunda Primary School',
  'Dalton-nunda Secondary School',
  'Daly Middle School',
  'Daly School',
  'Dalzell Grade School',
  'Damanhour University',
  'Damar Charter Academy',
  'Damar Elementary School',
  'Damascus Area Sch',
  'Damascus Elementary',
  'Damascus High',
  'Damascus Middle',
  'Damascus University',
  'Dames Ferry Elementary School',
  'Damghan University of Basic Sciences',
  'Damian Abajo',
  'Damiansville Elem School',
  'Damietta University',
  'Dammam Community College',
  'Damman Elementary',
  'Damon El',
  'Damon H S',
  'Damonte Ranch High School',
  'Dan Chadwick Campus',
  'Dan D Rogers El',
  'Dan Emmett Elementary School',
  'Dan Goldfarb Elementary School',
  'Dan H Pietrini Elem School',
  'Dan Hinton Accommodation School',
  'Dan J Kubacak El',
  'Dan Jacobs',
  'Dan Mccarty Middle School',
  'Dan Mills Elementary',
  'Dan Mini Elementary',
  'Dan O. Root Elementary',
  'Dan Powell Int',
  'Dan River High',
  'Dan River Middle',
  'Dan W. Peterson',
  'Dana',
  'Dana College',
  'Dana El Ctr',
  'Dana Elementary',
  'Dana Gray Elementary',
  'Dana Hills High',
  'Dana L Lyon Middle School',
  'Dana Road Elementary',
  'Danang College Of Technology',
  'Danbrook Elementary',
  'Danbury El',
  'Danbury Elementary School',
  'Danbury H S',
  'Danbury High School',
  'Danbury Middle',
  'Danbury Middle School',
  'Danbury Special Education',
  'Danby-rush Tower Middle',
  'Dandridge Elementary',
  'Dane Barse Elementary School',
  'Danebo Elementary School',
  'Daneshestan Institute of Higher Education',
  'Danforth Elem.',
  'Danforth J H',
  'Danforth Middle School',
  'Danger Bay School',
  'Dania Elementary School',
  'Daniel Axford Elementary School',
  'Daniel B Brunton',
  'Daniel Bagley Elementary School',
  'Daniel Boone Area Hs',
  'Daniel Boone Area Ms',
  'Daniel Boone Elem.',
  'Daniel Boone Elementary School',
  'Daniel Boone High School',
  'Daniel Burnham Elem School',
  'Daniel Butler',
  'Daniel C Oakes High School--castle Rock',
  'Daniel D. Waterman School',
  'Daniel Diaz Santana (jaguas)',
  'Daniel E Morgan School',
  'Daniel F. Mahoney Middle School',
  'Daniel F. Ryan Elementary School No. 19',
  'Daniel Feeney Preschool Center',
  'Daniel Hale Williams Elem Sch',
  'Daniel Hand High School',
  'Daniel Island School',
  'Daniel J Flood El Sch',
  'Daniel J. Bakie School',
  'Daniel J. Savage Middle',
  'Daniel Jenkins Academy Of Technology Middle School',
  'Daniel K Inouye Elementary School',
  'Daniel L Joyce Middle School',
  'Daniel Lewis Middle',
  'Daniel Mckee Alternative School',
  'Daniel Morgan Intermediate School',
  'Daniel Morgan Middle School',
  'Daniel Morgan Technology Center',
  'Daniel N. Buchanan Elementary',
  'Daniel Of St. Thomas Jenifer Elementary School',
  'Daniel P Hurld',
  'Daniel Pearl Journalism & Communications Magnet',
  'Daniel Phelan Elementary',
  'Daniel Pratt Elementary School',
  'Daniel Ramirez El',
  'Daniel Serrano Rivera',
  'Daniel Singleterry Sr',
  'Daniel Street School',
  'Daniel W Merritt School',
  'Daniel Warren Elementary School',
  'Daniel Webster',
  'Daniel Webster College',
  'Daniel Webster El',
  'Daniel Webster Elem.',
  'Daniel Webster Elementary',
  'Daniel Webster Elementary School',
  'Daniel Webster Hs',
  'Daniel Webster Middle',
  'Daniel Webster Middle School',
  'Daniel Webster Ms',
  'Daniel Webster School',
  'Daniel Webster School 46',
  'Daniel Webster Secondary School',
  'Daniel Wertz Elementary School',
  'Daniel Wright Elementary School',
  'Daniel Wright Jr High School',
  'Daniel Young Elem.',
  'Daniell Middle School',
  'Danielle-joy Peterson Elem Sch',
  'Daniels Canyon School',
  'Daniels El',
  'Daniels Elementary',
  'Daniels Farm School',
  'Daniels Middle',
  'Daniels Run Elementary',
  'Daniels University Preparatory Academy',
  'Danielsville Elementary School',
  'Danish Business Academy',
  'Danish El',
  'Danish University of Education',
  'Dankook University',
  'Dannelly Elementary School',
  'Danny J. Bakewell Sr. Primary Center',
  'Danny Jones Middle',
  'Dansville Elementary School',
  'Dansville High School',
  'Dansville Middle School',
  'Dansville Primary School',
  'Dante Alighieri Montessori School',
  'Dante B. Fascell Elementary School',
  'Danube Avenue Elementary',
  'Danvers High',
  'Danville Area Community College',
  'Danville Area Ms',
  'Danville Area Shs',
  'Danville Community College',
  'Danville Community High School',
  'Danville Ctr Adolescent Female',
  'Danville Elementary School',
  'Danville High School',
  'Danville Intermediate',
  'Danville Junior-senior High School',
  'Danville Middle',
  'Danville Middle School',
  'Danville Primary Sch',
  'Danville School',
  'Danvilleneel Elementary School',
  'Danz Elementary',
  'Daphne East Elementary School',
  'Daphne Elementary School',
  'Daphne High School',
  'Daphne Middle School',
  'Dapplegray Elementary',
  'Dar Al-Uloom Colleges',
  'Dar al Hekma College',
  'Darby 7-8',
  'Darby Avenue Charter',
  'Darby Creek Elementary School',
  'Darby High School',
  'Darby School',
  'Darby Twp Sch',
  'Darby Woods Elementary School',
  'Darbydale Elementary School',
  'Darcey School',
  'Dardanelle Elementary School',
  'Dardanelle High School',
  'Dardanelle Middle School',
  'Dardanelle Primary School',
  'Darden Primary Center',
  'Dardenne Elem.',
  'Dare Elementary',
  'Dare Learning Academy',
  'Dares Salaam Institute of Technology',
  'Daretown School',
  'Darien Elementary',
  'Darien High School',
  'Dario Cassina High',
  'Darling Achievement Ctr',
  'Darlington County Institute Of Technology',
  'Darlington Elementary',
  'Darlington Elementary/middle',
  'Darlington High',
  'Darlington Middle',
  'Darlington Public School',
  'Darnaby Es',
  'Darnall Charter',
  'Darnell Cookman Middle/high School',
  'Darnestown Elementary',
  'Darrell C Swope Middle School',
  'Darrell Lynn Hines Academy',
  'Darrington Elementary School',
  'Darrington Sr High School',
  'Darrouzett Schools',
  'Dartmouth College',
  'Dartmouth El',
  'Dartmouth Elementary School',
  'Dartmouth High',
  'Dartmouth Middle',
  'Darton State College',
  'Darul Hikmah Islamic College',
  'Darul Huda Islamic University',
  'Darul Ihsan University',
  'Darul Naim College of Technology',
  'Darul Quran Islamic College University',
  'Darul Takzim Institute of Technology',
  'Darul Ulum Islamic College',
  'Darwin Elem School',
  'Darwin L Gilmore El',
  'Daspit Road Elementary School',
  'Dassa Mckinney El Sch',
  'Dassel Elementary',
  'Dassel-cokato Alternative Ctr.',
  'Dassel-cokato Middle',
  'Dassel-cokato Senior High',
  'Data House',
  'Date Elementary',
  'Dateland Elementary School',
  'Datema House',
  'Dater Montessori Elementary School',
  'Datil Elementary',
  'Daugavpils University',
  'Daugherty El',
  'Dauphin County Technical School',
  'Dauphin Island Elementary School',
  'Dauphin Junior High School',
  'Dav University',
  'Davao Doctors College',
  'Dave Thomas East',
  'Dave Thomas Education Center West',
  'Davenport A+ School',
  'Davenport Adult And Career Education Center',
  'Davenport Community Campus',
  'Davenport Elem School',
  'Davenport Elementary',
  'Davenport Elementary School',
  'Davenport Es',
  'Davenport Hs',
  'Davenport Ridge School',
  'Davenport School',
  'Davenport School Of The Arts',
  'Davenport Senior High School',
  'Davenport University',
  'Daves Avenue Elementary',
  'Daves Creek Elementary School',
  'Davey Elementary',
  'Davey Elementary School',
  'David A Ellis',
  'David A. Brown Middle',
  'David A. Dutrow Elementary',
  'David A. Harrison Elementary',
  'David A. Kaechele Elementary',
  'David A. Perdue Primary',
  'David And Lynda Olson El',
  'David Anderson Jr/sr High School',
  'David B Crane Elementary School',
  'David Bacon School',
  'David Barkley/francisco Ruiz El',
  'David Barton Elem.',
  'David Brearley Middle/high School',
  'David Brewer Elementary',
  'David C Abbott Early Learning Center',
  'David C Hinson Sr Middle School',
  'David C. Douglass Memorial School',
  'David City Elementary School',
  'David City Secondary School',
  'David Cox Road Elementary',
  'David Crockett El',
  'David Crockett Elementary',
  'David Crockett Elementary School',
  'David Crockett High School',
  'David Crockett Middle',
  'David D Jones Elementary',
  'David Daniels El Academy Of Science & Math',
  'David Douglas High School',
  'David E Norman Elementary School',
  'David E Smith El',
  'David E Williams Ms',
  'David E. Owens Middle School',
  'David El',
  'David Ellis Academy',
  'David Ellis Academy Pk',
  'David Ellis Academy West',
  'David Fairchild Elementary School',
  'David G Burnet El',
  'David G Sanchez El',
  'David G. Farragut',
  'David G. Millen Middle',
  'David Gourley School',
  'David H. Hickman High',
  'David H. Ponitz Career Technology Center',
  'David Harrison Elementary',
  'David Hicks School',
  'David J. Quinn Middle School',
  'David K Sellars El',
  'David L Rahn Jr High School',
  'David L Rainer Elementary School',
  'David L Walker Int',
  'David L. Greenberg Elementary',
  'David Lawrence Jr. K-8 Center',
  'David Leech El Sch',
  'David Louis Memorial School',
  'David Lubin Elementary',
  'David M Cox Elementary School',
  'David Mindess',
  "David O'dodd Elementary School",
  'David Paterson School',
  'David Prouty High',
  'David R. Cawley Middle School',
  'David Reese Elementary',
  'David S Crockett Early Childhood Center',
  'David Skeet Elementary',
  'David Smith Elementary School',
  'David Starr Jordan Middle',
  'David Starr Jordan Senior High',
  'David T. Denny International Middle School',
  'David T. Wilson Elementary',
  'David Thibodaux Stem Magnet Academy',
  'David Turnham Education Center',
  'David W Carter H S',
  'David W. Long Elementary',
  'David Wade Correctional Center',
  'David Williams Jr Career &tech Cnt',
  'David Wolfle Elementary',
  'David Wooster Middle School',
  'David Ybarra Middle',
  'David Youree Elementary',
  'Davidsen Middle School',
  'Davidson Charter Academy: Cfa',
  'Davidson College',
  'Davidson County Community College',
  'Davidson County High School',
  'Davidson Early College',
  'Davidson Elem.',
  'Davidson Elementary',
  'Davidson Elementary School',
  'Davidson Es',
  'Davidson Hs',
  'Davidson Magnet School',
  'Davidson Middle School',
  'Davidson River School',
  'Davidsonville Elementary',
  'Davie County Early College High',
  'Davie County High',
  'Davie Elem School',
  'Davie Elementary School',
  'Davies Career-tech High School',
  'Daviess County High School',
  'Daviess County Middle School',
  'Davila Day',
  'Davila El',
  'Davinci Academy',
  'Davinci School For Science And The Arts',
  'Davis & Elkins College',
  'Davis (caroline) Intermediate',
  'Davis 21st Century Magnet Elementary School',
  'Davis Aerospace & Maritime High School',
  'Davis Aerospace High School',
  'Davis Bilingual Magnet School',
  'Davis Career Center',
  'Davis Center',
  'Davis College',
  'Davis County Community High School',
  'Davis County Elementary',
  'Davis County Middle School',
  'Davis Creek Elementary School',
  'Davis Drive Elementary',
  'Davis Drive Middle',
  'Davis El',
  'Davis El Sch',
  'Davis Elem.',
  'Davis Elementary',
  'Davis Elementary School',
  'Davis Es',
  'Davis H S Aldine',
  'Davis H. Hart Career Ctr.',
  'Davis High',
  'Davis High School',
  'Davis Hill Elementary',
  'Davis Hs',
  'Davis Int',
  'Davis Junior High School',
  'Davis M Magnet Elem School',
  'Davis Magnet School',
  'Davis Middle',
  'Davis Middle School',
  'Davis Ms',
  'Davis N Elem School',
  'Davis Ninth Grade',
  'Davis Park Elementary School',
  'Davis Primary School',
  'Davis Sch',
  'Davis School',
  'Davis School For Independent Study',
  'Davis Senior High',
  'Davis Special Education Pre-school',
  'Davis Thayer',
  'Davis Thomas Elementary Middle School',
  'Davis-ramoth School',
  'Davis-stuart School',
  'Davis-townsend Elementary',
  'Davisburg Elementary School',
  'Davisemerson Middle School',
  'Davison Alternative Education',
  'Davison Avenue Intermediate School',
  'Davison Elementarymiddle School',
  'Davison High School',
  'Davison Middle School',
  'Davisville Middle School',
  'Daw Elementary School',
  'Dawat University',
  'Dawes Avenue Elementary School',
  'Dawes Elem School',
  'Dawes Elementary School',
  'Dawes Intermediate School',
  'Dawes Middle School',
  'Dawn F Barnes Elementary Sch',
  'Dawnville Elementary School',
  'Dawnwood Middle School',
  'Dawood College of Engineering and Technology',
  'Dawson',
  'Dawson Colony School - 20',
  'Dawson Community College',
  'Dawson County High School',
  'Dawson County Junior High',
  'Dawson County Middle School',
  'Dawson Daep',
  'Dawson El',
  'Dawson Elementary School',
  'Dawson H S',
  'Dawson High School',
  'Dawson Orman Education Center',
  'Dawson School',
  'Dawson Springs Elementary',
  'Dawson Springs Jr/sr High School',
  'Dawson Springs Pre-school',
  'Dawson-boyd Secondary',
  'Dawson-bryant Elementary School',
  'Dawson-bryant High School',
  'Dawson-bryant Middle School',
  'Day Anna B Sch',
  'Day Creek Intermediate',
  'Day Elementary',
  'Day Head Start',
  'Day Nursery Of Abilene',
  'Day Reporting School',
  'Day Treatment',
  'Day Treatment Elementary',
  'Day Treatment Program',
  'Dayalbagh Educational Institute',
  'Daybreak Alternative School',
  'Daybreak Middle',
  'Daybreak Primary',
  'Daybreak School',
  'Daybrook Early Headstart Center(clsd 97)',
  'Daylight/twilight High School',
  'Daylor (william) High (continuation)',
  'Days Creek Charter School',
  'Dayspring Academy',
  'Daystar University',
  'Dayton Avenue School',
  'Dayton Boys Preparatory Academy',
  'Dayton Business Technology High School',
  'Dayton Center',
  'Dayton City Elementary',
  'Dayton Consolidated School',
  'Dayton Early College Academy Inc',
  'Dayton El Sch',
  'Dayton Elementary',
  'Dayton Elementary School',
  'Dayton Grade School',
  'Dayton H S',
  'Dayton Heights Elementary',
  'Dayton High School',
  'Dayton Intermediate School',
  'Dayton Jr High School',
  'Dayton Leadership Academies-dayton View Campus',
  'Dayton Leadership Academies-early Learning Academy',
  'Dayton Middle School',
  'Dayton Oaks',
  'Dayton Regional Stem School',
  'Dayton School',
  'Dayton Smart Elementary School',
  'Daytona Juvenile Residential Facility',
  'Daytona Middle School',
  'Daytona State College',
  'Daytons Bluff Elementary',
  'Dayville School',
  'Db Youth Services',
  'Dc Bilingual Pcs',
  'Dc Montessori Charter School',
  'Dc Preparatory Academy Pcs Anacostia Elementary',
  'Dc Preparatory Academy Pcs Benning Elementary',
  'Dc Preparatory Academy Pcs Benning Middle',
  'Dc Preparatory Academy Pcs Edgewood Elementary',
  'Dc Preparatory Academy Pcs Edgewood Middle',
  'Dc Scholars Pcs',
  'Dc Wolfe School',
  'Dc-g Meadows',
  'Dcis At Ford',
  'Dcis At Montbello',
  'Dcyf Alternative Ed. Program',
  'Dd Eisenhower High Sch (campus)',
  'Ddp - Simpson-waverly School',
  'De Anza Academy Of Technology And The Arts',
  'De Anza College',
  'De Anza Elementary',
  'De Anza High',
  'De Anza Magnet',
  'De Anza Middle',
  'De Armanville Elementary School',
  'De Beque Elementary School',
  'De Beque Pk-12 School District 49jt',
  'De Chaumes El',
  'De Diego Elem Community Academy',
  'De Forest High',
  'De Forest Middle',
  'De Haagse Hogeschool',
  'De Kalb County High School',
  'De La Salle University',
  'De La Salle University Araneta',
  'De La Vina Elem',
  'De Laveaga Elementary',
  'De Leon El',
  'De Leon H S',
  'De Leon Middle',
  'De Montfort University Leicester',
  'De Pere High',
  'De Pere Middle',
  'De Portola Elementary',
  'De Portola Middle',
  'De Santiago Ec/pre-k Center',
  'De Smet High School - 01',
  'De Smet Middle School - 03',
  'De Soto High',
  'De Soto High School',
  'De Soto Middle',
  'De Soto Virtual School',
  'De Vargas Middle',
  'De Zavala El',
  'De Zavala Middle School',
  'DePaul University',
  'DePauw University',
  'DeVry Institute of Technology',
  'Deady Middle',
  'Deaf Elementary School (fsdb)',
  'Deaf High School (fsdb)',
  'Deaf Middle School (fsdb)',
  'Deaf/hard Of Hearing',
  'Deaf/hard Of Hearing (dist 214)',
  'Deaf/hard Of Hearing (dist 23)',
  'Deaf/hard Of Hearing (dist 62)',
  'Deaf/hard Of Hearing/mn Prgrms',
  'Deakin University',
  'Deal Elementary School',
  'Deal Island School',
  'Deal Ms',
  'Deale Elementary',
  'Dean A Naldrett School',
  'Dean And Mildred Bennett El',
  'Dean Highland El',
  'Dean Jennings Educational Center',
  'Dean L. Shively',
  'Dean Lamar Allen Elementary School',
  'Dean Leaman J H School',
  'Dean Middle',
  'Dean Morgan Middle School',
  'Dean Petersen Elementary School',
  'Dean Road Elementary School',
  'Dean Rusk Middle School',
  'Dean S Luce',
  'Dean Street Elem School',
  'Deanda El',
  'Deane Bozeman School',
  'Deane Elementary School',
  'Deane-porter Elementary School',
  'Deanna Davenport El',
  'Deans Mill School',
  'Dear Elem.',
  'Dearborn',
  'Dearborn Academic Center Of Excellence',
  'Dearborn Elementary Charter Academy',
  'Dearborn Heights Virtual Academy',
  'Dearborn High School',
  'Dearborn Magnet High School',
  'Dearborn Park International School',
  'Dearborn Stem Middle School',
  'Dearing El',
  'Dearing Elementary School',
  'Dearington Elementary/innovation',
  'Deary School',
  'Deasy School',
  'Death Valley Elementary',
  'Death Valley High Academy',
  'Deauville Gardens East Elementary School',
  'Deauville Gardens West Elementary School',
  'Debakey H S For Health Prof',
  'Debary Elementary School',
  'Deborah A. Williams Elementary',
  'Deborah Brown Community School',
  'Debre Birhan University',
  'Debre Markos University',
  'Debrecen University of Agricultural Sciences',
  'Deburgos Bilingual Magnet Ms',
  'Debusk Elementary',
  'Deca Prep',
  'Decatur Avenue Early Childhood Center',
  'Decatur Central High School',
  'Decatur Classical Elem School',
  'Decatur Community Jr/sr High',
  'Decatur County Middle School',
  'Decatur Elementary',
  'Decatur Elementary School',
  'Decatur H S',
  'Decatur High Developmental',
  'Decatur High School',
  'Decatur Middle School',
  'Decatur Northside Elementary',
  'Decatur Stephen Sch',
  'Decatur Twp School For Excellence',
  'Decaturville Elementary',
  'Deccan College Postgraduate and Research Institute',
  'Decherd Elementary',
  'Decker El',
  'Decker Elementary',
  'Decker Middle',
  'Decker Prairie El',
  'Deckerville Community High School',
  'Deckerville Elementary School',
  'Declo Elementary School',
  'Declo Jr High School',
  'Declo Senior High School',
  'Decorah Elementary',
  'Decorah High School',
  'Decorah Middle School',
  'Decoto School For Independent Study',
  'Dedan Kimathi University of Technology',
  'Dedham High',
  'Dedham Middle School',
  'Dedham School',
  'Dee-mack High School',
  'Dee-mack Intermediate School',
  'Dee-mack Primary/jr High',
  'Deen Dayal Upadhyay Gorakhpur University',
  'Deep Branch Elementary',
  'Deep Creek - Damascus K-8 School',
  'Deep Creek Academy',
  'Deep Creek Central Elementary',
  'Deep Creek Elementary',
  'Deep Creek Elementary School',
  'Deep Creek High',
  'Deep Creek Middle',
  'Deep River Elementary',
  'Deep River Elementary School',
  'Deep Run Elementary',
  'Deep Run High',
  'Deep Springs Elementary School',
  'Deephaven Education Center',
  'Deephaven Elementary',
  'Deepwater Elementary',
  'Deepwater J H',
  'Deepwood El',
  'Deer Canyon Elementary',
  'Deer Chase Elementary School',
  'Deer Creek El',
  'Deer Creek Elementary',
  'Deer Creek Elementary School',
  'Deer Creek Es',
  'Deer Creek Hs',
  'Deer Creek Intermediate',
  'Deer Creek Intermediate School',
  'Deer Creek Middle School',
  'Deer Creek Ms',
  'Deer Creek School',
  'Deer Creek-lamont Es',
  'Deer Creek-lamont Hs',
  'Deer Crossing Elementary',
  'Deer Hill',
  'Deer Isle-stonington Elementary Sch',
  'Deer Isle-stonington High Sch',
  'Deer K-12 School',
  'Deer Lakes Hs',
  'Deer Lakes Ms',
  'Deer Meadow Primary School',
  'Deer Park 7-8',
  'Deer Park C C Elem School',
  'Deer Park Early Learning Center',
  'Deer Park Elementary',
  'Deer Park Elementary School',
  'Deer Park High School',
  'Deer Park Home Link Program',
  'Deer Park Hs',
  'Deer Park J H',
  'Deer Park Jr/sr High School',
  'Deer Park Middle',
  'Deer Park Middle Magnet School',
  'Deer Park Middle School',
  'Deer Park School',
  'Deer Path Elem School',
  'Deer Path Middle Sch East',
  'Deer Path Middle Sch West',
  'Deer Point Elementary School',
  'Deer Ridge Elementary',
  'Deer River High School Alp',
  'Deer River Schools Sped',
  'Deer River Secondary',
  'Deer Run Elementary',
  'Deer Run Elementary School',
  'Deer Run School',
  'Deer Trail Elementary School',
  'Deer Trail Junior-senior High School',
  'Deer Valley Academy',
  'Deer Valley Elementary School',
  'Deer Valley High',
  'Deer Valley High School',
  'Deer Valley Middle School',
  'Deer Valley Online Learning Program',
  'Deerfield Beach Elementary School',
  'Deerfield Beach High School',
  'Deerfield Beach Middle School',
  'Deerfield Colony Elementary - 05',
  'Deerfield Community School',
  'Deerfield Elem',
  'Deerfield Elementary',
  'Deerfield Elementary School',
  'Deerfield High',
  'Deerfield High School',
  'Deerfield Middle',
  'Deerfield Middle School',
  'Deerfield Park Elementary School',
  'Deerfield Run Elementary',
  'Deerfield School',
  'Deerfield Township School',
  'Deering High School',
  'Deering School',
  'Deerlake Middle School',
  'Deerpark Middle',
  'Deerwood Academy School',
  'Deerwood El',
  'Deerwood Elementary',
  'Deerwood Elementary School',
  'Defeated Elementary',
  'Defense Language Institute Foreign Language Center',
  'Defiance Elementary School',
  'Defiance Gr Sch',
  'Defiance High School',
  'Defiance Middle School',
  'Defranco El Sch',
  'Degan El',
  'Degrazia Elementary School',
  'Dehesa Charter',
  'Dehesa Elementary',
  'Dehryl A Dennis Prof-tech Center',
  'Deibler El Sch',
  'Dekalb Academy Of Technology And The Environment Charter Sc',
  'Dekalb Alternative School',
  'Dekalb Annex School',
  'Dekalb Early College Academy',
  'Dekalb Early Lrng And Dev Ctr',
  'Dekalb El',
  'Dekalb Elementary School Of The Arts',
  'Dekalb H S',
  'Dekalb High School',
  'Dekalb Jr.-sr. High',
  'Dekalb Middle',
  'Dekalb Middle School',
  'Dekalb Path Academy Charter School',
  'Dekalb Preparatory Academy Charter',
  'Dekalb Regional Youth Detention Center',
  'Dekalb School Of The Arts',
  'Dekalb Technical Center',
  'Dekalb West Elementary',
  'Dekeyser Elementary School',
  'Del Amigo High (continuation)',
  'Del Amo Elementary',
  'Del Campo High',
  'Del Castillo El',
  'Del Cerro Elementary',
  'Del City Es',
  'Del City Hs',
  'Del Crest Ms',
  'Del Dayo Elementary',
  'Del Dios Academy Of Arts And Sciences',
  'Del E Webb Middle School',
  'Del Easton Alternative High School',
  'Del Lago Academy - Campus Of Applied Science',
  'Del Lago Elementary',
  'Del Mar College',
  'Del Mar Elementary',
  'Del Mar Heights Elementary',
  'Del Mar High',
  'Del Mar Hills Elementary',
  'Del Mar Middle',
  'Del Norte Community',
  'Del Norte County Rop',
  'Del Norte Elementary',
  'Del Norte Heights El',
  'Del Norte High',
  'Del Norte High School',
  'Del Norte Schools K-8',
  'Del Obispo Elementary',
  'Del Oro High',
  'Del Paso Heights Elementary',
  'Del Paso Manor Elementary',
  'Del Prado Elementary School',
  'Del Puerto High',
  'Del Rey Elementary',
  'Del Rey Woods Elementary',
  'Del Rio Early College H S',
  'Del Rio Elementary',
  'Del Rio Elementary School',
  'Del Rio H S',
  'Del Rio Middle',
  'Del Roble Elementary',
  'Del Rosa Elementary',
  'Del Sol Academy Of The Performing Arts',
  'Del Sur Elementary',
  'Del Sur Senior Elementary',
  'Del Valle Continuation High',
  'Del Valle El',
  'Del Valle Elementary',
  'Del Valle H S',
  'Del Valle Middle',
  'Del Valle Opportunity Ctr',
  'Del Vallejo Middle',
  'Del Vista Math And Science Academy',
  'Delacey Family Educ Ctr',
  'Delahunty Ms',
  'Delaine Eastin Elementary',
  'Delair Elementary School',
  'Deland High School',
  'Deland Middle School',
  'Deland-weldon Elem School',
  'Deland-weldon High School',
  'Deland-weldon Middle School',
  'Delaney',
  'Delano Elementary',
  'Delano High',
  'Delano Middle',
  'Delano Senior High',
  'Delasalle Charter School',
  'Delaura Middle School',
  'Delavan Elementary School',
  'Delavan High School',
  'Delavan Jr High School',
  'Delavan-darien High',
  'Delaware Academy',
  'Delaware Academy Elementary School',
  'Delaware Academy High School',
  'Delaware Academy Of Public Safety And Security',
  'Delaware Area Career Center North Campus',
  'Delaware Area Career Center South Campus',
  'Delaware College Preparatory Academy',
  'Delaware College of Art and Design',
  'Delaware Community School',
  'Delaware County Community College',
  'Delaware County Technical High School-as',
  'Delaware County Technical High School-fo',
  'Delaware County Technical High School-ma',
  'Delaware Design-lab High School',
  'Delaware Early Childhood Center',
  'Delaware Elem.',
  'Delaware Elementary',
  'Delaware Elementary School',
  'Delaware Military Academy',
  'Delaware Primary School',
  'Delaware Ridge Elementary',
  'Delaware School For The Deaf',
  'Delaware State University',
  'Delaware Technical Community College-​Owens',
  'Delaware Technical Community College-​Stanton/​Wilmington',
  'Delaware Technical Community College-​Terry',
  'Delaware Township School',
  'Delaware Trail Elementary School',
  'Delaware Valley Chs',
  'Delaware Valley El Sch',
  'Delaware Valley Hs',
  'Delaware Valley Ms',
  'Delaware Valley Regional High School',
  'Delaware-chenango-madison-otsego Boces',
  'Delay Middle',
  'Delbert Pete & Pat Allen Ele',
  'Delcambre Elementary School',
  'Delcambre High School',
  'Delcastle Technical High School',
  'Delco Pri',
  'Delcroft Sch',
  'Delevan Drive Elementary',
  'Delevan Elementary School',
  'Delft University of Technology',
  'Delgado Community College',
  'Delhi Charter School',
  'Delhi College of Engineering (DCE)',
  'Delhi Elementary School',
  'Delhi High',
  'Delhi High School',
  'Delhi Middle',
  'Delhi Middle School',
  'Delia Davila De Caban',
  'Delia Sanchez Full Service School',
  'Delight Elementary School',
  'Delijan Payame Noor University',
  'Delisle Elementary School',
  'Delk-henson Intermediate School',
  "Dell Children's Medical Center",
  'Dell City School',
  'Dell H Robison Middle School',
  'Dell Pickett El',
  'Dell Rapids Elementary - 02',
  'Dell Rapids High School - 01',
  'Dell Rapids Middle School - 03',
  'Della Davidson Elementary',
  'Della Icenhower Int',
  'Della Lamb @ Woodland',
  'Della S. Lindley Elementary',
  'Dellroy Elementary School',
  'Dellview El',
  'Dellwood Early Learning Center',
  'Delmae Elementary',
  'Delmar A Cobble School',
  'Delmar Elementary',
  'Delmar High School',
  'Delmar Middle Level Ilc',
  'Delmar Middle School',
  'Delmina Woods Youth Facility',
  'Delmont Pre-k And Kindergarten Center',
  'Delong',
  'Delong Middle',
  'Deloras E Thompson El',
  'Delores Moye Elem School',
  'Delphi Community Elementary School',
  'Delphi Community High School',
  'Delphi Community Middle School',
  'Delphic Elementary',
  'Delran High School',
  'Delran Intermediate School',
  'Delran Middle School',
  'Delray Full Service Adult Education',
  'Delsea Regional High School',
  'Delsea Regional Middle School',
  'Delshire Elementary School',
  'Delta Academy',
  'Delta Academy Of Applied Learning',
  'Delta Bridges Charter',
  'Delta C-7 Elem.',
  'Delta C-7 High',
  'Delta Center Elementary School',
  'Delta Charter',
  'Delta Charter Online',
  'Delta Charter Online No.2',
  'Delta Charter School Mst',
  'Delta College',
  'Delta Elem.',
  'Delta Elementary Charter',
  'Delta Elementary School',
  'Delta High',
  'Delta High School',
  'Delta Home Charter',
  'Delta Innovative School',
  'Delta Jr. High School',
  'Delta Junction Elementary',
  'Delta Junction High School',
  'Delta Junction Junior High School',
  'Delta Kelly Elementary School',
  'Delta Keys Charter',
  'Delta Keys Charter 2',
  'Delta Launch Charter',
  'Delta Learning Center',
  'Delta Middle',
  'Delta Middle School',
  'Delta Mills Early Childhood Center',
  'Delta North School',
  'Delta Sierra Middle',
  'Delta South School',
  'Delta State University',
  'Delta View Elementary',
  'Delta Vista Middle',
  'Delta Woods Middle',
  'Delta-peach Bottom El Sch',
  'Delta/greely Homeschool',
  'Deltaschoolcraft Area Center',
  'Deltaschoolcraft Manistique Area Satellite Center',
  'Delton Kellogg Elem School',
  'Deltona Elementary School',
  'Deltona High School',
  'Deltona Lakes Elementary School',
  'Deltona Middle School',
  'Deltonkellogg High School',
  'Deltonkellogg Middle School',
  'Delwood Elementary School',
  'Demarest Elementary',
  'Demarest Middle School',
  'Demille Elementary',
  'Deming Cesar Chavez',
  'Deming Elementary',
  'Deming Elementary School',
  'Deming High',
  'Deming Intermediate',
  'Demmitt Elementary School',
  'Democracy Prep At Agassi Elementary',
  'Democracy Prep At Agassi High',
  'Democracy Prep At Agassi Middle',
  'Democracy Prep Congress Heights Pcs',
  'Democracy Prep Endurance Charter School',
  'Democracy Prep Harlem Charter School',
  'Democracy Prep Louisiana Charter School',
  'Democracy Preparatory Charter School',
  'Demoney Elementary',
  'Demopolis High School',
  'Demopolis Middle School',
  'Demores Elementary School',
  'Demorest Elementary School',
  'Demotte Elementary School',
  'Dena Boer',
  'Dena Kelso Graves El',
  'Dena?ina Elementary School',
  'Denair Charter Academy',
  'Denair Elementary',
  'Denair Elementary Charter Academy',
  'Denair High',
  'Denair Middle',
  'Denali Elementary',
  'Denali Montessori Elementary',
  'Denali Peak',
  'Denbigh Early Childhood Center',
  'Denbigh High',
  'Denby High School',
  'Deneen Elem School',
  'Denfeld High School',
  'Denham Oaks Elementary School',
  'Denham Springs Elementary School',
  'Denham Springs Freshman High School',
  'Denham Springs High School',
  'Denham Springs Junior High School',
  'Denhamtown Pre-k Center',
  'Denio Elementary School',
  'Denison',
  'Denison Alternative High School',
  'Denison Elementary School',
  'Denison H S',
  'Denison High School',
  'Denison Middle School',
  'Denison Montessori School',
  'Denison Public School',
  'Denison University',
  'Denker Avenue Elementary',
  'Denkmann Elem School',
  'Denman (james) Middle',
  'Denman Junior High School',
  'Denmark Community School',
  'Denmark Early Childhood Ctr',
  'Denmark Elementary',
  'Denmark Elementary School',
  'Denmark High',
  'Denmark Middle',
  'Denmark Olar High',
  'Denmark Technical College',
  'Denmark-olar Elementary',
  'Denmark-olar Middle',
  'Denn John Middle School',
  'Dennehotso Boarding School',
  'Dennett Elementary',
  'Denning Elementary School',
  'Dennis',
  "Dennis B O'brien Elementary School",
  'Dennis C Haley',
  'Dennis Chavez Elementary',
  'Dennis Early Childhood Center',
  'Dennis Elem School',
  'Dennis Es',
  'Dennis G. Earl Elementary',
  'Dennis Intermediate School',
  'Dennis Township Primary School',
  'Dennis Twp Elementary/middle School',
  'Dennis-yarmouth Regional High',
  'Dennison Elementary School',
  'Dent Elementary',
  'Dent Middle',
  'Dent-phelps Elem.',
  'Denton 7-8',
  'Denton Avenue School',
  'Denton Classical Academy',
  'Denton Co J J A E P',
  'Denton County Jjaep',
  'Denton County Jjaep Cfb Campus',
  'Denton Creek',
  'Denton Creek El',
  'Denton Elementary',
  'Denton Elementary School',
  'Denton H S',
  'Denton High School',
  'Denton Magnet School Of Technology',
  'Denton School',
  'Dentzler Elementary School',
  'Denver Center For 21st Learning At Wyman',
  'Denver Center For International Studies',
  'Denver Center For International Studies At Fairmon',
  'Denver City H S',
  'Denver Ctr',
  'Denver Discovery School',
  'Denver Early Elementary School',
  'Denver El Sch',
  'Denver Green School',
  'Denver Justice High School',
  'Denver Language School',
  'Denver Middle School',
  'Denver Montessori Junior/senior High School',
  'Denver Online High School',
  'Denver Place Elementary School',
  'Denver School Of Innovation And Sustainable Design',
  'Denver School Of The Arts',
  'Denver Senior High School',
  'Department Of Corrections',
  'Department Of Human Resources',
  'Depelchin-richmond',
  'Depew Es',
  'Depew High School',
  'Depew Hs',
  'Depew Middle School',
  'Deport El',
  'Deposit Elementary School',
  'Deposit Middle-senior High School',
  'Depriest Elem School',
  'Dept Of Correction Adult Schools',
  'Dept. Of Corrections Educational Program',
  'Deptford Township High School',
  'Depue Elem School',
  'Depue High School',
  'Deputy Elementary School',
  'Dequeen El',
  'Dequeen Elementary School',
  'Dequeen High School',
  'Dequeen Junior High School',
  'Dequeen Middle School',
  'Dequeen Primary',
  'Dequeen/mena Area Voc Center',
  'Dequincy Elementary School',
  'Dequincy High School',
  'Dequincy Middle School',
  'Dequincy Primary School',
  'Derby Elementary School',
  'Derby High School',
  'Derby Hills Elem',
  'Derby Middle Sch',
  'Derby Middle School',
  'Derby North Middle School',
  'Derby Ridge Elem.',
  'Derenne Middle School',
  'Deretchin El',
  'Deridder High School',
  'Deridder Junior High School',
  'Dermott Elementary School',
  'Dermott High School',
  'Dermott Jtc School',
  'Derry Area Ms',
  'Derry Area Shs',
  'Derry Early Education Program',
  'Derry El',
  'Derry Village School',
  'Deruyter Elementary School',
  'Deruyter High School',
  'Derynoski Elementary School',
  'Des Arc Elementary School',
  'Des Arc High School',
  'Des Lacs-burlington High School',
  'Des Moines Area Community College',
  'Des Moines Elementary',
  'Des Moines High',
  'Desana Middle School',
  'Descanso Elementary',
  'Desert Arroyo Middle School',
  'Desert Canyon Elementary',
  'Desert Canyon Middle School',
  'Desert Cove Elementary School',
  'Desert Edge High School',
  'Desert Elementary',
  'Desert Foothills Middle School',
  'Desert Garden Elementary',
  'Desert Garden Elementary School',
  'Desert Harbor Elementary School',
  'Desert Heights Charter School',
  'Desert Heights Elementary School',
  'Desert Heights Preparatory Academy',
  'Desert Hills El',
  'Desert Hills Elementary',
  'Desert Hills High',
  'Desert Hills High School',
  'Desert Hills Middle',
  'Desert Hills Middle School',
  'Desert Horizon Elementary School',
  'Desert Hot Springs High',
  'Desert Junior-senior High',
  'Desert Knolls Elementary',
  'Desert Learning Academy',
  'Desert Marigold School',
  'Desert Meadows Elementary School',
  'Desert Mesa Elementary School',
  'Desert Middle School',
  'Desert Mirage Elementary School',
  'Desert Mirage High',
  'Desert Mirage Preparatory Academy',
  'Desert Mountain Community',
  'Desert Mountain Elementary',
  'Desert Mountain High School',
  'Desert Mountain School',
  'Desert Oasis Elementary School',
  'Desert Oasis High (continuation)',
  'Desert Oasis High School',
  'Desert Palms Elementary School',
  'Desert Pines High School',
  'Desert Pointe Academy',
  'Desert Ridge Academy',
  'Desert Ridge High',
  'Desert Ridge Jr. High',
  'Desert Ridge Middle',
  'Desert Rose Academy Charter School',
  'Desert Rose Elementary',
  'Desert Rose High School',
  'Desert Sage Elementary School',
  'Desert Sands Charter',
  'Desert Sands Middle School',
  'Desert Shadows Elementary School',
  'Desert Shadows Middle School',
  'Desert Sky Community School',
  'Desert Sky Middle School',
  'Desert Sky Montessori',
  'Desert Sonora Elementary School',
  'Desert Spirit',
  'Desert Springs Academy',
  'Desert Springs Elementary School',
  'Desert Springs Middle',
  'Desert Springs Preparatory Elementary School',
  'Desert Star',
  'Desert Star Academy',
  'Desert Star Community School',
  'Desert Star Elementary',
  'Desert Sun Academy',
  'Desert Thunder',
  'Desert Trail Elementary',
  'Desert Trails Elementary School',
  'Desert Trails Preparatory Academy',
  'Desert Valley Elementary School',
  'Desert Valley High (continuation)',
  'Desert Valley School',
  'Desert Valleys Region',
  'Desert View Academy',
  'Desert View Community Day',
  'Desert View Elementary',
  'Desert View Elementary Intermediate',
  'Desert View Elementary School',
  'Desert View High School',
  'Desert View Middle',
  'Desert Vista Elementary School',
  'Desert Vista High School',
  'Desert Willow Elementary',
  'Desert Willow Elementary School',
  'Desert Willow Family School',
  'Desert Willow Middle',
  'Desert Willow Secondary',
  'Desert Wind El',
  'Desert Wind Middle School',
  'Desert Winds Continuation High',
  'Desert Winds Elementary School',
  'Desertaire El',
  'Deshler Alternative School',
  'Deshler Career Technical Center',
  'Deshler Elementary School',
  'Deshler High School',
  'Deshler Middle School',
  'Desiderio Mendez Rodriguez (maizales)',
  'Design & Architecture Senior High',
  'Design 39 Campus',
  'Design Lab @ Health Careers',
  'Design Science Middle College High',
  'Design Tech High',
  'Desmet 7-8',
  'Desmet School',
  'Desoto Alternative Education Center (daep)',
  'Desoto Alternative Program',
  'Desoto Central Elementary School',
  'Desoto Central High School',
  'Desoto Central Middle School',
  'Desoto Central Primary',
  'Desoto Co Career Center East',
  'Desoto Co Voc Complex',
  'Desoto County Adult Education Center',
  'Desoto County Alternative Center',
  'Desoto County High School',
  'Desoto East Middle (isteam)',
  'Desoto Elementary School',
  'Desoto H S & Isteam3d',
  'Desoto Intermediate School',
  'Desoto Jr. High',
  'Desoto Middle School',
  'Desoto Sr. High',
  'Desoto Trail Elementary School',
  'Desoto Virtual Franchise',
  'Desoto Virtual Instruction Program',
  'Desoto West Middle (isteam)',
  'Dessau El',
  'Dessau Middle',
  'Dessie Scott School',
  'Destin Elementary School',
  'Destin Middle School',
  'Destinations Academy',
  'Destinations Career Academy Of Colorado',
  'Destinations Career Academy Of Wisconsin High',
  'Destiny Achievers Academy Of Excellence',
  'Destiny Middle School',
  'Destiny School',
  'Destrehan High School',
  'Detent Ctr',
  'Detention Center',
  'Detention Center West',
  'Detention Facility Services',
  'Detour Arts And Technology Academy',
  'Detour High School',
  'Detroit Academy Of Arts And Sciences',
  'Detroit Academy Of Arts And Sciences Middle School',
  'Detroit Achievement Academy',
  'Detroit City West Side Academy For Leadership Development',
  'Detroit Collegiate High School',
  'Detroit Collegiate Preparatory High School Northwestern',
  'Detroit Community Schools Elementary',
  'Detroit Community Schools High School',
  'Detroit Delta Preparatory Academy For Social Justice',
  'Detroit Edison Public School Academy',
  'Detroit Edison Public School Academy High School',
  'Detroit El',
  'Detroit Enterprise Academy',
  'Detroit H S',
  'Detroit Innovation Academy',
  'Detroit Institute Of Technology At Cody',
  'Detroit International Academy For Young Women',
  'Detroit J H',
  'Detroit Lakes Alternative Prog.',
  'Detroit Lakes Area Learning Center',
  'Detroit Lakes Middle',
  'Detroit Lakes Middle Alc',
  'Detroit Lakes Senior High',
  'Detroit Leadership Academy Elementary',
  'Detroit Leadership Academy Middlehigh',
  'Detroit Merit Charter Academy',
  'Detroit Premier Academy',
  'Detroit Prep',
  'Detroit Public Safety Academy',
  'Detroit School Of Arts',
  'Detroit Service Learning Academy Campus',
  'Dett Elem School',
  'Deubrook Elementary - 03',
  'Deubrook High School - 01',
  'Deubrook Jr. High - 02',
  'Deuel High School - 01',
  'Deutsch-Ordens Fachhochschule Riedlingen Hochschule für Wirtschaft',
  'Deutsche Hochschule für Verwaltungswissenschaften Speyer',
  'Deutsche Sporthochschule Köln',
  'Deutsche Telekom Fachhochschule Leipzig',
  'Devall Middle School',
  'Deveaux Elementary School',
  'Developmental Classrooms',
  'Developmental Ctr',
  'Developmental Disabilities Program - 01',
  'Developmental Learning Center',
  'Developmental Learning Center (dlc)',
  'Developmental Learning Center New Providence',
  'Developmental Learning Center Warren',
  'Developmental Learning Pgm Dlp',
  'Developmental Pre-school',
  'Developmental Preschool',
  'Devens School',
  'Dever Elem School',
  'Devereux Ackerman Academy',
  'Devereux Hospital',
  'Devereux Treatment Program',
  'Devers Aep',
  'Devers El',
  'Devers Jh',
  'Devers Sch',
  'Devi Ahilya University of Indore',
  'Devils Lake High School',
  'Devine Daep School',
  'Devine H S',
  'Devine Intermediate School',
  'Devine Middle',
  'Devinny Elementary School',
  'Devon Aire K-8 Center',
  'Devon El Sch',
  'Devon Forest Elementary',
  'Devonian El',
  'Devonshire Alternative Elementary School',
  'Devonshire Elem School',
  'Devonshire Elementary',
  'Devonshire School',
  'Devry Advantage Academy High Schl',
  'Dew El',
  'Dewar Elementary',
  'Dewar Es',
  'Dewar Hs',
  'Dewar Ms',
  'Dewey Academy',
  'Dewey Avenue Elementary',
  'Dewey Elem Academy Of Fine Arts',
  'Dewey Elem School',
  'Dewey Elem.',
  'Dewey Elementary',
  'Dewey Elementary School',
  'Dewey Es',
  'Dewey Hs',
  'Dewey Ms',
  "Dewey Sch.-internat'l. Studies",
  'Dewey-carter Elementary',
  'Deweyville El',
  'Deweyville H S',
  'Dewitt Clinton High School',
  'Dewitt D. Barlow Elementary School',
  'Dewitt Elementary School',
  'Dewitt High School',
  'Dewitt Junior High School',
  'Dewitt Middle School',
  'Dewitt Road Elementary School',
  'Dewitt Waller Ms',
  'Dewitt-lavaca Special Ed Co-op',
  'Dewolf Continuation High',
  'Dexter Alternative School',
  'Dexter Elem',
  'Dexter Elementary',
  'Dexter Elementary School',
  'Dexter High',
  'Dexter High School',
  'Dexter Mccarty Middle School',
  'Dexter Middle',
  'Dexter Park',
  'Dexter Regional High School',
  'Deylaman Institute of Higher Education',
  'Deyton Elementary',
  'Dezavala El',
  'Dhaka International University',
  'Dhaka University of Engineering and Technology',
  'Dhirubhai Ambani Institute of Information and Communication Technology',
  'Dhofar University',
  'Dhurakijpundit University',
  'Di Giorgio Elementary',
  'Diablo Community Day',
  'Diablo Valley College',
  'Diablo View Middle',
  'Diablo Vista Elementary',
  'Diablo Vista Middle',
  'Diaconia University of Applied Sciences',
  'Diagnostic & Developmental Ctr',
  'Diagnostic Ctr.',
  'Diagonal Elementary School',
  'Diagonal Junior-senior High School',
  'Diamond Bar High',
  'Diamond Canyon Elementary',
  'Diamond Creek Elementary',
  'Diamond Elem.',
  'Diamond Elementary',
  'Diamond Elementary School',
  'Diamond Fork Junior High',
  'Diamond High',
  'Diamond Hill El',
  'Diamond Hill Elementary',
  'Diamond Hill-jarvis H S',
  'Diamond Lake Elem School',
  'Diamond Lakes Elementary School',
  'Diamond Middle',
  'Diamond Oaks Cdc',
  'Diamond Path El Sch Of Intl Studies',
  'Diamond Point Elementary',
  'Diamond Ranch High',
  'Diamond Ridge High School',
  'Diamond Ridge School',
  'Diamond Springs Elementary',
  'Diamond Technology Institute',
  'Diamond Valley Elementary',
  'Diamond Valley Middle',
  'Diamond Valley School',
  'Diamond View Elementary School',
  'Diamond View Middle',
  'Diamondback Elementary School',
  'Diamondhead Ready To Learn',
  'Diane S. Leichman Career Preparatory And Transition Center',
  'Diane Winborn El',
  'Diann Bankswilliamson Educational Center',
  'Dianne M Pellerin Center',
  'Diaz J H',
  'Diaz-villarreal El',
  'Dibble Elementary School',
  'Dibble Es',
  'Dibble Hs',
  'Dibble Ms',
  'Diberville Elem',
  'Diberville Middle School',
  'Diberville Senior High Sch',
  'Diboll H S',
  'Diboll J H',
  'Diboll Pri',
  'Dibrell Elementary',
  'Dibrugarh University',
  'Dick Scobee Elementary School',
  'Dick William Sch',
  'Dicken Elementary School',
  'Dickens (billy Joe) High (continuation)',
  'Dickenson Elementary School',
  'Dickerson Elementary School',
  'Dickerson Middle School',
  'Dickey El Sch',
  'Dickey Hill Elementary/middle',
  'Dickinson (john) High School',
  'Dickinson Avenue Elementary School',
  'Dickinson College',
  'Dickinson Continuation Center',
  'Dickinson East Elementary School',
  'Dickinson Elementary',
  'Dickinson Fine Arts Academy',
  'Dickinson H S',
  'Dickinson High School',
  'Dickinson Iron Early Childhood Education',
  'Dickinson Middle School',
  'Dickinson School',
  'Dickinson Spec Ed Unit',
  'Dickinson State University',
  'Dickinson West Elementary School',
  'Dickinsoniron Special Education',
  'Dickinsoniron Tech Ed Center',
  'Dickison Elementary',
  'Dickson County High School',
  'Dickson Elementary',
  'Dickson Es',
  'Dickson Hs',
  'Dickson Intermediate School',
  'Dickson Middle School',
  'Dickson Ms',
  'Dickson Upper Es',
  'Dicle (Tirgris) University',
  'Dieck Elementary School',
  'Diego De Torres Vargas',
  'Diego Hills Central Public Charter',
  'Diego Hills Charter',
  'Diego Rivera Learning Complex Green Design Steam Academy',
  'Diego Springs Academy',
  'Diego Valley Charter',
  'Diego Vazquez',
  'Diegueno Middle',
  'Diehl Sch',
  'Diekman Elem School',
  'Dieringer Heights Elementary',
  'Dierks Es',
  'Dierks High School',
  'Dieterich Elem School',
  'Dieterich Jr/sr High School',
  'Dietrich School',
  'Dietz K-8 School',
  'Diggs-latham Elementary',
  'Dighton Elem',
  'Dighton Elementary',
  'Dighton High',
  'Dighton Middle School',
  'Dighton-rehoboth Regional High School',
  'Digital Arts And Technology',
  'Digital Harbor High School',
  'Dijla University College',
  'Dike Elementary School',
  'Dike-new Hartford High School',
  'Dike-new Hartford Junior High School',
  'Dike-newell School',
  'Dilcon Community School',
  'Dildine Elementary',
  'Diley Middle School',
  'Dilla University',
  'Dillard 6-12',
  'Dillard Academy',
  'Dillard Alternative High School',
  'Dillard Drive Elementary',
  'Dillard Drive Middle',
  'Dillard Elementary School',
  'Dillard Middle',
  'Dillard Street Elementary',
  'Dillard University',
  'Diller-odell Elem School',
  'Diller-odell Secondary School',
  'Dilley El',
  'Dilley Elementary School',
  'Dilley H S',
  'Dillingham Correspondence School',
  'Dillingham Elementary',
  'Dillingham Int',
  'Dillingham Middle/high School',
  'Dillman El',
  'Dillon County Applied Technology Center',
  'Dillon Elementary School',
  'Dillon High',
  'Dillon Middle',
  'Dillon Middle School',
  'Dillon Valley Elementary School',
  'Dillsboro Elementary School',
  'Dillsburg El Sch',
  'Diloreto Elementary & Middle School',
  'Dilworth Elementary',
  'Dilworth School',
  'Dilworth-glyndon-felton Middle Sch',
  'Dilworth-glyndon-felton Senior High',
  'Diman Regional Vocational Technical High',
  'Dime Box School',
  'Dimensions Academy',
  'Dimensions Of Learning Academy',
  'Dimmick Comm Cons School',
  'Dimmick Comm Cons Sd 175',
  'Dimmitt H S',
  'Dimmitt Middle',
  'Dimmitt Middle School',
  'Dimocritus University of Thrace',
  'Dimon Elementary',
  'Dimond High School',
  'Dimondale Elementary School',
  'Dingeman Elementary',
  'Dingess Elementary School',
  'Dingle Elementary',
  'Dingman-delaware El Sch',
  'Dingman-delaware Ms',
  'Dingman-delaware Primary Sch',
  'Dinsmore Elementary School',
  'Dinuba High',
  'Dinwiddie County High',
  'Dinwiddie County Middle',
  'Dinwiddie Elementary',
  'Diomede School',
  'Dionne Warwick Institute',
  'Diploma Success Community School',
  'Diplomat Elementary School',
  'Diplomat Middle School',
  'Dire Dawa University',
  'Dirección General de Institutos Tecnológicos',
  'Directions Program',
  'Dirigo Elementary School',
  'Dirigo High School',
  'Dirksen Elem School',
  'Dirksen Elementary School',
  'Dirksen Junior High School',
  'Disciplinary Alternative Education Program',
  'Disciplinary Alternative Educational Progam (daep)',
  'Discipline Alternative Education Placement',
  'Discipline Alternative Education Program',
  'Discovery',
  'Discovery Academy',
  'Discovery Academy Of Lake Alfred',
  'Discovery Academy Of Science Charter',
  'Discovery Bay Elementary',
  'Discovery Canyon Campus Elementary School',
  'Discovery Canyon Campus High School',
  'Discovery Canyon Campus Middle School',
  'Discovery Charter',
  'Discovery Charter Elementary School',
  'Discovery Charter Ii',
  'Discovery Charter Of Lancaster',
  'Discovery Charter Preparatory School #2',
  'Discovery Charter School',
  'Discovery Charter School Hillpointe',
  'Discovery Charter School Mesa Vista',
  'Discovery Community Elementary',
  'Discovery Elementary',
  'Discovery Elementary - 26',
  'Discovery Elementary School',
  'Discovery Gardens Preschool',
  'Discovery Hall',
  'Discovery High',
  'Discovery High School',
  'Discovery High School-achieve',
  'Discovery Intermediate School',
  'Discovery Key Elementary School',
  'Discovery Lab School',
  'Discovery Middle',
  'Discovery Middle School',
  'Discovery Plus Academy',
  'Discovery Primary School',
  'Discovery Public School Faribault',
  'Discovery Ridge Elementary',
  'Discovery School',
  'Discovery School At Four Corners',
  'Discovery School Of The Arts',
  'Discovery Stem Academy',
  'Discovery Woods',
  "Dishchii'bikoh Community School",
  'Dishman El',
  'Dishman Hills High School',
  'Dishman Mcginnis Elementary School',
  'Disnard Elementary School',
  'Disney Elem Magnet School',
  'Disney Es',
  'Disney Ii Elem School',
  'Disney Ii Magnet Hs',
  'Disston Academy',
  'Disston Hamilton Sch',
  'Dist 206 Alternative High School',
  'Dist Wide Gifted Program',
  'Dist Wide Homebound Program',
  'Distance Learning Indep Study',
  'Distance Learning Program',
  'Distance Learning/corresp. Ctr.',
  'Distinctive College Prep',
  'District 287 Academy',
  'District 4k',
  'District 7 Alternative School',
  'District 9-r Shared School',
  'District 99 Early Childhood Ctr',
  'District Autism Program',
  'District Heights Elementary',
  'District Instructional Center (dic)',
  'District No 7 Elementary',
  'District Of Columbia International School',
  'District Pre-k Programs',
  'District Program Erms',
  'District Program Res',
  'District Program Ses',
  'District Reassignment & Opportunity Center',
  'District Run Home School',
  'District Service Center',
  'District Special Education',
  'District Summer Center C',
  'District Summer Center D',
  'District Summer Center E',
  'District Summer Center F',
  'District Summer Center G',
  'District Summer Center H',
  'District Summer Center L',
  'District Wide El',
  'Ditto El',
  'Diven School',
  'Divide County Elementary School',
  'Divide County High School',
  'Divide El',
  'Divide Elementary',
  'Divide High',
  'Divide School',
  'Divine Word College of Legazpi',
  'Divine Word University',
  'Divisadero Middle',
  'Division Avenue Senior High School',
  "Division Of Unaccompanied Children's Services (ducs)",
  'Division Street Elementary School',
  'Dix Street Elementary School',
  'Dixie Attendance Center',
  'Dixie Bee Elementary School',
  'Dixie Canyon Community Charter',
  'Dixie County Adult Center',
  'Dixie County High School',
  'Dixie El',
  'Dixie Elementary',
  'Dixie Elementary Magnet School',
  'Dixie Elementary School',
  'Dixie Heights High School',
  'Dixie High',
  'Dixie High School',
  'Dixie Hollins Adult Education Center',
  'Dixie M. Hollins High School',
  'Dixie Middle',
  'Dixie Middle School',
  'Dixie Montessori Academy',
  'Dixie Sun School',
  'Dixie Teen Parent Preschool',
  'Dixie Virtual Franchise',
  'Dixie Virtual Instruction (course Offerings)',
  'Dixie Virtual Instruction Program',
  'Dixieland Elementary',
  'Dixieland Elementary School',
  'Dixon 7-8',
  'Dixon Community Day',
  'Dixon Correctional Institute',
  'Dixon Elem School',
  'Dixon Elem.',
  'Dixon Elementary',
  'Dixon Elementary School',
  'Dixon High',
  'Dixon High School',
  'Dixon Middle',
  'Dixon Montessori Charter',
  'Dixon Road Elementary',
  'Diyala University',
  'Dk Academy',
  'Dlc Whitney',
  'Dneprodzerzhinsk State Technical University',
  'Dnepropetrovsk National University',
  'Dnepropetrovsk National University of Railway Transport',
  'Doak Elementary',
  'Dobbins Avt Hs',
  'Dobbins Elementary',
  'Dobbins Elementary School',
  'Dobbs Elem.',
  'Dobbs Elementary School',
  'Dobbs Ferry High School',
  'Dobbs Ferry Middle School',
  'Dobie H S',
  'Dobie J H',
  'Dobie Middle',
  'Dobie Pk Center',
  'Dobie Pri',
  'Dobson Academy The - A Ball Charter School',
  'Dobson Elementary',
  'Dobson High School',
  'Dobson James Sch',
  'Doby Elementary School',
  "Doby's Bridge Elementary",
  "Doby's Mill Elementary",
  'Dobyns - Bennett High School',
  'Dock Street School For Stem Studies',
  'Doctors Charter School Of Miami Shores',
  'Doctors Inlet Elementary School',
  'Dodd City School',
  'Dodd El',
  'Dodd Middle School',
  'Doddridge County Elementary School',
  'Doddridge County High School',
  'Doddridge County Middle School',
  'Doddridge County Preschool Center',
  'Dodge City Community College',
  'Dodge City High School',
  'Dodge City Middle School',
  'Dodge County High School',
  'Dodge County Middle School',
  'Dodge Elem School',
  'Dodge Elementary School',
  'Dodge Literacy Magnet',
  'Dodge Park Elementary',
  'Dodgeland Elementary',
  'Dodgeland High',
  'Dodgeland Middle',
  'Dodgen Middle School',
  'Dodgertown Elementary School',
  'Dodgeville Elementary',
  'Dodgeville High',
  'Dodgeville Middle',
  'Dodson 7-8',
  'Dodson Branch Elementary',
  'Dodson El Sch',
  'Dodson Elementary',
  'Dodson Elementary School',
  'Dodson High School',
  'Dodson Pri',
  'Dodson School',
  'Dodson Street Elementary School',
  'Doe Creek Middle School',
  'Doe Elementary',
  'Doe Run El Sch',
  'Doe Run Elem Sch',
  'Doerfler Elementary',
  'Doerre Int',
  'Doerun Elementary School',
  'Does Puentes Elementary School',
  'Dogan El',
  'Dogan Middle',
  'Dogus University',
  'Dogwood El',
  'Dogwood Elem.',
  'Dogwood Elementary',
  'Dogwood Elementary School',
  'Dogwood Hill Elementary School',
  'Dogwood Hills School',
  'Doherty Elementary School',
  'Doherty High School',
  'Doherty Memorial High',
  'Doherty Middle',
  'Dohn Community',
  'Doho University',
  'Dohron Wilson Elementary School',
  'Dohto University',
  'Dokkyo University',
  'Dokkyo University School of Medicine',
  'Dokuz Eylul University',
  'Dolan Ctr Maryville Farm Campus',
  'Dolan School',
  'Doland Elementary - 03',
  'Doland High School - 01',
  'Doland Jr. High - 02',
  'Dolbeare',
  'Dolby Elementary School',
  'Dole Elementary',
  'Dolgeville Elementary School',
  'Dolgeville Middle School',
  'Dollar Baytamarack City Area K12 School',
  'Dollarway High School',
  'Dollens Elementary School',
  'Dolores Alvarez Montanez',
  'Dolores Elementary School',
  'Dolores Gomez De Roman',
  'Dolores Gonzales Elementary',
  'Dolores Gonzalez',
  'Dolores High School',
  'Dolores Huerta El',
  'Dolores Huerta Elementary',
  'Dolores Huerta International Academy',
  'Dolores Mcclatchey El',
  'Dolores Middle School',
  'Dolores Street Elementary',
  'Dolphin Bay Elementary School',
  'Dolphin Park High',
  'Dolphin Terrace El',
  'Dolvin Elementary School',
  'Domingo Aponte Collazo',
  'Domingo Massol',
  'Domingo Nieves Ortiz (palma Sola)',
  'Domingo Pietri Ruiz',
  'Domingo Trevino Middle',
  'Dominguez Elementary',
  'Dominguez High',
  'Dominica State College',
  'Dominican College',
  'Dominican College of Philosophy and Theology',
  'Dominick H. Ferrara School',
  'Dominion High',
  'Dominion Middle School',
  'Dominion Trail Elementary',
  'Dommerich Elementary',
  'Don Agustin Acevedo Hernandez',
  'Don And Dee Snyder Elementary School',
  'Don Antonio Lugo High',
  'Don Benito Fundamental',
  'Don Bosco Academy',
  'Don Brewer Elementary School',
  'Don Callejon',
  'Don Carter El',
  'Don Cecilio Mtz Elementary',
  'Don D Shute Elem School',
  'Don D Steed Elementary',
  'Don E Hayden Elementary School',
  'Don E Woods Opportunity Center',
  'Don Earl Early Childhood Ctr.',
  'Don Estridge High Tech Middle School',
  'Don Gill El Sch',
  'Don Ignacio Dicupe Gonzalez',
  'Don Jeter El',
  'Don Jose Gallego',
  'Don Juan Avila Elementary',
  'Don Juan Avila Middle',
  'Don Julian Elementary',
  'Don Luis Munoz Marin (elemental Urbana Nueva)',
  'Don Manolo Rivera',
  'Don Mariano Marcos Memorial State University',
  'Don Mensendick School',
  'Don Pedro Elementary',
  'Don Pedro High',
  'Don R Daniel Ninth Grade Campus',
  'Don Riggio',
  'Don Roberts Elementary School',
  'Don Smith Learning Academy',
  'Don State Agrarian University',
  'Don State Technical University',
  'Don Stowell Elementary',
  'Don T Durham Int',
  'Don Whitt El',
  'Dona Ana Elementary',
  'Dona Merced Elementary',
  'Donald A. Quarles Early Childhood Center',
  'Donald B. Dixon-lyle R. Smith Middle',
  'Donald C Parker Early Educ Center',
  'Donald D Sherrill Education Cntr - Elem',
  'Donald D Stalker Elementary',
  'Donald D. Lum Elementary',
  'Donald D. Siegrist Elem.',
  'Donald E Gavit Middle/high School',
  'Donald E. Cline Elementary School',
  'Donald E. Schick Sch',
  'Donald E. Suburu',
  'Donald El',
  'Donald Elementary School',
  'Donald F. Bradach Elementary',
  'Donald Graham Elementary',
  'Donald H Eichhorn Ms',
  'Donald J Hobbs Middle School',
  'Donald J Yacks Elem School',
  'Donald J. Meyer Elementary',
  'Donald L Pavlik Middle School',
  'Donald L. Rheem Elementary',
  'Donald Leonetti El',
  'Donald Mckay',
  'Donald P Sloat Jhs',
  'Donald P Sutherland School',
  'Donald P Timony Grammar',
  'Donald R. Kuhn Juvenile Center',
  'Donald Ross',
  'Donald S Ray School',
  'Donald S. Jordan Intermediate',
  'Donald Stewart Early Childhood Center No 51',
  'Donaldson Career Center',
  'Donaldson Elem Sch',
  'Donaldson Way Elementary',
  'Donaldsonville High School',
  'Donaldsonville Primary School',
  'Donau-Universität Krems',
  'Donegal El Sch',
  'Donegal Hs',
  'Donegal Intermediate School',
  'Donegal Jhs',
  'Donegal Primary School',
  'Donegan El Sch',
  'Donehoo Elementary School',
  'Donelson Elementary',
  'Donelson Hills Elementary School',
  'Donelson Middle',
  'Donetsk National Technical University',
  'Donetsk National University',
  'Donetsk State Medical University',
  'Dong Eui University',
  'Dong Yang University of Technology',
  'Dong-A University',
  'Dongbei University of Finance And Economics',
  "Dongduk Women's University",
  'Donges Bay Elementary',
  'Dongguk University',
  'Donghua University Shanghai',
  'Dongola Elementary School',
  'Dongola High School',
  'Dongola Jr High School',
  'Dongseo University',
  'Dongshin University',
  'Doniphan Elem.',
  'Doniphan High',
  'Doniphan Intermediate',
  'Doniphan Middle',
  'Doniphan West Elementary School',
  'Doniphan West Jr/sr High School',
  'Doniphan-trumbull Elementary',
  'Doniphan-trumbull Secondary',
  'Donley Elementary School',
  'Donlin Drive Elementary School',
  'Donlon Elementary',
  'Donn B. Chenoweth Elementary',
  'Donna Alternative Education Program',
  'Donna H S',
  'Donna J. Beasley Technical Academy',
  'Donna L Loflin Elementary',
  'Donna Lewis El',
  'Donna North H S',
  'Donna Park',
  'Donna Shepard Int',
  'Donna Wernecke El',
  'Donnell Middle School',
  'Donnelly Elementary',
  'Donnelsville Elementary School',
  'Donner Springs Elementary School',
  'Donner Trail Elementary',
  'Donnie Bickham Middle School',
  'Donovan Elem School',
  'Donovan Elementary School',
  'Donovan Jr High School',
  'Donovan Sr High School',
  'Dool Elementary',
  'Doolen Middle School',
  'Dooley Center',
  'Dooley El',
  'Dooley Elementary',
  'Doolittle School',
  'Dooly County Elementary School',
  'Dooly County High School',
  'Dooly County Middle School',
  'Dooly County Prep Academy',
  'Dora Elem.',
  'Dora Elementary',
  'Dora Erickson Elementary School',
  'Dora High',
  'Dora High School',
  'Dora Kennedy French Immersion',
  'Dora Kimmons Jr. High School',
  'Dora L Small Elementary School',
  'Dora M Sauceda Middle',
  'Dora Moore Ece-8 School',
  'Dora Romero El',
  'Doral Academy',
  'Doral Academy Cactus',
  'Doral Academy Charter High School',
  'Doral Academy Charter Middle School',
  'Doral Academy Fire Mesa',
  'Doral Academy Northern Nevada',
  'Doral Academy Of Colorado',
  'Doral Academy Of Technology',
  'Doral Academy Red Rock',
  'Doral Academy Saddle',
  'Doral Academy West Pebble',
  'Doral International Academy Of Math And Science',
  'Doral Performing Arts & Entertainment Academy',
  'Dorchester Academy',
  'Dorchester Career And Technology Center',
  'Dorchester County Career And Technology Center',
  'Dorchester Elementary School',
  'Dorchester High School',
  'Dorchester Preschool',
  'Dordt College',
  'Dore Elem School',
  'Dorena School',
  'Dorie Miller El',
  'Dorie Miller Int',
  'Doris A. Sanders Learning Center',
  'Doris Cullins-lake Pointe El',
  'Doris French Elementary School',
  'Doris Hancock Elementary School',
  'Doris Henderson Newcomers Sch',
  'Doris Klaussen Dev Center',
  'Doris M Reed Elementary School',
  'Doris Martinez (almacigo Alto 2)',
  'Dorman High',
  'Dorman High Freshman Campus',
  'Dormont El Sch',
  'Dorn Primary Center',
  'Dorothy And Noble Harrelson School',
  'Dorothy C. Goodwin School',
  'Dorothy Dodds Elementary School',
  'Dorothy E Lewis Elementary School',
  'Dorothy Eisenberg Elementary School',
  'Dorothy Fox',
  'Dorothy Grant Elementary',
  'Dorothy Hains Elementary School',
  'Dorothy Height Elementary School',
  'Dorothy I Height Es',
  'Dorothy I. Height Elementary',
  'Dorothy L Beckwith',
  'Dorothy Lemelson Stem Academy Elementary School',
  'Dorothy M. Wallace Cope Center',
  'Dorothy Mcelhinney Middle',
  'Dorothy Moses Elementary School',
  'Dorothy Nolan Elementary School',
  'Dorothy P Adkins El',
  'Dorothy Simon Elem School',
  'Dorothy Smith Pullen El',
  'Dorothy Stinson School',
  'Dorothy Thomas Center',
  'Dorothy Thompson Middle',
  'Dorothy V. Johnson Community Day',
  'Dorr School',
  'Dorr Street Elementary School',
  'Dorris A Jones El',
  'Dorris Intermediate Sch',
  'Dorris Place Elementary',
  'Dorset Elementary School',
  'Dorsett Shoals Elementary School',
  'Dorsey Attendance Center',
  'Dorseyville Elementary School',
  'Dorseyville Ms',
  'Dort Elementary School',
  'Dorthy L. Bullock School',
  'Dorton Elementary School',
  'Dos Caminos',
  'Dos Caminos Elementary',
  'Dos Palos Carver Center',
  'Dos Palos Elementary',
  'Dos Palos High',
  'Dos Pueblos Senior High',
  'Dos Rios Elementary',
  'Dos Rios Elementary School',
  'Doshisha University',
  "Doshisha Women's College of Liberal Arts",
  'Doss El',
  'Doss High',
  'Dossin Elementarymiddle School',
  'Doswell E. Brooks Elementary',
  'Dot Lake School',
  'Dothan Brook School',
  'Dothan High School',
  'Dothan Technology Center',
  'Doty Elementary',
  'Doty Memorial School',
  'Doty Middle',
  'Double Adobe Elementary School',
  'Double Branch Elementary School',
  'Double Churches Elementary School',
  'Double Churches Middle School',
  'Double Diamond Elementary School',
  'Double Eagle Elementary',
  'Double File Trail El',
  'Double O Elementary School',
  'Double Peak',
  'Double Springs Elementary School',
  'Double Springs Middle School',
  'Double Tree Elementary',
  'Doudna Elementary',
  'Dougherty Comprehensive High School',
  'Dougherty Valley High',
  'Douglas Alternative School',
  'Douglas Anderson School Of The Arts',
  'Douglas Benold Middle',
  'Douglas Byrd High',
  'Douglas Byrd Middle',
  'Douglas City Elementary',
  'Douglas Co West Alternative High School',
  'Douglas Co West Elementary Sch',
  'Douglas Co West High School',
  'Douglas Co West Middle School',
  'Douglas College',
  'Douglas County Adult School',
  'Douglas County High School',
  'Douglas County Youth Center',
  'Douglas El',
  'Douglas Elem School',
  'Douglas Elementary',
  'Douglas Elementary 34 Campus',
  'Douglas Elementary School',
  'Douglas G Grafflin School',
  'Douglas Gardens Elementary School',
  'Douglas High School',
  'Douglas High School - 03',
  'Douglas Int',
  'Douglas Intermediate School',
  'Douglas J. Miller Elementary',
  'Douglas L. Jamerson Jr. Elementary',
  'Douglas Macarthur',
  'Douglas Macarthur Elem School',
  'Douglas Macarthur Elementary',
  'Douglas Macarthur Elementary Sch',
  'Douglas Macarthur Elementary School',
  'Douglas Macarthur Fundamental Intermediate',
  'Douglas Macarthur Jhs',
  'Douglas Middle School',
  'Douglas Middle School - 01',
  'Douglas Preschool - 08',
  'Douglas Primary School',
  'Douglas R Welch High School',
  'Douglas Road Elementary School',
  'Douglas S. Freeman High',
  'Douglas School',
  'Douglas T. Orchard School',
  'Douglas Upper Elementary School',
  'Douglas Waybright',
  'Douglass Academy',
  'Douglass Academy For Young Men',
  'Douglass Academy High School',
  'Douglass El',
  'Douglass Elem',
  'Douglass Elementary',
  'Douglass Elementary School',
  'Douglass Elementary/middle',
  'Douglass High',
  'Douglass High School',
  'Douglass Hs',
  'Douglass Middle',
  'Douglass Ms',
  'Douglass Park Elementary',
  'Douglass School',
  'Douglass Valley Elementary School',
  'Doull Elementary School',
  'Douma Elementary School',
  'Dousman Elementary',
  'Dovalina El',
  'Dove Academy Of Detroit',
  'Dove Creek High School',
  'Dove El',
  'Dove Hill Elementary',
  'Dove Sch Of Discovery Es Tulsa',
  'Dove Science Academy (okc)',
  'Dove Science Academy Es',
  'Dove Science Academy Es (okc)',
  'Dove Science Academy Hs',
  'Dove Science Academy Ms',
  'Dove Science Academy Tulsa',
  'Dove Science Academy Tulsa Hs',
  'Dove Science Academy Tulsa Jhs',
  'Dover Academy For International Studies',
  'Dover Air Force Base Middle School',
  'Dover Area El Sch',
  'Dover Area Hs',
  'Dover Area Intrmd Sch',
  'Dover Avenue Elementary School',
  'Dover El',
  'Dover Elementary',
  'Dover Elementary School',
  'Dover Es',
  'Dover High School',
  'Dover Hs',
  'Dover Intermediate School',
  'Dover Middle School',
  'Dover Senior High School',
  'Dover Shores Elementary',
  'Dover-eyota Elementary',
  'Dover-eyota High School',
  'Dover-eyota Middle School',
  'Dover-sherborn Regional High',
  'Dover-sherborn Regional Middle School',
  'Doves',
  'Dow University of Health Sciences',
  "Dow's Prairie Elementary",
  'Dowagiac Middle School',
  'Dowdell Middle Magnet School',
  'Dowds Elementary School',
  'Dowell El',
  'Dowell Elementary',
  'Dowell Elementary School',
  'Dowell Middle',
  'Dower Elementary School',
  'Dowling College',
  'Dowling El',
  'Dowling Elementary',
  'Down East Middle And Smyrna Elementary',
  'Downe Township Elementary School',
  'Downeast School',
  'Downes (john R.) Elementary School',
  'Downey',
  'Downey Community Day',
  'Downey Elementary School',
  'Downey High',
  'Downey Sch',
  'Downie (carrie) Elementary School',
  'Downieville Elementary',
  'Downieville Junior-senior High',
  'Downing',
  'Downingtown Hs East Campus',
  'Downingtown Hs West Campus',
  'Downingtown Ms',
  'Downingtown Stem Academy',
  'Downriver High School',
  'Downriver Virtual Academy',
  'Downs El',
  'Downsville Central School',
  'Downsville Community Charter School',
  'Downsville Elementary',
  'Downtown Alternative High School',
  'Downtown Business High',
  'Downtown Campus',
  'Downtown Charter Academy',
  'Downtown College Prep - Alum Rock',
  'Downtown College Preparatory',
  'Downtown College Preparatory Middle',
  'Downtown Denver Expeditionary School',
  'Downtown Doral Charter Elementary School',
  'Downtown Doral Charter Upper School',
  'Downtown Elementary',
  'Downtown Elementary Magnet Academy',
  'Downtown High',
  'Downtown Miami Charter School',
  'Downtown Montessori',
  'Downtown School',
  'Downtown Value',
  'Dows Lane (k-3) School',
  'Doxey School',
  'Doyle El Sch',
  'Doyle Elementary',
  'Doyle Elementary School',
  'Doyle High School',
  'Doyleryder School',
  'Doyline High School',
  'Dozier El',
  'Dozier Elementary School',
  'Dozier-libbey Medical High',
  'Dps Early Childhood Center',
  'Dps Foundation For Early Learners Edmonson',
  'Dps Foundation For Early Learners Glazer',
  'Dps Foundation For Early Learners Loving',
  'Dps Foundation For Early Learners Pk White',
  'Dps Hospital School',
  'Dr A Pantoja Comm Sch Excllnce -#18',
  'Dr Abraham P Cano Freshman Academy',
  'Dr Al Draper Intermed',
  'Dr Alejo Salinas Jr El',
  'Dr Alfredo M Aguayo',
  'Dr Americo Paredes El',
  'Dr An Wang School',
  'Dr Andy Hall Early Educ Ctr',
  'Dr Bernard C Watson Acad For Boys',
  'Dr Bessie Rhodes-global Studies',
  'Dr Bob Forinash Community Day',
  'Dr Bruce Wood El',
  'Dr Bryan C Jack El',
  'Dr Carrie D Robinson Littleton Elementary School',
  'Dr Cleveland Steward Jr El Sch',
  'Dr Daniel Bright Elementary School',
  'Dr David C Walker El',
  'Dr David W Kistler El Sch',
  'Dr Donald Wharton Elem School',
  'Dr E T Boon El',
  'Dr Edward G Dyer School',
  'Dr Efrain Gonzalez Tejera',
  'Dr Efrain Sanchez Hidalgo',
  'Dr Fermin Calderon El',
  'Dr Francisco Vazquez',
  'Dr Frederick N Sweetsir',
  'Dr George Blackman Ecc',
  'Dr Gertrude Bailey',
  'Dr H B Tanner Elementary',
  'Dr Harmon W Kelley El',
  'Dr Hesiquio Rodriguez El',
  'Dr Howard Elem School',
  'Dr Howard K Conley Elementary School',
  'Dr Hubert Eaton Sr Elem',
  'Dr Jack Cockrill Middle',
  'Dr Jacqueline Peek-davis School',
  'Dr Javier Saenz Middle',
  'Dr John C Page School',
  'Dr John Codington Elem',
  'Dr John Hole Elementary School',
  'Dr Jose Celso Barbosa',
  'Dr Joseph A Fowler El',
  'Dr Joseph F Pollack Academic Center Of Excellence',
  'Dr Julian Rogus School',
  'Dr Kay Teer Crawford El',
  'Dr Kenneth M Sorrick School',
  'Dr King Elementary School',
  'Dr Kirk Lewis Career And Technical H S',
  'Dr Lena Edwards Academic Charter School',
  'Dr Leo Cigarroa H S',
  'Dr Levesque Elementary School',
  'Dr Lewis S Libby School',
  'Dr Linda Henrie El',
  'Dr Lonnie Green Jr El',
  'Dr Lorenzo G Lafarelle Jjaep',
  'Dr Lorenzo G Lafarelle Middle',
  'Dr Lydia T Wright Sch Of Excellence',
  'Dr Lynn Armstrong El',
  'Dr M L Garza-gonzalez Charter School',
  'Dr Mae E Jones-clark El',
  'Dr Malakoff El',
  'Dr Maria Palmira Mendiola El',
  'Dr Mario E Ramirez El',
  'Dr Martin Luther King Academy',
  'Dr Martin Luther King Jr Multicultural Institute',
  'Dr Martin Luther King Jr School Complex',
  'Dr Martin Luther King School Jr Elementary School',
  'Dr Ml King Jr Literary&fine Arts',
  'Dr N H Jones Elementary School',
  'Dr Nick Osborne Primary Center',
  'Dr Pablo Perez',
  'Dr Paul Nettle',
  'Dr Paul S Saenz J H',
  'Dr Pedro Albizu Campos',
  'Dr Pedro Perea Fajardo',
  'Dr Preston L Williams Jr Elem Sch',
  'Dr R E Margo El',
  'Dr Rafael Lopez Landron',
  'Dr Richard Izquierdo Health And Science Charter School',
  'Dr Robert H Faulkner Academy',
  'Dr Robert Ketterer Cs Inc.',
  'Dr Robert W Gilliard Elementary',
  'Dr Ronald E Mcnair Jh',
  'Dr Ronald Mcnair High School',
  'Dr Rosa Minoka-hill School',
  'Dr Sarah Jandrucko Academy For Early Learners',
  'Dr Shirley J Williamson El',
  'Dr Silverio Medina Gaud (consumo)',
  'Dr Sue A Shook School',
  'Dr Susan S Mckinney Secondary School Of The Arts',
  'Dr T C Mccormick Jr Middle',
  'Dr Terry Robbins Middle',
  'Dr Thomas Esparza El',
  'Dr Tomas Rivera El',
  'Dr W Arnone Community School',
  'Dr Walter Cooper Academy',
  'Dr Weeks Elementary School',
  'Dr William H Horton Elementary School',
  'Dr William Long El',
  'Dr Wright L Lassiter Jr Early College H S',
  'Dr. Agustin Stahl',
  'Dr. Albert Einstein Academy School No. 29',
  'Dr. Albert M. Bean School',
  'Dr. Albert Schweitzer',
  'Dr. Antonia Pantoja School No. 27',
  'Dr. Antonio S. Pedreira (especializada)',
  'Dr. Augustine Ramirez Intermediate',
  'Dr. B. R. Ambedkar National Institute of Technology Jalandhar',
  'Dr. B.R. Ambedkar Open University',
  'Dr. Babasaheb Ambedkar Marathwada Universtiy',
  'Dr. Babasaheb Ambedkar Technological University',
  'Dr. Bernard Harris Sr. Elementary',
  'Dr. Bernard J. Dubray Middle',
  'Dr. Bernice Jameson Todd Academy',
  'Dr. Beverly S. Mathis Elementary School',
  'Dr. Bhim Rao Abdekar University',
  'Dr. C Owen Roundy Elementary School',
  'Dr. C.V. Raman University',
  'Dr. Camille Casteel High School',
  'Dr. Carlos Gonzalez',
  'Dr. Carlos J. Finlay Elementary',
  'Dr. Carter Godwin Woodson',
  'Dr. Catherine Ellison-rosa Parks Early Ed School',
  'Dr. Cayetano Coll Y Toste',
  'Dr. Cesareo Rosa Nieves',
  'Dr. Charles A. Bejarano Elementary School',
  'Dr. Charles C. Polk School',
  'Dr. Charles E. Brimm Medical Arts High School',
  'Dr. Charles E. Murphy School',
  'Dr. Charles P. Defuccio School',
  'Dr. Charles R. Drew Elementary',
  'Dr. Claude G Perkins Elementary School',
  'Dr. Clemente Fernandez',
  'Dr. D.Y. Patil University',
  'Dr. David L. Anderson Middle School',
  'Dr. Doug Sears Learning Center',
  'Dr. Douglas K. Fletcher Elementary',
  'Dr. E. Alma Flagg School',
  'Dr. Edward L. Whigham Elementary School',
  'Dr. Edward Ricci School',
  'Dr. Efrain Sanchez Hidalgo',
  'Dr. Emmitt Smith',
  'Dr. Eugene Sires Elementary',
  'Dr. Facundo Bueso',
  'Dr. Francisco Hernandez Y Gaetan',
  'Dr. Francisco M. Susoni',
  'Dr. Franklin H. Mayberry School',
  'Dr. Frederica S. Wilson/skyway Elementary School',
  'Dr. Gary And Annette Auxier Elementary School',
  'Dr. George S. Emerson Elementary School',
  'Dr. George Washington Carver Elementary School',
  'Dr. Gerald H. Woehr Elementary School',
  'Dr. Gilbert L. Porter Elementary School',
  'Dr. Gustavus Brown Elementary',
  'Dr. H. O. Smith Elementary School',
  'Dr. Halliwell School',
  'Dr. Hani Awadallah School',
  'Dr. Hari Singh Gour University formerly University of Sagar',
  'Dr. Helen Baldwin Middle School',
  'Dr. Henry A. Wise Jr. High',
  'Dr. Henry E. Perrine Academy Of The Arts',
  'Dr. Henry H. Davis Elementary School',
  'Dr. Henry W. Mack/west Little River K-8 Center',
  'Dr. Heriberto Domenech',
  'Dr. Hiram Gonzalez',
  'Dr. Isaac Gonzalez Martinez',
  'Dr. J. Michael Mcgrath Elementary',
  'Dr. J.p. Cleary Elementary',
  'Dr. James A. Forrest Career And Technology Center',
  'Dr. James Craik Elementary School',
  'Dr. James Edward Jones Primary Center',
  'Dr. Jesus M. Armaiz',
  'Dr. John A. Langford School',
  'Dr. John Grieco Elementary School',
  'Dr. John Long Middle School',
  'Dr. Jorge Alvarez Hs',
  'Dr. Jose A. Padin',
  'Dr. Jose Antonio Davila',
  'Dr. Jose Celso Barbosa',
  'Dr. Jose Gualberto Padilla',
  'Dr. Jose M. Lazaro',
  'Dr. Jose N. Gandara',
  'Dr. Jose Padin',
  'Dr. Joyanne D. Miller Elementary School',
  'Dr. Juan Jose Osuna',
  'Dr. Julian Nava Lrng Acads-sch Of Arts And Culture',
  'Dr. Julian Nava Lrng Acads-sch Of Business And Tech',
  'Dr. Juliet Thorner Elementary',
  'Dr. Julio J. Henna',
  'Dr. Kevin M. Hurley Middle School',
  'Dr. L. F. Soule School',
  'Dr. Lawrence H. Moore Math Science Technology Academy',
  'Dr. Leroy Mccloud Elementary School',
  'Dr. Lewis Dolphin Stallworth Sr. Charter',
  'Dr. Manuel C. Barreiro Elementary School',
  'Dr. Martin Luther King Charter School For Sci/tech',
  'Dr. Martin Luther King Jr Educational Complex',
  'Dr. Martin Luther King Jr. Academy',
  'Dr. Martin Luther King Jr. Early Childhood Center No 52',
  'Dr. Martin Luther King Jr. Early College',
  'Dr. Martin Luther King Jr. Elementary',
  'Dr. Martin Luther King Middle School',
  'Dr. Martin Luther King Montessori Academy',
  'Dr. Mary Giella Elementary School',
  'Dr. Mary Mcleod Bethune Elementary',
  'Dr. Maximo Donoso Sanchez',
  'Dr. May Angelou Elementary School',
  'Dr. Maya Angelou Community High',
  'Dr. Michael Conti School',
  'Dr. Michael M. Krop Senior High',
  'Dr. Mildred Dalton Henry Elementary',
  'Dr. Modesto Rivera Rivera',
  'Dr. Nathan A. Pitts-ashburton Elementary/middle',
  'Dr. Ne Roberts Elementary School',
  'Dr. Norman W. Crisp School',
  'Dr. Orlando Edreira Academy School No. 26',
  'Dr. Owen Lloyd Knox Elementary',
  'Dr. Panjabrao Deshmukh Krishi Vidyapeeth',
  'Dr. Paul L. Kelley Volunteer Academy',
  'Dr. Paul Rafalides School',
  'Dr. Pedro Albizu Campos',
  'Dr. Peter Marshall',
  'Dr. Philip O. Coakley Middle School',
  'Dr. Phillips Elementary',
  'Dr. Phillips High',
  'Dr. Phinnize J. Fisher Middle',
  'Dr. Pila',
  'Dr. Rafael Pujals',
  'Dr. Ram Manohar Lohia Avadh University',
  'Dr. Ramon Emeterio Betances',
  'Dr. Ramon Mellado Parsons',
  'Dr. Reynaldo J. Carreon Jr. Academy',
  'Dr. Robert B. Ingram/opa-locka Elementary School',
  'Dr. Robert H. Brown Middle School',
  'Dr. Rolando Espinosa K-8 Center',
  'Dr. Sally K. Ride Elementary',
  'Dr. Sammy Lee Medical And Health Science Magnet Elementary',
  'Dr. Samuel A. Mudd Elementary School',
  'Dr. Santiago Veve Calzada',
  'Dr. Santos J. Sepulveda',
  'Dr. Theodore T. Alexander Jr. Science Center',
  'Dr. Thomas L. Higdon Elementary',
  "Dr. Thomas S. O'connell School",
  'Dr. Tj Owens Gilroy Early College Academy',
  'Dr. Toni Bilbao Preparatory Academy',
  'Dr. Ulysses S. Wiggins College Preparatory Lab Schoo',
  'Dr. Victor Rincon',
  'Dr. W.j. Creel Elementary School',
  'Dr. Walter C. Ralston Intermediate',
  'Dr. William (bob) H Bailey Middle School',
  'Dr. William A. Chapman Elementary School',
  'Dr. William Burrus Elementary At Drakes Creek',
  'Dr. William Henderson Lower',
  'Dr. William Henderson Upper',
  'Dr. William Mennies Elementary School',
  'Dr. YS Parmar University of Horticulture and Forestry',
  'Dra. Antonia Saez',
  'Dra. Carmen Delia Colon Martinez',
  'Dra. Conchita Cuevas',
  'Dra. Maria Cadilla De Martinez',
  'Dra. Maria Socorro Lacot',
  'Dra. Maria T Delgado De Marcano',
  'Drachman Primary Magnet School',
  'Dracut Senior High',
  'Drager Early Education Center',
  'Drake Elem School',
  'Drake High School',
  'Drake Junior High School',
  'Drake Middle School',
  'Drake Residential Treatment Center',
  'Drake School',
  'Drake University',
  'Drakes Creek Middle School',
  'Drane Early Learning Center',
  'Dranesville Elementary',
  'Draper Elementary',
  'Draper Middle School',
  'Draper Park Middle',
  'Draper School',
  'Drauden Point Middle School',
  'Dravidian University',
  'Draw Academy',
  'Draw Academy Early Learning Center',
  'Draw Academy Upper El',
  'Drayton Elementary School',
  'Drayton Hall Elementary',
  'Drayton High School',
  'Draytonville Elementary',
  'Drc Campus',
  'Dream Academy',
  'Dream Academy Alternative School Of Choice',
  'Dream Charter School',
  'Dream Dine',
  'Dream Lake Elementary',
  'Dream Technical Academy',
  'Dreaming Summit Elementary',
  'Dreamyard Preparatory School',
  'Dreher High',
  'Dresden Elementary',
  'Dresden Elementary School',
  'Dresden High School',
  'Dresden Middle School',
  'Dressel Elementary School',
  'Dresser Elementary',
  'Drew (charles) College Preparatory Academy',
  'Drew Academy',
  'Drew Central Elem. School',
  'Drew Central High School',
  'Drew Central Middle School',
  'Drew El',
  'Drew Elementary School',
  'Drew Es',
  'Drew Freeman Middle',
  'Drew Hunter Middle School',
  'Drew Model Elementary',
  'Drew University',
  'Drewry Mason Elementary',
  'Drewsey Elementary School',
  'Drexel Avenue School',
  'Drexel Elem School',
  'Drexel Elem.',
  'Drexel Elementary',
  'Drexel Elementary School',
  'Drexel High',
  'Drexel Hill Ms',
  'Drexel University',
  'Drexler Middle- Intermediate School',
  'Driffill Elementary',
  'Driftwood Elementary School',
  'Driftwood Middle School',
  'Driggers El',
  'Driggs Elementary School',
  'Driggs School',
  'Dripping Springs El',
  'Dripping Springs H S',
  'Dripping Springs Middle',
  'Driscoll El & Middle',
  'Driscoll Elementary School',
  'Driscoll Middle',
  'Driswood Elementary School',
  'Driver Elementary',
  'Dropout Prevention School',
  'Drought Elementary',
  'Drs Reed - Mock El',
  'Druid Hill Elementary School',
  'Druid Hills Academy',
  'Druid Hills High School',
  'Druid Hills Middle School',
  'Drum Intermediate',
  'Drum Point Road Elementary School',
  'Drum Rock Early',
  'Drummond 7-8',
  'Drummond Elem School',
  'Drummond Elementary',
  'Drummond Es',
  'Drummond High',
  'Drummond High School',
  'Drummond Hs',
  'Drummond Island Elem School',
  'Drummond Junior High',
  'Drummond School',
  'Drummonds Elementary',
  'Drumright Hs',
  'Drums El/ms',
  'Drury College',
  'Drury High',
  'Dry Creek Elementary',
  'Dry Creek Elementary School',
  'Dry Creek School',
  'Dry Hollow Elementary School',
  'Dry Ridge Elementary',
  'Dryden Elem School',
  'Dryden Elementary',
  'Dryden Elementary School',
  'Dryden High School',
  'Dryden Middle School',
  'Dryden Street School',
  'Drzavni Univerzitet u Novom Pazaru',
  'Dsst College View High School',
  'Dsst: Byers High School',
  'Dsst: Byers Middle School',
  'Dsst: Cole High School',
  'Dsst: Cole Middle School',
  'Dsst: College View Middle School',
  'Dsst: Conservatory Green High School',
  'Dsst: Conservatory Green Middle School',
  'Dsst: Green Valley Ranch High School',
  'Dsst: Green Valley Ranch Middle School',
  'Dsst: Henry Middle School',
  'Dsst: Stapleton High School',
  'Dsst: Stapleton Middle School',
  'Du Pont Middle School',
  'Du Quoin Elementary School',
  'Du Quoin High School',
  'Du Quoin Middle School',
  'Dual Immersion Academy',
  'Dual Immersion Academy School',
  'Dual Language & Arts Magnet Middle School',
  'Dual Language Academy',
  'Dual Language Academy Of The Monterey Peninsula',
  'Dual Language School',
  'Duale Hochschule Baden-Württemberg',
  'Duane D Keller Middle School',
  'Duanesburg Elementary School',
  'Duanesburg High School',
  'Duarte High',
  'Dubach School',
  'Dubai Medical College for Girls',
  'Dubai Pharmacy College',
  'Dublin City University',
  'Dublin Coffman High School',
  'Dublin Consolidated School',
  'Dublin El',
  'Dublin Elementary',
  'Dublin H S',
  'Dublin High',
  'Dublin High School',
  'Dublin Institute for Advanced Studies',
  'Dublin Institute of Technology',
  'Dublin Int',
  'Dublin Jerome High School',
  'Dublin Middle',
  'Dublin Middle School',
  'Dublin Primary',
  'Dublin Scioto High School',
  'Dubna International University for Nature Society and Man',
  'Dubois Area Ms',
  'Dubois Area Shs',
  'Dubois Elem School',
  'Dubois Elementary',
  'Dubois Elementary School',
  'Dubois Elementary School Of Arts Technology',
  'Dubois Elementary School Of Entrepreneurship',
  'Dubois High Of Leadership Public Policy',
  'Dubois High School',
  'Dubois High School Of Arts Technology',
  'Dubois Integrity Academy',
  'Dubois Middle Of Leadership Public Policy',
  'Dubois Middle School',
  'Dubois Middle School Of Arts Technology',
  'Dubose Int',
  'Dubuque Senior High School',
  'Duchesne Elem.',
  'Duchesne High',
  'Duchesne School',
  'Duck Springs Elementary School',
  'Ducketts Lane',
  'Duckrey Tanner Sch',
  'Duckwater Elementary School',
  'Duckwater Middle School',
  'Duckwater Shoshone Elementary School',
  'Ducor Union Elementary',
  'Dudley El Gt Magnet School',
  'Dudley Elementary',
  'Dudley Elementary School',
  'Dudley Middle School',
  'Dudley Primary',
  'Dudley School',
  'Dudley Shoals Elementary',
  'Dudley Street Neighborhood Charter School',
  'Due West Elementary School',
  'Dueber Elementary School',
  'Dueitt Middle',
  'Duello Elem.',
  'Duff El',
  'Duff-allen Central Elementary',
  'Duffield-pattonsville Primary',
  'Duffy Elementary School',
  'Duffy School',
  'Dufief Elementary',
  'Dufur School',
  'Dug Gap Elementary School',
  'Dugan-tarango Middle',
  'Duggan School',
  'Dugger Union Community School Corp',
  'Dugsi Academy',
  'Dugway',
  'Dujardin Elementary School',
  'Duke Ellington School Of The Arts',
  'Duke Es',
  'Duke Hs',
  'Duke University',
  "Duksung Women's University",
  'Dulaney High',
  'Dularge Elementary School',
  'Dulce Elementary',
  'Dulce High',
  'Dulce Middle',
  'Dulles El',
  'Dulles Elem School',
  'Dulles H S',
  'Dulles Middle',
  'Duluth Area Learning Center',
  'Duluth High School',
  'Duluth Middle School',
  'Dumas H S',
  'Dumas High School',
  'Dumas Int',
  'Dumas J H',
  'Dumas Junior High School',
  'Dumbarton Elementary',
  'Dumbarton Middle',
  'Dumfries Elementary',
  'Dumlupinar University',
  'Dummerston Schools',
  'Dumont High School',
  'Dunaire Elementary School',
  'Dunaway El',
  'Dunbar (paul Laurence) Elementary School',
  'Dunbar And Br.',
  'Dunbar Boro El Sch',
  'Dunbar College Preparatory Academy',
  'Dunbar Community School',
  'Dunbar Creative Performing Arts',
  'Dunbar Early College High School',
  'Dunbar Elem School',
  'Dunbar Elementary',
  'Dunbar Elementary Magnet School',
  'Dunbar Elementary School',
  'Dunbar H S',
  'Dunbar High School',
  'Dunbar Hill School',
  'Dunbar Hs',
  'Dunbar Intermediate Center',
  'Dunbar Intermediate Es',
  'Dunbar Magnet Middle School',
  'Dunbar Middle',
  'Dunbar Middle School',
  'Dunbar Paul L Sch',
  'Dunbar Pri',
  'Dunbar Primary Center',
  'Dunbar Twp El Sch',
  'Dunbar Vocational Career Acad Hs',
  'Dunbarramer School',
  'Dunbarton Elementary School',
  'Duncan Chapel Elementary',
  'Duncan Creek Elementary',
  'Duncan El',
  'Duncan Elementary',
  'Duncan Falls Elementary School',
  'Duncan High School',
  'Duncan Hs',
  'Duncan Lake Early Childhood Center',
  'Duncan Lake Middle School',
  'Duncan Lake Transitions Program',
  'Duncan Ms',
  'Duncan Primary',
  'Duncan U. Fletcher High School',
  'Duncan U. Fletcher Middle School',
  'Duncan-russell Continuation',
  'Duncanville H S',
  'Duncanville Middle School',
  'Duncombe Elementary School',
  'Dundalk Elementary',
  'Dundalk High',
  'Dundalk Middle',
  'Dundee Community High School',
  'Dundee Elem School',
  'Dundee Elementary Academy',
  'Dundee Elementary School',
  'Dundee Highlands Elem School',
  'Dundee Junior-senior High School',
  'Dundee Middle School',
  'Dundee Ridge Middle Academy',
  'Dundee-crown High School',
  'Dundy Co Stratton High School',
  'Dunedin Elementary School',
  'Dunedin High School',
  'Dunedin Highland Middle School',
  'Dunellen High School',
  'Dunes Alternative High School',
  'Dungannon Intermediate',
  'Dunham Charter',
  'Dunham Elementary',
  'Dunham Elementary School',
  'Duniway Elementary School',
  'Duniway Middle School',
  'Dunkerton Elementary',
  'Dunkerton High School',
  'Dunkirk Middle School',
  'Dunkirk Senior High School',
  'Dunlap Elementary',
  'Dunlap Elementary School',
  'Dunlap Grade School',
  'Dunlap High School',
  'Dunlap Leadership Academy',
  'Dunlap Middle School',
  'Dunlap Valley Middle School',
  'Dunleith Elementary School',
  'Dunloe Elementary School',
  'Dunloggin Middle',
  'Dunlow Elementary School',
  'Dunmore El Ctr',
  'Dunmore Jshs',
  'Dunn El',
  'Dunn Elementary',
  'Dunn Elementary School',
  'Dunn Loring E.c. Resource Ctr.',
  'Dunn Middle',
  'Dunn Middle School',
  "Dunn's Corners School",
  'Dunne Technology Acad Elem Sch',
  'Dunnellon Elementary School',
  'Dunnellon High School',
  'Dunnellon Middle School',
  'Dunseith Day School',
  'Dunseith Elementary School',
  'Dunseith High School',
  'Dunsmore Elementary',
  'Dunsmuir Elementary',
  'Dunsmuir High',
  'Dunsmuir Joint Union High Community Day',
  'Dunstan Middle School',
  'Dunwiddie Elementary',
  'Dunwoody Elementary School',
  'Dunwoody High School',
  'Dunwoody Springs Elementary School',
  'Dunya Institute of Higher Education',
  'Dupage Alop',
  'Duplessis Primary School',
  'Duplin Early College High',
  'Dupo High School',
  'Dupo Jr High School',
  'Dupont (alexis I.) High School',
  'Dupont (alexis I.) Middle School',
  'Dupont (h.b.) Middle School',
  'Dupont (pierre S.) Middle School',
  'Dupont Elementary',
  'Dupont Elementary School',
  'Dupont Hadley Middle',
  'Dupont Manual High',
  'Dupont Middle',
  'Dupont Tyler Middle',
  'Dupre El',
  'Dupree Elementary - 02',
  'Dupree High School - 01',
  'Dupree Jr. High - 04',
  'Dupuy Alternative School',
  'Dupuyer School',
  'Duquesne El Sch',
  'Duquesne University',
  'Duran Junior High School',
  'Duran South',
  'Durand Area High School',
  'Durand Elem School',
  'Durand High School',
  'Durand Jr High School',
  'Durand Middle School',
  'Durand Middle/high',
  'Durand-eastman Intermediate School',
  'Duranes Elementary',
  'Durango Big Picture High School',
  'Durango High School',
  'Durango Transitional Learning Center',
  'Durant Elementary School',
  'Durant High School',
  'Durant Hs',
  'Durant Intermediate Es',
  'Durant Middle School',
  'Durant Ms',
  'Durant Public School',
  'Durant Road Elementary',
  'Durant Road Middle',
  'Durant Tuuri Mott School',
  'Durban Avenue Elementary School',
  'Durban Institute of Technology',
  'Durbin Creek Elementary School',
  'Durbin Elementary School',
  'Durfee Elementary',
  'Durfee Elementarymiddle School',
  'Durfee Magnet Elementary',
  'Durham Center',
  'Durham College',
  'Durham Community School',
  'Durham El',
  'Durham Elementary',
  'Durham Elementary School',
  'Durham High',
  'Durham Intermediate',
  'Durham Middle School',
  'Durham School Of The Arts',
  'Durham Technical Community College',
  "Durham's Performance Learning Center",
  'Durham-nockamixon El Sch',
  'Durhamville School',
  'Durkee El',
  'Durkin Park Elem School',
  'Durling Middle School',
  'Durrance Elementary',
  'Duryea El',
  'Duson Elementary School',
  'Dustin Es',
  'Dutch Broadway School',
  'Dutch Creek Elementary School',
  'Dutch Delta University',
  'Dutch Fork Elementary',
  'Dutch Fork High',
  'Dutch Fork Middle',
  'Dutch Hill Elementary',
  'Dutch Lane School',
  'Dutch Neck Elementary School',
  'Dutch Ridge El Sch',
  'Dutch University Institute for Art History (DUIA)',
  'Dutch Valley Elementary',
  'Dutchess Boces',
  'Dutchess Community College',
  'Dutchman Creek Middle',
  'Dutchtown Elementary School',
  'Dutchtown High',
  'Dutchtown High School',
  'Dutchtown Middle School',
  'Dutchtown Primary School',
  'Dutton Elementary School',
  'Dutton/brady Elementary',
  'Dutton/brady High School',
  'Dutton/brady Middle School',
  'Duval Academy',
  'Duval Charter At Baymeadows',
  'Duval Charter High School At Baymeadows',
  'Duval Charter Scholars Academy',
  'Duval Charter School At Coastal',
  'Duval Charter School At Flagler Center',
  'Duval Charter School At Mandarin',
  'Duval Charter School At Southside',
  'Duval Charter School At Westside',
  'Duval High',
  'Duval Mycroschool Of Integrated Academics And Technologies',
  'Duval Pk-8',
  'Duval Regional Juvenile Detention Center',
  'Duval Virtual Academy Franchise',
  'Duval Virtual Instruction (course Offerings)',
  'Duval Virtual Instruction Academy',
  'Duval Virtual Instruction Program',
  'Duvall Elementary School',
  'Duvalle Education Center',
  'Duveneck Elementary',
  'Duxberry Park Alternative Elementary School',
  'Duxbury High',
  'Duxbury Middle',
  'Duzce University',
  'Duzine School',
  'Dvorak Technology Acad Elem Sch',
  'Dwight Common School',
  'Dwight D Eisenhower El',
  'Dwight D Eisenhower Elem School',
  'Dwight D Eisenhower Elementary',
  'Dwight D Eisenhower Jhs',
  'Dwight D Eisenhower Middle School',
  'Dwight D. Eisenhower Academy Of Global Studies',
  'Dwight D. Eisenhower Elementary',
  'Dwight D. Eisenhower Elementary School',
  'Dwight D. Eisenhower Middle',
  'Dwight D. Eisenhower Middle School',
  'Dwight D. Eisenhower School',
  'Dwight Eisenhower Elementary',
  'Dwight Elementary School',
  'Dwight High School',
  'Dwight Middle',
  'Dwight Morrow High School/academies@englewood',
  'Dwight Rich School Of The Arts',
  'Dworshak Elementary School',
  'Dye Elementary School',
  'Dyer County High School',
  'Dyer El',
  'Dyer Elementary',
  'Dyer Elementary School',
  'Dyer Street Elementary',
  'Dyer-kelly Elementary',
  'Dyersburg High School',
  'Dyersburg Intermediate School',
  'Dyersburg Middle School',
  'Dyersburg Primary',
  'Dyersburg State Community College',
  'Dyersville Elementary School',
  'Dyess El',
  'Dyllis Springs Elementary',
  'Dynamic Family Services',
  'Dynard Elementary',
  'Dysart Elementary School',
  'Dysart High School',
  'Dysart Ischool',
  'Dysart-geneseo Elementary School',
  "Dzantik'i Heeni Middle School",
  'Dzil Libei Elementary School',
  'Dzilth-na-o-dith-hle Community School',
  'Dzit Dit Lool School Of Empowerment Action And Perseverance',
  'Dániel Berzsenyi Teacher Training College',
  'E A Jones El',
  'E A Kreft Primary School',
  'E A Lawhon El',
  'E A Mcallister Elementary School',
  'E A Powell Elementary School',
  'E B Aycock Middle',
  'E B Comstock Middle',
  'E B Frink Middle',
  'E B Reyna El',
  'E B Wilson',
  'E C Best Elementary School',
  'E C Brice El',
  'E C Mason El',
  'E C Nash School',
  'E Cook Elementary',
  'E E Miller Elementary',
  'E E Smith High',
  'E E Smith Middle',
  'E Ethel Little School',
  'E F Duvall 7-8',
  'E Grand Plains Elementary',
  'E H Gilbert El',
  'E J Blott Elementary School',
  'E J Harrington',
  'E J Hayes Elementary',
  'E J Moss Int',
  'E J Russell Elementary School',
  'E K Baker Elementary',
  'E K Powe Elementary',
  'E Lawson Brown Middle',
  'E M Baker School',
  'E M Crouthamel El Sch',
  'E M Rollins Elementary',
  'E M Yoder Elementary',
  'E MAKAALA SCHOOL',
  'E Melvin Honeycutt Elementary',
  'E Merle Smith Middle',
  'E N Peirce Ms',
  'E N Swett Elementary School',
  'E N White Elementary',
  'E O Coffman Middle School',
  'E O Young Jr Elementary',
  'E Omak Elementary',
  'E Otis Vaughn Middle School',
  'E P Ward Elementary School',
  'E R Crosby Elementary School',
  'E Ray El',
  'E Raymond Appleby Elementary School',
  'E Rudd Int',
  'E Somerville Community',
  'E T Wrenn Middle',
  'E Tallahatchie Voc Center',
  'E W Griffith Elementary School',
  'E W Thompson School',
  'E W Thurston Middle',
  'E-10 Nw El',
  'E-11 Central Nw El',
  'E-14 Modular Westside El',
  'E-17 Ne El Sandstone Ranch',
  "E-2 Central Ne El Don't Use",
  'E-cubed Academy',
  'E-institute At Avondale',
  'E-institute At Buckeye',
  'E-institute At Grovers',
  'E-institute At Metro',
  'E-institute At Surprise',
  'E-institute At Taylion',
  'E-institute At Union Hills',
  'E-school',
  'E. A. Cox Middle School',
  'E. A. G. L. E. Center High School - 07',
  'E. A. Hall Middle',
  'E. B. Morse Elementary',
  'E. Benjamin Oliver Elementary School',
  'E. C. Adams Middle School',
  'E. C. Goodwin Technical High School',
  'E. C. West Elementary School',
  'E. E. Lyon Elementary School',
  'E. E. ROGERS SDA SCHOOL',
  'E. G. Garrison Elementary',
  'E. H. Slaybaugh Elementary School',
  'E. Hale Curran Elementary',
  'E. J. Marshall Elementary',
  'E. K. Key Elementary School',
  'E. L. Musick Elementary',
  'E. L. Wright Middle',
  'E. Los Angeles Renaiss Acad At Esteban E. Torres High #2',
  'E. M. Grimmer Elementary',
  'E. M. Trout Es',
  'E. Neal Roberts Elementary',
  'E. O. Green Junior High',
  'E. O. Smith High School',
  'E. P. Foster Elementary',
  'E. P. Todd School',
  'E. Roy Bixby',
  'E. Russell Hicks Middle',
  'E. Ruth Sheldon Academy Of Innovative Learning',
  'E. S. Richardson Elementary School',
  'E. T. Booth Middle School',
  'E. W. Grove School',
  'E. Wilson Morrison Elementary',
  'E.A.P. Europäische Wirtschaftshochschule Berlin',
  'E.D. WHITE CATHOLIC HIGH SCHOOL',
  'E.H. MOTT LEARNING CENTER',
  'E.a. Harrold Elementary School',
  'E.a.g.l.e. Center - 09',
  'E.b. Ellington Elementary',
  'E.b. Stanley Middle',
  'E.b. Williams Stoner Hill Elementary School',
  'E.c. Glass High',
  'E.c.h.o. Charter School',
  'E.e. Jeter Elementary/middle',
  'E.h. Marsteller Middle',
  'E.j. Martinez Elementary',
  'E.l. Ross Elementary',
  'E.s.h. Greene Elementary',
  'E.w.f. Stirrup Elementary School',
  'E3 Civic High',
  'E3agle Academy',
  'EA YOUNG ACADEMY',
  'EAC MONTESSORI SCHOOL',
  'EAGER BEAVER CHILD CARE & LEARNING CENTER',
  'EAGLE ACADEMY',
  'EAGLE ACADEMY CHRISTIAN SCHOOL',
  'EAGLE ADVENTIST CHRISTIAN SCHOOL & PRESCHOOL',
  'EAGLE CHRISTIAN ACADEMY',
  'EAGLE CREEK ACADEMY',
  'EAGLE HEIGHTS CHRISTIAN ACADEMY',
  'EAGLE HEIGHTS CHRISTIAN SCHOOL',
  'EAGLE HILL SCHOOL',
  'EAGLE POINT CHRISTIAN ACADEMY',
  'EAGLE RIDGE CHRISTIAN SCHOOL',
  'EAGLE RIVER CHRISTIAN SCHOOL',
  'EAGLE ROCK MONTESSORI SCHOOL',
  'EAGLE ROCK SCHOOL & PDC',
  'EAGLE SCHGOOL',
  'EAGLE VIEW CHRISTIAN SCHOOL',
  'EAGLE WINGS ACADEMY',
  "EAGLE'S VIEW ACADEMY",
  'EAGLEBROOK SCHOOL',
  'EAGLES NEST AMISH SCHOOL',
  "EAGLES' RIDGE SCHOOL",
  'EARLY CHILDHOOD DEVELOP CENTER',
  'EARLY DISCOVERIES CDC',
  'EARLY DISCOVERY MONTESSORI SCHOOL',
  'EARLY EDUCATION CENTER',
  'EARLY LEARNING CENTER',
  'EARLY LEARNING CHILDRENS ACADEMY',
  'EARLY LEARNING COMMUNITY AT PACIFIC UNIVERSITY',
  'EARLY LEARNING MATTERS',
  'EARLY LRNG CTR AT GRACE MEMORIAL BAPTIST CHURCH',
  'EARLY VIEW ACADEMY OF EXCELLENCE',
  'EARLY WORLD MONTESSORI',
  'EARLY YEARS ACADEMY',
  'EARTHSONG COMMUNITY SCHOOL',
  'EASLEY CHRISTIAN SCHOOL',
  'EAST ACADEMY OF SCIENCE & TECHNOLOGY',
  'EAST AURORA MONTESSORI SCHOOL',
  'EAST BAY CAREER ACADEMY',
  'EAST BAY SCHOOL FOR BOYS',
  'EAST BAY WALDORF SCHOOL',
  'EAST BOSTON CENTRAL CATHOLIC SCHOOL',
  'EAST BURKE SCHOOL',
  'EAST CATHOLIC ELEMENTARY SCHOOL',
  'EAST CATHOLIC SCHOOL',
  'EAST COBB CHRISTIAN SCHOOL',
  'EAST DAYTON CHRISTIAN SCHOOL',
  'EAST DOVER SCHOOL',
  'EAST END ACADEMY',
  'EAST END ASSEMBLY & CHRISTIAN ACADEMY',
  'EAST END PRESCHOOL INC',
  'EAST FORK LUTHERAN GRADE SCHOOL AND HIGH SCHOOL',
  'EAST GATE CHRISTIAN ACADEMY',
  'EAST GORDON SCHOOL',
  'EAST HARLEM SCHOOL AT EXODUS HOUSE',
  'EAST HILL ACADEMY',
  'EAST HILL CHRISTIAN SCHOOL',
  'EAST LAKE ACADEMY',
  'EAST LINN CHRISTIAN ACADEMY',
  'EAST LIVERPOOL CHRISTIAN SCHOOL',
  'EAST LOCKE SCHOOL',
  'EAST MARTIN CHRISTIAN SCHOOL',
  'EAST MAYSVILLE',
  'EAST MEADOW',
  'EAST MEMORIAL CHRISTIAN ACADEMY',
  'EAST MIDWOOD HEBREW DAY SCHOOL',
  'EAST MOUNTAIN SCHOOL',
  'EAST MT EATON',
  'EAST NICKLE MINES AMISH SCHOOL',
  'EAST NORBROOK SCHOOL',
  'EAST ORCHARD CHRISTIAN ACADEMY',
  'EAST PALMYRA CHRISTIAN SCHOOL',
  'EAST PASCO ADVENTIST ACADEMY',
  'EAST PLAINS C/O NAOMI TROYER',
  'EAST RICHLAND CHRISTIAN SCHOOLS',
  'EAST RIDGEVILLE PAROCHIAL SCHOOL',
  'EAST SALEM 3RD SCHOOL',
  'EAST SALEM AMISH PAROCHIAL SCHOOL',
  'EAST SALEM PAROCHIAL SCHOOL',
  'EAST SHORE MONTESSORI SCHOOL',
  'EAST SIDE LEARNING CENTER',
  'EAST TEXAS CHRISTIAN ACADEMY',
  'EAST TEXAS CHRISTIAN SCHOOL',
  'EAST TN CHRISTIAN HOME & ACADEMY',
  'EAST VALLEY',
  'EAST VALLEY ACADEMY',
  'EAST VALLEY CHRISTIAN SCHOOL',
  'EAST VALLEY SDA SCHOOL',
  'EAST YODER SCHOOL',
  'EASTBROOK ACADEMY',
  'EASTER SEALS ACADEMY',
  'EASTER SEALS THERAPEUTIC SCHOOL',
  'EASTERN CHRISTIAN ELEMENTARY SCHOOL',
  'EASTERN CHRISTIAN HIGH SCHOOL',
  'EASTERN CHRISTIAN MIDDLE SCHOOL',
  'EASTERN HILLS CHRISTIAN ACADEMY',
  'EASTERN HILLS EDUCATIONAL BLDG',
  'EASTERN MENNONITE SCHOOL',
  'EASTERN ROAD CHRISTIAN ACADEMY',
  'EASTERSEALS ACADEMY',
  'EASTERSEALS LANCASTER SCHOOL',
  'EASTGATE CHRISTIAN ACADEMY',
  'EASTGATE LIFE ACADEMY',
  'EASTLAKE CHURCH CHILDREN CENTER',
  'EASTLAND CHRISTIAN SCHOOL',
  'EASTMONT BAPTIST KINDERGARTEN',
  'EASTMONT COMMUNITY SCHOOL',
  'EASTON COUNTRY DAY SCHOOL',
  'EASTRIDGE CHRISTIAN SCH-DAY CR',
  'EASTRIDGE CHRISTIAN SCHOOL',
  'EASTRIDGE PARK CHRISTIAN CHURCH',
  'EASTSIDE ACADEMY--REDMOND',
  'EASTSIDE CHRISTIAN SCHOOL',
  'EASTSIDE COLLEGE PREPARATORY SCHOOL',
  'EASTSIDE LUTHERAN SCHOOL',
  'EASTSIDE PREPARATORY SCHOOL',
  'EASTVIEW AMISH SCHOOL',
  'EASTWOOD SDA JR ACADEMY',
  'EATON ACADEMY',
  'EATON ARROWSMITH ACADEMY',
  'EBENEZER CHRISTIAN ACADEMY',
  'EBENEZER CHRISTIAN SCHOOL',
  'EBENEZER CHRISTIAN SCHOOL INC.',
  'EBENEZER DAY CARE',
  'EBENEZER PREPARATORY SCHOOL',
  'EBRIDGE MONTESSORI SCHOOL',
  'EBY CHIQUES SCHOOL',
  'ECAM - Institut Supérieur Industriel',
  'ECF KAYNE ERAS CENTER',
  'ECH SCHOOL AT EVERY CHILDS HOPE',
  'ECHELON ACADEMY',
  'ECHO RIDGE CHRISTIAN SCHOOL',
  'ECHO VALLEY',
  'ECLC OF NEW JERSEY',
  'ECOLE BILINGUE DE BERKELEY',
  'ECOLE BILINGUE DE LA NOUVELLE ORLEANS',
  'ECOLE CLAIRE FONTAINE',
  'ECOLE INTERNATIONALE DE NEW YORK (EINY)',
  'ECOLE NOTRE DAME DES VICTOIRES SCHOOL',
  'EDDLEMON ADVENTIST SCHOOL',
  'EDEN CHRISTIAN ACADEMY',
  'EDEN II SCHOOL',
  'EDEN MEADOW SCHOOL',
  'EDENVILLE SDA ELEMENTARY SCHOOL',
  'EDGARTON CHRISTIAN ACADEMY',
  'EDGEHOP CHRISTIAN ACADEMY',
  'EDGERTON CHRISTIAN ELEMENTARY',
  'EDGEWOOD ACADEMY',
  'EDGEWOOD CAMPUS SCHOOL',
  'EDGEWOOD CHRISTIAN SCHOOL',
  'EDGEWOOD HIGH SCHOOL OF THE SACRED HEART',
  'EDGEWOOD PREPRIMARY ACADEMY',
  'EDGEWOOD RANCH ACADEMY',
  'EDGEWOOD SCHOOL',
  'EDINBORO EARLY SCHOOL',
  'EDISON ACADEMICS',
  'EDISON ACADEMY',
  'EDISON HIGH SCHOOL',
  'EDISON LEARNING CORP.',
  'EDISONVILLE SCHOOL/AMOS FISHER, CHAIRMAN',
  'EDITH ROBINSON CHRISTIAN ACADEMY',
  'EDMUND BURKE ACADEMY',
  'EDMUND BURKE SCHOOL',
  'EDNA CHRISTIAN ACADEMY',
  'EDUCARE DAYCARE & LEARNING CENTER',
  'EDUCARE MONTESSORI',
  'EDUCARE PRESCHOOL',
  'EDUCATION & INTEGRATION SERVICES',
  'EDUCATION PLACE',
  'EDUCATION STATION PRESCHOOL',
  'EDUCATION WITH CARE FOR YOUNG CHILDREN',
  'EDUCATIONAL HARBOR',
  'EDUCATIONAL INST OHOLEI TORAH',
  'EDUCATIONAL PARTNERSHIP FOR INSTRUCTING CHILDREN',
  "EDUCATIVE CENTER EL REDENTOR'",
  'EDUCATORS INC PRIVATE CHRISTIAN SCHOOLS',
  'EDWARD AND JANE PECKWAS PREPARATORY ACADEMY',
  'EDWARD RD BAPTIST CHURCH KINDERGARTEN',
  'EF ACADEMY INTERNATIONAL BOARDING SCHOOL',
  'EFFICA SCHOOL OF MONTESSORI',
  'EFFORT CHRISTIAN SCHOOL AND PRESCHOOL',
  'EGREMONT SCHOOLS',
  'EHSAL - Europese Hogeschool Brussel',
  'EIGHT SQUARE SCHOOL',
  'EIHAB SCHOOL',
  'EINSTEIN ACADEMY',
  'EINSTEIN MONTESSORI ACADEMY',
  "EISEK HATORAH D'RACHMISTRIVKA",
  'EKKLESIA CHRISTIAN SCHOOL',
  'EL BETHEL CHRISTIAN ACADEMY',
  'EL CAJON SEVENTH-DAY ADVENTIST CHRISTIAN SCHOOL',
  'EL DORADO CHRISTIAN SCHOOL',
  'EL DORADO EMERSON PRIVATE SCHOOL',
  'EL DORADO MONTESSORI SCHOOL',
  'EL DORADO PRIVATE SCHOOL',
  'EL MONTECITO SCHOOL SAN ROQUE',
  'EL PASO ADVENTIST JR ACADEMY',
  'EL PASO BRIDGES ACADEMY',
  'EL PASO COUNTRY DAY SCHOOL',
  'EL PASO JEWISH ACADEMY',
  'EL RANCHO SCHOOL',
  'EL SOBRANTE CHRISTIAN SCHOOL',
  'EL VISTA BAPTIST ACADEMY',
  'ELAN VITAL MONTESSORI SCHOOL',
  'ELDER HIGH SCHOOL',
  'ELEANOR GERSON SN',
  'ELEGANT CHILD EARLY LEARNING CENTER',
  'ELEV/8 PREP ACADEMY',
  'ELEVATION PREPARATORY ACADEMY',
  'ELFERS CHRISTIAN SCHOOL',
  'ELIADA ACADEMY',
  'ELIJAH HOUSE ACADEMY',
  'ELIM CHRISTIAN SCHOOL',
  'ELIM JUNIOR ACADEMY & CHILD DEVELOPMENT CENTER',
  "ELIOT-PEARSON CHILDREN'S SCHOOL",
  'ELITE ACADEMY SCHOOL OF EXCELLENCE',
  "ELITE CHILDREN'S ACADEMY",
  'ELITE OPEN SCHOOL',
  'ELITE PREP',
  'ELITE PREPARATORY ACADEMY',
  'ELITE SCHOLARS STEAM ACADEMY',
  'ELIZABETH ACADEMY',
  'ELIZABETH ANN SETON MONTESSORI SCHOOL',
  'ELIZABETH IVES SCHOOL FOR SPECIAL CHILDREN',
  'ELIZABETH SETON HIGH SCHOOL',
  'ELIZABETH SETON MONTESSORI SCHOOL',
  'ELIZABETHTOWN CHRISTIAN ACADEMY',
  'ELK COUNTY CATHOLIC HIGH SCHOOL',
  'ELK CREEK SCHOOL',
  'ELK GROVE MONTESSORI SCHOOL',
  'ELK HILL HARAMBEE SCHOOL',
  'ELK VALLEY CHRISTIAN SCHOOL',
  'ELKHART ADVENTIST CHRISTIAN SCHOOL',
  'ELKHART CHRISTIAN ACADEMY',
  'ELKINS CHRISTIAN ACADEMY',
  'ELLENSBURG CHRISTIAN SCHOOL',
  'ELLICOTT BAPTIST SCHOOL',
  'ELLIOTT INSTITUTE',
  'ELLIOTT PRAIRIE CHRISTIAN SCHOOL',
  'ELLWOOD CHRISTIAN ACADEMY',
  'ELM GROVE LUTHERAN SCHOOL',
  'ELM RIDGE',
  'ELMHURST ACADDEMY OF EARLY LEARNING',
  'ELMHURST LEARNING CENTER',
  'ELMIRA BAPTIST CHRISTIAN ACADEMY',
  'ELMVIEW SCHOOL',
  'ELMWOOD COUNTRY DAY SCHOOL',
  'ELMWOOD PARK ACADEMY',
  'ELMWOOD-FRANKLIN SCHOOL',
  'ELTINGVILLE LUTHERAN SCHOOL',
  'ELVATON CHRISTIAN ACADEMY',
  'ELYRIA CATHOLIC HIGH SCHOOL',
  'ELYRIA CHRISTIAN SCHOOL',
  'EMAN SCHOOLS',
  'EMANUEL LUTHERAN SCHOOL',
  'EMANUEL PREPARATORY SCHOOL OF MATH AND SCIENCE',
  'EMANUEL REDEEMER LUTHERAN SCHOOL',
  'EMBERS ACADEMY',
  'EMEK HEBREW ACADEMY TEICHMAN FAMILY TORAH CENTER',
  'EMERALD CHRISTIAN ACADEMY',
  'EMERALD CITY SCHOOL',
  'EMERALD HEIGHTS ACADEMY',
  'EMERALD MOUNTAIN SCHOOL',
  'EMERGING MINDS MONTESSORI ACADEMY',
  'EMERSON ACADEMY',
  'EMERSON HIGH SCHOOL',
  'EMERSON PREPARATORY SCHOOL',
  'EMERSON SCHOOL',
  'EMERSON WALDORF SCHOOL',
  'EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória',
  'EMMA L MINNIS JUNIOR ACADEMY',
  'EMMA WILLARD SCHOOL',
  'EMMANUE BAPTIST ACADEMY',
  'EMMANUEL BAPTIST ACADEMY',
  'EMMANUEL BAPTIST CHRISTIAN SCHOOL',
  'EMMANUEL CHRISTIAN ACADEMY',
  'EMMANUEL CHRISTIAN SCHOOL',
  'EMMANUEL LUTHERAN SCHOOL',
  'EMMANUEL LUTHERN SCHOOL',
  'EMMANUEL UNITED METHODIST KINDERGARTEN',
  'EMMANUEL-FAITH LUTHERAN SCHOOL',
  'EMMANUEL-ST MICHAEL SCHOOL',
  'EMMAUS BAPTIST ACADEMY',
  'EMMAUS LUTHERAN SCHOOL',
  'EMMETSBURG CATHOLIC SCHOOL',
  'EMMORTON BAPTIST ACADEMY',
  'EMPORIA CHRISTIAN SCHOOL',
  'ENCHANTED CARE KIDS CAMPUS',
  "ENCHANTED CARE KIDS' CAMPUS",
  'ENCINO PRESBYTERIAN CHILDRENS CENTER',
  'ENDEAVOUR MIDDLE SCHOOL',
  'ENDLESS MOUNTAINS CHRISTIAN ACADEMY',
  'ENDLESS OPPORTUNITIES FOR CHILDREN',
  'ENDTIME CHRISTIAN SCHOOL OF EXCELLENCE',
  'ENFIELD CDT',
  'ENFIELD MONTESSORI SCHOOL',
  'ENGLE CREEK AMISH SCHOOL',
  'ENGLESIDE CHRISTIAN SCHOOL',
  'ENGLEWOOD BAPTIST WEEKDAY SCHOOL',
  'ENGLEWOOD CHRISTIAN SCHOOL',
  'ENGLISH CREEK ACADEMY',
  'ENGLISH ROSE DAY SCHOOL',
  'ENIC Telecom Lille 1',
  'ENLIGHTIUM ACADEMY',
  'ENOCH CHRISTIAN ACADEMY',
  'ENRICHED LEARNING CENTER',
  'ENTERPRISE SDA SCHOOL',
  "EPF Ecole d'Ingénieurs",
  'EPFL - EPF Lausanne',
  'EPHESUS ACADEMY',
  'EPHESUS JR ACADEMY',
  'EPHESUS JUNIOR ACADEMY',
  'EPHESUS SDA JUNIOR ACADEMY',
  'EPHRATA MENNONITE SCHOOL',
  'EPIPHANY ACADEMY',
  'EPIPHANY CATHEDRAL SCHOOL',
  'EPIPHANY CATHOLIC SCHOOL',
  'EPIPHANY DAY SCHOOL',
  'EPIPHANY EPISCOPAL SCHOOL',
  'EPIPHANY LUTHERAN SCHOOL',
  'EPIPHANY SCHOOL',
  'EPIPHANY SCHOOL OF CHARLOTTE',
  'EPISCOPAL DAY SCHOOL',
  'EPISCOPAL DAY SCHOOL OF CHRIST CHURCH PARISH',
  'EPISCOPAL HIGH SCHOOL',
  'EPISCOPAL SCHOOL OF ACADIANA',
  'EPISCOPAL SCHOOL OF JACKSONVILLE',
  'EPISCOPAL SCHOOL OF NASHVILLE',
  'EPSTEIN HILLEL SCHOOL',
  'EPWORTH CHRISTIAN SCHOOL',
  'EPWORTH PRESCHOOL & KINDERGARTEN',
  'ERIAL COMMUNITY CHURCH CHRISTIAN SCHOOL',
  'ERIE CATHOLIC PREPARATORY SCHOOL',
  'ERIE DAY SCHOOL',
  'ERIE FIRST CHRISTIAN ACADEMY',
  'ERSKINE ACADEMY',
  'ES INTERNATIONAL SCHOOL',
  'ESCAMBIA ACADEMY',
  'ESCAMBIA CHRISTIAN SCHOOL',
  'ESCANABA SDA SCHOOL',
  'ESCONDIDO ADVENTIST ACADEMY',
  'ESCONDIDO CHRISTIAN SCHOOL',
  'ESCUELA BILINGUE INTERNACIONAL',
  'ESCUELA DE GUADALUPE',
  'ESCUELA PLUS ELEMENTARY',
  'ESPERANZA ACADEMY',
  'ESPIRITO SANTO PAROCHIAL SCHOOL',
  'ESPIRITU SANTO CATHOLIC SCHOOL',
  'ESPREE CHILD LEARNING CENTER',
  'ESPRIT DE CORPS CENTER FOR LEARNING',
  'ESPRIT INTERNATIONAL SCHOOL',
  'ESSENTIAL MONTESSORI',
  'ESSEX VALLEY SCHOOL',
  'ESTABROOK CHRISTIAN SCHOOL',
  'ESTHER MILLER BAIS YAAKOV',
  "ESTHER'S MONTESSORI CENTER",
  "ESTHER'S NEST CHILDREN'S SCHOOL",
  "ESTHER'S SCHOOL",
  "ESTHER'S SCHOOL INC",
  "ESTHER'S SCHOOL KISSIMMEE",
  "ESTHER'S SCHOOL NEW PORT RICHEY",
  "ESTHER'S SCHOOL-HUDSON",
  'ESTHERS SCHOOL INC-PINELLAS',
  'ETERNITY CHRISTIAN SCHOOL',
  'ETHEL WALKER SCHOOL',
  'ETHICAL CULTURE FIELDSTON LOWER',
  'ETHICAL CULTURE FIELDSTON SCHOOLS',
  'ETHZ - ETH Zurich',
  'ETON ACADEMY',
  'ETON SCHOOL',
  'ETTA MAE ANDERSON PRIVATE SCHOOL',
  'EUCLID University',
  'EUGENE CHRISTIAN SCHOOL',
  'EUGENE MONTESSORI SCHOOL',
  'EUKARYA CHRISTIAN ACADEMY',
  'EUNICE HOLINESS ACADEMY',
  'EUREKA SCHOOL OF SANTA BARBARA',
  'EURO ACADEMY',
  'EUROPEAN ACADEMY OF EARLY EDUCATION',
  'EUROPEAN LEANING CENTER',
  'EVAN THOMAS INSTITUTE',
  'EVANGEL CHRISTIAN ACADEMY',
  'EVANGEL CHRISTIAN SCHOOL',
  'EVANGEL HEIGHTS CHRISTIAN ACADEMY',
  'EVANGEL HOUSE CHRISTIAN ACADEMY',
  'EVANGEL TEMPLE CHRISTIAN ACADEMY',
  'EVANGELICAL CHRISTIAN ACADEMY',
  'EVANGELICAL CHRISTIAN SCHOOL',
  'EVANGELICAL CHRISTIAN SCHOOL OF MEMPHIS INC.',
  'EVANGELICAL SCHOOL',
  'EVANS CHRISTIAN ACADEMY',
  'EVANSTON DAY SCHOOL',
  'EVANSVILLE CHRISTIAN SCHOOL',
  'EVANSVILLE DAY SCHOOL',
  'EVANSVILLE LUTHERAN SCHOOL',
  'EVEREST ACADEMY',
  'EVERETT CHRISTIAN SCHOOL',
  'EVERGREEN ACADEMY',
  'EVERGREEN CHRISTIAN PRIVATE SCHOOL',
  'EVERGREEN CHRISTIAN SCHOOL',
  'EVERGREEN COUNTRY DAY SCHOOL',
  'EVERGREEN LUTHERAN HIGH SCHOOL',
  'EVERGREEN LUTHERAN SCHOOL',
  'EVERGREEN MONTESSORI SCHOOL',
  'EVERGREEN MONTESSORI SCHOOL - AR',
  'EVERYDAY MAGIC INC.',
  'EVEVATIONS ACADEMY',
  'EVTEK University of Applied Sciences',
  'EWING ADVENTIST JUNIOR ACADEMY',
  'EWING MENNONITE SCHOOL',
  'EXCEL ADVENTIST ACADEMY',
  'EXCEL CHRISTIAN ACADEMY',
  'EXCEL CHRISTIAN SCHOOL',
  'EXCEL HIGH SCHOOL',
  'EXCEL INSTITUTE',
  'EXCELL PREPARATORY CENTER',
  'EXCELLENCE CHRISTIAN SCHOOL',
  'EXCELLENCE IN EDUCATION ACADEMY',
  'EXCELLENT COVENANT CHRISTIAN ACADEMY',
  'EXCELLENT MONTESSORI SCHOOL',
  'EXCELSIOR ACADEMY',
  'EXCELSIOR ELEMENTARY SCHOOL',
  'EXCELSIOR SCHOOL',
  'EXECUTIVE CHILD DEVELOPMENT CENTER',
  'EXPLORATIONS ACADEMY',
  'EXPLORATIONS PREPARATORY SCHOOL',
  'EXPLORE AND LEARN CHILDCARE',
  'EXPLORER WEST MIDDLE SCHOOL',
  'EYES EARS OF THE WORLD',
  'EZELL-HARDING CHRISTIAN SCHOOL',
  'EZRA ACADEMY',
  'EZZARD CHARLES SCHOOL',
  'Ea Johnson Memorial Hs',
  'Eachieve Academy - Wisconsin',
  'Eachieve Elementary',
  'Eads Elementary School',
  'Eads High School',
  'Eads Middle School',
  'Eagan Senior High',
  'Eager Street Academy',
  'Eagle Academy',
  'Eagle Academy Charter School',
  'Eagle Academy For Young Men',
  'Eagle Academy For Young Men Ii',
  'Eagle Academy For Young Men Iii',
  'Eagle Academy For Young Men Of Harlem',
  'Eagle Academy For Young Men Of Newark',
  'Eagle Academy Of Staten Island (the)',
  'Eagle Academy Pcs Capitol Riverfront',
  'Eagle Academy Pcs Congress Heights',
  'Eagle Arts Academy',
  'Eagle Bay School',
  'Eagle Bluff Elementary',
  'Eagle Butte High School - 01',
  'Eagle Butte Jr. High - 06',
  'Eagle Butte Primary - 02',
  'Eagle Butte Upper Elementary - 03',
  'Eagle Canyon Elementary',
  'Eagle Canyon High',
  'Eagle Cap Innovative Hs',
  'Eagle Charter School',
  'Eagle Cliffs Elementary',
  'Eagle College Prep',
  'Eagle College Prep Harmony',
  'Eagle College Prep Maryvale',
  'Eagle College Preparatory School- Mesa',
  'Eagle Community School',
  'Eagle County Charter Academy',
  'Eagle Creek',
  'Eagle Creek Elementary',
  'Eagle Creek Elementary School',
  'Eagle Crest Charter Academy',
  'Eagle Crest Elementary School',
  'Eagle Elementary',
  'Eagle Elementary School',
  'Eagle Elementary School Of The Arts',
  'Eagle Fox Park',
  'Eagle Glen Elementary',
  'Eagle Gravois Park',
  'Eagle Grove Elementary',
  'Eagle Grove High School',
  'Eagle Harbor High School',
  'Eagle Heights El',
  'Eagle Heights Elem. School',
  'Eagle Heights Elementary School',
  'Eagle Heights Spanish Immersion',
  'Eagle High School',
  'Eagle Hill Middle School',
  'Eagle Hills Elementary School',
  'Eagle Lake Elementary',
  'Eagle Lake Elementary School',
  'Eagle Lake Int',
  'Eagle Lake Pri',
  'Eagle Learning Center',
  'Eagle Middle School',
  'Eagle Mountain El',
  'Eagle Mountain Elementary',
  'Eagle Mountain Magnet School',
  'Eagle Nest Elementary',
  'Eagle Nest Middle',
  'Eagle Park Community School',
  'Eagle Pass High School',
  'Eagle Pass J H',
  'Eagle Peak At Pratt',
  'Eagle Peak Middle',
  'Eagle Peak Montessori',
  'Eagle Point Elementary',
  'Eagle Point Elementary School',
  'Eagle Point High School',
  'Eagle Point Middle School',
  'Eagle Point School',
  'Eagle Pointe Elementary School',
  'Eagle Prairie Elementary',
  'Eagle Ranch Elementary',
  'Eagle Ridge Academy',
  'Eagle Ridge Academy Charter School',
  'Eagle Ridge Academy Lower School',
  'Eagle Ridge El',
  'Eagle Ridge Elementary School',
  'Eagle Ridge High School',
  'Eagle Ridge Middle',
  'Eagle Ridge Middle School',
  'Eagle Ridge School',
  'Eagle River Elementary',
  'Eagle River High School',
  'Eagle Rock Elementary',
  'Eagle Rock Elementary School',
  'Eagle Rock High',
  'Eagle Rock Middle School',
  'Eagle Rock Multiage School',
  'Eagle School Intermediate',
  'Eagle Springs El',
  'Eagle Springs Elementary',
  'Eagle Summit Academy',
  'Eagle Summit Community Day',
  'Eagle Tower Grove East',
  'Eagle Tower Grove South',
  'Eagle Tree Continuation',
  'Eagle Valley Early College High School',
  'Eagle Valley Elementary',
  'Eagle Valley Elementary School',
  'Eagle Valley High School',
  'Eagle Valley Middle School',
  'Eagle Valley School',
  'Eagle Valley Secondary',
  'Eagle View',
  'Eagle View El Sch',
  'Eagle View Elementary',
  'Eagle View Ms',
  'Eagle View School',
  "Eagle's Landing High School",
  "Eagle's Landing Middle School",
  "Eagle's View Elementary School",
  'Eaglecrest High School',
  'Eaglecrest School',
  'Eagleridge Elementary',
  'Eagleridge Enrichment Program',
  'Eagleridge High School',
  'Eagles Elementary School',
  'Eagles Landing Middle School',
  'Eagles Nest Academy',
  'Eagles Nest Charter Academy',
  'Eagles Nest Elementary',
  'Eagles Nest Middle Charter School',
  "Eagles' Wings Virtual Charter School",
  'Eagleside Elementary School',
  'Eagleswood Elementary School',
  'Eagleton Elementary',
  'Eagleton Elementary School',
  'Eagleton Middle School',
  'Eagletown Es',
  'Eagletown Hs',
  'Eagleview Elementary School',
  'Eagleview Middle School',
  'Eagleville Charter School',
  'Eagleville El Sch',
  'Eagleville School',
  'Eakin Elementary',
  'Eakin Elementary School',
  'Eames Way School',
  'Eanes El',
  'Earhart Elem Opt For Knowl School',
  'Earhart Elementarymiddle School',
  'Earhart Environ Magnet Elem',
  'Earl & Lottie Wolford El',
  'Earl B Lundy Elementary School',
  'Earl Boyles Elementary',
  'Earl C Mcgraw School',
  'Earl E. Edmondson Elementary',
  'Earl El Sch',
  'Earl F Carr School',
  'Earl F. Johnson High (continuation)',
  'Earl H Hanson Elem School',
  'Earl Harris Es',
  'Earl L Vandermeulen High School',
  'Earl Lawson Elementary',
  'Earl Legette Elementary',
  'Earl Nance Sr. Elem.',
  'Earl Nash Elementary School',
  'Earl Pritchett School',
  'Earl Travillion Attendance Center',
  'Earl Warren Elementary',
  'Earl Warren Junior High',
  'Earl Warren Middle',
  'Earl Wooster High School',
  'Earle B. Wood Middle',
  'Earle Brown Elementary',
  'Earle C Davis Primary',
  'Earle E. Williams Middle',
  'Earle Elem School',
  'Earle Elementary School',
  'Earle High School',
  'Earle Jamieson Educational Options',
  'Earlham College',
  'Earlham Elementary School',
  'Earlham Middle School',
  'Earlham Senior High School',
  'Earlimart Community Day',
  'Earlimart Elementary',
  'Earlimart Middle',
  'Earlington Elementary School',
  'Earlington Heights Elementary School',
  'Earlsboro Es',
  'Earlsboro Hs',
  'Earlville Elem School',
  'Earlville Elementary School',
  'Earlville Jr/sr High School',
  'Early Beginnings Academy',
  'Early Child. Education',
  'Early Child. Education Ctr.',
  'Early Child. Family Educ. Ctr.',
  'Early Child. Special Ed. Ctr.',
  'Early Childhood',
  'Early Childhood Academy Pcs',
  'Early Childhood And Pat Center',
  'Early Childhood Assessment',
  'Early Childhood Assessment/special',
  'Early Childhood Building',
  'Early Childhood Campus - Cholla',
  'Early Childhood Center',
  'Early Childhood Center 1',
  'Early Childhood Center 2',
  'Early Childhood Center At Educare - Indian Hill',
  'Early Childhood Center At Educare - Kellom',
  'Early Childhood Center At Gateway',
  'Early Childhood Center At The Doyle School',
  'Early Childhood Center At Yates',
  'Early Childhood Cntr',
  'Early Childhood Ctr',
  'Early Childhood Ctr.',
  'Early Childhood Dev Cntr',
  'Early Childhood Development Center',
  'Early Childhood Development Center (ecdc)',
  'Early Childhood Development Ctr',
  'Early Childhood Ed Center West',
  'Early Childhood Edu Ctr',
  'Early Childhood Educ. Ctr.',
  'Early Childhood Education',
  'Early Childhood Education And Family Center',
  'Early Childhood Education Center',
  'Early Childhood Education Center - Carver Campus',
  'Early Childhood Elementary School',
  'Early Childhood Facility',
  'Early Childhood Family Center',
  'Early Childhood Gsrp',
  'Early Childhood Intervention',
  'Early Childhood Intervention Serv',
  'Early Childhood Lc',
  'Early Childhood Learning Center',
  'Early Childhood Learning Cntr',
  'Early Childhood Learning Ctr',
  'Early Childhood Learning Services',
  'Early Childhood Lrn Ctr',
  'Early Childhood Program',
  'Early Childhood Programswsesd',
  'Early Childhood School',
  'Early Childhood School - Central',
  'Early Childhood School - Gbc',
  'Early Childhood School - North',
  'Early Childhood School - South',
  'Early Childhood School - West',
  'Early Childhood Special Ed',
  'Early Childhood Special Ed Program',
  'Early Childhood Special Ed.',
  'Early Childhood Special Education',
  'Early Childhood Special Education (',
  'Early Childhood Special Education S',
  'Early Childhood Special Education School',
  'Early Childhood Year Round',
  'Early Childhood/special Education',
  'Early Childhoodgsrp',
  'Early College',
  'Early College Academy',
  'Early College Academy Of Columbus At Waverly Terrace',
  'Early College Academy-la Trade Tech College',
  'Early College Alliance',
  'Early College And Career Center',
  'Early College At Guilford',
  'Early College At Montcalm Community College',
  'Early College East High',
  'Early College H S',
  'Early College H S At Midland College',
  'Early College High',
  'Early College High School',
  'Early College High School At Carver',
  'Early College High School At Delaware State University',
  'Early College Of Arvada',
  'Early College Of Forsyth Co',
  'Early College Opportunities',
  'Early County Elementary School',
  'Early County High School',
  'Early County Middle School',
  'Early Ed Battle Creek',
  'Early Ed Eastern Heights',
  'Early Ed Frost Lake',
  'Early Ed Highland',
  'Early Ed John A. Johnson',
  'Early Ed Monroe',
  'Early Ed Obama',
  'Early Education',
  'Early Education - Birth To Three',
  'Early Education Ben Mays/rondo',
  'Early Education Bridge View',
  'Early Education Center',
  'Early Education Como',
  'Early Education Daytons Bluff',
  'Early Education Four Seasons',
  'Early Education Galtier',
  'Early Education Heights Comm',
  'Early Education Mississippi Ca',
  'Early Education Phalen Lake',
  'Early Education Program & Services',
  'Early Education Services',
  'Early El',
  'Early H S',
  'Early Head Start Nicholson',
  'Early Head Start Rosa Street',
  'Early Headstart',
  'Early Intervention/homebound',
  'Early Lansing Community College Head Start',
  'Early Learning Academy',
  'Early Learning Academy At Duval',
  'Early Learning Center',
  'Early Learning Center (0-3) At Gateway',
  'Early Learning Center (0-3) At Skinner',
  'Early Learning Center At New Legacy Charter School',
  'Early Learning Center-george Earle',
  'Early Learning In Fond Du Lac',
  'Early Learning Preschool',
  'Early Learning Program',
  'Early Learning Resource Center',
  'Early Learning Village',
  'Early Learning Wing',
  'Early Light Academy At Daybreak',
  'Early Middle',
  'Early On',
  'Early On Special Education',
  'Early Onwsesd',
  'Early Pri',
  'Early School Program',
  'Early Step Pre-school',
  'Early Wonders Preschool Partnership',
  'Earlywine Es',
  'Earnest O Woods Int',
  'Earnesteen Milstead Middle',
  'Earth School',
  'Ease',
  'Easley Elementary',
  'Easley High',
  'Eason Elementary',
  'East',
  'East Academy',
  'East Africa University Bosaso',
  'East Aiken School Of The Arts',
  'East Albemarle Elementary',
  'East Alexander Middle',
  'East Allegheny Jshs',
  'East Allen University',
  'East Alternative',
  'East Alton Middle School',
  'East Alton-wood River High Sch',
  'East Amory School',
  'East Amwell Township',
  'East Antioch Elem',
  'East Arbor Charter Academy',
  'East Arcadia Elementary',
  'East Area',
  'East Area Adult School',
  'East Arkansas Career Center',
  'East Arkansas Community College',
  'East Ascension High School',
  'East Auburn Community School',
  'East Aurora High School',
  'East Aurora Hs Rssp',
  'East Aurora Middle School',
  'East Avenue Elementary',
  'East Avenue Middle',
  'East Bakersfield High',
  'East Bank Middle School',
  'East Bay Arts High',
  'East Bay High School',
  'East Bay Innovation Academy',
  'East Beauregard Elementary School',
  'East Beauregard High School',
  'East Belfast School',
  'East Bend Elementary',
  'East Bernard El',
  'East Bernard H S',
  'East Bernard J H',
  'East Bernstadt Elementary School',
  'East Bethel Community School',
  'East Bladen High',
  'East Boston Early Childhood Center',
  'East Boston High',
  'East Bradford El Sch',
  'East Brainerd Elementary',
  'East Branch Preparatory Academydbawright Preparatory Academy',
  'East Bridge Academy Of Excellence',
  'East Bridgewater Jr./sr. High School',
  'East Broad Street School',
  'East Broadway Elementary School',
  'East Broadway School',
  'East Bronx Academy For The Future',
  'East Brook Elementary School',
  'East Brook Middle School',
  'East Brookfield Elementary',
  'East Brooklyn Community High School',
  'East Brunswick High School',
  'East Buchanan Elem.',
  'East Buchanan Elementary School',
  'East Buchanan High',
  'East Buchanan High School',
  'East Buchanan Middle',
  'East Buchanan Middle School',
  'East Burke High',
  'East Burke Middle',
  'East Calloway Elementary School',
  'East Campus Elementary School',
  'East Canton Elementary School',
  'East Canton High School',
  'East Canton Middle School',
  'East Canyon Elementary School',
  'East Career Technical Academy',
  'East Carolina Laboratory School',
  'East Carolina University',
  'East Carter Co. R-ii Elem.',
  'East Carter Co. R-ii High',
  'East Carter Co. R-ii Middle',
  'East Carter County High School',
  'East Carter Middle School',
  'East Carteret High',
  'East Cary Middle',
  'East Central Alternative School',
  'East Central Choice Progam',
  'East Central College',
  'East Central Community College',
  'East Central Dev Ctr',
  'East Central Elementary',
  'East Central Elementary School',
  'East Central H S',
  'East Central Heritage Middle',
  'East Central High School',
  'East Central Hs',
  'East Central Jhs',
  'East Central Lower Elementary',
  'East Central Middle School',
  'East Central Senior Secondary',
  'East Central Spec Ed Unit',
  'East Central University',
  'East Central Upper Elementary',
  'East Chambers El',
  'East Chambers H S',
  'East Chambers Intermediate',
  'East Chambers J H',
  'East Chapel Hill High',
  'East Cheatham Elementary',
  'East Chester Elementary School',
  'East Chicago Central High School',
  'East Chicago Lighthouse',
  'East Chicago Urban Enterprise Acad',
  'East China Administration',
  'East China Jiao Tong University',
  'East China Normal University',
  'East China University of Science and Technology',
  'East Clarendon Middle-high',
  'East Clark',
  'East Clayton Elementary',
  'East Clayton Elementary School',
  'East Cliff El',
  'East Clinton High School',
  'East Clinton Junior High School',
  'East Cobb Middle School',
  'East Coffee Elementary',
  'East Coldenham Elementary School',
  'East Coloma - Nelson Elem Sch',
  'East Columbus Elementary School',
  'East Columbus High',
  'East Columbus Magnet Academy',
  'East Community High School',
  'East Community Learning Center',
  'East Cooper Montessori Charter',
  'East County Elementary Special Education',
  'East Coventry El Sch',
  'East Coweta High School',
  'East Coweta Middle School',
  'East Crawford Elementary School',
  'East Dale Elementary School',
  'East Davidson High',
  'East Dekalb Special Education Center',
  'East Delta University',
  'East Derry El Sch',
  'East Derry Memorial Elementary School',
  'East Dover Elementary School',
  'East Dubuque Elem School',
  'East Dubuque High School',
  'East Dubuque Jr High',
  'East Duplin High',
  'East Early Childhood Center',
  'East Early Childhood Ctr',
  'East Early College H S',
  'East El',
  'East El Sch',
  'East Elem.',
  'East Elementary',
  'East Elementary Annex',
  'East Elementary School',
  'East Elmhurst Community School',
  'East End Community School',
  'East End Elementary',
  'East End Elementary School',
  'East End Intermediate School',
  'East End Preparatory School',
  'East End School',
  'East English Village Preparatory Academy',
  'East Evergreen School',
  'East Fairhaven',
  'East Fairmont High School',
  'East Fairmont Middle School',
  'East Fairview Elementary School',
  'East Fallowfield El Sch',
  'East Falmouth Elementary',
  'East Fannin Elementary School',
  'East Farms School',
  'East Feliciana High School',
  'East Feliciana Middle School',
  'East Feliciana Parish Enrichment Academy',
  'East Flatbush Community Research School',
  'East Flora Elementary',
  'East Fordham Academy For The Arts',
  'East Forest El Sch',
  'East Forest Jshs',
  'East Forsyth High',
  'East Forsyth Middle School',
  'East Fort Worth Montessori Academy',
  'East Franklin Elementary',
  'East Franklin Junior High School',
  'East Gadsden High School',
  'East Garfield Elementary School',
  'East Garner Elementary',
  'East Garner Middle',
  'East Gaston High',
  'East Glacier Park School',
  'East Gloucester Elementary',
  'East Goshen El Sch',
  'East Granby High School',
  'East Granby Middle School',
  'East Grand Forks Senior High',
  'East Grand Middle School',
  'East Grand Preparatory Academy',
  'East Grand Rapids High School',
  'East Grand Rapids Middle School',
  'East Grand School',
  'East Grays Harbor High School',
  'East Grays Harbor Open Doors',
  'East Greenwich High',
  'East Gresham Elementary School',
  'East Haddam Elementary School',
  'East Hall High School',
  'East Hall Middle School',
  'East Hamilton School',
  'East Hampton High School',
  'East Hampton Middle School',
  'East Hancock Elementary School',
  'East Handley El',
  'East Hanover El Sch',
  'East Hanover Middle School',
  'East Hardin Elementary',
  'East Hardin Middle School',
  'East Hardy Early/middle School',
  'East Hardy High School',
  'East Harlem Scholars Academy Charter School',
  'East Harlem Scholars Academy Charter School Ii',
  'East Hartford High School',
  'East Hartford Middle School',
  'East Haven High School',
  'East Heights Elementary School',
  'East Henderson High',
  'East Heritage Elementary',
  'East Hickman Elementary',
  'East Hickman High School',
  'East Hickman Intermediate School',
  'East Hickman Middle School',
  'East High',
  'East High (continuation)',
  'East High School',
  'East High Street El Sch',
  'East Hill Elementary School',
  'East Hill School',
  'East Hills Academy',
  'East Hills Elementary School',
  'East Hills Middle School',
  'East Hills Ms',
  'East Hoke Middle',
  'East Hollywood High',
  'East Houma Elementary School',
  'East Hs',
  'East Iberville Elementary/high School',
  'East Intermediate School',
  'East Iredell Elementary',
  'East Iredell Middle',
  'East Irondequoit Middle School',
  'East Islip High School',
  'East Islip Middle School',
  'East Jackson Comprehensive High School',
  'East Jackson Elementary',
  'East Jackson Elementary School',
  'East Jackson High School',
  'East Jackson Middle School',
  'East Jay County Middle School',
  'East Jefferson High School',
  'East Jessamine High School',
  'East Jessamine Middle School',
  'East Jones Elementary School',
  'East Jordan Alternative High School',
  'East Jordan Elementary School',
  'East Jordan Middlehigh School',
  'East Jr. High School',
  'East Juniata Jshs',
  'East Junior High',
  'East Junior High School',
  'East Kazakhstan State University',
  'East Kazakstan State Technical University',
  'East Kelloggsville School',
  'East Kemper Attendance Center',
  'East Kentwood Freshman Campus',
  'East Kentwood High School',
  'East Kingston Elementary School',
  'East Knox Elementary',
  'East Knox Elementary School',
  'East Knox Junior/senior High School',
  'East Lake',
  'East Lake Academy Of Fine Arts',
  'East Lake Elementary',
  'East Lake Elementary School',
  'East Lake High School',
  'East Lake Middle School Academy Of Engineering',
  'East Lansdowne El Sch',
  'East Lansing High School',
  'East Laurens Elementary School',
  'East Laurens High School',
  'East Laurens Middle School',
  'East Laurens Primary School',
  'East Lawrence Elementary School',
  'East Lawrence High School',
  'East Lawrence Middle School',
  'East Layton School',
  'East Lee Campus',
  'East Lee County High School',
  'East Lee Middle',
  'East Leesville Elementary School',
  'East Leonard School',
  'East Leroy Elementary School',
  'East Leyden High School',
  'East Light Academy',
  'East Limestone High School',
  'East Lincoln Elementary',
  'East Lincoln High',
  'East Lincoln Middle',
  'East Linden Elementary School',
  'East Link Academy',
  'East Liverpool High School',
  'East Liverpool Junior High',
  'East Longmeadow High',
  'East Los Angeles College',
  'East Los Angeles County Community',
  'East Lower School',
  'East Lyme High School',
  'East Lyme Middle School',
  'East Lynn Elementary School',
  'East Lynne Elem.',
  'East Marion Elementary School',
  'East Marion High School',
  'East Marshall Elementary School',
  'East Marshall Middle School',
  'East Marshall Senior High School',
  'East Mcdowell Middle',
  'East Meadow',
  'East Meadow High School',
  'East Meadows School',
  'East Mecklenburg High',
  'East Middle',
  'East Middle Alternative (emap)',
  'East Middle School',
  'East Middle School - 30',
  'East Midvale School',
  'East Millbrook Middle',
  'East Mills Elementary School',
  'East Mills Jr/sr High School',
  'East Millsboro Elementary School',
  'East Milton Elementary School',
  'East Minico Middle School',
  'East Mississippi Community College',
  'East Moline Education Center',
  'East Montana Middle',
  'East Montgomery Elementary',
  'East Montgomery High',
  'East Montpelier Elementary School',
  'East Mooresville Intermediate',
  'East Moriches Elementary School',
  'East Moriches School',
  'East Mountain High School',
  'East Ms',
  'East Muskingum Middle School',
  'East Naples Middle School',
  'East Nashville Magnet High School',
  'East Nashville Middle',
  'East Natchitoches Elementary & Middle School',
  'East New York Elementary School Of Excellence',
  'East New York Family Academy',
  'East New York Middle School Of Excellence',
  'East Newark Public School',
  'East Newton Elementary School',
  'East Newton High',
  'East Nicolaus High',
  'East Noble High School',
  'East Noble Middle School',
  'East Norriton Ms',
  'East North Street Academy',
  'East Northport Middle School',
  'East Oakland Leadership Academy',
  'East Oakland Pride Elementary',
  'East Oakview Elementary School',
  'East Oldham Middle School',
  'East Olive Elementary School',
  'East Olympia Elementary',
  'East Orange Campus High School',
  'East Orange Community Charter School',
  'East Orange Stem Academy High School',
  'East Orient Elementary School',
  'East Ouchita Middle School',
  'East Palestine Elementary School',
  'East Palestine High School',
  'East Palestine Middle School',
  'East Palo Alto Academy',
  'East Park Elementary School',
  'East Paulding High School',
  'East Paulding Middle School',
  'East Penn El Sch',
  'East Pennsboro Area Ms',
  'East Pennsboro Area Shs',
  'East Pennsboro El Sch',
  'East Peoria High School',
  'East Perry County Elementary School',
  'East Petersburg El Sch',
  'East Picacho Elementary',
  'East Pike El Sch',
  'East Pikeland El Sch',
  'East Poinsett Co. High School',
  'East Point Academy',
  'East Point El',
  'East Pointe Elementary School',
  'East Port Orchard Elementary',
  'East Portsmouth Elementary',
  'East Prairie Elem School',
  'East Prairie High',
  'East Prairie Jr. High',
  'East Prairie Middle School',
  'East Preparatory Academy',
  'East Providence Career Tech',
  'East Providence High',
  'East Quogue School',
  'East Ramapo Early Childhood Center At Kakiat',
  'East Range Academy Of Tech-science',
  'East Range Ii Csd School',
  'East Range Sec. Technical Center',
  'East Recep/diag Corr Cent/acad',
  'East Recep/diag Corr Cent/diag',
  'East Ridge El',
  'East Ridge Elementary',
  'East Ridge High School',
  'East Ridge Middle School',
  'East River High',
  'East Robertson Elementary',
  'East Robertson High School',
  'East Robeson Primary',
  'East Rochester Elementary School',
  'East Rochester Junior-senior High School',
  'East Rochester School',
  'East Rock Community Magnet School',
  'East Rockaway Junior-senior High School',
  'East Rockford Middle School',
  'East Rockingham Elementary',
  'East Rockingham High',
  'East Rowan High',
  'East Rutherford High School',
  'East Rutherford Middle School',
  'East Sac County Elementary Sac Building',
  'East Sac County Elementary Wall Lake',
  'East Sac County High School',
  'East Sac County Middle School',
  'East Salem Elementary',
  'East Salisbury Elementary',
  'East Samford School',
  'East San Gabriel Valley Rop',
  'East San Jose Elementary',
  'East Sandy School',
  'East School',
  'East Shore Leadership Academy',
  'East Shore Middle School',
  'East Shore Online',
  'East Side Charter School',
  'East Side Community School',
  'East Side El',
  'East Side El Sch',
  'East Side Elementary',
  'East Side Elementary School',
  'East Side Elementray School-ps 267',
  'East Side Es',
  'East Side High School',
  'East Side Int',
  'East Side Intermediate School',
  'East Side Middle School',
  'East Side School',
  'East Silver Spring Elementary',
  'East Smiths Station Elementary School',
  'East Somalia University',
  'East St Louis Senior High School',
  'East St Louis-lincoln Middle Sch',
  'East St. John Elementary School',
  'East St. John High School',
  'East Stanislaus High',
  'East Street Elementary School',
  'East Street School',
  'East Stroudsburg El Sch',
  'East Stroudsburg Shs North',
  'East Stroudsburg Shs South',
  'East Stroudsburg State University of Pennsylvania',
  'East Sunflower School',
  'East Surry High',
  'East Sutherlin Primary School',
  'East Syracuse Elementary School',
  'East Syracuse Minoa Central High School',
  'East Tate Elementary School',
  'East Taunton Elementary',
  'East Technical High School',
  'East Tennessee State University',
  'East Terrell Hills El',
  'East Texas Montessori Prep Academy',
  'East Thibodaux Middle School',
  'East Tipp Middle School',
  'East Tps - Prek - Reg Ed',
  'East Troy High',
  'East Troy Middle',
  'East Ukrainian National University',
  'East Union Attendance Center',
  'East Union Elementary',
  'East Union High',
  'East Union Intrmd Sch',
  'East Union Middle',
  'East Union Middle-high School',
  'East Upper High School',
  'East Valley Academy',
  'East Valley Central Middle School',
  'East Valley Elementary',
  'East Valley Elementary School',
  'East Valley High School',
  'East Valley Middle School',
  'East Valley Senior High',
  'East View Academy',
  'East View Academy School',
  'East View Elem School',
  'East View Elementary School',
  'East View H S',
  'East Village Community School (the)',
  'East Village High',
  'East Vincent El Sch',
  'East Voyager Academy',
  'East Wake Academy',
  'East Wake High',
  'East Wake Middle',
  'East Ward El',
  'East Ward El Sch',
  'East Washington Academy',
  'East Washington Elementary School',
  'East Washington Middle School',
  'East Webster Elementary School',
  'East Webster High School',
  'East West University',
  'East Whittier Middle',
  'East Wilkes High',
  'East Wilkes Middle',
  'East Williamsburg Scholars Academy',
  'East Windsor High School',
  'East Windsor Middle School',
  'East Woods Elementary School',
  'East Yancey Middle',
  'East York El Sch',
  'East-Siberian State Institute of Culture',
  'East-Siberian State University',
  'East-Siberian State University of Technology',
  'East-West University Mohakhali',
  'East-west School Of International Studies',
  'Eastampton Township Community School',
  'Eastbluff Elementary',
  'Eastbrook Elementary School',
  'Eastbrook High School',
  'Eastbrook Junior High School',
  'Eastbrook Middle School',
  'Eastbrook North Elementary',
  'Eastbrook South Elementary',
  'Eastbury School',
  'Eastchester Middle School',
  'Eastchester Senior High School',
  'Eastconn Special Education',
  'Easter Seals',
  'Easter Seals Child Development Center Daytona Beach',
  'Easter Seals Florida Inc.',
  'Easterbrook Discovery',
  'Easterby Elementary',
  'Easterling Primary',
  'Easterly Parkway El Sch',
  'Eastern Alamance High',
  'Eastern Arizona College',
  'Eastern Asia University',
  'Eastern Center For Arts & Technology',
  'Eastern Connecticut State University',
  'Eastern Elementary',
  'Eastern Elementary School',
  'Eastern Elementary/middle',
  'Eastern Gateway Community College',
  'Eastern Greenbrier Middle School',
  'Eastern Greene Elementary School',
  'Eastern Greene High School',
  'Eastern Greene Middle School',
  'Eastern Guilford High',
  'Eastern Guilford Middle',
  'Eastern Hancock Elementary School',
  'Eastern Hancock High School',
  'Eastern Hancock Middle School',
  'Eastern Heights Elementary',
  'Eastern Heights Middle School',
  'Eastern High',
  'Eastern High School',
  'Eastern Highlands Region (ehr)',
  'Eastern Hills El',
  'Eastern Hills H S',
  'Eastern Hills Middle',
  'Eastern Hs',
  'Eastern Idaho Technical College',
  'Eastern Illinois University',
  'Eastern Intermediate Elementary School',
  'Eastern Iowa Community College District',
  'Eastern Kentucky University',
  'Eastern Ky Education Center',
  'Eastern La Mental System',
  'Eastern Learning Center',
  'Eastern Lebanon Co Ms',
  'Eastern Lebanon Co Shs',
  'Eastern Maine Community College',
  'Eastern Mediterranean University',
  'Eastern Mennonite University',
  'Eastern Michigan University',
  'Eastern Middle School',
  'Eastern Montgomery Elementary',
  'Eastern Montgomery High',
  'Eastern Ms',
  'Eastern Nc School For The Deaf',
  'Eastern New Mexico University',
  'Eastern New Mexico University-​Roswell Campus',
  'Eastern Oklahoma State College',
  'Eastern Oregon State College',
  'Eastern Primary Elementary School',
  'Eastern Pulaski Elementary School',
  'Eastern Randolph High',
  'Eastern Regional High School',
  'Eastern Shore Community College',
  'Eastern Sky Community Charter School',
  'Eastern St. Hosp. Ed. Prg',
  'Eastern Suffolk Boces',
  'Eastern Technical High School',
  'Eastern University',
  'Eastern University Academy Charter Schoo',
  'Eastern University of Sri Lanka',
  'Eastern View High',
  'Eastern Washington University',
  'Eastern Wayne Elementary',
  'Eastern Wayne High',
  'Eastern Wayne Middle',
  'Eastern West Virginia Community and Technical College',
  'Eastern Westmoreland Ctc',
  'Eastern Wright Program',
  'Eastern Wyoming College',
  'Eastern York Hs',
  'Eastern York Ms',
  'Eastfield College',
  'Eastfield Global Magnet',
  'Eastford Elementary School',
  'Eastford Road',
  'Eastgate Elementary School',
  'Eastgate Middle',
  'Eastgate School',
  'Eastham Elementary',
  'Easthampton High',
  'Easthaven Elementary School',
  'Eastin Arcola',
  'Eastlake Elementary',
  'Eastlake Elementary School',
  'Eastlake Es',
  'Eastlake H S',
  'Eastlake High',
  'Eastlake High School',
  'Eastlake Middle',
  'Eastlake Middle School',
  'Eastlake School',
  'Eastland Care Campus',
  'Eastland Career Center',
  'Eastland Elementary School',
  'Eastland H S',
  'Eastland Jr/sr High School',
  'Eastland Middle',
  'Eastland Middle School',
  'Eastlawn Elem School',
  'Eastlawn Elementary',
  'Eastlawn Elementary School',
  'Eastlawn School',
  'Eastman Avenue Elementary',
  'Eastman Regional Youth Detention Center',
  'Eastman Youth Development Campus',
  'Eastmont Columbia Virtual Academy',
  'Eastmont Intermediate',
  'Eastmont Junior High',
  'Eastmont Middle',
  'Eastmont Park Prek-6 School',
  'Eastmont Senior High',
  'Eastmoor Academy',
  'Eastmorland Elem.',
  'Easton',
  'Easton Area Hs',
  'Easton Area Ms',
  'Easton Arts Academy Elementary Cs',
  'Easton Community Day',
  'Easton Continuation High',
  'Easton Elementary',
  'Easton Elementary School',
  'Easton High',
  'Easton Junior-senior High Sch',
  'Easton Middle',
  'Easton Middle School',
  'Easton Preschool',
  'Easton School',
  'Easton Secondary School',
  'Easton Valley Elementary School',
  'Easton Valley High School',
  'Eastover Elementary',
  'Eastover Elementary School',
  'Eastover-central Elementary',
  'Eastplain School',
  'Eastpointe Alternative Center',
  'Eastpointe High School',
  'Eastpointe Middle School',
  'Eastpointe Virtual Academy',
  'Eastport Elementary',
  'Eastport Elementary School',
  'Eastport-south Manor Junior Senior High School',
  'Eastridge Community Elementary School',
  'Eastridge El',
  'Eastridge Elementary School',
  'Eastridge Senior High School',
  'Eastshore Elementary',
  'Eastside Academy/transitional Learning Center',
  'Eastside Connections School',
  'Eastside Detroit Lions Academy',
  'Eastside El',
  'Eastside Elem',
  'Eastside Elementary',
  'Eastside Elementary School',
  'Eastside Es',
  'Eastside High',
  'Eastside High School',
  'Eastside Junior-senior High School',
  'Eastside Memorial At The Johnston Campus',
  'Eastside Middle School',
  'Eastside Technical Center',
  'Eastvale Elementary',
  'Eastvalley Elementary School',
  'Eastview Education Center',
  'Eastview Elem School',
  'Eastview Elementary',
  'Eastview Elementary School',
  'Eastview Elementary School - Snw',
  'Eastview Middle School',
  'Eastview Senior High',
  'Eastward Elementary School',
  'Eastway Elementary',
  'Eastway Middle',
  'Eastwood Academy',
  'Eastwood Early Childhood Center',
  'Eastwood Elem School',
  'Eastwood Elem.',
  'Eastwood Elementary',
  'Eastwood Elementary School',
  'Eastwood H S',
  'Eastwood Heights El',
  'Eastwood Hills Elem.',
  'Eastwood I High School',
  'Eastwood Knolls',
  'Eastwood Middle',
  'Eastwood Middle School',
  'Eastwood School',
  'Eaton Academy',
  'Eaton County Youth Facility',
  'Eaton Elementary',
  'Eaton Elementary School',
  'Eaton Es',
  'Eaton High School',
  'Eaton Middle School',
  'Eaton Park Elementary School',
  'Eaton Rapids High School',
  'Eaton Rapids Middle School',
  'Eaton Resa Career Preparation Center',
  'Eaton Resa Early On',
  'Eaton Resa Great Start',
  'Eaton Resa Great Start Readiness Program',
  'Eaton Resa Meadowview Offsite',
  'Eaton Resa Meadowview Onsite',
  'Eaton-johnson Middle',
  'Eatonville Developmental Pre-school',
  'Eatonville Elementary School',
  'Eatonville High School',
  'Eatonville Middle School',
  'Eau Claire Alternative Education',
  'Eau Claire Community Sites',
  'Eau Claire High',
  'Eau Claire High School',
  'Eau Claire Middle School',
  'Eau Gallie High School',
  'Eb Holman Elementary School',
  'Ebarb School',
  'Ebb Valley Elementary',
  'Ebbets Field Middle School',
  'Ebby Halliday El',
  'Ebc High School For Public Service-bushwick',
  'Eben Hopson Middle School',
  'Ebenezer Avenue Elementary: Traditional Montesorri Inquiry',
  'Ebenezer El Sch',
  'Ebenezer Elementary School',
  'Ebenezer Middle',
  'Ebenezer Middle School',
  'Eberhard-Karls-Universität Tübingen',
  'Eberhart Elem School',
  'Eberwhite School',
  'Ebinger Elem School',
  'Ebinport Elementary',
  'Eblen Intermediate',
  'Ebonyi State University',
  'Ebr Readiness Superintendent Academy',
  'Ebr Virtual Academy',
  'Ec & Hk Summer',
  'Ec @ Msb',
  'Ec Sp Ed At Colts Academy',
  'Ecademy Charter At Crane',
  'Ecademy Virtual High School',
  'Ecambridge',
  'Eccles School',
  'Eccleston Elementary',
  'Ecdc Bunche',
  'Ecdc Porter',
  'Ece Center',
  'Eceap',
  'Eceap W',
  'Echo Canyon K-8',
  'Echo Day Treatment Ctr.',
  'Echo Glen School',
  'Echo Hill Elementary',
  'Echo Hills Elementary School',
  'Echo Juvenile Detention Ctr',
  'Echo Lake Elementary',
  'Echo Lake Elementary School',
  'Echo Loder Elementary School',
  'Echo Mountain Intermediate School',
  'Echo Mountain Primary School',
  'Echo Park Leadership Engineer/tech',
  'Echo School',
  'Echo Shaw Elementary School',
  'Echo Valley Elementary',
  'Echols County Elementary/middle School',
  'Echols County High School',
  'Echols Middle School',
  'Ecisd Falcon Early College H S',
  'Ecker Hill Middle',
  'Eckert Int',
  'Eckhart Elementary School',
  'Eckstein Middle School',
  'Eclectic Elementary School',
  'Eclectic Middle School',
  'Ecoff Elementary',
  "Ecole Catholique d'Arts & Metiers",
  "Ecole Centrale d'Electronique - ECE",
  'Ecole Centrale de Lille',
  'Ecole Centrale de Lyon',
  'Ecole Centrale de Marseille',
  'Ecole Centrale de Nantes',
  'Ecole Européen des Affaires',
  'Ecole Européenne de Chimie Polymères et Matériaux de Strasbourg',
  "Ecole Française d'Electronique et d'Informatique",
  'Ecole Française de Papeterie et des Industries Graphiques',
  'Ecole Kenwood French Immersion',
  "Ecole Mohammadia d'Ingénieurs",
  "Ecole National d'Agriculture de Meknes",
  "Ecole Nationale Superieure d'Informatique et de Mathematiques Appliquees de Grenoble",
  "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique",
  'Ecole Nationale Supérieur de Géologie de Nancy',
  "Ecole Nationale Supérieur de Mécanique et d'Aéronautique",
  'Ecole Nationale Supérieur de Mécaniques et des Microtechniques',
  "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement",
  'Ecole Nationale Supérieure Agronomique de Toulouse',
  'Ecole Nationale Supérieure Electricité et Mécanique',
  "Ecole Nationale Supérieure d'Agronomie de Montpellier",
  "Ecole Nationale Supérieure d'Agronomie de Rennes",
  "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires",
  "Ecole Nationale Supérieure d'Arts et Métiers de Paris",
  "Ecole Nationale Supérieure d'Electrochimie et d'Electrométallurgie de Gernoble",
  "Ecole Nationale Supérieure d'Electronique d'Electrotechnique, d'Informatique et d'Hydraulique de Toulouse",
  "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux",
  "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Grenoble",
  "Ecole Nationale Supérieure d'Hydraulique et de Mécanique de Grenoble",
  "Ecole Nationale Supérieure d'Ingenieurs Electriciens de Grenoble",
  "Ecole Nationale Supérieure d'Ingénieurs de Génie Chimique",
  "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes",
  'Ecole Nationale Supérieure de Chimie de Clermont-Ferrand',
  'Ecole Nationale Supérieure de Chimie de Lille',
  'Ecole Nationale Supérieure de Chimie de Montpellier',
  'Ecole Nationale Supérieure de Chimie de Mulhouse',
  'Ecole Nationale Supérieure de Chimie de Paris',
  'Ecole Nationale Supérieure de Chimie de Rennes',
  'Ecole Nationale Supérieure de Chimie de Toulouse',
  'Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux',
  'Ecole Nationale Supérieure de Physique de Grenoble',
  'Ecole Nationale Supérieure de Physique de Marseille',
  'Ecole Nationale Supérieure de Physique de Strasbourg',
  'Ecole Nationale Supérieure de Techniques Avancées',
  "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace",
  "Ecole Nationale Supérieure de l'Electronique et de ses Applications",
  'Ecole Nationale Supérieure des Arts et Industries Textiles',
  'Ecole Nationale Supérieure des Arts et Industries de Strasbourg',
  'Ecole Nationale Supérieure des Industries Chimiques de Nancy',
  'Ecole Nationale Supérieure des Industries Textiles de Mulhouse',
  "Ecole Nationale Supérieure des Mines d'Alès",
  'Ecole Nationale Supérieure des Mines de Douai',
  'Ecole Nationale Supérieure des Mines de Nancy',
  'Ecole Nationale Supérieure des Mines de Paris',
  'Ecole Nationale Supérieure des Mines de St-Etienne',
  'Ecole Nationale Supérieure des Telecommunications de Bretagne',
  'Ecole Nationale Supérieure des Telecommunications de Paris',
  'Ecole Nationale Supérieure du Pétrole et des Monteurs',
  'Ecole Nationale Supérieure en Electrotechnique Electronique, Informatique et Hydraulique de Toulouse',
  "Ecole Nationale Vétérinaire d'Alfort",
  'Ecole Nationale Vétérinaire de Lyon',
  'Ecole Nationale Vétérinaire de Nantes',
  'Ecole Nationale Vétérinaire de Toulouse',
  "Ecole Nationale d'Administration",
  "Ecole Nationale d'Architecture",
  "Ecole Nationale d'Ingénieurs de Metz",
  "Ecole Nationale d'Ingénieurs de Saint-Etienne",
  "Ecole Nationale d'Ingénieurs de Tarbes",
  "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires",
  "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux",
  "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand",
  "Ecole Nationale de l'Aviation Civile",
  'Ecole Nationale de la Météorologie',
  "Ecole Nationale de la Statistique et de l'Administration Economique",
  "Ecole Nationale de la Statistique et de l'Analyse de l'information",
  'Ecole Nationale des Ponts et Chausees',
  'Ecole Nationale des Sciences Géographiques',
  "Ecole Nationale des Travaux Publics de l'Etat",
  "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg",
  'Ecole Normale Supérieure de Cachan',
  'Ecole Normale Supérieure de Fontenay-Saint Cloud',
  'Ecole Normale Supérieure de Lyon',
  'Ecole Normale Supérieure de Paris',
  'Ecole Polytechnique',
  'Ecole Polytechnique Marseille',
  'Ecole Polytechnique Universitaire de Lille',
  "Ecole Spéciale de Mécanique et d'Electricité",
  "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
  "Ecole Superieur d'Ingenieurs Leonard de Vinci",
  'Ecole Superieure Robert de Sorbon',
  'Ecole Superieure des Télécommunications',
  "Ecole Supérieure Internationale d'Administration des Entreprises",
  "Ecole Supérieure d'Agriculture d'Angers",
  "Ecole Supérieure d'Agriculture de Purpan",
  "Ecole Supérieure d'Electricité",
  "Ecole Supérieure d'Electronique de l'Ouest",
  "Ecole Supérieure d'Informatique et de Management",
  "Ecole Supérieure d'Informatique-Electronique-Automatique",
  "Ecole Supérieure d'Ingénieurs de Marseille",
  "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique",
  "Ecole Supérieure d'Ingénieurs en Génie Electrique",
  "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture",
  "Ecole Supérieure d'Optique",
  'Ecole Supérieure de Chimie Organique et Minérale',
  'Ecole Supérieure de Chimie Physique Electronique de Lyon',
  'Ecole Supérieure de Commerce de Bordeaux',
  'Ecole Supérieure de Commerce de Brest',
  'Ecole Supérieure de Commerce de Clermont-Ferrand',
  'Ecole Supérieure de Commerce de Dijon',
  'Ecole Supérieure de Commerce de Grenoble',
  'Ecole Supérieure de Commerce de Le Havre/Caen',
  'Ecole Supérieure de Commerce de Lille',
  'Ecole Supérieure de Commerce de Lyon',
  'Ecole Supérieure de Commerce de Marseille-Provence',
  'Ecole Supérieure de Commerce de Nantes-Atlantique',
  'Ecole Supérieure de Commerce de Paris',
  'Ecole Supérieure de Commerce de Pau',
  'Ecole Supérieure de Commerce de Reims',
  'Ecole Supérieure de Commerce de Rouen',
  'Ecole Supérieure de Commerce de Sophia Antipolis',
  'Ecole Supérieure de Commerce de Toulouse',
  'Ecole Supérieure de Commerce et Management',
  'Ecole Supérieure de Commerce et des Affaires',
  'Ecole Supérieure de Physique et de Chimie Industrielles',
  "Ecole Supérieure des Sciences Commerciales d'Angers",
  'Ecole Supérieure des Sciences Economiques et Commerciales',
  "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy",
  'Ecole Supérieure des Techniques Industrielles et des Textiles',
  'Ecole Toussaint Louverture',
  "Ecole Universitaire d'Ingénieurs de Lille",
  "Ecole d'Architecture de Nancy",
  "Ecole d'Ingénieurs en Informatique pour l'Industrie",
  "Ecole de l'Air",
  'Ecole des Hautes Etudes Commerciales',
  'Ecole des Hautes Etudes Commerciales MAROC',
  'Ecole des Hautes Etudes Commerciales du Nord',
  'Ecole des Hautes Etudes Industrielles de Lille',
  'Ecole des Hautes Etudes en Gestion Informatique et Communication',
  'Ecole des Ingénieurs de la Ville de Paris',
  'Ecole pour les Etudes et la Recherche en Informatique et Electronique',
  'Ecologica De Culebra',
  'Econ River High Charter',
  'Economedes H S',
  'Economy El Sch',
  'Ecorse Community High School',
  'Ecse',
  'Ecse - Homebased',
  'Ecse Center At Campus',
  'Ecse Inclusion',
  'Ecse Program',
  'Ecse@ Isd 15 Lifelong Learning',
  'Ector Co Youth Ctr',
  'Ector El',
  'Ector H S',
  'Ector Middle',
  'Ed & Life Skills Transition',
  'Ed & Verma Pastor Elementary School',
  'Ed Clapp Elementary School',
  'Ed Donaldson Education Center',
  'Ed Franz El',
  'Ed H White El',
  'Ed Pastor Elementary 4',
  'Ed V Baldwin Elementary',
  'Ed Venture Charter School',
  'Ed Von Tobel Middle School',
  'Ed W Clark High School',
  'Ed Willkie Middle',
  'Edadvance Headstart',
  'Edahow Elementary School',
  'Edcouch-elsa H S',
  'Edcouch-elsa H S Academy',
  'Edcsd',
  'Eddie Finley Sr J H',
  'Eddie White Academy',
  'Eddington School',
  'Eddy Elementary',
  'Eddy Middle School',
  'Eddystone El Sch',
  'Eddyville Charter School',
  'Eddyville Education Center',
  'Eddyville Elementary',
  'Eddyville-blakesburg- Fremont Jr/sr High',
  'Eden Area Rop',
  'Eden Area Technical Center',
  'Eden Central School',
  'Eden Cisd',
  'Eden Elementary',
  'Eden Elementary School',
  'Eden Gardens Elementary',
  'Eden Gardens Fundamental Elementary School',
  'Eden Hall Upper El Sch',
  'Eden Junior-senior High School',
  'Eden Lake Elementary',
  'Eden Park Academy',
  'Eden Park Elementary School',
  'Eden Park School',
  'Eden Park Superintendent Academy',
  'Eden Prairie Senior High',
  'Eden Valley Elementary',
  'Eden Valley Secondary',
  'Eden Valley-watkins Alp',
  'Edendale Middle',
  'Edenvale Elementary',
  'Edenwald School',
  'Edgar A Hooks Elementary',
  'Edgar A Poe Elem School',
  'Edgar B Davis',
  'Edgar Elementary',
  'Edgar Fahs Smith Steam Academy',
  'Edgar Glover Jr El',
  'Edgar H. Parkman - Autism Spectrum Disorder (asd)',
  'Edgar H. Parkman School',
  'Edgar High',
  'Edgar L Miller Elementary School',
  'Edgar L. Padgett Elementary',
  'Edgar Martin Middle School',
  'Edgar Middle',
  'Edgar Middle School',
  'Edgar Murray Elem.',
  'Edgar P. Harney Spirit Of Excellence Academy',
  'Edgar Road Elem.',
  'Edgartown Elementary',
  'Edge Academy The',
  'Edge High School - Himmel Park',
  'Edge High School - Northwest',
  'Edgebrook Elem School',
  'Edgecomb Eddy School',
  'Edgecombe Circle Elementary',
  'Edgecombe Community College',
  'Edgecombe Early College High',
  'Edgelea Elementary School',
  'Edgeley Elementary School',
  'Edgeley High School',
  'Edgemere El',
  'Edgemere Elementary',
  'Edgemere Es',
  'Edgemont Elementary',
  'Edgemont Elementary - 03',
  'Edgemont Elementary School',
  'Edgemont Gardens Elementary School',
  'Edgemont High School - 01',
  'Edgemont Jr High',
  'Edgemont Junior-senior High School',
  'Edgemont Primary',
  'Edgemont School',
  'Edgerton Community Elementary',
  'Edgerton Early Childhood Center',
  'Edgerton Elem',
  'Edgerton Elementary',
  'Edgerton Elementary School',
  'Edgerton High',
  'Edgerton High School',
  'Edgerton Middle',
  'Edgerton School',
  'Edgerton Secondary',
  'Edgewater Elementary',
  'Edgewater Elementary School',
  'Edgewater High',
  'Edgewater Public School',
  'Edgewood Academy',
  'Edgewood Cmty Developmental',
  'Edgewood College',
  'Edgewood Community Elementary School',
  'Edgewood Early Childhood Center',
  'Edgewood El',
  'Edgewood El Sch',
  'Edgewood Elem School',
  'Edgewood Elementary',
  'Edgewood Elementary Academy',
  'Edgewood Elementary School',
  'Edgewood H S',
  'Edgewood High',
  'Edgewood High School',
  'Edgewood Highland',
  'Edgewood Int',
  'Edgewood Intermediate School',
  'Edgewood Jr/sr High School',
  'Edgewood Junior High School',
  'Edgewood Middle',
  'Edgewood Middle School',
  'Edgewood Primary School',
  'Edgewood School',
  'Edgewood-colesburg Elementary School',
  'Edgewood-colesburg High School',
  'Edgeworth El Sch',
  'Edina Senior High',
  'Edinboro El Sch',
  'Edinboro University of Pennsylvania',
  'Edinbrook Elementary',
  'Edinburg Alternative Education Academy',
  'Edinburg Common School',
  'Edinburg Elem School',
  'Edinburg H S',
  'Edinburg High School',
  'Edinburg Jr High School',
  'Edinburg North H S',
  'Edinburgh Comm Middle School',
  'Edinburgh Community High School',
  'Edison',
  'Edison (thomas A.) Charter School',
  'Edison (thomas) Elementary',
  'Edison Academy',
  'Edison Career And Technology High School',
  'Edison Charter Academy',
  'Edison Computech',
  'Edison Early Learning Center',
  'Edison El',
  'Edison El Sch',
  'Edison Elem Regional Gifted Cntr',
  'Edison Elem School',
  'Edison Elem.',
  'Edison Elementary',
  'Edison Elementary - Burlington/edison',
  'Edison Elementary School',
  'Edison Elementary School (formerly Milan Elem)',
  'Edison Elementary School - Kennewick',
  'Edison Elementary School - Walla Walla',
  'Edison Environmental Science Academy',
  'Edison Es',
  'Edison H S',
  'Edison High',
  'Edison High School',
  'Edison High School - Sws',
  'Edison High School Academy',
  'Edison Hs/fareira Skills',
  'Edison Intermediate',
  'Edison Intermediate Center',
  'Edison Jr High School',
  'Edison Jr. High School',
  'Edison Junior High School',
  'Edison Junior-senior High School',
  'Edison K-8',
  'Edison Max Day Treatment',
  'Edison Middle',
  'Edison Middle School',
  'Edison Middle School (formerly Berlin-milan Middle School)',
  'Edison Middle School - 06',
  'Edison Park Creative And Expressive Arts School',
  'Edison Park Elem School',
  'Edison Park K-8 Center',
  'Edison Prek-6 School',
  'Edison Prep',
  'Edison Preparatory Ms',
  'Edison Primary School',
  'Edison School',
  'Edison School Of The Arts 47',
  'Edison Senior High',
  'Edison State Community College',
  'Edison-bethune Charter Academy',
  'Edisto Elementary',
  'Edisto High',
  'Edisto Primary',
  'Edith & Ethel Carman El',
  'Edith A Lombard School',
  'Edith A. Bogert Elementary School',
  'Edith B. Storey Elementary',
  'Edith Bowen Laboratory School',
  'Edith C Baker',
  'Edith Cowan University',
  'Edith E. Mackrille School',
  'Edith Garehime Elementary School',
  'Edith I. Starke Elementary School',
  'Edith L Slocum Elementary School',
  'Edith L. Frierson Elementary',
  'Edith Landels Elementary',
  'Edith M. Decker Elementary School',
  'Edith M. Griebling Elementary School',
  'Edith Scheuerman Elem',
  'Edith Teter Elementary School',
  'Edith West Fritsch Elementary School',
  'Edith Wolford Elementary School',
  'Edkey Inc. - Pathfinder Academy - Sequoia Lehi',
  'Edkey Inc. - Pathfinder Academy At Eastmark',
  'Edkey Inc. - Sequoia Choice Precision School',
  'Edkey Inc. - Sequoia Deaf School',
  'Edmeston Central School',
  'Edmond Doyle Es',
  'Edmonds Career Access Program',
  'Edmonds Community College',
  'Edmonds Elearning Academy',
  'Edmonds Elementary',
  'Edmonds Elementary School',
  'Edmonds Heights K-12',
  'Edmonds Woodway High School',
  'Edmondson Elementary',
  'Edmondson Heights Elementary',
  'Edmondson-westside High',
  'Edmonson County 5/6 Center',
  'Edmonson County High School',
  'Edmonson County Middle School',
  'Edmonson Elementary School',
  'Edmore Elementary School',
  'Edmore High School',
  'Edmund A. Burns Elementary',
  'Edmund G Ross Elementary',
  'Edmund Hatch Bennett',
  'Edmund Hmieleski Early Childhood Center',
  "Edmund J O'neal Middle School Of Excellence",
  'Edmund W Miles Middle School',
  'Edmundo Del Valle Cruz',
  'Edmundo Eddie Escobedo Sr Middle School',
  'Edmunds Central Elementary - 05',
  'Edmunds Central High School - 01',
  'Edmunds Central Middle School - 02',
  'Edmunds Consolidated School',
  'Edmunds Elementary School',
  'Edmunds Fine Arts Academy',
  'Edmunds Middle School',
  'Edna Alternative',
  'Edna And John W. Mosley P-8',
  'Edna Andrews Elementary',
  'Edna Batey Elementary',
  'Edna Beaman Elementary',
  'Edna Bigham Mays El',
  'Edna Brewer Middle',
  'Edna C. Stevens School',
  'Edna Drinkwater School',
  'Edna El',
  'Edna Elem',
  'Edna F Hinman Elementary School',
  'Edna H S',
  'Edna Hill Middle',
  'Edna J H',
  'Edna Karr High School',
  'Edna Keith Elem School',
  'Edna Libby Elementary School',
  'Edna Mae Fielder El',
  'Edna Maguire Elementary',
  'Edna Mahan Correctional Facility For Women',
  'Edna Rollins Elem School',
  'Edna Rowe El',
  'Edna Tamayo House',
  'Edna Thomas School',
  'Edneyville Elementary',
  'Ednovate - East College Prep',
  'Ednovate - Usc College Prep Pico-un Westlake Campus',
  'Ednovate - Usc Esperanza College Prep',
  'Ednovate - Usc Hybrid High College Prep',
  'Edogawa University',
  'Edon Elementary School',
  'Edon High School',
  'Edon Middle School',
  'Edop Dcals Extended Day',
  'Edoptions Hs Learning Center',
  'Edoptions Preparatory Academy',
  'Edp - Bulkeley High School',
  'Edp - Hphs Engineering And Green Technology Academy',
  'Edp - M.d. Fox School',
  'Edsel Ford High School',
  'Edtrek',
  'Eduardo Garcia Carrillo',
  'Eduardo J. Saldana',
  'Eduardo Mata El',
  'Eduardo Neumann Gandia',
  'Educ Therap Support At Madison',
  'Educacion Bilingue Luis Munoz Iglesias',
  'Educare Center Tps Pre-school',
  'Educare Of Lincoln',
  'Educare Of West Dupage',
  'Education Center International Academy 1',
  'Education Center International Academy 2',
  'Education Center International Academy 3',
  'Education Complex',
  'Education Dev Center',
  'Education Options',
  'Education Plus Academy Cyber Cs',
  'Education Service Centers',
  'Educational Academy For Boys & Girls',
  'Educational Daycare',
  'Educational Horizons Charter',
  'Educational Opportunity Center',
  'Educational Opportunity Center Reengagement',
  'Educational Options',
  'Educational Options Center State Preschool',
  'Educational Partnership High',
  'Educational Resource Center',
  'Educational Service Unit 5',
  'Educational Services Program-east',
  'Educational Services Program-west',
  'Educational Training Center',
  'Eduhsd Virtual Academy At Shenandoah',
  'Edupreneurship Student Center (esc) Phoenix',
  'Eduprize School',
  'Eduprize School Gilbert',
  'Edvisions Off Campus School',
  'Edward A Reynolds West Side High School',
  'Edward A Sisson',
  'Edward A. Upthegrove Elementary',
  'Edward B Cannan El',
  'Edward B. Cole Academy',
  'Edward Bain School - Creative Arts',
  'Edward Bain School - Dual Language',
  'Edward Bell Career Technical Center',
  'Edward Best Elementary',
  'Edward Briscoe El',
  'Edward Byrom Elementary School',
  'Edward C Reed High School',
  'Edward C. Merlo Institute Of Environmental Studies',
  'Edward D Sadler Jr Elementary School',
  'Edward D. Trice Elem. School',
  'Edward Devotion',
  'Edward Duke Ellington Beckham',
  'Edward E. Drew Jr. Middle',
  'Edward E. Taylor Elementary',
  'Edward Everett',
  'Edward F. Leddy Preschool',
  'Edward F. Parthum',
  'Edward Fenn School',
  'Edward Fitzgerald Elementary',
  'Edward G. Clymore Elementary',
  'Edward G. Molin Elementary School',
  'Edward Gonzales Elementary',
  'Edward H Cary Middle',
  'Edward H. Bryan',
  'Edward H. White High School',
  'Edward Harris Jr. Middle',
  'Edward Hynes Charter School',
  'Edward J Arthur Elementary School',
  'Edward J Bosti Elementary School',
  'Edward J Sam Accelerated School Of Lafayette',
  'Edward J Tobin Elem School',
  'Edward J. Patten Elementary School',
  'Edward J. Richardson Middle',
  'Edward K Downing El',
  'Edward Kemble Elementary',
  'Edward L Pine Middle School',
  'Edward L. Bouie- Sr. Elementary School',
  'Edward Little High School',
  'Edward M. Downer Elementary',
  'Edward M. Felegy Es',
  'Edward M. Kennedy Academy For Health Careers (horace Mann Ch',
  'Edward Martin Middle',
  'Edward Neill Elementary',
  'Edward Ortiz Middle',
  'Edward P. Boland School',
  'Edward R Andrews Elementary School',
  'Edward R Murrow High School',
  'Edward R. Roybal Learning Center',
  'Edward Roberson Middle',
  'Edward Russell Elementary',
  'Edward S. Rhodes School',
  'Edward Smith K-8 School',
  'Edward Stone Middle School',
  'Edward T. Bowser Sr. School Of Excellence',
  'Edward T. Hamilton',
  'Edward Titche El',
  'Edward Town Middle School',
  'Edward V. Walton Primary School',
  'Edward W Kilpatrick',
  'Edward W. Wyatt Middle',
  'Edward White Elementary School',
  'Edward Williams School',
  'Edwards County High School',
  'Edwards Elem School',
  'Edwards Elementary',
  'Edwards Elementary School',
  'Edwards Es',
  'Edwards Middle School',
  'Edwards-johnson Memorial Middle',
  'Edwards-knox Elementary School',
  'Edwards-knox Junior-senior High School',
  'Edwardsburg High School',
  'Edwardsburg Intermediate School',
  'Edwardsburg Middle School',
  'Edwardsburg Primary School',
  'Edwardsville Elem',
  'Edwardsville High School',
  'Edwin A Alderman Elementary',
  'Edwin A Anderson Elementary',
  'Edwin A Jones Early Childhood Center',
  'Edwin A Lee Elementary School',
  'Edwin A. Gibson Elementary',
  'Edwin F Williams Int',
  'Edwin H Greene Intermediate Middle School',
  'Edwin J Kiest El',
  'Edwin Joel Brown Middle School',
  'Edwin Loe Elementary School',
  'Edwin M Holt Elementary',
  'Edwin M Wells Middle',
  'Edwin Markham Elementary',
  'Edwin Markham Middle',
  'Edwin P. Hubble Elem.',
  'Edwin R Opstad Elementary',
  'Edwin Rhodes Elementary',
  'Edwin S Dodson Elementary School',
  'Edwin W. Chittum Elementary',
  'Edy Ridge Elementary School',
  'Edythe & Lloyd Katz Elementary School',
  'Edythe Jones Hayes Middle School',
  'Ee Knight Elementary School',
  'Eek School',
  'Eel River Charter',
  'Eel River Community',
  'Eel River Elementary School',
  'Eelo American University',
  'Ef Rittmueller Middle School',
  'Effat College',
  'Effie Kokrine Charter School',
  'Effie Morris El',
  'Effingham County High School',
  'Effingham County Middle School',
  'Effingham Elementary School',
  'Effingham High School',
  'Effingham Junior High School',
  'Efland Cheeks Elementary',
  'Egan Elementary School',
  'Ege University',
  'Egegik School',
  'Egeler Correctional Facility',
  'Egerton University',
  'Egg Harbor City Community Sch',
  'Egg Harbor Township High School',
  'Eggert Road Elementary School',
  'Eglin Elementary School',
  'Egly El',
  'Egremont',
  'Egret Lake Elementary School',
  'Egypt Elementary',
  'Egypt Lake Elementary School',
  'Egyptian Elem School',
  'Egyptian Jr High School',
  'Egyptian Sr High School',
  'Ehime University',
  'Ehove Career Center',
  'Ehrenberg Elementary School',
  'Ehrhardt El',
  'Ehrhart School',
  'Ehs Summer School',
  'Eiber Elementary School',
  'Eichi University',
  'Eicholdmertz School Of Math And Science',
  'Eight Corners Elementary School',
  'Eight Mile Elementary School',
  'Eight Mile High School',
  'Eighth Street Elementary School',
  'Eighth Street Middle School',
  'Eighty-first Street Ece Center',
  'Eighty-first Street Elementary',
  'Eiilm University',
  'Eiland El',
  'Eiland Middle School',
  'Eileen Brookman Elementary School',
  'Eileen Conners Elementary School',
  'Eindhoven University of Technology',
  'Einstein Charter At Sherwood Forest',
  'Einstein Charter High School At Sarah Towles Reed',
  'Einstein Charter Middle Sch At Sarah Towles Reed',
  "Einstein Charter School At Village De L'est",
  'Einstein Elementary',
  'Einstein Elementary School',
  'Einstein Middle',
  'Eisenberg (harry O.) Elementary School',
  'Eisenhower Academy',
  'Eisenhower Center For Innovation',
  'Eisenhower El',
  'Eisenhower El Sch',
  'Eisenhower Elem',
  'Eisenhower Elem School',
  'Eisenhower Elementary',
  'Eisenhower Elementary School',
  'Eisenhower Es',
  'Eisenhower H S',
  'Eisenhower High School',
  'Eisenhower Hs',
  'Eisenhower Intermediate School',
  'Eisenhower International Es',
  'Eisenhower Jr High',
  'Eisenhower Jr High School',
  'Eisenhower M/hs',
  'Eisenhower Middle',
  'Eisenhower Middle School',
  'Eisenhower Middle/high',
  'Eisenhower Ms',
  'Eisenhower Ninth Grade School',
  'Eisenhower School',
  'Eisenhower Senior High',
  'Eismann Elementary',
  'Eje Elementary Academy Charter',
  'Eje Middle Academy',
  'Ekalaka 7-8',
  'Ekalaka Elementary School',
  'Ekiti State University',
  'Ekron Elementary School',
  'Ekstrand Elementary School',
  'El #22',
  'El #29',
  'El #30',
  'El #31',
  'El Alter Learning Center',
  'El Cajon Valley High',
  'El Camino College-​Compton Center',
  'El Camino Community College District',
  'El Camino Creek Elementary',
  'El Camino Elementary',
  'El Camino Fundamental High',
  'El Camino High',
  'El Camino High (continuation)',
  'El Camino Junior High',
  'El Camino Real Academy',
  'El Camino Real Academy Community',
  'El Camino Real Charter High',
  'El Camino Real Continuation High',
  'El Camino Real Elementary',
  'El Campo H S',
  'El Campo Middle',
  'El Capitan Elementary',
  'El Capitan High',
  'El Capitan Middle',
  'El Capitan Public School',
  'El Carmelo Elementary',
  'El Centro College',
  'El Centro District Wide Preschool',
  'El Centro Elementary',
  'El Centro Jr./sr. High',
  'El Cerrito Elementary',
  'El Cerrito High',
  'El Cerrito Middle',
  'El Colegio Charter School',
  'El Colegio de México',
  'El Conquistador',
  'El Crystal Elementary',
  'El Daep',
  'El Diamante High',
  'El Dorado Avenue Elementary',
  'El Dorado Community School',
  'El Dorado El',
  'El Dorado Elementary',
  'El Dorado H S',
  'El Dorado High',
  'El Dorado High School',
  'El Dorado Middle',
  'El Dorado Springs Elem.',
  'El Dorado Springs High',
  'El Dorado Springs Middle',
  'El Farallon',
  'El Gabilan Elementary',
  'El Granada Elementary',
  'El Haynes Pcs Elementary',
  'El Haynes Pcs High',
  'El Haynes Pcs Middle',
  'El Jardin El',
  'El Magnet At Blackshear',
  'El Magnet At Hays',
  'El Magnet At Milam El',
  'El Magnet At Reagan El',
  'El Magnet At Travis',
  'El Magnet At Zavala',
  'El Marino Elementary',
  'El Mirage',
  'El Mirage School',
  'El Modena High',
  'El Molino High',
  'El Monte Elementary',
  'El Monte High',
  'El Monte Middle',
  'El Monte Union High School Community Day',
  'El Morro Elementary',
  'El Nido Elementary',
  'El Nido Preschool',
  'El Oro Way Charter For Enriched Studies',
  'El Parque',
  'El Paso Academy',
  'El Paso Academy West',
  'El Paso Community College',
  'El Paso Elem',
  'El Paso H S',
  'El Paso Leadership Academy',
  'El Paso-gridley High School',
  'El Paso-gridley Jr High School',
  'El Portal Elementary',
  'El Portal Middle',
  'El Potrero Pre-school',
  'El Puente',
  'El Puente Academy For Peace And Justice',
  'El Puente High',
  'El Rancho Charter',
  'El Rancho High',
  'El Reno Hs',
  'El Rincon Elementary',
  'El Rito Elementary',
  'El Roble Elementary',
  'El Roble Intermediate',
  'El Rodeo Elementary',
  'El Sausal Middle',
  'El Segundo High',
  'El Segundo Middle',
  'El Senorial',
  'El Sereno Alternative Education',
  'El Sereno Elementary',
  'El Sereno Middle',
  'El Shorouk Academy',
  'El Sierra Elem School',
  'El Sol Elementary',
  'El Sol Santa Ana Science And Arts Academy',
  'El Tejon Elementary',
  'El Toro Health Science Academy',
  'El Toro High',
  'El Toyon Elementary',
  'El Verano Elementary',
  'El Vista Elementary',
  'Eladia Correa Morales (cerro Gordo)',
  'Eladio J. Vega',
  'Eladio R Martinez Learning Center',
  'Eladio Rosa Romero',
  'Eladio Tirado Lopez',
  'Elaine S Schlather Int',
  'Elaine Wynn Elementary School',
  'Elam Alexander Academy At Burke',
  'Elan Academy Charter School',
  'Elayn Hunt Correctional Center',
  'Elba Area Vocational School',
  'Elba Elementary School',
  'Elba High School',
  'Elba Junior-senior High School',
  'Elba Lugo Carrion (especializada)',
  'Elba Secondary School',
  'Elberfeld Elementary School',
  'Elbert County Elementary School',
  'Elbert County High School',
  'Elbert County Middle School',
  'Elbert County Primary School',
  'Elbert Edwards Elementary School',
  'Elbert Elementary School',
  'Elbert Junior-senior High School',
  'Elbert Shaw Regional Youth Detention Center',
  'Elbert-palmer Elementary School',
  'Elberta Elementary School',
  'Elberta High School',
  'Elbow Creek Elementary',
  'Elbridge Elementary School',
  'Elbridge Gale Elementary School',
  'Elbridge Gerry',
  'Elc - West Zone',
  'Elc Winfield Primary Annex',
  'Elcan-king Elementary School',
  'Elcho Elementary',
  'Elcho High',
  'Elcho Middle',
  'Elco Intermd Sch',
  'Elda Elementary School',
  'Elden D Finley Jr High School',
  'Elder Coop Alter School',
  'Elder Coop Alternative School',
  'Elder Creek Elementary',
  'Elder Grove 7-8',
  'Elder Grove School',
  'Elder Middle',
  'Elder W Diggs School 42',
  'Elderberry Elementary',
  'Eldersburg Elementary',
  'Elderton El Sch',
  'Eldon Career Ctr.',
  'Eldon High',
  'Eldon Middle',
  'Eldon Ready Elementary School',
  'Eldon Upper Elem.',
  'Eldora-new Providence Elementary School',
  'Eldorado El',
  'Eldorado Elem School',
  'Eldorado Elementary School',
  'Eldorado Es',
  'Eldorado H S',
  'Eldorado High',
  'Eldorado High School',
  'Eldorado Hs',
  'Eldorado K-8 School',
  'Eldorado Middle',
  'Eldorado Middle School',
  'Eldorado Prep',
  'Eldred Junior-senior High School',
  'Eldridge Elementary',
  'Eldridge L. Miller Elementary School',
  'Eldridge Park School',
  'Eleanor G. Hewitt',
  'Eleanor H. Miller School',
  'Eleanor J. Toll Middle',
  'Eleanor Kolitz Hebrew Language Academy',
  'Eleanor Lemaire Elementary',
  'Eleanor Lemaire Junior High School',
  'Eleanor Mcmain Secondary School',
  'Eleanor Murray Fallon',
  'Eleanor N Johnson Middle',
  'Eleanor Roosevelt Community Learning Center',
  'Eleanor Roosevelt El Sch',
  'Eleanor Roosevelt High',
  'Eleanor Roosevelt High School',
  'Eleanor Roosevelt Middle School',
  'Eleanor Rush Intermediate School',
  'Eleanor S. Rice Elementary',
  'Eleanor Skillen School 34',
  'Eleanor Van Gelder',
  'Elearning Academy',
  'Electa Lee Magnet Middle School',
  'Electra El',
  'Electra Junior/senior High',
  'Electronic Classroom Of Tomorrow',
  'Eleele Elementary School',
  'Elem. Special Programs Dept.',
  'Elemental Alfonso Lopez Garcia',
  'Elemental Barrio Las 400',
  'Elemental Berwind',
  'Elemental Bo Higuillar (ecologica)',
  'Elemental Ceferina Cordero',
  'Elemental Daniel Velez Soto',
  'Elemental Jose D. Zayas',
  'Elemental Nueva',
  'Elemental Nueva (factor V)',
  'Elemental Rafael Cordero',
  'Elemental Tallaboa Alta',
  'Elemental Urbana',
  'Elemental Urbana Nueva',
  'Elementary #9 Tbd',
  'Elementary Alp @ John Glenn',
  'Elementary At Bennet',
  'Elementary Homebound',
  'Elementary Immersion Center - 43',
  'Elementary Lighthouse',
  'Elementary Middle Alternative',
  'Elementary Sch At Valparaiso',
  'Elementary School At Brainard',
  'Elementary School At Ceresco',
  'Elementary School At Dwight',
  'Elementary School At Eustis',
  'Elementary School At Halsey',
  'Elementary School At Johnson',
  'Elementary School At Scribner',
  'Elementary School At Sumner',
  'Elementary School At Syracuse',
  'Elementary School At Tekamah',
  'Elementary School At Wilber',
  'Elementary School For Math Science And Technology',
  'Elementary School Y',
  "Elementary' Disciplinary Alternative Education Prg",
  'Elena L. Christian Junior High Scchool',
  'Eleonor Roosevelt',
  "Elephant's Fork Elementary",
  'Eleva Elementary',
  'Eleva-strum High',
  'Eleva-strum Middle',
  'Elevate Academy',
  'Elevate Elementary',
  'Elfida Chavez El',
  'Elfrida Elementary School',
  'Elger Bay Elementary',
  'Elgin B. Milton Elem. School',
  'Elgin Community College',
  'Elgin El',
  'Elgin Elementary School',
  'Elgin Es',
  'Elgin H S',
  'Elgin High School',
  'Elgin Hs',
  'Elgin Middle',
  'Elgin Middle School',
  'Elgin Ms',
  'Elgin-new Leipzig Elementary School',
  'Elgin-new Leipzig High School',
  'Elhajj Malik Elshabazz Academy',
  'Eli Pinney Elementary School',
  'Eli Ramos Rosario',
  'Eli Terry Jr. Middle School',
  'Eli Terry School',
  'Eli Whitney - Intermediate Adaptive Learning (ial)',
  'Eli Whitney School',
  'Eli Whitney Technical High School',
  'Elias Boudinot Elementary School',
  'Elias Brookings',
  'Elias Herrera Middle',
  'Elias Longoria Sr Middle',
  'Elias Michael Elem.',
  'Elida Elementary',
  'Elida High',
  'Elida High School',
  'Elida Middle School',
  'Eligio Kika De La Garza El',
  'Elihu Beard Elementary',
  'Elijah Stroud Middle School',
  'Elim Elementary',
  'Elinor Lincoln Hickey Jr./sr. High',
  'Eliot Battle Elementary',
  'Eliot El',
  'Eliot Elementary',
  'Eliot Elementary School',
  'Eliot Es',
  'Eliot Hine Ms',
  'Elisa Davila Vazquez (espinosa Adentro)',
  'Elisabet Ney El',
  'Elisabeth M. Bennet Academy',
  'Elisabeth University of Music',
  'Elise L. Wolff Elementary School',
  'Elise Middle',
  'Elise P. Buckingham Charter Magnet High',
  'Eliseo C. Felix School',
  'Elisha M Pease El',
  'Elite Academy',
  'Elite Scholars Academy School',
  'Elitha Donner Elementary',
  'Eliza A Blaker School 55',
  'Eliza Hart Spalding Stem Academy',
  'Eliza Miller Elementary School',
  'Eliza Miller Primary School',
  'Eliza Northrop Elementary',
  'Elizabeth Andrews High School',
  'Elizabeth Avenue School',
  'Elizabeth Baldwin School',
  'Elizabeth Blackwell Elem School',
  'Elizabeth Cady Stanton Elementary School',
  'Elizabeth Carter Brooks',
  'Elizabeth City Middle',
  'Elizabeth City Pasquotank Early College',
  'Elizabeth City State University',
  'Elizabeth Cobb Middle School',
  'Elizabeth D. Redd Elementary',
  'Elizabeth Davis Middle',
  'Elizabeth Eichelberger Elem Sch',
  'Elizabeth F. Moore School',
  'Elizabeth Fonde Elementary School',
  'Elizabeth Forward Ms',
  'Elizabeth Forward Shs',
  'Elizabeth G Lyons Elementary',
  'Elizabeth Graham Elem School',
  'Elizabeth Green School',
  'Elizabeth Haddon School',
  'Elizabeth High School',
  'Elizabeth Holladay Elementary',
  'Elizabeth Ide Elem School',
  'Elizabeth Lane Elem',
  'Elizabeth Learning Center',
  'Elizabeth Lenz Elementary School',
  'Elizabeth M Cashwell Elementary',
  'Elizabeth Meyer School',
  'Elizabeth Middle School',
  'Elizabeth Morris Elem School',
  'Elizabeth Page Elementary School',
  'Elizabeth Pinkerton Middle',
  'Elizabeth Pole',
  'Elizabeth Price Elementary School',
  'Elizabeth R Martin El Sch',
  'Elizabeth Running Creek Preschool',
  'Elizabeth S Brown',
  'Elizabeth Scott Elementary',
  'Elizabeth Shelton School',
  'Elizabeth Smith El',
  'Elizabeth T. Hughbanks Elementary',
  'Elizabeth Tate Alt. High School',
  'Elizabeth Terronez Middle',
  'Elizabeth Traditional Elem',
  'Elizabeth Ustach Middle',
  'Elizabeth Vaughan Elementary',
  'Elizabeth Wilhelm Elementary School',
  'Elizabethton High School',
  'Elizabethtown Area Ms',
  'Elizabethtown Area Shs',
  'Elizabethtown College',
  'Elizabethtown Community and Technical College',
  'Elizabethtown High School',
  'Elizabethtown Middle',
  'Elizabethtown Primary',
  'Elizabethtown-lewis Central School',
  'Elk City Es',
  'Elk City Hs',
  'Elk City Ies',
  'Elk City Ms',
  'Elk City School',
  'Elk Creek',
  'Elk Creek Elementary',
  'Elk Creek Elementary School',
  'Elk Creek Junior-senior High',
  'Elk Elementary Center',
  'Elk Garden School',
  'Elk Grove Charter',
  'Elk Grove Elementary',
  'Elk Grove High',
  'Elk Grove High School',
  'Elk Hills Elementary',
  'Elk Knob Elementary',
  'Elk Lake El Sch',
  'Elk Lake Jshs',
  'Elk Meadow Elementary School',
  'Elk Meadows School',
  'Elk Mound High',
  'Elk Mound Middle',
  'Elk Mountain Elementary',
  'Elk Mountain Elementary - 01',
  'Elk Mountain High School - 04',
  'Elk Mountain Virtual High School - 92',
  'Elk Neck Elementary',
  'Elk Plain Head Start',
  'Elk Plain School Of Choice',
  'Elk Point-jefferson Elementary - 02',
  'Elk Point-jefferson High School - 01',
  'Elk Point-jefferson Middle School - 03',
  'Elk Rapids High School',
  'Elk Ridge Elementary',
  'Elk Ridge Middle',
  'Elk Ridge School',
  'Elk River District Shared Time Prog',
  'Elk River Middle School Alc',
  'Elk River Senior High',
  'Elk Run Early Childhood Center',
  'Elk Run Elementary',
  'Elk Valley El Sch',
  'Elk Valley Elementary',
  'Elk Valley High School',
  'Elkhardt Thompson Middle',
  'Elkhart Alternative Education',
  'Elkhart Area Career Center',
  'Elkhart Central High School',
  'Elkhart Daep',
  'Elkhart El',
  'Elkhart Elem',
  'Elkhart Elementary School',
  'Elkhart H S',
  'Elkhart High',
  'Elkhart Int',
  'Elkhart Lake Elementary/middle',
  'Elkhart Lake High',
  'Elkhart Memorial High School',
  'Elkhart Middle',
  'Elkhart Middle School',
  'Elkhorn',
  'Elkhorn Arbor View Early Ed Center',
  'Elkhorn Area High',
  'Elkhorn Area Middle',
  'Elkhorn B-3 Early Education Center',
  'Elkhorn City Elementary School',
  'Elkhorn Crossing School',
  'Elkhorn Elementary',
  'Elkhorn Elementary School',
  'Elkhorn Grandview Middle School',
  'Elkhorn High School',
  'Elkhorn Middle School',
  'Elkhorn Options Virtual School',
  'Elkhorn Ridge Middle School',
  'Elkhorn South High School',
  'Elkhorn Valley Elementary Sch',
  'Elkhorn Valley High School',
  'Elkhorn Valley View Middle School',
  'Elkhorn Village Elementary',
  'Elkin Elementary',
  'Elkin High',
  'Elkin Lewis Sch',
  'Elkin Middle',
  'Elkins El',
  'Elkins Elem. Primary School',
  'Elkins Elementary',
  'Elkins Elementary School',
  'Elkins High School',
  'Elkins Middle School',
  'Elkins Mountain School',
  'Elkins Park Sch',
  'Elkins Pointe Middle School',
  'Elkins Third Ward Elementary School',
  'Elkmont Elementary School',
  'Elkmont High School',
  'Elko Grammar School #2',
  'Elko High School',
  'Elko Institute For Academic Achievement',
  'Elko Middle',
  'Elkridge Elementary',
  'Elkridge Landing Middle',
  'Elkton Charter School',
  'Elkton Elementary',
  'Elkton Elementary - 02',
  'Elkton High',
  'Elkton High School - 01',
  'Elkton Jr. High - 03',
  'Elkton Middle',
  'Elkview Middle School',
  'Ell-saline Elementary',
  'Ell-saline Middle/high School',
  'Ella B. Vernetti School',
  'Ella Baker School',
  'Ella Barkley High',
  'Ella C. Pittman Elementary School',
  'Ella Canavan Elementary School',
  'Ella Dolhonde Elementary School',
  'Ella Elementary',
  'Ella G Clarke Elementary School',
  'Ella Lewis School',
  'Ella M White School',
  'Ella P Burr School',
  'Ella P. Stewart Academy For Girls',
  'Ella Risk School',
  'Ella Schorlemmer El',
  'Ella T. Grasso Technical High School',
  'Ellen Feickert Elementary',
  'Ellen Hopkins Elementary',
  'Ellen Myers Primary',
  'Ellen Ochoa Es',
  'Ellen Ochoa Learning Center',
  'Ellen Ochoa Middle School',
  'Ellen Ochoa Prep Academy',
  'Ellen Ochoa Stem Academy At Ben Miliam El',
  'Ellen P. Hubbell School',
  'Ellen R Hathaway',
  'Ellen Smith Elementary School',
  'Ellen St Campus',
  'Ellen T. Briggs Elementary School',
  'Ellen Woodside Elementary',
  'Ellenboro Elementary',
  'Ellenboro Elementary School',
  'Ellendale Elementary',
  'Ellendale Elementary School',
  'Ellendale High School',
  'Ellender Memorial High School',
  'Ellensburg Developmental Preschool',
  'Ellensburg High School',
  'Ellenville Elementary School',
  'Ellenville High School',
  'Ellenville Middle School',
  'Ellerbe Middle',
  'Ellerhorst Elementary',
  'Ellerth E. Larson Elementary',
  'Ellet High School',
  'Ellicott Elementary School',
  'Ellicott Middle School',
  'Ellicott Mills Middle',
  'Ellicott Road Elementary School',
  'Ellicott Senior High School',
  'Ellicottville Elementary School',
  'Ellicottville Middle School High School',
  'Ellijay Elementary School',
  'Ellijay Primary School',
  'Ellington (duke) High (continuation)',
  'Ellington Elem School',
  'Ellington Elementary',
  'Ellington Elementary School',
  'Ellington Es',
  'Ellington High School',
  'Ellington Middle School',
  'Ellinwood Elem',
  'Ellinwood High',
  'Ellinwood Middle School',
  'Elliott (william F) Elementary',
  'Elliott County High School',
  'Elliott El',
  'Elliott Elementary School',
  'Elliott Point Elementary School',
  'Elliott Ranch Elementary',
  'Elliott Street Bldg',
  'Elliott Street Elementary School',
  'Ellis B Hyde Elementary School',
  'Ellis El',
  'Ellis Elem School',
  'Ellis Elem.',
  'Ellis Elementary',
  'Ellis Elementary School',
  'Ellis Jr/sr High',
  'Ellis Mendell',
  'Ellis Middle',
  'Ellis Middle School',
  'Ellis School',
  'Ellison El',
  'Ellison Elem.',
  'Ellison Park School',
  'Elliston School',
  'Ellisville Elem.',
  'Ellisville State School',
  'Elloree Elementary',
  'Ellsworth Avenue School',
  'Ellsworth Community School',
  'Ellsworth Elem',
  'Ellsworth Elem School',
  'Ellsworth Elementary',
  'Ellsworth Elementary School',
  'Ellsworth Elementary-middle School',
  'Ellsworth High',
  'Ellsworth High School',
  'Ellsworth Hill Elementary School',
  'Ellsworth Middle',
  'Ellsworth Secondary',
  'Ellwood Elementary',
  'Ellwood Sch',
  'Elm Avenue Elementary School',
  'Elm City College Preparatory School',
  'Elm City Elementary',
  'Elm City Middle',
  'Elm City Montessori School',
  'Elm Creative Arts Elementary',
  'Elm Creek El',
  'Elm Creek Elementary',
  'Elm Creek Elementary School',
  'Elm Creek High School',
  'Elm Dale Elementary',
  'Elm Drive Elementary School',
  'Elm Elem School',
  'Elm Grove El',
  'Elm Grove Elem.',
  'Elm Grove Elementary School',
  'Elm Grove Middle School',
  'Elm High',
  'Elm Hill School',
  'Elm Lawn Elementary',
  'Elm Middle School',
  'Elm Park Community',
  'Elm Place School',
  'Elm River Township School',
  'Elm Road Elementary School',
  'Elm Springs Elementary - 13',
  'Elm Street Elementary',
  'Elm Street Elementary School',
  'Elm Street Middle School',
  'Elm Street School',
  'Elm Street School-east Machias',
  'Elm Street School-mechanic Falls',
  'Elm Tree Academy',
  'Elm Tree Elementary School',
  'Elma E Barrera El',
  'Elma Elementary School',
  'Elma High School',
  'Elma Middle School',
  'Elma Primary School',
  'Elmcrest Elementary School',
  'Elmdale Elementary School',
  'Elmer A Knopf Learning Center',
  'Elmer A. Henderson: A Johns Hopkins Partnership Sc',
  'Elmer A. Wolfe Elementary',
  'Elmer C Watson H S',
  'Elmer Elementary School',
  'Elmer H Franzen Intermediate Sch',
  'Elmer H. Cook Elem. School',
  'Elmer L Meyers Jshs',
  'Elmer S Bagnall',
  'Elmer Thienes-mary Hall Elementary School',
  'Elmer Wood Elementary',
  'Elmergib University',
  'Elmhurst College',
  'Elmhurst Community Prep',
  'Elmhurst Elementary',
  'Elmhurst Elementary School',
  'Elmira Elementary School',
  'Elmira High School',
  'Elmira Psychiatric Center',
  'Elmonica Elementary School',
  'Elmont Elem',
  'Elmont Elementary',
  'Elmont Memorial High School',
  'Elmora School No. 12',
  'Elmore City Jhs',
  'Elmore City-pernell Es',
  'Elmore County High School',
  'Elmore County Technical Center',
  'Elmore El',
  'Elmore Elementary',
  'Elmore Park Middle School',
  'Elms Elementary School',
  'Elms Road Elementary School',
  'Elmwood',
  'Elmwood El Sch',
  'Elmwood Elem School',
  'Elmwood Elementary',
  'Elmwood Elementary School',
  'Elmwood High',
  'Elmwood High School',
  'Elmwood Junior High School',
  'Elmwood Middle',
  'Elmwood Middle School',
  'Elmwood Park High School',
  'Elmwood Place Elementary School',
  'Elmwood Primary Learning Center',
  'Elmwood Street',
  'Elmwood Village Charter School - Days Park',
  'Elmwood Village Charter School Hertel',
  'Elmwood-murdock Elem School',
  'Elmwood-murdock Jr/sr High Sch',
  'Elodia R Chapa El',
  'Eloisa Pascual',
  'Eloise Japhet Academy',
  'Elolf El',
  'Elon College',
  'Elon Elementary',
  'Elon Park Elementary',
  'Elona P. Cooley Early Child Development Center',
  'Eloy Garza Salazar El',
  'Eloy Intermediate School',
  'Eloy Junior High School',
  'Elpidio H. Rivera',
  'Elrazi College Of Medical & Technological Sciences',
  'Elrod El',
  'Elrod School',
  'Elroy Avenue El Sch',
  'Elsa E Couto Annoni',
  'Elsa England El',
  'Elsa Meyer Elementary School',
  'Elsa Widenmann Elementary',
  'Elsanor School',
  'Elsberry High',
  'Elsie A Hastings Elementary',
  'Elsie Allen High',
  'Elsie C Johnson Elem Sch',
  'Elsie Collier Elementary School',
  'Elsie H Wilcox Elementary School',
  'Elsie Rogers Elementary School',
  'Elsie Wadsworth Elementary School',
  'Elsie Whitlow Stokes Community Freedom Pcs',
  'Elsik H S',
  'Elsinboro Township School',
  'Elsinore Elementary',
  'Elsinore High',
  'Elsinore Middle',
  'Elsmere Elementary School',
  'Elton Elementary School',
  'Elton Gregory Middle School',
  'Elton High School',
  'Elton Hills Elementary',
  'Elton M Garrett Junior High School',
  'Elva C Lobit Middle',
  'Elva Lynch Elementary School',
  'Elvehjem Elementary',
  'Elverado High School',
  'Elverado Intermediate School',
  'Elverado Junior High School',
  'Elverado Primary School',
  'Elverta Elementary',
  'Elvin Hill Elementary School',
  'Elvira Elementary School',
  'Elvira M. Colon Negron',
  'Elvira Vicente',
  'Elvis J Ballew H S',
  'Elwin Elementary',
  'Elwood C C School',
  'Elwood Elementary School',
  'Elwood Haynes Elementary School',
  'Elwood High School',
  'Elwood Intermediate School',
  'Elwood J. Keema High',
  'Elwood Jr-sr High School',
  'Elwood Kindle Elementary School',
  'Elwood Middle School',
  'Elwood/john Glenn High School',
  'Elwyn G Campbell',
  'Ely Elementary School',
  'Elydale Elementary',
  'Elyria Early Childhood Center',
  'Elyria High School',
  'Elysian Charter School',
  'Elysian Fields El',
  'Elysian Fields H S',
  'Elysian Fields Middle',
  'Elysian Fields Middle School',
  'Elysian Heights Elementary',
  'Elysian Middle School',
  'Elysian School',
  'Elza R. Tucker Elem. School',
  'Elzie D Patton Elementary School',
  'Emanuel County Institute',
  'Emanuel University',
  'Embassy Creek Elementary School',
  'Ember Charter School',
  'Emblem Academy',
  'Embry-Riddle Aeronautical University',
  'Emden Elementary School',
  'Emeishan Buddhist College',
  'Emeline Carpenter Early Childhood Center',
  'Emeline Carpenter El',
  'Emelita Academy Charter',
  'Emeq Yizrael College',
  'Emerado Elementary School',
  'Emerald Academy',
  'Emerald Coast Career Institute N',
  'Emerald Coast Middle School',
  'Emerald Coast Technical College',
  'Emerald Cove Middle School',
  'Emerald Elementary School',
  'Emerald Heights Elementary',
  'Emerald High',
  'Emerald Hill Elementary',
  'Emerald Hills Elementary',
  'Emerald Middle',
  'Emerald Park Elementary School',
  'Emerald Ridge High School',
  'Emerald Shores Elementary School',
  'Emereau: Bladen',
  'Emerick Elementary',
  'Emerita Leon Elemental',
  'Emerson (ralph Waldo) Elementary',
  'Emerson Academy',
  'Emerson Alternative Ed. (es)',
  'Emerson Alternative Ed. (hs)',
  'Emerson Alternative Ed. (ms)',
  'Emerson College',
  'Emerson Community Charter',
  'Emerson El',
  'Emerson Elem',
  'Emerson Elem School',
  'Emerson Elementary',
  'Emerson Elementary School',
  'Emerson Elementarymiddle School',
  'Emerson Es',
  'Emerson High School',
  'Emerson Hough Elementary School',
  'Emerson J Dillon Middle School',
  'Emerson Jr Sr High',
  'Emerson K-12',
  'Emerson Middle',
  'Emerson Middle School',
  'Emerson Ms',
  'Emerson Parkside Academy',
  'Emerson School',
  'Emerson School (the)',
  'Emerson School Of Hospitality',
  'Emerson-gridley El Sch',
  'Emerson-hubbard Elementary Sch',
  'Emerson-hubbard High School',
  'Emerson-williams School',
  'Emerson/bandini Elementary',
  'Emery El',
  'Emery High',
  'Emery O Muncie Elementary School',
  'Emery Park Elementary',
  'Emery Secondary',
  'Emeryville Preschool Students',
  'Emge Elem.',
  'Emge Junior High School',
  'Emil A. Cavallini Middle School',
  'Emil E. Holt Sr. High',
  'Emile El',
  'Emilia Bonilla',
  'Emilia Castillo Vda. Abreu',
  'Emiliano Figueroa Torres',
  'Emiliano Zapata El',
  'Emilie J. Ross Middle',
  'Emilie Ritchen Elementary',
  'Emilio & Guadalupe Gonzalez Pre-k Center',
  'Emilio B. Huyke',
  'Emilio Casas (penuelas)',
  'Emilio Castelar',
  'Emilio Del Toro Cuevas',
  'Emilio R. Delgado',
  'Emily Brittain El Sch',
  'Emily C Reynolds Middle School',
  'Emily C. Watkins Elementary',
  'Emily Carr Institute of Art + Design',
  'Emily Dickinson School',
  'Emily G Johns School',
  'Emily G Wetherbee',
  'Emily Gray Junior High School',
  'Emily Griffith High School',
  'Emily Meschter Early Learning Center',
  'Emily Program',
  'Emily Spong Preschool Center',
  'Eminence Elem.',
  'Eminence Elementary School',
  'Eminence High',
  'Eminence High School',
  'Eminence Jr-sr High School',
  'Eminnesota Online Academy',
  'Emlen Eleanor C Sch',
  'Emma Arleth Elementary School',
  'Emma B Trask Middle',
  'Emma B. Ward Elementary School',
  'Emma C Attales',
  'Emma C Chase School',
  'Emma C. Smith Elementary',
  'Emma Donnan Elementary School',
  'Emma Donnan Middle School',
  'Emma E Sherman Elementary School',
  'Emma E. Booker Elementary School',
  'Emma Elementary',
  'Emma G. Whiteknact School',
  'Emma Hart Willard School',
  'Emma Havens Young Elementary School',
  'Emma Jewel Charter Academy',
  'Emma K. Doub Elementary',
  'Emma L Miller Elementary School',
  'Emma Lazarus High School',
  'Emma Love Hardee Elementary',
  'Emma Murphy Elementary School',
  'Emma Roberson El',
  'Emma Smith Elementary School',
  'Emma V Lobbestael Elem School',
  'Emma Vera El',
  'Emma W. Shuey Elementary',
  'Emma Wilson Elementary',
  'Emmalena Elementary School',
  'Emmanuel College',
  'Emmaus Hs',
  'Emmerich Manual High School',
  'Emmerton Elementary',
  'Emmet Belknap Intermediate School',
  'Emmetsburg High School',
  'Emmetsburg Middle School',
  'Emmett Elementary',
  'Emmett High School',
  'Emmett J Conrad H S',
  'Emmett Middle School',
  "Emmett O'brien Technical High School",
  'Emmett S. Finley Elementary',
  'Emmett W Bassett School',
  'Emmitsburg Elementary',
  'Emmonak School',
  'Emmons County Spec Ed Unit',
  'Emmons Elementary School',
  'Emmons Grade School',
  'Emmons Lake Elementary School',
  'Emmorton Elementary',
  'Emmott El',
  'Emolior Academy',
  'Emory Elementary',
  'Emory Elementary School',
  'Emory University',
  'Emotionally Disturbed',
  'Emperor Elementary',
  'Empire Elem School',
  'Empire Elementary',
  'Empire Elementary School',
  'Empire Es',
  'Empire Gardens Elementary',
  'Empire High School',
  'Empire Hs',
  'Empire Ms',
  'Empire Oaks Elementary',
  'Empire Springs Charter',
  'Employment Transition Program',
  'Emporia High',
  'Emporia Middle School',
  'Emporia State University',
  'Empower',
  'Empower Charter',
  'Empower College Prep',
  'Empower Collegiate Academy',
  'Empower Generations',
  'Empowering Possibilities International Charter',
  'Empowerment Academy',
  'Empowerment Academy Charter School',
  'Empowerment H S',
  'Empresa Elementary',
  'Emsley A Laney High',
  'Enadia Way Technology Charter',
  'Enatai Elementary School',
  'Encampment K-12 School',
  'Encanto Elementary',
  'Encanto School',
  'Enchanted Hills Elementary',
  'Enchanted Lake Elementary School',
  'Encina Preparatory High',
  'Encinal El',
  'Encinal Elementary',
  'Encinal Junior/senior High',
  'Encinita Elementary',
  'Encino Charter Elementary',
  'Encino Park El',
  'Encompass Academy',
  'Encompass Academy Elementary',
  'Encore',
  'Encore Academy',
  'Encore High School For The Arts - Riverside',
  'Encore Jr./sr. High Sch For The Perf And Visual Arts',
  'Endeavor Academy',
  'Endeavor Alternative',
  'Endeavor Charter',
  'Endeavor Charter Academy',
  'Endeavor Charter School',
  'Endeavor College Preparatory Charter',
  'Endeavor Elementary',
  'Endeavor Elementary - 07',
  'Endeavor Elementary School',
  'Endeavor Hall',
  'Endeavor Hs-joe Frank Campbell Learning Center',
  'Endeavor Middle School',
  'Endeavor School',
  'Endeavour Elementary',
  'Endeavour Elementary And Middle School',
  'Endeavour Elementary School',
  'Endeavour Intermediate',
  'Endeavour Middle',
  'Endeavour Primary Learning Center',
  'Endeavour School',
  'Endeavour School Of Exploration',
  'Enderlin Area Elementary School',
  'Enderlin Area High School',
  'Enderly Heights Elementary',
  'Enders Elementary',
  'Enders Road Elementary School',
  'Enders-fisherville El Sch',
  'Enders-salk Elem School',
  'Endhaven Elementary',
  'Endicott/st John Elem And Middle',
  'Endy Elementary',
  'Eneida M. Hartner Elementary School',
  'Enemy Swim Day School',
  'Energized For Excellence Academy Ecc',
  'Energized For Excellence Academy El',
  'Energized For Excellence Academy Inc Middle',
  'Energized For Stem Academy Southeast H S',
  'Energized For Stem Academy Southeast Middle',
  'Energized For Stem Academy Southwest H S',
  'Energized For Stem Academy Southwest Middle',
  'Energy Institute H S',
  'Energy Tech High School',
  'Enfield Correctional Institution',
  'Enfield High School',
  'Enfield Middle S.t.e.a.m. Academy',
  'Enfield School',
  'Enfield Station Elementary School',
  'Enfield Street - Autism Spectrum Disorder (asd)',
  'Enfield Street School',
  'Enfield Transitional Learning Academy',
  'Enfield Village School',
  'Engadine Schools',
  'Engage Online Academy',
  'Enge-washington Int',
  'Engelhard Elementary',
  'Engelwood Elementary',
  'Enger School',
  'Engineering - Science University Magnet School',
  'Engineering College of Aarhus',
  'Engineering College of Copenhagen',
  'Engineering Faculty of Asian University',
  'England Elementary School',
  'England High School',
  'Engle Day Treatment',
  'Engleburg Elementary',
  'Engleman Elementary School',
  'Englewood Early Childhood Education Center At Madd',
  'Englewood Elementary',
  'Englewood Elementary School',
  'Englewood High School',
  'Englewood Hills Elementary School',
  'Englewood Leadership Academy',
  'Englewood Middle School',
  'Englewood On The Palisades Charter School',
  'English Crossing Elementary',
  'English Estates Elementary School',
  'English Landing Elem.',
  'English Language Learners And International Support',
  'English Valleys Elementary School',
  'English Valleys Jr-sr High School',
  'English Village Elementary School',
  'Engr And Tech Acad At Esteban E. Torres High #3',
  'Enid Hs',
  'Enka High',
  'Enka Intermediate',
  'Enka Middle',
  'Enlace Academy',
  'Enlightenment School',
  'Enloe High',
  'Enning/union Center Elementary - 09',
  'Ennis (howard T.) School',
  'Ennis 7-8',
  'Ennis H S',
  'Ennis High School',
  'Ennis J H',
  'Ennis School',
  'Eno River Academy',
  'Eno Valley Elementary',
  'Enoch Cobb Early Learning Center',
  'Enoch School',
  'Enochville Elementary',
  'Enon Elementary',
  'Enon Elementary School',
  'Enon Primary School',
  'Enoree Career Center',
  'Enos Elem School',
  'Enos Garcia Elementary',
  'Enosburg Falls Elementary School',
  'Enosburg Falls Junior/senior High School',
  'Enota Multiple Intelligences Academy',
  'Enrich Alden',
  'Enrich Excel Achieve Learning Academy',
  'Enrich Kingsland',
  'Enrich Program',
  'Enrico Fermi School For The Performing Arts',
  'Enrique Camarena Jr. High',
  'Enrique Huyke',
  'Enrique Kiki Camarena El',
  'Ensign School',
  'Enslen Elementary',
  'Ensley Elementary School',
  'Ensworth Elementary School',
  'Enterprise Academy/newport News City',
  'Enterprise Alternative',
  'Enterprise Charter High School',
  'Enterprise Charter School',
  'Enterprise Early Education Center',
  'Enterprise Elem',
  'Enterprise Elem School',
  'Enterprise Elementary',
  'Enterprise Elementary School',
  'Enterprise High',
  'Enterprise High School',
  'Enterprise Learning Academy',
  'Enterprise Middle',
  'Enterprise Middle School',
  'Enterprise Plus',
  'Enterprise School',
  'Enterprise Secondary',
  'Enterprise State Community College',
  'Entheos Academy Kearns',
  'Entheos Academy Magna',
  'Entiat Middle And High School',
  'Entrada',
  'Entrada Academy',
  'Entrepreneurship Preparatory School - Woodland Hills Campus',
  'Entz Elementary School',
  'Enugu State University of Science and Technology',
  'Enumclaw Middle School',
  'Enumclaw Sr High School',
  'Env Acad Of Research Tech And Earth Scis',
  'Environment Community Opportunity (eco) Charter School',
  'Environmental & Adventure School',
  'Environmental And Social Policy Magnet',
  'Environmental Charter High',
  'Environmental Charter Middle',
  'Environmental Charter Middle - Inglewood',
  'Environmental Charter School At Frick Pa',
  'Environmental Sciences Magnet At Hooker School',
  'Envision Academy For Arts & Technology',
  'Envision High School',
  'Envision Science Academy',
  'Envisions Level Iii School Prg',
  'Eola Hills Charter School',
  'Ep Pearce Elementary',
  'Ep Rayzor El',
  'Ephesus Elementary',
  'Ephesus Elementary School',
  'Ephraim Curtis Middle',
  'Ephraim Middle',
  'Ephraim School',
  'Ephrata High School',
  'Ephrata Middle School',
  'Ephrata Ms',
  'Ephrata Shs',
  'Epic Academy High School',
  'Epic Blended Charter Okc Es',
  'Epic Blended Charter Okc Hs',
  'Epic Blended Charter Okc Ms',
  'Epic Blended Charter Tulsa Es',
  'Epic Blended Charter Tulsa Hs',
  'Epic Blended Charter Tulsa Ms',
  'Epic Charter',
  'Epic De Cesar Chavez',
  'Epic Elementary',
  'Epic High School North',
  'Epic High School-south',
  'Epic One On One Charter Es',
  'Epic One On One Charter Hs',
  'Epic One On One Charter Ms',
  'Epic School',
  'Epifanio Estrada',
  'Epifanio Fernandez Vanga',
  'Epiphany Prep Charter',
  'Epoka University',
  'Epperly Heights Es',
  'Epping Elementary School',
  'Epping High School',
  'Epping Middle School',
  'Eppler Junior High School',
  'Epps High School',
  'Epps Island El',
  'Epruett Center Of Technology',
  'Eps Integrated Pre-kindergarten',
  'Eps Pk Steam Academy',
  'Epsilon',
  'Epsom Central School',
  'Epworth Elementary School',
  'Epworth Village Learning Cntr',
  'Equality Charter School',
  'Equestrian Trails Elementary',
  'Equipo Academy',
  'Equitas Academy #2',
  'Equitas Academy #3 Charter',
  'Equitas Academy 4',
  'Equitas Academy Charter',
  'Er Dickson Elementary School',
  'Era School',
  'Erasmus University Rotterdam',
  'Erasmushogeschool Brussel',
  'Erath High School',
  'Erath Middle School',
  'Erciyes University',
  'Ereckson Middle',
  'Erial Elementary School',
  'Eric Birch High (continuation)',
  'Eric Dahlstrom Middle',
  'Eric S. Smith Middle School',
  'Eric White Elementary',
  'Erick Es',
  'Erick Hs',
  'Erickson Elementary',
  'Erickson Elementary School',
  'Ericson Elem Scholastic Academy',
  'Ericson Elementary',
  'Erie 1 Boces',
  'Erie 2-chautauqua-cattaraugus Boces',
  'Erie Community College',
  'Erie County Holding Center',
  'Erie County Technical School',
  'Erie Elem Charter School',
  'Erie Elem School',
  'Erie Elementary',
  'Erie Elementary School',
  'Erie High School',
  'Erie Hs',
  'Erie Intermediate School',
  'Erie Middle School',
  'Erie Rise Leadership Academy Charter Sch',
  'Erieview Elementary School',
  'Erik Ramstad Middle School',
  'Eriksson Elementary School',
  'Erin Elementary',
  'Eritrea Institute of Technology',
  'Erle Stanley Gardner Middle',
  'Erma B. Reese Elementary',
  'Erma Duncan Polytechnical High',
  'Erma Nash El',
  'Erma Siegel Elementary',
  'Ermel El',
  'Ernest Becker Middle School',
  'Ernest Bowen Desilva Elementary School',
  'Ernest C Myer School',
  'Ernest Camp Edwards Education Complex',
  'Ernest Everett Just Middle',
  'Ernest F Kolb Elem School',
  'Ernest F Upham',
  'Ernest Gallet Elementary School',
  'Ernest Garcia Elementary',
  'Ernest Hemingway Steam School',
  'Ernest Horn Elementary School',
  'Ernest J. Finizio Jr. - Aldene School',
  'Ernest Kimme Charter Academy For Independent Learning',
  'Ernest Lawrence Middle',
  'Ernest May Elementary School',
  'Ernest O. Lawrence Elementary',
  'Ernest P. Barka Elementary School',
  'Ernest P. Willenberg Special Education Center',
  'Ernest R Elliott Elementary School',
  'Ernest R. Geddes Elementary',
  'Ernest R. Graham K-8 Academy',
  'Ernest Righetti High',
  'Ernest S. Mcbride Sr. High',
  'Ernest Stapleton Elementary',
  'Ernest W Seaholm High School',
  'Ernest Ward Middle School',
  'Ernestina Bracero',
  'Ernestina Mendez',
  'Ernesto Galarza Elementary',
  'Ernesto Juan Fonfrias Rivera',
  'Ernesto Ramos Antonini',
  'Ernesto Ramos Antonini (musica)',
  'Ernesto Serna School',
  'Ernesto Vicente Carattini (elemental Bo Ceiba)',
  'Ernie Davis Academy',
  'Ernie Pyle Elementary',
  'Ernie Pyle Elementary School',
  'Ernie Pyle Middle',
  'Ernie Pyle School 90',
  'Ernst-Moritz-Arndt Universität Greifswald',
  'Erpenbeck Elementary School',
  'Errick Road Elementary School',
  'Errol Consolidated Elementary School',
  'Errol Hassell Elementary School',
  'Erskine Elementary School',
  'Ervin C Whitt El',
  'Ervin Carlisle Elementary School',
  'Ervin Early Learning Center',
  'Ervin El',
  'Erving Elementary',
  'Erving Elementary School',
  'Ervinton Elementary',
  'Erwin Craighead Elementary School',
  'Erwin Elementary',
  'Erwin High',
  'Erwin Intermediate School',
  'Erwin Middle',
  'Erwin Middle School',
  'Erwin Montessori',
  'Erwin Technical College',
  'Erwin Valley Elementary School',
  'Erzincan University',
  'Erzurum Tecnical University',
  'Es141',
  'Esa 112 Special Ed Co-op',
  'Esc Academy',
  'Esc School At West Amwell',
  'Esc. Especializada Bellas Artes De Humacao',
  'Esc. Libre De Musica Humacao (especializada)',
  'Escalante High',
  'Escalante Middle School',
  'Escalante Middle/high School',
  'Escalante School',
  'Escalante Valley School',
  'Escalante-biggs Academy',
  'Escalon Charter Academy',
  'Escalon High',
  'Escalona Elementary',
  "Escambia Boys' Base",
  'Escambia Charter School',
  'Escambia County Alternative School',
  'Escambia County High School',
  'Escambia County Middle School',
  'Escambia High School',
  'Escambia Juvenile Detention',
  'Escambia Sch. Dist. Jail Prog.',
  'Escambia Virtual Academy Franchise',
  'Escambia Virtual Instruction (course Offerings)',
  'Escambia Virtual Instruction Program',
  'Escambia Virtual Instructional Program (district Provided)',
  'Escambia Westgate Center',
  'Escambiabrewton Career Technical Center',
  'Escamilla Int',
  'Escanaba Area Public High School',
  'Escanaba Junior High',
  'Escanaba Student Success Center',
  'Escanaba Upper Elementary',
  'Escandon El',
  'Esce Program',
  'Escola Nautica Infante D. Henrique',
  'Escola Superior de Artes e Design',
  'Escola Superior de Hotelaria e Turismo do Estoril',
  'Escondido Charter High',
  'Escondido Elementary',
  'Escondido High',
  'Escondido North El',
  'Escontrias Early Child Ctr',
  'Escontrias El',
  'Escuela Agricola Panamericana Zamorano',
  'Escuela Avancemos',
  'Escuela Bancaria y Comercial',
  'Escuela Colombiana de Carreras Industriales',
  'Escuela Colombiana de Ingeniería Julio Garavito',
  'Escuela De Bellas Artes (especializada)',
  'Escuela Especializada Bilingue Alcides Figueroa',
  'Escuela Especializada En Beisbol-manuel Cruz Maceira',
  'Escuela Especializada Federico Degetau I',
  'Escuela Libre De Musica (especializada)',
  'Escuela Militar de Ingeniería',
  'Escuela Nacional de Estudios Superiores Unidad León',
  'Escuela Politécnica Nacional',
  'Escuela Politécnica de Chimborazo',
  'Escuela Politécnica del Ejercito',
  'Escuela Popular Accelerated Family Learning',
  'Escuela Popular/center For Training And Careers Family Lrng',
  'Escuela Superior Politécnica del Litoral',
  'Escuela Superior de Gestion Comercial y Marketing (ESIC)',
  'Escuela Superiore de Administración Pública',
  'Escuela Verde',
  'Escuela de Administración de Negocios',
  'Escuela de Arquitectura y Diseño',
  'Escuela de Ingeniería de Antioquia',
  'Escuela de Policia "General Santander"',
  'Esd 112 Open Doors Reengagement',
  'Ese Birth Thru Age 5',
  'Ese Countywide',
  'Ese Homebound',
  'Ese Hospital Homebound Instructional Services',
  'Ese Other Teaching Services',
  'Ese Pre Kindergarten Disabilities Center',
  'Ese Related Services',
  'Ese Special Programs',
  'Ese Transition',
  'Esek Hopkins Middle',
  'Eshelman Avenue Elementary',
  'Eshleman El Sch',
  'Eskdale High',
  'Esl Newcomer Academy',
  'Esl Teen Literacy Program',
  'Esmond Elem School',
  'Esmond Station School',
  'Esmt-is 190',
  'Espam Formation University',
  'Espanola Valley High',
  'Esparto Elementary',
  'Esparto High',
  'Esparto Middle',
  'Esparza El',
  'Especializada De Bellas Artes',
  'Especially Kidz Health & Rehab Cnt',
  'Esperanza Academy Charter School',
  'Esperanza Charter School',
  'Esperanza Cyber Cs',
  'Esperanza Education Center',
  'Esperanza Elementary',
  'Esperanza Elementary School',
  'Esperanza Gonzalez',
  'Esperanza High',
  'Esperanza High (continuation)',
  'Esperanza Medrano El',
  'Esperanza Middle',
  'Esperanza Prep',
  'Esperanza Prepatory Academy',
  'Esperanza School',
  'Esperero Canyon Middle School',
  'Espino',
  'Espinosa Kuilan',
  'Esplanade Elementary',
  'Espy Elem.',
  'Esquire Hills Elementary',
  'Essence School',
  'Essex Campus Academy',
  'Essex Co Youth House',
  'Essex County College',
  'Essex Elementary',
  'Essex Elementary School',
  'Essex Fells Elementary School',
  'Essex High',
  'Essex High School',
  'Essex Intermediate',
  'Essex Junior Academy',
  'Essex Junior-senior High School',
  'Essex Middle School',
  'Essex Regional School',
  'Essex Rgc',
  'Essex Street Academy',
  'Essex Technical High School',
  'Essie Mae Kiser Foxx Charter School',
  'Essrig Elementary School',
  'Estabrook Elementary',
  'Estacada High School',
  'Estacada Middle School',
  'Estacado H S',
  'Estacado Middle',
  'Estancia High',
  'Estancia Middle',
  'Estancia Valley Classical Academy',
  'Estates Elementary School',
  'Esteban Rosado Baez',
  'Esteban Torres East La Performing Arts Magnet',
  'Esteem Academy',
  'Estell Manor Elementary School',
  'Estelle Elementary School',
  'Estelle Kampmeyer Elem School',
  'Estelle Sauget School Of Choice',
  'Estelline Elementary - 02',
  'Estelline High School - 01',
  'Estelline Jr. High - 03',
  'Estem Elementary School',
  'Estem Middle School',
  'Estem Public Charter High School',
  'Ester Feliciano Mendoza',
  'Estero High School',
  'Estes Elementary',
  'Estes Elementary School',
  'Estes Hills Elementary',
  'Estes M Mcdoniel Elementary School',
  'Estes Park High School',
  'Estes Park K-5 School',
  'Estes Park Middle School',
  'Estes Park Options School',
  'Esther Bennett Elementary',
  'Esther Dennis Middle School',
  'Esther F. Garrison School For The Arts',
  'Esther L. Walter',
  'Esther Lindstrom Elementary',
  'Esther Rivera (paso Seco)',
  'Estherville Lincoln Central High School',
  'Estherville Lincoln Central Middle School',
  'Estherwood Elementary School',
  'Estill County High School',
  'Estill County Middle School',
  'Estill County Success Academy',
  'Estill Elementary',
  'Estill High',
  'Estill Middle',
  'Estill Springs Elementary',
  'Estonian Academy of Arts',
  'Estonian Academy of Music and Theatre',
  'Estonian Academy of Security Sciences',
  'Estonian Business School',
  'Estonian University of Life Sciences',
  'Estrada Achievement Ctr',
  'Estrella Elementary',
  'Estrella Foothills High School',
  'Estrella High School',
  'Estrella Middle School',
  'Estrella Mountain Community College',
  'Estrella Mountain Elementary School',
  'Estrella Vista Elementary School',
  'Estrellita Continuation High',
  'Estudillo Elementary',
  'Esu #9 Preschool Home Base Prg',
  'Esu 2 Independent School',
  'Eswood C C Grade School',
  'Esy Summer Center',
  'Esztergom Theological College',
  'Ethan A Chase Middle',
  'Ethan B. Allen Elementary',
  'Ethan Elementary - 02',
  'Ethan High School - 01',
  'Ethan Jr. High - 03',
  'Ethel A. Jacobsen Elementary School',
  'Ethel Attendance Center',
  'Ethel Boyes Elementary School',
  'Ethel C Bobcean Elem School',
  'Ethel Dwyer Middle',
  'Ethel F. Beckford/richmond Primary Learning Center',
  'Ethel Hoppock Middle School',
  'Ethel I. Baker Elementary',
  'Ethel K Fyle Elementary School',
  'Ethel Koger Beckham Elementary',
  'Ethel Kucera Middle',
  'Ethel M Burke Elementary School',
  'Ethel M. Evans Elementary',
  'Ethel M. Gildersleeve Middle',
  'Ethel M. Taylor Academy',
  'Ethel Mcknight Elementary School',
  'Ethel Phillips Elementary',
  'Ethel R Jones Elementary School',
  'Ethel Reed Es',
  'Ethel Schoeffner Elementary School',
  'Ethel W. Kight Elementary School',
  'Ethel W. Staton Elementary School',
  'Ethiopian Civil Service University',
  'Ethos Academy- A Challenge Foundation Academy',
  'Ethridge El',
  'Ethridge Elementary',
  'Etisalat University College',
  'Etiwanda Colony Elementary',
  'Etiwanda Community Day',
  'Etiwanda High',
  'Etiwanda Intermediate',
  'Etna Community Day',
  'Etna Elementary',
  'Etna Elementary School',
  'Etna Road Elementary School',
  'Etna Union High',
  'Etna-dixmont School',
  'Etoile Academy Charter School',
  'Etoile Daep',
  'Etoile El',
  'Eton Elementary School',
  'Etowah County Alternative School',
  'Etowah County Special Education Learning Center',
  'Etowah Elementary',
  'Etowah High School',
  'Etowah Middle School',
  'Etta Dale Jhs',
  'Etta Gero School No. 9',
  'Ettrick Elementary',
  'Etude Elementary',
  'Etude High',
  'Etude Middle',
  'Eubank Elementary School',
  'Eubanks Int',
  'Eucalyptus',
  'Eucalyptus Elementary',
  'Eucalyptus Hills Elementary',
  'Euchee Creek Elementary School',
  'Euclid Avenue Elementary',
  'Euclid Central Middle School',
  'Euclid Elem School',
  'Euclid Elementary',
  'Euclid Elementary School',
  'Euclid High School',
  'Euclid Middle School',
  'Euclid Park Elementary School',
  'Euclid Preparatory School',
  'Euclid Schools Early Learning Center',
  'Eudora Elementary School',
  'Eudora High School',
  'Eudora Middle School',
  'Eufaula Elementary School',
  'Eufaula Es',
  'Eufaula High School',
  'Eufaula Hs',
  'Eufaula Ms',
  'Eufaula Primary School',
  "Eugene 'gene' Kranz J H",
  'Eugene A. Tighe Middle School',
  'Eugene Ashley High',
  'Eugene Auer Memorial School',
  'Eugene B Elliott Elem School',
  'Eugene Brooks Middle School',
  'Eugene C Vining',
  'Eugene Education Options',
  'Eugene Elem.',
  'Eugene Field A+ Elementary - 16',
  'Eugene Field Elem',
  'Eugene Field Elem School',
  'Eugene Field Elem.',
  'Eugene Field Elementary',
  'Eugene Field Es',
  'Eugene Field School',
  'Eugene High',
  'Eugene M Nikkari Elementary',
  'Eugene Padan Elementary',
  'Eugene Ware Elem',
  'Eugene Wright Science And Technology Academy',
  'Eugenia B. Thomas K-8 Center',
  'Eugenio Brac',
  'Eugenio Gonzalez Gonzalez',
  'Eugenio Guerra Cruz',
  'Eugenio Le Compte Benitez',
  'Eugenio Maria De Hostos',
  'Eugenio Maria De Hostos (intermedia)',
  'Eugenio Maria De Hostos (superior)',
  'Eugenio Maria De Hostos Center For Early Childhood Education',
  'Eugenio Maria De Hostos Charter School',
  'Eugenio Maria De Hostos Cs',
  'Eugenio Maria De Hostos Microsociety School',
  'Euharlee Elementary School',
  'Eula El',
  'Eula H S',
  'Eula J H',
  'Eulalie Rivera Elementary School',
  'Euless J H',
  'Eunice Career & Technical Education Center',
  'Eunice Elementary School',
  'Eunice High',
  'Eunice High School',
  'Eunice Junior High School',
  'Eunice Sato Academy Of Math & Science',
  'Eunice Smith Elem School',
  'Eunice Thomas Elem.',
  'Euper Lane Elementary School',
  'Eupisd Community Based Early Childhood Services',
  'Eupora Elementary School',
  'Eupora High School',
  'Eura Brown Elementary School',
  'Eurasia International University',
  'Eurasian Institute of market',
  'Eureka Community',
  'Eureka County High School',
  'Eureka County Online School',
  'Eureka Elem.',
  'Eureka Elementary',
  'Eureka Elementary - 02',
  'Eureka Elementary School',
  'Eureka Heights Elementary School',
  'Eureka High School',
  'Eureka High School - 01',
  'Eureka Jr/sr High',
  'Eureka Middle School',
  'Eureka Middle School - 03',
  'Eureka Middle School 5-8',
  'Eureka School',
  'Eureka Senior High',
  'Eureka Springs Elem. School',
  'Eureka Springs High School',
  'Eureka Springs Middle School',
  'Eureka Sr. High',
  'Europa Fachhochschule Fresenius',
  'Europa-Universität Viadrina Frankfurt (Oder)',
  'European Academy of Arts in Warsaw',
  'European Business School',
  'European Business School Schloß Reichartshausen',
  'European Carolus Magnus University',
  'European College of Liberal Arts',
  'European Graduate School Media & Communications',
  'European Humanities University',
  'European Institute of Education',
  'European International University',
  'European Management Center Paris',
  'European Management School',
  'European Open University',
  'European Regional Educational Academy of Armenia',
  'European School of Economics',
  'European School of Management - Tbilisi',
  'European University',
  'European University Athens Campus',
  'European University Cyprus',
  'European University Institute',
  'European University Portugal',
  'European University at St.Petersburg',
  'European University of Lefke',
  'Europäische Betriebswirtschafts-Akademie',
  'Europäische Fachhochschule',
  'Eustace H S',
  'Eustace Int',
  'Eustace Middle',
  'Eustace Pri',
  'Eustis Elementary School',
  'Eustis Heights Elementary School',
  'Eustis High School',
  'Eustis Middle School',
  'Eustis-farnam High School',
  'Eutaw Primary School',
  'Eutaw-marshburn Elementary',
  'Eutimio Salazar Elementary',
  'Ev Cain 21st Century Stem Charter',
  'Ev Online',
  'Ev Parent Partnership',
  'Eva B Stokely Elementary',
  'Eva G Simmons Elementary School',
  'Eva Gordon Elementary School',
  'Eva Gordon Upper Elementary School',
  'Eva Hoyt Zippel School',
  'Eva Marshall Elementary School',
  'Eva R Baca Elementary School',
  'Eva School',
  'Eva Turner Elementary School',
  'Eva Wolfe Elementary School',
  'Eva Y Patria Custodio',
  'Evadale Daep',
  'Evadale El',
  'Evadale H S',
  'Evadale J H',
  'Evaline Elementary School',
  'Evaluation And Detention Center',
  'Evamere Elementary School',
  'Evangelina Garza El',
  'Evangeline Central School',
  'Evangeline Elementary School',
  'Evangeline Roberts Institute Of Learning',
  'Evangelische Fachhochschule Berlin Fachhochschule für Sozialarbeit und Sozialpädagogik',
  'Evangelische Fachhochschule Darmstadt',
  'Evangelische Fachhochschule Freiburg Hochschule für Soziale Arbeit, Diakonie und Religionspädagogik',
  'Evangelische Fachhochschule Hannover',
  'Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen',
  'Evangelische Fachhochschule Nürnberg',
  'Evangelische Fachhochschule Reutlingen-Ludwigsburg Hochschule für Soziale Arbeit, Religionspädagogik und Diakonie',
  'Evangelische Fachhochschule Rheinland-Westfalen-Lippe',
  'Evangelische Fachhochschule für Religionspädagogik und Gemeindediakonie Moritzburg',
  'Evangelische Fachhochschule für Sozialpädagogik der "Diakonenanstalt des Rauhen Hauses" Hamburg',
  'Evangelische Hochschule für Soziale Arbeit Dresden (FH)',
  'Evangelische Theologische Faculteit Leuven',
  'Evans City El Sch',
  'Evans City Ms',
  'Evans El',
  'Evans El Sch',
  'Evans Elementary',
  'Evans Elementary School',
  'Evans Falls El Sch',
  'Evans High School',
  'Evans International Elementary School',
  'Evans Junior High School',
  'Evans Middle',
  'Evans Middle School',
  'Evans Mills Primary School',
  'Evans Park School',
  'Evans Ranch Elementary',
  'Evans School',
  'Evans Ses',
  'Evansdale Elementary School',
  'Evanston Academy Elementary School',
  'Evanston High School',
  'Evanston Middle School',
  'Evanston Twp High School',
  'Evansville Attendance Center',
  'Evansville Elementary',
  'Evansville High',
  'Evant El',
  'Evant H S',
  'Evaristo Camacho',
  'Evaristo Rivera Chevremont',
  'Evart Adult Education',
  'Evart Elementary School',
  'Evart High School',
  'Evart Middle School',
  'Evarts C. Stevens School',
  'Evarts Elementary School',
  'Eveleth Gilbert Hope Program',
  'Eveleth-gilbert Junior High',
  'Eveleth-gilbert Senior High',
  'Evelyn Alexander School',
  'Evelyn Carr Elementary',
  'Evelyn D Houston Elementary School',
  'Evelyn Hamlow Elementary',
  'Evelyn Hanshaw Middle',
  'Evelyn Stuckey Elementary School',
  'Evelyn Turlington El',
  'Evendale Elementary',
  'Evendale Elementary School',
  'Evening Educational Options',
  'Evening High School',
  'Evening School Of Excellence',
  'Evening Street Elementary School',
  'Everest Charter School',
  'Everest College',
  'Everest High School',
  'Everest Middle',
  'Everest Public High',
  'Everest Value',
  'Everett A. Rea Elementary',
  'Everett Alvarez High',
  'Everett Area El Sch',
  'Everett Area Hs',
  'Everett Area Ms',
  'Everett Community College',
  'Everett Dirksen Elementary Sch',
  'Everett Elem School',
  'Everett Elementary School',
  'Everett F Kerr Middle School',
  'Everett High',
  'Everett High School',
  'Everett Middle',
  'Everett Reengagement Academy',
  'Everett W Robinson',
  'Everett Wade Juvenile Ctr.',
  'Everette Lee Degolyer El',
  'Everetts Elementary S.t.e.m. Academy',
  'Everglades City School',
  'Everglades Elementary',
  'Everglades Elementary School',
  'Everglades High School',
  'Everglades K-8 Center',
  'Everglades Preparatory Academy',
  'Everglades Preparatory Academy High School',
  'Evergreen Academy',
  'Evergreen Academy Elem School',
  'Evergreen Avenue Elementary',
  'Evergreen Avenue Elementary School',
  'Evergreen Charter School',
  'Evergreen Community Charter',
  'Evergreen Community Cs',
  'Evergreen Community Day (5-8)',
  'Evergreen Community Day K-5',
  'Evergreen Continuation',
  'Evergreen El Sch',
  'Evergreen Elementary',
  'Evergreen Elementary School',
  'Evergreen Forest Elementary',
  'Evergreen Heights Elementary',
  'Evergreen High',
  'Evergreen High School',
  'Evergreen Institute Of Excellence',
  'Evergreen Jr High',
  'Evergreen Jr High School',
  'Evergreen Junior High',
  'Evergreen Junior High School',
  'Evergreen Middle',
  'Evergreen Middle School',
  'Evergreen Middle School For Urban Exploration',
  'Evergreen Mill Elementary',
  'Evergreen Park Elementary',
  'Evergreen Park High School',
  'Evergreen Primary',
  'Evergreen School',
  'Evergreen State College',
  'Evergreen Valley College',
  'Evergreen Valley High',
  'Everitt Middle School',
  'Everman Academy H S',
  'Everman H S',
  'Evers El',
  'Evers Elem School',
  'Evers Park El',
  'Everson Elementary',
  'Everton Elem.',
  'Everton Elementary School',
  'Everton High',
  'Evit - Apache Junction High School',
  'Evit - Arcadia High School',
  'Evit - Arizona College Prep Erie Campus',
  'Evit - Basha High School',
  'Evit - Brimhall Jr. High School',
  'Evit - Campo Verde High School',
  'Evit - Carson Jr. High School',
  'Evit - Casteel High School',
  'Evit - Chandler High School',
  'Evit - Chaparral High School',
  'Evit - Combs High School',
  'Evit - Compadre High School',
  'Evit - Corona Del Sol High School',
  'Evit - Coronado High School',
  'Evit - Desert Mountain High School',
  'Evit - Desert Ridge High School',
  'Evit - Desert Vista High School',
  'Evit - Dobson High School',
  'Evit - East Campus',
  'Evit - East Valley Academy',
  'Evit - East Valley Institute Of Technology',
  'Evit - Fountain Hills Vocational Center',
  'Evit - Fremont Junior High',
  'Evit - Gilbert High School',
  'Evit - Hamilton High School',
  'Evit - Hendrix Junior High',
  'Evit - Highland High School',
  'Evit - Higley High School',
  'Evit - Kino Junior High',
  'Evit - Marcos De Niza High School',
  'Evit - Mcclintock High School',
  'Evit - Mesa High School',
  'Evit - Mesa Junior High',
  'Evit - Mesquite High School',
  'Evit - Mountain Pointe High School',
  'Evit - Mountain View High School',
  'Evit - Perry High School',
  'Evit - Poston Junior High',
  'Evit - Powell Jr. High School',
  'Evit - Queen Creek High School',
  'Evit - Red Mountain High School',
  'Evit - Rhodes Junior High',
  'Evit - Saguaro High School',
  'Evit - Scottsdale Vocational Center',
  'Evit - Shepherd Junior High',
  'Evit - Skyline High School',
  'Evit - Smith Junior High',
  'Evit - Stapley Junior High',
  'Evit - Taylor Junior High',
  'Evit - Tempe Auto',
  'Evit - Tempe High School',
  'Evit - Westwood High School',
  'Evit - Williams Field High School',
  'Evit East Apache Junction',
  'Evit North Fountain Hills',
  'Evolution Academy Beaumont',
  'Evolution Academy Charter School',
  'Evolution Academy Houston',
  'Evolutions High School',
  'Evolve Academy',
  'Ewa Beach Elementary School',
  'Ewa Elementary School',
  'Ewa Makai Middle School',
  'Ewalt Elementary',
  'Ewell D Walker Middle',
  'Ewell School',
  'Ewentrout Creek Consolidated School',
  'Ewes-eckerd Youth Challenge',
  "Ewha Women's University",
  'Ewing Elementary',
  'Ewing Elementary School',
  'Ewing Halsell Ms',
  'Ewing High School',
  'Ewing Marion Kauffman High',
  'Ewing Marion Kauffman Middle',
  'Ewing Young Elementary School',
  'Ewing-northern Elem School',
  'Exalt Academy Of Southwest Little Rock',
  'Excel Academy',
  'Excel Academy (jjaep)',
  'Excel Academy (murworth)',
  'Excel Academy - Englewood Hs',
  'Excel Academy - Southwest Hs',
  'Excel Academy - Woodlawn Hs',
  'Excel Academy @ Francis M. Wood High',
  'Excel Academy Charter School',
  'Excel Academy Education Transition Center',
  'Excel Academy Pcs',
  'Excel Academy Public Charter',
  'Excel Alternative Program',
  'Excel Center - Anderson',
  'Excel Center - Clarksville',
  'Excel Center - Hammond',
  'Excel Center - Kokomo',
  'Excel Center - Lafayette',
  'Excel Center - Lafayette Square',
  'Excel Center - Noblesville',
  'Excel Center - Richmond',
  'Excel Center - Shelbyville',
  'Excel Center - South Bend',
  'Excel Center - University Heights',
  'Excel Center For Adult Learners',
  'Excel Center Lockhart',
  'Excel Charter Academy',
  'Excel H S Of Choice',
  'Excel High School',
  'Excel Prep Charter',
  'Excel Prep Charter - Ie',
  'Excel Public Charter School',
  'Excel School',
  'Excelencia School',
  'Excell Academy Charter',
  'Excell Program',
  'Excellence Academy Charter School',
  'Excellence Boys Charter School Of Bedford Stuyvesant',
  'Excellence Girls Charter School',
  'Excellence In Leadership Academy',
  'Excelsior Academy',
  'Excelsior Charter',
  'Excelsior Charter Academy',
  'Excelsior Charter Of Broward',
  'Excelsior Classical Academy',
  'Excelsior El',
  'Excelsior Elementary',
  'Excelsior High School',
  'Excelsior Language Academy Of Hialeah',
  'Excelsior Middle',
  'Excelsior Middle School',
  'Excelsior Prep Charter School',
  'Excelsior Preparatorty High School',
  'Excelsior Springs Career Ctr.',
  'Excelsior Springs High',
  'Excelsior Springs Middle',
  'Excelsior Springs Tech. High',
  'Excelsior Youth Center School',
  'Exceptional Child Coop.',
  'Exceptional Children Special Placements',
  "Exceptional Children's Cooperative",
  'Exceptional Pupil Coop',
  'Exceptional School',
  'Exceptional Student Education',
  'Executive Education Academy Charter Scho',
  'Executive Preparatory Academy Of Finance',
  'Exeter Community Day',
  'Exeter Elem.',
  'Exeter High',
  'Exeter High School',
  'Exeter Independent Study (alternative)',
  'Exeter Twp Jhs',
  'Exeter Twp Shs',
  'Exeter Union High',
  'Exeter-milligan Elem-exeter',
  'Exeter-milligan Elem-milligan',
  'Exeter-milligan High School',
  'Exeter-west Greenwich Reg. Jr.',
  'Exeter-west Greenwich Regional',
  'Eximius College Preparatory Academy',
  'Exira/ehk Middle School',
  'Exiraelk Horn-kimballton Elementary',
  'Exiraelk Horn-kimballton High School',
  'Expedition Academy',
  'Expeditionary Learning Academy At Moylan School',
  'Expeditionary Learning Middle School',
  'Expeditionary Learning School For Community Leaders',
  'Exploration Academy',
  'Exploration Ele Cs - Science-technol',
  'Explorations',
  'Explorations Academy',
  'Explore',
  'Explore 2000 Middle School',
  'Explore Academy',
  'Explore Charter School',
  'Explore Community School',
  'Explore Elementary',
  'Explore Empower Charter School',
  'Explore Exceed Charter School',
  'Explore Excel Charter School',
  'Explore Knowledge Academy Charter Elementary',
  'Explore Knowledge Academy Charter Secondary',
  'Explorer Academy',
  'Explorer Community School',
  'Explorer Elementary',
  'Explorer Elementary - 04',
  'Explorer Elementary School',
  'Explorer K-8',
  'Explorer Middle School',
  'Expo Alternative Learning Center',
  'Expo For Excellence Elementary Sch',
  'Expressions Learning Arts Academy',
  'Extended Day Learning Program',
  'Extended Transition Countywide',
  'Extension Borinquen',
  'Extensions Correspondence',
  'Extera Public',
  'Extera Public School No. 2',
  'External Sites',
  'Exton El Sch',
  'Eyer Ms',
  'Eyes-e-nini-hassee Camp',
  'Eyestone Elementary School',
  'Eyre Elementary School',
  'Ezard Elem.',
  'Ezekiel Balderas Elementary',
  'Ezel Elementary School',
  'Ezequiel Ramos Lasanta',
  'Ezequiel Tafoya Alvarado Academy',
  'Ezra Eby Elementary School',
  'Ezra H Baker Innovation School',
  'Ezra L. Nolan School',
  'Ezra Millard Elementary Sch',
  'Ezra Sparrow Early Childhood Center',
  'Ezzell El',
  'Eötvös Lorand University',
  'F A Cleveland',
  'F A Day Middle',
  'F B Mccord Elem School',
  'F D Roosevelt El',
  'F E Bellows Elementary School',
  'F E Miley School',
  'F E Peacock Middle School',
  'F E Smith School',
  'F L Moffett Pri',
  'F L Schlagle High',
  'F P Caillet El',
  'F P Donnelly School',
  'F S Banford Elementary School',
  'F S Ervin Elementary School',
  'F S Lara Academy',
  'F T Burns Elementary School',
  'F T Burns Middle School',
  'F W Gross El',
  'F Willard Crichfield Elementary',
  'F. B. Gwynn Educational Center',
  'F. D. Roosevelt Elementary',
  'F. E. Dubose Career Center',
  'F. J. Delaine Elementary',
  'F. J. Kingsbury School',
  'F. K. White Middle School',
  'F. L. Stanton Elementary School',
  'F. Niel Postlethwait Middle School',
  'F. Niel Postlethwait Middle School Ilc',
  'F. P. Tillman Elem.',
  'F. W. Buchholz High School',
  'F. W. Holbein Middle School',
  'F.b. Leon Guerrero Middle School',
  'F.d. Moon Es',
  'F.i. Denning Center Of Technology And Careers',
  'F.w. Kling Jr. Elementary',
  'FAAP - Fundação Armando Alvares Penteado',
  'FACES',
  'FACTORYVILLE CHRISTIAN SCHOOL',
  'FAE Business School - Faculdade de Administração e Economia',
  'FAIR ACRES COUNTRY DAY SCHOOL',
  'FAIRBANKS MONTESSORI ASSOCIATION',
  'FAIRBORN PRESCHOOL & DAYCARE CENTER',
  'FAIRFAX BAPTIST TEMPLE ACADEMY',
  'FAIRFAX CHRISTIAN SCHOOL',
  'FAIRFIELD AMISH MENNONITE SCHOOL',
  'FAIRFIELD CHRISTIAN ACADEMY',
  'FAIRFIELD CHRISTIAN SCHOOL',
  'FAIRFIELD COLLEGE PREPARATORY',
  'FAIRFIELD COUNTRY DAY SCHOOL',
  'FAIRFIELD COUNTY SDA SCHOOL',
  'FAIRFIELD EDUCATIONAL BULDING',
  'FAIRFIELD MENNONITE SCHOOL',
  'FAIRFIELD SCHOOL',
  'FAIRHAVEN CHRISTIAN SCHOOL',
  'FAIRHAVEN SCHOOL',
  'FAIRHAVEN SDA SCHOOL',
  'FAIRHILL CHRISTIAN SCHOOL',
  'FAIRHILL SCHOOL',
  'FAIRMONT CATHOLIC GRADE SCHOOL',
  'FAIRMONT PREPARATORY ACADEMY',
  'FAIRMONT PRIVATE SCHOOL',
  'FAIRMONT PRIVATE SCHOOL, NORTH TUSTIN CAMPUS',
  'FAIRMONT PRIVATE SCHOOLS',
  'FAIRVIEW AMISH SCHOOL',
  'FAIRVIEW CHRISTIAN SCHOOL',
  'FAIRVIEW EARLY CHILDHOOD CENTER',
  'FAIRVIEW MENNONITE SCHOOL',
  'FAIRVIEW PAROCHIAL SCHOOL',
  'FAIRVIEW SCHOOL',
  'FAIRVILLE FRIENDS SCHOOL INC',
  'FAIRWAY EDUCATION CENTER',
  'FAIRWINDS CHRISTIAN SCHOOL',
  'FAIRY TALE ACADEMY',
  'FAITH ACADEMY',
  'FAITH ACADEMY LAKE ARROWHEAD',
  'FAITH ACADEMY OF BELLVILLE',
  'FAITH ACADEMY OF JACKSON',
  'FAITH ACADEMY OF MARBLE FALLS',
  'FAITH ADVENTIST CHRISTIAN SCHOOL',
  'FAITH ARTS ACADEMY',
  'FAITH ASSEMBLY CHRISTIAN ACADEMY',
  'FAITH AVADEMY OF CHRISTIAN EDUCATION',
  'FAITH BAPTIST ACADEMY',
  'FAITH BAPTIST CHILD DEVELOPMENT CENTER',
  'FAITH BAPTIST CHRISTIAN ACADEMY',
  'FAITH BAPTIST CHRISTIAN SCHOOL',
  'FAITH BAPTIST SCHOOL',
  'FAITH BAPTIST SCHOOLS',
  'FAITH BIBLE ACADEMY',
  'FAITH BIBLE CHRISTIAN ACADEMY',
  'FAITH BIBLE CHRISTIAN SCHOOL',
  'FAITH BIBLE HIGH SCHOOL',
  'FAITH BUILDERS CHRISTIAN SCHOOL',
  'FAITH CENTRAL CHRISTIAN ACADEMY',
  'FAITH CHRISTIAN ACADEMY',
  'FAITH CHRISTIAN ACADEMY & PRESCHOOL',
  'FAITH CHRISTIAN ACADEMY OF GRIFFIN',
  'FAITH CHRISTIAN ACADEMY OF ORLANDO',
  'FAITH CHRISTIAN SCHOOL',
  'FAITH CHRISTIAN SCHOOL & DAYCARE',
  'FAITH CHRISTIAN SCHOOL OF KEARNEY',
  'FAITH CHRISTIAN SCHOOL SITE 2',
  'FAITH COMMUNITY ACADEMY',
  'FAITH COMMUNITY CHRISTIAN SCHOOL',
  'FAITH COUNTRY CHAPEL PRESCHOOL & KINDERGARTEN',
  'FAITH DIAMOND CHRISTIAN ACADEMY',
  'FAITH EDUCATION ACADEMY',
  'FAITH EVANGELICAL LUTHERAN SCHOOL',
  'FAITH FELLOWSHIP CHRISTIAN SCHOOL',
  'FAITH FELLOWSHIP SCHOOL',
  'FAITH GENERATION ACADEMY',
  'FAITH HARVEST CHRISTIAN ACADEMY',
  'FAITH HERITAGE CHRISTIAN ACADEMY',
  'FAITH HERITAGE SCHOOL',
  'FAITH HILLS CHRISTIAN SCHOOL',
  'FAITH ISLAMIC ACADEMY',
  'FAITH LIFE ACADEMY',
  'FAITH LUTHERAN ACADEMY',
  'FAITH LUTHERAN CHURCH & SCHOOL',
  'FAITH LUTHERAN DAY SCHOOL',
  'FAITH LUTHERAN HIGH SCHOOL',
  'FAITH LUTHERAN MIDDLE SCHOOL & HIGH SCHOOL',
  'FAITH LUTHERAN SCHOOL',
  'FAITH MENNONITE HIGH SCHOOL',
  'FAITH MISSIONARY ACADEMY',
  'FAITH MOUNTAIN CHRISTIAN ACADEMY',
  'FAITH OUTREACH ACADEMY',
  'FAITH OUTREACH EDUCATION CENTER',
  'FAITH PREP SCHOOL',
  'FAITH PREPARATORY SCHOOL',
  'FAITH PRESBYTERIAN KINDERGARTEN',
  'FAITH PRESBYTERIAN PRESCHOOL & KINDERGARTEN',
  'FAITH PRESCHOOL & CHILD CARE',
  'FAITH TABERNACLE CHRISTIAN ACADEMY',
  'FAITH TABERNACLE SCHOOL',
  'FAITH TEMPLE CHRISTIAN SCHOOL',
  'FAITH WALK ACADEMY',
  'FAITH WEST ACADEMY',
  'FAITHFUL AMBASSADORS BIBLE',
  'FAITHWAY CHRISTIAN SCHOOL',
  'FALCON CHRISTIAN ACADEMY',
  'FALCON RIDGE RANCH',
  'FALL RIVER DEACONESS HOME SCHOOL',
  'FALLON SEVENTH-DAY ADVENTIST SCHOOL',
  'FALLS BAPTIST ACADEMY',
  'FALLS CHURCH EPISCOPAL DAY SCHOOL',
  'FALLSBURG CHEDER SCHOOL/BAIS YAAKOV',
  'FALMOUTH ACADEMY',
  'FALSE RIVER ACADEMY',
  'FAMILIES OF FAITH CHRISTIAN ACADEMY',
  'FAMILIES UNITED NETWORK ACADEMY',
  'FAMILY ACADEMY',
  'FAMILY CHRISTIAN ACADEMY',
  'FAMILY CHRISTIAN ACADEMY ORLANDO',
  'FAMILY CHRISTIAN CENTER SCHOOL',
  'FAMILY CHRISTIAN SCHOOL',
  'FAMILY COMMUNITY CHRISTIAN SCHOOL',
  'FAMILY FIRST LEARNING CENTER LLC',
  'FAMILY LIFE ACADEMY',
  'FAMILY LIFE CENTER CHRISTIAN ACADEMY',
  'FAMILY LIFE CHRISTIAN ACADEMY',
  'FAMILY LIFE MINISTRIES INTERNATIONAL ACADEMY',
  'FAMILY OF CHRIST CHILD DEVELOPMENT CENTER',
  'FAMILY OF CHRIST CHRISTIAN SCHOOL',
  'FAMILY OF FAITH CHRISTIAN SCHOOL',
  'FAMILY WORSHIP CENTER KINGERGARTEN',
  'FAMILY WORSHIP CHRISTIAN ACADEMY',
  'FANTASY ISLAND SCHOOL FOR KIDS',
  'FAR BROOK SCHOOL',
  'FAR HILLS COUNTRY DAY SCHOOL',
  'FAR HORIZONS MONTESSORI',
  'FAR HORIZONS MONTESSORI SCHOOL',
  'FARBER HEBREW DAY SCHOOL',
  'FARIBAULT LUTHERAN SCHOOL',
  'FARM AND NATURE DISCOVERY SCHOOL',
  'FARM LANE SCHOOL',
  'FARMERS CHRISTIAN ACADEMY',
  'FARMERSVILLE MENNONITE SCHOOL',
  'FARMING FOR THE FUTURE ACADEMY',
  'FARMINGTON MENNONITE SCHOOL',
  'FARMVIEW MONTESSORI GARDEN',
  'FASMAN YESHIVA HIGH SCHOOL',
  'FAST - National University of Computer and Emerging Sciences (NUCES)',
  'FATHER ALLOUEZ CATHOLIC SCHOOL',
  'FATHER ANDREW WHITE SJ SCHOOL',
  'FATHER JAMES B HAY SCHOOL',
  'FATHER JUDGE HIGH SCHOOL',
  'FATHER LOPEZ CATHOLIC HIGH SCHOOL',
  'FATHER MARQUETTE ELEMENTARY SCHOOL',
  'FATHER RYAN HIGH SCHOOL',
  'FATHER YERMO ELEMENTARY SCHOOL',
  'FATHER YERMO HIGH SCHOOL',
  'FATHERS HARBOR ACADEMY',
  'FAULKNER KINDERGARTEN',
  'FAULKNER SPRINGS CHRISTIAN SCHOOL',
  'FAVOR CHRISTIAN AVADEMY',
  'FAY HONEY KNOPP MEMORIAL SCHOOL',
  'FAY SCHOOL',
  'FAYERWEATHER STREET SCHOOL',
  'FAYETTE ACADEMY',
  'FAYETTE CHRISTIAN SCHOOL',
  'FAYETTE MONTESSORI SCHOOL',
  'FAYETTE SCHOOL',
  'FAYETTE ST ACADEMY',
  'FAYETTEVILLE ACADEMY',
  'FAYETTEVILLE CHRISTIAN SCHOOL',
  'FAYETTEVILLE CREATIVE SCHOOLS',
  'FC BOYD SR CHRISTIAN SCHOOL',
  'FD REESE CHRISTIAN ACADEMY',
  'FEATHER RIVER ADVENTIST SCHOOL',
  'FEDCAP - 4T',
  'FEED MY SHEEP CHRISTIAN SCHOOL',
  'FEI TIAN ACADEMY OF THE ARTS CALIFORNIA',
  'FEI XIANG BILINGUAL SCHOOL',
  'FELICIAN SCHOOL FOR EXCEPTIONAL CHILDREN',
  'FELLOWSHIP ACADEMY',
  'FELLOWSHIP BAPTIST ACADEMY',
  'FELLOWSHIP BAPTIST SCHOOL',
  'FELLOWSHIP CHRISTIAN ACADEMY',
  'FELLOWSHIP CHRISTIAN SCHOOL',
  'FELLOWSHIP HAVEN BIBLE SCHOOL',
  'FELLOWSHIP OF LEARNING SCHOOL',
  'FENWICK HIGH SCHOOL',
  'FERNCLIFF MANOR',
  'FERNDALE MONTESSORI CENTER',
  'FERNDALE SCHOOL',
  'FERNWOOD ACADEMY',
  'FERNWOOD MONTESSOURI',
  'FERRY FARM BAPTIST CHURCH PRESCHOOL/KINDERGARTEN',
  'FEYNMAN SCHOOL',
  'FFHS - Fernfachhochschule Schweiz',
  'FHNW - Fachhochschule Nordwestschweiz',
  'FHS St.Gallen',
  'FIDELES CHRISTIAN SCHOOL',
  'FIDLEY DE FIDLEY DO ACADEMY',
  'FIELD STONE ACADEMY',
  'FIELDS OF GREEN MONTESSORI SCHOOL',
  'FIELDSTONE ACADEMY',
  'FILLMORE CHRISTIAN ACADEMY',
  'FINCH ACADEMY LLC',
  'FINGER LAKES CHRISTIAN SCHOOL',
  'FINR',
  'FIREHOUSE PRESCHOOL',
  'FIRELANDS MONTESSORI ACADEMY',
  'FIREWHEEL CHRISTIAN ACADEMY',
  'FIRM FOUNDATION CHRISTIAN SCHOOL',
  'FIRST ACADEMY',
  'FIRST ACADEMY CHRISTIAN SCHOOL',
  'FIRST ACADEMY-LEESBURG',
  'FIRST APOSTOLIC CHRISTIAN',
  'FIRST ASSEMBLY CHRISTIAN ACADEMY',
  'FIRST ASSEMBLY CHRISTIAN ACADEMYDAYCARE',
  'FIRST ASSEMBLY CHRISTIAN SCHOOL',
  'FIRST ASSEMBLY CHRISTIAN SCHOOL & DAYCARE',
  'FIRST BAPTIST',
  'FIRST BAPTIST ACADEMY',
  'FIRST BAPTIST ACADEMY DALLAS',
  'FIRST BAPTIST ACADEMY OF UNIVERSAL CITY',
  'FIRST BAPTIST BRANDON CHRISTIAN ACADEMY',
  'FIRST BAPTIST CHILD DEVELOPMENT CENTER',
  'FIRST BAPTIST CHRISTIAN ACADEMY',
  'FIRST BAPTIST CHRISTIAN ACADEMY-TROY',
  'FIRST BAPTIST CHRISTIAN SCHOOL',
  'FIRST BAPTIST CHURCH CHILD DEVELOPMENT CENTER',
  'FIRST BAPTIST CHURCH CHRISTIAN SCHOOL',
  'FIRST BAPTIST CHURCH DAY SCHOOL',
  'FIRST BAPTIST CHURCH DENBIGH CHILD DEVT CENTER AND',
  'FIRST BAPTIST CHURCH KINDERGARTEN',
  'FIRST BAPTIST CHURCH OF BYRAM KDGN/DAYCARE',
  'FIRST BAPTIST CHURCH OF EAST BAY',
  'FIRST BAPTIST CHURCH PRESCHOOL & KINDERGARDEN',
  'FIRST BAPTIST CHURCH PRESCHOOL & KINDERGARTEN',
  'FIRST BAPTIST CHURCH SCHOOL',
  'FIRST BAPTIST CHURCH WEE SCHOOL',
  'FIRST BAPTIST CHURCH WEEKDAY EDUCATION',
  'FIRST BAPTIST CHURCH WEEKDAY PRESCHOOL AND KINDER',
  'FIRST BAPTIST CHURCH-PINSON',
  'FIRST BAPTIST DAY SCHOOL',
  'FIRST BAPTIST DAY SCHOOL AND KINDERGARTEN',
  'FIRST BAPTIST KINDER/DAYCARE',
  'FIRST BAPTIST KINDERGARTEN',
  'FIRST BAPTIST PRESCHOOL',
  'FIRST BAPTIST PRESCHOOL AND KINDERGARTEN',
  'FIRST BAPTIST READY ACADEMY CHRISTIAN SCHOOL',
  'FIRST BAPTIST SCHOOL',
  'FIRST BAPTIST SCHOOL OF LAUREL',
  'FIRST CENTURY GOSPEL SCHOOL',
  'FIRST CHILDREN SCHOOL',
  'FIRST CHRISTIAN ACADEMY',
  'FIRST CHRISTIAN DAY SCHOOL',
  'FIRST CHRISTIAN PENTECOSTAL',
  'FIRST CHRISTIAN SCHOOL',
  'FIRST COAST CHRISTIAN SCHOOL',
  'FIRST COVENANT TREEHOUSE PRESCHOOL & KINDERGARTEN',
  'FIRST DISCOVERIES ACADEMY',
  'FIRST ENGLISH LUTHERAN PRESCHOOL & KINDERGARTEN',
  'FIRST EVANGELICAL LUTHERAN SCHOOL',
  'FIRST FAITH PRESCHOOL & KINDERGARTEN',
  'FIRST FLINT SDA SCHOOL',
  'FIRST FRIENDS CHILD CARE SCHOOL',
  'FIRST FRIENDS CHRISTIAN ACADEMY',
  'FIRST FRIENDS DAY SCHOOL',
  'FIRST FRUITS CHRISTIAN ACADEMY',
  'FIRST GERMAN EVANGELICAL LUTHERAN SCHOOL',
  'FIRST GOOD SHEPHERD LUTHERAN SCHOOL',
  'FIRST IMMANUEL LUTHERAN SCHOOL',
  'FIRST IMPRESSIONS ACADEMY',
  'FIRST IMPRESSIONS CHILD CARE CENTER, INC.',
  'FIRST LOVE CHRISTIAN ACADEMY',
  'FIRST LUTHERAN CIRCLE OF LOVE',
  'FIRST LUTHERAN PRESCHOOL & KINDERGARTEN',
  'FIRST LUTHERAN SCHOOL',
  'FIRST LUTHERAN SCHOOL FOR YOUNG CHILDREN',
  'FIRST METHODIST SCHOOL',
  'FIRST METHODIST SCHOOL INC.',
  'FIRST PETERS CAREER COLLEGE',
  "FIRST PHASE CHILDREN'S ACADEMY",
  'FIRST PRESBYTERIAN ACADEMY',
  'FIRST PRESBYTERIAN CHRISTIAN ACADEMY',
  'FIRST PRESBYTERIAN CHURCH KINDERGARTEN',
  'FIRST PRESBYTERIAN CHURCH KINDERGARTEN & PRESCHOOL',
  'FIRST PRESBYTERIAN CHURCH PRESCHOOL',
  'FIRST PRESBYTERIAN CHURCH SCHOOL',
  'FIRST PRESBYTERIAN DAY SCHOOL',
  'FIRST PRESBYTERIAN KINDERGARTEN',
  'FIRST PRESBYTERIAN MONTESSORI PRESCHOOL & KDGN',
  'FIRST PRESBYTERIAN PRESCHOOL',
  'FIRST PRESBYTERIAN PRESCHOOL & KINDERGARTEN',
  'FIRST PROTESTANT CHURCH SCHOOL',
  'FIRST SCHOOL',
  'FIRST SOUTHERN CHRISTIAN SCHOOL',
  'FIRST SOUTHERN METHODIST CHR & WESLEY CHRISTIAN SC',
  'FIRST STEPS',
  'FIRST STEPS ACADEMY',
  'FIRST STEPS SCHOOL',
  "FIRST UMC KID'S PAD",
  'FIRST UMC WEEKDAY SCHOOL',
  'FIRST UNITED METHODIST CHRISTIAN SCHOOL',
  'FIRST UNITED METHODIST KDGN/NURSERY SCHOOL',
  'FIRST UNITED METHODIST KINDERGARTEN & DAYCARE',
  'FIRST UNITED METHODIST PRESCHOOL',
  'FIRST UNITED METHODIST WEEKDAY MINISTRY',
  'FIRST WESLEYAN CHRISTIAN SCHOOL',
  'FIRSTART ACADEMY',
  'FISH CREEK SCHOOL',
  'FISHBURNE MILITARY SCHOOL',
  'FISHER ISLAND DAY SCHOOL',
  'FISHERS CHRISTIAN ACADEMY',
  'FISHERS MONTESSORI SCHOOLS',
  'FISHING CREEK PAROCHIAL SCHOOL',
  'FIVE ACRE SCHOOL',
  'FIVE ACRES SCHOOL',
  'FIVE FALLS CHRISTIAN SCHOOL',
  'FIVE MILE MONTESSORI',
  'FIVE OAKS ACADEMY',
  'FIVE OAKS SEVENTH-DAY ADVENTIST CHRISTIAN SCHOOL',
  'FIVE STAR MONTESSORI SCHOOL',
  'FLAGET ELEMENTARY SCHOOL',
  'FLAGSTAFF COMMUNITY CHRISTIAN SCHOOL',
  'FLAMING SWORD CHRISTIAN ACADEMY',
  "FLANAGAN'S PRESCHOOL",
  'FLAT IRON SCHOOL',
  'FLAT ROCK AMISH SCHOOL',
  'FLATBUSH SDA SCHOOL',
  'FLEETWOOD CHRISTIAN DAY SCHOOL',
  'FLETCHER ACADEMY',
  'FLEX SCHOOL',
  'FLINT ACADEMY',
  'FLINT HILLS CHRISTIAN SCHOOL',
  'FLINT RIVER ACADEMY, INC',
  'FLINTRIDGE MONTESSORI SCHOOL',
  'FLINTRIDGE PREPARATORY SCHOOL',
  'FLINTRIDGE SACRED HEART ACADEMY',
  'FLINTSTONE MENNONITE SCHOOL',
  'FLORAL CREST SCHOOL',
  'FLORENCE CHRISTIAN SCHOOL',
  'FLORIDA AUTISM CENTER',
  'FLORIDA CHRISTIAN SCHOOL',
  'FLORIDA COLLEGE ACADEMY',
  'FLOSSMOOR MONTESSORI SCHOOL',
  'FLOWERS MONTESSORI',
  'FLOYD MONTESSORI',
  'FLUSHING CHRISTIAN SCHOOL',
  'FLY CREEK AMISH SCHOOL',
  'FM KIRBY CHILDRENS CENTER',
  'FMI S.T.E.M. ACADEMY 34761',
  'FMI STEM ACADEMY',
  'FOCUS CENTER FOR AUTISM~FRESH START SCHOOL',
  'FOCUS ON THE FUTURE TRAINING CENTER',
  'FOLLOW THE CHILD MONTESSORI SCHOOL',
  'FOLSOM EDUCATIONAL ACADEMY',
  'FOLSOM MONTESSORI SCHOOL',
  'FON University',
  'FOND DU LAC CHRISTIAN SCHOOL',
  'FONTANA PAROCHIAL SCHOOL',
  'FONTBONNE ACADEMY',
  'FONTBONNE HALL ACADEMY',
  'FOOTHILL CHRISTIAN ACADEMY',
  'FOOTHILL CHRISTIAN SCHOOL',
  'FOOTHILL COUNTRY DAY SCHOOL',
  'FOOTHILL OAKS ACADEMY',
  'FOOTHILL PREPARATORY SCHOOL',
  'FOOTHILL PROGRESSIVE MONTESSORI SCHOOL',
  'FOOTHILLS ADVENTIST ELEMENTARY SCHOOL',
  'FOOTHILLS CHRISTIAN ELEMENTARY SCHOOL',
  'FOOTHILLS CHRISTIAN HIGH SCHOOL',
  'FOOTHILLS CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'FOOTHILLS CHRISTIAN SCHOOL',
  'FOOTHILLS COMMUNITY CHRISTIAN HIGH SCHOOL',
  'FOOTHILLS MONTESSORI SCHOOL',
  'FOOTHILLS SCHOOL OF ARTS & SCIENCES',
  'FOR KIDS SAKE CHILD CARE & LEARNING CENTER',
  'FOR THE LOVE OF LEARNING',
  'FORBUSH SCHOOL AT ANNE ARUNDEL THE',
  'FORBUSH SCHOOL AT HUNT VALLEY',
  'FORBUSH SCHOOL AT PRINCE GEORGES COUNTY',
  'FORCEY CHRISTIAN SCHOOL',
  'FORDHAM PREPARATORY SCHOOL',
  'FOREIGN LANGUAGE IMMERSION PREPARATORY ACADEMY',
  'FORESHADOW ACADEMY',
  'FORESIGHT LEARNING CENTER',
  'FOREST AMISH SCHOOL',
  'FOREST CITY ADVENTIST SCHOOL',
  'FOREST GROVE SCHOOL',
  'FOREST HEIGHTS LODGE SCHOOL',
  'FOREST HILLS CHRISTIAN SCHOOL',
  'FOREST HILLS LUTHERAN CHRISTIAN SCHOOL',
  'FOREST HILLS MONTESSORI SCHOOL',
  'FOREST LAKE ACADEMY.',
  'FOREST LAKE CHRISTIAN SCHOOL',
  'FOREST LAKE EDUCATION CENTER',
  'FOREST LAKES LEARNING CENTER',
  'FOREST PARK ADVENTIST CHRISTIAN SCHOOL',
  'FOREST RIDGE ACADEMY',
  'FOREST RIDGE SCHOOL',
  'FOREST RIDGE SCHOOL OF THE SACRED HEART',
  'FORESTDALE BAPTIST',
  'FOREVER YOUNG MONTESSORI SCHOOL AND DAY CARE',
  'FORGE ROAD AMISH SCHOOL',
  'FORISTELL BAPTIST ACADEMY',
  'FORK UNION MILITARY ACADEMY',
  'FORKS VALLEY SCHOOL',
  'FORREST WARD MEMORIAL SCHOOL',
  'FORSYTH SCHOOL',
  'FORT BEND CHRISTIAN ACADEMY ELEMENTARY SCHOOL',
  'FORT BEND CHRISTIAN ACADEMY HIGH SCHOOL',
  'FORT BEND MONTESSORI SCHOOL',
  'FORT DALE ACADEMY',
  'FORT LAUDERDALE PREPARATORY SCHOOL',
  'FORT SCOTT CHRISTIAN HEIGHTS',
  'FORT WORTH ACADEMY',
  'FORT WORTH CHRISTIAN SCHOOL',
  'FORT WORTH COUNTRY DAY SCHOOL',
  'FORTIS ACADEMY',
  'FORTUNA JUNIOR ACADEMY',
  'FORTUNE ACADEMY',
  'FOUNDATION CHRISTIAN ACADEMY',
  'FOUNDATION CHRISTIAN SCHOOL',
  'FOUNDATION PREPARATORY ACADEMY',
  'FOUNDATIONS',
  'FOUNDATIONS 4 ADVANCEMENT',
  'FOUNDATIONS CHRISTIAN ACADEMY',
  'FOUNDATIONS CHRISTIAN MONTESSORI ACADEMY',
  'FOUNDATIONS FOR THE FUTURE SCHOOL',
  'FOUNTAIN CITY BAPTIST HOME SCHOOL',
  'FOUNTAIN CITY CHRISTIAN SCHOOL',
  'FOUNTAIN MEADOW',
  'FOUNTAIN NOOK',
  'FOUNTAIN OF TRUTH CHRISTIAN ACADEMY',
  'FOUNTAIN VALLEY',
  'FOUNTAIN VALLEY MONTESSORI CENTER',
  'FOUNTAIN VALLEY SCHOOL OF COLORADO',
  'FOUR CORNERS',
  'FOUR MILE ADVENTIST CHRISTIAN',
  'FOUR WINDS WALDORF SCHOOL',
  'FOURSQUARE CHRISTIAN SCHOOL',
  'FOURTH BAPTIST CHRISTIAN SCHOOL',
  'FOWLERS HOLLOW SCHOOL',
  'FOX LUMBER AMISH PAROCHIAL SCHOOL',
  'FOX RUN',
  'FOX VALLEY CHRISTIAN ACADEMY',
  'FOX VALLEY LUTHERAN HIGH SCHOOL',
  'FOXCROFT ACADEMY',
  'FOXCROFT SCHOOL',
  'FPT University',
  'FR GABRIEL RICHARD HIGH SCHOOL',
  'FR MARQUETTE MIDDLE SCHOOL',
  'FR TOLTON CATHOLIC HIGH SCHOOL',
  'FRAMS CORNER',
  'FRANCES XAVIER WARDE SCHOOL',
  'FRANCIS HUGH WARDLAW ACADEMY',
  'FRANCISCAN MONTESSORI EARTH SCHOOL',
  'FRANKEL JEWISH ACADEMY OF METRO DETROIT',
  'FRANKFORD FRIENDS SCHOOL',
  'FRANKFORT COVENANT ACADEMY',
  'FRANKLIN ACADEMY',
  'FRANKLIN ACADEMY INC.',
  'FRANKLIN CHRISTIAN ACADEMY',
  'FRANKLIN CHRISTIAN DAY SCHOOL',
  'FRANKLIN CLASSICAL SCHOOL',
  'FRANKLIN MENNONITE SCHOOL',
  'FRANKLIN MONTESSORI SCHOOL',
  'FRANKLIN ROAD ACADEMY',
  'FRANKLIN ROAD CHRISTIAN SCHOOL',
  'FRANKLIN ROAD MENNONITE',
  'FRANKLIN VIRTUAL SCHOOLS/FRANKLIN VIRTUAL HIGH SCH',
  'FRASER WOODS MONTESSORI SCHOOL',
  'FRASSATI CATHOLIC ACADEMY',
  'FRASSATI CATHOLIC HIGH SCHOOL',
  'FREASE RIDGE',
  'FRED FINCH YOUTH CENTER',
  'FRED FINCH-OAKLAND HILLS ACADEMY',
  'FREDERIC AMISH SCHOOL',
  'FREDERICA ACADEMY',
  'FREDERICK ADVENTIST ACADEMY',
  'FREDERICK CHRISTIAN ACADEMY',
  'FREDERICK COUNTRY DAY MONTESSORI SCHOOL',
  'FREDERICK DOUGLASS CHRISTIAN SCHOOL',
  'FREDERICKSBURG ACADEMY',
  'FREDERICKSBURG CHRISTIAN SCHOOL',
  'FREDONIA HILL BAPTIST ACADEMY',
  'FREE CHRISTIAN',
  'FREE UNION COUNTRY SCHOOL',
  'FREE WILL CHRISTIAN ACADEMY',
  'FREEDOM ACADEMY',
  'FREEDOM CENTER FOR CREATIVE ARTS',
  'FREEDOM CHAPEL CHRISTIAN ACADEMY',
  'FREEDOM CHRISTIAN ACADEMY',
  'FREEDOM CHRISTIAN SCHOOL',
  'FREEDOM CHRISTIAN SCHOOLS',
  'FREEDOM MONTESSORI SCHOOL',
  'FREEDOM OUTREACH SCHOOL',
  'FREEDOM SCHOOL',
  'FREEDOMLAND CHRISTIAN ACADEMY',
  'FREEDOMSCHOOLERS ACADEMY',
  'FREEHOLD MONTESSORI PRESCHOOL',
  'FREEMAN ACADEMY',
  'FREEMAN ENRICHMENT CENTER',
  'FREEMONT VIEW SCHOOL',
  'FREMONT CHRISTIAN SCHOOL',
  'FRENCH AMERICAN INTERNATIONAL SCHOOL',
  'FRENCH AMERICAN SCHOOL OF NEW YORK',
  'FRENCH AMERICAN SCHOOL OF PRINCETON',
  'FRENCH AMERICAN SCHOOL OF PUGET SOUND',
  'FRENCH AMERICAN SCHOOL OF SILICON VALLEY',
  'FRENCH BROAD RIVER ACADEMY-BOYS',
  'FRENCH BROAD RIVER ACADEMY-GIRLS CAMPUS',
  'FRENCH CAMP ACADEMY',
  'FRENCH CREEK VALLEY CHRISTIAN',
  'FRENCH IMMERSION SCHOOL OF WASHINGTON',
  'FRENCH SCHOOL',
  'FRENCH-AMERICAN SCHOOL OF RHODE ISLAND',
  'FRESNO ADVENTIST ACADEMY',
  'FRESNO CHRISTIAN SCHOOLS',
  'FRESNO MONTESSORI SCHOOL',
  'FRESTA VALLEY CHRISTIAN SCHOOL',
  'FRIEDEL JEWISH ACADEMY',
  'FRIEDENS EVANGELICAL LUTHERAN SCHOOL',
  'FRIENDS ACADEMY',
  'FRIENDS CENTRAL SCHOOL',
  'FRIENDS COMMUNITY SCHOOL',
  'FRIENDS COVE MENNONITE SCHOOL',
  'FRIENDS IN FAITH CHRISTIAN SCHOOL',
  'FRIENDS MEETING SCHOOL',
  'FRIENDS PRESCHOOL & KINDERGARTEN',
  'FRIENDS SCHOOL',
  'FRIENDS SCHOOL HAVERFORD',
  'FRIENDS SCHOOL MULLICA HILL',
  'FRIENDS SCHOOL OF HARFORD',
  'FRIENDS SCHOOL OF MINNESOTA',
  'FRIENDS SCHOOL OF PORTLAND',
  'FRIENDS SCHOOL OF WILMINGTON',
  'FRIENDS SELECT SCHOOL',
  'FRIENDS SEMINARY',
  'FRIENDSHIP ADVENTIST SCHOOL',
  'FRIENDSHIP CHRISTIAN ACADEMY',
  'FRIENDSHIP CHRISTIAN SCHOOL',
  'FRIENDSHIP CHRISTIAN SCHOOLS',
  'FRIENDSHIP MENNONITE SCHOOL',
  'FRIENDSHIP MONTESSORI SCHOOL',
  'FRIENDSHIP SCHOOL',
  'FRIENDSWOOD CHILDRENSS HOUSE',
  'FRISCO MONTESSORI ACADEMY',
  'FROG HOLLOW SCHOOL',
  'FROM THE HEART CHRISTIAN SCHOOL',
  'FROM THE HEART PRESCHOOL & ENRICHMENT CENTER',
  'FRONT RANGE CHRISTIAN SCHOOL',
  'FRONTIERS ACADEMY',
  'FROST MONTESSORI SCHOOL',
  'FROSTIG SCHOOL',
  'FT WORTH ADVENTIST JR ACADEMY',
  'FT WORTH SAVE OUR CHILDREN',
  'FTMS Global Academy',
  'FUCHS MIZRACHI SCHOOL',
  'FUGEES ACADEMY',
  'FULFORD CHRISTIAN ACADEMY',
  'FULFORD UNITED METHODIST CHURCH',
  'FULL ARMOR CHRISTIAN ACADEMY',
  'FULL CIRCLE ELEMENTARY SCHOOL INC',
  'FULL CIRCLE MONTESSORI SCHOOL',
  'FULL CIRCLE NATURE SCHOOL',
  'FULL GOSPEL CHRISTIAN ACADEMY',
  'FULLINGTON ACADEMY',
  'FULTON SCIENCE ACADEMY PRIVATE SCHOOL',
  'FULTON VIEW AMISH SCHOOL',
  'FUQUA SCHOOL',
  'FURQAAN ACADEMY',
  'FURTAH PREPARATORY SCHOOL',
  'FUSION',
  'FUSION ACADEMY - DALLAS',
  'FUSION ACADEMY - HUNTINGTON BEACH',
  'FUSION ACADEMY - LOS ANGELES',
  'FUSION ACADEMY - LOS GATOS',
  'FUSION ACADEMY - MARIN',
  'FUSION ACADEMY - PASADENA',
  'FUSION ACADEMY - PLANO',
  'FUSION ACADEMY ALEXANDRIA',
  'FUSION ACADEMY AUS: AUSTIN',
  'FUSION ACADEMY BRK: BROOKLYN',
  'FUSION ACADEMY ENGLEWOOD',
  'FUSION ACADEMY FF: FAIRFIELD',
  'FUSION ACADEMY HB: HUNTINGTON BEACH',
  'FUSION ACADEMY HTW: THE WOODLANDS',
  'FUSION ACADEMY LAKE FOREST',
  'FUSION ACADEMY LC: MANHATTAN - LINCOLN CENTER',
  'FUSION ACADEMY LF: CHICAGO- LAKE FOREST',
  'FUSION ACADEMY LOS GATOS',
  'FUSION ACADEMY LP: CHICAGO- LINCOLN PARK',
  'FUSION ACADEMY MARIN',
  'FUSION ACADEMY MIRACLE MILE',
  'FUSION ACADEMY MM: MIRACLE MILE',
  'FUSION ACADEMY MV: MISSION VIEJO',
  'FUSION ACADEMY OB: CHICAGO- OAK BROOK',
  'FUSION ACADEMY PA: PASADENA',
  'FUSION ACADEMY PRN: PRINCETON',
  'FUSION ACADEMY SAN FRANCISCO',
  'FUSION ACADEMY SB: SOUTH BAY',
  'FUSION ACADEMY SF: SAN FRANCISCO',
  'FUSION ACADEMY SLB: SOLANA BEACH',
  'FUSION ACADEMY SLK: SOUTHLAKE',
  'FUSION ACADEMY SUG: SUGAR LAND',
  'FUSION ACADEMY WALNUT CREEK',
  'FUSION ACADEMY WOODBURY NY',
  'FUSION ACADEMY--WALNUT CREEK',
  'FUSION ACADEMY/WESTCHESTER',
  'FUTURE BUILDERS SCHOOL',
  'FUTURE KIDS',
  'FUTURE LEADERS ACADEMY',
  'FUTURE LEADERS ACADEMY OF KENDALL CORP',
  'FUTURE SCHOLARS CHRISTIAN ACADEMY',
  'FUTURES SCHOOL',
  'FUTUREWORLD NFP',
  'FUVIS LEARNING CENTER',
  'Fabens El',
  'Fabens H S',
  'Fabens Jjaep',
  'Fabens Middle School',
  'Fabius-pompey Elementary School',
  'Fabius-pompey Middle School High School',
  'Fabra El',
  'Fabyan Elementary School',
  'Face To Face Academy',
  'Fachhochschule Aachen',
  'Fachhochschule Aschaffenburg',
  'Fachhochschule Augsburg',
  'Fachhochschule Biberach Hochschule für Bauwesen und Wirtschaft',
  'Fachhochschule Bielefeld',
  'Fachhochschule Bingen',
  'Fachhochschule Bochum',
  'Fachhochschule Bonn-Rhein-Sieg',
  'Fachhochschule Brandenburg',
  'Fachhochschule Braunschweig/Wolfenbüttel',
  'Fachhochschule Burgenland',
  'Fachhochschule Dortmund',
  'Fachhochschule Düsseldorf',
  'Fachhochschule Eberswalde',
  'Fachhochschule Erfurt',
  'Fachhochschule Flensburg',
  'Fachhochschule Frankfurt am Main',
  'Fachhochschule Furtwangen Hochschule für Technik und Wirtschaft',
  'Fachhochschule Gelsenkirchen',
  'Fachhochschule Gießen-Friedberg',
  'Fachhochschule Hamburg',
  'Fachhochschule Hannover',
  'Fachhochschule Heidelberg',
  'Fachhochschule Heilbronn Hochschule für Technik und Wirtschaft',
  'Fachhochschule Hildesheim/Holzminden/Göttingen Hochschule für angewandte Wissenschaft und Kunst',
  'Fachhochschule Hof',
  'Fachhochschule Ingolstadt',
  'Fachhochschule JOANNEUM',
  'Fachhochschule Jena',
  'Fachhochschule Kaiserslautern',
  'Fachhochschule Karlsruhe Hochschule für Technik',
  'Fachhochschule Kempten Hochschule für Technik und Wirtschaft',
  'Fachhochschule Kiel',
  'Fachhochschule Koblenz',
  'Fachhochschule Konstanz Hochschule für Technik, Wirtschaft und Gestaltung',
  'Fachhochschule Krems',
  'Fachhochschule Kufstein (Tirol)',
  'Fachhochschule Kärnten',
  'Fachhochschule Köln',
  'Fachhochschule Landshut Hochschule für Wirtschaft - Sozialwesen - Technik',
  'Fachhochschule Lausitz',
  'Fachhochschule Lippe',
  'Fachhochschule Ludwigshafen Hochschule für Wirtschaft',
  'Fachhochschule Lübeck',
  'Fachhochschule Mainz',
  'Fachhochschule Mannheim Hochschule für Sozialwesen',
  'Fachhochschule Mannheim Hochschule für Technik und Gestaltung',
  'Fachhochschule Merseburg',
  'Fachhochschule München',
  'Fachhochschule Münster',
  'Fachhochschule Neu-Ulm',
  'Fachhochschule Neubrandenburg',
  'Fachhochschule Niederrhein',
  'Fachhochschule Nordhausen',
  'Fachhochschule Nordhessen',
  'Fachhochschule Nordostniedersachsen',
  'Fachhochschule Nürtingen Hochschule für Wirtschaft, Landwirtschaft und Landespflege',
  'Fachhochschule Offenburg Hochschule für Technik und Wirtschaft',
  'Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven',
  'Fachhochschule Osnabrück',
  'Fachhochschule Pforzheim Hochschule für Gestaltung, Technik und Wirtschaft',
  'Fachhochschule Potsdam',
  'Fachhochschule Pur',
  'Fachhochschule Ravensburg-Weingarten',
  'Fachhochschule Regensburg',
  'Fachhochschule Reutlingen Hochschule für Technik und Wirtschaft',
  'Fachhochschule Rosenheim Hochschule für Technik und Wirtschaft',
  'Fachhochschule Rottenburg Hochschule für Forstwirtschaft',
  'Fachhochschule Salzburg',
  'Fachhochschule Schmalkalden',
  'Fachhochschule Schwäbisch Gmünd Hochschule für Gestaltung',
  'Fachhochschule Schwäbisch Hall Hochschule für Gestaltung',
  'Fachhochschule St. Pölten',
  'Fachhochschule Stralsund',
  'Fachhochschule Stuttgart Hochschule der Medien',
  'Fachhochschule Stuttgart Hochschule für Technik',
  'Fachhochschule Trier Hochschule für Technik, Wirtschaft und Gestaltung',
  'Fachhochschule Ulm Hochschule für Technik',
  'Fachhochschule Vorarlberg',
  'Fachhochschule Wedel',
  'Fachhochschule Weihenstephan',
  'Fachhochschule Westküste Hochschule für Wirtschaft und Technik',
  'Fachhochschule Wiener Neustadt',
  'Fachhochschule Wiesbaden',
  'Fachhochschule Worms',
  'Fachhochschule Würzburg - Schweinfurt',
  'Fachhochschule der Wirtschaft',
  'Fachhochschule des Mittelstandes (FHM)',
  'Fachhochschule für Bank- und Finanzwirtschaft',
  'Fachhochschule für Oekonomie und Management (FOM)',
  'Fachhochschule für Technik und Wirtschaft Berlin',
  'Fachhochschule für Verwaltung und Rechtspflege Berlin',
  'Fachhochschule für Wirtschaft Berlin',
  'Fachhochschule für das öffentliche Bibliothekswesen Bonn',
  'Fachhochschule für die Wirtschaft',
  'Fachhochschule und Berufskollegs NTA Prof.Dr. Grübler gemein. GmbH',
  'Fachhochschulstudiengänge Hagenberg',
  'Fachhochschulstudiengänge Krems IMC',
  'Fachhochschulstudiengänge Steyr',
  'Fachhochschulstudiengänge Wels',
  'Fachhochschulstudiengänge der Wiener Wirtschaft',
  'Facing History High School@charles Mooney',
  'Facing History School (the)',
  'Factory Shoals Elementary School',
  'Factory Shoals Middle School',
  'Faculdade Integradas do Ceará',
  'Faculdade Italo Brasileira',
  'Faculdades Integradas Curitiba',
  'Faculdades Integradas Toledo',
  'Faculdades Integradas UPIS',
  'Faculdades Integradas do Brasil - UNIBRASIL',
  'Faculté Polytechnique de Mons',
  'Faculté Universitaire des Sciences Agronomiques de Gembloux',
  'Facultés Universitaires Catholiques de Mons',
  'Facultés Universitaires Notre-Dame de la Paix',
  'Facultés Universitaires Saint-Louis',
  'Fadden-mckeown-chambliss El',
  "Faga'itua High School",
  'Fahad Bin Sultan University',
  'Fain El',
  'Fain Elementary School',
  'Faine Elementary School',
  'Fair Avenue Elementary',
  'Fair Elementary',
  'Fair Elementary School',
  'Fair Garden Family/community Center',
  'Fair Grove Elem.',
  'Fair Grove Elementary',
  'Fair Grove High',
  'Fair Grove Middle',
  'Fair Haven Grade School',
  'Fair Haven School',
  'Fair Haven Uhsd #16',
  'Fair Lawn High School',
  'Fair Oaks Elementary',
  'Fair Oaks Elementary School',
  'Fair Oaks Ranch Community',
  'Fair Oaks Ranch El',
  'Fair Park Early Childhood Ctr',
  'Fair Park Elementary',
  'Fair Park High School',
  'Fair Park Middle School',
  'Fair Play Elem.',
  'Fair Play High',
  'Fair School Pilgrim Lane',
  'Fair Senior High',
  'Fair Street International Baccalaureate World School',
  'Fair View',
  'Fair View High (continuation)',
  'Fair-mont-egan 7-8',
  'Fair-mont-egan School',
  'Fair-oak Elementary',
  'Fairacres Elementary',
  'Fairbank Elementary School',
  'Fairbanks B.e.s.t.',
  'Fairbanks Elementary',
  'Fairbanks Elementary School',
  'Fairbanks High School',
  'Fairbanks Middle School',
  'Fairbanks Road Elementary School',
  'Fairborn Digital Academy',
  'Fairborn High School',
  'Fairborn Intermediate School',
  'Fairborn Primary School',
  'Fairbrook Elementary School',
  'Fairburn Avenue Elementary',
  'Fairbury Jr-sr High School',
  'Fairchild Elementary',
  'Faircrest Memorial Middle School',
  'Fairdale Elementary',
  'Fairdale Elementary School',
  'Fairdale High',
  'Fairfax County Adult High',
  'Fairfax Elem.',
  'Fairfax Elementary',
  'Fairfax Elementary School',
  'Fairfax High',
  'Fairfax High School Academy',
  'Fairfax Jr. High',
  'Fairfax Juvenile Detention Home',
  'Fairfax Learning Center',
  'Fairfax Senior High',
  'Fairfax Villa Elementary',
  'Fairfield 7-8',
  'Fairfield Alternative School',
  'Fairfield Area El Sch',
  'Fairfield Area Hs',
  'Fairfield Area Ms',
  'Fairfield Area Vocational School',
  'Fairfield Career And Technology Center',
  'Fairfield Career Center',
  'Fairfield Center School',
  'Fairfield Central Elementary School',
  'Fairfield Central High',
  'Fairfield Comm High School',
  'Fairfield Compass Elementary',
  'Fairfield Court Elementary',
  'Fairfield East Elementary School',
  'Fairfield El',
  'Fairfield Elem Academy',
  'Fairfield Elementary',
  'Fairfield Elementary School',
  'Fairfield Freshman High School',
  'Fairfield H S',
  'Fairfield High',
  'Fairfield High Preparatory School',
  'Fairfield High School',
  'Fairfield Int',
  'Fairfield J H',
  'Fairfield Jr High',
  'Fairfield Jr-sr High School',
  'Fairfield Local Elementary School',
  'Fairfield Local High School',
  'Fairfield Local Middle School',
  'Fairfield Ludlowe High School',
  'Fairfield Magnet For Math And Science',
  'Fairfield Magnet School',
  'Fairfield Middle',
  'Fairfield Middle School',
  'Fairfield North Elementary School',
  'Fairfield Primary School',
  'Fairfield Schools',
  'Fairfield South Elementary School',
  'Fairfield Township School',
  'Fairfield Union High School',
  'Fairfield Union Rushville Middle School',
  'Fairfield Warde High School',
  'Fairfield West Elementary School',
  'Fairfield Woods Middle School',
  'Fairfield-suisun Elementary Community Day',
  'Fairforest Elementary',
  'Fairforest Middle',
  'Fairglen Elementary School',
  'Fairgrieve School',
  'Fairgrounds Elementary School',
  'Fairgrounds Middle School',
  'Fairgrove Academy',
  'Fairgrove Elementary',
  'Fairgrove Middle',
  'Fairhaven Colony',
  'Fairhaven Elementary',
  'Fairhaven High',
  'Fairhaven Middle School',
  'Fairhaven School',
  'Fairhill Elementary',
  'Fairhope Elementary School',
  'Fairhope High School',
  'Fairhope Intermediate School',
  'Fairhope Middle School',
  'Fairington Elementary School',
  'Fairland East Elementary School',
  'Fairland Elementary',
  'Fairland Es',
  'Fairland High School',
  'Fairland Hs',
  'Fairland Middle School',
  'Fairland Ms',
  'Fairland West Elementary School',
  'Fairlands Elementary',
  'Fairlane Elementary',
  'Fairlawn Elementary',
  'Fairlawn Elementary School',
  'Fairlawn High School',
  'Fairleigh Dickinson University',
  'Fairless Elementary School',
  'Fairless High School',
  'Fairless Middle School',
  'Fairley High School',
  'Fairley School',
  'Fairmead Elementary',
  'Fairmeadow Elementary',
  'Fairmeadows El',
  'Fairmeadows Elementary School',
  'Fairmont Charter Elementary',
  'Fairmont Elementary',
  'Fairmont Elementary School',
  'Fairmont Heights High',
  'Fairmont High',
  'Fairmont J H',
  'Fairmont Jr./sr. High School',
  'Fairmont Junior High School',
  'Fairmont Middle',
  'Fairmont Neighborhood School',
  'Fairmont School',
  'Fairmont Senior High School',
  'Fairmont State College',
  'Fairmont Youth Academy',
  'Fairmoor Elementary School',
  'Fairmount',
  'Fairmount Early Childhood Center',
  'Fairmount Elem School',
  'Fairmount Elem.',
  'Fairmount Elementary',
  'Fairmount Elementary School',
  'Fairmount High School',
  'Fairmount Park Elementary School',
  'Fairmount Primary Center',
  'Fairmount School',
  'Fairplain Elementary School',
  'Fairplains Elementary School',
  'Fairplay Middle School',
  'Fairport Harding High School',
  'Fairport Senior High School',
  'Fairvalley High (continuation)',
  'Fairview 7-8',
  'Fairview Accelerated',
  'Fairview Accelerated Daep',
  'Fairview Accelerated Educational Coop',
  'Fairview Aep',
  'Fairview Alternative High School',
  'Fairview Attendance Center',
  'Fairview Avenue El Sch',
  'Fairview Daep',
  'Fairview Day Treatment Center',
  'Fairview Dual Program',
  'Fairview Early Childhood Center',
  'Fairview El',
  'Fairview El Sch',
  'Fairview Elem',
  'Fairview Elem School',
  'Fairview Elem.',
  'Fairview Elementary',
  'Fairview Elementary School',
  'Fairview Es',
  'Fairview Heights Elementary',
  'Fairview High School',
  'Fairview Hs',
  'Fairview Intermediate',
  'Fairview J H',
  'Fairview Marguerite',
  'Fairview Middle',
  'Fairview Middle School',
  'Fairview Ms',
  'Fairview Park Elementary',
  'Fairview Prek-6 School',
  'Fairview Primary',
  'Fairview Program',
  'Fairview School',
  'Fairview School (daep)',
  'Fairview School Readiness Plus',
  'Fairview South',
  'Fairview South Elementary School',
  'Fairview Special Programs',
  'Fairview Vt',
  'Fairview-alpha Elementary & Junior High School',
  'Fairview-clifton German Language School',
  'Fairview/miss Jewell El',
  'Fairway Elem.',
  'Fairway Elementary School',
  'Fairwood Alternative Elementary School',
  'Fairwood Elementary',
  'Fairwood Elementary School',
  'Fairyland Elementary School',
  'Faisalabad Institute of Textile and Fashion Design',
  'Faith Elementary',
  'Faith Elementary - 02',
  'Faith Family Master Academy',
  'Faith High School - 01',
  'Faith Jr. High - 06',
  'Faith North Early Childhood Learning Center',
  'Faith Pk - 10',
  'Faith Ringgold School Of Arts And Science',
  'Falcon Bluffs Middle School',
  'Falcon Cove Middle School',
  'Falcon Creek Middle School',
  'Falcon Elementary School Of Technology',
  'Falcon Heights Academy',
  'Falcon Heights Elementary',
  'Falcon High School',
  'Falcon Hill Elementary School',
  'Falcon Middle School',
  'Falcon Pass El',
  'Falcon Ridge',
  'Falcon Ridge Elementary',
  'Falcon Ridge Middle',
  'Falcon Ridge Public Charter',
  'Falcon Ridge School',
  'Falconer Elem School',
  'Falconer Middle/high School',
  'Faleasao Elementary School',
  'Falfurrias El',
  'Falfurrias H S',
  'Falfurrias J H',
  'Falk Elementary',
  'Falkenburg Road Jail',
  'Falkland Elementary',
  'Falkner Elementary School',
  'Falkner High School',
  'Falkville Elementary School',
  'Falkville High School',
  'Fall Branch Elementary',
  'Fall Brook',
  'Fall City Elementary',
  'Fall Creek El',
  'Fall Creek Elementary',
  'Fall Creek Elementary School',
  'Fall Creek High',
  'Fall Creek Intermediate School',
  'Fall Creek Junior High',
  'Fall Creek Middle',
  'Fall Creek Valley Middle School',
  'Fall Mountain Regional -pre School Program',
  'Fall Mountain Regional High School',
  'Fall River Community Day',
  'Fall River Elementary',
  'Fall River Elementary Community Day',
  'Fall River Elementary School',
  'Fall River Gateway To College @ Bcc',
  'Fall River High',
  'Fall River Junior-senior High',
  'Fall-hamilton Elementary',
  'Fallbrook Academy',
  'Fallbrook High',
  'Fallbrook Homeschool Academy',
  'Fallbrook Street Elementary',
  'Fallen Timbers Middle School',
  'Faller Elementary',
  'Falling Branch Elementary',
  'Falling Creek Elementary',
  'Falling Creek Middle',
  'Falling Spring El Sch',
  'Fallon Memorial School',
  'Fallon Park Elementary',
  'Falls',
  'Falls Academy - 08',
  'Falls Alternative Learning Site (fals)',
  'Falls Career H S',
  "Falls Church Governor's Health Sciences Academy",
  'Falls Church High',
  'Falls Church High School Academy',
  'Falls City El',
  'Falls City Elementary School',
  'Falls City H S',
  'Falls City High School',
  'Falls City Middle School',
  'Falls City North Elementary',
  'Falls City Senior High',
  'Falls City South Elementary',
  'Falls Elementary',
  'Falls Lake Academy',
  'Falls Secondary',
  'Falls Valley Elementary School',
  'Falls-lenox Primary Elementary School',
  'Fallsburg Elementary School',
  'Fallsburg Junior Senior High School',
  'Fallsington El Sch',
  'Fallsmead Elementary',
  'Fallstaff Elementary',
  'Fallston Elementary',
  'Fallston High',
  'Fallston Middle School',
  'Fallsvale',
  'Falmouth Elementary',
  'Falmouth Elementary School',
  'Falmouth High',
  'Falmouth High School',
  'Falmouth Middle School',
  'Falmouth University',
  'False Pass School',
  'Family Care Center',
  'Family Connection - Child Development Center',
  'Family Court Elementary School',
  'Family Court High School',
  'Family Ctr.',
  'Family Education Center For Parenting Teens',
  'Family Enrichment Center',
  'Family First Charter',
  'Family Freedom Academy',
  'Family Learning Centers',
  'Family Life Academy Charter School I',
  'Family Life Academy Charter School Ii',
  'Family Life Academy Charter School Iii',
  'Family Link',
  'Family Literacy Ctr.',
  'Family Partnership Charter School',
  'Family Partnership Home Study Charter',
  'Family School',
  'Family School (the)',
  'Family School 32',
  'Family Tree Learning Center',
  'Fammatre Elementary',
  'Famu Drs Virtual Franchise',
  'Fancher Creek Elementary',
  'Fancher School',
  'Fancy Farm Elementary School',
  'Fancy Gap Elementary',
  'Fannett-metal El Sch',
  'Fannett-metal Ms',
  'Fannett-metal Shs',
  'Fannie C. Williams Charter School',
  'Fannie E Proctor',
  'Fannie Lou Hamer Freedom High School',
  'Fannie Lou Hamer Middle School',
  'Fannie W. Fitzgerald Elementary',
  'Fannin County High School',
  'Fannin County Middle School',
  'Fannin El',
  'Fannin Middle',
  'Fannindel El',
  'Fannindel H S',
  'Fanning Middle Community Ed.',
  'Fanny Meyer Hillers',
  'Fanshawe Public School',
  'Fapsgear Up Academy',
  'Far East County Programs',
  'Far East State Transport University',
  'Far Easten State University of Humanities',
  'Far Eastern State Technical University',
  'Far Eastern State University',
  'Far Eastern University',
  'Far North Dallas Area',
  'Faraday Elem School',
  'Farallone View Elementary',
  'Faras Elementary School',
  'Farb Middle',
  'Fargo Davies High School',
  'Fargo Es',
  'Fargo Hs',
  'Fargo Spec Ed Unit',
  'Farias Early Childhood Center',
  'Farias El',
  'Faribault Area Learning Center',
  'Faribault Day School Alc',
  'Faribault Middle',
  'Faribault Senior High',
  'Farine El',
  'Faris Elementary',
  'Farjardo - La Seda Preschool',
  'Farley Elementary',
  'Farley Elementary School',
  'Farley Hill Elementary School',
  'Farley Middle',
  'Farm Hill School',
  'Farmdale El Sch',
  'Farmdale Elementary',
  'Farmer Elementary',
  'Farmers Branch El',
  'Farmersville El Sch',
  'Farmersville Elem School',
  'Farmersville Elementary School',
  'Farmersville H S',
  'Farmersville High',
  'Farmersville Int',
  'Farmersville J H',
  'Farmersville Junior High',
  'Farmin Stidwell Elementary School',
  'Farmingdale Elem School',
  'Farmingdale Elementary',
  'Farmingdale Senior High School',
  'Farmington 56 Campus',
  'Farmington Career Academies',
  'Farmington Central Elem Sch',
  'Farmington Central High Sch',
  'Farmington Central High School',
  'Farmington Central Jr High Sch',
  'Farmington Collaborative Pk',
  'Farmington Community School',
  'Farmington Correctional Center',
  'Farmington Elementary',
  'Farmington Elementary School',
  'Farmington Freshman Academy',
  'Farmington High',
  'Farmington High School',
  'Farmington Jr High',
  'Farmington Middle',
  'Farmington Preschool',
  'Farmington River Elementary',
  'Farmington School',
  'Farmington Senior High School',
  'Farmington Sr. High',
  'Farmington Steam Academy',
  'Farmington Treatment Center',
  'Farmington View Elementary School',
  'Farmington Woods Elementary',
  'Farmingville Elementary School',
  'Farmland Elementary',
  'Farmville Central High',
  'Farmville Middle',
  'Farmwell Station Middle',
  'Farnell Middle School',
  'Farney El',
  'Farnham Charter',
  'Farnsley Middle',
  'Farnsworth Aerospace Lower',
  'Farnsworth Aerospace Upper',
  'Farnsworth Elem School',
  'Farnsworth Middle',
  'Farnsworth Middle School',
  'Farr Avenue Elementary',
  'Farr West School',
  'Farragut Career Academy Hs',
  'Farragut Elem School',
  'Farragut Elem.',
  'Farragut Elementary',
  'Farragut High School',
  'Farragut Intermediate',
  'Farragut Middle School',
  'Farragut Primary',
  'Farrand Elementary School',
  'Farrell Area Es/lms',
  'Farrell Area Hs/ums',
  'Farrell B. Howell Ece-8 School',
  'Farrell El',
  'Farrell Louis H Sch',
  'Farrington Elem School',
  'Farrington Grove Elementary School',
  'Farrington School',
  'Farris Early Childhood Ctr',
  'Farristown Middle School',
  'Farson-eden Elementary',
  'Farson-eden High School',
  'Farson-eden Middle School',
  'Farwell El',
  'Farwell Elementary School',
  'Farwell H S',
  'Farwell High School',
  'Farwell J H',
  'Farwell Middle School',
  'Farwell Timberland Alternative High School',
  'Faryab Higher Education Institute',
  'Fasa Faculty of Medical Sciences',
  'Fassett Elementary School',
  'Fassett Junior High School',
  'Fast Forward High',
  'Fast Track',
  'Father Keith B. Kenny',
  'Fatih Sultan Mehmet University',
  'Fatima Elem.',
  'Fatima High',
  'Fatima mata national college kollam kerala',
  'Fau/slcsd Palm Pointe Educational Research School @ Traditio',
  'Faubion Elementary School',
  'Faubion Middle',
  'Faucettvestavia Elementary School',
  'Faulconer-chapman School',
  'Faulk Elementary School',
  'Faulk Middle',
  'Faulkton Elementary - 02',
  'Faulkton High School - 01',
  'Faulkton Jr. High - 08',
  'Fauquier High',
  'Faustino Santiago Montalvo',
  'Fawcett',
  'Fawn Area El Sch',
  'Fawn Hollow Elementary School',
  'Fawn Mountain Elementary',
  'Fawn Primary Center',
  'Faxon Elementary',
  'Faxon Language Immersion Academy',
  'Fay Elementary',
  'Fay Galloway Elementary School',
  'Fay Herron Elementary School',
  'Faye Webb El',
  'Fayette Central Elementary',
  'Fayette Central School',
  'Fayette County Career & Technical Instit',
  'Fayette County High School',
  'Fayette County Learning Center',
  'Fayette County Preschool',
  'Fayette Elementary School',
  'Fayette High',
  'Fayette Institute Of Technology',
  'Fayette Jr/sr High School',
  'Fayette Middle School',
  'Fayette Pre-school',
  'Fayette Regional Juvenile Det Ctr',
  'Fayette Twp El Sch',
  'Fayette Voc Center',
  'Fayette Ware Comprehensive High School',
  'Fayetteville El Sch',
  'Fayetteville Elementary',
  'Fayetteville Elementary School',
  'Fayetteville High',
  'Fayetteville High School',
  'Fayetteville High School East',
  'Fayetteville Middle School',
  'Fayetteville Schools',
  'Fayetteville State University',
  'Fayetteville Street Elementary',
  'Fayetteville Technical Community College',
  'Fayetteville Virtual Academy- Dist Conversion Charter Sch',
  'Fayetteville-manlius Senior High School',
  'Fayetteville-perry Elementary School',
  'Fayetteville-perry High School',
  'Fayetteville-perry Middle School',
  'Faylane Elementary',
  'Fayoum University',
  'Fayston Elementary School',
  'Fbc-preschool',
  'Fc Reed Middle School',
  'Fe Burleson Elementary School',
  'Feagin Mill Middle School',
  'Fearn Elementary School',
  'Fearnside Family Services Center',
  'Feaster (mae L.) Charter',
  'Feather Falls Elementary',
  'Feather River Academy',
  'Feather River Community College District',
  'Featherbed Lane Elementary',
  'Featherstone Elementary',
  'Feati University',
  'Fec Pre K',
  'Fecteau-leary Junior/senior High School',
  'Fedde (pharis F.) Middle',
  'Federal College Of Education (Technical) Akoka',
  'Federal Furnace School',
  'Federal Heights Elementary School',
  'Federal Hill Preparatory School',
  'Federal Hocking High School',
  'Federal Hocking Middle School',
  'Federal Street School',
  'Federal Terrace Elementary',
  'Federal University of Petroleum Resources',
  'Federal University of Technology Akure',
  'Federal University of Technology Minna',
  'Federal University of Technology Owerri',
  'Federal University of Technology Yola',
  'Federal Urdu University of ArtsScience and Technology',
  'Federal Way High School',
  'Federal Way Public Academy',
  'Federal Way Public School Eceap',
  'Federal Way Public Schools Headstart',
  'Federal Way Running Start Home School',
  'Federalsburg Elementary School',
  'Federico Asenjo (pre-tecnica)',
  'Federico Degetau (su Palo Hincado)',
  'Federico Degetau Ii',
  'Federico Degetau Y Gonzalez',
  'Feds Creek Elementary School',
  'Feelhaver Elementary School',
  'Fees College Preparatory Middle School',
  'Fehl-price El',
  'Feinstein (dianne) Elementary',
  'Feitshans Elem Sch',
  'Feldwood Elementary School',
  'Felicita Elementary',
  'Felicitas And Gonzalo Mendez High',
  'Felicity-franklin Local Elementary School',
  'Felicity-franklin Local High School',
  'Felicity-franklin Local Middle School',
  'Felida Elementary School',
  'Felipa Sanchez Cruzado',
  'Felipe Colon Diaz',
  'Felipe Gutierrez',
  'Felipe Quinones',
  'Felipe Rivera Centeno',
  'Felisa Rincon De Gautier (su Limon)',
  'Felisa Rincon De Gautier Institute For Law And Public Policy',
  'Felix A Williams Elementary School',
  'Felix Cordova Davila',
  'Felix Festa Achievement Middle School',
  'Felix Festa Character Middle School',
  'Felix Festa Determination Middle School',
  'Felix G Botello El',
  'Felix Garay Ortiz',
  'Felix J. Appleby Elementary',
  'Felix Lucas Benet',
  'Felix Rosario Rios',
  'Felix Sanchez Cruz',
  'Felix Seijo',
  'Felix Varela Senior High School',
  'Fell Cs',
  'Fell D Newlin Sch',
  'Fellows Elementary School',
  'Fellowship Elementary School',
  'Fellowsville Elementary',
  'Fellsmere Elementary School',
  'Felmers O Chaney Correctional Center',
  'Fels Samuel Hs',
  'Felt Es',
  'Felt Hs',
  'Felton Elementary',
  'Felton Laboratory Charter School',
  'Feltonville Intermediate School',
  'Feltonville Sch Of Arts & Sciences',
  'Feng Chia University',
  'Fenger Academy High School',
  'Fenley Transitional H S',
  'Fenley Transitional Middle',
  'Fennell Elementary',
  'Fennimore Elementary',
  'Fennimore High',
  'Fennimore Middle',
  'Fennville Elementary School',
  'Fennville Middle School',
  'Fennville Public High School',
  'Fenton Avenue Charter',
  'Fenton Charter Leadership Academy',
  'Fenton Elementary School',
  'Fenton High School',
  'Fenton Primary Center',
  'Fenton Senior High School',
  'Fenton Stem Acad: Elem Center For Sci Tech Engr And Math',
  'Fenway High School',
  'Fenwick El',
  'Ferber Elementary',
  'Ferdinand Elementary School',
  'Ferdinand T. Day Elementary',
  'Ferdowsi University of Mashhad',
  'Ferghana Politechnical Institute',
  'Fergus Falls Area Learning Center',
  'Fergus Falls Area Sp. Ed. Coop.',
  'Fergus Falls Early Childhood Sp Ed',
  'Fergus Falls Ec/elementary Sp Ed',
  'Fergus Falls Elementary Sp Ed 5-8',
  'Fergus High School',
  'Ferguson Elementary School',
  'Ferguson Hall Freshman School',
  'Ferguson Middle',
  'Ferguson Sch',
  'Ferguson Twp El Sch',
  'Ferguson-easley Elementary',
  'Fermanagh-mifflintown El Sch',
  'Fermin Delgado Diaz (su Bo Pena Pobre)',
  'Fern Bacon Middle',
  'Fern Bluff El',
  'Fern Creek Elementary',
  'Fern Creek High',
  'Fern Drive Elementary',
  'Fern Elementary',
  'Fern Hill',
  'Fern Hill El Sch',
  'Fern Hill Elementary School',
  'Fern Persons Elementary School',
  'Fern Ridge Middle School',
  'Fern-Fachhochschule Hamburg',
  'Fernan Stem Academy',
  'Fernandez El',
  'Fernandina Beach High School',
  'Fernandina Beach Middle School',
  'Fernando Callejo',
  'Fernando Luis Malave Olivera',
  'Fernando R. Ledesma Continuation High',
  'Fernando Rivera Intermediate',
  'Fernando Rosario Vazquez',
  'Fernando Suria Chavez',
  'Fernangeles Elementary',
  'Fernbank Elementary School',
  'Fernbrook Elementary',
  'Fernbrook School',
  'Ferndale Adult Education',
  'Ferndale Area Jshs',
  'Ferndale Early Childhood Center',
  'Ferndale Early Education Center',
  'Ferndale El Sch',
  'Ferndale Elementary',
  'Ferndale Elementary School',
  'Ferndale High',
  'Ferndale High School',
  'Ferndale Lower Elementary Campus',
  'Ferndale Middle',
  'Ferndale Middle School',
  'Ferndale Re-engagement',
  'Ferndale Special Services',
  'Ferndale Upper Elementary Campus',
  'Ferndell Henry Center For Learning',
  'Fernleaf Community Charter School',
  'Fernley Elementary School',
  'Fernley High School',
  'Fernley Intermediate School',
  'Fernuniversität Gesamthochschule Hagen',
  'Fernway Elementary School',
  'Fernway Park Elem School',
  'Fernwood Avenue Middle School',
  'Fernwood Elem School',
  'Fernwood Elementary',
  'Fernwood Montessori',
  'Ferrell Middle Magnet School',
  'Ferriday High School',
  'Ferriday Junior High School',
  'Ferriday Lower Elementary School',
  'Ferriday Upper Elementary School',
  'Ferris H S',
  'Ferris High School',
  'Ferris Int',
  'Ferris J H',
  'Ferris Spanger Elementary',
  'Ferris State University',
  'Ferris University',
  'Ferrisburgh Central School',
  'Ferron School',
  'Ferrucci Jr High',
  'Ferrum Elementary',
  'Ferry Elementary School',
  'Ferry Farm Elementary',
  'Ferry Pass Elementary School',
  'Ferry Pass Middle School',
  'Ferry School',
  'Ferryway',
  'Ferson Creek School',
  'Fertile-beltrami Elementary',
  'Fertile-beltrami Secondary',
  'Fesler (isaac) Junior High',
  'Fessenden Elementary School',
  'Fessenden-bowdon Elementary School',
  'Fessenden-bowdon High School',
  'Festival Foothills Elementary School',
  'Festus Elem.',
  'Festus Intermediate',
  'Festus Middle',
  'Festus Sr. High',
  'Fews Secondary Acceleration Academy',
  'Fickett Elementary School',
  'Fidalgo Elementary',
  'Fiddlehead School Of Arts & Science',
  'Fiddlers Canyon School',
  'Fiddyment Farm',
  'Fidel And Andrea R Villarreal El',
  'Fidel Lopez Colon',
  'Fidelina Melendez Monsanto',
  'Field (eugene) Elementary',
  'Field Club Elem School',
  'Field Community',
  'Field Early Child./kdgn. Ctr.',
  'Field El',
  'Field Elem School',
  'Field Elem.',
  'Field Elementary',
  'Field Elementary School',
  'Field High School',
  'Field Kindergarten Center',
  'Field Kindley High',
  'Field Middle',
  'Field Middle School',
  'Field Moses',
  'Field Park Elem School',
  'Field School',
  'Field Stevenson Elem School',
  'Field Street School',
  'Fieldale-collinsville Middle',
  'Fieldbrook Elementary',
  'Fieldcrest Elem School',
  'Fieldcrest High School',
  'Fieldcrest Intermediate School',
  'Fieldcrest Middle Sch/elem - East',
  'Fieldcrest Middle School',
  'Fieldcrest Primary School',
  'Fielding Institute',
  'Fielding School',
  'Fields El',
  'Fields Elementary School',
  'Fields Memorial School',
  'Fields Road Elementary',
  'Fields Store El',
  'Fields-sweet',
  'Fieldston Preparatory School',
  'Fieldstone Elementary School',
  'Fieldstone Middle School',
  'Fienberg/fisher K-8 Center',
  'Fiest El',
  'Fiesta Gardens International Elementary',
  'Fife High School',
  'Fife Lake Elementary School',
  'Fifteenth Street Elementary',
  'Fifth Avenue Elementary',
  'Fifth Avenue Elementary School',
  'Fifth Avenue School',
  'Fifth Consolidated School',
  'Fifth District Elementary',
  'Fifth Street Elementary School',
  'Fifth Street School',
  'Fifth Ward Elementary School',
  'Fifth Ward Junior High School',
  'Fifty-fourth Street Elementary',
  'Fifty-ninth Street Elementary',
  'Fifty-second Street Elementary',
  'Fifty-third Street Elementary',
  'Figarden Elementary',
  'Figueroa Street Elementary',
  'Fiji National University',
  'Fiji School of Medicine',
  'Fike High',
  'Filer Elementary School',
  'Filer High School',
  'Filer Intermediate School',
  'Filer Middle School',
  'Fillmore Central Alp',
  'Fillmore Central Elementary',
  'Fillmore Central High School',
  'Fillmore Central Middle School',
  'Fillmore Central School',
  'Fillmore Central Senior High',
  'Fillmore Elementary',
  'Fillmore Elementary School',
  'Fillmore Es',
  'Fillmore Middle',
  'Fillmore School',
  'Fillmore Senior High',
  'Finance Academy',
  'Finch El',
  'Finch Elementary',
  'Findlay Digital Academy',
  'Findlay Elementary',
  'Findlay High School',
  'Findlay Learning Center',
  'Findley Community Learning Center',
  'Findley Elementary',
  'Findley Elementary School',
  'Findley Oaks Elementary School',
  'Fine Arts Academy',
  'Fine Arts Academy "Jan Matejko" in Cracow',
  'Fine Arts Academy in Gdansk',
  'Fine Arts Academy in Katowice',
  'Fine Arts Academy in Poznan',
  'Fine Arts Academy in Warsaw',
  'Fine Arts Academy in Wroclaw',
  'Fine Arts Academy Wladyslaw Strzeminski in Lodz',
  'Fine Arts Elementary',
  'Finegayan Elementary School',
  'Finger Lake Elementary',
  'Finger Lakes Community College',
  'Finger Lakes Residential Center',
  'Fink El Sch',
  'Finkl Elem School',
  'Finland Elementary School',
  'Finland Middle School',
  'Finlayson Elementary',
  'Finletter Thomas K Sch',
  'Finley El',
  'Finley Elementary',
  'Finley Farms Elementary',
  'Finley Middle School',
  'Finley Road Elementary',
  'Finley-oates El',
  'Finley-sharon Elementary School',
  'Finley-sharon High School',
  'Finn Academy-an Elmira Charter School',
  'Finn Hill Middle School',
  'Finney (myrtle S.) Elementary',
  'Finneytown Secondary Campus',
  'Finnmark University College',
  'Fiorello H Laguardia High School',
  'Fipps Primary',
  'Fir Grove Childrens Center',
  'Fir Grove Elementary School',
  'Fir Grove Primary School',
  'Firat (Euphrates) University',
  'Fircrest Elementary School',
  'Fircrest Residential Habilitation',
  'Fire Lake Elementary',
  'Fire Prairie Middle',
  'Fire Ridge Elementary School',
  'Firebaugh Community Day',
  'Firebaugh High',
  'Firebaugh Middle',
  'Firelands Elementary School',
  'Firelands High School',
  'Fireside Elementary School',
  'Firestone High School',
  'Firestone Park Elementary School',
  'Fireweed Academy',
  'Firgrove Elementary',
  'First Avenue Elementary School',
  'First Avenue Middle',
  'First Avenue School',
  'First City School',
  'First Coast High School',
  'First Coast Technical College',
  'First Colonial High',
  'First Colony Middle',
  'First Creek Middle School',
  'First District El Sch',
  'First Flight Elementary School',
  'First Flight High School',
  'First Flight Middle School',
  'First Global University to teaching Jainism',
  'First Mesa Elementary School',
  'First Nations University of Canada',
  'First Philadelphia Preparatory Charter S',
  'First State Military Academy',
  'First State Montessori Academy',
  'First State School',
  'First Step Adams',
  'First Step Alden',
  'First Step Preschool And Daycare',
  'First Street',
  'First Street El Sch',
  'First Street Elementary',
  'First Ward Creative Arts Acad',
  'Firth High School',
  'Firth Middle School',
  'Firwood',
  'Firwood Elementary School',
  'Fischer Middle School',
  'Fisd Daep',
  'Fisd Jjaep',
  'Fishback Creek Public Academy',
  'Fishburn Avenue Elementary',
  'Fishburn Park Elementary',
  'Fishcreek Elementary School',
  'Fisher',
  'Fisher El',
  'Fisher Elementary',
  'Fisher Elementary School',
  'Fisher Es',
  'Fisher Grade School',
  'Fisher Hill',
  'Fisher Jr/sr High School',
  'Fisher Magnet Lower Academy',
  'Fisher Magnet Upper Academy',
  'Fisher Middle/high School',
  'Fisher School',
  'Fisher Secondary',
  "Fisher's Peak Elementary School",
  'Fisher-mitchell School',
  'Fishers Elementary School',
  'Fishers High School',
  'Fishers Island School',
  'Fishers Junior High School',
  'Fishers Landing Elementary School',
  'Fishhawk Creek Elementary School',
  'Fishing Cove El. School',
  'Fishing Creek El Sch',
  'Fishkill Elementary School',
  'Fishkill Plains Elementary School',
  'Fishtail School',
  'Fishweir Elementary School',
  'Fisk Elem.',
  'Fisk University',
  'Fiske',
  'Fiske Elem School',
  'Fit Academy',
  'Fit Academy High School',
  'Fitch High School',
  'Fitchburg High',
  'Fitchburg State College',
  'Fitiuta Elementary School',
  'Fitler Academics Plus',
  'Fitzgerald Early Childhood',
  'Fitzgerald El',
  'Fitzgerald High School',
  'Fitzgerald Middle School',
  'Fitzgerald Senior High School',
  'Fitzgerald Virtual Academy',
  'Fitzhugh Park Elementary School',
  'Fitzmorris Elementary School',
  'Fitzpatrick Aloysius L Sch',
  'Fitzpatrick Elementary School',
  'Fitzsimmons Middle School',
  'Fitzwater El Sch',
  'Fivay High Adult Education',
  'Fivay High School',
  'Five County Regional Alternative',
  'Five Forks Middle School',
  'Five Hawks El.',
  "Five Keys Adult School (sf Sheriff's)",
  "Five Keys Charter (sf Sheriff's)",
  "Five Keys Independence Hs (sf Sheriff's)",
  'Five Oaks Middle School',
  'Five Palms El',
  'Five Points',
  'Five Points El Sch',
  'Five Points Elementary School',
  'Five-county Detention Center',
  'Flagami Elementary School',
  'Flagg Street',
  'Flagler High',
  'Flagler Public School',
  'Flagler Technical Institute',
  'Flagler Virtual Instruction Program',
  'Flagler Virtual Instruction(course Offerings)',
  'Flagler-palm Coast High School',
  'Flagship Charter Academy',
  'Flagstaff Arts And Leadership Academy',
  'Flagstaff Charter Academy',
  'Flagstaff High School',
  'Flagstaff Junior Academy',
  'Flagstaff Middle School - Closed',
  'Flagstone Elementary School',
  'Flagview Intermediate School',
  'Flaherty Elementary School',
  'Flaherty Primary School',
  'Flambeau Correctional Center',
  'Flambeau Elementary',
  'Flambeau High',
  'Flambeau Middle',
  'Flaming Gorge School',
  'Flamingo Elementary School',
  'Flanagan Elem School',
  'Flanagan-cornell High School',
  'Flanders Elementary School',
  'Flanders School',
  'Flandreau Elementary - 04',
  'Flandreau High School - 01',
  'Flandreau Indian Boarding School',
  'Flandreau Middle School - 03',
  'Flasher Elementary School',
  'Flasher High School',
  'Flat Gap Elementary School',
  'Flat Lick Elementary School',
  'Flat Ridge Elementary School',
  'Flat River Academy Elementary',
  'Flat River Academy Middlehigh School',
  'Flat Rock Academic Virtual Academy 612',
  'Flat Rock Academic Virtual Academy K5',
  'Flat Rock Community Building Prek',
  'Flat Rock Community High School',
  'Flat Rock Elementary',
  'Flat Rock Elementary School',
  'Flat Rock Middle',
  'Flat Rock Middle School',
  'Flat Rock School',
  'Flat Shoals Elementary School',
  'Flathead High School',
  'Flathead Valley Community College',
  'Flatirons Elementary School',
  'Flatonia El',
  'Flatonia Secondary',
  'Flatwoods Elementary',
  'Flatwoods Elementary School',
  'Fleeta School',
  'Fleetridge Elem.',
  'Fleetwood Elementary School',
  'Fleetwood Ms',
  'Fleetwood Shs',
  'Fleming County High School',
  'Fleming Early Learning Neighborhood Center',
  'Fleming Elementary School',
  'Fleming High School',
  'Fleming Island Elementary School',
  'Fleming Island High School',
  'Fleming Middle',
  'Fleming Middle School',
  'Fleming Neon Middle School',
  'Flemingsburg Elementary School',
  'Flemington Elementary School',
  'Fletcher Community School',
  'Fletcher Drive Elementary',
  'Fletcher El',
  'Fletcher Elementary',
  'Fletcher Elementary School',
  'Fletcher Es',
  'Fletcher Hills Elementary',
  'Fletcher Hs',
  'Fletcher Jhs',
  'Fletcher W. Judson School',
  'Fletcher Walker Elementary',
  'Fletcher/maynard Academy',
  'Flex',
  'Flex - 45',
  'Flex Academy',
  'Flex High School',
  'Flex Program',
  'Flexible Student Support',
  'Flextech High School',
  'Flextech High School Novi',
  'Flickinger Elementary School',
  'Flight Program',
  'Flightline Upper Academy',
  'Flinders University of South Australia',
  'Flinn Elementary School',
  'Flint Hill Elementary',
  'Flint Hills Technical College',
  'Flint Lake Elementary School',
  'Flint Springs Elementary',
  'Flinthills High School',
  'Flinthills Intermediate School',
  'Flinthills Middle School',
  'Flinthills Primary School',
  'Flintstone Elementary',
  'Flintville School',
  'Flippen Elementary School',
  'Flippin Elementary School',
  'Flippin High School',
  'Flippin Middle School',
  'Flocktown-kossmann Elementary School',
  'Flomaton Elementary School',
  'Flomaton High School',
  'Flood Brook Usd #301',
  'Floodwood Alt. Learning Program',
  'Floodwood Elementary',
  'Floodwood Secondary',
  'Flor Del Sol - Closed',
  'Flora Elementary School',
  'Flora High School',
  'Flora M. Singer Elementary School',
  'Flora Ridge Elementary School',
  'Flora S. Curtis School',
  'Flora Thew Elementary School',
  'Flora Vista Elementary',
  'Floral Avenue Elementary School',
  'Floral City Elementary School',
  'Floral Park Memorial High School',
  'Floral Park-bellerose School',
  'Floral Street School',
  'Florala High School',
  'Floranada Elementary School',
  'Florence Avenue Elementary',
  'Florence Avenue School',
  'Florence Brasser School',
  'Florence Career Center',
  'Florence Chapel Middle',
  'Florence Crittenton High School',
  'Florence Drake Elementary School',
  'Florence E. Rata',
  'Florence El',
  'Florence Elem School',
  'Florence Elementary',
  'Florence Elementary - 02',
  'Florence Elementary School',
  'Florence Freshman Center',
  'Florence Griffith Joyner Elementary',
  'Florence H S',
  'Florence High',
  'Florence High School',
  'Florence High School - 01',
  'Florence Hill El',
  'Florence Howard Elementary School',
  'Florence J Scott El',
  'Florence K-8',
  'Florence L. Walther School',
  'Florence Learning Center',
  'Florence M. Burd',
  'Florence M. Gaudineer Middle School',
  'Florence Markofer Elementary',
  'Florence Mattison Elem. School',
  'Florence Mcclure Correctional Center',
  'Florence Middle',
  'Florence Middle School',
  'Florence Middle School - 03',
  'Florence Nightingale Middle',
  'Florence Rideout Elementary School',
  'Florence Riverfront School',
  'Florence Roche School',
  'Florence Sawyer School',
  'Florence Township Memorial High School',
  'Florence V Evans',
  'Florence Virtual Academy',
  'Florence W Stiles Middle',
  'Florence Wilson Elem',
  'Florence-carlton 7-8',
  'Florence-carlton El Schl',
  'Florence-carlton Hs',
  'Florence-​Darlington Technical College',
  'Florencia Garcia',
  'Florencio Santiago',
  'Flores El',
  'Flores Middle',
  'Floresta Elementary School',
  'Floresville Alter Ctr',
  'Floresville Alternative Center',
  'Floresville Alternative Center Choice Program',
  'Floresville Choice Program',
  'Floresville H S',
  'Floresville Middle',
  'Floret Global University',
  'Florida A&m University Developmental Research School',
  'Florida Agricultural and Mechanical University',
  'Florida Atlantic University',
  'Florida Autism Charter School Of Excellence',
  'Florida Avenue Elementary School',
  'Florida City Elementary School',
  'Florida Connections Academy',
  'Florida Cyber Charter Academy',
  'Florida Cyber Charter Academy At Clay',
  'Florida Cyber Charter Academy At Osceola',
  'Florida Futures Academy North Campus',
  'Florida Futures Academy South Campus',
  'Florida Gulf Coast University',
  'Florida Institute of Technology',
  'Florida International Academy',
  'Florida International University',
  'Florida Keys Community College',
  'Florida Mesa Elementary School',
  'Florida Panhandle Technical College',
  'Florida Parishes Juvenile Detention Cntr.',
  'Florida Pitt-waller Ece-8 School',
  "Florida Sheriff's Boys Ranch",
  'Florida Southwestern Collegiate High School',
  'Florida State Hospital',
  'Florida State University',
  'Florida State University School',
  'Florida Street Discovery Center',
  'Florida Virtual Academy At Hillsborough County',
  'Florida Virtual Academy At Pasco',
  'Florida Virtual Academy At Pinellas Charter School',
  'Florida Virtual School Flex 6-8',
  'Florida Virtual School Flex 9-12',
  'Florida Virtual School Flex K-5',
  'Florida Youth Challenge Academy',
  'Florien High School',
  'Florin Elementary',
  'Florin High',
  'Florine J Abel Elementary School',
  'Floris Elementary',
  'Floro Torrence School 83',
  'Florosa Elementary School',
  'Flory Academy Of Sciences And Technology',
  'Flossie Floyd Green El',
  'Flossmoor Hills Elem School',
  'Flour Bluff El',
  'Flour Bluff H S',
  'Flour Bluff Int',
  'Flour Bluff J H',
  'Flour Bluff Pri',
  'Flournoy Elementary',
  'Flower Hill Elementary',
  'Flower Hill School',
  'Flower Mound 9th Grade Campus',
  'Flower Mound El',
  'Flower Mound H S',
  'Flower Mound Public School',
  'Flower Valley Elementary',
  'Flowers Elementary School',
  'Flowertown Elementary',
  'Flowery Branch Elementary School',
  'Flowery Branch High',
  'Flowery Elementary',
  'Flowing Wells Digital Campus',
  'Flowing Wells High School',
  'Flowing Wells Junior High School',
  'Flowood Elementary School',
  'Floyd A. Schelby',
  'Floyd Bell Elementary School',
  'Floyd C Fretz Ms',
  'Floyd Central High School',
  'Floyd County Area Technology Center',
  'Floyd County High',
  'Floyd D. Johnson Technology Center',
  'Floyd Dryden Middle School',
  'Floyd Ebeling Elementary School',
  'Floyd El',
  'Floyd Elementary',
  'Floyd Elementary School',
  'Floyd Harwood Elementary School',
  'Floyd Henson Jr High Sch',
  'Floyd High',
  'Floyd I. Marchus',
  'Floyd Kellam High',
  'Floyd L Knight Children Center',
  'Floyd L. Shelton Elementary School At Crossroad',
  'Floyd Light Middle School',
  'Floyd M Jewett Elem School',
  'Floyd M. Stork Elementary',
  'Floyd Middle',
  'Floyd Middle School',
  'Floyd S Winslow Elementary School',
  'Floyd School',
  'Floyd T. Binns Middle',
  'Floydada H S',
  'Floydada Isd Daep',
  'Floydada J H',
  'Floyds Knobs Elementary School',
  'Flushing Early Childhood Center',
  'Flushing High School',
  'Flushing International High School',
  'Flushing Middle School',
  'Fluvanna County High',
  'Fluvanna Middle',
  'Flvs Full Time K-5',
  'Flvs Full-time 6-12',
  'Flying Hills Elementary',
  'Flynn (leonard R.) Elementary',
  'Flynn Elementary',
  'Flynn Elementary School',
  'Flynn Park Elem.',
  'Focus',
  'Focus Academy',
  'Focus Alp',
  'Focus Beyond',
  'Focus Homeschool',
  'Focus Learning Academy',
  'Focus Learning Academy Of Northern Columbus',
  'Focus Learning Academy Of Southeastern Columbus',
  'Focus Learning Academy Of Southwest Columbus',
  'Focus North High School',
  'Focus Program',
  'Focus Safe Sch Program',
  'Foerster El',
  'Fogarty Es',
  'Fogarty Memorial',
  'Fogelsville Sch',
  'Foley Elementary',
  'Foley Elementary School',
  'Foley High School',
  'Foley Intermediate Elementary',
  'Foley Intermediate School',
  'Foley Middle School',
  'Foley Senior High',
  'Folger Mckinsey Elementary',
  'Folk Arts-cultural Treasures Cs',
  'Folks Middle',
  'Folkston Elementary School',
  'Follansbee Middle School',
  'Follett School',
  'Folly Quarter Middle',
  'Folsom Cordova K-8 Community Charter',
  'Folsom Elementary School',
  'Folsom High',
  'Folsom Hills Elementary',
  'Folsom Junior High School',
  'Folsom Lake College',
  'Folsom Lake High',
  'Folsom Middle',
  'Folsum Education & Community Center',
  'Folwell Arts Magnet',
  'Folwell Elementary',
  'Fomic Polytechnic',
  'Fond Du Lac High',
  'Fond Du Lac Ojibwe School',
  'Fond Du Lac Stem Academy',
  'Fond Du Lac Stem Institute',
  'Fond du Lac Tribal and Community College',
  'Fonda-fultonville 5-8 School',
  'Fonda-fultonville K-4 School',
  'Fonda-fultonville Senior High School',
  'Fondation Universitaire Luxembourgeoise',
  'Fondazione Sacro Cuore',
  'Fondren El',
  'Fondren Middle',
  'Fontainebleau High School',
  'Fontainebleau Junior High School',
  'Fontana A. B. Miller High',
  'Fontana Elementary',
  'Fontana High',
  'Fontana Middle',
  'Fontbonne College',
  'Fontenelle Elem School',
  'Fontys University of Applied Sciences',
  'Fonville Middle',
  'Fonwood Early Childhood Ctr',
  'Food And Finance High School',
  'Foose Sch',
  'Foot Of Ten El Sch',
  'Foothill Alternative High',
  'Foothill College',
  'Foothill Community Day',
  'Foothill Elementary',
  'Foothill Elementary School',
  'Foothill High',
  'Foothill High (alternative)',
  'Foothill High School',
  'Foothill Intermediate',
  'Foothill Knolls Stem Academy Of Innovation',
  'Foothill Leadership Academy',
  'Foothill Middle',
  'Foothill Oak Elementary',
  'Foothill Oaks Elementary',
  'Foothill Plus',
  'Foothill Ranch Elementary',
  'Foothill Ranch Middle',
  'Foothill School',
  'Foothill Technology High',
  'Foothills Academy',
  'Foothills Academy Connected',
  'Foothills Charter High School (central Office - Madison)',
  'Foothills Elementary',
  'Foothills Elementary School',
  'Foothills High',
  'Foothills Middle',
  'Foothills Middle School',
  'Foothills School',
  'Forbes Road Ctc',
  'Forbes Road El Sch',
  'Forbes Road Jshs',
  'Forbes School',
  'Forbush Elementary',
  'Forbush High',
  'Forbush Middle',
  'Force Elementary School',
  'Ford Avenue Elementary School',
  'Ford Boulevard Elementary',
  'Ford Early Learning Center',
  'Ford El',
  'Ford Elementary',
  'Ford Elementary School',
  'Ford High School',
  'Ford Intermediate School',
  'Ford Road Elementary',
  'Ford-ford Br. Elem. Comm. Ed.',
  'Forder Elem.',
  'Fordham Colony Elementary - 04',
  'Fordham High School For The Arts',
  'Fordham Leadership Academy',
  'Fordham University',
  'Fordland Elem.',
  'Fordland High',
  'Fordland Middle',
  'Fordline Elementary School',
  'Fords Middle School',
  'Fords Prairie Elementary',
  'Fordson High School',
  'Fordsville Elementary School',
  'Fordville-lankin Elementary School',
  'Fordville-lankin High School',
  'Fordyce Elementary Schools',
  'Fordyce High School',
  'Foreign Language Academy',
  'Foreign Language Immersion And Cultural Studies',
  'Foreign Trade University',
  'Foreman High School',
  'Forest Academy',
  'Forest Acres Elementary',
  'Forest Area High School',
  'Forest Area Middle School',
  'Forest Avenue Elementary',
  'Forest Avenue Elementary School',
  'Forest Avenue School',
  'Forest Brook Elementary School',
  'Forest Brook Middle',
  'Forest Center',
  'Forest Charter',
  'Forest City Elementary School',
  'Forest City High School',
  'Forest City Middle School',
  'Forest City Regional El Sch',
  'Forest City Regional Hs',
  'Forest City-dunbar Elementary School',
  'Forest Creek El',
  'Forest Dale Elementary School',
  'Forest Edge Elementary',
  'Forest Elem School',
  'Forest Elementary',
  'Forest Elementary School',
  'Forest Glen Elem School',
  'Forest Glen Elementary',
  'Forest Glen Elementary School',
  'Forest Glen Middle',
  'Forest Glen Middle School',
  'Forest Grove Community School',
  'Forest Grove El Sch',
  'Forest Grove Elementary',
  'Forest Grove Elementary School',
  'Forest Grove High School',
  'Forest Grove Middle',
  'Forest Grove Middle School',
  'Forest Grove Public School',
  'Forest Heights Academy Of Excellence',
  'Forest Heights Elem. School',
  'Forest Heights Elementary',
  'Forest Heights Stem Academy',
  'Forest High School',
  'Forest Hill Community High School',
  'Forest Hill Community Learning Center',
  'Forest Hill El',
  'Forest Hill Elementary',
  'Forest Hill Elementary School',
  'Forest Hill High Adult Education',
  'Forest Hill High School',
  'Forest Hill Junior High School',
  'Forest Hill School',
  'Forest Hills Consolidated School',
  'Forest Hills El Sch',
  'Forest Hills Elem School',
  'Forest Hills Elementary',
  'Forest Hills Elementary School',
  'Forest Hills Global Elementary',
  'Forest Hills High',
  'Forest Hills High School',
  'Forest Hills Jshs',
  'Forest Hills Middle',
  'Forest Hills School',
  'Forest Hills Spec Educ Coop',
  'Forest Hills Transition Center',
  'Forest Home Elementary',
  'Forest Knolls Elementary',
  'Forest Lake Area Learning Center',
  'Forest Lake Elementary',
  'Forest Lake Elementary School',
  'Forest Lake School',
  'Forest Lake Senior High',
  'Forest Lakes Elementary',
  'Forest Lakes Elementary School',
  'Forest Lane Academy',
  'Forest Lane Community School',
  'Forest Meadow J H',
  'Forest Middle',
  'Forest North El',
  'Forest Oak Elementary School',
  'Forest Oak Middle',
  'Forest Oaks Elementary School',
  'Forest Park Academy',
  'Forest Park El. School',
  'Forest Park Elem.',
  'Forest Park Elementary',
  'Forest Park Elementary School',
  'Forest Park High',
  'Forest Park High School',
  'Forest Park Individual Ed School',
  'Forest Park Jr-sr High School',
  'Forest Park Magnet School',
  'Forest Park Middle',
  'Forest Park Middle School',
  'Forest Park School',
  'Forest Pines Drive Elementary',
  'Forest Ranch Charter',
  'Forest Research Institute Dehradun',
  'Forest Ridge',
  'Forest Ridge Elementary',
  'Forest Ridge Elementary School',
  'Forest Road Elem School',
  'Forest Road School',
  'Forest School',
  'Forest Scott Co Voc Tech Center',
  'Forest Street Elementary School',
  'Forest Trail El',
  'Forest View Elem',
  'Forest View Elem School',
  'Forest View Elementary',
  'Forest View Elementary School',
  'Forest View School',
  'Forest Vista El',
  'Forestbrook Elementary',
  'Forestbrook Middle',
  'Forestburg School',
  'Forestdale',
  'Forestdale Elementary',
  'Forestdale School',
  'Forester El',
  'Foresthill Divide',
  'Foresthill High',
  'Forestlawn Elementary',
  'Forestport Elementary School',
  'Forestridge El',
  'Forestview High',
  'Forestview Middle',
  'Forestville Academy',
  'Forestville Central High School',
  'Forestville Elementary',
  'Forestville Elementary School',
  'Forestville Road Elementary',
  'Forestwood Middle',
  'Forgan Es',
  'Forgan Hs',
  'Forge Ridge School',
  'Forge Road El Sch',
  'Fork Lane School',
  'Fork Shoals School',
  'Forked Island/e. Broussard Elem School',
  'Forked River Elementary School',
  'Forkner Elementary',
  'Forks Alternative School',
  'Forks El Sch',
  'Forks Elementary School',
  'Forks Intermediate School',
  'Forks Junior-senior High School',
  'Forks Of Salmon Elementary',
  'Forks River Elementary',
  'Forman El',
  'Forney Academic Center',
  'Forney H S',
  'Forrest City College Preparatory School',
  'Forrest City High School',
  'Forrest City Jr. High',
  'Forrest County Agricultural Hi Sch',
  'Forrest Edwin Sch',
  'Forrest G Averill School',
  'Forrest M Bird Charter School',
  'Forrest Park Prep Preschool',
  'Forrest Road Elementary School',
  'Forrest School',
  'Forrest Street Early Learning Center',
  'Forrest W. Hunt Elementary School',
  'Forrestal Elem School',
  'Forrestdale School',
  'Forrester Elementary',
  'Forreston Grade School',
  'Forreston Jr/sr High Sch',
  'Forsan El',
  'Forsan H S',
  'Forsyth 7-8',
  'Forsyth Academy',
  'Forsyth Central High School',
  'Forsyth Elem.',
  'Forsyth Elementary School',
  'Forsyth High',
  'Forsyth High School',
  'Forsyth Middle',
  'Forsyth Technical Community College',
  'Forsyth Virtual Academy',
  'Forsyth Woods Elementary',
  'Forsythe Middle School',
  'Forsythe Satellite Academy',
  'Fort Allen El Sch',
  'Fort Ann Elementary School',
  'Fort Ann Middle/high School',
  'Fort Ashby Primary School',
  'Fort Atkinson 4k',
  'Fort Atkinson High',
  'Fort Atkinson Middle',
  'Fort Barton School',
  'Fort Bellefontaine Campus',
  'Fort Belvoir Elementary',
  'Fort Belvoir Upper School',
  'Fort Bend Co Alter',
  'Fort Benton 7-8',
  'Fort Benton High School',
  'Fort Benton School',
  'Fort Blackmore Primary',
  'Fort Braden School',
  'Fort Bragg High',
  'Fort Bragg Middle',
  'Fort Branch Community School',
  'Fort Calhoun Elementary School',
  'Fort Calhoun High School',
  'Fort Caroline Elementary School',
  'Fort Caroline Middle School',
  'Fort Cherry El Ctr',
  'Fort Cherry Jshs',
  'Fort Chiswell High',
  'Fort Chiswell Middle',
  'Fort Clarke Middle School',
  'Fort Cobb-broxton Hs',
  'Fort Cobb-broxton Lower Es',
  'Fort Cobb-broxton Ms',
  'Fort Collins High School',
  'Fort Collins Montessori School',
  'Fort Colville Elementary',
  'Fort Couch Ms',
  'Fort Crook Elementary School',
  'Fort Daniel Elementary School',
  'Fort Dearborn Elem School',
  'Fort Defiance High',
  'Fort Deposit Elementary School',
  'Fort Dix Elementary School',
  'Fort Dodge High School',
  'Fort Dodge Middle School',
  'Fort Dorchester Elementary',
  'Fort Dorchester High',
  'Fort Edward School',
  'Fort Elliott School',
  'Fort Fairfield Elementary School',
  'Fort Fairfield Middle/high School',
  'Fort Foote Elementary',
  'Fort Frye High School',
  'Fort Garrison Elementary',
  'Fort Gay Pre K-8',
  'Fort Gibson Early Lrning Ctr',
  'Fort Gibson Hs',
  'Fort Gibson Intermediate Es',
  'Fort Gibson Ms',
  'Fort Gratiot Middle School',
  'Fort Green Preparatory Academy',
  'Fort Hall Elementary School',
  'Fort Hamilton High School',
  'Fort Hancock H S',
  'Fort Hancock Middle',
  'Fort Hayes Arts And Academic Hs',
  'Fort Hayes Career Center',
  'Fort Hays State University',
  'Fort Herriman Middle',
  'Fort Hill Collaborative Elementary School',
  'Fort Hill High',
  'Fort Howard Elementary',
  'Fort Hunt Elementary',
  'Fort Irwin Middle',
  'Fort Island Primary Elementary School',
  'Fort Jennings Elementary School',
  'Fort Jennings High School',
  'Fort Johnson Middle',
  'Fort Jones Elementary',
  'Fort Kent Community High School',
  'Fort Kent Elementary School',
  'Fort King Middle School',
  'Fort Larned Elementary School',
  'Fort Lauderdale High School',
  'Fort Leboeuf Ms',
  'Fort Leboeuf Shs',
  'Fort Lee High School',
  'Fort Lewis College',
  'Fort Lewis Elementary',
  'Fort Lewis Mesa Elementary School',
  'Fort Logan Northgate',
  'Fort Logan School',
  'Fort Loramie Elementary School',
  'Fort Loramie Junior-senior High School',
  'Fort Lupton High School',
  'Fort Lupton Middle School',
  'Fort Madison High School',
  'Fort Madison Middle School',
  'Fort Mccoy School',
  'Fort Meade Middle/senior High School',
  'Fort Meadow Early Childhood Center',
  'Fort Meigs Elementary School',
  'Fort Miami Elementary School',
  'Fort Middle School',
  'Fort Mill Elementary',
  'Fort Mill High',
  'Fort Mill Middle',
  'Fort Miller Middle',
  'Fort Mohave Elementary School',
  'Fort Montgomery Elementary School',
  'Fort Morgan High School',
  'Fort Morgan Middle School',
  'Fort Myers Beach Elementary School',
  'Fort Myers High School',
  'Fort Myers Middle Academy',
  'Fort Myers Technical College',
  'Fort Necessity School',
  "Fort O'brien School",
  'Fort Osage High',
  'Fort Payne High School',
  'Fort Payne Middle School',
  'Fort Peck Community College',
  'Fort Pierce Central High School',
  'Fort Pierce Magnet School Of The Arts',
  'Fort Pierce Westwood High School',
  'Fort Plain Junior-senior High School',
  'Fort Ransom Elementary School',
  'Fort Recovery Elementary School',
  'Fort Recovery High School',
  'Fort Recovery Middle School',
  'Fort Riley Elem',
  'Fort Riley Middle School',
  'Fort River Elementary',
  'Fort Ross Elementary',
  'Fort Sage Middle',
  'Fort Salonga Elementary School',
  'Fort Scott Community College',
  'Fort Scott Middle School',
  'Fort Scott Sr High',
  'Fort Settlement Middle',
  'Fort Shaw Elem School',
  'Fort Smallwood Elementary',
  'Fort Smith School',
  'Fort Stevens Elementary',
  'Fort Stockton H S',
  'Fort Stockton Middle',
  'Fort Street School',
  'Fort Sumner Elementary',
  'Fort Sumner High',
  'Fort Sumner Middle',
  'Fort Supply Es',
  'Fort Supply Hs',
  'Fort Thomas Elementary School',
  'Fort Thomas High School',
  'Fort Totten Spec Ed Unit',
  'Fort Towson Es',
  'Fort Towson Hs',
  'Fort Towson Jhs',
  'Fort Valley Middle School',
  'Fort Vancouver High School',
  'Fort Walton Beach High School',
  'Fort Washington El Sch',
  'Fort Washington Elementary',
  'Fort Washington Forest Elementary',
  'Fort White Elementary School',
  'Fort White High School',
  'Fort Worth Academy Of Fine Arts',
  'Fort Worth Academy Of Fine Arts El',
  'Fort Worth Can Academy Lancaster Avenue',
  'Fort Worth Can Academy Westcreek',
  'Fort Worthington Elementary',
  'Fort Yates Middle School',
  'Fort Yukon School',
  'Fort Zeller El Sch',
  'Forte Preparatory Academy Charter School',
  'Fortine 7-8',
  'Fortine School',
  'Fortis Academy',
  'Forts Ferry School',
  'Forts Pond Elementary',
  'Fortuna Middle',
  'Fortuna Union High',
  'Fortunato Jorge Corona',
  'Fortune',
  'Fortville Elementary School',
  'Forty-niner Rop',
  'Forty-ninth Street Elementary',
  'Forty-second Street Elementary',
  'Forwood Elementary School',
  'Foshan University',
  'Foshay Learning Center',
  'Foskett Ranch Elementary',
  'Foss',
  'Fossil Charter School',
  'Fossil Hill Middle',
  'Fossil Ridge H S',
  'Fossil Ridge High School',
  'Fossil Ridge Intermediate',
  'Fosston Secondary',
  'Foster Academy',
  'Foster City Elementary',
  'Foster El',
  'Foster El Sch',
  'Foster Elementary',
  'Foster Elementary School',
  'Foster H S',
  'Foster Heights Elementary School',
  'Foster Meade Career & Technical Center',
  'Foster Middle',
  'Foster Park Elem School',
  'Foster Park Elementary',
  'Foster Regional Applied Tech Ctr',
  'Foster Road Elementary',
  'Foster School',
  'Foster Senior High School',
  'Foster Traditional Academy',
  'Foster Village El',
  'Fostering Innovation And Relevance Through Stem And Trade',
  'Fostertown Etc Magnet School',
  'Fostoria Intermediate Elementary School',
  'Fostoria Junior/senior High School',
  'Fouke Elementary School',
  'Fouke High School',
  'Foulks Ranch Elementary',
  'Foundation Academy',
  'Foundation Academy Charter School',
  'Foundation For Early Learners Murphy Eaa',
  'Foundation For Early Learners Rutherford Charter',
  'Foundation Preparatory',
  'Foundation University',
  'Foundations Academy',
  'Foundations College Prep Charter',
  'Founders Academy',
  'Founders Academy Of Nevada',
  'Founders Classical Academy',
  'Founders Classical Academy Of Dallas',
  'Founders Classical Academy Of Flower Mound',
  'Founders Classical Academy Of Leander',
  'Founders Classical Academy Of Mesquite',
  'Founders Classical Academy Of Schertz',
  'Founders Elementary School',
  'Founders Hall Middle Sch',
  'Founders Memorial School',
  'Fountain Center',
  'Fountain Central High School',
  'Fountain City Elementary',
  'Fountain Elementary School',
  'Fountain Green Elementary',
  'Fountain Green School',
  'Fountain Hill El Sch',
  'Fountain Hills Charter School',
  'Fountain Hills High School',
  'Fountain Hills Middle School',
  'Fountain Inn Elementary',
  'Fountain International Magnet School',
  'Fountain Lake Charter High School',
  'Fountain Lake Elementary',
  'Fountain Lake Middle School Cobra Digital Prep Academy',
  'Fountain Middle School',
  'Fountain Primary School',
  'Fountain Rock Elementary',
  'Fountain University',
  'Fountain Valley High',
  'Fountain Woods Elementary School',
  'Fountain-fort Carson High School',
  'Fountain.hills Online',
  'Fountaindale Elementary',
  'Fountainhead College of Technology',
  'Four Corners Charter High School',
  'Four Corners Charter School',
  'Four Corners Elementary',
  'Four Corners Elementary School',
  'Four Corners Montessori Academy',
  'Four Corners Upper School',
  'Four County Area Voc Coop',
  'Four County Career Center',
  'Four Creeks Elementary',
  'Four Georgians School',
  'Four Heroes Elementary',
  'Four Mile Elementary',
  'Four Oaks Elementary',
  'Four Oaks Middle',
  'Four Peaks Elementary School',
  'Four Points Middle',
  'Four Rivers Career Ctr.',
  'Four Rivers Charter Public School',
  'Four Rivers Community School',
  'Four Seasons Elementary',
  'Four Winds Community High School',
  'Four Winds Community Pk School',
  'Fourah Bay College University of Sierra Leone',
  'Fourteenth Avenue School',
  'Fourteenth Street School',
  'Fourth Avenue Junior High School',
  'Fourth District Elementary School',
  'Fourth Street Elementary',
  'Fourth Street Learning Center',
  'Fourth Street Primary Center',
  'Fouse Elementary School',
  'Foust Elementary School',
  'Fowler Academy Community Day',
  'Fowler Academy Community Day (9-12)',
  'Fowler Academy Continuation',
  'Fowler Academy Independent Study',
  'Fowler Drive Elementary School',
  'Fowler El',
  'Fowler Elem',
  'Fowler Elementary School',
  'Fowler High',
  'Fowler High School',
  'Fowler Junior High School',
  'Fowler Middle',
  'Fowler School',
  'Fowlerville High School',
  'Fowlerville Junior High School',
  'Fowlerville Online Learning Academy',
  'Fox Chapel Area Hs',
  'Fox Chapel Elementary',
  'Fox Chapel Middle School',
  'Fox Chase Elementary School',
  'Fox Chase Sch',
  'Fox Cities Leadership Academy',
  'Fox College',
  'Fox Creek Charter High',
  'Fox Creek Elementary School',
  'Fox Creek Jr High School',
  'Fox Elem.',
  'Fox Elementary',
  'Fox Elementary School',
  'Fox Es',
  'Fox Hill',
  'Fox Hill Elem.',
  'Fox Hill Elementary School',
  'Fox Hills Center',
  'Fox Hills School',
  'Fox Hollow Elementary School',
  'Fox Hollow School',
  'Fox Hs',
  'Fox Lake Correctional Institution',
  'Fox Lane High School',
  'Fox Lane Middle School',
  'Fox Meadow Elementary',
  'Fox Meadow Elementary School',
  'Fox Meadow Intermediate Center',
  'Fox Meadow Middle School',
  'Fox Meadow School',
  'Fox Meadows Elementary',
  'Fox Middle',
  'Fox Mill Elementary',
  'Fox Prairie Elementary',
  'Fox Ridge Middle School',
  'Fox Ridge School',
  'Fox River Academy',
  'Fox River Grove Middle School',
  'Fox River Middle',
  'Fox Road Elementary',
  'Fox Run El',
  'Fox Run Elementary School',
  'Fox Sr. High',
  'Fox Technical H S',
  'Fox Trail Elementary School',
  'Fox Twp El Sch',
  'Fox Valley Technical College',
  'Fox West Academy',
  'Foxboro Elementary',
  'Foxboro School',
  'Foxborough High',
  'Foxborough Regional Charter School',
  'Foxfire East Academy',
  'Foxfire High School',
  'Foxfire Intermediate School',
  'Foxview Intermediate',
  'Foyil Es',
  'Foyil Hs',
  'Foyil Jhs',
  'Fpg Elementary',
  'Frakes School Center',
  'Frametown Elementary School',
  'Framingham High School',
  'Fran Fruzen Intermediate',
  'Frances And Jeannette Lee El',
  'Frances Brandon-pickett Elementary',
  'Frances C. Richmond School',
  'Frances C. Smith Early Childhood Center No 50',
  'Frances Corprew El',
  'Frances Demasi Elementary School',
  'Frances Demasi Middle School',
  'Frances Drake School',
  'Frances E Norton El',
  'Frances Ellen Watkins Harper Junior High',
  'Frances F. Mack Intermediate',
  'Frances Fuchs Early Childhood Center',
  'Frances Hazel Reid Elementary',
  'Frances J Warren Elementary School',
  'Frances K. Sweet Elementary School',
  'Frances Nungester Elementary School',
  'Frances Owen Holaway Elementary School',
  'Frances Perkins Academy',
  'Frances S. Tucker Elementary School',
  'Frances Slocum Elem School',
  'Frances Wakeland Elementary School',
  'Frances Walker Halfway House',
  'Frances Willard #32',
  'Frances Willard Elem',
  'Frances Willard Elem School',
  'Francestown Elementary School',
  'Francine Delany New School',
  'Francis A Higgins Elem School',
  'Francis A. Desmares Elementary School',
  'Francis Asbury Elementary',
  'Francis Bellamy Pre-school Center',
  'Francis Bradley Middle',
  'Francis C. Hammond Middle',
  'Francis Case Elementary - 04',
  'Francis D Raub Ms',
  'Francis El',
  'Francis Es',
  'Francis Granger Middle School',
  'Francis H Cortney Junior High School',
  'Francis H Sheckler El Sch',
  'Francis Hopkinson Elementary',
  'Francis Howell Central High',
  'Francis Howell High',
  'Francis Howell Middle',
  'Francis Howell North High',
  'Francis Howell Union High',
  'Francis J Kane',
  'Francis J Mcgrath Elementary',
  "Francis J O'neill School",
  'Francis J. Varieur School',
  'Francis J. White Learning Center',
  'Francis Joseph Reitz High School',
  'Francis L Stevens Elementary School',
  'Francis Lewis High School',
  'Francis Lore Elementary School',
  'Francis M Leahy',
  'Francis M Price Elementary School',
  'Francis M. Day Elementary School',
  'Francis Marion Intermediate School',
  'Francis Marion Military Academy',
  'Francis Marion School',
  'Francis Mcclure El Sch',
  'Francis Mcclure Intrmd Sch',
  'Francis Mcclure Primary Sch',
  'Francis R Scobee Middle',
  'Francis Reh Psa',
  'Francis S Grandinetti El Sch',
  'Francis School',
  'Francis Scott Key Elementary',
  'Francis Scott Key Elementary/middle',
  'Francis Scott Key High',
  'Francis Scott Key Middle',
  'Francis Street Primary School',
  'Francis T Bresnahan Elementary',
  'Francis T. Evans Elementary',
  'Francis T. Maloney High School',
  'Francis Vigo Elementary School',
  'Francis W Parker',
  'Francis W Parker School 56',
  'Francis W. Jones Magnet Middle',
  'Francis W. Parker Charter Essential School',
  'Francis Walsh Intermediate School',
  'Francis Wyman Elementary',
  'Francis X Hegarty Elementary School',
  'Francis X. Mcgraw Elementary',
  'Francis X. Nava Elementary',
  'Francisca Chavez (la Planta)',
  'Francisca Davila Semprit',
  'Francisco (prisco) Fuentes',
  'Francisco Barrientes Middle',
  'Francisco Bravo Medical Magnet High',
  'Francisco Elementary School',
  'Francisco Felicie Martinez (su Brenas)',
  'Francisco G. Pachin Marin',
  'Francisco Garcia Boyrie',
  'Francisco Gaztambide Vega',
  'Francisco Jordan',
  'Francisco Lopez Cruz',
  'Francisco Lugo Rosa (culebrinas)',
  'Francisco Manrique Cabrera',
  'Francisco Mariano Quinones',
  'Francisco Matias Lugo',
  'Francisco Medrano Middle',
  'Francisco Mendoza',
  'Francisco Menendez Balbane',
  'Francisco Middle',
  'Francisco Morales',
  'Francisco Oller',
  'Francisco Parra Duperon',
  'Francisco Pietri Mariani',
  'Francisco Prado Picart',
  'Francisco Ramos Sanchez',
  'Francisco Rivera Claudio',
  'Francisco Rodriguez Lopez',
  'Francisco Roque Munoz',
  'Francisco Sepulveda Middle',
  'Francisco Torres',
  'Francisco Valdes Rola',
  'Francisco Vasquez De Coronado Elementary School',
  'Francisco Vicenty',
  'Francisco Zayas Santana',
  'Francois Maconce Elem School',
  'Francone El',
  'Franconia El Sch',
  'Franconia Elementary',
  'Francophone Charter School Of Oakland',
  'Frank A Brodnicki Elem School',
  'Frank A Sedita School #30',
  'Frank A. Berry School',
  'Frank Anne Sch',
  'Frank Antonides School',
  'Frank Augustus Miller Middle',
  'Frank Borman School',
  'Frank Brattin Middle Schl',
  'Frank C Whiteley Elem School',
  'Frank Church High School',
  'Frank Crawford Martin K-8 Center',
  'Frank D Moates El',
  'Frank D. Parent',
  'Frank D. Spaziano Annex',
  'Frank D. Spaziano Elem School',
  'Frank Defino Central Elementary School',
  'Frank Del Olmo Elementary',
  'Frank E Bartlett School',
  'Frank E Weiss Elem School',
  'Frank E. Brigham Academy',
  'Frank E. Thompson Middle',
  'Frank E. Woodruff Elementary',
  'Frank El',
  'Frank Elementary',
  'Frank Elementary School',
  'Frank F Garside Junior High School',
  'Frank G Lindsey Elementary School',
  'Frank Guzick El',
  'Frank H Freedman',
  'Frank H Hammond Elementary School',
  'Frank H Harrison Middle School',
  'Frank H Wheeler Elementary Sch 4',
  'Frank H. Peterson Academies',
  'Frank Hartsfield Elementary School',
  'Frank Hughes School',
  'Frank I Brown Elementary School',
  'Frank J Carasiti Elementary School',
  'Frank J Smith Elementary',
  'Frank J. Dugan Elementary School',
  'Frank J. Zamboni',
  'Frank Jacinto Elementary',
  'Frank Jeannette Jr High School',
  'Frank K. Hehnly',
  'Frank Kim Elementary School',
  'Frank Kohn Elementary',
  'Frank L Madla Accelerated Collegiate Academy',
  'Frank L Madla Early College H S',
  'Frank L Smart Intermediate',
  'Frank L. Huff Elementary',
  'Frank Lanterman',
  'Frank Layden Elem',
  'Frank Ledesma Elementary',
  'Frank Lloyd Wright Intermediate',
  'Frank Long Elementary School',
  'Frank Love Elementary',
  'Frank M Knight Elementary School',
  'Frank M Sokolowski Elementary',
  'Frank M Tejeda Academy',
  'Frank M. Golson Elementary School',
  'Frank M. Wright Middle',
  'Frank Macias El',
  'Frank Madla El',
  'Frank Mccourt High School',
  'Frank Mcmillan J H',
  'Frank Mitchell Intermediate School',
  'Frank Morgan Elementary',
  'Frank Newman Middle',
  'Frank Nicholas Elementary School',
  "Frank O'bannon Elementary School",
  'Frank Otis Elementary',
  'Frank P Long Intermediate School',
  'Frank P. Brown Elementary',
  'Frank Paul Elementary',
  'Frank Phillips College',
  'Frank R Conwell School',
  'Frank Rushton Elem',
  'Frank S Lamping Elementary School',
  'Frank Seale Middle',
  'Frank Sinatra School Of The Arts High School',
  'Frank Sparkes Elementary',
  'Frank Stone Middle',
  'Frank T. Wheeler School',
  'Frank Tillery Elem. School',
  'Frank V Bergman Elem',
  'Frank Vessels Elementary',
  'Frank W. Cox High',
  'Frank W. Gavlak Elementary School',
  'Frank W. Springstead High School',
  'Frank W. Springstead High/adul',
  'Frank Wagner Elementary',
  'Frank Ward Strong School',
  'Frank West Elementary',
  'Frank White Education Center',
  'Frank Zeek Elementary',
  'Franke Park Elementary School',
  'Frankenmuth High School',
  'Frankford Elem.',
  'Frankford Elementary',
  'Frankford Hs',
  'Frankford Middle',
  'Frankford Township School',
  'Frankfort Career Development Center',
  'Frankfort Comm High School',
  'Frankfort Early Learning Academy',
  'Frankfort Elem',
  'Frankfort Elementary School',
  'Frankfort High',
  'Frankfort High School',
  'Frankfort Intermediate School',
  'Frankfort Middle School',
  'Frankfort Square Elem School',
  'Frankfort-schuyler Central High School',
  'Frankfort-schuyler Elementary School',
  'Frankfort-schuyler Middle School',
  'Frankie Ray Jackson Sr. Technical Center',
  'Frankie W Mccullough Acad For Girl',
  'Franklin',
  'Franklin (benjamin) Elementary',
  'Franklin - Pre School Program',
  'Franklin Academy',
  'Franklin Academy Charter C',
  'Franklin Academy Charter D',
  'Franklin Academy Charter School B',
  'Franklin Academy E',
  'Franklin Academy F',
  'Franklin Academy High School',
  'Franklin Academy Pembroke Pines',
  'Franklin Academy Pembroke Pines High School',
  'Franklin Academy Sunrise',
  'Franklin Area Jshs',
  'Franklin Area Ms',
  'Franklin At Alma Elementary',
  'Franklin At Brimhall Elementary',
  'Franklin Ave',
  'Franklin Avenue Elementary',
  'Franklin Avenue Middle School',
  'Franklin Avenue School',
  'Franklin Benjamin Hs',
  'Franklin Benjamin Sch',
  'Franklin Career & Technical Center',
  'Franklin Central High School',
  'Franklin Central School',
  'Franklin Classical Middle',
  'Franklin Co High School',
  'Franklin Co. Elem.',
  'Franklin Co. Specl. Ed. Coop.',
  'Franklin College Switzerland',
  'Franklin Community High School',
  'Franklin Community Middle School',
  'Franklin County Alternative School',
  'Franklin County Career And Technical Ctr',
  'Franklin County Career Technical Center',
  'Franklin County Ctc',
  'Franklin County Early College',
  'Franklin County High',
  'Franklin County High School',
  'Franklin County High Schoool',
  'Franklin County Learning Center (adult Divison)',
  'Franklin County Middle School',
  'Franklin County School',
  'Franklin County Technical',
  'Franklin D Roosevelt',
  'Franklin D Roosevelt El',
  'Franklin D Roosevelt H S',
  'Franklin D Roosevelt School',
  'Franklin D Roosevelt Senior High School',
  'Franklin D. Roosevelt',
  'Franklin Delano Roosevelt',
  'Franklin Delano Roosevelt Elementary',
  'Franklin Delano Roosevelt High School',
  'Franklin Discovery Academy',
  'Franklin Early Childhood Center',
  'Franklin Early Childhood Ctr.',
  'Franklin Early Childhood Development Center',
  'Franklin East Elementary School',
  'Franklin East Grade School',
  'Franklin El',
  'Franklin El Sch',
  'Franklin Elem',
  'Franklin Elem Fine Arts Center',
  'Franklin Elem School',
  'Franklin Elem.',
  'Franklin Elementary',
  'Franklin Elementary Center',
  'Franklin Elementary Sch',
  'Franklin Elementary School',
  'Franklin Forest Elementary',
  'Franklin H S',
  'Franklin Heights High School',
  'Franklin High',
  'Franklin High School',
  'Franklin Incentive Elem. Sch.',
  'Franklin Jr/sr High School',
  'Franklin Junior High School',
  'Franklin L. Williams School',
  'Franklin Lc',
  'Franklin Local Community School',
  'Franklin Lower Elementary',
  'Franklin Middle',
  'Franklin Middle Magnet School',
  'Franklin Middle School',
  'Franklin Military Academy',
  'Franklin Monroe Elementary School',
  'Franklin Monroe High School',
  'Franklin Parish High School',
  'Franklin Park Elementary School',
  'Franklin Park Middle School',
  'Franklin Park School',
  'Franklin Phonetic Primary School',
  'Franklin Phonetic Primary School-sunnyslope',
  'Franklin Pierce High School',
  'Franklin Pierce Law Center',
  'Franklin Police And Fire High School',
  'Franklin Primary School',
  'Franklin Regional Ms',
  'Franklin Regional Shs',
  'Franklin S Edmonds Sch',
  'Franklin Sch',
  'Franklin School',
  'Franklin Secondary School',
  'Franklin Senior High School',
  'Franklin Smith Elem.',
  'Franklin Square Elementary',
  'Franklin Steps K-2',
  'Franklin Technology Ctr.',
  'Franklin Towne Charter Elementary School',
  'Franklin Towne Chs',
  'Franklin Township Middle Sch West',
  'Franklin Township Middle Schl East',
  'Franklin Township School',
  'Franklin Twp El Sch',
  'Franklin Twp Elementary School',
  'Franklin University',
  'Franklin Upper Elementary',
  'Franklin Virtual Instruction Program',
  'Franklin West Elementary',
  'Franklin Woods Intermediate School',
  'Franklin and Marshall College',
  'Franklin-essex-hamilton Boces',
  'Franklin-simpson High School',
  'Franklin-simpson High School West Campus',
  'Franklin-simpson Middle School',
  'Franklinton Elementary',
  'Franklinton Elementary School',
  'Franklinton High',
  'Franklinton High School',
  'Franklinton Junior High School',
  'Franklinton Middle',
  'Franklinton Preparatory Academy',
  'Franklinton Primary School',
  'Franklinville Elementary',
  'Franklinville Elementary School',
  'Franklinville Junior-senior High School',
  'Franklyn S Barry School',
  'Frankston El',
  'Frankston H S',
  'Frankston Middle',
  'Frankstown El Sch',
  'Frankton Elementary School',
  'Frankton Jr-sr High School',
  'Franktown Elementary School',
  'Frantz Coe Elementary School',
  'Franz El',
  'Frasch Elementary School',
  'Fraser High School',
  'Fraser Valley Elementary School',
  'Fraternidad',
  'Fratney Elementary',
  'Fratt Elementary',
  'Fray Bartolome De Las Casas',
  'Frayser Achievement Elementary',
  'Frayser Elementary',
  'Frazee Elementary',
  'Frazee Elementary School',
  'Frazee Secondary',
  'Frazer 7-8',
  'Frazer Elementary',
  'Frazer Elementary School',
  'Frazer High School',
  'Frazer K-8 School',
  'Frazeysburg Elementary School',
  'Frazier El',
  'Frazier El Sch',
  'Frazier Elementary',
  'Frazier Hs',
  'Frazier Mountain High',
  'Frazier Ms',
  'Frazier Park Elementary',
  'Frazier Perspectives Magnet Es',
  'Frazier Prep Acad Charter Es',
  'Fred A Anderson Elementary',
  'Fred And Patti Shafer El',
  'Fred And Sara Machetanz Elementary School',
  'Fred Assam Elementary - 06',
  'Fred Becker Elementary School',
  'Fred Booth',
  'Fred C. Beyer High',
  'Fred C. Underhill School',
  'Fred Douglass',
  'Fred Douglass Early Childhood Center',
  'Fred E. Lull Special Education Center',
  'Fred E. Weibel Elementary',
  'Fred Ekstrand Elementary',
  'Fred El',
  'Fred Fifer Iii Middle School',
  'Fred Fifer Iii Middle School Ilc',
  'Fred G. Garner Elementary School',
  'Fred H Croninger Elementary School',
  'Fred H Tally El',
  'Fred Ipalook Elementary',
  'Fred J Hums Elementary School',
  'Fred J Page High School',
  'Fred J Page Middle School',
  'Fred J. Jaindl El Sch',
  'Fred K. Marchman Technical College',
  'Fred L Aiken El Sch',
  'Fred L Wilson Elementary',
  'Fred L. Thompson Junior High',
  'Fred L. Williams Elementary',
  'Fred M. Lynn Middle',
  'Fred Moodry Intermediate',
  'Fred Moore H S',
  'Fred P Hall School',
  'Fred P. Hamilton Career Center',
  'Fred Roberts Middle',
  'Fred Rodgers Magnet Acad',
  'Fred S Engle Ms',
  'Fred T Foard High',
  'Fred T. Korematsu Discovery Academy',
  'Fred T. Korematsu Elementary At Mace Ranch',
  'Fred T. Korematsu Middle',
  'Fred Tjardes School Of Innovation',
  'Fred W Eberle Tech Center',
  'Fred W Edwards Academy Aec',
  'Fred W Graff School',
  'Fred W Hill School',
  'Fred W Ritter Elementary School',
  'Fred W Traner Middle School',
  'Fred Wild Elementary School',
  'Freda Markley Early Childhood',
  'Fredda Nottingham Alternative Education Center',
  'Freddy Gonzalez El',
  'Frederic 6-12 School',
  'Frederic A. Priff Elementary School',
  'Frederic Elementary',
  'Frederic N. Brown Elementary School',
  'Frederic Remington High',
  'Frederic W. Cook Elementary School',
  'Frederick A Douglass Learning Academy',
  'Frederick Brewster School',
  'Frederick C Murphy',
  'Frederick Carder Elementary School',
  'Frederick Classical Charter School',
  'Frederick Community College',
  'Frederick County Career & Technology Center',
  'Frederick County Middle',
  'Frederick County Virtual School',
  'Frederick Douglass Academy',
  'Frederick Douglass Academy Ii Secondary School',
  'Frederick Douglass Academy Iii Secondary School',
  'Frederick Douglass Academy Iv Secondary School',
  'Frederick Douglass Academy V Middle School',
  'Frederick Douglass Academy Vi High School',
  'Frederick Douglass Academy Vii High School',
  'Frederick Douglass Academy Viii Middle School',
  'Frederick Douglass El',
  'Frederick Douglass Elementary',
  'Frederick Douglass Elementary School',
  'Frederick Douglass High',
  'Frederick Douglass High School',
  'Frederick Douglass International Academy',
  'Frederick Douglass Mastery Charter Schoo',
  'Frederick Douglass Reclamation Academy',
  'Frederick Douglass School 19',
  'Frederick Elementary',
  'Frederick Elementary - 02',
  'Frederick Es',
  'Frederick Fraize High School',
  'Frederick Fraize Middle School',
  'Frederick H. Tuttle Middle School',
  'Frederick Harris',
  'Frederick High',
  'Frederick High School - 01',
  'Frederick Hs',
  'Frederick J Dutile',
  'Frederick Joyce Elementary',
  'Frederick Jr. High - 03',
  'Frederick Law Olmsted Academy North',
  'Frederick Law Olmsted Academy South',
  'Frederick Ms',
  'Frederick Nerge Elem School',
  'Frederick Olmsted #156',
  'Frederick Post Kinder Center',
  'Frederick R. Douglass Elementary',
  'Frederick School',
  'Frederick Senior High School',
  'Frederick University',
  'Frederick V Pankow Center',
  'Frederick W. Hartnett Middle School',
  'Fredericksburg El',
  'Fredericksburg El Sch',
  'Fredericksburg Elem School',
  'Fredericksburg Elementary School',
  'Fredericksburg H S',
  'Fredericksburg Middle',
  'Fredericksburg Pri',
  'Frederickson Elementary',
  'Fredericktown Elem.',
  'Fredericktown Elementary School',
  'Fredericktown High',
  'Fredericktown High School',
  'Fredericktown Intermediate',
  'Fredericktown Middle School',
  'Frederiksen Elementary',
  'Fredon Township School',
  'Fredonia Early Learning Center',
  'Fredonia El',
  'Fredonia Elementary School',
  'Fredonia High School',
  'Fredonia Jr./sr. High School',
  'Fredonia Middle School',
  'Fredric W Watson Elementary School',
  'Fredstrom Elementary School',
  'Free Home Elementary School',
  'Free Horizon Montessori Charter School',
  'Free International University of Moldova',
  'Free International University of Social Studies',
  'Free Orchards Elementary School',
  'Free University "Maria Santissima Assunta"',
  'Free University Institute "Carlo Cattaneo"',
  'Free University Stockholm',
  'Free University of Bozen',
  'Freeburg Community High Sch',
  'Freeburg Elem School',
  'Freeburg Primary Center',
  'Freedom 7 Elementary School Of International Studies',
  'Freedom Academy',
  'Freedom Academy Alc',
  'Freedom Academy Charter School',
  'Freedom Academy North',
  'Freedom Academy Of Imperial Valley',
  'Freedom Acres School',
  'Freedom Area El Sch',
  'Freedom Area Ms',
  'Freedom Area Shs',
  'Freedom Crest Elementary',
  'Freedom District Elementary',
  'Freedom El',
  'Freedom Elem School',
  'Freedom Elem.',
  'Freedom Elementary',
  'Freedom Elementary - 06',
  'Freedom Elementary School',
  'Freedom Es',
  'Freedom High',
  'Freedom High School',
  'Freedom Hill Elementary',
  'Freedom Home',
  'Freedom Hs',
  'Freedom Intermediate',
  'Freedom Middle',
  'Freedom Middle School',
  'Freedom Park Elementary',
  'Freedom Prep Charter School',
  'Freedom Preparatory Academy',
  'Freedom Preparatory Academy - Vineyard',
  'Freedom Preparatory Academy Charter Elementary School',
  'Freedom Preparatory Academy Elementary School',
  'Freedom School',
  'Freedom Shores Elementary School',
  'Freedom Trail Elementary',
  'Freehold Borough High School',
  'Freehold Intermediate School',
  'Freehold Learning Center',
  'Freehold Township High School',
  'Freeland El/ms',
  'Freeland Elementary School',
  'Freeland Learning Center',
  'Freeland Middle Schoolhigh School',
  'Freeman Davis Elementary - 03',
  'Freeman El',
  'Freeman Elem',
  'Freeman Elem School',
  'Freeman Elementary',
  'Freeman Elementary - 02',
  'Freeman Elementary School',
  'Freeman Elementary-adams',
  'Freeman High School',
  'Freeman High School - 01',
  'Freeman Jr. High - 05',
  'Freeman Middle School',
  'Freeman School',
  "Freeman's Mill Elementary School",
  'Freeman-kennedy School',
  'Freemansburg El Sch',
  'Freeport Alternative High Sch',
  'Freeport Area Ms',
  'Freeport Area Shs',
  'Freeport Elementary School',
  'Freeport High School',
  'Freeport Int',
  'Freeport Middle School',
  'Freeport Senior High School',
  'Freer H S',
  'Freer J H',
  'Freese Elementary',
  'Freetown Elementary',
  'Freetown Elementary School',
  'Freetown-lakeville Middle School',
  'Freeville Elementary School',
  'Freewater Elementary School',
  'Freewill Elementary School',
  'Freie Kunst-Studienstätte Ottersberg',
  'Freie Universität Berlin',
  'Freiheit El',
  'Freire Charter School',
  'Freire Cs',
  'Frelinghuysen Middle School',
  'Frelinghuysen Township Elementary School',
  'Fremont Academy Of Engineering And Design',
  'Fremont Academy Of Environmental Science & Innovative Design',
  'Fremont Education And Activity Center',
  'Fremont Elem School',
  'Fremont Elem.',
  'Fremont Elementary',
  'Fremont Elementary School',
  'Fremont H Teed Middle School',
  'Fremont High',
  'Fremont High School',
  'Fremont Intermediate School',
  'Fremont Jr High/middle School',
  'Fremont Junior High School',
  'Fremont Learning Center',
  'Fremont Middle School',
  'Fremont Primary',
  'Fremont Ross High School',
  'Fremont School',
  'Fremont Senior High School',
  'Fremont Stars Elementary',
  'Fremont-mills Elementary School',
  'Fremont-mills Middle And Senior High School',
  'Fremont/hay Elementary School',
  'French Camp Elem School',
  'French Camp Elementary',
  'French Creek El Sch',
  'French Creek Elementary',
  'French El',
  'French Elementary School',
  'French Ford Middle School',
  'French Gulch-whiskeytown Elementary',
  'French Institute of Management',
  'French Prairie Middle School',
  'French Road Elementary School',
  'French Run Elementary School',
  'French Settlement Elementary School',
  'French Settlement High School',
  'French University in Armenia (UFAR)',
  'French Valley Elementary',
  'Frenchboro Elementary School',
  'Frenchglen Elementary School',
  'Frenchtown 7-8',
  'Frenchtown Elementary',
  'Frenchtown Elementary School',
  'Frenchtown High School',
  'Frenchtown Intermediate School',
  'Frenchtown School',
  'Frenship H S',
  'Frenship Middle',
  'Fresh Creek School (the)',
  'Fresh Start',
  'Fresh Start Center',
  'Fresh Start Treatment & Learning',
  'Freshman Center',
  'Freshman Ctr. - G. Baker Bldg.',
  'Freshwater Charter Middle',
  'Freshwater Ed. Dist. Alc',
  'Freshwater Elementary',
  'Freshwater Elementary School',
  'Fresno City And Fresno State Preschools',
  'Fresno City College',
  'Fresno Coe District-wide Preschool',
  'Fresno County Special Education Local Plan Area',
  'Fresno Flats Community Day',
  'Fresno High',
  'Fresno Pacific University',
  'Fresno Rop',
  'Frewsburg Junior-senior High School',
  'Frey Elementary School',
  'Friars Point Elementary School',
  'Fricano Primary School',
  'Frick Middle',
  'Frida Kahlo High',
  'Friday Harbor Elementary School',
  'Friday Harbor High School',
  'Friday Harbor Middle School',
  'Fridley Middle',
  'Fridley Middle Area Learning Center',
  'Fridley Moore Lk Area Learning Ctr',
  'Fridley Senior High',
  'Friedberg Elementary',
  'Friedell Middle',
  'Friedrich Froebel Garden Of Early Learning',
  'Friedrich-Alexander Universität Erlangen-Nürnberg',
  'Friedrich-Schiller Universität Jena',
  'Friel Early Learning Center',
  'Friend Elementary School',
  'Friend High Schools',
  'Friend Public School',
  'Friendly High',
  'Friendly Hills Elementary',
  'Friendly Hills Middle',
  'Friendly House Academia Del Pueblo Elem',
  'Friends University',
  'Friendship Academy Of Engineering And Technology',
  'Friendship Acdmy Of Fine Arts Chtr.',
  'Friendship Central School',
  'Friendship El',
  'Friendship El Sch',
  'Friendship Elementary',
  'Friendship Elementary School',
  'Friendship Hill El Sch',
  'Friendship Jr High School',
  'Friendship Learning Elementary',
  'Friendship Pcs Armstrong',
  'Friendship Pcs Blow Pierce Elementary',
  'Friendship Pcs Blow Pierce Middle',
  'Friendship Pcs Chamberlain Elementary',
  'Friendship Pcs Chamberlain Middle',
  'Friendship Pcs Collegiate Academy',
  'Friendship Pcs Online',
  'Friendship Pcs Southeast Academy',
  'Friendship Pcs Technology Preparatory Academy High',
  'Friendship Pcs Technology Preparatory Academy Middle',
  'Friendship Pcs Woodridge Elementary',
  'Friendship Pcs Woodridge Middle',
  'Friendship Valley Elementary',
  'Friendship Village School',
  'Friendsville Elementary',
  'Friendswood H S',
  'Friendswood J H',
  'Fries Avenue Elementary',
  'Fries School',
  'Friess Lake Elementary',
  'Friess Lake School',
  'Frink-chambers Public School',
  'Friona El',
  'Friona H S',
  'Friona J H',
  'Friona Pri',
  'Frisbie Middle',
  'Frisbie School',
  'Frisco Elementary School',
  'Frisco H S',
  'Fritz El Sch',
  'Froebel Elem.',
  'Frog Pond Elementary School',
  'Frohardt Elem School',
  'Froid 7-8',
  'Froid Elementary School',
  'Froid High School',
  'Fromberg 7-8',
  'Fromberg High School',
  'Fromberg School',
  'Front Range Community College',
  'Front Street Elementary School',
  'Frontenac Jr. High',
  'Frontenac Sr. High School',
  'Fronteras Charter School',
  'Frontier 7-8',
  'Frontier Academy',
  'Frontier Charter Academy',
  'Frontier Charter School',
  'Frontier El',
  'Frontier Elementary',
  'Frontier Elementary School',
  'Frontier Es',
  'Frontier High',
  'Frontier High (continuation)',
  'Frontier High School',
  'Frontier Hs',
  'Frontier International Academy',
  'Frontier International Academy Elementary',
  'Frontier Jr-sr High School',
  'Frontier Middle',
  'Frontier Middle School',
  'Frontier Regional',
  'Frontier Schl Of Excellence-m',
  'Frontier Schl Of Excellence-u',
  'Frontier Schl Of Innovation-m',
  'Frontier School',
  'Frontier School Of Innovation',
  'Frontier Senior High School',
  'Frontier Stem High School',
  'Frontier Trail Middle School',
  'Frontier Valley Elementary School',
  'Frost (earl) Elementary',
  'Frost El',
  'Frost Elementary',
  'Frost Elementary School',
  'Frost H S',
  'Frost Lake Elementary School',
  'Frost Middle',
  'Frost Middle School',
  'Frost School',
  'Frostburg State University',
  'Frostick School',
  'Frostproof Elementary School',
  'Frostproof Middle/senior High',
  'Frostwood El',
  'Fruchthendler Elementary School',
  'Fruit Cove Middle School',
  'Fruit Street School',
  'Fruit Valley Elementary School',
  'Fruita 8/9 School',
  'Fruita Middle School',
  'Fruita Monument High School',
  'Fruitdale Elementary School',
  'Fruitdale High School',
  'Fruithurst Elementary School',
  'Fruitland Elementary',
  'Fruitland Elementary School',
  'Fruitland High School',
  'Fruitland Intermediate',
  'Fruitland Middle School',
  'Fruitland Park Elementary School',
  'Fruitland Preparatory Academy',
  'Fruitland Primary',
  'Fruitport Adult Education',
  'Fruitport Alternative High School',
  'Fruitport High School',
  'Fruitport Middle School',
  'Fruitvale Elementary',
  'Fruitvale Elementary School',
  'Fruitvale H S',
  'Fruitvale Junior High',
  'Fruitvale Middle',
  'Fruitville Elementary School',
  'Fry Elementary School',
  'Fry Int',
  'Fryberger Elementary',
  'Frye Elementary School',
  'Fryelands Elementary',
  'Fsus Virtual School Franchise',
  'Ft Concho El',
  'Ft Davis School',
  'Ft Lincoln Elementary School',
  'Ft Loudoun Middle School',
  'Ft Sam Houston El',
  'Ft Sanders Education Development Center',
  'Ft Vannoy Elementary School',
  'Ft Wright Elementary School',
  'Ft. Caspar Academy',
  'Ft. Washakie Elementary',
  'Ft. Washakie High School',
  'Ft. Washakie Middle School',
  'Ft. Zumwalt East High',
  'Ft. Zumwalt Hope High',
  'Ft. Zumwalt North High',
  'Ft. Zumwalt North Middle',
  'Ft. Zumwalt South High',
  'Ft. Zumwalt South Middle',
  'Ft. Zumwalt West High',
  'Ft. Zuwmalt West Middle',
  'Fu Jen Catholic University',
  'Fudan University',
  'Fuente Nueva Charter',
  'Fuerstenau Early Childhood Center',
  'Fuerte Elementary',
  'Fuguitt Elementary School',
  'Fuji University',
  "Fuji Women's College",
  'Fujian Agricultural University',
  'Fujian Medical University',
  'Fujian Normal University',
  'Fujian University of Traditional Chinese Medicine',
  'Fujita Health University',
  'Fukui Medical School',
  'Fukui Prefectural University',
  'Fukui University',
  'Fukui University of Technology',
  'Fukuoka Dental College',
  'Fukuoka Institute of Technology',
  'Fukuoka International University',
  'Fukuoka Prefectural University',
  'Fukuoka University',
  'Fukuoka University of Education',
  "Fukuoka Women's University",
  'Fukushima Medical College',
  'Fukushima University',
  'Fukuyama Heisei University',
  'Fukuyama University',
  'Fulbright Elementary School',
  'Fulda Elementary',
  'Fulda Secondary',
  'Fulford Elementary School',
  'Fulgencio Pinero Rodriguez',
  'Fulks Run Elementary',
  'Full Circle High School',
  'Full Sail University',
  'Fullbright Avenue Elementary',
  'Fuller Elem School',
  'Fuller Elementary',
  'Fuller Elementary School',
  'Fuller Meadow',
  'Fuller Middle',
  'Fuller Middle School',
  'Fuller Street Elementary School',
  'Fuller Theological Seminary',
  'Fullerton College',
  'Fullerton Elem School',
  'Fullerton Elementary',
  'Fullerton Elementary School',
  'Fullerton High School',
  'Fullerton Iv Elementary School',
  'Fullerton Magnet Center',
  'Fullerton School',
  'Fullerton Union High',
  'Fulmar Road Elementary School',
  'Fulmore M S',
  'Fulshear H S',
  'Fulton (harry C.) Middle',
  'Fulton 4-5 Learning Center',
  'Fulton Academy Of Excellence',
  'Fulton Academy Of Science And Technology',
  'Fulton Alternative Education',
  'Fulton And Alsbury Academy Of Arts And Engineering',
  'Fulton County Area Technology Center',
  'Fulton County Avts',
  'Fulton County Elementary/middle School',
  'Fulton County High School',
  'Fulton El Sch',
  'Fulton Elem School',
  'Fulton Elementary',
  'Fulton Elementary School',
  'Fulton High School',
  'Fulton Independent School',
  'Fulton Jr High School',
  'Fulton Junior High School',
  'Fulton K-8',
  'Fulton Leadership Academy',
  'Fulton Middle',
  'Fulton Middle School',
  'Fulton Reception And Diag Ctr',
  'Fulton Special Education',
  'Fulton Sr. High',
  'Fulton Treatment Ctr.',
  'Fulton-​Montgomery Community College',
  'Fultondale Elementary School',
  'Fultondale High School',
  'Fundacion Escuela Colombiana de Rehabiliación',
  'Fundación Universitaria Luis Amigó',
  'Fundación Universitaria Manuela Beltrán',
  'Fundación Universitaria San Martín',
  'Fundación Universitaria de Boyacá',
  'Fundación Universitaria del Area Andina. Sede Pereira',
  'Fundação Educacional de Ituverava',
  'Funston Elem School',
  'Funston Elementary School',
  'Fuquay-varina Elementary',
  'Fuquay-varina High',
  'Fuquay-varina Middle',
  'Furgeson (venn W.) Elementary',
  'Furley Elementary',
  'Furlow Charter School',
  'Furman (duane E.) High (independent Study Program)',
  'Furman Middle',
  'Furman Templeton Preparatory Academy',
  'Furman University',
  'Furnace Brook Middle',
  'Furnace Woods Elementary School',
  'Furneaux El',
  'Furness Horace Hs',
  'Furr H S',
  'Furry Elementary School',
  'Furture School Of Fort Smith',
  'Fushun Petroleum University',
  'Fusion Charter',
  'Futral Road Elementary School',
  'Future Bound Independent Study Secondary',
  'Future Investment Middle School',
  'Future Leaders Institute Charter School',
  'Future Public School',
  'Future University',
  'Futures',
  'Futures Elementary',
  'Futures High',
  'Futures Program',
  'Futures School',
  'Futures Unlimited',
  'Futuro Academy Elementary',
  'Futurus High School',
  'Fuzhou University',
  'Fw Rgnl Program For Deaf',
  'Fyffe High School',
  'G A R Memorial Jshs',
  'G Austin Schoenly Elementary School',
  'G C Burkhead Elementary School',
  'G D Jones Elementary',
  'G D Rogers Garden- Bullock Elementary',
  "G E 'buddy' West El",
  'G H Whitcomb El',
  'G Herbert Jewett School',
  'G L Priess Primary School',
  'G L Wiley Opportunity Center',
  'G N Chapman Elementary School',
  'G N Dieterich Elem School',
  'G O A L S Program',
  'G R Edwards Middle',
  'G R Hampton Elementary School',
  'G R Whitfield',
  'G Ray Bodley High School',
  'G Stanley Hall Elem School',
  'G Stanley Hall Elementary Sch',
  'G W Bulluck Elementary',
  'G W Carver 6th Grade Stem Learning Center',
  'G W Carver Early Childhood Center',
  'G W Carver El',
  'G W Carver Elementary',
  'G W Carver High School',
  'G W Carver Middle',
  'G W Harby J H',
  'G W Kennemer Middle',
  'G W Krieger School',
  'G W Robinson',
  'G W Trenholm Primary School',
  'G-star School Of The Arts',
  'G. C. Hawley Middle',
  'G. Frank Davidson',
  'G. Frank Russell Technology Center',
  'G. Harold Antrim Elementary School',
  'G. Harold Hunt School',
  'G. Holmes Braddock Senior High',
  'G. James Gholson Middle',
  'G. Lisby Elementary At Hillsdale',
  'G. M. Walters Junior High',
  'G. O. Bailey Primary School',
  'G. Tyree Learning School',
  'G. W. Carver Preparatory Academy',
  'G. W. Carver Primary School',
  'G. W. Hellyer Elementary',
  'G. Weaver Hipps Elementary School',
  'G.a. Treakle Elementary',
  'G.f.w. Elementary',
  'G.f.w. High School',
  'G.f.w. Middle School',
  'G.h. Reid Elementary',
  'G.l.h. Johnson Elementary',
  'G.r. Stuart Elementary',
  'G.t. Woods Elementary School',
  'G.w. Carver Elementary',
  'G.w.stout Elementary',
  'G4S ACADEMY',
  'GAARDE CHRISTIAN SCHOOL',
  'GABRIEL RICHARD CATHOLIC HIGH SCHOOL',
  'GAHANNA CHRISTIAN ACADEMY',
  'GAIA DEMOCRATIC SCHOOL',
  'GAINESVILLE COUNTY DAY SCHOOL',
  'GAITHERSBURG PRESBYTERIAN PRESCHOOL & KINDERGARTEN',
  'GALAXY RANCH PRIVATE SCHOOL',
  'GALESBURG CHRISTIAN SCHOOL ASSOC',
  'GALILEAN CHRISTIAN ACADEMY',
  'GALILEE CHRISTIAN ACADEMY',
  'GALILEE MONTESSORI',
  'GALLAWAY CHRISTIAN ACADEMY',
  'GALLUP CHRISTIAN SCHOOL',
  'GALT ADVENTIST SCHOOL',
  'GALT CHRISTIAN SCHOOL',
  'GAN ISRAEL PRESCHOOL',
  'GANN ACADEMY-THE NEW JEWISH HIGH SCHOOL',
  'GAP CHRISTIAN ACADEMY',
  'GARBENS MENNONITE SCHOOL',
  'GARCES MEMORIAL HIGH SCHOOL',
  'GARDEN ACADEMY',
  'GARDEN CITY MONTESSORI',
  'GARDEN GATE MONTESSORI SCHOOL',
  'GARDEN HOMES LUTHERAN SCHOOL',
  'GARDEN MONTESSORI SCHOOL',
  'GARDEN OF ANGELS SCHOOL',
  'GARDEN OF HOPE MONTESSORI',
  'GARDEN OF KNOWLEDGE',
  'GARDEN OF THE SAHABA ACADEMY',
  'GARDEN SPOT AMISH SCHOOL',
  'GARDENA CHRISTIAN ACADEMY & PRESCHOOL',
  'GARDENDALE CHRISTIAN ACADEMY',
  'GARDENVIEW MONTESSORI',
  'GARDERE COMMUNITY CHRISTIAN SCHOOL',
  'GARDNER PREPARATORY SCHOOL',
  'GARDNER SCHOOL',
  'GARFIELD MONTESSORI SCHOOL',
  'GARFIELD PARK ACADEMY',
  'GARLAND CHRISTIAN ACADEMY',
  'GARLAND CHRISTIAN ADVENTIST SCHOOL',
  'GARRETT MEMORIAL CHRISTIAN SCHOOL',
  'GARRETTS WAY',
  'GARVEY SCHOOL',
  'GARY K. HERBERGER YOUNG SCHOLARS ACADEMY',
  'GASTON DAY SCHOOL',
  'GATE ACADEMY',
  'GATES HILL SCHOOL',
  'GATES OF LEARNING CENTER',
  'GATEWAY ACADEMY',
  'GATEWAY ACADEMY AT THE LEARNING LAB',
  'GATEWAY CENTER',
  'GATEWAY CHRISTIAN ACADEMY',
  'GATEWAY CHRISTIAN SCHOOL',
  'GATEWAY CHRISTIAN SCHOOLS',
  'GATEWAY MONTESSORI & PRESCHOOL',
  'GATEWAY MONTESSORI SCHOOL',
  'GATEWAY PRE-K SCHOOL',
  'GATEWAY SCHOOL',
  'GATEWAY WOODS SCHOOL',
  'GATEWAY-LONGVIEW LYNDE SCHOOL',
  'GATEWOOD SCHOOLS',
  'GATOR WILDERNESS CAMP SCHOOL',
  'GCE LAB SCHOOL',
  'GEARA GROUP/EDUCATIONAL DEVELOPMENTAL CENTER',
  'GEHMANS MENNONITE SCHOOL',
  'GEIBEL CATHOLIC JUNIOR-SENIOR HIGH SCHOOL',
  'GEIS ACRES SCHOOL',
  'GELLER HOUSE SCHOOL',
  'GEM EXPRESS PRESCHOOL & KINDERGARTEN',
  'GEM SCHOOL INC (THE)',
  'GEM STATE ADVENTIST ACADEMY',
  'GENERAL ASSEMBLY ACADEMY',
  'GENERATION OF HOPE ACADEMY',
  'GENERATION OF HOPE ACADEMY INC 2',
  'GENERATIONS OF LEARNING',
  'GENESEE ACADEMY',
  'GENESEE CHRISTIAN SCHOOL',
  'GENESEE LAKE SCHOOL',
  'GENESIS ACADEMY',
  'GENESIS ACADEMY, INC.',
  'GENESIS CHRISTIAN ACADEMY',
  'GENESIS CHRISTIAN DAY SCHOOL',
  'GENESIS CHRISTIAN SCHOOL',
  'GENESIS CLASSICAL ACADEMY',
  'GENESIS EDUCATIONAL CENTER',
  'GENESIS ONE CHRISTIAN SCHOOL',
  'GENESIS PREPARATORY ACADEMY',
  'GENESIS PREPARATORY SCHOOL',
  'GENESIS SCHOOL',
  'GENEVA ACADEMY',
  'GENEVA CHRISTIAN SCHOOL',
  'GENEVA CLASSICAL ACADEMY',
  'GENEVA DAY SCHOOL',
  'GENEVA SCHOOL OF BOERNE',
  'GENGRAS CENTER/UNIV OF ST JOSEPH',
  'GENOA CHRISTIAN ACADEMY',
  'GENTRY ACADEMY',
  'GENZA BOTTOM',
  'GEOGE CROTHERS MEMORIAL SCHOOL',
  'GEORGE E PETERS ADVENTIST SCHOOL',
  'GEORGE JACKSON ACADEMY',
  'GEORGE MCCANN MEMORIAL CATHOLIC SCHOOL',
  'GEORGE N HUNT CAMPUS SCHOOL',
  'GEORGE SCHOOL',
  'GEORGE STEVENS ACADEMY',
  'GEORGE STONE ELEMENTARY',
  'GEORGE THOMAS JR ACADEMY',
  'GEORGE THOMAS JR ACADEMY AT EDEN',
  'GEORGE WALTON ACADEMY',
  'GEORGETOWN HILL EARLY CHILDHOOD SCHOOL AT NRC',
  'GEORGETOWN HILL EARLY SCHOOL',
  'GEORGETOWN PREPARATORY SCHOOL',
  'GEORGETOWN VISITATION PREPARATORY SCHOOL',
  'GEORGIA CHRISTIAN SCHOOL',
  'GEORGIA ROAD',
  'GEORGIA-CUMBERLAND ACADEMY',
  'GEORGIANA BRUCE KIRBY PREPARATORY SCHOOL',
  'GERARD PREPATORY SCHOOL',
  'GERMAN INTERNATIONAL SCHOOL',
  'GERMAN INTERNATIONAL SCHOOL BOSTON',
  'GERMAN INTERNATIONAL SCHOOL CHICAGO',
  'GERMAN INTERNATIONAL SCHOOL NEW YORK',
  'GERMAN INTERNATIONAL SCHOOL WASHINGTON DC',
  'GERMANTOWN ACADEMY',
  'GERMANTOWN CHRISTIAN SCHOOL',
  'GERRARD BERMAN DAY SCHOOL',
  'GERSH ACADEMY',
  'GERSH ACADEMY- INC',
  'GERSTELL ACADEMY',
  'GERTRUGE B NIELSEN CHILD CARE & LEARNING CENTER',
  'GESHER JEWISH DAY SCHOOL',
  'GESHER YEHUDA',
  'GESU CATHOLIC SCHOOL',
  'GESU ELEMENTARY SCHOOL',
  'GESU SCHOOL',
  'GETHSEMANE BAPTIST CHRISTIAN SCHOOL',
  'GETHSEMANE CHRISTIAN ACADEMY',
  'GETHSEMANE CHRISTIAN SDA JUNIOR ACADEMY',
  'GETHSEMANE LUTHERAN SCHOOL',
  'GFBC EDUCATION PROGRAMS/DAY SCHOOL',
  'GFS',
  'GHENT MONTESSORI SCHOOL',
  'GHS ACADEMY- GOLDEN HILLS SCHOOL',
  'GIANT STEPS CHILDRENS CENTER',
  'GIANT STEPS CONNECTICUT',
  'GIANT STEPS ILLINOIS',
  'GIBAULT',
  'GIBAULT CATHOLIC',
  'GIBBONS SCHOOL',
  'GIDEON HAUSNER JEWISH DAY SCHOOL',
  'GIFT OF KIDS INC. THE',
  'GIFTED CHILDREN',
  'GIFTED CHILDREN PRESCHOOL AND KINDERGARTEN',
  'GIG HARBOR ACADEMY',
  'GIGGLE AND GROW PRESCHOOL & EARLY LEARNING CENTER',
  'GIL-BLU NURSERY& KINDERGARTEN',
  'GILEAD CHRISTIAN SCHOOL',
  'GILL ST BERNARD S SCHOOL',
  'GILLEN BREWER SCHOOL',
  'GILLIS CENTER SCHOOL',
  'GILMAN SCHOOL',
  'GILMORE MEMORIAL CHRIS ACD',
  'GILMOUR ACADEMY',
  'GINA CUMBIE',
  'GINDI MAIMONIDES ACADEMY',
  'GINGERBREAD ACADEMY',
  'GINGERBREAD NURSERY SCHOOL',
  'GIRARD COLLEGE',
  'GIRLS IN FOCUS ACADEMY',
  'GIRLS PREPARATORY SCHOOL',
  "GIRLS' SCHOOL OF AUSTIN",
  'GIVING TREE MONTESSORI',
  'GIVING TREE MONTESSORI SCHOOL',
  'GLA ACADEMY SCHOOL OF EXCELLENCE',
  'GLACIER LAKE SCHOOL',
  'GLAD TIDINGS ACADEMY',
  'GLAD TIDINGS CHRISTIAN ACADEMY & CHILD DEVELOPMENT',
  'GLAD TIDINGS LEARNING CENTER AND ACADAMEY EAST',
  'GLADE CHRISTIAN ACADEMY',
  'GLADES CHRISTIAN ACADEMY',
  'GLADES DAY SCHOOL',
  'GLADEVIEW CHRISTIAN SCHOOL',
  'GLCAC CHILD CARE CENTER',
  'GLEN OAKS SCHOOL',
  'GLEN ROCK SCHOOL',
  'GLEN URQUHART SCHOOL',
  'GLENBROOK SCHOOL',
  'GLENDALE ADVENTIST ACADEMY',
  'GLENDALE CHRISTIAN SCHOOL',
  'GLENDALE LUTHERAN SCHOOL',
  'GLENDALE PRESCHOOL & KINDERGARTEN',
  'GLENELG COUNTRY SCHOOL',
  'GLENFOREST SCHOOL',
  'GLENN STRATTON LEARNING CENTER',
  'GLENOAKS CHRISTIAN ELEMENTARY',
  'GLENVIEW ACADEMY',
  'GLENVIEW ADVENTIST ACADEMY',
  'GLENVIEW NEW CHURCH SCHOOL',
  'GLENWOOD ACADEMY',
  'GLENWOOD CHRISTIAN ACADEMY',
  'GLENWOOD CHRISTIAN SCHOOL',
  'GLENWOOD COUNTRY DAY SCHOOL',
  'GLENWOOD SCHOOL',
  'GLIS',
  'GLOBAL CHILDREN MONTESSORI SCHOOL',
  'GLOBAL LEARNING ACADEMIES',
  'GLOBAL MONTESSORI ACADEMY',
  'GLOBAL VILLAGE PROJECT',
  'GLORIA DEI LUTHERAN ACADEMY',
  'GLORIA DEI LUTHERAN ELEMENTARY SCHOOL',
  'GLORIA DEI LUTHERAN SCHOOL',
  'GLORIA DEI MONTESSORI SCHOOL',
  'GLORIA S FRIEND CHRISTIAN ACADEMY',
  'GLORY ACADEMY',
  'GLORY CHRISTIAN SCHOOL',
  'GLOUCESTER CATHOLIC HIGH SCHOOL',
  'GLOUCESTER COUNTY CHRISTIAN SCHOOL',
  'GLOUCESTER MONTESSORI SCHOOL',
  'GLOVER HILL RD AMISH SCHOOL',
  'GMI Engineering and Management Institute',
  'GO LIKE THE WIND MONTESSORI SCHOOL',
  'GOBLES JR ACADEMY',
  "GOD'S HERITAGE CHRISTIAN ACADEMY",
  'GODDARD SCHOOL',
  'GODDARD SCHOOL FOR EARLY CHILDHOOD',
  'GODDARD SCHOOL FOR EARLY CHILDHOOD DEVELOPMENT',
  'GODDARD SCHOOL IN GAITHERSBURG',
  'GODDARD SCHOOL OF CHERRY HILL',
  'GODDARD SCHOOL OF MOORESTOWN',
  'GODDARD SCHOOL OF MOORESVILLE',
  'GODDARD SCHOOL OF OLD BRIDGE II',
  'GODDARD SCHOOL-ASHBURN',
  'GODDARD SCHOOL-HILLSBOROUGH',
  'GODDARD SCHOOL-LEESBURG',
  'GODDARD SCHOOL-RIVERS BEND',
  'GODS HERITAGE CHRISTIAN ACADEMY',
  'GODS HOUSE KINDERGARTEN & DAYCARE',
  'GODS LITTLE CHERUBS ELEMENTARY SCHOOL',
  'GOLD COAST CHRISTIAN SCHOOL',
  'GOLD MATERIAL',
  'GOLDA OCH ACADEMY',
  'GOLDEN BRANCH ACADEMY',
  'GOLDEN BRIDGE ACADEMY',
  'GOLDEN BRIDGES SCHOOL',
  'GOLDEN HARVEST MONTESSORI SCHOOL',
  'GOLDEN HEART CHRISTIAN SCHOOL',
  'GOLDEN HILLS CHRISTIAN SCHOOL',
  'GOLDEN MOUNTAIN ACADEMY',
  'GOLDEN POND SCHOOL',
  'GOLDEN RULE ACADEMY',
  'GOLDEN STATE BAPTIST SCHOOLS',
  'GOLDEN WINGS ACADEMY INC',
  'GOLDEN YEARS MONTESSORI',
  'GOLDENDALE SDA SCHOOL',
  'GOMPERS PRIVATE SCHOOL',
  'GONZAGA COLLEGE HIGH SCHOOL',
  'GONZAGA PREPARATORY SCHOOL',
  'GOOD BEGINNINGS KINDERGARTEN',
  'GOOD DAY PRESCHOOL & KINDERGARTEN',
  'GOOD EARTH MONTESSORI SCHOOL',
  'GOOD SAMARITAN CHRISTIAN ACADEMY',
  'GOOD SAMARITAN DAY SCHOOL',
  'GOOD SHEPHERD',
  'GOOD SHEPHERD ACADEMY',
  'GOOD SHEPHERD CATHOLIC ACADEMY',
  'GOOD SHEPHERD CATHOLIC REGIONAL SCHOOL',
  'GOOD SHEPHERD CATHOLIC SCHOOL',
  'GOOD SHEPHERD CHRISTIAN ACADEMY',
  'GOOD SHEPHERD CHRISTIAN SCHOOL',
  'GOOD SHEPHERD DAY SCHOOL',
  'GOOD SHEPHERD EARLY CHILDHOOD',
  'GOOD SHEPHERD ELEMENTARY SCHOOL',
  'GOOD SHEPHERD EPISCOPAL SCHOOL',
  'GOOD SHEPHERD EVANGELICAL LUTHERAN SCHOOL',
  'GOOD SHEPHERD LUTHERAN',
  'GOOD SHEPHERD LUTHERAN SCHOOL',
  'GOOD SHEPHERD MONTESSORI',
  'GOOD SHEPHERD MONTESSORI SCHOOL',
  'GOOD SHEPHERD REGIONAL SCHOOL',
  'GOOD SHEPHERD SCHOOL',
  'GOOD SHEPHERD SCHOOL & DAYCARE-2',
  'GOOD SPIRIT DEVELOPMENT CENTER',
  'GOOD TIDINGS ACADEMY',
  'GOOD TREE ACADEMY',
  'GOODLAND ACADEMY',
  'GOODTIME CHINESE SCHOOL',
  'GOODWILL MENNONITE SCHOOL',
  'GOOSE CREEK',
  'GORDON SCHOOL',
  'GORMAN CHRISTIAN ACADEMY & EARLY LEARNING CENTER',
  'GOSPEL ARENA CHRISTIAN ACADEMY',
  'GOSPEL BAPTIST CHRISTIAN SCHOOL',
  'GOSPEL HAVEN ACADEMY',
  'GOSPEL LIGHT BAPTIST ACADEMY',
  'GOSPEL LIGHT CHRISTIAN SCHOOL',
  'GOSPEL LIGHT SCHOOL',
  'GOSPEL MENNONITE SCHOOL',
  'GOSPEL OF GRACE',
  'GOSPEL OUTREACH CHRISTIAN SCHOOL',
  'GOSPEL OUTREACH SCHOOL',
  'GOSPEL TABERNACLE CHRISTIAN ACADEMY',
  'GOTTESMAN RTW ACADEMY',
  'GOUDEAU ACCELERATED PREPARATORY ACADEMY',
  'GOULD ACADEMY',
  'GOVANS PRESBYTERIAN PRESCHOOL',
  'GOVERNMENT STREET CHRISTIAN SCHOOL',
  'GOVERNOR FRENCH ACADEMY',
  'GR WOONSOCKET CATHOLIC REGIONAL',
  'GRACE ACADEMY',
  'GRACE ACADEMY HARTFORD',
  'GRACE ACADEMY OF DALLAS',
  'GRACE AND FAITH INTERNATIONAL ACADEMY',
  'GRACE AND GLORY CHRISTIAN SCHOOL',
  'GRACE ASSEMBLY LEARNING CENTER',
  'GRACE BAPTIST ACADEMY',
  'GRACE BAPTIST CHRISTIAN ACADEMY',
  'GRACE BAPTIST CHRISTIAN SCHOOL',
  'GRACE BAPTIST CHURCH/SCHOOL',
  'GRACE BAPTIST PRESCHOOL AND ACADEMY',
  'GRACE BAPTIST SCHOOL',
  'GRACE BIBLE ACADEMY',
  'GRACE BIBLE CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'GRACE BISHOP SCHOOL',
  'GRACE BOYS RANCH',
  'GRACE BRETHREN CHRISTIAN SCHOOL',
  'GRACE BRETHREN ELEMENTARY',
  'GRACE BRETHREN JR SR HIGH SCHOOL',
  'GRACE CASCADE CHRISTIAN SCHOOLS',
  'GRACE CHAPEL LUTHERAN SCHOOL',
  'GRACE CHILD DEVELOPMENT CENTER',
  "GRACE CHILDREN'S LEARNING CENTER",
  'GRACE CHRISTIAN ACADEMNY',
  'GRACE CHRISTIAN ACADEMY',
  'GRACE CHRISTIAN ACADEMY INC',
  'GRACE CHRISTIAN ACADEMY OF MARYLAND',
  'GRACE CHRISTIAN CENTER',
  'GRACE CHRISTIAN PRESCHOOL',
  'GRACE CHRISTIAN SCHOOL',
  'GRACE CHRISTIAN SCHOOL (CHANGED THE NAME 6/15)',
  'GRACE CHRISTIAN SCHOOL - ELEMENTARY/MIDDLE',
  'GRACE CHRISTIAN SCHOOL OCALA, FL INC',
  'GRACE CHRISTIAN SCHOOL OF PASCO',
  'GRACE CHRISTIAN SCHOOLS',
  'GRACE CHURCH SCHOOL',
  'GRACE CLASSICAL ACADEMY',
  'GRACE CLASSICAL CHRISTIAN ACADEMY',
  'GRACE COMMUNITY DAYCARE & SCHOOL',
  'GRACE COMMUNITY SCHOOL',
  'GRACE COMMUNITY SCHOOL - SKYLINE BLVD CAMPUS',
  'GRACE COVENANT ACADEMY',
  'GRACE COVENANT BAPTIST ACADEMY',
  'GRACE CROSSING ACADEMY',
  'GRACE ENGLISH LUTHERAN SCHOOL',
  'GRACE EPISCOPAL CHURCH KINDERGARTEN',
  'GRACE EPISCOPAL DAY SCHOOL',
  'GRACE EPISCOPAL SCHOOL',
  'GRACE EVANGELICAL LUTHERAN SCHOOL',
  'GRACE FELLOWSHIP ACADEMY',
  'GRACE GARDEN PRESCHOOL',
  'GRACE HARBOR SCHOOL',
  'GRACE LUTHERAN',
  'GRACE LUTHERAN ACADEMY',
  'GRACE LUTHERAN CHRISTIAN SCHOOL',
  'GRACE LUTHERAN KINDERGARTEN',
  'GRACE LUTHERAN PRESCHOOL & KINDERGARTEN',
  'GRACE LUTHERAN PRESCHOOL AND KINDERGARTEN',
  'GRACE LUTHERAN SCHOOL',
  'GRACE LUTHERAN SCHOOL & PRESCHOOL',
  'GRACE MENNONITE SCHOOL',
  'GRACE MONTESSORI ACADEMY',
  'GRACE MONTESSORI SCHOOL',
  'GRACE POINT ACADEMY',
  'GRACE PREPARATORY ACADEMY',
  'GRACE PREPARATORY SCHOOL',
  'GRACE SCHOOL',
  'GRACE TEMPLE CHRISTIAN ACADEMY',
  'GRACE UNITED METHODIST PRESCHOOL',
  'GRACE-ST LUKES EPISCOPAL SCHOOL',
  'GRACEPOINT SCHOOL',
  'GRACEPOINTE ACADEMY',
  'GRACEPOINTE CHRISTIAN ACADEMY',
  'GRACEWAY ACADEMY',
  'GRAFTON',
  'GRAFTON INTEGRATED HEALTH NETWORK - BERRYVILLE',
  'GRAFTON-ELM STREET CAMPUS',
  'GRAHAM ACADEMY',
  'GRAHAM LAKE SCHOOL',
  'GRAMERCY CHRISTIAN SCHOOL',
  'GRANADA PARK UMC NURSERY SCHOOL AND KINDERGARTEN',
  'GRANADA PRESBYTERIAN DAY SCHOOL',
  'GRAND BLANC MONTESSORI',
  'GRAND HAVEN CHRISTIAN SCHOOL',
  'GRAND ISLAND CHRISTIAN SCHOOL',
  'GRAND PRAIRIE EVANGELICAL METHODIST SCHOOL',
  'GRAND RIVER ACADEMY',
  'GRAND RIVER VALLEY',
  'GRAND VALLEY MENNONITE SCHOOL',
  'GRAND VIEW HOSP CHILDRENS CENTER',
  'GRANDE RONDE ACADEMY',
  'GRANDVIEW ADVENTIST JR ACADEMY',
  'GRANDVIEW CHRISTIAN SCHOOL',
  'GRANDVIEW PREPARATORY SCHOOL',
  'GRANDVILLE CALVIN CHRISTIAN SCHOOLS',
  'GRANGER CHRISTIAN SCHOOL',
  'GRANITE ACADEMY',
  'GRANITE BAPTIST SCHOOL',
  'GRANITE BAY MONTESSORI',
  'GRANITE HILL SCHOOL',
  'GRANITE START EARLY LEARNING CENTER',
  'GRANT CHRISTIAN SCHOOL',
  'GRANT ROAD DAY CARE & KINDERGARTIN',
  'GRANTS PASS SEVENTH-DAY ADVENTIST SCHOOL',
  'GRANVILLE CHRISTIAN ACADEMY',
  'GRANVILLE LUTHERAN SCHOOL',
  'GRAPEVILLE CHRISTIAN SCHOOL',
  'GRAPEVINE FAITH CHRISTIAN SCHOOL',
  'GRAPEVINE SCHOOL',
  'GRASSROOTS FREE SCHOOL',
  'GRATIENY DAY CARE CENTER, CORPORATION',
  'GRAVEL RUN MENNONITE SCHOOL',
  'GRAVELTON PAROCHIAL SCHOOL',
  'GRAVETTE HOLINESS BIBLE SCHOOL',
  'GRAY ROAD CHRISTIAN SCHOOL',
  'GRAYS HARBOR ADVENTIST CHRISTIAN SCHOOL',
  'GRAYSON CHRISTIAN SCHOOL',
  'GREAT ACCOMPLISHMENTS ACADEMY',
  'GREAT BARRINGTON RUDOLF STEINER SCHOOL',
  'GREAT BEGINNINGS',
  'GREAT BEGINNINGS CHRISTIAN KINDERGARTEN',
  'GREAT BEGINNINGS FOR LITTLE KIDS',
  'GREAT BEGINNINGS LEARNING SCHOOL',
  'GREAT BEGINNINGS MONTESSORI SCHOOL',
  'GREAT BEND SDA SCHOOL',
  'GREAT BRIDGE CHRISTIAN ACADEMY',
  'GREAT BRIDGE PRESBYTERIAN PRESCHOOL',
  'GREAT BRIDGE PRESCHOOL AND KINDERGARTEN',
  "GREAT CIRCLE EDGEWOOD CHILDREN'S CENTER",
  'GREAT CIRCLE-TOM BUTTERFIELD CAMPUS',
  'GREAT COMMISSION CHRISTIAN ACADEMY',
  'GREAT COMMISSION SCHOOLS',
  'GREAT FALLS CENTRAL CATHOLIC HIGH SCHOOL',
  'GREAT FOUNDATIONS MONTESSORI',
  'GREAT FOUNDATIONS MONTESSORI SCHOOL',
  'GREAT HOPE BAPTIST SCHOOL',
  'GREAT LAKES ACADEMY',
  'GREAT LAKES ADVENTIST ACADEMY',
  'GREAT LAKES MENNONITE SCHOOL',
  'GREAT LAKES MONTESSORI',
  'GREAT LEAPS ACADEMY',
  'GREAT OAKS ELEMENTARY SCHOOL',
  'GREAT PLAINS LUTHERAN HIGH SCHOOL',
  'GREAT REVELATIONS ACADEMY',
  'GREAT RIVER CHRISTIAN SCHOOL',
  'GREAT STRIDES REHABILITATION',
  'GREAT TOMORROWS USA',
  'GREATER ATLANTA ADVENTIST ACADEMY',
  'GREATER ATLANTA CHRISTIAN SCHOOL',
  'GREATER BATON ROUGE HOPE ACADEMY',
  'GREATER BECKLEY CHRISTIAN SCHOOL',
  'GREATER BOSTON ACADEMY',
  'GREATER CALVARY ACADEMY',
  'GREATER FAITH ACADEMY',
  'GREATER FAITH BAPTIST CHURCH CHILD DEVT CENTER',
  'GREATER FAYETTEVILLE ADVENTIST',
  'GREATER GRACE CHRISTIAN ACADEMY',
  'GREATER HOLY TEMPLE CHRISTIAN ACADEMY',
  'GREATER HOULTON CHRISTIAN ACADEMY',
  'GREATER LANSING ADVENTIST SCHOOL',
  'GREATER MANCHESTER YMCA CHILD DEVELOPMENT CENTER',
  'GREATER MEMPHIS ADVENTIST ACADEMY',
  'GREATER NEW ORLEANS CHRISTIAN ACADEMY',
  'GREATER NEW YORK ACADEMY',
  'GREATER PORTLAND CHRISTIAN SCHOOL',
  'GREATER QUINCY CHILD CARE CENTER',
  'GREATER THINGS CHRISTIAN SCHOOL',
  'GREATER TRINITY ACADEMY',
  'GREATER VISION ACADEMY',
  'GREATER VISIONS CHILDRENS CENTER',
  'GREATER YOUTH CHRISTIAN ACADEMY',
  'GREATSTART EARLY LEARNING',
  'GREECE CHRISTIAN SCHOOL',
  'GREECE MONTESSORI SCHOOL',
  'GREEK AMERICAN INSTITUTE OF NEW YORK',
  'GREEN ACRE AMISH SCHOOL',
  'GREEN ACRES',
  'GREEN ACRES SCHOOL',
  'GREEN BAY ADVENTIST JUNIOR ACADEMY',
  'GREEN BAY AREA CATHOLIC EDU. INC GRACE',
  "GREEN BAY MONTESSORI CHILDREN'S WORLD",
  'GREEN BAY TRINITY LUTHERAN SCHOOL',
  'GREEN BROOK ACADEMY',
  "GREEN CHILDREN'S HOUSE",
  'GREEN CHIMNEYS SCHOOL',
  'GREEN CHINESE SCHOOL',
  'GREEN FIELDS SCHOOL',
  'GREEN GATE SCHOOL',
  'GREEN GROVE SCHOOL',
  'GREEN HEDGES SCHOOL',
  'GREEN HILL',
  'GREEN HILLS LEARNING CENTER',
  'GREEN HOUSE MONTESSORI SCHOOL',
  'GREEN HOUSE PRESCHOOL & KINDERGARTEN',
  'GREEN LANE SCHOOL',
  'GREEN MEADOW',
  'GREEN MEADOW AMISH SCHOOL',
  'GREEN MEADOW PAROCHIAL SCHOOL',
  'GREEN MEADOW SCHOOL',
  'GREEN MEADOW WALDORF SCHOOL',
  'GREEN MEADOW-HILLSIDE',
  'GREEN MOUNTAIN VALLEY SCHOOL',
  'GREEN OAKS SCHOOL',
  'GREEN PARK LUTHERAN SCHOOL',
  'GREEN PASTURES CHRISTIAN SCHOOL',
  'GREEN RIDGE SCHOOL',
  'GREEN RIVER CHRISTIAN ACADEMY',
  'GREEN RIVER MONTESSORI',
  'GREEN SCHOOL OF HOLLYWOOD',
  'GREEN TWIG SCHOOL',
  'GREEN VALLEY',
  'GREEN VALLEY CHRISTIAN SCHOOL',
  'GREEN VALLEY LUTHERAN KINDERGARTEN',
  'GREEN VALLEY METHODIST PRESCHOOL & KINDERGARTEN',
  'GREEN VALLEY MONTESSORI SCHOOL',
  'GREEN VALLEY SCHOOL',
  'GREENACRES CHRISTIAN ACADEMY',
  'GREENBRIER ACADEMY',
  'GREENBRIER CHRISTIAN ACADEMY',
  'GREENBRIER EPISCOPAL SCHOOL',
  'GREENBROOK BAPTIST CHURCH KINDERGARTEN',
  'GREENBROOK MONTESSORI SCHOOL',
  'GREENDALE BAPTIST ACADEMY',
  'GREENE CO. YOUTH ACADEMY',
  'GREENE COUNTY CHRISTIAN SCHOOL',
  'GREENE GABLES SCHOOL',
  'GREENE HILL SCHOOL',
  'GREENE STREET FRIENDS SCHOOL',
  'GREENE TOWNE SCHOOL',
  'GREENE VALLEY CHRISTIAN ACADEMY',
  'GREENEVILLE ADVENTIST ACADEMY',
  'GREENFIELD CENTER SCHOOL',
  'GREENFIELD SCHOOL',
  'GREENFIELDS ACADEMY',
  'GREENFOREST MCCALEP CHRISTIAN ACADEMY',
  'GREENGATE SCHOOL',
  'GREENHILL SCHOOL',
  'GREENHOUSE MONTESSORI SCHOOL',
  'GREENLAND AMISH SCHOOL',
  'GREENLAND SCHOOL',
  'GREENLEAF FRIENDS ACADEMY',
  'GREENSBORO DAY SCHOOL',
  'GREENSBORO MONTESSORI SCHOOL',
  'GREENSBURG CENTRAL CATHOLIC HIGH SCHOOL',
  'GREENSPRING MONTESSORI SCHOOL',
  'GREENTREES COUNTRY DAY SCHOOL',
  'GREENVILLE CHRISTIAN ACADEMY',
  'GREENVILLE CHRISTIAN SCHOOL',
  'GREENVILLE CLASSICAL ACADEMY',
  'GREENVILLE MONTESSORI SCHOOL',
  'GREENVILLE SCHOOL',
  'GREENWAYS ACADEMY',
  'GREENWICH ACADEMY',
  'GREENWICH CATHOLIC SCHOOL',
  'GREENWICH COUNTRY DAY',
  'GREENWOOD CHRISTIAN ACADEMY',
  'GREENWOOD CHRISTIAN SCHOOL',
  'GREENWOOD FRIENDS SCHOOL',
  'GREENWOOD LABORATORY SCHOOL',
  'GREENWOOD MENNONITE SCHOOL',
  "GREENWOOD MONTESSORI CHILDREN'S HOUSE",
  'GREENWOOD MONTESSORI SCHOOL',
  'GREENWOOD PRESCHOOL',
  'GREENWOOD SCHOOL',
  'GREENWOOD SCHOOL, INC.',
  'GREGORY THE GREAT ACADEMY',
  'GREY NUN ACADEMY',
  'GREYFRIARS CLASSICAL ACADEMY',
  'GREYSTONE HOUSE',
  'GREYSTONE HOUSE MONTESSORI',
  'GREYSTONE HOUSE MONTESSORI SCHOOL',
  'GRIER SCHOOL',
  'GRIFFIN ACADEMY COOPERATIVE',
  'GRIFFIN CHRISTIAN HIGH SCHOOL',
  'GRIGGS INTERNATIONAL ACADEMY',
  'GROFFDALE SCHOOL',
  'GROSS CATHOLIC HIGH SCHOOL',
  'GROSS SCHECHTER DAY SCHOOL',
  'GROTON COMMUNITY SCHOOL',
  'GROVE CHRISTIAN SCHOOL',
  'GROVE CITY CHRISTIAN ACADEMY',
  'GROVE CITY CHRISTIAN SCHOOL',
  'GROVE SCHOOL',
  'GROVE SCHOOL OF CARY',
  'GROVES ACADEMY',
  'GROW ACADEMY',
  'GROWING CIRCLE MONTESSORI',
  'GROWING CONCERN SCHOOL',
  'GROWING DAYS CHILDCARE AND LEARNING CENTER',
  'GROWING GARDEN ACADEMY',
  'GROWING GARDEN PRESCHOOL & KINDERGARTEN',
  'GROWING LIGHT MONTESSORI SCHOOL',
  'GROWING MINDS CENTER',
  'GROWING MINDS CHRISTIAN ACADEMY',
  'GROWING MINDS TEACHING CENTER',
  'GROWING PLACE',
  'GROWING PLACE CHILDCARE & PRESCHOOL',
  'GROWING TIME MONTESSORI SCHOOL',
  'GROWING TOGETHER ACADEMY',
  'GROWING TREASURES LEARNING CENTER #2',
  'GROWING TREE ACADEMY',
  'GRYMES MEMORIAL SCHOOL',
  'GUADALUPE MONTESSORI SCHOOL',
  'GUADALUPE REGIONAL MIDDLE SCHOOL',
  'GUARDIAN ANGEL ORTHODOX DAY SCHOOL',
  'GUARDIAN ANGEL-ST BONIFACE SCHOOL',
  'GUARDIAN ANGELS',
  'GUARDIAN ANGELS CATHOLIC SCHOOL',
  'GUARDIAN ANGELS CENTRAL CATHOLIC HIGH SCHOOL',
  'GUARDIAN ANGELS ELEMENTARY SCHOOL',
  'GUARDIAN ANGELS REGIONAL SCHOOL',
  'GUARDIAN ANGELS SCHOOL',
  'GUARDIAN CATHOLIC SCHOOL',
  'GUARDIAN CHRISTIAN ACADEMY',
  'GUARDIAN LUTHERAN SCHOOL',
  'GUIDANCE ACADEMY',
  'GUIDANCE EDUCATION ACADEMY',
  'GUIDEPOST MONTESSORI FOOTHILL RANCH',
  'GUIDING HANDS SCHOOL, INC.',
  'GUIDING SHEPHERD CHRISTIAN SCHOOL',
  'GULF COAST SCHOOL FOR AUTISM',
  'GULF OAKS THERAPEUTIC DAY SCHOOL',
  'GULF STREAM SCHOOL',
  'GULFCOAST CHRISTIAN ACADEMY',
  'GULFCOAST SDA ELEMENTARY SCHOOL',
  'GULLIVER ACADEMY-MARIAN C. KRUTULIS CAMPUS',
  'GULLIVER PREPARATORY SCHOOL',
  'GULLIVER PREPARATORY SCHOOL-MILLER DRIVE CAMPUS',
  'GUNNERY ROAD CHRISTIAN ACADEMY',
  'GUSTAVUS ADOLPHUS LEARNING CENTER',
  'GUTHRIE CHRISTIAN DAY',
  'GWYNEDD FRIENDS SCHOOL',
  'GWYNEDD MERCY ACADEMY EL DIVISION',
  'GWYNEDD MERCY ACADEMY HIGH SCHOOL',
  'GYPSY SPRINGS',
  'Gabbs Elementary School',
  'Gabbs High School',
  'Gabbs Middle School',
  'Gabe P Allen Charter School',
  'Gabilan Elementary',
  'Gabilan Hills',
  'Gabino Soto',
  'Gables Elementary School',
  'Gaborone Universal College of Law',
  'Gabriela Mistral',
  'Gabriela Mistral Elementary',
  'Gabrielino High',
  'Gabriella Charter',
  'Gabriella Charter 2',
  'Gackle-streeter Elementary School',
  'Gackle-streeter High School',
  'Gadsden Admin Complex Prek Center',
  'Gadsden Adult & Community Education Center',
  'Gadsden Central Academy',
  'Gadsden City Alternative School',
  'Gadsden City High School',
  'Gadsden County High School',
  'Gadsden Elementary',
  'Gadsden Elementary Magnet School',
  'Gadsden Elementary School',
  'Gadsden High',
  'Gadsden Middle',
  'Gadsden Middle School',
  'Gadsden State Community College',
  'Gadsden Technical Institute',
  'Gadsden Virtual Franchise',
  'Gadsden Virtual Instruction Program',
  'Gaenslen Elementary',
  'Gaffney Elementary School',
  'Gaffney High',
  'Gaffney Lane Elementary School',
  'Gaffney Middle',
  'Gage Elementary',
  'Gage Park High School',
  'Gages Lake School',
  'Gahanna East Middle School',
  'Gahanna South Middle School',
  'Gahanna West Middle School',
  'Gahanna- Jefferson Clark Hall',
  'Gahr (richard) High',
  'Gaines Alternative At Hamblen',
  'Gaines Elementary School',
  'Gainesboro Elementary',
  'Gainesville Elem.',
  'Gainesville Exploration Academy',
  'Gainesville H S',
  'Gainesville High',
  'Gainesville High School',
  'Gainesville J H',
  'Gainesville Middle',
  'Gainesville Middle School',
  'Gainesville Regional Youth Detention Center',
  'Gainfield Elementary School',
  'Gaiser Middle School',
  'Gaither High School',
  'Gaithersburg Elementary',
  'Gaithersburg High',
  'Gaithersburg Middle',
  'Gakushuin University',
  'Galapagos Rockford Charter Sch',
  'Galata School',
  'Galatas El',
  'Galatasaray University',
  'Galatia Elem School',
  'Galatia Grade School Annex',
  'Galatia High School',
  'Galatia Jr High',
  'Galax Elementary',
  'Galax High',
  'Galax Middle',
  'Galaxy Elementary School',
  'Galaxy Middle School',
  'Galbreath-marshall Building',
  'Gale Elem Community Academy',
  'Gale Elementary School',
  'Gale Pond Alamo El',
  'Gale Ranch Middle',
  'Gale School',
  'Gale-bailey Elementary School',
  'Galen College of Nursing-​Louisville',
  'Galena Elementary School',
  'Galena Elementary School (k-6)',
  'Galena High',
  'Galena High School',
  'Galena Interior Learning Academy (gila)',
  'Galena Middle School',
  'Galena Park El',
  'Galena Park H S',
  'Galena Park Middle',
  'Galena Primary School',
  'Galena-abesville Elem.',
  'Gales Ferry School',
  'Galesburg High School',
  'Galesburg Middle School',
  'Galesburgaugusta Alternative Education',
  'Galesburgaugusta High School',
  'Galesburgaugusta Middle School',
  'Galesburgaugusta Primary School',
  'Galesville Elementary',
  'Galesville-ettrick-trempealeau High',
  'Galesville-ettrick-trempealeau Middle',
  'Galeton Area Sch',
  'Galeton Elementary School',
  'Galewood Elementary',
  'Galileo Academy 101',
  'Galileo Elem Math & Sci Schol Acd',
  'Galileo High',
  'Galileo Magnet High',
  'Galileo Magnet School',
  'Galileo School For Gifted Learning',
  'Galileo School Of Math And Science',
  'Galillee College',
  'Galindo El',
  'Galion High School',
  'Galion Middle School',
  'Gallaher (robert S.) Elementary School',
  'Gallatin County High School',
  'Gallatin County Lower Elementary',
  'Gallatin County Middle School',
  'Gallatin County Upper Elementary',
  'Gallatin Elementary',
  'Gallatin Elementary School',
  'Gallatin Gateway 7-8',
  'Gallatin Gateway School',
  'Gallatin High',
  'Gallatin High School',
  'Gallatin Junior High School',
  'Gallatin Middle School',
  'Gallatin Senior High School',
  'Gallaudet University',
  'Gallberry Farm Elementary',
  'Gallego Intermediate Fine Arts Magnet School',
  'Gallego Primary Fine Arts Magnet',
  'Gallegos El',
  'Gallia Academy High School',
  'Gallia Academy Middle School',
  'Galliano Elementary School',
  'Gallimore Elementary School',
  'Gallina Elementary',
  'Gallinas Elementary',
  'Gallistel Elem Language Academy',
  'Gallman Elementary',
  'Galloway Academy',
  'Galloway El',
  'Galloway Elementary School',
  'Galloway Ridge Intermediate School',
  'Galloway Township Middle School',
  'Gallup Central Alternative',
  'Gallup High',
  'Gallup Hill School',
  'Gallup Middle',
  'Galm El',
  'Galt High',
  'Galtier Elementary School',
  'Galva Elem School',
  'Galva Jr-sr High School',
  'Galva-holstein Elementary School',
  'Galva-holstein Upper Elementary',
  'Galvan El',
  'Galveston Co Detention Ctr',
  'Galveston Co J J A E P',
  'Galveston Co Jjaep',
  'Galveston College',
  'Galveston Elementary School',
  'Galvez Middle School',
  'Galvez Primary School',
  'Galvin Middle School',
  'Galway Elementary',
  'Galway Junior/senior High School',
  'Galway Mayo Institute of Technology',
  'Gamaliel Elementary',
  'Gambell School',
  'Gamble Rogers Middle School',
  'Gambold Pre-school',
  'Gamewell Elementary',
  'Gamewell Middle',
  'Gammon Elem',
  'Gamp',
  'Gams High Tech Magnet School',
  'Ganado El',
  'Ganado H S',
  'Ganado High School',
  'Ganado Jh',
  'Ganado Middle School',
  'Ganado Primary School',
  'Gananda Middle School',
  'Gandhara Institute of Medical Sciences',
  'Gandhi Institute of Technology and Managment',
  'Gandhigram Rural Institute',
  'Gandy Elementary School',
  'Ganesha High',
  'Gangdara Institute Of Science & Technology',
  'Ganiard School',
  'Ganja State University',
  'Gannett Peak Elementary',
  'Gannon University',
  'Gans Es',
  'Gans Hs',
  'Gansevoort Elementary School',
  'Gansu Agricultural University',
  'Gansu University of Technology',
  'Gant Elementary',
  'Gantner Avenue School',
  'Gap Creek Elementary',
  'Gap Primary School',
  'Gar-field High',
  'Garaway High School',
  'Garber Es',
  'Garber High School',
  'Garber Hs',
  'Garcia El',
  'Garcia Lorca Elem School',
  'Garcia Middle',
  'Garcia Ymla',
  'Garcia-leza Ec/pre-k Center',
  'Gard Elementary School',
  'Garden City Alternate Education Center',
  'Garden City Community College',
  'Garden City El',
  'Garden City Elementary',
  'Garden City Elementary School',
  'Garden City H S',
  'Garden City High School',
  'Garden City Middle School',
  'Garden City Park School',
  'Garden City School',
  'Garden County Elementary Sch',
  'Garden County Junior/senior High School',
  'Garden Elementary',
  'Garden Elementary School',
  'Garden Gate Elementary',
  'Garden Grove Elementary',
  'Garden Grove Elementary School',
  'Garden Grove High',
  'Garden Heights Elementary',
  'Garden Hills Elem School',
  'Garden Hills Elementary School',
  'Garden Lakes Elementary School',
  'Garden Oaks Montessori',
  'Garden Park El',
  'Garden Park Elementary',
  'Garden Place Elementary School',
  'Garden Plain Elem',
  'Garden Plain High',
  'Garden Ridge Area H S',
  'Garden Ridge El',
  'Garden Road Elementary',
  'Garden Spot Ms',
  'Garden Spot Shs',
  'Garden Springs Elementary School',
  'Garden State Correctional Facility',
  'Garden Valley Elementary',
  'Garden Valley Elementary School',
  'Garden Valley School',
  'Garden Village Elementary',
  'Garden Villas El',
  'Gardena Elementary',
  'Gardena Senior High',
  'Gardendale El',
  'Gardendale Elementary School',
  'Gardendale High School',
  'Gardenhill Elementary',
  'Gardens El',
  'Gardens Elementary School',
  'Gardens School Of Technology Arts Inc',
  'Gardenview Elementary',
  'Gardenville Elementary',
  'Gardiner 7-8',
  'Gardiner Area High School',
  'Gardiner Elem',
  'Gardiner High School',
  'Gardiner Manor School',
  'Gardiner Middle School',
  'Gardiner Regional Middle School',
  'Gardiner School',
  'Gardiners Avenue School',
  'Gardner',
  'Gardner Academy',
  'Gardner Academy For Learning And Technology',
  'Gardner Bullis Elementary',
  'Gardner Edgerton High',
  'Gardner Elem',
  'Gardner Elem School',
  'Gardner Elementary',
  'Gardner Elementary School',
  'Gardner High',
  'Gardner Magnet School',
  'Gardner Middle School',
  'Gardner Park Elementary',
  'Gardner Pilot Academy',
  'Gardner Road Elementary School',
  'Gardner Street Elementary',
  'Gardner-dickinson School',
  'Gardner-newman Middle School',
  'Gardner-south Wilmington Twp H S',
  'Gardner-strong Elem. School',
  'Gardners Elementary',
  'Gardnertown Leadership Academy Elementary School',
  'Gardnerville Elementary School',
  'Garey High',
  'Garfield At Palouse High School',
  'Garfield Auxiliary Ms/hs',
  'Garfield Co Dist Hs',
  'Garfield Community School',
  'Garfield Early Childhood Learning Center',
  'Garfield East Elementary School',
  'Garfield El',
  'Garfield Elem',
  'Garfield Elem School',
  'Garfield Elem.',
  'Garfield Elementary',
  'Garfield Elementary - 19',
  'Garfield Elementary School',
  'Garfield Es',
  'Garfield Heights High School',
  'Garfield Heights Middle School',
  'Garfield High',
  'Garfield High School',
  'Garfield Middle',
  'Garfield Middle School',
  'Garfield Montessori School',
  'Garfield Public Preschool Annex',
  'Garfield School',
  'Garibaldi Elementary School',
  'Garin Elementary',
  'Garinger High',
  'Garland Aec',
  'Garland Can Academy',
  'Garland Elementary',
  'Garland H S',
  'Garland Mcmeans J H',
  'Garland R. Quarles Elementary',
  'Garland School',
  'Garlough Environmental Magnet',
  'Garner Correctional Institution',
  'Garner El',
  'Garner Fine Arts Academy',
  'Garner High',
  'Garner Middle',
  'Garner-hayfield-ventura Elementary School',
  'Garner-hayfield-ventura High School',
  'Garner-hayfield-ventura Intermediate School',
  'Garner-hayfield-ventura Junior High',
  'Garnet Career Center',
  'Garnet J. Robertson Intermediate',
  'Garnet Mesa Elementary School',
  'Garnet Valley El Sch',
  'Garnet Valley Hs',
  'Garnet Valley Ms',
  'Garnett Elementary School',
  'Garrard County Area Technology Center',
  'Garrard County High School',
  'Garrard Middle School',
  'Garret Schenck School',
  'Garretson Elementary',
  'Garretson Elementary - 02',
  'Garretson High School - 01',
  'Garretson Middle School - 03',
  'Garrett Academy Of Technology',
  'Garrett College',
  'Garrett El',
  'Garrett Elem.',
  'Garrett Elementary',
  'Garrett Elementary School',
  'Garrett Heights Elementary',
  'Garrett Heyns High School',
  'Garrett High School',
  'Garrett Middle School',
  'Garrett Morgan Academy',
  'Garrett Morgan Elementary',
  'Garrett Morgan Schl Of Science School',
  'Garrett Park Elementary',
  'Garrett Pri',
  'Garrettford El Sch',
  'Garriga El',
  'Garrison 7 & 8th',
  'Garrison El',
  'Garrison Elem.',
  'Garrison Elementary School',
  'Garrison Es',
  'Garrison H S',
  'Garrison High School',
  'Garrison Middle',
  'Garrison Middle School',
  'Garrison Mill Elementary School',
  'Garrison School',
  'Garrison-jones Elementary School',
  'Garrison-pilcher Elementary School',
  'Garrisonville Elementary',
  'Garry Middle School',
  'Garth Elementary School',
  'Garton Elementary',
  'Garvanza Elementary',
  'Garvey (richard) Intermediate',
  'Garvey Academy',
  'Garvey M Elem School',
  'Garvin Memorial',
  'Garvy J Elem School',
  'Garwood El',
  'Garwood Elementary School',
  'Gary A. Knox Elementary School',
  'Gary Adult Center',
  'Gary And Bobbye Jack Minshew El',
  'Gary Career Center',
  'Gary D Wright Elem Sch',
  'Gary E. Cobb Middle School',
  'Gary Elem School',
  'Gary Elementary School',
  'Gary Hardin Academy',
  'Gary Lighthouse Charter School',
  'Gary Middle College',
  'Gary Road Elementary',
  'Gary Road Intermediate School',
  'Gary School',
  'Gary W Campbell H S',
  'Garyville/mt. Airy Math & Science Magnet Schl.',
  'Garza (carmen Lomas) Primary Center',
  'Garza County Regional Juvenile Center',
  'Garza El',
  'Garza Independence H S',
  'Gasconade Elem.',
  'Gashland Elem.',
  'Gaskill Preparatory School',
  'Gaspar De Portola Middle',
  'Gaspar Vila Mayans',
  'Gaston College',
  'Gaston Early College High School',
  'Gaston Elementary',
  'Gaston Elementary School',
  'Gaston High School',
  'Gaston Jr/sr High School',
  'Gaston Middle',
  'Gaston Point Elementary School',
  'Gaston Virtual Academy',
  'Gate 4/5',
  'Gate City Charter',
  'Gate City Charter School For The Arts',
  'Gate City Elementary School',
  'Gate City High',
  'Gate City Middle',
  'Gate Program',
  'GateWay Community College',
  'Gatelot Avenue School',
  'Gates - Alternative High School Program',
  'Gates County Senior High',
  'Gates El',
  'Gates Elementary School',
  'Gates Lane',
  'Gates Middle School',
  'Gates Mills Elementary School',
  'Gates Secondary School',
  'Gates Street Elementary',
  'Gates-chili High School',
  'Gates-chili Middle School',
  'Gatesville El',
  'Gatesville Elementary',
  'Gatesville H S',
  'Gatesville Int',
  'Gatesville J H',
  'Gatesville Pri',
  'Gateway',
  'Gateway Academy',
  'Gateway Academy High School',
  'Gateway Academy To Innovation & Tech.',
  'Gateway Academy-sierra Vista Charter H S',
  'Gateway Academy-townlake Charter H S',
  'Gateway Achievement Center',
  'Gateway Center',
  'Gateway Charter Academy - Middle - H S',
  'Gateway Charter Academy -el',
  'Gateway Charter Elementary School',
  'Gateway Charter High School',
  'Gateway Charter Intermediate School',
  "Gateway Children's Services",
  'Gateway College And Career Academy',
  'Gateway College Preparatory School',
  'Gateway Community',
  'Gateway Community College',
  'Gateway Community Day',
  'Gateway Community Services',
  'Gateway Community and Technical College',
  'Gateway Early College High School',
  'Gateway Education Center',
  'Gateway Educational Ctr.',
  'Gateway Educational Options',
  'Gateway El',
  'Gateway Elem.',
  'Gateway Elementary',
  'Gateway Elementary School',
  'Gateway Environmental K-8 Learning Center',
  'Gateway H S',
  'Gateway High',
  'Gateway High (continuation)',
  'Gateway High School',
  'Gateway International',
  'Gateway Lab School',
  'Gateway Middle',
  'Gateway Middle School',
  'Gateway Ms',
  'Gateway Pointe Elementary',
  'Gateway Polytechnic Academy',
  'Gateway Preparatory Academy',
  'Gateway Regional High',
  'Gateway Regional High School',
  'Gateway Regional Middle School',
  'Gateway Sch',
  'Gateway School',
  'Gateway Science Acad-south Ele',
  'Gateway Science Acad/st Louis',
  'Gateway Science Academy High',
  'Gateway Science Academy Middle',
  'Gateway Shs',
  'Gateway Stem Academy Charter School',
  'Gateway Student Support Ctr',
  'Gateway Tech H S',
  'Gateway Technical College',
  'Gateway To College',
  'Gateway To College - Is',
  'Gateway To College Academy',
  'Gateway To College At Holyoke Community College',
  'Gateway To College At Laney College',
  'Gateway To College At Springfield Technical Community Colleg',
  'Gateway To Collegemott Community College',
  'Gateway To Graduation Academy',
  'Gateway To Success Academy',
  'Gateway University',
  'Gateway/hubert Wheeler School',
  'Gateways High School',
  'Gatewood Academy',
  'Gatewood Elementary',
  'Gatewood Elementary School',
  'Gatewood Es',
  'Gatlinburg Pittman High',
  'Gator Run Elementary School',
  'Gattis El',
  'Gauer (melbourne A.) Elementary',
  'Gauger-cobbs Middle School',
  'Gauhati University',
  'Gauldin Elementary',
  'Gauley Bridge Elementary',
  'Gauley River Elementary School',
  'Gault Elementary',
  'Gault Street Elementary',
  'Gause Academy Of Leadership',
  'Gause El',
  'Gause Elementary',
  'Gautier Elementary School',
  'Gautier High School',
  'Gautier Middle School',
  'Gavilan College',
  'Gavilan Peak Elementary',
  'Gavilan View Middle',
  'Gavin Central Elementary School',
  'Gavin South Middle School',
  'Gayhead School',
  'Gaylesville High School',
  'Gaylord A Nelson Educational Center',
  'Gaylord High Schoolvoc Bldg',
  'Gaylord Intermediate School',
  'Gaylord Middle School',
  'Gaylord Virtual Instruction Program',
  'Gayman El Sch',
  'Gaynor Mccown Expeditionary Learning School',
  'Gayton Elementary',
  'Gayville-volin Elementary - 02',
  'Gayville-volin High School - 01',
  'Gayville-volin Jr. High - 03',
  'Gaywood Elementary',
  'Gazelle Elementary',
  'Gazi University Ankara',
  'Gaziantep University',
  'Gaziosmanpasa University',
  'Gc Middle College High',
  'Gcclr Institute Of Technology',
  'Gcms Elementary School',
  'Gcms High School',
  'Gcms Middle School',
  'Gdansk Management College',
  'Gdynia Maritime Academy',
  'Ge Massey Elementary',
  'Gearhart Elementary School',
  'Gearing Elementary School',
  'Gearity Professional Development School',
  'Gearld Wright School',
  'Geary Elementary/middle School',
  'Geary Es',
  'Geary Hs',
  'Geary Ms',
  'Gebze Institute of Technology',
  'Gebze Tecnical University',
  'Ged Testing',
  'Gedik University',
  'Gee Edmonson Academy',
  'Gee White Academy',
  'Geer Park Elementary',
  'Geeter Middle',
  'Geff Elem School',
  'Gegan Elementary',
  'Geggie Elem.',
  'Gehringer Elementary',
  'Geiger',
  'Geiger Early Childhood Center',
  'Geiger Elementary',
  'Geil Elementary School',
  'Geist Elementary School',
  'Geist Montessori Academy',
  'Geller House School',
  'Gem Charter School',
  'Gem Prep: Meridian',
  'Gem Prep: Nampa',
  'Gem Prep: Pocatello School',
  'Gemini Elementary School',
  'Gemini Jr High School',
  'Gemsville Technical University',
  'Gen Douglas Macarthur Senior High School',
  'Gen George Patton Elem School',
  'Gen John A Logan Attendance Cntr',
  'Gen John J Stefanik',
  'Genaro Bou',
  'Genaro Cautino',
  'Gene Burton College And Career Academy',
  'Gene Dillon Elementary School',
  'Gene Drevno Community Day',
  'Gene Howe El',
  'Gene M Reed El',
  'Gene Pike Middle',
  'Gene Scarselli Elementary School',
  'Gene Sparado Regional Juvenile Center',
  'Gene W. Brown Elementary',
  'Gene Ward Elementary School',
  'Gene Witt Elementary School',
  'General Academic Development',
  'General Beadle Elementary - 01',
  'General Brown Junior-senior High School',
  'General Charles G. Harker School',
  'General Davie Jr. Primary Center',
  'General Grant Middle',
  'General Greene Elementary',
  'General Herkimer Elementary School',
  'General John Nixon Elementary',
  'General John Stricker Middle',
  'General Johnnie Wilson Middle School',
  'General Mclane Hs',
  'General Myer Elementary School',
  'General Nash El Sch',
  'General Ray Davis Middle School',
  'General Ricardo Sanchez El',
  'General Rosecrans Elementary',
  'General Shafter Elementary',
  'General Shanks Elem School',
  'General Sherman Junior High School',
  'General Smallwood Middle School',
  'General Stanford Elementary',
  'General Tommy Franks El',
  'General Trass High School',
  'General Wayne El Sch',
  'General William Floyd Elementary School',
  'Generating Recovery Of Academic Direction',
  'Generoso Morales Munoz',
  'Genesee Career Institute',
  'Genesee Community Charter School',
  'Genesee Community College',
  'Genesee County Jail',
  'Genesee Early College',
  'Genesee High School',
  'Genesee Hill Elementary',
  'Genesee Isd Transition Center',
  'Genesee School',
  'Genesee Stem Academy',
  'Genesee Street Elementary School',
  'Genesee Valley Boces',
  'Genesee Valley Central School',
  'Genesee Valley Regional Center',
  'Geneseo Elementary School',
  'Geneseo High School',
  'Geneseo Middle School',
  'Geneseo Middle School High School',
  'Genesis Academy',
  'Genesis H S',
  'Genesis Innovation Academy For Boys',
  'Genesis Innovation Academy For Girls',
  'Genesis Innovative School',
  'Genesis Preparatory School',
  'Genesis School',
  'Genesis School Inc.',
  'Geneva 304 Early Learning Center',
  'Geneva Business School',
  'Geneva College',
  'Geneva Community High School',
  'Geneva County Elementary School',
  'Geneva County High School',
  'Geneva County Middle School',
  'Geneva Elementary School',
  'Geneva High School',
  'Geneva Middle Sch North',
  'Geneva Middle Sch South',
  'Geneva Middle School',
  'Geneva North Elementary School',
  'Geneva North High School',
  'Geneva Platt R. Spencer Elementary School',
  'Geneva Regional Career Technical Center',
  'Geneva School',
  'Genevieve Didion',
  'Genevieve M. Crosby Elementary',
  'Genoa Area High School',
  'Genoa Area Middle School',
  'Genoa Central Elem. School',
  'Genoa Central High School',
  'Genoa El',
  'Genoa Elementary',
  'Genoa Elementary School',
  'Genoa Middle School',
  'Genoa-hugo School',
  'Genoa-kingston High School',
  'Genoa-kingston Middle School',
  'Genoveva Perez',
  'Gentian Elementary School',
  'Gentilly Terrace Elementary School',
  'Gentry El',
  'Gentry High School',
  'Gentry High School Conversion Charter',
  'Gentry Intermediate School',
  'Gentry J H',
  'Gentry Middle School',
  'Gentry Primary',
  'Gentry Primary School',
  'Gentry Residential Treat. Fac.',
  'Geo E Nettels Elem',
  'Geo G Dodge Elementary School',
  'Geo H Oliver Visual/perf. Arts',
  'Geo International High School',
  'Geo Prep Academy Of Greater Baton Rouge',
  'Geo Prep Mid-city Of Greater Baton Rouge',
  'Geo T Wilkins Jr High School',
  'Georg-August Universität Göttingen',
  'Georg-Simon-Ohm-Fachhochschule Nürnberg',
  'George A Ferrell El Sch',
  'George A Jackson School',
  'George A Lacure Elem School',
  'George A Whitten Elementary',
  'George A. Buljan Middle',
  'George And Evelyn Stein Continuation',
  'George B Carpenter Elem School',
  'George B Weatherbee School',
  'George B. Fine Elementary School',
  'George B. Miller Elementary',
  'George Bancroft #34',
  'George Bannerman Dealey International Academy',
  'George Bannerman Dealey Montessori',
  'George Bibich Elementary School',
  'George Blaisdell El Sch',
  'George Brown College',
  'George Brown Jr. Elementary',
  'George Bush H S',
  'George C Marshall Elementary',
  'George C Simkins Jr Elementary',
  'George C Wallace State Community College-​Dothan',
  'George C Wallace State Community College-​Hanceville',
  'George C Wallace State Community College-​Selma',
  'George C. Baker Elementary School',
  'George C. Marshall Elementary',
  'George C. Miller Jr. Middle School',
  'George C. Payne Elementary',
  'George C. Weimer Elementary School',
  'George Carr Round Elementary',
  'George Cirby Elementary',
  'George Clarke El',
  'George County High School',
  'George County Middle School',
  'George Cox Elementary School',
  'George Crockett Academy',
  'George Cullender Kind',
  'George D Gilbert Elementary School',
  'George D Ryder Elementary School',
  'George D Steckel El Sch',
  'George D Warriner High School For Personalized Learning',
  'George D Warriner Middle',
  'George Dawson Middle',
  'George De La Torre Jr. Elementary',
  'George Defer Elementary School',
  'George E Harris Elementary School',
  'George E Jack School',
  'George E Kelly El',
  'George E. Washington Elementary School (pk-5)',
  'George E. Wilson Elementary School',
  'George Eisenhut Elementary',
  'George Elementary',
  'George Elementary School',
  'George Ellery Hale Charter Academy',
  'George F Baker High School',
  'George F Johnson Elementary School',
  'George F. Baker Elementary',
  'George F. Kelly Elementary',
  'George F. Willett',
  'George Fischer Middle School',
  'George Fox College',
  'George Fox Middle',
  'George G White',
  'George G. Tyler Elementary',
  'George Gervin Academy',
  'George Gervin Prep Academy',
  'George Grant Mason Elementary School',
  'George Guffey Elem.',
  'George H Bryant Vocationalagricultural Center',
  'George H Conley',
  'George H Fisher School 93',
  'George H Gerke Elementary School',
  'George H Potter',
  'George H W Bush New Tech Odessa',
  'George H. Englesby Elementary School',
  'George H. Flamson Middle',
  'George H. Gilson Middle School',
  'George H. Moody Middle',
  'George Hall Elementary',
  'George Hall Elementary School',
  'George Hanaford School',
  'George Hays-jennie Porter Elementary',
  'George Herbert Walker Bush El',
  'George Hersey Robertson School',
  'George Hildebrand Elementary',
  'George I Sanchez',
  'George I Sanchez H S',
  'George I Sanchez North',
  'George J Anderson El',
  'George J H',
  'George J Mitchell School',
  'George J Plava El Sch',
  'George J. Mcintosh Elementary',
  'George J. Mitchell Elementary School',
  'George J. Peters School',
  'George J. West El. School',
  'George Jr Republic El Sch',
  'George Jr Republic Hs',
  'George Jr Republic Ms',
  'George Junior High School',
  'George Junior Republic School',
  'George K. Porter Middle',
  'George Kelly Elementary',
  'George Keverian School',
  'George Key',
  'George L Carrington Middle',
  'George L Catrambone',
  'George L Cooke School',
  'George L Dilworth Middle School',
  'George L Myers Elementary School',
  'George L. Hess Educational Complex',
  'George L. Snowden Elementary',
  'George Lincoln Mosher',
  'George Long Elementary School',
  'George M Davis Elementary School',
  'George M Kozmetsky School',
  'George M Riddle Elementary School',
  'George M. Hampton Middle',
  'George M. Null Elem.',
  'George Mason Elementary',
  'George Mason High',
  'George Mason University',
  'George Mayne Elementary',
  'George Mcdowell-exchange Middle School',
  'George Mcgovern Middle School -09',
  'George Mcparland Elementary',
  'George Melcher Elem.',
  'George Middle School',
  'George Morgan Sr. H.s.',
  'George Nicoloff Elementary',
  'George O Barr School',
  'George P Austin Junior High School',
  'George P. Mullen Elementary',
  'George P. Phenix Elementary',
  'George Peabody El',
  'George R Austin Intermediate School',
  'George R Carter Middle School',
  'George R Ledbetter Intermediate',
  'George R Martin',
  'George R Staley Elementary School',
  'George R. Robinson Elem.',
  'George Ranch H S',
  'George Rogers Clark High School',
  'George Rogers Clark Md/hs',
  'George Rogers Clark School',
  'George Ross Mackenzie Elementary School',
  'George S Buck School 94',
  'George S Mickelson Alternative Elementary - 06',
  'George S Mickelson Alternative High School - 04',
  'George S. Mickelson Middle School - 02',
  'George S. Patton Continuation',
  'George Saiter School Of Excellence',
  'George Sargeant Elementary',
  'George Southard Elementary School',
  'George Stone Area Voc-tech Center',
  'George T Wilkins Elem School',
  'George T. Baker Aviation Technical College',
  'George T. Cromwell Elementary',
  'George T. Daniel Elementary School',
  'George T. Egling Middle',
  'George Unseld Early Childhood Center',
  'George V. Leyva Intermediate',
  'George V. Voinovich Reclamation Academy',
  'George Visual And Performing Arts Magnet And Middle',
  'George W Bush El',
  'George W Carver Creative Arts Learning Center',
  'George W Carver Early Education Center',
  'George W Herlich Juv Det Ctr',
  'George W Hewlett High School',
  'George W Julian School 57',
  'George W Lieb Elem School',
  'George W Long High School',
  'George W Miller Elementary School',
  'George W Truett El',
  'George W. Bush Elementary',
  'George W. Carver Center For Arts & Technology',
  'George W. Carver Elementary',
  'George W. Carver Elementary School',
  'George W. Carver Intermediate',
  'George W. F. Mcmechen',
  'George W. Gibbs Elementary',
  'George W. Hartmann Center',
  'George W. Jenkins Senior High',
  'George W. Marks Elementary School',
  'George W. Munroe Elementary School',
  'George W. Watkins Elementary',
  'George W. Whitlow Elementary',
  'George Wagner Middle',
  'George Ward Elementary School',
  'George Washington',
  'George Washington Academy',
  'George Washington Academy School No. 1',
  'George Washington Bush Middle Sch',
  'George Washington Carver',
  'George Washington Carver Academy',
  'George Washington Carver El',
  'George Washington Carver Elem.',
  'George Washington Carver Elementary',
  'George Washington Carver Elementary School',
  'George Washington Carver Freshman Campus',
  'George Washington Carver High School',
  'George Washington Carver High School For The Sciences',
  'George Washington Carver Institute',
  'George Washington Carver Middle',
  'George Washington Carver Middle School',
  'George Washington Carver School 87',
  'George Washington Carver School Of Arts And Science',
  'George Washington Charter',
  'George Washington Comm Middle Sch',
  'George Washington Community Hs',
  'George Washington El Sch',
  'George Washington Elem School',
  'George Washington Elementary',
  'George Washington Elementary Sch',
  'George Washington Elementary School',
  'George Washington High',
  'George Washington High School',
  'George Washington Middle',
  'George Washington Middle School',
  'George Washington Preparatory High',
  'George Washington School',
  'George Washington School No. 2',
  'George Washington University',
  'George Watts Elementary',
  'George Welch Elementary School',
  'George West El',
  'George West H S',
  'George West J H',
  'George West Pri',
  'George Westergard Elementary School',
  'George Westinghouse Career & Technical Ed High School',
  'George White Elementary',
  'George Whittell High School',
  'George Wolf El Sch',
  'George Wythe High',
  'George Y. Komure Elementary',
  "George's Creek Elementary",
  'George-little Rock Middle School',
  'George-little Rock Senior High School',
  'Georgetown Alter Prog',
  'Georgetown Behavioral Health Institute',
  'Georgetown Central School',
  'Georgetown Charter Academy',
  'Georgetown Community School',
  'Georgetown East Elementary',
  'Georgetown Elementary',
  'Georgetown Elementary School',
  'Georgetown H S',
  'Georgetown High',
  'Georgetown High School',
  'Georgetown Jr/sr High School',
  'Georgetown Kindergarten Center',
  'Georgetown Middle',
  'Georgetown Middle School',
  'Georgetown Primary',
  'Georgetown School',
  'Georgetown University',
  'Georgetown-ridge Farm High School',
  'Georgetowne Middle School',
  'Georgia Academy For The Blind',
  "Georgia Baptist Children's Home And Family Ministries",
  'Georgia Brown Dual Immersion Magnet Elementary',
  'Georgia Center',
  'Georgia College',
  'Georgia Connections Academy',
  'Georgia Cyber Academy',
  'Georgia Elementary & Middle School',
  'Georgia Highlands College',
  'Georgia Institute of Technology',
  'Georgia Jones Ayers Middle School',
  'Georgia Matthews Elem',
  'Georgia Military College',
  'Georgia Morris Elementary',
  'Georgia Morse Middle School - 02',
  'Georgia Northwestern Technical College',
  "Georgia O'keeffe Elementary",
  'Georgia Perimeter College',
  'Georgia Piedmont Technical College',
  'Georgia School For Innovation And The Classics',
  'Georgia School For The Deaf',
  'Georgia Southern University',
  'Georgia Southwestern State University',
  'Georgia State University',
  'Georgia Washington Middle School',
  'Georgian Agricultural University',
  'Georgian Court College',
  'Georgian Forest Elementary',
  'Georgian Heights Alt Elementary School',
  'Georgian Hills Achievement Elementary School',
  'Georgian Hills Middle',
  'Georgian Technical University',
  'Georgiana School',
  'Georgie D. Tyler Middle',
  'Georgina Alvarado',
  'Georgina Baquero',
  'Georgina P. Blach Junior High',
  'Gerald A. Lawson Acad Of The Arts Math And Sci',
  'Gerald A. Smith Elementary',
  'Gerald Adams Elementary School',
  'Gerald D Cushing School',
  'Gerald Elem.',
  'Gerald F. Litel Elementary',
  'Gerald G Huesken Ms',
  'Gerald Otte Blair Middle Sch',
  'Gerald P. Carr Intermediate',
  'Gerald R Ford Academic Center',
  'Gerald R. Ford Elementary',
  'Gerald W Kirn Middle School',
  'Geraldine 7-8',
  'Geraldine Boudreaux Elementary School',
  'Geraldine Claytor Magnet Academy',
  'Geraldine High School',
  'Geraldine J Mann School',
  'Geraldine Johnson School',
  'Geraldine O Foster Early Childhood Center',
  'Geraldine Palmer El',
  'Geraldine School',
  'Gerard A. Guilmette',
  'Gerard El',
  'Gerardo Selles Sola',
  'Gerber Elementary',
  'Gerber Jr./sr. High',
  'Gereau Center For Applied Technology & Career Exploration',
  'Gering Early Childhood Program',
  'Gering Head Start',
  'Gering High School',
  'Gering Junior High School',
  'Gerlach K-12',
  'Germain Academy For Academic Achievement',
  'German Gerena Community School',
  'German Jordanian University',
  'German Rieckehoff',
  'German University in Cairo',
  'German Valley Grade School',
  'Germanna Community College',
  'Germanshire Elementary',
  'Germanton Elementary',
  'Germantown Elem School',
  'Germantown Elementary',
  'Germantown Elementary School',
  'Germantown High',
  'Germantown High School',
  'Germantown Hills Elementary Sch',
  'Germantown Hills Middle School',
  'Germantown Junior-senior High School',
  'Germantown Middle',
  'Germantown Middle School',
  'Gerner Family Early Ed Ctr.',
  'Geronimo Es',
  'Geronimo Hs',
  'Geronimo Ms',
  'Gerrardstown Elementary School',
  'Gerritts Middle',
  'Gerstle River School',
  'Gertie Belle Rogers Elementary - 04',
  'Gertrude C. Folwell Elementary School',
  'Gertrude K. Edelman/sabal Palm',
  'Gertrude Walker Elem',
  'Gervais Elementary School',
  'Gervais High School',
  'Gervais Middle School',
  'Gess Elementary',
  'Gettysburg Area Hs',
  'Gettysburg Area Ms',
  'Gettysburg College',
  'Gettysburg Elementary',
  'Gettysburg Elementary - 02',
  'Gettysburg High School - 01',
  'Gettysburg Jr. High - 03',
  'Gettysburg Montessori Charter School',
  'Getwell Elementary',
  'Getz School',
  'Geyer Springs Early Childhood Center',
  'Geyser 7-8',
  'Geyser High School',
  'Geyser Road Elementary School',
  'Geyser School',
  'Geyserville Elementary',
  'Geyserville New Tech Academy',
  'Gezira College of Technology',
  'Gh Carson Elementary School',
  'Ghana Christian University College',
  'Ghana Institute of Management and Public Administration (GIMPA)',
  'Ghana Telecom University College',
  'Ghazni University',
  'Ghent Elementary',
  'Ghent K-8',
  'Gholson School',
  'Ghulam Ishaq Khan Institute of Science & Technology',
  'Giannini (a.p.) Middle',
  'Giano Intermediate',
  'Giant City Elem School',
  'Gibbon Elementary School',
  'Gibbon High School',
  'Gibbons Street Elementary School',
  'Gibbs Elementary',
  'Gibbs Elementary School',
  'Gibbs High School',
  'Gibbs Magnet Elementary School',
  'Gibbs Pre-k Center',
  'Gibbsboro Elementary School',
  'Giberson Elementary School',
  'Gibraltar Elementary',
  'Gibraltar High',
  'Gibraltar Middle',
  'Gibsland-coleman High School',
  'Gibson County High School',
  'Gibson Ek High School',
  'Gibson El',
  'Gibson Elem.',
  'Gibson Elementary',
  'Gibson Elementary School',
  'Gibson Southern High School',
  'Gibson Technical Ctr.',
  'Gibsonburg High School',
  'Gibsonburg Middle School',
  'Gibsonton Elementary School',
  'Gibsonville Elementary',
  'Giddings El',
  'Giddings H S',
  'Giddings Int',
  'Giddings Middle',
  'Gideon Edward Sch',
  'Gideon Elem.',
  'Gideon High',
  'Gideon Pond Elementary',
  'Gideon Welles School',
  'Gideons Elementary School',
  'Gidley Elementary',
  'Gier Elementary School',
  'Gier Park School',
  'Giese Elementary',
  'Giesinger El',
  'Giffen Memorial Elementary School',
  'Gifford C. Cole Middle',
  'Gifford Elementary',
  'Gifford Grade School',
  'Gifford Middle School',
  'Gifford St Regional Safe Sch Prog',
  'Gift - Duncan High School',
  'Gift - Ft. Thomas High School',
  'Gift - Gila Institute For Technology',
  'Gift - Mt. Graham High School',
  'Gift - Pima High School',
  'Gift - Safford High School',
  'Gift - Thatcher High School',
  'Gift University',
  'Gift-morenci High School',
  'Gift-mt. Turnbull Academy',
  'Gifted Academy Of Mathematics And Entrepreneurial Studies',
  'Gifu Keizai University',
  'Gifu Pharmaceutical University',
  'Gifu Shotoku Gakuen University',
  'Gifu University',
  'Gifu University for Education and Languages',
  "Gifu Women's University",
  'Gig Harbor High',
  'Gihon Elementary School',
  'Gil Sanchez Elementary',
  'Gila Bend Elementary',
  'Gila Bend High School',
  'Gila Crossing Community School',
  'Gila Ridge High School',
  'Gila Valley Learning Center',
  'Gila Vista Jr High School',
  'Gilbert A. Dater High School',
  'Gilbert Avenue School',
  'Gilbert Classical Academy - Closed',
  'Gilbert Classical Academy High School',
  'Gilbert Classical Academy Jr.',
  'Gilbert Cuellar Sr El',
  'Gilbert Elementary',
  'Gilbert Elementary School',
  'Gilbert F M El',
  'Gilbert Gerdes J H',
  'Gilbert Global Academy High School',
  'Gilbert Global Academy Junior High',
  'Gilbert H. Hood Middle School',
  'Gilbert Heights Elementary School',
  'Gilbert High',
  'Gilbert High (continuation)',
  'Gilbert High School',
  'Gilbert Int',
  'Gilbert Intermediate School',
  'Gilbert J Mircovich Elementary',
  'Gilbert Junior High School',
  'Gilbert L Sena Charter Hs',
  'Gilbert Linkous Elementary',
  'Gilbert Middle',
  'Gilbert Middle School',
  'Gilbert Park Elementary School',
  'Gilbert Primary',
  'Gilbert School',
  'Gilbert Stuart Middle School',
  'Gilbert W Mcneal Elementary School',
  'Gilbert Willie Sr El',
  'Gilberto Concepcion De Gracia',
  'Gilberts Elem Sch',
  'Gilbertsville El Sch',
  'Gilbertsville-mount Upton Elementary School',
  'Gilbertsville-mount Upton Junior-senior High School',
  'Gilboa-conesville Central School',
  'Gilbreath-reed Career And Technical Center',
  'Gilchrist Elementary',
  'Gilchrist Elementary School',
  'Gilchrist Junior/senior High School',
  'Gilchrist Virtual Franchise',
  'Gilchrist Virtual Instruction (course Offerings)',
  'Gilchrist Virtual Instruction Program',
  'Gilchrist Virtual Instruction Program (district Provided)',
  'Gilcrease Es',
  'Gilcrest Elementary School',
  'Gilder Elem School',
  'Gildersleeve School',
  'Gildford Colony School',
  'Gildo Rey Elementary School',
  'Gilead Hill School',
  'Giles Co High School',
  'Giles County Tech. Center',
  'Giles High',
  'Gilford Butler School',
  'Gilford Elementary School',
  'Gilford High School',
  'Gilford Middle School',
  'Gilham Elementary School',
  'Gililland Middle School',
  'Gilkey Elementary School',
  'Gill Elementary',
  'Gill Elementary School',
  'Gill Hall El Sch',
  'Gilles-sweet Elementary School',
  'Gillespie Elem School',
  'Gillespie High School',
  'Gillespie Middle School',
  'Gillespie Park Elementary',
  'Gillett Elementary',
  'Gillett Elementary School',
  'Gillett High',
  'Gillett Int',
  'Gillett Middle',
  'Gillette El',
  'Gillette Elementary School',
  'Gillette Road Middle School',
  'Gilliam Elem.',
  'Gilliam School',
  'Gillingham Charter School',
  'Gillis Elementary School',
  'Gilman City Elem.',
  'Gilman City High',
  'Gilman Elementary',
  'Gilman High',
  'Gilmanton Elementary',
  'Gilmanton Elementary School',
  'Gilmanton High',
  'Gilmanton Middle',
  'Gilmartin School',
  'Gilmer County Elementary School',
  'Gilmer County High School',
  'Gilmer El',
  'Gilmer H S',
  'Gilmer High School',
  'Gilmer Int',
  'Gilmer Middle School',
  'Gilmor Elementary',
  'Gilmore City Elementary School',
  'Gilmore Elementary School',
  'Gilmore J Fisher Middle',
  'Gilmore Lane Elementary',
  'Gilmore Middle',
  'Gilmore School Early Childhood Center',
  'Gilmour Es',
  'Gilpin County Elementary School',
  'Gilpin County Undivided High School',
  'Gilpin Manor Elementary',
  'Gilpin Montessori Public School',
  'Gilroy High',
  'Gilroy Prep (a Navigator School)',
  'Gilroy Special Ed Preschool',
  'Gilson Brown Elem School',
  'Gilsum Elementary School',
  'Giltner Elementary School',
  'Giltner High School',
  'Ginger Mcnabb El',
  'Ginn Academy',
  'Ginnings El',
  'Ginter Park Elementary',
  'Ginther Elementary School',
  'Girard Elementary School',
  'Girard High',
  'Girard Hs',
  'Girard Intermediate Middle School',
  'Girard Junior High School',
  'Girard Middle',
  'Girard Middle School',
  'Girard Sr High School',
  'Girard Stephen Sch',
  'Girardot Center For Youth',
  'Girdler Elementary School',
  'Girdwood School',
  'Giresd Center Programs',
  'Girls Academic Leadership Academy (gala)',
  'Girls Athletic Leadership School High School',
  'Girls Athletic Leadership School Los Angeles',
  'Girls Athletic Leadership School Middle School',
  'Girls Hs',
  'Girls Leadership Academy Of Arizona',
  'Girls Leadership Academy Of Wilmington',
  'Girls Preparatory Charter School Of New York',
  'Girls Preparatory Charter School Of The Bronx',
  'Girne American University',
  'Gisler (robert) Elementary',
  'Giunta Middle School',
  'Givens Early Childhood Center',
  'Givens Elementary',
  'Glacial Drumlin School',
  'Glacial Hills Elementary',
  'Glacial Ridge Transitional Skills P',
  'Glacier Creek Middle',
  'Glacier Edge Elementary',
  'Glacier Elementary School',
  'Glacier Gateway Elem',
  'Glacier High School',
  'Glacier High School Charter',
  'Glacier Hills School - Arts/science',
  'Glacier Middle School',
  'Glacier Park Elementary',
  'Glacier Peak Elementary School',
  'Glacier Peak High School',
  'Glacier Point Middle',
  'Glacier Ridge Elementary',
  'Glacier Ridge Elementary School',
  'Glacier Valley Elementary',
  'Glacier View Junior High',
  'Glacier View School',
  'Gladbrook-reinbeck Elementary School',
  'Gladbrook-reinbeck High School',
  'Gladden Elem.',
  'Gladden Farms Elementary',
  'Gladden Middle School',
  'Glade Creek Elementary',
  'Glade Creek Elementary School',
  'Glade Elementary',
  'Glade Elementary School',
  'Glade Hill Elementary',
  'Glade Spring Middle',
  'Glade View Elementary School',
  'Glades Academy Inc',
  'Glades Central H.s. Adult Education',
  'Glades Central High School',
  'Glades Construction Academy At West Tech',
  'Glades Middle School',
  'Glades Virtual Franchise',
  'Glades Virtual Instruction Program',
  'Glades Virtual Instruction Program (district Provided)',
  'Glades West',
  'Gladesboro Elementary',
  'Gladeville Elementary',
  'Gladewater H S',
  'Gladewater Middle',
  'Gladewater Pri',
  'Gladiola Elementary School',
  'Gladness V Player Ecc',
  'Gladstone Area High School',
  'Gladstone Area Middle School',
  'Gladstone Center For Children And Families',
  'Gladstone Elem.',
  'Gladstone Elementary',
  'Gladstone High',
  'Gladstone High School',
  'Gladstone Street Elementary',
  'Gladstone Street School',
  'Gladstone Voluntary Pre-k',
  'Gladwin Community Alternative Hs',
  'Gladwin Elementary School',
  'Gladwin High School',
  'Gladwin Intermediate School',
  'Gladwin Junior High School',
  'Gladwyne Sch',
  'Gladys A. Abraham Elementary School',
  'Gladys Dart School',
  'Gladys Dillon Elementary School',
  'Gladys Jung Elementary',
  'Gladys Noon Spellman Elementary',
  'Gladys Nunery School',
  'Gladys Poet-christian Elementary',
  'Gladys Polk El',
  'Gladys Weymouth Elem School',
  'Gladys Wood Elementary',
  'Glandorf Elementary School',
  'Glankler Early Learning Center',
  'Glanton Elementary',
  'Glanton-hindsman Elementary',
  'Glasco Elem',
  'Glasco High',
  'Glascock County Consolidated School',
  'Glasgow Caledonian University',
  'Glasgow Elem.',
  'Glasgow Elementary.',
  'Glasgow High',
  'Glasgow High School',
  'Glasgow Middle',
  'Glasgow Middle School',
  'Glasgow School of Art',
  'Glass City Academy',
  'Glass El',
  'Glassboro High School',
  'Glassboro Intermediate School',
  'Glassbrook Elementary',
  'Glasscock Elementary School',
  'Glassell Park Elementary',
  'Glassford Hill Middle School',
  'Glassmanor Elementary',
  'Glastonbury High School',
  'Glastonbury/east Hartford Magnet School',
  'Gleason El',
  'Gleason Lake Elementary',
  'Gleason School',
  'Glebe Elementary',
  'Glebe Street Elementary School',
  'Gledhill Street Elementary',
  'Glen A. Wilson High',
  'Glen Acres El Sch',
  'Glen Acres Elementary School',
  'Glen Allen Elementary',
  'Glen Allen High',
  'Glen Alpine Elementary',
  'Glen Alta Elementary',
  'Glen Arden Elementary',
  'Glen Avenue School',
  'Glen Avon Elementary',
  'Glen Burnie High',
  'Glen Burnie Park Elementary',
  'Glen C. Taylor Elementary School',
  'Glen Carbon Elem School',
  'Glen City Elementary',
  'Glen Couch El',
  'Glen Cove El',
  'Glen Cove Elementary',
  'Glen Cove High School',
  'Glen Crest Middle School',
  'Glen Dale Center',
  'Glen Dale Elementary School',
  'Glen Edwards Middle',
  'Glen Este Middle School',
  'Glen Flora Elem School',
  'Glen Forest Elementary',
  'Glen Fork Elementary & Middle School',
  'Glen Garner Elementary School',
  'Glen Grove Elem School',
  'Glen H Peters School',
  'Glen H. Dysinger Sr. Elementary',
  'Glen Haven Elementary',
  'Glen Head Elementary School',
  'Glen Hill Primary',
  'Glen Hills Middle',
  'Glen Hills School',
  'Glen Iris Elementary School',
  'Glen Lake Community School',
  'Glen Lake Elementary',
  'Glen Lake Elementary School',
  'Glen Lake School',
  'Glen Landing Middle School',
  'Glen Lea Elementary',
  'Glen Loch El',
  'Glen Meadow Middle School',
  'Glen Oak Comm Learning Cntr',
  'Glen Oak Elementary',
  'Glen Oak Elementary School',
  'Glen Oaks',
  'Glen Oaks Community College',
  'Glen Oaks El',
  'Glen Oaks Elem School',
  'Glen Oaks Elementary School',
  'Glen Oaks Park Elementary School',
  'Glen Oaks Senior High School',
  'Glen Park Acad For Excel In Lrn',
  'Glen Park El',
  'Glen Park Elementary',
  'Glen Paul',
  'Glen Ridge High School',
  'Glen Rock High School',
  'Glen Rock Middle School',
  'Glen Rose El',
  'Glen Rose Elementary School',
  'Glen Rose H S',
  'Glen Rose High School',
  'Glen Rose Int',
  'Glen Rose J H',
  'Glen Rose Middle School',
  'Glen School',
  'Glen Springs Elementary School',
  'Glen Ullin Elementary School',
  'Glen Ullin High School',
  'Glen View Elementary',
  'Glen View Elementary School',
  'Glen View High',
  'Glen Yermo Elementary',
  'Glen-worden Elementary School',
  'Glenallan Elementary',
  'Glenallen Elementary School',
  'Glenarden Woods Elementary',
  'Glenbard East High School',
  'Glenbard North High School',
  'Glenbard South High School',
  'Glenbard West High School',
  'Glenbrook Elem School',
  'Glenbrook Elementary',
  'Glenbrook Evening High School',
  'Glenbrook Middle',
  'Glenbrook North High School',
  'Glenbrook Off-campus Center',
  'Glenbrook South High School',
  'Glenburn Elementary School',
  'Glenburn High School',
  'Glencairn School',
  'Glencliff Elementary',
  'Glencliff High School',
  'Glencliff School',
  'Glencoe Elementary School',
  'Glencoe Es',
  'Glencoe High School',
  'Glencoe Hs',
  'Glencoe Middle School',
  'Glencoe-silver Lake Senior High',
  'Glencrest 6th Grade Sch',
  'Glenda Dawson H S',
  'Glendaal School',
  'Glendale Acres Elementary',
  'Glendale American School',
  'Glendale Colony Elementary - 04',
  'Glendale Community Charter School',
  'Glendale Community College',
  'Glendale El Sch',
  'Glendale El.',
  'Glendale Elem School',
  'Glendale Elem.',
  'Glendale Elementary',
  'Glendale Elementary School',
  'Glendale High',
  'Glendale High School',
  'Glendale Jshs',
  'Glendale Landmark School',
  'Glendale Middle',
  'Glendale Primary School',
  'Glendale School',
  'Glendale Union High School District Online Learning Academy',
  'Glendale Union Online',
  'Glendale-feilbach Elementary School',
  'Glendale-kenly Elementary',
  'Glendening Elementary School',
  'Glendo Elementary',
  'Glendo High School',
  'Glendo Junior High School',
  'Glendora Elementary School',
  'Glendora High',
  'Glendover Elementary School',
  'Glenelg High',
  'Glenellen Elementary',
  'Glenfair Elementary School',
  'Glenfeliz Boulevard Elementary',
  'Glenfield Elementary School',
  'Glenfield Middle School',
  'Glenford Elementary School',
  'Glengarry Elementary',
  'Glengary Elementary School',
  'Glenham School',
  'Glenhope El',
  'Glenkirk Elementary',
  'Glenknoll Elementary',
  'Glenmar Elementary',
  'Glenmeade Elementary',
  'Glenmont Elementary School',
  'Glenmoor Elementary',
  'Glenmor High School',
  'Glenmora High School',
  'Glenmore El',
  'Glenmount Elementary/middle',
  'Glenn C Hardin Int',
  'Glenn C Marlow Elementary',
  'Glenn County Juvenile Court',
  'Glenn County Rop',
  'Glenn County Special Education',
  'Glenn Dale Elementary',
  'Glenn Duffy Elementary School',
  'Glenn Duncan Stem Academy',
  'Glenn El',
  'Glenn Elem School',
  'Glenn Elementary',
  'Glenn F Burton School',
  'Glenn H Curtiss Memorial School',
  'Glenn H S',
  'Glenn Hammond Curtiss Middle',
  'Glenn Harmon El',
  'Glenn Hills Elementary School',
  'Glenn Hills High School',
  'Glenn Hills Middle School',
  'Glenn L. Downs School',
  'Glenn Livingston Elementary',
  'Glenn Martin Elementary',
  'Glenn Middle',
  'Glenn O Swing Elementary',
  'Glenn Public School',
  'Glenn R Marshall Elementary School',
  'Glenn Raymond Middle School',
  'Glenn W Levey Middle School',
  'Glenn Westlake Middle School',
  'Glenn York El',
  'Glennallen Elementary',
  'Glennallen Jr/sr High School',
  'Glennon Heights Elementary School',
  'Glenns Ferry Elementary School',
  'Glenns Ferry High School',
  'Glenns Ferry Middle School',
  'Glenns Valley Elementary School',
  'Glennville Elementary School',
  'Glennville Middle School',
  'Glenoak High School',
  'Glenoaks El',
  'Glenoaks Elementary',
  'Glenolden Sch',
  'Glenpool Es',
  'Glenpool Hs',
  'Glenpool Ms',
  'Glenridge Elem.',
  'Glenridge Elementary',
  'Glenridge Middle',
  'Glenrock High School',
  'Glenrock Intermediate School',
  'Glenrock Middle School',
  'Glens Falls Middle School',
  'Glens Falls Senior High School',
  'Glenshire Elementary',
  'Glenside',
  'Glenside El Sch',
  'Glenside Middle School',
  'Glenvar Elementary',
  'Glenvar High',
  'Glenvar Middle',
  'Glenview Center For Childcare And Learning',
  'Glenview College Preparatory High School',
  'Glenview Elementary',
  'Glenview Elementary School',
  'Glenview Middle',
  'Glenview Middle School',
  'Glenview School',
  'Glenville Emmons Elementary',
  'Glenville High School',
  'Glenville School',
  'Glenville-emmons Secondary',
  'Glenwood',
  'Glenwood Ave Elementary School',
  'Glenwood City Elementary',
  'Glenwood City High',
  'Glenwood City Middle',
  'Glenwood El',
  'Glenwood El Sch',
  'Glenwood Elem Sch & Academy',
  'Glenwood Elem.',
  'Glenwood Elementary',
  'Glenwood Elementary School',
  'Glenwood Es',
  'Glenwood Heights Primary',
  'Glenwood High School',
  'Glenwood Intermediate Sch',
  'Glenwood Intermediate School',
  'Glenwood Landing Elementary School',
  'Glenwood Leadership Academy',
  'Glenwood Middle',
  'Glenwood Middle School',
  'Glenwood Park Elementary School',
  'Glenwood Primary School',
  'Glenwood Ridge Elementary School',
  'Glenwood School',
  'Glenwood School (k-8)',
  'Glenwood Secondary',
  'Glenwood Senior High School',
  'Glenwood Springs Elementary School',
  'Glenwood Springs High School',
  'Glenwood Springs Middle School',
  'Glickman Elementary',
  'Glidden Elementary',
  'Glidden-ralston Elementary School',
  'Glidden-ralston Jr-sr High School',
  'Glide Elementary School',
  'Glide High School',
  'Glide Middle School',
  'Glider Elementary',
  'Glion Institute of Higher Education',
  'Global Academy',
  'Global Achievers School',
  'Global Ambassadors Language Academy',
  'Global Business School Barcelona',
  'Global Communications Academy',
  'Global Community Charter School',
  'Global Community Hs At Morris Hall',
  'Global Concepts Charter School',
  'Global Connections High School',
  'Global Dual Language School',
  'Global Education Academy',
  'Global Education Academy 2',
  'Global Education Academy Middle',
  'Global Experience Magnet School',
  'Global Family',
  'Global Heights Academy',
  'Global Impact Stem Academy',
  'Global Intermediate Academy',
  'Global Leadership Academy',
  'Global Leadership Academy Cs',
  'Global Leadership Academy Cs Southwest A',
  'Global Learning Academy',
  'Global Learning Charter',
  'Global Learning Charter Public School',
  'Global Learning Collaborative (the)',
  'Global Learning Village',
  'Global Open University  Nagaland',
  'Global Outreach Charter Academy',
  'Global Preparatory Academy',
  'Global Primary Academy',
  'Global Scholars Academy',
  'Global Tech Academy',
  'Global Technology Preparatory',
  'Global University',
  'Global Village Academy',
  'Global Village Academy - Colorado Springs',
  'Global Village Academy - Douglas County',
  'Global Village Academy - Fort Collins',
  'Global Village Academy - Northglenn',
  'Global Village Academy Aurora',
  'Global Youth Charter',
  'Globe Academy Charter School I',
  'Globe Charter School',
  'Globe High School',
  'Globe Park School',
  'Globe University &amp; Minnesota School of Business',
  'Gloria Floyd Elementary School',
  'Gloria Gonzalez (las Cruces)',
  'Gloria M Sabater Elementary School',
  'Gloria Maria Borrero Oliveras (segunda Unidad Bo. Macana)',
  'Gloria Marshall El',
  'Glorietta Elementary',
  "Gloucester & Mathews Counties Gov's Health Sciences Academy",
  'Gloucester City Jr. Sr. High School',
  'Gloucester City Middle School',
  'Gloucester County College',
  'Gloucester County Institute Of Technology',
  'Gloucester High',
  'Gloucester Preschool',
  'Gloucester Township Elementary School',
  'Glover',
  'Glover Community Learning Center',
  'Glover Middle School',
  'Glover Public School',
  'Glover Village School',
  'Gloversville High School',
  'Gloversville Middle School',
  'Gloverville Elementary',
  'Glyndale Elementary School',
  'Glyndon Elementary',
  'Glyndon-felton Elementary',
  'Glynn Academy',
  'Glynn H. Brock Elementary School',
  'Glynn Middle',
  'Gmg Elementary School',
  'Gmg Secondary School',
  'Gna El Ctr',
  'Gnesins Russian Academy of Music',
  'Goa University',
  'Goal Academy',
  'Goal Digital Academy',
  'Goal Program',
  'Goal-granting Opp For Alt Lrng',
  'Goals',
  'Goals Academy',
  'Goals Program',
  'Gobles Elementary School',
  'Gobles High School',
  'Gobles Virtual Academy',
  'Gocio Elementary School',
  'Goddard High',
  'Goddard J H',
  'Goddard Middle',
  'Goddard Middle School',
  'Goddard School/science Technical',
  'Godfrey Elementary School',
  'Godfrey G. Berry Elementary',
  'Godfrey Okoye University',
  'Godfreylee Early Childhood Ctr',
  'Godfreylee Ed Station',
  'Godley El',
  'Godley H S',
  'Godley Int',
  'Godley Jjaep',
  'Godley Middle',
  'Godley Station School',
  'Godsman Elementary School',
  'Godwin El',
  'Godwin Elementary School',
  'Godwin Heights Learning Center',
  'Godwin Heights Middle School',
  'Godwin Heights Senior High School',
  'Goessel Elem',
  'Goessel High',
  'Goethe Elem School',
  'Goethe International Charter',
  'Goffstown High School',
  'Gogebic Co Community Education',
  'Gogebic Community College',
  'Gogebicontonagon Isd Special Education',
  'Goins Elementary',
  'Gokhale Institute of Politics and Economics',
  'Gold Academy',
  'Gold Bar Elementary',
  'Gold Beach High School',
  'Gold Burg School',
  'Gold Camp Elementary School',
  'Gold Coast Community School',
  'Gold Creek School',
  'Gold Hill Elementary',
  'Gold Hill Elementary School',
  'Gold Hill Middle',
  'Gold Oak Elementary',
  'Gold Ridge Educational Center',
  'Gold Ridge Elementary',
  'Gold River Discovery Center K-8',
  'Gold Rush Charter',
  'Gold Rush Elementary',
  'Gold Strike High',
  'Gold Trail',
  'Golden Acres El',
  'Golden Brook Elementary School',
  'Golden City Elem.',
  'Golden City High',
  'Golden Eagle Charter',
  'Golden Elementary',
  'Golden Empire Elementary',
  'Golden Fields School',
  'Golden Gate Community Charter',
  'Golden Gate Elementary School',
  'Golden Gate High School',
  'Golden Gate Middle School',
  'Golden Gate University',
  'Golden Glades Elementary School',
  'Golden Grove Elementary School',
  'Golden Heart Academy',
  'Golden High School',
  'Golden Hill',
  'Golden Hill Elementary',
  'Golden Hill K-8',
  'Golden Hills Elementary',
  'Golden Hills Elementary School',
  'Golden Isles Elementary School',
  'Golden Lake Elementary',
  'Golden Meadow Lower Elementary School',
  'Golden Meadow Middle School',
  'Golden Meadow Upper Elementary School',
  'Golden Meadows El',
  'Golden Oak Community',
  'Golden Oak Elementary',
  'Golden Oak Montessori Of Hayward',
  'Golden Plains Elem',
  'Golden Plains High',
  'Golden Plains Middle',
  'Golden Poppy Elementary',
  'Golden Ridge',
  'Golden Ridge School',
  'Golden Ring Middle',
  'Golden Rule',
  'Golden Rule Charter School',
  'Golden Rule Desoto',
  'Golden Rule Grand Prairie',
  'Golden Rule Schools Inc - Wilmer',
  'Golden Rule Southwest',
  'Golden Sierra Junior Senior High',
  'Golden Springs Elementary',
  'Golden Springs Elementary School',
  'Golden Strip Career And Technology Center',
  'Golden Terrace Elementary School',
  'Golden Triangle Early College',
  'Golden Valley Charter',
  'Golden Valley Elementary',
  'Golden Valley High',
  'Golden Valley Middle',
  'Golden Valley Orchard',
  'Golden Valley River',
  'Golden View Classical Academy',
  'Golden View Elementary',
  'Golden West College',
  'Golden West Elementary',
  'Golden West High',
  'Golden West Middle',
  'Goldendale High School',
  'Goldendale Middle School',
  'Goldendale Primary School',
  'Goldendale Support Service Center',
  'Goldenrod Elementary',
  'Goldenview Colony School - 04',
  'Goldenview Middle School',
  'Goldey-Beacom College',
  'Goldfield Elementary School',
  'Goldie Maple Academy',
  "Goldminer's Daughter",
  'Goldonna Elementary & Junior High School',
  'Goldrick Elementary School',
  'Goldsboro Elementary Magnet',
  'Goldsboro High',
  'Goldsmith Elementary',
  'Goldsmiths College University of London',
  'Goldsmithschiffman Elementary',
  'Goldthwaite El',
  'Goldthwaite H S',
  'Goldthwaite Middle',
  'Goldwood Primary Elementary School',
  'Golestan University of Medical Sciences',
  'Goleta Valley Junior High',
  'Golf Middle School',
  'Golfcrest El',
  'Golfview Elem School',
  'Golfview Elementary Magnet School',
  'Goliad El',
  'Goliad H S',
  'Goliad Middle',
  'Golightly Career And Technical Center',
  'Golightly Education Center',
  'Gollis University',
  'Golva Elementary School',
  'Gomal University',
  'Gombe State University',
  'Gomel State Medical University',
  'Gomel State Technical University Pavel Sukhoi',
  'Gomel State University Francisk Scarnia',
  'Gomez Heritage Elementary Sch',
  'Gompers Elementary',
  'Gompers Elementarymiddle School',
  'Gompers Junior High School',
  'Gompers K-8',
  'Gompers Preparatory Academy',
  'Gonabad University of Medical Sciences',
  'Gondar University',
  'Gonic School',
  'Gonsalves (joe A.) Elementary',
  'Gonzaga University',
  'Gonzales David Camp',
  'Gonzales Early Childhood Campus',
  'Gonzales East Avenue Pri',
  'Gonzales El',
  'Gonzales Elementary',
  'Gonzales H S',
  'Gonzales High',
  'Gonzales J H',
  'Gonzales Middle School',
  'Gonzales North Avenue Int',
  'Gonzales Pri Academy',
  'Gonzales Primary School',
  'Gonzalez Bello',
  'Gonzalez El',
  'Gonzalez Elementary',
  'Gonzalo And Sofia Garcia El',
  'Gonzalo Felicitas Mendez Fundamental Intermediate',
  'Goochland Elementary',
  'Goochland High',
  'Goochland Middle',
  'Good Beginnings Center',
  'Good El',
  'Good Foundations Academy',
  'Good Hope Elementary',
  'Good Hope Elementary School',
  'Good Hope High School',
  'Good Hope Middle School',
  'Good Hope Ms',
  'Good Hope Primary School',
  'Good Intent Elementary School',
  'Good Sheperd Therapeutic Center',
  'Goode Sch',
  'Goode Stem Academy Hs',
  'Goodfield Elem School',
  'Goodhue Elementary',
  'Goodhue High School Credit Recovery',
  'Goodhue Secondary',
  'Gooding Elementary School',
  'Gooding High School',
  'Gooding Middle School',
  'Goodings Grove School',
  'Goodland Elementary',
  'Goodland Jr-sr. High School',
  'Goodlett Elementary',
  'Goodlettsville Elementary',
  'Goodlettsville Middle',
  'Goodman El',
  'Goodman Elem.',
  'Goodman High',
  'Goodman Middle School',
  'Goodman Pickens Elementary School',
  'Goodman-armstrong Elementary',
  'Goodnight Elementary School',
  'Goodnight Middle',
  'Goodnoe El Sch',
  'Goodpine Middle School',
  'Goodrell Middle School',
  'Goodrich Academy',
  'Goodrich El',
  'Goodrich Elem School',
  'Goodrich Elementary',
  'Goodrich Elementary School',
  'Goodrich H S',
  'Goodrich High School',
  'Goodrich Middle',
  'Goodrich Middle School',
  'Goodridge Elementary',
  'Goodridge Secondary',
  'Goodson Middle',
  'Goodsprings Elementary School',
  'Goodview Elementary',
  'Goodwater Montessori School',
  'Goodwell Es',
  'Goodwell Hs',
  'Goodwill Easter Seal Center Special Child',
  'Goodwill Excel Center Pcs',
  'Goodwill High',
  'Goodwill Life Academy',
  'Goodwillie Environmental School',
  'Goodwin Elem School',
  'Goodwin Elementary School',
  'Goodwin Frazier El',
  'Goodwyn Middle School',
  'Goodyear Early Childhood Center',
  'Goodyear Elementary School',
  'Goose Bay Elementary',
  'Goose Creek High',
  'Goose Creek Memorial',
  'Goose Creek Primary',
  'Goose Creek School',
  'Goose Rock Elementary',
  'Goosehill Primary Center',
  'Gordo Elementary School',
  'Gordo High School',
  'Gordon Bailey Elementary',
  'Gordon Bernell Charter',
  'Gordon Bush Elementary',
  'Gordon Central High School',
  'Gordon Correctional Center',
  'Gordon Creek Elementary School',
  'Gordon Dewitt Elementary School',
  'Gordon Early Literacy Center',
  'Gordon Elementary',
  'Gordon Elementary School',
  'Gordon Gregory Middle School',
  'Gordon H. Beatty Elementary',
  'Gordon Lee High School',
  'Gordon Lee Middle School',
  'Gordon Mccaw Elementary School',
  'Gordon Parks Academy',
  'Gordon Parks Elem.',
  'Gordon Parks High School',
  'Gordon Parks School (the)',
  'Gordon Russell Middle School',
  'Gordon School',
  'Gordon W Mitchell',
  'Gordon-barbour Elementary',
  'Gordon-rushville Ele-rushville',
  'Gordon-rushville Elem-gordon',
  'Gordon-rushville High School',
  'Gordon-rushville Middle School',
  'Gordonsville Elementary School',
  'Gordonsville High School',
  'Gordonville Attendance Ctr.',
  'Gore Es',
  'Gore Hs',
  'Gore Upper Es',
  'Gorenflo Elementary School',
  'Goreville Elementary School',
  'Goreville High School',
  'Gorgan University of Agricultural Sciences and Natural Resources',
  'Gorham Elementary School',
  'Gorham High School',
  'Gorham Middle School',
  'Gorman Crossing Elementary',
  'Gorman Elementary',
  'Gorman H S',
  'Gorman Learning Center',
  'Gorman Middle',
  'Gorno-Altaisk State University',
  'Gorrell Elementary School',
  'Gorrie Elementary School',
  'Gorsuch West Elementary School',
  'Gorton High School',
  'Gorzycki Middle',
  'Goshen At Hillcrest Elementary School',
  'Goshen Center School',
  'Goshen Central High School',
  'Goshen College',
  'Goshen Creek El',
  'Goshen Elementary',
  'Goshen Elementary School',
  'Goshen High School',
  'Goshen Intermediate School',
  'Goshen Lane Elementary School',
  'Goshen Middle School',
  'Goshen Post Elementary',
  'Goshen School',
  'Goshen Secure Center',
  'Gosnell Elementary School',
  'Gosnell High School',
  'Gosport Elementary School',
  'Gossett El',
  'Gossler Park School',
  'Gotha Middle',
  'Gotham Avenue School',
  'Gotham Professional Arts Academy',
  'Gothan Collaborative High School',
  'Gothenburg Secondary School',
  'Gotland University College',
  'Gotsch Intermediate Sch.',
  'Gotwals El Sch',
  'Goucher College',
  'Goucher Elementary',
  'Goudy Technology Academy',
  'Gouge Elementary',
  'Gould Educational Center',
  'Gould Elementary School',
  'Gould/mountain Elementary School',
  'Goulds Elementary School',
  'Gouverneur Elementary School',
  'Gouverneur Junior-senior High School',
  'Gov Bent Elementary',
  'Gov Edward Winslow',
  'Gov George Clinton School',
  'Gov Stem Academy For Engineering Marketing And Information',
  'Gov. Morehead School F/t Blind',
  'Gov. Thomas Johnson High',
  'Gov. Thomas Johnson Middle',
  'Govalle El',
  'Govans Elementary',
  'Gove Elementary School',
  'Government College University Faisalabad',
  'Government College University Lahore',
  'Government Hill Elementary',
  'Governor Aram J. Pothier Schoo',
  'Governor Charles C Stratton',
  'Governor Christopher Delsesto',
  'Governor James B Longley Elem Sch',
  'Governor John Fairfield School',
  'Governor Livingston High School',
  'Governor Mifflin Ms',
  'Governor Mifflin Shs',
  'Governor Samuel Wilder King Intermediate School',
  'Governor Sanford B Dole Middle School',
  'Governor Wallace Rider Farrington High School',
  'Governor William Pitkin School',
  'Governor Wolf El Sch',
  "Governor's Academy For Career And Technical Education In Arl",
  "Governor's Career And Technical Academy For Engineering Stud",
  "Governor's Charter School",
  "Governor's Health Sciences Academy",
  "Governor's Ranch Elementary School",
  "Governor's School For The Arts",
  "Governor's School Of Southside Virginia",
  "Governor's Stem Academy At Osburn High School",
  "Governor's Stem Academy At The Burton Center For Arts And Te",
  'Governors State University',
  'Govind Ballabh Pant University of Agriculture and Technology',
  'Gowan Science Academy',
  'Gowana Middle School',
  'Gowanda Elementary School',
  'Gowanda High School',
  'Gowanda Middle School',
  'Gower Elementary',
  'Gower Middle School',
  'Gower West Elem School',
  'Goza Middle School',
  'Gr Lawrence Regional Vocational Technical',
  'Gr Lowell Regional Vocational Technical',
  'Gr New Bedford Vocational Technical',
  'Gra-mar Middle',
  'Graber Elementary',
  'Grace Abbott Elem School',
  'Grace B Luhrs Univ Elem',
  'Grace Bordewich Mildred Bray Elementary',
  'Grace Christian Elementary School',
  'Grace College',
  'Grace E Hardeman El',
  'Grace E. Metz Middle',
  'Grace Early Child. Ed. Ctr.',
  'Grace Elementary School',
  'Grace Farrar Cole',
  'Grace Hartman El',
  'Grace Hill Elementary School',
  'Grace Hopper Stem Academy',
  'Grace Hudson Elementary',
  'Grace Jordan Elementary',
  'Grace Jr/sr High School',
  'Grace King High School',
  'Grace L Roxbury Elementary School',
  'Grace M. Breckwedel Middle School',
  'Grace M. Davis High',
  'Grace Mcwayne Elementary School',
  'Grace Miller Elementary',
  'Grace N. Rogers Elementary School',
  'Grace Park El Sch',
  'Grace Patterson Elementary',
  'Grace R Brandenburg Int',
  'Grace S Beck Sch',
  'Grace S. Thille Elementary',
  'Grace Smith Elementary',
  'Grace Snell Middle School',
  'Grace Warner Elementary School',
  'Grace Wilday Junior High School',
  'Grace Yokley Middle',
  'Graceland College',
  "Graceland Park/o'donnel Heights Elementary",
  'Gracemont Es',
  'Gracemont Hs',
  'Gracemor Elem.',
  'Graceville Colony - 07',
  'Graceville Elementary School',
  'Graceville High School',
  'Gracewood Elementary School',
  'Graciano Gomez Elementary',
  'Graciela Garcia El',
  'Gracielina Rosado Alfaro',
  'Grad Academy Memphis',
  'Grad Alliance Program',
  'Grade Downing Elementary School',
  'Graden Elem.',
  'Graduate School of Business Administration Zurich (GSBA Zurich)',
  'Graduate University for Advanced Studies',
  'Graduation Achievement Center Charter High School',
  'Graduation Assistance Program',
  'Graduation Prep Academy Lanier',
  'Graduation Prep Academy Travis',
  'Grady Brown Elementary',
  'Grady Burnett J H',
  'Grady Elementary',
  'Grady Elementary School',
  'Grady High',
  'Grady High School',
  'Grady Middle School',
  'Grady School',
  'Graebner Elementary School',
  'Graettinger-terril Elementary School',
  'Graettinger-terril High School',
  'Graettinger-terril Middle School',
  'Graff Career Center',
  'Graford El',
  'Graford H S',
  'Grafton Bethel Elementary',
  'Grafton Elem School',
  'Grafton Elementary',
  'Grafton Elementary School',
  'Grafton High',
  'Grafton High School',
  'Grafton Junior High School',
  'Grafton Middle',
  'Grafton Street',
  'Grafton Village Elementary',
  'Graham A Barden Elementary',
  'Graham A Elem School',
  'Graham And Parks',
  'Graham Creek Elementary School',
  'Graham El',
  'Graham Elementary',
  'Graham Elementary And Middle School',
  'Graham Elementary School',
  'Graham Es',
  'Graham H S',
  'Graham High',
  'Graham High School',
  'Graham Hill Elementary School',
  'Graham Hs',
  'Graham Intermediate',
  'Graham J H',
  'Graham Kapowsin High School',
  'Graham Learning Ctr',
  'Graham Mesa Elementary School',
  'Graham Middle',
  'Graham Middle School',
  'Graham Park Middle',
  'Graham Primary School',
  'Graham R Training Center',
  'Graham Road Elementary',
  'Graham School',
  'Graham School The',
  'Grahamwood Elementary',
  'Grain Valley Early Childhd Ctr',
  'Grain Valley High',
  'Grain Valley North Middle Schl',
  'Grain Valley South Middle Schl',
  'Grainger Academy',
  'Grainger Elementary',
  'Grainger High School',
  'Grainwood El.',
  'Gramercy Arts High School',
  'Gramercy Elementary School',
  'Gramercy School',
  'Granada Community Charter',
  'Granada East School',
  'Granada Elementary',
  'Granada Elementary School',
  'Granada High',
  'Granada Hills Charter High',
  'Granada Huntley East Chain Elem Sch',
  'Granada Huntley East Chain High Sch',
  'Granada Huntley East Chain Middle S',
  'Granada Middle',
  'Granada Primary School',
  'Granada Undivided High School',
  'Granard Middle',
  'Granbery Elementary',
  'Granbury H S',
  'Granbury Middle',
  'Granby',
  'Granby Elementary',
  'Granby Elementary School',
  'Granby High',
  'Granby Jr Sr High School',
  'Granby Memorial High School',
  'Granby Memorial Middle School',
  'Grand Ave Sch',
  'Grand Avenue Elementary',
  'Grand Avenue Elementary School',
  'Grand Avenue Es',
  'Grand Avenue Middle School',
  'Grand Avenue Primary Learning Center',
  'Grand Bay Middle School',
  'Grand Blanc Academy',
  'Grand Blanc Community High School',
  'Grand Blanc Middle School East',
  'Grand Blanc Middle School West',
  'Grand Caillou Elementary School',
  'Grand Caillou Middle School',
  'Grand Canyon College Preparatory Charter School',
  'Grand Canyon Elementary',
  'Grand Canyon High School',
  'Grand Canyon University',
  'Grand Center Arts Acad Middle',
  'Grand Center Arts Academy High',
  'Grand Chain Alternative Center',
  'Grand Concourse Academy Charter School',
  'Grand Coteau Elementary School',
  'Grand County High',
  'Grand County Middle',
  'Grand Forks Area Career & Tech Center',
  'Grand Forks Spec Ed Unit',
  'Grand Haven Cyber School',
  'Grand Haven High School',
  'Grand Hts.early Child',
  'Grand Island Elementary',
  'Grand Island Intervention And Assessment',
  'Grand Island Senior High School',
  'Grand Isle Elementary School',
  'Grand Isle High School',
  'Grand Junction Elementary',
  'Grand Junction High School',
  'Grand Lake High School',
  'Grand Ledge High School',
  'Grand Marais Community',
  'Grand Marsh Elementary',
  'Grand Meadow Elementary',
  'Grand Meadow Middle',
  'Grand Meadow Senior High',
  'Grand Mesa Choice Academy',
  'Grand Mesa High School',
  'Grand Mesa Middle School',
  'Grand Mesa Youth Services Center',
  'Grand Mound Elementary',
  'Grand Oak Elementary',
  'Grand Oaks Elementary',
  'Grand Oaks H S',
  'Grand Park Career Center',
  'Grand Prairie Collegiate Institute',
  'Grand Prairie Elem School',
  'Grand Prairie Elementary School',
  'Grand Prairie Fine Arts Academy',
  'Grand Prairie H S',
  'Grand Rapids Area Learning Center',
  'Grand Rapids Child Discovery Center',
  'Grand Rapids Community College',
  'Grand Rapids Early Childhood Center',
  'Grand Rapids Ellington Academy High School',
  'Grand Rapids Ellington Academy Of Arts Technologyelementa',
  'Grand Rapids Ellington Academymiddle School',
  'Grand Rapids Learning Center',
  'Grand Rapids Montessori Public School',
  'Grand Rapids Public Museum School',
  'Grand Rapids Senior High',
  'Grand Rapids University Preparatory Academy',
  'Grand Ridge Elementary',
  'Grand Ridge School',
  'Grand River Academy',
  'Grand River Head Start',
  'Grand River Preparatory High School',
  'Grand River Technical Sch.',
  'Grand Saline El',
  'Grand Saline H S',
  'Grand Saline Int',
  'Grand Saline Middle',
  'Grand Star Elementary',
  'Grand Terrace Elementary',
  'Grand Terrace High Sch At The Ray Abril Jr. Edal Complex',
  'Grand Traverse Academy',
  'Grand Valley Center For Family Learning',
  'Grand Valley Elementary School',
  'Grand Valley High School',
  'Grand Valley Middle School',
  'Grand Valley State University',
  'Grand View Boulevard Elementary',
  'Grand View Elementary',
  'Grand View Elementary School',
  'Grand View Public School',
  'Grande Park Elem Sch',
  'Grande Reserve Elem Sch',
  'Grandfalls-royalty School',
  'Grandfather Academy',
  'Grandfield Es',
  'Grandfield Hs',
  'Grandhaven Elementary School',
  'Grandin Court Elementary',
  'Grandport Elementary Academy',
  'Grandview Alternative Sch.',
  'Grandview College Preparatory Academy',
  'Grandview El',
  'Grandview El Sch',
  'Grandview Elem',
  'Grandview Elem.',
  'Grandview Elementary - 06',
  'Grandview Elementary School',
  'Grandview H S',
  'Grandview Heights High School',
  'Grandview Heights Middle School',
  'Grandview High',
  'Grandview High School',
  'Grandview Hills El',
  'Grandview Isd Jjaep',
  'Grandview J H',
  'Grandview Middle',
  'Grandview Middle School',
  'Grandview Public School',
  'Grandview Sr. High',
  'Grandview Upper El Sch',
  'Grandview-hopkins El',
  'Grandville Central Elementary School',
  'Grandville Century Park Learning Center',
  'Grandville Cummings Elementary School',
  'Grandville East Elementary School',
  'Grandville Grand View Elementary School',
  'Grandville High School',
  'Grandville Middle School',
  'Grandville South Elementary School',
  'Grandville West Elementary School',
  'Grandy Primary',
  'Grange Elementary',
  'Grange Hall Elementary',
  'Grange Middle',
  'Granger Elementary',
  'Granger Elementary School',
  'Granger High',
  'Granger High School',
  'Granger Junior High',
  'Granger Middle School',
  'Granger School',
  'Grangerland Int',
  'Grangeville Elem/middle School',
  'Grangeville High School',
  'Granite Bay High',
  'Granite City High School',
  'Granite Connection High',
  'Granite Es',
  'Granite Falls Elementary',
  'Granite Falls High School',
  'Granite Falls Middle',
  'Granite Falls Middle School',
  'Granite Falls Open Doors',
  'Granite High School',
  'Granite Hill Elementary',
  'Granite Hills High',
  'Granite Hs',
  'Granite Mountain Middle School',
  'Granite Oaks Middle',
  'Granite Park Jr High',
  'Granite Pointe Elementary',
  'Granite Preschool',
  'Granite Quarry Elementary',
  'Granite Ridge Intermediate',
  'Granite School',
  'Granite State Arts Academy Charter School',
  'Granite Street School',
  'Granite Technical Institute',
  'Granite Valley Middle',
  'Graniteville School',
  'Grannemann Elem.',
  'Grant',
  'Grant Avenue Elementary School',
  'Grant Beacon Middle School',
  'Grant Bowler Elementary School',
  'Grant Center',
  'Grant Co Detention Ctr',
  'Grant Colony School',
  'Grant Community High School',
  'Grant Community School',
  'Grant County High School',
  'Grant County Middle School',
  'Grant County Special Ed Coop',
  'Grant Elem',
  'Grant Elem School',
  'Grant Elementary',
  'Grant Elementary School',
  'Grant High School',
  'Grant Junior High School',
  'Grant K-8',
  'Grant Learning Center',
  'Grant Line Elementary School',
  'Grant Middle',
  'Grant Middle School',
  'Grant Park Elem School',
  'Grant Park High School',
  'Grant Primary',
  'Grant Primary Center',
  'Grant Ragan Elementary',
  'Grant Ranch Ece-8 School',
  'Grant Sawyer Middle School',
  'Grant School',
  'Grant Street Elementary',
  'Grant Township School',
  'Grant Union High',
  'Grant Union Junior/senior High School',
  'Grant Watts Elementary School',
  'Grant Wood Elementary School',
  "Grant's Lick Elementary School",
  'Grant-deuel Elementary - 02',
  'Grant-deuel High School - 01',
  'Grant-deuel Jr. High - 03',
  'Grant-foreman Es',
  'Grant-white Elem School',
  'Grantfork Upper Elementary Sch',
  'Grantham',
  'Grantham Academy',
  'Grantham Elementary',
  'Grantham Middle',
  'Grantham Village School',
  'Granton Elementary',
  'Granton High',
  'Grantosa Drive Elementary',
  'Grants High',
  'Grants Pass High School',
  'Grantsburg Elementary',
  'Grantsburg High',
  'Grantsburg Middle',
  'Grantsville Elementary',
  'Grantsville High',
  'Grantsville Jr High',
  'Grantsville School',
  'Grantswood Community Elementary School',
  'Granville Academy',
  'Granville Central High',
  'Granville Early College High',
  'Granville Elementary School',
  'Granville High School',
  'Granville Intermediate School',
  'Granville Junior-senior High School',
  'Granville Middle School',
  'Granville T. Woods Academy Of Innovation Charter School',
  'Granville Village School',
  'Granville Wells Elementary School',
  'Grape Creek H S',
  'Grape Creek Int',
  'Grape Creek Middle',
  'Grape Creek Pri',
  'Grape Street Elementary',
  'Grapeland El',
  'Grapeland Elementary',
  'Grapeland H S',
  'Grapeland J H',
  'Grapeview Elementary & Middle School',
  'Grapevine El',
  'Grapevine Elementary',
  'Grapevine Elementary School',
  'Grapevine H S',
  'Grapevine Middle',
  'Grapevine-colleyville Collegiate Academy At Tcc Ne',
  'Grasonville Elementary School',
  'Grasp Academy',
  'Grass Lake Elem School',
  'Grass Lake Elementary School',
  'Grass Lake High School',
  'Grass Lake Middle School',
  'Grass Ranch Colony Elementary - 13',
  'Grass Ranch Colony High School - 15',
  'Grass Range 7-8',
  'Grass Range High School',
  'Grass Range School',
  'Grass Valley Charter',
  'Grass Valley Elementary',
  'Grass Valley Elementary School',
  'Grass Valley Preschool',
  'Grasse El Sch',
  'Grassfield Elementary',
  'Grassfield High',
  "Grassfield High School Governor's Stem Academy",
  'Grassland Colony Elementary - 06',
  'Grassland Elementary',
  'Grassland Middle School',
  'Grassy Creek Elementary School',
  'Grassy Fork Elementary',
  'Grassy Lake Elementary School',
  'Grassy Pond Elementary',
  'Grassy Waters Elementary School',
  'Gratigny Elementary School',
  'Grattan Elementary',
  'Gratton Charter',
  'Gratton Elementary',
  'Gratts Learning Academy For Young Scholars (glays)',
  'Gratz Brown Elem.',
  'Gravelly Hill Middle',
  'Gravenstein Community Day',
  'Gravenstein Elementary',
  'Gravenstein First',
  'Graveraet Elementary School',
  'Graves County Central Elementary',
  'Graves County High School',
  'Graves County Middle School',
  'Graves Elementary',
  'Graves Elementary School',
  'Gravette High School',
  'Gravette Middle School',
  'Gravette Upper Elementary',
  'Gravity High School',
  'Gray',
  'Gray Avenue Middle',
  'Gray Collegiate Academy',
  'Gray Court-owings Elementary/middle',
  'Gray El',
  'Gray Elem School',
  'Gray Elem.',
  'Gray Elementary School',
  'Gray M Sanborn Elem School',
  'Gray Middle School',
  'Gray Station Middle School',
  'Gray Stone Day',
  "Gray's Creek Elementary School",
  "Gray's Woods El Sch",
  'Gray-new Gloucester High School',
  'Gray-new Gloucester Middle School',
  'Grayhawk Elementary School',
  'Grayling Elementary School',
  'Grayling High School',
  'Grayling Middle School',
  'Graymont Elem School',
  'Grays Chapel Elementary',
  'Grays Creek Elementary',
  'Grays Creek High',
  'Grays Creek Middle',
  'Grays Harbor College',
  'Grays Harbor Juvenile Detention',
  'Grayside Elementary',
  'Grayslake Central High School',
  'Grayslake Early Childhood',
  'Grayslake Middle School',
  'Grayslake North High School',
  'Grayson Co J J A E P',
  'Grayson Co. Alternative Education',
  'Grayson College',
  'Grayson County Area Vocational Ed Center',
  'Grayson County High',
  'Grayson County High Career & Technical Education',
  'Grayson County High School',
  'Grayson County Middle School',
  'Grayson County Special Education Ssa',
  'Grayson Elementary',
  'Grayson Elementary School',
  'Grayson High School',
  'Grayson Highlands School',
  'Graystone Elementary',
  'Graysville Elementary School',
  'Grayville Jr Sr High School',
  'Grazide Elementary',
  'Greasewood Springs Community School Inc',
  'Greasy Public School',
  'Great Bay Community College',
  'Great Bay Elearning Charter School (h)',
  'Great Bay Elearning Charter School (m)',
  'Great Beginnings Early Ed Ctr.',
  'Great Beginnings Pre-school',
  'Great Bend High School',
  'Great Bend Middle School',
  'Great Bridge High',
  'Great Bridge Intermediate',
  'Great Bridge Middle',
  'Great Bridge Primary',
  'Great Brook School',
  'Great Expectations',
  'Great Expectations Academy',
  'Great Falls College Montana State University',
  'Great Falls Elementary',
  'Great Falls Elementary School',
  'Great Falls High',
  'Great Falls High School',
  'Great Falls Middle',
  'Great Futures Charter School',
  'Great Hearts Academies - Anthem Prep',
  'Great Hearts Academies - Archway Arete',
  'Great Hearts Academies - Archway Chandler',
  'Great Hearts Academies - Archway Cicero',
  'Great Hearts Academies - Archway Glendale',
  'Great Hearts Academies - Archway Lincoln',
  'Great Hearts Academies - Archway North Phoenix',
  'Great Hearts Academies - Archway Scottsdale',
  'Great Hearts Academies - Archway Trivium East',
  'Great Hearts Academies - Archway Trivium West',
  'Great Hearts Academies - Archway Veritas',
  'Great Hearts Academies - Arete Prep',
  'Great Hearts Academies - Chandler Prep',
  'Great Hearts Academies - Cicero Prep',
  'Great Hearts Academies - Glendale Prep',
  'Great Hearts Academies - Lincoln Prep',
  'Great Hearts Academies - Maryvale Prep',
  'Great Hearts Academies - North Phoenix Prep',
  'Great Hearts Academies - Scottsdale Prep',
  'Great Hearts Academies - Teleos Prep',
  'Great Hearts Academies - Trivium Prep',
  'Great Hearts Academies - Veritas Prep',
  'Great Hearts Irving',
  'Great Hearts Monte Vista',
  'Great Hearts Monte Vista North',
  'Great Hearts Northern Oaks',
  'Great Hollow Middle School',
  'Great Lakes Academy',
  'Great Lakes Academy Charter Es',
  'Great Lakes Bay Early College',
  'Great Lakes Cyber Academy',
  'Great Lakes Elementary',
  'Great Lakes Elementary School',
  'Great Lakes Online Education',
  'Great Lakes Online School',
  'Great Lakes University of Kisumu',
  'Great Meadows Regional Middle School',
  'Great Mills High',
  'Great Neck Elementary School',
  'Great Neck Middle',
  'Great Neck North High School',
  'Great Neck North Middle School',
  'Great Neck Road Elementary School',
  'Great Neck South High School',
  'Great Neck South Middle School',
  'Great Northern Elementary',
  'Great Northwest Area Career & Tech Center',
  'Great Oak',
  'Great Oak High',
  'Great Oak Middle School',
  'Great Oaks Academy',
  'Great Oaks Charter School',
  'Great Oaks Day Treatment Program',
  'Great Oaks El',
  'Great Oaks Elementary School',
  'Great Oaks Legacy Charter School',
  'Great Parents Great Start',
  'Great Partnership Special Education Consortium',
  'Great Path Academy At Mcc',
  'Great Plain School',
  'Great River Elementary School',
  'Great River School',
  'Great Salt Bay Community School',
  'Great Seneca Creek Elementary',
  'Great Start Readiness Program',
  'Great Valley Academy',
  'Great Valley Academy - Salida',
  'Great Valley Elementary',
  'Great Valley Hs',
  'Great Valley Ms',
  'Great Western Academy',
  'Great Western Elementary',
  'Great Work Montessori',
  'Great Zimbabwe University',
  'Greater Altoona Ctc',
  'Greater Binghamton Health Center',
  'Greater Bridgeport Community Mental Health Center',
  'Greater Brunswick Charter School',
  'Greater Egleston Community High School',
  'Greater Grace Charter Academy Inc.',
  'Greater Hartford Academy Of The Arts High School - Full Ti',
  'Greater Hartford Academy Of The Arts Magnet Middle',
  'Greater Heights Academy',
  'Greater Johnstown Ctc',
  'Greater Johnstown Ms',
  "Greater Johnstown School District's Cybe",
  'Greater Johnstown Shs',
  'Greater Lafayette Area Se',
  'Greater Latrobe Jhs',
  'Greater Latrobe Shs',
  'Greater Nanticoke Area Ed Ctr',
  'Greater Nanticoke Area Shs',
  'Greater Ohio Virtual School',
  "Greater Peninsula Governor's Stem Academy",
  'Greater Plains Elementary School',
  'Greater San Diego Academy',
  'Greater Summit County Early Learning Center',
  'Greathouse El',
  'Greathouse/shryock Traditional',
  'Greco Middle School',
  'Greeley Alternative School',
  'Greeley Central High School',
  'Greeley County Elem School',
  'Greeley County Jr./sr. High',
  'Greeley Elem',
  'Greeley Elem School',
  'Greeley Hill Elementary',
  'Greeley West High School',
  'Greeleyville Elementary',
  'Greely High School',
  'Greely Middle School',
  'Greely Middle School 4-5',
  'Green Acres El',
  'Green Acres Elementary',
  'Green Acres Elementary School',
  'Green Acres Middle',
  'Green Acres Middle School',
  'Green Acres School',
  'Green Bank Elementary-middle School',
  'Green Bay Avenue School',
  'Green Bay Correctional Institution',
  'Green Bay Early Childhood Center',
  'Green Bay Head Start',
  'Green Bay School',
  'Green Brook Middle School',
  'Green Canyon High School',
  'Green Central Park Elementary',
  'Green Charter School',
  'Green Charter School Of The Midlands',
  'Green City Elem.',
  'Green City High',
  'Green Co. Tech Jr. High School',
  'Green County Area Technology Center',
  'Green County High School',
  'Green County Intermediate School',
  'Green County Middle School',
  'Green County Primary School',
  'Green Cove Springs Junior High School',
  'Green El',
  'Green Elementary',
  'Green Elementary School',
  'Green Forest Elem.',
  'Green Forest Elementary School',
  'Green Forest High School',
  'Green Forest Intermed School',
  'Green Gables Elementary School',
  'Green Grove Elementary',
  'Green Grove Elementary School',
  'Green High School',
  'Green Hill Academic School',
  'Green Hills Elementary',
  'Green Hills Elementary School',
  'Green Hills School',
  'Green Holly Elementary School',
  'Green Hope Elementary',
  'Green Hope High',
  'Green Inspiration Academy',
  'Green Intermediate Elementary School',
  'Green Isle Community School',
  'Green Lake Elementary',
  'Green Lake Elementary School',
  'Green Lake High',
  'Green Magnet Math And Science Academy',
  'Green Meadow',
  'Green Meadow Elementary School',
  'Green Meadow School',
  'Green Meadows Elementary',
  'Green Meadows Intermediate Elem',
  'Green Middle School',
  'Green Mountain Elementary',
  'Green Mountain Elementary School',
  'Green Mountain High School',
  'Green Mountain School',
  'Green Mountain Technology And Career Center',
  'Green Mountain Uhsd #35',
  'Green Oaks Academy',
  'Green Oaks Fundamental Elementary',
  'Green Oaks Performing Arts Academy',
  'Green Park Elementary School',
  'Green Pastures Es',
  'Green Pines Elem.',
  'Green Point Elementary',
  'Green Primary Elementary School',
  'Green Primary School',
  'Green Rgc',
  'Green Ridge El Sch',
  'Green Ridge Elem.',
  'Green Ridge Elementary',
  'Green Ridge High',
  'Green River',
  'Green River Community College',
  'Green River Education Center',
  'Green River High',
  'Green River High School',
  'Green River School',
  'Green Run Collegiate',
  'Green Run Elementary',
  'Green Run High',
  'Green Sea Floyds Elementary',
  'Green Sea Floyds High',
  'Green Springs Elem',
  'Green Springs Elementary School',
  'Green Springs High School',
  'Green Street Academy',
  'Green Street School',
  'Green T. Lindon Elementary School',
  'Green Tech High Charter School',
  'Green Township Elementary School',
  'Green Trails Elem.',
  'Green Tree East Elementary',
  'Green Tree Elem.',
  'Green Tree Elementary',
  'Green University of Bangladesh',
  'Green Valley Academy At Edgar Allan Poe',
  'Green Valley Charter',
  'Green Valley El',
  'Green Valley El Sch',
  'Green Valley Elementary',
  'Green Valley Elementary School',
  'Green Valley High',
  'Green Valley High School',
  'Green Valley Independent Study',
  'Green Valley Middle',
  'Green W Elem School',
  'Green Woods Cs',
  "Green's Farms School",
  "Green's Farms Segregated",
  'Green-fields Elementary School',
  'Greenacres Elementary',
  'Greenacres Elementary School',
  'Greenacres Middle School',
  'Greenacres School',
  'Greenback School',
  'Greenbelt Elementary',
  'Greenbelt Middle',
  'Greenberg Joseph Sch',
  'Greenbrae Elementary School',
  'Greenbriar East Elementary',
  'Greenbriar El',
  'Greenbriar Elem School',
  'Greenbriar Elementary School',
  'Greenbriar Middle School',
  'Greenbriar West Elementary',
  'Greenbrier Alternative Learning Center',
  'Greenbrier East High School',
  'Greenbrier Eastside Elementary',
  'Greenbrier Elem School',
  'Greenbrier Elementary',
  'Greenbrier Elementary School',
  'Greenbrier High School',
  'Greenbrier Intermediate',
  'Greenbrier Junior High School',
  'Greenbrier Middle',
  'Greenbrier Middle School',
  'Greenbrier Primary',
  'Greenbrier School Of Practical Nursing',
  'Greenbrier Springhill Elementary School',
  'Greenbrier West High School',
  'Greenbrier Westside Elementary',
  'Greenbrier Wooster Elementary',
  'Greenbrook Elem School',
  'Greenbrook Elementary',
  'Greenbrook Elementary School',
  'Greenburgh Academy',
  'Greenburgh Eleven Elementary School',
  'Greenburgh Eleven High School',
  'Greenburgh Eleven Middle School',
  'Greenbush Elementary',
  'Greenbush Elementary School',
  'Greenbush-middle River Junior High',
  'Greenbush-middle River Secondary',
  'Greencastle Elementary',
  'Greencastle Middle School',
  'Greencastle Senior High School',
  'Greencastle-antrim El Sch',
  'Greencastle-antrim Ms',
  'Greencastle-antrim Pri Sch',
  'Greencastle-antrim Shs',
  'Greendale Elementary',
  'Greendale High',
  'Greendale Middle',
  'Greendale Middle School',
  'Greendell',
  'Greene Central High',
  'Greene Central School',
  'Greene Co. Tech High School',
  'Greene Co. Tech Interm. School',
  'Greene Co. Tech Primary School',
  'Greene County Career Center',
  'Greene County Ctc',
  'Greene County Elementary',
  'Greene County High School',
  'Greene County Intermediate',
  'Greene County Intermediate School',
  'Greene County Middle',
  'Greene County Middle School',
  'Greene County Technical Education Center',
  'Greene County Vocational Technical',
  'Greene Cty Tech Elem. School',
  'Greene Cty Tech Intermediate',
  'Greene Early College High',
  'Greene High School',
  'Greene Intermediate Center',
  'Greene Intermediate School',
  'Greene Middle School',
  'Greene N Elem School',
  'Greene Primary School',
  'Greene Valley School',
  'Greene-hills Goal',
  'Greene-hills School',
  'Greene-sullivan Sp Ed Sch',
  'Greeneview Elementary School',
  'Greeneview High School',
  'Greeneview Middle School',
  'Greeneville High School',
  'Greeneville Middle School',
  'Greeneville/greene Co Center For Technology',
  'Greenfield 7-8',
  'Greenfield Albert M Sch',
  'Greenfield Bilingual',
  'Greenfield Central Junior High Sch',
  'Greenfield Community',
  'Greenfield Community College',
  'Greenfield El',
  'Greenfield Elem School',
  'Greenfield Elem.',
  'Greenfield Elementary',
  'Greenfield Elementary School',
  'Greenfield High',
  'Greenfield High School',
  'Greenfield Intermediate School',
  'Greenfield Junior High School',
  'Greenfield Middle',
  'Greenfield Middle School',
  'Greenfield School',
  'Greenfield Union Elementarymiddle School',
  'Greenfield-central High School',
  'Greenford International University',
  'Greenglade Elementary School',
  'Greenhalge',
  'Greenhaven Elementary',
  'Greenheart Medical School',
  'Greenhill Elementary School',
  'Greenhills Elementary',
  'Greenhurst Elementary School',
  'Greenland Central School',
  'Greenland Elementary',
  'Greenland Elementary School',
  'Greenland High School',
  'Greenland Middle School',
  'Greenland Pines Elementary School',
  'Greenlawn Elementary School',
  'Greenlawn Terrace Elementary School',
  'Greenleaf El',
  'Greenleaf Elementary',
  'Greenleaf Kindergarten Center',
  'Greenleaf University',
  'Greenlee Attendance Center',
  'Greenlee Elementary School',
  'Greenlee Primary',
  'Greenlodge',
  'Greenman Elem School',
  'Greenmont Avenue',
  'Greenmont Elementary School',
  'Greenock El Sch',
  'Greenon High School',
  'Greenon Jr. High School',
  'Greenport Elementary School',
  'Greenport High School',
  'Greens Prairie El',
  'Greensboro Academy',
  'Greensboro Elementary',
  'Greensboro Elementary School',
  'Greensboro High School',
  'Greensboro Middle School',
  'Greensburg Community High School',
  'Greensburg Community Jr High',
  'Greensburg Elementary',
  'Greensburg-salem Hs',
  'Greensburg-salem Ms',
  'Greensferry Elementary School',
  'Greenspoint Ec/pk/kg School',
  'Greenspoint El',
  'Greensview Elementary School',
  'Greensville County High',
  'Greensville Elementary',
  'Greentown Intermediate School',
  'Greentree El',
  'Greentree Elementary',
  'Greentree Preparatory Charter School',
  'Greenup County Area Technology Center',
  'Greenup County High School',
  'Greenvale Es',
  'Greenvale Park Elementary',
  'Greenvale School',
  'Greenview Alternative School',
  'Greenview Elementary School',
  'Greenview Jr/sr High School',
  'Greenview Knolls Elementary',
  'Greenview Upper Elementary School',
  'Greenville Alternative Educational Program',
  'Greenville Consolidated School',
  'Greenville Elem School',
  'Greenville Elem.',
  'Greenville Elementary',
  'Greenville Elementary School',
  'Greenville Fundamental Elementary',
  'Greenville H S',
  'Greenville High',
  'Greenville High School',
  'Greenville Jr High School',
  'Greenville Jr. High',
  'Greenville Jshs',
  'Greenville Junior/senior High',
  'Greenville Middle',
  'Greenville Middle Academy',
  'Greenville Middle School',
  'Greenville Park Leadership Academy',
  'Greenville Primary School',
  'Greenville Public School',
  'Greenville School',
  'Greenville Senior High Academy',
  'Greenville Senior High School',
  "Greenville Superintendent's Academy",
  'Greenville Technical Center',
  'Greenville Technical Charter High School',
  'Greenville Technical College',
  'Greenway Elementary School',
  'Greenway High School',
  'Greenway Middle',
  'Greenway Middle School',
  'Greenway Park Elementary',
  'Greenway Primary School',
  'Greenway Senior High',
  'Greenways Int',
  'Greenwich Elementary School',
  'Greenwich High School',
  'Greenwich Junior-senior High School',
  'Greenwich School',
  'Greenwich University',
  'Greenwich-lenhartsville El Sch',
  'Greenwood',
  'Greenwood Academy',
  'Greenwood Alternative School',
  'Greenwood Charter School',
  'Greenwood Colony Elementary - 05',
  'Greenwood Community High Sch',
  'Greenwood Early Childhood Center',
  'Greenwood Early Learning Center',
  'Greenwood El',
  'Greenwood El Sch',
  'Greenwood Elem School',
  'Greenwood Elem.',
  'Greenwood Elementary',
  'Greenwood Elementary School',
  'Greenwood Es',
  'Greenwood Forest El',
  'Greenwood Freshman Center',
  'Greenwood H S',
  'Greenwood High',
  'Greenwood High School',
  'Greenwood Hills El',
  'Greenwood Hs',
  'Greenwood Junior High School',
  'Greenwood Lake Elementary School',
  'Greenwood Lake Middle School',
  'Greenwood Lakes Middle School',
  'Greenwood Middle',
  'Greenwood Middle School',
  'Greenwood Mill Elementary',
  'Greenwood Ms',
  'Greenwood Northeast Elementary Sch',
  'Greenwood School',
  'Greer Elementary',
  'Greer Elementary School',
  'Greer High',
  'Greer Middle',
  'Greer Middle College Charter High',
  'Greg Mathis Charter High',
  'Gregg Anderson Academy',
  'Gregg County Shared Services Daep',
  'Gregg El',
  'Gregg Middle',
  'Gregorio Luperon High Sch For Science And Mathematics',
  'Gregorio Rodriguez Orama',
  'Gregory B Jarvis Middle School',
  'Gregory Drive Elementary School',
  'Gregory Elem School',
  'Gregory Elementary - 02',
  'Gregory Elementary School',
  'Gregory Gardens Elementary',
  'Gregory Heights Elementary',
  'Gregory High School - 01',
  'Gregory Hill Preschool',
  'Gregory Intermediate',
  'Gregory Jocko Jackson-sports-arts',
  'Gregory Junior High - 04',
  'Gregory Math & Sci Elem Academy',
  'Gregory-lincoln Ed Ctr',
  'Gregory-portland H S',
  'Gregory-portland Int',
  'Gregory-portland J H',
  'Greiffenstein Alternative Elementary',
  'Grenada Elementary',
  'Grenada Elementary School',
  'Grenada Enrichment & Transition Cen',
  'Grenada High',
  'Grenada Middle School',
  'Grenada Upper Elementary School',
  'Grenada Voc Complex',
  'Grenloch Terrace Early Childhood Center',
  'Grenoble Ecole de Management',
  'Grenora Elementary School',
  'Grenora High School',
  'Gresham Arthur Academy',
  'Gresham Elem School',
  'Gresham Elementary',
  'Gresham Elementary School',
  'Gresham High',
  'Gresham High School',
  'Gresham Middle School',
  'Gretchen Everhart School',
  'Gretchen Higgins Elementary',
  'Gretchko Elementary School',
  'Gretna Elementary',
  'Gretna Elementary School',
  'Gretna High',
  'Gretna High School',
  'Gretna Middle',
  'Gretna Middle School',
  'Gretna No. 2 Academy For Advanced Studies',
  'Gretsa Universtiy',
  'Grewenow Elementary',
  'Grey Cloud Elementary',
  'Greybull Elementary',
  'Greybull High School',
  'Greybull Middle School',
  'Greycliff School',
  'Greyhills Academy High School',
  'Greyhound Central Elementary School',
  'Greyhound Central Performance Academy',
  'Greyhound Intermediate School',
  'Greylock',
  'Greynolds Park Elementary School',
  'Greysbranch Elementary School',
  'Greystone Elementary School',
  'Greystone Es',
  'Greystone Park Psychiatric Hospital',
  'Greystone School',
  'Greywolf Elementary School',
  'Gribbin School',
  'Gridley Elem',
  'Gridley Grade School',
  'Gridley High',
  'Gridley Middle School',
  'Gridley Street Elementary',
  'Gridley Unified Community Day (7-12)',
  'Griegos Elementary',
  'Griffin Avenue Elementary',
  'Griffin Bay School',
  'Griffin Creek Elementary School',
  'Griffin El',
  'Griffin Elementary School',
  'Griffin High School',
  'Griffin Home',
  'Griffin Memorial School',
  'Griffin Middle',
  'Griffin Middle School',
  'Griffin Middle School Academy',
  'Griffin School',
  'Griffith College',
  'Griffith El',
  'Griffith Elem',
  'Griffith Elem.',
  'Griffith Elementary',
  'Griffith Elementary School',
  'Griffith Institute High School',
  'Griffith Institute Middle School',
  'Griffith Middle',
  'Griffith Middle School',
  'Griffith Senior High School',
  'Griffith Thomas Elementary School',
  'Griffith University',
  'Griffiths Middle',
  'Grifton',
  'Griggs County Central Elementary School',
  'Griggs County Central High School',
  'Griggs Road Elementary',
  'Griggsville-perry High School',
  'Griggsville-perry Middle School',
  'Griggsville-perry Primary School',
  'Grigsby Intermediate Sch',
  'Grill Elementary School',
  'Grimes Education Center',
  'Grimes Elem School',
  'Grimes Es',
  'Grimes School',
  'Grimmway Academy',
  'Grimmway Academy Shafter',
  'Grimsley High',
  'Grimsrud Elementary School',
  'Grindstone Elementary School',
  'Grinnell College',
  'Grinnell Community Middle School',
  'Grinnell Community Senior High School',
  'Grinnell Grade School',
  'Grinnell Middle School',
  'Grinnell School',
  'Griscom Juvenile Detention Ctr',
  'Grissom El',
  'Grissom Elem School',
  'Grissom Elementary School',
  'Grissom Es',
  'Grissom Middle School',
  'Griswold Alternative School',
  'Griswold Elementary School',
  'Griswold High School',
  'Griswold Middle School',
  'Griswold Middle/high School',
  'Grizzly Challenge Charter',
  'Grizzly Hill',
  'Grodno State Agrarian University',
  'Grodno State Medical University',
  'Grodno State University Yanka Kupaly',
  'Groesbeck H S',
  'Groesbeck Middle',
  'Grogan Elementary School',
  'Grolee Elementary School',
  'Groner K-8',
  'Groom School',
  'Groppi High',
  'Gros Cap School',
  'Grosenbacher El',
  'Gross El',
  'Grosse Ile High School',
  'Grosse Ile Middle School',
  'Grosse Pointe North High School',
  'Grosse Pointe South High School',
  'Grossmont College',
  'Grossmont High',
  'Grossmont Middle College High',
  'Grossmont Secondary',
  'Groton Area Elementary - 02',
  'Groton Area High School - 01',
  'Groton Area Middle School - 04',
  'Groton Dunstable Regional',
  'Groton Dunstable Regional Middle',
  'Groton Elementary School',
  'Groton Junior/senior High School',
  'Groupe Sup de Co Amiens Picardie',
  'Groupe Sup de Co Montpellier',
  'Grouse Creek Elementary',
  'Grouse Creek Secondary',
  'Grout Elementary School',
  'Grove',
  'Grove Avenue Elem School',
  'Grove City Area Hs',
  'Grove City Area Ms',
  'Grove City High School',
  'Grove Elementary',
  'Grove Elementary School',
  'Grove Es',
  'Grove Hill Elementary School',
  'Grove Hill Preschool Academy',
  'Grove Hs',
  'Grove J. Tuttle School',
  'Grove Jr High School',
  'Grove Lower Es',
  'Grove Ms',
  'Grove Park Elementary',
  'Grove Park Elementary School',
  'Grove Park Preschool',
  'Grove Patterson Academy Elementary School',
  'Grove Public School',
  'Grove Street Academies',
  'Grove Street School',
  'Grove Upper Es',
  'Grove Valley Es',
  'Grovecenter Elementary',
  'Grovecrest School',
  'Groveland El Sch',
  'Groveland Elementary',
  'Groveland Elementary School',
  'Groveland Park Elementary',
  'Groveport Community School',
  'Groveport Elementary School',
  'Groveport Madison High School',
  'Groveport Madison Middle School Central',
  'Groveport Madison Middle School North',
  'Groveport Madison Middle School South',
  'Grover Beach Elementary',
  'Grover C Fields Middle',
  'Grover Cleveland Charter High',
  'Grover Cleveland El Sch',
  'Grover Cleveland Elementary School',
  'Grover Cleveland High School',
  'Grover Cleveland Middle School',
  'Grover Elementary',
  'Grover Heights Elementary',
  'Grover Hill Elementary School',
  'Groves El',
  'Groves High School',
  'Groves Middle',
  'Grovespring Elem.',
  'Groveton El',
  'Groveton Elementary',
  'Groveton Elementary School',
  'Groveton High School',
  'Groveton High School (middle)',
  'Groveton J H-h S',
  'Grovetown Elementary School',
  'Grovetown High School',
  'Grovetown Middle School',
  'Grow Academy',
  'Growing Up Green Charter School',
  'Growing Up Green Charter School Ii',
  'Growth Public',
  'Grps Adult Education',
  'Gruening Middle School',
  'Grulla El',
  'Grulla Middle',
  'Grundy Avenue School',
  'Grundy Center Elementary School',
  'Grundy Center High School',
  'Grundy Center Middle School',
  'Grundy Co. Elem.',
  'Grundy Co. High',
  'Grundy County High School',
  'Grundy Elem School',
  'Grundy High',
  'Grunsky Elementary',
  'Gruver El',
  'Gruver H S',
  'Gruver J H',
  'Grygla Elementary',
  'Grygla Secondary',
  'Gsl Junior High School',
  'Gst Spec Ed Unit',
  'Gt Norman Elementary School',
  'Gtcc Middle College High',
  'Gtec',
  'Guadalupe Alternative Programs',
  'Guadalupe Centers Elementary',
  'Guadalupe Centers High School',
  'Guadalupe Centers Middle',
  'Guadalupe County Correction',
  'Guadalupe El',
  'Guadalupe Elementary',
  'Guadalupe Elementary School',
  'Guadalupe Preschool',
  'Guadalupe School',
  'Guajome Learning Center',
  'Guajome Park Academy Charter',
  'Guangdong Peizheng College',
  'Guangdong Polytechnic Normal University',
  'Guangdong Radio & TV University',
  'Guangdong University of Foreign Studies',
  'Guangdong University of Technology',
  'Guangxi Medical University',
  'Guangxi Normal University',
  'Guangxi Traditional Chinese Medical University',
  'Guangxi University',
  'Guangxi University for Nationalities',
  'Guangzhou Academy of Fine Art',
  'Guangzhou Normal University',
  'Guangzhou University',
  'Guangzhou University of Traditional Chinese Medicine',
  'Guardian Angel',
  'Guardino Elementary School',
  'Gubkin Russian State University of Oil and Gas',
  'Gubser Elementary School',
  'Gudith Elementary School',
  'Guerneville Elementary',
  'Guernsey-sunrise Elementary',
  'Guernsey-sunrise High School',
  'Guernsey-sunrise Junior High',
  'Guerra El',
  'Guerrero El',
  'Guerrero Elementary School',
  'Guerrero Thompson',
  'Guess El',
  'Gueydan High School',
  'Guffey Charter School',
  'Guggenheim Elementary School',
  'Guidance Center',
  'Guidance Charter',
  'Guideway Elementary',
  'Guilan University',
  'Guilan University of Medical Sciences',
  'Guild Elementary',
  'Guilderland Elementary School',
  'Guilderland High School',
  'Guilford Central School',
  'Guilford College',
  'Guilford Elementary',
  'Guilford Elementary School',
  'Guilford Elementary/middle',
  'Guilford High School',
  'Guilford Hills El Sch',
  'Guilford Lakes School',
  'Guilford Preparatory Academy',
  'Guilford Technical Community College',
  'Guillen Middle',
  'Guillermina Rosado De Ayala',
  'Guillermo Flores El',
  'Guillermo Gonzalez (playita)',
  'Guillermo Riefkhol',
  'Guilmette Middle School',
  'Guin Elementary School',
  'Guin Foss Elementary',
  'Guinn (james M.) Elementary',
  'Guinyard-butler Middle',
  'Guion Creek Elementary School',
  'Guion Creek Middle School',
  'Guiteras School',
  'Guizhou Normal University',
  'Guizhou University',
  'Gujarat Ayurved University',
  'Gujarat Technological University Ahmedabad',
  'Gujarat University Ahmedabad',
  'Gulbarga University',
  'Gulf Avenue Elementary',
  'Gulf Beaches Elementary Magnet School',
  'Gulf Breeze Elementary School',
  'Gulf Breeze High School',
  'Gulf Breeze Middle School',
  'Gulf Coast Academy Of Science And Technology',
  'Gulf Coast Charter Academy South',
  'Gulf Coast High School',
  'Gulf Coast Middle School',
  'Gulf County Adult School',
  'Gulf Elementary School',
  'Gulf Gate Elementary School',
  'Gulf High Adult Education',
  'Gulf High School',
  'Gulf Highlands Elementary School',
  'Gulf Medical University',
  'Gulf Middle School',
  'Gulf Shores Elementary School',
  'Gulf Shores High School',
  'Gulf Shores Middle School',
  'Gulf Trace Elementary School',
  'Gulf University College',
  'Gulf University for Science and Technology',
  'Gulf Virtual Franchise',
  'Gulfport Central Middle School',
  'Gulfport High School',
  'Gulfport Montessori Elementary School',
  'Gulfport Vocational Annex',
  'Gulfside Elementary School',
  'Gulfstream Academy Of Hallandale Beach',
  'Gulfstream Early Childhood Center Of Excellence',
  'Gulfstream Elementary School',
  'Gulfstream L.i.f.e. Academy',
  'Gulfview Middle School',
  'Gulhane military medical Academy',
  'Gull Lake Gateway Academy',
  'Gull Lake High School',
  'Gull Lake Middle School',
  'Gull Lake Virtual School',
  'Gullatt Elementary School',
  'Gulledge El',
  'Gullett El',
  'Gulu University',
  'Gum Springs Elementary School',
  'Gumushane University',
  'Gunderson High',
  "Gunma Prefectural Women's University",
  'Gunma University',
  'Gunn J H',
  'Gunnison Elementary School',
  'Gunnison High School',
  'Gunnison Middle School',
  'Gunnison Valley High',
  'Gunnison Valley Middle',
  'Gunnison Valley School',
  'Gunpowder Elementary',
  'Gunsaulus Elem Scholastic Academy',
  'Gunston Elementary',
  'Gunston Middle',
  'Gunstream El',
  'Gunter El',
  'Gunter H S',
  'Gunter Middle',
  'Guntersville Elementary School',
  'Guntersville High School',
  'Guntersville Middle School',
  'Guntown Middle School',
  'Gurdon High School',
  'Gurdon Primary School',
  'Gurney Elementary School',
  'Guru Ghasidas University',
  'Guru Gobind Singh Indraprastha University',
  'Guru Jambeshwar University',
  'Guru Nanak Dev University',
  'Gurukul University',
  'Gurukula Kangri University',
  'Gus Birdwell El',
  'Gus Franklin Jr. School',
  'Gus Garcia Middle',
  'Gus Harrison Correctional Facility',
  'Gus Winston Cain El',
  'Gussie M. Baker Elementary',
  'Gust Elementary School',
  'Gustav H Webling Elementary School',
  'Gustav-Siewerth-Akademie',
  'Gustavo A. Becquer',
  'Gustavus Adolphus College',
  'Gustavus School',
  'Gustine Elementary',
  'Gustine High',
  'Gustine Middle',
  'Gustine School',
  'Gusty Michael School',
  'Gutermuth Elementary',
  'Guthridge School',
  'Guthrie Center Elementary School',
  'Guthrie Center High School',
  'Guthrie Hs',
  'Guthrie Jhs',
  'Guthrie School',
  'Guthrie Upper Es',
  'Gutierrez Middle',
  'Guy B Teachey Elementary',
  'Guy E Rowe School',
  'Guy Jr. Emanuele Elementary',
  'Guy K. Stump Elementary',
  'Guy Lee Elementary School',
  'Guy-perkins Elementary School',
  'Guy-perkins High School',
  'Guyan Valley Middle',
  'Guyandotte Elementary School',
  'Guyer H S',
  'Guymon Hs',
  'Guyton Elementary School',
  'Guzman El',
  'Gw Career Academy',
  'Gw Carver Elementary',
  'Gw Long Elementary School',
  'Gwendolyn Brooks Elem Sch',
  'Gwendolyn Brooks Elementary',
  'Gwendolyn Brooks Middle School',
  'Gwendolyn Woolley Elementary School',
  'Gwin Elementary School',
  'Gwin Oaks Elementary School',
  'Gwinn Middlehigh School',
  'Gwinnett Intervention Education (give) Center East',
  'Gwinnett Intervention Education Center (give) West',
  'Gwinnett Online Campus',
  'Gwinnett School Of Mathematics- Science And Technology',
  'Gwinnett Technical College',
  'Gwyn-nor El Sch',
  'Gwynedd Square El Sch',
  'Gwynn Park High',
  'Gwynn Park Middle',
  'Gwynns Falls Elementary',
  'Gyeongsang National University',
  'Gypsum Creek Middle School',
  'Gypsum Elementary School',
  'Gypsy Public School',
  'Göteborg University',
  'H & M Potter School',
  'H Ashton Marsh',
  'H B Emery Jr Memorial School',
  'H B Gonzalez El',
  'H B Mattlin Middle School',
  'H B Sugg Elementary',
  'H B Thompson Middle School',
  'H B Zachry El',
  'H Barbara Booker Elementary School',
  'H Bob Daniel Sr Int',
  'H C Burton School',
  'H C Carleston El',
  'H C Crittenden Middle School',
  'H C Davis Elementary',
  'H C Storm Elem School',
  'H C Williams Senior High School',
  'H Councill Trenholm State Technical College',
  'H D Cooke Es',
  'H D Crull Elementary School',
  'H D Hilley El',
  'H D Staples El',
  'H D Woodson Hs',
  'H E L P Marshall',
  'H E L P Sullivan',
  'H E White Elementary School',
  'H F EPSTEIN HEBREW ACADEMY',
  'H F Mcneill El',
  'H F Stevens Middle',
  'H Frank Carey High School',
  'H G Blake Elementary School',
  'H G Temple El',
  'H G Temple Int',
  'H Grady Spruce H S',
  'H Guy Child School',
  'H H Beam Elementary',
  'H H Conrady Jr High School',
  'H H Galligan',
  'H I Holland El At Lisbon',
  'H J Macdonald Middle',
  'H Kenneth Kirchner School',
  'H L Harshman Middle School',
  'H L Higgins El',
  'H L Richards High Sch(campus)',
  'H L Suverkrup Elementary School',
  'H L Trigg Community',
  'H M Carroll El',
  'H M King H S',
  'H Maynard Middle School',
  'H O Whitehurst El',
  'H Olive Day',
  'H P Fitzgerald Elementary School',
  'H R Mccall Elem School',
  'H R Moye El',
  'H S #7',
  'H S Ahead Academy',
  'H S For Law And Justice',
  'H S Point',
  'H T Faulk Early Childhood School',
  'H T Jones El',
  'H W Good El Sch',
  'H W Mountz Elementary School',
  'H W Schulze El',
  'H W Wilkey Elementary School',
  'H-l-v Elementary School',
  'H-l-v Junior-senior High School',
  'H. A. Hyde Elementary',
  'H. A. Snow Elementary',
  'H. Allen Hight Elementary',
  'H. B. Rhame Elementary',
  'H. B. Swofford Career Center',
  'H. Byron Masterson Elem.',
  'H. C. Wilcox Technical High School',
  'H. Clarke Powers Elementary',
  'H. E. Corley Elementary',
  'H. E. Mccracken Middle',
  'H. Frank Dominguez Elementary',
  'H. G. Hill Middle',
  'H. Glenn Richardson',
  'H. H. Ellis Technical High School',
  'H. H. Garnett Elementary',
  'H. L. Bourgeois High School',
  'H. L. Johnson Elementary School',
  'H. O. Brittingham Elementary School',
  'H. Russel Swift Elementary School',
  'H. S. Chase School',
  'H. S. Moody Elementary School',
  'H. W. Byers Elementary (k-5)',
  'H. W. Byers High School (9-12)',
  'H. W. Byers Middle School (6-8)',
  'H. W. Harkness Elementary',
  'H. W. Wheatley Early Childhood Center',
  'H. Y. Livesay Middle School',
  'H.O.P.E. ACADEMY',
  'H.O.P.E. ACADEMY, INC.',
  'H.b. Lawrence School',
  'H.b. Price Elementary School',
  'H.e.a.r.t. High School',
  'H.h. Poole Middle',
  'H.l. Lubker Elementary School',
  'H.m. Pearson Elementary',
  'H.o.m.e. Program',
  'H.s. Truman Elementary School',
  'H.t. Edwards Learning Center Facility',
  "HA'OR BEACON SCHOOL",
  "HA'PENNY MONTESSORI SCHOOL",
  'HACIENDA CHILD DEVELOPMENT CENTER',
  'HACIENDA SCHOOL',
  'HADAR SOUTH FLORIDA HIGH SCHOOL FOR GIRLS',
  'HADDONFIELD FRIENDS SCHOOL',
  'HADI SCHOOL OF EXCELLENCE',
  'HADLEY SCHOOL',
  'HAFTR MIDDLE SCHOOL',
  'HAGERSTOWN CHILDRENS SCHOOL',
  'HAHNSTOWN MENNONITE SCHOOL',
  'HAILI CHRISTIAN SCHOOL',
  'HALE O ULU SCHOOL',
  'HALEAKALA WALDORF SCHOOL',
  'HALES CORNERS LUTHERAN SCHOOL',
  'HALES FRANCISCAN HIGH SCHOOL',
  'HALIFAX ACADEMY',
  'HALIFAX CHRISTIAN SCHOOL',
  'HALL INSTITUTE FOR THE ARTS',
  'HALLMARK YOUTHCARE RICHMOND',
  'HALSTOM ACADEMY',
  'HALSTROM ACADEMY',
  'HALSTROM ACADEMY - NEWPORT BEACH',
  'HALSTROM ACADEMY - WALNUT CREEK',
  'HALSTROM ACADEMY - WESTLAKE VILLAGE',
  'HALSTROM ACADEMY- HUNTINGTON BEACH',
  'HALSTROM ACADEMY-SAN DIEGO',
  'HALSTROM ACADEMY-WOODLAND HILLS',
  'HALSTROM ADADEMY-ANAHEIM HILLS CAMPUS',
  'HAMDEN HALL COUNTRY DAY SCHOOL',
  'HAMILTON ACADEMY',
  'HAMILTON CHRISTIAN ACADEMY',
  'HAMILTON HEIGHTS CHRISTIAN ACADEMY',
  'HAMILTON PARK MONTESSORI SCHOOL',
  'HAMLET PRESBYTERIAN WEEKDAY SCHOOL',
  'HAMLIN ROBINSON SCHOOL',
  'HAMLIN SCHOOL',
  'HAMMITT SCHOOL - THE BABY FOLD',
  'HAMMOND BAPTIST HIGH SCHOOL',
  'HAMPDEN CHRISTIAN SCHOOL',
  'HAMPDEN DUBOSE ACADEMY',
  'HAMPSHIRE COUNTRY SCHOOL',
  'HAMPSTEAD ACADEMY',
  'HAMPTON ACADEMY',
  'HAMPTON FIRST BAPTIST ACADEMY',
  'HAMPTON LUTHERAN SCHOOL',
  'HAMPTON PARK CHRISTIAN SCHOOL',
  'HAMPTON ROADS ACADEMY',
  'HAMPTON ROADS INTERNATIONAL MONTESSORI SCHOOL',
  'HAMZA ACADEMY',
  'HAMZAH SCIENCE ACADEMY',
  'HANAHAUOLI SCHOOL',
  'HANALANI SCHOOLS',
  'HANC - MIDDLE & HIGH SCHOOL',
  'HANC - WEST HEMPSTEAD',
  'HANCOCK DAY SCHOOL',
  'HAND IN HAND CHRISTIAN MONTESSORI',
  'HANDPRINT ACADEMY',
  'HANDS-ON MONTESSORI SCHOOL',
  'HANFORD CHRISTIAN SCHOOL',
  'HANGER HALL SCHOOL',
  'HANNA PERKINS SCHOOL',
  'HANNA SACKS BAIS YAAKOV HIGH SCHOOL',
  'HANOVER ACADEMY',
  'HANOVER MENNONITE SCHOOL',
  'HANSEL & GRETEL EARLY LEARNING CENTER',
  'HANSON MEMORIAL HIGH SCHOOL',
  'HAPPINESS HILL CHRISTIAN ACADEMY',
  "HAPPY 'R' WEE",
  'HAPPY DAY CHRISTIAN CHILD CARE',
  'HAPPY DAY LEARNING CENTER',
  'HAPPY DAY SCHOOL',
  'HAPPY DAYS LEARNING CENTER',
  'HAPPY FACES ACADEMY LLC',
  'HAPPY HANDS EDUCATION CENTER',
  'HAPPY HEARTS LEARNING CENTER',
  "HAPPY HOLLOW CHILDREN'S CENTER",
  'HAPPY HOME DAYCARE',
  'HAPPY KIDS PRIVATE SCHOOL',
  'HAPPY MEMORIES LEARNING CENTER 2',
  'HAPPY MEMORIES LEARNING CENTER CORP',
  'HAPPY MONTESSORI SCHOOL',
  'HAPPY PRESCHOOL LAND',
  'HAPPY TOTS MONTESSORI SCHOOL',
  'HAPPY VALLEY MONTESSORI SCHOOL',
  'HAPPYLAND DAY SCHOOL',
  'HARAMBEE CHRISTIAN SCHOOL',
  'HARAMBEE PREPARATORY SCHOOL',
  'HARBOR CHRISTIAN SCHOOLS',
  'HARBOR COUNTRY DAY SCHOOL',
  'HARBOR DAY SCHOOL',
  'HARBOR HOUSE CENTER EARLY ACADEMICS',
  'HARBOR LIGHT CHRISTIAN SCHOOL',
  'HARBOR SCHOOL',
  'HARBORCREEK YOUTH SERVICES',
  'HARBOUR OAKS MONTESSORI SCHOOL',
  'HARBOUR POINTE MONTESSORI SCHOOL',
  'HARBOUR SCHOOL AT BALTIMORE',
  'HARDING ACADEMY',
  'HARDING ACADEMY WHITE STATION',
  'HAREST CHRISTIAN SCHOOL',
  'HARFORD CHRISTIAN SCHOOL',
  'HARFORD DAY SCHOOL',
  'HARGRAVE MILITARY ACADEMY',
  'HARKHAM HILLEL HEBREW ACADEMY',
  'HARKHAM-GAON ACADEMY',
  'HARLAN CHRISTIAN SCHOOL',
  'HARLAN COUNTY CHRISTIAN SCHOOL',
  'HARLEM ACADEMY',
  'HARLEYSVILLE LEARNING CENTER',
  'HARMON STEM SCHOOL',
  'HARMONY CHRISTIAN SCHOOL',
  'HARMONY HEIGHTS SCHOOL',
  'HARMONY HILL SCHOOL',
  'HARMONY MONTESSORI CENTER',
  'HARMONY MONTESSORI SCHOOL',
  'HARMONY RIDGE SCHOOL',
  'HARMONY SCHOOL',
  'HARMONY SCHOOL AT PRINCETON',
  'HARPETH HALL SCHOOL',
  'HARRAH COMMUNITY CHRISTIAN SCHOOL',
  'HARRELLS CHRISTIAN ACADEMY',
  'HARRIET EISMAN COMMUNITY SCHOOL',
  'HARRIET TUBMAN PRESCHOOL & ELEMENTARY',
  'HARRIS JUNIOR ACADEMY',
  'HARRIS SCHOOL',
  'HARRISBURG ACADEMY',
  'HARRISBURG ADVENTIST SCHOOL',
  'HARRISBURG CATHOLIC ELEMENTARY SCHOOL',
  'HARRISBURG CATHOLIC ELEMENTRY SCHOOL',
  'HARRISBURG CHRISTIAN SCHOOL',
  'HARRISBURG DAY SCHOOL',
  'HARRISON SEVENTH-DAY ADVENTIST SCHOOL',
  'HARRISONVILLE CHRISTIAN SCHOOL',
  'HARRISTOWN SCHOOL',
  'HART 2 HART ACADEMY INC',
  'HARTFORD AREA SDA SCHOOL',
  'HARTLAND CHRISTIAN SCHOOL',
  'HARTMAN STATION AMISH SCHOOL',
  'HARVARD ACADEMY',
  'HARVARD PRESCHOOL & KINDERGARTEN',
  'HARVARD-WESTLAKE',
  'HARVARD-WESTLAKE SCHOOL',
  'HARVEST BAPTIST CHRISTIAN ACADEMY',
  'HARVEST BAPTIST SCHOOL',
  'HARVEST CHRISTIAN ACADEMY',
  'HARVEST CHRISTIAN ACADEMY - WILMINGTON',
  'HARVEST CHRISTIAN ACADEMY FOR THE DEAF',
  'HARVEST CHRISTIAN SCHOOL',
  'HARVEST COMMUNITY SCHOOL',
  'HARVEST HILLS CHRISTIAN SCHOOL',
  'HARVEST JUBILEE CHRISTIAN ACADEMY',
  'HARVEST LANE AMISH SCHOOL',
  'HARVEST LANE SCHOOL',
  'HARVEST PREPARATORY SCHOOL',
  'HARVEST RAIN ACADEMY',
  'HARVEST TEMPLE CHRISTIAN ACADEMY',
  'HARVEST TIME ACADEMY',
  'HARVEST TIME CHRISTIAN ACADEMY',
  'HARVEST TIME CHRISTIAN SCHOOL',
  'HARVEST TIME INTERNATIONAL ACADEMY',
  'HARVESTER CHRISTIAN ACADEMY',
  'HARVEY BROWNE PRESCHOOL',
  'HASC - HEBREW ACADEMY FOR SPECIAL CHILDREN',
  'HASTEN HEBREW ACADEMY',
  'HATHAWAY BROWN SCHOOL',
  'HAVENWOOD CHURCH PRESCHOOL CENTER',
  'HAVENWYCK SCHOOL',
  'HAVERHILL BAPTIST DAY SCHOOL',
  'HAVERN SCHOOL',
  'HAW RIVER CHRISTIAN ACADEMY',
  'HAWAII BAPTIST ACADEMY',
  'HAWAII PREPARATORY ACADEMY',
  'HAWAIIAN MISSION ACADEMY',
  'HAWAIIAN MISSION ACADEMY KA LAMA IKI',
  'HAWAIIAN MISSION ACADEMY WINWARD CAMPUS',
  'HAWARDEN HILLS ACADEMY',
  'HAWK MEADOW MONTESSORI SCHOOL',
  'HAWKEN SCHOOL',
  'HAWKSWOOD SCHOOL',
  'HAWPATCH SCHOOL',
  'HAWTHORNE CHRISTIAN ACADEMY',
  'HAWTHORNE COUNTRY DAY SCHOOL',
  'HAWTHORNE MONTESSORI SCHOOL',
  'HAWTHORNE VALLEY SCHOOL',
  'HAYCREEK SCHOOL',
  'HAYDEN CATHOLIC HIGH SCHOOL',
  'HAYDEN CHILD CARE CENTER',
  'HAYFIELD MONTESSORI SCHOOL',
  'HAYMARKET BAPTIST CHURCH PRESCHOOL & KINDERGARTEN',
  'HAYNSWORTH SCHOOL',
  'HAYS MARANATHA CHRISTIAN SCHOOL',
  'HAYWORTH CHRISTIAN SCHOOL',
  'HAZEL DELL SCHOOL',
  'HAZELWOOD AMISH SCHOOL',
  'HAZLETON TRINITY NURSERY & KINDERGARTEN SCHOOL',
  'HEAD ROYCE SCHOOL',
  'HEADSUP CHILD DEVELOPMENT CENTER PALO ALTO',
  'HEADSUP! CHILD DEVELOPMENT CENTER',
  'HEADWATERS ACADEMY',
  'HEALING ARTS INSTITUTE OF SOUTH FLORIDA',
  'HEALING WATERS CHRISTIAN ACADEMY',
  'HEALY-MURPHY CENTER',
  'HEART CENTERED MULTI-AGE',
  'HEART PINE SCHOOL',
  'HEART PRINTS EARLY EDUCATION CENTER',
  'HEART TO HEART CHRISTIAN ACADEMY',
  'HEART TO HEART CHRISTIAN ACADEMY & CHILDCARE',
  'HEARTHSTONE SCHOOL',
  'HEARTLAND CHRISTIAN',
  'HEARTLAND CHRISTIAN ACADEMY',
  'HEARTLAND CHRISTIAN SCHOOL',
  'HEARTLAND CHRISTIAN SCHOOL & PRESCHOOL',
  'HEARTLAND LUTHERAN HIGH SCHOOL',
  'HEARTLAND MONTESSORI SCHOOL',
  'HEARTLAND SCHOOL',
  'HEARTWOOD MONTESSORI SCHOOL',
  'HEATHROW CHRISTIAN ACADEMY',
  'HEATHWOOD HALL EPISCOPAL SCHOOL',
  "HEAVEN'S TREASURES ACADEMY",
  'HEAVENLY HOST LUTHERAN SCHOOL',
  'HEAVENLY VISION EDUCATION CENTER',
  'HEBREW ACADEMY',
  'HEBREW ACADEMY COMMUNITY SCHOOL',
  'HEBREW ACADEMY FIVE TOWNS ROCKAWAY HIGH',
  'HEBREW ACADEMY FOR SPECIAL',
  'HEBREW ACADEMY OF CLEVELAND',
  'HEBREW ACADEMY OF NASSAU-PLAINVIEW CAMPUS',
  'HEBREW ACADEMY OF TIDEWATER',
  'HEBREW ACADEMY-LOWER SCHOOL',
  'HEBREW DAY SCHOOL OF ANN ARBOR',
  'HEBREW DAY SCHOOL OF SULLIVAN',
  'HEBREW HIGH SCHOOL OF NEW ENGLAND',
  'HEBRON ACADEMY',
  'HEBRON CENTER CHRISTIAN SCHOOL',
  'HEBRON CHRISTIAN ACADEMY',
  'HEICHAL HATORAH',
  'HEIDELBERG ACADEMY',
  'HEIDI HO KINDERGARTEN & PRESCHOOL',
  'HEIGHTS CHRISTIAN SCHOOLS-BLUEFIELD CAMPUS',
  'HEIRWAY CHRISTIAN ACADEMY',
  'HELDERBERG CHRISTIAN SCHOOL',
  'HELENA CHRISTIAN SCHOOL',
  'HELIAS CATHOLIC HIGH SCHOOL',
  'HELIOS NEW SCHOOL',
  'HELLENIC AMERICAN ACADEMY',
  'HELLO MONTESSORI PRESCHOOL & KINDERGARTEN',
  'HELPING HAND SCHOOL',
  'HEMINGWAY DAY SCHOOL',
  'HENDERSON INTERNATIONAL SCHOOL',
  'HENDERSONVILLE BIBLE KINDERGARTEN',
  'HENDERSONVILLE CHRISTIAN ACADEMY',
  'HENNESSY CATHOLIC SCHOOL',
  'HENRIETTA G LEWIS CAMPUS SCHOOL',
  'HEP Vaud - Haute école pédagogique du canton de Vaud',
  'HEP-BEJUNE - Haute Ecole Pédagogique BEJUNE',
  'HEP-PH FR - University of Teacher Education Fribourg',
  'HEPHATHA LUTHERAN SCHOOL',
  'HEPTON SCHOOL',
  'HEPVS - Haute école pédagogique du Valais',
  'HERE WE GROW CHILD DEVELOPMENT CENTER',
  'HERIAGE CHRISTIAN ACADEMY',
  'HERITAGE ACADEMY',
  'HERITAGE ADVENTIST SCHOOL',
  'HERITAGE BAPTIST ACADEMY',
  'HERITAGE BAPTIST CHRISTIAN SCHOOL',
  'HERITAGE CHRISTIAN ACADEMY',
  'HERITAGE CHRISTIAN ACADEMY - MT. JULIET',
  'HERITAGE CHRISTIAN SCHOOL',
  'HERITAGE CHRISTIAN SCHOOL INC',
  'HERITAGE CHRISTIAN SCHOOL-LOWER',
  'HERITAGE CHRISTIAN SCHOOLS',
  'HERITAGE CLASSICAL CHRISTIAN ACADEMY',
  'HERITAGE HALL',
  'HERITAGE HALL CHRISTIAN SCHOOL',
  'HERITAGE HILLS EARLY LEARNING CENTER',
  'HERITAGE LUTHERAN SCHOOL',
  'HERITAGE MONTESSORI',
  'HERITAGE MONTESSORI ACADEMY OF ALLEN',
  'HERITAGE MONTESSORI OF MURPHY',
  'HERITAGE MONTESSORI SCHOOL',
  'HERITAGE OAK PRIVATE EDUCATION',
  'HERITAGE OAK SCHOOL',
  'HERITAGE ONLINE ACADEMY',
  'HERITAGE PREPARATORY ACADEMY',
  'HERITAGE PREPARATORY SCHOOL OF GA',
  'HERITAGE PRIVATE SCHOOL INC.',
  'HERITAGE REFORMED CHRISTIAN SCHOOL',
  'HERITAGE SCHOOL',
  'HERMISTON CHRISTIAN CENTER & SCHOOL',
  'HERMISTON JUNIOR ACADEMY',
  'HERMITAGE HALL',
  'HERNANDO BAPTIST KINDERGARTEN',
  'HERNANDO CHRISTIAN ACADEMY',
  'HERON POND MONTESSORI SCHOOL',
  'HERONFIELD ACADEMY',
  'HERSHORIN SCHIFF DAY SCHOOLS OF TOMORROW INC DBA G',
  'HES-SO : UAS Western Switzerland',
  'HESPENA CHRISTIAN SCHOOL',
  'HESS ACADEMY',
  'HEUSER HEARING & LANGUAGE ACADEMY',
  'HEZION ACADEMY',
  'HI TECH KIDZ KOLLEGE',
  'HI-STAR CENTER FOR CHILDREN',
  'HIALEAH HARVEST PRIVATE SCHOOL',
  'HICKORY AMISH MENNONITE SCHOOL',
  'HICKORY CHRISTIAN ACADEMY',
  'HICKORY DAY SCHOOL',
  'HICKORY GROVE',
  'HICKORY GROVE CHRISTIAN SCHOOL',
  'HICKORY GROVE SCHOOL',
  'HICKORY HILL',
  'HICKORY HILL SCHOOL',
  'HICKORY HOLLOW',
  'HICKORY HOLLOW CHRISTIAN SCHOOL',
  'HICKORY HOLLOW SCHOOL',
  'HICKORY KNOLL SCHOOL',
  'HICKORY LANE SCHOOL',
  'HICKORY RIDGE',
  'HICKORY RIDGE ACADEMY',
  'HICKORY RIDGE AMISH SCHOOL',
  'HICKORY TREE SCHOOL',
  'HICKORY VALLEY CHRISTIAN SCHOOL',
  'HIDDEN ACRES',
  'HIDDEN ACRES SCHOOL/DAVID BEILER',
  'HIDDEN MEADOW',
  'HIDDEN TREASURE CHRISTIAN SCHOOL',
  'HIDDEN TREASURE PRESCHOOL CHRISTIAN ACADEMY',
  'HIDDEN VALLEY AMISH PAROCHIAL SCHOOL',
  'HIDDEN VALLEY SCHOOL',
  'HIDDEN VIEW',
  'HIFZ ACADEMY',
  'HIGDON HILL SCHOOL',
  'HIGH ACRES SCHOOL',
  'HIGH BLUFF ACADEMY',
  'HIGH EXPECTATIONS EDUCATION CENTER',
  'HIGH FRONTIER SCHOOL',
  'HIGH MEADOW SCHOOL',
  'HIGH MEADOWS SCHOOL',
  'HIGH MOUNTAIN INSTITUTE',
  'HIGH MOWING SCHOOL',
  'HIGH PLAINS CHRISTIAN SCHOOL',
  'HIGH POINT CHRISTIAN ACADEMY',
  'HIGH POINT FRIENDS SCHOOL',
  'HIGH POINT PAROCHIAL SCHOOL',
  'HIGH ROAD ACADEMY',
  'HIGH ROAD SCH OF NEW LONDON PRIM AT BENNIE DOVER',
  'HIGH ROAD SCHOOL OF ANNE ARUNDEL COUNTY',
  'HIGH ROAD SCHOOL OF BALTIMORE COUNTY',
  'HIGH ROAD SCHOOL OF BLOOMINGTON',
  'HIGH ROAD SCHOOL OF BOONE COUNTY',
  'HIGH ROAD SCHOOL OF DELAWARE',
  'HIGH ROAD SCHOOL OF HARFORD COUNTY',
  'HIGH ROAD SCHOOL OF NEW LONDON MD/HIGH AT SHILOH',
  'HIGH ROAD SCHOOL OF PROVIDENCE',
  'HIGH ROAD SCHOOL OF SOUTHERN MARYLAND',
  'HIGH ROAD SCHOOL OF WALLINGFORD -HARTFORD PRIMARY',
  'HIGH ROAD UPPER SCHOOL',
  'HIGH VALLEY CHRISTIAN SCHOOL',
  'HIGHLAND ACADEMY',
  'HIGHLAND ADVENTIST SCHOOL',
  'HIGHLAND AMISH SCHOOL',
  'HIGHLAND BAPTIST CHRISTIAN SCHOOL',
  'HIGHLAND CATHOLIC SCHOOL',
  'HIGHLAND CHRISTIAN ACADEMY',
  'HIGHLAND CHRISTIAN SCHOOL',
  'HIGHLAND CHURCH MENNONITE',
  'HIGHLAND ELEMENTARY SCHOOL',
  'HIGHLAND HALL WALDORF SCHOOL',
  'HIGHLAND PARK CHRISTIAN ACADEMY',
  'HIGHLAND PARK KINDERGARTEN',
  'HIGHLAND PARK MONTESSORI',
  'HIGHLAND PARK MONTESSORI SCHOOL',
  'HIGHLAND PARK PRESBYTERIAN DAY SCHOOL',
  'HIGHLAND PARK SCHOOL',
  'HIGHLAND PRES. NURSERY AND WEEKDAY SCHOOL',
  'HIGHLAND RIM ACADEMY',
  'HIGHLAND SCHOOL',
  'HIGHLAND VIEW CHRISTIAN SCHOOL',
  'HIGHLANDER ACADEMY',
  'HIGHLANDER SCHOOL',
  'HIGHLANDS ACADEMY',
  'HIGHLANDS CHRISTIAN ACADEMY',
  'HIGHLANDS CHRISTIAN SCHOOLS',
  'HIGHLANDS HOSPITAL REGIONAL CENTER FOR AUTISM',
  'HIGHLANDS LATIN SCHOOL',
  'HIGHLAWN MONTESSORI SCHOOL',
  'HIGHPOINT ACADEMY',
  'HIGHPOINT ACADEMY ESE CENTER',
  'HIGHPOINT CHRISTIAN ACADEMY',
  'HIGHPOINT CHRISTIAN SCHOOL',
  'HIGHVIEW CHRISTIAN ACADEMY',
  'HIJK,INC DAB EARLY START LEARNING ACADEMY',
  'HILAND HALL SCHOOL',
  'HILDEBRANDT LEARNING CENTER',
  'HILL COUNTRY CHRISTIAN SCHOOL OF AUSTIN',
  'HILL COUNTRY CHRISTIAN SCHOOL OF SAN MARCOS',
  'HILL COUNTRY MONTESSORI SCHOOL',
  'HILL MURRAY SCHOOL',
  'HILL POINT MONTESSORI PREPARATORY SCHOOL',
  'HILL SCHOOL OF FORT WORTH',
  'HILL SCHOOL OF WILMINGTON',
  'HILL SIDE SCHOOL',
  'HILLBROOK SCHOOL',
  'HILLCREST',
  'HILLCREST ACADEMY',
  'HILLCREST CHRISTIAN ACADEMY',
  'HILLCREST CHRISTIAN SCHOOL',
  'HILLCREST EDUCATIONAL FOUNDATION',
  'HILLCREST LUTHERAN ACADEMY',
  'HILLCREST MENNONITE SCHOOL',
  'HILLCREST SCHOOL',
  'HILLCREST SDA ELEMENTARY SCHOOL',
  'HILLCREST SDA SCHOOL',
  'HILLDALE SCHOOL',
  'HILLEL ACADEMY',
  'HILLEL ACADEMY OF BROOME COUNTY',
  'HILLEL ACADEMY OF GREATER DAYTON',
  'HILLEL ACADEMY OF TAMPA',
  'HILLEL ACADEMY PITTSBURGH',
  'HILLEL COMMUNITY DAY SCHOOL',
  'HILLEL DAY SCHOOL',
  'HILLEL TORAH NORTH SUBURBAN DAY SCHOOL',
  'HILLEL YESHIVA SCHOOL',
  'HILLS CHRISTIAN SCHOOL',
  'HILLSBORO CHRISTIAN ACADEMY',
  'HILLSDALE ACADEMY',
  'HILLSDALE CHRISTIAN ACADEMY',
  'HILLSDALE CHRISTIAN SCHOOL',
  'HILLSDALE MENNONITE SCHOOL',
  'HILLSIDE ACADEMY',
  'HILLSIDE ACRES',
  'HILLSIDE ALTERNATIVE PROGRAM',
  'HILLSIDE AMISH SCHOOL',
  'HILLSIDE BAPTIST ACADEMY',
  "HILLSIDE CHILDREN'S CENTER -ANDREWS TRAHEY CAMPUS",
  'HILLSIDE CHILDRENS CENTER - FINGER LAKES CAMPUS',
  'HILLSIDE CHILDRENS CENTER HALPERN',
  'HILLSIDE CHRISTIAN ACADEMY',
  'HILLSIDE CHRISTIAN SCHOOL',
  'HILLSIDE MENNONITE SCHOOL',
  'HILLSIDE MENNONITE W',
  'HILLSIDE MONTESSORI',
  'HILLSIDE MONTESSORI SCHOOL',
  'HILLSIDE PRESCHOOL AND ACADEMY',
  'HILLSIDE SCHOOL',
  'HILLSIDE SEVENTH-DAY ADVENTIST SCHOOL',
  'HILLSIDE STUDENT COMMUNITY SCHOOL',
  'HILLSIDE VIEW SCHOOL',
  'HILLSIDES EDUCATION CENTER',
  'HILLTOP',
  'HILLTOP AMISH SCHOOL',
  'HILLTOP CHRISTIAN SCHOOL',
  'HILLTOP COUNTRY DAY SCHOOL',
  'HILLTOP MONTESSORI SCHOOL',
  'HILLTOP PRESCHOOL & KINDERGARTEN',
  'HILLTOP SCHOOL',
  'HILLTOP SDA SCHOOL',
  'HILLTOP VIEW AMISH SCHOOL',
  'HILLWOOD ACADEMIC DAY SCHOOL',
  'HILTON HEAD CHRISTIAN ACADEMY',
  'HILTON HEAD PREPARATORY SCHOOL',
  'HINES CHILD CARE CENTER',
  'HINKLETOWN MENNONITE SCHOOL',
  'HINSDALE ADVENTIST ACADEMY',
  'HINSHAW PARCHIAL SCHOOL AMISH',
  'HIS ACADEMY/INSTITUTE',
  'HIS KIDS CHRISTIAN SCHOOL',
  'HIS ROYAL CHRISTIAN ACADEMY',
  'HIS WORD CHRISTIAN ACADEMY',
  'HITCHCOCK WOODS SCHOOL',
  'HMONG TOJ SIAB CHILDRENS HOUSE',
  'HMS RICHARDS ADVENTIST SCHOOL',
  'HMS SCHOOL FOR CHILDREN WITH CEREBRAL PALSY',
  "HO'ALA SCHOOL",
  "HO'OMANA HOU SCHOOL",
  'HOBE SOUND CHRISTIAN ACADEMY',
  'HOBGOOD ACADEMY',
  'HOBOKEN CATHOLIC ACADEMY',
  'HOBOKEN MONTESSORI SCHOOL',
  'HOCHBERG PREPARATORY SCHOOL',
  'HOFFMAN ACADEMY',
  'HOGGS HOLLOW',
  'HOHENWALD CHRISTIAN ACADEMY',
  'HOLBROOK SEVENTH-DAY ADVENTIST INDIAN SCHOOL',
  'HOLDEN BEACH CHRISTIAN ACADEMY',
  'HOLDEN CHRISTIAN ACADEMY',
  'HOLDEN HIGH SCHOOL',
  'HOLDERNESS SCHOOL',
  'HOLINESS CHRISTIAN ACADEMY',
  'HOLLAND ADVENTIST ACADEMY',
  'HOLLAND CHRISTIAN HIGH SCHOOL',
  'HOLLAND CHRISTIAN ROSE PARK CHRISTIAN SCHOOL',
  'HOLLAND HALL',
  'HOLLANDALE CHRISTIAN SCHOOL',
  'HOLLIS MONTESSORI SCHOOL',
  'HOLLISTER MONTESSORI SCHOOL',
  'HOLLISTER SDA CHRISTIAN SCHO',
  'HOLLOMAN CHILD DEVT CENTER & ELEMENTARY ACADEMY',
  'HOLLOW AMISH SCHOOL',
  'HOLLOW REED SCHOOL',
  'HOLLY BROOK MONTESSORI SCHOOL',
  'HOLLY GROVE CHRISTIAN SCHOOL',
  'HOLLYDELL SCHOOL',
  'HOLLYWOOD CHRISTIAN SCHOOL',
  'HOLLYWOOD EDUCATION LITERACY PROJECT MIAMI',
  'HOLLYWOOD PRESCHOOL & KINDERGARTEN',
  'HOLLYWOOD SCHOOLHOUSE',
  'HOLLYWOOD UMC CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'HOLMESBURG CHRISTIAN ACADEMY',
  'HOLMSTEAD SCHOOL',
  'HOLT LUTHERAN SCHOOL',
  'HOLT STREET CHRISTIAN ACADEMY',
  'HOLTON-ARMS SCHOOL',
  'HOLY ANGELS CATHOLIC',
  'HOLY ANGELS CATHOLIC ACADEMY',
  'HOLY ANGELS CATHOLIC SCHOOL',
  'HOLY ANGELS ELEMENTARY SCHOOL',
  'HOLY ANGELS OF THE HUDSON VALLEY',
  'HOLY ANGELS SCHOOL',
  'HOLY APOSTLES SCHOOL',
  'HOLY CHILD ACADEMY',
  'HOLY CHILD CATHOLIC SCHOOL',
  'HOLY CHILD JESUS CATHOLIC ACADEMY',
  'HOLY CHILD SCHOOL AT ROSEMONT',
  'HOLY COMFORTER EPISCOPAL SCHOOL',
  'HOLY CROSS ACADEMY',
  'HOLY CROSS CATHOLIC',
  'HOLY CROSS CATHOLIC ACADEMY',
  'HOLY CROSS CATHOLIC SCHOOL',
  'HOLY CROSS CENTRAL SCHOOL',
  'HOLY CROSS CHRISTIAN ACADEMY',
  'HOLY CROSS DISTRICT HIGH SCHOOL',
  'HOLY CROSS ELEMENTARY SCHOOL',
  'HOLY CROSS EPISCOPAL SCHOOL',
  'HOLY CROSS HIGH SCHOOL',
  'HOLY CROSS LUTHERAN ACADEMY',
  'HOLY CROSS LUTHERAN CHURCH',
  'HOLY CROSS LUTHERAN CHURCH & SCHOOL',
  'HOLY CROSS LUTHERAN SCHOOL',
  'HOLY CROSS PS-K CHILDCARE',
  'HOLY CROSS REGIONAL CATHOLIC SCHOOL',
  'HOLY CROSS SCHOOL',
  'HOLY CROSS SCHOOL @ SAN BUENAVENTURA MISSION',
  'HOLY CROSS SCHOOL-BLESSED SACRAMENT CENTER',
  'HOLY CROSS SCHOOL-ST MICHAEL CENTER',
  'HOLY FAMILY ACADEMY',
  'HOLY FAMILY CATHEDRAL SCHOOL',
  'HOLY FAMILY CATHOLIC ACADEMY',
  'HOLY FAMILY CATHOLIC HIGH SCHOOL',
  'HOLY FAMILY CATHOLIC SCHOOL',
  'HOLY FAMILY CONSOLIDATED SCHOOL',
  'HOLY FAMILY CRISTO REY CATHOLIC HIGH SCHOOL',
  'HOLY FAMILY DAY SCHOOL',
  'HOLY FAMILY EARLY LEARNING CENTER',
  'HOLY FAMILY ELEMENTARY',
  'HOLY FAMILY ELEMENTARY SCHOOL',
  'HOLY FAMILY GRADE SCHOOL',
  'HOLY FAMILY HIGH SCHOOL',
  'HOLY FAMILY HOLY NAME SCHOOL',
  'HOLY FAMILY INST',
  'HOLY FAMILY PARISH SCHOOL',
  'HOLY FAMILY PAROCHIAL SCHOOL',
  'HOLY FAMILY REGIONAL CATHOLIC SCHOOL',
  'HOLY FAMILY REGIONAL SCHOOL',
  'HOLY FAMILY SCHOOL',
  'HOLY FAMILY SCHOOL ST LUDMILA CENTER',
  'HOLY FAMILY ST. MARYS SCHOOL',
  'HOLY FAMILY-ST JUDE CENTER-LASALLE CENTER',
  'HOLY GHOST CATHOLIC SCHOOL',
  'HOLY GHOST LUTHERAN SCHOOL',
  'HOLY GHOST PREP',
  'HOLY GHOST SCHOOL',
  'HOLY GHOST-IMMACULATE CONCEPTION SCHOOL',
  'HOLY GROUND BAPTIST ACADEMY',
  'HOLY GUARDIAN ANGELS REGIONAL SCHOOL',
  'HOLY GUARDIAN ANGELS SCHOOL',
  'HOLY INFANCY SCHOOL',
  'HOLY INFANT ELEMENTARY SCHOOL',
  'HOLY INNOCENTS ELEMENTARY SCHOOL',
  'HOLY INNOCENTS EPISCOPAL SCHOOL, NW',
  'HOLY INNOCENTS SCHOOL',
  'HOLY INNOCENTS SCHOOL OF THE NORTHWEST',
  'HOLY LOVE PRESCHOOL & KINDERGARTEN',
  'HOLY MARTYRS ARMENIAN DAY SCHOOL',
  'HOLY MARTYRS CABAYAN ELEM/FERRAHIAN HIGH SCHOOL',
  'HOLY NAME CATHOLIC SCHOOL',
  'HOLY NAME ELEMENTARY',
  'HOLY NAME ELEMENTARY SCHOOL',
  'HOLY NAME HIGH SCHOOL',
  'HOLY NAME OF JESUS',
  'HOLY NAME OF JESUS CATHOLIC SCHOOL',
  'HOLY NAME OF JESUS SCHOOL',
  'HOLY NAME OF MARY ELEMENTARY SCHOOL',
  'HOLY NAME OF MARY MONTESSORI SCHOOL',
  'HOLY NAME PARISH SCHOOL',
  'HOLY NAME SCHOOL',
  'HOLY NAMES HIGH SCHOOL',
  'HOLY NATIVITY SCHOOL',
  'HOLY REDEEMER CATHOLIC SCHOOL',
  'HOLY REDEEMER GRADE SCHOOL',
  'HOLY REDEEMER H.S.',
  'HOLY REDEEMER SCHOOL',
  'HOLY ROSARY',
  'HOLY ROSARY ACADEMY',
  'HOLY ROSARY ACADEMY & HIGH SCHOOL',
  'HOLY ROSARY CATHOLIC SCHOOL',
  'HOLY ROSARY ELEMENTARY SCHOOL',
  'HOLY ROSARY REGIONAL',
  'HOLY ROSARY SCHOOL',
  'HOLY SAVIOR ACADEMY',
  'HOLY SAVIOR CATHOLIC ACADEMY',
  'HOLY SAVIOR MENARD HIGH SCHOOL',
  'HOLY SAVIOR SCHOOL',
  'HOLY SEPULCHER CATHOLIC SCHOOL',
  'HOLY SPIRIT ACADEMY',
  'HOLY SPIRIT CATHOLIC SCHOOL',
  'HOLY SPIRIT CENTRAL SCHOOL',
  'HOLY SPIRIT ELEMENTARY SCHOOL',
  'HOLY SPIRIT HIGH SCHOOL',
  'HOLY SPIRIT PARISH SCHOOL',
  'HOLY SPIRIT PREPARTORY SCHOOL',
  'HOLY SPIRIT REGIONAL CATHOLIC SCHOOL',
  'HOLY SPIRIT SCHOOL',
  'HOLY TRINITY',
  'HOLY TRINITY ACADEMY',
  'HOLY TRINITY ACADEMY & PRESCHOOL',
  'HOLY TRINITY CATHOLIC ACADEMY',
  'HOLY TRINITY CATHOLIC ELEMENTARY',
  'HOLY TRINITY CATHOLIC ELEMENTARY SCHOOL',
  'HOLY TRINITY CATHOLIC HIGH SCHOOL',
  'HOLY TRINITY CATHOLIC MIDDLE SCHOOL',
  'HOLY TRINITY CATHOLIC SCHOOL',
  'HOLY TRINITY CATHOLIC SCHOOLS',
  'HOLY TRINITY CLASSICAL CHRISTIAN SCHOOL',
  'HOLY TRINITY DAY SCHOOL',
  'HOLY TRINITY DIOCESAN HIGH SCHOOL',
  'HOLY TRINITY ELEMENTARY HOLIDAYSBURG CAMPUS',
  'HOLY TRINITY ELEMENTARY SCHOOL',
  'HOLY TRINITY EPISCOPAL ACADEMY',
  'HOLY TRINITY EPISCOPAL DAY SCHOOL',
  'HOLY TRINITY EPISCOPAL SCHOOL',
  'HOLY TRINITY EPISCOPAL SCHOOL OF GREATER HOUSTON',
  'HOLY TRINITY EVANGELICAL LUTHERAN SCHOOL',
  'HOLY TRINITY HIGH SCHOOL',
  'HOLY TRINITY LUTHERAN SCHOOL',
  'HOLY TRINITY MIDDLE SCHOOL CAMPUS',
  'HOLY TRINITY PARISH SCHOOL',
  'HOLY TRINITY SCHOOL',
  'HOLY WISDOM ACADEMY',
  'HOLYCROSS EARLY CHILDHOOD CENTER',
  'HOLYLAND CATHOLIC SCHOOL',
  'HOME PORT LEARNING CENTER',
  'HOMESTEAD AMISH SCHOOL',
  'HOMEWOOD CHRISTIAN ACADEMY',
  'HONEY BROOK CHRISTIAN ACADEMY',
  'HONEY BROOK SCHOOL',
  'HONEY HILL CHRISTIAN ACADEMY',
  'HONEY LOCUST',
  'HONEY RIDGE',
  'HONEY TREE EARLY CHILDHOOD CENTER',
  'HONEYBROOK MENNONITE SCHOOL',
  'HONEYSUCKLE KNOLL SCHOOL',
  'HONEYSUCKLE RIDGE SCHOOL ATT: ALLEN LAPP',
  'HONEYTREE AND BRANCHES ACADEMY',
  'HONEYVIEW AMISH SCHOOL',
  'HONEYVILLE SCHOOL',
  'HONGWANJI MISSION SCHOOL',
  'HONOLULU WALDORF SCHOOL',
  'HONOR RIDGE ACADEMY',
  'HOOD VIEW JUNIOR ACADEMY',
  'HOOPER ACADEMY',
  'HOOSAC SCHOOL',
  'HOOVER CHRISTIAN SCHOOL',
  'HOPE ACADEMY',
  'HOPE ACADEMY FOR DYSLEXICS',
  'HOPE ACADEMY OF THE BRONX',
  'HOPE ACADEMY OF WEXFORD',
  'HOPE ACADEMY PRESBYTERIAN HOME',
  'HOPE BAPIST ACADEMY',
  'HOPE BAPTIST SCHOOL',
  'HOPE CENTER',
  "HOPE CHILDREN'S CENTER",
  'HOPE CHRISTIAN ACADEMY',
  'HOPE CHRISTIAN HIGH SCHOOL',
  'HOPE CHRISTIAN SCHOOL',
  'HOPE CHRISTIAN SCHOOL - FORTIS',
  'HOPE CHRISTIAN SCHOOL - PRIMA',
  'HOPE CHRISTIAN SCHOOL - VIA',
  'HOPE CHRISTIAN SCHOOL: CARITAS',
  'HOPE CHRISTIAN SCHOOL: FIDELIS',
  'HOPE CHRISTIAN SCHOOL: SEMPER',
  'HOPE CHURCH SCHOOL',
  'HOPE COMMUNITY CHRISTIAN ACADEMY',
  'HOPE CREEK ACADEMY',
  'HOPE EXCEL REACHING THE WORLD ACADEMY',
  'HOPE FOR THE FUTURE CHRISTIAN ACADEMY',
  'HOPE GARDENS TREATMENT CENTER',
  'HOPE HALL SCHOOL',
  "HOPE HARBOR CHILDREN'S HOME ACADEMY",
  'HOPE HAVEN ASSOCIATION INC',
  'HOPE LUTHERAN ELEMENTARY SCHOOL',
  'HOPE LUTHERAN SCHOOL',
  'HOPE LUTHERAN SCHOOL AND PRESCHOOL',
  'HOPE MENNONITE SCHOOL',
  'HOPE MONTESSORI ACADEMY',
  'HOPE MONTESSORI ACADEMY WILDWOOD',
  'HOPE MONTESSORI SCHOOL LTD',
  'HOPE PARTNERSHIP FOR EDUCATION',
  'HOPE PROTESTANT REFORMED CHRISTIAN SCHOOL',
  'HOPE RANCH LEARNING ACADEMY',
  'HOPE RURAL SCHOOL',
  'HOPE SCHOOL',
  'HOPE SPRINGS CHRISTIAN LEARNING CENTER',
  'HOPE TECHNOLOGY SCHOOL',
  'HOPEFUL JOURNEYS EDUCATIONAL CENTER',
  'HOPETREE @ WOODS (WILDERNESS OUTDOOR OPPORTUNITY D',
  'HOPETREE ACADEMY',
  'HOPEWELL ACADEMY',
  'HOPEWELL BAPTIST ACADEMY',
  'HOPKINS SCHOOL',
  'HORACE MANN LABORATORY SCHOOL',
  'HORACE MANN SCHOOL',
  'HORACE MANN UPPER SCHOOL',
  'HORIZON ACADEMY',
  'HORIZON CHRISTIAN ACADEMY',
  'HORIZON CHRISTIAN ELEMENTARY & MIDDLE SCHOOL',
  'HORIZON CHRISTIAN HIGH SCHOOL',
  'HORIZON CHRISTIAN SCHOOL',
  'HORIZON HIGH SCHOOL',
  'HORIZON MONTESSORI SCHOOL',
  'HORIZON PREP',
  'HORIZON SCHOOL',
  'HORIZONS AT LCDS',
  'HORSEHEADS CHRISTIAN SCHOOL',
  'HORSHAM MONTESSORI CHILDREN HOUSE',
  'HOSANNA CHRISTIAN ACADEMY',
  'HOSANNA CHRISTIAN SCHOOL',
  'HOSANNA LEARNING CENTER',
  'HOSMER ROAD',
  'HOT SPRINGS ADVENTIST SCHOOL',
  'HOUGHTON ACADEMY',
  'HOUMA CHRISTIAN SCHOOL',
  'HOUSATONIC ACADEMY',
  'HOUSE OF CHILDREN',
  'HOUSE OF PRAYER CHRISTIAN ACADEMY',
  'HOUSE OF THE LORD CHRISTIAN ACADAMY',
  'HOUSE OF THE LORD CHRISTIAN ACADEMY',
  'HOUSTON ACADEMY',
  'HOUSTON CHRISTIAN HIGH SCHOOL',
  'HOUSTON PEACE ACADEMY',
  'HOUSTON QURAN ACADEMY',
  'HOVNANIAN SCHOOL',
  'HOWARDSVILLE CHRISTIAN SCHOOL',
  'HOWE MILITARY SCHOOL',
  'HOWELLS COMMUNITY CATHOLIC SCHOOL',
  'HRRS JUAN DIEGO ACADEMY',
  'HSLU - Lucerne University of Applied Sciences and Arts',
  'HSR - Hochschule für Technik Rapperswil',
  'HTW Chur - University of Applied Sciences HTW Chur',
  'HUAKAILANI SCHOOL FOR GIRLS',
  'HUBER HEIGHTS CHRISTIAN ACADEMY',
  'HUBLER RIDGE SCHOOL',
  'HUDA SCHOOL & MONTESSORI',
  'HUDSON CATHOLIC REGIONAL HIGH SCHOOL',
  'HUDSON COUNTRY MONTESSORI SCHOOL',
  'HUDSON HILLS ACADEMY-BEACON CAMPUS',
  'HUDSON MONTESSORI',
  'HUDSON MONTESSORI SCHOOL',
  'HUDSON VALLEY CHRISTIAN ACADEMY',
  'HUDSON VALLEY SUDBURY SCHOOL',
  'HUDSON VIEW CHRISTIAN ACADEMY',
  'HUDSONVILLE CHRISTIAN SCHOOL',
  'HUENEME CHRISTIAN SCHOOL',
  'HUG - Univ. Hospitals of Geneva',
  'HUGHSON CHRISTIAN SCHOOL',
  'HULL CHRISTIAN SCHOOL',
  'HULL PROTESTANT REFORMED CHRISTIAN SCHOOL',
  'HUMANEX ACADEMY',
  'HUMBLE CHRISTIAN SCHOOL',
  'HUMBOLDT BAY CHRISTIAN SCHOOL',
  'HUMPHREYS ACADEMY',
  'HUNT RIDGE MONTESSORI SCHOOL',
  'HUNTER CLASSICAL CHRISTIAN SCHOOL',
  'HUNTER MILL MONTESSORI SCHOOL',
  'HUNTERDON PREPARATORY SCHOOL',
  'HUNTING PARK CHRISTIAN ACADEMY',
  'HUNTINGTON CATHOLIC SCHOOL',
  'HUNTINGTON CHRISTIAN ACADEMY',
  'HUNTINGTON CHRISTIAN SCHOOL',
  'HUNTINGTON LEARNING CENTER',
  'HUNTINGTON LEARNING CENTER-APOPKA',
  'HUNTINGTON MONTESSORI-NORTHSHORE LEARNING',
  'HUNTINGTON VALLEY BAPTIST CHURCH PRESCHOOL',
  'HUNTINGTON-SURREY HIGH SCHOOL',
  'HUNTSVILLE CHRISTIAN ACADEMY',
  'HURON VALLEY LUTHERAN HIGH SCHOOL',
  'HUTCHISON SCHOOL',
  'HUTTERVILLE COLONY ELEMENTARY - 01',
  'HUYETTS MENNONITE SCHOOL',
  'HWP - Hamburger Universität für Wirtschaft und Politik',
  'HWZ - University of Applied Sciences in Business Administration Zurich',
  'HYDE PARK DAY SCHOOL CHICAGO CAMPUS',
  'HYDE PARK DAY SCHOOL NORTHFIELD',
  'HYDE PARK HIGH SCHOOL',
  'HYDE PARK MONTESSORI',
  'HYDE PARK SCHOOL',
  'HYDE SCHOOL',
  'HYLA MIDDLE SCHOOL',
  'HYLAND CHRISTIAN SCHOOL',
  'HYMAN BRAND HEBREW ACADEMY',
  'Ha:san Preparatory & Leadership School',
  'Haaff Elementary School',
  'Haaheo Elementary School',
  'Haas Elementary School',
  'Haas Hall Academy',
  'Haas Hall Academy At The Lane',
  'Haas Hall Academy Bentonville',
  'Haas Hall Academy Jones Center',
  'Haas Middle',
  'Habersham Central High School',
  'Habersham Success Academy',
  'Hac Daep H S',
  'Hac Daep Middle',
  'Hacettepe University',
  'Hachinohe Institute of Technology',
  'Hachinohe University',
  'Hacienda Elementary',
  'Hacienda Heights El',
  'Hacienda Science/environmental Magnet',
  'Hackberry El',
  'Hackberry High School',
  'Hackberry Hill Elementary School',
  'Hackensack High School',
  'Hackensack Middle School',
  'Hacker Elementary School',
  'Hacker Middle School',
  'Hacker Valley Elementary School',
  'Hackett Elementary',
  'Hackett Elementary School',
  'Hackett High School',
  'Hackett Horatio B Sch',
  'Hackettstown High School',
  'Hackettstown Middle School',
  'Hackleburg Elementary School',
  'Hackleburg High School',
  'Hackler Intermediate School',
  'Hackney Pri',
  'Haddam Elementary School',
  'Haddam-killingworth High School',
  'Haddam-killingworth Middle School',
  'Haddon Avenue Elementary',
  'Haddon Heights Jr-sr Hs',
  'Haddon Township High School',
  'Haddonfield Memorial High School',
  'Haddonfield Middle School',
  'Hadfield Elementary',
  'Hadhramout University of Science and Technology',
  'Hadley',
  'Hadley E Watts Middle School',
  'Hadley Elementary',
  'Hadley Junior High School',
  'Hadley Middle School',
  'Hadley-luzerne Junior-senior High School',
  'Hadnot-hayes S.t.e.m. Elementary School',
  'Haen Elementary',
  'Hafen Elementary',
  'Hafencity Universität Hamburg',
  'Hagan Elementary School',
  'Hagan School',
  'Hage Elementary',
  'Hagemann Elem.',
  'Hagen Early Education Center',
  'Hagen Road Elementary School',
  'Hager Elementary School',
  'Hager Preschool',
  'Hagerman Elementary',
  'Hagerman High',
  'Hagerman Middle',
  'Hagerman School',
  'Hagerstown Community College',
  'Hagerstown Elementary School',
  'Hagerstown Jr-sr High School',
  'Hagerty High School',
  'Haggard Middle',
  'Haggerty',
  'Haggerty School',
  'Hagginwood Elementary',
  'Hagood Elementary',
  'Hahaione Elementary School',
  'Hahira Elementary School',
  'Hahira Middle School',
  'Hahn Intermediate School',
  'Hahnamann Honoeopathic Medical College',
  'Hahntown El Sch',
  'Hahnville High School',
  'Hai Duong University of  Economics and Technology',
  'Haigazian University',
  'Haigh Elementary School',
  'Haiku Elementary School',
  'Hailey El',
  'Hailey Elementary School',
  'Haileyville Es',
  'Haileyville Hs',
  'Hailmann Elementary School',
  'Hainan Normal University',
  'Hainan University',
  'Haine El Sch',
  'Haine Ms',
  'Haines City Headstart Center',
  'Haines City Senior High School',
  'Haines Elem School',
  'Haines Elementary',
  'Haines Elementary School',
  'Haines High School',
  'Haines Home School',
  'Haines Middle School',
  'Haines Sixth Grade Center',
  'Hainesport Township School',
  'Hairgrove El',
  'Haisley Elementary School',
  'Hajee Mohammad Danesh Science and Technology University',
  'Hajjar Elementary',
  'Hajvery University Lahore for Women',
  'Hakipuu Learning Center',
  'Hakkari University',
  'Hakodate University',
  'Hakuoh University',
  'Hal E Clark Middle School',
  'Hal Hutchens Elementary',
  'Hal Smith Elementary School',
  'Halau Ku Mana - A New Century Pcs',
  'Halcyon Elementary School',
  'Halcyon School (special Education)',
  'Haldane Elementary/middle School',
  'Haldane High School',
  'Hale',
  'Hale Center H S',
  'Hale Co J J A E P',
  'Hale Co Jjaep',
  'Hale Cook Elementary',
  'Hale County High School',
  'Hale County Jjaep',
  'Hale County Middle School',
  'Hale Creek Elementary School',
  'Hale Early Education Center',
  'Hale El',
  'Hale Elem School',
  'Hale Elem.',
  'Hale Elementary',
  'Hale Elementary School',
  'Hale Elementarymiddle School',
  'Hale High',
  'Hale High School',
  'Hale Jhs',
  'Hale Road Elementary School',
  'Halecrest Elementary',
  'Haledon Public School',
  'Haleiwa Elementary School',
  'Hales Corners Elementary',
  'Halethorpe Elementary',
  'Haley Elem Academy',
  'Haley Elementary',
  'Haley J El',
  'Haley T El',
  'Haleyville Center Of Technology',
  'Haleyville Elementary School',
  'Haleyville High School',
  'Haleyville Middle School',
  'Haleyville-mauricetown Elementary School',
  'Half Day School',
  'Half Hollow Hills High School East',
  'Half Hollow Hills High School West',
  'Half Moon Bay High',
  'Halfway Elem.',
  'Halfway Secondary',
  'Halic University',
  'Halifax Area El Sch',
  'Halifax Area Hs',
  'Halifax Area Ms',
  'Halifax Behavioral Services',
  'Halifax Community College',
  'Halifax County Career Center',
  'Halifax County High',
  'Halifax County Middle',
  'Halifax Elementary',
  'Halifax West School',
  'Haliwa-saponi Tribal School',
  'Hall Center For Education',
  'Hall District Elementary',
  'Hall Elem School',
  'Hall Elementary School',
  'Hall Fletcher Elementary',
  'Hall High School',
  'Hall International',
  'Hall Kent Elementary School',
  'Hall Memorial School',
  'Hall Middle',
  'Hall School',
  'Hall-dale Elementary School',
  'Hall-dale High School',
  'Hall-dale Middle And High School',
  'Hall-dale Middle School',
  'Hall-woodward Elementary',
  'Hallandale High School',
  'Halldale Elementary',
  'Halle Hewetson Elementary School',
  'Hallen School',
  'Haller Middle School',
  'Hallett Academy',
  'Hallett Elementary',
  'Hallettsville El',
  'Hallettsville H S',
  'Hallettsville J H',
  'Halley Elementary',
  'Halliday Elementary School',
  'Halliday High School',
  'Hallie Randall El',
  'Hallie Wells Middle School',
  'Hallinan Elementary School',
  'Hallman Elementary School',
  'Hallmark Charter',
  'Hallmark University',
  'Hallowell El Sch',
  'Halls Cross Roads Elementary',
  'Halls Elementary',
  'Halls Elementary School',
  'Halls Ferry Elem.',
  'Halls High School',
  'Halls Junior High School',
  'Halls Middle School',
  'Hallsboro Middle',
  'Hallsboro-artesia Elementary',
  'Hallsburg School',
  'Hallsville Daep',
  'Hallsville East El',
  'Hallsville H S',
  'Hallsville High',
  'Hallsville Int',
  'Hallsville Intermediate',
  'Hallsville J H',
  'Hallsville Middle',
  'Hallsville North El',
  'Hallsville Pri',
  'Hallsville Primary',
  'Hallsville School',
  'Hallsville Virtual School',
  'Hallyburton Academy',
  'Hallym University',
  'Halmstad Elementary',
  'Halmstad University College',
  'Halpin Early Childhood Ctr',
  'Halstead Academy',
  'Halstead High',
  'Halstead Middle School',
  'Halsted Middle School',
  'Haltom H S',
  'Haltom Middle',
  'Halverson Elementary',
  'Hamadan University of Medical Sciences',
  'Hamady Community High School',
  'Hamady Middle School',
  'Hamagrael Elementary School',
  'Hamamatsu University',
  'Hamamatsu University School of Medicine',
  'Hambden Elementary School',
  'Hamblen County Alternative School',
  'Hamblen Elementary',
  'Hambrick Elementary School',
  'Hambrick Middle',
  'Hambright El Sch',
  'Hamburg Area Hs',
  'Hamburg Area Middle School',
  'Hamburg High School',
  'Hamburg Middle School',
  'Hamburg School',
  'Hamdan Bin Mohammed e-University',
  'Hamdard University',
  'Hamden High School',
  'Hamden Middle School',
  'Hamel Elementary School',
  'Hamer Elementary School',
  'Hamersville Elementary School',
  'Hamilton Academy',
  'Hamilton Alternative Academy',
  'Hamilton Andrew Sch',
  'Hamilton Avenue School',
  'Hamilton Cnty Math & Science',
  'Hamilton College',
  'Hamilton Community Day',
  'Hamilton Community Elementary Sch',
  'Hamilton Community High School',
  'Hamilton County Collegiate High At Chattanooga State',
  'Hamilton County Elementary School',
  'Hamilton County High School',
  'Hamilton County Jr/sr High School',
  'Hamilton County Preschool Center',
  'Hamilton County Virtual School',
  'Hamilton Creek School',
  'Hamilton Crossing Elementary School',
  'Hamilton E. Holmes Elementary',
  'Hamilton Early Childhood Center',
  'Hamilton Early Learning Center',
  'Hamilton East - Steinert',
  'Hamilton El',
  'Hamilton El Sch',
  'Hamilton Elem',
  'Hamilton Elem School',
  'Hamilton Elem.',
  'Hamilton Elem. Community Ed.',
  'Hamilton Elementary',
  'Hamilton Elementary School',
  'Hamilton Elementary/middle',
  'Hamilton Es',
  'Hamilton Freshman School',
  'Hamilton Grange Middle School',
  'Hamilton H S',
  'Hamilton Heights El Sch',
  'Hamilton Heights Elementary School',
  'Hamilton Heights High School',
  'Hamilton Heights Middle School',
  'Hamilton Heights Primary School',
  'Hamilton Heights School',
  'Hamilton High',
  'Hamilton High School',
  'Hamilton Holmes Middle',
  'Hamilton Intermediate School',
  'Hamilton International Middle School',
  'Hamilton J H',
  'Hamilton Jr/sr High Sch',
  'Hamilton Junior-senior High School',
  'Hamilton Local Preschool',
  'Hamilton Meadow Park',
  'Hamilton Middle',
  'Hamilton Middle School',
  'Hamilton North-nottingham',
  'Hamilton Park Pacesetter Magnet',
  'Hamilton Primary School',
  'Hamilton School',
  'Hamilton Se Int And Jr High Sch',
  'Hamilton Southeastern Hs',
  'Hamilton Stem Academy (k-6)',
  'Hamilton Township High School',
  'Hamilton Traditional School',
  'Hamilton Twp El Sch',
  'Hamilton Virtual Franchise',
  'Hamilton Virtual Instruction Program',
  'Hamilton Virtual Instruction Program (district Provided)',
  'Hamilton Vtae School',
  'Hamilton West-watson',
  'Hamilton-fulton-montgomery Boces',
  'Hamilton-maineville Elementary School',
  'Hamilton-wenham Regional High',
  'Hamiltonparsons Elementary School',
  'Hamlet El',
  'Hamlet Middle',
  'Hamlin Charter Academy',
  'Hamlin El',
  'Hamlin Elementary - 09',
  'Hamlin Elementary School',
  'Hamlin H S',
  'Hamlin High School - 01',
  'Hamlin Middle',
  'Hamlin Middle School',
  'Hamlin Middle School - 02',
  'Hamlin Pk-8',
  'Hamlin Upper Grade Center',
  'Hamline Elem School',
  'Hamline Elementary School',
  'Hamline University',
  'Hammarskjold Middle School',
  'Hammer Montessori At Galarza Elementary',
  'Hammett Bowen Jr. Elementary School',
  'Hammock Pointe Elementary School',
  'Hammocks Middle School',
  'Hammon Es',
  'Hammon Hs',
  'Hammond Academy Of Science & Tech',
  'Hammond Central School',
  'Hammond Eastside Magnet',
  'Hammond Elem School',
  'Hammond Elementary',
  'Hammond Elementary School',
  'Hammond High',
  'Hammond High Magnet School',
  'Hammond High School',
  'Hammond Hill Elementary',
  'Hammond Middle School',
  'Hammond School',
  'Hammond Westside Montessori',
  'Hammondsport Junior-senior High School',
  'Hammonton Early Childhood Eductional Center',
  'Hammonton High School',
  'Hammonton Middle School',
  'Hampden',
  'Hampden Academy',
  'Hampden Charter School Of Science East',
  'Hampden El Sch',
  'Hampden Meadows School',
  'Hampden-Sydney College',
  'Hampshire College',
  'Hampshire County Career Training Center',
  'Hampshire Elem School',
  'Hampshire High School',
  'Hampshire Middle School',
  'Hampshire Regional High',
  'Hampshire Senior High School',
  'Hampshire Unit School',
  'Hampstead Central School',
  'Hampstead Elementary',
  'Hampstead Hill Academy',
  'Hampstead Middle School',
  'Hampton Academy',
  'Hampton Bays Elementary School',
  'Hampton Bays High School',
  'Hampton Bays Middle School',
  'Hampton Bennett Early Childhood Center',
  'Hampton Borough School',
  'Hampton Centre School',
  'Hampton City Public Schools Gov Health Sciences Academy',
  'Hampton Cove Elementary',
  'Hampton Cove Middle School',
  'Hampton Elem Fine & Perf Arts Sch',
  'Hampton Elem School',
  'Hampton Elem Univ Partnership',
  'Hampton Elementary',
  'Hampton Elementary School',
  'Hampton High',
  'Hampton High School',
  'Hampton Hs',
  'Hampton L. Daughtry Elementary School',
  'Hampton Middle School',
  'Hampton Ms',
  'Hampton Oaks Elementary',
  'Hampton Preschool',
  "Hampton Public Schools Architecture & Applied Arts Governor'",
  'Hampton Street School',
  'Hampton University',
  'Hampton-dumont High School',
  'Hampton-dumont Middle School',
  'Hampton-moreno-dugat Early Childhood Center',
  'Hamshire-fannett El',
  'Hamshire-fannett H S',
  'Hamshire-fannett Int',
  'Hamshire-fannett Middle',
  'Hamtramck Academy',
  'Hamtramck High School',
  'Hana High & Elementary School',
  "Hanaa'dli Community School",
  'Hanahan Elementary',
  'Hanahan High',
  'Hanahan Middle',
  'Hanalei Elementary School',
  'Hanawalt Elementary',
  'Hanazono University',
  'Hanby Building Elementary School',
  'Hanby El',
  'Hanby Elementary School',
  'Hanby Middle School',
  'Hance El Sch',
  'Hanceville Elementary School',
  'Hanceville High School',
  'Hanceville Middle School',
  'Hancock',
  'Hancock Central High School',
  'Hancock Central Middle School',
  'Hancock College Preparatory Hs',
  'Hancock County Career Technical Cen',
  'Hancock County Elementary',
  'Hancock County High School',
  'Hancock County Middle School',
  'Hancock County Technical Center',
  'Hancock Creek Elementary School',
  'Hancock El',
  'Hancock El Sch',
  'Hancock Elementary',
  'Hancock Elementary School',
  'Hancock Grammar School',
  'Hancock High School',
  'Hancock Junior-senior High School',
  'Hancock Middle School',
  'Hancock Middle Senior High',
  'Hancock Middlehigh School',
  'Hancock North Central Elementary',
  'Hancock Park Elementary',
  'Hancock Place Elem.',
  'Hancock Place Middle',
  'Hancock Sec.',
  'Hancock Sr. High',
  'Hand In Hand Primary',
  'Hand Middle',
  'Hand Ms',
  'Handelshochschule Leipzig',
  'Handicapped Contractual Services',
  'Handke Center- Ec Special Education',
  'Handley El',
  'Handley Elementary School',
  'Handley High School',
  'Handley Middle',
  'Handley Middle School',
  'Handley School',
  'Handlon Correctional Facility',
  'Handy Elementary',
  'Handy Middle School',
  'Hanes El',
  'Hanes Magnet School',
  'Hanford Community Day',
  'Hanford Elementary Community Day',
  'Hanford High',
  'Hanford High School',
  'Hanford Night (continuation)',
  'Hanford West High',
  'Hanh Phan Tilley Elementary',
  'Hankinson Elementary School',
  'Hankinson High School',
  'Hanksville School',
  'Hankuk Aviation University',
  'Hankuk University of Foreign Studies',
  'Hankyong National University',
  'Hanley International Academy',
  'Hanna Early College H S',
  'Hanna Elementary',
  'Hanna Es',
  'Hanna Hs',
  'Hanna Ranch Elementary',
  'Hanna Springs El',
  'Hanna Woods Elem.',
  'Hannah Beardsley Middle School',
  'Hannah Caldwell Elem Sch',
  'Hannah Cole Primary',
  'Hannah Crawford Elementary School Grades 3 -5',
  'Hannah Crawford Elementary School Prek-grade 2',
  'Hannah Elementary',
  'Hannah Gibbons-nottingham Elementary School',
  'Hannah J Ashton Middle School',
  'Hannah Pamplico High',
  'Hannah Penn',
  'Hannah-pamplico Elementary/middle',
  'Hannahville Indian School',
  'Hannalei Elementary',
  'Hannam University',
  'Hannan Elementary',
  'Hannan High School',
  'Hannan Trace Elementary School',
  'Hannan University',
  'Hannibal Career Tech. Ctr.',
  'Hannibal High School',
  'Hannibal Middle',
  'Hannibal Sr. High',
  'Hanoi Medical University',
  'Hanoi National Economics University',
  'Hanoi Open University',
  'Hanoi Univerisity of Transportation',
  'Hanoi University of Agriculture',
  'Hanoi University of Architecture',
  'Hanoi University of Civil Engineering',
  'Hanoi University of Mining and Geology',
  'Hanoi University of Science',
  'Hanoi University of Technology',
  'Hanover Area Jshs',
  'Hanover Area Memorial El Sch',
  'Hanover Central High School',
  'Hanover Central Middle School',
  'Hanover College',
  'Hanover Countryside Elem School',
  'Hanover El Sch',
  'Hanover Elem',
  'Hanover Elementary',
  'Hanover Green El Sch',
  'Hanover High',
  'Hanover High School',
  'Hanover Highlands Elem School',
  'Hanover Junior-senior High School',
  'Hanover Middle',
  'Hanover Ms',
  'Hanover Online Academy',
  'Hanover Park High School',
  'Hanover School',
  'Hanover Shs',
  'Hanover Street El Sch',
  'Hanover Street School',
  'Hanoverhorton Elementary School',
  'Hanoverhorton High School',
  'Hanoverhorton Middle School',
  'Hanoverhorton Virtual School',
  'Hans Christensen Middle',
  'Hans Christian Andersen Elementary School',
  'Hans Herr El Sch',
  'Hanscom Middle',
  'Hanscom Primary',
  'Hansei University',
  'Hansen Elementary',
  'Hansen Elementary School',
  'Hansen Jr/sr High School',
  'Hanseo University',
  'Hanshew Middle School',
  'Hanshin University',
  'Hanson Colony Alternative High School - 07',
  'Hanson Elementary',
  'Hanson Elementary - 06',
  'Hanson Elementary School',
  'Hanson High School - 01',
  'Hanson Middle School',
  'Hanson Middle School - 02',
  'Hanson Park Elem School',
  'Hansung University Seoul',
  'Hanthorn Early Education',
  'Hanyang University',
  'Hanze University Groningen',
  'Hapa-hmong American Peace Academy K3-12',
  'Hapeville Charter Career Academy',
  'Hapeville Charter Middle School',
  'Hapeville Elementary School',
  'Happy Camp Elementary',
  'Happy Camp High',
  'Happy El',
  'Happy H S',
  'Happy Hollow Elementary',
  'Happy Hollow Elementary School',
  'Happy Hollow School',
  'Happy Valley Community Day',
  'Happy Valley Elementary',
  'Happy Valley Elementary School',
  'Happy Valley High School',
  'Happy Valley Learning Center',
  'Happy Valley Middle School',
  'Happy Valley Primary',
  'Happy Valley School',
  'Happy Valley School East Campus',
  'Har-ber High School',
  'Har-bur Middle School',
  'Harada Elementary',
  'Harahan Elementary School',
  'Haralson County High School',
  'Haralson County Middle School',
  'Haramaya University',
  'Harambee Elementary School',
  'Harambee Institute Of Science And Techno',
  'Harare Institute of Technology',
  'Harbin Engineering University',
  'Harbin Institute of Technology',
  'Harbin Medical University',
  'Harbin Normal University',
  'Harbin University of Civil Engineering & Architecture',
  'Harbin University of Science and Technology',
  'Harbins Elementary School',
  'Harbison West Elementary',
  'Harbor',
  'Harbor Academy Reg Safe Sch Prg',
  'Harbor Beach Community High School',
  'Harbor Beach Elementary School',
  'Harbor Beach Middle School',
  'Harbor City Elementary',
  'Harbor City Elementary School',
  'Harbor City International Charter',
  'Harbor Creek Junior Hs',
  'Harbor Creek Senior Hs',
  'Harbor Educational Program',
  'Harbor Heights',
  'Harbor Heights Elementary School',
  'Harbor High',
  'Harbor High School',
  'Harbor Hill School',
  'Harbor Lights Middle School',
  'Harbor Ridge Middle School',
  'Harbor Science And Arts Charter School',
  'Harbor Springs Charter',
  'Harbor Springs High School',
  'Harbor Springs Middle School',
  'Harbor Teacher Preparation Academy',
  'Harbor View Elementary',
  'Harbor View School (the)',
  'Harbordale Elementary School',
  'Harborfields High School',
  'Harborside Academy',
  'Harborside Elementary',
  'Harborside Middle School',
  'Harborview Elementary',
  'Harbour Pointe Middle School',
  'Harbour View Elementary',
  'Harbour View Elementary School',
  'Harbour Way Elementary Community Day',
  'Harcum College',
  'Hardaway High School',
  'Hardee Junior High School',
  'Hardee Senior High School',
  'Hardee Virtual Franchise',
  'Hardee Virtual Instruction Program',
  'Hardee Virtual Instruction Program (district Provided)',
  'Hardeeville Elementary',
  'Hardeeville-ridgeland Middle',
  'Hardeman Elem.',
  'Harden Middle',
  'Harder Elementary',
  'Hardesty Es',
  'Hardesty Hs',
  'Hardin Co Alter Ed',
  'Hardin Co Alternative Aep',
  'Hardin Co J J A E P',
  'Hardin Community School',
  'Hardin County Alternative Ed - Buna Isd',
  'Hardin County Day Treatment Center',
  'Hardin County Elem School',
  'Hardin County High School',
  'Hardin County Jr High School',
  'Hardin County Middle School',
  'Hardin El',
  'Hardin Elementary School',
  'Hardin H S',
  'Hardin High School',
  'Hardin Houston Elementary School',
  'Hardin Int',
  'Hardin Intermediate',
  'Hardin J H',
  'Hardin Middle',
  'Hardin Middle School',
  'Hardin Northern Elementary School',
  'Hardin Northern High School',
  'Hardin Park Elementary',
  'Hardin Primary',
  'Hardin Reynolds Elementary',
  'Hardin Valley Academy',
  'Hardin Valley Elementary',
  'Hardin-central Elem.',
  'Hardin-central High',
  'Hardin-jefferson H S',
  'Harding Avenue Elementary',
  'Harding Avenue School',
  'Harding Charter Preparatory Hs',
  'Harding County High School - 01',
  'Harding County Middle School - 03',
  'Harding El Sch',
  'Harding Elementary',
  'Harding Elementary School',
  'Harding Fine Arts Academy',
  'Harding Grade School',
  'Harding High School',
  'Harding Middle School',
  'Harding Primary School',
  'Harding Sch',
  'Harding Senior High',
  'Harding Street Elementary',
  'Harding Township School',
  'Harding University',
  'Harding University High',
  'Harding University Partnership',
  'Harding Warren G Ms',
  'Hardinsburg Elementary School',
  'Hardwick El',
  'Hardwick Elementary',
  'Hardwick Elementary School',
  'Hardy',
  'Hardy Brown College Prep',
  'Hardy Elementary',
  'Hardy Elementary School',
  'Hardy Middle School',
  'Hardy Ms',
  'Hardy Oak El',
  'Hardyston Township Elementary School',
  'Hardyston Township Middle School',
  'Harford Community College',
  'Harford Heights Elementary',
  'Harford Hills Elementary',
  'Harford Technical High',
  'Hargill El',
  'Hargrave H S',
  'Hargrove Elementary',
  'Hariri Canadian University',
  'Harker Heights El',
  'Harker Heights H S',
  'Harker-wylie School',
  'Harkers Island Elementary',
  'Harlan (david W. ) Elementary School',
  'Harlan Community Academy Hs',
  'Harlan Community Middle School',
  'Harlan County High School',
  'Harlan Elementary School',
  'Harlan H S',
  'Harlan High School',
  'Harlan Intermediate School',
  'Harlan Primary',
  'Harlan Rowe Ms',
  'Harlan-butlerville Elementary School',
  'Harlandale Alternative Center Boot H S',
  'Harlandale Alternative Center Boot Middle',
  'Harlandale H S',
  'Harlandale Isd Stem Echs-alamo Colleges At Pac',
  'Harlandale Middle',
  'Harlean Beal El',
  'Harlem 7-8',
  "Harlem Children's Zone Promise Academy Charter School",
  "Harlem Children's Zone Promise Academy Ii Charter School",
  'Harlem El',
  'Harlem Elementary School',
  'Harlem Hebrew Language Academy Charter School',
  'Harlem Heights Community Charter School',
  'Harlem High School',
  'Harlem Hs - 9th Grade Campus',
  'Harlem Link Charter School',
  'Harlem Middle School',
  'Harlem Park Elementary',
  'Harlem Prep Charter School',
  'Harlem Renaissance High School',
  'Harlem Village Academy Charter School',
  'Harlem Village Academy Leadership Charter School',
  'Harleton El',
  'Harleton H S',
  'Harleton J H',
  'Harley Avenue Elementary School',
  'Harley Harmon Elementary School',
  'Harley Hopkins Family Center',
  'Harleyville Elementary',
  'Harleyville-ridgeville Middle',
  'Harlingen H S',
  'Harlingen H S - South',
  'Harlingen School Of Health Professions',
  'Harloe Elementary',
  'Harlowton High School',
  'Harman Elementary School',
  'Harman Elementary/high School',
  'Harmar Elementary School',
  'Harmon Elementary School',
  'Harmon Johnson Elementary',
  'Harmon Middle School',
  'Harmony Area El Sch',
  'Harmony Area Jshs',
  'Harmony Community School',
  'Harmony Dc Pcs School Of Excellence',
  'Harmony El',
  'Harmony Elementary',
  'Harmony Elementary School',
  'Harmony Grove Elem. School',
  'Harmony Grove High School',
  'Harmony Grove Junior High School',
  'Harmony Grove Middle School',
  'Harmony H S',
  'Harmony High School',
  'Harmony Hill School',
  'Harmony Hills El',
  'Harmony Hills Elementary',
  'Harmony Intermediate Center',
  'Harmony Intermediate School',
  'Harmony J H',
  'Harmony Learning Center',
  'Harmony Magnet Academy',
  'Harmony Middle',
  'Harmony Public School',
  'Harmony School',
  'Harmony School Of Achievement - Houston',
  'Harmony School Of Advancement-houston',
  'Harmony School Of Business - Dallas',
  'Harmony School Of Discovery - Houston',
  'Harmony School Of Endeavor-houston',
  'Harmony School Of Enrichment - Houston',
  'Harmony School Of Excellence - Austin',
  'Harmony School Of Excellence - El Paso',
  'Harmony School Of Excellence - Laredo',
  'Harmony School Of Excellence - San Antonio',
  'Harmony School Of Excellence - Sugar Land',
  'Harmony School Of Excellence-houston',
  'Harmony School Of Exploration- Houston',
  'Harmony School Of Fine Arts And Technology-houston',
  'Harmony School Of Ingenuity-houston',
  'Harmony School Of Innovation - Austin',
  'Harmony School Of Innovation - Brownsville',
  'Harmony School Of Innovation - Carrollton',
  'Harmony School Of Innovation - El Paso',
  'Harmony School Of Innovation - Fort Worth',
  'Harmony School Of Innovation - Garland',
  'Harmony School Of Innovation - Houston',
  'Harmony School Of Innovation - Katy',
  'Harmony School Of Innovation - Laredo',
  'Harmony School Of Innovation - San Antonio',
  'Harmony School Of Innovation - Waco',
  'Harmony School Of Innovation- Euless',
  'Harmony School Of Innovation-sugar Land',
  'Harmony School Of Nature And Athletics - Dallas',
  'Harmony School Of Political Science & Comm-austin',
  'Harmony School Of Science - Austin',
  'Harmony School Of Science-houston',
  'Harmony School Of Technology-houston',
  'Harmony Science Acad (el Paso)',
  'Harmony Science Acad (san Antonio)',
  'Harmony Science Acad (waco)',
  'Harmony Science Academy - Austin',
  'Harmony Science Academy - Brownsville',
  'Harmony Science Academy - Bryan',
  'Harmony Science Academy - Carrollton',
  'Harmony Science Academy - Cedar Park',
  'Harmony Science Academy - Dallas',
  'Harmony Science Academy - Euless',
  'Harmony Science Academy - Garland',
  'Harmony Science Academy - Grand Prairie',
  'Harmony Science Academy - Laredo',
  'Harmony Science Academy - Lubbock',
  'Harmony Science Academy - Odessa',
  'Harmony Science Academy - Plano',
  'Harmony Science Academy- Beaumont',
  'Harmony Science Academy- Fort Worth',
  'Harmony Science Academy-houston',
  'Harmony Science Academy-katy',
  'Harmony Science Academy-pflugerville',
  'Harmony Science Academy-sugar Land',
  'Harmony Township School',
  'Harmony-leland Elementary School',
  'Harms Adv Tech Center-wncc',
  'Harms Elementary School',
  'Harnett Central High',
  'Harnett Central Middle',
  'Harnett Early College (hcec)',
  'Harnett Primary',
  'Harnew Elem School',
  'Harney Elementary School',
  'Harns Marsh Elementary School',
  'Harns Marsh Middle School',
  'Harokopio University',
  'Harold Ambuehl Elementary',
  'Harold B Lee Elementary School',
  'Harold B. Williams Elementary School',
  'Harold C Urey Middle School',
  'Harold C. Johnson Elementary',
  'Harold Cade Middle',
  'Harold D Fayette School',
  'Harold D Guthrie Center For Excellence',
  'Harold E. Winkler Middle',
  'Harold Ferguson High School',
  'Harold Kaveolook School',
  'Harold Keller Elementary School',
  'Harold L Qualters Middle',
  'Harold Lewis Center School',
  'Harold Macon Ratcliffe Elementary',
  'Harold Martin School',
  'Harold Mcalister High (opportunity)',
  'Harold Mccormick Elementary',
  'Harold O Brumsted Elementary School',
  'Harold Schnell Elementary School',
  'Harold Steele Elementary School',
  'Harold T Branch Academy For Career & Technical Ed',
  'Harold T Wiley School',
  'Harold W Smith School',
  'Harold Wendell Lang Sr Middle',
  'Harold William Kolb',
  'Harp Elementary School',
  'Harper',
  'Harper Academy',
  'Harper Alternative School',
  'Harper Charter School',
  'Harper College',
  'Harper Creek High School',
  'Harper Creek Middle School',
  'Harper Daep',
  'Harper El',
  'Harper Elem',
  'Harper Elem School',
  'Harper Elementary School',
  'Harper H S',
  'Harper High School',
  'Harper Mc Caughan Elem School',
  'Harper Middle',
  'Harper Middle College High',
  'Harper Park Middle',
  'Harper Woods High School',
  'Harper Woods Middle School',
  'Harper Woods Virtual Academy',
  'Harper-archer Middle School',
  'Harpers Choice Middle',
  'Harpers Ferry Middle School',
  'Harpeth High School',
  'Harpeth Middle School',
  'Harpeth Valley Elementary',
  'Harpool Middle',
  'Harpst Academy',
  'Harpswell Coastal Academy',
  'Harpswell Community School',
  'Harpursville Junior-senior High School',
  'Harrah Elementary School',
  'Harrah Hs',
  'Harrah Ms',
  'Harran University',
  'Harrand Creek Elementary School',
  'Harrel El',
  'Harrell Accelerated Learning Center',
  'Harrell Budd El',
  'Harriet Beecher Stowe Elementary',
  'Harriet Bishop Elementary',
  'Harriet G. Eddy Middle',
  'Harriet Gifford Elem School',
  'Harriet Johnson Primary School',
  'Harriet Ross Tubman Academy',
  'Harriet Treem Elementary School',
  'Harriet Tubman Charter School',
  'Harriet Tubman Elementary School',
  'Harriet Tubman Village Charter',
  'Harriette Kirschen Elementary',
  'Harriman High School',
  'Harriman Middle School',
  'Harrington',
  'Harrington Avery D Sch',
  'Harrington El',
  'Harrington Elementary',
  'Harrington Elementary School',
  'Harrington High School',
  'Harrington Park School',
  'Harrington Waddell Elementary',
  'Harris Academy',
  'Harris Bilingual Elementary School',
  'Harris Co J J A E P',
  'Harris Co Jjaep',
  'Harris Co Sch For Accelerated Lrn',
  'Harris County Carver Middle School',
  'Harris County High School',
  'Harris County Juvenile Detention Center',
  'Harris County Youth Village',
  'Harris Creek Elementary',
  'Harris El',
  'Harris Elem.',
  'Harris Elementary School',
  'Harris Hill Elementary School',
  'Harris Intermediate',
  'Harris J R El',
  'Harris Middle',
  'Harris Middle School',
  'Harris Newmark Continuation',
  'Harris Park Elementary School',
  'Harris R P El',
  'Harris Road Middle',
  'Harris Sch',
  'Harris School',
  'Harris-hillman Special Education',
  'Harris-lake Park Elementary School',
  'Harris-lake Park High School',
  'Harris/jackson Community Learning Center',
  'Harrisburg Area Community College-​Harrisburg',
  'Harrisburg College & Career Preparatory School',
  'Harrisburg Elem.',
  'Harrisburg Elementary',
  'Harrisburg Elementary School',
  'Harrisburg High',
  'Harrisburg High School',
  'Harrisburg High School - 01',
  'Harrisburg Hs',
  'Harrisburg Hs - Scitech Cmp',
  'Harrisburg Jtc School',
  'Harrisburg Middle',
  'Harrisburg Middle School',
  'Harrisburg North Middle School - 08',
  'Harrisburg Pike Community School',
  'Harrisburg South Middle School - 03',
  'Harrison 7-8',
  'Harrison Alternative Education',
  'Harrison Avenue Elementary School',
  'Harrison Career Center',
  'Harrison Center',
  'Harrison Central Elementary',
  'Harrison Central High School',
  'Harrison Central Jr./sr. High School',
  'Harrison Co Child Development Cen',
  'Harrison Co Evolution High School',
  'Harrison Co Evolution Middle School',
  'Harrison Co Voc Complex',
  'Harrison Comm Learning Cntr',
  'Harrison Community High School',
  'Harrison County Alternative School',
  'Harrison County Area Technology Center',
  'Harrison County High School',
  'Harrison County Learning Center',
  'Harrison County Middle School',
  'Harrison County Spec Ed Coop',
  'Harrison East Elementary',
  'Harrison Education Center',
  'Harrison Elem School',
  'Harrison Elementary',
  'Harrison Elementary School',
  'Harrison Grade School',
  'Harrison High School',
  'Harrison Hill Elementary School',
  'Harrison Jr. High School',
  'Harrison Lane El',
  'Harrison Lyseth Elem School',
  'Harrison Middle',
  'Harrison Middle School',
  'Harrison Ms',
  'Harrison North Elementary School',
  'Harrison Park El Sch',
  'Harrison Park School',
  'Harrison Parkway Elementary School',
  'Harrison Prep School',
  'Harrison Primary Center',
  'Harrison Road Elementary',
  'Harrison Schmitt Elementary',
  'Harrison School',
  'Harrison Street Elem School',
  'Harrison Street Elementary',
  'Harrison Street Elementary School',
  'Harrison Township Elementary School',
  'Harrison-morton Ms',
  'Harrison/jefferson/madison Complex',
  "Harrisonburg H.s. Governor's Stem Academy",
  'Harrisonburg High',
  'Harrisonburg High School',
  'Harrisonville Elem.',
  'Harrisonville High',
  'Harrisonville Middle',
  'Harrisville Elementary',
  'Harrisville Elementary School',
  'Harrisville Junior-senior High School',
  'Harriton Shs',
  'Harritt Elementary School',
  'Harrold Ms',
  'Harrold School',
  'Harrowgate Elementary',
  'Harry Andersen Middle School',
  'Harry B. Flood Middle School',
  'Harry Bridges Span',
  'Harry C Withers El',
  'Harry C. Morrison Elem. School',
  'Harry C. Sharp Elementary School',
  'Harry D Jacobs High School',
  'Harry Dewey Fundamental Elementary',
  'Harry E Elden Elementary School',
  'Harry E Fry School',
  'Harry E. James Elementary',
  'Harry F Abate Elementary School',
  'Harry H Herndon',
  'Harry Hoag School',
  'Harry Kizirian Elementary',
  'Harry L Bain',
  'Harry L Edson School',
  'Harry L Mc Ginnis Middle School',
  'Harry Lee Cole',
  'Harry M Arndt Middle',
  'Harry M Fisher Elementary School',
  'Harry M. Bailey Middle School',
  'Harry M. Hurst Middle School',
  'Harry Mckillop El',
  'Harry N Mixon Intermediate School',
  'Harry P B Carden',
  'Harry Reid Elementary School',
  'Harry Russell Elementary School',
  'Harry S Truman El Sch',
  'Harry S Truman Elementary School',
  'Harry S Truman High School',
  'Harry S Truman Middle',
  'Harry S. Fisher Elementary School',
  'Harry S. Truman Elem.',
  'Harry S. Truman Elementary',
  'Harry S. Truman Elementary School',
  'Harry S. Truman Middle',
  'Harry S. Truman Middle School',
  'Harry S. Truman School',
  'Harry Shimotsu El',
  'Harry Stone Montessori Academy',
  'Harry Stone Montessori Academy (es)',
  'Harry Street Elem',
  'Harry W Lockley Early Learning Center',
  'Harry Wirtz Elementary',
  'Hart County High School',
  'Hart County Middle School',
  'Hart El',
  'Hart High School',
  'Hart Isd',
  'Hart Middle School',
  'Hart Ms',
  'Hart Rop',
  'Hart School',
  'Hart Street Elementary',
  'Hart-ransom Academic Charter',
  'Hart-ransom Elementary',
  'Harte (bret) Elementary',
  'Harte Elementary',
  'Harter Elementary School',
  'Hartford Alternative Education',
  'Hartford Area Career & Technology Center',
  'Hartford Avenue Elementary',
  'Hartford Correctional Center',
  'Hartford Elem School',
  'Hartford Elementary School',
  'Hartford High',
  'Hartford High School',
  'Hartford Jr./sr. High School',
  'Hartford Magnet Trinity College Academy',
  'Hartford Memorial Middle School',
  'Hartford Middle School',
  'Hartford Middle/high School',
  'Hartford Prekindergarten Magnet School',
  'Hartford Sylvia Encinas Elementary',
  'Hartford Upper Elementar',
  'Hartford Upper Elementary School',
  'Hartford-sumner Elementary School',
  'Hartington Newcastle High School',
  'Hartington-newcastle Elem At Hartington',
  'Hartington-newcastle Elem At Newcastle',
  'Hartland Consolidated School',
  'Hartland Elementary School',
  'Hartland Farms Intermediate School',
  'Hartland Fine Arts Leadership Academy',
  'Hartland High School',
  'Hartland Lakes Elementary School',
  'Hartland Ms At Ore Creek',
  'Hartland Round Elementary School',
  'Hartland School',
  'Hartland School Of Community Learning',
  'Hartland Village Elementary School',
  'Hartland Virtual Academy',
  'Hartley Elementary',
  'Hartley Elementary School',
  'Hartley School',
  'Hartley-melvin-sanborn Elementary School',
  'Hartley-melvin-sanborn High School',
  'Hartley-melvin-sanborn Middle School',
  'Hartly Elementary School',
  'Hartman El Ctr',
  'Hartman Elem School',
  'Hartman Intrmd Sch',
  'Hartman Middle',
  'Hartnell College',
  'Hartranft John F Sch',
  'Hartridge Academy',
  'Harts Bluff El',
  'Harts Hill School',
  'Harts Pk-8',
  'Hartsburg-emden Jr-sr High School',
  'Hartselle High School',
  'Hartselle Intermediate School',
  'Hartselle Junior High School',
  'Hartsfield El',
  'Hartshorn School',
  'Hartshorne Es',
  'Hartshorne Hs',
  'Hartshorne Ms',
  'Hartstern Elementary',
  'Hartsville High',
  'Hartsville Middle',
  'Hartvigsen School',
  'Hartville Elem.',
  'Hartville Elementary School',
  'Hartville High',
  'Hartwell Elementary School',
  'Hartwick College',
  'Hartwood El Sch',
  'Hartwood Elementary',
  'Harvard Avenue Performance Academy',
  'Harvard El',
  'Harvard Elem School',
  'Harvard Elementary',
  'Harvard Elementary School',
  'Harvard High School',
  'Harvard Jr High School',
  'Harvard Middle School',
  'Harvard Park Elem School',
  'Harvard University',
  'Harvard-kent',
  'Harvest Collegiate High School',
  'Harvest Elementary',
  'Harvest Elementary School',
  'Harvest High',
  'Harvest Hill S.t.e.a.m. Academy',
  'Harvest Hills Es',
  'Harvest Middle',
  'Harvest Park',
  'Harvest Park Middle',
  'Harvest Park Preschool Center',
  'Harvest Preparatory Academy',
  'Harvest Preparatory Academy Goodyear',
  'Harvest Preparatory Academy San Luis Az',
  'Harvest Preparatory School',
  'Harvest Ridge Cooperative Charter/placer Academy',
  'Harvest Ridge Elem.',
  'Harvest School',
  'Harvest Valley Elementary',
  'Harvey Austin School #97',
  'Harvey C Fenner Elementary School',
  'Harvey Clarke Elementary School',
  'Harvey Dunn Elementary - 54',
  'Harvey El',
  'Harvey Elementary',
  'Harvey Green Elementary',
  'Harvey High School',
  'Harvey Milk High School',
  'Harvey Mudd College',
  'Harvey N Dondero Elementary School',
  'Harvey Oaks Elementary School',
  'Harvey R Newlin Elementary',
  'Harvey Rice Elementary School',
  'Harvey S Brown El',
  'Harveyswanson Elementary School',
  'Harvie Elementary',
  'Harwell El',
  'Harwich Elementary School',
  'Harwinton Consolidated School',
  'Harwood Elementary School',
  'Harwood J H',
  'Harwood Union High School',
  'Harwood Union Middle School',
  'Hasan Kalyoncu University',
  'Hasbrouck Heights High School',
  'Hasbrouck Heights Middle School',
  'Hashemite University',
  'Haskell (pliny Fisk) Middle',
  'Haskell El',
  'Haskell Elem Sci Tech Engr Arts And Math (steam) Magnet',
  'Haskell Elementary School',
  'Haskell H S',
  'Haskell Hs',
  'Haskell Indian Nations University',
  'Haskell J H',
  'Haskell Ms',
  'Haskin Elementary School',
  'Haslet El',
  'Haslett High School',
  'Haslett Middle School',
  'Hassan Elementary',
  'Hassayampa Elementary School',
  'Hasselt University',
  'Hassler El',
  'Hasson Heights Sch',
  'Hastings Alternative Center',
  'Hastings College',
  'Hastings Early Child Special Ed.',
  'Hastings H S',
  'Hastings High School',
  'Hastings Juvenile Correctional',
  'Hastings Mallory Elementary School',
  'Hastings Middle',
  'Hastings Middle School',
  'Hastings Senior High School',
  'Hasty Elementary',
  'Hatboro-horsham Shs',
  'Hatch Valley Elementary',
  'Hatch Valley High',
  'Hatch Valley Middle',
  'Hatchery Hill Elementary School',
  'Hatchett El',
  'Hatfield El Sch',
  'Hatfield Elementary',
  'Hatfield Elementary School',
  'Hathaway Elementary',
  'Hathaway High School',
  'Hatherly Elementary',
  'Hatikvah International Charter School',
  'Hatley Elementary',
  'Hatley High School',
  'Hato Nuevo',
  'Hato Viejo Cumbre',
  'Hattie A. Watts Elementary School',
  'Hattie B Stokes Elementary School',
  'Hattie C. Warner Elementary School',
  'Hattie Cotton Elementary',
  'Hattie Martin Early Childhood Center',
  'Hattiesburg High School',
  'Hatton Clc',
  'Hatton Eielson Elementary School',
  'Hatton Eielson High School',
  'Hatton Elementary School',
  'Hatton High School',
  'Hatton-mccredie Elem.',
  'Hatyai University',
  'Haubstadt Community School',
  'Haude El',
  'Haugan Elem School',
  'Haugen Elementary',
  'Haughton High School',
  'Haughton Middle School',
  'Hauke Alter Ed',
  'Haun El',
  'Hauppauge High School',
  'Hauppauge Middle School',
  'Hauser Jr-sr High School',
  'Haut Gap Middle',
  'Hauton B Lee Middle School',
  'Hauula Elementary School',
  'Havana High School',
  'Havana Jr High School',
  'Havana Magnet School',
  'Havard (leona M) Early Education',
  'Havasu Preparatory Academy',
  'Havasuonline',
  'Havasupai Elementary School',
  'Havel Elementary School',
  'Havelock Elementary',
  'Havelock High',
  'Havelock Middle',
  'Haven Drive Middle',
  'Haven Elem',
  'Haven Elementary School',
  'Haven High',
  'Haven Middle School',
  'Haven Montessori Charter School',
  'Haven Virtual Academy',
  'Havencroft Elem',
  'Havens Elementary',
  'Havenview Middle',
  'Haverford College in Pennsylvania',
  'Haverford Ms',
  'Haverford Shs',
  'Haverhill Alternative School',
  'Haverhill Cooperative Middle School',
  'Haverhill Elementary School',
  'Haverhill High',
  'Haverling Senior High School',
  'Haverstraw Elementary School',
  'Haviland Avenue School',
  'Haviland Elem',
  'Haviland Elementary School',
  'Haviland Middle School',
  'Havre De Grace Elementary',
  'Havre De Grace High',
  'Havre De Grace Middle',
  'Havre High School',
  'Havre Middle School',
  'Haw Creek Elementary',
  'Haw River Elementary',
  'Hawaii Academy Of Arts & Science Pcs',
  'Hawaii Community College',
  'Hawaii Pacific University',
  'Hawaii School For The Deaf And Blind',
  'Hawaii Technology Academy - Pcs',
  'Hawaiian Avenue Elementary',
  'Hawaiian Elementary',
  'Hawarden Elementary School',
  'Hawassa University',
  'Hawes Elementary',
  'Hawes Elementary School',
  'Hawfields Middle',
  'Hawk El',
  'Hawk Eye Elementary',
  'Hawk Hollow Elem School',
  'Hawk Point Elem.',
  'Hawk Ridge Elementary',
  'Hawkes Bluff Elementary School',
  'Hawkeye Community College',
  'Hawking S.t.e.a.m. Charter',
  'Hawking S.t.e.a.m. Charter School 2',
  'Hawkins El',
  'Hawkins Elementary',
  'Hawkins Elementary School',
  'Hawkins H S',
  'Hawkins Middle',
  'Hawkins Middle School',
  'Hawkins Mill Elementary',
  'Hawkins Path School',
  'Hawkins Street School',
  'Hawkinsville High School',
  'Hawks Home School',
  'Hawks Nest Steam Academy',
  'Hawks Rise Elementary School',
  'Hawlemont Regional',
  'Hawler Medical University',
  'Hawley El',
  'Hawley Elementary',
  'Hawley Elementary School',
  'Hawley Environmental School',
  'Hawley H S',
  'Hawley Middle',
  'Hawley Secondary',
  'Haworth Es',
  'Haworth Hs',
  'Haworth Ms',
  'Haworth Public School',
  'Hawthorn Academy South Jordan',
  'Hawthorn Academy West Jordan',
  'Hawthorn Aspen Elem School',
  'Hawthorn Center',
  'Hawthorn Elem School',
  'Hawthorn Elem School North',
  'Hawthorn Elem School South',
  'Hawthorn Elem.',
  'Hawthorn High School',
  'Hawthorn Hill Elem.',
  'Hawthorn Hills Elementary',
  'Hawthorn Middle',
  'Hawthorn Middle School North',
  'Hawthorn Middle School South',
  'Hawthorn Sch Of Dual Language',
  'Hawthorn Townline Elem Sch',
  'Hawthorne Avenue School',
  'Hawthorne Brook',
  'Hawthorne Cedar Knolls Sr/jr High School',
  'Hawthorne Diploma Program',
  'Hawthorne Elem Scholastic Academy',
  'Hawthorne Elem School',
  'Hawthorne Elem.',
  'Hawthorne Elementary',
  'Hawthorne Elementary - 56',
  'Hawthorne Elementary School',
  'Hawthorne Elementary School - Everett',
  'Hawthorne Elementary School - Kennewick',
  'Hawthorne Elementary School - Seattle',
  'Hawthorne Es',
  'Hawthorne High',
  'Hawthorne High School',
  'Hawthorne Junior High School',
  'Hawthorne Math And Science Academy',
  'Hawthorne Middle',
  'Hawthorne Middle School',
  'Hawthorne Middle/high School',
  'Hawthorne Park Elelmentary School',
  'Hawthorne Pk-8 Academy',
  'Hawthorne Readiness Plus',
  'Hawthorne School',
  'Hawtree Creek Middle School',
  'Haxtun Elementary School',
  'Haxtun High School',
  'Hay Branch El',
  'Hay Elem Community Academy',
  'Hay Primary Center',
  'Hay Springs Early Childhood',
  'Hay Springs Elementary School',
  'Hay Springs High School',
  'Hay Springs Middle School',
  'Haycock Elementary',
  'Haydee Caballero',
  'Haydee Rexach',
  'Hayden Canyon Charter School',
  'Hayden Elementary School',
  'Hayden High School',
  'Hayden Meadows Elementary School',
  'Hayden Middle School',
  'Hayden Peak School',
  'Hayden Primary School',
  'Hayden R. Lawrence Upper Elementary School',
  'Hayden Valley Elementary School',
  'Hayden/mcfadden',
  'Haydock Academy Of Arts & Sciences',
  'Hayes Bilingual School',
  'Hayes Center Elementary School',
  'Hayes Center Secondary School',
  'Hayes Cooper Center For Math Sc Tec',
  'Hayes Elementary',
  'Hayes Elementary School',
  'Hayes Es',
  'Hayes Freedom High School',
  'Hayes Intermediate School',
  'Hayes K8',
  'Hayes Leonard Elementary School',
  'Hayes Lewis Elementary School',
  'Hayes Middle',
  'Hayes Middle School',
  'Hayes Primary School',
  'Hayestown Avenue School',
  'Hayesville Elementary',
  'Hayesville Elementary School',
  'Hayesville High',
  'Hayesville Middle',
  'Hayfield Elementary',
  'Hayfield Secondary',
  'Hayfork High',
  'Hayfork Valley Elementary',
  'Hayhurst Elementary School',
  'Haymarket Elementary School',
  'Haymon-morris Middle School',
  'Haynal Imre University of Health Sciences Budapest',
  'Haynes Academy School For Advanced Studies',
  'Haynes Bridge Middle School',
  'Haynes Charter For Enriched Studies',
  'Haynes Early Education Center',
  'Haynes El',
  'Haynes Inman Education Center',
  'Haynes Middle',
  'Haynesfield Elementary',
  'Haynesville Elementary School',
  'Haynesville Jr./sr. High School',
  'Hayneville Middle School',
  'Haynie Elementary School',
  'Haynor School',
  'Hays Co Juvenile Justice Alt Ed Prog',
  'Hays Co Juvenile Justice Alternative Center',
  'Hays High',
  'Hays Middle School',
  'Hays-lodge Pole 7-8',
  'Hays-lodge Pole High Sch',
  'Hayshire El Sch',
  'Haysville Middle School',
  'Haysville West Middle School',
  'Hayt Elem School',
  'Hayti High',
  'Hayward 4 Learning',
  'Hayward Ctr For Individualized Learning',
  'Hayward Elementary - 38',
  'Hayward Elementary School',
  'Hayward Group Home',
  'Hayward High',
  'Hayward Intermediate',
  'Hayward Middle',
  'Hayward Middle School',
  'Hayward Primary',
  'Hayward Smith Es',
  'Haywood Community College',
  'Haywood Early College',
  'Haywood Elementary',
  'Haywood High School',
  'Haywood Middle School',
  'Haywood Public School',
  'Hazara University',
  'Hazard Community College Tech Campus',
  'Hazard Community and Technical College',
  'Hazard High School',
  'Hazard Middle School',
  'Hazardville Memorial School',
  'Hazel Avenue Elementary School',
  'Hazel Dell Elem School',
  'Hazel Dell Elementary School',
  'Hazel Fischer Elementary',
  'Hazel Green Elementary School',
  'Hazel Green High School',
  'Hazel Grove Elem',
  'Hazel Grove Elem.',
  'Hazel Grove Elementary School',
  'Hazel Harvey Elementary School',
  'Hazel Harvey Peace El',
  'Hazel Ingram El',
  'Hazel M. Bailey Primary',
  'Hazel Park Access Alternative School',
  'Hazel Park Adult Education',
  'Hazel Park Advantage Alternative School',
  'Hazel Park Esl 9 Mile',
  'Hazel Park High School',
  'Hazel Park Junior High School',
  'Hazel Park Preparatory Academy',
  'Hazel Park Training Facility',
  'Hazel Park Training Facility Ii',
  'Hazel Park/hilda Knoff School',
  'Hazel S Pattison El',
  'Hazel Strauch Elementary',
  'Hazel Stuart Elementary School',
  'Hazel Valley Elementary',
  'Hazel Wolf K-8',
  'Hazelbrook Middle School',
  'Hazeldale Elementary School',
  'Hazelgreen Elem School',
  'Hazeltine Avenue Elementary',
  'Hazelton Elementary',
  'Hazelton-moffit-braddock Elementary School',
  'Hazelton-moffit-braddock High School',
  'Hazelwood Central High',
  'Hazelwood East High',
  'Hazelwood Elementary',
  'Hazelwood Elementary School',
  'Hazelwood Elementary/middle',
  'Hazelwood Middle School',
  'Hazelwood West High',
  'Hazen Elementary School',
  'Hazen High School',
  'Hazen Senior High School',
  'Hazen Uhsd # 26',
  'Hazle Township Early Learning Center',
  'Hazlehurst Elementary School',
  'Hazlehurst High School',
  'Hazlehurst Middle School',
  'Hazlet Middle School',
  'Hazleton Area Career Center',
  'Hazleton Area Hs',
  'Hazleton El/ms',
  'Hazlewood Elementary School',
  'Hb Woodlawn Secondary Program',
  'Hc Jjaep - Excel Academy',
  'Hcc Life Skills Program',
  'Hcs Early College High',
  'He Dog Elementary - 05',
  'Head Elementary School',
  'Head Middle',
  "Head O'meadow Elementary School",
  'Head Start',
  'Head Start At Garfield',
  'Head Start Program',
  'Head Start/eceap Preschool',
  'Headland Elementary School',
  'Headland High School',
  'Headland Middle School',
  'Headstart',
  'Headstart Eastland',
  'Headstart Preschool Special Education',
  'Headwaters Educ Learning Program',
  'Headway Academy',
  'Headway Academy North',
  'Healdsburg Charter',
  'Healdsburg Elementary',
  'Healdsburg High',
  'Healdsburg Junior High',
  'Healdton Es',
  'Healdton Hs',
  'Healdton Ms',
  'Health & Family Resource Center (hfrc)',
  'Health & Science School',
  'Health Careers Academy',
  'Health Careers H S',
  'Health Leadership High School',
  'Health Opportunities High School',
  'Health Sciences & Human Services',
  'Health Sciences Charter School',
  'Health Sciences High',
  'Health Sciences Middle',
  'Health Sciences University',
  'Health sciences University of Mongolia',
  'Health/wellness Envi Magnet',
  'Healthy Learning Academy Charter School',
  'Healthy Start Academy',
  'Healy Elem',
  'Healy Elem School',
  'Healy High',
  'Healy Murphy Pk',
  'Healy Secondary',
  'Healy-murphy',
  'Heard County High School',
  'Heard County Middle School',
  'Heard Elementary School',
  'Heard Magnet School',
  'Heard Street',
  'Heard-mixon Elementary School',
  'Heards Ferry Elementary School',
  'Hearing Impaired',
  'Hearn Academy The - A Ball Charter School',
  'Hearn Elementary',
  'Hearne El',
  'Hearne H S',
  'Hearne J H',
  'Hearst Elem School',
  'Hearst Elementary',
  'Hearst Es',
  'Heart Butte 6-8',
  'Heart Butte Elementary',
  'Heart Butte High School',
  'Heart Mountain Academy',
  'Heart Of The Lake Elementary',
  'Heart River Elementary School',
  'Hearthwood Elementary School',
  'Heartland Career Center',
  'Heartland Community College',
  'Heartland Community Elementary',
  'Heartland Community High Sch',
  'Heartland Elementary',
  'Heartland Elementary School',
  'Heartland Girls Ranch',
  'Heartland High (continuation)',
  'Heartland High School',
  'Heartland Ms',
  'Heartland R-v Sch.',
  'Heartland Ranch Elementary School',
  'Heartland School',
  'Heartlands Institute Of Technology',
  'Heartwood School',
  'Heath',
  'Heath Elementary',
  'Heath Elementary School',
  'Heath High School',
  'Heath Middle School',
  'Heath Springs Elementary',
  'Heath-brook',
  'Heathcote School',
  'Heather Elementary',
  'Heather Glen El',
  'Heather Hill Elem School',
  'Heather Hills Elementary',
  'Heather Ridge High School',
  'Heather Ridge Middle School',
  'Heatherbrae School',
  'Heatherstone Elem',
  'Heatherwood Elementary School',
  'Heatherwood Middle School',
  'Heathrow Elementary School',
  'Heatly School',
  'Heaton Elementary',
  'Heavener Es',
  'Heavener Hs',
  'Heavilin Elementary School',
  'Hebard Elementary',
  'Hebbronville El',
  'Hebbronville H S',
  'Hebbronville J H',
  'Hebbville Elementary',
  'Hebei Academy of Fine Art',
  'Hebei Agricultural University',
  'Hebei Medical University',
  'Hebei Normal University',
  'Hebei United University',
  'Hebei University',
  'Hebei University of Economics and Trade',
  'Hebei University of Science and Technology',
  'Hebei University of Technology',
  'Heber Elementary',
  'Heber Hunt Elem.',
  'Heber Springs Elem. School',
  'Heber Springs High School',
  'Heber Springs Middle School',
  'Heber Valley School',
  'Hebrew Language Academy Charter School',
  'Hebrew Language Academy Charter School 2',
  'Hebrew University of Jerusalem',
  'Hebron - Harman Elementary',
  'Hebron 9th Grade Center',
  'Hebron Avenue School',
  'Hebron Elementary School',
  'Hebron H S',
  'Hebron High School',
  'Hebron Middle School',
  'Hebron Station School',
  'Hebron University',
  'Hebron Valley El',
  'Heck/quaw Elementary',
  'Hector A. Cafferata Jr Elementary School',
  'Hector Elementary School',
  'Hector G. Godinez',
  'Hector Hernandez Arana (intermedia Bo Piletas)',
  'Hector High School',
  'Hector I. Rivera',
  'Hector J Garcia Early College H S',
  'Hector P Garcia El',
  'Hector P Garcia Middle',
  'Hedden Elementary School',
  'Hedding Grade Sch',
  'Hedenkamp (anne And William) Elementary',
  'Hedgcoxe El',
  'Hedge',
  'Hedgepeth Williams Middle School',
  'Hedges Elem School',
  'Hedgesville Elementary School',
  'Hedgesville High School',
  'Hedgesville Middle School',
  'Hedley School',
  'Hedrick El',
  'Hedrick Middle',
  'Hedrick Middle School',
  'Heeia Elementary School',
  'Hefei University of Technology',
  'Hefferan Elem School',
  'Heflin El',
  'Hefner Ms',
  'Hegins-hubley El Sch',
  'Hehai University',
  'Heide Trask High',
  'Heidelberg College',
  'Heidelberg High School',
  'Heidelberg Junior High School',
  'Heidelberg School Math & Science',
  'Heights El',
  'Heights Elementary',
  'Heights Elementary School',
  'Heights H S',
  'Heights High',
  'Heights Middle School',
  'Heights Terrace El/ms',
  'Heights/murray El Sch',
  'Heilongjiang August 1st Reclamation University',
  'Heilongjiang Commercial University',
  'Heilongjiang University',
  'Heim Elementary School',
  'Heim Middle School',
  'Heiman Elementary School',
  'Heineman Middle School',
  'Heinrich-Heine Universität Düsseldorf',
  'Heinz Kaiser Elementary',
  'Heir Force Community School',
  'Heisei International University',
  'Heizer Middle School',
  'Heketi Community Charter School',
  'Hela High School',
  'Helbing El',
  'Helemano Elementary School',
  'Helen A Hansen Elementary School',
  'Helen A. Fort Middle School',
  'Helen Arnold Community Learning Center',
  'Helen B Duffield Elementary School',
  'Helen B. Stafford Elementary',
  'Helen Ball El',
  'Helen Baller Elem',
  'Helen Bernstein High',
  'Helen C Cannon Junior High School',
  'Helen Carr Castello Elementary',
  'Helen Cordero Primary',
  'Helen Cox High School',
  'Helen Edward Early Childhood Center',
  'Helen Estock Elementary',
  'Helen Griffith Elementary School',
  'Helen Haller Elementary School',
  'Helen Hansen Elementary',
  'Helen Herr Elementary School',
  'Helen Hunt Jackson College Prep High School',
  'Helen Hunt Jackson Elementary',
  'Helen I. Smith School',
  'Helen J Stewart School',
  'Helen Jydstrup Elementary School',
  'Helen Keeling Elementary School',
  'Helen Keller Elem School',
  'Helen Keller Elementary',
  'Helen Keller Jhs',
  'Helen Keller Middle',
  'Helen Keller Middle School',
  'Helen L Beeler',
  'Helen L. Dollahan Elementary',
  'Helen Lemme Elementary School',
  'Helen M Davis School',
  'Helen M Smith Elementary School',
  'Helen M. Knight School',
  'Helen M. Lehman Elementary',
  'Helen M. Wilcox Elementary',
  'Helen Major El',
  'Helen Morgan School',
  'Helen Paul Learning Ctr',
  'Helen R Donaghue School',
  'Helen S Dunn Elementary School',
  'Helen Stacey Middle',
  'Helen Steiner Rice Es',
  'Helen Street School',
  'Helen Thackston Charter School',
  'Helen Thompson School',
  'Helen Tyson Middle School',
  'Helen Y. Davis Leadership Academy Charter Public School',
  'Helena College University of Montana',
  'Helena Elem.',
  'Helena Elementary',
  'Helena Elementary School',
  'Helena Flats 7-8',
  'Helena Flats School',
  'Helena High School',
  'Helena Intermediate School',
  'Helena Middle',
  'Helena Middle School',
  'Helena Park El',
  'Helendale Elementary',
  'Helendale Road Primary School',
  'Helene Grant Headstart',
  'Helensview High School',
  'Helfrich Park Stem Academy',
  'Heliotrope Avenue Elementary',
  'Helix High',
  'Helix School',
  'Helke Elementary School',
  'Hellen Caro Elementary School',
  'Hellenic Army Academy',
  'Hellenic Classical Charter School',
  'Hellenic Open University',
  'Heller Elem',
  'Hellgate High School',
  'Hellgate Middle School',
  'Hellstern Middle School',
  'Helm Elementary',
  'Helman Elementary School',
  'Helms El',
  'Helms Middle',
  'Helmsburg Elementary School',
  'Helmville School',
  'Helmwood Heights Elementary School',
  'Helotes El',
  'Help University College',
  'Helper Middle',
  'Helping Hand',
  'Helping Hands',
  'Helsinki School of Economics and Business Administration',
  'Helsinki University of Technology',
  'Heltonville Elementary School',
  'Helwan University',
  'Hem Junior/senior High School',
  'Hembree Springs Elementary School',
  'Hemby Bridge Elementary',
  'Hemchandracharay North Gujarat University',
  'Hemenway',
  'Hemet Elementary',
  'Hemet High',
  'Hemeterio Colon',
  'Hemingford Elementary School',
  'Hemingford High School',
  'Hemingway Career And Technology Center',
  'Hemingway Elementary',
  'Hemingway High',
  'Hemingway Mb Lee Middle',
  'Hemlock Alternative Education',
  'Hemlock Creek Elementary',
  'Hemlock Elementary',
  'Hemlock Elementary School',
  'Hemlock High School',
  'Hemlock High School Early Middle College',
  'Hemlock Middle School',
  'Hemlock School',
  'Hemmenway El',
  'Hemmerling Elementary',
  'Hemmeter Elementary School',
  'Hempfield Area Shs',
  'Hempfield El Sch',
  'Hempfield Shs',
  'Hemphill El',
  'Hemphill Elementary School',
  'Hemphill H S',
  'Hemphill Middle',
  'Hemphill Middle School',
  'Hempstead Alternative Learning Center',
  'Hempstead El',
  'Hempstead Elementary School',
  'Hempstead H S',
  'Hempstead High School',
  'Hempstead Middle',
  'Hemwati Nandan Bahuguna Garhwal University',
  'Henagar Junior High School',
  'Henan Agriculture University',
  'Henan Buddhist College',
  'Henan Normal University',
  'Henan Univeristy',
  'Henderson',
  'Henderson Academy',
  'Henderson Bay Alt High School',
  'Henderson Collegiate',
  'Henderson Community College',
  'Henderson County Career Academy',
  'Henderson County Early College',
  'Henderson County High School',
  'Henderson County North Middle School',
  'Henderson County South Middle School',
  'Henderson El',
  'Henderson Elem School',
  'Henderson Elem.',
  'Henderson Elementary',
  'Henderson Elementary School',
  'Henderson H S',
  'Henderson Hammock Charter School',
  'Henderson High',
  'Henderson J El',
  'Henderson J H',
  'Henderson Middle',
  'Henderson Middle School',
  'Henderson Mill Elementary School',
  'Henderson N El',
  'Henderson/ward-stewart Elementary',
  'Hendersonville Elementary',
  'Hendersonville High',
  'Hendersonville High School',
  'Hendersonville Middle',
  'Hendley Es',
  'Hendrick Hudson High School',
  'Hendrick Middle',
  'Hendrick Ranch Elementary',
  'Hendricks Alc',
  'Hendricks Avenue Elementary School',
  'Hendricks Elem Community Academy',
  'Hendricks Elementary',
  'Hendricks Elementary School',
  'Hendricks Middle School',
  'Hendricks Middle School Alc',
  'Hendricks Preschool',
  'Hendricks Senior High',
  'Hendrickson H S',
  'Hendrix College',
  'Hendrix Junior High School',
  'Hendron Lone Oak Elementary School',
  'Hendry Park Elementary School',
  'Hendry Virtual Instruction Program',
  'Hendry Virtual Instruction Program District Provided',
  'Hendy Avenue School',
  'Henking Elem School',
  'Henley Elementary School',
  'Henley High School',
  'Henley Middle School',
  'Hennepin Elementary School',
  'Hennepin Gateway To College',
  'Hennepin Technical College',
  'Hennepin Technical Pathways',
  'Hennessey Hs',
  'Hennessey Lower Es',
  'Hennessey Upper Es',
  'Henniker Community School',
  'Henning Elementary',
  'Henning Secondary',
  'Henninger High School',
  'Henri A. Yelle',
  'Henrico High',
  'Henrico Juvenile Detention Home - James River',
  'Henrietta El',
  'Henrietta H S',
  'Henrietta Middle',
  'Henry',
  'Henry & Evelyn Bozarth Elementary School',
  'Henry (patrick) Elementary',
  'Henry A Wallace Elementary School',
  'Henry A. Coffeen Elementary',
  'Henry Abbott Technical High School',
  'Henry B Gonzalez El',
  'Henry B Gonzalez Elementary',
  'Henry B. Burkland Elementary School',
  'Henry B. Milnes Elementary School',
  'Henry B. Whitehorne Middle School',
  'Henry B. Wilson Family School',
  'Henry Barnard - Educational Counseling Program (ecp)',
  'Henry Barnard School',
  'Henry Bauerschlag El',
  'Henry C Sanborn Elementary',
  'Henry C. Beck Middle School',
  'Henry C. Maxwell Elementary',
  'Henry Charles W Sch',
  'Henry Clay Elementary',
  'Henry Clay High School',
  'Henry Co High School',
  'Henry Co/martinsville Reg Pgm',
  'Henry County Early Childhood',
  'Henry County High School',
  'Henry County Middle School',
  'Henry County Virtual Campus',
  'Henry Cuellar El',
  'Henry D Perry Educational Center',
  'Henry D. Ward Elementary',
  'Henry Dalton Elementary',
  'Henry David Thoreau Continuation',
  'Henry Defer Intermediate Elementary School',
  'Henry Dunkerson Pathways Academy',
  'Henry E Harris Community School',
  'Henry E Warren Elementary',
  'Henry E. Bonner Elementary',
  'Henry E. Lackey High School',
  'Henry E.s. Reeves Elementary School',
  'Henry Eissler Elementary',
  'Henry Elem School',
  'Henry Elem.',
  'Henry Elementary',
  'Henry Elementary - 02',
  'Henry Elementary School',
  'Henry F Lamuth Middle School',
  'Henry F. Bishop Elementary',
  'Henry F. Kammann Elementary',
  'Henry F. Kite Elementary School',
  'Henry F. Moss Middle School',
  'Henry Ford Academy',
  'Henry Ford Academy Alameda School For Art + Design',
  'Henry Ford Academy School For Creative Studies Elementary',
  'Henry Ford Academy School For Creative Studies Psad',
  'Henry Ford Community College',
  'Henry Ford Early College',
  'Henry Ford Early Collegeadvanced Manufacturing',
  'Henry Ford El',
  'Henry Ford Elementary',
  'Henry Ford Elementary School',
  'Henry Ford Ii High School',
  'Henry Ford Middle School',
  'Henry Grew',
  'Henry H Wells Middle School',
  'Henry H. Filer Middle School',
  'Henry Haight Elementary',
  'Henry Hank Oyama',
  'Henry Heights Elementary School',
  'Henry High',
  'Henry High School - 01',
  'Henry High School - Sws',
  'Henry Hudson Regional School',
  'Henry J Kaiser Elementary School',
  'Henry J Kaiser High School',
  'Henry J Kalfas Magnet School',
  'Henry J Robinson Middle',
  'Henry J. Kaiser High',
  'Henry J. Mclaughlin Middle School',
  'Henry J. Winters School',
  'Henry James Memorial School',
  'Henry Johnson Charter School',
  'Henry Karrer Middle School',
  'Henry L Cottrell School',
  'Henry L Slater Elementary School',
  'Henry L Stimson Middle School',
  'Henry L. Bonsall Family School',
  'Henry L. Sneed Middle',
  'Henry Leavenworth',
  'Henry Lord Community School',
  'Henry M. Flagler Elementary School',
  'Henry M. Gunn High',
  'Henry M. Jackson High School',
  'Henry Mayfield Elementary School',
  'Henry Mcneal Turner Learning Academy',
  'Henry Metzger Middle',
  'Henry Middle',
  'Henry Middle School - 03',
  'Henry Miller Elementary',
  'Henry Niedermeier Center For Education',
  'Henry P Clough',
  'Henry P Fieler Elementary School',
  'Henry P. Becton Regional High School',
  'Henry P. Mohr Elementary',
  'Henry Perrine Baldwin High School',
  'Henry Puffer School',
  'Henry Raab Elem School',
  'Henry S Evans Elementary School',
  'Henry S. West Laboratory School',
  'Henry Scott Middle',
  'Henry Senior High',
  'Henry Sibley High School',
  'Henry Snyder High School',
  'Henry T. Gage Middle',
  'Henry Timrod Elementary',
  'Henry V Burger Junior High School',
  'Henry V. Adams Elementary School',
  'Henry W Cowherd Middle School',
  'Henry W Eggers Middle School',
  'Henry W Longfellow Career Exploration Academy',
  'Henry W Longfellow Elem School',
  'Henry W Sory El',
  'Henry W. Longfellow',
  'Henry W. Moore School',
  'Henry Wertheimer Middle',
  'Henry Whittemore Elementary School',
  'Henry Wilson Memorial School',
  'Henry Winkelman Elem School',
  'Henry World School',
  'Henry Zarrow International Sch',
  'Henry-senachwine Grade School',
  'Henry-senachwine High School',
  'Henryetta Es',
  'Henryetta Hs',
  'Henryetta Ms',
  'Henrys Fork Elementary',
  'Henryville Elementary School',
  'Henryville Jr & Sr High School',
  'Hepburn-lycoming Primary Sch',
  'Hephzibah Elementary School',
  'Hephzibah High School',
  'Hephzibah Middle School',
  'Heppner Elementary School',
  'Heppner Junior/senior High School',
  'Heraclio H. Rivera Colon',
  'Herald Es',
  'Herald Whitaker Middle School',
  'Herat University',
  'Herberich Primary Elementary School',
  'Herbert A Derfelt Elementary School',
  'Herbert A. Ammons Middle School',
  'Herbert A. Wood Elementary',
  'Herbert Akins Rd Elementary',
  'Herbert C. Green Middle',
  'Herbert C. Hoover Middle School',
  'Herbert Clark Hoover',
  'Herbert H Lehman High School',
  'Herbert H. Cruickshank Middle',
  'Herbert Hoover Elementary',
  'Herbert Hoover Elementary School',
  'Herbert Hoover High',
  'Herbert Hoover High School',
  'Herbert Hoover Middle',
  'Herbert Hoover Middle School',
  'Herbert J. Saunders Middle',
  'Herbert Marcus El',
  'Herbert Mills Elementary School',
  'Herbert N. Richardson 21st Century School',
  'Herbert Slater Middle',
  'Herbertsville Elementary School',
  'Herbin Metz Education Center',
  'Herbison Woods Elementary School',
  'Herculaneum High',
  'Hercules High',
  'Hercules Middle',
  "Here's Help",
  'Hereford Center For Accelerated Learning',
  'Hereford El Sch',
  'Hereford Elementary - 08',
  'Hereford H S',
  'Hereford High',
  'Hereford J H',
  'Hereford Middle',
  'Hereford Preparatory Academy',
  'Herff El',
  'Herfurth El',
  'Herget Middle School',
  'Herig School',
  'Herington Elem',
  'Herington High',
  'Herington Middle Sch',
  'Heriot-Watt University',
  'Heritage',
  'Heritage Academy',
  'Heritage Academy - Sws',
  'Heritage Academy Elementary',
  'Heritage Academy Laveen',
  'Heritage Academy Of Del Rio',
  'Heritage Academy Of San Antonio',
  'Heritage Academy Of Windcrest',
  'Heritage Academy Queen Creek',
  'Heritage Cccoe Special Education Programs',
  'Heritage Collegiate Leadership Academy',
  'Heritage Community Charter',
  'Heritage Community Day',
  'Heritage Digital Academy Charter Middle',
  'Heritage E-stem Magnet School',
  'Heritage El',
  'Heritage El Sch',
  'Heritage Elem School',
  'Heritage Elementary',
  'Heritage Elementary - Williams',
  'Heritage Elementary School',
  'Heritage Es',
  'Heritage Grove Middle School',
  'Heritage H S',
  'Heritage Heights Academy',
  'Heritage Heights Elementary School',
  'Heritage High',
  'Heritage High School',
  "Heritage High School Governor's Stem Academy",
  'Heritage Hill Elementary School',
  'Heritage Hills High School',
  'Heritage Hills Middle School',
  'Heritage Intermediate',
  'Heritage Intermediate Grd Ctr',
  'Heritage Intermediate School',
  'Heritage Jh And Elem Sch',
  'Heritage Jr/sr High School',
  'Heritage Junior High School',
  'Heritage K-8 Charter',
  'Heritage Lakes Elem School',
  'Heritage Landing',
  'Heritage Middle',
  'Heritage Middle School',
  'Heritage Oak Elementary',
  'Heritage Park High School',
  'Heritage Peak Charter',
  'Heritage Primary',
  'Heritage Rose El',
  'Heritage School',
  'Heritage School (the)',
  'Heritage Stem Academy',
  'Heritage Trails Community School',
  'Heritage Trails Es',
  'Heritage Valley Independent Study',
  'Herk Bouris Elementary',
  'Herkimer County Community College',
  'Herkimer Elementary School',
  'Herkimer High School',
  'Herkimer-fulton-hamilton-otsego Boces',
  'Herlong High',
  'Herma S. Simmons Elementary School',
  'Herman (leonard) Intermediate',
  'Herman Avenue Elementary School',
  'Herman Badillo Bilingual Academy',
  'Herman E Dummer',
  'Herman E Utley Middle',
  'Herman Elementary',
  'Herman Furlough Jr Middle',
  'Herman K Ankeney Middle School',
  'Herman L Bradt Elementary School',
  'Herman L. Horn Elementary',
  'Herman Lawson Early Childhood School',
  'Herman Leimbach Elementary',
  'Herman Secondary',
  'Hermann Elem.',
  'Hermann High',
  'Hermann Middle',
  'Hermantown Alternative Learning Prg',
  'Hermantown Early Childhood',
  'Hermantown Elementary',
  'Hermantown Middle',
  'Hermantown Senior High',
  'Hermes/intermediate School',
  'Hermina Diaz Aponte (lago Alto)',
  'Herminia C. Ramirez',
  'Herminia Garcia',
  'Herminia Rivera Fernandez (guaraguao Carretera)',
  'Herminio Sierra',
  'Herminio W Santaella',
  'Hermiston High School',
  'Hermitage Elem.',
  'Hermitage Elementary',
  'Hermitage Elementary School',
  'Hermitage High',
  'Hermitage High School',
  'Hermitage Middle',
  'Hermitage Springs Elementary School',
  'Hermleigh School',
  'Hermon Elementary School',
  'Hermon High School',
  'Hermon Hutchens Elementary',
  'Hermon Middle School',
  'Hermon-dekalb Central School',
  'Hermosa Drive Elementary',
  'Hermosa Elementary',
  'Hermosa Elementary - 04',
  'Hermosa Hgts Elementary',
  'Hermosa Middle School',
  'Hermosa Montessori Charter',
  'Hermosa Valley Elementary',
  'Hermosa View Elementary',
  'Hermosa Vista Elementary School',
  'Hernandez El',
  'Hernandez Elementary',
  'Hernandez Learning Center',
  'Hernandez Middle School',
  'Hernando County Adult Education',
  'Hernando County Jail',
  'Hernando Elem',
  'Hernando Elementary School',
  'Hernando Eschool Virtual Franchise',
  'Hernando High School',
  'Hernando High School/adult Education',
  'Hernando Hills Elementary',
  'Hernando Middle School',
  'Hernando Virtual Instruction',
  'Hernando Virtual Instruction (course Offerings)',
  'Hernando Virtual Instruction Program',
  'Herndon Career Ctr.',
  'Herndon Cons Elementary & Middle School',
  'Herndon Elementary',
  'Herndon High',
  'Herndon Magnet School',
  'Herndon Middle',
  'Herndon-barstow Elementary',
  'Hernwood Elementary',
  'Hero High School',
  'Herod El',
  'Heroes Academy Prek-5',
  'Heroes Elementary',
  'Heroes Middle School',
  'Heron',
  'Heron Creek Middle School',
  'Heron Heights Elementary School',
  'Heron Lake-okabena Elementary',
  'Heron Lake-okabena Secondary',
  'Heron Pond Elementary School',
  'Heronville Es',
  'Herreid Elementary - 02',
  'Herreid High School - 01',
  'Herreid Middle School - 03',
  'Herrera El',
  'Herrick Avenue Elementary',
  'Herrick Middle School',
  'Herrick Park Elementary School',
  'Herricks High School',
  'Herricks Middle School',
  'Herriman High',
  'Herriman School',
  'Herriman School #6',
  'Herrin C U S D 4 Elem School',
  'Herrin High School',
  'Herrin Middle School',
  'Herrington School',
  'Herron High School',
  'Herschel Jones Middle School',
  'Herscher High School',
  'Herscher Intermediate Sch',
  'Hershey Early Childhood Ctr',
  'Hershey Elementary School',
  'Hershey High School',
  'Hershey Hs',
  'Hershey Intrmd El Sch',
  'Hershey Ms',
  'Hershey Primary El Sch',
  'Hertford Co Early College',
  'Hertford County High',
  'Hertford County Middle',
  'Hertford Grammar',
  'Hertie School of Governance',
  'Herty Pri',
  'Herzen State Pedagogical University of Russia',
  'Herzl Elem School',
  'Herzog Elem.',
  'Hesby Oaks Leadership Charter',
  'Heskett Middle School',
  'Hesperia Community Day',
  'Hesperia Community Education',
  'Hesperia High',
  'Hesperia High School',
  'Hesperia Junior High',
  'Hesperia Middle School',
  'Hesperian Elementary',
  'Hesse School',
  'Hesston College',
  'Hesston Elem',
  'Hesston High',
  'Hesston Middle',
  'Hester Jr High School',
  'Hester Juvenile Detent',
  'Heston Edward Sch',
  'Heth-washington Elementary School',
  'Hettie Halstead El',
  'Hettinger Elementary School',
  'Hettinger High School',
  'Heuser Hearing & Language Academy',
  'Heusner Elem',
  'Heuvelton Central School',
  'Hevel Elementary',
  'Hewes Middle',
  'Hewitt El',
  'Hewitt School',
  'Hewitt-texas Elementary',
  'Hewitttrussville High School',
  'Hewitttrussville Middle School',
  'Hewlett Elementary School',
  'Heyburn Elementary School',
  'Heyer Elementary',
  'Heythrop College University of London',
  'Heyward C Bellamy Elem',
  'Heyward Gibbes Middle',
  'Heywood Avenue Elementary School',
  'Heywood Elementary School',
  'Heyworth Elem School',
  'Heyworth Jr-sr High School',
  'HfH – University of Applied Sciences of Special Needs Education',
  'Hh Browning Academy',
  'Hh Dow High School',
  'Hi-mount Elementary',
  'Hi-plains School District R-23',
  'Hialeah Elementary School',
  'Hialeah Gardens Elementary School',
  'Hialeah Gardens Middle School',
  'Hialeah Gardens Senior High School',
  'Hialeah Middle School',
  'Hialeah Senior High Adult Education Center',
  'Hialeah Senior High School',
  'Hialeah-miami Lakes Senior Adult',
  'Hialeah-miami Lakes Senior High',
  'Hiatt Middle School',
  'Hiawassee Elementary',
  'Hiawatha College Prep - Kingfield',
  'Hiawatha College Prep - Northrop',
  'Hiawatha Collegiate High School',
  'Hiawatha Elem',
  'Hiawatha Elem Sch Prek-8',
  'Hiawatha Elem School',
  'Hiawatha Elementary',
  'Hiawatha Elementary School',
  'Hiawatha High School',
  'Hiawatha Leadership Acad-morris Pk',
  'Hiawatha Leadership Acdy-northrop',
  'Hiawatha Middle School',
  'Hiawatha School',
  'Hiawatha Sr High',
  'Hibbard Elem School',
  'Hibbard Elementary School',
  'Hibbett School',
  'Hibbing Community College',
  'Hibbing High',
  'Hibiscus Elementary School',
  'Hibriten High',
  'Hichborn Middle School',
  'Hickerson Elementary',
  'Hickey El',
  'Hickey Elem.',
  'Hickman Charter',
  'Hickman Co Middle School',
  'Hickman Co Sr High School',
  'Hickman County Elementary School',
  'Hickman County High School',
  'Hickman El',
  'Hickman Elementary',
  'Hickman Middle',
  'Hickman Mills Freshman Center',
  'Hickok Elem',
  'Hickory Bend Elem School',
  'Hickory Career Arts Magnet High School',
  'Hickory Center Elementary School',
  'Hickory Creek Elementary School',
  'Hickory Creek Middle School',
  'Hickory Creek School',
  'Hickory Elementary',
  'Hickory Elementary School',
  'Hickory Flat Attendance Center',
  'Hickory Flat Elementary School',
  'Hickory Grove El Sch',
  'Hickory Grove Elementary',
  'Hickory Grove Elementary School',
  'Hickory Grove-sharon Elementary',
  'Hickory High',
  'Hickory Hill Elementary Sch',
  'Hickory Hills Elem.',
  'Hickory Hills Elementary School',
  'Hickory Hills Middle',
  'Hickory Hs',
  'Hickory Middle',
  'Hickory Point Elem School',
  'Hickory Ridge Elementary',
  'Hickory Ridge Elementary School',
  'Hickory Ridge High',
  'Hickory Ridge Middle',
  'Hickory Tavern Elementary/middle',
  'Hickory Tree Elementary School',
  'Hickory Woods Elementary School',
  'Hicks Canyon Elementary',
  'Hicks El',
  'Hicks Elementary School',
  'Hicks High School',
  'Hicksville Elementary School',
  'Hicksville High School',
  'Hicksville Middle School',
  'Hico El',
  'Hico H S',
  'Hidalgo Academy',
  'Hidalgo Co J J A E P',
  'Hidalgo County Boot Camp',
  'Hidalgo County J J A E P',
  'Hidalgo Early College H S',
  'Hidalgo Early Start Academy',
  'Hidalgo El',
  'Hidalgo J J A E P',
  'Hidalgo Park El',
  'Hidayatullah National Law University Raipur',
  'Hidden Cove El',
  'Hidden Creek Elementary School',
  'Hidden Forest El',
  'Hidden Hills Elementary',
  'Hidden Hills Elementary School',
  'Hidden Hollow El',
  'Hidden Hollow School',
  'Hidden Lake Elementary',
  'Hidden Lake Elementary School',
  'Hidden Lake High School',
  'Hidden Lakes El',
  'Hidden Oak Elementary School',
  'Hidden Oaks Elementary',
  'Hidden Oaks Elementary School',
  'Hidden Oaks Middle School',
  'Hidden River Middle School',
  'Hidden Springs Elementary',
  'Hidden Springs Elementary School',
  'Hidden Trails Elementary',
  'Hidden Valley Elementary',
  'Hidden Valley Elementary School',
  'Hidden Valley High',
  'Hidden Valley High School',
  'Hidden Valley Middle',
  'Hiddenite Elementary',
  'Hidenwood Elementary',
  'Higashi Nippon International University',
  'Higbee Elem.',
  'Higbee High',
  'Higgins Education Center',
  'Higgins Elem Community Academy',
  'Higgins School',
  'Higginson',
  'Higginson/lewis K-8',
  'High Bridge Elementary',
  'High Bridge Elementary School',
  'High Bridge Middle School',
  'High Country Elementary',
  'High Desert',
  'High Desert Community Day',
  'High Desert Correctional Center',
  'High Desert Juvenile Detention And Assessment Center',
  'High Desert Middle School',
  'High Desert Montessori Charter School',
  'High Desert Premier Academy',
  'High Desert State Prison',
  'High Grove Early Childhood',
  'High Hills Elementary',
  'High Horizons Magnet School',
  'High Institute for Banking & Financial Studies',
  'High Island El',
  'High Island Middle',
  'High Island School',
  'High Lakes Elementary School',
  'High Marq Environmental Charter School',
  'High Mount Elem School',
  'High Mountain Middle School',
  'High Mountain Road School',
  'High Peaks Elementary School',
  'High Plain Elementary',
  'High Plains - 06',
  'High Plains Comm Elem/clarks',
  'High Plains Community High Sch',
  'High Plains Community Ms',
  'High Plains Elementary School',
  'High Plains Elementary/polk',
  'High Plains School',
  'High Point Academy',
  'High Point Central High',
  'High Point East',
  'High Point El',
  'High Point Elem School',
  'High Point Elem.',
  'High Point Elementary',
  'High Point Elementary School',
  'High Point High',
  'High Point Regional High School',
  'High Point School',
  'High Point University',
  'High Pointe El',
  'High Pointe Elem.',
  'High Ridge Elem.',
  'High Rock School',
  'High Rolls Mountain Park Elementary',
  'High Sch-construction Trades Engineering & Architecture',
  'High School Ahead Academy',
  'High School At Brainard',
  'High School At Dunning',
  'High School At Johnson',
  'High School At Syracuse',
  'High School At Tekamah',
  'High School Fff',
  'High School For Arts And Business',
  'High School For Civil Rights',
  'High School For Community Leadership',
  'High School For Dual Language And Asian Studies',
  'High School For Energy And Technology',
  'High School For Environmental Studies',
  'High School For Excellence And Innovation',
  'High School For Global Citizenship (the)',
  'High School For Health Careers & Sciences',
  'High School For Health Professions & Human Services',
  'High School For Innovation In Advertising And Media',
  'High School For Language And Diplomacy (the)',
  'High School For Language And Innovation',
  'High School For Law & Public Service',
  'High School For Law Advocacy And Community Justice',
  'High School For Media & Communications',
  'High School For Medical Professions',
  'High School For Recording Arts',
  'High School For Service And Learning At Erasmus',
  'High School For Teaching And The Professions',
  'High School For Violin And Dance',
  'High School For Youth And Community Development',
  'High School Homebound',
  'High School In The Community',
  'High School Inc.',
  'High School Learning Center',
  'High School Math Science And Engineering At Ccny',
  'High School Of American Studies At Lehman College',
  'High School Of Applied Communications',
  'High School Of Arts And Technology',
  'High School Of Commerce',
  'High School Of Computers And Technology',
  'High School Of Contemporary Arts',
  'High School Of Economics & Finance',
  'High School Of Enterprise Business & Technology',
  'High School Of Fashion Industries (the)',
  'High School Of Health Sciences',
  'High School Of Hospitality Management',
  'High School Of Sports Management',
  'High School Of Telecommunications Arts And Technology',
  'High School Of World Cultures',
  'High School Re Entry',
  'High School Sails',
  'High School Steps 9-12',
  'High School Summer Program',
  'High School Ttt',
  'High Shoals Elementary School',
  'High Springs Community School',
  'High Step Transition',
  'High Street Head Start',
  'High Tech Early College',
  'High Tech Elementary',
  'High Tech Elementary Chula Vista',
  'High Tech Elementary Explorer',
  'High Tech Elementary North County',
  'High Tech Elementary School',
  'High Tech High',
  'High Tech High Chula Vista',
  'High Tech High International',
  'High Tech High Media Arts',
  'High Tech High North County',
  'High Tech High School',
  'High Tech La',
  'High Tech Middle',
  'High Tech Middle Chula Vista',
  'High Tech Middle Media Arts',
  'High Tech Middle North County',
  'High Technology High School',
  'Highbanks Learning Center',
  'Highbridge Green School (the)',
  'Highbridge Hill Elementary School',
  'Highcliff El Sch',
  'Highcrest Middle School',
  'Highcrest School',
  'Highcroft Elementary',
  'Highcroft Ridge Elem.',
  'Higher Colleges of Technology',
  'Higher Ground Academy',
  'Higher Ground Secondary Academy',
  'Higher Institute Of Arts & Tech',
  'Higher Institute of Agriculture and Animal Husbandry',
  'Higher Institute of Business Administration',
  'Higher Learning Academy',
  'Higher School o Business in Tarnow',
  'Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz',
  'Higher School of Economics',
  'Higher School of Psychology',
  'Higher School of University and Advanced Studies Pisa',
  'Higher Technological Institute',
  'Highfalls Elementary',
  'Highgate Heights',
  'Highgate School',
  'Highgrove Elementary',
  'Highland',
  'Highland Academy',
  'Highland Academy Charter',
  'Highland Acres Elementary School',
  'Highland Autism Center',
  'Highland Avenue Elementary School',
  'Highland Bluff Elementary',
  'Highland City Elementary School',
  'Highland Community College',
  'Highland Community Elementary',
  'Highland Community High',
  'Highland Community Middle',
  'Highland Community School',
  'Highland Creek Elementary',
  'Highland Drive Elementary School',
  'Highland East Jhs',
  'Highland El',
  'Highland El Sch',
  'Highland Elem',
  'Highland Elem School',
  'Highland Elem.',
  'Highland Elementary',
  'Highland Elementary School',
  'Highland Falls Intermediate School',
  'Highland Forest El',
  'Highland Free School',
  'Highland Gardens Elementary School',
  'Highland Grove Elementary',
  'Highland High',
  'Highland High School',
  'Highland Hills El',
  'Highland Hills Elementary School',
  'Highland Hills Middle School',
  'Highland Home School',
  'Highland Hts El',
  'Highland Jr High School',
  'Highland Jr.-sr. High',
  'Highland Junior High',
  'Highland Junior High School',
  'Highland Lakes El',
  'Highland Lakes Elementary School',
  'Highland Lakes School',
  'Highland Meadows El',
  'Highland Middle',
  'Highland Middle School',
  'Highland Mill Montessori',
  'Highland Ms',
  'Highland Oaks Elementary',
  'Highland Oaks Middle',
  'Highland Oaks Middle School',
  'Highland Pacific Elementary',
  'Highland Park Alter Ed Ctr',
  'Highland Park Central',
  'Highland Park Community School',
  'Highland Park Continuation',
  'Highland Park El',
  'Highland Park El Sch',
  'Highland Park Elem.',
  'Highland Park Elementary',
  'Highland Park Elementary School',
  'Highland Park Es',
  'Highland Park H S',
  'Highland Park High',
  'Highland Park High School',
  'Highland Park Middle',
  'Highland Park Middle School',
  'Highland Park School',
  'Highland Park Senior High',
  'Highland Pines School',
  'Highland Prep',
  'Highland Primary Center',
  'Highland Primary School',
  'Highland Ranch Elementary',
  'Highland Regional High School',
  'Highland Renaissance Academy',
  'Highland Residential Center',
  'Highland Rim School',
  'Highland Sch',
  'Highland Sch Of Technology',
  'Highland School',
  'Highland School At Kenwood Elementary School',
  'Highland Springs Elementary',
  'Highland Springs High',
  'Highland Terrace Elementary',
  'Highland View Elementary',
  'Highland Village El',
  'Highland West Jhs',
  'Highland-goffes Falls School',
  'Highland-turner Elementary School',
  'Highlander Charter',
  'Highlander Way Middle School',
  'Highlands',
  'Highlands Academy',
  'Highlands Community Charter',
  'Highlands County Hospital/homebound Program',
  'Highlands County Schools Jail Program',
  'Highlands El',
  'Highlands Elem',
  'Highlands Elem School',
  'Highlands Elementary',
  'Highlands Elementary School',
  'Highlands Grove Elementary School',
  'Highlands H S',
  'Highlands High',
  'Highlands High School',
  'Highlands Intermediate School',
  'Highlands J H',
  'Highlands Juvenile Detention Home',
  'Highlands Middle School',
  'Highlands Ms',
  'Highlands Ranch High School',
  'Highlands Ranch Hs Pre-k',
  'Highlands School',
  'Highlands Shs',
  'Highlands Virtual Franchise',
  'Highlands Virtual Instruction Program',
  'Highlands Virtual School',
  'Highlands Youth Academy',
  'Highlandtown Elementary #215',
  'Highlandtown Elementary #237',
  'Highlandville Elem.',
  'Highlawn Elementary School',
  'Highline Academy Northeast',
  'Highline Academy Southeast',
  'Highline Community College',
  'Highline Community Elementary School',
  'Highline High School',
  'Highline Home School Center',
  'Highline Open Doors 1418',
  'Highly Inquisitive And Versatile Education (hive) Preparator',
  'Highmark Charter School',
  'Highmeadow Common Campus School',
  'Highmore Elementary - 02',
  'Highmore High School - 01',
  'Highmore Jr. High - 03',
  'Highpoint',
  'Highpoint - Daep',
  'Highpoint East',
  'Highpoint School East',
  'Highpoint School East (crosby)',
  'Highpoint School East (daep)',
  'Highpoint School North',
  'Highpoint Virtual Academy Of Michigan',
  'Highridge Family Center',
  'Hightower El',
  'Hightower Elementary School',
  'Hightower H S',
  'Hightower Trail Elementary School',
  'Hightower Trail Middle School',
  'Hightstown High School',
  'Highview 6th Grade Center',
  'Highview Alc',
  'Highview Elementary School',
  'Highview Independent Study',
  'Highview Middle School',
  'Highview School',
  'Highville Charter School',
  'Highwood High School',
  'Highwood Hills Elementary',
  'Highwood Middle School',
  'Highwood School',
  'Higley High School',
  'Higley Traditional Academy',
  'Hiiraan University',
  'Hijiyama University',
  'Hiland High School',
  'Hiland Park Elementary School',
  'Hilbert Elementary',
  'Hilbert High',
  'Hilbert Middle',
  'Hilbert Middle School',
  'Hilburn Drive Academy',
  'Hilda C Escobar/alicia C Rios El',
  'Hilda J. Barbour Elementary',
  'Hilda L. Solis Lrng Acad Sch Of Tech Business And Ed',
  'Hilda Lahti Elementary School',
  'Hilda Raquel Mateo (santa Ana)',
  'Hildebran Elementary',
  'Hildebrandt Int',
  'Hilder Pearson Elementary',
  'Hildreth Elementary School',
  'Hilham Elementary',
  'Hill And Plain School',
  'Hill Campus Of Arts And Sciences',
  'Hill Center',
  'Hill Central Music Academy',
  'Hill City Elem',
  'Hill City Elementary',
  'Hill City Elementary - 02',
  'Hill City Elementary School',
  'Hill City High School - 01',
  'Hill City Junior-senior High',
  'Hill City Middle School',
  'Hill City Middle School - 04',
  'Hill City Senior High',
  'Hill Co J J A E P',
  'Hill Co Jjaep',
  'Hill College',
  'Hill Community Learning Center',
  'Hill Country El',
  'Hill Country H S',
  'Hill Country Middle',
  'Hill Country Youth Ranch',
  'Hill County Jjaep',
  'Hill Creek Elementary',
  'Hill El',
  'Hill Elem School',
  'Hill Elementary',
  'Hill Elementary School',
  'Hill Farm Elementary School',
  'Hill Field School',
  'Hill Freedman World Academy',
  'Hill House Passport Academy Charter Scho',
  'Hill Int',
  'Hill Intermediate Elementary School',
  'Hill Middle',
  'Hill Middle School',
  'Hill Regional Career High School',
  'Hill Top Colony School',
  'Hill View Elementary School',
  'Hill View Montessori Charter Public School',
  'Hill-gustat Middle School',
  'Hill-roberts Elementary School',
  'Hillandale Elementary',
  'Hillard Collins Elementary School',
  'Hillcrest',
  'Hillcrest 7-8',
  'Hillcrest Academy',
  'Hillcrest Academy-north',
  'Hillcrest Academy-south',
  'Hillcrest At Youth Services Center',
  'Hillcrest Colony Elementary',
  'Hillcrest Colony Elementary - 05',
  'Hillcrest Drive Elementary',
  'Hillcrest Education Center',
  'Hillcrest El',
  'Hillcrest El Sch',
  'Hillcrest Elem',
  'Hillcrest Elem School',
  'Hillcrest Elementary',
  'Hillcrest Elementary - 04',
  'Hillcrest Elementary School',
  'Hillcrest Es',
  'Hillcrest H S',
  'Hillcrest Hall Treatment Center',
  'Hillcrest Heights Elementary',
  'Hillcrest High',
  'Hillcrest High School',
  'Hillcrest Intermediate Sch',
  'Hillcrest Jr High',
  'Hillcrest Middle',
  'Hillcrest Middle School',
  'Hillcrest Pds Magnet',
  'Hillcrest Primary School',
  'Hillcrest School',
  'Hillcrest Special Services Center',
  'Hillcrest Steam Academy',
  'Hilldale Es',
  'Hilldale Hs',
  'Hilldale Lower Es',
  'Hilldale Ms',
  'Hilldale School',
  'Hilldale Upper Es',
  'Hillendale El Sch',
  'Hiller Elementary School',
  'Hillery T. Broadous Elementary',
  'Hillgrove High School',
  'Hilliard Bradley High School',
  'Hilliard City School District Preschool',
  'Hilliard Comstock Middle',
  'Hilliard Crossing Elementary School',
  'Hilliard Darby High School',
  'Hilliard Davidson High School',
  'Hilliard El',
  'Hilliard Elementary School',
  'Hilliard Heritage Middle School',
  'Hilliard Horizon Elementary School',
  'Hilliard Memorial Middle School',
  'Hilliard Middle-senior High',
  'Hilliard Station Sixth Grade Elementary School',
  'Hilliard Tharp Sixth Grade Elementary School',
  'Hilliard Weaver Middle School',
  'Hillis Elementary School',
  'Hillman Community Jrsr High School',
  'Hillman Elementary School',
  'Hillmccloy High School',
  'Hillpoint Elementary',
  'Hillrise Elementary',
  'Hillrise Elementary School',
  'Hills Chapel School',
  'Hills Elementary School',
  'Hills Garrison Elementary School',
  'Hills-beaver Creek Elementary',
  'Hills-beaver Creek Secondary',
  'Hills-hendersonville El Sch',
  'Hillsboro Alternative School',
  'Hillsboro Charter Academy',
  'Hillsboro Early Childhood Center',
  'Hillsboro El',
  'Hillsboro Elem',
  'Hillsboro Elem.',
  'Hillsboro Elementary',
  'Hillsboro Elementary School',
  'Hillsboro Elementary/ Middle School',
  'Hillsboro H S',
  'Hillsboro High',
  'Hillsboro High School',
  'Hillsboro Int',
  'Hillsboro Intermediate School',
  'Hillsboro J H',
  'Hillsboro Jr High School',
  'Hillsboro Jr. High',
  'Hillsboro Middle Elem.',
  'Hillsboro Middle School',
  'Hillsboro Middle/high School',
  'Hillsboro Online Academy',
  'Hillsboro Primary',
  'Hillsboro Primary School',
  'Hillsboro Treatment Ctr.',
  'Hillsboro-deering Elementary School',
  'Hillsboro-deering High School',
  'Hillsboro-deering Middle School',
  'Hillsborough Academy Of Math And Science',
  'Hillsborough Community College',
  'Hillsborough Elementary',
  'Hillsborough Ese Contracted Residential Placement',
  'Hillsborough Girls Academy',
  'Hillsborough High School',
  'Hillsborough Township Elementary School',
  'Hillsborough Township Middle School',
  'Hillsborough Virt Instr Prgs',
  'Hillsborough Virtual Franchise High School',
  'Hillsborough Virtual Instruction Course Offerings',
  'Hillsborough Virtual School',
  'Hillsdale College',
  'Hillsdale El Sch',
  'Hillsdale Elementary',
  'Hillsdale Elementary School',
  'Hillsdale High',
  'Hillsdale High School',
  'Hillsdale Isd Local Programs',
  'Hillsdale Middle',
  'Hillsdale Middle School',
  'Hillsdale Preparatory School',
  'Hillsdale School',
  'Hillsdale Workforce Devtech Center',
  'Hillside Acad For Excel',
  'Hillside Arts And Letters Academy',
  'Hillside Avenue School',
  'Hillside Colony Elementary - 04',
  'Hillside Colony High School - 07',
  'Hillside Colony School',
  'Hillside Conant School',
  'Hillside El',
  'Hillside El Sch',
  'Hillside Elem',
  'Hillside Elem School',
  'Hillside Elementary',
  'Hillside Elementary School',
  'Hillside Grade School',
  'Hillside High',
  'Hillside High School',
  'Hillside Intermediate School',
  'Hillside Junior High School',
  'Hillside Junior/senior High',
  'Hillside Learning Behavior Center',
  'Hillside Middle',
  'Hillside Middle School',
  'Hillside New Tech High',
  'Hillside School',
  'Hillsmere Elementary',
  'Hillsville Elementary',
  'Hilltonia Middle School',
  'Hilltop Community Elementary School',
  'Hilltop Day Care And Preschool',
  'Hilltop Drive Elementary',
  'Hilltop El',
  'Hilltop El Sch',
  'Hilltop Elem School',
  'Hilltop Elementary',
  'Hilltop Elementary School',
  'Hilltop High School',
  'Hilltop Middle',
  'Hilltop Primary School',
  'Hilltop School',
  'Hilltop Senior High',
  'Hilltown Cooperative Charter Public School',
  'Hillview Academy',
  'Hillview Crest Elementary',
  'Hillview Elem.',
  'Hillview Elementary School',
  'Hillview High (continuation)',
  'Hillview Intermediate Center',
  'Hillview Junior High',
  'Hillview Middle',
  'Hillview School',
  'Hillwood High',
  'Hillwood Middle',
  'Hillyard Technical Ctr.',
  'Hilmar High',
  'Hilmar Middle',
  'Hilo High School',
  'Hilo Intermediate School',
  'Hilo Union Elementary School',
  'Hilsman Middle School',
  'Hilton D. Bell Intermediate',
  'Hilton Elementary',
  'Hilton Elementary School',
  'Hilton Head Island Early Childhood Center',
  'Hilton Head Island Elementary',
  'Hilton Head Island High',
  'Hilton Head Island Middle',
  'Hilton Head Island School For The Creative Arts',
  'Hilton High School',
  'Hilton Road Elementary School',
  'Himachal Pradesh Agricultural University',
  'Himachal Pradesh University',
  'Himeji Dokkyo University',
  'Himeji Institute of Technology',
  'Hinckley Elementary',
  'Hinckley Elementary School',
  'Hinckley-big Rock Elem Sch',
  'Hinckley-big Rock High School',
  'Hinckley-big Rock Middle Sch',
  'Hinckley-finlayson Alternative Prog',
  'Hinckley-finlayson Secondary',
  'Hindley Elementary School',
  'Hindman Elementary School',
  'Hinds Co Career/technical Center',
  'Hinds Community College',
  'Hinds Community Vicksburg Center',
  'Hines Middle School',
  'Hines Primary School',
  'Hines-caldwell',
  'Hinesburg Elementary School',
  'Hingham High',
  'Hingham Middle School',
  'Hinkle Creek Elementary School',
  'Hinkley High School',
  'Hinks School',
  'Hinojosa Ec/pre-k Center',
  'Hinsdale 7-8',
  'Hinsdale Central High School',
  'Hinsdale Central School',
  'Hinsdale Elementary School',
  'Hinsdale High School',
  'Hinsdale Middle High School',
  'Hinsdale Middle School',
  'Hinsdale School',
  'Hinsdale South High School',
  'Hintgen Elementary',
  'Hinton Area Elementary',
  'Hinton Elementary School',
  'Hinton Es',
  'Hinton High School',
  'Hinton Hs',
  'Hinton Middle School',
  'Hinton Ms',
  'Hipolito Caldero',
  'Hipolito Garcia Borrero',
  'Hiram College',
  'Hiram Elementary School',
  'Hiram High School',
  'Hiram L Dorman',
  'Hiram Neuwoehner',
  'Hiram W Dodd El Sch',
  'Hiram W. Johnson High',
  'Hirosaki Gakuin University',
  'Hirosaki University',
  "Hiroshima Bunkyo Women's University",
  'Hiroshima City University',
  'Hiroshima Institute of Technology',
  'Hiroshima International University',
  'Hiroshima Jogakuin University',
  'Hiroshima Kokusai Gakuin University',
  'Hiroshima Prefectural University',
  'Hiroshima Shudo University',
  'Hiroshima University',
  'Hiroshima University of Economics',
  "Hiroshima Women's University",
  'Hirsch Academy A Challenge Foundation',
  'Hirsch El',
  'Hirsch Metropolitan High School',
  'Hirschi H S',
  'His House',
  'Hiseville Elementary School',
  'Hitch Elem School',
  'Hitchcock Co Elementary',
  'Hitchcock Co Jr/sr High School',
  'Hitchcock Elementary School',
  'Hitchcock H S',
  'Hitchcock Headstart',
  'Hitchcock Pri',
  'Hitchcock-tulare Elementary - 05',
  'Hitchcock-tulare High School - 01',
  'Hitchcock-tulare Junior High School - 02',
  'Hite Elementary',
  'Hite Saunders Elementary School',
  'Hitec University',
  'Hiteon Elementary School',
  'Hitit University',
  'Hitotsubashi University',
  'Hiwassee Dam Elementary/middle',
  'Hiwassee Dam High',
  'Hixson Elementary',
  'Hixson High School',
  'Hixson Middle',
  'Hixson Middle School',
  'Hl Brockett',
  'Hlww Alternative Learning Program',
  'Hmong College Prep Academy Es',
  'Hmong College Prep Academy Hs',
  'Hmong College Prep Academy Ms',
  'Hmong International Academy',
  'Ho Chi Minh City Open University',
  'Ho Chi Minh City University of Agriculture and Forestry',
  'Ho Chi Minh City University of Architecture',
  'Ho Chi Minh City University of Economics',
  'Ho Chi Minh City University of Foreign Languages and Information Technology',
  'Ho Chi Minh City University of Law',
  'Ho Chi Minh City University of Medicine and Pharmacy',
  'Ho Chi Minh City University of Natural Sciences',
  'Ho Chi Minh City University of Pedagogics',
  'Ho Chi Minh City University of Social Sciences and Humanities',
  'Ho Chi Minh City University of Technology',
  'Ho Chi Minh City University of Transport',
  'Ho Polytechnic',
  'Ho-ho-kus Public School',
  'Hoaglin-zenia Elementary',
  'Hobart Boulevard Elementary',
  'Hobart Es',
  'Hobart High School',
  'Hobart Hs',
  'Hobart Middle School',
  'Hobart Ms',
  'Hobart and William Smith Colleges',
  'Hobble Creek School',
  'Hobbs Alter Ed Co-op',
  'Hobbs Elementary',
  'Hobbs Freshman High',
  'Hobbs High',
  'Hobbs Middle School',
  'Hobbs Williams El',
  'Hobbton Elementary',
  'Hobbton High',
  'Hobbton Middle',
  'Hobby El',
  'Hobby Middle',
  'Hobe Sound Elementary School',
  'Hoben Elementary School',
  'Hobgood Elementary',
  'Hoboken Charter School',
  'Hoboken Dual Language Charter School',
  'Hoboken Elementary School',
  'Hoboken High School',
  'Hoboken Middle School',
  'Hobomock Elementary',
  'Hobson 6-8',
  'Hobson High School',
  'Hobson School',
  'Hochschule Albstadt-Sigmaringen',
  'Hochschule Anhalt (FH) Hochschule für angewandte Wissenschaften',
  'Hochschule Bremen',
  'Hochschule Bremerhaven',
  'Hochschule Coburg',
  'Hochschule Darmstadt',
  'Hochschule Esslingen',
  'Hochschule Fulda',
  'Hochschule Harz Hochschule für angewandte Wissenschaften (FH)',
  'Hochschule Magdeburg-Stendal (FH)',
  'Hochschule Mittweida (FH)',
  'Hochschule München',
  'Hochschule Vechta',
  'Hochschule Wismar Fachhochschule für Technik, Wirtschaft und Gestaltung',
  'Hochschule Zittau/Görlitz (FH)',
  'Hochschule für Bankwirtschaft (HfB) Private Fachhochschule der Bankakademie',
  'Hochschule für Berufstätige Rendsburg',
  'Hochschule für Gestaltung und Kunst Zürich',
  'Hochschule für Internationales Management',
  'Hochschule für Jüdische Studien Heidelberg',
  'Hochschule für Philosophie München',
  'Hochschule für Politik (HFP)',
  'Hochschule für Technik Wirtschaft und Kultur Leipzig (FH)',
  'Hochschule für Technik und Wirtschaft Dresden (FH)',
  'Hochschule für Technik und Wirtschaft des Saarlandes',
  'Hocker Grove Middle',
  'Hocking College',
  'Hocking Hills Elementary School',
  'Hockinson Heights Elementary School',
  'Hockinson High School',
  'Hockinson Middle School',
  'Hodeidah University',
  'Hodgdon Middle/high School',
  'Hodge El',
  'Hodge Elementary School',
  'Hodge Road Elementary',
  'Hodgeman County Elementary',
  'Hodgeman County High',
  'Hodgen Elem.',
  'Hodgen Public School',
  'Hodgenville Elementary School',
  'Hodges Bend Middle',
  'Hodges El',
  'Hodges Elementary',
  'Hodges Manor Elementary',
  'Hodgesville Elementary',
  'Hodgin Elementary',
  'Hodgkins Elem School',
  'Hodgson (paul M.) Vocational Technical High School',
  'Hoech Middle',
  'Hoehne Schools',
  'Hoff Elementary School',
  'Hoffer Elementary',
  'Hoffman Elem School',
  'Hoffman Estates High School',
  'Hoffman Learning Center',
  'Hoffman Middle',
  'Hoffman Trails Elementary School',
  'Hoffman-boston Elementary',
  'Hoffmann El',
  'Hoffmann Lane El',
  'Hofius Int',
  'Hofstetter Elementary',
  'Hofstra University',
  'Hogan Cedars Elementary School',
  'Hogan Middle',
  'Hogan Preparatory Acad Middle',
  'Hogan Preparatory Academy',
  'Hogan Preparatory Academy Elem',
  'Hogan St Regional Youth Ctr.',
  'Hogan-spring Glen Elementary School',
  'Hogansville Elementary School',
  'Hogarth Kingeekuk Sr. Memorial School',
  'Hogere Zeevaartschool - Maritime Academy',
  'Hogeschool Antwerpen',
  'Hogeschool Inholland',
  'Hogeschool Leiden',
  'Hogeschool Rotterdam',
  'Hogeschool Utrecht',
  'Hogeschool West-Vlaanderen (TU)',
  'Hogeschool van Amsterdam',
  'Hogeschool voor Wetenschap & Kunst',
  'Hogeschool voor Wetenschap en Kunst (VLEKHO) Brussel',
  'Hogeschool voor de Kunsten Utrecht (HKU)',
  'Hogg Middle',
  'Hogsett Elementary School',
  'Hohai University Changzhou',
  'Hohokam Traditional School',
  'Hoisington High',
  'Hoisington Middle',
  'Hoke County High',
  'Hokes Bluff Elementary School',
  'Hokes Bluff High School',
  'Hokes Bluff Middle School',
  'Hokkaido Information University',
  'Hokkaido Institute of Pharmaceutical Sciences',
  'Hokkaido Institute of Technology',
  'Hokkaido Tokai University',
  'Hokkaido University',
  'Hokkaido University of Education',
  'Hokkaido University of Health Sciences',
  'Hokkaigakuen University',
  'Hokkaigakuen University of Kitami',
  'Hokulani Elementary School',
  'Hokuriku University',
  'Hokusei Gakuen University',
  'Holabird Elementary',
  'Holabird Middle',
  'Holar University College',
  'Holbrook El',
  'Holbrook High School',
  'Holbrook Junior High School',
  'Holbrook Language Academy',
  'Holbrook Middle',
  'Holbrook Middle High School',
  'Holbrook Road School',
  'Holbrook School',
  'Holcomb Bridge Middle School',
  'Holcomb Elem 3-5',
  'Holcomb Elem.',
  'Holcomb Elementary School',
  'Holcomb High',
  'Holcomb Middle',
  'Holcombe Elementary',
  'Holcombe High',
  'Holcombe L Rucker School Of Community Research',
  'Holdeman Elementary School',
  'Holden Central Elementary School',
  'Holden Elem School',
  'Holden Elem.',
  'Holden Elementary School',
  'Holden High',
  'Holden High School',
  'Holden Middle',
  'Holden School',
  'Holden Village Community School',
  'Holdenville Hs',
  'Holder Elementary',
  'Holderness Central School',
  'Holdingford Elementary',
  'Holdingford Secondary',
  'Holdrege Elementary School',
  'Holdrege High School',
  'Holdrege Middle School',
  'Holdrege Public Schools Early Childhood Ed Ctr',
  'Holdrum Middle School',
  'Holgate Elementary School',
  'Holgate High School',
  'Holgate Middle School - 02',
  'Holice Powell Elementary',
  'Holicong Ms',
  'Holiday Heights El',
  'Holiday Hill Elementary School',
  'Holiday Park El Sch',
  'Holiday Park School',
  'Holiman El',
  'Holladay Elementary',
  'Holladay Intermediate Magnet School',
  'Holland Brook School',
  'Holland El',
  'Holland El Sch',
  'Holland Elem.',
  'Holland Elementary',
  'Holland Elementary School',
  'Holland H S',
  'Holland Heights',
  'Holland High School',
  'Holland Hill School',
  'Holland Junior/senior High School',
  'Holland Language Academy At Van Raalte',
  'Holland Medical H S',
  'Holland Middle',
  'Holland Ms',
  'Holland Patent Central High School',
  'Holland Patent Elementary School',
  'Holland Patent Middle School',
  'Holland Township Elementary School',
  'Holland Virtual Tech High School',
  'Holland Woods Middle School',
  'Hollandale Alternative Program',
  'Hollenbeck Middle',
  'Hollencrest Middle',
  'Holley Elementary School',
  'Holley Junior Senior High School',
  'Holley-navarre Intermediate',
  'Holley-navarre Middle School',
  'Holley-navarre Primary',
  'Hollibrook El',
  'Holliday El',
  'Holliday Elementary',
  'Holliday H S',
  'Holliday Middle',
  'Holliday Montessori',
  'Hollidaysburg Area Jhs',
  'Hollidaysburg Area Shs',
  'Hollifield Station Elementary',
  'Holliman School',
  'Hollin Meadows Elementary',
  'Holling Heights Elem School',
  'Hollinger K-8 School',
  'Hollingers Island Elementary School',
  'Hollingsworth East Elementary School',
  'Hollingworth Elementary',
  'Hollins College',
  'Hollis',
  'Hollis Academy',
  'Hollis Consolidated Grade Sch',
  'Hollis Es',
  'Hollis F. Price Middle College',
  'Hollis Hand Elementary School',
  'Hollis Hs',
  'Hollis Ms',
  'Hollis Primary School',
  'Hollis School',
  'Hollis T Dietz El',
  'Hollis Upper Elementary School',
  'Hollis-brookline High School',
  'Hollis-brookline Middle School',
  'Hollister Dual Language Academy',
  'Hollister Early Childhood Ctr',
  'Hollister Elem.',
  'Hollister Elementary',
  'Hollister Elementary Leadership Academy',
  'Hollister Elementary School',
  'Hollister High',
  'Hollister Middle',
  'Hollister Prep',
  'Holliston High',
  'Holloman Elementary',
  'Holloman Middle',
  'Hollow Hills Elementary',
  'Holloway Elementary',
  'Holloway Elementary School',
  'Holloway High School',
  'Holly Academy',
  'Holly Avenue Elementary',
  'Holly Creek Public School',
  'Holly Drive Leadership Academy',
  'Holly Elementary School',
  'Holly Glen Elementary School',
  'Holly Grove Elementary',
  'Holly Grove Middle',
  'Holly Hall Elementary',
  'Holly Harshman Elem. School',
  'Holly Heights Elementary School',
  'Holly High School',
  'Holly Hill Elementary',
  'Holly Hill Elementary School',
  'Holly Hill School',
  'Holly Hill-roberts Middle',
  'Holly Hills Elementary School',
  'Holly Junior High School',
  'Holly Lane Elementary School',
  'Holly Middle School',
  'Holly Oak Elementary',
  'Holly Pond Elementary School',
  'Holly Pond High School',
  'Holly Pond Middle',
  'Holly Ridge Elementary',
  'Holly Ridge Elementary School',
  'Holly Ridge Middle',
  'Holly Shelter Middle',
  'Holly Springs Career & Tech Center',
  'Holly Springs Elementary',
  'Holly Springs Elementary School',
  'Holly Springs High',
  'Holly Springs High School',
  'Holly Springs Intermediate School',
  'Holly Springs Junior High School',
  'Holly Springs Primary School',
  'Holly Springs-motlow Elementary',
  'Holly Tree Elementary',
  'Hollydale',
  'Hollydale Elementary School',
  'Hollymead Elementary',
  'Hollyvale Elementary',
  'Hollywood Academy Of Arts & Science',
  'Hollywood Academy Of Arts And Science Middle School',
  'Hollywood Beach Elementary',
  'Hollywood Central Elementary School',
  'Hollywood Elem School',
  'Hollywood Elementary',
  'Hollywood Elementary School',
  'Hollywood Heights',
  'Hollywood Hill Elementary',
  'Hollywood Hills Elementary School',
  'Hollywood Hills High School',
  'Hollywood Park Elementary',
  'Hollywood Park Elementary School',
  'Hollywood Primary Center',
  'Hollywood Senior High',
  'Holm Elementary School',
  'Holman Elem.',
  'Holman Elementary',
  'Holman Middle',
  'Holmdel High School',
  'Holme Thomas Sch',
  'Holmen High',
  'Holmen Middle',
  'Holmen Public Preschool',
  'Holmes Al Elementarymiddle School',
  'Holmes Avenue Elementary',
  'Holmes Co Career Technical Center',
  'Holmes Community College',
  'Holmes County Central High School',
  'Holmes County High School',
  'Holmes Edgenuity',
  'Holmes Elem School',
  'Holmes Elementary',
  'Holmes Elementary School',
  'Holmes H S',
  'Holmes High School',
  'Holmes Institute',
  'Holmes Jr High School',
  'Holmes Junior High School',
  'Holmes Learning Center',
  'Holmes Middle',
  'Holmes Middle School',
  'Holmes Park Es',
  'Holmes Road Elementary',
  'Holmes Road Elementary School',
  'Holmes School',
  'Holmes Stem Academy',
  'Holmes Virtual Franchise',
  'Holmes Virtual Instruction Program',
  'Holmesville Elementary School',
  'Holmgreen Center',
  'Holmquist El',
  'Holmsley El',
  'Holomua Elementary School',
  'Holsenbeck Elementary School',
  'Holsteiner Agricultural School',
  'Holston Elementary',
  'Holston High',
  'Holston Middle School',
  'Holston Valley Middle School',
  'Holston View Elementary',
  'Holt Crossing Intermediate School',
  'Holt Elementary',
  'Holt Elementary School',
  'Holt High School',
  'Holt Junior High School',
  'Holt Middle School',
  'Holt School',
  'Holt Senior High School',
  'Holten Richmond Middle School',
  'Holton Career And Resource Center',
  'Holton Elementary School',
  'Holton High',
  'Holton High School',
  'Holton Middle',
  'Holton Middle School',
  'Holton Virtual Academy',
  'Holtville Elementary School',
  'Holtville High',
  'Holtville High School',
  'Holtville Junior High',
  'Holtville Middle School',
  'Holualoa Elementary School',
  'Holub Middle',
  'Holum Education Center',
  'Holy Angel University',
  'Holy Cross School',
  'Holy Spirit University of Kaslik',
  'Holyoke Alternative School',
  'Holyoke Community Charter School',
  'Holyoke Community College',
  'Holyoke Elementary School',
  'Holyoke High',
  'Holyoke Senior High School',
  'Holz Elementary School',
  'Homan Elementary',
  'Homan Elementary School',
  'Home & Hosp/transition Support',
  'Home And Hospital Program',
  'Home Assignments-elementary',
  'Home Assignments-secondary',
  'Home Based',
  'Home Choice Academy',
  'Home Education Exchange',
  'Home Education Site',
  'Home Elem School',
  'Home Gardens Academy',
  'Home Instruction Elementary',
  'Home Instruction H.s.',
  'Home Link Alternative',
  'Home Of The Innocents Discovery',
  'Home Of The Innocents School',
  'Home Port Learning Center',
  'Home School Experience',
  'Home Street Middle',
  'Home/hospital',
  'Home/hospital Instruction',
  'Homebased Comp Ed',
  'Homebound',
  'Homebound Elementary',
  'Homebound Elementary School',
  'Homebound High School',
  'Homebound Program',
  'Homebound Secondary',
  'Homebound/ Hospital Education',
  'Homebridge',
  'Homeconnection',
  'Homecroft Elementary',
  'Homecroft Elementary School',
  'Homedale Elementary School',
  'Homedale High School',
  'Homedale Middle School',
  'Homeland Park Primary',
  'Homelink',
  'Homelink River',
  'Homer A. Plessy Community School',
  'Homer Brink School',
  'Homer Community High School',
  'Homer Davis Elementary School',
  'Homer Dr El',
  'Homer Elementary School',
  'Homer Es',
  'Homer Flex School',
  'Homer High School',
  'Homer Iddings Elementary School',
  'Homer Intermediate School',
  'Homer Jr High School',
  'Homer Junior High School',
  'Homer L. Hines Middle',
  'Homer Long Es',
  'Homer Middle School',
  'Homer Pittard Campus School',
  'Homer Senior High School',
  'Homer Street',
  'Homer-center El Sch',
  'Homer-center Jshs',
  'Homero Rivera Sola',
  'Homestake Peak School',
  'Homestead (alternative)',
  'Homestead El',
  'Homestead Elementary',
  'Homestead Elementary School',
  'Homestead High',
  'Homestead Middle School',
  'Homestead School',
  'Homestead Senior High School',
  'Homestead/wakefield Elementary',
  'Hometech Charter',
  'Hometown Elem School',
  'Hometown Elementary',
  'Homeville El Sch',
  'Homewood Elementary',
  'Homewood High School',
  'Homewood Middle School',
  'Homewood School',
  'Homewood-flossmoor High School',
  'Homeworks',
  'Hominy Early Childhood Center',
  'Hominy Hs',
  'Hominy Ms',
  'Hominy Valley Elementary',
  'Hommel El',
  'Hommocks School',
  'Homosassa Elementary School',
  'Honaker Elementary',
  'Honaker High',
  'Honam University',
  'Honaunau Elementary School',
  'Honcut',
  'Hondo Elementary',
  'Hondo H S',
  'Hondo High',
  'Honduras Elementary School',
  'Honea Path Elementary',
  'Honea Path Middle',
  'Honeoye Elementary School',
  'Honeoye Falls-lima Middle School',
  'Honeoye Falls-lima Senior High School',
  'Honeoye Middle/high School',
  'Honesdale Hs',
  'Honey Brook El Ctr',
  'Honey Creek Community School',
  'Honey Creek Elementary',
  'Honey Creek Elementary School',
  'Honey Creek Middle School',
  'Honey Dew Elementary',
  'Honey El',
  'Honey Grove El',
  'Honey Grove H S',
  'Honey Grove Middle',
  'Honey Hollow Elementary',
  'Honey Island Elementary School',
  'Honey Run Academy Elementary',
  'Honey Run Academy Secondary',
  'Honeydew Elementary',
  'Honeysuckle Middle School',
  'Honeywell Elem School',
  'Hong Kong Academy for Performing Arts',
  'Hong Kong Baptist University',
  'Hong Kong Institute of Education',
  'Hong Kong Polytechnic University',
  'Hong Kong Shue Yan College',
  'Hong Kong University of Science and Technology',
  'Hongik University',
  'Honiss Elementary Middle School',
  'Honokaa Elementary School',
  'Honokaa High & Intermediate School',
  'Honolulu Community College',
  'Hononegah High School',
  'Honor Elementary',
  'Honor Intermediate',
  'Honors Academy Of Literature',
  'Honowai Elementary School',
  'Hood',
  'Hood Canal Elem & Junior High',
  'Hood College',
  'Hood River Middle School',
  'Hood River Valley High School',
  'Hood-case El',
  'Hook El',
  'Hook Elementary School',
  'Hookele Elementary School',
  'Hookena Elementary School',
  'Hooker Es',
  'Hooker Hs',
  'Hooker Oak Elementary',
  'Hooks El',
  'Hooks H S',
  'Hooks J H',
  'Hooksett Memorial School',
  'Hoonah Elementary',
  'Hoonah Jr/sr High School',
  'Hoopa Valley Elementary',
  'Hoopa Valley High',
  'Hooper Avenue Elementary',
  'Hooper Avenue Elementary School',
  'Hooper Avenue Primary Center',
  'Hooper Bay School',
  'Hooper School',
  'Hoopeston Area High School',
  'Hoopeston Area Middle School',
  'Hoosac Valley Elementary School',
  'Hoosac Valley High School',
  'Hoosac Valley Middle School',
  'Hoosic Valley Elementary School',
  'Hoosic Valley Junior Senior High School',
  'Hoosick Falls Elementary School',
  'Hoosick Falls Junior/senior High School',
  'Hoosier Acad Virtual Charter Sch',
  'Hoosier Academy - Indianapolis',
  'Hoosier Hills Career Center',
  'Hoosier Hills Childcare Center',
  'Hoosier Prairie Elementary School',
  'Hoosier Road Elementary School',
  'Hoover (herbert) Middle',
  'Hoover El',
  'Hoover El Sch',
  'Hoover Elem School',
  'Hoover Elementary',
  'Hoover Elementary School',
  'Hoover Es',
  'Hoover High',
  'Hoover High School',
  'Hoover Math And Science Academy',
  'Hoover Middle',
  'Hoover Middle School',
  'Hoover School',
  'Hoover Street Elementary',
  'Hoover Wood Elem School',
  'Hooverson Heights Primary School',
  'Hooverville El Sch',
  'Hop Brook Elementary School',
  'Hopatcong High School',
  'Hopatcong Middle School',
  'Hope',
  'Hope Academic Academy',
  'Hope Academy',
  'Hope Academy Charter School',
  'Hope Academy For Autism',
  'Hope Academy Northcoast',
  'Hope Academy Northwest Campus',
  'Hope Academy Of West Michigan',
  'Hope Africa University',
  'Hope Charter',
  'Hope Charter Leadership Academy',
  'Hope Chinese Charter School',
  'Hope College',
  'Hope College And Career Readiness Academy',
  'Hope College Prep High School',
  'Hope Community Academy',
  'Hope Community Cs',
  'Hope Community Pcs Lamond',
  'Hope Community Pcs Tolson',
  'Hope D Wall Tmh Child Dev Ctr',
  'Hope Elem',
  'Hope Elementary',
  'Hope Elementary School',
  'Hope For Hyndman Cs',
  'Hope H S',
  'Hope High',
  'Hope High School',
  'Hope High School Online',
  'Hope Highlands Middle School',
  "Hope Hill Children's Home",
  'Hope Infant-toddler Special Education',
  'Hope Inst Lrning Acad Elem School',
  'Hope Leadership Academy',
  'Hope Learning Academy Of Toledo',
  'Hope Middle',
  'Hope Middle School',
  'Hope Mills Middle',
  'Hope Of Detroit Academy Elementary',
  'Hope Of Detroit Academy Middlehigh',
  'Hope Online Learning Academy Elementary',
  'Hope Online Learning Academy High School',
  'Hope Online Learning Academy Middle School',
  'Hope School',
  'Hope Street Charter Academy',
  'Hope Street Elementary',
  'Hope Sullivan Elementary School',
  'Hope Township School',
  'Hope Valley Elementary',
  'Hope Valley Elementary School',
  'Hope View Elementary',
  'Hope-centennial Elementary School',
  'Hopedale Jr Sr High',
  'Hopeville School',
  'Hopewell',
  'Hopewell Crest',
  'Hopewell El Sch',
  'Hopewell Elementary',
  'Hopewell Elementary School',
  'Hopewell High',
  'Hopewell Jhs',
  'Hopewell Junior School',
  'Hopewell Middle',
  'Hopewell Middle School',
  'Hopewell School',
  'Hopewell Shs',
  'Hopewell Valley Central High School',
  'Hopewell-loudon Local Elementary School',
  'Hopewell-loudon Local High School',
  'Hopi Day School',
  'Hopi Elementary School',
  'Hopi Jr/sr High School',
  'Hopkins Academy',
  'Hopkins Co Career & Technology Center',
  'Hopkins County Central High School',
  'Hopkins County Day Treatment',
  'Hopkins County Schools Academy',
  'Hopkins El',
  'Hopkins Elementary',
  'Hopkins Elementary School',
  'Hopkins High School',
  'Hopkins Hill School',
  'Hopkins Lloyd Community School',
  'Hopkins Middle',
  'Hopkins Middle School',
  'Hopkins North Junior High',
  'Hopkins Road Elementary',
  'Hopkins Senior High',
  'Hopkins West Junior High',
  'Hopkinson Francis Sch',
  'Hopkinsville Community College',
  'Hopkinsville High School',
  'Hopkinsville Middle School',
  'Hopkinton High',
  'Hopkinton High School',
  'Hopkinton Middle School',
  'Hopkinton Pre-school',
  'Hopp Elementary/secondary',
  'Hopper Center',
  'Hopper Middle',
  'Hopper Pri',
  'Hoquiam High School',
  'Hoquiam Homelink School',
  'Hoquiam Middle School',
  'Horace Cureton Elementary',
  'Horace Elementary School',
  'Horace Ensign Intermediate',
  'Horace Greeley High School',
  'Horace H. Epes Elementary',
  'Horace J. Good Middle School',
  'Horace Mann',
  'Horace Mann Community School',
  'Horace Mann Dual Language Magnet',
  'Horace Mann El Sch',
  'Horace Mann Elc',
  'Horace Mann Elem School',
  'Horace Mann Elem.',
  'Horace Mann Elementary',
  'Horace Mann Elementary - 07',
  'Horace Mann Elementary School',
  'Horace Mann Es',
  'Horace Mann High',
  'Horace Mann J H',
  'Horace Mann Junior High',
  'Horace Mann Laboratory',
  'Horace Mann Middle',
  'Horace Mann Middle School',
  'Horace Mann Prek-6 School',
  'Horace Mann Roosevelt Elementary School',
  'Horace Mann School',
  'Horace Mann School For The Deaf',
  'Horace Mann Ucla Community',
  'Horace May Elementary',
  'Horace Mitchell Primary School',
  "Horace O'bryant School",
  'Horace W. Porter School',
  'Horatio Elementary School',
  'Horatio High School',
  'Horicon Elementary',
  'Horicon High',
  'Horicon Junior High',
  'Horizon',
  'Horizon Academy At Marion Oaks',
  'Horizon Academy West',
  'Horizon Alternative School',
  'Horizon Charter',
  'Horizon Charter School Of Tampa',
  'Horizon Elem School',
  'Horizon Elementary',
  'Horizon Elementary - 09',
  'Horizon Elementary School',
  'Horizon H S',
  'Horizon Heights El',
  'Horizon High',
  'Horizon High School',
  'Horizon Honors Elementary School',
  'Horizon Honors Secondary School',
  'Horizon Middle',
  'Horizon Middle School',
  'Horizon Montessori',
  'Horizon Montessori Ii',
  'Horizon Montessori Iii',
  'Horizon School',
  'Horizon Sci Acad - Chatham Cthr',
  'Horizon Sci Acad - Southwest Chtr',
  'Horizon Science Acad Cleveland',
  'Horizon Science Acad-belmont',
  'Horizon Science Acad-mckinley Pk',
  'Horizon Science Academy Columbus',
  'Horizon Science Academy Columbus Middle School',
  'Horizon Science Academy Dayton Downtown',
  'Horizon Science Academy Dayton High School',
  'Horizon Science Academy Denison Elementary School',
  'Horizon Science Academy Elementary School',
  'Horizon Science Academy Lorain',
  'Horizon Science Academy Toledo',
  'Horizon Science Academy Twin Cities',
  'Horizon Science Academy Youngstown',
  'Horizon Science Academy-cincinnati',
  'Horizon Science Academy-cleveland Middle School',
  'Horizon Science Academy-dayton',
  'Horizon Science Academy-denison Middle School',
  'Horizon Science Academy-springfield',
  'Horizon-on-the-hudson Magnet School',
  'Horizons Academy',
  'Horizons Elementary',
  'Horizons Elementary School',
  'Horizons Exploratory Academy',
  'Horizons High School',
  'Horizons K-8 School',
  'Horizons School: Independent Study',
  'Horizonte Instruction & Training Center',
  'Horlick High',
  'Hormozgan University of Medical Sciences',
  'Horn El',
  'Horn H S',
  'Horn Lake Elem',
  'Horn Lake High',
  'Horn Lake Intermediate School',
  'Horn Lake Middle School',
  'Hornbeck High School',
  'Hornbrook Elementary',
  'Horne El',
  'Horne Street School',
  'Hornedo Middle',
  'Hornell Intermediate School',
  'Hornell Junior-senior High School',
  'Hornet Park Elementary School',
  'Hornets Nest Elementary',
  'Horning Middle',
  'Hornsby Elementary',
  'Hornsby-dunlap El',
  'Hornung Elementary School',
  'Horrell Hill Elementary',
  'Horry-​Georgetown Technical College',
  'Horse Branch Elementary School',
  'Horse Creek Academy',
  'Horse Creek Learning Center',
  'Horse Creek School',
  'Horse Heaven Hills Middle School',
  'Horseheads Intermediate School',
  'Horseheads Middle School',
  'Horseheads Senior High School',
  'Horseshoe Bend Elementary',
  'Horseshoe Bend High School',
  'Horseshoe Bend Middle/sr High',
  'Horseshoe Drive Elementary New Vision Academy',
  'Horseshoe Trails Elementary School',
  'Hort Elementary',
  'Horton Disciplinary Alternative Education Program',
  'Horton Elem',
  'Horton Elementary',
  'Horton High',
  'Horton Middle',
  'Hortons Creek Elementary',
  'Hortonville Area K4 School',
  'Hortonville Elementary',
  'Hortonville High',
  'Hortonville Middle',
  'Hosack El Sch',
  'Hosea Elem.',
  'Hosei University',
  'Hoseo University',
  'Hosford Elementary Junior High School',
  'Hosford Middle School',
  'Hosford Park New Tech Elementary',
  'Hoshi University',
  'Hosler Middle',
  'Hosmer',
  'Hosp El',
  'Hospers Elementary School',
  'Hospital & Homebound',
  'Hospital And Homebound',
  'Hospital Homebound',
  'Hospital Homebound Instruction',
  'Hospital Homebound Program',
  'Hospital Homebound Services',
  'Hospital Schools',
  'Hospital/homebound',
  'Hospital/homebound Program',
  'Hospital/homebound/homebased Programs',
  'Hostos-lincoln Academy Of Science',
  'Hot Springs 7-8',
  'Hot Springs County High School',
  'Hot Springs Elementary',
  'Hot Springs Elementary - 02',
  'Hot Springs High',
  'Hot Springs High School',
  'Hot Springs High School - 01',
  'Hot Springs Intermediate School',
  'Hot Springs Junior Academy',
  'Hot Springs Middle School - 06',
  'Hot Springs School',
  'Hot Springs World Class High School',
  'Hotchkiss Elementary School',
  'Hotchkiss High School',
  'Hotelschool The Hague',
  'Hotevilla Bacavi Community School',
  'Houchin Es',
  'Houck El Sch',
  'Houck Middle School',
  'Houdegbe North American University Benin',
  'Houdini Elementary',
  'Hough Elementary School',
  'Hough Street Elem School',
  'Houghtaling Elementary',
  'Houghton Central High School',
  'Houghton Elementary',
  'Houghton Elementary School',
  'Houghton Lake Community Education',
  'Houghton Lake High School',
  'Houghton Lake Middle School',
  'Houghton Middle School',
  'Houghton-kearney Elementary',
  'Houlka Attendance Center',
  'Houlton Elementary',
  'Houlton Elementary School',
  'Houlton High School',
  'Houlton Junior High School',
  'Houlton Southside School',
  'Houma Junior High School',
  'Housatonic Community College',
  'Housatonic Valley Regional High School',
  'House Creek El',
  'House Elementary',
  'House Elementary School',
  'House High',
  'House Junior High',
  'House Of Arts Letters And Science (hals) Academy',
  'House Springs Early Child Ctr',
  'House Springs Elem.',
  'Housel Middle School',
  'Houser El',
  'Houserville/lemont El Sch',
  'Housman El',
  'Houston',
  'Houston Academy',
  'Houston Academy For International Studies',
  'Houston Alternative School',
  'Houston Behavioral Healthcare',
  'Houston Can Academy - Hobby',
  'Houston Can Academy - North',
  'Houston Can Academy - Southwest',
  'Houston Career & Technology Ed.',
  'Houston Co High School',
  'Houston Co Middle School',
  'Houston Community College',
  'Houston County Alternative School',
  'Houston County Area Vocational Center',
  'Houston County Crossroads Center',
  'Houston County High School',
  'Houston Educational Service And Early Childhood Center',
  'Houston El',
  'Houston Elem.',
  'Houston Elementary',
  'Houston Elementary School',
  'Houston Es',
  'Houston Gateway Academy',
  'Houston Gateway Academy - Coral Campus',
  'Houston Gateway Academy - Elite Academy',
  'Houston Gateway Academy Inc Elite College Prep',
  'Houston H S',
  'Houston Heights Charter School',
  'Houston Heights Learning Academy Inc',
  'Houston Henry E Sch',
  'Houston High',
  'Houston High School',
  'Houston Lower Elementary',
  'Houston Math Science And Technology Center',
  'Houston Middle',
  'Houston Middle School',
  'Houston Secondary',
  'Houston Upper Elementary',
  'Hoven Elementary - 02',
  'Hoven High School - 01',
  'Hoven Jr. High - 03',
  'Howar Junior High School',
  'Howard A Hanlon Elementary School',
  'Howard A Yeager Elem Sch',
  'Howard And Suamico 4k Collaborative',
  'Howard B Thomas Grade School',
  'Howard B. Brunner Elementary School',
  'Howard B. Owens Science Center',
  'Howard B. Stroud Elementary School',
  'Howard Burnham El',
  'Howard C Reiche Community Sch',
  'Howard C. Johnson Elementary School',
  'Howard Cattle Elementary',
  'Howard College',
  'Howard Community College',
  'Howard D. Mcmillan Middle School',
  'Howard Dobbs El',
  'Howard Drive Elementary School',
  'Howard E Heckethorn Elementary',
  'Howard E Hollingsworth Elementary School',
  'Howard E Thirkill Primary School',
  'Howard Eccles Elementary School',
  'Howard El',
  'Howard El Sch',
  'Howard Elementary',
  'Howard Elementary - 02',
  'Howard Elementary School',
  'Howard Gardner Community Charter',
  'Howard Gardner Multiple Intelligence Cs',
  'Howard Hathaway School',
  'Howard High',
  'Howard High School',
  'Howard High School - 01',
  'Howard High School Of Technology',
  'Howard Inghram Elementary',
  'Howard J. Mckibben Elementary',
  'Howard Jr. High - 05',
  'Howard L Goff School',
  'Howard L Hall Elementary',
  'Howard L. Emmons',
  'Howard Lake Waverly Winsted Ecse',
  'Howard Lake-waverly-winsted Middle',
  'Howard Lake-waverly-winsted Sec.',
  'Howard Learning Academy',
  'Howard M Phifer Middle School',
  'Howard Middle',
  'Howard Middle School',
  'Howard Norman El',
  'Howard Pence Elementary',
  'Howard R. Driggs School',
  'Howard R. Yocum Elementary School',
  'Howard School',
  'Howard Street Charter',
  'Howard T Herber Middle School',
  'Howard Tanner',
  'Howard University',
  'Howard University Middle School Of Math And Science Pcs',
  'Howard Valentine Coffman Cove School',
  'Howard W. Bishop Middle School',
  'Howard Wasden Elementary School',
  'Howard Wood Elementary',
  'Howardellis Elementary School',
  'Howards Grove High',
  'Howards Grove Middle',
  'Howbert Elementary School',
  'Howe Avenue Elementary',
  'Howe El',
  'Howe El Sch',
  'Howe Elem School',
  'Howe Elementary',
  'Howe Elementary School',
  'Howe Es',
  'Howe H S',
  'Howe Hall Aims Elementary',
  'Howe Hs',
  'Howe Julia Ward Sch',
  'Howe Middle',
  'Howe Trainable Center And Montessori',
  'Howe-manning',
  'Howell Cheney Technical High School',
  'Howell Elementary School',
  'Howell Graves Preschool',
  'Howell High School',
  'Howell L. Watkins Middle School',
  'Howell Middle',
  'Howell Mountain Elementary',
  'Howell Park Elementary School',
  'Howell Peter Elementary',
  'Howell Road School',
  'Howell Township Memorial Elementary School',
  'Howell Township Middle School North',
  'Howell Township Middle School South',
  'Howell Valley Elem.',
  'Howells Senior High School',
  'Howells-dodge Elementary School/dodge',
  'Howells-dodge Elementary School/howells',
  'Howevalley Elementary School',
  'Howitt School',
  'Howland Glen Elementary School',
  'Howland High School',
  'Howland Middle School',
  'Howland Springs Elementary School',
  'Howsman El',
  'Hoxie Elem',
  'Hoxie Elementary School',
  'Hoxie High',
  'Hoxie High School',
  'Hoxsie School',
  'Hoyland El',
  'Hoyleton Education Center',
  'Hoyne Elem School',
  'Hoyt Early Learning Center',
  'Hoyt Middle School',
  'Hozho Academy',
  'Hp Webb El',
  'Hphs Engineering And Green Technology Academy',
  'Hphs Law And Government Academy',
  'Hphs Nursing And Health Sciences Academy',
  'Hs 560 City-as-school',
  'Hs Culture Club',
  'Hs Extended Year Program',
  'Hs For Law Enforcement And Public Safety',
  'Hs For Public Service-heroes Of Tomorrow',
  'Hs Of Engineering & Science Carver Hs',
  'Hs-3 Nw H S',
  'Hs-5 Ne H S',
  'Hsd 86 Adult Opportunties Pgm',
  'Hsuan Chuang University',
  'Ht Smith Elementary School',
  'Htrs Elementary School',
  'Htrs High School',
  'Htrs Middle School',
  'Huachiew Chalermprakiet University',
  'Huachuca City School',
  'Huachuca Mountain Elementary School',
  'Huafan University',
  'Huaihai Institute of Technology',
  'Huaihua Medical College',
  'Huaihua Radio and Television University',
  'Huaihua University',
  'Hualapai Elementary',
  'Huanghe Science & Technology University',
  'Huaqiao University Quanzhuo',
  'Huazhong Agricultural University',
  'Huazhong University of Science and Technology',
  'Hubbard El',
  'Hubbard Elementary School',
  'Hubbard H S',
  'Hubbard High School',
  'Hubbard Isd',
  'Hubbard Mastery School',
  'Hubbard Middle',
  'Hubbard Middle School',
  'Hubbard Street Elementary',
  'Hubbard Woods Elem School',
  'Hubbard-radcliffe Elementary School',
  'Hubbardston Center',
  'Hubbell Elementary',
  'Hubbell/extended Resource',
  'Hubbertville School',
  'Hubble Middle School',
  'Hubei University',
  'Huber Ridge Elementary School',
  'Huber St No 3',
  'Hubert H Humphrey Elementary',
  'Hubert H Humphrey Middle School',
  'Hubert H. Bancroft Elementary',
  'Hubert Howe Bancroft Middle',
  'Hubert Kairuki Memorial University',
  'Hubert Middle School',
  'Hubert O. Sibley K-8 Academy',
  'Huckabay Daep',
  'Huckabay School',
  'Huckleberry Hill',
  'Huckleberry Hill Elementary School',
  'Hucrest Elementary School',
  'Huddleston Elementary',
  'Huddleston Elementary School',
  'Huddleston Int',
  'Hudlow Elementary School',
  'Hudnall (claude) Elementary',
  'Hudson 4k',
  'Hudson Academy Of Arts And Sciences',
  'Hudson Alternative High School',
  'Hudson Area High School',
  'Hudson Arts And Science Charter School',
  'Hudson Bend Middle',
  'Hudson Co Youth House',
  'Hudson County Community College',
  'Hudson El',
  'Hudson Elem School',
  'Hudson Elem.',
  'Hudson Elementary',
  'Hudson Elementary School',
  'Hudson Falls High School',
  'Hudson Falls Intermediate School',
  'Hudson Falls Middle School',
  'Hudson Falls Primary School',
  'Hudson H S',
  'Hudson High',
  'Hudson High Adult Education',
  'Hudson High School',
  'Hudson High School Of Learning Technologies',
  'Hudson Junior High School',
  'Hudson K-8',
  'Hudson Keight School',
  'Hudson Maxim Elementary School',
  'Hudson Memorial School',
  'Hudson Middle',
  'Hudson Middle School',
  'Hudson Park Elementary School',
  'Hudson Prairie Elementary',
  'Hudson Regional School',
  'Hudson Valley Community College',
  "Hudson's Bay High School",
  'Hudsonville Freshman Building',
  'Hudsonville High School',
  'Hudtloff Middle School',
  'Hue University',
  'Hue University of Agriculture and Forestry',
  'Huebner El',
  'Hueco El',
  'Huegel Elementary',
  'Hueneme Elementary',
  'Hueneme High',
  'Huey L. Porter Middle School',
  'Hueytown Elementary School',
  'Hueytown High School',
  'Hueytown Middle School',
  'Huff Cons Elementary & Middle School',
  'Huff Elem School',
  'Huffaker Elementary School',
  'Huffines Middle',
  'Huffman Academy',
  'Huffman El',
  'Huffman Elem School',
  'Huffman Elementary',
  'Huffman High Schoolmagnet',
  'Huffman Middle',
  'Huffman Middle School',
  'Hufford Junior High School',
  'Hugger Elementary School',
  'Huggins El',
  'Hugh B. Bain Middle School',
  'Hugh Bish Es',
  'Hugh C. Spalding Academy',
  'Hugh Cole School',
  'Hugh Dingess Elementary School',
  'Hugh Gallagher Elementary School',
  'Hugh Goodwin Elementary School',
  'Hugh J Boyd Jr. Elementary School',
  'Hugh M Cummings High',
  'Hugh Mercer Elementary',
  'Hugh R Jones Elementary School',
  "Hugh Roe O'donnell",
  'Hugh W Gregg Elementary School',
  'Hughes Academy Of Science And Technology',
  'Hughes C Elem School',
  'Hughes Elementary School',
  'Hughes Jones Harrodsburg Area Tech Ctr',
  'Hughes L Elem School',
  'Hughes Middle',
  'Hughes Road El',
  'Hughes Springs El',
  'Hughes Springs H S',
  'Hughes Springs J H',
  'Hughes Stem High School',
  'Hughes-elizabeth Lakes',
  'Hughesville Jshs',
  'Hughey El',
  'Hughson Elementary',
  'Hughson High',
  'Hughston El',
  'Hugo A. Owens Middle',
  'Hugo Elementary',
  'Hugo Es',
  'Hugo Hs',
  'Hugo Intermediate',
  'Hugo Ms',
  'Hugo Reid Elementary',
  'Hugoton Elem',
  'Hugoton High',
  'Hugoton Learning Academy',
  'Hugoton Middle',
  'Huguenot High',
  'Huguley Elementary School',
  'Huie Elementary School',
  'Huie Special Educ Ctr',
  'Huizhou University',
  'Hulan L Whitson Elem',
  'Hulbert Es',
  'Hulbert Jr-sr Hs (sr)',
  'Hulbert Ms',
  'Hulet Elementary School',
  'Hulett School',
  'Hull El',
  'Hull High',
  'Hull Middle School',
  'Hull Prairie Intermediate School',
  'Hull-daisetta El',
  'Hull-daisetta H S',
  'Hull-daisetta J H',
  'Hull-jackson Elementary',
  'Hull-sanford Elementary School',
  'Hulsing Elementary School',
  'Hulstrom Options K-8 School',
  'Humanitas Acad Of Art And Tech At Esteban E. Torres High #4',
  'Humanities And Arts (harts) Academy Of Los Angeles',
  'Humanities And Arts Magnet High School',
  'Humanities And Science High School - Tempe',
  'Humanities And Sciences Academy Arizona',
  'Humanities And Sciences High School - Phoenix',
  'Humanities Preparatory Academy',
  'Humann Elementary School',
  'Humansville Elem.',
  'Humansville High',
  'Humansville Middle School',
  'Humber College',
  'Humble Community Learning Center',
  'Humble El',
  'Humble H S',
  'Humble Middle',
  'Humboldt Acad Of Higher Lrning',
  'Humboldt Adult Ed Correctional',
  'Humboldt Adult Program',
  'Humboldt County Office Of Education Court',
  'Humboldt County Office Of Education Juvenile Hall Court',
  'Humboldt County Rop',
  'Humboldt Elem.',
  'Humboldt Elementary School',
  'Humboldt High School',
  'Humboldt Junior/senior High School',
  'Humboldt Middle School',
  'Humboldt Park Elementary',
  'Humboldt State University',
  'Humboldt Universität Berlin',
  'Humbolt Elementary School',
  'Hume - Fogg High',
  'Hume Elem.',
  'Hume High',
  'Hume Lake Charter',
  'Humes Preparatory Academy Middle School',
  'Humke Elementary',
  'Humphrey Elementary',
  'Humphrey Elementary School',
  'Humphrey Jr-sr High School',
  "Humphrey's Highland El",
  'Humphreys Avenue Elementary',
  'Humphreys Co Voc Center',
  'Humphreys College Academy Of Business Law And Education',
  'Humphreys County High School',
  'Humphreys Jr High School',
  'Humphries Elementary School',
  'Hunan Agricultural University',
  'Hunan Normal University',
  'Hunan University',
  'Hundred High School',
  'Hungarian Academy of Craft and Design',
  'Hungarian Academy of Fine Arts Budapest',
  'Hungarian University of Physical Education',
  'Hungary Creek Middle',
  'Hungerford Elementary',
  'Hunley Park Elementary',
  'Hunnewell',
  'Hunt Club Elem',
  'Hunt County Juvenile Detention Ctr',
  'Hunt El',
  'Hunt Elementary',
  'Hunt Elementary School',
  'Hunt Meadows Elementary',
  'Hunt Middle',
  'Hunt School',
  'Hunt Valley Elementary',
  'Hunter B. Andrews',
  'Hunter College',
  'Hunter Elementary',
  'Hunter Elementary School',
  'Hunter High',
  'Hunter Hills Elementary School',
  'Hunter Huss High',
  'Hunter Jr High',
  'Hunter Lake Elementary School',
  'Hunter Middle School',
  'Hunter School',
  'Hunter Street Elementary',
  'Hunter William H Sch',
  "Hunter's Green Elementary School",
  'Hunter-kinard-tyler Elementary',
  'Hunter-kinard-tyler High',
  'Hunter-tannersville Middle School High School',
  'Hunterdon Central Regional High School District',
  'Hunterdon County Academies',
  'Hunterdon County Vocational School District Central Campus',
  'Hunterdon Polytech Bartles Corner Campus',
  'Hunters Bend Elementary',
  'Hunters Creek El',
  'Hunters Creek Elementary',
  'Hunters Creek Middle',
  'Hunters Glen El',
  'Hunters Glen Elementary School',
  'Hunters Lane High',
  'Hunters Point Boarding School',
  'Hunters Point Community Middle School',
  'Hunters Woods Elementary School For The Arts And Sciences',
  'Huntersville Elementary',
  'Huntertown Elementary School',
  'Hunting Ridge Elem School',
  'Huntingburg Elementary School',
  'Huntingdon Area Ms',
  'Huntingdon Area Shs',
  'Huntingdon College',
  'Huntingdon County Ctc',
  'Huntingdon High School',
  'Huntingdon Middle School',
  'Huntingdon Primary',
  'Huntington Beach High',
  'Huntington Drive Elementary',
  'Huntington East Middle School',
  'Huntington El',
  'Huntington Elementary School',
  'Huntington H S',
  'Huntington High School',
  'Huntington Int',
  'Huntington Junior College',
  'Huntington K-8 School',
  'Huntington Middle',
  'Huntington Middle School',
  'Huntington North High School',
  'Huntington Park Elementary',
  'Huntington Park Senior High',
  'Huntington Place Elementary School',
  'Huntington School',
  'Huntington Schools Preschool',
  'Huntington Seacliff Elementary',
  'Huntington Therapeutic Day School',
  'Huntington University',
  'Huntington Woods Early Childhood Center',
  'Huntingtown Elementary',
  'Huntingtown High School',
  'Huntingtowne Farms Elementary',
  'Huntland School',
  'Huntley Elementary',
  'Huntley High School',
  'Huntley Hills Elementary School',
  'Huntley Middle School',
  'Huntley Project 7-8',
  'Huntley Project Elem K-6',
  'Huntley Project High Schl',
  'Hunts Point School (the)',
  'Huntsville Center For Technology',
  'Huntsville El',
  'Huntsville Elementary',
  'Huntsville H S',
  'Huntsville High School',
  'Huntsville Int',
  'Huntsville Intermediate Sch',
  'Huntsville Junior High School',
  'Huntsville Middle School',
  'Hupfeld Acad./western Village',
  'Huppertz El',
  'Hurd Barrington Elementary',
  'Hurden Looker School',
  'Hurffville Elementary School',
  'Hurla M Midkiff El',
  'Hurlbut W Smith K-8 School',
  'Hurlbutt Elementary School',
  'Hurley Elem School',
  'Hurley Elem.',
  'Hurley Elementary',
  'Hurley Elementary/middle',
  'Hurley High',
  'Hurley Ranch Elementary',
  'Hurlock Elementary School',
  'Huron Academy Grades 38',
  'Huron Academy Grades K2',
  'Huron Ai Center Elem Bobcean Program',
  'Huron Ai Center Hs Program',
  'Huron Ai Center Progam',
  'Huron Area Technical Center',
  'Huron Colony Elementary - 13',
  'Huron Elementary',
  'Huron Elementary School',
  'Huron High School',
  'Huron High School - 01',
  'Huron Learning Center',
  'Huron Middle',
  'Huron Middle School - 02',
  'Huron Park Elementary School',
  'Huron Primary School',
  'Huron University College',
  'Huron University USA in London',
  'Huron Valley Adult Education',
  'Huron Valley Womens',
  'Hurricane Creek Elementary',
  'Hurricane Deck Elem.',
  'Hurricane High',
  'Hurricane High School',
  'Hurricane Intermediate',
  'Hurricane Island Outward Bound',
  'Hurricane Middle',
  'Hurricane School',
  'Hurricane Town Elementary',
  'Hurshel Antwine School',
  'Hurst Hills El',
  'Hurst J H',
  'Hurt Park Elementary',
  'Husmann Elem School',
  'Hustisford High',
  'Huston Academy',
  'Hustonville Elementary School',
  'Hutch School',
  'Hutchens Elementary School',
  'Hutchings Child & Youth Services',
  'Hutchins El',
  'Hutchinson Central Technical High School',
  'Hutchinson Community College',
  'Hutchinson Correctional Facility',
  'Hutchinson El',
  'Hutchinson El Sch',
  'Hutchinson Elementary School',
  'Hutchinson Elementarymiddle School',
  'Hutchinson High School',
  'Hutchinson Magnet School At Allen',
  'Hutchinson Middle',
  'Hutchinson Middle School 7',
  'Hutchinson Middle School 8',
  'Hutchinson Middle School A.l.c.',
  'Hutchinson Night Alt Learning Ctr',
  'Hutchinson Park Elementary',
  'Hutchinson School',
  'Hutchinson Senior High',
  'Hutchinson Transition Assistance Pg',
  'Hutchinson West Elementary',
  'Hutchison Beach Elementary School',
  'Hutchison Elementary',
  'Hutchison Farm Elementary',
  'Hutchison High School',
  'Huth Road School',
  'Hutsonville Elem And Jr High Sch',
  'Hutsonville High School',
  'Hutterische Colony Elementary - 18',
  'Hutto El',
  'Hutto H S',
  'Hutto Middle',
  'Hutto Middle School',
  'Hutton Elementary',
  'Huxford Elementary School',
  'Huy/a.g. Bell Elementary School',
  'Hvanneyri Agricultural University',
  'Hved Ecse',
  'Hved Spectrum Program',
  'Hw Longfellow Med/stem Magnet Midl',
  'Hwy 46 Area El',
  'Hyalite Elementary',
  'Hyampom Arts Magnet Elementary',
  'Hyannis Elementary School',
  'Hyannis High School',
  'Hyannis West Elementary',
  'Hyatt Elementary',
  'Hyatt Park Elementary',
  'Hyatts Middle School',
  'Hyattsville Elementary',
  'Hyattsville Middle',
  'Hybla Valley Elementary',
  'Hydaburg School',
  'Hyde Addison Es At Meyer',
  'Hyde Elem.',
  'Hyde Grove Elementary School',
  'Hyde Intl Studies/commun Elem Magnet',
  'Hyde Leadership Charter School',
  'Hyde Leadership Charter School - Brooklyn',
  'Hyde Park Academy High School',
  'Hyde Park El',
  'Hyde Park Elem School',
  'Hyde Park Elementary School',
  'Hyde Park Middle School',
  'Hyde Park School',
  'Hyder School',
  'Hydesville Elementary',
  'Hydetown El Sch',
  'Hydro-eakly Es',
  'Hydro-eakly Hs',
  'Hydro-eakly Ms',
  'Hyer El',
  'Hygiene Elementary School',
  'Hylen Souders Elementary School',
  'Hyman Fine Elementary School',
  'Hynes Elem School',
  'Hyogo College of Medicine',
  'Hyogo University',
  'Hyogo University of Education',
  'Hyrcania Institute of Higher Education',
  'Hyre Community Learning Center',
  'Hysham 7-8',
  'Hysham High School',
  'Hysham School',
  'Hyupsung University',
  'I AM ACADEMY',
  'I B Perrine Elementary School',
  'I C Evans El',
  'I Can Do Anything Charter High School',
  'I E Johnson Elementary',
  'I Kan Regional Alternatve Att Ctr',
  'I M Terrell El',
  'I Preparatory Academy',
  'I S KOSLOFF TORAH ACADEMY HIGH SCHOOL FOR GIRLS',
  'I T Holleman El',
  'I T Montgomery Elementary School',
  'I T Stoddard Elementary School',
  'I W And Eleanor Hyde El',
  'I X L Elem',
  "I'SOT SCHOOL",
  'I-Shou University',
  'I-lead Charter School',
  'I-tracc',
  'I. J. AND JEANNE WAGNER JCC EARLY CHILDHOOD CENTER',
  'I.M. Sechenov Moscow Medical Academy',
  'I.a. Lewis School',
  'I.c. Norcom High',
  'I.j. Holton Intermediate School',
  'I4learning Community School',
  'IASE (Institute of Advanced Studies) Deemed University',
  'IBAIS University',
  'IBCK EDUCATIONAL CENTER',
  'IBN SEENA ACADEMY',
  'IC CATHOLIC PREP',
  'ICC COMMUNITY SCHOOL',
  'ICCI ACADEMY',
  'ICFAI University',
  'ICI University',
  'ICM ACADEMY',
  'ICN FULL-TIME SCHOOL',
  'ICOB ACADEMY',
  'IDA CROWN JEWISH ACADEMY',
  'IDEA ACADEMY',
  'IDEAL MONTESSORI SCHOOL',
  'IDEAL SCHOOL',
  'IDEAVENTIONS ACADEMY FOR MATHEMATICS AND SCIENCE',
  'IDRAC (Institut de recherche en action commerciale)',
  'IDYLLWILD ARTS ACADEMY',
  'IDYLLWILD MONTESSORI SCHOOL',
  'IE University',
  'IL CENTRAL CHRISTIAN SCHOOL',
  'ILAN HIGH SCHOOL',
  'ILAN RAMON DAY SCHOOL',
  'ILE OMODE',
  'ILIFF PRESCHOOL, INC.',
  'ILIRIA College',
  'ILLIANA CHRISTIAN HIGH SCHOOL',
  'ILLINOIS LUTHERAN ELEMENTARY SCHOOL',
  'ILLINOIS LUTHERAN HIGH SCHOOL',
  'ILM ACADEMY',
  'ILM MONTESSORI INC',
  'IM BIG NOW LEARNING CENTER',
  'IMAGINATION SCHOOL',
  'IMAGINATION STATION',
  'IMAGINATION STATION LEARNING CENTER',
  'IMAGINATION STATION TOO MONTESSORI',
  'IMAGINE ACADEMY',
  'IMAGO DEI ACADEMY',
  'IMAGO DEI MIDDLE SCHOOL',
  'IMAN ACADEMY - SOUTHWEST',
  'IMANI CHRISTIAN ACADEMY',
  'IMG ACADEMY',
  'IMHOTOP ACADEMY',
  'IMLAY CITY CHRISTIAN SCHOOL',
  'IMMACULATA ACADEMY',
  'IMMACULATA CATHOLIC SCHOOL',
  'IMMACULATA CLASSICAL ACADEMY',
  'IMMACULATA HIGH SCHOOL',
  'IMMACULATA HS',
  'IMMACULATA-LA SALLE HIGH SCHOOL',
  'IMMACULATE CONCEPTION',
  'IMMACULATE CONCEPTION / OLPH',
  'IMMACULATE CONCEPTION ACADEMY',
  'IMMACULATE CONCEPTION CATHEDRAL SCHOOL',
  'IMMACULATE CONCEPTION CATHOLIC ACADEMY IN ASTORIA',
  'IMMACULATE CONCEPTION CATHOLIC SCHOOL',
  'IMMACULATE CONCEPTION ELEMENTARY SCHOOL',
  'IMMACULATE CONCEPTION GRADE SCHOOL',
  'IMMACULATE CONCEPTION HIGH SCHOOL',
  'IMMACULATE CONCEPTION KINDERGARTEN AND NURSERY',
  'IMMACULATE CONCEPTION REGIONAL SCHOOL',
  'IMMACULATE CONCEPTION SCHOOL',
  'IMMACULATE CONCEPTION SCHOOL - EXCHANGE',
  'IMMACULATE CONCEPTION UKRAINIAN CATHOLIC SCHOOLS',
  'IMMACULATE CONCEPTION-ST JOSEPH SCHOOL',
  'IMMACULATE HEART',
  'IMMACULATE HEART ACADEMY',
  'IMMACULATE HEART CENTRAL JR/SR HIGH SCHOOL',
  'IMMACULATE HEART OF MARY',
  'IMMACULATE HEART OF MARY ACADEMY',
  'IMMACULATE HEART OF MARY SCHOOL',
  'IMMACULATE HEART OF MARY/ST CASIMIR SCHOOL',
  'IMMACULATE HIGH SCHOOL',
  'IMMANUEL BAPTIST CHURCH SCHOOL',
  'IMMANUEL CHRISTIAN ACADEMY',
  'IMMANUEL CHRISTIAN LEARNING CENTER',
  'IMMANUEL CHRISTIAN SCHOOL',
  'IMMANUEL DAY SCHOOL',
  'IMMANUEL EVANGELICAL LUTHERAN SCHOOL',
  'IMMANUEL EVANGELICAL SCHOOL',
  'IMMANUEL LUTHERAN',
  'IMMANUEL LUTHERAN CHURCH AND SCHOOL',
  'IMMANUEL LUTHERAN DAY SCHOOL',
  'IMMANUEL LUTHERAN HIGH SCHOOL',
  'IMMANUEL LUTHERAN PRESCHOOL & KINDERGARTEN',
  'IMMANUEL LUTHERAN SCHOOL',
  'IMMANUEL-LUTHERAN SCHOOL',
  'IMPACT ACADEMY SCHOOL',
  'IMS INDEPENDENT MISSION SCHOOLS',
  'INCARNATE WORD ACADEMY',
  'INCARNATE WORD ACADEMY MIDDLE LEVEL',
  'INCARNATE WORD ACADEMY-ELEM LEVEL',
  'INCARNATE WORD PARISH SCHOOL',
  'INCARNATION CATHOLIC ACADEMY',
  'INCARNATION CATHOLIC SCHOOL',
  'INCARNATION PARISH SCHOOL',
  'INCARNATION SCHOOL',
  'INCLUSIVE LEARNING ACADEMY',
  'INDEPENDENCE AT RAINBOW',
  'INDEPENDENCE BIBLE SCHOOL',
  'INDEPENDENCE CHRISTIAN ACADEMY',
  'INDEPENDENCE CHRISTIAN SCHOOL',
  'INDEPENDENCE HIGH SCHOOL',
  'INDEPENDENT BAPTIST ACADEMY',
  'INDEPENDENT EDUCATIONAL PROGRAMS',
  'INDEPENDENT HOLINESS ACADEMY',
  'INDEPENDENT SCHOOL OF THOUGHT',
  'INDIAN BIBLE ACADEMY',
  'INDIAN COMMUNITY SCHOOL',
  'INDIAN CREEK MENNONITE SCHOOL',
  'INDIAN CREEK SCHOOL',
  'INDIAN CREEK-WELCOME BAPTIST CHURCH SCHOOL',
  'INDIAN HARBOUR MONTESSORI',
  'INDIAN HILL SCHOOL',
  'INDIAN MOUND SCHOOL',
  'INDIAN MOUNTAIN SCHOOL',
  'INDIAN SPRINGS SCHOOL',
  'INDIAN VALLEY',
  'INDIANA ACADEMY',
  'INDIANA CHRISTIAN ACADEMY',
  'INDIANA HORIZON ACADEMY',
  'INDIANA MONTESSORI ACADEMY',
  'INDIANA MONTESSORI COMMUNITY SCHOOL',
  'INDIANA WESLEYAN SCHOOL',
  'INDIANAPOLIS JUNIOR ACADEMY',
  'INDIANAPOLIS SOUTHSIDE CHRISTIAN ACADEMY',
  'INEZ WRENN SDA SCHOOL',
  'INFANT DREAMS DAY CARE',
  'INFANT JESUS OF PRAGUE SCHOOL',
  'INFANT JESUS SCHOOL',
  'INFANT OF PRAGUE CATHOLIC SCHOOL',
  'INFINITE GRACE AUTISM ACADEMY',
  'INFINITY ACADEMY',
  'INLY SCHOOL',
  'INNER HARBOUR SCHOOL',
  'INNER-CITY SCHOOL',
  'INNERCEPT',
  'INNOVATIONS ACADEMY',
  'INSPIRATION ACADEMY',
  'INSPIRATIONS ACADEMY',
  'INSPIRED CHRISTIAN SCHOOL',
  'INSTILLING GOODNESS / DEVELOPING VIRTUE SCHOOLS',
  'INSTITUTE FOR EDUCATIONAL ACHIEVEMENT',
  'INSTITUTE OF ISLAMIC EDUCATION',
  'INSTITUTE OF KINGS AND PRIESTS INC',
  'INSTITUTE OF TECHNOLOGY AND ACADEMICS',
  'INSTITUTO BILINGUE GUADALUPANO',
  'INSTITUTO BILINGUE SAN JOSE',
  'INTEGERITY ACADEMY',
  'INTEGRITY CHRISTIAN ACADEMY',
  'INTEGRITY CHRISTIAN ACADEMY #6889',
  'INTEGRITY TABERNACLE CHRISTIAN ACADEMY',
  'INTELLICOR INTERNATIONAL ACADEMY',
  'INTENSIVE EDUCATION ACADEMY IN',
  'INTERACTIVE ACADEMY',
  'INTERACTIVE EDUCATION ACADEMY',
  'INTERAMERICAN LEARNING CENTER',
  'INTERCULTURAL MONTESSORI LANGUAGE SCHOOL',
  'INTERMOUNTAIN',
  'INTERMOUNTAIN ACADEMY',
  'INTERMOUNTAIN ADVENTIST ACADEMY',
  'INTERMOUNTAIN CHRISTIAN SCHOOL',
  'INTERNATIONAL ACADEMY OF ARTS & SCIENCES',
  'INTERNATIONAL ACADEMY OF CINCINNATI',
  'INTERNATIONAL BIBLE BAPTIST ACADEMY',
  'INTERNATIONAL BILINGUAL MONTESSORI SCHOOL',
  "INTERNATIONAL CHILDREN'S ACADEMY",
  'INTERNATIONAL CHRISTIAN ACADEMY',
  'INTERNATIONAL CHRISTIAN ACADEMY OF LABELLE',
  'INTERNATIONAL CHRISTIAN HIGH SCHOOL',
  'INTERNATIONAL CHRISTIAN MONTESSORI SCHL OF NEWPORT',
  'INTERNATIONAL CHRISTIAN SCHOOL',
  'INTERNATIONAL CHRISTIAN SCHOOLS KONA',
  'INTERNATIONAL COMMUNITY SCHOOL',
  'INTERNATIONAL CONNECTIONS ACADEMY',
  'INTERNATIONAL HIGH SCHOOL',
  'INTERNATIONAL ISLAMIC ACADEMY',
  'INTERNATIONAL LEADERSHIP ACADEMY',
  'INTERNATIONAL MONTESSORI ACADEMY',
  'INTERNATIONAL MONTESSORI PRESCHOOL',
  'INTERNATIONAL MONTESSORI SCHOOL',
  'INTERNATIONAL MONTESSORI SCHOOLS AT PAOLI',
  'INTERNATIONAL PREPARATORY SCHOOL',
  'INTERNATIONAL SCHOOL OF ARIZONA',
  'INTERNATIONAL SCHOOL OF BOSTON',
  'INTERNATIONAL SCHOOL OF BROOKLYN',
  'INTERNATIONAL SCHOOL OF INDIANA',
  'INTERNATIONAL SCHOOL OF LOS ANGELES - LOS FELIZ',
  'INTERNATIONAL SCHOOL OF LOS ANGELES - WEST VALLEY',
  'INTERNATIONAL SCHOOL OF LOS ANGELES ORANGE COUNTY',
  'INTERNATIONAL SCHOOL OF LOS ANGELES-BURBANK',
  'INTERNATIONAL SCHOOL OF LOS ANGELES-PAS',
  'INTERNATIONAL SCHOOL OF THE PENINSULA',
  'INTERNATIONAL SCHOOL OF TUCSON',
  "INTIRAYMI SPANISH MONTESSORI CHILDREN'S HOUSE",
  'INTOWN COMMUNITY SCHOOL',
  'INVERNESS CHRISTIAN ACADEMY',
  'INVITATION HILL ADVENTIST SCHOOL',
  'INWOOD CHRISTIAN SCHOOL',
  'IOLANI SCHOOL',
  'IONA PREPARATORY LOWER SCHOOL',
  'IONA PREPARATORY SCHOOL',
  'IOWA CHRISTIAN ACADEMY',
  'IOWA GREAT LAKES LUTHERAN SCHOOL',
  'IOWA MENNONITE SCHOOL',
  'IQRA ACADEMY OF UTAH',
  'IQRA MATH AND SCIENCE ACADEMY',
  "IRENE'S CHRISTIAN ACADEMY, INC.",
  'IRETON CHRISTIAN SCHOOLS',
  'IRINE M HUNT SCHOOL OF MARIN',
  'IRISHTOWN SCHOOL C/O SAMUEL STOLTZFUS',
  'IRON ACADEMY',
  'IRON GROVE SCHOOL',
  'IRON SHARPENS IRON ACADEMY',
  'IRONBOUND CATHOLIC ACADEMY',
  'IRONWOOD',
  'IRONWOOD (NTLP)',
  'IRONWOOD CHRISTIAN ACADEMY',
  'IRVINE HEBREW DAY SCHOOL',
  'IRVINE MONTESSORI',
  'ISAAC NEWTON CHRISTIAN ACADEMY',
  'ISCHOOL',
  'ISFORT - Institut de Formation en Technologie Alimentaire',
  'ISJ ACADEMY PK-8',
  'ISLAH ACADEMY',
  'ISLAMIC ACADAMEY OF SOUTH JERSEY',
  'ISLAMIC ACADEMY OF ALABAMA',
  'ISLAMIC ACADEMY OF BURLINGTON COUNTY',
  'ISLAMIC ACADEMY OF DELAWARE',
  'ISLAMIC ACADEMY OF NEW ENGLAND',
  'ISLAMIC ACADEMY OF SAN ANTONIO (IASA)',
  'ISLAMIC COMMUNITY SCHOOL',
  'ISLAMIC CULTURAL CENTER SCHOOL',
  'ISLAMIC FAITH ACADEMY',
  'ISLAMIC SCHOOL OF COLUMBIA-MISSOURI',
  'ISLAMIC SCHOOL OF GREATER KANSAS CITY',
  'ISLAMIC SCHOOL OF GREATER NEW ORLEANS',
  'ISLAMIC SCHOOL OF GREATER TOLEDO',
  'ISLAMIC SCHOOL OF IRVING',
  'ISLAMIC SCHOOL OF LOUISVILLE',
  'ISLAMIC SCHOOL OF MET',
  'ISLAMIC SCHOOL OF MIAMI',
  'ISLAMIC SCHOOL OF PORTLAND',
  'ISLAMIC SCHOOL OF RHODE ISLAND',
  'ISLAMIC SCHOOL OF SAN DIEGO',
  'ISLAND CHRISTIAN ACADEMY',
  'ISLAND CHRISTIAN SCHOOL',
  'ISLAND MONTESSORI ACADEMY',
  'ISLAND PACIFIC ACADEMY',
  'ISLAND PREP',
  'ISLE OF WIGHT ACADEMY',
  'ISRAEL HENRY BEREN HIGH SCHOOL',
  'ISSAQUAH MONTESSORI SCHOOL',
  'ISTHMUS MONTESSORI ACADEMY',
  'IT University of Copenhagen',
  "IT'S A SMALL WORLD ACADEMY",
  "IT'S A SMALL WORLD ELEMENTARY SCHOOL",
  'ITALIAN HOME FOR CHILDREN',
  'ITEC PREPARATORY ACADEMY',
  'ITHACA SDA SCHOOL',
  'ITHACA WALDORF SCHOOL',
  'ITOP CHRISTIAN ACADEMY',
  'IVA CHRISTIAN SCHOOL',
  'IVIE LEAGUE CHRISTIAN PRESCHOOL W SLAUSON',
  'IVY HALL ACADEMY',
  'IVY HILL ACADEMY',
  'IVY HILL CHRISTIAN ACADEMY',
  'IVY LEAGUE MONTESSORI SCHOOL',
  'IVY LEAGUE PRESCHOOL ACADEMY',
  'IVY LEAGUE SCHOOL',
  'IVY STREET SCHOOL',
  'IVYCREST MONTESSORI PRIVATE SCHOOL - CHAPMAN',
  'IVYCREST MONTESSORI PRIVATE SCHOOL - YORBA LINDA',
  'IVYMOUNT SCHOOL',
  'Iact College',
  'Iaeger Elementary School',
  'Iago J H',
  'Iao Intermediate School',
  'Ib School Of Quabbin',
  'Ibapah School',
  'Ibaraki Christian College',
  'Ibaraki Prefectural University of Health Sciences',
  'Ibaraki University',
  'Ibarra Elementary',
  'Iber Holmes Gove Middle School',
  'Iberia Elem.',
  'Iberia High',
  'Iberia Middle School',
  'Iberville Charter Academy',
  'Iberville Elementary School',
  'Ibn Sina National College for Medical Studies',
  'Ibn Sina University',
  'Ibra College of Technology',
  'Ibraham Elementary',
  'Ibrahim Babangida University',
  'Icademy Global',
  'Icahn Charter School 1',
  'Icahn Charter School 2',
  'Icahn Charter School 3',
  'Icahn Charter School 4',
  'Icahn Charter School 5',
  'Icahn Charter School 6',
  'Icahn Charter School 7',
  'Ican Charter Academy',
  'Icard Elementary',
  'Iccf Ed Program',
  'Icef Inglewood Elementary Charter Academy',
  'Icef Inglewood Middle Charter Academy',
  'Icef Innovation Los Angeles Charter',
  'Icef Lou Dantzler Preparatory Academy',
  'Icef View Park Preparatory Charter',
  'Icef View Park Preparatory Charter High',
  'Icef View Park Preparatory Charter Middle',
  'Icef Vista Elementary Academy',
  'Icef Vista Middle Academy',
  'Iceland Academy of the Arts',
  'Iceland University of Education',
  'Ichabod Crane Elementary School',
  'Ichabod Crane Middle School',
  'Ichabod Crane Primary School',
  'Ichabod Crane Senior High School',
  'Icicle River Middle School',
  'Icm - Farm Hill',
  'Icm - Lawrence',
  'Icm - Spencer',
  'Icm - Wesley',
  'Icm- Woodrow Wilson Middle School',
  'Ida B. Wells Academy Es/ms',
  'Ida Burns Elementary School',
  'Ida Cannon Middle',
  'Ida Elementary School',
  'Ida Flood Dodge Traditional Middle Magnet School',
  'Ida Freeman Es',
  'Ida Greene Lower Elementary',
  'Ida High School',
  'Ida Jew Academies',
  'Ida M. Stewart Elementary School',
  'Ida Middle School',
  'Ida Rankin Elementary School',
  'Ida S. Baker High School',
  'Idabel Hs',
  'Idabel Ms',
  'Idabel Primary South Es',
  'Idaho Arts Charter School',
  'Idaho City High School',
  'Idaho Connects Online Alternative School',
  'Idaho Connects Online School',
  'Idaho Distance Education Academy',
  'Idaho Falls Senior High School',
  'Idaho Fine Arts Academy',
  'Idaho Hill Elementary School',
  'Idaho Home Learning Academy',
  'Idaho School For The Deaf And The Blind',
  'Idaho Science And Technology Charter School',
  'Idaho State University',
  'Idaho Technical Career Academy',
  'Idaho Virtual Academy',
  'Idaho Vision High School',
  'Idaho Youth Challenge Academy Fall',
  'Idaho Youth Challenge Academy Spring',
  'Idalia Elementary School',
  'Idalia Junior-senior High School',
  'Idalou El',
  'Idalou H S',
  'Idalou Middle',
  'Idea Academy',
  'Idea Academy Alamo',
  'Idea Academy Mission',
  'Idea Academy Pharr',
  'Idea Academy San Benito',
  'Idea Academy San Juan',
  'Idea Academy Weslaco',
  'Idea Allan Academy',
  'Idea Allan College Preparatory',
  'Idea Bluff Springs Academy',
  'Idea Bluff Springs College Preparatory',
  'Idea Brackenridge Academy',
  'Idea Brackenridge College Preparatory',
  'Idea Brownsville Academy',
  'Idea Brownsville College Preparatory',
  'Idea Carver Academy',
  'Idea Carver College Preparatory',
  'Idea Center',
  'Idea College Prep',
  'Idea College Prep Weslaco',
  'Idea College Preparatory Alamo',
  'Idea College Preparatory Mission',
  'Idea College Preparatory Pharr',
  'Idea College Preparatory San Benito',
  'Idea College Preparatory San Juan',
  'Idea Eastside Academy',
  'Idea Eastside College Preparatory',
  'Idea Edinburg Academy',
  'Idea Edinburg College Preparatory',
  'Idea Ewing Halsell Academy',
  'Idea Ewing Halsell College Preparatory',
  'Idea Frontier Academy',
  'Idea Frontier College Preparatory',
  'Idea Judson Academy',
  'Idea Judson College Preparatory',
  'Idea Mays Academy',
  'Idea Mays College Preparatory',
  'Idea Mcallen Academy',
  'Idea Mcallen College Preparatory',
  'Idea Monterrey Park Academy',
  'Idea Monterrey Park College Preparatory',
  'Idea Najim Academy',
  'Idea Najim College Preparatory',
  'Idea North Mission Academy',
  'Idea North Mission College Preparatory',
  'Idea Pcs',
  'Idea Quest Academy',
  'Idea Quest College Preparatory',
  'Idea Rio Grande City Academy',
  'Idea Rio Grande City College Preparatory',
  'Idea Riverview Academy',
  'Idea Riverview College Preparatory',
  'Idea Rundberg Academy',
  'Idea Rundberg College Preparatory',
  'Idea South Flores Academy',
  'Idea South Flores College Preparatory',
  'Idea Toros College Preparatory',
  'Idea Tres Lagos Academy',
  'Idea Tres Lagos College Preparatory',
  'Idea Walzem Academy',
  'Idea Walzem College Preparatory',
  'Idea Weslaco Pike Academy',
  'Idea Weslaco Pike College Preparatory',
  'Ideal',
  'Ideal Academy Pcs',
  'Ideal Elem School',
  'Ider School',
  'Iditarod Elementary',
  'Idle Hour Elementary School',
  'Idlehurst Elementary School',
  'Idlewild Elementary',
  'Idlewild Elementary School',
  'Idlewood Elementary School',
  'Iduma El',
  'Idyllwild',
  'Idyllwilde Elementary School',
  'Idylwild Elementary School',
  'Iflagler-virtual Franchise',
  'Iforward',
  'Iftin Charter',
  'Igbinedion University',
  'Igh Early Childhood Program',
  'Igiugig School',
  'Ignacio Conchos School',
  'Ignacio Cruz Early Childhood Center',
  'Ignacio Elementary School',
  'Ignacio High School',
  'Ignacio Middle School',
  'Ignacio Miranda',
  'Ignacio Zaragoza El',
  'Ignite Achievement Academy',
  'Ignite Entrepreneurship Academy',
  'Ignite Innovation Academy - Pitt',
  'Igo-ono Elementary',
  'Igrad',
  'Igrad Academy',
  'Ihigh Virtual Academy',
  'Ihsca Charter High School',
  'Ijla Charter High School',
  'Ikard El',
  'Ikm-manning High School',
  'Ikm-manning Middle School',
  'Il Center For Rehab & Educ-r',
  'Il Dept Of Cldrn And Famly Svcs',
  'Il Mathematics & Science Academy',
  'Il Sch For The Visually Impaired',
  'Il Valley Central High School',
  'Il Youth Center - Pere Marquette',
  'Ila Elementary School',
  'Ilalko Elementary School',
  'Ilam University',
  'Ilam University of Medical Sciences',
  'Ilchester Elementary',
  'Ilead Academy',
  'Ilead Charter Sch',
  'Ilead Hybrid',
  'Ilead Lancaster Charter',
  'Ilead Online',
  'Ilead Spring Meadows',
  'Ileana De Gracia (sup. Urbana)',
  'Iles Elem School',
  'Ilia Chavchavadze State University',
  'Iliahi Elementary School',
  'Ilima Intermediate School',
  'Ilisagvik College',
  'Illahee Elementary School',
  'Illahee Middle School',
  'Illing Middle School',
  'Illini Bluffs Elementary School',
  'Illini Bluffs High School',
  'Illini Bluffs Middle School',
  'Illini Central Grade School',
  'Illini Central High School',
  'Illini Central Middle School',
  'Illini Elem School',
  'Illini Middle School',
  'Illini West High School',
  'Illinois Benedictine College',
  'Illinois Central College',
  'Illinois City Elem School',
  'Illinois Institute of Technology',
  'Illinois Park Elem School',
  'Illinois School',
  'Illinois School For The Deaf',
  'Illinois State University',
  'Illinois Valley Community College',
  'Illinois Valley High School',
  'Illinois Youth Center-warrenville',
  'Illinois Youth Ctr - St Charles',
  'Ilsa Independent College',
  'Iltexas - East Fort Worth El',
  'Iltexas - East Fort Worth Middle',
  'Iltexas - Fort Worth/saginaw H S',
  'Iltexas - Houston Orem El',
  'Iltexas - Houston Orem Middle',
  'Iltexas - Houston Windmill Lake El',
  'Iltexas - Houston Windmill Lakes Middle',
  'Iltexas - Katy/westpark H S',
  'Iltexas - Lancaster El',
  'Iltexas - Lancaster Middle',
  'Iltexas - Saginaw El',
  'Iltexas - Saginaw Middle',
  'Ilwaco High School',
  'Im Terrell Academy For Stem And Vpa',
  'Image Elementary School',
  'Imagine 100 Academy Of Excellence',
  'Imagine Akron Academy',
  'Imagine Andrews Public Charter',
  'Imagine At South Vero',
  'Imagine Avondale Elementary',
  'Imagine Avondale Middle',
  'Imagine Bell Canyon',
  'Imagine Camelback Elementary',
  'Imagine Camelback Middle',
  'Imagine Charter',
  'Imagine Charter At Lakewood Ranch',
  'Imagine Charter School At North Manatee',
  'Imagine Charter School At Weston',
  'Imagine Charter/n Lauderdale',
  'Imagine Classical Academy',
  'Imagine Columbia Leadership Academy Charter',
  'Imagine Columbus Primary School',
  'Imagine Coolidge Elementary',
  'Imagine Cortez Park Elementary',
  'Imagine Cortez Park Middle',
  'Imagine Desert West Elementary',
  'Imagine Desert West Middle',
  'Imagine East Mesa Elementary',
  'Imagine East Mesa Middle',
  'Imagine Foundations At Leeland Pcs',
  'Imagine Foundations At Morningside Pcs',
  'Imagine Hill Avenue',
  'Imagine International Academy Of North Texas',
  'Imagine Leadership Academy',
  'Imagine Lincoln Public Charter',
  'Imagine Me Leadership Charter School',
  'Imagine Prep Coolidge',
  'Imagine Prep Superstition',
  'Imagine Prep Surprise',
  'Imagine Rosefield',
  'Imagine School At Evening Rose',
  "Imagine School At Land O' Lakes",
  'Imagine School At North Port',
  'Imagine School At Palmer Ranch',
  'Imagine School At Town Center',
  'Imagine School Mountain View',
  'Imagine School South Campus',
  'Imagine Schools At Broward',
  'Imagine Schools At Imperial Valley',
  'Imagine Schools At South Lake',
  'Imagine Schools At West Melbourne',
  'Imagine Schools Chancellor Campus',
  'Imagine Schools Riverside County',
  'Imagine Superstition Middle',
  'Imagine Surprise Middle',
  'Imagine Technical Academy',
  'Imagine Tempe',
  'Imagine West Gilbert Elementary',
  'Imagine West Gilbert Middle',
  'Imagine Woodbury Academy',
  'Imagineering Academy',
  'Imam Khomeini International University',
  'Imam Reza University',
  'Imam Sadiq University',
  'Imater Academy',
  'Imater Academy Middle School',
  'Imater Preparatory Academy High School',
  'Imbery',
  'Imbler Charter School',
  'Imboden Area Charter School',
  'Imhotep Institute Chs',
  'Imk Community Education',
  'Imlay City High School',
  'Imlay City Middle School',
  'Imlay Elementary School',
  'Immanuel Kant State University of Russia',
  'Immersion Schools Science Technology Arts',
  'Immigrant Shelter',
  'Immokalee Community School',
  'Immokalee High School',
  'Immokalee Middle School',
  'Immokalee Technical College',
  'Immokalee Technology Academy',
  'Immokalee Teen Parenting Prog',
  'Imnaha Elementary School',
  'Imo State University',
  'Imogene Garner Hook Junior High',
  'Imogene Gideon El',
  'Impact Academy At Orchard Lake El',
  'Impact Academy Of Arts & Technology',
  'Impact Charter Elementary',
  'Impact Early College H S',
  'Impact Halfway House',
  'Impact Prep At Chestnut',
  'Impact Reengagement Program',
  'Imperial Ave. Holbrook High',
  'Imperial Beach Charter',
  'Imperial College London',
  'Imperial College School of Medicine',
  'Imperial County Juvenile Hall/community',
  'Imperial County Special Education',
  'Imperial Elementary',
  'Imperial Estates Elementary School',
  'Imperial High',
  'Imperial Middle',
  'Imperial Pathways Charter',
  'Imperial Valley College',
  'Imperial Valley Home School Academy',
  'Imperial Valley Rop',
  'Imprints Program',
  'In Math & Science Academy',
  'In Math & Science Academy - North',
  'In Math & Science Academy - South',
  'In-tech Academy (ms/hs 368)',
  'Ina Arbuckle Elementary',
  'Inarajan Elementary School',
  'Inarajan Middle School',
  'Inavale Community Partners Dba Muddy Creek Charter School',
  'Inborden Elementary S.t.e.a.m. Academy',
  'Inca Elementary School',
  'Inchelium Elementary School',
  'Inchelium High School',
  'Inchelium Middle School',
  'Inchon National University of Education',
  'Inchon University',
  'Incito Schools',
  'Incline Elementary School',
  'Incline High School',
  'Incline Middle School',
  'Increase Miller Elementary School',
  'Independence',
  'Independence Academy',
  'Independence Academy Middle School',
  'Independence Alternative High School',
  'Independence Charter',
  'Independence Charter Academy',
  'Independence Charter Ms',
  'Independence Community College',
  'Independence Continuation',
  'Independence Continuation High',
  'Independence Cs',
  'Independence Cs West',
  'Independence El',
  'Independence El Sch',
  'Independence Elem School',
  'Independence Elem.',
  'Independence Elementary',
  'Independence Elementary School',
  'Independence Es',
  'Independence H S',
  'Independence High',
  'Independence High (alternative)',
  'Independence High (continuation)',
  'Independence High Magnet',
  'Independence High School',
  'Independence Jr High School',
  'Independence Junior Senior High School',
  'Independence Leadership Academy',
  'Independence Magnet',
  'Independence Middle',
  'Independence Middle School',
  'Independence Ms',
  'Independence Preschool',
  'Independence Primary School',
  'Independence School Local I',
  'Independence Sr High',
  'Independence Valley Elementary School',
  'Independent Elementary',
  'Independent Hill',
  'Independent Scholar',
  'Independent School',
  'Independent Study Program',
  'Independent Study Sojourner Truth',
  'Independent University Bangladesh',
  'Inderkum High',
  'Index Elementary School',
  'India Hook Elementary',
  'Indiahoma Es',
  'Indiahoma Hs',
  'Indialantic Elementary School',
  'Indian Agricultural Research Institute',
  'Indian Ave School',
  'Indian Bayou Elementary School',
  'Indian Bend Elementary School',
  'Indian Board of Alternative Medicine',
  'Indian Brook',
  'Indian Camp Es',
  'Indian Creek El',
  'Indian Creek Elem',
  'Indian Creek Elem.',
  'Indian Creek Elementary',
  'Indian Creek Elementary School',
  'Indian Creek High School',
  'Indian Creek Intermediate School',
  'Indian Creek Middle School',
  'Indian Creek Middle School Grades 5 & 6',
  'Indian Creek Middle School Grades 7 & 8',
  'Indian Creek Sr High School',
  'Indian Crest Ms',
  'Indian Diggings Elementary',
  'Indian Fields Elementary School',
  'Indian Grove Elem School',
  'Indian Head',
  'Indian Head Elementary School',
  'Indian Hill',
  'Indian Hill Elem School',
  'Indian Hill Elementary',
  'Indian Hill Elementary School',
  'Indian Hill High School',
  'Indian Hill Middle School',
  'Indian Hill Primary Elementary School',
  'Indian Hill School',
  'Indian Hills Community College',
  'Indian Hills Continuation High',
  'Indian Hills Elementary',
  'Indian Hills Elementary School',
  'Indian Hills High School',
  'Indian Hills Junior High School',
  'Indian Hills Middle',
  'Indian Hills School',
  'Indian Hollow Elementary',
  'Indian Hollow School',
  'Indian Institute of Information Technology',
  'Indian Institute of Information Technology Guwahati',
  'Indian Institute of Information Technology Kota',
  'Indian Institute of Information Technology Vadodara',
  'Indian Institute of Information Technology and Management - Gwalior',
  'Indian Institute of Information Technology and Management - Kerala',
  'Indian Institute of Management Tiruchirappalli',
  'Indian Institute of Science',
  'Indian Institute of Technology Bombay',
  'Indian Institute of Technology Delhi',
  'Indian Institute of Technology Guwahati',
  'Indian Institute of Technology Hyderabad',
  'Indian Institute of Technology Indore',
  'Indian Institute of Technology Jodhpur',
  'Indian Institute of Technology Kanpur',
  'Indian Institute of Technology Kharagpur',
  'Indian Institute of Technology Madras',
  'Indian Institute of Technology Patna',
  'Indian Institute of Technology Roorkee',
  'Indian Island School',
  'Indian Knoll Elem School',
  'Indian Knoll Elementary',
  'Indian Lake Central School',
  'Indian Lake Elementary',
  'Indian Lake Elementary School',
  'Indian Lake High School',
  'Indian Lake Middle School',
  'Indian Lakes Elementary',
  'Indian Land Elementary',
  'Indian Land High',
  'Indian Land Middle',
  'Indian Landing Elementary School',
  'Indian Lane El Sch',
  'Indian Law Institue',
  'Indian Meridian Es',
  'Indian Mills Elementary School',
  'Indian Mills Memorial School',
  'Indian Mound Middle',
  'Indian Mounds Elementary',
  'Indian Neck School',
  'Indian Oasis Intermediate Elementary School',
  'Indian Oasis Intermediate School To Baboquivari Middle Schoo',
  'Indian Oasis Primary Elementary School',
  'Indian Orchard Elementary',
  'Indian Paintbrush Elementary',
  'Indian Peaks Charter School',
  'Indian Peaks Elementary School',
  'Indian Pines Elementary School',
  'Indian Plains Alternative School',
  'Indian Prairie Elem School',
  'Indian Prairie Elementary School',
  'Indian Queen Elementary',
  'Indian Ridge Elementary School',
  'Indian Ridge Middle',
  'Indian Ridge Middle School',
  'Indian Ridge School',
  'Indian Riffle Elementary School',
  'Indian River (irjcf)',
  'Indian River Academy',
  'Indian River Charter High School',
  'Indian River Elementary School',
  'Indian River High',
  'Indian River High School',
  'Indian River Intensive Learning Center',
  'Indian River Intermediate School',
  'Indian River Middle',
  'Indian River Middle School',
  'Indian River School',
  'Indian River State College',
  'Indian River Virtual Instruction Program',
  'Indian Rock El Sch',
  'Indian Run Elementary School',
  'Indian School of Business Management and Administration',
  'Indian School of Mines (ISM)',
  'Indian Spring Middle',
  'Indian Springs El',
  'Indian Springs Elementary',
  'Indian Springs Elementary School',
  'Indian Springs High',
  'Indian Springs High School',
  'Indian Springs Middle',
  'Indian Springs Middle School',
  'Indian Statistical Institute',
  'Indian Township School',
  'Indian Trace Elementary School',
  'Indian Trail Elem School',
  'Indian Trail Elementary',
  'Indian Trail Elementary School',
  'Indian Trail High School And Academy',
  'Indian Trail Intermediate School',
  'Indian Trail Jr High School',
  'Indian Trail Middle School',
  'Indian Trails Elem.',
  'Indian Trails Middle School',
  'Indian University',
  'Indian Valley El Sch',
  'Indian Valley Elementary',
  'Indian Valley Elementary School',
  'Indian Valley High School',
  'Indian Valley Intermediate School',
  'Indian Valley Intrmd Sch',
  'Indian Valley Ms',
  'Indian Veterinary Research Institute',
  'Indian Village Elementary School',
  'Indian Wells Elementary',
  'Indian Woods Elementary School',
  'Indian Woods Middle',
  'Indiana Academy For Sci Math Hmn',
  'Indiana Area Jhs',
  'Indiana Area Shs',
  'Indiana Avenue Elementary School',
  'Indiana College Preparatory School',
  'Indiana Connections Academy',
  'Indiana Connections Career Academy',
  'Indiana County Technology Center',
  'Indiana Elem School',
  'Indiana Institute of Technology',
  'Indiana School For The Blind',
  'Indiana School For The Deaf',
  'Indiana State University',
  'Indiana University',
  'Indiana University Southeast',
  'Indiana University at Bloomington',
  'Indiana University at South Bend',
  'Indiana University of Pennsylvania',
  'Indiana University/Purdue University at Columbus',
  'Indiana University/Purdue University at Fort Wayne',
  'Indiana University/Purdue University at Indianapolis',
  'Indiana Virtual Pathways Academy',
  'Indiana Virtual School',
  'Indiana Wesleyan University Marion',
  'Indianapolis Academy Of Excellence',
  'Indianapolis Lighthouse East',
  'Indianapolis Metropolitan High Sch',
  'Indianhead Academy High School',
  'Indianola Academic Achievement',
  'Indianola Career & Tech Center',
  'Indianola Elementary',
  'Indianola Es',
  'Indianola High School',
  'Indianola Hs',
  'Indianola Informal K-8 School',
  'Indianola Middle School',
  'Indiantown Adult High School',
  'Indiantown Middle School',
  'Indiera Fria',
  'Indio High',
  'Indio Middle',
  'Indira Gandhi Agricultural University',
  'Indira Gandhi Institute of Development and Research',
  'Indira Gandhi Institute of Medical Sciences',
  'Indira Gandhi National Open University',
  'Individualized Learning Center',
  'Individuals Achieving Independence',
  'Indpls Lighthouse Charter School',
  'Indra House',
  'Indraprastha Institute of Information Technology Delhi',
  'Indus Elementary',
  'Indus Institute of Higher Education',
  'Indus Secondary',
  'Industrial Design Engineering And Art',
  'Industrial El East',
  'Industrial El West',
  'Industrial H S',
  'Industrial J H',
  'Industry Limited Secure School',
  'Ines Encarnacion',
  'Ines M Mendoza Vda De Munoz Marin',
  'Ines M. Mendoza De Munoz Marin',
  'Ines Maria Mendoza',
  'Ines Maria Mendoza De Munoz Marin',
  'Inez Elementary',
  'Inez Elementary School',
  'Infant And Preschool Program',
  'Infant Center',
  'Infant Pre',
  'Infant Toddler At Bryan Community School',
  'Infant Toddler At Lincoln High School',
  'Infant Toddler At Lincoln North Star High School',
  'Infant Toddler At Lincoln Northeast High School',
  'Infinity Cs',
  'Infinity Early College H S',
  'Infinity Institute',
  'Infinity Math Science & Tech Hs',
  'Infinity Middle School',
  'Infinity:minnesota Digital Academy',
  'Information Technology And Software Engineering High School',
  'Information Technology High School',
  'Information and Communications Technology Academy',
  'Information and Communications University',
  'Ingalls',
  'Ingalls Elem',
  'Ingalls High School/junior High',
  'Ingels Elem.',
  'Ingenium Charter',
  'Ingenium Charter Middle',
  'Ingenuity Charter',
  'Ingenuity Prep Pcs',
  'Ingersoll Middle School',
  'Ingham Academyfamily Center',
  'Ingham Administrative Unit',
  'Ingham County Youth Center',
  'Ingham Isd Asd Programs',
  'Ingham Virtual High School',
  'Inglemoor Hs',
  'Inglenook School',
  'Ingleside Elementary',
  'Ingleside H S',
  'Ingleside Middle School',
  'Ingleside Primary School',
  'Inglewood Continuation High',
  'Inglewood El Sch',
  'Inglewood Elementary',
  'Inglewood High',
  'Inglewood Middle School',
  'Ingomar Attendance Center',
  'Ingomar El Sch',
  'Ingomar Ms',
  'Ingraham High School',
  'Ingram El',
  'Ingram Middle',
  'Ingram Sowell Elementary',
  'Ingram Tom Moore H S',
  'Ingram/pye Elementary School',
  'Ingrid B. Lacy Middle',
  'Inha University',
  'Iniciativa Universidad del Atlantico',
  'Initial Point High School',
  'Inje University',
  'Inkom Elementary School',
  'Inkster Preparatory Academy',
  'Inland Lakes Elementary School',
  'Inland Lakes Secondary School',
  'Inland Leaders Charter',
  'Inlet Elementary School',
  'Inlet Grove Community High School',
  'Inlet Grove High Adult',
  'Inlet View Elementary',
  'Inman Elem',
  'Inman Elementary',
  'Inman Intermediate',
  'Inman Jr/sr High School',
  'Inman Middle School',
  'Inman Primary School',
  'Inner Mongolia Agricultural University',
  'Inner Mongolia Normal University',
  'Inner Mongolia Polytechnic University',
  'Inner Mongolia University',
  'Innes Community Learning Center',
  'Innis Elementary School',
  'Innocademy',
  'Innocademy Allegan Campus',
  'Innoko River School',
  'Innovation Academy',
  'Innovation Academy Charter School',
  'Innovation Central High School',
  'Innovation Charter School',
  'Innovation Design Entrepreneurship Academy',
  'Innovation Diploma Plus',
  'Innovation Early College High',
  'Innovation High School',
  'Innovation Middle',
  'Innovation Montessori Ocoee',
  'Innovations - Pcs',
  'Innovations Academy',
  'Innovations Academy Of Palmdale',
  'Innovations High School',
  'Innovations International Charter School Of Nv',
  'Innovations K-8',
  'Innovations Middle Charter',
  'Innovations Stem Academy',
  'Innovative & Alternative High',
  'Innovative & Alternative Middle',
  'Innovative Arts Academy Cs',
  'Innovative Connections High School',
  'Innovative Horizons Charter',
  'Innovative Learning Center',
  'Inocencio A. Montero',
  'Inocencio Cintron Zayas',
  'Inola Es',
  'Inola Hs',
  'Inola Ms',
  'Inoorero University',
  'Inquiry Charter School',
  'Insel Gruppe',
  'Insight @ San Diego',
  'Insight @ San Joaquin',
  'Insight Pa Cyber Cs',
  'Insight School Of California',
  'Insight School Of Indiana',
  'Insight School Of Ks At Hilltop Ed Center',
  'Insight School Of Michigan',
  'Insight School Of Minnesota',
  'Insight School Of Ohio',
  'Insight School Of Oklahoma Hs',
  'Insight School Of Oklahoma Ms',
  'Insight School Of Oregon Painted Hills',
  'Insight School Of Washington',
  'Insight School Of Wisconsin High',
  'Insights Learning Center',
  'Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh',
  'Inskip Elementary',
  'Inspire Academy - A Sch Of Inquiry',
  'Inspire Charter',
  'Inspire Charter - Kern',
  'Inspire Charter - South',
  'Inspire Charter Academy (natl. Heritage Acad.)',
  'Inspire Charter School - North',
  'Inspire Charter Schools - Central',
  'Inspire Elementary',
  'Inspire School Of Arts And Sciences',
  'Inspire Virtual Charter School',
  'Inspired For Excellence Academy Inc',
  'Inspired Teaching Demonstration Pcs',
  'Inspired Vision Academy El',
  'Inspired Vision Academy Middle',
  'Institucion Universitaria de Envigado',
  'Institución Universitaria Iberoamericana',
  'Institue of Historical Research University of London',
  'Institue of Technology Tralee',
  'Institut Armand-Frappier Université du Québec',
  "Institut Catholique d'Arts et Métiers Lille",
  "Institut Catholique d'Arts et Métiers Nantes",
  'Institut Catholique de Paris',
  'Institut Catholique de Toulouse',
  'Institut Commercial de Nancy',
  'Institut National Polytechnique de Grenoble',
  'Institut National Polytechnique de Lorraine',
  'Institut National Polytechnique de Toulouse',
  'Institut National Supérieur de Formation Agro-Alimentaire',
  'Institut National de la Recherche Scientifique Université du Québec',
  'Institut National des Postes et Télécommunications',
  'Institut National des Sciences Appliquées de Lyon',
  'Institut National des Sciences Appliquées de Rennes',
  'Institut National des Sciences Appliquées de Rouen',
  'Institut National des Sciences Appliquées de Toulouse',
  'Institut National des Télécommunications',
  'Institut Prima Bestari - Pine Academy',
  'Institut Sains & Teknologi Akprind',
  'Institut Sains dan Teknologi Al Kamal',
  'Institut Supérieur Agricole de Beauvais',
  "Institut Supérieur d'Agriculture Lille",
  "Institut Supérieur d'Agriculture Rhone-Alpes",
  "Institut Supérieur de Commerce et d'Administration des Entreprises",
  'Institut Supérieur de Gestion de Tunis',
  'Institut Supérieur de Management et de Technologie (MATCI)',
  "Institut Supérieure d'Electronique de Paris",
  "Institut Supérieure d'Electronique du Nord",
  'Institut Teknologi Adhi Tama Surabaya',
  'Institut Teknologi Bandung',
  'Institut Teknologi Brunei',
  'Institut Teknologi Sepuluh Nopember',
  'Institut Textile et Chimique de Lyon',
  "Institut d'Agriculture de Technologie et d'Education de Kibungo",
  "Institut d'Etudes Politiques de Bordeaux",
  "Institut d'Etudes Politiques de Paris (Sciences Po)",
  "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique",
  'Institut des Hautes Etudes de Management',
  "Institut des Sciences de l'Ingénieur de Clermont-Ferrand",
  "Institut des Sciences de l'Ingénieur de Montpellier",
  'Institut des Sciences de la Matière et du Rayonnement',
  'Institut des hautes études économiques et commerciales',
  'Institute For Collaborative Education',
  'Institute For Health Professionals At Cambria Heights',
  'Institute Of Technology At Syracuse Central',
  'Institute Of Technology Australia',
  'Institute for Advanced Studies Lucca',
  'Institute for Command Engineers of The Ministry for Emergency Situations',
  'Institute for World Politics',
  'Institute for the Economy in Transition',
  'Institute of Accountancy Arusha',
  'Institute of Advanced Legal Studies University of London',
  'Institute of Architecture "Ion Mincu" Bucharest',
  'Institute of Business & Technology',
  'Institute of Business Administration (IBA)',
  'Institute of Business Administration Sukkur',
  'Institute of Business and Politics',
  'Institute of Classical Studies University of London',
  'Institute of Commerce and Business',
  'Institute of Commonwealth Studies University of London',
  'Institute of Education University of London',
  'Institute of Finance',
  'Institute of Finance Management',
  'Institute of Germanic Studies University of London',
  'Institute of Industrial Electronics Engineering',
  'Institute of Information Technology',
  'Institute of Latin American Studies University of London',
  'Institute of Management & Enteurpreneurship of South East Europe',
  'Institute of Management Development - Ohrid',
  'Institute of Management Sciences Lahore (IMS)',
  'Institute of Management Sciences Peshawar',
  'Institute of Management Studies',
  'Institute of Management and Business Technology',
  'Institute of Management and Economy',
  'Institute of Public Administration',
  'Institute of Science and Technology',
  'Institute of Social Studies',
  'Institute of Space Technology',
  'Institute of Teachers Education Batu Lintang',
  'Institute of Teachers Education Darul Aman',
  "Institute of Teachers Education Dato' Razali Ismail",
  'Institute of Teachers Education Ilmu Khas',
  'Institute of Teachers Education Ipoh',
  'Institute of Teachers Education Islamic Education',
  'Institute of Teachers Education Keningau',
  'Institute of Teachers Education Kent',
  'Institute of Teachers Education Kota Bharu',
  'Institute of Teachers Education Malay Language',
  'Institute of Teachers Education Melaka',
  'Institute of Teachers Education Penang',
  'Institute of Teachers Education Perlis',
  'Institute of Teachers Education Raja Melewar',
  'Institute of Teachers Education Rajang',
  'Institute of Teachers Education Sarawak',
  'Institute of Teachers Education Sultan Abdul Halim',
  'Institute of Teachers Education Sultan Mizan',
  'Institute of Teachers Education Tawau',
  'Institute of Teachers Education Technical Education',
  'Institute of Teachers Education Temenggong Ibrahim',
  'Institute of Teachers Education Tengku Ampuan Afzan',
  'Institute of Teachers Education Tuanku Bainun',
  'Institute of Teachers Education Tun Hussein Onn',
  'Institute of Technology and Management',
  'Instituto Loaiza Cordero',
  'Instituto Mauá de Tecnologia',
  'Instituto Nacional de Educación Física "General Manuel Belgrano"',
  'Instituto Nacional de Matemática Pura e Aplicada - IMPA',
  'Instituto Piaget',
  'Instituto Politecnico do Porto',
  'Instituto Politécnico Nacional',
  'Instituto Politécnico Nacional Campus Guanajuato',
  'Instituto Politécnico da Guarda',
  'Instituto Politécnico de Beja',
  'Instituto Politécnico de Bragança',
  'Instituto Politécnico de Castelo Branco',
  'Instituto Politécnico de Coimbra',
  'Instituto Politécnico de Leiria',
  'Instituto Politécnico de Lisboa',
  'Instituto Politécnico de Portalegre',
  'Instituto Politécnico de Santarém',
  'Instituto Politécnico de Setúbal',
  'Instituto Politécnico de Tomar',
  'Instituto Politécnico de Viana do Castelo',
  'Instituto Politécnico do Cávado e do Ave',
  'Instituto Superior Bissaya Barreto',
  'Instituto Superior D. Afonso III - INUAF',
  'Instituto Superior Minero Metalúrgico "Dr. Antonio Núñez Jiménez"',
  'Instituto Superior Novas Profissões - INP',
  'Instituto Superior Politécnico Gaya',
  'Instituto Superior Politécnico José Antonio Echeverría',
  'Instituto Superior Politécnico e Universitário',
  'Instituto Superior de Assistentes e Intérpretes',
  'Instituto Superior de Ciências Educativas',
  'Instituto Superior de Ciências Empresariais e de Turismo',
  'Instituto Superior de Ciências do Trabalho e da Empresa',
  'Instituto Superior de Ciênicas e Tecnologia de Moçambique',
  'Instituto Superior de Entre Douro e Vouga',
  'Instituto Superior de Gestão - ISG',
  'Instituto Superior de Informática e Gestão',
  'Instituto Superior de Línguas e Administração',
  'Instituto Superior de Paços de Brandão',
  'Instituto Superior de Psicologia Aplicada',
  'Instituto Superior de Relações Internacionais (ISRI)',
  'Instituto Superior de Saúde do Alto Ave',
  'Instituto Superior de Tecnologias Avançadas - ISTEC',
  'Instituto Superior de Transportes e Comunicações',
  'Instituto Tecnologico de Durango',
  'Instituto Tecnologico de Minatitlan',
  'Instituto Tecnológico Autonómo de México',
  'Instituto Tecnológico Metropolitano',
  'Instituto Tecnológico de Aeronáutica',
  'Instituto Tecnológico de Aguascalientes',
  'Instituto Tecnológico de Buenos Aires',
  'Instituto Tecnológico de Celaya',
  'Instituto Tecnológico de Costa Rica',
  'Instituto Tecnológico de León',
  'Instituto Tecnológico de Morelia',
  'Instituto Tecnológico de Querétaro',
  'Instituto Tecnológico de San Luis Potosí',
  'Instituto Tecnológico de Santo Domingo',
  'Instituto Tecnológico de Sonora',
  'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)',
  'Instituto Tecnológico y de Estudios Superiores de Occidente',
  'Instituto Universitario Aeronáutico',
  'Instituto Universitario CEMA',
  'Instituto Universitario Nacional del Arte',
  'Instituto Universitario de Ciencias de la Salud - Buenos Aires',
  'Instituto Universitario de Estudios Navales y Marítimos',
  'Instituto Universitario de La Paz',
  'Instituto Universitario de la Policia Federal',
  'Instituto de Artes Visuais Design e Marketing - IADE',
  'Instituto de Enseñanza Superior Oteima',
  'Instituto de Enseñanza Superior del Ejército',
  'Instituto de Estudios Superiores de la Sierra',
  'Instituts Supérieurs de Formation des Maîtres',
  'Instituts Supérieurs des Etudes Technologiques',
  'Instr/guid Center',
  'Instructional & Guidance Center',
  'Instructional Center System Wide',
  'Instructional Resource Center',
  'Int Bo Quebrada Arenas',
  "Int'l Leadership Of Texas Es- N Richland Hills",
  "Int'l Leadership Of Texas Middle - Grand Prairie",
  "Int'l Leadership Of Texas Ms-n Richland Hills",
  "Int'l Newcomer Acad",
  'Intech Collegiate High School',
  'Integral University',
  'Integrated Arts Academy',
  'Integrated Arts Academy At H. O. Wheeler',
  'Integrated Arts And Technology High School',
  'Integrated Day Charter School',
  'Integrated Learning Program',
  'Integrated Preschool',
  'Integrated Star Preschool Program',
  'Integrity Charter',
  'Integrity Education Centre',
  'Intellectual Virtues Academy',
  'Intellectual Virtues Academy Of Long Beach',
  'Intelli-school',
  'Intelli-school - Metro Center',
  'Intelli-school - Paradise Valley',
  'Intelli-school Glendale',
  'Intensive Dcd - Snw',
  'Intensive Interagency Program',
  'Intensive Learning Center',
  'Inter-american Elem Magnet School',
  'Inter-lakes Elementary School',
  'Inter-lakes High School',
  'Inter-lakes Middle Tier',
  'Interactive Media & Construction (imac)',
  'Interagency Alternative Secondary Center',
  'Interagency Detention School',
  'Interagency Open Doors',
  'Interagency Programs',
  'Interboro Shs',
  'Intercollege',
  'Interdistrict Discovery Magnet School',
  'Interdistrict School For Arts And Communication',
  'Intergenerational School The',
  'Interior Distance Education Of Alaska (idea)',
  'Interior Elementary - 09',
  'Interlachen Elementary School',
  'Interlachen High School',
  'Interlake Senior High School',
  'Intermedia Berwind',
  'Intermedia Bo Quebradillas',
  'Intermedia Juan Serralles',
  'Intermedia Nueva',
  'Intermedia Ramon Perez Purcel',
  'Intermedia Rexville',
  'Intermedia Roman Baldorioty De Castro',
  'Intermedia Salvador Busquets',
  'Intermedia Urbana',
  'Intermediate Education Center',
  'Intermediate Elementary School',
  'Intermediate Sch',
  'Intermediate Sch.',
  'Intermediate School',
  'Intermediate School At Valparaiso',
  'Intermountain Stem Academy Charter',
  'International Acad Of Columbus',
  'International Academy',
  'International Academy At Hull',
  'International Academy Of Atlantic City Charter School',
  'International Academy Of Denver At Harrington',
  'International Academy Of Flint K12',
  'International Academy Of Macomb',
  'International Academy Of Saginaw',
  'International Academy Of Smyrna',
  'International Academy Of Trenton Charter School',
  'International Ataturk Alatoo University',
  'International Banking Institute St. Petersburg',
  'International Blacksea University',
  'International Buddhist College',
  'International Buddhist University',
  'International Budo University',
  'International Burch University',
  'International Business School Kelajak ILMI',
  'International Business School of Scandinavia',
  'International Business University of Beijing',
  'International Centre for Isclamic Science',
  'International Charter School',
  'International Charter School Of Atlanta',
  'International Charter School Of New York (the)',
  'International Christian University',
  'International College of Tourism and Hotel Management',
  'International College of the Cayman Islands',
  'International Colleges of Islamic Science',
  'International Commerce High School - Phoenix',
  'International Commerce High School - Tempe',
  'International Community',
  'International Community High School',
  'International Community School',
  'International Culinary Institute (ICI)',
  'International Culture University',
  'International East-European University',
  'International H S',
  'International Hellenic University',
  'International High School',
  'International High School @ Langley Park',
  'International High School @ Largo',
  'International High School At Lafayette',
  'International High School At Laguardia Community College',
  'International High School At Prospect Heights',
  'International High School At Union Square',
  'International High School For Health Sciences',
  'International High School Of New Orleans',
  'International Higher School for Advanced Studies Trieste',
  'International Hotel Management Institute',
  'International Institute for Geo - Information Science and Earth Observation',
  'International Institute for Higher Education',
  'International Institute for Population Sciences',
  'International Institute of Information Technology Bangalore',
  'International Institute of Information Technology Bhubaneswar',
  'International Institute of Information Technology Pune',
  'International Islamic College',
  'International Islamic College of Penang',
  'International Islamic University',
  'International Islamic University Chittagong',
  'International Kazakh-Turkish University',
  'International Leadership Charter School',
  'International Leadership Of Texas (ilt)',
  'International Leadership Of Texas - Arlington El',
  'International Leadership Of Texas - Garland H S',
  'International Leadership Of Texas - Garland Middle',
  'International Leadership Of Texas El - Katy',
  'International Leadership Of Texas El - Keller',
  'International Leadership Of Texas El - Westpark',
  'International Leadership Of Texas Es-grand Prairie',
  'International Leadership Of Texas H S - Arlington',
  'International Leadership Of Texas Middle - Katy',
  'International Leadership Of Texas Middle - Keller',
  'International Leadership Of Texas Ms-westpark',
  'International Leadership Of Texas-arlington Middle',
  'International Leadership Of Tx-keller-saginaw H S',
  'International Magnet School For Global Citizenship',
  'International Medical & Technological University',
  'International Medical University',
  'International Newcomer Institute',
  "International People's College",
  'International Polytechnic High',
  'International Prep Academy',
  'International Preparatory School (the)',
  'International Sakharov Environmental University',
  'International School',
  'International School At Dundee',
  'International School At Mesa Del Sol',
  'International School For Liberal Arts',
  'International School Of America',
  'International School Of Beaverton',
  'International School Of Broward',
  'International School Of Louisiana',
  'International School Of Louisiana Jefferson Parish',
  'International School Of Monterey',
  'International School of Business Management',
  'International School of Management',
  'International School of Management ISM Dortmund',
  'International School of Management in Prešov',
  'International School of New Media University of Lübeck',
  'International Spanish Language Acad',
  'International Student Center',
  'International Studies Charter High School',
  'International Studies Charter Middle School',
  'International Studies Elementary Charter School',
  'International Studies Lrng Center At Legacy High Sch Complex',
  'International Studies Magnet',
  'International Studies Preparatory Academy',
  'International Studies Virtual',
  'International Theravada Buddhist Missionary University',
  'International Tourism Institute',
  'International Transition Center',
  'International Turkmen Turkish University',
  'International University',
  'International University College',
  'International University College of Nursing (IUCN)',
  'International University College of Technology Twintech (IUCTT)',
  'International University Institute of Luxembourg',
  'International University School of Medicine (IUSOM)',
  'International University for Graduate Studies',
  'International University for Science & Technology',
  'International University in Geneva',
  'International University in Germany',
  'International University of Africa (IUA)',
  'International University of Business Agriculture and Technology',
  'International University of Engineering Moscow',
  'International University of Fundamental Studies St. Petersburg',
  'International University of Health and Welfare',
  'International University of Japan',
  'International University of Kagoshima',
  'International University of Kyrgyzstan',
  'International University of Management',
  'International University of Novi Pazar',
  'International University of Sarajevo',
  'International University of Southern Europe',
  'International University of Travnik',
  'Internationale Fachhochschule Aalen',
  'Internationale Fachhochschule Bad Honnef',
  'Internationales Hochschulinstitut Zittau',
  'Internationle Akademie für Philosophie',
  'Internet Academy',
  'Internet Pasco Academy Of Learning',
  'Intersect',
  'Interstate 35 Elementary School',
  'Interstate 35 Secondary School',
  'Intervale Elementary School',
  'Intervention Learning Program',
  'Intl Sch Of Communications',
  'Intrepid College Preparatory Charter School',
  'Intrinsic Charter High School',
  'Inver Grove Heights Middle',
  'Inver Hills Community College',
  'Inverness Academy',
  'Inverness Elementary',
  'Inverness Elementary School',
  'Inverness Middle School',
  'Inverness Primary School',
  'Inverness School',
  'Invest Collegiate - Imagine',
  'Invest Collegiate Transform',
  'Invest High School',
  'Invest Roosevelt Alternative High School',
  'Invest Transition',
  'Invictus High School',
  'Invictus Preparatory Charter School',
  'Inwood Academy For Leadership Charter School',
  'Inwood Early College For Health And Information Technologies',
  'Inwood Elementary School',
  'Inwood Primary School',
  'Inyo County Special Education Preschool',
  'Inyokern Elementary',
  'Inza R Wood Middle School',
  'Inönü University',
  'Iola El',
  'Iola H S',
  'Iola Middle School',
  'Iola Roberts Elementary School',
  'Iola Sr High',
  'Iola-scandinavia Elementary',
  'Iola-scandinavia High',
  'Iona College',
  'Iona Elementary School',
  'Ione Community Charter School',
  'Ione Elementary',
  'Ione Junior High',
  'Ione Olson Elementary',
  'Ionia High School',
  'Ionia Maximum Correctional Facility',
  'Ionia Middle School',
  'Ionian University Corfu',
  'Iosco Resa Special Education',
  'Iota Elementary School',
  'Iota High School',
  'Iota Middle School',
  'Iotla Valley Elementary',
  'Iowa Central Community College',
  'Iowa City High School',
  'Iowa Connections Academy Elementary',
  'Iowa Connections Academy High School',
  'Iowa Connections Academy Middle School',
  'Iowa Elementary',
  'Iowa Elementary School',
  'Iowa Falls - Alden High School',
  'Iowa High School',
  'Iowa Lakes Community College',
  'Iowa Park H S',
  'Iowa Park Jjaep',
  'Iowa State University',
  'Iowa Valley Elementary School',
  'Iowa Valley Jr-sr High School',
  'Iowa Virtual Academy',
  'Iowa Western Community College',
  'Iowa-grant Elementary/middle',
  'Iowa-grant High',
  'Iowa-maple Elementary School',
  'Ipakanni Early College Charter',
  'Ipp At Honeyspot School',
  'Ipp Classroom - Johnson',
  'Ippolito Elementary School',
  'Iprep Academy School No 8',
  'Ips/ Butler University Lab Sch 60',
  'Ipswich Elementary - 02',
  'Ipswich High',
  'Ipswich High School - 01',
  'Ipswich Middle School',
  'Ipswich Middle School - 04',
  'Iq Academy California-los Angeles',
  'Iq Academy Minnesota',
  'Iq Academy Washington',
  'Iqra University',
  'Ira A Murphy',
  'Ira A. Fulton Elementary',
  'Ira B Jones Elementary',
  'Ira Cross Jr El',
  'Ira F Simmons Middle School',
  'Ira Harbison',
  'Ira J Earl Elementary School',
  'Ira School',
  'Iraan El',
  'Iraan H S',
  'Iraan J H',
  'Iran University of Medical Sciences',
  'Iran University of Science and Technology Tehran',
  'Iranian Academy of Persian Language and Literature',
  'Iraq University College',
  'Irasburg Village School',
  'Irbid National University',
  'Iredell Charter Academy',
  'Iredell School',
  'Ireland Drive Middle',
  'Ireland El',
  'Ireland Elementary School',
  'Irene - Wakonda Elementary - 02',
  'Irene - Wakonda High School - 01',
  'Irene - Wakonda Jr. High - 04',
  'Irene B. West Elementary',
  'Irene C Cardwell El',
  'Irene Clinkscale El',
  'Irene E. Feldkirchner Elementary School',
  'Irene Erickson Elementary School',
  'Irene King Elem School',
  'Irene Lopez School',
  'Irene M Garcia Middle',
  'Irene Reither Elementary School',
  'Ireton Elementary School',
  'Irion El',
  'Irion H S',
  'Iris Becker Elementary School',
  'Irish Elementary School',
  'Irish International University (Distance Education)',
  'Irish University Business School Cebu',
  'Irkutsk State Academy of Economics',
  'Irkutsk State Technical University',
  'Irkutsk State University',
  'Irle Elementary School',
  'Irma C. Austin Middle School',
  'Irma Deliz De Munoz',
  'Irma Dru Hutchison El',
  "Irma Lerma Rangel Young Women's Leadership School",
  'Irma Rangel Young Womens Leadership Middle',
  'Irmo Elementary',
  'Irmo High',
  'Irmo Middle',
  'Iron County Preschool',
  'Iron Forge El Sch',
  'Iron Forge Elem Sch',
  'Iron Horse Elementary School',
  'Iron Horse Middle',
  'Iron House Elementary',
  'Iron Mountain High School',
  'Iron River Elementary',
  'Iron Springs School',
  'Iron Station Elementary',
  'Irondale Community School',
  'Irondale Middle School',
  'Irondale Senior High',
  'Irondequoit High School',
  'Ironia School',
  'Irons J H',
  'Irons Middle',
  'Ironton Elementary School',
  'Ironton High School',
  'Ironton Middle School',
  'Ironton Sch',
  'Ironton-la Valle Elementary',
  'Ironwood Elementary School',
  'Ironwood High School',
  'Ironwood Ridge High School',
  'Ironwood School',
  'Iroquois Community School',
  'Iroquois El Sch',
  'Iroquois Elementary - 04',
  'Iroquois High',
  'Iroquois High School - 01',
  'Iroquois Intermediate School',
  'Iroquois Jshs',
  'Iroquois Middle School',
  'Iroquois Middle School - 02',
  'Iroquois Point Elementary School',
  'Iroquois Senior High School',
  'Iroquois West Elem Sch/danforth',
  'Iroquois West Elem Sch/thawville',
  'Iroquois West Elem School/gilman',
  'Iroquois West High School',
  'Iroquois West Middle School',
  'Irrigon Elementary School',
  'Irrigon Junior/senior High School',
  'Irs Program At Moriarty',
  'Irvin El',
  'Irvin H S',
  'Irvine Adult Transition Programs',
  'Irvine High',
  'Irvine Valley College',
  'Irving & Beatrice Peskoe K-8 Center',
  'Irving A. Robbins Middle School',
  'Irving College Elementary',
  'Irving El',
  'Irving El Sch',
  'Irving Elem',
  'Irving Elem School',
  'Irving Elementary',
  'Irving Elementary School',
  'Irving Es',
  'Irving H S',
  'Irving L. Branch Elementary',
  'Irving Middle',
  'Irving Middle School',
  'Irving Ms',
  'Irving Park Elementary',
  'Irving Preschool',
  'Irving Primary School',
  'Irving School',
  'Irvington Community School',
  'Irvington Elem School',
  'Irvington Elementary School',
  'Irvington High',
  'Irvington High School',
  'Irvington Middle School',
  'Irwin & Susan Molasky Junior High School',
  'Irwin Academic Center',
  'Irwin Academy Of Performing Arts',
  'Irwin Altman Middle School 172',
  'Irwin County Elementary School',
  'Irwin County High School',
  'Irwin County Middle School',
  'Irwin Elementary School',
  'Irwin High (continuation)',
  'Irwin M. Jacobs Elementary School',
  'Irwin O. Addicott Elementary',
  'Irwin Preschool',
  'Is 10 Horace Greeley',
  'Is 117 Joseph H Wade',
  'Is 119 Glendale (the)',
  'Is 125 Thomas J Mccann Woodside',
  'Is 136 Charles O Dewey',
  'Is 141 Steinway (the)',
  'Is 145 Joseph Pulitzer',
  'Is 171 Abraham Lincoln',
  'Is 181 Pablo Casals',
  'Is 187 Christa Mcauliffe School (the)',
  'Is 192 Linden (the)',
  'Is 2 George L Egbert',
  'Is 204 Oliver W Holmes',
  'Is 206 Ann Mersereau',
  'Is 211 John Wilson',
  'Is 218 Salome Urena',
  'Is 219 New Venture School',
  'Is 227 Louis Armstrong',
  'Is 228 David A Boody',
  'Is 229 Roland Patterson',
  'Is 230',
  'Is 232',
  'Is 237',
  'Is 238 Susan B Anthony Academy',
  'Is 24 Myra S Barnes',
  'Is 25 Adrien Block',
  'Is 250 Robert F Kennedy Community Middle School (the)',
  'Is 254',
  'Is 27 Anning S Prall',
  'Is 281 Joseph B Cavallaro',
  'Is 285 Meyer Levin',
  'Is 289',
  'Is 303 Herbert S Eisenberg',
  'Is 303 Leadership & Community Service',
  'Is 313 School Of Leadership Development',
  'Is 318 Eugeno Maria De Hostos',
  'Is 318 Math Science & Tech Through Arts School',
  'Is 338',
  'Is 339',
  'Is 34 Tottenville',
  'Is 340',
  'Is 347 School Of Humanities',
  'Is 349 Math Science And Technology',
  'Is 364 Gateway',
  'Is 381',
  'Is 392',
  'Is 49 Berta A Dreyfus',
  'Is 5 Walter Crowley Intermediate School (the)',
  'Is 51 Edwin Markham',
  'Is 528 Bea Fuller Rodgers School',
  'Is 59 Springfield Gardens',
  'Is 61 Leonardo Da Vinci',
  'Is 61 William A Morris',
  'Is 68 Isaac Bildersee',
  'Is 7 Elias Bernstein',
  'Is 72 Rocco Laurie',
  'Is 73 Frank Sansivieri Intermediate School (the)',
  'Is 75 Frank D Paulo',
  'Is 77',
  'Is 93 Ridgewood',
  'Is 96 Seth Low',
  'Is 98 Bay Academy',
  'Is The Alternative Program (tap)',
  'Isaac Del Rosario',
  'Isaac Dickson Elementary',
  'Isaac E Imes School',
  'Isaac E Young Middle School',
  'Isaac Fox Elementary School',
  'Isaac J. Gourdine Middle',
  'Isaac L. Sowers Middle',
  'Isaac Lane Technology Magnet Elementary',
  'Isaac Litton Middle',
  'Isaac M Bear Early College High School',
  'Isaac Middle School',
  'Isaac Newton Graham Middle',
  'Isaac Newton Ms For Math And Science',
  'Isaac Singleton Elem Sch',
  'Isaac Tripp El Sch',
  'Isaac: Integrated Science And Asian Culture Academy',
  'Isaacs Early Childhood School',
  'Isaacs El',
  'Isabel Alvarado Alvarado',
  'Isabel Flores',
  'Isabel Suarez',
  'Isabela State University',
  'Isabella Bird Community School',
  'Isabella Day Treatment',
  'Isabella High School',
  'Isabelle Jackson Elementary',
  'Isable Elementary School',
  'Isador Cohen Elementary',
  'Isaiah Haines',
  'Isanti Intermediate School',
  'Isanti Middle School',
  'Isanti Primary School',
  'Isbell El',
  'Isbell Middle',
  'Isbill J H',
  'Isbister Elementary School',
  'Ischool High - Lewisville',
  'Ischool High At The Woodlands',
  'Ischool High At University Park',
  'Ischool High Of Hickory Creek',
  'Ischool2020',
  'Isd 181 Learning Center',
  'Isd 281 I.s.',
  'Isd 361 - Alc Program',
  'Isd 728 Focus/journey',
  'Isd Early Childhood Services',
  'Isdlocal Center Programs',
  'Isdlocal Programs',
  'Iseap Program',
  'Iselin Middle School',
  'Isely Traditional Magnet Elem',
  'Isenberg Elementary',
  'Isfahan University',
  'Isfahan University of Medical Sciences',
  'Isfahan University of Technology',
  'Isham Memorial Elementary School',
  'Ishi Hills Middle',
  'Ishik University',
  'Ishikawa Elementary School',
  'Ishinomaki Senshu University',
  'Ishpeming High School',
  'Ishpeming Middle School',
  'Ishpemingnegauneenice Comm Ed Division',
  'Isik University',
  'Isla Vista Elementary',
  'Islamia University of Bahawalpur',
  'Islamic Azad University Ahar',
  'Islamic Azad University Aliabad',
  'Islamic Azad University Arak',
  'Islamic Azad University Ardabil',
  'Islamic Azad University Bandar Abbas',
  'Islamic Azad University Bojnourd',
  'Islamic Azad University Bostanabad',
  'Islamic Azad University Boukan',
  'Islamic Azad University Central Tehran Branch',
  'Islamic Azad University Dehaghan',
  'Islamic Azad University Falavarjan',
  'Islamic Azad University Fars Science & Research Branch',
  'Islamic Azad University Garmsar',
  'Islamic Azad University Gorgan',
  'Islamic Azad University Hamedan',
  'Islamic Azad University Janah',
  'Islamic Azad University Karaj',
  'Islamic Azad University Kazeron',
  'Islamic Azad University Kerman',
  'Islamic Azad University Khorasgan',
  'Islamic Azad University Lahijan',
  'Islamic Azad University Mahshar',
  'Islamic Azad University Majlesi',
  'Islamic Azad University Mashhad',
  'Islamic Azad University Maybod',
  'Islamic Azad University Mehriz',
  'Islamic Azad University Mobarakeh',
  'Islamic Azad University Najaf Abad',
  'Islamic Azad University North Tehran',
  'Islamic Azad University Parand',
  'Islamic Azad University Qazvin',
  'Islamic Azad University Qom',
  'Islamic Azad University Quchan',
  'Islamic Azad University Region One',
  'Islamic Azad University Roodehen',
  'Islamic Azad University Sanandaj',
  'Islamic Azad University Saveh',
  'Islamic Azad University Semnan',
  'Islamic Azad University Shabestar',
  'Islamic Azad University Shahr-e-rey Branch',
  'Islamic Azad University Shahrood',
  'Islamic Azad University Shahryar',
  'Islamic Azad University Shiraz',
  'Islamic Azad University Shirvan',
  'Islamic Azad University Sowmesara',
  'Islamic Azad University Tabriz',
  'Islamic Azad University Tafresh',
  'Islamic Azad University Tehran Medical',
  'Islamic Azad University Tehran Science & Research Branch',
  'Islamic Azad University Tehran South',
  'Islamic Azad University Varamin',
  'Islamic Azad University Yazd',
  'Islamic College for Sciences and Technologies',
  'Islamic University College',
  'Islamic University Kushtia',
  'Islamic University in Uganda',
  'Islamic University of Gaza',
  'Islamic University of Medinah',
  'Islamic University of Rotterdam',
  'Islamic University of Technology',
  'Island Avenue Elementary School',
  'Island City Academy',
  'Island City Elementary School',
  'Island City Virtual Academy',
  'Island Coast High School',
  'Island Community Day',
  'Island Creek Elementary',
  'Island Elementary',
  'Island Heights Elementary School',
  'Island High (continuation)',
  'Island Juvenile Detention Education Program',
  'Island Lake Elementary',
  'Island Montessori Charter School',
  'Island Park Elementary',
  'Island Park High School',
  'Island Park Lincoln Orens Middle School',
  'Island Trees High School',
  'Island Trees Middle School',
  'Island View Elementary',
  'Island Village Montessori School',
  'Islander Middle School',
  'Islands Academy',
  'Islands Elementary School',
  'Islands High School',
  'Isle Area Learning Center',
  'Isle Au Haut Rural School',
  'Isle Elementary',
  'Isle La Motte Elementary School',
  'Isle Of Hope School',
  'Isle Secondary',
  'Isles Community Car',
  'Islesboro Central School',
  'Isleta Elementary School',
  'Isleton Elementary',
  'Islip High School',
  'Islip Middle School',
  'Ismael Maldonado Lugaro',
  'Isom Elementary School',
  'Ison Springs Elementary School',
  'Isonville Elementary School',
  'Isothermal Community College',
  'Isra University',
  'Israel Loring School',
  'Israel Putnam School',
  'Issac Monfort Elementary School',
  'Issaquah Head Start',
  'Issaquah High School',
  'Issaquah Middle School',
  'Issaquah Special Services',
  'Issaquah Valley Elementary',
  'Istanbul Arel University',
  'Istanbul Aydin University',
  'Istanbul Bilgi University',
  'Istanbul Esenyurt University',
  'Istanbul Gelisim University',
  'Istanbul Kemerburgaz  University',
  'Istanbul Kultur University',
  'Istanbul Medeniyet University',
  'Istanbul Medipol University',
  'Istanbul Rumeli University',
  'Istanbul Sabahattin Zaim University',
  'Istanbul Technical University',
  'Istanbul Ticaret University',
  'Istem Geauga Early College High School',
  'Isthmus Montessori Academy Public',
  'Istituto Universitario di Studi Superiori',
  'Istrouma High School',
  'Isucceed Virtual High School',
  'It Takes A Village Academy',
  'Italy H S',
  'Itasca Community College',
  'Itasca El',
  'Itasca H S',
  'Itasca Middle',
  'Itaskin Education Center',
  'Itawamba Agricultural High School',
  'Itawamba Attendance Center',
  'Itawamba Career & Technical Center',
  'Itawamba Community College',
  'Itawamba Improvement Center',
  'Itech@thomas A Edison Educational Center',
  'Ithaca College',
  'Ithaca Elementary',
  'Ithaca High',
  'Ithaca High School',
  'Ithaca Middle',
  'Ithaca Senior High School',
  'Ithan El Sch',
  'Ithica Elementary',
  'Itinerant Sp Ed',
  'Itineris Early College High',
  'Itliong-vera Cruz',
  'Ittihad Private University',
  'Ittihad University',
  'Iuka Comm Cons School',
  'Iuka Elementary School',
  'Iuka Middle School',
  'Iuniversity Prep',
  'Iva Elementary',
  'Ivalee Elementary School',
  'Ivan G Smith',
  'Ivan K Garrison Center',
  'Ivan L Green Primary School',
  'Ivan Sand After School Credit Recov',
  'Ivan Sand Community High School',
  'Ivan Sand Community School Summer',
  'Ivan Sand Community School- Is',
  'Ivan Sand Community School-middle',
  'Ivan Sand Transition Program',
  'Ivanhoe Elementary',
  'Ivanna Eudora Kean High School',
  'Ivano-Frankivsk National University of Oli and Gas',
  'Ivanovo State Academy of Medicine',
  'Ivanovo State Power University',
  'Ivanovo State Textile Academy',
  'Ivanovo State University',
  'Ivanovo State University of Chemistry and Technology',
  'Ivc Learning Center',
  'Iveland Elem.',
  'Iver C. Ranum Middle School',
  'Ivey Lane Elementary',
  'Ivey Ranch Elementary',
  'Ivory Primary School',
  'Ivy Academia',
  'Ivy Academy Inc.',
  'Ivy Bound Acad Math Sci And Tech Charter Mid 2',
  'Ivy Bound Acad Of Math Sci And Tech Charter Mid',
  'Ivy Creek Elementary School',
  'Ivy Drive Goal',
  'Ivy Drive Medically Fragile',
  'Ivy Drive School',
  'Ivy Hall Elementary School',
  'Ivy Hawn Charter School Of The Arts',
  'Ivy High (continuation)',
  'Ivy Hill Elem School',
  'Ivy Hill Elementary School',
  'Ivy Preparatory Academy At Gwinnett',
  'Ivy Preparatory Academy- Inc',
  'Ivy Stockwell Elementary School',
  'Ivy Tech Community College',
  'Ivytech Charter',
  'Iwaki Meisei University',
  'Iwate Medical University',
  'Iwate Prefectural University',
  'Iwate University',
  'Ixonia Elementary',
  'Iyc Chicago',
  'Iyc Kewanee',
  'Izaak Walton Intermediate',
  'Izard Center For Learning',
  'Izard Co. Cons. Elem. School',
  'Izard Co. Cons. High School',
  'Izard County Cons Middle Sch',
  'Izhevsk State Technical University',
  'Izmir Economic University',
  'Izmir Economy University',
  'Izmir Institute of Technology',
  'J & R Phalen Leadership Academy',
  'J A Hargrave El',
  'J A Vitovsky El',
  'J B Conant High School',
  'J B Kennedy Elem School',
  'J B Nelson Elem School',
  'J B Page Primary School',
  'J B Stephens El',
  'J B Sutton Elementary School',
  'J B Wilmeth El',
  'J BAR J LEARNING CENTERS',
  'J BAR J LEARNING CTRS SISTERS ACADEMY CAMPUS',
  'J C Fremont Elementary School',
  'J C French El',
  'J C Harmon High',
  'J C Hoglan Elementary School',
  'J C Martin Jr El',
  'J C Mckenna Middle',
  'J C Sawyer Elementary',
  'J C Solmonese',
  'J C Thompson El',
  'J Clyde Hopkins Elementary School',
  'J Covington Elem School',
  'J D Clement Early College Hs',
  'J D Colt Elem School',
  'J D George Elementary School',
  'J D Smith Middle School',
  'J E COSGRIFF MEMORIAL CATHOLIC SCHOOL',
  'J E Hobbs Elementary School',
  'J E Holmes Middle',
  'J E Johnson Elementary',
  'J E Lanigan School',
  'J E Ober Elementary School',
  'J E Turner Elementary',
  'J E Woodard Elementary',
  'J E Woody Elementary',
  'J Emmett Hinchcliffe Sr Elem Sch',
  'J Everett Light Career Center',
  'J F Burns Elementary School',
  'J F Drake State Community and Technical College',
  'J F Kennedy El Sch',
  'J F Kennedy Elementary',
  'J F Kennedy Elementary School',
  'J F Kennedy High',
  'J F Kennedy Memorial',
  'J F Kennedy Middle School',
  'J F Shields High School',
  'J FRANK WHITE ACADEMY',
  'J Franklyn Keller Intermediate School',
  'J Fred Sparke School',
  'J G B EDUCATION SERVICES',
  'J G Johnson Elementary School',
  'J Giles Elem School',
  'J Glenn Edwards Elementary',
  'J H Brooks El Sch',
  'J H Gunn Elementary',
  'J H Hines El',
  'J Harding Armstrong',
  'J Harold Brinley Middle School',
  'J Harold Van Zant',
  'J Henry Higgins Middle',
  'J Herman Reed Middle',
  'J J A E P',
  'J J Wessendorff Middle',
  'J K Hileman El',
  'J Kawas El',
  'J L Boren El',
  'J L Buford Intermediate Ed Ctr',
  'J L Everhart Magnet El',
  'J L Hensey Elem School',
  'J L Long Middle',
  'J L Lyon El',
  'J L Williams/lovett Ledger El',
  'J Larry Newton School',
  'J Leigh Elem School',
  'J Lyndal Hughes El',
  'J M Alexander Middle',
  'J M Hanks H S',
  'J M Hannum Elem School',
  'J M Hill El Sch',
  'J M Mckenney Middle School',
  'J M Ullom Elementary School',
  'J M Weatherwax High School',
  'J Martin Jacquet Middle',
  'J Michael Conley Elementary School At Southwood',
  'J N Ervin El',
  'J N Fries Magnet School',
  'J Nelson Kelly Elementary School',
  'J O Wilson Es',
  'J P Lord Program',
  'J P Starks El',
  'J Paul Taylor Academy',
  'J R Fugett Ms',
  'J R Ingram Jr Elementary',
  'J Robert Hendricks Elementary School',
  'J Russell Elementary School',
  'J S Waters School',
  'J Sam Gentry Middle',
  'J Sargeant Reynolds Community College',
  'J Spencer Smith School',
  'J Sterling Morton Alternative Sch',
  'J Sterling Morton East High Sch',
  'J Sterling Morton Elem School',
  'J Sterling Morton Freshman Cntr',
  'J Sterling Morton West High Sch',
  'J T Barber Elementary',
  'J T Brackenridge El',
  'J T Karaffa Elementary School',
  'J T Lambert Intermediate Sch',
  'J T Manning Elem School',
  'J T Mcwilliams Elementary School',
  'J T Stevens El',
  'J T Williams Secondary Montessori',
  'J Taylor Finley Middle School',
  'J Turner Hood',
  'J U Blacksher School',
  'J V Forrestal Elementary School',
  'J W Burkett El Sch',
  'J W Caceres El',
  'J W Coon Elementary',
  'J W Eater Jr High School',
  'J W Holloway Sixth Grade School',
  'J W Long El',
  'J W Mclauchlin Elementary',
  'J W Parker Middle',
  'J W Poynter Middle School',
  'J W Ray Learning Center',
  'J W Reason Elementary School',
  'J W Riley Elem School',
  'J W Riley Intermediate School',
  'J W Robinson Jr Elem School',
  'J W Seabrook Elementary',
  'J W Turlington School',
  'J W Williams Middle',
  'J W Wiseman Elementary',
  'J Warren Killam',
  'J Watson Bailey Middle School',
  'J Wilbur Haley Elementary School',
  'J William Fulbright Junior High School',
  'J William Leary Junior High School',
  'J Z George High School',
  'J. A. Dobbins Middle School',
  'J. A. Phillips Middle School',
  'J. Ackerman Coles Elementary School',
  'J. Allen Axson Elementary School',
  'J. Blaine Blayton Elementary',
  'J. Brown Elementary',
  'J. C. Crumpton Elementary',
  'J. C. Lynch Elementary',
  'J. C. Mitchell Elementary School',
  'J. C. Montgomery',
  'J. C. Parks Elementary School',
  'J. C. Roe Center',
  'J. C. Stuart Elementary School',
  'J. Christian Bollwage Finance Academy',
  'J. Colin English Elementary School',
  'J. D. Dickerson Primary School',
  'J. D. Lever Elementary',
  'J. D. Parker School Of Technology',
  'J. E. B. Stuart Middle School',
  'J. E. Harper Elementary School',
  'J. E. Hester Elementary',
  'J. E. Manch Elementary School',
  'J. E. Moss Elementary',
  'J. E. Van Wig Elementary',
  'J. E. Young Academic Center',
  'J. Everett Barr Court',
  'J. F. Kennedy Elementary School',
  'J. F. Kennedy Es',
  'J. F. Kennedy School',
  'J. F. Webb High',
  'J. Fithian Tatem School',
  'J. Frank Dent Elementary',
  'J. Frank Hillyard Middle',
  'J. Garfield Jackson Sr. Academy',
  'J. Graham Brown School',
  'J. H. Hull Middle',
  'J. H. Mcgaugh Elementary',
  'J. H. Workman Middle School',
  'J. Haley Durham Elementary',
  'J. Harley Bonds Career Center',
  'J. Harvey Rodgers School',
  'J. I. Watson Elementary School',
  'J. J. Finley Elementary School',
  'J. J. Flynn School',
  'J. J. Izard Elementary School',
  'J. K. Gourdin Elementary',
  'J. K. Haynes Charter Inc.',
  'J. Knox Elementary',
  'J. L. Jones Elementary School',
  'J. L. Lomax Elementary School',
  'J. L. Mann High Academy',
  'J. Lupton Simpson Middle',
  'J. M. Amador Elementary',
  'J. M. Tate Senior High School',
  'J. M. Tawes Vocational Center',
  'J. M. Wright Technical High School',
  'J. Marion Roynon Elementary',
  'J. Mason Tomlin Elementary School',
  'J. Michael Lunsford Middle',
  'J. Milton Jeffrey Elementary School',
  'J. O. Combs Middle School',
  'J. O. Kelly Middle School',
  'J. P. Freeman Elementary/middle',
  'J. P. Ryon Elementary School',
  'J. P. Taravella High School',
  'J. P. Thomas Elementary',
  'J. Paul Truluck Intermediate',
  'J. R. Baker Elementary',
  'J. R. Trippe Middle School',
  'J. Ralph Mcilvaine Early Childhood Center',
  'J. S. Clark Elementary School',
  'J. T. Reddick School',
  'J. W. Oakley Elementary',
  'J. W. Sam Es',
  'J. Wallace James Elementary School',
  'J. X. Wilson Elementary',
  'J.O.Y. ACADEMY OF SOUTHERN NEVADA',
  'J.a. Allard El Sch',
  'J.a. Chalkley Elementary',
  'J.a. Fair High School',
  'J.a. Hughes Elementary',
  'J.b. Fisher Elementary',
  'J.b. Martin Middle School',
  'J.b. Nachman Elementary School',
  'J.b. Watkins Elementary',
  'J.c. Ellis Elementary School',
  'J.d. Leftwich High School',
  'J.d. Meisler Middle School',
  'J.e.b. Stuart Elementary',
  'J.e.j. Moore Middle',
  'J.f. Gauthier School',
  'J.f. Kennedy School',
  'J.f. Wahl Elementary School',
  'J.g. Hening Elementary',
  'J.h. Williams Middle School',
  'J.i. Barron Sr. Elementary School',
  'J.i. Burton High',
  'J.j. Audubon Elementary School',
  'J.l. Francis Elementary',
  'J.l. Wilkinson Elementary School',
  'J.m. Bevins Elementary',
  'J.m. Dozier Middle',
  'J.p. Case Middle School',
  'J.p. Torres Success Academy',
  'J.q. San Miguel Elementary School',
  'J.r. Arnold High School',
  'J.r. Smith School',
  'J.s. Aucoin Elementary School',
  'J.s. Clark Magnet Elementary School',
  'J.v. Washam Elementary',
  'J.v.b. Wicoff Elementary School',
  'J.w. Adams Combined',
  'J.w. Alvey Elementary',
  'J.w. Faulk Elementary School',
  'J.w. Smith Elementary',
  "JACK AND JILL CHILDREN'S CENTER",
  'JACK M BARRACK HEBREW ACADEMY',
  'JACKLYN ACADEMY',
  'JACKS MOUNTAIN SCHOOL',
  'JACKSON ACADEMY',
  'JACKSON CHRISTIAN ELEMENTARY SCHOOL',
  'JACKSON CHRISTIAN SCHOOL',
  'JACKSON HEIGHTS CHRISTIAN ACADEMY',
  'JACKSON HEIGHTS SCHOOL SDA',
  'JACKSON HOLE CHRISTIAN ACADEMY',
  'JACKSON HOLE COMMUNITY SCHOOL',
  'JACKSON PREPARATORY SCHOOL',
  'JACKSON SCHOOL',
  'JACKSON TRAIL CHRISTIAN SCHOOL',
  'JACKSON-FEILD BHS-GWALTNEY SCHOOL',
  'JACKSONVILLE ADVENTIST ACADEMY',
  'JACKSONVILLE ASSEMBLY CHRISTIAN ACADEMY',
  'JACKSONVILLE CHRISTIAN ACADEMY',
  'JACKSONVILLE COUNTRY DAY SCHOOL',
  'JACKSONVILLE PAROCHIAL SCHOOL',
  'JACKSONVILLE SCHOOL FOR AUTISM',
  'JACOB SAPIRSTEIN CAMPUS - HEBREW ACAD OF CLEVELAND',
  'JAMAICA SDA ELEMENTARY/BUSY BEE LEARNING CENTER',
  "JAMAS CHILDREN'S UNIVERSITY",
  'JAMES CARAWAY CHRISTIAN ACADEMY',
  'JAMES E DAVIS SCHOOL',
  'JAMES F FARR ACADEMY',
  'JAMES L COLLINS CATHOLIC SCHOOL',
  'JAMES RIVER DAY SCHOOL',
  'JAMESON CHRISTIAN ACADEMY',
  'JAMESPORT MENNONITE SCHOOL',
  'JAMON MONTESSORI DAY SCHOOL',
  'JAN PETERSON CHILD DAY CARE CENTER',
  'JAPANESE EDUCATIONAL INSTITUTE',
  'JAPANESE LANGUAGE ADVANCEMENT',
  'JAPHET SCHOOL',
  'JARDINE ACADEMY',
  'JARVISBURG CHRISTIAN ACADEMY',
  'JASPER ADVENTIST CHRISTIAN SCHOOL',
  'JASPER MOUNTAIN SCHOOL',
  'JAY COUNTY CHRISTIAN ACADEMY',
  'JAY CREEK SCHOOL',
  'JCA - MICHELE BLOCK GAN YELADIM PRESCHOOL & KDGN',
  'JCC OF CENTRAL NEW JERSEY',
  'JDT CHRISTIAN ACADEMY',
  'JEAN GARVIN SCHOOL',
  "JEAN LYLE'S CHILDRENS CENTER",
  'JEAN PIAGET ACADEMY',
  'JEFFERSON ACADEMY',
  'JEFFERSON CHRISTIAN ACADEMY',
  'JEFFERSON COMMUNITY SCHOOL',
  'JEFFERSON DAVIS ACADEMY',
  'JEFFERSON PAROCHIAL AMISH SCHOOL',
  'JEFFERSONVILLE COUNTRY DAY SCHOOL',
  'JEHOVAH-JIREH CHRISTIAN ACADEMY',
  'JEMICY SCHOOL',
  'JENISON CHRISTIAN SCHOOL',
  'JENNINGS CK AMISH SCHOOL',
  'JENNINGS FIRST CHRISTIAN SCHOOL',
  'JENNY HART EARLY EDUCATION CENTER',
  'JENNY LYNN ELEMENTARY',
  'JEREMIAH ACADEMY',
  'JERICHO',
  'JERSEY CHRISTIAN SCHOOL',
  'JERSEY SHORE BAPTIST ACADEMY',
  'JERSEY SHORE FREE SCHOOL',
  'JES MEMORIAL SDA SCHOOL',
  'JESSE REMINGTON HIGH SCHOOL',
  'JESSIE R WAGNER ADVENTIST ELEMENTARY',
  'JESUIT ACADEMY',
  'JESUIT COLLEGE PREP SCHOOL',
  'JESUIT HIGH SCHOOL',
  'JESUS CHAPEL SCHOOL',
  'JESUS HARVEST TIME ACADEMY',
  'JESUS IS LORD CHRISTIAN SCHOOL',
  'JESUS IS THE WAY CHRISTIAN ACADEMY',
  'JESUS ONLY ACADEMY',
  'JESUS OUR RESTORER CHRISTIAN SCHOOL',
  'JETS YESHIVAH',
  'JEWISH ACADEMY',
  'JEWISH ACADEMY OF ORLANDO',
  'JEWISH CENTER FOR SPECIAL EDUC',
  "JEWISH CHILDREN'S FOLKSHUL",
  'JEWISH COMMUNITY DAY SCHOOL',
  'JEWISH COMMUNITY DAY SCHOOL OF RHODE ISLAND',
  'JEWISH COMMUNITY HIGH SCHOOL OF THE BAY',
  'JEWISH COOPERATIVE SCHOOL',
  'JEWISH DAY SCHOOL OF THE LEHIGH VALLEY',
  'JEWISH FOUNDATION SCHOOL',
  'JEWISH HERITAGE DAY SCHOOL',
  'JEWISH HIGH SCHOOL OF CONNECTICUT',
  'JEWISH INSTITUTE OF QUEENS',
  'JEWISH PRIMARY DAY SCHOOL/NATIONS CAPITAL',
  'JFK-LOWER CAMPUS',
  'JIA CHRISTIAN ACADEMY',
  'JIA-RAY LEARNING CENTER INC.',
  'JIM ELLIOT CHRISTIAN HIGH SCHOOL',
  'JIN-A CHILD CARE CENTER',
  "JO'S LEARNING ACADEMY",
  'JOAN DACHS BAIS YAAKOV ELEMENTARY SCHOOL',
  'JOAN MACY SCHOOL',
  'JOHN A COLEMAN CATHOLIC HIGH SCHOOL',
  'JOHN A COLEMAN SCHOOL',
  'JOHN BAPST MEMORIAL HIGH SCHOOL',
  'JOHN BURROUGHS SCHOOL',
  'JOHN CARDINAL OCONNOR SCHOOL',
  'JOHN CARROLL CATHOLIC HIGH SCHOOL',
  'JOHN CARROLL HIGH SCHOOL',
  'JOHN CARROLL SCHOOL',
  'JOHN DEWEY ACADEMY',
  'JOHN F KENNEDY CATHOLIC HIGH SCHOOL',
  'JOHN F KENNEDY CATHOLIC SCHOOL',
  'JOHN F KENNEDY CATHOLIC SCHOOL UPPER CAMPUS',
  'JOHN G WOOD SCHOOL',
  'JOHN HANCOCK ACADEMY',
  'JOHN IRELAND SCHOOL',
  'JOHN KENNEY CHILD CARE CENTER HELLER PARK',
  'JOHN KNOX WEEKDAY KINDERGARTEN',
  'JOHN L COBLE ELEMENTARY SCHOOL',
  'JOHN PAUL II ACADEMY',
  'JOHN PAUL II CATHOLIC HIGH SCHOOL',
  'JOHN PAUL II CATHOLIC SCHOOL',
  "JOHN PAUL II CATHOLIC SCHOOL AT ST MATTHEW'S",
  'JOHN PAUL II CENTER FOR SPECIAL LEARNING',
  'JOHN PAUL II ELEMENTARY SCHOOL',
  'JOHN PAUL II HIGH SCHOOL',
  'JOHN PAUL II REGIONAL SCHOOL',
  'JOHN PAUL THE GREAT ACADEMY',
  'JOHN T MORGAN ACADEMY',
  'JOHN THOMAS DYE SCHOOL',
  'JOHNS CREEK MONTESSORI SCHOOL OF GEORGIA',
  'JOHNSON',
  'JOHNSON & JOHNSON CHILD DEVT CTR OF SOMERSET CNTY',
  'JOHNSON CHRISTIAN SCHOOL',
  'JOHNSON CORNERS CHRISTIAN ACADEMY',
  'JOHNSON COUNTY CHRISTIAN ACADEMY',
  'JOHNSON FERRY CHRISTIAN ACADEMY',
  "JOHNSON'S LEARNING CENTER",
  'JOHNSONS CORNER',
  'JOHNSONS MONTESSORI SCHOOL',
  'JOHNSTON INSTITUTE INC DBA MONROE COUNTRY DAY SCHO',
  'JOHNSTOWN CHRISTIAN SCHOOL',
  'JOLIET CATHOLIC ACADEMY',
  'JOLIET MONTESSORI SCHOOL',
  'JOLLY TODDLERS CHILD CARE CENTER & SCHOOL',
  'JOLLY TOTS TOO!',
  'JONAS & SUSIE EBERSOL',
  'JONATHAN EDWARDS CLASSICAL ACADEMY',
  'JORDAN BAPTIST SCHOOL',
  'JORDAN CATHOLIC SCHOOL',
  'JORDAN CHRISTIAN ACADEMY',
  'JORDAN GLEN SCHOOL',
  'JOSE MARTI SCHOOL #2',
  'JOSE MARTI SCHOOL 1',
  'JOSEPH ACADEMY',
  'JOSEPH ACADEMY-MELROSE PARK',
  'JOSEPH AND FLORENCE MANDEL JEWISH DAY SCHOOL',
  'JOSEPH KUSHNER HEBREW ACADEMY RAE KUSHNER YESHIVA',
  'JOSEPH S BRUNO MONTESSORI ACADEMY',
  'JOSEPHINE EDWARDS CHRISTIAN SCHOOL',
  'JOSEPHINUM ACADEMY OF THE SACRED HEART',
  'JOSHUA CENTER SHORELINE CDT',
  'JOSHUA CENTER THAMES VALLEY',
  'JOSHUA CHRISTIAN ACADEMY',
  'JOSHUA SPRINGS CHRISTIAN SCHOOL',
  'JOURNEY CHRISTIAN SCHOOL',
  'JOURNEY EDUCATION',
  'JOURNEY HIGH SCHOOL',
  'JOURNEY OF FAITH CHRISTIAN SCHOOL',
  'JOURNEY SCHOOL',
  'JOURNEYS SCHOOL OF TETON SCIENCE SCHOOLS',
  'JOWONIO SCHOOL',
  'JOY ACADEMY',
  'JOY EXPLOSION CHRISTIAN ACADEMY',
  'JOY KINDERGARTEN & PRESCHOOL',
  'JOY SCHOOL',
  'JOYCES TOLUCA LAKE PRESCHOOL & KINDERGARTEN',
  'JOYFUL BEGINNINGS ACADEMY',
  'JOYFUL KIDS ACADEMY',
  'JOYFUL NOISE CHRISTIAN SCHOOL',
  'JOYFUL SCHOLARS MONTESSORI ELEMENTARY SCHOOL',
  'JOYFUL SOUND CHRISTIAN ACADEMY',
  'JOYLAND PRESCHOOL',
  'JOYLAND PRESCHOOL/CHILDCARE',
  'JSERRA CATHOLIC HIGH SCHOOL',
  'JTC ACADEMY',
  'JUAN DIEGO ACADEMY',
  'JUAN DIEGO CATHOLIC HIGH SCHOOL',
  'JUANITA G HAWKINS ACADEMY',
  'JUBILEE ACADEMY',
  'JUBILEE CHRISTIAN ACADEMY',
  'JUBILEE CHRISTIAN PREPARATORY ACADEMY',
  'JUBILEE CHRISTIAN SCHOOL',
  'JUBILEE LEADERSHIP ACADEMY',
  'JUDAH CHRISTIAN SCHOOL',
  'JUDGE MEMORIAL CATHOLIC HIGH SCHOOL',
  'JUDSON INTERNATIONAL SCHOOL',
  'JULIA BROWN MONTESSORI SCHOOL',
  'JULIA MORGAN SCHOOL FOR GIRLS',
  'JULIE BILLIART SCHOOL',
  'JUNCTION CHRISTIAN ACADEMY',
  'JUNE BUCHANAN SCHOOL',
  'JUNEAU MONTESSORI SCHOOL',
  'JUNEAU SDA CHRISTIAN SCHOOL',
  'JUNIATA CHRISTIAN SCHOOL',
  'JUNIATA MENNONITE SCHOOL',
  'JUNIOR BLIND OF AMERICA',
  'JUNIPER HILL SCHOOL',
  'JUNIPERO SERRA HIGH SCHOOL',
  'JUNIPERO SERRA SCHOOL',
  'JUPITER CHRISTIAN SCHOOL',
  'JUST CHILDREN',
  'JUST KIDS CENTERS INC',
  'JUST KIDS LEARNING CENTER',
  'JUST LIKE MOMS PRESCHOOL & KINDERGARTEN',
  'JUST RIGHT ACADEMY',
  'JUST-4-KIDS PRESCHOOL',
  'JUSTIN-SIENA',
  "JW HALLAHAN CATHOLIC GIRLS' HIGH SCHOOL",
  'Ja Lanigan Elementary School',
  'Jabish Middle School',
  'Jac-cen-del Elementary School',
  'Jac-cen-del Ms/hs',
  'Jacinto City El',
  'Jacinto Lopez Martinez',
  'Jack & Sharon Rhoads El',
  'Jack & Terry Mannion Middle School',
  'Jack Abrams Stem Magnet School',
  'Jack Anderson Elementary',
  'Jack Barnes Elementary School',
  'Jack Benny Middle School',
  'Jack Britt High',
  'Jack C Binion El',
  'Jack C Hays H S',
  'Jack D Johnson El',
  'Jack Dailey Elementary School',
  'Jack Daley Primary School',
  'Jack David Gordon Elementary School',
  'Jack E Singley Academy',
  'Jack Egnaty Sr. School',
  'Jack Franscioni Elementary',
  'Jack G. Desmond Middle',
  'Jack Harmon Elementary School',
  'Jack Harvey Elementary School',
  'Jack Hayes Elementary School',
  'Jack Hille Middle School',
  'Jack Jackter Intermediate School',
  'Jack Jouett Middle',
  'Jack L Kuban Elementary School',
  'Jack L. Weaver Elementary',
  'Jack London Community Day',
  'Jack London Continuation',
  'Jack London Elementary',
  'Jack London Middle School',
  'Jack Lowe Sr El',
  'Jack Lummus Int',
  'Jack Lund Schofield Middle School',
  'Jack M Fields Sr El',
  'Jack Northrop Elementary',
  'Jack Norton Elementary',
  'Jack P Nix Elementary School',
  'Jack P. Timmons Elementary School',
  'Jack Robey Jr. High School',
  'Jack Swigert Aerospace Academy',
  'Jack T Farrar Elementary',
  'Jack Taylor El',
  'Jack Thoman Air And Space Academy And Performing Arts Studio',
  'Jack Young Middle',
  'Jackie Carden El',
  'Jackie Harris Preparatory Academy',
  'Jackie Mae Townsell El',
  'Jackling School',
  'Jackman Elementary School',
  'Jackman Road Elementary School',
  'Jackpot Elementary School',
  'Jackpot High School',
  'Jackpot Junior High School',
  'Jacks Creek Elementary',
  'Jacks Valley Elementary School',
  'Jacksboro El',
  'Jacksboro Elementary',
  'Jacksboro H S',
  'Jacksboro Middle',
  'Jacksboro Middle School',
  'Jackson A Elem Language Acad',
  'Jackson Academy High School',
  'Jackson Academy Middle School',
  'Jackson Academy Of Music And Math (jamm)',
  'Jackson Alternative School',
  'Jackson Andrew Sch',
  'Jackson Annex School',
  'Jackson Area Career Center',
  'Jackson Area Technology Center',
  'Jackson Avenue',
  'Jackson Avenue Elementary',
  'Jackson Avenue School',
  'Jackson Career Technology Magnet Elementary',
  'Jackson Center',
  'Jackson Center Elementary School',
  'Jackson Center High School',
  'Jackson Central-merry Early College High',
  'Jackson Charter School',
  'Jackson City School',
  'Jackson Co Early College',
  'Jackson Co Juvenile Detention Cntr',
  'Jackson Co Sch Of Alternatives',
  'Jackson College',
  'Jackson Correctional Institution',
  'Jackson County Alternative School',
  'Jackson County Central Middle',
  'Jackson County Central Senior High',
  'Jackson County Early Childhood Center',
  'Jackson County High School',
  'Jackson County Jail',
  'Jackson County Middle School',
  'Jackson County School-sunland',
  'Jackson County Vocational Center',
  'Jackson Creek Elementary',
  'Jackson Creek Middle School',
  'Jackson Davis Elementary',
  'Jackson Early Childhood Center',
  'Jackson El',
  'Jackson El Sch',
  'Jackson Elem School',
  'Jackson Elementary',
  'Jackson Elementary School',
  'Jackson Es',
  'Jackson Grammar School',
  'Jackson Heights Elementary',
  'Jackson Heights Elementary School',
  'Jackson Heights High School And Middle School',
  'Jackson Heights Middle School',
  'Jackson Heights School',
  'Jackson High School',
  'Jackson Hole High School',
  'Jackson Hole Middle School',
  'Jackson Int',
  'Jackson Intermediate',
  'Jackson Intermediate Center',
  'Jackson Intermediate School',
  'Jackson Isd Local Based Special Education Programs',
  'Jackson Junior High',
  'Jackson Liberty High School',
  'Jackson M Elem School',
  'Jackson Main Elementary School',
  'Jackson Mann',
  'Jackson Memorial Elementary',
  'Jackson Memorial High School',
  'Jackson Memorial Middle School',
  'Jackson Middle',
  'Jackson Middle School',
  'Jackson Northview Elementary School',
  'Jackson P. Burley Middle',
  'Jackson Park Elem.',
  'Jackson Park Elementary',
  'Jackson Pathways',
  'Jackson Preparatory Early College',
  'Jackson Preparatory Elementary',
  'Jackson Primary Sch',
  "Jackson River Governor's School",
  'Jackson River Tech Ctr',
  'Jackson Road Elementary',
  'Jackson Road Elementary School',
  'Jackson Sch',
  'Jackson School',
  'Jackson Sipp/child Crisis',
  'Jackson Southview Elementary School',
  'Jackson Sr. High',
  'Jackson State Community College',
  'Jackson State University',
  'Jackson Stem Middle',
  'Jackson Street',
  'Jackson Street Elementary',
  'Jackson Technology Center',
  'Jackson Township Elementary School',
  'Jackson Virtual Franchise',
  'Jackson Virtual Instruction Course Offerings',
  'Jackson Virtual Instruction Program',
  'Jackson Westview Elementary School',
  'Jackson-keller El',
  'Jackson-milton Elementary School',
  'Jackson-milton High School',
  'Jackson-milton Middle School',
  'Jackson-via Elementary',
  'Jackson/perry Ms Taoep',
  'Jackson/roosevelt Complex',
  'Jacksonolin High School',
  'Jacksonsteele Elementary School',
  'Jacksonville Beach Elementary School',
  'Jacksonville College-​Main Campus',
  'Jacksonville Commons Elem',
  'Jacksonville Commons Middle',
  'Jacksonville Elementary',
  'Jacksonville Elementary School',
  'Jacksonville H S',
  'Jacksonville Heights Elementary School',
  'Jacksonville High',
  'Jacksonville High School',
  'Jacksonville Lighthouse Elementary',
  'Jacksonville Middle',
  'Jacksonville Middle School',
  'Jacksonville State University',
  'Jacksonville University',
  'Jacksonwald El Sch',
  'Jacob Coy Middle School',
  'Jacob Elementary',
  'Jacob G. Smith Elementary School',
  'Jacob Hiatt Magnet',
  'Jacob L. Adams Elementary',
  'Jacob Wiens Elementary',
  'Jacob Wismer Elementary School',
  "Jacob's Well El",
  'Jacobs Elementary School',
  'Jacobs Fork Middle',
  'Jacobs Road Elementary',
  'Jacobs University Bremen',
  'Jacobsen High (china Spring Yc)',
  'Jacobsen Middle',
  'Jacobsville Elementary',
  'Jacoby Creek',
  'Jacox Elementary',
  'Jacqueline Kennedy-onassis High School',
  'Jacqueline M. Walsh School',
  'Jacques Marquette Elem School',
  'Jacques Memorial Elementary School',
  'Jadara University',
  'Jadavpur University',
  'Jaeger Preschool',
  'Jaffrey Grade School',
  'Jaffrey-rindge Middle School',
  'Jagannath University',
  'Jagiellonian University Cracow',
  'Jahangirnagar University',
  'Jahn Elem School',
  'Jahrom University of Medical Sciences',
  'Jai Narain Vyas University',
  'Jai Prakash University',
  'Jail-gila County Sheriffs Office',
  'Jail-graham County Sheriffs Office',
  'Jail-greenlee County Sheriffs Office',
  'Jail-maricopa County Sheriffs Office',
  'Jail-santa Cruz County Sheriffs Office',
  'Jaime A. Collazo Del Rio',
  'Jaime C. Rodriguez',
  'Jaime Coira Ortiz (su Pozas)',
  'Jaime Escalante Elementary',
  'Jaime Escalante Middle',
  'Jaime L. Drew',
  'Jaime Rosario (buena Vista Elemental)',
  'Jain Vishva Bharati Institute',
  'Jaipur National University',
  'Jake Drost School For Exceptional Children',
  'Jake Silbernagel El',
  'Jal Elementary',
  'Jal High',
  'Jal Jr High',
  'Jalalabad State University Kyrgyzstan',
  'Jalen Rose Leadership Academy',
  'Jamacha Elementary',
  'Jamaica Child Development Center',
  "Jamaica Children's School",
  'Jamaica Elementary School',
  'Jamaica Gateway To The Sciences',
  'Jamaica Village School',
  'James A Allison Elementary Sch 3',
  'James A Arthur Int',
  'James A Caywood Elementary School',
  'James A Dever School',
  'James A Farley Elementary School',
  'James A Garfield Elementary School',
  'James A Garfield High School',
  'James A Garfield Middle School',
  'James A Garfield School 31',
  'James A Green High School',
  'James A Harmon Elementary School',
  'James A Monaco',
  'James A Rhodes State College',
  'James A. Cawood Elementary',
  'James A. Duff Elementary School',
  'James A. Garfield Elementary School',
  'James A. Garfield Senior High',
  'James A. Graham Elementary',
  'James A. Herod Elementary School',
  'James A. Long Elementary School',
  'James A. Mcdivitt Elementary School',
  'James A. Mckee Elementary',
  'James A. Shanks Middle School',
  'James A. Taylor High School',
  'James A. Whitaker Elementary',
  'James And Margie Marion El',
  'James Avant Elementary School',
  'James B Congdon',
  'James B Dudley High',
  'James B Eads Elementary School',
  'James B Havard El',
  'James B Mcmillan Elementary School',
  'James B Rolle School',
  'James B. Davidson Middle',
  'James B. Edwards Elementary',
  'James B. Sanderlin Pk-8',
  'James Baldwin School-a School For Expeditionary Lrning',
  'James Bickley Elementary',
  'James Bilbray Elementary School',
  'James Blair Middle',
  'James Bowie El',
  'James Bowie Fine Arts Academy',
  'James Bowie H S',
  'James Bowie Middle',
  'James Buchanan El Sch',
  'James Buchanan Hs',
  'James Buchanan Ms',
  'James Burd El Sch',
  'James Byrnes Freshman Academy',
  'James C Neill El',
  'James C. Enochs High',
  'James C. Isabell School',
  'James Caldwell Elementary School',
  'James Caldwell High School',
  'James Campbell High School',
  'James Cashman Middle School',
  'James Clark School',
  'James Clemens High School',
  'James Coble Middle',
  'James Cole Elementary School',
  'James Condon Elementary',
  'James Conger Elementary School',
  'James Cook University of North Queensland',
  'James D Price School',
  'James D. Adams Middle School',
  'James Dawson Elementary',
  'James Dougherty Elementary',
  'James Dukes Elementary',
  'James E & A Rae Smalley Elementary School',
  'James E Bazzell Middle School',
  'James E Mitchell El',
  'James E Plew Elementary School',
  'James E Randolph El',
  'James E Shepard Middle',
  'James E Williams El',
  'James E. Duckworth Regional Center',
  'James E. Freer Elem.',
  'James E. Moss School',
  'James E. Potter Intermediate',
  'James E. Stephens Elementary School',
  'James Earl Carter Elementary',
  'James Earl Rudder H S',
  'James Elem.',
  'James Elementary',
  'James Elementary School',
  'James Ellis School',
  'James Enders Elementary',
  'James F Baugher El Sch',
  'James F Delaney El',
  'James F Hennessey',
  'James F. Byrnes High',
  'James F. Cooper Elementary School',
  'James F. Doughty School',
  'James F. Murray School',
  'James Fallon Elementary School',
  'James Fannin Middle',
  'James Faulkner Elementary School',
  'James Fitzgerald Elementary School',
  'James Foote School',
  'James Ford Rhodes High School',
  'James Foster Elementary',
  'James Franklin Smith Elementary',
  'James G. Brumfield Elementary',
  'James Garfield',
  'James Garfield Elementary',
  'James Garland Walsh Middle',
  'James Gettys El Sch',
  'James Gibson Elementary School',
  'James Griffith Intermediate Es',
  'James H Baker Sixth Grade Campus',
  'James H Bean School',
  'James H Boyd Elementary School',
  'James H Faulkner State Community College',
  'James H Ross El',
  'James H Watson Elementary School',
  'James H. Bright Elementary/j.w. Johnson Elementary',
  'James H. Eldredge School',
  'James H. Harrison Elementary',
  'James H. Hendrix Elementary',
  'James H. Moran Middle School',
  'James H. Spann Elementary',
  'James Hart School',
  'James Hillhouse High School',
  'James House Preschool',
  'James Hubert Blake High',
  'James Hunt High',
  'James Hurst Elementary',
  "James I O'neill High School",
  'James Irvin Adult Education',
  'James Irvin Education Center',
  'James Irvine Intermediate',
  'James Irwin Charter Academy',
  'James Irwin Charter Elementary School',
  'James Irwin Charter High School',
  'James Irwin Charter Middle School',
  'James Island Charter High',
  'James Island Elementary',
  'James Island Middle',
  'James J Ambrose School (the)',
  'James J Chittick',
  'James J Ferris High School',
  'James J Hilfiker Elementary School',
  'James J. Curiale School',
  'James J. Flynn Elementary School',
  'James J. Mcbride Special Education Center',
  'James Jackson Elementary School',
  'James John Elementary School',
  'James Johnson Elementary School',
  'James Jordan Middle',
  'James K. Polk Elementary',
  'James Kenan High',
  'James L Cowart Elementary School',
  'James L Masters',
  'James L Wright El',
  'James L. Capps Ms',
  'James L. Day Middle',
  'James L. Mcguire School',
  'James Lane Allen Elementary School',
  'James Leitch Elementary',
  'James Lewis Elem.',
  'James Lick High',
  'James Logan High',
  'James Love Elementary',
  'James M Coughlin Jshs',
  'James M Steele H S',
  'James M. Anderson Elementary School',
  'James M. Bennett High',
  'James M. Brown Elementary',
  'James M. Burchfield Primary',
  'James M. Marlowe Elementary School',
  'James M. Quinn School',
  'James M. Singleton Charter School',
  'James Madison Academic Campus',
  'James Madison Charter Academy School',
  'James Madison Elementary',
  'James Madison H S',
  'James Madison High School',
  'James Madison Intermediate School',
  'James Madison Middle',
  'James Madison Middle School',
  'James Madison Preparatory Charter High School',
  'James Madison Preparatory School',
  'James Madison Primary School',
  'James Madison School',
  'James Madison School #10',
  'James Madison University',
  'James Marshall Elementary',
  'James Martin Middle',
  'James Mastricola Elementary School',
  'James Mastricola Upper Elementary School',
  'James Mcgee Elementary',
  'James Mchenry Elementary',
  'James Middle',
  'James Monroe Elementary',
  'James Monroe Elementary School',
  'James Monroe High',
  'James Monroe High School',
  'James Monroe Middle',
  'James Morris School',
  'James Mosher Elementary',
  'James N. Gamble Montessori High School',
  'James Otis',
  'James Otis Kaler Elementary School',
  'James P Butler El',
  'James P Timilty Middle',
  'James Patterson El',
  'James R Bilhartz Jr El',
  'James R Brooks Middle',
  'James R Doolittle Academy',
  'James R Geisler Middle School',
  'James R Watson Elementary School',
  'James R Wood Elem School',
  'James R. Allen High School',
  'James R. Cowan Fundamental Elementary',
  'James R. D. Oldham School',
  'James R. Tate Elem. School',
  'James Rickards High School',
  'James River Elementary',
  'James River High',
  'James River Spec Ed Cooperative',
  'James Rodriguez Elementary',
  'James Rosser Elementary School',
  'James Rumsey Technical Institute',
  'James Russell Lowell',
  'James Russell Lowell Elementary School',
  'James Russell Lowell School 51',
  'James Rutter Middle',
  'James Ryder Randall Elementary',
  'James S Daley Middle School',
  'James S Evans Elementary School',
  'James S Hogg El',
  'James S Wilson Ms',
  'James S. Fugman Elementary',
  'James S. Hunt Elementary School',
  'James S. Rickards Middle School',
  'James S. Russell Middle',
  'James Sales Elementary',
  'James Simons Elementary',
  'James Sprunt Community College',
  'James Stephens International Academy',
  'James Stephens Montessori School',
  'James Sullivan Middle School',
  'James T Alton Middle School',
  'James T Jones Elementary School',
  'James Tansey',
  'James Templeton Elementary School',
  'James Tillman Elementary Magnet School',
  'James Tippit Middle',
  'James Valley Area Career & Tech Center',
  'James Van Allen Elementary School',
  'James W Hennigan',
  'James W Parker Ms',
  'James W Smith Elementary',
  'James W. Lilley Jr. Elementary School',
  'James W. Mitchell High Adult Education',
  'James W. Mitchell High School',
  'James W. Rice Primary School',
  'James W. Sikes Elementary School',
  'James Walker Elem.',
  'James Weldon Johnson College Prepartory Middle School',
  'James Weldon Johnson School',
  'James Whitcomb Riley Elem Sch',
  'James Whitcomb Riley Elem School',
  'James Whitcomb Riley School',
  'James Whitcomb Riley School 43',
  'James Williams Middle',
  'James Wilson Grimes School',
  'James Wilson Young Middle School',
  'James Wood High',
  'James Wood Middle',
  'James Workman Middle',
  'James Wright Middle',
  'James Y Joyner Elementary',
  'Jameson Elementary School',
  'Jamestown Area El Sch',
  'Jamestown Area Jshs',
  'Jamestown C-i Elem.',
  'Jamestown C-i High',
  'Jamestown Community College',
  'Jamestown Elem.',
  'Jamestown Elementary',
  'Jamestown Elementary School',
  'Jamestown High',
  'Jamestown High School',
  'Jamestown Lower Elementary School',
  'Jamestown Middle',
  'Jamestown Middle School',
  'Jamestown School-lawn',
  'Jamestown School-melrose',
  'Jamestown Spec Ed Unit',
  'Jamestown Upper Elementary School',
  'Jamesville Colony Elementary - 03',
  'Jamesville Elementary',
  'Jamesville Elementary School',
  'Jamesville-dewitt High School',
  'Jamesville-dewitt Middle School',
  'Jami Institute of Technology',
  'Jamia Hamdard University',
  'Jamia Millia Islamia University',
  'Jamie Mcgee Elementary School',
  'Jamieson Elem School',
  'Jamison (donald C.) High (continuation)',
  'Jamison El Sch',
  'Jamul Intermediate',
  'Jamul Primary',
  'Jan Jones Blackhurst Elementary School',
  'Jan Schiff El',
  'Jan Work Community',
  'Jana Elem.',
  'Jane A Hambric School',
  'Jane Addams Business Careers High School',
  'Jane Addams Continuation',
  'Jane Addams Elem School',
  'Jane Addams Elementary School',
  'Jane Addams Jhs',
  'Jane Addams Middle',
  'Jane Addams Middle School',
  'Jane Ball Elementary School',
  'Jane Chance Elementary',
  'Jane D. Hull Elementary',
  'Jane E. Tuitt Elementary School',
  'Jane Edwards Elementary',
  'Jane Frederick High',
  'Jane Goodall Environmental Middle Charter School',
  'Jane Goodall Environmental Science',
  'Jane H. Bryan Elementary',
  'Jane Lathrop Stanford Middle',
  'Jane Lew Elementary School',
  'Jane Long',
  'Jane Long El',
  'Jane Macon Middle',
  'Jane Phillips Es',
  'Jane Ryan School',
  'Jane S. Roberts K-8 Center',
  'Jane Stenson School',
  'Janes Elementary',
  'Janesville Elementary',
  'Janesville Elementary School',
  'Janesville Junior-senior High School',
  'Janesville-waldorf-pemberton El.em.',
  'Janesville-waldorf-pemberton Sec',
  'Janet Brockett El',
  'Janet Kahn Fine Arts Academy',
  'Janie C Hargrave Elem',
  'Janie Darr Elementary School',
  'Janie Howard Wilson School',
  'Janie P. Abbott Elementary',
  'Janis E. Dismus Middle School',
  'Janitell Junior High School',
  'Jann Mann Educational Center',
  'Janney Es',
  'Janos Selye University',
  'Janowski El',
  'Janssen Elementary',
  'Janus Pannonius University',
  'Japan Advanced Institute of Science and Technology',
  'Japan College of Social Work',
  "Japan Women's University",
  'Japanese Red Cross College of Nursing',
  'Jaramillo Elementary',
  'Jaramogi Oginga Odinga University of Science and Technology',
  'Jardin De La Infancia',
  'Jardine Elementary',
  'Jardine Middle School',
  'Jardine Technology Middle Magnet',
  'Jardines De Caguas',
  'Jardines De Ponce',
  'Jardines Del Paraiso',
  'Jardon Vocational School',
  'Jared Eliot School',
  'Jarman Es',
  'Jarman Ms',
  'Jarrell El',
  'Jarrell H S',
  'Jarrell Int',
  'Jarrell Middle',
  'Jarrett Middle',
  'Jarrettown El Sch',
  'Jarrettsville Elementary',
  'Jarvis Camp',
  'Jarvisburg Elementary',
  'Jason Lee',
  'Jason Lee Elementary School',
  'Jason Lee Middle School',
  'Jasper Co Jr High',
  'Jasper Co. Elem.',
  'Jasper County High School',
  'Jasper County Middle School',
  'Jasper County Primary School',
  'Jasper Elem School',
  'Jasper Elementary',
  'Jasper Elementary School',
  'Jasper H S',
  'Jasper High',
  'Jasper High School',
  'Jasper J H',
  'Jasper Junior High School',
  'Jasper Middle School',
  'Jasper-troupsburg Elementary School',
  'Jasper-troupsburg Junior-senior High School',
  'Jatiya Kabi Kazi Nazrul Islam University',
  'Jawaharlal Nehru Agricultural University',
  'Jawaharlal Nehru Centre for Advanced  Scientific Research',
  'Jawaharlal Nehru Technological University',
  'Jawaharlal Nehru University',
  'Jawzjan University',
  'Jay County High School',
  'Jay Elementary School',
  'Jay Es',
  'Jay H S',
  'Jay High School',
  'Jay Hs',
  'Jay M Robinson High',
  'Jay M Robinson Middle',
  'Jay Ms',
  'Jay Shideler Elementary',
  'Jay Stream Middle School',
  'Jay Upper Es',
  'Jay W. Jeffers Elementary School',
  'Jay/westfield Joint Elementary School',
  'Jayenne Elementary School',
  'Jayhawk Elementary',
  'Jayhawk-linn High',
  'Jaypee Institute of Information Technology',
  'Jayton Schools',
  'Jazan University',
  'Jb Griffin',
  'Jb Pennington High School',
  'Jb Stephens Elementary School',
  'Jc Kelly El',
  'Jc Sommer Elementary School',
  'Jcc - Lewiston',
  'Jcc - Nampa',
  'Jcc - St Anthony',
  'Jcclisd Academy A Regional Middle College',
  'Jcfa',
  'Jcfa Lafayette',
  'Jcfa-east',
  'Jcps Eschool',
  'Jcssa',
  'Jd Hall Learning Center',
  'Jdc High School',
  'Je Terry Elementary School',
  'Je Williams P6 School',
  'Je-neir Elem School',
  'Jeaga Middle School',
  'Jean & Betty Schmalz El',
  'Jean C Few Pri',
  'Jean Callison Elementary',
  'Jean Massieu Academy',
  'Jean Massieu School For The Deaf',
  'Jean Mcclung Middle',
  'Jean Mcnair Elementary School',
  'Jean Nuzzi Intermediate School',
  "Jean O'dell Learning Center",
  'Jean Ribault High School',
  'Jean Ribault Middle School',
  'Jeane Thorman Elementary',
  'Jeanerette Elementary School',
  'Jeanerette Senior High School',
  'Jeanette Hayes El',
  'Jeanne R. Meadows Elementary',
  'Jeannette Hs',
  'Jeannette Mckee El Sch',
  'Jeannette Mckee Ms',
  'Jeannette Myhre Elementary School',
  'Jeannette Shs',
  'Jeddito School',
  'Jedi Virtual K-12',
  "Jeehdeez'a Elementary School",
  'Jeff Davis Co Voc Tech Center',
  'Jeff Davis Elementary School',
  'Jeff Davis High School',
  'Jeff Davis Middle School',
  'Jeff Davis Primary School',
  'Jeff H Towles Intermediate School',
  "Jeffco's 21st Century Virtual Academy",
  'Jefferies J H',
  'Jeffers Elementary School',
  'Jeffers High School',
  'Jeffers Hill Elementary',
  'Jeffers Pond Elementary',
  'Jefferson',
  'Jefferson (annex) Elementary School',
  'Jefferson (thomas) Elementary',
  'Jefferson Academy',
  'Jefferson Academy Elementary',
  'Jefferson Academy High School',
  'Jefferson Adult/community Center',
  'Jefferson Alt High School',
  'Jefferson Area Junior High School',
  'Jefferson Area Sr High School',
  'Jefferson Area Vocational Center',
  'Jefferson Attendance Center',
  'Jefferson Ave El',
  'Jefferson Ave Elementary School',
  'Jefferson Avenue School',
  'Jefferson Building - 64',
  'Jefferson Central School',
  'Jefferson Charter Academy',
  'Jefferson City High',
  'Jefferson Co Elem School',
  'Jefferson Co High',
  'Jefferson Co High School',
  'Jefferson Co J J A E P',
  'Jefferson Co Jr Hi',
  'Jefferson Co North High',
  'Jefferson Co Youth Acad',
  'Jefferson College',
  'Jefferson Community College',
  'Jefferson Community and Technical College',
  'Jefferson County',
  'Jefferson County Adult School',
  'Jefferson County Alternative School',
  'Jefferson County Couns And Learning Ctrwest',
  'Jefferson County Counseling Learning Centereast',
  'Jefferson County Elementary A Somerset Charter School',
  'Jefferson County Ese School',
  'Jefferson County High',
  'Jefferson County High A Somerset Charter School',
  'Jefferson County High School',
  'Jefferson County International Baccalaureate School',
  'Jefferson County Middle A Somerset Charter School',
  'Jefferson County Middle School',
  'Jefferson County North Elem/middle',
  'Jefferson County Open Elementary School',
  'Jefferson County Open Secondary',
  'Jefferson County Traditional Middle',
  'Jefferson County Upper Elementary',
  'Jefferson County-dubois Avts',
  'Jefferson Davis Community College',
  'Jefferson Davis High School',
  'Jefferson Davis Middle',
  'Jefferson Davis Middle School',
  'Jefferson Early Childhood Center',
  'Jefferson Early Childhood Ctr',
  'Jefferson Early Lrning Ctr',
  'Jefferson Ec Ctr',
  'Jefferson El',
  'Jefferson El Sch',
  'Jefferson Elem',
  'Jefferson Elem School',
  'Jefferson Elem.',
  'Jefferson Elementary',
  'Jefferson Elementary - 06',
  'Jefferson Elementary - 09',
  'Jefferson Elementary 5th6th',
  'Jefferson Elementary Center',
  'Jefferson Elementary School',
  'Jefferson Es',
  'Jefferson Forest High',
  'Jefferson H S',
  'Jefferson Heights Es',
  'Jefferson High',
  'Jefferson High (continuation)',
  'Jefferson High School',
  'Jefferson Intermediate',
  'Jefferson Intermediate Center',
  'Jefferson Intermediate School',
  'Jefferson International Academy Elementary',
  'Jefferson International Academy Intermediate',
  'Jefferson Island Road Elementary',
  'Jefferson J H',
  'Jefferson Jr High School',
  'Jefferson Junior High School',
  'Jefferson Junior/senior High',
  'Jefferson Junior/senior High School',
  'Jefferson Leadership Academies',
  'Jefferson Learning Center',
  'Jefferson Lighthouse Elementary',
  'Jefferson Lincoln Elementary',
  'Jefferson Magnet School',
  'Jefferson Middle',
  'Jefferson Middle School',
  'Jefferson Middle School Academy',
  'Jefferson Montessori',
  'Jefferson Ms',
  'Jefferson Park Elementary School',
  'Jefferson Park School',
  'Jefferson Parkway Elementary School',
  'Jefferson Pk-8 School',
  'Jefferson Preparatory High School',
  'Jefferson Pri Sch',
  'Jefferson Primary School',
  'Jefferson R-vii Preschool',
  'Jefferson Rise Charter School',
  'Jefferson Road School',
  'Jefferson School',
  'Jefferson Senior High',
  'Jefferson State Community College',
  'Jefferson Technical College',
  'Jefferson Terrace Elementary School',
  'Jefferson Township High School',
  'Jefferson Township Middle School',
  'Jefferson Village School',
  'Jefferson Virtual Franchise',
  'Jefferson Virtual Instruction Course Offerings',
  'Jefferson Virtual Instruction Program',
  'Jefferson West Elementary School',
  'Jefferson West High',
  'Jefferson West Middle',
  'Jefferson-craig Elementary School',
  'Jefferson-houston Elementary',
  'Jefferson-lewis-hamilton-herkimer-oneida Boces',
  'Jefferson-morgan El Sch',
  'Jefferson-morgan Ms/hs',
  'Jefferson/todd Alternative Program',
  'Jeffersontown Elementary',
  'Jeffersontown High',
  'Jeffersonville Elementary',
  'Jeffersonville High School',
  'Jeffery Elementary',
  'Jeffery Primary Sch',
  'Jeffrey Behavior Jr/sr High School',
  'Jeffrey City Elementary',
  'Jeffrey Clark School',
  'Jeffrey Trail Middle',
  'Jeffreys Grove Elementary',
  'Jeffries Elem.',
  'Jellick Elementary',
  'Jellico Elementary',
  'Jellico High School',
  'Jemez Day School',
  'Jemez Valley Elementary',
  'Jemez Valley High',
  'Jemez Valley Middle',
  'Jemison Elementary School',
  'Jemison High School',
  'Jemison Intermediate School',
  'Jemison Middle School',
  'Jemtegaard Middle School',
  'Jena Elementary School',
  'Jena High School',
  'Jena Junior High School',
  'Jenifer Junior High School',
  'Jenison High School',
  'Jenison International Academy',
  'Jenison Junior High School',
  'Jenison Public Schools Early Childhood Center',
  'Jenkins County Elementary School',
  'Jenkins County High School',
  'Jenkins County Middle School',
  'Jenkins Creek Elementary School',
  'Jenkins Early Childhood Learning Center',
  'Jenkins Elementary School',
  'Jenkins High School',
  'Jenkins Independent School',
  'Jenkins Junior/senior High',
  'Jenkins Middle School',
  'Jenkins-white Elementary School',
  'Jenkintown El Sch',
  'Jenkintown Middle/high School',
  'Jenks Abram Sch',
  'Jenks East Es',
  'Jenks East Intermediate Es',
  'Jenks El',
  'Jenks Hill El Sch',
  'Jenks Hs',
  'Jenks Ms',
  'Jenks West Es',
  'Jenks West Intermediate Es',
  'Jenner Elem Academy Of The Arts',
  'Jennie Barker Elem',
  'Jennie D. Blake School',
  'Jennie Dean Elementary',
  'Jennie F Snapp Middle School',
  'Jennie Moore Elementary',
  'Jennie Reid El',
  'Jennie Rogers Elementary School',
  'Jennie Wilson Elem',
  'Jennings Community Learning Center',
  'Jennings County High School',
  'Jennings County Middle School',
  'Jennings Elementary School',
  'Jennings Experiential High School',
  'Jennings High',
  'Jennings High School',
  'Jennings Jr. High',
  'Jennings Lodge Elementary School',
  'Jennings Middle School',
  'Jennings Public School',
  'Jennings Randolph Elementary School',
  'Jennings School',
  'Jenny Lind Elementary',
  'Jenny Oropeza Elementary',
  'Jenny Preston El',
  'Jensen Beach Elementary School',
  'Jensen Beach High School',
  'Jensen El',
  'Jensen Elem Scholastic Academy',
  'Jensen Elementary School',
  'Jensen Ranch Elementary',
  'Jeonju University',
  'Jerabek Elementary',
  'Jerash Private University',
  'Jere Baxter Middle',
  'Jere L. Stambaugh Middle',
  'Jere Whitson Elementary',
  'Jeremiah E Burke High',
  'Jeremiah Gray Elementary School',
  'Jeremy Ranch Preschool',
  'Jeremy Ranch School',
  'Jerger Elementary School',
  'Jericho Elementary School',
  'Jericho Middle School',
  'Jericho Senior High School',
  'Jerling Junior High School',
  'Jermaine L Green Stem Institute In Queens',
  'Jerolene',
  'Jerome Academy',
  'Jerome Dunn Academy No 9',
  'Jerome Harrison School',
  'Jerome High School',
  'Jerome Mack Middle School',
  'Jerome Middle School',
  'Jerome W. Stampley 9th Grade Academ',
  'Jerry D. Holland Middle',
  'Jerry L White Center High School',
  'Jerry Pop Williams Elem Sch',
  'Jerry R Junkins El',
  'Jerry Ross Elementary School',
  'Jerry Thomas Elementary School',
  'Jerry Voorhis Elementary',
  'Jerry Whitehead Elementary School',
  'Jerry Zucker Middle School Of Science',
  'Jersey Avenue Elementary',
  'Jersey City Community Charter School',
  'Jersey City Global Cs',
  'Jersey City Golden Door Charter School',
  'Jersey Comm High School',
  'Jersey Shore Area El Sch',
  'Jersey Shore Area Shs',
  'Jersey Shore Ms',
  'Jersey Village H S',
  'Jerseyville East Elem School',
  'Jerseyville West Elem School',
  'Jerstad-agerholm Elementary',
  'Jerstad-agerholm Middle',
  'Jerusalem Elementary School',
  'Jerusalem University College',
  'Jess Harben El',
  'Jessamine Career And Technology Center',
  'Jessamine Early Learning Village',
  'Jesse Beer - Madison Early Childhood Learning Center',
  'Jesse Boyd Elementary',
  'Jesse C White Learning Academy',
  'Jesse D Lay Elementary School',
  'Jesse D Scott Es',
  'Jesse G. Sanchez Elementary',
  'Jesse Hall Elementery School',
  'Jesse J. Mccrary Jr. Elementary School',
  'Jesse Keen Elementary School',
  'Jesse L Anderson Elementary School',
  'Jesse M. Bethel High',
  'Jesse Mcgowen El',
  'Jesse Owens Elementary School',
  'Jesse S. Bobo Elementary',
  'Jesse Stuart Elementary School',
  'Jesse Wharton Elem',
  'Jessie Baker',
  'Jessie Beck Elementary School',
  'Jessie D. Clifton Elementary School',
  'Jessie F. George Elementary',
  'Jessie Franklin Taylor',
  'Jessie Hayden Elementary',
  'Jessie L Jensen El',
  'Jessie Loomis School',
  'Jessie M Clark Middle School',
  'Jessie Mae Monroe Elementary',
  'Jessie Nelson Academy',
  'Jessie P. Miller Elementary School',
  'Jessie Rouse School',
  'Jessie T Zoller Elementary School',
  'Jessie Thackrey Preschool',
  'Jessieville Elementary School',
  'Jessieville High School',
  'Jessieville Middle School',
  'Jessup El',
  'Jessup Elementary',
  'Jesup Elementary School',
  'Jesup High School',
  'Jesup Middle School',
  'Jesup W. Scott High School',
  'Jesus Manuel Suarez',
  'Jesus Maria Quinones',
  'Jesus Maria Sanroma',
  'Jesus Rivera Bultron',
  'Jesus Sanchez Erazo',
  'Jesus Silva',
  'Jesus T. Pinero',
  'Jeter Primary School',
  'Jeter-watson Intermediate',
  'Jettie S. Tisdale School',
  'Jewel C Wietzel Center',
  'Jewel City Community Day',
  'Jewel M. Sumner High School',
  'Jewel M. Sumner Middle School',
  'Jewel Middle School',
  'Jewell Elementary School',
  'Jewell School',
  'Jewett Elementary School',
  'Jewett Middle Academy Magnet',
  'Jewett School',
  'Jewett School Of The Arts',
  'Jewish University in Moscow',
  'Jf Webb Hs Of Health And Life Sciences',
  'Jhs 104 Simon Baruch',
  'Jhs 118 William W Niles',
  'Jhs 123 James M Kiernan',
  'Jhs 125 Henry Hudson',
  'Jhs 127 Castle Hill (the)',
  'Jhs 131 Albert Einstein',
  'Jhs 14 Shell Bank',
  'Jhs 143 Eleanor Roosevelt',
  'Jhs 144 Michelangelo',
  'Jhs 145 Arturo Toscanini',
  'Jhs 151 Lou Gehrig',
  'Jhs 157 Stephen A Halsey',
  'Jhs 162 Lola Rodriguez De Tio',
  'Jhs 162 Willoughby (the)',
  'Jhs 167 Robert F Wagner',
  'Jhs 185 Edward Bleeker',
  'Jhs 189 Daniel Carter Beard',
  'Jhs 190 Russell Sage',
  'Jhs 194 William Carr',
  'Jhs 201 Dyker Heights (the)',
  'Jhs 202 Robert H Goddard',
  'Jhs 210 Elizabeth Blackwell',
  'Jhs 216 George J Ryan',
  'Jhs 217 Robert A Van Wyck',
  'Jhs 218 James P Sinnott',
  'Jhs 22 Jordan L Mott',
  'Jhs 220 John J Pershing',
  'Jhs 223 Montauk (the)',
  'Jhs 226 Virgil I Grisson',
  'Jhs 227 Edward B Shallow',
  'Jhs 234 Arthur W Cunningham',
  'Jhs 259 William Mckinley',
  'Jhs 278 Marine Park',
  'Jhs 291 Roland Hayes',
  'Jhs 292 Margaret S Douglas',
  'Jhs 383 Philippa Schuyler',
  'Jhs 50 John D Wells',
  'Jhs 52 Inwood',
  'Jhs 54 Booker T Washington',
  'Jhs 57 Whitelaw Reid',
  'Jhs 62 Ditmas',
  'Jhs 67 Louis Pasteur',
  'Jhs 74 Nathaniel Hawthorne',
  'Jhs 78 Roy H Mann',
  'Jhs 8 Richard S Grossley',
  'Jhs 80 Mosholu Parkway (the)',
  'Jhs 88 Peter Rouget',
  'Jhs 98 Herman Ridder',
  'Jhw Inspire Academy - Afton Oaks',
  'Jhw Inspire Academy - Bell County',
  'Jhw Inspire Academy - Crockett',
  'Jhw Inspire Academy - Granbury',
  'Jhw Inspire Academy - Hays County',
  'Jhw Inspire Academy - Legacy Ranch',
  'Jhw Inspire Academy - Meridell',
  'Jhw Inspire Academy - Rockdale',
  'Jhw Inspire Academy - Williams House',
  'Jiangsu University of Science and Technology',
  'Jiangxi Agricultural University',
  'Jiangxi Normal University',
  'Jiangxi University of Finance and Economics',
  'Jiangxi University of Traditional Chinese Medicine',
  'Jiaying University',
  'Jichi Medical School',
  'Jie Ming Mandarin Immersion Academy',
  'Jigjiga University',
  'Jikei University School of Medicine',
  'Jilin Agricultural University',
  'Jilin University',
  'Jilin University of Technology',
  'Jill Chaifetz Transfer High School',
  'Jill Kinmont Boothe',
  'Jill Stone El At Vickery Meadow',
  'Jim Allen Elementary School',
  'Jim And Betty Hughes El',
  'Jim Barnes Middle',
  'Jim Bridger Middle School',
  'Jim Bridger School',
  'Jim C. Bailey Middle School',
  'Jim Darcy School',
  'Jim Falls Elementary',
  'Jim Hill High School',
  'Jim Hill Middle School',
  'Jim Maples Academy',
  'Jim Ned H S',
  'Jim Ned Middle',
  'Jim Pearson Elementary School',
  'Jim Plain El',
  'Jim Satterfield Middle School',
  'Jim Spradley El',
  'Jim Stone Elementary School',
  'Jim Thorpe Area Hs',
  'Jim Thorpe Elementary School',
  'Jim Thorpe Fundamental',
  'Jim?nez Roberto And Dr. Francisco Elementary School',
  'Jimei University',
  'Jimma University',
  'Jimmie M. Goodloe Elementary School',
  'Jimmie Tyler Brashear El',
  'Jimmy C Draughn High School',
  'Jimmy Carter Early College H S',
  'Jimmy Carter Middle',
  'Jimmy Huntington School',
  'Jimtown Elementary School',
  'Jimtown High School',
  'Jimtown Intermediate School',
  'Jimtown Junior High School',
  'Jinan University',
  'Jing Mei Elementary School',
  'Jingdezhen China Institute',
  'Jinks Middle School',
  'Jinnah University for Women',
  'Jireh Swift',
  'Jishou University',
  "Jissen Women's University",
  'Jiwaji University',
  'Jj Hill Montessori',
  'Jj Smith Elementary',
  'Jjaep',
  'Jjaep Campus',
  'Jjaep Disciplinary School',
  'Jjaep Instructional',
  'Jjaep-southwest Key Program',
  'Jkl Bahweting Anishnabe School',
  'Jm Langston Focus',
  'Jm Rapport School For Career Development',
  'Jo Ann Ford El',
  'Jo Brighton Skills Center',
  'Jo Byrns Elementary School',
  'Jo Byrns High School',
  'Jo Kelly Sp Ed',
  'Jo Mackey Elementary School',
  'Jo Nelson Middle',
  'Joan Austin Elementary School',
  'Joan C Sergent Instructional Resource Center',
  'Joan Link El',
  'Joan Macqueen Middle',
  'Joan Martin Elementary School',
  'Joann A. Alexie Memorial School',
  'Joann Bridges Academy',
  'Joann Leleck At Broad Acres Elementary',
  'Joann Walters Elementary Sch',
  'Joanna Connell Sch',
  'Joanna-woodson Elementary',
  'Joaquin Cigarroa Middle',
  'Joaquin El',
  'Joaquin Ferran',
  'Joaquin H S',
  'Joaquin J H School',
  'Joaquin Miller Career And Transition Center',
  'Joaquin Miller Elementary',
  'Joaquin Miller Middle',
  'Joaquin Moraga Intermediate',
  'Joaquin Rodriguez Ruiz',
  'Jobos',
  'Jobu University',
  'Jockey Hollow School',
  'Jodhpur National University',
  'Jody Richards Elementary',
  'Joe Baca',
  'Joe Barnes Vocational Center',
  'Joe Carlson Elementary School',
  'Joe D. Esther Elem.',
  'Joe Dale Sparks Campus',
  'Joe Hall Elementary School',
  'Joe Hamilton Elementary',
  'Joe Harrison Carter',
  'Joe Henderson Elementary',
  'Joe Hubenak El',
  'Joe K Bryant El',
  'Joe Lee Johnson El',
  'Joe M Gillmore Elementary School',
  'Joe M Pirtle El',
  'Joe Mathias Elementary School',
  'Joe Michell',
  'Joe Nightingale Elementary',
  'Joe Redington Senior Jr/sr High School',
  'Joe Serna Jr. Charter',
  'Joe Shafer Middle School',
  'Joe Shoemaker School',
  'Joe Stanley Smith Elementary',
  'Joe Stefani',
  'Joe T. Robinson Elem. School',
  'Joe T. Robinson High School',
  'Joe T. Robinson Middle School',
  'Joe Toler-oak Hill Elementary',
  'Joe V Hart El',
  'Joe Walker El Sch',
  'Joe Walker Middle',
  'Joe Wright El',
  'Joel Barlow High School',
  'Joel E. Barber Elem.',
  'Joel E. Smilow Prep',
  'Joel J. Hidahl Elementary',
  'Joel P. Jensen Middle',
  'Joella Exley El',
  'Joella Good Elementary School',
  'Joelton Elementary',
  'Joelton Middle',
  'Joetsu University of Education',
  'Johann Wolfgang Goethe Universität Frankfurt am Main',
  'Johanna Boss High',
  'Johanna Echols-hansen High (continuation)',
  'Johanna Odonnell Int',
  'Johanna Perrin Middle School',
  'Johannes-Gutenberg Universität Mainz',
  'Johannesburglewiston Elementarymiddle School',
  'Johannesburglewiston High School',
  'John & Carol Carlson Elementary',
  'John & Olive Hinojosa El',
  'John A Baker',
  'John A Bannes Elem School',
  'John A Bishop',
  'John A Dubiski Career H S',
  'John A Gupton College',
  'John A Holmes High',
  'John A Logan College',
  'John A Renfro Elementary School',
  'John A Sciole Elementary School',
  'John A Sippel El',
  'John A. Carusi Middle School',
  'John A. Crisafulli Elementary School',
  'John A. Evans Middle',
  'John A. Ferguson Senior High',
  'John A. Forrest Elementary School',
  'John A. Otis Elementary',
  'John A. Rowland High',
  'John A. Sutter Middle',
  'John A.johnson Achievement Plus El.',
  'John Adams',
  'John Adams #4',
  'John Adams Academy',
  'John Adams Academy - El Dorado Hills',
  'John Adams Academy - Lincoln',
  'John Adams College & Career Academy',
  'John Adams Early Childhood Learning Center',
  'John Adams Elementary',
  'John Adams Elementary School',
  'John Adams High School',
  'John Adams Middle',
  'John Adams Middle School',
  'John Allen School',
  'John And Jacquelyn Miller Elementary',
  'John And Karen Litz Preschool',
  'John And Lynn Brawner Int',
  'John And Polly Townley El',
  'John And Shamarion Barber Middle',
  'John Archer School',
  'John Arredondo El',
  'John Ashley',
  'John Avery Parker',
  'John B Alexander H S',
  'John B Connally H S',
  'John B Devalles',
  'John B Wright Elementary School',
  'John B. Allard',
  'John B. Cary Elementary',
  'John B. Dey Elementary',
  'John B. Lange Middle',
  'John B. Monlux Elementary',
  'John B. Riebli Elementary',
  'John B. Sliney School',
  'John B. Stanton School',
  'John B. Stetson Charter School',
  'John B. Whitsitt Elementary',
  'John Baker Elementary',
  'John Baldwin Elementary',
  'John Ball Park Zoo School',
  'John Barrett Middle',
  'John Barry School',
  'John Beck El Sch',
  'John Bidwell Elementary',
  'John Blacow Elementary',
  'John Boise Middle',
  'John Bowne High School',
  'John Brainerd Elementary School',
  'John Breen School',
  'John Brown Elementary School',
  'John Brown University',
  'John Burroughs Middle',
  'John C Birdlebough High School',
  'John C Burke Correctional Center',
  'John C Calhoun State Community College',
  'John C Dempsey Middle School',
  'John C Fremont Middle School',
  'John C Fremont Ms',
  'John C Magnus Center',
  'John C Martinez Elementary',
  'John C Tayloe Elementary',
  'John C Webb El',
  'John C. Bass Elementary School',
  'John C. Calhoun Elementary',
  'John C. Daniels',
  'John C. Fremont Elementary',
  'John C. Fremont School',
  'John C. Fremont Senior High',
  'John C. Kimball High',
  'John C. Milanesi Elementary School',
  'John C. Myers Elementary',
  'John C. Schiffer Collaborative School',
  'John Cabot University',
  'John Cabrillo Elementary',
  'John Campbell Primary School',
  'John Cary Early Childhood Ctr.',
  'John Champe High School',
  'John Chavis Middle School',
  'John Clancy/joseph Maggiore Elementary School',
  'John Clem Elementary School',
  'John Cline Elementary School',
  'John Colemon Elementary',
  'John D Hardy',
  'John D Mc Govern Elementary',
  'John D Moeller Es',
  'John D Philbrick',
  'John D Pierce Middle School',
  'John D Runkle',
  'John D Spicer El',
  'John D. ?bud? Hawk Elementary At Jackson Park',
  'John D. Floyd Elementary School',
  'John D. Perkins Academy',
  'John D. Rockefeller Career Center',
  'John D. Sloat Elementary',
  'John De La Howe School',
  'John Deere Middle School',
  'John Dewey Academy Of Learning',
  'John Dewey Elementary School',
  'John Dewey High School',
  'John Diemer Elem',
  'John Doedyns El',
  'John Dolland Elementary',
  'John Dooley Elementary School',
  'John Drugan School',
  'John E Albright Middle School',
  'John E Burke',
  'John E Gregg Elementary School',
  'John E Joy Elementary School',
  'John E Pound Early Childhood Center',
  'John E Riley Elementary School',
  'John E White Elementary School',
  'John E. Dwyer Technology Academy',
  'John E. Ewing Middle',
  'John E. Ford K-8 School',
  'John E. Mccarthy',
  'John E. Steinbeck Elementary',
  'John Early Middle',
  'John Edwards High',
  'John Edwards Middle',
  'John Ehret High School',
  'John Ehrhardt Elementary',
  'John Eliot',
  'John Ericsson Middle School 126',
  'John F Farrellemmett Elementary School',
  'John F Hughes Elementary School',
  'John F Kennedy',
  'John F Kennedy Day School',
  'John F Kennedy El',
  'John F Kennedy Elem',
  'John F Kennedy Elem School',
  'John F Kennedy Elementary',
  'John F Kennedy Elementary School',
  'John F Kennedy H S',
  'John F Kennedy High School',
  'John F Kennedy Intermediate School',
  'John F Kennedy Jr School',
  'John F Kennedy Learning Center',
  'John F Kennedy Magnet School',
  'John F Kennedy Memorial Hi School',
  'John F Kennedy Memorial School',
  'John F Kennedy Middle',
  'John F Kennedy Middle Sch',
  'John F Kennedy Middle School',
  'John F Kennedy Primary School',
  'John F Kennedy School',
  'John F Kennedy Senior High School',
  'John F Mendoza Elementary School',
  'John F Miller School',
  'John F Parker Middle',
  'John F Peeler El',
  'John F Ward El',
  'John F. Cruikshank Jr.',
  'John F. Deering Middle',
  'John F. Horgan School',
  'John F. Kennedy',
  'John F. Kennedy #7',
  'John F. Kennedy Elementary',
  'John F. Kennedy Elementary - 57',
  'John F. Kennedy Elementary School',
  'John F. Kennedy High',
  'John F. Kennedy High School',
  'John F. Kennedy Jr High',
  'John F. Kennedy Junior High',
  'John F. Kennedy Middle',
  'John F. Kennedy Middle School',
  'John F. Kennedy School',
  'John F. Kennedy University',
  'John F. Land',
  'John F. Long',
  'John F. Pattie Sr. Elementary',
  'John F. Ryan',
  'John F. Scarpa Technical Education Center Of Cumberland Coun',
  'John F. Turner Senior Elementary School',
  'John Fenwick Academy',
  'John Finney High (continuation)',
  'John Fiske Elem',
  'John Foster Dulles Elementary',
  'John Foster Dulles Elementary School',
  'John Fredson School',
  'John Fremont Elementary',
  'John G Borden Middle School',
  'John G Carlisle Elementary',
  'John G Conyers Learning Academy',
  'John G Dinkelmeyer School',
  'John G Hardin El',
  'John G Riley Elementary School',
  'John G Tower El',
  'John G Whittier',
  'John G Whittier #2',
  'John G. Dupuis Elementary School',
  'John G. Mattos Elementary',
  'John Gates Elem School',
  'John Gill Elementary',
  'John Glenn El',
  'John Glenn Elem.',
  'John Glenn Elementary School',
  'John Glenn Es',
  'John Glenn High School',
  'John Glenn Middle',
  'John Glenn Middle School Of International Studies',
  'John Gomes Elementary',
  'John Green Elementary',
  'John Greenleaf Whittier Elementary School',
  'John Greer Elem School',
  'John H Castle Elementary School',
  'John H Duval',
  'John H Hinds Career Center',
  'John H Kirby El',
  'John H Reagan El',
  'John H Shary El',
  'John H West School',
  'John H. Bayne Elementary',
  'John H. Eader Elementary',
  'John H. Francis Polytechnic',
  'John H. Fuller School',
  'John H. Glenn High',
  'John H. Glenn School',
  'John H. Liechty Middle',
  'John H. Nuffer Elementary',
  'John H. Pitman High',
  'John H. Poole Middle',
  'John H. Still',
  'John H. Walker Middle School',
  'John H. Winslow Elementary School',
  'John H. Woodson Junior High School',
  'John Hancock Charter School',
  'John Hancock Demonstration Sch',
  'John Handley High School',
  'John Hanson Middle School',
  'John Hanson Montessori',
  'John Hardin High School',
  'John Harris Elementary - 23',
  'John Hay Elementary',
  'John Hay Elementary School',
  'John Henry High',
  'John Hersey High School',
  'John Hill School',
  'John Hoeven Elementary School',
  'John Hope Continuation',
  'John Hopkins Elementary School',
  'John Hopkins Middle School',
  'John Humbird Elementary',
  'John Hustis Elementary',
  'John Hydock Elementary School',
  'John I Leonard Adult Education Center',
  'John I Meister Elementary School',
  'John I. Dawes Early Learning Center',
  'John I. Leonard High School',
  'John I. Smith K-8 Center',
  'John Ireland El',
  'John J Ahern',
  'John J Blair Elementary',
  'John J Ciavarra El',
  'John J Daly Elementary School',
  'John J Doran',
  'John J Duggan Middle',
  'John J Lukancic Middle Sch',
  'John J Mcglynn Elementary School',
  'John J Pershing El',
  'John J Shaughnessy',
  'John J Young Middle School',
  'John J. Cairns Continuation',
  'John J. Cornwell',
  'John J. Doyle Elementary',
  'John J. Johnson Ii Elementary School',
  'John J. Mcglynn Middle School',
  'John J. Mclaughlin Cumberland',
  'John J. Montgomery Elementary',
  'John J. Pershing Elementary',
  'John J. Wright Educational And Cultural Center',
  'John Jacobs Elementary School',
  'John Jay Elem School',
  'John Jay High School',
  'John Jay Middle School',
  'John Jay School For Law',
  'John Jay Senior High School',
  'John Johnson Elementary School',
  'John K Tarbox',
  'John K. Hubbard Es',
  'John Kelley Elementary',
  'John Kennedy Elementary School',
  'John Kennedy Middle Grade School',
  'John Kennedy School',
  'John Kerr Elementary',
  'John L Edwards Primary School',
  'John L Leflore Magnet School',
  'John L Mcculloch Junior High Sch',
  'John L Nash Middle School',
  'John L Sipley Elem School',
  'John L. Costley Middle School',
  'John L. Golden Elementary',
  'John L. Hurt Elementary',
  'John L. Ory Communications Magnet Elementary',
  'John L. Prueitt Elementary',
  'John Laidlaw Elem School',
  'John Leslie Patton Jr Academic Center',
  'John Lewis Childs School',
  'John Lewis Invictus Academy',
  'John Long Middle',
  'John Love Elementary School',
  'John Lyman School',
  'John M Andersen Elementary School',
  'John M Andersen Jr High School',
  'John M Barnes Elem School',
  'John M Marshall Elementary School',
  'John M Morehead High',
  'John M Tidwell Middle',
  'John M Tobin',
  'John M. Bailey Community School',
  'John M. Gandy Elementary',
  'John M. Horner Junior High',
  'John M. Sexton Elementary School',
  'John M. Stumbo Elementary School',
  'John Malcom Elementary',
  'John Mall High School',
  'John Marshal School No. 20',
  'John Marshall Adult Prep',
  'John Marshall Community High Sch',
  'John Marshall Early Childhood Center',
  'John Marshall Elementary',
  'John Marshall Elementary School',
  'John Marshall High',
  'John Marshall High School',
  'John Marshall Hs',
  'John Marshall Middle',
  'John Marshall Ms',
  'John Marshall School Of Business And Civic Leadership',
  'John Marshall School Of Engineering',
  'John Marshall School Of Information Technology',
  'John Marshall Senior High',
  'John Marshall Upper Elementary School',
  'John Mccandless Charter',
  'John Mcintire Elementary School',
  'John Mckeever El',
  'John Mcloughlin Elementary School',
  'John Metcalf Middle School',
  'John Middleton Elem School',
  'John Mills Elem School',
  'John Monteith Elementary School',
  'John Morse Therapeutic Center',
  'John Muir',
  'John Muir Charter',
  'John Muir Elementary',
  'John Muir Elementary School',
  'John Muir Fundamental Elementary',
  'John Muir High',
  'John Muir Literacy Academy',
  'John Muir Middle',
  'John Mullan Elementary School',
  'John Murdy Elementary',
  'John N. Dalton Intermediate',
  'John Neely Bryan El',
  'John Nelson Elem School',
  'John Newbery Elementary',
  'John Nowlin Elem.',
  'John O. Tynes Elementary',
  'John Overton High',
  'John P Ojeda Middle',
  'John P Oldham',
  'John P Parker Elementary School',
  'John P Powell Middle School',
  'John P. Faber Elementary School',
  'John P. Holland Charter School',
  'John P. Stevens High School',
  'John P. Woods Elem. School',
  'John Paterson School',
  'John Paulding School',
  'John Pearl Elementary School',
  'John Philip Sousa Elementary School',
  'John Pittard Elementary',
  'John Polk Alternative School',
  'John Powers Center',
  'John Q Adams El',
  'John Q Thomas Elementary School',
  'John Q. Adams Middle School',
  'John Quincy Adams Primary School',
  'John R Beatty Elementary School',
  'John R Bonfield El Sch',
  'John R Davis School',
  'John R Fausey',
  'John R Griffin Middle',
  'John R Hummel Elementary School',
  'John R Kment Elementary School',
  'John R Lawrence Elementary',
  'John R Lea Middle School',
  'John R Mott Jr/hi School',
  'John R Roach',
  'John R Rodger Elementary School',
  'John R Tibbott Elem School',
  'John R. Peterson Elementary',
  'John R. Williams',
  'John R. Wooden High',
  'John R. Wooden Middle School',
  'John Randolph Tucker High',
  'John Read Middle School',
  'John Redd Smith Elementary',
  'John Reed Primary',
  'John Reith Elementary',
  'John Ridgeway Elem.',
  'John Robert Lewis Elementary School',
  'John Rogers Elementary School',
  'John Rolfe Middle',
  'John Ross Es',
  'John Ruhrah Elementary',
  'John S Clark Elem School',
  'John S Hobart Elementary School',
  'John S Irwin Elementary School',
  'John S Jones Elementary School',
  'John S Park Elementary School',
  'John S. Battle High',
  'John S. Charlton School',
  'John S. Martinez Sea And Sky Stem School',
  'John S. Wash Elementary',
  'John Sager Middle School',
  'John Schroder Early Childhood Ctr',
  'John Sedgwick Middle School',
  'John Sells Middle School',
  'John Sevier Elementary',
  'John Sevier Middle School',
  'John Shaw Elementary',
  'John Simatovich Elementary School',
  'John Sinnott Elementary',
  'John Small Elementary',
  'John Snively Elementary',
  'John Stallings Elementary',
  'John Stanford International School',
  'John Stark Regional High School',
  'John Steinbeck Elementary',
  'John Stockton Elementary School',
  'John Story Jenks Academy For The Arts An',
  'John Strange Elementary School',
  'John Street School',
  'John Sutter Middle',
  'John Swett Elementary',
  'John Swett High',
  'John T Hoggard High',
  'John T Reid Middle',
  'John T Waugh Elementary School',
  'John T White El',
  'John T. Baker Middle School',
  'John T. Hartman Elem.',
  'John T. Nichols Middle',
  'John Tartan Elementary School',
  'John Thomas School Of Discover',
  'John Trotwood Moore Middle',
  'John Trumbull Primary School',
  'John Tuck Elementary School',
  'John Tyler Community College',
  'John Tyler Elementary',
  'John Tyler H S',
  'John Tyson Elementary School',
  'John V Evans Elementary',
  'John V Lindsay Wildcat Academy Charter School',
  'John Van Lindley Elementary',
  'John Vanderburg Elementary School',
  'John Vierra High',
  'John W And Margie Stipes El',
  'John W Armstrong El',
  'John W Arndt El',
  'John W Bonner Elementary School',
  'John W Carpenter El',
  'John W Dillard Elementary',
  'John W Dodd Middle School',
  'John W Jones Elementary',
  'John W Kendall Elem School',
  'John W Lavelle Preparatory Charter School',
  'John W Mccormack',
  'John W Rex Charter Elementary',
  'John W Rogers Middle',
  'John W Runyon El',
  'John W Thimmig Elementary School',
  'John W. Bate Middle School',
  'John W. Harris',
  'John W. Luff Elem.',
  'John W. Mack Elementary',
  'John W. Mcdevitt Middle School',
  'John W. Moore Middle',
  'John W. North High',
  'John W. Reiley Elementary School',
  'John W. Tolbert Jr. Elementary',
  'John W. Wayland Elementary',
  'John W. Wynn Middle',
  'John Wallace Middle School',
  'John Ward',
  'John Weldon Elem.',
  'John Wesley Powell Middle School',
  'John Wetten Elementary School',
  'John Will Elementary School',
  'John William Decas',
  'John Winship El',
  'John Winthrop',
  'John Winthrop Middle School',
  'John Winthrop School',
  'John Witherspoon Middle School',
  'John Wood Community College',
  'John Wood Elementary School',
  'John Y Dater Elementary School',
  'John Yeates Middle',
  'John Young Elementary',
  'Johnakin Middle',
  'Johnnie Cochran Jr. Middle',
  'Johnnie John Sr. School',
  'Johnnie L. Cochran Jr. Academy',
  'Johnny Appleseed',
  'Johnny E. Laboy Torres',
  'Johnny Gray Jones Youth Shelter & Detention Center',
  'Johnny N Allen-6th Grade Campus',
  'Johnny Oldman School',
  'Johnnycake Corners Elementary School',
  'Johnnycake Elementary',
  'Johns Creek Elementary',
  'Johns Creek Elementary School',
  'Johns Creek High School',
  'Johns El',
  'Johns Hill Magnet School',
  'Johns Hopkins University',
  'Johns Hopkins University Applied Physics Laboratory',
  'Johns Hopkins University SAIS Bologna Center',
  'Johnsburg Central School',
  'Johnsburg Elementary School',
  'Johnsburg High School',
  'Johnsburg Jr High School',
  'Johnson',
  'Johnson & Wales University',
  'Johnson Alternative Learning Center',
  'Johnson Bayou High School',
  'Johnson Bible College',
  'Johnson C. Smith University',
  'Johnson Central High School',
  'Johnson City Elementary/intermediate School',
  'Johnson City Elementary/primary School',
  'Johnson City Middle School',
  'Johnson City Senior High School',
  'Johnson Co Central El-tecumseh',
  'Johnson Co Central Elem-cook',
  'Johnson Co Central High School',
  'Johnson Co Central Middle Sch',
  'Johnson Co High School',
  'Johnson Co Middle School',
  'Johnson County Alternative School',
  'Johnson County Community College',
  'Johnson County Elementary School',
  'Johnson County High School',
  'Johnson County J J A E P',
  'Johnson County Jjaep',
  'Johnson County Middle School',
  'Johnson Creek Elementary',
  'Johnson Creek Middle And High',
  'Johnson Crossing Academic Center (5-6)',
  'Johnson Early Childhood Center',
  'Johnson El',
  'Johnson Elem School',
  'Johnson Elem.',
  'Johnson Elementary',
  'Johnson Elementary School',
  'Johnson Es',
  'Johnson H S',
  'Johnson High School',
  'Johnson House Steps 3-6',
  'Johnson Junior High School',
  'Johnson Magnet',
  'Johnson Middle',
  'Johnson Ms',
  'Johnson Park Elementary',
  'Johnson Park Middle School',
  'Johnson Park School',
  'Johnson Preschool',
  'Johnson Ranch El',
  'Johnson Senior High',
  'Johnson Street Global Studies',
  'Johnson Traditional Middle',
  'Johnson Upper Elementary School',
  'Johnson Wabash Elem.',
  'Johnson Youth Center',
  'Johnson-williams Middle',
  'Johnsonburg Area El Sch',
  'Johnsonburg Area Hs',
  'Johnsontown Road Elementary',
  'Johnsonville Elem School',
  'Johnsonville Elementary',
  'Johnsonville High',
  'Johnsonville Middle',
  'Johnston Charter Academy',
  'Johnston City High School',
  'Johnston Co Early College Academy',
  'Johnston Co Middle College',
  'Johnston Community College',
  'Johnston Cooper Elementary',
  'Johnston County Schools Career Technical',
  'Johnston El',
  'Johnston Elementary',
  'Johnston Elementary School',
  'Johnston Hopkins Elementary School',
  'Johnston Middle School',
  'Johnston Senior High',
  'Johnston Senior High School',
  'Johnston Square Elementary',
  'Johnston-edgefield-trenton Middle',
  'Johnston-mcqueen El',
  'Johnstone Elementary School',
  'Johnstonville Elementary',
  'Johnstown Elementary School',
  'Johnstown High School',
  'Johnstown Senior High School',
  'Johnsville Elementary',
  'Johore Bharu Primeir Polytechnic',
  'Joli Ann Leichtag Elementary',
  'Joliet 7-8',
  'Joliet Central High School',
  'Joliet High School',
  'Joliet Junior College',
  'Joliet School',
  'Joliet Twp High Sch - Alternate',
  'Joliet West High School',
  'Jolley Elementary - 04',
  'Jolliff Middle',
  'Jolly Elementary School',
  'Jollyville El',
  'Jomo Kenyatta University of Agriculture and Technology',
  'Jonas Bronck Academy',
  'Jonas Clarke Middle',
  'Jonas E Salk Elem School',
  'Jonas E Salk Elementary School',
  'Jonas E Salk Middle School',
  'Jonas E. Salk',
  'Jonas Salk Elementary',
  'Jonas Salk Middle School',
  'Jonata Middle',
  'Jonathan Alder High School',
  'Jonathan Alder Junior High',
  'Jonathan Daniels School',
  'Jonathan Dayton High School',
  'Jonathan Elementary',
  'Jonathan Elementary School',
  'Jonathan Hager Elementary',
  'Jonathan Jennings Elementary Sch',
  'Jonathan Jennings School 109',
  'Jonathan Law High School',
  'Jonathan Valley Elementary',
  'Jones (albert H.) Elementary School',
  'Jones Academy',
  'Jones Co Career & Tech Ed Center',
  'Jones College',
  'Jones College Prep High School',
  'Jones County Elementary - 02',
  'Jones County High School',
  'Jones County High School - 01',
  'Jones County Junior College',
  'Jones County Learning Center',
  'Jones County Middle School - 04',
  'Jones Cove Elementary',
  'Jones Dairy Elementary',
  'Jones Ec/pre-k/kg Center',
  'Jones El',
  'Jones Elementary',
  'Jones Elementary School',
  'Jones Elementary/intermediate School',
  'Jones Es',
  'Jones Farrar Magnet School',
  'Jones Fine Arts / Dual Language Academy',
  'Jones Fork Elementary School',
  'Jones Futures Academy',
  'Jones High',
  'Jones Hs',
  'Jones Intermediate',
  'Jones Lane Elementary',
  'Jones Leadership Academy',
  'Jones Middle',
  'Jones Middle School',
  'Jones Ms',
  'Jones Park Elementary',
  'Jones Senior High',
  'Jones Valley Elementary School',
  'Jones Valley Middle School',
  'Jones-wheat Elementary School',
  'Jonesboro Elem School',
  'Jonesboro Elementary School',
  'Jonesboro High School',
  'Jonesboro Middle School',
  'Jonesboro School',
  'Jonesboro Vocational Center',
  'Jonesboro-hodge Elementary School',
  'Jonesboro-hodge High School',
  'Jonesboro-hodge Middle School',
  'Jonesborough Elementary',
  'Jonesborough Middle School',
  'Jonesburg Elem.',
  'Jonesport Elementary School',
  'Jonesport-beals High School',
  'Jonestown El Sch',
  'Jonestown Elementary School',
  'Jonesville Elementary',
  'Jonesville Elementary Middle',
  'Jonesville Elementary School',
  'Jonesville High School',
  'Jonesville Junior High School',
  'Jonesville Middle',
  'Jonesville Middle School',
  'Jonesville Pathways',
  'Joplin Early Childhood Ctr.',
  'Joplin Elem School',
  'Joplin Elementary School',
  'Joplin High',
  'Joppa Elementary',
  'Joppa Jr & Sr High School',
  'Joppa View Elementary',
  'Joppatowne Elementary',
  'Joppatowne High',
  'Jordahl Elementary School',
  'Jordan (june) School For Equity',
  'Jordan 7-8',
  'Jordan Academy For Technology & Careers South',
  'Jordan Academy For Technology And Careers - North',
  'Jordan Academy of Music / Higher Institute of Music',
  'Jordan Bank Sch',
  'Jordan Center For Early Education',
  'Jordan Creek Elementary School',
  'Jordan Early Childhood',
  'Jordan El',
  'Jordan Elem Community School',
  'Jordan Elem School',
  'Jordan Elementary',
  'Jordan Elementary School',
  'Jordan H S',
  'Jordan High',
  'Jordan Hill Road Elementary School',
  'Jordan Hills School',
  'Jordan Matthews High',
  'Jordan Middle',
  'Jordan Middle School',
  'Jordan Ridge School',
  'Jordan Road Elementary School',
  'Jordan Secondary',
  'Jordan Secondary Learning Center',
  'Jordan University of Science and Technology',
  'Jordan Valley Elementary School',
  'Jordan Valley High School',
  'Jordan Valley School',
  'Jordan Vocational High School',
  'Jordan-elbridge High School',
  'Jordan-elbridge Middle School',
  'Jordan-small Middle School',
  'Jordan/jackson Elementary',
  'Jorge Mas Canosa Middle School',
  'Jorge R Gutierrez Early Childhood Center',
  'Jorge Rosario Del Valle',
  'Jorge Seda Crespo',
  'Jorge Washington Ii',
  'Jornada Elementary',
  'Josai International University',
  'Josai University',
  "Jose 'joe' May El",
  'Jose A. Castillo',
  'Jose A. Gonzalez',
  'Jose A. Lopez Castro',
  'Jose A. Montanez Genaro (voc. Area)',
  'Jose A. Nieves',
  'Jose Acevedo Alvarez',
  'Jose Andino Y Amezquita',
  'Jose Antonio Navarro El',
  'Jose Barrios Elementary',
  'Jose Bernabe Munoz El',
  'Jose Borrego Middle',
  'Jose C. Rosario',
  'Jose Calzada Ferrer',
  'Jose Campeche',
  'Jose Collazo Colon',
  'Jose Colomban Rosario',
  'Jose Colon Gonzalez',
  'Jose Cordero Rosario',
  'Jose D. Rosado (bajura Almirante)',
  'Jose Davila Semprit',
  'Jose De Choudens',
  'Jose De Diego',
  'Jose De Diego Middle School',
  'Jose De Escandon El',
  'Jose De Jesus And Maria Luisa Vega El',
  'Jose Emilio Lugo',
  'Jose F. Cintron Y Anexo',
  'Jose F. Diaz',
  'Jose Felipe Zayas',
  'Jose Fernandez Rubial',
  'Jose Gautier Benitez',
  'Jose Gonzalez Ruiz',
  'Jose Gualberto Padilla',
  'Jose H Damian El',
  'Jose Horacio Cora',
  'Jose J Alderete Middle',
  'Jose Julian Acosta',
  'Jose Julian Acosta (teatro)',
  'Jose Julian Tapia De La Rosa',
  'Jose M Espada Zayas',
  'Jose M. Hernandez',
  'Jose M. Massari',
  'Jose M. Rivera Solis',
  'Jose M. Torres',
  'Jose Maria Del Valle',
  'Jose Marti Freshman Academy',
  'Jose Marti Mast 6-12 Academy',
  'Jose Mercado (especializada)',
  'Jose Munoz Vazquez',
  'Jose Nevarez Lopez',
  'Jose Onofre Torres Fermoso',
  'Jose Pablo Morales',
  'Jose Padin',
  'Jose Pagan De Jesus (elemental Bo Sabana Hoyos)',
  'Jose R. Agosto',
  'Jose R. Gaztambide',
  'Jose Ramon Rodriguez',
  'Jose Rios Middle School',
  'Jose Robles Otero',
  'Jose Rodriguez De Soto',
  'Jose Rojas Cortes',
  'Jose Santos Alegria',
  'Jose Santos Quinonez',
  'Jose Sepulveda Elementary',
  'Jose Severo Quinones',
  'Jose Vizcarrondo (su Angeles)',
  'Josefa Del Rio Guerrero',
  'Josefa L Sambrano El',
  'Josefa Velez Bauza (superior Urbana)',
  'Josefina Barcelo',
  'Josefina Boya Leon',
  'Josefina Ferrero',
  'Josefina Leon Zayas',
  'Josefina Linares',
  'Josefina Marrero Febus (lomas Valles)',
  'Josefina Munoz De Bernier',
  'Josefita Monserrate De Selles',
  'Joseph A Campbell Elementary',
  'Joseph A Edgar Imtermediate School',
  'Joseph A. Browne School',
  'Joseph A. Citta Elementary School',
  'Joseph A. Craig Charter School',
  'Joseph A. Cuillier Sr. Career Center',
  'Joseph A. Depaolo Middle School',
  'Joseph A. Foran High School',
  'Joseph A. Gascon Elementary',
  'Joseph A. Gregori High',
  'Joseph A. Whelan School',
  'Joseph Arnold Elementary',
  'Joseph Arthur Middle School',
  'Joseph Ayo Babalola University',
  'Joseph Azevada Elementary',
  'Joseph B Radez Elementary School',
  'Joseph B. Johnson Learning Center',
  'Joseph B. Lancaster Elementary School',
  'Joseph Block Middle School',
  'Joseph C Ashkar El Sch',
  'Joseph C Chamberlain',
  'Joseph C Shaner Memorial Elementary School',
  'Joseph C Sterling Elem School',
  'Joseph C Wilson Foundation Academy',
  'Joseph C Wilson Magnet High School',
  'Joseph C. Briscoe Academy',
  'Joseph C. Caruso School',
  'Joseph Case High',
  'Joseph Case Jr High',
  'Joseph Charter School',
  'Joseph City Elementary School',
  'Joseph City High School',
  'Joseph City Junior High School',
  'Joseph Conger Elementary School',
  'Joseph D. Sharp Elementary School',
  'Joseph Demello',
  'Joseph E Ferderbar El Sch',
  'Joseph E Fisher School',
  'Joseph E Fiske',
  'Joseph E Hill Education Center',
  'Joseph E Thiriot Elementary School',
  'Joseph E. Soehl Middle School',
  'Joseph Estabrook',
  'Joseph F Brandt School',
  'Joseph F. Cappello School',
  'Joseph F. Plouffe Academy',
  'Joseph Finegan Elementary School',
  'Joseph G Luther',
  'Joseph G Steenland Elementary School',
  'Joseph Gale Elementary School',
  'Joseph Gaudet Academy',
  'Joseph George Middle',
  'Joseph Gilbert El',
  'Joseph Gomez Elementary School',
  'Joseph H Gibbons',
  'Joseph H Martin',
  'Joseph H. Brensinger School',
  'Joseph H. Gaudet School',
  'Joseph H. Saunders Elementary',
  'Joseph H. Wardlaw Elementary',
  'Joseph Henry Elementary School',
  'Joseph Hess Elementary School',
  'Joseph J Catena School',
  'Joseph J Hurley',
  'Joseph J Rhoads Learning Center',
  'Joseph J. Davies Elementary School',
  'Joseph Jenks Middle School',
  'Joseph K Lumsden Bahweting Anishnabe Academy',
  'Joseph Keels Elementary',
  'Joseph Kerr Middle',
  'Joseph L Bowler Sr Elementary School',
  'Joseph L Frazier Elementary',
  'Joseph L. Carwise Middle School',
  'Joseph L. Mccourt Ms',
  'Joseph L. Mudd Elem.',
  'Joseph Lane Middle School',
  'Joseph Le Conte Middle',
  'Joseph Lee',
  'Joseph M Carkenord Elementary School',
  'Joseph M Gallagher School',
  'Joseph M Neal Elementary School',
  'Joseph M. Ferraina Early Childhood Learning Center',
  'Joseph M. Simas',
  'Joseph Martin Elementary School',
  'Joseph Mcavinnue',
  'Joseph Melillo Middle School',
  'Joseph Metcalf School',
  'Joseph Nicollet Middle School',
  'Joseph O. Goodwin School',
  'Joseph Osgood',
  'Joseph P Keefe Technical High School',
  'Joseph P Manning',
  'Joseph P Tynan',
  'Joseph P. King Jr. Middle',
  'Joseph Pomeroy Widney Career Prep And Transition Center',
  'Joseph R Dawe Jr Elementary',
  'Joseph R. Bolger Middle School',
  'Joseph R. Perry Elementary',
  'Joseph R. Pye Elementary',
  'Joseph Rogers Primary School',
  'Joseph S. & Olinga Gregory Elementary',
  'Joseph S. Clark Preparatory High School',
  'Joseph S. Shanklin Elementary',
  'Joseph S. Stackhouse School',
  'Joseph Sibilly Elementary School',
  'Joseph Sims Elementary',
  'Joseph Stilwell Middle School',
  'Joseph T. Donahue Elementary School',
  'Joseph T. Henley Middle',
  'Joseph Van Pelt Elementary',
  'Joseph W Grier Academy',
  'Joseph W Lisenby Primary School',
  'Joseph W Martin Jr Elementary',
  'Joseph Weller Elementary',
  'Joseph Welty Middle School',
  'Joseph Widmer Jr. Elementary',
  'Joseph Williams Elementary School',
  'Joseph Zito Elementary School',
  'Josephine Chrysler Elementary',
  'Josephine Hodgkins Elementary School',
  'Josey High School',
  'Josh Stevens Elementary School',
  'Joshua Academy',
  'Joshua Butler Elementary School',
  'Joshua Chadbourne Elementary',
  'Joshua Circle Elementary',
  'Joshua Cowell Elementary',
  'Joshua Dixon Elementary School',
  'Joshua Eaton',
  'Joshua Elementary',
  'Joshua H S',
  'Joshua H S Ninth Grade Campus',
  'Joshua Hills Elementary',
  'Joshua Tree Elementary',
  'Josiah Bartlett Elementary School',
  'Josiah Harris Elementary School',
  'Josiah Haynes',
  'Josiah Quincy',
  'Joslin El',
  'Joslyn Elem School',
  'Jotham W. Wakeman School',
  'Jouett Elementary',
  'Jourdanton Daep',
  'Jourdanton El',
  'Jourdanton H S',
  'Jourdanton J H',
  'Journalism And Media Academy',
  'Journey',
  'Journey Elementary',
  'Journey Elementary - 05',
  'Journeys Academy',
  'Journeys Alternative Program',
  'Journeys School',
  'Journeys Secondary School',
  'Journeys South',
  'Jowell El',
  'Joy Elementary',
  'Joy Elementary School',
  'Joy James El',
  'Joy Preparatory Academy',
  'Joyce Clark Middle School',
  'Joyce Kilmer',
  'Joyce Kilmer Elem School',
  'Joyce Kilmer Elementary School',
  'Joyce Kilmer Middle School',
  'Joyce Kilmer School',
  'Joyce Kilmer School 69',
  'Joyce M. Bullock Elementary School',
  'Joyner Elementary',
  'Joyner Elementary School',
  'Jp Dabbs Elementary',
  'Jps Montessori Center',
  'Jr-sr High School At Palmyra',
  'Js Clark Leadership Academy',
  'Jt Brackenridge Daep',
  'Juab High',
  'Juab Jr High',
  'Juan A Sanchez',
  'Juan A. Sanchez Davila',
  'Juan Alejo Arizmendi',
  'Juan Antonio Corretjer',
  'Juan B. Huyke',
  'Juan Bautista De Anza',
  'Juan Bautista De Anza Charter',
  'Juan C. Pagan',
  'Juan Cabrillo Elementary',
  'Juan Cabrillo Middle',
  'Juan Cuevas Aboy',
  'Juan De Anza Elementary',
  'Juan De Dios Quinones',
  'Juan De Dios Salinas Middle',
  'Juan De Onate Elementary',
  'Juan E. Miranda',
  'Juan I. Vega',
  'Juan Jose Maunez',
  'Juan Jose Osuna (espsecializada)',
  'Juan L. Santiago',
  'Juan Lagunas Soria Elementary',
  'Juan M. Guerrero Elementary School',
  'Juan Morel Campos Secondary School',
  'Juan Morell Campos',
  'Juan Morell Campos (musica )',
  'Juan Morell Campos Elemental',
  'Juan N Seguin El',
  'Juan Navarro',
  'Juan Pablo Duarte - Jose Julian Marti School No 28',
  'Juan Ponce De Leon',
  'Juan Ponce De Leon (especializada)',
  'Juan Ponce De Leon Ii',
  'Juan Quirindongo Morell (superior)',
  'Juan Ramon Jimenez',
  'Juan Roman Ocasio',
  'Juan Seguin Early Childhood Center',
  'Juan Seguin El',
  'Juan Serapio Mangual',
  'Juan Suarez Pelegrina (nueva)',
  'Juan Zamora',
  'Juana A Mendez (metropolis)',
  'Juana B Guzman (guacio)',
  'Juana Briones Elementary',
  'Juana Colon',
  'Juana G Aviles (franquez)',
  'Juana Rodriguez Mundo',
  'Juanamaria Elementary',
  'Juanillo Fuentes',
  'Juanita Blakely Jones Elementary',
  'Juanita Elementary',
  'Juanita Garcia Peraza',
  'Juanita Gardine Elementary School',
  'Juanita High',
  'Juanita Ramirez Gonzalez',
  'Juanita Rivera',
  'Juanita Rivera Albert',
  'Juarez (benito) Elementary',
  'Juarez Community Academy Hs',
  'Juarez Elementary',
  'Juarez-lincoln Elementary',
  'Juarez-lincoln H S',
  'Juarez/lincoln El',
  'Jubail Industrial College',
  'Jubail University College',
  'Juban Parc Elementary School',
  'Juban Parc Junior High School',
  'Jubilee - Lake View University Prep',
  'Jubilee Brownsville',
  'Jubilee Harlingen',
  'Jubilee Highland Hills',
  'Jubilee Highland Park',
  'Jubilee Kingsville',
  'Jubilee Leadership Academy',
  'Jubilee Living Way',
  'Jubilee San Antonio',
  'Jubilee Wells Branch',
  'Juda Elementary',
  'Juda High',
  'Judge Barefoot Sanders Law Magnet',
  'Judge Haynes Elementary School',
  'Judge James C Adolphus El',
  'Judge Lionel R. Collins Elementary School',
  'Judge Oscar De La Fuente El',
  'Judge Sylvania W. Woods Sr. Elementary',
  'Judia Jackson Harris Elementary',
  'Judice Middle School',
  'Judith A Resnik Community Learning Center',
  'Judith A Resnik Middle',
  'Judith A Vivas',
  'Judith A. Resnik Elementary',
  'Judith D Steele Elementary School',
  'Judith F. Baca Arts Academy',
  'Judith Gap 7-8',
  'Judith Gap High School',
  'Judith Gap School',
  'Judith Giacoma Elem School',
  'Judith L Harlow El',
  'Judith Morton Johnston Elementary',
  'Judith P. Hoyer Early Childhood Center',
  'Judith P. Hoyer Montessori',
  'Judith S Kaye High School',
  'Judith Zaffirini El',
  'Judkins Middle',
  'Judson & Brown Elementary',
  'Judson B Walker Middle',
  'Judson Care Academy',
  'Judson College',
  'Judson Early College Academy',
  'Judson Fundamental Elementary School',
  'Judson H S',
  'Judson Learning Acad',
  'Judson Middle',
  'Judson Middle School',
  'Judson Steam Academy',
  'Judsonia Elementary School',
  'Judy & John L Goolsby Elementary School',
  'Judy Andrews Second Chance Center',
  'Judy Hajek El',
  'Judy Jacobs Parkway Elementary School',
  'Judy Miller El',
  'Judy Nelson Elementary',
  'Judy Rucker El',
  'Julesburg Elementary School',
  'Julesburg High School',
  'Julia A Traphagen School',
  'Julia A. Barnes School',
  'Julia A. Stark School',
  'Julia B. Maitland School',
  'Julia B. Morrison Elementary',
  'Julia Bird Jones Muller El',
  'Julia Butler Hansen Elementary',
  'Julia C. Lathrop Intermediate',
  'Julia Cordero Negron',
  'Julia De Burgos',
  'Julia F Callahan',
  'Julia Goldstein Early Child.',
  'Julia Green Elementary',
  'Julia Keen Elementary School',
  'Julia Landon College Preparatory & Leadership Development Sc',
  'Julia Lathrop Elem School',
  'Julia Lee Moore Elem. School',
  'Julia Morgan Elementary',
  'Julia P. Bryant Elementary School',
  'Julia Randall Elementary School',
  'Julia S Molloy Education Center',
  'Juliaetta Elementary School',
  'Julian & Marjorie Lange School Elementary School',
  'Julian C Gallardo El',
  'Julian Charter',
  'Julian Curtiss School',
  'Julian E Blanco (ballet)',
  'Julian Elementary',
  'Julian Harris Elementary School',
  'Julian High',
  'Julian High School',
  'Julian Junior High',
  'Julian Newman Elementary School',
  'Julian O Strong Middle School',
  'Julian S Adame',
  'Julian T Saldivar El',
  'Julian Thomas Elementary',
  'Julie Korenstein Elementary',
  'Julien Elementary',
  'Julien Hathaway Elementary',
  'Juliet Morris Elementary',
  'Juliet W. Long School',
  'Juliette Low Elem School',
  'Juliette Low School Of The Arts',
  'Julington Creek Elementary School',
  'Julio Alvarado',
  'Julio Collazo Silva',
  'Julio Lebron Soto',
  'Julio Millan Cepeda',
  'Julio Ressy',
  'Julio Seijo',
  'Julio Selles Sola',
  'Julio Victor Guzman',
  'Julius A Wendt Elementary/john C Thomas Middle School',
  'Julius Corsini Elementary',
  'Julius Dorsey El',
  'Julius E Sprauve',
  'Julius I Foust Elementary',
  'Julius L Matthey Middle',
  'Julius Marks Elementary School',
  'Julius Patrick Elementary School',
  'Julius West Middle',
  'Jumeira University',
  'Jumoke Academy',
  'Jump Start Learning Center',
  'Jumpertown High School',
  'Jumping Branch Elementary School',
  'Junaluska Elementary',
  'Junction City Elem. School',
  'Junction City Elementary',
  'Junction City Elementary School',
  'Junction City High School',
  'Junction City Middle School',
  'Junction City Sr High',
  'Junction El',
  'Junction Elementary',
  'Junction H S',
  'Junction Hill Elem.',
  'Junction K-8',
  'Junction Middle',
  'June Creek Elementary School',
  'June Nelson Elementary',
  'Juneau Community Charter School',
  'Juneau County Charter School',
  'Juneau-douglas High School',
  'Jungman Elem School',
  'Juniata College',
  'Juniata El Sch',
  'Juniata Elementary School',
  'Juniata Gap El Sch',
  'Juniata Park Academy',
  'Juniata Shs',
  'Juniata Valley El Sch',
  'Juniata Valley Jshs',
  'Junior Elementary',
  'Junior-senior High At Sumner',
  'Juniper',
  'Juniper Elementary',
  'Juniper Elementary School',
  'Juniper Hill School',
  'Juniper Ridge Community School',
  'Juniper Ridge Elementary',
  'Junipero Serra Elementary',
  'Junipero Serra High',
  'Junius H Rose High',
  'Juntendo University',
  'Juntura Elementary School',
  'Jupiter Elementary School',
  'Jupiter Farms Elementary School',
  'Jupiter High Adult Education Center',
  'Jupiter High School',
  'Jupiter Middle School',
  'Jurupa Hills High',
  'Jurupa Middle',
  'Jurupa Unified Pre-k Readiness Center',
  'Jurupa Valley High',
  'Jurupa Vista Elementary',
  'Jury Elem.',
  'Just 4 Development Laboratory',
  'Just Arts And Management Charter Middle School',
  'Just Elementary School',
  'Just For Girls',
  'Justice Aw Seeworth Academy Es',
  'Justice Aw Seeworth Academy Hs',
  'Justice Elementary School',
  'Justice High Charter School',
  'Justice Myron E Leavitt Middle School',
  'Justice Page Middle School',
  'Justice Public School',
  'Justice Street Academy Charter',
  'Justice Thurgood Marshall Intrmd',
  'Justin Early Learners Academy',
  'Justin El',
  'Justin F Kimball H S',
  'Justina Vazquez Mendoza',
  'Justine Spitalny School',
  'Justiss El',
  'Justus C. Richardson Middle School',
  'Justus Gage Elementary School',
  'Justus Liebig Universität Gießen',
  'Justus-tiawah Public School',
  'Juvenile Care Center',
  'Juvenile Ctr.',
  'Juvenile Detent Ctr',
  'Juvenile Detention',
  'Juvenile Detention 3-5',
  'Juvenile Detention Center',
  'Juvenile Detention Ctr',
  'Juvenile Detention Ctr.',
  'Juvenile Detention Education Program',
  'Juvenile Female Secure Unit',
  'Juvenile Hall (endeavor/voyager Secondary)',
  'Juvenile Justice Aep',
  'Juvenile Justice Alternative',
  'Juvenile Justice Alternative Ed Program',
  'Juvenile Justice Alternative Ed Program/venus',
  'Juvenile Justice Alternative Education Program',
  'Juvenile Justice Center',
  'Juvenile Justice Center Alt Ed',
  'Juvenile Justice Ctr.',
  'Juvenile Medium Secure Facility',
  'Juvenile Medium Secure Facility South',
  'Juvenile Offenders Program',
  'Juvenile Service Center',
  'Juvenile Services Center',
  'Juvenille Justice Alternative Program',
  'Jw Sexton High School',
  'Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala',
  'Jönköping University College',
  'Jönköping University College of Health Sciences',
  'K Academy',
  'K B Polk Center For Academically Talented & Gifted',
  'K C Heffernan Elementary School',
  'K C J D C',
  'K D Waldo Middle School',
  'K M Smith El Sch',
  'K O Knudson Junior High School',
  'K SCHOOL',
  'K William Harvey Elem',
  'K-12 Ellensburg Learning Center',
  'K-5 Elementary School Lincoln Street Building',
  'K-8 Flva Virtual School Continuity Program',
  'K-8 School Kk',
  'K-8 School Ll',
  'K. E. Cunningham/canal Point Elementary',
  'K. I. Jones Elementary',
  'K. T. Murphy School',
  'K.N.Toosi University of Technology',
  'K.a.s.a. (knowledge And Success Academy)',
  'K.r. Hanchey Elementary School',
  'K12 INTERNATIONAL ACADEMY',
  'KA VEE AMISH SCHOOL',
  "KABBALAH CHILDREN'S ACADEMY",
  'KADIMA DAY SCHOOL',
  'KADIMAH SCHOOL OF BUFFALO',
  "KAHALU'U CHRISTIAN SCHOOL",
  'KAHILI ADVENTIST SCHOOL',
  'KAIMUKI CHRISTIAN SCHOOL',
  'KALAMAZOO CHRISTIAN SCHOOL',
  'KALAMAZOO COUNTRY DAY SCHOOL',
  'KALAMAZOO SDA JUNIOR ACADEMY',
  'KALECK FAMILY CHILD CARE CENTER',
  'KALEIDOSCOPE SCHOOL OF DISCOVERY',
  'KALISPELL MONTESSORI CENTER',
  'KAMEHAMEHA SCHOOLS KAPALAMA CAMPUS',
  'KAMEHAMEHA SCHOOLS MAUI',
  "KAMI'S CUDDLING CENTER",
  'KANAWHA CHRISTIAN SCHOOL',
  'KANGAROO FOREST EARLY CHILDHOOD CENTER',
  'KANGAROO KIDS CHILDCARE & LEARNING CENTER',
  'KANKAKEE TRINITY ACADEMY',
  'KANNER LEARNING CENTER',
  'KANSAS CITY ACADEMY',
  'KANSAS CITY CHRISTIAN SCHOOL',
  'KANTZ MENNONITE PAROCHIAL SCHOOL',
  'KAPAUN MT CARMEL CATHOLIC HIGH SCHOOL',
  'KAPKA COOPERATIVE SCHOOL',
  'KARDIA CHRISTIAN ACADEMY',
  'KASB Institute of Technology',
  'KATHERINE DELMAR BURKE SCHOOL',
  'KATHERINE MICHIELS SCHOOL',
  'KATIE MILLER-WEST MT. HOPE',
  'KATY ADVENTIST CHRISTIAN SCHOOL',
  'KATY GT ACADEMY',
  'KATY MONTESSORI SCHOOL',
  'KATZ HILLEL DAY SCHOOL OF BOCA RATON',
  'KAUAI CHRISTIAN ACADEMY',
  'KAUFMAN CHRISTIAN SCHOOL',
  "KAWAIAHA'O CHURCH SCHOOL",
  'KAZOO SCHOOL',
  'KBU International College',
  'KDI School of Public Policy and Management',
  'KDU College Sdn Bhd',
  'KEARNS-SAINT ANN SCHOOL',
  'KEARNY CHRISTIAN ACADEMY',
  'KEHILLAH EARLY LEARNING CENTER',
  'KEHILLAH JEWISH HIGH SCHOOL',
  'KEHILLAT MOGEN DAVID EDUCATION CENTER',
  'KEHOE - FRANCE NORTHSHORE',
  'KEHOE-FRANCE SCHOOL',
  'KEIO ACADEMY OF NEW YORK',
  'KEITH COUNTRY DAY SCHOOL',
  'KEITH THOMPSON NON PUBLIC SCHOOL',
  'KEITH WOLD JOHNSON CHILD CENTER',
  'KELLENBERG MEMORIAL HIGH SCHOOL',
  'KELLER MONTESSORI SCHOOL',
  'KELLEYTOWN BAPTIST KINDERGARTEN',
  'KELLMAN BROWN ACADEMY',
  'KEMMERER VILLAGE SCHOOL',
  'KEMP MILL MONTORSORRI SCHOOL',
  'KEMPSVILLE ACADEMY INC',
  'KEMPSVILLE CENTER FOR BEHAVIORAL HEALTH',
  'KEMPSVILLE CHILD DEVELOPMENT CENTER',
  'KEMPTON NEW CHURCH SCHOOL',
  'KENDALL CHRISTIAN SCHOOL',
  'KENMONT MONTESSORI SCHOOL',
  'KENNEBEC MONTESSORI SCHOOL',
  'KENNEDY HEIGHTS MONTESSORI CENTER',
  'KENNEDY KRIEGER AT SOUTHERN HIGH SCHOOL',
  'KENNEDY KRIEGER SCHOOL',
  'KENNEDY KRIEGER SCHOOL GREENSPRING CAMPUS',
  'KENNEDY KRIEGER SCHOOL: FAIRMOUNT CAMPUS',
  'KENNEDY-DONOVAN CENTER SCHOOL',
  'KENNETT CHRISTIAN ACADEMY',
  'KENOSHA MONTESSORI SCHOOL',
  'KENSINGTON SCHOOL OF LAGRANGE',
  'KENSTON FOREST SCHOOL',
  'KENT ACADEMY',
  'KENT DENVER SCHOOL',
  'KENT PLACE SCHOOL',
  'KENT SCHOOL',
  'KENTON MENNONITE SCHOOL',
  'KENTS HILL SCHOOL',
  'KENTUCKY CHRISTIAN ACADEMY',
  'KENTUCKY COUNTRY DAY SCHOOL',
  'KENTWOOD PREPARATORY SCHOOL',
  'KENWOOD SCHOOL',
  'KEOKUK CATHOLIC SCHOOLS',
  'KEOKUK CHRISTIAN ACADEMY',
  'KERMAN CHRISTIAN SCHOOL',
  'KERR-VANCE ACADEMY',
  'KESHET DAY SCHOOLS',
  'KESSER MALKA',
  'KESWELL SCHOOL',
  'KESWICK CHRISTIAN SCHOOL',
  'KETCHUM-GRANDE SCHOOL OF THE CHARLTON SCHOOL.',
  'KETTLE MORAINE LUTHERAN HIGH SCHOOL',
  'KEY BISCAYNE PRESBYTERIAN SCHOOL',
  'KEY POINT ACADEMY',
  'KEY SCHOOL',
  'KEYS KADEMY',
  'KEYS MONTESSORI SCHOOL',
  'KEYSTONE CHILD DEVELOPMENT CENTER',
  'KEYSTONE CHRISTIAN ACADEMY',
  'KEYSTONE CHRISTIAN ACADEMY - YORK',
  'KEYSTONE MONTESSORI SCHOOL',
  'KEYSTONE NATIONAL HIGH SCHOOL',
  'KEYSTONE PREP HIGH SCHOOL',
  'KEYSTONE SCHOOL',
  'KEYSTONE SMILES ALTERNATIVE EDUCATION',
  'KHAN LAB SCHOOL',
  'KHHD YOEL OF SATMAR BP',
  'KID ESTEEM MONTESSORI SCHOOL',
  'KID JUNCTION',
  'KID KARE',
  'KID LIFE ACADEMY ELEMENTARY',
  'KID TOWN USA PRESCHOOL-MONTESSORI ACADEMY',
  "KID'S CHOICE LEARNING CENTER",
  "KID'S DAYCARE & KINDERGARTEN",
  "KID'S KINGDOM CHRISTIAN ACADEMY",
  "KID'S LOVE DAYCARE & PRESCHOOL",
  "KID'S VILLA LEARNING CENTER LLC",
  "KID'Z COLONY",
  'KIDDIE ACADEMY',
  'KIDDIE ACADEMY CHILD CARE LEARNING CENTER',
  'KIDDIE ACADEMY OF BIRCHFIELD',
  'KIDDIE ACADEMY OF CHARLOTTE',
  'KIDDIE ACADEMY OF COLLEGEVILLE',
  'KIDDIE ACADEMY OF DELRAN',
  'KIDDIE ACADEMY OF IRVINE',
  'KIDDIE ACADEMY OF MONTGOMERYVILLE',
  'KIDDIE ACADEMY OF PLUMSTEADVILLE',
  'KIDDIE ACADEMY OF SILVER SPRING TOWNSHIP',
  'KIDDIE KAMPUS II',
  'KIDDIE KAMPUS LEARNING CENTER',
  'KIDDIE KOLLEGE',
  'KIDDIE KOLLEGE PRESCHOOL',
  'KIDDIELAND LEARNING CENTER',
  "KIDS 'R' KIDS #21",
  "KIDS 'R' KIDS - MABLETON GA #54",
  'KIDS AREA LEARNING CENTER',
  'KIDS CAMPUS LEARNING CENTER',
  'KIDS CASTLE CHILD CARE CENTER',
  'KIDS COLLABORATE EARLY LEARNING CENTER, INC.',
  'KIDS CONNECTION ELEMENTARY',
  'KIDS CORNER LLC',
  'KIDS COUNT CHILD CENTER',
  'KIDS COUNTRY',
  'KIDS COUNTRY SCHOOL',
  'KIDS DAY OUT PRESCHOOL AND KINDERGARTEN',
  'KIDS FIRST ACADEMY',
  'KIDS FIRST CHRISTIAN ACADEMY',
  'KIDS FOR KIDS ACADEMY',
  'KIDS HARBOR OF HAMILTON MILL LLC',
  'KIDS IN ACTION',
  'KIDS IN HARMONY DAY SCHOOL',
  'KIDS INCORPORATED LEARNING CENTER',
  'KIDS LAND DAY CARE AND LEARNING CENTER',
  'KIDS LEARNING CENTER',
  'KIDS LEARNING CENTER OF SOUTH DADE',
  'KIDS LEARNING CENTER OF SOUTH DADE III',
  'KIDS LEARNING CENTER-S DADE 2',
  'KIDS OF GRACE',
  'KIDS OF PROMISE PRESCHOOL',
  'KIDS PARADISE 2 INC',
  'KIDS PARADISE LEARNING CENTER',
  'KIDS PLACE CHILD DEVELOPMENT CENTER',
  'KIDS PLAYHOUSE CHILD CARE CENTER',
  'KIDS R KIDS',
  'KIDS R KIDS LEARNING ACADEMY',
  'KIDS R KIDS NO. 35 HUMBLE',
  'KIDS R KIDS NO. 59',
  'KIDS R KIDS OF ROUND ROCK',
  'KIDS R KIDS OF SW LAS VEGAS',
  'KIDS R KIDS THE ACADEMY AT BLAKENEY',
  'KIDS R US CHILDCARE',
  'KIDS SMALL WORLD LEARNING CENTER',
  'KIDS STUFF PRESCHOOL KINDERGARTEN & CHILDCARE',
  'KIDS TOWN MONTESSORI SCHOOL',
  'KIDS UNLIMITED DBA TREE HILL LEARNING CENTR',
  'KIDS VILLAGE',
  'KIDS WORLD ACADEMY',
  'KIDS WORLD CHRISTIAN EDU CENTER',
  'KIDS WORLD SCHOOL, INC & CORNERSTONE ACADEMY OF L.',
  "KIDS' COMPANY INC.",
  'KIDSPEACE NATIONAL CENTERS OF NEW ENGLAND',
  'KIDSPORT ACADEMY',
  'KIDSUCCESS',
  'KIDSVILLE LEARNING CENTER',
  'KIDSVILLE USA',
  'KIDWORKS USA',
  'KIDZONE PRESCHOOL ACADEMY',
  'KIEFER SCHOOL',
  'KIILYS KIDS INC.',
  'KILLEEN ADVENTIST JUNIOR ACADEMY',
  'KILLIAN HILL CHRISTIAN SCHOOL',
  'KILLINGTON MOUNTAIN SCHOOL',
  'KILOMBO ACADEMIC & CULT. INST.',
  'KIM SCHOOL',
  'KIMBALL UNION ACADEMY',
  'KIMBER HILLS ACADEMY',
  'KIMBERTON WALDORF SCHOOL',
  'KINDER CARE LEARNING CENTER',
  'KINDER GARDEN SCHOOL',
  'KINDER GARDEN SCHOOL WEST CHESTER',
  'KINDER-PRAISE LEARNING CENTER',
  'KINDERCARE',
  'KINDERCARE DAYCARE CENTER',
  'KINDERCARE LEARNING CENTER',
  'KINDERCARE LEARNING CENTER #1004',
  'KINDERCARE LEARNING CENTER #1036',
  'KINDERCARE LEARNING CENTER #14',
  'KINDERCARE LEARNING CENTER - WESTFORD',
  'KINDERCARE LEARNING CENTER 1280',
  'KINDERCARE LEARNING CENTER 868',
  'KINDERCARE LEARNING CENTER-LINDENHURST',
  'KINDERCARE LEARNING CENTER-NORTH SALT LAKE',
  'KINDERCARE LEARNING CENTER-OREM',
  'KINDERCARE LEARNING CENTER-PASEO LADERA SAP',
  'KINDERCARE LEARNING CENTERS',
  'KINDERCARE LEARNING CENTERS # 1535',
  'KINDERCARE LEARNING CENTERS # 218',
  'KINDERCARE LEARNING CENTERS #982',
  'KINDERCOURT ACADEMY',
  'KINDERHAUS MONTESSORI SCHOOL OF HYDE PARK',
  'KINDERHOUSE MONTESSORI SCHOOL',
  'KINDERKIDS LEARNING CENTER AND PRESCHOOL II',
  'KINDERLAND MONTESSORI',
  'KINDERLAND4KIDZ LLC',
  'KINDI ACADEMY',
  'KINDLE FARM SCHOOL',
  'KING ARTHUR MONTESSORI ACADEMY',
  'KING FAMILY MINISTRIES SCHOOL',
  'KING OF KINGS LUTHERAN',
  'KING OF KINGS LUTHERAN PRESCHOOL AND KINDERGARTEN',
  'KING OF KINGS LUTHERAN SCHOOL',
  'KING STREET CHRISTIAN ACADEMY',
  "KING'S ACADEMY",
  "KING'S CHRISTIAN ACADEMY",
  "KING'S CHRISTIAN SCHOOL",
  "KING'S GATE CHRISTIAN SCHOOL",
  "KING'S KID CHRISTIAN ACADEMY",
  "KING'S KIDS PRESCHOOL & KINDERGARTEN",
  "KING'S RIDGE CHRISTIAN SCHOOL",
  "KING'S SCHOOLS",
  "KING'S SCHOOLS OF THE DESERT",
  "KING'S WAY CHRISTIAN SCHOOL",
  "KING'S WOOD MONTESSORI SCHOOL",
  'KINGDOM ACADEMY',
  'KINGDOM ACADEMY LEARNING CENTER',
  'KINGDOM CHRISTIAN ACADEMY',
  'KINGDOM HEART ACADEMY',
  'KINGDOM HEIGHTS CHRISTIAN SCHOOL',
  'KINGDOM KIDS CHRISTIAN ACADEMY',
  'KINGDOM LIFE ACADEMY',
  'KINGDOM PALACE DAYCARE INC.',
  'KINGDOM PREPARATORY ACADEMY',
  'KINGFISHER ACADEMY INC',
  'KINGS ACADEMY',
  'KINGS COURT CHRISTIAN ACADEMY',
  'KINGS KIDS ACADEMY',
  'KINGS KIDS CHILD CARE CENTER',
  'KINGS LANDING ACADEMY',
  'KINGS VALLEY CHRISTIAN SCHOOL',
  'KINGS WAY CHRISTIAN SCHOOLS',
  'KINGSBURY DAY SCHOOL',
  'KINGSBURY SDA SCHOOL',
  'KINGSLEY MONTESSORI SCHOOL',
  'KINGSPOINT CHRISTIAN SCHOOL',
  'KINGSTON ACADEMY',
  'KINGSTON CATHOLIC SCHOOL',
  'KINGSVIEW CHRISTIAN SCHOOL',
  'KINGSWAY CHRISTIAN ACADEMY',
  'KINGSWAY CHRISTIAN SCHOOL',
  'KINGSWAY LEARNING CENTER',
  'KINGSWAY LEARNING CENTER HADDONFIELD CAMPUS',
  'KINGSWAY PREPARATORY SCHOOL',
  'KINGSWOOD ACADEMY',
  'KINGSWOOD MONTESSORI ACADEMY',
  'KINGSWOOD SCHOOL',
  'KINGSWOOD-OXFORD SCHOOL',
  'KINGWOOD CHRISTIAN SCHOOL',
  'KINGWOOD MONTESSORI SCHOOL',
  'KINNERET DAY SCHOOL',
  'KINO LEARNING CENTER',
  'KINSMAN AMISH SCHOOL',
  'KINWEST MONTESSORI ACADEMY',
  'KIRBY HALL SCHOOL',
  'KIRK ACADEMY',
  'KIRK DAY SCHOOL',
  "KIRK O' THE VALLEY SCHOOL",
  'KIRK RD SCHOOL',
  'KIRKLAND CHILDRENS SCHOOL',
  "KIRKWOOD CHILDREN'S HOUSE",
  'KIRKWOOD CHRISTIAN SCHOOLS',
  'KIROV ACADEMY OF BALLET OF WASHINGTON D.C.',
  'KISK CREEK SCHOOL',
  'KITSAP ADVENTIST CHRISTIAN SCHOOL',
  'KITTREDGE SCHOOL',
  'KLA SCHOOLS OF PLAINFIELD',
  'KLA SCHOOLS OF WALNUT CREEK',
  'KLAMATH BASIN BEHAVURAL HEALTH',
  'KLIP INTERNATIONAL',
  'KLUB KID TOO',
  'KLURMAN MESIVTA HIGH SCHOOL',
  'KNESSES BAIS LEVI',
  "KNIGHT'S CHRISTIAN ACADEMY",
  'KNIGHTS ACADEMY & HIGH SCHOOL LLC',
  'KNIGHTS LEARNING CENTER',
  'KNOB VIEW',
  'KNOTT AVENUE CHRISTIAN CHURCH EARLY CHILDHOOD DEV',
  'KNOW-IT-ALL PREPARATORY SCHOOL AND TUTORING CENTER',
  'KNOWLEDGE BEGINNINGS',
  'KNOWLEDGE BEGINNINGS - HILLSBORO 0601',
  'KNOWLEDGE BEGINNINGS SCHOOL',
  'KNOWLEDGE BUILDERS ACADEMY',
  'KNOWLEDGE-FIRST EMPOWERMENT CENTER',
  'KNOXVILLE ADVENTIST SCHOOL',
  'KNOXVILLE BAPTIST CHRISTIAN',
  'KNOXVILLE CHRISTIAN SCHOOL',
  'KNOXVILLE MONTESSORI SCHOOL',
  'KODIAK CHRISTIAN SCHOOL',
  'KODOMONO KUNI',
  'KOHALA ADVENTIST SCHOOL',
  'KOHELET YESHIVA HIGH SCHOOL',
  'KOHLER MEADOW',
  'KOINONIA DAY TREATMENT',
  'KOLBE ACADEMY',
  'KOLBE ACADEMY & TRINITY PREP',
  'KOLBE CATHEDRAL HIGH SCHOOL',
  'KOLOB CANYON RTC',
  'KONA ADVENTIST CHRISTIAN SCHOOL',
  'KONOCTI CHRISTIAN ACADEMY',
  'KOOLAU BAPTIST ACADEMY',
  'KOOTENAI VALLEY CHRISTIAN SCHOOL',
  'KORAES ELEMENTARY SCHOOL',
  'KORE ACADEMY',
  'KOREAN BAPTIST CHURCH SCHOOL',
  'KOREAN EDUCATION CENTER ONLINE SCHOOL',
  'KOREAN SCHOOL OF SACRAMENTO',
  'KORMAN HYMAN HIGH SCHOOL-TALMU',
  'KPC DAY SCHOOL',
  'KRADLE 2 KINDERGARTEN - BAUER DR',
  'KRALLTOWN MENNONITE SCHOOL',
  'KREATIVE KIDS ACADEMY',
  'KRIEGER SCHECHTER DAY SCHOOL',
  'KROK Economics and Law University',
  'KROKA EXPEDITIONS',
  'KSU CHILD DEVELOPMENT CENTER',
  'KUEMPER CATHOLIC SCHOOL SYSTEM',
  'KURN HATTIN HOMES',
  'KURN HATTIN HOMES FOR CHILDREN',
  'KURTZ VALLEY SCHOOL',
  'KVC NILES',
  'KZV ARMENIAN SCHOOL',
  'Ka @ The Crossings',
  'Ka Umeke Kaeo - Pcs',
  'Ka Waihona O Ka Naauao - New Century Pcs',
  "Ka'ohao Public Charter School",
  "Ka'u Learning Academy - Pcs",
  'Kaaawa Elementary School',
  'Kaala Elementary School',
  'Kabarak University',
  'Kabardino-Balkarian State University',
  'Kaboora Institute of Higher Education',
  'Kabul Education University',
  'Kabul Health Sciences Institute',
  'Kabul Medical University',
  'Kabul University',
  'Kachemak Selo School',
  'Kachina Elementary School',
  'Kadir Has University',
  'Kadoka Area High School - 01',
  'Kadoka Elementary - 02',
  'Kadoka Middle School - 03',
  'Kaduna State University',
  'Kae Avenue Elementary School',
  'Kaegebein School',
  'Kaelepulu Elementary School',
  'Kaewai Elementary School',
  'Kaffie Middle',
  'Kafkas University',
  'Kafr El-Sheikh University',
  'Kagawa Institute of Nutrition',
  'Kagawa Medical School',
  'Kagawa University',
  'Kagel Elementary',
  'Kagoshima Immaculate Heart University',
  'Kagoshima University',
  "Kagoshima Women's College",
  'Kahakai Elementary School',
  'Kahala Elementary School',
  'Kahaluu Elementary School',
  'Kahla Middle',
  'Kahler Middle School',
  'Kahlil Gibran School',
  'Kahlotus Elem & High',
  'Kahoa Elementary School',
  'Kahramanmaras Sutcu Imam University',
  'Kahuku Elementary School',
  'Kahuku High & Intermediate School',
  'Kahului Elementary School',
  'Kaibeto Boarding School',
  'Kailua Elementary School',
  'Kailua High School',
  'Kailua Intermediate School',
  'Kaimiloa Elementary School',
  'Kaimuki High School',
  'Kaimuki Middle School',
  'Kainalu Elementary School',
  'Kairos Academy',
  'Kairos Pdx',
  'Kairos Public School Vacaville Academy',
  'Kaiser El',
  'Kaiser Elementary',
  'Kaiser Elementary School',
  'Kaiser Es',
  'Kaizen Education Foundation Dba El Dorado High School',
  'Kaizen Education Foundation Dba Gilbert Arts Academy',
  'Kaizen Education Foundation Dba Maya High School',
  'Kaizen Education Foundation Dba Quest High School',
  'Kaizen Education Foundation Dba South Pointe Elementary Scho',
  'Kaizen Education Foundation Dba Summit High School',
  'Kaizen Education Foundation Dba Vista Grove Preparatory Acad',
  'Kakatiya University',
  'Kake Elementary & High School',
  'Kakiat Elementary School',
  'Kalaheo Elementary School',
  'Kalaheo High School',
  'Kalama Elem School',
  'Kalama Jr Sr High',
  'Kalamazoo Adult Education',
  'Kalamazoo Area Math And Science Center',
  'Kalamazoo Central High School',
  'Kalamazoo College',
  'Kalamazoo Covenant Academy',
  'Kalamazoo Innovative Learning Program',
  'Kalamazoo Psychiatric Hospital',
  'Kalamazoo Resa Head Start',
  'Kalamazoo Resa West Campusecse',
  'Kalamazoo Resa West Campusgreat Start',
  'Kalamazoo Resa West Campusyoung Adult Program',
  'Kalamazoo Valley Community College',
  'Kalani High School',
  'Kalapuya Elementary School',
  'Kalapuya High School',
  'Kaleidoscope Academy',
  'Kaleidoscope Charter Elementary Sch',
  'Kaleidoscope Charter High School',
  'Kaleidoscope Middle School',
  'Kaleidoscope School Of Arts & Science',
  'Kaleidoscope School Of Memphis',
  'Kaleiopuu Elementary School',
  'Kaley Lake Como Elementary',
  'Kali School',
  'Kalida Elementary School',
  'Kalida High School',
  'Kalifornsky Beach Elementary',
  'Kalihi Elementary School',
  'Kalihi Kai Elementary School',
  'Kalihi Uka Elementary School',
  'Kalihi Waena Elementary School',
  'Kalinga University',
  'Kaliningrad State Technical University',
  'Kalispell Middle School',
  'Kalkaska Alternative Program',
  'Kalkaska High School',
  'Kalkaska Middle School',
  'Kalles Junior High',
  'Kallison El',
  'Kalmar University College',
  'Kalmiopsis Elementary School',
  'Kalmyk State University',
  'Kaltag School',
  'Kaluga State Pedagogical University',
  'Kamaile Academy Pcs',
  "Kamakura Women's College",
  'Kamala Elementary',
  'Kamalani Academy',
  'Kamalii Elementary School',
  'Kamalolmolk Art & Architecture Higher Education Institute of Noshahr',
  'Kamchatka State University Vitus Bering',
  'Kameshwar Singh Darbhanga Sanskrit University',
  'Kamiah Elementary School',
  'Kamiah High School',
  'Kamiah Middle School',
  'Kamiak High School',
  'Kamiakin High School',
  'Kamiakin Middle School',
  'Kamiloiki Elementary School',
  'Kammerer Middle',
  'Kampala International University',
  'Kampala University',
  'Kanab High',
  'Kanab Middle',
  'Kanab School',
  'Kanagawa Dental College',
  'Kanagawa Institute of Technology',
  'Kanagawa University',
  'Kanapaha Middle School',
  'Kanawha City Elementary School',
  'Kanawha Elementary School',
  'Kanawha Valley Community and Technical College',
  'Kanazawa College of Art',
  'Kanazawa College of Economics',
  'Kanazawa Gakuin University',
  'Kanazawa Institute of Technology',
  'Kanazawa Medical University',
  'Kanazawa University',
  'Kanda University of International Studies',
  'Kandahar University',
  'Kandy Stripe Academy',
  'Kane Area El Sch',
  'Kane Area Hs',
  'Kane Area Ms',
  'Kane Preschool',
  'Kaneland Blackberry Creek Elem',
  'Kaneland Harter Middle School',
  'Kaneland John Shields Elem Sch',
  'Kaneland John Stewart Elem Sch',
  'Kaneland Mcdole Elem School',
  'Kaneland Senior High School',
  'Kaneohe Elementary School',
  'Kanesville School',
  'Kangnam University',
  'Kangnung National University',
  'Kangwon National University',
  'Kankakee Area Co-op Day School',
  'Kankakee Community College',
  'Kankakee High School',
  'Kankakee Junior High School',
  'Kankakee Valley High School',
  'Kankakee Valley Intermediate Sch',
  'Kankakee Valley Middle School',
  'Kannada University',
  'Kannapolis Charter Academy',
  'Kannapolis Intermediate',
  'Kannapolis Middle',
  'Kannur University',
  'Kano State University of Technology Wudil',
  'Kanoelani Elementary School',
  'Kanoheda Elementary School',
  'Kanoon Elem Magnet School',
  'Kanopolis Middle',
  'Kanpur University',
  'Kansai Gaidai University',
  'Kansai Medical University',
  'Kansai University',
  'Kansai University of International Studies',
  'Kansai University of Social Welfare',
  'Kansas City Kansas Community College',
  'Kansas City Neighborhood Acad',
  'Kansas Connections Academy',
  'Kansas Elem School',
  'Kansas Es',
  'Kansas High School',
  'Kansas Hs',
  'Kansas Ms',
  'Kansas State University',
  'Kansas Virtual Academy (ksva)',
  'Kansas Wesleyan University',
  'Kansasville Elementary',
  'Kanto Gakuen University',
  'Kanto Gakuin University',
  'Kanu O Ka Aina - New Century Pcs',
  'Kanuikapono Learning Center',
  'Kaohsiung Medical College',
  'Kaohsuing Open University',
  'Kapaa Elementary School',
  'Kapaa High School',
  'Kapaa Middle School',
  'Kapalama Elementary School',
  'Kapiolani Community College',
  'Kaplan Career Academy',
  'Kaplan Elementary School',
  'Kaplan High School',
  'Kaplan University',
  'Kapolei Charter School By Goodwill Hawaii',
  'Kapolei Elementary School',
  'Kapolei High School',
  'Kapolei Middle School',
  'Kaposia Education Center Elementary',
  'Kapowsin Elementary',
  'Kappa',
  'Kappa V - Knowledge And Power Prep Academy',
  'Kapunahala Elementary School',
  'Kar University',
  'Karabuk University',
  'Karachi Institute Of Economics And Technology',
  'Karachi Institute of Information Technology',
  'Karachi Medical and Dental College',
  'Karachi School of Art',
  'Karadeniz Technical University',
  'Karaganda State Buketov University',
  'Karaganda State Technical University',
  'Karakoram International University',
  'Karatay University',
  'Karatina University',
  'Kardan University',
  'Karel De Grote Hogeschool',
  'Karel Havlicek Elem School',
  'Karelian State Pedagogical University',
  'Karen A. Ionta El Sch',
  'Karen Acres Elementary School',
  'Karen M. Siegel Academy',
  'Karen Wagner H S',
  'Karen Western Elementary Sch',
  'Karigon Elementary School',
  'Karl F. Clemens Elementary',
  'Karl G. Maeser Preparatory Academy',
  'Karl Richter Campus',
  'Karl W Saile Bear Road Elementary School',
  'Karlshochschule International University',
  'Karlstad Elementary',
  'Karlstad University',
  'Karluk School',
  'Karnatak University',
  'Karnataka State Law University',
  'Karnataka State Open University',
  'Karner Blue Education Center',
  'Karnes City H S',
  'Karnes City J H',
  'Karnes City J J A E P',
  'Karnes City Pri',
  'Karns City Hs',
  'Karns Elementary',
  'Karns High School',
  'Karns Middle School',
  'Karolinska Institute Stockholm',
  'Karpagam University',
  'Karshner Elementary',
  'Karunya Institute Of Technology ( Deemed University)',
  'Karval Elementary School',
  'Karval Junior-senior High School',
  'Karwan Institute of Higher Education',
  'Kase Academy',
  'Kasem Bundit University',
  'Kasetsart University',
  'Kashan University',
  'Kashan University of Medical Sciences',
  'Kashia Elementary',
  'Kashmere Gardens El',
  'Kashmere H S',
  'Kaskaskia College',
  'Kaskaskia Education Center',
  'Kassala University',
  'Kasson Elementary/middle School',
  'Kasson-mantorville Elementary',
  'Kasson-mantorville Middle School',
  'Kasson-mantorville Senior High',
  'Kastamonu University',
  'Kastner Intermediate',
  'Kasuun Elementary',
  'Katahdin Elementary School',
  'Katahdin Middle/high School',
  'Kate Bond Elementary School',
  'Kate Bond Middle School',
  'Kate Collins Middle',
  'Kate D Smith Dar Elementary School',
  'Kate D Smith Dar High School',
  'Kate Dowdall Elementary School',
  'Kate Duncan Smith Dar Middle',
  'Kate Goodrich Elementary',
  'Kate M Smith Elementary School',
  'Kate M. Smith Elementary School',
  'Kate Shepard Elementary School',
  'Kate Sullivan Elementary School',
  'Kate Waller Barrett Elementary',
  'Kateb Institute of Higher Education',
  'Katella High',
  'Katharine D Malone Elementary School',
  'Katharine Drexel Elementary School',
  'Katharine Lee Bates',
  'Katherine Anne Porter School',
  'Katherine B. Sutton Elementary School',
  'Katherine Dunn Elementary School',
  'Katherine Edwards Middle',
  'Katherine Elementary',
  'Katherine Finchy Elementary',
  'Katherine G Johnson Stem Academy',
  'Katherine Gallegos Elementary',
  'Katherine H Hankins Middle School',
  'Katherine I. Daily Es',
  'Katherine Johnson Technology Magnet Academy',
  'Katherine L. Albiani Middle',
  'Katherine R. Smith Elementary',
  'Katherine Stephens El',
  'Katherine Thomas Elementary School',
  'Kathleen & Tim Harney Middle School',
  'Kathleen E. Goodwin School',
  'Kathleen Elementary School',
  'Kathleen Grimm School For Leadership',
  'Kathleen H. Ryerson Elementary School',
  'Kathleen Joerger Lindsey El',
  'Kathleen Middle School',
  'Kathleen Senior High School',
  'Kathlyn Joy Gilliam Collegiate Academy',
  'Kathmandu University',
  'Katholieke Hogeschool Kempen',
  'Katholieke Hogeschool Leuven',
  'Katholieke Hogeschool Limburg',
  'Katholieke Universiteit Brussel',
  'Katholieke Universiteit Leuven',
  'Katholieke Universiteit Leuven Campus Kortrijk',
  'Katholisch-Theologische Privatuniversität Linz',
  'Katholische Fachhochschule Berlin (KFB)',
  'Katholische Fachhochschule Freiburg Hochschule für Sozialwesen, Religionspädagogik und Pflege',
  'Katholische Fachhochschule Mainz',
  'Katholische Fachhochschule Norddeutschland',
  'Katholische Fachhochschule Nordrhein-Westfalen',
  'Katholische Hochschule für Soziale Arbeit Saarbrücken',
  'Katholische Stiftungsfachhochschule München',
  'Katholische Universität Eichstätt',
  'Kathryn D. Markley El Sch',
  'Kathryn Griffis El',
  'Kathryn Hughes Elementary',
  "Kathryn O'loughlin Mccarthy Elem",
  'Kathryn P. Stoklosa Middle School',
  'Kathryn Senor Elementary School',
  'Kathryn Sue Simonton Elementary',
  'Kathryn Winn Primary',
  'Kathryn Wolfe Criswell El',
  'Kathy Binks Elementary',
  'Kathy Caraway El',
  'Kathy L. Batterman Elementary School',
  'Katie Harper-wright Elem',
  'Kato Public Charter School',
  'Katonah Elementary School',
  'Katowice School of Economics',
  'Katsina University',
  'Katy El',
  'Katy H S',
  'Katy J H',
  'Kau High & Pahala Elementary School',
  'Kauai Community College',
  'Kauai High School',
  'Kaufer H S',
  'Kauffman Leadership Academy',
  'Kaufman El',
  'Kaufman H S',
  'Kaukauna High',
  'Kauluwela Elementary School',
  'Kaumana Elementary School',
  'Kaunakakai Elementary School',
  'Kaunas Medical Academy',
  'Kaunas University of Technology',
  'Kauri Sue Hamilton',
  'Kavod Elementary Charter',
  'Kawaikini Ncpcs',
  'Kawameeh Middle School',
  "Kawamura Gakuen Woman's University",
  'Kawana Springs Elementary',
  'Kawasaki Medical School',
  'Kawasaki University of Medical Care',
  'Kaweah High',
  'Kay Carl Elementary School',
  'Kay Granger El',
  'Kay Road Elementary',
  "Kay's Creek Elementary",
  'Kaya University',
  'Kaycee School',
  'Kayenta Community School',
  'Kayenta Elementary School',
  'Kayenta Middle School',
  'Kaysville Jr High',
  'Kaysville School',
  'Kazak American University',
  'Kazak National Technical University',
  'Kazak State University of Agriculture',
  'Kazakh British Technical University',
  'Kazakhstan Institute of Management Economics, and Strategic Research',
  'Kazan State Academy of Architecture and Civil Engineering',
  'Kazan State Finance and Economics Institute',
  'Kazan State Music Conservatory',
  'Kazan State Pedagogical University',
  'Kazan State University',
  'Kazan State University of Medicine',
  'Kazan State University of Technology',
  'Kazen El',
  'Kc International-wallace',
  'Kc Kids Pre-school',
  'Kc Ling Elementary School',
  'Kcisd - Daep',
  'Ke Ana Laahana - Pcs',
  'Ke Kula Niihau O Kekaha Learning Center - A Laboratory Pcs',
  'Ke Kula O Ehunuikaimalino',
  'Ke Kula O Nawahiokalaniopuu Iki - Laboratory Pcs',
  'Ke Kula O Samuel M Kamakau - A Laboratory Pcs',
  'Keaau Elementary School',
  'Keaau High School',
  'Keaau Middle School',
  'Kealakehe Elementary School',
  'Kealakehe High School',
  'Kealakehe Intermediate School',
  'Kealing M S',
  'Keams Canyon Elementary School',
  'Kean College',
  'Kean Elementary School',
  'Keansburg High School',
  'Kearney Education Center',
  'Kearney Elem.',
  'Kearney High',
  'Kearney High School',
  'Kearney Jr. High',
  'Kearney Middle',
  'Kearney Middle School',
  'Kearney West High School',
  'Kearns Academy',
  'Kearns High',
  'Kearns Jr High',
  'Kearny College Connections',
  'Kearny Digital Media & Design',
  'Kearny Elementary',
  'Kearny Eng Innov & Design',
  'Kearny Gen Philip Sch',
  'Kearny High School',
  'Kearny Sct',
  'Kearsarge Reg. Elem. School At New London',
  'Kearsarge Reg. Elementary School - Bradford',
  'Kearsarge Regional High School',
  'Kearsarge Regional Middle School',
  'Kearsley High School',
  'Keating Elementary School',
  'Keaukaha Elementary School',
  'Keavy Elementary School',
  'Kebbi State University of Science and Technology',
  'Kec Central Efe 170',
  'Kecoughtan High',
  'Kedron Elementary School',
  'Kee High School',
  'Keeble Ec/pre-k Center',
  'Keefe Avenue Elementary',
  'Keefer Crossing Middle',
  'Keele University',
  'Keeley El',
  'Keenan El',
  'Keenburg Elementary',
  'Keene (william B.) Elementary School',
  'Keene Central School',
  'Keene El',
  'Keene Elementary School',
  'Keene H S',
  'Keene High School',
  'Keene J H',
  'Keene Jjaep',
  'Keene Middle School',
  'Keene Mill Elementary',
  'Keene State College',
  'Keene-riverview Elementary',
  'Keenes Crossing Elementary',
  'Keeney School',
  'Keeseville Primary School',
  'Keet Gooshi Heen Elementary',
  'Keeth Elementary School',
  'Keeven Elem.',
  'Keewahdin Elementary School',
  'Keewatin-nashwauk Elementary School',
  'Keewaydin Discovery Center',
  'Kegonsa Elementary',
  'Kegotank Elementary',
  'Keheley Elementary School',
  'Kehrs Mill Elem.',
  'Keiai University',
  'Keicher Elementary School',
  'Keidan Special Education School',
  'Keifer Academy',
  'Keigwin Middle School',
  'Keiko Davidson El',
  'Keiller Leadership Academy',
  'Keimyung University',
  'Keio University',
  'Keisen Jogaku-en College',
  'Keiser University',
  'Keister Elementary',
  'Keith B. Bright High (juvenile Hall)',
  'Keith Bovenschen School',
  'Keith C & Karen W Hayes Elementary',
  'Keith El',
  'Keith Elementary School',
  'Keith Lutz Horizon High School',
  'Keith Mccarthy Academy',
  'Keith Middle School',
  'Keith Middlehigh School',
  'Keith Valley Ms',
  'Keithville Elementary/middle School',
  'Keiwa College',
  'Keizer Elementary School',
  'Kekaha Elementary School',
  'Kekionga Middle School',
  'Kell Grade School',
  'Kell High School',
  'Kellar Primary School',
  'Kellar School',
  'Keller Center For Advanced Learning',
  'Keller Early Learning Center - North',
  'Keller Early Learning Center - South',
  'Keller El',
  'Keller Elem Gifted Magnet School',
  'Keller Elementary',
  'Keller Elementary School',
  'Keller H S',
  'Keller Middle',
  'Keller-harvel El',
  'Kelley El',
  'Kelley Elementary',
  'Kelley Es',
  'Kelley Lake Elementary School',
  'Kelley Smith Elementary School',
  'Kelley William D Sch',
  'Kelleys Island High School',
  'Kelliher Elementary',
  'Kelliher Secondary',
  'Kellison Elem.',
  'Kellman Corporate Community Elem',
  'Kellogg (karl H.) Elementary',
  'Kellogg Community College',
  'Kellogg Elem School',
  'Kellogg Elementary',
  'Kellogg Elementary School',
  'Kellogg High School',
  'Kellogg Marsh Elementary School',
  'Kellogg Middle',
  'Kellogg Middle School',
  'Kellogg Polytechnic Elementary',
  'Kelloggsville Early Childhood Learning Center',
  'Kelloggsville High School',
  'Kelloggsville Middle School',
  'Kellom Elementary School',
  'Kellwood School Alternative',
  'Kelly',
  'Kelly A Burlison Middle School',
  'Kelly Creek Elementary School',
  'Kelly Edwards Elementary',
  'Kelly El Sch',
  'Kelly Elementary',
  'Kelly Elementary School',
  'Kelly High School',
  'Kelly John B Sch',
  'Kelly Lane Middle',
  'Kelly Lane Primary School',
  'Kelly Liberal Arts Academy',
  'Kelly Middle School',
  'Kelly Mill Elementary',
  'Kelly Mill Middle',
  'Kelly Miller Elementary',
  'Kelly Miller Ms',
  'Kelly Springs Elementary School',
  'Kelly Steam Magnet Middle School',
  'Kelly Walsh High School',
  'Kelly-pharr El',
  'Kellybrook Elem School',
  'Kellyville Es',
  'Kellyville Hs',
  'Kellyville Ms',
  'Kellyville Upper Es',
  'Kelsey L. Pharr Elementary School',
  'Kelsey Norman Elem.',
  'Kelseyville Community Day',
  'Kelseyville Elementary',
  'Kelseyville High',
  'Kelso (william H.) Elementary',
  'Kelso El',
  'Kelso Elem.',
  'Kelso Elementary School',
  'Kelso High School',
  'Kelso Virtual Academy',
  'Kelton School',
  'Kelvin Grove Jr High School',
  'Kelvyn Park High School',
  'Kemerovo State University',
  'Kemmerer Elementary School',
  'Kemmerer Junior Senior High School',
  'Kemmerer Village School',
  'Kemp Alternative',
  'Kemp Elem School',
  'Kemp Elementary School',
  'Kemp H S',
  'Kemp Int',
  'Kemp J H',
  'Kemp Mill Elementary',
  'Kemp Prek-6 School',
  'Kemp Pri',
  'Kemp Primary',
  'Kemp-carver El',
  'Kemper County High School',
  'Kemper Elementary School',
  'Kempner H S',
  'Kempsville Elementary',
  'Kempsville High',
  'Kempsville Meadows Elementary',
  'Kempsville Middle',
  'Kempton Elementary School',
  'Kempton Street Literacy Academy',
  'Kemptown Elementary',
  "Ken 'chief' Hill Learning Academy",
  'Ken Caryl Middle School',
  'Kenai Alternative High School',
  'Kenai Central High School',
  'Kenai Middle School',
  'Kenansville Elementary',
  'Kenbridge Elementary',
  'Kenbrook Elementary School',
  'Kendale Elementary School',
  'Kendale Lakes Elementary School',
  'Kendall Center',
  'Kendall El',
  'Kendall Elementary',
  'Kendall Elementary School',
  'Kendall Junior-senior High School',
  'Kendall-whittier Es',
  'Kendallvue Elementary School',
  'Kenderton El Sch',
  'Kendon School',
  'Kendrick El',
  'Kendrick High School',
  'Kendrick Jr/sr High School',
  'Kendrick Lakes Elementary School',
  'Kendrick Middle School',
  'Kendyl Depoali Middle School',
  'Kendyl Depoali Preschool',
  'Kenedy El',
  'Kenedy H S',
  'Kenedy Middle',
  'Kenesaw Elementary School',
  'Kenesaw Secondary School',
  'Kenilworth Elementary',
  'Kenilworth Elementary School',
  'Kenilworth Junior High',
  'Kenilworth Science And Technology Charter School',
  'Kenly Elementary School',
  'Kenmare Elementary School',
  'Kenmare High School',
  'Kenmoor Early Childhood Center',
  'Kenmoor Middle',
  'Kenmore East Senior High School',
  'Kenmore Elementary',
  'Kenmore High School',
  'Kenmore Middle',
  'Kenmore Middle School',
  'Kenmore West Senior High School',
  'Kenmore/garfield High School',
  'Kenna Elementary School',
  'Kennard El',
  'Kennard Elementary School',
  'Kennard H S',
  'Kennard-dale Hs',
  'Kennard/classical Jr. Acad.',
  'Kennebec Elementary - 04',
  'Kennebec Valley Community College',
  'Kennebunk Elementary School',
  'Kennebunk High School',
  'Kennebunkport Consolidated Sch',
  'Kennedale Alter Ed Prog',
  'Kennedale H S',
  'Kennedale J H',
  'Kennedy (john F.) Elementary',
  'Kennedy Center',
  'Kennedy Community School',
  'Kennedy Early Childhood Center',
  'Kennedy El',
  'Kennedy Elem',
  'Kennedy Elem School',
  'Kennedy Elementary',
  'Kennedy Elementary - 08',
  'Kennedy Elementary School',
  'Kennedy Es',
  'Kennedy Gardens Elementary',
  'Kennedy High School',
  'Kennedy Junior High School',
  'Kennedy Learning Center',
  'Kennedy Middle',
  'Kennedy Middle School',
  'Kennedy Montessori Elementary',
  'Kennedy Park Elementary School',
  'Kennedy Primary Academy',
  'Kennedy Road Middle School',
  'Kennedy School',
  'Kennedy Senior High',
  'Kennedy Zapata El',
  'Kennedy-curry Middle',
  'Kennedy-longfellow',
  'Kennedy-powell El',
  'Kennelly School',
  'Kenner Discovery Health Sciences Academy',
  'Kennerly Elem.',
  'Kennesaw Charter School',
  'Kennesaw Elementary School',
  'Kennesaw Mountain High School',
  'Kennesaw State University',
  'Kenneth A. Brett School',
  'Kenneth Burdett School',
  'Kenneth C. Hanrahan Elem.',
  'Kenneth Coombs School',
  'Kenneth D Bailey Academy',
  'Kenneth D. King Middle School',
  'Kenneth Davis El',
  'Kenneth E Little El',
  'Kenneth E Neubert Elem School',
  'Kenneth Gardner Elementary',
  'Kenneth Henderson Middle',
  'Kenneth J Carberry Elementary',
  'Kenneth L Hermansen Elementary',
  'Kenneth L Rutherford School',
  'Kenneth Murphy School',
  'Kenneth N Butz Jr El Sch',
  'Kenneth P Morris Elementary School',
  'Kenneth R. Olson Middle School',
  'Kenneth T Beagle Elementary School',
  'Kenneth W Clement',
  'Kenneth W.culbert Elementary',
  'Kennett Career Tech. Ctr.',
  'Kennett High',
  'Kennett High School',
  'Kennett Hs',
  'Kennett Middle',
  'Kennett Ms',
  'Kennewick High School',
  'Kenney Middle School',
  'Kenny C Guinn Middle School',
  'Kenny Elementary',
  'Kenny Lake School',
  'Kennydale Elementary School',
  'Keno Elementary School',
  'Kenosha 4 Year Old Kindergarten',
  'Kenosha Eschool K-12',
  'Kenosha Home Community',
  'Kenosha Park Elementary',
  'Kenosha Pike School',
  'Kenosha School Of Technology Enhanced Curriculum',
  'Kenova Elementary School',
  'Kenowa Hills Central Elementary School',
  'Kenowa Hills Early Childhood Center',
  'Kenowa Hills High School',
  'Kenowa Hills Middle School',
  'Kenowa Hills Pathways High School',
  'Kenrose Elementary',
  'Kenroy Elementary',
  'Kensal Elementary School',
  'Kensett Elementary School',
  'Kensico School',
  'Kensington Creative & Performing Arts Hs',
  'Kensington Elementary',
  'Kensington Elementary School',
  'Kensington Health Sciences',
  'Kensington Hs',
  'Kensington Intermediate Elementary School',
  'Kensington International School',
  'Kensington Park Elementary School',
  'Kensington Parkwood Elementary',
  'Kensington Road School',
  'Kensington Woods Schools',
  'Kensler Elem',
  'Kenston High School',
  'Kenston Intermediate School',
  'Kenston Middle School',
  'Kent Career Tech Center',
  'Kent Center School',
  'Kent City Alternative High School',
  'Kent City Elementary School',
  'Kent City High School',
  'Kent City Middle School',
  'Kent County Community School',
  'Kent County High',
  'Kent County Middle School',
  'Kent County Secondary Ilc',
  'Kent Education Centerbeltline',
  'Kent Education Centeroakleigh',
  'Kent El',
  'Kent Elementary Intensive Learning Center',
  'Kent Elementary School',
  'Kent Gardens Elementary',
  'Kent Heights School',
  'Kent Hills School',
  'Kent Innovation High School',
  'Kent Isd Conference Center',
  'Kent Isd Early Childhood Services',
  'Kent Isd Early On',
  'Kent Island Elementary School',
  'Kent Island High School',
  'Kent Lake Elementary School',
  'Kent Middle School',
  'Kent Mountain View Academy',
  'Kent Phoenix Academy',
  'Kent Prairie Elementary',
  'Kent Primary School',
  'Kent State University',
  'Kent Transition Center',
  'Kent Vocational Options',
  'Kent-meridian High School',
  'Kenter Canyon Elementary Charter',
  'Kentlake High School',
  'Kenton Co Academies Of Innovation & Tech',
  'Kenton Elementary School',
  'Kenton High School',
  'Kenton Middle School',
  'Kenton Ridge High School',
  'Kentridge High School',
  'Kentuck Elementary',
  'Kentucky Advanced Technology Center',
  'Kentucky Community & Technical College System',
  'Kentucky River Foothills Headstart',
  'Kentucky School For The Blind',
  'Kentucky School For The Deaf',
  'Kentucky Tech - Anderson County Tech Ctr',
  'Kentucky Tech - Ashland Reg Tech Ctr',
  'Kentucky Tech - Central Campus',
  'Kentucky Tech - Daviess County Campus',
  'Kentucky Tech - Harlan Regional Tech Ctr',
  'Kentucky Tech - Hazard Regional Tech Ctr',
  'Kentucky Tech - Jefferson Campus',
  'Kentucky Tech - Madisonville Reg Tech Ct',
  'Kentucky Tech - Mayo Regional Tech Ctr',
  'Kentucky Tech - Owensboro Campus',
  'Kentucky Trail Elem.',
  'Kentwood El',
  'Kentwood Elementary',
  'Kentwood High Magnet School',
  'Kentwood High School',
  'Kenwood Academy High School',
  'Kenwood Elem School',
  'Kenwood Elementary',
  'Kenwood Elementary School',
  'Kenwood High',
  'Kenwood High Ib And Sports Science',
  'Kenwood K-8 Center',
  'Kenwood Middle',
  'Kenwood Public School',
  'Kenwood Station Elementary School',
  'Kenwood Trail Middle School',
  'Kenya College of Accountancy',
  'Kenya Medical Training College',
  'Kenya Methodist University',
  'Kenyatta University',
  'Kenyon College',
  'Kenyon Woods Middle School',
  'Kenyon-wanamingo Alternative Prog',
  'Kenyon-wanamingo Elementary',
  'Kenyon-wanamingo High School Credit',
  'Kenyon-wanamingo Middle',
  'Kenyon-wanamingo Senior High',
  'Keokuk High School',
  'Keokuk Middle School',
  'Keolu Elementary School',
  'Keonepoko Elementary School',
  'Keoneula Elementary School',
  'Keota Elementary School',
  'Keota Es',
  'Keota High School',
  'Keota Hs',
  'Keough Memorial Academy',
  'Keowee Elementary',
  'Keplar Elementary',
  'Kepler Neighborhood',
  'Kepley Middle School',
  'Kepner Beacon Middle School',
  'Kepner Middle School',
  'Keppel Academy',
  'Keppen School',
  'Kerala Agricultural University',
  'Kerala University of Fisheries and Ocean Sciences',
  'Kerbala University',
  'Kerby Elementary School',
  'Kerens School',
  'Kerhonkson Elementary School',
  'Kerkhoven Secondary',
  'Kerkstra Elem School',
  'Kerman Graduate University of Technology',
  'Kerman High',
  'Kerman Middle',
  'Kerman University of Medical Sciences',
  'Kerman-floyd Elementary',
  'Kermanshah University of Medical Sciences',
  'Kermit El',
  'Kermit H S',
  'Kermit J H',
  'Kermit Johnson School',
  'Kermit King Elementary',
  'Kermit Koontz Education Complex',
  'Kermit Mckenzie Junior High',
  'Kermit Pre K-8',
  'Kermit R Booker Sr Elementary School',
  'Kermit S Nickerson School',
  'Kern Avenue Elementary',
  'Kern County Community',
  'Kern County Juvenile Court',
  'Kern County Rop',
  'Kern County Special Education',
  'Kern High Roc',
  'Kern Valley High',
  'Kern Workforce 2000 Academy',
  'Kernan Elementary School',
  'Kernan Middle School',
  'Kernan Trail Elementary School',
  'Kernersville Elementary',
  'Kernersville Middle',
  'Kernodle Middle',
  'Kernsville Sch',
  'Kernville Elementary',
  'Kerr El',
  'Kerr El Sch',
  'Kerr Elementary School',
  'Kerr Es',
  'Kerr H S',
  'Kerr Ms',
  'Kerrick Elementary',
  'Kerrville Disciplinary Alternative School',
  'Kerrydale Elementary',
  'Kersey Creek Elementary',
  'Kershaw Elem School',
  'Kershaw Elementary',
  'Kershaw Intermediate School',
  'Keshena Primary',
  'Kesling Middle School',
  'Kessler Elementary School',
  'Kester Avenue Elementary',
  'Kester School',
  'Kestrel Heights School',
  "Ket'acik/aapalluk Memorial School",
  'Ketcham Es',
  'Ketchikan Charter School',
  'Ketchikan High School',
  'Ketchum Es',
  'Ketchum Hs',
  'Ketchum Ms',
  'Ketelsen El',
  'Ketron Elementary School',
  'Kettering Early Childhood Education Center',
  'Kettering Elementary',
  'Kettering Fairmont High School',
  'Kettering Middle',
  'Kettering Middle School',
  'Kettering University',
  'Ketterlinus Elementary School',
  'Kettle Falls Elementary School',
  'Kettle Falls High School',
  'Kettle Falls Middle School',
  'Kettle Lake Elementary School',
  'Kettle Moraine 4k',
  'Kettle Moraine Correctional Institution',
  'Kettle Moraine Explore',
  'Kettle Moraine High',
  'Kettle Moraine Middle',
  'Kettle Run High',
  'Kettleman City Elementary',
  'Kevin K. Coleman School',
  'Kewanee High School',
  'Kewaskum Elementary',
  'Kewaskum High',
  'Kewaskum Middle',
  'Kewaunee Elementary',
  'Kewaunee High',
  'Kewaunee Middle',
  'Keweenaw Bay Ojibwa Community College',
  'Key (francis Scott) Elementary',
  'Key Biscayne K-8 Center',
  'Key Center School',
  'Key El',
  'Key Elementary (gaffney)',
  'Key Elementary School',
  'Key Es',
  'Key Francis Scott Sch',
  'Key Largo School',
  'Key Middle',
  'Key Middle (slade)',
  'Key Peninsula Middle School',
  'Key West Collegiate School',
  'Key West High School',
  'Keya Paha County High School',
  'Keyes El',
  'Keyes Elementary',
  'Keyes Es',
  'Keyes Hs',
  'Keyes To Learning Charter',
  'Keyport Central School',
  'Keyport High School',
  'Keys Acad',
  'Keys Center',
  'Keys El',
  'Keys Es',
  'Keys Gate Charter High School',
  'Keys Gate Charter School',
  'Keys Grace Academy',
  'Keys H S',
  'Keys Hs',
  'Keys Voc Center',
  'Keyser Elementary School',
  'Keyser High School',
  'Keyser Middle School',
  'Keyser Primary School',
  'Keystone Academy',
  'Keystone Academy Charter School',
  'Keystone Central Ctc',
  'Keystone Education Center Cs',
  'Keystone El Sch',
  'Keystone Elementary',
  'Keystone Elementary School',
  'Keystone Heights Elementary',
  'Keystone Heights Junior/senior High',
  'Keystone High School',
  'Keystone Jshs',
  'Keystone Middle School',
  'Keystone Montessori Charter School',
  'Keystone Oaks Hs',
  'Keystone Oaks Ms',
  'Keystone Public School',
  'Keytesville Elem.',
  'Keytesville High',
  "Kha'p'o Community School",
  'Khabarovsk State Academy of Economics and Law',
  'Khabarovsk State University of Technology',
  'Khalifa University',
  'Khalifa University of Science Technology and Research',
  'Khalil Gibran International Academy',
  'Khalsa Montessori Elementary School - Phoenix',
  'Khalsa School',
  'Khana-e-Noor Institute of Higher Education',
  'Kharkiv National University',
  'Kharkiv National University of Economics',
  'Kharkiv National University of Radio Electronics',
  'Kharkiv State Medical University',
  'Kharkiv State Technical University of Construction and Architecture',
  'Kharkiv State Transport Technical University',
  'Khazar University',
  'Khepera Cs',
  'Kherson State University',
  'Khmelnitsky National University',
  'Khomeinishahr Payame Noor University',
  'Khon Kaen University',
  'Khulna University',
  'Khulna University of Engineering And Technology',
  'Khurasan University',
  'Ki Charter Academy',
  'Ki Sawyer Elementary School',
  'Kiana School',
  'Kibi International University',
  'Kickapoo Elementary',
  'Kickapoo High',
  'Kickapoo Nation School',
  'Kickemuit Middle School',
  'Kid Street Learning Center Charter',
  'Kids Care Elementary',
  'Kids Community College Charter',
  'Kids Community College Charter High School',
  'Kids Community College Charter School Southeast Hillsborough',
  'Kids Community College Riverview South',
  'Kids Community College Southeast Middle Charter School',
  'Kids Company',
  'Kids Unlimited Academy',
  'Kidspeace',
  'Kidwell El',
  'Kidz Choice Charter School',
  'Kiefer Es',
  'Kiefer Hs',
  'Kiel Elementary School',
  'Kiel Eschool',
  'Kiel High',
  'Kiel Middle',
  'Kiev National Economic University',
  'Kiev National University of Construction and Architecture',
  'Kiev School Of Economics',
  'Kiev Slavonic University',
  'Kiewit Middle School',
  'Kigali Health Institute',
  'Kigali Independent University',
  'Kigali Institute of Education',
  'Kigali Institute of Science & Technology',
  'Kihei Charter School',
  'Kihei Elementary School',
  'Kiita Learning Community',
  'Kiker El',
  'Kila 7-8',
  'Kila School',
  'Kilauea Elementary School',
  'Kilbourn Elementary',
  'Kilbourne High School',
  'Kilbourne Middle School',
  'Kilby Laboratory School',
  'Kilby Shores Elementary',
  'Kildare Public School',
  'Kildeer Countryside Elem School',
  'Kilgore College',
  'Kilgore H S',
  'Kilgore Int',
  'Kilgore Middle',
  'Kilgore Pri',
  'Kilgour Elementary School',
  'Kilian Community College',
  'Kilimanjaro Christian Medical College',
  'Kilis 7 Aralik University',
  'Killarney Elementary',
  'Killbuck Elementary School',
  'Killdeer Elementary School',
  'Killdeer High School',
  'Killearn Lakes Elementary School',
  'Killeen H S',
  'Killeen Isd Career Center',
  'Killeen Isd Early College H S',
  'Killeen Jjaep',
  'Killian Elementary',
  'Killian Middle',
  'Killingly Central School',
  'Killingly High School',
  'Killingly Intermediate School',
  'Killingly Memorial School',
  'Killington Elementary School',
  'Killingworth Elementary School',
  'Killough Middle',
  'Killybrooke Elementary',
  'Kilmer Center',
  'Kilmer Elem School',
  'Kilmer Middle',
  'Kiln Creek Elementary',
  'Kilo Middle School',
  'Kilohana Elementary School',
  'Kilough Elementary School',
  'Kilpatrick Elementary School',
  'Kim Elementary School',
  'Kim Undivided High School',
  'Kimball',
  'Kimball El',
  'Kimball Elementary',
  'Kimball Elementary - 02',
  'Kimball Elementary School',
  'Kimball Es At Davis',
  'Kimball Head Start',
  'Kimball High School - 01',
  'Kimball Hill Elem School',
  'Kimball Jr/sr High School',
  'Kimball Middle School',
  'Kimball Middle School - 04',
  'Kimball Secondary',
  'Kimball Wiles Elementary School',
  'Kimbark Elementary',
  'Kimbell Elementary School',
  'Kimberley Park Elementary',
  'Kimberlin Acad For Excel',
  'Kimberly Elementary',
  'Kimberly Elementary School',
  'Kimberly Heights Elem School',
  'Kimberly High',
  'Kimberly High School',
  'Kimberly Lane Elementary',
  'Kimberly Middle School',
  'Kimbrough Elementary',
  'Kimbrough Middle',
  'Kimes Elem School',
  'Kimmel Alternative School',
  'Kimmel Farm Elementary',
  'Kimmie M Brown El',
  'Kimper Elementary School',
  'Kimpton Middle School',
  "Kin Dah Lichi'i Olta",
  'Kinard Core Knowledge Middle School',
  'Kinard Elementary',
  'Kinawa School',
  'Kincaid Elementary',
  'Kincaid Elementary School',
  'Kinchafoonee Primary School',
  'Kincheloe Elementary School',
  'Kinder Academy',
  'Kinder Cub School Inc',
  'Kinder Elementary School',
  'Kinder High School',
  'Kinder Middle School',
  'Kinder Ranch Area H S',
  'Kinder Ranch El',
  'Kindergarten Academy',
  'Kindergarten Center',
  'Kindergarten Center At Filbert St',
  'Kindergarten Success Academy',
  'Kindezi',
  'Kindezi Academy',
  'Kindezi Old 4th Ward',
  'Kindred El',
  'Kindred Elementary School',
  'Kindred High School',
  'Kinetic Academy',
  'King & Queen Elementary',
  'King (martin Luther) High (continuation)',
  'King (thomas Starr) Elementary',
  'King Abdul Aziz University',
  'King Abdullah University of Science and Technology',
  'King Academy Community School',
  'King Academy Of Social Justice',
  'King Avenue Elementary',
  'King Career Center',
  'King Center Charter School',
  'King City Arts Magnet',
  'King City Elem.',
  'King City High',
  'King College Prep High School',
  'King Colony School',
  'King Cove School',
  'King David Kalakaua Middle School',
  'King Early Childhood Ctr',
  'King El Sch',
  'King Elementary',
  'King Elementary School',
  'King Es',
  'King Fahad University of Petroleum and Minerals',
  'King Fahd Security College',
  'King Faisal University',
  'King George Elementary',
  'King George High',
  'King George Middle',
  'King H S',
  'King High School',
  'King International',
  'King International Baccalaureate Middle',
  'King John R Academic And Performing Arts Academy',
  'King Jr Elementary',
  'King Jr. (martin Luther) Academic Middle',
  'King K-8',
  'King Kamehameha Iii Elementary School',
  'King Kaumualii Elementary School',
  'King Kekaulike High School',
  'King Khaled University',
  'King Liholiho Elementary School',
  'King Martin Luther Hs',
  'King Middle Grade School',
  'King Middle School',
  "King Mongkut's University of Technology Ladkrabang",
  "King Mongkut's University of Technology North Bangkok",
  "King Mongkut's University of Technology Thonburi",
  'King Open',
  'King Philip Middle School',
  'King Philip Regional High',
  'King Saud University',
  'King Saud bin Abdulaziz University for Health Sciences',
  'King School',
  'King Science/tech Magnet M S',
  'King Springs Elementary School',
  'King Street Intermediate School',
  'King Street Primary School',
  'King Street School',
  'King William High',
  'King William Lunalilo Elementary School',
  "King's College",
  "King's College London University of London",
  "King's Fork High",
  "King's Fork Middle",
  "King's Grant Elementary",
  "King's Highway Elementary School",
  "King's Highway Segregated",
  "King's University College",
  'King-chavez Academy Of Excellence',
  'King-chavez Arts Academy',
  'King-chavez Athletics Academy',
  'King-chavez Community High',
  'King-chavez Preparatory Academy',
  'King-chavez Primary Academy',
  'King-murphy Elementary School',
  'King/drew Medical Magnet High',
  'King/robinson Magnet School',
  'Kingfield Elementary School',
  'Kingfisher Hs',
  'Kingfisher Ms',
  'Kingman Academy Of Learning - High School',
  'Kingman Academy Of Learning - Intermediate School',
  'Kingman Academy Of Learning - Middle School',
  'Kingman Academy Of Learning - Primary School',
  'Kingman Elementary School',
  'Kingman High',
  'Kingman High School',
  'Kingman Middle School',
  'Kings Beach Elementary',
  'Kings Canyon Continuation',
  'Kings Canyon Middle',
  'Kings Canyon Unified School District Wide Preschools',
  'Kings Chapel Elementary School',
  'Kings Collegiate Charter School',
  'Kings County Community',
  'Kings County Rop',
  'Kings County Selpa Preschool',
  'Kings County Special Education',
  'Kings Creek Elementary',
  'Kings Daughters Hosp. Ep.',
  'Kings Elementary School',
  'Kings Glen Elementary',
  'Kings High School',
  'Kings Highway El Sch',
  'Kings Highway Elementary Magnet School',
  'Kings Junior High School',
  'Kings Kids Academy Of Health Sciences',
  'Kings Lake Education Center',
  'Kings Manor El',
  'Kings Mills Educational Center',
  'Kings Mills Elementary School',
  'Kings Mountain Elementary',
  'Kings Mountain High',
  'Kings Mountain Intermediate',
  'Kings Mountain Middle',
  'Kings Park Elementary',
  'Kings Park High School',
  'Kings Peak School',
  'Kings Ridge School',
  'Kings River Elementary',
  'Kings River Elementary School',
  'Kings River High (continuation)',
  'Kings River-hardwick Elementary',
  'Kings Road School',
  'Kings Trail Elementary School',
  'Kings Valley Academy',
  'Kings Valley Academy Ii',
  'Kings Valley Charter School',
  'Kingsborough Early College School',
  'Kingsborough Middle',
  'Kingsborough School',
  'Kingsbridge International High School',
  'Kingsburg High',
  'Kingsburg Independent Study High',
  'Kingsbury Colony Attn Ctr',
  'Kingsbury Country Day School',
  'Kingsbury Elementary',
  'Kingsbury Elementary School',
  'Kingsbury High',
  'Kingsbury Middle',
  'Kingsford Elementary',
  'Kingsford Elementary School',
  'Kingsford Heights Elementary Sch',
  'Kingsford High School',
  'Kingsford Middle School',
  'Kingsford Park Elementary School',
  'Kingsgate Es',
  'Kingsland Elementary School',
  'Kingsland Intermediate School',
  'Kingsland Junior/senior High School',
  'Kingsland School',
  'Kingsley Area Elementary School',
  'Kingsley Area High School',
  'Kingsley Area Middle School',
  'Kingsley Elem School',
  'Kingsley Elementary',
  'Kingsley Elementary School',
  'Kingsley Jr High School',
  'Kingsley Montgomery School',
  'Kingsley-pierson High School',
  'Kingsman Academy Pcs',
  'Kingston College',
  'Kingston Elem.',
  'Kingston Elementary',
  'Kingston Elementary School',
  'Kingston Es',
  'Kingston High',
  'Kingston High School',
  'Kingston Hill Academy',
  'Kingston Hs',
  'Kingston Intermediate',
  'Kingston Middle',
  'Kingston Middle School',
  'Kingston Ms',
  'Kingston Primary',
  'Kingston Springs Elementary',
  'Kingston University',
  'Kingstree Middle Magnet',
  'Kingstree Senior High',
  'Kingsview Middle',
  'Kingsville Elem.',
  'Kingsville Elementary',
  'Kingsville Elementary School',
  'Kingsville High',
  'Kingsway Elementary School',
  'Kingsway Regional High School',
  'Kingsway Regional Middle School',
  'Kingswood Elementary',
  'Kingswood Elementary School',
  'Kingswood K-8',
  'Kingswood Regional High School',
  'Kingswood Regional Middle School',
  'Kingswood School',
  'Kingwestwood Elementary School',
  'Kingwood Elementary',
  'Kingwood H S',
  'Kingwood Middle',
  'Kingwood Park H S',
  'Kingwood Township School',
  'Kinjo Gakuin University',
  'Kinkeade Early Childhood',
  'Kinki University',
  'Kinloch Elementary School',
  'Kinloch Park Elementary School',
  'Kinloch Park Middle School',
  'Kinnaman Elementary School',
  'Kinnan Elementary School',
  'Kinnelon High School',
  'Kinney High (continuation)',
  'Kinney School',
  'Kinnikinnick School',
  'Kino Junior High School',
  'Kinoshita Elementary',
  'Kinport Middle School',
  'Kinross Correctional Facility',
  'Kinry Road Elementary School',
  'Kinsella Magnet School Of Performing Arts',
  'Kinsey Elementary School',
  'Kinsey School',
  'Kinsley Jr/sr High School',
  'Kinsley-offerle Elementary School K-6',
  'Kinsner Elementary School',
  'Kinston High',
  'Kinston School',
  'Kinta Es',
  'Kinta Hs',
  'Kinterbish Junior High School',
  'Kinyon Elementary School',
  'Kinzie Elem School',
  'Kiona-benton City High School',
  'Kiona-benton City Middle School',
  'Kiona-benton City Primary School',
  'Kiona-benton Intermediate School',
  'Kiowa County Elem/jr. High',
  'Kiowa County High School',
  'Kiowa Elementary School',
  'Kiowa Es',
  'Kiowa High School',
  'Kiowa Hs',
  'Kiowa Middle School',
  'Kipapa Elementary School',
  'Kipling Elem School',
  'Kipp 3d Academy',
  'Kipp Academy Boston Charter School',
  'Kipp Academy Charter School',
  'Kipp Academy Chicago Campus',
  'Kipp Academy Lynn Charter School',
  'Kipp Academy Middle',
  'Kipp Academy Nashville',
  'Kipp Academy Nashville Elementary School',
  'Kipp Academy Of Innovation',
  'Kipp Academy Of Opportunity',
  'Kipp Academy West Middle',
  'Kipp Adelante Preparatory Academy',
  'Kipp Always Mentally Prepared Charter School',
  'Kipp Aspire Academy',
  'Kipp Atlanta Collegiate',
  'Kipp Austin Academy Of Arts & Letters',
  'Kipp Austin Beacon Prep',
  'Kipp Austin Brave',
  'Kipp Austin College Prep',
  'Kipp Austin Collegiate',
  'Kipp Austin Comunidad',
  'Kipp Austin Connections El',
  'Kipp Austin Leadership El',
  'Kipp Austin Obras',
  'Kipp Austin Vista Middle Schools',
  'Kipp Bayview Academy',
  'Kipp Believe',
  'Kipp Blytheville College Preparatory School',
  'Kipp Blytheville Collegiate High School',
  'Kipp Booker T Washington',
  'Kipp Bridge Academy',
  'Kipp Camino Academy',
  'Kipp Central City',
  'Kipp Central City Academy',
  'Kipp Charlotte',
  'Kipp Chicago Charter Schools',
  'Kipp Chicago Chrtrs - Ascend Acad',
  'Kipp Chicago Chrtrs Bloom Campus',
  'Kipp Climb Academy',
  'Kipp Columbus',
  'Kipp Comienza Community Prep',
  'Kipp Connect Middle',
  'Kipp Connect Pri',
  'Kipp Corazon Academy',
  'Kipp Dc Aim Academy Pcs',
  'Kipp Dc Arts And Technology Academy Pcs',
  'Kipp Dc College Preparatory Academy Pcs',
  'Kipp Dc Connect Academy Pcs',
  'Kipp Dc Discover Academy Pcs',
  'Kipp Dc Grow Academy Pcs',
  'Kipp Dc Heights Academy Pcs',
  'Kipp Dc Key Academy Pcs',
  'Kipp Dc Lead Academy Pcs',
  'Kipp Dc Leap Academy Pcs',
  'Kipp Dc Northeast Academy Pcs',
  'Kipp Dc Pcs Promise Academy',
  'Kipp Dc Quest Academy Pcs',
  'Kipp Dc Spring Academy Pcs',
  'Kipp Dc Valor Academy Pcs',
  'Kipp Dc Will Academy Pcs',
  'Kipp Delta College Preparatory School',
  'Kipp Delta Collegiate High School',
  'Kipp Delta Elementary Literacy Academy',
  'Kipp Denver Collegiate High School',
  'Kipp Destiny El',
  'Kipp Destiny Middle',
  'Kipp Dream Prep',
  'Kipp Dubois Charter School',
  'Kipp Durham College Preparatory',
  'Kipp East Community',
  'Kipp Empower Academy',
  'Kipp Endeavor Academy',
  'Kipp Esperanza Dual Language Academy',
  'Kipp Excelencia Community Preparatory',
  'Kipp Explore Academy',
  'Kipp Gaston College Preparatory',
  'Kipp Generations Collegiate',
  'Kipp Halifax College Prep',
  'Kipp Harmony Academy',
  'Kipp Heartwood Academy',
  'Kipp Heritage Academy',
  'Kipp Houston H S',
  'Kipp Ignite Academy',
  'Kipp Iluminar Academy',
  'Kipp Indy College Prep Middle',
  'Kipp Indy Unite Elementary',
  'Kipp Infinity Charter School',
  'Kipp Inspire Academy',
  'Kipp Intrepid Preparatory School',
  'Kipp Jacksonville K-8',
  'Kipp King Collegiate High',
  'Kipp Leadership',
  'Kipp Legacy Preparatory School',
  'Kipp Liberation College Prep',
  'Kipp Los Angeles College Preparatory',
  'Kipp Memphis Academy Elementary',
  'Kipp Memphis Collegiate Elementary',
  'Kipp Memphis Collegiate High School',
  'Kipp Memphis Collegiate Middle',
  'Kipp Memphis Middle Academy',
  'Kipp Memphis Preparatory Elementary',
  'Kipp Memphis Preparatory Middle',
  'Kipp Memphis University Middle',
  'Kipp Minnesota Charter School',
  'Kipp Morial',
  'Kipp Nashville College Prep',
  'Kipp Nashville College Prep Elementary',
  'Kipp Nashville Collegiate High School',
  'Kipp Nexus Middle',
  'Kipp Nexus Pri',
  'Kipp North Star Primary',
  'Kipp Northeast College Preparatory',
  'Kipp Northeast Denver Leadership Academy',
  'Kipp Northeast Denver Middle School',
  'Kipp Northeast Elementary',
  'Kipp Nyc Washington Heights Academy Charter School',
  'Kipp Okc South Community Prep',
  'Kipp One Academy',
  'Kipp Peace El',
  'Kipp Philadelphia Charter School',
  'Kipp Philosophers Academy',
  'Kipp Pleasant Grove Middle',
  'Kipp Pleasant Grove Pri',
  'Kipp Poder Academy',
  'Kipp Polaris Academy For Boys',
  'Kipp Prime College Preparatory',
  'Kipp Prize Preparatory Academy',
  'Kipp Promesa Prep',
  'Kipp Raices Academy',
  'Kipp Reach College Preparatory',
  'Kipp Renaissance',
  'Kipp San Francisco Bay Academy',
  'Kipp San Francisco College Preparatory',
  'Kipp San Jose Collegiate',
  'Kipp Scholar Academy',
  'Kipp Sharp College Prep',
  'Kipp Sharpstown College Preparatory',
  'Kipp Shine Prep',
  'Kipp Sol Academy',
  'Kipp South Fulton Academy School',
  'Kipp Spirit College Prep',
  'Kipp St. Louis High',
  'Kipp Star College Prep Charter',
  'Kipp Strive Academy',
  'Kipp Strive Primary',
  'Kipp Summit Academy',
  'Kipp Sunnyside H S',
  'Kipp Sunrise Academy',
  'Kipp Sunshine Peak Academy',
  'Kipp Tech Valley Charter School',
  'Kipp Triumph Academy',
  'Kipp Truth Academy',
  'Kipp Truth El',
  'Kipp Tulsa Acad. College Prep.',
  'Kipp Un Mundo Dual Language Academy',
  'Kipp Unity Pri',
  'Kipp University Prep H S',
  'Kipp Valiant Community Prep',
  'Kipp Victory Academy',
  'Kipp Vida Preparatory Academy',
  'Kipp Vision',
  'Kipp Vision Primary',
  'Kipp Voice / Kipp Impact K-8',
  'Kipp Voice Elementary School',
  'Kipp Voyage Academy For Girls',
  'Kipp Ways Primary School',
  'Kipp West Atlanta Young Scholars Academy',
  'Kipp West Philadelphia Cs',
  'Kipp West Philadelphia Preparatory Chart',
  'Kipp Wisdom Academy',
  'Kipp Zenith Academy',
  'Kipp: Cooper Norcross A New Jersey Nonprofit Corporation',
  'Kipps Elementary',
  'Kiptopeke Elementary',
  'Kirby Dorothy Camp',
  'Kirby Elementary School',
  'Kirby High',
  'Kirby High School',
  'Kirby Middle',
  'Kirby Middle School',
  "Kirby's Mill Elementary School",
  'Kirby-smith Middle School',
  'Kirbyville El',
  'Kirbyville Elem.',
  'Kirbyville H S',
  'Kirbyville J H',
  'Kirbyville Middle',
  'Kircher School',
  'Kirchliche Hochschule Bethel',
  'Kirchliche Hochschule Wuppertal',
  'Kirikkale University',
  'Kiriri Womens University of Science and Technology',
  'Kirk (george V.) Middle School',
  'Kirk Adams Elementary School',
  'Kirk El',
  'Kirk Elementary',
  'Kirk Elementary School',
  'Kirk School',
  'Kirkbride Eliza B Sch',
  'Kirkersville Elementary School',
  'Kirkland Early Childhood Ctr',
  'Kirkland Elementary School',
  'Kirkland Middle School',
  'Kirklareli University',
  'Kirkman Park Elementary',
  'Kirkmere School',
  'Kirkpatrick El',
  'Kirkpatrick Elementary',
  'Kirkpatrick Health /wellness',
  'Kirkpatrick Middle',
  'Kirksey El',
  'Kirksey Middle School',
  'Kirksville Area Tech. Ctr.',
  'Kirksville Elementary School',
  'Kirksville Primary',
  'Kirksville Sr. High',
  'Kirkwood Community College',
  'Kirkwood Early Child. Ctr.',
  'Kirkwood Elementary',
  'Kirkwood Elementary School',
  'Kirkwood Sr. High',
  'Kiroli Elementary School',
  'Kirtland Community College',
  'Kirtland Elementary',
  'Kirtland Elementary School',
  'Kirtland High School',
  'Kirtland Middle',
  'Kirtland Middle School',
  'Kirtland Pre-k Early',
  'Kiryas Joel Village School',
  'Kiser Elementary',
  'Kiser Elementary School',
  'Kiser Intermediate',
  'Kiser Middle',
  'Kiser Prek-6 School',
  'Kishwaukee College',
  'Kishwaukee Elem School',
  'Kisii University',
  'Kiski Area East Primary Sch',
  'Kiski Area Hs',
  'Kiski Area Ihs',
  'Kiski Area North Primary Sch',
  'Kiski Area South Primary Sch',
  'Kiski Area Upper El Sch',
  'Kismet Elem',
  'Kissel Hill El Sch',
  'Kissimmee Charter Academy',
  'Kissimmee Elementary School',
  'Kissimmee Middle School',
  'Kisthardt Elementary School',
  'Kit Carson Elementary',
  'Kit Carson Elementary School',
  'Kit Carson International Academy',
  'Kit Carson Junior-senior High School',
  'Kitakyushu University',
  'Kitami Institute of Technology',
  'Kitasato University',
  'Kitsap Co Detention Ctr',
  'Kitsap Lake Elementary',
  'Kittatinny Regional High School',
  'Kittitas B-5 Special Ed Program',
  'Kittitas Elementary School',
  'Kittitas High School',
  'Kittredge',
  'Kittredge Magnet School',
  'Kittrell Elementary',
  'Kittrell Elementary School',
  'Kittridge Street Elementary',
  'Kittson Central Elementary',
  'Kittson Central Secondary',
  'Kitty Hawk Elementary School',
  'Kitty Hawk Middle',
  'Kitty Mcdonough Ward Elementary School',
  'Kitty Stone Elementary School',
  'Kiva Elementary School',
  'Klahowya Secondary',
  'Klaipeda University',
  'Klamath Community College',
  'Klamath Learning Center',
  'Klamath River Elementary',
  'Klamath Union High School',
  'Klatt Elementary',
  'Klawock City School',
  'Kleb Int',
  'Kleberg El',
  'Klein Annex',
  'Klein Cain H S',
  'Klein Collins H S',
  'Klein Education Placement Program',
  'Klein El Sch',
  'Klein Elementary - 08',
  'Klein Forest H S',
  'Klein H S',
  'Klein Int',
  'Klein Oak H S',
  'Klein Road El',
  'Klein Sems',
  'Klem Road North Elementary School',
  'Klem Road South Elementary School',
  'Klenk El',
  'Klentzman Int',
  'Klepinger Community School',
  'Kleptz Early Childhood Learning Center',
  'Klickitat Elem & High',
  'Kline Whitis El',
  'Klinger Ms',
  'Klockner Elementary School',
  'Kloefkorn Elementary',
  'Klondike Elementary School',
  'Klondike Isd',
  'Klondike Lane Elementary',
  'Klondike Middle School',
  'Klondike Preparatory Academy',
  'Kluckhohn Elementary School',
  'Kluge Elementary',
  'Klukwan School',
  'Km Global Sch',
  'Kms Learning Center',
  'Knapp Charter Academy',
  'Knapp El Sch',
  'Knapp Elementary',
  'Knapp Elementary School',
  'Knapp Forest Elementary School',
  'Knappa High School',
  'Knd Elementary',
  'Kneeland Elementary',
  'Knees School',
  'Knickerbacker Middle School',
  'Knickerbocker School',
  'Knickrehm Elementary School',
  'Knight Elementary',
  'Knight Elementary School',
  'Knight Enloe Elementary School',
  'Knight Middle',
  'Knight Road Elementary',
  'Knightdale Elementary',
  'Knightdale High',
  'Knights Academy',
  'Knights Elementary School',
  'Knights Ferry Elementary',
  'Knightsbridge University',
  'Knightsen Elementary',
  'Knightstown Elementary School',
  'Knightstown High School',
  'Knightstown Intermediate School',
  'Knightsville Elementary',
  'Knik Elementary School',
  'Knippa School',
  'Knob Hill Elementary',
  'Knob Noster Elem.',
  'Knob Noster High',
  'Knob Noster Middle',
  'Knoch Hs',
  'Knoch Ms',
  'Knoll Elementary',
  'Knolls Elementary',
  'Knolls Vista Elementary',
  'Knollwood Elementary',
  'Knollwood Elementary School',
  'Knollwood Heights Elementary - 08',
  'Knollwood Preparatory Academy',
  'Knollwood School',
  'Knott County Area Technology Center',
  'Knott County Central High School',
  'Knotts Island Elementary',
  'Knowledge Academies High School',
  'Knowledge Academy',
  'Knowledge Advanced Skills',
  'Knowledge And Power Prep Academy International High School',
  'Knowledge And Power Prep Academy Iv',
  'Knowledge And Power Preparatory Academy - Kappa Vii',
  'Knowledge And Power Preparatory Academy Iii',
  'Knowledge And Power Preparatory Academy Vi',
  'Knowledge College for Science and Technology',
  'Knowledge Enlightens You (key) Academy',
  'Knowledge International University',
  'Knowledge Quest Academy',
  'Knowlton El',
  'Knowlton School',
  'Knowlton Township Board Of Education',
  'Knox Adaptive Education Center',
  'Knox Appalachian School',
  'Knox Central High School',
  'Knox City El',
  'Knox City H S',
  'Knox Co. Elem.',
  'Knox Co. High',
  'Knox College',
  'Knox Community Elementary School',
  'Knox Community High School',
  'Knox Community Middle School',
  'Knox County Area Technology Center',
  'Knox County Career Center',
  'Knox County Career Center 4',
  'Knox County Day Treatment',
  'Knox County Learning Academy',
  'Knox County Middle School',
  'Knox Doss Middle School At Drakes Creek',
  'Knox Early Childhood Campus',
  'Knox El',
  'Knox Elementary School',
  'Knox Gifted Academy',
  'Knox Inspire Academy',
  'Knox J H',
  'Knox Junior High School',
  'Knox Middle',
  'Knox Trail Middle School',
  'Knox Wiley Middle',
  'Knoxville Cntr Student Success',
  'Knoxville High School',
  'Knoxville Jr High School',
  'Knoxville Middle School',
  'Knoxville Sr High School',
  'Knudsen Elementary School',
  'Koa Elementary School',
  'Kobe City University of ForeignStudies',
  'Kobe Design University',
  'Kobe Gakuin University',
  'Kobe International University',
  'Kobe Jogakuin University',
  'Kobe Pharmaceutical University',
  "Kobe Shinwa Women's University",
  "Kobe Shoin Women's University",
  'Kobe University',
  'Kobe University of Mercantile Marine',
  "Kobe Women's University",
  'Kobuk School',
  'Koc University',
  'Kocaeli University',
  'Koch Elem.',
  'Koch Elementary - 03',
  'Kochi Medical School',
  'Kochi University',
  'Kochi University of Technology',
  "Kochi Women's University",
  'Kocurek El',
  'Koda Middle School',
  'Kodiak High School',
  'Kodiak Middle School',
  'Kodolanyi Janos University College',
  'Koelsch Elementary School',
  'Koenig Elementary',
  'Koennecke El',
  'Koepsell Elementary School',
  'Kofa High School',
  'Koforidua Polytechnic',
  'Kogakkan University',
  'Kogakuin University',
  'Kogi State University',
  'Kohala Elementary School',
  'Kohala High School',
  'Kohala Middle School',
  'Kohat University of Science and Technology  (KUST)',
  'Kohfeldt El',
  'Kohinoor School of Hospitality Management',
  'Kohl Elementary School',
  'Kohl Open Elementary',
  'Kohlberg El',
  'Kohler Elementary',
  'Kohler High',
  'Kohler Middle',
  'Kohrville El',
  "Koka Women's College",
  'Kokand State Pedagogical Institute',
  'Kokanee Elementary',
  'Kokesh Area Learning Center',
  'Kokhanok School',
  'Koko Head Elementary School',
  'Kokomo Area Career Center',
  'Kokomo High School',
  'Kokugakuin University',
  'Kokushikan University',
  'Kolb Elementary School',
  'Kolb Middle',
  'Kolda El',
  'Kolej Universiti Insaniah',
  'Koliganek School',
  'Kolling Elementary School',
  'Kolmar Avenue Elem School',
  'Kolmar Elem School',
  'Koloa Elementary School',
  'Kolter El',
  'Komachin Middle School',
  'Komar University of Science and Technology',
  'Komarek Elem School',
  'Komazawa University',
  'Komensky Elem School',
  'Komsomolsk-on-Amur State Technical University',
  'Kona Pacific - Pcs',
  'Konan University',
  "Konan Women's University",
  'Konawa Es',
  'Konawa Hs',
  'Konawa Ms',
  'Konawaena Elementary School',
  'Konawaena High School',
  'Konawaena Middle School',
  'Kongju National University',
  'Kongju National University of Education',
  'Konkan Agricultural University',
  'Konkuk University',
  'Konnoak Elementary',
  'Konocti Education Center',
  'Konocti Preschool',
  'Konya Gida Tarim University',
  'Konyang University',
  'Kooken Ed Ctr',
  'Kookmin University',
  'Koontz Elementary',
  'Koontz Intermediate',
  'Kooser Elementary School',
  'Koosharem School',
  'Kootenai Bridge Academy',
  'Kootenai Elementary School',
  'Kootenai Jr/sr High School',
  'Kopachuck Middle School',
  'Kopperl School',
  'Korea Advanced Institute of Science & Technology',
  'Korea Maritime University',
  'Korea National Open University',
  'Korea National University of Arts',
  'Korea National University of Education',
  'Korea National University of Physical Education',
  'Korea University',
  'Korger-chestnut',
  "Koriyama Women's University and College",
  'Kornblum',
  'Korte Elem.',
  'Kosciusko Alternative School',
  'Kosciusko Attala Career Technical C',
  'Kosciusko Junior High School',
  'Kosciusko Lower Elem',
  'Kosciusko Middle',
  'Kosciusko Senior High School',
  'Kosciusko Upper Elem',
  'Kosciuszko Elementary',
  'Kosciuszko School',
  'Koshien University',
  'Koshkonong Elem.',
  'Koshkonong High',
  'Koshkonong Trails School',
  'Kosin University',
  'Kossuth Elementary School',
  'Kossuth High School',
  'Kossuth Lajos University',
  'Kossuth Middle School',
  'Kostanai State University',
  'Kostoryz El',
  'Kostroma State Technological University',
  'Kota Bharu Polytechnic',
  'Kota Kinabalu Polytechnic',
  'Kotelawala Defence University',
  'Kotlik School',
  'Kotzebue Middle/high School',
  'Kountze El',
  'Kountze H S',
  'Kountze Int',
  'Kountze Middle',
  'Kouts Elementary School',
  'Kouts Middle/high School',
  'Koya University (Kurdistan Region)',
  'Koyasan University',
  'Koyuk-malimiut School',
  'Kozmetsky At Rathgeber Village',
  'Kozminski Elem Community Academy',
  'Kraemer Middle',
  'Kraft Meadows Middle School',
  'Krahn El',
  'Kramer Elementary School',
  'Kramer Lane Elementary School',
  'Kramer Ms',
  'Krasnoyarsk State Technical University',
  'Kratt Elementary',
  'Kratz Elem.',
  'Kratzer Sch',
  'Kraus Middle School',
  'Krause El',
  'Kreamer Street Elementary School',
  'Krebs Public School',
  'Kreinhop El',
  'Kreitner Elem School',
  'Kreiva Academy Public Charter School (h)',
  'Kreiva Academy Public Charter School (m)',
  'Kremlin-hillsdale Es',
  'Kremlin-hillsdale Hs',
  'Kresa Head Start Clc',
  'Kresa Head Start New Life',
  'Kresa Head Start Pcc',
  'Kresa Head Start Sjc',
  'Kresa Head Start West Main',
  'Kress El',
  'Kress H S',
  'Kresson School',
  'Kreutz Creek El Sch',
  'Kriewald Rd El',
  'Krimmel Intermed',
  'Krirk University',
  'Krishna University',
  'Krisle Elementary',
  'Kromrey Middle',
  'Krotz Springs Elementary School',
  'Krouch Es',
  'Krout 2-3 Elementary',
  'Krueger El',
  'Krueger Middle',
  'Krum Early Education Center',
  'Krum H S',
  'Krum Middle',
  'Kruse El',
  'Kruse Elementary School',
  'Krystal School Of Science Math & Technology',
  'Kua O Ka La - Ncpcs',
  'Kuala Lumpur Infrastructure University College',
  'Kuala Lumpur Metropolitan University',
  'Kuala Terengganu City Polytechnic',
  'Kualapuu School - Public Conversion Charter',
  'Kuban State University',
  'Kuban State University of Agriculture',
  'Kuban State University of Technology',
  'Kuching Polytechnic',
  'Kuehnhaven Middle School',
  'Kuehnle El',
  'Kuentz El',
  'Kuinerrarmiut Elitnaurviat',
  'Kujawa Ec/pre-k Center',
  'Kujawa El',
  'Kula Aupuni Niihau A Kahelelani Aloha - Ncpcs',
  'Kula Elementary School',
  'Kullerstrand Elementary School',
  'Kulm Elementary School',
  'Kulm High School',
  'Kulp El Sch',
  'Kulshan Middle School',
  'Kumamoto Gakuen University',
  'Kumamoto Institute of Technology',
  'Kumamoto Prefectural University',
  'Kumamoto University',
  'Kumaraguru College of Technology',
  'Kumasi Polytechnic',
  'Kumaun University',
  'Kumeyaay Elementary',
  'Kumoh National University of Technology',
  'Kun Shan University',
  'Kuna High School',
  'Kuna Middle School',
  'Kuna Professional Technical',
  'Kung Shan Institute of Technology',
  'Kunitachi College of Music',
  'Kunkel El Sch',
  'Kunmimg University of Science and Technology',
  'Kunsan National University',
  'Kunsmiller Creative Arts Academy',
  'Kunst (tommie) Junior High',
  'Kunstakademie Düsseldorf.',
  'Kunsthochschule Berlin-Weissensee Hochschule für Gestaltung',
  'Kurashiki Sakuyo University',
  'Kurashiki University of Science and the Arts',
  'Kurdistan University of Medical Sciences',
  'Kure University',
  'Kurgan International University',
  'Kursk State Medical University',
  'Kursk State Technical University',
  'Kursk State University',
  'Kurt Hahn Expeditionary Learning School',
  'Kurth Pri',
  'Kurtz Elementary School',
  'Kurukshetra University',
  'Kurume Institute of Technology',
  'Kurume University',
  'Kuser Elementary School',
  'Kushiro Public University of Economics',
  'Kuskokwim Learning Academy',
  'Kutz El Sch',
  'Kutztown Area Ms',
  'Kutztown Area Shs',
  'Kutztown El Sch',
  'Kutztown University of Pennsylvania',
  'Kuumba Academy Charter School',
  'Kuvempu University',
  'Kuwait International Law School',
  'Kuwait Maastricht Business School',
  'Kuwait University',
  'Kuzbass State Technical University',
  'Kw-vc Elementary',
  'Kwame Nkrumah University of Science and Technology',
  'Kwan Dong University',
  'Kwangju National University of Education',
  'Kwangju University',
  'Kwangwoon University',
  'Kwansei Gakuin University',
  'Kwantleen University College',
  'Kwara State Polytecnic',
  'Kwara State University',
  'Kwararafa University',
  "Kwassui Women's College",
  'Kwigillingok School',
  'Kwis Elementary',
  'Ky Advanced Tech Center',
  'Ky Tech - Bowling Green Regional Tech Center',
  'Kyambogo University',
  'Kyffin Elementary School',
  'Kyle El',
  'Kyle Elementary School',
  'Kyle R. Wilson Elementary School',
  'Kynoch Elementary',
  'Kyonggi University',
  'Kyorin University',
  'Kyoritsu Pharmaceutical University',
  "Kyoritsu Woman's University",
  'Kyoto Bunkyo University',
  'Kyoto City University of Arts',
  'Kyoto Gakuen University',
  'Kyoto Institute of Technology',
  'Kyoto Pharmaceutical University',
  'Kyoto Prefectural University',
  'Kyoto Prefectural University of Medicine',
  'Kyoto Sangyo University',
  'Kyoto Seika University',
  "Kyoto Tachibana Women's University",
  'Kyoto University',
  'Kyoto University of Art and Design',
  'Kyoto University of Education',
  'Kyoto University of Foreign Studies',
  "Kyoto Women's University",
  'Kyrene Akimel A-al Middle School',
  'Kyrene Altadena Middle School',
  'Kyrene Aprende Middle School',
  'Kyrene Centennial Middle School',
  'Kyrene De La Colina School',
  'Kyrene De La Esperanza School',
  'Kyrene De La Estrella Elementary School',
  'Kyrene De La Mariposa School',
  'Kyrene De La Mirada School',
  'Kyrene De La Paloma School',
  'Kyrene De La Sierra School',
  'Kyrene De Las Brisas School',
  'Kyrene De Las Lomas School',
  'Kyrene De Las Manitas School',
  'Kyrene De Los Cerritos School',
  'Kyrene De Los Lagos School',
  'Kyrene De Los Ninos School',
  'Kyrene Del Cielo School',
  'Kyrene Del Milenio',
  'Kyrene Del Norte School',
  'Kyrene Del Pueblo Middle School',
  'Kyrene Middle School',
  'Kyrene Monte Vista School',
  'Kyrene Traditional Academy',
  'Kyrgyz National Agrarian University',
  'Kyrgyz National University',
  'Kyrgyz Russian Slavic University',
  'Kyrgyz State Technical University',
  'Kyrgyz State University of Construction Transportation and Architecture',
  'Kyrgyz Turkish  Manas University',
  'Kyrock Elementary School',
  'Kyunghee University',
  'Kyungil University',
  'Kyungnam University',
  'Kyungpook National University',
  'Kyungsung University',
  'Kyungwon University',
  'Kyushu Dental College',
  'Kyushu Institute of Design',
  'Kyushu Institute of Technology',
  'Kyushu International University',
  'Kyushu Kyoritsu University',
  'Kyushu Sangyo University',
  'Kyushu Tokai University',
  'Kyushu University',
  'Kyushu University of Nursing and SocialWelfare',
  "Kyushu Women's University",
  'Károl Gáspár University of the Reformed Church',
  'L & L MONTESSORI SCHOOL',
  'L A Gililland El',
  'L A Muldown School',
  'L A Webber Middle-high School',
  'L B J El',
  'L B Johnson El',
  'L B Morris El Sch',
  'L B Yancey Elementary',
  'L C Swain Middle School',
  'L C Y C',
  'L D Batchelder',
  'L D Brady Elem School',
  'L E Berger Elementary School',
  'L E Claybon El',
  'L E Fletcher Technical Community College',
  'L E Monahan El',
  'L E Starke Elem School',
  'L E Willson Elementary School',
  'L F Dewing',
  'L F Smith Elementary',
  'L G Alarcon El',
  'L G Nourse Elementary',
  'L G Pinkston H S',
  'L Gilbert Carroll Middle',
  'L H Coffin',
  'L Isd J J A E P',
  'L J Bell Elementary',
  'L J Hauser Jr High School',
  'L J Stevens Intermediate School',
  'L L Hotchkiss El',
  'L M Prince School',
  'L N Stem Academy',
  'L O Donald El',
  'L P Quinn Elementary School',
  'L P Waters Early Childhood Center',
  'L Pearl Palmer Elementary School',
  'L R E I',
  'L V Stockard Middle',
  "L'ECOLE FRANCAISE DU MAINE",
  "L'ETOILE FRENCH IMMERSION SCHOOL",
  "L'HERITAGE FRANCAIS",
  "L'Université de l'Entreprise",
  "L'etoile Du Nord French Immersion L",
  "L'etoile Du Nord French Immersion U",
  "L'ouverture Computer Technology Magnet",
  'L. A. Ainger Middle School',
  'L. A. Chaffin Jr. High School',
  'L. B. Nelson Elementary',
  'L. B. Williams Elementary - 03',
  'L. D. Mcarthur Elementary School',
  'L. Douglas Wilder Middle',
  'L. E. Gable Middle',
  'L. Hollingworth School For Talented And Gifted',
  'L. K. Moss Elementary School',
  'L. L. Owen Elementary School',
  'L. Leo Judice Elementary School',
  'L. P. Collins Elementary',
  'L. R. Green Elementary',
  'L. T. Ball Intermediate School',
  'L. Thomas Heck Middle School',
  'L. W. Conder Elementary',
  'L. W. Ruppel Academy For Advanced Studies',
  'L. W. Westfall Es',
  'L.A. COUNTY FAIR ASSOC. CHILD DEVELOPMENT CENTER',
  'L.D.College of Engineering',
  'L.I.F.T ACADEMY',
  'L.N. Gumilyov Eurasian National University',
  'L.a. County High School For The Arts',
  'L.b. Johnson Elementary School',
  'L.b. Johnson Middle',
  'L.f. Addington Middle',
  'L.f. Henderson Interm. Sch.',
  'L.f. Palmer Elementary',
  'L.h. Marrero Middle School',
  'L.h. Tanglen Elementary',
  'L.j. Alleman Middle School',
  'L.l. Beazley Elementary',
  'L.p. Monteleone Junior High School',
  'L.p. Vaughn Elementary & Middle School',
  'L.s. Rugg Elementary School',
  'L.w. Higgins High School',
  'LA ACADEMIA AT THE DENVER INNER CITY PARISH',
  'LA AMISTAD EDUCATIONAL CENTER',
  'LA CANADA PREPARATORY SCHOOL',
  'LA CASA MONTESSORI SCHOOL',
  'LA CASITA BILINGUE MONTESSORI SCHOOL',
  'LA CHEIM SCHOOL',
  'LA CHEIM SCHOOL, INC.',
  'LA CORE CHRISTIAN ELEMENTARY SCHOOL',
  'LA COSTA VALLEY PRESCHOOL & KINDERGARTEN',
  'LA CRESCENTA MONTESSORI SCHOOL',
  'LA GRANDE ADVENTIST CHRISTIAN SCHOOL',
  'LA JOLLA COUNTRY DAY SCHOOL',
  'LA LUMIERE SCHOOL',
  'LA MARIPOSA MONTESSORI',
  'LA MIRADA HEIGHTS CHRISTIAN PRESCHOOL & ELEMENTARY',
  'LA PETITE ACADEMY',
  'LA PETITE ACADEMY - FAIRVIEW',
  'LA PETITE ECOLE',
  'LA PETITE ECOLE / ENGLISH FRENCH LEARNING ACADEMY',
  'LA PETITE MONTESSORI SCHOOL LLC',
  'LA PIETRA HAWAII SCHOOL FOR GIRLS',
  'LA PRIMA CASA MONTESSORI SCHOOL',
  'LA PRINTANIERE MONTESSORI',
  'LA PROGRESIVA SCHOOL',
  'LA PURISIMA CONCEPCION SCHOOL',
  'LA PURISIMA SCHOOL',
  'LA QUINTA CHRISTIAN FELLOWSHIP',
  'LA REINA HIGH SCHOOL',
  'LA SALLE ACADEMY',
  'LA SALLE CATHOLIC COLLEGE PREPARATORY',
  'LA SALLE CATHOLIC SCHOOL',
  'LA SALLE COLLEGE HIGH SCHOOL',
  'LA SALLE HIGH SCHOOL',
  'LA SALLE INSTITUTE',
  'LA SALLE PERU CHRISTIAN SCHOOL',
  'LA SALLE SCHOOL',
  'LA SCALA PRESCHOOL LEARNING CENTER',
  'LA SCUOLA',
  "LA SCUOLA D'ITALIA",
  'LA SIERRA ACADEMY',
  'LACONIA CHRISTIAN ACADEMY',
  'LACORDAIRE ACADEMY',
  'LAD N LASSIE PRESCHOOL',
  'LADERA HEIGHTS PREP DEVELOPMENT CENTER',
  'LAFAYETTE CHRISTIAN ACADEMY',
  'LAFAYETTE CHRISTIAN SCHOOL',
  'LAFAYETTE SCHOOL',
  'LAGO MAR PRESCHOOL 1',
  'LAGO MAR PRESCHOOL II',
  'LAGRANGE ACADEMY',
  'LAGUNA MADRE CHRISTIAN ACADEMY',
  'LAGUNA NIGUEL JR ACADEMY',
  'LAGUNA NIGUEL MONTESSORI CENTER',
  'LAHR-WELL CHRISTIAN ACADEMY',
  'LAINGSBURG CHRISTIAN SCHOOL',
  'LAKE ALMANOR CHRISTIAN SCHOOL',
  'LAKE ANNE NURSERY KINDERGARTEN',
  'LAKE ARROWHEAD CHRISTIAN SCHOOL',
  'LAKE AVENUE CHURCH SCHOOL',
  'LAKE BROOK ACADEMY',
  'LAKE BUTLER CHRISTIAN ACADEMY',
  'LAKE CASTLE PRIVATE SCHOOL',
  'LAKE CATHOLIC HIGH SCHOOL',
  'LAKE CENTER CHRISTIAN SCHOOL',
  'LAKE CHAMPLAIN WALDORF SCHOOL',
  'LAKE CITY CHRISTIAN ACADEMY',
  'LAKE CITY JR ACADEMY',
  'LAKE COUNTRY CHRISTIAN SCHOOL',
  'LAKE COUNTRY LUTHERAN HIGH SCHOOL',
  'LAKE COUNTRY MONTESSORI',
  'LAKE COUNTRY SCHOOL',
  'LAKE COUNTY BAPTIST SCHOOL',
  'LAKE FERN MONTESSORI ACADEMY',
  'LAKE FOREST ACADEMY',
  'LAKE FOREST PARK MONTESSORI',
  'LAKE FORREST PREPARATORY SCHOOL',
  'LAKE GRACE CHRISTIAN SCHOOL',
  'LAKE HIGHLAND PREPARATORY SCHOOL',
  'LAKE HILLS MONTESSORI AUSTIN CAMPUS',
  'LAKE HILLS MONTESSORI SCHOOL',
  'LAKE HOUSE ACADEMY',
  'LAKE JACKSON EDUCATIONAL YOUTH ACADEMY',
  'LAKE MAGDALENE UMC EARLY CHILDHOOD CENTER',
  'LAKE MARY PREPARATORY SCHOOL',
  'LAKE MEAD CHRISTIAN ACADEMY',
  'LAKE MICHIGAN ACADEMY',
  'LAKE MICHIGAN CATHOLIC SCHOOL',
  'LAKE MONTESSORI SCHOOL',
  'LAKE MURRAY MONTESSORI SCHOOL',
  'LAKE NELSON SDA SCHOOL',
  'LAKE NORMAN CHRISTIAN SCHOOL',
  'LAKE ORION BAPTIST SCHOOL',
  'LAKE PARK BAPTIST SCHOOL',
  'LAKE PLACID CHRISTIAN SCHOOL',
  'LAKE PLACID MONTESSORI ACADEMY LLC',
  'LAKE POINTE ACADEMY',
  'LAKE PREPARATORY SCHOOL, INC.',
  'LAKE REGION CHRISTIAN SCHOOL',
  'LAKE RIDGE ACADEMY',
  'LAKE ROSE CHRISTIAN ACADEMY',
  'LAKE SHORE CHRISTIAN ACADEMY',
  'LAKE SIDE',
  'LAKE TAHOE SCHOOL',
  'LAKE TAPPS MONTESSORI',
  'LAKE VIEW SCHOOL',
  'LAKE WASHINGTON GIRLS MIDDLE SCHOOL',
  'LAKE WORTH CHRISTIAN SCHOOL',
  'LAKECREST BAPTIST SCHOOL',
  'LAKEHILL PREPARATORY SCHOOL',
  'LAKELAND CHRISTIAN ACADEMY',
  'LAKELAND CHRISTIAN SCHOOL',
  'LAKELAND CROSSROADS SCHOOL',
  'LAKELAND GIRLS ACADEMY',
  'LAKELAND REGIONAL SCHOOL',
  'LAKELAND-ANDOVER SCHOOL',
  'LAKEMARY CENTER',
  'LAKES AREA MONTESSORI',
  'LAKES REGION CHRISTIAN SCHOOL',
  'LAKESHORE CHRISTIAN SCHOOL',
  'LAKESHORE MONTESSORI SCHOOL',
  'LAKESIDE ACADEMY',
  'LAKESIDE ALPHA',
  'LAKESIDE CHRISTIAN ACADEMY',
  'LAKESIDE EDUCATIONAL NETWORK',
  'LAKESIDE LUTHERAN HIGH SCHOOL',
  'LAKESIDE MONTESSORI',
  'LAKESIDE MONTESSORI ACADEMY LLC',
  'LAKESIDE MONTESSORI HIGHLANDS',
  'LAKESIDE MONTESSORI SCHOOL',
  'LAKESIDE PRESBYTERIAN CENTER FOR CHILDREN',
  'LAKESIDE SCHOOL',
  'LAKEVIEW',
  'LAKEVIEW ACADEMY',
  'LAKEVIEW BAPTIST ACADEMY',
  'LAKEVIEW CHRISTIAN ACADEMY',
  'LAKEVIEW CHRISTIAN SCHOOL',
  'LAKEVIEW JR-SR SW IN REGIONAL YOUTH VLG/HS',
  'LAKEVIEW LEARNING CENTER',
  'LAKEVIEW MARIA MONTESSORI PRESCHOOL',
  'LAKEVIEW SCHOOL',
  'LAKEVIEW SCHOOL ATT: LEON AUKER',
  'LAKEVILLE PAROCHIAL',
  'LAKEWOOD CATHOLIC ACADEMY',
  'LAKEWOOD CHEDER SCHOOL',
  'LAKEWOOD CHRISTIAN SCHOOL',
  'LAKEWOOD CHRISTIAN SCHOOLS',
  'LAKEWOOD LUTHERAN SCHOOL',
  'LAKEWOOD MONTESSORI',
  'LAKEWOOD MONTESSORI SCHOOL',
  'LAKEWOOD PARK CHRISTIAN SCHOOL',
  'LAKIN PRESCHOOL OF REFORM CONGREGATION OHEB SHAOLO',
  'LAKOTA WALDORF SCHOOL',
  'LAM CHRISTIAN ACADEMY',
  'LAMAR CHRISTIAN SCHOOL',
  'LAMAR SCHOOL',
  'LAMB OF GOD EV LUTHERAN SCHOOL',
  'LAMB OF GOD LUTHERAN SCHOOL',
  "LAMB'S GATE CHRISTIAN SCHOOL",
  'LAMBO ACADEMY INTERNATIONAL',
  'LAMONT CHRISTIAN SCHOOL',
  'LAMPLIGHTER MONTESSORI SCHOOL',
  'LANAKILA BAPTIST JR & SR HIGH SCHOOL',
  'LANCASTER BAPTIST SCHOOL',
  'LANCASTER CHRISTIAN SCHOOL',
  'LANCASTER COUNTY CHRISTIAN SCHOOL-WEST LAMPETER',
  'LANCASTER MENNONITE SCHOOL NEW DANVILLE',
  'LANCASTER MENNONITE SCHOOL-LANCASTER CAMPUS',
  'LANCASTER MONTESSORI SCHOOL',
  'LANCASTER-MONTESSORI PRE SCHOOL INC.',
  'LAND O LAKES CHRISTIAN SCHOOL',
  'LAND OF CHILDREN',
  'LAND OF LAKES MONTESSORI SCHOOL',
  'LAND PARK CAMPUS',
  'LANDENBERG CHRISTIAN ACADEMY',
  'LANDER GRINSPOON ACADEMY - SOLOMON SCHECHTER',
  'LANDMARK CHRISTIAN ACADEMY',
  'LANDMARK CHRISTIAN SCHOOL',
  'LANDMARK SCHOOL',
  'LANDMARK SCHOOL - ELEMENTARY MIDDLE SCHOOL',
  'LANDSDALE MONTESSORI SCHOOL',
  'LANGAN SCHOOL/CENTER FOR DISABILITY SERVICES',
  'LANHAM CHRISTIAN SCHOOL',
  'LANIER CHRISTIAN ACADEMY',
  'LANSDALE CATHOLIC HIGH SCHOOL',
  'LANSDOWNE FRIENDS SCHOOL',
  'LANSING BAPTIST SCHOOL',
  'LANSING CATHOLIC CENTRAL HIGH SCHOOL',
  'LANSING CHRISTIAN SCHOOL',
  'LAREN MONTESSORI SCHOOL',
  'LARKIN VALLEY AMISH SCHOOL',
  'LAS CRUCES ACADEMY',
  'LAS CRUCES CATHOLIC SCHOOL',
  'LAS VEGAS JUNIOR ACADEMY',
  'LASALLE ACADEMY',
  'LASCHOOL ACADEMY',
  'LASCUOLA DITALIA',
  'LASSETER ACADEMY',
  'LATHAM CHRISTIAN ACADEMY',
  'LATHAM SCHOOL',
  'LAUDERHILL COMMUNITY CHILD CARE CENTER',
  'LAURA E MASON CHRISTIAN ACADEMY',
  'LAUREL ACADEMY',
  'LAUREL FORK SCHOOL',
  'LAUREL HALL SCHOOL',
  'LAUREL HALL SCHOOL - FREDERICK',
  'LAUREL HALL SCHOOL - HAGERSTOWN',
  'LAUREL OAKS ADVENTIST SCHOOL',
  'LAUREL RUN SCHOOL',
  'LAUREL SCHOOL',
  'LAURELWOOD ACADEMY, INC.',
  'LAURENCE MANNING ACADEMY',
  'LAURENCE SCHOOL',
  'LAURINBURG NORMAL & INDUSTRIAL INSTITUTE',
  'LAVA HEIGHTS ACADEMY',
  'LAWERENCE SCHOOL - LOWER',
  'LAWRENCE ACADEMY',
  'LAWRENCE CATHOLIC ACADEMY',
  'LAWRENCE COUNTY CHRISTIAN SCHOOL',
  'LAWRENCE HALL SCHOOL',
  'LAWRENCE MONTESSORI SCHOOL',
  'LAWRENCE WOODMERE ACADEMY',
  'LAWTON ACADEMY OF ARTS & SCIENCES',
  'LAYTON CHRISTIAN ACADEMY',
  'LE CHAPERON ROUGE',
  'LE CHAPERON ROUGE - ROCKY RIVER',
  'LE CHAPERON ROUGE - SOLON',
  'LE CHAPERON ROUGE ELEMENTARY',
  'LE CHAPERON ROUGE-AVON LAKE',
  'LE JARDIN ACADEMY',
  'LE LYCEE FRANCAIS DE LOS ANGELES',
  'LE PETIT PAPILLON MONTESSORI SCHOOL',
  'LE PORT FOUNTAIN VALLEY',
  "LEA'S CHRISTIAN SCHOOL",
  'LEADERS PREPARATORY SCHOOL',
  'LEADERSHIP CHRISTIAN ACADEMY',
  'LEAFY OAK SCHOOL',
  'LEAGUE SCHOOL OF GREATER BOSTON',
  'LEAKE ACADEMY',
  'LEANING OAK SCHOOL',
  'LEAP & BOUND ACADEMY',
  'LEAP INTERNATIONAL MONTESSORI SCHOOL',
  'LEARN AND PLAY MONTESSORI MAPLE INC.',
  'LEARN AND PLAY MONTESSORI SCHOOL',
  'LEARN AS WE PLAY CHILD CARE CENTER',
  'LEARN WITH THE BEST SCHOOL, INC.',
  'LEARN, INC.',
  'LEARNING & ACHIEVEMENT ALL BOYS ACADEMY',
  'LEARNING & SHARING',
  'LEARNING ACADEMY',
  'LEARNING CIRCLE MONTESSORI SCHOOL',
  'LEARNING COMMUNITY INTERNATIONAL THE',
  'LEARNING DISABILITIES CLINIC- INC.',
  'LEARNING EXPECTATIONS AND ACADEMIC PERFORMANCE ACA',
  'LEARNING EXPERIENCE- THE',
  'LEARNING EXPRESS ACADEMY',
  'LEARNING LAB OF GREEN HILLS',
  'LEARNING PATHWAYS KINDERGARTEN',
  'LEARNING PROJECT ELEMENTARY SCHOOL',
  'LEARNING SCOPE ACADEMY',
  'LEARNING SKILLS ACADEMY',
  'LEARNING THEIR WAY',
  'LEARNING TREE',
  'LEARNING TREE CHRISTIAN SCHOOL',
  'LEARNING TREE EAST',
  'LEARNING TREE ELEMENTARY SCHOOL',
  'LEARNING TREE MONTESSORI SCHOOL',
  'LEARNING TREEINC',
  'LEARNING YEARS DAY SCHOOL',
  'LEARNINGSPRING SCHOOL',
  'LEARNINGTIME ACADEMY',
  'LEATHERWOOD CREEK',
  'LEBANON ACADEMY',
  'LEBANON CATHOLIC SCHOOL',
  'LEBANON LUTHERAN SCHOOL',
  'LEBANON UNITED METHODIST KINDERGARTEN',
  'LEBANON VALLEY CHRISTIAN SCHOOL',
  'LEE ACADEMY',
  'LEE ACADEMY FOR GIFTED EDUCATION',
  'LEE CHRISTIAN SCHOOL',
  'LEE-SCOTT ACADEMY',
  'LEEPERS',
  'LEES SUMMIT ACADEMY',
  'LEEWAY SCHOOL',
  'LEFFERTS GARDEN MONTESSORI',
  'LEGACY ACADEMY - CHRISTIAN UNIVERSITY-MODEL SCHOOL',
  'LEGACY ACADEMY SUWANEE DAM',
  'LEGACY CHRISTIAN ACADEMY',
  'LEGACY CHRISTIAN SCHOOL',
  'LEGACY CLASSICAL CHRISTIAN ACADEMY',
  'LEGACY CONNECTIONS VOCATIONAL SCHOOL',
  'LEGACY LEARNING CENTER',
  'LEGACY MONTESSORI SCHOOL',
  'LEGACY PREPARATORY CHRISTIAN ACADEMY',
  'LEGACY PRIMARY SCHOOL',
  'LEGACY SCHOOL',
  'LEGACY TREATMENT SERVICES, MARY A. DOBBINS SCHOOL',
  'LEGAL NAME BETHEL PRESCHOOL OF LEWES INC.',
  'LEGEND COLLEGE PREPARATORY',
  'LEHIGH CHRISTIAN ACADEMY',
  'LEHIGH VALLEY CHRISTIAN HIGH SCHOOL',
  'LEHIGH VALLEY SDA SCHOOL',
  'LEHMANN SCHOOL & TECH ED CENTER',
  'LEHRMAN COMMUNITY DAY SCHOOL',
  'LEIF ERICSON DAY SCHOOL',
  'LEILA DAY NURSERIES, INC.',
  'LEISURE ACRE SCHOOL',
  'LENAWEE CHRISTIAN SCHOOL',
  'LENOIR CITY CHRISTIAN ACADEMY',
  'LEO CATHOLIC HIGH SCHOOL',
  'LEO M BERNSTEIN JEWISH ACADEMY OF FINE ARTS',
  'LEON VALLEY CHRISTIAN ACADEMY',
  'LEONARD E BURKET CHRISTIAN SCHOOL',
  'LEONARD HALL JR NAVAL ACADEMY',
  'LEPORT MONESSORI',
  'LEPORT MONTESSORI FAIRFAX',
  'LEPORT MONTESSORI HERNDON',
  'LEPORT MONTESSORI RESTON',
  'LEPORT MONTESSORI SCHOOL',
  'LEPORT SCHOOL',
  'LEPORT SCHOOL - CARLSBAD VILLAGE',
  'LEPORT SCHOOL ALDIE',
  'LEPORT SCHOOL BROADLANDS',
  'LEPORT SCHOOL IRVINE WEST PARK',
  'LEPORT SCHOOLS',
  'LEPORT SCHOOLS - IRVINE SPECTRUM NORTH CAMPUS',
  'LERNER JEWISH COMMUNITY DAY SCHOOL',
  'LES ENFANTS MONTESSORI SCHOOL',
  "LES FINCH'S LEARNING TREE DAY",
  'LESLEY ELLIS SCHOOL',
  'LESTER COON ADVENTIST SCHOOL',
  'LEV BAIS YAAKOV',
  'LEVEL CROSS CHRISTIAN SCHOOL',
  'LEVELLAND CHRISTIAN SCHOOL',
  'LEVEY DAY SCHOOL',
  'LEVON & HASMIG TAVLIAN ARMENIAN PRESCHOOL & KDGN',
  'LEVY CHRISTIAN KINDERGARTEN',
  'LEWIS LITTLE FOLKS',
  'LEWISBURG BAPTIST ACADEMY',
  'LEXINGTON CATHOLIC HIGH SCHOOL',
  'LEXINGTON CHRISTIAN ACADEMY',
  'LEXINGTON HEARING & SPEECH CENTER',
  'LEXINGTON JUNIOR ACADEMY',
  'LEXINGTON LIFE ACADEMY',
  'LEXINGTON MONTESSORI SCHOOL',
  'LEXINGTON PARK BAPTIST CHURCH PRESCHOOL',
  'LEXINGTON SCHOOL FOR THE DEAF',
  'LEXINGTON UNIVERSAL ACADEMY',
  'LIAHONA ACADEMY FOR YOUTH',
  'LIAHONA PREPARATORY ACADEMY',
  'LIAL CATHOLIC SCHOOL',
  'LIBBY ADVENTIST CHRISTIAN SCHOOL',
  'LIBERTAS ACADEMY',
  'LIBERTAS CHRISTIAN SCHOOL',
  'LIBERTY ACADEMY',
  'LIBERTY BAPTIST ACADEMY',
  'LIBERTY BAPTIST SCHOOL',
  'LIBERTY BIBLE ACADEMY',
  'LIBERTY CHRISTIAN ACADEMY',
  'LIBERTY CHRISTIAN SCHOOL',
  'LIBERTY CLASSICAL ACADEMY',
  'LIBERTY FAITH CHRISTIAN ACADEMY',
  'LIBERTY FAITH CHURCH',
  'LIBERTY PREPARATORY CHRISTIAN ACADEMY',
  'LIBERTY UNIVERSITY ONLINE ACADEMY',
  'LIBERTYVILLE MONTESSORI SCHOOL',
  'LICCSAL Business College',
  'LICK-WILMERDING HIGH SCHOOL',
  'LICKING COUNTY CHRISTIAN ACADEMY',
  'LIFE ACADEMY',
  'LIFE ACADEMY OF PUGET SOUND',
  'LIFE CENTER ACADEMY',
  'LIFE CENTER CHRISTIAN ACADEMY',
  'LIFE CHRISTIAN ACADEMY',
  'LIFE CHRISTIAN SCHOOL',
  'LIFE CHURCH SCHOOL',
  'LIFE COMMUNITY SERVICE CENTER',
  'LIFE DEVELOPMENT INSTITUTE',
  'LIFE LEARNING COMMUNITY',
  'LIFE MISSION TRAINING CENTER',
  'LIFE MISSSION SCHOOL',
  'LIFE OF CHRIST CHRISTIAN ACADEMY',
  'LIFE POINT ACADEMY/ LIFE POINT CHURCH',
  'LIFE PREP ACADEMY',
  'LIFE PREPARATORY ACADEMY',
  'LIFE SONG MONTESSORI',
  'LIFE SOURCE CHRISTIAN SCHOOL',
  'LIFE TRAINING ACADEMY',
  'LIFEGATE CHRISTIAN SCHOOL',
  'LIFES ACADEMY',
  'LIFESCAPE CHILDRENS SPECIALTY SCHOOL',
  'LIFESHARE EDUCATIONAL RESOURCES',
  'LIFESKILLS OUTREACH LEARNING AND SUPPORTS',
  'LIFESPAN SCHOOL & DAYCARE',
  'LIFESTONE ACADEMY',
  'LIFESTYLE CHRISTIAN SCHOOL',
  'LIFETIME MONTESSORI',
  'LIFEWORKS SCHOOL AT ROCKBOURNE FALLS',
  'LIGHT CHRISTIAN ACADEMY',
  'LIGHT CHRISTIAN ACADEMY DAYCARE & EARLY CHLD DEVEL',
  'LIGHT OF FAITH CHRISTIAN ACADEMY',
  'LIGHT OF THE WORLD OF ATLANTA',
  'LIGHT OF THE WORLD SCHOOL',
  'LIGHTED WAY ASSOCIATION',
  'LIGHTHOUSE',
  'LIGHTHOUSE ACADEMY',
  'LIGHTHOUSE APOSTOLIC ACADEMY',
  'LIGHTHOUSE BAPTIST ACADEMY',
  'LIGHTHOUSE BAPTIST SCHOOL',
  'LIGHTHOUSE BILINGUAL ACADEMY',
  'LIGHTHOUSE CENTER FOR CREATIVE LEARNING',
  'LIGHTHOUSE CHRISTIAN ACADEMY',
  'LIGHTHOUSE CHRISTIAN LEARNING CENTER',
  'LIGHTHOUSE CHRISTIAN PREPARATORY ACADEMY',
  'LIGHTHOUSE CHRISTIAN SCHOOL',
  'LIGHTHOUSE CHRISTIAN SCHOOL DINSMORE',
  'LIGHTHOUSE CHRISTIAN SCHOOL MIDDLEBURG',
  'LIGHTHOUSE CHRISTIAN SCHOOL WESTSIDE',
  'LIGHTHOUSE CHRISTIAN SCHOOL-MANDARIN',
  'LIGHTHOUSE CHURCH SCHOOL',
  'LIGHTHOUSE EARLY LEARNING ACADEMY',
  'LIGHTHOUSE ISLAND ACADEMY',
  'LIGHTHOUSE MENNONITE SCHOOL',
  'LIGHTHOUSE MONTESSORI SCHOOL',
  'LIGHTHOUSE PREPARATORY ACADEMY',
  'LIGHTHOUSE PREPARATORY CHRISTIAN SCHOOL',
  'LIGHTHOUSE PRIVATE CHRISTIAN ACADEMY',
  'LIGHTHOUSE SCHOOL, INC',
  'LIGHTHOUSE SDA CHRISTIAN SCHOOL',
  'LIGO SCHOOL',
  'LIGUORI ACADEMY',
  'LIHIGH SCHOOL',
  'LILAC GROVE AMISH SCHOOL',
  'LILLIAN VALLELY SCHOOL',
  'LILLIPUT EARLY CHILDHOOD CENTER',
  'LIMA CENTRAL CATHOLIC HIGH SCHOOL',
  'LIMA CHRISTIAN SCHOOL',
  'LIMBERLOST CREEK SCHOOL',
  'LIMESTONE HILLS DAY SCHOOL',
  'LIMESTONE MENNONITE PAROCHIAL SCHOOL',
  'LIMESTONE RIDGE SCHOOL',
  'LIMESTONE VALLEY SCHOOL',
  'LIMEVILLE AMISH SCHOOL',
  'LINABEAN ACADEMY INC.',
  'LINCOLN ACADEMY',
  'LINCOLN AMISH SCHOOL',
  'LINCOLN CHRISTIAN ACADEMY',
  'LINCOLN CHRISTIAN ELEM/JR-SR',
  'LINCOLN CHRISTIAN SCHOOL',
  'LINCOLN CITY SEVENTH-DAY ADVENTIST SCHOOL',
  'LINCOLN COUNTY MENNONITE SCHOOL',
  'LINCOLN LUTHERAN MIDDLE/HIGH SCHOOL',
  'LINCOLN MARTI COMMUNITY AGENCY 43',
  'LINCOLN PARK PRESCHOOL AND KINDERGARTEN',
  'LINCOLN SCHOOL',
  'LINCOLN-MARTI COMMUNITY AGENCY',
  'LINCOLN-MARTI SCHOOL # 28',
  'LINCOLN-MARTI SCHOOL LLC',
  'LINDA VISTA ADVENTIST ELEMENTARY SCHOOL',
  'LINDAMOOD BELL ACADEMY',
  'LINDEN GROVE SCHOOL',
  'LINDEN HALL',
  'LINDEN SDA SCHOOL',
  'LINDEN WALDORF SCHOOL',
  'LINDSAY LANE CHRISTIAN ACADEMY',
  'LINFIELD CHRISTIAN SCHOOL',
  'LINGUA NATAL',
  'LINN LAWN',
  'LINN LUTHERAN SCHOOL',
  'LINN MENNONITE CHRISTIAN SCHOOL',
  'LINTON HALL SCHOOL',
  'LINVILLE HILL CHRISTIAN SCHOOL',
  'LINWOOD SCHOOL',
  'LINWORTH CHILDRENS CENTER',
  'LION & LAMB CHRISTIAN SCHOOL',
  'LIONHEART ACADEMY OF THE TRIAD',
  'LIONS MATHEMATICS & SCIENCE CHRISTIAN ACADEMY',
  "LISA'S TIPPY TOES DAY CARE & LEARNING CENTER",
  'LISBON FALLS CHRISTIAN ACADEMY',
  'LITCHFIELD MONTESSORI SCHOOL',
  'LITITZ AREA MENNONITE SCHOOL',
  'LITITZ CHRISTIAN SCHOOL',
  'LITTLE ACORN',
  'LITTLE ACORN MONTESSORI ACADEMY',
  'LITTLE ACORN SCHOOL',
  'LITTLE ACORNS GROW',
  'LITTLE ANGEL ACADEMY 2',
  'LITTLE ANGEL DAYCARE CENTER #3',
  'LITTLE ANGELS ACADEMY BURBANK',
  'LITTLE ANGELS CHRISTIAN SCHOOL',
  'LITTLE ASH AMISH SCHOOL',
  'LITTLE BEAVER PAROCHIAL SCHOOL',
  'LITTLE BLESSINGS PRESCHOOL',
  'LITTLE BLOSSOM MONTESSORI SCHOOL',
  'LITTLE BRITAIN MENNONITE SCHOOL',
  "LITTLE CHILDREN'S LEARNING ACADEMY",
  'LITTLE CITIZENS / WESTSIDE ACADEMY #3',
  'LITTLE CITY FOUNDATION CHILDBRIDGE CTR FOR EDUC',
  'LITTLE CORRAL KINDERGARTE',
  'LITTLE COUNTRY SCHOOL HOUSE',
  'LITTLE CRANE MONTESSORI SCHOOL',
  'LITTLE CREEK SCHOOL',
  'LITTLE DOVES PRESCHOOL & KINDERGARTEN',
  'LITTLE EARTH SCHOOL',
  'LITTLE EINSTEINS LEARNING CENTER',
  'LITTLE EXPLORERS LEARNING CENTER',
  'LITTLE EXPLORERS MONTESSORI',
  'LITTLE EXPLORERS MONTESSORI SCHOOL',
  'LITTLE FLOCK CHRISTIAN ACADEMY',
  'LITTLE FLOCK MINISTRIES',
  'LITTLE FLOWER CATHOLIC HIGH SCHOOL FOR GIRLS',
  'LITTLE FLOWER CATHOLIC SCHOOL',
  'LITTLE FLOWER ELEMENTARY SCHOOL',
  'LITTLE FLOWER MONTESSORI SCHOOL',
  'LITTLE FLOWER PREPARATORY SCHOOL, INC.',
  'LITTLE FLOWER PRIMARY SCHOOL',
  'LITTLE FLOWER SCHOOL',
  'LITTLE FLOWERS MONTESSORI',
  'LITTLE FLOWERS OF HOPE CENTER FOR CHILDREN',
  'LITTLE FOLKS',
  'LITTLE FRIENDS',
  'LITTLE FRIENDS CHILD CARE & KINDERGARTEN',
  'LITTLE FRIENDS MONTESSORI',
  'LITTLE FRIENDS MONTESSORI SCHOOL',
  'LITTLE FRIENDS OF JESUS NURSERY SCHOOL',
  'LITTLE FRIENDS PRESCHOOL',
  'LITTLE FROGS & POLLIWOGS',
  'LITTLE GENIUS ACADEMY',
  'LITTLE GENUIS ACADEMY',
  'LITTLE HANDS NURSERY SCHOOL',
  'LITTLE HARVARD',
  'LITTLE LAMB CHRISTIAN SCHOOL',
  'LITTLE LAMBS PRESCHOOL',
  'LITTLE LANGUAGE ACADEMY',
  'LITTLE LEARNERS',
  'LITTLE LEARNERS CHILD DEVELOPMENT CENTER',
  'LITTLE LEARNERS MONTESSORI',
  'LITTLE LEARNERS PRESCHOOL',
  'LITTLE LORDS AND LADIES CHRISTIAN PRIVATE SCHOOL',
  'LITTLE MINERS MONTESSORI',
  'LITTLE MIRACLES',
  'LITTLE NITTANY SCHOOL',
  'LITTLE OAK MONTESSORI SCHOOL',
  'LITTLE ONES ACADEMY',
  'LITTLE PEOPLE AT WORK',
  'LITTLE PEOPLE DAY CARE & KINDERGARTEN',
  'LITTLE PEOPLE DAYCARE',
  'LITTLE PEOPLE DAYCARE SCHOOL',
  'LITTLE PEOPLE SCHOOL',
  "LITTLE PEOPLE'S LANDING",
  'LITTLE PEOPLES ACADEMY',
  'LITTLE PEOPLES CHRISTIAN DAYCARE/ACADEMY',
  'LITTLE PEOPLES COUNTRY',
  'LITTLE PIECE OF ME MONTESSORI',
  'LITTLE PROMISES DAYCARELEARNING CENTER',
  'LITTLE RED HEN PRESCHOOL & KINDERGARTEN',
  'LITTLE RED SCHOOLHOUSE',
  'LITTLE RED TRAIN PRIVATE SCHOOL',
  'LITTLE RIVER COMMUNITY SCHOOL',
  'LITTLE RIVER MONTESSORI SCHOOL',
  'LITTLE ROCK ADVENTIST ACADEMY',
  'LITTLE ROCK CHRISTIAN ACADEMY',
  'LITTLE ROCK MONTESSORI SCHOOL',
  'LITTLE RUN SCHOOL',
  'LITTLE SCHOLARS ACADEMY OF CALIFORNIA',
  'LITTLE SCHOLARS ELEMENTARY',
  'LITTLE SCHOLARS MONTESSORI',
  'LITTLE SEEDLINGS CHRISTIAN PRESCHOOL & KDGN',
  'LITTLE SONSHINE SCHOOL HOUSE',
  'LITTLE SPROUTS LEARNING CENTER LL',
  'LITTLE STAR CENTER',
  'LITTLE STEPS ACADEMY',
  'LITTLE SUNSHINE PRESCHOOL',
  'LITTLE THEATRE SCHOOL NURSERY AND KINDERGARTEN',
  'LITTLE TRAVELERS DAYCARE & LEARNING CENTER',
  'LITTLE TREASURES LEARNING CENTER',
  'LITTLE VILLAGE ACADEMY',
  'LITTLE VILLAGE COUNTRY DAY SCHOOL',
  'LITTLE VOYAGEURS MONTESSORI SCHOOL',
  'LITTLE YORKERS MONTESSORI SCHOOL',
  'LITTLETON MONTESSORI SCHOOL',
  'LIVE OAK ACADEMY',
  'LIVE OAK CLASSICAL SCHOOL',
  'LIVE OAK SCHOOL',
  'LIVE OAK WALDORF SCHOOL',
  'LIVELY STONES FOR JESUS SCHOOL OF THE FUTURE',
  'LIVING FAITH ACADEMY',
  'LIVING FAITH SCHOOL',
  'LIVING GRACE CHRISTIAN SCHOOL',
  'LIVING HOPE LUTHERAN SCHOOL',
  'LIVING MONTESSORI ACADEMY',
  'LIVING ROCK ACADEMY',
  'LIVING SPRINGS ACADEMY',
  'LIVING SPRINGS CHRISTIAN SCHOOL',
  'LIVING STONES CHRISTIAN SCHOOL',
  'LIVING WATER CHRISTIAN SCHOOL',
  'LIVING WATERS CHRISTIAN SCHOOL',
  'LIVING WATERS MENNONITE SCHOOL',
  'LIVING WAY CHRISTIAN ACADEMY',
  'LIVING WISDOM SCHOOL',
  'LIVING WISDOM SCHOOL OF PALO ALTO',
  'LIVING WISDOM SCHOOL OF SEATTLE',
  'LIVING WORD ACADEMY',
  'LIVING WORD ACADEMY-SOUTHWEST INDIAN SCHOOL',
  'LIVING WORD CHRISTIAN ACADEMY',
  'LIVING WORD CHRISTIAN CENTER KINGDOM ACADEMY',
  'LIVING WORD CHRISTIAN SCHOOL',
  'LIVING WORD LUTHERAN HIGH SCHOOL',
  'LIVING WORD MINISTRY',
  'LIVINGSTON CHRISTIAN SCHOOL',
  'LIVINGSTON MONTESSORI SCHOOL',
  'LIVINGSTONE ACADEMY - RIVER VIEW',
  'LIVINGSTONE ADVENTIST ACADEMY',
  'LIVINGWAY FIELDS OF ACT CHRISTIAN ACADEMY',
  'LIVONIA MONTESSORI SCHOOL',
  'LLANO CHRISTIAN ACADEMY',
  'LLOYD PREPARATORY SCHOOL',
  'LOBLOLLY SCHOOL',
  'LOCCSE ACADEMY',
  'LOCK HAVEN CATHOLIC ELEMENTARY SCHOOL',
  'LOCK HAVEN CATHOLIC SCHOOLS CHAPTER 1',
  'LOCKHART MONTESSORI SCHOOL',
  'LOCUST GROVE',
  'LOCUST GROVE SCHOOL',
  'LOCUST RIDGE SCHOOL',
  'LOCUST RUN',
  'LODESTONE ACADEMY',
  'LODESTONE ACADEMY BALDWIN PARK',
  'LODI ACADEMY',
  'LODI CHRISTIAN SCHOOL',
  'LODI SDA ELEMENTARY SCHOOL',
  'LOG CABIN SCHOOL',
  'LOG CHURCH CHRISTIAN SCHOOL',
  'LOGAN CHRISTIAN SCHOOL',
  'LOGAN HOPE SCHOOL',
  'LOGAN RIVER ACADEMY',
  'LOGANVILLE CHRISTIAN ACADEMY',
  'LOGOS ACADEMY',
  'LOGOS CHRISTIAN ACADEMY',
  'LOGOS PREPARATORY SCHOOL',
  'LOGOS SCHOOL',
  'LOLIE ECCLES EARLY EDUCATION CENTER',
  'LOMA LINDA ACADEMY',
  'LONDON BRIDGE BAPTIST PRESCHOOL AND KINDERGARDEN',
  'LONDON CHRISTIAN ACADEMY',
  'LONDON GROVE FRIENDS KINDERGARTEN',
  'LONDONDERRY CHRISTIAN MONTESSORI',
  'LONDONDERRY SCHOOL',
  'LONE MAPLE AMISH SCHOOL',
  'LONE MOUNTAIN ACADEMY',
  'LONE OAK AMISH SCHOOL',
  'LONE OAK MENNONITE SCHOOL',
  'LONE PINE',
  'LONE PINE CHRISTIAN ACADEMY',
  'LONE PINE SCHOOL',
  'LONG BEACH CATHOLIC REGIONAL SCHOOL',
  'LONG HILL MONTESSORI SCHOOL',
  'LONG ISLAND BAPTIST ACADEMY',
  'LONG ISLAND HEBREW ACADEMY',
  'LONG ISLAND LUTHERAN DAY SCHOOL AT ST PAULS',
  'LONG ISLAND LUTHERAN MIDDLE & HIGH SCHOOL',
  'LONG ISLAND MONTESSORI SCHOOL',
  'LONG ISLAND SCHOOL FOR THE GIFTED',
  'LONG LAKE CONSERVATION CENTER',
  'LONG SHOALS WESLEYAN ACADEMY',
  'LONG TRAIL SCHOOL',
  'LONGMEADOW MONTESSORI',
  'LONGMONT CHRISTIAN SCHOOL',
  'LONGMORE ACADEMY',
  'LONGVIEW CHRISTIAN ACADEMY',
  'LONGVIEW MENNONITE SCHOOL',
  'LONGVIEW SCHOOL',
  'LONGWOOD CHRISTIAN ACADEMY',
  'LOOKING GLASS - RIVER ROAD',
  'LOOKING GLASS CENTER POINT SCHOOL',
  "LOOKING GLASS CHILDREN'S CENTER",
  'LOOKING GLASS NEW ROADS SCHOOL',
  'LOOKING GLASS PATHWAYS GIRLS PROGRAM',
  'LOOKING GLASS RIVERFRONT SCHOOL & CAREER CENTER',
  'LOOKING GLASS STEPPING STONE PROGRAM SCHOOL',
  'LOOMIS GATEWAY ACADEMY',
  'LORD & SAVIOR LUTHERAN SCHOOL',
  'LORD OF LIFE LUTHERAN SCHOOL',
  'LORD STIRLING SCHOOL',
  'LORDS TABERNACLE CHRISTIAN ACADEMY',
  'LORETTO ACADEMY',
  'LORETTO CATHOLIC SCHOOL',
  'LORI PETRIE PRESCHOOL',
  'LORRAINE D FOSTER DAY SCHOOL',
  'LOS ALTOS CHRISTIAN SCHOOL',
  'LOS ANGELES CHEDER / BAIS TZIVIA',
  'LOS ANGELES CHRISTIAN ACADEMY',
  'LOS ANGELES CHRISTIAN SCHOOL',
  'LOS ANGELES COMPUTER SCIENCE ACADEMY',
  'LOS BANOS ADVENTIST SCHOOL',
  'LOS ENCINOS SCHOOL',
  'LOS GATOS CHRISTIAN SCHOOL',
  'LOS PEQUENOS ANGELITOS',
  'LOS PINOS NUEVOS CHRISTIAN SCHOOL CORP.',
  'LOTUS ACADEMY',
  'LOTUS CHILD MONTESSORI',
  'LOUDOUN SCHOOL FOR THE GIFTED',
  'LOUISE S MCGEHEE SCHOOL',
  'LOUISIANA CHRISTIAN SCHOOL',
  'LOUISIANA NEW SCHOOL ACADEMY',
  'LOUISVILLE ADVENTIST ACADEMY',
  'LOUISVILLE CLASSICAL ACADEMY',
  'LOUISVILLE HIGH SCHOOL',
  'LOUISVILLE JEWISH DAY SCHOOL',
  'LOUISVILLE MONTESSORI SCHOOL',
  'LOURDES ACADEMY',
  'LOURDES ACADEMY ELEMENTARY SCHOOL',
  'LOURDES ACADEMY HIGH SCHOOL',
  'LOURDES ACADEMY MIDDLE SCHOOL',
  'LOURDES ACADEMY OF OSHKOSH WISCONSIN INC',
  'LOURDES CATHOLIC SCHOOL',
  'LOURDES HIGH SCHOOL',
  'LOVE & CARE CHRISTIAN PRESCHOOL',
  'LOVE 2 LEARN PRESCHOOL & KINDERGARTEN',
  'LOVE COVENANT CHRISTIAN SCHOOL',
  'LOVE OF LEARNING MONTESSORI',
  'LOVE OF LEARNING MONTESSORI SCHOOL',
  'LOVE THYSELF CHILD DEVELOPMENT',
  'LOVELAND ACADEMY',
  'LOVELY STAR ACADEMY',
  'LOVELY STARS ACADEMY 2',
  'LOVEVILLE MENNONITE SCHOOL',
  'LOVING CARE YOUNG ACHIEVERS CLUB',
  'LOVING SAVIOR OF THE HILLS LUTHERAN SCHOOL',
  'LOVING SHEPHERD LUTHERAN SCHOOL',
  'LOVING START LEARNING CENTER',
  'LOWELL DAY NURSERY',
  'LOWELL SCHOOL',
  'LOWER BUCKS FAMILY YMCA AT NEW TOWN',
  'LOWER TRAIL SCHOOL',
  'LOWNDES ACADEMY',
  'LOYOLA ACADEMY OF ST LOUIS',
  'LOYOLA BLAKEFIELD',
  'LOYOLA CATHOLIC SCHOOL',
  'LOYOLA COLLEGE PREPARATORY HIGH SCHOOL',
  'LOYOLA HIGH SCHOOL',
  'LOYOLA SACRED HEART HIGH SCHOOL',
  'LSB College',
  'LT JOSEPH P KENNEDY JR SCHOOL',
  'LUBAVITCH CHEDER DAY SCHOOL',
  'LUBAVITCH EDUCATIONAL CENTER',
  'LUBAVITCH GIRLS HIGH SCHOOL',
  'LUBAVITCH MESIVTA OF CHICAGO',
  'LUBAVITCHER YESHIVA',
  'LUBAVITCHER YESHIVA ACADEMY',
  'LUBBOCK CHRISTIAN SCHOOL',
  'LUBBOCK JUNIOR ACADEMY',
  'LUC BOYS SCHOOL',
  'LUCAS CHRISTIAN ACADEMY',
  'LUCY DANIELS CENTER FOR EARLY CHILDHOOD',
  'LUCY SCHOOL',
  'LUDIC',
  'LUDINGTON AREA CATHOLIC SCHOOL',
  'LUKE M POWERS CATHOLIC HIGH SCHOOL',
  'LUMEN CHRISTI CATHOLIC SCHOOL',
  'LUMEN CHRISTI HIGH SCHOOL',
  'LURGAN VIEW',
  'LURIA ACADEMY OF BROOKLYN',
  'LUSK SCHOOL',
  'LUSTRE CHRISTIAN HIGH SCHOOL',
  'LUTHER HIGH SCHOOL',
  'LUTHER MEMORIAL ACADEMY',
  'LUTHER MEMORIAL SCHOOL',
  'LUTHER PREP',
  'LUTHERAN ASSN FOR SPEC ED',
  'LUTHERAN CENTRAL SCHOOL',
  'LUTHERAN CHURCH & SCHOOL OF MESSIAH',
  'LUTHERAN CHURCH OF THE CROSS DAY SCHOOL',
  'LUTHERAN CHURCH SCHOOLS',
  'LUTHERAN ELEMENTARY SCHOOL OF BAY RIDGE',
  'LUTHERAN HIGH NORTH',
  'LUTHERAN HIGH NORTHEAST',
  'LUTHERAN HIGH SCHOOL',
  'LUTHERAN HIGH SCHOOL EAST',
  'LUTHERAN HIGH SCHOOL NORTH',
  'LUTHERAN HIGH SCHOOL OF GREATER NEW ORLEANS',
  'LUTHERAN HIGH SCHOOL OF INDIANAPOLIS',
  'LUTHERAN HIGH SCHOOL OF KANSAS CITY',
  'LUTHERAN HIGH SCHOOL OF SAN ANTONIO',
  'LUTHERAN HIGH SCHOOL OF SIOUX FALLS',
  'LUTHERAN HIGH SCHOOL OF ST CHARLES COUNTY',
  'LUTHERAN HIGH SCHOOL SOUTH',
  'LUTHERAN HIGH SCHOOL WESTLAND',
  'LUTHERAN HIGH WEST',
  'LUTHERAN INTERPARISH SCHOOL',
  'LUTHERAN SCHOOL ASSOCIATION',
  'LUTHERAN SCHOOL OF FLUSHING & BAYSIDE',
  'LUTHERAN SCHOOL OF ST LUKE',
  'LUTHERAN SOUTH ACADEMY',
  'LUTHERAN SOUTH UNITY SCHOOL',
  'LUTHERAN SPECIAL SCHOOL & EDUCATION SERVICES',
  'LUTZ LEARNING CENTER',
  'LWCS',
  'LYCE FRANAIS DE NEW YORK',
  'LYCEAM KENNEDY INTERNATIONAL SCHOOL',
  'LYCEE FRANCAIS DE CHICAGO',
  'LYCEE FRANCO-AMERICAIN',
  'LYCEUM KENNEDY',
  'LYCEUM- THE',
  'LYNDEN CHRISTIAN SCHOOL',
  'LYNDEN CHRISTIAN SCHOOL EVERGREEN CAMPUS',
  'LYNDEN CHRISTIAN SCHOOLS: ADMINISTRATION',
  'LYNDON ACADEMY',
  'LYNDON INSTITUTE',
  'LYNN OAKS SCHOOL',
  'LYNWOOD SCHOOL',
  'La Academia',
  'La Academia De Esperanza',
  'La Academia De Estrellas',
  'La Academia Dolores Huerta',
  'La Academia Partnership Charter School',
  'La Amistad',
  'La Ballona Elementary',
  'La Barge Elementary',
  'La Canada Elementary',
  'La Canada High',
  'La Carmen',
  'La Casa De Esperanza Charter School',
  'La Casita Elementary',
  'La Causa Charter School',
  'La Center Elementary',
  'La Center High School',
  'La Center Home School Academy',
  'La Center Middle School',
  'La Cima Charter School',
  'La Cima Middle School',
  'La Colima Elementary',
  'La Colina Junior High',
  'La Conner Elementary',
  'La Conner High School',
  'La Conner Middle',
  'La Contenta Middle',
  'La Costa Canyon High',
  'La Costa Heights Elementary',
  'La Costa Meadows Elementary',
  'La Crescent Montessori & Stem Acad',
  'La Crescent Sec Montessori/stem',
  'La Crescent Senior High',
  'La Crescent-hokah Elementary',
  'La Crescent-hokah Middle School',
  'La Crescenta Elementary',
  'La Crosse Design Institute',
  'La Crosse Elementary',
  'La Crosse High',
  'La Crosse Middle School',
  'La Crosse Offsite Preschool',
  'La Cuesta Continuation High',
  'La Cueva High',
  'La Cumbre Junior High',
  'La Encantada El',
  'La Entrada Continuation High',
  'La Entrada High',
  'La Entrada Middle',
  'La Escuelita Elementary',
  'La Esperanza (luis Pales Matos)',
  'La Familia Continuation High',
  'La Farge Elementary',
  'La Farge High',
  'La Farge Middle',
  'La Fargeville Central School',
  'La Fayette Junior-senior High School',
  'La Fayette School',
  'La Fe Preparatory School',
  'La Feria Academy',
  'La Feria H S',
  'La Fermina',
  'La Fetra Elementary',
  'La France Elementary',
  'La Francis Hardiman Elementary School',
  'La Gloria El',
  'La Gloria Elementary',
  'La Granada Elementary',
  'La Grande High School',
  'La Grande Middle School',
  'La Grange Education Center',
  'La Grange Elementary',
  'La Grange H S',
  'La Grange Middle',
  'La Grange Moscow Elementary',
  'La Habra High',
  'La Harpe Elementary School',
  'La Harpe Junior High School',
  'La Honda Elementary',
  'La Honda Steam Academy',
  'La Jara Elementary School',
  'La Jolla Elementary',
  'La Jolla High',
  'La Joya Community High School',
  'La Joya Elementary',
  'La Joya H S',
  'La Joya Isd Head Start Program',
  'La Joya Middle',
  'La Joya Palmview H S',
  'La Junta Intermediate School',
  'La Junta Jr/sr High School',
  'La Junta Primary School',
  'La Loma Junior High',
  'La Luz Elementary',
  'La Madera Elementary',
  'La Mariposa',
  'La Marque El',
  'La Marque H S',
  'La Marque Middle',
  'La Marque Pri',
  'La Merced Elementary',
  'La Merced Intermediate',
  'La Mesa Arts Academy',
  'La Mesa Dale Elementary',
  'La Mesa El',
  'La Mesa Elementary',
  'La Mesa Junior High',
  'La Mesa On Track Prek Center',
  'La Mesa-spring Valley Home Independent Study',
  'La Mirada Elementary',
  'La Mirada High',
  'La Moille High School',
  'La Monte Elem.',
  'La Monte High',
  'La Paloma Academy',
  'La Paloma Academy (lakeside)',
  'La Paloma Academy-south',
  'La Paloma El',
  'La Paloma Elementary',
  'La Paloma High (continuation)',
  'La Patera Elementary',
  'La Paz Intermediate',
  'La Paz Middle',
  'La Plata Elem.',
  'La Plata High',
  'La Plata High School',
  'La Plata Middle',
  'La Pluma Elementary',
  'La Pointe Elementary',
  'La Porte City Elementary School',
  'La Porte El',
  'La Porte H S',
  'La Porte J H',
  'La Presa Elementary',
  'La Primaria Elementary',
  'La Promesa Early Learning',
  'La Promesa Elementary',
  'La Pryor El',
  'La Pryor H S',
  'La Puente High',
  'La Puente Valley Rop',
  'La Quinta High',
  'La Quinta Middle',
  'La Resolana Leadership',
  'La Rosa Elementary',
  'La Sal School',
  'La Salle Avenue Elementary',
  'La Salle Charter School',
  'La Salle Universities - International Programmes',
  'La Salle-peru Twp High School',
  'La School For Ag Science',
  'La Senita Elementary',
  'La Serna High',
  'La Sierra High',
  'La Sierra High (alternative)',
  'La Sierra University',
  'La Soledad',
  'La Tercera Elementary',
  'La Tierra Community School',
  'La Tierra Early Childhood Center',
  'La Tierra Montessori School Of The Arts And Sciences',
  'La Tijera K-8 Charter School Academy Of Excellence',
  'La Trobe University',
  'La Union El',
  'La Union Elementary',
  'La Vega El',
  'La Vega H S',
  'La Vega Int H P Miles Campus',
  'La Vega J H George Dixon Campus',
  'La Vega Pri',
  'La Venture Middle School',
  'La Verkin School',
  'La Verne Heights Elementary',
  'La Verne Science And Technology Charter',
  'La Vernia H S',
  'La Vernia Int',
  'La Vernia J H',
  'La Vernia Pri',
  'La Veta Elementary',
  'La Veta Elementary School',
  'La Veta Junior-senior High School',
  'La Vida Charter',
  'La Villa Early College H S',
  'La Villa J J A E P H S',
  'La Villa Middle',
  'La Villita El',
  'La Villita Middle',
  'La Vina Elementary',
  'La Vina Middle',
  'La Vista Center',
  'La Vista High (continuation)',
  'La Vista Middle School',
  'La Vista West Elementary Sch',
  "La's Promise Charter Middle #1",
  'LaGrange College',
  'LaSalle University',
  'Labadie Elem.',
  'Labadieville Middle School',
  'Labadieville Primary School',
  'Labay Middle',
  'Labelle Adult School',
  'Labelle Aire Elementary School',
  'Labelle Elementary School',
  'Labelle High School',
  'Labelle Middle School',
  'Labelle Youth Developmental Academy',
  'Labette Community College',
  'Labette County High School',
  'Laboratory Cs',
  'Laboratory School Of Finance And Technology (the): X223',
  'Labrae High School',
  'Labrae Intermediate School',
  'Labrae Middle School',
  'Laburnum Elementary',
  'Lac Courte Oreilles Ojibwa Community College',
  'Lac Courte Oreilles Ojibwa School',
  'Lac Du Flambeau Elementary',
  'Lac Qui Parle Valley Middle School',
  'Lac Qui Parle Valley Secondary',
  'Lacache Middle School',
  'Lacamas Heights Elementary',
  'Lacassine High School',
  'Lace Elem School',
  'Lacey Elementary',
  'Lacey Spring Elementary',
  'Lacey Township High School',
  'Lacey Township Middle School',
  'Laceys Spring Elementary School',
  'Lack-tuscarora El Sch',
  'Lackamas Elementary',
  'Lackawanna College',
  'Lackawanna High School',
  'Lackawanna Middle School',
  'Lackawanna Trail El Ctr',
  'Lackawanna Trail Jshs',
  'Lackland El',
  'Laclede Elem.',
  'Lacomb School',
  'Laconia High',
  'Laconia High School',
  'Laconia Middle School',
  'Lacoochee Elementary School',
  'Lacoste El',
  'Lacoste Elementary School',
  'Lacreole Middle School',
  'Lacroft Elementary School',
  'Lacrosse Elementary',
  'Lacrosse Elementary School',
  'Lacrosse High School',
  'Lacrosse School',
  'Lacrossroads Charter',
  'Lacy El',
  'Lacy Elementary',
  'Lacygne Elem',
  'Lad Lake Synergy School',
  'Ladd Acres Elementary School',
  'Ladd Comm Cons Sch',
  'Ladd Elementary',
  'Ladd Lane Elementary',
  'Ladera Del Norte Elementary',
  'Ladera Elementary',
  'Ladera Palma Elementary',
  'Ladera Ranch Elementary',
  'Ladera Ranch Middle',
  'Ladera Vista Junior High School Of The Arts',
  'Ladislao Martinez',
  'Ladoga Elementary School',
  'Ladoke Akintola University of Technology',
  'Ladonia Elementary School',
  'Ladow Technical Center',
  'Ladse Learning Center',
  'Ladson Elementary',
  'Ladue Early Childhood Center',
  'Ladue Fifth Grade Center',
  'Ladue Horton Watkins High',
  'Ladue Middle',
  'Lady Bird Johnson Middle',
  'Lady Liberty Academy Charter School',
  "Lady's Island Elementary",
  "Lady's Island Middle",
  'Ladysmith Elementary',
  'Ladysmith High',
  'Ladysmith Middle',
  'Lafargue Elementary School',
  'Lafayette - Pershing',
  'Lafayette Academy',
  'Lafayette Avenue School',
  'Lafayette Co. High',
  'Lafayette Co. Middle',
  'Lafayette College',
  'Lafayette County Elementary',
  'Lafayette County High School',
  'Lafayette Early Childhood Center',
  'Lafayette Eastside Elementary School',
  'Lafayette El Sch',
  'Lafayette Elementary',
  'Lafayette Elementary School',
  'Lafayette Es',
  'Lafayette Estates Elementary School',
  'Lafayette High',
  'Lafayette High School',
  'Lafayette International School',
  'Lafayette Lanier Elementary School',
  'Lafayette Lerning Center',
  'Lafayette Meadow School',
  'Lafayette Middle School',
  'Lafayette Mills School',
  'Lafayette Ms',
  'Lafayette Park Elementary School',
  'Lafayette Park Primary Center',
  'Lafayette Preparatory Academy',
  'Lafayette Public Charter School',
  'Lafayette Regional School',
  'Lafayette Renaissance Charter Academy',
  'Lafayette School',
  'Lafayette Secondary',
  'Lafayette Sr. High',
  'Lafayette Street School',
  'Lafayette Sunnyside Intermediate',
  'Lafayette Tecumseh Jr High School',
  'Lafayette Township School',
  'Lafayette Upper Elementary',
  'Lafayette Upper Elementary School',
  'Lafayette Virtual Franchise',
  'Lafayette Virtual Instruction Course Offerings',
  'Lafayette Virtual Instruction K-5 Fulltime',
  'Lafayette Virtual Instruction Program',
  'Lafe Nelson School',
  'Lafollette Elementary',
  'Lafollette Elementary School',
  'Lafollette High',
  'Lafollette Middle School',
  'Lafourche Parish Juvenile Justice Facility',
  'Lago Vista El',
  'Lago Vista H S',
  'Lago Vista Int',
  'Lago Vista Middle',
  'Lagonda Elementary School',
  'Lagos City Polytechnic',
  'Lagos El',
  'Lagos State Polytechnic',
  'Lagos State University',
  'Lagrange Elementary School',
  'Lagrange High School',
  'Lagrange Middle School',
  'Laguna Beach High',
  'Laguna Creek High',
  'Laguna Elementary',
  'Laguna Elementary School',
  'Laguna High',
  'Laguna Hills High',
  'Laguna Middle',
  'Laguna Middle School',
  'Laguna Niguel Elementary',
  'Laguna Nueva',
  'Laguna Road Elementary',
  'Laguna Vista Elementary',
  'Laguna-acoma High',
  'Laguna-acoma Middle',
  'Lagunita Elementary',
  'Lagunitas Elementary',
  'Lahaina Intermediate School',
  'Lahainaluna High School',
  'Lahontan Elementary School',
  'Lahore College for Women University',
  'Lahore University of Management Sciences',
  'Lahti Polytechnic',
  'Laie Elementary School',
  'Laikipia University',
  'Laing Middle',
  'Laingsburg Elementary School',
  'Laingsburg High School',
  'Laingsburg Middle School',
  'Laird Elementary School',
  'Lairon College Preparatory Academy',
  'Lake Academy',
  'Lake Academy Eustis',
  'Lake Academy Leesburg',
  'Lake Agassiz Elementary School',
  'Lake Agassiz Special Ed. Coop.',
  'Lake Air Montessori School',
  'Lake Alfred Elementary School',
  'Lake Alfred Polytech Academy',
  'Lake Ann Elementary School',
  'Lake Anne Elementary',
  'Lake Arbor Elementary',
  'Lake Area Career & Tech Center',
  'Lake Area New Tech Early College High School',
  'Lake Area Technical Institute',
  'Lake Arrowhead Elementary',
  'Lake Arthur Elementary',
  'Lake Arthur Elementary School',
  'Lake Arthur High',
  'Lake Arthur High School',
  'Lake Arthur Middle',
  'Lake Asbury Elementary School',
  'Lake Asbury Junior High School',
  'Lake Avenue Elementary School',
  'Lake Belton Middle',
  'Lake Benton Elementary',
  'Lake Benton Secondary',
  'Lake Bluff Elem Sch',
  'Lake Bluff Elementary',
  'Lake Bluff Middle School',
  'Lake Braddock Secondary',
  'Lake Brantley High School',
  'Lake Butler Elementary School',
  'Lake Butler Middle School',
  'Lake Cable Elementary School',
  'Lake Canyon Elementary',
  'Lake Career Technical Ctr.',
  'Lake Carolina Elementary Lower Campus',
  'Lake Carolina Elementary Upper Campus',
  'Lake Center Elementary School',
  'Lake Center Middle',
  'Lake Central High School',
  'Lake Charles Charter Academy',
  'Lake Charles College Prep',
  'Lake Chelan Preschool',
  'Lake City Alternative School',
  'Lake City Community School',
  'Lake City Credit Recovery',
  'Lake City Early Childhood Center',
  'Lake City Elementary',
  'Lake City Elementary School',
  'Lake City High',
  'Lake City High School',
  'Lake City Middle School',
  'Lake Co Collaborative Alop',
  'Lake Co High School',
  'Lake Cormorant Elementary',
  'Lake Cormorant High',
  'Lake Cormorant Middle',
  'Lake Country Academy - Charter',
  'Lake Country Elementary School',
  'Lake Country School',
  'Lake County Acer Program',
  'Lake County Early College',
  'Lake County High School',
  'Lake County Intermediate School',
  'Lake County International Charter',
  'Lake County Rop',
  'Lake Creek H S',
  'Lake Creek Learning Center',
  'Lake Crest Elem School',
  'Lake Cumberland Area Technology Center',
  'Lake Cumberland Youth Development Center',
  'Lake Dallas El',
  'Lake Dallas H S',
  'Lake Dallas Middle',
  'Lake Delton Elementary',
  'Lake Denoon Middle',
  'Lake Dolloff Elementary School',
  'Lake Don Pedro Elementary',
  'Lake Drive Program For Hearing Impared',
  'Lake Elem.',
  'Lake Elementary',
  'Lake Elementary School',
  'Lake Elkhorn Middle',
  'Lake Elmo Elementary',
  'Lake Elmore School',
  'Lake Eola Charter',
  'Lake Erie College Preparatory School',
  'Lake Erie International High School',
  'Lake Fenton High School',
  'Lake Fenton Middle School',
  'Lake Forest Academy',
  'Lake Forest Central Elementary School',
  'Lake Forest College',
  'Lake Forest East Elementary School',
  'Lake Forest Elementary',
  'Lake Forest Elementary Charter School',
  'Lake Forest Elementary School',
  'Lake Forest High School',
  'Lake Forest Hills Elementary School',
  'Lake Forest Middle School',
  'Lake Forest North Elementary School',
  'Lake Forest Park Elementary',
  'Lake Forest South Elementary School',
  'Lake Garda Elementary School',
  'Lake Gem Elementary',
  'Lake Geneva Middle',
  'Lake George Charter School',
  'Lake George Elementary',
  'Lake George Elementary School',
  'Lake George Junior-senior High School',
  'Lake Gibson Middle School',
  'Lake Gibson Senior High School',
  'Lake Grove Elementary School',
  'Lake Hamilton Elementary Sch',
  'Lake Hamilton High School',
  'Lake Hamilton Interm. School',
  'Lake Hamilton Jr. High School',
  'Lake Hamilton Middle School',
  'Lake Hamilton Primary School',
  'Lake Harbor Middle School',
  'Lake Harriet Lower Elementary',
  'Lake Harriet Upper School',
  'Lake Havasu High School',
  'Lake Hazel Elementary School',
  'Lake Hazel Middle School',
  'Lake Hiawatha Elementary School',
  'Lake High School',
  'Lake Highlands El',
  'Lake Highlands H S',
  'Lake Highlands J H',
  'Lake Hills Elementary',
  'Lake Hills Elementary School',
  'Lake Hills School',
  'Lake Hood Elementary',
  'Lake Howell High School',
  'Lake Huron Virtual Hybrid High School',
  'Lake In The Hills Elem School',
  'Lake International School',
  'Lake Jackson Int',
  'Lake Joy Elementary School',
  'Lake Joy Primary School',
  'Lake Land College',
  'Lake Lindenhubbell Schools',
  'Lake Los Angeles Elementary',
  'Lake Louise Elem School',
  'Lake Louise Elementary School',
  'Lake Lucina Elementary School',
  'Lake Lure Classical Academy',
  'Lake Magdalene Elementary School',
  'Lake Maloney School',
  'Lake Marie Elementary',
  'Lake Marion Creek Middle School',
  'Lake Marion Elementary',
  'Lake Marion High School And Technology Center',
  'Lake Mary Elementary School',
  'Lake Mary High School',
  'Lake Mathews Elementary',
  'Lake Michigan College',
  'Lake Middle School',
  'Lake Mills Elementary',
  'Lake Mills Elementary School',
  'Lake Mills High',
  'Lake Mills Middle',
  'Lake Mills Middle School',
  'Lake Mills Senior High School',
  'Lake Minatare School',
  'Lake Minneola High School',
  'Lake Murray Elementary',
  'Lake Myra Elementary',
  'Lake Myrtle Elementary School',
  'Lake Nona High',
  'Lake Nona Middle',
  'Lake Norman Charter',
  'Lake Norman Elementary',
  'Lake Norman High',
  'Lake Oconee Charter',
  'Lake Of The Woods Elementary',
  'Lake Of The Woods Secondary',
  'Lake Olympia Middle',
  'Lake Orienta Elementary School',
  'Lake Orion Community High School',
  'Lake Oswego Middle School',
  'Lake Oswego Senior High School',
  'Lake Otis Elementary',
  'Lake Panasoffkee Elementary School',
  'Lake Park Audubon Elementary',
  'Lake Park Audubon Secondary',
  'Lake Park Elem School',
  'Lake Park Elementary School',
  'Lake Park Es',
  'Lake Park High School',
  'Lake Park School',
  'Lake Parsippany Elementary School',
  'Lake Pend Oreille High School',
  'Lake Placid Elementary School',
  'Lake Placid High School',
  'Lake Placid Junior-senior High School',
  'Lake Placid Middle School',
  'Lake Pleasant Elementary',
  'Lake Pleasant School',
  'Lake Pointe El',
  'Lake Pontchartrain Elementary School',
  'Lake Powell High School',
  'Lake Powell School',
  'Lake Prairie Elementary School',
  'Lake Preschool',
  'Lake Preston Elementary - 02',
  'Lake Preston High School - 01',
  'Lake Preston Jr. High - 03',
  'Lake Quinault Elementary',
  'Lake Quinault High School',
  'Lake Region High School',
  'Lake Region Middle School',
  'Lake Region Spec Ed Unit',
  'Lake Region State College',
  'Lake Region Uhsd #24',
  'Lake Region Vocational Center',
  'Lake Ridge Elementary',
  'Lake Ridge Elementary School',
  'Lake Ridge Middle',
  'Lake Ridge New Tech Middle School',
  'Lake Ridge School',
  'Lake Rim Elementary',
  'Lake Ripley Elementary',
  'Lake Riviera Middle School',
  'Lake Road Elem.',
  'Lake Road Elementary',
  'Lake Roosevelt Alternative School',
  'Lake Roosevelt Elementary',
  'Lake Roosevelt Jr/sr High School',
  'Lake Seneca Elementary',
  'Lake Shipp Elementary School',
  'Lake Shore Elementary',
  'Lake Shore High School',
  'Lake Shore Middle',
  'Lake Shore Middle School',
  'Lake Shore Senior High School',
  'Lake Silver Elementary',
  'Lake Spokane Elementary',
  'Lake St. George Elementary School',
  'Lake Stevens Elementary School',
  'Lake Stevens Middle School',
  'Lake Stevens Sr High School',
  'Lake Stickney Elementary School',
  'Lake Street Elementary School',
  'Lake Street Primary',
  'Lake Street School',
  'Lake Superior Academy',
  'Lake Superior College',
  'Lake Superior Elementary',
  'Lake Superior High',
  'Lake Superior Intermediate',
  'Lake Superior Learning Community',
  'Lake Superior Primary',
  'Lake Superior State University',
  'Lake Sybelia Elementary',
  'Lake Tahoe Community College',
  'Lake Tahoe Environmental Science Magnet',
  'Lake Tapps Elementary',
  'Lake Taylor High',
  'Lake Taylor Middle',
  'Lake Technical College',
  'Lake Tract Elementary School',
  'Lake Trafford Elementary School',
  'Lake Travis El',
  'Lake Travis H S',
  'Lake Travis Middle',
  'Lake Valley Elementary School',
  'Lake Valley Navajo School',
  'Lake View',
  'Lake View Elementary',
  'Lake View Elementary Primary',
  'Lake View Elementary School',
  'Lake View H S',
  'Lake View High',
  'Lake View High School',
  'Lake View School',
  'Lake Village Elementary School',
  'Lake Virtual Franchise',
  'Lake Virtual Instruction Course Offerings',
  'Lake Virtual Instruction Program',
  'Lake Virtual Instruction Program (district Provided)',
  'Lake Wales Senior High School',
  'Lake Washington High',
  'Lake Washington Technical Academy',
  'Lake Weir High School',
  'Lake Weir Middle School',
  'Lake Weston Elementary',
  'Lake Whitney Elementary',
  'Lake Wilderness Elementary',
  'Lake Windward Elementary School',
  'Lake Worth Community Middle',
  'Lake Worth H S',
  'Lake Worth High Adult Education Center',
  'Lake Worth High School',
  'Lake Wylie Elementary',
  'Lake Youngs Elementary School',
  'Lake Zurich High School',
  'Lake Zurich Middle - N Campus',
  'Lake Zurich Middle - S Campus',
  'Lake-lehman Jshs',
  'Lake-noxen El Sch',
  'Lakeaires Elementary',
  'Lakebridge Behavioral Health System',
  'Lakeforest Elementary',
  'Lakehead University',
  'Lakehurst Elementary School',
  'Lakeland Academy Community School',
  'Lakeland Community College',
  'Lakeland Correctional Facility',
  'Lakeland El',
  'Lakeland El Sch - Mayfield Campus',
  'Lakeland El Sch - Scott Campus',
  'Lakeland Elem.',
  'Lakeland Elementary',
  'Lakeland Elementary School',
  'Lakeland Elementary/middle',
  'Lakeland High',
  'Lakeland High School',
  'Lakeland Highlands Middle School',
  'Lakeland Hills Elementary',
  'Lakeland Jshs',
  'Lakeland Junior High School',
  'Lakeland Middle Preparatory School',
  'Lakeland Middle School',
  'Lakeland Montessori Middle School',
  'Lakeland Montessori School House',
  'Lakeland Regional High School',
  'Lakeland School',
  'Lakeland Senior High School',
  'Lakeland Star Acad (lsa)',
  'Lakeland Star School--strong Talented Adventurous Remarkable',
  'Lakeland Village',
  'Lakeland Virtual Academy',
  'Lakeland-copper Beech Middle School',
  'Lakelands Park Middle',
  'Lakemont Elementary',
  'Lakeport Alternative (home School)',
  'Lakeport Community Day',
  'Lakeport Elementary',
  'Laker Elementary',
  'Laker High School',
  'Laker Junior High School',
  'Laker Online',
  'Lakeridge El',
  'Lakeridge Elementary School',
  'Lakeridge High School',
  'Lakeridge Jr High',
  'Lakeridge Middle School',
  'Lakes Academy',
  'Lakes And Bridges Charter School',
  'Lakes Community High School',
  'Lakes Country Academy',
  'Lakes Country Academy - Alex',
  'Lakes Country Academy - Mhd',
  'Lakes Country Youth Educ Svcs',
  'Lakes Elementary School',
  'Lakes High School',
  'Lakes International Language Acad',
  'Lakes Intl Language Acad-headwaters',
  'Lakes Magnet School',
  'Lakes Region Community College',
  'Lakeshore Alternative Elementary',
  'Lakeshore Alternative School',
  'Lakeshore El',
  'Lakeshore Elementary',
  'Lakeshore Elementary School',
  'Lakeshore High School',
  'Lakeshore Intergenerational School',
  'Lakeshore Middle',
  'Lakeshore Middle School',
  'Lakeshore Primary Elementary School',
  'Lakeshore Road Elementary School',
  'Lakeshore School',
  'Lakeshore Technical College',
  'Lakeshore Virtual School',
  'Lakeside',
  'Lakeside Academy',
  'Lakeside Charter School',
  'Lakeside Early Advantage Preschool',
  'Lakeside El',
  'Lakeside El.',
  'Lakeside Elem',
  'Lakeside Elementary',
  'Lakeside Elementary School',
  'Lakeside Farms Elementary',
  'Lakeside High',
  'Lakeside High School',
  'Lakeside Intermediate School',
  'Lakeside Jr High School',
  'Lakeside Junior High School',
  'Lakeside Junior-senior High School',
  'Lakeside Junior/senior High School',
  'Lakeside Middle',
  'Lakeside Middle School',
  'Lakeside Park Elementary',
  'Lakeside Primary School',
  'Lakeside School',
  'Laketown Elem School',
  'Laketown Elementary',
  'Lakeview Academy',
  'Lakeview Academy Campus 2',
  'Lakeview Annex',
  'Lakeview Centennial H S',
  'Lakeview Colony Elementary - 05',
  'Lakeview Dropout Prevention',
  'Lakeview Early Childhood Center',
  'Lakeview El',
  'Lakeview El Sch',
  'Lakeview Elem School',
  'Lakeview Elementary',
  'Lakeview Elementary - 09',
  'Lakeview Elementary School',
  'Lakeview Es',
  'Lakeview Fundamental Elementary',
  'Lakeview High School',
  'Lakeview Home School',
  'Lakeview Hope Academy',
  'Lakeview Hs',
  'Lakeview Jr High School',
  'Lakeview Jr./sr. High School',
  'Lakeview Junior High',
  'Lakeview Junior High School',
  'Lakeview Leadership Academy',
  'Lakeview Middle',
  'Lakeview Middle School',
  'Lakeview Montessori School',
  'Lakeview Ms',
  'Lakeview Primary',
  'Lakeview School',
  'Lakeview Secondary',
  'Lakeview Senior High School',
  'Lakeview Special Education',
  'Lakeview Technology Academy',
  'Lakeview Terrace Elementary',
  'Lakeview Usd #43',
  'Lakeview Woods School',
  'Lakeview-fort Oglethorpe High School',
  'Lakeville Area Learning Center',
  'Lakeville Early Childhood Program',
  'Lakeville Elementary',
  'Lakeville Elementary School',
  'Lakeville High School',
  'Lakeville Middle School',
  'Lakeville North High',
  'Lakeville South High',
  'Lakevue Elementary School',
  'Lakeway El',
  'Lakewood City Academy',
  'Lakewood Community',
  'Lakewood Creek Elementary',
  'Lakewood Early Childhood Center',
  'Lakewood El',
  'Lakewood Elem.',
  'Lakewood Elementary',
  'Lakewood Elementary School',
  'Lakewood Falls Elem School',
  'Lakewood High',
  'Lakewood High School',
  'Lakewood Middle',
  'Lakewood Middle School',
  'Lakewood Montessori Middle',
  'Lakewood Nwesd 189 Open Door Program',
  'Lakewood Park Elementary School',
  'Lakewood Primary School',
  'Lakewood Ranch High School',
  'Lakewood School',
  'Lakin Elem',
  'Lakin High',
  'Lakin Middle',
  'Lakota East High School',
  'Lakota Elementary School',
  'Lakota High School',
  'Lakota Middle School',
  'Lakota Plains Junior School',
  'Lakota Ridge Junior School',
  'Lakota West High School',
  'Lakshmibai National Institute of Physical Education',
  'Laliberte Elementary School',
  'Lalit Narayan Mithila University',
  'Lalor Elementary School',
  'Lalumier Elem School',
  'Lamar - Milledge Elementary School',
  'Lamar Academy',
  'Lamar Bruni Vergara Middle',
  'Lamar Careertechnical Center',
  'Lamar Co Voc Tech Center',
  'Lamar Community College',
  'Lamar Cons H S',
  'Lamar County Elementary School',
  'Lamar County Head Start',
  'Lamar County High School',
  'Lamar County Highintermediate',
  'Lamar County Middle School',
  'Lamar County Primary School',
  'Lamar County School Of Technology',
  'Lamar Delta Alter',
  'Lamar Early Ee-kg',
  'Lamar El',
  'Lamar Elem.',
  'Lamar Elementary',
  'Lamar Elementary School',
  'Lamar H S',
  'Lamar High',
  'Lamar High School',
  'Lamar Institute of Technology',
  'Lamar J H',
  'Lamar Louise Curry Middle School',
  'Lamar M S',
  'Lamar Middle',
  'Lamar Middle School',
  'Lamar Reese School Of The Arts',
  'Lamar State College-​Orange',
  'Lamar State College-​Port Arthur',
  'Lamar University',
  'Lamarque Elementary School',
  'Lamb Elementary',
  'Lamb Elementary School',
  'Lambert 7-8',
  'Lambert Elementary School',
  'Lambert High School',
  'Lambert School',
  'Lambert-lavoie',
  'Lamberton Ms',
  'Lamberton Robert E Sch',
  'Lamberts Mill Academy',
  'Lambertville Public School',
  'Lamboglia',
  'Lambs Elementary',
  'Lame Deer 7-8',
  'Lame Deer High School',
  'Lame Deer School',
  'Lamesa Daep',
  'Lamesa H S',
  'Lamesa Middle',
  'Lamesa Success Academy',
  'Lamkin El',
  'Lammersville Elementary',
  'Lamoille Union High School',
  'Lamoille Union Middle School',
  'Lamoine Consolidated School',
  'Lamoni Elementary School',
  'Lamoni High School',
  'Lamoni Middle School',
  'Lamont Elementary',
  'Lamont Middle School',
  'Lamonte-annex Elementary School',
  'Lamora Park School',
  'Lamotte 7-8',
  'Lamotte School',
  'Lamoure Colony School',
  'Lamoure Elementary School',
  'Lamoure High School',
  'Lampang College of Commerce and Technology',
  'Lampang Rajabhat University',
  'Lampang Vocational College',
  'Lampasas H S',
  'Lampasas Middle',
  'Lampeter El Sch',
  'Lampeter-strasburg Shs',
  'Lamphere Center',
  'Lamphere Early Childhood Center',
  'Lamphere High School',
  'Lamphun College of Agriculture and Technology',
  'Lamprey River Elementary School',
  'Lampson Elementary',
  'Lanai High & Elementary School',
  'Lanai Road Elementary',
  'Lanakila Elementary School',
  'Lancashire Elementary School',
  'Lancaster Alternative And Virtual Academies',
  'Lancaster Central School',
  'Lancaster County Ctc-brownstown Campus',
  'Lancaster County Ctc-mt Joy Campus',
  'Lancaster County Ctc-willow St Campus',
  'Lancaster El',
  'Lancaster Elementary',
  'Lancaster Elementary School',
  'Lancaster H S',
  'Lancaster High',
  'Lancaster High School',
  'Lancaster Middle',
  'Lancaster Middle School',
  'Lancaster Primary',
  'Lancaster Secondary',
  'Lance Creek Elementary',
  'Lance Middle',
  "Land O' Lakes High Adult Education",
  "Land O' Lakes High School",
  "Land O'pines Elementary School",
  'Landaff Blue School',
  'Landau Elementary',
  'Landeck Elementary School',
  'Lander Adult',
  'Lander Elementary',
  'Lander Middle School',
  'Lander University',
  'Lander Valley High School',
  'Landergin El',
  'Landers Elementary',
  'Landing School',
  'Landis El',
  'Landis Elementary',
  'Landis Elementary School',
  'Landis Middle School',
  'Landis Run Intermediate School',
  'Landisville Intrmd Ctr',
  'Landisville Ms',
  'Landisville Pri Ctr',
  'Landmark Academy',
  'Landmark Academy At Reunion',
  'Landmark Elem School',
  'Landmark Elementary',
  'Landmark Elementary School',
  'Landmark High',
  'Landmark High School',
  'Landmark Middle',
  'Landmark Middle School',
  'Landmark University',
  'Landon Middle School',
  'Landrum El',
  'Landrum High',
  'Landrum Middle',
  'Landry El',
  'Landstown Elementary',
  'Landstown High',
  'Landstown Middle',
  'Lane Community College',
  'Lane Elem School',
  'Lane Elementary',
  'Lane Elementary School',
  'Lane Intermediate',
  'Lane Middle School',
  'Lane Public School',
  'Lane School',
  'Lane Technical High School',
  'Lanes Mill Elementary School',
  'Lanesboro Elementary',
  'Lanesboro Secondary',
  'Lanesborough Elementary',
  'Lanesville Elementary School',
  'Lanesville Jr-sr Hs',
  'Lanett Junior High School',
  'Lanett Senior High School',
  'Laneview Elementary',
  'Laneville School',
  'Laney College',
  'Laney High School',
  'Lang Ranch',
  'Langara College',
  'Langdon Area Elementary School',
  'Langdon Area High School',
  'Langdon Avenue Elementary',
  'Langdon C Kerr Elementary',
  'Langdon Es',
  'Lange (dorothea) Elementary',
  'Langford A Elem School',
  'Langford Area Elementary - 02',
  'Langford Area High School - 01',
  'Langford Area Middle School - 03',
  'Langford El',
  'Langford Elementary',
  'Langford Middle School',
  'Langham Creek H S',
  'Langham El',
  'Langlade Elementary',
  'Langley El',
  'Langley Es',
  'Langley High',
  'Langley Park/mccormick Elementary',
  'Langley-bath-clearwater Middle',
  'Langsford House',
  'Langston Chapel Elementary School',
  'Langston Chapel Middle School',
  'Langston Charter Middle',
  'Langston High Continuation Program',
  'Langston Hughes Acad Arts-tech',
  'Langston Hughes Charter Academy',
  'Langston Hughes Elem',
  'Langston Hughes Elementary School',
  'Langston Hughes High School',
  'Langston Magnet School',
  'Langston Middle',
  'Langston Middle School',
  'Langston Road Elementary School',
  'Langston University',
  'Langtree Charter Academy',
  'Langtree Elementary School',
  'Language Academy',
  'Language Based Classroom At Hatton School',
  'Language Based Classroom Multihandicapped',
  'Language Development Center',
  'Lanier Career Academy',
  'Lanier County Elementary School',
  'Lanier County High School',
  'Lanier County Middle School',
  'Lanier County Primary School',
  'Lanier Elementary',
  'Lanier Elementary School',
  'Lanier Es',
  'Lanier H S',
  'Lanier High School',
  'Lanier Middle',
  'Lanier Middle School',
  'Lanier Primary School',
  'Lanier Senior High School',
  'Lanier Technical College',
  'Lanier-james Education Center',
  'Laning Avenue School',
  'Lankaran State University',
  'Lankenau Hs',
  'Lankershim Elementary',
  'Lannon Elementary',
  'Lannoye Elementary',
  'Lanny Frasier Middle',
  'Lanoka Harbor Elementary School',
  'Lanphier High School',
  'Lansberry El',
  'Lansbridge University',
  'Lansdowne Elementary',
  'Lansdowne Elementary School',
  'Lansdowne High & Academy Of Finance',
  'Lansdowne Middle',
  'Lanse Area School',
  'Lanse Creuse High School',
  'Lanse Creuse High School North',
  'Lanse Creuse Middle School Central',
  'Lanse Creuse Middle School East',
  'Lanse Creuse Middle School North',
  'Lanse Creuse Middle School South',
  'Lanse Creusemount Clemens Center For Lifelong Learning',
  'Lansing Adult Education',
  'Lansing Charter Academy',
  'Lansing Community College',
  'Lansing Correctional Facility',
  'Lansing Elementary Community School',
  'Lansing Elementary School',
  'Lansing High 9-12',
  'Lansing High School',
  'Lansing Middle 6-8',
  'Lansing Middle School',
  'Lansingburgh Senior High School',
  'Lantana Elementary School',
  'Lantana Middle Adult Education Center',
  'Lantana Middle School',
  'Lantern Lane El',
  'Lantern Road Elementary School',
  'Lantrip El',
  'Lanzhou University',
  'Laona High',
  'Lapeer Co Education And Technology Center',
  'Lapeer Community High School',
  'Lapeer High School',
  'Lapel Elementary School',
  'Lapel Middle School',
  'Lapel Sr High School',
  'Lapham Elementary',
  'Lapine Elementary School',
  'Lapine Middle School',
  'Lapine Senior High School',
  'Laplace Elementary School',
  'Lapoint School',
  'Laporte Elementary',
  'Laporte High School',
  'Laporte Secondary',
  'Lapoynor El',
  'Lapoynor H S',
  'Lapoynor J H',
  'Lappeenranta University of Technology',
  'Lapwai Elementary School',
  'Lapwai Middle/high School',
  'Laquey R-v Elem.',
  'Laquey R-v High',
  'Laquey R-v Middle',
  'Lara Elem Academy',
  'Lara Kendall Elementary',
  'Laramie County Community College',
  'Laramie High School',
  'Laramie Middle School',
  'Laramie Montessori Charter School',
  'Laraway Elem School',
  'Larchmont',
  'Larchmont Charter',
  'Larchmont Elementary',
  'Larchmont Elementary School',
  'Laredo Child Development Center',
  'Laredo Community College',
  'Laredo Elem.',
  'Laredo Elementary School',
  'Laredo Middle School',
  'Laremont School',
  'Largo High',
  'Largo High School',
  'Largo Middle School',
  'Largo-tibet Elementary School',
  'Larimore Elem.',
  'Larimore Elementary School',
  'Larimore High School',
  'Lark Elementary School',
  'Lark Ellen Elementary',
  'Larkdale Elementary School',
  'Larkin Bailey Es',
  'Larkin High School',
  'Larkin School For The Health Sciences',
  'Larkmoor Elementary School',
  'Larkspur El',
  'Larkspur Elementary School',
  'Larkspur Middle',
  'Larne Elementary',
  'Larned Middle School',
  'Larned Sr High',
  'Larose Elementary',
  'Larose-cut Off Middle School',
  'Larry A. Ryle High School',
  'Larry C Kennedy School',
  'Larry D Guinn Special Programs Center',
  'Larry G Smith El',
  'Larry J. Macaluso El Sch',
  'Larry Miller Intermediate Elementary School',
  'Larrymore Elementary',
  'Larsen Bay School',
  'Larsen Elementary School',
  'Larsen Middle School',
  'Larsen School',
  'Larson El',
  'Larson Elementary',
  'Larson Heights Elementary',
  'Larson Middle School',
  'Larue County High School',
  'Larue County Middle School',
  'Larue Miller El',
  'Las Americas',
  'Las Americas Aspira Academy',
  'Las Americas Children Center',
  'Las Animas Elementary',
  'Las Animas Elementary School',
  'Las Animas High School',
  'Las Animas Junior High School',
  'Las Brisas Academy',
  'Las Brisas Elementary School',
  'Las Colinas El',
  'Las Colinas Middle',
  'Las Cruces High',
  'Las Flores Elementary',
  'Las Flores High (alternative)',
  'Las Flores Home Education Independent Study Academy',
  'Las Flores Middle',
  'Las Juntas Elementary',
  'Las Lomas',
  'Las Lomas El',
  'Las Lomas Elementary',
  'Las Lomas High',
  'Las Lomitas Elementary',
  'Las Mareas',
  'Las Mercedes',
  'Las Monjitas',
  'Las Montanas Charter',
  'Las Palmas',
  'Las Palmas El',
  'Las Palmas Elementary',
  'Las Palmas Middle',
  'Las Palmitas Elementary',
  'Las Parcelas De Campo Rico',
  'Las Plumas High',
  'Las Posas Elementary',
  'Las Positas College',
  'Las Positas Elementary',
  'Las Promise Charter High 1',
  'Las Puertas Community School',
  'Las Sendas Elementary School',
  "Las Vegas Academy Of Int'l Studies Performing And Visual Ar",
  'Las Vegas High School',
  'Las Virtudes',
  'Las Yescas El',
  'Lasa H S',
  'Lasalle Backus Ec',
  'Lasalle County Detention Home',
  'Lasalle Elem Language Academy',
  'Lasalle Elem School',
  'Lasalle Elementary School',
  'Lasalle High School',
  'Lasalle Ii Lang Acad Elem Sch',
  'Lasalle Intermediate Academy',
  'Lasalle Junior High School',
  'Lasalle Preparatory School',
  'Lasalle Springs Middle',
  'Lasara El',
  'Lasara H S',
  'Lasater El',
  'Lascassas Elementary',
  'Lasd Preschool',
  'Lasell College',
  'Lashawn Dcps Non Public',
  'Lashmeet/matoaka Elementary School',
  'Lashon Academy',
  'Lasley Elementary School',
  'Lassalette',
  'Lasselle Elementary',
  'Lassen Community College',
  'Lassen Community Day',
  'Lassen County Special Education',
  'Lassen Elementary',
  'Lassen High',
  'Lassen Rop',
  'Lassen View Community Day',
  'Lassen View Elementary',
  'Lassen-antelope Volcanic Academy (lava)',
  'Lassiter High School',
  'Lassiter Middle',
  'Latexo El',
  'Latexo H S',
  'Latham Elem.',
  'Latham Elementary School',
  'Latham Ridge School',
  'Lathers Early Childhood And Kindergarten Center',
  'Lathrop Elem.',
  'Lathrop Elementary',
  'Lathrop High',
  'Lathrop High School',
  'Lathrop Middle',
  'Lathrop Street El Sch',
  'Latimer Elementary',
  'Latimer Lane School',
  'Latin American Montessori Bilingual Pcs',
  'Latin Builders Association Construction And Business Managem',
  'Latin College Prep',
  'Latin Grammar School',
  'Latino College Preparatory Academy',
  'Laton Elementary',
  'Laton High',
  'Latona Avenue Elementary',
  'Latonia Elementary School',
  'Latrobe El Sch',
  'Latrobe Elementary',
  'Latta Elementary',
  'Latta Es',
  'Latta High',
  'Latta Hs',
  'Latta Middle',
  'Latta Ms',
  'Latter-​day Saints Business College',
  'Lattie Coor',
  'Latvian Academy of Arts',
  'Latvian Academy of Culture',
  'Latvian Academy of Music',
  'Latvian Academy of Sports Education',
  'Latvian Maritime Academy',
  'Latvian University of Agriculture',
  'Lau (gordon J.) Elementary',
  'Lauderbach (j. Calvin) Elementary',
  'Lauderdale Co Educational & Skills',
  'Lauderdale County High School',
  'Lauderdale Lakes Middle School',
  'Lauderdale Manors Early Learning & Resource Center',
  'Lauderdale Manors Elementary',
  'Lauderhill 6-12',
  'Lauderhill High',
  'Lauderhill Paul Turner Elementary School',
  'Lauers Park El Sch',
  'Laughlin El Stem Magnet',
  'Laughlin Jr/sr High School',
  'Laukhuf Elementary',
  "Lauli'i Elementary School",
  'Launch Expeditionary Learning Charter School',
  'Launch High School',
  'Launch Preschool',
  'Laupahoehoe Community - Pcs',
  'Laura B Negley El',
  'Laura B Sprague School',
  'Laura B Wallace Middle',
  'Laura B. Anderson Elementary - 10',
  'Laura Baker Sun Program',
  'Laura C. Saunders Elementary School',
  'Laura Dearing Elementary School',
  'Laura Donovan School',
  'Laura E Richards School',
  'Laura Hose Elementary School',
  'Laura Ingalls Wilder',
  'Laura Ingalls Wilder Elementary - 02',
  'Laura Ingalls Wilder Int',
  'Laura Irwin Elementary',
  'Laura Jeffrey Academy Charter',
  'Laura Lee Therapeutic Day School',
  'Laura Macarthur Elementary',
  'Laura Mercado',
  'Laura N. Banks Elementary',
  'Laura Navarro',
  'Laura Reeves El',
  'Laura Reeves Pri',
  'Laura Speed Elliott Middle',
  'Laura Welch Bush El',
  'Laura Wilder Elementary - 31',
  'Laura Woodward Elementary School',
  'Laureate Academy Charter School',
  'Laureate Park Elementary',
  'Laurel Co Schools Center For Innovation',
  'Laurel County Day Treatment',
  'Laurel County Virtual Academy',
  'Laurel Creek Elementary',
  'Laurel Dell Elementary',
  'Laurel Education Center',
  'Laurel El Sch',
  'Laurel Elem Magnet Sch Of Innovation And Career Explr',
  'Laurel Elementary',
  'Laurel Elementary School',
  'Laurel High',
  'Laurel High School',
  'Laurel High School Voc Complex',
  'Laurel Highlands Ms',
  'Laurel Highlands Shs',
  'Laurel Hill Elem School',
  'Laurel Hill Elementary',
  'Laurel Hill Primary',
  'Laurel Hill School',
  'Laurel Hills Elem.',
  'Laurel Intermediate Middle School',
  'Laurel Jshs',
  'Laurel Lane Elementary',
  'Laurel Ledge School',
  'Laurel Meadow Elementary',
  'Laurel Middle School',
  'Laurel Mill Elementary',
  'Laurel Mountain El',
  'Laurel New School Hold',
  'Laurel Nokomis School',
  'Laurel Oak Elementary School',
  'Laurel Oaks Cdc',
  'Laurel Oaks Charter School',
  'Laurel Park Elementary',
  'Laurel Park Elementary School',
  'Laurel Park Middle',
  'Laurel Plains Elementary School',
  'Laurel Preparatory Academy',
  'Laurel Regional Special Ed Ctr',
  'Laurel Ridge',
  'Laurel Ridge Elementary',
  'Laurel Ridge Elementary School',
  'Laurel Ridge Middle School',
  'Laurel Ruff Transition',
  'Laurel School',
  'Laurel Senior High School',
  'Laurel Springs School',
  'Laurel Street Elementary',
  'Laurel Tree Charter',
  'Laurel Valley El Sch',
  'Laurel Wood Elementary',
  'Laurel Woods Elementary',
  'Laurel-concord-coleridge Elementary School',
  'Laurel-concord-coleridge High School',
  'Laurel-concord-coleridge Middle School',
  'Laureles El',
  'Laurelglen Elementary',
  'Laurelhurst Elementary School',
  'Laurelton-pardee Intermediate School',
  'Laurelville Elementary School',
  'Laurelwood Elementary',
  'Laurence J. Daly Elem.',
  'Laurene Edmondson Elementary School',
  'Laurens Central School',
  'Laurens District 55 High',
  'Laurens Elementary',
  'Laurens Middle',
  'Laurens-marathon Elementary School',
  'Laurens-marathon High School',
  'Laurens-marathon Middle School',
  'Laurentian Environmental Center',
  'Laurentian University of Sudbury',
  'Laurentino Estrella Colon',
  'Laurentino Nieves Velez',
  'Laurenzo Early Childhood Ctr',
  'Lauretta B Millsop Primary School',
  'Laurin Middle School',
  'Lauro Cavazos El',
  'Laurus Academy',
  'Laurus College',
  'Lausanne Hotel School (EHL)',
  'Lava Elementary School',
  'Lava Ridge Elementary School',
  'Lava Ridge Intermediate',
  'Lavaca Elementary School',
  'Lavaca High School',
  'Lavaca Middle School',
  'Lavace Stewart El',
  'Lavaland Elementary',
  'Lavalette Elementary School',
  'Lavallette Elementary School',
  'Laveen Elementary School',
  'Lavergne High School',
  'Lavergne Lake Elementary School',
  'Lavergne Middle School',
  'Lavergne Primary',
  'Laverna Evans Elem School',
  'Laverne Elementary Preparatory Academy',
  'Laverne Es',
  'Laverne Hs',
  'Lavilla School Of The Arts',
  'Laville Elementary School',
  'Laville Jr-sr High School',
  'Lavina 7-8',
  'Lavina High School',
  'Lavina School',
  'Lavizzo Elem School',
  'Lavonia Elementary School',
  'Lavoy Exceptional Center',
  'Law El',
  'Law Elementary School',
  'Law Enforcement Officers Memorial High School',
  'Law University of Bratislava',
  'Lawhon Elementary School',
  'Lawler Middle',
  'Lawless Elementary',
  'Lawlor Early Childhood Center',
  'Lawn El',
  'Lawn Manor School',
  'Lawndale El',
  'Lawndale Elem Community Academy',
  'Lawndale Elementary School',
  'Lawndale High',
  'Lawnside School',
  'Lawnton El Sch',
  'Lawnwood Elementary School',
  'Lawrence',
  'Lawrence & Heidi Canarelli Middle School',
  'Lawrence - Nelson Middle School',
  'Lawrence Avenue Elementary School',
  'Lawrence Brook Elementary School',
  'Lawrence Central High School',
  'Lawrence Co High School',
  'Lawrence Co Tech & Career Center',
  'Lawrence Cook Middle',
  'Lawrence County',
  'Lawrence County Academy',
  'Lawrence County Center Technology',
  'Lawrence County Ctc',
  'Lawrence County Developmental',
  'Lawrence County High School',
  'Lawrence D. Crocker College Prep',
  'Lawrence E Elkins H S',
  'Lawrence E. Jones Middle',
  'Lawrence Early Childhood Center At #4 School',
  'Lawrence Early Learning Center',
  'Lawrence Education Center',
  'Lawrence El',
  'Lawrence Elem',
  'Lawrence Elementary',
  'Lawrence Elementary - 02',
  'Lawrence Elementary School',
  'Lawrence Elementary School At Broadway Campus',
  'Lawrence Family Development Charter School',
  'Lawrence Family Public Academy',
  'Lawrence Free State High',
  'Lawrence Gardner High School',
  'Lawrence High',
  'Lawrence High School',
  'Lawrence Intermediate School',
  'Lawrence Jr High School',
  'Lawrence Jrsr High School',
  'Lawrence Liberty Memorial Central Mid School',
  'Lawrence Middle School',
  'Lawrence Middle School At Broadway Campus',
  'Lawrence North High School',
  'Lawrence Orr Elementary',
  'Lawrence Primary School At #2 School',
  'Lawrence Road Middle School',
  'Lawrence School',
  'Lawrence Senior High School',
  'Lawrence South Middle School',
  'Lawrence Southwest Middle School',
  'Lawrence Technological University',
  'Lawrence University',
  'Lawrence Virtual School',
  'Lawrence W Cross Middle School',
  'Lawrence W Pingree',
  'Lawrence West Middle School',
  'Lawrence-lawson Elementary',
  'Lawrence/nelson Elementary Sch',
  'Lawrence/nelson High Sch',
  'Lawrenceburg High School',
  'Lawrenceburg Primary School',
  'Lawrenceburg Public',
  'Lawrenceville Elementary School',
  'Lawrenceville High School',
  'Lawson Elem.',
  'Lawson Elementary School',
  'Lawson High',
  'Lawson Middle',
  'Lawson-marriott Elementary',
  'Lawsonville Elementary',
  'Lawtell Elementary School',
  'Lawtey Elementary School',
  'Lawton Accelerated Academic Center',
  'Lawton Alternative Elementary',
  'Lawton C. Johnson Summit Middle School',
  'Lawton Chiles Elementary',
  'Lawton Chiles High School',
  'Lawton Chiles Middle Academy',
  'Lawton Chiles Middle School',
  'Lawton Elementary School',
  'Lawton Henry W Sch',
  'Lawton High School',
  'Lawton Hs',
  'Lawton Junior-senior High School',
  'Lawton M. Chiles Elementary School',
  'Lawton Middle School',
  'Layer Elementary School',
  'Layne Elementary',
  'Layton High',
  'Layton School',
  'Laytonsville Elementary',
  'Laytonville Continuation High',
  'Laytonville Elementary',
  'Laytonville High',
  'Lazaro Larry Garcia',
  'Lazarski School of Commerce and Law',
  'Lazbuddie School',
  'Lazear Charter Academy',
  'Lbj Elementary School',
  'Lbj High School',
  'Lc - Breakthrough Ii School',
  'Lc - Burns School',
  'Lc Hatcher Elementary School',
  'Lcdo Guillermo Atiles Moreau',
  'Lcst',
  'Le Barron Park El',
  'Le Center Pre K-8',
  'Le Cordon Bleu College of Culinary Arts-​Chicago',
  'Le Grand Elementary',
  'Le Grand High',
  'Le Mars High School',
  'Le Mars Middle School',
  'Le Monde French Immersion Public Charter School',
  'Le Moyne College',
  'Le Noir El',
  'Le Pera Elementary School',
  'Le Roy Junior-senior High School',
  'Le Sueur-henderson High School',
  'Le Sueur-henderson Middle School',
  'Le White Middle School',
  "Le'atele Elementary School",
  'LeTourneau University',
  'Lea Elementary',
  'Lea Henry C Sch',
  'Lea Hill Elementary School',
  'Leach (john G.) School',
  'Leach Public School',
  'Lead Academy',
  'Lead Academy H S',
  'Lead Charter School',
  'Lead City University of Ibadan',
  'Lead Correspondence',
  'Lead Elementary',
  'Lead Hill Elementary School',
  'Lead Hill High School',
  'Lead Innovation Studio',
  'Lead Mine Elementary',
  'Lead Prep Southeast',
  'Lead-deadwood Career & Technical Education - 10',
  'Lead-deadwood Elementary - 03',
  'Lead-deadwood High School - 01',
  'Lead-deadwood Middle School - 02',
  'Leaders Heights El Sch',
  'Leaders In Learning',
  'Leaders Of Tomorrow',
  'Leadership & Public Service High School',
  'Leadership Academy',
  'Leadership Academy For Young Men',
  'Leadership Academy Of Nevada',
  'Leadership Academy Of Utah',
  'Leadership High',
  'Leadership Institute',
  'Leadership Learning Academy',
  'Leadership Learning Academy - Ogden',
  'Leadership Prep School',
  'Leadership Prep School Secondary',
  'Leadership Preparatory Academy',
  'Leadership Preparatory Bedford Stuyvesant Charter School',
  'Leadership Preparatory Brownsville Charter School',
  'Leadership Preparatory Canarsie Charter School',
  'Leadership Preparatory Charter School',
  'Leadership Preparatory Ocean Hill Charter School',
  'Leadership Public Schools - Hayward',
  'Leadership Public Schools: Richmond',
  'Leading Creek Elementary',
  'Leading Edge Academy At East Mesa',
  'Leading Edge Academy Gilbert Early College',
  'Leading Edge Academy Gilbert Elementary',
  'Leading Edge Academy Maricopa',
  'Leading Edge Academy Mountain View',
  'Leading Edge Academy Online',
  'Leading Edge Academy San Tan',
  'Leading University',
  'Leadore School',
  'Leads Primary Charter School',
  'Leaf Charter School',
  'Leaf River Academy',
  'League Academy',
  'League City El',
  'League City Int',
  'League Elementary School',
  'Leake Central Elementary School',
  'Leake Central High School',
  'Leake Central Junior High',
  'Leake Co Career & Technical Center',
  'Leake County Elementary School',
  'Leake County High School',
  'Leakesville Elementary School',
  'Leakesville Jr High School',
  'Leakey School',
  'Leaksville-spray Elementary',
  'Leal (frank C.) Elementary',
  'Leal El',
  'Leal Elem School',
  'Lealman Avenue Elementary School',
  'Lealman Innovation Academy',
  'Lean Elementary School',
  'Leander H S',
  'Leander Middle',
  'Leap Academy',
  'Leap Academy At North Fairmont',
  'Leap Academy University Charter School',
  'Leap High School',
  'Leap School',
  'Leaphart Elementary',
  'Leapss',
  'Leapwood Avenue Elementary',
  'Lear North Elementary',
  'Learey Technical College',
  'Learn 10 Charter School',
  'Learn Aba Program At Center School',
  'Learn Charter Excel Campus',
  'Learn Charter Schools',
  'Learn Chtr - 7th Campus',
  'Learn Chtr - Butler',
  'Learn Chtr - Hunter Perkins Camp',
  'Learn Chtr - Middle School',
  'Learn Chtr - South Chicago Campus',
  'Learn Chtr 9 Campus In Waukegan',
  'Learn Chtr Sch 6 N Chicago Campus',
  'Learn Chtr-campbell Campus',
  'Learn Haynes Elementary Intensive Program',
  'Learn High School Aba',
  'Learn Ledyard Riches Program',
  'Learn Pathways Program',
  'Learn Sails Middle School Program',
  'Learn Sails Program At Salem',
  'Learn Sts 18-21 Year Old Program',
  'Learners And Leaders',
  'Learners Center',
  'Learning Academy',
  'Learning Academy At Bloomfield',
  'Learning Academy Of Santa Rosa',
  'Learning Alternative Center For Empowering Youth',
  'Learning Bridge',
  'Learning Center',
  'Learning Choice Academy',
  'Learning Community At Broadalbin-perth (the)',
  'Learning Community Charter',
  'Learning Community Charter School',
  'Learning Ctr',
  'Learning For Leadership Charter',
  'Learning For Life Charter',
  'Learning Foundation',
  'Learning Foundation And Performing Arts Alta Mesa',
  'Learning Foundation And Performing Arts Gilbert',
  'Learning Foundation And Performing Arts Warner',
  'Learning Gate Community School',
  'Learning Institute The',
  'Learning Latitudes Charter',
  'Learning Lodge Academy',
  'Learning Options High School',
  'Learning Path Academy',
  'Learning Post High (alternative)',
  'Learning Support',
  'Learning Tree Academy',
  'Learning Tree Preschool',
  'Learning Way Elementary',
  'Learning Without Limits',
  'Learning Works',
  'Learwood Middle School',
  'Leary El',
  'Leasure (may B.) Elementary School',
  'Leataata Floyd Elementary',
  'Leatherwood Elementary School',
  'Leavelle Mccampbell Middle',
  'Leavenworth Sr High',
  'Leavenworth Virtual School',
  'Leavitt Area High School',
  'Leawood Elementary',
  'Leawood Elementary School',
  'Leawood Middle',
  'Lebanese American University',
  'Lebanese International University',
  'Lebanese University',
  'Lebanon Borough School',
  'Lebanon County Ctc',
  'Lebanon Elem School',
  'Lebanon Elementary',
  'Lebanon Elementary School',
  'Lebanon Elementary Schools',
  'Lebanon High',
  'Lebanon High School',
  'Lebanon Junction Elementary',
  'Lebanon Junior High',
  'Lebanon Middle',
  'Lebanon Middle School',
  'Lebanon Ms',
  'Lebanon Primary',
  'Lebanon Road Elementary',
  'Lebanon Senior High School',
  'Lebanon Shs',
  'Lebanon Sr. High',
  'Lebanon Tech. Career Ctr.',
  'Lebanon Trail H S',
  'Lebanon Valley College',
  'Lebhart Elementary',
  'Leblanc Elementary School',
  'Leblanc Middle School',
  'Leblanc Therapeutic Day School',
  'Lebleu Settlement Elementary School',
  'Lebo Elem',
  'Lebo High',
  'Lecanto High School',
  'Lecanto Middle School',
  'Lecanto Primary School',
  'Leckie Ec',
  'Leclaire Elem School',
  'Lecompton Elem',
  'Leconte Elementary',
  'Ledesma (rita) Elementary',
  'Ledford Middle',
  'Ledford Senior High',
  'Ledge Street School',
  'Ledgemont Elementary',
  'Ledgeview Elementary School',
  'Ledgewood Elem School',
  'Ledyard Center School',
  'Ledyard Charter School',
  'Ledyard High School',
  'Ledyard Middle School',
  'Lee A Mcshan Jr El',
  'Lee A. Tolbert Com. Academy',
  'Lee Academy',
  'Lee Adolescent Mothers Program',
  'Lee Antonello Elementary School',
  'Lee Avenue School',
  'Lee Buice El',
  'Lee Burneson Middle School',
  'Lee Central High',
  'Lee Central Middle',
  'Lee Co. Schools Improvement Center',
  'Lee College',
  'Lee County Area Technology Center',
  'Lee County Career & Tech. Center',
  'Lee County Career And Technology Center',
  'Lee County Elementary School',
  'Lee County High',
  'Lee County High School',
  'Lee County Jail',
  'Lee County Middle High School',
  'Lee County Middle School East',
  'Lee County Middle School West',
  'Lee County Primary School',
  'Lee County Virtual Franchise',
  'Lee County Virtual Instruction Program (district Provided)',
  'Lee Cty Corrections',
  'Lee Davis High',
  'Lee Early College',
  'Lee Eaton Elementary School',
  'Lee El',
  'Lee Elem',
  'Lee Elem School',
  'Lee Elementary',
  'Lee Elementary Magnet School',
  'Lee Elementary School',
  'Lee Es',
  'Lee F Jackson School',
  'Lee Freshman H S',
  'Lee H Means El',
  'Lee H S',
  'Lee H. Kellogg School',
  'Lee Hall Early Childhood Center',
  'Lee Hall Elementary',
  'Lee Hamilton Elem.',
  'Lee High',
  'Lee High School',
  'Lee High School 9th Grade Campus',
  'Lee Hill Elementary',
  'Lee Hunter Elem.',
  'Lee Kornegay Intermediate School',
  'Lee L Driver Middle School',
  'Lee Learning Center',
  'Lee M Thurston High School',
  'Lee M. Waid Elementary',
  'Lee Mathson Middle',
  'Lee Middle',
  'Lee Middle School',
  'Lee Middle/high School',
  'Lee Montessori Pcs',
  'Lee Park El Sch',
  'Lee R Foster Elem School',
  'Lee Richmond Elementary',
  'Lee Road Junior High School',
  'Lee Road School',
  'Lee Street Elementary School',
  'Lee V. Pollard High',
  'Lee Vining Elementary',
  'Lee Vining High',
  'Lee Virtual Instruction (course Offerings)',
  'Lee Virtual Instruction Program',
  'Lee Williams High School',
  'Lee Woodard Elementary',
  "Lee's Summit Elem.",
  "Lee's Summit North High",
  "Lee's Summit Sr. High",
  "Lee's Summit West High",
  'Lee-jackson Elementary',
  'Lee/winn School',
  'Leech Lake Tribal College',
  'Leechburg Area Hs',
  'Leechburg Area Ms',
  'Leedey Es',
  'Leedey Hs',
  'Leedom El Sch',
  'Leeds',
  'Leeds Avenue Elementary School',
  'Leeds Central School',
  'Leeds Elementary',
  'Leeds Elementary School',
  'Leeds High School',
  'Leeds Metropolitan University',
  'Leeds Middle School',
  'Leeds Primary School',
  'Leelanau Montessori Public School Academy',
  'Lees Corner Elementary',
  'Leesburg Elementary',
  'Leesburg Elementary School',
  'Leesburg High School',
  'Leestown Middle School',
  'Leesville Elem.',
  'Leesville High School',
  'Leesville Junior High School',
  'Leesville Road Elementary',
  'Leesville Road High',
  'Leesville Road Middle',
  'Leesylvania Elementary',
  'Leeton Elem.',
  'Leeton High',
  'Leeton Middle',
  'Leetonia Elementary School',
  'Leetonia High School',
  'Leetonia Middle School',
  'Leeward Community College',
  'Leewood K-8 Center',
  'Leffingwell Elementary',
  'Leffingwell High (continuation)',
  'Lefler Middle School',
  'Leflore County Alternative School',
  'Leflore County Elementary School',
  'Leflore County High School',
  'Leflore County Voc Center',
  'Leflore Es',
  'Leflore Hs',
  'Lefors School',
  'Legacy Acad Of Excellence Charter',
  'Legacy Academy',
  'Legacy Academy Charter',
  'Legacy Charter',
  'Legacy Charter Academy',
  'Legacy Charter School',
  'Legacy College Preparatory Charter School',
  'Legacy Early College',
  'Legacy El',
  'Legacy Elem Charter School',
  'Legacy Elementary',
  'Legacy Elementary School',
  'Legacy High',
  'Legacy High Charter',
  'Legacy High School',
  'Legacy Jr High',
  'Legacy Leadership Academy Inc.',
  'Legacy Middle',
  'Legacy Options High School',
  'Legacy Point Elementary School',
  'Legacy Preparatory Academy',
  'Legacy Preparatory Charter Academy Mesquite',
  'Legacy Preparatory Charter Academy Plano',
  'Legacy Scholars Academy',
  'Legacy School',
  'Legacy School Of Sport Sciences',
  'Legacy Traditional Charter School - Gilbert',
  'Legacy Traditional Charter School - Laveen Village',
  'Legacy Traditional School - Avondale',
  'Legacy Traditional School - Casa Grande',
  'Legacy Traditional School - Chandler',
  'Legacy Traditional School - East Mesa',
  'Legacy Traditional School - Glendale',
  'Legacy Traditional School - Maricopa',
  'Legacy Traditional School - North Chandler',
  'Legacy Traditional School - Northwest Tucson',
  'Legacy Traditional School - Peoria',
  'Legacy Traditional School - Queen Creek',
  'Legacy Traditional School - Surprise',
  'Legacy Traditional School North Valley',
  'Legal Prep Academy Charter Hs',
  'Legend Elementary',
  'Legend High School',
  'Legend Springs Elementary',
  'Legends Academy Charter',
  'Legends Property',
  'Legg Middle School',
  'Leggee Elementary School',
  'Leggett Community Learning Center',
  'Leggett El',
  'Leggett H S',
  'Leggett Valley Elementary',
  'Leggett Valley High',
  'Legion Park Elementary',
  'Legion Park Elementary School',
  'Legore Elementary',
  'Legrande Elementary School',
  'Lehi Elementary School',
  'Lehi High',
  'Lehi Jr High',
  'Lehi School',
  'Lehigh Acres Middle School',
  'Lehigh Carbon Community College',
  'Lehigh Career & Technical Institute',
  'Lehigh El Sch',
  'Lehigh Elementary',
  'Lehigh Elementary School',
  'Lehigh Parkway El Sch',
  'Lehigh Senior High School',
  'Lehigh University',
  'Lehigh Valley Academy Regional Cs',
  'Lehigh Valley Charter High School For Th',
  'Lehigh Valley Dual Language Charter Scho',
  'Lehighton Area Hs',
  'Lehighton Area Ms',
  'Lehman Alternative Community School',
  'Lehman H S',
  'Lehman Intermediate Sch',
  'Lehman Middle School',
  'Lehman-jackson El Sch',
  'Lehua Elementary School',
  'Leib El Sch',
  'Leicester Central School',
  'Leicester Elementary',
  'Leicester High',
  'Leicester Memorial Elementary',
  'Leicester Middle',
  'Leicester Primary School',
  'Leiden University',
  'Leigh Elementary School',
  'Leigh High',
  'Leigh High School',
  'Leighton Elementary School',
  'Leighton Hall (dentention Center)',
  'Leihoku Elementary School',
  'Leila Davis Elementary School',
  'Leila P Cowart El',
  'Leilehua High School',
  'Leinkauf Elementary School',
  'Leipsic Elementary School',
  'Leipsic High School',
  'Leisure City K-8 Center',
  'Leisure Park Es',
  'Leith Walk Elementary',
  'Leland & Gray Uhsd #34',
  'Leland E Edge Middle',
  'Leland Elem School',
  'Leland Elementary Accerated School',
  'Leland High',
  'Leland High School',
  'Leland Middle',
  'Leland P Brown Elementary',
  'Leland Public School',
  'Leland School Park',
  'Leland Street Elementary',
  'Leland Voc Complex',
  'Lely Elementary School',
  'Lely High School',
  'Leman Academy Of Excellence',
  'Leman Academy Of Excellence Sierra Vista',
  'Leman Academy Of Excellence-oro Valley Arizona',
  'Leman Academy Of Excellence-sierra Vista',
  'Leman Middle School',
  'Leman Virtual Academy',
  'Lemasters Elem.',
  'Lemay Elementary School',
  'Lemay Street Elementary',
  'Lemira Elementary',
  'Lemm El',
  'Lemmer Elementary School',
  'Lemmon Elementary - 02',
  'Lemmon High School - 01',
  'Lemmon Jr. High - 06',
  'Lemmon Valley Elementary School',
  'Lemon Avenue Elementary',
  'Lemon Bay High School',
  'Lemon Crest Elementary',
  'Lemon Grove Academy For The Sciences And Humanities',
  'Lemon Road Elementary',
  'Lemons Mill Elementary School',
  'Lemont Twp High School',
  'Lemonweir Elementary',
  'Lemonwood Elementary',
  'Lemoore Elementary',
  'Lemoore High',
  'Lemoore Middle College High',
  'Lemoore Online College Preparatory High',
  'Lemoore University Elementary Charter',
  'Lemoyne Elementary School',
  'Lempster Community School',
  'Len Lastinger Primary School',
  'Lena Archuleta Elementary School',
  'Lena Dunn Elementary School',
  'Lena Elem School',
  'Lena Elementary',
  'Lena High',
  'Lena Juniper Elementary School',
  'Lena M. Francesch (rubias Km. 25)',
  'Lena Mae Moses Middle School',
  'Lena Middle',
  'Lena Vista Elementary School',
  'Lena Whitmore Elementary School',
  'Lena-winslow High School',
  'Lena-winslow Jr High School',
  'Lenape El Sch',
  'Lenape Elementary School',
  'Lenape High School',
  'Lenape Meadows',
  'Lenape Ms',
  'Lenape Tech',
  'Lenape Valley Regional High School',
  'Lenart Elem Regional Gifted Ctr',
  'Lengel Ms',
  'Lenicia B. Weemes Elementary',
  'Lenihan Intermediate School',
  'Lenkerville El Sch',
  'Lenna W. Conrow Elementary School',
  'Lennard Adult & Community Center',
  'Lennard High School',
  'Lennox Elementary - 02',
  'Lennox High School - 01',
  'Lennox Intermediate - 09',
  'Lennox Jr. High - 08',
  'Lennox Mathematics Science And Technology Academy',
  'Lennox Middle',
  'Lennox Virtual Academy',
  'Lenoir City Elementary',
  'Lenoir City High School',
  'Lenoir City Intermediate/middle School',
  'Lenoir Community College',
  'Lenoir County Early College High',
  'Lenoir County Learning Academy',
  'Lenoir-Rhyne College',
  'Lenora Braynon Smith Elementary',
  'Lenore Kirk Hall El',
  'Lenore Pre K-8 School',
  'Lenox Elementary School',
  'Lenox High School',
  'Lenox Memorial High',
  'Lenox School',
  'Lent Elementary School',
  'Lenwil Elementary School',
  'Lenwood Elementary',
  'Leo A Rizzuto El',
  'Leo A. Savoie School',
  'Leo Adams Middle',
  'Leo B. Hart Elementary',
  'Leo Bernabi School',
  'Leo Carrillo Elementary',
  'Leo E. Kerner Jr. Elementary School',
  'Leo Elementary School',
  'Leo F Giblyn School',
  'Leo G. Pauly Elementary',
  'Leo J Leo El',
  'Leo Junior/senior High School',
  'Leo Politi Elementary',
  'Leo R. Croce Elementary',
  'Leo William Butler Elementary School',
  'Leola El Sch',
  'Leola Elementary - 02',
  'Leola High School - 01',
  'Leola Middle School - 03',
  'Leoma Elementary',
  'Leominster Center For Excellence',
  'Leominster High School',
  'Leon Co Juvenile Detention Center',
  'Leon County Jail',
  'Leon County Virtual Instruction Program',
  'Leon County Virtual School Franchise',
  'Leon Countywide Adult Education Prog',
  'Leon El',
  'Leon Elementary School',
  'Leon H S',
  'Leon H Sablatura Middle',
  'Leon H. Ollivier Middle',
  'Leon Heights El',
  'Leon High School',
  'Leon J H',
  'Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw',
  'Leon M Goldstein High School For The Sciences',
  'Leon Sheffield Magnet Elementary School',
  'Leon Springs El',
  'Leon Taylor J H',
  'Leon Valley El',
  'Leon Virtual Academy',
  'Leon Virtual Instruction (course Offerings)',
  'Leon W Hayes Middle School',
  'Leona Ave Middle School',
  'Leona H. Cox Community Elementary',
  'Leona Jackson',
  'Leona Libby Middle School',
  'Leona Valley Elementary',
  'Leonard C Seal Elem',
  'Leonard Dober Elementary School',
  'Leonard El',
  'Leonard Elementary School',
  'Leonard Evans Jr Middle',
  'Leonard G. Westhoff Elementary',
  'Leonard H S',
  'Leonard Int',
  'Leonard J H',
  'Leonard J. Tyl Middle School',
  'Leonard Lawrence Elem School',
  'Leonard M Jennings Elementary',
  'Leonard Middle',
  'Leonard Middle School',
  'Leonard Shanklin El',
  'Leonard V. Moore Middle School',
  'Leonardo Da Vinci',
  'Leonardo Da Vinci Health Sciences Charter',
  'Leonardo Da Vinci High School',
  'Leonardo Da Vinci School For Gifted Learners',
  'Leonardo Elementary School',
  'Leonardo Valentin Tirado',
  'Leonardtown Elementary',
  'Leonardtown High',
  'Leonardtown Middle',
  'Leoncio Melendez',
  'Leone High School',
  'Leone Midkiff Elementary School',
  'Leonia High School',
  'Leonia Middle School',
  'Leonidas School',
  'Leonides Gonzalez Cigarroa Md El',
  'Leonides Morales Rodriguez',
  'Leonor Hambly K-8',
  'Leonora Fillmore Elementary',
  'Leontine Gracey Elementary',
  'Leonville Elementary School',
  'Leopold Elem.',
  'Leopold Elementary',
  'Leopold High',
  'Leota Fiedler Elementary School',
  'Leota Middle School',
  'Lepanto Elementary',
  'Leptondale Elementary School',
  'Lerna Bridges',
  'Leroy Anderson Elementary',
  'Leroy Drive Elementary School',
  'Leroy E.mayo',
  'Leroy Elem',
  'Leroy Elementary',
  'Leroy Elementary School',
  'Leroy Gordon Cooper Elementary School',
  'Leroy Greene Academy',
  'Leroy H Smith School',
  'Leroy High School',
  'Leroy Junior High School',
  'Leroy L. Doig Intermediate',
  'Leroy Massey Elementary School',
  'Leroy Nichols Elementary',
  'Leroy Secondary',
  'Leroy Wood',
  'Les Bois Junior High School',
  'Les Paul Middle',
  'Les Peters Academy',
  'Les Roches Gruyère University of Applied Sciences',
  'Les Roches International School of Hotel Management',
  'Leschi Elementary School',
  'Lesher Middle School',
  'Lesley University',
  'Leslie A Stemmons El',
  'Leslie Bell Elem.',
  'Leslie County Area Technology Center',
  'Leslie County High School',
  'Leslie County Learning Center',
  'Leslie Elementary School',
  'Leslie F Roblyer Learning Ctr',
  'Leslie Fox Keyser Elementary',
  'Leslie H. Walton Middle',
  'Leslie High School',
  'Leslie M. Stover Middle',
  'Leslie Middle School',
  'Lessenger Elementary School',
  'Lessie Moore Elementary School',
  'Lesslie Elementary',
  'Lester B Sommer Elementary School',
  'Lester C Noecker',
  'Lester Crawl Primary Ctr',
  'Lester D. Wilson Elementary School',
  'Lester Davis',
  'Lester Elem School',
  'Lester Elementary',
  'Lester Elementary School',
  'Lester Park Elementary',
  'Lester Prairie Elementary',
  'Lester Prairie Secondary',
  'Lester Prep',
  'Lester R Arnold High School',
  'Lesterville Elem.',
  'Lesterville High',
  'Lesterville Ranch Campus',
  'Leta Horn Smith',
  'Letcher County Alternative Education Ctr',
  'Letcher County Area Technology Center',
  'Letcher County Central High School',
  'Letcher Elementary School',
  'Letcher Middle School',
  'Letchworth Elementary School',
  'Letchworth Middle School',
  'Letchworth Senior High School',
  'Letford Elementary School',
  'Letha Raney Intermediate',
  'Leto Adult/community Center',
  'Leto High School',
  'Letort El Sch',
  'Letot Campus',
  'Letourneau Elementary School',
  'Lettie Brown Elementary School',
  'Lettie Marshall Dent Elem',
  'Leupp Public School',
  'Leupp School Inc.',
  'Leuzinger High',
  'Levan R Scott Academy',
  'Level 4 Program Pathways To Change',
  'Level Creek Elementary School',
  'Level Cross Elementary',
  'Level Green El Sch',
  'Level Grove Elementary School',
  'Level Up Academy',
  'Levelland Academic Beginnings Center',
  'Levelland H S',
  'Levelland Int',
  'Levelland Middle',
  'Levelock School',
  'Leverett Elementary',
  'Leverett Elementary School',
  'Leverette Elementary School',
  'Leveretts Chapel El',
  'Leveretts Chapel H S',
  'Leveretts Chapel J H',
  'Levi Elementary',
  'Levi H. Dickey Elementary',
  'Levi Leonard Elementary',
  'Levi Milton Elementary School',
  'Levi P. Dodge Middle School',
  'Levine Middle College High',
  'Leviton Dual Language School',
  'Levy Learning Academy',
  'Levy Sp Ed Center',
  'Levy Virtual Franchise',
  'Levy Virtual Instruction (course Offerings)',
  'Levy Virtual Instruction Program',
  'Levy Virtual Instruction Program (district Provided)',
  'Lew Muckle Elementary School',
  'Lew Wallace Elementary',
  'Lew Wallace Elementary School',
  'Lew Wallace School 107',
  'Lewelling Elementary School',
  'Lewin G. Joel Jr. School',
  'Lewis & Clark College',
  'Lewis & Clark Elem School',
  'Lewis & Clark Elementary',
  'Lewis & Clark Elementary Sch',
  'Lewis & Clark Elementary School',
  'Lewis & Clark High School',
  'Lewis & Clark Middle School',
  'Lewis & Clark School',
  'Lewis (william C.) Dual Language Elementary School',
  'Lewis And Clark Elem.',
  'Lewis And Clark Elementary',
  'Lewis And Clark Elementary Sch',
  'Lewis And Clark Elementary School',
  'Lewis And Clark Es',
  'Lewis And Clark Hall',
  'Lewis And Clark High School',
  'Lewis And Clark Learning Ctr.',
  'Lewis And Clark Middle',
  'Lewis And Clark Montessori Charter School',
  'Lewis Angapak Memorial School',
  'Lewis Anna Woodbury Elementary School',
  'Lewis C Cassidy Academics Plus Sch',
  'Lewis Carroll Elementary School',
  'Lewis Cass Elementary School',
  'Lewis Cass High School',
  'Lewis Cass Junior High School',
  'Lewis Cass Polytechnic Academy',
  'Lewis Central Middle School',
  'Lewis Central Senior High School',
  'Lewis Chapel Middle',
  'Lewis Clark Career Ctr.',
  'Lewis Clark Elem.',
  'Lewis Co High School',
  'Lewis County Alternative School',
  'Lewis County Central Elementary School',
  'Lewis County Elementary',
  'Lewis County High School',
  'Lewis County Intermediate School',
  'Lewis County Jail',
  'Lewis County Juvenile Detention',
  'Lewis County Middle School',
  'Lewis E Rowe Elementary School',
  'Lewis E. Wadsworth Elementary',
  'Lewis El',
  'Lewis Elem',
  'Lewis Elem School',
  'Lewis Elementary',
  'Lewis Elementary School',
  'Lewis F Mayer Middle School',
  'Lewis F. Cole Middle School',
  'Lewis Frasier Middle School',
  'Lewis H. Britton Middle',
  'Lewis J Bennett High School Of Innovative Technolgy',
  'Lewis Lemon Elementary',
  'Lewis M Myers El Sch',
  'Lewis Maire Elementary School',
  'Lewis Middle',
  'Lewis Opportunity',
  'Lewis River Academy',
  'Lewis S. Mills High School',
  'Lewis School',
  'Lewis University',
  'Lewis Vincent Elementary School',
  'Lewis and Clark Community College',
  'Lewis-Clark State College',
  'Lewis-arriola Elementary School',
  'Lewis-clark Elem School',
  'Lewis-clark Jr High School',
  'Lewis-palmer Elementary School',
  'Lewis-palmer High School',
  'Lewis-palmer Middle School',
  'Lewisburg Elementary',
  'Lewisburg Elementary School',
  'Lewisburg High School',
  'Lewisburg Hs',
  'Lewisburg Middle',
  'Lewisburg Middle School',
  'Lewisburg Primary',
  'Lewisdale Elementary',
  'Lewiston Elementary',
  'Lewiston Elementary School',
  'Lewiston High School',
  'Lewiston Middle School',
  'Lewiston Porter Middle School',
  'Lewiston Porter Senior High School',
  'Lewiston Regional Technology Ctr',
  'Lewiston School',
  'Lewiston-altura Elementary',
  'Lewiston-altura Intermediate El.',
  'Lewiston-altura Secondary',
  'Lewistown 7-8',
  'Lewistown El Sch',
  'Lewistown Elementary',
  'Lewistown Intrmd Sch',
  'Lewistown Jr/sr High School',
  'Lewisville El',
  'Lewisville Elementary',
  'Lewisville H S',
  'Lewisville High',
  'Lewisville Jtc School',
  'Lewisville Middle',
  'Lewton School',
  'Lex La-ray Technical Ctr.',
  'Lexington Academy (the)',
  'Lexington Avenue Primary Center',
  "Lexington Children's Place",
  'Lexington Creek El',
  'Lexington Day Treatment',
  'Lexington El',
  'Lexington Elem School',
  'Lexington Elem.',
  'Lexington Elementary',
  'Lexington Elementary School',
  'Lexington Es',
  'Lexington Four Early Childhood Center',
  'Lexington H S',
  'Lexington High',
  'Lexington High School',
  'Lexington Hs',
  'Lexington Jr High School',
  'Lexington Junior High',
  'Lexington Junior High School',
  'Lexington Middle',
  'Lexington Middle School',
  'Lexington Ms',
  'Lexington Park Elementary',
  'Lexington School',
  'Lexington Senior High',
  'Lexington Technology Center',
  'Lexington Trad Magnet School',
  'Lexington Trails Middle School',
  'Leyendecker El',
  'Leyte Normal University',
  'Leyte State University',
  'Leyton Elementary/junior High School',
  'Leyton High School',
  'Liaocheng Teachers University',
  'Liaoning Normal University',
  'Liaoning Technical University',
  'Liaoning University',
  'Liaquat University of Medical & Health Sciences Jamshoro',
  'Libbey Elementary',
  'Libby C Booth Elementary School',
  'Libby Center',
  'Libby El',
  'Libby Elem School',
  'Libby Elementary',
  'Libby Elementary School',
  'Libby High School',
  'Libby Middle School',
  'Libby-tozier School',
  'Libera Università degli Studi di Scienze Umane e Tecnologiche',
  'Liberal Elem.',
  'Liberal High',
  'Liberal Middle',
  'Liberal South Middle',
  'Liberal Sr High',
  'Liberata Iraldo (nueva)',
  'Liberation Diploma Plus',
  'Libertas Academy Charter School',
  'Libertas College Preparatory Charter',
  'Libertas School',
  'Liberty',
  'Liberty Academy',
  'Liberty Arts Academy',
  'Liberty Arts Magnet K-8',
  'Liberty Avenue Middle School',
  'Liberty Bell El Sch',
  'Liberty Bell Jr Sr High',
  'Liberty Bell Middle School',
  'Liberty Boulevard Elementary',
  'Liberty Center Elementary School',
  'Liberty Center High School',
  'Liberty Center Middle School',
  'Liberty Charter School',
  'Liberty City Elementary School',
  'Liberty Collegiate Academy',
  'Liberty Common Charter School',
  'Liberty Community Center',
  'Liberty Corner School',
  'Liberty County Adult School',
  'Liberty County High School',
  'Liberty Creek Elementary School',
  'Liberty Drive Elementary',
  'Liberty Early Childhood School',
  'Liberty Early Elementary School',
  'Liberty Early Learning Center',
  'Liberty El',
  'Liberty El Sch',
  'Liberty Elem',
  'Liberty Elem School',
  'Liberty Elementary',
  'Liberty Elementary - 02',
  'Liberty Elementary School',
  'Liberty Es',
  'Liberty Grove El',
  'Liberty H S',
  'Liberty High',
  'Liberty High (alternative)',
  'Liberty High (continuation)',
  'Liberty High School',
  'Liberty High School Academy For Newcomers',
  'Liberty Hill El',
  'Liberty Hill H S',
  'Liberty Hill Int',
  'Liberty Hill J H',
  'Liberty Hill Middle',
  'Liberty Hs',
  'Liberty Intermediate School',
  'Liberty J H',
  'Liberty Jr High & Elementary',
  'Liberty Junior High School',
  'Liberty Junior School',
  'Liberty Lake Elementary',
  'Liberty Learning Center',
  'Liberty Magnet School',
  'Liberty Memorial Middle',
  'Liberty Middle',
  'Liberty Middle School',
  'Liberty Middle/high School',
  'Liberty North High School',
  'Liberty Oaks Elem.',
  'Liberty Park Elementary',
  'Liberty Park Elementary School',
  'Liberty Park Middle School',
  'Liberty Pines Academy',
  'Liberty Point Elementary School',
  'Liberty Point International School',
  'Liberty Preparatory Academy',
  'Liberty Preparatory School',
  'Liberty Primary',
  'Liberty Public School',
  'Liberty Ranch High',
  'Liberty Ridge Elementary',
  'Liberty School',
  'Liberty Sr High School',
  'Liberty Sr. High',
  'Liberty Tech Charter Academy',
  'Liberty Technology Magnet High School',
  'Liberty Traditional Charter School',
  'Liberty Traditional Charter School - Saddleback',
  'Liberty Traditional School',
  'Liberty Tree Elementary School',
  'Liberty Union Elementary School',
  'Liberty Union High School',
  'Liberty Union Middle School',
  'Liberty University',
  'Liberty View Elementary',
  'Liberty Virtual Franchise',
  'Liberty Virtual Instruction Program',
  'Liberty Wilderness Crossroads',
  'Liberty-benton Elementary School',
  'Liberty-benton High School',
  'Liberty-benton Middle School',
  'Liberty-curtin El Sch',
  'Liberty-eylau Early Childhood Center',
  'Liberty-eylau El',
  'Liberty-eylau H S',
  'Liberty-eylau Middle',
  'Liberty-eylau Pri',
  'Liberty-valley El Sch',
  'Libertyville Elementary School',
  'Libertyville High School',
  'Librado Net',
  'Libre De Musica Y Bellas Artes Ernesto Ramos Antonini',
  'Libya Open University',
  'Libyan International Medical University (LIMU)',
  'Liceo de Cagayan University',
  'Lichen K-8',
  'Lick (james) Middle',
  'Lick Creek Elem School',
  'Lickdale El Sch',
  'Licking Elem.',
  'Licking Heights Central',
  'Licking Heights High School',
  'Licking Heights North',
  'Licking Heights South',
  'Licking Heights West',
  'Licking High',
  'Licking Valley Elementary School',
  'Licking Valley High School',
  'Licking Valley Middle School',
  'Licton Springs K-8',
  'Lida Hooe El',
  'Lidgerwood Elementary',
  'Lidgerwood Elementary School',
  'Lidgerwood High School',
  'Lidia Fiol Scarano',
  'Lido Elementary School',
  'Lieck El',
  'Lied Middle School',
  'Lieder El',
  'Lien Elementary',
  'Liepaja Pedagogical Higher School',
  'Liestman El',
  'Lietz Elementary',
  'Life Academy',
  'Life Academy High School For Film And Music',
  'Life Entrepreneur Magnet Sch',
  'Life H S Waxahachie',
  'Life Learning Academy Charter',
  'Life Lines Adult Connection',
  'Life Middle Waxahachie',
  'Life Prep',
  'Life School Cedar Hill',
  'Life School Lancaster',
  'Life School Mountain Creek',
  'Life School Oak Cliff',
  'Life School Red Oak',
  'Life Sciences Secondary School',
  'Life Skills',
  'Life Skills Center',
  'Life Skills Center Of Arizona',
  'Life Skills Center Of Colorado Springs',
  'Life Skills Center Of Columbus North',
  'Life Skills Center Of Columbus Southeast',
  'Life Skills Center Of Dayton',
  'Life Skills Center Of Elyria',
  'Life Skills Center Of North Akron',
  'Life Skills Center Of Pontiac',
  'Life Skills Center Of Toledo',
  'Life Skills Ctr Of Cincinnati',
  'Life Skills Ctr Of Youngstown',
  'Life Skills High School Of Cleveland',
  'Life Skills Of Northeast Ohio',
  'Life Source International Charter',
  'Lifebridge Center',
  'Lifehouse',
  'Lifeline Education Charter',
  'Lifelinks Community School',
  'Lifelinks-nebraska',
  'Lifelong Learning Academy',
  'Lifespan',
  'Lifespan Of Mn Youth Transition',
  'Lifetech Academy',
  'Lift For Life Academy',
  'Lift For Life Academy High Sch',
  'Ligarde El',
  'Liggett Street Elementary',
  'Liggett Trail Education Center',
  'Light Farms El',
  'Light Of The World Academy',
  'Lightfoot Elementary',
  'Lighthouse Academies Okc Inc',
  'Lighthouse Academy',
  'Lighthouse Academy Eagle Village',
  'Lighthouse Academy North Campus',
  'Lighthouse Academy St Johns',
  'Lighthouse Academy The Haven',
  'Lighthouse Academy The Pier',
  'Lighthouse Academy Waalkes',
  'Lighthouse Care Center Of Augusta',
  'Lighthouse Charter School',
  'Lighthouse Charter School - B Campus',
  'Lighthouse Community Charter',
  'Lighthouse Community Charter High',
  'Lighthouse Community Day',
  'Lighthouse Community Sch Inc',
  'Lighthouse Education Center',
  'Lighthouse Elementary School',
  'Lighthouse High',
  'Lighthouse Learning Academy',
  'Lighthouse Learning Center',
  'Lighthouse Learning Center - Daep',
  'Lighthouse Learning Center - Jjaep',
  'Ligon Middle',
  'Ligonier Valley Hs',
  'Ligonier Valley Ms',
  'Liguan Elementary School',
  'Lihikai Elementary School',
  'Lila Annex',
  'Lila Maria Mercedes Mayoral',
  'Lilac',
  'Lilbourn Elem.',
  'Lilburn Elementary School',
  'Lilburn Middle School',
  'Lilesville Elementary',
  'Liliam Lujan Hickey Elementary School',
  'Lilienthal (claire) Elementary',
  'Lilja Elementary',
  'Lilla G. Frederick Middle School',
  'Lillehammer University College',
  'Lillian Black Elementary',
  'Lillian C. Poole Elementary School',
  'Lillian Drive School',
  'Lillian El',
  'Lillian Feinstein El. School',
  'Lillian Fletcher Elem School',
  'Lillian G Mason Elementary School',
  'Lillian Larsen Elementary',
  'Lillian M Jacobs',
  'Lillian M. Dunfee Elementary',
  'Lillian M. Steen',
  'Lillian Parks Hussey School',
  'Lillian Peterson School',
  'Lillian Schaper School',
  'Lillian Schmitt Elementary School',
  'Lillian Schumacher Elem.',
  'Lillian Street Elementary',
  'Lillie B Williamson High School',
  'Lillie B. Haynes School',
  'Lillie Burney Steam Academy',
  'Lillie C. Evans K-8 Center',
  'Lillie Idella Kitley Elementary',
  'Lillie J Jackson Early Childhood Center',
  'Lillie May Carroll Jackson School',
  'Lillington-shawtown Elementary',
  'Lillion E Luehrs J H',
  'Lilly & Wing Fong Elementary School',
  'Lily B Clayton El',
  'Lily Lake Elementary',
  'Lily Lake Grade School',
  'Lima 7-8',
  'Lima Elementary School',
  'Lima High School',
  'Lima Hs - Preschool',
  'Lima North Middle School',
  'Lima School',
  'Lima Senior High School',
  'Lima South Science-technology Magnet K-8',
  'Lima West Middle School',
  'Lima-brighton Elementary',
  'Lime Kiln Elementary School',
  'Lime Kiln Middle',
  'Lime Street Elementary',
  'Limerick Avenue Elementary',
  'Limerick El Sch',
  'Limestone Community High School',
  'Limestone Community School',
  'Limestone County Area Vocational Technology',
  'Limestone County Juvenile Detention Center',
  'Limestone County Virtual School Center',
  'Limestone Creek Elementary School',
  'Limestone Es',
  'Limestone Middle Sch',
  'Limestone Walters Elem School',
  'Limestone-central Elementary',
  'Limkokwing University College of Creative Technology',
  'Limon Elementary School',
  'Limon Junior-senior High School',
  'Limona Elementary School',
  'Lin Elementary School',
  'Lin-wood Public School (elem)',
  'Lin-wood Public School (high)',
  'Lin-wood Public School (middle)',
  'Linapuni Elementary School',
  'Lincoln',
  'Lincoln (abraham) Elementary',
  'Lincoln (abraham) High',
  'Lincoln 7-8',
  'Lincoln Academy',
  'Lincoln Academy Regional Interdistrict Collaborative',
  'Lincoln Acres',
  'Lincoln Adult',
  'Lincoln Akerman School',
  'Lincoln Alternative Elementary',
  'Lincoln Alternative High School',
  'Lincoln Annex School',
  'Lincoln Attendance Center',
  'Lincoln Avenue Academy',
  'Lincoln Avenue Elementary',
  'Lincoln Avenue Elementary School',
  'Lincoln Avenue School',
  'Lincoln Building - 44',
  'Lincoln Campus',
  'Lincoln Center',
  'Lincoln Center Elementary',
  'Lincoln Central Elem',
  'Lincoln Central Elem.',
  'Lincoln Charter Academy',
  'Lincoln Charter School',
  'Lincoln City Career Technical High School',
  'Lincoln Cltrl Ctr-montessori Elem',
  'Lincoln Co Alternative',
  'Lincoln Co High School',
  'Lincoln College Prep.',
  'Lincoln Comm High School',
  'Lincoln Community Day',
  'Lincoln Community School',
  'Lincoln Community School #5',
  'Lincoln County Adult Correction',
  'Lincoln County Area Technology Center',
  'Lincoln County Elementary School',
  'Lincoln County High School',
  'Lincoln County Middle School',
  'Lincoln County Ninth Grade Academy',
  'Lincoln Crossing Elementary',
  'Lincoln Cs',
  'Lincoln Early Childhood Center',
  'Lincoln Early Childhood Ed. Center',
  'Lincoln Early Childhood School',
  'Lincoln Early Chld Ctr',
  'Lincoln East High School',
  'Lincoln Education Center',
  'Lincoln El',
  'Lincoln El Sch',
  'Lincoln Elem',
  'Lincoln Elem School',
  'Lincoln Elem.',
  'Lincoln Elementary',
  'Lincoln Elementary - 04',
  'Lincoln Elementary - 05',
  'Lincoln Elementary - 08',
  'Lincoln Elementary - Ivanhoe',
  'Lincoln Elementary Magnet School',
  'Lincoln Elementary Performing Arts',
  'Lincoln Elementary Schoo',
  'Lincoln Elementary School',
  'Lincoln Elementary School For The Arts',
  'Lincoln Es',
  'Lincoln Grade School',
  'Lincoln Hall Middle School',
  'Lincoln Heights Academy',
  'Lincoln Heights Elem School',
  'Lincoln Heights Elementary',
  'Lincoln Heights Elementary Sch',
  'Lincoln Heights Elementary School',
  'Lincoln Heights Middle School',
  'Lincoln High',
  'Lincoln High (continuation)',
  'Lincoln High School',
  'Lincoln High School - 02',
  'Lincoln Hill High School',
  'Lincoln Hills School',
  'Lincoln Hs',
  'Lincoln Humanities/communications Magnet High Sch',
  'Lincoln Intermediate',
  'Lincoln International School',
  'Lincoln International Studies School',
  'Lincoln J H',
  'Lincoln Jackson Arts',
  'Lincoln Jr High School',
  'Lincoln Jr/sr High',
  'Lincoln Jshs',
  'Lincoln Junior High',
  'Lincoln Junior High School',
  'Lincoln K-8 Choice Elementary',
  'Lincoln Land Community College',
  'Lincoln Leadership Academy Charter Schoo',
  'Lincoln Learning Ctr',
  'Lincoln Magnet School',
  'Lincoln Memorial Academy',
  'Lincoln Memorial Elem',
  'Lincoln Middle',
  'Lincoln Middle School',
  'Lincoln Ms',
  'Lincoln New Tech High School',
  'Lincoln Northeast High School',
  'Lincoln Orchard Mesa Elementary School',
  'Lincoln Parish Early Childhood Center',
  'Lincoln Park Academy',
  'Lincoln Park Elementary School',
  'Lincoln Park High School',
  'Lincoln Park Middle School',
  'Lincoln Park Performing Arts Cs',
  'Lincoln Park Preschool',
  'Lincoln Park Sch',
  'Lincoln Pk-8 School',
  'Lincoln Prairie Elem School',
  'Lincoln Prairie School',
  'Lincoln Pre-kindergarten School',
  'Lincoln Preparatory Academy',
  'Lincoln Preparatory School',
  'Lincoln Preschool Center',
  'Lincoln Primary',
  'Lincoln Primary Center',
  'Lincoln Savage Middle School',
  'Lincoln School',
  'Lincoln School Alop',
  'Lincoln School Annex',
  'Lincoln School Of Early Learning',
  'Lincoln School Of Science And Technology',
  'Lincoln School Rssp',
  'Lincoln School Taoep',
  'Lincoln Secondary',
  'Lincoln Senior High',
  'Lincoln Senior High School',
  'Lincoln Southeast High School',
  'Lincoln Sr High School',
  'Lincoln Street',
  'Lincoln Street Elementary School',
  'Lincoln Terrace Elementary',
  'Lincoln Titus Elementary School',
  'Lincoln Trail Behavioral Health Systems',
  'Lincoln Trail Elem School',
  'Lincoln Trail Elementary School',
  'Lincoln Unified Preschool',
  'Lincoln University',
  'Lincoln Upper Elementary School',
  'Lincoln Village Reg Juvenile Det Ctr',
  'Lincoln Way West',
  'Lincoln West School Of Global Studies',
  'Lincoln West School Of Science & Health',
  'Lincoln-bassett School',
  'Lincoln-douglas Elementary School',
  'Lincoln-eliot',
  'Lincoln-erdman Elementary',
  'Lincoln-hancock Community School',
  'Lincoln-hubbard Elementary School',
  'Lincoln-irving Elem School',
  'Lincoln-marti Charter School Hialeah Campus',
  'Lincoln-marti Charter School Little Havana Campus',
  'Lincoln-marti Charter Schools(osceola Campus)',
  'Lincoln-marti Schools International Campus',
  'Lincoln-mckinley School',
  'Lincoln-sudbury Regional High',
  'Lincoln-thomson',
  'Lincoln-way Central High School',
  'Lincoln-way East High School',
  'Lincoln-west High School',
  'Lincoln/roosevelt School',
  'Lincolnking Academy',
  'Lincolnshire Elementary',
  'Lincolnton High',
  'Lincolnton Middle',
  'Lincolnview Elementary School',
  'Lincolnview Jr/sr High School',
  'Lincolnville Central School',
  'Lincolnway El Sch',
  'Lincolnwood Elem School',
  'Lincolnwood High School',
  'Lincolnwood Jr High School',
  'Lincrest Elementary',
  'Lincroft Elementary School',
  'Lind Elementary School',
  'Lind Ritzville Middle School',
  'Lind-ritzville High School',
  'Lind-ritzville Middle School',
  'Linda Abril Educational Academy',
  'Linda Childers Knapp Elementary School',
  'Linda Elementary',
  'Linda Esperanza Marquez High A Hntngtn Park Inst Of Appl Med',
  'Linda Esperanza Marquez High B Libra Academy',
  'Linda Esperanza Marquez High C School Of Social Justice',
  'Linda Herrington El',
  'Linda Jobe Middle',
  'Linda Lentin K-8 Center',
  'Linda Lyon El',
  'Linda Mar Educational Center',
  'Linda Nolen Learning Center',
  'Linda Rankin Givens Elementary School',
  'Linda Tutt H S',
  'Linda Verde Elementary',
  'Linda Vista Elementary',
  'Lindale H S',
  'Lindale Junior High',
  'Lindale Middle',
  'Lindbergh Elem',
  'Lindbergh Elem.',
  'Lindbergh Elementary',
  'Lindbergh Elementary School',
  'Lindbergh Es',
  'Lindbergh Senior High School',
  'Lindbergh Sr. High',
  'Lindbergh Stem Academy',
  'Lindbergh/schweitzer Elementary',
  'Lindblom Math & Science Acad Hs',
  'Lindell Boulevard School',
  'Lindeman Elementary School',
  'Lindemann Elementary School',
  'Linden',
  'Linden Avenue Middle School',
  'Linden Avenue School',
  'Linden Charter Academy',
  'Linden El',
  'Linden El Sch',
  'Linden Elementary',
  'Linden Elementary School',
  'Linden Grove Middle School',
  'Linden High',
  'Linden High School',
  'Linden Hill Elementary School',
  'Linden Hill School',
  'Linden Middle School',
  'Linden Park Elementary School',
  'Linden Park Neighborhood Early Childhood Education Center',
  'Linden Stem Academy (k-6)',
  'Linden Street School',
  'Linden Tree Elementary School',
  'Linden West Elem.',
  'Linden-kildare H S',
  'Linden-mckinley Stem Academy',
  'Lindeneau Elementary School',
  'Lindenhurst Middle School',
  'Lindenhurst Senior High School',
  'Lindenwold High School',
  'Lindenwold Middle School',
  'Lindenwold Preschool Building',
  'Lindenwold School Five',
  'Lindenwold School Four',
  'Lindenwood Elementary',
  'Linder El',
  'Linderman School',
  'Lindero Canyon Middle',
  'Lindgren Early Learning Center',
  'Lindhurst High',
  'Lindley 6th Grade Academy',
  'Lindley Academy Cs At Birney',
  'Lindley Elementary School',
  'Lindley Middle School',
  'Lindley Park Elementary',
  'Lindo Park Elementary',
  'Lindon School',
  'Lindop Elem School',
  'Lindrith Area Heritage',
  'Lindsay Attendance Center',
  'Lindsay Community Day',
  'Lindsay El',
  'Lindsay Es',
  'Lindsay H S',
  'Lindsay Hs',
  'Lindsay Ms',
  'Lindsay School',
  'Lindsay Senior High',
  'Lindsey Academy',
  'Lindsey Elementary School',
  'Lindsey Hopkins Technical College',
  'Lindsey-steiner Elementary',
  'Lindy Ross Elementary School',
  'Line Creek Elem.',
  'Line Elementary',
  'Line Mountain El Sch',
  'Line Mountain Hs',
  'Line Mountain Ms',
  'Lineville Elementary School',
  'Lineville Intermediate',
  'Lineweaver Elementary School',
  'Linfield College',
  'Ling Tung University',
  'Linganore High',
  'Lingelbach Anna L Sch',
  'Lingerfeldt Elementary',
  'Lingle Avenue El Sch',
  'Lingle Middle School',
  'Lingle-ft. Laramie Elementary',
  'Lingle-ft. Laramie High School',
  'Lingle-ft. Laramie Middle School',
  'Linglestown El Sch',
  'Linglestown Ms',
  'Lingleville School',
  'Lingnan University',
  'Linguistic University of Nizhny Novgorod',
  'Link Campus University of Malta',
  'Link Community Charter School',
  'Link Elementary School',
  'Link12 Lakeville',
  'Link2learn Virtual Charter School',
  'Linkhorn Park Elementary',
  'Linkhorne Elementary',
  'Linkhorne Middle',
  'Links',
  'Links Academy',
  'Links Transition',
  'Linköping University',
  'Linn Co Area Career Tech Ctr',
  'Linn Co. Elem.',
  'Linn Co. High',
  'Linn Elem',
  'Linn Elementary',
  'Linn Grove Elementary School',
  'Linn High',
  'Linn State Technical College',
  'Linn-mar High School',
  'Linn-​Benton Community College',
  'Linnaeus W West School',
  'Linne Elem School',
  'Linns Valley-poso Flat Elementary',
  'Linntown El Sch',
  'Lino Lakes Elementary',
  'Lino Padro Rivera',
  'Linscott Charter',
  'Linscott-rumford',
  'Linsday Elementary School',
  'Linthicum Elementary',
  'Linton El',
  'Linton Elementary School',
  'Linton High School',
  'Linton Ms',
  'Linton Springs Elementary',
  'Linton T. Simmons Elementary',
  'Linton University College',
  'Linton-stockton Elementary',
  'Linton-stockton High School',
  'Linton-stockton Jr High School',
  'Linus Pauling Middle School',
  'Linville-edom Elementary',
  'Linwood E. Howe Elementary',
  'Linwood El Sch',
  'Linwood Elementary',
  'Linwood Elementary School',
  'Linwood Es',
  'Linwood Holton Elementary',
  'Linwood Middle School',
  'Linwood Monroe Arts Plus Lower',
  'Linwood Monroe Arts Plus Upper',
  'Linwood Public Charter School',
  'Lions Park Elem School',
  'Lionsgate Academy',
  'Lionsgate Academy - Lynx Program',
  'Lionsgate Academy - Shoreview',
  'Lionsgate Academy Aim',
  'Lionville El Sch',
  'Lionville Ms',
  'Lipan El',
  'Lipan H S',
  'Lipeck State Technical University',
  'Lipman Middle',
  'Lippitt School',
  'Lipscomb Elementary',
  'Lipscomb Elementary School',
  'Lipsey School',
  'Lirio Montessori',
  'Lisa Academy',
  'Lisa Academy Chenal',
  'Lisa Academy High',
  'Lisa Academy North Elementary Charter School',
  'Lisa Academy North High Charter School',
  'Lisa Academy North Middle Charter School',
  'Lisa J. Mails Elementary',
  'Lisa Park Elementary School',
  'Lisbon Ccsd 90',
  'Lisbon Central School',
  'Lisbon Community School',
  'Lisbon Elementary',
  'Lisbon Elementary School',
  'Lisbon Grade School',
  'Lisbon High School',
  'Lisbon Middle School',
  'Lisbon Regional School (elem)',
  'Lisbon Regional School (high)',
  'Lisbon Regional School (middle)',
  'Liscano El',
  'Lisd Tech Center',
  'Lisha Kill Middle School',
  'Lisle High School',
  'Lisle Jr High School',
  'Lismore Colony School',
  'Lismore Colony Senior High School',
  'Lister',
  'Listwood School',
  'Liszt Ferenc Academy of Music Budapest',
  'Lit Cypr-mrceville H S',
  'Litchfield Area Learning Program',
  'Litchfield Early Childhood Special',
  'Litchfield Elementary School',
  'Litchfield High School',
  'Litchfield Hills Transition Center',
  'Litchfield Intermediate School',
  'Litchfield Middle School',
  'Litchfield Prekindergarten',
  'Litchfield Preschool',
  'Litchfield Senior High',
  'Litchfield Senior High School',
  'Litchville-marion Elementary School',
  'Litchville-marion High School',
  'Literacy First Charter',
  'Literacy/leadership/technology Academy',
  'Lithia Springs Comprehensive High School',
  'Lithia Springs Elementary School',
  'Lithonia High School',
  'Lithonia Middle School',
  'Lithuania Christian College',
  'Lithunian Academy of Music and Theatre',
  'Lithunian Institute of Physical Education',
  'Lithunian University of Agriculture',
  'Lithunian Veterinary Academy',
  'Lititz El Sch',
  'Little Axe Es',
  'Little Axe Hs',
  'Little Axe Ms',
  'Little Bay Pri',
  'Little Bennett Elementary',
  'Little Big Horn College',
  'Little Birch Elementary School',
  'Little Black Pearl Arts Acad Hs',
  'Little Blue Elementary',
  'Little Britain Elementary School',
  'Little Canada Elementary',
  'Little Cedars Elementary School',
  'Little Chico Creek Elementary',
  'Little Chute Career Pathways Academy',
  'Little Chute Elementary',
  'Little Chute High',
  'Little Chute Intermediate',
  'Little Chute Middle',
  'Little Creek Elementary',
  'Little Cypress El',
  'Little Cypress Int',
  'Little Cypress J H',
  'Little Eagles Early Learning Center',
  'Little El',
  'Little Elementary School',
  'Little Elm H S',
  'Little Falls Continuing Education',
  'Little Falls High School',
  'Little Falls Middle School',
  'Little Falls Senior High',
  'Little Falls Township Public School # 1',
  'Little Falls Township Public School # 2',
  'Little Falls Township Public School # 3',
  'Little Flower School',
  'Little Fort Elem School',
  'Little Harbour School',
  'Little Hawks Discovery Preschool',
  'Little Heart Elementary School',
  'Little Hocking Elementary School',
  'Little Husky Learning Center',
  'Little Indians Preschool',
  'Little Lake Elementary',
  'Little Learners Preschool',
  'Little Miami High School',
  'Little Miami Intermediate Middle School',
  'Little Miami Junior High School',
  'Little Mill Middle School',
  'Little Milligan',
  'Little Mountain Elementary',
  'Little Mountain Elementary - Snw',
  'Little Oak Middle School',
  'Little Pearl Elementary School',
  'Little Powder Elementary',
  'Little Prairie Primary',
  'Little Prep Academy Elementary',
  'Little Priest Tribal College',
  'Little Quakers Academy',
  'Little Raiders University',
  'Little Rails Early Learning',
  'Little Red Schoolhouse',
  'Little River Elem.',
  'Little River Elementary',
  'Little River High',
  'Little River Junior High',
  'Little Rock Elementary School',
  'Little Rock Prep Academy',
  'Little Run Elementary',
  'Little Sand',
  'Little School',
  'Little Shasta Elementary',
  'Little Singer Community School',
  'Little Snake River Valley School',
  'Little Stars Pre-school',
  'Little Tor Elementary School',
  'Little Turtle Elementary School',
  'Little Valley School',
  'Little Village Elem School',
  'Little Whippets Preschool',
  'Little Wolf Junior/senior High',
  'Little Wound School',
  'Littlebrook School',
  'Littleburg Elementary - 12',
  'Littlefield El',
  'Littlefield H S',
  'Littlefield J H',
  'Littlefield Middle',
  'Littlefield Pri',
  'Littlefork-big Falls Elementary',
  'Littlefork-big Falls Secondary',
  'Littlejohn Elem School',
  'Littlerock Elementary School',
  'Littlerock High',
  'Littlestown Shs',
  'Littleton Academy',
  'Littleton Elementary School',
  'Littleton High School',
  'Littleton Middle School',
  'Littleton Prep Charter School',
  'Littleville Elementary School',
  'Littlewood Elementary School',
  'Litwin',
  'Litzsinger',
  'Livaudais Middle School',
  'Live Oak 1-3 Learning Center',
  'Live Oak Academy',
  'Live Oak Alternative',
  'Live Oak Charter',
  'Live Oak El',
  'Live Oak Elementary',
  'Live Oak Elementary School',
  'Live Oak High',
  'Live Oak High (continuation)',
  'Live Oak High School',
  'Live Oak Junior High',
  'Live Oak Manor Elementary School',
  'Live Oak Middle',
  'Live Oak Middle School',
  'Live Oak Ridge Middle',
  'Live Oaks Cdc',
  'Live Oaks School',
  'Lively El',
  'Lively Technical Center',
  'Livermore Elementary School',
  'Livermore High',
  'Livermore Valley Charter',
  'Livermore Valley Charter Preparatory High',
  'Liverpool Elementary School',
  'Liverpool High School',
  'Liverpool Hope University College',
  'Liverpool John Moores University',
  'Liverpool Middle School',
  'Livingston',
  'Livingston Academy',
  'Livingston Avenue School',
  'Livingston Central High School',
  'Livingston Classical Cyber Academy',
  'Livingston Co Crossroads Academy',
  'Livingston Co. Elem.',
  'Livingston Collegiate Academy',
  'Livingston County Academy',
  'Livingston County Jail',
  'Livingston County Middle School',
  'Livingston Elementary',
  'Livingston Elementary School',
  'Livingston H S',
  'Livingston H S Academy',
  'Livingston High',
  'Livingston High School',
  'Livingston Int',
  'Livingston J H',
  'Livingston Junior High School',
  'Livingston Manor Elementary School',
  'Livingston Manor High School',
  'Livingston Middle',
  'Livingston Middle School',
  'Livingston Parish Literacy & Technology Center',
  'Livingston Park',
  'Livingston Stepping Stones Preschool',
  'Livonia Career Technical Center',
  'Livonia Elementary School',
  'Livonia High School',
  'Livonia Middle School',
  'Livonia Senior High School',
  'Livonia Transition Program',
  'Livsey Elementary School',
  'Liza Jackson Preparatory School',
  'Lizana Elementary School',
  'Lizemore Elementary School',
  'Lizzie Curtis El',
  'Lizzie Graham',
  'Lizzie M Burges Alternative School',
  'Lizzie Nell Cundiff Mcclure El',
  'Lk Crystal Wellcome Memorial El',
  'Lk Crystal-wellcome Memorial Sec.',
  'Lk Nokomis Comm-keewaydin Campus',
  'Lk Nokomis Comm-wenonah Campus',
  'Llano El',
  'Llano Elementary',
  'Llano H S',
  'Llano J H',
  'Llanos Del Sur',
  'Llcarenac Campus',
  'Llcbay Campus',
  'Llewellyn Elementary School',
  'Lloyd & Dolly Bentsen El',
  'Lloyd C. Bird High',
  'Lloyd Diedrichsen Elementary School',
  'Lloyd Elem School',
  'Lloyd Estates Elementary School',
  'Lloyd G. Johnson Junior High',
  'Lloyd Hance Community',
  'Lloyd Harbor School',
  'Lloyd High School',
  'Lloyd M Bentsen El',
  'Lloyd R Ferguson El',
  'Lloyd Road Elementary School',
  'Lloyd-kennedy Charter',
  'Lmp Roe',
  'Lmp Roe Taoep',
  'Lo-inyo Elementary',
  'Loa School',
  'Loachapoka Elementary School',
  'Loachapoka High School',
  'Loaiza Cordero Del Rosario',
  'Loara Elementary',
  'Loara High',
  'Lobelville Elementary',
  'Local Based Special Education Early Childhood Programs',
  'Localbased Speced Programs',
  'Loch Lomond Elementary',
  'Loch Raven High',
  'Loch Raven Technical Academy',
  'Lochbuie Elementary School',
  'Lochburn Middle School',
  'Lock Haven University of Pennsylvania',
  'Lockard Elementary School',
  'Locke A Elem Charter Academy',
  'Locke Alain Sch',
  'Locke Hill El',
  'Locke J Elem School',
  'Locke Middle',
  'Lockeford Elementary',
  'Lockeland Elementary',
  'Lockerman Bundy Elementary',
  'Lockerman Middle School',
  'Lockett Elementary',
  'Lockhart El',
  'Lockhart Elementary',
  'Lockhart Elementary Magnet School',
  'Lockhart Elementary School',
  'Lockhart H S',
  'Lockhart H S Freshman M L Cisneros Campus',
  'Lockhart J H',
  'Lockhart Middle',
  'Lockhart Pride H S',
  'Lockhart School',
  'Lockheed Elementary School',
  'Lockhurst Drive Charter Elementary',
  'Lockland Elementary School',
  'Lockland High School',
  'Lockland Middle School',
  'Locklin Technical Center',
  'Lockmar Elementary School',
  'Lockney Daep',
  'Lockney El',
  'Lockney H S',
  'Lockney J H',
  'Lockport High School',
  'Lockport Lower Elementary School',
  'Lockport Middle School',
  'Lockport Township High Sch East',
  'Lockport Upper Elementary School',
  'Lockwood Avenue Elementary',
  'Lockwood Elem.',
  'Lockwood Elementary',
  'Lockwood Elementary School',
  'Lockwood High',
  'Lockwood Intermediate',
  'Lockwood Middle School',
  'Lockwood Preschool',
  'Lockwood Primary',
  'Lockwood Specl. Educ. Coop.',
  'Loconoma Valley High',
  'Locust Corner Elementary School',
  'Locust Ecc',
  'Locust Elem School',
  'Locust Elementary',
  'Locust Fork Elementary',
  'Locust Fork High School',
  'Locust Grove Early Lrning Ctr',
  'Locust Grove El Sch',
  'Locust Grove Elementary',
  'Locust Grove Elementary School',
  'Locust Grove High',
  'Locust Grove Hs',
  'Locust Grove Middle',
  'Locust Grove Ms',
  'Locust Grove Primary School',
  'Locust Grove Upper Es',
  'Locust Lane Elementary',
  'Locust School',
  'Locust Trace Agriscience Center',
  'Locust Valley Elementary School',
  'Locust Valley High School',
  'Locust Valley Middle School',
  'Lodestar: A Lighthouse Community Charter Public',
  'Lodge Community School',
  'Lodge Grass 7-8',
  'Lodge Grass High School',
  'Lodge Grass School',
  'Lodge Pole Elementary (alternative)',
  'Lodge Pole School',
  'Lodi Elementary',
  'Lodi High',
  'Lodi High School',
  'Lodi Middle',
  'Lodi Ms Hs',
  'Lodi Primary',
  'Lodi-4-kids',
  'Loesche William H Sch',
  'Loess Hills Elementary',
  'Loftis Middle School',
  'Log College Ms',
  'Logan Academy Of Global Ecology',
  'Logan Ave Elem',
  'Logan County Alternative School',
  'Logan County High School',
  'Logan Early Childhood Center',
  'Logan El',
  'Logan El Sch',
  'Logan Elem',
  'Logan Elem School',
  'Logan Elementary',
  'Logan Elementary School',
  'Logan Elm High School',
  'Logan Fontenelle Middle School',
  'Logan High',
  'Logan High School',
  'Logan James Sch',
  'Logan Jr High School',
  'Logan K-8',
  'Logan Middle',
  'Logan Middle School',
  'Logan Senior High School',
  'Logan Township Elementary School',
  'Logan View Elementary',
  'Logan View Jr/sr High School',
  'Logan-hocking Middle School',
  'Logan-magnolia Elementary School',
  'Logan-magnolia Jr-sr High School',
  'Logan-rogersville Elementary',
  'Logan-rogersville High',
  'Logan-rogersville Middle',
  'Logan-rogersville Primary',
  'Logan-rogersville Upper Elem.',
  'Logandale Middle School',
  'Logansport Area Jnt Spec Ed',
  'Logansport Community High School',
  'Logansport High School',
  'Logansport Junior High School',
  'Logansport Juvenile Correctional',
  'Loganville Elementary',
  'Loganville Elementary School',
  'Loganville High School',
  'Loganville Middle School',
  'Loganville-springfield El Sch',
  'Loge Elementary School',
  "Loggers' Run Community Middle School",
  'Logistics Engineering University of PLA',
  'Logos Public Charter School',
  'Lohn School',
  'Lohr Elementary School',
  'Lois & Jerry Tarkanian Middle School',
  'Lois Allen Elementary School',
  'Lois Craig Elementary School',
  'Lois E. Borchardt Elementary',
  'Lois F Giddens El',
  'Lois Hornsby Middle',
  'Lois Lenski Elementary School',
  'Lois Lindsey El',
  'Lois P. Rockwell Elementary',
  'Lois T. Murray Elementary',
  'Lokelani Intermediate School',
  'Loker School',
  'Lola Rodriguez De Tio',
  'Loleta Elementary',
  'Lolo Elementary',
  'Lolo Middle School',
  'Loma Elementary',
  'Loma Elementary School',
  'Loma Heights Elementary',
  'Loma Linda Elementary',
  'Loma Linda Elementary School',
  'Loma Linda University',
  'Loma Park El',
  'Loma Portal Elementary',
  'Loma Prieta Elementary',
  'Loma Rica Elementary',
  'Loma Terrace El',
  'Loma Verde',
  'Loma Verde Elementary',
  'Loma Vista',
  'Loma Vista Charter',
  'Loma Vista Elementary',
  'Loma Vista Environmental Science Academy',
  'Loma Vista Immersion Academy',
  'Loma Vista Middle',
  'Lomarena Elementary',
  'Lomax El',
  'Lomax J H',
  'Lomax Magnet Elementary School',
  'Lombard Jr High School',
  'Lombardi Middle',
  'Lombardy Elementary School',
  'Lomega Es',
  'Lomega Hs',
  'Lometa School',
  'Lomie G Heard Elementary School',
  'Lomie G. Heard Elementary School A Marzano Academy',
  'Lomira Elementary',
  'Lomira High',
  'Lomira Middle',
  'Lomita Math/science/technology Magnet',
  'Lomita Park Elementary',
  'Lomitas Elementary',
  'Lomond Elementary School',
  'Lomond View School',
  'Lompoc High',
  'Lompoc Valley Middle',
  'London Academy',
  'London Business School',
  'London College of Science & Technology',
  'London El',
  'London Elementary',
  'London Elementary School',
  'London Guildhall University',
  'London H S',
  'London High School',
  'London Institute of Management and Technology',
  'London Metropolitan University',
  'London Middle',
  'London Middle School',
  'London School',
  'London School of Business & Finance',
  'London School of Economics and Political Science University of London',
  'London School of Hygiene & Tropical Medicine University of London',
  'London School of Jewish Studies',
  'London Towne Elementary',
  'Londonderry El Sch',
  'Londonderry Middle School',
  'Londonderry Senior High School',
  'Lone Dell Elem.',
  'Lone Grove Hs',
  'Lone Grove Intermediate School',
  'Lone Grove Ms',
  'Lone Grove Primary Es',
  'Lone Hill Middle',
  'Lone Jack Elem.',
  'Lone Jack High',
  'Lone Jack School Center',
  'Lone Mountain Elementary School',
  'Lone Oak El',
  'Lone Oak Elementary',
  'Lone Oak Elementary School',
  'Lone Oak H S',
  'Lone Oak Intermediate School',
  'Lone Oak Middle',
  'Lone Oak Middle School',
  'Lone Peak High',
  'Lone Peak High School',
  'Lone Peak School',
  'Lone Pine Elementary School',
  'Lone Pine High',
  'Lone Rock Middle School',
  'Lone Rock School',
  'Lone Star College System',
  'Lone Star Early Childhood Center',
  'Lone Star El',
  'Lone Star Elementary',
  'Lone Star Elementary School',
  'Lone Star H S',
  'Lone Star H S Central',
  'Lone Star H S East Central',
  'Lone Star H S North',
  'Lone Star H S South',
  'Lone Star H S Southeast',
  'Lone Star H S West',
  'Lone Star High School',
  'Lone Star Language Academy',
  'Lone Star Middle School',
  'Lone Star Public School',
  'Lone Star Undivided High School',
  'Lone Tree Elementary',
  'Lone Tree Elementary School',
  'Lone Tree Junior-senior High School',
  'Lone Wolf Es',
  'Lone Wolf Hs',
  'Lonedell Elem.',
  'Loneman Day School',
  'Lonetree Spec Ed Unit',
  'Long Academy',
  'Long Barn High',
  'Long Beach City College',
  'Long Beach Elem School',
  'Long Beach Elementary School',
  'Long Beach High School',
  'Long Beach Island Grade School',
  'Long Beach Middle School',
  'Long Beach Senior High School',
  'Long Beach Unified School District Rop',
  'Long Branch Elementary',
  'Long Branch Elementary School',
  'Long Branch High School',
  'Long Branch Middle School',
  'Long Cane Elementary School',
  'Long Cane Middle School',
  'Long Cane Primary',
  'Long County High School',
  'Long County Middle School',
  'Long Creek Attendance Center',
  'Long Creek Elementary',
  'Long Creek School',
  'Long Drain School',
  'Long Early Learning Center',
  'Long El',
  'Long Elem.',
  'Long Elementary School',
  'Long Hill Elementary',
  'Long Hill School',
  'Long Island City High School',
  'Long Island Elem',
  'Long Island Elementary School',
  'Long Island University',
  'Long Lake Central School',
  'Long Lake Colony Elementary - 05',
  'Long Lake Elementary School',
  'Long Lots School',
  'Long Meadow Elementary School',
  'Long Middle',
  'Long Middle Community Ed. Ctr.',
  'Long Middle School',
  'Long Mill Elementary',
  'Long Neck Elementary School',
  'Long Pond School',
  'Long Prairie-grey Eagle Elementary',
  'Long Prairie-grey Secondary School',
  'Long Reach High',
  'Long River Middle School',
  'Long Term Case',
  'Long Valley Charter',
  'Long Valley Charter - Susanville',
  'Long Valley Middle School',
  'Longacre Elementary School',
  'Longbranch El',
  'Longbranch Elementary School',
  'Longcoy Elementary School',
  'Longdale Elementary',
  'Longden Elementary',
  'Longest Elementary',
  'Longfellow',
  'Longfellow (henry W.) Elementary',
  'Longfellow Alt Extended',
  'Longfellow Alternative',
  'Longfellow Arts And Technology Middle',
  'Longfellow Choice Elementary',
  'Longfellow El',
  'Longfellow Elem School',
  'Longfellow Elem.',
  'Longfellow Elementary',
  'Longfellow Elementary - 05',
  'Longfellow Elementary School',
  'Longfellow Es',
  'Longfellow K-8',
  'Longfellow Middle',
  'Longfellow Middle School',
  'Longfellow Ms',
  'Longfellow New Tech Elementary Sch',
  'Longfellow School',
  'Longfellow School-portland',
  'Longfields Elementary',
  'Longleaf Elementary',
  'Longleaf Elementary School',
  'Longleaf Middle',
  'Longleaf School Of The Arts',
  'Longley Way Elementary',
  'Longmeadow High',
  'Longmont Estates Elementary School',
  'Longmont High School',
  'Longoria El',
  'Longridge School',
  'Longs Creek El',
  'Longs Peak Middle School',
  'Longstreth William C Sch',
  'Longvalley Elementary - 10',
  'Longview',
  'Longview El Sch',
  'Longview Elementary',
  'Longview Elementary School',
  'Longview Farm Elem.',
  'Longview H S',
  'Longview High School',
  'Longview School',
  'Longview School District Special Services',
  'Longwood Academy For Discovery (the)',
  'Longwood Elem School',
  'Longwood Elementary',
  'Longwood Elementary School',
  'Longwood High School',
  'Longwood Junior High School',
  'Longwood Middle School',
  'Longwood Preparatory Academy',
  'Longwood University',
  'Lonnie Jackson Academy',
  'Lonoke Elementary School',
  'Lonoke High School',
  'Lonoke Middle School',
  'Lonoke Primary School',
  'Lonsdale Elementary',
  'Lonsdale Pre K-4',
  'Loogootee Elementary School',
  'Loogootee High School',
  'Loogootee Middle School',
  'Lookeba-sickles Es',
  'Lookeba-sickles Hs',
  'Lookingglass Elementary School',
  'Lookout Mountain',
  'Lookout Mountain Elementary',
  'Lookout Mountain School',
  'Lookout Valley Elementary',
  'Lookout Valley Middle / High School',
  'Loomis Basin Charter',
  'Loomis El Sch',
  'Loomis Elementary',
  'Loomis Elementary School',
  'Loomis Secondary School',
  'Loon Lake Elementary School',
  'Loon Lake Homelink Program',
  'Loop School',
  'Looscan El',
  'Loowit High School',
  'Lopatcong Elementary School',
  'Lopatcong Township Middle School',
  'Lopez Continuation High',
  'Lopez Early College H S',
  'Lopez Elementary',
  'Lopez Elementary School',
  'Lopez Exceptional Student Education Center',
  'Lopez Middle',
  'Lopez Middle High School',
  'Lopez School',
  'Lopez-riggins El',
  'Lora L Batchelor Middle School',
  'Lorah Park Elementary School',
  'Lorain County Community College',
  'Lorain High School',
  'Lorain K-12 Digital Academy',
  'Lorain Preparatory Academy',
  'Loraine School',
  'Loraine T Golbow El',
  'Lorane El Sch',
  'Loranger Elementary School',
  'Loranger High School',
  'Loranger Memorial School',
  'Loranger Middle School',
  'Loras College',
  'Lorbeer Middle',
  'Lord Baden-powell',
  'Lord Baltimore Elementary School',
  'Lord Beaconsfield Landry-oliver Perry Walker High',
  'Lord Botetourt High',
  'Lord Fairfax Community College',
  'Lord Stlrling Elementary School',
  'Lords Park Elem School',
  'Lordsburg High',
  'Lordship School',
  'Lordstown Elementary School',
  'Lordstown High School',
  'Loreauville Elementary School',
  'Loreauville High School',
  'Loren Eyler Elementary School',
  'Loren Miller Elementary',
  'Lorena El',
  'Lorena Falasco Elementary',
  'Lorena High',
  'Lorena Middle',
  'Lorena Pri',
  'Lorena Street Elementary',
  'Lorencita Ramirez De Arellano',
  'Lorene Rogers Middle',
  'Lorene Smith Kirkpatrick El',
  'Lorenz C List School',
  'Lorenzo Coballes Gandia',
  'Lorenzo De Zavala El',
  'Lorenzo De Zavala Environmental Science Academy',
  'Lorenzo De Zavala Middle',
  'Lorenzo El',
  'Lorenzo H S',
  'Lorenzo Loya Pri',
  'Lorenzo Manor Elementary',
  'Lorenzo R Smith Elem School',
  'Lorenzo Vizcarrondo',
  'Lorenzo Walker Technical College',
  'Lorenzo Walker Technical High School',
  'Lorestan University',
  'Lorestan University of Medical Sciences',
  'Loreto Street Elementary',
  'Loretta Lampton Elementary',
  'Loretta Park Elementary School',
  'Loretto Elementary School',
  'Loretto High School',
  'Lorin A. Eden Elementary',
  'Lorin Griset Academy',
  'Loring Colony School',
  'Loring Elementary',
  'Loring Nicollet Extended',
  'Loring-flemming Elementary School',
  'Loring-nicollet High',
  'Loris Elementary',
  'Loris High',
  'Loris Middle',
  'Lorna Byrne Middle School',
  'Lorna J Kesterson Elementary School',
  'Lorne Street Elementary',
  'Lorraine Elementary School',
  'Lorrie Yeager Juvenile Center',
  'Lorton Station Elementary',
  'Los Alamitos Elementary',
  'Los Alamitos High',
  'Los Alamitos Middle',
  'Los Alamos High',
  'Los Alamos Middle',
  'Los Alisos Intermediate',
  'Los Alisos Middle',
  'Los Altos Elementary',
  'Los Altos High',
  'Los Amigos',
  'Los Amigos Elementary',
  'Los Amigos Elementary School',
  'Los Amigos High',
  'Los Angeles Academy Middle',
  'Los Angeles Academy Of Arts & Enterprise Charter',
  'Los Angeles Center For Enriched Studies',
  'Los Angeles City College',
  'Los Angeles Community College District',
  'Los Angeles County College of Nursing and Allied Health',
  'Los Angeles County Online High',
  'Los Angeles County Rop',
  'Los Angeles County Special Education',
  'Los Angeles Elementary',
  'Los Angeles Harbor College',
  'Los Angeles High School Of The Arts',
  'Los Angeles International Charter High',
  'Los Angeles Leadership Academy',
  'Los Angeles Leadership Primary Academy',
  'Los Angeles Mission College',
  'Los Angeles Pierce College',
  'Los Angeles River At Sonia Sotomayor Learning Academies',
  'Los Angeles Senior High',
  'Los Angeles Southwest College',
  'Los Angeles Trade Technical College',
  'Los Angeles Unified School District Rocp',
  'Los Angeles Valley College',
  'Los Arboles Literacy And Technology Academy',
  'Los Arboles Middle',
  'Los Banos Elementary',
  'Los Banos High',
  'Los Banos Junior High',
  'Los Banos Special Education Preschool',
  'Los Berros Visual And Performing Arts Academy',
  'Los Cerritos',
  'Los Cerritos Elementary',
  'Los Cerritos Middle',
  'Los Cerros Middle',
  'Los Coches Creek Middle',
  'Los Coyotes Elementary',
  'Los Coyotes Middle',
  'Los Cuates Middle',
  'Los Encinos Ses',
  'Los Feliz Charter School For The Arts',
  'Los Feliz Science/tech/engineer/math/medicine Magnet',
  'Los Fresnos El',
  'Los Fresnos H S',
  'Los Fresnos United',
  'Los Gatos High',
  'Los Lunas Elementary',
  'Los Lunas Family School',
  'Los Lunas High',
  'Los Lunas Middle',
  'Los Medanos College',
  'Los Medanos Elementary',
  'Los Molinos Community Day',
  'Los Molinos Elementary',
  'Los Molinos High',
  'Los Nietos Middle',
  'Los Ninos',
  'Los Ninos Elementary',
  'Los Ninos Elementary School',
  'Los Obispos Middle',
  'Los Olivos Elementary',
  'Los Osos High',
  'Los Osos Middle',
  'Los Padillas Elementary',
  'Los Padres Elementary',
  'Los Padrinos Juvenile Hall',
  'Los Paseos Elementary',
  'Los Penasquitos Elementary',
  'Los Perales Elementary',
  'Los Primeros School Of Sciences & Arts',
  'Los Puentes Charter',
  'Los Ranchos Elementary',
  'Los Reyes El',
  'Los Robles Academy',
  'Los Robles Elementary',
  'Los Robles Magnet Academy',
  'Los Tules Middle',
  'Losoya Int',
  'Loss (olive B.) Elementary School',
  'Lost Creek Elementary Sch',
  'Lost Creek Elementary School',
  'Lost Hills Elementary',
  'Lost Lake Elementary School',
  'Lost Mountain Middle School',
  'Lost Pines El',
  'Lost River Career Cooperative',
  'Lost River Elementary',
  'Lost River High School',
  'Lostant Elem School',
  'Lothian Elementary',
  'Lothrop Magnet Center',
  'Lothrop School',
  'Lotspeich El',
  'Lott Detention Center',
  'Lott El',
  'Lott Juvenile Detention Center',
  'Lott Middle School',
  'Lottie M Schmidt Elem School',
  'Lotus School',
  'Lotus School For Excellence',
  'Lou Henry Elementary School',
  'Lou Henry Hoover Elementary',
  'Lou Mendive Middle School',
  'Loudenslager Elementary School',
  'Loudon Elementary',
  'Loudon Elementary School',
  'Loudon High School',
  'Loudonville High School',
  'Loudonville Junior High School',
  'Loudonville School',
  'Loudoun Academy Of Science',
  'Loudoun Co. Juvenile Detention Home',
  'Loudoun County High',
  'Loudoun Valley High',
  'Loughborough University',
  'Loughman Oaks Elementary School',
  'Louis A. Bohn Elementary',
  'Louis Agassiz School',
  'Louis B Russell Jr School 48',
  'Louis E Dieruff Hs',
  'Louis E. Stocklmeir Elementary',
  'Louis F Angelo Elementary',
  'Louis G Lobit El',
  'Louis G. Zeyen Elementary',
  'Louis J. Villalovoz Elementary',
  "Louis L'amour Elementary School",
  'Louis Lake Intermediate',
  'Louis M Klein Middle School',
  'Louis Miller Terrebonne Career And Technical Hs',
  'Louis Pasteur Fundamental Middle',
  'Louis S. Sheffield Elementary School',
  'Louis Toffolon School',
  'Louis V Denti Elementary School',
  'Louis Vandermolen Fundamental Elementary',
  'Louis Wiener Jr Elementary School',
  'Louisa Boren Stem K-8',
  'Louisa County High',
  'Louisa County Middle',
  'Louisa East Elementary School',
  'Louisa May Alcott Elementary School',
  'Louisa Middle School',
  'Louisa Perritt Primary',
  'Louisa West Elementary School',
  'Louisa-muscatine Elementary',
  'Louisa-muscatine High School',
  'Louisa-muscatine Jr High',
  'Louisburg College',
  'Louisburg Elementary',
  'Louisburg High',
  'Louisburg Middle',
  'Louise A Conley',
  'Louise A Spencer / Miller Street School',
  'Louise A. Benton Middle',
  'Louise Cabaniss El',
  'Louise Davy Trahan',
  'Louise Durham Elem. School',
  'Louise El',
  'Louise Foussat Elementary',
  'Louise H S',
  'Louise J H',
  'Louise M Smith Development Center',
  'Louise Peacock School',
  'Louise R. Johnson Middle School',
  'Louise S. Mcinnis Elementary School',
  'Louise Sandrini Elementary',
  'Louise Troy Prek-6 School',
  'Louise Van Meter Elementary',
  'Louise White Elem School',
  'Louise Wolff Kahn El',
  'Louisiana College',
  'Louisiana Correctional Institute For Women',
  'Louisiana Delta Community College',
  'Louisiana Elem.',
  'Louisiana High',
  'Louisiana Key Academy',
  'Louisiana Middle',
  'Louisiana Schnell Elementary',
  'Louisiana School For Math Science & The Arts',
  'Louisiana School For The Deaf',
  'Louisiana School For The Visually Impaired',
  'Louisiana Special Education Center',
  'Louisiana State Penitentiary',
  'Louisiana State University',
  'Louisiana State University at Alexandria',
  'Louisiana State University-​Eunice',
  'Louisiana Tech University',
  'Louisiana Virtual Charter Academy',
  'Louisville Academy',
  'Louisville Day',
  'Louisville Elementary School',
  'Louisville High School',
  'Louisville Male High',
  'Louisville Metro Youth Center',
  'Louisville Middle School',
  'Lounsberry Hollow School',
  'Loup City Elementary School',
  'Loup City High School',
  'Loup County Elementary School',
  'Loup County High School',
  'Lourdes School',
  'Love Chapel Elementary',
  'Love Creek Elementary School',
  'Love El',
  'Love Grove Elementary School',
  'Love Memorial Elementary',
  'Lovejoy Daep',
  'Lovejoy Discovery School #43',
  'Lovejoy Elem School',
  'Lovejoy Elementary School',
  'Lovejoy H S',
  'Lovejoy High School',
  'Lovejoy Middle School',
  'Lovejoy Technology Academy',
  'Lovelady El',
  'Lovelady J H H S',
  'Loveland Classical School',
  'Loveland Early Childhood Center',
  'Loveland Elementary School',
  'Loveland High School',
  'Loveland Intermediate School',
  'Loveland Middle School',
  'Loveland Primary School',
  'Loveless Academic Magnet Program High School',
  'Lovelia P. Flournoy Elementary',
  'Lovell Elementary',
  'Lovell High',
  'Lovell High School',
  'Lovell Middle School',
  'Lovelock Elementary School',
  'Loves Park Elem School',
  'Lovett El',
  'Lovett Elem School',
  'Lovettsville Elementary',
  'Loveworks Academy For Arts',
  'Lovin Elementary School',
  'Loving Elementary',
  'Loving High',
  'Loving Middle',
  'Lovinggood Middle School',
  'Lovington 6th Grade Academy',
  'Lovington Freshman Academy',
  'Lovington Grade School',
  'Lovington High',
  'Lovonya Dejean Middle',
  'Low Elementary School',
  'Lowcountry Leadership Charter School',
  'Lowcountry Montessori School',
  'Lowe Elementary',
  "Lowe's Grove Middle",
  'Lowell',
  'Lowell Community Charter Public School',
  'Lowell Day School On Broadway',
  'Lowell Elem',
  'Lowell Elem School',
  'Lowell Elementary',
  'Lowell Elementary - 28',
  'Lowell Elementary - Everett',
  'Lowell Elementary School',
  'Lowell H Smith Elementary School',
  'Lowell High',
  'Lowell International Elementary',
  'Lowell James R Sch',
  'Lowell Junior/senior High School',
  'Lowell Middle',
  'Lowell Middle School',
  'Lowell Middlesex Academy Charter School',
  'Lowell School',
  'Lowell Scott Middle School',
  'Lowell Senior High School',
  'Lowell Smith Jr Middle',
  'Lowell Village School',
  'Lowell-longfellow Elem School',
  'Lowellville Elementary School',
  'Lowellville High School',
  'Lower Allen El Sch',
  'Lower Alloways Creek Elementary School',
  'Lower Brule Day School',
  'Lower Cape May Regional High School',
  'Lower Columbia College',
  'Lower Creek Elementary',
  'Lower Dauphin Hs',
  'Lower Dauphin Ms',
  'Lower East Side Preparatory High School',
  'Lower Elementary',
  'Lower Grade Hellgate',
  'Lower Gwynedd El Sch',
  'Lower Lake Elementary',
  'Lower Lake High',
  'Lower Lee Elementary',
  'Lower Macungie Ms',
  'Lower Manhattan Arts Academy',
  'Lower Manhattan Community Middle School',
  'Lower Merion Hs',
  'Lower Moreland Hs',
  'Lower Nazareth El Sch',
  'Lower Pottsgrove El Sch',
  'Lower Richland High',
  'Lowery El',
  'Lowery Elementary School',
  'Lowery Freshman Center',
  'Lowery Middle School',
  'Lowery Road',
  'Lowes Elementary School',
  'Lowes Island Elementary',
  'Lowman Elementary School',
  'Lowman Hill Elem',
  'Lowndes Co Voc Complex',
  'Lowndes County Alternative School',
  'Lowndes County Middle School',
  'Lowndes High School',
  'Lowndes Middle School',
  'Lowpoint-washburn Elem School',
  'Lowpoint-washburn Jr Sr High Sch',
  'Lowrance Elementary/middle',
  'Lowrance Middle',
  'Lowrey Elementary School',
  'Lowrey Middle School',
  'Lowrey Public School',
  'Lowrie Elem School',
  'Lowrie Primary School',
  'Lowry Elementary School',
  'Lowville Elementary School',
  'Lowville High School',
  'Lowville Middle School',
  'Loxahatchee Groves Elementary',
  'Loxley Elementary School',
  'Loy Norrix High School',
  'Loy School',
  'Loyal Barker Elementary',
  'Loyal Elementary',
  'Loyal Heights Elementary School',
  'Loyal High',
  'Loyal Junior High',
  'Loyalsock Twp Ms',
  'Loyalsock Twp Shs',
  'Loyalsock Valley El Sch',
  'Loyalton Elementary',
  'Loyalton High',
  'Loyd E Auman Elementary',
  'Loyd Star School',
  'Loyola College',
  'Loyola Elementary',
  'Loyola Marymount University',
  'Loyola University Chicago',
  'Loyola Village Fine And Performing Arts Magnet',
  'Loysville Youth Dev Ctr',
  'Lozano Elem Bilingual & Intl Ctr',
  'Lposd Home School Academy',
  'Lps Career Academy',
  'Lps Oakland R & D Campus',
  'Lrng In An Urban Comm With High Achievement (l.u.c.h.a.)',
  'Ls Mnic',
  'Lsh Middle School Alternative Prog',
  'Lsu Laboratory School',
  'Lt Clayre Sullivan Elementary',
  'Lt Col Horrace Meek Hickam Elementary School',
  'Lt Elezer Davis',
  'Lt Elmer J Mcmahon Elementary',
  'Lt Job Lane School',
  'Lt Peter M Hansen',
  'Lt Taylor Intermediate School',
  'Ltg Richard J. Seitz Elementary',
  'Lu Sutton Elementary',
  'Lubbock Co J J A E P',
  'Lubbock Co Juvenile Justice Ctr',
  'Lubbock Co Youth Ctr',
  'Lubbock H S',
  'Lubbock-cooper Bush Middle',
  'Lubbock-cooper Central El',
  'Lubbock-cooper East El',
  'Lubbock-cooper High School',
  'Lubbock-cooper Middle',
  'Lubbock-cooper New Hope Academy',
  'Lubbock-cooper North El',
  'Lubbock-cooper South El',
  'Lubbock-cooper West El',
  'Lubec Consolidated School',
  'Lubeck Elementary School',
  'Lucama Elementary',
  'Lucas Crossing Elem. Complex',
  'Lucas Elementary',
  'Lucas Elementary School',
  'Lucas Heritage Middle School',
  'Lucas High School',
  'Lucas Middle',
  'Lucas Pk',
  'Lucas/sylvan Elementary Unified',
  'Luce Elementary School',
  'Luce Road Elementary School',
  'Lucerne Elementary',
  'Lucerne Valley Community Day',
  'Lucerne Valley Elementary',
  'Lucerne Valley High',
  'Lucerne Valley Middle',
  'Lucero Elementary School',
  'Lucia Cubero',
  'Lucia Wallace Elementary School',
  'Lucie E. Campbell Elementary',
  'Lucile Bruner Elementary School',
  'Lucile Erwin Middle School',
  'Lucile Hendricks El',
  'Lucile S Patton Elem School',
  'Lucile Souders Elementary',
  'Lucille Cherbonnier/norbert Rillieux Elem. School',
  'Lucille J. Smith Elementary',
  'Lucille M. Brown Middle',
  'Lucille M. Nixon Elementary',
  'Lucille Moore Elementary School',
  'Lucille Nash El',
  'Lucille Nesom Memorial',
  'Lucille Roybal-allard Elementary',
  'Lucille S Rogers Elementary School',
  'Lucille Umbarger Elementary',
  'Lucille Whitehead Intermediate',
  'Lucio Middle',
  'Lucious And Emma Nixon Academy Charter',
  'Lucious And Emma Nixon Academy Of Technology And Science',
  'Luck Elementary',
  'Luck High',
  'Lucketts Elementary',
  'Luckey Ranch El',
  'Luckiamute Valley Charter School',
  'Lucy Elementary',
  'Lucy Franklin Elem.',
  'Lucy Grillasca',
  'Lucy Laney @ Cleveland Park Elem.',
  'Lucy Mae Mcdonald El',
  'Lucy N. Holman Elementary School',
  'Lucy Ragsdale High',
  'Lucy Rede Franco Middle',
  'Lucy T. Davis Elementary',
  'Lucy V. Barnsley Elementary',
  'Lucy Wortham James Elem.',
  'Lucyle Collins Middle School',
  'Ludington High School',
  'Ludington Magnet Middle School',
  'Ludlam Elementary School',
  'Ludlow Elementary - 06',
  'Ludlow Elementary School',
  'Ludlow High School',
  'Ludlow James R Sch',
  'Ludlow Preschool',
  'Ludlow Senior High',
  'Ludlow Taylor Es',
  'Ludong University',
  'Ludovico Costoso',
  'Ludwig Elem School',
  'Ludwig-Maximilians-Universität München',
  'Ludwik Solski State Academy of Theatre in Cracow',
  'Lueders-avoca El/j H',
  'Lueders-avoca H S',
  'Luella Elementary School',
  'Luella High School',
  'Luella Merrett El',
  'Luella Middle School',
  'Lufkin H S',
  'Lufkin Middle',
  'Lufkin Road Middle',
  'Lugo Elementary',
  'Lugoff Elementary',
  'Lugoff-elgin High',
  'Lugoff-elgin Middle',
  'Lugonia Elementary',
  'Luhansk State Medical University',
  'Luhansk Taras Shevchenko National Pedagogical University',
  'Luhr Elementary',
  'Luigi Aprea Elementary',
  'Luis A. Ferre Aguayo',
  'Luis A. Ramos El Sch',
  'Luis A. Rivera',
  'Luis Colon (adolfo Babilonia)',
  'Luis E. Armijo Elementary',
  'Luis F. Crespo (sup. Nueva)',
  'Luis Felipe Rodriguez Garcia',
  'Luis Hernaiz Veronne',
  'Luis Llorens Torres',
  'Luis M. Santiago',
  'Luis Melendez Rodriguez',
  'Luis Mu?os Marin Elementary School',
  'Luis Muniz Souffront',
  'Luis Munoz Grillo',
  'Luis Munoz Marin',
  'Luis Munoz Marin School',
  'Luis Munoz Rivera',
  'Luis Munoz Rivera 1',
  'Luis Munoz Rivera 2',
  'Luis Munoz Rivera Ii',
  'Luis Munoz-rivera Ms',
  'Luis Negron Lopez',
  'Luis P. Untalan Middle School',
  'Luis Pales Matos',
  'Luis Ramos Gonzalez',
  'Luis Rodriguez Cabrero',
  'Luis Santaella',
  'Luis Santaliz Capestany',
  'Luis T Balinas',
  'Luis Valdez Leadership Academy',
  'Luisa M. Valderrama (san Antonio)',
  'Luisa Pineiro Fuentes School Of Science And Discovery',
  'Luiseno',
  'Lujan-chavez El',
  'Lukachukai Community School',
  'Lukas Elementary School',
  'Luke C Moore High School',
  'Luke Elementary School',
  'Lukeville Upper Elementary',
  'Lukfata Public School',
  'Lula Belle Goodman El',
  'Lula Elementary School',
  'Lula J. Edge Elementary School',
  'Lulea University of Technology',
  'Luling Elementary School',
  'Luling H S',
  'Luling Pri',
  'Lulu Walker School',
  'Lumberg Elementary School',
  'Lumberport Elementary School',
  'Lumberton Campus',
  'Lumberton Early Childhood Sch',
  'Lumberton Elementary School',
  'Lumberton H S',
  'Lumberton High School',
  'Lumberton Int',
  'Lumberton Junior High',
  'Lumberton Middle',
  'Lumberton Middle School',
  'Lumberton Pri',
  'Lumberton Senior High',
  'Lumbini Buddhist University',
  'Lumen Charter High',
  'Lumen Scholar Institute',
  'Lumin Lindsley Park Community School',
  'Lummi Elementary School',
  'Lummi High School',
  'Lumpkin County Elementary School',
  'Lumpkin County High School',
  'Lumpkin County Middle School',
  'Luna Community College',
  'Luna El',
  'Luna Middle',
  'Lunada Bay Elementary',
  'Lund Elementary School',
  'Lund High School',
  'Lund University',
  'Lundahl Middle School',
  'Lundy Elementary School',
  'Lunenburg High',
  'Lunenburg Middle',
  'Lunenburg Middle School',
  'Lunenburg Primary School',
  'Lunenburg Schools',
  'LungHwa University of Science and Technology',
  'Lupane State University',
  'Lupelele Elementary School',
  'Lupin Hill Elementary',
  'Lupine Hills Elementary',
  'Lupton Junior High School',
  'Lura A. White Elementary School',
  'Lura Kinsey Elementary School',
  'Luray Elementary',
  'Luray High',
  'Luray Middle',
  'Lurgan El Sch',
  'Lurleen B Wallace Community College',
  'Lusher Charter School',
  'Lusher Elem.',
  'Lusk Elementary',
  'Lusk Middle School',
  'Lustre School',
  'Lutacaga Elementary',
  'Lutcher Elementary School',
  'Lutcher High School',
  'Lutgarda Rivera Reyes (florida)',
  'Luther Branson School',
  'Luther Burbank Elem School',
  'Luther Burbank Elementary',
  'Luther Burbank High',
  'Luther Burbank Middle',
  'Luther Burbank Middle School',
  'Luther C Klager Elem School',
  'Luther College',
  'Luther Conant School',
  'Luther E Ball (chjcf)',
  'Luther Elementary',
  'Luther Es',
  'Luther Hs',
  'Luther J Schilling School',
  'Luther L Wright K12 School',
  'Luther L. Vaughan Elementary',
  'Luther Lee Emerson School',
  'Luther Luckett Education Center',
  'Luther Ms',
  'Luther Nick Jeralds Middle',
  'Luther P. Jackson Middle',
  'Luther School',
  'Luther Seminary',
  'Luther W Low El Sch',
  'Luther W. Machen Elementary',
  'Lutheran School Of Theology In Aarhus',
  'Lutherische Theologische Hochschule Oberursel',
  'Luthersburg El Sch',
  'Lutherville Laboratory',
  'Lutie Elem.',
  'Lutie High',
  'Luttrell Elementary',
  'Lutz Elementary School',
  'Lutz Preparatory School',
  'Lutz School For Work Experience',
  'Luverne Alternative Program',
  'Luverne Elementary',
  'Luverne Elementary School',
  'Luverne High School',
  'Luverne Middle',
  'Luverne Senior High',
  'Lux Middle School',
  'Luxemburg-casco High',
  'Luxemburg-casco Intermediate',
  'Luxemburg-casco Middle',
  'Luxemburg-casco Primary',
  'Luxford Elementary',
  'Luxmanor Elementary',
  'Luxun Academy of Fine Art',
  'Luz A Cruz De Santana',
  'Luz America Calderon',
  'Luz Eneida Colon (la Cumbre)',
  'Luzerne County Community College',
  'Luzhou Medical College',
  'Lvcs Early Childhood',
  'Lviv National Medical Univeristy',
  'Lviv National University Ivan Franko',
  'Lviv Polytechnic National University',
  'Lyal Burkholder Junior High School',
  'Lybrook Elementary',
  'Lybrook Elementary School',
  'Lycee Francais De La Nouvelle-orleans',
  'Lyceum of the Philippines University',
  'Lycoming College',
  'Lycoming Ctc',
  'Lycoming Valley Intrmd Sch',
  'Lydia Hawk Elementary',
  'Lydia Jackson Elementary',
  'Lydia M Lopez (pena Pobre Parcelas)',
  'Lydia Melendez',
  'Lydia Middleton Elementary School',
  'Lydia Rippey Elementary',
  'Lydia Romero-cruz Elementary',
  'Lydiksen Elementary',
  'Lyeffion Junior High School',
  'Lyerly Elementary School',
  'Lyford El',
  'Lyford H S',
  'Lyford Middle',
  'Lylburn Downing Middle',
  'Lyle A Torrant Center',
  'Lyle Creek Elementary',
  'Lyle Elementary',
  'Lyle Elementary School',
  'Lyle High School',
  'Lyle Middle School',
  'Lyle S. Briggs Fundamental',
  'Lyle School',
  'Lyle Secondary',
  'Lyles Middle',
  'Lyles-crouch Elementary',
  'Lyman B. Goff Middle School',
  'Lyman C. Hunt Middle School',
  'Lyman Elementary',
  'Lyman Elementary School',
  'Lyman Gilmore Middle',
  'Lyman Hall Elementary School',
  'Lyman Hall High School',
  'Lyman High School',
  'Lyman High School - 01',
  'Lyman Intermediate School',
  'Lyman Memorial High School',
  'Lyman Middle School - 02',
  'Lyman Moore Middle School',
  'Lyme Central School',
  'Lyme Consolidated School',
  'Lyme Elementary School',
  'Lyme-old Lyme High School',
  'Lyme-old Lyme Middle School',
  'Lyn Knoll Elementary School',
  'Lynbrook Elementary',
  'Lynbrook High',
  'Lynbrook North Middle School',
  'Lynbrook Senior High School',
  'Lynbrook South Middle School',
  'Lynch Elementary',
  'Lynch Elementary School',
  'Lynch Secondary School',
  'Lynchburg City Secondary Alternative',
  'Lynchburg College',
  'Lynchburg Elementary',
  'Lynchburg Juvenile Detention Home',
  "Lynchburg Regional Governor's Stem Academy",
  'Lynchburg-clay Elementary School',
  'Lynchburg-clay High School',
  'Lynchburg-clay Middle School',
  'Lyncourt School',
  'Lyncrest Elementary School',
  'Lynd Elementary',
  'Lynd Middle School',
  'Lyndale Elementary',
  'Lyndeborough Central School',
  'Lynden Academy',
  'Lynden High School',
  'Lynden Middle School',
  'Lynden Special Services',
  'Lyndhurst Elementary',
  'Lyndhurst High School',
  'Lyndon',
  'Lyndon B Johnson',
  'Lyndon B Johnson Daep',
  'Lyndon B Johnson El',
  'Lyndon B Johnson H S',
  'Lyndon B Johnson Middle',
  'Lyndon B. Johnson Elementary',
  'Lyndon B. Johnson Middle School',
  'Lyndon Elem',
  'Lyndon H Strough Middle School',
  'Lyndon High',
  'Lyndon State College',
  'Lyndon Station Elementary',
  'Lyndon Town School',
  'Lyndonville Elementary School',
  'Lynhaven Elementary',
  'Lynhurst 7th & 8th Grade Center',
  'Lynn Camp Schools',
  'Lynn Crest Elementary School',
  'Lynn Elementary School',
  'Lynn English High',
  'Lynn Fanning Elementary School',
  'Lynn G Haskin Elementary School',
  'Lynn Haven Elementary School',
  'Lynn High School',
  'Lynn Lucas Middle',
  'Lynn Middle',
  'Lynn Road Elementary',
  'Lynn University',
  'Lynn Urquides',
  'Lynn Vocational Technical Institute',
  'Lynn Wood Es',
  'Lynn Woods',
  'Lynndale Elementary',
  'Lynne Thigpen Elem School',
  'Lynnewood El Sch',
  'Lynnfield High',
  'Lynnfield Middle School',
  'Lynnfield Preschool',
  'Lynnhaven Elementary',
  'Lynnhaven Middle',
  'Lynnhurst',
  'Lynnville Elementary School',
  'Lynnville-sully Elementary School',
  'Lynnville-sully High School',
  'Lynnville-sully Middle School',
  'Lynnwood Elementary',
  'Lynnwood Elementary School',
  'Lynnwood High School',
  'Lynwood Alternative',
  'Lynwood Avenue School',
  'Lynwood Elementary',
  'Lynwood High',
  'Lynwood Middle',
  'Lyon',
  'Lyon At Blow Elem.',
  'Lyon College',
  'Lyon County Adult Education',
  'Lyon County Elementary School',
  'Lyon County High School',
  'Lyon County Middle School',
  'Lyon Elem School',
  'Lyon Elementary',
  'Lyon Elementary School',
  'Lyon K-8',
  'Lyon Magnet Elementary School',
  'Lyon Upper 9-12',
  'Lyons Center',
  'Lyons Central Elementary',
  'Lyons Community School',
  'Lyons Creek Middle School',
  'Lyons El',
  'Lyons Elementary School',
  'Lyons High School',
  'Lyons Middle School',
  'Lyons Middle/senior High School',
  'Lyons Mill Elementary',
  'Lyons Park Elementary',
  'Lyons Primary School',
  'Lyons School',
  'Lyons Senior High School',
  'Lyons Twp High Sch',
  'Lyons Upper Elementary',
  'Lysander Borrero Terry',
  'Lyter El Sch',
  'Lytle Creek Elementary',
  'Lytle Daep',
  'Lytle El',
  'Lytle H S',
  'Lytle J H',
  'Lytle Primary School',
  'M A Lynch Elementary School',
  'M B Hubbard Elementary',
  'M B North E C Lrn Ctr',
  'M C S Noble Middle',
  'M Clifford Miller Middle School',
  'M E Pearson Elem',
  'M J Christensen Elementary School',
  'M J Cunningham Elem Sch',
  'M L King Academy',
  'M L King Elementary School',
  'M L King Jr Elem School',
  'M L King Middle',
  'M L Phillips El',
  'M Marcus Kiley Middle',
  'M Norcross Stratton',
  'M Rivas El',
  'M S U At New Lisbon',
  'M. A. Jones Elementary School',
  'M. B. Kennedy Middle',
  'M. D. Fox School',
  'M. D. Roberts Middle School',
  'M. H. Stanley Middle',
  'M. J. Kaufman Elementary School',
  'M. Joan Pearson Elementary School',
  'M. L. King Elementary',
  'M. L. King Jr. School',
  'M. Lynn Bennion School',
  'M. Robert Adkison Elementary',
  'M. S. Bailey Child Development Center',
  'M. S. Palmer High School',
  'M. Scott Carpenter Elementary School',
  'M. Scott Carpenter Middle School',
  'M.d. Williams Intermediate School',
  'M.e. Norman Elementary School',
  'M.e.t.s. Charter School',
  'M.l. King Jr. Es',
  'M.l. King Magnet Elem. School',
  'M.r. Weaver Elementary School',
  'M.u. Lujan Elementary School',
  'M.u.s.d. #40 - Little Vandal Preschool',
  'M.w. Savage Elementary',
  'M0NTESSORI COUNTRY DAY A RIVERLAKES',
  'MAAYAN TORAH DAY SCHOOL',
  'MAAYANOT YESHIVA HIGH SCHOOL FOR GIRLS',
  'MABLETON CHRISTIAN ACADEMY',
  'MABSCOTT CHRISTIAN ACADEMY',
  'MAC ARTHUR PARK LUTHERAN SCHOOL',
  'MACADEMY',
  'MACEDONIAN CHRISTIAN ACADEMY',
  'MACFEAT EARLY CHILDHOOD LAB SCHOOL',
  'MACHON BAIS YAAKOV HILDA BIRN HIGH SCHOOL',
  'MACKINTOSH ACADEMY',
  'MACLAY SCHOOL',
  'MACON CHRISTIAN ACADEMY',
  'MACON EAST ACADEMY',
  'MACON ROAD BAPTIST SCHOOL',
  'MAD RIVER MONTESSORI',
  'MADINAH ACADEMY OF MADISON',
  'MADISON ACADEMY',
  'MADISON AVENUE BAPTIST ACADEMY',
  'MADISON BAPTIST ACADEMY',
  'MADISON CAMPUS ELEMENTARY SCHOOL',
  'MADISON CHRISTIAN SCHOOL',
  'MADISON COMMUNITY MONTESSORI',
  'MADISON COUNTRY DAY SCHOOL',
  'MADISON MONTESSORI SCHOOL',
  'MADISON OAKS ACADEMY',
  'MADISON RIDGELAND ACADEMY',
  'MADISON WALDORF SCHOOL',
  'MADONNA DEL SASSO SCHOOL',
  'MADONNA HEIGHT SCHOOL',
  'MADONNA LEARNING CENTER',
  'MADONNA SCHOOL',
  'MADRASATU AHLIS SUNNAH',
  'MADRONA SCHOOL',
  'MADRONE ADVENTIST ELEMENTARY SCHOOL',
  'MAGDALEN CATHOLIC SCHOOL',
  'MAGIC KINGDOM DAY NURSERY',
  'MAGIC MOMENTS FOR CHILDREN',
  'MAGIC RAINBOW PRE SCHOOL',
  'MAGNIFICAT HIGH SCHOOL',
  'MAGNOLIA ACADEMY',
  'MAGNOLIA HEIGHTS SCHOOL',
  'MAGNOLIA MONTESSORI SCHOOL',
  'MAGNOLIA SPEECH SCHOOL',
  'MAHAFFEY CHRISTIAN SCHOOL',
  'MAHARISHI SCHOOL',
  'MAILI BIBLE SCHOOL',
  'MAIMONIDES HEBREW DAY SCHOOL',
  'MAIMONIDES JEWISH DAY SCHOOL',
  'MAIMONIDES SCHOOL ELEMENTARY SECTION',
  'MAIN LINE REFORM TEMPLE SCHOOL FOR EARLY CHILDHOOD',
  'MAIN STREET CHRISTIAN EDUCATION CENTER',
  'MAIN STREET MONTESSORI & MIDDLE SCHOOL',
  'MAIN STREET SCHOOLHOUSE- INC',
  'MAINE CENTRAL INSTITUTE',
  'MAINE COAST SEMESTER AT CHEWONKI',
  'MAINE COAST WALDORF SCHOOL',
  'MAINSPRING ACADEMY INC',
  'MAITLAND MONTESSORI SCHOOL',
  'MAKKAH ISLAMIC SCHOOL',
  'MAKUA LANI CHRISTIAN ACADEMY',
  'MALAIKA EARLY LEARNING CENTER',
  'MALAMALAMA WALDORF SCHOOL',
  'MALIK ACADEMY',
  'MALIK ACADEMY/ AL BUSTAN PRESCHOOL',
  'MALTA MONTESSORI',
  'MALTRAIT MEMORIAL CATHOLIC SCHOOL',
  'MALVERN PREPARATORY SCHOOL',
  'MALVERN SCHOOL',
  "MAMA JACK'S PREPARATORY ACADEMY",
  "MANA'O'IO BAPTIST ACADEMY",
  'MANARAH ISLAMIC ACADEMY',
  'MANASSA SCHOOL',
  'MANASSAS ADVENTIST PREPARATORY SCHOOL',
  'MANASSAS CHRISTIAN SCHOOL',
  'MANATEE LEARNING ACADEMY',
  'MANCHESTER CHRISTIAN ACADEMY',
  'MANCHESTER VILLAGE SCHOOL',
  'MANGROVE SCHOOL OF SARASOTA',
  'MANHATTAN CATHOLIC SCHOOL',
  "MANHATTAN CHILDREN'S CENTER (THE)",
  'MANHATTAN CHRISTIAN ACADEMY',
  'MANHATTAN CHRISTIAN SCHOOL',
  'MANHATTAN HIGH SCHOOL FOR GIRLS',
  'MANHATTAN STAR ACADEMY',
  'MANHEIM CHRISTIAN DAY SCHOOL',
  'MANITOU SCHOOL',
  'MANITOWOC LUTHERAN HIGH SCHOOL',
  'MANLIUS PEBBLE HILL SCHOOL',
  'MANN RESIDENTIAL SCHOOL',
  'MANOA COMMUNITY CHURCH-KEEP',
  'MANOA JAPANESE LANGUAGE SCHOOL',
  'MANOR MONTESSORI SCHOOL-BETHESDA',
  'MANOR MONTESSORI SCHOOL-ROCKVILLE',
  'MANSFIELD CHRISTIAN SCHOOL',
  'MANSIO MENS MONTESSORI SCHOOL',
  'MANSION DAY SCHOOL',
  'MANTECA ADVENTIST CHRISTIAN SCHOOL',
  'MANUS ACADEMY',
  'MANVILLE SCHOOL',
  'MANY RIVERS MONTESSORI',
  'MANZANITA SCHOOL AND INSTITUTE',
  'MANZANO CHRISTIAN SCHOOL',
  'MANZANO DAY SCHOOL',
  'MAPLE AVE SCHOOL',
  'MAPLE COUNTRY SCHOOL',
  'MAPLE DENE ELEMENTARY SCHOOL',
  'MAPLE GROVE',
  'MAPLE GROVE AMISH SCHOOL',
  'MAPLE GROVE SCHOOL',
  'MAPLE HILL SCHOOL',
  'MAPLE KNOB',
  'MAPLE KNOLL',
  'MAPLE KNOLL MONTESSORI CHILD CENTER',
  'MAPLE LAKE ACADEMY',
  'MAPLE LANE SCHOOL',
  'MAPLE LEAF AMISH SCHOOL',
  'MAPLE MEADOW SCHOOL',
  'MAPLE PARK MONTESSORI SCHOOL',
  'MAPLE RIDGE',
  'MAPLE RIDGE PAROCHIAL SCHOOL',
  'MAPLE RIDGE SCHOOL',
  'MAPLE ROW SCHOOL',
  'MAPLE SPRINGS C/O MIRIAM MILLER',
  'MAPLE TREE ACADEMY',
  'MAPLE TREE MONTESSORI ACADEMY',
  'MAPLE VALLEY ELEMENTARY SCHOOL',
  'MAPLE VILLAGE WALDORF SCHOOL',
  'MAPLEBROOK SCHOOL',
  'MAPLESTONE',
  'MAPLEWOOD ACADEMY',
  'MAPLEWOOD BAPTIST ACADEMY',
  'MAPLEWOOD SCHOOL',
  'MARANATHA ACADEMY',
  'MARANATHA ADVENTIST CHRISTIAN SCHOOL',
  'MARANATHA ADVENTIST SCHOOL',
  'MARANATHA BAPTIST ACADEMY',
  'MARANATHA BAPTIST CHRISTIAN SCHOOL',
  'MARANATHA BAPTIST CHURCH ACADEMY',
  'MARANATHA CHRISTIAN ACADEMY',
  'MARANATHA CHRISTIAN SCHOOL',
  'MARANATHA HIGH SCHOOL',
  'MARANATHA MENNONITE CHRISTIAN SCHOOL',
  'MARANATHA SEVENTH-DAY ADVENTIST CHRISTIAN SCHOOL',
  'MARANATHAN ACADEMY',
  'MARATHON CHRISTIAN ACADEMY',
  'MARBELLA MONTESSORI',
  'MARBLE VALLEY ACADEMY',
  'MARBURN ACADEMY',
  'MARCIA D SMITH SCHOOL',
  'MARCUS POINTE CHRISTIAN SCHOOL',
  'MARDAN SCHOOL',
  'MARELUE MONTESORRI ACADEMY',
  'MARENGO ACADEMY HIGH SCHOOL',
  'MARET SCHOOL',
  'MARGARET MURPHY CENTER FOR CHILDREN-CHARLES',
  'MARGARET MURPHY CENTER FOR CHILDREN-RODMAN',
  'MARGARET P MUSCARELLE CHILD DEVELOPMENT CENTER',
  'MARGARET SHADICK CYERT CENTER FOR EARLY ED & PROG',
  'MARGOLIN HEBREW ACADEMY',
  'MARGUERITE CHRISTIAN PRE SCHOOL',
  'MARIA IMMACOLATA CATHOLIC SCHOOL',
  'MARIA MONTESSORI SCHOOL',
  'MARIA REGINA ELEMENTARY SCHOOL',
  'MARIA REGINA HIGH SCHOOL',
  'MARIAN CATHOLIC HIGH SCHOOL',
  'MARIAN CENTER SCHOOL & SERVICES',
  'MARIAN CENTRAL CATHOLIC HIGH SCHOOL',
  'MARIAN HIGH SCHOOL',
  'MARIAN MIDDLE SCHOOL',
  'MARIANAPOLIS PREP SCHOOL',
  'MARIETTA CHRISTIAN SCHOOL',
  'MARIETTA FIRST BAPTIST CHRUCH CEE',
  'MARIETTA JOHNSON SCHOOL OF ORGANIC EDUCATION',
  'MARIMONT MONTESSORI SCHOOL INC',
  'MARIN ACADEMY',
  'MARIN CATHOLIC HIGH SCHOOL',
  'MARIN CHRISTIAN ACADEMY',
  'MARIN COUNTRY DAY SCHOOL',
  'MARIN HORIZON SCHOOL',
  'MARIN MONTESSORI SCHOOL',
  'MARIN PREPARATORY SCHOOL',
  'MARIN PRIMARY & MIDDLE SCHOOL',
  'MARINA MONTESSORI',
  'MARINE MILITARY ACADEMY',
  'MARINERS CHRISTIAN SCHOOL',
  'MARION ACADEMY',
  'MARIPOSA MONTESSORI SCHOOL',
  'MARIST CATHOLIC HIGH SCHOOL',
  'MARIST HIGH SCHOOL',
  'MARIST SCHOOL',
  'MARK DAY SCHOOL',
  'MARKETPLACE MISSION LEARNING CENTER',
  'MARLBORO ACADEMY',
  'MARLBORO MONTESSORI ACADEMY',
  'MARLBOROUGH SCHOOL',
  'MARLIN HUTTERITE SCHOOL',
  'MARLTON CHRISTIAN ACADEMY ELEMENTARY & PRESCHOOL',
  'MARMION ACADEMY',
  'MARQUETTE ACADEMY',
  'MARQUETTE CATHOLIC HIGH SCHOOL',
  'MARQUETTE CATHOLIC SCHOOLS PK-12',
  'MARQUETTE MANOR BAPTIST ACADEMY',
  'MARQUETTE UNIVERSITY HIGH SCHOOL',
  'MARS HILL ACADEMY',
  'MARS HILL BIBLE SCHOOL',
  'MARSHALL ACADEMY',
  'MARSHALL CHRISTIAN ACADEMY',
  'MARSHALLTOWN CHRISTIAN SCHOOL',
  'MARTIN DALE SCHOOL',
  'MARTIN DE PORRES',
  'MARTIN J GOTTLIEB DAY SCHOOL',
  'MARTIN LUTHER ACADEMY',
  'MARTIN LUTHER CHRISTIAN SCHOOL',
  'MARTIN LUTHER GRAMMAR SCHOOL',
  'MARTIN LUTHER HIGH SCHOOL',
  'MARTIN LUTHER KING JR CHRISTIAN ACADEMY',
  'MARTIN LUTHER LUTHERAN SCHOOL',
  'MARTIN LUTHER SCHOOL',
  'MARTIN MEMORIAL SEVENTH-DAY ADVENTIST SCHOOL',
  'MARTINDALE PAROCHIAL SCHOOL',
  'MARTINS CREEK C/O ELAINE MILLER',
  'MARTINS FERRY CHRISTIAN SCHOOL',
  'MARTINSBURG CHRISTIAN ACADEMY',
  "MARTY'S CENTER",
  'MARVELL ACADEMY',
  'MARY BOBB LEARNING ACADEMY',
  'MARY CARIOLA CHILDRENS CENTER',
  'MARY CARRICO MEMORIAL SCHOOL',
  'MARY HELP OF CHRISTIANS ACADEMY',
  'MARY HELP OF CHRISTIANS CATHOLIC SCHOOL',
  'MARY HELP OF CHRISTIANS SCHOOL',
  'MARY IMMACULATE ACADEMY',
  'MARY IMMACULATE ELEMENTARY SCHOOL',
  'MARY IMMACULATE QUEEN SCHOOL',
  'MARY INSTITUTE & ST LOUIS COUNTRY DAY SCHOOL',
  'MARY OF LOURDES SCHOOL',
  'MARY OF NAZARETH CATHOLIC S',
  'MARY OF NAZARETH SCHOOL',
  'MARY OUR QUEEN SCHOOL',
  'MARY QUEEN OF APOSTLES SCHOOL -FREEPORT RD',
  'MARY QUEEN OF CATHOLIC ACADEMY',
  'MARY QUEEN OF HEAVEN SCHOOL',
  'MARY QUEEN OF PEACE CATHOLIC SCHOOL',
  'MARY QUEEN OF PEACE SCHOOL',
  'MARY QUEEN OF SAINTS CATHOLIC ACADEMY-GREENFIELD',
  'MARY QUEEN OF THE HOLY ROSARY SCHOOL',
  'MARY SEAT OF WISDOM SCHOOL',
  'MARY STAR OF THE SEA SCHOOL',
  'MARY, STAR OF THE SEA EARLY LEARNING CENTER',
  'MARY, STAR OF THE SEA SCHOOL',
  'MARYGROVE SERVICES, INC.',
  'MARYHAVEN CENTER OF HOPE',
  'MARYKNOLL SCHOOL',
  'MARYLAND GOSPEL ASSEMBLY SCHOOL',
  'MARYMOUNT HIGH SCHOOL LOS ANGELES',
  'MARYMOUNT SCHOOL OF NY',
  'MARYVALE PREPARATORY SCHOOL',
  'MARYVILLE CHRISTIAN SCHOOL',
  'MARYVILLE JEN SCHOOL',
  'MASON COUNTY CHRISTIAN SCHOOL',
  'MASON PREPARATORY SCHOOL',
  'MASSANUTTEN MILITARY ACADEMY',
  'MASSILLON CHRISTIAN SCHOOL',
  'MASTER CHRISTIAN AMBASSADOR ACADEMY',
  'MASTER MINDS ACADEMY',
  'MASTER VINEYARD MISSION ACADEMY',
  "MASTER'S ACADEMY OF VERO BEACH",
  "MASTER'S TRAINING ACADEMY INC.",
  'MASTERS PREPARATORY CHRISTIAN ACADEMY',
  'MATER AMORIS SCHOOL',
  'MATER CHRISTI SCHOOL',
  'MATER DEI ACADEMY',
  'MATER DEI CATHOLIC HIGH SCHOOL',
  'MATER DEI CATHOLIC SCHOOL',
  'MATER DEI HIGH SCHOOL',
  'MATER DEI PREP HIGH SCHOOL',
  'MATER DEI SCHOOL',
  'MATER DEI SCHOOL NATIVITY CENTER',
  'MATER DOLOROSA ELEMENTARY SCHOOL',
  'MATER DOLOROSA SCHOOL',
  'MATERNITY BVM ELEMENTARY SCHOOL',
  'MATERNITY BVM SCHOOL',
  'MATERNITY OF MARY - ST ANDREW SCHOOL',
  'MATHENY SCHOOL',
  'MATIGNON HIGH SCHOOL',
  'MATLOCK ACADEMY',
  'MATTERSTOWN SCHOOL',
  "MATTHEW'S CENTER FOR VISUAL LEARNING",
  'MAUI ADVENTIST SCHOOL',
  'MAUI PREPARATORY ACADEMY',
  'MAULDIN CHRISTIAN ACADEMY',
  'MAULDIN UNITED METHODIST PRESCHOOL & KINDERGARTEN',
  'MAUMEE VALLEY COUNTRY DAY SCHOOL',
  'MAUMEE VALLEY SCHOOL',
  'MAUR HILL - MOUNT ACADEMY',
  'MAVAKSHAI HASHEM',
  'MAVEN ACADEMY THE',
  'MAY CENTER FOR LEARNING',
  'MAY CTR SCHOOL FOR AUTISM AND DEVELOPMENTAL DIS',
  'MAY CTR SCHOOL FOR BRAIN INJURY AND RELATED DIS',
  'MAY RIVER MONTESSORI SCHOOL',
  'MAYBECK HIGH SCHOOL',
  'MAYER LUTHERAN HIGH SCHOOL',
  'MAYFAIR CHILD DEVELOPMENT CENTER',
  'MAYFAIR CHRISTIAN SCHOOL',
  'MAYFIELD JUNIOR SCHOOL',
  'MAYFIELD SENIOR SCHOOL',
  'MAYFLOWER CHRISTIAN SCHOOL',
  'MAYFLOWER EARLY CHILDHOOD CENTER',
  'MAYFLOWER HERITAGE CHRISTIAN',
  'MAYLAN INTERNATIONAL ACADEMY',
  'MAYWOOD CHRISTIAN SCHOOL',
  'MAYWOOD MARGERY DAW DAY CARE',
  'MAZZUCHELLI CATHOLIC MIDDLE SCHOOL',
  'MC CARTAN MEMORIAL SCHOOL',
  'MC GLANNAN SCHOOL',
  'MC LEAN SCHOOL OF MARYLAND',
  'MCA ACADEMY',
  'MCALESTER CHRISTIAN ACADEMY',
  'MCAULEY CATHOLIC HIGH SCHOOL',
  'MCAULEY HIGH SCHOOL',
  'MCAULEY NAZARETH HOME FOR BOYS',
  'MCBEATH SCHOOL',
  'MCC ACADEMY',
  'MCCALLIE SCHOOL',
  'MCCAULEY SCHOOL',
  'MCCLAYS MILL AMISH SCHOOL',
  'MCCROSSAN BOYS RANCH',
  'MCDONALD MONTESSORI SCHOOL',
  'MCDONELL CENTRAL CATHOLIC HIGH SCHOOL',
  'MCDONOGH SCHOOL',
  'MCDONOUGH METHODIST ACADEMY',
  'MCDOWELL CENTER FOR CHILDREN',
  'MCDOWELL MIDDLE AMISH SCHOOL',
  'MCDOWELL SCHOOL',
  'MCELWAIN CHRISTIAN ACADEMY',
  'MCES-ST. JOHN CAMPUS',
  'MCGILL-TOOLEN CATHOLIC HIGH SCHOOL',
  'MCGINNIS WOODS COUNTRY DAY SCHOOL',
  'MCGUFFEY MONTESSORI SCHOOL',
  'MCI-Management Center Innsbruck',
  'MCKINNA LEARNING CENTER',
  'MCKINNEY BYRD ACADEMY',
  'MCKINNEY CHRISTIAN ACADEMY',
  'MCLEA SCHOOL',
  "MCMILLIAN'S FIRST STEPS CDC / ACADEMY",
  'MCMINNVILLE ADVENTIST CHRISTIAN SCHOOL',
  'MCMINNVILLE CHRISTIAN ACADEMY',
  'MCMINNVILLE MONTESSORI SCHOOL',
  'MCP MIDDLE & HIGH SCHOOL',
  'MCQUAID JESUIT',
  'MDE SCHOOL OF EAST COBB',
  'MDQ ACADEMY',
  "ME'RAJ ACADEMY",
  'MEACHAM CHILD CARE & LEARNING CENTER',
  'MEAD HALL EPISCOPAL SCHOOL',
  'MEAD MONTESSORI SCHOOL',
  'MEADOW BROOK',
  'MEADOW BROOK AMISH SCHOOL',
  'MEADOW BROOK SCHOOL',
  'MEADOW CREEK',
  'MEADOW GLADE ADVENTIST ELEMENTARY SCHOOL',
  'MEADOW GLOW',
  'MEADOW GREEN MENNONITE SCHOOL',
  'MEADOW LANE SCHOOL',
  'MEADOW LARK',
  'MEADOW LARK SCHOOL',
  'MEADOW MONTESSORI SCHOOL',
  'MEADOW OAKS ACADEMY',
  'MEADOW ROCK',
  'MEADOW RUN SCHOOL',
  'MEADOW SPRINGS',
  'MEADOW SPRINGS SCHOOL/ELAM STOLTZFUS, SECRETARY',
  'MEADOW VALLEY',
  'MEADOW VALLEY CHRISTIAN SCHOOL',
  'MEADOW VALLEY SCHOOL',
  'MEADOW VIEW AMISH SCHOOL',
  'MEADOW VIEW MENNONITE SCHOOL',
  'MEADOW VIEW PAROCHIAL SCHOOL',
  'MEADOW VIEW SCHOOL',
  'MEADOWBROOK ACADEMY',
  'MEADOWBROOK AMISH PAROCHIAL SCHOOL',
  'MEADOWBROOK CHILD GARDEN',
  'MEADOWBROOK MONTESSORI SCHOOL',
  'MEADOWBROOK PAROCHIAL',
  'MEADOWBROOK SCHOOL OF WESTON',
  'MEADOWBROOK WALDORF SCHOOL',
  'MEADOWLANE MONTESSORI SCHOOL',
  'MEADOWLARK HILL',
  'MEADOWLARK MONTESSORI SCHOOL',
  'MEADOWLARK SCHOOL',
  'MEADOWRIDGE PELHAM ACADEMY',
  'MEADOWS MONTESSORI',
  'MEADOWS OF HOPE CHRISTIAN SCHOOL',
  'MEADOWS SCHOOL',
  'MEADOWVIEW AMISH SCHOOL',
  'MEADOWVIEW CHRISTIAN ACADEMY',
  'MEADOWVIEW CHRISTIAN SCHOOL',
  'MEALY AMISH SCHOOL',
  'MECE',
  'MECHANICSVILLE MENNONITE SCHOOL',
  'MECHINA OF SOUTH FLORIDA',
  'MEDIA AMISH PAROCHIAL SCHOOL/AMOS KAFFMAN',
  "MEDIA CHILDREN'S HOUSE MONTESSORI SCHOOL",
  'MEDIA-PROVIDENCE FRIENDS SCHOOL',
  'MEDINA ACADEMY',
  'MEDINA CHRISTIAN ACADEMY',
  'MEDLOCK BRIDGE MONTESSORI',
  'MEETING HOUSE MONTESSORI SCHOOL',
  'MEETING STREET',
  'MEETING STREET ACADEMY',
  'MEETING STREET/THE SCHWARTZ CENTER',
  'MEF University',
  'MEHER MONTESSORI ACADEMY',
  'MEHER MONTESSORI SCHOOL',
  'MEHER SCHOOLS',
  'MEIGS MENNONITE CHRISTIAN SCHOOL',
  'MEIRA ACADEMY',
  'MEISTER MEMORIAL SDA SCHOOL',
  'MEKEEL CHRISTIAN SCHOOL',
  'MELBOURNE CENTRAL CATHOLIC HIGH SCHOOL',
  'MELIORA ACADEMY',
  'MELMARK NEW ENGLAND',
  'MELMARK SCHOOL',
  'MELODY ECHOES SCHOOL',
  'MELVIN J BERMAN HEBREW ACADEMY',
  'MEMORIAL CHRISTIAN ACADEMY',
  'MEMORIAL CREME DE LA CREME',
  'MEMORIAL DAY SCHOOL',
  'MEMORIAL HALL SCHOOL',
  'MEMORIAL LUTHERAN SCHOOL',
  'MEMPHIS CATHOLIC MIDDLE & HIGH SCHOOL',
  'MEMPHIS JUNIOR ACADEMY',
  'MEMPHIS RECOVERY CENTER',
  'MEMPHIS UNIVERSITY SCHOOL',
  'MENACHEM MENDEL SEATTLE CHEDER DAY SCHOOL',
  'MENAUL SCHOOL',
  'MENDHAM COUNTRY DAY SCHOOL',
  'MENI NINHO MENI NINHA INC',
  'MENLO SCHOOL',
  'MENTOR CHRISTIAN SCHOOL',
  'MENTORING ACADEMY',
  'MEOROS BAIS YAAKOV',
  'MERAMEC VALLEY CHRISTIAN SCHOOL',
  'MERCER CHRISTIAN ACADEMY',
  'MERCERSBURG ACADEMY',
  'MERCHANT HILL SCHOOL',
  'MERCY ACADEMY',
  'MERCY CAREER & TECHNICAL HIGH SCHOOL',
  'MERCY HIGH SCHOOL',
  'MERCY HIGH SCHOOL, SAN FRANCISCO',
  'MERCY MONTESSORI',
  'MERCY MULTIPLIED',
  'MERCY SCHOOL INSTITUTE',
  'MERCY SPECIAL LEARNING CENTER',
  'MERCYHURST PREPARATORY SCHOOL',
  'MERCYMOUNT COUNTRY DAY SCHOOL',
  'MERDINIAN ARMENIAN EVANGELICAL SCHOOL',
  'MEREDITH-DUNN SCHOOL',
  'MERIDIAN ACADEMY',
  'MERIDIAN SCHOOL',
  'MERION MERCY ACADEMY',
  'MERIT ACADEMY',
  'MERIT OF PRINCE WILLIAM',
  'MERRIMACK PTA KINDERGARTEN & PRESCHOOL',
  'MERRIMACK VALLEY MONTESSORI SCHOOL',
  'MERRITT ACADEMY',
  'MERRITT ISLAND CHRISTIAN SCHOOL',
  'MERRY HILLS SCHOOL',
  'MERRYHILL ELEMENTARY SCHOOL',
  'MERRYHILL ELEMENTARY SCHOOL - DURANGO',
  'MERRYHILL ELEMENTARY SCHOOL - SUMMERLIN',
  'MERRYHILL SCHOOL',
  'MERRYHILL SCHOOL 1074',
  'MERRYHILL SCHOOL AT GREEN VALLEY',
  'MESA GRANDE ACADEMY',
  'MESILAS BAIS YAAKOV',
  'MESILLA VALLEY CHRISTIAN SCHOOL',
  'MESIVTA AHAVAS HATORAH',
  "MESIVTA AHAVAS HATORAH D'LAKEWOOD",
  'MESIVTA ATERES SHMUEL OF WATERBURY',
  'MESIVTA ATERES YAAKOV',
  'MESIVTA CHOFETZ CHAIM',
  'MESIVTA HIGH SCHOOL',
  'MESIVTA KESER TORAH',
  'MESIVTA NEIMUS HATORAH',
  'MESIVTA NESIVOS HATALMUD',
  'MESIVTA NEZER HATORAH',
  'MESIVTA OF CLIFTON',
  'MESIVTA OF CORAL SPRINGS',
  'MESIVTA OF EATONTOWN',
  'MESIVTA OF GREATER LOS ANGELES',
  'MESIVTA OF NORTH JERSEY',
  'MESIVTA OF WEST BLOOMFIELD',
  "MESIVTA OF YESHIVA D'MONSEY",
  'MESIVTA OHR CHAIM MEIR',
  'MESIVTA RABBI SAMSON RAPHAEL HIRSCH',
  'MESIVTA TIFERES RAV ZVI ARYEH ZEMEL',
  "MESIVTA TORAH V'EMUNAH",
  'MESIVTA YAM HATORAH',
  'MESIVTA YESHIVA RABBI CHAIM BERLIN',
  'MESIVTA YESODEI YESHURUN',
  'MESIVTA/YESHIVA GEDOLA MANHATTAN BEACH',
  'MESORAH HIGH SCHOOL FOR GIRLS',
  'MESQUITE FRIENDSHIP CHRISTIAN ACADEMY',
  'MESSIAH ACADEMY',
  'MESSIAH LUTHERAN CLASSICAL ACADEMY',
  'MESSIAH LUTHERAN LAMBS EARLY CHILDHOOD SCHOOL',
  'MESSIAH LUTHERAN SCHOOL',
  'MESSIAH MONTESSORI',
  'MESSMER CATHOLIC SCHOOLS',
  'MESSMER ST ROSE CATHOLIC SCHOOL',
  'METAIRIE PARK COUNTRY DAY SCHOOL',
  'METHODIST FAMILY CHILD CARE',
  'METHODIST SCHOOL FOR EARLY EDUCATION',
  'METHODIST SERVICES',
  'METHOW VALLEY COMMUNITY SCHOOL',
  'METRO CATHOLIC SCHOOL',
  'METRO CHRISTIAN ACADEMY',
  'METRO EAST MONTESSORI SCHOOL',
  'METRO WEST JEWISH DAY SCHOOL',
  'METRO-EAST LUTHERAN HIGH SCHOOL',
  'METROPOLITAN CHRISTIAN SCHOOL',
  'METROPOLITAN DAY SCHOOL',
  'METROPOLITAN INTERNATIONAL SCHOOL OF MIAMI',
  'METROPOLITAN JUNIOR ACADEMY',
  'METROPOLITAN MONTESSORI SCHOOL',
  'METROPOLITAN OPEN SCHOOL',
  'METROPOLITIAN SCHOOL OF TALENT AND THE ARTS',
  'METROWEST CHRISTIAN ACADEMY',
  'METTA MONTESSORI SCHOOL',
  'METZLERS MENNONITE SCHOOL',
  'METZUYAN ACADEMY',
  'MEYGA LEARNING CENTER',
  'MI SOL ACADEMY',
  'MIAMI CHRISTIAN SCHOOL',
  'MIAMI COUNTRY DAY SCHOOL',
  'MIAMI SHORES COMMUNITY CHURCH SCHOOL',
  'MIAMI SHORES MONTESSORI SCHOOL',
  'MIAMI SHORES PRESBYTERIAN CHURCH SCHOOL',
  'MIAMI SPRINGS ADVENTIST SCHOOL',
  'MIAMI SPRINGS BAPTIST CHURCH KINDERGARTEN',
  'MIAMI SPRINGS MONTESSORI SCHOOL',
  'MIAMI UNION ACADEMY OF SDA',
  'MICAH CHRISTIAN SCHOOL',
  'MICHIANA CHRISTIAN ACADEMY',
  'MICHIGAN ISLAMIC ACADEMY',
  'MICHIGAN LUTHERAN SEMINARY',
  'MID PACIFIC INSTITUTE',
  'MID VALLEY CHRISTIAN SCHOOL',
  'MID VERMONT CHRISTIAN SCHOOL',
  'MID-AMERICA PREPARATORY SCHOOL',
  'MID-COLUMBIA ADVENTIST CHRISTIAN SCHOOL',
  'MID-PENINSULA HIGH SCHOOL',
  'MID-VALLEY CHRISTIAN ACADEMY',
  'MIDDLE CREEK MONTESSORI',
  'MIDDLE ROAD SCHOOL C/O SAM YODER',
  'MIDDLE RUN SCHOOL',
  'MIDDLE TENNESSEE CHRISTIAN SCHOOL',
  'MIDDLE VALLEY ACADEMY',
  'MIDDLEBURG ACADEMY, INC.',
  'MIDDLETON ACADEMY',
  'MIDDLETONS EARLY LEARNING CENTER',
  'MIDDLETOWN ADVENTIST SCHOOL',
  'MIDDLETOWN CHRISTIAN SCHOOL',
  'MIDDLETOWN CHRISTIAN SCHOOL-SDA',
  'MIDLAND CHRISTIAN ACADEMY',
  'MIDLAND CHRISTIAN SCHOOL',
  'MIDLAND MONTESSORI SCHOOL',
  'MIDLAND SCHOOL CORPORATION',
  'MIDLAND VALLEY CHRISTIAN ACADEMY',
  'MIDLANDS MONTESSORI SCHOOL',
  'MIDLOTHIAN MONTESSORI',
  'MIDRASH LMAN ACHAI',
  'MIDRESHET SHALHEVET - SHALHEVET HIGH SCHOOL FOR GI',
  'MIDSTATE CHRISTIAN ACADEMY',
  'MIDTOWN INTERNATIONAL SCHOOL',
  'MIDTOWN MONTESSORI',
  'MIDTOWN MONTESSORI SCHOOL',
  'MIDWAY COVENANT CHRISTIAN SCHOOL',
  'MIDWEST ACADEMY',
  'MIDWEST CHRISTIAN MONTESSORI ACADEMY',
  'MIDWEST MONTESSORI SCHOOL',
  'MIDWESTERN CHRISTIAN ACADEMY',
  'MIDWOOD CATHOLIC ACADEMY',
  'MIDWOOD MONTESSORI SCHOOL',
  'MIFFLIN CO CHRISTIAN ACADEMY',
  'MIFTAAHUL ULOOM ACADEMY',
  'MILAGRE KIDS SCHOOL',
  'MILAN CENTER SCHOOL',
  'MILE HIGH ACADEMY',
  'MILE HIGH BAPTIST SCHOOL',
  'MILESTONE ACADEMY',
  'MILESTONES BEHAVIORAL SERVICES',
  'MILESTONES DAY SCHOOL',
  'MILESTONES MONTESSORI OF IRVINE',
  'MILFORD CHRISTIAN ACADEMY',
  'MILFORD CHRISTIAN SCHOOL',
  'MILFORD E BARNES JR SCHOOL',
  'MILFORD MILL CHURCH CHILD DEVELOPMENT CENTER',
  'MILHOUS SCHOOL INC. - GERBER',
  'MILKEN COMMUNITY SCHOOLS',
  'MILL CREEK RIDGE SCHOOL',
  'MILL CREEK SCHOOL',
  'MILL POND SCHOOL',
  'MILLBACH MENNONITE SCHOOL',
  'MILLBROOK SCHOOL',
  'MILLCREEK AMISH',
  'MILLCREEK PAROCHIAL SCHOOL',
  'MILLCREEK SCHOOL',
  'MILLEDGEVILLE CHRISTIAN ACADEMY',
  'MILLENNIAL CHRISTIAN SCHOOL',
  'MILLENNIAL KINGDOM FAMILY SCHOOL',
  'MILLENNIUM ACADEMY',
  'MILLENNIUM CHRISTIAN ACADEMY',
  'MILLENNIUM SCHOOL OF SAN FRANCISCO',
  'MILLER SCHOOL OF ALBEMARLE',
  "MILLER'S MILITARY ACADEMY",
  'MILLERS CREEK CHRISTIAN SCHOOL',
  'MILLERVIEW AMISH SCHOOL',
  'MILLHOPPER MONTESSORI SCHOOL',
  'MILLPORT SCHOOL',
  'MILLS COLLEGE CHILDRENS SCHOOL',
  'MILLS MONTESSORI',
  'MILLWOOD SCHOOL',
  'MILPITAS CHRISTIAN SCHOOL',
  'MILROY AMISH SCHOOL',
  'MILTON EISNER YESHIVA HIGH SCHOOL',
  'MILTON HERSHEY SCHOOL',
  'MILTON MONTESSORI SCHOOL',
  'MILTON-STATELINE ADVENTIST SCHOOL',
  'MILWAUKEE JEWISH DAY SCHOOL',
  'MILWAUKEE LUTHERAN HIGH SCHOOL',
  'MILWAUKEE MONTESSORI SCHOOL',
  'MILWAUKEE SEVENTH-DAY ADVENTIST SCHOOL',
  "MIMI'S LEARNING CENTER",
  'MINERAL SPRINGS CHRISTIAN SCHOOL',
  'MINERVA AREA CHRISTIAN SCHOOL',
  'MINGO VALLEY CHRISTIAN SCHOOL',
  'MINI ME ACADEMY',
  'MINIAPPLE INTERNATIONAL MONTESSORI',
  'MINNEHAHA ACADEMY - UPPER SCHOOL',
  'MINNESOTA MONTESSORI ACCELERTD LEARNING CENTER INC',
  'MINNESOTA VALLEY LUTHERAN HIGH SCHOOL',
  'MINNETONKA CHRISTIAN ACADEMY',
  'MINNICH SCHOOL',
  'MINNICK EDUCATION CENTER - WISE',
  'MINNICK EDUCATION CENTER - WYTHEVILLE',
  'MINNICK SCHOOL-HARRISONBURG',
  'MINNIELAND ACADEMY @ KIRKPATRICK FARMS',
  'MINNIELAND ACADEMY @ SOUTH RIDING',
  'MINNIELAND ACADEMY AT ASHBRIAR',
  'MINT SPRINGS AMISH SCHOOL',
  'MINTZ CHRISTIAN ACADEMY',
  'MIRACLE CITY ACADEMY',
  'MIRAJ ISLAMIC SCHOOL',
  'MIRAMONTE SCHOOL',
  'MIRIAM SCHOOL',
  'MIRMAN SCHOOL FOR GIFTED CHILDREN',
  'MIRRER YESHIVA EDUCATIONAL INSTITUTE',
  'MIRRER YESHIVA HIGH SCHOOL',
  'MIRUS ACADEMY',
  'MISERICORDIA SCHOOL',
  'MISHAWAKA CATHOLIC SCHOOL',
  'MISS BILLIES KIDS KAMPUS',
  'MISS CARUSI LEARNING CENTER',
  "MISS ENDY'S CHRISTIAN ACADEMY",
  'MISS FRANCINE DAY SCHOOL II',
  "MISS HALL'S",
  'MISS LOU CHRISTIAN ACADEMY',
  "MISS MARIE'S LEARNING CENTER",
  "MISS PORTER'S SCHOOL",
  'MISS SHELLEYS UPWARD PREP SCHOOL',
  'MISSION ACADEMY',
  'MISSION BASILICA SCHOOL',
  'MISSION BAY MONTESSORI ACADEMY',
  'MISSION BEND CHRISTIAN ACADEMY',
  'MISSION CHURCH ACADEMY AND PRESCHOOL',
  'MISSION COLLEGE PREP HS',
  'MISSION DOLORES ACADEMY',
  'MISSION FOR EDUCATING CHILDREN WITH AUTISM',
  'MISSION HOPE FOUNDATION',
  'MISSION LUTHERAN SCHOOL',
  'MISSION MONTESSORI',
  'MISSION OF FAITH COMMUNITY SCHOOL',
  'MISSION VALLEY CHRISTIAN SCHOOL',
  'MISSION VALLEY CHRISTIANACADEMY',
  "MISSION VALLEY MONTESSORI CHILDREN'S LEARNING CTR",
  'MISSION VIEJO CHRISTIAN SCHOOL',
  'MISSIONARY BIBLE BAPTIST ACADEMY',
  'MISSIONARY CHRISTIAN ACADEMY',
  'MISSISSIPPI VALLEY MONTESSORI SCHOOL',
  'MISSON CREEK CHRISTIAN EDUCATION CENTER',
  "MISSOULA INT'L SCHOOL",
  'MISSOULA VALLEY MONTESSORI',
  'MISSOURI MILITARY ACADEMY',
  'MISSOURI TORAH INSTITUTE',
  'MISSOURI VALLEY CHRISTIAN ACADEMY',
  'MISTWOOD EDUCATIONAL CENTER',
  'MISTWOOD MONTESSORI SCHOOL',
  'MISTY MEADOW SCHOOL',
  'MISTY MEADOWS SDA SCHOOL',
  'MISTY VALLEY SCHOOL',
  'MITCHELL CHRISTIAN SCHOOL',
  'MITCHELL ROAD CHRISTIAN ACADEMY',
  'MIZEL JEWISH COMMUNITY DAY SCHOOL',
  'MIZZENTOP DAY SCHOOL',
  'MIZZOU K 12 ONLINE/MU HIGH SCHOOL',
  'MLC PREPARATORY ACADEMY',
  'MMCFC - FALCON',
  'MMI PREPARATORY SCHOOL',
  'MNTS TAMIL SCHOOL',
  'MOBILE JUNIOR ACADEMY',
  'MODEL SECONDARY SCHOOL FOR THE DEAF',
  'MODESTO CHRISTIAN SCHOOL',
  'MOGADORE CHRISTIAN ACADEMY',
  'MOHAMMED SCHOOLS OF ATLANTA',
  'MOHAWK SCHOOL',
  'MOHAWK VALLEY CHRISTIAN ACADEMY',
  'MOHICAN HILLS',
  'MOHICAN VALLEY',
  'MOLINE CHRISTIAN SCHOOL',
  'MOMENTOUS SCHOOL',
  'MONA MONTESSORI GREENTREE BILLIANCE',
  'MONADNOCK WALDORF SCHOOL',
  'MONARCH ACADEMY',
  'MONARCH BAY MONTESSORI ACADEMY',
  'MONARCH CHRISTIAN MONTESSORI',
  'MONARCH CHRISTIAN SCHOOL',
  'MONARCH LEARNING ACADEMY',
  'MONARCH MONTESSORI',
  'MONARCH MONTESSORI SCHOOL',
  'MONARCH SCHOOL OF NEW ENGLAND',
  'MONCLOVA CHRISTIAN ACADEMY',
  'MONICA ROS SCHOOL',
  'MONMOUTH CHRISTIAN ACADEMY',
  'MONMOUTH MONTESORI ACADEMY',
  'MONMOUTH MONTESSORI SCHOOL LLC',
  'MONROE ACADEMY',
  'MONROE BAY CHRISTIAN ACADEMY',
  'MONROE CATHOLIC ELEMENTARY SCHOOLS',
  'MONROE CHRISTIAN SCHOOL',
  'MONROE COUNTY CHRISTIAN ACADEMY',
  'MONROE COUNTY MENNONITE SCHOOL',
  'MONROE MONTESSORI SCHOOL',
  'MONSEY BEIS CHAYA MUSHKA-HIGH SCHOOL',
  'MONSIGNOR BONNER & ARCHBISHOP PRENDERGAST HS',
  'MONSIGNOR DONOVAN CATHOLIC HIGH SCHOOL',
  'MONSIGNOR EDWARD PACE HIGH SCHOOL',
  'MONSIGNOR HADDAD MIDDLE',
  'MONSIGNOR KELLY CATHOLIC SCHOOL',
  'MONSIGNOR MATTHEW CLARKE CATHOLIC REGIONAL SCHOOL',
  'MONSIGNOR MCCLANCY MEMORIAL HIGH SCHOOL',
  'MONSIGNOR MCHUGH SCHOOL',
  'MONSIGNOR SLADE CATHOLIC SCHOOL',
  'MONTADHAR PEACE SCHOOL',
  'MONTANA ACADEMY',
  'MONTCLAIR COOPERATIVE SCHOOL',
  'MONTE CASSINO SCHOOL',
  'MONTE VISTA CHRISTIAN SCHOOL',
  'MONTEALEGRE SENIOR HIGH SCHOOL',
  'MONTEBELLO CHRISTIAN SCHOOL',
  'MONTECITO BAPTIST SCHOOL',
  'MONTEREY BAY ACADEMY',
  'MONTEREY BAY CHRISTIAN SCHOOL',
  'MONTEREY MENNONITE SCHOOL',
  'MONTEREY PARK CHRISTIAN SCHOOL',
  'MONTESSOIR OF CHATSWORTH',
  'MONTESSORI & MUSIC CENTER',
  'MONTESSORI ACADEMY',
  'MONTESSORI ACADEMY - DELRAN',
  'MONTESSORI ACADEMY AT BELMONT GREENE',
  'MONTESSORI ACADEMY AT SHARON SPRINGS',
  'MONTESSORI ACADEMY AT SPRING VALLEY',
  "MONTESSORI ACADEMY AT ST JOHN'S",
  'MONTESSORI ACADEMY IN THE OAKS',
  'MONTESSORI ACADEMY OF ARCADIA',
  'MONTESSORI ACADEMY OF CAMPBELL',
  'MONTESSORI ACADEMY OF CHAMBERSBURG',
  'MONTESSORI ACADEMY OF CHICAGO',
  'MONTESSORI ACADEMY OF CINCINNATI',
  'MONTESSORI ACADEMY OF COLORADO',
  'MONTESSORI ACADEMY OF ED INC- DBA MONTESSORI ACAD',
  'MONTESSORI ACADEMY OF GLASGOW',
  'MONTESSORI ACADEMY OF LA PUENTE',
  'MONTESSORI ACADEMY OF NAPLES',
  'MONTESSORI ACADEMY OF NORTH HOFFMAN',
  'MONTESSORI ACADEMY OF NORTH TEXAS',
  'MONTESSORI ACADEMY OF TAMPA BAY',
  'MONTESSORI ACADEMY OF UPLAND',
  'MONTESSORI ACADEMY OF VALPARAISO',
  'MONTESSORI ACADEMY OF VIRGINIA',
  'MONTESSORI ACADEMY OF WEST COVINA',
  'MONTESSORI ACADEMY OF WINTER GARDEN',
  'MONTESSORI ACADEMY-CAPE COD',
  'MONTESSORI ADOLESCENT PROGRAM',
  'MONTESSORI AMERICAN SCHOOL',
  'MONTESSORI AT BROOKSTONE SCHOOL',
  'MONTESSORI AT GREENWOOD PLAZA',
  'MONTESSORI AT RIVERTON',
  'MONTESSORI AT ROSEBOROUGH',
  'MONTESSORI AT SAMISH WOODS',
  'MONTESSORI AT SANDY FORD',
  'MONTESSORI AT THE MARINA',
  'MONTESSORI AT THE PARK',
  'MONTESSORI AT VICKERY',
  'MONTESSORI BUILDING BLOCKS',
  'MONTESSORI BY THE SEA',
  'MONTESSORI CENTER ACADEMY',
  'MONTESSORI CENTER OF DOWNRIVER',
  'MONTESSORI CENTER OF OUR LADY',
  'MONTESSORI CENTER ROOM',
  'MONTESSORI CENTER SCHOOL',
  'MONTESSORI CENTRAL ASSOCIATION',
  'MONTESSORI CENTRE',
  'MONTESSORI CENTRE OF BARRINGTON',
  'MONTESSORI CHILD DEVELOPMENT CENTER',
  "MONTESSORI CHILDREN'S ACADEMY",
  "MONTESSORI CHILDREN'S CENTER",
  "MONTESSORI CHILDREN'S ELEMENTARY",
  "MONTESSORI CHILDREN'S GARDEN",
  "MONTESSORI CHILDREN'S HOUSE",
  "MONTESSORI CHILDREN'S HOUSE & SCHOOL",
  "MONTESSORI CHILDREN'S HOUSE OF DURHAM",
  "MONTESSORI CHILDREN'S HOUSE OF FRANKTOWN",
  "MONTESSORI CHILDREN'S HOUSE OF HAYWARD",
  "MONTESSORI CHILDREN'S HOUSE OF LENAWEE",
  "MONTESSORI CHILDREN'S HOUSE OF N. BARRINGTON",
  "MONTESSORI CHILDREN'S HOUSE OF NANTUCKET",
  "MONTESSORI CHILDREN'S HOUSE OF SHADY OAKS",
  "MONTESSORI CHILDREN'S HOUSE OF VALLEY FORGE",
  "MONTESSORI CHILDREN'S ROOM",
  "MONTESSORI CHILDREN'S SCHOOL OF KEY WEST",
  "MONTESSORI CHILDREN'S WORLD",
  'MONTESSORI CHILDRENS ACADEMIE',
  'MONTESSORI CHILDRENS ACADEMY',
  'MONTESSORI CHILDRENS CENTER OF ALLEN PARK',
  'MONTESSORI CHILDRENS COMMUNITY',
  'MONTESSORI CHILDRENS GARDEN',
  'MONTESSORI CHILDRENS HOUSE',
  'MONTESSORI CHILDRENS HOUSE AT CHAPEL OF THE CROSS',
  'MONTESSORI CHILDRENS HOUSE II',
  'MONTESSORI CHILDRENS HOUSE OF LONG VIEW',
  'MONTESSORI CHILDRENS HOUSE OF LOUDOUN',
  'MONTESSORI CHILDRENS HOUSE OF SYCAMORE AND DEKALB',
  'MONTESSORI CHILDRENS ROOM',
  'MONTESSORI CHILDRENS SCHOOL',
  'MONTESSORI CHILDRENS SCHOOLHOUSE',
  'MONTESSORI CHILDRENS WORLD',
  'MONTESSORI CHRISTIAN ACADEMY LLC',
  'MONTESSORI CHRISTIAN SCHOOL',
  'MONTESSORI CIRCLE OF FRIENDS',
  'MONTESSORI COMMUNITY',
  'MONTESSORI COMMUNITY SCHOOL',
  'MONTESSORI COMMUNITY SCHOOL OF SALT LAKE CITY',
  'MONTESSORI CONNECTION WEST',
  'MONTESSORI CONNECTIONS',
  'MONTESSORI CORNER AT PRINCETON',
  'MONTESSORI COTTAGE',
  'MONTESSORI COUNTRY DAY',
  'MONTESSORI COUNTRY DAY SCHOOL',
  'MONTESSORI COUNTRY SCHOOL',
  'MONTESSORI DAY SCHOOL',
  'MONTESSORI DE TERRA LINDA',
  'MONTESSORI DISCOVERY',
  'MONTESSORI DISCOVERY SCHOOL',
  'MONTESSORI DISCOVRY SCHOOL OF THE TWIN STATES, INC',
  'MONTESSORI EARLY CHILDHOOD CENTER',
  'MONTESSORI EARLY LEARNING CENTER',
  'MONTESSORI EAST - EAST NASHVILLE',
  'MONTESSORI EDUCARE ACADEMY',
  'MONTESSORI EDUCATION CENTER OF HAWAII',
  'MONTESSORI EDUCATIONAL CENTER',
  'MONTESSORI EDUCATIONAL CENTERS-MONTESSORI ELEMENTA',
  'MONTESSORI ELEMENTARY & MIDDLE SCHOOL OF TRACY',
  'MONTESSORI ELEMENTARY SCHOOL OF ALAMEDA',
  'MONTESSORI ENRICHMENT CENTER',
  'MONTESSORI EPISCOPAL SCHOOL',
  'MONTESSORI EXPLORER ELEMENTARY',
  'MONTESSORI FAMILY SCHOOL',
  'MONTESSORI FARM SCHOOL',
  'MONTESSORI FOUNDATIONS OF CHGO',
  'MONTESSORI FOUNTAINHEAD SCHOOL',
  'MONTESSORI GREENHOUSE SCHOOL',
  'MONTESSORI GREENHOUSE SCHOOLS',
  'MONTESSORI HALE O KEIKI',
  'MONTESSORI HARBOR MESA SCHOOL',
  'MONTESSORI HIGH SCHOOL',
  'MONTESSORI HIGH SCHOOL AT UNIVERSITY CIRCLE',
  'MONTESSORI HIGH SCHOOL INCORPORATED',
  'MONTESSORI HILLS ACADEMY',
  'MONTESSORI HOUSE FOR CHILDREN',
  'MONTESSORI HOUSE OF CHILDREN',
  'MONTESSORI HOUSE OF OCALA',
  'MONTESSORI IN MOTION',
  'MONTESSORI IN REDLANDS',
  'MONTESSORI IN THE PARK',
  'MONTESSORI IN THE WOODS',
  'MONTESSORI IN TOWN',
  'MONTESSORI INSTITUTE OF BROWARD',
  'MONTESSORI INSTITUTE OF SAN DIEGO CHILD HOUSE',
  'MONTESSORI INTERNATIONAL ACADEMY',
  'MONTESSORI INTERNATIONAL CHILDRENS HOUSE',
  'MONTESSORI INTERNATIONAL SCHOOL',
  'MONTESSORI INTERNATIONALE SCHOOL',
  'MONTESSORI IVY LEAGUE',
  'MONTESSORI JOY LEARNING CENTER',
  'MONTESSORI KIDS ACADEMY',
  'MONTESSORI KINGDOM OF LEARNING',
  'MONTESSORI LANGUAGE ACADEMY',
  'MONTESSORI LEARNING CENTER',
  'MONTESSORI LEARNING CENTER OF EDISON PARK',
  'MONTESSORI LEARNING CENTRE',
  'MONTESSORI LEARNING COMMUNITY OF ASHEVILLE',
  'MONTESSORI LEARNING COTTAGE',
  'MONTESSORI LEARNING FOR LIVING',
  'MONTESSORI LEARNING HOUSE',
  'MONTESSORI LEARNING INSTITUTE',
  'MONTESSORI MANOR',
  'MONTESSORI MASTERY SCHOOL OF ODESSA',
  'MONTESSORI MIDDLE SCHOOL',
  'MONTESSORI MOMENTS',
  'MONTESSORI NEW BEGINNINGS ACADEMY',
  'MONTESSORI OF BREA',
  'MONTESSORI OF CALABASAS',
  'MONTESSORI OF CHANTILLY - CASA DEI BAMBINI',
  'MONTESSORI OF GAINESVILLE - CASA DEI BAMBINI',
  'MONTESSORI OF GREENWICH BAY',
  'MONTESSORI OF LADERA RANCH',
  'MONTESSORI OF LAGUNA NIGUEL',
  'MONTESSORI OF MT PLEASANT',
  'MONTESSORI OF NEW PALTZ',
  'MONTESSORI OF SALT LAKE',
  'MONTESSORI OF WOODRIDGE',
  'MONTESSORI ON COPPER HILL',
  'MONTESSORI ON ELM',
  'MONTESSORI ON THE LAKE',
  'MONTESSORI ONE',
  'MONTESSORI ONE PRESCHOOL ACADEMY',
  'MONTESSORI PARENTS COOP',
  'MONTESSORI PATHWAYS OF NEW ENGLAND',
  'MONTESSORI PEACE SCHOOL',
  'MONTESSORI PLUS ACADEMY',
  'MONTESSORI PLUS SCHOOL',
  'MONTESSORI PREPARATORY SCHOOL',
  'MONTESSORI PREPARATORY SCHOOL OF OCALA',
  'MONTESSORI PRESCHOOL ACADEMY',
  'MONTESSORI PROGRESSIVE LEARNING CENTER',
  'MONTESSORI RADMOOR SCHOOL',
  'MONTESSORI RAINBOW SCHOOL',
  'MONTESSORI RENAISSANCE ACADEMY',
  'MONTESSORI SCHOOL',
  'MONTESSORI SCHOOL AT FIVE CANYONS',
  'MONTESSORI SCHOOL AT HAMPSTEAD',
  'MONTESSORI SCHOOL EUREKA',
  'MONTESSORI SCHOOL FOR CREATIVE LEARNING',
  'MONTESSORI SCHOOL FOR THE ARTS & SCIENCES',
  'MONTESSORI SCHOOL GREATER HARTFORD',
  'MONTESSORI SCHOOL HOUSE',
  'MONTESSORI SCHOOL HOUSE OF SPRINGFIELD',
  'MONTESSORI SCHOOL OF ABERDEEN',
  'MONTESSORI SCHOOL OF AGOURA',
  'MONTESSORI SCHOOL OF ALEXANDRIA',
  'MONTESSORI SCHOOL OF ANDERSON',
  'MONTESSORI SCHOOL OF AURORA',
  'MONTESSORI SCHOOL OF BATON ROUGE',
  'MONTESSORI SCHOOL OF BEAVERTON',
  'MONTESSORI SCHOOL OF BOWLING GREEN',
  'MONTESSORI SCHOOL OF BRAINERD',
  'MONTESSORI SCHOOL OF CASPER',
  'MONTESSORI SCHOOL OF CASTLE ROCK',
  'MONTESSORI SCHOOL OF CEDAR LANE',
  'MONTESSORI SCHOOL OF CELEBRATION',
  'MONTESSORI SCHOOL OF CENTRAL',
  'MONTESSORI SCHOOL OF CHAMPAIGN-URBANA',
  'MONTESSORI SCHOOL OF CHEYENNE',
  'MONTESSORI SCHOOL OF CLARKSTON',
  'MONTESSORI SCHOOL OF COLUMBIA',
  'MONTESSORI SCHOOL OF CONCORD',
  'MONTESSORI SCHOOL OF CORONA',
  'MONTESSORI SCHOOL OF DAYTON',
  'MONTESSORI SCHOOL OF DOWNTOWN',
  'MONTESSORI SCHOOL OF DULUTH',
  'MONTESSORI SCHOOL OF EAST ORLANDO',
  'MONTESSORI SCHOOL OF EVERGREEN',
  'MONTESSORI SCHOOL OF FAIRFAX',
  'MONTESSORI SCHOOL OF FLORENCE',
  'MONTESSORI SCHOOL OF FORT MYERS',
  'MONTESSORI SCHOOL OF FORT WORTH',
  'MONTESSORI SCHOOL OF FRANKLIN',
  'MONTESSORI SCHOOL OF FREMONT',
  'MONTESSORI SCHOOL OF GREAT',
  'MONTESSORI SCHOOL OF GREENVILLE',
  'MONTESSORI SCHOOL OF HACIENDA HEIGHTS',
  'MONTESSORI SCHOOL OF HERNDON',
  'MONTESSORI SCHOOL OF HOLMES RUN CHILDRENS HOUSE',
  'MONTESSORI SCHOOL OF IOWA CITY',
  'MONTESSORI SCHOOL OF JERSEY CITY',
  'MONTESSORI SCHOOL OF JOHNS ISLAND',
  'MONTESSORI SCHOOL OF JONESBORO',
  'MONTESSORI SCHOOL OF LAGUNA BEACH',
  'MONTESSORI SCHOOL OF LAKE FOREST',
  'MONTESSORI SCHOOL OF LAKE FOREST ADOLESCENT PRGM',
  'MONTESSORI SCHOOL OF LEMONT',
  'MONTESSORI SCHOOL OF LONG GROVE',
  'MONTESSORI SCHOOL OF MANHATTAN BEACHH',
  'MONTESSORI SCHOOL OF MAUI',
  'MONTESSORI SCHOOL OF MAULDIN',
  'MONTESSORI SCHOOL OF MCLEAN',
  'MONTESSORI SCHOOL OF MELROSE',
  'MONTESSORI SCHOOL OF MODESTO',
  'MONTESSORI SCHOOL OF NEWARK',
  'MONTESSORI SCHOOL OF NORTHERN VIRGINIA',
  'MONTESSORI SCHOOL OF NY',
  'MONTESSORI SCHOOL OF OAKTON',
  'MONTESSORI SCHOOL OF OCEANSIDE',
  'MONTESSORI SCHOOL OF OCEANSIDE # 2',
  'MONTESSORI SCHOOL OF ORLANDO',
  'MONTESSORI SCHOOL OF PALM SPRINGS',
  'MONTESSORI SCHOOL OF PAWLEYS ISLAND',
  'MONTESSORI SCHOOL OF PENSACOLA',
  'MONTESSORI SCHOOL OF PLEASANTON',
  'MONTESSORI SCHOOL OF PULLMAN',
  'MONTESSORI SCHOOL OF RALEIGH',
  'MONTESSORI SCHOOL OF ROCHESTER',
  'MONTESSORI SCHOOL OF ROME',
  'MONTESSORI SCHOOL OF RUSTON',
  'MONTESSORI SCHOOL OF SAN DIEGO',
  'MONTESSORI SCHOOL OF SAN LEANDRO',
  'MONTESSORI SCHOOL OF SAUK RAPIDS',
  'MONTESSORI SCHOOL OF SKOKIE',
  'MONTESSORI SCHOOL OF SKOKIE NFP',
  'MONTESSORI SCHOOL OF ST CLOUD',
  'MONTESSORI SCHOOL OF SYRACUSE',
  'MONTESSORI SCHOOL OF THE DESERT',
  'MONTESSORI SCHOOL OF THE EPIPHANY',
  'MONTESSORI SCHOOL OF THE FINGER LAKES',
  'MONTESSORI SCHOOL OF THE VALLEY',
  'MONTESSORI SCHOOL OF TRACY',
  'MONTESSORI SCHOOL OF WAUKESHA',
  'MONTESSORI SCHOOL OF WESTERN SPRINGS',
  'MONTESSORI SCHOOL OF WESTFIELD',
  'MONTESSORI SCHOOL OF WESTMINSTER',
  'MONTESSORI SCHOOL OF WINSTON-SALEM',
  'MONTESSORI SCHOOL OF WOODSTOCK',
  'MONTESSORI SCHOOL OF YAKIMA',
  'MONTESSORI SCHOOL PENINSULA',
  'MONTESSORI SCHOOL-CORPUS CHRISTI',
  'MONTESSORI SCHOOL-EAST RUTHERFORD',
  'MONTESSORI SCHOOL-HOLMES RUN',
  'MONTESSORI SCHOOLHOUSE',
  'MONTESSORI SCHOOLS OF CENTRAL TEXAS(TEMPLE CAMPUS)',
  'MONTESSORI SCHOOLS OF SNOHOMISH COUNTY',
  'MONTESSORI SCIENCE ACADEMY',
  'MONTESSORI SEEDS OF EDUCATION',
  'MONTESSORI STEPPING STONES',
  'MONTESSORI SUNDBORN CHILDRENS HOUSE',
  'MONTESSORI VISIONS ACADEMY',
  'MONTESSORI WEAVER SCHOOL',
  'MONTESSORI WEST CHRISTIAN SCHOOL',
  'MONTESSORI WORLD SCHOOL',
  'MONTEVISTA ACADEMY',
  'MONTEZUMA MENNONITE SCHOOL',
  'MONTFORT ACADEMY',
  'MONTGOMERY ACADEMY',
  'MONTGOMERY BELL ACADEMY',
  'MONTGOMERY CATHOLIC PREPARATORY SCHOOL',
  'MONTGOMERY CHRISTIAN SCHOOL',
  'MONTGOMERY KID CONNECTION',
  'MONTGOMERY MONTESSORI SCHOOL',
  'MONTGOMERY SCHOOL',
  'MONTICELLO ACADEMY',
  'MONTICELLO CHRISTIAN ACADEMY',
  'MONTINI CATHOLIC HIGH SCHOOL',
  'MONTINI CATHOLIC SCHOOL',
  'MONTROSE CHRISTIAN MONTESSORI',
  'MONTROSE SCHOOL',
  'MONTVERDE ACADEMY',
  'MONTVIEW COMMUNITY PRESCHOOL & KINDERGARTEN',
  'MONUMENT CHRISTIAN ACADEMY',
  'MONUMENT HEIGHTS DAY SCHOOL LLC',
  'MONUMENT VALLEY SDA MISSION SCHOOL',
  'MOONRIDGE ACADEMY',
  'MOORELAND HILL SCHOOL',
  'MOORES DAY CARE PREP SCHOOL',
  'MOORESTOWN FRIENDS SCHOOL',
  'MOORESVILLE CHRISTIAN ACADEMY',
  'MOOSEHEART CHILD CITY AND SCHOOL INC',
  'MORAVIAN ACADEMY LOWER SCHOOL',
  'MORAVIAN ACADEMY MIDDLE SCHOOL',
  'MOREAU CATHOLIC HIGH SCHOOL',
  'MOREAU MONTESSORI SCHOOL',
  'MORELAND NOTRE DAME SCHOOL',
  'MORELAND SCHOOL',
  'MORGAN AUTISM CENTER',
  'MORGAN PARK ACADEMY',
  'MORGANTON DAY SCHOOL',
  'MORGANTOWN CHRISTIAN ACADEMY',
  'MORGANTOWN LEARNING ACADEMY',
  'MORGANTOWN MONTESSORI',
  'MORNING SON CHRISTIAN SCHOOL',
  'MORNING STAR ACADEMY',
  'MORNING STAR CATHOLIC SCHOOL TAMPA',
  'MORNING STAR LUTHERAN SCHOOL',
  'MORNING STAR MENNONITE SCHOOL',
  'MORNING STAR SCHOOL',
  'MORNING STARS LEARNING PROGRAM',
  'MORNINGSIDE ACADEMY',
  'MORNINGSIDE MONTESSORI SCHOOL',
  'MORNINGSTAR CHINESE SCHOOL',
  'MORNINGSTAR MONTESSORI HOUSE OF CHILDREN',
  'MORRIS CATHOLIC HIGH SCHOOL',
  'MORRIS COUNTY EDUCARE & ENRICHMENT CENTER',
  'MORRIS WILSON-YOUTH VILLAGES',
  'MORRISON SCHOOL',
  'MORRISON ZION LUTHERAN SCHOOL',
  'MORRISTOWN COVENANT ACADEMY',
  'MORROW VALLEY CHRISTIAN ACADEMY',
  'MORVIN REIFF',
  'MOSAIC ACADEMY',
  'MOSAIC MONTESSORI ACADEMY',
  'MOSDOS CHASIDEI SQUARE',
  'MOSDOS KRULA',
  "MOSDOS OHR HATORAH GIRLS' DIVISION",
  'MOSDOS SANZ OF MONSEY',
  'MOSES LAKE CHRISTIAN ACADEMY',
  'MOST BLESSED SACRAMENT CATHOLIC SCHOOL',
  'MOST BLESSED SACRAMENT SCHOOL',
  'MOST HOLY REDEEMER CATHOLIC',
  'MOST HOLY REDEEMER ELEMENTARY SCHOOL',
  'MOST HOLY REDEEMER SCHOOL',
  'MOST HOLY ROSARY SCHOOL',
  'MOST HOLY TRINITY SCHOOL',
  'MOST PRECIOUS BLOOD',
  'MOST PRECIOUS BLOOD CATHOLIC SCHOOL',
  'MOST PRECIOUS BLOOD SCHOOL',
  'MOST PURE HEART OF MARY SCHOOL',
  'MOST RERE HEART OF MARY CATHOLIC SCHOOL',
  'MOTHER CATHERINE ACADEMY',
  'MOTHER FRANCISKA ELEMENTARY SCHOOL',
  'MOTHER GOOSE CHRISTIAN SCHOOL',
  'MOTHER GOOSE KINDERGARTEN',
  'MOTHER GOOSE LAND KINDERGARTEN',
  'MOTHER LODE ADVENTIST JR ACADEMY',
  'MOTHER LODE CHRISTIAN SCHOOL',
  'MOTHER MARIA A. BRUNNER',
  'MOTHER MCAULEY LIBERAL ARTS HIGH SCHOOL',
  'MOTHER OF DEVINE GRACE SCHOOL',
  'MOTHER OF DIVINE GRACE',
  'MOTHER OF GOD SCHOOL',
  'MOTHER OF GOOD COUNSEL',
  'MOTHER OF OUR REDEEMER CATHOLIC SCHOOL',
  'MOTHER OF SORROWS SCHOOL',
  'MOTHER SETON ACADEMY',
  'MOTHER SETON REGIONAL HIGH SCHOOL',
  'MOTHER TERESA ACADEMY',
  'MOTHER TERESA CATHOLIC ACADEMY',
  'MOTHER TERESA CATHOLIC ELEMENTARY',
  'MOTHER TERESA OF CALCUTTA',
  'MOTHER TERESA REGIONAL CATHOLIC SCHOOL',
  'MOUNDROCK WALDOLF SCHOOL',
  'MOUNDS PARK ACADEMY',
  'MOUNT',
  'MOUNT ALVERNIA ACADEMY',
  'MOUNT AVIAT ACADEMY',
  'MOUNT CALVARY ACADEMY',
  'MOUNT CALVARY CHRISTIAN SCHOOL',
  'MOUNT CALVARY LUTHERAN SCHOOL',
  'MOUNT CARMEL ACADEMY',
  'MOUNT CARMEL GUILD ACADEMY',
  'MOUNT CARMEL HIGH SCHOOL',
  'MOUNT CARMEL SCHOOL',
  'MOUNT CARMEL-HOLY ROSARY SCHOOL',
  'MOUNT CLARE CHRISTIAN SCHOOL',
  'MOUNT DE SALES ACADEMY',
  'MOUNT ELLIS ACADEMY',
  'MOUNT HEBRON CHRISTIAN ACADEMY',
  'MOUNT HOPE LUTHERAN SCHOOL',
  'MOUNT JEZREEL CHRISTIAN SCHOOL',
  'MOUNT LEBANON MONTESSORI',
  'MOUNT MADONNA SCHOOL',
  'MOUNT MERCY ACADEMY',
  'MOUNT MERICI ACADEMY',
  'MOUNT MICHAEL BENEDICTINE HIGH SCHOOL',
  'MOUNT MORIAH CHRISTIAN SCHOOL',
  'MOUNT NOTRE DAME HIGH SCHOOL',
  'MOUNT OLIVE EARLY LEARNING CENTER',
  'MOUNT OLIVE LUTHERAN SCHOOL',
  'MOUNT OLIVET CHRISTIAN SCHOOL',
  'MOUNT OLIVET SEVENTH-DAY ADVENTIST SCHOOL',
  'MOUNT OLYMPUS CHRISTIAN CHILD DEVELOPMENT',
  'MOUNT PARAN CHRISTIAN SCHOOL',
  'MOUNT PISGAH CHRISTIAN ACADEMY',
  'MOUNT PISGAH CHRISTIAN SCHOOL',
  'MOUNT PISQAH ACADEMY',
  'MOUNT PLEASANT ACADEMY',
  'MOUNT PLEASANT BAPTIST ACADEMY',
  'MOUNT PLEASANT CHRISTIAN SCHOOL',
  'MOUNT PROSPECT ACADEMY',
  'MOUNT PROVIDENCE CHILD DEVELOPMENT CENTER',
  'MOUNT ROYAL ACADEMY',
  'MOUNT SACRED HEART SCHOOL',
  'MOUNT SAINT DOMINIC ACADEMY',
  'MOUNT SAINT JOSEPH HIGH SCHOOL',
  'MOUNT SAINT MARY HIGH SCHOOL',
  'MOUNT ST CHARLES ACADEMY',
  'MOUNT ST JOSEPH ACADEMY',
  'MOUNT ST MARYS ACADEMY',
  'MOUNT ST MICHAEL ACADEMY',
  'MOUNT ST MICHAEL CATHOLIC SCHOOL',
  'MOUNT ST. MARY ACADEMY',
  'MOUNT TAMALPAIS SCHOOL',
  'MOUNT TOM DAY SCHOOL',
  'MOUNT VERNON ACADEMY',
  'MOUNT VERNON CHRISTIAN SCHOOL',
  'MOUNT VERNON SDA ELEMENTARY SCHOOL',
  'MOUNT ZION CHRISTIAN ACADEMY',
  'MOUNT ZION CHRISTIAN SCHOOL',
  'MOUNT ZION CHRISTIAN SCHOOLS',
  'MOUNT ZION TEMPLE',
  'MOUNTAIN AREA CHRISTIAN ACADEMY',
  'MOUNTAIN BLVD MONTESSORI SCHOOL',
  'MOUNTAIN CHRISTIAN SCHOOL',
  'MOUNTAIN GROVE CHRISTIAN ACADEMY',
  'MOUNTAIN HEIGHTS CHRISTIAN SCHOOL',
  'MOUNTAIN HEIGHTS MONTESSORI',
  'MOUNTAIN HOME CHRISTIAN ACADEMY',
  'MOUNTAIN ISLAND DAY SCHOOL',
  'MOUNTAIN LAKE ACADEMY',
  'MOUNTAIN LAKE CHRISTIAN SCHOOL',
  'MOUNTAIN LAUREL MONTESSORI SCHOOL',
  'MOUNTAIN LAUREL SUDBURY SCHOOL',
  'MOUNTAIN LAUREL WALDORF SCHOOL',
  'MOUNTAIN MISSION SCHOOL',
  'MOUNTAIN PATHWAYS SCHOOL',
  'MOUNTAIN PEAK PRIVATE SCHOOL',
  'MOUNTAIN ROAD CHRISTIAN ACADEMY',
  'MOUNTAIN ROAD SCHOOL',
  'MOUNTAIN SCHOOL AT WINHALL',
  'MOUNTAIN SHADOWS MONTESSORI SCHOOL',
  'MOUNTAIN SHADOWS SCHOOL',
  'MOUNTAIN SIDE SCHOOL',
  'MOUNTAIN SPRINGS PREPARATORY ACADEMY',
  'MOUNTAIN STATE CHRISTIAN SCHOOL',
  "MOUNTAIN STATES CHILDREN'S HOME",
  'MOUNTAIN SUN COMMUNITY SCHOOL',
  'MOUNTAIN TOP CHRISTIAN ACADEMY',
  'MOUNTAIN TOP SCHOOL',
  'MOUNTAIN VALLEY CHILD AND FAMILY SERVICES',
  'MOUNTAIN VALLEY CHRISTIAN ACADEMY',
  'MOUNTAIN VIEW ADVENTIST SCHOOL',
  'MOUNTAIN VIEW AMISH SCHOOL',
  'MOUNTAIN VIEW BAPTIST ACADEMY',
  'MOUNTAIN VIEW BAPTIST SCHOOL',
  'MOUNTAIN VIEW CHRISTIAN ACADEMY',
  'MOUNTAIN VIEW CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'MOUNTAIN VIEW CHRISTIAN SCHOOL',
  'MOUNTAIN VIEW CHRISTIAN SCHOOLS',
  'MOUNTAIN VIEW MENNONITE SCHOOL',
  'MOUNTAIN VIEW MONTESSORI',
  'MOUNTAIN VIEW MONTESSORI SCHOOL',
  'MOUNTAIN VIEW PAROCHIAL SCHOOL',
  'MOUNTAIN VIEW PRIVATE SCHOOL',
  'MOUNTAIN VIEW SCHOOL',
  'MOUNTAIN VIEW-LOS ALTOS MONTESSORI CHILDRENS CTR',
  'MOUNTAIN YOUTH ACADEMY',
  'MOUNTAINEER MONTESSORI SCHOOL',
  'MOUNTAINEERS SCHOOL OF AUTISM INC',
  'MOUNTAINSIDE CHRISTIAN ACADEMY',
  'MOUNTAINSIDE MONTESSORI',
  'MOUNTAINTOP MONTESSORI SCHOOL',
  'MOUNTVIEW CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'MOWERSVILLE CHRISTIAN ACADEMY',
  "MR. J'S ACADEMY",
  "MRS ALEXANDER'S SCHOOL",
  'MRS RONDAS MONTESSORI SCHOOL',
  'MS.',
  'MSE/MHC-COLLABORATIVE SCHOOL',
  'MSGR GADOURY PRIMARY SCHOOL',
  'MSGR SCANLAN HIGH SCHOOL',
  'MT AETNA ADVENTIST SCHOOL (MAAS)',
  'MT AETNA MENNONITE SCHOOL',
  'MT AIRY CHRISTIAN DAY SCHOOL',
  'MT ALVERNIA HIGH SCHOOL',
  'MT CALVARY CHRISTIAN ACADEMY',
  'MT CALVARY LUTHERAN EARLY EDUCATION CENTER',
  'MT CALVARY LUTHERAN SCHOOL',
  'MT CALVARY SDA JUNIOR ACADEMY',
  'MT CALVARY-GRACE LUTHERAN SCHOOL',
  'MT CARMEL CHRISTIAN ACADEMY',
  'MT ELLIS ELEMENTARY',
  'MT FRANKLIN CHRISTIAN ACADEMY',
  'MT HELIX ACADEMY - TIEE',
  'MT HOPE MONTESSORI SCHOOL',
  'MT JULIET CHRISTIAN ACADEMY',
  'MT JULIET MONTESSORI ACADEMY',
  'MT LEBANON EVANGELICAL LUTHERAN SCHOOL',
  'MT LEVANON SCHOOL',
  'MT MORIAH ACADEMY',
  'MT MORIAH CHRISTIAN FUNDAMENTAL ACADEMY',
  'MT OLIVE CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'MT OLIVE CHRISTIAN SCHOOL',
  'MT OLIVE LUTHERAN SCHOOL',
  'MT PILGRIM CHRISTIAN ACADEMY',
  'MT PLEASANT AMISH SCHOOL',
  'MT PLEASANT CHRISTIAN ACADEMY',
  'MT PLEASANT CHRISTIAN SCHOOL',
  'MT PLEASANT PRESBYTERIAN CHURCH LEARNING CENTER',
  'MT PLEASANT VIEW AMISH SCHOOL',
  'MT ROCK PAROCHIAL SCHOOL',
  'MT SALUS CHRISTIAN SCHOOL',
  'MT SCOTT PARK CENTER FOR LEARNING',
  'MT SHEBA CHRISTAN ACADEMY',
  'MT SINAI JR ACADEMY',
  'MT ST MARY ACADEMY',
  'MT TABOR CHRISTIAN SCHOOL',
  'MT VERNON CHRISTIAN ACADEMY',
  'MT VERNON PRESBYTERIAN SCHOOL',
  'MT WASHINGTON UMC CHILD ENRICHMENT CENTER',
  'MT ZION BIBLE SCHOOL',
  'MT. HOPE CHRISTIAN ACADEMY',
  'MT. OLIVE CHILD CARE AND LEARNING CENTER',
  'MT. PLEASANT CHRISTIAN ACADEMY',
  'MTI College',
  'MTTA - MASTERS TRAINING ACADEMY',
  'MUD CREEK CHRISTIAN SCHOOL',
  'MUD LAKE SCHOOL',
  'MUDDY CREEK CHRISTIAN SCHOOL',
  'MUDDY LANE SCHOOL',
  'MUDDY RUN AMISH SCHOOL',
  'MUHLENBURG CHRISTIAN ACADEMY',
  'MUIR WOOD LEARNING CENTER',
  'MUKILTEO ACADEMY',
  'MUKILTEO MONTESSORI SCHOOL',
  'MUKWONAGO BAPTIST ACADEMY',
  'MULBERRY CHRISTIAN ACADEMY',
  'MULBERRY SCHOOL',
  'MULBERRY TREE PRESCHOOL',
  'MULLEIN HILL CHRISTIAN ACADEMY',
  'MULLEN HIGH SCHOOL',
  'MULLET RUN',
  'MULLINS MARION CHRISTIAN SCHOOL',
  'MUMFORD RD',
  'MUNDELEIN KINDERCARE',
  'MUNDO MONTESSORI',
  'MUNISING BAPTIST SCHOOL',
  'MUNSEY PRESCHOOL & KINDERGARTEN',
  'MURL-WINDSOR ACADEMY AT HOLLYWOOD',
  'MURPHY ADVENTIST CHRISTIAN SCHOOL',
  'MURRIETA RANCH PRESCHOOL',
  'MUSCODA CHRISTIAN DAY SCHOOL',
  'MUSE SCHOOL CA',
  'MUSKEGON CATHOLIC CENTRAL',
  'MUSKEGON CHRISTIAN SCHOOL',
  'MUSKOGEE SEVENTH-DAY ADVENTIST CHRISTIAN ACADEMY',
  'MUSLIM ACADEMY',
  'MUSLIM ACADEMY OF GREATER ORLANDO',
  'MUSLIM AMERICAN YOUTH ACADEMY',
  'MUSLIM CENTER JUNIOR HIGH SCHOOL',
  'MUSTARD SEED KIDZ',
  'MUSTARD SEED SCHOOL',
  'MUSTO HOLLOW AMISH SCHOOL',
  'MVP DIAMOND ACADEMY',
  'MY 1ST COLLEGE LC',
  'MY DREAMS ACADEMY',
  'MY FIRST MONTESSORI',
  'MY HAPPY HOUSE',
  'MY LITTLE WORLD',
  'MY SCHOOL',
  'MY SCHOOL-CATHEDRAL OF FAITH LEARNING CENTER',
  'MY WORLD LEARNING CENTER',
  'MYERS SCHOOL',
  'MYRTLE BEACH SDA CHRISTIAN SCHOOL',
  'MYRTLE FARM MONTESSORI SCHOOL',
  'MYRTLE GROVE CHRISTIAN SCHOOL',
  'Ma Academy For Math And Science School',
  'Maac Community Charter',
  'Maakhir University',
  'Maasai Mara University',
  'Maastricht University',
  'Mabank Daep',
  'Mabank H S',
  'Mabank Int',
  'Mabank J H',
  'Mabel Brasher Elementary School',
  'Mabel Elementary',
  'Mabel G. Homes School No. 5',
  'Mabel Hoggard Elementary School',
  'Mabel I Wilson School',
  'Mabel K Holland Elementary School',
  'Mabel L. Pendleton Elementary',
  'Mabel M. Paine Elementary',
  'Mabel O Donnell Elem School',
  'Mabel Padgett Elementary School',
  'Mabel Rush Elementary School',
  'Mabel-canton Elementary',
  'Mabel-canton Secondary',
  'Mabelle B. Avery Middle School',
  'Mabelle M Burrell',
  'Mabelvale Elementary School',
  'Mabelvale Middle School',
  'Mable Barron',
  'Mable Woolsey Elem School',
  'Mableton Elementary School',
  'Mabry Elementary School',
  'Mabry Middle School',
  'Mabscott Elementary',
  'Mabton Jr. Sr. High',
  'Mabton Step Up To College',
  'Mac Arthur Elementary',
  'Mac Farland Intermediate',
  'Mac Williams Middle',
  'MacCormac College',
  'MacEwan University',
  'Macafee Road School',
  'Macalester College',
  'Macaria Dela Garza Gorena El',
  'Macario Garcia Middle',
  'Macarthur Area Int',
  'Macarthur Early Childhood Center',
  'Macarthur El',
  'Macarthur El-int',
  'Macarthur Elem',
  'Macarthur Elementary',
  'Macarthur Elementary School',
  'Macarthur Es',
  'Macarthur H S',
  'Macarthur High School',
  'Macarthur Hs',
  'Macarthur Intrntl Spanish Acad',
  'Macarthur K8 University Academy',
  'Macarthur Middle',
  'Macarthur Middle School',
  'Macarthur Ms',
  'Macarthur Ninth Grade School',
  'Macarthur Park Elem Sch For The Visual And Perf Arts',
  'Macarthur Primary And Early Education Center',
  'Macarthur School',
  'Macarthur/echo Elementary',
  'Macatawa Bay Middle School',
  'Macau Polytechnic Institute',
  'Macclenny Elementary School',
  'Maccormick Secure Center',
  'Maccray Area Learning Program',
  'Maccray East Elementary',
  'Maccray Senior High',
  'Maccray West Elementary',
  'Macdonald Middle School',
  'Macdonell El',
  'Macdonough School',
  'Macdougall High',
  'Macdougall/walker Correctional Institution',
  'Macdowell Montessori School K3-12',
  'Macdowell Preparatory Academy',
  "Mace's Lane Middle School",
  'Macedonia Elementary',
  'Macedonia Elementary School',
  'Macedonia Middle',
  'Macedonia School',
  'Macfarland Ms',
  'Macfarlane Park Elementary Magnet School',
  'Macgowan School',
  'Macgregor El',
  'Macgregor Elementary School',
  'Macgregor High (continuation)',
  'Machado Elementary',
  'Machananao Elementary School',
  'Machesney Elem School',
  'Machias Elementary',
  'Machias Memorial High School',
  'Macintyre Park Middle School',
  'Mack Benn Jr. Elementary',
  'Mack Lyon Middle School',
  'Mackay Elementary School',
  'Mackay Jr/sr High School',
  'Mackeben Elementary School',
  'Mackensen Elementary School',
  'Mackenzie Elementarymiddle School',
  'Mackenzie Middle',
  'Mackey El',
  'Mackinac Island School',
  'Mackinaw City K12 School',
  'Mackinaw High School',
  'Mackinaw Trail Middle School',
  'Macks Creek Elem.',
  'Macks Creek High',
  'Macksville Elem',
  'Macksville High',
  'Maclary (r. Elisabeth) Elementary School',
  'Macmillan International At Mckee',
  'Macnaughton School',
  'Macomb Academy',
  'Macomb Academy Of Arts Sciences',
  'Macomb Community College',
  'Macomb Correctional Facility',
  'Macomb County Juvenile Justice Center School',
  'Macomb Es',
  'Macomb Hs',
  'Macomb Junior High School',
  'Macomb Math Science Technology Center',
  'Macomb Mipp Center',
  'Macomb Montessori Academy',
  'Macomb Senior High School',
  'Macon Area Voc. Sch.',
  'Macon Co. Elem.',
  'Macon Co. High',
  'Macon County Area Vocational School',
  'Macon County Elementary School',
  'Macon County High School',
  'Macon County Junior High School',
  'Macon County Middle School',
  'Macon Early College High School',
  'Macon Elem.',
  'Macon Middle',
  'Macon Middle School',
  'Macon Regional Youth Detention Center',
  'Macon Sr. High',
  'Macon Youth Development Campus',
  'Macon-hall Elementary',
  'Maconaquah Elementary School',
  'Maconaquah High School',
  'Maconaquah Middle School',
  'Macopin Middle School',
  'Macquarie University',
  'Macungie El Sch',
  'Macy Elementary',
  'Macy Intermediate',
  'Macy Mcclaugherty Elementary/middle',
  'Mad River Early Childhood Center',
  'Mad River Middle School',
  'Madame Luchetti',
  'Madawalabu University',
  'Madawaska Elementary School',
  'Madawaska Middle/high School',
  'Maddock Elementary School',
  'Maddock High School',
  'Maddox Elementary School',
  'Maddox Intermediate School',
  'Maddux Elementary School',
  'Maddux-lang Primary School',
  'Madeira Beach Fundamental K-8',
  'Madeira Elementary School',
  'Madeira High School',
  'Madeira Middle School',
  'Madeleine Dugger Andrews',
  'Madeley Ranch El',
  'Madelia Elementary',
  'Madelia Secondary',
  'Madeline English School',
  'Madeline M Breckinridge Elem School',
  'Madenat Alelem University College',
  'Madera County Independent Academy',
  'Madera Elementary',
  'Madera High',
  'Madera South High',
  'Madero Middle School',
  'Mades-johnstone Center',
  'Mades-johnstone Rssp',
  'Madge T. James Kind. Ctr.',
  'Madhya Pradesh Bhoj (Open) University',
  'Madiba Prep Middle School',
  'Madie Ives K-8 Preparatory Academy',
  'Madill Es',
  'Madill Hs',
  'Madill Ms',
  'Madill School',
  'Mading El',
  'Madison #1 Elementary School',
  'Madison (james) Elementary',
  'Madison 2-3 Center - 19',
  'Madison Academic Magnet High School',
  'Madison Academy Elementarymiddle School',
  'Madison Academy High School',
  'Madison Alternative Center',
  'Madison Area Memorial H S',
  'Madison Area Technical College',
  'Madison At Chancellor South',
  'Madison Avenue Elementary',
  'Madison Avenue Elementary School',
  'Madison Avenue Lower Elementary',
  'Madison Avenue School Of Arts',
  'Madison Avenue Upper Elementary',
  'Madison Camelview Elementary',
  'Madison Central High School',
  'Madison Central School',
  'Madison Co Pre School',
  'Madison Co Voc Complex',
  'Madison Co. Alternative School',
  'Madison Community High',
  'Madison Consolidated High School',
  'Madison Consolidated Jr High Sch',
  'Madison County Adult Center',
  'Madison County Alternative School',
  'Madison County Area Technology Center',
  'Madison County Career Technical Center',
  'Madison County Central School',
  'Madison County Day Treatment',
  'Madison County Elementary School',
  'Madison County Excel Alternative Education Center',
  'Madison County High',
  'Madison County High School',
  'Madison County Middle School',
  'Madison Creative Arts Academy Inc',
  'Madison Creek Elementary',
  'Madison Cross Roads Elementary School',
  'Madison Crossing Elementary',
  'Madison District Early Childhood Education Center',
  'Madison Early Childhood Center',
  'Madison Early Childhood School',
  'Madison Early College High',
  'Madison El',
  'Madison Elem',
  'Madison Elem School',
  'Madison Elem.',
  'Madison Elementary',
  'Madison Elementary - 07',
  'Madison Elementary School',
  'Madison Es',
  'Madison H S',
  'Madison Headstart',
  'Madison Heights Elementary',
  'Madison Heights Elementary School',
  'Madison High',
  'Madison High School',
  'Madison High School - 01',
  'Madison Highland Prep',
  'Madison James Foster Elementary School',
  'Madison Jr High School',
  'Madison Junior High',
  'Madison Junior High School',
  'Madison Junior School',
  'Madison Juvenile Correctional',
  'Madison Kindergarten Academy',
  'Madison Leadership Academy',
  'Madison Meadows School',
  'Madison Middle',
  'Madison Middle School',
  'Madison Middle School - 02',
  'Madison Monroe School No. 16',
  'Madison Ms',
  'Madison Park Academy 6-12',
  'Madison Park Academy Tk-5',
  'Madison Park Elem School',
  'Madison Park Elementary',
  'Madison Park Elementary School',
  'Madison Park High',
  'Madison Park School',
  'Madison Place Elementary',
  'Madison Pre-k',
  'Madison Preparatory Academy',
  'Madison Preparatory High School',
  'Madison Preschool',
  'Madison Primary',
  'Madison Richard Simis School',
  'Madison Rose Lane School',
  'Madison S.t.e.a.m. Academy',
  'Madison School',
  'Madison Senior High School',
  'Madison South Elementary School',
  'Madison Southern High School',
  'Madison Station Elementary School',
  'Madison Street Academy Of Visual And Performing Arts',
  'Madison Student Support Center',
  'Madison Tap Program',
  'Madison Traditional Academy',
  'Madison Virtual Academy',
  'Madison Virtual Franchise',
  'Madison Virtual Instruction Program',
  "Madison's Trust Elementary",
  'Madison-grant Jr./sr. High School',
  'Madison-marietta-nassau Elementary',
  'Madison-oneida Boces',
  'Madison-plains Elementary School',
  'Madison-plains High School',
  'Madison-plains Intermediate School',
  'Madison-plains Junior High',
  'Madisoncarver Academy',
  'Madisonville Community College',
  'Madisonville El',
  'Madisonville Elementary School',
  'Madisonville H S',
  'Madisonville Health Technology Center',
  'Madisonville Int',
  'Madisonville Intermediate School',
  'Madisonville J H',
  'Madisonville Junior High School',
  'Madisonville Middle School',
  'Madisonville North Hopkins High School',
  'Madisonville Primary',
  'Madisonville Smart Elementary',
  'Madisonville Tech - Hopkinsville',
  'Madisonville Technical College',
  'Madonna University',
  'Madras High School',
  'Madras Middle School',
  'Madras Primary School',
  'Madrid Elementary School',
  'Madrid High School',
  'Madrid Junior High School',
  'Madrid-waddington Elementary School',
  'Madrid-waddington Junior-senior High School',
  'Madrona Elementary',
  'Madrona Elementary School',
  'Madrona Heights Preschool Program',
  'Madrona K-5 School',
  'Madrona Middle',
  'Madrona Nongraded',
  'Madrone Elementary',
  'Madrone High Continuation',
  'Madrone Trail Public Charter School',
  'Madurai Kamaraj University',
  'Mae E Reynolds School',
  'Mae Fah Luang University',
  'Mae Hensley Junior High',
  'Mae Jemison School',
  'Mae Luster Stephens J H',
  'Mae M. Walters Elementary School',
  'Mae Smythe El',
  'Mae Stevens Early Learning Academy',
  'Maedgen El',
  'Maejo University',
  'Maemae Elementary School',
  'Maeola E. Beitzel Elementary',
  'Maercker Elem School',
  'Maeser School',
  'Magadh University',
  'Magazine Elementary School',
  'Magdalena Elementary',
  'Magdalena High',
  'Magdalena Middle',
  'Magee Academy Of Arts & Sciences',
  'Magee El',
  'Magee Elementary',
  'Magee Elementary School',
  'Magee High School',
  'Magee Middle School',
  'Magellan Charter',
  'Magelssen Elementary',
  'Maggie Cordova Elementary School',
  "Maggie L. Walker Governor's School",
  'Magic City Campus High School',
  'Magic Valley High School',
  'Magill Elementary School',
  'Magma Ranch K8 School',
  'Magna School',
  'Magna Vista High',
  'Magness Creek Elementary',
  'Magnet Academy For Cultural Arts',
  'Magnet Cove Elementary School',
  'Magnet Cove High School',
  'Magnet School Of Math Science & Design Tech',
  'Magnet Traditional School',
  'Magnitogorsk State University',
  'Magnolia',
  'Magnolia Avenue Elementary',
  'Magnolia El',
  'Magnolia Elementary',
  'Magnolia Elementary School',
  'Magnolia Grove School',
  'Magnolia H S',
  'Magnolia High',
  'Magnolia High School',
  'Magnolia Int',
  'Magnolia Intermediate',
  'Magnolia J H',
  'Magnolia Jr. High School',
  'Magnolia Junior High',
  'Magnolia Middle',
  'Magnolia Middle School',
  'Magnolia Montessori Academy',
  'Magnolia Montessori For All',
  'Magnolia Park',
  'Magnolia Park Elementary',
  'Magnolia Parkway El',
  'Magnolia School',
  'Magnolia School Of Excellence',
  'Magnolia Science Academy',
  'Magnolia Science Academy 2',
  'Magnolia Science Academy 3',
  'Magnolia Science Academy 4',
  'Magnolia Science Academy 5',
  'Magnolia Science Academy 6',
  'Magnolia Science Academy 7',
  'Magnolia Science Academy Bell',
  'Magnolia Science Academy San Diego',
  'Magnolia Science Academy Santa Ana',
  'Magnolia Trace Elementary School',
  'Magnolia West H S',
  'Magnolia Woods Elementary School',
  'Magoffin County High School',
  'Magoffin Middle',
  'Magothy River Middle',
  'Magrill El',
  'Magruder Elementary',
  'Magsig Middle School',
  'Magueyes',
  'Mahachulalongkorn Buddhist University',
  'Mahaffey El',
  'Mahaffie Elem',
  'Mahala F. Atchison School',
  'Mahalia Jackson Elementary School',
  'Mahamakut Buddhist University',
  'Mahanakorn University of Technology',
  'Mahanay El',
  'Mahanoy Area El Sch',
  'Mahanoy Area Hs',
  'Mahanoy Area Jshs',
  'Mahanoy Area Ms',
  'Mahantongo El Sch',
  'Maharaja Ganga Singh University Bikaner',
  'Maharaja Sayajirao University of Baroda',
  'Maharishi Dayanand University Rohtak (Haryana )',
  'Maharishi University of Management',
  'Maharshi Dayanand Sarswati University Ajmer',
  'Mahasarakham University',
  'Mahatma Gandhi Chitrakut Gramoday University',
  'Mahatma Gandhi Kashi Vidyapith',
  'Mahatma Gandhi University Kerala',
  'Mahatma Jyotiba Phule Rohilkhand University Bareilly',
  'Mahatma K. Gandhi School',
  'Mahatma Phule Agricultural University',
  'Mahidol University',
  'Mahidol University International College',
  'Mahler (theresa S.) Children Center',
  'Mahnomen Area Learning Center',
  'Mahnomen Elementary',
  'Mahnomen Secondary',
  'Mahomet-seymour High School',
  'Mahomet-seymour Jr High School',
  'Mahone Middle',
  'Mahoning Career & Technical',
  'Mahoning County High School',
  'Mahoning El Sch',
  'Mahoning Unlimited Classroom',
  'Mahoning Valley Opportunity Center',
  'Mahopac Falls School',
  'Mahopac High School',
  'Mahopac Middle School',
  'Mahsa University College for Health and Medical Science',
  'Mahtomedi Middle',
  'Mahtomedi Preschool',
  'Mahtomedi Senior High',
  'Mahwah High School',
  'Maiden Choice School',
  'Maiden Elementary',
  'Maiden High',
  'Maiden Middle School',
  'Maidu Elementary',
  'Maidu High Independent Study',
  'Maie Bartlett Heard School',
  'Maie Ellis Elementary',
  'Maili Elementary School',
  'Main Avenue Elementary',
  'Main Dunstable School',
  'Main Elementary',
  'Main Elementary School',
  'Main Preparatory Academy',
  'Main Road School',
  'Main Street Academy',
  'Main Street Alternative School',
  'Main Street Charter Academy',
  'Main Street El Sch',
  'Main Street Elem.',
  'Main Street Elementary',
  'Main Street Elementary School',
  'Main Street High School',
  'Main Street Int',
  'Main Street Middle',
  'Main Street Sch',
  'Main Street School',
  'Main Street School School (4-5)',
  'Maine Academy Of Natural Sciences',
  'Maine Connections Academy',
  'Maine Consolidated School',
  'Maine East High School',
  'Maine Elementary',
  'Maine Memorial School',
  'Maine Prairie High (continuation)',
  'Maine Region Ten Technical High Sch',
  'Maine South High School',
  'Maine Virtual Academy',
  'Maine West High School',
  'Maine-endwell Middle School',
  'Maine-endwell Senior High School',
  'Mainland High School',
  'Mainland Preparatory Academy',
  'Mainland Regional High School',
  'Mains Elementary',
  'Maisd Early On Program',
  'Maisie E. Quinn School',
  'Maitland Middle',
  'Maize Central Elementary',
  'Maize Elementary',
  'Maize Middle School',
  'Maize Road Elementary School',
  'Maize South Elementary',
  'Maize South High School',
  'Maize South Middle School',
  'Maize Sr High',
  'Maize Virtual Preparatory School',
  'Majan University College',
  'Majestic Fields School',
  'Majestic School',
  'Majestic Way Elementary',
  'Majmaah University',
  'Major Cheney El At South Birdville',
  'Major Edwards Elementary',
  'Major General Raymond Murray High',
  'Major General William R Shafter Elementary School',
  'Major George S. Welch Elementary School',
  'Major Lynn Mokler',
  'Major Sheldon Wheeler Elementary School',
  'Major Sheldon Wheeler Middle School',
  'Makaha Elementary School',
  'Makakilo Elementary School',
  'Makalapa Elementary School',
  'Makanlal Chutur Vedi University',
  'Makawao Elementary School',
  'Makefield El Sch',
  'Makerere University',
  'Makhanlal Chaturvedi National University of Journalism and Communication',
  'Making Community Connections Charter School (h)',
  'Making Community Connections Charter School (m)',
  'Making Community Connections Charter School - Monadnock',
  'Making Waves Academy',
  'Malabar Intermediate School',
  'Malabar Street Elementary',
  'Malabon Elementary School',
  'Malad Elementary School',
  'Malad Middle School',
  'Malad Senior High School',
  'Malaga Elementary',
  'Malakoff Alternative Program (map)',
  'Malakoff El',
  'Malakoff H S',
  'Malakoff Middle',
  'Malama Honua Learning Center Pcs',
  'Malaspina University College',
  'Malaviya National Institute of Technology Jaipur',
  'Malayer University',
  'Malaysia University of Science and Technology (MUST)',
  'Malc',
  'Malcolm C. Hursey Elementary',
  'Malcolm Elementary School',
  'Malcolm High School',
  'Malcolm Jr/sr High School',
  'Malcolm L Bell',
  'Malcolm Rector Technical H S',
  'Malcolm S Mackay School',
  'Malcolm White',
  'Malcolm Williams School',
  'Malcolm X Academy',
  'Malcolm X Elementary',
  'Malcolm X Es At Green',
  'Malcolm X Shabazz High School',
  'Malcom Bridge Elementary School',
  'Malcom Bridge Middle School',
  'Malden Early Learning Center',
  'Malden Elementary School',
  'Malden Grade School',
  'Malden High',
  'Malden Lower Elem.',
  'Maldives National University',
  'Maldonado Amelia Elementary School',
  'Malekan Payame Nour University',
  'Malibu Elementary',
  'Malibu High',
  'Malin Elementary School',
  'Mallard Creek Elementary',
  'Mallard Creek High',
  'Mallard Creek Stem Academy',
  'Mallard Elementary School',
  'Malletts Bay School',
  'Malley Drive Elementary School',
  'Mallinckrodt A.b.i. Elem.',
  'Malloch Elementary',
  'Mallory Elem.',
  'Malmborg School',
  'Malmö University College',
  'Malone College',
  'Malone El',
  'Malone Elementary',
  'Malone High School',
  'Malone Middle School',
  'Maloney Interdistrict Magnet School',
  'Malow Junior High School',
  'Malpass Corner Elementary',
  'Malta 6-7-8',
  'Malta Avenue Elementary School',
  'Malta Bend Elem.',
  'Malta Bend High',
  'Malta El',
  'Malta Elementary School',
  'Malta High School',
  'Malta K-5',
  'Maltby Elementary',
  'Maltby Intermediate School',
  'Maltepe University',
  'Malvern Elementary School',
  'Malvern High School',
  'Malvern Junior High School',
  'Malvern Middle School',
  'Malverne Senior High School',
  'Mamaroneck Avenue School',
  'Mamaroneck High School',
  'Mamasani University of Technology',
  'Mambrino School',
  'Mamie Agnes Jones Elementary School',
  'Mamie L. Northcutt Elementary',
  'Mamie Lou Gross Elementary School',
  'Mamie Martin Elementary School',
  'Mamie Towles Elementary School',
  'Mamie Whitesides Elementary',
  'Mammoth Elementary',
  'Mammoth Elementary School',
  'Mammoth Heights Elementary',
  'Mammoth High',
  'Mammoth Middle',
  'Mammoth Spring Elem. School',
  'Mammoth Spring High School',
  'Mamou Elementary School',
  'Mamou High School',
  'Mamoun Private University for Science and Technology',
  'Man Elementary School',
  'Man Middle School',
  'Man Senior High School',
  'Mana Academy Charter School',
  'Management Development Institute of Singapore Tashkent',
  'Management and Science University',
  'Manalapan High School',
  'Manalapan-englishtown Middle School',
  'Manana Elementary School',
  'Manara Academy',
  'Manara Leadership Academy',
  'Manara Stem Academy - Arlington',
  'Manarat International University',
  'Manasquan Elementary School',
  'Manasquan High School',
  'Manassa Elementary School',
  'Manassah E Bradley',
  'Manassas High',
  'Manassas Park Elementary',
  'Manassas Park High',
  'Manassas Park Middle',
  'Manatee Academy K-8',
  'Manatee Bay Elementary School',
  'Manatee Charter School',
  'Manatee Community Action Agency-head Start',
  'Manatee Cove Elementary School',
  'Manatee Elementary School',
  'Manatee High School',
  'Manatee Middle School',
  'Manatee School For The Arts',
  'Manatee School Of Arts/sciences',
  'Manatee Technical College',
  'Manatee Virtual Franchise (etech School Of Manatee)',
  'Manatee Virtual Instruction Program',
  'Manaugh Elementary School',
  'Manavon El Sch',
  'Manawa Elementary',
  'Mance Park Middle',
  'Mancelona Elementary School',
  'Mancelona High School',
  'Mancelona Middle School',
  'Manchester Academic Cs',
  'Manchester Avenue Elementary',
  'Manchester Central High School',
  'Manchester Community College',
  'Manchester Elem School',
  'Manchester Elementary',
  'Manchester Elementary School',
  'Manchester Elementary/middle School',
  'Manchester Essex Regional High School',
  'Manchester Essex Regional Middle School',
  'Manchester Gate',
  'Manchester High',
  'Manchester High School',
  'Manchester Intermediate School',
  'Manchester Jr-sr High School',
  'Manchester Memorial Elementary',
  'Manchester Memorial High School',
  'Manchester Middle',
  'Manchester Middle School',
  'Manchester Middlehigh School',
  'Manchester Park Elementary',
  'Manchester Preschool - Site 2',
  'Manchester Preschool Center',
  'Manchester Regional Academy',
  'Manchester Regional Day School',
  'Manchester School',
  'Manchester School Of Technology (high School)',
  'Manchester Township Elementary School',
  'Manchester Township High School',
  'Manchester Township Middle School',
  'Manchester Valley High',
  'Manchester West High School',
  'Mancos Early Learning Center',
  'Mancos Elementary School',
  'Mancos High School',
  'Mancos Middle School',
  'Mandalay Middle School',
  'Mandalay School',
  'Mandalay Technological University',
  'Mandan High School',
  'Mandan Middle School',
  'Mandaree Day School',
  'Mandaree Elementary School',
  'Mandaree High School',
  'Mandarin High School',
  'Mandarin Immersion Magnet School',
  'Mandarin Lakes K-8 Academy',
  'Mandarin Middle School',
  'Mandarin Oaks Elementary School',
  'Mandela International Magnet (mims)',
  'Manderson Elementary',
  'Mandeville Elementary School',
  'Mandeville High School',
  'Mandeville Junior High School',
  'Mandeville Middle School',
  'Mandhu College',
  'Manes Elem.',
  'Manetuck Elementary School',
  'Manford Williams El',
  'Mangalore University',
  'Mangham Elementary School',
  'Mangham High School',
  'Mangham Junior High School',
  'Mango Elementary',
  'Mango Elementary School',
  'Mangum Elementary',
  'Mangum Hs',
  'Mangum Ms',
  'Manhasset Middle School',
  'Manhasset Secondary School',
  'Manhattan 7-8',
  'Manhattan Academy For Arts And Languages',
  'Manhattan Area Technical College',
  'Manhattan Beach Middle',
  'Manhattan Beach Preschool',
  'Manhattan Bridges High School',
  'Manhattan Business Academy',
  'Manhattan Center For Science & Mathematics',
  'Manhattan Charter School',
  'Manhattan Charter School Ii',
  'Manhattan College',
  'Manhattan Comprehensive Night And Day High School',
  'Manhattan Early College School For Advertising',
  'Manhattan Elem',
  'Manhattan H S',
  'Manhattan High School West/east Campus',
  'Manhattan International High School',
  'Manhattan Jr High School',
  'Manhattan Middle School Of The Arts And Academics',
  'Manhattan Place Elementary',
  'Manhattan School For Career Development',
  'Manhattan Village Academy',
  'Manhattan/hunter Science High School',
  'Manheim Central Ms',
  'Manheim Central Shs',
  'Manheim El Sch',
  'Manheim Twp Hs',
  'Manheim Twp Ms',
  'Manierre Elem School',
  'Manila Central University',
  'Manila Elementary School',
  'Manila High',
  'Manila High School',
  'Manila Middle School',
  'Manila School',
  'Manipal University',
  'Manipur University',
  'Maniscalco Elementary School',
  'Manistee Harbor Academy',
  'Manistee Isd Center Programs',
  'Manistee Middle High School',
  'Manistique Alternative Ed Center',
  'Manistique Middle And High School',
  'Manito Elementary School',
  'Manitoba Elementary',
  'Manitou Park',
  'Manitou Springs Elementary School',
  'Manitou Springs High School',
  'Manitou Springs Middle School',
  'Mankato Area Night School',
  'Mankato Area Summer School',
  'Mankato East Senior High',
  'Mankato West Senior High',
  'Manley Career Academy High School',
  'Manley Elementary',
  'Manlio Silva Elementary',
  'Mann',
  'Mann (horace) Elementary',
  'Mann Elem School',
  'Mann Elem.',
  'Mann Elementary',
  'Mann Elementary School',
  'Mann Es',
  'Mann Magnet Middle School',
  'Mann Middle',
  'Mann Middle School',
  'Mannford Ec Ctr',
  'Mannford Hs',
  'Mannford Lower Es',
  'Mannford Ms',
  'Mannford Upper Es',
  'Mannheim Early Childhood Ctr',
  'Mannheim Middle School',
  'Manning Early Childhood Center',
  'Manning Elementary',
  'Manning Elementary School',
  'Manning High',
  'Manning Junior High',
  'Manning Oaks Elementary School',
  'Manning Options School',
  'Manning Primary',
  'Mannington Middle School',
  'Mannington Township Elementary School',
  'Mannsdale Elementary',
  'Mannsdale Upper Elementary',
  'Mannsville Manor Elementary School',
  'Mannsville Public School',
  'Manoa El Sch',
  'Manoa Elementary School',
  'Manokotak School',
  'Manomet Elementary',
  'Manonmaniam Sundaranar University',
  'Manor College',
  'Manor El',
  'Manor El Sch',
  'Manor Elementary',
  'Manor Elementary School',
  'Manor Excel Academy',
  'Manor H S',
  'Manor Heights Elementary',
  'Manor Hill Elem School',
  'Manor Hill Elem.',
  'Manor Intermediate School',
  'Manor Lake Elementary',
  'Manor Middle',
  'Manor Middle School',
  'Manor New Technology High',
  'Manor New Technology Middle',
  'Manor Oaks William Bowie School',
  'Manor View Elementary',
  'Manor Woods Elementary',
  'Manorhaven Elementary School',
  'Manse Elementary School',
  'Mansfeld Middle Magnet School',
  'Mansfield City Alternative School',
  'Mansfield Elective Academy',
  'Mansfield Elem And High School',
  'Mansfield Elementary School',
  'Mansfield Frontier H S',
  'Mansfield H S',
  'Mansfield High',
  'Mansfield High School',
  'Mansfield Jr. High',
  'Mansfield Jtc School',
  'Mansfield Lake Ridge H S',
  'Mansfield Legacy H S',
  'Mansfield Middle School',
  'Mansfield Senior High School',
  'Mansfield Spanish Immersion School',
  'Mansfield Summit H S',
  'Mansfield Timberview H S',
  'Mansfield Township Elementary',
  'Mansfield Township Elementary School',
  'Mansfield University of Pennsylvania',
  'Mansion Avenue School',
  'Manson Elementary',
  'Manson High School',
  'Manson Mesa High School',
  'Manson Middle School',
  'Manson Northwest Webster Elementary School-barnum',
  'Manson Northwest Webster Junior High/high School',
  'Manson Park School',
  'Manson Youth Institution',
  'Mansoura University',
  'Mantachie Attendance Center',
  'Manteca Community Day (7-12)',
  'Manteca Community Day (k-6)',
  'Manteca High',
  'Manteno Elem School',
  'Manteno High School',
  'Manteno Middle School',
  'Manteo Elementary School',
  'Manteo High School',
  'Manteo Middle School',
  'Manthala George Jr. School',
  'Manti High',
  'Manti School',
  'Manton Consolidated Elem School',
  'Manton Consolidated High School',
  'Manton Consolidated Middle School',
  'Manton Elementary',
  'Mantua Elementary',
  "Manu'a High School",
  'Manual Academy',
  'Manual Arts Senior High',
  'Manual Career Tech. Ctr.',
  'Manual Guajardo Jr El',
  'Manual High School',
  'Manuel A. Barreto',
  'Manuel A. Perez',
  'Manuel A. Salinas Creative Arts Elementary',
  'Manuel Agosto Lebron',
  'Manuel Alonso Diaz Torres',
  'Manuel Boada',
  'Manuel Bou Gali',
  'Manuel Candanedo',
  'Manuel Corchado Y Juarbe',
  'Manuel Cuevas Bacener',
  'Manuel De Vargas Elementary',
  'Manuel Demiguel Elementary School',
  'Manuel Elzaburu Vizcarrondo',
  'Manuel Esqueda Elementary',
  'Manuel F Fernandez Juncos',
  'Manuel F. Cunha Intermediate',
  'Manuel F. Hernandez',
  'Manuel Febres Gonzalez',
  'Manuel Fernandez Juncos',
  'Manuel Garcia Perez (nueva)',
  'Manuel Gonzalez Melo',
  'Manuel Gonzalez Pato',
  'Manuel J Cortez Elementary School',
  'Manuel Jara El',
  'Manuel L. Quezon University',
  'Manuel L. Real Elementary',
  'Manuel Martin Monserrate',
  'Manuel Martinez Davila',
  'Manuel Mendez Liciaga',
  'Manuel Morales Feliciano',
  'Manuel Negron Collazo I',
  'Manuel Ortiz Suya',
  'Manuel Padilla Davila',
  'Manuel Pena Jr. School',
  'Manuel Ramos Hernandez',
  'Manuel S. Enverga University Foundation',
  'Manuel Surillo',
  'Manuel Velilla',
  'Manuela Toro Morice',
  'Manulele Elementary School',
  'Manvel Elementary School',
  'Manvel H S',
  'Manvel J H',
  'Manville High School',
  'Many Elementary School',
  'Many Farms Community School',
  'Many Farms Elementary School',
  'Many Farms High School',
  'Many High School',
  'Many Junior High School',
  'Manz Elementary',
  'Manzanita Community',
  'Manzanita Community Day',
  'Manzanita Elementary',
  'Manzanita Elementary School',
  'Manzanita Middle',
  'Manzanita Montessori Charter',
  'Manzanita Public Charter',
  'Manzanita School',
  'Manzanita Seed Elementary',
  'Manzano High',
  'Manzano Mesa Elementary',
  'Manzano Middle',
  'Manzanola Elementary School',
  'Manzanola Junior-senior High School',
  'Manzo Elementary School',
  'Mapaville School',
  'Maple',
  'Maple Avenue Elementary',
  'Maple Avenue Middle School',
  'Maple Avenue Ms',
  'Maple Avenue School',
  'Maple Creek Elementary',
  'Maple Creek Middle School',
  'Maple Crest Middle School',
  'Maple Dale Elementary',
  'Maple Dale Elementary School',
  'Maple East Elementary School',
  'Maple Elem School',
  'Maple Elementary',
  'Maple Elementary School',
  'Maple Glen El Sch',
  'Maple Glen Elementary',
  'Maple Grove Alternative High School',
  'Maple Grove Elem School',
  'Maple Grove Elem.',
  'Maple Grove Elementary',
  'Maple Grove Elementary School',
  'Maple Grove Junior/senior High School',
  'Maple Grove K-8',
  'Maple Grove Middle School',
  'Maple Grove School',
  'Maple Grove Senior High',
  'Maple Heights High School',
  'Maple High',
  'Maple Hill Elem',
  'Maple Hill Elementary',
  'Maple Hill Elementary School',
  'Maple Hill High School',
  'Maple Hill Middle School',
  'Maple Hill School',
  'Maple Hills Elementary',
  'Maple Intermediate Elementary School',
  'Maple Lake Elementary',
  'Maple Lake Secondary',
  'Maple Lane Elementary School',
  'Maple Lane School',
  'Maple Lawn El',
  'Maple Lawn Elementary',
  'Maple Leaf Elementary School',
  'Maple Manor El/ms',
  'Maple Mountain High',
  'Maple Park Middle',
  'Maple Place Middle School',
  'Maple Point Ms',
  'Maple Primary Center',
  'Maple Public School',
  'Maple Ridge El Sch',
  'Maple Ridge Elementary School',
  'Maple Ridge School',
  'Maple River East Elementary',
  'Maple River Elementary School',
  'Maple River Middle School',
  'Maple River Secondary',
  'Maple River West Elementary',
  'Maple Road Elementary School',
  'Maple School',
  'Maple Shade High School',
  'Maple Street Elementary School',
  'Maple Street Magnet School',
  'Maple Street Magnet School For The Arts',
  'Maple Street School',
  'Maple Tree Elementary',
  'Maple Valley Elementary School',
  'Maple Valley High School',
  'Maple Valley Jrsr High School',
  'Maple Valley Pathways High School',
  'Maple Valley School',
  'Maple Valley-anthon Oto High School',
  'Maple View Middle School',
  'Maple West Elementary School',
  'Maple Wood Elementary School',
  'Maplebrook El',
  'Maplebrook Elem School',
  'Maplecrest Elem.',
  'Maplehurst Elementary School',
  'Maplemere Elementary School',
  'Maples Elementary School',
  'Mapleshade',
  'Maplesville High School',
  'Mapleton Early Childhood Center',
  'Mapleton Early College High School',
  'Mapleton Elementary School',
  'Mapleton Expeditionary School Of The Arts',
  'Mapleton High School',
  'Mapleton Jr/sr High School',
  'Mapleton Junior High',
  'Mapleton Middle School',
  'Mapleton School',
  'Mapleton-union El Sch',
  'Mapletown Jshs',
  'Mapleview El Sch',
  'Mapleview Intermediate',
  'Maplewood',
  'Maplewood Career Center',
  'Maplewood Center',
  'Maplewood El',
  'Maplewood El Sch',
  'Maplewood Elem School',
  'Maplewood Elem.',
  'Maplewood Elementary',
  'Maplewood Elementary School',
  'Maplewood Heights Elementary School',
  'Maplewood High',
  'Maplewood High School',
  'Maplewood Intermediate School',
  'Maplewood Jshs',
  'Maplewood Middle',
  'Maplewood Middle School',
  'Maplewood Parent Coop',
  'Maplewood School',
  'Maplewood-richmond Hgts. High',
  'Maquan Elementary',
  'Maquoketa Community High School',
  'Maquoketa Middle School',
  'Maquoketa Valley Middle School',
  'Maquoketa Valley Senior High School',
  'Mar Athanasios College for Advanced Studies',
  'Mar Lee School',
  'Mar Vista Academy',
  'Mar Vista Elementary',
  'Mar Vista Senior High',
  'Mara Poly-Tech College',
  'Marais Des Cygnes Valley Elem',
  'Marais Des Cygnes Valley High',
  'Marais Des Cygnes Valley Middle',
  'Marana Distance Learning',
  'Marana High School',
  'Marana Middle School',
  'Maranacook Community High Sch',
  'Maranacook Community Middle Sch',
  'Marathon Elementary',
  'Marathon High',
  'Marathon High School',
  'Marathon Independent School Dist',
  'Marathon School',
  'Marathon Venture Academy',
  'Marathwada Agricultural University',
  'Marble Charter School',
  'Marble City Public School',
  'Marble Early Childhood Center',
  'Marble Elementary',
  'Marble Falls El',
  'Marble Falls H S',
  'Marble Falls Middle',
  'Marble Hill High School Of International Studies',
  'Marble School',
  'Marblehead Community Charter Public School',
  'Marblehead Elementary',
  'Marblehead High',
  'Marblehead Veterans Middle School',
  'Marbletown Elementary School',
  'Marbrook Elementary School',
  'Marbury High School',
  'Marbury Middle School',
  'Marbut Elementary School',
  'Marc High',
  'Marc Kahre Elementary School',
  'Marc T. Atkinson Middle School',
  'Marce Becerra Academy',
  'Marcela Garcia Cora',
  'Marceline High',
  'Marceline Middle',
  'Marcelino Canino Canino',
  'Marcelino Rodriguez Roman',
  'Marcell El',
  'Marcella Int',
  'Marcellus Community Education Center',
  'Marcellus Elementary School',
  'Marcellus High School',
  'March El Sch',
  'March Middle',
  'March Mountain High',
  'March Valley',
  'Marchman Technical College Adult',
  'Marcia Buker School',
  'Marcia R Garza',
  'Marcial Sablan Elementary School',
  'Marclay Sch',
  'Marco Antonio Firebaugh High',
  'Marco Forster Middle',
  'Marco Island Academy',
  'Marco Island Charter Middle',
  'Marcola Elementary School',
  'Marcos De Niza High School',
  'Marcum-illinois Union Elementary',
  'Marcus 9th Grade Campus',
  'Marcus A. Milam K-8 Center',
  'Marcus H S',
  'Marcus Hook El Sch',
  'Marcus Newcomb Middle School',
  'Marcus Oldham College',
  'Marcus Whitman Elementary',
  'Marcus Whitman High School',
  'Marcus Whitman Middle School',
  'Marcus Whitman-cowiche Elementary',
  'Marcy El',
  'Marcy Elementary',
  'Marcy Elementary School',
  'Marcy Open Elementary',
  'Mardela Middle & High',
  'Mardin Artuklu University',
  'Mare Island Health And Fitness Academy',
  'Mare Island Technology Academy',
  'Maree Garnett Farring Elementary',
  'Marengo Comm Middle School',
  'Marengo Elementary',
  'Marengo High School',
  'Marengo Ranch Elementary',
  'Marengo Valley Elementary',
  'Marfa Schools',
  'Margaret A Neary',
  'Margaret Allen Middle',
  'Margaret B Henderson El',
  'Margaret B. Pollard Middle',
  'Margaret Beeks Elementary',
  'Margaret Brent Elementary',
  'Margaret Brent Middle',
  'Margaret Brent Regional Center',
  'Margaret Buerkle Middle',
  'Margaret C Clifford School',
  'Margaret C Ells',
  'Margaret Chase Smith Sch - Skowhegan',
  'Margaret Chase Smith Sch -sanford',
  'Margaret Daniels Primary',
  'Margaret Elementary',
  'Margaret G. Scotten Elementary',
  'Margaret Green Junior High School',
  'Margaret Harris Comprehensive School',
  'Margaret Hearne Elementary',
  'Margaret Heath Elementary',
  'Margaret Hedrick Elementary',
  'Margaret I. Robertson School',
  'Margaret J. Kemp',
  'Margaret K. Lewis In Millville',
  'Margaret Keating Elementary',
  'Margaret L Donovan',
  'Margaret L Felty El',
  'Margaret L Vetter',
  'Margaret Landell Elementary',
  'Margaret Leary School',
  'Margaret M. Generali Autism',
  'Margaret M. Generali Elementary School',
  'Margaret M. Pierce Elementary',
  'Margaret Mace Elementary School',
  'Margaret Mead Jhs',
  'Margaret Murphy Kindergarten Center',
  'Margaret Newton Elementary',
  'Margaret Pauline Brown Elementary',
  'Margaret R Brown Elementary School',
  'Margaret Ross El Sch',
  'Margaret S Mcwhirter El',
  'Margaret Scott Elementary School',
  'Margaret Sheehy Elementary',
  'Margaret Taylor Smith El',
  'Margaret W Cuyler Elementary School',
  'Margaret White Elementary',
  'Margaret Willis Elementary',
  'Margaret Yarbrough Elementary School',
  'Margaretta Carey Elementary School',
  'Margaretta Elementary School',
  'Margaretta High School',
  'Margaretville Central School',
  'Margarita Janer Palacios',
  'Margarita Middle',
  'Margarita Muniz Academy',
  'Margarita Rivera De Janer',
  'Margate Elementary School',
  'Margate Middle School',
  'Margetts Elementary School',
  'Margil El',
  'Marguerita Elementary',
  'Marguerite E Peaslee',
  'Marguerite E Small Elementary',
  'Marguerite F. Christian Elementary',
  'Marguerite Hahn Elementary',
  'Marguerite J Lundy El',
  'Marguerite L Mulvey School',
  'Marguerite Montgomery Elementary',
  'Marguerite Poindexter Lamotte Elementary',
  'Marguerite Vann Elem. School',
  'Mari-linn Elementary School',
  'Maria Alicia P Munoz El',
  'Maria C. Huertas (la Prieta)',
  'Maria C. Osorio',
  'Maria Carrillo High',
  'Maria Cruz Buitrago',
  'Maria Curie-Sklodowska University Lublin',
  'Maria Davila Semidey',
  'Maria Dolores Faria',
  'Maria E. Bas De Vazquez',
  'Maria E. Rodriguez',
  'Maria Grzegorzewska College for Special Education',
  'Maria Hastings',
  'Maria I. Dones',
  'Maria J. Corredor Rivera',
  'Maria L Mcdougall',
  'Maria L. Baldwin',
  'Maria L. Jimenez Lopez',
  'Maria Libertad Gomez',
  'Maria Lopez Ponce',
  'Maria Luisa Arcelay',
  'Maria M Simmons De Rivera',
  'Maria Milagros Ortiz Garcia (jacaboa)',
  'Maria Montanez Gomez',
  'Maria Montessori Academy',
  'Maria Montessori Charter Academy',
  'Maria Moreno El',
  'Maria Teresa Pineiro',
  'Maria Teresa Serrano',
  'Maria Ulloa Elementary School',
  'Maria Varisco Rogers Charter School',
  'Maria Vazquez De Umpierre',
  'Maria Weston Chapman Middle School',
  'Mariam Boyd Elementary',
  'Marian A. Peterson Middle',
  'Marian Bergeson Elementary',
  'Marian E. Mckeown Elementary School',
  'Marian Manor El',
  'Mariana Academy',
  'Mariana Bracetti',
  'Mariana Bracetti Academy Cs',
  'Marianna Adult Center',
  'Marianna Avenue Elementary',
  'Marianna High School',
  'Marianna Middle School',
  'Mariano Abril',
  'Mariano Abril Elemental',
  'Mariano Abril Intermedia',
  'Mariano Castro Elementary',
  'Mariano Feliu Balseiro',
  'Mariano Lake Community School',
  'Mariano Marcos State University',
  'Mariano Riera Palmer',
  'Maricopa County Community College District',
  'Maricopa Elementary',
  'Maricopa Elementary School',
  'Maricopa High',
  'Maricopa High School',
  'Maricopa Institute Of Technology',
  'Maricopa Middle',
  'Maricopa Wells Middle School',
  'Marie B. Riviere Elementary School',
  'Marie C Graham Elem School',
  'Marie Curie High School-nursing',
  'Marie Curie Institute Of Engineering And Communication',
  'Marie Curie Stem Elementary',
  'Marie Durand Elementary School',
  'Marie G Davis',
  'Marie Gatton Phillips Elementary',
  'Marie H Katzenbach Elementary/ Middle School For The Deaf',
  'Marie H Katzenbach High School',
  'Marie L. Greenwood Academy',
  'Marie L. Hare High',
  'Marie M Hughes Elementary',
  'Marie Murphy School',
  'Marie Reed Es',
  'Marie Roberts-caney Elementary School',
  'Marie Schaefer Elem School',
  'Marie V. Duffy Elementary School',
  'Mariemont Elementary',
  'Mariemont Elementary School',
  'Mariemont High School',
  'Mariemont Junior High School',
  'Maries Co. Middle',
  'Marietta Center For Advanced Academics',
  'Marietta College',
  'Marietta Elementary School',
  'Marietta Es',
  'Marietta High School',
  'Marietta Hs',
  'Marietta Middle School',
  'Marietta Ms',
  'Marietta Regional Youth Detention Center',
  'Marietta Sixth Grade Academy',
  'Marieville School',
  'Marigny Elementary School',
  'Marigold Elementary',
  'Marij State Technical University',
  'Marij State University',
  'Marilla Primary School',
  'Marilyn Miller Elementary',
  'Marin Abajo',
  'Marin County Juvenile Court',
  'Marin County Rop',
  'Marin County Special Education',
  'Marin Elementary',
  'Marin Oaks High',
  "Marin's Community",
  'Marina Childrens Center',
  'Marina Del Rey Middle',
  'Marina High',
  'Marina Middle',
  'Marina Village Middle',
  'Marina Vista Elementary',
  'Marina West Elementary',
  'Marine Academy Of Science And Technology',
  'Marine Academy Of Technology And Environmental Science',
  'Marine Area Community School',
  'Marine Biological Laboratory',
  'Marine City High School',
  'Marine City Middle School',
  'Marine Creek Collegiate H S',
  'Marine Creek El',
  'Marine Elem School',
  'Marine Elementary',
  'Marine Leadership Academy - Ames',
  'Marine Math And Science Acad Hs',
  'Marine Sci. & Tech. Early College Hs',
  'Marine Science Education Center',
  'Marine Science Magnet High School Of Southeastern Connectic',
  'Marine View Middle',
  'Mariner High School',
  'Mariner Middle School',
  'Mariners Elementary',
  'Marinette High',
  'Marinette Middle',
  'Mario C & Joanne Monaco Middle School',
  'Mario Drago School No. 3',
  'Mario F. Pagan Irizarry (sta Rosa)',
  'Mario G. Olmos Elementary',
  'Mario Umana Academy',
  'Marion 7-8',
  'Marion Academy',
  'Marion Alternative Center',
  'Marion B Earl Elementary School',
  'Marion C Seltzer Elementary School',
  'Marion C. Early Elem.',
  'Marion C. Early High',
  'Marion C. Early Junior High',
  'Marion C. Moore School',
  'Marion Center Area Jr/sr Hs',
  'Marion Charter School',
  'Marion City Digital Academy',
  'Marion Co High School',
  'Marion Co Voc Complex',
  'Marion Co. Adult & Community Ed. Center',
  'Marion Co. Elem.',
  'Marion Co. High',
  'Marion County Alternative School',
  'Marion County Area Technology Center',
  'Marion County High School',
  'Marion County Knight Academy',
  'Marion County Middle School',
  'Marion County Middle/high School',
  'Marion County Technical Center',
  'Marion D Crouse Instr Center',
  'Marion Donaldson Elementary School',
  'Marion E Cahlan Elementary School',
  'Marion E Zeh',
  'Marion Early Childhood Ed Cntr',
  'Marion El Sch',
  'Marion Elearning',
  'Marion Elem',
  'Marion Elem.',
  'Marion Elementary',
  'Marion Elementary - 02',
  'Marion Elementary School',
  'Marion G Vedder Elementary School',
  'Marion H S',
  'Marion High',
  'Marion High School',
  'Marion High School - 01',
  'Marion Home School',
  'Marion Intermediate',
  'Marion Intermediate School',
  'Marion Jordan Elem School',
  'Marion Jr High School',
  'Marion Junior High School',
  'Marion Junior-senior High School',
  'Marion L Steele High School',
  'Marion Local Elementary School',
  'Marion Local High School',
  'Marion Middle',
  'Marion Middle School',
  'Marion Middle School - 03',
  'Marion Military Institute',
  'Marion Mix Elementary',
  'Marion Oaks Elementary School',
  'Marion P. Thomas Charter School',
  'Marion Park Complex',
  'Marion Reg. Juvenile Detention Center',
  'Marion School',
  'Marion Senior High',
  'Marion Street School',
  'Marion T. Bedwell Elementary School',
  'Marion Technical College',
  'Marion Technical Institute',
  'Marion Virtual Franchise',
  'Marion Virtual High School',
  'Marion Virtual Instruction Program',
  'Marion W. Cross School',
  'Marion Youth Academy',
  'Marion-franklin High School',
  'Marion-sterling Elementary School',
  'Marion-walker El Sch',
  'Marionneaux Elementary School',
  'Marionville Elem.',
  'Marionville High',
  'Marionville Middle',
  'Mariposa Avenue Elementary',
  'Mariposa County High',
  'Mariposa Dual Language Academy Charter School',
  'Mariposa Elementary',
  'Mariposa Elementary School',
  'Mariposa School Of Global Education',
  'Mariposa-nabi Primary Center',
  'Marissa Elem School',
  'Marissa Jr & Sr High School',
  'Marist College',
  'Maritime Academy Cs',
  'Maritime Academy Of Toledo The',
  'Maritime University Constanta',
  'Maritime University in Szczecin',
  'Marjorie Carlson Elementary School',
  'Marjorie French Middle School',
  'Marjorie H. Tobias Elementary',
  'Marjorie Kinnan Rawlings Elem',
  'Marjorie Kinnan Rawlings Elementary School',
  'Marjorie Veeh Elementary',
  'Marjorie W Estes Elementary School',
  'Marjory Stoneman Douglas Elem',
  'Marjory Stoneman Douglas High School',
  'Marjory Vickery El',
  'Mark C Roose Elementary School',
  'Mark Delay School',
  'Mark Denman Elementary School',
  'Mark G Hoyle Elementary',
  'Mark Keppel',
  'Mark Keppel Elementary',
  'Mark Keppel High',
  'Mark L Fine Elementary School',
  'Mark Morris High School',
  'Mark Newbie Elementary School',
  'Mark T. Sheehan High School',
  'Mark Twain',
  'Mark Twain Dual Language Academy',
  'Mark Twain El',
  'Mark Twain Elem',
  'Mark Twain Elem School',
  'Mark Twain Elem.',
  'Mark Twain Elementary',
  'Mark Twain Elementary School',
  'Mark Twain Elementarymiddle School',
  'Mark Twain Es',
  'Mark Twain Is 239 For The Gifted And Talented',
  'Mark Twain Jr. High',
  'Mark Twain Junior High',
  'Mark Twain Leadership Vanguard',
  'Mark Twain Middle',
  'Mark Twain Primary School',
  'Mark Twain Special Center',
  'Mark Twain Sr. High',
  'Mark W Bills Middle School',
  'Mark West Charter',
  'Mark West Elementary',
  'Mark White El',
  'Marked Tree Elementary School',
  'Marked Tree High School',
  'Marked Tree Middle School',
  'Markesan High',
  'Markesan Intermediate',
  'Markesan Middle',
  'Markesan Primary',
  'Market Street Elementary School',
  'Markham El',
  'Markham El Sch',
  'Markham Elementary',
  'Markham Elementary School',
  'Markham Park Elem School',
  'Markham Place',
  'Markham Woods Middle School',
  'Markle Intrmd Sch',
  'Marksville Elementary School',
  'Marksville High School',
  'Marlan J. Walker Elementary',
  'Marlatt Elem',
  'Marlboro Central High School',
  'Marlboro College',
  'Marlboro County High',
  'Marlboro Elementary School',
  'Marlboro High School',
  'Marlboro Memorial Middle School',
  'Marlboro Middle School',
  'Marlboro School Of Discovery',
  'Marlborough El Sch',
  'Marlborough Elementary School',
  'Marlborough High',
  'Marlette Elementary',
  'Marlette Jrsr High School',
  'Marley Elementary',
  'Marley Glen School',
  'Marley Middle',
  'Marley Park Elementary',
  'Marlin Alternative Education Program',
  'Marlin Daep',
  'Marlin Elementary School',
  'Marlin H S',
  'Marlin Junior Academy',
  'Marlin Pri Academy',
  'Marlington High School',
  'Marlington Middle School',
  'Marlinton Elementary School',
  'Marlinton Middle School',
  'Marlow Elementary School',
  'Marlow Es',
  'Marlow Hs',
  'Marlow Ms',
  'Marlowe Elementary School',
  'Marlowe Middle School',
  'Marlton',
  'Marlton Elementary',
  'Marlton Middle',
  'Marmaduke Elementary School',
  'Marmaduke High School',
  'Marmara University',
  'Marmarth Elementary School',
  'Marmaton Valley Elem',
  'Marmaton Valley High',
  'Marmet Elementary School',
  'Marmot School 9-12',
  'Marmot School K-8',
  'Marnie Simons Elementary School',
  'Maroa-forsyth Grade School',
  'Maroa-forsyth Middle School',
  'Maroa-forsyth Senior High Sch',
  'Marple Newtown Shs',
  'Marquand Elem.',
  'Marquand-zion High',
  'Marquardt Middle School',
  'Marquette Branch Prison',
  'Marquette Elem School',
  'Marquette Elementary',
  'Marquette Elementary School',
  'Marquette Elementarymiddle School',
  'Marquette Montessori Academy',
  'Marquette Senior High School',
  'Marquette Sr. High',
  'Marquette University',
  'Marquettealger Administrative Unit',
  'Marquez Charter',
  'Marr/cook Elementary School',
  'Marrama Elementary School',
  'Marrero Academy For Advanced Studies',
  'Marrington Elementary',
  'Marrington Middle School Of The Arts',
  'Marrion Elementary School',
  'Marriotts Ridge High',
  'Marrs Elementary School',
  'Marrs Es',
  'Mars Area Centennial Sch',
  'Mars Area El Sch',
  'Mars Area Ms',
  'Mars Area Primary Ctr',
  'Mars Area Shs',
  'Mars Elementary School',
  'Mars Estates Elementary',
  'Mars Hill Elementary',
  'Marseilles Elementary School',
  'Marsh (harry M.) Junior High',
  'Marsh Avenue School For Expeditionary Learning',
  'Marsh Creek Elementary',
  'Marsh Creek Sixth Grade Center',
  'Marsh Elem School',
  'Marsh Elementary',
  'Marsh Elementary School',
  'Marsh Fork Elementary',
  'Marsh Grammar School',
  'Marsh Middle',
  'Marsh Pointe Elementary',
  'Marsh Valley High School',
  'Marsh Valley Middle School',
  'Marshall (john) Elementary',
  'Marshall (thurgood) Elementary',
  'Marshall (thurgood) Elementary School',
  'Marshall (thurgood) High',
  'Marshall Academy',
  'Marshall Academy Of The Arts',
  'Marshall Alternative School',
  'Marshall C Darnell Elementary',
  'Marshall Charter',
  'Marshall Co High School',
  'Marshall County Central High',
  'Marshall County High School',
  'Marshall County Technical Center',
  'Marshall Durham Middle',
  'Marshall Early Childhood',
  'Marshall Early Learning Center',
  'Marshall Ec Voluntary Prek',
  'Marshall El',
  'Marshall El Sch',
  'Marshall Elementary',
  'Marshall Elementary School',
  'Marshall Es',
  'Marshall Fundamental',
  "Marshall Governor's Stem Academy",
  'Marshall Greene Middle School',
  'Marshall H S',
  'Marshall High',
  'Marshall High School',
  'Marshall High School Academy',
  'Marshall Hill Elementary School',
  'Marshall Intermediate Center',
  'Marshall J H',
  'Marshall John Sch',
  'Marshall Jr High School',
  'Marshall Kendrick Middle',
  'Marshall Lane Elementary',
  'Marshall Learning Center',
  'Marshall Math Science Academy',
  'Marshall Metropolitan High School',
  'Marshall Middle',
  'Marshall Middle School',
  'Marshall Ms',
  'Marshall Opportunity High School',
  'Marshall Pomeroy Elementary',
  'Marshall Primary',
  'Marshall Ranch Elementary School',
  'Marshall Road Elementary',
  'Marshall School',
  'Marshall Simonds Middle',
  'Marshall Sr. High',
  'Marshall Street El Sch',
  'Marshall Street School',
  'Marshall T. Moore Es',
  'Marshall Technical School',
  'Marshall Thurgood',
  'Marshall Thurgood Elementary School',
  'Marshall University',
  'Marshall Virtual',
  'Marshall W. Errickson School',
  'Marshalltown Community College',
  'Marshalltown High School',
  'Marshdale Elementary School',
  'Marshfield High',
  'Marshfield Jr. High',
  'Marshfield K4',
  'Marshfield Middle',
  'Marshfield Senior High School',
  'Marshpoint Elementary School',
  'Marshville Elementary',
  'Marshwood Great Works School',
  'Marshwood High School',
  'Marshwood Middle School',
  'Marsing Elementary School',
  'Marsing High School',
  'Marsing Middle School',
  'Marston Middle',
  'Mart Academic Alernative Program',
  'Mart El',
  'Mart H S',
  'Mart Middle',
  'Marta Sanchez (guayabo)',
  'Marta Valle High School',
  'Marta Velez De Fajardo (especializada)',
  'Martel School',
  'Martell Elementary School',
  'Martensdale Elementary School',
  'Martensdale-st Marys Jr-sr High School',
  'Martha & Josh Morriss Math & Engineering El',
  'Martha B. Day Elementary School',
  'Martha B. King Middle School',
  'Martha Baldwin Elementary',
  'Martha Brown Middle School',
  'Martha Elementary School',
  'Martha Ellen Stilwell School For The Performing Arts',
  'Martha Escutia Primary Center',
  'Martha F Gleason Special Services Center 45-15',
  'Martha Gaskins K5',
  'Martha Hunt El',
  'Martha J Ridpath Elementary School',
  'Martha Jane Potter Elementary School',
  'Martha Jones',
  'Martha K. Glaze Regional Youth Detention Center',
  'Martha Lake Elementary',
  'Martha Layne Collins High School',
  'Martha P King Elementary School',
  'Martha Puckett Middle School',
  'Martha Rawls Smith Elementary School',
  'Martha Reid El',
  'Martha Turner Reilly El',
  'Martha Vinyard Elementary School',
  "Martha's Vineyard Charter School",
  "Martha's Vineyard Regional High",
  'Marthasville Elem.',
  'Marthaville Elementary & Junior High School',
  'Marti El',
  'Martic El Sch',
  'Marticville Ms',
  'Martin Avenue Elementary School',
  'Martin Behrman Charter Acad Of Creative Arts & Sci',
  'Martin Bluff',
  'Martin Boulevard Elementary',
  'Martin Center For The Arts',
  'Martin City Elem.',
  'Martin Community College',
  'Martin County Area Technology Center',
  'Martin County Girls Academy',
  'Martin County Head Start Preschool',
  'Martin County High School',
  'Martin County Jail',
  'Martin County Middle School',
  'Martin County West Junior High',
  'Martin County West Senior High',
  'Martin De Leon El',
  'Martin E Young Elementary',
  'Martin El',
  'Martin Elementary',
  'Martin Elementary - 03',
  'Martin Elementary School',
  'Martin G Atkins Elementary School',
  'Martin G. Brumbaugh',
  'Martin Garcia Giusti',
  'Martin Gonzalez',
  'Martin H S',
  'Martin Hall Detention Ctr',
  'Martin High School',
  'Martin J. Ryerson School',
  'Martin Kellogg Middle School',
  'Martin King Jr Elementary',
  'Martin L King Acad For Excellence Alt',
  'Martin L King Career Technology',
  'Martin L King Elem School',
  'Martin L King Jr Elementary School',
  'Martin L. Olson School',
  'Martin Luther King',
  'Martin Luther King Academy For Boys Elementary School',
  'Martin Luther King Early Childhood Center',
  'Martin Luther King El. School',
  'Martin Luther King Elem School',
  'Martin Luther King Elementary',
  'Martin Luther King Elementary School',
  'Martin Luther King Jr Education Center Academy',
  'Martin Luther King Jr Elementary',
  'Martin Luther King Jr Elementary School',
  'Martin Luther King Jr High School',
  'Martin Luther King Jr Learning Center',
  'Martin Luther King Jr Middle',
  'Martin Luther King Jr School',
  'Martin Luther King Jr.',
  'Martin Luther King Jr. Campus',
  'Martin Luther King Jr. Charter School Of Excellence',
  'Martin Luther King Jr. Early Learning Center',
  'Martin Luther King Jr. Elementary',
  'Martin Luther King Jr. Elementary School',
  'Martin Luther King Jr. High',
  'Martin Luther King Jr. Junior High',
  'Martin Luther King Jr. Middle',
  'Martin Luther King Jr. School',
  'Martin Luther King Jr. School No. 6',
  'Martin Luther King Jr. Technology Academy',
  'Martin Luther King Junior High School',
  'Martin Luther King Leadership Academy',
  'Martin Luther King Middle',
  'Martin Luther King Middle School',
  'Martin Luther King Preparatory High School',
  'Martin Luther King Transition Center',
  'Martin Luther King- Jr. Elementary School',
  'Martin Luther King- Jr. High School',
  'Martin Meylin Ms',
  'Martin Middle',
  'Martin Middle School',
  'Martin Millennium Academy',
  'Martin Murphy Middle',
  'Martin P Mckay Education Center',
  'Martin Park Elementary School',
  'Martin Petitjean Elementary School',
  'Martin Primary',
  'Martin R. Heninger Elementary',
  'Martin Road Elementary School',
  'Martin Sch',
  'Martin School',
  'Martin Sortun Elementary School',
  'Martin T Krueger Middle School',
  'Martin Van Buren Elementary',
  'Martin Van Buren High School',
  'Martin Walker El',
  'Martin Warren Elem.',
  'Martin Weiss El',
  'Martin-Luther Universität Halle-Wittenberg',
  'Martinez C El',
  'Martinez Early Intervention Preschool Program (meipp)',
  'Martinez El',
  'Martinez Elementary School',
  'Martinez Junior High',
  'Martinez Middle School',
  'Martinez R El',
  'Martins Creek Elementary/mid',
  'Martins Ferry High School',
  'Martins Ferry Middle School',
  'Martins Mill El',
  'Martins Mill H S',
  'Martinsburg El Sch',
  'Martinsburg High School',
  'Martinsburg North Middle School',
  'Martinsburg South Middle School',
  'Martinson Elementary',
  'Martinsville Elem School',
  'Martinsville High',
  'Martinsville High School',
  'Martinsville Jr- Sr High School',
  'Martinsville Middle',
  'Martinsville School',
  'Marty Indian School',
  'Martyn Alternative School',
  'Marumsco Hills Elementary',
  'Marva J Daniel Futures Preparatory School',
  'Marvell High School',
  'Marvell Primary School',
  'Marvin A. Dutcher Middle',
  'Marvin B Smith Elementary',
  'Marvin El',
  'Marvin Elem.',
  'Marvin Elementary',
  'Marvin Elementary School',
  'Marvin L Winans Academy Elementary',
  'Marvin L Winans Academy Middle School',
  'Marvin M Sedway Middle School',
  "Marvin Marshall Preschool And Children's Center",
  'Marvin Moss Elementary School',
  'Marvin Picollo School',
  'Marvin Primary School',
  'Marvin Ridge High',
  'Marvin Ridge Middle',
  'Marvin Sisk Middle School',
  'Marvin W Foote Youth Services',
  'Marvin Wright Elementary School',
  'Marvine El Sch',
  'Marvista Elementary',
  'Mary A Otondo Elementary School',
  'Mary A White School',
  'Mary A. Dryden Veterans Memorial School',
  'Mary A. Fisk Elementary School',
  'Mary A. Goetz Elementary School',
  'Mary A. Hubbard Elementary School',
  'Mary Adams Elementary School',
  'Mary Allen El',
  'Mary Ann Sanders Elementary',
  'Mary Ann Winterling Elementary At Bentalou',
  'Mary Austin Holley El',
  'Mary B Austin Elementary School',
  'Mary B Martin School',
  'Mary B. Lewis Elementary',
  'Mary B. Neal Elementary School',
  'Mary B. Perry High',
  'Mary Baldwin College',
  'Mary Beck Elementary School',
  'Mary Bethune Alternative',
  'Mary Bethune Elementary Literature/technology',
  'Mary Bird Early Childhood Education Center',
  'Mary Blair Elementary School',
  'Mary Blount Elementary',
  'Mary Bramlett Elementary',
  'Mary Branch El',
  'Mary Bray Elementary School',
  'Mary Bryan Elementary School',
  'Mary Buren Elementary',
  'Mary Burks Marek El',
  'Mary C Howse El Sch',
  "Mary C O'brien Elementary School",
  'Mary C Williams Elementary',
  'Mary C. Dondero Elementary School',
  'Mary C. Snow West Side Elementary',
  'Mary Calcott Elementary School',
  'Mary Carr Greer Elementary',
  'Mary Casey Black Elementary',
  'Mary Chapa Academy',
  'Mary Collins Charter School At Cherry Valley',
  'Mary D Altavesta',
  'Mary D Lang Kdg Ctr',
  'Mary D Mitchell School',
  'Mary D. Coghill Charter School',
  'Mary Daly Elementary School',
  'Mary Deshazo El',
  'Mary Deterding Elementary',
  'Mary E Dardess Elementary School',
  'Mary E Finn School',
  'Mary E Flaherty School',
  'Mary E Pennock Elementary School',
  'Mary E Post Elementary School',
  'Mary E Smithey Pace H S',
  'Mary E Stapleton Elementary',
  'Mary E. Baker School',
  'Mary E. Fogarty El. School',
  'Mary E. Griswold School',
  'Mary E. Roberts Elementary School',
  'Mary E. Rodman Elementary',
  'Mary E. Silveira Elementary',
  'Mary E. Volz Elementary School',
  'Mary Ellen Henderson Middle',
  'Mary Emma Bailey Elementary School',
  'Mary Endres Elementary School',
  'Mary Esther Elementary School',
  'Mary Ethel Costello School',
  'Mary Evans El',
  'Mary Evelyn Castle Elementary Sch',
  'Mary F. Janvier School',
  'Mary Farmar Elementary',
  'Mary Fay Pendleton Elementary',
  'Mary Feeser Elementary School',
  'Mary Fonseca Elementary School',
  'Mary Ford Elementary',
  'Mary Frank Harris Elementary Sch',
  'Mary G Clarkson School',
  'Mary G Montgomery High School',
  'Mary G. Fritz Elementary School Of Yalesville',
  'Mary G. Porter Traditional',
  'Mary Goff Elementary School',
  'Mary Grett School',
  'Mary H. Wright Elementary',
  'Mary Harper Middle',
  'Mary Harris Int',
  'Mary Helen Berlanga El',
  'Mary Helen Guest Elementary School',
  'Mary Hoge Middle',
  'Mary Hughes School',
  'Mary Ingles Elementary School',
  'Mary J Donohoe Community School',
  'Mary J Tanner Primary School',
  'Mary Jo And William Macdonald Maryhurst',
  'Mary Jo Sheppard El',
  'Mary K Vollmer Elementary School',
  'Mary K. Goode Elementary School',
  'Mary Kay Mcmillin Early Childhood Center',
  'Mary L Evans Early Childhood Center',
  'Mary L Welty Elementary School',
  'Mary L. Tracy School',
  'Mary Lee Burbank',
  'Mary Lee Clark Elementary School',
  'Mary Lillard I S',
  'Mary Lou Cowlishaw Elementary',
  'Mary Lou Dieterich Elementary',
  'Mary Lou Hartman',
  'Mary Lynch Elementary',
  'Mary M Bethune',
  'Mary M Bethune Early Child Dev Ctr',
  'Mary M Lynch',
  'Mary M Walsh',
  'Mary M. Knight School',
  'Mary Mae Jones Elem. School',
  'Mary Matula Elementary School',
  'Mary Mcarthur Elementary',
  'Mary Mcguire School',
  'Mary Mcleod Bethune Day Academy Pcs',
  'Mary Mcleod Bethune El',
  'Mary Mcleod Bethune Elementary',
  'Mary Mcleod Bethune Elementarymiddle School',
  'Mary Mcleod Bethune Middle',
  'Mary Mcleod Bethune Middle School',
  'Mary Mcleod Bethune School',
  'Mary Mcpherson Elementary',
  'Mary Meredith High School',
  'Mary Meredith K-12 School',
  'Mary Miller Junior High School',
  'Mary Morgan Elem Sch',
  'Mary Morrisson School',
  'Mary Moss At Adams Academy',
  'Mary Munford Elementary',
  'Mary O Pottenger',
  'Mary Orr Int',
  'Mary P. Henck Intermediate',
  'Mary Passage Middle',
  'Mary Paxton Keeley Elem.',
  'Mary Peacock Elementary',
  'Mary Persons High School',
  'Mary Potter Middle',
  'Mary Purcell Elementary School',
  'Mary R. Fisher Elementary School',
  'Mary R. Tisko School',
  'Mary Raber Elementary School',
  'Mary Reid School (k-3)',
  'Mary Rowlandson Elementary',
  'Mary Ryan Academy',
  'Mary S. Shoemaker School',
  'Mary Snow School',
  'Mary Stark Elementary School',
  'Mary T. Murphy School',
  'Mary Todd Elementary School',
  'Mary Tsukamoto Elementary',
  'Mary V Wheeler Elementary',
  'Mary W Burroughs Elementary School',
  'Mary W French Academy',
  'Mary W. Jackson School',
  'Mary Walker Alternative High Schl',
  'Mary Walker High School',
  'Mary Walker Promise',
  'Mary Walter Elementary',
  'Mary Washington College',
  'Mary Welsh Elementary',
  'Mary White Es',
  'Mary Williams Elementary',
  'Mary Woodward Elementary School',
  'Marya Yates Elementary School',
  'Maryam Institute of Higher Education',
  'Maryann Binford Elementary',
  'Marycrest Early Childhood Center',
  'Maryetta Public School',
  'Maryland Avenue Elementary',
  'Maryland City Elementary',
  'Maryland Elementary',
  'Maryland Elementary School',
  'Maryland Montessori',
  'Marylin Avenue Elementary',
  'Marymount Manhattan College',
  'Marymount University',
  'Marysville Arts And Technology High School',
  'Marysville Charter Academy For The Arts',
  'Marysville Community Day',
  'Marysville Coop Program',
  'Marysville Elem',
  'Marysville Elementary School',
  'Marysville Getchell High School',
  'Marysville High',
  'Marysville High School',
  'Marysville Jr/sr High',
  'Marysville Middle School',
  'Marysville Mountain View High School',
  'Marysville Nwesd 189 Youth Engagement',
  'Marysville Pilchuck High School',
  'Marysville Sd Special',
  'Marysville Stem Early College High School',
  'Maryvale Elementary',
  'Maryvale Elementary School',
  'Maryvale High School',
  'Maryvale Intermediate School',
  'Maryvale Middle School',
  'Maryvale Primary School',
  'Maryvale School',
  'Maryville Elem School',
  'Maryville Elementary',
  'Maryville Elementary School',
  'Maryville High',
  'Maryville High School',
  'Maryville Junior High School',
  'Maryville Middle',
  'Maryville Treatment Center',
  'Maryville University',
  'Marzolf Primary Sch',
  'Masada Charter School',
  'Masaryk University',
  'Mascenic Regional High School',
  'Mascoma Valley Regional High School',
  'Masconomet Regional High School',
  'Masconomet Regional Middle School',
  'Mascotte Elementary School',
  'Mascoutah Elem School',
  'Mascoutah High School',
  'Mascoutah Middle School',
  'Masdar University Of Science And Technology',
  'Masefau Elementary School',
  'Maseno University',
  'Mash-ka-wisen',
  'Mashburn Elementary School',
  'Mashhad University of Medical Sciences',
  'Mashpee High',
  'Mashpee Middle School',
  'Masinde Muliro University of Science and Technology',
  'Mason',
  'Mason Central',
  'Mason Central School',
  'Mason City High School',
  'Mason Classical Academy',
  'Mason Community Learning Center',
  'Mason County Area Technology Center',
  'Mason County Career Center',
  'Mason County Central Hs',
  'Mason County Central Ms',
  'Mason County Central Upper Elementary',
  'Mason County Detention Center',
  'Mason County Eastern Elem Sch',
  'Mason County Eastern Junior Highhigh School',
  'Mason County High School',
  'Mason County Intermediate School',
  'Mason County Middle School',
  'Mason County School For Success',
  'Mason Creek Elementary School',
  'Mason Creek Middle School',
  'Mason Crest Elementary',
  'Mason Early Childhood Center Elementary School',
  'Mason El',
  'Mason Elem School',
  'Mason Elem.',
  'Mason Elementary',
  'Mason Elementary School',
  'Mason Es',
  'Mason H S',
  'Mason High School',
  'Mason Hs',
  'Mason Intermediate Elementary School',
  'Mason J H',
  'Mason Middle School',
  'Mason Ridge Elem.',
  'Mason Road School',
  'Mason Run High School',
  'Mason Senior High School',
  "Mason's Cove Elementary",
  'Mason-corinth Elementary',
  'Mason-dixon Elementary',
  'Mason-rice',
  'Mason/clark Middle Sch',
  'Masonairport Virtual Academy',
  'Masonic Heights Elementary School',
  'Masontown El Sch',
  'Maspeth High School',
  'Massabesic High School',
  'Massabesic Middle School',
  'Massac County High School',
  'Massac Jr High School',
  'Massachusetts Bay Community College',
  'Massachusetts Institute of Technology',
  'Massachusetts Virtual Academy At Greenfield Commonwealth Vir',
  "Massanutten Regional Governor's School",
  'Massanutten Tech Ctr',
  'Massapequa High School',
  'Massaponax High',
  'Massasoit Community College',
  'Massena Senior High School',
  'Massey Hill Classical High',
  'Massey Ranch El',
  'Massey University',
  'Massillon Digital Academy Inc',
  'Massillon Intermediate School',
  'Massillon Junior High School',
  'Massillon Preschool',
  'Mast Academy',
  'Mast Community Charter School',
  'Mast Community Cs Ii',
  'Mast Landing School',
  'Mast Way School',
  'Mast@fiu',
  'Mastbaum Jules E Avts',
  'Masterman Julia R Sec Sch',
  'Masters Elem School',
  'Mastery Chs - Lenfest Campus',
  'Mastery Cs - Cleveland Elementary',
  'Mastery Cs - Francis D. Pastorius Elemen',
  'Mastery Cs - Hardy Williams',
  'Mastery Cs - Mann Campus',
  'Mastery Cs - Thomas Campus',
  'Mastery Cs -shoemaker Campus',
  'Mastery Cs John Wister Elementary',
  'Mastery Cs-clymer Elementary',
  'Mastery Cs-gratz Campus',
  'Mastery Cs-harrity Campus',
  'Mastery Cs-pickett Campus',
  'Mastery Cs-smedley Campus',
  'Mastery School',
  'Mastery Schools Of Camden Inc.',
  'Masuda (kazuo) Middle',
  'Masuk High School',
  'Mat-su Career & Tech Ed High School',
  'Mat-su Central School',
  'Mat-su Day School',
  'Mat-su Middle College School',
  'Mat-su Youth Facility',
  'Mata Int',
  'Matafao Elementary School',
  'Matagorda School',
  'Matamoras Elementary School',
  'Matanzas High School',
  'Matapeake Elementary School',
  'Matapeake Middle School',
  'Matatula Elementary School',
  'Matawan Regional High School',
  'Matawan-aberdeen Middle School',
  'Match Charter Public School',
  'Matec',
  'Matej Bel University in Banská Bystrica',
  'Mateo Hernandez',
  'Mater Academy',
  'Mater Academy At Mount Sinai',
  'Mater Academy At St Cloud',
  'Mater Academy Bay Elementary',
  'Mater Academy Bay Middle School',
  'Mater Academy Bonanza',
  'Mater Academy Charter High',
  'Mater Academy Charter Middle',
  'Mater Academy East Charter',
  'Mater Academy East Charter High School',
  'Mater Academy High School Of International Studies',
  'Mater Academy Lakes High School',
  'Mater Academy Lakes Middle School',
  'Mater Academy Miami Beach',
  'Mater Academy Middle School Of International Studies',
  'Mater Academy Mountain Vista',
  'Mater Academy Northern Nevada',
  'Mater Academy Of International Studies',
  'Mater Brickell Preparatory Academy',
  'Mater Brighton Lakes',
  'Mater East Academy Middle School',
  'Mater Gardens Academy',
  'Mater Gardens Academy Middle School',
  'Mater Grove Academy',
  'Mater International Academy',
  'Mater Palms Academy',
  'Mater Performing Arts & Entertainment Academy',
  'Mater Virtual Academy Charter Middle/high School',
  'Matewan Pre K-8',
  'Math & Science Magnet School',
  'Math And Science Academy',
  'Math And Science College Preparatory',
  'Math And Science Exploratory School (the)',
  'Math And Science Success Academy',
  'Math Civics And Sciences Cs',
  'Math Engineering And Science Academy Charter High School',
  'Math Science & Technology Magnet Academy At Roosevelt High',
  'Math Science Technology Preparatory School At Seneca (the)',
  'Math/science/tech Magnet',
  'Mathematics And Science Leadership Academy',
  'Mathematics Science Research And Technology Magnet Hs',
  'Matheny-withrow Elem Sch',
  'Mather',
  'Mather Heights Elementary',
  'Mather High School',
  'Matherville Intermediate School',
  'Matheson Park Elementary',
  'Mathew J Brletic Elementary',
  'Mathews El',
  'Mathews Elem.',
  'Mathews Elementary',
  'Mathews Elementary School',
  'Mathews High',
  'Mathews High School',
  'Mathews Junior High School',
  'Mathewson School',
  'Mathis El',
  'Mathis Elem.',
  'Mathis H S',
  'Mathis Int',
  'Mathis Middle',
  'Matias De Llano El',
  'Matias Gonzalez Garcia',
  'Matias Rivera',
  'Matilda Avenue School (the)',
  'Matilda Dunston Elementary',
  'Matilda Harris Elementary School',
  'Matilija Junior High',
  'Matinicus Elementary School',
  'Matn University',
  'Matoaca Elementary',
  'Matoaca High',
  'Matoaca Middle',
  'Matoaka Elementary',
  'Matoska International',
  'Matsumoto Dental University',
  'Matsusaka University',
  'Matsuyama Elementary',
  'Matsuyama University',
  'Matt Elementary School',
  'Matt Garcia Career And College Academy',
  'Matt Kelly Elementary School',
  'Matt Wilson Elementary School',
  'Mattacheese Middle School',
  'Mattahunt',
  'Mattamuskeet Early College High',
  'Mattamuskeet Elementary',
  'Mattanawcook Academy',
  'Mattanawcook Jr High School',
  'Mattapan Early Elementary School',
  'Mattaponi Elementary',
  'Mattawa Elementary',
  'Mattawa Elementary Pre-school',
  'Mattawan Early Elem School',
  'Mattawan High School',
  'Mattawan Later Elem School',
  'Mattawan Middle School',
  'Mattawoman Middle School',
  'Matteson Elem School',
  'Matthew A. Henson Elementary',
  'Matthew Arthur Elementary School',
  'Matthew Fontaine Maury High',
  'Matthew Gage Middle',
  'Matthew Henson Middle School',
  'Matthew J Kuss Middle',
  'Matthew Jago Elementary School',
  'Matthew Paterson Elementary School',
  'Matthew Thornton Elementary School',
  'Matthew Turner Elementary',
  'Matthew W. Gilbert Middle School',
  'Matthew Whaley Elementary',
  'Matthews Charter Academy',
  'Matthews Elem.',
  'Matthews Elementary',
  'Matthews Elementary School',
  'Matthews Learning Center',
  'Matthews Middle School',
  'Matthys El',
  'Mattie Lively Elementary School',
  'Mattie Lou Maxwell',
  'Mattie T Blount High School',
  'Mattie V Rutherford Alt Ed Center',
  'Mattie Washburn Elementary',
  'Mattie Wells Elementary School',
  'Mattituck Junior-senior High School',
  'Mattituck-cutchogue Elementary School',
  'Mattole Elementary',
  'Mattole Triple Junction High',
  'Mattole Valley Charter (#159)',
  'Mattoon High School',
  'Mattoon Middle School',
  'Mattson Middle School',
  'Matunuck School',
  'Matzke El',
  'Maud Abrams School',
  'Maud E Johnson Elem School',
  'Maud Es',
  'Maud Hs',
  'Maud S Sherwood Elementary School',
  'Maud School',
  'Maude B. Davis Elementary',
  'Maude Bishop Elem',
  'Maude H. Trefethen School',
  'Maude I Logan El',
  'Maude M. Wilkins Elementary School',
  'Maude Mae Kirchner El',
  'Maude Marshall Elementary School',
  'Maude Saunders Elementary School',
  'Maude Trevvett Elementary',
  'Maudrey J. Sommer School',
  'Maudrie Walton El',
  'Maugansville Elementary',
  'Maui High School',
  'Maui Waena Intermediate School',
  'Mauka Lani Elementary School',
  'Maulana Azad National Institute of Technology Bhopal',
  'Mauldin Elementary',
  'Mauldin High',
  'Mauldin Middle',
  'Maumee High School',
  'Maumelle Charter Elementary School',
  'Maumelle Charter High School',
  'Maumelle High School',
  'Maumelle Middle School',
  'Maunaloa Elementary School',
  'Maunawili Elementary School',
  'Maupin Elementary',
  'Maupin Elementary School',
  'Maureen Joy Charter School',
  'Maureen M Welch El Sch',
  'Maurepas School',
  'Maurice A Donahue Elementary',
  'Maurice Bowling Middle School',
  'Maurice C. Cash Elementary School',
  'Maurice Hawk Elementary School',
  'Maurice J Tobin',
  'Maurice J. Mcdonough High School',
  'Maurice L Wolfe El',
  'Maurice River Township School',
  'Maurice Sendak Community School (the)',
  'Maurice Sendak Elementary',
  'Maurice Spear Campus',
  'Maurice W Downing Primary School',
  'Mauriceville El',
  'Mauriceville Middle',
  'Maurine Cain Middle',
  'Maurine Elementary - 04',
  'Mauro-sheridan Magnet School',
  'Maury City Elementary',
  'Maury Elementary',
  'Maury Elementary School At Eliot Hine',
  'Maury Middle School',
  'Maury River Middle School',
  'Maus Middle',
  'Mauston High',
  'Mauston Montessori Charter School',
  'Maverick El',
  'Mavericks High School At Palm Springs',
  'Mawbey Street Elementary School',
  'Mawlana Bhashani Science And Technology University',
  'Max Bruner Junior Middle School',
  'Max Elementary School',
  'Max High School',
  'Max Leuchter',
  'Max Meadows Elementary',
  'Max O Vaughan El',
  'Max Paun School',
  'Max S Hayes High School',
  'Maxdale El',
  'Maxey Elementary School',
  'Maxfield El',
  'Maxfield Elementary School',
  'Maxim Gorky Institute of Literature',
  'Maximina Mendez (campamento)',
  'Maximo Elementary School',
  'Maxine Greene High School For Imaginative Inquiry (the)',
  'Maxine Hong Kingston Elementary',
  'Maxine Johnson Elementary',
  'Maxine O Bush Elementary School',
  'Maxine Smith Steam Academy',
  'Maxson Elementary',
  'Maxson Middle School',
  'Maxwell Academy An Ib World (k-7)',
  'Maxwell Colony Elementary - 04',
  'Maxwell El Sch',
  'Maxwell Elementary',
  'Maxwell Elementary School',
  'Maxwell High',
  'Maxwell High School',
  'Maxwell Hill Elementary',
  'Maxwell Intermediate School',
  'Maxwell Middle',
  'Maxwell Spanish Immersion Elem School',
  'Maxwell Sr High',
  'May El',
  'May Elem School',
  'May H S',
  'May Moore Primary School',
  'May Overby Elementary - 09',
  'May Ranch Elementary',
  'May River High',
  'May Roberts Elementary School',
  'May Sands Montessori School',
  'May Street',
  'May Street Elementary School',
  'May V Peck Elementary School',
  'May Valley Elementary School',
  'May Watts Elementary School',
  'May Werthan Shayne Elementary School',
  'May Whitney Elem School',
  'May-port Cg High School',
  'May-port Cg Middle School',
  'Maya Angelou Academy At New Beginnings',
  'Maya Angelou Elem Sch',
  'Maya Angelou Elementary',
  'Maya Angelou Elementary School',
  'Maya Angelou French Immersion',
  'Maya Angelou H S',
  'Maya Angelou Pcs High School',
  'Maya Lin',
  'Mayall Street Elementary',
  'Maybell School',
  'Mayberry Cultural And Fine Arts Magnet Middle',
  'Mayberry Street Elementary',
  'Maybeury Elementary',
  'Maybury Elementary School',
  'Mayde Creek El',
  'Mayde Creek H S',
  'Mayde Creek J H',
  'Mayer Elem School',
  'Mayer Elementary School',
  'Mayer High School',
  'Mayer Junior High School',
  'Mayes El',
  'Mayewood Middle',
  'Mayfair',
  'Mayfair Elementary',
  'Mayfair Elementary School',
  'Mayfair High',
  'Mayfair Laboratory School',
  'Mayfair Sch',
  'Mayfield Center Elementary School',
  'Mayfield Colony Elementary - 04',
  'Mayfield Elementary',
  'Mayfield Elementary School',
  'Mayfield High',
  'Mayfield High School',
  'Mayfield Intermediate',
  'Mayfield Jr/sr High School',
  'Mayfield Middle School',
  'Mayfield Ms',
  'Mayfield Preschool',
  'Mayfield Woods Middle',
  'Mayfield Youth Development Center',
  'Mayfield/graves County Area Tech Center',
  'Mayflower Elementary',
  'Mayflower Elementary School',
  'Mayflower High School',
  'Mayflower Middle School',
  'Mayflower Mill Elementary School',
  'Mayland Community College',
  'Mayland Early College',
  'Maymont Pre-k Center',
  'Maynard A Traviss Technical College',
  'Maynard Elementary',
  'Maynard Elementary School',
  'Maynard Evans High',
  'Maynard H. Jackson- Jr. High School',
  'Maynard High',
  'Maynard High School',
  'Maynard P Wilson Elementary School',
  'Maynardville Elementary',
  'Mayo Demonstration Es',
  'Mayo Elementary',
  'Mayo High For Math Science & Technology',
  'Mayo Middle School',
  'Mayo Senior High',
  'Mayo Technical College',
  'Mayor John H Wilson Elementary School',
  'Mayor Joseph J Fern Elementary School',
  'Maypearl H S',
  'Maypearl Int',
  'Maypearl Middle',
  'Mayport Elementary School',
  'Mayport Middle School',
  'Mays Chapel Elementary',
  'Mays Community Academy',
  'Mays Elem Academy',
  'Mays High School',
  'Maysville Community and Technical College',
  'Maysville Elem.',
  'Maysville Elementary',
  'Maysville Elementary School',
  'Maysville Es',
  'Maysville High School',
  'Maysville Hs',
  'Maysville Jr.-sr. High',
  'Maysville Middle School',
  'Maysville O Tags',
  'Mayville Elementary School',
  'Mayville High',
  'Mayville High School',
  'Mayville Middle',
  'Mayville Middle School',
  'Maywood Academy High',
  'Maywood Avenue School',
  'Maywood Campus',
  'Maywood Center For Enriched Studies',
  'Maywood Elementary',
  'Maywood Elementary School',
  'Maywood High School',
  'Maywood Hills Elementary',
  'Maywood Middle',
  'Maywood Middle School',
  'Mazama High School',
  'Mazandaran University of Iran',
  'Mazandaran University of Medical Science',
  'Mazandaran University of Science & Technology',
  'Maze Middle',
  'Mazie Es',
  'Mazie Gable El Sch',
  'Mazomanie Elementary',
  'Mazon-verona-kinsman Elem School',
  'Mazon-verona-kinsman Middle Sch',
  'Mbarara University of Science and Technology',
  'Mc Bride Pre-k Academy',
  'Mc Clave Elementary School',
  'Mc Clave Undivided High School',
  'Mc Collum Elementary',
  'Mc Cook Alternative Education',
  'Mc Cook Elementary School',
  'Mc Cook Junior High School',
  'Mc Cook Senior High School',
  'Mc Cool Junction Elem School',
  'Mc Dermitt Elementary School',
  'Mc Dermitt High School',
  'Mc Dermitt Junior High School',
  'Mc Donald Elementary School',
  'Mc Elwain Elementary School',
  'Mc Ewen Elementary',
  'Mc Ewen High School',
  'Mc Kinley Middle',
  'Mc Kinley-thatcher Elementary School',
  'Mc Lain Community High School',
  'Mc Lain Elementary School',
  'Mc Lain High School',
  'Mc Laurin Elementary School',
  'Mc Leod Elementary School',
  'Mc Meen Elementary School',
  'Mc Millan Magnet Middle School',
  'Mc Neal Elementary School',
  'Mc Pherson County Secondary School',
  'Mc Polin Preschool',
  'McDaniel College',
  'McDowell Technical Community College',
  'McGill University',
  'McHenry County College',
  'McLennan Community College',
  'McMaster University',
  'McMurry University',
  'McNeese State University',
  'Mc^2 Stem High School',
  'Mcadams Attendance Center',
  'Mcadenville Elementary',
  'Mcadoo-kelayres El/ms',
  'Mcadory Elementary School',
  'Mcadory High School',
  'Mcadory Middle School',
  'Mcalearsawden Elem School',
  'Mcalester Hs',
  'Mcalister Intermediate School',
  'Mcallen H S',
  'Mcallister Elementary School',
  'Mcalpine Elementary',
  'Mcanally Int',
  'Mcandrew El',
  'Mcannulty El Sch',
  'Mcarthur High School',
  'Mcat High School',
  'Mcauliffe El',
  'Mcauliffe Elem School',
  'Mcauliffe Elementary',
  'Mcauliffe Es',
  'Mcauliffe International School',
  'Mcauliffe Manual Middle School',
  'Mcbain Elementary School',
  'Mcbain Jrsr High School',
  'Mcbean Elementary School',
  'Mcbee El',
  'Mcbee Elementary',
  'Mcbee High',
  'Mcbrayer Elementary School',
  'Mcbride Elem.',
  'Mcbride Elementary School',
  'Mcbride Middle School',
  'Mccabe Elementary',
  'Mccain Middle School',
  'Mccall El',
  'Mccall Gen George A Sch',
  'Mccall Middle',
  'Mccall Primary School',
  'Mccall-donnelly High School',
  'Mccallum H S',
  'Mccamey H S',
  'Mccamey Middle',
  'Mccamey Pri',
  'Mccandless Elementary',
  'Mccants Middle',
  'Mccardle Elementary',
  'Mccarroll Middle',
  'Mccarroll Middle 6th Grade',
  'Mccarter Elem',
  'Mccarthy Middle School',
  'Mccarthy-towne School',
  'Mccartney Ranch Elementary School',
  'Mccarty Elementary School',
  'Mccarver',
  'Mccaskey Campus',
  'Mcclain High School',
  'Mcclarin High School',
  'Mccleary Elem',
  'Mccleary Elementary',
  'Mccleery Elem School',
  'Mcclellan El Sch',
  'Mcclellan Elem School',
  'Mcclellan Elementary School',
  'Mcclellan High (continuation)',
  'Mcclellan Magnet High School',
  'Mcclelland Elementary School',
  'Mcclelland School',
  'Mcclendon El',
  'Mcclernand Elem School',
  'Mccleskey Middle School',
  'Mcclintock High School',
  'Mcclintock Middle',
  'Mccloskey John F Sch',
  'Mccloskey Middle School',
  'Mccloud Elementary',
  'Mccloud High',
  'Mccluer High',
  'Mccluer North High',
  'Mccluer South-berkeley High',
  'Mcclure Alexander K Sch',
  'Mcclure Elem',
  'Mcclure Elementary School',
  'Mcclure Elementary School Yakima',
  'Mcclure Es',
  'Mcclure Jr High School',
  'Mcclure Middle School',
  'Mcclusky Elementary School',
  'Mcclusky High School',
  'Mcclymonds High',
  'Mccoll Elementary/middle',
  'Mccollom Elem',
  'Mccollough Elementary School',
  'Mccollum H S',
  'Mccomb Elementary School',
  'Mccomb High School',
  'Mccomb Local Elementary School',
  'Mccomb Local High School',
  'Mccomb Local Middle School',
  'Mccomb Middle School',
  'Mccombs Middle School',
  'Mcconnell Elementary',
  'Mcconnell Middle School',
  'Mcconnellsburg El Sch',
  'Mcconnellsburg Hs',
  'Mcconnellsburg Ms',
  'Mcconnellsville Elementary School',
  'Mccook Central Elementary - 02',
  'Mccook Central High School - 01',
  'Mccook Central Middle School - 03',
  'Mccool Junction Jr-sr High Sch',
  'Mccoppin (frank) Elementary',
  'Mccord Elementary',
  'Mccord Middle School',
  'Mccord Public School',
  'Mccordsville Elementary School',
  'Mccorkle Pk-8',
  'Mccormick El Sch',
  'Mccormick Elem School',
  'Mccormick Elementary',
  'Mccormick Elementary School',
  'Mccormick High',
  'Mccormick Junior High',
  'Mccormick Junior High School',
  'Mccormick Middle',
  'Mccormick Middle School',
  'Mccormick School',
  "Mccormick's Creek Elementary Sch",
  'Mccornack Elementary School',
  'Mccoy Avenue Elementary',
  'Mccoy El',
  'Mccoy Elementary School',
  'Mccracken County High School',
  'Mccracken Middle',
  'Mccracken Open Campus School',
  'Mccracken Regional School',
  'Mccray Elementary School',
  'Mccreary Academy',
  'Mccreary Central High School',
  'Mccreary County Middle School',
  'Mccreary County Preschool North',
  'Mccreary County Preschool South',
  'Mccreary Rd El',
  'Mccrorey-liston School Of Technology',
  'Mccrory Elementary School',
  'Mccrory High School',
  'Mcculloch Elementary',
  'Mcculloch Intermediate School',
  'Mcculloch School',
  'Mccullough (calvin R. ) Middle School',
  'Mccullough El Sch',
  'Mccullough J H',
  'Mccurdy Charter School',
  'Mccurdy Elem.',
  'Mccurtain Es',
  'Mccurtain Hs',
  'Mccutchanville Elementary School',
  'Mccutcheon Elem School',
  'Mccutcheon High School',
  'Mcdade El',
  'Mcdade Elem Classical School',
  'Mcdade H S',
  'Mcdaniel Delaplaine Sch',
  'Mcdaniel Learning Center',
  'Mcdavidjones Elementary School',
  'Mcdermoth Elementary',
  'Mcdermott El',
  'Mcdermott Elementary School',
  'Mcdill Elementary',
  'Mcdonald County High',
  'Mcdonald El Sch',
  'Mcdonald Elementary',
  'Mcdonald Elementary School',
  'Mcdonald Green Elementary',
  'Mcdonald High School',
  'Mcdonald International School',
  'Mcdonald Middle',
  'Mcdonnell Elementary School',
  'Mcdonogh #32 Literacy Charter School',
  'Mcdonogh #35 Academy',
  'Mcdonogh #35 College Preparatory School',
  'Mcdonogh 26/homedale Elementary School',
  'Mcdonogh 42 Charter School',
  'Mcdonough Middle School',
  'Mcdonough School',
  'Mcdougle El',
  'Mcdougle Elementary',
  'Mcdougle Middle',
  'Mcdowell',
  'Mcdowell Academy For Innovation',
  'Mcdowell Early College',
  'Mcdowell Elem School',
  'Mcdowell Elementary',
  'Mcdowell Elementary School',
  'Mcdowell High',
  'Mcdowell Hs',
  'Mcdowell Middle',
  'Mcdowell Mountain Elementary School',
  'Mceachern High School',
  'Mceachron Elem',
  'Mcebright Community Learning Center',
  'Mcelvain School',
  'Mceowen Elem.',
  'Mcesa And Sugnet School Classroom Programs',
  'Mcevans School',
  'Mcever Elementary School',
  'Mcewen Jr. High School',
  'Mcf - Oak Park Heights',
  'Mcf-moose Lake/willow River',
  'Mcf-redwing Maginnis High School',
  'Mcfadden Intermediate',
  'Mcfadden School Of Excellence',
  'Mcfall Elementary School',
  'Mcfarland High',
  'Mcfarland Independent',
  'Mcfarland Middle',
  'Mcfarland Middle School',
  'Mcfarland Primary School',
  'Mcfee El',
  'Mcferran Preparatory Academy',
  'Mcgaheysville Elementary',
  'Mcgalliard Elementary School',
  'Mcgarity Elementary School',
  'Mcgary Middle School',
  'Mcgaughey Elem School',
  'Mcgavock Elementary',
  'Mcgavock High',
  "Mcgee's Crossroads Elem",
  "Mcgee's Crossroads Middle",
  'Mcgehee Elementary School',
  'Mcgehee High School',
  'Mcghee Elementary School',
  'Mcgill El',
  'Mcgill Elementary School',
  'Mcgill School Of Success',
  'Mcgilvra Elementary School',
  'Mcglone Academy',
  'Mcgovern Elementary School',
  'Mcgowen El',
  'Mcgrath Elem.',
  'Mcgrath Elementary School',
  'Mcgrath School',
  'Mcgraw Elementary School',
  'Mcgraw Secondary School',
  'Mcgregor Area Learning Program',
  'Mcgregor El',
  'Mcgregor Elem.',
  'Mcgregor Elementary',
  'Mcgregor Elementary School',
  'Mcgregor H S',
  'Mcgregor Intermediate School',
  'Mcgregor Pri',
  'Mcgregor Secondary',
  'Mcguffey Elementary School',
  'Mcguffey Hs',
  'Mcguffey Ms',
  'Mcguffey Pk-8 School',
  'Mcguire Middle',
  'Mcharg Elementary',
  'Mchenry East High School',
  'Mchenry High School-west Campus',
  'Mchenry Middle School',
  'Mchenry Primary',
  'Mcintire Elem.',
  'Mcintosh Academy',
  'Mcintosh Area School',
  'Mcintosh County Middle School',
  'Mcintosh Elementary',
  'Mcintosh Elementary - 02',
  'Mcintosh Elementary School',
  'Mcintosh High School',
  'Mcintosh High School - 01',
  'Mcintosh Jr. High - 03',
  'Mcintosh Middle School',
  'Mcintyre El Sch',
  'Mcintyre Elementary School',
  'Mckamy El',
  'Mckamy Middle',
  'Mckay Arts Academy',
  'Mckay Creek Elementary School',
  'Mckay Elem School',
  'Mckay Elementary School',
  'Mckay High School',
  'Mckean (thomas) High School',
  'Mckean El Sch',
  'Mckee El Sch',
  'Mckee Elementary School',
  'Mckee Middle',
  'Mckee Middle School',
  'Mckee Prek Center',
  'Mckee Road Elementary',
  'Mckeel Academy Of Technology',
  'Mckeel Elementary Academy',
  'Mckeesport Area Shs',
  'Mckeesport Area Tech Ctr',
  'Mckell Elementary School',
  'Mckell Middle School',
  'Mckellar Vocational Center',
  'Mckellips Learning Center',
  'Mckelvey Elem.',
  'Mckelvie Intermediate School',
  'Mckendree Elementary School',
  'Mckenna Elementary',
  'Mckenna Elementary School',
  'Mckenney-harrison Elementary Sch',
  'Mckenny Elementary',
  'Mckenzie Career Center',
  'Mckenzie El',
  'Mckenzie Elem School',
  'Mckenzie Elementary',
  'Mckenzie Elementary School',
  'Mckenzie High School',
  'Mckenzie Middle School',
  'Mckenzie School',
  'Mckeown School',
  'Mckevett Elementary',
  'Mckinley',
  'Mckinley Academy',
  'Mckinley Avenue Elementary',
  'Mckinley Avenue Elementary School',
  'Mckinley Center',
  'Mckinley Charter School',
  'Mckinley Class. Leadership Ac.',
  'Mckinley Community School',
  'Mckinley El Sch',
  'Mckinley Elem',
  'Mckinley Elem School',
  'Mckinley Elem.',
  'Mckinley Elementary',
  'Mckinley Elementary - 06',
  'Mckinley Elementary School',
  'Mckinley Es',
  'Mckinley High School',
  'Mckinley Institute Of Technology',
  'Mckinley Jr High School',
  'Mckinley Middle',
  'Mckinley Middle Magnet School',
  'Mckinley Middle School',
  'Mckinley Ms',
  'Mckinley Prek',
  'Mckinley Primary Center',
  'Mckinley Sch',
  'Mckinley School',
  'Mckinley Senior High School',
  'Mckinley Technology Hs',
  'Mckinley Vocational High School',
  'Mckinley William Sch',
  'Mckinley-brighton Elementary School',
  'Mckinleyville High',
  'Mckinleyville Middle',
  'Mckinna Elementary',
  'Mckinney Boyd H S',
  'Mckinney Elementary School',
  'Mckinney H S',
  'Mckinney North H S',
  'Mckinnon',
  'Mckinnon Elementary',
  'Mckissick Elementary',
  'Mckitrick Elementary School',
  'Mckittrick Elementary',
  'Mcknight El Sch',
  'Mcknight Middle School',
  'Mclain Hs For Science And Tech',
  'Mclain Jhs',
  'Mclane Elementary',
  'Mclane Elementary School',
  'Mclane High',
  'Mclane Middle School',
  'Mclaren (john) Children Centers',
  'Mclaughlin Elementary - 02',
  'Mclaughlin High School - 01',
  'Mclaughlin Middle School - 03',
  'Mclaughlin Middle School And Fine Arts Academy',
  'Mclaughlin Secondary School',
  'Mclaughlin Strickland El',
  'Mclaurin Attendance Center',
  'Mclaurin Elementary',
  'Mclaurin Elementary School',
  'Mclean 6th Grade',
  'Mclean County High School',
  'Mclean County Middle School',
  'Mclean Education Center (alt)',
  'Mclean High',
  'Mclean Middle',
  'Mclean School',
  'Mclean Science/tech Magnet Elem',
  'Mcleansville Elementary',
  'Mclees Elementary',
  'Mclendon Elementary School',
  'Mclennan Co Challenge Academy',
  'Mclennan County Challenge Academy',
  'Mcleod El',
  'Mcleod H S',
  'Mcleod Middle',
  'Mcleod School',
  'Mclish Ms',
  'Mcloud Es',
  'Mcloud Hs',
  'Mcloud Jhs',
  'Mcloughlin High School',
  'Mcloughlin Middle School',
  'Mclouth Elem',
  'Mclouth High',
  'Mclouth Middle',
  'Mcmanus (john A.) Elementary',
  'Mcmasters El',
  'Mcmath Middle',
  'Mcmichael Middle',
  'Mcmichael Morton Sch',
  'Mcmicken Heights Elementary',
  'Mcmillan Elem.',
  'Mcmillan Elementary',
  'Mcmillan Elementary School',
  'Mcmillan School',
  'Mcmillian Learning Center',
  'Mcmillin (corky) Elementary',
  'Mcminn High School',
  'Mcminnville High School',
  'Mcmullan El',
  'Mcmullen County School',
  'Mcmullen Elementary School',
  'Mcmullen-booth Elementary School',
  'Mcmurray El Sch',
  'Mcmurray Es',
  'Mcmurray Middle',
  'Mcmurray Middle School',
  'Mcnab Elementary School',
  'Mcnab-meco School',
  'Mcnabb Elementary School',
  'Mcnabb Middle School',
  'Mcnair Camp',
  'Mcnair El',
  'Mcnair Elem School',
  'Mcnair Elem.',
  'Mcnair Elementary',
  'Mcnair High School',
  'Mcnair Middle School',
  'Mcnairy Central High School',
  'Mcnamara El',
  'Mcnary Elementary School',
  'Mcnary Heights Elementary School',
  'Mcnary High School',
  'Mcnaughton Correctional Center',
  'Mcneal Elementary School',
  'Mcnear Elementary',
  'Mcneel Intermediate',
  'Mcneil Canyon Elementary',
  'Mcneil H S',
  'Mcnichols Plaza',
  'Mcnicol Middle School',
  'Mcniel Middle',
  'Mcninch Primary School',
  'Mcnutt El',
  'Mcphee Elementary School',
  "Mcpheeter's Bend Elementary",
  'Mcpherson Elem School',
  'Mcpherson Elementary',
  'Mcpherson High',
  'Mcpherson Magnet',
  'Mcpherson Middle School',
  'Mcpolin School',
  'Mcps Transitions School',
  'Mcqueen School',
  'Mcqueeney El',
  'Mcquerry Elem.',
  'Mcquistion El Sch',
  'Mcrae El',
  'Mcrae Elementary School',
  'Mcreynolds Middle',
  'Mcroberts El',
  'Mcso Adults',
  'Mcso Juveniles',
  'Mcsorley Elementary School',
  'Mcspedden El',
  'Mcswain Elementary',
  'Mcsweeny Elementary',
  'Mctigue Elementary School',
  'Mcvay Elementary School',
  'Mcvey (joseph M.) Elementary School',
  'Mcvey Elementary School',
  'Mcvs Arthur R. Sypek Center',
  'Mcvs Assunpink Center',
  'Mcwhorter El',
  'Mcwillie Elementary School',
  'Mdp - Batchelder School',
  'Mdp - Bulkeley High School',
  'Mdp - Hphs Law And Government Academy',
  'Mdp - Moylan School',
  'Me Sch Of Science & Mathematics',
  'Meacham Middle',
  'Meachem Elementary School',
  'Mead Alternative High School',
  'Mead Education Partnership Prog',
  'Mead El',
  'Mead Elementary',
  'Mead Elementary Charter School',
  'Mead Elementary School',
  'Mead High School',
  'Mead Middle School',
  'Mead Open Doors',
  'Mead Preschool',
  'Mead School',
  'Mead Senior High School',
  'Mead Valley Elementary',
  'Meade County Area Technology Center',
  'Meade County High School',
  'Meade Elem',
  'Meade Gen George C Sch',
  'Meade Heights Elementary',
  'Meade High',
  'Meade Middle',
  'Meade Park Elem School',
  'Meade River School',
  'Meador Elementary',
  'Meadow Bridge Elementary',
  'Meadow Bridge High',
  'Meadow Brook',
  'Meadow Brook Elementary School',
  'Meadow Brook School',
  'Meadow Community School',
  'Meadow Creek El',
  'Meadow Crest Early Childhood Education Center',
  'Meadow Drive School',
  'Meadow Elementary',
  'Meadow Elementary School',
  'Meadow Glen Elementary',
  'Meadow Glen Middle',
  'Meadow Glens Elementary School',
  'Meadow Green Elementary',
  'Meadow Hall Elementary',
  'Meadow Heights Elem.',
  'Meadow Heights Elementary',
  'Meadow Heights High',
  'Meadow Hill Global Explorations Magnet School',
  'Meadow Hill Middle School',
  'Meadow Homes Elementary',
  'Meadow Lake Elementary',
  'Meadow Lakes Elementary',
  'Meadow Lands Elementary School',
  'Meadow Lane Elem',
  'Meadow Lane Elem.',
  'Meadow Lane Elementary',
  'Meadow Lane Elementary School',
  'Meadow Lane School',
  'Meadow Lark Elementary',
  'Meadow Lark School',
  'Meadow Park Elementary',
  'Meadow Park Elementary School',
  'Meadow Park Middle School',
  'Meadow Point Elementary School',
  'Meadow Pond Elementary School',
  'Meadow Ridge Elementary',
  'Meadow Ridge Elementary School',
  'Meadow Ridge School',
  'Meadow School',
  'Meadow Valley Middle School',
  'Meadow View',
  'Meadow View Elementary',
  'Meadow View Elementary School',
  'Meadow View Primary',
  'Meadow View School',
  'Meadow Village El',
  'Meadow Wood El',
  'Meadow Woods Elementary',
  'Meadow Woods Middle',
  "Meadow's Edge Elementary School",
  'Meadowbrook',
  'Meadowbrook Academy',
  'Meadowbrook Community Day',
  'Meadowbrook El',
  'Meadowbrook Elem School',
  'Meadowbrook Elem.',
  'Meadowbrook Elementary',
  'Meadowbrook Elementary - 10',
  'Meadowbrook Elementary School',
  'Meadowbrook Farms School',
  'Meadowbrook High',
  'Meadowbrook High School',
  'Meadowbrook Intermediate Sch',
  'Meadowbrook Middle',
  'Meadowbrook Middle School',
  'Meadowbrook School',
  'Meadowcliff Elementary School',
  'Meadowcreek El',
  'Meadowcreek Elementary School',
  'Meadowcreek High School',
  'Meadowdale Elem School',
  'Meadowdale Elementary',
  'Meadowdale High School',
  'Meadowdale Middle School',
  'Meadowdale Prek-6 School',
  'Meadowfield Elementary',
  'Meadowlake Elementary',
  'Meadowland Elementary',
  'Meadowlane Elementary School',
  'Meadowlane Intermediate Elementary School',
  'Meadowlane Primary Elementary School',
  'Meadowlark Academy',
  'Meadowlark Elementary',
  'Meadowlark Elementary School',
  'Meadowlark Middle',
  'Meadowlark Ridge Elem',
  'Meadowlark School',
  'Meadowlawn Elementary',
  'Meadowlawn Elementary School',
  'Meadowlawn Middle School',
  'Meadowmere Elem.',
  'Meadowood Child Development Center',
  'Meadowood Education Center',
  'Meadowood Program',
  'Meadows Arts And Technology Elementary',
  'Meadows Avenue Elementary',
  'Meadows El',
  'Meadows Elem.',
  'Meadows Elementary',
  'Meadows Elementary School',
  'Meadows Of Dan Elementary',
  'Meadows School',
  'Meadows Valley School',
  'Meadowside School',
  'Meadowthorpe Elementary School',
  'Meadowvale Elementary',
  'Meadowvale Elementary School',
  'Meadowview Elem',
  'Meadowview Elem School',
  'Meadowview Elementary',
  'Meadowview Elementary School',
  'Meadowview Magnet Middle',
  'Meadowview Middle School',
  'Meadowview School',
  'Meads Mill Middle School',
  'Meadville Area Shs',
  'Meadville Elem.',
  'Meadville Elementary',
  'Meadville High',
  'Meadville Ms',
  'Meairs Elementary',
  'Meany Middle School',
  'Mears Middle School',
  'Meaux Elementary School',
  'Mecca Elementary',
  'Mechanics Grove Elem School',
  'Mechanicsburg Area Shs',
  'Mechanicsburg High School',
  'Mechanicsburg Middle School',
  'Mechanicsburg Ms',
  'Mechanicsville Elementary',
  'Mechanicville Elementary School',
  'Mechanicville Junior/senior High School',
  'Mecosta Elementary School',
  'Mecostaosceola Career Center',
  'Mecostaosceola Education Center',
  'Medardo Carazo',
  'Medart Elementary School',
  'Medary Elementary - 05',
  'Medea Creek Middle',
  'Medfield Heights Elementary',
  'Medfield Senior High',
  'Medford Elementary',
  'Medford Elementary School',
  'Medford Es',
  'Medford High',
  'Medford Hs',
  'Medford Memorial Middle School',
  'Medford Middle',
  'Medford Secondary',
  'Medford Vocational Technical High',
  'Medgar Evers College Preparatory School',
  'Medgar Evers Primary Acadmc Cntr',
  'Media Arts Collaborative Charter',
  'Media El Sch',
  'Mediania Alta Elemental',
  'Mediapolis Elementary School',
  'Mediapolis High School',
  'Medical Academy For Science And Technology (m.a.s.t.) @ Home',
  'Medical Academy Ludwik Rydygier in Bydgoszcz',
  'Medical Academy in Bialystok',
  'Medical Academy in Gdansk',
  'Medical Academy in Lodz',
  'Medical Academy in Lublin',
  'Medical Academy in Warszawa',
  'Medical Academy in Wroclaw',
  'Medical Academy of Latvia',
  'Medical Academy Karol Marcinkowski in Poznan',
  'Medical College of Georgia',
  'Medical College of Wisconsin',
  'Medical Faculty Osh State University',
  'Medical Lake Alternative High School',
  'Medical Lake High School',
  'Medical Lake Middle School',
  'Medical University Pleven',
  'Medical University Plovdiv',
  'Medical University Varna',
  'Medical University of Sofia',
  'Medically Fragile Program (waves)',
  'Medicine And Community Health Academy At Cody',
  'Medicine Bow Elementary',
  'Medicine Crow Middle School',
  'Medicine Lake 7-8',
  'Medicine Lake High School',
  'Medicine Lake School',
  'Medicine Lodge Grade School',
  'Medicine Lodge Jr/sr High School',
  'Medicine Valley Elementary',
  'Medicine Valley Jr-sr High School',
  'Medill Elementary School',
  'Medina County Career Center',
  'Medina Elementary',
  'Medina Elementary School',
  'Medina High School',
  'Medina Middle School',
  'Medina School',
  'Medina Valley H S',
  'Medina Valley Loma Alta Middle',
  'Medina Valley Middle',
  'Medinah Intermediate School',
  'Medinah Middle School',
  'Medinah Primary School',
  'Medio Creek El',
  'Medizinische Hochschule Hannover',
  'Medizinische Universität Graz',
  'Medizinische Universität Innsbruck',
  'Medizinische Universität Lübeck',
  'Medizinische Universität Wien',
  'Medlar View Elementary School',
  'Medlin Middle',
  'Medlock Bridge Elementary School',
  'Medlock Youth Village',
  'Medomak Middle School',
  'Medomak Valley High School',
  'Medora Elementary',
  'Medora Elementary School',
  'Medora Intermediate School',
  'Medora Jr & Sr High School',
  'Medulla Elementary School',
  'Medway Elementary School',
  'Medway High',
  'Medway Middle',
  'Medway Middle School',
  'Meece Middle School',
  'Meehan Austin Ms',
  'Meehan Elementary School',
  'Meek Elementary School',
  'Meek High School',
  'Meeker',
  'Meeker Elementary',
  'Meeker Elementary School',
  'Meeker Es',
  'Meeker High School',
  'Meeker Hs',
  'Meeker Middle School',
  'Meeker Ms',
  'Meeker-wright Early Childhood Sp Ed',
  'Meekins Middle School',
  'Meeteetse School',
  'Meeting House Hill School',
  'Meeting Street Academy-spartanburg',
  'Meeting Street Elementary At Brentwood',
  'Meetinghouse School',
  'Megan Cope Elementary',
  'Megatrend University of Applied Sciences',
  'Meherrin Elementary',
  'Meherrin Powellton Elementary',
  'Mehlville High School',
  'Mehmet Akif Ersoy University',
  'Mehoopany El Sch',
  'Mehrabyan Medical Institute and Medical College',
  'Mehran University of Engineering & Technology',
  'Meigs County High School',
  'Meigs High School',
  'Meigs Intermediate School',
  'Meigs Middle',
  'Meigs Middle School',
  'Meigs North Elementary',
  'Meigs Primary School',
  'Meigs South Elementary',
  'Meiji College of Pharmacy',
  'Meiji Gakuin University',
  'Meiji University',
  'Meiji University of Oriental Medicine',
  'Meijo University',
  'Meikai University',
  'Meiklejohn Elementary',
  'Meiners Oaks Elementary',
  'Meio University',
  'Meir School',
  'Meisei University',
  'Mejiro University',
  'Mekelle Institute of Technology',
  'Mekelle University',
  'Melaka City Polytechnic',
  'Melaka Islamic University College',
  'Melaleuca Elementary School',
  'Melba Elementary School',
  'Melba High School',
  'Melba Passmore El',
  'Melbourne (ella P.) Elementary',
  'Melbourne Center For Personal Growth',
  'Melbourne Elementary School',
  'Melbourne High School',
  'Melbourne Senior High School',
  'Melcher-dallas Elem',
  'Melcher-dallas High School',
  'Melcher-dallas Junior High School',
  'Melillo Middle',
  'Melinda Heights Elementary',
  'Melissa H S',
  'Melissa Jones School',
  'Melissa Middle',
  'Melissa Ridge Intermed',
  'Mellen Public School',
  'Mellette Elementary - 07',
  'Mellichamp Elementary',
  'Mellon El Sch',
  'Mellon Elementary School',
  'Mellon Ms',
  'Melody Elem School',
  'Melridge Elementary School',
  'Melrose',
  'Melrose Avenue Elementary',
  'Melrose Elementary',
  'Melrose Elementary School',
  'Melrose High',
  'Melrose Junior',
  'Melrose Leadership Academy',
  'Melrose Middle',
  'Melrose Park Elem School',
  'Melrose Park Elementary School',
  'Melrose Sch',
  'Melrose School',
  'Melrose Secondary',
  'Melrose-mindoro High',
  'Melstone 7-8',
  'Melstone High School',
  'Melstone School',
  'Melva Davis Academy Of Excellence',
  'Melville S. Jacobson Elementary',
  'Melville School',
  'Melvin Avenue Elementary',
  'Melvin E Sine School',
  'Melvin G Syre Elementary',
  'Melvin H. Kreps Middle School',
  'Melvin Hendrickson Development Center',
  'Melvindale High School',
  'Melwood Elementary',
  'Melzer School',
  'Memminger Elementary',
  'Memorial',
  'Memorial 9th Grade Academy At Austin',
  'Memorial Campus',
  'Memorial Drive El',
  'Memorial Drive Elementary School',
  'Memorial Early Childhood Center',
  "Memorial Early College H S With St Philip's",
  'Memorial Education Center',
  'Memorial El',
  'Memorial El Sch',
  'Memorial Elem School',
  'Memorial Elementary',
  'Memorial Elementary 12 Campus',
  'Memorial Elementary School',
  'Memorial H S',
  'Memorial High',
  'Memorial High School',
  'Memorial Hs',
  'Memorial Int',
  'Memorial Intermediate',
  'Memorial J H',
  'Memorial Jhs',
  'Memorial Jr High School',
  'Memorial Junior High School',
  'Memorial Junior School',
  'Memorial Middle',
  'Memorial Middle School',
  'Memorial Middle School - 04',
  'Memorial Park',
  'Memorial Park Elementary School',
  'Memorial Park Middle School',
  'Memorial Parkway El',
  'Memorial Parkway J H',
  'Memorial Pathway Academy H S',
  'Memorial Pathway Academy Middle',
  'Memorial Scholars & Athletes',
  'Memorial School',
  'Memorial Senior High School',
  'Memorial Spaulding',
  'Memorial University of Newfoundland',
  'Memphis Academy Of Health Sciences',
  'Memphis Academy Of Health Sciences High School',
  'Memphis Academy Of Science Engineering Middle/high',
  'Memphis Business Academy Elementary School',
  'Memphis Business Academy High School',
  'Memphis Business Academy Middle',
  'Memphis College Preparatory',
  'Memphis Delta Preparatory Charter School',
  'Memphis Elementary School',
  'Memphis Grizzlies Preparatory Charter School',
  'Memphis H S',
  'Memphis Juniorsenior High School',
  'Memphis Middle',
  'Memphis Rise Academy',
  'Memphis Scholars Caldwell-guthrie',
  'Memphis Scholars Florida Kansas',
  'Memphis Scholars Raleigh-egypt',
  'Memphis School Of Excellence',
  'Memphis School Of Excellence Elementary',
  'Memphis Stem Academy',
  'Memphis Street Academy Cs @ Jp Jones',
  'Memphis Virtual School',
  'Mena High School',
  'Mena Middle School',
  'Menahga Elementary',
  'Menahga High School',
  'Menahga Middle School',
  'Menallen Sch',
  'Menands School',
  'Menard El',
  'Menard H S',
  'Menard J H',
  'Menasha High',
  'Menchaca El',
  'Menchville High',
  'Mendel El',
  'Mendel University of Agriculture and Forestry',
  'Mendeleev Russian University of Chemical Technology',
  'Mendenhall El',
  'Mendenhall Elementary School',
  'Mendenhall High School',
  'Mendenhall Junior High School',
  'Mendenhall Middle',
  'Mendenhall River Community School',
  'Mendenhall William Camp',
  'Mendez El',
  'Mendez Exceptional Center',
  'Mendez M S',
  'Mendham Township Elementary School',
  'Mendham Township Middle School',
  'Mendocino Alternative',
  'Mendocino College',
  'Mendocino County Community',
  'Mendocino County Rop',
  'Mendocino High',
  'Mendocino K-8',
  'Mendocino Sunrise High',
  'Mendon Center Elementary School',
  'Mendon El Sch',
  'Mendon Elementary School',
  'Mendon Middlehigh School',
  'Mendota Community Day',
  'Mendota Continuation High',
  'Mendota Elementary',
  'Mendota High',
  'Mendota Junior High',
  'Mendota Mental Health Institution',
  'Mendota Twp High School',
  'Mendoza Elementary School',
  'Menger El',
  'Menifee County High School',
  'Menifee Elementary School',
  'Menifee Preschool',
  'Menifee Valley Middle',
  'Menlo Avenue Elementary',
  'Menlo Elementary School',
  'Menlo Park Academy',
  'Menlo Park Academy Extended',
  'Menlo Park Elementary School',
  'Menlo Park Terrace Elementary School',
  'Menlo-atherton High',
  'Menno Elementary - 02',
  'Menno High School - 01',
  'Menno Middle School - 05',
  'Menoken Elementary School',
  'Menominee Elementary School',
  'Menominee High School',
  'Menominee Indian High',
  'Menominee Indian Middle',
  'Menominee Isd Special Education',
  'Menominee Jr High School',
  'Menominee Tribal School',
  'Menomonee Falls High',
  'Menomonie High',
  'Menomonie Middle',
  'Menotomy Preschool',
  'Menoufia University',
  'Mental Health Center',
  'Mentally Handicapped',
  'Mentasta Lake School',
  'Mentone Elementary',
  'Mentone Elementary School',
  'Mentor High School',
  'Mentorship Steam Academy',
  'Meramec Elem.',
  'Meramec Heights Elem.',
  'Meramec Valley Community Sch.',
  'Meramec Valley Early Child.',
  'Meramec Valley Middle',
  'Merancas Middle College High',
  'Meraux Elementary',
  'Merc',
  'Merc Extended',
  'Merced City School District Community Day',
  'Merced College',
  'Merced County Juvenile Court',
  'Merced County Rop',
  'Merced County Special Education',
  'Merced Elementary',
  'Merced High',
  'Merced Marcano',
  'Merced Scholars Charter',
  'Mercedes Academic Academy',
  'Mercedes Daep',
  'Mercedes Early Childhood Center',
  'Mercedes Early College Academy',
  'Mercedes Garcia De Colorado',
  'Mercedes H S',
  'Mercedes Rosado',
  'Mercer & Blumberg Lrn Ctr',
  'Mercer Area El Sch',
  'Mercer Area Ms',
  'Mercer Area Shs',
  'Mercer Central',
  'Mercer County Career Center',
  'Mercer County Community College',
  'Mercer County Day Treatment',
  'Mercer County Early Learning Center',
  'Mercer County Elementary School',
  'Mercer County Head Start',
  'Mercer County High School',
  'Mercer County Intermediate School',
  'Mercer County Jr High School',
  'Mercer County Senior High School',
  'Mercer County Technical Education Center',
  'Mercer County Technical School Culinary Arts Academy',
  'Mercer County Technical School Health Sciences Academy',
  'Mercer County Technical School Stem Academy',
  'Mercer Elementary',
  'Mercer Elementary School',
  'Mercer High',
  'Mercer High School',
  'Mercer International Middle School',
  'Mercer Island High School',
  'Mercer Middle',
  'Mercer Middle School',
  'Mercer Regional School',
  'Mercer School',
  'Mercer University',
  'Mercersburg El Sch',
  'Mercerville Elementary School',
  'Mercey Springs Elementary',
  'Merchantville Elementary School',
  'Mercury Mine Elementary School',
  'Mercy College',
  'Mercy Community Academy',
  'Mercyhurst College',
  'Meredith (everett) Middle School',
  'Meredith College',
  'Meredith Hill Elementary School',
  'Meredith Middle School',
  'Meredith Nicholson Elementary Sch',
  'Meredith Nicholson School 96',
  'Meredith William M Sch',
  'Meredosia-chambersburg Elem Sch',
  'Meredosia-chambersburg High Sch',
  'Meredosia-chambersburg Jr High',
  'Mergenthaler Vocational-technical High',
  'Meridian',
  'Meridian Academy',
  'Meridian Community College',
  'Meridian Creek Middle School',
  'Meridian Early College High School',
  'Meridian El',
  'Meridian Elem School',
  'Meridian Elementary',
  'Meridian Elementary School',
  'Meridian H S',
  'Meridian High School',
  'Meridian Impact Re-engagement',
  'Meridian Jr High School',
  'Meridian Junior High School',
  'Meridian Medical Arts Charter',
  'Meridian Middle School',
  'Meridian Parent Partnership Program',
  'Meridian Park Elementary School',
  'Meridian Pcs',
  'Meridian Ranch Elementary School',
  'Meridian School',
  'Meridian Special Programs',
  'Meridian Street Elementary School',
  'Meridian Technical Charter High School',
  'Meridian World School Llc',
  'Meridiana El',
  'Meridianville Middle School',
  'Meridith-dunbar Early Childhood Academy',
  'Merino Elementary School',
  'Merino Junior Senior High School',
  'Merion El Sch',
  'Merit Academy',
  'Merit College Preparatory Academy',
  'Merit Preparatory Charter School Of Newark',
  'Meriwether Elementary School',
  'Meriwether Lewis Elementary',
  'Merizo Martyrs Elementary School',
  'Merkel El',
  'Merkel H S',
  'Merkel Middle',
  'Merkur Internationale FH Karlsruhe',
  'Merle J Abbett Elementary School',
  'Merle L. Fuller Elementary',
  'Merle Sidener Gifted Academy',
  'Merlimau Polytechnic',
  'Merlinda Elementary',
  'Mermentau Elementary School',
  'Meroby Elementary School',
  'Merquin Elementary',
  'Merreline A Kangas School',
  'Merriam Avenue School',
  'Merriam Cherry Street Elementary',
  'Merriam Park Elementary',
  'Merriam School',
  'Merrick Academy-queens Public Charter School',
  'Merrick Avenue Middle School',
  'Merrick-moore Elementary',
  'Merrifield El',
  'Merrill Adult Diploma Academy',
  'Merrill Elem School',
  'Merrill Elementary',
  'Merrill Elementary School',
  'Merrill F. West High',
  'Merrill High',
  'Merrill High School',
  'Merrill Middle',
  'Merrill Middle School',
  'Merrill Park Elementary School',
  'Merrill Road Elementary School',
  'Merrillville High School',
  'Merrillville Intermediate School',
  'Merrimac Community',
  'Merrimac School',
  'Merrimack High School',
  'Merrimack Middle School',
  'Merrimack Valley High School',
  'Merrimack Valley Middle School',
  'Merriman Park El',
  'Merritt Academy',
  'Merritt Brown Middle School',
  'Merritt College',
  'Merritt Creek Academy',
  'Merritt Elementary',
  'Merritt Elementary School',
  'Merritt Es',
  'Merritt Hs',
  'Merritt Island High School',
  'Merritt Memorial',
  'Merritt Trace Elementary',
  'Merriwether Elementary',
  'Merriwether Middle',
  'Merrol Hyde Magnet School',
  'Merry Acres Middle School',
  'Merry Elementary School',
  'Merry Oaks International Academy',
  'Merrydale Elementary School',
  'Merryman Elementary',
  'Merrymount',
  'Merryville High School',
  'Merrywood Elementary',
  'Mersin University',
  'Merton E. Hill Elementary',
  'Merton Intermediate',
  'Merton Primary',
  'Merton Williams Middle School',
  'Meru University of Science and Technology',
  'Mervin Iverson Elementary School',
  'Merwin Elementary',
  'Merwin Elementary School',
  'Meryl S Colt Elementary School',
  'Merz Akademie Hochschule für Gestaltung Stuttgart',
  'Mesa Academy For Advanced Studies',
  'Mesa Alta Jr High',
  'Mesa Arts Academy',
  'Mesa Community College',
  'Mesa Distance Learning Program',
  'Mesa Elem',
  'Mesa Elementary',
  'Mesa Elementary School',
  'Mesa Grande Elementary',
  'Mesa H S',
  'Mesa High School',
  'Mesa Linda Middle',
  'Mesa Middle',
  'Mesa Middle School',
  'Mesa Ridge High School',
  'Mesa Robles',
  'Mesa Transitional Learning Center',
  'Mesa Valley Community School',
  'Mesa Verde El',
  'Mesa Verde Elementary',
  'Mesa Verde Elementary School',
  'Mesa Verde High',
  'Mesa Verde Middle',
  'Mesa View Elementary',
  'Mesa View Elementary School',
  'Mesa View Middle',
  'Mesa View Middle School',
  'Mesa Vista El',
  'Mesa Vista High',
  'Mesa Vista Middle',
  'Mesabi Area Learning Center',
  'Mesabi East Elementary',
  'Mesabi East Secondary',
  'Mesabi Range Community and Technical College',
  'Mesalands Community College',
  'Mescalero Apache School',
  'Meshik School',
  'Mesick Consolidated Jrsr High School',
  'Mesilla Elementary',
  'Mesilla Park Elementary',
  'Mesilla Valley Alternative Middle School',
  'Mesita El',
  'Meskwaki Settlement School',
  'Mesnier Primary Sch.',
  'Mesopotamia Elementary School',
  'Mesquite Academy',
  'Mesquite Continuation High',
  'Mesquite Elementary',
  'Mesquite Elementary School',
  'Mesquite H S',
  'Mesquite High School',
  'Mesquite Jr High School',
  'Mesquite Trails Elementary',
  'Messalonskee High School',
  'Messalonskee Middle School',
  'Messiah College',
  'Messmore Elementary School',
  'Met Day Treatment Center',
  'Metacomet School',
  'Metairie Academy For Advanced Studies',
  'Metamora Grade School',
  'Metamora High School',
  'Metcalf El',
  'Metcalf Elementary School',
  'Metcalf School',
  'Metcalfe County Elementary School',
  'Metcalfe County High School',
  'Metcalfe County Middle School',
  'Metcalfe Elem Community Academy',
  'Metcalfe Elementary',
  'Metea Valley High School',
  'Methacton Hs',
  'Method Schools High',
  'Method Schools K-8',
  "Methodist Children's Home",
  'Methodist Home For Children Of Greater New Orleans',
  'Methodschools',
  'Methow Valley Elementary',
  'Methuen High',
  'Metlakatla High School',
  'Metolius Elementary School',
  'Metompkin Elementary',
  'Metro Charter',
  'Metro Charter Academy',
  'Metro Deaf School',
  'Metro Early College High School',
  'Metro East Web Academy',
  'Metro Heights Academy',
  'Metro High',
  'Metro High School',
  'Metro Institute Of Technology',
  'Metro North Elementary School',
  'Metro Opportunity',
  'Metro Regional Youth Detention Center',
  'Metro Richmond Alternative Ed',
  'Metro School',
  'Metro Schools Charter',
  'Metro Schools Middle Charter',
  'Metro Tech High School',
  'Metro West Detention Facility',
  'Metrolina Reg Scholars Academy',
  'Metroplex School',
  'Metropolis Elem School',
  'Metropolitan Arts Institute',
  'Metropolitan Business Academy',
  'Metropolitan Career Tech Ctr',
  'Metropolitan Community College (MO)',
  'Metropolitan Community College (NE)',
  'Metropolitan Continuation',
  'Metropolitan Diploma Plus High School',
  'Metropolitan Expeditionary Learning School',
  'Metropolitan High School (the)',
  'Metropolitan Learning Center',
  'Metropolitan Learning Center For Global And International S',
  'Metropolitan Lighthouse Charter School',
  'Metropolitan Soundview High School (the)',
  'Metropolitan State College of Denver',
  'Metropolitan State University',
  'Metropolitan University',
  'Metropolitan Vo-tech School',
  'Metrowest Elementary',
  'Mettawee Community School Usd #47',
  'Metter Elementary School',
  'Metter High School',
  'Metter Intermediate School',
  'Metter Middle School',
  'Mettie Jordan Elementary',
  'Metuchen High School',
  'Metwest High',
  'Metz El',
  'Metzgar El Sch',
  'Metzger Elementary School',
  'Metzler El',
  'Mevers School Of Excellence',
  'Mexia H S',
  'Mexia J H',
  'Mexicayotl Academy',
  'Mexicayotl Charter School',
  'Mexico Elementary School',
  'Mexico High',
  'Mexico High School',
  'Mexico Middle',
  'Mexico Middle School',
  'Meyer Center For Special Children',
  'Meyer El',
  'Meyer Elementary School',
  'Meyer Middle',
  'Meyerland Middle',
  'Meyerpark El',
  'Meyers Center For Special Children',
  'Meyersdale Area El Sch',
  'Meyersdale Area Hs',
  'Meyersdale Area Ms',
  'Meyersville El',
  'Meyler Street Elementary',
  'Meyzeek Middle',
  'Mfl Marmac Elementary School',
  'Mfl Marmac Hs',
  'Mfl Marmac Middle School',
  'Mg Ellis',
  'Mg Millet Learning Center',
  'Mh Specht El',
  'Mhs Ames Campus',
  'Miami Arts Charter',
  'Miami Arts Studio 6-12 At Zelda Glazer',
  'Miami Beach Adult & Community Ed Center',
  'Miami Beach Senior High School',
  'Miami Bridge North',
  'Miami Bridge South',
  'Miami Carol City Senior High',
  'Miami Central Senior High School',
  "Miami Children's Museum Charter School",
  'Miami Community Charter High School',
  'Miami Community Charter Middle School',
  'Miami Community Charter School',
  'Miami Coral Park High Adult Education',
  'Miami Coral Park Senior High',
  'Miami East Elementary',
  'Miami East High School',
  'Miami East Junior High School',
  'Miami Edison Senior High School',
  'Miami Elem.',
  'Miami Elementary School',
  'Miami Gardens Elementary School',
  'Miami Heights Elementary School',
  'Miami High',
  'Miami Hs',
  'Miami Jackson Senior Adult Ed Center',
  'Miami Jackson Senior High School',
  'Miami Junior Senior High School',
  'Miami Killian Senior High School',
  'Miami Lakes Educational Center',
  'Miami Lakes Educational Center And Technical College',
  'Miami Lakes K-8 Center',
  'Miami Lakes Middle School',
  'Miami Macarthur South',
  'Miami Middle School',
  'Miami Norland Senior High School',
  'Miami Northwestern Senior High',
  'Miami Palmetto Senior High Adult',
  'Miami Palmetto Senior High School',
  'Miami Park Elementary School',
  'Miami School',
  'Miami Senior Adult Education Center',
  'Miami Senior High School',
  'Miami Shores Elementary School',
  'Miami Southridge Senior High',
  'Miami Springs Elementary School',
  'Miami Springs Middle School',
  'Miami Springs Senior High Adult',
  'Miami Springs Senior High School',
  'Miami Sunset Adult Education Center',
  'Miami Sunset Senior High School',
  'Miami Trace Elementary School',
  'Miami Trace High School',
  'Miami Trace Middle School',
  'Miami University of Ohio',
  'Miami Valley Academies',
  'Miami Valley Ctc',
  'Miami Valley Ctc Alternative',
  'Miami View Elementary School',
  'Miami Youth Academy',
  'Miami-dade Online Academy- Virtual Instruction Program',
  'Miami-dade Virtual High School Franchise',
  'Miami-yoder Middle/high School',
  'Miami/yoder Elementary School',
  'Miamisburg High School',
  'Miamisburg Middle School',
  'Miamisburg Secondary Academy',
  'Miamitown Elementary School',
  'Mica Peak High School',
  'Micaela Escudero',
  'Micanopy Area Cooperative School Inc.',
  'Micanopy Middle School Inc.',
  'Micaville Elementary',
  'Miccosukee Indian School',
  'Michael A Maroun Elementary School',
  'Michael Anderson',
  'Michael Anderson Elementary',
  'Michael C. Riley Elementary',
  'Michael Collins Elem School',
  "Michael D'arcy Elementary",
  'Michael Driscoll',
  'Michael E Baum Elem School',
  'Michael E Fossum Middle',
  'Michael E. Smith Middle School',
  'Michael El',
  'Michael F Stokes School',
  'Michael F. Wallace Autism',
  'Michael F. Wallace Middle School',
  'Michael G. Wickman Elementary',
  'Michael Grimmer Middle School',
  'Michael J Mcgivney',
  'Michael J Perkins',
  'Michael J Petrides School (the)',
  'Michael J. Castori Elementary',
  'Michael Mccoy Elementary',
  'Michael Okpara University of Agriculture',
  'Michael R Null Middle',
  'Michael R. Hollis Innovation Academy',
  'Michael R. White',
  'Michael T Simmons Elementary',
  'Michelle Obama Elementary',
  'Michelle Obama Sch Of Technology',
  'Micheltorena Street Elementary',
  'Michener Elementary School',
  'Michie Elementary',
  'Michigamme Elementary School',
  'Michigan Avenue Academy',
  'Michigan Avenue Elementary School',
  'Michigan Center Jrsr High School',
  'Michigan City High School',
  'Michigan Connections Academy',
  'Michigan Cyber Academy',
  'Michigan Great Lakes Virtual Academy',
  'Michigan International Prep School',
  'Michigan Mathematics And Science Academy Elementary',
  'Michigan Mathematics And Science Academy Middlehigh',
  'Michigan Online School',
  'Michigan Primary School',
  'Michigan Reformatory',
  'Michigan School For The Arts',
  'Michigan School For The Deaf',
  'Michigan State University',
  'Michigan Technical Academy Elementary',
  'Michigan Technical Academy Middle School',
  'Michigan Technological University',
  'Michigan Virtual Charter Academy',
  'Michigan Youth Challenge Academy',
  'Mickey Cox Elementary',
  'Mickey Leland College Prep Acad For Young Men',
  'Mickle Middle School',
  'Micro Elementary',
  'Microsociety Academy Charter School Of Southern Nh',
  'Microsociety Magnet School',
  'Mid City High',
  'Mid Michigan Community College',
  'Mid Ohio Valley Technical Institute',
  'Mid Peninsula Reg Spec Ed Ctr',
  'Mid Peninsula School',
  'Mid Rivers Elem.',
  'Mid Stat Ed Dist. Success Prog.',
  'Mid State Ed Dist Steps Trans. Prog',
  'Mid Valley Alternative Charter',
  'Mid Valley El Ctr',
  'Mid Valley Elementary School',
  'Mid Valley High (continuation)',
  'Mid Valley Secondary Center',
  'Mid-America Nazarene College',
  'Mid-Sweden University',
  'Mid-buchanan Elem.',
  'Mid-buchanan Sr. High',
  'Mid-carolina High',
  'Mid-carolina Middle',
  "Mid-city's Prescott School Of Enriched Sciences",
  'Mid-coast Sch Of Tech-region 8',
  'Mid-columbia Parent Partnership',
  'Mid-maine Technical Center',
  'Mid-prairie East Elem. School',
  'Mid-prairie High School',
  'Mid-prairie Middle School',
  'Mid-prairie West Elem. School',
  'Mid-south Comm College Tech Center',
  'Mid-state North',
  'Mid-valley Academy (pk-3)',
  'Mid-​Plains Community College',
  'Mid-​South Community College',
  'Mid-​State Technical College',
  'Midas Creek School',
  'Midd-west Hs',
  'Midd-west Ms',
  'Middle #10',
  'Middle #9',
  'Middle Area Learning Center',
  'Middle Bucks Institute Of Technology',
  'Middle Canyon School',
  'Middle College',
  'Middle College @ Austin Peay State University',
  'Middle College At Gtcc-greensboro',
  'Middle College At Gtcc-high Point',
  'Middle College At Nc A&t',
  'Middle College H S At Hcc Fraga',
  'Middle College H S At Hcc Gulfton',
  'Middle College High',
  'Middle College High At Bennett',
  'Middle College High School',
  'Middle College High School At Laguardia Community College',
  'Middle College Hs @ Dtcc',
  'Middle College Of Forsyth Cnty',
  'Middle Creek Elementary',
  'Middle Creek Elementary School',
  'Middle Creek High',
  'Middle Early College High School',
  'Middle East Technical University',
  'Middle East University',
  'Middle Fork Elementary',
  'Middle Fork School',
  'Middle Gate Elementary School',
  'Middle Georgia State College',
  'Middle Grove Elem.',
  'Middle Level Alternative Program',
  'Middle Level Learning Center',
  'Middle Lvl Lrn Ctr',
  'Middle Park High School',
  'Middle Paxton El Sch',
  'Middle Peninsula Juvenile Detention Home',
  'Middle Ridge Elementary School',
  'Middle River Elementary',
  'Middle River Middle',
  'Middle Road School',
  'Middle Sch.',
  'Middle School',
  'Middle School # 4',
  'Middle School 322',
  'Middle School Aa',
  'Middle School Area Learning Center',
  'Middle School At Parkside',
  'Middle School At Syracuse',
  'Middle School For Art And Philosphy',
  'Middle School Immersion Center - 53',
  'Middle School Mathematics Science Technology Center',
  'Middle School Of Marketing And Legal Studies',
  'Middle School Of Pacolet',
  'Middle School Of Plainville',
  'Middle School Of Poplarville',
  'Middle School Of The Kennebunks',
  'Middle School Options',
  'Middle School Sails',
  'Middle School Steps 7-8',
  'Middle Smithfield El Sch',
  'Middle Tennessee State University',
  'Middle Township Elementary #1',
  'Middle Township Elementary #2',
  'Middle Township Elementary #4',
  'Middle Township High School',
  'Middle Valley Elementary',
  'Middle Village Preparatory Charter School',
  'Middle Years Alternative',
  'Middleberg Public School',
  'Middleborough Elementary',
  'Middleborough High',
  'Middlebranch Elementary School',
  'Middlebrook School',
  'Middleburg Community Charter',
  'Middleburg El Sch',
  'Middleburg Elementary School',
  'Middleburg Heights Junior High',
  'Middleburg High School',
  'Middleburgh Elementary School',
  'Middleburgh Junior/senior High School',
  'Middlebury Academy',
  'Middlebury College',
  'Middlebury Elementary School',
  'Middlebury Id #4 School',
  'Middlebury Union High School',
  'Middlebury Union Middle School',
  'Middlefield Memorial School',
  'Middlefork Primary School',
  'Middlefork School Rssp',
  'Middlesboro Alternative School',
  'Middlesboro Elementary School',
  'Middlesboro High School',
  'Middlesboro Middle School',
  'Middlesboro Preschool',
  'Middlesettlements',
  'Middlesex Co Voc School East Bruns. School Of Career Develop',
  'Middlesex Co Voc School Piscataway School Of Career Develop',
  'Middlesex Co Youth Ctr',
  'Middlesex Community College',
  'Middlesex County College',
  'Middlesex County Voc Acad Allied Health & Biomedical Science',
  'Middlesex County Voc Academy Math Science &engineering Techn',
  'Middlesex County Vocational School East Brunswick',
  'Middlesex County Vocational School Perth Amboy',
  'Middlesex County Vocational School Piscataway',
  'Middlesex El Sch',
  'Middlesex Elementary',
  'Middlesex High',
  'Middlesex High School',
  'Middlesex Middle School',
  'Middlesex Transition Academy',
  'Middlesex University',
  'Middlesex Valley Elementary School',
  'Middleton Academy',
  'Middleton Elementary',
  'Middleton Elementary School',
  'Middleton Heights Elementary',
  'Middleton High',
  'Middleton High School',
  'Middleton Middle School',
  'Middleton Mill Creek Elementary',
  'Middleton Primary Center',
  'Middleton Street Elementary',
  'Middleton-burney Elementary School',
  'Middleton-cross Plains Area School District 4k',
  'Middletown Area Hs',
  'Middletown Area Ms',
  'Middletown Elementary',
  'Middletown High',
  'Middletown High School',
  'Middletown High School North',
  'Middletown High School South',
  'Middletown Middle',
  'Middletown Middle School',
  'Middletown Prarie Elementary',
  'Middletown Preparatory & Fitness Academy',
  'Middletown Primary',
  'Middletown Springs Elementary School',
  'Middletown Twin Towers Middle School',
  'Middletown Village Elementary School',
  'Midfield Area Vocational Department',
  'Midfield Elementary School',
  'Midfield High School',
  'Midkota Elementary School',
  'Midkota High School',
  'Midlakes High School',
  'Midlakes Intermediate School',
  'Midlakes Middle School',
  'Midlakes Primary School',
  'Midland Academy',
  'Midland Academy Charter School',
  'Midland Academy Of Advanced And Creative Studies',
  'Midland Alternative Program',
  'Midland City Elementary School',
  'Midland El/ms',
  'Midland Elementary',
  'Midland Elementary - 11',
  'Midland Elementary School',
  'Midland Freshman H S',
  'Midland H S',
  'Midland High School',
  'Midland Middle School',
  'Midland Middle/high School',
  'Midland Park Jr./sr. High School',
  'Midland Park Primary',
  'Midland School',
  'Midland School #1',
  'Midland Stem Institute',
  'Midland Street',
  'Midland Trail',
  'Midland Trail Elementary School',
  'Midland Trail High',
  'Midland Valley High',
  'Midlands Middle College',
  'Midlands State University',
  'Midlands Technical College',
  'Midlothian H S',
  'Midlothian Heritage H S',
  'Midlothian High',
  'Midlothian Middle',
  'Midmichigan Correctional Facility',
  'Midmichigan Leadership Academy',
  'Midnight Sun Elementary School',
  'Midnight Sun Family Learning Center',
  'Midnimo Cross Cultural Community School',
  'Midstreams Elementary School',
  'Midtown Academy',
  'Midtown Community Elementary School',
  'Midtown Community School #8',
  'Midtown Educational Center',
  'Midtown Elementary',
  'Midtown High School',
  'Midtown Primary School',
  'Midtown Public Charter School',
  'Midvale Alternative School',
  'Midvale Early Childhood Center',
  'Midvale Elementary',
  'Midvale Elementary School',
  'Midvale Middle',
  'Midvale School',
  'Midvalley Academy-mcallen Charter H S',
  'Midvalley Academy-mercedes Charter H S',
  'Midvalley Academy-san Benito Charter H S',
  'Midvalley School',
  'Midview East Intermediate School',
  'Midview High School',
  'Midview Middle School',
  'Midview North Elementary School',
  'Midview West Elementary School',
  'Midway Alternative H S',
  'Midway Colony',
  'Midway Early Childhood Center',
  'Midway Elem.',
  'Midway Elementary',
  'Midway Elementary School',
  'Midway Es',
  'Midway H S',
  'Midway Heights Elem.',
  'Midway High',
  'Midway High School',
  'Midway Hills Academy',
  'Midway Hills Primary',
  'Midway Hs',
  'Midway Middle',
  'Midway Middle School',
  'Midway Park El',
  'Midway Professional Development Center',
  'Midway School',
  'Midwest Central High School',
  'Midwest Central Middle School',
  'Midwest Central Primary School',
  'Midwest City Es',
  'Midwest City Hs',
  'Midwest School',
  'Midwest Special Education Coop.',
  'Midwestern State University',
  'Midwood High School',
  'Mie University',
  'Mifflin Alternative Middle School',
  'Mifflin Co Hs',
  'Mifflin Co Jhs',
  'Mifflin Co Ms',
  'Mifflin County Academy Of Science And Te',
  'Mifflin Elementary School',
  'Mifflin High School',
  'Mifflin Park El Sch',
  'Mifflin Thomas Sch',
  'Mifflinburg Area Intrmd Sch',
  'Mifflinburg Area Ms',
  'Mifflinburg Area Shs',
  'Mifflinburg El Sch',
  'Mighty Oaks Early Learning School',
  'Mighty River Academy Of Virtual Education',
  'Miguel A Sastre Oliver',
  'Miguel A. Rivera',
  'Miguel Carrillo Jr El',
  'Miguel De Cervantes Saavedra',
  'Miguel F. Chiques',
  'Miguel Gonzalez Bauza',
  'Miguel Hidalgo Elementary',
  'Miguel Juarez Middle School',
  'Miguel Melendez Munoz',
  'Miguel Sepulveda Elementary School',
  'Miguel Such',
  'Miguelito Elementary',
  'Mijted - Ashfork High School',
  'Mijted - Bagdad High School',
  'Mijted - Bradshaw Mountain High School',
  'Mijted - Chino Valley High School',
  'Mijted - Mayer High School',
  'Mijted - Mountain Institute Central Campus',
  'Mijted - Prescott High School',
  'Mijted - Seligman High School',
  'Mike Choate Early Childhood Education Center',
  'Mike Clemens Center Adapt Lrng',
  'Mike Davis Elementary School',
  'Mike Miller Elementary - 07',
  'Mike Moseley El',
  'Mike Moses Middle',
  'Mike Ocallaghan Middle School',
  'Mike Sena Elementary',
  'Mikelnguut Elitnaurviat',
  'Mila Elementary School',
  'Milaca Area Learning Center',
  'Milaca Elementary',
  'Milaca Secondary High',
  'Milagro Middle',
  'Milam El',
  'Milam Elementary School',
  'Milan Alternative Education',
  'Milan Elem.',
  'Milan Elementary',
  'Milan Elementary School',
  'Milan High',
  'Milan High School',
  'Milan Intermediate School',
  'Milan Middle',
  'Milan Middle School',
  'Milan Village Elementary School',
  'Milano El',
  'Milano H S',
  'Milano J H',
  'Milbank High School - 01',
  'Milbank Middle School - 02',
  'Milbridge Elementary School',
  'Milbrook Elementary',
  'Milburn Es',
  'Milburn Hs',
  'Milby H S',
  'Mildred Aitken School',
  'Mildred Avenue K-8',
  'Mildred B. Janson Elementary',
  'Mildred B. Moss Elementary School',
  'Mildred Barry Garvin Elementary',
  'Mildred C Wells Preparatory Academy',
  'Mildred C. Lakeway School',
  'Mildred E Strang Middle School',
  'Mildred E. Lineham School',
  'Mildred El',
  'Mildred H S',
  'Mildred Helms Elementary School',
  'Mildred Jenkins El',
  'Mildred L Day School',
  'Mildred L Sanville Preschool',
  'Mildred Magowan Elementary School',
  'Mildred Merkley Elementary School',
  'Mildred Osborne Charter School',
  'Mildred Perkins Elementary',
  'Mildred S. Harris Elementary School',
  'Mile Creek School',
  'Mile Tree Elementary',
  'Miles Avenue Elementary',
  'Miles Avenue School',
  'Miles Community College',
  'Miles El',
  'Miles Elementary School',
  'Miles H S',
  'Miles Intermediate School',
  'Miles Jones Elementary',
  'Miles Lane School',
  'Miles P. Richmond',
  'Miles Park School',
  'Miles River Middle',
  'Miles School',
  'Miles Township El Sch',
  'Miles W. Culwell Community Day',
  'Miles-exploratory Learning Center',
  'Milestone Academy',
  'Milestones Charter School',
  'Milesville Elementary - 08',
  'Miley Achievement Center Es',
  'Miley Achievement Center Sec',
  'Milford Brook School',
  'Milford Central Academy',
  'Milford Central School',
  'Milford Elem',
  'Milford Elementary School',
  'Milford Grade School East Campus',
  'Milford Grade School West Campus',
  'Milford High',
  'Milford High School',
  'Milford High School Campus',
  'Milford Junior High School',
  'Milford Middle School',
  'Milford Mill Academy',
  'Milford Ms',
  'Milford Preschool And Extended Day',
  'Milford School',
  'Milford Senior High School',
  'Milford Sr High School',
  'Mililani High School',
  'Mililani Ike Elementary School',
  'Mililani Mauka Elementary School',
  'Mililani Middle School',
  'Mililani Uka Elementary School',
  'Mililani Waena Elementary School',
  'Military And Global Leadership Academy',
  'Military Hgts Elementary',
  'Military Institute of Science and Technology',
  'Military Magnet Academy',
  'Military Technical College',
  'Military University Shoumen',
  'Military academy of Lithuania',
  'Milk (harvey) Civil Rights Elementary',
  'Milkovich Middle School',
  'Mill A Elementary School',
  'Mill Brook School',
  'Mill City El Sch',
  'Mill Creek East Elementary',
  'Mill Creek El Sch',
  'Mill Creek Elem',
  'Mill Creek Elem.',
  'Mill Creek Elementary',
  'Mill Creek Elementary School',
  'Mill Creek Es',
  'Mill Creek High School',
  'Mill Creek Hs',
  'Mill Creek Intermediate School',
  'Mill Creek Middle',
  'Mill Creek Middle School',
  'Mill Creek School',
  'Mill Creek Towne Elementary',
  'Mill Creek Upper Elem.',
  'Mill Creek West Elementary',
  'Mill Elementary',
  'Mill Falls Charter School',
  'Mill Hall El Sch',
  'Mill Hill School',
  'Mill Lake School',
  'Mill Middle School',
  'Mill Park Elementary School',
  'Mill Plain Elementary School',
  'Mill Pond Elementary School',
  'Mill Pond School',
  'Mill Ridge Primary School',
  'Mill River Union High School',
  'Mill Road El Sch',
  'Mill Road-intermediate Grades',
  'Mill Road-primary Grades',
  'Mill Run Elementary',
  'Mill Stream Elementary School',
  'Mill Street Elem School',
  'Mill Street Elementary',
  'Mill Valley Elementary',
  'Mill Valley Elementary School',
  'Mill Valley High School',
  'Mill Valley Middle',
  'Mill Village El Sch',
  'Millard Area Technology Center',
  'Millard Central Middle School',
  'Millard Fillmore Elementary School',
  'Millard Hawk Elementary School',
  'Millard High',
  'Millard Mccollam Elementary',
  'Millard North High School',
  'Millard North Middle School',
  'Millard School',
  'Millard South High School',
  'Millard West High School',
  'Millboro Elementary',
  'Millbridge Elementary',
  'Millbridge Elementary School',
  'Millbrook Colony Elementary - 04',
  'Millbrook Elementary',
  'Millbrook High',
  'Millbrook High School',
  'Millbrook Junior High School',
  'Millbrook Middle School',
  'Millbrooke Elementary',
  'Millbrooke Elementary School',
  'Millburn Elem School',
  'Millburn High School',
  'Millburn Middle School',
  'Millbury Junior/senior High',
  'Millbury Street Elementary School',
  'Millcreek Elementary School',
  'Millcreek High',
  'Millcreek Jr High',
  'Mille Lacs Academy (nexus)',
  'Milledgeville Elem School',
  'Milledgeville High School',
  'Millegeville Itu',
  'Millenium Art Academy',
  'Millennia Elementary',
  'Millennia Gardens Elementary',
  'Millennial Tech Middle',
  'Millennium 6-12 Collegiate Academy',
  'Millennium Brooklyn High School',
  'Millennium Charter',
  'Millennium Charter Academy',
  'Millennium Charter High',
  'Millennium Community School',
  'Millennium Elem School',
  'Millennium Elementary',
  'Millennium Elementary School',
  'Millennium High Alternative',
  'Millennium High School',
  'Millennium Middle School',
  'Miller',
  'Miller (isaac) Elementary',
  'Miller Avenue Elementary School',
  'Miller Avenue School',
  'Miller Career Academy',
  'Miller City Elementary School',
  'Miller City High School',
  'Miller City Middle School',
  'Miller Co. Elem.',
  'Miller County Elementary School',
  'Miller County High School',
  'Miller County Middle School',
  'Miller Creek Middle',
  'Miller El',
  'Miller El Sch',
  'Miller Elem',
  'Miller Elementary',
  'Miller Elementary - 02',
  'Miller Elementary School',
  'Miller Fred C. Camp',
  'Miller Grove High School',
  'Miller Grove Middle School',
  'Miller Grove School',
  'Miller Heights El',
  'Miller Heights El Sch',
  'Miller High',
  'Miller High School',
  'Miller High School - 01',
  'Miller Int',
  'Miller Jordan Middle',
  'Miller Jr. High - 04',
  'Miller Junior High',
  'Miller Magnet Middle School',
  'Miller Middle',
  'Miller Middle School',
  'Miller Ms',
  'Miller Park Center',
  'Miller Park Elem School',
  'Miller Perry Elementary',
  'Miller Place High School',
  'Miller Point El',
  'Miller Ridge Elementary School',
  'Miller School',
  'Miller Special Education',
  'Miller Street School At Spencer',
  'Miller Valley School',
  'Miller Wall Elementary School',
  "Miller's Hill",
  'Miller-driscoll School',
  'Miller-south Visual Performing Arts',
  'Millerdale Colony Elementary - 11',
  'Millers Creek Elementary',
  'Millers Run Usd #37',
  'Millersburg Area Ms',
  'Millersburg Area Shs',
  'Millersburg Elementary School',
  'Millersburg Elementary- Middle Sch',
  'Millersport Elementary School',
  'Millersport Jr/sr High School',
  'Millersville Attendance Ctr.',
  'Millersville Elementary',
  'Millersville University of Pennsylvania',
  'Millicoma School',
  'Milligan Academy Rssp',
  'Milligan College',
  'Millikan High',
  'Millikan Sci Tech Engr And Math (stem) Magnet',
  'Milliken Elementary School',
  'Milliken Middle School',
  'Milliken Park Elementary Sch',
  'Millikin Elem School',
  'Millikin Elementary',
  'Millikin University',
  'Millingport Elementary',
  'Millington Central High School',
  'Millington Elementary',
  'Millington Elementary School',
  'Millington High School',
  'Millington Junior High School',
  'Millington Middle School',
  'Millis High School',
  'Millis Middle',
  'Millis Road Elementary',
  'Millmont El Sch',
  'Millridge Elementary School',
  'Millry High School',
  'Mills E. Godwin High',
  'Mills El',
  'Mills Elementary',
  'Mills Elementary School',
  'Mills Es',
  'Mills High',
  'Mills Lawn Elementary School',
  'Mills Middle',
  'Mills Park Elementary',
  'Mills Park Middle',
  'Mills Pond Elementary School',
  'Mills River Elementary',
  'Millsap El',
  'Millsap H S',
  'Millsap Middle',
  'Millsaps Career & Tech Center',
  'Millsaps College',
  'Millsboro Middle School',
  'Millside Elementary School',
  'Millstadt Consolidated School',
  'Millstadt Primary Center',
  'Millstone River School',
  'Millstone Township Elementary School',
  'Millstone Township Middle School',
  'Millstone Township Primary School',
  'Millstream Career Center',
  'Millswood Middle',
  'Milltown Primary School',
  'Millview Elementary',
  'Millville Area El Sch',
  'Millville Area Jshs',
  'Millville Elementary',
  'Millville Public Charter School',
  'Millville School',
  'Millville Senior High School',
  'Millwood Elementary',
  'Millwood Es',
  'Millwood Hs',
  'Millwood Kindergarten Center',
  'Milne El',
  'Milne Grove Elem School',
  'Milner School',
  'Milnor Elementary School',
  'Milnor High School',
  'Milo Elementary School',
  'Milor Continuation High',
  'Milpitas High',
  'Milroy Area Charter School',
  'Milroy Elementary',
  'Milroy Elementary School',
  'Milroy Junior High',
  'Milton Area Ms',
  'Milton Avenue School',
  'Milton B Lee Academy Of Science & Engineering',
  'Milton Bradley School',
  'Milton C Porter Ed Center',
  'Milton Cooper El',
  'Milton E Tucker Middle School',
  'Milton El',
  'Milton Elementary School',
  'Milton Fein School',
  'Milton Fuller Roberts',
  'Milton H. Allen Elementary School',
  'Milton High',
  'Milton High School',
  'Milton Hs',
  'Milton J Fletcher Elementary School',
  'Milton L Olive Middle School',
  'Milton M Daniels Learning Cntr',
  'Milton M. Somers Middle School',
  'Milton Middle',
  'Milton Middle School',
  'Milton Pope Elem School',
  'Milton School',
  'Milton Senior High School',
  'Milton Terrace Elementary School',
  'Milton-union Elementary School',
  'Milton-union High School',
  'Milton-union Middle School',
  'Miltona Elementary',
  'Miltonvale Elem',
  'Miltonvale High',
  'Milwaukee Academy Of Chinese Language',
  'Milwaukee Academy Of Science',
  'Milwaukee Area Technical College',
  'Milwaukee College Preparatory School -- 36th Street Campus',
  'Milwaukee College Preparatory School -- 38th Street',
  'Milwaukee College Preparatory School -- Lloyd Street',
  'Milwaukee College Preparatory School: Lola Rowe North Campus',
  'Milwaukee Collegiate Academy',
  'Milwaukee Community Cyber High',
  'Milwaukee County Correctional Facility South',
  'Milwaukee County Youth Education Center',
  'Milwaukee Environmental Science Academy',
  'Milwaukee Excellence Charter School',
  'Milwaukee French Immersion',
  'Milwaukee German Immersion',
  'Milwaukee High School Of The Arts',
  'Milwaukee Math And Science Academy',
  'Milwaukee Parkside School',
  'Milwaukee Scholars Charter School',
  'Milwaukee School Of Languages',
  'Milwaukee School of Engineering',
  'Milwaukee Secure Detention Facility',
  'Milwaukee Sign Language Elementary',
  'Milwaukee Spanish Immersion',
  'Milwaukie Academy Of The Arts',
  'Milwaukie Elementary School',
  'Milwaukie High School',
  'Milwee Middle School',
  'Milwood Elementary School',
  'Milwood Magnet School',
  'Mimar Sinan Fine Arts University',
  'Mimar Sinan University',
  "Mimasaka Women's College",
  'Mimbres Valley Alternative School',
  'Mimi Farley El',
  'Mimosa Elementary School',
  'Mimosa Park Elementary School',
  'Mims El',
  'Mims Elementary School',
  'Mina El',
  'Minamikyushu University',
  'Minarets Charter High',
  'Minarets High',
  'Minatare Elementary School',
  'Minatare High School',
  'Minatare Pre-start Pre-school',
  'Minco Es',
  'Minco Hs',
  'Minco Ms',
  'Mindanao State University',
  'Mindanao State University - Iligan Institute of Technology',
  'Minden East Elementary School',
  'Minden Elementary School',
  'Minden High School',
  'Minden Public Sch Pre-school',
  'Mindoro Elementary',
  'Mineola El',
  'Mineola H S',
  'Mineola High School',
  'Mineola Middle',
  'Mineola Middle School',
  'Mineola Pri',
  'Miner (george) Elementary',
  'Miner Academy',
  'Miner Es',
  'Miner School',
  'Mineral Alternative Education',
  'Mineral Area College',
  'Mineral County Adult Education School',
  'Mineral County Alternative Program',
  'Mineral County High School',
  'Mineral County Technical Center',
  'Mineral King Elementary',
  'Mineral Point Elementary',
  'Mineral Point High',
  'Mineral Point Middle',
  'Mineral Ridge High School',
  'Mineral Springs Elem. School',
  'Mineral Springs Elementary',
  'Mineral Springs High School',
  'Mineral Springs Middle',
  'Mineral Wells Academy',
  'Mineral Wells Elementary School',
  'Mineral Wells H S',
  'Mineral Wells J H',
  'Mineral Wells/palo Pinto County Daep',
  'Minersville Area El Ctr',
  'Minersville Area Jshs',
  'Minersville School',
  'Minerva Central School',
  'Minerva Deland School',
  'Minerva Elementary School',
  'Minerva High School',
  'Minerva Middle School',
  'Mines Elementary School',
  'Minetto Elementary School',
  'Minford High School',
  'Minford Middle School',
  'Minford Primary Elementary School',
  'Ming Chuan University',
  'Mingechevir State University',
  'Minges Brook School',
  'Minglewood Elementary',
  'Mingo Central Comprehensive High School',
  'Mingo County Extended Learning Center',
  'Mingo Puxico Technical High',
  'Mingus Online Academy',
  'Mingus Springs Charter School',
  'Mingus Union High School',
  'Minhaj University Lahore',
  'Mini-cassia Juvenile Detention Center',
  'Minia University',
  'Minico Senior High School',
  'Minima Oronoz',
  'Minisinaakwaang Leadership Academy',
  'Minisink Valley Elementary School',
  'Minisink Valley High School',
  'Minisink Valley Intermediate School',
  'Minisink Valley Middle School',
  'Minne Lusa Elem School',
  'Minneapolis College of Art and Design',
  'Minneapolis Community and Technical College',
  'Minneapolis Elementary',
  'Minneapolis High',
  'Minneapolis On Line Learning',
  'Minnechaug Regional High',
  'Minneha Core Knowledge Elem',
  'Minnehaha Elementary',
  'Minnehaha Elementary School',
  'Minneola Elem',
  'Minneola Elementary Conversion Charter School',
  'Minneola High',
  'Minneota Elementary',
  'Minneota Secondary',
  'Minnequa Elementary School',
  'Minnesauke Elementary School',
  'Minnesota Center',
  'Minnesota Connections Academy',
  'Minnesota Excellence In Learning Ac',
  'Minnesota Girls Academy',
  'Minnesota Math And Science Academy',
  'Minnesota New Country Elementary',
  'Minnesota New Country School',
  'Minnesota Online High School',
  'Minnesota State College-​Southeast Technical',
  'Minnesota State Community and Technical College',
  'Minnesota State University Mankato',
  'Minnesota State University Moorhead',
  'Minnesota Transitions Charter Elem',
  'Minnesota Valley School',
  'Minnesota Virtual Academy',
  'Minnesota West Community and Technical College',
  'Minnetonka East Middle',
  'Minnetonka Senior High',
  'Minnetonka West Middle',
  'Minnewashta Elementary',
  'Minnewaska Area Elementary',
  'Minnewaska Area Intermediate School',
  'Minnewaska Area Junior High',
  'Minnewaska Day Treatment Program',
  'Minnewaska Early Childhood Sp.ed.',
  'Minnewaska Secondary',
  'Minnewaska Secondary Alternative Pg',
  'Minnewaukan Elementary School',
  'Minnewaukan High School',
  'Minnie Cannon Elementary',
  'Minnie Cline Elem.',
  'Minnie H Kindergarten Center',
  'Minnie Hughes Elementary',
  'Minnie Ruffin Elementary School',
  'Minnieville Elementary',
  'Minoa Elementary School',
  'Minocqua Elementary',
  'Minooka Community High School',
  'Minooka Elem School',
  'Minooka Intermediate School',
  'Minooka Jr High School',
  'Minooka Primary Center',
  'Minor Community School',
  'Minor Daniels Academy',
  'Minor Elementary School',
  'Minor High School',
  'Minor Hill School',
  'Minor Middle School',
  'Minors Lane Elementary',
  'Minot Consolidated School',
  'Minot Forest',
  'Minot Spec Ed Unit',
  'Minot State University',
  'Minsk Institute of Management',
  'Minsk State Linguistic University',
  'Minster Elementary School',
  'Minster Jr/sr High School',
  'Mint Canyon Community Elementary',
  'Mint Hill Middle',
  'Mint Valley Elementary',
  'Minter Bridge Elementary School',
  'Minter Creek Elementary',
  'Mintie White Elementary',
  'Minto Elementary School',
  'Minto High School',
  'Minto School',
  'Mintonye Elementary School',
  'Mintz Elementary School',
  'Minuteman Regional High',
  'Mioausable Elementary School',
  'Mioausable High School',
  'Mira Catalina Elementary',
  'Mira Costa High',
  'Mira Loma High',
  'Mira Loma Middle',
  'Mira Mesa High',
  'Mira Monte Elementary',
  'Mira Monte High',
  'Mira Monte High (alternative)',
  'Mira Vista Elementary',
  'MiraCosta College',
  'Mirabile Elem.',
  'Miradero 2',
  'Mirage Elementary School',
  'Miraleste Elementary',
  'Miraleste Intermediate',
  'Miraloma Elementary',
  'Miramar Elementary School',
  'Miramar High School',
  'Miramar Ranch Elementary',
  'Miramonte Elementary',
  'Miramonte High',
  'Miranda Junior High',
  'Mire Elementary School',
  'Mireles El',
  'Mireles Elem Academy',
  'Miriam F Mccarthy School',
  'Mirror Lake Elementary School',
  'Mirror Lake Middle School',
  'Mirror Lakes Elementary School',
  'Mirus Secondary',
  'Miryang National University',
  'Misamis University',
  'Misan University',
  'Miscoe Hill School',
  'Mishawaka High School',
  'Mishicot High',
  'Mishicot Middle',
  'Mispillion Elementary',
  'Misr International University',
  'Misr University for Sience and Technology',
  'Miss May Vernon El',
  'Mission Academy',
  'Mission Achievement And Success',
  'Mission Avenue Elementary',
  'Mission Avenue Open Elementary',
  'Mission Bay High',
  'Mission Bell Elementary',
  'Mission Bend El',
  'Mission College',
  'Mission Collegiate H S',
  'Mission Community Day',
  'Mission Continuation',
  'Mission Crest Elementary',
  'Mission Early College H S',
  'Mission Early College High',
  'Mission Early Learning',
  'Mission Education Center',
  'Mission Elementary',
  'Mission Es',
  'Mission Estancia Elementary',
  'Mission Glen El',
  'Mission H S',
  'Mission Heights Preparatory High School',
  'Mission High',
  'Mission High School',
  'Mission Hill Middle',
  'Mission Hill School',
  'Mission Hills High',
  'Mission Intermediate Grd Ctr',
  'Mission J H',
  'Mission Manor Elementary School',
  'Mission Meadows Elementary',
  'Mission Middle',
  'Mission Montessori Academy',
  'Mission Oak High',
  'Mission Park Elementary',
  'Mission Preparatory',
  'Mission Ridge',
  'Mission Road Elementary School',
  'Mission San Jose Elementary',
  'Mission San Jose High',
  'Mission Trail Elementary',
  'Mission Trail Middle School',
  'Mission Trails Rop',
  'Mission Valley',
  'Mission Valley El',
  'Mission Valley Elementary',
  'Mission Valley Junior And Senior High School',
  'Mission Valley Rocp',
  'Mission Viejo Elementary School',
  'Mission Viejo High',
  'Mission View Elementary School',
  'Mission View Public',
  'Mission Vista High',
  'Mission West El',
  'Missisquoi Valley Uhsd #7',
  'Mississinawa Valley Elementary School',
  'Mississinawa Valley Jr/sr High School',
  'Mississinawa Valley Preschool',
  'Mississinewa High School',
  'Mississippi College',
  'Mississippi Creative Arts Elem',
  'Mississippi Delta Community College',
  'Mississippi Elementary',
  'Mississippi Gulf Coast Community College',
  'Mississippi Heights Elementary',
  'Mississippi State University',
  'Mississippi University for Women',
  'Mississippi Valley School',
  'Missouri Ave Elementary',
  'Missouri City Elem.',
  'Missouri City Middle',
  'Missouri Eastern Corr Center',
  'Missouri Ridge Elementary School',
  'Missouri Ridge Middle School',
  'Missouri River Area Career & Tech Center',
  'Missouri Southern State College',
  'Missouri State University',
  'Missouri State University-​West Plains',
  'Missouri University of Science and Technology',
  'Missouri Valley Elementary',
  'Missouri Valley High School',
  'Missouri Valley Middle School',
  'Missouri Western State College',
  'Mist Elementary School',
  'Mistletoe Elementary',
  'Mistral Center For Early Childhood',
  'Mit Academy',
  'Mitchell Boulevard El',
  'Mitchell Child Development',
  'Mitchell Community College',
  'Mitchell Community Elementary',
  'Mitchell County Elementary School',
  'Mitchell County High School',
  'Mitchell County Middle School',
  'Mitchell County Primary School',
  'Mitchell Early Childhood Center',
  'Mitchell El',
  'Mitchell El Sch',
  'Mitchell Elem School',
  'Mitchell Elementary',
  'Mitchell Elementary School',
  'Mitchell Es',
  'Mitchell Guidance Center',
  'Mitchell Guidance Center Middle',
  'Mitchell High',
  'Mitchell High School',
  'Mitchell High School - 01',
  'Mitchell Int',
  'Mitchell Intermediate',
  'Mitchell Jr High School',
  'Mitchell Middle',
  'Mitchell Middle School - 02',
  'Mitchell Road Elementary',
  'Mitchell School',
  'Mitchell Secondary School',
  'Mitchell Technical Institute',
  'Mitchell-neilson Elementary',
  'Mitchellville Elementary',
  'Mitkof Middle School',
  'Mittelstadt El',
  'Mittineague',
  'Mittye P. Locke Elementary School',
  'Mitzi Bond El',
  'Miwok Valley Language Academy Charter',
  'Mixter School',
  "Miyagi Gakuin Women's College",
  'Miyagi University',
  'Miyagi University of Education',
  'Miyamura High School',
  'Miyazaki Medical College',
  'Miyazaki Municipal University',
  'Miyazaki Prefectural Nursing University',
  'Miyazaki University',
  'Mizan Tepi University',
  'Mize Attendance Center',
  'Mize Elementary School',
  'Mky- Head Start /dpp Pk School',
  'Ml King Academy',
  'Mlk / Lafayette Elementary',
  'Mlk A R T Program',
  'Mlk Jr High Tech & Computer Magnet School',
  'Mls-mohall Elementary School',
  'Mls-mohall High School',
  'Mmcaa School Readinessfarwell',
  'Mmcru High School',
  'Mmcru Marcus Elementary',
  'Mn Academy For Hearing Speech-lang',
  'Mn Academy For The Blind',
  'Mn Academy For The Deaf',
  'Mn Correctional Facility-faribault',
  'Mn Correctional Facility-lino Lakes',
  'Mn Correctional Facility-rush City',
  'Mn Correctional Facility-shakopee',
  'Mn Correctional Facility-st. Cloud',
  'Mn Correctional Facility-stillwater',
  'Mn International Middle Chr School',
  'Mn Online High School - I.s.',
  'Mn River Valley Alc - Ind. Study',
  'Mn River Valley Alc-seatbased',
  'Mn River Valley Alc-summer',
  'Mn River Valley Ed-online Learning',
  'Mn Security Hospital Yaap',
  'Mn Specialty Health Sys-cambridge',
  'Mn Valley Ed. Dist. Early Childhood',
  'Mnps Virtual School',
  'Mnvss',
  'Mo School For The Blind - Elem',
  'Mo School For The Blind - Hs',
  'Moab Charter School',
  'Moanalua Elementary School',
  'Moanalua High School',
  'Moanalua Middle School',
  'Moapa Valley High School',
  'Moberly Area Community College',
  'Moberly Area Technical Ctr.',
  'Moberly Middle',
  'Moberly Sr. High',
  'Mobile County Training Middle School',
  'Mobile Elementary School',
  'Mobile Mental Health Center',
  'Mobridge High School - 01',
  'Mobridge Middle School - 02',
  'Mobridge Upper Elementary - 04',
  'Moc-floyd Valley High School',
  'Moc-floyd Valley Middle School',
  'Moccasin Community Day',
  'Moccasin Elementary School',
  'Mockingbird El',
  'Mockingbird Elementary School',
  'Mocksville Elementary',
  'Model Elementary School',
  'Model High',
  'Model Laboratory Elementary School',
  'Model Laboratory High School',
  'Model Laboratory Middle School',
  'Model Middle School',
  'Modern Acadmy',
  'Modern Institute of Managament',
  'Modern Sciences & Arts University',
  'Modern University For Technology and Information',
  'Modern University for the Humanities',
  'Modesto High',
  'Modesto Junior College',
  'Modoc County Juvenile Court',
  'Modoc County Rop',
  'Modoc County Special Education',
  'Modoc High',
  'Modoc Middle',
  'Moe And Gene Johnson H S',
  'Moencopi Day School',
  'Moffat County High School',
  'Moffat Prek-12 School',
  'Moffet John Sch',
  'Moffett Elementary',
  'Moffett Public School',
  'Mofid University',
  'Mogadishu University',
  'Mogadore High School',
  'Mogadore Junior High School',
  'Mogilev State University A.A. Kuleshov',
  'Mogollon High School',
  'Mogollon Jr High School',
  'Mohammad Ali Jinnah University',
  'Mohammad Ali Jinnah University Karachi',
  'Mohan Lal Sukhadia University',
  'Mohansic School',
  'Moharimet School',
  'Mohave Accelerated Elementary School',
  'Mohave Accelerated Elementary School East',
  'Mohave Accelerated Learning Center',
  'Mohave Community College',
  'Mohave High School',
  'Mohave Middle School',
  'Mohave Valley Junior High School',
  'Mohawk Avenue School',
  'Mohawk El',
  'Mohawk El Sch',
  'Mohawk Elementary School',
  'Mohawk High School',
  'Mohawk Jhs',
  'Mohawk Primary Center',
  'Mohawk Shs',
  'Mohawk Trail Regional High',
  'Mohawk Trails Elementary School',
  'Mohawk Valley Community College',
  'Mohawk Valley Psychiatric Center For Children & Youth',
  'Mohawk Valley School',
  'Mohegan School',
  'Mohi-ud-Din Islamic University',
  'Mohonasen Senior High School',
  'Moi University',
  'Moises E Molina H S',
  'Moises Melendez',
  'Moises Vela Middle',
  'Mojave Elementary',
  'Mojave High',
  'Mojave High School',
  'Mojave Jr./sr. High',
  'Mojave River Academy',
  'Mojave Vista Elementary',
  'Mokapu Elementary School',
  'Mokelumne High (continuation)',
  'Mokelumne Hill Elementary',
  'Mokena Elem School',
  'Mokena Intermediate School',
  'Mokena Jr High School',
  'Mokpo National Maritime University',
  'Mokpo National University',
  'Mokulele Elementary School',
  'Mokwon University Taejon',
  'Molalla Elementary School',
  'Molalla High School',
  'Molalla River Academy',
  'Molalla River Middle School',
  'Molde University College',
  'Moldova Cooperative Trade University',
  'Moldova State Agricultural University',
  'Moldova State University',
  'Moldova State University of Medicine and Pharmacy "N. Testemitsanu"',
  'Moldova Technical University',
  'Moler Elementary School',
  'Molholm Elementary School',
  'Moline Elem.',
  'Moline Hs At Coolidge Campus',
  'Moline Sr High School',
  'Molino Park Elementary',
  'Mollie B Hoover Elementary School',
  'Mollie Ray Elementary',
  'Mollison Elem School',
  'Molloy College',
  'Molly Creek Area Learning Center',
  'Molly Ockett Middle School',
  'Molly Ockett School',
  'Molly S. Bakman Elementary',
  'Molly Stark School',
  'Molokai High School',
  'Molokai Middle School',
  'Molt School',
  'Momauguin School',
  'Momence High School',
  'Momence Jr High School',
  'Momentum Academy',
  'Momilani Elementary School',
  'Mon Valley Ctc',
  'Mona School',
  'Mona Shores High School',
  'Mona Shores Middle School',
  'Monac Elementary School',
  'Monacan High',
  'Monache High',
  'Monaco Elementary School',
  'Monadnock Regional High School',
  'Monadnock Regional Middle School',
  'Monahans Ed Ctr',
  'Monahans H S',
  'Monarch',
  'Monarch Academy',
  'Monarch Academy Annapolis Es',
  'Monarch Academy Public Charter School',
  'Monarch Elementary',
  'Monarch Global Academy Pcs Laurel Campus',
  'Monarch Grove Elementary',
  'Monarch High School',
  'Monarch K-8 School',
  'Monarch Learning Center',
  'Monarch Montessori',
  'Monash Univerisity South Africa',
  'Monash University',
  'Monash University Malaysia Campus',
  'Monatiquot Kindergarten Center',
  'Monaview Elementary',
  'Monclova Elementary School',
  'Moncure School',
  'Monday El',
  'Mondovi Elementary',
  'Mondovi High',
  'Mondovi Middle',
  'Mondragon Univertsitatea',
  'Monee Education Center',
  'Monee Elementary School',
  'Monelison Middle',
  'Monessen El Ctr',
  'Monessen Ms',
  'Monessen Shs',
  'Moneta Continuation',
  'Moneta Elementary',
  'Monett Elem.',
  'Monett High',
  'Monett Intermediate',
  'Monett Middle',
  'Monfort Elementary School',
  'Monfort Heights Elementary School',
  'Monforton 7-8',
  'Monforton Primary',
  'Monforton School',
  'Monger Elementary School',
  'Mongolian National University',
  'Mongolian State University of Agriculture',
  'Mongolian State University of Arts and Culture',
  'Mongolian State University of Education',
  'Mongolian University of Science and Technology',
  'Monhagen Middle School',
  'Monhegan Island School',
  'Monica B Leary Elementary School',
  'Moniteau Jshs',
  'Monitor Elementary',
  'Monkton Central School',
  'Monmouth Academy',
  'Monmouth Beach Elementary School',
  'Monmouth College',
  'Monmouth County Career Center',
  'Monmouth County Vocational Technical High School',
  'Monmouth Elementary School',
  'Monmouth Junction Elementary School',
  'Monmouth Middle School',
  'Monmouth Regional High School',
  'Monmouth Regional School',
  'Monmouth Transitional Education Center',
  'Monmouth University',
  'Monmouth-roseville High Sch',
  'Monmouth-roseville Jr High Sch',
  'Monnie Meyer Wylie Pri',
  'Monnig Middle',
  'Mono County Rop',
  'Monocacy El Ctr',
  'Monocacy Elementary',
  'Monocacy Middle',
  'Monocacy Valley Montessori School',
  'Monomoy Regional High School',
  'Monomoy Regional Middle School',
  'Monon Trail Elementary School',
  'Monona Grove High',
  'Monona Grove Liberal Arts',
  'Monongah Elementary School',
  'Monongah Middle School',
  'Monongahela Middle School',
  'Monroe (albert F.) Magnet Middle School',
  'Monroe 1 Boces',
  'Monroe 2-orleans Boces',
  'Monroe Academy For Visual Arts & Design',
  'Monroe Area High School',
  'Monroe Avenue Elementary',
  'Monroe Career & Tech Inst',
  'Monroe Center Grade School',
  'Monroe Central Elementary School',
  'Monroe Central High School',
  'Monroe Central Jr-sr High School',
  'Monroe Charter Academy',
  'Monroe City Elem.',
  'Monroe City Middle',
  'Monroe City R-i High',
  'Monroe Co Advanced Learning Center',
  'Monroe Co High',
  'Monroe Co Middle',
  'Monroe Co Voc Tech',
  'Monroe Community College',
  'Monroe Consolidated School',
  'Monroe Correctional Facility',
  'Monroe County Area Technology Center',
  'Monroe County Careertechnical Center',
  'Monroe County Community College',
  'Monroe County Education Center',
  'Monroe County High School',
  'Monroe County Middle College',
  'Monroe County Middle School- Banks Stephens Campus',
  'Monroe County Transition Center',
  'Monroe County Vocational Center',
  'Monroe County Youth Center',
  'Monroe Demonstration Ms',
  'Monroe El Sch',
  'Monroe Elem School',
  'Monroe Elem.',
  'Monroe Elementary',
  'Monroe Elementary School',
  'Monroe Es',
  'Monroe Grade School',
  'Monroe High',
  'Monroe High (continuation)',
  'Monroe High School',
  'Monroe Intermediate School',
  'Monroe Junior High School',
  'Monroe Juvenile Detention Center',
  'Monroe Middle',
  'Monroe Middle School',
  'Monroe Preparatory Academy',
  'Monroe Primary Center',
  'Monroe Primary School',
  'Monroe Public School',
  'Monroe Road Elementary School',
  'Monroe School',
  'Monroe Special Ed Preschool',
  'Monroe Technology Center',
  'Monroe Township High School',
  'Monroe Twp El Sch',
  'Monroe Twp Middle School',
  'Monroe Virtual Academy (course Offerings)',
  'Monroe Virtual Franchise',
  'Monroe Virtual Instruction Program',
  'Monroe Virtual Instruction Program (district Provided)',
  'Monroe-woodbury High School',
  'Monroe-woodbury Middle School',
  'Monroe/randolph Red Brick Sch',
  'Monroeton Elementary',
  'Monroeville Elementary School',
  'Monroeville High School',
  'Monroeville Middle School',
  'Monroney Ms',
  'Monrovia Elementary School',
  'Monrovia High',
  'Monrovia High School',
  'Monrovia Middle School',
  'Monsenor Oscar Romero Charter Middle',
  'Monserrate Leon De Irizarry',
  'Monson High School',
  'Monson-sultana Elementary',
  'Mont Harmon Middle',
  'Mont Pleasant Middle School',
  'Mont Vernon Village School',
  'Monta Jane Akin El',
  'Monta Loma Elementary',
  'Monta Vista High',
  'Montabella Elementary',
  'Montabella Juniorsenior High',
  'Montachusett Regional Vocational Technical',
  'Montague Area Childhood Center',
  'Montague Charter Academy',
  'Montague El',
  'Montague Elementary',
  'Montague Elementary School',
  'Montague High School',
  'Montague Township School',
  'Montague Village El',
  'Montair Elementary',
  'Montalvin Manor Elementary',
  'Montalvo Elementary',
  'Montana City Middle Schl',
  'Montana City School',
  'Montana State University - Billings',
  'Montana State University - Bozeman',
  'Montana State University - Northern',
  'Montana Street Magnet School',
  'Montana Tech',
  'Montana Vista El',
  'Montandon El Sch',
  'Montanuniversität Leoben',
  'Montara Avenue Elementary',
  'Montara Elementary',
  'Montauk School',
  'Montbello Career And Technical High School',
  'Montcalm Area Career Center',
  'Montcalm Community Adult Education',
  'Montcalm Community College',
  'Montcalm Elementary School',
  'Montcalm High School',
  'Montclair',
  'Montclair El',
  'Montclair Elementary',
  'Montclair Elementary School',
  'Montclair High',
  'Montclair High School',
  'Montclair State University',
  'Montclaire Elementary',
  'Montcross Charter Academy',
  'Monte Alto El',
  'Monte Alto H S',
  'Monte Bella Elementary',
  'Monte Cristo El',
  'Monte Cristo Elementary',
  'Monte Del Sol Charter',
  'Monte Gardens Elementary',
  'Monte Rio Elementary',
  'Monte Sano Elementary School',
  'Monte Santo',
  'Monte Verde Elementary',
  'Monte Vista',
  'Monte Vista Elementary',
  'Monte Vista Elementary School',
  'Monte Vista High',
  'Monte Vista Middle',
  'Monte Vista Middle School',
  'Monte Vista On-line Academy',
  'Monte Vista School',
  'Monte Vista Senior High School',
  'Monte Vista Street Elementary',
  'Monteagle Elementary',
  'Montebello Community Day',
  'Montebello Elementary',
  'Montebello Gardens Elementary',
  'Montebello High',
  'Montebello Intermediate',
  'Montebello Park Elementary',
  'Montebello Road School',
  'Montebello School',
  'Montecito High (continuation)',
  'Montecito Union',
  'Montefiore Special Elem School',
  'Montegut Elementary School',
  'Montegut Middle School',
  'Montello Elementary School',
  'Montello Junior/senior High',
  'Montello School',
  'Montello Virtual Charter School',
  'Montemalaga Elementary',
  'Montera Elementary',
  'Montera Middle',
  'Monterey',
  'Monterey Bay Charter',
  'Monterey Community School',
  'Monterey Continuation',
  'Monterey County Home Charter',
  'Monterey County Special Education',
  'Monterey Elementary',
  'Monterey Elementary School',
  'Monterey H S',
  'Monterey Heights Elementary',
  'Monterey High',
  'Monterey High (continuation)',
  'Monterey High School',
  'Monterey Highlands Elementary',
  'Monterey Hills Elementary',
  'Monterey Park Elementary',
  'Monterey Peninsula College',
  'Monterey Peninsula Unified Sc',
  'Monterey Peninsula Unified School District Community Day',
  'Monterey Ridge Elementary',
  'Monterey Road Elementary',
  'Monterey Trail High',
  'Monterey Vista Elementary',
  'Monterrey Elementary',
  'Montesano Jr-sr High',
  'Montessori Academy',
  'Montessori Academyof Early Enrichment Inc',
  'Montessori At Franklin',
  'Montessori Borealis Public Alternative School',
  'Montessori Charter School Of Flagstaff - Campus',
  'Montessori Community School',
  'Montessori Day Public Schools Chartered - Mountainside',
  'Montessori Day Public Schools Chartered - Tempe',
  "Montessori De Santa Cruz - St. Ann's Hall",
  'Montessori Del Mundo Charter School',
  'Montessori Education Centre Charter School - Mesa',
  'Montessori Education Centre Charter School - North Campus',
  'Montessori Elementary School',
  'Montessori House Charter School',
  'Montessori Magnet At Fisher School',
  'Montessori Magnet At Moylan School',
  'Montessori Magnet School',
  'Montessori Of Englewood Chtr Es',
  'Montessori Of The Rio Grande',
  'Montessori Peaks Charter Academy',
  'Montessori Regional Cs',
  'Montessori School 27',
  'Montessori School 31',
  'Montessori Schoolhouse',
  'Montevallo Elementary School',
  'Montevallo High School',
  'Montevallo Middle School',
  'Montevideo Elementary',
  'Montevideo Middle',
  'Montevideo Senior High',
  'Montezuma Creek School',
  'Montezuma Elem',
  'Montezuma Elementary',
  'Montezuma Elementary School',
  'Montezuma High School',
  'Montezuma Junior High School',
  'Montezuma-cortez High School',
  'Montford North Star Academy',
  'Montgomery (john J.) Elementary',
  'Montgomery Blair High',
  'Montgomery C Smith Intermediate School',
  'Montgomery Center School',
  'Montgomery Central',
  'Montgomery Central Elementary',
  'Montgomery Central High',
  'Montgomery Central Middle',
  'Montgomery Childrens Specialty Center',
  'Montgomery City Elem.',
  'Montgomery City Treatment Ctr.',
  'Montgomery Co J J A E P',
  'Montgomery Co. High',
  'Montgomery Co. Middle',
  'Montgomery College',
  'Montgomery Community College',
  'Montgomery County Area Technology Center',
  'Montgomery County Community College',
  'Montgomery County Early College',
  'Montgomery County Early Learning Center',
  'Montgomery County Elementary School',
  "Montgomery County Governor's Stem Academy",
  'Montgomery County High School',
  'Montgomery County Intermediate School',
  'Montgomery County Middle School',
  'Montgomery Creek Elementary',
  'Montgomery El',
  'Montgomery El Sch',
  'Montgomery Elementary',
  'Montgomery Elementary School',
  'Montgomery H S',
  'Montgomery High',
  'Montgomery High School',
  'Montgomery Int',
  'Montgomery J H',
  'Montgomery Jshs',
  'Montgomery Knolls Elementary',
  'Montgomery Learning Academy',
  'Montgomery Lower Middle School',
  'Montgomery Middle',
  'Montgomery Pre K-8',
  'Montgomery Preparatory Academy For Career Technologies',
  'Montgomery Ridge Intermediate School',
  'Montgomery Senior High',
  'Montgomery Upper Middle School',
  'Montgomery Village Middle School',
  'Monticello',
  'Monticello Academy',
  'Monticello Alternative Program',
  'Monticello Elementary',
  'Monticello Elementary School',
  "Monticello Governor's Health Sciences Academy",
  'Monticello High',
  'Monticello High School',
  'Monticello High School - Snw',
  'Monticello Intermediate School',
  'Monticello Middle',
  'Monticello Middle School',
  'Monticello Middle School - Snw',
  'Monticello Montessori Charter School',
  'Monticello School',
  'Monticello Senior High',
  'Monticello Trails Middle School',
  'Monticello Vocational Center',
  'Monticello-brown Summit Elem',
  'Montlake Elementary School',
  'Montlieu Academy Of Technology',
  'Montmorency Ccsd #145',
  'Montour El Sch',
  'Montour Hs',
  'Montoursville Area Shs',
  'Montowese Elementary School',
  'Montpelier Elementary',
  'Montpelier Elementary School',
  'Montpelier High School',
  'Montpelier School',
  'Montreat College',
  'Montrose Alternative Education Center',
  'Montrose Area Jshs',
  'Montrose Elem.',
  'Montrose Elementary',
  'Montrose Elementary - 02',
  'Montrose Elementary School',
  'Montrose High',
  'Montrose High School',
  'Montrose High School - 01',
  'Montrose Middle School - 03',
  'Montross Middle',
  'Montura Early Learning Center',
  'Montvale Elementary',
  'Montview Elementary School',
  'Montview Math & Health Sciences Elementary School',
  'Montville High School',
  'Montville Township High School',
  'Montvue Elementary',
  'Montwood H S',
  'Montwood Middle',
  'Monument Academy Pcs',
  'Monument Charter Academy',
  'Monument Elementary',
  'Monument Middle',
  'Monument Mt Regional High',
  'Monument School',
  'Monument Valley High',
  'Monument Valley High School',
  'Monument Valley Regional Middle School',
  'Monument View Montessori Charter School',
  'Mooberry Elementary School',
  'Moody',
  'Moody Early Childhood Center',
  'Moody El',
  'Moody Elementary',
  'Moody Elementary School',
  'Moody H S',
  'Moody High School',
  'Moody Junior High School',
  'Moody Middle',
  'Moody Middle School',
  'Moody Pre-k',
  'Moody School',
  'Mooers Elementary School',
  'Moon Area Lower Ms',
  'Moon Area Upper Ms',
  'Moon Elementary School',
  'Moon Lake Elementary School',
  'Moon Lake School',
  'Moon Mountain School',
  'Moon Shs',
  'Moon Valley High School',
  'Moonachie Campus',
  'Mooneyham El',
  'Moonlight Elementary School',
  'Moorcroft High School',
  'Moorcroft K-8',
  'Moore (john Bassett) School',
  'Moore 7-8',
  'Moore County High School',
  'Moore El',
  'Moore El Sch',
  'Moore Elementary',
  'Moore Elementary School',
  'Moore Haven Adult Education Center',
  'Moore Haven Elementary School',
  'Moore Haven Middle High School.',
  'Moore High School',
  'Moore Hs',
  'Moore J Hampton Sch',
  'Moore M H El',
  'Moore Magnet Elementary',
  'Moore Middle',
  'Moore Middle School',
  'Moore Montessori Community School',
  'Moore Mst Magnet School',
  'Moore Sch',
  'Moore School',
  'Moore Square Museum Magnet Middle',
  'Moore Street Facility',
  'Moorefield Elementary School',
  'Moorefield High School',
  'Moorefield Intermediate School',
  'Moorefield Middle School',
  'Moorefield Station Elementary',
  'Mooreland El Sch',
  'Mooreland Es',
  'Mooreland Heights Elementary',
  'Mooreland Hs',
  'Mooreland University',
  'Moorer Middle School',
  'Moores Hill Elementary School',
  'Moores Mill Intermediate School',
  'Mooresburg Elementary',
  'Moorestown High School',
  'Moorestown Upper Elementary School',
  'Mooresville High School',
  'Mooresville Intermediate',
  'Mooresville Middle',
  'Mooretown Elementary Professional Develop. Ctr.',
  'Mooreville Elementary School',
  'Mooreville High School',
  'Mooreville Middle School',
  'Moorhead High School',
  'Moorhead J H',
  'Moorhead Middle School',
  'Mooringsport Elementary School',
  'Moorlands Elementary',
  'Moorpark College',
  'Moorpark High',
  'Moorsbridge Elementary School',
  'Moos Elem School',
  'Moose Hill School',
  'Moose Lake Elementary',
  'Moose Lake Secondary',
  'Moose Pass School',
  'Moosup Elementary School',
  'Mora Alternative Learning Center',
  'Mora Elementary',
  'Mora Elementary School',
  'Mora High',
  'Mora Middle Level Alc',
  'Mora Secondary',
  'Morada Middle',
  'Moraine El Sch',
  'Moraine Elementary School',
  'Moraine Park Technical College',
  'Moraine Valley Community College',
  'Morales Elementary',
  'Morales J H',
  'Moran Elementary',
  'Moran Elementary School',
  'Moran Prairie Elementary',
  'Moran School',
  'Moravia Elementary School',
  'Moravia High School',
  'Moravia Junior-senior High School',
  'Moravia Park',
  'Moravian College',
  'Moravian Falls Elementary',
  'Mordovian State University',
  'Moreau Elementary School',
  'Moreau Hall',
  'Moreau Heights Elem.',
  'Morehead City Middle',
  'Morehead City Primary',
  'Morehead Elem At Camp Glenn',
  'Morehead Elementary',
  'Morehead Middle',
  'Morehead Montessori',
  'Morehead State University',
  'Morehead Stem Academy',
  'Morehead Youth Development Center',
  'Morehouse Junior High School',
  'Morehouse Magnet School',
  'Moreland Art/health Science Magnet',
  'Moreland Elementary School',
  'Moreland Hills Elementary School',
  'Moreland Middle',
  'Moreland Ridge Middle',
  'Moreland Road Elementary',
  'Morello Park Elementary',
  'Morenci Elementary School',
  'Morenci High School',
  'Morenci Middle And High School',
  'Moreno El',
  'Moreno Elementary',
  'Moreno High (continuation)',
  'Moreno J H',
  'Moreno Valley College',
  'Moreno Valley Community Learning Center',
  'Moreno Valley High',
  'Moreno Valley Online Academy',
  'Moretown Elementary School',
  'Morey Avenue Early Childhood Development',
  'Morey Middle School',
  'Morey Montessori Public School Academy',
  'Morgan Center School',
  'Morgan Central Elementary School',
  'Morgan City High School',
  'Morgan City Junior High School',
  'Morgan Co. Elem.',
  'Morgan Co. High',
  'Morgan Co. Middle',
  'Morgan Co. R-i Elem.',
  'Morgan Co. R-i High',
  'Morgan Co. South Elem.',
  'Morgan Community College',
  'Morgan County Area Technology Center',
  'Morgan County Career And Technical Center',
  'Morgan County Elementary School',
  'Morgan County High School',
  'Morgan County Learning Center',
  'Morgan County Middle School',
  'Morgan County Primary School',
  'Morgan El Magnet School',
  'Morgan Elementary',
  'Morgan Elementary School',
  'Morgan Full Service Community School',
  'Morgan High',
  'Morgan High School',
  'Morgan Jr./sr. High',
  'Morgan Junior High School',
  'Morgan Kincaid Preparatory',
  'Morgan Maxwell School',
  'Morgan Middle',
  'Morgan Middle School',
  'Morgan Mill El',
  'Morgan Park High School',
  'Morgan Preschool',
  'Morgan Road Elementary School',
  'Morgan Road Middle School',
  'Morgan School',
  'Morgan State University',
  'Morgan Township Elementary School',
  'Morgan Township Middle/high School',
  'Morgan Woods Elementary School',
  'Morgandale Elementary',
  'Morganfield Elementary School',
  'Morganshaw School',
  'Morganton Road Elementary',
  'Morgantown College Prep Academy',
  'Morgantown Elementary School',
  'Morgantown High School',
  'Morgantown Middle',
  'Morgen Owings Elementary School',
  'Moriah Elementary School',
  'Moriah Junior-senior High School',
  'Moriarty Elementary',
  'Moriarty High',
  'Moriarty Magnet School',
  'Moriarty Middle',
  'Moriches Elementary School',
  'Morikami Park Elementary School',
  'Morin School',
  'Morioka College',
  'Morley Elementary School',
  'Morley Nelson Elementary',
  'Morley School',
  'Morley Stanwood Elementary School',
  'Morley Stanwood High School',
  'Morley Stanwood Middle School',
  'Mormon Trail Elementary School',
  'Mormon Trail Jr-sr High School',
  'Morning Creek Elementary',
  'Morning Star School',
  'Morning Sun Elementary School',
  'Morningside',
  'Morningside Community School',
  'Morningside El',
  'Morningside Elementary',
  'Morningside Elementary School',
  'Morningside High School',
  'Morningside K-8 Academy',
  'Morningside Middle',
  'Morningside School',
  'Morningstar Treatment Services- Inc. Youth Estate',
  'Morningview Elementary School',
  'Morocco Elementary School',
  'Morongo Valley Elementary',
  'Moroni School',
  'Morrell Park Elementary/middle',
  'Morrice Area Elementary School',
  'Morrice Area High School',
  'Morrice Schaefer Charter',
  'Morrill El',
  'Morrill Elem Math & Sci School',
  'Morrill Elementary',
  'Morrill High School',
  'Morrill Middle',
  'Morrilton Elementary School',
  'Morrilton Intermediate School',
  'Morrilton Junior High School',
  'Morrilton Primary School',
  'Morrilton Sr. High School',
  'Morris',
  'Morris (evelyn I.) Early Childhood',
  'Morris Academy For Collaborative Studies',
  'Morris Adler Elementary School',
  'Morris Area Elementary',
  'Morris Area Secondary',
  'Morris Avenue Elementry School',
  'Morris Avenue Intermediate School',
  'Morris Behavior Jr/sr Hs',
  'Morris Bye Elementary',
  'Morris Central School',
  'Morris Co Youth Ctr',
  'Morris Community High School',
  'Morris County School Of Technology',
  'Morris E. Dailey Charter Elementary',
  'Morris Elementary',
  'Morris Elementary School',
  'Morris Es',
  'Morris Ford Middle School',
  'Morris Goodwin School',
  'Morris Grove Elementary',
  'Morris Hill Elem',
  'Morris Hills High School',
  'Morris Hs',
  'Morris Innovative High School',
  'Morris Jeff Community School',
  'Morris K. Hamasaki Elementary',
  'Morris K. Udall Escuela De Bellas Artes',
  'Morris Knolls High School',
  'Morris Middle',
  'Morris Ms',
  'Morris P8',
  'Morris Plains Borough School',
  'Morris Regional School',
  'Morris Robert Sch',
  'Morris Schott Elementary',
  'Morris Slingluff Elementary School',
  'Morris Street School',
  'Morris Sunset East High School',
  'Morris Transitional Education Center',
  'Morris Upchurch Middle',
  'Morrish Elementary School',
  'Morrison Academic Advancement Ctr',
  'Morrison Alt Learning Program',
  'Morrison Andrew J Sch',
  'Morrison County Learning Center',
  'Morrison Elementary',
  'Morrison Elementary School',
  'Morrison Es',
  'Morrison High School',
  'Morrison Hs',
  'Morrison Jr High School',
  'Morrison Ms',
  'Morrisonville Elem School',
  'Morrisonville Elementary School',
  'Morrisonville High School',
  'Morrisonville Jr High School',
  'Morristown Central School',
  'Morristown East High',
  'Morristown Elementary',
  'Morristown Elementary School',
  'Morristown High School',
  'Morristown Jr-sr High School',
  'Morristown West High',
  'Morrisville Elementary',
  'Morrisville Hs',
  'Morrisville Intermediate Sch',
  'Morrisville Middle School High School',
  'Morro Bay High',
  'Morrow El Sch',
  'Morrow Elementary School',
  'Morrow High School',
  'Morrow Middle School',
  'Morse',
  'Morse Avenue Elementary',
  'Morse Elementary',
  'Morse Elementary School',
  'Morse High',
  'Morse High School',
  'Morse Memorial School',
  'Morse Mid',
  'Morse Pond School',
  'Morse School',
  'Morse Street School',
  'Morse Young Magnet School',
  'Mort Elementary School',
  'Mortensen Elementary School',
  'Mortimer Jordan High School',
  'Mortimer Y. Sutherland Middle',
  'Morton Academy',
  'Morton Alternative Schools',
  'Morton College',
  'Morton El',
  'Morton Elem Career Academy',
  'Morton Elementary',
  'Morton Elementary School',
  'Morton Gingerwood Elem School',
  'Morton High School',
  'Morton Jr High School',
  'Morton Junior-senior High',
  'Morton Magnet Middle School',
  'Morton Middle',
  'Morton Middle School',
  'Morton Ranch El',
  'Morton Ranch H S',
  'Morton Ranch J H',
  'Morton School',
  'Morton Senior High School',
  'Morton Thomas G Sch',
  'Morton-sioux Spec Ed Unit',
  'Morven Elementary',
  'Mosaic Academy Charter',
  'Mosaic Digital Academy Lower School',
  'Mosaic Digital Academy Pt St. Lucie Virtual Instruc (course',
  'Mosaic Digital Academy Upper School (st. Lucie Virtual Franc',
  'Mosaic Elementary',
  'Mosaic Home Education Partnership',
  'Mosaic Preparatory Academy',
  'Mosaic School',
  'Mosaics School',
  'Mosby Woods Elementary',
  'Moscone (george R.) Elementary',
  'Moscow Charter School',
  'Moscow El Sch',
  'Moscow Elem',
  'Moscow Elementary',
  'Moscow External University of the Humanities',
  'Moscow High',
  'Moscow High School',
  'Moscow Institute of Physics and Technology',
  'Moscow International Higher Business School (MIRBIS)',
  'Moscow Middle School',
  'Moscow P. I. Tchaikovsky Conservatory',
  'Moscow Power Engineering Institute (Technical University)',
  'Moscow State Academy of Applied Biotechnology',
  'Moscow State Automobile and Road Technical University',
  'Moscow State Industrial University',
  'Moscow State Institute of International Relations MFA Russia (MGIMO-University)',
  'Moscow State Institute of Radiotechnics Electronics and Automation (Technical University)',
  'Moscow State Institute of Steel and Alloys',
  'Moscow State Technical University of Civil Aviation',
  'Moscow State Textile University A.N. Kosygin',
  'Moscow State University',
  'Moscow State University of Civil Engineering',
  'Moscow State University of Commerce',
  'Moscow State University of Culture',
  'Moscow State University of Ecological Engineering',
  'Moscow State University of Economics Statistics and Informatics',
  'Moscow State University of Environmental Engineering',
  'Moscow State University of Forestry Engineering',
  'Moscow State University of Geodesy and Cartography',
  'Moscow State University of Railway Transport',
  'Moscow State University of Technology (Stankin)',
  'Moscow Technical University of Informatics and Communication',
  'Moscow University Touro',
  'Moseley Public School',
  'Moselle Elementary School',
  'Moses Dewitt Elementary School',
  'Moses Lake High School',
  'Moses Mckissack Middle',
  'Moses Y. Beach School',
  'Moshannon Valley El Sch',
  'Moshannon Valley Jshs',
  'Mosheim Elementary',
  'Moshi University College of Cooperative and Business Studies',
  'Mosi Partnership Elementary School',
  'Mosier',
  'Mosier Community School',
  'Mosinee Elementary',
  'Mosinee High',
  'Mosinee Middle',
  'Mosquero Elementary',
  'Mosquero High',
  'Moss Agate Elementary',
  'Moss Bluff Elementary School',
  'Moss Bluff Middle School',
  'Moss El',
  'Moss Es',
  'Moss Haven El',
  'Moss Hill Elementary',
  'Moss Hs',
  'Moss Park Elementary',
  'Moss Point Alternative School',
  'Moss Point Career & Technical Educa',
  'Moss Point Escatawpa Upper Elem',
  'Moss Point High School',
  'Moss Point Kreole Primary School',
  'Moss Side Ms',
  'Moss Street Elementary',
  'Moss Street Partnership School',
  'Moss-nuckols Elementary',
  'Mossdale Elementary',
  'Mosser El Sch',
  'Mossville Elementary School',
  'Mossville Jr High',
  'Mossy Creek Elementary',
  'Mossy Creek Elementary School',
  'Mossy Creek Middle School',
  'Mossy Head School',
  'Mossy Oaks Elementary',
  'Mossyrock Academy',
  'Mossyrock Elementary School',
  'Mossyrock Jr./sr. High School',
  'Mot Charter School',
  'Mote (anna P.) Elementary School',
  'Moten Es',
  "Mother Teresa Women's University",
  'Motilal Nehru National Institute Of Technology',
  'Motivation Hs',
  'Motley County School',
  'Motley El',
  'Motley-staples Middle School',
  'Motlow State Community College',
  'Moton Early Childhood Center',
  'Moton Elementary School',
  'Mott Community College',
  'Mott El',
  'Mott Elementary School',
  'Mott Hall Bridges Academy',
  'Mott Hall Bronx High School',
  'Mott Hall Charter School',
  'Mott Hall Community School',
  'Mott Hall High School',
  'Mott Hall Ii',
  'Mott Hall Iii',
  'Mott Hall Iv',
  'Mott Hall School (the)',
  'Mott Hall V',
  'Mott Haven Academy Charter School',
  'Mott Haven Community High School',
  'Mott Haven Village Prep High School',
  'Mott Middle College High School',
  'Mott Road Elementary School',
  'Mott-regent Elementary School',
  'Mott-regent High School',
  'Moulana Azad national Urdu University',
  'Moulton El',
  'Moulton Elementary',
  'Moulton Elementary School',
  'Moulton H S',
  'Moulton Middle School',
  'Moulton-branch Elementary School',
  'Moulton-udell High School',
  'Moultonborough Academy',
  'Moultonborough Academy (junior High)',
  'Moultonborough Central School',
  'Moultrie Middle',
  'Moultrie Technical College',
  'Mound City Elem.',
  'Mound City High',
  'Mound City Middle',
  'Mound El',
  'Mound Elementary',
  'Mound Elementary School',
  'Mound Fort Junior High',
  'Mound Park Elementary School',
  'Mound Street Health Careers Academy',
  'Mound Street It Careers Academy',
  'Mound Street Military Careers Academy',
  'Mound Valley Elem',
  'Mound Valley Elementary School',
  'Mound View Elementary',
  'Mound Westonka High School',
  'Moundridge Elem',
  'Moundridge High',
  'Moundridge Middle',
  'Mounds Es',
  'Mounds Hs',
  'Mounds View Adult Education',
  'Mounds View Alc',
  'Mounds View Bridges Program',
  'Mounds View Senior High',
  'Moundsville Middle School',
  'Moundville Elementary School',
  'Mount Abraham Uhsd #28',
  'Mount Adams Middle School',
  'Mount Airy Elementary',
  'Mount Airy High',
  'Mount Airy Middle',
  'Mount Allison University',
  'Mount Arlington Public School',
  'Mount Auburn El',
  'Mount Auburn International Academy',
  'Mount Ayr Elementary',
  'Mount Ayr High School',
  'Mount Baker Academy',
  'Mount Baker Junior High',
  'Mount Baker Middle School',
  'Mount Baker Senior High',
  'Mount Bethel Elementary School',
  'Mount Caesar School',
  'Mount Calm H S',
  'Mount Calm Isd',
  'Mount Carbon Elementary School',
  'Mount Carmel Academy',
  'Mount Carmel Area El Sch',
  'Mount Carmel Area Hs',
  'Mount Carmel Area Jh',
  'Mount Carmel Elementary School',
  'Mount Carmel High School',
  'Mount Carmel School',
  'Mount Clemens High School',
  'Mount Clemens Middle School',
  'Mount Daniel School',
  'Mount Eagle Elementary',
  'Mount Eaton Elementary School',
  "Mount Eden Children's Academy",
  'Mount Elden Middle School',
  'Mount Energy Elementary',
  'Mount Erie Elem School',
  'Mount Erie Elementary',
  'Mount Gallant Elementary',
  'Mount Garfield Middle School',
  'Mount Gilead Elementary',
  'Mount Greenwood Elem School',
  'Mount Hall Elementary School',
  'Mount Harmony Elementary',
  'Mount Healthy Elementary School',
  'Mount Hebron High',
  'Mount Hermon Preschool Center',
  'Mount Holly Elementary',
  'Mount Holly Middle',
  'Mount Holyoke College',
  'Mount Hope',
  'Mount Hope Elem.',
  'Mount Hope Elementary',
  'Mount Horeb Area Community 4k',
  'Mount Horeb Early Learning Center',
  'Mount Horeb Elementary School',
  'Mount Horeb High',
  'Mount Horeb Intermediate',
  'Mount Horeb Middle',
  'Mount Horeb Primary Center',
  'Mount Ida Elementary School',
  'Mount Ida High School',
  'Mount Kenya University',
  'Mount Kisco Elementary School',
  'Mount Logan Middle',
  'Mount Mahogany School',
  'Mount Marion Elementary School',
  'Mount Markham Elementary School',
  'Mount Markham Middle School',
  'Mount Markham Senior High School',
  'Mount Meru University',
  'Mount Miguel High',
  'Mount Morris Elementary School',
  'Mount Morris Middle/senior High School',
  'Mount Mourne School',
  'Mount Nittany El Sch',
  'Mount Nittany Ms',
  'Mount Ogden Junior High',
  'Mount Olive Attendance Center',
  'Mount Olive Elementary',
  'Mount Olive Elementary School',
  'Mount Olive High School',
  'Mount Olive Intermediate School',
  'Mount Olive Middle',
  'Mount Olive Middle School',
  'Mount Olivet Elementary',
  'Mount Pleasant Area Hs',
  'Mount Pleasant Area Jhs',
  'Mount Pleasant Daep',
  'Mount Pleasant Elementary',
  'Mount Pleasant Elementary School',
  'Mount Pleasant H S',
  'Mount Pleasant High',
  'Mount Pleasant High School',
  'Mount Pleasant J H',
  'Mount Pleasant Middle',
  'Mount Pleasant Middle School',
  'Mount Pleasant School',
  'Mount Pleasant-cottage School',
  'Mount Prospect Elementary School',
  'Mount Pulaski Elem School',
  'Mount Pulaski High School',
  'Mount Rainier High School',
  'Mount Rock Elem Sch',
  'Mount Rose K-8 Academy Of Languages Elementary School',
  'Mount Royal College',
  'Mount Royal Elementary/middle',
  'Mount Saint Joseph College',
  'Mount Saint Mary College',
  'Mount Saint Vincent University',
  'Mount Savage Middle',
  'Mount Scott Elementary School',
  'Mount Senario College',
  'Mount Si High School',
  'Mount Sinai Elementary School',
  'Mount Sinai High School',
  'Mount Sinai Middle School',
  "Mount St. Mary's University",
  'Mount Sterling Elementary School',
  'Mount Tabor Elementary School',
  'Mount Tabor High School',
  'Mount Taylor Elementary',
  'Mount Toro High',
  'Mount Union Area Jhs',
  'Mount Union Area Shs',
  'Mount Union College',
  'Mount Union-kistler El Sch',
  'Mount Vernon',
  'Mount Vernon Elem School',
  'Mount Vernon Elementary',
  'Mount Vernon Elementary - 02',
  'Mount Vernon Elementary School',
  'Mount Vernon High',
  'Mount Vernon High School',
  'Mount Vernon High School- 01',
  'Mount Vernon Jr High School',
  'Mount Vernon Middle School',
  'Mount Vernon Middle School - 03',
  'Mount Vernon Open Doors',
  'Mount Vernon Special Ed',
  'Mount Vernon Woods Elementary',
  'Mount View Core Knowledge Charter School',
  'Mount View Elem School',
  'Mount View Elementary',
  'Mount View High School',
  'Mount View Middle',
  'Mount View Youth Services Center',
  'Mount Wachusett Community College',
  'Mount Yonah Elementary School',
  'Mount Zion Elementary School',
  'Mount Zion High School',
  'Mount Zion Primary',
  'Mountain Academy Charter',
  'Mountain Avenue Elementary',
  'Mountain Bay Elementary',
  'Mountain Brook Elementary School',
  'Mountain Brook High School',
  'Mountain Brook Junior High School',
  'Mountain City Elementary',
  'Mountain Creek Middle',
  'Mountain Crest High',
  'Mountain Discovery',
  'Mountain Education Charter High School',
  'Mountain Elementary',
  'Mountain Empire Community College',
  'Mountain Empire High',
  'Mountain Empire Special Education Preschool',
  'Mountain Gap P8',
  'Mountain Green School',
  'Mountain Grove Elem.',
  'Mountain Grove High',
  'Mountain Grove Middle',
  'Mountain Heights Academy',
  'Mountain Heritage High',
  'Mountain High',
  'Mountain High School',
  'Mountain Home Charter (alternative)',
  'Mountain Home Jr. High School',
  'Mountain Home Junior High School',
  'Mountain Home Kindergarten',
  'Mountain Home Sr High School',
  'Mountain House Elementary',
  'Mountain House High',
  'Mountain Iron-buhl Secondary',
  'Mountain Island Charter School',
  'Mountain Island Day School',
  'Mountain Island Lake Academy',
  'Mountain Lake Elementary',
  'Mountain Lake Secondary',
  'Mountain Lakes High',
  'Mountain Lakes High School',
  'Mountain Mahogany Community School',
  'Mountain Meadow Elementary',
  'Mountain Meadows Elementary',
  'Mountain Meadows Primary',
  'Mountain Middle School',
  'Mountain Oak Charter School',
  'Mountain Oaks',
  'Mountain Oaks High',
  'Mountain Park',
  'Mountain Park Elementary',
  'Mountain Park Elementary School',
  'Mountain Park School',
  'Mountain Phoenix Community School',
  'Mountain Pine Elem. School',
  'Mountain Pine High School',
  'Mountain Pointe High School',
  'Mountain Range High School',
  'Mountain Ridge High School',
  'Mountain Ridge Intermediate',
  'Mountain Ridge Jr High',
  'Mountain Ridge Middle School',
  'Mountain Ridge Middle School.',
  'Mountain Road Elementary School',
  'Mountain Rose Academy',
  'Mountain Sage Community School',
  'Mountain School',
  'Mountain Shadows Education Center - Closed',
  'Mountain Shadows Elementary School',
  'Mountain Shadows Middle',
  'Mountain Shadows School',
  'Mountain Sky Middle School',
  'Mountain Song Community School',
  'Mountain Springs Elem School',
  'Mountain Trail Middle School',
  'Mountain Trails School',
  'Mountain Valley Academy',
  'Mountain Valley Elementary School',
  'Mountain Valley High School',
  'Mountain Valley Middle School',
  'Mountain Valley School',
  'Mountain View',
  'Mountain View (alternative)',
  'Mountain View Academy',
  'Mountain View College',
  'Mountain View El',
  'Mountain View El Sch',
  'Mountain View Elem.',
  'Mountain View Elem. School',
  'Mountain View Elementary',
  'Mountain View Elementary & Middle School',
  'Mountain View Elementary - 08',
  'Mountain View Elementary School',
  'Mountain View H S',
  'Mountain View High',
  'Mountain View High (continuation)',
  'Mountain View High School',
  'Mountain View Intermediate',
  'Mountain View Jshs',
  'Mountain View K-8',
  'Mountain View Learning Academy',
  'Mountain View Middle',
  'Mountain View Middle School',
  'Mountain View Montessori Charter',
  'Mountain View Preparatory School',
  'Mountain View School',
  'Mountain View Senior High School',
  'Mountain View Virtual',
  'Mountain View-gotebo Es',
  'Mountain View-gotebo Hs',
  'Mountain Villa School',
  'Mountain Village Charter School',
  'Mountain Village Montessori Charter School',
  'Mountain Village School',
  'Mountain Vista',
  'Mountain Vista Academy',
  'Mountain Vista Community School',
  'Mountain Vista Educational Center',
  'Mountain Vista Elementary',
  'Mountain Vista Elementary School',
  "Mountain Vista Governor's School",
  'Mountain Vista High',
  'Mountain Vista High School',
  'Mountain Vista Homeschool Academy',
  'Mountain Vista Middle',
  'Mountain Vista School',
  'Mountain Way Elementary',
  'Mountain Way School',
  'Mountain West Montessori Academy',
  'Mountainair Elementary',
  'Mountainair High',
  'Mountainair Jr High',
  'Mountainburg Elementary School',
  'Mountainburg High School',
  'Mountainburg Middle School',
  'Mountaineer Middle School',
  'Mountains of the Moon University',
  'Mountainside Elementary School',
  'Mountainside High School',
  'Mountainside Middle School',
  'Mountainside School',
  'Mountaintop Area El Sch',
  'Mountainview Alternative High School',
  'Mountainview Correctional Facility',
  'Mountainview El',
  'Mountainview Elementary',
  'Mountainview Elementary School',
  'Mountainville Academy',
  'Mountlake Terrace Elementary',
  'Mountlake Terrace High School',
  'Mountview Middle',
  'Mountview Road School',
  'Mountview Youth Service Center',
  'Mountville El Sch',
  'Mountwest Community and Technical College',
  'Movement Charter School',
  'Moville Elementary School',
  'Moving Everest Charter School',
  'Moving Forward',
  'Movip Elem. School',
  'Movip High School',
  'Mowat Middle School',
  'Mowrey El Sch',
  'Mowrie A Ebner El Sch',
  'Moxee Elementary',
  'Moya Elementary',
  'Moyer Elementary School',
  'Moyers Es',
  'Moyers Hs',
  'Moyock Elementary',
  'Moyock Middle',
  'Mozart',
  'Mozart Elem School',
  'Mozelle Brown El',
  'Mozyr State Pedagogical Institute',
  'Mpes Preschool',
  'Mpls. H.s. Alternative Program',
  'Mps Metro C',
  'Mps Metro Ha',
  'Mps Metro Sj',
  'Mpusd Preschool',
  'Mrachek Middle School',
  'Mrh Early Childhood Center',
  'Mrh Elementary',
  'Mrh Middle',
  'Ms 101 Edward R Byrne',
  'Ms 113 Ronald Edmonds Learning Center',
  'Ms 129 Academy Of Independent Learning And Leadership',
  'Ms 131',
  "Ms 137 America's School Of Heroes",
  'Ms 158 Marie Curie',
  'Ms 180 Dr Daniel Hale Williams',
  'Ms 224 Manhattan East School For Arts & Academics',
  'Ms 243 Center School',
  'Ms 245 Computer School (the)',
  'Ms 246 Walt Whitman',
  'Ms 247 Dual Language Middle School',
  'Ms 250 West Side Collaborative Middle School',
  'Ms 255 Salk School Of Science',
  'Ms 260 Clinton School Writers & Artists',
  'Ms 266 Park Place Community Middle School',
  'Ms 267 Math Science & Technology',
  'Ms 297',
  'Ms 301 Paul L Dunbar',
  'Ms 302 Luisa Dessus Cruz',
  'Ms 319 Marie Teresa',
  'Ms 324 Patria Mirabal',
  'Ms 35 Stephen Decatur',
  'Ms 358',
  'Ms 390',
  'Ms 394',
  'Ms 442 Carroll Gardens School For Innovation',
  'Ms 51 William Alexander',
  'Ms 53 Brian Piccolo',
  'Ms 582',
  'Ms 584',
  'Ms 61 Dr Gladstone H Atwell',
  'Ms 839',
  'Ms School For Math And Science',
  'Ms School For The Arts',
  'Ms School For The Blind Hs',
  'Ms School For The Deaf Elementary',
  'Ms School For The Deaf Secondary',
  'Ms-5 Nw Middle',
  'Ms055',
  'Msed Birth To 3',
  'Msed Paes Lab',
  'Mt Abram Regional High School',
  'Mt Angel Middle School',
  'Mt Ararat High School',
  'Mt Ararat Middle School',
  'Mt Baker Middle School',
  'Mt Blue High School',
  'Mt Blue Middle School',
  'Mt Carmel Elementary',
  'Mt Carmel Elementary School',
  'Mt Carmel Grade School',
  'Mt Carmel Junior High School',
  'Mt Clemens Montessori Academy',
  'Mt Comfort Elementary School',
  'Mt Desert Elementary School',
  'Mt Desert Island High School',
  'Mt Enterprise El',
  'Mt Enterprise H S',
  'Mt Everett Regional',
  'Mt Gilead High School',
  'Mt Gilead Middle School',
  'Mt Graham High School',
  'Mt Greylock Regional High',
  'Mt Harrison Jr/sr High',
  'Mt Healthy High School',
  'Mt Healthy Junior High School',
  'Mt Holly Springs El Sch',
  'Mt Hope Elementary School',
  'Mt Hope School',
  'Mt Hope/nanjemoy Elementary School',
  'Mt Jefferson Jr High School',
  'Mt Laurel Elementary School',
  'Mt Lebanon Shs',
  'Mt Lookout Elementary School',
  'Mt Mansfield Union High School',
  'Mt Morris Education And Community Center',
  'Mt Morris Middle School',
  'Mt Nebo Elementary School',
  'Mt Olive Elementary',
  'Mt Olive Elementary School',
  'Mt Olive High School',
  'Mt Olive Primary School',
  'Mt Orab Middle School',
  'Mt Orab Primary Elementary School',
  'Mt Peak El',
  'Mt Penn El Sch',
  'Mt Penn Primary Ctr',
  'Mt Pleasant Adult And Community Education',
  'Mt Pleasant Elementary School',
  'Mt Pleasant High School',
  'Mt Pleasant Middle School',
  'Mt Pleasant Senior High School',
  'Mt Rainier Elementary',
  'Mt Sch For Deaf & Blnd El',
  'Mt Sch For Deaf & Blnd Hs',
  'Mt Spokane High School',
  'Mt Tabor Middle School',
  'Mt Tahoma',
  'Mt Tipton Elementary School',
  'Mt Ulla Elementary',
  'Mt Valley El',
  'Mt Valley Middle',
  'Mt Vernon Alternative School',
  'Mt Vernon Early Learning Academy',
  'Mt Vernon El',
  'Mt Vernon El Sch',
  'Mt Vernon Elementary School',
  'Mt Vernon H S',
  'Mt Vernon Head Start',
  'Mt Vernon High School',
  'Mt Vernon Int',
  'Mt Vernon Middle',
  'Mt Vernon Middle School',
  'Mt Vernon Place School',
  'Mt View Elementary',
  'Mt View Elementary School',
  'Mt View High School',
  'Mt View Middle School',
  'Mt Wolf El Sch',
  'Mt Zion Elem School',
  'Mt Zion High School',
  'Mt Zion Intermediate School',
  'Mt Zion Jr High School',
  'Mt. Airy Elementary School',
  'Mt. Alava Elementary School',
  'Mt. Anthony Senior Uhsd #14',
  'Mt. Anthony Union Middle School',
  'Mt. Baldy Joint Elementary',
  'Mt. Burney Special Education Center',
  'Mt. Carmel High',
  'Mt. Diablo Elementary',
  'Mt. Diablo High',
  'Mt. Dora High School',
  'Mt. Dora Middle School',
  'Mt. Eccles Elementary',
  'Mt. Eden High',
  'Mt. Edgecumbe High School',
  'Mt. Everest Academy',
  'Mt. George International',
  'Mt. Gleason Middle',
  'Mt. Healthy North Elementary School',
  'Mt. Healthy Preparatory And Fitness Academy',
  'Mt. Healthy South Elementary School',
  'Mt. Herman Ese Center',
  'Mt. Hermon School',
  'Mt. Holly School',
  'Mt. Hood Community College',
  'Mt. Hope High School',
  'Mt. Horeb School',
  'Mt. Jordan Middle',
  'Mt. Judea K-12 School',
  'Mt. Juliet Elementary',
  'Mt. Juliet High School',
  'Mt. Juliet Middle School',
  'Mt. Lassen Charter',
  'Mt. Lassic High (continuation)',
  'Mt. Lebanon Elementary',
  'Mt. Lebanon School',
  'Mt. Loafer School',
  'Mt. Logan Elementary School',
  'Mt. Lukens Continuation',
  'Mt. Madonna High',
  'Mt. Mckinley',
  'Mt. Nebo Junior High',
  'Mt. Olive Innovation And Technology High',
  'Mt. Pilchuck Elementary School',
  'Mt. Pisgah Elementary',
  'Mt. Pisgah Middle',
  'Mt. Pleasant Academy',
  'Mt. Pleasant Elementary',
  'Mt. Pleasant Elementary School',
  'Mt. Pleasant High',
  'Mt. Pleasant Middle Visual Perform. Arts',
  'Mt. Pleasant School',
  'Mt. Rainier Parent Partnership',
  'Mt. San Antonio College',
  'Mt. San Jacinto Community College District',
  'Mt. San Jacinto High',
  'Mt. Savage Elementary',
  'Mt. Shasta Elementary',
  'Mt. Shasta High',
  'Mt. Solo Middle School',
  'Mt. Spurr Elementary',
  'Mt. Stuart Elementary',
  'Mt. Tallac High',
  'Mt. Turnbull Academy',
  'Mt. Turnbull Elementary School',
  'Mt. Vernon Avenue School',
  'Mt. Vernon Elem.',
  'Mt. Vernon Elementary',
  'Mt. Vernon High',
  'Mt. Vernon Intermediate',
  'Mt. Vernon Middle',
  'Mt. Vernon Treatment Ctr.',
  'Mt. Vernon-ruth Elementary School',
  'Mt. Vernon/enola Elem. School',
  'Mt. Vernon/enola High School',
  'Mt. View Elementary',
  'Mt. View Goal',
  'Mt. Washington Elementary',
  'Mt. Washington Elementary School',
  'Mt. Washington Middle School',
  'Mt. Whitney High',
  'Mt. Woodson Elementary',
  'Mt. Zion Elementary',
  'Mt. Zion High School',
  'Mt. Zion Middle School',
  'Mtn Home High Career Academics',
  'Mts High School',
  'Mts Pease Academy',
  "Mu' tah University",
  'Muadzam Shah Polytechnic',
  'Muckleshoot Tribal School',
  'Muddy Brook Regional Elementary School',
  'Mueller Aerospace/engineering Discovery Magne',
  'Mueller Charter (robert L.)',
  'Mueller El',
  'Mueller Park Jr High',
  'Muenster El',
  'Muenster H S',
  'Muessel Primary Center',
  'Muffley Elem School',
  'Mugla Sitki Kocman University',
  'Muhimbili University of Health and Allied Sciences (MUHAS)',
  'Muhlenberg County Career & Tech Center',
  'Muhlenberg County High School',
  'Muhlenberg El Ctr',
  'Muhlenberg El Sch',
  'Muhlenberg Hs',
  'Muhlenberg Ms',
  'Muhlenberg North Middle',
  'Muhlenberg South Elementary',
  'Muhlenberg South Middle School',
  'Muir (john) Elementary',
  'Muir Elementary',
  'Muir K-8',
  'Muir Middle School',
  'Muir School',
  'Muirlands Middle',
  'Mukilteo Elementary',
  'Mukilteo Reengagement Academy Open Doors',
  "Mukogawa Women's University",
  'Mukwonago High',
  'Mulberry Creek Elementary School',
  'Mulberry Elementary',
  'Mulberry Elementary School',
  'Mulberry Grove Elem School',
  'Mulberry Grove Jr High School',
  'Mulberry Grove Sr High School',
  'Mulberry High School',
  'Mulberry Middle School',
  'Mulberry Senior High School',
  'Mulcahey Elementary School',
  'Mulcahy Middle',
  'Muldoon Elementary',
  'Muldrow Es',
  'Muldrow Hs',
  'Muldrow Ms',
  'Muleshoe H S',
  'Mulhall-orlando Es',
  'Mulhall-orlando Hs',
  'Mulick Park Elementary School',
  'Mulino Elementary School',
  'Mulitas Alvelo',
  'Mulkey Elementary School',
  'Mull Elementary',
  'Mullan Road Elementary',
  'Mullan Schools',
  'Mullan Trail Elementary School',
  'Mullanphy Botanical Gardens',
  'Mullen Elementary School',
  'Mullen High School',
  'Mullen-hall',
  'Mullenix Ridge Elementary School',
  'Mullens Elementary School',
  'Mullens Middle School',
  'Muller Elementary Magnet School',
  'Muller Road Middle',
  'Mullica Township Middle School',
  'Mullica Township Primary School',
  'Mullin El',
  'Mullin H S',
  'Mullin Oaks',
  'Mullins Elementary School',
  'Mullins High',
  'Mullins School',
  'Mulready Elementary',
  'Mulrennan Middle School',
  'Multi Needs Program',
  'Multi-sensory Instruction Teaching Children Hands-on (mitch)',
  'Multi-vocational Life Skills Program',
  'Multicultural Acad Scholarshp Hs',
  'Multicultural Academy',
  'Multicultural Academy Cs',
  'Multicultural High School',
  'Multicultural Learning Center',
  'Multicultural Magnet School',
  'Multimedia University',
  'Multimedia University of Kenya',
  'Multiply Handicaped',
  'Multisensory Learning Academy',
  'Multnomah Street Elementary',
  'Mulungushi University',
  'Mulvane Elem W D Munson',
  'Mulvane Grade School',
  'Mulvane High',
  'Mulvane Middle School',
  'Mumford El',
  'Mumford H S',
  'Mumford High School',
  'Muncie Area Career Center',
  'Muncie Central High School',
  'Muncy Jshs',
  'Munday El',
  'Munday Secondary',
  'Mundelein Cons High School',
  'Mundo Verde Bilingual Pcs',
  'Mundy Mill Academy',
  "Mundy's Mill High School",
  'Mundys Mill Middle School',
  'Munford Elementary',
  'Munford Elementary School',
  'Munford High School',
  'Munford Middle School',
  'Munfordville Elementary School',
  'Munger Elementarymiddle School',
  'Munger Hill',
  'Munhall Elem School',
  'Munich Elementary School',
  'Munich High School',
  'Municipal School',
  'Munising High And Middle School',
  'Munoz-marin Luis',
  'Munroe Elementary School',
  'Munsey Elementary',
  'Munsey Park Elementary School',
  'Munson Elementary School',
  'Munster High School',
  'Munz John Camp',
  'Mupu Elementary',
  'Muraco Elementary',
  'Muraoka (saburo) Elementary',
  'Muraski Elementary School',
  'Murch Es At Udc',
  'Murchison El',
  'Murchison Middle',
  'Murchison Street Elementary',
  'Murdoch University',
  'Murdock Academy For Success',
  'Murdock Elementary',
  'Murdock Elementary School',
  'Murdock High School',
  'Murdock Middle School',
  'Murdock-portal Elementary',
  'Murfreesboro Elementary School',
  'Murfreesboro High School',
  'Muriel Forbes Academy',
  'Muriel W. Battle High School',
  'Murmansk State Technical University',
  'Murni Nursing College',
  'Murnin El',
  'Muroran Institute of Technology',
  'Murphey Middle School',
  'Murphey Traditional Academy',
  'Murphy Academy',
  'Murphy Candler Elementary School',
  'Murphy Creek K-8 School',
  'Murphy Elem School',
  'Murphy Elem.',
  'Murphy Elementary',
  'Murphy Elementary School',
  'Murphy High',
  'Murphy High School',
  'Murphy Junior High School',
  'Murphy Middle',
  'Murphy Ranch Elementary',
  'Murphysboro High School',
  'Murphysboro Middle School',
  'Murrah High School',
  'Murray Adult Transition',
  'Murray Avenue Sch',
  'Murray Avenue School',
  'Murray Co. Central Elementary',
  'Murray County Central Secondary',
  'Murray County High School',
  'Murray Elem Language Academy',
  'Murray Elementary',
  'Murray Elementary School',
  'Murray High',
  'Murray High School',
  'Murray Hill Academy',
  'Murray Hill Middle',
  'Murray Jr/sr High',
  'Murray Lake Elementary',
  'Murray Lasaine Elementary',
  'Murray Manor Elementary',
  'Murray Middle',
  'Murray Middle School',
  'Murray Park Elementary',
  'Murray Preschool Head Start',
  'Murray State College',
  'Murray State University',
  'Murray/calloway County Area Tech Center',
  'Murrayville Elementary',
  'Murrayville-woodson Elem School',
  'Murrell School',
  'Murrell Taylor Elem. School',
  'Murrieta Canyon Academy',
  'Murrieta Elementary',
  'Murrieta Mesa High',
  'Murrieta Valley High',
  'Murry Bergtraum High School For Business Careers',
  'Murry Fly El',
  'Murtaugh Schools',
  'Murwood Elementary',
  'Mus Alparslan University',
  'Musashi Institute of Technology',
  'Musashi University',
  'Musashino Academy of Music',
  'Musashino Art University',
  "Musashino Women's University",
  'Muscat College',
  'Muscatel Middle',
  'Muscatine High School',
  'Muscle Shoals Career Academy',
  'Muscle Shoals High School',
  'Muscle Shoals Middle School',
  'Muscogee Youth Development Center',
  'Muscota',
  'Muscoy Elementary',
  'Muse El Sch',
  'Muse Elem Sch',
  'Museum',
  'Museum Academy',
  'Museum School 25',
  'Museum School Avondale Estates',
  'Music Academy "Felix Nowowiejski" in Bydgoszcz',
  'Music Academy "Fryderyk Chopin" in Warszaw',
  'Music Academy "Ignacy Jana Paderewski" in Poznan',
  'Music Academy "Karol Lipinski" in Wroclaw',
  'Music Academy "Karol Szymanowski" in Katowice',
  'Music Academy "Stanislaw Moniuszko" in Gdansk',
  'Music Academy in Cracow',
  'Music Academy in Lodz',
  'Music Mountain Jr./sr. High School',
  'Muskego Elementary',
  'Muskego High',
  'Muskegon Area Career Tech Center',
  'Muskegon Community College',
  'Muskegon Community Education Center',
  'Muskegon County Juvenile Transition Center',
  'Muskegon Covenant Academy',
  'Muskegon Heights Academy',
  'Muskegon High School',
  'Muskegon Middle School',
  'Muskegon Montessori Academy For Environmental Change',
  'Muskegon River Youth Home',
  'Muskingum College',
  'Muskogee Ec Ctr',
  'Muskogee Hs',
  'Musselman High School',
  'Musselman Middle School',
  'Musser El Sch',
  'Musson Elementary School',
  'Mustafa International University',
  'Mustafa Kemal University',
  'Mustang Centennial Es',
  'Mustang Creek Es',
  'Mustang Education Ctr',
  'Mustang Es',
  'Mustang Horizon Ies',
  'Mustang Hs',
  'Mustang Lakehoma Es',
  'Mustang Ms',
  'Mustang North Ms',
  'Mustang Trails Es',
  'Mustang Valley Es',
  'Mutesa 1 Royal University',
  'Muthesius-Hochschule Fachhochschule für Kunst und Gestaltung',
  'Mutual Elementary',
  'Mvao Middle School',
  'Mvwsd Home & Hospital',
  'Mwalimu Nyerere Memorial Academy',
  'My Little School',
  'Myakka City Elementary School',
  'Myakka River Elementary School',
  'Myanmar Aerospace Engineering University',
  'Myatt El',
  'Mydistrict Virtual School',
  'Myers Corners School',
  'Myers El',
  'Myers El Sch',
  'Myers Elementary',
  'Myers Elementary School',
  'Myers Es',
  'Myers Middle School',
  'Myers Park High',
  'Myers Park Traditional',
  'Myers-ganoung Elementary School',
  'Myers-wilkins Elementary',
  'Myersville Elementary',
  'Myford Elementary',
  'Mykolo Romerio university',
  'Mylan Park Elementary School',
  'Myles Elementary School',
  'Myles J. Mcmanus Middle School',
  'Mynderse Academy',
  'Myongji University',
  'Myra A. Noble Elementary',
  'Myra Green Middle',
  'Myra Linn Elementary',
  'Myra Terwilliger Elementary School',
  'Myrna M. Fuentes',
  'Myron B. Thompson Academy - A New Century Pcs',
  'Myron D. Witter Elementary',
  'Myron J. Francis Elementary',
  'Myron L. Powell Elementary School',
  'Myrtle Attendance Center',
  'Myrtle Ave Sch',
  'Myrtle Avenue Elementary',
  'Myrtle Beach Elementary',
  'Myrtle Beach High',
  'Myrtle Beach Intermediate',
  'Myrtle Beach Middle',
  'Myrtle Beach Primary',
  'Myrtle C. Thibodeaux Elementary School',
  'Myrtle Cooper El',
  'Myrtle Creek Elementary School',
  'Myrtle Crest School',
  'Myrtle Grove Elementary School',
  'Myrtle Grove K-8 Center',
  'Myrtle Grove Middle',
  'Myrtle H. Stevens School',
  'Myrtle Place Elementary School',
  'Myrtle Point High School',
  'Myrtle Tate Elementary School',
  'Myrtlewood Elementary School',
  'Myschoolkent',
  'Mystic Middle School',
  'Mystic Valley Regional Charter School',
  'Myton School',
  'Mzumbe University (Chuo Kikuu Mzumbe)',
  'Mzuzu University',
  'Mälardalen University',
  'Märkische Fachhochschule Iserlohn',
  'N A Hamilton Bicentennial School',
  'N A Howry Intermediate',
  'N A Walbran Elementary School',
  'N B Galloway Elem School',
  'N B Mills Elementary',
  'N Dade Center For Modern Language',
  'N Garland H S',
  'N H Pilate Middle School',
  'N H Wixon Innovation School',
  'N Hopewell-winterstown El Sch',
  'N Judson-san Pierre Jr Sr High Sch',
  'N L Dillard Middle',
  'N O Nelson Elem School',
  'N Omak Elementary',
  'N R Burger Middle School',
  'N Virginia Reg Spec Ed Pgm',
  'N W Harllee Early Childhood Center',
  'N. A. Bleshman Regional Day School',
  'N. B. Cook Elementary School',
  'N. Charlestown Community School',
  'N. P. Moss Preparatory Academy',
  'N.a. Chaderjian High',
  'N.a.c.a. Freedom And Democracy Academy Ii',
  'N.b. Clements Junior High',
  'N.e. Community Treatment Ctr.',
  'N.e. Regional Correction Cntr.',
  'N.e.w. Academy Canoga Park',
  'N.e.w. Academy Of Science And Arts',
  'N.p. Trist Middle School',
  'N.s.u. Elementary Lab School',
  'N.s.u. Middle Lab School',
  'N.w. (northwestern) Regional Juvenile Detention Home',
  'NABA - Nuova Accademia di Belle Arti Milan',
  'NACHLAS BARS YAAKOV',
  'NACOGDOCHES CHRISTIAN ACADEMY',
  'NAMASTE MONTESSORI SCHOOL',
  'NAMPA CHRISTIAN SCHOOLS',
  'NAMPA MONTESSORI',
  'NANSEMOND-SUFFOLK ACADEMY',
  'NANTUCKET LIGHTHOUSE SCHOOL',
  'NANTUCKET NEW SCHOOL',
  'NAPA CHRISTIAN CAMPUS OF EDUCATION',
  "NAPA MONTESSORI CHILDREN'S HOUSE INC.",
  'NAPERVILLE CHRISTIAN ACADEMY',
  'NAPERVILLE MONTESSORI SCHOOL',
  'NAPLES ADVENTIST CHRISTIAN SCHOOL',
  'NAPLES CHRISTIAN ACADEMY',
  'NARDIN ACADEMY',
  'NASA GODDARD CHILD DEVELOPMENT CENTER',
  'NASH ROAD',
  'NASHOBA BROOKS SCHOOL',
  'NASHOBA LEARNING GROUP',
  'NASHOBA MONTESSORI SCHOOL',
  'NASHUA CATHOLIC REGIONAL JR HIGH SCHOOL',
  'NASHUA CHILD LEARNING CENTER',
  'NASHUA CHILDRENS HOME',
  'NASHUA CHRISTIAN ACADEMY',
  'NASHVILLE CHRISTIAN SCHOOL',
  'NASHVILLE INTERNATIONAL ACADEMY',
  'NASSAU SUFFOLK SERVICES FOR AUTISM',
  'NATCHEZ TRACE YOUTH ACADEMY',
  'NATHANAEL GREENE ACADEMY',
  'NATION AMISH SCHOOL',
  'NATION CHILDREN SCHOOL OF NOAHS ARK',
  'NATIONAL CHRISTIAN ACADEMY',
  'NATIONAL HIGH SCHOOL',
  'NATIONAL UNIVERSITY VIRTUAL HIGH SCHOOL',
  'NATIONWIDE ACADEMY',
  'NATIVE AMERICAN CHRISTIAN ACADEMY',
  'NATIVITY ACADEMY AT ST BONIFACE',
  'NATIVITY BVM SCHOOL',
  'NATIVITY CATHOLIC SCHOOL',
  'NATIVITY ELEMENTARY SCHOOL',
  'NATIVITY MIGUEL OF SCRANTON',
  'NATIVITY OF MARY SCHOOL',
  'NATIVITY OF OUR BLESSED LADY',
  'NATIVITY OF OUR LORD CATHOLIC SCHOOL',
  'NATIVITY OF OUR LORD SCHOOL',
  'NATIVITY OF OUR SAVIOR SCHOOL',
  'NATIVITY OF THE BLESSED VIRGIN MARY SCHOOL',
  'NATIVITY PARISH SCHOOL',
  'NATIVITY PREP ACADEMY',
  'NATIVITY PREPARATORY ACADEMY',
  'NATIVITY PREPARATORY SCHOOL',
  'NATIVITY PREPARATORY SCHOOL NEW BEDFORD',
  'NATIVITY PREPARATORY SCHOOL OF WILMINGTON',
  'NATIVITY PRESCHOOL & KINDERGARTEN',
  'NATIVITY SCHOOL',
  'NATIVITYMIGUEL MIDDLE SCHOOL - BUFFALO',
  'NATOMAS CHRISTIAN SCHOOL REAL LIFE CHURCH',
  'NATURALLY GROWN SCHOOL',
  'NATURE DISCOVERY SCHOOL',
  'NATURE HILL SCHOOL',
  'NATURE NURTURES FARM SCHOOL',
  'NATURE VALLEY SCHOOL',
  "NATURE'S WAY MONTESSORI",
  'NATURES CLASSROOM INSTITUTE & MONTESSORI SCHOOL',
  'NATURES WAY MONTESSORI SCHOOL',
  'NAUDAIN MONTESSON ACADEMY',
  'NAUTILUS MONTESSORI SCHOOL',
  'NAUVOO COUNTRY',
  'NAVAJO CHRISTIAN PREPARATORY ACADEMY',
  'NAVAL ACADEMY PRIMARY SCHOOL',
  'NAVY HALE KEIKI SCHOOL',
  'NAYA EARLY COLLEGE ACADEMY',
  'NAZARENE CHRISTIAN ACADEMY',
  'NAZARENE CHRISTIAN ACADEMY BIG SPRING',
  'NAZARENE NURSERY SCHOOL & KINDERGARTEN',
  'NAZARETH ACADEMY',
  'NAZARETH ACADEMY CATHOLIC SCHOOL',
  'NAZARETH ACADEMY GRADE SCHOOL',
  'NAZARETH ACADEMY HIGH SCHOOL',
  'NAZARETH ELEMENTARY',
  'NAZARETH SCHOOL',
  'NC BOYS ACADEMY',
  'NE MILES JEWISH DAY SCHOOL',
  'NE PEDIATRIC SCHOOL',
  'NEAR NORTH MONTESSORI',
  'NEBO CROSSING ACADEMY',
  'NEBO VALLEY',
  'NEBRASKA EVANGELICAL LUTHERAN HIGH SCHOOL',
  'NED University of Engineering and Technology Karachi',
  'NEEDLES SDA SCHOOL',
  'NEIGHBORHOOD ACADEMY',
  'NEIGHBORHOOD CHRISTIAN CENTER',
  'NEIGHBORHOOD CHRISTIAN SCHOOL',
  'NEIGHBORHOOD LEARNING CENTER',
  'NEIGHBORHOOD SCHOOLHOUSE',
  'NEIGHBORS GROVE CHRISTIAN ACADEMY',
  'NEIL HELLMAN SCHOOL/PARSONS CHILD & FAMILY CENTER',
  'NELSON ACADEMY OF AGRICULTURAL SCIENCES ONLINE',
  'NELSON COUNTY BAPTIST SCHOOL',
  'NELSON MANDELA ELEM.',
  'NEOSHO CHRISTIAN SCHOOLS',
  'NERINX HALL HIGH SCHOOL',
  'NESIVOS BAIS YAAKOV',
  'NESKOWIN VALLEY SCHOOL',
  'NETHERLANDS REFORMED CHRISTIAN SCHOOL',
  'NEUSE CHRISTIAN ACADEMY',
  'NEVADA SAGE WALDORF SCHOOL',
  'NEW ALADDIN LEARNING CENTER',
  'NEW ALBANY MONTESSORI SCHOOL',
  'NEW AMSTERDAM SCHOOL',
  'NEW ANGELS DAY CARE',
  'NEW BEDFORD SCHOOL',
  'NEW BEGININGS ACADEMY',
  'NEW BEGINNERS PREPARATORY',
  'NEW BEGINNING CHRISTIAN ACADEMY',
  'NEW BEGINNINGS',
  'NEW BEGINNINGS CHRISTIAN ACADEMY',
  'NEW BEGINNINGS CHRISTIAN SCHOOL',
  'NEW BEGINNINGS MONTESSORI',
  'NEW BERN CHRISTIAN ACADEMY',
  'NEW BETHEL ADVENTIST CHRISTIAN ACADEMY',
  'NEW BETHLEHEM MENNONITE SCHOOL',
  'NEW BETHLEHEM WESLEYAN METHODIST SCHOOL',
  'NEW BIGINNINGS ANNEX',
  'NEW BRAUNFELS CHRISTIAN ACADEMY',
  'NEW BRIDGE ACADEMY',
  'NEW BUNKER HILL',
  'NEW BURLINGTON AMISH SCHOOL',
  'NEW CANAAN COUNTRY SCHOOL',
  'NEW CASTLE CHRISTIAN ACADEMY',
  'NEW CENTURY MONTESSORI ACADEMY',
  'NEW CENTURY SCHOOL THE',
  'NEW CHAPEL CHRISTIAN ACADEMY',
  'NEW CITY CHRISTIAN SCHOOL',
  'NEW CITY SCHOOL',
  'NEW COMMUNITY ACADEMY',
  "NEW CONNECTION'S ACADEMY",
  'NEW CONQUEST MENNONITE SCHOOL',
  'NEW COVENANT ACADEMY',
  'NEW COVENANT CHRISTIAN SCHOOL',
  "NEW COVENANT LUTHERAN CHURCH CHILDREN'S MINISTRY C",
  'NEW COVENANT SCHOOL',
  'NEW COVENANT SCHOOLS',
  'NEW CREATION CHRISTIAN ACADEMY',
  'NEW DANVILLE SCHOOL',
  'NEW DAY',
  'NEW DAY ACADEMY',
  'NEW DIMENSION CHRISTIAN SCHOOL',
  'NEW DIRECTION CHSTIAN ACADEMY',
  'NEW DIRECTIONS',
  'NEW DIRECTIONS COMMUNITY SCHOOL OF ORLANDO',
  'NEW DIRECTIONS SCHOOL',
  'NEW DIRECTIONS SCHOOL AT NCCF',
  'NEW DISCOVERY MONTESSORI SCHOOL',
  'NEW DORP CHRISTIAN ACADEMY',
  'NEW ENGLAND ADOLESCENT RESEARCH INSTITUTE',
  'NEW ENGLAND CLASSICAL ACADEMY',
  'NEW ENGLAND HEBREW ACADEMY',
  'NEW ENGLAND VALLEY MENNONITE',
  'NEW ERA CHRISTIAN ACADEMY',
  'NEW ERA CHRISTIAN SCHOOL',
  'NEW ERA EDUCAITON INC',
  'NEW FAITH CHRISTIAN ACADEMY',
  'NEW FRANKLIN CHRISTIAN ACADEMY',
  'NEW FRIENDS CHILDCARE & PRESCHOOL',
  'NEW FRONTIERS CHRISTIAN ACADEMY',
  'NEW GARDEN FRIENDS SCHOOL',
  "NEW GENERATION MONTESSORI CHILDREN'S ACADEMY",
  'NEW GENERATION SCHOOL',
  'NEW GRACE CENTER CHRISTIAN SCHOOL',
  'NEW HARVEST CHRISTIAN SCHOOLS',
  'NEW HAVEN MENNONITE SCHOOL',
  'NEW HAVEN SCHOOL',
  'NEW HAVEN YFS',
  'NEW HEBRON CHRISTIAN SCHOOL',
  'NEW HEIGHTS BAPTIST ACADEMY',
  'NEW HEIGHTS CHRISTIAN ACADEMY',
  'NEW HEIGHTS CHRISTIAN SCHOOL',
  'NEW HEIGHTS SCHOOL TN CHILDRENS HOME WEST',
  'NEW HOLLAND EARLY LEARNING CENTER',
  'NEW HOLLAND MENNONITE SCHOOL',
  'NEW HOPE ACADEMY',
  'NEW HOPE CAROLINAS',
  'NEW HOPE CHRISTIAN ACADEMY',
  'NEW HOPE CHRISTIAN ACADEMY/ROBINSON SMALL LRNG CTR',
  'NEW HOPE CHRISTIAN SCHOOL',
  'NEW HOPE CHRISTIAN SCHOOLS',
  'NEW HOPE EARLY LEARNING',
  'NEW HOPE LUTHERAN ACADEMY',
  'NEW HOPE MANOR',
  'NEW HOPE SCHOOL',
  'NEW HOPE SDA SCHOOL AND LEARNING CENTER',
  'NEW HORIZON ACADEMY',
  'NEW HORIZON CENTER',
  'NEW HORIZON CHRISTIAN SCHOOL',
  'NEW HORIZON ELEMENTARY SCHOOL - IRVINE',
  'NEW HORIZON SCHOOL',
  'NEW HORIZON SCHOOL WESTSIDE',
  'NEW HORIZON SCHOOL-LOS ANGELES CAMPUS',
  'NEW HORIZONS ACADEMY',
  'NEW HORIZONS CENTER FOR LEARNING',
  'NEW HORIZONS CHILD DEVMNT CENTER',
  'NEW HORIZONS CHRISTIAN ACADEMY',
  'NEW HORIZONS COUNTRY DAY SCHOOL',
  'NEW HORIZONS ELEMENTARY SCHOOL',
  'NEW HORIZONS PRIVATE SCHOOL',
  'NEW HORIZONS SCHOOL',
  'NEW HORIZOS ACADEMY',
  'NEW INDEPENDENCE ACADEMY',
  'NEW ISRAEL CHRISTIAN SCHOOL',
  'NEW JERSEY UNITED CHRISTIAN ACADEMY',
  'NEW JERUSALEM CHRISTIAN ACADEMY',
  'NEW KITUWAH ACADEMY',
  'NEW L.E.A.P.S. ACADEMY',
  'NEW LEAF ACADEMY',
  'NEW LEAF SCHOOL FOR CHANGE',
  'NEW LIFE ACADEMY',
  'NEW LIFE ALTERNATIVES CHRISTIAN ACADEMY',
  'NEW LIFE CELEBRATION CHRISTIAN ACADEMY',
  'NEW LIFE CHRISTIAN ACADEMY',
  'NEW LIFE CHRISTIAN ACADEMY HYBRID',
  'NEW LIFE CHRISTIAN ACADEMY OF ACHIEVERS',
  'NEW LIFE CHRISTIAN DAY SCHOOL',
  'NEW LIFE CHRISTIAN SCHOOL',
  'NEW LIFE CHRISTIAN SCHOOL OF EXCELLENCE',
  'NEW LIFE CHRISTIAN SCHOOL/GREAT BEGINNINGS PRESCHO',
  'NEW LIFE CHRISTIAN SCHOOLS',
  'NEW LIFE PREPARATORY ACADEMY',
  'NEW LIGHT BAPTIST SCHOOL OF EXCELLENCE',
  'NEW LIVING WORD SCHOOL',
  'NEW MANNA CHRISTIAN SCHOOL',
  'NEW MILLTOWN AMISH PAROCHIAL SCHOOL',
  'NEW MONTESSORI SCHOOL',
  'NEW MORNING SCHOOL',
  'NEW MOUNT OLIVE CHRISTIAN DAYCARE & ACADEMY',
  'NEW ORLEANS ADVENTIST ACADEMY',
  'NEW OUTLOOK ACADEMY',
  'NEW PACT CHRISTIAN ACADEMY',
  'NEW PATHWAYS ACADEMY',
  'NEW PROSPECT FWB CHURCH & CHRISTIAN SCHOOL',
  'NEW PROVIDENCE SCHOOL',
  'NEW ROAD SCHOOL',
  'NEW ROAD SCHOOL OF SOMERSET',
  'NEW SALEM EVANGELICAL LUTHERAN SCHOOL',
  'NEW SCHOOL MONTESSORI CENTER',
  'NEW SCHOOL OF MONMOUTH COUNTY',
  'NEW SONG SCHOOL',
  'NEW SPIRITUAL FOUNDATION CHRISTIAN ACADEMY',
  'NEW ST MARK MONTESSORI SCHOOL',
  'NEW STORY',
  'NEW STORY SCHOOL',
  'NEW SUMMIT SCHOOL',
  'NEW SYSTEM SCHOOL INC.',
  'NEW TESTAMENT BAPTIST CHRISTIAN SCHOOL',
  'NEW TESTAMENT BAPTIST CHURCH SCHOOL',
  'NEW TESTAMENT BAPTIST SCHOOL',
  'NEW TESTAMENT CHRISTIAN ACADEMY',
  'NEW TESTAMENT CHRISTIAN CENTER',
  'NEW TESTAMENT CHRISTIAN SCHOOL',
  'NEW TESTAMENT CHURCH LITTLE ANGELS ACADEMY',
  'NEW TESTAMENT CHURCH SCHOOL',
  'NEW ULM AREA CATHOLIC SCHOOLS',
  'NEW VISION CHRISTIAN ACADEMY',
  'NEW VISION WILDERNESS WEST COAST',
  'NEW VISTA MONTESSORI SCHOOL',
  'NEW VISTA SCHOOL',
  'NEW VISTAS ACADEMY',
  'NEW VISTAS SCHOOL',
  'NEW WAY ACADEMY',
  'NEW WORLD MONTESSORI SCHOOL',
  'NEW YORK INSTITUTE FOR SPECIAL EDUCATION',
  'NEW YORK MILITARY ACADEMY',
  'NEW YORK SCHOOL FOR THE DEAF',
  'NEWARK BOYS CHORUS SCHOOL',
  'NEWARK CATHOLIC HIGH SCHOOL',
  'NEWARK CENTER FOR CREATIVE LEARNING',
  'NEWARK CHRISTIAN SCHOOL',
  'NEWBERRY ACADEMY',
  'NEWBERRY CHRISTIAN COMMUNITY SCHOOL',
  'NEWBRIDGE SCHOOL',
  'NEWBURGH CHRISTIAN SCHOOL',
  'NEWBURYPORT MONTESSORI SCHOOL',
  'NEWCOMB ROAD',
  'NEWFOUND ACADEMY',
  'NEWGATE SCHOOL',
  'NEWHOPE ACADEMY',
  'NEWMAN CATHOLIC',
  'NEWMAN CATHOLIC ELEMENTARY AT ST ANNE PARISH',
  'NEWMAN CATHOLIC ELEMENTARY SCHOOL AT ST MARK',
  'NEWMAN CATHOLIC HIGH SCHOOL',
  'NEWMAN CENTRAL CATHOLIC HIGH SCHOOL',
  'NEWMARK HIGH SCHOOL',
  'NEWPORT CENTRAL CATHOLIC HIGH SCHOOL',
  "NEWPORT CHILDREN'S SCHOOL",
  'NEWPORT CHRISTIAN ACADEMY',
  'NEWPORT CHRISTIAN SCHOOL',
  'NEWPORT MONTESSORI SCHOOL',
  'NEWPORT SCHOOL',
  'NEWTON BIBLE CHRISTIAN SCHOOL',
  'NEWTON CHRISTIAN SCHOOL',
  'NEWTON COUNTY ACADEMY',
  'NEWTON LEARNING CENTER',
  'NEWTON MONTESSORI SCHOOL',
  'NEWTOWN FRIENDS SCHOOL',
  'NEXT GENERATION ACADEMICS INC',
  'NEXT GENERATION CHILD DEVELOPMENT CENTER',
  'NEXT GENERATION CHRISTIAN ACADEMY',
  'NEXT GENERATION SCHOOL',
  'NEXTIDE ACADEMY',
  'NEXUS SCHOOL',
  'NFI BUXTON - BEACON HOUSE',
  'NFI SIDNEY RIVER BEND SCHOOL',
  'NFI-STETSON RANCH SCHOOL',
  'NGO Vladikavkaz Institute of Economics Management, and Law',
  'NHS HUMAN SERVICES',
  'NHS SCHOOL',
  'NHS SCHOOL OF READING',
  'NHS SCHOOL ST ANNES',
  'NHS SCHOOL-WOODHAVEN',
  'NHS SCHOOLS- APOLLO',
  "NHTI-​Concord's Community College",
  'NHTV Breda University of Professional Education',
  'NIA HOUSE LEARNING CENTER',
  'NIA HOUSE MONTESSORI',
  'NIAGARA CATHOLIC JR/SR HIGH SCHOOL',
  'NICAEA ACADEMY',
  'NICAEA ACADEMY OF CAPE CORAL',
  'NICHOLAS SCHOOL',
  'NICHOLS SCHOOL',
  'NICKTOWN PAROCHIAL SCHOOL',
  'NIGHTINGALE MONTESSORI',
  'NIGHTINGALE-BAMFORD SCHOOL',
  'NIGRITIAN ACADEMY',
  'NIKAITCHUAT ILISAGVIAT',
  'NILES ADVENTIST SCHOOL',
  'NISHI HONGWANJI CHILD DEVELOPMENT CENTER',
  'NIST CHILD CARE CENTER',
  'NITTANY CHRISTIAN SCHOOL',
  'NIVERTON SCHOOL',
  'NJ CLASSICAL ACADEMY',
  'NJEDDA ELEMENTARY SCHOOL',
  'NJEDDA HS',
  "NJERI'S MORNING GLORY SCHOOL",
  'NKWUSM SALISH LANGUAGE SCHOOL',
  'NO COUNTY CHRISTIAN SCHOOL',
  "NOAH'S ARK",
  "NOAH'S ARK CHILD CARE CENTER",
  "NOAH'S ARK CHRISTIAN PRESCHOOL & KINDERGARTEN",
  "NOAH'S ARK LEARNING CENTER",
  "NOAH'S ARK PRESCHOOL/KINDERGARTEN",
  'NOAHS ARK CHRISTIAN CHILD CARE CENTER',
  'NOAHS ARK CHRISTIAN PRESCHOOL',
  'NOAHS ARK KINDERGARTEN',
  'NOAHS ARK LEARNING CENTER/PRESCHOOL',
  'NOAHS ARK PRESCHOOL AND KINDERGARTEN',
  'NOAHS PARK WEEKDAY EARLY EDUCATION',
  'NOBLE ACADEMY',
  'NOBLE AND GREENOUGH SCHOOL',
  'NOBLE LEADERSHIP ACADEMY',
  'NOLAN CATHOLIC HIGH SCHOOL',
  'NOLAN CHRISTIAN ACADEMY',
  'NOONAN ELEMENTARY ACADEMY',
  'NOONDAY CHRISTIAN ACADEMY',
  'NOOR ACADEMY',
  'NOOR ACADEMY OF ARIZONA',
  'NOOR-UL-IMAN SCHOOL',
  'NORA CRONIN PRESENTATION ACADEMY',
  'NORBECK MONTESSORI CENTER',
  'NORD ANGLIA INTERNATIONAL SCHOOL',
  'NOREEN COOK CENTER FOR ECE OF HAR ZION TEMPLE',
  'NORFOLK ACADEMY',
  'NORFOLK CATHOLIC ELEM.',
  'NORFOLK CATHOLIC HIGH SCHOOL',
  'NORFOLK CHRISTIAN SCHOOL - BEACH CAMPUS',
  'NORFOLK CHRISTIAN SCHOOLS',
  'NORFOLK COLLEGIATE SCHOOL',
  'NORLINA CHRISTIAN SCHOOL',
  'NORMA D RICHARDS ADVENTIST CHRISTIAN SCHOOL',
  'NORMANTOWN CHRISTIAN LTD.',
  'NORMATIVE SERVICES',
  'NORRIS ACADEMY - SEQUEL',
  'NORTH AMERICAN ACADEMY',
  'NORTH AMERICAN MARTYRS SCHOOL',
  'NORTH AMISH SCHOOL',
  'NORTH BAY CHRISTIAN ACADEMY',
  'NORTH BOGARD AMISH SCHOOL',
  'NORTH BRANCH SCHOOL',
  'NORTH BRIDGE ACADEMY',
  'NORTH BROWARD CHRISTIAN SCHOOL',
  'NORTH BROWARD PREPARATORY SCHOOL',
  'NORTH BUNKER HILL',
  'NORTH CARROLL COMMUNITY SCHOOL',
  'NORTH CEDAR ACADEMY',
  'NORTH CENTRAL TEXAS ACADEMY',
  'NORTH CHRISTIAN SCHOOL',
  'NORTH CLACKAMAS CHRISTIAN SCHOOL',
  'NORTH COAST MENNONITE',
  'NORTH COAST MONTESSORI SCHOOL',
  'NORTH COBB CHRISTIAN SCHOOL',
  'NORTH COUNTRY CHRISTIAN SCHOOL',
  'NORTH COUNTRY SCHOOL',
  'NORTH COUNTY CHRISTIAN SCHOOL',
  'NORTH CROSS SCHOOL',
  'NORTH DALLAS ADVENTIST ACADEMY',
  'NORTH DELTA SCHOOL',
  'NORTH END',
  'NORTH END MONTESSORI SCHOOL LLC',
  'NORTH FLORIDA CHRISTIAN SCHOOL',
  'NORTH FLORIDA SCHOOL OF SPECIAL EDUCATION',
  'NORTH FORK',
  'NORTH FORK CHRISTIAN SCHOOL',
  'NORTH HARDIN CHRISTIAN SCHOOL',
  'NORTH HEIGHTS CHRISTIAN ACADEMY',
  'NORTH HENDREN AMISH SCHOOL',
  'NORTH HILLS CHRISTIAN SCHOOL',
  'NORTH HILLS PREP',
  'NORTH IDAHO CHRISTIAN SCHOOL',
  'NORTH IOWA CHRISTIAN SCHOOL',
  'NORTH JACKSON AMISH SCHOOL/BEN KING, CHAIRMAN',
  'NORTH KANNAPOLIS CHRISTIAN ACADEMY',
  'NORTH KERN CHRISTIAN SCHOOL',
  'NORTH KISSIMMEE CHRISTIAN SCHOOL',
  'NORTH LIBERTY CHRISTIAN SCHOOL',
  'NORTH LITTLE ROCK CATHOLIC ACADEMY',
  'NORTH LITTLE ROCK MONTESSORI SCHOOL',
  'NORTH LOVE CHRISTIAN',
  'NORTH MOBILE CHRISTIAN SCHOOL',
  'NORTH MOUNTAIN VIEW AMISH',
  'NORTH MYRTLE BEACH CHRISTIAN SCHOOL',
  'NORTH NEW SUMMIT SCHOOL',
  'NORTH PARK CHRISTIAN ACADEMY',
  'NORTH PARK ELEMENTARY SCHOOL',
  'NORTH PHOENIX BAPTIST CHURCH PRESCHOOL',
  'NORTH PITTSBURGH CHILDRENS HOUSE',
  'NORTH POINTE CHRISTIAN SCHOOLS',
  'NORTH POLE CHRISTIAN SCHOOL',
  'NORTH RALEIGH CHRISTIAN ACADEMY',
  'NORTH RICHMOND',
  'NORTH ROME CHRISTIAN SCHOOL',
  'NORTH SCOTT AMISH SCHOOL',
  'NORTH SEATTLE FRENCH SCHOOL',
  'NORTH SHORE CHRISTIAN',
  'NORTH SHORE COUNTRY DAY SCHOOL',
  'NORTH SHORE DAY SCHOOL',
  'NORTH SHORE HEBREW ACADEMY',
  'NORTH SHORE HEBREW ACADEMY HIGH SCHOOL',
  'NORTH SHORE MONTESSORI SCHOOL',
  'NORTH SIDLING HILL',
  'NORTH SPENCER CHRISTIAN ACADEMY',
  'NORTH SPRING ACADEMY',
  'NORTH STAR AMISH SCHOOL/BENUEL KING, CHAIRMAN',
  'NORTH STAR CHRISTIAN SCHOOL',
  'NORTH STAR MONTESSORI SCHOOL',
  'NORTH STONINGTON CHRISTIAN ACADEMY',
  'NORTH SUNFLOWER ACADEMY',
  'NORTH TEXAS LEADERSHIP ACADEMY',
  'NORTH VALLEY BAPTIST SCHOOL',
  'NORTH VALLEY CHRISTAIN ACADEMY',
  'NORTH VALLEY SCHOOL, REDDING',
  'NORTH VALLEY SCHOOL/VICTOR TREATMENT CTR INC',
  'NORTH WALL SCHOOLS',
  'NORTH WALTERBORO CHRISTIAN ACADEMY',
  'NORTH WHIDBEY CHRISTIAN HIGH SCHOOL',
  'NORTH YARMOUTH ACADEMY',
  'NORTHBAY CHRISTIAN ACADEMY',
  'NORTHBROOK COMMUNITY SYNOGOGUE',
  'NORTHBROOK MONTESSORI SCHOOL',
  'NORTHCREEK ACADEMY & PRESCHOOL',
  'NORTHDADE REGIONAL ACADEMY',
  'NORTHDALE LUTHERAN SCHOOL',
  'NORTHEAST ACADEMY',
  'NORTHEAST BAPTIST SCHOOL',
  'NORTHEAST CENTRAL CHRISTIAN SCHOOL',
  'NORTHEAST CHRISTIAN ACADEMY',
  'NORTHEAST SPENCERVILLE',
  'NORTHEASTERN ACADEMY',
  'NORTHEASTERN WISCONSIN LUTHERAN HIGH SCHOOL',
  'NORTHERN CALIFORNIA PREPARATORY',
  'NORTHERN CAMBRIA CATHOLIC SCHOOL',
  'NORTHERN CHAUTAUQUA CATHOLIC SCHOOL',
  'NORTHERN DAUPHIN CHRISTIAN SCHOOL',
  'NORTHERN HILLS CHRISTIAN ACADEMY',
  'NORTHERN ILLINOIS ACADEMY',
  'NORTHERN KY MONTESSORI CENTER',
  'NORTHERN LIGHT MENNONITE SCHOOL',
  'NORTHERN LIGHT SCHOOL',
  'NORTHERN LIGHTS ACADEMY',
  'NORTHERN LIGHTS MONTESSORI SCHOOL',
  'NORTHERN LIGHTS SCHOOL',
  'NORTHERN MAPLE CHRISTIAN SCHOOL',
  'NORTHERN MICHIGAN CHRISTIAN ACADEMY',
  'NORTHERN MICHIGAN CHRISTIAN SCHOOL',
  'NORTHERN OHIO ADVENTIST ACADEMY',
  'NORTHERN PRIVATE SCHOOL',
  'NORTHERN VOICES',
  'NORTHFIELD MONTESSORI',
  'NORTHFIELD MOUNT HERMON SCHOOL',
  'NORTHGATE ACADEMY',
  'NORTHLAKE ACADEMY',
  'NORTHLAKE CHRISTIAN',
  'NORTHLAKE MONTESSORI & CC',
  'NORTHLAND CHRISTIAN SCHOOL',
  'NORTHLAND LUTHERAN HIGH SCHOOL',
  'NORTHMINSTER CHRISTIAN SCHOOL',
  'NORTHMINSTER LEARNING CENTER',
  'NORTHPOINT CHRISTIAN SCHOOL',
  'NORTHPOINT SCHOOL & INTENSIVE OUTPATIENT PROGRAM',
  'NORTHRIDGE LEARNING CENTER/DORIUS ACADEMY',
  'NORTHRIDGE PREPARATORY SCHOOL',
  'NORTHSHORE CHRISTIAN ACADEMY',
  'NORTHSIDE BAPTIST CHRISTIAN SCHOOL',
  'NORTHSIDE BAPTIST SCHOOL',
  'NORTHSIDE BAPTIST WEEKDAY EDUCATION PROGRAM',
  'NORTHSIDE CATHOLIC ACADEMY',
  'NORTHSIDE CATHOLIC SCHOOL',
  'NORTHSIDE CHRISTIAN ACADEMY',
  'NORTHSIDE CHRISTIAN SCHOOL',
  'NORTHSIDE MONTESSORI',
  'NORTHSIDE MONTESSORI SCHOOL',
  'NORTHSIDE SCHOOL',
  'NORTHSIDE THERAPEUTIC EARLY CHILDHOOD CENTER',
  'NORTHSTAR ACADEMY',
  'NORTHSTAR CHRISTIAN ACADEMY',
  'NORTHSTAR SCHOOL',
  'NORTHVIEW BAPTIST ACADEMY',
  'NORTHVIEW MONTESSORI PRESCHOOL AND KINDERGARTEN',
  'NORTHVIEW SDA CHRISTIAN SCHOOL',
  'NORTHVILLE CHRISTIAN SCHOOL',
  'NORTHWEST ACADEMY',
  'NORTHWEST CATHOLIC HIGH SCHOOL',
  'NORTHWEST CATHOLIC SCHOOL',
  'NORTHWEST CHAPEL PRESCHOO & K',
  'NORTHWEST CHILDRENS CENTER',
  'NORTHWEST CHILDRENS HOME EDUC CENTER',
  'NORTHWEST CHINESE ACADEMY',
  'NORTHWEST CHRISTIAN SCHOOL',
  'NORTHWEST CHRISTIAN SCHOOLS',
  'NORTHWEST HILLS CHRISTIAN SCHOOL',
  'NORTHWEST INDIAN BIBLE SCHOOL',
  'NORTHWEST IOWA PROTESTANT REFORMED SCHOOL',
  'NORTHWEST LIBERTY SCHOOL',
  'NORTHWEST LUTHERAN SCHOOL',
  'NORTHWEST SCHOOL DEAF AND HARD-OF-HEARING CHILDREN',
  'NORTHWEST SUBURBAN MONTESSORI SCHOOL',
  'NORTHWEST VILLAGE SCHOOL',
  'NORTHWEST YESHIVA HS',
  'NORTHWESTERN HUMAN SERVICES',
  'NORTHWESTERN HUMAN SERVICES AUTISM SCHOOL',
  'NORTHWESTERN HUMAN SVCS AUTISM SCHOOL',
  'NORTHWOOD ACADEMY',
  'NORTHWOOD MONTESSORI OF IRVINE',
  'NORTHWOOD MONTESSORI SCHOOL',
  'NORTHWOOD SCHOOL',
  'NORTHWOOD TEMPLE ACADEMY',
  'NORTHWOODS MENNONITE SCHOOL',
  'NORTHWOODS MONTESSORI SCHOOL - DORAVILLE',
  'NORTHWOODS SDA ELEMENTARY',
  'NORWALK CATHOLIC SCHOOL - EARLY CHILDHOOD CENTER',
  'NORWALK CATHOLIC SCHOOL - ELEMENTARY',
  'NORWALK CATHOLIC SCHOOL / ST PAUL HIGH SCHOOL',
  'NORWALK CHRISTIAN ACADEMY',
  'NORWALK CHRISTIAN SCHOOL',
  'NORWOOD MONTESSORI SCHOOL',
  'NORWOOD SCHOOL',
  'NORWOOD-FONTBONNE ACADEMY',
  'NOTRE DAME ACADEMY',
  'NOTRE DAME ACADEMY ELEMENTARY',
  'NOTRE DAME ACADEMY ELEMENTARY SCHOOL',
  'NOTRE DAME ACADEMY GIRLS HIGH SCHOOL',
  'NOTRE DAME ACADEMY HIGH SCHOOL',
  'NOTRE DAME ACADEMY OF BELLEVILLE',
  'NOTRE DAME ACADEMY OF BELLEVILLE-CATHEDRAL CAMPUS',
  'NOTRE DAME CATHOLIC HIGH SCHOOL',
  'NOTRE DAME CATHOLIC SCHOOL',
  'NOTRE DAME COLLEGE PREP',
  'NOTRE DAME CRISTO REY HIGH SCHOOL',
  'NOTRE DAME DE LA BAIE ACADEMY',
  'NOTRE DAME DE LA SALETTE BOYS ACADEMY',
  'NOTRE DAME DE SION HIGH SCHOOL',
  'NOTRE DAME DE SION SCHOOL',
  'NOTRE DAME ELEMENTARY SCHOOL',
  'NOTRE DAME HIGH SCHOOL',
  'NOTRE DAME JR/SR HIGH',
  'NOTRE DAME JR/SR HIGH SCHOOL',
  'NOTRE DAME MIDDLE SCHOOL',
  'NOTRE DAME OF BETHLEHEM SCHOOL',
  'NOTRE DAME PARISH SCHOOL',
  'NOTRE DAME PREPARATORY',
  'NOTRE DAME PREPARATORY SCHOOL',
  'NOTRE DAME PREPARATORY SCHOOL & MARIST ACADEMY',
  'NOTRE DAME REGIONAL HIGH SCHOOL',
  'NOTRE DAME SCHOOL',
  'NOTRE DAME SCHOOL OF DE PERE',
  'NOTRE DAME-CATHEDRAL LATIN SCHOOL',
  'NOTTAWA CHRISTIAN SCHOOL',
  'NOUVEL CATHOLIC CENTRAL ELEMENTARY',
  'NOVA CENTER',
  'NOVA SCHOOL',
  'NOVI NORTHVILLE MONTESSORI CENTER',
  'NOVITAS ACADEMY',
  'NSU UNIVERSITY SCHOOL',
  'NTB - Hochschule für Technik Buchs',
  'NTC CHRISTIAN ACADEMY',
  'NUEVA ESPERANZA LEADERSHIP ACADEMY',
  'NUEVO AMANECE DAY CARE/PRE-SCHOOL',
  'NUR ACADEMY',
  'NUR ISLAMIC SCHOOL OF LOUISVILLE',
  'NURSERY RHYMES PRESCHOOL & KINDERGARTEN',
  'NURTURING NEST MONTESSORI',
  'NYSARC INC-SUFFOLK CHAPTER',
  'NYSMITH SCHOOL FOR THE GIFTED',
  'Na Wai Ola - Pcs',
  "Na' Neelzhiin Ji'olta",
  "Naa Tsis'aan",
  'Naaba Ani Elementary',
  'Naalehu Elementary School',
  'Naaman Forest H S',
  'Naas Elementary School',
  'Nabnasset',
  'Naches Trail Elementary',
  'Naches Valley Elementary School',
  'Naches Valley High School',
  'Naches Valley Middle School',
  'Nachusa Campus Rssp',
  'Nachusa Campus School',
  'Naco Elementary School',
  'Nacogdoches Co Alter Ed Coop',
  'Nacogdoches County Daep Coop',
  'Nacogdoches H S',
  'Nadaburg Elementary School',
  'Nadine Johnson El',
  'Nagaland University',
  'Nagano University',
  'Nagaoka University of Technology',
  'Nagasaki Institute of Applied Science',
  'Nagasaki Prefectural University',
  'Nagasaki University',
  'Nagel Middle School',
  'Nagoya City University',
  'Nagoya College of Music',
  'Nagoya Economics University',
  'Nagoya Gakuin University',
  'Nagoya Institute of Technology',
  'Nagoya University',
  'Nagoya University of Arts',
  'Nagoya University of Commerce and Business Administration',
  'Nagoya University of Foreign Studies',
  "Nagoya Women's University",
  'Nagpur University',
  'Nags Head Elementary School',
  'Nah Tah Wahsh Public School Academy',
  'Nahed Chapman New American Aca',
  'Nahunta Elementary School',
  'Nahunta Primary School',
  'Naif Arab University for Security Sciences',
  'Nailor Elementary School',
  'Nairobi Institute of Business Studies',
  'Nairobi Technical Training Institute',
  'Najaf Abad University of Applied Science and Technology',
  'Najran University',
  'Nakamura Gakuen University',
  'Nakhchivan Private University',
  'Nakhchivan State University',
  'Nakina Middle',
  'Naknek Elementary',
  'Nalanda Open University',
  'Nalle Es',
  'Namaqua Elementary School',
  'Namaste Elem Charter School',
  'Name',
  'Namik Kemal University',
  'Nampa Early Childhood Center',
  'Nampa Senior High School',
  'Nan Gray Davis Elementary School',
  'Nanaikapono Elementary School',
  'Nanakuli Elementary School',
  'Nanakuli High & Intermediate School',
  'Nance Es',
  'Nanchang University',
  'Nancy Cory Elementary',
  'Nancy Elementary School',
  'Nancy Gomes Elementary School',
  'Nancy Grayson El Sch',
  'Nancy Hanks Elementary School',
  'Nancy J Cochran El',
  'Nancy Lopez Elementary',
  'Nancy Loud School',
  'Nancy Moseley El',
  'Nancy Neal El',
  'Nancy R. Kordyak Elementary',
  'Nancy Reynolds Elementary',
  'Nancy Ryles Elementary School',
  'Nancy Smith El',
  'Nancy Young Elementary School',
  'Nandua High',
  'Nandua Middle',
  'Nangarhar University',
  'Nanhua University',
  'Nanih Waiya Attendance Center',
  'Nanjing Agricultural University',
  'Nanjing Forestry University',
  'Nanjing Institute of Meteorology',
  'Nanjing Medical University',
  'Nanjing Normal University',
  'Nanjing Union Theological Seminary',
  'Nanjing University',
  'Nanjing University of Aeronautics and Astronautics',
  'Nanjing University of Chemical Technology',
  'Nanjing University of Economics',
  'Nanjing University of Posts and Telecommunications',
  'Nanjing University of Science and Technology',
  'Nanjing University of Traditional Chinese Medicine',
  'Nankai University',
  'Nannie Berry Elementary',
  'Nanny El',
  'Nansemond Parkway Elementary',
  'Nansemond River High',
  'Nantahala School',
  'Nantucket Elementary',
  'Nantucket High',
  'Nantucket Intermediate School',
  'Nanuet Senior High School',
  'Nanwalek School',
  'Nanyang Technological University',
  'Nanzan University',
  'Naomi Elementary School',
  'Naomi Pasemann El',
  'Naomi Press El',
  'Nap Ford Community Charter',
  'Napa County Community',
  'Napa County Juvenile Hall/court',
  'Napa County Opportunity',
  'Napa County Rop',
  'Napa High',
  'Napa Junction Elementary',
  'Napa Preschool Program',
  'Napa Street Elementary',
  'Napa Valley College',
  'Napa Valley Independent Studies',
  'Napa Valley Language Academy',
  'Napaaqtugmiut School',
  'Napavine Elementary',
  'Napavine Jr Sr High School',
  'Naper Elem School',
  'Naperville Central High School',
  'Naperville North High School',
  'Napi School',
  'Napier Elementary',
  'Napier University',
  'Naples Area Teenage Parenting',
  'Naples Elementary',
  'Naples Elementary School',
  'Naples High School',
  'Naples Park Elementary School',
  'Naples School',
  'Naples Street Elementary School',
  'Napoleon Elementary School',
  'Napoleon High School',
  'Napoleon Junior/senior High School',
  'Napoleon Middle School',
  'Napoleonville Middle School',
  'Napoleonville Primary School',
  'Nappanee Elementary School',
  'Naquag Elementary School',
  'Nara Medical University',
  'Nara Sangyo University',
  'Nara University',
  'Nara University of Commerce',
  'Nara University of Education',
  "Nara Women's University",
  'Naramake Elementary School',
  'Naranca Elementary',
  'Narciso Cavazos',
  'Narciso Rabell Cabrero',
  'Narcoossee Elementary School',
  'Narcoossee Middle School',
  'Narendra Deva University of Agriculture and Technology',
  'Naresuan University',
  'Narragansett Elementary',
  'Narragansett Elementary School',
  'Narragansett High School',
  'Narragansett Middle',
  'Narragansett Pier School',
  'Narragansett Regional High',
  'Narraguagus High School',
  'Narrows Elementary/middle',
  'Narrows High',
  'Narrows View Intermediate',
  'Narsee Monjee Institute of Management Studies',
  'Naruto University of Education',
  'Narvie Harris Elementary School',
  'Nasarawa State University Keffi',
  'Naschitti Elementary',
  'Naselle Elementary',
  'Naselle Homelink',
  'Naselle Jr Sr High Schools',
  'Naselle Youth Camp School',
  'Nash Central High',
  'Nash Central Middle',
  'Nash Community College',
  'Nash El',
  'Nash Elem School',
  'Nash Elementary',
  'Nasha Shkola Charter School',
  'Nashoba Public School',
  'Nashoba Regional',
  'Nashoba Valley Technical High School',
  'Nashport Elementary School',
  'Nashua 7-8',
  'Nashua Community College',
  'Nashua Elem.',
  'Nashua High School',
  'Nashua High School North',
  'Nashua High School South',
  'Nashua School',
  'Nashua Title I Preschool',
  'Nashua-plainfield Elementary School',
  'Nashua-plainfield Intermediate School',
  'Nashua-plainfield Junior-seniorhigh School',
  'Nashville Academy Of Computer Science',
  'Nashville Big Picture High School',
  'Nashville Classical',
  'Nashville Comm High School',
  'Nashville Elementary',
  'Nashville Elementary School',
  'Nashville Grade School',
  'Nashville High School',
  'Nashville Junior High School',
  'Nashville Prep',
  'Nashville Primary School',
  'Nashville School Of The Arts',
  'Nashville State Community College',
  'Nashwauk Secondary',
  'Nashwauk-keewatin Alp',
  'Nasonville Elementary',
  'Nassakeag Elementary School',
  'Nassau Boces',
  'Nassau Co Juvenile Detention Center',
  'Nassau Community Academy',
  'Nassau Community College',
  'Nassau County Adult School',
  'Nassau School',
  'Nassau Virtual Franchise',
  'Nassau Virtual Instruction (course Offerings)',
  'Nassau Virtual Instruction Program',
  'Nassau Virtual Instruction Program (district Provided)',
  'Natalbany Middle',
  'Natalia Early Child Ctr',
  'Natalia El',
  'Natalia H S',
  'Natalia J H',
  'Natalie Kreeger Elementary School',
  'Natchaug School',
  'Natchez Early College Academy',
  'Natchez Elementary School',
  'Natchez Freshman Academy',
  'Natchez High School',
  'Natchitoches Central High School',
  'Natchitoches Jr. High School',
  'Natchitoches Magnet School',
  'Nate Mack Elementary School',
  'Nate Perry Elementary School',
  'Natha Howell El',
  'Nathan Adams El',
  'Nathan B. Young Elementary School',
  'Nathan Bishop Middle',
  'Nathan Hale',
  'Nathan Hale - Primary Adaptive Learning (pal)',
  'Nathan Hale Arts Magnet School',
  'Nathan Hale Elem School',
  'Nathan Hale Elementary School',
  'Nathan Hale High',
  'Nathan Hale High School',
  'Nathan Hale Hs',
  'Nathan Hale Intermediate',
  'Nathan Hale Magnet Middle School',
  'Nathan Hale Middle School',
  'Nathan Hale Primary School',
  'Nathan Hale School',
  'Nathan Hale-ray High School',
  'Nathan Hale-ray Middle School',
  'Nathan T Hall School',
  'Nathan Twining Elementary School',
  'Nathan Twining Middle School',
  'Nathanael Greene Elementary',
  'Nathanael Greene Middle',
  'Nathanael Greene Primary School',
  'Nathanael Greene School',
  'Nathaniel Alexander Elementary',
  'Nathaniel Bowditch',
  'Nathaniel H Stephens Elementary School',
  'Nathaniel Hawthorne Ec School',
  'Nathaniel Hawthorne El',
  'Nathaniel Hawthorne Elementary School',
  'Nathaniel Morton Elementary',
  'Nathaniel Narbonne Senior High',
  'Nathaniel Scribner Middle School',
  'Nathaniel Woodhull Elementary School',
  'Natick High',
  'Nation Ford High',
  'National Academy Foundation',
  'National Academy for Physical Education and Sports Bucharest',
  'National Academy for Theatre and Film Arts "Krustju Sarafov"',
  'National Academy of Arts',
  'National Academy of Fine Arts',
  'National Academy of Music "Pantcho Vladigerov"',
  'National Aerospace University Kharkov Aviation Institute',
  'National Agricultural University',
  'National Aviation Academy',
  'National Aviation Univesity',
  'National Central University',
  'National Changhua University of Education',
  'National Cheng Kung University',
  'National Chengchi University',
  'National Chi Nan University',
  'National Chiao Tung University',
  'National Chiayi University',
  'National Chung Cheng University',
  'National Chung Hsing University Taichung',
  'National Chung Hsing University Taipei',
  'National City Middle',
  'National College for Medical and Technical Studies',
  'National College of Art and Design',
  'National College of Arts',
  'National College of Ireland',
  'National College of Physical Education and Sports',
  'National Collegiate Preparatory Pchs',
  'National Dairy Research Institute',
  'National Defence Academy of Latvia',
  'National Defence Medical College',
  'National Defense University',
  'National Dong Hwa University',
  'National Fisheries University',
  'National Formosa University',
  'National Hsin-Chu Teachers College',
  'National Hualien Teachers College',
  'National Ilan University',
  'National Institute of Development Administration',
  'National Institute of Education',
  'National Institute of Fitness and Sports Kanoya',
  'National Institute of Industrial Engineering',
  'National Institute of Mental Health and Neuro Sciences',
  'National Institute of Technology Agartala',
  'National Institute of Technology Andhra Pradesh',
  'National Institute of Technology Arunachal Pradesh',
  'National Institute of Technology Calicut',
  'National Institute of Technology Delhi',
  'National Institute of Technology Durgapur',
  'National Institute of Technology Goa',
  'National Institute of Technology Hamirpur',
  'National Institute of Technology Jamshedpur',
  'National Institute of Technology Kurukshetra',
  'National Institute of Technology Manipur',
  'National Institute of Technology Meghalaya',
  'National Institute of Technology Mizoram',
  'National Institute of Technology Nagaland',
  'National Institute of Technology Patna',
  'National Institute of Technology Puducherry',
  'National Institute of Technology Raipur',
  'National Institute of Technology Rourkela',
  'National Institute of Technology Sikkim',
  'National Institute of Technology Silchar',
  'National Institute of Technology Srinagar',
  'National Institute of Technology Surathkal',
  'National Institute of Technology Trichy',
  'National Institute of Technology Uttarakhand',
  'National Institute of Technology Warangal',
  'National Institute of the Arts',
  'National Inventors Hall Of Fame School Center For Stem',
  'National Kaohsiung First University of Science and Technology',
  'National Kaohsiung Normal University',
  'National Kaohsiung University of Applied Sciences',
  'National Law School of India University',
  'National Medical University O.O. Bogomolets',
  'National Metallurgical Academy of Ukraine',
  'National Military Academy of Afghanistan',
  'National Mining University',
  'National Museum Institute of History of Art Conservation and Museology',
  'National Open University',
  'National Open University of Nigeria',
  'National Park College',
  'National Park Community College',
  'National Park School',
  'National Park Technology Center',
  'National Pedagogical University M. Dragomanov',
  'National Pingtung Teachers College',
  'National Pingtung University of Science and Technology',
  'National Pirogov Memorial Medical University',
  'National Radio Astronomy Observatory',
  'National Research University of Electronic Technology',
  'National Ribat University',
  'National Road',
  'National Sanskrit University',
  'National School District Preschool',
  'National School of Political and Administrative Studies Bucharest',
  'National Sports Academy Sofia',
  'National Sun Yat-Sen University',
  'National Taichung Teachers College',
  'National Tainan Teachers College',
  'National Taipei College of Nursing',
  'National Taipei Teachers College',
  'National Taipei University',
  'National Taipei University of Technology',
  'National Taitung Teachers College',
  'National Taiwan College of Physical Education and Sports',
  'National Taiwan College of the Arts',
  'National Taiwan Normal University',
  'National Taiwan Ocean University',
  'National Taiwan University',
  'National Taiwan University of Science and Technology',
  'National Taras Shevchenko University of Kiev',
  'National Teachers Elem Academy',
  'National Technical University (Kharkiv Polytechnical Institute)',
  'National Technical University (Kiev Politechnical Institute)',
  'National Technical University of Athens',
  'National Technological University',
  'National Trail Elementary School',
  'National Trail High School',
  'National Trail Middle School',
  'National Transport University',
  'National Tsinghua University',
  'National United University',
  'National University',
  'National University Academy',
  'National University Academy Sparrow',
  'National University of Arts',
  'National University of Defense Technology',
  'National University of Food Technologies',
  'National University of Internal Affairs',
  'National University of Ireland',
  'National University of Ireland Galway',
  'National University of Ireland Maynooth',
  'National University of Kaohsiung',
  'National University of Kiev-Mohyla Academy',
  'National University of Laos',
  'National University of Lesotho',
  'National University of Management',
  'National University of Modern Languages',
  'National University of Music',
  'National University of Ostroh Academy',
  'National University of Rwanda',
  'National University of Samoa',
  'National University of Science and Technology',
  'National University of Science and Technology Bulawayo',
  'National University of Shipbuilding',
  'National University of Singapore',
  'National University of Theater and Film Arts',
  'National University of Uzbekistan',
  'National Yang Ming Medical College',
  'National Yunlin University of Science and Technology',
  'National-Louis University',
  'Nations Ford Elementary',
  'Native - Chinle High School',
  'Native - Ganado High School',
  'Native - Monument Valley High School',
  'Native - Pinon High School',
  'Native - Red Mesa High School',
  'Native - Red Valley/cove High School',
  'Native - Sanders Valley High School',
  'Native - Tuba City High School',
  'Native - Window Rock High School',
  'Native American Comm Academy',
  'Native American Magnet',
  'Native Central Campus',
  'Natividad Elementary',
  'Natoma Elem',
  'Natoma High (7-12)',
  'Natoma Station Elementary',
  'Natomas Charter',
  'Natomas Gateways Middle',
  'Natomas High',
  'Natomas Middle',
  'Natomas Pacific Pathways Prep',
  'Natomas Pacific Pathways Prep Elementary',
  'Natomas Pacific Pathways Prep Middle',
  'Natomas Park Elementary',
  'Natrona County High School',
  'Natural Bridge Elementary',
  'Natural Bridge Elementary School',
  'Natural High (continuation)',
  'Natural Science Academy',
  'Nature Coast Middle School',
  'Nature Coast Technical High',
  'Nature Hill Intermediate',
  'Nature Ridge Elem School',
  'Naturita Elementary School',
  'Naubuc School',
  'Naugatuck High School',
  'Naugatuck Valley Community College',
  'Naughton Elementary School',
  'Naukati School',
  'Nauset Regional High',
  'Nauset Regional Middle',
  'Nautilus Elementary School',
  'Nautilus K-8 School',
  'Nautilus Middle School',
  'Nauvoo Elem School',
  'Nava College Preparatory Academy',
  'Navajo County Instruction For Success (ncis)',
  'Navajo Elementary',
  'Navajo Elementary School',
  'Navajo Es',
  'Navajo Heights Elem School',
  'Navajo Hs',
  'Navajo Jhs',
  'Navajo Middle School',
  'Navajo Mountain High',
  'Navajo Pine High',
  'Navajo Preparatory School',
  'Naval Avenue Elementary School',
  'Naval Postgraduate School',
  'Navarre Elementary School',
  'Navarre High School',
  'Navarre Intermediate Center',
  'Navarrete Elementary',
  'Navarro College',
  'Navarro County Aep/abc',
  'Navarro County Daep/abc',
  'Navarro El',
  'Navarro H S',
  'Navarro Int',
  'Navarro J H',
  'Navarro Middle',
  'Navasota H S',
  'Navasota J H',
  'Navelencia Middle',
  'Navesink Elementary School',
  'Navigator Academy Of Leadership Hillsborough',
  'Navigator Elementary',
  'Navigator Pointe Academy',
  'Navigator Upper Elementary School',
  'Navin Elementary School',
  'Navit - Alchesay High School',
  'Navit - Blue Ridge High School',
  'Navit - Holbrook High School',
  'Navit - Joseph City High School',
  'Navit - Mogollon High School (heber-overgaard)',
  'Navit - Navit Courses',
  'Navit - Payson High School',
  'Navit - Round Valley High School',
  'Navit - Show Low High School',
  'Navit - Snowflake High School',
  'Navit - St. Johns High School',
  'Navit - Winslow High School',
  'Navo Middle',
  'Navy Elementary',
  'Navy Point Elementary School',
  'Nawroz University',
  'Nay-ah-shing School',
  'Nayanova University',
  'Nayatt School',
  'Nayaug Elementary School',
  'Naylor Elem.',
  'Naylor High',
  'Naylor/ccsu Leadership Academy',
  'Naytahwaush Community School',
  'Nazareth Area Hs',
  'Nazareth Area Intermediate Sch',
  'Nazareth Area Ms',
  'Nazareth College',
  'Nazareth School',
  'Nazlini Community School',
  'Nc Connections Academy',
  'Nc Leadership Charter Academy',
  'Nc School For The Deaf',
  'Nc Virtual Academy',
  'Nci Charter School Without Walls',
  'Ncvps',
  'Ndejje University',
  'Ne Center For Educ Of Blind',
  'Ne Correctional Youth Facility',
  'Ne Metro North',
  'Ne Metro Tartan',
  'Ne Mo Specl. Ed. Coop',
  'Nea Community Learning Center',
  'Neabsco Elementary',
  'Neah Bay Elementary School',
  'Neah Bay Junior/ Senior High School',
  'Neah-kah-nie High School',
  'Neah-kah-nie Middle School',
  'Neal Dow Elementary',
  'Neal El',
  'Neal Math Science Academy',
  'Neal Middle',
  'Neale Elementary School',
  'Near East School of Theology',
  'Near East University',
  'Near West Intergenerational School',
  'Neason Hill El Sch',
  'Neavesdavis Detention Center For Children',
  'Nebbie Williams El',
  'Nebinger George W Sch',
  'Nebo Advanced Learning Center',
  'Nebo Elementary',
  'Nebo Elementary School',
  'Nebo View School',
  'Nebraska City High School',
  'Nebraska City Middle School',
  'Nebraska City Northside Elem',
  'Nebraska College of Technical Agriculture',
  'Nebraska Indian Community College',
  'Nebraska Youth Academy',
  'Neca Independent Study',
  'Necedah Elementary',
  'Necedah Middle/high',
  'Neches Daep',
  'Neches El',
  'Neches H S',
  'Necmettin Erbakan University',
  'Ned E Williams El',
  'Nederland Elementary School',
  'Nederland H S',
  'Nederland Middle-senior High School',
  'Nedrose Elementary School',
  'Nedrose Elementary School 4-8',
  'Nedrose High School',
  'Needham Broughton High',
  'Needham Elementary School',
  'Needham High',
  'Needles Community Day',
  'Needles High',
  'Needles Middle',
  'Needmore Elementary School',
  'Needville El',
  'Needville H S',
  'Needville J H',
  'Needville Middle',
  'Needwood Middle School',
  'Neelain University',
  'Neelsville Middle',
  'Neely O Brien Elementary School',
  'Neely Traditional Academy',
  "Neely's Bend Elementary",
  "Neely's Bend Middle",
  "Neely's Bend: A Lead Public School",
  'Neelyville Elem.',
  'Neelyville High',
  'Neenah Creek Elementary',
  'Neenah High',
  'Neeskara Elementary',
  'Neeta School',
  'Neff Ecc',
  'Neff El',
  'Neff Middle',
  'Neff Sch',
  'Negaunee High School',
  'Negaunee Middle School',
  'Negreet High School',
  'Negtemiut Elitnaurviat School',
  'Nehalem Elementary School',
  'Nehaunsey Middle School',
  'Neidig El',
  'Neidig El Sch',
  'Neighborhood Charter School Of Harlem',
  'Neighborhood House Charter School',
  'Neighborhood School',
  "Neighbors' New Vistas High School",
  'Neihardt Elementary School',
  'Neijiang Teacher University',
  'Neil A Armstrong Ms',
  'Neil A Pepin',
  'Neil A. Armstrong Elementary',
  'Neil Armstrong #40',
  'Neil Armstrong 5-6 Ms',
  'Neil Armstrong Academy',
  'Neil Armstrong El',
  'Neil Armstrong Elem School',
  'Neil Armstrong Elementary School',
  'Neil Armstrong Middle School',
  'Neil Armstrong Program/hawthorne',
  'Neil Armstrong School',
  'Neil C Twitchell Elementary School',
  'Neil Cummins Elementary',
  'Neil E Reid High School',
  'Neil Elem School',
  'Neil Hafley Elementary',
  'Neil V Christensen School - Closed',
  'Neill Elementary',
  'Neillsville Elementary',
  'Neillsville Middle/high',
  'Neinas Dual Language Learning Academy',
  'Neisd - Pk4sa',
  'Neithercut Elementary School',
  'Nekoosa 4k',
  'Nekoosa Academy',
  'Nekoosa High',
  'Nel/cps Construction Career',
  'Nelda Mundy Elementary',
  'Nelda Sullivan Middle',
  'Nelida Melendez Melendez (elem. Urbana)',
  'Neligh-oakdale High School',
  'Nell Dawson Elementary',
  'Nell Hill Rhea El',
  'Nell Holcomb Elem.',
  'Nelle Shean Elementary',
  'Nellie B Chisholm Middle School',
  'Nellie F. Bennett Elementary School',
  'Nellie Hughes Stokes Elementary School',
  'Nellie K. Parker',
  'Nellie Mae Glass Elementary',
  'Nellie Muir Elementary School',
  'Nellie N. Coffman Middle',
  'Nellie Reed Elementary',
  'Nellie Stone Johnson Elementary',
  'Nellie Thornton School Of Performing Arts',
  'Nelsen Middle School',
  'Nelson Avenue Middle',
  'Nelson County Area Technology Center',
  'Nelson County Early Learning Center',
  'Nelson County High',
  'Nelson County High School',
  'Nelson Early Childhood Campus',
  'Nelson El',
  'Nelson Elem',
  'Nelson Elementary',
  'Nelson Elementary School',
  'Nelson Island Area School',
  'Nelson Mandela High School',
  'Nelson Mandela Metropolitan University',
  'Nelson Middle',
  'Nelson Place',
  'Nelson Prairie School',
  'Nelson R Mandela/dr Hosea Zollicoffer High School',
  'Nelson Ridge School',
  'Nelson S. Dilworth Elementary',
  'Nelson Wilks Herron Elementary',
  'Nelsonville-york Elementary School',
  'Nelsonville-york High School',
  'Nelsonville-york Junior High School',
  'Nemaha Central Elementary And Middle School',
  'Nemaha Central High School',
  'Nemesio R. Canales I',
  'Nemo Vista Elementary School',
  'Nemo Vista High School',
  'Nemo Vista Middle School',
  'Nenahnezad Community School',
  'Nenana City School',
  'Neocity Academy',
  'Neodesha High',
  'Neoga Early Childhood Center',
  'Neoga Elementary School',
  'Neoga Jr/sr High School',
  'Neola School',
  'Neosho County Community College',
  'Neosho Ctr For Interventn/supp',
  'Neosho High',
  'Neosho Jr. High',
  'Neosho Rapids Elementary',
  'Nepal Sanskrit University',
  'Neponset Grade School',
  'Neptune Beach Elementary School',
  'Neptune Elementary School',
  'Neptune High School',
  'Neptune Middle School',
  'Nereida Alicea Cruz',
  'Nerstrand Charter School',
  'Nesaquake Middle School',
  'Nesbit Elementary',
  'Nesbit Elementary School',
  'Nesbitt Discovery Academy',
  'Nescopeck El Sch',
  'Neshaminy Hs',
  'Neshannock Jshs',
  'Neshannock Memorial El Sch',
  'Neshoba Central Elementary School',
  'Neshoba Central High School',
  'Neshoba Central Middle School',
  'Neshobe School',
  'Nesmith El',
  'Nespelem Elementary',
  'Ness City Elem',
  'Ness City High',
  'Ness Elementary',
  'Nessacus Regional Middle School',
  'Nestle Avenue Charter',
  'Nestor Language Academy Charter',
  'Nestucca High School',
  'Nestucca Valley Elementary',
  'Netaji Subhas Institute of Technology',
  'Netcong Elementary School',
  'Nether Providence El Sch',
  'Netherwood Knoll Elementary',
  'Netherwood School',
  'Nets',
  'Nett Lake Elementary',
  'Nettelhorst Elem School',
  'Nettie Baccus El',
  'Nettie Davis Elem School',
  'Nettie E Karcher School',
  'Nettie L. Waite Middle',
  'Nettie Marshall Academy Of Dual Language',
  'Nettle Creek Elem School',
  'Nettleton High School',
  'Nettleton Junior High School',
  'Nettleton Middle School',
  'Nettleton Primary School',
  'Nettleton Upper Elementary School',
  'Network Charter School',
  'Neuqua Valley High School',
  'Neuse Charter School',
  'Neva King Cooper Educational Center',
  'Nevada Avenue Elementary',
  'Nevada City Charter',
  'Nevada City Preschool',
  'Nevada City School Of The Arts',
  'Nevada Connections Academy',
  'Nevada County Special Education',
  'Nevada Elementary School',
  'Nevada High',
  'Nevada High School',
  'Nevada Learning Academy At Ccsd',
  'Nevada Middle',
  'Nevada Middle School',
  'Nevada Regional Tech.-ctr.',
  'Nevada State High School Downtown',
  'Nevada State High School Henderson',
  'Nevada State High School Summerlin',
  'Nevada System of Higher Education',
  'Nevada Union High',
  'Nevada Virtual Academy',
  'Neveln Elementary',
  'Neville High School',
  'Nevils Elementary School',
  'Nevin Avenue Elementary',
  'Nevin Coppock Elementary School',
  'Nevin Platt Middle School',
  'Nevis Area Learning Program',
  'Nevis Elementary',
  'Nevis Secondary',
  'Nevitt Elementary School',
  'Nevitt Forest Elementary',
  'Nevsehir Haci Bektas Veli University',
  'Nevsky Institute of Language and Culture',
  'New 6th H S - Pearson Ranch',
  'New Albany',
  'New Albany Early Learning Center',
  'New Albany Elementary School',
  'New Albany High School',
  'New Albany Intermediate School',
  'New Albany Middle School',
  'New Albany Primary School',
  'New Albany Senior High School',
  'New Albany/s.tippah/union Co. Alt',
  'New Albin Elementary School',
  'New America School',
  'New America School - Las Cruces',
  'New America School - Lowry',
  'New America School - Phoenix',
  'New America School - Thornton',
  'New American Academy At Roberto Clemente State Park',
  'New American Academy Charter School (the)',
  'New Approach Alternative H S',
  'New Athens Elem',
  'New Athens High School',
  'New Athens Jr High',
  'New Auburn Elementary',
  'New Auburn Jr-sr High',
  'New Augusta Public Academy - North',
  'New Augusta Public Academy - South',
  'New Baden Elementary School',
  'New Bedford Academy',
  'New Bedford High',
  'New Beginning Learning Center',
  'New Beginnings',
  'New Beginnings - 89',
  'New Beginnings - Naples',
  'New Beginnings Academy',
  'New Beginnings Alternative High School',
  'New Beginnings Education Center',
  'New Beginnings High School',
  'New Beginnings Immokalee',
  'New Beginnings Inc Family Academy',
  'New Beginnings Learning Academy',
  'New Berlin Elementary School',
  'New Berlin High School',
  'New Berlin Jr High School',
  'New Berlin Middle/high',
  'New Bern High',
  'New Bethel Elementary School',
  'New Bloomfield El Sch',
  'New Bloomfield Elem.',
  'New Bloomfield High',
  'New Boston Central School',
  'New Boston Daep',
  'New Boston Elem School',
  'New Boston H S',
  'New Boston Middle',
  'New Braintree Grade',
  'New Branches Charter Academy',
  'New Braunfels H S',
  'New Braunfels H S Ninth Grade Center',
  'New Braunfels Middle',
  'New Bremen Elementary School',
  'New Bremen High School',
  'New Bridge High School',
  'New Bridge Learning Center',
  'New Bridge Middle',
  'New Bridge School',
  'New Bridges Elementary School',
  'New Brighton Area El Sch',
  'New Brighton Area Hs',
  'New Brighton Area Ms',
  'New Brighton Middle',
  'New Britain High School',
  'New Britain Transition Center',
  'New Britton Elementary School',
  'New Brockton Elementary School',
  'New Brockton High School',
  'New Brunswick High School',
  'New Brunswick Middle School',
  'New Buffalo Elementary School',
  'New Buffalo Middle School',
  'New Buffalo Senior High School',
  'New Bulgarian University',
  'New Campus Center',
  'New Canaan High School',
  'New Caney El',
  'New Caney H S',
  'New Caney Middle',
  'New Carlisle Elementary School',
  'New Castle Elementary',
  'New Castle Elementary School',
  'New Castle High School',
  'New Castle Jhs',
  'New Castle Middle School',
  'New Castle Shs',
  'New Center Elementary',
  'New Central Elem School',
  'New Century Academy',
  'New Century International Elementary',
  'New Century International Middle',
  'New Century Middle',
  'New Century School',
  'New Century Technology High School',
  'New Chance',
  'New City Elementary School',
  'New City School',
  'New City School - East Campus',
  'New College of California',
  'New College of Florida',
  'New Community School',
  'New Concord Elementary School',
  'New Connections',
  'New Connections Day Program',
  'New Conroe J H',
  'New Creek Primary School',
  'New Cumberland Ms',
  'New Dawn Charter High School',
  'New Dawn School',
  'New Day - Day Treatment',
  'New Day Academy',
  'New Day Academy - Shasta',
  'New Day Academy Boarding & Day School',
  'New Day Charter School',
  'New Deal Daep',
  'New Deal El',
  'New Deal H S',
  'New Deal Middle',
  'New Design High School',
  'New Design Middle School',
  'New Designs Charter',
  'New Designs Charter School-watts',
  'New Diana H S',
  'New Diana Middle',
  'New Dimensions',
  'New Dimensions High School',
  'New Direction',
  'New Direction Lrn Ctr',
  'New Directions',
  'New Directions - Geneva',
  'New Directions Academy',
  'New Directions Alternative Education Center',
  'New Directions Alternative H.s. Program',
  'New Directions Center',
  'New Directions Learning Community',
  'New Directions Program',
  'New Directions Secondary School',
  'New Discoveries Montessori Academy',
  'New Discoveries Preschool',
  'New Dominion Academy',
  'New Dominion Alternative Center/prince William Co.',
  'New Dorp High School',
  'New Durham Elementary School',
  'New Eagle El Sch',
  'New Early Childhood Center',
  'New Economic School',
  'New Egypt High School',
  'New Egypt Middle School',
  'New Egypt Primary School',
  'New El',
  'New El #41',
  'New El 132',
  'New El 133',
  'New El 135',
  'New El 150',
  'New El 166',
  'New El At Ryans Crossing',
  'New Elem School',
  'New Ellenton Steam Magnet Middle',
  'New Elm Spring Colony Elementary - 08',
  'New Emerson El Sch',
  'New Emerson School At Columbus',
  'New England Elementary School',
  'New England High School',
  'New England Institute of Technology',
  'New Era Academy',
  'New Era College of Arts Science & Technology',
  'New Era Elementary School',
  'New Era University',
  'New Explorations Into Sciencetech And Math High School',
  'New Explorers High School',
  'New Fairfield High School',
  'New Fairfield Middle School',
  'New Field Elem School',
  'New Foundations Cs',
  'New Franklin El Sch',
  'New Franklin Elementary',
  'New Franklin Middle-high',
  'New Franklin School',
  'New Frontier High School',
  'New Frontiers Charter School',
  'New Futures School',
  'New Garden El Sch',
  'New Georgia Elementary School',
  'New Glarus Elementary',
  'New Glarus High',
  'New Glarus Middle',
  'New Glennwood Elementary',
  'New Groningen School',
  'New H S',
  'New H S #14',
  'New Hampshire College',
  'New Hampshire Estates Elem',
  'New Hampstead High School',
  'New Hampton Community School',
  'New Hampton Elementary School',
  'New Hampton High School',
  'New Hampton Middle School',
  'New Hanover High',
  'New Hanover Township School',
  'New Hanover-upper Frederick El',
  'New Hartford Elementary School',
  'New Hartford Senior High School',
  'New Haven Academy',
  'New Haven Administration',
  'New Haven Correctional Center',
  'New Haven Elem.',
  'New Haven Elementary',
  'New Haven Elementary School',
  'New Haven High',
  'New Haven High School',
  'New Haven Intermediate School',
  'New Haven Middle',
  'New Haven Middle School',
  'New Haven Primary School',
  'New Hebron Attendance Center',
  'New Heights Academy Charter School',
  'New Heights Charter',
  'New Heights Charter School Of Brockton',
  'New Heights Elementary',
  'New Heights Elementary School',
  'New Heights Middle',
  'New Heights Middle School',
  'New Heights School Inc.',
  'New High School',
  'New High School - Herriman',
  'New Highland Academy',
  'New Highland Elementary School',
  'New Hingham Regional Elementary',
  'New Holland Core Knowledge Academy',
  'New Holland Elementary',
  'New Holland-middletown Elem Sch',
  'New Holstein Elementary',
  'New Holstein High',
  'New Holstein Middle',
  'New Home School',
  'New Hope',
  'New Hope Academy',
  'New Hope Academy Charter School',
  'New Hope Alt High',
  'New Hope Elem School',
  'New Hope Elementary',
  'New Hope Elementary School',
  'New Hope H S',
  'New Hope High School',
  'New Hope Middle School',
  'New Hope-solebury Hs',
  'New Hope-solebury Lower El Sch',
  'New Hope-solebury Ms',
  'New Hope-solebury Upper El Sch',
  'New Hopewell Elementary',
  'New Horizon Alternative School',
  'New Horizon H S',
  'New Horizon High',
  'New Horizon High School',
  'New Horizon Magnet School',
  'New Horizon School For The Performing Arts',
  'New Horizons',
  'New Horizons Alternative',
  'New Horizons Butler Farm Campus',
  'New Horizons Charter Academy',
  'New Horizons Community Charter School',
  'New Horizons Elementary School',
  'New Horizons For Learning',
  "New Horizons Governor's School",
  'New Horizons High School',
  'New Horizons Learning Center',
  'New Horizons Woodside Campus',
  'New Hyde Park Memorial High School',
  'New Hyde Park Road School',
  'New Iberia Senior High School',
  'New Is',
  'New J H',
  'New J J A E P',
  'New Jersey City University',
  'New Jersey Institute of Technology',
  'New Jersey Training School',
  'New Jersey Virtual School',
  'New Jerusalem',
  'New Jerusalem Elementary',
  'New Joseph Bonnheim (njb) Community Charter',
  'New Jr #16',
  'New Kent Elementary',
  'New Kent High',
  'New Kent Middle',
  'New Knoxville Elementary School',
  'New Knoxville High School',
  'New Lakeline Area El',
  'New Lane Memorial Elementary School',
  'New Leaf Center',
  'New Lebanon Junior-senior High School',
  'New Lebanon School',
  'New Legacy Charter School',
  'New Lexington Elementary',
  'New Lexington Elementary School',
  'New Lexington High School',
  'New Lexington Middle School',
  'New Liberty Innovation School',
  'New Life Academy Of Excellence',
  'New Life Charter Academy',
  'New Lima Es',
  'New Lima Hs',
  'New Lisbon Correctional Institution',
  'New Lisbon Elementary',
  'New Lisbon Junior High/high',
  'New London Academy Elementary',
  'New London Alternative School',
  'New London Elementary School',
  'New London High',
  'New London High School',
  'New London Jr-sr High School',
  'New London Middle',
  'New London Middle School',
  'New London Visual And Performing Arts Magnet School',
  'New London-spicer Middle',
  'New London-spicer Sr.',
  'New Los Angeles Charter',
  'New Los Angeles Charter Elementary',
  'New Lothrop Elementary School',
  'New Lothrop High School',
  'New Madrid Bend Youth Ctr.',
  'New Madrid Elem.',
  'New Madrid R-i Tech Skills Ctr',
  'New Manchester Elementary School',
  'New Manchester High School',
  'New Mark Middle',
  'New Market Elementary',
  'New Market Elementary School',
  'New Market High School',
  'New Market Middle',
  'New Market School',
  'New Market Skills Center',
  'New Marlborough Central',
  'New Martinsville School',
  'New Mexico Connections Academy',
  'New Mexico Highlands University',
  'New Mexico Institute of Mining & Technology',
  'New Mexico International School',
  'New Mexico Junior College',
  'New Mexico Military Institute',
  'New Mexico State University',
  'New Mexico State University-​Alamogordo',
  'New Mexico State University-​Carlsbad',
  'New Mexico State University-​Dona Ana',
  'New Mexico Virtual Academy',
  'New Miami Elementary School',
  'New Miami High School',
  'New Miami Middle School',
  'New Middle',
  'New Middle #9 At Gulf Way',
  'New Middle At Mcneil',
  'New Middle School',
  'New Middle School Pathway',
  'New Middleton Elementary',
  'New Middletown Elementary School',
  'New Midway/woodsboro Elementary',
  'New Milford High School',
  'New Millenium Academy Charter Sch',
  'New Millennium Business Academy Middle School',
  'New Millennium Secondary',
  'New Mission High School',
  'New Monmouth Elementary School',
  'New Mountain Hill Elementary School',
  'New Neighbor Campus',
  'New Northeast Area El',
  'New Oak Ridge Pk-6 242',
  'New Open World Academy K-12',
  'New Opportunities Charter',
  'New Options High School',
  'New Orleans Center For Creative Arts',
  'New Orleans Charter Science And Mathematics Hs',
  'New Orleans Military & Maritime Academy',
  'New Oxford El Sch',
  'New Oxford Ms',
  'New Oxford Shs',
  'New Palestine Elementary School',
  'New Palestine High School',
  'New Paltz Middle School',
  'New Paltz Senior High School',
  'New Paradigm College Prep',
  'New Paradigm Glazer Academy',
  'New Paradigm Loving Academy',
  'New Paris Elementary School',
  'New Path',
  'New Paths Area Learning Center',
  'New Pathways Center',
  'New Philadelphia High School',
  'New Plymouth Elementary',
  'New Plymouth High School',
  'New Plymouth Middle School',
  'New Prague Credit Recovery',
  'New Prague Middle School',
  'New Prague Senior High',
  'New Prague Summer Credit Recovery',
  'New Prairie High School',
  'New Prairie Middle School',
  'New Pre-k Center',
  'New Prospect Elementary',
  'New Prospect Elementary School',
  'New Providence Elementary',
  'New Providence High School',
  'New Providence Middle',
  'New Providence Middle School',
  'New Renaissance Middle School',
  'New Republic Elementary',
  'New Richmond Early Childhood Special Education',
  'New Richmond Elementary School',
  'New Richmond High',
  'New Richmond High School',
  'New Richmond Hillside Elementary',
  'New Richmond Middle',
  'New Richmond Middle School',
  'New Richmond Paperjack Elementary',
  'New Richmond Starr Elementary',
  'New Riegel Elementary School',
  'New Riegel High School',
  'New River Community College',
  'New River Community and Technical College',
  'New River Elementary',
  'New River Elementary School',
  'New River Middle School',
  'New River Valley Juvenile Detention Home',
  'New Roberto Clemente',
  'New Rochelle High School',
  'New Rockford-sheyenne Elementary School',
  'New Rockford-sheyenne High School',
  'New Roots Charter School',
  'New Salem El Sch',
  'New Salem Elementary',
  'New Salem-almont High School',
  'New Sarpy Elementary School',
  'New School',
  'New School Community Day',
  'New School For Community Service',
  'New School For Leadership And Journalism (the)',
  'New School For The Arts',
  'New School For The Arts Middle School',
  'New School For Youth Empowerment',
  'New School High',
  'New Scotland Elementary School',
  'New Searles School',
  'New Sharon @ Deptford High School',
  'New Simpson Hill Dist 32',
  'New Site High School',
  'New Smyrna Beach High School',
  'New Smyrna Beach Middle School',
  'New Song Academy',
  'New Springs Schools',
  'New Stanley Elem',
  'New Start',
  'New Start - Basic Alternative Center',
  'New Suffolk School',
  'New Summerfield School',
  'New Suncook School',
  'New Sweden Consolidated School',
  'New Tech H S At Coppell',
  'New Tech High - Zion-benton East',
  'New Tech Innovative Institute',
  'New Tech Institute',
  'New Technology High',
  'New Technology High School - 30',
  'New Technology Hs@east Tech',
  'New Technology West',
  'New Temple',
  'New Town Elementary',
  'New Town Elementary School',
  'New Town High',
  'New Town High School',
  'New Traditions Elementary',
  'New Trails Early Learning Ctr.',
  'New Trier Township H S Northfield',
  'New Trier Township H S Winnetka',
  'New Ulm High School',
  'New Ulm Middle School',
  'New Underwood Elementary - 02',
  'New Underwood High School - 01',
  'New Underwood Jr. High - 04',
  'New Union Elementary',
  'New Urban High School',
  'New Utrecht High School',
  'New Valley Continuation High',
  'New Ventures Charter School',
  'New Vienna Elementary School',
  'New View School',
  'New Village Girls Academy',
  'New Vision Academy',
  'New Vision Charter School',
  'New Vision High',
  'New Vision High School',
  'New Vision Learning Academy',
  'New Vision Middle',
  'New Vision Sch.of Math/sci/tec',
  'New Visions Aim Charter High School I',
  'New Visions Aim Charter High School Ii',
  'New Visions Charter High School For Humanities Ii',
  'New Visions Charter High School For The Humanities',
  'New Visions Charter High School For The Humanities Iii',
  'New Visions Charter High School For The Humanities Iv',
  'New Visions Charter Hs-adv Math/scie',
  'New Visions Chtr Hs-adv Ma/sci Ii',
  'New Visions Chtr Hs-adv Ma/sci Iii',
  'New Visions Chtr Hs-adv Ma/sci Iv',
  'New Visions Elementary School',
  'New Vista High School',
  'New Vista Middle',
  'New Vocational H S',
  'New Voices School Of Academic And Creative Arts',
  'New Washington Elementary School',
  'New Washington Middle/high School',
  'New Waverly El',
  'New Waverly H S',
  'New Waverly Int',
  'New Waverly J H',
  'New West Charter',
  'New Windsor School',
  'New World Educational Center',
  'New World High School',
  'New World Preparatory Charter School',
  'New World School Of The Arts',
  'New World University',
  'New York Avenue Elementary School',
  'New York Avenue School',
  'New York Center For Autism Charter School',
  'New York Center For Autism Charter School Bronx',
  'New York City Academy For Discovery',
  'New York City Charter School Of The Arts',
  "New York City Children's Center",
  'New York City Montessori Charter School',
  'New York Elem',
  'New York Elem.',
  'New York French-american Charter School',
  'New York Institute of Technology',
  'New York Instiute of Technology',
  'New York Mills Elementary',
  'New York Mills Elementary School',
  'New York Mills Junior-senior High School',
  'New York Mills Secondary',
  'New York State School For The Blind',
  'New York State School For The Deaf',
  'New York University',
  'New York University Abu Dhabi',
  'Newark Ccsd 66',
  'Newark Charter School',
  'Newark Chsd 18',
  'Newark Comm High School',
  'Newark Digital Academy',
  'Newark Early College High School',
  'Newark Educators Community Charter School',
  'Newark Elem School',
  'Newark Elementary School',
  'Newark High School',
  'Newark Innovation Academy',
  'Newark Junior High',
  'Newark Leadership Academy',
  'Newark Memorial High',
  'Newark Middle School',
  'Newark Prep Charter School',
  'Newark School',
  'Newark Senior High School',
  'Newark Tech',
  'Newark Valley Middle School',
  'Newark Valley Senior High School',
  'Newark Vocational High School',
  'Neway Center',
  'Newaygo County Career Tech Center',
  'Newaygo High School',
  'Newaygo Middle School',
  'Newberg Senior High School',
  'Newbern Elementary School',
  'Newbern Middle School',
  'Newberry College',
  'Newberry Correctional Facility',
  'Newberry County Career Center',
  'Newberry El Sch',
  'Newberry Elem Math & Science Acad',
  'Newberry Elementary',
  'Newberry Elementary School',
  'Newberry High',
  'Newberry High School',
  'Newberry Middle',
  'Newberry Springs Elementary',
  'Newbridge Math & Reading Preparatory Academy',
  'Newbridge Road School',
  'Newbrook Elementary School',
  'Newburg Elem.',
  'Newburg High',
  'Newburg Middle',
  'Newburg-united Elementary School',
  'Newburg-united High School',
  'Newburgh Elementary School',
  'Newburgh Free Academy',
  'Newburgh Preparatory Charter High School',
  'Newbury Elementary',
  'Newbury Elementary School',
  'Newbury High School',
  'Newbury Park High',
  'Newburyport High',
  'Newby Elementary School',
  'Newby Memorial Elementary School',
  'Newcastle Charter',
  'Newcastle Early Childhood Ctr',
  'Newcastle Elementary',
  'Newcastle Elementary 3-5',
  'Newcastle Elementary K-2',
  'Newcastle Elementary School',
  'Newcastle Es',
  'Newcastle High School',
  'Newcastle Hs',
  'Newcastle Middle School',
  'Newcastle Ms',
  'Newcastle School',
  'Newcastle University Medicine Malaysia',
  'Newcomb Academy',
  'Newcomb Central School',
  'Newcomb Elementary',
  'Newcomb High',
  'Newcomb Middle',
  'Newcomer Academy At Lafayette',
  'Newcomer Center',
  'Newcomers',
  'Newcomers Community School',
  'Newcomers High School',
  'Newcomerstown High School',
  'Newcomerstown Middle School',
  'Newdale Colony Elementary - 04',
  'Newday Center',
  'Newell Barney Middle School',
  'Newell E Woolls Int',
  'Newell Elementary',
  'Newell Elementary - 02',
  'Newell Elementary School',
  'Newell High School - 01',
  'Newell Middle School - 04',
  'Newell-fonda Elementary',
  'Newell-fonda High School',
  'Newell-fonda Middle Sch.',
  'Newellton Elementary School',
  'Newesd 101 Open Doors',
  'Newfane Early Childhood Center',
  'Newfane Elementary School',
  'Newfane Middle School',
  'Newfane Senior High School',
  'Newfield Elementary School',
  'Newfield High School',
  'Newfield Middle School',
  'Newfield School',
  'Newfield Senior High School',
  'Newfields Elementary School',
  'Newfolden Elementary',
  'Newfound Memorial Middle School',
  'Newfound Regional High School',
  'Newgulf El',
  'Newhalen School',
  'Newhall Elementary',
  'Newhart Middle',
  'Newhope Elementary',
  'Newington Elementary',
  'Newington Forest Elementary',
  'Newington High School',
  'Newington Public School',
  'Newkirk Es',
  'Newkirk Hs',
  'Newkirk Ms',
  'Newland (william T.) Elementary',
  'Newland Elementary',
  'Newlon Elementary School',
  'Newlonsburg El Sch',
  'Newman El',
  'Newman Elementary',
  'Newman Grove Elementary School',
  'Newman Grove High School',
  'Newman International Academy At Fort Worth',
  'Newman International Academy At Grace',
  'Newman International Academy At Pioneer',
  'Newman International Academy Of Arlington',
  'Newman International Academy Of Mansfield',
  'Newman International At Cedar Hill',
  'Newman Ms',
  'Newman School',
  'Newman-crows Landing Independent Study',
  'Newmark Elementary',
  'Newmarket Elementary School',
  'Newmarket Jr.-sr. High (elem)',
  'Newmarket Jr.-sr. High School',
  'Newnan Crossing Elementary School',
  'Newnan High School',
  'Newpoint Pinellas Academy',
  'Newport Academy',
  'Newport Area Career Tech Ctr',
  'Newport City Elementary School',
  'Newport Coast Elementary',
  'Newport Colony Elementary - 04',
  'Newport El',
  'Newport El Sch',
  'Newport Elem School',
  'Newport Elementary',
  'Newport Elementary School',
  'Newport Grammar School',
  'Newport Harbor High',
  'Newport Heights Elementary',
  'Newport High School',
  'Newport Hs',
  'Newport Intermediate School',
  'Newport International University',
  'Newport Middle',
  'Newport Middle High School (high)',
  'Newport Middle School',
  'Newport Mill Middle',
  'Newport Ms',
  "Newport News & York County Governor's Health Science Academy",
  'Newport News Juvenile Detention Home',
  'Newport Primary School',
  'Newport Regional School',
  'Newport Senior High School',
  'Newport Town School',
  'Newports Institute of Communication & Economics',
  'Newsome High School',
  'Newsome Park Elementary',
  'Newton College And Career Academy',
  'Newton Collins El',
  'Newton Comm High School',
  'Newton County Elementary School',
  'Newton County High School',
  'Newton County Theme School At Ficquett',
  'Newton County Voc Complex',
  'Newton D Baker School',
  'Newton Early Childhood Center',
  'Newton El',
  'Newton Elem School',
  'Newton Elementary School',
  'Newton Falls Elementary School',
  'Newton Falls High School',
  'Newton Falls Junior High School',
  'Newton Falls Middle School',
  'Newton H S',
  'Newton High School',
  'Newton Middle',
  'Newton Middle School',
  'Newton Municipal Career Center',
  'Newton North High',
  'Newton Parrish Elementary School',
  'Newton Rayzor El',
  'Newton School',
  'Newton Senior High School',
  'Newton South High',
  'Newton Sr High',
  'Newton Yost Elementary School',
  'Newton-conover High',
  'Newton-conover Middle',
  'Newton-lee Elementary',
  'Newton-ransom Sch',
  'Newtown El Sch',
  'Newtown Elementary',
  'Newtown High School',
  'Newtown Middle School',
  'Newtown Ms',
  'Newtown Preschool',
  'Newtown-harris Elem.',
  'Newtown-harris High',
  'Newville El Sch',
  'Nex Gen Academy',
  'Next Charter School',
  'Next Door Charter',
  'Next Generation Academy',
  'Next High',
  'Next Step Program (aacbp)',
  'Next Wave Junior High',
  'Nextech High School',
  'Nextech High School Of Lansing',
  'Nextech High School Of Metro Detroit',
  'Nextgeneration Steam Academy',
  'Nexton Elementary',
  'Nexus Academy',
  'Nexus Academy Of Columbus',
  'Nexus Academy Of Indianapolis',
  'Nexus Academy Of Toledo',
  'Nexus Stem Academy Elementary School',
  'Nexus Stem Academy Middle School',
  'Neysa Callison El',
  'Nezperce School',
  'Nf Woods Campus',
  'Nfl Yet College Prep Academy',
  'Ni River Middle',
  'Niagara Charter School',
  'Niagara County Community College',
  'Niagara County Jail',
  'Niagara Elementary',
  'Niagara Elementary School',
  'Niagara Falls High School',
  'Niagara High',
  'Niagara Street School',
  'Niagara University',
  'Niagara-wheatfield Senior High School',
  'Niangua Elem.',
  'Niangua High',
  'Niantic Center School',
  'Niblack Elementary School',
  'Nibley Park School',
  'Nibley School',
  'Nicandro Garcia',
  'Nicasio',
  'Nicely El Sch',
  'Niceville Senior High School',
  'Nicholas A Hermes Elem School',
  'Nicholas A. Ferri Middle',
  'Nicholas A. Inman Intermediate',
  'Nicholas County Career/technical Center',
  'Nicholas County Elementary School',
  'Nicholas County High School',
  'Nicholas Elementary',
  'Nicholas J. Begich Middle School',
  'Nicholas Murray Butler Academy School No. 23',
  'Nicholas Orem Middle',
  'Nicholas Oresko Community School',
  'Nicholas S. Lacorte-peterstown School No. 3',
  'Nicholasville Elementary School',
  'Nicholls Elementary School',
  'Nicholls State University',
  'Nichols Career Ctr.',
  'Nichols El',
  'Nichols Elementary',
  'Nichols Elementary School',
  'Nichols Elementarymiddle School',
  'Nichols Hills Es',
  'Nichols Int',
  'Nichols J H',
  'Nichols Middle School',
  'Nichols Sawmill El',
  'Nichols School',
  'Nichols Upper Es',
  'Nicholslawson Middle School',
  'Nicholson Elem School',
  'Nicholson Elementary School',
  'Nicholson Tech Acad Elem Sch',
  'Nick Kerr Middle',
  'Nickajack Elementary School',
  'Nickelsville Elementary',
  'Nickerson Elem',
  'Nickerson High',
  "Nicklaus Children's Hospital",
  'Nicola Valley Institute of Technology',
  'Nicolas Aguayo Aldea',
  'Nicolas Junior High',
  'Nicolas Sevilla',
  'Nicolas Valley Elementary',
  'Nicolaus Copernicus School',
  'Nicolaus Copernicus University of Torun',
  'Nicolet Area Technical College',
  'Nicolet Elementary',
  'Nicolet High',
  'Nicolet Middle',
  'Nicollet Early Childhood Special Ed',
  'Nicollet Elementary School',
  'Nicollet Middle School',
  'Nicollet Senior High School',
  'Nicoma Park Es',
  'Nicoma Park Intermediate Es',
  'Nicoma Park Ms',
  'Nidorf Barry J.',
  'Nielson Elementary School',
  'Nieman Elem',
  'Niemann Elementary School',
  'Niemes (john H.) Elementary',
  'Niger Delta University',
  'Nigerian Defence Academy',
  'Nigerian Turkish Nile University',
  'Night Sch',
  'Nightengale Elementary School',
  'Nightingale Charter',
  'Nightingale Elem School',
  'Niguel Hills Middle',
  'Nihon Fukushi University',
  'Nihon University',
  'Niigata College of Pharmacy',
  'Niigata Sangyo University',
  'Niigata University',
  'Niigata University of International and Information Studies',
  'Niigata University of Management',
  'Niihau High & Elementary School',
  'Niikuusra Community School',
  'Nijiiro Japanese Immersion Elementary School',
  'Nike Elem.',
  'Nike Elementary',
  'Nikiski Middle/senior High School',
  'Nikiski North Star Elementary',
  'Nikola Tesla Education Opportunity Center',
  'Nikolaevsk School',
  'Nikolaus Homestead Elementary School',
  'Nikolay Middle',
  'Nilai University College',
  'Nile Garden Elementary',
  'Nile University',
  'Nile Valley University',
  'Niles Central',
  'Niles Comm Schools Adult Education',
  'Niles Elementary',
  'Niles Extended School Community',
  'Niles Intermediate School',
  'Niles Middle School',
  'Niles North High School',
  'Niles Primary School',
  'Niles Senior High School',
  'Niles West High School',
  'Nimitz El',
  'Nimitz H S',
  'Nimitz Middle',
  'Nimitz Ninth Grade School',
  'Nina Harris Ese Center',
  'Nina Otero Community School',
  'Nine Mile Falls Elementary',
  'Ninety Six Elementary',
  'Ninety Six High',
  'Ninety Six Primary',
  'Ninety-fifth Street Elementary',
  'Ninety-ninth Street Elementary',
  'Ninety-one School',
  'Ninety-second Street Elementary',
  'Ninety-sixth Street Elementary',
  'Ninety-third Street Elementary',
  'Ningbo University',
  'Ningbo University of Technology',
  'Ningxia Medical College',
  'Ningxia University',
  'Ninilchik School',
  'Ninnekah Es',
  'Ninnekah Ms',
  'Ninos Heroes Elem Academic Ctr',
  'Ninth District Elementary',
  'Ninth Grade Academy',
  'Ninth Grade Center',
  'Ninth Street Elementary',
  'Niobrara County High School',
  'Niobrara Elementary School',
  'Niobrara Secondary School',
  'Niota Elementary',
  'Nipher Middle',
  'Nipissing University',
  'Nipmuc Regional High',
  'Nipomo Elementary',
  'Nipomo High',
  'Nippersink Middle School',
  'Nippon Bunri University',
  'Nippon Dental University',
  'Nippon Institute of Technology',
  'Nippon Medical School',
  'Nippon Sport Science University',
  'Nippon Veterinary and Animalscience University',
  'Nirma University',
  'Nisantasi University',
  'Nisd Bexar County Jja',
  'Nisd Intervention',
  'Nisd Pksa',
  'Nishikyushu University',
  'Nishimoto Elementary',
  'Nishinippon Institute of Technology',
  'Nisho Gakusha University',
  'Nishuane Elementary School',
  'Niskayuna High School',
  'Nisley Elementary School',
  'Nisqually Middle School',
  'Nissitissit Middle School',
  'Nisswa Elementary',
  'Nita Pearson El',
  'Nitrauer Sch',
  'Nitro Elementary School',
  'Nitro High School',
  'Nitsch El',
  'Nitschmann Ms',
  'Nittany Valley Cs',
  'Niu Valley Middle School',
  'Nivadhana University',
  'Niwot Elementary School',
  'Niwot High School',
  'Nixa High',
  'Nixa Junior High',
  'Nixon (patricia) Elementary',
  'Nixon El',
  'Nixon Elem School',
  'Nixon Elementary School',
  'Nixon H S',
  'Nixon Smiley El',
  'Nixon-smiley Alternative Center',
  'Nixon-smiley Cisd Daep',
  'Nixon-smiley Daep',
  'Nixon-smiley H S',
  'Nixon-smiley Middle',
  'Nixon-smiley Pace',
  'Nixyaawii Community School',
  "Nizam's Institute of Medical Sciences",
  'Nizhny Novgorod State Academy of Medicine',
  'Nizhny Novgorod State Architectural - Building University',
  'Nizhny Novgorod State Technical University',
  'Nizhny Novgorod State University',
  'Nizhoni Elementary',
  'Nj Regional Day School - Newark',
  'Njala University',
  'Nkrumah Academy Charter Es',
  'Nkumba University',
  'Nlc Day Treatment',
  'Nm School For The Arts',
  'Nm School For The Blind And Visually Impaired',
  'Nm School For The Deaf',
  "Nm Women's Correction",
  'Nnamdi Azikiwe University',
  'No 4 Joseph Battin Es',
  'No Penobscot Tech-region 3',
  'No. Ut. Acad. For Math Engineering & Science',
  'No. Ut. Acad. Of Math Engineering & Science Ogden',
  'Noah Wallace School',
  'Noah Webster Academy',
  'Noah Webster Schools- Mesa',
  'Noah Webster Schools-pima',
  'Noakhali University of Science and Technology',
  'Nob Hill Early Childhood Center',
  'Nob Hill Elem School',
  'Nob Hill Elementary School',
  'Nobel Elem School',
  'Noble 4-5 Elementary',
  'Noble Academy',
  'Noble Academy-cleveland',
  'Noble Academy-columbus',
  'Noble Avenue Elementary',
  'Noble Crossing Elementary School',
  'Noble Elementary',
  'Noble Elementary School',
  'Noble Elementarymiddle School',
  'Noble High School',
  'Noble Hs',
  'Noble Middle School',
  'Noble Minds',
  'Noble Prentis Elem',
  'Noble St Chtr - Mansueto',
  'Noble St Chtr Rauner College Prep',
  'Noble St Chtr-baker Campus',
  'Noble St Chtr-butler - Crimson',
  'Noble St Chtr-chicago Bulls Prep',
  'Noble St Chtr-comer College Prep',
  'Noble St Chtr-drw Trading Col',
  'Noble St Chtr-golder College Prep',
  'Noble St Chtr-hansberry Prep Slvr',
  'Noble St Chtr-itw Speer Acad',
  'Noble St Chtr-johnson Colg Prep',
  'Noble St Chtr-muchin College Prep',
  'Noble St Chtr-noble Campus',
  'Noble St Chtr-pritzker Col Prep',
  'Noble St Chtr-rowe-clark Ms Acad',
  'Noble St Chtr-the Noble Academy',
  'Noble St Chtr-uic College Prep',
  'Noble Street Charter Schools',
  'Noble/allbritton Elementary School',
  'Nobleboro Central School',
  'Noblesville East Middle School',
  'Noblesville High School',
  'Noblesville West Middle School',
  'Nocatee Elementary School',
  'Nocona El',
  'Nocona H S',
  'Nocona Middle',
  'Nodaway Valley Elementary School',
  'Nodaway Valley High School',
  'Nodaway Valley Middle School',
  'Nodaway-holt Elem.',
  'Nodaway-holt Jr.-sr. High',
  'Noddin Elementary',
  'Nodland Elementary School',
  'Noe Middle',
  'Noel C. Taylor Academy At Oakland',
  'Noel Community Arts School',
  'Noel El',
  'Noel Elem.',
  'Noel Estrada',
  'Noel Grisham Middle',
  'Noel Levasseur Elem School',
  'Noel Primary',
  'Noelani Elementary School',
  'Noemi Dominguez El',
  'Nogales High',
  'Nogales High School',
  'Nohl Canyon Elementary',
  'Nokomis Elementary',
  'Nokomis Jr/sr High Sch',
  'Nokomis Montessori North Campus',
  'Nokomis Montessori South Campus',
  'Nokomis Regional High School',
  'Nokomis School',
  'Nola Kathryn Wilson El',
  'Nolachuckey Elementary',
  'Nolan Creek School',
  'Nolan Elementary',
  'Nolan Elementary School',
  'Nolan Elementarymiddle School',
  'Nolan Middle',
  'Nolan Ryan J H',
  'Nolanville El',
  'Nolensville Elementary',
  'Nolensville High School',
  'Noli School',
  'Nolley Elementary School',
  'Nome Elementary',
  'Nome Youth Facility',
  'Nome-beltz Jr/sr High',
  'Nompeng Academy',
  'Non School Pk',
  'Non-enrolled Pk Exceptional Ed Program',
  'Non-ris Preschool Cms',
  'Nondalton School',
  'Nonnewaug High School',
  'Nonpublic Sp Ed Program',
  'Nonschool Adult Education',
  'Nonschool Exceptional Education',
  'Nooksack Elementary',
  'Nooksack Valley High School',
  'Nooksack Valley Middle School',
  'Nooksack Valley Special Services',
  'Noonan El',
  'Noor International Academy',
  'Nora Davis Magnet School',
  'Nora Elementary School',
  'Nora Sterry Elementary',
  'Noralto Elementary',
  'Norborne Elem.',
  'Norborne High',
  'Norco College',
  'Norco Elementary',
  'Norco Elementary School',
  'Norco High',
  'Norco Intermediate',
  'Norcrest Elementary School',
  'Norcross Elementary School',
  'Norcross High School',
  'Nord Country',
  'Nordakademie Staatlich anerkannte private Fachhochschule mit dualen Studiengängen',
  'Nordale Elementary',
  'Nordheim School',
  'Nordhoff High',
  'Nordic School of Public Health',
  'Nordonia High School',
  'Nordonia Middle School',
  'Nordstrom Elementary',
  'Norfeld Colony Elementary - 07',
  'Norfeldt Learning Center',
  'Norfeldt School',
  'Norfleet Elem.',
  'Norfolk Alternative High',
  'Norfolk Alternative Middle',
  'Norfolk County Agricultural',
  'Norfolk Highlands Primary',
  'Norfolk Jr High School',
  'Norfolk Juvenile Detention Center',
  'Norfolk Middle School',
  'Norfolk Re-ed',
  'Norfolk Senior High School',
  'Norfolk State University',
  'Norfolk Technical Center',
  'Norfork High School',
  'Norge Elementary',
  'Noriega Children Center',
  'Norland Elementary School',
  'Norland Middle School',
  'Norma Adams Clemons Academy',
  'Norma Anderson Preschool',
  'Norma Butler Bossard Elementary School',
  'Norma Coombs Elementary',
  'Norma Dorsey El',
  'Norma Elementary School',
  'Norma I. Torres Colon',
  'Norma J Paschal El',
  'Norma Krueger El',
  'Norma Krueger El/bert Karrer Campus',
  'Normal Community High School',
  'Normal Community West High School',
  'Normal Heights Elementary',
  'Normal Park Museum Magnet School',
  'Norman Binkley Elementary',
  'Norman Borlaug Elementary School',
  'Norman County East Elementary',
  'Norman County East Secondary',
  'Norman County West Elementary',
  'Norman County West Secondary',
  'Norman El',
  'Norman G. Ambrosini Elementary',
  'Norman Hs',
  'Norman J H',
  'Norman J Levy Lakeside School',
  'Norman L. Sullivan Middle',
  'Norman Liddell Elementary',
  'Norman M Thomas El',
  'Norman N. Glick Middle',
  'Norman North Hs',
  'Norman Park Elementary School',
  'Norman R Kelley Intermediate School',
  'Norman R. Brekke Elementary',
  'Norman Rockwell Elem School',
  'Norman S Weir',
  'Norman S. Edelcup/sunny Isles Beach K-8',
  'Norman Smith Elementary',
  'Normandale Community College',
  'Normandale Elementary',
  'Normandale Hills Elementary',
  'Normandie Avenue Elementary',
  'Normandin Middle School',
  'Normandy Crossing El',
  'Normandy Elementary School',
  'Normandy High',
  'Normandy High School',
  'Normandy Kindergarten Center',
  'Normandy Park Education Center',
  'Normandy Park School',
  'Normandy Village Elementary School',
  'Normangee El',
  'Normangee H S',
  'Normangee Middle',
  'Normative Services',
  'Normont Elementary',
  'Norphlet Elementary School',
  'Norphlet Middle School',
  'Norrback Avenue',
  'Norris Academy Virtual School',
  'Norris City Annex',
  'Norris City-omaha Elem School',
  'Norris City-omaha-enfield H S',
  'Norris El',
  'Norris Elementary',
  'Norris Elementary - 08',
  'Norris Elementary School',
  'Norris High',
  'Norris High School',
  'Norris Intermediate School',
  'Norris Middle',
  'Norris Middle School',
  'Norristown Area Hs',
  'Norrisville Elementary',
  'Norseman Elementary',
  'Norte Vista High',
  'Norterra Canyon K-8',
  'North 13th St Tech',
  'North Academy Senior High',
  'North Adams Elementary School',
  'North Adams High School',
  'North Adamsjerome Elem School',
  'North Adamsjerome Middlehigh School',
  'North Aiken Elementary',
  'North Albany Academy',
  'North Albany Elementary School',
  'North Albany Middle School',
  'North Allegheny Hs',
  'North American University',
  'North Andover High',
  'North Andover Middle',
  'North Andrew Elem.',
  'North Andrew High',
  'North Andrew Middle',
  'North Andrews Gardens Elementary School',
  'North Area Adult Education-day Program',
  'North Area Adult Education-night Program',
  'North Area Community',
  'North Area Elementary Transition School',
  'North Ark College Secondary Center',
  'North Arkansas College',
  'North Arlington High School',
  'North Arlington Middle School',
  'North Arvada Middle School',
  'North Asheboro Middle',
  'North Atlanta High School',
  'North Attleboro High',
  'North Attleborough Early Learning Center',
  'North Attleborough Middle',
  'North Augusta Elementary',
  'North Augusta High',
  'North Augusta Middle',
  'North Aurelius Elementary School',
  'North Avondale Montessori Elementary School',
  'North Babylon High School',
  'North Baldwin Center For Technology',
  'North Baltimore High School',
  'North Baltimore Middle School',
  'North Banks Middle School Of Excellence',
  'North Barrington Elem School',
  'North Bay Elementary School',
  'North Bay Haven Career Academy',
  'North Bay Haven Charter Academy Elementary School',
  'North Bay Haven Charter Academy Middle School',
  'North Bayou Rapides Elementary',
  'North Beach Elementary School',
  'North Beach Junior High School',
  'North Beach Senior High School',
  'North Beardsley Elementary',
  'North Belmont Elementary',
  'North Belt El',
  'North Belton Middle',
  'North Bend Central Elementary School',
  'North Bend Central High School',
  'North Bend Central Middle School',
  'North Bend Elementary',
  'North Bend Elementary School',
  'North Bend Middle School',
  'North Bend Senior High School',
  'North Bergen High School',
  'North Berwick Elementary Schools',
  'North Bethesda Middle',
  'North Beverly Elementary',
  'North Boone High School',
  'North Boone Middle School',
  'North Boone Upper Elem Sch',
  'North Border-pembina Elementary School',
  'North Border-pembina High School',
  'North Border-walhalla Elementary School',
  'North Border-walhalla High School',
  'North Boulevard School',
  'North Branch Area Education Center',
  'North Branch Early Childhood',
  'North Branch Elementary School',
  'North Branch High School',
  'North Branch Lab School Alc',
  'North Branch Middle',
  'North Branch Middle School',
  'North Branch Senior High',
  'North Brandywine Ms',
  'North Branford High School',
  'North Branford Intermediate School',
  'North Bridge El',
  'North Broad Street School',
  'North Broadway Elementary',
  'North Bronx School Of Empowerment',
  'North Brook Elementary',
  'North Brookfield Elementary',
  'North Brookfield High',
  'North Brooks Elementary School',
  'North Broward Academy Of Excellence',
  'North Broward Academy Of Excellence Middle School',
  'North Brunswick High',
  'North Brunswick Township High School',
  'North Bullitt High School',
  'North Buncombe Elementary',
  'North Buncombe High',
  'North Buncombe Middle',
  'North Butler Elementary',
  'North Butler Jr/sr High School',
  'North Cache Middle School',
  'North Caddo Elementary-middle School',
  'North Caddo High School',
  'North Callaway High',
  'North Calloway Elementary School',
  'North Campbell Tech',
  'North Campus',
  'North Canaan Elementary School',
  'North Canton Elementary',
  'North Canton Middle School',
  'North Canyon High School',
  'North Cape Elementary',
  'North Carolina A&T State University',
  'North Carolina Central University',
  'North Carolina State University',
  'North Carolina Wesleyan College',
  'North Caroline High School',
  'North Carroll Middle',
  'North Cedar Elementary School',
  'North Cedar Jr/sr High School',
  'North Cedar Lowden Elementary Center',
  'North Cedar Mechanicsville Elem Ctr',
  'North Central Academy',
  'North Central Academy Campus',
  'North Central Area Career & Tech Center',
  'North Central Area Juniorsenior High School',
  'North Central Bible College',
  'North Central Career Ctr.',
  'North Central Elementary School',
  'North Central High',
  'North Central High School',
  'North Central Junior High School',
  'North Central Junior/high School',
  'North Central Kansas Technical College',
  'North Central Learning Center',
  'North Central Michigan College',
  'North Central Middle',
  'North Central Missouri College',
  'North Central Region',
  'North Central Regional',
  'North Central Secure Trmnt Unt',
  'North Central State College',
  'North Central Texas College',
  'North Central Vocational Center',
  'North Charleston Creative Arts Elementary',
  'North Charleston Elementary',
  'North Charleston High',
  'North Chatham Elementary',
  'North Chevy Chase Elementary',
  'North Chicago Community High Sch',
  'North China Electric Power University',
  'North China University of Technology',
  'North City Elementary',
  'North Clarion Co El Sch',
  'North Clarion Co Jshs',
  'North Clay Community High School',
  'North Clay Elem/jr High Sch',
  'North Clay Middle School',
  'North Clayton High School',
  'North Clayton Middle School',
  'North Cleveland Goal Academy',
  'North Cliff School',
  'North Clinton Elementary',
  'North Co. Middle',
  'North Co. Sr. High',
  'North Coastal Consortium Schools',
  'North Cobb High School',
  'North Coffee Elementary',
  'North Coleman Road School',
  'North College Hill Elementary School',
  'North College Hill High School',
  'North College Hill Middle School',
  'North Collins Elementary School',
  'North Collins Junior-senior High School',
  'North Columbia Academy',
  'North Columbia Elementary School',
  'North Columbus Elementary',
  'North Conejos Alternative Program',
  'North Cook Young Adult Academy',
  'North Corbin Elementary School',
  'North Corbin Junior High School',
  'North Cottonwood',
  'North Country Career Center',
  'North Country Charter Academy (h)',
  'North Country Charter Academy (m)',
  'North Country Community College',
  'North Country Elementary',
  'North Country Junior Uhsd #22',
  'North Country Road School',
  'North Country Senior Uhsd #22',
  'North Country Voc. Coop. Ctr.',
  'North County Charter School',
  'North County High',
  'North County K-8 Center',
  'North County Parkside Elem.',
  'North County Primary',
  'North County Regional Occupational Program',
  'North County Trade Tech High',
  'North Cove Elementary',
  'North Coventry El Sch',
  'North Cow Creek Elementary',
  'North Crawford Elementary',
  'North Crawford High',
  'North Creek Elementary School',
  'North Creek High School',
  'North Crowley Elementary School',
  'North Crowley H S',
  'North Cumberland Elementary',
  'North Cumberland Middle',
  'North Dade Middle School',
  'North Dakota State College of Science',
  'North Dakota State University',
  'North Dakota University System',
  'North Dallas H S',
  'North Davidson High',
  'North Davidson Middle',
  'North Davie Middle',
  'North Daviess Elem.',
  'North Daviess Elementary School',
  'North Daviess High',
  'North Daviess Jr-sr High School',
  'North Davis Elementary',
  'North Davis Jr High',
  'North Davis Preparatory Academy',
  'North Dayton School Of Science & Discovery',
  'North Dearborn Elementary School',
  'North Decatur Elementary School',
  'North Decatur Jr-sr High School',
  'North Delta Alternative School',
  'North Desoto High School',
  'North Desoto Lower Elementary School',
  'North Desoto Middle School 6-8',
  'North Desoto Upper Elementary School',
  'North Dickinson El Sch',
  'North Dickinson School',
  'North District Middle',
  'North Division High 0419',
  'North Dodge Elementary School',
  'North Dorchester High School',
  'North Dorchester Middle School',
  'North Douglas Elementary School',
  'North Douglas High School',
  'North Dover Elementary School',
  'North Drive Elementary',
  'North Duplin Elementary',
  'North Duplin Jr Sr High',
  'North East Carolina Prep',
  'North East Education Center',
  'North East Elementary',
  'North East High',
  'North East Hs',
  'North East Intrmd El',
  'North East J J A E P',
  'North East Middle',
  'North East Ms',
  'North East University Bangladesh',
  'North Eastern Hill University',
  'North Edgecombe High',
  'North Education Center Academy',
  'North El',
  'North Elem School',
  'North Elem.',
  'North Elementary',
  'North Elementary School',
  'North End Elementary',
  'North End Esc',
  'North End Middle School',
  'North Eugene High School',
  'North Euless El',
  'North Evanston Elementary',
  'North Fairview',
  'North Falmouth Elementary',
  'North Farmington High School',
  'North Fayette Elementary Fayette Center',
  'North Fayette Elementary School',
  'North Fayette Valley H School',
  'North Fayette Valley Middle School',
  'North Fayette-valley Middle School',
  'North Florida Aviation Academy',
  'North Florida Community College',
  'North Forest H S',
  'North Forest Pines Elementary',
  'North Forestville Elementary',
  'North Fork Elementary',
  'North Fork Elementary School',
  'North Fork Middle',
  'North Fork Montessori @ Crawford',
  'North Fork School Of Integrated Studies',
  'North Forney H S',
  'North Forrest Attendance Center',
  'North Forrest High School',
  'North Forsyth High',
  'North Forsyth High School',
  'North Forsyth Middle School',
  'North Fort Myers Academy For The Arts',
  'North Fort Myers High School',
  'North Franklin Virtual Academy',
  'North Frederick Elementary',
  'North Freedom Elementary',
  'North Fremont Jr/sr High School',
  'North Gardens High School',
  'North Garner Middle',
  'North Gaston High',
  'North Gem Elementary',
  'North Gem High School',
  'North Gem Middle School',
  'North Georgetown Elementary School',
  'North Georgia Technical College',
  'North Glade Elementary School',
  'North Glen Elementary',
  'North Glendale Elem.',
  'North Godwin Elementary School',
  'North Grade Elementary School',
  'North Grafton Elementary',
  'North Graham Elementary',
  'North Grammar El',
  'North Greenbush School',
  'North Greene Elem',
  'North Greene High School',
  'North Greene Jr/sr High Sch',
  'North Greenville Elementary',
  'North Gresham Elementary School',
  'North Grove Elementary School',
  'North Gulfport Middle School',
  'North Gwinnett High School',
  'North Gwinnett Middle School',
  'North Habersham Middle School',
  'North Hagerstown High',
  'North Hall High School',
  'North Hall Middle School',
  'North Hamilton Elementary',
  'North Hampton School',
  'North Hancock Elementary School',
  'North Hanover Township Upper Elementary School',
  'North Hardin High School',
  'North Harford Elementary',
  'North Harford High',
  'North Harford Middle',
  'North Harlem Elementary',
  'North Harlem Elementary School',
  'North Harnett Primary',
  'North Harrison Elem.',
  'North Harrison Elementary School',
  'North Harrison High',
  'North Harrison High School',
  'North Harrison Middle School',
  'North Hart Elementary School',
  'North Hartsville Elementary',
  'North Haven Community School',
  'North Haven High School',
  'North Haven Middle School',
  'North Haven Transition Partnership',
  'North Heights Alter',
  'North Heights El',
  'North Heights Elementary School',
  'North Heights Jr. High School',
  'North Henderson High',
  'North Hennepin Community College',
  'North Hero Elementary School',
  'North Hi Mount El',
  'North Hialeah Elementary School',
  'North High',
  'North High School',
  'North High School Engagement Center',
  'North Highland Elementary School',
  'North Highland Es',
  'North Highland School',
  'North Highlands Elementary School',
  'North Hill Elementary',
  'North Hill Elementary School',
  'North Hills El Sch',
  'North Hills Elementary',
  'North Hills Ms',
  'North Hills Shs',
  'North Hillsborough',
  'North Holland Elementary School',
  'North Hollywood Senior High',
  'North Hopkins El',
  'North Hopkins H S',
  'North Hornell School',
  'North Houston Early College H S',
  'North Hs',
  'North Hudson Elementary',
  'North Hunterdon High School',
  'North Huron School',
  'North Iberville Elementary',
  'North Idaho College',
  'North Idaho Stem Charter Academy',
  'North Intermediate',
  'North Intermediate School',
  'North Iowa Area Community College',
  'North Iowa Elem Buffalo Center',
  'North Iowa High School',
  'North Iowa Middle School',
  'North Iredell High',
  'North Iredell Middle',
  'North Island College',
  'North Jackson Elementary',
  'North Jackson Elementary School',
  'North Jackson High School',
  'North Jacksonville School',
  'North Jefferson Elementary',
  'North Jefferson Middle School',
  'North Johnston High',
  'North Johnston Middle',
  'North Jones Elementary School',
  'North Joshua El',
  'North Judson-san Pierre Elem Sch',
  'North Junior High',
  'North Junior High School',
  'North Kansas City High',
  'North Kazakhstan State University',
  'North Kern Vocational Training Center',
  'North Kingstown Sr. High',
  'North Kirkwood Middle',
  'North Kitsap High School',
  'North Knox Intermediate',
  'North Knox Jr-sr High School',
  'North Knox Primary School',
  'North Kossuth Elementary School',
  'North Lafayette Elementary School',
  'North Lake College',
  'North Lake Elementary',
  'North Lake High School',
  'North Lake Middle School',
  'North Lake School',
  'North Lakeland Elementary',
  'North Lakeland Elementary School Of Choice',
  'North Lakes Academy',
  'North Lakes Academy 56',
  'North Lamar H S',
  'North Landing Elementary',
  'North Larose Elementary School',
  'North Lauderdale Pk-8',
  'North Laurel Elementary School',
  'North Laurel High School',
  'North Laurel Middle School',
  'North Laurinburg Elementary',
  'North Lawn Elem',
  'North Lawndale Charter Hs',
  'North Lawndale Prep Chtr - Christ',
  'North Lawndale Prep Chtr-collins',
  'North Lawrence Career Center',
  'North Layton Jr High',
  'North Lee Elementary School',
  'North Lenoir High',
  'North Levalley Elem School',
  'North Lewis Elementary School',
  'North Liberty',
  'North Liberty Elementary',
  'North Liberty School',
  'North Lincoln High',
  'North Lincoln Middle',
  'North Linden Elementary School',
  'North Linn Elementary',
  'North Little Rock Center Of Excellence',
  'North Little Rock High School',
  'North Live Oak Elementary School',
  'North Livingston Elementary School',
  'North Londonderry Elementary School',
  'North Loop El',
  'North Mac Elementary',
  'North Mac High School',
  'North Mac Intermediate',
  'North Mac Middle School',
  'North Madison Elementary School',
  'North Magoffin Elementary',
  'North Maharashtra University',
  'North Mahaska Elementary School',
  'North Mahaska Jr-sr High School',
  'North Main St Elementary School',
  'North Main Street School',
  'North Marion High School',
  'North Marion Intermediate School',
  'North Marion Middle School',
  'North Marion Primary School',
  'North Marshall Middle School',
  'North Mason Homelink Program',
  'North Mason Senior High School',
  'North Mecklenburg High',
  'North Medford High School',
  'North Mercer Elem.',
  'North Mesa Elementary School',
  'North Mesquite H S',
  'North Metro Academy Of Performing Arts',
  'North Metro Flex Academy',
  'North Miami Beach Senior High',
  'North Miami Elementary School',
  'North Miami Middle School',
  'North Miami Middle/high School',
  'North Miami Senior Adult Education',
  'North Miami Senior High School',
  'North Mianus School',
  'North Middle',
  'North Middle School',
  'North Middle School - 35',
  'North Middle School Health Sciences And Technology',
  'North Middle/high',
  'North Middlesex Regional',
  'North Middletown Elementary School',
  'North Mobile County Middle School',
  'North Montco Tech Career Center',
  'North Monterey County Center For Independent Study',
  'North Monterey County High',
  'North Monterey County Middle',
  'North Montgomery High School',
  'North Moore High',
  'North Mor Elementary School',
  'North Mountain Middle',
  'North Mullins Primary',
  'North Murray High School',
  'North Muskegon Elementary School',
  'North Muskegon High School',
  'North Muskegon Middle School',
  'North Myrtle Beach High',
  'North Myrtle Beach Middle',
  'North Naples Middle School',
  'North Newton Elementary',
  'North Newton Jr-sr High School',
  'North Nicholas High School',
  'North Nimishillen Elementary School',
  'North Nodaway Elem.',
  'North Nodaway Jr.-sr. High',
  'North Oakland Community Charter',
  'North Oaks Middle',
  'North Oakview Elementary School',
  'North Oconee High School',
  'North Ogden Jr High',
  'North Ogden School',
  'North Ohio Elementary School',
  'North Oldham High School',
  'North Oldham Middle School',
  'North Olmsted High School',
  'North Olmsted Middle School',
  'North Olympic Peninsula Skills Center',
  'North Orange County Rop',
  'North Ossetian State University',
  'North Panola Achievement Academy',
  'North Panola High School',
  'North Panola Junior High School',
  'North Panola Voc Comp',
  'North Park Academy Of The Arts',
  'North Park College and Theological Seminary',
  'North Park Continuation High',
  'North Park Elem.',
  'North Park Elementary',
  'North Park Elementary - 08',
  'North Park Elementary School',
  'North Park Es',
  'North Park High School',
  'North Park Junior High School',
  'North Park Montessori Academy',
  'North Park School',
  'North Parkway Middle School',
  'North Paulding High School',
  'North Pembroke Elementary',
  'North Pemiscot Sr. High',
  'North Penn Shs',
  'North Penn-liberty Jshs',
  'North Penn-mansfield Jshs',
  'North Pennfield Elementary',
  'North Pike Career & Technical Cente',
  'North Pike Elementary School',
  'North Pike Middle School',
  'North Pike Senior High School',
  'North Pines Middle School',
  'North Pitt High',
  'North Plainfield High School',
  'North Plainfield Middle School',
  'North Plains Elementary School',
  'North Plains Opportunity Center',
  'North Platte Elem.',
  'North Platte High',
  'North Platte High School',
  'North Platte Intermediate',
  'North Platte Jr. High',
  'North Pocono Hs',
  'North Pocono Intmd Sch',
  'North Pocono Ms',
  'North Point Academy',
  'North Point Education Center',
  'North Point High School',
  'North Point School',
  'North Pointe Center',
  'North Pointe El',
  'North Pointe Elementary',
  'North Pointe Elementary School',
  'North Pointe Preparatory',
  'North Pole Elementary',
  'North Pole High School',
  'North Pole Middle School',
  'North Polk Central Elementary School',
  'North Polk Elementary School',
  'North Polk High School',
  'North Polk Middle School',
  'North Polk West Elementary School',
  'North Pontotoc Elementary School',
  'North Pontotoc High School',
  'North Pontotoc Middle School',
  'North Pontotoc Upper Elementary',
  'North Port High School',
  'North Posey High School',
  'North Posey Junior High School',
  'North Powder Charter School',
  'North Prairie Jr High',
  'North Providence High',
  'North Putnam Middle School',
  'North Putnam Sr High School',
  'North Queens Community High School',
  'North Quincy High',
  'North Ranch Elementary School',
  'North Ranchito Elementary',
  'North Rd Elementary School',
  'North Reading High',
  'North Reading Middle',
  'North Region Blind',
  'North Rich School',
  'North Richland Middle',
  'North Ridge El',
  'North Ridge Elementary',
  'North Ridge Middle',
  'North Ridge Middle School',
  'North Ridge School',
  'North Ridgeville Academic Center',
  'North Ridgeville Education Ctr Elementary School',
  'North Ridgeville High School',
  'North River Elem School',
  'North River Elementary',
  'North River School',
  'North Riverside El',
  'North Road Elementary School',
  'North Rock Creek Public School',
  'North Rockford Middle School',
  'North Rockland High School',
  'North Rose-wolcott Elementary School',
  'North Rose-wolcott High School',
  'North Rose-wolcott Middle School',
  'North Routt Charter School',
  'North Rowan Elementary',
  'North Rowan High',
  'North Rowan Middle',
  'North Royalton High School',
  'North Royalton Middle School',
  'North Saginaw Charter Academy',
  'North Salem El Sch',
  'North Salem Elementary School',
  'North Salem High School',
  'North Salem Middle School/high School',
  'North Salinas High',
  'North Salisbury Elementary',
  'North Sand Mountain School',
  'North Sanpete High',
  'North Sanpete Middle',
  'North Sanpete Special Purpose School',
  'North Sargent Elementary School',
  'North Sargent High School',
  'North Sashabaw Elementary School',
  'North School',
  'North Schuylkill Elem Sch',
  'North Schuylkill Jshs',
  'North Scituate School',
  'North Scott High School',
  'North Scott Junior High School',
  'North Senior High',
  'North Sevier High',
  'North Sevier Middle',
  'North Shelby',
  'North Shelby Elem.',
  'North Shelby High',
  'North Shore Academy',
  'North Shore Academy Elem',
  'North Shore Community College',
  'North Shore Community School',
  'North Shore El',
  'North Shore Elementary',
  'North Shore Elementary School',
  'North Shore Middle',
  'North Shore Middle School',
  'North Shore Plaza Elementary School',
  'North Shore Plaza High School',
  'North Shore Senior High',
  'North Shore Senior High School',
  'North Shoreview Montessori',
  'North Side Community School',
  'North Side El Sch',
  'North Side Elem School',
  'North Side Elementary',
  'North Side Elementary School',
  'North Side H S',
  'North Side High School',
  'North Side Middle School',
  'North Side Primary Center',
  'North Side Primary School',
  'North Side School',
  'North Smitfield Hs',
  'North Smithfield Elementary',
  'North Smithfield Ms',
  'North Smyrna Elementary School',
  'North South Tippah Voc Tech Center',
  'North South University',
  'North Springfield Elementary',
  'North Springs Elementary',
  'North Springs High School',
  'North Stafford High',
  'North Stanly High',
  'North Stanly Middle',
  'North Star 7-8',
  'North Star Academy',
  'North Star Academy Charter School',
  'North Star Central El Sch',
  'North Star Charter School',
  'North Star East Ms',
  'North Star El',
  'North Star Elementary',
  'North Star Elementary School',
  'North Star High School',
  'North Star Hs',
  'North Star Independent Study',
  'North Star Online School',
  'North Star School',
  'North State Independence High',
  'North Stewart Elementary',
  'North Stokes High',
  'North Stonington Elementary School',
  'North Strabane Intrmd Sch',
  'North Stratfield School',
  'North Street',
  'North Street Elementary School',
  'North Street School',
  'North Summit High',
  'North Summit Middle',
  'North Summit Preschool',
  'North Summit School',
  'North Sumner Elementary',
  'North Sumter Junior High School',
  'North Surry High',
  'North Syracuse Junior High School',
  'North Tahoe High',
  'North Tahoe School',
  'North Tama Elementary',
  'North Tama High School',
  'North Tamarind Elementary',
  'North Tampa Epic 3 Center',
  'North Tapps Middle School',
  'North Tazewell Elementary',
  'North Technical',
  'North Technical Education Center',
  'North Terrace Elementary',
  'North Texas Collegiate Academy-east Campus',
  'North Texas Collegiate Academy-north Campus',
  'North Texas Collegiate Academy-south Campus',
  'North Texas State Hospital - Afp - Vernon',
  'North Thurston High School',
  'North Todd Elementary School',
  'North Tonawanda High School',
  'North Tonawanda Middle School',
  'North Topsail Elementary',
  'North Trail Elementary',
  'North Twin Lakes Elementary School',
  'North Union Elementary',
  'North Union Elementary School',
  'North Union High School',
  'North Union Middle School',
  'North University High',
  'North University of Baia Mare',
  'North Valley Academy',
  'North Valley Area Career & Tech Center',
  'North Valley Elementary',
  'North Valley High',
  'North Valley High (continuation)',
  'North Valley High School',
  'North Valley Middle School',
  'North Valley Military Institute College Preparatory Academy',
  'North Valley School For Young Adults',
  'North Valleys High School',
  'North Verdemont Elementary',
  'North Vermilion High School',
  'North Vermillion Elementary School',
  'North Vermillion High School',
  'North Vermillion Middle School',
  'North Vernon Elementary School',
  'North View Elementary School',
  'North View Middle School Ib World',
  'North Vista Elementary',
  'North Wake College And Career Academy',
  'North Wales El Sch',
  'North Walpole School',
  'North Wamac Grade School',
  'North Ward Elementary School',
  'North Ward Prekindergarten',
  'North Warren Central School',
  'North Warren Elementary',
  'North Warren Regional School',
  'North Washington Elementary School',
  'North Wauchula Elementary School',
  'North Wayne Elementary School',
  'North Webster Elementary School',
  'North Webster High School',
  'North Webster Junior High School',
  'North Webster Lower Elementary School',
  'North Webster Upper Elementary School',
  'North West Frontier Province Agricultural University',
  'North Whidbey Middle School',
  'North White Elementary School',
  'North White High School',
  'North White Middle School',
  'North Whiteville Academy',
  'North Whitfield Middle School',
  'North Wilkes High',
  'North Wilkes Middle',
  'North Wilkesboro Elementary',
  'North Windham Asd Program',
  'North Windham School',
  'North Windy Ridge Intermediate',
  'North Winneshiek Elementary School',
  'North Winneshiek Middle School',
  'North Wood Elem.',
  'North Wood High School',
  'North Wood Middle School',
  'North Woods Elementary',
  'North Woods International School',
  'North Woods Secondary',
  'North Woolmarket Elementary And Mid',
  'North Zulch Elementary/secondary',
  'North-West State Technical University',
  'North-grand High School',
  'North-linn Middle School',
  'North-linn Senior High School',
  'North/central Area Alternative Learning Center',
  'NorthWest Arkansas Community College',
  'Northam Elementary',
  'Northampton Area Hs',
  'Northampton Area Ms',
  'Northampton Community College',
  'Northampton County High School',
  'Northampton Early College',
  'Northampton El',
  'Northampton High',
  'Northampton Middle',
  'Northaven Elementary',
  'Northaven Elementary School',
  'Northboro Elementary School',
  'Northbridge Elementary',
  'Northbridge High',
  'Northbridge Middle',
  'Northbrook El',
  'Northbrook H S',
  'Northbrook Junior High School',
  'Northbrook Middle',
  'Northbrook Middle School',
  'Northbrook School',
  'Northcentral Technical College',
  'Northcoast Preparatory And Performing Arts Academy',
  'Northcrest Elementary School',
  'Northcutt Elementary School',
  'Northdale Middle',
  "Northdale Superintendent's Academy",
  'Northeast Academy East',
  'Northeast Academy Elementary School',
  'Northeast Academy North',
  'Northeast Academy Of Aerospace & Advtech',
  'Northeast Agricultural University',
  'Northeast Alabama Community College',
  'Northeast Art And Science Polytechn',
  'Northeast Bradford El Sch',
  'Northeast Bradford Jshs',
  'Northeast Campus',
  'Northeast Campus- Tift County High School',
  'Northeast Claiborne Charter',
  'Northeast College Prep Charter Scho',
  'Northeast College Preparatory High School',
  'Northeast Community',
  'Northeast Community College',
  'Northeast Dubois High School',
  'Northeast East Elementary School',
  'Northeast Elem',
  'Northeast Elem Magnet School',
  'Northeast Elem School',
  'Northeast Elem.',
  'Northeast Elementary',
  'Northeast Elementary At Lyons',
  'Northeast Elementary School',
  'Northeast Es',
  'Northeast Es And Is',
  'Northeast Forest University',
  'Northeast Guilford High',
  'Northeast Guilford Middle',
  'Northeast Hamilton Elementary School',
  'Northeast High',
  'Northeast High School',
  'Northeast Hs',
  'Northeast In Spec Ed Coop',
  'Northeast Intrmd Sch',
  'Northeast Iowa Community College-​Calmar',
  'Northeast Jones High School',
  'Northeast Jr High School',
  'Northeast Lauderdale Elementary',
  'Northeast Lauderdale High School',
  'Northeast Lauderdale Middle School',
  'Northeast Magnet High School',
  'Northeast Metro Regional Vocational',
  'Northeast Middle',
  'Northeast Middle School',
  'Northeast Middle-high School',
  'Northeast Mississippi Community College',
  'Northeast Ms',
  'Northeast Ms Regional Alternative',
  'Northeast Ms/extended Resource',
  'Northeast Ms/goal',
  'Northeast Ne Juvenile Services',
  'Northeast Nodaway Elementary',
  'Northeast Nodaway High',
  'Northeast Normal University',
  'Northeast North Elementary School',
  'Northeast Ohio College Preparatory School',
  'Northeast Range Secondary',
  'Northeast Regional School - Biotech/agri',
  'Northeast School',
  'Northeast Secondary School',
  'Northeast Service Cooperative Oll',
  'Northeast State Community College',
  'Northeast Tacoma',
  'Northeast Texas Community College',
  'Northeast University at Qinhuangdao Campus',
  'Northeast Vernon Co. R-i Elem.',
  'Northeast Vernon Co. R-i High',
  'Northeast Wisconsin Learning Academy',
  'Northeast Wisconsin Montessori Charter School',
  'Northeast Wisconsin Technical College',
  'Northeastern Clinton Middle School',
  'Northeastern Clinton Senior High School',
  'Northeastern Elementary School',
  'Northeastern High',
  'Northeastern High School',
  'Northeastern Junior College',
  'Northeastern Louisiana University',
  'Northeastern Middle School',
  'Northeastern Ms',
  'Northeastern Nevada Virtual Academy',
  'Northeastern Nm Correctional Facility',
  'Northeastern Oklahoma A&M College',
  'Northeastern Randolph Middle',
  'Northeastern Shs',
  'Northeastern State University',
  'Northeastern Technical College',
  'Northeastern University',
  'Northend Elementary School',
  'Northern Adirondack Elementary School',
  'Northern Adirondack Middle/high School',
  'Northern Alberta Institute of Technology',
  'Northern Arizona Distance Learning',
  'Northern Arizona University',
  'Northern Az Academy For Career Dev. - Taylor',
  'Northern Bedford Co El Sch',
  'Northern Bedford Co Ms/shs',
  'Northern Border University',
  'Northern Burlington County Regional High School',
  'Northern Burlington County Regional Middle School',
  'Northern Cambria El Sch',
  'Northern Cambria Hs',
  'Northern Cambria Ms',
  'Northern Career Institute',
  'Northern Caribbean University',
  'Northern Cass Elementary School',
  'Northern Cass High School',
  'Northern Cass Middle School',
  'Northern Cheyenne Tribal School',
  'Northern Correctional Institution',
  'Northern El Sch',
  'Northern Elementary',
  'Northern Elementary School',
  'Northern Essex Community College',
  'Northern Garrett High School',
  'Northern Granville Middle',
  'Northern Guilford Elementary',
  'Northern Guilford High',
  'Northern Guilford Middle',
  'Northern Heights',
  'Northern Heights Elementary Schl',
  'Northern Heights Elementary School',
  'Northern High',
  'Northern High School',
  'Northern Highlands Regional High School',
  'Northern Hills Academy',
  'Northern Hills El',
  'Northern Hills Elementary',
  'Northern Hills Es',
  'Northern Hills Middle School',
  'Northern Hs',
  'Northern Illinois University',
  'Northern Jiaotong University',
  'Northern Kentucky Tech',
  'Northern Kentucky University',
  'Northern Ky Health Technology Center',
  'Northern Ky Tech - Edgewood',
  'Northern Ky Tech - Highland Hts',
  'Northern Ky Technical College',
  'Northern Ky Youth Dev Center',
  'Northern Lakes Regional Academy',
  'Northern Lebanon Ms',
  'Northern Lebanon Shs',
  'Northern Lehigh Ms',
  'Northern Lehigh Shs',
  'Northern Lights Abc K-8 School',
  'Northern Lights Academy',
  'Northern Lights Academy Cooperative',
  'Northern Lights College',
  'Northern Lights Community School',
  'Northern Lights Elementary',
  'Northern Lincoln Elem.',
  'Northern Maine Community College',
  'Northern Michigan University',
  'Northern Middle',
  'Northern Middle School',
  'Northern Ms',
  'Northern Nash High',
  'Northern Neck Gov Stem Academy/agric & Maritime Studies',
  'Northern Neck Reg Special Ed',
  'Northern Neck Regional Alternative Ed',
  'Northern Neck Technical Center',
  'Northern Oklahoma College',
  'Northern Palms Charter High School',
  'Northern Parkway Elementary School',
  'Northern Plains Spec Ed',
  'Northern Potter Childrens Sch',
  'Northern Potter Jshs',
  'Northern Reg. Juvenile Detention Center',
  'Northern Shores Elementary',
  'Northern Sichuan Medical College',
  'Northern Star Online',
  'Northern State Medical University',
  'Northern State University',
  'Northern Summit Academy',
  'Northern Tier Career Center',
  'Northern Trails 56 School',
  'Northern University Bangladesh',
  'Northern Va. Tr. Ctr. E.p',
  'Northern Valley High',
  'Northern Valley Region Iii',
  'Northern Valley Regional High School At Demarest',
  'Northern Valley Regional High School At Old Tappan',
  'Northern Vance High',
  'Northern Virginia Community College',
  'Northern Virginia Juvenile Detention Home',
  'Northern Waters Environmental School',
  'Northern Westmoreland Ctc',
  'Northern Wisconsin Center',
  'Northfield Academy',
  'Northfield Area Learning Center',
  'Northfield Community Elementary School',
  'Northfield Community Middle School',
  'Northfield Community Nursery Sch',
  'Northfield Elementary',
  'Northfield Elementary School',
  'Northfield High School',
  'Northfield Jr-sr High School',
  'Northfield Middle',
  'Northfield Middle/high School',
  'Northfield Senior High',
  'Northgate El',
  'Northgate Elementary School',
  'Northgate High',
  'Northgate High School',
  'Northgate Intermediate',
  'Northgate Middle',
  'Northgate Mshs',
  'Northglade Montessori School',
  'Northglenn High School',
  'Northglenn Middle School',
  'Northington Elementary School',
  'Northlake El',
  'Northlake Elementary School',
  'Northlake Hills Elementary',
  'Northlake Middle School',
  'Northlake Park Community Elementary',
  'Northlake School',
  'Northlake Youth Academy',
  'Northland Career Ctr.',
  'Northland College',
  'Northland Community and Technical College',
  'Northland Educational Center',
  'Northland High School',
  'Northland Learning Center 010',
  'Northland Learning Center 020',
  'Northland Learning Center 040',
  'Northland Learning Center 050',
  'Northland Learning Center 060',
  'Northland Pines Elementary-eagle River',
  "Northland Pines Elementary-land O' Lakes",
  'Northland Pines Elementary-st Germain',
  'Northland Pines High',
  'Northland Pines Middle',
  'Northland Pines Montessori Learning Center (npmlc)',
  'Northland Pioneer College',
  'Northland Preparatory Academy',
  'Northland Preparatory And Fitness Academy',
  'Northland Secondary',
  'Northlawn Jr High School',
  'Northley Ms',
  'Northline El',
  'Northmead Elementary',
  'Northmont Elementary',
  'Northmont High School',
  'Northmont Middle School',
  'Northmont Secondary Academy',
  'Northmoor Elementary School',
  'Northmoor Es',
  'Northmoor Preschool',
  'Northmoor Primary School',
  'Northmor Elementary School',
  'Northmor High School',
  'Northmore Elementary School',
  'Northome Elementary',
  'Northome Secondary',
  'Northpark Elementary',
  'Northpoint Academy',
  'Northpoint Elementary',
  'Northpoint Elementary School',
  'Northpoint Expeditionary Learning Academy',
  'Northpointe Academy',
  'Northpointe Int',
  'Northport Elementary',
  'Northport Elementary School',
  'Northport High School',
  'Northport Homelink Program',
  'Northport K-8 School',
  'Northport Middle School',
  'Northport Public School',
  'Northport Senior High School',
  'Northrich El',
  'Northridge Academy',
  'Northridge Academy High',
  'Northridge Elementary',
  'Northridge Elementary School',
  'Northridge Es',
  'Northridge High',
  'Northridge High School',
  'Northridge Intermediate',
  'Northridge Middle',
  'Northridge Middle School',
  'Northridge Primary',
  'Northridge School',
  'Northrop Elementary',
  'Northrop High School',
  'Northrop Readiness Plus',
  'Northshore Charter School',
  'Northshore Elementary',
  'Northshore Elementary School',
  'Northshore High School',
  'Northshore Middle School',
  'Northshore Networks',
  'Northshore Online School',
  'Northshore Primary Center',
  'Northshore Special Services',
  'Northside Charter High School',
  'Northside Child Development Center',
  'Northside College Preparatory Hs',
  'Northside Early Childhood Center',
  'Northside Early Learning Center',
  'Northside El',
  'Northside El Sch',
  'Northside Elem',
  'Northside Elem School',
  'Northside Elementary',
  'Northside Elementary School',
  'Northside H S',
  'Northside High',
  'Northside High School',
  'Northside Intermediate',
  'Northside Intermediate School',
  'Northside Jr/sr High School',
  'Northside K-8',
  'Northside Learning Center',
  'Northside Middle',
  'Northside Middle School',
  'Northside Pri',
  'Northside Primary School',
  'Northside School',
  'Northstar Community Charter School',
  'Northstar Elementary School',
  'Northstar Middle',
  'Northstar Middle School',
  'Northtowne Elementary School',
  'Northumberland County Ctc',
  'Northumberland Elementary',
  'Northumberland High',
  'Northumberland Middle',
  'Northvail Elementary School',
  'Northvale Public School',
  'Northview',
  'Northview Alternative High School',
  'Northview Elem',
  'Northview Elem School',
  'Northview Elem.',
  'Northview Elementary',
  'Northview Elementary School',
  'Northview High',
  'Northview High School',
  'Northview Intermediate School',
  'Northview Junior Academy',
  'Northview Middle',
  'Northview Middle School',
  'Northview School',
  'Northview Senior Academy',
  'Northview Village (8th Grade)',
  'Northville Elementary School',
  'Northville High School',
  'Northwest',
  'Northwest A&F University',
  'Northwest Academy',
  'Northwest Academy Of Health Sciences',
  'Northwest Alternative High School',
  'Northwest Area Hs',
  'Northwest Area Intermediate Sch',
  'Northwest Area Learning Center',
  'Northwest Area Primary Sch',
  'Northwest Arkansas Classical Academy',
  'Northwest Arkansas Classical Academy High',
  'Northwest Cabarrus High',
  'Northwest Cabarrus Middle',
  'Northwest Campus',
  'Northwest Career & Techincal Academy Elementary School',
  'Northwest Career & Technical Academy',
  'Northwest Career And Technical Academy',
  'Northwest Career And Technical High School',
  'Northwest Childrens Home',
  'Northwest Classen Hs',
  'Northwest College',
  'Northwest College Preparatory High School',
  'Northwest Community High School',
  'Northwest Community Middle School',
  'Northwest Crossing El',
  'Northwest Early College H S (nwechs)',
  'Northwest Early Elementary School',
  'Northwest El',
  'Northwest El Sch',
  'Northwest Elem',
  'Northwest Elem School',
  'Northwest Elem.',
  'Northwest Elementary',
  'Northwest Elementary School',
  'Northwest Es',
  'Northwest Expedition Academy',
  'Northwest Florida Ballet Academie',
  'Northwest Florida State College',
  'Northwest Guilford High',
  'Northwest Guilford Middle',
  'Northwest H S',
  'Northwest Halifax Collegiate And Technic',
  'Northwest Head Start',
  'Northwest Heights Es',
  'Northwest High',
  'Northwest High School',
  'Northwest Iowa Community College',
  'Northwest Junior High School',
  'Northwest Kansas Technical College',
  'Northwest Kidder Middle School',
  'Northwest Laurens Elementary',
  'Northwest Middle',
  'Northwest Middle School',
  'Northwest Mississippi Community College',
  'Northwest Missouri State University',
  'Northwest Ms',
  'Northwest Nazarene College',
  'Northwest Normal University Lanzhou',
  'Northwest Pa Collegiate Academy',
  'Northwest Passage High School',
  "Northwest Polytechnical University Xi'an",
  'Northwest Prep Academy',
  'Northwest Prep Charter',
  'Northwest Primary School',
  'Northwest Rankin High School',
  'Northwest Rankin Middle School',
  'Northwest Reg. Youth Ctr.',
  'Northwest School',
  'Northwest School Of The Arts',
  'Northwest State Community College',
  'Northwest Technical Center',
  'Northwest Technical College',
  'Northwest Technical Sch.',
  "Northwest University Xi'an",
  'Northwest Valley Sch.',
  'Northwest Vista College',
  'Northwest Whitfield County High School',
  'Northwest Wyoming Treatment Center',
  'Northwest-​Shoals Community College',
  'Northwestern College of Iowa',
  'Northwestern College-​Chicago Campus',
  'Northwestern Connecticut Community College',
  'Northwestern Cyber High School - 92',
  'Northwestern El Sch',
  'Northwestern Elem School',
  'Northwestern Elem.',
  'Northwestern Elementary',
  'Northwestern Elementary - 04',
  'Northwestern Elementary School',
  'Northwestern Evening/saturday High',
  'Northwestern High',
  'Northwestern High School',
  'Northwestern High School - 01',
  'Northwestern Jr High School',
  'Northwestern Junior/senior High School',
  'Northwestern Lehigh El Sch',
  'Northwestern Lehigh Hs',
  'Northwestern Lehigh Ms',
  'Northwestern Middle',
  'Northwestern Middle School',
  'Northwestern Middle School - 02',
  'Northwestern Ms',
  'Northwestern Primary Sch',
  'Northwestern Reg Ed Pgm',
  'Northwestern Regional High School',
  'Northwestern Regional Middle School',
  'Northwestern Senior High School',
  'Northwestern Shs',
  'Northwestern State University',
  'Northwestern University',
  'Northwestern University In Qatar',
  'Northwestern University of the Philippines',
  'Northwinds Elementary',
  'Northwood Abc',
  'Northwood Academy Cs',
  'Northwood Appold Community Academy',
  'Northwood El',
  'Northwood Elementary',
  'Northwood Elementary School',
  'Northwood Es',
  'Northwood High',
  'Northwood High School',
  'Northwood High/middle',
  'Northwood Hills El',
  'Northwood Jr High School',
  'Northwood Middle',
  'Northwood Middle School',
  'Northwood Pre-school',
  'Northwood Sch.',
  'Northwood Virtual Charter School',
  'Northwood-kensett Elementary',
  'Northwood-kensett Jr-sr High School',
  'Northwoods Community Elementary',
  'Northwoods Community Secondary School',
  'Northwoods Elementary',
  'Northwoods Fine Arts Academy',
  'Northwoods Middle',
  'Northwoods Park Middle',
  'Norton Commons Elementary',
  'Norton Creek Elementary School',
  'Norton Elementary',
  'Norton Elementary School',
  'Norton High',
  'Norton High School',
  'Norton Jr High',
  'Norton Middle',
  'Norton Middle School',
  'Norton Park Elementary School',
  'Norton Primary Elementary School',
  'Norton School',
  'Norton Science And Language Academy',
  'Norton University',
  'Nortre Dame Seishin University',
  'Norup International School',
  'Norvelt El Sch',
  'Norview Elementary',
  'Norview High',
  'Norview Middle',
  'Norwalk Community College',
  'Norwalk Early Childhood Center',
  'Norwalk High',
  'Norwalk High School',
  'Norwalk Middle School',
  'Norwalk Pathways Academy At Briggs',
  'Norwalk Senior High School',
  'Norwalk-ontario-wilton Elementary',
  'Norway Elementary School',
  'Norway High School',
  "Norway's Information Technology University College",
  'Norwayne Elementary School',
  'Norwayne High School',
  'Norwayne Middle',
  'Norwayne Middle School',
  'Norwegian College of Veterinary Medicine',
  'Norwegian School of Economics and Business Administration',
  'Norwegian School of Management BI',
  'Norwegian State Academy of Music',
  'Norwegian Teacher Academy for Studies in Religion and Education',
  'Norwegian University of Life Sciences',
  'Norwegian University of Science and Technology',
  'Norwegian University of Sport and Physical Education',
  'Norwell High',
  'Norwell High School',
  'Norwell Middle School',
  'Norwich Elementary School',
  'Norwich Free Academy',
  'Norwich High',
  'Norwich High School',
  'Norwich Middle School',
  'Norwich Technical High School',
  'Norwich University',
  'Norwin Ms',
  'Norwin Shs',
  'Norwood Avenue School',
  'Norwood Conversion Community School',
  'Norwood Creek Elementary',
  'Norwood El',
  'Norwood Elem School',
  'Norwood Elem.',
  'Norwood Elementary',
  'Norwood Elementary School',
  'Norwood High',
  'Norwood High School',
  'Norwood Junior High',
  'Norwood Middle School',
  'Norwood Park Elem School',
  'Norwood Park Elementary School',
  'Norwood Primary School',
  'Norwood Public School',
  'Norwood Public Schools',
  'Norwood Sch',
  'Norwood School',
  'Norwood Street Elementary',
  'Norwood View Elementary School',
  'Norwood-norfolk Elementary School',
  'Norwood-norfolk Middle School',
  'Norwood-norfolk School',
  'Nosotros Academy',
  'Notasulga High School',
  'Notch Peak Elementary',
  'Notre Dame University',
  "Notre Dame Women's College",
  'Notre Dame of Marbel University',
  'Nottawa Community School',
  'Nottingham Country El',
  'Nottingham El',
  'Nottingham Elementary',
  'Nottingham Elementary School',
  'Nottingham High School',
  'Nottingham Sch',
  'Nottingham Trent University',
  'Nottingham West Elementary School',
  'Nottoway Elementary',
  'Nottoway High',
  'Nottoway Intermediate',
  'Nottoway Middle',
  'Notus Elementary School',
  'Notus Jr/sr High School',
  'Nova Academy',
  'Nova Academy - Coachella',
  'Nova Academy Cedar Hill',
  'Nova Academy Prichard',
  'Nova Alternative School',
  'Nova Blanche Forman Elementary',
  'Nova Classical Academy Lower School',
  'Nova Classical Academy Upper School',
  'Nova Discipline Academy',
  'Nova Dwight D. Eisenhower Elem',
  'Nova Education Center',
  'Nova High School',
  'Nova Middle School',
  'Nova Opportunity',
  'Nova Scotia Agricultural College',
  'Nova Scotia College of Art and Design',
  'Nova Southeastern University',
  'Nova Tech',
  'Nova-northwest Opportunities Vocational Academy',
  'Novak Academy',
  'Novak Elementary School',
  'Novato Charter',
  'Novato Early Intervention',
  'Novato High',
  'Novena University',
  'Novgorod State University',
  'Novi Adult Education',
  'Novi Adult Transition Center',
  'Novi Early Childhood Education Center',
  'Novi High School',
  'Novi Meadows School',
  'Novi Middle School',
  'Novi Woods Elementary School',
  'Novosibirsk State Academy of Economics and Mangement',
  'Novosibirsk State Agricultural University',
  'Novosibirsk State Medical Academy',
  'Novosibirsk State Music Academy M. Glinka',
  'Novosibirsk State Technical University',
  'Novosibirsk State University',
  'Nowata Es',
  'Nowata Hs',
  'Nowata Ms',
  'Nowlin Elementary School',
  'Noxapater Attendance Center',
  'Noxon 7-8',
  'Noxon High School',
  'Noxon Road Elementary School',
  'Noxon School',
  'Noxubee Co Voc Tech',
  'Noxubee County High School',
  'Noyes Es',
  'Noyo High (continuation)',
  'Nr4kids Charter School',
  'Nrheg Elementary',
  'Nrheg Secondary',
  'Nrm Early College High School',
  'Nsec Transition Program',
  'Nso - Independent Study',
  'Nu Tech High',
  "Nu'uuli Vocational Technical High School",
  'Nuckols Farm Elementary',
  'Nucla High School',
  'Nucla Middle School',
  'Nuclear Institute for Agriculture and Biology (NIAB)',
  'Nueces Canyon El',
  'Nueces Canyon Jh/hs',
  'Nueces Co J J A E P',
  'Nuernberger Education Center',
  'Nuestra Senora De Covadonga',
  'Nuestro Elementary',
  'Nuestro Mundo',
  'Nuestros Valores Charter',
  'Nueva Brigida Alvarez Rodriguez',
  'Nueva Continuation High',
  'Nueva Elem Bo Quebrada Negrito',
  'Nueva Elemental Urbana De Guaynabo',
  'Nueva Escuela Su De Bayamon',
  'Nueva Escuela Urbana De Ciales',
  'Nueva Intermedia',
  'Nueva Juan S Marchand',
  'Nueva Sup Con Departamento Vocacional',
  'Nueva Superior De Loiza (superior Vocacional)',
  'Nueva Vista Continuation High',
  'Nueva Vista Elementary',
  'Nueva Vista Language Academy',
  'Nueva Vista School / Arizona State Hospital',
  'Nuevas Fronteras',
  'Nugaal University',
  'Nuh Naci Yazgan University',
  'Nuiqsut Trapper School',
  'Nukus State Teachers Training Institute',
  'Numa Elementary',
  'Number 1',
  'Number 10',
  'Number 2',
  'Number 4',
  'Number 5',
  'Number 6',
  'Number 8',
  'Number 9',
  'Number Three School',
  'Nunaka Valley Elementary',
  'Nunam Iqua School',
  'Nunamiut School',
  'Nuner Primary Center',
  'Nunez Community College',
  'Nuniwarmiut School',
  'Nursery El',
  'Nursery Road Elementary',
  'Nut Swamp Elementary School',
  'Nute High School',
  'Nute Junior High School',
  'Nutley High School',
  'Nuttall Middle School',
  'Nutter Fort Intermediate School',
  'Nutter Fort Primary School',
  'Nuuanu Elementary School',
  'Nuview Academy',
  'Nuview Academy Annex',
  'Nuview Bridge Early College High',
  'Nuview Elementary',
  'Nw Allprep',
  'Nw Learning Center',
  'Nw Mo Specl. Ed. Coop.',
  'Nw Region Interdistrict Council',
  'Nw Wayne Trainable Center',
  'Nwa Community College Reg Center',
  'Nwabsd Home School',
  'Nyack Middle School',
  'Nyack Senior High School',
  'Nyc Charter Hs - Aeci',
  'Nyc Ischool',
  'Nyc Lab High School For Collaborative Studies',
  'Nyc Lab Ms For Collaborative Studies',
  'Nyc Museum School',
  'Nye County Adult Education',
  'Nye Early Childhood',
  'Nye El',
  'Nye El Sch',
  'Nye Elementary',
  'Nye School',
  'Nyenrode Business University',
  'Nyos - Magnolia Mccullough Campus',
  'Nyos Charter School',
  'Nys Dept Of Corrections Placement',
  'Nyssa Elementary School',
  'Nyssa High School',
  'Nyssa Middle School',
  'Nystrom Elementary',
  'O A Fleming El',
  'O A Peterson',
  'O Brown Elementary',
  'O C Johnson School',
  'O C KIDS PRESCHOOL',
  'O C Taylor Ctr',
  'O D Speer School',
  'O D Wyatt H S',
  'O Fallon High School',
  'O H Herman Middle',
  'O H Somers Elementary School',
  'O H Stowe El',
  'O Henry El',
  'O Henry Middle',
  'O J Neighbours Elementary School',
  'O K Adcock Elementary School',
  'O K Elem',
  'O M Mc Nair Upper Elementary',
  'O M Roberts El',
  'O M Tiffany Elementary - 11',
  'O Neill Middle School',
  'O R Baker Elementary School',
  'O W Holmes',
  'O W Huth Middle School',
  "O'CONNELL COLLEGE PREPARATORY SCHOOL",
  "O'DEA HIGH SCHOOL",
  "O'GORMAN HIGH SCHOOL",
  "O'HARA CATHOLIC ELEMENTARY SCHOOL",
  "O'QUINN SCHOOLS OF PORTER GAUD",
  "O'QUINN SCHOOLS OF PORTER-GAUD - MOUNT PLEASANT",
  "O'banion Middle",
  "O'bannon Elementary School",
  "O'bannon High School",
  "O'brien Middle",
  "O'bryant Int",
  "O'bryant Pri",
  "O'bryant School Math/science",
  "O'connell (john) High",
  "O'connor El",
  "O'connor Elementary School",
  "O'connor H S",
  "O'dea Elementary School",
  "O'dell El",
  "O'donnell Middle",
  "O'donnell Middle School",
  "O'donnell School",
  "O'hara Lanier Middle",
  "O'hara Park Middle",
  "O'keeffe Middle",
  "O'kreek Elementary - 14",
  "O'malley Elementary",
  "O'melveny Elementary",
  "O'neal Elem.",
  "O'neill Elementary School",
  "O'neill High School",
  "O'shea Keleher El",
  'O. B. Whaley Elementary',
  'O. Comeaux High School',
  'O. J. Actis Junior High',
  'O. J. Semmes Elementary School',
  'O. N. Hirsch Elementary',
  'O. P. Earle Elementary',
  'O. S. Hubbard Elementary',
  'O. Trent Bonner Middle',
  'O. W. Erlewine Elementary',
  'O.b. Gates Elementary',
  'O.h. Anderson Elementary',
  'O.w. Dillon Leadership Academy',
  'OAK ARBOR CHRISTIAN SCHOOL',
  'OAK ARBOR CHURCH SCHOOL',
  'OAK BRIDGE',
  'OAK CITY ACADEMY',
  'OAK CREEK RANCH SCHOOL',
  'OAK CREST ACADEMY',
  'OAK CREST PRIVATE SCHOOL',
  'OAK FARM SCHOOL',
  'OAK FOREST ACADEMY',
  'OAK GROVE',
  'OAK GROVE AMISH SCHOOL',
  'OAK GROVE CHRISTIAN SCHOOL',
  'OAK GROVE CLASSICAL ACADEMY',
  'OAK GROVE CLASSICAL CHRISTIAN SCHOOL',
  'OAK GROVE I',
  'OAK GROVE II',
  'OAK GROVE INSTITUTE',
  'OAK GROVE LUTHERAN ELEMENTARY SCHOOL',
  'OAK GROVE LUTHERAN SCHOOL',
  'OAK GROVE MENNONITE SCHOOL',
  'OAK GROVE MONTESSORI ELEMENTARY SCHOOL',
  'OAK GROVE MONTESSORI SCHOOL',
  'OAK GROVE PAROCHIAL SCHOOL',
  'OAK GROVE SCHOOL',
  'OAK HALL EPISCOPAL SCHOOL',
  'OAK HARBOR CHRISTIAN SCHOOL',
  'OAK HILL',
  'OAK HILL ACADEMY',
  'OAK HILL CLASSICAL SCHOOL',
  'OAK HILL MONTESSORI',
  'OAK HILL SCHOOL',
  'OAK LANE MENNONITE SCHOOL',
  'OAK LANE SCHOOL',
  'OAK LAWN MONTESSORI SCHOOL',
  'OAK MEADOW MONTESSORI',
  'OAK MEADOW SCHOOL',
  'OAK MOUNTAIN ACADEMY',
  'OAK PARK CHRISTIAN SCHOOL',
  'OAK PLAINS ACADEMY',
  'OAK RIDGE AMISH SCHOOL',
  'OAK RIDGE MILITARY ACADEMY',
  'OAK RIDGE SCHOOL',
  'OAK RIDGE/TURKEY HOLLOW SCHOOL',
  'OAK SHADE SCHOOL',
  'OAK STREET CHRISTIAN SCHOOL',
  'OAK VALLEY SCHOOL',
  'OAK VIEW',
  'OAKDALE ACADEMY',
  'OAKDALE CHRISTIAN ACADEMY',
  'OAKDALE CHRISTIAN ACADEMY & CHILD CARE',
  "OAKES CHILDREN'S CENTER",
  'OAKGROVE AMISH PAROCHIAL SCHOOL',
  'OAKGROVE SCHOOL',
  'OAKHAVEN MONTESSORI SCHOOL',
  'OAKHILL CHRISTIAN SCHOOL',
  'OAKHURST ADVENTIST CHRISTIAN SCHOOL',
  'OAKLAND BAPTIST SCHOOL',
  'OAKLAND CATHOLIC HIGH SCHOOL',
  'OAKLAND CHILDRENS ACADEMY',
  'OAKLAND CHRISTIAN SCHOOL',
  'OAKLAND HEBREW DAY SCHOOL',
  'OAKLAND MONTESSORI ACADEMY',
  'OAKLAND SCHOOL',
  'OAKLAWN ACADEMY',
  'OAKRIDGE MENNONITE SCHOOL',
  'OAKRIDGE PRIVATE SCHOOL',
  'OAKRIDGE RANCH - MONTESSORI FARM SCHOOL',
  'OAKS CHRISTIAN SCHOOL',
  'OAKS MONTESSORI SCHOOL',
  'OAKSTONE ACADEMY PALM BEACH CORPORATION',
  'OAKVIEW AMISH SCHOOL',
  'OAKVIEW PREP SCHOOL OF SDA',
  'OAKWOOD',
  'OAKWOOD ACADEMY',
  'OAKWOOD ADVENTIST ACADEMY',
  'OAKWOOD BAPTIST DAY SCHOOL',
  'OAKWOOD CHRISTIAN ACADEMY',
  'OAKWOOD CHRISTIAN SCHOOL',
  'OAKWOOD FRIENDS SCHOOL',
  'OAKWOOD PAROCHIAL SCHOOL',
  'OAKWOOD SCHOOL',
  'OAKWOOD SECONDARY SCHOOL',
  'OASIS CHRISTIAN ACADEMY',
  'OASIS CHRISTIAN SCHOOL',
  'OASIS DAY SCHOOL',
  'OASIS OF LOVE CHRISTIAN ACADEMY',
  'OASIS TRILINGUAL COMMUNITY SCHOOL',
  'OATH CHILD DEVELOPMENT CENTER',
  'OBRIDGE ACADEMY',
  'OC MONTESSORI ACADEMY',
  'OCALA CHRISTIAN ACADEMY',
  'OCALA CHRISTIAN LEARNING ACADEMY INC.',
  'OCALA PREPARATORY ACADEMY',
  'OCEAN ACADEMY',
  'OCEAN COUNTY CHRISTIAN ACADEMY',
  'OCEAN STATE MONTESSORI SCHOOL',
  'OCEAN TIDES SCHOOL',
  'OCEAN VIEW CHRISTIAN ACADEMY',
  'OCEAN VIEW SCHOOL',
  'OCEANA CHRISTIAN SCHOOL',
  'OCEANSIDE ADVENTIST ELEMENTARY',
  'OCONEE CHRISTIAN ACADEMY',
  'ODENTON CHRISTIAN SCHOOL',
  'ODESSA CHRISTIAN SCHOOL',
  'ODYSSEY COMMUNITY SCHOOL',
  'ODYSSEY DAY SCHOOL',
  'ODYSSEY HOUSE ACADEMY',
  'ODYSSEY LEARNING CENTER',
  'ODYSSEY SCHOOL',
  'OGLETHORPE SDA SCHOOL',
  'OHANA INSTITUTE',
  'OHEL CHAYZ DBA TIFERES MORDECHEI CENTER FOR INTERG',
  'OHIO VALLEY CHRISTIAN SCHOOL',
  'OHIO VALLEY VOICES',
  'OHR CHADASH ACADEMY',
  'OHR ELIYAHU ACADEMY',
  'OHR HAMEIR SEMINARY - TIFERETH ISRAEL HIGH SCHOOL',
  'OIL CREEK SCHOOL',
  'OJAI VALLEY SCHOOL',
  'OKC MONTESSORI',
  'OKEECHOBEE ADVENTIST CHRISTIAN SCHOOL',
  'OKEECHOBEE CHRISTIAN ACADEMY',
  'OKLAHOMA CHRISTIAN SCHOOL (UPPER ELEMENTARY)',
  'OLD BETHEL CHRISTIAN ACADEMY',
  'OLD COLONY MONTESSORI SCHOOL',
  'OLD DONATION EPISCOPAL DAY SCHOOL',
  'OLD LINE AMISH SCHOOL',
  'OLD MISSION PRE SCHOOL KINDERGARTEN',
  'OLD MISSION SCHOOL',
  'OLD ORCHARD SCHOOL',
  'OLD ORDER MENNONITE GARDEN SPOT',
  'OLD PATHS BAPTIST ACADEMY',
  'OLD PATHS CHRISTIAN ACADEMY',
  'OLD PEACHTREE MONTESSORI SCHOOL',
  'OLD PLANK CHRISTIAN ACADEMY',
  'OLD SCHOOL MONTESSORI',
  'OLD SUWANEE CHRISTIAN SCHOOL',
  'OLD TIME BAPTIST ACADEMY',
  'OLD TOWN MONTESSORI SCHOOL',
  'OLD TRAIL SCHOOL',
  'OLDENBURG ACADEMY',
  'OLDFIELDS SCHOOL',
  'OLDSMAR CHRISTIAN SCHOOL',
  'OLELO CHRISTIAN ACADEMY',
  'OLIVE BRANCH CHRISTIAN ACADEMY',
  'OLIVE KNOLLS CHRISTIAN SCHOOL',
  "OLIVIA'S MONTESSORI PRESCHOOL",
  'OLNEY ADVENTIST PREP',
  'OLNEY CHRISTIAN SCHOOL',
  'OLNEY FRIENDS SCHOOL',
  'OLTC INSTITUTE',
  'OLYMPIA COMMUNITY SCHOOL',
  'OLYMPIA WALDORF SCHOOL',
  'OLYMPIC CHRISTIAN SCHOOL',
  'OLYMPIC VIEW MONTESSORI',
  'OMAHA MEMORIAL SDA SCHOOL',
  'OMAHA STREET SCHOOL',
  'OMAK ADVENTIST CHRISTIAN SCHOOL',
  'OMAR HAIKAL ISLAMIC ACADEMY',
  'OMEGA LEARNING CENTER',
  'OMEGA LEARNING CENTER - EAST COBB',
  'OMEGA LEARNING CENTER ACWORTH',
  'OMNI MONTESSORI SCHOOL',
  'ONCE UPON A TIME / THEATRE STREET SCHOOL',
  'ONE ACCORD CHRISTIAN ACADEMY',
  'ONE SCHOOL OF THE ARTS',
  'ONE TEAM CHRISTIAN ACADEMY',
  'ONE WORLD MONTESSORI SCHOOL INC. LEIGH',
  'ONE WORLD SCHOOL- LLC',
  'ONEIDA BAPTIST INSTITUTE',
  'ONENESS FAMILY SCHOOL',
  'ONEONTA COMMUNITY CHRISTIAN SCHOOL',
  'ONEONTA MONTESSORI SCHOOL',
  'ONTARIO CHRISTIAN SCHOOLS',
  'OOLTEWAH ADVENTIST SCHOOL',
  'OOSTBURG CHRISTIAN SCHOOL',
  'OPELOUSAS CATHOLIC SCHOOL',
  'OPEN ARMS CHRISTIAN CHILD DEVELOPMENT CENTER',
  'OPEN ARMS LUTHERAN CHILD DEVELOPMENT CENTER',
  'OPEN BIBLE ACADEMY',
  'OPEN BIBLE CHRISTIAN ACADEMY',
  'OPEN BIBLE CHRISTIAN SCHOOL',
  'OPEN BIBLE LEARNING CENTER',
  'OPEN DOOR CHRISTIAN ACADEMY',
  'OPEN DOOR CHRISTIAN SCHOOL',
  'OPEN DOOR OUTREACH CHRISTIAN ACADEMY',
  'OPEN FIELDS SCHOOL',
  'OPEN SCHOOL EAST',
  'OPEN SCHOOL NORTH',
  'OPEN WINDOW SCHOOL',
  'OPEN WINGS LEARNING COMMUNITY',
  'OPENDOOR SCHOOL',
  'OPERATION FRESH START',
  'OPERATION REBIRTH CHRISTIAN ACADEMY',
  'OPPORTUNITIES SCHOOL',
  'OPPORTUNITY TRAINING CENTER',
  'OPTIMAL CHRISTIAN ACADEMY',
  'OPTIONS EDUCATIONAL SERVICES',
  'OR HACHAIM ACADEMY',
  'ORA ACADEMY',
  'ORANGE AVENUE BAPTIST SCHOOL',
  'ORANGE CITY CHRISTIAN SCHOOL',
  'ORANGE COUNTY ACADEMY',
  'ORANGE COUNTY CHRISTIAN SCHOOL',
  'ORANGE CRESCENT SCHOOL',
  'ORANGE GROVE ACADEMY',
  'ORANGE GROVE CENTER',
  'ORANGE LUTHERAN HIGH SCHOOL',
  'ORANGEBURG CHRISTIAN SCHOOL',
  'ORANGEVALE SEVENTH-DAY ADVENTIST SCHOOL',
  'ORANGEVILE CHRISTIAN ACADEMY',
  'ORANGEWOOD ACADEMY',
  'ORANGEWOOD CHRISTIAN SCHOOL',
  'ORATORY ATHENAEUM FOR UNIVERSITY PREP',
  'ORATORY PREPARATORY SCHOOL',
  'ORCAS CHRISTIAN SCHOOL',
  'ORCAS MONTESSORI SCHOOL',
  'ORCHARD FRIENDS SCHOOL',
  'ORCHARD GROVE',
  'ORCHARD HILL SCHOOL',
  'ORCHARD HILLS CHRST ACADEMY',
  'ORCHARD HOUSE SCHOOL',
  'ORCHARD KNOB',
  'ORCHARD KNOB SCHOOL (AMISH)',
  'ORCHARD LANE SCHOOL',
  'ORCHARD SCHOOL',
  'ORCHARD SIDE SCHOOL',
  'ORCHARD VALLEY WALDORF SCHOOL',
  'ORCHARDVILLE MENNONITE SCHOOL',
  'ORE BANK SCHOOL',
  'OREGON EPISCOPAL SCHOOL',
  'OREGON OUTREACH',
  "ORFALEA FAMILY & ASI CHILDREN'S CENTER RM # 133",
  'ORIGINS MONTESSORI ACADEMY',
  'ORINDA ACADEMY',
  'ORION MONTESSORI SCHOOL LLC',
  'ORLANDO BAPTIST TEMPLE',
  'ORLANDO CHRISTIAN PREP',
  'ORLANDO DAY NURSERY',
  'ORLANDO JUNIOR ACADEMY',
  'ORLEANS COUNTY CHRISTIAN SCHOOL',
  'OROVILLE CHRISTIAN SCHOOL',
  'ORTONVILLE MONTESSORI',
  'ORWELL PAROCHIAL SCHOOL',
  'OSBORN SCHOOL',
  'OSBY CHRISTIAN ACADEMY',
  'OSCEOLA ADVENTIST CHRISTIAN SCHOOL',
  'OSCEOLA CHRISTIAN PREPARATORY SCHOOL',
  'OSKALOOSA CHRISTIAN SCHOOL',
  'OSSIPEE VALLEY CHRISTIAN SCHOOL',
  'OSWEGO COMMUNITY CHRISTIAN SCHOOL',
  'OTSEGO CHRISTIAN SCHOOL',
  'OTTAWA COUNTY CHRISTIAN ACADEMY',
  'OTTER CREEK CHRISTIAN ACADEMY',
  'OTTER CREEK KINDERGARTEN & PRESCHOOL',
  'OTTUMWA CHRISTIAN SCHOOL',
  'OUACHITA CHRISTIAN SCHOOL',
  "OUR CHILDREN'S CENTER",
  "OUR FATHER'S LUTHERAN SCHOOL",
  'OUR HOPE CHRISTIAN ACADEMY',
  'OUR KIDZ WORLD LEARNING CENTER',
  'OUR LADE OF GRACE CATHOLIC SCHOOL',
  'OUR LADY ACADEMY',
  'OUR LADY HELP OF CHRISTIANS',
  'OUR LADY HELP OF CHRISTIANS SCHOOL',
  'OUR LADY IMMACULATE SCHOOL',
  'OUR LADY OF ANGELS SCHOOL',
  'OUR LADY OF ASSUMPTION SCHOOL',
  'OUR LADY OF BETHLEHEM SCHOOL & CHILDCARE',
  'OUR LADY OF CALVARY SCHOOL',
  'OUR LADY OF CATHOLIC ACADEMY',
  'OUR LADY OF CHARITY PRIVATE SCHOOL',
  'OUR LADY OF CONFIDENCE',
  'OUR LADY OF CONSOLATION SCHOOL',
  'OUR LADY OF CZESTOCHOWA',
  'OUR LADY OF FATIMA CATHOLIC SCHOOL',
  'OUR LADY OF FATIMA ELEMENTARY SCHOOL',
  'OUR LADY OF FATIMA SCHOOL',
  'OUR LADY OF GOOD COUNSEL HIGH SCHOOL',
  'OUR LADY OF GOOD COUNSEL SCHOOL',
  'OUR LADY OF GRACE',
  'OUR LADY OF GRACE ACADEMY',
  'OUR LADY OF GRACE CATHOLIC ACADEMY',
  'OUR LADY OF GRACE CATHOLIC SCHOOL',
  'OUR LADY OF GRACE ELEMENTARY SCHOOL',
  'OUR LADY OF GRACE MONTESSORI SCHOOL',
  'OUR LADY OF GRACE NURSERY SCHOOL & KINDERGARTEN',
  'OUR LADY OF GRACE SCHOOL',
  'OUR LADY OF GREENWOOD SCHOOL',
  'OUR LADY OF GUADALUPE',
  'OUR LADY OF GUADALUPE ACADEMY',
  'OUR LADY OF GUADALUPE CATHOLIC ACADEMY',
  'OUR LADY OF GUADALUPE SCHOOL',
  'OUR LADY OF GUADALUPE SCHOOL - LA',
  'OUR LADY OF HOPE CATHOLIC SCHOOL',
  'OUR LADY OF HOPE ST LUKE SCHOOL',
  'OUR LADY OF HUMILITY SCHOOL',
  'OUR LADY OF HUNGARY SCHOOL',
  'OUR LADY OF LAKE HURON SCHOOL',
  'OUR LADY OF LAS VEGAS SCHOOL',
  'OUR LADY OF LORETO SCHOOL',
  'OUR LADY OF LORETTO ELEMENTARY SCHOOL',
  'OUR LADY OF LORETTO SCHOOL',
  'OUR LADY OF LOURDES ACADEMY',
  'OUR LADY OF LOURDES CATHOLIC ACADEMY',
  'OUR LADY OF LOURDES CATHOLIC ELEMENTARY',
  'OUR LADY OF LOURDES CATHOLIC SCHOOL',
  'OUR LADY OF LOURDES ELEMENTARY',
  'OUR LADY OF LOURDES ELEMENTARY SCHOOL',
  'OUR LADY OF LOURDES INTERPARISH SCHOOL',
  'OUR LADY OF LOURDES PRESCHOOL',
  'OUR LADY OF LOURDES SCHOOL',
  'OUR LADY OF MALIBU SCHOOL',
  'OUR LADY OF MERCY ACADEMY',
  'OUR LADY OF MERCY CATHOLIC HIGH SCHOOL',
  'OUR LADY OF MERCY CATHOLIC SCHOOL',
  'OUR LADY OF MERCY ELEMENTARY SCHOOL',
  'OUR LADY OF MERCY REGIONAL SCHOOL',
  'OUR LADY OF MERCY SCHOOL',
  'OUR LADY OF MERCY SCHOOL FOR YOUNG WOMEN',
  'OUR LADY OF MIRACLES',
  'OUR LADY OF MOUNT CARMEL',
  'OUR LADY OF MOUNT CARMEL ACADEMY',
  'OUR LADY OF MOUNT CARMEL SCHOOL',
  'OUR LADY OF MT CARMEL',
  'OUR LADY OF MT CARMEL SCHOOL',
  'OUR LADY OF MT CARMEL-ST BENEDICTA SCHOOL',
  'OUR LADY OF MT. CARMEL',
  'OUR LADY OF PEACE',
  'OUR LADY OF PEACE CATHOLIC SCHOOL',
  'OUR LADY OF PEACE ELEMENTARY SCHOOL',
  'OUR LADY OF PEACE INTERMEDIATE SCHOOL',
  'OUR LADY OF PEACE SCHOOL',
  'OUR LADY OF PERPETUAL HELP',
  'OUR LADY OF PERPETUAL HELP ACADEMY',
  'OUR LADY OF PERPETUAL HELP CATHOLIC ACADEMY',
  'OUR LADY OF PERPETUAL HELP CATHOLIC ACADEMY OF BRO',
  'OUR LADY OF PERPETUAL HELP CATHOLIC SCHOOL',
  'OUR LADY OF PERPETUAL HELP SCHOOL',
  'OUR LADY OF PERPETUAL HELP/MISSION GRAMMAR SCHOOL',
  'OUR LADY OF POMPEII SCHOOL',
  'OUR LADY OF PROMPT SUCCOR SCHOOL',
  'OUR LADY OF PROVIDENCE JR-SR HIGH SCHOOL',
  'OUR LADY OF PROVIDENCE REGIONAL SCHOOL',
  'OUR LADY OF REFUGE CATHOLIC SCHOOL',
  'OUR LADY OF REFUGE SCHOOL',
  'OUR LADY OF SORROWS',
  'OUR LADY OF SORROWS CATHOLIC ACADEMY',
  'OUR LADY OF SORROWS ELEMENTARY SCHOOL',
  'OUR LADY OF SORROWS SCHOOL',
  'OUR LADY OF SORROWSCATHOLIC SCHOOL',
  'OUR LADY OF TALPA SCHOOL',
  'OUR LADY OF TEPAYAC ELEMENTARY SCHOOL',
  'OUR LADY OF TEPEYAC HIGH SCHOOL',
  'OUR LADY OF THE ANGELS MEMORIAL ELEMENTARY SCHOOL',
  'OUR LADY OF THE ASSUMPTION CATHOLIC SCHOOL',
  'OUR LADY OF THE ASSUMPTION SCHOOL',
  'OUR LADY OF THE BLESSED SACRAMENT SCHOOL',
  'OUR LADY OF THE ELMS ELEMENTARY SCHOOL',
  'OUR LADY OF THE ELMS SCHOOL',
  'OUR LADY OF THE HAMPTONS REGIONAL CATHOLIC SCHOOL',
  'OUR LADY OF THE HILLS REG CATHOLIC HIGH SCHOOL',
  'OUR LADY OF THE HOLY ROSARY SCHOOL',
  'OUR LADY OF THE HOLY ROSARY-ST RICHARD SCHOOL',
  'OUR LADY OF THE HOLY SOULS',
  'OUR LADY OF THE LAKE CARTHOLIC SCHOOL',
  'OUR LADY OF THE LAKE CATHOLIC SCHOOL',
  'OUR LADY OF THE LAKE REGIONAL CATHOLIC SCHOOL',
  'OUR LADY OF THE LAKE ROMAN CATHOLIC SCHOOL',
  'OUR LADY OF THE LAKE SCHOOL',
  'OUR LADY OF THE LAKES',
  'OUR LADY OF THE LAKES CATHOLIC SCHOOL',
  'OUR LADY OF THE MIRACULOUS MEDAL',
  'OUR LADY OF THE MOST BLESSED SACRAMENT SCHOOL',
  'OUR LADY OF THE MOUNTAINS SCHOOL',
  'OUR LADY OF THE PILLAR SCHOOL',
  'OUR LADY OF THE PRAIRIE SCHOOL',
  'OUR LADY OF THE PRESENTATION SCHOOL',
  'OUR LADY OF THE RIDGE SCHOOL',
  'OUR LADY OF THE ROSARY CATHOLIC SCHOOL',
  'OUR LADY OF THE ROSARY SCHOOL',
  'OUR LADY OF THE SACRED HEART HIGH SCHOOL',
  'OUR LADY OF THE SACRED HEART SCHOOL',
  'OUR LADY OF THE SNOWS CATHOLIC ACADEMY',
  'OUR LADY OF THE SNOWS CATHOLIC SCHOOL',
  'OUR LADY OF THE SNOWS SCHOOL',
  'OUR LADY OF THE VALLEY CATHOLIC SCHOOL',
  'OUR LADY OF THE VALLEY SCHOOL',
  'OUR LADY OF THE VISITACION SCHOOL',
  'OUR LADY OF THE WAYSIDE SCHOOL',
  'OUR LADY OF TRUST CATHOLIC ACCADAMY',
  'OUR LADY OF UNITY SCHOOL',
  'OUR LADY OF VICTORIES',
  'OUR LADY OF VICTORY',
  'OUR LADY OF VICTORY CATHOLIC SCHOOL',
  'OUR LADY OF VICTORY ELEMENTARY SCHOOL',
  'OUR LADY OF VICTORY SCHOOL',
  'OUR LADY OF VISITATION CHURCH',
  'OUR LADY OF WISDOM REGIONAL SCHOOL',
  'OUR LADY PERPETUAL HELP SCHOOL',
  'OUR LADY PROMPT SUCCOR SCHOOL',
  'OUR LADY QUEEN OF ANGELS SCHOOL',
  'OUR LADY QUEEN OF APOSTLES REG CATHOLIC SCHOOL',
  'OUR LADY QUEEN OF HEAVEN ELEMENTARY SCHOOL',
  'OUR LADY QUEEN OF HEAVEN SCHOOL',
  'OUR LADY QUEEN OF MARTYRS',
  'OUR LADY QUEEN OF MARTYRS CATHOLIC ACADEMY',
  'OUR LADY QUEEN OF MARTYRS SCHOOL',
  'OUR LADY QUEEN OF PEACE CATHOLIC SCHOOL',
  'OUR LADY QUEEN OF PEACE SCHOOL',
  'OUR LADY SACRED HEART ACADEMY',
  'OUR LADY SCHOOL',
  'OUR LADY STAR OF THE SEA',
  'OUR LADY STAR OF THE SEA ELEMENTARY',
  'OUR LADY STAR OF THE SEA REGIONAL',
  "OUR LADY'S SCHOOL",
  'OUR LADY-PROMPT SUCCOR SCHOOL',
  'OUR LADYS CATHOLIC ACADEMY',
  'OUR LADYS MONTESSORI SCHOOL',
  'OUR LADYS SCHOOL',
  'OUR LDY OF GOOD COUNSEL SCHOOL',
  'OUR MONTESSORI SCHOOL',
  'OUR MOTHER OF CONSOLATION PARISH SCHOOL',
  'OUR MOTHER OF GOOD COUNSEL SCHOOL',
  'OUR MOTHER OF PEACE ELEMENTARY',
  'OUR MOTHER OF SORROWS SCHOOL',
  'OUR PLAYHOUSE PRESCHOOL & KINDERGART',
  'OUR PLAYHOUSE PRESCHOOL & KINDERGARTEN',
  'OUR REDEEMER EVANGELICAL LUTHERAN SCHOOL',
  'OUR REDEEMER LUTHERAN',
  'OUR REDEEMER LUTHERAN NORTH SCHOOL',
  'OUR REDEEMER LUTHERAN SCHOOL',
  'OUR REDEEMER PRESCHOOL',
  'OUR REDEEMERS CHRISTIAN SCHOOL',
  'OUR SAVIOR EVANGELICAL LUTHERAN SCHOOL',
  'OUR SAVIOR LUTHERAN CHURCH & SCHOOL',
  'OUR SAVIOR LUTHERAN PRESCHOOL',
  'OUR SAVIOR LUTHERAN SCHOOL',
  "OUR SAVIOR'S CHRISTIAN ELEMENTARY",
  "OUR SAVIOR'S LUTHERAN DAY SCHOOL",
  "OUR SAVIOR'S LUTHERAN SCHOOL",
  'OUR SAVIORS LUTHERAN SCHOOL',
  'OUR SAVIOUR LUTHERAN SCHOOL',
  'OUR SAVIOUR SCHOOL',
  'OUR SCHOOL',
  'OUR SHEPHERD LUTHERAN SCHOOL',
  'OUR SHEPHERD LUTHERAN SCHOOL & PRESCHOOL',
  "OUR SISTERS' SCHOOL",
  'OUR WORLD MONTESSORI',
  'OUT OF DOOR ACADEMY',
  'OUTREACH CHRISTIAN ACADEMY',
  'OUTREACH CHRISTIAN CENTER ACADEMY',
  'OUTREACH CHRISTIAN EDUCATION',
  'OUTSIDE IN SCHOOL',
  "OVELL'S CHRISITIAN ACADEMY",
  'OVER THE RAINBOW MONTESSORI SCHOOL',
  'OVER THE RAINBOW PRESCHOOL',
  'OVERBROOK PRESCHOOL AND KINDERGARTEN',
  'OVERBROOK SCHOOL',
  'OVERCOMER ACADEMY',
  "OVERCOMERS' ACADEMY",
  'OVERFIELD EARLY CHILDHOOD PROGRAM',
  'OVERLAND CHRISTIAN SCHOOLS',
  'OVIEDO MONTESSORI SCHOOL',
  'OVILLA CHRISTIAN SCHOOL',
  'OWATONNA CHRISTIAN SCHOOL',
  'OWENS CHRISTIAN ACADEMY',
  'OWENSBORO CATHOLIC ELEMENTARY K-3',
  'OWENSBORO CATHOLIC ELEMENTARY SCHOOL 4-6 CAMPUS',
  'OWENSBORO CATHOLIC HIGH SCHOOL',
  'OWENSBORO CATHOLIC MIDDLE SCHOOL',
  'OWENTON AMISH MENNONITE SCHOOL',
  'OWL HOLLOW AMISH SCHOOL',
  'OXBOW SCHOOLHOUSE',
  'OXBRIDGE ACADEMY OF THE PALM BEACHES',
  'OXFORD ACADEMY',
  'OXFORD CHRISTIAN ACADEMY',
  'OXFORD DAY SCHOOL',
  'OXFORD HILLS CHRISTIAN ACADEMY',
  'OXFORD LEARNING SOURCE',
  'OXFORD PARK KANSAS CITY CHRISTIAN CAMPUS',
  'OXFORD SCHOOL',
  'OXFORD UNIVERSITY SCHOOL',
  'OZARK ADVENTIST ACADEMY',
  'OZARK ADVENTIST SCHOOL',
  'OZARK MENNONITE SCHOOL',
  'OZARK VALLEY',
  'OZAUKEE CHRISTIAN SCHOOL',
  'Oa-bcig Middle School',
  'Oabcig Elementary-ida Grove',
  'Oabcig Sr High School',
  'Oaisd Community Based Early Childhood',
  'Oak Avenue Elementary',
  'Oak Avenue Intermediate',
  'Oak Bluffs Elementary',
  'Oak Brook Elem.',
  'Oak Brook Park District',
  'Oak Canyon Jr High',
  'Oak Chan Elementary',
  'Oak Cliff Faith Family Academy',
  'Oak Creek Charter School Of Bonita Springs',
  'Oak Creek East Middle',
  'Oak Creek El',
  'Oak Creek Elementary',
  'Oak Creek Elementary School',
  'Oak Creek High',
  'Oak Creek Intermediate',
  'Oak Creek West Middle',
  'Oak Crest Elementary',
  'Oak Crest Es',
  'Oak Crest Int',
  'Oak Crest Middle',
  'Oak Crest School',
  'Oak Dale El',
  'Oak Elem School',
  'Oak Elementary',
  'Oak Elementary School',
  'Oak Flat El Sch',
  'Oak Forest El',
  'Oak Forest Elementary',
  'Oak Forest Elementary School',
  'Oak Forest High School',
  'Oak Glen Elem School',
  'Oak Glen High',
  'Oak Glen High School',
  'Oak Glen Middle School',
  'Oak Grove',
  'Oak Grove Accelerated School',
  'Oak Grove Central Elementary',
  'Oak Grove El',
  'Oak Grove Elem',
  'Oak Grove Elem School',
  'Oak Grove Elem.',
  'Oak Grove Elementary',
  'Oak Grove Elementary School',
  'Oak Grove Elementary/willowside Middle',
  'Oak Grove High',
  'Oak Grove High School',
  'Oak Grove Lower Elementary',
  'Oak Grove Middle',
  'Oak Grove Middle School',
  'Oak Grove Primary',
  'Oak Grove Primary School',
  'Oak Grove Public School',
  'Oak Grove School',
  'Oak Grove Upper Elementary',
  'Oak Grove West School',
  'Oak Grove/bellemeade Elementary',
  'Oak Hammock K-8 School',
  'Oak Hammock Middle School',
  'Oak Harbor Elementary',
  'Oak Harbor High School',
  'Oak Harbor Intermediate School',
  'Oak Harbor Middle School',
  'Oak Heights Elementary',
  'Oak Heights Elementary School',
  'Oak Hill Academy',
  'Oak Hill Community Elementary',
  'Oak Hill El',
  'Oak Hill Elem.',
  'Oak Hill Elementary',
  'Oak Hill Elementary School',
  'Oak Hill High',
  'Oak Hill High School',
  'Oak Hill Junior High School',
  'Oak Hill Middle',
  'Oak Hill Middle School',
  'Oak Hill Middle/high School',
  'Oak Hill Ms',
  'Oak Hill School',
  'Oak Hills Elementary',
  'Oak Hills Elementary School',
  'Oak Hills High',
  'Oak Hills High School',
  'Oak Hills J H',
  'Oak Hills School',
  'Oak Hills Terrace El',
  'Oak Hollow School',
  'Oak Intermediate Elementary School',
  'Oak Knoll Alternative',
  'Oak Knoll Elementary',
  'Oak Knoll Elementary School',
  'Oak Knoll Middle',
  'Oak Lane Elementary',
  'Oak Lawn Comm High School',
  'Oak Lawn School',
  'Oak Lawn-hometown Middle Sch',
  'Oak Manor Elementary',
  'Oak Manor Sixth Grade Center',
  'Oak Meadow Community Day',
  'Oak Meadow El',
  'Oak Meadow Elementary',
  'Oak Meadows El',
  'Oak Meadows Elementary',
  'Oak Mesa Elementary',
  'Oak Middle',
  'Oak Middle School',
  'Oak Mountain Elementary School',
  'Oak Mountain High School',
  'Oak Mountain Intermediate School',
  'Oak Mountain Middle School',
  'Oak Orchard School',
  'Oak Park',
  'Oak Park & River Forest High Sch',
  'Oak Park Alternative Education Center',
  'Oak Park El Sch',
  'Oak Park Elem School',
  'Oak Park Elementary',
  'Oak Park Elementary School',
  'Oak Park High',
  'Oak Park High School',
  'Oak Park Independent',
  'Oak Park Microsociety Elementary School',
  'Oak Park Middle School',
  'Oak Park Neighborhood Learning',
  'Oak Park Preparatory Academy',
  'Oak Park School',
  'Oak Park Special Emphasis School',
  'Oak Park Virtual Academy',
  'Oak Park-carpenter Elementary',
  'Oak Point El',
  'Oak Point Elementary',
  'Oak Pointe Elementary',
  'Oak Prairie Jr High School',
  'Oak Ridge',
  'Oak Ridge El',
  'Oak Ridge El Sch',
  'Oak Ridge Elem School',
  'Oak Ridge Elem.',
  'Oak Ridge Elementary',
  'Oak Ridge Elementary School',
  'Oak Ridge H S',
  'Oak Ridge Heights School',
  'Oak Ridge High',
  'Oak Ridge High School',
  'Oak Ridge Intermediate',
  'Oak Ridge Leadershipenviron/health',
  'Oak Ridge Rssp',
  'Oak Ridge School',
  'Oak Run Elementary',
  'Oak Springs El',
  'Oak Springs School',
  'Oak Street Elem School',
  'Oak Street Elementary',
  'Oak Street Elementary School K-4',
  'Oak Street School',
  'Oak Terrace Elem School',
  'Oak Trace Elementary School',
  'Oak Tree Community Day',
  'Oak Tree Elementary',
  'Oak Tree Elementary School',
  'Oak Valley Elem School',
  'Oak Valley Elementary',
  'Oak Valley Elementary School',
  'Oak Valley Middle',
  'Oak Valley Middle School',
  'Oak View Elem School',
  'Oak View Elementary',
  'Oak View Elementary School',
  'Oak View High',
  'Oak View High School & Education Center',
  'Oak View Learning Ctr.',
  'Oak View Middle',
  'Oak View Middle School',
  'Oak Woods School',
  'Oak-land Middle School',
  'Oakboro Choice Stem',
  'Oakbrook Elem School',
  'Oakbrook Elementary',
  'Oakbrook Elementary School',
  'Oakbrook Middle',
  'Oakbrooke Elementary School',
  'Oakcliff Elementary School',
  'Oakcrest Elementary School',
  'Oakcrest High School',
  'Oakcrest Int',
  'Oakcrest School',
  'Oakdale',
  'Oakdale Charter',
  'Oakdale Elem',
  'Oakdale Elem School',
  'Oakdale Elementary',
  'Oakdale Elementary School',
  'Oakdale Grade School',
  'Oakdale Heights Elementary',
  'Oakdale Heights Elementary School',
  'Oakdale High',
  'Oakdale High School',
  'Oakdale Junior High',
  'Oakdale Middle',
  'Oakdale Middle School',
  'Oakdale Public School',
  'Oakdale School',
  'Oakdale-bohemia Middle School',
  'Oakendell Community',
  'Oakes Elementary School',
  'Oakes Es',
  'Oakes High School',
  'Oakesdale Elementary School',
  'Oakesdale High School',
  'Oakfield Elementary',
  'Oakfield High',
  'Oakfield Middle',
  'Oakfield-alabama Elementary School',
  'Oakfield-alabama Middle School High School',
  'Oakham Center',
  'Oakhaven Elementary',
  'Oakhaven High',
  'Oakhaven Middle',
  'Oakhill Correctional Institution',
  'Oakhill Elem School',
  'Oakhills Elementary',
  'Oakhurst El',
  'Oakhurst Elementary',
  'Oakhurst Elementary School',
  'Oakhurst Intermediate Academy',
  'Oakhurst Steam Academy',
  'Oakland Academy',
  'Oakland Avenue Charter',
  'Oakland Bay Junior High School',
  'Oakland Beach School',
  'Oakland Charter Academy',
  'Oakland Charter High',
  'Oakland City Elementary School',
  'Oakland Community College',
  'Oakland Craig Elementary',
  'Oakland Craig Junior High',
  'Oakland Craig Senior High',
  'Oakland Early College',
  'Oakland Education Center',
  'Oakland El',
  'Oakland Elementary',
  'Oakland Elementary School',
  'Oakland Heights Elem. School',
  'Oakland Heights Elementary School',
  'Oakland High',
  'Oakland High School',
  'Oakland International Academy Elementary',
  'Oakland International Academy High School',
  'Oakland International Academy K1',
  'Oakland International Academy Middle',
  'Oakland International High',
  'Oakland Meadow School',
  'Oakland Middle School',
  'Oakland Military Institute College Preparatory Academy',
  'Oakland Mills High',
  'Oakland Mills Middle',
  'Oakland Opportunity Academy',
  'Oakland Park Alternative Elementary',
  'Oakland Park Elementary School',
  'Oakland Primary',
  'Oakland School For The Arts',
  'Oakland Schools Technical Campusne',
  'Oakland Schools Technical Campusnw',
  'Oakland Schools Technical Campusse',
  'Oakland Schools Technical Campussw',
  'Oakland Sol (school Of Language) Dual Language Middle',
  'Oakland Technical High',
  'Oakland Terrace Elementary',
  'Oakland Terrace School For The Visual And Performing Arts',
  'Oakland Unity High',
  'Oakland Unity Middle',
  'Oakland University',
  'Oaklandon Elementary School',
  'Oaklands Elementary',
  'Oaklandvale',
  'Oaklane Colony Elementary - 05',
  'Oaklawn Campus Program',
  'Oaklawn El',
  'Oaklawn Elem',
  'Oaklawn Elementary',
  'Oaklawn Elementary School',
  'Oaklawn Junior High School',
  'Oaklawn Language Academy',
  'Oaklawn Magnet School',
  'Oaklea Middle School',
  'Oakleaf High School',
  'Oakleaf Junior High',
  'Oakleaf Village Elementary School',
  'Oakleigh Elementary',
  'Oakley (calvin C.) Elementary',
  'Oakley El',
  'Oakley Elem',
  'Oakley Elementary',
  'Oakley Elementary School',
  'Oakley Jr/sr High School',
  'Oakley Middle School',
  'Oakley Park Elementary School',
  'Oakley Sr High',
  'Oakley W Best Middle School',
  'Oaklyn Public School',
  'Oaklyn Sch',
  'Oakman Elementary School',
  'Oakman High School',
  'Oakman Middle School',
  'Oakmont El',
  'Oakmont Elementary',
  'Oakmont Elementary School',
  'Oakmont High',
  'Oakmont Regional High School',
  'Oakridge Elementary',
  'Oakridge Elementary School',
  'Oakridge Es',
  'Oakridge Fusion',
  'Oakridge Group Home',
  'Oakridge High School',
  'Oakridge Junior High School',
  'Oakridge Lower Elementary School',
  'Oakridge Middle',
  'Oakridge Middle School',
  'Oakridge School',
  'Oakridge Upper Elementary School',
  'Oakrun Middle',
  'Oaks Academy',
  'Oaks Correctional Facility',
  'Oaks El',
  'Oaks El Sch',
  'Oaks Middle',
  'Oaks Road Elementary',
  'Oaks-mission Es',
  'Oaks-mission Hs',
  'Oakshire Elementary',
  'Oakshire Elementary School',
  'Oakside Scholars Charter Academy',
  'Oakside School',
  'Oakstead Elementary School',
  'Oakstone Community School',
  'Oakton Community College',
  'Oakton Elem School',
  'Oakton Elementary',
  'Oakton High',
  'Oaktree Elementary School',
  'Oakvale School',
  'Oakview Community Elementary',
  'Oakview El Sch',
  'Oakview Elementary',
  'Oakview Elementary School',
  'Oakview High (alternative)',
  'Oakview Middle School',
  'Oakview Pri School',
  'Oakview School',
  'Oakville Elem.',
  'Oakville Elementary',
  'Oakville High School',
  'Oakville Middle',
  'Oakville Sr. High',
  'Oakwood Avenue Elementary School',
  'Oakwood El',
  'Oakwood Elem.',
  'Oakwood Elementary',
  'Oakwood Elementary School',
  'Oakwood Grade School',
  'Oakwood H S',
  'Oakwood High School',
  'Oakwood Int',
  'Oakwood Intermediate',
  'Oakwood Junior High School',
  'Oakwood Manor Elem.',
  'Oakwood Middle School',
  'Oakwood Primary Center',
  'Oakwood School',
  'Oakwood Terrace El',
  'Oakwood University',
  'Oakwood-windsor Elementary',
  'Oark Elementary School',
  'Oark High School',
  'Oasis Academy',
  'Oasis Charter Elementary School',
  'Oasis Charter High School',
  'Oasis Charter Middle School',
  'Oasis Charter Public',
  'Oasis Continuation High',
  'Oasis Elementary',
  'Oasis Elementary School',
  'Oasis High (alternative)',
  'Oasis K-12',
  'Oasis Middle School',
  'Oasis Preparatory Academy Charter',
  'Oasis Residential Center',
  'Oates El',
  'Oatville Elem',
  'Obadiah Knight El',
  'Obafemi Awolowo University Ile-Ife',
  'Obama School Of Career And Technical Education',
  'Obama Service Learning Elementary',
  'Oberle (william) Elementary School',
  'Oberlin College',
  'Oberlin Elem',
  'Oberlin Elementary School',
  'Oberlin High School',
  'Oberon Elementary School',
  'Oberon Junior High School',
  'Obihiro University of Agriculture and Veterinary Medicine',
  'Obion County Central High School',
  'Obirin University',
  'Oblock Jhs',
  'Oblong Elem School',
  'Oblong High School',
  'Obninsk State Technical University for Nuclear Power Engineering',
  'Obong University',
  'Obsidian Middle School',
  'Obuch Ms',
  'Ocala Middle',
  'Ocala Springs Elementary School',
  'Ocali Charter Middle School',
  'Occidental College',
  'Occohannock Elementary',
  'Occoquan Elementary',
  'Occs:chep/pchs',
  'Ocean',
  'Ocean Academy',
  'Ocean Academy Charter School',
  'Ocean Acres Elementary School',
  'Ocean Air',
  'Ocean Alternative Education Center',
  'Ocean Avenue',
  'Ocean Avenue Elementary School',
  'Ocean Avenue School',
  'Ocean Bay Elementary',
  'Ocean Bay Middle',
  'Ocean Beach Alternative School',
  'Ocean Beach Early Childhood Center',
  'Ocean Beach Elementary',
  'Ocean Breeze Elementary School',
  'Ocean Charter',
  'Ocean City Elementary',
  'Ocean City High School',
  'Ocean City Intermediate School',
  'Ocean City Primary School',
  'Ocean Co Juv Det Ctr',
  'Ocean County College',
  'Ocean County Vocational Technical School Brick Center',
  'Ocean County Vocational Technical School Culinary Center',
  'Ocean County Vocational Technical School Jackson Center',
  'Ocean County Vocational Technical School Lakehurst Center',
  'Ocean County Vocational Technical School Toms River Center',
  'Ocean County Vocational Technical School Waretown Center',
  'Ocean Crest Elementary School',
  'Ocean Drive Elementary',
  'Ocean Dunes High School',
  'Ocean Gate Elementary School',
  'Ocean Grove Charter',
  'Ocean Hill Collegiate Charter School',
  'Ocean Knoll Elementary',
  'Ocean Lakes Elementary',
  'Ocean Lakes High',
  'Ocean Palms Elementary School',
  'Ocean Park Elementary',
  'Ocean Regional School',
  'Ocean Rgc',
  'Ocean Road Elementary School',
  'Ocean Shore Elementary',
  'Ocean Shores Elementary',
  'Ocean Shores High (continuation)',
  'Ocean Springs High School',
  'Ocean Springs Middle School',
  'Ocean Springs Upper Elementary Scho',
  'Ocean Studies Charter',
  'Ocean Township Elementary School',
  'Ocean Township High School',
  'Ocean Trans Ed Center',
  'Ocean University of China',
  'Ocean View Early Education',
  'Ocean View Elementary',
  'Ocean View High',
  'Ocean View Hills',
  'Ocean View Junior High',
  'Oceana High',
  'Oceana Middle School',
  'Oceanair Elementary',
  'Oceanlake Elementary School',
  'Oceano Elementary',
  'Oceans Behavioral Health',
  'Oceanside Collegiate Academy',
  'Oceanside High',
  'Oceanside High School',
  'Oceanside Middle School',
  'Oceanview Middle School',
  'Oceanway Elementary School',
  'Oceanway School',
  'Ocee Elementary School',
  "Ochanomizu Women's University",
  'Ochoa Elementary School',
  'Ochwilla Elementary School',
  'Ocia A. Peters Elementary',
  'Ockerman Elementary School',
  'Ockerman Middle School',
  'Ockley Green Middle School',
  'Ocoee Elementary',
  'Ocoee High',
  'Ocoee Middle',
  'Ocoee Middle School',
  'Oconee County Elementary School',
  'Oconee County High School',
  'Oconee County Middle School',
  'Oconee County Primary School',
  'Oconomowoc High',
  'Oconto Elementary',
  'Oconto Falls Elementary',
  'Oconto Falls High',
  'Oconto High',
  'Oconto Middle',
  'Ocosta Elementary School',
  'Ocosta Junior - Senior High',
  'Ocotillo Early Learning Elementary School',
  'Ocotillo Elementary',
  'Ocotillo Ridge Elementary',
  'Ocotillo School',
  'Ocps Academic Center For Excellence',
  'Ocracoke School',
  'Ocsa',
  'Ocsd5 High School For Health Professions',
  'Octavio Cumpiano',
  'Octavio V. Catto Community Family School',
  'October 6 university',
  'Octorara Area Jshs',
  'Octorara El Sch',
  'Octorara Intermediate Sch',
  'Octorara Primary Lc',
  'Ocvs Digital Academy',
  'Ocvs Virtual Franchise',
  'Ocvs Virtual Instruction (course Offerings)',
  'Ocvs Virtual Instruction Program',
  'Odebolt-arthur Elementary School',
  'Odell Grade School',
  'Odem El',
  'Odem H S',
  'Odem Int',
  'Odem J H',
  'Oden High School',
  'Oden Maddox Elementary School',
  'Oden Schools',
  'Odense University',
  'Odenton Elementary',
  'Odenville Elementary School',
  'Odenville Intermediate School',
  'Odenville Middle School',
  'Odessa Career And Technical Early College H S',
  'Odessa College',
  'Odessa Elementary School',
  'Odessa H S',
  'Odessa High',
  'Odessa High School',
  'Odessa Kilpatrick El',
  'Odessa Middle',
  'Odessa National Academy of Food Technologies',
  'Odessa National I.I. Mecnikov University',
  'Odessa National Marine University',
  'Odessa National Maritime Academy',
  'Odessa National Polytechnic University',
  'Odessa State Academy of Construction and Architecture',
  'Odessa State Medical University',
  'Odessa Upper Elem.',
  'Odessa-montour Junior/senior High School',
  'Odin Attendance Center 9-12',
  'Odin Attendance Center K-8',
  'Odlar Yurdu University',
  'Odle Middle School',
  'Odom El',
  'Odom Elementary School',
  'Odom Middle',
  'Oduduwa University',
  'Odum Elementary School',
  'Odyssey Academy',
  'Odyssey Academy - Bay Area',
  'Odyssey Academy - Galveston',
  'Odyssey Charter',
  'Odyssey Charter High School',
  'Odyssey Charter School',
  'Odyssey Charter School 6-8',
  'Odyssey Charter School K-5',
  'Odyssey Community School',
  'Odyssey Continuation',
  'Odyssey Elementary',
  'Odyssey Elementary School',
  'Odyssey Institute For Advanced And International Studies',
  'Odyssey Middle',
  'Odyssey Middle School',
  'Odyssey Middlehigh School',
  'Odyssey Multiage Program',
  'Odyssey Online Learning School',
  'Odyssey Preparatory Charter Academy',
  'Odyssey School',
  'Odyssey School Of Denver',
  'Odyssey-magellan',
  'Oehl Elementary',
  'Oehrli Elementary School',
  'Oelrichs Elementary - 02',
  'Oelrichs High School - 01',
  'Oelrichs Jr. High - 03',
  'Oelwein High School',
  'Oelwein Middle School',
  'Ofelia Diaz Rodriguez',
  'Ofelia Torres Pratts - Gato I',
  'Off Campus',
  'Off Campus Cte',
  'Off Campus Daep',
  'Officer Leonard A Reed El',
  'Ogallala High School',
  'Ogden Ave Elem School',
  'Ogden El',
  'Ogden Elem',
  'Ogden Elem School',
  'Ogden Elementary',
  'Ogden Elementary School',
  'Ogden High',
  'Ogden High School',
  'Ogden Int High School',
  'Ogden Middle School',
  'Ogden Preparatory Academy',
  'Ogden Preschool',
  'Ogdensburg Borough School',
  'Ogdensburg Free Academy',
  'Ogeechee Technical College',
  'Ogema Elementary',
  'Ogemaw Heights High School',
  'Ogilvie Elementary',
  'Ogilvie Secondary',
  'Oglala Lakota County Alternative - 09',
  'Oglala Lakota County Virtual High School - 92',
  'Ogle El',
  'Oglesby Elem School',
  'Oglesby School',
  'Oglethorpe Avenue Elementary School',
  'Oglethorpe Charter School',
  'Oglethorpe County Elementary School',
  'Oglethorpe County High School',
  'Oglethorpe County Middle School',
  'Oglethorpe County Primary School',
  'Oglethorpe Point Elementary School',
  'Ogletree Elementary School',
  'Ohara El Sch',
  'Ohatchee Elementary School',
  'Ohatchee High School',
  'Ohio Avenue Elementary School',
  'Ohio College Preparatory School',
  'Ohio Com Cons Grade School',
  'Ohio Community High School',
  'Ohio Connections Academy Inc',
  'Ohio Construction Academy',
  'Ohio County Alternative Learning Program',
  'Ohio County Area Technology Center',
  'Ohio County Day Treatment',
  'Ohio County Elementary School',
  'Ohio County High School',
  'Ohio County Middle School',
  'Ohio Dominican College',
  'Ohio Elementary School',
  'Ohio Hi-point',
  'Ohio Northern University',
  'Ohio School For The Deaf',
  'Ohio State University Agricultural Technical Institute',
  'Ohio State University Columbus',
  'Ohio Street School',
  'Ohio University',
  'Ohio Valley Career & Technical Center',
  'Ohio Valley Energy Technology Academy',
  'Ohio Virtual Academy',
  'Ohio Wesleyan University',
  'Ohka Gakuen University',
  'Ohkay Owingeh Community School',
  'Ohlone College',
  'Ohlone Elementary',
  "Ohtani Women's University",
  'Ohu University',
  'Oil City Area Ms',
  'Oil City Shs',
  'Oilton El',
  'Oilton Hs',
  'Oita Medical University',
  'Oita University',
  'Oita University of Nursing and Health Sciences',
  'Oj Dejonge Middle School',
  'Ojibwa Elementary School',
  'Ojibwa Indian School',
  'Ojibway Correctional Facility',
  'Ojibwe Charter School',
  'Ojo Amarillo Elementary',
  'Ojo Caliente Elementary',
  'Ojo Encino Day School',
  'Ojus Elementary School',
  'Oka (isojiro) Elementary',
  'Okaloosa Academy',
  'Okaloosa Online Non Franchised',
  'Okaloosa Regional Detention',
  'Okaloosa Stemm Center',
  'Okaloosa Technical College And Choice High School',
  'Okaloosa Virtual Franchise',
  'Okaloosa Virtual Instruction (course Offerings)',
  'Okaloosa Virtual Instruction Program',
  'Okaloosa Youth Academy',
  'Okan University',
  'Okanagan University College',
  'Okanogan Alternative High School',
  'Okanogan Co Juvenile Detention',
  'Okanogan High School',
  'Okanogan Middle School',
  'Okanogan Outreach Alternative School',
  'Okapilco Elementary School',
  'Okarche Es',
  'Okarche Hs',
  'Okarche Jhs',
  'Okatie Elementary',
  'Okaw Valley Elementary School',
  'Okaw Valley High School',
  'Okaw Valley Middle School',
  'Okawville Grade School',
  'Okawville Jr/sr High School',
  'Okay Es',
  'Okay Hs',
  'Okayama Prefectural University',
  'Okayama Shoka University',
  'Okayama University',
  'Okayama University of Science',
  'Okeechobee Achievement Academy',
  'Okeechobee High School',
  'Okeechobee Intensive Halfway House',
  'Okeechobee Virtual Franchise',
  'Okeechobee Virtual Instruction Program',
  'Okeechobee Youth Development Center',
  'Okeeffe Elem School',
  'Okeeheelee Middle School',
  'Okeene Es',
  'Okeene Jr-sr Hs (jr)',
  'Okeene Jr-sr Hs (sr)',
  'Okefenokee Technical College',
  'Okemah Hs',
  'Okemah Ms',
  'Okemah Noble Ctr',
  'Okemos High School',
  'Okemos Public Montessoricentral',
  'Okinawa International University',
  'Okinawa Prefectural University of Fine Arts',
  'Okinawa University',
  'Okkodo High School',
  'Okla. Virtual Charter Acad Es',
  'Okla. Virtual Charter Acad Hs',
  'Okla. Virtual Charter Acad Ms',
  'Oklahoma Baptist University',
  'Oklahoma Centennial Hs',
  'Oklahoma Centennial Ms',
  'Oklahoma Christian University',
  'Oklahoma City Community College',
  'Oklahoma City University',
  'Oklahoma Connections Acad Es',
  'Oklahoma Connections Acad Hs',
  'Oklahoma Connections Acad Ms',
  'Oklahoma El Sch',
  'Oklahoma Road Middle',
  'Oklahoma Schl For The Blind Es',
  'Oklahoma Schl For The Blind Hs',
  'Oklahoma School For The Deaf',
  'Oklahoma State University',
  'Oklahoma Union Es',
  'Oklahoma Union Hs',
  'Oklahoma Union Ms',
  'Okmulgee Hs',
  'Okmulgee Jhs',
  'Okmulgee Primary Es',
  'Okoboji Elementary School',
  'Okoboji High School',
  'Okoboji Middle School',
  'Okolona Elementary',
  'Okolona Elementary School',
  'Okolona High School',
  'Okolona Voc Complex',
  'Oktaha Es',
  'Oktaha Hs',
  'Okte Elementary School',
  'Ola Elementary School',
  'Ola High School',
  'Ola Middle School',
  'Olabisi Onabanjo University',
  'Olalla Elementary School',
  'Olander Elementary School',
  'Olanta Elementary',
  'Olathe East Sr High',
  'Olathe Elementary School',
  'Olathe High School',
  'Olathe Middle School',
  'Olathe North Sr High',
  'Olathe Northwest High School',
  'Olathe South Sr High',
  'Olathe West High School',
  'Old Adobe Elementary Charter',
  'Old Bethpage School',
  'Old Bonhomme Elem.',
  'Old Bridge Elementary',
  'Old Bridge High School',
  'Old Brook High School',
  'Old Center Elementary',
  'Old Colony Regional Vocational Technical',
  'Old Corden Porter School',
  'Old Country Road School',
  'Old County Road School',
  'Old Dock Elementary',
  'Old Dominion University',
  'Old Donation School',
  'Old Elm Spring Colony Elementary - 03',
  'Old Farmers Road School',
  'Old Forge El Sch',
  'Old Forge Elementary',
  'Old Forge Jshs',
  'Old Fort Elementary',
  'Old Fort Elementary School Bettsville Campus',
  'Old Fort High School',
  'Old Gallinas',
  'Old Greenwich School',
  'Old Hammondtown',
  'Old Harbor School',
  'Old High Middle School',
  'Old Kentucky Home Middle School',
  'Old Kings Elementary School',
  'Old Mill Elementary',
  'Old Mill Elementary School',
  'Old Mill High',
  'Old Mill Middle North',
  'Old Mill Middle South',
  'Old Mill Pond',
  'Old Mill Road School',
  'Old Mill School',
  'Old Mission Peninsula School',
  'Old North',
  'Old Orchard Beach High School',
  'Old Orchard Elementary',
  'Old Orchard Elementary School',
  'Old Orchard Jr High School',
  'Old Pointe Elementary',
  'Old Post Elementary School',
  'Old Post Road',
  'Old Quarry Middle Sch',
  'Old Redford Academy Elemmiddle',
  'Old Redford Academy High',
  'Old Redford Academy Middle',
  'Old Richmond Elementary',
  'Old River Elementary',
  'Old Rochester Regional High',
  'Old Rochester Regional Jr High',
  'Old Saybrook Middle School',
  'Old Saybrook Senior High School',
  'Old Settlers El',
  'Old Shell Road Magnet School',
  'Old State Elementary School',
  'Old Sturbridge Academy Charter Public School',
  'Old Town Academy K-8 Charter',
  'Old Town El',
  'Old Town Elementary',
  'Old Town Elementary School',
  'Old Town High School',
  'Old Turnpike School',
  'Old Union El',
  'Old Vail Middle School',
  'Old West End Academy Elementary School',
  'Old Wire Elementary School',
  'Olde Columbine High School',
  'Olde Creek Elementary',
  'Olde Orchard Alt Elementary School',
  'Olde Providence Elementary',
  'Olde Sawmill Elementary School',
  'Olde Towne Middle',
  'Oldfield Middle School',
  'Oldham County High School',
  'Oldham County Middle School',
  'Oldham County Preschool',
  'Oldham-ramona Elementary - 02',
  'Oldham-ramona High School - 01',
  'Oldham-ramona Jr. High - 03',
  'Oldmans Township School',
  'Olds Elementary',
  'Oldsmar Elementary School',
  'Olean Intermediate-middle School',
  'Olean Senior High School',
  'Oleander Elementary',
  'Olentangy High School',
  'Olentangy Liberty High School',
  'Olentangy Liberty Middle School',
  'Olentangy Meadows Elementary School',
  'Olentangy Orange Middle School',
  'Olentangy Shanahan Middle School',
  'Olentangy Shanahan Preschool',
  'Oleson El',
  'Oley Valley El Sch',
  'Oley Valley Ms',
  'Oley Valley Shs',
  'Olfen School',
  'Olga Evangelina Colon Torres (la Luna)',
  'Olga L. Reed Elementary',
  'Olga Mas Ramirez',
  'Olin Elementary School',
  'Olinda Elementary',
  'Olita Elementary',
  'Olive Branch Elem',
  'Olive Branch High School',
  'Olive Branch Intermediate School',
  'Olive Branch Middle',
  'Olive Branch Preschool Center',
  'Olive C Martin School',
  'Olive Chapel Elementary',
  'Olive Drive Elementary',
  'Olive Elementary',
  'Olive Es',
  'Olive Grove Charter',
  'Olive Grove Elementary',
  'Olive Hill Elementary School',
  'Olive Hs',
  'Olive J Dodge Elementary School',
  'Olive Middle',
  'Olive Peirce Middle',
  'Olive Street Elementary',
  'Olive Township Elementary School',
  'Olive View Elementary',
  'Olive Vista Middle',
  'Olive-mary Stitt School',
  'Olivehurst Elementary',
  'Oliveira Elementary',
  'Oliveira Middle',
  'Olivelands Elementary',
  'Olivenhain Pioneer Elementary',
  'Oliver Ames High',
  'Oliver Beach Elementary',
  'Oliver C. Greenwood Elementary',
  'Oliver E Clift El',
  'Oliver El',
  'Oliver Elementary School',
  'Oliver Ellsworth School',
  'Oliver H Perry Elementary School',
  'Oliver Hazard Perry',
  'Oliver Hoover Elementary School',
  'Oliver Julian Kendall Elem School',
  'Oliver K5 School',
  'Oliver Mccracken Middle School',
  'Oliver Ms',
  'Oliver Parks 6th Grade School',
  'Oliver Partnership School',
  'Oliver Springs High School',
  'Oliver Springs Middle',
  'Oliver Street School',
  'Oliver W Holmes Elem School',
  'Oliver W Holmes Middle School',
  'Oliver W Winch Middle School',
  'Oliver Wendell Holmes Humanities/communications',
  'Oliver Wendell Holmes Junior High',
  'Oliver Wendell Holmes Middle',
  'Oliver Wolcott Technical High School',
  'Oliver-mercer Spec Ed Unit',
  'Olivero Garza Sr El',
  'Olivet Elementary Charter',
  'Olivet Elementary School',
  'Olivet High School',
  'Olivet Middle School',
  'Olivet Nazarene University',
  'Olivewood',
  'Olivewood Elementary',
  'Olivia Nieto Herrera Elementary',
  'Olivia Park Elementary',
  'Olla-standard Elementary School',
  'Olle Middle',
  'Ollie Culbreth Jr. School',
  'Ollie Detwiler Elementary School',
  "Ollie O'grady El",
  'Olmito El',
  'Olmos El',
  'Olmstead Elementary School',
  'Olmsted Elementary School',
  'Olmsted Falls Early Childhood Center',
  'Olmsted Falls High School',
  'Olmsted Falls Intermediate Building',
  'Olmsted Falls Middle School',
  'Olney C Allen Elem School',
  'Olney Charter High School',
  'Olney El',
  'Olney El Sch',
  'Olney Elementary',
  'Olney Elementary School',
  'Olney H S',
  'Olney J H',
  'Olomana School',
  'Olomoana Elementary School',
  'Olosega Elementary School',
  'Olpe Elementary',
  'Olpe Jr./sr. High School',
  'Olsen El',
  'Olsen Middle School',
  'Olsen Park El',
  'Olson Elementary',
  'Olson Elementary School',
  'Olson Middle',
  'Olson Park Elem School',
  'Oltman Middle School',
  'Olton H S',
  'Olton J H',
  'Olustee Es',
  'Olustee Hs',
  'Olustee-eldorado Es',
  'Olustee-eldorado Hs',
  'Olympia College',
  'Olympia El',
  'Olympia Heights Elementary School',
  'Olympia High',
  'Olympia High School',
  'Olympia Hills',
  'Olympia Middle School',
  'Olympia North Elem Sch',
  'Olympia Regional Learning Academy',
  'Olympia Regional Learning Academy - Montessori School',
  'Olympia South Elem Sch',
  'Olympia West Elem Sch',
  'Olympian High',
  'Olympic Continuation High',
  'Olympic Elementary',
  'Olympic Elementary School',
  'Olympic Heights Adult Education Center',
  'Olympic Heights Community High',
  'Olympic High (continuation)',
  'Olympic High - Biotech Health Pub Admin',
  'Olympic High - Renaissance School',
  'Olympic High School',
  'Olympic High-leadership And Development',
  'Olympic High-math Eng Tech Science',
  'Olympic Hills Elementary School',
  'Olympic Middle School',
  'Olympic Primary Center',
  'Olympic Team High School',
  'Olympic View Elem',
  'Olympic View Elementary',
  'Olympic View Elementary School',
  'Olympic View Middle School',
  'Olympus Academy',
  'Olympus High',
  'Olympus Jr High',
  'Olympus Junior High',
  'Omaha Elementary School',
  'Omaha High School',
  'Omaha Home For Boys Schools',
  'Omaha North Magnet High School',
  'Omaha Northwest Magnet High School',
  'Omaha Public Schools Homebased',
  'Omaha South Magnet High School',
  'Omak High School',
  'Omak Middle School',
  'Oman College Of Management & Technology',
  'Omar Al-Mukhtar University',
  'Omar D Blair Charter School',
  'Omar Elementary',
  'Ombudsman - Charter East',
  'Ombudsman - Charter East Ii',
  'Ombudsman - Charter Metro',
  'Ombudsman - Charter Northeast',
  'Ombudsman - Charter Northwest',
  'Ombudsman - Charter Valencia',
  'Ombudsman - Charter West',
  'Ombudsman Chicago Hs',
  'Omdurman Ahlia University',
  'Omdurman Islamic University',
  'Omega Alpha Academy School',
  'Omega Elementary School',
  'Omegon',
  'Omer Halisdemir University',
  'Omni High School',
  'Omni Middle School',
  'Omro Elementary',
  'Omro High',
  'Omro Middle',
  'Omsk State Agrarian University',
  'Omsk State Medicical Academy',
  'Omsk State Pedagogical University',
  'Omsk State Technical University',
  'Omsk State Transport University',
  'Omsk State University',
  'On Track Academy',
  'Ona Elementary School',
  'Onaga Elem',
  'Onaga Elementary',
  'Onaga Senior High',
  'Onahan Elem School',
  'Onalaska El',
  'Onalaska Elementary School',
  'Onalaska High',
  'Onalaska High School',
  'Onalaska Jr/sr High',
  'Onalaska Middle',
  'Onalaska Middle School',
  'Onamia High School',
  'Onamia Intermediate School',
  'Onamia Primary School',
  'Onate Elementary',
  'Onate High',
  'Onaway Elementary School',
  'Onaway Middle School',
  'Onaway Senior High School',
  'Ondo State University of Science and Technology',
  'Ondokuz Mayis University Samsun',
  'One City Senior Preschool',
  'One Hundred Eighteenth Street',
  'One Hundred Eighty-sixth Street Elementary',
  'One Hundred Fifty-sixth Street Elementary',
  'One Hundred Fifty-third Street',
  'One Hundred Ninth Street Elementary',
  'One Hundred Seventh Street Elementary',
  'One Hundred Sixteenth Street Elementary',
  'One Hundred Thirty-fifth Street Elementary',
  'One Hundred Twelfth Street Elementary',
  'One Hundred Twenty-second Street Elementary',
  'One Room Middle School',
  'One World Middle School At Edenwald',
  'One.charter',
  'Oneco Elementary School',
  'Oneida Elementary',
  'Oneida Elementary School',
  'Oneida High School',
  'Oneida Middle School',
  'Oneida Nation School',
  'Oneida Senior High School',
  'Oneida-herkimer-madison Boces',
  'Oneka Elementary School',
  'Onekama Consolidated Schools',
  'Oneonta Elementary',
  'Oneonta Elementary School',
  'Oneonta High School',
  'Oneonta Middle School',
  'Oneonta Senior High School',
  'Onepurpose',
  'Onesimo Hernandez El',
  'Oneta Ridge Ms',
  'Onida Elementary - 04',
  'Onion Creek Elementary',
  'Onizuka Camp',
  'Online 728',
  'Online School',
  'Onofre Carballeira',
  "Onondaga Cnty Sheriff's Dept",
  'Onondaga Community College',
  'Onondaga Hill Middle School',
  'Onondaga Nation School',
  'Onondaga Road Elementary School',
  'Onondaga Senior High School',
  'Onondaga-cortland-madison Boces',
  'Onslow Early College',
  'Onsted Community High School',
  'Onsted Elementary',
  'Onsted Middle School',
  'Ontario Center',
  'Ontario College of Art and Design',
  'Ontario Elementary School',
  'Ontario High',
  'Ontario High School',
  'Ontario Middle School',
  'Ontario Primary School',
  'Ontario-seneca-yates-cayuga-wayne Boces',
  'Ontarioville Elem School',
  'Onteora High School',
  'Onteora Middle School',
  'Ontiveros (juan Pacifico) Elementary',
  'Ontonagon Area School',
  'Oolitic Middle School',
  'Oologah-talala Hs',
  'Oologah-talala Lower Es',
  'Oologah-talala Ms',
  'Oologah-talala Upper Es',
  'Ooltewah Elementary',
  'Ooltewah High School',
  'Ooltewah Middle School',
  'Oostburg Elementary',
  'Oostburg High',
  'Oostburg Middle',
  'Opal Cliffs',
  'Opal Elementary - 15',
  'Opal Myrick Elementary School',
  'Opal Robinson Elementary',
  "Opal School Of The Portland Children's Museum",
  'Opdyke-belle Rive Grade Sch',
  'Opelika High School',
  'Opelika Learning Center',
  'Opelika Middle School',
  'Opelousas Junior High School',
  'Opelousas Senior High School',
  'Open Alternative',
  'Open Charter Magnet',
  'Open Classroom',
  'Open Den',
  'Open Door Youth Reengagement Renton',
  'Open Doors - Youth Reengagement Program',
  'Open Doors At Lwit',
  'Open Doors Battle Ground',
  'Open Doors Community School',
  'Open Doors Evergreen',
  'Open Doors Puyallup',
  'Open Doors Re-engagement Wenatchee',
  'Open Doors Reengagement Program',
  'Open Doors Vancouver',
  'Open Doors Youth Re-engagement Spokane',
  'Open Doors Youth Reengagement (1418)',
  'Open Doors Youth Reengagement Sultan',
  'Open High',
  'Open International University for Alternative Medicines',
  'Open Polytechnic',
  'Open University',
  'Open University Malaysia',
  'Open University of Cyprus',
  'Open University of Hong Kong',
  'Open University of Israel',
  'Open University of Sri Lanka',
  'Open University of Sudan (OUS)',
  'Open University of Tanzania',
  'Open University of the Netherlands',
  'Open Valley Independent Study',
  'Open World Learning Secondary',
  'Opequon Elementary School',
  'Opheim 7-8',
  'Opheim High School',
  'Opheim School',
  'Ophir 7-8',
  'Ophir Elementary',
  'Ophir Elementary School',
  'Opp Elementary School',
  'Opp High School',
  'Opp Middle School',
  'Oppe El',
  'Oppenheim-ephratah-st Johnsville Elementary School',
  'Oppenheim-ephratah-st Johnsville Junior/senior High School',
  'Opport Awareness Ctr',
  'Opportunities For Learning - Baldwin Park',
  'Opportunities For Learning - Baldwin Park Ii',
  'Opportunities For Learning - Capistrano',
  'Opportunities For Learning - Duarte',
  'Opportunities For Learning - Fresno',
  'Opportunities For Learning - Santa Clarita',
  'Opportunity Academy',
  'Opportunity Charter',
  'Opportunity Charter School',
  'Opportunity Elementary',
  'Opportunity High School',
  'Opportunity Middle College',
  'Opportunity Program',
  'Opportunity Youth Academy',
  'Opportunity Youth Reengagement',
  'Ops Virtual Elementary School',
  'Ops Virtual High School',
  'Ops Virtual Middle School',
  'Optima Public School',
  'Optimist Charter',
  'Optimum Learning Environment Charter School',
  'Options',
  'Options At Edina High School',
  'Options Charter School - Carmel',
  'Options Charter School Noblesville',
  'Options For Youth San Gabriel',
  'Options For Youth-acton',
  'Options For Youth-burbank Charter',
  'Options For Youth-san Bernardino',
  'Options For Youth-san Juan',
  'Options For Youth-victorville Charter',
  'Options H S',
  'Options High School',
  'Options Mid/elem Ebd',
  'Options School',
  'Options Secondary',
  'Oquenock Elementary School',
  'Oquirrh Hills Middle',
  'Oquirrh Hills School',
  'Oquirrh School',
  'Oracle Charter School',
  'Oracle Ridge School',
  'Oradea University',
  'Oradell Public School',
  'Oral Roberts University',
  'Oralia R Rodriguez El',
  'Oran Elementary School',
  'Oran High School',
  'Oran K Gragson Elementary School',
  'Oran M Roberts El',
  'Oran P Lawler Elementary School',
  'Orange Avenue School',
  'Orange Beach Elementary School',
  'Orange Brook Elementary School',
  'Orange Center Elementary',
  'Orange Center School',
  'Orange City Elementary School',
  'Orange Coast College',
  'Orange County Academy Of Sciences And Arts',
  'Orange County Community College',
  'Orange County Conservation Corps Charter',
  'Orange County Educational Arts Academy',
  'Orange County High',
  'Orange County Preparatory Academy Charter',
  'Orange County Special Education',
  'Orange County Workforce Innovation High',
  'Orange Cove High',
  'Orange Early Childhood Center',
  'Orange Elementary',
  'Orange Elementary School',
  'Orange Glen Elementary',
  'Orange Glen High',
  'Orange Grove Charter',
  'Orange Grove El',
  'Orange Grove Elementary',
  'Orange Grove Elementary School',
  'Orange Grove H S',
  'Orange Grove High',
  'Orange Grove Int',
  'Orange Grove J H',
  'Orange Grove Middle',
  'Orange Grove Middle Magnet School',
  'Orange Grove Middle School',
  'Orange Grove Pri',
  'Orange High',
  'Orange High School',
  'Orange Hunt Elementary',
  'Orange Inclusive Preschool',
  'Orange Park Elementary School',
  'Orange Park High School',
  'Orange Park Junior High School',
  'Orange Park Performing Arts Academy (oppaa)',
  'Orange Preparatory Academy',
  'Orange River Elementary School',
  'Orange Technical College Mid Florida Campus',
  'Orange Technical College Orlando Campus',
  'Orange Technical College Westside Campus',
  'Orange Technical College Winter Park Campus',
  'Orange Unified Pre-k Sdc',
  'Orange Vista High',
  'Orange Youth Academy',
  'Orange-ulster Boces',
  'Orangeburg Calhoun Technical College',
  'Orangeburg Consolidated School District Five Technology Cent',
  'Orangeburg Wilkinson High',
  'Orangedale Early Learning Center',
  'Orangefield El',
  'Orangefield H S',
  'Orangefield J H',
  'Orangethorpe Elementary',
  'Orangevale Open K-8',
  'Orangeview Junior High',
  'Orangeville Elem School',
  'Orangeville High School',
  'Orangeville Jr High School',
  'Orangewood Elementary',
  'Orangewood Elementary School',
  'Orangewood High (continuation)',
  'Orangewood School',
  'Orca K-8 School',
  'Orcas Island Elementary School',
  'Orcas Island High School',
  'Orcas Island Middle School',
  'Orchard Academies 2b',
  'Orchard Academies 2c',
  'Orchard Avenue Elementary School',
  'Orchard Center Elementary',
  'Orchard Center High School',
  'Orchard Collegiate Academy',
  'Orchard Dale Elementary',
  'Orchard Drive Elem.',
  'Orchard Elementary',
  'Orchard Elementary School',
  'Orchard Farm Elementary',
  'Orchard Farm High School',
  'Orchard Farm Middle School',
  'Orchard Farms El. School',
  'Orchard Gardens',
  'Orchard Grove Elementary',
  'Orchard Heights Elementary',
  'Orchard High School',
  'Orchard Hill Elementary School',
  'Orchard Hill Intermediate School',
  'Orchard Hill School',
  'Orchard Hills',
  'Orchard Hills Elementary School',
  'Orchard Hills School',
  'Orchard Hollow Elementary School',
  'Orchard Knob Elementary',
  'Orchard Knob Middle',
  'Orchard Lake Middle School',
  'Orchard Lane Elementary',
  'Orchard Mesa Middle School',
  'Orchard Middle School',
  'Orchard Park',
  'Orchard Park Academy',
  'Orchard Park Elementary',
  'Orchard Park Elementary School',
  'Orchard Park High School',
  'Orchard Park Middle School',
  'Orchard Place Elem School',
  'Orchard Place School',
  'Orchard Prairie Elementary',
  'Orchard Primary School',
  'Orchard Ranch Elementary',
  'Orchard Ridge Elementary',
  'Orchard School',
  'Orchard Valley Middle School',
  'Orchard View',
  'Orchard View Alternative High School',
  'Orchard View Community Education',
  'Orchard View Early Elementary',
  'Orchard View Elementary',
  'Orchard View Elementary School',
  'Orchard View High School',
  'Orchard View Intermediate School',
  'Orchard View Middle School',
  'Orchard View School',
  'Orchard Villa Elementary School',
  'Orchards Elementary School',
  'Orcutt Academy Charter',
  'Orcutt Junior High',
  'Ord Elementary School',
  'Ord Jr-sr High School',
  'Ord Terrace Elementary',
  'Ordean East Middle School',
  'Ordot Chalan Pago Elementary School',
  'Ordu University',
  'Ordway Elementary',
  'Ore City El',
  'Ore City H S',
  'Ore City Middle',
  'Ore Valley El Sch',
  'Orearville Elem.',
  'Oredocker Project School',
  'Orefield Ms',
  'Oregon 4k',
  'Oregon City Senior High School',
  'Oregon City Service Learning Academy',
  'Oregon Connections Academy',
  'Oregon Correctional Center',
  'Oregon Elem Sch',
  'Oregon Elementary',
  'Oregon Elementary School',
  'Oregon Graduate Institute of Science and Technology',
  'Oregon Health Sciences University',
  'Oregon High',
  'Oregon High School',
  'Oregon Institute of Technology',
  'Oregon Middle',
  'Oregon Middle School',
  'Oregon School For The Deaf',
  'Oregon State University',
  'Oregon Trail Academy',
  'Oregon Trail Elementary',
  'Oregon Trail Elementary School',
  'Oregon Trail Middle School',
  'Oregon Virtual Academy',
  'Oregon Virtual Education',
  'Oregon-davis Elementary School',
  'Oregon-davis Jr-sr High School',
  'Orem High',
  'Orem Jr High',
  'Orem School',
  'Orems Elementary',
  'Orenburg State University',
  'Orenco Elementary School',
  'Orenda Elementary School',
  'Orendorf El Sch',
  'Orenic Intermediate School',
  'Orestimba High',
  'Orick Elementary',
  'Orient Elem',
  'Orient Elementary School',
  'Orient-macksburg Senior High School',
  'Oriental University',
  'Origins High School',
  'Orinda Intermediate',
  'Oriole Beach Elementary School',
  'Oriole Elementary School',
  'Oriole Lane Elementary',
  'Oriole Park Elem School',
  'Oriole Park Elementary School',
  'Orion Academy',
  'Orion Alternative',
  'Orion Elementary School',
  'Orion High School',
  'Orion Jr High',
  'Orion Middle School',
  'Orion Oaks Elementary School',
  'Orion Preparatory Academy Charter',
  'Oriska Elementary School',
  'Oriskany Junior-senior High School',
  'Orissa University of Agriculture and Technology',
  'Orkhon University',
  'Orland Center School',
  'Orland Colony Elementary - 04',
  'Orland Community Day',
  'Orland High',
  'Orland Jr High School',
  'Orland Park Elem School',
  'Orlando Gifted Academy',
  'Orlando Science Elementary Charter',
  'Orlando Science Middle High Charter',
  'Orleans Elementary',
  'Orleans Elementary School',
  'Orleans Jr-sr High School',
  'Orleans-niagara Boces',
  'Orlo Avenue School',
  'Orlo Vista Elementary',
  'Ormond Beach Elementary School',
  'Ormond Beach Middle School',
  'Ormondale Elementary',
  'Oro Grande Elementary',
  'Oro Grande Elementary School',
  'Orofino Elementary School',
  'Orofino High School',
  'Orondo Elementary And Middle School',
  'Orono Discovery Center',
  'Orono High School',
  'Orono Intermediate Elementary',
  'Orono Middle',
  'Orono Middle School',
  'Orono Senior High',
  'Orosi High',
  'Orourke Elementary School',
  'Orovada Elementary School',
  'Oroville Elementary',
  'Oroville High',
  'Oroville High Community Day',
  'Oroville Middle-high School',
  'Orozco Elem Fine Arts & Sciences',
  'Orr Academy High School',
  'Orr Creek',
  'Orr El',
  'Orr Es',
  'Orrick Elem.',
  'Orrick High',
  'Orrington Elem School',
  'Orrs Elementary School',
  'Orrum Middle',
  'Orrville Elementary School',
  'Orrville High School',
  'Orrville Middle School',
  'Ortadogu Tecnical University',
  'Ortega (jose) Elementary',
  'Ortega El',
  'Ortega Elementary',
  'Ortega Elementary School',
  'Ortega High',
  'Ortega Middle School',
  'Orthopaedic Hospital',
  'Orting High School',
  'Orting Middle School',
  'Orting Primary School',
  'Orting Special Education',
  'Ortiz De Dominguez Elem School',
  'Ortiz El',
  'Ortiz Elementary School',
  'Ortiz Middle',
  'Ortona Elementary School',
  'Ortonville Secondary',
  'Orville A Todd Middle School',
  'Orville C Krause Elementary School',
  'Orville H. Platt High School',
  'Orville Wright Elementary',
  'Orville Wright Engineering And Design Magnet',
  'Orvis Risner Es',
  'Orwell Village School',
  'Osage Beach Elem.',
  'Osage City Elem',
  'Osage City High',
  'Osage City Middle School',
  'Osage Co. Elem.',
  'Osage Creek Elementary School',
  'Osage High',
  'Osage High School',
  'Osage Hills Public School',
  'Osage Middle',
  'Osage Middle School',
  'Osage Public School',
  'Osage School',
  'Osage Trail Middle',
  'Osage Upper Elem.',
  'Osaka City University',
  'Osaka College of Music',
  'Osaka Dental University',
  'Osaka Electro-Communication University',
  'Osaka Gakuin University',
  'Osaka Institute of Technology',
  'Osaka International University',
  'Osaka International University for Women',
  'Osaka Medical College',
  'Osaka Prefectural University',
  'Osaka Sangyo University',
  "Osaka Shoin Women's College",
  'Osaka University',
  'Osaka University of Arts',
  'Osaka University of Commerce',
  'Osaka University of Economics',
  'Osaka University of Economics & Law',
  'Osaka University of Education',
  'Osaka University of Foreign Studies',
  'Osaka University of Health and Sport Sciences',
  'Osaka University of Pharmaceutical Sciences',
  "Osaka Women's University",
  'Osakis Elementary',
  'Osakis Secondary',
  'Osawatomie High',
  'Osawatomie Middle School',
  'Osborn Academy Of Mathematics',
  'Osborn College Preparatory Academy',
  'Osborn Correctional Institution',
  'Osborn Elem.',
  'Osborn Elementary',
  'Osborn Evergreen Academy Of Design And Alternative Energy',
  'Osborn High',
  'Osborn Hill School',
  'Osborn Middle School',
  'Osborn School',
  'Osborn Two-way Immersion Academy',
  'Osborne El',
  'Osborne El Sch',
  'Osborne Elem',
  'Osborne Elementary School',
  'Osborne High School',
  'Osborne Junior/senior High',
  'Osborne Middle School',
  'Osbornville Elementary School',
  'Osbourn High',
  'Osbourn Park High',
  'Oscar A Carlson High School',
  'Oscar De La Hoya Animo Charter High',
  'Oscar F Raymond',
  'Oscar F. Loya Elementary',
  'Oscar F. Smith High',
  'Oscar Hamilton Elementary Sch',
  'Oscar Hernandez Guevarra',
  'Oscar Howe Elementary - 58',
  'Oscar J. Pope Elementary School',
  'Oscar L Bunker',
  'Oscar Patterson Elementary Magnet',
  'Oscar Smith Middle',
  'Oscarson Preschool',
  'Oscarson School',
  'Osceola Center For Early Learning',
  'Osceola Charter Preschool',
  'Osceola County School For The Arts',
  'Osceola Creek Middle School',
  'Osceola Elem.',
  'Osceola Elementary',
  'Osceola Elementary School',
  'Osceola Fundamental High',
  'Osceola High',
  'Osceola High School',
  'Osceola Intermediate',
  'Osceola Jr.-sr. High',
  'Osceola Magnet School',
  'Osceola Middle',
  'Osceola Middle School',
  'Osceola Mills El Sch',
  'Osceola Regional Juvenile Commitment Facility',
  'Osceola Science Charter School',
  'Osceola Stem Charter',
  'Osceola Street Elementary',
  'Osceola Virtual Franchise (secondary)',
  'Osceola Virtual Instruction (course Offerings)',
  'Osceola Virtual Instruction Program',
  'Oscoda Area High School',
  'Osgood Elementary School',
  'Osgood Kindergarten Center',
  'Oshki Manidoo Center',
  'Oshki Ogimaag Charter School',
  'Oshkosh Correctional Institution',
  'Oskaloosa Elem',
  'Oskaloosa Elementary School',
  'Oskaloosa High School',
  'Oskaloosa Jr-sr High School',
  'Oskaloosa Middle School',
  'Oslo Middle School',
  'Oslo School of Architecture',
  'Osmangazi University',
  'Osmania University',
  'Osmaniye Korkut Ata University',
  'Osmond Elementary',
  'Osmond Elementary School',
  'Osmond High School',
  'Oso Grande Elementary',
  'Osolo Elementary School',
  'Osprey Learning Center',
  'Osprey Learning Center (alternative)',
  'Osseo Alc Alternative Programs',
  'Osseo Area Learning Center',
  'Osseo Middle School',
  'Osseo Secondary Transition Center',
  'Osseo Senior High',
  'Osseo-fairchild Elementary',
  'Osseo-fairchild High',
  'Osseo-fairchild Middle',
  'Ossian Elementary',
  'Ossie Ware Mitchell Middle School',
  'Ossining High School',
  'Ossipee Central School',
  'Ossun Elementary School',
  'Osteen Elementary School',
  'Oster Elementary',
  'Oster-oakview School',
  'Ostmann Elem.',
  'Ostrander Elementary School',
  'Osun State University',
  'Osuna Elementary',
  'Oswayo Valley El Sch',
  'Oswayo Valley Hs',
  'Oswayo Valley Ms',
  'Oswegatchie Elementary School',
  'Oswego Boces',
  'Oswego East High School',
  'Oswego Goal',
  'Oswego High School',
  'Oswego Junior-senior High School',
  'Oswego Middle School',
  'Oswego Neosho Hgts Elem',
  'Osyka Elementary School',
  'Otani University',
  'Otaru University of Commerce',
  'Otay Elementary',
  'Otay Ranch Senior High',
  'Otego Elementary School',
  'Otemae University',
  'Otemon Gakuin University',
  'Otero Elementary School',
  'Otero Junior College',
  'Othello High School',
  'Other Schools',
  'Otho E Stuart Middle School',
  'Otis A. Mason Elementary School',
  'Otis Elem School',
  'Otis Elementary School',
  'Otis J Brock Iii Elementary School',
  'Otis Junior-senior High School',
  'Otis L Hairston Sr Middle',
  'Otis Orchards School',
  'Otis P Graves Elem School',
  'Otis-bison Elementary',
  'Otis-bison Junior/senior High School',
  'Otisfield Community School',
  'Otisville Elementary School',
  'Otken Elementary School',
  'Otoole Elem School',
  'Otsego Elementary',
  'Otsego Elementary School',
  'Otsego High School',
  'Otsego Junior High',
  'Otsego Middle School',
  'Otsego West Campus High School',
  'Otsego-delaware-schoharie-greene Boces',
  'Otselic Valley Central School',
  "Otsuma Women's University",
  'Ott El',
  'Ott Elem.',
  'Ottauquechee School',
  'Ottawa Area Center',
  'Ottawa Elementary School',
  'Ottawa Hills Elementary School',
  'Ottawa Hills High School',
  'Ottawa Middle School',
  'Ottawa River Elementary School',
  'Ottawa Sr High',
  'Ottawa Township High School',
  'Ottawa-glandorf Complex Elementary School',
  'Ottawa-glandorf High School',
  'Otter Creek Academy',
  'Otter Creek Elem School',
  'Otter Creek Elementary',
  'Otter Creek Elementary School',
  'Otter Creek Middle School',
  'Otter Lake Elementary',
  'Otter Lake Elementary School',
  'Otter River Elementary',
  'Otter Valley Union High School',
  'Otterbein College',
  'Otterbein Elementary School',
  'Otterville Elem.',
  'Otterville High',
  'Otto L Shortell Middle School',
  'Otto Middle',
  'Otto Petersen Elementary School',
  'Otto-Friedrich Universität Bamberg',
  'Otto-eldred El Sch',
  'Otto-eldred Jshs',
  'Otto-von-Guericke Universität Magdeburg',
  'Ottobine Elementary',
  'Ottomon Way Elementary',
  'Ottoson Middle',
  'Ottoville Elementary School',
  'Ottoville High School',
  'Ottumwa Community Preschool',
  'Ottumwa High School',
  'Ottway Elementary',
  'Otwell Middle School',
  'Otwell Miller Academy',
  'Ouabache Elementary School',
  'Ouachita Elementary School',
  'Ouachita High School',
  'Ouachita Junior High School',
  'Ouachita Parish High School',
  'Ouachita Vocational Center',
  'Ouida Springer',
  'Ouisconsing School Of Collaboration',
  'Oulu Polytechnic',
  'Our Community Charter',
  'Our Home',
  'Our Home Asap - 12',
  'Our Home Rediscovery - 77',
  'Our Lady of Fatima University',
  'Our Lady of the Lake University',
  'Our World Neighborhood Charter School',
  'Ouray Elementary School',
  'Ouray Middle School',
  'Ouray Senior High School',
  'Ousd Community Day',
  'Ousd Home Sch',
  'Ousley J H',
  'Out Of District Facility',
  'Out Of District Special Ed',
  'Out Of State School',
  'Outback Preschool',
  'Outcomes For Academic Resilience',
  'Outley El',
  'Outlook Academy',
  'Outlook Academy Youth Home Campus',
  'Outlook Elementary School',
  'Outside Creek Elementary',
  'Outward Bound',
  'Ouzinkie School',
  'Ovando School',
  'Ovejas',
  'Overall Creek Elementary',
  'Overbrook Attendance Center',
  'Overbrook Child Development Center',
  'Overbrook Edu Ctr',
  'Overbrook Elementary School',
  'Overbrook Hs',
  'Overbrook Senior High School',
  'Overby-sheppard Elementary',
  'Overdale Elementary School',
  'Overhills Elementary',
  'Overhills High',
  'Overhills Middle',
  'Overholser Es',
  'Overlake School',
  'Overland Avenue Elementary',
  'Overland Elementary',
  'Overland High School',
  'Overland Park Elem',
  'Overland Trail Elementary',
  'Overland Trail Middle',
  'Overland Trail Middle School',
  'Overlea High & Academy Of Finance',
  'Overlook Elementary',
  'Overlook Elementary School',
  'Overlook Middle School',
  'Overlook Primary School',
  'Overlook Sch',
  'Overpark Elementary',
  'Overstreet Elementary',
  'Overton El',
  'Overton Elementary',
  'Overton Elementary School',
  'Overton H S',
  'Overton High',
  'Overton High School',
  'Oveta Culp Hobby El',
  'Oviatt Elementary School',
  'Ovidelsie Comm Edalt Hs',
  'Ovidelsie High School',
  'Ovidelsie Middle School',
  'Oviedo High School',
  'Owasco Elementary School',
  'Owasso 6th Grade Ctr',
  'Owasso 7th Grade Ctr',
  'Owasso 8th Grade Ctr',
  'Owasso Hs',
  'Owatin Creek El Sch',
  'Owatonna Alc 6-8',
  'Owatonna Alc 9-12',
  'Owatonna Ecse',
  'Owatonna Middle School',
  'Owatonna Senior High',
  'Owatonna Summer School 6-8',
  'Owatonna Summer School 9-12',
  'Owego Elementary School',
  'Owego Free Academy',
  'Owego-apalachin Middle School',
  'Owen County Elementary/primary School',
  'Owen County High School',
  'Owen D Young Central School',
  'Owen Elem Scholastic Academy',
  'Owen Elementary School',
  'Owen Es',
  'Owen High',
  'Owen Intermediate School',
  'Owen J Roberts Hs',
  'Owen J Roberts Ms',
  'Owen Marsh Elem School',
  'Owen Middle',
  'Owen Valley Community High School',
  'Owen Valley Middle School',
  'Owen-withee Elementary',
  'Owen-withee High',
  'Owen-withee Junior High',
  'Owendalegagetown Elementary School',
  'Owendalegagetown Jrsr High School',
  'Owens Community Academy Es',
  'Owens Community College',
  'Owens Cross Roads School',
  'Owens El',
  'Owens Elementary School',
  'Owens Int',
  'Owens Valley Elementary',
  'Owens Valley High',
  'Owensboro Community and Technical College',
  'Owensboro Day Treatment',
  'Owensboro High School',
  'Owensboro Innovation Academy',
  'Owensboro Middle School North',
  'Owensboro Middle School South',
  'Owensboro Technical College',
  'Owensmouth Continuation',
  'Owensville Community School',
  'Owensville Elem.',
  'Owensville High',
  'Owensville Middle',
  'Owings Mills Elementary',
  'Owings Mills High',
  'Owingsville Elementary School',
  'Owl Creek School',
  'Owls Head Central School',
  'Owosso High School',
  'Owosso Middle School',
  'Owsley County Elementary School',
  'Owsley County High School',
  'Owyhee Elementary School',
  'Owyhee Elementary School (all Nv & Idaho Only)',
  'Owyhee High School (all Nv & Idaho Only)',
  'Owyhee Junior High School (all Nv & Idaho Only)',
  'Ox Ridge Elementary School',
  'Oxbow Aces Academyalternative And Adult Ed',
  'Oxbow Creek Elementary',
  'Oxbow Elementary School',
  'Oxbow Uhsd #30',
  'Oxendine Elementary',
  'Oxf/laf School Of Applied Tech',
  'Oxford Academy',
  'Oxford Academy High School',
  'Oxford Academy Middle School',
  'Oxford Academy Primary School',
  'Oxford Area Hs',
  'Oxford Area Vocational School',
  'Oxford Avenue School',
  'Oxford Bridges High School',
  'Oxford Brookes University',
  'Oxford Center School',
  'Oxford Central School',
  'Oxford Crossroads Day School',
  'Oxford Day Academy',
  'Oxford Early Learning Center',
  'Oxford Elem',
  'Oxford Elementary',
  'Oxford Elementary School',
  'Oxford High',
  'Oxford High School',
  'Oxford Hills Comprehensive H S',
  'Oxford Hills Middle School',
  'Oxford Hills Tech - Region 11',
  'Oxford Intermediate School',
  'Oxford Jr/sr High',
  'Oxford Learning Center',
  'Oxford Middle',
  'Oxford Middle School',
  'Oxford Preparatory',
  'Oxford Preparatory Academy - Chino Valley',
  'Oxford Preparatory Academy - Saddleback Valley',
  'Oxford Preparatory Academy - South Orange County',
  'Oxford Schools Early College',
  'Oxford Street Elementary School',
  'Oxford Valley El Sch',
  'Oxford Virtual Academy',
  'Oxford-cumberland Canal School',
  'Oxhead Road School',
  'Oxmoor K5',
  'Oxnard College',
  'Oxnard High',
  'Oxnard Street Elementary',
  'Oxon Hill Elementary',
  'Oxon Hill High',
  'Oxon Hill Middle',
  'Oxycocus Elementary School',
  'Oya Charter Manitou 6th-8th',
  'Oya Charter Manitou 9th-12th',
  'Oya Charter Tecumseh 6th-8th',
  'Oya Charter Tecumseh 9th-12th',
  'Oyler School',
  'Oyster Adams Bilingual School',
  'Oyster Bay High School',
  'Oyster Creek El',
  'Oyster River High School',
  'Oyster River Middle School',
  'Oysterponds Elementary School',
  'Ozark Correctional Center',
  'Ozark High',
  'Ozark High School',
  'Ozark Hills School',
  'Ozark Horizon School',
  'Ozark Jr. High',
  'Ozark Junior High School',
  'Ozark Kindergarten School',
  'Ozark Middle School',
  'Ozark Montessori Academy Springdale',
  'Ozark Mountain Technical Ctr.',
  'Ozark Regional Juvenile Det Ct',
  'Ozarka College',
  'Ozarks Technical Community College',
  'Ozaukee Elementary',
  'Ozaukee High',
  'Ozaukee Middle',
  'Ozen H S',
  'Ozona El',
  'Ozona Elementary School',
  'Ozona H S',
  'Ozona Middle',
  'Ozyegin University',
  'P A C',
  'P A Diskin Elementary School',
  'P A S S Learning Ctr',
  'P Brent Trottier',
  'P C Jantz Elementary',
  'P E P',
  'P E P Alter',
  'P E P Alter Co-op',
  'P E Wallace Middle',
  'P F Stewart El',
  'P H Greene El',
  'P H Miller Elem School',
  'P J Jacobs Junior High',
  'P L Bolin Elem School',
  'P L C-pampa Learning Ctr',
  'P M Akin El',
  'P R I D E School',
  'P S Berg Elementary School',
  'P S Jones Middle',
  'P T Coe Elementary School',
  'P W Moore Elementary',
  'P-coc Parent-child Oriented Classroom',
  'P-tech Early College At Northglenn High School',
  'P. A. Walsh Steam Academy',
  'P. B. Ritch Middle School',
  'P. K. Yonge Virtual Franchise',
  'P. M. Wells Charter Academy',
  'P. W. Engvall Elementary',
  'P.b. Smith Elementary',
  'P.b. Young Sr. Elementary',
  'P.j. Hill Elementary',
  'P.k. Yonge Developmental Research School',
  'P.r.e.p. (positive Refocus Education Program)',
  'PA TREATMENT & HEALING - EAST STROUDSBURG',
  'PA TREATMENT & HEALING - LEHIGTON',
  'PA TREATMENT & HEALING - SCRANTON',
  'PA TREATMENT & HEALING - WILLIAMSPORT',
  'PACE BRANTLEY SCHOOL',
  'PACE PRIVATE SCHOOL SYSTEM',
  'PACE SCHOOL',
  'PACELLI CATHOLIC HIGH SCHOOL',
  'PACELLI CATHOLIC SCHOOLS',
  'PACIFIC ACADEMY',
  'PACIFIC AUTISM CENTER FOR EDUCATION',
  'PACIFIC BAPTIST SCHOOL',
  'PACIFIC BOYCHOIR ACADEMY',
  'PACIFIC BUDDHIST ACADEMY',
  'PACIFIC CHRISTIAN SCHOOL',
  'PACIFIC CREST COMMUNITY SCHOOL',
  'PACIFIC CREST SCHOOL',
  'PACIFIC EXPLORER MONTESSORI',
  'PACIFIC HARBOR CHRISTIAN SCHOOL',
  'PACIFIC HILLS SCHOOL',
  'PACIFIC LEARNING ACADEMY',
  'PACIFIC LEARNING CENTER NW',
  'PACIFIC LUTHERAN HIGH SCHOOL',
  'PACIFIC NORTHERN ACADEMY',
  'PACIFIC POINT ACADEMY',
  'PACIFIC POINT CHRISTIAN SCHOOLS',
  'PACIFIC PRESCHOOL',
  'PACIFIC RIDGE SCHOOL',
  "PACIFIC RIM INT'L SCHOOL",
  'PACIFIC UNION COLLEGE ELEMENTARY SCHOOL',
  'PACIFICA CHRISTIAN HIGH SCHOOL',
  'PACIFICA MONTESSORI SCHOOL',
  'PACKER COLLEGIATE INSTITUTE',
  'PACKER RIDGE ACADEMY',
  'PACKINGHOUSE CHRISTIAN ACADEMY',
  'PACTT LEARNING CENTER',
  'PADDINGTON STATION',
  'PADUA ACADEMY',
  'PADUA FRANCISCAN HIGH SCHOOL',
  'PAGE ACADEMY',
  'PAGE CHRISTIAN SCHOOL',
  'PAGE PRIVATE SCHOOL - BEVERLY HILLS',
  'PAGE PRIVATE SCHOOL - HANCOCK PARK',
  'PAIDEA CHILD DEVELOPMENT CENTER',
  'PAIDEIA ACADEMY',
  'PAIDEIA SCHOOL',
  'PAIDEIG ACADEMY',
  'PAINT CREEK ACADEMY',
  'PAINT VALLEY SCHOOL',
  'PAL ACADEMY',
  'PALACE CREEK SCHOOL',
  'PALADIN ACADEMY',
  'PALENCIA MONTESSORI ACADEMY',
  'PALISADES CHRISTIAN ACADEMY',
  'PALISADES COUNTRY DAY SCHOOL',
  'PALISADES EPISCOPAL SCHOOL',
  'PALISADES UMC PRESCHOOL & KINDERGARTEN',
  'PALM BAY SDA SCHOOL',
  'PALM BEACH BILINGUAL SCHOOL',
  'PALM BEACH CHRISTIAN ACADEMY',
  'PALM BEACH DAY ACADEMY',
  'PALM DESERT LEARNING TREE CENTER',
  'PALM GROVE CHRISTIAN SCHOOL',
  'PALM HARBOR MONTESSORI ACADEMY',
  'PALM SPRINGS LEARNING ACADEMY',
  'PALM TREE ACADEMY',
  'PALM TREE SCHOOL',
  'PALM VALLEY SCHOOL',
  'PALMA SCHOOL',
  'PALMDALE UNITED METHODIST PRESCHOOL-KINDERGARTEN',
  'PALMER CATHOLIC ACADEMY',
  'PALMER SCHOOL',
  'PALMER TRINITY SCHOOL',
  "PALMERDALE UMC CHRISTIAN SCHOOL & CHILDREN'S CTR",
  'PALMETTO BAY ACADEMY',
  'PALMETTO CHRISTIAN ACADEMY',
  'PALMETTO CHRISTIAN ACADEMY OF GREENWOOD',
  'PALMETTO CHRISTIAN SCHOOL',
  'PALOUSE HILLS CHRISTIAN SCHOOL',
  'PAMLICO CHRISTIAN ACADEMY',
  'PANAMA CITY ADVANCED SCHOOL',
  'PANAMINT CHRISTIAN SCHOOL',
  'PANTEGO CHRISTIAN ACADEMY - ARLINGTON CAMPUS',
  'PANTEGO CHRISTIAN ACADEMY-MANSFIELD CAMPUS',
  'PAOLO PREP ACADEMY',
  'PAPILLON MONTESSORI SCHOOL',
  'PARACLETE HIGH SCHOOL',
  'PARADISE ADVENTIST ACADEMY',
  'PARADISE CHRISTIAN SCHOOL',
  'PARADISE LANE SCHOOL',
  'PARADISE MENNONITE SCHOOL',
  'PARADISE VALLEY CHRISTIAN PREPARATORY',
  'PARADISE VALLEY CHRISTIAN SCHOOL',
  'PARAGON PREPARATORY INC',
  'PARAMOUNT CHRISTIAN ACADEMY',
  'PARAMUS CATHOLIC HIGH SCHOOL',
  'PARDES JEWISH DAY SCHOOL',
  'PARISH EPISCOPAL SCHOOL',
  'PARK ACADEMY',
  'PARK ACADEMY SCHOOL',
  'PARK AVENUE CHRISTIAN ACADEMY',
  'PARK AVENUE CHRISTIAN SCHOOL',
  'PARK CENTURY SCHOOL',
  'PARK CHRISTIAN SCHOOL',
  'PARK CHRISTIAN SCHOOLS',
  'PARK CITY DAY SCHOOL',
  'PARK DAY SCHOOL',
  'PARK FOREST MONTESSORI SCHOOL',
  'PARK MAITLAND SCHOOL',
  'PARK MONTESSORI',
  "PARK MONTESSORI CHILDREN'S CENTER",
  'PARK PLACE SCHOOL',
  'PARK POINT MONTESSORI',
  'PARK ROW CHRISTIAN ACADEMY',
  'PARK STREET SCHOOL',
  'PARK TUDOR SCHOOL',
  'PARK VIEW MONTESSORI SCHOOL - CHICAGO',
  'PARKCENTER MONTESSORI',
  'PARKER MONTESSORI EDUCATIONAL INSTITUTE',
  'PARKER SCHOOL',
  'PARKERSBURG ACADEMY',
  'PARKERSBURG CATHOLIC ELEMENTARY SCHOOL',
  'PARKERSBURG CATHOLIC HIGH SCHOOL',
  'PARKERSBURG CHRISTIAN SCHOOL',
  'PARKHILL CHRISTIAN ACADEMY',
  'PARKLAND LUTHERAN CHURCH & SCHOOL',
  'PARKLAND PREPARATORY ACADEMY',
  'PARKLANE ACADEMY',
  'PARKMAN PAROCHIAL',
  'PARKRIDGE CHRISTIAN ACADEMY',
  'PARKRIDGE PRIVATE SCHOOL',
  'PARKSIDE',
  'PARKSIDE AMISH SCHOOL',
  'PARKSIDE COMMUNITY SCHOOL',
  'PARKVIEW ADVENTIST ACADEMY',
  'PARKVIEW BAPTIST SCHOOL',
  'PARKVIEW CHRISTIAN ACADEMY',
  'PARKVIEW CHRISTIAN SCHOOL',
  'PARKVIEW JR ACADEMY',
  'PARKVIEW PRE-K',
  'PARKWAY BAPTIST CHURCH WEE CARE',
  'PARKWAY CHRISTIAN SCHOOL',
  'PARKWAY ELEMENTARY SCHOOL',
  'PARKWAY PRESBYTERIAN PRESCHOOL AND KINDERGARTEN',
  'PARKWOOD SCHOOL',
  'PARKWOOD WEEKDAY EARLY EDUCATION CENTER',
  'PARMA HEIGHTS CHRISTIAN ACADEMY',
  'PARMA MONTESSORI SCHOOL ELEMENTARY',
  'PAROCHIAL #1',
  'PAROCHIAL #2',
  'PARSIPPANY CHRISTIAN SCHOOL',
  'PARSON AMISH SCHOOL',
  'PARSONS CHRISTIAN ACADEMY',
  'PARTNERS IN EXCELLENCE ACADEMY',
  'PARZIVAL SHIELD WALDORF PRESCHOOL KINDERGARTEN',
  'PASADENA CHRISTIAN SCHOOL',
  'PASADENA PREP ACADEMY',
  'PASADENA WALDORF HIGH SCHOOL',
  'PASADENA WALDORF SCHOOL',
  'PASCACK VALLEY LEARING CENTER',
  'PASSAGE CHRISTIAN ACADEMY',
  'PATH VALLEY CHRISTIAN SCHOOL',
  'PATHFINDER VILLAGE SCHOOL',
  'PATHS TO INDEPENDENCE',
  'PATHWAY CHRISTIAN ACADEMY',
  'PATHWAY CHRISTIAN SCHOOL',
  'PATHWAY INC',
  'PATHWAY MONTESSORI',
  'PATHWAY SCHOOL',
  'PATHWAY SCHOOL INC.',
  'PATHWAYS ACADEMY',
  'PATHWAYS DAY SCHOOL',
  'PATHWAYS EDUCATION CENTER',
  'PATHWAYS MERRY MEETING CENTER ACHIEVE PROGRAM',
  'PATHWAYS MIDDLE AND HIGH',
  'PATHWAYS PRIVATE SCHOOL',
  'PATHWAYS SCHOOL -- CROSSLAND RE-ENTRY',
  'PATHWAYS SCHOOL OF EXCELLENCE',
  'PATHWAYS SCHOOL--ANNE ARUNDEL',
  'PATHWAYS SCHOOL--DUVAL RE-ENTRY',
  'PATHWAYS SCHOOL--EDGEWOOD',
  'PATRICIAN ACADEMY',
  'PATRICK HENRY ACADEMY',
  'PATTEN ACADEMY OF CHRISTIAN EDUCATION',
  'PATTI-CAKE DAYCARE CENTER',
  'PAUL ANDERSON HIGH SCHOOL',
  'PAUL VI CATHOLIC HIGH SCHOOL',
  'PAUL VI HIGH SCHOOL',
  'PAVILION FOUNDATION SCHOOL',
  'PAW CREEK CHRISTIAN ACADEMY',
  'PAYSON COMMUNITY CHRISTIAN SCHOOL',
  'PCAL CHRISTIAN SCHOOL',
  'PEABODY SCHOOL',
  'PEACE ACADEMY',
  'PEACE BY PIECE LEARNING CENTER',
  'PEACE EVANGELICAL LUTHERAN SCHOOL',
  'PEACE LUTHERAN ACADEMY',
  'PEACE LUTHERAN PRESCHOOL',
  'PEACE LUTHERAN PRESCHOOL & DAYCARE',
  'PEACE LUTHERAN SCHOOL',
  'PEACE LUTHERAN SCHOOL & PREKINDERGARTEN',
  'PEACE MONTESSORI',
  'PEACE MONTESSORI SCHOOL',
  'PEACE ROSE MONTESSORI SCHOOL',
  'PEACE TERRACE ACADEMY',
  'PEACE VALLEY SCHOOL',
  'PEACE WITH CHRIST CHRISTIAN SCHOOL',
  'PEACEFORCE CHRISTIAN ACADEMY',
  'PEACEFUL BEGINNINGS MONTESSORI ACADEMY',
  'PEACEFUL VALLEY CHRISTIAN SCHOOL',
  'PEACEFUL VALLEY MONTESSORI ACADEMY',
  'PEACEFUL WOODS',
  'PEACH BLOSSOM SCHOOL',
  'PEACH LANE AMISH SCHOOL',
  'PEACH LANE SCHOOL',
  'PEACHTOWN ELEMENTARY SCHOOL',
  'PEACHTREE ACADEMY',
  'PEACHTREE ROAD LUTHERAN PRESCHOOL',
  'PEAR PARK BAPTIST SCHOOL',
  'PEAR TREE POINT SCHOOL',
  'PEARL HARBOR CHRISTIAN ACADEMY',
  'PEARL PREPARATORY SCHOOL',
  'PEARLAND HERITAGE CHRISTIAN ACADEMY',
  'PEBBLECREEK MONTESSORI',
  'PEBBLES PRESCHOOL AND KINDERGARTEN',
  'PECONIC COMMUNITY SCHOOL',
  'PECULIAR PRIESTHOOD ACADEMY',
  'PEDDIE SCHOOL',
  'PEDRO VALLEY SCHOOL',
  'PEE DEE ACADEMY',
  'PEE WEE LEARNING ACADEMY',
  'PELKIE MENNONITE SCHOOL',
  'PELLA CHRISTIAN GRADE SCHOOL',
  'PELLA CHRISTIAN HIGH SCHOOL',
  'PEN BAY CHRISTIAN SCHOOL',
  'PENCROFT SCHOOL',
  'PEND OREILLE VALLEY ADVENTIST SCHOOL',
  'PENFIELD VILLAGE NURSERY SCHOOL & KINDERGARTEN',
  'PENIEL BAPTIST ACADEMY',
  'PENIEL CHRISTIAN',
  'PENINSULA ADVENTIST SCHOOL',
  'PENINSULA CATHOLIC HIGH SCHOOL',
  'PENINSULA MONTESSORI SCHOOL',
  'PENN CHRISTIAN ACADEMY',
  'PENN JOHNS PAROCHIAL SCHOOL',
  'PENN VIEW CHRISTIAN ACADEMY',
  'PENN-MONT ACADEMY',
  'PENNINGTON MONTESSORI SCHOOL',
  'PENNS CREEK SCHOOL',
  'PENNS VALLEY AMISH PAROCHIAL SCHOOL',
  'PENNSY RIDGE SCHOOL',
  'PENNSYLVANIA SCHOOL FOR THE DEAF',
  'PENNY LANE SCHOOL',
  'PENOBSCOT CHRISTIAN SCHOOL',
  'PENSACOLA CATHOLIC HIGH SCHOOL',
  'PENSACOLA JR ACADEMY',
  'PENSACOLA PRIVATE SCHOOL OF LIBERAL ARTS',
  'PENSOCOLA MENNONITE CHRISTIAN SCHOOL',
  'PENTAB ACADEMY',
  'PENTECOSTAL WAY OF TRUTH SCHOOL ACADEMY',
  "PEOPLE'S ELEMENTARY SCHOOL",
  'PEOPLES BAPTIST ACADEMY',
  'PEORIA ACADEMY',
  'PEORIA CHRISTIAN SCHOOL',
  'PEORIA CHRISTIAN SCHOOL - MONROE',
  'PEORIA HEBREW DAY SCHOOL',
  'PEORIA NOTRE DAME HIGH SCHOOL',
  'PEPPER VALLEY LEARNING CENTER',
  'PEPPERELL CHRISTIAN ACADEMY',
  'PEPPERMINT JUNCTION',
  'PEPPERTREE MONTESSORI',
  'PEQUEA CHRISTIAN SCHOOL',
  'PEQUEA MENNONITE SCHOOL',
  'PERCEPTION EARLY LEARNING CENTER',
  'PEREGRINE SCHOOL',
  'PERELMAN JEWISH DAY SCHOOL-FOREMAN CENTER',
  'PERELMAN JEWISH DAY SCHOOL-STERN CENTER',
  "PERIDOT-OUR SAVIOR'S LUTHERAN ELEMENTARY SCHOOL",
  'PERIMETER CHRISTIAN SCHOOL',
  'PERKINS SCHOOL',
  'PERKINS SCHOOL FOR THE BLIND',
  'PERKIOMEN SCHOOL',
  'PERPETUAL HELP DAY NURSERY',
  'PERRY CHRISTIAN ACADEMY',
  'PERRY HALL CHRISTIAN SCHOOL',
  'PERRY VIEW SCHOOL',
  'PERRY-MEADOWS LEARNING CENTER',
  'PERTH AMBOY CATHOLIC SCHOOL',
  'PERU CATHOLIC SCHOOL',
  'PETER PAN CHILDCARE & LEARNING CENTER',
  'PETER RABBIT NURSERY SCHOOL',
  'PETERS ROAD',
  'PETERSEN ELEMENTARY SCHOOL',
  'PETERSHAM MONTESSORI SCHOOLS',
  'PETRA ACADEMY',
  'PETRA LUTHERAN SCHOOL',
  'PH Zug - Pädagogische Hochschule Zug',
  'PH Zürich - Pädagogische Hochschule Zürich',
  'PHBern - University of Teacher Education Bern',
  'PHEBE ANNN THRONE KINDERGARTEN',
  'PHELPS CREEK',
  'PHENIX CHRISTIAN SCHOOL',
  'PHGR - University of Teacher Education Graubünden',
  'PHIL-MONT CHRISTIAN ACADEMY',
  'PHILADELPHIA CHRISTIAN CENTER ACADEMY',
  'PHILADELPHIA FREE SCHOOL',
  'PHILIPS ACADEMY',
  'PHILLIPS ACADEMY',
  'PHILLIPS BROOKS SCHOOL',
  'PHILLIPS BUILDING FUTURES ~ LOUDOUN',
  'PHILLIPS EXETER ACADEMY',
  'PHILLIPS SCHOOL-ANNANDALE',
  'PHILOMATH MONTESSORI SCHOOL',
  'PHLU - University of Teacher Education Lucerne',
  'PHOENIX ACADEMY DAY SCHOOL',
  'PHOENIX CHRISTIAN PREPARATORY SCHOOL',
  'PHOENIX CHRISTIAN SCHOOL PK-8',
  'PHOENIX COUNTRY DAY SCHOOL',
  'PHOENIX LEARNING ACADEMY INC.',
  'PHOENIX MONTESSORI',
  'PHOENIX PRESCHOOL OF NEW JERSEY',
  'PHOENIX RANCH SCHOOL',
  'PHOENIX RANCH WEST',
  'PHOENIX SCHOOL',
  "PHOENIX SCHOOL/REBEKAH CHILDREN'S SERVICES",
  'PHSG - Pädagogische Hochschule St.Gallen',
  'PHSZ - University of Teacher Education Schwyz',
  'PHTG - Pädagogische Hochschule Thurgau',
  "PHYL'S ACADEMY",
  "PHYL'S ACADEMY PREP",
  "PHYL'S ACADEMY PREPARATORY SCHOOL",
  'PHYLLIS L SUSSER SCHOOL FOR EXCEPTIONAL CHILDREN I',
  'PHYLS ACADEMY',
  'PICADILLY RIDGE SCHOOL',
  'PICKENS ACADEMY',
  'PICO SCHOOL',
  'PIEDMONT ACADEMY',
  'PIEDMONT CHRISTIAN SCHOOL',
  'PIEFFER HILL PAROCHIAL SCHOOL',
  'PIERCE PRIVATE DAY SCHOOL',
  'PIERCING ARROW PRIVATE SCHOOL',
  'PIERREPONT SCHOOL',
  'PIKE CHRISTIAN ACADEMY',
  'PIKE COUNTY CHRISTIAN ACADEMY',
  'PIKE COUNTY CHRISTIAN SCHOOL',
  'PIKE LIBERAL ARTS SCHOOL',
  'PIKES PEAK ACADEMY',
  'PIKES PEAK CHRISTIAN SCHOOL',
  'PILGRIM CHRISTIAN DAY SCHOOL',
  'PILGRIM CHRISTIAN SCHOOL',
  'PILGRIM FELLOWSHIP SCHOOL',
  'PILGRIM LUTHERAN CHRISTIAN SCHOOL',
  'PILGRIM LUTHERAN SCHOOL',
  'PILGRIM SCHOOL',
  'PILLARS ACADEMY',
  'PILLOW ACADEMY',
  'PIN CUSHION MENNONITE SCHOOL',
  'PIN OAK SCHOOL',
  'PINCUSHION HILL MONTESSORI SCHOOL',
  'PINE CASTLE CHRISTIAN ACADEMY',
  'PINE COBBLE SCHOOL',
  'PINE CREEK AMISH SCHOOL',
  'PINE CREEK SCHOOL',
  'PINE CREST SCHOOL',
  'PINE CREST SCHOOL AT BOCA RATON',
  'PINE DRIVE CHRISTIAN SCHOOL',
  'PINE FORGE ACADEMY',
  'PINE GROVE',
  'PINE GROVE AMISH PAROCHIAL SCHOOL',
  'PINE GROVE AMISH SCHOOL',
  'PINE GROVE CHRISTIAN ACADEMY',
  'PINE GROVE MENNONITE SCHOOL',
  'PINE GROVE SCHOOL',
  'PINE HARBOR CHRISTIAN ACADEMY',
  'PINE HAVEN BOYS CENTER',
  'PINE HILL AMISH SCHOOL',
  'PINE HILL COUNTRY DAY SCHOOL',
  'PINE HILL WALDORF SCHOOL',
  'PINE HILLS LEARNING PLACE',
  'PINE HOLLOW SCHOOL',
  'PINE MOUNTAIN CHRISTIAN SCHOOL',
  'PINE POINT SCHOOL',
  'PINE RIDGE',
  'PINE RIDGE ACADEMY',
  'PINE RIDGE AMISH SCHOOL',
  'PINE RIDGE CHRISTIAN SCHOOL',
  'PINE RIDGE MENNONITE SCHOOL',
  'PINE RIDGE SCHOOL',
  'PINE TREE ACADEMY',
  'PINE TREE PRESCHOOL MONTESSOR',
  'PINE VALLEY SCHOOL',
  'PINE VALLEY UMC WEE CARE PRESCHOOL & KINDERGARTEN',
  'PINE VIEW',
  'PINE VIEW SCHOOL',
  'PINE WOODS SCHOOL',
  'PINECREST ACADEMY',
  'PINECREST MENNONITE',
  'PINECREST PRESBYTERIAN DAY SCHOOL',
  'PINECREST SCHOOL',
  'PINEHAVEN CHRISTIAN CHILDRENS RANCH',
  'PINEHURST CHRISTIAN SCHOOL',
  'PINES ACADEMY',
  'PINES CHRISTIAN- THE',
  'PINES MONTESSORI SCHOOL',
  'PINES PRESBYTERIAN PRESCHOOL',
  'PINEVIEW SCHOOL',
  'PINEWOOD CHRISTIAN ACADEMY',
  'PINEWOOD PREPARATORY SCHOOL',
  'PINEWOODS MONTESSORI SCHOOLCORP',
  'PINEY GROVE BOYS ACADEMY',
  'PINGREE SCHOOL',
  'PINNACLE ACADEMY',
  'PINNACLE MONTESSORI',
  'PINOCCHIO DAY CARE',
  'PIONEER ACADEMY',
  'PIONEER AMISH PAROCHIAL SCHOOL',
  'PIONEER BAPTIST CHURCH SCHOOL',
  'PIONEER BAPTIST SCHOOL',
  'PIONEER CHRISTIAN SCHOOL',
  'PIONEER COUNTRY',
  'PIONEER MEADOWS MONTESSORI SCHOOL',
  'PIONEER MONTESSORI SCHOOL',
  'PIONEER SCHOOL',
  'PIONEER VALLEY CHRISTIAN ACADEMY',
  'PIONEER VALLEY MONTESSORI SCHOOL',
  'PIONEERS ACADEMY',
  'PIPE CREEK CHRISTIAN SCHOOL',
  'PIQUA CATHOLIC SCHOOL',
  'PIQUA CHRISTIAN SCHOOL',
  'PIQUA SDA CHRISTIAN SCHOOL',
  'PITCH PROGRAM (WED TO BE YOUTH SHELTER)',
  'PITT ACADEMY',
  'PITTSBURGH NEW CHURCH SCHOOL',
  'PITTSBURGH URBAN CHRISTIAN SCHOOL',
  'PITTSSFORD SDA SCHOOL',
  'PIUS X HIGH SCHOOL',
  'PIUS XI CATHOLIC HIGH SCHOOL',
  'PIXIE & DIXIE DAY CARE CENTER INC',
  'PK RIDGE INTL MONTESSORI SCHOOL',
  'PK-12 VIRTUAL ACADEMY INC.',
  'PLACER LEARNING CENTER',
  'PLAID ACADEMY',
  'PLAIN VIEW SCHOOL',
  'PLAINVIEW AMISH SCHOOL',
  'PLAINVIEW MENNONITE SCHOOL',
  'PLAINVIEW PAROCHIAL SCHOOL',
  'PLANO TAMIL SCHOOL',
  'PLATO ACADEMY',
  'PLATO ACADEMY INC',
  'PLAY & LEARN PRESCHOOL',
  'PLAY AND LEARN ACADEMY',
  'PLAY LEARN CHILDCARE CENTER INC',
  'PLAY MOUNTAIN PLACE',
  'PLAYFUL MINDS',
  'PLAYHOUSE COOP SCHOOL',
  'PLAYMATES LEARNING CENTER',
  'PLAZA BAPTIST CHRISTIAN PRESCHOOL',
  'PLAZA CHILD CARE',
  'PLAZA HEIGHTS CHRISTIAN ACADEMY',
  'PLCCA DEBRA ANN PORTER 24/7 CHILD DEVT CENT',
  'PLEA SCHOOL',
  'PLEASANT ACRES SCHOOL',
  'PLEASANT GROVE CHRISTIAN SCHOOL',
  'PLEASANT GROVE SCHOOL',
  'PLEASANT HILL',
  'PLEASANT HILL ACADEMY',
  'PLEASANT HILL ADVENTIST ACADEMY',
  'PLEASANT HILL AMISH PAR SCHOOL',
  'PLEASANT HILL C/O DAVID YODER',
  'PLEASANT HILL CHRISTIAN SCHOOL',
  'PLEASANT HILL WESLEYAN ACADEMY',
  'PLEASANT HILLS MONTESSORI SCHOOL',
  'PLEASANT MEADOW SCHOOL',
  'PLEASANT RIDGE SCHOOL',
  'PLEASANT VALLEY',
  'PLEASANT VALLEY AMISH SCHOOL',
  'PLEASANT VALLEY BAPTIST SCHOOL',
  'PLEASANT VALLEY MENNONITE SCHOOL',
  'PLEASANT VALLEY PAROCH SCHOOL',
  'PLEASANT VALLEY SCHOOL',
  'PLEASANT VIEW',
  'PLEASANT VIEW AMISH SCHOOL',
  'PLEASANT VIEW BAPTIST SCHOOL',
  'PLEASANT VIEW CHRISTIAN SCHOOL',
  'PLEASANT VIEW I',
  'PLEASANT VIEW MENNONITE SCHOOL',
  'PLEASANT VIEW SCHOOL',
  'PLEASANTVIEW CHRISTIAN ELEMENTARY SCHOOL',
  'PLIMPTON SCHOOL',
  'PLUM CREEK CHRISTIAN ACADEMY',
  'PLUM TREE MONTESSORI',
  'PLUMFIELD ACADEMY',
  'PLUMFIELD ACADEMY- INC',
  'PLUMSTEAD CHRISTIAN SCHOOL',
  'PLYMOUTH CHRISTIAN ACADEMY',
  'PLYMOUTH CHRISTIAN ELEMENTARY',
  'PLYMOUTH CHRISTIAN HIGH SCHOOL',
  'PLYMOUTH CHRISTIAN SCHOOL',
  'PLYMOUTH CHURCH SCHOOL',
  'PLYMOUTH MEETING FRIENDS SCHOOL',
  'PLYMOUTH-CANTON MONTESSORI SCHOOL',
  'PNEUMA CHRISTIAN ACADEMY',
  'POCAHONTAS CATHOLIC SCHOOL',
  'POCONO ADVENTIST CHRISTIAN SCHOOL',
  'POINCIANA CHRISTIAN PREPARATORY SCHOOL',
  'POINSETT COLONY ELEMENTARY SCHOOL',
  'POINT OF GRACE CHRISTIAN',
  'POINT OF GRACE CHRISTIAN ACADEMY',
  'POINT QUEST EDUCATION',
  'POKO LOKO CHILD CARE CENTER',
  'POKO LOKO EARLY LEARNING CENTER',
  'POLAND SPRING ACADEMY',
  'POLARIS CHRISTIAN ACADEMY',
  'POLITZ DAY SCHOOL',
  'POLITZ HEBREW ACADEMY',
  'POLK COUNTY CHRISTIAN SCHOOL',
  'POLY PREP COUNTRY DAY SCHOOL',
  'POLY PREP LOWER SCHOOL',
  'POLYTECHNIC SCHOOL',
  'POMFRET SCHOOL',
  'PONDEROSA MONTESSORI SCHOOL',
  'PONTIAC CHRISTIAN SCHOOL',
  'POPE FRANCIS HIGH SCHOOL',
  'POPE JOHN PAUL II ACADEMY',
  'POPE JOHN PAUL II CATHOLIC H S',
  'POPE JOHN PAUL II CATHOLIC HIGH SCHOOL',
  'POPE JOHN PAUL II CATHOLIC SCHOOL',
  'POPE JOHN PAUL II HIGH SCHOOL',
  'POPE JOHN PAUL II REGIONAL SCHOOL',
  'POPE JOHN XXIII CENTRAL CATHOLIC HIGH SCHOOL',
  'POPE JOHN XXIII ELEMENTARY SCHOOL',
  'POPE JOHN XXIII HIGH SCHOOL',
  'POPE JOHN XXIII SCHOOL',
  'POPLAR GROVE SCHOOL',
  'POPLAR SPRINGS HOSPITAL LEARNING CENTER',
  'PORT CHARLOTTE ADVENTIST SCHOOL',
  'PORTAGE CHRISTIAN SCHOOL',
  'PORTER ACADEMY',
  'PORTER-GAUD SCHOOL',
  'PORTERSVILLE CHRISTIAN SCHOOL',
  'PORTICO RIVER BRETHREN SCHOOL',
  'PORTLAND ADVENTIST ACADEMY',
  'PORTLAND ADVENTIST ELEMENTARY SCHOOL',
  'PORTLAND CHINESE SCHOOL',
  'PORTLAND CHRISTIAN SCHOOLS',
  'PORTLAND JEWISH ACADEMY',
  'PORTLAND MONTESSORI ACADEMY',
  'PORTLAND WALDORF SCHOOL',
  'PORTLAND YOUTHBUILDERS',
  'PORTLEDGE SCHOOL',
  'PORTSMOUTH CATHOLIC REGIONAL SCHOOL',
  'PORTSMOUTH CHRISTIAN ACADEMY',
  'PORTSMOUTH CHRISTIAN SCHOOL',
  'PORTSMOUTH STEM ACADEMY',
  'POSITIVE TOMORROWS',
  'POSSUM VALLEY',
  'POTENTIA ACADEMY',
  'POTENTIAL CHRISTIAN ACADEMY',
  'POTENTIAL DEVELOPMENT PROGRAM SCHOOL OF AUTISM',
  'POTOMAC CRESCENT WALDORF SCHOOL',
  'POTOMAC GLEN DAY SCHOOL',
  "POTTER'S HOUSE ACADEMY",
  "POTTER'S WHEEL ACADEMY",
  'POTTERS HOUSE HIGH SCHOOL',
  'POUGHKEEPSIE DAY SCHOOL',
  'POUGHKEEPSIE SEVENTH-DAY ADVENTIST ELEM SCHOOL',
  'POULSBO ADVENTIST SCHOOL',
  'POVERTY RIDGE AMISH SCHOOL',
  'POWELL VALLEY CHRISTIAN SCHOOL',
  'POWHATAN SCHOOL',
  'PRACTICAL LEARNING CENTER',
  'PRAIRIE CATHOLIC SCHOOL',
  'PRAIRIE CHRISTIAN ACADEMY',
  'PRAIRIE CREEK SCHOOL',
  'PRAIRIE FLOWER MONTESSORI SCHOOL',
  'PRAIRIE HILL LEARNING CENTER',
  'PRAIRIE HILL WALDORF SCHOOL',
  'PRAIRIE LAND SCHOOL',
  'PRAIRIE LANE',
  'PRAIRIE LANE AMISH SCHOOL',
  'PRAIRIE LEARNING ED CENTER',
  'PRAIRIE LUTHERAN ELEMENTARY SCHOOL',
  'PRAIRIE LUTHERAN SCHOOLS',
  'PRAIRIE MOON WALDORF SCHOOL',
  'PRAIRIE SCHOOL',
  'PRAIRIE SCHOOL ATTN:ERNEST',
  'PRAIRIE SCHOOL OF DUPAGE',
  'PRAIRIE UNION SCHOOL',
  'PRAIRIE VIEW ACADEMY',
  'PRAIRIE VIEW MONTESSORI',
  'PRAIRIE VIEW SPECIAL PURPOSE DAY SCHOOL',
  'PRAISE ACADEMY',
  'PRAISE CHRISTIAN ACADEMY',
  'PRAISE POWER & PRAYER CHRISTIAN SCHOOL',
  'PRATTVILLE CHRISTIAN ACADEMY',
  'PRECIOUS ANGELS CHILD CARE CENTER',
  'PRECIOUS CREATIONS II',
  'PRECIOUS GEMS MONTESSORI',
  'PRECIOUS HAVEN LEARNING ACADEMY',
  'PRECIOUS PEOPLE EARLY LEARNING CENTER',
  'PRECIOUS YEARS LEARNING CENTER LTD.',
  'PREMIER ACADEMY',
  'PREMIER ACADEMY - OAKLAND',
  'PREMIER ACADEMY OF TROPHY CLUB',
  'PREMIER ACADEMY SCHOOL',
  'PREMIER EARLY LEARNING CENTER',
  'PRENTISS CHRISTIAN SCHOOL',
  'PREP ACADEMY SCHOOLS',
  'PREP ACADEMY SCHOOLS - DUBLIN',
  'PRESBYTERIAN CHRISTIAN SCHOOL',
  'PRESBYTERIAN DAY SCHOOL',
  'PRESBYTERIAN EAR INSTITUTE',
  'PRESBYTERIAN NURSERY SCHOOL & KINDERGARTEN',
  'PRESBYTERIAN PAN AMERICAN SCHOOL',
  'PRESBYTERIAN SCHOOL',
  'PRESCHOOL PLAY HOUSE/FUNLAND',
  'PRESCHOOL WEEKDAY MINISTRIES',
  'PRESENTATION ACADEMY',
  'PRESENTATION ELEMENTARY SCHOOL',
  'PRESENTATION HIGH SCHOOL',
  'PRESENTATION OF MARY ACADEMY',
  'PRESENTATION OF MARY SCHOOL',
  'PRESENTATION OF THE BLESSED MARY SCHOOL',
  'PRESIDIO HILL SCHOOL',
  'PRESIDIO KNOLLS SCHOOL',
  'PRESSLEY RIDGE DAY SCHOOL - GREENSBURG',
  'PRESSLEY RIDGE DAY SCHOOL - PITTSBURGH',
  'PRESSLEY RIDGE JOHNSTOWN',
  'PRESSLEY RIDGE SCHOOL FOR AUTISM',
  'PRESSLEY RIDGE SCHOOL FOR THE DEAF PROGRAM',
  'PRESTIGE PRESCHOOL ACADEMY',
  'PRESTON CREEK MONTESSORI',
  'PRESTON HIGH SCHOOL',
  'PRESTON HOLLOW PRSBYTRN SCHOOL',
  'PRESTON MEADOWS MONTESSORI',
  'PRESTONWOOD CHRISTIAN ACADEMY',
  'PRESTONWOOD CHRISTIAN ACADEMY OF PROSPER',
  'PREVAILING FAITH CHRISTIAN ACADEMY',
  'PREW ACADEMY',
  'PRICHARD PREPARATORY SCHOOL',
  "PRIDE & JOY CHILDREN'S ACADEMY",
  'PRIDE & JOY LEARNING',
  'PRIEST LAKE CHRISTIAN ACADEMY',
  'PRIMANTI MONTESSORI SCHOOL WHITTIER',
  "PRIMARY CHILDREN'S MEDICAL CENTER SCHOOL",
  'PRIMARY LEARNING ACADEMY',
  'PRIMARY LEARNING PLACE',
  'PRIMARY MONTESSORI DAY SCHOOL',
  'PRIMARY PLUS EL QUITO SCHOOL',
  'PRIMARY PLUS ELEMENTARY SCHOOL',
  'PRIMAVERA SCHOOL',
  'PRIME CARE LEARNING CENTER II',
  'PRIMROSE AT IRONBRIDGE CORNER',
  'PRIMROSE HILL SCHOOL',
  'PRIMROSE MONTESSORI SCHOOL',
  'PRIMROSE SCHOOL',
  'PRIMROSE SCHOOL - IMPERIAL OAKS',
  'PRIMROSE SCHOOL AT BRIDGEWATER',
  'PRIMROSE SCHOOL AT FALL CREEK',
  'PRIMROSE SCHOOL AT GRAY EAGLE',
  'PRIMROSE SCHOOL AT HIDDEN LAKES',
  'PRIMROSE SCHOOL AT HIGHLANDS RANCH BUSINESS PARK',
  'PRIMROSE SCHOOL AT LAKEWOOD RANCH TOWN CENTER',
  'PRIMROSE SCHOOL AT LOWRY',
  'PRIMROSE SCHOOL AT MACLAND POINTE',
  'PRIMROSE SCHOOL AT MEADOWBROOK',
  'PRIMROSE SCHOOL AT MOOREFIELD STATION',
  'PRIMROSE SCHOOL AT PALM VALLEY',
  'PRIMROSE SCHOOL AT POLARIS',
  'PRIMROSE SCHOOL AT REUNION',
  'PRIMROSE SCHOOL AT SHADOW CANYON',
  'PRIMROSE SCHOOL AT SONOMA RANCH',
  'PRIMROSE SCHOOL AT STANDLEY LAKE',
  'PRIMROSE SCHOOL AT SUGARLOAF PARKWAY EAST',
  'PRIMROSE SCHOOL AT SUMMERWOOD',
  'PRIMROSE SCHOOL AT THE FLATIRONS',
  'PRIMROSE SCHOOL AT TORREY PEAKS',
  'PRIMROSE SCHOOL AT VISTA LAKES',
  'PRIMROSE SCHOOL AT WEST CLAY',
  'PRIMROSE SCHOOL OF ALGONQUIN',
  'PRIMROSE SCHOOL OF ALPHARETTA',
  'PRIMROSE SCHOOL OF ARROWHEAD',
  'PRIMROSE SCHOOL OF BARKER CYPRESS',
  'PRIMROSE SCHOOL OF BENT TRAIL',
  'PRIMROSE SCHOOL OF BERKELEY HEIGHTS',
  'PRIMROSE SCHOOL OF BRIARGATE',
  'PRIMROSE SCHOOL OF CARROLLWOOD',
  'PRIMROSE SCHOOL OF CASTLE HILLS',
  'PRIMROSE SCHOOL OF CENTENNIAL',
  'PRIMROSE SCHOOL OF CHANHASSEN',
  'PRIMROSE SCHOOL OF COTTONWOOD CREEK',
  'PRIMROSE SCHOOL OF EAGAN',
  'PRIMROSE SCHOOL OF ELDRIDGE PARKWAY',
  'PRIMROSE SCHOOL OF ERIE AT VISTA RIDGE',
  'PRIMROSE SCHOOL OF FIVE FORKS',
  'PRIMROSE SCHOOL OF FOUR POINTS',
  'PRIMROSE SCHOOL OF FRIENDSWOOD',
  'PRIMROSE SCHOOL OF HUEBNER VILLAGE',
  'PRIMROSE SCHOOL OF KELLIWOOD',
  'PRIMROSE SCHOOL OF LA VISTA-PRIVATE KINDERGARTEN',
  'PRIMROSE SCHOOL OF LAKE MARY HEATHROW',
  'PRIMROSE SCHOOL OF LAS COLINAS',
  'PRIMROSE SCHOOL OF LEAGUE CITY AT SOUTH SHORE',
  'PRIMROSE SCHOOL OF LEGACY',
  'PRIMROSE SCHOOL OF LONGMONT',
  'PRIMROSE SCHOOL OF MIDLOTHIAN AT WATERFORD',
  'PRIMROSE SCHOOL OF N E GREEN OAKS',
  'PRIMROSE SCHOOL OF NE FLOWER MOUND',
  'PRIMROSE SCHOOL OF NOBLESVILLE',
  'PRIMROSE SCHOOL OF NORTH MASON CREEK',
  'PRIMROSE SCHOOL OF PARKWOOD HILL',
  'PRIMROSE SCHOOL OF PEARLAND PARKWAY',
  'PRIMROSE SCHOOL OF PROVIDENCE PAVILION',
  'PRIMROSE SCHOOL OF RIVERWOODS',
  'PRIMROSE SCHOOL OF ROCHESTER',
  'PRIMROSE SCHOOL OF ROUND ROCK AT FOREST CREEK',
  'PRIMROSE SCHOOL OF ROWLETT',
  'PRIMROSE SCHOOL OF ROYERSFORD',
  'PRIMROSE SCHOOL OF SADDLE ROCK',
  'PRIMROSE SCHOOL OF SHADY HOLLOW',
  'PRIMROSE SCHOOL OF SIENNA',
  'PRIMROSE SCHOOL OF SOUTH ELGIN',
  'PRIMROSE SCHOOL OF SPRAYBERRY',
  'PRIMROSE SCHOOL OF SPRING CYPRESS',
  'PRIMROSE SCHOOL OF SPRING HILL',
  'PRIMROSE SCHOOL OF SPRINGS RANCH',
  'PRIMROSE SCHOOL OF STONE BROOKE',
  'PRIMROSE SCHOOL OF SUWANEE WEST',
  'PRIMROSE SCHOOL OF SWIFT CREEK',
  'PRIMROSE SCHOOL OF TALLGRASS',
  'PRIMROSE SCHOOL OF THE LAKES AT BLAINE',
  'PRIMROSE SCHOOL OF THE MID-CITIES',
  'PRIMROSE SCHOOL OF THORNTON',
  'PRIMROSE SCHOOL OF VALLEY RANCH',
  'PRIMROSE SCHOOL OF WARREN',
  'PRIMROSE SCHOOL OF WEST CARROLLTON',
  'PRIMROSE SCHOOL OF WEST CINCO RANCH',
  'PRIMROSE SCHOOL OF WEST FISHERS',
  'PRIMROSE SCHOOL OF WEST PEARLAND',
  'PRIMROSE SCHOOL OF WEST WOODS',
  'PRIMROSE SCHOOL OF WOODBURY',
  'PRIMROSE SCHOOL ON YANKEE',
  'PRIMROSE SCHOOL ST PINNACLE',
  'PRIMROSE WEST PLANO',
  'PRINCE ACADEMY',
  'PRINCE OF PEACE CATHOLIC SCHOOL',
  'PRINCE OF PEACE CHRISTIAN SCHOOL',
  'PRINCE OF PEACE EVANGELICAL LUTHERAN SCHOOL',
  'PRINCE OF PEACE LEARNING CENTER',
  'PRINCE OF PEACE LUTHERAN CHURCH AND SCHOOL',
  'PRINCE OF PEACE LUTHERAN SCHOOL',
  'PRINCE OF PEACE PRESCHOOL',
  'PRINCE OF PEACE SCHOOL',
  'PRINCE WILLIAM ACADEMY',
  'PRINCETON ACADEMY OF THE SACRED HEART',
  'PRINCETON CHILD DEVELOPMENT INSTITUTE',
  'PRINCETON CHRISTIAN ACADEMY',
  'PRINCETON CHRISTIAN SCHOOL',
  'PRINCETON DAY SCHOOL',
  'PRINCETON FRIENDS SCHOOL',
  'PRINCETON JR SCHOOL',
  'PRINCETON MONTESSORI SCHOOL',
  'PRINCIPAL',
  'PRINCIPIA SCHOOL',
  'PRIOR LAKE CHRISTIAN ACADEMY',
  'PRISM EDUCATION CENTER',
  'PROCTOR ACADEMY',
  'PROGRAM FOR RIGOR AND INNOVATION IN EDUCATION',
  'PROGRESS VILLAGE CHRISTIAN ACADEMY',
  'PROGRESSIONS SCHOOL',
  'PROGRESSIVE LEARNING FOR CHILDREN',
  'PROGRESSIVE SCHOOL OF LONG ISLAND',
  'PROJECT LEARN SCHOOL',
  'PROJECT PATCH',
  'PROLOGUE EARLY COLLEGE',
  'PROMISE ACADEMY',
  'PROMISE CHRISTIAN ACADEMY',
  'PROMISE CHRISTIAN PRESCHOOL',
  'PROSPECT CHRISTIAN ACADEMY',
  'PROSPECT GROVE HIGH SCHOOL',
  'PROSPECT PARK BNOS LEAH HIGH SCHOOL',
  'PROSPECT PARK YESHIVA',
  'PROSPECT SIERRA SCHOOL',
  'PROTESTANT REFORMED/HERITAGE CHRISTIAN SCHOOLS',
  'PROVIDENCE',
  'PROVIDENCE ACADEMY',
  'PROVIDENCE ACADEMY UPPER SCHOOL',
  'PROVIDENCE CATHOLIC CHILDRENS ACADEMY',
  'PROVIDENCE CATHOLIC HIGH SCHOOL',
  'PROVIDENCE CATHOLIC SCHOOL',
  'PROVIDENCE CHRISTIAN ACADEMY',
  'PROVIDENCE CHRISTIAN SCHOOL',
  'PROVIDENCE CHRISTIAN SCHOOL OF TEXAS',
  'PROVIDENCE CLASSICAL ACADEMY',
  'PROVIDENCE CLASSICAL CHRISTIAN ACADEMY',
  'PROVIDENCE CLASSICAL CHRISTIAN SCHOOL',
  'PROVIDENCE CLASSICAL SCHOOL',
  'PROVIDENCE COUNTRY DAY SCHOOL',
  'PROVIDENCE CRISTO REY HIGH SCHOOL',
  'PROVIDENCE DAY SCHOOL',
  'PROVIDENCE EDUCATION GROUP LLC',
  'PROVIDENCE HALL',
  'PROVIDENCE HEBREW DAY SCHOOL',
  'PROVIDENCE HEIGHTS ALPHA SCHOOL',
  'PROVIDENCE HIGH SCHOOL',
  'PROVIDENCE MONTESSORI',
  'PROVIDENCE MONTESSORI SCHOOL',
  'PROVIDENCE PREPARATORY ACADEMY',
  'PROVIDENCE PRIVATE ACADEMY',
  'PROVIDENCE SCHOOL',
  'PROVIDENCE SCHOOL PIGEON HILL BUILDING',
  'PROVIDENCE ST. MEL SCHOOL',
  'PROVIDENCE-SBCS',
  'PROVO CANYON SCHOOL - SPRINGVILLE CAMPUS',
  'PROVO CANYON SCHOOL-PROVO CAMPUS',
  'PRUNEDALE CHRISTIAN ACADEMY',
  'PS 1 PLURALISTIC SCHOOL',
  'PSG College of Technology',
  'PTPL College',
  'PUDDLETOWN SCHOOL',
  'PUEBLO WEST CHRISTIAN ACADEMY',
  'PUGET SOUNDS COMMUNITY SCHOOL',
  'PUI TAK CHRISTIAN SCHOOL',
  'PULASKI ACADEMY',
  'PULLMAN CHRISTIAN SCHOOL',
  'PUMPKIN PATCH PRESCHOOL & KINDERGARTEN',
  'PUMPKIN RIDGE',
  'PUMPKIN VINE SCHOOL',
  'PUNAHOU SCHOOL',
  'PUNGO CHRISTIAN ACADEMY',
  'PURCELL MARIAN HIGH SCHOOL',
  'PURE IN HEART CHRISTIAN ACAD& PERFORMING ARTS CTR',
  'PURNELL SCHOOL',
  'PURPOSE OF LIFE ACADEMY',
  'PUSCH RIDGE CHRISTIAN ACADEMY GRAMMAR',
  'PUSCH RIDGE CHRISTIAN ACADEMY UPPER SCHOOL',
  'PUTNAM BANNER PRIVATE SCHOOL SYSTEM- INC',
  'PUTNAM SCIENCE ACADEMY',
  'PUZZLE BOX ACADEMY',
  'PYTHAGORAS CHILDRENS ACADEMY',
  'Paakuma K-8',
  'Paauilo Elementary & Intermediate School',
  'Pablo Avila Gonzalez',
  'Pablo Cardona (altozano)',
  'Pablo Casals',
  'Pablo Colon Berdecia',
  'Pablo Elementary',
  'Pablo Fuentes Rivera',
  'Pablo Neruda Academy For Architecture And World Studies',
  'Pablo Roybal Elementary',
  'Pablo Suarez Ortiz',
  'Pabna University of Science and Technology',
  'Pace',
  'Pace Academy',
  'Pace Academy Charter',
  'Pace Alternative High School',
  'Pace Alternative School',
  'Pace Career Academy Charter School',
  'Pace Center',
  'Pace Center For Girls',
  'Pace Center For Girls Clay',
  'Pace Center For Girls Inc.',
  'Pace Center For Girls Treasure Coast',
  'Pace Center For Girls-jax',
  'Pace Charter School Of Hamilton',
  'Pace Correspondence',
  'Pace Early College H S',
  'Pace Elementary',
  'Pace High School',
  'Pace Preparatory Academy',
  'Pace Program',
  'Pace School For Girls',
  'Pace University',
  'Paces Creek Elementary',
  'Paces-w Ne Juvenile Services',
  'Pacetti Bay Middle School',
  'Pachappa Elementary',
  'Pacheco Elementary',
  'Pacheco High',
  'Pachin Marin',
  'Pacific Adventist University',
  'Pacific Avenue - Early Bird Preschool',
  'Pacific Avenue Academy Of Music',
  'Pacific Beach Elementary',
  'Pacific Beach Elementary School',
  'Pacific Beach High',
  'Pacific Beach Middle',
  'Pacific Boulevard',
  'Pacific Career And Technology High',
  'Pacific Cascade Middle School',
  'Pacific Coast Charter',
  'Pacific Coast High (continuation)',
  'Pacific Collegiate Charter',
  'Pacific Community Charter',
  'Pacific County Jail',
  'Pacific Crest Innovation Academy',
  'Pacific Crest Middle School',
  'Pacific Drive Elementary',
  'Pacific Elementary',
  'Pacific Grove High',
  'Pacific Grove Middle',
  'Pacific Heritage Academy',
  'Pacific High',
  'Pacific High School',
  'Pacific International College',
  'Pacific Law Academy',
  'Pacific Lodge Residential Education Center',
  'Pacific Lutheran University',
  'Pacific Middle School',
  'Pacific Rim Elementary',
  'Pacific Trails Middle',
  'Pacific Union College',
  'Pacific Union Elementary',
  'Pacific University',
  'Pacific Valley (k-12)',
  'Pacific View Charter',
  'Pacific View Charter 2.0',
  'Pacific View Leadership Elementary',
  'Pacific Western University',
  'Pacifica High',
  'Pacifica Independent Home Study',
  'Packanack Elementary School',
  "Packard Children's Hospital/stanford",
  'Packsaddle El',
  'Pacoima Charter Elementary',
  'Pacoima Middle',
  'Pacolet Elementary',
  'Pact',
  'Pact @ Jfk',
  'Pact Charter School',
  'Pact Charter Secondary',
  'Pactolus',
  'Paddison Elementary',
  'Paddock Elementary School',
  'Paddock Lane School',
  'Paddock Road Elementary School',
  'Paddy Hill Elementary School',
  'Paden City Elementary School',
  'Paden City High School',
  'Paden Es',
  'Paden Hs',
  'Padonia International Elementary',
  'Padre Anibal Reyes Belen (sup Nueva)',
  'Padre Nazario',
  'Padre Rufo (bilingue)',
  'Padre Rufo M. Fernandez',
  'Padron El',
  'Paducah Area Technology Center',
  'Paducah Head Start Preschool',
  'Paducah Middle School',
  'Paducah School',
  'Paducah Tilghman High School',
  'Paec Academy',
  'Paec Alternative Elementary',
  'Paec Alternative High School',
  'Paec Education Center Ec',
  'Paetow H S',
  'Page County High',
  'Page County Middle',
  'Page County Technical Center',
  'Page Elementary School',
  'Page High',
  'Page High School',
  'Page Hilltop Elementary School',
  'Page Jackson Elementary',
  'Page Middle',
  'Page Middle School',
  'Page School Center',
  'Page Street Elementary',
  'Pageland Elementary',
  'Pagosa Peak Open School',
  'Pagosa Springs Elementary School',
  'Pagosa Springs High School',
  'Pagosa Springs Middle School',
  'Pahoa Elementary School',
  'Pahoa High & Intermediate School',
  'Pahokee Elementary School',
  'Pahokee Middle-senior High',
  'Pahranagat Valley Elementary School',
  'Pahranagat Valley High School',
  'Pahranagat Valley Middle School',
  'Pahrump Early Childhood Special Education',
  'Pahrump Valley High School',
  'Pahvant School',
  'Paia Elementary School',
  'Paichai University',
  'Paideia Cooperative School',
  'Paideia High School',
  'Paideia School 15',
  'Paideia School 24',
  'Paige Elementary School',
  'Paine Elementary School',
  'Paint Branch Elementary',
  'Paint Branch High',
  'Paint Creek Elementary School',
  'Paint Creek School',
  'Paint Lick Elementary School',
  'Paint Rock School',
  'Paint Rock Valley High School',
  'Paint Valley Elementary',
  'Paint Valley High School',
  'Paint Valley Middle School',
  'Paintbrush Elementary',
  'Painted Desert Montessori Llc',
  'Painted Hills Middle',
  'Painted Rock Elementary',
  'Painted Sky Elementary',
  'Painted Sky Elementary School',
  'Painted Stone Elementary',
  'Paintsville Elementary School',
  'Paintsville Head Start',
  'Paintsville High School',
  'Paisley Ib Magnet School',
  'Paisley School',
  'Pajarito Elementary',
  'Pajaro Middle',
  'Pajaro Valley High',
  'Pak-AIMS (American Institute of Management Sciences)',
  'Pakachoag School',
  'Pakanasink Elementary School',
  'Pakistan Institute of Engineering and Applied Sciences (PIEAS)',
  'Paktia University',
  'Pal Program',
  'Palacios H S',
  'Palacios J H',
  'Palacky University',
  'Paladin Career And Tech High School',
  'Palatine High School',
  'Palatka High School',
  'Palawan State University',
  'Palco High',
  'Palco Jr. High',
  'Palencia Elementary School',
  'Palermo',
  'Palermo Consolidated School',
  'Palermo Elementary School',
  'Palermo Special Education Preschool',
  'Palermo Union Community Day (5-8)',
  'Palermo Union Community Day (k-8)',
  'Palestine Career And Technical H S',
  'Palestine Grade School',
  'Palestine H S',
  'Palestine High School',
  'Palestine J H',
  'Palestine Polytechnic University',
  'Palestine Technical College-Dier Elbalah',
  'Palestine Technical University - Kadoorie',
  'Palestine-wheatley Elem. Sch.',
  'Palestine-wheatley Senior High',
  'Palisade Attendance Center',
  'Palisade Glacier High (continuation)',
  'Palisade High School',
  'Palisade Preparatory School',
  'Palisades Charter Elementary',
  'Palisades Charter High',
  'Palisades Elementary',
  'Palisades Elementary School',
  'Palisades High (continuation)',
  'Palisades Hs',
  'Palisades Ms',
  'Palisades Park Elementary',
  'Palisades Park Jr-sr High School',
  'Palm Acres Charter High School',
  'Palm Avenue Elementary',
  'Palm Avenue Excep. Student Center',
  'Palm Bay Academy Charter School',
  'Palm Bay Elementary School',
  'Palm Bay High Adult Education',
  'Palm Bay Magnet Senior High School',
  'Palm Bay Preparatory Academy 6-12',
  'Palm Beach Atlantic College',
  'Palm Beach Central High Adult Education',
  'Palm Beach Central High School',
  'Palm Beach County Jail',
  'Palm Beach Gardens Elementary School',
  'Palm Beach Gardens High Adult Education Center',
  'Palm Beach Gardens High School',
  'Palm Beach Juvenile Correctional Facility',
  'Palm Beach Lakes High Adult Education',
  'Palm Beach Lakes High School',
  'Palm Beach Maritime Academy',
  'Palm Beach Maritime Academy High School',
  'Palm Beach Public School',
  'Palm Beach Regional Detention Center',
  'Palm Beach School For Autism',
  'Palm Beach State College',
  'Palm Beach Virtual Franchise',
  'Palm Beach Virtual Instruction (course Offerings)',
  'Palm Beach Virtual Instruction Program',
  'Palm Canyon',
  'Palm City Elementary School',
  'Palm Cove Elementary School',
  'Palm Crest Elementary',
  'Palm Desert Charter Middle',
  'Palm Desert High',
  'Palm El',
  'Palm Elementary',
  'Palm Elementary School',
  'Palm Glades Preparatory Academy',
  'Palm Glades Preparatory Academy High School',
  'Palm Grove El',
  'Palm Harbor Academy',
  'Palm Harbor Community School',
  'Palm Harbor Middle School',
  'Palm Harbor University High',
  'Palm Lake Elementary',
  'Palm Lakes Elementary School',
  'Palm Lane',
  'Palm Lane Elementary',
  'Palm Middle',
  'Palm River Elementary School',
  'Palm Springs Elementary School',
  'Palm Springs High',
  'Palm Springs Middle School',
  'Palm Springs Middle School Adult',
  'Palm Springs North Elementary School',
  'Palm Terrace Elementary School',
  'Palm Tree Elementary',
  'Palm Valley Elementary',
  'Palm Valley Preschool',
  'Palm View Elementary',
  'Palm View Elementary School',
  'Palm Vista Elementary',
  'Palma Ceia Elementary',
  'Palma Sola Elementary School',
  'Palmcroft Elementary School',
  'Palmdale Aerospace Academy',
  'Palmdale Discovery Center',
  'Palmdale High',
  'Palmdale Learning Plaza',
  'Palmer Early Learning Center',
  'Palmer El',
  'Palmer El Sch',
  'Palmer Elem School',
  'Palmer Elementary',
  'Palmer Elementary School',
  'Palmer H S',
  'Palmer High',
  'Palmer High School',
  'Palmer Junior-senior High',
  'Palmer Lake Elementary',
  'Palmer Lake Elementary School',
  'Palmer Middle',
  'Palmer Middle School',
  'Palmer Park Preparatory Academy',
  'Palmer Ridge High School',
  'Palmer River',
  'Palmer School',
  'Palmer Way',
  'Palmer-laakso El',
  'Palmerton Area Hs',
  'Palmerton Area Jhs',
  'Palmetto Academy Of Learning And Success Charter',
  'Palmetto Academy Of Learning Motorsports (palm)',
  'Palmetto Bays Elementary',
  'Palmetto Charter School',
  'Palmetto Elementary',
  'Palmetto Elementary School',
  'Palmetto High',
  'Palmetto High School',
  'Palmetto Middle',
  'Palmetto Middle School',
  'Palmetto Ridge High School',
  'Palmetto Scholars Academy',
  'Palmetto Youth Academy',
  'Palmetto Youth Academy Charter',
  'Palmiter Special Education',
  'Palmquist Elementary',
  'Palms Elementary',
  'Palms Elementary School',
  'Palms Middle',
  'Palmshores Behavior Health Center',
  'Palmview Elementary School',
  'Palmyra Area Ms',
  'Palmyra Area Shs',
  'Palmyra Elem.',
  'Palmyra Elementary',
  'Palmyra High',
  'Palmyra High School',
  'Palmyra Middle',
  'Palmyra-eagle High',
  'Palmyra-eagle Middle',
  'Palmyra-macedon Intermediate School',
  'Palmyra-macedon Middle School',
  'Palmyra-macedon Primary School',
  'Palmyra-macedon Senior High School',
  'Palo Alto College',
  'Palo Alto El',
  'Palo Alto High',
  'Palo Alto Middle',
  'Palo Duro H S',
  'Palo Pinto El',
  'Palo Verde College',
  'Palo Verde Elementary',
  'Palo Verde Elementary School',
  'Palo Verde High',
  'Palo Verde High Magnet School',
  'Palo Verde High School',
  'Palo Verde Middle School',
  'Palo Verde School',
  'Palolo Elementary School',
  'Paloma Creek El',
  'Paloma Creek High',
  'Paloma Elementary',
  'Paloma Valley High',
  'Palomar College',
  'Palomar Elementary',
  'Palomar High',
  'Palomares Academy Of Health Science',
  'Palomares Elementary',
  'Palominas Elementary School',
  'Palomino Intermediate School',
  'Palomino Primary School',
  'Palos East Elementary School',
  'Palos South Middle School',
  'Palos Verdes High',
  'Palos Verdes Intermediate',
  'Palos Verdes Peninsula High',
  'Palos West Elementary School',
  'Palouse At Garfield Middle School',
  'Palouse Elementary',
  'Palouse High School',
  'Palouse Junction High School',
  'Palouse Prairie Charter School',
  'Pals',
  'Pamela Hodson Es',
  'Pamir University',
  'Pamlico Community College',
  'Pamlico County High',
  'Pamlico County Middle',
  'Pamlico County Primary',
  'Pampa H S',
  'Pampa J H',
  'Pamukkale University',
  'Pan Africa Christian University',
  'Pan African Christian University College',
  'Pan American Academy Cs',
  'Pan American International High School',
  'Pan American International High School At Monroe',
  'Pan-African University',
  'Pan-american Charter School',
  'Pana Jr High School',
  'Pana Sr High School',
  'Panaca Elementary School',
  'Panacea Prep Charter School',
  'Panama City Marine Institute',
  'Panama Elementary',
  'Panama Es',
  'Panama High School',
  'Panama Hs',
  'Panama K-6 School',
  'Panama Ms',
  'Pandit Ravi Shankar Shukla University',
  'Pandora-gilboa Elementary School',
  'Pandora-gilboa High School',
  'Pandora-gilboa Middle School',
  'Pangasinan State University',
  'Pangborn Elementary',
  'Pangburn Elementary School',
  'Pangburn High School',
  'Panguitch High',
  'Panguitch Middle',
  'Panguitch School',
  'Panhandle El',
  'Panhandle H S',
  'Panhandle J H',
  'Panjab University',
  'Pankalo',
  'Pannnasatra University of Cambodia',
  'Panoche Elementary',
  'Panola College',
  'Panola Cs',
  'Panola Early College H S',
  'Panola Es',
  'Panola Hs',
  'Panola Way Elementary School',
  'Panorama City Elementary',
  'Panorama Elementary',
  'Panorama High',
  'Panorama High School',
  'Panorama Middle School',
  'Panorama School',
  'Pansophia Academy',
  'Pansy Kidd Ms',
  'Pantano High School',
  'Pantano Middle School',
  'Panteios University of Economics and Political Sciences Athens',
  'Pantera Elementary',
  'Panther Academy',
  'Panther Creek Elementary School',
  'Panther Creek High',
  'Panther Creek School',
  'Panther Lake Elementary School',
  'Panther Run Elementary School',
  'Panther Valley El Sch',
  'Panther Valley Intermediate Sch',
  'Panther Valley Jshs',
  'Panzhihua University',
  'Paola High',
  'Paola Middle',
  'Paoli Es',
  'Paoli Hs',
  'Paoli Jr & Sr High School',
  'Paonia Elementary School',
  'Paonia High School',
  'Papa Juan Xxiii',
  'Papago School',
  'Paper Mill',
  'Papermaker Preschool',
  'Papillion La Vista Early Childhood Center (plecc)',
  'Papillion La Vista Senior High',
  'Papillion La Vista South Hs',
  'Papillion Middle School',
  'Pappas Rehabilitation Hospital For Children',
  'Papua New Guinea University of Technology',
  'Par Excellence Academy',
  'Para Los Ni?os - Evelyn Thurman Gratts Primary',
  'Para Los Ni?os Charter',
  'Para Los Ni?os Middle',
  'Parade',
  'Paradigm High School',
  'Paradise Canyon Elementary',
  'Paradise Charter',
  'Paradise Charter Middle',
  'Paradise Creek Regional High School',
  'Paradise Education Center',
  'Paradise El',
  'Paradise El Sch',
  'Paradise Elearning Academy',
  'Paradise Elementary',
  'Paradise Elementary School',
  'Paradise H S',
  'Paradise Hills Elementary',
  'Paradise Honors High School',
  'Paradise Int',
  'Paradise Intermediate',
  'Paradise Knoll Elementary School',
  'Paradise Middle',
  'Paradise Senior High',
  'Paradise Unified Special Education',
  'Paradise Valley Community College',
  'Paradise Valley Elementary',
  'Paradise Valley Elementary School',
  'Paradise Valley Engineering Academy',
  'Paradise Valley High School',
  'Paradox Valley Charter School',
  'Paragon Academy',
  'Paragon Academy Of Technology',
  'Paragon Charter Academy',
  'Paragon Collegiate Academy',
  'Paragon Elementary School',
  'Paragon Learning Center',
  'Paragon Mills Elementary',
  'Paragon Science Academy',
  'Paragould High School',
  'Paragould Junior High',
  'Paragould Primary School',
  'Paramount Academy',
  'Paramount Charter Academy',
  'Paramount Charter School',
  'Paramount Collegiate Academy',
  'Paramount Elementary',
  'Paramount Elementary School',
  'Paramount High',
  'Paramount Park Middle',
  'Paramount School Of Excellence',
  'Paramount Terrace El',
  'Paramount Unified Community Day',
  'Paramus High School',
  'Parcelas Aguas Claras',
  'Parcelas Magueyes',
  'Parcelas Maria',
  'Parcelas Marueno',
  'Parcelas Real',
  'Parcelas Vieques',
  'Parcells Middle School',
  'Parchment Central Elem School',
  'Parchment High School',
  'Parchment Middle School',
  'Parchment North Elem School',
  'Parchment Northwood Elem School',
  'Pardee School',
  'Pardeeville Elementary',
  'Pardeeville High',
  'Pardeeville Middle',
  'Paredes El',
  'Paredes M S',
  'Parent Community Connection',
  'Parent Partnerhip',
  'Parents As Partners',
  'Paris Bridges',
  'Paris Cooperative High School',
  'Paris Cooperative High School 4',
  'Paris Cooperative High School 95',
  'Paris Elem.',
  'Paris Elementary',
  'Paris Elementary School',
  'Paris H S',
  'Paris High',
  'Paris High School',
  'Paris J H',
  'Paris Jr. High',
  'Paris Junior College',
  'Paris Middle School',
  'Paris Preschool Center',
  'Paris Ridge Elementary',
  'Paris-Sorbonne University Abu Dhabi',
  'Parish Hill High School',
  'Parishville-hopkinton Elementary School',
  'Parishville-hopkinton Junior-senior High School',
  'Park Ave Elementary School',
  'Park Avenue Elementary',
  'Park Avenue Elementary School',
  'Park Avenue School',
  'Park Brook Elementary',
  'Park Center Ib World School',
  'Park City 7-8',
  'Park City Elementary School',
  'Park City High',
  'Park City High School',
  'Park City Magnet School',
  'Park City Prep Charter School',
  'Park City School',
  'Park Community Charter School',
  'Park Creek Elementary School',
  'Park Crest El',
  'Park Crest Middle',
  'Park Crossing High School',
  'Park Dale Lane Elementary',
  'Park East High School',
  'Park East School',
  'Park El',
  'Park El Sch',
  'Park Elem',
  'Park Elemementary School',
  'Park Elementary',
  'Park Elementary School',
  'Park Es',
  'Park Falls Elementary',
  'Park Forest El Sch',
  'Park Forest Elementary School',
  'Park Forest Middle School',
  'Park Forest Ms',
  'Park Glen El',
  'Park Hall Elementary',
  'Park High',
  'Park High School',
  'Park Hill Elementary',
  'Park Hill High',
  'Park Hill School',
  'Park Hill South High',
  'Park Hills El Sch',
  'Park Intermediate School',
  'Park Junior High School',
  'Park Lake School',
  'Park Lakes El',
  'Park Lakes Elementary School',
  'Park Lane El Sch',
  'Park Lane Elementary School',
  'Park Lane School',
  'Park Lawn Elementary',
  'Park Layne Elementary School',
  'Park Lodge Elementary School',
  'Park Magnet School',
  'Park Manor Elem School',
  'Park Meadows Elementary School',
  'Park Middle',
  'Park Middle School',
  'Park Orchard Elementary School',
  'Park Place El',
  'Park Place Middle School',
  'Park Rapids Area Century Elementary',
  'Park Rapids Area Century Middle',
  'Park Rapids Senior High',
  'Park Ridge Academic Magnet School',
  'Park Ridge Elementary',
  'Park Ridge Elementary School',
  'Park Ridge High School',
  'Park River Elementary School',
  'Park River High School',
  'Park Road Es',
  'Park Road Montessori',
  'Park Road School',
  'Park School',
  'Park Senior High',
  'Park Side Elementary',
  'Park Slope Collegiate',
  'Park Spanish Immersion Elementary',
  'Park Springs Elementary School',
  'Park Street Elementary School',
  'Park Street Intermediate School',
  'Park Street School',
  'Park Terrace Elementary',
  'Park Terrace School',
  'Park Trails Elementary School',
  'Park Valley Elementary',
  'Park Valley Secondary',
  'Park View Elem School',
  'Park View Elementary',
  'Park View Elementary School',
  'Park View High',
  'Park View Int',
  'Park View Middle',
  'Park View Middle School',
  'Park View School',
  'Park Village El',
  'Park Village Elementary',
  'Park Vista Community High School',
  'Park Vista Elementary School',
  'Park Vista High Adult Education',
  'Park West High (continuation)',
  'Park West School',
  'Park Western Place Elementary',
  'Parkade Elem.',
  'Parkdale El',
  'Parkdale Elementary School',
  'Parkdale High',
  'Parke Creek Treatment Ctr',
  'Parke Lane Elementary School',
  'Parker',
  'Parker (jean) Elementary',
  'Parker Alternative School',
  'Parker Core Knowledge Charter School',
  'Parker El',
  'Parker Elem',
  'Parker Elem Community Academy',
  'Parker Elementary',
  'Parker Elementary - 02',
  'Parker Elementary School',
  'Parker Farms School',
  'Parker High',
  'Parker High School',
  'Parker High School - 01',
  'Parker Int',
  'Parker Intermediate Ctr',
  'Parker Jr. High - 03',
  'Parker Junior High School',
  'Parker Middle School',
  'Parker Performing Arts',
  'Parker Road El',
  'Parker Road Elem.',
  'Parker Road Preschool',
  'Parker Whitney Elementary',
  'Parker Woods Montessori',
  'Parker-bennett-curry School',
  'Parker-egin Elementary School',
  'Parker-varney School',
  'Parkers Chapel Elem. School',
  'Parkers Chapel High School',
  'Parkers Lake High School',
  'Parkers Prairie Elementary',
  'Parkers Prairie Secondary',
  'Parkersburg Elementary School',
  'Parkersburg High School',
  'Parkersburg South High School',
  'Parkfield Elementary',
  'Parkhill J H',
  'Parkivew Elementary School',
  'Parkland College',
  'Parkland El',
  'Parkland Es',
  'Parkland H S',
  'Parkland High',
  'Parkland Hs',
  'Parkland Middle',
  'Parkland Pre K Center',
  'Parkland School',
  'Parklane Early Childhood Ctr',
  'Parklane Elementary',
  'Parklane Elementary School',
  'Parklawn Elementary',
  'Parkmead Elementary',
  'Parkmont Elementary',
  'Parkmoor Elementary School',
  'Parkridge Elementary',
  'Parkrose High School',
  'Parkrose Middle School',
  'Parks (rosa) Elementary',
  'Parks El',
  'Parks Elementary School',
  'Parks Middle School',
  'Parks Primary School',
  'Parkside Education Center',
  'Parkside El',
  'Parkside El Sch',
  'Parkside Elem Community Academy',
  'Parkside Elementary',
  'Parkside Elementary School',
  'Parkside High',
  'Parkside Intermediate',
  'Parkside Jr High School',
  'Parkside Middle',
  'Parkside Middle School',
  'Parkside Montessori',
  'Parkside Preparatory Academy',
  'Parkside Primary School',
  'Parkside School',
  'Parkston Elementary - 02',
  'Parkston High School - 01',
  'Parkston Jr. High - 05',
  'Parkston Virtual High School - 92',
  'Parkton Elementary',
  'Parkview',
  'Parkview Center School',
  'Parkview Early Education Center',
  'Parkview Early Literacy Center',
  'Parkview El',
  'Parkview El Sch',
  'Parkview Elem.',
  'Parkview Elementary',
  'Parkview Elementary School',
  'Parkview Es',
  'Parkview Heights Elem School',
  'Parkview High',
  'Parkview High School',
  'Parkview Intermediate School',
  'Parkview Jr High School',
  'Parkview Junior High',
  'Parkview Learning Center',
  'Parkview Magnet High School',
  'Parkview Middle',
  'Parkview Middle School',
  'Parkview Primary School',
  'Parkview School',
  'Parkview School - Levelland',
  'Parkview School - Lubbock',
  'Parkview Village Elementary',
  'Parkville Community School',
  'Parkville High & Center For Math/science',
  'Parkville Middle & Center Of Technology',
  'Parkway Academy',
  'Parkway Center City Middle College',
  'Parkway El',
  'Parkway Elem.',
  'Parkway Elementary',
  'Parkway Elementary School',
  'Parkway Heights Middle',
  'Parkway High School',
  'Parkway Manor Sch',
  'Parkway Middle',
  'Parkway Middle School',
  'Parkway Montessori/community Middle',
  'Parkway Northwest',
  'Parkway School',
  'Parkway West',
  'Parkway West Ctc',
  'Parkwood Elem School',
  'Parkwood Elem.',
  'Parkwood Elementary',
  'Parkwood Elementary School',
  'Parkwood Heights Elementary School',
  'Parkwood High',
  'Parkwood Hill Int',
  'Parkwood Middle',
  'Parkwoodupjohn Elementary School',
  'Parley Coburn School',
  "Parley's Park Preschool",
  'Parleys Park School',
  'Parliament Place School',
  'Parlier High',
  'Parlier Junior High',
  'Parlin School',
  'Parma Elementary School',
  'Parma High School',
  'Parma Middle School',
  'Parma Park Elementary School',
  'Parmalee Elementary School',
  'Parmelee Avenue Elementary',
  'Parmelee Es',
  'Parmenter',
  'Parmer Lane El',
  'Parmley El',
  'Parnall Correctional Facility',
  'Parnassus Prep Sch-grammar',
  'Parnassus Prep School-rhetoric',
  'Parnassus Preparatory School-logic',
  'Parnell El',
  'Parowan High',
  'Parowan School',
  'Parr Elementary School',
  "Parr's Ridge Elementary",
  'Parras (nick G.) Middle',
  'Parris South Elementary',
  'Parrish Elem School',
  'Parrish Elementary School',
  'Parrish Middle School',
  'Parrish Program',
  'Parrottsville Elementary',
  'Parry Mccluer High',
  'Parry Mccluer Middle',
  'Parshall Elementary School',
  'Parshall High School',
  'Parsippany High School',
  'Parsippany Hills High School',
  'Parson Hills Elem. School',
  'Parsons',
  'Parsons Accelerated School',
  'Parsons El',
  'Parsons Elementary',
  'Parsons Elementary School',
  'Parsons Junior High',
  'Parsons Memorial School',
  'Parsons Middle School',
  'Parsons Pre-k Ctr',
  'Parsons Sr High',
  'Partee Elementary School',
  'Parthenia Street Elementary',
  'Parthum Middle School',
  'Partin Elementary School',
  'Partin Settlement Elementary School',
  'Partners For Success-old Mill Sch',
  'Partnership Academy',
  'Partnership Academy Inc.',
  'Partnership Elementary',
  'Partnerships For Student-centered Learning',
  'Partridge Elem',
  'Partridge Elem.',
  'Parwan University',
  'Pasadena Avenue Elementary',
  'Pasadena City College',
  'Pasadena Elementary',
  'Pasadena Elementary School',
  'Pasadena Fundamental Elementary School',
  'Pasadena High',
  'Pasadena High School',
  'Pasadena Lakes Elementary School',
  'Pasadena Memorial H S',
  'Pasadena Park Elementary',
  'Pasadena Rosebud Academy',
  'Pasadena Rosebud Academy Middle',
  'Pascack Hills High School',
  'Pascack Valley High School',
  'Pascagoula High School',
  'Pascagoula Opportunity Center',
  'Pascasio P. Sancerrit',
  'Paschal H S',
  'Paschal Sherman Indian School',
  'Pasco Charter Academy',
  'Pasco Early Childhood',
  'Pasco Early Learning Center',
  'Pasco Elementary School',
  'Pasco Eschool-virtual Franchise',
  'Pasco High Adult Education',
  'Pasco High School',
  'Pasco Middle School',
  'Pasco Mycroschool',
  'Pasco Senior High School',
  'Pasco Virtual Instruction (course Offerings)',
  'Pasco Virtual Instruction Program',
  'Pasco Virtual Instruction Program (district Provided)',
  'Pasco-Hernando State College',
  'Pasco-​Hernando Community College',
  'Pascual Ledoux Academy',
  'Paseo Acad. Of Performing Arts',
  'Paseo Del Norte Academy-vista Del Sol Charter High',
  'Paseo Del Rey Fundamental',
  'Paseo Grande Charter',
  'Paseo Hills Elementary',
  'Paseo Pointe School',
  'Paseo Verde Elementary School',
  'Pashley Elementary School',
  'Paso Del Norte Academy-mesa Charter H S',
  'Paso Del Norte School',
  'Paso Palma I',
  'Paso Robles High',
  'Paso Robles Independent Study Center',
  'Paso Verde',
  'Pasodale El',
  'Pasquotank County High',
  'Pasquotank Elementary',
  'Pass Academy',
  'Pass Christian Elem',
  'Pass Christian High School',
  'Pass Christian Middle',
  'Pass Creek School',
  'Pass Program',
  'Pass Road Elementary School',
  'Passage Charter School',
  'Passages Transition Program',
  'Passageways',
  'Passaic Arts And Science Charter School',
  'Passaic County Community College',
  'Passaic County Technical Institute',
  'Passaic County-manchester Regional High School',
  'Passaic Gifted And Talented Academy School No. 20',
  'Passaic High School No. 12',
  'Passaic Regional School',
  'Passaic Valley Regional High School',
  'Passmore El',
  'Passow Elementary School',
  'Passport Charter',
  'Pasteur Elem School',
  'Pasteur Elementary School',
  'Pat Butler Elementary',
  'Pat Cooper El',
  'Pat Dillard Special Achievement Center',
  'Pat Henry Es',
  'Pat Reynolds El',
  'Patagonia Elementary School',
  'Patagonia Montessori School',
  'Patagonia Union High School',
  'Patapsco High & Center For Arts',
  'Patapsco Middle',
  'Pataskala Elementary School',
  'Pataula Charter Academy',
  'Patch Elementary',
  'Patchogue-medford High School',
  'Pate Early Childhood Ctr.',
  'Pate Elementary',
  "Pate's Creek Elementary School",
  'Patel Institute For Innovation',
  'Pateros Elementary',
  'Pateros High School',
  'Paterson Arts And Science Charter School',
  'Paterson Charter School For Science/technology',
  'Paterson Elementary School',
  'Path Academy',
  'Pathfinder Academy',
  'Pathfinder Achievement Center',
  'Pathfinder Camp',
  'Pathfinder Ed Prg-lancaster Co',
  'Pathfinder Educational Center',
  'Pathfinder Elem.',
  'Pathfinder Elementary School',
  'Pathfinder High School',
  'Pathfinder K-8 School',
  'Pathfinder Kindergarten Center',
  'Pathfinder School',
  'Pathfinder Vocational Technical',
  'Pathfinders Alternative Ed',
  'Pathumthani University',
  'Pathway Academy Elementary',
  'Pathway Academy High School',
  'Pathway Community Day',
  'Pathway Elementary Community Day',
  'Pathway Independent Study',
  'Pathway School',
  'Pathway School Of Discovery',
  'Pathway To Change',
  'Pathways',
  'Pathways 3h Campus',
  'Pathways Academic Campus',
  'Pathways Academy',
  'Pathways Academy Charter',
  'Pathways Academy Charter Adult Education',
  'Pathways Academy K-8 Center',
  'Pathways Academy Of Technology And Design',
  'Pathways Beyond/early Steps To Success Building',
  'Pathways Charter',
  'Pathways College Preparatory School: A College Board School',
  'Pathways Community',
  'Pathways Community Day',
  'Pathways Early College Innovation School',
  'Pathways Elementary',
  'Pathways Elementary (alternative)',
  'Pathways Elementary School',
  'Pathways Future Center',
  'Pathways Global Leadership Academy',
  'Pathways H S',
  'Pathways High',
  'Pathways High (alternative)',
  'Pathways In Education',
  'Pathways In Education - North Market',
  'Pathways In Education - Tn',
  'Pathways In Education - Whitehaven',
  'Pathways In Education Hs',
  'Pathways In Education-louisiana Inc.',
  'Pathways In Education: Nampa',
  'Pathways In Technology Early College High School',
  'Pathways Learning Center',
  'Pathways Learning Ctr',
  'Pathways Middle School',
  'Pathways Middle School (alternative)',
  'Pathways Program Grades 6-7',
  'Pathways Program Grades 8-12',
  'Pathways Roe 11',
  'Pathways School',
  'Pathways To College',
  'Pathways To Success Academic Campus',
  'Patlan El',
  'Patna University',
  'Patoka Elem School',
  'Patoka Jr High School',
  'Patoka Sr High School',
  'Paton-churdan Elementary',
  'Paton-churdan Jr-sr High School',
  'Patria Latorre Ramirez',
  'Patria Perez',
  'Patricia A Bendorf Elementary School',
  'Patricia A Dichiaro School',
  'Patricia A Guth El Sch',
  'Patricia A. Hannaford Career Center',
  'Patricia Beatty Elementary',
  'Patricia Kay Beaver Elementary',
  'Patricia Knowles El',
  'Patricia M. Noonan School Ps #26',
  'Patricia S Garza El',
  'Patricia St Clair Elementary School',
  'Patricia Sullivan Metropolitan Ministries Partnership School',
  'Patricio Perez El',
  'Patrick Copeland Elementary',
  'Patrick County High',
  'Patrick El',
  'Patrick Elementary School',
  'Patrick F. Healy Middle School',
  'Patrick F. Taylor Science & Technology Academy',
  'Patrick Hamilton Elementary',
  'Patrick Henry Community College',
  'Patrick Henry Elementary',
  'Patrick Henry Elementary School',
  'Patrick Henry Es',
  'Patrick Henry High',
  'Patrick Henry High School',
  'Patrick Henry Middle',
  'Patrick Henry Middle School',
  'Patrick Henry Middle School - 07',
  'Patrick Henry School',
  'Patrick Henry School Of Science And Arts',
  'Patrick J Kennedy',
  'Patrick J Thomas Juvenile Cntr',
  'Patrick Lynch Elementary',
  'Patrick M Villano School',
  'Patrick Marsh Middle',
  'Patrick Mcgaheran',
  'Patrick Springs Elementary',
  'Patricksburg Elementary School',
  'Patriot Academy',
  'Patriot Elementary',
  'Patriot Elementary -06',
  'Patriot Elementary School',
  'Patriot High',
  'Patriot High School',
  'Patriot Oaks Academy',
  'Patriot Preparatory Academy',
  'Patriots Elementary',
  'Patronis Elementary School',
  'Patsy Sommer El',
  'Pattengill Academy',
  'Pattengill School',
  'Patterson El',
  'Patterson Elementary',
  'Patterson Elementary School',
  'Patterson Es',
  'Patterson High',
  'Patterson High School',
  'Patterson International School',
  'Patterson John M Sch',
  'Patterson Junior High School',
  'Patterson Mill High School',
  'Patterson Mill Middle School',
  'Patterson Park Public Charter School',
  'Patterson Primary Sch',
  'Patterson Road Elementary',
  'Patti Welder Middle',
  'Pattison Elementary School',
  "Pattison's Academy For Comprehensive Education",
  'Patton El',
  'Patton Elem School',
  'Patton Elementary',
  'Patton Jr High',
  'Patton Middle School',
  'Patton Springs School',
  'Pattonsburg Elem.',
  'Pattonsburg High',
  'Pattonville Heights Middle',
  'Pattonville Pre-school',
  'Pattonville Sr. High',
  'Patuakhali Science and Technology University',
  'Patuxent Appeal Elementary Campus',
  'Patuxent Elementary',
  'Patuxent High',
  'Patuxent Valley Middle',
  'Patwin Elementary',
  'Pau Wa Lu Middle School',
  'Pauba Valley Elementary',
  'Paul & Sheila Wellstone Elementary',
  'Paul A Brown Alternative Center',
  'Paul A Dever',
  'Paul A Schalm School',
  'Paul A. Smith School',
  'Paul And Jane Meyer Public H S',
  'Paul B Cooper Elem',
  'Paul B D Temple Elementary School',
  'Paul B. Stephens Ese Center',
  'Paul Banks Elementary',
  'Paul Belton El',
  'Paul Breaux Middle School',
  'Paul Bunyan Center',
  'Paul Burbank Elementary',
  'Paul C Bunn Elementary School',
  'Paul Cuffee Charter School',
  'Paul D Camp Community College',
  'Paul D Schreiber Senior High School',
  'Paul D. Henry Elementary',
  'Paul D. West Middle School',
  'Paul Dunbar Lawrence School',
  'Paul E Culley Elementary School',
  'Paul Ecke-central Elementary',
  'Paul Elementary School',
  'Paul F Boston Middle School',
  'Paul F Doyon Memorial',
  'Paul G. Blazer High School',
  'Paul G. Caywood Elementary',
  'Paul G. Miller',
  'Paul H Huber Jr High School',
  'Paul H. Cale Elementary',
  'Paul Habans Charter School',
  'Paul Hadley Middle School',
  'Paul Hanlon',
  'Paul Harding Jr High School',
  'Paul I Miller School 114',
  'Paul J Bellew Elementary School',
  'Paul J Gelinas Junior High School',
  'Paul J. Blackwell Learning Center',
  'Paul J. Solis Elementary School',
  'Paul Kinder Middle School',
  'Paul Knox Middle',
  'Paul L Dunbar Elementary School',
  'Paul L Dunbar Learning Center',
  'Paul L Patterson Elementary School',
  'Paul L. Cato Middle',
  'Paul Laurence Dunbar Early Education Center',
  'Paul Laurence Dunbar High',
  'Paul Laurence Dunbar High School',
  'Paul Laurence Dunbar K-8 Center',
  'Paul Laurence Dunbar Middle For Innovation',
  'Paul Laurence Dunbar Middle School',
  'Paul Laurence Dunbar School',
  'Paul Munro Elementary',
  'Paul Norton Elementary School',
  'Paul P Gates Elementary School',
  'Paul Pcs International High School',
  'Paul Pcs Middle School',
  'Paul R Baird Middle',
  'Paul R Brown Leadership Academy',
  'Paul R. Smith Middle School',
  'Paul Revere',
  'Paul Revere Charter Middle',
  'Paul Revere Intermediate School',
  'Paul Revere Primary School',
  'Paul Road School',
  'Paul Robeson Charter School For The Humanities',
  'Paul Robeson Community School For The Arts',
  'Paul Robeson Hs For Human Services',
  'Paul Rogers Elementary',
  'Paul Rumburg Elementary',
  'Paul S. Robeson Elementary School',
  'Paul Saylor Elementary School',
  'Paul T. Albert Memorial School',
  'Paul University',
  'Paul V Fly El Sch',
  'Paul V Moore High School',
  'Paul W Bryant High School',
  'Paul W Carleton',
  'Paul W. Bell Middle School',
  'Paula Hedbavny School',
  'Paula Mojica',
  'Paularino Elementary',
  'Paulden Community School',
  'Paulding County High School',
  'Paulding Elementary School',
  'Paulding High School',
  'Paulding Middle',
  'Paulding Middle School',
  'Paulette Elementary School',
  'Paulette Smith Middle School',
  'Paulina Elementary School',
  'Paulina School',
  'Pauline Agassiz Shaw Elementary School',
  'Pauline Central Primary',
  'Pauline Glenn Springs Elementary',
  'Pauline J. Petway Elementary School',
  'Pauline Naumann El',
  'Pauline South Intermediate',
  'Paulo Freire Charter School',
  'Paulo Freire Freedom School - Downtown',
  'Paulo Freire Freedom School - University',
  'Paulo Freire Social Justice Charter School',
  'Pauls Valley Hs',
  'Pauls Valley Jhs',
  'Paulsboro High School',
  'Paulsboro Junior High School',
  'Pauma Elementary',
  'Paumanok Elementary School',
  'Pauoa Elementary School',
  'Pavaiai Elementary School',
  'Pave Academy Charter School',
  'Pave Southeast Raleigh Charter',
  'Pavilion Junior-senior High School',
  'Pavlodar University',
  'Pavol Jozef Safarik University in Kosice',
  'Paw Creek Elementary',
  'Paw Paw Early Elementary School',
  'Paw Paw Elem School',
  'Paw Paw Elementary School',
  'Paw Paw High School',
  'Paw Paw Jr/sr High School',
  'Paw Paw Later Elementary',
  'Paw Paw Middle School',
  'Pawcatuck Middle School',
  'Pawel Wlodkowic University College in Plock',
  'Pawhuska Es',
  'Pawhuska Hs',
  'Pawhuska Jhs',
  'Pawling Elementary School',
  'Pawling High School',
  'Pawling Middle School',
  'Pawnee City Elementary Sch',
  'Pawnee City Secondary School',
  'Pawnee El / J H',
  'Pawnee Elem',
  'Pawnee Elem School',
  'Pawnee Es',
  'Pawnee Grade School',
  'Pawnee Heights',
  'Pawnee Hs',
  'Pawnee Jr/sr High Sch',
  'Pawnee Ms',
  'Pawnee School Pk-12',
  'Paws',
  'Paws Academy',
  'Pawtucketville Memorial',
  'Paxico Middle School',
  'Paxinosa El Sch',
  'Paxon Hollow Ms',
  'Paxon School/advanced Studies',
  'Paxson School',
  'Paxtang El Sch',
  'Paxton Center',
  'Paxton Elementary School',
  'Paxton High School',
  'Paxton School',
  'Paxton-buckley-loda High School',
  'Paxton-buckley-loda Jr High Sch',
  'Paxtonia El Sch',
  'Payame Noor University',
  'Payame Noor University Tafresh',
  'Payap University Chaiang Mai',
  'Payette Alternative Center',
  'Payette Alternative High School',
  'Payette High School',
  'Payette Lakes Middle School',
  'Payette Primary School',
  'Payette River Technical Academy',
  'Payne Elem',
  'Payne Elementary School',
  'Payne Es',
  'Paynesville Area High School',
  'Paynesville Elementary',
  'Paynesville Middle',
  'Payneville Elementary School',
  'Paynter El Sch',
  'Payson Center For Success - Online',
  'Payson Center For Success High School',
  'Payson Elementary School',
  'Payson High',
  'Payson High School',
  'Payson Jr High',
  'Payton College Preparatory Hs',
  'Pbl Eastlawn',
  'Pcjted - Ajo High School',
  'Pcjted - Amphitheater High School',
  'Pcjted - Andrada Polytechnic High School',
  'Pcjted - Aztec Middle College',
  'Pcjted - Aztec Middle College Desert Vista',
  'Pcjted - Aztec Middle College East',
  'Pcjted - Aztec Middle College North West',
  'Pcjted - Baboquivari High School',
  'Pcjted - Canyon Del Oro High School',
  'Pcjted - Catalina Foothills High School',
  'Pcjted - Catalina High School',
  'Pcjted - Central Campus',
  'Pcjted - Cholla High School',
  'Pcjted - Cienega High School',
  'Pcjted - Desert View High School',
  'Pcjted - Empire High School',
  'Pcjted - Flowing Wells High School',
  'Pcjted - Howenstine High School',
  'Pcjted - Ironwood Ridge High School',
  'Pcjted - Marana High School',
  'Pcjted - Mountain View High School',
  'Pcjted - Palo Verde High School',
  'Pcjted - Project More',
  'Pcjted - Pueblo High School',
  'Pcjted - Rincon High School',
  'Pcjted - Rio Rico High School',
  'Pcjted - Sabino High School',
  'Pcjted - Sahuarita High School',
  'Pcjted - Sahuaro High School',
  'Pcjted - San Manuel High School',
  'Pcjted - Santa Rita High School',
  'Pcjted - Southwest Alt. High School',
  'Pcjted - Sunnyside High School',
  'Pcjted - Tanque Verde High School',
  'Pcjted - Tapp High School',
  'Pcjted - Tucson High School',
  'Pcjted - University High School',
  'Pcjted - Vail High School',
  'Pcjted - Walden Grove High School',
  'Pcjted- Nogales High School',
  'Pcjted- Pierson High School',
  'Pcm High School',
  'Pcm Middle School',
  'Pd Chatterton Middle School',
  'Pd Graham Elementary School',
  'Pe Ell School',
  'Pea Ridge Elementary School',
  'Pea Ridge High School',
  'Pea Ridge Intermediate School',
  'Pea Ridge Manufacturing & Business Academy',
  'Pea Ridge Middle School',
  'Pea Ridge Primary School',
  'Peabody',
  'Peabody (george) Elementary',
  'Peabody Charter',
  'Peabody Elem.',
  'Peabody Elementary',
  'Peabody Elementary School',
  'Peabody Es Capitol Hill Cluster',
  'Peabody High School',
  'Peabody Magnet High School',
  'Peabody Montessori Elementary School',
  'Peabody Veterans Memorial High',
  'Peabody-burns Elementary',
  'Peabody-burns Jr/sr High School',
  'Peace Academy',
  'Peace And Education Coalition Hs',
  'Peace Center',
  'Peace College',
  'Peace Dale School',
  'Peace Garden Sp Ed Student Support Services',
  'Peace River Elementary School',
  'Peace Valley Charter School',
  'Peach County High School',
  'Peach Creek El',
  'Peach El',
  'Peach Hill Academy',
  'Peach Plains School',
  'Peach Springs School',
  'Peacham Elementary School',
  'Peachcrest Elementary School',
  'Peachland Avenue Elementary',
  'Peachland-polkton Elem',
  'Peachtree City Elementary School',
  'Peachtree Elementary',
  'Peachtree Elementary School',
  'Peachtree Middle School',
  'Peachtree Ridge High School',
  'Peak Charter Academy',
  'Peak High School',
  'Peak To Peak Charter School',
  'Peak To Peak Mountain Charter',
  'Peak View Elementary',
  'Peak Virtual Academy',
  'Peake Elementary School',
  'Peaks Island School',
  'Peaks Mill Elementary',
  'Peakview Elementary School',
  'Peakview School',
  'Pear Park Elementary School',
  'Pearblossom Elementary',
  'Pearce Elementary School',
  'Pearce H S',
  'Pearcy El',
  'Pearl',
  'Pearl Alternativeadult Education School',
  'Pearl B. Larsen Elementary School',
  'Pearl City Elementary School',
  'Pearl City High School',
  'Pearl City Highlands School',
  'Pearl City Jr High School',
  'Pearl Creek Colony Elementary - 05',
  'Pearl Creek Elementary',
  'Pearl E Rhodes Elementary',
  'Pearl Hall El',
  'Pearl Harbor Elementary School',
  'Pearl Harbor Kai Elementary School',
  'Pearl Haskew Elementary',
  'Pearl High School',
  'Pearl Junior High School',
  'Pearl Lower Elementary School',
  'Pearl M Hirsch El',
  'Pearl R. Miller Middle School',
  'Pearl Ridge Elementary School',
  'Pearl River Central High School',
  'Pearl River Central Junior High',
  'Pearl River Central Lower Elementar',
  'Pearl River Central Upper Elementar',
  'Pearl River Community College',
  'Pearl River Elementary School',
  'Pearl River High School',
  'Pearl River Middle School',
  'Pearl Sample Elementary',
  'Pearl Stephens Elementary School',
  'Pearl Street Center',
  'Pearl Upper School',
  'Pearl Watson Elementary School',
  'Pearl Zanker Elementary',
  'Pearl-cohn High',
  'Pearland H S',
  'Pearland J H East',
  'Pearland J H South',
  'Pearland J H West',
  'Pearls Hawthorne School',
  'Pearman Elementary School',
  'Pearre Creek Elementary School',
  'Pearsall H S',
  'Pearsall Int',
  'Pearsall J H',
  'Pearson Early Childhood',
  'Pearson El',
  'Pearson Elementary',
  'Pearson Elementary School',
  'Pearson Middle',
  'Pearson Middle School',
  'Pearson Ranch Middle',
  'Pearson Sixth Grade Center',
  "Pearson's Corner Elementary",
  'Pearsontown Elementary',
  'Peary Es',
  'Pease Communications/technology Academy',
  'Pease Creek Colony Elementary - 06',
  'Pease El',
  'Pease Middle',
  'Peasley Middle',
  'Peaster El',
  'Peaster H S',
  'Peaster Middle',
  'Peavine Elementary School',
  'Peavine Public School',
  'Pebble Creek El',
  'Pebble Hills El',
  'Pebble Hills H S',
  'Pebblebrook High School',
  'Pecan Creek El',
  'Pecan Grove El',
  'Pecan Grove Elementary School',
  'Pecan Grove Primary School',
  'Pecan Park Elementary School',
  'Pecan Ridge H S',
  'Pecan Springs El',
  'Pecan Trail Int',
  'Pecan Valley El',
  'Pecatonica Comm Middle School',
  'Pecatonica Elem School',
  'Pecatonica Elementary',
  'Pecatonica High',
  'Pecatonica High School',
  'Peck Community Elem School',
  'Peck El',
  'Peck Elem School',
  'Peck Elementary School',
  'Peck Jrsr High School',
  'Peck Place School',
  'Peck Slip School (the)',
  'Peckham Public School',
  'Pecos Connections Academy',
  'Pecos Elementary',
  'Pecos H S',
  'Pecos High',
  'Pecos Kind',
  'Pecos Middle',
  'Peculiar Elem.',
  'Pedagogical State University "Ion Creanga"',
  'Pedagogical University of Bydgoszcz',
  'Pedagogical University of Czestochowa',
  'Pedagogical University of Kielce',
  'Pedagogical University of Krakow',
  'Pedagogical University of Rzeszow',
  'Pedagogical University of Slupsk',
  'Pedagogical University of Zielona Gora',
  'Pedagogical University of the Polish Association for Adult Education in Warsaw',
  'Pedley Elementary',
  'Pedro Amador',
  'Pedro Bosch',
  'Pedro C. Lujan Elementary School',
  'Pedro C. Timothee Anexo',
  'Pedro Carlos Timothee',
  'Pedro Colon Santiago',
  'Pedro Falu Orellano',
  'Pedro Fidel Colberg',
  'Pedro Gutierrez',
  'Pedro J. Rodriguez Oquendo',
  'Pedro Lopez Canino',
  'Pedro Melendez Santiago',
  'Pedro Menendez High School',
  'Pedro Millan Rivera',
  'Pedro Moczo Baniet',
  'Pedro Nelson Colberg',
  'Pedro P. Casablanca',
  'Pedro Rosario Nieves',
  'Pedro Soto Rivera',
  'Pee Dee Elementary',
  'Pee Dee Math Science And Technology Academy',
  'Peebles El',
  'Peebles El Sch',
  'Peebles Elementary School',
  'Peebles High School',
  "Peek's Chapel Elementary",
  'Peekskill High School',
  'Peekskill Middle School',
  'Peeler Open Elementary',
  'Peep - Prepare',
  'Peep School - Pre School Program',
  'Peeples Elementary School',
  'Peeples Middle School',
  'Peet J H',
  'Peet Junior High School',
  'Peete El',
  'Peetz Elementary School',
  'Peetz Junior-senior High School',
  'Pegasus Charter H S',
  'Pegasus High',
  'Pegasus School',
  'Peggs Public School',
  'Peggy Heller Elementary',
  'Pegram Elementary',
  'Peifer Elementary School',
  'Peiffer Elementary School',
  'Peine Ridge Elem.',
  'Peirce',
  'Peirce Elem Intl Studies School',
  'Peirce Thomas M Sch',
  'Pekin Community High School',
  'Pekin Elementary School',
  'Pekin Middle School',
  'Pekin Preschool Family Ed Center',
  'Peking University',
  'Pelahatchie Attendance Center',
  'Pelahatchie Elementary School',
  'Pelham Academy Of Academics And Community Engagement',
  'Pelham City Middle School',
  'Pelham Elementary',
  'Pelham Elementary School',
  'Pelham Gardens Middle School',
  'Pelham High School',
  'Pelham Lab High School',
  'Pelham Memorial High School',
  'Pelham Memorial School',
  'Pelham Middle School',
  'Pelham Oaks',
  'Pelham Park Middle School',
  'Pelham Preparatory Academy',
  'Pelham Ridge',
  'Pelham Road Elementary',
  'Pelican Elementary',
  'Pelican Elementary School',
  'Pelican Island Elementary School',
  'Pelican Marsh Elementary School',
  'Pelican Rapids Alt Center Mid-level',
  'Pelican Rapids Alt Learning Center',
  'Pelican Rapids Secondary',
  'Pelican School',
  'Pelion Elementary',
  'Pelion High',
  'Pelion Middle',
  'Pell City High School',
  'Pella High School',
  'Pella Middle School',
  'Pellissippi State Community College',
  'Pellston Elementary School',
  'Pellston Middlehigh School',
  'Pemayetv Emahakv Charter Middle School',
  'Pemberton Early Childhood Education Center',
  'Pemberton Elementary',
  'Pemberton Township High School',
  'Pemberville Elementary School',
  'Pembina Spec Ed Cooperative',
  'Pembine Elementary',
  'Pembine High',
  'Pembroke Academy',
  'Pembroke Community Middle School',
  'Pembroke Elementary',
  'Pembroke Elementary School',
  'Pembroke High School',
  'Pembroke Hill School',
  'Pembroke Intermediate School',
  'Pembroke Junior-senior High School',
  'Pembroke Lakes Elementary School',
  'Pembroke Meadows Elementary',
  'Pembroke Middle',
  'Pembroke Pines Elementary School',
  'Pembroke Primary School',
  'Pembroke School',
  'Pembroke State University',
  'Pembroke Village School',
  'Pembrook Colony Elementary - 06',
  'Pemetic Elementary School',
  'Pemiscot Co Career Tech Ctr',
  'Pemiscot Co. R-iii Elem.',
  'Pen Argyl Area Hs',
  'Pena El',
  'Penacook Elementary School',
  'Penang International Dental College',
  'Penasco Elementary',
  'Penasco High',
  'Penasco Middle',
  'Pence Elementary',
  'Pend Oreille River School',
  'Pender Early College High',
  'Pender Elementary School',
  'Pender High',
  'Pender High School',
  'Pendergast Elementary School',
  'Pendergast Private Alternative School',
  'Pendergrass Fairwold School',
  'Penderlea Elementary',
  'Pendleton County High School',
  'Pendleton County Middle/high School',
  'Pendleton Early Learning Center',
  'Pendleton Elementary',
  'Pendleton Elementary School',
  'Pendleton Heights High School',
  'Pendleton Heights Middle School',
  'Pendleton High',
  'Pendleton High School',
  'Pendleton Juvenile Correctional',
  'Pendroy School',
  'Penelope School',
  'Penfield El Sch',
  'Penfield Montessori Academy',
  'Penfield Senior High School',
  'Peninsula Elementary',
  'Peninsula Elementary School',
  'Peninsula High',
  'Peninsula High (continuation)',
  'Peninsula High School',
  'Peninsula Marine Institute',
  'Peninsula Preparatory Academy Charter School',
  'Peninsula School',
  'Peninsula Union Elementary',
  'Penn (william) High School',
  'Penn Alexander Sch',
  'Penn Beach Elementary School',
  'Penn Bernville El Sch',
  'Penn Brook',
  'Penn Cambria Hs',
  'Penn Cambria Intrmd Sch',
  'Penn Cambria Ms',
  'Penn Cambria Pre-primary',
  'Penn Cambria Primary Sch',
  'Penn Elem School',
  'Penn Elementary',
  'Penn Elementary School',
  'Penn Es',
  'Penn Forest Elementary',
  'Penn High School',
  'Penn Hills Charter School Of Entrepreneu',
  'Penn Hills El Sch',
  'Penn Hills Shs',
  'Penn London El Sch',
  'Penn Manor Hs',
  'Penn Ms',
  'Penn Trafford Hs',
  'Penn Treaty Sch',
  'Penn Valley El Sch',
  'Penn Valley Sch',
  'Penn Valley Union Special Education Preschool',
  'Penn Wood El Sch',
  'Penn Wood Hs',
  'Penn Wood Ms',
  'Penn Wynne Sch',
  'Penn Yan Academy',
  'Penn Yan Elementary School',
  'Penn Yan Middle School',
  "Penn's Grove Sch",
  'Penn-grampian El Sch',
  'Penn-griffin Schools',
  'Penn-kidder Campus',
  'Penn-lincoln El Sch',
  'Pennbrook Ms',
  'Penncrest Hs',
  'Penndale Ms',
  'Pennell El Sch',
  'Pennell Joseph Sch',
  'Penney High',
  'Pennfield Dunlap Elementary',
  'Pennfield Middle School',
  'Pennfield Ms',
  'Pennfield Purdy School',
  'Pennfield Senior High School',
  'Penngrove Elementary',
  'Pennichuck Middle School',
  'Penniman Elem School',
  'Pennington Elementary',
  'Pennington Elementary School',
  'Pennington Middle',
  'Pennington School',
  'Pennoyer Elem School',
  'Pennridge Central Ms',
  'Pennridge Hs',
  'Pennridge North Ms',
  'Pennridge South Ms',
  'Penns Grove High School',
  'Penns Grove Middle School',
  'Penns Manor Area El Sch',
  'Penns Manor Area Jshs',
  'Penns Valley Area El And Intrmd Sch',
  'Penns Valley Area Jshs',
  'Pennsauken High School',
  'Pennsauken Intermediate School',
  'Pennsbury Hs',
  'Pennsville Memorial High School',
  'Pennsville Middle School',
  'Pennsylvania Ave School',
  'Pennsylvania Avenue School',
  'Pennsylvania College of Technology',
  'Pennsylvania Cyber Cs',
  'Pennsylvania Distance Learning Cs',
  'Pennsylvania Elementary School',
  'Pennsylvania Highlands Community College',
  'Pennsylvania Institute of Technology',
  'Pennsylvania Leadership Cs',
  'Pennsylvania State University',
  'Pennsylvania State University - Schuylkill Campus',
  'Pennsylvania Virtual Cs',
  'Pennville Elementary School',
  'Pennwood Ms',
  'Penny Creek Elementary',
  'Penny Road Elementary',
  'Pennypack El Sch',
  'Pennyroyal Elementary School',
  'Penobscot Elementary School',
  'Penobscot Valley High School',
  'Penquis Valley Middle/high School',
  'Penrose Elementary School',
  'Penrose Sch',
  'Penryn Elementary',
  'Pensacola Beach Elementary School Inc',
  'Pensacola Christian College',
  'Pensacola High School',
  'Pensacola State College',
  'Pensar Academy',
  'Penta Career Center - On Campus',
  'Penta Satellite',
  'Pentucket Lake Elementary',
  'Pentucket Regional Middle',
  'Pentucket Regional Sr High',
  'Pentwater Public School',
  'Penza State University',
  'People For People Cs',
  "People' s Friendship University of Russia",
  "People's University of Bangladesh",
  'Peoples Academy',
  'Peoples Academy Middle School',
  'Peoples Preparatory Charter School',
  'Peoria Accelerated High School',
  'Peoria Cty Jvnl Det Ctr 6-8',
  'Peoria Cty Jvnl Det Ctr 9-12',
  'Peoria Ecampus',
  'Peoria Elementary School',
  'Peoria Flex Academy',
  'Peoria Heights Grade School',
  'Peoria Heights High School',
  'Peoria High School',
  'Peoria Regional High School',
  'Peoria Traditional School',
  'Peosta Elementary School',
  'Peotone Elem School',
  'Peotone High School',
  'Peotone Intermediate Center',
  'Peotone Jr High School',
  'Pep - Itinerant',
  'Pep Alter School',
  'Pepe Barron Academy',
  'Pepe Barron Middle School',
  'Pepin Academies',
  'Pepin Academies Of Pasco County',
  'Pepin Elementary',
  'Pepin High',
  'Pepita Arenas',
  'Pepita Garriga',
  'Pepita Lopez',
  'Pepper Drive Elementary',
  'Pepper Elementary School',
  'Pepper Ridge Elementary',
  'Pepper Ridge Elementary School',
  'Pepper Tree Elementary',
  'Pepperdine University',
  'Pepperell Elementary',
  'Pepperell High School',
  'Pepperell Middle School',
  'Pepperell Primary',
  'Pepperhill Elementary',
  'Pequannock Township High School',
  'Pequannock Valley School',
  'Pequea El Sch',
  'Pequea Valley Hs',
  'Pequea Valley Intrmd Sch',
  'Pequenakonck Elementary School',
  'Pequot Lakes Middle',
  'Pequot Lakes Senior High',
  'Perak Islamic College',
  'Peralta Elementary',
  'Peralta School',
  'Peralta Trail Elementary School',
  'Perandoe Educational Program',
  'Perbanas Institute',
  'Perchas Diaz',
  'Percy Julian Middle School',
  'Percy L Julian School',
  'Percy Priest Elementary',
  'Percy W Neblett El',
  'Perdana University',
  'Perdew Elementary',
  'Perdido Elementary School',
  'Perdue Elementary School',
  'Peregrine Elementary School',
  'Peres Elementary',
  'Perez El',
  'Perez Elem School',
  'Perfecto Mancha El',
  'Perfor & Vis Arts H S',
  'Performance Academy Eastland',
  'Performance Based Preparatory Academy',
  'Performance Learning Center',
  'Performing Arts Academy Of The Ocean County Vocational Tech',
  'Performing Arts And Technology High School',
  'Performing Arts Community At Diego Rivera Learning Complex',
  'Performing Arts School Of Technology',
  'Perham Area Learning Center',
  'Perham Senior High',
  'Periwinkle Elementary School',
  'Periyar University',
  'Perkerson Elementary School',
  'Perkett Elementary School',
  'Perkins County Elementary Sch',
  'Perkins County Junior- Senior High School',
  'Perkins Elementary School',
  'Perkins High School',
  'Perkins K-8',
  'Perkins Middle',
  'Perkins-tryon Es',
  'Perkins-tryon Hs',
  'Perkins-tryon Intermediate Es',
  'Perkins-tryon Ms',
  'Perkinston Elementary School',
  'Perkiomen Valley Hs',
  'Perkiomen Valley Ms-east',
  'Perkiomen Valley Ms-west',
  'Perley Elementary',
  'Perley Fine Arts Academy',
  'Perlis Islamic Higher Learning Institute',
  'Perm State Academy of Agriculture',
  'Perm State Medical Academy',
  'Perm State Pedagogical University',
  'Perm State Pharmaceutical Academy',
  'Perm State Technical University',
  'Perm State University',
  'Permian H S',
  'Perpich Center For Arts Education',
  'Perquimans Central',
  'Perquimans County High',
  'Perquimans County Middle',
  'Perrin Early Learning Center',
  'Perrin El',
  'Perrin Elementary School',
  'Perrin H S',
  'Perrin Learning Ctr',
  'Perrin Woods Elementary School',
  'Perrinville Early Childhood Center',
  'Perris Elementary',
  'Perris High',
  'Perris Lake High (continuation)',
  'Perry #1 Elementary School',
  'Perry Browne Intermediate School',
  'Perry Career Academy - Eula Wilburn Ponds Perry Center For',
  'Perry Central Elementary School',
  'Perry Central High School',
  'Perry Central Jr-sr High School',
  'Perry Central Middle School',
  'Perry Co Voc Complex',
  'Perry Co. Middle',
  'Perry County Alternative Center',
  'Perry County Alternative School',
  'Perry County Central High School',
  'Perry County High School',
  'Perry Creek Elementary School',
  'Perry Early Learning Center',
  'Perry East Elementary',
  'Perry El Sch',
  'Perry Elem School',
  'Perry Elementary',
  'Perry Elementary School',
  'Perry Es',
  'Perry G Keithley Middle School',
  'Perry Hall Elementary',
  'Perry Hall High',
  'Perry Hall Middle',
  'Perry Heights Middle School',
  'Perry High School',
  'Perry Hill Elementary School',
  'Perry Hs',
  'Perry Innovation Center',
  'Perry Jhs',
  'Perry Junior High School',
  'Perry Junior-senior High School',
  'Perry L. Drew Elementary School',
  'Perry Lecompton High',
  'Perry Lower Intrmd Sch',
  'Perry Meridian 6th Grade Academy',
  'Perry Meridian High School',
  'Perry Meridian Middle School',
  'Perry Middle',
  'Perry Middle School',
  'Perry Prekindergarten',
  'Perry Primary School',
  'Perry Street Preparatory Pcs',
  'Perry W Harrison Elementary',
  'Perry Worth Elementary School',
  'Perry-lecompton Middle',
  'Perrydale School',
  'Perrymont Elementary',
  'Perrysburg High School',
  'Perrysburg Junior High School',
  'Perrysburg Preschool',
  'Perryton H S',
  'Perryton J H',
  'Perryton Kinder',
  'Perryville Area Car Tech Ctr',
  'Perryville Elem.',
  'Perryville Elementary',
  'Perryville Elementary School',
  'Perryville High',
  'Perryville High School',
  'Perryville Middle',
  'Perryville School',
  'Perryville Sr. High',
  'Perrywood Elementary',
  'Persell Middle School',
  'Perseus House Cs Of Excellence',
  'Pershing Co Adult',
  'Pershing Continuation High',
  'Pershing County High School (and Alternative Ed)',
  'Pershing County Middle School',
  'Pershing Early Learning Center',
  'Pershing El',
  'Pershing Elem Humanities Magnet',
  'Pershing Elem School',
  'Pershing Elem.',
  'Pershing Elementary',
  'Pershing Elementary School',
  'Pershing Es',
  'Pershing High School',
  'Pershing Hill Elementary',
  'Pershing Middle',
  'Pershing Park El',
  'Pershing Pine Castle Elementary',
  'Persian Gulf University',
  'Person Early College Innovation & Ldrshp',
  'Person High',
  'Perspectives Charter High School',
  'Perspectives Chtr - Hs Of Tech',
  'Perspectives Chtr - Iit Campus',
  'Perspectives Chtr - Joslin Campus',
  'Perspectives Chtr - Leadership Ac',
  'Perth Amboy High School',
  'Pertzsch Elementary',
  'Peru Elementary School',
  'Peru High School',
  'Peru Jr/sr High School',
  'Peru Junior High School',
  'Peru State College',
  'Peruvian Park School',
  'Pes University',
  'Pesa Parcelas',
  'Pescadero Elementary And Middle',
  'Pescadero High',
  'Peshastin Dryden Elementary',
  'Peshtigo Elementary',
  'Peshtigo High',
  'Peshtigo Middle',
  'Pesson Addition Elementary School',
  'Petal Elementary',
  'Petal High School',
  'Petal Middle School',
  'Petal Primary School',
  'Petal Upper Elementary School',
  'Petaluma Accelerated Charter',
  'Petaluma High',
  'Petaluma Junior High',
  'Pete Gallego Elementary',
  'Pete Mirich Elementary School',
  'Peter Boe Jr Elementary School',
  'Peter Burnett Elementary',
  'Peter Burnett Middle',
  'Peter Coleman Elementary School',
  'Peter Cooper School',
  'Peter E Hyland Center',
  'Peter F Alba Middle School',
  'Peter Fitzpatrick School',
  'Peter G Schmidt Elementary',
  'Peter Hobart Elementary',
  'Peter J Kirksey Career Center',
  'Peter J Palombi School',
  'Peter J. Shields Elementary',
  'Peter Johansen High',
  'Peter M Gombert Elementary Sch',
  'Peter Muhlenberg Middle',
  'Peter Muschal Elementary',
  'Peter Noyes',
  'Peter Pendleton Elementary',
  'Peter S Ogden Elementary',
  'Peter Sarpy Elementary School',
  'Peter Thacher Elementary School',
  'Peter W Reilly',
  'Peter Woodbury School',
  'Peterboro Street Elementary School',
  'Peterborough Elementary School',
  'Peters Canyon Elementary',
  'Peters Colony El',
  'Peters El Sch',
  'Peters Elementary School',
  'Peters Es',
  'Peters Twp Hs',
  'Peters Twp Ms',
  'Petersburg Daep',
  'Petersburg Elem School',
  'Petersburg Elementary School',
  'Petersburg High',
  'Petersburg High School',
  'Petersburg Primary',
  'Petersburg Regional Alternative',
  'Petersburg School',
  'Petersen Alternative Center For Education',
  'Petersen El',
  'Petersham Center',
  'Peterson Academic Center',
  'Peterson Elem',
  'Peterson Elem School',
  'Peterson Elementary',
  'Peterson Elementary School',
  'Peterson Middle',
  'Peterson-central Elementary School',
  'Peterstown Elementary School',
  'Peterstown Middle School',
  'Petoskey High School',
  'Petoskey Middle School',
  'Petoskey Montessori Elem School',
  'Petra Corretjer De Oneill',
  'Petra Mercado Bougart',
  'Petra Roman Vigo',
  'Petra Zenon De Fabery',
  'Petree Elementary',
  'Petro Mohyla Black Sea State University',
  'Petroamerica Pagan',
  'Petroglyph Elementary',
  'Petroleum University of Technology',
  'Petrolia El',
  'Petrolia Junior High/high School',
  'Petronas Technology University',
  'Petronila El',
  'Petros Joyner Elementary',
  'Petrosky El',
  'Petrova Elementary School',
  'Petrozavodsk State University',
  'Petru Maior University of Targu Mures',
  'Petsworth Elementary',
  'Pettis Co. Elem.',
  'Pettisville Elementary School',
  'Pettisville High School',
  'Pettit Park School',
  'Pettus El',
  'Pettus Secondary',
  'Pevely Elem.',
  'Pewamowestphalia Elementary School',
  'Pewamowestphalia Middlehigh School',
  'Pewaukee High',
  'Pewaukee Lake Elementary',
  'Pewitt El',
  'Pewitt H S',
  'Pewitt J H',
  'Peyton Elementary School',
  'Peyton Forest Elementary School',
  'Peyton Junior High School',
  'Peyton Online Academy',
  'Peyton Senior High School',
  'Pfaff El Sch',
  'Pfc Mario Ybarra El',
  'Pfc Robert P Hernandez Middle',
  'Pfc William J Grabiarz #79',
  'Pfeiffer Elementary School',
  'Pfeiffer Intermediate School',
  'Pfeiffer-burleigh Sch',
  'Pflugerville El',
  'Pflugerville H S',
  'Pflugerville Middle',
  'Phalen At Francis Scott Key 103',
  'Phalen Lake Hmong Studies Magnet',
  'Phalen Leadership Academy - In Inc',
  'Pham Ngoc Thach University of Medicine',
  'Phantom Knight School Of Opportunity',
  'Phantom Lake Elementary',
  'Pharos International University',
  'Pharr Elementary School',
  'Phase',
  'Pheasant Point Elem.',
  'Pheasant Ridge Primary School',
  'Phelan Elementary',
  'Phelps Architecture Construction And Engineering Hs',
  'Phelps Co. Elem.',
  'Phelps Day Treatment',
  'Phelps Elementary',
  'Phelps Elementary School',
  'Phelps Gifted Ctr.',
  'Phelps High',
  'Phelps High School',
  'Phelps Luck Elementary',
  'Phenix City Elementary School',
  'Phenix City Intermediate School',
  'Phenix Elementary',
  'Phil Campbell Elementary School',
  'Phil Campbell High School',
  'Phil D. Swing Elementary',
  'Phil Snowdon Elementary',
  'Philadelphia Academy Cs',
  'Philadelphia Electrical & Tech Chs',
  'Philadelphia Elementary',
  'Philadelphia Elementary School',
  'Philadelphia High School',
  'Philadelphia Learning Academy - North',
  'Philadelphia Learning Academy - South',
  'Philadelphia Middle School',
  'Philadelphia Military Acad At Elverson',
  'Philadelphia Montessori Cs',
  'Philadelphia Performing Arts Cs',
  'Philadelphia Primary School',
  'Philadelphia University',
  'Philander Lee Elementary School',
  'Philip Barbour High School Complex',
  'Philip Elementary - 02',
  'Philip G Coburn',
  'Philip G Vroom Community School',
  'Philip High School - 01',
  'Philip J Rock Center And School',
  'Philip J Schuyler Achievement Academy',
  'Philip J Weaver Ed Center',
  'Philip J. Reilly Elementary/special Education',
  'Philip Jr. High - 03',
  'Philip Magruder Middle',
  "Philip O'brien Elementary School",
  'Philip R. Smith School',
  'Philip Simmons Elementary',
  'Philip Simmons High',
  'Philip Simmons Middle',
  'Philip W Sugg Middle School',
  "Philip's Academy Charter School Of Paterson",
  'Philippi Elementary School',
  'Philippi Middle School',
  'Philippine Christian University',
  'Philippine Military Academy',
  'Philippine Normal University',
  "Philippine Women's University",
  'Philipsburg 7-8',
  'Philipsburg El Sch',
  'Philipsburg Primary School',
  'Philipsburg School',
  'Philipsburg-osceola Area Hs',
  'Philipsburg-osceola Area Ms',
  'Phillip J Patino School Of Entrepreneurship',
  'Phillip M. Stokoe Elementary',
  'Phillip O Berry Academy Of Technology',
  'Phillip Sharp Middle School',
  "Phillip's Academy Charter School",
  'Phillippi Shores Elementary School',
  'Phillips Academy',
  'Phillips Academy High School',
  'Phillips Avenue School',
  'Phillips Community Career & Tech',
  'Phillips Community College of the University of Arkansas',
  'Phillips El',
  'Phillips El Sch',
  'Phillips Elementary',
  'Phillips Elementary School',
  'Phillips High',
  'Phillips High School',
  'Phillips Middle',
  'Phillips Preparatory Middle School',
  'Phillips University',
  'Phillips-Universität Marburg',
  'Phillipsburg Early Childhood Learning Center',
  'Phillipsburg Elem',
  'Phillipsburg Elementary School',
  'Phillipsburg High',
  'Phillipsburg High School',
  'Phillipsburg Middle',
  'Phillipsburg Middle School',
  'Phillipston Memorial',
  'Phillis Wheatley Community School',
  'Phillis Wheatley Elem.',
  'Phillis Wheatley Elementary School',
  'Philo High School',
  'Philo Junior High School',
  'Philo T Farnsworth Elementary School',
  'Philo T. Farnsworth School',
  'Philo-hill Magnet Academy',
  'Philomath Elementary School',
  'Philomath High School',
  'Philomath Middle School',
  'Philosophisch-Theologische Hochschule Münster',
  'Philosophisch-Theologische Hochschule SVD Sankt Augustin',
  'Philosophisch-Theologische Hochschule Sankt Georgen',
  'Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)',
  'Philosophisch-Theologische Hochschule der Salesianer Don Boscos',
  'Philosophisch-Theologisches Studium Erfurt Staatlich anerkannte Wissenschaftliche Hochschule',
  'Phineas Banning Senior High',
  'Phineas Bates',
  'Phinney Elem',
  'Phippsburg Elementary School',
  'Phoebe A. Hearst Elementary',
  'Phoebe Apperson Hearst Elementary',
  'Phoebe Hearst School',
  'Phoebus High',
  'Phoenicia Elementary School',
  'Phoenix',
  'Phoenix Academy',
  'Phoenix Academy Charter School',
  'Phoenix Academy Community Day',
  'Phoenix Academy Community School',
  'Phoenix Academy High',
  'Phoenix Academy Lawrence',
  'Phoenix Academy Public Charter High School Springfield',
  'Phoenix Academy Residential Education Center',
  'Phoenix Academy- Primary Elem. Ib Myp',
  'Phoenix Advantage Charter School',
  'Phoenix Alternative High School',
  'Phoenix Alternative School',
  'Phoenix Center',
  'Phoenix Charter Academy',
  'Phoenix Charter High',
  'Phoenix Chic - Morton Ave',
  'Phoenix Coding Academy',
  'Phoenix College',
  'Phoenix College Preparatory Academy',
  'Phoenix Collegiate Academy Charter School',
  'Phoenix Collegiate Academy Elementary Llc',
  'Phoenix Collegiate Academy High School',
  'Phoenix Community Learning Ctr',
  'Phoenix Continuation',
  'Phoenix Elementary Academy Community Day',
  'Phoenix Elementary School',
  'Phoenix Elementarymiddle School',
  'Phoenix H S',
  'Phoenix High',
  'Phoenix High (continuation)',
  'Phoenix High Community Day',
  'Phoenix High School',
  'Phoenix Learning Center',
  'Phoenix Magnet Elementary School',
  'Phoenix Middle',
  'Phoenix Middle School',
  'Phoenix Military Academy Hs',
  'Phoenix Prep Academy',
  'Phoenix Program',
  'Phoenix Project',
  'Phoenix Recovery Programs',
  'Phoenix Rising High',
  'Phoenix School',
  'Phoenix School At Southwest Schools',
  'Phoenix Secondary',
  'Phoenix Thomas J Pappas Regional Elementary School',
  'Phoenix Thomas J. Pappas Regional Middle School',
  'Phoenix Union Bioscience High School',
  'Phoenix Union Cyber High School',
  'Phoenix Union-wilson College Preparatory',
  'Phoenixville Area Hs',
  'Phoenixville Area Kdg Ctr',
  'Phoenixville Area Ms',
  'Phoenixville Early Learning Ctr',
  'Phranakhon Si Ayutthaya Rajabhat University',
  'Phyllis E. Williams Elementary',
  'Phyllis R. Miller Elementary School',
  'Phyllis Wheatley Elementary',
  'Phyllis Wheatley Elementary School',
  'Physical Education Academy "Eugeniusz Piasecki" in Poznan',
  'Physical Education Academy "Jedrzej Sniadecki" in Gdansk',
  'Physical Education Academy "Jozef Pilsudski" in Warsaw',
  'Physical Education Academy in Katowice',
  'Physical Education Academy in Wroclaw',
  'Pi Beta Phi Elementary',
  'Pi Program',
  'Picacho Middle',
  'Picacho School',
  'Picadome Elementary School',
  'Picard Community Day Elementary',
  'Picayune Junior High School',
  'Picayune Memorial High School',
  'Piccolo Elem Specialty School',
  'Piccowaxen Middle School',
  'Pick Elementary School',
  'Pickard Elem School',
  'Pickaway Elementary School',
  'Pickaway-ross Career & Technology Center',
  'Pickens County Career And Technology Center',
  'Pickens County High School',
  'Pickens County Middle School',
  'Pickens Elementary',
  'Pickens Elementary/high School',
  'Pickens High',
  'Pickens Middle',
  'Pickering Elementary School',
  'Pickering High School',
  'Pickering Middle',
  'Pickering Valley El Sch',
  'Pickerington Alternative School',
  'Pickerington Community School',
  'Pickerington Elementary School',
  'Pickerington High School Central',
  'Pickerington High School North',
  'Pickerington Lakeview Junior High',
  'Pickerington Ridgeview Junior High School',
  'Pickett Co High School',
  'Pickett County Elementary',
  'Pickett Elem.',
  'Pickett Elementary School',
  "Pickett's Mill Elementary",
  'Pickford Public Schools',
  'Pickle El',
  'Pickwick Southside School',
  'Picnic Point Elementary',
  'Pico Canyon Elementary',
  'Picolata Crossing Elementary School',
  'Picotte Elementary School',
  'Picture Rocks Elementary',
  'Picture Rocks Intermediate School',
  'Pieceful Solutions Charter School',
  'Pied Piper School',
  'Piedmont Alternative',
  'Piedmont Avenue Elementary',
  'Piedmont Classical High School',
  'Piedmont Community Charter School',
  'Piedmont Community College',
  'Piedmont Elementary',
  'Piedmont Elementary School',
  'Piedmont Es',
  'Piedmont Global Academy',
  "Piedmont Governor's School",
  'Piedmont High',
  'Piedmont High School',
  'Piedmont Hills High',
  'Piedmont Hs',
  'Piedmont Ib Middle',
  'Piedmont Intermediate Es',
  'Piedmont Lakes Middle',
  'Piedmont Middle',
  'Piedmont Middle School',
  'Piedmont Ms',
  'Piedmont Preschool',
  'Piedmont Primary Es',
  'Piedmont Reg Ed/ivy Creek',
  'Piedmont Regional Juvenile Detention Center',
  'Piedmont Technical College',
  'Piedmont Valley Elementary - 05',
  'Piedmont Virginia Community College',
  'Piedmont Year-round Education',
  'Piedra Vista High',
  'Pieper Ranch Middle',
  'Pier Elementary',
  'Pierce',
  'Pierce City High',
  'Pierce City Middle',
  'Pierce College at Puyallup',
  'Pierce County High School',
  'Pierce County Middle School',
  'Pierce County Skills Center',
  'Pierce Downer Elem School',
  'Pierce Early Childhood',
  'Pierce El',
  'Pierce Elementary',
  'Pierce Elementary School',
  'Pierce Es',
  'Pierce Hammock Elementary School',
  'Pierce High',
  'Pierce Jr/sr High School',
  'Pierce Middle School',
  'Pierce Park Elementary School',
  'Pierce School',
  'Pierce Street Elementary School',
  'Pierceton Elementary School',
  'Piermont Village School',
  'Pierpont Community and Technical College',
  'Pierpont Elementary',
  'Pierre A. Capdau Learning Academy',
  'Pierre Indian Learning Center',
  'Pierre Moran Middle School',
  'Pierre Part Middle School',
  'Pierre Part Primary School',
  'Pierre Van Cortlandt School',
  'Pierremont Elem.',
  'Pierrepont School',
  'Pierson Elementary School',
  'Pierson Middle School',
  'Pierson Middle/high School',
  'Pierson Vocational High School',
  'Pierz Alternative Program',
  'Pieter B Coeymans School',
  'Pietzsch/mac Arthur El',
  'Pigeon Forge High School',
  'Pigeon Forge Middle School',
  'Pigeon Forge Primary',
  'Pigeon River Elementary',
  'Piggott Academy Of International Studies',
  'Piggott Elementary School',
  'Piggott High School',
  'Pike Central High School',
  'Pike Central Middle School',
  'Pike County Alternative Learning Center',
  'Pike County Central High School',
  'Pike County Day Treatment',
  'Pike County Elementary School',
  'Pike County High School',
  'Pike County Middle School',
  'Pike County Primary School',
  'Pike Elementary School',
  'Pike High School',
  'Pike Lake Elementary',
  'Pike Lake Kindergarten Center',
  'Pike Road Elementary School',
  'Pike Road High School',
  'Pike Road Historic School',
  'Pike Valley Elem',
  'Pike Valley High',
  'Pike Valley Jr High',
  'Pike-delta-york High School',
  'Pike-delta-york Middle School',
  'Pike/lincoln Technical Ctr.',
  'Pikeland Community School',
  'Pikes Peak Community College',
  'Pikes Peak Early College',
  'Pikes Peak Elementary School',
  'Pikes Peak Online School',
  'Pikes Peak Prep',
  'Pikes Peak School Expeditionary Learning',
  'Pikesville High',
  'Pikesville Middle',
  'Piketon Jr/sr High School',
  'Pikeview High School',
  'Pikeview Middle School',
  'Pikeville Elementary',
  'Pikeville Elementary School',
  'Pikeville High School',
  'Pilarcitos Alternative High (continuation)',
  'Pilgrim Academy',
  'Pilgrim High School',
  'Pilgrim Park Middle',
  'Pillager Area Charter School',
  'Pillager Elementary',
  'Pillager Middle School',
  'Pillager Senior High School',
  'Pillans Middle School',
  'Pillar Academy Of Business & Finance',
  'Pillar Academy Online',
  'Pillar Falls Elementary',
  'Pillow El',
  'Pillsbury Elementary',
  'Pilot Butte Elementary',
  'Pilot Butte Middle School',
  'Pilot Elementary',
  'Pilot Grove Elem.',
  'Pilot Grove High',
  'Pilot Knob Stem Magnet School',
  'Pilot Mountain Elementary',
  'Pilot Mountain Middle',
  'Pilot Point El',
  'Pilot Point H S',
  'Pilot Point Int',
  'Pilot Point School',
  'Pilot Point Selz Middle',
  'Pilot Rock Elementary School',
  'Pilot Rock High School',
  'Pilot Station School',
  'Pilsen Elem Community Academy',
  'Pim Arts High School',
  'Pima Butte Elementary School',
  'Pima Community College',
  'Pima County At Walden Grove',
  'Pima County Jted',
  'Pima County Jted Annex',
  'Pima County Jted At Amphi Land Lab',
  'Pima County Jted At Camino Seco',
  'Pima County Jted At Empire',
  'Pima County Jted At Flowing Wells',
  'Pima County Jted At Indian Oasis',
  'Pima County Jted At Santa Rita',
  'Pima County Jted At Star',
  'Pima County Jted Cosmetology',
  'Pima County Jted Nw Campus',
  'Pima Elementary School',
  'Pima High School',
  'Pima Junior High School',
  'Pima Partnership Academy',
  'Pima Partnership School The',
  'Pima Prevention Partnership-tucson',
  'Pima Rose Academy',
  'Pima Vocational High School',
  'Pimlico Elementary',
  'Pimmit E.c. Resouce Ctr.',
  'Pin Oak Elementary',
  'Pin Oak Middle',
  'Pinacate Middle',
  'Pinar Elementary',
  'Pinas Arriba',
  'Pinch Elementary School',
  'Pinchbeck Elementary',
  'Pinckney Community High School',
  'Pinckney Elem',
  'Pinckneyville Comm High School',
  'Pinckneyville Elem School',
  'Pinckneyville Middle School',
  'Pinconning Central Elementary School',
  'Pinconning High School',
  'Pinconning Middle School',
  'Pine Acres Early Childhood Center',
  'Pine Avenue Elementary School',
  'Pine Beach Elementary School',
  'Pine Bend Elementary',
  'Pine Bluff High School',
  'Pine Bluff Lighthouse Academy',
  'Pine Bluff Lighthouse College Prep Academy High School',
  'Pine Bluffs Elementary',
  'Pine Bluffs Jr & Sr High School',
  'Pine Brook Elementary School',
  'Pine Brook School',
  'Pine Bush Elementary School',
  'Pine Bush Senior High School',
  'Pine Butte Elementary Sch',
  'Pine Castle Elementary',
  'Pine City Area Learning Center',
  'Pine City Elementary',
  'Pine City School',
  'Pine City Secondary',
  'Pine County Transition Program',
  'Pine Creek 6-8',
  'Pine Creek Elementary',
  'Pine Creek Elementary School',
  'Pine Creek High School',
  'Pine Creek School',
  'Pine Crest Elementary',
  'Pine Crest Elementary School',
  'Pine Eagle Charter School',
  'Pine Elem/jr High School',
  'Pine Elementary',
  'Pine Elementary School',
  'Pine Estates Elementary School',
  'Pine Forest El',
  'Pine Forest Elementary School',
  'Pine Forest High',
  'Pine Forest High School',
  'Pine Forest Middle',
  'Pine Forest School',
  'Pine Forge El Sch',
  'Pine Glen Elementary',
  'Pine Grove',
  'Pine Grove Area Hs',
  'Pine Grove Area Ms',
  'Pine Grove El Sch',
  'Pine Grove Elementary',
  'Pine Grove Elementary School',
  'Pine Grove Elementary Stem Magnet',
  'Pine Grove High School',
  'Pine Grove Manor School',
  'Pine Grove Middle',
  'Pine Grove Middle School',
  'Pine Grove School',
  'Pine Grove Youth Conservation Camp',
  'Pine Hall Elementary',
  'Pine Haven Elementary',
  'Pine Haven Elementary School',
  'Pine Hill',
  'Pine Hill Education Center',
  'Pine Hill Elementary',
  'Pine Hill Middle School',
  'Pine Hill Schools',
  'Pine Hills Elementary',
  'Pine Hills Elementary School',
  'Pine Hills Youth Corr Facil El',
  'Pine Hills Youth Corr Facil Hs',
  'Pine Hollow Middle',
  'Pine Island Elementary',
  'Pine Island Elementary School',
  'Pine Island High School Alp',
  'Pine Island Middle',
  'Pine Island Secondary',
  'Pine Jog Elementary School',
  'Pine Knob Elementary School',
  'Pine Knot Elementary School',
  'Pine Knot Intermediate School',
  'Pine Knot Primary School',
  'Pine Lake Elementary School',
  'Pine Lake Middle School',
  'Pine Lake Preparatory',
  'Pine Lane Elementary',
  'Pine Level Elementary',
  'Pine Level Elementary School',
  'Pine Log Elementary',
  'Pine Meadow Elementary',
  'Pine Meadow Elementary School',
  'Pine Mountain Middle School',
  'Pine Park Elementary School',
  'Pine Point Elementary',
  'Pine Prairie High School',
  'Pine Rest Kentwood Public Schools',
  'Pine Ridge',
  'Pine Ridge Alternative Center',
  'Pine Ridge El',
  'Pine Ridge Elementary',
  'Pine Ridge Elementary School',
  'Pine Ridge High School',
  'Pine Ridge Job Corps',
  'Pine Ridge Middle',
  'Pine Ridge Middle School',
  'Pine Ridge School',
  'Pine River Area Elementary School',
  'Pine River Area Middlehigh School',
  'Pine River Area Upper Elementary School',
  'Pine River Correctional Facility',
  'Pine River Elementary School',
  'Pine River School For Young Learners (prsyl)',
  'Pine River-backus Area Learning Ctr',
  'Pine River-backus Elementary',
  'Pine River-backus High School',
  'Pine River-backus Midlevel Alc',
  'Pine Road El Sch',
  'Pine Run El Sch',
  'Pine School',
  'Pine Shadows El',
  'Pine Spring Elementary',
  'Pine Springs Day School',
  'Pine Springs Prep Academy',
  'Pine St. Elementary',
  'Pine Strawberry Elementary School',
  'Pine Street El Sch',
  'Pine Street Elementary',
  'Pine Street Elementary School',
  'Pine Technical College',
  'Pine To Prairie Coop. Ctr.',
  'Pine Trail Elementary School',
  'Pine Trails Elementary School',
  'Pine Tree Daep',
  'Pine Tree Elementary School',
  'Pine Tree H S',
  'Pine Tree Hill Elementary',
  'Pine Tree J H',
  'Pine Tree Middle',
  'Pine Tree Pri',
  'Pine Valley Central Junior-senior High School',
  'Pine Valley Elementary',
  'Pine Valley Elementary School',
  'Pine Valley Middle',
  'Pine View Elementary',
  'Pine View Elementary School',
  'Pine View High',
  'Pine View Middle',
  'Pine View Middle School',
  'Pine View School',
  'Pine Villa Elementary School',
  'Pine Village Elementary School',
  'Pine Wood Elementary School',
  'Pine-richland Hs',
  'Pine-richland Ms',
  'Pineapple Cove Classical Academy',
  'Pineapple Cove Classical Academy West Melbourne',
  'Pinebrook Elementary',
  'Pinecreek El Sch',
  'Pinecrest Academy (north Campus)',
  'Pinecrest Academy (south Campus)',
  'Pinecrest Academy Charter Middle School',
  'Pinecrest Academy Inspirada',
  'Pinecrest Academy Of Nevada Cadence',
  'Pinecrest Academy Of Nevada Horizon',
  'Pinecrest Academy St Rose',
  'Pinecrest Collegiate Academy Charter',
  'Pinecrest Cove Academy',
  'Pinecrest Creek Charter',
  'Pinecrest Elementary',
  'Pinecrest Elementary School',
  'Pinecrest Elementary/middle School',
  'Pinecrest Glades Academy',
  'Pinecrest Glades Preparatory Academy Middle High School',
  'Pinecrest High',
  'Pinecrest Lakes Academy',
  'Pinecrest Preparatory Academy',
  'Pinecrest Preparatory Academy Charter High School',
  'Pinecrest Preparatory Charter',
  'Pinecrest School',
  'Pinecrest Supports & Services Center',
  'Pinedale Elementary',
  'Pinedale Elementary - 11',
  'Pinedale Elementary School',
  'Pinedale High School',
  'Pinedale Middle School',
  'Pineforest El',
  'Pinehill Alternative High School',
  'Pinehurst Early Elementary',
  'Pinehurst Elementary',
  'Pinehurst Elementary School',
  'Pinelands',
  'Pinelands Regional High School',
  'Pinelands Regional Junior High School',
  'Pinellas Academy Of Math And Science',
  'Pinellas Central Elementary School',
  'Pinellas County Jail',
  'Pinellas Gulf Coast Academy',
  'Pinellas Juvenile Det Center',
  'Pinellas Mycroschool Of Integrated Academics And Technologie',
  'Pinellas Park Elementary School',
  'Pinellas Park High School',
  'Pinellas Park Middle School',
  'Pinellas Preparatory Academy',
  'Pinellas Primary Academy',
  'Pinellas Secondary School',
  'Pinellas Technical College - Clearwater',
  'Pinellas Technical College - St Petersburg',
  'Pinellas Technical High School At Seminole',
  'Pinellas Teleschool',
  'Pinellas Virtual Franchise',
  'Pinellas Virtual Instruction (course Offerings)',
  'Pinellas Virtual Instruction Program',
  'Pinellas Virtual K-12',
  'Pinellas Westcoast Academy High School',
  'Pinello Elementary School',
  'Pineloch Elementary',
  'Pinemount Elementary School',
  'Piner Elementary School',
  'Piner High',
  'Piner Middle',
  'Piner-olivet Charter',
  'Pines Elementary',
  'Pines Elementary School',
  'Pines Lake Elementary School',
  'Pines Lakes Elementary School',
  'Pines Middle School',
  'Pines School - Acs',
  'Pines School - Nsp',
  'Pines School - Rjc',
  'Pines School Day Program',
  'Pinetree Community Elementary',
  'Pinetta Elementary School',
  'Pinevale Elementary School',
  'Pineview Elementary',
  'Pineview Elementary School',
  'Pineview Homes',
  'Pineville Elem.',
  'Pineville Elementary',
  'Pineville Elementary School',
  'Pineville High School',
  'Pineville Independent School',
  'Pineville Junior High School',
  'Pineville Middle School',
  'Pineville Primary',
  'Pinewood Avenue Elementary',
  'Pinewood El.',
  'Pinewood Elem School',
  'Pinewood Elementary',
  'Pinewood Elementary - Snw',
  'Pinewood Elementary School',
  'Pinewood Middle School',
  'Pinewood School',
  'Pinewoods Elementary School',
  'Piney Branch Elementary',
  'Piney Chapel Elementary School',
  'Piney Creek Elementary',
  'Piney Grove Elementary',
  'Piney Grove Middle',
  'Piney Grove Middle School',
  'Piney Orchard Elementary',
  'Piney Point El',
  'Piney Point Elementary',
  'Piney Ridge Elementary',
  'Piney Woods El',
  'Pineywoods Aec Of Choice',
  'Pineywoods Community Academy H S',
  'Pingree-buchanan Elementary School',
  'Pingree-buchanan High School',
  'Pink El',
  'Pink Hill Elementary',
  'Pink School',
  'Pinkerton Academy',
  'Pinkerton El',
  'Pinkham Elementary',
  'Pinkston Middle School',
  'Pinkston Street Elementary',
  'Pinnacle Academy',
  'Pinnacle Academy Charter - Independent Study',
  'Pinnacle Academy Inc.',
  'Pinnacle Canyon Academy',
  'Pinnacle Charter High School',
  'Pinnacle Classical Academy',
  'Pinnacle Elementary',
  'Pinnacle Elementary School',
  'Pinnacle High School',
  'Pinnacle High School - Casa Grande',
  'Pinnacle High School - Tempe',
  'Pinnacle Int',
  'Pinnacle Online - Wmcb',
  'Pinnacle Online High School',
  'Pinnacle Peak Preparatory',
  'Pinnacle Pointe Academy',
  'Pinnacle View Middle School',
  'Pinnacles Community',
  'Pinnacles High',
  'Pinole Middle',
  'Pinole Valley High',
  'Pinon Accelerated Middle School',
  'Pinon Community School',
  'Pinon Elementary',
  'Pinon Elementary School',
  'Pinon High School',
  'Pinon Hills Elementary',
  'Pinon Mesa Middle',
  'Pinon Valley Elementary School',
  'Pinson Elementary School',
  'Pinson Valley High School',
  'Pintlala Elementary School',
  'Pio Pico Elementary',
  'Pio Pico Middle',
  'Pioche Elementary School',
  'Pioneer',
  'Pioneer (alternative) High School',
  'Pioneer Academy',
  'Pioneer Bilingual Elementary School',
  'Pioneer Career & Technology',
  'Pioneer Career Academy',
  'Pioneer Charter School Of Science',
  'Pioneer Charter School Of Science Ii (pcss-ii)',
  'Pioneer Community Day',
  'Pioneer Continuation High',
  'Pioneer Crossing El',
  'Pioneer Drive Elementary',
  'Pioneer El',
  'Pioneer Elem School',
  'Pioneer Elementary',
  'Pioneer Elementary School',
  'Pioneer Es',
  'Pioneer Grove Educational Center',
  'Pioneer Headstart',
  'Pioneer Heritage Middle',
  'Pioneer High',
  'Pioneer High (continuation)',
  'Pioneer High School',
  'Pioneer High School For The Performing Arts',
  'Pioneer Intermediate Es',
  'Pioneer International University',
  'Pioneer Jr-sr High School',
  'Pioneer Junior High',
  'Pioneer Magnet School For The Visual And Performing Arts',
  'Pioneer Middle',
  'Pioneer Middle School',
  'Pioneer Park Elementary',
  'Pioneer Park Elementary School',
  'Pioneer Park Es',
  'Pioneer Path',
  'Pioneer Peak Elementary',
  'Pioneer Preparatory - A Challenge Foundation',
  'Pioneer Primary School',
  'Pioneer Public School',
  'Pioneer Ridge Elementary School',
  'Pioneer Ridge Middle',
  'Pioneer Ridge Middle School',
  'Pioneer School',
  'Pioneer School Of The Arts',
  'Pioneer Secondary Alternative High School',
  'Pioneer Senior High School',
  'Pioneer Springs Community School',
  'Pioneer Tech High School',
  'Pioneer Technical Center',
  'Pioneer Technology (ptaa) Greenville Middle',
  'Pioneer Technology (ptaa) Mesquite Middle',
  'Pioneer Trail Elementary',
  'Pioneer Trail Middle School',
  'Pioneer Valley Chinese Immersion Charter School',
  'Pioneer Valley Elementary',
  'Pioneer Valley High',
  'Pioneer Valley Performing Arts Charter Public School',
  'Pioneer Valley Preschool',
  'Pioneer Valley Regional',
  'Pioneer Work And Learn Center',
  'Pioneer-pleasant Vale Es',
  'Pioneer-pleasant Vale Hs',
  'Pioneer-pleasant Vale Jhs',
  'Pipe Creek Elementary School',
  'Piper East Elementary School',
  'Piper Elementary School',
  'Piper High',
  'Piper High School',
  'Piper Middle',
  'Piper School',
  'Pipestone Learning Center',
  'Pipestone Middle',
  'Pipestone Senior High',
  'Pipkin Middle',
  'Piqua Central Intermediate School',
  'Piqua High School',
  'Piqua Junior High School',
  'Piri Reis University',
  'Pirie Elem Fine Arts & Academic C',
  'Pirrung El',
  'Piru Elementary',
  'Piscataquis Community Elementary',
  'Piscataquis Community Secondary Sch',
  'Piscataquis Community Secondary School',
  'Piscataway Regional Day School',
  'Piscataway Township High School',
  'Pisgah Elementary',
  'Pisgah Elementary School',
  'Pisgah Forest Elementary',
  'Pisgah High',
  'Pisgah High School',
  'Pistor Middle School',
  'Pitahaya',
  'Pitcher Elem.',
  'Pitkin High School',
  'Pitman High School',
  'Pitman Middle School',
  'Pitner Elementary School',
  'Pitt Community College',
  'Pittman Center Elementary',
  'Pittman Charter',
  'Pittman El',
  'Pittman Elem.',
  'Pittman Elementary Leadership Academy',
  'Pitts Elementary School',
  'Pitts School Road Elementary',
  'Pittsboro Elementary',
  'Pittsboro Elementary School',
  'Pittsboro Primary School',
  'Pittsburg El',
  'Pittsburg Es',
  'Pittsburg H S',
  'Pittsburg High',
  'Pittsburg Hs',
  'Pittsburg Int',
  'Pittsburg J H',
  'Pittsburg Middle School',
  'Pittsburg Pri',
  'Pittsburg School (elem)',
  'Pittsburg School (high)',
  'Pittsburg Senior High',
  'Pittsburg State University',
  'Pittsburgh Allderdice Hs',
  'Pittsburgh Allegheny 6-8',
  'Pittsburgh Allegheny K-5',
  'Pittsburgh Arlington K-8',
  'Pittsburgh Arsenal 6-8',
  'Pittsburgh Arsenal K-5',
  'Pittsburgh Banksville K-5',
  'Pittsburgh Beechwood K-5',
  'Pittsburgh Brashear Hs',
  'Pittsburgh Brookline K-8',
  'Pittsburgh Capa 6-12',
  'Pittsburgh Carmalt K-8',
  'Pittsburgh Carrick Hs',
  'Pittsburgh Chartiers Early Childhood Ctr',
  'Pittsburgh Classical 6-8',
  'Pittsburgh Colfax K-8',
  'Pittsburgh Concord K-5',
  'Pittsburgh Conroy',
  'Pittsburgh Dilworth K-5',
  'Pittsburgh Faison K-5',
  'Pittsburgh Fulton K-5',
  'Pittsburgh Grandview K-5',
  'Pittsburgh Greenfield K-8',
  'Pittsburgh Homewood Early Childhood Ctr',
  'Pittsburgh King K-8',
  'Pittsburgh Langley K-8',
  'Pittsburgh Liberty K-5',
  'Pittsburgh Lincoln K-5',
  'Pittsburgh Linden K-5',
  'Pittsburgh Manchester K-8',
  'Pittsburgh Mccleary Early Childhood Ctr',
  'Pittsburgh Mifflin K-8',
  'Pittsburgh Miller K-5',
  'Pittsburgh Milliones 6-12',
  'Pittsburgh Minadeo K-5',
  'Pittsburgh Montessori K-5',
  'Pittsburgh Morrow K-8',
  'Pittsburgh Obama 6-12',
  'Pittsburgh Oliver',
  'Pittsburgh Online Academy',
  'Pittsburgh Perry Hs',
  'Pittsburgh Phillips K-5',
  'Pittsburgh Pioneer',
  'Pittsburgh Roosevelt K -5',
  'Pittsburgh Schiller 6-8',
  'Pittsburgh Science And Technology Academ',
  'Pittsburgh South Brook 6-8',
  'Pittsburgh South Hills 6-8',
  'Pittsburgh Spring Garden Early Childhood',
  'Pittsburgh Spring Hill K-5',
  'Pittsburgh Sterrett 6-8',
  'Pittsburgh Sunnyside K-8',
  'Pittsburgh Technical Institute',
  'Pittsburgh Weil K-8',
  'Pittsburgh West Liberty K-5',
  'Pittsburgh Westwood K-8',
  'Pittsburgh Whittier K-5',
  'Pittsburgh Woolslair K-5',
  'Pittsfield Elementary School',
  'Pittsfield High',
  'Pittsfield High School',
  'Pittsfield Middle School',
  'Pittsfield School',
  'Pittsfield South Elem School',
  'Pittsford Area Elem School',
  'Pittsford Area High School',
  'Pittsford Sutherland High School',
  'Pittsford-mendon High School',
  'Pittsgrove Township Middle School',
  'Pittston Area Ms',
  'Pittston Area Pri Ctr',
  'Pittston Area Shs',
  'Pittston City Intrmd Ctr',
  'Pittston Consolidated School',
  'Pittsville Elementary',
  'Pittsville Elementary & Middle',
  'Pittsville High',
  'Pittsylvania Vocational-technical Center',
  'Pitzer College',
  'Piute High',
  'Piute Middle',
  'Piute Mountain Elementary',
  'Pivik El Sch',
  'Pivot Charter School',
  'Pivot Charter School - San Diego',
  'Pivot Charter School North Valley',
  'Pivot Charter School Riverside Ii',
  'Pivot Online Charter - North Bay',
  'Pixley Elementary',
  'Pixley Middle',
  'Pizitz Middle School',
  'Pizzo Elementary School',
  'Pjatigorsk State Linguistic University',
  'Pk Early Intervention',
  'Pk Early Intervention Program',
  'Pk Satellite Centers',
  'Place',
  'Place Bridge Academy',
  'Placedo El',
  'Placentino Elementary',
  'Placer County Community Schools',
  'Placer County Court Schools',
  'Placer County Pathways Charter',
  'Placer County Special Education',
  'Placer Elementary',
  'Placer High',
  'Placerita Junior High',
  'Placitas Elementary',
  'Plain City Elementary School',
  'Plain City School',
  'Plain Dealing High School',
  'Plain Elementary',
  'Plain View Elementary',
  'Plain View Primary',
  'Plainedge Middle School',
  'Plainedge Senior High School',
  'Plainfield Academy',
  'Plainfield Academy For The Arts & Advanced Studies',
  'Plainfield Central School',
  'Plainfield Community Middle School',
  'Plainfield East High School',
  'Plainfield El Sch',
  'Plainfield Elem School',
  'Plainfield Elementary',
  'Plainfield Elementary School',
  'Plainfield High School',
  'Plainfield Memorial School',
  'Plainfield North High School',
  'Plainfield South High School',
  'Plains 7-8',
  'Plains El',
  'Plains Elem',
  'Plains Elementary',
  'Plains Elementary School',
  'Plains H S',
  'Plains High School',
  'Plains Middle',
  'Plainsburg Union Elementary',
  'Plainview Academic Charter Academy',
  'Plainview Elem.',
  'Plainview Elementary',
  'Plainview Elementary School',
  'Plainview H S',
  'Plainview High School',
  'Plainview Hs',
  'Plainview Intermediate Es',
  'Plainview Junior-senior High School',
  'Plainview Ms',
  'Plainview Primary Es',
  'Plainview School',
  'Plainview Secondary School',
  'Plainview-elgin-millville 4-6',
  'Plainview-elgin-millville High',
  'Plainview-elgin-millville Junior',
  'Plainview-elgin-millville Pk-3',
  'Plainview-old Bethpage Kindergarten Center',
  'Plainview-old Bethpage Middle School',
  'Plainview-old Bethpage/jfk High School',
  'Plainville Elem',
  'Plainville High',
  'Plainville High School',
  'Plainwell High School',
  'Plainwell Middle School',
  'Plaisance Elementary School',
  'Plamondon Elem School',
  'Planada Elementary',
  'Plank Junior High',
  'Plank Road Complex',
  'Plank Road North Elementary School',
  'Plank Road South Elementary School',
  'Plankinton Elementary - 02',
  'Plankinton High School - 01',
  'Plankinton Jr. High - 03',
  'Plano Cusd 88',
  'Plano East Sr H S',
  'Plano Elementary',
  'Plano Family Literacy',
  'Plano High School',
  'Plano Isd Academy H S',
  'Plano Jjaep',
  'Plano Middle School',
  'Plano Special Programs Center',
  'Plano Sr H S',
  'Plano West Senior H S',
  'Plant City Adult & Community Center',
  'Plant City High School',
  'Plant High School',
  'Plantation Elementary',
  'Plantation Elementary School',
  'Plantation High School',
  'Plantation Key School',
  'Plantation Middle School',
  'Plantation Oaks Elementary School',
  'Plantation Park Elementary',
  'Plantation Park Elementary School',
  'Plantersville Elementary',
  'Plantersville Middle School',
  'Plantsville Elementary School',
  'Planwel University',
  'Planz Elementary',
  'Plaquemine Senior High School',
  'Plat Elementary',
  'Plateau Valley Elementary School',
  'Plateau Valley High School',
  'Plateau Valley Middle School',
  'Platina Elementary',
  'Plato Academy',
  'Plato Academy Charter School (k-8)',
  'Plato Academy Charter School Tarpon Springs',
  'Plato Academy Clearwater',
  'Plato Academy Largo Charter School',
  'Plato Academy Of St.petersburg',
  'Plato Academy Palm Harbor Charter School',
  'Plato Academy Pinellas Park Charter School',
  'Plato Academy Tampa Charter School',
  'Plato Academy Trinity Charter School',
  'Plato Elem.',
  'Plato Es',
  'Plato High',
  'Plato Learning Acad Elem School',
  'Plato Seminole',
  'Platt College',
  'Platt Elementary School',
  'Platt Technical High School',
  'Platte - Geddes Elementary - 02',
  'Platte - Geddes High School - 01',
  'Platte - Geddes Jr. High - 03',
  'Platte Canyon High School',
  'Platte Center Elementary School',
  'Platte City Middle',
  'Platte Colony Elementary - 10',
  'Platte County High',
  'Platte River Charter Academy',
  'Platte River Elementary School',
  'Platte Valley Coop.',
  'Platte Valley Elementary School',
  'Platte Valley High School',
  'Platte Valley Middle School',
  'Platte Valley Youth Services Center',
  'Plattekill Elementary School',
  'Platteview Central Jr High School',
  'Platteview Senior High School',
  'Platteville Elementary School',
  'Platteville High',
  'Platteville Middle',
  'Plattin Primary',
  'Plattsburg High',
  'Plattsburgh Senior High School',
  'Plattsmouth Early Childhood',
  'Plattsmouth Elementary School',
  'Plattsmouth High School',
  'Plattsmouth Middle School',
  'Plaucheville Elementary School',
  'Plavan (urbain H.) Elementary',
  'Playa Del Rey Elementary',
  'Playa Del Rey Elementary School',
  'Playa Grande',
  'Playa Vista Elementary',
  'Playing For Keeps Early Childhood Center',
  'Plaza Elementary',
  'Plaza Elementary School',
  'Plaza Middle',
  'Plaza Park International Prep Acad',
  'Plaza Robles Continuation High',
  'Plaza Towers Es',
  'Plaza Vista',
  'Plc Arts Academy At Scottsdale Inc.',
  'Pleasant Acres Elem School',
  'Pleasant Avenue School',
  'Pleasant City Elementary School',
  'Pleasant Community Digital',
  'Pleasant Crossing Elementary',
  'Pleasant Elementary',
  'Pleasant Elementary School',
  'Pleasant Gap El Sch',
  'Pleasant Garden Elementary',
  'Pleasant Gardens Elementary',
  'Pleasant Glade Elementary',
  'Pleasant Green School',
  'Pleasant Grove',
  'Pleasant Grove El',
  'Pleasant Grove Elementary',
  'Pleasant Grove Elementary School',
  'Pleasant Grove H S',
  'Pleasant Grove High',
  'Pleasant Grove High School',
  'Pleasant Grove Int',
  'Pleasant Grove Jr High',
  'Pleasant Grove Middle',
  'Pleasant Grove Public School',
  'Pleasant High School',
  'Pleasant Hill Ec Ctr',
  'Pleasant Hill El',
  'Pleasant Hill Elem School',
  'Pleasant Hill Elem.',
  'Pleasant Hill Elementary',
  'Pleasant Hill Elementary School',
  'Pleasant Hill High',
  'Pleasant Hill High School',
  'Pleasant Hill Intermediate',
  'Pleasant Hill Middle',
  'Pleasant Hill Primary',
  'Pleasant Hill School',
  'Pleasant Hills Ms',
  'Pleasant Home School',
  'Pleasant Hope Elem.',
  'Pleasant Hope High',
  'Pleasant Hope Middle',
  'Pleasant Hope Ranch Sch.',
  'Pleasant Knoll Elementary',
  'Pleasant Knoll Middle',
  'Pleasant Lake Elementary School',
  'Pleasant Lane Elem School',
  'Pleasant Lea Elem.',
  'Pleasant Lea Middle',
  'Pleasant Middle School',
  'Pleasant Plains Elementary',
  'Pleasant Plains High School',
  'Pleasant Plains Middle School',
  'Pleasant Prairie Elementary',
  'Pleasant Ridge Elem',
  'Pleasant Ridge Elem School',
  'Pleasant Ridge Elementary',
  'Pleasant Ridge Elementary School',
  'Pleasant Ridge High',
  'Pleasant Ridge Middle',
  'Pleasant Ridge Montessori School',
  'Pleasant Ridge Special Education Preschool',
  'Pleasant Run El',
  'Pleasant Run Elementary School',
  'Pleasant Run Middle School',
  'Pleasant Street Elementary School',
  'Pleasant Street School',
  'Pleasant Union Elementary',
  'Pleasant Valley Colony Elementary - 06',
  'Pleasant Valley Early Child.',
  'Pleasant Valley El',
  'Pleasant Valley El Sch',
  'Pleasant Valley Elem',
  'Pleasant Valley Elem School',
  'Pleasant Valley Elem.',
  'Pleasant Valley Elem/jr High',
  'Pleasant Valley Elementary',
  'Pleasant Valley Elementary School',
  'Pleasant Valley High',
  'Pleasant Valley High School',
  'Pleasant Valley Hs',
  'Pleasant Valley Innovative School',
  'Pleasant Valley Intrmd Sch',
  'Pleasant Valley Junior High School',
  'Pleasant Valley Middle',
  'Pleasant Valley Middle School',
  'Pleasant Valley Ms',
  'Pleasant Valley Primary',
  'Pleasant Valley School',
  'Pleasant Valley School Of Engineering And Arts',
  'Pleasant View',
  'Pleasant View El Sch',
  'Pleasant View Elem.',
  'Pleasant View Elementary',
  'Pleasant View Elementary School',
  'Pleasant View Junior High',
  'Pleasant View Magnet School',
  'Pleasant View Middle',
  'Pleasant View Middle School',
  'Pleasant View School',
  'Pleasantdale Elementary School',
  'Pleasantdale Middle Sch',
  'Pleasanton El',
  'Pleasanton Elem',
  'Pleasanton Elementary School',
  'Pleasanton H S',
  'Pleasanton High',
  'Pleasanton High School',
  'Pleasanton Int',
  'Pleasanton Isd School Of Choice',
  'Pleasanton J H',
  'Pleasanton Middle',
  'Pleasanton Pri',
  'Pleasants County Middle School',
  'Pleasantview Elem',
  'Pleasantview Elementary',
  'Pleasantview Elementary School',
  'Pleasantville El',
  'Pleasantville El Sch',
  'Pleasantville Elementary',
  'Pleasantville Elementary School',
  'Pleasantville High School',
  'Pleasantville Middle School',
  'Pleasure Ridge Park High',
  'Plekhanov Russian Academy of Economics',
  'Plenty Coups High School',
  'Plentywood 7-8',
  'Plentywood High School',
  'Plentywood School',
  'Pleviak Elem School',
  'Plevna 7-8',
  'Plevna High School',
  'Plevna School',
  'Plover-whiting Elementary',
  'Plt Middle School',
  'Plum Canyon Elementary',
  'Plum Center For Lifelong Learning',
  'Plum City Elementary',
  'Plum City High',
  'Plum Cove School',
  'Plum Creek El',
  'Plum Creek Elementary',
  'Plum Grove Jr High School',
  'Plum Point Elementary',
  'Plum Point Middle',
  'Plum Shs',
  'Plum Valley Elementary',
  'Plumas Avenue Elementary',
  'Plumas Charter',
  'Plumas County Community',
  'Plumas County Opportunity',
  'Plumas County Rop',
  'Plumb Elementary School',
  'Plumbrook Elementary School',
  'Plummer El',
  'Plummer Elementary',
  'Plummer Es',
  'Plummer Middle',
  'Plumosa School Of The Arts',
  'Plush Elementary School',
  'Plymell Elementary',
  'Plymouth Alternative High School',
  'Plymouth Center School',
  'Plymouth Commun Intermediate',
  'Plymouth Creek Elementary',
  'Plymouth Early Childhood Center',
  'Plymouth Educational Center',
  'Plymouth El Sch',
  'Plymouth Elementary',
  'Plymouth Elementary School',
  'Plymouth High',
  'Plymouth High School',
  'Plymouth Middle',
  'Plymouth North High',
  'Plymouth Regional High School',
  'Plymouth River',
  'Plymouth Scholars Charter Academy',
  'Plymouth School',
  'Plymouth South High',
  'Plymouth South Middle',
  'Plymouth State College',
  'Plymouth Youth Center',
  'Plymouth Youth Center Extended',
  'Plymouth-whitemarsh Shs',
  'Pmhs Career & Technology Center',
  'Pnm-santa Fe',
  'Poage Elementary School',
  'Poca Elementary',
  'Poca High School',
  'Poca Middle School',
  'Pocahontas Area Elementary School',
  'Pocahontas Area Middle/ High School',
  'Pocahontas Area Regional Learning Center',
  'Pocahontas Center',
  'Pocahontas County High School',
  'Pocahontas Elementary',
  'Pocahontas High School',
  'Pocahontas Junior High School',
  'Pocahontas Middle',
  'Pocalla Springs Elementary',
  'Pocantico Hills Central School',
  'Pocasset School',
  'Pocatello Community Charter',
  'Pocatello High School',
  'Pocatello Juvenile Detention',
  'Pocola Es',
  'Pocola Hs',
  'Pocola Ms',
  'Pocomoke Elementary',
  'Pocomoke High',
  'Pocomoke Middle',
  'Pocono Mountain East Hs',
  'Pocono Mountain East Jhs',
  'Pocono Mountain West Hs',
  'Pocono Mountain West Jhs',
  'Pocopson El Sch',
  'Poder Academy',
  'Poder Academy Secondary School',
  'Podesta Ranch Elementary',
  'Poe El',
  'Poe Elem Classical School',
  'Poe Elementary',
  'Poe Middle',
  'Poestenkill Elementary School',
  'Poff El Sch',
  'Pogue Options Alternative Academy',
  'Pohakea Elementary School',
  'Pohang University of Science and Technology',
  'Pohatcong Township School',
  'Pohukaina School',
  'Poinciana Academy Of Fine Arts',
  'Poinciana Elementary School',
  'Poinciana High School',
  'Poinciana Park Elementary School',
  'Poinciana Stem Elementary Magnet School',
  'Poindexter Elementary School',
  'Poinsettia Elementary',
  'Point 4 The Future',
  'Point Alternative Center',
  'Point Arena High',
  'Point Defiance',
  'Point Dume Elementary',
  'Point Elem.',
  'Point Fermin Elementary',
  'Point Harmony Elementary School',
  'Point Higgins School',
  'Point Loma High',
  "Point O' View Elementary",
  'Point Of Discovery School',
  'Point Option Alternative School',
  'Point Pleasant Beach High School',
  'Point Pleasant Borough High School',
  'Point Pleasant Elementary',
  'Point Pleasant Junior/senior High School',
  'Point Road School',
  'Point Roberts Primary',
  'Point Rock Alternative',
  'Point Vicente Elementary',
  'Point Webster Middle',
  'Pointe South Elementary School',
  'Pointe South Middle School',
  'Pointe-aux-chenes Elementary School',
  'Pointer Ridge Elementary',
  'Pointers Run Elementary',
  'Pointview Elementary School',
  'Poison Spider Elementary',
  'Pojoaque High',
  'Pojoaque Intermediate',
  'Pojoaque Middle',
  'Pokhara University',
  'Poland Community School',
  'Poland Elementary School',
  'Poland Junior High School',
  'Poland Junior-senior High School',
  'Poland Middle School',
  'Poland North Preschool',
  'Poland Regional H S',
  'Poland Seminary High School',
  'Poland Union Elementary School',
  'Polaris Career Center',
  'Polaris Charter School',
  'Polaris Elem Charter Academy',
  'Polaris Elementary School',
  'Polaris Expeditionary Learning School',
  'Polaris High (alternative)',
  'Polaris High School',
  'Polaris K-12 School',
  'Polaris School',
  'Polaris Tech',
  'Pole Corral Elementary',
  'Pole Green Elementary',
  'Polenta Elementary',
  'Police Academy',
  'Police Academy in Bratislava',
  'Police Academy of Latvia',
  'Polish Open University in Warsaw (WSZ/POU)',
  'Polish-Japanese Institute of Information Technology in Warsaw',
  'Politeknik Negeri Bandung',
  'Politeknik Negeri Jakarta',
  'Politeknik Negeri Lhokseumawe',
  'Politeknik Negeri Malang',
  'Politeknik Negeri Padang',
  'Politeknik Negeri Pontianak',
  'Politeknik Negeri Sambas',
  'Politeknik Negeri Semarang',
  'Politeknik Pos Indonesia',
  'Politécnico Columbiano "Jaime Isaza Cadavid"',
  'Politécnico Grancolombiano - Institución Universitaria',
  'Politécnico de Guanajuato',
  'Polk Acceleration Academy',
  'Polk Avenue Elementary School',
  'Polk Central Elementary School',
  'Polk City Elementary School',
  'Polk County Early College',
  'Polk County High School',
  'Polk County Middle School',
  "Polk County Sheriff's Central Center",
  'Polk County Sheriffs Regional Detention Center',
  'Polk El',
  'Polk Elementary School',
  'Polk Full Time Eschool',
  'Polk Halfway House',
  'Polk Middle',
  'Polk Pre-collegiate Academy',
  'Polk School',
  'Polk State College Collegiate High School',
  'Polk State Lakeland Gateway To College Charter High School',
  'Polk Street School',
  'Polk Virtual Franchise',
  'Polk Virtual Instruction (course Offerings)',
  'Polk Virtual Instruction Program',
  'Pollard Elementary School',
  'Pollard Middle',
  'Pollardmccall Junior High School',
  'Pollock Elementary School',
  'Pollock Robert B Sch',
  'Pollocksville Elementary',
  'Polly Fox Academy Community School',
  'Polly Ryon Middle',
  'Polo Comm High School',
  'Polo Elem.',
  'Polo High',
  'Polo Middle',
  'Polo Park Middle School',
  'Polo Ridge Elementary',
  'Polo Road Elementary',
  'Polonia University in Czêstochowa',
  'Polotsk State University',
  'Polser El',
  'Polson High School',
  'Polson Middle School',
  'Poltava National Technical University',
  'Poltava University of Consumer Cooperatives in Ukraine',
  'Polton Community Elementary School',
  'Poly Drive School',
  'Polytech High School',
  'Polytechnic H S',
  'Polytechnic High',
  'Polytechnic Ibadan',
  'Polytechnic Institute of Bari',
  'Polytechnic Institute of Milan',
  'Polytechnic Institute of Turin',
  'Polytechnic University',
  'Polytechnic University of New York',
  'Polytechnic University of Puerto Rico',
  'Polytechnic University of Tirane',
  'Polytechnic University of the Philippines',
  'Polytechnic of Namibia',
  'Polytechnical University of Kabul',
  'Pomaikai Elementary School',
  'Pomaria-garmany Elementary',
  'Pomelo Community Charter',
  'Pomerado Elementary',
  'Pomeranian Academy of Medicine in Szczecin',
  'Pomerene Elementary School',
  'Pomeroy El',
  'Pomeroy Elementary',
  'Pomeroy Elementary School',
  'Pomeroy Jr Sr High School',
  'Pomfret Community School',
  'Pomo',
  'Pomolita Middle',
  'Pomona Alternative',
  'Pomona College',
  'Pomona Community Day',
  'Pomona El',
  'Pomona Elementary',
  'Pomona Elementary School',
  'Pomona High',
  'Pomona High School',
  'Pomona Middle School',
  'Pomona Preschool',
  'Pomor State University',
  'Pomorsk State University',
  'Pompano Beach Elementary School',
  'Pompano Beach Institute Of International Studies',
  'Pompano Beach Middle School',
  'Pompano Youth Treatment Center',
  'Pomperaug Regional High School',
  'Pomperaug School',
  'Pompton Lakes High School',
  'Ponaganset High School',
  'Ponaganset Middle School',
  'Ponca City East Ms',
  'Ponca City Hs',
  'Ponca City West Ms',
  'Ponca Elem School',
  'Ponca Elementary School',
  'Ponca High School',
  'Ponce De Leon Elementary School',
  'Ponce De Leon High School',
  'Ponce De Leon Middle School',
  'Ponce High School',
  'Ponchatoula High School',
  'Ponchatoula Junior High School',
  'Pond Cove Elementary',
  'Pond Creek-hunter Es',
  'Pond Creek-hunter Hs',
  'Pond Creek-hunter Ms',
  'Pond Elem.',
  'Pond Elementary',
  'Pond Gap Elementary',
  'Pond Hill School',
  'Pond Road Middle School',
  'Pond Springs El',
  'Ponder El',
  'Ponder H S',
  'Ponder J H',
  'Pondera Colony School',
  'Ponderosa El',
  'Ponderosa Elementary',
  'Ponderosa Elementary School',
  'Ponderosa High',
  'Ponderosa High School',
  'Ponderosa Middle School',
  'Ponderosa School',
  'Pondicherry University',
  'Ponemah Elementary',
  'Pontchartrain Elementary School',
  'Ponte Vedra High School',
  'Ponte Vedra Palm Valley- Rawlings Elementary School',
  'Pontiac Academy For Excellence Elementary',
  'Pontiac Academy For Excellence High School',
  'Pontiac Academy For Excellence Middle School',
  'Pontiac Elementary',
  'Pontiac High School',
  'Pontiac Jr High School',
  'Pontiac Middle School',
  'Pontifcia Universitas Lateranensis',
  'Pontifcia Universitas a S.Thomas Aquinate in Urbe',
  'Pontifcia Università Gregoriana',
  'Pontifcia Università Urbaniana',
  'Pontifica Università Gregoriana',
  'Pontificia Universidad Catolica de Chile',
  'Pontificia Universidad Catolica de Puerto Rico',
  'Pontificia Universidad Católica Madre y Maestra (PUCMM)',
  'Pontificia Universidad Católica del Ecuador',
  'Pontificia Universidad Católica del Perú',
  'Pontificia Universidad Javeriana',
  'Pontificia Universidade Católica do Paraná',
  'Pontificia Università Lateranense',
  'Pontificia Università S. Tommaso',
  'Pontificia Università della Santa Croce',
  'Pontificio Ateneo Antonianum',
  'Pontificio Istituto Orientale',
  'Pontifícia Universidade Católica de Campinas',
  'Pontifícia Universidade Católica de Minas Gerais',
  'Pontifícia Universidade Católica de São Paulo',
  'Pontifícia Universidade Católica do Rio Grande do Sul',
  'Pontifícia Universidade Católica do Rio de Janeiro',
  'Pontotoc Co Alternative Program',
  'Pontotoc Elementary School',
  'Pontotoc High School',
  'Pontotoc Junior High School',
  'Pontotoc Middle School',
  'Pontotoc Ridge Career & Tech. Cnt.',
  'Ponus Ridge Middle School',
  'Pony Express Elementary',
  'Pony Express School',
  'Pony Lake School',
  'Poole Gakuin University',
  'Pooler Elementary School',
  'Poolesville Elementary',
  'Poolesville High',
  'Poolville El',
  'Poolville H S',
  'Poolville J H',
  'Pop Warner Little Scholars',
  'Popakademie Baden-Württemberg',
  'Pope Co High School',
  'Pope County Elementary School',
  'Pope El',
  'Pope Elementary',
  'Pope Elementary School',
  'Pope High School',
  'Pope School',
  'Pope Valley Elementary',
  'Popham El',
  'Poplar 5-6 School',
  'Poplar 7-8',
  'Poplar Avenue Elementary',
  'Poplar Bluff Early Childhood C',
  'Poplar Bluff High',
  'Poplar Bluff Jr. High',
  'Poplar Bluff Kindergarten Ctr.',
  'Poplar Bluff Middle School',
  'Poplar Bluff Tech. Career Ctr.',
  'Poplar Bridge Elementary',
  'Poplar Creek Elementary',
  'Poplar Elementary',
  'Poplar Grove 5-8',
  'Poplar Grove Elem School',
  'Poplar Grove K-4',
  'Poplar Halls Elementary',
  'Poplar High School',
  'Poplar Road Elementary School',
  'Poplar School',
  'Poplar Springs Elementary',
  'Poplar Springs Elementary School',
  'Poplar Springs High School',
  'Poplar Tree Elementary',
  'Poplarville Career Development Ctr',
  'Poplarville Jr Sr High School',
  'Poplarville Lower Elementary School',
  'Poplarville Upper Elementary Sch',
  'Poplin Elementary',
  'Popplewell Elementary School',
  'Popps Ferry Elementary School',
  'Poquessing Ms',
  'Poquonock Elementary School',
  'Poquoson Elementary',
  'Poquoson High',
  'Poquoson Middle',
  'Poquoson Primary',
  'Por Vida Academy Charter H S',
  'Porcupine Day School',
  'Porfirio Cruz Garcia',
  'Porfirio H. Gonzales Elementary School',
  'Port Alexander School',
  'Port Allegany El Sch',
  'Port Allegany Jshs',
  'Port Allen Elementary School',
  'Port Allen High School',
  'Port Allen Middle School',
  'Port Angeles High School',
  'Port Aransas H S',
  'Port Arthur Alternative Center',
  'Port Barre Elementary School',
  'Port Barre High School',
  'Port Barre Middle School',
  'Port Byron Senior High School',
  'Port Charlotte High School',
  'Port Charlotte Middle School',
  'Port Chester Middle School',
  'Port Chester Senior High School',
  'Port Clinton High School',
  'Port Clinton Middle School',
  'Port Colden School',
  'Port Dickinson Elementary School',
  'Port Dickson Polytechnic',
  'Port Edwards Elementary',
  'Port Gardner',
  'Port Gibson High School',
  'Port Gibson Middle School',
  'Port Graham School',
  'Port Houston El',
  'Port Huron High School',
  'Port Huron Northern High School',
  'Port Isabel H S',
  'Port Isabel J H',
  'Port Jefferson Elementary School',
  'Port Jefferson Middle School',
  'Port Jervis Middle School',
  'Port Jervis Senior High School',
  'Port Leyden Elementary School',
  'Port Lions School',
  'Port Malabar Elementary School',
  'Port Matilda El Sch',
  'Port Monmouth Elementary School',
  'Port Monmouth Road School',
  'Port Neches El',
  'Port Neches Middle',
  'Port Neches-groves H S',
  'Port Norris Middle School',
  'Port O Connor El',
  'Port Of Los Angeles High',
  'Port Orange Elementary School',
  'Port Reading School',
  'Port Republic Elementary School',
  'Port Richmond High School',
  'Port Richmond School For Visionary Learning',
  'Port Royal Elementary',
  'Port Salerno Elementary School',
  'Port Salerno Learning Center',
  'Port St. Joe Elementary School',
  'Port St. Joe High School',
  'Port St. Lucie High School',
  'Port Susan Middle School',
  'Port Towns Elementary',
  'Port Townsend High School',
  'Port Washington Elementary School',
  'Port Washington High',
  'Port Washington-saukville School District Preschool',
  'Port Wentworth Elementary School',
  'Porta Central',
  'Porta High School',
  'Portage Academy Of Achievement',
  'Portage Area El Sch',
  'Portage Area Jshs',
  'Portage Central High School',
  'Portage Central Middle School',
  'Portage Collab Montessori Middle School',
  'Portage Community High School',
  'Portage High',
  'Portage High School',
  'Portage Lakes Career Center',
  'Portage Middle School',
  'Portage North Middle School',
  'Portage Northern High School',
  'Portage Park Elem School',
  'Portage Partnering Preschool',
  'Portage Path Community Learning Center',
  'Portage West Middle School',
  'Portageville Elem.',
  'Portageville High',
  'Portal Elementary School',
  'Portal Middle/high School',
  'Portales High',
  'Portales Jr High',
  'Porter Consolidated Es',
  'Porter Consolidated Hs',
  'Porter County Career Center',
  'Porter County Education Services',
  'Porter Early College H S',
  'Porter El',
  'Porter Elementary',
  'Porter Elementary School',
  'Porter H S',
  'Porter Lakes Elementary School',
  'Porter Middle School',
  'Porter Ranch Community',
  'Porter Ridge Elementary',
  'Porter Ridge High School',
  'Porter Ridge Middle School',
  'Porterdale Elementary School',
  'Porters Point School',
  'Porterville College',
  'Porterville High',
  'Portland Arthur Academy Charter School',
  'Portland Arts & Technology H S',
  'Portland Community College',
  'Portland East Middle School',
  'Portland Elem.',
  'Portland Elementary',
  'Portland Elementary School',
  'Portland Gateview Elementary School',
  'Portland High School',
  'Portland Middle School',
  'Portland State University',
  'Portland Village School',
  'Portland West Middle School',
  'Portlock Primary',
  'Portola Elementary',
  'Portola High',
  'Portola Hills Elementary',
  'Portola Junior/senior High',
  'Portola Middle',
  'Portola Opportunity',
  'Portola Springs Elementary',
  'Portola-butler Continuation High',
  'Portsmouth Elementary',
  'Portsmouth High School',
  'Portsmouth Junior High School/portsmouth High School',
  'Portsmouth Middle School',
  'Portsmouth Re-ed',
  'Portsmouth West Elementary School',
  'Portsmouth West High School',
  'Portsmouth West Middle School',
  'Portville Elementary School',
  'Portville Junior-senior High School',
  'Porum Es',
  'Porum Hs',
  'Posen Consolidated High School',
  'Posen Elem School',
  'Posen Elementary School',
  'Positive Approach To Learning',
  'Positive Outcomes Charter School',
  'Positive Pathways Transition Center',
  'Positive Redirection Ctr',
  'Positive Solutions Charter',
  'Possum Elementary School',
  'Possum Middle School',
  'Post El',
  'Post Elementary',
  'Post Falls High School',
  'Post Falls Middle School',
  'Post Franklin School',
  'Post H S',
  'Post Hs Self-cont',
  'Post Middle',
  'Post Middle School',
  'Post Oak Middle',
  'Post Oak School',
  'Post Road School',
  'Post Secondary Program',
  'Post Secondary Transition Program',
  'Postgraduate Institute of Agriculture (PGIA)',
  'Postgraduate Institute of Pali & Buddhist Studies (University of Kelaniya)',
  'Postgraduate lnstitute of Medical Education and Research',
  'Postma El',
  'Poston Butte High School',
  'Poston Junior High School',
  'Poston Road Elementary School',
  'Posts & Telecommunications Institute of Technology',
  'Postsecondary Alternatives For Secondary Students-pass',
  'Pot Spring Elementary',
  'Poteau Hs',
  'Poteau Primary Es',
  'Poteau Upper Es',
  'Poteet El',
  'Poteet H S',
  'Poteet Int',
  'Poteet J H',
  'Potentials Charter School',
  'Poth El',
  'Poth H S',
  'Poth J H',
  'Potlatch Elementary School',
  'Potlatch Jr/sr High School',
  'Potomac 7-8',
  'Potomac Elem School',
  'Potomac Elementary',
  'Potomac Falls High',
  'Potomac Heights Elementary',
  'Potomac High',
  'Potomac Landing Elementary',
  'Potomac Middle',
  'Potomac School',
  'Potomac View Elementary',
  'Potomack Intermediate School',
  'Potosi Elem.',
  'Potosi Elementary',
  'Potosi High',
  'Potosi Middle',
  'Potowmack Elementary',
  'Potranco El',
  'Potrero Elementary',
  'Potrero Heights Elementary',
  'Potsdam Senior High School',
  'Potter Elementary School',
  'Potter Gray Elementary',
  'Potter Road',
  'Potter School',
  'Potter Street Elementary School',
  'Potter Valley Elementary',
  'Potter Valley High',
  'Potter Valley Junior High',
  'Potter-burns School',
  'Potter-dix Elementary School',
  'Potter-dix Jr/sr High School',
  'Potter-thomas Sch',
  'Potterville Elementary School',
  'Potterville High School',
  'Potterville Middle School',
  'Potti Sreeramulu Telugu University',
  'Potts Camp High School (9-12)',
  'Potts Camp Middle School (4-8)',
  'Pottsboro El',
  'Pottsboro H S',
  'Pottsboro Middle',
  'Pottsgrove Ms',
  'Pottsgrove Shs',
  'Pottstown Ms',
  'Pottstown Shs',
  'Pottsville Area Hs',
  'Pottsville Elementary School',
  'Pottsville High School',
  'Pottsville Junior High School',
  'Pottsville Middle Grade',
  'Poudre Community Academy',
  'Poudre High School',
  'Poughkeepsie High School',
  'Poughkeepsie Middle School',
  'Poulsbo Elementary School',
  'Poulsbo Middle School',
  'Poultney Elementary School',
  'Poultney High School',
  'Pound Middle School',
  'Pound Ridge Elementary School',
  'Poway High',
  'Powder Mill School',
  "Powder River Basin Children's Center",
  'Powder River Co Dist High',
  'Powder River Elementary',
  'Powder Springs Elementary School',
  'Powderhorn Elementary School',
  'Powdersville Elementary',
  'Powdersville High',
  'Powdersville Middle',
  'Powel Samuel Sch',
  'Powell Academy For Success',
  'Powell Butte Community Charter School',
  'Powell County Academy',
  'Powell County High School',
  'Powell County Middle School',
  'Powell El',
  'Powell Elem Paideia Comm Academy',
  'Powell Elementary',
  'Powell Es',
  'Powell High School',
  'Powell Junior High School',
  'Powell Middle School',
  'Powell Twp Elementary School',
  'Powell Valley Elementary',
  'Powell Valley Elementary School',
  'Powells Lane School',
  'Power 7-8',
  'Power Apac School',
  'Power Center Academy Elementary School',
  'Power Center Academy High School',
  'Power Center Academy Middle',
  'Power High School',
  'Power Learning Center',
  'Power Middle School',
  'Power Ranch Elementary',
  'Power School',
  'Power Technical Early College',
  'Power and Water Institute of Technology',
  'Powers Elementary',
  'Powers Elementary School',
  'Powers Ferry Elementary School',
  'Powers High School',
  'Powers Lake Elementary School',
  'Powers Lake High School',
  'Powers-ginsburg Elementary',
  'Powhatan Elementary',
  'Powhatan Elementary School',
  'Powhatan High',
  'Powhatan Junior High',
  'Powhatan Voc. Center',
  'Pownal Elementary School',
  'Poyen Elementary School',
  'Poyen High School',
  'Poyner Elementary',
  'Poynette Elementary',
  'Poynette High',
  'Poynette Middle',
  'Poznan School of Banking',
  'Poznan University of Life Sciences',
  'Ppep Tec - Alice S. Paul Learning Center',
  'Ppep Tec - Celestino Fernandez Learning Center',
  'Ppep Tec - Cesar Chavez Learning Center',
  'Ppep Tec - Colin L. Powell Learning Center',
  'Ppep Tec - Jose Yepez Learning Center',
  'Ppep Tec - Manuel Borjorquez Learning Center-closed',
  'Ppep Tec - Raul H. Castro Learning Center',
  'Ppep Tec - Victor Soltero Learning Center',
  'Ppep Youth Build',
  'Prada El',
  'Prado View Elementary',
  'Prague College',
  'Prague Es',
  'Prague Hs',
  'Prague Institute of Chemical Technology',
  'Prague International University',
  'Prague Ms',
  'Prairie Branch Elem.',
  'Prairie Care Brooklyn Park - Ihp',
  'Prairie Care Brooklyn Park Day Prg',
  'Prairie Care Edina - Php',
  'Prairie Care Mankato',
  'Prairie Center Elem',
  'Prairie Central Elem School',
  'Prairie Central High School',
  'Prairie Central Jr High School',
  'Prairie Central Primary East',
  'Prairie Central Primary West',
  'Prairie Central Upper Elementary',
  'Prairie Children Preschool',
  'Prairie City Elementary School',
  'Prairie City School',
  'Prairie Creek',
  'Prairie Creek Community School',
  'Prairie Creek El',
  'Prairie Creek Elementary',
  'Prairie Creeks Charter School',
  'Prairie Crest Elementary School',
  'Prairie Crossing Charter School',
  'Prairie Crossing Elementary School',
  'Prairie Du Chien Correctional Inst',
  'Prairie Du Chien High',
  'Prairie Du Rocher Elem School',
  'Prairie Edge',
  'Prairie Elem',
  'Prairie Elem School',
  'Prairie Elem/jr High School',
  'Prairie Elementary',
  'Prairie Elementary School',
  'Prairie Elk Colony School',
  'Prairie Es',
  'Prairie Farm Elementary',
  'Prairie Farm High',
  'Prairie Farm Middle',
  'Prairie Grove Elem School',
  'Prairie Grove Elem. School',
  'Prairie Grove Elementary School',
  'Prairie Grove High School',
  'Prairie Grove Junior High School',
  'Prairie Grove Middle School',
  'Prairie Harbor Alternative School',
  'Prairie Heights Elementary School',
  'Prairie Heights Middle School',
  'Prairie Heights Sr High School',
  'Prairie High School',
  'Prairie Hill Elem School',
  'Prairie Hill Elementary School',
  'Prairie Hills Elementary School',
  'Prairie Hills Middle',
  'Prairie Home Elem.',
  'Prairie Home High',
  'Prairie House School',
  'Prairie Jr High School',
  'Prairie Jr/sr High School',
  'Prairie Junior-senior High School',
  'Prairie Knolls Middle Sch',
  'Prairie Lake Elementary',
  'Prairie Lakes Education Center',
  'Prairie Lane Elementary',
  'Prairie Lane Elementary School',
  'Prairie Lea School',
  'Prairie Lincoln Elementary School',
  'Prairie Meadows Learning Center',
  'Prairie Middle School',
  'Prairie Mountain School',
  'Prairie Norton Elementary School',
  'Prairie Oak School',
  'Prairie Park Elem',
  'Prairie Phoenix Academy',
  'Prairie Point',
  'Prairie Point Elem School',
  'Prairie Point Elem.',
  'Prairie Preschool',
  'Prairie Queen Elementary',
  'Prairie Queen Es',
  'Prairie Ridge Early Learning School',
  'Prairie Ridge Elementary',
  'Prairie Ridge Elementary School',
  'Prairie Ridge High School',
  'Prairie Ridge Middle School',
  'Prairie River Middle',
  'Prairie Rose Elementary School',
  'Prairie Seeds El School Academy',
  'Prairie Seeds High School Academy',
  'Prairie Seeds Middle School Academy',
  'Prairie Star Elementary',
  'Prairie Star Middle',
  'Prairie State College',
  'Prairie Trace Elementary School',
  'Prairie Trail Elementary',
  'Prairie Trail Middle School',
  'Prairie Trail School',
  'Prairie Vale Es',
  'Prairie Valley El',
  'Prairie Valley Elementary School',
  'Prairie Valley H S',
  'Prairie View A&M University',
  'Prairie View Academy',
  'Prairie View El',
  'Prairie View Elem School',
  'Prairie View Elem.',
  'Prairie View Elementary',
  'Prairie View Elementary & Middle',
  'Prairie View Elementary School',
  'Prairie View Es',
  'Prairie View Grade School',
  'Prairie View High',
  'Prairie View High School',
  'Prairie View Middle',
  'Prairie View Middle School',
  'Prairie View School',
  'Prairie Vista',
  'Prairie Vista Elementary School',
  'Prairie Vista Middle',
  'Prairie Wind Elem School',
  'Prairie Wind Elementary',
  'Prairie Wind Middle',
  'Prairie Winds Elementary School',
  'Prairie Winds Middle School',
  'Prairie Woods Elementary',
  'Prairie-hills Junior High School',
  'Prairiecare',
  'Prairiecare Chaska',
  'Prairiecare Maplewood',
  'Prairieland Elementary School',
  'Prairieview Elementary School',
  'Prairieview School',
  'Prairieview-ogden Jr High Sch',
  'Prairieview-ogden North Elem',
  'Prairieview-ogden South Elem',
  'Prairieville Middle School',
  'Prairieville Primary School',
  'Prairiewood Elem Sch',
  'Prairiland H S',
  'Prairiland J H',
  'Prasetiya Mulya Business School',
  'Prass Elementary School',
  'Prather Elementary School',
  'Pratt Community College',
  'Pratt Elementary',
  'Pratt Elementary School',
  'Pratt Es',
  'Pratt Institute',
  'Pratt Sr High',
  'Prattsburgh Central School',
  'Prattville Elementary School',
  'Prattville High School',
  'Prattville Intermediate School',
  'Prattville Junior High School',
  'Prattville Kindergarten School',
  'Prattville Primary School',
  'Pratum Elementary School',
  'Pray-woodman Elementary',
  'Prb Pequot Lakes Midlevel Alc',
  'Pre K Incentive Program',
  'Pre School Program',
  'Pre-k Early Intervention Prog',
  'Pre-k Ese Services',
  'Pre-k Partnership Childcare',
  'Pre-k Sped',
  'Pre-kindergarten',
  'Pre-school Dev. Center',
  'Pre/k For Sa - East Central Isd',
  'Preble High',
  'Preble Shawnee High School/junior High School',
  'Precarpathian University',
  'Precision Academy',
  'Preclarus Mastery Academy',
  'Preeminent Charter',
  'Prek',
  'Prek Autism',
  'Prek Ese',
  'Prek Intervention',
  'Prek Partnership Center',
  'Prek Partnership School',
  'Prek Speech And Language Student Services',
  'Prek-eastbury',
  'Prek-nayaug',
  'Prek/kindergarten Center',
  'Prekindergarten Facility',
  'Prekindergarten North',
  'Prekindergarten Offsites',
  'Prekindergarten Programs',
  'Prekindergarten South',
  'Premier Academy Morris',
  'Premier Academy Morris Taoep',
  'Premier Arts And Science Charter School',
  'Premier Charter High School',
  'Premier Charter School',
  'Premier H S American Youthworks',
  'Premier H S Of Abilene',
  'Premier H S Of Amarillo',
  'Premier H S Of Arlington',
  'Premier H S Of Austin',
  'Premier H S Of Brownsville',
  'Premier H S Of Comanche/early',
  'Premier H S Of Dayton',
  'Premier H S Of Del Rio',
  'Premier H S Of East El Paso',
  'Premier H S Of El Paso',
  'Premier H S Of Fort Worth',
  'Premier H S Of Granbury',
  'Premier H S Of Huntsville',
  'Premier H S Of Laredo',
  'Premier H S Of Lewisville',
  'Premier H S Of Lubbock',
  'Premier H S Of Midland',
  'Premier H S Of Mission',
  'Premier H S Of New Braunfels',
  'Premier H S Of North Austin',
  'Premier H S Of North Houston',
  'Premier H S Of Palmview',
  'Premier H S Of Pflugerville',
  'Premier H S Of Pharr',
  'Premier H S Of San Juan',
  'Premier H S Of South Irving',
  'Premier H S Of Texarkana',
  'Premier H S Of Tyler',
  'Premier H S Of Waco',
  'Premier H S San Antonio - West',
  'Premier H S San Antonio East',
  'Premier High School Of Little Rock',
  'Premier High Schools Career & Technical Ed Center',
  'Premier University',
  'Premont Collegiate H S',
  'Premont Early College Academy',
  'Prempeh College',
  'Prendergast School',
  'Prenger Family Ctr.',
  'Prentice Elementary',
  'Prentice High',
  'Prentice Middle',
  'Prentiss Co Voc Complex',
  'Prentiss Senior High School',
  'Prepa Tec - Los Angeles',
  'Prepa Tec Los Angeles High',
  'Preparatory Academy For Writers: A College Board School',
  'Preparatory Cs Of Mathematics Science',
  'Preparatory For Early College H S',
  'Preparatory Literary Academy Of Cultural Excellence',
  'Presa El',
  'Presbyterian College',
  'Presbyterian University College',
  'Presbyterian University of East Africa',
  'Preschool',
  'Preschool (sp Ed)',
  'Preschool 4 Janesville',
  'Preschool And Eceap',
  'Preschool Center',
  'Preschool Early Education Program Peep',
  'Preschool Grasp',
  'Preschool Infant Other',
  'Preschool Learning Academy',
  'Preschool Options',
  'Preschool Team',
  'Preschool-primary Learning Center (plc)',
  'Prescott 4k',
  'Prescott College',
  'Prescott Elem School',
  'Prescott Elementary School',
  'Prescott High',
  'Prescott High School',
  'Prescott Jr Sr High',
  'Prescott Junior High',
  'Prescott Memorial School',
  'Prescott Middle',
  'Prescott Mile High Middle School',
  'Prescott South Elementary',
  'Prescott South Middle School',
  'Prescott Special Ed Pre-school',
  'Prescott Valley School',
  'Presho Elementary - 03',
  'Presidency University',
  'President Abraham Lincoln Elementary School',
  'President Avenue Elementary',
  'President Barack Obama Elementary School',
  'President George Washington Middle School',
  'President Theodore Roosevelt High School',
  'President Thomas Jefferson Elementary School',
  'President William Mckinley High School',
  'Presidential Meadows El',
  'Presidential Park Elementary School',
  'Presidents Elementary',
  'Presidio Early Ed.',
  'Presidio El',
  'Presidio H S',
  'Presidio Middle',
  'Presidio School',
  'Presque Isle Academy',
  'Presque Isle High School',
  'Presque Isle Middle School',
  'Presque Isle Reg Career & Tech Ctr',
  'Pressley Ridge At Grant Gardens',
  'Pressley Ridge At Laurel Park',
  'Pressley Ridge At White Oak Village',
  'Pressly School',
  'Prestige Academy',
  'Prestige Preparatory Academy',
  'Preston Elementary',
  'Preston Elementary School',
  'Preston Es',
  'Preston Hall Middle School',
  'Preston High School',
  'Preston Hollow El',
  'Preston Hs',
  'Preston Institute of Management Sciences and Technology (PIMSAT)',
  'Preston Jr High School',
  'Preston Middle School',
  'Preston Park Elementary',
  'Preston Plains School',
  'Preston Sch',
  'Preston University Pakistan Campus',
  "Preston Veterans' Memorial School",
  'Prestonsburg Elementary School',
  'Prestonsburg High School',
  'Prestonwood El',
  'Prestwick K-8 Stem Academy',
  'Prestwood Elementary',
  'Presumpscot School',
  'Pretrial Detention Facility',
  'Pretty Prairie Elem',
  'Pretty Prairie High',
  'Pretty Prairie Middle',
  'Pretty Water Public School',
  'Prettyboy Elementary',
  'Preuss School Ucsd',
  'Prevail Academy',
  'Price',
  'Price (adelaide) Elementary',
  'Price Academy',
  'Price Charter Middle',
  'Price El',
  'Price El Sch',
  'Price Elementary',
  'Price Halfway House',
  'Price Intermediate',
  'Price Middle School',
  'Price T Young El',
  'Price T Young Middle',
  'Price-harris Communications Magnet',
  'Prices Fork Elementary',
  'Priceville Elementary School',
  'Priceville High School',
  'Priceville Junior High School',
  'Prichard Elementary School',
  'Priddy School',
  'Pride Academy',
  'Pride Academy At Prospect Avenue',
  'Pride Academy Charter School',
  'Pride Alter Sch',
  'Pride Continuation',
  'Pride Elementary School',
  'Pride Prep School',
  'Pride School',
  'Pride Transitions',
  'Prien Lake Elementary School',
  'Priest Elementarymiddle School',
  'Priest Lake Elementary School',
  'Priest River Educational Program (prep)',
  'Priest River Elementary School',
  'Priest River Jr High School',
  'Priest River Lamanna High',
  'Priest Street',
  'Priestley Sch',
  'Prieto Math-science Elem Sch',
  'Primary Academy For Success',
  'Primary Center At Jefferson',
  'Primary Center At Wilson',
  'Primary Charter',
  'Primary Dcd - Snw',
  'Primary Education Center',
  'Primary Elementary School',
  'Primary Years Academy',
  'Primavera - Online',
  'Primero Elementary School',
  'Primero Junior-senior High School',
  'Primier International University Perak',
  'Primitivo Garcia Elem.',
  'Primitivo Marchand',
  'Primos El Sch',
  'Primrose Hill School',
  'Primrose School',
  'Prince Chapman Academy',
  'Prince David Kawananakoa Middle School',
  'Prince Edward County High',
  'Prince Edward County Vocational Technical',
  'Prince Edward Elementary',
  'Prince Edward Middle',
  'Prince George High',
  "Prince George's Community College",
  'Prince Hall',
  'Prince Jonah Kalanianaole Elementary & Intermediate School',
  'Prince Jonah Kuhio Elementary School',
  'Prince Mohammad Bin Fahd University',
  'Prince Street School',
  'Prince Sultan College for Tourism and Hotel Scinces',
  'Prince Sultan University',
  'Prince William Juv. Detention Home',
  'Prince William Sound Community College',
  'Prince of Songkla University',
  'Princess Anne Elementary',
  'Princess Anne Elementary School',
  'Princess Anne High',
  'Princess Anne Middle',
  'Princess Miriam K Likelike Elementary School',
  'Princess Nahienaena Elementary School',
  'Princess Nora Bint Abdulrahman University',
  'Princess Sumaya University for Technology',
  'Princess Victoria Kaiulani Elementary School',
  'Princeton Alternative Learning Center',
  'Princeton Alternative Services',
  'Princeton Area Learning Center',
  'Princeton Charter School',
  'Princeton Comm Intermediate School',
  'Princeton Comm Primary School',
  'Princeton Community High School',
  'Princeton Community Middle',
  'Princeton Community Middle School',
  'Princeton Educational Options',
  'Princeton Elementary',
  'Princeton Elementary School',
  'Princeton H S',
  'Princeton High',
  'Princeton High School',
  'Princeton House Charter',
  'Princeton Intermediate School',
  'Princeton Junior-senior High',
  'Princeton Middle School',
  'Princeton Online Academy',
  'Princeton Primary School',
  'Princeton R-v Elem.',
  'Princeton R-v Jr.-sr. High',
  'Princeton School',
  'Princeton Senior High School',
  'Princeton Special Programs Center',
  'Princeton Street Elementary',
  'Princeton University',
  'Princeton/farmersville Daep',
  'Princeville Elem School',
  'Princeville Elementary',
  'Princeville High School',
  'Pringle Elementary School',
  'Pringle-morse El-j H',
  'Prior Lake High School',
  'Prior Lake-savage Area Alc',
  'Prior Lake-savage Ecse',
  'Priority Intervention Academy',
  'Prisk Elementary',
  'Pritchard J H',
  'Pritchardville Elementary',
  'Pritchett Elementary School',
  'Pritchett High School',
  'Pritchett Middle School',
  'Pritzker Elem School',
  'Private College of Dentistry and Pharmacy',
  'Private Fachhochschule Göttingen',
  'Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz',
  'Private FernFachhochschule Darmstadt',
  'Private Hanseuniversität',
  'Private Nicholas Minue Elementary School',
  'Private School Services',
  'Private Universität Witten/Herdecke',
  'Private Universität für Medizinische Informatik und Technik Tirol',
  'Privredna Akademija (Business Academy)',
  'Pro-vision H S',
  'Pro-vision Middle',
  'Probstfield Center For Education',
  'Probstfield Elementary',
  'Procter Elem.',
  'Procter R Hug High School',
  'Proctor Elementary',
  'Proctor Elementary School',
  'Proctor Junior/senior High School',
  'Proctor Senior High',
  'Proctor Terrace Elementary',
  'Prodeo Academy',
  'Prodeo Academy St. Paul',
  'Prodigy Center For Youth Development And Academic Excellence',
  'Proegler School',
  'Profesora Juana Rosario Carrero (escuela Intermedia Nueva)',
  'Profesora Luisa Monsegur Velez',
  'Professional & Technical High School',
  'Professional Academy Magnet At Loften High School',
  'Professional Pathways High School',
  'Professional Performing Arts High School',
  'Professor Juan Bosch Public School',
  'Profile Junior High School',
  'Profile Senior High School',
  'Progeny Academy Charter School',
  'Program For Academic And Career Enhancement (pace)',
  'Programs At Park Academy',
  'Progreso El West',
  'Progreso H S',
  'Progress Elementary School',
  'Progress High School For Professional Careers',
  'Progress South Elem.',
  'Progress Village Middle Magnet School',
  'Progressive Academy Of Creative Education',
  'Project Accept Traice Es',
  'Project Bridge/russell Co',
  'Project C.o.f.f.e.e.',
  'Project Challenge',
  'Project Chrysalis Middle',
  'Project Compass',
  'Project Echo',
  'Project Impact Stem Academy',
  'Project Indian',
  'Project Insight',
  'Project More High School',
  'Project Renew/northampton Co',
  'Project Restore',
  'Project Return',
  'Project Return/fluvanna Co',
  'Project Return/powhatan Co',
  'Project Search',
  'Project Search Mayo Clinic',
  'Project Search-avera Marshall',
  'Project Soar',
  'Project Stay-supporting Teachers And Youth',
  'Project Success Learning Center',
  'Project Teach - Atlantic',
  'Project Teach - Burlington',
  'Project Teach - Cape May',
  'Project Teach - Mercer',
  'Project Teach - Monmouth',
  'Project Teach - Warren',
  'Project Tyke',
  'Prologue Early College Hs',
  'Promenade Elementary',
  'Promethean Charter School',
  'Promise Academy',
  'Promise Academy - Spring Hill',
  'Promise Road Elementary',
  'Promontory School Of Expeditionary Learning',
  'Pronghorn Elementary',
  'Propel Cs - Hazelwood',
  'Propel Cs-braddock Hills',
  'Propel Cs-east',
  'Propel Cs-homestead',
  'Propel Cs-mckeesport',
  'Propel Cs-montour',
  'Propel Cs-northside',
  'Propel Cs-pitcairn',
  'Prophetstown Elem School',
  'Prophetstown High School',
  'Prospect Charter School',
  'Prospect Community Day School',
  'Prospect Education Center',
  'Prospect Elem School',
  'Prospect Elementary',
  'Prospect Elementary School',
  'Prospect Heights Middle',
  'Prospect High',
  'Prospect High (continuation)',
  'Prospect High School',
  'Prospect Hill Academy Charter School',
  'Prospect Hill School',
  'Prospect Mill Elementary',
  'Prospect Mountain High School',
  'Prospect Park Sch',
  'Prospect Park School No. 1',
  'Prospect Point Elementary',
  'Prospect Ridge Academy',
  'Prospect School',
  'Prospect Valley Elementary School',
  'Prospects High (alternative)',
  'Prosper Daep',
  'Prosper H S',
  'Prosper Valley School',
  'Prosperitas Leadership Academy Charter',
  'Prosperity-rikard Elementary',
  'Prosser Career Academy Hs',
  'Prosser Career Education Center',
  'Prosser Falls Education Center',
  'Prosser Heights Elementary',
  'Prosser High School',
  'Protsman Elementary School',
  'Prove High School',
  'Provencal Elementary & Junior High School',
  'Providence',
  'Providence Career Technical',
  'Providence College',
  'Providence Creek Academy Charter School',
  'Providence El',
  'Providence El Sch',
  'Providence Elementary',
  'Providence Elementary School',
  'Providence Grove High',
  'Providence Hall',
  'Providence High',
  'Providence Jr./sr. High',
  'Providence Middle',
  'Providence School',
  'Providence Spring Elementary',
  'Providence University',
  'Providence-englewood Elem Charter',
  'Providencia Elementary',
  'Provident Cs',
  'Provident Heights El',
  'Providing Urban Learners Success In Education High School',
  'Provincetown Schools',
  'Provine High School',
  'Provisional Accelerated Learning Academy',
  'Proviso Area Except Child Center',
  'Proviso East High School',
  'Proviso Math And Science Academy',
  'Proviso Thsd 209 Dream School',
  'Proviso West High School',
  'Provo Adult Education',
  'Provo Eschool',
  'Provo High',
  'Provo Peaks School',
  'Provost Academy Ohio',
  'Provost School',
  'Pruden Center For Industry/tech',
  'Prudence Crandall - Educational Counseling Program (ecp)',
  'Prudence Crandall School',
  'Prue Es',
  'Prue Hs',
  'Pruette Scale Academy',
  'Prune Hill Elem',
  'Prunedale Elementary',
  'Prussing Elem School',
  'Pryor 7-8',
  'Pryor Hs',
  'Pryor Jhs',
  'Ps 1 Alfred E Smith',
  'Ps 1 Bergen (the)',
  'Ps 1 Courtlandt School',
  'Ps 1 Tottenville',
  'Ps 10',
  'Ps 100 Coney Island School (the)',
  'Ps 100 Glen Morris',
  'Ps 100 Isaac Clason',
  'Ps 101 School In The Gardens',
  'Ps 101 Verrazano (the)',
  'Ps 102 Bayview',
  'Ps 102 Bayview (the)',
  'Ps 102 Jacques Cartier',
  'Ps 103 Hector Fontanez',
  'Ps 104 Bays Water (the)',
  'Ps 105 Bay School (the)',
  'Ps 105 Blythebourne (the)',
  'Ps 105 Senator Abraham Bernstein',
  'Ps 106 Edward Everett Hale',
  'Ps 106 Parkchester',
  'Ps 107',
  'Ps 107 John W Kimball',
  'Ps 107 Thomas A Dooley',
  'Ps 108 Assemblyman Angelo Del Toro Educational Complex',
  'Ps 108 Capt Vincent G Fowler',
  'Ps 108 Philip J Abinanti',
  'Ps 108 Sal Abbracciamento',
  'Ps 109',
  'Ps 109 Sedgwick',
  'Ps 11 Highbridge',
  'Ps 11 Kathryn Phelan',
  'Ps 11 Purvis J Behan',
  'Ps 11 Thomas Dongan School',
  'Ps 11 William T Harris',
  'Ps 110',
  'Ps 110 Florence Nightingale',
  'Ps 110 Monitor (the)',
  'Ps 110 Theodore Schoenfeld',
  'Ps 111 Adolph S Ochs',
  'Ps 111 Jacob Blackwell',
  'Ps 111 Seton Falls',
  'Ps 112 Bronxwood',
  'Ps 112 Dutch Kills',
  'Ps 112 Jose Celso Barbosa',
  'Ps 112 Lefferts Park',
  'Ps 114 Luis Lorens Torres School',
  'Ps 114 Ryder Elementary',
  'Ps 115 Alexander Humboldt',
  'Ps 115 Daniel Mucatel School',
  'Ps 116 Elizabeth L Farrell',
  'Ps 116 Mary Lindley Murray',
  'Ps 117 J Keld/briarwood School',
  'Ps 118 Lorraine Hansberry',
  'Ps 119 Amersfort',
  'Ps 12 James B Colgate',
  'Ps 12 Lewis And Clark School',
  'Ps 120',
  'Ps 120 Carlos Tapia',
  'Ps 121',
  'Ps 121 Nelson A Rockefeller',
  'Ps 121 Throop',
  'Ps 122 Mamie Fay',
  'Ps 123',
  'Ps 123 Mahalia Jackson',
  'Ps 123 Suydam',
  'Ps 124 Osmond A Church',
  'Ps 124 Silas B Dutcher',
  'Ps 124 Yung Wing',
  'Ps 125 Ralph Bunche',
  'Ps 126 Dr Marjorie H Dunbar',
  'Ps 126 Jacob August Riis',
  'Ps 127 Aerospace Science Magnet School',
  'Ps 127 Mckinley Park',
  'Ps 128 Audubon',
  'Ps 128 Bensonhurst',
  'Ps 128 The Lorraine Tuzzo Juniper Valley Elementary',
  'Ps 129 John H Finley',
  'Ps 129 Patricia Larkin',
  'Ps 13 Clement C Moore',
  'Ps 13 M L Lindemeyer',
  'Ps 13 Roberto Clemente',
  'Ps 130',
  'Ps 130 Abram Stevens Hewitt',
  'Ps 130 Hernando De Soto',
  'Ps 130 Parkside (the)',
  'Ps 131',
  'Ps 131 Abigail Adams',
  'Ps 132 Conselyea School (the)',
  'Ps 132 Garrett A Morgan',
  'Ps 132 Juan Pablo Duarte',
  'Ps 132 Ralph Bunche',
  'Ps 133',
  'Ps 133 Fred R Moore',
  'Ps 133 William A Butler',
  'Ps 134',
  'Ps 134 George F Bristow',
  'Ps 134 Henrietta Szold',
  'Ps 134 Hollis',
  'Ps 135 Sheldon A Brookner',
  'Ps 136 Roy Wilkins',
  'Ps 138',
  'Ps 138 Samuel Randall',
  'Ps 139 Alexine A Fenty',
  'Ps 139 Rego Park',
  'Ps 14',
  'Ps 14 Fairview',
  'Ps 14 Senator John Calandra',
  'Ps 140',
  'Ps 140 Eagle School (the)',
  'Ps 140 Edward K Ellington',
  'Ps 140 Nathan Straus',
  'Ps 141',
  'Ps 142 Amalia Castro',
  'Ps 143 Louis Armstrong',
  'Ps 144 Col Jeromus Remsen',
  'Ps 145 Andrew Jackson',
  'Ps 145 Bloomingdale School (the)',
  'Ps 146 Ann M Short',
  'Ps 146 Edward Collins',
  'Ps 146 Howard Beach',
  'Ps 147 Issac Remsen',
  'Ps 148',
  'Ps 149 Christa Mcauliffe',
  'Ps 149 Danny Kaye',
  'Ps 149 Sojourner Truth',
  'Ps 15 Institute For Environmental Learning',
  'Ps 15 Jackie Robinson',
  'Ps 15 Patrick F Daly',
  'Ps 15 Roberto Clemente',
  'Ps 150',
  'Ps 150 Charles James Fox',
  'Ps 150 Christopher',
  'Ps 151 Lyndon B Johnson',
  'Ps 151 Mary D Carter',
  'Ps 152 Dyckman Valley',
  'Ps 152 Evergreen',
  'Ps 152 Gwendoline N Alleyne School',
  'Ps 153 Adam Clayton Powell',
  'Ps 153 Helen Keller',
  'Ps 153 Homecrest',
  'Ps 153 Maspeth Elementary',
  'Ps 154',
  'Ps 154 Harriet Tubman',
  'Ps 154 Jonathan D Hyatt',
  'Ps 155',
  'Ps 155 William Paca',
  'Ps 156 Laurelton',
  'Ps 156 Waverly',
  'Ps 157 Grove Hill',
  'Ps 158 Baylard Taylor',
  'Ps 158 Warwick',
  'Ps 159',
  'Ps 159 Isaac Pitkin',
  'Ps 159 Luis Mumoz Marin Biling',
  'Ps 16',
  'Ps 16 John J Driscoll',
  'Ps 16 Leonard Dunkly',
  'Ps 16 Nancy Debenedittis School (the)',
  'Ps 16 Wakefield',
  'Ps 160 Walt Disney',
  'Ps 160 Walter Francis Bishop',
  'Ps 160 William T Sampson',
  'Ps 161 Arthur Ashe School',
  'Ps 161 Crown (the)',
  'Ps 161 Pedro Albizu Campos',
  'Ps 161 Ponce De Leon School',
  'Ps 162 John Golden',
  'Ps 163 Alfred E Smith',
  'Ps 163 Arthur A Schomberg',
  'Ps 163 Bath Beach',
  'Ps 163 Flushing Heights',
  'Ps 164 Caesar Rodney',
  'Ps 164 Queens Valley',
  'Ps 165 Edith K Bergtraum',
  'Ps 165 Ida Posner',
  'Ps 165 Robert E Simon',
  'Ps 166 Henry Gradstein',
  'Ps 166 Richard Rogers School Of The Arts & Science (the)',
  'Ps 168',
  'Ps 169 Bay Terrace',
  'Ps 169 Robert F Kennedy',
  'Ps 169 Sunset Park',
  'Ps 17',
  'Ps 17 Henry D Woodworth',
  'Ps 17 Henry David Thoreau',
  'Ps 170',
  'Ps 171 Patrick Henry',
  'Ps 171 Peter G Van Alst',
  'Ps 172 Beacon School Of Excellence',
  'Ps 173',
  'Ps 173 Fresh Meadows',
  'Ps 174 William Sidney Mount',
  'Ps 175 City Island',
  'Ps 175 Henry H Garnet',
  'Ps 175 Lynn Gross Discovery School (the)',
  'Ps 176',
  'Ps 176 Cambria Heights',
  'Ps 176 Ovington',
  'Ps 177',
  'Ps 177 Marlboro (the)',
  'Ps 178 Dr Selman Waksman',
  'Ps 178 Saint Clair Mckelway',
  'Ps 179',
  'Ps 179 Kensington',
  'Ps 18',
  'Ps 18 Edward Bush',
  'Ps 18 John G Whittier',
  'Ps 18 John Peter Zenger',
  'Ps 18 Park Terrace',
  'Ps 18 Winchester',
  'Ps 180 Hugo Newman',
  'Ps 181',
  'Ps 181 Brookfield',
  'Ps 182',
  'Ps 182 Samantha Smith',
  'Ps 183 Dr Richard R Green',
  'Ps 183 Robert L Stevenson',
  'Ps 184 Flushing Manor',
  'Ps 184 Newport',
  'Ps 184 Shuang Wen',
  'Ps 185 Early Chldhd Discovery',
  'Ps 185 Walter Kassenbrock',
  'Ps 186 Castlewood',
  'Ps 186 Dr Irving A Gladstone',
  'Ps 186 Walter J Damrosch School',
  'Ps 188',
  'Ps 188 Island School (the)',
  'Ps 188 Kingsbury',
  'Ps 188 Michael E Berdy',
  'Ps 189',
  'Ps 189 Bilingual Center (the)',
  'Ps 19 Asher Levy',
  'Ps 19 Curtis School (the)',
  'Ps 19 Judith K Weiss',
  'Ps 19 Marino Jeantet',
  'Ps 190 Sheffield',
  'Ps 191 Mayflower',
  'Ps 191 Paul Robeson',
  'Ps 192 Jacob H Schiff',
  'Ps 192 Magnet School For Math And Science Inquiry',
  'Ps 193 Alfred J Kennedy',
  'Ps 193 Gil Hodges',
  'Ps 194 Countee Cullen',
  'Ps 194 Raoul Wallenberg',
  'Ps 195',
  'Ps 195 Manhattan Beach',
  'Ps 195 William Haberle',
  'Ps 196',
  'Ps 196 Grand Central Parkway',
  'Ps 196 Ten Eyck',
  'Ps 197 John B Russwurm',
  'Ps 197 Kings Highway Academy (the)',
  'Ps 197 Ocean School (the)',
  'Ps 198',
  'Ps 198 Isador E Ida Straus',
  'Ps 199 Frederick Wachtel',
  'Ps 199 Jessie Isador Straus',
  'Ps 199 Maurice A Fitzgerald',
  'Ps 199 Shakespeare School (the)',
  'Ps 2',
  'Ps 2 Alfred Zimberg',
  'Ps 2 Meyer London',
  'Ps 20 Anna Silver',
  'Ps 20 Clinton Hill',
  'Ps 20 John Bowne',
  'Ps 20 Port Richmond',
  'Ps 200 Benson School',
  'Ps 200 James Mccune Smith School (the)',
  'Ps 201 Discovery School For Inquiry And Research (the)',
  'Ps 202 Ernest S Jenkyns',
  'Ps 203 Floyd Bennett School',
  'Ps 203 Oakland Gardens',
  'Ps 204 Morris Heights',
  'Ps 204 Vince Lombardi',
  'Ps 205 Alexander Graham Bell',
  'Ps 205 Clarion',
  'Ps 205 Fiorello Laguardia',
  'Ps 206 Horace Harding School (the)',
  'Ps 206 Jose Celso Barbosa',
  'Ps 206 Joseph F Lamb',
  'Ps 207',
  'Ps 207 Elizabeth G Leary',
  'Ps 207 Rockwood Park',
  'Ps 208 Alain L Locke',
  'Ps 208 Elsa Ebeling',
  'Ps 209',
  'Ps 209 Clearview Gardens',
  'Ps 209 Margaret Mead',
  'Ps 21 Crispus Attucks',
  'Ps 21 Edward Hart',
  'Ps 21 Margaret Emery-elm Park',
  'Ps 21 Philip H Sheridan',
  'Ps 211',
  'Ps 212',
  'Ps 212 Lady Deborah Moody',
  'Ps 212 Midtown West',
  'Ps 213 Carl Ullman School (the)',
  'Ps 213 New Lots',
  'Ps 214',
  'Ps 214 Cadwallader Colden',
  'Ps 214 Michael Friedsam',
  'Ps 215 Morris H Weiss',
  'Ps 216 Arturo Toscanini',
  'Ps 217 Colonel David Marcus School',
  'Ps 219 Kennedy-king',
  'Ps 219 Paul Klapper',
  'Ps 22 Graniteville',
  'Ps 22 Thomas Jefferson',
  'Ps 220 Edward Mandel',
  'Ps 221 North Hills School (the)',
  "Ps 221 Toussaint L'ouverture",
  'Ps 222 Katherine R Snyder',
  'Ps 222-fire Fighter Christopher A Santora School',
  'Ps 223 Lyndon B Johnson',
  'Ps 224',
  'Ps 224 Hale A Woodruff',
  'Ps 225 Eileen E Zaglin (the)',
  'Ps 226',
  'Ps 226 Alfred De B Mason',
  'Ps 228 Early Childhood Magnet School For The Arts',
  'Ps 229 Dyker',
  'Ps 229 Emanuel Kaplan',
  'Ps 23 At Queens Children Center',
  'Ps 23 Carter G Woodson',
  "Ps 23 New Children's School (the)",
  'Ps 23 Richmondtown',
  'Ps 230 Doris L Cohen',
  'Ps 231',
  'Ps 232 Lindenwood',
  'Ps 233',
  'Ps 233 Langston Hughes',
  'Ps 234',
  'Ps 234 Independence School',
  'Ps 235 Janice Marie Knight School',
  'Ps 236 Langston Hughes',
  'Ps 236 Mill Basin',
  'Ps 238 Anne Sullivan',
  'Ps 239',
  'Ps 24',
  'Ps 24 Andrew Jackson',
  'Ps 24 Spuyten Duyvil',
  'Ps 241 Emma L Johnston',
  'Ps 242 Leonard P Stavisky Early Childhood School',
  'Ps 242 Young Diplomats Magnet Academy (the)',
  'Ps 243 Weeksville School (the)',
  'Ps 244 Richard R Green',
  'Ps 245',
  'Ps 246 Poe Center',
  'Ps 247',
  'Ps 249 Caton (the)',
  'Ps 25 Bilingual School',
  'Ps 25 Eubie Blake School',
  'Ps 250 George H Lindsey',
  'Ps 251',
  'Ps 251 Paerdegat',
  'Ps 253',
  'Ps 254 Dag Hammarskjold',
  'Ps 254-rosa Parks School (the)',
  'Ps 255',
  'Ps 255 Barbara Reing School',
  'Ps 256',
  'Ps 256 Benjamin Banneker',
  'Ps 257 John F Hylan',
  'Ps 26 Carteret School (the)',
  'Ps 26 Jesse Owens',
  'Ps 26 Rufus King',
  'Ps 261 Philip Livingston',
  'Ps 262 El Hajj Malik El Shabazz Elementary School',
  'Ps 264 Bay Ridge Elementary School For The Arts',
  'Ps 268 Emma Lazarus',
  'Ps 269 Nostrand',
  'Ps 27 Hillery Park Academy',
  'Ps 270 Johann Dekalb',
  'Ps 272 Curtis Estabrook',
  'Ps 273',
  'Ps 273 Wortman',
  'Ps 274 Kosciusko',
  'Ps 276 Louis Marshall',
  'Ps 277',
  'Ps 277 Gerritsen Beach',
  'Ps 279 Capt Manuel Rivera Jr',
  'Ps 279 Herman Schreiber',
  'Ps 28 Mount Hope',
  'Ps 28 Thomas Emanuel Early Childhood Center (the)',
  'Ps 28 Warren Prep Academy (the)',
  'Ps 28 Wright Brothers',
  'Ps 280',
  'Ps 282 Park Slope',
  'Ps 287 Bailey K Ashford',
  'Ps 288 Shirley Tanyhill (the)',
  'Ps 289 George V Brower',
  'Ps 29',
  'Ps 29 Bardwell',
  'Ps 29 John M Harrigan',
  'Ps 290 Juan Morel Campos',
  'Ps 290 Manhattan New School',
  'Ps 291',
  'Ps 295',
  'Ps 297 Abraham Stockton',
  'Ps 298 Dr Betty Shabazz',
  'Ps 299 Thomas Warren Field School',
  'Ps 3 Bedford Village (the)',
  'Ps 3 Charrette School',
  'Ps 3 Margaret Gioiosa School (the)',
  'Ps 3 Raul Julia Micro Society',
  'Ps 30 Hernandez/hughes',
  'Ps 30 Westerleigh',
  'Ps 30 Wilton',
  'Ps 304 Early Childhood School',
  'Ps 305 Dr Peter Ray',
  'Ps 306',
  'Ps 306 Ethan Allen',
  'Ps 307 Daniel Hale Williams',
  'Ps 308 Clara Cardwell',
  'Ps 309 George E Wibecan Preparatory Academy',
  'Ps 31 Bayside',
  'Ps 31 Samuel F Dupont',
  'Ps 31 William T Davis',
  'Ps 310 Marble Hill',
  'Ps 312 Bergen Beach',
  'Ps 315',
  'Ps 315 Lab School',
  'Ps 316 Elijah Stroud',
  'Ps 319',
  'Ps 32 Belmont',
  'Ps 32 Gifford School (the)',
  'Ps 32 Samuels Mills Sprole',
  'Ps 32 State Street',
  'Ps 321 William Penn',
  'Ps 325',
  'Ps 326',
  'Ps 327 Dr Rose B English',
  'Ps 328 Phyllis Wheatley',
  'Ps 329 Surfside',
  'Ps 33 Chelsea Prep',
  'Ps 33 Edward M Funk',
  'Ps 33 Timothy Dwight',
  'Ps 330',
  'Ps 333 Manhattan School For Children',
  'Ps 335 Granville T Woods',
  'Ps 34 Franklin D Roosevelt',
  'Ps 34 John Harvard',
  'Ps 34 Oliver H Perry',
  'Ps 340',
  'Ps 345 Patrolman Robert Bolden',
  'Ps 346 Abe Stark',
  'Ps 35',
  'Ps 35 Clove Valley School (the)',
  'Ps 35 Franz Siegel',
  'Ps 35 Nathaniel Woodhull',
  'Ps 36',
  'Ps 36 J C Drumgoole',
  'Ps 36 Margaret Douglas',
  'Ps 36 St Albans School',
  'Ps 36 Unionport',
  'Ps 360',
  'Ps 361 East Flatbush Early Childhood School',
  'Ps 368',
  'Ps 369 Coy L Cox School',
  'Ps 37',
  'Ps 37 Multiple Intelligence School',
  'Ps 370',
  'Ps 371 Lillian L Rashkis',
  "Ps 372 Children's School (the)",
  'Ps 373',
  'Ps 373 Brooklyn Transition Center',
  'Ps 375 Jackie Robinson School',
  'Ps 376',
  'Ps 377',
  'Ps 377 Alejandrina B De Gautier',
  'Ps 38 George Cromwell',
  'Ps 38 Pacific (the)',
  'Ps 38 Roberto Clemente',
  'Ps 38 Rosedale',
  'Ps 380 John Wayne Elementary',
  'Ps 39 Francis J Murphy Jr',
  'Ps 39 Henry Bristow',
  'Ps 396',
  'Ps 397 Foster-laurie',
  'Ps 398 Walter Weaver',
  'Ps 399 Stanley Eugene Clarke',
  'Ps 4 Duke Ellington',
  'Ps 4 Maurice Wollin',
  'Ps 40 Augustus Saint-gaudens',
  'Ps 40 George W Carver',
  'Ps 40 Samuel Huntington',
  'Ps 41 Crocheron',
  'Ps 41 Francis White',
  'Ps 41 Greenwich Village',
  'Ps 41 Gun Hill Road',
  'Ps 42 Benjamin Altman',
  'Ps 42 Claremont',
  'Ps 42 Eltingville',
  'Ps 42 Occupational Training Center',
  'Ps 43',
  'Ps 43 Jonas Bronck',
  'Ps 44 David C Farragut',
  'Ps 44 Marcus Garvey',
  'Ps 44 Thomas C Brown',
  'Ps 45 Clarence Witherspoon',
  'Ps 45 John Tyler',
  'Ps 452',
  'Ps 46 Albert V Maniscalco',
  'Ps 46 Alley Pond',
  'Ps 46 Arthur Tappan',
  'Ps 46 Edgar Allan Poe',
  'Ps 46 Edward C Blum',
  'Ps 47 Chris Galas',
  'Ps 47 John Randolph',
  'Ps 48 Joseph R Drake',
  'Ps 48 Mapleton',
  'Ps 48 Po Michael J Buczek',
  'Ps 48 William G Wilcox',
  'Ps 48 William Wordsworth',
  'Ps 49 Dorothy Bonawit Kole',
  'Ps 49 Willis Avenue',
  'Ps 5 Dr Ronald Mcnair',
  'Ps 5 Ellen Lurie',
  'Ps 5 Huguenot',
  'Ps 5 Port Morris',
  'Ps 50 Frank Hankinson',
  'Ps 50 Talfourd Lawn Elementary School',
  'Ps 50 Vito Marcantonio',
  'Ps 503 School Of Discovery (the)',
  'Ps 506 School Of Journalism And Technology (the)',
  'Ps 51',
  'Ps 51 Bronx New School',
  'Ps 51 Elias Howe',
  'Ps 52',
  'Ps 52 John C Thompson',
  'Ps 52 Sheepshead Bay',
  'Ps 527 East Side School For Social Action',
  'Ps 53',
  'Ps 53 Basheer Quisim',
  'Ps 536',
  'Ps 54 Charles W Leng',
  'Ps 54 Hillside',
  'Ps 54 Samuel C Barnes',
  'Ps 55 Benjamin Franklin',
  'Ps 55 Henry M Boehm',
  'Ps 55 Maure',
  'Ps 56 Harry Eichler',
  'Ps 56 Lewis H Latimer',
  'Ps 56 Louis Desario School (the)',
  'Ps 56 Norwood Heights',
  'Ps 57 Crescent',
  'Ps 57 Hubert H Humphrey',
  'Ps 58',
  'Ps 58 Carroll (the)',
  'Ps 58 School Of Heroes',
  'Ps 583',
  'Ps 59 Beekman Hill International',
  'Ps 59 Community School Of Technology (the)',
  'Ps 59 Dr Charles Drew Science Magnet',
  'Ps 59 William Floyd',
  'Ps 6 Cpl Allan F Kivlehan School',
  'Ps 6 Lillie D Blake',
  'Ps 6 West Farms',
  'Ps 60 Alice Austen',
  'Ps 60 Woodhaven',
  'Ps 61 Arthur O Eve School Of Distinction',
  'Ps 61 Francisco Oller',
  'Ps 62 Chester Park School',
  'Ps 62 Inocensio Casanova',
  "Ps 63 Author's Academy",
  'Ps 63 Old South',
  'Ps 64 Frederick Law Olmsted',
  'Ps 64 Joseph P Addabbo',
  'Ps 64 Robert Simon',
  'Ps 65',
  'Ps 65 Academy Of Innovative Learning (the)',
  'Ps 65 Mother Hale Academy',
  'Ps 65 Raymond York Elementary School (the)',
  'Ps 65 Roosevelt Ecc',
  'Ps 66',
  'Ps 66 Jacqueline Kennedy-onassis',
  'Ps 66 North Park Middle Academy',
  'Ps 66 School Of Higher Expectations',
  'Ps 67 Charles A Dorsey',
  'Ps 67 Mohegan School',
  'Ps 68',
  'Ps 68 Cambridge',
  'Ps 69 Daniel D Tompkins',
  'Ps 69 Houghton Academy',
  'Ps 69 Jackson Heights',
  'Ps 69 Journey Prep School',
  'Ps 69 Vincent D Grippo School',
  'Ps 7 Abraham Lincoln',
  'Ps 7 Louis F Simeone',
  'Ps 7 Samuel Stern',
  'Ps 70',
  'Ps 70 Max Schoenfeld',
  'Ps 71 Forest',
  'Ps 71 Rose E Scala',
  'Ps 72 Dr William Dorney',
  'Ps 721 Brooklyn Occupational Training Center',
  'Ps 721 Manhattan Occupational Training Center',
  'Ps 721 Stephen Mcsweeney School',
  'Ps 723',
  'Ps 73 Bronx',
  'Ps 74 Future Leaders Elementary School',
  'Ps 74 Hamlin Park - Claude And Ouida Clapp Academy',
  'Ps 748 Brooklyn School For Global Scholars',
  'Ps 75 Emily Dickinson',
  'Ps 75 Mayda Cortiella',
  'Ps 75 School Of Research And Discovery',
  'Ps 753 School For Career Development',
  'Ps 76 A Phillip Randolph',
  'Ps 76 Bennington School (the)',
  'Ps 76 William Hallet',
  'Ps 77',
  'Ps 77 Lower Lab School',
  'Ps 770 New American Academy',
  'Ps 771',
  'Ps 78',
  'Ps 78 Anne Hutchinson',
  'Ps 79 Francis Lewis',
  'Ps 79 Horan School',
  'Ps 8 Issac Varian',
  'Ps 8 Luis Belliard',
  'Ps 8 Robert Fulton',
  'Ps 8 Shirlee Solomon',
  'Ps 80 Thurgood Marshall Magnet Of Multimedia',
  'Ps 81',
  'Ps 81 Jean Paul Richter',
  'Ps 81 Robert J Christen',
  'Ps 81 Thaddeus Stevens',
  'Ps 811 Connie Lekas School',
  'Ps 811 Mickey Mantle School',
  'Ps 82',
  'Ps 82 Hammond',
  'Ps 83 Donald Hertz',
  'Ps 83 Luis Munoz Rivera',
  'Ps 84',
  'Ps 84 Jose De Diego',
  'Ps 84 Lillian Weber',
  'Ps 84 Steinway',
  'Ps 85 Great Expectations',
  'Ps 85 Judge Charles Vallone',
  'Ps 86',
  'Ps 86 Irvington (the)',
  'Ps 86 Kingsbridge Heights',
  'Ps 87',
  'Ps 87 William Sherman',
  'Ps 88 S Silverstein Little Sparrow School',
  'Ps 88 Seneca',
  'Ps 889',
  'Ps 89',
  'Ps 89 Cypress Hills',
  'Ps 89 Elmhurst',
  'Ps 9',
  'Ps 9 Ryer Avenue Elementary School',
  'Ps 9 Sarah Anderson',
  'Ps 9 Teunis G Bergen',
  'Ps 90 Edna Cohen School',
  'Ps 90 Horace Mann',
  'Ps 91',
  'Ps 91 Albany Avenue School (the)',
  'Ps 91 Richard Arkwright',
  'Ps 92',
  'Ps 92 Adrian Hegeman',
  'Ps 92 Harry T Stewart Sr',
  'Ps 92 Mary Mcleod Bethune',
  'Ps 93 Albert G Oliver',
  'Ps 93 William H Prescott',
  'Ps 94',
  'Ps 94 David D Porter',
  'Ps 94 Henry Longfellow (the)',
  'Ps 94 Kings College School',
  'Ps 95 Eastwood',
  'Ps 95 Gravesend (the)',
  'Ps 95 Sheila Mencher',
  'Ps 96',
  'Ps 96 Joseph Lanzetta',
  'Ps 96 Richard Rodgers',
  'Ps 97',
  'Ps 97 Forest Park',
  'Ps 97 Highlawn (the)',
  'Ps 98 Douglaston School (the)',
  'Ps 98 Shorac Kappock',
  'Ps 99 Isaac Asimov',
  'Ps 99 Kew Gardens',
  'Ps 993',
  'Ps Berg Elementary School',
  'Ps K004',
  'Ps Q004',
  'Ps Q811',
  'Ps X811',
  'Ps/is 104 Fort Hamilton School (the)',
  'Ps/is 113 Anthony J Pranzo',
  'Ps/is 116 William C Hughley',
  'Ps/is 137 Rachael Jean Mitchell',
  'Ps/is 155 Nicholas Herkimer',
  'Ps/is 157 Benjamin Franklin Heath And Science (the)',
  'Ps/is 178 Holliswood',
  'Ps/is 187 Hudson Cliffs',
  'Ps/is 208',
  'Ps/is 210 21st Century Academy',
  'Ps/is 217 Roosevelt Island',
  'Ps/is 218 Rafael Hernandez Dual Language Magnet School',
  'Ps/is 224',
  'Ps/is 266',
  'Ps/is 268',
  'Ps/is 295',
  'Ps/is 30 Mary White Ovington',
  'Ps/is 323',
  'Ps/is 384 Frances E Carter',
  'Ps/is 45 Horace E Greene',
  'Ps/is 54',
  'Ps/is 78',
  'Ps/is 87 Middle Village',
  'Ps/ms 114 Belle Harbor',
  'Ps/ms 138 Sunrise',
  'Ps/ms 147 Ronald Mcnair',
  'Ps/ms 194',
  'Ps/ms 20 Po George J Werdan Iii',
  'Ps/ms 200 Pomonok School And Star Academy (the)',
  'Ps/ms 280 Mosholu Parkway',
  'Ps/ms 29 Melrose School',
  'Ps/ms 31 William Lloyd Garrison (the)',
  'Ps/ms 4 Crotona Park West',
  'Ps/ms 42 R Vernam',
  'Ps/ms 498 Van Nest Academy',
  'Psa Civic Leadership High School',
  'Psd Correspondence Program',
  'Psd Global Academy',
  'Psd Options School',
  'Psd Special Services',
  'Psja Early College H S',
  'Psja Early Start',
  'Psja Memorial Early College H S',
  'Psja North Early College H S',
  'Psja Sonia M Sotomayor H S',
  'Psja Southwest Early College H S',
  'Psja Thomas Jefferson T-stem Early College H S',
  'Psrc Early College At Rcc',
  'Pt. Pleasant Intermediate',
  'Pt. Pleasant Primary',
  'Ptarmigan Elementary',
  'Ptarmigan Ridge Elementary School',
  'Pte Tremble Elementary School',
  'Public Academy For Performing Arts',
  'Public Policy Charter',
  'Public Safety Academy',
  'Public School Number Five',
  'Public School Number One',
  'Public School Number Two',
  'Public Service Community At Diego Rivera Learning Complex',
  'Public Service Leadership Academy At Fowler',
  'Puc Achieve Charter School',
  'Puc Cals Middle School And Early College High',
  'Puc Comm Charter Mid And Puc Comm Charter Early College High',
  'Puc Community Charter Elementary',
  'Puc Early College Academy For Leaders And Scholars (ecals)',
  'Puc Excel Charter Academy',
  'Puc Inspire Charter Academy',
  'Puc International Preparatory Academy',
  'Puc Lakeview Charter Academy',
  'Puc Lakeview Charter High',
  'Puc Milagro Charter',
  'Puc Nueva Esperanza Charter Academy',
  'Puc Santa Rosa Charter Academy',
  'Puc Triumph Charter Academy And Puc Triumph Charter High',
  'Puckett Attendance Center',
  'Puckett El',
  'Puckett Elementary School',
  "Puckett's Mill Elementary School",
  'Pueblo Academy Of Arts',
  'Pueblo Charter School For The Arts & Sciences',
  'Pueblo Community College',
  'Pueblo County High School',
  'Pueblo De Los Angeles Continuation',
  'Pueblo Del Sol Elementary School',
  'Pueblo Del Sol Middle School',
  'Pueblo Elementary School',
  'Pueblo Gardens Elementary',
  'Pueblo High School',
  'Pueblo Pintado Community School',
  'Pueblo Sch. For Arts & Sciences At Fulton Heights',
  'Pueblo Vista Elementary',
  'Pueblo West High School',
  'Pueblo Youth Service Center',
  'Puente Blanco',
  'Puente Charter',
  'Puente Hills High',
  'Puesta Del Sol Elementary',
  'Puesta Del Sol Elementary School',
  'Puget Sound High School',
  'Puget Sound Skills Center',
  'Pugh El',
  'Pugliese Elementary West',
  'Pugsley Correctional Facility',
  'Pukalani Elementary School',
  'Pukyong National University',
  'Pulaski (casimir) Elementary School',
  'Pulaski Community Middle',
  'Pulaski County Area Technology Center',
  'Pulaski County Elementary School',
  'Pulaski County High School',
  'Pulaski County Middle School',
  "Pulaski County Public Schools Governor's Stem Academy",
  'Pulaski County Senior High',
  'Pulaski Day Treatment~eagle Academy',
  'Pulaski El Sch',
  'Pulaski Elementary',
  'Pulaski Elementary School',
  'Pulaski Elementarymiddle School',
  'Pulaski Heights Elem. School',
  'Pulaski Heights Middle School',
  'Pulaski High',
  'Pulaski High School',
  'Pulaski Intl Sch Of Chicago',
  'Pulaski Middle',
  'Pulaski Middle School',
  'Pulaski Road School',
  'Pulaski Street Elementary School',
  'Pulaski Technical College',
  'Pullam El',
  'Pullen Elementary School',
  'Pulley Career Center',
  'Pulliam Elementary',
  'Pullman Elem School',
  'Pullman Elementary',
  'Pullman Elementary School',
  'Pullman High School',
  'Puls Elem',
  'Pumpkin Center Intermediate',
  'Pumpkin Center Primary',
  'Pumpkin Delight School',
  'Pungoteague Elementary',
  'Punjab Agricultural University',
  'Punjab Engineering College',
  'Punjab Institute of Computer science',
  'Punjab Technical University',
  'Punjab University College of Information Tecnology',
  'Punjabi University Patiala',
  'Punta Gorda Middle School',
  'Puntland State University',
  'Punxsutawney Area Hs',
  'Punxsutawney Area Ms',
  'Puohala Elementary School',
  'Pupil Personnel Campus',
  'Purbanchal University',
  'Purcell Elementary School',
  'Purcell Es',
  'Purcell Hs',
  'Purcell Intermediate School',
  'Purcell Jhs',
  'Purchase Line El Sch',
  'Purchase Line Jshs',
  'Purchase School',
  'Purchase Youth Village',
  'Purche Avenue Elementary',
  'Purdue Polytechnic High School Ind',
  'Purdue University',
  'Purdue University Calumet',
  'Purdue University North Central',
  'Purdy Elem.',
  'Purdy Elementary',
  'Purdy Elementary School',
  'Purdy High',
  'Purdy Middle',
  'Purefoy El',
  'Purificacion Rodriguez',
  'Purnell Swett High',
  'Purple Heart El',
  'Purple Panthers Preschool',
  'Purple Sage El',
  'Purple Sage Elementary',
  'Purpose Prep',
  'Purvis High School',
  'Purvis Lower Elementary',
  'Purvis Middle School',
  'Purvis Upper Elementary',
  'Pusan National University',
  'Pusan National University of Education',
  'Pusan University of Foreign Studies',
  "Pusan Women's University",
  'Put-in-bay Elementary School',
  'Put-in-bay High School',
  'Putegnat El',
  'Puterbaugh Ms',
  'Putman Elementary School',
  'Putnam Academy Of Arts And Sciences',
  'Putnam Avenue Upper School',
  'Putnam Career & Technical Center',
  'Putnam Central School',
  'Putnam City Hs',
  'Putnam City North Hs',
  'Putnam City West Hs',
  'Putnam Co Primary Sch',
  'Putnam Co. Elem.',
  'Putnam Co. High',
  'Putnam Co. Middle',
  'Putnam County Correctional Facility',
  'Putnam County Elem School',
  'Putnam County Elementary School',
  'Putnam County Exceptional Education',
  'Putnam County High School',
  'Putnam County Jr High School',
  'Putnam County Middle School',
  'Putnam County Primary School',
  'Putnam Edge High School',
  'Putnam El',
  'Putnam Elementary School',
  'Putnam Heights Elementary',
  'Putnam Heights Es',
  'Putnam High School',
  'Putnam Middle School',
  'Putnam Valley Elementary School',
  'Putnam Valley High School',
  'Putnam Valley Middle School',
  'Putnam Virtual Franchise',
  'Putnam Virtual Instruction Program',
  'Putnam Virtual Instruction Program (district Provided)',
  'Putnam-westchester Boces',
  'Putney Central School',
  'Puu Kukui Elementary School',
  'Puuhale Elementary School',
  'Puxico Elem.',
  'Puxico High',
  'Puxico Jr. High',
  'Puyallup High School',
  'Puyallup Parent Partnership Program',
  'Pvhs Aoi Program',
  'Pvonline',
  'Pwani University',
  'Pyburn El',
  'Pyle (sarah) Academy',
  'Pyle Elementary',
  'Pymatuning Valley High School',
  'Pymatuning Valley Middle School',
  'Pymatuning Valley Primary Elementary School',
  'Pyne Arts',
  'Pyongtaek University',
  'Pyongyang University of Science and Technology',
  'Pyramid Education Center',
  'Pyramid Lake High School',
  'Pyron Elementary School',
  'Pyrtle Elementary School',
  'Pázmány Péter Catholic University',
  'Pädagogische Hochschule Erfurt/Mühlhausen',
  'Pädagogische Hochschule Freiburg',
  'Pädagogische Hochschule Heidelberg',
  'Pädagogische Hochschule Karlsruhe',
  'Pädagogische Hochschule Ludwigsburg',
  'Pädagogische Hochschule Schwäbisch Gmünd',
  'Pädagogische Hochschule Weingarten',
  'Q.i. Roberts Jr. - Sr. High School',
  'QALAM COLLEGIATE ACADEMY',
  'QSAC SCHOOL',
  'QUAD CITIES CHRISTIAN SCHOOL',
  'QUAD CITY MONTESSORI SCHOOL',
  'QUAD PREPARATORY SCHOOL (THE)',
  'QUAKERTOWN CHRISTIAN SCHOOL',
  'QUALITY EDUCATION INSTITUTE OF DURHAM',
  'QUALITY TIME EARLY LEARNING CENTER',
  'QUALITY TIME LEARNING CENTER',
  'QUARRY ROAD SCHOOL',
  'QUBA INSTITUTE DAY SCHOOL',
  'QUEEN OF ALL SAINTS CATHOLIC ACADEMY',
  'QUEEN OF ALL SAINTS CATHOLIC SCHOOL',
  'QUEEN OF ALL SAINTS SCHOOL',
  'QUEEN OF ANGELS CATHOLIC SCHOOL',
  'QUEEN OF ANGELS ELEMENTARY SCHOOL',
  'QUEEN OF ANGELS SCHOOL',
  'QUEEN OF APOSTLES CATHOLIC SCHOOL',
  'QUEEN OF APOSTLES GRADE SCHOOL',
  'QUEEN OF HEAVEN CATHOLIC SCHOOL',
  'QUEEN OF HEAVEN SCHOOL',
  'QUEEN OF MARTYRS ELEMENTARY SCHOOL',
  'QUEEN OF PEACE CATHOLIC ACADEMY',
  'QUEEN OF PEACE CATHOLIC SCHOOL',
  'QUEEN OF PEACE ELEMENTARY SCHOOL',
  'QUEEN OF PEACE MONTESSORI SCHOOL',
  'QUEEN OF PEACE SCHOOL',
  'QUEEN OF THE APOSTLES GRADE SCHOOL',
  'QUEEN OF THE HOLY ROSARY ACADEMY',
  'QUEEN OF THE HOLY ROSARY SCHOOL',
  'QUEEN OF THE MIRACULOUS MEDAL SCHOOL',
  'QUEEN OF THE ROSARY SCHOOL',
  'QUEEN OF THE UNIVERSE SCHOOL',
  "QUEEN'S DAUGHTERS DAY CARE CENTER INC.",
  'QUEENS CENTERS FOR PROGRESS (UCP OF QUEENS)',
  'QUEST ACADEMY',
  'QUEST KIDS',
  'QUEST MONTESSORI SCHOOL',
  'QUEST SCHOOL',
  'QUESTA MIDDLE SCHOOL',
  'QUINCY AREA CHRISTIAN SCHOOL ASSOCIATION',
  'QUINCY CATHOLIC ACADEMY',
  'QUINCY NOTRE DAME HIGH SCHOOL',
  'QUINCY VALLEY SCHOOL',
  'Qafqaz University',
  'Qassim Private College',
  'Qassim University',
  'Qauid-e-Awam University of Engineering Sciences & Technology',
  'Qazvin University of Medical Sciences',
  'Qingdao University',
  'Qingdao University of Science and Technology',
  'Qinghai Normal University',
  'Qinghai Radio & Television University',
  'Qinghai University',
  'Qiongzhou University',
  'Qom University',
  'Qom University of Medical Sciences',
  'Qom University of Technology',
  'Quabbin Regional High School',
  'Quabbin Regional Middle School',
  'Quaboag Regional High',
  'Quaboag Regional Middle Innovation School',
  'Quaid-i-Azam University',
  'Quail Creek Es',
  'Quail Glen Elementary',
  'Quail Hollow Elementary School',
  'Quail Hollow Middle',
  'Quail Hollow School',
  'Quail Lake Environmental Charter',
  'Quail Run Elementary',
  'Quail Run Elementary School',
  'Quail Summit Elementary',
  'Quail Valley El',
  'Quail Valley Elementary',
  'Quail Valley Middle',
  'Quailwood Elementary',
  'Quaker Digital Academy',
  'Quaker Farms School',
  'Quaker Hill Elementary School',
  'Quaker Ridge School',
  'Quaker Valley Hs',
  'Quaker Valley Ms',
  'Quakertown Community Hs',
  'Quakertown El Sch',
  'Quality Education Academy',
  'Quanah H S',
  'Quander Road School',
  'Quannah Mccall Elementary School',
  'Quantum Academy',
  'Quantum High School',
  'Quapaw Es',
  'Quapaw Hs',
  'Quapaw Ms',
  'Quarry Hill Community School',
  'Quarry Hill El Sch',
  'Quarryville El Sch',
  'Quarter Mile Lane School',
  'Quarterfield Elementary',
  'Quarton Elementary',
  'Quartz Hill Elementary',
  'Quartz Hill High',
  'Quartzsite Elementary School',
  'Quashnet School',
  'Quatama Elementary School',
  'Quebrada Grande',
  'Quebrada Honda',
  'Quebrada Larga',
  'Quebradas',
  'Queen Anne Elementary',
  'Queen Anne Place Elementary',
  "Queen Anne's County High School",
  'Queen Arwa University',
  'Queen City H S',
  'Queen City Stem School',
  'Queen Creek Elementary School',
  'Queen Creek High School',
  'Queen Creek Middle School',
  'Queen Creek Virtual Academy',
  'Queen Kaahumanu Elementary School',
  'Queen Mary and Westfield College University of London',
  'Queen Palmer Elementary School',
  "Queen's Grant Community School",
  "Queen's University",
  'Queens Academy High School',
  'Queens College School For Math Science & Technology (the)',
  'Queens Collegiate - A College Board School',
  'Queens Creek Elementary',
  'Queens Explorers Elementary School',
  'Queens Gateway To Health Sciences Secondary School',
  'Queens High School For Information Research And Technology',
  'Queens High School For Language Studies',
  'Queens High School For The Sciences At York College',
  'Queens High School Of Teaching Liberal Arts And Sciences',
  'Queens Int',
  'Queens Lake Middle',
  'Queens Metropolitan High School',
  'Queens Preparatory Academy',
  'Queens Satellite High School For Opportunity',
  'Queens School For Leadership (the)',
  'Queens School Of Inquiry (the)',
  'Queens Transition Center',
  'Queens United Middle School',
  'Queens University',
  'Queens Vocational And Technical High School',
  'Queensborough Elementary School',
  'Queensbury Elementary School',
  'Queensbury Middle School',
  'Queensbury Senior High School',
  'Queensland University of Technology',
  'Queets-clearwater Elementary',
  'Quemado Elementary',
  'Quemado High',
  'Quemados',
  'Quentin Brown Primary K-4',
  'Quentin Elementary School',
  'Quest',
  'Quest Academy',
  'Quest Academy At Dacono',
  'Quest Academy Bridger',
  'Quest Academy Northwest',
  'Quest Academy West Alexander',
  'Quest Charter Academy',
  'Quest Charter School',
  'Quest Charter School Academy',
  'Quest Community School',
  'Quest Day Treatment',
  'Quest Early College H S',
  'Quest Elementary School',
  'Quest H S',
  'Quest High School',
  'Quest Leadership Academy',
  'Quest Middle Of Coppell',
  'Quest Middle School Of Little Rock',
  'Quest Middle School Of Pine Bluff',
  'Quest To Learn',
  'Quest University',
  'Questa High',
  'Questa Jr High',
  'Qufu Normal University',
  'Qugcuun Memorial School',
  'Quibbletown Middle School',
  'Quiet Waters Elementary School',
  'Quil Ceda Tulalip Elementary',
  'Quilcene High And Elementary',
  'Quileute Tribal School',
  'Quimby Middle School',
  'Quimby Oak Middle',
  'Quince Orchard High',
  'Quincy College',
  'Quincy Elem',
  'Quincy Elementary',
  'Quincy Elementary School',
  'Quincy High',
  'Quincy High School',
  'Quincy High Tech High',
  'Quincy Jones Elementary',
  'Quincy Jr High School',
  'Quincy Junior High',
  'Quincy Junior/senior High',
  'Quincy Middle School',
  'Quincy Sr High School',
  'Quincy University',
  'Quincy Upper School',
  'Quindaro Elem',
  'Quinebaug Middle College',
  'Quinebaug Valley Community College',
  'Quinn Campus Public Middle',
  'Quinney Elementary',
  'Quinnipiac College',
  'Quinnipiac Real World Math Stem School',
  'Quinsigamond',
  'Quinsigamond Community College',
  'Quinter Elem',
  'Quinter Jr-sr High',
  'Quinton Es',
  'Quinton Hs',
  'Quinton Township School',
  'Quioccasin Middle School',
  'Quitman Alternative School',
  'Quitman Co Vocational Complex',
  'Quitman Community School',
  'Quitman County Elementary',
  'Quitman County Elementary School',
  'Quitman County High School',
  'Quitman County Middle School',
  'Quitman El',
  'Quitman Elementary School',
  'Quitman H S',
  'Quitman High School',
  'Quitman J H',
  'Quitman Jr High School',
  'Quitman Lower Elementary School',
  'Quitman Upper Elementary School',
  'Qulin Elem.',
  'Quogue Elementary School',
  'Qurtuba University of Science and Infromation Technology',
  'R A Brown Middle School',
  'R A Hall El',
  'R A Hubbard High School',
  'R A Long High School',
  'R B Chamberlin Middle School',
  'R B Dean Elementary',
  'R Brown Mcallister Elementary',
  'R C Barton Middle',
  'R C Flores-mark A Zapata El',
  'R C Hinsdale Elementary School',
  'R C Loflin Middle',
  'R C Waters Elementary School',
  'R D Brown Voc Center',
  'R D Mcadams J H',
  'R D S P D',
  'R E A D Y Program',
  'R E Bennett Elementary',
  'R E Jewell Elementary School',
  'R E Lee El',
  'R E Simpson School',
  'R E Stevenson Elementary School',
  'R E Thompson Intermediate School',
  'R E Tobler Elementary School',
  'R F Hartman El',
  'R F Patterson El',
  'R Freeman Sch Of Engineering',
  'R G Drage Career Technical Ctr',
  'R Grant Graham Elem School',
  'R Guild Gray Elementary School',
  'R H Bearden Elementary School',
  'R Homer Andrews Elementary',
  'R Howell Elementary',
  'R J Baskett Middle School',
  'R J HENDLEY CHRISTIAN COMMUNITY SCHOOL',
  'R J Murray Middle School',
  'R J Reynolds High',
  'R K Welsh Elem School',
  'R L Brown Gifted And Talented Academy',
  'R L Stevenson Pri',
  'R L Thornton El',
  'R L Wright Elem',
  'R M Marrs Magnet Middle School',
  'R Max Abbott Middle',
  'R N Harris Elementary',
  'R Nelson Snider High School',
  'R O W V A Elem School',
  'R O W V A Jr And Sr High School',
  'R Pete Woodard Jr High School',
  'R Q Sims Int',
  'R Steve Folsom El',
  'R Stewart Esten',
  'R T Fisher Alternative',
  'R T HUDSON ELEMENTARY SCHOOL',
  'R V Groves El',
  'R V Haderlein Elem',
  'R Yzaguirre Middle',
  'R-5 High School',
  'R-s Central High School',
  'R-s Middle School',
  'R. A. Doyle Elem.',
  'R. B. Hunt Elementary School',
  'R. B. Stall High',
  'R. Bruce Wagner Elementary School',
  'R. C. Bannerman Learning Center',
  'R. C. Edwards Middle',
  'R. C. Lipscomb Elementary School',
  'R. Clem Churchwell Elementary School',
  'R. D. Anderson Applied Technology Center',
  'R. D. White Elementary',
  'R. D. Wood Elementary School',
  'R. Dan Nolan Middle School',
  'R. Dean Kilby Elementary',
  'R. Dudley Seymour School',
  'R. E. Davis Elementary',
  'R. Earle Davis Early Childhood Center For Technology',
  'R. F. Hazard Elementary',
  'R. Frank Nims Middle School',
  'R. G. Central Middle',
  'R. H. Conwell',
  'R. H. Fulmer Middle',
  'R. I. Meyerholz Elementary',
  'R. J. Longstreet Elementary School',
  'R. J. Neutra',
  'R. K. Finn Ryan Road',
  'R. K. Lloyde High',
  'R. L. Stevenson Elementary',
  'R. M. Bacon Elementary School',
  'R. M. Miano Elementary',
  'R. M. Moore Elementary School',
  'R. O. Hardin Elementary',
  'R. P. Dawkins Middle',
  'R. Paul Krey Elementary',
  'R. Rex Parris High',
  'R. Roger Rowe Elementary',
  'R. Roger Rowe Middle',
  'R. Sarracino Middle',
  'R. V. Daniels Elementary School',
  'R. V. Kerr Elementary School',
  'R.C. SMITH CHRISTIAN ACADEMY',
  'R.c. Longan Elementary',
  'R.e. Baker Elementary School',
  'R.h. Long Booneville Middle School',
  'R.i. School For The Deaf',
  'R.i.s.e. Program',
  'R.j. Frank Academy Of Marine Science & Engineering',
  'R.j. Vial Elementary School',
  'R.k. Smith Middle School',
  'R.m. Sweeney Elementary',
  'R.o. Nelson Elementary',
  'R.p. Pablo Gutierrez Flores',
  'R.v.traylor Elementary',
  'R1ts',
  'RABBI ARTHUR SCHNEIER PARK EAST DAY SCHOOL',
  'RABBI DAVID L SILVER YESHIVA ACADEMY',
  'RABBI JACOB JOSEPH SCHOOL',
  'RABBI JACOB JOSEPH SCHOOL FOR BOYS',
  'RABBI JACOB PRESSMAN ACADEMY',
  'RABBI PESACH RAYMON YESHIVA',
  'RABBIT COUNTRY SCHOOL',
  'RABUN GAP NACOOCHEE SCHOOL',
  'RABUN-GAP NACOOCHEE SCHOOL',
  'RACCOON VALLEY SCHOOL',
  'RACINE CHRISTIAN SCHOOL',
  'RACINE MONTESSORI SCHOOL',
  'RADCLIFFE CREEK SCHOOL',
  'RADCLIFFE LEARNING CENTER',
  'RADFORD SCHOOL',
  'RADIANT LIFE ACADEMY',
  'RADIANT STEM ACADEMY',
  "RAEKWON'S SCHOLASTIC DAYCARE ACADEMY LLC",
  'RAGLESVILLE AMISH SCHOOL',
  'RAILROAD SCHOOL',
  'RAILSIDE',
  'RAINBOW ACADEMY',
  'RAINBOW ACADEMY OF LEARNING',
  'RAINBOW CENTER FOR CHILDREN & FAMILIES',
  'RAINBOW CENTER FOR COMMUNICATIVE DISORDERS',
  'RAINBOW CHILD CARE CENTER',
  'RAINBOW CHILDRENS ACADEMY',
  'RAINBOW CHRISTIAN ACADEMY',
  'RAINBOW COMMUNITY SCHOOL',
  'RAINBOW EXPRESS PRESCHOOL',
  'RAINBOW MONTESSORI',
  'RAINBOW MONTESSORI MULTICULTURAL SCHOOL',
  'RAINBOW MONTESSORI SCHOOL - BLOOMFIELD',
  'RAINBOW MONTESSORI SCHOOL - CACTUS',
  'RAINBOW MONTESSORI SCHOOL - VOLTAIRE',
  'RAINBOW SCHOOL',
  'RAINBOW STATION AT SONTERRA',
  'RAINBOW STATION AT WYNDHAM',
  'RAINBOW STATION CEDAR PARK',
  'RAINBOW VALLEY',
  'RAINEY SCHOOL OF MONTESSORI',
  'RAINIER CHRISTIAN SCHOOLS-KENT VIEW ELEMENTARY',
  'RAISING KNOWLEDGE ACADEMY INC.',
  'RALEIGH CHRISTIAN ACADEMY',
  'RAMAH JUNIOR ACADEMY',
  'RAMALYNN MONTESSORI - APPLE VALLEY CAMPUS',
  'RAMALYNN MONTESSORI ACADEMY',
  'RAMAZ UPPER SCHOOL',
  'RAMBAM DAY SCHOOL',
  'RAMBAM MESIVTA',
  'RAMONA CONVENT SECONDARY SCHOOL',
  'RAMONA LUTHERAN CHRISTIAN SCHOOL',
  'RAMSON PAROCHIAL SCHOOL',
  'RANCHO ENCINITAS ACADEMY',
  'RANCHO HERITAGE SCHOOL',
  'RANCHO VIEJO MONTESSORI SCHOOL',
  'RANDAZZO SCHOOL',
  'RANDOLPH CHRISTIAN SCHOOL',
  'RANDOLPH SCHOOL',
  'RANDOLPH-MACON ACADEMY',
  'RANGELY CHRISTIAN ACADEMY',
  'RANNEY SCHOOL',
  'RANSOM EVERGLADES SCHOOL',
  'RAPHAEL ACADEMY',
  'RAPID CITY CHRISTIAN SCHOOL',
  'RAPID CITY SEVENTH-DAY ADVENTIST ELEMENTARY SCHOOL',
  'RARITAN VALLEY MONTESSORI ACADEMY',
  'RASG HEBREW ACADEMY',
  'RASKOB DAY SCHOOL',
  'RAV TEITZ MESIVTA ACADEMY',
  'RAVENSCROFT SCHOOL',
  'RAVENSWOOD BAPTIST CHRISTIAN SCHOOL',
  'RAW LEARNING',
  'RAWLINSVILLE SCHOOL',
  'RAYMOND HILL SCHOOL',
  'RAYNE CATHOLIC ELEMENTARY SCHOOL',
  'RAZI SCHOOL',
  'RCS LEARNING CENTER',
  'RCSI-Medical University of Bahrain',
  'REACH ACADEMY INC',
  'REACH FOR THE STARS LEARNING CENTER',
  'REACH SCHOOL',
  'READING MONTESSORI SCHOOL',
  'READING SDA JR ACADEMY',
  'READY SET GROW',
  'READY SET GROW!',
  'REAGAN EDUCATIONAL ACADEMY INC',
  'REAL LIFE CHRISTIAN ACADEMY',
  'REALMS OF INQUIRY',
  'REAMSTOWN MENNONITE SCHOOL',
  'RECTORY SCHOOL',
  'RED APPLE SCHOOL AT PEACE LUTHERAN CHURCH',
  'RED BANK CATHOLIC HIGH SCHOOL',
  'RED BIRD CHRISTIAN SCHO',
  'RED BRICK NURSERY SCHOOL',
  'RED CLOUD INDIAN SCHOOL',
  'RED HILL CHRISTIAN SCHOOL',
  'RED HILL LUTHERAN SCHOOL',
  'RED HILL MONTESSORI',
  'RED HILL PAROCHIAL SCHOOL',
  'RED LION CHRISTIAN ACADEMY',
  'RED LION CHRISTIAN SCHOOL',
  'RED LION ELEMENTARY SCHOOL',
  'RED MOUNTAIN MONTESSORI ACADEMY',
  'RED OAK SCHOOL',
  'RED ROCK SCHOOL',
  'RED SANDS CHRISTIAN SCHOOL',
  'RED TOP MEADOWS',
  'RED WELL SCHOOL',
  'REDD SCHOOL',
  'REDDING ADVENTIST ACADEMY',
  'REDDING CHRISTIAN SCHOOL',
  'REDEEMER BAPTIST SCHOOL',
  'REDEEMER BY THE SEA LUTHERAN KINDERGARTEN&PRESCHL',
  'REDEEMER CHRISTIAN ACADEMY',
  'REDEEMER CHRISTIAN SCHOOL',
  'REDEEMER CLASSICAL ACADEMY',
  'REDEEMER EPISCOPAL SCHOOL',
  'REDEEMER EVANGELICAL LUTHERAN SCHOOL',
  'REDEEMER LUTHERAN CHRISTIAN SCHOOL',
  'REDEEMER LUTHERAN SCHOOL',
  'REDEEMER LUTHERAN TINY TREASURES',
  'REDEEMER MONTESSORI SCHOOL',
  'REDEEMER PRESCHOOL & KNDRGRTN',
  'REDEEMER SCHOOL',
  'REDEEMING WORD CHRISTIAN ACADEMY',
  'REDEMPTION BAPTIST ACADEMY',
  'REDEMPTION EVANGELICAL LUTHERAN',
  'REDEMPTION ROAD CHRISTIAN ACADEMY',
  'REDEMPTORIST CATHOLIC SCHOOL',
  'REDLAND CHRISTIAN ACADEMY',
  'REDLANDS ADVENTIST ACADEMY',
  'REDWOOD ADVENTIST ACADEMY',
  'REDWOOD CHRISTIAN SCHOOL',
  'REDWOOD CHRISTIAN SCHOOLS',
  'REDWOOD MONTESSORI ACADEMY',
  'REDWOOD MONTESSORI SCHOOL',
  'REDWOODS INTERNATIONAL MONTESSORI HOUSE OF CHLDRN',
  'REECE SCHOOL',
  'REED ACADEMY',
  "REED'S PREPARATORY SCHOOL",
  'REENAS BAIS YAAKOV',
  'REFINE CHRISTIAN LEARNING PRIVATE SCHOOL',
  'REFLECTIONS OF CHRIST CHRISTIAN ACADEMY',
  'REFORMATION LUTHERAN SCHOOL',
  'REFORMED HERITAGE CHRISTIAN SCHOOL',
  'REGENCY CHRISTIAN ACADEMY',
  'REGENTS ACADEMY',
  'REGENTS SCHOOL OF AUSTIN',
  'REGENTS SCHOOL OF OXFORD',
  'REGINA ACADEMY @ ST. JOHN THE BAPTIST SCHOOL',
  'REGINA CATHOLIC EDUCATION CENTER',
  'REGINA COELI ACADEMY',
  'REGINA COELI CATHOLIC SCHOOL',
  'REGINA COELI SCHOOL',
  'REGINA DOMINICAN HIGH SCHOOL',
  'REGINA HIGH SCHOOL',
  'REGIS CATHOLIC SCHOOLS',
  'REGIS HIGH & MIDDLE SCHOOL',
  'REGIS JESUIT HIGH SCHOOL',
  'REGIS MIDDLE SCHOOL',
  'REHRERSBURG CHRISTIAN SCHOOL',
  'REICHER CATHOLIC HIGH SCHOOL',
  'REID SCHOOL',
  'REID TEMPLE CHRISTIAN ACADEMY',
  'REIGN HOMESCHOOLING ACADEMY',
  'REITZ MEMORIAL HIGH SCHOOL',
  'REJOICE CHRISTIAN SCHOOL ELEMENTARY',
  'REJOYCE IN JESUS CHRISTIAN SCHOOL',
  "RELAY CHILDREN'S CENTER",
  'RELIGIOUS SCHOOL',
  'REM LEARNING CENTER',
  'RENAISSANCE ACADEMY',
  'RENAISSANCE CLASSICAL CHRISTIAN ACADEMY',
  'RENAISSANCE MONTESSORI ACADEMY',
  'RENAISSANCE MONTESSORI SCHOOL',
  'RENAISSANCE SCHOOL',
  'RENAISSANCE SCHOOL FOR ARTS AND SCIENCES',
  'RENAISSANCE VILLAGE ACADEMY',
  'RENASCENCE SCHOOL INTERNATIONAL',
  'RENBROOK SCHOOL',
  'RENO CHRISTIAN FELLOWSHIP PRESCHOOL AND MINISTRY',
  'RENTON CHRISTIAN SCHOOL',
  'RENTON PREPARATORY CHRISTIAN SCHOOL',
  'RESOLUTE ACADEMY',
  'RESOLUTION RANCH ACADEMY',
  'RESTART LEADERSHIP ACADEMY',
  "RESTON CHILDREN'S CENTER",
  'RESTON MONTESSORI SCHOOL',
  'RESTORATION ACADEMY',
  'RESTORATION MINISTRIES CHRISTIAN ACADEMY',
  'RESURRECTION ACADEMY',
  'RESURRECTION ASCENSION CATHOLIC ACADEMY',
  'RESURRECTION CATHOLIC SCHOOL',
  'RESURRECTION CATHOLIC SCHOOL - ELEMENTARY CAMPUS',
  'RESURRECTION CATHOLIC SCHOOL - HIGH SCHOOL CAMPUS',
  'RESURRECTION CHRISTIAN SCHOOL',
  'RESURRECTION COLLEGE PREP HIGH SCHOOL',
  'RESURRECTION GRAMMAR SCHOOL',
  'RESURRECTION LUTHERAN ACADEMY',
  'RESURRECTION LUTHERAN CHILD DEVELOPMENT CENTER',
  'RESURRECTION LUTHERAN SCHOOL',
  'RESURRECTION LUTHERAN SCHOOL & CHILD CARE',
  'RESURRECTION OF OUR LORD',
  'RESURRECTION REGIONAL CATHOLIC SCHOOL',
  'RESURRECTION SCHOOL',
  'RESURRECTION ST PAUL SCHOOL',
  'REV GEORGE A BROWN SCHOOL',
  'REVELATION CHRISTIAN SCHOOL',
  'REVELATION SCHOOL OF FLORIDA',
  'REVIVAL BAPTIST CHRISTIAN SCHOOL',
  'REVIVAL FIRE CHRISTIAN ACADEMY',
  'REVIVER ACADEMY',
  'REVO ACADEMY',
  'REYN FRANCA',
  'REYNOLDS MOUNTAIN CHRISTIAN ACADENY',
  'RFK LANCASTER SCHOOL',
  'RFKCAC EXPERIMENT WITH TRAVEL SCHOOL',
  'RFM CHRISTIAN ACADEMY',
  'RFM CHRISTIAN ACADEMY COCO2',
  'RHEA COUNTY ACADEMY',
  'RHEMA CHRISTIAN ACADEMY',
  'RHEMA CHRISTIAN SCHOOL',
  'RHEMA WORD CHRISTIAN ACADEMY',
  'RHODORA J DONAHUE ACADEMY',
  'RICE MEMORIAL HIGH SCHOOL',
  'RICH BEGINNINGS CHILD CARE CENTER',
  'RICHARD WIRN ACADEMY',
  'RICHARDSON SCHOOL',
  'RICHLAND ACADEMY',
  'RICHLAND CHRISTIAN ACADEMY',
  'RICHLANDS CHRISTIAN ACADEMY',
  'RICHMOND ACADEMY',
  'RICHMOND CHRISTIAN SCHOOL',
  'RICHMOND FRIENDS SCHOOL',
  'RICHMOND MONTESSORI SCHOOL',
  'RICHMOND PREPARATORY CHRISTIAN ACADEMY',
  'RICHMOND WALDORF SCHOOL',
  'RICKS CENTER FOR GIFTED CHILDREN',
  'RIDGE ACADEMY',
  'RIDGE CHRISTIAN ACADEMY',
  'RIDGE MANOR CHRISTIAN ACADEMY',
  'RIDGE SCHOOL',
  'RIDGE VIEW',
  'RIDGECROFT SCHOOL',
  'RIDGEFIELD ACADEMY',
  'RIDGEFIELD CHRISTIAN SCHOOL',
  'RIDGEFIELD MONTESSORI SCHOOL',
  'RIDGEMONT MONTESSORI SCHOOL',
  'RIDGESIDE SCHOOL',
  'RIDGEVIEW AMISH SCHOOL',
  'RIDGEVIEW CAMDEN SCHOOL',
  'RIDGEVIEW CHRISTIAN SCHOOL',
  'RIDGEWAY NURSERY SCHOOL & KINDERGARTEN',
  'RIDGEWOOD PREPARATORY SCHOOL',
  'RIDGEWOOD SCHOOL',
  'RIDGWAY CHRISTIAN SCHOOL',
  'RIDLEY AREA YMCA-CHILDCARE',
  'RIGHT STEPS OF CHURCHVILLE',
  'RILEY SCHOOL',
  'RINCON VALLEY CHRISTIAN SCHOOL',
  'RING MOUNTAIN DAY SCHOOL',
  'RIO GRANDE BAPTIST ACADEMY',
  'RIO GRANDE CHRISTIAN ACADEMY',
  'RIO GRANDE SCHOOL',
  'RIO LINDO ADVENTIST ACADEMY',
  'RIPON CHRISTIAN SCHOOLS',
  'RIPPLING CREEK AMISH SCHOOL',
  'RISE & SHINE PRESCHOOL & KINDERGARTEN',
  'RISE INSTITUTE',
  'RISEN CHRIST CATHOLIC SCHOOL',
  'RISEN CHRIST CHRISTIAN ACADEMY',
  'RISEN CHRIST LUTHERAN SCHOOL',
  'RISEN SAVIOR CHRISTIAN ACADEMY',
  'RISEN SAVIOR LUTHERAN SCHOOL',
  'RISING STAR MONTESSORI',
  'RISING STARS ACADEMY',
  'RISING STARS ISLAMIC SCHOOL',
  'RITE OF PASSAGE HIGH SCHOOL MINDEN',
  'RIVENDELL SCHOOL',
  'RIVENDELL SCHOOL OF NORTHERN COLORADO',
  'RIVER CHRISTIAN ACADEMY',
  'RIVER CITY BELIEVERS ACADEMY',
  'RIVER CITY CHRISTIAN SCHOOL',
  'RIVER OAK CHRISTIAN ACADEMY',
  'RIVER OAKS ACADEMY',
  'RIVER OAKS SCHOOL',
  'RIVER OF LIFE CHRISTIAN SCHOOL',
  'RIVER OF LIFE SCHOOL',
  'RIVER ROADS LUTHERAN SCHOOL',
  'RIVER ROCK ACADEMY',
  'RIVER ROCK ACADEMY - LANCASTER',
  'RIVER ROCK ACADEMY AMITY CAMPUS',
  'RIVER ROCK ACADEMY INC',
  'RIVER ROCK ACADEMY NEWVILLE CAMPUS',
  'RIVER ROCK ACADEMY RED LION CAMPUS',
  'RIVER ROCK SCHOOL',
  'RIVER SCHOOL',
  'RIVER SONG SCHOOL',
  'RIVER VALLEY',
  'RIVER VALLEY CHRISTIAN SCHOOL',
  'RIVER VALLEY MENNONITE SCHOOL',
  'RIVER VALLEY SCHOOL',
  'RIVER VALLEY WALDORF SCHOOL',
  'RIVERBEND ACADEMY',
  'RIVERBEND INTERNATIONAL SCHOOL',
  'RIVERBEND MONTESSORI SCHOOL',
  'RIVERBEND SCHOOL',
  'RIVERCHASE UNITED METHODIST DAY SCHOOL',
  'RIVERDALE BAPTIST SCHOOL',
  'RIVERDALE CHRISTIAN ACADEMY',
  'RIVERDALE COUNTRY SCHOOL',
  'RIVERDAY SCHOOL',
  'RIVERFIELD COUNTRY DAY SCHOOL',
  'RIVERHILL SCHOOL',
  'RIVERLAND CHRISTIAN ACADEMY',
  'RIVERMONT COLLEGIATE',
  'RIVERMONT SCHOOL - GREATER PETERSBURG',
  'RIVERMONT SCHOOL - TIDEWATER',
  'RIVERPOINTE CHRISTIAN ACADEMY',
  'RIVERRUN COMMUNITY MONTESSORI',
  'RIVERS ACADEMY',
  'RIVERS ACADEMY ATLANTA',
  'RIVERS EDGE CHRISTIAN ACADEMY',
  'RIVERS OF LIFE CHRISTIAN SCHOOL',
  'RIVERSIDE ACADEMY',
  'RIVERSIDE CHRISTIAN',
  'RIVERSIDE CHRISTIAN ACADEMY',
  'RIVERSIDE CHRISTIAN SCHOOL',
  'RIVERSIDE CHRISTIAN TRAINING SCHOOL',
  'RIVERSIDE CHURCH WEEK DAY SCHOOL',
  'RIVERSIDE MISSION CHRISTIAN ACADEMY',
  'RIVERSIDE MONTESSORI ACADEMY',
  'RIVERSIDE PRESBYTERIAN DAY SCHOOL',
  'RIVERSIDE SCHOOL',
  'RIVERSTONE INTERNATIONAL SCHOOL',
  'RIVERSTONE MONTESSORI ACADEMY',
  'RIVERTREE ACADEMY',
  'RIVERTREE CHRISTIAN SCHOOL',
  'RIVERTREE SCHOOL',
  'RIVERVIEW BAPTIST CHRISTIAN SCHOOL',
  'RIVERVIEW CHRISTIAN ACADEMY',
  'RIVERVIEW CHRISTIAN SCHOOL',
  'RIVERVIEW LUTHERAN SCHOOL',
  'RIVERVIEW MEMORIAL SCHOOL',
  'RIVERVIEW SCHOOL',
  'RIVERWALK CHRISTIAN ACADEMY',
  'RIVERWOOD CLASSICAL SCHOOL',
  'RIVERWOOD COMMUNITY SCHOOL',
  'RIVET JR SR HIGH SCHOOL',
  'RIVIERA HALL LUTHERAN SCHOOL',
  'RIVIERA SCHOOLS',
  'RJB CHRISTIAN SCHOOL',
  'RL FINNEY CHRISTIAN ACADEMEY',
  'RMIT International University Vietnam',
  'ROADSTEAD MONTESORRI HIGH SCHOOL',
  'ROANOKE ADVENTIST PREPARATORY SCHOOL',
  'ROANOKE CATHOLIC SCHOOL',
  'ROANOKE VALLEY CHRISTIAN SCHOOLS',
  'ROBBINS NEST PRESCHOOL',
  'ROBERT B JOLICOEUR SCHOOL',
  'ROBERT C PARKER SCHOOL',
  'ROBERT E LEE ACADEMY',
  'ROBERT F MUNROE DAY SCHOOL',
  'ROBERT J WILF PRESCHOOL AND KINDERGARTEN',
  'ROBERT LOUIS STEVENSON SCHOOL',
  'ROBERT M BEREN ACADEMY',
  'ROBERTS ACADEMY AT FLORIDA SOUTHERN COLLEGE',
  'ROBINDELL PRIVATE SCHOOL',
  'ROBLANCA ACADEMY',
  'ROCHAMBEAU-THE FRENCH INTL SCHOOL',
  'ROCHELLE ZELL JEWISH HIGH SCHOOL',
  'ROCHESTER ARTS & SCIENCES ACADEMY',
  'ROCHESTER CENTRAL LUTHERAN SCHOOL',
  'ROCHESTER CHRISTIAN SCHOOL',
  'ROCHESTER HILLS CHRISTIAN SCHOOL',
  'ROCHESTER LEARNING ACADEMY',
  'ROCHESTER MONTESSORI SCHOOL',
  'ROCHESTER PENTECOSTAL SCHOOL',
  'ROCHESTER SCHOOL FOR THE DEAF',
  'ROCK BROOK SCHOOL',
  'ROCK CHURCH ACADEMY',
  'ROCK COUNTY CHRISTIAN SCHOOL',
  'ROCK CREEK AMISH SCHOOL',
  'ROCK CREEK CHRISTIAN ACADEMY',
  'ROCK CREEK SCHOOL',
  'ROCK DUMP SCHOOL',
  'ROCK HAVEN CHRISTIAN SCHOOL',
  'ROCK HILL SCHOOL',
  'ROCK HOLLOW SCHOOL',
  'ROCK POINT SCHOOL',
  'ROCK PRAIRIE MONTESSORI',
  'ROCK RIDGE SCHOOL',
  'ROCK SOLID ACADEMY',
  'ROCK SOLID CHRIISTIAN ACADEMYSCHOOL',
  'ROCK SOLID CHRISTIAN ACADEMY',
  'ROCK SPRINGS CHRISTIAN ACADEMY',
  'ROCK VALLEY',
  'ROCK VALLEY CHRISTIAN SCHOOL',
  'ROCKBRIDGE CHRISTIAN ACADEMY',
  'ROCKFISH CHRISTIAN ACADEMY',
  'ROCKFORD BOYLAN CATHOLIC HIGH SCHOOL',
  'ROCKFORD CHRISTIAN SCHOOL',
  'ROCKFORD CHRISTIAN SCHOOLS',
  "ROCKFORD IQRA' SCHOOL",
  'ROCKFORD LUTHERAN ACADEMY',
  'ROCKFORD LUTHERAN SCHOOL - JR/SR HIGH SCHOOL',
  'ROCKHURST HIGH SCHOOL',
  'ROCKLAND COUNTRY DAY SCHOOL',
  'ROCKLAND INSTITUTE FOR SPECIAL EDUCATION',
  'ROCKLAND LEARNING CENTER',
  'ROCKLEDGE CHRISTIAN SCHOOL',
  'ROCKVALE AMISH SCHOOL',
  'ROCKVILLE SCHOOL',
  'ROCKWERN ACADEMY',
  'ROCKY BAYOU CHRISTIAN SCHOOL',
  'ROCKY KNOLL SCHOOL',
  'ROCKY MOUNT ACADEMY',
  'ROCKY MOUNTAIN CHRISTIAN ACADEMY',
  'ROCKY MOUNTAIN EDUCATION',
  'ROCKY MOUNTAIN LUTHERAN HIGH SCHOOL',
  'ROCKY MOUNTAIN MONTESSORI ACADEMY',
  'ROCKY RIDGE AMISH SCHOOL',
  'ROCKY RIDGE SCHOOL',
  'ROCKY SPRINGS SCHOOL',
  'ROD OF GOD CHRISTIAN ACADEMY',
  'RODEPH SHOLOM SCHOOL',
  'ROEPER CITY & COUNTRY SCHOOL',
  'ROEPER SCHOOL',
  'ROGER BACON HIGH SCHOOL',
  'ROGER WILLIAMS DAYCARE CENTER',
  'ROGERS ADVENTIST SCHOOL',
  'ROGERS CITY MONTESSORI',
  'ROGERS PARK MONTESSORI SCHOOL',
  'ROGUE VALLEY ADVENTIST ACADEMY',
  'ROHAN WOODS SCHOOL',
  'ROHR BAIS CHAYA ACADEMY',
  'ROLAND PARK COUNTRY SCHOOL',
  'ROLLA SEVENTH-DAY ADVENTIST SCHOOL',
  'ROLLING ACRES AMISH SCHOOL',
  'ROLLING ACRES SCHOOL',
  'ROLLING HILLS CATHOLIC SCHOOL AND ANTONIAN MIDDLE',
  'ROLLING HILLS CHRISTIAN SCHOOL',
  'ROLLING HILLS PREPARATORY SCHOOL',
  'ROLLING MEADOW AMISH SCHOOL',
  'ROLLING MEADOW SCHOOL',
  'ROLLING RIDGE',
  'ROLLING RIDGE SCHOOL',
  'ROLLINGWOOD ACADEMY',
  'ROLLINS CHILD DEVELOPMENT CENTER',
  'ROMAN CATHOLIC HIGH SCHOOL',
  'ROME CATHOLIC SCHOOL',
  'RONALD KNOX MONTESSORI SCHOOL',
  'RONALD REAGAN SECONDARY SCHOOL',
  'RONCALLI CATHOLIC HIGH SCHOOL',
  'RONCALLI ELEMENTARY SCHOOL',
  'RONCALLI HIGH SCHOOL',
  'RONCALLI JR & SR HIGH SCHOOL',
  'RONCALLI PRIMARY SCHOOL',
  'ROOT ACADEMY',
  'ROOTS AND WINGS ACADEMY',
  'ROOTS SCHOOL',
  'ROSARIAN ACADEMY',
  'ROSARY ACADEMY',
  'ROSARY HIGH SCHOOL',
  'ROSARY SCHOOL',
  'ROSAS SCHOOL/TRADITIONAL',
  'ROSATI-KAIN HIGH SCHOOL',
  'ROSE & ALEX PILIBOS ARMENIAN SCHOOL',
  'ROSE ACADEMY LLC',
  'ROSE HILL MONTESSORI',
  'ROSE ROCK SCHOOL',
  'ROSE TREE DAY SCHOOL',
  'ROSE VALLEY SCHOOL',
  'ROSEBURG JUNIOR ACADEMY',
  'ROSEDALE BAPTIST SCHOOL',
  'ROSEDALE CHILD DEVELOPMENT CENTER',
  'ROSEHILL CHRISTIAN SCHOOL',
  'ROSEHILL MONTESSORI SCHOOL',
  'ROSELAND CHILD DEVELOPMENT CENTER',
  'ROSELAND DAYCARE CENTER',
  'ROSELLE CATHOLIC HIGH SCHOOL',
  'ROSEMARY ANDERSON HIGH SCHOOL EAST',
  'ROSEMARY ANDERSON HIGH SCHOOL/PORTLAND OIC',
  'ROSEMONT ACADEMY',
  'ROSEVILLE COMMUNITY SCHOOL',
  'ROSS CORNERS CHRISTIAN ACADEMY',
  'ROSS COUNTY CHRISTIAN ACADEMY',
  'ROSS ROAD SCHOOL',
  'ROSS SCHOOL',
  'ROSSIER PARK ELEMENTARY SCHOOL',
  'ROSSVILLE CHRISTIAN ACADEMY',
  'ROSWELL UNITED METHODIST PRESCHOOL & KINDERGARTEN',
  'ROUND GROVE CHRISTIAN ACADEMY',
  'ROUND HILL SCHOOL',
  'ROUND ROCK CHRISTIAN ACADEMY',
  'ROUTT CATHOLIC HIGH SCHOOL',
  'ROWAN COUNTY CHRISTIAN ACADEMY',
  'ROWLAND HALL',
  'ROWLAND HALL SECONDARY',
  'ROXBORO CHRISTIAN ACADEMY',
  'ROXBOROUGH CHRISTIAN SCHOOL',
  'ROYAL INTERNATIONAL CHRISTIAN ACADEMY',
  'ROYAL KIDS ACADEMY',
  'ROYAL MONTESSORI ACADEMY',
  'ROYAL OAK NURSERY SCHOOL & KINDERGARTEN',
  'ROYAL PALM ACADEMY',
  'ROYAL REDEEMER LUTHERAN SCHOOL',
  'ROYALMONT ACADEMY',
  'ROYCEMORE SCHOOL',
  'ROYER-GREAVES SCHOOL FOR BLIND',
  'RSM MONTESSORI SCHOOL',
  'RUDOLF STEINER SCHOOL OF ANN ARBOR',
  'RUDOLF STEINER SCHOOL OF ANN ARBOR LOWER SCHOOL',
  'RUDYARD CHRISTIAN SCHOOL',
  'RUFFING MONTESSORI SCHOOL',
  'RUMSEY HALL SCHOOL',
  'RUMSON COUNTRY DAY SCHOOL',
  'RUNDELL CHRISTIAN DAY SCHOOL',
  'RUNNELS SCHOOL',
  'RUPERT RIDGE SCHOOL',
  'RUSH DAY SCHOOL',
  'RUSKIN CHRISTIAN SCHOOL',
  'RUSSIAN KIDS HOUSE',
  'RUTGERS PREPARATORY SCHOOL',
  'RUTGERS-LIVINGSTON DAY CARE CENTER',
  'RUTH MURDOCH ELEMENTARY SCHOOL',
  'RUTHERFORD ACADEMY',
  'RUTLAND AREA CHRISTIAN SCHOOL',
  'RYAN ACADEMY OF NORFOLK',
  'RYCEVILLE AMISH PAROCHIAL SCHOOL',
  'RYE COUNTRY DAY SCHOOL',
  'RYNEAR RD SCHOOL',
  'Raa Middle School',
  'Raba El',
  'Rabanal',
  'Rabbit Creek Elementary',
  'Rabindra Bharati University',
  'Rabun County Elementary School',
  'Rabun County High School',
  'Rabun County Middle School',
  'Rabun County Primary School',
  'Raby High School',
  'Raccoon Cons Elem School',
  'Race Brook School',
  'Raceland Lower Elementary School',
  'Raceland Middle School',
  'Raceland Upper Elementary School',
  'Raceland-worthington High School',
  'Raceland-worthington Schools Campus A',
  'Raceland-worthington Schools Campus B',
  'Rachel Carson Elementary',
  'Rachel Carson High School For Coastal Studies',
  'Rachel Patterson Elementary School',
  'Racine Alternative Learning',
  'Racine Alternative Learning 6-12',
  'Racine Civil Leaders Academy',
  'Racine Correctional Institution',
  'Racine Early Education Center',
  'Racine Youthful Offender Warren Young High',
  'Radboud University',
  'Radburn Elementary School',
  'Radcliff Elementary',
  'Radcliff Elementary School',
  'Radcliffe & Sue Killam El',
  'Radcliffe School',
  'Radford High',
  'Radford University',
  'Radiant Elementary',
  'Radio Park El Sch',
  'Radium Springs Elementary School',
  'Radium Springs Middle School',
  'Radix Elementary',
  'Radley Elementary School',
  'Radloff Middle School',
  'Radnor El Sch',
  'Radnor Ms',
  'Radnor Shs',
  'Rae C. Stedman Elementary',
  'Raes East Alt Ed',
  'Raes Night School',
  'Raes Rssp',
  'Raes West',
  'Raes West Alt Ed',
  'Rafael A Cantu J H',
  'Rafael Antonio Delgado Mateo',
  'Rafael Aparicio Jimenez',
  'Rafael Colon Salgado',
  'Rafael Cordero',
  'Rafael Cordero Molina',
  'Rafael Cordero Molina Elementary School',
  'Rafael De J. Cordero School',
  'Rafael De Jesus',
  'Rafael Esparra Cartagena',
  'Rafael Fabian',
  'Rafael Hernandez',
  'Rafael Hernandez Marin',
  'Rafael Hernandez School',
  'Rafael Irizarry Rivera',
  'Rafael Maria De Labra',
  'Rafael Martinez Nadal',
  'Rafael N. Coca',
  'Rafael Pont Flores',
  'Rafael Quinones Vidal',
  'Rafael Rexach Dueno',
  'Rafael Rivera Otero',
  'Rafaelina E. Lebron Flores',
  'Rafer Johnson',
  'Rafer Johnson Childrens Center',
  'Rafer Johnson Junior High',
  'Raffaello Palla Elementary',
  'Raffles University',
  'Rafsanjan University of Medical Sciences',
  'Raft River Elementary School',
  'Raft River Jr/sr High School',
  'Rafting Creek Elementary',
  'Ragersville Elementary School',
  'Raghebe Esfahani University',
  'Ragland High School',
  'Raguet El',
  'Rahe Bulverde El',
  'Rahn Elementary',
  'Rahway 7th & 8th Grade Academy',
  'Rahway High School',
  'Rail Ranch Elementary',
  'Rail Road Flat Elementary',
  'Railroad Canyon Elementary',
  'Rails Academy',
  'Railway Elementary',
  'Rainbow Center',
  'Rainbow Dreams Academy',
  'Rainbow Dreams Academy Secondary',
  'Rainbow El Sch',
  'Rainbow Elementary School',
  'Rainbow Lake Middle',
  'Rainbow Middle School',
  'Rainbow Park Elementary School',
  'Rainbow Ridge Elementary',
  'Rainbow Springs',
  'Rainbow Valley Elementary School',
  'Raincross High (continuation)',
  'Rainelle Elementary',
  'Raines Elementary School',
  'Raines H S',
  'Rainey Mccullers School Of The Arts',
  'Rainier Beach High School',
  'Rainier Elementary School',
  'Rainier Jr/sr High School',
  'Rainier Middle School',
  'Rainier Prep',
  'Rainier Senior High School',
  'Rainier Valley Leadership Academy',
  'Rainier View Elementary School',
  'Rains El',
  'Rains H S',
  'Rains Int',
  'Rains J H',
  'Rainsboro Elementary School',
  'Raintree Elementary School',
  'Rainwater El',
  'Rainy River Community College',
  'Raisbeck Aviation High School',
  'Raisin City Elementary',
  'Raisinville School',
  'Rajamangala University of Technology Lanna Chiang Rai',
  'Rajamangala University of Technology Lanna Nan',
  'Rajamangala University of Technology Phra Nakhon',
  'Rajarata University of Sri Lanka',
  'Rajasthan Agricultural University Bikaner',
  'Rajasthan Technical University',
  'Rajasthan Vidyapeeth University',
  'Rajendra Agricultural University',
  'Rajitlal Institute of Technology & Health Sciences (RITHS)',
  'Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)',
  'Rajiv Gandhi University',
  'Rajshahi University of Engineering and Technology',
  'Rak Medical & Health Sciences University',
  'Rakuno Gakuen University',
  'Raleigh Charter High School',
  'Raleigh Elementary School',
  'Raleigh High School',
  'Raleigh Hills Elementary School',
  'Raleigh Oak Charter',
  'Raleigh Park Elementary School',
  'Raleigh Primary/el Academy',
  'Raleigh Road Elementary',
  'Raleigh-bartlett Meadows Elementary',
  'Raleigh-egypt High',
  'Ralls County Elementary',
  'Ralls El',
  'Ralls H S',
  'Ralls Middle',
  'Ralph A Fabrizio School',
  'Ralph A. Gates Elementary',
  'Ralph And Mary Lynn Boyer El',
  'Ralph Askins School',
  "Ralph B O'maley Middle",
  'Ralph Bunche El',
  'Ralph Bunche Elementary',
  'Ralph C Mahar Regional',
  'Ralph C Starkey (cjcf)',
  'Ralph Cadwallader Middle School',
  'Ralph Chandler Middle',
  'Ralph D Butler',
  'Ralph Downs Es',
  'Ralph Dunlap Elementary',
  'Ralph E. Hawes Elementary',
  'Ralph E. Waite Elementary',
  'Ralph Eickenroht El',
  'Ralph Emerson Elementary',
  'Ralph F. Wilson Elementary School',
  'Ralph J Bunche Academy',
  'Ralph J Bunche Elementary',
  'Ralph J Frost Academy',
  'Ralph J. Bunche High',
  'Ralph J. Steinhauer Elementary School',
  'Ralph M Atwood Primary School',
  'Ralph M Williams Junior Elementary School',
  'Ralph M. Captain Elem.',
  'Ralph M. T. Johnson School',
  'Ralph Parr El',
  'Ralph Pfluger El',
  'Ralph R Mckee Career And Technical Education High School',
  'Ralph R Smith School',
  'Ralph R Willis Career/technical Center',
  'Ralph Reed School',
  'Ralph Richardson Center',
  'Ralph S Maugham School',
  'Ralph Talbot',
  'Ralph W. Emerson',
  'Ralph Waldo Emerson Elem School',
  'Ralph Waldo Emerson Elementary School',
  'Ralph Waldo Emerson Junior High',
  'Ralph Waldo Emerson School 58',
  'Ralph Wheelock School',
  'Ralph Witters Elementary',
  'Ralston Elem School',
  'Ralston Elementary School',
  'Ralston High School',
  'Ralston Intermediate',
  'Ralston Middle School',
  'Ralston Valley Senior High School',
  'Rama Road Elementary',
  'Ramage Elementary',
  'Ramah Elementary',
  'Ramah High',
  'Ramapo College',
  'Ramapo High School',
  'Ramapo Ridge',
  'Ramay Junior High School',
  'Ramblewood Elementary',
  'Ramblewood Elementary School',
  'Ramblewood Middle School',
  'Ramer Elementary',
  'Ramey El',
  'Ramey-estep High School',
  'Ramirez El',
  'Ramirez Thomas Elementary',
  'Ramirez-burks El',
  'Ramiro Barrera Middle',
  'Ramiro Colon Colon',
  'Ramkhamhaeng University',
  'Ramon Avila Molinari',
  'Ramon B. Lopez',
  'Ramon Brugueras',
  'Ramon C. Cortines School Of Visual And Performing Arts',
  'Ramon De Jesus Sierra',
  'Ramon E. Betances',
  'Ramon E. Rodriguez Diaz',
  'Ramon Emeterio Betances',
  'Ramon Frade Leon',
  'Ramon Garza Elementary',
  'Ramon Jose Davila',
  'Ramon Luis Rivera (juan Asencio)',
  'Ramon Maria Torres',
  'Ramon Marin',
  'Ramon Marin Sola',
  'Ramon Morales Pena',
  'Ramon Olivares',
  'Ramon Perez Purcell',
  'Ramon Power Y Giralt',
  'Ramon Quinones Medina',
  'Ramon Quinones Pacheco',
  'Ramon Rodriguez',
  'Ramon S. Tafoya Elementary',
  'Ramon Saavedra',
  'Ramon T. Rivera (su Lajita)',
  'Ramon Torres Rivera',
  'Ramon Vila Mayo',
  'Ramona',
  'Ramona Boulevard Elementary School',
  'Ramona Community Montessori',
  'Ramona El',
  'Ramona Elementary',
  'Ramona Head Start/state Preschool',
  'Ramona High',
  'Ramona Junior High',
  'Ramona Middle',
  'Ramona Opportunity High',
  'Ramona-alessandro Elementary',
  'Rampart High School',
  'Rampart School',
  'Rampello K-8 Magnet School',
  'Ramsay 7-8',
  'Ramsay El Sch',
  'Ramsay High School',
  'Ramsay School',
  'Ramseur Elementary',
  'Ramsey El Sch',
  'Ramsey Elem School',
  'Ramsey Elementary',
  'Ramsey High School',
  'Ramsey Junior High School',
  'Ramsey Magnet School Of Science',
  'Ramsey Middle',
  'Ramsey Middle School',
  'Ramsey Street High',
  'Ramtown Elementary School',
  'Rana Institute of Higher Education',
  'Ranburne Elementary School',
  'Ranburne High School',
  'Ranch Academy',
  'Ranch Creek Elementary',
  'Ranch Elementary School',
  'Ranch Heights Es',
  'Ranch Hills Elementary',
  'Ranch View Elementary',
  'Ranch View Elementary School',
  'Ranch View Middle School',
  'Rancheria Continuation',
  'Ranchero Middle',
  'Ranches Academy',
  'Ranchi University',
  'Ranchito Avenue Elementary',
  'Rancho Alamitos High',
  'Rancho Bernardo High',
  'Rancho Buena Vista High',
  'Rancho Campana High',
  'Rancho Canada Elementary',
  'Rancho Cordova Elementary',
  'Rancho Cotate High',
  'Rancho Cucamonga High',
  'Rancho Cucamonga Middle',
  'Rancho De La Nacion',
  'Rancho Del Mar High (continuation)',
  'Rancho Del Rey Middle',
  'Rancho Dominguez Preparatory',
  'Rancho Elementary',
  'Rancho Gabriela',
  'Rancho High School',
  'Rancho Isabella El',
  'Rancho Las Positas Elementary',
  'Rancho Learning Center (alternative)',
  'Rancho Medanos Junior High',
  'Rancho Milpitas Middle',
  'Rancho Minerva Middle',
  'Rancho Mirage Elementary',
  'Rancho Mirage High',
  'Rancho Pico Junior High',
  'Rancho Romero Elementary',
  'Rancho Rosal Elementary',
  'Rancho San Diego Elementary',
  'Rancho San Joaquin Middle',
  'Rancho San Justo',
  'Rancho Santa Fe Elementary School',
  'Rancho Santa Fe Preschool',
  'Rancho Santa Gertrudes Elementary',
  'Rancho Santa Margarita Intermediate',
  'Rancho Sienna El',
  'Rancho Tehama Elementary',
  'Rancho Verde El',
  'Rancho Verde Elementary',
  'Rancho Verde High',
  'Rancho Viejo Elementary School',
  'Rancho Viejo Middle',
  'Rancho Village Es',
  'Rancho Vista Elementary',
  'Rancho Vista High',
  'Rancho-starbuck Intermediate',
  'Ranchos De Taos Elementary',
  'Ranchos Middle',
  'Ranchview H S',
  'Ranchwood Es',
  'Rancier Middle',
  'Rancocas Valley Regional High School',
  'Rand Elementary',
  'Rand Road Elementary',
  'Randall Carter Elementary School',
  'Randall Consolidated School',
  'Randall Elem.',
  'Randall Elementary',
  'Randall G. Lynch Middle School',
  'Randall H S',
  'Randall K. Cooper High School',
  'Randall Middle School',
  'Randall Pepper Elementary',
  'Randall School',
  'Randallstown Elementary',
  'Randallstown High',
  'Randels Elementary School',
  'Randle Highlands Es',
  'Randleman Elementary',
  'Randleman High',
  'Randleman Middle',
  'Randolph A. Philip Avt Hs',
  'Randolph Academy',
  'Randolph Academyhamburg Campus',
  'Randolph Career And Technical Center',
  'Randolph Clay High School',
  'Randolph Clay Middle School',
  'Randolph Community College',
  'Randolph Community Middle',
  'Randolph County Alternative Center',
  'Randolph County Elementary School',
  'Randolph County High School',
  'Randolph Early College High',
  'Randolph El',
  'Randolph Elem',
  'Randolph Elem School',
  'Randolph Elementary',
  'Randolph Elementary School',
  'Randolph H S',
  'Randolph Heights Elementary',
  'Randolph High',
  'Randolph High School',
  'Randolph Middle',
  'Randolph Middle School',
  'Randolph Park Elementary School',
  'Randolph Secondary',
  'Randolph Senior High School',
  'Randolph Southern Elementary Sch',
  'Randolph Southern Jr-sr High Sch',
  'Randolph Technical Career Center',
  'Randolph Technical Center',
  'Randolph Union High School',
  'Randolph-Macon College',
  "Randolph-Macon Woman's College",
  'Randolph-henry High',
  'Randolphroanoke Career Technology Center',
  'Randolphville Elementary School',
  'Random Lake Elementary',
  'Random Lake High',
  'Random Lake Middle',
  'Randy Smith Middle School',
  'Range El',
  'Range Line Early Childhood',
  'Range View Elementary',
  'Rangeland Elementary',
  'Rangeley Lakes Regional School',
  'Rangely Junior/senior High School',
  'Ranger College',
  'Ranger El',
  'Ranger Elementary',
  'Ranger Elementary/middle',
  'Ranger H S',
  'Ranger High Tech Academy',
  'Ranger Middle',
  'Ranger School',
  'Rangerville El',
  'Rangeview High School',
  'Rangsit University',
  'Rani Durgavati University',
  'Rankin County Learning Center',
  'Rankin El',
  'Rankin Elem School',
  'Rankin Elementary',
  'Rankin Elementary School',
  'Rankin H S',
  'Rann El',
  'Ransom Grade School',
  'Ransom H Barr Elementary School',
  'Ransom Middle School',
  'Ranson Elementary School',
  'Ranson Middle',
  'Rantoul Twp High School',
  'Rap Elizabeth',
  'Rap Freeport',
  'Rapelje 7-8',
  'Rapelje High School',
  'Rapelje School',
  'Raphael J Mcnulty Academy For Intern Studies & Literacy',
  'Rapid City Elementary School',
  'Rapid City High School - 45',
  'Rapid Run Middle School',
  'Rapid Valley Elementary - 12',
  'Rapides Alternative Positive Program For Students',
  'Rapides High School',
  'Rapides Training Academy',
  'Rappahannock Community College',
  'Rappahannock County Elementary',
  'Rappahannock County High',
  'Rappahannock High',
  'Rappahannock Juvenile Detention Home',
  'Raptor Program',
  'Raquel Cavazos El',
  'Rare Earth High (continuation)',
  'Raritan High School',
  'Raritan Valley Community College',
  'Raritan Valley School',
  'Rasco Middle',
  'Rashkis Elementary',
  'Rasor El',
  'Rather L H',
  'Raton High',
  'Raton Intermediate',
  'Rattan Es',
  'Rattan Hs',
  'Rattan Jhs',
  'Rattlesnake Elementary School',
  'Rattlesnake Ridge Elementary',
  'Rau School',
  'Raul A Gonzalez Jr El',
  'Raul Garza Jr El',
  'Raul Grijalva Elementary School',
  'Raul H. Castro Middle School',
  'Raul Julia (flamboyan Gardens)',
  'Raul Longoria El',
  'Raul P Elizondo Elementary School',
  'Raul Perales Middle',
  'Raul Quintanilla Sr Middle',
  'Raul Ybarra',
  'Raul Yzaguirre School For Success',
  'Raupp School',
  'Raven Elementary',
  'Raven School',
  'Raven Stream Elementary',
  'Ravena-coeymans-selkirk Middle School',
  'Ravena-coeymans-selkirk Senior High School',
  'Ravenel Elementary',
  'Ravenna Child Care Preschool',
  'Ravenna Elementary School',
  'Ravenna High School',
  'Ravenna Middle School',
  'Ravenna Senior High',
  'Ravenswood Child Development Center',
  'Ravenswood Comprehensive Middle',
  'Ravenswood Elem School',
  'Ravenswood Grade School',
  'Ravenswood High School',
  'Ravenswood Middle School',
  'Ravensworth Elementary',
  'Ravenwood Elem.',
  'Ravenwood Elementary',
  'Ravenwood High School',
  'Ravia Public School',
  'Ravine Drive Elementary School',
  'Ravinia Elem School',
  'Rawhide Elementary',
  'Rawlins County Elementary',
  'Rawlins County Jr/sr High School',
  'Rawlins Elementary',
  'Rawlins High School',
  'Rawlins Middle School',
  'Rawlinson Middle',
  'Rawlinson Road Middle',
  'Rawls Springs Attendance Center',
  'Rawson Elementary',
  'Rawson School',
  'Rawsonville Elementary School',
  'Ray And Jamie Wolman El',
  'Ray Borane Middle School',
  'Ray Brooks School',
  'Ray Childers Elementary',
  'Ray D Corbett J H',
  'Ray D. Molo Middle Magnet School',
  'Ray Daily El',
  'Ray E Braswell H S',
  'Ray E Kilmer Elementary School',
  'Ray El',
  'Ray Elem School',
  'Ray Elementary School',
  'Ray H Darr El',
  'Ray H S',
  'Ray High School',
  'Ray Jr/sr High School',
  'Ray Marsh Elem',
  'Ray Miller Elem.',
  'Ray S. Musselman Learning Center',
  'Ray Street Academy',
  'Ray V. Pottorf Elementary School',
  'Ray Wiltsey Middle',
  'Ray/phyllis Simon Intermediate',
  'Rayburn Correctional Institute',
  'Rayburn El',
  'Rayburn Elementary',
  'Rayburn H S',
  'Rayburn Middle',
  'Raye Mccoy El',
  'Raye-allen El',
  'Rayen Early College',
  'Rayford Int',
  'Rayma C. Page Elementary School',
  'Raymer Elementary School',
  'Raymond A Watson Elem School',
  'Raymond A. Geiger Elementary School',
  'Raymond A. Villa Fundamental Intermediate',
  'Raymond Academy',
  'Raymond Avenue Elementary',
  'Raymond B Cooper J H',
  'Raymond B. Stewart Middle School',
  'Raymond Benson Primary School',
  'Raymond C Buckley Elementary School',
  'Raymond Case Elementary',
  'Raymond Central Preschool',
  'Raymond Cree Middle',
  'Raymond E. Shaw Elementary',
  'Raymond E. Voorhees Elementary School',
  'Raymond Ec',
  'Raymond Elementary',
  'Raymond Elementary School',
  'Raymond Ellis Elem School',
  'Raymond F Brandes School 65',
  'Raymond F. Orr Elem. School',
  'Raymond Gabaldon Elementary',
  'Raymond Grade School',
  'Raymond Granite High',
  'Raymond High School',
  'Raymond Home Link School',
  'Raymond J Grey Junior High',
  'Raymond J. Fisher Middle',
  'Raymond Jr High',
  'Raymond Jr Sr High School',
  'Raymond L Young Elementary School',
  'Raymond Laborde Correctional Center',
  'Raymond Laperche School',
  'Raymond Lesniak Energy Strength Hope Recovery High School',
  'Raymond Mays Middle',
  'Raymond Nancy Hodge Elem.',
  'Raymond Park Intermediate',
  'Raymond Park Middle School',
  'Raymond S. Kellis',
  'Raymond Tellas Academy - Daep',
  'Raymond Tellas Academy - Jjaep',
  'Raymond Temple Elementary',
  'Raymond W. Kershaw School',
  'Raymond-knowles Elementary',
  'Raymondville Elem.',
  'Raymondville H S',
  'Raymondville Options Academic Academy',
  'Raymore Elem.',
  'Raymore-peculiar East Middle',
  'Raymore-peculiar South Middle',
  'Raymore-peculiar Sr. High',
  'Rayne El Sch',
  'Rayne High School',
  'Raynham Middle School',
  'Raytown Central Middle',
  'Raytown Education Ctr.',
  'Raytown Middle',
  'Raytown South Sr. High',
  'Raytown Sr. High',
  'Rayville Elementary School',
  'Rayville High School',
  'Rayville Junior High School',
  'Razdolna School',
  'Razi University',
  'Rb Boyce Elementary School',
  'Rbhudson Middle School',
  'Rcb College Preparatory Academy',
  'Rcb Medical Arts Academy',
  'Rcma Leadership Academy',
  'Rcma Wimauma Academy',
  'Rcma-glades Early Childhood',
  'Re-engagement',
  'Re-engagement School (nine Mile Falls)',
  'Re-entry High School',
  'Re-entry Middle School',
  'Rea View Elementary',
  'Reach',
  'Reach Academy',
  'Reach Academy (the)',
  'Reach Academy For Girls',
  'Reach Center Summit Lake',
  'Reach Charter',
  'Reach Charter Academy',
  'Reach Charter School',
  'Reach Cyber Cs',
  'Reach H S',
  'Reach Leadership Steam Academy',
  'Reach Programs',
  'Reach St. Louis Day Treatment',
  'Reach Transition',
  'Reaching All Minds Academy',
  'Reaching Individual Student Excellence',
  'Read (george) Middle School',
  'Read Elementary',
  'Read Mountain Middle',
  'Read Pre-k Center',
  'Read School',
  'Read-pattillo Elementary School',
  'Read-turrentine El',
  'Readfield Elementary',
  'Readfield Elementary School',
  'Reading Area Community College',
  'Reading Community High School',
  'Reading Community Middle School',
  'Reading Elementary School',
  'Reading High School',
  'Reading Intermediate Hs',
  'Reading J H',
  'Reading Memorial High',
  'Reading Muhlenberg Ctc',
  'Reading School',
  'Reading Shs',
  'Reading-fleming Intermediate School',
  'Readington Middle School',
  'Readlyn Elementary School',
  'Readsboro Elementary School',
  'Ready 4 Learning School',
  'Ready Springs Elementary',
  'Ready Start Preschool',
  'Reagan Academy',
  'Reagan College Preparatory High',
  'Reagan County El',
  'Reagan County H S',
  'Reagan County Middle',
  'Reagan Early Childhood',
  'Reagan El',
  'Reagan Elementary',
  'Reagan Elementary School',
  'Reagan H S',
  'Reagan K-8 Educational Ctr',
  'Reagan Middle School',
  'Reagan Pre-k School',
  'Real Academy (reaching Every Adolescent Learner)',
  'Real Learning Academy',
  'Real Salt Lake Academy High School',
  'Real Special Ed Ctr',
  'Realm Charter High',
  'Realm Charter Middle',
  'Realms (rimrock Expeditionary Alternative Learning Middle Sc',
  'Reams Road Elementary',
  'Reamstown El Sch',
  'Reardan Elementary School',
  'Reardan Middle-senior High School',
  'Reassignment Ctr',
  'Reaves El',
  'Reavis Elem Math & Sci Spec Schl',
  'Reavis Elem School',
  'Reavis High School',
  'Reay E Sterling Middle',
  'Reba O Steck Elementary School',
  'Rebecca Boone Elem.',
  'Rebecca Creek El',
  'Rebecca M Johnson',
  'Rebecca Turner Elemtnary School',
  'Rebound School Of Opportunity',
  'Recep Tayip Erdogan University',
  'Reche Canyon Elementary',
  'Recluse School',
  'Recovery Campus',
  'Recovery Education Campus',
  'Rector A. Jones Middle School',
  'Rector Elementary School',
  'Rector High School',
  'Rector Johnson Ms',
  'Red Apple Elementary',
  'Red Arrow Elementary School',
  'Red Bank Charter School',
  'Red Bank Elementary',
  'Red Bank Elementary School',
  'Red Bank High School',
  'Red Bank Middle School',
  'Red Bank Primary School',
  'Red Bank Regional High School',
  'Red Bay High School',
  'Red Bluff Community Day',
  'Red Bluff Community Day K-5',
  'Red Bluff El',
  'Red Bluff High',
  'Red Boiling Springs Elementary',
  'Red Boiling Springs School',
  'Red Brick School Omega',
  'Red Bridge Elem.',
  'Red Bud Elem School',
  'Red Bud Elementary School',
  'Red Bud High School',
  'Red Bud Middle School',
  'Red Bug Elementary School',
  'Red Canyon High School',
  'Red Cedar Elementary',
  'Red Cliff School',
  'Red Cloud Elementary School',
  'Red Cloud High School',
  'Red Creek Elementary',
  'Red Creek High School',
  'Red Creek Middle School',
  'Red Cross Elementary School',
  'Red Duke El',
  'Red El',
  'Red Feather Lakes Elementary School',
  'Red Hawk Elementary',
  'Red Hawk Ridge Elementary School',
  'Red Hill Elementary',
  'Red Hill Elementary School',
  'Red Hill Jr/sr High School',
  'Red Hills Middle',
  'Red Hook Neighborhood School',
  'Red Hook Residential Center',
  'Red Hook Senior High School',
  'Red House Run Elementary',
  'Red Jacket Elementary School',
  'Red Jacket High School',
  'Red Jacket Middle School',
  'Red Lake Alternative Learning',
  'Red Lake County Central Elementary',
  'Red Lake County Central High School',
  'Red Lake Early Childhood Center',
  'Red Lake Ec Special Education Ext',
  'Red Lake Elementary',
  'Red Lake Juvenile Detention Faclty',
  'Red Lake Middle Level Alc',
  'Red Lake Middle School',
  'Red Lake Secondary',
  'Red Land Shs',
  'Red Level Elementary School',
  'Red Level High School',
  'Red Lick El',
  'Red Lick Middle',
  'Red Lion Area Jhs',
  'Red Lion Area Shs',
  'Red Lodge High School',
  'Red Mesa Elementary School',
  'Red Mesa High School',
  'Red Mesa Junior High School',
  'Red Mill El Sch',
  'Red Mill Elementary',
  'Red Mill School',
  'Red Mountain Center For Early Childhood',
  'Red Mountain High School',
  'Red Mountain Middle',
  'Red Mountain Ranch Elementary',
  'Red Mountain School',
  'Red Oak El',
  'Red Oak Elem School',
  'Red Oak Elementary',
  'Red Oak Elementary School',
  'Red Oak Es',
  'Red Oak H S',
  'Red Oak High School',
  'Red Oak Hs',
  'Red Oak Middle',
  'Red Oak Middle School',
  'Red Oak-sturgeon Elementary',
  'Red Pine Elementary',
  'Red Pump Elementary School',
  'Red Raider Preschool',
  'Red River Alc - Barnesville 146',
  'Red River Area Learning Center',
  'Red River Elementary School',
  'Red River High School',
  'Red River Junior High School',
  'Red River Valley Charter School',
  'Red River Valley Elementary School',
  'Red Rock Academy',
  'Red Rock Central Elementary',
  'Red Rock Central Secondary',
  'Red Rock Day School',
  'Red Rock El',
  'Red Rock Elementary',
  'Red Rock Elementary School',
  'Red Rock Ridge - Sun',
  'Red Rock Ridge Area Learning Cntr',
  'Red Rock Ridge Learning Center',
  'Red Rock Ridge Mid-level Alc',
  'Red Rocks Community College',
  'Red Rocks Elementary School',
  'Red Sands El',
  'Red Sandstone Elementary School',
  'Red Sea University',
  'Red Shirt School - 04',
  'Red Smith K-8',
  'Red Springs High',
  'Red Springs Middle',
  'Red Top Meadows',
  'Red Trail Elementary School',
  'Red Valley/cove High School',
  'Red Water Elementary School',
  'Red Willow Colony School - 09',
  'Red Wing Hs Credit Recovery',
  'Red Wing Senior High',
  'Redan Elementary School',
  'Redan High School',
  'Redan Middle School',
  'Redbank Valley Hs',
  'Redbank Valley Intrmd Sch',
  'Redbank Valley Primary School',
  'Redbird Elementary School',
  'Redbud Run Elementary',
  'Redcliffe Elementary',
  'Reddick Elementary School',
  'Reddick-collier Elementary School',
  'Redding (louis L.) Middle School',
  'Redding Collegiate Academy',
  'Redding Community Day',
  'Redding Elementary',
  'Redding Elementary School',
  'Redding School Of The Arts',
  'Redding Stem Academy',
  'Reddix Center',
  'Redeemer College',
  'Redeemers University',
  'Redfield Elementary - 03',
  'Redfield Elementary School',
  'Redfield High School - 01',
  'Redfield Jr. High - 02',
  'Redford Service Learning Academy Campus',
  'Redford Union High School',
  'Redgranite Correctional Institute',
  'Redgranite Elementary',
  'Redirection Alternative Education',
  'Redkey Elementary School',
  'Redland Elementary School',
  'Redland Middle',
  'Redland Middle School',
  'Redland Oaks El',
  'Redlands Community College',
  'Redlands Eacademy',
  'Redlands East Valley High',
  'Redlands Middle School',
  'Redlands Senior High',
  'Redmond Early Learning Center At Hugh Hartman',
  'Redmond Elementary',
  'Redmond High',
  'Redmond High School',
  'Redmond Middle School',
  'Redmond Proficiency Academy',
  'Redondo Beach Learning Academy',
  'Redondo Elementary School',
  'Redondo Shores High (continuation)',
  'Redondo Union High',
  'Redoubt Elementary',
  'Redstone Elementary School',
  'Redtail Ridge Elementary School',
  'Reduced Services',
  'Redwater 7-8',
  'Redwater El',
  'Redwater H S',
  'Redwater J H',
  'Redwater Middle',
  'Redwater School',
  'Redway Elementary',
  'Redwood Academy Of Ukiah',
  'Redwood Coast Montessori',
  'Redwood Continuation High',
  'Redwood Elementary',
  'Redwood Elementary School',
  'Redwood Heights Elementary',
  'Redwood High',
  'Redwood Middle',
  'Redwood Middle School',
  'Redwood Pre-school',
  'Redwood Preparatory Charter',
  'Redwood School',
  'Redwood Shores Elementary',
  'Redwood Valley Alternative',
  'Redwood Valley Middle',
  'Redwood Valley Senior High',
  'Reece Academy',
  'Reeces Creek El',
  'Reeceville El Sch',
  'Reed',
  'Reed Academy',
  'Reed City High School',
  'Reed City Middle School',
  'Reed College',
  'Reed El',
  'Reed Elem School',
  'Reed Elem.',
  'Reed Elementary',
  'Reed Elementary School',
  'Reed Esc',
  'Reed Intermediate School',
  'Reed Middle',
  'Reed Point 7-8',
  'Reed Point Elementary',
  'Reed Point High School',
  'Reed School',
  'Reed-custer Elementary School',
  'Reed-custer High School',
  'Reed-custer Middle School',
  'Reed-custer Primary School',
  'Reede Gray Elementary',
  'Reeder Elementary School',
  'Reedley College',
  'Reedley High',
  'Reedley Middle College High',
  'Reeds Brook Middle School',
  'Reeds Creek Elementary',
  'Reeds Elementary',
  'Reeds Ferry School',
  'Reeds Road Elementary School',
  'Reeds Spring Elem.',
  'Reeds Spring High',
  'Reeds Spring Intermediate',
  'Reeds Spring Middle',
  'Reeds Spring Primary',
  'Reedsburg Area High',
  'Reedsport Community Charter School',
  'Reedsville Elementary',
  'Reedsville High',
  'Reedsville Middle',
  'Reedville Elementary School',
  'Reedy Creek Elementary',
  'Reedy Creek Elementary School',
  'Reedy Creek Middle',
  'Reedy Fork Elementary',
  'Reedy H S',
  'Reef Sunset Middle',
  'Reef-sunset Primary Community Day',
  'Reef-sunset Secondary Community Day',
  'Reek Elementary',
  'Reeltown Elementary School',
  'Reeltown High School',
  'Rees E. Price Elementary School',
  'Rees El',
  'Rees School',
  'Reese Educational Ctr',
  'Reese Elementary School',
  'Reese High School',
  'Reese Middle School',
  'Reese Road Leadership Academy',
  'Reethspuffer Elementary School',
  'Reethspuffer High School',
  'Reethspuffer Intermediate Sch',
  'Reethspuffer Middle School',
  'Reeves High School',
  'Reeves Middle School',
  'Reeves-hinger El',
  'Reeves-rogers Elementary',
  'Reform Elementary School',
  'Reformed Church University',
  'Reformed Theological Academy of Debrecen',
  'Refugio El',
  'Refugio H S',
  'Refugio High',
  'Refugio J H',
  'Reg Alternative Plus Self Project/roanoke City',
  'Reg Day School For Deaf',
  "Reg'l Learning Academy Star Winn",
  "Reg'l Learning Center Star Boone",
  "Reg'l Learning Center Star Winn",
  "Reg'l Safe School Program Roe #28",
  'Regal Elementary',
  'Regan Intermediate School',
  'Regan School',
  'Regency Park El Sch',
  'Regency Park Elementary',
  'Regency Place El',
  'Regency Place Elementary',
  'Regent College',
  'Regent High School',
  'Regent Park Scholars Charter Academy',
  'Regent University',
  'Regent University College of Science and Technology',
  'Regents Academy',
  'Reggio Magnet School Of The Arts',
  'Regina Howell El',
  'Reginald Bennett Elementary School',
  'Reginald Chavez Elementary',
  'Reginald F. Lewis High School',
  'Reginald M. Benton Middle',
  'Regino Vega Matinez (nueva Elemental Bo Santa Clara)',
  'Region 2 Juvenile Detention Center',
  'Region 9 Sch Of Applied Technology',
  'Region Two Sch Of Applied Tech',
  'Regional Alop School',
  'Regional Alternative Ed/king William',
  'Regional Alternative Ed/stafford Co',
  'Regional Alternative Education Center/buena Vista',
  'Regional Alternative School',
  'Regional Alternative/pittslvania Co',
  'Regional Alternative/wythe Co',
  'Regional Center For Agriscience And Career Advancement',
  'Regional Center For Change',
  'Regional Day Sch Deaf',
  'Regional Day Sch For Deaf',
  'Regional Day School',
  'Regional Institute For Children & Adolescents',
  'Regional Justice Center',
  'Regional Learning Academy/wise Co',
  'Regional Multicultural Magnet School',
  'Regional Programs School',
  'Regional/alternative Center',
  'Regis University',
  'Regl Inst Scholastic Excellence',
  'Regl Learning Academy Star Boone',
  'Rehobeth Elementary School',
  'Rehobeth High School',
  'Rehobeth Middle School',
  'Rehoboth Elementary School',
  'Rehoboth Road Middle School',
  'Reichle School',
  'Reid Elementary School',
  'Reid High',
  'Reid Park Academy',
  'Reid Ross Classical High',
  'Reid Ross Classical Middle',
  "Reid Traditional Schools' Painted Rock Academy",
  "Reid Traditional Schools' Valley Academy",
  'Reidenbaugh El Sch',
  'Reidland Elementary School',
  'Reidland Intermediate School',
  'Reidland Middle School',
  'Reidsville Elementary School',
  'Reidsville High',
  'Reidsville Middle',
  'Reidsville Middle School',
  'Reidville Elementary',
  'Reidy Creek Elementary',
  'Reiffton Sch',
  'Reilly El',
  'Reilly Elem School',
  'Reilly Elementary School',
  'Reimagine Prep',
  'Reinberg Elem School',
  'Reingold Elementary',
  'Reinhard Early Childhood Center',
  'Reinhardt El',
  'Reinhardt Holm Elementary School',
  'Reisterstown Elementary',
  'Reitaku University',
  'Relay Elementary',
  'Relevant Academy Of Eaton County',
  'Remann Hall Juvenile Detention Center',
  'Remer Elementary',
  'Remington Elementary At Potwin',
  'Remington Elementary School',
  'Remington Es',
  'Remington Middle',
  'Remington Middle School',
  'Remington Point El',
  'Remsen Elementary School',
  'Remsen Junior-senior High School',
  'Remsen-union Elementary School',
  'Remsen-union Middle School',
  'Remsenburg-speonk Elementary School',
  'Remynse El',
  'Rena B. Wright Primary',
  'Rena Elementary School',
  'Renaissance',
  'Renaissance Academy',
  'Renaissance Academy Charter School',
  'Renaissance Academy Charter School Of The Arts',
  'Renaissance Academy Cs',
  'Renaissance Alternative High School',
  'Renaissance Arts Academy',
  'Renaissance Arts Academy K-12',
  'Renaissance At Mathson',
  'Renaissance Charter Academy',
  'Renaissance Charter At Crown Point',
  'Renaissance Charter Middle School At Pines',
  'Renaissance Charter School',
  'Renaissance Charter School At Boggy Creek',
  'Renaissance Charter School At Central Florida',
  'Renaissance Charter School At Central Palm',
  'Renaissance Charter School At Chickasaw Trail',
  'Renaissance Charter School At Cooper City',
  'Renaissance Charter School At Cypress',
  'Renaissance Charter School At Goldenrod',
  'Renaissance Charter School At Hunters Creek',
  'Renaissance Charter School At Palms West',
  'Renaissance Charter School At Poinciana',
  'Renaissance Charter School At Seminole',
  'Renaissance Charter School At Summit',
  'Renaissance Charter School At Tapestry',
  'Renaissance Charter School At Tradition',
  'Renaissance Charter School At University',
  'Renaissance Charter School At Wellington',
  'Renaissance Charter School At West Palm Beach',
  'Renaissance Charter School Of Coral Springs',
  'Renaissance Charter School Of Plantation',
  'Renaissance Charter School Of St. Lucie',
  'Renaissance Charter Schools At Pines',
  'Renaissance Community School For The Arts',
  'Renaissance County Community',
  'Renaissance Court',
  'Renaissance Elementary Charter School',
  'Renaissance Es',
  'Renaissance Expedition Learn Outward Bound School',
  'Renaissance High',
  'Renaissance High Continuation',
  'Renaissance High School',
  'Renaissance High School For Musical Theater And Technology',
  'Renaissance High School For The Arts',
  'Renaissance Home For Youth',
  'Renaissance Learning Center',
  'Renaissance Magnet Middle School - Closed',
  'Renaissance Middle Charter School',
  'Renaissance Middle School',
  'Renaissance Middle School At The Rand Building',
  'Renaissance Public Academy',
  'Renaissance Public School Academy',
  'Renaissance School',
  'Renaissance School Of The Arts',
  'Renaissance Secondary School',
  'Renaissance University',
  'Renaissance Virtual School',
  'Renaissance West Steam Academy',
  'Renaissance/scott Co',
  'Renberg Elementary - 42',
  'Rend Lake College',
  'Rendezvous Elementary',
  'Rendezvous Upper Elementary School',
  'Rene A. Rost Middle School',
  'Rene Marques',
  'Renew Accelerated High School',
  'Renew Cultural Arts Academy At Live Oak Elementary',
  'Renew Dolores T. Aaron Elementary',
  'Renew Mcdonogh City Park Academy',
  'Renew Schaumburg Elementary',
  'Renew Scitech Academy At Laurel',
  'Renfroe Middle School',
  'Renick Elem.',
  'Renmin University of China',
  'Rennell El',
  'Renner Middle',
  'Reno High School',
  'Reno Valley Middle School',
  'Renovo El Sch',
  'Rensaissance Charter High School For Innovation',
  'Rensselaer Central High School',
  'Rensselaer Central Primary School',
  'Rensselaer County Jail',
  'Rensselaer Junior/senior High School',
  'Rensselaer Middle School',
  'Rensselaer Park Elementary School',
  'Rensselaer Polytechnic Institute',
  'Rensselaer-columbia-greene Boces',
  'Rentiss Co Alternative School',
  'Renton Academy',
  'Renton Park Elementary School',
  'Renton Senior High School',
  'Renton Technical College',
  'Renu Hope Preschool',
  'Renville County West Elementary',
  'Renville County West Senior High',
  'Renwood Elementary School',
  'Renzulli Gifted And Talented Academy',
  'Reo School',
  'Rep Academy',
  'Repertory Company High School For Theatre Arts',
  'Repetto Elementary',
  'Repton Junior High School',
  'Republic County Jr./sr. High School',
  'Republic Elementary School',
  'Republic High',
  'Republic High School',
  'Republic Junior High',
  'Republic Middle',
  'Republic Parent Partner',
  'Republic Senior High School',
  'Republica De Brazil',
  'Republica De Colombia',
  'Republica De Costa Rica',
  'Republica De Mexico',
  'Republica Del Ecuador',
  'Republica Del Peru',
  'Republicmichigamme School',
  'Resaca El',
  'Resaca Middle',
  'Rescue Elementary',
  'Research And Service High School',
  'Research Triangle Charter',
  'Research Triangle High School',
  'Reseda Elementary',
  'Reseda Senior High',
  'Reserve Elementary',
  'Reserve High',
  'Reserve Primary Sch',
  'Reserved For Future Use',
  'Reservoir Avenue School',
  'Reservoir High',
  'Resica El Sch',
  'Resident Student/out Of State',
  'Residential Consortium',
  'Residential Schools',
  'Residential Services',
  'Resilience Charter School Inc',
  'Resiliency Middle School',
  'Resiliency Preparatory Academy',
  'Resiliency Preparatory School',
  'Reskin Elem School',
  'Resnik Camp',
  'Resolute Academy Charter',
  'Resource Center - 11',
  'Resource Link Charter School',
  'Respect Academy At Lincoln',
  'Responsive Education Virtual Learning',
  'Ressie Jeffries Elementary',
  'Resurgence Hall Charter School',
  'Retta Brown Elementary School',
  'Reuben B. Myers Canton School Of Ar',
  'Reuben Elementary',
  'Reuben Johnson El',
  'Reunion Elementary School',
  'Reuther Central High',
  'Reuther Middle School',
  'Rev Dr Frank Napier Jr School',
  'Rev. Dr. Ercel F. Webb School',
  'Rev. Henry E. Baker Sr. Interm. School',
  'Revere (paul) Elementary',
  'Revere Elem School',
  'Revere Elementary',
  'Revere High',
  'Revere High School',
  'Revere Junior-senior High School',
  'Revere Middle',
  'Revere Middle School',
  'Reverend Benjamin Parker Elementary School',
  'Revilla Jr/sr High School',
  'Rex Bell Elementary School',
  'Rex Elem',
  'Rex Mill Middle School',
  'Rex-rennert Elementary',
  'Rexville Elemental',
  'Reyburn Intermediate',
  'Reydon Es',
  'Reydon Hs',
  'Reyes Maria Ruiz Leadership Academy',
  'Reykjavík University',
  'Reymouth Special Education Center',
  'Reynaldo Martinez Elementary School',
  'Reynolds Arthur Academy',
  'Reynolds El',
  'Reynolds El Sch',
  'Reynolds Elementary',
  'Reynolds Elementary School',
  'Reynolds High',
  'Reynolds High School',
  'Reynolds Jshs',
  'Reynolds Lane Elementary School',
  'Reynolds Learning Academy',
  'Reynolds Middle',
  'Reynolds Middle School',
  'Reynolds Ms',
  'Reynoldsburg Health Sciences & Human Services Stem Academy',
  'Reynoldsburg High School Estem',
  'Reynoldsburg Hs Business Education Leadership & Law Academy',
  'Reynoldsburg Hs Encore: Arts Communication & Design Academy',
  'Rezekne Higher School',
  'Rf Pettigrew Elementary - 17',
  'Rgv Youth Recovery Home',
  'Rham High School',
  'Rham Middle School',
  'Rhame Avenue Elementary School',
  'Rhame Elementary School',
  'Rhawnhurst Sch',
  'Rhea Central Elementary',
  'Rhea County High School',
  'Rhea Middle School',
  'Rhea Valley Elementary',
  'Rheems El Sch',
  'Rhein Benninghoven Elem',
  'Rheinisch Westfälische Technische Hochschule Aachen',
  'Rheinische Fachhochschule Köln',
  'Rheinische Friedrich-Wilhelms Universität Bonn',
  'Rhinebeck Senior High School',
  'Rhinelander High',
  'Rhoades Elementary School',
  'Rhoades Es',
  'Rhoads James Sch',
  'Rhoda Maxwell Elementary',
  'Rhode Island College',
  'Rhodes College',
  'Rhodes College And Career Academy',
  'Rhodes E Washington Sch',
  'Rhodes El',
  'Rhodes Elem School',
  'Rhodes Junior High School',
  'Rhodes Middle',
  'Rhodes Schools Of Environmental Studies',
  'Rhodes University',
  'Rialto High',
  'Rialto Middle',
  'Riara University School of Business and Law',
  'Rib Lake Elementary',
  'Rib Lake High',
  'Rib Lake Middle',
  'Rib Mountain Elementary',
  'Ricardo Arroyo Laracuente',
  'Ricardo El',
  'Ricardo Estrada Middle',
  'Ricardo Flores Magon Academy',
  'Ricardo Lizarraga Elementary',
  'Ricardo Middle',
  'Ricardo Richards Elementary Scchool',
  'Ricardo Rodriguez Torres',
  'Ricardo Salinas El',
  'Riccardi Elementary School',
  'Rice (eldridge) Elementary',
  'Rice (lilian J.) Elementary',
  'Rice (william) Elementary',
  'Rice Avenue Ms',
  'Rice Canyon Elementary',
  'Rice Challenge Academy',
  'Rice Childrens Center',
  'Rice Creek Elementary',
  'Rice Creek School',
  'Rice El',
  'Rice El Sch',
  'Rice Elementary',
  'Rice Elementary School',
  'Rice H S',
  'Rice Intermediate/middle School',
  'Rice Jh',
  'Rice Lake Elementary',
  'Rice Lake High',
  'Rice Lake Middle',
  'Rice Sch /la Escuela Rice',
  'Rice School',
  'Rice Square',
  'Rice University',
  'Riceville Elementary',
  'Riceville Elementary School',
  'Riceville High School',
  'Rich Acres Elementary',
  'Rich Central Campus High School',
  'Rich East Campus High School',
  'Rich High',
  'Rich Hill Elem.',
  'Rich Hill High',
  'Rich Hill Youth Dev. Ctr.',
  'Rich Middle School',
  'Rich Mountain Community College',
  'Rich Pond Elementary',
  'Rich Preschool',
  'Rich South Campus High School',
  'Rich Valley Elementary',
  'Richard A. Alonzo Community Day',
  'Richard Allen Academy',
  'Richard Allen Academy Ii',
  'Richard Allen Academy Iii',
  'Richard Allen Preparatory',
  'Richard Allen Preparatory Cs',
  'Richard Avenue Elementary School',
  'Richard B Wilson Jr School',
  'Richard Bard Elementary',
  'Richard Bland College of the College of William and Mary',
  'Richard Bowling Elementary',
  'Richard Butler School',
  'Richard Byrd Elementary School',
  'Richard C Crockett Middle School',
  'Richard C Hedke Elem School',
  'Richard C. Haydon Elementary',
  'Richard C. Priest Elementary School',
  'Richard Carroll Elementary',
  'Richard Crane Elementary',
  'Richard D. Browning High',
  'Richard D. Hubbard School',
  'Richard E Byrd',
  'Richard E Cavazos El',
  'Richard E Miller School',
  'Richard E. Byrd Middle',
  'Richard Elementary School',
  'Richard F Bernotas Middle Sch',
  'Richard Gordon Elementary',
  'Richard H Bryan Elementary School',
  'Richard H Hungerford School (the)',
  "Richard H O'rourke Middle School",
  'Richard H. Gettys Middle',
  'Richard H. Lewis Alternative',
  'Richard Hardy Memorial School',
  'Richard Haynes Elementary',
  'Richard Henry Dana Elementary',
  'Richard Henry Dana Exceptional Needs',
  'Richard Henry Dana Middle',
  'Richard Henry Lee Elementary',
  'Richard Ira Jones Middle School',
  'Richard J Bailey School',
  'Richard J Duncan Elementary',
  'Richard J Lee El',
  'Richard J Murphy',
  'Richard J Rundle Elementary School',
  'Richard J Wilson El',
  'Richard J. Hyde El Sch',
  'Richard Johnson Elementary',
  'Richard Kane Es',
  'Richard L Johnson Early Educ Cntr',
  'Richard L Rice School',
  'Richard L. Graves Middle',
  'Richard L. Sanders School',
  'Richard Lagow El',
  'Richard M. Moon Primary',
  'Richard M. Teitelman Middle School',
  'Richard Maghakian Memorial School',
  'Richard Mann Elementary School',
  'Richard Mckenna Charter School - Montessori',
  'Richard Mckenna Charter School - Online',
  'Richard Mckenna Charter School - Online Alternative',
  'Richard Mckenna Charter School - Onsite',
  'Richard Milburn Academy',
  'Richard Milburn Academy Amarillo',
  'Richard Milburn Academy Corpus Christi',
  'Richard Milburn Academy Fort Worth',
  'Richard Milburn Academy Houston (suburban)',
  'Richard Milburn Academy Lubbock',
  'Richard Milburn Academy Midland',
  'Richard Milburn Academy Midland South',
  'Richard Milburn Academy Odessa',
  'Richard Milburn Academy Pasadena',
  'Richard Milburn Alter H S (killeen)',
  'Richard Montgomery High',
  'Richard O. Jacobson Elementary School',
  'Richard P Connor Elementary School',
  'Richard R Green High School Of Teaching',
  'Richard Riordan Primary Center',
  'Richard Simpson Elem.',
  'Richard Stockton College of New Jersey',
  'Richard Stockton Elementary School',
  'Richard T Stank Middle School',
  'Richard T. Yates Elementary',
  'Richard W. Vincent Elementary School',
  'Richard Warren Middle School',
  'Richard Wright Pcs For Journalism And Media Arts',
  'Richard Yoakley School',
  'Richards Career Academy Hs',
  'Richards El',
  'Richards Elem.',
  'Richards Elementary',
  'Richards Elementary School',
  'Richards H S',
  'Richards Middle School',
  'Richards Sch For Young Women Leaders',
  'Richardson Classical Academy',
  'Richardson El',
  'Richardson Elem.',
  'Richardson Elementary',
  'Richardson Elementary School',
  'Richardson H S',
  'Richardson Heights El',
  'Richardson Middle',
  'Richardson Middle School',
  'Richardson Ms',
  'Richardson North J H',
  'Richardson Olmsted School',
  'Richardson Park Elementary School',
  'Richardson Park Learning Center',
  'Richardson Prep Hi',
  'Richardson Sixth Grade Academy',
  'Richardson Terrace El',
  'Richardson West J H',
  'Richardsville Elementary',
  'Richardton-taylor Elementary School',
  'Richardton-taylor High School',
  'Richboro El Sch',
  'Richboro Ms',
  'Richbourg School',
  'Richer',
  'Richey 7-8',
  'Richey Academy',
  'Richey El',
  'Richey Elementary School',
  'Richey High School',
  'Richey School',
  'Richfield College Experience Prg',
  'Richfield Dual Language School',
  'Richfield Elementary',
  'Richfield High',
  'Richfield Middle',
  'Richfield Preschool',
  'Richfield Public School Academy',
  'Richfield Public School Academy K2',
  'Richfield Public School Academy Prek',
  'Richfield School',
  'Richfield Senior High',
  'Richfield Springs Elementary School',
  'Richfield Springs Junior/senior High School',
  'Richfield Stem School',
  'Richford Elementary School',
  'Richford Junior/senior High School',
  'Richgrove Elementary',
  'Richland Avenue Elementary',
  'Richland Career Center At Archibald',
  'Richland Center High',
  'Richland College',
  'Richland Collegiate H S Of Math Science Engineerin',
  'Richland Community College',
  'Richland Continuation High',
  'Richland County Elementary School',
  'Richland County High School',
  'Richland County Middle School',
  'Richland Creek Elementary',
  'Richland El',
  'Richland El Sch',
  'Richland Elem School',
  'Richland Elem.',
  'Richland Elementary',
  'Richland Elementary School',
  'Richland H S',
  'Richland High',
  'Richland High School',
  'Richland Hs',
  'Richland Jr High',
  'Richland Jr. High',
  'Richland Junior High',
  'Richland Junior-senior High School',
  'Richland Middle',
  'Richland Northeast High',
  'Richland One Charter Middle College',
  'Richland Online Academy',
  'Richland School',
  'Richland Springs School',
  'Richland Two Charter High',
  'Richland Upper Elementary',
  'Richlands Elementary',
  'Richlands High',
  'Richlands Middle',
  'Richlands Primary',
  'Richman Elementary',
  'Richmond 9th Grade Academy',
  'Richmond Acceleration Program',
  'Richmond Alternative School',
  'Richmond Avenue School',
  'Richmond Career Education And Employment (charter School)',
  'Richmond Charter Academy',
  'Richmond Charter Elementary-benito Juarez',
  'Richmond College Preparatory',
  'Richmond Community College',
  'Richmond Community High',
  'Richmond Community High School',
  'Richmond Consolidated',
  'Richmond County Elementary',
  'Richmond County Learning Center',
  'Richmond County Technical Career Magnet School',
  'Richmond Drive Elementary',
  'Richmond Early College High',
  'Richmond El Sch',
  'Richmond Elementary',
  'Richmond Elementary School',
  'Richmond Es',
  'Richmond Grade School',
  'Richmond Heights Elementary School',
  'Richmond Heights High School',
  'Richmond Heights Middle School',
  'Richmond High',
  'Richmond High School',
  'Richmond Hill Elementary School',
  'Richmond Hill High School',
  'Richmond Hill Middle School',
  'Richmond Hill Primary School',
  'Richmond Hospital Ep.',
  'Richmond Intermediate Sch',
  'Richmond Juvenile Detention Home',
  'Richmond Middle',
  'Richmond Middle School',
  'Richmond Perrine Optimist',
  'Richmond Sch',
  'Richmond Senior High',
  'Richmond Street Elementary',
  'Richmond Technical Center',
  'Richmond University - The American International University in London',
  'Richmond-burton High School',
  'Richneck Elementary',
  'Richter El',
  'Richton Elementary School',
  'Richton High School',
  'Richton Square School',
  'Richvale Elementary',
  'Richview Middle',
  'Richwood High School',
  'Richwood Junior High School',
  'Richwood Middle School',
  'Richwoods Elem.',
  'Richwoods High School',
  'Rick Marcotte Central School',
  'Rick Schneider Middle',
  'Rickard Elementary School',
  'Rickey C Bailey Middle',
  'Rickman Elementary',
  'Rickover Jr High School',
  'Rickover Naval Academy High Schl',
  'Ricms',
  'Rico Elementary School',
  'Riddle Brook School',
  'Riddle El',
  'Riddle Elementary',
  'Riddle Elementary School',
  'Riddle High School',
  'Ride El',
  'Rideout Elementary School',
  'Rider H S',
  'Rider University',
  'Riderwood Elementary',
  'Ridge And Valley Charter School',
  'Ridge Central Elem School',
  'Ridge Circle Elem School',
  'Ridge Community High School',
  'Ridge Creek El',
  'Ridge Crest Elementary',
  'Ridge Crest Elementary School',
  'Ridge Early Childhood Center',
  'Ridge Elementary',
  'Ridge Elementary School',
  'Ridge Family Center For Learning',
  'Ridge High School',
  'Ridge Hill School',
  'Ridge Hospital Alt. Elementary',
  'Ridge Hospital Alt. High School',
  'Ridge Lawn Elementary School',
  'Ridge Meadows Elem.',
  'Ridge Middle School',
  'Ridge Mills Elementary School',
  'Ridge Park Charter Academy',
  'Ridge Park El Sch',
  'Ridge Point H S',
  'Ridge Ranch Elementary School',
  'Ridge Road Elementary School',
  'Ridge Road Middle',
  'Ridge Road Primary School',
  'Ridge Road School',
  'Ridge Ruxton',
  'Ridge Spring-monetta Elementary',
  'Ridge Spring-monetta Middle High',
  'Ridge Street School',
  'Ridge Technical College',
  'Ridge Teen Parent Program',
  'Ridge View Academy Charter School',
  'Ridge View Community School',
  'Ridge View Elem.',
  'Ridge View Elementary',
  'Ridge View Elementary School',
  'Ridge View High',
  'Ridge View High School',
  'Ridge Wood Elementary School',
  'Ridge-brook Elementary School',
  'Ridgebury Elementary School',
  'Ridgecrest Charter',
  'Ridgecrest El',
  'Ridgecrest Elementary',
  'Ridgecrest Elementary School',
  'Ridgecrest Es',
  'Ridgecrest Intermediate',
  'Ridgecrest School',
  'Ridgedale Alternative School',
  'Ridgedale Community School',
  'Ridgedale Elementary School',
  'Ridgedale Jr/sr High School',
  'Ridgedale Middle School',
  'Ridgefield High School',
  'Ridgefield Memorial High School',
  'Ridgefield Park Jr Sr High School',
  'Ridgegate El',
  'Ridgeland Elementary',
  'Ridgeland High',
  'Ridgeland High School',
  'Ridgeland-dallas Elementary',
  'Ridgeland-hardeeville High',
  'Ridgeline Academy Inc.',
  'Ridgeline High School',
  'Ridgeline Middle School',
  'Ridgeline Montessori',
  'Ridgeline School',
  'Ridgely Elem School',
  'Ridgely Elementary School',
  'Ridgely Middle',
  'Ridgemont El',
  'Ridgemont Elementary',
  'Ridgemont Elementary School',
  'Ridgemont High School',
  'Ridgemoor Elementary',
  'Ridgemoor Park Montessori',
  'Ridgepoint Elementary',
  'Ridgeroad Elementary School',
  'Ridgetop El',
  'Ridgetop Middle School',
  'Ridgeview Charter School',
  'Ridgeview Classical Charter Schools',
  'Ridgeview El',
  'Ridgeview Elem',
  'Ridgeview Elem.',
  'Ridgeview Elementary',
  'Ridgeview Elementary School',
  'Ridgeview Es',
  'Ridgeview Global Studies Academy',
  'Ridgeview Group Home',
  'Ridgeview High',
  'Ridgeview High (continuation)',
  'Ridgeview High School',
  'Ridgeview Jr High School',
  'Ridgeview Middle',
  'Ridgeview Middle School',
  'Ridgevue High School',
  'Ridgewater College',
  'Ridgeway Community School',
  'Ridgeway Elem.',
  'Ridgeway Elementary',
  'Ridgeway Elementary School',
  'Ridgeway High',
  'Ridgeway Middle',
  'Ridgeway School',
  'Ridgewood Avenue School',
  'Ridgewood Comm High School',
  'Ridgewood El',
  'Ridgewood Elem School',
  'Ridgewood Elementary',
  'Ridgewood Elementary School',
  'Ridgewood High Adult Education',
  'Ridgewood High School',
  'Ridgewood Middle',
  'Ridgewood Middle School',
  'Ridgewood Park Elementary',
  'Ridglea Hills El',
  'Ridgway Area Hs',
  'Ridgway Area Ms',
  'Ridgway Elementary School',
  'Ridgway High (continuation)',
  'Ridgway High School',
  'Ridgway Middle School',
  'Ridley Hs',
  'Ridley Ms',
  'Rieck Avenue Elementary School',
  'Rieke Elementary School',
  'Riesel School',
  'Riffenburgh Elementary School',
  'Rifle High School',
  'Rifle Middle School',
  'Riga Aviation University',
  'Riga Teacher Training and Educational Management Academy',
  'Riga Technical University',
  'Rigby High School',
  'Rigby Middle School',
  'Rigdon Road Elementary School',
  'Riggins Elementary School',
  'Riggs Elementary',
  'Right Fork School Center',
  'Rigler Elementary School',
  'Riker Hill Elementary School',
  "Rikkyo University (St. Paul's University)",
  'Riletta Twyne Cream Family School',
  'Riley Avenue School',
  'Riley Comm Cons School',
  'Riley County Grade School',
  'Riley County High School',
  'Riley Creek Elementary School',
  'Riley Elem',
  'Riley Elementary',
  'Riley Elementary School',
  'Riley High School',
  'Riley Preschool',
  'Riley School',
  'Riley Street Middle School',
  'Riley Upper Elementary School',
  'Riley/new Dawn',
  'Rilke Schule Charter School',
  'Rillito Center',
  'Rim Country Middle School',
  'Rim Of The World Senior High',
  'Rim Rock Elementary School',
  'Rim Virtual',
  'Rimer Community Learning Center',
  'Rimersburg El Sch',
  'Rimes Early Learning & Literacy Center',
  'Rimrock Colony School',
  'Rimrock Elementary',
  'Rimrock Jr/sr High School',
  'Rincon Elementary School',
  'Rincon High (continuation)',
  'Rincon High School',
  'Rincon Middle',
  'Rincon Valley Charter',
  'Rincon Valley Middle',
  'Rincon Valley Partnership',
  'Rincon Vista Middle School',
  'Rindge Avenue Upper School',
  'Rindge Memorial School',
  'Rineyville Elementary School',
  'Ring Factory Elementary',
  'Ring Lardner Middle School',
  'Ringgold El',
  'Ringgold El Sch North',
  'Ringgold El Sch South',
  'Ringgold Elementary',
  'Ringgold Elementary School',
  'Ringgold High School',
  'Ringgold Middle',
  'Ringgold Middle School',
  'Ringgold Ms',
  'Ringgold Primary School',
  'Ringgold Shs',
  'Ringing Rocks El Sch',
  'Ringling Es',
  'Ringling Hs',
  'Ringling Jhs',
  'Ringwood Es',
  'Ringwood Hs',
  'Ringwood School Primary Ctr',
  'Rini Middle College',
  'Rio Americano High',
  'Rio Bravo Elementary',
  'Rio Bravo Middle',
  'Rio Bravo-greeley Elementary',
  'Rio Calaveras Elementary',
  'Rio Canas Abajo',
  'Rio Canas Arriba',
  'Rio Cazadero High (continuation)',
  'Rio Colorado Elementary School',
  'Rio Costilla Sw Learning Academy',
  'Rio Del Mar',
  'Rio Del Mar Elementary',
  'Rio Del Norte',
  'Rio Del Oro Elementary',
  'Rio Del Rey High (continuation)',
  'Rio Del Valle Middle',
  'Rio Elementary',
  'Rio Gallinas School',
  'Rio Grande City Cisd Grulla H S',
  'Rio Grande City H S',
  'Rio Grande Elementary',
  'Rio Grande Elementary School',
  'Rio Grande High',
  'Rio Grande Preparatory Institute',
  'Rio Hondo College',
  'Rio Hondo El',
  'Rio Hondo Elementary',
  'Rio Hondo H S',
  'Rio Hondo Int',
  'Rio Hondo J H',
  'Rio Linda High',
  'Rio Linda Preparatory Academy',
  'Rio Lindo Elementary',
  'Rio Mesa High',
  'Rio Middle/high',
  'Rio Norte Junior High',
  'Rio Plaza Elementary',
  'Rio Rancho Cyber Academy',
  'Rio Rancho Elementary',
  'Rio Rancho High',
  'Rio Rancho Middle School',
  'Rio Real Elementary',
  'Rio Rico High School',
  'Rio Rosales',
  'Rio Salado College',
  'Rio San Gabriel Elementary',
  'Rio Seco Elementary',
  'Rio Tierra Junior High',
  'Rio Valley Charter',
  'Rio Verde Academy',
  'Rio Vista Community Day',
  'Rio Vista El',
  'Rio Vista Elementary',
  'Rio Vista Elementary School',
  'Rio Vista H S',
  'Rio Vista Head Start',
  'Rio Vista High',
  'Rio Vista Isd Jjaep',
  'Rio Vista Middle',
  'Rio Vista School Of Applied Learning',
  'Riojas El',
  'Rios Elementary',
  'Riphah International Univeristy',
  'Ripley Central School',
  'Ripley Co. Elem.',
  'Ripley Elementary',
  'Ripley Elementary School',
  'Ripley Es',
  'Ripley High School',
  'Ripley House Charter School',
  'Ripley House Middle Campus',
  'Ripley Hs',
  'Ripley Middle School',
  'Ripley Primary',
  'Ripley Union Lewis Huntington Elementary School',
  'Ripley Union Lewis Huntington Middle School',
  'Ripley-ohio-dearborn Sp Ed Coop',
  'Ripley-union-lewis-huntington High School',
  'Ripon College',
  'Ripon Elementary',
  'Ripon High',
  'Ripon Middle',
  'Ripona Elementary',
  'Ripperdan Community Day',
  'Rippleside Elementary',
  'Rippling Woods Elementary',
  'Rippon Middle',
  'Rippowam Middle School',
  'Ripton Elementary School',
  'Ririe Elementary School',
  'Ririe Jr/sr High School',
  'Risco Elem.',
  'Risco High',
  'Risd Acad',
  'Risdon Middle School',
  'Rise',
  'Rise & Shine Academy',
  'Rise Academy',
  'Rise Academy At Van Sickle',
  'Rise Academy For Achievement',
  'Rise Academy School Of Science And Technology',
  'Rise Community',
  'Rise High',
  'Rise Kohyang High',
  'Rise Kohyang Middle',
  'Rise Learning Center',
  'Rise Prep Mayoral Academy',
  'Rise Preschool',
  'Rise Transition Academy',
  'Rise Transitional Program',
  'Rise Up Academy At Eggleston',
  'Riser Elementary School',
  'Riser Middle School',
  'Riseup Community School',
  'Rising Leaders Academy',
  'Rising Scholars Academy Of South Texas',
  'Rising Star El',
  'Rising Star Elem',
  'Rising Star H S',
  'Rising Starr Middle School',
  'Rising Stars Academy',
  'Rising Stars Academy On Glenmore',
  'Rising Stars Academy On Harrison',
  'Rising Stars Academy On Vine',
  'Rising Stars Academy Otr',
  'Rising Sun',
  'Rising Sun Elementary',
  'Rising Sun High',
  'Rising Sun High School',
  'Rising Sun Middle School',
  'Rising Sun Montessori',
  'Rising Tide Charter Public School',
  'Risley International Academy Of Innovation',
  'Risley Middle School',
  'Rison Elementary School',
  'Rison High School',
  'Rissho University',
  'Rissler Elem.',
  'Rita A. Marquez Elementary',
  'Rita Cannan Elementary School',
  'Rita Drabek El',
  'Rita E. Miller Elementary School',
  'Rita Murphy Elementary School',
  'Rita Smith El',
  'Ritchie County High School',
  'Ritchie County Middle',
  'Ritchie Elementary School',
  'Ritchie Park Elementary',
  'Rite Of Passage',
  'Ritenour Early Childhood Ctr',
  'Ritenour Middle',
  'Ritenour Sr. High',
  'Ritsumeikan Asia Pacific University',
  'Ritsumeikan University',
  'Ritta Elementary',
  'Ritter El Sch',
  'Ritter Elementary',
  'Rittman Academy',
  'Rittman Elementary School',
  'Rittman High School',
  'Rittman Middle School',
  'Ritzman Community Learning Center',
  'Ritzville Grade School',
  'Ritzville High School',
  'Rivelon Elementary',
  'Rivendell Academy',
  'Rivendell Elementary',
  'Rivendell High School',
  'River Bend',
  'River Bend Area Learning Center',
  'River Bend Career & Technical Center',
  'River Bend Early Childhood Prog.',
  'River Bend Educational Center',
  'River Bend Elem.',
  'River Bend Elementary',
  'River Bend Middle',
  'River Bend Middle School',
  'River Birch Elementary School',
  'River Bluff Elementary',
  'River Bluff High',
  'River Bluff Middle',
  'River Charter',
  'River Charter Schools Lighthouse Charter',
  'River Cities High',
  'River City Academy',
  'River City Early College',
  'River City High',
  'River City Middle School',
  'River City Scholars Charter Academy',
  'River City Science Academy',
  'River City Science Academy At Mandarin',
  'River City Science Academy Innovation School',
  'River City Science Elementary Academy',
  'River Crest Elementary',
  'River Dell Elementary',
  'River Dell Middle School',
  'River Dell Regional High School',
  'River Delta Community Day',
  'River Delta High/elementary (alternative)',
  'River East Elementary',
  'River Elementary School',
  'River Eves Elementary School',
  'River Falls 4 Children',
  'River Falls High',
  'River Falls Public Montessori Academy',
  'River Forest High School',
  'River Forest Middle School',
  'River Gate Elementary',
  'River Gate High School',
  'River Glen',
  'River Grove Elem School',
  'River Grove Elementary School',
  'River Hall Elementary School',
  'River Heights Elementary',
  'River Heights Intermediate',
  'River Heights School',
  'River High School',
  'River Hill High',
  'River Islands Technology Academy Ii',
  'River Mill Academy',
  'River Mill Elementary School',
  'River Montessori Elementary Charter',
  'River Oak Charter',
  'River Oaks Academy',
  'River Oaks El',
  'River Oaks Elementary',
  'River Oaks Elementary School',
  'River Oaks Hospital',
  'River Oaks Middle',
  'River Parishes Community College',
  'River Pines El',
  'River Place El',
  'River Plaza Elementary School',
  'River Ridge Academy',
  'River Ridge El',
  'River Ridge Elementary',
  'River Ridge Elementary School',
  'River Ridge High',
  'River Ridge High Adult Education',
  'River Ridge High School',
  'River Ridge Middle',
  'River Ridge Middle School',
  'River Road Elementary School',
  'River Road H S',
  'River Road Middle',
  'River Road/el Camino Del Rio Elementary School',
  'River Rock School',
  'River Rouge High School',
  'River Rouge Stem Academy At Dunn',
  'River School',
  'River School (the)',
  'River Springs Charter',
  'River Springs Elementary',
  'River Springs Middle School',
  'River Street Early Learning Center (site 2)',
  'River Terrace Ec',
  'River Trail Elementary',
  'River Trail Learning Ctr @ Lo Jacob',
  'River Trail Middle School',
  'River Trail School',
  'River Trails El',
  'River Trails Lrning Ctr Parkview',
  'River Trails Middle School',
  'River Valley Academy',
  'River Valley Academy Alc',
  'River Valley Arena Community Elementary',
  'River Valley Charter',
  'River Valley Charter School',
  'River Valley Community College',
  'River Valley Early Learning Center',
  'River Valley Elementary',
  'River Valley Elementary Lone Rock',
  'River Valley Elementary Plain',
  'River Valley Elementary School',
  'River Valley High',
  'River Valley High School',
  'River Valley Int',
  'River Valley Junior- Senior High School',
  'River Valley Middle',
  'River Valley Middle School',
  'River Valley Middlehigh School',
  'River Valley School',
  'River Valley Technical Center',
  'River Valley Virtual Academy',
  'River Valley Vocational Center',
  'River View Community School',
  'River View Elementary School',
  'River View High School',
  'River View Middle School',
  'River View School',
  'River Woods Elementary School',
  "River's Edge Elementary School",
  "River's Edge Montessori Prek-6 School",
  'Rivera Early College H S',
  'Rivera El',
  'Rivera Elementary',
  'Rivera Middle',
  'Riverbank Charter School Of Excellence',
  'Riverbank Elementary',
  'Riverbank High',
  'Riverbank Language Academy',
  'Riverbend Academy',
  'Riverbend Elementary',
  'Riverbend Elementary School',
  'Riverbend High',
  'Riverbend High School',
  'Riverbend Middle School',
  'Riverbend Prep',
  'Riverbend Sch.',
  'Riverbend Treatment Ctr.',
  'Riverchase Ele',
  'Riverchase Elementary School',
  'Rivercrest El',
  'Rivercrest Elementary',
  'Rivercrest Elementary School',
  'Rivercrest H S',
  'Rivercrest High School',
  'Rivercrest J H',
  'Rivercrest Preparatory',
  'Riverdahl Elem School',
  'Riverdale',
  'Riverdale Academy',
  'Riverdale Avenue Community School',
  'Riverdale Avenue Middle School',
  'Riverdale Country Day School',
  'Riverdale Elem School',
  'Riverdale Elementary',
  'Riverdale Elementary School',
  'Riverdale Grade School',
  'Riverdale Heights Elem School',
  'Riverdale High',
  'Riverdale High School',
  'Riverdale Junior High',
  'Riverdale Middle School',
  'Riverdale Preschool Center',
  'Riverdale School',
  'Riverdale Sr High School',
  'Riverdale/kingsbridge Academy (ms/hs 141)',
  'Rivereast Program',
  'Riverfield School',
  'Riverglades Elementary School',
  'Riverglen Jr High School',
  'Rivergold Elementary',
  'Riverhead Charter School',
  'Riverhead Middle School',
  'Riverhead Senior High School',
  'Riverheads Elementary',
  'Riverheads High',
  'Riverhills Elementary Magnet School',
  'Riverland Community College',
  'Riverland Elementary School',
  'Riverlawn Elementary',
  'Rivermont Elementary',
  'Riveroak Technical College',
  'Riveroaks Elementary School',
  'Riverpoint Academy',
  'Rivers Edge Academy',
  'Rivers Edge Academy Charter School',
  'Rivers Edge Elementary',
  'Rivers Edge Elementary School',
  'Rivers Edge High School',
  'Rivers Edge School',
  'Rivers Elementary School',
  'Rivers Es',
  'Rivers State University of Science and Technology',
  'Riverside',
  'Riverside Academy',
  'Riverside Academy High School',
  'Riverside Academy Is',
  'Riverside Academy Mlp',
  'Riverside Academy Prek',
  'Riverside Academy West Campus',
  'Riverside Alternative High School',
  'Riverside Applied Lrn Ctr',
  'Riverside Brookfield Twp Hs',
  'Riverside Central Elementary',
  'Riverside City College',
  'Riverside Colony Elementary - 15',
  'Riverside Community High School',
  'Riverside Community Intermediate School',
  'Riverside County Community',
  'Riverside County Education Academy',
  'Riverside County Education Academy - Indio',
  'Riverside County Juvenile Court',
  'Riverside County Rop',
  'Riverside County Special Education',
  'Riverside Drive Charter',
  'Riverside East Elem. School',
  'Riverside Education Academy',
  'Riverside El Sch',
  'Riverside El Sch East',
  'Riverside El Sch West',
  'Riverside Elc',
  'Riverside Elementary',
  'Riverside Elementary - Cedar Rapids',
  'Riverside Elementary - Spalding',
  'Riverside Elementary Community School',
  'Riverside Elementary School',
  'Riverside H S',
  'Riverside High',
  'Riverside High School',
  'Riverside Hs',
  'Riverside Indian School',
  'Riverside Institute Of Technology',
  'Riverside Intermediate',
  'Riverside Intermediate School',
  'Riverside Jr/sr High School',
  'Riverside Jshs',
  'Riverside Junior High',
  'Riverside Junior/senior High School',
  'Riverside Leadership Magnet Elementary',
  'Riverside Learning Center',
  'Riverside Magnet School At Goodwin College',
  'Riverside Meadows Intermediate',
  'Riverside Middle',
  'Riverside Middle School',
  'Riverside Middle/high School',
  'Riverside Ms',
  'Riverside Park El',
  'Riverside Preparatory',
  'Riverside Preschool',
  'Riverside Primary School',
  'Riverside Public School',
  'Riverside School',
  'Riverside School 44',
  'Riverside School For Makers And Artists (the)',
  'Riverside Stem Academy',
  'Riverside Traditional School',
  'Riverside Virtual',
  'Riverside Virtual High School',
  'Riverside West Elem. School',
  'Riverside Youth Corr Facil El',
  'Riverside Youth Corr Facil Hs',
  'Riversink Elementary School',
  'Riversprings Middle School',
  'Riverton Elem',
  'Riverton Elem School',
  'Riverton Elementary School',
  'Riverton High',
  'Riverton High School',
  'Riverton Intermediate School',
  'Riverton Middle',
  'Riverton Middle School',
  'Riverton Parke Jr-sr High School',
  'Riverton School',
  'Riverton Street Charter School',
  'Riverview Charter School',
  'Riverview Community High School',
  'Riverview East Academy',
  'Riverview East High School',
  'Riverview Elem School',
  'Riverview Elementary',
  'Riverview Elementary School',
  'Riverview Elementary/middle',
  'Riverview Gardens Sr. High',
  'Riverview Gsrp Preschool',
  'Riverview High School',
  'Riverview Jr High',
  'Riverview Jshs',
  'Riverview Junior High School',
  'Riverview Learning Center',
  'Riverview Middle',
  'Riverview Opportunity Center',
  'Riverview School',
  'Riverview Stem Elementary',
  'Riverview Virtual Academy',
  'Riverview West School Of Excellence',
  'Rivervue Middle School',
  'Riverwalk Academy',
  'Riverwatch Middle School',
  'Riverway Elementary',
  'Riverway Learning Community Chtr',
  'Riverway Secondary',
  'Riverwood Elementary',
  'Riverwood Elementary School',
  'Riverwood International Charter School',
  'Riverwood Middle',
  'Riverwoods School - Residential',
  'Riverwoods School Non-residential',
  'Rivesville Elementary/middle School',
  'Rivier College',
  'Riviera Beach Elementary',
  'Riviera Beach Maritime Academy',
  'Riviera Beach Preparatory & Achievement Academy',
  'Riviera Elementary',
  'Riviera Elementary School',
  'Riviera Middle School',
  'Riyadh College of Dentistry and Pharmacy',
  'Rizal Technological University',
  'Rj O Intermediate School',
  'Rj Richey El',
  'Rj Steeby School',
  'Rlcc Elementary At Oklee',
  'Roach El',
  'Roach Middle',
  'Roachdale Elementary School',
  'Road Branch Elementary & Middle School',
  'Road To Success Academy',
  'Road To Success Academy At Campus Kilpatrick',
  'Roadrunner Elementary School',
  'Roadrunner School',
  'Roan Creek Elementary',
  'Roan Elementary School',
  'Roan Forest El',
  'Roane County High',
  'Roane County High School',
  'Roane State Community College',
  'Roane-jackson Vo-tech Center',
  'Roanoke Academy For Mathematics & Science Elementary',
  'Roanoke Avenue School',
  'Roanoke College',
  'Roanoke El',
  'Roanoke Elementary School',
  'Roanoke Rapids Early College High',
  'Roanoke Rapids High School',
  'Roanoke Valley Early College',
  "Roanoke Valley Governor's School",
  'Roanoke Valley Juvenile Detention Home',
  'Roanoke Valley Regional Board',
  'Roanoke-benson High School',
  'Roanoke-benson Jr High School',
  'Roanoke-​Chowan Community College',
  'Roaring Brook School',
  'Roaring Fork High School',
  'Roaring River Elementary',
  'Roark El',
  'Rob Reiner Children & Families Center',
  'Robb El',
  'Robb El Sch',
  'Robberson Elem.',
  'Robbie E Howard J H',
  'Robbie Hall Parker School',
  'Robbin E L Washington El',
  'Robbins Elementary',
  'Robbins Elementary School',
  'Robbins Lane Elementary School',
  'Robbinsdale Academic Summer Program',
  'Robbinsdale Armstrong Senior High',
  'Robbinsdale Cooper Senior High',
  'Robbinsdale Elementary - 14',
  'Robbinsdale Middle',
  'Robbinsdale Shared Time Prog.',
  'Robbinsdale Spanish Immersion El.',
  'Robbinsdale Tasc Alc',
  'Robbinsdale Transition Center',
  'Robbinsville Elementary',
  'Robbinsville High',
  'Robbinsville High School',
  'Robbinsville Middle',
  'Robein Elem School',
  'Roberge Elementary School',
  'Roberson High',
  'Robert A Jamieson School',
  'Robert A. Cross Middle Magnet',
  'Robert A. Millikan Affiliated Charter & Perf Arts Magnet Mid',
  'Robert A. Taft Elementary School',
  'Robert A. Taft Information Technology High School',
  'Robert And Danell Tarwater Elementary',
  'Robert Anderson Middle',
  'Robert Asp Elementary',
  'Robert B Glenn High School',
  'Robert B Green El',
  'Robert B Havens Elem School',
  'Robert B Jaggard School',
  'Robert B Sewell El',
  'Robert B. Turner Elementary School',
  'Robert Benjamin Wiley Community Cs',
  'Robert Bennis Elementary - 05',
  'Robert Blue School',
  'Robert Boeckman Middle School',
  'Robert Bracker Elementary',
  'Robert Brown Middle School',
  'Robert C Byrd High School',
  'Robert C Dodson School',
  'Robert C Harris Elementary School',
  'Robert C Hatch High School',
  'Robert C Hill Elem School',
  'Robert C Lindsey Elementary School',
  'Robert C Zamora Middle',
  'Robert C. Cooley Middle',
  'Robert C. Fisler Elementary',
  'Robert C. Markham Elementary',
  'Robert C. Wood Sr Early Childhood Center',
  'Robert Churchwell Elementary',
  'Robert Clow Elem Sch',
  'Robert Crippen El',
  'Robert Crown School',
  'Robert Cushman Murphy Junior High School',
  'Robert D Brandon Elem School',
  'Robert D Reynolds Primary School',
  'Robert D Sloman Primary',
  'Robert D. Campbell Jr. High',
  'Robert D. Stethem Educational Center',
  'Robert Denier Youth Services Center',
  'Robert Down Elementary',
  'Robert Driscoll Jr El',
  'Robert Drummond Elem.',
  'Robert E Abbott Middle School',
  'Robert E Bell School',
  'Robert E Clark Middle',
  'Robert E Ellis Middle',
  'Robert E Lake Elementary School',
  'Robert E Lee Early Childhood',
  'Robert E Lee El',
  'Robert E Lee Elementary',
  'Robert E Lee H S',
  'Robert E Lee Int',
  'Robert E Peary School',
  'Robert E. Aylor Middle',
  'Robert E. Cashion Elementary',
  'Robert E. Fitch High School',
  'Robert E. Howard Middle',
  'Robert E. Lee Elem.',
  'Robert E. Lee Elem. School',
  'Robert E. Lee Elementary',
  'Robert E. Lee High',
  'Robert E. Lee High School',
  'Robert E. Lee Junior High School',
  'Robert E. Lilliard Elementary',
  'Robert E. Melican Middle School',
  'Robert E. Peary Middle',
  'Robert E. Willett Elementary',
  'Robert Eagle Staff Middle School',
  'Robert Elliott Alternative Education Center',
  'Robert Ellsworth Correctional Center',
  'Robert Erskine School',
  'Robert F Bumpus Middle School',
  'Robert F Hunt',
  'Robert F Hunt El',
  'Robert F Kennedy Community High School',
  'Robert F Morehead Middle Schoo',
  'Robert F Schultz Elementary School',
  'Robert F Wagner Jr Secondary School-arts And Technology',
  'Robert F. Kennedy Charter',
  'Robert F. Kennedy El. School',
  'Robert F. Kennedy Elementary',
  'Robert F. Kennedy High',
  'Robert F. Woodall Elementary',
  'Robert Frost',
  'Robert Frost Charter School',
  'Robert Frost Elem School',
  'Robert Frost Elementary',
  'Robert Frost Elementary - 18',
  'Robert Frost Elementary School',
  'Robert Frost Jhs',
  'Robert Frost Middle',
  'Robert Frost Middle School',
  'Robert Frost School',
  'Robert Frost Sixth-grade Academy',
  'Robert Fulton College Preparatory',
  'Robert Fulton Elementary School',
  'Robert G Cole Middle/high School',
  'Robert Goddard Montessori',
  'Robert Gordon Elementary School',
  'Robert Gray Elementary',
  'Robert H Goddard High School For Comm Arts 7 & Technology',
  'Robert H Harvey Elementary School',
  'Robert H Jackson Elementary School',
  'Robert H Jamison School',
  'Robert H Rockenbaugh El',
  'Robert H Studebaker',
  'Robert H. Adams Middle School',
  'Robert H. Jenkins Junior Middle',
  'Robert H. Lewis Continuation',
  'Robert H. Prine Elementary School',
  'Robert H. Sperreng Middle',
  'Robert Heideman Elementary',
  'Robert Hill Lane Elementary',
  'Robert Hunter Elementary School',
  'Robert J Kaiser Middle School',
  'Robert J West Middle School',
  'Robert J. Burch Elementary School',
  'Robert J. Coelho Middle School',
  'Robert J. Elkington Middle School',
  'Robert J. Fite Elementary',
  'Robert J. Mcgarvey Elementary',
  "Robert J. O'brien School",
  'Robert Janss School',
  'Robert K Shafer Ms',
  'Robert Kerr School',
  'Robert King El',
  'Robert Kupper Learning Center',
  'Robert L Bradley Elementary School',
  'Robert L Forbuss Elementary School',
  'Robert L Ford',
  'Robert L Merritt Junior High School',
  'Robert L Nickels Intermediate School',
  'Robert L Olds Junior High School',
  'Robert L Puster El',
  'Robert L Taylor Elementary School',
  'Robert L. Bailey Iv',
  'Robert L. Bland Middle School',
  'Robert L. Craig School',
  'Robert L. Davis Elem. School',
  'Robert L. Horbelt Elementary School',
  'Robert L. Mccaffrey Middle',
  'Robert L. Patton High School',
  'Robert L. Shell Juvenile Center',
  'Robert L. Stevens Elementary',
  'Robert L. Stevenson Elementary School',
  'Robert Lee El',
  'Robert Lee Frost School 106',
  'Robert Lee H S',
  'Robert Lewis Magnet School',
  'Robert Lince Early Learning Center',
  'Robert Louis Stevenson Intermediate',
  'Robert Louis Stevenson Middle',
  'Robert Louis Stevenson Middle School',
  'Robert Lucas Elementary School',
  'Robert Lunt Elementary School',
  'Robert M Finley Middle School',
  'Robert M Larson Elementary School',
  'Robert M Shoemaker H S',
  'Robert M. Martin Elementary',
  'Robert M. Paterson Elementary',
  'Robert M. Pyles Stem Academy',
  'Robert Mascenick Elementary School',
  'Robert Mc Queen High School',
  'Robert Menendez Elementary School',
  'Robert Mitchell Elementary School',
  'Robert Morgan Educational Center',
  'Robert Morgan Educational Center And Technical College',
  'Robert Morris #27',
  'Robert Morris School',
  'Robert Morris School No. 18',
  'Robert Morris University',
  'Robert Morris University Illinois',
  'Robert Moses Middle School',
  'Robert Moton Elementary',
  'Robert N. Wilentz Elementary School',
  'Robert O Gibson Middle School',
  'Robert O. Townsend Junior High',
  'Robert P Brabham Middle',
  'Robert P. Ulrich Elementary',
  'Robert Place Miller Elementary School',
  'Robert R Graves School',
  'Robert R Lazar Middle School',
  'Robert R Rojas El',
  'Robert R Vela H S',
  'Robert R. Church Elementary',
  'Robert R. Gray Elementary',
  'Robert Randall Elementary',
  'Robert Reid El Sch',
  'Robert Renick Education Center',
  'Robert Richardson Elementary School',
  'Robert Russa Moton Charter School',
  'Robert Russa Moton Elementary School',
  'Robert S Farrell High School',
  'Robert S. Payne Elementary',
  'Robert Sanders Elementary',
  'Robert Seaman Elementary School',
  'Robert Semple Elementary',
  'Robert Shaw Theme School',
  'Robert Smalls International Academy',
  'Robert Stuart Middle School',
  'Robert T Hill Middle',
  'Robert T. Capeless Elementary School',
  'Robert Taft Middle School',
  'Robert Treat Academy Charter School',
  'Robert Trombly Elementary School',
  'Robert Turner College And Career H S',
  'Robert W Carbonaro School',
  'Robert W Combs Elementary School',
  'Robert W Traip Academy',
  'Robert W. Coleman Elementary',
  'Robert Waters Elementary School',
  'Robert Willis Elementary School',
  'Roberta C Cartwright Elementary School',
  'Roberta G. Doering School',
  'Roberta T. Smith Elementary School',
  'Roberta Tipps El',
  'Roberta Wright Rylander El',
  'Roberto Alberty (cacao Centro)',
  'Roberto Clemente',
  'Roberto Clemente Charter',
  'Roberto Clemente Cs',
  'Roberto Clemente Elementary Charter Scho',
  'Roberto Clemente Elementary School',
  'Roberto Clemente Leadership Academy For Global Awareness',
  'Roberto Silva Morales',
  'Roberto W. Clemente Middle',
  'Roberts 7-8',
  'Roberts Academy: A Paideia Learning Community',
  'Roberts El',
  'Roberts El Sch',
  'Roberts Elementary',
  'Roberts Elementary School',
  'Roberts Ferry Charter School Academy',
  'Roberts Ferry Union Elementary',
  'Roberts High School',
  'Roberts K-8 School',
  'Roberts Middle School',
  'Roberts Naylor',
  'Roberts Road El',
  'Roberts School',
  'Roberts Street Middle School',
  'Robertsdale Elementary School',
  'Robertsdale Gr Sch',
  'Robertsdale High School',
  'Robertson Charter School',
  'Robertson Co. Virtual School',
  'Robertson County School',
  'Robertson El',
  'Robertson Elementary',
  'Robertson Es',
  'Robertson High',
  'Robertson High (continuation)',
  'Robertson Road Elementary',
  'Robertson School',
  'Robertsville Elem.',
  'Robertsville Elementary School',
  'Robertsville Middle School',
  'Robeson Academy Malcolm X Academy',
  'Robeson Co Career Ctr',
  'Robeson Community College',
  'Robeson El Ctr',
  'Robeson Elem School',
  'Robeson High School',
  'Robey Elementary School',
  'Robichaud Senior High School',
  'Robidoux Middle',
  'Robin Hill Public School',
  'Robin Hood',
  'Robina Lyle Elementary School',
  'Robins Elementary School',
  'Robinson Academy',
  'Robinson Community Learning Center',
  'Robinson Correctional Institution',
  'Robinson El',
  'Robinson Elem',
  'Robinson Elem School',
  'Robinson Elem.',
  'Robinson Elementary',
  'Robinson Elementary School',
  'Robinson G Jones Elementary School',
  'Robinson H S',
  'Robinson High School',
  'Robinson Int',
  'Robinson Intermediate',
  'Robinson J H',
  'Robinson Middle',
  'Robinson Middle School',
  'Robinson Park',
  'Robinson Pri',
  'Robinson School',
  'Robinson Secondary',
  'Robinsonville Elementary School',
  'Robinswood Middle',
  'Robinwood Elem.',
  'Robinwood Elementary',
  'Robinwood Lane Elementary School',
  'Robious Elementary',
  'Robious Middle',
  'Robison El',
  'Robison El Sch',
  'Robison Elementary School',
  'Robla Elementary',
  'Robla Preschool',
  'Robles Elementary School',
  'Robson Elementary School',
  'Robstown Early College H S',
  'Roby El',
  'Roby Elementary School',
  'Roby H S',
  'Rochambeau Middle School',
  'Rochdale Early Advantage Charter School',
  'Roche Elementary',
  'Roche-a-cri Elementary',
  'Rochelle Focus House',
  'Rochelle Middle',
  'Rochelle Middle School',
  'Rochelle School',
  'Rochelle School Of The Arts',
  'Rochelle Twp High School',
  'Rochester Academy Charter School',
  'Rochester Academy-idp Living(rail)',
  'Rochester Adams High School',
  'Rochester Adult Education',
  'Rochester Alc Recovery Program',
  'Rochester Alternative Learning Ctr',
  'Rochester Area El Sch',
  'Rochester Beacon Academy',
  'Rochester Career Mentoring Charter School',
  'Rochester Community High School',
  'Rochester Community Middle School',
  'Rochester Community and Technical College',
  'Rochester Early College International High School',
  'Rochester Elem 2-3',
  'Rochester Elem Ec-1 Sch',
  'Rochester Elementary/high School',
  'Rochester High School',
  'Rochester Institute of Technology',
  'Rochester Institute of Technology Dubai',
  'Rochester Intermediate School',
  'Rochester Jr High School',
  'Rochester Math And Science Academy',
  'Rochester Memorial',
  'Rochester Middle School',
  'Rochester Ms',
  'Rochester Phoenix Academy',
  'Rochester Prep Charter School 3',
  'Rochester Primary School',
  'Rochester Stem Academy',
  'Rocinante High',
  'Rock Bend Hs Alc',
  'Rock Branch Elementary',
  'Rock Bridge Elem.',
  'Rock Bridge Sr. High',
  'Rock Canyon High School',
  'Rock Canyon Hs Pre-k',
  'Rock Canyon School',
  'Rock Cave Elementary',
  'Rock Chapel Elementary School',
  'Rock County High School',
  'Rock Creek Community Academy',
  'Rock Creek Elem.',
  'Rock Creek Elementary',
  'Rock Creek Elementary School',
  'Rock Creek Es',
  'Rock Creek Forest Elementary',
  'Rock Creek Grant School',
  'Rock Creek Hs',
  'Rock Creek Jr/sr High School',
  'Rock Creek Middle School',
  'Rock Creek School',
  'Rock Creek Valley Elementary',
  'Rock Crusher Elementary School',
  'Rock Cut Elem School',
  'Rock Elementary',
  'Rock Falls Middle School',
  'Rock Falls Township High School',
  'Rock Hall Elementary',
  'Rock Hill Elementary School',
  'Rock Hill High',
  'Rock Hill Middle School',
  'Rock Hill School',
  'Rock Hill Sr High School',
  'Rock Hills Elementary School',
  'Rock Hills Jr/sr High School',
  'Rock Island Academy',
  'Rock Island Elementary',
  'Rock Island Elementary School',
  'Rock Island High School',
  'Rock L Butler Ms',
  'Rock Lake Elementary',
  'Rock Lake Middle School',
  'Rock Ledge Elementary',
  'Rock Mills Junior High School',
  'Rock Point Community School',
  'Rock Port Elem.',
  'Rock Port High',
  'Rock Prairie El',
  'Rock Quarry Elementary School',
  'Rock Quary Middle School',
  'Rock Rest Elementary',
  'Rock Ridge Elementary',
  'Rock Ridge Elementary School',
  'Rock Ridge High',
  'Rock River Challenge',
  'Rock River Charter School',
  'Rock River Elementary',
  'Rock River High School',
  'Rock River Intermediate',
  'Rock River Junior High School',
  'Rock Run Elementary',
  'Rock Spring Elementary',
  'Rock Spring Elementary School',
  'Rock Springs Elementary',
  'Rock Springs Elementary School',
  'Rock Springs High School',
  'Rock Springs Junior High',
  'Rock Springs Middle School',
  'Rock Terrace School',
  'Rock University High',
  'Rock Valley College',
  'Rock Valley Elementary School',
  'Rock Valley Jr-sr High School',
  'Rock View Elementary',
  'Rockaway Collegiate High School',
  'Rockaway Meadow Elementary School',
  'Rockaway Park High School For Environmental Sustainability',
  'Rockaway Valley School',
  'Rockbridge County High',
  'Rockbridge Elementary School',
  'Rockbridge Montessori Charter School',
  'Rockbrook El',
  'Rockbrook Elementary School',
  'Rockburn Elementary',
  'Rockcastle Academy For Academic Ach',
  'Rockcastle County Area Technology Center',
  'Rockcastle County High School',
  'Rockcastle County Middle School',
  'Rockcreek Elementary School',
  'Rockdale Academy Elementary School',
  'Rockdale County High School',
  'Rockdale El',
  'Rockdale Elem School',
  'Rockdale Es',
  'Rockdale H S',
  'Rockdale Int',
  'Rockdale J H',
  'Rockdale Open Campus School',
  'Rockdale Rydc',
  'Rockdale Visual & Performing Arts Magnet',
  'Rockefeller Incentive Elem.',
  'Rockefeller University',
  'Rocketship Academy Brilliant Minds',
  'Rocketship Alma Academy',
  'Rocketship Dc Pcs Rise Academy',
  'Rocketship Discovery Prep',
  'Rocketship Fuerza Community Prep',
  'Rocketship Futuro Academy',
  'Rocketship Los Suenos Academy',
  'Rocketship Mateo Sheedy Elementary',
  'Rocketship Mosaic Elementary',
  'Rocketship Nashville Northeast Elementary',
  'Rocketship Northside',
  'Rocketship Partners Community Prep',
  'Rocketship Redwood City',
  'Rocketship Rising Stars',
  'Rocketship Si Se Puede Academy',
  'Rocketship Southside Community Prep',
  'Rocketship Spark Academy',
  'Rocketship United',
  'Rockey Glenn Camp',
  'Rockfield Elementary',
  'Rockfish Elementary',
  'Rockfish Hoke Elementary',
  'Rockfish River Elementary',
  'Rockford College',
  'Rockford Community Services',
  'Rockford East High School',
  'Rockford Elementary',
  'Rockford Elementary Arts Magnet Sch',
  'Rockford Elementary School',
  'Rockford Envrnmntl Science Acad',
  'Rockford High School',
  'Rockford Hs Freshman Center',
  'Rockford Junior-senior Rockford Senior High',
  'Rockford Middle',
  'Rockford Secondary',
  'Rockford Spanish Immersion',
  'Rockford Special Services R1ts',
  'Rockhill El Sch',
  'Rockhill Elementary',
  'Rockhill Elementary School',
  'Rockhurst College',
  'Rockingham Central Elementary School',
  'Rockingham Co Early College High',
  'Rockingham Community College',
  'Rockingham County High',
  'Rockingham County Middle',
  'Rockingham Middle',
  'Rockland Boces',
  "Rockland Children's Psychiatric Center",
  'Rockland Community College',
  'Rockland County Sheriffs Department',
  'Rockland Elem School',
  'Rockland Psychiatric Center',
  'Rockland Public School',
  'Rockland Senior High',
  'Rockland Woods Elementary',
  'Rockledge Elementary',
  'Rockledge Senior High School',
  'Rocklin Academy',
  'Rocklin Academy At Meyers Street',
  'Rocklin Academy Gateway',
  'Rocklin Elementary',
  'Rocklin High',
  'Rocklin Independent Charter Academy',
  'Rockmart High School',
  'Rockmart Middle School',
  'Rockport Colony Elementary- 10',
  'Rockport Elementary',
  'Rockport Heights Elem.',
  'Rockport High',
  'Rockport Middle',
  'Rockport-fulton H S',
  'Rockport-fulton Middle',
  'Rockport-ohio Elementary School',
  'Rockridge Academy',
  'Rockridge High School',
  'Rockridge Jr High School',
  'Rockrimmon Elementary School',
  'Rocksprings K-12',
  'Rockton Elem School',
  'Rockvale Elementary',
  'Rockvale Middle School',
  'Rockville Elementary',
  'Rockville Elementary School',
  'Rockville High',
  'Rockville High School',
  'Rockville Jr-sr High School',
  'Rockwall H S',
  'Rockwall Quest Academy',
  'Rockwall-heath H S',
  'Rockway Elementary School',
  'Rockway Middle School',
  'Rockwell Charter High School',
  'Rockwell Elementary',
  'Rockwell Elementary School',
  'Rockwell Middle School',
  'Rockwell School',
  'Rockwood Area El Sch',
  'Rockwood Area Jshs',
  'Rockwood Ctr. Early Child. Ed.',
  'Rockwood Elementary',
  'Rockwood Es',
  'Rockwood High School',
  'Rockwood Middle School',
  'Rockwood Preparatory Academy',
  'Rockwood South Middle',
  'Rockwood Summit Sr. High',
  'Rockwood Valley Middle',
  'Rocky Boy 7-8',
  'Rocky Boy High School',
  'Rocky Boy School',
  'Rocky Branch Elementary',
  'Rocky Branch Elementary School',
  'Rocky Comfort Elem.',
  'Rocky Creek Elementary',
  'Rocky Creek Elementary School',
  'Rocky Ford Junior/senior High School',
  'Rocky Fork Middle School',
  'Rocky Grove Jshs',
  'Rocky Heights Elementary School',
  'Rocky Heights Middle School',
  'Rocky Hill Elementary',
  'Rocky Hill High School',
  'Rocky Hill Middle',
  'Rocky Mount Elementary',
  'Rocky Mount Elementary School',
  'Rocky Mount High',
  'Rocky Mount Middle',
  'Rocky Mount Preparatory',
  'Rocky Mountain Academy Of Evergreen',
  'Rocky Mountain Classical Academy',
  'Rocky Mountain College',
  'Rocky Mountain Deaf School',
  'Rocky Mountain Digital Academy',
  'Rocky Mountain Elementary',
  'Rocky Mountain Elementary School',
  'Rocky Mountain High School',
  'Rocky Mountain Jr High',
  'Rocky Mountain Middle',
  'Rocky Mountain Middle School',
  'Rocky Mountain Prep - Fletcher Campus',
  'Rocky Mountain Prep: Creekside',
  'Rocky Mountain Prep: Southwest',
  'Rocky Mountain Public School',
  'Rocky Mountain School',
  'Rocky Mountain School Of Expeditionary Learning',
  'Rocky Plains Elementary School',
  'Rocky Point Charter',
  'Rocky Point Elementary',
  'Rocky Point High School',
  'Rocky Point Middle School',
  'Rocky Ridge Boarding School',
  'Rocky Ridge Elementary',
  'Rocky Ridge Elementary School',
  'Rocky River Early Childhood Center At Beach School',
  'Rocky River Elementary',
  'Rocky River High',
  'Rocky River High School',
  'Rocky River Middle School',
  'Rocky Run Elementary',
  'Rocky Run Middle',
  'Rocky Top Middle School',
  'Rocky View Elementary',
  'Rockyford School - 03',
  'Rocori Alc',
  'Rocori Middle',
  'Rocori Senior High',
  'Rod Kelley Elementary',
  'Rod Paige Middle School',
  'Rodburn Elementary School',
  'Rodeo Hills Elementary',
  'Rodeo Palms J H',
  'Roderick R Paige El',
  'Rodger & Ellen Beck J H',
  'Rodger O. Borror Middle School',
  'Rodgers Elementary',
  'Rodgers Elementary School',
  'Rodgers Forge Elementary',
  'Rodgers Middle School',
  'Rodman Early Childhood Center',
  'Rodney B. Cox Elementary School',
  'Rodney E. Thompson Middle',
  'Rodolfo C Centeno El',
  'Rodolfo Rudy Silva Jr El',
  'Rodolfo Torres El',
  'Rodriguez El',
  'Rodriguez Elementary',
  'Rodriguez Middle',
  'Rodulfo Del Valle',
  'Roe 20 Learning Alt Branch School',
  'Roe 53 Academy',
  'Roe 53 Safe School',
  'Roe Adult Learning Ctr Jersey',
  'Roe Adult Learning Ctr Macoupin',
  'Roe Alternative Prog Of Lake Co',
  'Roe Safe Sch Greene',
  'Roe Safe Sch Macoupin',
  'Roe20 Learning Alternative Branch',
  'Roebling Elementary School',
  'Roebuck Elementary',
  'Roeding Elementary',
  'Roehampton University of Surrey',
  'Roel A & Celia R Saenz El',
  'Roerinkknoll Education Center',
  'Roesland Elem',
  'Roessleville School',
  'Roff Es',
  'Roff Hs',
  'Rogene Worley Middle',
  'Roger Amos Mcmurtry School',
  'Roger Anton Elementary',
  'Roger B Chaffee Elementary School',
  'Roger Clap',
  'Roger Corbett Elementary School',
  'Roger D Gehring Elementary School',
  'Roger E Sides El',
  'Roger E Wellington',
  'Roger L. Putnam Vocational Technical Academy',
  'Roger Ludlowe Middle School',
  'Roger M Bryan Elementary School',
  'Roger Q Mills El',
  'Roger R Bell Elementary',
  'Roger S. Oraze Elementary',
  'Roger Sherman School',
  'Roger Williams Middle',
  'Roger Williams University',
  'Rogers (greg) Elementary',
  'Rogers Academy',
  'Rogers City Elementary School',
  'Rogers City High School',
  'Rogers Creek Elementary',
  'Rogers Early Elementary School',
  'Rogers El',
  'Rogers Elem School',
  'Rogers Elem.',
  'Rogers Elementary',
  'Rogers Elementary School',
  'Rogers H S',
  'Rogers Heights Elementary',
  'Rogers Heritage High School',
  'Rogers High School',
  'Rogers International School',
  'Rogers Lane Elementary',
  'Rogers Middle',
  'Rogers Middle School',
  'Rogers Ms',
  'Rogers New Technology High School',
  'Rogers Park Elementary',
  'Rogers Park Middle School',
  'Rogers Primary Sch',
  'Rogers Ranch School',
  'Rogers School',
  'Rogers Senior High',
  'Rogers Stem Academy',
  'Rogers Street Academy',
  'Rogers-herr Middle',
  'Rogersville Elementary',
  'Rogersville Middle School',
  'Rognel Heights Elementary/middle',
  'Rogue Community College',
  'Rogue River Elementary School',
  'Rogue River Junior/senior High',
  'Roguewood Elementary School',
  'Rohr (fred H.) Elementary',
  'Rohrerstown El Sch',
  'Rohwer Elementary School',
  'Roland Es',
  'Roland Green School',
  'Roland Hs',
  'Roland Jhs',
  'Roland Park Elementary/middle',
  'Roland Park K-8 Magnet School',
  'Roland Reynolds El',
  'Roland Rogers Elementary School',
  'Roland W. Russom Elementary',
  'Roland-grise Middle',
  'Roland-story Elementary School',
  'Roland-story High School',
  'Roland-story Middle School',
  'Rolando Elementary',
  'Rolando Park Elementary',
  'Rolesville Charter Academy',
  'Rolesville Elementary',
  'Rolesville High',
  'Rolesville Middle',
  'Rolette Elementary School',
  'Rolette High School',
  "Rolf's Early Childhood Center",
  'Roll Hill School',
  'Rolla Elem (prek-5)',
  'Rolla Jh/hs (6-12)',
  'Rolla Jr. High',
  'Rolla Middle',
  'Rolla Sr. High',
  'Rolla Technical Ctr.',
  'Rolla Technical Inst.',
  'Rollan D. Melton Ellementary School',
  'Rolland Colony Elementary - 05',
  'Rolland Warner Campus 67',
  'Rolling Acres El Sch',
  'Rolling Acres Middle School',
  'Rolling Green',
  'Rolling Green Elementary School',
  'Rolling Hills El',
  'Rolling Hills El Sch',
  'Rolling Hills Elementary',
  'Rolling Hills Elementary School',
  'Rolling Hills Middle',
  'Rolling Hills Primary School',
  'Rolling Hills Public Charter',
  'Rolling Hills School',
  'Rolling Knolls Elementary',
  'Rolling Meadow School',
  'Rolling Meadows El',
  'Rolling Meadows High School',
  'Rolling Meadows School',
  'Rolling Prairie Elementary School',
  'Rolling Ridge El Sch',
  'Rolling Ridge Elem',
  'Rolling Ridge Elementary',
  'Rolling Terrace Elementary',
  'Rolling Valley Elementary',
  'Rollings Middle School Of The Arts',
  'Rollingstone Community Elementary',
  'Rollingwood Elementary',
  'Rollingwood Es',
  'Rollins College',
  'Rollins Early Childhood Center',
  'Rollins Elementary School',
  'Rollins Place Elementary',
  'Rollinsford Grade School',
  'Roma H S',
  'Roma Middle',
  'Roman Baldorioty De Castro',
  'Roman Colon Correa (rio Jueyes)',
  'Romanian-American University',
  'Rombout Middle School',
  'Rome City Elementary School',
  'Rome Comm Cons Elem School',
  'Rome Corners Intermediate',
  'Rome Free Academy',
  'Rome High School',
  'Rome Middle School',
  'Romeo Elementary School',
  'Romeo Engineering Technology Center',
  'Romeo High School',
  'Romeo Middle School',
  'Romeoville High School',
  'Romero Elementary',
  'Romig Middle School',
  'Romine Interdist. Elem. School',
  'Rommelt El Sch',
  'Romney Elementary School',
  'Romney Middle School',
  'Romoland Elementary',
  'Romona Elem School',
  'Romulo D Martinez El',
  'Romulus Adult Education',
  'Romulus Adult Education Alternative',
  'Romulus Central School',
  'Romulus Elementary School',
  'Romulus Middle School',
  'Romulus Senior High School',
  'Romulus Virtual Learning Center',
  'Ron Brown College Preparatory High School',
  'Ron Hockwalt Academies (continuation)',
  'Ron Nunn Elementary',
  'Ron Russell Middle School',
  'Ron Watson Middle School',
  'Ronald A. Mcnair Elementary',
  'Ronald D O Neal',
  'Ronald E Mcnair Discover Learning Academy Elementary School',
  'Ronald E Mcnair El',
  'Ronald E Mcnair Middle',
  'Ronald E. Mcnair Elementary',
  'Ronald E. Mcnair High',
  'Ronald E. Mcnair Junior High',
  'Ronald Edmonds Learning Ctr Ii',
  'Ronald L Sodoma Elementary School',
  'Ronald L. Meinders Primary Learning Center',
  'Ronald Mcdonald House',
  'Ronald Mcnair 78',
  'Ronald Mcnair Academy',
  'Ronald Mcnair Magnet Middle School',
  'Ronald R Albrecht Elementary',
  'Ronald Reagan Academy',
  'Ronald Reagan Academy School No. 30',
  'Ronald Reagan El',
  'Ronald Reagan Elementary',
  'Ronald Reagan Es',
  'Ronald Reagan Fundamental School',
  'Ronald Thornton Middle',
  'Ronald W Reagan High School',
  'Ronald W Reagan Middle',
  'Ronald W. Reagan Elementary',
  'Ronald W. Reagan/doral Senior High School',
  'Ronald Wilson Reagan Middle',
  'Ronan High School',
  'Ronan Middle School',
  'Roncalli Stem Academy',
  'Ronceverte Elementary',
  'Ronda-clingman Elementary',
  'Rondo Campus',
  'Rondo Center Pre-k',
  'Rondout Elem School',
  'Rondout Valley High School',
  'Rondout Valley Intermediate School',
  'Rondout Valley Junior High School',
  'Rongey Ms',
  'Ronkonkoma Middle School',
  'Rooc Incorporated',
  'Rooftop Elementary',
  'Rooney Ranch Elementary School',
  'Roopville Elementary School',
  'Roosa Es',
  'Roosevelt',
  'Roosevelt (theodore) Elementary',
  'Roosevelt Academy',
  'Roosevelt Academy University College',
  'Roosevelt Alexander El',
  'Roosevelt Altern Sch',
  'Roosevelt Alternative School',
  'Roosevelt Avenue',
  'Roosevelt Center',
  'Roosevelt Charter Academy',
  "Roosevelt Children's Academy Charter School",
  'Roosevelt Early Learning Center',
  'Roosevelt Education Center',
  'Roosevelt El',
  'Roosevelt El Sch',
  'Roosevelt Elem',
  'Roosevelt Elem School',
  'Roosevelt Elem.',
  'Roosevelt Elementary',
  'Roosevelt Elementary - 08',
  'Roosevelt Elementary School',
  'Roosevelt Es',
  'Roosevelt H S',
  'Roosevelt High',
  'Roosevelt High School',
  'Roosevelt High School - 03',
  'Roosevelt High School - Early College Studies',
  'Roosevelt High School - Sws',
  'Roosevelt Hs',
  'Roosevelt Intermediate School',
  'Roosevelt International Middle',
  'Roosevelt J H',
  'Roosevelt Jr High',
  'Roosevelt Junior High',
  'Roosevelt Junior High School',
  'Roosevelt Magnet School',
  'Roosevelt Middle',
  'Roosevelt Middle Adult Education Center',
  'Roosevelt Middle School',
  'Roosevelt Ms',
  'Roosevelt Public School',
  'Roosevelt School',
  'Roosevelt School #7',
  'Roosevelt Senior High',
  'Roosevelt Stay Hs',
  'Roosevelt Steam Academy',
  'Roosevelt Theodore Ms',
  'Roosevelt-perry Elementary',
  'Roosevelt-wilson El',
  'Rooseveltmcgrath Elem School',
  'Rooster Springs El',
  'Root Elementary',
  'Root Elementary School',
  'Root River Program',
  'Rooted School',
  'Roots & Wings Community',
  'Roots And Branches School',
  'Roots Charter High School',
  'Roots Elementary',
  'Roots International Academy',
  'Roots Pcs',
  'Rootstown Elementary School',
  'Rootstown/mabel Schnee Bldg Middle School',
  'Rootstown/ward Davis Bldg High School',
  'Rop Center',
  'Roper Elementary School',
  'Ropes School',
  'Roque Guerra Jr El',
  'Rorimer Elementary',
  'Rosa Bernard',
  'Rosa C. Benitez',
  'Rosa Costa Valdiveso',
  'Rosa E. Molinari',
  'Rosa Fort High School',
  'Rosa International Middle School',
  'Rosa L Parks Middle School',
  'Rosa L. Parks Elementary',
  'Rosa L. Parks School Of Fine And Performing Arts',
  'Rosa Lee Carter Elementary',
  'Rosa Luz Zayas (cedro Arriba)',
  'Rosa M. Parks Middle',
  'Rosa M. Rodriguez',
  'Rosa Parks Center',
  'Rosa Parks Charter High School',
  'Rosa Parks Early Learning Center',
  'Rosa Parks El',
  'Rosa Parks Elementary',
  'Rosa Parks Elementary - 15',
  'Rosa Parks Elementary School',
  'Rosa Parks Environmental Science Magnet',
  'Rosa Parks Es',
  'Rosa Parks Learning Center',
  'Rosa Parks/central Community School',
  'Rosa Parks/millbrook El',
  'Rosa Pascual Paris',
  'Rosa Sanchez Vargas',
  'Rosa Scott School',
  'Rosa Taylor Elementary School',
  'Rosabelle W. Blake Academy',
  'Rosalia Elementary & Secondary School',
  'Rosalie Elementary School',
  'Rosalina C. Martinez',
  'Rosalind Franklin Stem Elementary',
  'Rosalyn Yalow Charter School',
  'Rosamond Elementary',
  'Rosamond High Early College Campus',
  'Rosamond School',
  'Rosario Belber',
  'Rosary College',
  'Rosaryville Elementary',
  'Roscoe Central School',
  'Roscoe Collegiate H S',
  'Roscoe Conkling Elementary School',
  'Roscoe El',
  'Roscoe Elementary',
  'Roscoe Middle School',
  'Roscoe R Nix Elementary',
  'Roscomare Road Elementary',
  'Roscommon Coop Preschool',
  'Roscommon Elementary School',
  'Roscommon High School',
  'Roscommon Middle School',
  'Rose Acres Elem.',
  'Rose Avenue Elementary',
  'Rose Bud Elementary School',
  'Rose Bud High School',
  'Rose City High (continuation)',
  'Rose City School',
  'Rose Creek School',
  'Rose Drive Elementary',
  'Rose E Krug Elem School',
  'Rose Elementary',
  'Rose Ferrero Elementary',
  'Rose Garden El',
  'Rose Glen Elementary',
  'Rose Haggar El',
  'Rose Hamilton Elementary School',
  'Rose Hill Elem School',
  'Rose Hill Elementary',
  'Rose Hill Elementary School',
  'Rose Hill High',
  'Rose Hill Intermediate',
  'Rose Hill Middle',
  'Rose Hill Middle School',
  'Rose Hill Primary',
  'Rose Hill School',
  'Rose Hill-magnolia Elementary',
  'Rose L Macdonald',
  'Rose M Gaffney School',
  'Rose M Singer Center',
  'Rose Park Middle',
  'Rose Park School',
  'Rose Pioneer Elementary School',
  'Rose School',
  'Rose Springs School',
  'Rose State College',
  'Rose Stein International Elementary',
  'Rose Tree El Sch',
  'Rose Union Es',
  'Rose Valley Elementary',
  'Rose Warren Elementary School',
  'Rose Witcher Es',
  'Rose-Hulman Institute of Technology',
  'Roseau Dist. Area Learning Program',
  'Roseau Elementary',
  'Roseau Secondary',
  'Rosebank Elementary',
  'Roseboro Elementary',
  'Roseboro-salemburg Middle',
  'Rosebud 7-8',
  'Rosebud Elementary - 04',
  'Rosebud Elementary School',
  'Rosebud High School',
  'Rosebud Pri',
  'Rosebud School',
  'Rosebud-lott Academy',
  'Rosebud-lott Daep',
  'Rosebud-lott H S',
  'Rosebud-lott Learning Center',
  'Rosebud-lott Middle',
  'Roseburg High School',
  'Rosecrans Elementary',
  'Rosecrest School',
  'Rosedale',
  'Rosedale Center',
  'Rosedale Colony Elementary - 11',
  'Rosedale Elementary',
  'Rosedale Elementary School',
  'Rosedale Middle',
  'Rosedale-north Elementary',
  'Rosedell Elementary',
  'Rosehill El',
  'Rosehill Elem',
  'Roseland Charter',
  'Roseland Creek Elementary',
  'Roseland Elementary',
  'Roseland Montessori',
  'Roseland Park Elementary School',
  'Roselawn Condon Elementary School',
  'Roselawn Elementary',
  'Roselawn High',
  'Roselle Middle School',
  'Roselle Park High School',
  'Roselle Park Middle School',
  'Roselle Preschool',
  'Rosemary Clarke Middle School',
  'Rosemary Elementary',
  'Rosemary Hills Elementary',
  'Rosemary Kennedy Elementary',
  'Rosemary Middle',
  'Rosemead High',
  'Rosemeade El',
  'Rosemont 6th Grade',
  'Rosemont Avenue Elementary',
  'Rosemont El',
  'Rosemont Elem School',
  'Rosemont Elementary',
  'Rosemont Elementary School',
  'Rosemont Forest Elementary',
  'Rosemont High',
  'Rosemont Middle',
  'Rosemont Park El',
  'Rosemont Ridge Middle School',
  'Rosemore Middle School',
  'Rosemount Area Learning Center',
  'Rosemount Elementary',
  'Rosemount Middle',
  'Rosemount Senior High',
  'Rosendale Intermediate',
  'Rosendale Primary',
  'Rosendale School',
  'Rosendo Benavides El',
  'Rosendo Matienzo Cintron',
  'Rosenow Elementary',
  'Rosenthal Montessori Elementary School',
  'Rosenwald Dunbar Elementary School',
  'Rosenwald Elementary',
  'Rosenwald Elementary School',
  'Rosenwald Elementary/middle',
  'Rosenwald High School',
  'Rosepine Elementary School',
  'Rosepine High School',
  'Roses In Concrete',
  'Rosette Colony Elementary - 03',
  'Roseville Adult High School',
  'Roseville Area Middle',
  'Roseville Area Senior High',
  'Roseville Community Charter School',
  'Roseville Elementary School',
  'Roseville High',
  'Roseville High School',
  'Roseville Middle School',
  'Roseway Heights School',
  'Rosewood Avenue Elementary',
  'Rosewood Elementary',
  'Rosewood Head Start',
  'Rosewood High',
  'Rosewood Magnet School',
  'Rosewood Middle',
  'Rosewood Park',
  'Rosewood School',
  'Rosholt Elementary',
  'Rosholt Elementary - 02',
  'Rosholt High',
  'Rosholt High School - 01',
  'Rosholt Jr. High - 04',
  'Rosholt Middle',
  'Rosie Sorrells Education And Social Services H S',
  'Rosinton School',
  'Rosita Elementary',
  'Rosita Valley Elementary',
  'Rosita Valley Literacy Academy',
  'Roskilde University',
  'Roskruge Bilingual Elementary School - Closed',
  'Roskruge Bilingual Magnet Middle School',
  'Rosland Elementary',
  'Roslund El Sch',
  'Roslyn Heights Elementary School',
  'Roslyn High School',
  'Roslyn Middle School',
  'Roslyn Road Elem School',
  'Roslyn Sch',
  'Rosman Elementary',
  'Rosman High',
  'Rosman Middle',
  'Rosmarie Ann Siragusa School',
  'Ross (betsy) Elementary',
  'Ross (faye) Middle',
  'Ross (lulu M.) Elementary School',
  'Ross A. Lurgio Middle School',
  'Ross Beatty High School',
  'Ross Collins Voc Center',
  'Ross El',
  'Ross El Sch',
  'Ross Elem.',
  'Ross Elementary',
  'Ross Elementary School',
  'Ross Es',
  'Ross High School',
  'Ross Middle',
  'Ross Middle School',
  'Ross Montessori School',
  'Ross N. Robinson Middle School',
  'Ross Park Elementary School',
  'Ross School',
  'Ross Sterling Middle',
  'Ross Street Elementary School',
  'Ross University Caribbean School of Medicine',
  'Ross University Caribbean School of Veterinary Medicine',
  'Ross Valley Charter',
  'Ross/woodward School',
  'Rossford High School',
  'Rossford Junior High School',
  'Rosshill Academy Elementary',
  'Rossiter School',
  'Rossman Elementary',
  'Rossmoor Elementary',
  'Rossmoyne El Sch',
  'Rosspoint Elementary School',
  'Rossview Elementary',
  'Rossview High',
  'Rossview Middle',
  'Rossville Elem',
  'Rossville Elementary School',
  'Rossville Jr.-sr. High School',
  'Rossville Middle School',
  'Rossville Middle/senior High Sch',
  'Rossville-alvin Elem School',
  'Rostov State Medical University',
  'Rostov State University',
  'Rostraver El Sch',
  'Roswell Corrections',
  'Roswell High',
  'Roswell High School',
  'Roswell North Elementary School',
  'Rosy Mound School',
  'Rotan El',
  'Rotan H S',
  'Rotella Interdistrict Magnet School',
  'Roth El',
  'Rothenberg Preparatory Academy',
  'Rother Elementary',
  'Rothsay Elementary',
  'Rothsay Secondary',
  'Rothschild Elementary',
  'Rothschild Leadership Academy School',
  'Roton Middle School',
  'Rough Rock Community School',
  'Roughrider Academy',
  'Roughrider Area Career & Tech Center',
  'Rougon Elementary School',
  'Roulhac Middle School',
  'Round Hill Elementary',
  'Round Hill Elementary School',
  'Round Lake Beach Elem School',
  'Round Lake Elementary School',
  'Round Lake Middle School',
  'Round Lake Senior High School',
  'Round Lake Transition Center',
  'Round Lake-brewster Elementary',
  'Round Lake-brewster Middle School',
  'Round Meadow Elementary',
  'Round Mountain Elementary School',
  'Round Mountain High School',
  'Round Mountain Middle School',
  'Round Prairie Elementary School',
  'Round Rock Elementary School',
  'Round Rock H S',
  'Round Rock Opport Ctr Daep',
  'Round Top Elementary',
  'Round Top-carmine El',
  'Round Top-carmine H S',
  'Round Valley',
  'Round Valley Continuation',
  'Round Valley Elementary',
  'Round Valley Elementary School',
  'Round Valley High',
  'Round Valley High School',
  'Round Valley Middle School',
  'Roundstone Elementary School',
  'Roundtown El Sch',
  'Roundup 7-8',
  'Roundup Elementary',
  'Roundup H S',
  'Roundy Elementary School',
  'Rountree Elem.',
  'Rouse Elementary',
  'Rouse H S',
  'Rouses Point Elementary School',
  'Rousseau Elementary School',
  'Rousseau Mcclellan School 91',
  'Route 40 Elementary',
  'Route 66 Elementary',
  'Rovaniemi University of Applied Sciences',
  'Rover Elementary School',
  'Rowan Avenue Elementary',
  'Rowan Co Early College',
  'Rowan College',
  'Rowan County Middle School',
  'Rowan County Preschool Center',
  'Rowan County Senior High School',
  'Rowan El Sch',
  'Rowan Elementary',
  'Rowan Elementary School',
  'Rowan Middle School',
  'Rowan-​Cabarrus Community College',
  'Rowanty Vocational Tech Ctr',
  'Rowayton School',
  'Rowe Elementary',
  'Rowe H S',
  'Rowe Lane El',
  'Rowe Middle School',
  'Rowell Elementary',
  'Rowen William Sch',
  'Rowena Chess Elementary',
  'Rowena Kyle Elementary School',
  'Rowland Academy',
  'Rowland Assistive Technology Academy',
  'Rowland Avenue Elementary',
  'Rowland El',
  'Rowland Elementary',
  'Rowland Elementary School',
  'Rowland Middle',
  'Rowland Norment Elementary',
  'Rowland Unified Community Day',
  'Rowlesburg School',
  'Rowlett El',
  'Rowlett H S',
  'Rowlett Middle Academy',
  'Roxana Junior High School',
  'Roxana Sr High School',
  'Roxboro Community School',
  'Roxboro Elementary School',
  'Roxboro Middle School',
  'Roxboro Road Elementary School',
  'Roxboro Road Middle School',
  'Roxborough Elementary School',
  'Roxborough Hs',
  'Roxborough Intermediate',
  'Roxbury Central School',
  'Roxbury Community College',
  'Roxbury High School',
  'Roxbury Preparatory Charter School',
  'Roxbury School',
  'Roxbury Village School',
  'Roxhill Elementary School',
  'Roxton Isd',
  'Roy 7-8',
  'Roy A. Hunt El Sch',
  'Roy A. Johnson High',
  'Roy Allen Elementary School',
  'Roy Benavidez El',
  'Roy C Ketcham Senior High School',
  'Roy Chapman Andrews Academy',
  'Roy Cisneros El',
  'Roy Cloud Elementary',
  'Roy De Shane Elementary School',
  'Roy E Holmes Elementary School',
  'Roy Elem School',
  'Roy Elementary',
  'Roy G. Eversole Elementary School',
  'Roy Gomm Elementary School',
  'Roy Herburger Elementary',
  'Roy High',
  'Roy High School',
  'Roy J Smith Middle',
  'Roy J Wollam El',
  'Roy Johnson Sixth Grade Campus',
  'Roy Jr High',
  'Roy Kelley Elementary School',
  'Roy L Waldron Elementary',
  'Roy Lee Walker El',
  'Roy Maas Youth Alternatives/the Bridge',
  'Roy Miller H S And Metro School Of Design',
  'Roy Neal Shelling Sr. Elementary',
  'Roy O. Andersen Elementary',
  'Roy P Benavidez El',
  'Roy Romer Middle',
  'Roy School',
  'Roy W. Brown Middle School',
  'Roy W. Loudon Elementary',
  'Roy West Martin Middle School',
  'Royal Academic Academy',
  'Royal Academy of Music',
  'Royal Academy of Music University of London',
  'Royal College of Art',
  'Royal College of Music University of London',
  'Royal College of Physicians of Ireland',
  'Royal College of Surgeons',
  'Royal Danish Academy of Fine Arts School of Architecture',
  'Royal Danish Academy of Fine Arts School of Visual Art',
  'Royal Danish Academy of Music',
  'Royal Danish School of Educational Sciences',
  'Royal Danish School of Pharmacy',
  'Royal Early Childhood Center',
  'Royal Early College H S',
  'Royal El',
  'Royal Elementary',
  'Royal Elementary School',
  'Royal Free Hospital School of Medicine University of London',
  'Royal Green Elementary School',
  'Royal H S',
  'Royal Heights Elem.',
  'Royal High',
  'Royal High School',
  'Royal Holloway and Bedford New College',
  'Royal Intermediate School',
  'Royal J H',
  'Royal Live Oaks Academy For The Arts And Sciences Charter',
  'Royal Manor Elementary School',
  'Royal Melbourne Institute of Technology',
  'Royal Middle School',
  'Royal Military Academy',
  'Royal Military College of Canada',
  'Royal Oak High School',
  'Royal Oak Middle',
  'Royal Oak Middle School',
  'Royal Oaks Elementary',
  'Royal Oaks Steam Academy (k-7)',
  'Royal Palm Beach Elementary School',
  'Royal Palm Beach High Adult',
  'Royal Palm Beach High School',
  'Royal Palm Charter School',
  'Royal Palm Elementary School',
  'Royal Palm Exceptional School Center',
  'Royal Palm Middle School',
  'Royal Palm School',
  'Royal Ridge El',
  'Royal Roads University',
  'Royal School of Library and Information Science',
  'Royal Spring Middle School',
  'Royal Sunset (continuation)',
  'Royal University of Agriculture',
  'Royal University of Bhutan',
  'Royal University of Fine Arts',
  'Royal University of Law and Economics',
  'Royal University of Phnom Penh',
  'Royal Valley Elementary',
  'Royal Valley High',
  'Royal Valley Middle School',
  'Royal Veterinary and Agricultural University',
  'Royal View Elementary School',
  'Royal lnstitute of Technology',
  'Royall Elementary',
  'Royall High',
  'Royall Intermediate',
  'Royall Middle',
  'Royals Academy Canton',
  'Royals Academy Carthage',
  'Royals Academy Macomb',
  'Royals Rssp Canton',
  'Royals Rssp Carthage',
  'Royals Rssp Macomb',
  'Royalston Community School',
  'Royalton Elementary',
  'Royalton High School',
  'Royalton Middle School',
  'Royalton Public Schools Early Child',
  'Royalton-hartland Elementary School',
  'Royalton-hartland High School',
  'Royalton-hartland Middle School',
  'Royalview Elementary School',
  'Royalwood El',
  'Roye-williams Elementary',
  'Royersford El Sch',
  'Royerton Elementary School',
  'Royle Elementary School',
  'Royse City H S',
  'Royse City Middle',
  'Royster Middle School',
  'Royston Elementary School',
  'Rozelle Elementary',
  'Rozet Elementary',
  'Rralc: Dgf Secondary 2164',
  'Rrisd Early College H S',
  'Rrmr Elementary School',
  'Rsd High School',
  'Rt Barrera El',
  'Rtc 1',
  'Rtc 2',
  'Rtr Alc',
  'Rtr Alp',
  'Rtr Elementary School',
  'Rtr High School',
  'Rtr Middle School',
  'Ruahmah J Hutchings Elementary',
  'Ruben A Cirillo High School',
  'Ruben C Rodriguez El',
  'Ruben Chavira El',
  'Ruben Daniels Adult Ed Etc',
  'Ruben Daniels Community Schooletc',
  'Ruben Dario Middle School',
  'Ruben Hinojosa El',
  'Ruben P Diaz Elementary School',
  'Ruben Rodriguez Figueroa',
  'Ruben S. Ayala High',
  'Ruben S. Torres Elementary',
  'Ruben Salazar Continuation',
  'Ruben Yancy Alternative School',
  'Rubidoux High',
  'Ruby Bridges Elementary',
  'Ruby Drive Elementary',
  'Ruby Duncan Elementary School',
  'Ruby Elementary',
  'Ruby F. Carver Elementary',
  'Ruby Major Elementary',
  'Ruby S Thomas Elementary School',
  'Ruby Valley Elementary School',
  'Ruby Van Meter School',
  'Ruby Young El',
  'Ruby-wise Elementary School',
  'Ruch Elementary School',
  'Rucker Boulevard Elementary School',
  'Rucker El',
  'Rucker Elementary',
  'Rucker Stewart Middle',
  'Ruckersville Elementary',
  'Rudd Middle School',
  'Rudder Middle',
  'Rudecinda Sepulveda Dodson Middle',
  'Ruder Elementary',
  'Rudolfo Anaya Elementary',
  'Rudolph Elem Learning Center',
  'Rudolph Gordon Elementary',
  'Rudolph Matas School',
  'Rudolph Rivera Elementary',
  'Rudolph Rivera Middle',
  'Rudsdale Continuation',
  'Rudy Elementary School',
  'Rudy G Bologna Elementary',
  'Rudyard Area Schools',
  'Rudyard Area Service Center',
  'Rue Elementary School',
  'Ruediger Elementary School',
  'Ruffner Elementary School',
  'Rufino Huertas',
  'Rufino Mendoza Sr El',
  'Rufino Vigo',
  'Rufus C Burleson El',
  'Rufus E. Payne Elementary School',
  'Rugby Ely Elementary School',
  'Rugby High School',
  'Rugby Middle',
  'Rugel El',
  'Ruggles Elem School',
  'Ruggles Lane',
  'Ruhkala Elementary',
  'Ruhr-Universität Bochum',
  'Ruhuma Junior High School',
  'Ruidoso High',
  'Ruidoso Middle',
  'Ruiz El',
  'Ruiz Elem School',
  'Rule School',
  'Ruleville Central Elem School',
  'Ruleville Central High School',
  'Ruleville Middle School',
  'Rulon M Ellis Elementary School',
  'Rum River East Education Program',
  'Rum River Elementary',
  'Rum River North',
  'Rum River South',
  'Rumbaugh El Sch',
  'Rumford Elementary',
  'Rummel Creek El',
  'Rumney Marsh Academy',
  'Rumney School (middlesex)',
  'Rumsey Station Elementary Sch',
  'Rumson-fair Haven Regional High School',
  'Rundlett Middle School',
  'Runestone Regional Learning Center',
  'Runge El',
  'Runge H S',
  'Runn El',
  'Runnells Elementary',
  'Runnelstown Elementary School',
  'Running Brook Elementary',
  'Running Brushy Middle',
  'Running Creek Elementary School',
  'Running Fox Elem.',
  'Running Springs Elementary',
  'Runnymede Elementary',
  'Runyan El',
  'Runyon Elementary School',
  'Rupert A Nock Middle',
  'Rupert El Sch',
  'Rupert Elementary',
  'Rupert Elementary School',
  'Rupley Elem School',
  'Ruppenthal Middle',
  'Ruprecht-Karls-Universität Heidelberg',
  'Rural Cass Spec Ed Unit',
  'Rural Center Elem',
  'Rural Community Academy',
  'Rural Dell Elementary School',
  'Rural Elementary School #1',
  'Rural Elementary School #2',
  'Rural Elementary School #3',
  'Rural Elementary School #4',
  'Rural Hall Elementary',
  'Rural Point Elementary',
  'Rural Retreat Elementary',
  'Rural Retreat High',
  'Rural Retreat Middle',
  'Rural Special Elem. School',
  'Rural Special High School',
  'Rural Vale Elementary',
  'Rural Virtual Academy',
  'Rusangu University',
  'Rusch Elementary',
  'Rusd Montessori',
  'Rush City Secondary',
  'Rush Creek Elementary',
  'Rush El',
  'Rush Elementary',
  'Rush Springs Es',
  'Rush Springs Hs',
  'Rush Springs Ms',
  'Rush Strong Elementary',
  'Rush-henrietta Senior High School',
  'Rusheon Middle School',
  'Rushford-peterson Elementary',
  'Rushford-peterson Middle',
  'Rushford-peterson Senior High',
  'Rushmore Academy',
  'Rushmore Avenue School',
  'Rushmore Elementary School',
  'Rushton Elem',
  'Rushville Consolidated High School',
  'Rushville Elem.',
  'Rushville Elementary School East',
  'Rushville Elementary School West',
  'Rushville-industry High School',
  'Rushwood Elementary School',
  'Rusk El',
  'Rusk H S',
  'Rusk Intermediate',
  'Rusk J H',
  'Rusk Pri',
  'Ruskin Elementary',
  'Ruskin Elementary School',
  'Ruskin High School',
  'Ruskin Prek-6 School',
  'Russell Area Technology Center',
  'Russell B Walter El Sch',
  'Russell Babb Es',
  'Russell Berrie Nanotechnology Institute',
  'Russell Blvd. Elem.',
  'Russell Byers Cs',
  'Russell C Struble El Sch',
  'Russell Cave Elementary School',
  'Russell County High School',
  'Russell County Middle School',
  'Russell County Voc.',
  'Russell Dougherty Es',
  'Russell El',
  'Russell El Sch',
  'Russell Elem School',
  'Russell Elem.',
  'Russell Elementary',
  'Russell Elementary School',
  'Russell Hawkins Jr. High',
  'Russell High',
  'Russell High School',
  'Russell I Doig Middle School',
  'Russell Jones Ed Center',
  'Russell Middle School',
  'Russell O. Brackman Middle School',
  'Russell P Schupmann El',
  'Russell Primary School',
  'Russell Ranch Elementary',
  'Russell School',
  'Russell Springs Elementary School',
  'Russell St Elementary',
  'Russell-mcdowell Intermediate School',
  'Russellville Area Technology Center',
  'Russellville City Career Tech Center',
  'Russellville Elementary',
  'Russellville Elementary School',
  'Russellville High',
  'Russellville High School',
  'Russellville Jr. High School',
  'Russellville Junior/senior High School',
  'Russellville Middle School',
  'Russellville Upper Elem. Sch.',
  'Russia Elementary School',
  'Russia High School',
  'Russian Academy of Arts',
  'Russian Academy of Theatre Arts',
  'Russian Customs Academy Vladivostok Branch',
  'Russian Jack Elementary',
  'Russian Mission School',
  'Russian State Geological Prospecting University',
  'Russian State Hydrometeorological University',
  'Russian State Medical University',
  'Russian State University for the Humanities',
  'Russian-Armenian (Slavonic) State University',
  'Russo/mcentee Academy',
  'Rustburg Elementary',
  'Rustburg High',
  'Rustburg Middle',
  'Rustic Acres Elementary - 04',
  'Rustic Lane Elementary',
  'Rustic Oak El',
  'Ruston Elementary School',
  'Ruston High School',
  'Ruston Junior High School',
  'Rutgers University',
  'Rutgers University Camden',
  'Rutgers University Newark',
  'Ruth Ann Monroe Primary',
  'Ruth Barker Middle School',
  'Ruth Barron El',
  'Ruth Brown Elementary',
  'Ruth C Kinney Elementary School',
  'Ruth Chaffee School',
  'Ruth Cherry Int',
  'Ruth Clark Elementary K-5',
  'Ruth Doyle Intermediate School',
  'Ruth Evelyn Cruz Santos',
  'Ruth Fisher Elementary School',
  'Ruth Fox Elementary School',
  'Ruth Fyfe Elementary School',
  'Ruth Grimes Elementary',
  'Ruth Hill Elementary School',
  'Ruth Hoppin School',
  'Ruth Jones Mcclendon Middle',
  'Ruth K. Broad Bay Harbor K-8 Center',
  'Ruth Livingston Elementary',
  'Ruth M Schneider Elem School',
  'Ruth Mcgregor Elementary School',
  'Ruth Mercer Elem.',
  'Ruth Musser Middle',
  'Ruth N. Upson Elementary School',
  'Ruth O. Harris Middle',
  'Ruth Owens Kruse Education Center',
  'Ruth Parker Eason School',
  'Ruth Powell Elementary School',
  'Ruth Rains Middle School',
  'Ruthe Deskin Elementary School',
  'Rutherford B Hayes High School',
  'Rutherford B. Gaston Sr. Middle',
  'Rutherford B. Hayes Elementary School',
  'Rutherford College Elem',
  'Rutherford Early College High School',
  'Rutherford El',
  'Rutherford El Sch',
  'Rutherford Elementary',
  'Rutherford High School',
  'Rutherford Opportunity Center',
  'Rutherford Winans Academy',
  'Rutherfordton Elementary School',
  'Ruthford B Hayes',
  'Ruthlawn Elementary School',
  'Ruthven-ayrshire Elementary School',
  'Ruthven-ayrshire High School',
  'Rutland Elem School',
  'Rutland Elementary',
  'Rutland Elementary - 02',
  'Rutland High School',
  'Rutland High School - 01',
  'Rutland Intermediate School',
  'Rutland Jr. High - 03',
  'Rutland Middle School',
  'Rutland Northeast Primary School',
  'Rutland Northwest School',
  'Rutland Senior High School',
  'Rutland Town Elementary School',
  'Rutledge El',
  'Rutledge Elementary School',
  'Rutledge H. Pearson Elementary School',
  'Rutledge Hall Elem School',
  'Rutledge Middle School',
  'Rutledge Primary',
  'Rutledge School',
  'Ruus Elementary',
  'Rvdo Felix Castro Rodriguez',
  'Ryal Public School',
  'Ryan El',
  'Ryan Elementary School',
  'Ryan Es',
  'Ryan H S',
  'Ryan Hs',
  'Ryan Middle School',
  'Ryan Park Elementary School',
  'Ryazan State Pedagogical University',
  'Rybinsk State Academy of Aviational Technology',
  'Rydal East Sch',
  'Ryder Elem Math & Sci Spec School',
  'Rye Cove High',
  'Rye Cove Intermediate',
  'Rye Elementary School',
  'Rye High School',
  'Rye Junior High School',
  'Rye Middle School',
  'Rye Neck Middle School',
  'Rye Neck Senior High School',
  'Ryegate 7-8',
  'Ryegate High School',
  'Ryegate School',
  'Ryerson Elementary School',
  'Ryerson Polytechnic University',
  "Rykers' Ridge Elementary School",
  'Ryland Heights Elementary School',
  'Rymfire Elementary School',
  'Ryszard Lazarski University of Commerce and Law in Warsaw',
  'Ryukoku University',
  'Ryutsu Keizai University',
  'S And S Cons El',
  'S And S Cons H S',
  'S And S Cons Middle',
  'S Ben Benavidez Elementary',
  'S Bryan Jennings Elementary School',
  'S C Lee J H',
  'S Christa Mcauliffe Elementary',
  'S E Gross Middle School',
  'S Ellen Jones Elementary School',
  'S F Austin El',
  'S G A Elementary School',
  'S Garland H S',
  'S Gus Alexander Jr El',
  'S H Crowley Int',
  'S H School',
  'S Ray Lowder Elementary',
  'S S Conner El',
  'S S Dillow El',
  'S S Palmer El Sch',
  'S S Seward Institute',
  'S St. Paul Community Lrng. Cntr.',
  'S T E M Academy',
  'S V Marshall Elementary School',
  'S W Snowden Elementary',
  'S Y School',
  'S. A. Hull Elementary School',
  'S. A. Moffett Elementary',
  'S. B. Butler School',
  'S. Christa Mcauliffe Elementary',
  'S. Christa Mcauliffe Stem Academy',
  'S. D. Spady Elementary School',
  'S. Gordon Stewart Middle',
  'S. J. Welsh Middle School',
  'S. L. Lewis Elementary School',
  'S. P. Arnett Middle School',
  'S. S. Dixon Intermediate School',
  'S. S. Dixon Primary School',
  'S. William Abel Community',
  'S. Y. Jackson Elementary',
  'S.A.T. VIRTUAL RESOURCE CENTER',
  'S.O.U.L. CHRISTIAN ACADEMY INC.',
  'S.T.A.R.S. AUTISM SCHOOL',
  'S.a.v.e. High School',
  'S.c. Tucker Elementary School',
  'S.f. County Civic Center Secondary',
  'S.f. County Court Woodside Learning Ctr',
  'S.f. County Opportunity (hilltop)',
  'S.f. County Special Education',
  'S.f. International High',
  'S.g.reinertsen Elementary',
  'S.j. Montgomery Elementary School',
  'S.l. Mason Elementary School',
  'S.p. Morton Elementary',
  'S.t.a.r. Day Treatment Ctr.',
  'S/sgt Michael P Barrera Veterans El',
  'SABOT AT STONY POINT',
  'SACRAMENTO ADVENTIST ACADEMY',
  'SACRAMENTO CHRISTIAN SCHOOL',
  'SACRAMENTO COUNTRY DAY SCHOOL',
  'SACRAMENTO MAKERS ACADEMY',
  'SACRAMENTO MONTESSORI SCHOOL',
  'SACRAMENTO WALDORF SCHOOL',
  'SACRED HEART ACADEMY',
  'SACRED HEART ACADEMY BRYN MAWR',
  'SACRED HEART AREA SCHOOL',
  'SACRED HEART CATHEDRAL PREPARATORY',
  'SACRED HEART CATHEDRAL SCHOOL',
  'SACRED HEART CATHOLIC',
  'SACRED HEART CATHOLIC ACADEMY',
  'SACRED HEART CATHOLIC ELEMENTARY SCHOOL',
  'SACRED HEART CATHOLIC SCHOOL',
  'SACRED HEART ELEMENTARY',
  'SACRED HEART ELEMENTARY & HIGH SCHOOL',
  'SACRED HEART ELEMENTARY SCHOOL',
  'SACRED HEART GRADE SCHOOL',
  'SACRED HEART GRIFFIN HIGH SCHOOL',
  'SACRED HEART H. S.',
  'SACRED HEART HIGH SCHOOL',
  'SACRED HEART KINDERGARTEN',
  'SACRED HEART MIDDLE SCHOOL - 02',
  'SACRED HEART MODEL SCHOOL',
  'SACRED HEART NATIVTY SCHOOLS',
  'SACRED HEART OF JESUS',
  'SACRED HEART OF JESUS ELEMENTARY SCHOOL',
  'SACRED HEART OF JESUS HIGH SCHOOL',
  'SACRED HEART OF JESUS SCHOOL',
  'SACRED HEART PARISH SCHOOL',
  'SACRED HEART SCHOOL',
  'SACRED HEART SCHOOL-96TH',
  'SACRED HEART SCHOOLS ATHERTON',
  'SACRED HEART VILLA',
  'SACRED HEART VILLA SCHOOL',
  'SACRED HEARTS SCHOOL',
  'SACRED HEARTS SCHOOL & EARLY LEARNING CENTER',
  'SADDLE RIVER DAY SCHOOL',
  'SADDLEBACK VALLEY CHRISTIAN SCHOOL',
  'SADDLEBROOK PREPARATORY SCHOOL',
  'SADDLERS RUN SCHOOL',
  'SAFARI LEARNING ACADEMY',
  'SAFE HARBOR ACADEMY',
  'SAFE HARBOR BAPTIST ACADEMY',
  'SAFE HARBOR SCHOOL',
  'SAFE HAVEN CHRISTIAN ACADEMY',
  'SAFE HAVEN SCHOOL',
  'SAFETY HARBOR MONTESSORI ACADEMY',
  'SAGE DAY AT BOONTON',
  'SAGE DAY AT ROCHELLE PARK',
  'SAGE HILL SCHOOL',
  'SAGE RIDGE SCHOOL',
  'SAGE SCHOOL',
  'SAGEBRUSH ELEMENTARY SCHOOL',
  'SAGEBRUSH MONTESSORI SCHOOL',
  'SAGEMONT LOWER SCHOOL',
  'SAGER SOLOMON SCHECHTER DAY SCHOOL',
  'SAGINAW CHIPPEWA ACADEMY',
  'SAGUARO HILLS SDA CHRISTIAN SCHO',
  'SAHAG-MESROB ARMENIAN CHRISTIAN SCHOOL',
  'SAILAWAY LEARNING & ACADEMY',
  'SAIN JOHN PAUL II ACADEMY',
  'SAINTS ACADEMY',
  'SAINTS PETER & PAUL ELEMENTARY SCHOOL',
  'SAINTS TABERNACLE SCHOOL OF EXCELLENCE',
  'SAINTT THERESA CATHOLIC SCHOOL',
  'SALAH TAWFIK ELEMENTARY AND MIDDLE',
  'SALAM ACADEMY',
  'SALAM SCHOOL',
  'SALEM ACADEMY',
  'SALEM CHRISTIAN ACADEMY',
  'SALEM CHRISTIAN ACADEMY ELEMENTARY',
  'SALEM CHRISTIAN SCHOOL',
  'SALEM COUNTY CHRISTIAN ACADEMY',
  'SALEM LUTHERAN SCHOOL',
  'SALEM MONTESSORI SCHOOL',
  'SALEM RANCH JR SR HIGH SCHOOL',
  'SALEM SAYERS BAPTIST ACADEMY',
  'SALEM SCHOOL',
  'SALEM WESLEYAN ACADEMY',
  'SALESIAN ELEMENTARY & JUNIOR HIGH SCHOOL',
  'SALESIAN HIGH SCHOOL',
  'SALESIANUM SCHOOL',
  'SALFORD MENNONITE CHILD CARE CENTER',
  'SALINA CHRISTIAN ACADEMY',
  'SALINE CHRISTIAN ACADEMY',
  'SALISBURY ACADEMY',
  'SALISBURY ADVENTIST SCHOOL',
  'SALISBURY BAPTIST ACADEMY',
  'SALISBURY CHRISTIAN SCHOOL',
  'SALISBURY MENNONITE SCHOOL',
  'SALISH SCHOOL OF SPOKANE',
  'SALISH SEA DEAF SCHOOL',
  'SALMON SDA SCHOOL',
  'SALMONBERRY SCHOOL',
  'SALPOINTE CATHOLIC HIGH SCHOOL',
  'SALT ACADEMY',
  'SALT AND LIGHT ACADEMY',
  'SALTMEADOW SCHOOL',
  'SALVATION CHRISTIAN ACADEMY',
  'SALVE REGINA CATHOLIC ACADEMY',
  'SAMARITAN HOUSE FOR BOYS',
  'SAMUEL FULLER SCHOOL',
  "SAMUEL GOLDWYN FOUNDATION CHILDREN'S CENTER",
  'SAN ANGELO CHRISTIAN ACADEMY',
  'SAN ANSELMO MONTESSORI SCHOOL',
  'SAN ANTONIO ACADEMY OF TEXAS',
  'SAN ANTONIO CHRISTIAN SCHOOL',
  'SAN ANTONIO COUNTRY DAY MONTESSORI SCHOOL',
  'SAN CARLOS SCHOOL',
  'SAN CLEMENTE CHRISTIAN SCHOOL',
  'SAN DIEGO ACADEMY',
  'SAN DIEGO CENTER FOR CHILDREN ACADEMY',
  'SAN DIEGO FRENCH AMERICAN SCHOOL',
  'SAN DOMENICO SCHOOL',
  'SAN FRANCISCO ADVENTIST SCHOOL',
  'SAN FRANCISCO CHRISTIAN SCHOOL',
  'SAN FRANCISCO CITY ACADEMY',
  'SAN FRANCISCO DAY SCHOOL',
  'SAN FRANCISCO DE ASIS',
  'SAN FRANCISCO FRIENDS SCHOOL',
  'SAN FRANCISCO PACIFIC ACADEMY',
  'SAN FRANCISCO SCHOOL',
  'SAN FRANCISCO UNIVERSITY HIGH SCHOOL',
  'SAN FRANCISCO WALDORF HIGH SCHOOL',
  'SAN FRANCISCO WALDORF SCHOOL',
  'SAN GABRIEL ACADEMY',
  'SAN GABRIEL CHRISTIAN SCHOOL',
  'SAN GABRIEL MISSION ELEMENTARY SCHOOL',
  'SAN GABRIEL MISSION HIGH SCHOOL',
  'SAN JACINTO CHRISTIAN ACADEMY',
  'SAN JOAQUIN MEMORIAL HIGH SCHOOL',
  'SAN JOSE CATHOLIC GRADE SCHOOL',
  'SAN JOSE CHRISTIAN SCHOOL',
  'SAN JOSE EPISCOPAL DAY SCHOOL',
  'SAN JOSE MONTESSORI SCHOOL',
  'SAN JUAN DEL RIO CATHOLIC SCHOOL',
  'SAN JUAN DIEGO ACADEMY',
  'SAN JUAN DIEGO CATHOLIC HIGH SCHOOL',
  'SAN JUAN MONTESSORI SCHOOL',
  'SAN LUIS OBISPO CHRISTIAN ACADEMY',
  'SAN MARCOS ADVENTIST JUNIOR ACADEMY',
  'SAN MARCOS BAPTIST ACADEMY',
  'SAN MARTIN DE PORRES CATHOLIC SCHOOL',
  'SAN MIGUEL ACADEMY OF NEWBURGH',
  'SAN MIGUEL HIGH SCHOOL',
  'SAN MIGUEL SCHOOL',
  'SAN MIGUEL SCHOOL OF TULSA',
  'SAN RAMON VALLEY CHRISTIAN ACADEMY',
  'SAN XAVIER MISSION SCHOOL',
  'SANBORN CHRISTIAN SCHOOL',
  'SANCTA FAMILIA ACADEMY',
  'SANCTUARY ACADEMY OF THE REGIONAL BIBLE INSTITUTE',
  'SAND HILL SCHOOL',
  'SANDBOX LEARNING CENTER',
  'SANDBOX PRESCHOOL & CHILD CARE',
  'SANDCASTLE MONTESSORI ACADEMY',
  'SANDERLING WALDORF SCHOOL',
  'SANDHILLS CLASSICAL CHRISTIAN SCHOOL',
  'SANDHILLS SCHOOL',
  'SANDIA MONTESSORI SCHOOL OF ALBUQUERQUE',
  'SANDIA PREPARATORY SCHOOL',
  'SANDIA VIEW ACADEMY',
  'SANDIA VIEW CHRISTIAN SCHOOL',
  'SANDPOINT CHRISTIAN SCHOOL',
  'SANDPOINT JR ACADEMY',
  'SANDPOINT WALDORF SCHOOL',
  'SANDS SPRINGS SCHOOL',
  'SANDUSKY CENTRAL CATHOLIC SCHOOL',
  'SANDY CREEK CHRISTIAN ACADEMY',
  'SANDY HILL SCHOOL/IVAN KING',
  'SANDY LANE NURSERY SCHOOL',
  'SANDY OAK AMISH SCHOOL',
  'SANDY RIDGE SCHOOL',
  'SANDY SPRINGS FRIENDS MIDDLE SCHOOL',
  'SANDY SPRINGS UNITED METHODIST PRESCHOOL & KINDERG',
  'SANFORD CHRISTIAN ACADEMY',
  'SANIKU EAST-WEST LANGUAGE SCHOOL',
  'SANIKU GAKUIN SANTA CLARA JAPANESE SCHOOL',
  'SANLANDO CHRISTIAN SCHOOL',
  'SANS Technology Institute',
  "SANSON'S PRESCHOOL LEARNING CENTER",
  'SANT BANI SCHOOL',
  'SANTA BARBARA MIDDLE SCHOOL',
  'SANTA CATALINA SCHOOL',
  'SANTA CLARA CHRISTIAN SCHOOL',
  'SANTA CLARA DAY NURSERY',
  'SANTA CLARA ELEMENTARY SCHOOL',
  'SANTA CLARA OF ASSISI SCHOOL',
  'SANTA CLARITA CHRISTIAN SCHOOL',
  'SANTA CRUZ CATHOLIC SCHOOL',
  'SANTA CRUZ CHILDRENS SCHOOL',
  'SANTA CRUZ MONTESSORI SCHOOL',
  'SANTA CRUZ WALDORF SCHOOL',
  'SANTA FE CENTERS',
  'SANTA FE CHRISTIAN SCHOOLS',
  "SANTA FE GIRLS' SCHOOL",
  'SANTA FE KIDS COMPANY OF CALIFORNIA',
  'SANTA FE SCHOOL FOR THE ARTS & SCIENCES',
  'SANTA FE SPRINGS CHRISTIAN',
  'SANTA FE WALDORF SCHOOL',
  'SANTA LUCIA SCHOOL',
  'SANTA MARGARITA CATHOLIC HIGH SCHOOL',
  'SANTA MARIA SCHOOL',
  'SANTA MONICA MONTESSORI',
  'SANTA ROSA CHRISTIAN SCHOOL',
  'SANTA ROSA DE LIMA CATHOLIC SCHOOL',
  'SANTA SOPHIA ACADEMY',
  'SANTA TERESITA ELEMENTARY SCHOOL',
  'SANTA YNEZ VALLEY CHRISTIAN ACADEMY',
  'SANTIAM CHRISTIAN SCHOOL',
  'SANTO NINOS REGIONAL CATHOLIC SCHOOL',
  'SANYU LEARNING CENTER',
  'SAPA UN CATHOLIC ACADEMY ELEMENTARY',
  'SAR HIGH SCHOOL',
  'SARAH & IRVING PITT CHILD DEVELOPMENT CENTER',
  'SARAH DOOLEY CENTER FOR AUTISM SCHOOL',
  'SARAH LAWRENCE EARLY CHILDHOOD CENTER',
  'SARATOGA CENTRAL CATHOLIC HIGH SCHOOL',
  'SARI ISDANER EARLY CHILDHOOD CENTER AT KATZ JCC',
  'SASTRA Deemed University',
  'SATORI',
  'SATORI SCHOOL',
  'SATSUMA CHRISTIAN SCHOOL',
  'SAUGANASH MONTESSORI SCHOOL',
  'SAUL MIROWITZ JEWISH COMMUNITY SCHOOL',
  'SAVANNAH ADVENTIST CHRISTIAN SCHOOL',
  'SAVANNAH CHRISTIAN ACADEMY',
  'SAVANNAH CHRISTIAN MIDDLE SCHOOL',
  'SAVANNAH CHRISTIAN PREPARATORY SCHOOL',
  'SAVANNAH COUNTRY DAY SCHOOL',
  'SAVANNAYH HONOR ACADEMY',
  'SAVARY ACADEMY FOR VIRTUAL EDUCATION SAVE',
  'SAVIO HOUSE',
  'SAWMILL LANE',
  'SAWMILL ROAD SCHOOL',
  'SAWTELLE LEARNING CENTER',
  'SAXON HILL SCHOOL',
  'SAXONY LUTHERAN HIGH SCHOOL',
  'SAYRE SCHOOL',
  'SCALPY HOLLOW SCHOOL',
  'SCAPPOOSE ADVENTIST SCHOOL',
  'SCARED HEART HIGH SCHOOL',
  'SCATTERGOOD FRIENDS SCHOOL',
  'SCECINA MEMORIAL HIGH SCHOOL',
  'SCENIC ACRES',
  'SCENIC HILLS CHRISTIAN ACADEMY',
  'SCENIC KNOLL SCHOOL',
  'SCENIC MOUNTAIN PAROCHIAL SCHOOL',
  'SCENIC POINT SCHOOL',
  'SCENIC VALLEY',
  'SCENIC VIEW C/O ESTA BEACHY',
  'SCENIC VIEW CHRISTIAN SCHOOL',
  'SCENIC VIEW SCHOOL',
  'SCHAEFFER ACADEMY',
  'SCHAEFFERSTOWN MENNONITE HIGH SCHOOL',
  'SCHAUMBURG CHRISTIAN SCHOOL',
  'SCHECHTER SCHOOL OF LONG ISLAND',
  "SCHOLAR'S PREP ACADEMY INC",
  'SCHOLARS ACADEMY',
  'SCHOLARS PREP ACADEMY,INC.',
  'SCHOLZE CENTER',
  'SCHOOL AROUND US',
  'SCHOOL FOR CHILDREN WITH HIDDEN INTELLIGENCE',
  'SCHOOL FOR COMMUNITY LEARNING',
  'SCHOOL FOR INDEPENDENT LEARNERS',
  'SCHOOL FOR LANGUAGE & COMMUNICATION DEVELOPMENT',
  'SCHOOL FOR YOUNG PERFORMERS',
  'SCHOOL HOUSE MONTESSORI ACADEMY',
  'SCHOOL OF EXCELLENCE',
  'SCHOOL OF INCARNATION',
  'SCHOOL OF KNOWLEDGE',
  'SCHOOL OF LITTLE SCHOLARS',
  'SCHOOL OF OUR LADY',
  'SCHOOL OF SAINT ELIZABETH',
  'SCHOOL OF SAINT LEO THE GREAT',
  'SCHOOL OF SANTA ISABEL',
  'SCHOOL OF ST MARY',
  'SCHOOL OF ST MARY PRIMARY CENTER',
  'SCHOOL OF ST PETER',
  'SCHOOL OF THE BLESSED SACRAMENT SCHOOL',
  'SCHOOL OF THE CATHEDRAL MARY OUR QUEEN',
  'SCHOOL OF THE HOLY CHILDHOOD',
  'SCHOOL OF THE INCARNATION',
  'SCHOOL OF THE MADELEINE',
  'SCHOOL OF THE OZARKS',
  'SCHOOL OF THE WOODS',
  'SCHOOL ONE',
  'SCHOOL TIME',
  'SCHOOL WOODLAND HILL',
  'SCHOOLHOUSE ACADEMY',
  'SCHOOLHOUSE LEARNING CENTER-SELLERSVILLE',
  'SCHOOLHOUSE MONTESSORI ACADEMY',
  'SCHRADER LANE CHILD CARE CENTER',
  'SCHROTH SCHOOL',
  'SCIENCE ACADEMY OF CHICAGO',
  'SCIENCE LANGUAGE & ARTS',
  'SCO FAMILY OF SERVICES/T PAPLIN SCHOOL',
  'SCOFIELD CHRISTIAN SCHOOL',
  'SCORE ACADEMY',
  'SCORE AT THE TOP CORAL SPRINGS LLC',
  'SCORE AT THE TOP LEARNING CENTER - WELLINGTON',
  'SCORE AT THE TOP PALM BEACH LLC',
  'SCOTCH PLAINS BAPTIST CHRISTIAN DAY SCHOOL',
  'SCOTLAND CHRISTIAN ACADEMY',
  'SCOTT VALLEY CHRISTIAN ACADEMY',
  'SCOTTSDALE CHILD CARE & LEARNING CENTER -KIERLAND',
  'SCOTTSDALE CHILD CARE & LEARNING CTR - CAREFREE',
  'SCOTTSDALE CHRISTIAN ACADEMY',
  'SCOUT RIDGE',
  'SCRANTON AREA CHRISTIAN ACADEMY DBA ABINGTON CHRIS',
  'SCRANTON HEBREW DAY SCHOOL',
  'SCRANTON PREPARATORY SCHOOL',
  'SCRANTON SCHOOL FOR DEAF & HARD OF HEARING',
  'SCRIBES & SCRIBBLERS CHILD DEVELOPMENT CENTER',
  'SCRIPPS MONTESSORI SCHOOL',
  'SCY HIGH (SOUTHERN CALIFORNIA YESHIVA HIGH SCHOOL)',
  'SEA CHANGE PREPARATORY',
  'SEA CREST SCHOOL',
  'SEA STAR WALDORF SCHOOL',
  'SEABROOK AVENTIST EARLY LEARNING CENTER',
  'SEABURY HALL',
  'SEACOAST CHRISTIAN ACADEMY',
  'SEACOAST LEARNING COLLABORATIVE',
  'SEACOAST WALDORF SCHOOL',
  'SEACREST COUNTRY DAY SCHOOL',
  'SEAGER HILL SCHOOL',
  'SEAMARK RANCH',
  'SEAPORT ACADEMY',
  'SEASHORE SCHOOL',
  'SEASIDE CHRISTIAN ACADEMY',
  'SEATTLE ACADEMY',
  'SEATTLE AMISTAD SCHOOL',
  'SEATTLE AREA GERMAN AMERICAN SCHOOL',
  'SEATTLE CHRISTIAN SCHOOLS',
  'SEATTLE GIRLS SCHOOL',
  'SEATTLE HEBREW ACADEMY',
  'SEATTLE NATIVITY SCHOOL',
  'SEATTLE PREPARATORY SCHOOL',
  'SEATTLE URBAN ACADEMY',
  'SEATTLE WALDORF HIGH SCHOOL',
  'SEATTLE WALDORF SCHOOL',
  'SEBA DALKAI BOARDING SCHOOL',
  'SECOND BAPTIST KINDERGARTEN',
  'SECOND BAPTIST SCHOOL',
  'SECOND CHANCE TECHNOLOGY EDGE',
  'SECOND HOME LEARNING CENTER',
  'SECOND PRESBYTERIAN WEEKDAY SCHOOL',
  'SECOND START',
  'SECOND START-PINE HILL SCHOOL',
  'SECOND STREET CHILDRENS SCHOOL',
  'SECRET GARDEN MONTESSORI',
  'SEDGEFIELD SCHOOL',
  'SEDONA SKY ACADEMY',
  'SEEDLINGS EARLY LEARNING CENTER',
  'SEEDS OF EXCELLENCE CHRISTIAN ACADEMY',
  'SEEDS OF FAITH CHRISTIAN ACADEMY',
  'SEFFNER CHRISTIAN ACADEMY',
  'SEGUIN CHRISTIAN ACADEMY',
  'SEIGAKUIN ATLANTA INTERNATIONAL SCHOOL',
  'SELAH COVENANT CHRISTIAN SCHOOL',
  'SELF SCHOOL',
  'SELKIRK SCHOOL',
  'SELWYN COLLEGE PREPARATORY SCHOOL',
  'SEMINARY BAPTIST CHURCH KINDERGARTEN',
  'SEMINOLE MENNONITE SCHOOL',
  'SEMITA SCHOOL',
  'SENECA ACADEMY',
  'SENECA BIBLE BAPTIST ACADEMY',
  'SENECA CENTERS OLIVERA SCHOOL',
  'SENECA CENTERS PATHFINDER ACADEMY',
  'SENECA FAMILY OF AGENCIE- CATALYST ACADEMY',
  'SENECA FAMILY OF AGENCIES JAMES BALDWIN ACADEMY',
  'SENECA TRAIL CHRISTIAN ACADEMY',
  'SEPHARDIC BET YAAKOV',
  'SEPTEMBER SCHOOL',
  'SEQUATCHIE VALLEY PREPARATORY ACADEMY',
  'SEQUEL THREE SPRINGS SCHOOL',
  'SEQUEL TSI IDAHO',
  'SEQUOIA CHRISTIAN ACADEMY',
  'SEQUOIA CHRISTIAN SCHOOL',
  'SEQUOIA PRESCHOOL KINDERGARTEN',
  'SEQUOYAH',
  'SEQUOYAH SCHOOL',
  'SERENDIPITY CENTER',
  'SERENDIPITY SCHOOL',
  'SERRA CATHOLIC HIGH SCHOOL',
  'SERRA CATHOLIC SCHOOL',
  'SERRANO HEIGHTS ACADEMY',
  'SERVIAM GIRLS ACADEMY',
  'SERVICE CHRISTIAN SCHOOL',
  'SERVITE HIGH SCHOOL',
  'SETON ACADEMY',
  'SETON ACADEMY WEST',
  'SETON CATHOLIC CENTRAL',
  'SETON CATHOLIC CENTRAL JR/SR HIGH',
  'SETON CATHOLIC ELEMENTARY SCHOOL',
  'SETON CATHOLIC ELEMENTARY SCHOOL PEOSTA CENTER',
  'SETON CATHOLIC HIGH SCHOOL',
  'SETON CATHOLIC PREPARATORY HIGH SCHOOL',
  'SETON CATHOLIC SCHOOL',
  'SETON GRADE SCHOOL',
  'SETON HALL PREPARATORY SCHOOL',
  'SETON MONTESSORI SCHOOL',
  'SETON REGIONAL CATHOLIC SCHOOL',
  'SETON SCHOOL',
  'SETON-LA SALLE HIGH SCHOOL',
  'SEVEN ARROWS ELEMENTARY',
  'SEVEN BRIDGES SCHOOL',
  'SEVEN HILLS AT GROTON',
  'SEVEN HILLS SCHOOL',
  'SEVEN PEAKS SCHOOL',
  'SEVEN SORROWS BUM SCHOOL',
  'SEWICKLEY ACADEMY',
  'SEYMOUR COMMUNITY CHRISTIAN SCHOOL',
  'SGT HENRY JOHNSON YOUTH LEADERSHIP ACADEMY',
  "SHA'AREI BINA TORAH ACADEMY FOR GIRLS",
  'SHAARE SHAMAYIM PRESCHOOL AND KINDERGARTEN',
  'SHAAREI ARAZIM OF MONSEY',
  'SHAAREI TORAH OF ROCKLAND',
  'SHABACH! CHRISTIAN ACADEMY',
  'SHADE MOUNTAIN CHRISTIAN SCHOOL',
  'SHADE MOUNTAIN SCHOOL',
  'SHADE MT MENNONITE',
  'SHADOW RIDGE',
  'SHADY ACRE SCHOOL',
  'SHADY ACRES AMISH SCHOOL',
  'SHADY ACRES SCHOOL',
  'SHADY GLADE SCHOOL',
  'SHADY GLEN SCHOOL',
  'SHADY GROVE C/O DENNIS KLINE',
  'SHADY GROVE CHRISTIAN SCHOOL',
  'SHADY GROVE MENNONITE SCHOOL',
  'SHADY GROVE PAROCHIAL SCHOOL',
  'SHADY GROVE SCHOOL',
  'SHADY GROVE SCHOOL/CHRISTIAN STOLTZFUS',
  'SHADY HILL SCHOOL',
  'SHADY HOLLOW C/O JOHN BEECHY',
  'SHADY LANE',
  'SHADY LANE AMISH SCHOOL',
  'SHADY LANE SCHOOL',
  'SHADY LAWN AMISH PAROCHIAL SCHOOL',
  'SHADY MAPLE AMISH PAROCHIAL',
  'SHADY MAPLE SCHOOL',
  'SHADY MAPLES SCHOOL',
  'SHADY MEADOW SCHOOL',
  'SHADY NOOK AMISH SCHOOL',
  'SHADY OAKS SCHOOL',
  'SHADY POINT SDA SCHOOL',
  'SHADY POND SCHOOL',
  'SHADY RUN AMISH SCHOOL',
  'SHADY SIDE ACADEMY',
  'SHADY SIDE ACADEMY JUNIOR SCHOOL',
  'SHADY SIDE ACADEMY MIDDLE SCHOOL',
  'SHAFER CENTER FOR EARLY INTERVENTION- THE',
  'SHAJARA TAYYIBA SCHOOL',
  'SHAKER ROAD SCHOOL',
  'SHAKOPEE AREA CATHOLIC SCHOOL',
  'SHALHEVET HIGH SCHOOL',
  'SHALLOWFORD PRESBYTERIAN SCHOOL',
  'SHALOM CHRISTIAN ACADEMY',
  'SHALOM MENNONITE SCHOOL',
  'SHALOM SCHOOL',
  'SHALOM TORAH CENTER',
  'SHALVA HIGH SCHOOL',
  'SHANDON UNITED METHODIST PRESCHOOL & KINDERGARTEN',
  'SHANNON FOREST CHRISTIAN SCHOOL',
  'SHANTI KIDS PRESCHOOL',
  'SHARON ACADEMY',
  'SHARON SCHOOL OF EXCELLENCE',
  'SHARON TEMPLE ADVENTIST SCHOOL',
  "SHARON'S CENTRE",
  'SHARONVILLE UMC WEEKDAY NURSERY SCHOOL',
  'SHARP ACADEMY',
  'SHARP DEAN SCHOOL OF CONTINUING STUDIES INC',
  'SHARP RUN',
  'SHATTUCK-ST. MARYS SCHOOL',
  'SHAWE MEMORIAL JR-SR HIGH SCHOOL',
  'SHAWNEE MISSION CHRISTIAN SCHOOL',
  'SHEARIM TORAH HIGH SCHOOL FOR GIRLS',
  'SHEBOYGAN AREA LUTHERAN HIGH SCHOOL',
  'SHEBOYGAN COUNTY CHRISTIAN HIGH SCHOOL',
  'SHEBOYGAN COUNTY CHRISTIAN SCHOOL',
  'SHEETS MEMORIAL CHRISTIAN SCHOOL',
  'SHEFA SCHOOL',
  'SHEKINAH CHRISTIAN SCHOOL',
  'SHEKINAH GLORY CHRISTIAN ACADEMY',
  'SHELBY COUNTY CATHOLIC SCHOOL',
  'SHELDON ACADEMY OF VERMONT ACHIEVEMENT CENTER',
  'SHELDON CHRISTIAN SCHOOL',
  'SHELIA DANIELS CHRISTIAN ACADEMY',
  'SHELTERING TREE RANCH',
  'SHELTERWOOD ACADEMY',
  'SHELTON ACADEMY',
  'SHELTON SCHOOL',
  'SHELTON VALLEY CHRISTIAN SCHOOL',
  'SHEMA KOLAINU-HEAR OUR VOICES',
  'SHENANDOAH CHRISTIAN SCHOOL',
  'SHENANDOAH RANCH ACADEMY',
  'SHENANDOAH VALLEY ACADEMY',
  'SHENANDOAH VALLEY ADVENTIST ELEMENTARY SCHOOL',
  'SHENKER ACADEMY',
  'SHEPARD HIGH SCHOOL',
  'SHEPHERD ACADEMY',
  'SHEPHERD CENTER PRE SCHOOL',
  'SHEPHERD CHRISTIAN SCHOOL',
  'SHEPHERD LUTHERAN',
  'SHEPHERD OF GOD CHRISTAIN ACADEMY CORP',
  'SHEPHERD OF THE COAST LUTHERAN SCHOOL',
  'SHEPHERD OF THE DESERT LUTHERAN SCHOOL',
  'SHEPHERD OF THE HILLS CHRISTIAN SCHOOL',
  'SHEPHERD OF THE HILLS LUTHERAN SCHOOL',
  'SHEPHERD OF THE LAKES LUTHERAN SCHOOL',
  'SHEPHERD OF THE VALLEY LUTHERAN SCHOOL',
  'SHEPHERD OF THE VALLEY SCHOOL',
  'SHEPHERD OF THE WOODS LUTHERAN SCHOOL - II',
  'SHEPHERD SCHOOL',
  "SHEPHERD'S CHRISTIAN ACADEMY",
  "SHEPHERD'S HEART CHRISTIAN SCHOOL",
  "SHEPHERD'S HILL ACADEMY",
  'SHEPHERDS RIVER MENNONITE SCHOOL',
  'SHEPLER RIDGE SCHOOL',
  "SHERI TORAH V'YOEL MOSHE",
  'SHERIDAN ACADEMY',
  'SHERIDAN HILLS CHRISTIAN SCHOOL',
  'SHERIDAN ROAD CHRISTIAN SCHOOL',
  'SHERIDAN SCHOOL',
  'SHERMAN PARK LUTHERAN SCHOOL / PRESCHOOL',
  'SHERMAN VIEW SCHOOL',
  'SHERWOOD CENTER FOR THE EXCEPTINOAL CHILD',
  'SHERWOOD CHRISTIAN ACADEMY',
  'SHERWOOD FOREST MONTESSORI SCHOOL',
  'SHEVACH HIGH SCHOOL',
  'SHIELD OF FAITH CHRISTIAN SCHOOL',
  'SHILOH BAPTIST EARLY LEARNING ACADEMY',
  'SHILOH CHRISTIAN ACADEMY',
  'SHILOH CHRISTIAN SCHOOL',
  'SHILOH HOLINESS CHRISTIAN SCHOOL',
  'SHILOH INSTITUTE OF LEARNING',
  'SHILOH SDA SCHOOL',
  'SHINER CATHOLIC SCHOOL',
  'SHINING LIGHT CHILDCARE DEVELOPMENT CENTER',
  'SHINING STAR ACADEMY OF THE ARTS',
  'SHINING STAR CHRISTIAN SCHOOL-FAIRVIEW',
  'SHINING STAR CHRISTIAN SCHOOL-WEST CAPITAL',
  'SHINING STARS ACADEMY',
  'SHINING STARS PRESCHOOL & KINDERGARTEN',
  'SHIPSHE MEADOWS SCHOOL',
  'SHIR AMI EARLY LEARNING CENTER',
  'SHIRLEY RUN AMISH SCHOOL',
  'SHOAL CREEK ADVENTIST SCHOOL',
  'SHOALS CHRISTIAN SCHOOL',
  "SHODAIR CHILDREN'S HOSPITAL",
  'SHORE CHRISTIAN ACADEMY',
  'SHORE COUNTRY DAY SCHOOL',
  'SHORECREST PREPARATORY SCHOOL',
  'SHOREHAVEN SCHOOL',
  'SHORELAND LUTHERAN HIGH SCHOOL',
  'SHORELINE CHRISTIAN SCHOOL OF FLORENCE',
  'SHOREWOOD CHRISTIAN SCHOOL',
  'SHORTRIDGE ACADEMY',
  'SHOW ME CHRISTIAN YOUTH HOME',
  'SHOW-ME CHRISTIAN SCHOOL',
  'SHOW-ME CHRISTIAN YOUTH HOME',
  'SHOWERS OF BLESSINGS CHRISTIAN ACADEMY',
  'SHREWSBURY CHILDRENS CENTER',
  'SHREWSBURY CHRISTIAN ACADEMY',
  'SHREWSBURY MONTESSORI SCHOOL',
  'SHRINE ACADEMY',
  'SHRINE CATHOLIC GRADE SCHOOL',
  'SHRINE CATHOLIC HIGH SCHOOL',
  'SHRINE OF ST ANNE SCHOOL',
  'SHULAMITH SCHOOL FOR GIRLS',
  'SHULAMITH SCHOOL FOR GIRLS OF BROOKLYN',
  'SHULTS-LEWIS SCHOOL',
  'SHUMWAY ACADEMY',
  'SHUTT VALLEY',
  'SIDWELL FRIENDS SCHOOL',
  'SIENA ACADEMY',
  'SIENNA LUTHERAN ACADEMY',
  'SIERRA ACADEMY OF SCOTTSDALE',
  'SIERRA ELEMENTARY & HIGH SCHOOL',
  'SIERRA LUTHERAN HIGH SCHOOL',
  'SIERRA MADRE COMMUNITY NURSERY SCHOOL',
  'SIERRA NORWOOD CALVARY CHILD DEVELOPMENT CENTER',
  'SIERRA SCHOOL',
  'SIERRA SCHOOL OF SOLANO COUNTY',
  'SIERRA VIEW JR ACADEMY',
  'SIERRA WALDORF SCHOOL',
  'SIGNAL MOUNTAIN CHRISTIAN SCHOOL',
  'SIKORA MONTESSORI SCHOOL',
  'SILICON VALLEY ACADEMY',
  'SILOAH LUTHERAN SCHOOL',
  'SILVEIRA KINDERGARTEN & NURSERY SCHOOL',
  'SILVER BOW MONTESSORI SCHOOL',
  'SILVER HILL SCHOOL',
  'SILVER LAKE MENNONITE SCHOOL',
  'SILVER OAK ACADEMY',
  'SILVER SPRUCE MONTESSORI SCHOOL',
  'SILVER TEN AMISH SCHOOL',
  'SILVER VALLEY CHRISTIAN ACADEMY',
  'SILVERCREEK ADVENTIST SCHOOL',
  'SILVERDALE BAPTIST ACADEMY',
  'SILVERLINE MONTESSORI - SILVERLAKE CAMPUS',
  'SILVERLINE MONTESSORI SCHOOL - SHADOW CREEK',
  'SILVERSTEIN HEBREW ACADEMY',
  'SILVERWOOD SCHOOL',
  'SILVIAS SCHOOL',
  'SIMI VALLEY ADVENTIST SCHOOL',
  'SIMI VALLEY MONTESSORI SCHOOL',
  'SIMONTON CHRISTIAN ACADEMY',
  'SIMPSON COUNTY ACADEMY',
  'SINAI ACADEMIC CENTER',
  'SINAI CHRISTIAN ACADEMY',
  'SINAI HIGH SCHOOL AT TABC',
  "SINAI SCHOOL AT MA'AYANOT",
  'SINAI SCHOOL AT RYNJ',
  'SINCLAIR CHRISTIAN ACADEMY',
  'SINGING HILLS SCHOOL',
  'SINGING MEADOW SCHOOL',
  'SINGING STONES SCHOOL',
  'SIOUX CENTER CHRISTIAN SCHOOL',
  'SIOUX FALLS ADVENTIST SCHOOL',
  'SIOUX FALLS CHRISTIAN SCHOOLS',
  'SIOUX FALLS LUTHERAN SCHOOL',
  'SISTER CLARA MUHAMMAD SCHOOL',
  'SISTER THEA BOWMAN',
  'SISTER THEA BOWMAN CATHOLIC ACADEMY',
  'SISTER THEA BOWMAN CATHOLIC SCHOOL',
  'SISTERS ACADEMY OF BALTIMORE',
  'SISTERS CHRISTIAN ACADEMY',
  'SIX RIVERS MONTESSORI',
  'SKAGIT ADVENTIST ACADEMY',
  'SKEELS CHRISTIAN SCHOOL',
  'SKINNER ELEMENTARY MONTESSORI SCHOOL',
  'SKY VALLEY ADVENTIST SCHOOL',
  'SKYCREST CHRISTIAN SCHOOL',
  'SKYLAR HADDEN SCHOOL',
  'SKYUKA HALL',
  'SKYWARD ACADEMY',
  'SL JONES CHRISTIAN ACADEMY',
  'SLANESVILLE CHRISTIAN SCHOOL',
  'SLATER MONTESSORI ACADEMY',
  'SLAUSON LEARNING CENTER',
  'SLAVIC CHRISTIAN ACADEMY',
  'SLIDELL CHRISTIAN ACADEMY',
  'SMALL STEPS NURTURING CENTER',
  'SMALL STEPS NURTURING CENTER - JENSEN',
  'SMALL WONDERS AT UPMC',
  'SMALL WORLD CHILD CARE',
  'SMALL WORLD CHRISTIAN SCHOOL',
  'SMALL WORLD DAYCARE CENTER',
  'SMALL WORLD DISCOVERY CENTER',
  'SMALL WORLD LEARNING CENTER',
  'SMALL WORLD MONTESSORI',
  'SMALL WORLD MONTESSORI METHOD SCHOOL II',
  'SMALL WORLD MONTESSORI SCHOOL',
  'SMALLER SCHOLARS MONTESSORI',
  'SMART HORIZONS CAREER ONLINE HIGH SCHOOL',
  'SMART SCHOOLS',
  'SMART SCHOOLS LLC',
  'SMART START PRESCHOOL',
  'SMART STARTS AT DADELAND',
  'SMART-START MONTESSORI ACADEMY',
  'SMARTY PANTS PRESCHOOL & KINDERGARTEN',
  'SMCA',
  'SMILES AROUND US ACADEMY',
  'SMILING RIDGE SCHOOL @ ISAAC S STOLTZERS',
  'SMITH COLLEGE CAMPUS SCHOOL',
  'SMITH HOLLOW SCHOOL ATT JACOB STOLTZPUS',
  'SMITH MOUNTAIN LAKE CHRISTIAN ACADEMY',
  'SMITH PREP INC',
  'SMITHFIELD AVE NURSERY SCHOOL & KINDERGARTEN',
  "SMITHSON'S CHRISTIAN SCHOOL",
  'SMITHSONIAN EARLY ENRICHMENT CENTER',
  'SMITHVILLE MONTESSORI ACADEMY II',
  'SMK Fomra Institute of Technology',
  "SMOKY ROW CHILDREN'S CENTER",
  'SMYRNA CHRISTIAN SCHOOL',
  'SMYRNA MONTESSORI SCHOOL',
  'SNAKE HILL AMISH SCHOOL',
  'SNAKE RIVER MONTESSORI SCHOOL',
  'SNAKE SPRING VALLEY CHRISTIAN ACADEMY DBA FOUNDATI',
  'SNOW REMOVAL',
  'SNYDER CHRISTIAN SCHOOL',
  'SO. ADAMS AMISH SCHOOL',
  'SOAR LEARNING CENTER',
  'SOARING EAGLES CENTER FOR AUTISM',
  'SOARING EAGLES CHRISTIAN ACADEMY',
  'SOARING HEIGHTS SCHOOL',
  'SOCIAL CIRCLE CHRISTIAN SCHOOL AT FBC',
  'SOCIAL SOUTH CENTRAL CMNTY SVC',
  'SOCRATES PREPARATORY SCHOOL',
  'SOFT RUN SCHOOL',
  'SOILLE SAN DIEGO HEBREW BAY SCHOOL',
  'SOJOURN ACADEMY',
  'SOL ACADEMY, INC',
  'SOLA FIDE LUTHERAN SCHOOL',
  'SOLEBURY MONTESSORI SCHOOL',
  'SOLEBURY SCHOOL',
  'SOLID FOUNDATION CHRISTIAN ACADEMY',
  'SOLID FOUNDATION PREPARATORY ACADEMY',
  'SOLID ROCK BAPTIST CHURCH',
  'SOLID ROCK CHRISTIAN ACADEMY',
  'SOLID ROCK CHRISTIAN SCHOOL',
  'SOLID ROCK COMMUNITY SCHOOL',
  'SOLIMAR ACADEMY',
  'SOLOMON LUTHERAN SCHOOL',
  'SOLOMON SCHECHTER ACADEMY',
  'SOLOMON SCHECHTER DAY SCHOOL',
  'SOLOMON SCHECHTER DAY SCHOOL OF BERGEN CO',
  'SOLOMON SCHECHTER DAY SCHOOL OF GREATER MONMOUTH C',
  'SOLOMON SCHECHTER DAY SCHOOL OF LAS VEGAS',
  'SOLOMON SCHECHTER SCHOOL OF MANHATTAN',
  'SOLOMON SCHECHTER SCHOOL OF QUEENS',
  'SOLUTIONS ACADEMY',
  'SOMERSET CHRISTIAN SCHOOL',
  'SOMERSET EDUCATIONAL SERVICES',
  'SOMERSET HILLS LEARNING INSTITUTE',
  'SOMERSET HILLS MONTESSORI SCHOOL',
  'SONIA SHANKMAN ORTHOGENIC SCHOOL',
  'SONLIGHT HERITAGE ACADEMY',
  'SONLIGHT RIVER BRETHREN SCHOOL',
  'SONNENBERG SCHOOL',
  'SONOMA COUNTRY DAY SCHOOL',
  'SONRISE ACADEMY',
  'SONRISE CHRISTIAN SCHOOL',
  'SONSHINE CHRISTIAN ACADEMY',
  'SONSHINE CHRISTIAN ELEMENTARY',
  'SONSHINE EXPRESS PRESCHOOL',
  'SONSHINE FACTORY PRESCHOOL & KINDERGARTEN',
  'SONSHINE LEARNING CENTER',
  'SONSHINE PRESCHOOL & DAYCARE',
  'SONSHINE PRESCHOOL & KINDERGARTEN',
  'SONSHINE SCHOOL',
  'SONSHIP CHRISTIAN SCHOOL',
  'SOPHIA ACADEMY',
  'SOPHIA CHRISTIAN ACADEMY',
  'SOTO ACADEMY',
  'SOUDERTON VANTAGE ACADEMY',
  'SOULARD SCHOOL',
  'SOULS HARBOR CHRISTIAN ACADEMY',
  'SOULS HARBOR LIGHTHOUSE CHRISTIAN ACADEMY',
  'SOUND DOCTRINE CHRISTIAN ACADEMY',
  'SOUND OF MUSIC PRESCHOOL',
  'SOUTH AIKEN BAPTIST CHRISTIAN SCHOOL',
  'SOUTH AIKEN PRESBYTERIAN KINDERGARTEN',
  'SOUTH ARKANSAS CHRISTIAN SCHOOL',
  'SOUTH BAY CHRISTIAN ACADEMY',
  'SOUTH BAY JR ACADEMY OF SDA',
  'SOUTH BAY JUNIOR ACADEMY',
  'SOUTH BEECH SCHOOL',
  'SOUTH BEND JUNIOR ACADEMY',
  'SOUTH BOGARD AMISH SCHOOL',
  'SOUTH BOONE',
  'SOUTH BOSTON CATHOLIC ACADEMY',
  'SOUTH BUNKER HILL SCHOOL',
  'SOUTH CAMPUS',
  'SOUTH CENTRAL COMMUNITY SERVICES - SOUTH SHORE',
  'SOUTH CENTRAL SCHOOL MAIN CAMPUS',
  'SOUTH CHARLOTTE BAPTIST ACADEMY',
  'SOUTH CHOCTAW ACADEMY',
  'SOUTH CHRISTIAN HIGH SCHOOL',
  'SOUTH CITY CATHOLIC ACADEMY LEARNING CENTER',
  "SOUTH COAST METRO CHILDREN'S VILLAGE",
  'SOUTH COUNTY MONTESSORI SCHOOL',
  'SOUTH COVE MENNONITE SCHOOL',
  'SOUTH DUBLIN C/O KRISTINA MILLER',
  'SOUTH EAST AMISH SCHOOL',
  'SOUTH EAST CENTER',
  'SOUTH FLORIDA ACADEMY OF LEARNING',
  'SOUTH FLORIDA LEARNERS',
  'SOUTH FLORIDA MONTESSORI ACADEMY',
  'SOUTH FLORIDA SCHOOL OF EXCELLENCE',
  'SOUTH HAVEN CHRISTIAN SCHOOL',
  'SOUTH HILLS ACADEMY',
  'SOUTH HILLS CHILD DEVELOPMENT CENTER',
  'SOUTH JERSEY CHRISTIAN ACADEMY',
  'SOUTH JERSEY SUDBURY SCHOOL',
  'SOUTH KENT SCHOOL',
  'SOUTH LAKE MONTESSORI SCHOOL',
  'SOUTH LANCASTER ACADEMY',
  'SOUTH LEEPER C/O JOHN PETERSHEIM',
  'SOUTH LIBERTY BAPTIST ACADEMY',
  'SOUTH LOOP MONTESSORI SCHOOL',
  'SOUTH MCCOMB BAPTIST KINDERGARTEN',
  'SOUTH MERRIMACK CHRISTIAN ACADEMY',
  'SOUTH MILLBORNE',
  'SOUTH MILLWOOD PAROCHIAL SCHOOL',
  'SOUTH MOUNTAIN PAROCHIAL SCHOOL',
  'SOUTH MOUNTAIN VIEW SCHOOL',
  'SOUTH MT HOPE SCHOOL O/O JOE BOWMAN JR',
  'SOUTH OLIVE CHRISTIAN SCHOOL',
  'SOUTH ORANGE COUNTRY DAY SCHOOL',
  'SOUTH ORLANDO CHRISTIAN ACADEMY',
  'SOUTH PARK CHRISTIAN ACADEMY',
  'SOUTH PENINSULA HEBREW DAY SCHOOL',
  'SOUTH POINTE CHRISTIAN SCHOOL',
  'SOUTH PRAIRIE SCHOOL',
  'SOUTH RIDGE CHRISTIAN ACA.',
  'SOUTH SAN JOAQUIN EDUCATION CENTER',
  'SOUTH SHORE CONSERVATORY OF MUSIC PRESCHOOL',
  'SOUTH SHORE COUNTRY DAY SCHOOL',
  'SOUTH SHORE MONTESSORI',
  'SOUTH SHORE MONTESSORI SCHOOL AT WATERSET',
  'SOUTH SHORE SDA SCHOOL',
  'SOUTH SIDE BAPTIST SCHOOL',
  'SOUTH SIDE CHRISTIAN SCHOOL',
  'SOUTH SUBURBAN CHRISTIAN SCHOOL',
  'SOUTH SUBURBAN MONTESSORI',
  'SOUTH TEXAS CHRISTIAN ACADEMY',
  'SOUTH VIEW',
  'SOUTH WALTON MONTESSORI ACADEMY',
  'SOUTHAMPTON ACADEMY',
  'SOUTHAMPTON MONTESSORI SCHOOL',
  'SOUTHCREST CHRISTIAN SCHOOL',
  'SOUTHEAST CAMPUS SCHOOL',
  'SOUTHEAST CHRISTIAN SCHOOL',
  'SOUTHEAST MISSOURI CHRISTIAN ACADEMY',
  'SOUTHEASTERN CHRISTIAN ACADEMY',
  'SOUTHEASTERN HIGH SCHOOL',
  'SOUTHEASTERN MILITARY ACADEMY',
  'SOUTHERN ACADEMY',
  'SOUTHERN CALIFORNIA MONTESSORI SCHOOL',
  'SOUTHERN CONNECTICUT HEBREW ACADEMY',
  'SOUTHERN HILLS MENNONITE SCHOOL',
  'SOUTHERN MAGNOLIA MONTESSORI SCHOOL',
  'SOUTHERN MEADOW SCHOOL',
  'SOUTHERN NH MONTESSORI ACADEMY',
  'SOUTHERN TIER CATHOLIC SCHOOL',
  'SOUTHERN UTE INDIAN MONTESSORI ACADEMY',
  'SOUTHFIELD CHRISTIAN SCHOOL',
  'SOUTHFIELD SCHOOL',
  'SOUTHGATE MONTESSORI PRESCHOOL',
  'SOUTHINGTON CATHOLIC SCHOOL',
  'SOUTHLAKE MONTESSORI',
  'SOUTHLAND ACADEMY',
  'SOUTHLAND CHRISTIAN ACADEMY',
  'SOUTHLAND CHRISTIAN SCHOOL',
  'SOUTHLANDS CHRISTIAN SCHOOLS',
  'SOUTHMINSTER DAY SCHOOL',
  'SOUTHPOINT ACADEMY',
  'SOUTHPORT CHRISTIAN SCHOOL',
  'SOUTHPORT PRESBYTERIAN CHRISTIAN SCHOOL',
  'SOUTHSHIRE COMMUNITY SCHOOL',
  'SOUTHSIDE BAPTIST CHURCH & CHRISTIAN SCHOOL',
  'SOUTHSIDE CHRISTIAN ACADEMY',
  'SOUTHSIDE CHRISTIAN SCHOOL',
  'SOUTHTOWNS CATHOLIC SCHOOL',
  'SOUTHWELL AMISH SCHOOL',
  'SOUTHWEST ATLANTA CHRISTIAN ACADEMY',
  'SOUTHWEST CHICAGO CHRISTIAN SCHOOL-OAK LAWN',
  'SOUTHWEST CHICAGO CHRISTIAN SCHOOL-TINLEY PARK',
  'SOUTHWEST CHICAGO CHRISTIAN SCHOOLS',
  'SOUTHWEST CHRISTIAN ACADEMY',
  'SOUTHWEST CHRISTIAN SCHOOL-PREP CAMPUS',
  'SOUTHWEST COMMUNITY CHRISTIAN ACADEMY',
  'SOUTHWEST COVENANT SCHOOLS',
  'SOUTHWEST FLORIDA CHRISTIAN ACADEMY',
  'SOUTHWEST GEORGIA ACADEMY',
  'SOUTHWEST GEORGIA INSTITUTE OF EDUCATION',
  'SOUTHWEST INDIAN SCHOOLS/LIVING WORD ACADEMY',
  'SOUTHWEST MN CHRISTIAN HIGH SCHOOL',
  'SOUTHWEST MONTESSORI SCHOOL',
  'SOUTHWEST SUBURBAN MONTESSORI',
  'SOUTHWESTERN ACADEMY',
  'SOUTHWESTERN CHRISTIAN SCHOOL',
  'SOUTHWESTERN YOUTH SERVICES',
  'SOUTHWIND MONTESSORI',
  'SOUTHWOOD SCHOOL',
  'SOWELA Technical Community College',
  'SPA CHRISTIAN SCHOOL',
  'SPACE CITY PREPARATORY',
  'SPACE COAST CHRISTIAN ACADEMY',
  'SPACE COAST EARLY INTERVENTION CENTER',
  'SPANISH HOUSE ELEMENTARY SCHOOL',
  'SPANISH RIVER CHRISTIAN SCHOOL',
  'SPANISH SCHOOLHOUSE',
  'SPANISH WITH SARAH',
  'SPARROW ACADEMY',
  'SPARTA ACADEMY',
  'SPARTANBURG CHRISTIAN ACADEMY',
  'SPARTANBURG DAY SCHOOL',
  'SPAULDING CATHOLIC SCHOOL',
  'SPEARFISH CLASSICAL CHRISTIAN SCHOOL',
  'SPECIAL CARE- INC.',
  'SPECIAL KIDS ACADEMY',
  'SPECKTOWN SCHOOL',
  'SPECTRUM ACADEMY',
  'SPECTRUM CENTER',
  'SPECTRUM CENTER - SOLANO',
  'SPECTRUM CENTER - TARA HILLS',
  'SPECTRUM CENTER SCHOOLS-RANDOL CAMPUS',
  'SPECTRUM CENTER- ANTIOCH CAMPUS',
  'SPECTRUM CENTER- LONG BEACH ELEMENTARY',
  'SPECTRUM CENTER- LONG BEACH MIDDLE MARKET STREET',
  'SPECTRUM CENTER-VALLEY CAMPUS',
  'SPECTRUM SCHOOL',
  'SPECTRUM SCHOOLS SAN GORGONIO',
  'SPECTRUMCENTER SCHOOLS DOWNEY',
  'SPEECH & LANGUAGE DEVELOPMENT CENTER',
  'SPEIRO ACADEMY',
  'SPELL WELL MONTESSORI SCHOOL',
  'SPELLMAN PREP SCHOOL',
  'SPENCERVILLE ADVENTIST ACADEMY',
  'SPHINX ACADEMY',
  'SPIRIT ACADEMY',
  'SPIRIT OF HOPE MONTESSORI SCHOOL',
  'SPIRIT OF LIFE CHRISTIAN ACADEMY',
  'SPIRITUS SANCTUS ACADEMY',
  'SPOKANE CHRISTIAN ACADEMY',
  'SPOKANE FALLS MONTESSORI SCHOOL',
  'SPOKANE MONTESSORI SCHOOL NORTH',
  'SPOKANE VALLEY ADVENTIST SCHOOL',
  'SPONDEO PRESCHOOL',
  'SPRING ACADEMY',
  'SPRING BANK SCHOOL',
  'SPRING BAPTIST ACADEMY',
  'SPRING BROOK',
  'SPRING CREEK ACADEMY',
  'SPRING CREEK SCHOOL',
  'SPRING GARDEN PAROCHIAL SCHOOL',
  'SPRING GARDEN WALDORF SCHOOL',
  'SPRING HILL',
  'SPRING HILL CHRISTIAN ACADEMY',
  'SPRING HILL PAROCHIAL SCHOOL',
  'SPRING HILL SCHOOL',
  'SPRING HILL-ADVANCED ELEMENTARY SCHOOL OF SANTA CR',
  'SPRING HOLLOW AMISH SCHOOL',
  'SPRING HOUSE EARLY LEARNING INC',
  'SPRING MEADOW SCHOOL',
  'SPRING MEADOW SCHOOL C/O RHODA YODER',
  'SPRING MOUNTAIN CHRISTIAN ACADEMY',
  'SPRING RIDGE ACADEMY',
  'SPRING RUN',
  'SPRING RUN SCHOOL',
  'SPRING STREET INTERNATIONAL SCHOOL',
  'SPRING VALE ACADEMY',
  'SPRING VALLEY (EAST)',
  'SPRING VALLEY ACADEMY',
  'SPRING VALLEY CHRISTIAN ACADEMY',
  'SPRING VALLEY PAROCHIAL',
  'SPRING VALLEY SCHOOL',
  'SPRING VIEW AMISH SCHOOL',
  'SPRING WOOD C/O KRISTINA MILLER',
  'SPRING WOODS WEEKDAY MINISTRIES',
  'SPRINGBOARD ACADEMICS',
  'SPRINGBOK ACADEMY',
  'SPRINGDALE',
  'SPRINGDALE EDUCATION CENTER',
  'SPRINGFIELD ACADEMY',
  'SPRINGFIELD CATHOLIC HIGH SCHOOL',
  'SPRINGFIELD CENTER SCHOOL',
  'SPRINGFIELD CHRISTIAN ACADEMY',
  'SPRINGFIELD CHRISTIAN KINDERGARTEN AND PRESCHOOL',
  'SPRINGFIELD CHRISTIAN SCHOOL',
  'SPRINGFIELD COMMONWEALTH ACADEMY',
  'SPRINGFIELD LUTHERAN SCHOOL',
  'SPRINGFIELD MONTESSORI SCHOOL',
  'SPRINGFIELD SEVENTH-DAY ADVENTIST JUNIOR ACADEMY',
  'SPRINGHEAD AMISH SCHOOL/SAM EBERSOL, CHAIRMAN',
  'SPRINGHILL MONTESSORI SCHOOL',
  'SPRINGHILL SCHOOL',
  'SPRINGS ADVENTIST ACADEMY',
  'SPRINGS BAPTIST ACADEMY',
  'SPRINGS EAST SCHOOL',
  'SPRINGSIDE CHESTNUT HILL ACADEMY',
  'SPRINGSTONE',
  'SPRINGSTONE LAKES MONTESSORI SCHOOL',
  'SPRINGTIME SCHOOL',
  'SPRINGTON LAKE MONTESSORI SCHOOL',
  'SPRINGVIEW ACADEMY,INC',
  'SPRINGVILLE CHRISTIAN SCHOOL',
  'SPRINGVILLE MENNONITE SCHOOL',
  'SPRINGVILLE PREPARATORY ACADEMY',
  'SPRINGWOOD SCHOOL',
  'SPROUTING TO SUCCESS',
  'SPRUCE PINE MONTESSORI SCHOOL',
  'SPRUCE ST SCHOOL',
  'SPRUCETREE MONTESSORI SCHOOL',
  'SPURWINK SCHOOL-CORNVILLE',
  'SQUAW VALLEY ACADEMY',
  'SRH University of Applied Sciences',
  'SRM Institute Of Science & Technology ( Deemed University)',
  'SS AGATHA - ALOYSIUS SCHOOL',
  'SS ANDREW-THOMAS SCHOOL',
  'SS CYRIL & METHODIUS SCHOOL',
  'SS FELICITAS & PERPETUA SCHOOL',
  'SS FRANCIS & CLARE CATHOLIC SCHOOL',
  'SS JOACHIM & ANNE SCHOOL',
  'SS JOHN & PAUL SCHOOL',
  'SS MARY AND MATHIAS CATHOLIC SCHOOL',
  'SS PETER & PAUL CATHOLIC SCHOOL',
  'SS PETER & PAUL ELEMENTARY SCHOOL',
  'SS PETER & PAUL HIGH SCHOOL',
  'SS PETER & PAUL SCHOOL',
  'SS PETER AND PAUL SCHOOL',
  'SS PHILIP & JAMES ELEMENTARY SCHOOL',
  'SS PHILIP & JAMES SCHOOL',
  'SS ROBERT & WILLIAM CATHOLIC SCHOOL',
  'SS SIMON AND JUDE CATHEDRAL SCHOOL',
  'SS. CYRIL & METHODIUS CATHOLIC SCHOOL',
  'ST',
  "ST ABRAHAM'S CLASSICAL CHRISTIAN ACADEMY",
  'ST ADALBERT CATHOLIC SCHOOL',
  'ST ADALBERT ELEMENTARY SCHOOL',
  'ST ADALBERT SCHOOL',
  'ST ADELAIDE ACADEMY',
  'ST AEDAN/ST BRENDAN SCHOOL',
  'ST AGATHA ACADEMY',
  'ST AGATHA CATHOLIC SCHOOL',
  'ST AGATHA ELEMENTARY SCHOOL',
  'ST AGATHA SCHOOL',
  'ST AGNES ACADEMIC SCHOOL',
  'ST AGNES ACADEMY',
  'ST AGNES ACADEMY SCHOOL',
  'ST AGNES ACADEMY-ST. DOMINIC SCHOOL',
  'ST AGNES CATHEDRAL SCHOOL',
  'ST AGNES CATHOLIC ELEMENTARY SCHOOL',
  'ST AGNES CATHOLIC SCHOOL',
  'ST AGNES ELEMENTARY SCHOOL',
  'ST AGNES OF BOHEMIA SCHOOL',
  'ST AGNES SCHOOL',
  'ST AGNES SCHOOLS',
  'ST AIDAN SCHOOL',
  'ST AIDAN SCHOOL-EAST CAMPUS',
  'ST ALBAN ROE SCHOOL',
  'ST ALBANS CHRISTIAN ACADEMY',
  'ST ALBANS COUNTRY DAY SCHOOL',
  'ST ALBANS EPISCOPAL DAY SCHOOL',
  'ST ALBANS SCHOOL',
  'ST ALBERT CATHOLIC SCHOOLS',
  'ST ALBERT THE GREAT CATHOLIC SCHOOL',
  'ST ALBERT THE GREAT ELEMENTARY SCHOOL',
  'ST ALBERT THE GREAT SCHOOL',
  'ST ALEXANDER SCHOOL',
  'ST ALOYSIUS ACADEMY',
  'ST ALOYSIUS CATHOLIC SCHOOL',
  'ST ALOYSIUS EDUCATION CENTER',
  'ST ALOYSIUS ELEMENTARY ACADEMY',
  'ST ALOYSIUS ELEMENTARY SCHOOL',
  'ST ALOYSIUS GONZAGA SCHOOL',
  'ST ALOYSIUS GRADE SCHOOL',
  'ST ALOYSIUS HIGH SCHOOL-VICKSBURG CATHOLIC SCHOO',
  'ST ALOYSIUS ON THE OHIO SCHOOL',
  'ST ALOYSIUS REGIONAL SCHOOL',
  'ST ALOYSIUS SCHOOL',
  'ST ALPHONSUS CATHOLIC SCHOOL',
  'ST ALPHONSUS LIGUORI SCHOOL',
  'ST ALPHONSUS SCHOOL',
  'ST AMBROSE ACADEMY',
  'ST AMBROSE CATHOLIC SCHOOL',
  'ST AMBROSE ELEMENTARY SCHOOL',
  'ST AMBROSE OF WOODBURY CATHOLIC SCHOOL',
  'ST AMBROSE SCHOOL',
  'ST AMELIA SCHOOL',
  'ST ANASTASIA CATHOLIC ELEMENTARY SCHOOL',
  'ST ANASTASIA CATHOLIC SCHOOL',
  'ST ANASTASIA ELEMENTARY SCHOOL',
  'ST ANASTASIA SCHOOL',
  'ST ANDREW ACADEMY',
  'ST ANDREW APOSTLE SCHOOL',
  'ST ANDREW AVELLINO CATHOLIC SCHOOL',
  'ST ANDREW CATHOLIC',
  'ST ANDREW CATHOLIC SCHOOL',
  'ST ANDREW ELEMENTARY SCHOOL',
  'ST ANDREW NATIVITY SCHOOL',
  'ST ANDREW PARISH SCHOOL',
  'ST ANDREW SCHOOL',
  'ST ANDREW THE APOSTLE',
  'ST ANDREW THE APOSTLE PARISH SCHOOL',
  'ST ANDREW THE APOSTLE SCHOOL',
  "ST ANDREW'S ACADEMY",
  "ST ANDREW'S ELEMENTARY SCHOOL",
  "ST ANDREW'S EPISCOPAL ACADEMY",
  "ST ANDREW'S EPISCOPAL DAY SCHL",
  "ST ANDREW'S EPISCOPAL SCHOOL",
  "ST ANDREW'S ESPISCOPAL SCHOOL",
  "ST ANDREW'S NURSERY SCHOOL & KINDERGARTEN",
  "ST ANDREW'S PRESCHOOL & KINDERGARTEN",
  "ST ANDREW'S SCHOOL",
  "ST ANDREW'S-SEWANEE SCHOOL",
  'ST ANDREW-ST ELIZABETH ANN SETON SCHOOL',
  'ST ANDREWS COUNTRY DAY SCHOOL',
  'ST ANDREWS DAY SCHOOL',
  'ST ANDREWS EPISCOPAL SCHOOL',
  'ST ANDREWS LUTHERAN SCHOOL',
  'ST ANDREWS METHODIST KINDERGARTEN',
  'ST ANDREWS MONTESSORI SCHOOL',
  'ST ANDREWS NURSERY SCHOOL',
  'ST ANDREWS PRESCHOOL & KINDERGARTEN',
  'ST ANDREWS SCHOOL',
  'ST ANDREWS SCHOOL OF BOCA RATON INC',
  'ST ANDREWS UMC WEEKDAY SCHOOL',
  'ST ANGELA ELEMENTARY SCHOOL',
  'ST ANGELA MERICI ELEMENTARY SCHOOL',
  'ST ANGELA MERICI PARISH SCHOOL',
  'ST ANGELA MERICI SCHOOL',
  'ST ANN ACADEMY',
  'ST ANN CATHOLIC SCHOOL',
  'ST ANN ELEMENTARY SCHOOL',
  'ST ANN GRADE SCHOOL',
  'ST ANN S SCHOOL',
  'ST ANN SCHOOL',
  "ST ANN'S ACADEMY",
  "ST ANN'S CATHOLIC SCHOOL",
  "ST ANN'S HOME",
  "ST ANN'S SCHOOL",
  'ST ANNE CATHOLIC GRADE SCHOOL',
  'ST ANNE CATHOLIC SCHOOL',
  'ST ANNE ELEMENTARY SCHOOL',
  'ST ANNE INSTITUTE',
  'ST ANNE PARISH SCHOOL',
  'ST ANNE SCHOOL',
  "ST ANNE'S DAY SCHOOL",
  "ST ANNE'S PARISH DAY SCHOOL",
  "ST ANNE'S SCHOOL",
  "ST ANNE'S-BELFIELD SCHOOL",
  'ST ANNE-PACELLI CATHOLIC SCHOOL',
  'ST ANNES EPISCOPAL SCHOOL',
  'ST ANNES SCHOOL',
  'ST ANNES SCHOOL OF ANNAPOLIS',
  'ST ANNS HIGH SCHOOL',
  'ST ANNS SCHOOL',
  'ST ANSELM CATHOLIC ACADEMY',
  'ST ANSELM ELEMENTARY SCHOOL',
  'ST ANSELM PAROCHIAL SCHOOL',
  'ST ANSELM SCHOOL',
  'ST ANTHOMY HIGH SCHOOL',
  'ST ANTHONY - IMMACULATE CONCEPTION SCHOOL',
  'ST ANTHONY ACADEMY',
  'ST ANTHONY CATHEDRAL BASILICA SCHOOL',
  'ST ANTHONY CATHOLIC HIGH SCHOOL',
  'ST ANTHONY CATHOLIC SCHOOL',
  'ST ANTHONY CATHOLIC SCHOOL CATHOLIC SCHOOL SYS',
  'ST ANTHONY DE PADUA SCHOOL',
  'ST ANTHONY ELEMENTARY SCHOOL',
  'ST ANTHONY GRADE SCHOOL',
  'ST ANTHONY HIGH SCHOOL',
  'ST ANTHONY OF PADUA',
  'ST ANTHONY OF PADUA CATHOLIC SCHOOL',
  'ST ANTHONY OF PADUA ELEMENTARY SCHOOL',
  'ST ANTHONY OF PADUA SCHOOL',
  'ST ANTHONY ON THE LAKE',
  'ST ANTHONY PADUA PARISH SCHOOL',
  'ST ANTHONY PADUA SCHOOL',
  'ST ANTHONY PARISH SCHOOL',
  'ST ANTHONY SCHOOL',
  'ST ANTHONY SCHOOL KAILUA',
  'ST ANTHONY SCHOOL OF MILWAUKEE',
  'ST ANTHONY SCHOOL PROGRAMS',
  "ST ANTHONY'S HIGH SCHOOL",
  'ST ANTHONYS SCHOOL OF HIGHER EDUCATION',
  'ST ATHANASIUS',
  'ST ATHANASIUS SCHOOL',
  'ST AUGUSTIN CATHOLIC SCHOOL',
  'ST AUGUSTIN PRESCHOOL',
  'ST AUGUSTINE CATHOLIC HIGH SCHOOL',
  'ST AUGUSTINE CATHOLIC SCHOOL',
  'ST AUGUSTINE ELEMENTARY SCHOOL',
  'ST AUGUSTINE HIGH SCHOOL',
  'ST AUGUSTINE INDIAN MISSION SCHOOL',
  'ST AUGUSTINE PREPARATORY SCHOOL',
  'ST AUGUSTINE SCHOOL',
  'ST AUSTIN CATHOLIC SCHOOL',
  'ST BARBARA ELEMENTARY SCHOOL',
  'ST BARBARA SCHOOL',
  'ST BARNABAS CATHOLIC SCHOOL',
  'ST BARNABAS ELEMENTARY SCHOOL',
  'ST BARNABAS EPISCOPAL SCHOOL',
  'ST BARNABAS HIGH SCHOOL',
  'ST BARNABAS PARISH SCHOOL',
  'ST BARNABAS REGIONAL SCHOOL',
  'ST BARNABAS SCHOOL',
  'ST BARTHOLOMEW ACADEMY',
  'ST BARTHOLOMEW CATHOLIC SCHOOL',
  'ST BARTHOLOMEW SCHOOL',
  'ST BARTHOLOMEWS SCHOOL',
  'ST BASIL ACADEMY',
  'ST BASIL ACADEMY OF CLASSICAL STUDIES',
  'ST BASIL CATHOLIC SCHOOL',
  'ST BEDE ACADEMY',
  'ST BEDE ELEMENTARY SCHOOL',
  'ST BEDE SCHOOL',
  'ST BEDE-VENERABLE SCHOOL',
  'ST BENEDICT ACADEMY',
  'ST BENEDICT CATHEDRAL SCHOOL',
  'ST BENEDICT CATHOLIC SCHOOL',
  'ST BENEDICT ELEMENTARY INC.',
  'ST BENEDICT ELEMENTARY SCHOOL',
  'ST BENEDICT PREPARATORY SCHOOL',
  'ST BENEDICT SCHOOL',
  'ST BENEDICT THE MOOR SCHOOL',
  "ST BENEDICT'S PREP",
  'ST BENEDICTS EPISCOPAL SCHOOL',
  'ST BENILDE SCHOOL',
  'ST BERNADETTE CATHOLIC SCHOOL',
  'ST BERNADETTE ELEMENTARY SCHOOL',
  'ST BERNADETTE SCHOOL',
  'ST BERNARD ACADEMY',
  'ST BERNARD CATHOLIC ACADEMY',
  'ST BERNARD CATHOLIC SCHOOL',
  'ST BERNARD ELEMENTARY',
  'ST BERNARD ELEMENTARY SCHOOL',
  'ST BERNARD HIGH SCHOOL',
  'ST BERNARD MISSION SCHOOL',
  'ST BERNARD OF CLAIRVAUX SCHOOL',
  'ST BERNARD PREPARATORY SCHOOL',
  'ST BERNARD REGIONAL CATHOLIC SCHOOL',
  'ST BERNARD SCHOOL',
  "ST BERNARD'S SCHOOL",
  'ST BERNARDINE OF SIENA SCHOOL',
  'ST BERNARDS ACADEMY',
  'ST BERNARDS C.C.H.S.',
  'ST BERNARDS CATHOLIC SCHOOL',
  'ST BERNARDS SCHOOL',
  'ST BONAVENTURE',
  'ST BONAVENTURE CATHOLIC SCHOOL',
  'ST BONAVENTURE ELEMENTARY SCHOOL',
  'ST BONAVENTURE HIGH SCHOOL',
  'ST BONIFACE CATHOLIC SCHOOL',
  'ST BONIFACE ELEMENTARY SCHOOL',
  'ST BONIFACE SCHOOL',
  'ST BRENDAN',
  'ST BRENDAN CATHOLIC HIGH SCHOOL',
  'ST BRENDAN CATHOLIC SCHOOL',
  'ST BRENDAN ELEMENTARY SCHOOL',
  "ST BRENDAN'S CATHOLIC CHURCH",
  'ST BRIDGET ELEMENTARY SCHOOL',
  'ST BRIDGET OF KILDARE SCHOOL',
  'ST BRIDGET OF SWEDEN',
  'ST BRIDGET PARISH SCHOOL',
  'ST BRIDGET SCHOOL',
  'ST BRIGID CATHOLIC SCHOOL',
  'ST BRIGID SCHOOL',
  'ST BRIGID/OUR LADY OF HOPE REGIONAL SCHOOL',
  'ST BRONISLAVA ELEMENTARY SCHOOL',
  'ST BRUNO CATHOLIC SCHOOL',
  'ST BRUNO ELEMENTARY SCHOOL',
  'ST BRUNO PARISH SCHOOL',
  'ST CAJETAN ELEMENTARY SCHOOL',
  'ST CAMILLUS CATHOLIC ACADEMY',
  'ST CASIMIR CATHOLIC SCHOOL',
  'ST CASIMIR SCHOOL',
  "ST CASIMIR'S SCHOOL",
  'ST CASSIAN ELEMENTARY SCHOOL',
  'ST CATHARINE ACADEMY',
  'ST CATHARINE OF SIENA SCHOOL',
  'ST CATHERINE ACADEMY',
  'ST CATHERINE CATHOLIC SCHOOL',
  'ST CATHERINE ELEMENTARY SCHOOL',
  'ST CATHERINE LABOURE SCHOOL',
  'ST CATHERINE OF ALEXANDRIA SCHOOL',
  'ST CATHERINE OF GENOA SCHOOL',
  'ST CATHERINE OF SIENA',
  'ST CATHERINE OF SIENA ACADEMY',
  'ST CATHERINE OF SIENA CATHOLIC SCHOOL',
  'ST CATHERINE OF SIENA ELEMENTARY SCHOOL',
  'ST CATHERINE OF SIENA PARISH SCHOOL',
  'ST CATHERINE OF SIENA SCHOOL',
  'ST CATHERINE SCHOOL',
  'ST CATHERINE SIENA-ST LUCY SCHOOL',
  "ST CATHERINE'S ACADEMY",
  "ST CATHERINE'S CTR FOR CHILDREN R & E MAY SCHOOL",
  'ST CATHERINE-ST MARYS GRADE SCHOOL',
  'ST CATHERINES MONTESSORI',
  'ST CATHERINES SCHOOL',
  'ST CECELIA INTERPAROCHIAL SCHOOL',
  'ST CECILIA ACADEMY',
  'ST CECILIA CATHEDRAL SCHOOL',
  'ST CECILIA CATHOLIC SCHOOL',
  'ST CECILIA ELEMENTARY SCHOOL',
  'ST CECILIA MIDDLE SCH/HIGH SCHOOL',
  'ST CECILIA SCHOOL',
  'ST CELESTINE SCHOOL',
  'ST CHARLES',
  'ST CHARLES APACHE MISSION SCHOOL',
  'ST CHARLES BORROMEO',
  'ST CHARLES BORROMEO ACCADMY',
  'ST CHARLES BORROMEO CATHOLIC ACADEMY',
  'ST CHARLES BORROMEO CATHOLIC SCHOOL',
  'ST CHARLES BORROMEO ELEMENTARY SCHOOL',
  'ST CHARLES BORROMEO PRIMARY SCHOOL',
  'ST CHARLES BORROMEO SCHOOL',
  'ST CHARLES CATHOLIC HIGH SCHOOL',
  'ST CHARLES CATHOLIC SCHOOL',
  'ST CHARLES ELEMENTARY SCHOOL',
  'ST CHARLES ELEMENTERY SCHOOL',
  'ST CHARLES MISSION SCHOOL',
  'ST CHARLES PARISH SCHOOL',
  'ST CHARLES PREPARATORY SCHOOL',
  'ST CHARLES SCHOOL',
  'ST CHRISTINA SCHOOL',
  'ST CHRISTINE ELEMENTARY SCHOOL',
  'ST CHRISTOPHER',
  'ST CHRISTOPHER CATHOLIC SCHOOL',
  'ST CHRISTOPHER ELEMENTARY SCHOOL',
  'ST CHRISTOPHER MONTESSORI',
  'ST CHRISTOPHER PARISH SCHOOL',
  'ST CHRISTOPHER SCHOOL',
  "ST CHRISTOPHER'S SCHOOL",
  'ST CHRISTOPHER-OTTILIE SCHOOL',
  'ST CHRISTOPHERS',
  'ST CLARE CATHOLIC ACADEMY',
  'ST CLARE CATHOLIC SCHOOL',
  'ST CLARE OF ASSISI CATHOLIC SCHOOL',
  'ST CLARE OF ASSISI SCHOOL',
  'ST CLARE OF MONTEFALCO SCHOOL',
  'ST CLARE SCHOOL',
  'ST CLARE/SAINT PAUL ELEMENTARY SCHOOL',
  'ST CLARES SCHOOL',
  'ST CLEMENT COPTIC ORTHODOX CHRISTIAN ACADEMY',
  'ST CLEMENT ELEMENTARY SCHOOL',
  'ST CLEMENT GRADE SCHOOL',
  'ST CLEMENT OF ROME SCHOOL',
  'ST CLEMENT SCHOOL',
  "ST CLEMENT'S PARISH SCHOOL",
  'ST CLEMENTS REGIONAL CATHOLIC SCHOOL',
  'ST CLETUS ELEMENTARY SCHOOL',
  'ST CLETUS SCHOOL',
  'ST CLOUD CHRISTIAN SCHOOL',
  'ST COLEMAN SCHOOL',
  'ST COLETTA DAY SCHOOL OF MILWAUKEE',
  'ST COLETTE CATHOLIC SCHOOL',
  'ST COLETTE SCHOOL',
  'ST COLUMBA CATHOLIC SCHOOL',
  'ST COLUMBA SCHOOL',
  'ST COLUMBAN ELEMENTARY SCHOOL',
  'ST COLUMBAN SCHOOL',
  'ST COLUMBKILLE PARTNERSHIP SCHOOL',
  'ST COLUMBKILLE SCHOOL',
  'ST CONSTANCE SCHOOL',
  'ST CORNELIUS ELEMENTARY',
  'ST CORNELIUS SCHOOL',
  'ST CROIX CATHOLIC SCHOOL',
  'ST CROIX LUTHERAN HIGH SCHOOL',
  'ST CROIX MONTESSORI',
  'ST CYPRIAN ELEMENTARY SCHOOL',
  "ST CYPRIAN'S EPISCOPAL SCHOOL",
  'ST CYRIL & METHODIUS CATHOLIC SCHOOL',
  'ST CYRIL ELEMENTARY SCHOOL',
  'ST CYRIL OF JERUSALEM SCHOOL',
  'ST CYRIL PRESCHOOL & KINDERGARTEN',
  'ST DANIEL THE PROPHET SCHOOL',
  'ST DAVID CATHOLIC SCHOOL',
  'ST DAVID SCHOOL',
  "ST DAVID'S EPISCOPAL SCHOOL",
  "ST DAVID'S NURSERY SCHOOL",
  "ST DAVID'S SCHOOL",
  'ST DAVIDS DAY SCHOOL',
  'ST DAVIDS EPISCOPAL SCHOOL',
  'ST DAVIDS SCHOOL',
  'ST DEMETRIOS SCHOOL',
  'ST DENIS SCHOOL',
  'ST DENNIS SCHOOL',
  'ST DIDACUS SCHOOL',
  'ST DOMINIC ACADEMY',
  'ST DOMINIC ACADEMY-AUBURN',
  'ST DOMINIC ACADEMY-LEWISTON CAMPUS',
  'ST DOMINIC CATHOLIC SCHOOL',
  'ST DOMINIC ELEMENTARY SCHOOL',
  'ST DOMINIC HIGH SCHOOL',
  'ST DOMINIC SAVIO CATHOLIC HIGH SCHOOL',
  'ST DOMINIC SAVIO SCHOOL',
  'ST DOMINIC SCHOOL',
  "ST DOMINIC'S",
  'ST DOROTHY ELEMENTARY SCHOOL',
  'ST DOROTHY SCHOOL',
  'ST DUNSTAN CATHOLIC SCHOOL',
  'ST EDITH CATHOLIC SCHOOL',
  'ST EDMOND CATHOLIC SCHOOL',
  "ST EDMOND'S ACADEMY",
  'ST EDMUND CAMPION ACADEMY',
  'ST EDMUND ELEMENTARY SCHOOL',
  'ST EDMUND PREPARATORY HIGH SCHOOL',
  "ST EDMUND'S ACADEMY",
  'ST EDWARD CATHOLIC SCHOOL',
  'ST EDWARD CENTRAL CATHOLIC HIGH SCHOOL',
  'ST EDWARD CONFESSOR SCHOOL',
  'ST EDWARD ELEMENTARY SCHOOL',
  'ST EDWARD HIGH SCHOOL',
  'ST EDWARD ON-THE-LAKE CATHOLIC SCHOOL',
  'ST EDWARD SCHOOL',
  'ST EDWARD THE CONFESSOR PARISH SCHOOL',
  'ST EDWARD THE CONFESSOR SCHOOL',
  "ST EDWARD'S SCHOOL",
  'ST EDWARD-EPIPHANY SCHOOL',
  'ST EDWARDS CATHOLIC SCHOOL',
  'ST EGBERT CATHOLIC SCHOOL',
  'ST ELIZABETH ANN SETON',
  'ST ELIZABETH ANN SETON CATHOLIC SCHOOL',
  'ST ELIZABETH ANN SETON MIDDLE SCHOOL',
  'ST ELIZABETH ANN SETON REGIONAL SCHOOL PK-8',
  'ST ELIZABETH ANN SETON SCHOOL',
  'ST ELIZABETH AREA CATHOLIC SCHOOL',
  'ST ELIZABETH CATHOLIC ACADEMY',
  'ST ELIZABETH CATHOLIC SCHOOL',
  'ST ELIZABETH ELEMENTARY SCHOOL',
  'ST ELIZABETH NURSERY & MONTESSORI SCHOOL',
  'ST ELIZABETH OF HUNGARY CATHOLIC SCHOOL',
  'ST ELIZABETH PARISH SCHOOL',
  'ST ELIZABETH SCHOOL',
  'ST ELIZABETH SCHOOL & CHILD CARE CENTER',
  'ST ELIZABETH SETON SCHOOL',
  'ST EMILY ELEMENTARY SCHOOL',
  'ST EPHREM SCHOOL',
  'ST ETHELREDA ELEMENTARY SCHOOL',
  'ST EUGENE ELEMENTARY SCHOOL',
  'ST EUGENE SCHOOL',
  "ST EUGENE'S CATHEDRAL SCHOOL",
  'ST EUPHRASIA SCHOOL',
  'ST EUSTACHIUS ELEMENTARY SCHOOL',
  'ST FABIAN CATHOLIC SCHOOL',
  'ST FAITH PRESCHOOL & KINDERGARTEN',
  'ST FELICITAS CATHOLIC SCHOOL',
  'ST FELIX SCHOOL',
  'ST FERDINAND ELEMENTARY SCHOOL',
  'ST FERDINAND SCHOOL',
  'ST FINBAR PARISH SCHOOL',
  'ST FRANCES ACADEMY',
  'ST FRANCES CABRINI ACADEMY',
  'ST FRANCES CABRINI CATHOLIC AC',
  'ST FRANCES CABRINI CATHOLIC SCHOOL',
  'ST FRANCES CABRINI SCHOOL',
  'ST FRANCES DE CHANTAL SCHOOL',
  'ST FRANCES OF ROME SCHOOL',
  'ST FRANCES OUTREACH CENTER',
  'ST FRANCES X CABRINI SCHOOL',
  'ST FRANCIS & ST ROSE OF LIMA SCHOOL',
  'ST FRANCIS ACADEMY',
  'ST FRANCIS BORGIA GRADE SCHOOL',
  'ST FRANCIS BORGIA REGIONAL HIGH SCHOOL',
  'ST FRANCIS BORGIA SCHOOL',
  'ST FRANCIS CATHEDRAL SCHOOL',
  'ST FRANCIS CATHOLIC ACADEMY',
  'ST FRANCIS CATHOLIC HIGH SCHOOL',
  'ST FRANCIS CATHOLIC SCHOOL',
  'ST FRANCIS CENTRAL CATHOLIC SCHOOL',
  'ST FRANCIS CHILDRENS SCHOOL',
  'ST FRANCIS DAY SCHOOL',
  'ST FRANCIS DE SALES CATHEDRAL SCHOOL',
  'ST FRANCIS DE SALES CATHOLIC ACADEMY',
  'ST FRANCIS DE SALES CATHOLIC SCHOOL',
  'ST FRANCIS DE SALES HIGH SCHOOL',
  'ST FRANCIS DE SALES PARISH SCHOOL',
  'ST FRANCIS DE SALES REGIONAL',
  'ST FRANCIS DE SALES SCHOOL',
  'ST FRANCIS DE SALES SCHOOL FOR THE DEAFIN BROOKLYN',
  'ST FRANCIS DE SALES-ST STEPHEN',
  'ST FRANCIS EPISCOPAL SCHOOL',
  'ST FRANCIS HIGH SCHOOL',
  'ST FRANCIS HIGH SCHOOL SALESIAN COLLEGE PREP',
  'ST FRANCIS INTERNATIONAL SCHOOL',
  'ST FRANCIS MONTESSORI',
  'ST FRANCIS MONTESSORI CHRISTIAN SCHOOL',
  'ST FRANCIS OF ASSISI',
  'ST FRANCIS OF ASSISI CATHOLIC ACADEMY',
  'ST FRANCIS OF ASSISI CATHOLIC SCHOOL',
  'ST FRANCIS OF ASSISI ELEMENTARY SCHOOL',
  'ST FRANCIS OF ASSISI MIDDLE SCHOOL',
  'ST FRANCIS OF ASSISI SCHOOL',
  'ST FRANCIS PARISH SCHOOL',
  'ST FRANCIS PREP',
  'ST FRANCIS PREPARATORY SCHOOL',
  'ST FRANCIS SCHOOL',
  'ST FRANCIS SCHOOLS',
  'ST FRANCIS SERAPH SCHOOL',
  'ST FRANCIS SOLANO SCHOOL',
  'ST FRANCIS SOLANUS SCHOOL',
  'ST FRANCIS XAVIER CATHOLIC ACADEMYOOL',
  'ST FRANCIS XAVIER CATHOLIC ACADENY',
  'ST FRANCIS XAVIER CATHOLIC SCHOOL',
  'ST FRANCIS XAVIER CATHOLIC SCHOOL SYSTEM',
  'ST FRANCIS XAVIER ELEMENTARY SCHOOL',
  'ST FRANCIS XAVIER ELEMENTARY SCHOOL-MCD CAMPUS',
  'ST FRANCIS XAVIER HIGH SCHOOL',
  'ST FRANCIS XAVIER PREP SCHOOL',
  'ST FRANCIS XAVIER SCHOOL',
  'ST FRANCIS-ST HEDWIG SCHOOL',
  'ST FRANCIS/HOLY GHOST CATHOLIC SCHOOL',
  'ST FREDERICK HIGH SCHOOL',
  'ST GABRIEL CATHOLIC SCHOOL',
  'ST GABRIEL CONSOLIDATED SCHOOL',
  'ST GABRIEL ELEMENTARY SCHOOL',
  'ST GABRIEL SCHOOL',
  'ST GABRIEL THE ARCHANGEL SCHOOL',
  "ST GABRIEL'S CATHOLIC SCHOOL",
  'ST GABRIEL-SORROWFUL VIRGIN SCHOOL',
  'ST GABRIELS HALL',
  'ST GALL ELEMENTARY SCHOOL',
  'ST GEMMA CENTER',
  'ST GENEVIEVE ELEMENTARY SCHOOL',
  'ST GENEVIEVE HIGH SCHOOL',
  'ST GENEVIEVE MIDDLE SCHOOL',
  'ST GENEVIEVE SCHOOL',
  'ST GEORGE ACADEMY',
  'ST GEORGE CATHOLIC SCHOOL',
  'ST GEORGE EPISCOPAL SCHOOL',
  'ST GEORGE GRADE SCHOOL',
  'ST GEORGE PARISH SCHOOL',
  'ST GEORGE SCHOOL',
  "ST GEORGE'S EPISCOPAL SCHOOL",
  "ST GEORGE'S INDEPENDENT SCHOOL",
  "ST GEORGE'S SCHOOL",
  'ST GERALD SCHOOL',
  'ST GERARD CAMPUS INC',
  'ST GERARD CATHOLIC HIGH SCHOOL & REGIONAL M S',
  'ST GERARD MAJELLA CATHOLIC SCHOOL',
  'ST GERARD MAJELLA SCHOOL',
  'ST GERARD SCHOOL',
  'ST GERMAINE ELEMENTARY SCHOOL',
  'ST GERMAINE SCHOOL',
  'ST GERTRUDE HIGH SCHOOL',
  'ST GERTRUDE SCHOOL',
  'ST GILBERT ELEMENTARY SCHOOL',
  'ST GREGORY BARBARIGO SCHOOL',
  'ST GREGORY ELEMENTARY SCHOOL',
  'ST GREGORY PARISH SCHOOL',
  'ST GREGORY SCHOOL',
  'ST GREGORY THE GREAT CATHOLIC ACADEMY',
  'ST GREGORY THE GREAT CATHOLIC SCHOOL',
  'ST GREGORY THE GREAT SCHOOL',
  "ST GREGORY'S EARLY CHILDHOOD CENTER",
  'ST GREGORYS A & M HOVSEPIAN SCHOOL',
  'ST GREGORYS ACADEMY',
  'ST GREGORYS SCHOOL FOR BOYS',
  'ST HELEN CATHOLIC ACADEMY',
  'ST HELEN CATHOLIC ELEMENTARY SCHOOL',
  'ST HELEN CATHOLIC SCHOOL',
  'ST HELEN ELEMENTARY SCHOOL',
  'ST HELEN ROMAN CATHOLIC CHURCH',
  'ST HELEN SCHOOL',
  'ST HELENA MONTESSORI SCHOOL AND FARM',
  'ST HELENA SCHOOL',
  'ST HELENA SCHOOL OF HOBBS, INC.',
  'ST HELENS SCHOOL',
  'ST HENRY CATHOLIC SCHOOL',
  'ST HENRY DISTRICT HIGH SCHOOL',
  'ST HENRY SCHOOL',
  'ST HENRYS AREA SCHOOL',
  'ST HERMAN OF ALASKA CHRISTIAN SCHOOL',
  'ST HILARY ELEMENTARY SCHOOL',
  'ST HILARY SCHOOL',
  'ST HUBERT CATHOLIC HIGH SCHOOL FOR GIRLS',
  'ST HUBERT ELEMENTARY SCHOOL',
  'ST HUGH CATHOLIC SCHOOL',
  'ST HUGO OF THE HILLS ELEMENTARY SCHOOL',
  'ST IGNATIUS CATHOLIC SCHOOL',
  'ST IGNATIUS CATHOLIC SCHOOL (SICS)',
  'ST IGNATIUS COLLEGE PREP',
  'ST IGNATIUS COLLEGE PREPARATORY',
  'ST IGNATIUS ELEMENTARY SCHOOL',
  'ST IGNATIUS HIGH SCHOOL',
  'ST IGNATIUS LOYOLA ACADEMY',
  'ST IGNATIUS LOYOLA REGIONAL SCHOOL',
  'ST IGNATIUS LOYOLA SCHOOL',
  'ST IGNATIUS MARTYR CATHOLIC SCHOOL',
  'ST IGNATIUS OF LOYOLA SCHOOL',
  'ST IGNATIUS PARISH SCHOOL',
  'ST IGNATIUS SCHOOL',
  'ST IRENAEUS PARISH SCHOOL',
  'ST IRENE CATHOLIC SCHOOL',
  'ST ISAAC JOGUES ELEMENTARY SCHOOL',
  'ST ISABELLA SCHOOL',
  'ST ISIDORE ELEMENTARY SCHOOL',
  'ST ISIDORE SCHOOL',
  'ST JACOBI EVANGELICAL LUTHERAN SCHOOL',
  'ST JAMES & JOHN CATOLIC SCHOOL',
  'ST JAMES - ST JOHN SCHOOL',
  'ST JAMES ACADEMY',
  'ST JAMES CATHEDRAL SCHOOL',
  'ST JAMES CATHOLIC SCHOOL',
  'ST JAMES CATHOLIC SCHOOLS',
  'ST JAMES CHILD DEVELOPMENT CENTER',
  'ST JAMES CHRISTIAN ACADEMY',
  'ST JAMES DAY SCHOOL',
  'ST JAMES ELEMENTARY',
  'ST JAMES ELEMENTARY SCHOOL',
  'ST JAMES EPISCOPAL DAY SCHOOL',
  'ST JAMES EPISCOPAL SCHOOL',
  'ST JAMES EPISCOPAL SCHOOL OF DALLAS',
  'ST JAMES LUTHERAN ELEMENTARY SCHOOL',
  'ST JAMES LUTHERAN SCHOOL',
  'ST JAMES METHODIST KINDERGARTEN',
  'ST JAMES PARISH DAY SCHOOL & KINDERGARTEN',
  'ST JAMES REGIONAL CATHOLIC SCHOOL',
  'ST JAMES SCHOOL',
  'ST JAMES SETON SCHOOL',
  'ST JAMES THE APOSTLE SCHOOL',
  'ST JAMES THE GREATER SCHOOL',
  "ST JAMES' CHILDREN'S SCHOOL",
  'ST JAMES-HOLY REDEEMER SCHOOL',
  'ST JANE DE CHANTAL SCHOOL',
  'ST JANE FRANCES DE CHANTAL SCHOOL',
  'ST JANE FRANCIS SCHOOL',
  'ST JEAN BAPTISTE HIGH SCHOOL',
  'ST JEAN VIANNEY SCHOOL',
  'ST JEANNE DE LESTONNAC (SISTERS OF COMPANY OF',
  'ST JEANNE DE LESTONNAC SCHOOL',
  'ST JEROME ACADEMY',
  'ST JEROME CATHOLIC SCHOOL',
  'ST JEROME ELEMENTARY SCHOOL',
  'ST JEROME PARISH SCHOOL',
  'ST JEROME REGIONAL SCHOOL',
  'ST JEROME SCHOOL',
  'ST JOACHIM ELEMENTARY SCHOOL',
  'ST JOACHIM SCHOOL',
  'ST JOAN ANTIDA HIGH SCHOOL',
  'ST JOAN OF ARC',
  'ST JOAN OF ARC CATHOLIC SCHOOL',
  'ST JOAN OF ARC EARLY LEARNING CENTER',
  'ST JOAN OF ARC ELEMENTARY SCHOOL',
  'ST JOAN OF ARC PARISH SCHOOL',
  'ST JOAN OF ARC ROMAN CATHOLIC SCHOOL',
  'ST JOAN OF ARC SCHOOL',
  'ST JOHN ACADEMY',
  'ST JOHN AMELITH LUTHERAN SCHOOL',
  'ST JOHN BERCHMANS CATHEDRAL SCHOOL',
  'ST JOHN BERCHMANS SCHOOL',
  'ST JOHN BOSCO ACADEMY',
  'ST JOHN BOSCO CATHOLIC',
  'ST JOHN BOSCO CATHOLIC SCHOOL',
  'ST JOHN BOSCO ELEMENTARY SCHOOL',
  'ST JOHN BOSCO SCHOOL',
  'ST JOHN BREBEUF SCHOOL',
  'ST JOHN CATHOLIC SCHOOL',
  'ST JOHN CENTRAL GRADE SCHOOL',
  'ST JOHN CENTRAL HIGH SCHOOL',
  'ST JOHN CHRISTIAN HERITAGE ACADEMY',
  'ST JOHN DE LA SALLE CATHOLIC ACADEMY',
  'ST JOHN ELEMENTARY CATHOLIC SCHOOL',
  'ST JOHN ELEMENTARY SCHOOL',
  'ST JOHN EUDES SCHOOL',
  'ST JOHN EVANGELICAL LUTHERAN SCHOOL',
  'ST JOHN EVANGELIST ELEMENTARY SCHOOL',
  'ST JOHN EVANGELIST SCHOOL',
  'ST JOHN FISHER',
  'ST JOHN FISHER ELEMENTARY SCHOOL',
  'ST JOHN FISHER SCHOOL',
  'ST JOHN FRANCIS REGIS SCHOOL',
  'ST JOHN HIGH SCHOOL',
  'ST JOHN KANTY ELEMENTARY SCHOOL',
  'ST JOHN LALANDE SCHOOL',
  'ST JOHN LUTHERAN',
  'ST JOHN LUTHERAN CHURCH & SCHOOL',
  'ST JOHN LUTHERAN SCHOOL',
  'ST JOHN NEPOMUCENE SCHOOL',
  'ST JOHN NEPOMUK CATHOLIC SCHOOL',
  'ST JOHN NEUMANN ACADEMY',
  'ST JOHN NEUMANN CATHOLIC HIGH SCHOOL',
  'ST JOHN NEUMANN CATHOLIC SCHOOL',
  'ST JOHN NEUMANN HIGH SCHOOL',
  'ST JOHN NEUMANN REGIONAL CATHOLIC SCHOOL',
  'ST JOHN NEUMANN REGIONAL SCHOOL',
  'ST JOHN NEUMANN SCHOOL',
  'ST JOHN NEWMANN REGIONAL ACADEMY',
  'ST JOHN NOTRE DAME SCHOOL',
  'ST JOHN NOTTINGHAM LUTHERAN SCHOOL',
  'ST JOHN OF GOD COMMUNITY SERVICES/ARCHBISHOP DAM',
  'ST JOHN OF KRONSTADT ORTHODOX CHRISTIAN SCHOOL',
  'ST JOHN OF THE CROSS PARISH SCHOOL',
  'ST JOHN PAUL II CATHOLIC ACAD.-NEPONSET CAMPUS',
  'ST JOHN PAUL II CATHOLIC ACADEMY',
  'ST JOHN PAUL II CATHOLIC ACADEMY COLUMBIA CAMP',
  'ST JOHN PAUL II CATHOLIC ACADEMY MATTAPAN CAMP',
  'ST JOHN PAUL II CATHOLIC HIGH SCHOOL',
  'ST JOHN PAUL II CATHOLIC PRE-PARATORY SCHOOL',
  'ST JOHN PAUL II CATHOLIC SCHOOL',
  'ST JOHN PAUL II CLASSICAL SCHOOL',
  'ST JOHN PAUL II HIGH SCHOOL',
  'ST JOHN PAUL II SCHOOL',
  'ST JOHN PRAISE & WORSHIP CENTER PREP ACADEMY',
  'ST JOHN PRESCHOOL',
  'ST JOHN PRIMARY SCHOOL',
  'ST JOHN REGIONAL CATHOLIC SCHOOL',
  'ST JOHN S ELEMENTARY SCHOOL',
  'ST JOHN S LUTHERAN SCHOOL',
  'ST JOHN SCHOOL',
  'ST JOHN SCHOOL OF LITTLE CANADA',
  'ST JOHN THE APOSTLE CATHOLIC',
  'ST JOHN THE APOSTLE CATHOLIC SCHOOL',
  'ST JOHN THE APOSTLE SCHOOL',
  'ST JOHN THE BAPTIST',
  'ST JOHN THE BAPTIST CATHOLIC ELEM.',
  'ST JOHN THE BAPTIST CATHOLIC ELEMENTARY SCHOOL',
  'ST JOHN THE BAPTIST CATHOLIC MONTESSORI SCHOOL',
  'ST JOHN THE BAPTIST CATHOLIC SCHOOL',
  'ST JOHN THE BAPTIST DIOCESAN HIGH SCHOOL',
  'ST JOHN THE BAPTIST MIDDLE SCHOOL',
  'ST JOHN THE BAPTIST SCHOOL',
  'ST JOHN THE BELOVED SCHOOL',
  'ST JOHN THE EVANGELIST CATHOLIC SCHOOL',
  'ST JOHN THE EVANGELIST NOTRE DAME ACE ACADEMY',
  'ST JOHN THE EVANGELIST SCHOOL',
  'ST JOHN THE EVANGELIST/ST PIUS X SCHOOL',
  'ST JOHN VIANNEY CATHOLIC SCHOOL',
  'ST JOHN VIANNEY ELEMENTARY SCHOOL',
  'ST JOHN VIANNEY HS',
  'ST JOHN VIANNEY PARISH SCHOOL',
  'ST JOHN VIANNEY REGIONAL HIGH SCHOOL',
  'ST JOHN VIANNEY REGIONAL SCHOOL',
  'ST JOHN VIANNEY SCHOOL',
  'ST JOHN VILLA ACADEMY - HIGH SCHOOL',
  'ST JOHN XXIII CATHOLIC SCHOOL',
  'ST JOHN XXIII CATHOLIC SCHOOL COMMUNITY',
  'ST JOHN XXIII COLLEGE PRREPARATORY',
  "ST JOHN'S ACADEMY",
  "ST JOHN'S CATHOLIC PREP",
  "ST JOHN'S CATHOLIC PRESCHOOL",
  "ST JOHN'S CATHOLIC SCHOOL",
  "ST JOHN'S CHRISTIAN ACADEMY",
  "ST JOHN'S ELEMENTARY SCHOOL",
  "ST JOHN'S EPISCOPAL SCHOOL",
  "ST JOHN'S EV. LUTHERAN SCHOOL",
  "ST JOHN'S EVANGELICAL LUTHERAN SCHOOL",
  "ST JOHN'S GRADE SCHOOL",
  "ST JOHN'S HIGH SCHOOL",
  "ST JOHN'S KINDERGARTEN",
  "ST JOHN'S LUTHERAN SCHOOL",
  "ST JOHN'S MILITARY SCHOOL",
  "ST JOHN'S NORTHWESTERN MILITARY ACADEMY",
  "ST JOHN'S PREPARATORY SCHOOL",
  "ST JOHN'S SCHOOL",
  'ST JOHN-EMMANUEL LUTHERAN SCHOOL',
  'ST JOHN-EVANGELIST SCHOOL',
  'ST JOHN-SACRED HEART SCHOOL',
  'ST JOHN-ST JAMES LUTHERAN SCHOOL',
  'ST JOHNS AREA SCHOOL',
  'ST JOHNS CATHOLIC SCHOOL',
  'ST JOHNS CHILDRENS CENTER',
  'ST JOHNS CHRISTIAN SCHOOL',
  'ST JOHNS COLLEGE HIGH SCHOOL',
  'ST JOHNS COUNTRY DAY SCHOOL',
  'ST JOHNS DAY SCHOOL',
  'ST JOHNS DELIVERENCE SCHOOL OF TOMORROW',
  'ST JOHNS EPISCOPAL DAY SCHOOL',
  'ST JOHNS EPISCOPAL PARISH DAY SCHOOL',
  'ST JOHNS EPISCOPAL SCHOOL',
  'ST JOHNS EVANGELICAL LUTHERAN SCHOOL',
  'ST JOHNS GRAMMAR SCHOOL',
  'ST JOHNS JESUIT HIGH SCHOOL & ACADEMY',
  'ST JOHNS LUTHERAN SCHOOL',
  'ST JOHNS PREPARATORY SCHOOL',
  'ST JOHNS SCHOOL',
  'ST JOHNS-ST ANDREWS CATHOLIC SCHOOL',
  'ST JOSAPHAT PARISH SCHOOL',
  'ST JOSAPHAT SCHOOL',
  'ST JOSEPH',
  'ST JOSEPH - ST. THOMAS SCHOOL',
  'ST JOSEPH ACADEMY',
  'ST JOSEPH ACADEMY INC',
  'ST JOSEPH CATHEDRAL SCHOOL',
  'ST JOSEPH CATHOLIC ACADEMY',
  'ST JOSEPH CATHOLIC ELEMENTARY SCHOOL',
  'ST JOSEPH CATHOLIC HIGH SCHOOL',
  'ST JOSEPH CATHOLIC PARISH SCHOOL',
  'ST JOSEPH CATHOLIC SCHOOL',
  'ST JOSEPH CATHOLIC SCHOOL - ELEMENTARY CAMPUS',
  'ST JOSEPH CATHOLIC SCHOOL OST',
  'ST JOSEPH CATHOLIC SCHOOL TAMPA',
  'ST JOSEPH CENTER FOR SPECIAL LEARNING',
  'ST JOSEPH CENTRAL CATHOLIC HIGH SCHOOL',
  'ST JOSEPH CHRISTIAN SCHOOL',
  'ST JOSEPH COMMUNITY SCHOOL',
  'ST JOSEPH CONSOLIDATED SCHOOL',
  'ST JOSEPH ELEMENTARY SCHOOL',
  'ST JOSEPH GRADE SCHOOL',
  'ST JOSEPH HESSEN CASSEL',
  'ST JOSEPH HIGH SCHOOL',
  'ST JOSEPH HILL ACADEMY',
  'ST JOSEPH INDIAN SCHOOL',
  'ST JOSEPH INTERPAROCHIAL SCHOOL',
  'ST JOSEPH MARQUETTE CATHOLIC SCHOOL',
  'ST JOSEPH MIDDLE SCHOOL',
  'ST JOSEPH MINOR SEMINARY',
  'ST JOSEPH MISSION SCHOOL',
  'ST JOSEPH MONTESSORI CHILDRENS CENTER',
  'ST JOSEPH MONTESSORI SCHOOL',
  'ST JOSEPH OF CUPERTINO ELEMENTARY SCHOOL',
  'ST JOSEPH PANSH SCHOOL',
  'ST JOSEPH PARISH SCHOOL',
  'ST JOSEPH PAROCHIAL SCHOOL',
  'ST JOSEPH PREPARATORY HIGH SCHOOL',
  'ST JOSEPH PRO-CATHEDRAL SCHOOL',
  'ST JOSEPH REGIONAL ACADEMY',
  'ST JOSEPH REGIONAL CATHOLIC SCHOOL',
  'ST JOSEPH REGIONAL ELEMENTARY SCHOOL',
  'ST JOSEPH REGIONAL HIGH SCHOOL',
  'ST JOSEPH REGIONAL JR HIGH SCHOOL',
  'ST JOSEPH REGIONAL SCHOOL',
  'ST JOSEPH S CATHOLIC SCHOOL',
  'ST JOSEPH SCHOOL',
  'ST JOSEPH SCHOOL K-12',
  'ST JOSEPH SCHOOL- VERONA',
  'ST JOSEPH SCHOOL-- ISSAQUAH AND SNOQUALMIE',
  'ST JOSEPH SCHOOL-JOSEPHVILLE',
  'ST JOSEPH SCHOOL-WENATCHEE',
  'ST JOSEPH ST ROBERT SCHOOL',
  'ST JOSEPH THE CARPENTER SCHOOL',
  'ST JOSEPH THE PROTECTOR',
  'ST JOSEPH THE PROVIDER SCHOOL',
  'ST JOSEPH THE WORKER GRADE SCHOOL',
  'ST JOSEPH THE WORKER SCHOOL',
  'ST JOSEPH VILLA ACADEMY ELEMENTARY',
  "ST JOSEPH'S ACADEMY",
  "ST JOSEPH'S CATHOLIC CHURCH",
  "ST JOSEPH'S CATHOLIC SCHOOL",
  "ST JOSEPH'S CHILDREN'S HOME",
  "ST JOSEPH'S COLLEGIATE INSTITUTE",
  "ST JOSEPH'S EPISCOPAL SCHOOL",
  "ST JOSEPH'S PREPARATORY SCHOOL",
  "ST JOSEPH'S REGIONAL CATHOLIC SC",
  "ST JOSEPH'S SCHOOL",
  "ST JOSEPH'S SCHOOL FOR THE DEAF",
  'ST JOSEPHS CATHOLIC SCHOOL',
  'ST JOSEPHS SCHOOL',
  'ST JOSEPHS SCHOOL FOR THE BLIND',
  'ST JUDE CATHEDRAL SCHOOL',
  'ST JUDE CATHOLIC',
  'ST JUDE CATHOLIC SCHOOL',
  'ST JUDE EDUCATIONAL INSTITUTE',
  'ST JUDE ELEMENTARY SCHOOL',
  'ST JUDE MISSION SCHOOL',
  'ST JUDE OF THE LAKE SCHOOL',
  'ST JUDE REGIONAL CATHOLIC SCHOOL',
  'ST JUDE SCHOOL',
  'ST JUDE THADDEUS SCHOOL',
  'ST JUDE THE APOSTLE CATHOLIC SCHOOL',
  'ST JUDE THE APOSTLE SCHOOL',
  'ST JULIANA CATHOLIC SCHOOL',
  'ST JULIANA FALCONIERI SCHOOL',
  'ST JUSTIN ELEMENTARY SCHOOL',
  'ST JUSTIN MARTYR ELEMENTARY SCHOOL',
  'ST JUSTIN MARTYR SCHOOL',
  'ST KATERI CATHOLIC SCHOOLS',
  'ST KATERI PARISH SCHOOL',
  'ST KATERI SCHOOL',
  'ST KATHARINE DREXEL PREPAROTORY SCHOOL',
  'ST KATHARINE DREXEL SCHOOL',
  'ST KIERAN CATHOLIC SCHOOL',
  'ST KILIAN PARISH SCHOOL',
  'ST KILIAN SCHOOL',
  'ST LA SALLE ELEMENTARY SCHOOL',
  'ST LAURENCE CATHOLIC SCHOOL',
  'ST LAURENCE ELEMENTARY SCHOOL',
  'ST LAURENCE H.S.',
  'ST LAWRENCE ACADEMY',
  'ST LAWRENCE CATHOLIC SCHOOL',
  'ST LAWRENCE ELEMENTARY SCHOOL',
  'ST LAWRENCE ELEMENTARY/MIDDLE SCHOOL',
  'ST LAWRENCE MARTYR ELEMENTARY SCHOOL',
  'ST LAWRENCE OF BRINDISI CATHOLIC SCHOOL',
  'ST LAWRENCE SCHOOL',
  'ST LAWRENCE SEMINARY HIGH SCHOOL',
  'ST LEO CATHOLIC ACADEMY',
  'ST LEO ELEMENTARY SCHOOL',
  'ST LEO SCHOOL',
  'ST LEO THE GREAT CATHOLIC SCHOOL',
  'ST LEO THE GREAT ELEMENTARY SCHOOL',
  'ST LEO THE GREAT SCHOOL',
  'ST LEONARD SCHOOL',
  'ST LILLIAN ACADEMY',
  'ST LINUS CATHOLIC ELEMENTARY SCHOOL',
  'ST LINUS ELEMENTARY SCHOOL',
  'ST LORENZ LUTHERAN SCHOOL',
  'ST LOUIS ACADEMY',
  'ST LOUIS CATHOLIC ACADEMY',
  'ST LOUIS CATHOLIC HIGH SCHOOL',
  'ST LOUIS CATHOLIC SCHOOL',
  'ST LOUIS CHRISTIAN ACADEMY',
  'ST LOUIS COVENANT SCHOOL',
  'ST LOUIS DE MONTFORT',
  'ST LOUIS DE MONTFORT CATHOLIC SCHOOL',
  'ST LOUIS ELEMENTARY SCHOOL',
  'ST LOUIS KING OF FRANCE',
  'ST LOUIS KING OF FRANCE SCHOOL',
  'ST LOUIS SCHOOL',
  'ST LOUIS THE KING SCHOOL',
  'ST LOUIS UNIFIED SCHOOL OF SDA',
  'ST LOUIS UNIVERSITY HIGH SCHOOL',
  'ST LOUISE DE MARILLAC SCHOOL',
  'ST LUCAS EVANGELICAL LUTHERAN SCHOOL',
  'ST LUCAS GRADE SCHOOL',
  'ST LUCY DAY SCHOOL FOR CHLDRN W VISUAL IMPAIRMENT',
  'ST LUCY PARISH SCHOOL',
  'ST LUCY SCHOOL',
  "ST LUCY'S SCHOOL",
  'ST LUCYS PRIORY HIGH SCHOOL',
  'ST LUDGER ELEMENTARY SCHOOL',
  'ST LUKE CATHOLIC ELEMENTARY SCHOOL',
  'ST LUKE CATHOLIC SCHOOL',
  'ST LUKE ELEMENTARY SCHOOL',
  'ST LUKE LUTHERAN CHURCH & SCHOOL',
  'ST LUKE PARISH SCHOOL',
  'ST LUKE S CHRISTIAN ACADEMY & PRESCHOOL',
  'ST LUKE S LUTHERAN SCHOOL',
  'ST LUKE SCHOOL',
  'ST LUKE THE EVANGELIST CATHOLIC SCHOOL',
  "ST LUKE' S EPISCOPAL DAY SCHOOL",
  "ST LUKE'S CHRISTIAN ACADEMY",
  "ST LUKE'S DAY SCHOOL",
  "ST LUKE'S ELEMENTARY SCHOOL",
  "ST LUKE'S EPISCOPAL SCHOOL",
  "ST LUKE'S LITTLE SAINTS PRESCHOOL & KINDERGTEN",
  "ST LUKE'S LUTHERAN SCHOOL",
  "ST LUKE'S PRESCHOOL",
  "ST LUKE'S PRESCHOOL & KINDERGARTEN",
  'ST LUKES ELEMENTARY SCHOOL',
  'ST LUKES EPISCOPAL SCHOOL',
  'ST LUKES LUTHERAN SCHOOL',
  'ST LUKES SCHOOL',
  'ST MADELEINE SCHOOL',
  'ST MADELEINE SOPHIE CATHOLIC SCHOOL',
  'ST MADELEINE SOPHIE SCHOOL',
  'ST MALACHY',
  'ST MALACHY ELEMENTARY SCHOOL',
  'ST MALACHY SCHOOL',
  'ST MARCUS LUTHERAN SCHOOL',
  'ST MARGARET CATHOLIC ACADEMY',
  'ST MARGARET CATHOLIC SCHOOL',
  'ST MARGARET MARY ALACOQUE SCHOOL',
  'ST MARGARET MARY CATHOLIC SCHOOL',
  'ST MARGARET MARY SCHOOL',
  'ST MARGARET OF SCOTLAND',
  'ST MARGARET OF SCOTLAND SCHOOL',
  'ST MARGARET OF YORK ELEMENTARY SCHOOL',
  'ST MARGARET REGIONAL SCHOOL',
  'ST MARGARET SCHOOL',
  "ST MARGARET'S DAY SCHOOL",
  "ST MARGARET'S SCHOOL",
  'ST MARGARETS SCHOOL',
  "ST MARGUERITE'S CATHOLIC SCHOOL",
  'ST MARIA GORETTI',
  'ST MARIA GORETTI CATHOLIC SCHOOL',
  'ST MARIA GORETTI HIGH SCHOOL',
  'ST MARIA GORETTI PRESCHOOL AND KINDERGARTEN',
  'ST MARIA GORETTI SCHOOL',
  'ST MARIANNE SCHOOL',
  'ST MARIE CHILD CARE CENTER',
  'ST MARK CATHOLIC ACADEMY',
  'ST MARK CATHOLIC SCHOOL',
  'ST MARK ELEMENTARY SCHOOL',
  'ST MARK EVANGELICAL LUTHERAN SCHOOL',
  'ST MARK LUTHERAN SCHOOL',
  'ST MARK PARISH SCHOOL',
  'ST MARK SCHOOL',
  'ST MARK THE EVANGELIST SCHOOL',
  "ST MARK'S CHRISTIAN SCHOOL",
  "ST MARK'S DAY SCHOOL",
  "ST MARK'S EPISCOPAL ACADEMY",
  "ST MARK'S EPISCOPAL SCHOOL",
  "ST MARK'S HIGH SCHOOL",
  "ST MARK'S KINDERGARTEN",
  "ST MARK'S LUTHERAN SCHOOL",
  "ST MARK'S SCHOOL",
  "ST MARK'S SCHOOL OF TEXAS",
  'ST MARKS CATHEDRAL SCHOOL',
  'ST MARKS EARLY CHILDHOOD CENTER',
  'ST MARKS ELEMENTARY SCHOOL',
  'ST MARKS EPISCOPAL DAY SCHOOL',
  'ST MARKS EPISCOPAL SCHOOL',
  'ST MARKS EVANGELICAL LUTHERAN SCHOOL',
  'ST MARKS LUTHERAN SCHOOL',
  'ST MARKS MONTESSORI SCHOOL',
  'ST MARKS PRESCHOOL & KINDERGARTEN',
  'ST MARKS SCHOOL',
  'ST MARTHA CATHOLIC SCHCOL',
  'ST MARTHA CATHOLIC SCHOOL',
  'ST MARTHA ELEMENTARY SCHOOL',
  'ST MARTHA SCHOOL',
  'ST MARTIN DE PORRES ACADEMY',
  'ST MARTIN DE PORRES CATHOLIC SCHOOL',
  'ST MARTIN DE PORRES HIGH SCHOOL',
  'ST MARTIN DE PORRES SCHOOL',
  'ST MARTIN ELEMENTARY SCHOOL',
  'ST MARTIN IN THE FIELDS PARISH SCHOOL',
  'ST MARTIN LUTHERAN SCHOOL',
  'ST MARTIN OF TOURS',
  'ST MARTIN OF TOURS ACADEMY',
  'ST MARTIN OF TOURS ELEMENTARY SCHOOL',
  'ST MARTIN OF TOURS SCHOOL',
  'ST MARTIN S LUTHERAN SCHOOL',
  "ST MARTIN'S EPISCOPAL SCHOOL",
  "ST MARTIN'S-IN-THE-FIELD EPISCOPAL SCHOOL",
  'ST MARTINI LUTHERAN SCHOOL',
  'ST MARTINS LUTHERAN CHURCH SCHOOL',
  'ST MARTINS LUTHERAN SCHOOL',
  'ST MARTINS SCHOOL',
  'ST MARY',
  'ST MARY & ALL ANGELS SCHOOL',
  'ST MARY & ST MICHAEL SCHOOL',
  'ST MARY ACADEMY',
  'ST MARY ACADEMY-BAY VIEW',
  'ST MARY AT THE ANGELS CATHOLIC SCHOOL',
  'ST MARY BYZANTINE SCHOOL',
  'ST MARY CATHEDRAL ELEMENTARY SCHOOL',
  'ST MARY CATHEDRAL SCHOOL',
  'ST MARY CATHOLIC CENTRAL HIGH SCHOOL',
  'ST MARY CATHOLIC COMMUNITY SCHOOL',
  'ST MARY CATHOLIC ELEMENTARY SCHOOL',
  'ST MARY CATHOLIC SCHOOL',
  'ST MARY CENTRAL HIGH SCHOOL',
  'ST MARY CENTRAL SCHOOL',
  'ST MARY ELEMENTARY SCHOOL',
  'ST MARY GATE OF HEAVEN CATHOLIC ACADEMY',
  'ST MARY GRADE SCHOOL',
  'ST MARY HELP OF CHRISTIANS SCHOOL',
  'ST MARY HIGH SCHOOL',
  'ST MARY IMMACULATE CONCEPTION SCHOOL',
  'ST MARY IMMACULATE PARISH SCHOOL',
  'ST MARY INTERPAROCHIAL SCHOOL',
  'ST MARY LITTLETON',
  'ST MARY MAGDALEN CATHOLIC SCHOOL',
  'ST MARY MAGDALEN ELEMENTARY SCHOOL',
  'ST MARY MAGDALEN SCHOOL',
  'ST MARY MAGDALENE SCHOOL',
  'ST MARY NATIVITY SCHOOL',
  'ST MARY OF CARMEL SCHOOL',
  'ST MARY OF GOSTYN CATHOLIC SCHOOL',
  'ST MARY OF IMMACULATE CONCEPTION SCHOOL',
  'ST MARY OF MT CARMEL SCHOOL',
  'ST MARY OF MT. CARMEL SCHOOL',
  'ST MARY OF THE ASSUMPTION ELEMENTARY SCHOOL',
  'ST MARY OF THE ASSUMPTION HS',
  'ST MARY OF THE ASSUMPTION SCHOOL',
  'ST MARY OF THE FALLS SCHOOL',
  'ST MARY OF THE HILLS SCHOOL',
  'ST MARY OF THE IMMACULATE CONCEPTION SCHOOL',
  'ST MARY OF THE LAKE SCHOOL',
  'ST MARY OF THE LAKES SCHOOL',
  'ST MARY OF THE PURIFICATION MONTESSORI SCHOOL',
  'ST MARY OF THE WOODS SCHOOL',
  'ST MARY OF-THE-KNOBS CATHOLIC SCHOOL',
  'ST MARY ON THE HILL SCHOOL',
  'ST MARY PARISH CATHOLIC SCHOOL',
  'ST MARY PARISH SCHOOL',
  'ST MARY S ACADEMY',
  'ST MARY S CATHOLIC SCHOOL',
  'ST MARY S HIGH SCHOOL',
  'ST MARY S RYKEN HIGH SCHOOL',
  'ST MARY S SCHOOL',
  'ST MARY SCHOOL',
  'ST MARY ST JOSEPH SCHOOL',
  'ST MARY STAR OF THE SEA SCHOOL',
  'ST MARY VISITATION SCHOOL',
  "ST MARY'S - METAMORA",
  "ST MARY'S ACADEMY",
  "ST MARY'S ASSUMPTION SCHOOL",
  "ST MARY'S CATHOLIC SCHOOL",
  "ST MARY'S CATHOLIC SCHOOL & PRESCHOOL",
  "ST MARY'S CATHOLIC SCHOOL - RICHMOND",
  "ST MARY'S CATHOLIC SCHOOL BELEN",
  "ST MARY'S CATHOLIC SCHOOL K-12",
  "ST MARY'S CENTRAL GRADE SCHOOL",
  "ST MARY'S CENTRAL SCHOOL",
  "ST MARY'S COLLEGE HIGH SCHOOL",
  "ST MARY'S ELEMENTARY SCHOOL",
  "ST MARY'S EPISCOPAL SCHOOL",
  "ST MARY'S GRADE SCHOOL",
  "ST MARY'S HALL",
  "ST MARY'S HIGH SCHOOL",
  "ST MARY'S INSTITUTE",
  "ST MARY'S MISSION SCHOOL",
  "ST MARY'S MONTESSORI SCHOOL",
  "ST MARY'S MONTESSORI SCHOOL - FONTANA",
  "ST MARY'S NATIVITY SCHOOL",
  "ST MARY'S OF HANNAH CATHOLIC SCHOOL",
  "ST MARY'S OF MEDFORD",
  "ST MARY'S PREP",
  "ST MARY'S PREPARATORY SCHOOL",
  "ST MARY'S PRIMARY SCHOOL",
  "ST MARY'S SCHOOL",
  'ST MARY-ALPHONSUS CATHOLIC SCHOOL',
  'ST MARY-BASHA CATHOLIC SCHOOL',
  'ST MARY/MCCORMICK CATHOLIC ACADEMY',
  'ST MARY/ST MICHAEL SCHOOL',
  'ST MARYS ACADEMY',
  'ST MARYS CATHEDRAL SCHOOL',
  'ST MARYS CATHOLIC ELEMENTARY SCHOOL',
  'ST MARYS CATHOLIC MIDDLE SCHOOL',
  'ST MARYS CATHOLIC SCHOOL',
  'ST MARYS CATHOLIC SCHOOLS',
  'ST MARYS CENTRAL CATHOLIC SCHOOL',
  'ST MARYS COLGAN JR/SR HIGH SCHOOL',
  'ST MARYS DOMINICAN HIGH SCHOOL',
  'ST MARYS ELEMENTARY SCHOOL',
  'ST MARYS EPISCOPAL DAY SCHOOL',
  'ST MARYS EPISCOPAL SCHOOL',
  'ST MARYS HIGH SCHOOL',
  'ST MARYS INSTITUTE',
  'ST MARYS OF KICKAPOO',
  'ST MARYS PARISH SCHOOL',
  'ST MARYS REGIONAL SCHOOL',
  'ST MARYS SCHOOL',
  'ST MARYS SCHOOL WAUKESHA CAMPUS',
  'ST MARYS SCHOOLS',
  'ST MARYS SPRINGS ACADEMY',
  'ST MATTHEW',
  'ST MATTHEW CATHOLIC SCHOOL',
  'ST MATTHEW ELEMENTARY SCHOOL',
  'ST MATTHEW LUTHERAN SCHOOL',
  'ST MATTHEW PARISH SCHOOL',
  'ST MATTHEW SCHOOL',
  'ST MATTHEW THE APOSTLE SCHOOL',
  "ST MATTHEW'S EARLY EDUCATION CENTER",
  "ST MATTHEW'S EPISCOPAL DAY SCHOOL",
  "ST MATTHEW'S EPISCOPAL SCHOOL",
  "ST MATTHEW'S PARISH SCHOOL",
  "ST MATTHEW'S PRESCHOOL",
  "ST MATTHEW'S SCHOOL",
  'ST MATTHEWS CATHOLIC SCHOOL',
  'ST MATTHEWS LUTHERAN SCHOOL',
  'ST MATTHEWS PRESCHOOL & KINDERGARTEN',
  'ST MATTHIAS CATHOLIC ACADEMY',
  'ST MATTHIAS ELEMENTARY SCHOOL',
  'ST MATTHIAS PARISH SCHOOL',
  'ST MATTHIAS SCHOOL',
  'ST MAXIMILIAN KOLBE',
  'ST MAXIMILIAN KOLBE PARISH SCHOOL',
  'ST MEL SCHOOL',
  'ST MICHAEL ACADEMY',
  'ST MICHAEL CATHOLIC HIGH SCHOOL',
  'ST MICHAEL CATHOLIC SCHOOL',
  'ST MICHAEL CATHOLIC SCHOOL - HOUSTON',
  'ST MICHAEL ELEMENTARY SCHOOL',
  'ST MICHAEL INDIAN SCHOOL',
  'ST MICHAEL LUTHERAN SCHOOL',
  'ST MICHAEL PARISH SCHOOL',
  'ST MICHAEL SCHOOL',
  'ST MICHAEL SPECIAL SCHOOL',
  'ST MICHAEL THE ARCHANGEL',
  'ST MICHAEL THE ARCHANGEL CATHOLIC HIGH SCHOOL',
  'ST MICHAEL THE ARCHANGEL CATHOLIC SCHOOL',
  'ST MICHAEL THE ARCHANGEL HIGH SCHOOL',
  'ST MICHAEL THE ARCHANGEL REGIONAL SCHOOL',
  'ST MICHAEL THE ARCHANGEL SCHOOL',
  "ST MICHAEL'S ACADEMY",
  "ST MICHAEL'S CATHOLIC ACADEMY",
  "ST MICHAEL'S CATHOLIC SCHOOL",
  "ST MICHAEL'S ELEMENTARY SCHOOL",
  "ST MICHAEL'S LEARNING ACADEMY",
  "ST MICHAEL'S PARISH DAY SCHOOL",
  "ST MICHAEL'S PREP SCHOOL",
  "ST MICHAEL'S SCHOOL",
  'ST MICHAEL-ST. CLEMENT SCHOOL',
  'ST MICHAEL-ST. GABRIEL ARCHANGELS CATHOLIC ELEME',
  'ST MICHAELS ACADEMY',
  'ST MICHAELS ASSOC FOR SP ED',
  'ST MICHAELS CATHOLIC SCHOOL',
  'ST MICHAELS CHRISTIAN ACADEMY',
  'ST MICHAELS COUNTRY DAY SCHOOL',
  'ST MICHAELS ELEMENTARY SCHOOL',
  'ST MICHAELS EPISCOPAL SCHOOL',
  'ST MICHAELS SCHOOL',
  'ST MICHAELS SCHOOL & NURSERY INC',
  'ST MICHEL ACADEMY',
  'ST MONICA ACADEMY',
  'ST MONICA CATHOLIC ACADEMY',
  'ST MONICA CATHOLIC ELEMENTARY SCHOOL',
  'ST MONICA CATHOLIC SCHOOL',
  'ST MONICA SCHOOL',
  'ST MONICA SCHOOL M',
  'ST NICHOLAS ACADEMY',
  'ST NICHOLAS CATHOLIC SCHOOL',
  'ST NICHOLAS ELEMENTARY SCHOOL',
  'ST NICHOLAS GREEK ORTHODOX ACADEMY',
  'ST NICHOLAS MONTESSORI SCHOOL',
  'ST NICHOLAS OF TOLENTINE ELEMENTARY SCHOOL',
  'ST NICHOLAS ORTHODOX ACADEMY',
  'ST NICHOLAS SCHOOL',
  'ST NICHOLAS TOLENTINE SCHOOL',
  'ST NICHOLAS UKRAINIAN CATHEDRAL SCHOOL',
  'ST NICHOLAS UKRAINIAN CATHOLIC SCHOOL',
  "ST NICHOLAS-ST MARY'S SCHOOL",
  'ST NORBERT ELEMENTARY SCHOOL',
  'ST NORBERT SCHOOL',
  'ST ODILIA SCHOOL',
  'ST ODILO ELEMENTARY SCHOOL',
  'ST OLAF CATHOLIC SCHOOL',
  'ST PANCRAS SCHOOL',
  'ST PANCRATIUS ELEMENTARY SCHOOL',
  'ST PASCAL BAYLON SCHOOL',
  'ST PASCHAL BAYLON SCHOOL',
  'ST PATRICIA ELEMENTARY SCHOOL',
  'ST PATRICK ACADEMY',
  'ST PATRICK CATHEDRAL SCHOOL',
  'ST PATRICK CATHOLIC HIGH SCHOOL',
  'ST PATRICK CATHOLIC SCHOOL',
  'ST PATRICK ELEMENTARY SCHOOL',
  'ST PATRICK HIGH SCHOOL',
  'ST PATRICK INTERPARISH SCHOOL',
  'ST PATRICK OF HEATHERDOWNS SCHOOL',
  'ST PATRICK SCHOOL',
  'ST PATRICK SCHOOL & EDUCATION CENTER',
  "ST PATRICK'S CATHOLIC SCHOOL",
  "ST PATRICK'S SCHOOL",
  'ST PATRICK-ST VINCENT HIGH SCHOOL',
  'ST PATRICKS CATHLOIC SCHOOL',
  'ST PATRICKS CATHOLIC SCHOOL',
  'ST PAUL ACADEMY - SUMMIT LOWER',
  'ST PAUL ACADEMY - SUMMIT SCHOOL',
  'ST PAUL CATHOLIC ELEMENTARY',
  'ST PAUL CATHOLIC GRADE SCHOOL',
  'ST PAUL CATHOLIC SCHOOL',
  'ST PAUL CATHOLIC SCHOOL - JACKSONVILLE BEACH',
  'ST PAUL CHRISTIAN ACADEMY',
  'ST PAUL CHRISTIAN DAYCARE & KINDERGARTEN',
  'ST PAUL CHRISTIAN EDUCATION CENTER',
  'ST PAUL CHRISTIAN SCHOOL',
  'ST PAUL ELEMENTARY',
  'ST PAUL ELEMENTARY SCHOOL',
  'ST PAUL EVANGELICAL LUTHERAN SCHOOL',
  'ST PAUL HIGH SCHOOL',
  'ST PAUL LUTHERAN',
  'ST PAUL LUTHERAN CHILD DEVELOPMENT CENTER',
  'ST PAUL LUTHERAN EARLY CHILDHOOD MINISTRY',
  'ST PAUL LUTHERAN HIGH SCHOOL',
  'ST PAUL LUTHERAN PRESCHOOL AND KINDERGARTEN',
  'ST PAUL LUTHERAN SCHOOL',
  'ST PAUL LUTHERAN SERBIN SCHOOL',
  'ST PAUL MISSION GRADE SCHOOL',
  'ST PAUL OF THE CROSS SCHOOL',
  'ST PAUL PARISH SCHOOL',
  'ST PAUL PAROCHIAL SCHOOL',
  'ST PAUL PREPARATORY SCHOOL',
  'ST PAUL SCHOOL',
  'ST PAUL THE APOSTLE CATHOLIC SCHOOL',
  'ST PAUL THE APOSTLE SCHOOL',
  "ST PAUL'S ACADEMY",
  "ST PAUL'S CATHOLIC ELEMENTARY SCHOOL-RIVERSIDE",
  "ST PAUL'S CHRISTIAN DAY CARE CENTER",
  "ST PAUL'S DAY SCHOOL & KINDERGARTEN",
  "ST PAUL'S EPISCOPAL SCHOOL",
  "ST PAUL'S EVANGELICAL LUTHERAN SCHOOL",
  "ST PAUL'S FIRST LUTHERAN SCHOOL",
  "ST PAUL'S LEARNING CENTER",
  "ST PAUL'S LUTHERAN SCHOOL",
  "ST PAUL'S PREPARATORY ACADEMY",
  "ST PAUL'S SCHOOL",
  'ST PAULS CATHOLIC SCHOOL',
  'ST PAULS CHOIR SCHOOL',
  'ST PAULS CHRISTIAN DAY SCHOOL',
  'ST PAULS DAY SCHOOL',
  'ST PAULS ELEMENTARY SCHOOL',
  'ST PAULS EPISCOPAL DAY SCHOOL',
  'ST PAULS EPISCOPAL MONTESSORI SCHOOL',
  'ST PAULS EPISCOPAL SCHOOL',
  'ST PAULS EVANGELICAL LUTHERAN SCHOOL',
  'ST PAULS LUTHERAN SCHOOL',
  'ST PAULS NURSERY & DAY SCHOOL',
  'ST PAULS SCHOOL',
  'ST PAULS UNITED CHURCH OF CHRIST SCHOOL',
  'ST PEREGRINE ACADEMY',
  'ST PERPETUA SCHOOL',
  'ST PETER & ST PAUL CATHOLIC SCHOOL',
  'ST PETER ACADEMY',
  'ST PETER CATHEDRAL',
  'ST PETER CATHEDRAL SCHOOL',
  'ST PETER CATHOLIC',
  'ST PETER CATHOLIC ACADEMY',
  'ST PETER CATHOLIC SCHOOL',
  'ST PETER CENTRAL CATHOLIC ELEMENTARY SCHOOL',
  'ST PETER CHANEL INTERPAROCHIAL SCHOOL',
  'ST PETER CLAVER',
  'ST PETER CLAVER CATHOLIC SCHOOL',
  'ST PETER CLAVER PRESCHOOL & KINDERGARTEN',
  'ST PETER ELEMENTARY SCHOOL',
  'ST PETER IN CHAINS SCHOOL',
  'ST PETER INTERPARISH CATHOLIC SCHOOL',
  'ST PETER LUTHERAN SCHOOL',
  'ST PETER MARTYR',
  'ST PETER OF ALCANTARA SCHOOL',
  'ST PETER S LUTHERAN SCHOOL',
  'ST PETER SCHOOL',
  'ST PETER THE APOSTLE SCHOOL',
  "ST PETER'S CATHOLIC SCHOOL",
  "ST PETER'S CLASSICAL SCHOOL",
  "ST PETER'S ELEMENTARY",
  "ST PETER'S EPISCOPAL DAY SCHOOL",
  "ST PETER'S EPISCOPAL MONTESSORI SCHOOL",
  "ST PETER'S EPISCOPAL PRESCHOOL & KINDERGARTEN",
  "ST PETER'S EPISCOPAL SCHOOL",
  "ST PETER'S EVANGELICAL LUTHERAN SCHOOL",
  "ST PETER'S HOME",
  "ST PETER'S LUTHERAN SCHOOL",
  "ST PETER'S REGIONAL SCHOOL",
  "ST PETER'S ROMAN CATHOLIC SCHOOL",
  "ST PETER'S SCHOOL",
  'ST PETER-IMMANUEL LUTHERAN SCHOOL',
  'ST PETER-MARIAN CATHOLIC JR-SR HIGH SCHOOL',
  'ST PETER/ST FRANCIS SCHOOL',
  'ST PETERS ACADEMY',
  'ST PETERS BOYS HIGH SCHOOL',
  'ST PETERS CATHOLIC SCHOOL',
  'ST PETERS CHRISTIAN DAY SCHOOL',
  'ST PETERS EARLY CHILDHOOD DEVELOPMENT CENTER',
  'ST PETERS ELEMENTARY SCHOOL',
  'ST PETERS EPISCOPAL SCHOOL',
  'ST PETERS LUTHERAN SCHOOL',
  'ST PETERS MEMORIAL SCHOOL',
  'ST PETERS MIDDLE SCHOOL',
  'ST PETERS PREPARATORY SCHOOL',
  'ST PETERS SCHOOL',
  'ST PETERSBURG CATHOLIC HIGH SCHOOL',
  'ST PETRONVILLE SCHOOL',
  'ST PHILIP & ST. AUGUSTINE CATHOLIC',
  'ST PHILIP CATHOLIC CENTRAL HIGH SCHOOL',
  'ST PHILIP LUTHERAN PRESCHOOL',
  'ST PHILIP LUTHERAN SCHOOL',
  'ST PHILIP NERI',
  'ST PHILIP NERI ELEMENTARY SCHOOL',
  'ST PHILIP NERI SCHOOL',
  'ST PHILIP PREPARATORY SCHOOL',
  'ST PHILIP SCHOOL',
  'ST PHILIP THE APOSTLE CATHOLIC SCHOOL',
  'ST PHILIP THE APOSTLE SCHOOL',
  "ST PHILIP'S EPISCOPAL SCHOOL",
  "ST PHILIP'S SCHOOL",
  'ST PHILIPS EPISCOPAL SCHOOL',
  'ST PHILIPS LUTHERAN SCHOOL',
  'ST PHILIPS SCHOOL',
  'ST PHILIPS SCHOOL & COMMUNITY CENTER',
  'ST PHILOMENA EARLY LEARNING CENTER',
  'ST PHILOMENA SCHOOL',
  'ST PHILOMENE ELEMENTARY SCHOOL',
  'ST PHILP NERI SCHOOL',
  'ST PIUS ELEMENTARY SCHOOL',
  'ST PIUS SCHOOL',
  'ST PIUS TENTH SCHOOL',
  'ST PIUS V CATHOLIC SCHOOL',
  'ST PIUS V SCHOOL',
  'ST PIUS X',
  'ST PIUS X CATHOLIC HIGH SCHOOL',
  'ST PIUS X CATHOLIC SCHOOL',
  'ST PIUS X CHURCH',
  'ST PIUS X CLASSICAL ACADEMY',
  'ST PIUS X ELEMENTARY SCHOOL',
  'ST PIUS X GRADE SCHOOL',
  'ST PIUS X HIGH SCHOOL',
  'ST PIUS X SCHOOL',
  'ST PIUS X/ST LEO ELEMENTARY SCHOOL',
  'ST POLYCARP CATHOLIC SCHOOL',
  'ST PRO REGIONAL CATHOLIC SCHOOL',
  'ST PROCOPIUS SCHOOL',
  'ST RAFAEL THE ARCHANGEL SCHOOL',
  'ST RAPHAEL ACADEMY',
  'ST RAPHAEL CATHOLIC SCHOOL',
  'ST RAPHAEL ELEMENTARY SCHOOL',
  'ST RAPHAEL SCHOOL',
  'ST RAPHAEL THE ARCHANGEL ELEMENTARY SCHOOL',
  'ST RAPHAEL THE ARCHANGEL SCHOOL',
  'ST RAYMOND ACADEMY FOR GIRLS',
  'ST RAYMOND ELEMENTARY SCHOOL',
  'ST RAYMOND HIGH SCHOOL FOR BOYS',
  'ST RAYMOND OF PENAFORT SCHOOL',
  'ST RAYMOND SCHOOL',
  "ST RAYMOND'S INDEPENDENCE MISSION SCHOOLS",
  'ST RICHARD CATHOLIC SCHOOL',
  'ST RICHARD SCHOOL',
  "ST RICHARD'S EPISCOPAL SCHOOL",
  'ST RITA CATHOLIC SCHOOL',
  'ST RITA ELEMENTARY SCHOOL',
  'ST RITA OF CASCIA HIGH SCHOOL',
  'ST RITA OF CASCIA SCHOOL',
  'ST RITA SCHOOL',
  'ST RITA SCHOOL FOR THE DEAF',
  "ST RITA'S SCHOOL",
  'ST ROBERT BELLARMINE CATHOLIC SCHOOL',
  'ST ROBERT BELLARMINE ELEMENTARY SCHOOL',
  'ST ROBERT CATHOLIC SCHOOL',
  'ST ROBERT SCHOOL',
  'ST ROCCO SCHOOL',
  'ST ROCH CATHOLIC SCHOOL',
  'ST ROMUALD INTERPAROCHIAL SCHOOL',
  'ST ROSALIA ACADEMY',
  'ST ROSALIE ELEMENTARY SCHOOL',
  'ST ROSE CATHOLIC SCHOOL',
  'ST ROSE ELEMENTARY SCHOOL',
  'ST ROSE GRAMMAR SCHOOL',
  'ST ROSE OF LIMA ACADEMY',
  'ST ROSE OF LIMA CATHLIC SCHOOL',
  'ST ROSE OF LIMA CATHOLIC ACADEMY',
  'ST ROSE OF LIMA CATHOLIC SCHOOL',
  'ST ROSE OF LIMA ELEMENTARY',
  'ST ROSE OF LIMA ELEMENTARY SCHOOL',
  'ST ROSE OF LIMA SCHOOL',
  'ST ROSE PHILIPPINE DUCHESNE',
  'ST ROSE PHILIPPINE DUCHESNE SCHOOL',
  'ST ROSE SCHOOL',
  "ST ROSE ST MARY'S SCHOOL",
  'ST ROSE-MCCARTHY CATHOLIC SCHOOL',
  'ST SABINA ACADEMY',
  'ST SAVA ACADEMY',
  'ST SAVA ORTHODOX SCHOOL',
  'ST SAVIOUR HIGH SCHOOL',
  'ST SCHOLASTICA ACADEMY',
  'ST SCHOLASTICA HSC ACADEMY',
  'ST SCHOLASTICA PARISH SCHOOL',
  'ST SEBASTIAN CATHOLIC SCHOOL',
  'ST SEBASTIAN REGIONAL SCHOOL',
  'ST SEBASTIAN SCHOOL',
  'ST SIMON',
  'ST SIMON STOCK SCHOOL',
  'ST SIMON THE APOSTLE SCHOOL',
  'ST SIMONS CHRISTIAN SCHOOL',
  'ST STANISLAUS CATHOLIC SCHOOL',
  'ST STANISLAUS ELEMENTARY SCHOOL',
  'ST STANISLAUS KOSTKA CATHOLIC ACADEMY',
  'ST STANISLAUS KOSTKA SCHOOL',
  'ST STANISLAUS SCHOOL',
  'ST STEPHEN CATHOLIC SCHOOL',
  'ST STEPHEN CHILD CARE & LEARNING CENTER',
  'ST STEPHEN ELEMENTARY SCHOOL',
  'ST STEPHEN LUTHERAN SCHOOL',
  'ST STEPHEN MARTYR SCHOOL',
  'ST STEPHEN OF HUNGARY SCHOOL',
  'ST STEPHEN PAROCHIAL SCHOOL',
  'ST STEPHEN PROTOMARTYR SCHOOL',
  'ST STEPHEN SCHOOL',
  'ST STEPHEN THE MARTYR SCHOOL',
  "ST STEPHEN'S ARMENIAN ELEMENTARY SCHOOL",
  "ST STEPHEN'S EPISCOPAL SCHOOL",
  "ST STEPHEN'S PRESCHOOL",
  'ST STEPHENS ACADEMY',
  'ST STEPHENS CLASSICAL CHRISTIAN ACADEMY',
  'ST STEPHENS EPISCOPAL SCHOOL',
  'ST STEPHENS LUTHERAN ACADEMY',
  'ST STEPHENS LUTHERAN ACADEMY NORTH',
  'ST STEPHENS LUTHERAN SCHOOL',
  'ST STEPHENS SCHOOL',
  'ST SUSANNA ELEMENTARY SCHOOL',
  'ST SYA ACADEMY II',
  'ST SYLVESTER ELEMENTARY SCHOOL',
  'ST TERESA CATHOLIC SCHOOL',
  'ST TERESA EARLY CHILDHOOD CENTER',
  'ST TERESA ELEMENTARY SCHOOL',
  'ST TERESA OF AVAILA CATHOLIC SCHOOL',
  'ST TERESA OF AVILA CATHOLIC SCHOOL',
  'ST TERESA OF AVILA SCHOOL',
  'ST TERESA OF CALCUTTA CATHOLIC SCHOOL',
  'ST TERESA REGIONAL SCHOOL',
  'ST TERESA SCHOOL',
  "ST TERESA'S ACADEMY",
  "ST TERESA'S SCHOOL",
  'ST THECLA ELEMENTARY SCHOOL',
  'ST THECLA SCHOOL',
  'ST THEODORE GUERIN HIGH SCHOOL',
  'ST THEODORE SCHOOL',
  'ST THEODORES HOLY FAMILY CATHOLIC SCHOOL',
  'ST THERESA CATHOLIC SCHOOL',
  'ST THERESA ELEMENTARY SCHOOL',
  'ST THERESA GRADE SCHOOL',
  'ST THERESA MIDDLE',
  'ST THERESA OF CALCETTA',
  'ST THERESA SCHOOL',
  'ST THERESAS CATHOLIC SCHOOL',
  'ST THERESE ACADEMY',
  'ST THERESE CARMELITE SCHOOL',
  'ST THERESE CATHOLIC ACADEMY',
  'ST THERESE CATHOLIC SCHOOL',
  'ST THERESE CHINESE CATHOLIC SCHOOL',
  'ST THERESE CLASSICAL ACADEMY',
  'ST THERESE OF JESUS SCHOOL',
  'ST THERESE SCHOOL',
  'ST THERESE SCHOOL-MUNHALL',
  "ST THERESE'S ACADEMY",
  'ST THOMAS ACADEMY',
  'ST THOMAS AQUINAS ACADEMY',
  'ST THOMAS AQUINAS CATHOLIC SCHOOL',
  'ST THOMAS AQUINAS ELEMENTARYSCHOOL',
  'ST THOMAS AQUINAS HIGH SCHOOL',
  'ST THOMAS AQUINAS HS',
  'ST THOMAS AQUINAS PARISH SCHOOL',
  'ST THOMAS AQUINAS REGIONAL CATHOLIC HIGH SCHOOL',
  'ST THOMAS AQUINAS REGIONAL SCHOOL',
  'ST THOMAS AQUINAS SCHOOL',
  'ST THOMAS CATHOLIC ELEMENTARY SCHOOL',
  'ST THOMAS CHOIR SCHOOL',
  'ST THOMAS CONSOLIDATED SCHOOL',
  'ST THOMAS EARLY LEARNING CENTER',
  'ST THOMAS EPISCOPAL PARISH SCHOOL',
  'ST THOMAS EPISCOPAL SCHOOL',
  'ST THOMAS HIGH SCHOOL',
  'ST THOMAS MORE ACADEMY',
  'ST THOMAS MORE CATHOLIC',
  'ST THOMAS MORE CATHOLIC SCHOOL',
  'ST THOMAS MORE ELEMENTARY SCHOOL',
  'ST THOMAS MORE HIGH SCHOOL',
  'ST THOMAS MORE PARISH SCHOOL',
  'ST THOMAS MORE SCHOOL',
  'ST THOMAS OF CANTERBURY SCHOOL',
  'ST THOMAS OF VILLANOVA SCHOOL',
  'ST THOMAS REGIONAL SCHOOL',
  'ST THOMAS SCHOOL',
  'ST THOMAS THE APOSTLE',
  'ST THOMAS THE APOSTLE CATHOLIC SCHOOL',
  'ST THOMAS THE APOSTLE ELEMENTARY SCHOOL',
  'ST THOMAS THE APOSTLE EPISCOPAL SCHOOL',
  'ST THOMAS THE APOSTLE SCHOOL',
  "ST THOMAS'S DAY SCHOOL",
  'ST TIMOTHY CHRISTIAN ACADEMY',
  'ST TIMOTHY MIDDLE SCHOOL',
  'ST TIMOTHY PARISH SCHOOL',
  'ST TIMOTHY SCHOOL',
  "ST TIMOTHY'S CHRISTIAN ACADEMY",
  "ST TIMOTHY'S EPISCOPAL DAY SCHOOL",
  'ST TIMOTHYS PARISH SCHOOL',
  'ST TIMOTHYS SCHOOL',
  'ST TURIBIUS SCHOOL',
  'ST URSULA ACADEMY',
  'ST URSULA SCHOOL',
  'ST URSULA VILLA SCHOOL',
  'ST VALENTINE CATHOLIC ELEMENTARY SCHOOL',
  'ST VERONICA CATHOLIC SCHOOL',
  'ST VERONICA ELEMENTARY SCHOOL',
  'ST VERONICA/INDEPENDENCE MISSION SCHOOL',
  'ST VIATOR CATHOLIC SCHOOL',
  'ST VIATOR ELEMENTARY SCHOOL',
  'ST VIATOR HIGH SCHOOL',
  'ST VICTOR ELEMENTARY SCHOOL',
  'ST VICTOR SCHOOL',
  'ST VINCENT ACADEMY',
  'ST VINCENT DE PAUL',
  'ST VINCENT DE PAUL CATHOLIC ELEMENTARY SCHOOL',
  'ST VINCENT DE PAUL CATHOLIC SCHOOL',
  'ST VINCENT DE PAUL CATHOLIC SCHOOLS',
  'ST VINCENT DE PAUL ELEMENTARY SCHOOL',
  'ST VINCENT DE PAUL HIGH SCHOOL',
  'ST VINCENT DE PAUL PARISH SCHOOL',
  'ST VINCENT DE PAUL SCHOOL',
  'ST VINCENT DEPAUL',
  'ST VINCENT DEPAUL ELEMENTARY SCHOOL',
  'ST VINCENT DEPAUL SCHOOL',
  'ST VINCENT FAMILY CENTERS ELEMENTARY',
  'ST VINCENT FERRER ELEMENTARY SCHOOL',
  'ST VINCENT FERRER HIGH SCHOOL',
  'ST VINCENT FERRER SCHOOL',
  'ST VINCENT MARTYR SCHOOL',
  'ST VINCENT PALLOTTI HIGH SCHOOL',
  'ST VINCENT PALLOTTI SCHOOL',
  'ST VINCENT SCHOOL',
  "ST VINCENT'S DAY HOME",
  "ST VINCENT'S SPECIAL NEEDS SERVICES",
  'ST VINCENT-ST MARY HIGH SCHOOL',
  'ST VINCENTS ACADEMY',
  'ST VITUS SCHOOL',
  'ST VIVIAN SCHOOL',
  'ST WALTER SCHOOL',
  'ST WENCESLAUS',
  'ST WENCESLAUS ELEMENTARY SCHOOL',
  'ST WENCESLAUS SCHOOL',
  'ST WENDEL CATHOLIC SCHOOL',
  'ST WENDELIN CATHOLIC SCHOOL',
  'ST WENDELIN SCHOOL',
  'ST WILLIAM CATHOLIC SCHOOL',
  'ST WILLIAM OF YORK CATHOLIC SCHOOL',
  'ST WILLIAM SCHOOL',
  'ST XAVIER CATHOLIC SCHOOL',
  'ST XAVIER HIGH SCHOOL',
  'ST ZACHARY ELEMENTARY SCHOOL',
  'ST, JOSEPH ST. ROBERT SCHOOL',
  'STAFFORD ACADEMY',
  'STAGECOACH RUN',
  'STAGECOACH RUN SCHOOL',
  'STAHLVILLE SCHOOL',
  'STANBRIDGE ACADEMY',
  'STAND BY ME, LLC',
  'STANDARD CHRISTIAN SCHOOL',
  'STANDIFER GAP SDA SCHOOL',
  'STANDING ROCK HIGH SCHOOL',
  'STANFORD EISENBERG KNOXVILLE JEWISH DAY SCHOOL',
  'STANFORD ONLINE HIGH SCHOOL',
  'STANISLAUS ACADEMY',
  'STANLEY BRITISH PRIMARY SCHOOL',
  'STANLEY G FALK SCHOOL',
  'STANLEY SEVENTH-DAY ADVENTIST SCHOOL',
  'STANSBURY ACADEMY',
  'STAR ACADEMY',
  'STAR BABIES LEARNING CENTER',
  'STAR CHRISTIAN ACADEMY',
  'STAR CHRISTIAN SCHOOL',
  'STAR MONTESSORI SCHOOL',
  'STAR OF THE SEA SCHOOL',
  'STAR POINTE ACADEMY',
  'STAR PREP ACADEMY',
  'STARBRIGHT EARLY LEARNING CENTER',
  'STARBRIGHT SCHOOL',
  'STARCHILD ACADEMY',
  'STARCHILD ACADEMY LAKE MARY',
  'STARCHILD ACADEMY WINTER GARDEN',
  'STARKE CHRISTIAN SCHOOL',
  'STARKVILLE ACADEMY',
  'STARKVILLE CHRISTIAN SCHOOL',
  'STARLITE CHILD DEVELOPMENT CENTER',
  'STARMARKER SCHOOL EARLY EDUCATION WYILDWOOD',
  'STARPOINT SCHOOL',
  'STARR ACADEMY',
  'STARS HIGH SCHOOL',
  'STARS LEARNING CENTER',
  'STARS MONTESSORI ACADEMY',
  'STARS OF THE WORLD 2',
  'STARTING GATE SCHOOL',
  'STARWOOD MONTESSORI SCHOOL',
  'STATE COLLEGE FRIENDS SCHOOL',
  'STATE LINE AMISH',
  'STATE LINE CHRISTIAN SCHOOL',
  'STATESVILLE CHRISTIAN SCHOOL',
  'STATESVILLE MONTESSORI SCHOOL',
  'STAUNTON MONTESSORI SCHOOL',
  "STE JEANNE D'ARC ELEMENTARY SCHOOL",
  'STEAM VALLEY',
  'STEAMBOAT MOUNTAIN SCHOOL',
  'STEELVILLE SCHOOL',
  'STEEPLE ON THE BEACH MONTESSORI SCHOOL',
  'STEIN EDUCATION CENTER',
  'STEIN YESHIVA OF LINCOLN PARK',
  'STELLA K. ABRAHAM H.S. FOR GIRLS',
  'STELLA LEARNING CENTER',
  'STELLA MARIS ACADAMY',
  'STELLA MARIS ACADEMY',
  'STELLA MARIS ACADEMY ST JOHNS CAMPUS',
  'STELLAR ACADEMY',
  'STEM 3 ACADEMY',
  'STEM STEPS',
  'STEP AHEAD KINDERGARTEN',
  'STEP BY STEP CHRISTIAN SCHOOL',
  'STEP BY STEP MONTESSORI',
  'STEP BY STEP MONTESSORI SCHOOL',
  'STEP BY STEP MONTESSORI SCHOOL OF CORCORAN',
  'STEP BY STEP MONTESSORI-MAPLE GROVE',
  'STEP OF FAITH CHRISTIAN ACADEMY',
  'STEPHEN GAYNOR SCHOOL',
  'STEPHEN T BADIN HIGH SCHOOL',
  'STEPHENVILLE CHRISTIAN SCHOOL',
  'STEPPEN STONE ACADEMY',
  'STEPPING STONE CHRISTIAN SCHOOL',
  'STEPPING STONE LEARNING CENTER',
  'STEPPING STONE MONTESSORI',
  'STEPPING STONE OF SOUTHEASTERN QUEEN SCHOOL',
  'STEPPING STONE SCHOOL',
  'STEPPING STONES',
  'STEPPING STONES ACADEMY',
  'STEPPING STONES EDUCATIONAL THERAPY CENTER',
  'STEPPING STONES LEARNING ACADEMY',
  'STEPPING STONES MONTESSORI',
  'STEPPING STONES MONTESSORI SCHOOL',
  'STEPPING STONES PREPRETRY ACADENY',
  'STEPPING STONES SCHOOL',
  'STEPPING STONES SCHOOL INC.',
  'STEPPINGSTONE MONTESSORI SCHOOL',
  'STEPPINGSTONE SCHOOL',
  'STEPS FOR LEARNING PRESCHOOL CENTER',
  'STERLING ACADEMY',
  'STERLING CHRISTIAN ACADEMY',
  'STERLING EAST OAKLAND CAMPUS',
  'STERLING EAST-CHESAPEAKE CAMPUS',
  'STERLING EDUCATION',
  'STERLING EDUCATION EAST REGION',
  'STERLING EDUCATION-NEW ENGLAND-BOSTON',
  'STERLING NORTH - KALISPELL CAMPUS',
  'STERLING SOUTH - INDIANAPOLIS CAMPUS',
  'STERLING WEST',
  'STERLING WEST CHICAGO CAMPUS',
  'STERLING WEST GOTHENBURG CAMPUS',
  'STERLING WEST LOS ANGELES',
  'STERLING WEST SAN FRANCISCO CAMPUS',
  'STERLING WEST SEATTLE CAMPUS',
  'STERNE SCHOOL',
  'STETSON BAPTIST CHRISTIAN SCHOOL',
  'STEVENS CHILDRENS HOME',
  'STEVENS COOPERATIVE SCHOOL',
  'STEVENS POINT CHRISTIAN ACADEMY',
  'STEVENSON SCHOOL - PEBBLE BEACH CAMPUS',
  'STEVENSON SCHOOL CARMEL CAMPUS',
  'STEWART HOME SCHOOL',
  'STIE Perbanas',
  'STILL CREEK CHRISTIAN ACADEMY',
  'STILL WATERS LEARNING CENTER',
  'STILLPOINT SCHOOL',
  'STILLWATER ACADEMY',
  'STILLWATER CHRISTIAN SCHOOL',
  'STILLWATER MONTESSORI SCHOOL',
  'STILLWELL AVENUE PREP',
  'STILLWELL COUNTRY SCHOOL',
  'STJOHN PAUL II CATHOLIC SCHOOL',
  'STMIK AMIKOM Yogyakarta',
  'STMIK Sinar Nusantara',
  'STOCKBRIDGE METHODIST PRESCHOOL & KINDERGARTEN',
  'STOCKDALE CHRISTIAN SCHOOLS',
  'STOCKTON ACCELERATED INSTITUTE',
  'STOCKTON BAPTIST SCHOOL',
  'STOKES ACADEMY',
  'STONE BRIDGE SCHOOL',
  'STONE CITY CHRISTIAN ACADEMY',
  'STONE HILL',
  'STONE RIDGE CHRISTIAN ELEMENTARY',
  'STONE RIDGE CHRISTIAN SCHOOL',
  'STONE RIDGE CHRISTIAN SCHOOLS',
  'STONE RIDGE MONTESSORI',
  'STONE RIDGE SCHOOL OF THE SACRED HEART',
  'STONEBORO WESLEYAN SCHOOL',
  'STONEBRIDGE ACADEMY',
  'STONEBRIDGE CHRISTIAN ACADEMY',
  'STONEGATE CHRISTIAN ACADEMY',
  'STONEHILL CHRISTIAN ACADEMY',
  'STONELEIGH-BURNHAM SCHOOL',
  'STONEWOOD SCHOOL',
  'STONEY ACRES PAROCHIAL SCHOOL',
  'STONEY ACRES SCHOOL',
  'STONEY CREEK',
  'STONEY CREEK MONTESSORI',
  'STONEY CREEK SCHOOL',
  'STONEY MEADOW',
  'STONEY RUN AMISH SCHOOL',
  'STONEYBROOKE CHRISTIAN SCHOOLS-SAN JUAN CAPISTRANO',
  'STONY FLAT AMISH SCHOOL',
  'STONY FORK MENNONITE SCHOOL',
  'STONY HILL SCHOOL',
  'STONY MEADOW SCHOOL',
  'STONY POINT CHRISTIAN ACADEMY',
  'STONY POINT SCHOOL',
  'STONYBROOK PAROCHIAL SCHOOL',
  'STONYBROOK SCHOOL',
  'STOREFRONT ACADEMY HARLEM',
  'STORYBOOK HOLLOW MONTESSORI',
  'STORYBOOK PRESCHOOL',
  'STRACKS DAM SCHOOL',
  'STRAFFORD LEARNING CTR - JOHN POWERS SCHOOL',
  'STRAKE JESUIT COLLEGE PREP SCHOOL',
  'STRANG SCHOOL/RANCH HOPE',
  'STRATEGIC ACADEMY',
  'STRATFORD ACADEMY',
  'STRATFORD FRIENDS SCHOOL',
  'STRATFORD MIDDLE SCHOOL',
  'STRATFORD PRESCHOOL',
  'STRATFORD SCHOOL',
  'STRATFORD SCHOOL - FREMONT CURTIS CAMPUS',
  'STRATFORD SCHOOL - MILPITAS',
  'STRATFORD SCHOOL - PALO ALTO',
  'STRATFORD SCHOOL - SAN FRANCISCO',
  'STRATFORD SCHOOL - SUNNYVALE WASHINGTON PARK',
  'STRATFORD SCHOOL- ALTADENA ALLEN',
  'STRATTON MOUNTAIN SCHOOL',
  'STREAMWOOD MONTESSORI',
  'STREET CAR RUN',
  'STRIAR HEBREW ACADEMY',
  'STRIDER ACADEMY',
  'STRONG ROCK CHRISTIAN SCHOOL',
  'STRONGSVILLE MONTESSORI',
  'STS JOACHIM & ANN SCHOOL',
  'STS LEO-SETON CATHOLIC SCHOOL',
  'STS PETER & PAUL',
  'STS PETER & PAUL ACADEMY',
  'STS PETER & PAUL CATHOLIC ELEMENTARY SCHOOL',
  'STS PETER & PAUL CATHOLIC SCHOOL',
  'STS PETER & PAUL SCHOOL',
  'STS PHILIP & JAMES SCHOOL',
  'STS PHILIP AND JAMES ELEMENTARY',
  'STS SIMON & JUDE SCHOOL',
  'STS. PETER & PAUL SCHOOL',
  'STUART G FERST SCHOOL',
  'STUART HALL FOR BOYS',
  'STUART HALL HIGH SCHOOL',
  'STUART HALL SCHOOL',
  'STUDY CIRCLE PRESCHOOL & KINDERGARTEN',
  'STURGIS CHRISTIAN SCHOOL',
  'SU ESCUELA LANGUAGE ACADEMY',
  'SUBIACO ACADEMY',
  'SUBURBAN CHRISTIAN SCHOOL',
  'SUBURBAN SCHOOL',
  'SUCCESS UNLIMITED ACADEMY',
  'SUCCESSFUL BEGINNINGS LEARNING CENTER INC',
  'SUDBURY VALLEY SCHOOL',
  'SUFFOLK CHRISTIAN ACADEMY',
  'SUGAR & SPICE PRESCHOOL',
  'SUGAR BOWL ACADEMY',
  'SUGAR BUSH',
  'SUGAR CREEK MONTESSORI SCHOOL',
  'SUGAR CREEK SCHOOL',
  'SUGAR GROVE SCHOOL',
  'SUGAR HILL CHRISTIAN ACADEMY',
  'SUGAR HOLLOW SCHOOL',
  'SUGARLOAF MONTESSORI SCHOOL',
  'SULAM SCHOOL',
  'SULLINS ACADEMY',
  'SUMMER MOUNTAIN SCHOOL',
  'SUMMERHILL PAROCHIAL SCHOOL',
  'SUMMERS-KNOLL SCHOOL',
  'SUMMERSVILLE MENNONITE SCHOOL',
  'SUMMERSVILLE SDA SCHOOL',
  'SUMMERVILLE CATHOLIC SCHOOL',
  'SUMMIT ACADEMY',
  'SUMMIT ACADEMY OF GREATER LOUISVILLE',
  'SUMMIT ACADEMY OF SOUTHWEST OHIO',
  'SUMMIT CHRISTIAN ACADEMY',
  'SUMMIT CHRISTIAN SCHOOL',
  'SUMMIT CLASSICAL CHRISTIAN SCHOOL',
  'SUMMIT HILL SCHOOL',
  'SUMMIT MILLS PAROCHIAL SCHOOL',
  'SUMMIT MONTESSORI SCHOOL',
  'SUMMIT PREPARATORY SCHOOL',
  'SUMMIT QUESTA MONTESSORI SCHOOL',
  'SUMMIT RIDGE CHRISTIAN SCHOOL',
  'SUMMIT SCHOOL',
  'SUMMIT SCHOOL INC',
  'SUMMIT SCHOOL OF AHWATUKEE',
  'SUMMIT SCHOOL OF THE POCONOS',
  'SUMMIT SCHOOLS',
  'SUMMIT VALLEY',
  'SUMMITVIEW',
  'SUMMITVIEW CHRISTIAN SCHOOL',
  'SUMRALL BAPTIST KINDERGARTEN',
  'SUN GROVE MONTESSORI SCHOOL',
  'SUNBEAM SPECIAL SCHOOL',
  'SUNBEAMS LUTHERAN SCHOOL',
  'SUNBURY CHRISTIAN ACADEMY',
  'SUNCOAST CHRISTIAN ACADEMY',
  'SUNCOAST PRIMARY SCHOOL',
  'SUNCOAST WALDORF SCHOOL',
  'SUNDAI MICHIGAN INTERNATIONAL ACADEMY',
  'SUNDANCE MONTESSORI SCHOOL',
  'SUNDANCE SCHOOL',
  'SUNDROPS MONTESSORI SCHOOL',
  'SUNFIELD FARM & WALDORF SCHOOL',
  'SUNFLOWER MONTESSORI',
  'SUNFLOWER MONTESSORI SCHOOL',
  'SUNFLOWER PERSCHOOL & KINDERGARTEN.',
  'SUNFLOWER PRESCHOOL',
  'SUNFLOWER PRIVATE SCHOOL',
  'SUNFLOWERS ACADEMY',
  'SUNG STAR ACADEMY & LEARNING CENTER',
  'SUNLIGHT CHRISTIAN ACADEMY',
  'SUNNY ACRES',
  'SUNNY BEAR ACADEMY',
  'SUNNY CREST SCHOOL',
  'SUNNY HAVEN MENNONITE SCHOOL',
  'SUNNY HOLLOW MONTESSORI',
  'SUNNY LOVE SCHOOL/IVAN KING',
  'SUNNY MEADOW',
  'SUNNY MEADOW AMISH SCHOOL',
  'SUNNY MEADOW PAROCHIAL SCHOOL',
  'SUNNY MEADOW SCHOOL',
  'SUNNY MEADOWS',
  'SUNNY MEADOWS SCHOOL',
  'SUNNY PLAINS CHRISTIAN SCHOOL',
  'SUNNY RIDGE SCHOOL',
  'SUNNY SIDE SCHOOL',
  'SUNNY SLOPE',
  'SUNNY SLOPE AMISH PAROCH SCHOOL',
  'SUNNY SLOPE C/O ERVIN MAST',
  'SUNNY SLOPE SCHOOL',
  'SUNNY SPOT SCHOOL',
  'SUNNY VIEW',
  'SUNNY VIEW MENNONITE SCHOOL',
  'SUNNY VIEW SCHOOL',
  'SUNNYBROOK DAY SCHOOL',
  'SUNNYBURN SCHOOL',
  'SUNNYDALE ADVENTIST ACADEMY',
  'SUNNYDALE SDA ELEMENTARY SCHOOL',
  'SUNNYSIDE CHRISTIAN SCHOOL',
  'SUNNYSIDE MENNONITE SCHOOL',
  'SUNNYSIDE MONTESSORI HOUSE OF CHILDREN',
  'SUNNYSIDE SCHOOL',
  'SUNNYVALE CHRISTIAN SCHOOL',
  'SUNNYVALLEY PAROCHIAL SCHOOL',
  'SUNNYVIEW SCHOOL',
  'SUNRISE ACADEMY',
  'SUNRISE ACADEMY OF EXCELLENCE',
  'SUNRISE MONTESSORI ACADEMY',
  'SUNRISE MONTESSORI OF NAPA VALLEY',
  'SUNRISE MONTESSORI SCHOOL',
  'SUNRISE MONTESSORI SCHOOL OF ROSEVILLE, INC.',
  'SUNRISE SCHOOL',
  'SUNRISE SCHOOL AMISH',
  'SUNRISE SCHOOL OF MIAMI',
  'SUNRISE VIEW C/O NAOMI RABER',
  'SUNSET CHRISTIAN PREPARATORY',
  'SUNSET HILLS MONTESSORI SCHOOL',
  'SUNSET MESA SCHOOLS',
  'SUNSET MONTESSORI COMMUNITY',
  'SUNSET MONTESSORI SCHOOL',
  'SUNSET PREPARATORY SCHOOL',
  'SUNSET RUN PAROCHIAL SCHOOL',
  'SUNSET VALLEY SCHOOL',
  'SUNSET VIEW AMISH PAROCHIAL',
  'SUNSET VIEW C/O MOSE HERSHBERGER',
  'SUNSET VIEW SCHOOL',
  'SUNSHINE ACADEMY',
  'SUNSHINE ALTERNATIVE EDUCATION CENTER',
  'SUNSHINE BIBLE ACADEMY',
  'SUNSHINE CHILDCARE & PRESCHOOL CENTER',
  'SUNSHINE COTTAGE SCHOOL FOR DEAF CHILDREN',
  'SUNSHINE EDUCATIONAL CENTER',
  'SUNSHINE LEARNING MIAMI',
  'SUNSHINE MONTESSORI',
  'SUNSHINE MONTESSORI PRES & CHILD CARE CENTER',
  'SUNSHINE MONTESSORI SCHOOL',
  'SUNSHINE SCHOOL',
  'SUNSHINE STATE ACADEMY',
  'SUNSTONE MONTESSORI SCHOOL',
  'SUNTREE MONTESSORI',
  'SUNY Broome Community College',
  'SUNY Maritime College',
  'SUNY Westchester Community College',
  'SUPER LEARNING CTR S FAITH CHRISTIAN ACADEMY',
  'SUPLEE SCHOOL/CHRISTIAN STOLTZFUS',
  'SUPSI - University of Applied Sciences Southern Switzerland',
  'SURF CITY CHRISTIAN CHILD DEVELOPMENT PRESCHOOL',
  'SUSAN ODELL TAYLOR SCHOOL FOR CHILDREN',
  'SUSAN WAYNE CENTER OF EXCELLENCE',
  'SUSANNA WESLEY EARLY EDUCATION MINISTRY',
  'SUSANVILLE ADVENTIST CHRISTIAN SCHOOL',
  'SUSQUEHANNA BIBLE ACADEMY',
  'SUSQUEHANNA WALDORF SCHOOL',
  'SUSSEX CHRISTIAN SCHOOL',
  'SUSSEX SCHOOL',
  'SUTHERLIN ADVENTIST CHRISTIAN SCHOOL',
  'SUWANNEE RIVER RIDGE TECHNICAL HIGH SCHOOL',
  'SWALLOWTAIL SCHOOL',
  'SWAN SCHOOL',
  'SWANN SPECIAL CARE CTR-ELEM/SECONDARY SCHOOL',
  'SWANNANOA VALLEY MONTESSORI SCHOOL',
  'SWANSEA WOOD SCHOOL',
  'SWANTON MENNONITE FELLOWSHIP SCHOOL',
  'SWEENY CHRISTIAN SCHOOL',
  'SWEET AND SMART MONTESSORI SCHOOL',
  'SWEET PEAS VILLAGE EARLY CHILDHOOD SCHOOL',
  'SWEETHAVEN CHRISTIAN ACADEMY',
  'SWEETWATER EPISCOPAL ACADEMY',
  'SWIFT CREEK MENNONITE SCHOOL',
  'SWISS MEADOW SCHOOL',
  'SWISS VALLEY SCHOOL',
  'SWITZER LEARNING CENTER',
  'SYCAMORE ACADEMY',
  'SYCAMORE HOLLOW SCHOOL',
  'SYCAMORE SCHOOL',
  'SYLVA BAY ACADEMY',
  'SYMPHONY SEVEN SCHOOL OF ARTS AND TECH',
  'SYNAPSE SCHOOL',
  "SYNERGY CHILDREN'S CENTER",
  'SYNERGY LEARNING ACADEMY',
  'SYNERGY SCHOOL',
  'SYRACUSE HEBREW DAY SCHOOL',
  'Saad College of Nursing and Allied Health Sciences',
  'Saba University',
  'Sabal Elementary School',
  'Sabal Palm Elementary School',
  'Sabal Point Elementary School',
  'Sabana Llana',
  'Sabanci University',
  'Sabanetas Mani',
  'Sabaragamuwa University of Sri Lanka',
  'Sabathani Campus',
  'Sabattus Primary School',
  'Sabetha Elementary School',
  'Sabetha High School',
  'Sabetha Middle School',
  'Sabillasville Elementary',
  'Sabin Elem Magnet School',
  'Sabin Elementary School',
  'Sabin Middle School',
  'Sabin World School',
  'Sabina Elementary School',
  'Sabinal El',
  'Sabinal H S',
  'Sabinal Middle',
  'Sabine El',
  'Sabine H S',
  'Sabine Isd Daep',
  'Sabine Middle',
  'Sabine Pass School',
  'Sabino High School',
  'Sabino Rivera Berrios',
  'Sabis International',
  'Sabis International Charter School',
  'Sabish Middle',
  'Sable Elementary School',
  'Sabold El Sch',
  'Sabzevar School of Medical Sciences',
  'Sabzevar Teacher Training University',
  'Saca Online',
  'Sacajawea Elementary',
  'Sacajawea Elementary School',
  'Sacajawea Junior High School',
  'Sacajawea Middle School',
  'Sacajawea School',
  'Sacandaga School',
  'Sacaton Elementary',
  'Sacaton Middle School',
  'Saccarappa School',
  'Sachem High School East',
  'Sachem High School North',
  'Sachse H S',
  'Sackets Harbor Central School',
  'Saco 7-8',
  'Saco High School',
  'Saco Middle School',
  'Saco School',
  'Sacopee Valley Elementary School',
  'Sacopee Valley High Sch',
  'Sacopee Valley Middle School',
  'Sacramento Charter High',
  'Sacramento City College',
  'Sacramento County Ed Special Education',
  'Sacramento County Rop',
  'Sacramento County Sh Special Education',
  'Sacramento Elementary School',
  'Sacramento Valley Charter',
  'Sacred Heart Hospital',
  'Sacred Heart University',
  'Sad #53 Alternative Education',
  'Sadat Academy for Management Sciences',
  'Sadat Institute of Higher Education',
  'Saddle Brook Middle/high School',
  'Saddle Mountain Elementary',
  'Saddle Peak Elementary',
  'Saddle Ranch Elementary School',
  'Saddle Ridge Elementary',
  'Saddle Ridge Elementary And Middle School',
  'Saddle Rock School',
  'Saddleback College',
  'Saddleback Elementary School',
  'Saddleback High',
  'Saddleback Preschool',
  'Saddlebrook Elementary School',
  'Saddlewood Elementary School',
  'Sadie Halstead Middle School',
  'Sadie T. Tillis Elementary School',
  'Sadjad Institute of Technology',
  'Sadler Arts Academy',
  'Sadler Elementary',
  'Sadler Means Ywla',
  'Sadra University',
  'Saeger Middle',
  'Saegert El',
  'Saegertown El Sch',
  'Saegertown Jshs',
  'Saenz El',
  'Safe',
  'Safe Harbor Academy',
  'Safe School Program',
  'Safe School Program Centralia',
  'Safety Harbor Elementary School',
  'Safety Harbor Middle School',
  'Saffell Street Elementary School',
  'Safford Elementary School',
  'Safford High School',
  'Safford K-8 School',
  'Safford Middle School',
  'Sag Harbor Elementary School',
  'Saga Medical School',
  'Saga University',
  "Sagami Women's University",
  "Sagamore Children's Psychiatric Center",
  'Sagamore Hill El',
  'Sagamore Hills Elementary School',
  'Sagamore Middle School',
  'Sagaponack School',
  'Sage Academy',
  'Sage Academy Charter School',
  'Sage Canyon',
  'Sage Canyon Elementary',
  'Sage Colleges',
  'Sage Community School',
  'Sage Creek Elementary',
  'Sage Creek High',
  'Sage Creek School',
  'Sage Crest Elementary',
  'Sage Elementary',
  'Sage Elementary School',
  'Sage Hills High School',
  'Sage Hills Open Doors',
  'Sage Hills School',
  'Sage International School Of Boise',
  'Sage Montessori Charter School',
  'Sage Park Middle School',
  'Sage Point Elementary School',
  'Sage Valley Junior High School',
  'Sage Valley Middle School',
  'Sagebrush Elementary',
  'Sagebrush Elementary School',
  'Sageland El',
  'Sageville Elementary School',
  'Sagewood Elementary',
  'Sagewood Elementary School',
  'Sagewood Middle School',
  'Saghalie Middle School',
  'Saginaw Arts And Sciences Academy',
  'Saginaw Chippewa Tribal College',
  'Saginaw Correctional Facility',
  'Saginaw County Juvenile Center',
  'Saginaw Covenant Academy',
  'Saginaw El',
  'Saginaw H S',
  'Saginaw High School',
  'Saginaw Isd Transitions Center',
  'Saginaw Learn To Earn Academy',
  'Saginaw Preparatory Academy',
  'Saginaw Valley State University',
  'Sagle Elementary School',
  'Saguaro Elementary School',
  'Saguaro High School',
  'Sahand University of Technology',
  'Sahmyook University',
  'Sahuarita High School',
  'Sahuarita Intermediate School',
  'Sahuarita Middle School',
  'Sahuarita Primary School',
  'Sahuaro High School',
  'Sahuaro Ranch Elementary School',
  'Sahuaro School',
  'Sai Camp Cassidy Lake',
  'Saigling El',
  'Saigon University',
  'Sail',
  'Sail Charter Academy - School For Arts-infused Learning',
  'Sail Program',
  'Sail Skills For Adult Independent Living',
  'Sailorway Middle School',
  'Saint Albans High School',
  'Saint Anselm College',
  'Saint Charles Middle School',
  'Saint Clair Area El/ms',
  'Saint Clair Co Roe Safe School',
  'Saint Clair County High School',
  'Saint Clair Evans Academy',
  'Saint Cloud Math And Science Academ',
  'Saint Cloud State University',
  'Saint Croix Academy Of Virtual Education',
  'Saint Croix Central Elementary',
  'Saint Croix Central High',
  'Saint Croix Central Middle',
  'Saint Croix Falls Elementary',
  'Saint Croix Falls High',
  'Saint Croix Falls Middle',
  "Saint Edward's University",
  'Saint Elizabeth College of Nursing',
  'Saint Elmo Elementary School',
  'Saint Francis College',
  'Saint Francis High',
  "Saint George's Hospital Medical School University of London",
  'Saint Helena Elementary',
  'Saint Helena High',
  'Saint Helena Primary',
  'Saint Helens Elementary',
  'Saint James Elementary School',
  'Saint Jo El',
  'Saint Jo H S',
  'Saint John Elementary School',
  'Saint John High School',
  "Saint John's University (MN)",
  "Saint John's University (NY)",
  'Saint Joseph Central School',
  "Saint Joseph's Children's Home",
  "Saint Joseph's College (IN)",
  "Saint Joseph's Elementary School",
  "Saint Joseph's University",
  'Saint Lawrence Children & Youth Services',
  'Saint Lawrence Elementary School',
  'Saint Lawrence High School',
  'Saint Lawrence-lewis Boces',
  'Saint Leo University',
  'Saint Louis College',
  'Saint Louis University',
  'Saint Mary College',
  "Saint Mary's College (IN)",
  "Saint Mary's College of California",
  "Saint Mary's School",
  "Saint Mary's University",
  "Saint Mary's University of Minnesota",
  'Saint Marys Elementary School',
  'Saint Marys Middle School',
  "Saint Michael's College",
  'Saint Olaf College',
  'Saint Paul College',
  'Saint Paul Music Academy',
  'Saint Paul University',
  'Saint Pauls Elementary',
  'Saint Pauls High',
  'Saint Pauls Middle',
  'Saint Regis Falls Central School',
  'Saint Regis Mohawk School',
  "Saint Stephen's Indian School",
  'Saint Stephens Elementary',
  'Saint Stephens High',
  'Saint Thomas Elementary School',
  'Saint Thomas High School',
  'Saint Vincent College',
  'Saint Xavier University',
  'Saints Academy',
  'Saints Guided Studies',
  'Saints Online',
  'Saisd - Pk 4 Sa',
  'Saitama Institute of Technology',
  'Saitama Medical School',
  'Saitama Prefectural University',
  'Saitama University',
  'Saito College',
  'Sakai Intermediate',
  'Sakamoto Elementary',
  'Sakarya University',
  'Sakhalin State University',
  'Saks Elementary School',
  'Saks High School',
  'Saks Middle School',
  'Sakushin Gakuin University',
  'Sal Castro Middle',
  'Salado H S',
  'Salado Int',
  'Salado J H',
  'Salahddin University (Kurdistan Region)',
  'Salam University',
  'Salamanca High School',
  'Salamonie School',
  'Salazar Crossroads Academy',
  'Salazar District Alternative Education Program',
  'Salazar El',
  'Salazar Elem Bilingual Center',
  'Salazar Elementary',
  'Salcha Elementary',
  'Sale Creek Middle / High School',
  'Sale Elementary School',
  'Salem Academy Charter School',
  'Salem Attendance Center',
  'Salem Avenue Elementary',
  'Salem Campus',
  'Salem Church Elementary',
  'Salem Church Middle',
  'Salem Community College',
  'Salem Community High School',
  'Salem County Career And Technical High School',
  'Salem Drive School',
  'Salem Early Childhood',
  'Salem El Sch',
  'Salem Elementary',
  'Salem Elementary School',
  'Salem Heights Elementary School',
  'Salem High',
  'Salem High School',
  'Salem Hills Elementary',
  'Salem Hills High',
  'Salem Hyde Elementary School',
  'Salem Junior High',
  'Salem Junior High School',
  'Salem Middle',
  'Salem Middle School',
  'Salem Prep High School',
  'Salem School',
  'Salem Sr. High',
  'Salem University',
  'Salem Upper Elem.',
  'Salem Woods Elementary School',
  'Salem-liberty Elementary School',
  'Salemburg Elementary',
  'Salemtownship Elementary School',
  'Salemwood',
  'Salford Hills El Sch',
  'Salida Del Sol Academy',
  'Salida Del Sol Elementary',
  'Salida Early Childhood Center',
  'Salida Elementary',
  'Salida High School',
  'Salida Middle School',
  'Salida Middle School - Vella Campus',
  'Salida Montessori Charter School',
  'Salina Elementary P 3',
  'Salina Es',
  'Salina High Central',
  'Salina High South',
  'Salina Hs',
  'Salina Intermediate 4 8',
  'Salina Ms',
  'Salina School',
  'Salina South Middle',
  'Salinas Community',
  'Salinas El',
  'Salinas High',
  'Saline Alternative High School',
  'Saline Co. Career Ctr.',
  'Saline County Career Center',
  'Saline High School',
  'Saline Middle School',
  'Salisbury Central School',
  'Salisbury Community School',
  'Salisbury El Sch',
  'Salisbury Elem.',
  'Salisbury Elementary',
  'Salisbury Elementary School',
  'Salisbury High',
  'Salisbury High (continuation)',
  'Salisbury Middle',
  'Salisbury Ms',
  'Salisbury Shs',
  'Salisbury University',
  'Salisbury-elk Lick El Sch',
  'Salisbury-elk Lick Jshs',
  'Salish Kootenai College',
  'Salish Middle School',
  'Salish Ponds Elementary School',
  'Salk Elementary School',
  'Salk Es',
  'Salk Middle School',
  'Salladasburg El Sch',
  'Sallas Mahone Elementary',
  'Sallie B Howard School',
  'Sallie Cone Preschool',
  'Sallie Humble Elementary School',
  'Sallie Jones Elementary School',
  'Sallie Zetterower Elementary School',
  'Sallisaw Hs',
  'Sallisaw Ms',
  'Sally Dailey Meadows Elementary School',
  'Sally Mauro School',
  'Sally Ride Elementary: A Smart Academy',
  'Sallye B. Mathis Elementary School',
  'Sallye R Moore El',
  'Salmen High School',
  'Salmon Alternative High School',
  'Salmon Bay K-8 School',
  'Salmon Creek Elementary',
  'Salmon Creek School - A Charter',
  'Salmon Jr/sr High School',
  'Salmon Juvenile Detention Center',
  'Salmon Pioneer Primary School',
  'Salmon Prairie School',
  'Salmon River Elementary School',
  'Salmon River High School',
  'Salmon River Jr/sr High School',
  'Salmon River Middle School',
  'Salnave Elementary',
  'Salome Elementary School',
  'Salome H. Long Memorial School',
  'Salome High School',
  'Salome Ure?a Elementary School',
  'Salt Brook School',
  'Salt Creek Academy',
  'Salt Creek Elem School',
  'Salt Creek Elementary',
  'Salt Creek Intermediate School',
  'Salt Fork High School',
  'Salt Fork Junior High School',
  'Salt Fork North Elementary School',
  'Salt Fork South Elementary School',
  'Salt Lake Arts Academy',
  'Salt Lake Center For Science Education',
  'Salt Lake Community College',
  'Salt Lake Elementary School',
  'Salt Lake Head Start',
  'Salt Lake School For The Performing Arts',
  'Salt River Accelerated Learning Academy',
  'Salt River Elementary School',
  'Salt River High School',
  'Salt Rock Elementary School',
  'Salt School',
  'Saltars Point Elementary',
  'Saltillo Elementary School',
  'Saltillo High School',
  'Saltillo Primary School',
  'Saltillo School',
  'Saltonstall School',
  'Saltsburg El Sch',
  'Saltsburg Ms/hs',
  'Saltville Elementary',
  'Saltzman East Memorial Elementary School',
  'Saluda Elementary',
  'Saluda Elementary School',
  'Saluda High',
  'Saluda Middle',
  'Saluda Primary',
  'Saluda River Academy For The Arts',
  'Saluda Trail Middle',
  'Salvador Brau',
  'Salvador Brau Elemental',
  'Salvador Elementary',
  'Salvador Fuentes',
  'Salvador Garcia Middle',
  'Salvador Rodriguez',
  'Salvador Sanchez Middle',
  'Salvatore R. Calabro',
  'Salyards Middle',
  'Salyer Es',
  'Salyers El',
  'Salyersville Grade School',
  'Sam Adams Elementary School',
  'Sam Barlow High School',
  'Sam Boardman Elementary School',
  'Sam Brannan Middle',
  'Sam Case Elementary',
  'Sam D Bundy Elementary',
  'Sam D. Panter Elementary School',
  'Sam E. Hill Family/community Center',
  'Sam Fordyce El',
  'Sam H. Lawson Middle',
  'Sam Houston',
  'Sam Houston El',
  'Sam Houston Elementary',
  'Sam Houston H S',
  'Sam Houston High School',
  'Sam Houston Middle',
  'Sam Houston State University',
  'Sam Houston State University Charter School',
  'Sam Hughes Elementary',
  'Sam Jamison Middle',
  'Sam Perdue Juvenile Center',
  'Sam Rayburn',
  'Sam Rayburn El',
  'Sam Rayburn El Steam Academy',
  'Sam Rayburn H S',
  'Sam Rosen El',
  'Sam Rotolo Middle Sch',
  'Sam Salem Community Learning Center',
  'Sam Tasby Middle',
  'Sam V. Curtis Elementary',
  'Sam Webb Continuation',
  'Samar State University',
  'Samara Community School',
  'Samara State Academy for Railway Transportation',
  'Samara State Academy of Architecture and Civil Engineering',
  'Samara State Aerospace University',
  'Samara State Agricultural Academy',
  'Samara State Medical University',
  'Samara State Technical University',
  'Samara State University',
  'Samara State University of Economics',
  'Samara State University of Teacher Training',
  'Samarkand State University',
  'Sambalpur University',
  'Samchok National University',
  'Samford University',
  'Sami Shamoon College of Engineering',
  'Samish Elementary School',
  'Sammamish Senior High',
  'Sammons El',
  'Sammy Mcclure Sr. Middle School',
  'Samoana High School',
  'Samoset Elementary School',
  'Samoset Middle School',
  'Samoset School',
  'Sampit Elementary',
  'Sampson Academy',
  'Sampson Community College',
  'Sampson Early College High',
  'Sampson El',
  'Sampson G. Smith School',
  'Sampson Middle',
  'Sampurnanand Sanskrit University',
  'Sams Valley Elementary School',
  'Samsel Upper Elementary School',
  'Samson Elementary School',
  'Samson High School',
  'Samson Middle School',
  'Samsula Academy',
  'Samuel A. Heyward Career And Technology Center',
  'Samuel Adams',
  'Samuel B. Webb Elementary School',
  'Samuel Beck El',
  'Samuel Bissell Elementary School',
  'Samuel Bowles',
  'Samuel Brown Academy',
  'Samuel Chase Elementary',
  'Samuel Clemens H S',
  'Samuel E. Hubbard Elementary School',
  'Samuel E. Shull Middle School',
  'Samuel Enoka Kalama Intermediate School',
  'Samuel F. B. Morse Elementary',
  'Samuel G Love Elementary School',
  'Samuel Gompers Middle',
  'Samuel Gompers Sch',
  'Samuel Houston El',
  'Samuel Huntington School',
  'Samuel J Preston School',
  'Samuel J. Green Charter School',
  'Samuel Jackman Middle',
  'Samuel K Faust El Sch',
  'Samuel Kennedy Elementary',
  'Samuel L Wagner Middle School',
  'Samuel L. Gravely Jr. Elementary',
  'Samuel M Ridgway Middle School',
  'Samuel Mickle School',
  'Samuel Morey Elementary School',
  'Samuel Ogle Middle',
  'Samuel P. Langley Elementary',
  'Samuel P. Massie Academy',
  'Samuel Pennypacker Sch',
  'Samuel R. Donald Elementary School',
  'Samuel S. Gaines Academy K-8',
  'Samuel S. Yellin Elementary School',
  'Samuel Slater Middle School',
  'Samuel Smith Elementary School',
  'Samuel Staples Elementary School',
  'Samuel T. Busansky School',
  'Samuel Vaughn Elementary',
  'Samuel W Mason',
  'Samuel W. Simpson Elementary',
  'Samuel W. Tucker Elementary',
  'Samuel W. Wolfson High School',
  'Samuel Watson',
  'Samueli Academy',
  'Samuels Elementary School',
  'Samuelson Elementary School',
  'San Agustin',
  'San Altos Elementary',
  'San Andreas Continuation High',
  'San Andreas Elementary',
  'San Andreas High',
  'San Andreas High (continuation)',
  'San Angelo Special Progs',
  'San Angelo State School',
  'San Antonio Can Academy',
  'San Antonio College',
  'San Antonio Continuation',
  'San Antonio Elementary',
  'San Antonio Elementary School',
  'San Antonio High (continuation)',
  'San Antonio Rop',
  'San Antonio School For Inquiry & Creativity',
  'San Antonito Elementary',
  'San Ardo Elementary',
  'San Augustine El',
  'San Augustine H S',
  'San Beda College',
  'San Benancio Middle',
  'San Benito County Juvenile Hall Court',
  'San Benito County Opportunity',
  'San Benito County Regional Special Education Center',
  'San Benito Elementary',
  'San Benito H S',
  'San Benito High',
  'San Benito Riverside Middle',
  'San Benito Veterans Memorial Academy',
  'San Bernardino City Community Day',
  'San Bernardino Co Juve Detention And Assessment Center',
  'San Bernardino County Rop',
  'San Bernardino County Special Education',
  'San Bernardino High',
  'San Bernardino Valley College',
  'San Carlos Charter Learning Center',
  'San Carlos El',
  'San Carlos Park Elementary School',
  'San Carlos Secondary',
  'San Carlos Student Services Preschool',
  'San Carlos Unified School District #20 Alternative Center',
  'San Cayetano Elementary',
  'San Cayetano Elementary School',
  'San Clemente High',
  'San Diego Business/leadership',
  'San Diego Christian College',
  'San Diego City College',
  'San Diego Cooperative Charter',
  'San Diego Cooperative Charter School 2',
  'San Diego County Community',
  'San Diego County Court',
  'San Diego County Rop',
  'San Diego County Special Education',
  'San Diego H S',
  'San Diego International Studies',
  'San Diego Mesa College',
  'San Diego Metro Career And Tech',
  'San Diego Miramar College',
  'San Diego Neighborhood Homeschools',
  'San Diego Riverside',
  'San Diego Science And Technology',
  'San Diego Scpa',
  'San Diego State University',
  'San Diego Virtual',
  'San Diego Workforce Innovation High',
  'San Dieguito High Academy',
  'San Dimas High',
  'San Elijo Elementary',
  'San Elijo Middle',
  'San Elizario H S',
  'San Elizario Jjaep',
  'San Felipe Memorial Middle',
  'San Felipe Pueblo Elementary School',
  'San Fernando Elementary',
  'San Fernando Elementary School',
  'San Fernando Institute Of Applied Media',
  'San Fernando Middle',
  'San Fernando Senior High',
  'San Francisco Community Alternative',
  'San Francisco County Rop',
  'San Francisco Public Montessori',
  'San Francisco State University',
  'San Gabriel Avenue Elementary',
  'San Gabriel Elementary',
  'San Gabriel High',
  'San Geronimo Valley Elementary',
  'San Gorgonio High',
  'San Gorgonio Middle',
  'San Ildefonso Day School',
  'San Isidro El',
  'San Isidro H S',
  'San Jacinto Early Childhood Education Center',
  'San Jacinto El',
  'San Jacinto Elementary',
  'San Jacinto High',
  'San Jacinto Int',
  'San Jacinto J H',
  'San Jacinto Leadership Academy - Magnet',
  'San Jacinto Valley Academy',
  'San Joaquin Building Futures Academy',
  'San Joaquin County Community',
  'San Joaquin County Rocp',
  'San Joaquin County Special Education',
  'San Joaquin Delta College',
  'San Joaquin Elementary',
  'San Joaquin High (continuation)',
  'San Joaquin Valley College-​Visalia',
  'San Joaquin Valley High',
  'San Jon Elementary',
  'San Jon High',
  'San Jon Middle School',
  'San Jose Academy',
  'San Jose Charter Academy',
  'San Jose City College',
  'San Jose Conservation Corps Charter',
  'San Jose Elementary',
  'San Jose Elementary School',
  'San Jose High',
  'San Jose Intermediate',
  'San Jose Preparatory High School',
  'San Jose State University',
  'San Jose Street Elementary',
  'San Jose Valley Continuation High',
  'San Juan',
  'San Juan Bautista School of Medicine',
  'San Juan Choices Charter',
  'San Juan College',
  'San Juan College High School',
  'San Juan Elementary',
  'San Juan High',
  'San Juan Hills High',
  'San Lauren Elementary',
  'San Leandro High',
  'San Leon El',
  'San Lorenzo Elementary',
  'San Lorenzo High',
  'San Lorenzo Unified Preschool',
  'San Lorenzo Valley Elementary',
  'San Lorenzo Valley High',
  'San Lorenzo Valley Middle',
  'San Lucas Elementary',
  'San Luis County Special Education',
  'San Luis Elementary',
  'San Luis High (continuation)',
  'San Luis High School',
  'San Luis Middle School',
  'San Luis Obispo County Community',
  'San Luis Obispo County Juvenile Court',
  'San Luis Obispo High',
  'San Luis Pre-school',
  'San Luis Rey Elementary',
  'San Manual Jr. High School',
  'San Manuel High School',
  'San Marcos Elementary',
  'San Marcos Elementary School',
  'San Marcos H S',
  'San Marcos High',
  'San Marcos Middle',
  'San Marcos Senior High',
  'San Marin High',
  'San Marino Elementary',
  'San Marino High',
  'San Martin Gwinn Environmental Science Academy',
  'San Mateo County Rop',
  'San Mateo County Special Education',
  'San Mateo Elementary School',
  'San Mateo High',
  'San Mateo Park Elementary',
  'San Mateo-foster City Special Education Preschool',
  'San Miguel',
  'San Miguel Children Center',
  'San Miguel Elementary',
  'San Onofre Elementary',
  'San Pablo Elementary School',
  'San Pascual Elem Sci Tech Engr Arts And Math (steam) Magnet',
  'San Pasqual Academy',
  'San Pasqual High',
  'San Pasqual Middle',
  'San Pasqual Union Elementary',
  'San Pasqual Valley Elementary',
  'San Pasqual Valley High',
  'San Pasqual Vocational Academy',
  'San Patricio',
  'San Pedro El',
  'San Pedro Elementary',
  'San Pedro Senior High',
  'San Pedro Street Elementary',
  'San Pedro Valley High School',
  'San Pedro Valley Online Academy',
  'San Perlita El',
  'San Perlita H S',
  'San Perlita Middle',
  'San Rafael Elementary',
  'San Rafael High',
  'San Rafael Middle School',
  'San Ramon Elementary',
  'San Ramon Valley High',
  'San Saba El',
  'San Saba H S',
  'San Saba Middle',
  'San Salvador Preschool',
  'San Simon School',
  'San Tan Charter School',
  'San Tan Elementary',
  'San Tan Foothills High School',
  'San Tan Heights Elementary',
  'San Vicente',
  'San Vicente El',
  'San Vicente Elementary',
  'San Ysidro High',
  'San Ysidro Middle',
  "Sana'a University",
  'Sanaag University of Science and Technology',
  'Sanborn Central Elementary - 06',
  'Sanborn Central High School - 01',
  'Sanborn Central Middle School - 02',
  'Sanborn El',
  'Sanborn Elementary School',
  'Sanborn Regional High School',
  'Sanborn Regional Middle School',
  'Sanborn School',
  'Sanbornton Central School',
  'Sanchez (david J.) Elementary',
  'Sanchez (george I.) Elementary',
  'Sanchez El',
  'Sanchez Elementary',
  'Sanchez School',
  'Sand Creek Elementary',
  'Sand Creek Elementary School',
  'Sand Creek High School',
  'Sand Creek Intermediate School',
  'Sand Creek International Elementary School',
  'Sand Creek Middle School',
  'Sand Gap Elementary School',
  'Sand Hill Elementary',
  'Sand Hill Elementary School',
  'Sand Hill-venable Elem',
  'Sand Lake Elementary',
  'Sand Lake Elementary School',
  'Sand Lake-miller Hill School',
  'Sand Pine Elementary School',
  'Sand Point Elementary',
  'Sand Point School',
  'Sand Ridge Charter School',
  'Sand Ridge Elementary',
  'Sand Ridge Jr High',
  'Sand Ridge Secure Treatment Center',
  'Sand Rock High School',
  'Sand School',
  'Sand Springs Ec Ctr',
  'Sand Springs School',
  'Sandalwood Elementary',
  'Sandalwood High School',
  'Sandburg Elem School',
  'Sandburg Elementary',
  'Sandburg Elementary School',
  'Sandburg Middle',
  'Sandburg Middle School',
  'Sandburg Ms',
  'Sandcreek Middle School',
  'Sandel Elementary',
  'Sanders Corner Elementary',
  'Sanders El',
  'Sanders Elementary',
  'Sanders Elementary School',
  'Sanders Memorial Elementary School',
  'Sanders Middle',
  'Sanders Middle School',
  'Sanders Park Elementary School',
  'Sanders-clyde Elementary',
  'Sanderson Academy',
  'Sanderson High',
  'Sanderson Schools',
  'Sandersville Elementary',
  'Sandersville Regional Youth Detention Center',
  'Sandhill Elementary',
  'Sandhill Elementary School',
  'Sandhills Community College',
  'Sandhills Educ Services Coop',
  'Sandhills Elementary',
  'Sandhills Farm Life Elementary',
  'Sandhills Middle',
  'Sandhills Primary',
  'Sandhoke Early College High',
  'Sandia Base Elementary',
  'Sandia Elementary',
  'Sandia High',
  'Sandia Vista Elementary',
  'Sandlapper Elementary',
  'Sandlick Elementary',
  'Sandman Consolidated School',
  'Sandoval Academy Of Bilingual Education',
  'Sandoval Elem School',
  'Sandoval Jr High School',
  'Sandoval Sr High School',
  'Sandown Central School',
  'Sandown North Elementary School',
  'Sandoz Elementary School',
  'Sandpiper Elementary',
  'Sandpiper Elementary School',
  'Sandpiper Shores Elementary School',
  'Sandpoint High School',
  'Sandpoint Juvenile Detention',
  'Sandpoint Middle School',
  "Sandra Day O'connor High School",
  'Sandra J. Gallardo Elementary',
  'Sandra L Thompson Elementary School',
  'Sandra Mossman El',
  'Sandra Todd-williams Academy',
  'Sandra Tovar Medeiros Elementary',
  'Sandridge Elem School',
  'Sandrock Elementary',
  'Sands Cisd',
  'Sands Montessori Elementary School',
  'Sandshore Rd. Elementary School',
  'Sandston Elementary',
  'Sandstone Middle School',
  'Sandstone Montessori Charter School',
  'Sandstone School',
  'Sandtown Middle School',
  'Sandusky Elementary',
  'Sandusky Elementary School',
  'Sandusky High School',
  'Sandusky Juniorsenior High School',
  'Sandusky Middle',
  'Sandusky Middle School',
  'Sandwich Central School',
  'Sandwich Community High School',
  'Sandwich High',
  'Sandwich Middle School',
  'Sandwich Stem Academy',
  'Sandy Bluffs Alternative Education Center',
  'Sandy Creek Elementary School',
  'Sandy Creek High School',
  'Sandy Creek Middle School',
  'Sandy Grade School',
  'Sandy Grove Elementary',
  'Sandy Grove Middle',
  'Sandy High School',
  'Sandy Hill Elementary',
  'Sandy Hill School',
  'Sandy Hook Elementary',
  'Sandy Hook Elementary School',
  'Sandy Knoll School',
  'Sandy Lane Elementary School',
  'Sandy Plains Elementary',
  'Sandy Ridge Elementary',
  'Sandy River Middle School',
  'Sandy Run Ms',
  'Sandy Run School',
  'Sandy School',
  'Sandy Searles Miller Elementary School',
  'Sandy Springs Middle School',
  'Sandy Valley Elementary School',
  'Sandy Valley High School',
  'Sandy Valley Jr/sr High School',
  'Sandy Valley Middle School',
  'Sandycreek El Sch',
  'Sandymount Elementary',
  'Sandyston Walpack Consolidated School',
  'Sandyview Elementary School',
  'Sanford Creek Elementary',
  'Sanford Education Center',
  'Sanford Elementary School',
  'Sanford H Calhoun High School',
  'Sanford High School',
  'Sanford Jr High School',
  'Sanford Junior/senior High School',
  'Sanford Middle',
  'Sanford Middle School',
  'Sanford Regional Technical Center',
  'Sanford-fritch El',
  'Sanford-fritch H S',
  'Sanford-fritch J H',
  'Sanfordville Elementary School',
  'Sang Ji University',
  'Sang Myung University',
  'Sangamon County Learning Academy',
  'Sangamon Elem School',
  'Sangamon Safe School Program',
  'Sangamon Valley East Elem School',
  'Sangamon Valley High School',
  'Sangamon Valley Middle School',
  'Sangamon Valley West Elem School',
  'Sangaree Elementary',
  'Sangaree Intermediate',
  'Sangaree Middle',
  'Sanger Academy Charter',
  'Sanger Elementary',
  'Sanger H S',
  'Sanger High',
  'Sanger Middle',
  'Sanger Sixth Grade Campus',
  'Sango Elementary',
  'Sangre De Cristo Elementary School',
  'Sangre De Cristo Undivided High School',
  'Sangre Ridge Es',
  'Sangster Elementary',
  'Sanhedrin High',
  'Sanilac Career Center',
  'Sanilac Isd Special Education Services',
  'Sanislo Elementary School',
  'Sanjay Gandhi Postgraduate lnstitute of Medical Sciences',
  'Sankei University',
  'Sanko University',
  'Sankofa Academy',
  'Sankofa Freedom Academy Charter School',
  'Sankofa Middle School',
  'Sankofa Underground North Academy',
  'Sanlee Middle School',
  'Sanno University',
  'Sanostee Day School',
  'Sansom Middle School',
  'Santa Ana College',
  'Santa Ana High',
  'Santa Anna El',
  'Santa Anna Secondary',
  'Santa Barbara Charter',
  'Santa Barbara City College',
  'Santa Barbara Community Academy',
  'Santa Barbara County Community',
  'Santa Barbara County Juvenile Court',
  'Santa Barbara County Rop-north',
  'Santa Barbara County Rop-south',
  'Santa Barbara County Special Education',
  'Santa Barbara Junior High',
  'Santa Barbara Senior High',
  'Santa Barbara Unified Early Childhood',
  'Santa Clara Community Day',
  'Santa Clara County Community',
  'Santa Clara County Court',
  'Santa Clara County Rop-south',
  'Santa Clara County Special Education',
  'Santa Clara Elementary',
  'Santa Clara Elementary School',
  'Santa Clara High',
  'Santa Clara School',
  'Santa Clara University',
  'Santa Clarita Elementary',
  'Santa Clarita Valley International',
  'Santa Cruz Center For Success',
  'Santa Cruz City Elementary Alternative Education-monarch',
  'Santa Cruz County Career Advancement Charter',
  'Santa Cruz County Community',
  'Santa Cruz County Court',
  'Santa Cruz County Rop',
  'Santa Cruz County Special Education',
  'Santa Cruz Elementary School',
  'Santa Cruz Gardens Elementary',
  'Santa Cruz High',
  'Santa Cruz Valley Union High School',
  'Santa Fe 5/6 Center',
  'Santa Fe Community College',
  'Santa Fe Computer Science Magnet',
  'Santa Fe El',
  'Santa Fe Elem.',
  'Santa Fe Elementary',
  'Santa Fe Elementary School',
  'Santa Fe Engage',
  'Santa Fe Es',
  'Santa Fe H S',
  'Santa Fe High',
  'Santa Fe High School',
  'Santa Fe Hs',
  'Santa Fe Indian School',
  'Santa Fe J H',
  'Santa Fe S Pathways Mid Colleg',
  'Santa Fe South Early Childhood',
  'Santa Fe South Hills Es',
  'Santa Fe South Hs',
  'Santa Fe South Ms',
  'Santa Fe South Penn Es',
  'Santa Fe South Spero Es',
  'Santa Fe Trail Elem',
  'Santa Fe Trail Elem.',
  'Santa Fe Trail High',
  'Santa Fe Trail Middle School',
  'Santa Fe Unit School',
  'Santa Gertrudis Academy H S',
  'Santa Gertrudis School',
  'Santa Lucia Elementary',
  'Santa Lucia Middle',
  'Santa Margarita Academy',
  'Santa Margarita Elementary',
  'Santa Maria Academic Academy-options Campus (6-12)',
  'Santa Maria Daep',
  'Santa Maria El',
  'Santa Maria H S',
  'Santa Maria High',
  'Santa Maria J H',
  'Santa Maria Jjaep',
  'Santa Maria Middle',
  'Santa Maria Middle School',
  'Santa Monica Alternative (k-8)',
  'Santa Monica Boulevard Community Charter',
  'Santa Monica College',
  'Santa Monica High',
  'Santa Monica-malibu Preschool',
  'Santa Paula High',
  'Santa Rita El',
  'Santa Rita Elementary',
  'Santa Rita High School',
  'Santa Rita Pre-k',
  'Santa Rosa Academy',
  'Santa Rosa Accelerated Charter',
  'Santa Rosa Adult School',
  'Santa Rosa Charter School For The Arts',
  'Santa Rosa Daep',
  'Santa Rosa Day School',
  'Santa Rosa Elementary',
  'Santa Rosa Elementary School',
  'Santa Rosa French-american Charter (srfacs)',
  'Santa Rosa H S',
  'Santa Rosa High',
  'Santa Rosa Iii',
  'Santa Rosa Jjaep',
  'Santa Rosa Junior College',
  'Santa Rosa Middle',
  'Santa Rosa Online Virtual Instruction Program',
  'Santa Rosa Preschool',
  'Santa Rosa Ranch School',
  'Santa Rosa Road Academic Academy',
  'Santa Rosa Technology Magnet',
  'Santa Rosa Virtual Franchise',
  'Santa Rosa Virtual Instruction Course Offerings',
  'Santa Susana Elementary',
  'Santa Susana High',
  'Santa Teresa Elementary',
  'Santa Teresa High',
  'Santa Teresa Middle',
  'Santa Teresita',
  'Santa Ynez Elementary',
  'Santa Ynez Valley Charter',
  'Santa Ynez Valley Union High',
  'Santaluces Community High',
  'Santaluces High Adult Education Center',
  'Santan Elementary',
  'Santan Junior High School',
  'Santana High',
  'Santana High (continuation)',
  'Santapogue School',
  'Santaquin School',
  'Santee Alternative',
  'Santee Education Complex',
  'Santee Elementary',
  'Santee Elementary School',
  'Santee High School',
  'Santee Success Program',
  'Santiago Canyon College',
  'Santiago Collazo Perez',
  'Santiago Elementary',
  'Santiago Garcia El',
  'Santiago High',
  'Santiago Hills Elementary',
  'Santiago Iglesias Pantin',
  'Santiago Middle',
  'Santiago Negroni',
  'Santiago R. Palmer Superior',
  'Santiago Torres',
  'Santiam Elementary School',
  'Santiam Junior/senior High School',
  'Santo Domingo Elementary',
  'Santo Domingo Middle',
  'Santo El',
  'Santo Forte J H',
  'Santo H S',
  'Santo Nino El',
  'Santos Livas El',
  'Sanville Elementary',
  'Sanyo Gakuen University',
  'Sap Program',
  'Sapphire Elementary School',
  'Sappington Elem.',
  'Sapporo Gakuin University',
  'Sapporo International University',
  'Sapporo Medical University',
  'Sapporo University',
  'Sapulpa Hs',
  'Sapulpa Jhs',
  'Sapulpa Ms',
  'Sara Babb High',
  'Sara Collins Elementary',
  'Sara Coughlin Elementary',
  'Sara Harp Minter Elementary School',
  'Sara Lindemuth El Sch',
  'Sara M. Gilmore Academy',
  'Sara M. Ragsdale Elementary',
  'Sara Scott Harllee Middle School',
  'Sarah A Raymond Sch Of Early Educ',
  'Sarah Adams Elementary School',
  'Sarah Dyer Barnes School',
  'Sarah G Banks Middle School',
  'Sarah Greenwood',
  'Sarah J Anderson Elementary',
  'Sarah J Webber Media Arts Academy',
  'Sarah Lawrence College',
  'Sarah M. Roach Elementary',
  'Sarah Marley School',
  'Sarah Mcgarvin Intermediate',
  'Sarah Milner Elementary School',
  'Sarah Moore Greene Magnet Technology Academy',
  'Sarah Noble Intermediate School',
  'Sarah Porter School',
  'Sarah S King El',
  'Sarah Scott Middle School',
  'Sarah Strinden El',
  'Sarah W Gibbons Middle',
  'Sarah W Starkweather El Sch',
  'Sarah Winnemucca Elementary School',
  'Sarah Zumwalt Middle',
  'Sarajevo Film Academy',
  'Saraland Early Education Center',
  'Saraland Elementary School',
  'Saraland High School',
  'Saraland Middle Schooladams Campus',
  'Saranac Elementary School',
  'Saranac High School',
  'Saranac Jrsr High School',
  'Saranac Lake Middle School',
  'Saranac Lake Senior High School',
  'Saranac Middle School',
  'Sarasota Academy Of The Arts',
  'Sarasota High School',
  'Sarasota Middle School',
  'Sarasota Military Academy',
  'Sarasota Military Academy Prep',
  'Sarasota School Of Arts/sciences',
  'Sarasota Suncoast Academy',
  'Sarasota Virtual Academy (virtual Franchise)',
  'Sarasota Virtual Instruction (course Offerings)',
  'Sarasota Virtual Instruction Program',
  'Saratoga Elem School',
  'Saratoga Elementary',
  'Saratoga Elementary School',
  'Saratoga High',
  'Saratoga Middle/high School',
  'Saratoga School',
  'Saratoga Shores School',
  'Saratoga Springs High School',
  'Saratov State Academy of Law',
  'Saratov State Agrarian University',
  'Saratov State Medical University',
  'Saratov State Socio-Economic University',
  'Saratov State Technical University',
  'Saratov State University',
  'Sarcoxie High',
  'Sardar Patel University',
  'Sardar Vallabhai Patel University of Agriculture Amd Technology',
  'Sardar Vallabhbhai National Institute of Technology',
  'Sardarkrushinagar Dantiwada Agricultural University',
  'Sardinia Elementary School',
  'Sardis Elementary',
  'Sardis Elementary School',
  'Sardis High School',
  'Sardis Middle School',
  'Sargent Central Elementary School',
  'Sargent Central High School',
  'Sargent Elementary School',
  'Sargent High School',
  'Sargent Junior High School',
  'Sargent School',
  'Sargent Senior High School',
  'Sargent Shriver Elementary',
  'Sarhad University of Science & Information Technology Peshawar',
  'Sarita El',
  'Sarpy Co Coop Head Start-bellevue',
  'Sarpy Co Coop Head Start-gretna',
  'Sarpy Co Coop Head Start-papillion-la Vista',
  'Sarpy Co Coop Head Start-springfield Platteview',
  'Sartartia Middle',
  'Sartell Ec Service Center',
  'Sartell Middle',
  'Sartell Senior High',
  'Sartorette Charter',
  'Sartori Education Center',
  'Sasakwa Es',
  'Sasakwa Hs',
  'Sasd 4k',
  'Sased Central-proj Ican & Pace',
  'Sashabaw Middle School',
  'Saskatchewan Indian Federated College',
  'Sassarini Elementary',
  'Satakunta University Of Applied Scinces',
  'Satanta Elem',
  'Satanta Jr-sr High',
  'Satchel Ford Elementary',
  'Satellite Academy High School',
  'Satellite East Middle School',
  'Satellite High School',
  'Satellite Senior High School',
  'Sathyabama University',
  'Saticoy Elementary',
  'Satilla Elementary School',
  'Satilla Marsh Elementary School',
  'Sato Elementary School',
  'Sator Sanchez Elem School',
  'Satori Charter School',
  'Satsop Elementary',
  'Satsuma High School',
  'Satterlee School',
  'Saturn Elementary School',
  'Saturn Street Elementary',
  'Satus Elementary',
  'Sau Campus',
  'Sau Tech Career Center',
  'Saucedo Elem Scholastic Academy',
  'Saucelito Elementary',
  'Saucier Elementary School',
  'Saucon Valley El Sch',
  'Saucon Valley Ms',
  'Saucon Valley Shs',
  'Sauder Elementary School',
  'Sauder School of Business',
  'Sauganash Elem School',
  'Saugatuck Elementary School',
  'Saugatuck High School',
  'Saugatuck Middle School',
  'Saugerties Junior High School',
  'Saugerties Senior High School',
  'Saugus High',
  'Sauk Centre Elementary',
  'Sauk Centre Secondary',
  'Sauk Elem School',
  'Sauk Prairie High',
  'Sauk Prairie Middle',
  'Sauk Rapids Ecfe',
  'Sauk Rapids-rice Middle School',
  'Sauk Rapids-rice Senior High',
  'Sauk Trail Elementary',
  'Sauk Valley Community College',
  'Saukville Elementary',
  'Saul Martinez Elementary',
  'Saul W B Agricultural School',
  'Sault Area Career Center',
  'Sault Area High School',
  'Sault Area Middle School',
  'Saunders Trades & Technical High School',
  'Saunemin Elem School',
  'Sauquoit Valley Elementary School',
  'Sauquoit Valley High School',
  'Sauquoit Valley Middle School',
  'Saurashtra University',
  'Sauvie Island School',
  'Sava: Sacramento Academic And Vocational Academy',
  'Savage 7-8',
  'Savage High School',
  'Savage Road Elementary School',
  'Savage School',
  'Savanna Es',
  'Savanna High',
  'Savanna Hs',
  'Savanna Oaks Middle',
  'Savanna Ridge Elementary School',
  'Savannah Arts Academy',
  'Savannah Classical Academy Charter High School',
  'Savannah Classical Academy Charter School',
  'Savannah Early College High School',
  'Savannah El',
  'Savannah Elementary',
  'Savannah Grove Elementary',
  'Savannah Heights Int',
  'Savannah High',
  'Savannah Lakes El',
  'Savannah Middle',
  'Savannah Regional Youth Detention Center',
  'Savannah Technical College',
  'Saville Elementary School',
  'Savin Rock Community School',
  'Savoy El',
  'Savoy Es',
  'Savoy H S',
  'Saw Mill Road School',
  'Sawgrass Bay Elementary School',
  'Sawgrass Elementary School',
  'Sawgrass Lake Elementary School',
  'Sawgrass Springs Middle School',
  'Sawmill Intermediate School',
  'Sawmill Woods Elementary School',
  'Sawmills Elementary',
  'Sawnee Elementary School',
  'Sawtooth Elementary School',
  'Sawtooth Middle School',
  'Sawtooth Mountain Elementary',
  'Sawtooth Ridge Community',
  'Sawyer Elem School',
  'Sawyer Elementary',
  'Sawyer Elementary School',
  'Sawyer High School',
  'Sawyer Road Elementary School',
  'Sawyer Woods Elementary School',
  'Saxe Gotha Elementary',
  'Saxe Middle School',
  'Saxion Universities',
  'Saxton Middle School',
  'Saxton River Elementary School',
  'Say?ik: Gastineau Community School',
  'Saydel High School',
  'Sayen Elementary School',
  'Sayler Park Elementary School',
  'Sayles School',
  'Saylesville Elementary',
  'Sayre Area Hs',
  'Sayre Elem Language Academy',
  'Sayre Elementary School',
  'Sayre Es',
  'Sayre Hs',
  'Sayre Ms',
  'Sayre William L Ms',
  'Sayreville Middle School',
  'Sayreville War Memorial High School',
  'Sayville High School',
  'Sayville Middle School',
  'Sc Connections Academy',
  "Sc Governor's School For Arts And Humanities",
  "Sc Governor's School For Science And Mathematics",
  'Sc School For The Blind Elementary/middle',
  'Sc School For The Blind High',
  'Sc School For The Deaf Elementary/middle',
  'Sc School For The Deaf High',
  'Sc Virtual Charter School',
  'Sc Whitmore School',
  'Scale Leadership Academy',
  'Scale School',
  'Scales Elementary',
  'Scales Elementary School',
  'Scales Mound Elem School',
  'Scales Mound High School',
  'Scales Mound Jr High School',
  'Scales Technology Academy',
  'Scammon Bay School',
  'Scammon Elem School',
  'Scandia Elementary',
  'Scandinavian Art and Business Institute',
  'Scandinavian Middle',
  'Scanlan Oaks El',
  'Scapa At Bluegrass',
  'Scappoose High School',
  'Scappoose Middle School',
  'Scarborough El',
  'Scarborough Elem',
  'Scarborough H S',
  'Scarborough High School',
  'Scarborough Middle School',
  'Scarlet Oak Elem School',
  'Scarlet Oaks Cdc',
  'Scarlett Middle School',
  'Scarsdale Middle School',
  'Scarsdale Senior High School',
  'Scavo Alternative High School',
  'Sccp Images',
  'Scenic Elementary School',
  'Scenic Heights Elementary',
  'Scenic Heights Elementary School',
  'Scenic Hill Elementary School',
  'Scenic Hills El Sch',
  'Scenic Hills Elementary',
  'Scenic Middle School',
  'Scenic Park Elementary',
  'Scera Park School',
  'Sch For Classics: An Academy-twp',
  'Sch Of Hist And Dramatic Arts At Sonia Sotomayor Lrng Acads',
  'Schaefer Middle School',
  'Schaeffer Sch',
  'Schafer Elem School',
  'Schafer Park Elementary',
  'Schaffner Traditional Elementary',
  'Schaghticoke Middle School',
  'Schall Elementary School',
  'Schallenberger Elementary',
  'Schaller-crestland Elementary',
  'Schaller-crestland M.s. Ridge View Middle School',
  'Schallert El',
  'Schalmont High School',
  'Schalmont Middle School',
  'Schanen Estates El',
  'Scharbauer El',
  'Schaumburg High School',
  'Schavey Road Elementary School',
  'Schell El',
  'Schenck El',
  'Schenck High School',
  'Schendel Elementary',
  'Schenectady County Community College',
  'Schenectady High School',
  'Schenevus Central School',
  'Schenk Elementary',
  'Schertz El',
  'Schiesher Elem School',
  'Schiller Elem School',
  'Schiller International University American College of Switzerland',
  'Schiller International University Heidelberg',
  'Schiller International University London',
  'Schiller International University Madrid',
  'Schiller International University Paris',
  'Schiller International University Strasbourg',
  'Schilling Elem',
  'Schilling Farms Middle',
  'Schimelpfenig Middle',
  'Schindewolf Int',
  'Schirle Elementary School',
  'Schlegel Road Elementary School',
  'Schleswig Elementary School',
  'Schleswig Middle-school',
  'Schley County Elementary School',
  'Schley Middle High School',
  'Schmid Elem School',
  'Schmidt Elementary School',
  'Schmitt Elementary',
  'Schmitt Elementary School',
  'Schmucker Middle School',
  'Schnecksville Sch',
  'Schnee Learning Center',
  'Schneider Elem School',
  'Schochler El',
  'Schoenbar Middle School',
  'Schofield',
  'Schofield Elementary',
  'Schofield Elementary School',
  'Schofield Middle',
  'Schoharie Elementary School',
  'Schoharie High School',
  'Scholar Academy',
  "Scholars' Academy",
  'Scholarship Prep - Oceanside',
  'Scholarship Prep Charter',
  'Scholastic Academy For Academic Excellence',
  'Scholls Heights Elementary School',
  'Schoo Middle School',
  'School #1',
  'School #11',
  'School #12',
  'School #13',
  'School #14',
  'School #15',
  'School #16',
  'School #17',
  'School #2',
  'School #3',
  'School #4',
  'School #5',
  'School #6',
  'School #8',
  'School #9',
  'School 1',
  'School 1-martin B Anderson',
  'School 10',
  'School 12',
  'School 12-james P B Duffy',
  'School 13',
  'School 14',
  'School 15',
  "School 15-children's School Of Rochester (the)",
  'School 16',
  'School 16-john Walton Spencer',
  'School 17',
  'School 17-enrico Fermi',
  'School 18',
  'School 19',
  'School 19-dr Charles T Lunsford',
  'School 2',
  'School 2-clara Barton',
  'School 20',
  'School 20-henry Lomb School',
  'School 21',
  'School 22',
  'School 22-lincoln School',
  'School 23',
  'School 23-francis Parker',
  'School 24',
  'School 25',
  'School 25-nathaniel Hawthorne',
  'School 26',
  'School 27',
  'School 28',
  'School 28-henry Hudson',
  'School 29',
  'School 29-adlai E Stevenson',
  'School 3',
  'School 3-nathaniel Rochester',
  'School 30',
  'School 33-audubon',
  'School 34-dr Louis A Cerulli',
  'School 35-pinnacle',
  'School 39-andrew J Townson',
  'School 4',
  'School 4-george Mather Forbes',
  'School 41-kodak Park',
  'School 42-abelard Reynolds',
  'School 43-theodore Roosevelt',
  'School 44-lincoln Park',
  'School 45-mary Mcleod Bethune',
  'School 46-charles Carroll',
  'School 5',
  'School 5-john Williams',
  'School 50-helen Barrett Montgomery',
  'School 52-frank Fowler Dow',
  'School 53 Montessori Academy',
  'School 54-flower City Community School',
  'School 57-early Childhood School',
  'School 58-world Of Inquiry School',
  'School 6',
  'School 6-kindergarten Center',
  'School 7',
  'School 7-oceanside Senior High School',
  'School 7-virgil Grissom',
  'School 8',
  'School 8-roberto Clemente',
  'School 9',
  'School 9-dr Martin Luther King Jr',
  'School 9e-boardman Elem School',
  'School 9m-oceanside Middle School',
  'School Age Child Care',
  'School Aged Parenting Program',
  'School At St George Place',
  'School Community Guidance Center',
  'School Dist 133 Annex',
  'School District 428 Iyc-hrb',
  'School District Of The Menomonie Area 4k',
  'School For Accelerated Learning And Technologies Inc',
  'School For Accelerated Lrn',
  'School For Advanced Studies',
  'School For Advanced Studies - South',
  'School For Advanced Studies Homestead',
  'School For Advanced Studies North',
  'School For Advanced Studies-wolfson',
  'School For Agricultural And Environmental Studies',
  'School For All Seasons',
  'School For Arts And Performance',
  'School For Blind Elementary',
  'School For Blind High',
  'School For Creat & Perf Arts High School',
  'School For Deaf Elementary',
  'School For Deaf High',
  'School For Democracy And Leadership',
  'School For Early Development And Achievement (seda)',
  'School For Entrepreneurship And Technology',
  'School For Environmental Citizenship',
  'School For Excellence',
  'School For Exceptional Children',
  'School For Exceptional Studies',
  'School For Future Leaders (the)',
  'School For Global Leaders',
  'School For Hearing Impaired',
  'School For Human Rights (the)',
  'School For Inquiry And Social Justice (the)',
  'School For The Blind',
  'School For The Deaf Pk-8',
  'School For The Entrepreneur',
  'School For The Highly Gifted',
  'School For The Talented And Gifted',
  'School For The Visual Arts And Humanities',
  'School For Tourism And Hospitality',
  'School Home Partnership Program',
  'School In The Square Public Charter School',
  'School Lane Cs',
  'School No. 1',
  'School No. 2',
  'School No. 3',
  'School No. 4',
  'School No. 5',
  'School No.16',
  'School No.17',
  'School Of Academic And Behavioral Learning Excellence (sable',
  'School Of Architecture And Construction Trades',
  'School Of Arts And Enterprise',
  'School Of Buisiness Technology',
  'School Of Business And Management',
  'School Of Business And Tourism At Contreras Learning Complex',
  'School Of Career And Technical Ed',
  'School Of Culinary Arts Hospitality And Tourism',
  'School Of Discovery Genesis Center',
  'School Of Dreams Academy',
  'School Of Earth And Space Science',
  'School Of Education And Training',
  'School Of Engineering & Sciences',
  'School Of Engineering-arts At Olson',
  'School Of Environmental Studies',
  'School Of Extended Educational Options',
  'School Of Fine Arts',
  'School Of Government & Public Administration',
  'School Of Health Professions',
  'School Of Health Science',
  'School Of Humanities At Juliette Gordon Low',
  'School Of Information Technology',
  'School Of Innovation',
  'School Of Inquiry And Life Sciences',
  'School Of Integrated Learning (the)',
  'School Of Math Science And Healthy Living (the)',
  'School Of Options & Applied Research High',
  'School Of Options & Applied Research Middle',
  'School Of Performing Arts',
  'School Of Science & Technology',
  'School Of Science And Applied Learning',
  'School Of Science And Engineering',
  'School Of Science And Technology',
  'School Of Science And Technology - Alamo',
  'School Of Science And Technology Advancement',
  'School Of Science And Technology Corpus Christi',
  'School Of Science And Technology Discovery',
  'School Of Science And Technology Excellence',
  'School Of Science And Technology Houston',
  'School Of Science Technology Engineering And Mathematics',
  'School Of Social Justice Hs',
  'School Of Success Academy-sos',
  'School Of Technology And Arts I',
  'School Of Technology And Arts Ii',
  'School Of Technology At Carver',
  'School Of The Arts',
  'School Of The Future',
  'School Of The Future Brooklyn',
  'School Of The Future High School',
  'School Of Universal Learning (soul)',
  'School Of Unlimited Learning',
  'School On Wheels',
  'School One Elementary',
  'School Readiness',
  'School Readiness Program',
  'School Street El Sch',
  'School Street School',
  'School To Life',
  'School Within School At Goding',
  'School Without Walls',
  'School Without Walls At Francis Stevens',
  'School Without Walls Hs',
  'School of Banking and Management in Cracow',
  'School of Business and Finance',
  'School of Design and Crafts',
  'School of Economic Sciences',
  'School of Education Pisa',
  'School of Finance and Banking',
  'School of Management',
  'School of Oriental and African Studies University of London',
  'School of Pharmacy University of London',
  'School of Planning and Architecture',
  'School of Slavonic and East European Studies University of London',
  'School of the Art Institute of Chicago',
  'Schoolcraft College',
  'Schoolcraft Elementary School',
  'Schoolcraft High School',
  'Schoolcraft Learning Community Chtr',
  'Schoolcraft Middle School',
  'Schoolfield Elementary',
  'Schrader Elementary School',
  'Schramm Education Center',
  'Schriever Elementary School',
  'Schroeder Elementary',
  'Schroeder Elementary School',
  'Schroeder Middle School',
  'Schroon Lake Central School',
  'Schrop Intermediate School',
  'Schrum Memorial School',
  'Schubert Elem School',
  'Schuchard Elementary School',
  'Schulenburg El',
  'Schulenburg Secondary',
  'Schuler Elementary School',
  'Schulte Elementary',
  'Schulter Es',
  'Schulter Hs',
  'Schultz El',
  'Schultz Elementary',
  'Schultz J H',
  'Schulze El',
  'Schulze Elementarymiddle School',
  'Schumacher Community Learning Cent',
  'Schumann Elementary',
  'Schurr High',
  'Schurz Elementary',
  'Schurz Elementary School',
  'Schurz High School',
  'Schuster El',
  'Schuyler Avenue El Sch',
  'Schuyler Central High School',
  'Schuyler Co. Elem.',
  'Schuyler Co. High',
  'Schuyler Elementary School',
  "Schuyler Elementary-fisher's",
  'Schuyler Headstart',
  'Schuyler Industry Middle Sch',
  'Schuyler Industry Middle School',
  'Schuyler Middle School',
  'Schuyler-colfax Middle School',
  'Schuyler-steuben-chemung-tioga-allegany Boces',
  'Schuylerville Elementary School',
  'Schuylerville High School',
  'Schuylerville Middle School',
  'Schuylerville Preparatory High School',
  'Schuylkill El Sch',
  'Schuylkill Haven El Ctr',
  'Schuylkill Haven Ms',
  'Schuylkill Haven Shs',
  'Schuylkill Technology Centers-north Camp',
  'Schuylkill Technology Centers-south Camp',
  'Schuylkill Valley El Sch',
  'Schuylkill Valley Hs',
  'Schuylkill Valley Ms',
  'Schwartz Es',
  'Schwarzkoff Elementary School',
  'Schwarzkopf Elementary School',
  'Schwegler Elem',
  'Schweitzer El Sch',
  'Schwenksville El Sch',
  'Schwettman Education Center',
  'Sci Tech Engr Arts And Math Acad At La Presa',
  'Sci Tech Engr Arts And Math At Legacy High Sch Complex',
  'Sci-tech Preparatory',
  'Science & Technology Academy At Knights Landing',
  'Science And Math Institute',
  'Science And Medicine Middle School (the)',
  'Science And Technology Academy: A Mott Hall School',
  'Science And Technology Magnet School Of Southeastern Connec',
  'Science Ctr',
  'Science Focus Program',
  'Science Hall El',
  'Science Hill Elementary School',
  'Science Hill High School',
  'Science Leadership Academy',
  'Science Leadership Academy Ms',
  'Science Park High School',
  'Science Skills Center High School',
  'Science Technology & Research High School At Erasmus',
  'Science University of Tokyo',
  'Science University of Tokyo in Yamaguchi',
  'Scintilla Charter Academy',
  'Scio Central School',
  'Scio High School',
  'Scio Middle School',
  'Scioto County Career Technical Center',
  'Scioto Darby Elementary School',
  'Scioto Elementary School',
  'Scioto Ridge Elementary School',
  'Sciotoville',
  'Sciotoville Elementary Academy',
  'Scipio Elementary School',
  'Scitech Academy Charter School',
  'Scituate High School',
  'Scituate Middle School',
  'Scobee Camp',
  'Scobee El',
  'Scobey 7-8',
  'Scobey High School',
  'Scobey School',
  'Scofield Middle School',
  'Scoggins Middle',
  'Scootney Springs Elementary',
  'Score Academy',
  'Scotch Elementary School',
  'Scotch Plains-fanwood High School',
  'Scotchtown Avenue School',
  'Scotchtown Hills Elementary',
  'Scotia-glenville Middle School',
  'Scotia-glenville Senior High School',
  'Scotland Co. Elem.',
  'Scotland Co. High',
  'Scotland Early College High',
  'Scotland El Sch',
  'Scotland Elementary - 02',
  'Scotland Elementary School',
  'Scotland High School',
  'Scotland High School - 01',
  'Scotland Middle School - 05',
  'Scotland Neck Elementary Leadership Acad',
  'Scotland Park El',
  'Scotlandville Magnet High School',
  'Scotlandville Middle Pre-engineering Academy',
  'Scotsdale El',
  'Scott & White Hospital',
  'Scott Altman Primary School',
  'Scott Avenue Elementary',
  'Scott Central Attendance Center',
  'Scott Charter School',
  'Scott Child Development Center',
  'Scott Christian University',
  'Scott City Elem.',
  'Scott City High',
  'Scott City Lower Elem',
  'Scott City Middle',
  'Scott Co. Central Elem.',
  'Scott Co. Central High',
  'Scott Co. Elem.',
  'Scott Co. Middle',
  'Scott County Career And Technical Center',
  'Scott County High School',
  'Scott County Middle School',
  'Scott County Ninth Grade School',
  'Scott County Preschool',
  'Scott Dual Language Magnet',
  'Scott El',
  'Scott Elem School',
  'Scott Elem.',
  'Scott Elementary School',
  'Scott High School',
  'Scott Highlands Middle',
  'Scott Johnson El',
  'Scott Joseph Camp',
  'Scott L Libby Elementary School',
  'Scott Lake Elementary School',
  'Scott Lane Elementary',
  'Scott M Ellis Elementary School',
  'Scott M Matheson Jr High',
  'Scott M. Brame Middle School',
  'Scott Memorial Middle',
  'Scott Middle School',
  'Scott Morgan Johnson Middle',
  'Scott Ms',
  'Scott Regional Tech Center',
  'Scott River High',
  'Scott Sch',
  'Scott School',
  'Scott Teays Elementary',
  'Scott Valley Community Day',
  'Scott Valley Junior High',
  "Scott's Branch High",
  "Scott's Branch Middle",
  'Scottish Corners Elementary School',
  'Scotts Branch Elementary',
  'Scotts Creek Elementary',
  'Scotts Elementary',
  'Scotts Hill Elementary',
  'Scotts Hill High School',
  'Scotts Mills Elementary School',
  'Scotts Ridge Elementary',
  'Scotts Ridge Middle School',
  'Scotts Valley High',
  'Scotts Valley Middle',
  'Scottsbluff Senior High School',
  'Scottsboro High School',
  'Scottsboro Junior High School',
  'Scottsburg Elem School',
  'Scottsburg Elementary',
  'Scottsburg Middle School',
  'Scottsburg Senior High School',
  'Scottsdale Community College',
  'Scottsdale Country Day School',
  'Scottsdale Online Learning',
  'Scottsville Elementary',
  'Scottville Elementary School',
  'Scottwood Elementary School',
  'Scouters Mountain Elementary',
  'Scps Consequence Unit',
  'Scps Early Learning Coalition',
  'Scranton Elementary',
  'Scranton Elementary School',
  'Scranton High School',
  'Scranton Hs',
  'Scranton Middle School',
  'Scranton School',
  'Screven County Elementary School',
  'Screven County High School',
  'Screven County Middle School',
  'Screven Elementary School',
  'Scriber Lake High School',
  'Scribner Road Elementary School',
  'Scribner-snyder Secondary Sch',
  'Scripps Elementary',
  'Scripps Middle School',
  'Scripps Ranch High',
  'Scroggins El',
  'Scroggs Elementary',
  'Scudder Kenyon Camp',
  'Scudder Pri',
  'Scullen Middle School',
  'Sculptor Charter School',
  'Scuola Vita Nuova Charter',
  'Scurlock Elementary',
  'Scurry-rosser El',
  'Scurry-rosser H S',
  'Scurry-rosser Middle',
  'Sd 27j Preschool At The Brighton Lrc',
  'Sd Global Vision Academy',
  'Sdsbvi Elementary - 01',
  'Sdsbvi High School - 02',
  'Se Area Technical Skills Center',
  'Se Do Mo Cha Elementary School',
  'Se Do Mo Cha Middle School',
  'Se Island Correspondence',
  'Se Neighborhood Sch Of Excellence',
  'Sea Breeze Elementary School',
  'Sea Castle Elementary School',
  'Sea Cliff Elementary School',
  'Sea Gate Elementary School',
  'Sea Girt Elementary School',
  'Sea Isle Elementary',
  'Sea Park Elementary School',
  'Sea Road School',
  'Sea View Elementary',
  'Sea Wind Elementary School',
  'Sea-tech',
  'Seaborn Elementary School',
  'Seabourn El',
  'Seabreeze Elementary School',
  'Seabreeze High School',
  'Seabrook Elementary',
  'Seabrook Elementary School',
  'Seabrook Int',
  'Seabrook Middle School',
  'Seacbec (warren)',
  'Seacoast Charter Academy',
  'Seacoast Charter School',
  'Seacoast School',
  'Seadrift School',
  'Seaford Central Elementary School',
  'Seaford Elementary',
  'Seaford Harbor School',
  'Seaford Manor School',
  'Seaford Middle School',
  'Seaford Senior High School',
  'Seagoville El',
  'Seagoville H S',
  'Seagoville Middle',
  'Seagoville North El',
  'Seagraves Schools',
  'Seagrove Elementary',
  'Seagull Academy',
  'Seagull School',
  'Seahurst Elementary School',
  'Seale J H',
  'Sealey Elementary School',
  'Sealston Elementary',
  'Sealy H S',
  'Sealy J H',
  'Seaman High',
  'Seaman Middle School',
  'Seapco Academy',
  'Seapco Rise',
  'Searcy High School',
  'Searingtown School',
  'Searles Elementary',
  'Searsport District High School',
  'Searsport District Middle School',
  'Searsport Elementary',
  'Seashore Learning Center',
  'Seashore Middle Acad',
  'Seaside Charter K-8 School',
  'Seaside Community Charter School',
  'Seaside Elementary',
  'Seaside Heights Elementary School',
  'Seaside High',
  'Seaside High School',
  'Seaside Middle',
  'Seaside Neighborhood School',
  'Seat Pleasant Elementary',
  'Seatack Elementary An Achievable Dream Academy',
  'Seaton Es',
  'Seattle Central College',
  'Seattle Hill Elementary',
  'Seattle Pacific University',
  'Seattle Skills Center',
  'Seattle University',
  'Seattle World School',
  'Seaview Elementary',
  'Seaview Elementary School',
  'Seawell Elementary',
  'Seba Dalkai Boarding School',
  'Sebago Elementary School',
  'Sebastian Charter Junior High School',
  'Sebastian Elementary School',
  'Sebastian Middle School',
  'Sebastian Pabon Alves (corozo)',
  'Sebastian Questa Elementary',
  'Sebastian River High School',
  'Sebastian River Middle School',
  'Sebasticook Valley Middle School',
  'Sebastopol Attendance Center',
  'Sebastopol Independent Charter',
  'Sebatian Kolowa University College',
  'Sebeka Elementary',
  'Sebeka Secondary',
  'Seberang Perai Polytechnic',
  'Sebha University',
  'Seboyeta Elementary',
  'Sebree Elementary School',
  'Sebring High School',
  'Sebring Middle School',
  'Seca - Is',
  'Secaucus High School',
  'Secaucus Middle School',
  'Seckman Elem.',
  'Seckman Middle',
  'Seckman Sr. High',
  'Seco Mines Elementary',
  'Second Avenue School',
  'Second Chance',
  'Second Chance Academy',
  'Second Chance At Ghazvini Learning Center',
  'Second Creek Elementary School',
  'Second District El Sch',
  'Second Hill Lane School',
  'Second Mesa Day School',
  'Second Street Elementary',
  'Second Street School',
  'Second University of Naples',
  'Secondary Academy For Success',
  'Secondary Adult And Community Ed',
  'Secondary Alter Ctr',
  'Secondary Area Learning Cntr Summer',
  'Secondary Daep',
  'Secondary Head Start Centers',
  'Secondary Lighthouse',
  'Secondary Options',
  'Secondary Sch At Raymond',
  'Secondary School For Journalism',
  'Secondary Special Education',
  'Secondary Student Success Center 801',
  'Secondary Student Success Center 802',
  'Secondary Student Success Center 803',
  'Secondary Student Success Center-804',
  'Secondary Success Program',
  'Secondary Technical Ed Prog S.t.e.p',
  'Secrest Elementary School',
  'Secrist Middle School',
  'Section Elementary',
  'Section High School',
  'Secundino Diaz',
  'Sedalia Elementary',
  'Sedalia Elementary School',
  'Sedalia Middle School',
  'Sedalia Park Elementary School',
  'Sedan Elem',
  'Sedan High',
  'Sedge Garden Elementary',
  'Sedgefield Elementary',
  'Sedgefield Intermediate',
  'Sedgefield Middle',
  'Sedgwick Elementary School',
  'Sedgwick High',
  'Sedgwick Middle School',
  'Sedol Sector Programs',
  'Sedona Charter School',
  'Sedona Integrated Preschool',
  'Sedona Red Rock Junior/senior High School',
  'Sedro Woolley Senior High School',
  'Seeall Academy (the)',
  'Seed Pcs Of Washington Dc',
  'Seedling Mile Elem School',
  'Seeds Of Health Elementary Program',
  'Seeger Memorial Jr-sr High School',
  'Seekonk High',
  'Seele El',
  'Seeley Elementary',
  'Seeley Lake 7-8',
  'Seeley Lake Elementary',
  'Seeley-swan High School',
  'Seely Place School',
  'Seeqs - Sch Examining Essential Questions Of Sustainability',
  'Seffner Elementary School',
  'Segerstrom High',
  'Segi University College',
  'Sego Lily School',
  'Sego Middle School',
  'Segue Inst For Learning',
  'Seguin El',
  'Seguin H S',
  'Segunda Unidad Bo Puerto Real',
  'Segundo Ruiz Belvis',
  'Sehome High School',
  'Seiad Elementary',
  'Seian University of Art & Design',
  'Seiberling Clc',
  'Seidel Learning Center',
  'Seigakuin University',
  'Seijo University',
  'Seikei University',
  'Seiling Es',
  'Seiling Jr-sr Hs (jr)',
  'Seiling Jr-sr Hs (sr)',
  'Seinan Gakuin University',
  'Seisen University',
  'Seiter Education Service Center',
  'Seitz Middle School',
  'Seiwa College',
  'Sejong Academy Of Minnesota',
  'Sejong University',
  'Sekolah Tinggi Akuntansi Negara (STAN)',
  'Sela Pcs',
  'Selah Academy',
  'Selah Academy Reengagement Program',
  'Selah High School',
  'Selah Homelink',
  'Selah Intermediate School',
  'Selah Middle School',
  'Selangor Islamic University College',
  'Selby Elementary - 02',
  'Selby High School - 01',
  'Selby Jr. High - 03',
  'Selby Lane Elementary',
  'Selby Preschool',
  'Selbyville Middle School',
  'Selc',
  'Selcuk University',
  'Selden Middle School',
  'Seldens Landing Elementary',
  'Select Community Day (secondary)',
  'Self Development Academy-phoenix',
  'Self Development Charter School',
  'Self Enhancement Inc/sei Academy',
  'Selfridge Elementary School',
  'Selfridge High School',
  'Seligman Elementary School',
  'Seligman High School',
  'Selinsgrove Area El Sch',
  'Selinsgrove Area Hs',
  'Selinsgrove Area Ms',
  'Selinsgrove Intrmd Sch',
  'Selkirk College',
  'Selkirk Elementary',
  'Selkirk High School',
  'Selkirk Middle School',
  'Sellers Elementary',
  'Sellers Middle',
  'Sellersville El Sch',
  'Sellwood Middle School',
  'Selma Avenue Elementary',
  'Selma Elementary',
  'Selma Elementary School',
  'Selma F Bartlett Elementary School',
  'Selma Herndon Elementary',
  'Selma High',
  'Selma High School',
  'Selma Independent',
  'Selma Middle School',
  'Selma Olinder Elementary',
  'Selma Street Elementary School',
  'Selman El',
  'Selman Int',
  'Selmaville Elem School',
  'Selmer Elementary',
  'Selmer Middle School',
  'Seltice Elementary School',
  'Seltzer Elem',
  'Selvidge Middle',
  'Selwyn Elementary',
  'Selzer Elementary Middle School',
  'Sem El',
  'Sem Yeto Continuation High',
  'Semera University',
  'Semey State Medical University',
  'Semey State University',
  'Seminary Elementary School',
  'Seminary High School',
  'Seminary Hills Park El',
  'Seminary Middle School',
  'Seminole Academy',
  'Seminole Academy Of Digital Learning',
  'Seminole County Detention Center',
  'Seminole County Elementary School',
  'Seminole County Middle/high School',
  'Seminole County Virtual Franchise (scvs)',
  'Seminole El',
  'Seminole Elementary School',
  'Seminole H S',
  'Seminole Heights Charter High School',
  'Seminole High School',
  'Seminole Hs',
  'Seminole J H',
  'Seminole Middle School',
  'Seminole Ms',
  'Seminole Pri',
  'Seminole Ridge Community High Adult',
  'Seminole Ridge Community High School',
  'Seminole Science Charter School',
  'Seminole Springs Elementary School',
  'Seminole State College',
  'Seminole Success Ctr',
  'Seminole Trails Elementary School',
  'Seminole Virtual Instruction (course Offerings)',
  'Seminole Virtual Instruction Program (svip)',
  'Semitropic Elementary',
  'Semmelweis University of Medical Sciences',
  'Semmes Elementary School',
  'Semmes Middle School',
  'Semnan University',
  'Semnan University of Medical Sciences',
  'Semper Elementary School',
  'Semple Elementary',
  'Semyung University',
  'Sen./tate Co. Optional Learning Cen',
  'Senath-hornersville Elem Sch',
  'Senath-hornersville High Schoo',
  'Senath-hornersville Middle Sch',
  'Senatobia Elementary School',
  'Senatobia Jr Sr High School',
  'Senatobia Middle School',
  'Senatobia Tate Co Regional Voc Ctr',
  'Senator James H Donovan Middle School',
  'Sendai University',
  'Sendera Ranch El',
  'Seneca College',
  'Seneca Early Childhood School',
  'Seneca East Elementary',
  'Seneca East High School',
  'Seneca East Middle School',
  'Seneca Elem School South Campus',
  'Seneca Elem.',
  'Seneca Elementary',
  'Seneca Elementary School',
  'Seneca Falls Middle School',
  'Seneca Grade School North Campus',
  'Seneca High',
  'Seneca High School',
  'Seneca Highlands Career And Technical Ce',
  'Seneca Hs',
  'Seneca Intermediate School',
  'Seneca Junior High',
  'Seneca Middle',
  'Seneca Middle School',
  'Seneca Preparatory Academy',
  'Seneca Ridge Middle',
  'Seneca School',
  'Seneca Street School',
  'Seneca Valley High',
  'Seneca Valley Hs',
  'Seneca Valley Ms',
  'Senior Hs',
  'Senita Valley Elementary School',
  'Senn High School',
  'Senn-thomas Middle',
  'Sennett Middle',
  'Senops',
  'Senshu University',
  'Sentinel Career & Technology Center',
  'Sentinel Elementary School',
  'Sentinel High',
  'Sentinel High School',
  'Sentinel Peak High School',
  'Sentinel Tech Alt School',
  'Senzoku Gakuen College',
  'Seokyeong University',
  'Seonam University',
  'Seoul City University',
  'Seoul National University',
  'Seoul National University of Education',
  'Seoul National University of Technology',
  'Seoul Teological University',
  "Seoul Women's University",
  'Seowon University',
  'Sequatchie Co High School',
  'Sequatchie Co Middle School',
  'Sequel Transition Academy - 07',
  'Sequim Community School',
  'Sequim Middle School',
  'Sequim Senior High',
  'Sequiota Elem.',
  'Sequoia Choice School Arizona Distance Learning School',
  'Sequoia Elementary',
  'Sequoia Elementary Charter',
  'Sequoia Elementary School',
  'Sequoia High',
  'Sequoia High School',
  'Sequoia Middle',
  'Sequoia Pathway Academy',
  'Sequoia School',
  'Sequoia Secondary School',
  'Sequoia Union Elementary',
  'Sequoia Village High School',
  'Sequoia Village School',
  'Sequoya Elementary School',
  'Sequoya Middle School',
  'Sequoyah',
  'Sequoyah Elementary',
  'Sequoyah Elementary School',
  'Sequoyah Es',
  'Sequoyah High School',
  'Sequoyah Hs',
  'Sequoyah Middle School',
  'Sequoyah Ms',
  'Ser Youthbuild High School Academy',
  'Ser-ninos Charter El',
  'Ser-ninos Charter El Ii',
  'Ser-ninos Charter Middle',
  'Serena Elem School',
  'Serena High School',
  'Serena Hills Elem School',
  'Serena Middle School',
  'Serene Hills El',
  'Serene Lake Elementary',
  'Serenity High',
  'Serenity Place Academy',
  'Sergeant Bluff-luton Elementary School',
  'Sergeant Bluff-luton Middle School',
  'Sergeant Bluff-luton Primary School',
  'Sergeant Bluff-luton Senior High School',
  'Sergeant Paul R Smith Middle School',
  'Sergeant Samuel K Solomon Elementary School',
  'Serna El',
  'Serra (junipero) Elementary',
  'Serra High',
  'Serrania Avenue Charter For Enriched Studies',
  'Serrano Elementary',
  'Serrano High',
  'Serrano Intermediate',
  'Serrano Middle',
  'Serv By Appt',
  'Service High School',
  'Service Valley Charter Academy',
  'Sese School',
  'Sespe',
  'Sesser-valier Elem School',
  'Sesser-valier High School',
  'Sesser-valier Jr High School',
  'Sessions Elementary',
  'Sessums Elementary School',
  'Setauket Elementary School',
  'Seth Boyden Elementary Demonstration School',
  'Seth G. Haley School',
  'Seth Paine Elem School',
  'Seth Whitman Elem Sch',
  'Seth Woodard Elementary',
  'Seton Hall University',
  'Seton Home',
  'Setsunan University',
  'Settlement Canyon School',
  'Settlement Home',
  'Settlers Point Elementary',
  'Settlers Way El',
  'Settles Bridge Elementary School',
  'Sevastopol Elementary',
  'Sevastopol High',
  'Sevastopol Middle',
  'Sevastopol National Technical University',
  'Sevastopol Pre-school',
  'Seven Bridges Middle School',
  'Seven Generations Charter School',
  'Seven Hills Charter School',
  'Seven Hills El',
  'Seven Hills Intermediate',
  'Seven Hills Prep Academy Lower El',
  'Seven Hills Prep Academy Upper',
  'Seven Hills Preparatory Academy',
  'Seven Lakes H S',
  'Seven Lakes J H',
  'Seven Locks Elementary',
  'Seven Mile Elementary School',
  'Seven Mile School',
  'Seven Oak Middle School',
  'Seven Oaks Classical School',
  'Seven Oaks Elementary',
  'Seven Oaks Elementary School',
  'Seven Pines Elementary',
  'Seven Springs Elementary School',
  'Seven Springs Middle School',
  'Seven-bar Elementary',
  'Seventh Ave',
  'Seventh Ave Elem School',
  'Seventh District Elementary',
  'Seventh Street Elem. School',
  'Seventh Street Elementary',
  'Seventh Street Elementary School',
  'Seventh Street Sch',
  'Seventh Ward Elementary School',
  'Seventy Ninth Street School',
  'Seventy-fifth Street Elementary',
  'Seventy-first Classical Middle',
  'Seventy-first High',
  'Seventy-fourth St. Elementary',
  'Seventy-fourth Street Elementary',
  'Severance Middle School',
  'Severn Elementary',
  'Severn River Middle',
  'Severna Park Elementary',
  'Severna Park High',
  'Severna Park Middle',
  'Severo E. Colberg Ramirez',
  'Sevick Special Education',
  'Sevier Career And Technical Education Center',
  'Sevier Connection Online',
  'Sevier County High School',
  'Sevier Middle',
  'Sevierville Intermediate',
  'Sevierville Middle School',
  'Sevierville Primary',
  'Sevilla Primary School',
  'Sevilla West School',
  'Sewanee Elementary',
  'Sewanee University of the South',
  'Sewanhaka High School',
  'Seward County Community College and Area Technical School',
  'Seward Elem Communication Arts Ac',
  'Seward Elementary',
  'Seward Elementary School',
  'Seward High School',
  'Seward Middle School',
  'Sewell Elementary School',
  'Sewell-anderson',
  'Sewells Point Elementary',
  'Sexton Mountain Elementary School',
  'Seylar El Sch',
  'Seymour Dual Language Academy',
  'Seymour El',
  'Seymour Elem.',
  'Seymour Elementary School',
  'Seymour H S',
  'Seymour High',
  'Seymour High School',
  'Seymour Intermediate',
  'Seymour Middle',
  'Seymour Middle School',
  'Seymour Primary',
  'Seymour Rogers Middle School',
  'Seymour Senior High School',
  'Seymour Smith Intermediate Learning Center',
  'Seymour-jackson Elementary School',
  'Seymour-redding Elementary School',
  'Sgt Leonel Trevino El',
  'Sgt Manuel Chacon Middle',
  'Sgt Roberto Ituarte',
  'Sgt William G Harrell Middle',
  'Sgt Wm H Carney Academy',
  'Shaanxi Normal University',
  'Shabazz-city High',
  'Shabbona Elem School',
  'Shabbona Middle School',
  'Shabonee School',
  'Shackelford El',
  'Shackelford Elementary',
  'Shackelford J H',
  'Shacklette Elementary',
  'Shade Elementary School',
  'Shade Gap El Sch',
  'Shade Jshs',
  'Shades Cahaba Elementary School',
  'Shades Mountain Elementary School',
  'Shades Valley High School',
  'Shadeville Elementary School',
  'Shadle Park High School',
  'Shadow Butte Elementary School',
  'Shadow Creek H S',
  'Shadow Forest El',
  'Shadow Hills Elementary',
  'Shadow Hills High',
  'Shadow Hills Middle',
  'Shadow Lake Elementary',
  'Shadow Mountain High School',
  'Shadow Oaks El',
  'Shadow Oaks Elementary School',
  'Shadow Ridge',
  'Shadow Ridge High School',
  'Shadow Ridge Middle',
  'Shadow Ridge Middle School',
  'Shadow Rock Elementary School',
  'Shadow Valley School',
  'Shadowbriar El',
  'Shadowglen El',
  'Shadowlawn Elementary School',
  'Shady Brook El',
  'Shady Brook Elementary',
  'Shady Cove School',
  'Shady Dell Early Childhood Ctr',
  'Shady Grove El',
  'Shady Grove El Sch',
  'Shady Grove Elementary',
  'Shady Grove Elementary School',
  'Shady Grove Middle',
  'Shady Grove Public School',
  'Shady Grove School',
  'Shady Hill Elementary School',
  'Shady Hills Elementary School',
  'Shady Lane Elementary',
  'Shady Lane Elementary School',
  'Shady Oaks El',
  'Shady Point Public School',
  'Shady Shores El',
  'Shady Side Elementary',
  'Shady Spring Elementary',
  'Shady Spring High',
  'Shady Spring Middle School',
  'Shady Valley Elementary',
  'Shadybrook Elementary',
  'Shadycrest El',
  'Shadydale El',
  'Shadyside High School',
  'Shafer El Sch',
  'Shaffer Elementary',
  'Shaffer Elementary School',
  'Shafter High',
  'Shaftsbury Elementary School',
  'Shah Abdul Latif University Khairpur',
  'Shahala Middle School',
  'Shahed University',
  'Shaheed Beheshti University',
  'Shaheed Beheshti University of Medical Sciences',
  'Shaheed Benazir Bhutto Women University',
  'Shaheed Chamran University',
  'Shaheed Rajaei Teacher Training University',
  'Shaheed Zulifkar Ali Bhutto Institute of Science and Technology',
  'Shahid Bahonar University of Kerman',
  'Shahjalal University of Science and Technology',
  'Shahputra College',
  'Shahrekord University',
  'Shahrekord University of Medical Sciences',
  'Shahrood University of Medical Sciences',
  'Shahrood University of Technology',
  'Shaikh Zayed University',
  'Shakamak Elementary School',
  'Shakamak Jr-sr High School',
  'Shaker High School',
  'Shaker Hts High School',
  'Shaker Hts Middle School',
  'Shaker Junior High School',
  'Shaker Lane Elementary',
  'Shaker Road Elementary School',
  'Shakerag Elementary School',
  'Shakopee High School',
  'Shakopee Jr Alc',
  'Shakopee Junior High East',
  'Shakopee Junior High West',
  'Shakopee Sr Alc',
  'Shaktoolik School',
  'Shaler Academy',
  'Shaler Area El Sch',
  'Shaler Area Hs',
  'Shaler Area Ms',
  'Shalimar Elementary School',
  'Shallotte Middle',
  'Shallow Brook Inter Sch',
  'Shallowater Early College H S/ Woodward Academy',
  'Shallowater El',
  'Shallowater H S',
  'Shallowater Int',
  'Shallowater Middle',
  'Shallowford Falls Elementary School',
  'Shalom High',
  'Shamokin Area El Sch',
  'Shamokin Area Hs',
  'Shamokin Area Intermediate Sch',
  'Shamokin Area Ms',
  'Shamona Creek El Sch',
  'Shamrock',
  'Shamrock Colony Elementary - 05',
  'Shamrock El',
  'Shamrock Gardens Elementary',
  'Shamrock H S',
  'Shamrock Middle',
  'Shamrock Springs Elementary School',
  'Shandin Hills Middle',
  'Shandon Community Day',
  'Shandon Elementary',
  'Shandon High',
  'Shandong Agricultural University',
  'Shandong Economic University',
  'Shandong Medical University',
  'Shandong Normal University',
  'Shandong University',
  'Shandong University of Art and Design',
  'Shandong University of Science and Technology',
  'Shandong University of Technology',
  'Shandong University of Triaditional Chinese Medicine',
  'Shands El',
  'Shaner Elem',
  'Shanghai Business School',
  'Shanghai City College',
  'Shanghai Customs College',
  'Shanghai Dainji University',
  'Shanghai Fisheries University',
  'Shanghai Institue of Foreign Trade',
  'Shanghai International Studies University',
  'Shanghai Jiaotong University',
  'Shanghai Lida Polytechnic Institute',
  'Shanghai Lixin University of Commerce',
  'Shanghai Maritime University',
  'Shanghai Medical University',
  'Shanghai Normal University',
  'Shanghai Ouhua Vocational Technical College',
  'Shanghai Sanda University',
  'Shanghai Second Medical University',
  'Shanghai Second Polytechnic University',
  'Shanghai Sipo Polytechnic',
  'Shanghai Television University',
  'Shanghai TieDao University',
  'Shanghai University',
  'Shanghai University of Engineering Science',
  'Shanghai University of Finance and Economics',
  'Shanghai University of Science and Technology',
  'Shanghai University of Traditional Chinese Medicine and Pharmacology',
  'Shanksville-stonycreek El Sch',
  'Shanksville-stonycreek Hs',
  'Shanksville-stonycreek Ms',
  'Shanmugha Arts Science Technology and Research Academy',
  'Shannock Valley El Sch',
  'Shannon College of Hotel Management',
  'Shannon Colony Elementary - 08',
  'Shannon Elementary',
  'Shannon Elementary School',
  'Shannon H S',
  'Shannon High School',
  'Shannon Johnson Elementary School',
  'Shannon Middle School',
  'Shannon Park Elementary',
  'Shannon Primary School',
  'Shannon Ranch Elementary',
  'Shannondale Elementary',
  'Shantou University',
  'Shanxi Agricultural University',
  'Shanxi Normal University',
  'Shanxi University',
  'Shaoguan University',
  'Shapiro Stem Academy',
  'Shapleigh Memorial School',
  'Shapleigh School',
  'Shaqra University',
  'Shared Journeys',
  'Shared Services Integrated Preschool',
  'Shared Time',
  'Shared Time Nonpublic',
  'Shared Time Program',
  'Sharif University of Technology',
  'Sharif University of Technology Kish Campus',
  'Shark River Hills Elementary School',
  'Sharon A Richardson El',
  'Sharon C. Mcauliffe Elementary',
  'Sharon Center School',
  'Sharon Christa Mcauliffe Middle',
  'Sharon Community',
  'Sharon Dawes Elementary School',
  'Sharon Early Childhood Center',
  'Sharon Elementary',
  'Sharon Elementary School',
  'Sharon High',
  'Sharon Hill Sch',
  'Sharon Hills Elementary School',
  'Sharon Hs',
  'Sharon J Hardy Elementary School',
  'Sharon Middle',
  'Sharon Ms',
  'Sharon School',
  'Sharon Shannon El',
  'Sharon Springs Central School',
  'Sharon Springs Elem',
  'Sharon-mutual Es',
  'Sharon-mutual Hs',
  'Sharonville Elementary School',
  'Sharp Avenue Elementary',
  'Sharp Creek Elementary School',
  'Sharp El',
  'Sharp Park Academy',
  'Sharp-leadenhall Elementary',
  'Sharpe Elementary',
  'Sharpe Elementary School',
  'Sharps Chapel Elementary',
  'Sharpsburg Elementary',
  'Sharpsburg Elementary School',
  'Sharpsburg Primary',
  'Sharpstein Elementary School',
  'Sharpstown H S',
  'Sharpstown International School',
  'Sharpsville Area El Sch',
  'Sharpsville Area Ms',
  'Sharpsville Area Shs',
  'Sharswood George Sch',
  'Sharyland Advanced Academic Academy',
  'Sharyland H S',
  'Sharyland North J H',
  'Sharyland Pioneer H S',
  'Shasta Charter Academy',
  'Shasta College',
  'Shasta County Independent Study Charter',
  'Shasta County Juvenile Court',
  'Shasta County Special Education',
  'Shasta Elementary',
  'Shasta Elementary School',
  'Shasta High',
  'Shasta Lake',
  'Shasta Meadows Elementary',
  'Shasta Middle School',
  'Shasta Plus',
  'Shasta-trinity Rop',
  'Shatekon Elementary School',
  'Shattuck Educational Park',
  'Shattuck Es',
  'Shattuck Hs',
  'Shattuck Independent Study',
  'Shattuck Middle',
  'Shaull El Sch',
  'Shaver Elementary School',
  'Shaw Academy',
  'Shaw Avenue School',
  'Shaw Butte School',
  'Shaw El',
  'Shaw Elementary School',
  'Shaw Heights Elementary',
  'Shaw Heights Middle School',
  'Shaw High School',
  'Shaw Island Elementary School',
  'Shaw Middle School',
  'Shaw Road Elementary',
  'Shaw Ses',
  'Shaw Visual/perf. Arts Ctr.',
  'Shawano Community Middle',
  'Shawano High',
  'Shawanoe Elem',
  'Shawboro Elementary School',
  'Shawmont Sch',
  'Shawmut Hills School',
  'Shawmut School',
  'Shawnee Community College',
  'Shawnee Early Childhood School',
  'Shawnee Ec Ctr',
  'Shawnee El Sch',
  'Shawnee Elem.',
  'Shawnee Elementary',
  'Shawnee Elementary School',
  'Shawnee Heights Elem',
  'Shawnee Heights High',
  'Shawnee Heights Middle',
  'Shawnee High School',
  'Shawnee Hs',
  'Shawnee Jr High School',
  'Shawnee Middle School',
  'Shawnee Mission East High',
  'Shawnee Mission North High',
  'Shawnee Mission Northwest High',
  'Shawnee Mission South High',
  'Shawnee Mission West High',
  'Shawnee Ms',
  'Shawnee State University',
  'Shawnee Trail El',
  'Shawono Center',
  'Shawsheen Elementary',
  'Shawsheen Elementary School',
  'Shawsheen School',
  'Shawsheen Valley Vocational Technical High School',
  'Shawsville Middle',
  'Shawswick Elementary School',
  'Shawswick Middle School',
  'Shay Elementary School',
  'Shea Middle School',
  'Shead High School',
  'Sheafe Road Elementary School',
  'Shear Early Childhood Center',
  'Shearer Elementary',
  'Shearer Elementary School',
  'Shearn El',
  'Sheboygan Falls Elementary',
  'Sheboygan Falls High',
  'Sheboygan Falls Middle',
  'Sheboygan Leadership Academy',
  'Shedeck Es',
  'Sheehy Elementary School',
  'Sheeler High Charter',
  'Sheely Farms Elementary School',
  'Sheep-harney Elementary',
  'Sheffey Elementary',
  'Sheffield Area El Sch',
  'Sheffield El',
  'Sheffield Elementary',
  'Sheffield Elementary School',
  'Sheffield Hallam University',
  'Sheffield High',
  'Sheffield High School',
  'Sheffield Junior High School',
  'Sheffield M/hs',
  'Sheikhbahaee University',
  'Sheiko Elementary School',
  'Sheila R. Tarr Elementary School',
  'Sheila Skip Nowell (capital)',
  'Sheila Skip Nowell (central)',
  'Shelbina Elem.',
  'Shelburne Community School',
  'Shelburne Middle',
  'Shelby - Rising City Elementary School',
  'Shelby - Rising City High School',
  'Shelby - Rising City Middle School',
  'Shelby 7-8',
  'Shelby Co Education Center',
  'Shelby County Area Technology Center',
  'Shelby County College And Career Center',
  'Shelby County East Middle School',
  'Shelby County Headstart',
  'Shelby County High School',
  'Shelby County Summer School',
  'Shelby County West Middle School',
  'Shelby Elementary School',
  'Shelby High',
  'Shelby High School',
  'Shelby Intermediate',
  'Shelby Junior High School',
  'Shelby Middle',
  'Shelby Middle School',
  'Shelby Oaks Elementary',
  'Shelby School',
  'Shelby Traditional Academy',
  'Shelby Valley Day Treatment',
  'Shelby Valley High School',
  'Shelbyville Bridges',
  'Shelbyville Central High School',
  'Shelbyville High School',
  'Shelbyville Kindergarten Center',
  'Shelbyville Middle School',
  'Shelbyville School',
  'Shelbyville Sr High School',
  'Sheldon Clark High School',
  'Sheldon Early Childhood Academy',
  'Sheldon El',
  'Sheldon Elem.',
  'Sheldon Elementary',
  'Sheldon Elementary School',
  'Sheldon High',
  'Sheldon High School',
  'Sheldon Jjaep',
  'Sheldon Middle School',
  'Sheldon Pines School',
  'Sheldon Woods Elementary School',
  'Shell Beach Elementary',
  'Shell Creek Elementary School',
  'Shell Knob Elem.',
  'Shell Lake Elementary (3-6)',
  'Shell Lake Junior/senior High',
  'Shell Lake Primary (k-2)',
  'Shell Rock Elementary School',
  'Shelledy Elementary School',
  'Shelley Berkley Elementary School',
  'Shelley S. Boone Middle School',
  'Shelley School',
  'Shelley Senior High School',
  'Shellsburg Elementary School',
  'Shelter Cove',
  'Shelter Island School',
  'Shelter Rock Elementary School',
  'Shelter Rock School',
  'Shelters Elementary School',
  'Shelton Elementary School',
  'Shelton High School',
  'Shelton Park Elementary',
  'Shelton State Community College',
  'Shelton View Elementary',
  'Shelyn Elementary',
  'Shenandoah Elem.',
  'Shenandoah Elementary',
  'Shenandoah Elementary School',
  'Shenandoah High School',
  'Shenandoah Middle School',
  'Shenandoah Street Elementary',
  'Shenandoah University',
  'Shenandoah Valley',
  'Shenandoah Valley El Sch',
  'Shenandoah Valley Elem.',
  "Shenandoah Valley Governor's School",
  'Shenandoah Valley Jshs',
  'Shenandoah Valley Juvenile Detention Home',
  'Shenandoah Valley Reg',
  'Shenandoah Valley Reg Alternative Ed/genesis',
  'Shenango El Sch',
  'Shenango Hs',
  'Shendi University',
  'Shenendehowa High School',
  'Shenkar School of Engineering & Design',
  'Shenyang Institute of Chemical Technology',
  'Shenyang Jianzhu University',
  'Shenyang Pharmaceutical University',
  'Shenyang Polytechnic University',
  'Shenyang University',
  'Shenzhen University',
  'Shepard Blvd. Elem.',
  'Shepard El',
  'Shepard Hill Elementary School',
  'Shepard Hills Elementary',
  'Shepardson Elementary School',
  'Shepaug Valley School',
  'Shepherd Elementary',
  'Shepherd Elementary School',
  'Shepherd Es',
  'Shepherd Glen School',
  'Shepherd H S',
  'Shepherd High School',
  'Shepherd Hill Regional High',
  'Shepherd Int',
  'Shepherd Junior High School',
  'Shepherd Middle',
  'Shepherd Middle School',
  'Shepherd Pri',
  'Shepherdstown El Sch',
  'Shepherdstown Elementary School',
  'Shepherdstown Middle School',
  'Shepherdsville Elementary',
  'Sheppard Afb El',
  'Sheppard Elementary',
  'Sheppard Isaac Sch',
  'Shepton H S',
  'Sher-E-Kashmir University of Agricultural Sciences and Technology',
  'Sherando High',
  'Sherard Elementary School',
  'Sherburn Elementary',
  'Sherburne-earlville Elementary School',
  'Sherburne-earlville Middle School',
  'Sherburne-earlville Senior High School',
  'Sheridan',
  'Sheridan 7-8',
  'Sheridan Academy For Young Leaders',
  'Sheridan Allprep Academy',
  'Sheridan College',
  'Sheridan El',
  'Sheridan El Sch',
  'Sheridan Elem',
  'Sheridan Elem Math & Science Acad',
  'Sheridan Elem School',
  'Sheridan Elementary',
  'Sheridan Elementary Schl',
  'Sheridan Elementary School',
  'Sheridan Green Elementary School',
  'Sheridan High School',
  'Sheridan Hill Elementary School',
  'Sheridan Hills Elementary',
  'Sheridan Hills Elementary School',
  'Sheridan Intermediate School',
  'Sheridan Japanese School',
  'Sheridan Junior High School',
  'Sheridan Middle School',
  'Sheridan Park Elementary School',
  'Sheridan Preparatory Academy',
  'Sheridan Road',
  'Sheridan Sch',
  'Sheridan School',
  'Sheridan Street Elementary',
  'Sheridan Technical College',
  'Sheridan Terrace El Sch',
  'Sheridan Way Elementary',
  "Sheriff's Detention Center",
  "Sheriff's Youth Villa",
  'Sherlock Elem School',
  'Sherman',
  'Sherman Ave Elementary',
  'Sherman County School',
  'Sherman Early Childhood Center',
  'Sherman El',
  'Sherman Elem School',
  'Sherman Elementary',
  'Sherman Elementary School',
  'Sherman H S',
  'Sherman High School',
  'Sherman Indian High School',
  'Sherman Junior High School',
  'Sherman Middle',
  'Sherman Oaks Center For Enriched Studies',
  'Sherman Oaks Elementary',
  'Sherman Oaks Elementary Charter',
  'Sherman School',
  'Sherman Thomas Charter',
  'Sherman Thomas Charter High',
  'Sherman Thomas Stem Academy',
  'Sherrard Elementary School',
  'Sherrard High School',
  'Sherrard Jr High School',
  'Sherrard Middle School',
  'Sherrelwood Elementary School',
  'Sherrills Ford Elementary',
  'Sherrod El',
  'Sherrod Elementary',
  'Sherrouse School',
  'Sherwood',
  'Sherwood Acres Elementary School',
  'Sherwood Charter School',
  'Sherwood El',
  'Sherwood Elem School',
  'Sherwood Elem.',
  'Sherwood Elementary',
  'Sherwood Elementary School',
  'Sherwood Forest Elementary',
  'Sherwood Forest Elementary School',
  'Sherwood Githens Middle',
  'Sherwood Global Studies Academy',
  'Sherwood Heights Elementary Sch',
  'Sherwood Heights Elementary School',
  'Sherwood High',
  'Sherwood High School',
  'Sherwood Middle',
  'Sherwood Middle Academic Academy',
  'Sherwood Middle School',
  'Sherwood Montessori',
  'Sherwood Park Elementary',
  'Shery (kurt T.) High (continuation)',
  'Shettler School',
  'Sheyenne Valley Area Career & Tech Center',
  'Sheyenne Valley Spec Ed Unit',
  'Shiawassee Resd Student Learning Center West',
  'Shibaura Institute of Technology',
  'Shickley Elementary School',
  'Shickley High School',
  'Shidler Es',
  'Shidler Hs',
  'Shidler Ms',
  'Shields (richard A.) Elementary School',
  'Shields El',
  'Shields Elem School',
  'Shields Elementary School',
  'Shields Middle School',
  'Shields Valley 7-8',
  'Shields Valley Elementary',
  'Shields Valley High Schl',
  'Shiga University',
  'Shiga University of Medical Science',
  'Shih Chien University',
  'Shih Hsin University',
  'Shihezi University',
  'Shikellamy Hs',
  'Shikellamy Ms',
  'Shikoku Christian College',
  'Shikoku University',
  'Shiloh Charter',
  'Shiloh Elementary',
  'Shiloh Elementary School',
  'Shiloh High School',
  'Shiloh Hills El Sch',
  'Shiloh Hills Elementary',
  'Shiloh Middle',
  'Shiloh Middle School',
  'Shiloh Park Elem School',
  'Shiloh Point Elementary',
  'Shimane University',
  'Shimane University Faculty of Medicine',
  'Shimonoseki City University',
  'Shinawatra University',
  'Shiner El',
  'Shiner H S',
  'Shingle Creek Elementary',
  'Shining Mountain Elementary',
  'Shining Mountain Preschool',
  'Shining Rock Classical Academy: Cfa',
  'Shining Star Early Childhood Center',
  'Shining Stars Montessori Academy Pcs',
  'Shining Stars Preschool',
  'Shinshu University',
  'Shiocton Elementary',
  'Shiocton High',
  "Shipley's Choice Elementary",
  'Shipman Elem School',
  'Shippensburg Area Ms',
  'Shippensburg Area Shs',
  'Shippensburg Intrm Sch',
  'Shippensburg University of Pennsylvania',
  'Shiprock High',
  'Shipshewana-scott Elementary Sch',
  'Shirakawa (george Sr.) Elementary',
  "Shirayuri Women's College",
  'Shiraz Payamnoor University',
  'Shiraz University',
  'Shiraz University of Medical Sciences',
  'Shiraz University of Technology',
  'Shirland C C School',
  'Shirleen Zacharias Early Clge Leadership Academy',
  'Shirley & Bill Wallin Elementary School',
  'Shirley Avenue Elementary',
  'Shirley C. Heim Middle',
  'Shirley D. Simmons Middle School',
  'Shirley Dill Brothers El',
  'Shirley Elementary School',
  'Shirley High School',
  'Shirley Hills Elementary School',
  'Shirley Hills Primary School',
  'Shirley Johnson/gretna Park Elementary School',
  'Shirley Lane Elementary',
  'Shirley Mann Elementary School',
  'Shirley Twp El Sch',
  'Shirpser Elementary',
  'Shishmaref School',
  'Shivaji University',
  'Shive El',
  'Shive Elementary',
  'Shivela Middle',
  'Shiver Elementary School',
  "Shiwi Ts'ana Elementary",
  'Shizuoka Prefectural University',
  'Shizuoka Sangyo University',
  'Shizuoka University',
  'Shoal Creek Elem.',
  'Shoal Creek Elementary',
  'Shoal Creek Elementary School',
  'Shoal River Middle School',
  'Shoally Creek Elementary',
  'Shoals Community Elementary School',
  'Shoals Community High School',
  'Shoals Elementary',
  'Shoals Elementary School',
  'Shoals Middle School',
  'Shobhit University Meerut',
  'Shoemaker El Sch',
  'Shoemaker Elementary',
  'Shoemaker Elementary School',
  'Shoesmith Elem School',
  'Shohola El Sch',
  'Shokei College',
  'Sholund School For Girls',
  'Shomal University',
  'Shonan Institute of Technology',
  'Shongum School',
  'Shonto Preparatory School',
  'Shonto Preparatory Technology High School',
  'Shook Elem.',
  'Shoop Math-sci Tech Elem Academy',
  'Shopville Elementary School',
  'Shore Acres Elementary',
  'Shore Acres Elementary School',
  'Shore Elementary Magnet School',
  'Shore Middle School',
  'Shore Regional High School',
  'Shore Road School',
  'Shorecliffs Middle',
  'Shorecrest High School',
  'Shoreham Elementary School',
  'Shoreham-wading River High School',
  'Shorehaven El',
  'Shoreland Elementary School',
  'Shoreline Academy',
  'Shoreline Community College',
  'Shoreline Elementary School',
  'Shoreline Middle',
  'Shoreline-monroe High School',
  'Shoreview Elementary School',
  'Shorewood Elementary',
  'Shorewood High',
  'Shorewood High School',
  'Shorewood Hills Elementary',
  'Shorewood Intermediate',
  'Short Avenue Elementary',
  'Short El',
  'Short Elementary',
  'Short Line School',
  'Short Mountain Elementary',
  'Short Pump Elementary',
  'Short Pump Middle',
  'Shorter College',
  'Shortlidge (evan G.) Academy',
  'Shortridge High School',
  'Shoshone Elementary',
  'Shoshone Elementary School',
  'Shoshone High (continuation)',
  'Shoshone High School',
  'Shoshone Learning Center',
  'Shoshone Middle School',
  'Shoshone-bannock School District No 512',
  'Shoshoni Elementary',
  'Shoshoni High School',
  'Shoshoni Junior High School',
  'Shotwell Middle',
  'Shoultes Elementary',
  'Shoumen University "Konstantin Preslavski"',
  'Show Low High School',
  'Show Low Junior High School',
  'Showa College of Pharmaceutical Sciences',
  'Showa University',
  "Showa Women's University",
  'Showalter Middle School',
  'Showell (phillip C.) Elementary School',
  'Showell Elementary',
  "Shreemati Nathibai Damodar Thackersey Women's University",
  'Shreve Elementary School',
  'Shreve Island Elementary School',
  'Shrevewood Elementary',
  'Shrewsbury Borough Elementary School',
  'Shrewsbury El Sch',
  'Shrewsbury Mountain School',
  'Shrewsbury Sr High',
  'Shri Jagannath Sanskrit University',
  'Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha',
  'Shridhar University',
  'Shrine Hospital',
  'Shrine School',
  'Shriners Hospitals For Children',
  'Shroder Paideia High School',
  'Shs Graduation Alliance',
  'Shuchiin College',
  'Shue-medill Middle School',
  'Shuford Elementary',
  'Shugart El',
  "Shujitsu Women's University",
  'Shuksan Middle School',
  'Shukutoku University',
  'Shuler Elementary',
  'Shull Early Learning Center',
  'Shull Elem.',
  'Shull-david El Sch',
  'Shullsburg Elementary',
  'Shullsburg High',
  'Shullsburg Junior High',
  'Shuman Elementary School',
  'Shumate Middle School',
  'Shumei University',
  'Shumway Leadership Academy',
  'Shungnak School',
  'Shurtleff Early Childhood',
  'Shutesbury Elementary',
  'Shwab Elementary',
  'Siam Learning Center',
  'Siam University',
  'Siatech',
  'Siatech Academy South',
  'Siatech Gainesville',
  'Siatech High Charter',
  'Siauliai University',
  'Sibelius Academy',
  'Siberian Academy of Public Services',
  'Siberian State Industrial University',
  'Siberian State Medical University',
  'Siberian State Transport University',
  'Siberian State University of Telecommunications and Informatics',
  'Sibley East Middle School',
  'Sibley East-arlington Elementary',
  'Sibley East-arlington Senior High',
  'Sibley East-gaylord Elementary',
  'Sibley Elementary',
  'Sibley Ocheyedan Elementary School',
  'Sibley School',
  'Sibley-ocheyedan High School',
  'Sibley-ocheyedan Middle School',
  'Sichuan Agricultural University',
  'Sichuan Fine Art Institute',
  'Sichuan International Studies University',
  'Sichuan Normal University',
  'Sichuan University',
  'Sicily Island High School',
  'Sickles High School',
  'Sicomac Elementary School',
  'Side By Side Charter School',
  'Side Creek Elementary School',
  'Sidney Alternative Program',
  'Sidney C. Huntington Elementary',
  'Sidney C. Huntington Jr/sr High School',
  'Sidney Deener Elem. School',
  'Sidney Elementary School',
  'Sidney Fenn Elementary School',
  'Sidney Glen Elementary School',
  'Sidney Gutierrez Middle',
  'Sidney H Poynter',
  'Sidney Head Start',
  'Sidney High School',
  'Sidney Lanier Center',
  'Sidney Lanier Expressive Arts Vanguard',
  'Sidney Middle School',
  'Sidney School',
  'Sido Kanhu Murmu University',
  'Siebert El',
  'Siebert Elementary School',
  'Siebert School',
  'Siebold University of Nagasaki',
  'Sieden Prairie Elementary School',
  'Siefert Elementary',
  'Siegel High School',
  'Siegel Middle School',
  'Siembra Leadership High School',
  'Siena Elementary',
  'Sienna Crossing El',
  'Sierra 2-8 School',
  'Sierra Academy Of Expeditionary Learning',
  'Sierra Alternative',
  'Sierra Alternative Learning Academy',
  'Sierra Avenue Elementary',
  'Sierra Blanca School',
  'Sierra Bonita Elementary',
  'Sierra Charter',
  'Sierra College',
  'Sierra County Special Education',
  'Sierra Del Oro',
  'Sierra Early Education Program',
  'Sierra Elementary',
  'Sierra Elementary School',
  'Sierra Expeditionary Learning',
  'Sierra Foothill Charter',
  'Sierra Grande K-12 School',
  'Sierra Heights Elementary School',
  'Sierra High',
  'Sierra High (continuation)',
  'Sierra High School',
  'Sierra Hills Elementary',
  'Sierra Home',
  'Sierra House Elementary',
  'Sierra Junior High',
  'Sierra Lakes Elementary',
  'Sierra Linda Elementary',
  'Sierra Linda High School',
  'Sierra Madre Elementary',
  'Sierra Madre High (continuation)',
  'Sierra Madre Middle',
  'Sierra Middle',
  'Sierra Middle School',
  'Sierra Montessori Academy',
  'Sierra Nevada Academy Charter School',
  'Sierra Oaks K-8',
  'Sierra Pacific High',
  'Sierra Park Elementary',
  'Sierra Pass (continuation)',
  'Sierra Preparatory Academy',
  'Sierra Primary',
  'Sierra Ridge Middle',
  'Sierra Verde Elementary',
  'Sierra View Elementary',
  'Sierra Vista',
  'Sierra Vista Charter High',
  'Sierra Vista Education Center',
  'Sierra Vista El',
  'Sierra Vista Elementary',
  'Sierra Vista Elementary School',
  'Sierra Vista High',
  'Sierra Vista High (alternative)',
  'Sierra Vista High (continuation)',
  'Sierra Vista High School',
  'Sierra Vista Junior High',
  'Sierra Vista K-8',
  'Sierra Vista Middle',
  'Sierra Vista Middle School',
  'Sierra Vista Online',
  'Sierra Vista Primary',
  'Sierra-enterprise Elementary',
  'Sierra-osage Treatment Ctr.',
  'Sierramont Middle',
  'Siersma Elementary School',
  'Sifton Elementary School',
  'Sig Rogich Middle School',
  'Sigel Elem. Comm. Ed. Ctr.',
  'Sigifredo Perez El',
  'Sigler El',
  'Signal Hill Elem School',
  'Signal Hill Elementary',
  'Signal Hill Elementary School',
  'Signal Hill School',
  'Signal Knob Middle',
  'Signal Mountain Middle/high School',
  'Signature School Inc',
  'Sigourney Elementary',
  'Sigourney Jr-sr High Sch',
  'Sigsbee Charter School',
  'Sigwalt Cls Center',
  'Siirt University',
  'Sikeston Career Tech. Ctr.',
  'Sikeston Hope Ctr.',
  'Sikeston Kindergarten Ctr.',
  'Sikeston Sr. High',
  "Sikha 'O' Anushandhan University",
  'Sikkim Manipal University of Health Medical and Technological Sciences',
  'Silas Bartsch',
  'Silas Deane Middle School',
  'Silas Willard Elem School',
  'Silas Wood 6th Grade Center',
  'Siler City Elementary',
  'Silesian Academy of Medicine in Katowice',
  'Silesian School of Economics and Languages',
  'Silesian Technical University of Gliwice',
  'Silesian University',
  'Siletz Valley Early College Academy',
  'Siletz Valley Schools',
  'Silex Elem.',
  'Silex High',
  'Siliaga Elementary School',
  'Silicon Valley Career Technical Education',
  'Silk Hope School',
  'Silla University',
  'Silliman University',
  'Silo Es',
  'Silo Hs',
  'Silo Ms',
  'Siloam Springs High School Conversion Charter',
  'Siloam Springs Intermediate School',
  'Siloam Springs Middle School',
  'Silpakorn University',
  'Silsbee El',
  'Silsbee H S',
  'Silva Health Magnet',
  'Silva Valley Elementary',
  'Silver Bay Elementary School',
  'Silver Beach Elementary School',
  'Silver Bluff Elementary School',
  'Silver Bluff High',
  'Silver City Elem',
  'Silver City Elementary School',
  'Silver Creek El',
  'Silver Creek Elementary',
  'Silver Creek Elementary School',
  'Silver Creek High',
  'Silver Creek High School',
  'Silver Creek Middle',
  'Silver Creek Middle School',
  'Silver Creek Primary School',
  'Silver Crest Elementary School',
  'Silver Crest School',
  'Silver Firs Elementary',
  'Silver Fork Elementary',
  'Silver Gate Elementary',
  'Silver Grove School',
  'Silver High',
  'Silver Hill Horace Mann Charter School',
  'Silver Hills Elementary School',
  'Silver Hills Middle School',
  'Silver Hills School',
  'Silver Lake Colony - 07',
  'Silver Lake El',
  'Silver Lake Elem',
  'Silver Lake Elem Sch-bladen',
  'Silver Lake Elementary - Everett',
  'Silver Lake Elementary School',
  'Silver Lake Elementary School - Federal Way',
  'Silver Lake High School',
  'Silver Lake Intermediate',
  'Silver Lake Jr-sr High',
  'Silver Lake Regional High',
  'Silver Lake Regional Middle School',
  'Silver Lakes Elementary School',
  'Silver Lakes Middle School',
  'Silver Lane School',
  'Silver Mesa School',
  'Silver Oak Elementary',
  'Silver Oak High Public Montessori Charter',
  'Silver Palms Elementary School',
  'Silver Peak Elementary School',
  'Silver Rail Elementary School',
  'Silver Ridge Elementary',
  'Silver Ridge Elementary School',
  'Silver River Mentoring And Instruction',
  'Silver Run Elementary School',
  'Silver Sage Elementary School',
  'Silver Sands Middle School',
  'Silver Sands Montessori',
  'Silver Sands-excep. Children',
  'Silver Shores Elementary School',
  'Silver Spring El Sch',
  'Silver Spring International Middle',
  'Silver Spring School',
  'Silver Springs Elementary School',
  'Silver Springs High (continuation)',
  'Silver Spur Elementary',
  'Silver Stage Elementary School',
  'Silver Stage High School',
  'Silver Stage Middle School',
  'Silver Star Center',
  'Silver Star Elementary School',
  'Silver Strand Elementary',
  'Silver Summit Academy',
  'Silver Summit School',
  'Silver Trail Elementary School',
  'Silver Trail Middle School',
  'Silver Valley Academy',
  'Silver Valley Community Day',
  'Silver Valley Elementary',
  'Silver Valley High',
  'Silver Wing Elementary',
  'Silverado High',
  'Silverado High School',
  'Silverado Middle',
  'Silverbrook Elementary',
  'Silverbrook Intermediate',
  'Silvercrest El',
  'Silverdale Elementary',
  'Silverhill School',
  'Silverlake El',
  'Silverland Middle School',
  'Silvermine Elementary School',
  'Silverthorne Elementary School',
  'Silverton Elementary School',
  'Silverton High School',
  'Silverton Middle School',
  'Silverton Paideia Elementary School',
  'Silverton School',
  'Silverwood Elementary',
  'Silvestre Martinez',
  'Silvestre S Herrera School',
  'Silvies River Charter School',
  'Silvio O Conte Community',
  'Simeon Career Academy High School',
  'Simi Valley High',
  'Simla Elementary School',
  'Simla High School',
  'Simla Junior High School',
  'Simle Middle School',
  'Simley Alternative Program',
  'Simley Senior High',
  'Simmons Career Center',
  'Simmons College',
  'Simmons El Sch',
  'Simmons Elementary - 10',
  'Simmons Elementary School',
  'Simmons Exceptional Center',
  'Simmons High School',
  'Simmons Middle School',
  'Simmons Middle School - 03',
  'Simmons Pinckney Middle',
  'Simms High School',
  'Simon Es',
  'Simon Fraser University',
  'Simon Kenton High School',
  'Simon Madera',
  'Simon Rodia Continuation',
  'Simon Sanchez High School',
  'Simon Youth Academy',
  'Simon Youth Found Acad At Outlet Mktplce',
  "Simon's Rock College",
  'Simonds Elementary',
  'Simonds Elementary School',
  'Simons Middle',
  'Simons Middle School',
  'Simonsdale Elementary',
  'Simonton Elementary School',
  'Simpson Acad Hs For Young Women',
  'Simpson Avenue Elementary',
  'Simpson Central School',
  'Simpson Co Technical Center',
  'Simpson College',
  'Simpson County Achievement Center',
  'Simpson Elem',
  'Simpson Elementary School',
  'Simpson High School',
  'Simpson Middle School',
  'Simpson-waverly School',
  'Simpsonville Elementary',
  'Sims El',
  'Sims Elementary School',
  'Sims Middle',
  'Simsboro High School',
  'Simsbury High School',
  'Sinagua High School - Closed',
  'Sinagua Middle School',
  'Sinai Elementary',
  'Sinai University',
  'Sinaloa Middle',
  'Sinclair Community College',
  'Sinclair El',
  'Sinclair Lane Elementary',
  'Sinclairville Elementary School',
  'Sinclear Elementary',
  'Sindh Agricultural University',
  'Sinforoso Aponte',
  'Sing Lum Elementary',
  'Singapore Institute of Management (SIM)',
  'Singapore Management University',
  'Singer High School',
  'Singidunum University',
  'Singing Hills Elementary School',
  'Singing Hills Preschool',
  'Singing River Academy',
  'Singleton Es',
  'Sinking Fork Elementary School',
  'Sinking Springs El Sch',
  'Sinnar University',
  'Sinop University',
  'Sinsheimer Elementary',
  'Sinton El',
  'Sinton H S',
  'Sioux Center High School',
  'Sioux Center Middle School',
  'Sioux Central Elementary School',
  'Sioux Central High',
  'Sioux Central Middle School',
  'Sioux County High School',
  'Sioux Trail Elementary',
  'Sioux Valley Elementary - 02',
  'Sioux Valley High School - 01',
  'Sioux Valley Middle School - 04',
  'Siple Elementary School',
  'Sippican',
  'Sipsey Valley High School',
  'Sipsey Valley Middle School',
  'Sir Francis Drake High',
  'Sir Syed Institute Of Technology Islamabad',
  'Sir Syed University of Engineering and Technology',
  'Siren Elementary',
  'Siren High',
  'Sirnak University',
  'Sirrine Elementary School',
  'Sirte University',
  'Sisk Elementary',
  'Siskiyou County Rop',
  'Siskiyou County Special Education',
  'Sisseton High School - 01',
  'Sisseton Middle School - 02',
  'Sisseton Wahpeton College',
  'Sisson',
  'Sissonville Elementary School',
  'Sissonville High School',
  'Sissonville Middle School',
  'Sistan & Balouchestan University',
  'Sistema Universitario Ana G. Méndez',
  'Sister Lakes Elementary School',
  'Sister Robert Joseph Bailey Elementary School',
  'Sisters Elementary School',
  'Sisters High School',
  'Sisters Middle School',
  'Sistersville Elementary School',
  'Sisulu-walker Charter School Of Harlem',
  'Sitka High School',
  'Sitka Reach',
  'Sitting Bull Academy',
  'Sitting Bull School',
  'Sitton Elementary School',
  'Siu Charter Sch Of East St Louis',
  'Siuslaw Elementary School',
  'Siuslaw High School',
  'Siuslaw Middle School',
  'Sivells Bend El',
  'Siwanoy School',
  'Siwell Middle School',
  'Six Directions Indigenous School',
  'Six Mile Charter Academy',
  'Six Mile Elementary',
  'Six Rivers Charter High',
  'Six-six Magnet School',
  'Sixes Elementary School',
  'Sixteenth & Haak El Sch',
  'Sixteenth Avenue School',
  'Sixth Avenue Elementary',
  'Sixth Avenue Elementary School',
  'Sixth District Elementary School',
  'Sixth Grade Academy',
  'Sixth Grade Charter Academy At Petaluma Jr. High',
  'Sixth Street Elementary',
  'Sixth Street Prep',
  'Sixth Ward Elementary School',
  'Sixth Ward Middle School',
  'Sixty-eighth Street Elementary',
  'Sixty-first Street Elementary',
  'Sixty-sixth Street Elementary',
  'Sizer School: A North Central Charter Essential School',
  'Skaggs El',
  'Skagit County Detention Center',
  'Skagit Family Learning Center Mvsd',
  'Skagit River School House',
  'Skagit Valley College',
  'Skagway School',
  'Skaith Elem.',
  'Skamania Elementary',
  'Skaneateles Middle School',
  'Skaneateles Senior High School',
  'Skano Elementary School',
  'Skelly Elementary School',
  'Skelly Es',
  'Skiatook Es',
  'Skiatook Hs',
  'Skiatook Intermediate Es',
  'Skidmore College',
  'Skidmore-tynan El',
  'Skidmore-tynan H S',
  'Skidmore-tynan J H',
  'Skiles Test Elementary School',
  'Skill Source',
  'Skill Source Learning Center',
  'Skillbuilding Opportunities For Aca',
  'Skinner El',
  'Skinner Elem School',
  'Skinner Magnet Center',
  'Skinner Middle School',
  'Skinner North Elem Sch',
  'Skinner Road School',
  'Skipcha El',
  'Skippack El Sch',
  'Skipwith Elementary',
  'Skoff Elementary',
  'Skoglund Middle School',
  'Skoi-yase School',
  'Skowhegan Area High School',
  'Skowhegan Area Middle School',
  'Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology',
  'Skull Valley Elementary School',
  'Sky Academy Englewood',
  'Sky Academy Venice',
  'Sky City Community School',
  'Sky Country Elementary',
  'Sky Harbour El',
  'Sky Islands',
  'Sky Mountain Charter',
  'Sky Oaks Elementary',
  'Sky Ranch Es',
  'Sky Valley Education Center',
  'Sky Valley Options',
  'Sky View Elementary',
  'Sky View Elementary School',
  'Sky View High',
  'Sky View Middle School',
  'Sky Vista Middle School',
  'Skyblue Mesa Elementary',
  'Skycrest Elementary',
  'Skycrest Elementary School',
  'Skykomish Elementary School',
  'Skykomish High School',
  'Skyland Elementary',
  'Skyland Elementary School',
  'Skylands School Fot The Exceptional',
  'Skyline',
  'Skyline College',
  'Skyline D5',
  'Skyline Elem',
  'Skyline Elem.',
  'Skyline Elementary',
  'Skyline Elementary School',
  'Skyline Es',
  'Skyline H S',
  'Skyline Heights Elem. School',
  'Skyline High',
  'Skyline High School',
  'Skyline Math And Science Academy',
  'Skyline Middle',
  'Skyline Middle School',
  'Skyline North Elementary',
  'Skyline Prep High School',
  'Skyline Ranch Elementary School',
  'Skyline Senior High School',
  'Skyline University College Sharjah',
  'Skyline Vista Elementary School',
  'Skyridge Elementary',
  'Skyridge High School',
  'Skyridge Middle School',
  'Skyview Academy',
  'Skyview Community Elementary',
  'Skyview Community Middle',
  'Skyview El',
  'Skyview Elementary School',
  'Skyview Es',
  'Skyview High School',
  'Skyview Middle School',
  'Skyview School',
  'Skyview Upper El Sch',
  'Skyvue Elementary School',
  'Skyway Elementary',
  'Skyway Elementary School',
  'Skyway Park Elementary School',
  'Slack El',
  'Slack Elementary',
  'Slackwood Elementary School',
  'Slade Elementary',
  'Slade Middle School',
  'Slam Academy Of South Tampa Elementary',
  'Slam Academy Of South Tampa Middle/high',
  'Slam Boca',
  'Slana School',
  'Slanesville Elementary School',
  'Slate Canyon',
  'Slate Creek Elementary',
  'Slate Hill Elementary School',
  'Slate Ridge Elementary School',
  'Slate Run Elementary School',
  'Slater Elementary',
  'Slater Elementary School',
  'Slater High',
  'Slater Marietta Elementary',
  'Slater Special Education Preschool',
  'Slatington El Sch',
  'Slaton Daep',
  'Slaton H S',
  'Slaton Isd Daep',
  'Slaton J H',
  'Slaton Middle',
  'Slaughter Community Charter School',
  'Slaughter El',
  'Slaughter Elementary',
  'Slaughter Elementary School',
  'Slauson Intermediate',
  'Slauson Middle School',
  'Slavens K-8 School',
  'Slcevergreen',
  'Sleeping Giant Middle Sch',
  'Sleepy Eye Elementary',
  'Sleepy Eye Sec.',
  'Sleepy Hill Elementary School',
  'Sleepy Hill Middle School',
  'Sleepy Hollow El',
  'Sleepy Hollow Elem School',
  'Sleepy Hollow Elementary',
  'Sleepy Hollow High School',
  'Sleepy Hollow Middle School',
  'Slidell High School',
  'Slidell Junior High School',
  'Slidell Schools',
  'Sligh Middle School',
  'Sligo Creek Elementary',
  'Sligo El Sch',
  'Sligo Middle',
  'Slinger Elementary',
  'Slinger High',
  'Slinger Middle',
  'Slingerlands Elementary School',
  'Slippery Rock Area El Sch',
  'Slippery Rock Area Hs',
  'Slippery Rock Area Ms',
  'Slippery Rock University of Pennsylvania',
  'Sloan Creek Int',
  'Sloan El Sch',
  'Sloan-hendrix Elem. School',
  'Sloan-hendrix High School',
  'Sloan-hendrix Middle School',
  'Sloat (commodore) Elementary',
  'Sloatsburg Elementary School',
  'Slobomir P Univerzitet',
  'Slocomb Elementary School',
  'Slocomb High School',
  'Slocomb Middle School',
  'Slocum Daep Instructional',
  'Slocum H S',
  'Slocum Pk - 8',
  'Slocum Skewes School',
  'Slovak Medical University',
  'Slovak University of Agriculture in Nitra',
  'Slovak University of Technology in Bratislava',
  'Slover Mountain High (continuation)',
  'Slvusd Charter',
  'Slvusd Home And Hospital',
  'Smackover Elementary School',
  'Smackover High School',
  'Small M S',
  'Smalley Elementary School',
  'Smallwood Drive School',
  'Smart Pope Livingston Elementary',
  "Smart's Mill Middle",
  'Smec Alc',
  'Smedley Street Sch',
  'Smethport Area El Sch',
  'Smethport Area Jshs',
  'Smiley Elementary',
  'Smiley Elementary School',
  'Smilie Memorial Elementary School',
  'Smith (c. L.) Elementary',
  'Smith (jennie E.) Elementary School',
  'Smith Academy',
  'Smith Academy For Excellence',
  'Smith Camp',
  'Smith Center Elem',
  'Smith Center Jr Sr High',
  'Smith Clove Elementary School',
  'Smith College',
  'Smith Cotton Junior High Schl',
  'Smith County Career Center',
  'Smith County High School',
  'Smith County Middle School',
  'Smith Early Learning Center',
  'Smith El',
  'Smith Elem School',
  'Smith Elementary',
  'Smith Elementary School',
  'Smith Farm Elementary',
  'Smith H S',
  'Smith Junior High School',
  'Smith Middle',
  'Smith Middle School',
  'Smith Ms',
  'Smith River Elementary',
  'Smith Road Elementary School',
  'Smith School',
  'Smith Springs Elementary School',
  'Smith Station Elementary',
  'Smith Station Freshman Center',
  'Smith Street Elementary School',
  'Smith Valley 7-8',
  'Smith Valley Primary Schl',
  'Smith Valley Schools',
  'Smith Vocational And Agricultural High',
  'Smith W Elem School',
  'Smith-barnes Elementary School',
  'Smith-cotton High School',
  'Smith-hale Middle',
  'Smitha Middle School',
  'Smithfield El',
  'Smithfield El Sch',
  'Smithfield Elementary',
  'Smithfield High',
  'Smithfield High School',
  'Smithfield Middle',
  'Smithfield-selma High',
  'Smithland Elementary',
  'Smithridge Stem Academy',
  'Smiths Station High School',
  'Smiths Station Junior High School',
  'Smithsburg Elementary',
  'Smithsburg High',
  'Smithsburg Middle',
  'Smithson Craighead Academy',
  'Smithson Valley H S',
  'Smithson Valley Middle',
  'Smithton Elem School',
  'Smithton Elem.',
  'Smithton High',
  'Smithton Middle',
  'Smithtown Elementary School',
  'Smithtown High School East',
  'Smithtown High School-west',
  'Smithville El',
  'Smithville Elementary',
  'Smithville Elementary School',
  'Smithville Es',
  'Smithville H S',
  'Smithville High',
  'Smithville High School',
  'Smithville Hs',
  'Smithville J H',
  'Smithville Middle',
  'Smithville Ms',
  'Smoke Rise Elementary School',
  'Smoketown El Sch',
  'Smoketree Elementary School',
  'Smokey Mountain Elementary',
  'Smokey Road Middle School',
  'Smokiam Alternative High School',
  'Smoky Hill High School',
  'Smoky Mountain Elementary',
  'Smoky Mountain High',
  'Smoky Row Elementary School',
  'Smoky Valley High',
  'Smoky Valley Middle School',
  'Smoky Valley Virtual Charter School',
  'Smolensk Humanitarian University',
  'Smolensk State Medical Academy',
  'Smolny University',
  'Smoot Elementary',
  'Smothers Academy Preparatory School',
  'Smothers Es',
  'Smouse Opportunity School',
  'Smyer El',
  'Smyer H S',
  'Smylie Wilson Middle',
  'Smyrna Elementary',
  'Smyrna Elementary School',
  'Smyrna High School',
  'Smyrna Middle School',
  'Smyrna Primary',
  'Smyrna West Alternative School',
  'Smyser Elem School',
  'Smyth County Career & Technical Center',
  'Smyth J Elem School',
  'Smyth Road School',
  'Smythe Academy Of Arts And Sciences',
  'Smythe Elementary',
  'Snacks Crossing Elementary School',
  'Snail Lake Kindergarten Center',
  'Snake River Elementary',
  'Snake River High School',
  'Snake River Jr High School',
  'Snake River Juvenile Detention',
  'Snake River Middle School',
  'Snapfinger Elementary School',
  'Snapper Creek Elementary School',
  'Snead State Community College',
  'Sneads Elementary School',
  'Sneads High School',
  'Sneed El',
  'Snell Middle',
  'Snelling-merced Falls Elementary',
  'Snellville Middle School',
  'Snelson-golden Middle School',
  'Sni-a-bar Elem.',
  'Sno Co Jail',
  'Sno-isle Skills Center',
  'Snohomish Center',
  'Snohomish Detention Center',
  'Snohomish High School',
  'Snohomish Online Learning',
  'Snook El',
  'Snook Secondary',
  'Snoqualmie Access',
  'Snoqualmie Elementary',
  'Snoqualmie Parent Partnership Program',
  'Snow Canyon High',
  'Snow Canyon Middle',
  'Snow Creek Elementary',
  'Snow Elementary',
  'Snow Elementary School',
  'Snow Heights El',
  'Snow Hill Elementary',
  'Snow Hill High',
  'Snow Hill Middle',
  'Snow Hill Primary',
  'Snow Horse School',
  'Snow Pond Arts Academy School',
  'Snow Rogers Elementary School',
  'Snow School',
  'Snow Springs School',
  'Snowcrest Jr High',
  'Snowden Elementary/middle',
  'Snowden International School At Copley',
  'Snowflake High School',
  'Snowflake Intermediate School',
  'Snowflake Junior High School',
  'Snowflake Preschool',
  'Snowhill Elementary School',
  'Snowline Virtual',
  'Snowshoe Elementary',
  'Snowville Elementary',
  'Snowville School',
  'Snowy Peaks High School',
  'Snowy Range Academy',
  'Snug Harbor Community School',
  'Snyder Academy',
  'Snyder El',
  'Snyder El Sch',
  'Snyder Es',
  'Snyder H S',
  'Snyder Hs',
  'Snyder Int',
  'Snyder J H',
  'Snyder Park Elementary School',
  'Snyder Pri',
  'So Aroostook School',
  'So Platte Elem At Big Springs',
  'So San Antonio Career Ed Ctr',
  'So Shore Vocational Technical High',
  'So Valley Elementary School',
  'Soai University',
  'Soap Lake Elementary',
  'Soap Lake Middle & High School',
  'Soar',
  'Soar Academic Institute',
  'Soar Academy',
  'Soar Academy Public Charter School',
  'Soar At Green Valley Ranch',
  'Soar Charter Academy',
  'Soar Ctr',
  'Soar High (students On Academic Rise)',
  'Soaring Eagles Elementary School',
  'Soaring Hawk Elementary School',
  'Soaring Heights',
  'Soaring Heights Elem.',
  'Sobesky Academy',
  'Soc Just Leadership Acad At Esteban E. Torres High #5',
  'Socastee Elementary',
  'Socastee High',
  'Socastee Middle',
  'Social Circle Elementary School',
  'Social Circle High School',
  'Social Circle Middle School',
  'Social Circle Primary School',
  'Social-emotional Alt Program',
  'Socorro H S',
  'Socorro High',
  'Socorro Middle',
  'Socrates Academy',
  'Socrates Online',
  'Socrum Elementary School',
  'Soda Creek Elementary School',
  'Soda Springs High School',
  'Soddy Daisy High School',
  'Soddy Daisy Middle School',
  'Soddy Elementary',
  'Soderstrom Elem',
  'Sodt Elementary School',
  'Sodus Elementary School',
  'Sodus Intermediate School',
  'Sodus Jr/sr High School',
  'Sofia Rexach',
  'Sofia University "St. Kliment Ohridski"',
  'Sogang University',
  'Sohag University',
  'Sohar University',
  'Sojourner School',
  'Sojourner Truth Academy',
  'Sojourner Truth Middle School',
  'Soka University',
  'Sokoine University of Agriculture',
  'Sokoto State University',
  'Sol Aureus College Preparatory',
  'Sol Feinstone El Sch',
  'Solana Highlands Elementary',
  'Solana Pacific Elementary',
  'Solana Ranch Elementary',
  'Solana Santa Fe Elementary',
  'Solana Vista Elementary',
  'Solanco Hs',
  'Solano Avenue Elementary',
  'Solano Community College',
  'Solano County Community',
  'Solano County Special Education',
  'Solano Juvenile Detention Facility',
  'Solano Middle',
  'Solano School',
  'Solapur University',
  'Solar Preparatory School For Girls At Bonham',
  'Soldan International Studies',
  'Soldier Creek Es',
  'Soldier Hollow Charter School',
  'Soldier Mountain High (continuation)',
  'Soldiers Memorial Middle School',
  'Soldotna Elementary',
  'Soldotna High School',
  'Soldotna Montessori Charter School',
  'Soldotna Prep School',
  'Soleado Elementary',
  'Soledad Enrichment Action Charter High',
  'Soledad High',
  'Soledad Preschool Center',
  'Soledad Transition Center',
  'Solen High School',
  'Soleng Tom Elementary School',
  'Solis-cohen Solomon Sch',
  'Sollers Point/southeastern Technical High',
  'Solley Elementary',
  'Solo',
  'Solomon Elem',
  'Solomon Elem School',
  'Solomon Elementary School',
  'Solomon High',
  'Solomon Magnet School',
  'Solomon P Ortiz Int',
  'Solomon/plains El Sch',
  'Solomon/plains Jhs',
  'Solon Elementary School',
  'Solon High School',
  'Solon Intermediate',
  'Solon Middle School',
  'Solon Robinson Elementary School',
  'Solon Springs School',
  'Solorio Academy High School',
  'Solorsano Middle',
  'Solusi University',
  'Solvang Elementary',
  'Solvay Elementary School',
  'Solvay High School',
  'Solvay Middle School',
  "Solve D'ippolito Elementary School",
  'Solway Elementary',
  'Somaliland University of Technology',
  'Somavia High',
  'Sombra Del Monte Elementary',
  'Somerdale Park School',
  'Somers Elementary',
  'Somers Elementary School',
  'Somers High School',
  'Somers Intermediate School',
  'Somers Middle School',
  'Somers Senior High School',
  'Somerset 6/7 Intermediate School',
  'Somerset Academic Achievement Center',
  'Somerset Academy',
  'Somerset Academy (silver Palms)',
  'Somerset Academy At Silver Palms',
  'Somerset Academy Bay',
  'Somerset Academy Bay Middle School',
  'Somerset Academy Boca East',
  'Somerset Academy Boca Middle School',
  'Somerset Academy Canyons High School',
  'Somerset Academy Canyons Middle School',
  'Somerset Academy Charter',
  'Somerset Academy Charter Elementary School (south Homestead)',
  'Somerset Academy Charter High',
  'Somerset Academy Charter High School',
  'Somerset Academy Charter High School (south Campus)',
  'Somerset Academy Charter High School (south Homestead)',
  'Somerset Academy Charter High School Miramar Campus',
  'Somerset Academy Charter Middle School',
  'Somerset Academy Charter Middle School (country Palms)',
  'Somerset Academy Charter Middle School (south Homestead)',
  'Somerset Academy Charter Middle School South Miami Campus',
  'Somerset Academy Davie Charter',
  'Somerset Academy East Preparatory',
  'Somerset Academy Elementary (miramar Campus)',
  'Somerset Academy Elementary School South Miami Campus',
  'Somerset Academy Hollywood',
  'Somerset Academy Hollywood Middle School',
  'Somerset Academy Jfk Charter School',
  'Somerset Academy Key Charter High School',
  'Somerset Academy Key Middle School',
  'Somerset Academy Lakes',
  'Somerset Academy Lone Mountain',
  'Somerset Academy Losee',
  'Somerset Academy Middle (miramar Campus)',
  'Somerset Academy Middle School',
  'Somerset Academy North Las Vegas',
  'Somerset Academy Pompano (k-5)',
  'Somerset Academy Sky Pointe',
  'Somerset Academy St. Lucie',
  'Somerset Academy Stephanie',
  'Somerset Academy Village Charter Middle School',
  'Somerset Academy-elementary Eagle Campus',
  'Somerset Academy-middle Eagle Campus',
  'Somerset Area Jr-sr Hs',
  'Somerset Arts Academy',
  'Somerset Arts Conservatory',
  'Somerset Berkley Regional High School',
  'Somerset Career & Technical Center',
  'Somerset College Preparatory Academy',
  'Somerset Community College',
  'Somerset Continuation High',
  'Somerset County Technology Center',
  'Somerset County Vocational Technical High School',
  'Somerset Early Childhood El',
  'Somerset El',
  'Somerset Elementary',
  'Somerset Elementary Academy',
  'Somerset Elementary School',
  'Somerset Gables Academy',
  'Somerset H S',
  'Somerset High',
  'Somerset High School',
  'Somerset J H',
  'Somerset Middle',
  'Somerset Middle School',
  'Somerset Miramar South K-5',
  'Somerset Neighborhood School',
  'Somerset Oaks Academy',
  'Somerset Pines Academy',
  'Somerset Preparatory Academy',
  'Somerset Preparatory Academy Charter High At North Lauderdal',
  'Somerset Preparatory Academy Charter School At North Lauderd',
  'Somerset Preparatory Academy Pcs',
  'Somerset Preparatory Academy Sunset',
  'Somerset Preparatory Charter Middle School',
  'Somerset School',
  'Somerset Secondary Academy',
  'Somerset Valley Middle School',
  'Somerset Village Academy',
  'Somerset Virtual Academy',
  'Somersworth High School',
  'Somersworth Middle School',
  'Somerton Middle School',
  'Somerville El',
  'Somerville Elementary School',
  'Somerville H S',
  'Somerville High',
  'Somerville High School',
  'Somerville Middle School',
  'Somerville Road Elementary School',
  'Somis Elementary',
  'Somonauk High School',
  'Somonauk Middle School',
  'Songo Locks School',
  'Sonia Sotomayor Elementary - 65',
  'Sonnesyn Elementary',
  'Sonnie Hereford Elementary School',
  'Sonnie Penn Elementary',
  'Sonntag El',
  'Sonny & Allegra Nance El',
  "Sonoda Women's University",
  'Sonoma Charter',
  'Sonoma County Alternative Education Programs',
  'Sonoma County Court',
  'Sonoma County Rop',
  'Sonoma County Special Education',
  'Sonoma Elementary',
  'Sonoma Heights Elementary School',
  'Sonoma Mountain Elementary',
  'Sonoma Mountain High (continuation)',
  'Sonoma Ranch Elementary School',
  'Sonoma State University',
  'Sonoma Valley High',
  'Sonora El',
  'Sonora Elementary',
  'Sonora Elementary School',
  'Sonora H S',
  'Sonora High',
  'Sonora J H',
  'Sonora Middle School',
  'Sonoran Desert School',
  'Sonoran Foothills',
  'Sonoran Heights Elementary',
  'Sonoran Science Academy - Broadway',
  'Sonoran Science Academy - Davis Monthan',
  'Sonoran Science Academy - Phoenix',
  'Sonoran Science Academy - Tucson',
  'Sonoran Science Academy-peoria',
  'Sonoran Sky Elementary School',
  'Sonoran Trails Middle School',
  'Sonoraville Elementary',
  'Sonoraville High School',
  'Sonoraville Middle School',
  'Sontag Elementary',
  'Soo Hill Elementary School',
  'Soo Township School',
  'Soochow University',
  "Sookmyung Women's University",
  'Soonchunhyang University',
  'Sooner Es',
  'Soongsil University',
  'Soos Creek Elementary School',
  'Sope Creek Elementary School',
  'Soper Es',
  'Soper Hs',
  'Sophia P Kingston Elementary School',
  'Sophia T. Salvin Special Education Center',
  'Sophia University',
  'Sophia-soak Creek Elementary',
  'Sophie B. Wright Institute Of Academic Excellence',
  'Sopori Elementary School',
  'Sopris Elementary School',
  'Soquel Elementary',
  'Soquel High',
  'Sor Isolina Ferre (villa Del Carmen)',
  'Soran University',
  'Sorensen Magnet School Of The Arts And Humanities',
  'Sorento Elem School',
  'Sorgho Elementary School',
  'Soroco High School',
  'Soroco Middle School',
  'Soroco Preschool At Yampa',
  'Sorrento Elementary',
  'Sorrento Primary School',
  'Sorrento Springs Elem.',
  'Sorters Mill El',
  'Soset Charter School',
  'Sossaman Middle School',
  'Sotero Figueroa',
  'Sotheby´s Institute of Art - London',
  'Soto Street Elementary',
  'Souder El',
  'Souderton Area Shs',
  'Souderton Cs Collaborative',
  'Souhegan Coop High School',
  'Soule Elementary School',
  'Soule Road',
  'Soule Road Elementary School',
  'Soule Road Middle School',
  'Soulsbyville Elementary',
  'Soulsville Charter School',
  'Sound Beach School',
  'Sound School',
  'Soundbridge @ Academy Of Aerospace & Engineering Elementary',
  'Soundbridge @ Museum Academy',
  'Soundview Academy For Culture And Scholarship',
  'Sour Lake El',
  'Souris River Campus Alternative High School',
  'Souris Valley Spec Ed Unit',
  'Sousa Elementary School',
  'Sousa Ms',
  'South',
  'South Abington Sch',
  'South Academic Center',
  'South Accelerated Academy',
  'South Adams Elementary School',
  'South Adams High School',
  'South Adams Middle School',
  'South Aiken High',
  'South Albany High School',
  'South Allegheny Early Childhood Ctr',
  'South Allegheny El Sch',
  'South Allegheny Ms/hs',
  'South Amboy Elementary',
  'South Amboy Middle/high School',
  'South Amherst Middle School',
  'South Anchorage High School',
  'South Anna Elementary',
  'South Arbor Charter Academy',
  'South Area Adult Education-day Program',
  'South Area Adult Education-night Program',
  'South Area Alternative Learning Center',
  'South Area Head Start',
  'South Area Secondary Intensive Transition Program',
  'South Arkansas Community College',
  'South Asheboro Middle',
  'South Asian Institute of Technology And Medicine (SAITM)',
  'South Asian University',
  'South Athens El',
  'South Atlanta High School',
  'South Avenue School',
  'South Avondale Elementary School',
  'South Baker Intermediate School',
  'South Baldwin Center For Technology',
  'South Bank University',
  'South Barber 7-12',
  'South Barber Pre-k-6',
  'South Baton Rouge Charter Academy',
  'South Bay Academy Community Day',
  'South Bay Charter',
  'South Bay Elementary',
  'South Bay School',
  'South Beach Psychiatric Center',
  'South Beauregard Elementary School',
  'South Beauregard High School',
  'South Beauregard Upper Elementary School',
  'South Beaver Dam Elementary',
  'South Beaver Elementary School - Closed',
  'South Beloit Jr High School',
  'South Beloit Sr High School',
  'South Belt El',
  'South Belton Middle',
  'South Bend High School',
  'South Bend Home Link',
  'South Bendle Elementary School',
  'South Bloomfield Elementary',
  'South Bosque El',
  'South Boston Elementary',
  'South Boston/halifax Early Learning Center',
  'South Branch Vocational School',
  'South Brandywine Ms',
  'South Breeze Elementary',
  'South Bristol Elementary School',
  'South Bronx Academy For Applied Media',
  'South Bronx Charter School',
  'South Bronx Classical Charter School',
  'South Bronx Classical Charter School Ii',
  'South Bronx Classical Charter School Iii',
  'South Bronx Classical Charter School Iv',
  'South Bronx Community Charter High School',
  'South Bronx Early College Academy Charter School',
  'South Bronx Preparatory - A College Board School',
  'South Brooklyn Community High School',
  'South Broward High School',
  'South Broward Montessori Charter School',
  'South Brunswick Charter',
  'South Brunswick High',
  'South Brunswick High School',
  'South Brunswick Middle',
  'South Buffalo Charter School',
  'South Buffalo El Sch',
  'South Burlington High School',
  'South Butler Intermediate El Sch',
  'South Butler Primary Sch',
  'South Cache Middle School',
  'South Caldwell High',
  'South Callaway Early Chd Pk-02',
  'South Callaway Elem.',
  'South Callaway High',
  'South Callaway Middle',
  'South Cameron High School',
  'South Campus 916',
  'South Campus Community High',
  'South Campus Community Middle',
  'South Canton Scholars Charter Academy',
  'South Canyon Elementary - 15',
  'South Carelian Polytechnic',
  'South Carolina Calvert Academy',
  'South Carolina Science Academy',
  'South Carroll High',
  'South Central Alternative High School',
  'South Central Calhoun Elementary Building',
  'South Central Calhoun High School',
  'South Central Calhoun Middle School',
  'South Central Career Ctr.',
  'South Central College',
  'South Central Correctional Ctr',
  'South Central El',
  'South Central Elementary',
  'South Central Elementary - 03',
  'South Central Elementary School',
  'South Central Elementary-kinmundy',
  'South Central High',
  'South Central High School',
  'South Central High School - 01',
  'South Central Jr & Sr High School',
  'South Central Jr-sr High School',
  'South Central Junior High School',
  'South Central Michigan Virtual',
  'South Central Middle School',
  'South Central Middle School - 02',
  'South Central Prairie Spec Ed Unit',
  'South Charleston High School',
  'South Charleston Middle School',
  'South Charlotte Middle',
  'South China Agricultural University',
  'South China Construction University',
  'South China Normal University',
  'South China University',
  'South China University of Technology',
  'South Christian Elementary School',
  'South City',
  'South Clearfield School',
  'South Clinton Elementary',
  'South Coast Continuation',
  'South Cobb High School',
  'South Coffeyville Es',
  'South Coffeyville Hs',
  'South Colby Elementary',
  'South Columbia Elementary School',
  'South Columbia Family School',
  'South Columbus Elementary School',
  'South Columbus High',
  'South Columbus Preparatory Academy',
  'South Conway Elementary',
  'South Country School',
  'South County Career Center',
  'South County Community Day',
  'South County Consortium',
  'South County High',
  'South County Middle',
  'South Crawford Elementary School',
  'South Creek Elementary',
  'South Creek High',
  'South Creek Middle',
  'South Crowley Elementary School',
  'South Cumberland Elementary',
  'South Dade Middle School',
  'South Dade Senior High School',
  'South Dade Technical College',
  'South Dakota School of Mines and Technology',
  'South Dakota State University',
  'South Dale Middle School',
  'South Davidson High',
  'South Davidson Middle',
  'South Davie Middle',
  'South Davis El',
  'South Davis Elementary School',
  'South Davis Jr High',
  'South Daytona Elementary School',
  'South Dearborn High School',
  'South Dearborn Middle School',
  'South Decatur Elementary School',
  'South Decatur Jr-sr High School',
  'South Delta Elementary School',
  'South Delta High School',
  'South Delta Middle School',
  'South Delta Vocational Complex',
  'South Division High',
  'South Dodge Elementary School',
  'South Dorchester School',
  'South Douglas Elementary School',
  'South Dover Elementary School',
  'South Doyle High School',
  'South Doyle Middle School',
  'South Early College H S',
  'South East European University',
  'South East High',
  'South Eastern Intrmd Sch',
  'South Eastern Kenya University',
  'South Eastern Ms',
  'South Eastern University of Sri Lanka',
  'South Edgecombe Middle',
  'South Edmonson Elementary School',
  'South Education Center Academy',
  'South Effingham Elementary School',
  'South Effingham High School',
  'South Effingham Middle School',
  'South Egremont',
  'South El',
  'South El Monte High',
  'South El Sch',
  'South Elem School',
  'South Elem.',
  'South Elementary',
  'South Elementary Center',
  'South Elementary School',
  'South Elgin High School',
  'South End Elementary',
  'South End Elementary School',
  'South End Middle School',
  'South Eugene High School',
  'South Euless El',
  'South Fant School Of Early Education',
  'South Fayette Intermediate Sch',
  'South Fayette Ms',
  'South Fayette Twp El Sch',
  'South Fayette Twp Hs',
  'South Fentress Elementary School',
  'South Florence High',
  'South Florida Autism Charter School Inc',
  'South Florida International Charter School',
  'South Floyd Elementary School',
  'South Floyd High/middle School',
  'South Fork Elem.',
  'South Fork Elementary',
  'South Fork Elementary School',
  'South Fork High',
  'South Fork High School',
  'South Fork Jr Sr High School',
  'South Fork Junior - Senior High',
  'South Forrest Attendance Center',
  'South Forsyth High School',
  'South Forsyth Middle School',
  'South Fort Myers High School',
  'South Fortuna Elementary',
  'South Fremont High School',
  'South Fremont Jr High',
  'South Fulton Elementary',
  'South Fulton Middle / High School',
  'South Gallia High School',
  'South Gallia Middle School',
  'South Garner High',
  'South Gate Middle',
  'South Gate Senior High',
  'South Georgia El',
  'South Georgia Technical College',
  'South Gibson County High School',
  'South Girard School',
  'South Glens Falls Senior High School',
  'South Grade Elementary School',
  'South Grafton Elementary',
  'South Graham Elementary',
  'South Grand Prairie H S',
  'South Grant Elementary School',
  'South Granville High',
  'South Gray High',
  'South Gray Jr High',
  'South Green Elementary School',
  'South Greene High School',
  'South Greenville Elementary',
  'South Grove Elementary School',
  'South Grove Intermediate School',
  'South Gujarat University',
  'South Gwinnett High School',
  'South Habersham Middle School',
  'South Hadley High',
  'South Hagerstown High',
  'South Hall Middle School',
  'South Hamilton El Sch',
  'South Hamilton Elem',
  'South Hamilton Elementary School',
  'South Hamilton Middle And High School',
  'South Hampton Barnard School',
  'South Hampton K8',
  'South Hancock Elementary School',
  'South Hanover El Sch',
  'South Hardin High School',
  'South Hardin Middle School',
  'South Harnett Elementary',
  'South Harrison Early Child Ctr',
  'South Harrison Elem.',
  'South Harrison High',
  'South Harrison High School',
  'South Harrison Middle',
  'South Harrison Middle School',
  'South Harrison Township Elementary School',
  'South Hart Elementary School',
  'South Haven Alliance Campus',
  'South Haven Elem',
  'South Haven Elementary',
  'South Haven Elementary School',
  'South Haven High',
  'South Haven High School',
  'South Heart Elementary School',
  'South Heart High School',
  'South Heights Elementary School',
  'South Hi Mount El',
  'South Hialeah Elementary School',
  'South High',
  'South High Community',
  'South High School',
  'South Highlands Elementary Magnet School',
  'South Highlands Middle School',
  'South Hill Elementary',
  'South Hill School',
  'South Hills El',
  'South Hills H S',
  'South Hills High',
  'South Hills Middle',
  'South Hills Middle School',
  'South Hillsborough',
  'South Holt Elem.',
  'South Holt High',
  'South Hopkins Middle School',
  'South Houston El',
  'South Houston High School',
  'South Houston Int',
  'South Hunterdon High School',
  'South Hutchinson Elem',
  'South Iredell High',
  'South Iron Elem.',
  'South Iron High',
  'South Irvine Preschool Center',
  'South Jackson Elementary School',
  'South Jacksonville Elem School',
  'South Jefferson Elementary School',
  'South Jefferson High School',
  'South Jetty High School',
  'South Johnston High',
  'South Jones Elementary School',
  'South Jones High School',
  'South Jordan Middle',
  'South Jordan School',
  'South Junior High',
  'South Junior High School',
  'South Kearns School',
  'South Keller Int',
  'South Kilbourne Elementary',
  'South Kingstown High',
  'South Kingstown Integrated Pre',
  'South Kitsap High School',
  'South Knoll El',
  'South Knox Elementary',
  'South Knox Elementary School',
  'South Knox Middle-high School',
  'South Kortright Central School',
  'South Lafourche High School',
  'South Lake Elementary',
  'South Lake Es',
  'South Lake High School',
  'South Lake Middle',
  'South Lake Middle School',
  'South Lakes High',
  'South Lakewood Elementary School',
  'South Lamar School',
  'South Larose Elementary School',
  'South Laurel High School',
  'South Laurel Middle School',
  'South Lawn El',
  'South Lawrence East Elementary School',
  'South Lawrence Elementary',
  'South Lebanon El Sch',
  'South Lebanon Elementary School',
  'South Lenoir High',
  'South Lewis High School',
  'South Lewis Middle School',
  'South Lexington Development Center',
  'South Lexington School',
  'South Lincoln School',
  'South Lindhurst Continuation High',
  'South Live Oak Elementary School',
  'South Livingston Elementary School',
  'South Londonderry Elementary School',
  'South Loop El',
  'South Loop Elem School',
  'South Louisiana Community College',
  'South Lyon East High School',
  'South Lyon High School',
  'South Macon Elementary',
  'South Magoffin Elementary',
  'South Main Street Elementary School',
  'South Man Elementary School',
  'South Maple Elementary School',
  'South Marshall Elementary School',
  'South Marshall Middle',
  'South Mckeel Academy',
  'South Meadow School',
  'South Meadows Elementary School',
  'South Meadows Middle School',
  'South Mebane Elementary',
  'South Mecklenburg High',
  'South Medford High School',
  'South Memorial',
  'South Mesa Elementary School',
  'South Miami Heights Elementary',
  'South Miami K-8 Center',
  'South Miami Middle School',
  'South Miami Senior High School',
  'South Middle',
  'South Middle School',
  'South Middle School - 36',
  'South Mifflin Stem Academy (k-6)',
  'South Milwaukee High',
  'South Milwaukee Middle',
  'South Mountain Community College',
  'South Mountain El Sch',
  'South Mountain Elementary',
  'South Mountain Elementary School',
  'South Mountain High School',
  'South Mountain Ms',
  'South Mountain Preparatory Academy',
  'South Mountain School',
  'South Mountain Secure Trmnt Un',
  'South Newton Elementary',
  'South Newton Elementary School',
  'South Newton Middle School',
  'South Newton Senior High School',
  'South Nodaway Elem.',
  'South Nodaway High',
  "South O'brien Elem Sch Primghar Center",
  "South O'brien Secondary School",
  'South Oak Cliff H S',
  'South Ocala Elementary School',
  'South Ocean Middle School',
  'South Oceanside Elementary',
  'South Ogden Jr High',
  'South Oldham High School',
  'South Oldham Middle School',
  'South Olive Elementary School',
  'South Orange Middle School',
  'South Orangetown Middle School',
  'South Page Elementary School',
  'South Page Senior High School',
  'South Palm Gardens H S',
  'South Panola High School',
  'South Park Academy',
  'South Park Elem Center',
  'South Park Elem School',
  'South Park Elem.',
  'South Park Elementary',
  'South Park Elementary - 03',
  'South Park Elementary - 16',
  'South Park Elementary School',
  'South Park High School',
  'South Park Middle',
  'South Park Middle School',
  'South Park Ms',
  'South Park Shs',
  'South Park Tapp',
  'South Pasadena Middle',
  'South Pasadena Senior High',
  'South Paulding High School',
  'South Paulding Middle School',
  'South Pekin Elem School',
  'South Pemiscot Elem.',
  'South Pemiscot High',
  'South Penn Elementary',
  'South Perry Elementary School',
  'South Philadelphia Hs',
  'South Phoenix Prep And Arts Academy',
  'South Piedmont Community College',
  'South Pike Alternative School',
  'South Pike Career & Technical Cente',
  'South Pike Jr High',
  'South Pike Senior High School',
  'South Pines Elementary',
  'South Pittsburg Elementary',
  'South Pittsburg High School',
  'South Plainfield High School',
  'South Plainfield Middle School',
  'South Plains Academy Charter H S',
  'South Plains College',
  'South Plantation High School',
  'South Plaquemines Elementary School',
  'South Plaquemines High School',
  'South Platte High School',
  'South Point Elem.',
  'South Point Elementary',
  'South Point Elementary School',
  'South Point High',
  'South Point High School',
  'South Point Middle School',
  'South Pointe Elementary School',
  'South Pointe High',
  'South Pointe High School',
  'South Pointe Junior High School',
  'South Pointe Middle',
  'South Pointe Scholars Charter Academy',
  'South Polk Elementary',
  'South Pontotoc Elementary School',
  'South Pontotoc High School',
  'South Pontotoc Middle School',
  'South Portland High School',
  'South Prairie Elementary',
  'South Prairie Elementary School',
  'South Prairie High School',
  'South Preschool Center Located At Starlight School',
  'South Preston School',
  'South Primary School',
  'South Providence',
  'South Puget Sound Community College',
  'South Putnam High School',
  'South Putnam Middle School',
  'South Ranchito Dual Language Academy',
  'South Range Elementary School',
  'South Range High School',
  'South Range Middle School',
  'South Rayne Elementary School',
  'South Region Deaf',
  'South Rich School',
  'South Richmond High School Is/ps 25',
  'South Ridge Elementary',
  'South Ridge Elementary An Ib World School',
  'South Ridge High School',
  'South Ridge Secondary',
  'South Ripley Elementary School',
  'South Ripley High School',
  'South Ripley Junior High School',
  'South River',
  'South River Elementary',
  'South River Elementary School',
  'South River High',
  'South River High School',
  'South River Middle School',
  'South River Primary School',
  'South Robeson High',
  'South Rock Creek Public School',
  'South Routt Elementary School',
  'South Row',
  'South Rowan High',
  'South Royalton Elementary/high School',
  'South Salem Elementary',
  'South Salem Elementary School',
  'South Salem High School',
  'South San Antonio H S',
  'South San Francisco High',
  'South Sanpete Education Support Center',
  'South School',
  'South Scioto Academy',
  'South Scotland Elementary',
  'South Scranton Intrmd Sch',
  'South Seminole Middle School',
  'South Seneca Elementary School',
  'South Seneca Middle/high School',
  'South Senior High',
  'South Seventeenth Street School',
  'South Sevier High',
  'South Sevier Middle',
  'South Shades Crest Elementary School',
  'South Shaver El',
  'South Shelby High',
  'South Shelby Middle School',
  'South Shore Charter Public School',
  'South Shore Elementary',
  'South Shore Elementary School',
  'South Shore Fine Arts Elem Sch',
  'South Shore Intl Col Prep Hs',
  'South Shore Jr/sr High',
  'South Shore Pk-8 School',
  'South Shores Elem School',
  'South Shores/csudh Visual And Performing Arts',
  'South Side El Sch',
  'South Side Elementary',
  'South Side Elementary School',
  'South Side Extended Resource',
  'South Side Goal',
  'South Side Grade School',
  'South Side High School',
  'South Side Hs',
  'South Side Lower Elementary School',
  'South Side Middle School',
  'South Side Ms',
  'South Side School',
  'South Sioux City Middle School',
  'South Sioux Senior High School',
  'South Smithfield Elementary',
  'South Smiths Station Elementary School',
  'South Sound High School',
  'South Spencer High School',
  'South Spencer Middle School',
  'South St Marys Street El Sch',
  'South St. Paul Secondary',
  'South Stacey School',
  'South Stanly High',
  'South Stanly Middle',
  'South Stokes High',
  'South Street Elementary',
  'South Street Elementary School',
  'South Street School',
  'South Suburban College',
  'South Summit High',
  'South Summit Middle',
  'South Summit Preschool',
  'South Summit School',
  'South Sumter High School',
  'South Sumter Middle School',
  'South Sutter Charter',
  'South Tahoe High',
  'South Tahoe Middle',
  'South Tama County Elementary School',
  'South Tama County High School',
  'South Tama County Middle School',
  'South Tamarind Elementary',
  'South Tech Academy',
  'South Tech Preparatory Academy',
  'South Technical',
  'South Technical Adult Education',
  'South Terrace Elementary',
  'South Terrebonne High School',
  'South Texas Academy For Medical Professions',
  'South Texas Business Education & Technology Academ',
  'South Texas College of Law',
  'South Texas H S For Health Professions',
  'South Texas Preparatory Academy',
  'South Thibodaux Elementary School',
  'South Todd Elementary School',
  'South Toe Elementary',
  'South Toms River Elementary School',
  'South Topsail Elementary',
  'South Umpqua High School',
  'South Union Elementary School',
  'South University of Science and Technology of China',
  'South Valley Academy',
  'South Valley Elementary School',
  'South Valley High (continuation)',
  'South Valley Jr. High',
  'South Valley Middle',
  'South Valley Middle School',
  'South Valley Prep',
  'South Valley Prep And Arts School',
  'South Valley School',
  'South Valley Spec Ed Unit',
  'South Valley University',
  'South Verde Technology Magnet',
  'South Vermillion High School',
  'South Vermillion Middle School',
  'South Vienna Elementary School',
  'South Vienna Middle School',
  'South View Elementary School',
  'South View High',
  'South View Middle',
  'South View Upper Elem School',
  'South Waco El',
  'South Walker Elementary School',
  'South Walnut Elementary School',
  'South Walton High School',
  'South Ward El',
  'South Ward Elementary School',
  'South Warren Elementary',
  'South Warren High School',
  'South Warren Middle School',
  'South Wasco County High School',
  'South Washington Alternative Hs',
  'South Wayne Elementary School',
  'South Weber School',
  'South Webster Jr/sr High School',
  'South West University Yucai College',
  'South Western Shs',
  'South Whidbey Academy',
  'South Whidbey Elementary',
  'South Whidbey High School',
  'South Whidbey Middle',
  'South Whidbey Special Services',
  'South Whitley Elementary School',
  'South Williamsport Area Jshs',
  'South Wilmington Grade School',
  'South Windsor High School',
  'South Winneshiek Elementary School',
  'South Winneshiek High School',
  'South Winneshiek Middle School',
  'South Woods Elementary School',
  'South Woods Middle School',
  'South-East Asia University',
  'South-West University "Neofit Rilski"',
  'South-western Career Academy',
  'South-western Preschool Center',
  'South/west Park Elementary',
  'Southampton Elementary',
  'Southampton Elementary School',
  'Southampton High',
  'Southampton High School',
  'Southampton Intermediate School',
  'Southampton Middle',
  'Southampton Road',
  'Southampton Solent University',
  'Southampton Township School #1',
  'Southampton Township School #2',
  'Southampton Township School #3',
  'Southark Career Center',
  'Southaven Elementary',
  'Southaven High School',
  'Southaven Intermediate School',
  'Southaven Middle School',
  'Southbridge High School',
  'Southbridge Middle School',
  'Southbury Elem School',
  'Southcentral Kentucky Community and Technical College',
  'Southdale Elementary School',
  'Southdown Elementary School',
  'Southdown School',
  'Southdowns School',
  'Southeast Alternative School',
  'Southeast Area',
  'Southeast Area Elementary School',
  'Southeast Arkansas College',
  'Southeast Bulloch High School',
  'Southeast Bulloch Middle School',
  'Southeast Career Pathways',
  'Southeast Career Technical Academy',
  'Southeast Community College Area',
  'Southeast Correctional Center',
  'Southeast Delco Kindergarten Center',
  'Southeast El',
  'Southeast El Sch',
  'Southeast Elem School',
  'Southeast Elem.',
  'Southeast Elementary',
  'Southeast Elementary School',
  'Southeast Es',
  'Southeast Fountain Elementary',
  'Southeast Guilford High',
  'Southeast Guilford Middle',
  'Southeast Halifax Collegiate Prep Academ',
  'Southeast High',
  'Southeast High School',
  'Southeast Hs',
  'Southeast Idaho Professional Technical School',
  'Southeast Intermediate Elementary School',
  'Southeast Junior High School',
  'Southeast Kelloggsville School',
  'Southeast Kentucky Community and Technical College',
  'Southeast Lauderdale Elementary',
  'Southeast Lauderdale High School',
  'Southeast Lauderdale Middle School',
  'Southeast Learning Center',
  'Southeast Michigan Virtual Academy',
  'Southeast Middle',
  'Southeast Middle School',
  'Southeast Missouri Hospital College of Nursing and Health Sciences',
  'Southeast Missouri State University',
  'Southeast Polk High School',
  'Southeast Polk Junior High',
  'Southeast Primary Elementary School',
  'Southeast Raleigh High',
  'Southeast Region',
  'Southeast Region Career & Tech Center',
  'Southeast Rop',
  'Southeast Saline Elem',
  'Southeast Saline High',
  'Southeast Tech',
  'Southeast Technical Institute',
  'Southeast University',
  'Southeast Valley High School',
  'Southeast Warren Intermediate',
  'Southeast Warren Jr-sr High School',
  'Southeast Warren Primary',
  'Southeast Webster-grand Southeast Valley Middle',
  'Southeast Whitfield County High School',
  'Southeastern',
  'Southeastern Academy',
  'Southeastern Career Center',
  'Southeastern Community College',
  'Southeastern Coop Ed Pgm',
  'Southeastern Elementary',
  'Southeastern Elementary School',
  'Southeastern High School',
  'Southeastern Illinois College',
  'Southeastern Jr. High School',
  'Southeastern Jr/sr High School',
  'Southeastern La University Lab School',
  'Southeastern Louisiana University',
  'Southeastern Middle',
  'Southeastern Middle School',
  'Southeastern Randolph Middle',
  'Southeastern Regional Vocational Technical',
  'Southeastern School',
  'Southeastern Sr. High School',
  'Southeastern Technical College',
  'Southeastern Va Tr Ctr Ep',
  'Southern Alberta Institute of Technology',
  'Southern Arizona Community High School',
  'Southern Arkansas University Tech',
  'Southern Avenue Charter Elementary School',
  'Southern Bluffs Elementary',
  'Southern Boone Elem.',
  'Southern Boone High',
  'Southern Boone Middle',
  'Southern Boone Primary',
  'Southern Boulevard School',
  'Southern California Roc',
  'Southern Cayuga 7-12 Secondary School',
  'Southern Cayuga Elementary School',
  'Southern Choctaw Elementary School',
  'Southern Choctaw High School',
  'Southern Coffey County High School',
  'Southern Coffey County Jr. High School',
  'Southern College',
  'Southern College of Technology',
  'Southern Columbia Hs',
  'Southern Columbia Ms',
  'Southern Connecticut State University',
  'Southern Crescent Technical College',
  'Southern Cross University',
  'Southern Delaware School Of The Arts',
  'Southern Denmark Business School',
  'Southern Desert Correctional Center',
  'Southern Door Elementary',
  'Southern Door High',
  'Southern Door Middle',
  'Southern El Sch',
  'Southern Elementary',
  'Southern Elementary School',
  'Southern Fulton El Sch',
  'Southern Fulton Jshs',
  'Southern Garrett High School',
  'Southern Guilford High',
  'Southern Guilford Middle',
  'Southern Heights Elementary',
  'Southern High',
  'Southern High School',
  'Southern Hills Career Technical Center',
  'Southern Hills El',
  'Southern Hills Elementary School',
  'Southern Hills Es',
  'Southern Hills Middle School',
  'Southern Humboldt Community',
  'Southern Huntingdon Co Hs/ms',
  'Southern Illinois University-Carbondale',
  'Southern Illinois University-Edwardsville',
  'Southern Ind Career And Tech Cent',
  'Southern Lee High School',
  'Southern Lehigh Intermediate Sch',
  'Southern Lehigh Ms',
  'Southern Lehigh Shs',
  'Southern Local Elementary School',
  'Southern Local Jr/sr High School',
  'Southern Maine Community College',
  'Southern Medial University',
  'Southern Methodist University',
  'Southern Michigan Center For Science Industry Smcsi',
  'Southern Middle',
  'Southern Middle School',
  'Southern Ms',
  'Southern Nash High',
  'Southern Nash Middle',
  'Southern Nazarene University',
  'Southern New Hampshire University',
  'Southern Nm Correction',
  'Southern Oak Elementary School',
  'Southern Oaks Elementary School',
  'Southern Oaks Middle School',
  'Southern Ohio Academy',
  'Southern Oregon State College',
  'Southern Pines Elementary',
  'Southern Pines Primary',
  'Southern Plains Area Learning Cntr.',
  'Southern Plains Bridges',
  'Southern Plains Education Coop.',
  'Southern Regional High School',
  'Southern Regional Middle School',
  'Southern Rgc',
  'Southern School Of Energy And Sustainabi',
  'Southern State Community College',
  'Southern Taiwan University of Technology',
  'Southern Trinity High',
  'Southern Union State Community College',
  'Southern University Bangladesh',
  'Southern University Lab School',
  'Southern University Laboratory Virtual School',
  'Southern University and A&M College',
  'Southern University at Shreveport',
  'Southern Ural State University',
  'Southern Utah University',
  'Southern Valley Jr/sr High',
  'Southern Vance High',
  'Southern View Elem School',
  'Southern Wake Academy',
  'Southern Wayne High',
  'Southern Wells Elementary School',
  'Southern Wells Jr-sr High School',
  'Southern West Virginia Community and Technical College',
  'Southern Yangtze University',
  'Southfield Elementary School',
  'Southfield High School For The Arts And Technology',
  'Southfield Regional Academic Campus',
  'Southgate Academy',
  'Southgate Anderson High School',
  'Southgate El',
  'Southgate Elementary',
  'Southgate Elementary School',
  'Southgate Public School',
  'Southgate School',
  'Southgate-rippetoe Es',
  'Southington Elementary School',
  'Southington High School',
  'Southington Middle School',
  'Southington Public Schools Preschool Program',
  'Southington Public Schools Preschool Program At Hatton Scho',
  'Southland College Prep Chtr Hs',
  'Southland Elem.',
  'Southland Elementary',
  'Southland High',
  'Southland Middle',
  'Southland School',
  'Southland Senior High',
  'Southlawn Elem',
  'Southlawn Elementary School',
  'Southlawn Middle School',
  'Southlawn School',
  'Southmayd El',
  'Southmont Elementary',
  'Southmont Jr High School',
  'Southmont Sr High School',
  'Southmoor Elementary School',
  'Southmoore Hs',
  'Southmore Int',
  'Southmoreland El Sch',
  'Southmoreland Ms',
  'Southmoreland Primary Center',
  'Southmoreland Shs',
  'Southmost El',
  'Southold Elementary School',
  'Southold Junior-senior High School',
  'Southpointe Adult High',
  'Southport 6th Grade Academy',
  'Southport Central School',
  'Southport Elementary',
  'Southport Elementary School',
  'Southport High School',
  'Southport Middle School',
  'Southridge El',
  'Southridge Elementary',
  'Southridge Elementary School',
  'Southridge High School',
  'Southridge Jhs',
  'Southridge Middle',
  'Southridge Middle School',
  'Southshore Charter Academy',
  'Southshore Elementary',
  'Southside Academy',
  'Southside Academy Charter School',
  'Southside Alter Ctr',
  'Southside Alternative High School',
  'Southside Ashpole Elementary',
  'Southside Charter High School',
  'Southside Community School',
  'Southside Early Childhood Center',
  'Southside El',
  'Southside El Sch',
  'Southside Elementary',
  'Southside Elementary Charter',
  'Southside Elementary School',
  'Southside Es',
  'Southside Estates Elementary School',
  'Southside Family Charter School',
  'Southside H S',
  'Southside High',
  'Southside High School',
  'Southside Junior High School',
  'Southside K-8',
  'Southside L.i.n.k. Project/brunswick Co',
  'Southside Middle',
  'Southside Middle School',
  'Southside Occupational Acad Hs',
  'Southside Pri',
  'Southside Primary School',
  'Southside School',
  'Southside Special Educ School',
  'Southside Stem Academy At Campostella',
  'Southside Technical Center',
  'Southside Va Training Ctr',
  'Southside Virginia Community College',
  'Southside/ashpole Elem',
  'Southview',
  'Southview Elem.',
  'Southview Elementary',
  'Southview Elementary School',
  'Southview High',
  'Southview Middle School',
  'Southview Preschool Center',
  'Southview Primary School',
  'Southwark Sch',
  'Southwest Academy',
  'Southwest Agricultural University',
  'Southwest Alternative High School',
  'Southwest Baltimore Charter School',
  'Southwest Baptist University',
  'Southwest Behavior J/shs',
  'Southwest Calloway Elementary School',
  'Southwest Campus',
  'Southwest Career And Technical Academy',
  'Southwest City Elem.',
  'Southwest Colorado E-school',
  'Southwest Community Campus School',
  'Southwest Community High School',
  'Southwest Dekalb High School',
  'Southwest Detroit Community School',
  'Southwest Early Childhood Ctr',
  'Southwest Early College',
  'Southwest Early College High School',
  'Southwest Edgecombe High',
  'Southwest Educational Academy',
  'Southwest El',
  'Southwest El Sch',
  'Southwest Elem',
  'Southwest Elem School',
  'Southwest Elem.',
  'Southwest Elementary',
  'Southwest Elementary School',
  'Southwest Elementary-indianola',
  'Southwest Es',
  'Southwest Florida Juvenile Detention Center',
  'Southwest Florida Public Service Academy',
  'Southwest Forestry University',
  'Southwest Georgia S.t.e.m. Charter Academy',
  'Southwest Georgia Technical College',
  'Southwest Guilford High',
  'Southwest Guilford Middle',
  'Southwest H S',
  'Southwest High',
  'Southwest High School',
  'Southwest High School - Sws',
  'Southwest Idaho Juvenile Detention',
  'Southwest Intermediate Learning Center',
  'Southwest Jiaotong University',
  'Southwest Jr. High School',
  'Southwest Jr/sr High School',
  'Southwest Junior High',
  'Southwest Junior High School',
  'Southwest Key',
  'Southwest Key Program',
  'Southwest Key Transitional Learning Center',
  'Southwest Laurens Elementary',
  'Southwest Leadership Academy',
  'Southwest Leadership Academy Cs',
  'Southwest Legacy H S',
  'Southwest Licking Early Learning Center',
  'Southwest Livingston Co R-1 El',
  'Southwest Livingston Co R-1 Hs',
  'Southwest Louisiana Charter Academy',
  'Southwest Miami Adult & Community Education Center',
  'Southwest Miami Senior High',
  'Southwest Middle',
  'Southwest Middle School',
  'Southwest Middle School - 38',
  'Southwest Mississippi Community College',
  'Southwest Missouri State University',
  'Southwest Ms',
  'Southwest Ohio Preparatory School',
  'Southwest Open Charter School',
  'Southwest Petroleum University',
  'Southwest Prep Northwest El',
  'Southwest Preparatory Learning Center',
  'Southwest Preparatory School',
  'Southwest Preparatory School-northwest',
  'Southwest Preparatory Southeast Campus',
  'Southwest Primary',
  'Southwest Region',
  'Southwest Research Institute',
  'Southwest School',
  'Southwest Schools Bissonnet El Campus',
  'Southwest Schools Mangum El Campus',
  'Southwest Secondary Learning Center',
  'Southwest Senior High',
  'Southwest Spec Ed Unit',
  'Southwest Tennessee Community College',
  'Southwest Texas Junior College',
  'Southwest Texas State University',
  'Southwest University',
  'Southwest University of Finance and Economics',
  'Southwest University of Nationalities',
  'Southwest University of Political Science and Law',
  'Southwest University of Science and Technology',
  'Southwest Valley Middle School',
  'Southwest Vermont Career Development Center',
  'Southwest Virginia Community College',
  "Southwest Virginia Governor's School",
  'Southwest Wisconsin Technical College',
  'Southwest Youth And Family Services',
  'Southwestern Adventist College',
  'Southwestern Assemblies of God University',
  'Southwestern Classical Academy',
  'Southwestern College',
  'Southwestern Community College',
  'Southwestern Elementary',
  'Southwestern Elementary School',
  'Southwestern Heights Jr/sr High',
  'Southwestern High School',
  'Southwestern Illinois College',
  'Southwestern Indian Polytechnic Institute',
  'Southwestern Michigan College',
  'Southwestern Middle School',
  'Southwestern Oregon Community College',
  'Southwestern Randolph High',
  'Southwestern Randolph Middle',
  'Southwestern Senior High School',
  'Southwestern University',
  'Southwestern Va Mental Health Institute',
  'Southwestern Va Tr Ctr Ep',
  'Southwestern Wisconsin Community Middle',
  'Southwestern Wisconsin Elementary',
  'Southwestern Wisconsin High',
  'Southwestern Youth Services',
  'Southwick Elementary School',
  'Southwick Regional School',
  'Southwick School',
  'Southwind Elementary',
  'Southwind High',
  'Southwinds Academy',
  'Southwood Academy For The Arts',
  'Southwood Elem.',
  'Southwood Elementary',
  'Southwood Elementary School',
  'Southwood Glen Elementary',
  'Southwood High School',
  'Southwood Jr-sr High School',
  'Southwood Middle School',
  'Southwood Valley El',
  'Southworth Elementary',
  'Soutsaka Institute of management and Technology',
  'Sovereign Avenue School',
  'Sowams Elementary School',
  'Sowers Alternative High School',
  'Sowers Elementary School',
  'Sowic Educ Center',
  'Sp Ed Co-op',
  'Sp Ed Transitions',
  'Space Aeronautics Gateway To Exploration Academy',
  'Space Center Int',
  'Space Coast Junior/senior High School',
  'Space Shuttle Columbia School',
  'Spackenkill High School',
  'Spaght Multimedia Magnet',
  'Spain Elementarymiddle School',
  'Spain Park High School',
  'Spain Rehab',
  'Spalding Drive Elementary',
  'Spalding High School',
  'Spalding Park Elementary',
  'Spanaway Elementary',
  'Spanaway Elementary Eceap',
  'Spanaway Elementary Preschool',
  'Spanaway Lake High School',
  'Spanaway Middle School',
  'Spanish Fork High',
  'Spanish Fork Jr High',
  'Spanish Fort Elementary School',
  'Spanish Fort High School',
  'Spanish Fort Middle School',
  'Spanish Lake Campus',
  'Spanish Lake Elementary School',
  'Spanish Lake Primary School',
  'Spanish Oaks School',
  'Spanish River Community High School',
  'Spanish River High Adult Education',
  'Spanish Springs Elementary School',
  'Spanish Springs High School',
  'Spanishburg',
  'Spann Elementary School',
  'Spanos (alex G.) Elementary',
  'Spark Academy',
  'Spark Charter',
  'Spark M. Matsunaga Elementary School',
  'Spark! Discovery Preschool',
  'Sparkman Elementary School',
  'Sparkman High School',
  'Sparkman Middle School',
  'Sparkman Ninth Grade School',
  'Sparks El',
  'Sparks Elementary',
  'Sparks High School',
  'Sparks Hs Preschool',
  'Sparks Integrated Preschool',
  'Sparks Middle',
  'Sparks Middle School',
  'Sparr Elementary School',
  'Sparrow Road Intermediate',
  'Sparrows Point High',
  'Sparrows Point Middle',
  'Sparta Adult Education',
  'Sparta Alpine School',
  'Sparta Alternative Independent Learning School',
  'Sparta Charter Prekindergarten',
  'Sparta El',
  'Sparta Elem.',
  'Sparta Elementary',
  'Sparta High',
  'Sparta High Point School',
  'Sparta High School',
  'Sparta Lincoln School',
  'Sparta Meadowview Intermediate',
  'Sparta Meadowview Middle',
  'Sparta Middle',
  'Sparta Middle School',
  'Sparta Pk Center',
  'Sparta Senior High School',
  'Spartan University of Health Sciences',
  'Spartanburg Community College',
  'Spartanburg High',
  'Spartanburg Methodist College',
  'Spartanburg Preparatory School',
  'Spartanburg School District 7 Early Childhood Center',
  'Spaulding Elementary',
  'Spaulding Elementary School',
  'Spaulding High School',
  'Spaulding Memorial',
  'Spaulding Middle',
  'Spaulding School',
  'Spaulding Uhsd #41',
  'Speake',
  'Spearfish Academy At Canyon Hills Elementary - 09',
  'Spearfish Academy At Canyon Hills High School - 10',
  'Spearfish High School - 01',
  'Spearfish Middle School - 05',
  'Spearman Elementary',
  'Spearman H S',
  'Spearman J H',
  'Spears El',
  'Spearville Elem',
  'Spearville Jr/sr High',
  'Speas Elementary',
  'Spec Rafael Hernando Middle',
  'Spec. Educ. Atc',
  'Special Acres School',
  'Special Ed Preschool',
  'Special Ed School',
  'Special Ed Yankee Hill Program',
  'Special Ed-non Public',
  'Special Ed.',
  'Special Ed. N.p.',
  'Special Education',
  'Special Education Building',
  'Special Education Center',
  'Special Education Facilities',
  'Special Education Pre-school',
  'Special Education Preschool',
  'Special Education Preschool Dis',
  'Special Education School',
  'Special Education Services',
  'Special Education Services/relife',
  'Special Education Servicessouth',
  'Special Education-infant/preschool Program',
  'Special Education-preschool-laurel',
  'Special Music School',
  'Special Needs Program',
  'Special Prog Ctr/j J A E P',
  'Special Prog Ctr/jjaep',
  'Special Program Center',
  'Special Programs',
  'Special Programs Ctr',
  'Special Services',
  'Special Services Center',
  'Special Services North',
  'Special Services School',
  'Spectrum Academy - Nsl',
  'Spectrum Academy - Pleasant Grove',
  'Spectrum Care Academy',
  'Spectrum Cs',
  'Spectrum Elementary',
  'Spectrum High School',
  'Spectrum Junior/senior High School',
  'Spectrum Middle School',
  'Spectrum Middle School - Grades 7-8',
  'Spectrum Regional Academy',
  'Sped Preschool',
  'Sped Private/homebound',
  'Speech Program - Sp Ed Program',
  'Speed Early Learning Center',
  'Speed Independence Elem Sch',
  'Speed Independence High School',
  'Speed Program For Adaptive Learn',
  'Speedway Avenue School',
  'Speedway Junior High School',
  'Speedway Senior High School',
  'Speedwell Elementary',
  'Speegleville El',
  'Speer El',
  'Speight Middle',
  'Spelman College',
  'Spence El',
  'Spence Elementary',
  'Spence Memorial Es',
  'Spencer Academy',
  'Spencer Borden',
  'Spencer Butte Middle School',
  'Spencer County Elementary School',
  'Spencer County Headstart',
  'Spencer County High School',
  'Spencer County Middle School',
  'Spencer County Preschool',
  'Spencer Crossing',
  'Spencer Elementary',
  'Spencer Elementary School',
  'Spencer Es',
  'Spencer High School',
  'Spencer Junior High/high',
  'Spencer Loomis Elementary School',
  'Spencer Middle School',
  'Spencer Pointe',
  'Spencer Road Elementary School',
  'Spencer School',
  'Spencer Technology Acad Elem Sch',
  'Spencer Trail',
  'Spencer Valley Elementary',
  'Spencer-van Etten Elementary School',
  'Spencer-van Etten High School',
  'Spencer-van Etten Middle School',
  'Spencerport High School',
  'Spencerville Elementary School',
  'Spencerville High School',
  'Spencerville Middle School',
  'Spencerwestlawn Elementary School',
  'Spero Academy',
  'Sperry Es',
  'Sperry Hs',
  'Sperry Ms',
  'Spessard L Holland Elementary',
  'Spessard L. Holland Elementary School',
  'Spicer Alter Ed Ctr',
  'Spicewood El',
  'Spicewood Park El',
  'Spickard Elem.',
  'Spillane Middle',
  'Spiller Elementary',
  'Spindale Elementary School',
  'Spink Colony Elementary - 03',
  'Spinning Elementary',
  'Spinning Hills Middle School (5-6)',
  'Spirit Creek Middle School',
  'Spirit Elementary School',
  'Spirit Lake Elementary School',
  'Spirit Lake High School',
  'Spirit Lake Middle School',
  'Spiritridge Elementary School',
  'Spiro Es',
  'Spiro Hs',
  'Spiro Ms',
  'Spiru Haret University',
  'Spitler Elementary School',
  'Splendora H S',
  'Splendora J H',
  'Split Rock Elementary School',
  'Spoede Elem.',
  'Spofford Pond',
  'Spofford Treatment Center',
  'Spokane Area Professional-technical Skills Center',
  'Spokane Community College',
  'Spokane County Jail',
  'Spokane Falls Community College',
  'Spokane High',
  'Spokane International Academy Elementary',
  'Spokane Juvenile Detention School',
  'Spokane Middle',
  'Spokane Public Montessori',
  'Spokane Regional Health District',
  'Spokane Valley High School',
  'Spokane Valley Learning Academy',
  'Spokane Valley Tech',
  'Spokane Valley Transition School',
  'Spook Hill Elementary School',
  'Spoon River College',
  'Spoon River Valley Elem School',
  'Spoon River Valley Jr High Sch',
  'Spoon River Valley Sr High Sch',
  'Spooner Elementary',
  'Spooner High',
  'Spooner Middle',
  'Sport And Medical Sciences Academy',
  'Sporting Hill El Sch',
  'Sports Leadership And Management (slam) Charter Middle Schoo',
  'Sports Leadership And Management (slam) Middle School - Nort',
  'Sports Leadership And Management (slam) Middle School Palm B',
  'Sports Leadership And Management Academy',
  'Sports Leadership And Management Academy (slam',
  'Sports Leadership And Management Academy (tampa)',
  'Sports Leadership Of Miami Charter High School',
  'Spoto High School',
  'Spotswood Elementary',
  'Spotswood High',
  'Spotswood High School',
  'Spotswood Memorial Middle School',
  'Spotsylvania Career And Technical Center',
  'Spotsylvania High',
  'Spotsylvania Middle',
  'Spottsville Elementary School',
  'Spout Springs Elementary School',
  'Spradling Elementary School',
  'Sprague Elementary',
  'Sprague Elementary School',
  'Sprague High School',
  'Sprague School',
  'Spray School',
  'Sprayberry Education Center',
  'Sprayberry High School',
  'Spreckels Elementary',
  'Spring Arbor College',
  'Spring Ave Elem School',
  'Spring Bluff Elem.',
  'Spring Branch Academic Institute',
  'Spring Branch Ed Ctr',
  'Spring Branch El',
  'Spring Branch Elem.',
  'Spring Branch Middle',
  'Spring Brook Elementary School',
  'Spring City El Sch',
  'Spring City Elementary',
  'Spring City Middle School',
  'Spring City School',
  'Spring Cove El Sch',
  'Spring Cove Ms',
  'Spring Creek - 6th Grade',
  'Spring Creek Charter School',
  'Spring Creek Colony Elementary - 04',
  'Spring Creek Colony Schl',
  'Spring Creek Community School',
  'Spring Creek El',
  'Spring Creek Elem School',
  'Spring Creek Elementary',
  'Spring Creek Elementary - 07',
  'Spring Creek Elementary School',
  'Spring Creek Es',
  'Spring Creek High',
  'Spring Creek Matanzas Charter',
  'Spring Creek Middle',
  'Spring Creek Middle School',
  'Spring Creek School',
  'Spring Creek Youth Services Center',
  'Spring Early College Academy',
  'Spring Elementary School',
  'Spring Farms El Sch',
  'Spring Forest Middle',
  'Spring Forge Intrd Sch',
  'Spring Garden El',
  'Spring Garden El Sch',
  'Spring Garden Elementary',
  'Spring Garden Elementary School',
  'Spring Garden High School',
  'Spring Garden Middle',
  'Spring Garden Middle School',
  'Spring Garden Sch',
  'Spring Garden School',
  'Spring Glen School',
  'Spring Grove Area Hs',
  'Spring Grove Area Intrmd Sch',
  'Spring Grove Area Ms',
  'Spring Grove El Sch',
  'Spring Grove Elementary',
  'Spring Grove Elementary School',
  'Spring Grove Primary Center',
  'Spring Grove Secondary',
  'Spring H S',
  'Spring Harbor Middle',
  'Spring Hill College',
  'Spring Hill Elementary',
  'Spring Hill Elementary School',
  'Spring Hill H S',
  'Spring Hill High',
  'Spring Hill High (continuation)',
  'Spring Hill High School',
  'Spring Hill Int',
  'Spring Hill J H',
  'Spring Hill Middle',
  'Spring Hill Middle School',
  'Spring Hill Opportunity',
  'Spring Hill Pri',
  'Spring Hills Elem School',
  'Spring Hope Elementary',
  'Spring Lake Alternative Education',
  'Spring Lake Colony Elementary - 04',
  'Spring Lake Colony High School - 05',
  'Spring Lake Elem School',
  'Spring Lake Elementary',
  'Spring Lake Elementary School',
  'Spring Lake Heights Elementary School',
  'Spring Lake High School',
  'Spring Lake Intermediate School',
  'Spring Lake Magnet Center',
  'Spring Lake Middle',
  'Spring Lake Middle School',
  'Spring Lake Park El',
  'Spring Lake Park Senior High',
  'Spring Lake School',
  'Spring Lake Youth Academy',
  'Spring Lane School',
  'Spring Meadow Early Childhood Center',
  'Spring Meadows',
  'Spring Meadows El',
  'Spring Mill Elementary School',
  'Spring Mills Elementary School',
  'Spring Mills High School',
  'Spring Mills Middle School',
  'Spring Mills Primary',
  'Spring Mountain Elementary School',
  'Spring Mountain J/shs',
  'Spring Oaks Middle',
  'Spring Park Elementary School',
  'Spring Place Elementary School',
  'Spring Prairie Elementary',
  'Spring Ridge El Sch',
  'Spring Ridge Elementary',
  'Spring Ridge Middle',
  'Spring Road Elementary',
  'Spring Run Elementary',
  'Spring Shadow El',
  'Spring Station Middle School',
  'Spring Street',
  'Spring Street School',
  'Spring Trail Elementary School',
  'Spring Valley Academy',
  'Spring Valley Center For Exploration',
  'Spring Valley Colony Elementary - 05',
  'Spring Valley El',
  'Spring Valley Elem.',
  'Spring Valley Elementary',
  'Spring Valley High',
  'Spring Valley High School',
  'Spring Valley Middle',
  'Spring View Elementary School',
  'Spring View Middle',
  'Spring Wood Middle School',
  'Spring Woods H S',
  'Spring Woods Middle',
  'Spring-ford Intrmd Sch 5th/6th',
  'Spring-ford Ms 7th Grade Ctr',
  'Spring-ford Ms 8th Grade Ctr',
  'Spring-ford Shs 9-12 Ctr',
  'Springboro High School',
  'Springboro Intermediate School',
  'Springboro Junior High School',
  'Springbrook Elementary',
  'Springbrook Elementary School',
  'Springbrook High',
  'Springbrook Middle School',
  'Springcreek Primary Elementary School',
  'Springdale Academy',
  'Springdale El',
  'Springdale Elementary',
  'Springdale Elementary School',
  'Springdale Es',
  'Springdale High School',
  'Springdale Jshs',
  'Springdale Middle School',
  'Springdale Park Elementary School',
  'Springdale School',
  'Springdale School Of Innovations',
  'Springer Correctional Facility',
  'Springer Elementary',
  'Springer Es',
  'Springer High',
  'Springer Hs',
  'Springer Middle School',
  'Springfield Ball Charter School',
  'Springfield Central High',
  'Springfield Community Day',
  'Springfield El Sch',
  'Springfield Elem School',
  'Springfield Elementary',
  'Springfield Elementary - 03',
  'Springfield Elementary School',
  'Springfield Estates Elementary',
  'Springfield High School',
  'Springfield High School Of Science And Technology',
  'Springfield Hs',
  'Springfield Intermediate Middle School',
  'Springfield Junior/senior High School',
  'Springfield Learning Academy',
  'Springfield Literacy Center',
  'Springfield Middle',
  'Springfield Middle School',
  'Springfield Park Elementary',
  'Springfield Plains Elem School',
  'Springfield Preparatory And Fitness Academy',
  'Springfield Preparatory Charter School',
  'Springfield Primary',
  'Springfield Public Day Elementary School',
  'Springfield Public Day High School',
  'Springfield Public Day Middle School',
  'Springfield Secondary',
  'Springfield Southeast High Sch',
  'Springfield Technical Community College',
  'Springfield Township School',
  'Springfield Twp El Sch-enfield',
  'Springfield Twp El Sch-erdenhm',
  'Springfield Twp Hs',
  'Springfield Twp Ms',
  'Springfield Vocational Academy',
  'Springfield-clark County',
  'Springfield-green Spring Elementary Sch',
  'Springhead Elementary School',
  'Springhill El',
  'Springhill Elementary',
  'Springhill Elementary School',
  'Springhill Lake Elementary',
  'Springhill School',
  'Springhouse Ms',
  'Springhurst Elementary School',
  'Springlake-earth Daep',
  'Springlake-earth Elem/middle School',
  'Springlake-earth H S',
  'Springman Middle School',
  'Springmill Stem Elementary',
  'Springmore Elementary',
  'Springport Elementary School',
  'Springport High School',
  'Springport Middle School',
  'Springridge El',
  'Springs Ranch Elementary School',
  'Springs School',
  'Springs Studio For Academic Excellence',
  'Springs Valley Comm High School',
  'Springs Valley Elementary School',
  'Springside School',
  'Springton Lake Ms',
  'Springton Manor El Sch',
  'Springtown El',
  'Springtown H S',
  'Springtown Int',
  'Springtown Middle',
  'Springtown Reno El',
  'Springview Elementary School',
  'Springville Educational Center',
  'Springville Elem School',
  'Springville Elementary',
  'Springville Elementary School',
  'Springville High',
  'Springville High School',
  'Springville Jr High',
  'Springville K-8 School',
  'Springville Middle School',
  'Springville Secondary School',
  'Springwater Environmental Sciences School',
  'Springwater Trail High School',
  'Springwood Elementary School',
  'Springwoods Elementary',
  'Sproul Junior High School',
  'Spruance Gilbert Sch',
  'Spruce Creek Elementary School',
  'Spruce Creek High School',
  'Spruce Elementary',
  'Spruce Elementary School',
  'Spruce Lane Colony School',
  'Spruce Mountain Elementary School',
  'Spruce Mountain High School',
  'Spruce Mountain Middle School',
  'Spruce Mountain Primary School',
  'Spruce Run',
  'Spruce School',
  'Spruce Street Elementary School',
  'Spruce Street School',
  'Sprucewood School',
  'Sprunica Elementary School',
  'Spry Community Links High School',
  'Spry Elem Community School',
  'Spry Middle School',
  'Spur School',
  'Spurgeon Intermediate',
  'Spurger El',
  'Spurger H S',
  'Spy Rock Elementary',
  'Squadron Line School',
  'Squalicum High School',
  'Squannacook Early Childhood Center',
  'Squantum',
  'Squaw Gap School',
  'Squaw Valley Preparatory',
  'Squire John Thomas Elementary',
  'Squires Elementary School',
  'Sree Chitra Tirunal Institute for Medical Sciences and Technology',
  'Sree Rama Krishna Degree College',
  'Sree Sankaracharya University of Sanskrit',
  'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya',
  'Sri Krishnadevaraya University',
  'Sri Lanka Institute of Advanced Technical Education',
  'Sri Lanka Institute of Information Technology (SLIT)',
  "Sri Padmavati Women's University",
  'Sri Ramachardra Medical College and Research Institute',
  'Sri Sant Gajanan Maharaj College of Engineering',
  'Sri Sathya Sai Institute of Higher Learning',
  'Sri Venkateswara Institute of Medical Sciences',
  'Sri Venkateswara University',
  'Srinakharinwirot University',
  'Sripatum University',
  'Sru El Sch',
  'Ssg Manuel R Puentes',
  'St Albans Consolidated',
  'St Anne Comm High School',
  'St Anne Elem School',
  'St Ansgar Elementary School',
  'St Ansgar High School',
  'St Anthony Academy',
  'St Anthony School',
  'St Bernard Elementary School',
  'St Bernard-elmwood Place High School',
  'St Charles Community College',
  'St Charles Community Middlehigh School',
  'St Charles East High School',
  'St Charles Elementary School',
  'St Charles North High School',
  'St Charles Virtual School',
  'St Clair Co Juv Detention Center',
  'St Clair County Community College',
  'St Clair County Intervention Academy',
  'St Clair Elementary',
  'St Clair High School',
  'St Clair Middle School',
  'St Clairsville Elementary School',
  'St Clairsville High School',
  'St Clairsville Middle School',
  'St Clements University - Higher Education School',
  'St Cloud Area Learning Center',
  'St Cloud Extended Day Program',
  'St Cloud Hospital Progs-residential',
  'St Cloud Technical and Community College',
  'St Coletta Special Education Pcs',
  'St Croix Correctional Center',
  'St Croix Preparatory Academy Middle',
  'St Croix Regional Technical Center',
  'St Croix Valley Area Learning Cntr.',
  'St David Elementary School',
  'St David High School',
  'St Edward Elementary School',
  'St Edward High School',
  'St Elmo El',
  'St Elmo Elem School',
  'St Elmo Jr High School',
  'St Elmo Sr High School',
  'St Francis Elem',
  'St Francis High',
  'St George Elem',
  'St George Elem School',
  'St George School',
  'St Helens Arthur Academy',
  'St Helens High School',
  'St Helens Middle School',
  'St Henry Elementary School',
  'St Henry High School',
  'St Henry Middle School',
  'St Ignace Elementarymiddle School',
  'St Ignace Juvenile Detention Facility',
  'St Ignatius Elementary School',
  'St Ignatius High School',
  'St Ignatius Middle School',
  'St Jacob Elem School',
  'St James Elementary',
  'St John Elem',
  'St John Elementary',
  'St John High',
  'St John Valley Technology Center',
  'St John/endicott High',
  'St Johns Classical Academy',
  'St Johns County Juvenile Residential',
  'St Johns County Transition Program',
  'St Johns High School',
  'St Johns Middle School',
  "St Joseph Children's Home",
  'St Joseph Elem School',
  'St Joseph High School',
  'St Joseph Middle School',
  'St Joseph-ogden High School',
  'St Lake/ia Cntrl/buena Vista Early College Hs',
  'St Lawrence Correctional Facility',
  'St Lawrence Middle School',
  'St Leonard Elementary',
  'St Libory Elem School',
  'St Libory Elementary School',
  'St Louis Correctional Facility',
  'St Louis Early Childhood Center',
  'St Louis High School',
  'St Louis Lang Immersion School',
  'St Lukes Hospital',
  'St Maries Community Education Alternative',
  'St Maries High School',
  'St Maries Middle School',
  'St Martin East Elementary School',
  'St Martin High School',
  'St Martin N Elementary School',
  'St Martin Upper Elementary',
  "St Mary's Academy Charter School",
  'St Marys Adolescent Ed Program',
  'St Marys Area Ms',
  'St Marys Area Shs',
  'St Marys Elem',
  'St Marys Memorial High School',
  'St Marys Public School',
  'St Marys West Intermediate School',
  'St Matthews Elementary',
  'St Michael-albertville Middle West',
  'St Paul Conservatory Performing Art',
  'St Paul Elementary School',
  'St Paul High School',
  'St Paul Junior High School',
  'St Paul School',
  "St Philip's College",
  "St Philip's College Early College H S With Saisd",
  'St Regis 7-8',
  'St Regis High School',
  'St Regis School',
  'St Rose Elem School',
  "St Theresa's Medical University",
  'St Thomas El Sch',
  'St Vincent Home',
  'St. Albans City Elementary School',
  'St. Albans Town Educational Center',
  'St. Amant High School',
  'St. Amant Middle School',
  'St. Amant Primary School',
  'St. Ambrose University',
  'St. Andrew Georgian University',
  'St. Andrew School',
  "St. Andrew's School Of Math And Science",
  "St. Andrew's University",
  'St. Andrews Middle',
  'St. Andrews Presbyterian College',
  'St. Anne University',
  'St. Ansgar Middle School',
  'St. Anthony Middle',
  'St. Anthony Park Elementary',
  'St. Anthony Village Alternative',
  'St. Anthony Village Senior High',
  'St. Augustine High School',
  'St. Augustine International University',
  'St. Augustine Public Montessori School (sapms)',
  'St. Augustine University of Tanzania',
  'St. Bernard Middle School',
  'St. Bethlehem Elementary',
  'St. Bonaventure University',
  'St. Charles Elementary',
  'St. Charles Elementary School',
  'St. Charles High',
  'St. Charles High School',
  'St. Charles Secondary',
  'St. Charles Street Elementary School',
  'St. Charles West High',
  'St. Clair College',
  'St. Clair Elem.',
  'St. Clair Elementary',
  'St. Clair High',
  'St. Clair Jr. High',
  'St. Clair Secondary',
  'St. Clare Walker Middle',
  'St. Cloud Elementary School',
  'St. Cloud Group Home',
  'St. Cloud High School',
  'St. Cloud Hospital Programs Non-res',
  'St. Cloud Middle School',
  'St. Cloud Preparatory Academy',
  'St. Cloud Preparatory Academy High School',
  'St. Croix Educational Compex High School',
  'St. Croix Educational Complex Vocational School',
  'St. Croix Preparatory Academy Lower',
  'St. Croix Preparatory Academy Upper',
  'St. Elizabeth Elem.',
  'St. Elizabeth High',
  'St. Elizabeth’s College of Health and Social Sciences in Bratislava',
  'St. Francis Elementary',
  'St. Francis High',
  'St. Francis Indian School',
  'St. Francis Middle',
  'St. Francis Xavier University',
  'St. George Academy',
  'St. George Elementary School',
  'St. George Middle',
  "St. George's University",
  'St. Georges Technical High School',
  'St. Helena Arts And Technology Academy',
  'St. Helena College And Career Academy',
  'St. Helena Early Learning Center',
  'St. Helena Elementary',
  'St. Hope Leadership Academy Charter School',
  'St. Hope Public School 7',
  'St. Hosp. Training Center Parsons',
  'St. James Early Childhood',
  'St. James Elementary',
  'St. James High',
  'St. James High School',
  'St. James Intermediate',
  'St. James Middle',
  'St. James Middle School',
  'St. James Middle/high School',
  'St. James Northside Elementary',
  'St. James Santee Elementary',
  'St. James-gaillard Elementary',
  'St. Joe Elementary School',
  'St. Joe High School',
  'St. Joe K-12 School',
  'St. John Child Development Center',
  'St. John Elementary School',
  "St. John's & St. Mary's Institute of Technology",
  "St. John's College - Annapolis",
  "St. John's College - Santa Fe",
  "St. John's University",
  'St. Johns Community Campus',
  'St. Johns County Jail (djj)',
  'St. Johns Elementary',
  'St. Johns Elementary School',
  'St. Johns High',
  'St. Johns Lane Elementary',
  'St. Johns Learning Center',
  'St. Johns River State College',
  'St. Johns Technical High School',
  'St. Johns Virtual Franchise',
  'St. Johns Virtual Instruction Program K-8',
  'St. Johns Virtual Instructional Program',
  'St. Johnsbury School',
  'St. Joseph College (CT)',
  'St. Joseph Group of Institutes',
  'St. Joseph University Beirut',
  "St. Joseph's College (ME)",
  'St. Lawrence University',
  "St. Louis Children's Hospital",
  'St. Louis College Prep High',
  'St. Louis College Prep Middle',
  'St. Louis College of Pharmacy',
  'St. Louis Park Middle School',
  'St. Louis Park Senior High',
  'St. Louis Park Special Ed. Program',
  'St. Lucie County School Board-jail Program-ese',
  'St. Lucie Detention Center',
  'St. Lucie Elementary School',
  'St. Lucie Virtual Instruction Program',
  'St. Lucie West Centennial High',
  'St. Lucie West K-8 School',
  "St. Luke' s College of Nursing",
  'St. Marianna University School of Medicine',
  'St. Marks School',
  'St. Martin Middle School',
  "St. Martin's College",
  'St. Martinville Junior High School',
  'St. Martinville Primary School',
  'St. Martinville Senior High School',
  "St. Mary's College of Maryland",
  "St. Mary's County Evening High",
  "St. Mary's County Evening High School",
  "St. Mary's University",
  'St. Marys East Primary School',
  'St. Marys Elementary School',
  'St. Marys High School',
  'St. Marys Junior Senior High',
  'St. Marys Middle School',
  'St. Marys Video And Communication Technology',
  'St. Matthews K-8 School',
  'St. Matthews University',
  'St. Michael Elementary',
  'St. Michael-albertville Middle East',
  'St. Michael-albertville Senior High',
  'St. Michaels Elementary',
  'St. Michaels Middle/high School',
  'St. Paul',
  'St. Paul City Middle School',
  'St. Paul City Primary School',
  'St. Paul Elementary',
  'St. Paul Elementary School',
  'St. Paul High School',
  'St. Paul Middle School',
  'St. Paul University',
  'St. Pauls University',
  'St. Peter Alc',
  'St. Peter Ecse',
  'St. Peter High School',
  'St. Peter Middle School',
  "St. Peter's Academy",
  'St. Peters Elem.',
  'St. Petersburg College',
  'St. Petersburg Collegiate High School',
  'St. Petersburg High School',
  'St. Petersburg Institute of Arts and Restoration',
  'St. Petersburg Institute of Sales Management and Economics',
  'St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture',
  'St. Petersburg State Academy for Engineering Economics (ENGECON)',
  'St. Petersburg State Agrarian University',
  'St. Petersburg State Chemical Pharmaceutical Academy',
  'St. Petersburg State Cinema and TV University',
  'St. Petersburg State Conservatory',
  'St. Petersburg State Electrotechnical University',
  'St. Petersburg State Forest-Technical Academy',
  'St. Petersburg State Institute of Technology (Technological University)',
  'St. Petersburg State Marine Technical University',
  'St. Petersburg State Medical Academy',
  'St. Petersburg State Mining Institute (Technical University)',
  'St. Petersburg State Mountain Institut',
  'St. Petersburg State Pediatric Medical Academy',
  'St. Petersburg State Polytechnical University',
  'St. Petersburg State Technical University',
  'St. Petersburg State Technological University of Plant Polymers',
  'St. Petersburg State Theatre Arts Academy',
  'St. Petersburg State University',
  'St. Petersburg State University of Aerospace Instrumentation',
  'St. Petersburg State University of Architecture and Civil Engineering',
  'St. Petersburg State University of Civil Aviation',
  'St. Petersburg State University of Culture and Arts',
  'St. Petersburg State University of Economics and Finance',
  'St. Petersburg State University of Engineering and Economics',
  'St. Petersburg State University of Information Technologies Mechanics and Optics',
  'St. Petersburg State University of Technology and Design',
  'St. Petersburg State University of Telecommunication',
  'St. Petersburg State University of Waterway Communications',
  'St. Petersburg StateMechnikov  Medical Academy',
  'St. Rose Elementary School',
  'St. Simons Elementary School',
  'St. Stephen Elementary',
  'St. Stephen Middle',
  'St. Stephens Indian School',
  'St. Tammany Junior High School',
  'St. Thomas University',
  'St. Thomas University (FL)',
  'St. Vrain Community Montessori School',
  'St. Vrain Global Online Academy',
  'St. Vrain Valley P-tech School',
  'St.Cyril and Methodius University',
  "St.James's School of Medicine Bonaire",
  'St.Kliment Ohridski University',
  "St.Patrick's International College London",
  'St.pierre Education Center',
  'Staars',
  'Staatlich anerkannte Fachhochschule für Kunsttherapie',
  'Staatliche Hochschule für Musik',
  'Stacy Middle',
  'Stadium',
  'Stadium Drive Elementary School',
  'Stadium School',
  'Stadium View',
  'Stadley Rough School',
  'Staff Sargent James J. Hill Elementary School',
  'Staff Sergeant Lucian Adams El',
  'Stafford Academy For Technolgy (stat)',
  'Stafford Adjustment Center',
  'Stafford Autism',
  'Stafford Center',
  'Stafford El',
  'Stafford Elementary',
  'Stafford Elementary School',
  'Stafford H S',
  'Stafford High School',
  'Stafford Int',
  'Stafford Intermediate School',
  'Stafford Middle',
  'Stafford Middle School',
  'Stafford Middle School/high School',
  'Stafford Pri',
  'Stafford Primary School',
  'Stafford School',
  'Stafford Senior High',
  'Stafford Technical Center',
  'Staffordshire University',
  'Staffordville School',
  'Stagebarn Middle School - 10',
  'Stagecoach Elementary',
  'Stagecoach Elementary School',
  'Stagecoach School',
  'Stagg Elem School',
  'Stagg Senior High',
  'Stagg Street Elementary',
  'Stahl El',
  'Stahl Junior High',
  'Staley High',
  'Staley Middle',
  'Stalker Elementary School',
  'Stall Brook',
  'Stallings Elementary',
  'Stallings Island Middle School',
  'Stambaugh Charter Academy',
  'Stambaugh Elementary School',
  'Stamford Academy',
  'Stamford Central School',
  'Stamford Charter School For Excellence',
  'Stamford Elementary School',
  'Stamford H S',
  'Stamford High School',
  'Stamford International University',
  'Stamford Middle',
  'Stamford University',
  'Stamm Elementary School',
  'Stamping Ground Elementary School',
  'Stanaford Elementary School',
  'Stanberry Elem.',
  'Stanberry High',
  'Stand Watie Es',
  'Standard Elementary',
  'Standard Middle',
  'Standiford Elementary',
  'Standing Bear Elementary Sch',
  'Standing Pine Elementary School',
  'Standing Rock Community School',
  'Standing Rock Spec Ed Unit',
  'Standing Stone El Sch',
  'Standishsterling Central Elementary School',
  'Standishsterling Central Jrsr High School',
  'Standishsterling Middle School',
  'Standley Lake High School',
  'Standley Middle',
  'Stanfield Elementary',
  'Stanfield Elementary School',
  'Stanfield Family Integrated Center',
  'Stanfield Secondary School',
  'Stanford 7-8',
  'Stanford Avenue Elementary',
  'Stanford Elementary',
  'Stanford Elementary School',
  'Stanford High School',
  'Stanford J Gibson Primary School',
  'Stanford Middle',
  'Stanford Primary Center',
  'Stanford School',
  'Stanford University',
  'Stangel Elementary',
  'Stanhope Elmore High School',
  'Stanislaus Alternative Charter',
  'Stanislaus County Institute Of Learning',
  'Stanislaus County Special Education',
  'Stanislaus County West Campus',
  'Stanislaus Elementary',
  'Stanley',
  'Stanley C Stanley El',
  'Stanley Correctional Institution',
  'Stanley County Elementary - 08',
  'Stanley County High School - 01',
  'Stanley County Middle School - 02',
  'Stanley Elem',
  'Stanley Elem/jr High School',
  'Stanley Elementary',
  'Stanley Elementary School',
  'Stanley G. Oswalt Academy',
  'Stanley High School',
  'Stanley M Koziol Elementary School',
  'Stanley Makowski Early Childhood Center',
  'Stanley Middle',
  'Stanley Mosk Elementary',
  'Stanley Switlik Elementary School',
  'Stanley-boyd High',
  'Stanley-boyd Middle',
  'Stanleytown Elementary',
  'Stanly Academy Learning Center',
  'Stanly Community College',
  'Stanly County Virtual Education',
  'Stanly Early College High',
  'Stansberry Elementary School',
  'Stansbury High',
  'Stansbury Park School',
  'Stansbury School',
  'Stanton Academy',
  'Stanton College Preparatory',
  'Stanton County Elementary',
  'Stanton County Jr./sr. High School',
  'Stanton Edwin M Sch',
  'Stanton El',
  'Stanton Elem.',
  'Stanton Elementary',
  'Stanton Elementary School',
  'Stanton Es',
  'Stanton H S',
  'Stanton High School',
  'Stanton Middle',
  'Stanton Middle School',
  'Stanton Primary Elementary School',
  'Stanton School',
  'Stanton-smith El',
  'Stanton-weirsdale Elementary School',
  'Stantonsburg Elementary',
  'Stanwood A. Murphy Elementary',
  'Stanwood El Sch',
  'Stanwood Elementary School',
  'Stanwood High School',
  'Stanwood Middle School',
  'Staples High School',
  'Staples-motley Elementary',
  'Staples-motley Senior High',
  'Stapleton Elem.',
  'Stapleton Elementary School',
  'Stapleton High School',
  'Stapleton School',
  'Stapley Junior High School',
  'Star Academic High School',
  'Star Academy',
  'Star Academy High School',
  'Star Academy Of Toledo',
  'Star Academy Ps 63 (the)',
  'Star At Anderson Community Day',
  'Star Center Elementary',
  'Star Charter School',
  'Star City High School',
  'Star City Middle School',
  'Star Elem School',
  'Star Elementary',
  'Star Elementary School',
  'Star Family Literacy',
  'Star Hill Elementary School',
  'Star International Academy',
  'Star Lake Elementary School',
  'Star Middle School',
  'Star Of The North Academy Charter S',
  'Star Of The North Secondary School',
  'Star Program',
  'Star Spencer Hs',
  'Star Transition',
  'Star Valley High School',
  'Star Valley Middle School',
  'Star View Elementary',
  'Starbuck Elementary School',
  'Starbuck Middle',
  'Starbuck School',
  'Stargate Charter School',
  'Stark College And Career High School',
  'Stark County Elem Sch',
  'Stark County High School',
  'Stark County Junior High School',
  'Stark El',
  'Stark Elem.',
  'Stark Elementary School',
  'Stark State College',
  'Stark Village School',
  'Starke Elementary School',
  'Starkey El',
  'Starkey Elementary School',
  'Starks High School',
  'Starkville High School',
  'Starkweather Academy Fiegel',
  'Starkweather Elementary School',
  'Starkweather High School',
  'Starland Elementary',
  'Starlight Cove Elementary School',
  'Starlight Elementary',
  'Starlight Park School',
  'Starline Elementary School',
  'Starling Elementary School',
  'Starling Pk-8',
  'Starmont Elementary School',
  'Starmont High School',
  'Starmont Middle School Comm School District',
  'Starmount Acad Of Excellence',
  'Starmount High',
  'Starmount Middle',
  'Starms Discovery',
  'Starms Early Childhood',
  'Starpoint High School',
  'Starpoint Middle School',
  'Starquest Acad Rssp North',
  'Starquest Acad Rssp South',
  'Starr Academy',
  'Starr Albion Prep',
  'Starr Detroit Academy',
  'Starr Elementary',
  'Starr Elementary School',
  'Starr King K-8',
  'Starr-iva Middle',
  'Starrett El',
  'Starrs Mill High School',
  'Starrs Online Academy',
  'Starry Elementary School',
  'Stars Academy',
  'Stars Accelerated H S',
  'Stars Autism Program',
  'Stars Charter',
  'Stars Early Learning Center',
  'Stars-meriden School District',
  'Starshine Academy',
  'Starshine Fay Landrum Academy',
  'Starside Elem',
  'Start Campus',
  'Start Elementary School',
  'Start High School',
  'Startown Elementary',
  'Startzville El',
  'Staszic Pila College',
  'State Bridge Crossing Elementary School',
  'State College Area Hs',
  'State College Of Florida Collegiate School',
  'State El Ctr',
  'State Engineering University of Armenia',
  'State Fair',
  'State Fair Community College',
  'State Film Television and Theatre Higher School Leon Schiller in Lodz',
  'State Line Elementary',
  'State Maritine Academy',
  'State Pedagogical University in Kryvyi Rih',
  'State Road Elementary',
  'State Road Elementary School',
  'State School For The Blind',
  'State Street Academy',
  'State Street El Sch',
  'State Street Elem',
  'State Street Elementary',
  'State Street High School',
  'State Street Intermediate School',
  'State Street School',
  'State University of Aerospace Technologies',
  'State University of Management',
  'State University of New York College at Brockport',
  'State University of New York College at Buffalo',
  'State University of New York College at Cortland',
  'State University of New York College at Geneseo',
  'State University of New York College at New Paltz',
  'State University of New York College at Potsdam',
  'State University of New York College of Agriculture and Technology at Cobleskill',
  'State University of New York College of Environmental Science and Forestry',
  'State University of New York College of Technology at Alfred',
  'State University of New York Polytechnic Institute',
  'State University of New York System',
  'State University of New York at Albany',
  'State University of New York at Binghamton',
  'State University of New York at Buffalo',
  'State University of New York at Farmingdale',
  'State University of New York at Fredonia',
  'State University of New York at Oswego',
  'State University of New York at Plattsburgh',
  'State University of New York at Stony Brook',
  'State University of Tetova',
  'Staten Island School Of Civic Leadership',
  'Staten Island Technical High School',
  'Statesboro High School',
  'Statesboro Steam Academy',
  'Stateside Elementary',
  'Statesville High',
  'Statesville Middle',
  'Statesville Road Elementary',
  'Statham Elementary School',
  'Station Avenue Elementary',
  'Station Camp Elementary',
  'Station Camp High School',
  'Station Camp Middle School',
  'Stauffer Middle',
  'Staunton City School Preschool Programs',
  'Staunton Elem School',
  'Staunton Elementary School',
  'Staunton High School',
  'Staunton Jr High School',
  'Staunton River High',
  'Staunton River Middle',
  'Stavropol State Technical University',
  'Stavropol State University',
  'Stayton Elementary School',
  'Stayton High School',
  'Stayton Middle School',
  'Ste Marie Elem School',
  'Ste. Genevieve Elem.',
  'Ste. Genevieve Middle',
  'Ste. Genevieve Sr. High',
  'Stead Elementary School',
  'Steadham El',
  'Steadley Elem.',
  'Steam Academy',
  'Steam Academy @ Burke',
  'Steam Academy At Mlk',
  'Steam Academy Of Akron',
  'Steam Academy Of Dayton',
  'Steam Academy Of Warren',
  'Steam Academy Of Warrensville Heights',
  'Steam Middle',
  'Steamboat Springs Early Childhood Center',
  'Steamboat Springs High School',
  'Steamboat Springs Middle School',
  'Stearne Allen M Sch',
  'Stearns',
  'Stearns Elementary School',
  'Stearns Jr-sr High School',
  'Stebbins High School',
  'Steck Elementary School',
  'Stedman Elementary',
  'Stedman Elementary School',
  'Stedman Primary',
  'Stedwick Elementary',
  'Stedy- Antelope Hs Satellite',
  'Stedy- Arizona Western College',
  'Stedy- Central Campus',
  'Stedy- Cibola High School Satellite',
  'Stedy- Desert View Academy',
  'Stedy- Gila Ridge Hs Satellite',
  'Stedy- Kofa Hs Satellite',
  'Stedy- San Luis Hs Satellite',
  'Stedy- Vista Hs Satellite',
  'Stedy- Yuma Hs Satellite',
  'Steed Es',
  'Steekee Elementary',
  'Steel Academy',
  'Steel Center For Career And Technical Ed',
  'Steel City Academy',
  'Steel Edward Sch',
  'Steel Valley Ms',
  'Steel Valley Shs',
  'Steele Canyon High',
  'Steele Creek Elementary',
  'Steele Elementary School',
  'Steele Junior High School',
  'Steele Lane Elementary',
  'Steele Montessori Academy',
  'Steele School',
  'Steele Street Elementary School',
  'Steele-dawson Elementary School',
  'Steele-dawson High School',
  'Steeleville Elem School',
  'Steeleville High School',
  'Steelton-highspire El Sch',
  'Steelton-highspire Hs',
  'Steelville Elem.',
  'Steelville High',
  'Steelville Middle',
  "Steen's Creek Elementary",
  'Steenrod Elementary School',
  'Steep Falls Elementary School',
  'Steeple Run Elem School',
  'Steere Farm Elementary School',
  'Steffan Manor Elementary',
  'Steffen Middle',
  'Stege Elementary',
  'Steger Intermediate Center',
  'Steger Primary Center',
  'Steger Sixth Grade Ctr.',
  'Stehekin Elementary',
  'Stehlik Int',
  'Steilacoom High',
  'Steinbeis-Hochschule-Berlin',
  'Steinbrenner High School',
  'Steindorf Steam K-8 Magnet',
  'Steiner Ranch El',
  'Steinhatchee School',
  'Steinmetz College Prep Hs',
  'Stell Middle',
  'Stella Brockman Elementary',
  'Stella I. Hills Elementary',
  'Stella Marquez',
  'Stella May Swartz Elem School',
  'Stella Mayfield Elementary School',
  'Stella Middle Charter Academy',
  'Stella Schola',
  'Stella Worley Middle School',
  'Stellar Charter',
  'Stellar Collegiate Charter School',
  'Stellar Elementary School',
  'Stellar Leadership Academy',
  'Stellar Secondary Charter High',
  'Stelle Claughton Middle',
  'Steller Secondary School',
  'Stem - Health Academy',
  'Stem Academy',
  'Stem Academy - Lewisville',
  'Stem Academy At Bernstein High',
  'Stem At Showalter',
  'Stem Bridge School (the)',
  'Stem Early College @ Nc A&t Su',
  'Stem Early High',
  "Stem For Life Governor's Academy",
  'Stem Institute Of Manhattan',
  'Stem Lab',
  'Stem Launch',
  'Stem Magnet Academy Elem',
  'Stem Magnet At Fisher School',
  'Stem Middle Academy',
  'Stem Partnership School',
  'Stem Prep Academy',
  'Stem Prep High School',
  'Stem School Chattanooga',
  'Stem School Highlands Ranch',
  'Stemley Road Elementary School',
  'Stemmers Run Middle',
  'Stenden University',
  'Stennis Voc Tech Complex',
  'Stenstrom Elementary School',
  'Stenwood Elementary',
  'Step',
  'Step Academy Charter School',
  'Step Academy Elementary',
  'Step Ahead High School',
  'Step Charter El',
  'Step Charter Ii',
  'Step School',
  'Step Td Program',
  'Step Up School',
  'Step Up To College Open Doors High School',
  'Step-meriden School District',
  'Stepanski Early Childhood Center',
  'Stephanie A Vierno School (the)',
  'Stephanie Cravens Early Childhood',
  'Stephen And Harriet Myers Middle School',
  'Stephen Bell Elementary School',
  'Stephen C Foster El',
  'Stephen Decatur Elementary School',
  'Stephen Decatur High',
  'Stephen Decatur Middle',
  'Stephen Decatur Middle School',
  'Stephen F Austin',
  'Stephen F Austin El',
  'Stephen F Austin H S',
  'Stephen F Austin Pri',
  'Stephen F Austin State University Charter School',
  'Stephen F. Austin State University',
  'Stephen Foster Elementary',
  'Stephen Foster Elementary Charter',
  'Stephen Foster Elementary School',
  'Stephen Foster School 67',
  'Stephen J. Gerace School',
  'Stephen Knight Center For Early Education',
  'Stephen Knolls School',
  'Stephen M. White Middle',
  'Stephen Mack Middle School',
  'Stephen Olney School',
  'Stephen R. Fitz Intermediate',
  'Stephen S. Husse (la Vega)',
  'Stephen Senior High',
  'Stephen T Mather Building Arts And Craftmanship High School',
  'Stephens College',
  'Stephens County Fifth Grade Academy',
  'Stephens County High School',
  'Stephens County Middle School',
  'Stephens El',
  'Stephens Elementary',
  'Stephens Elementary School',
  'Stephens Middle',
  'Stephens Middle School',
  'Stephensen Elementary School',
  'Stephenson Elementary School',
  'Stephenson High School',
  'Stephenson Middle School',
  'Stephenson Middlehigh School',
  'Stephensville Elementary School',
  'Stephenville H S',
  'Stepney Elementary School',
  'Stepping Stones Academy',
  'Stepping Stones Preschool',
  'Steps Community Day',
  'Steps K-2 Nichols',
  'Steps Program',
  'Steps Toward Adult Responsibility',
  'Steps-meriden School District',
  'Stepstone Academy',
  'Steptoe Elementary School',
  'Steptoe Valley Adult Education',
  'Steptoe Valley High School',
  'Sterchi Elementary',
  'Sterling City El',
  'Sterling City School',
  'Sterling City/veribest Special Programs',
  'Sterling Community School',
  'Sterling Elem.',
  'Sterling Elementary',
  'Sterling Elementary School',
  'Sterling Es',
  'Sterling Grade School',
  'Sterling H Fly Jr H S',
  'Sterling H S',
  'Sterling Heights Senior Hs',
  'Sterling High School',
  'Sterling Hs',
  'Sterling Intermediate School',
  'Sterling Junior High/senior High',
  'Sterling Middle',
  'Sterling Middle School',
  'Sterling Montessori Academy',
  'Sterling Morton Elementary School',
  'Sterling Park Elementary School',
  'Sterling School',
  'Sterlington Elementary School',
  'Sterlington High School',
  'Sterlington Middle School',
  'Sterlitamak State Pedagogical Institute',
  'Stern Elementary School',
  'Sternberger Elementary',
  'Stetser El Sch',
  'Stetson Elementary School',
  'Stetson Hills Elementary',
  'Stetson Ms',
  'Stetson University',
  'Stetsonville Elementary',
  'Stettin Elementary',
  'Steuart Hill Academic Academy',
  'Steuart W. Weller Elementary',
  'Steuben Elementary School',
  'Steubenville High School',
  'Steubing El',
  'Steubing Ranch El',
  'Stevan Dohanos Elementary School',
  'Steve Cozine Elementary School',
  'Steve Luther Elementary',
  'Steve Schorr Elementary School',
  'Steven Millard Elementary',
  'Steven R. Jasinski Elementary School',
  'Stevens Alternate High School',
  'Stevens Brook School',
  'Stevens Creek Elementary',
  'Stevens Creek Elementary School',
  'Stevens El',
  'Stevens El Sch',
  'Stevens Elementary',
  'Stevens Elementary School',
  'Stevens Forest Elementary',
  'Stevens H S',
  'Stevens High School',
  'Stevens High School - 42',
  'Stevens Institute of Technology',
  'Stevens Middle School',
  'Stevens Park El',
  'Stevens Point Area Senior High',
  'Stevens Primary Sch',
  'Stevens T Mason Elementary School',
  'Stevenson (robert Louis) Elementary',
  'Stevenson Accelerated School',
  'Stevenson Elem School',
  'Stevenson Elementary',
  'Stevenson Elementary School',
  'Stevenson High School',
  'Stevenson Middle',
  'Stevenson Middle School',
  'Stevenson Ranch Elementary',
  'Stevenson School',
  'Stevenson University',
  'Stevensville 7-8',
  'Stevensville High School',
  'Stevensville K-6',
  'Stevensville Middle School',
  'Steward Elementary',
  'Steward Elementary School',
  'Stewardson-strasburg Elem School',
  'Stewardson-strasburg High School',
  'Stewart',
  'Stewart Academy',
  'Stewart Alternative Elementary School',
  'Stewart Co High School',
  'Stewart County Elementary School',
  'Stewart County High School',
  'Stewart County Middle School',
  'Stewart Creek El',
  'Stewart Creek High',
  'Stewart El',
  'Stewart El Sch',
  'Stewart Elem',
  'Stewart Elementary',
  'Stewart Elementary - 05',
  'Stewart Elementary School',
  'Stewart Heights Elementary',
  'Stewart Manor School',
  'Stewart Middle Magnet School',
  'Stewart Middle School',
  'Stewart Ms',
  'Stewart School',
  'Stewart Street Elementary School',
  'Stewart Treatment Center',
  'Stewarts Creek El',
  'Stewarts Creek Elementary School',
  'Stewarts Creek High School',
  'Stewarts Creek Middle School',
  'Stewartsboro Elementary',
  'Stewartstown Community School',
  'Stewartstown El Sch',
  'Stewartsville El Sch',
  'Stewartsville Elem.',
  'Stewartsville Elementary',
  'Stewartsville High',
  'Stewartsville Middle School',
  'Stewartville Middle',
  'Stewartville Senior High',
  'Stidham Public School',
  'Stiegel El Sch',
  'Stigall Primary School',
  'Stigler Es',
  'Stigler Hs',
  'Stigler Ms',
  'Stikine Middle School',
  'Stiles Elementary School',
  'Stiles Family Center',
  'Stiles Point Elementary',
  'Still Elementary School',
  'Still Middle School',
  'Stillaguamish School',
  'Stillman Middle',
  'Stillman Valley High School',
  'Stillmeadow School',
  'Stillson Elementary',
  'Stillwater Area High School',
  'Stillwater Dist. Special Services',
  'Stillwater Elementary',
  'Stillwater Elementary School',
  'Stillwater Hs',
  'Stillwater Jhs',
  'Stillwater Middle School',
  'Stillwater Middle School High School',
  'Stillwater Ms',
  'Stillwater Special Services-ecse',
  'Stillwater Township School',
  'Stilson Elementary School',
  'Stilwell Elementary',
  'Stilwell Es',
  'Stilwell Hs',
  'Stilwell Junior High School',
  'Stilwell Ms',
  'Stilwell Tech Ctr/cate',
  'Stine Elementary',
  'Stinesville Elementary School',
  'Stingel Elementary School',
  'Stingley Elementary School',
  'Stinnett Elementary School',
  'Stinson El',
  'Stinson Middle',
  'Stipe (samuel) Elementary',
  'Stirling Elementary School',
  'Stissing Mountain Junior/senior High School',
  'Stissing Mountain Middle School',
  'Stivers School For The Arts',
  'Stix Early Childhood Ctr.',
  'Sto-rox Jshs',
  'Sto-rox Primary Ctr',
  'Sto-rox Upper El Sch',
  'Stober Elementary School',
  'Stock Early Childhood Center',
  'Stockard Coffee Elementary',
  'Stockbridge Alternative High School',
  'Stockbridge Central School',
  'Stockbridge Elementary',
  'Stockbridge Elementary School',
  'Stockbridge High',
  'Stockbridge High School',
  'Stockbridge Jrsr High School',
  'Stockbridge Middle',
  'Stockbridge Middle School',
  'Stockbridge Valley Central School',
  'Stockdale El',
  'Stockdale Elementary',
  'Stockdale H S',
  'Stockdale High',
  'Stockdale J H',
  'Stockdick J H',
  'Stocker Elementary',
  'Stockholm Institute of Education',
  'Stockholm School of Economics',
  'Stockholm School of Economics in Riga',
  'Stockholm University',
  'Stocking Elementary',
  'Stocks Elementary',
  'Stockton (commodore) Children Center',
  'Stockton Borough School',
  'Stockton Collegiate International Elementary',
  'Stockton Collegiate International Secondary',
  'Stockton Elem',
  'Stockton Elem School',
  'Stockton Elem.',
  'Stockton High',
  'Stockton Middle',
  'Stockton Middle School',
  'Stockton Springs Elementary Sch',
  'Stockton Sr High School',
  'Stockton Unified Early College Academy',
  'Stocktrail Elementary',
  'Stockwell Elementary School',
  'Stockwell Place Elementary School',
  'Stoddard (alexander J.) Elementary',
  'Stoddard Elementary',
  'Stoddard Elementary School',
  'Stoddert Es',
  'Stokes',
  'Stokes Beard Elementary School',
  'Stokes Early College',
  'Stokes Elementary School',
  'Stokesdale Elementary',
  'Stoller Middle School',
  'Stolley Park Elem School',
  'Stone Academy',
  'Stone Avenue Elementary',
  'Stone Bank Elementary',
  'Stone Bridge',
  'Stone Bridge Academy',
  'Stone Bridge High',
  'Stone Canyon Es',
  'Stone Child College',
  'Stone Corral Elementary',
  'Stone Creek Elementary',
  'Stone Creek Elementary School',
  'Stone Creek School',
  'Stone Elem Scholastic Academy',
  'Stone Elem School',
  'Stone Elementary',
  'Stone Elementary School',
  'Stone Harbor Elementary School',
  'Stone High School',
  'Stone Hill Middle',
  'Stone Hill School',
  'Stone Hotchkiss Area',
  'Stone Lake Elementary',
  'Stone Lakes Elementary',
  'Stone Magnet Middle School',
  'Stone Memorial High School',
  'Stone Middle',
  'Stone Middle School',
  'Stone Mill Elementary',
  'Stone Mill Elementary School',
  'Stone Mountain Elementary',
  'Stone Mountain Elementary School',
  'Stone Mountain High School',
  'Stone Mountain Middle School',
  'Stone Oak El',
  'Stone Pk-12 School',
  'Stone Ranch Elementary',
  'Stone Ridge Es',
  'Stone Robinson Elementary',
  'Stone School',
  'Stone Spring Elementary',
  'Stone Valley Community Cs',
  'Stone Valley Middle',
  'Stonebrae Elementary',
  'Stonebridge Elementary',
  'Stonebridge Middle School',
  'Stonebridge World School',
  'Stonebrook Montessori',
  'Stonecreek Junior High',
  'Stonegate El',
  'Stonegate Elem.',
  'Stonegate Elementary',
  'Stoneham Central Middle School',
  'Stoneham High',
  'Stonehedge Elementary School',
  'Stonehouse Elementary',
  'Stonehurst Avenue Elementary',
  'Stonehurst Hills El Sch',
  'Stoneleigh Elementary',
  'Stoneman Elementary',
  'Stoner Avenue Elementary',
  'Stoner Creek Elementary',
  'Stoner Prairie Elementary',
  'Stoner-thomas School',
  'Stoneridge Elementary',
  'Stonestreet Elementary',
  'Stoneview Elementary School',
  'Stoneville Elementary',
  'Stonewall El',
  'Stonewall Elementary',
  'Stonewall Elementary School',
  'Stonewall Es',
  'Stonewall Hs',
  'Stonewall Jackson El',
  'Stonewall Jackson Elementary',
  'Stonewall Jackson Elementary School',
  'Stonewall Jackson High',
  'Stonewall Jackson Middle',
  'Stonewall Jackson Middle School',
  'Stonewall Middle',
  'Stonewall Tell Elementary School',
  'Stonewall/flanders El',
  'Stoney Creek Elementary',
  'Stoney Creek High School',
  'Stoney Point Continuation',
  'Stoney Point Elementary',
  'Stonington Boe Pre-school',
  'Stonington Elem School',
  'Stonington High School',
  'Stonington Hs Prek',
  'Stono Park Elementary',
  'Stony Brook El Sch',
  'Stony Brook Elementary',
  'Stony Brook Elementary School',
  'Stony Brook School',
  'Stony Creek El Sch',
  'Stony Creek Elem School',
  'Stony Creek Elementary School',
  'Stony Hill School',
  'Stony Lane El. School',
  'Stony Lane Elementary School',
  'Stony Mill Elementary',
  'Stony Point Academy',
  'Stony Point Elem.',
  'Stony Point Elementary',
  'Stony Point Elementary School',
  'Stony Point H S',
  'Stony Point North',
  'Stony Point South',
  'Stonybrook Elementary School',
  'Stonybrook Intermediate Academy',
  'Stonybrook Middle School',
  'Stopher Elementary',
  'Storefront Academy Charter School',
  'Storer Elementary School',
  'Stories Creek Elementary',
  'Storm El',
  'Storm Grove Middle School',
  'Storm Lake Elementary',
  'Storm Lake High School',
  'Storm Lake Middle School',
  'Stormonth Elementary',
  'Story Elementary',
  'Story Int',
  'Stott Elementary School',
  'Stottlemyer Early Childhood Ctr',
  'Stough Elementary',
  'Stoughton High',
  'Stourbridge Primary Ctr',
  'Stout Elem',
  'Stout Field Elementary School',
  'Stout Middle School',
  'Stoutland Elementary',
  'Stoutland High',
  'Stovall Academy',
  'Stovall Middle',
  'Stovall Sped Complex',
  'Stovall-shaw Elementary',
  'Stove Prairie Elementary School',
  'Stow Creek Township',
  'Stow-munroe Falls High School',
  'Stowe Elem School',
  'Stowe Elementary',
  'Stowe Elementary School',
  'Stowe Middle/high School',
  'Stowers Elementary School',
  'Stowers(cecil B.) Elementary',
  'Stoy Elementary School',
  'Strack Int',
  'Strafford Elem.',
  'Strafford High',
  'Strafford Middle',
  'Strafford School',
  'Straight Public School',
  'Strain-japan Elem.',
  'Straley Elementary School',
  'Stranahan Elementary School',
  'Stranahan High School',
  'Strandwood Elementary',
  'Strange Elementary',
  'Strange School',
  'Strasburg Elem.',
  'Strasburg Elementary School',
  'Strasburg High',
  'Strasburg High School',
  'Strasburg-franklin Elementary School',
  'Strasburg-franklin High School',
  'Strassburg Elem',
  'Stratfield School',
  'Stratford Academy - Johnson House',
  'Stratford Academy - Victoria Soto School',
  'Stratford Avenue School',
  'Stratford College London',
  'Stratford Elementary',
  'Stratford Es',
  'Stratford H S',
  'Stratford High',
  'Stratford High School',
  'Stratford Hs',
  'Stratford J H',
  'Stratford Landing Elementary',
  'Stratford Middle',
  'Stratford Middle School',
  'Stratford Ms',
  'Stratford Program',
  'Stratford Public School',
  'Stratford Road School',
  'Stratford Stem Magnet School',
  'Stratford University',
  'Strath Haven Hs',
  'Strath Haven Ms',
  'Stratham Memorial School',
  'Strathern Street Elementary',
  'Strathmore Community Day',
  'Strathmore Elementary',
  'Strathmore Elementary School',
  'Strathmore High',
  'Strathmore University',
  'Stratmoor Hills Elementary School',
  'Stratton Elementary',
  'Stratton Elementary School',
  'Stratton Meadows Elementary School',
  'Stratton Middle School',
  'Stratton Senior High School',
  'Straub Middle School',
  'Straughn Elementary School',
  'Straughn High School',
  'Straughn Middle School',
  'Strausser Elementary School',
  'Strawberry Crest High School',
  'Strawberry Elementary',
  'Strawberry Hill',
  'Strawberry Hill An Ext. Of Rogers International',
  'Strawberry Knoll Elementary',
  'Strawberry Mansion Hs',
  'Strawberry Park Elementary School',
  'Strawberry Point Elementary',
  'Strawbridge Elementary',
  'Strawbridge Elementary School',
  'Strawn School',
  'Strawtown Elementary School',
  'Strayer College',
  'Strayer Ms',
  'Strayhorn Elementary School',
  'Strayhorn High School',
  'Stream Charter',
  'Streams El Sch',
  'Streamwood High School',
  'Streator Twp High School',
  'Street Academy (alternative)',
  'Street El',
  'Streetsboro Elementary School',
  'Streetsboro High School',
  'Streetsboro Middle School',
  'Streiber Memorial School',
  'Stricker Elementary School',
  'Strickland Middle',
  'Stride Academy K-8 Charter School',
  'Stringer Attendance Center',
  'Stringfellow Elementary School',
  'Stringtown Elementary School',
  'Stringtown Es',
  'Stringtown Hs',
  'Stripling Elementary School',
  'Stripling Middle',
  'Strive (southern Transition Real-world And Independent Voca',
  'Strive Collegiate Academy',
  'Strive Prep - Excel',
  'Strive Prep - Federal',
  'Strive Prep - Gvr',
  'Strive Prep - Kepner',
  'Strive Prep - Lake',
  'Strive Prep - Montbello',
  'Strive Prep - Rise',
  'Strive Prep - Ruby Hill',
  'Strive Prep - Smart Academy',
  'Strive Prep - Sunnyside',
  'Strive Prep - Westwood',
  'Strive Secondary',
  'Strive Transition',
  'Strive Transition Program',
  'Strive-stratford',
  'Strobridge Elementary',
  'Strode Station Elementary School',
  'Strodes Mills El Sch',
  'Strom Thurmond Career And Technology Center',
  'Strom Thurmond High',
  'Stroman Middle',
  'Strong 21st Century Communications Magnet And Scsu Lab Scho',
  'Strong Elementary School',
  'Strong Foundations Charter School',
  'Strong High School',
  'Strong Vincent Hs',
  'Strong Vincent Ms',
  'Strongsville High School',
  'Strongsville Middle School',
  'Strother Es',
  'Strother Hs',
  'Stroud Hs',
  'Stroud Ms',
  'Stroudsburg Hs',
  'Stroudsburg Jhs',
  'Stroudsburg Ms',
  'Struble Elementary School',
  'Structural Alt Confinement School',
  'Structured Teaching - 12',
  'Strum Elementary',
  'Struthers Elementary School',
  'Struthers High School',
  'Struthers Middle School',
  'Struthers Preschool',
  'Stryker Elementary School',
  'Stryker High School',
  'Stuard El',
  'Stuart Academy',
  'Stuart Burns Elementary',
  'Stuart Career Tech H S',
  'Stuart Elementary',
  'Stuart Elementary School',
  'Stuart Es',
  'Stuart High',
  'Stuart High School',
  'Stuart Hobson Ms Capitol Hill Cluster',
  'Stuart Hs',
  'Stuart Learning Center',
  'Stuart M Townsend Elementary School',
  'Stuart M. Beville Middle',
  'Stuart Mesa Elementary',
  'Stuart Middle School',
  'Stuart Pepper Middle School',
  'Stuart Place El',
  'Stuart R Paddock School',
  'Stuart W Cramer High',
  'Stuarts Draft Elementary',
  'Stuarts Draft High',
  'Stuarts Draft Middle',
  'Stubblefield Alternative Academy',
  'Stubblefield Lrn Ctr',
  'Stubbs (frederick Douglass) Elementary School',
  'Stuchbery El',
  'Stuckey Center',
  'Stucky Middle School',
  'Studebaker Center',
  'Studebaker Elementary',
  'Studebaker Elementary School',
  'Student Alter Prog',
  'Student Alternative Ctr',
  'Student Athlete Headquarters',
  'Student Choice High School',
  'Student Leadership Academy',
  'Student Link',
  'Student Opportunity Center',
  'Student Reassignment Ctr',
  'Student Serv./special Programs',
  'Student Service Center',
  'Student Services',
  'Student Services School',
  'Student Services/ese',
  'Student Success Open Doors Academy',
  'Student Support Center',
  'Student Support Services',
  'Studio',
  'Studio 39',
  'Study Alternative High',
  'Study Elementary School',
  'Stukey Elementary School',
  'Stults Road El',
  'Sturgeon Bay High',
  'Sturgeon Cromer Elementary School',
  'Sturgeon Elem.',
  'Sturgeon High',
  'Sturgeon Middle',
  'Sturgis Brown High School - 01',
  'Sturgis Charter Public School',
  'Sturgis Elementary - 03',
  'Sturgis Elementary School',
  'Sturgis High School',
  'Sturgis Intermediate School - 06',
  'Sturgis Middle School',
  'Sturgis Schools Adult Education Center',
  'Sturgis Williams Middle School - 02',
  'Stuttgart High School',
  'Stuttgart Junior High School',
  'Stuyvesant High School',
  'Su Adelaida Vega',
  'Su Adolfo Garcia',
  'Su Agapito Lopez Flores',
  'Su Alejandrina Rios (su Sabana)',
  'Su Alfredo Dorrington',
  'Su Almirante Norte',
  'Su Ana Dalila Burgos Ortiz',
  'Su Ana Joaquina Ortiz Ortiz',
  'Su Andres Sandin Martinez',
  'Su Andres Soto Quinones',
  'Su Angelica Delgado (su America)',
  'Su Antonia Serrano Gonzalez',
  'Su Antonio Acarron Correa',
  'Su Antonio Romero Muniz',
  'Su Aristides Maisonave',
  'Su Asuncion Lugo',
  'Su Barrio Sabana Hoyos (nueva)',
  'Su Bayamoncito',
  'Su Bernaldo Mendez Jimenez',
  'Su Bonifacio Alvarado',
  'Su Botijas 1',
  'Su Cacique Majagua',
  'Su Carlos Conde Marin',
  'Su Carlos Zayas',
  'Su Carmelo Serrano Cubano',
  'Su Carmen Vignals Rosario',
  'Su Carmen Zenaida Vega (la Plata)',
  'Su Certenejas Ii',
  'Su Clara M. Aramburu',
  'Su Conchita Iguartua De Suarez (su Adams)',
  'Su Cruz Ortiz Stella',
  'Su David Antongiorgi Cordova',
  'Su David Colon Vega',
  'Su Demetrio Rivera',
  'Su Dr Arturo Morales Carrion',
  'Su Enrique Colon',
  'Su Enrique De Jesus Borras',
  'Su Epifanio Estrada',
  'Su Eugenio Maria De Hostos',
  'Su Eugenio Nazario Soto',
  'Su Federico Degetau',
  'Su Francisco Maria Quinones',
  'Su Francisco Serrano',
  'Su Francisco Vazquez Pueyo',
  'Su Francisco Vincenty',
  'Su Galo Rosado',
  'Su Gerardo Selles Sola',
  'Su Hatillo',
  'Su Helechal',
  'Su Higinio Figueroa Villegas',
  'Su Honorio Hernandez',
  'Su Jagual Adentro',
  'Su Jaime Castaner',
  'Su Jesus T Sanabria Cruz',
  'Su Joaquin Parrilla',
  'Su Joaquin Vazquez Cruz',
  'Su Jorge Lucas Valdivieso',
  'Su Jose A Vargas',
  'Su Jose C. Barbosa',
  'Su Jose Gonzalez Ginorio',
  'Su Jose R. Barreras',
  'Su Jose Toro Rios',
  'Su Josefina Sitiriche',
  'Su Juan Alemany Silva',
  'Su Juan B. Soto',
  'Su Juan Cancio Ortiz De La Renta',
  'Su Juan Cardona Rodriguez',
  'Su Juan D. Stubbe',
  'Su Julian Marrero',
  'Su Lauro Gonzalez Hijo',
  'Su Luciano Rios',
  'Su Luis Munoz Rivera',
  'Su Manuel Ortiz',
  'Su Manuel Ruiz Gandia',
  'Su Marcos Sanchez',
  'Su Maria C. Santiago',
  'Su Marta Lafontaine',
  'Su Matrullas',
  'Su Maximino A. Salas',
  'Su Mercedes Palma',
  'Su Nemesio R. Canales',
  'Su Nicolas Rodriguez',
  'Su Nueva Bo. Mediania',
  'Su Oscar Porrata Doria',
  'Su Pasto',
  'Su Pedro Diaz Fonseca',
  'Su Pedro Fernandez',
  'Su Pedro M. Dominicci',
  'Su Pedro Rivera Molina',
  'Su Playa',
  'Su Rafael Hernandez',
  'Su Rafael Zamot Cruz',
  'Su Ramon Alejandro Ayala',
  'Su Ramon E. Betances',
  'Su Rexford G. Tugwell',
  'Su Rogelio Rosado (su Playita)',
  'Su Rosa Maria Rosario De Leon',
  'Su Sabana Llana',
  'Su Sanamuertos',
  'Su Sandalio Marcano',
  'Su Santiago R. Palmer',
  'Su Silverio Garcia',
  'Su Tomas Carrion Maduro',
  'Su Toribio Rivera',
  'Su Urbana Salinas',
  'Su Vidal Serrano',
  'Su Zoilo Gracia',
  'Suamico Elementary',
  'Suan Dusit Rajabhat University',
  'Sublette Elem',
  'Sublette High',
  'Sublette K-12 Virtual Academy',
  'Sublette Middle',
  'Sublimity Elementary School',
  'Suburban Park Elementary',
  'Success Academy',
  'Success Academy - 39',
  'Success Academy 4-5',
  'Success Academy At Ghazvini Learning Center',
  'Success Academy Charter School - Bed Stuy 1',
  'Success Academy Charter School - Bensonhurst',
  'Success Academy Charter School - Bronx 4',
  'Success Academy Charter School - Rosedale',
  'Success Academy Charter School - Springfield Gardens',
  'Success Academy Charter School - Washington Heights',
  'Success Academy Charter School-bed Stuy 2',
  'Success Academy Charter School-bronx 1',
  'Success Academy Charter School-bronx 2',
  'Success Academy Charter School-bronx 3',
  'Success Academy Charter School-cobble Hill',
  'Success Academy Charter School-crown Heights',
  'Success Academy Charter School-fort Greene',
  'Success Academy Charter School-harlem 1',
  'Success Academy Charter School-harlem 2',
  'Success Academy Charter School-harlem 3',
  'Success Academy Charter School-harlem 4',
  'Success Academy Charter School-harlem 5',
  'Success Academy Charter School-harlem 6',
  "Success Academy Charter School-hell's Kitchen",
  'Success Academy Charter School-hudson Yards',
  'Success Academy Charter School-nyc 10',
  'Success Academy Charter School-nyc 13',
  'Success Academy Charter School-nyc 4',
  'Success Academy Charter School-nyc 8',
  'Success Academy Charter School-nyc 9',
  'Success Academy Charter School-prospect Heights',
  'Success Academy Charter School-union Square',
  'Success Academy Charter School-upper West',
  'Success Academy Charter School-williamsburg',
  'Success Academy Cs - Bergen Beach',
  'Success Academy Hs',
  'Success Academy Lewis',
  'Success Academy Perryville',
  'Success Academy Primary School',
  'Success Academy Tucson Adult',
  'Success Academy Yuma',
  'Success Belding',
  'Success Dsu',
  'Success Edmoremontabellavestaburg',
  'Success Elem.',
  'Success Escanaba',
  'Success Grand Rapids Alger Heights',
  'Success Grand Rapids Fuller Ave',
  'Success H S',
  'Success Howard Citytri County',
  'Success Institute Charter',
  'Success Kenowa Hills',
  'Success Lakeview',
  'Success Menominee',
  'Success Mile Academy',
  'Success One!',
  'Success Preparatory Academy',
  'Success Program',
  'Success St Louis',
  'Success Stantoncentral Montcalm',
  'Success Suu',
  'Success Three Rivers',
  'Success Virtual Learning Centers Of Michigan',
  'Successtech Academy School',
  'Sucesion Torres',
  'Sudan Academy of Sciences',
  'Sudan El',
  'Sudan H S',
  'Sudan University for Science and Technology',
  'Sudbrook Magnet Middle',
  'Sudbury Country School',
  'Sudderth El',
  'Sudduth Elementary School',
  'Suder Elementary School',
  'Suder Montessori Elem Magnet Schl',
  'Sudie L Williams El',
  'Sudlersville Elementary School',
  'Sudlersville Middle School',
  'Sudley Elementary',
  'Sudlow Intermediate',
  'Sue Buel Elementary',
  'Sue Cleveland Elementary',
  'Sue Creech El',
  'Sue Crouch Int Sch',
  'Sue Evelyn Rattan El',
  'Sue H Morrow Elementary School',
  'Sue Reynolds Elementary School',
  'Sue Sossaman Early Childhood Development Center',
  'Suella G. Ellis Elementary',
  'Suez Canal University',
  'Suffern Middle School',
  'Suffern Senior High School',
  'Suffield Elementary School',
  'Suffield High School',
  'Suffield Middle School',
  'Suffolk County Community College',
  'Suffolk County Sheriffs Office',
  'Suffolk Re-ed',
  'Suffolk University',
  'Sugar Bush Elementary',
  'Sugar Camp Elementary',
  'Sugar Creek Charter',
  'Sugar Creek Consolidated Elem Sch',
  'Sugar Creek Elem School',
  'Sugar Creek Elem.',
  'Sugar Creek Elementary',
  'Sugar Creek Elementary Sch',
  'Sugar Creek Elementary School',
  'Sugar Grove Academy',
  'Sugar Grove Elementary',
  'Sugar Grove Elementary School',
  'Sugar Hill Elementary',
  'Sugar Hill Elementary School',
  'Sugar Land Middle',
  'Sugar Loaf El',
  'Sugar Loaf Elementary',
  'Sugar Mill El',
  'Sugar Mill Elementary School',
  'Sugar Valley Rural Cs',
  'Sugar-salem High School',
  'Sugar-salem Junior High School',
  'Sugarbush Elementary School',
  'Sugarcreek El Sch',
  'Sugarland Elementary',
  'Sugarland Elementary School',
  'Sugarloaf Elementary',
  'Sugarloaf Mountain Juvenile Hall Program',
  'Sugarloaf School',
  'Sugarmill Elementary',
  'Sugartown El Sch',
  "Sugino Women's College",
  'Sugiyama Jogakuen University',
  'Suisun Elementary',
  'Suisun Valley Elementary',
  'Suitland Elementary',
  'Suitland High',
  'Sukhothai Thammathirat Open University',
  'Sul Ross El',
  'Sul Ross State University',
  'Suleyman Demirel University',
  'Sulligent School',
  'Sullivan Boces',
  'Sullivan Central High School',
  'Sullivan Co El Sch',
  'Sullivan Co Jshs',
  'Sullivan County Community College',
  'Sullivan East High School',
  'Sullivan El',
  'Sullivan Elem',
  'Sullivan Elem School',
  'Sullivan Elem.',
  'Sullivan Elementary',
  'Sullivan Elementary School',
  'Sullivan Gardens K-8',
  'Sullivan High School',
  'Sullivan James J Sch',
  'Sullivan Middle',
  'Sullivan Middle School',
  'Sullivan North High School',
  'Sullivan Primary',
  'Sullivan South High School',
  'Sullivan Sr. High',
  'Sullivan Village Es',
  'Sullivan West Elementary School',
  'Sullivan West High School At Lake Huntington',
  "Sullivan's Island Elementary",
  'Sullivant Avenue Community School',
  'Sullivant Elementary School',
  'Sully Buttes High School - 07',
  'Sully Buttes Jr. High - 06',
  'Sully Elementary',
  'Sulphur Bluff Isd',
  'Sulphur Es',
  'Sulphur High School',
  'Sulphur Hs',
  'Sulphur Intermediate Es',
  'Sulphur Ms',
  'Sulphur Rock Magnet School',
  'Sulphur Springs Community Elementary',
  'Sulphur Springs El',
  'Sulphur Springs Elementary',
  'Sulphur Springs H S',
  'Sulphur Springs K-8 School',
  'Sulphur Springs Middle',
  'Sulphur Springs Special Education Consortia',
  'Sultan Abdul Halim Muadzam Shah Polytechnic',
  'Sultan Ahmad Shah Islamic College',
  'Sultan Azlan Shah Polytechnic',
  'Sultan Elementary School',
  'Sultan Haji Ahmad Shah Polytechnic',
  'Sultan Idris Shah Polytechnic',
  'Sultan Ismail Petra International Islamic College',
  'Sultan Middle School',
  'Sultan Mizan Zainal Abidin Polytechnic',
  'Sultan Qaboos University',
  'Sultan Salahuddin Abdul Aziz Shah Polytechnic',
  'Sultan Senior High School',
  'Sultana Elementary',
  'Sultana High',
  'Sultanah Bahiyah Polytechnic',
  'Sumac Elementary',
  'Sumas Elementary',
  'Sumgait State University',
  'Sumiton Elementary School',
  'Sumiton Middle School',
  'Summer Creek H S',
  'Summer Creek Middle',
  'Summer Grove Elementary School',
  'Summer Hill Preschool Center',
  'Summer Street',
  'Summerdale Early Childhood Center',
  'Summerdale Elementary',
  'Summerdale School',
  'Summerfield Charter Academy',
  'Summerfield Crossings Elementary School',
  'Summerfield Elementary',
  'Summerfield Elementary School',
  'Summerfield High School',
  'Summerfield Juniorsenior High School',
  'Summerfiled Elementary School',
  'Summerland Elementary',
  'Summerour Middle School',
  'Summers County High School',
  'Summers Elementary School',
  'Summers Middle School',
  'Summerside Elementary School',
  'Summersill Elementary',
  'Summersville Elem.',
  'Summersville Elementary School',
  'Summersville Grade School',
  'Summersville High',
  'Summersville Middle School',
  'Summerton Early Childhood Center',
  'Summertown Elementary',
  'Summertown High School',
  'Summerville Advantage Academy',
  'Summerville Elementary',
  'Summerville High',
  'Summerville Middle School',
  'Summerwind Elementary',
  'Summerwind School Of Math And Science',
  'Summerwood El',
  'Summit',
  'Summit Academy',
  'Summit Academy - Bluffdale',
  'Summit Academy - Draper Campus',
  'Summit Academy - Independence',
  'Summit Academy - Toledo',
  'Summit Academy Akron Elementary School',
  'Summit Academy Akron Middle School',
  'Summit Academy Alternative Learnerswarren Middle & Secondary',
  'Summit Academy Charter School',
  'Summit Academy Community School - Cincinnati',
  'Summit Academy Community School - Dayton',
  'Summit Academy Community School - Painesville',
  'Summit Academy Community School Alternative Learners -xenia',
  'Summit Academy Community School Alternative Learners-lorain',
  'Summit Academy Community School For Alternative Learn-canton',
  'Summit Academy Community School-columbus',
  'Summit Academy Community School-parma',
  'Summit Academy Community School-toledo',
  'Summit Academy Community School-warren',
  'Summit Academy High School',
  'Summit Academy Middle School - Columbus',
  'Summit Academy North Elementary School',
  'Summit Academy North High School',
  'Summit Academy North Middle School',
  'Summit Academy School - Lorain',
  'Summit Academy Secondary - Akron',
  'Summit Academy Secondary - Canton',
  'Summit Academy Secondary - Youngstown',
  'Summit Academy Secondary School - Middletown',
  'Summit Academy Toledo Learning Center',
  'Summit Academy Transition High School Dayton',
  'Summit Academy Transition High School-cincinnati',
  'Summit Academy Transition High School-columbus',
  'Summit Academy-youngstown',
  'Summit Acdy Comm Schl For Alternative Learners Of Middletown',
  'Summit Charter',
  'Summit Charter Academy',
  'Summit Community College High School',
  'Summit Cove Elementary School',
  'Summit Drive Elementary',
  'Summit El Sch',
  'Summit Elementary',
  'Summit Elementary - 02',
  'Summit Elementary School',
  'Summit Environmental School',
  'Summit Hall Elementary',
  'Summit High',
  'Summit High (continuation)',
  'Summit High School',
  'Summit High School - 01',
  'Summit Hill Elementary',
  'Summit Hill Junior High School',
  'Summit Hills Elementary School',
  'Summit Intermediate',
  'Summit Intermediate School',
  'Summit Lakes Middle',
  'Summit Lane School',
  'Summit Leadership Academy-high Desert',
  'Summit Learning Center',
  'Summit Learning Charter',
  'Summit Learning Program',
  'Summit Middle Charter School',
  'Summit Middle School',
  'Summit Ms',
  'Summit Oaks-day',
  'Summit Oaks-residential',
  'Summit Park Elementary',
  'Summit Park Elementary School',
  'Summit Parkway Middle',
  'Summit Pointe Elem.',
  'Summit Preparatory Charter',
  'Summit Preparatory Charter High',
  'Summit Public School K2',
  'Summit Public School: Atlas',
  'Summit Public School: Denali',
  'Summit Public School: Olympus',
  'Summit Public School: Rainier',
  'Summit Public School: Shasta',
  'Summit Public School: Sierra',
  'Summit Public School: Tahoma',
  'Summit Public School: Tamalpais',
  'Summit Ridge Middle School',
  'Summit Road Elementary',
  'Summit School',
  'Summit Secondary',
  'Summit Station Preschool',
  'Summit Street School',
  'Summit Trail Middle School',
  'Summit University of Louisiana',
  'Summit Valley School',
  'Summit View',
  'Summit View Academy',
  'Summit View Elementary',
  'Summit View Elementary School',
  'Summit View High School',
  'Summit View Independent Study',
  'Summit View J/shs',
  'Summit View Middle School',
  'Summitt El',
  'Summitview El Sch',
  'Summitview Elementary',
  'Summitville School',
  'Sumner Academy Of Arts & Science',
  'Sumner Attendance Center',
  'Sumner Avenue',
  'Sumner County Middle College High School',
  'Sumner County Middle Technical College High At Portland',
  'Sumner Elem Math & Sci Comm Acad',
  'Sumner Elementary',
  'Sumner G. Whittier School',
  'Sumner High',
  'Sumner High School',
  'Sumner Hill Jr Hi School',
  'Sumner Memorial High School',
  'Sumner Middle School',
  'Sumner Special Services',
  'Sumner-fredericksburg Hs',
  'Sumner-fredericksburg Middle School',
  'Sumpter Elementary School',
  'Sumrall Elementary School',
  'Sumrall High School',
  'Sumrall Middle School',
  'Sumter Career And Technology Center',
  'Sumter Central High School',
  'Sumter County Adult Center',
  'Sumter County Elementary School',
  'Sumter County Intermediate School',
  'Sumter County Middle School',
  'Sumter County Primary School',
  'Sumter County Virtual Instruction Program',
  'Sumter High',
  'Sumter Prep Academy',
  'Sumter Professional Center & Early Learning Academy',
  'Sumter Virtual Franchise',
  'Sumter Youth Development Campus',
  'Sumy National Agrarian University',
  'Sumy State University',
  'Sun',
  "Sun 'n Lake Elementary School",
  'Sun Area Technical Institute',
  'Sun Blaze Elementary',
  'Sun Canyon School',
  'Sun Center',
  'Sun City Elementary School',
  'Sun Empire Elementary',
  'Sun Path Elementary',
  'Sun Prairie Four Kids',
  'Sun Prairie High',
  'Sun Ridge Middle',
  'Sun River Middle School',
  'Sun Terrace Elementary',
  'Sun Transition',
  'Sun Valley Charter School',
  'Sun Valley El',
  'Sun Valley Elementary',
  'Sun Valley Elementary School',
  'Sun Valley High',
  'Sun Valley High School',
  'Sun Valley Hs',
  'Sun Valley Magnet',
  'Sun Valley Middle',
  'Sun View Elementary',
  'Sun Yat-Sen University of Medical Sciences',
  'Sunapee Central School',
  'Sunapee Middle High School',
  'Sunapee Sr. High School',
  'Sunbeam',
  'Sunbridge Schools',
  'Sunbright School',
  'Sunburst 7 - 8',
  'Sunburst Elementary',
  'Sunburst High School',
  'Sunburst School',
  'Sunchon National University',
  'Suncoast Community High School',
  'Suncoast Elementary School',
  'Suncoast High Adult Education Center',
  'Suncoast Polytechnical High School',
  'Suncoast School For Innovative Studies',
  'Suncoast Technical College',
  'Suncoast Technical College North Port',
  'Suncoast Technical Educational Center - Extension Campus',
  'Suncoast Technical Educational Center - Main Campus',
  'Suncrest Elementary School',
  'Suncrest Middle School',
  'Suncrest School',
  'Sundale Colony High School',
  'Sundale Colony School',
  'Sundale Elementary',
  'Sundance Elementary',
  'Sundance Elementary School',
  'Sundance Secondary School',
  'Sunderland Elementary',
  'Sunderland Elementary School',
  'Sundown El',
  'Sundown Elementary',
  'Sundown H S',
  'Sundown J H',
  'Sundown Lane El',
  'Suned High School',
  'Suned High School Of North Broward',
  'Sunflower Elem',
  'Sunflower Elementary',
  'Sunflower Elementary School',
  'Sunflower School',
  'Sung Kyun Kwan University',
  'Sungkyul University',
  "Sungshin Women's University",
  'Sunkist Elementary',
  'Sunlake Academy Of Math And Sciences',
  'Sunlake High Adult Education',
  'Sunlake High School',
  'Sunland Elementary',
  'Sunland Elementary School',
  'Sunland Park Academy',
  'Sunland Park Elementary',
  'Sunman Elementary School',
  'Sunman-dearborn Middle School',
  'Sunmoon University',
  'Sunmount Developmental Center',
  'Sunny Brae Avenue Elementary',
  'Sunny Brae Middle',
  'Sunny Heights Elementary School',
  'Sunny Hill Elem School',
  'Sunny Hills Elementary',
  'Sunny Hills High',
  'Sunny Pointe Elem.',
  'Sunny Sands Elementary',
  'Sunny Slope Elem School',
  'Sunny View Elementary School',
  'Sunny Wolf Charter School',
  'Sunnybrae Elementary',
  'Sunnybrae Elementary School',
  'Sunnycrest Elementary School',
  'Sunnydale Elem School',
  'Sunnydale Elementary',
  'Sunnyland Elementary School',
  'Sunnymead Elementary',
  'Sunnymead Elementary School',
  'Sunnymead Middle',
  'Sunnymeadows Elementary',
  'Sunnymede Elementary School',
  'Sunnyside Elem',
  'Sunnyside Elementary',
  'Sunnyside Elementary School',
  'Sunnyside Environmental School',
  'Sunnyside High',
  'Sunnyside High School',
  'Sunnyside Intermediate School',
  'Sunnyside Online Success Academy',
  'Sunnyside School',
  'Sunnyslope Elementary',
  'Sunnyslope Elementary School',
  'Sunnyslope High School',
  'Sunnyvale El',
  'Sunnyvale H S',
  'Sunnyvale Middle',
  'Sunnyview Primary',
  'Sunol Glen Elementary',
  'Sunquam Elementary School',
  'Sunray El',
  'Sunray Elementary School',
  'Sunray H S',
  'Sunray Middle',
  'Sunridge Charter',
  'Sunridge Elementary',
  'Sunridge Elementary School',
  'Sunridge Learning Center',
  'Sunridge Middle',
  'Sunridge Middle School',
  'Sunrise (special Education)',
  'Sunrise - Mcmillan El',
  'Sunrise Academy',
  'Sunrise Acres Elementary School',
  "Sunrise Children's Services - Morehead",
  'Sunrise Drive Elementary School',
  'Sunrise Early Learning Center',
  'Sunrise El',
  'Sunrise El Sch',
  'Sunrise Elem.',
  'Sunrise Elementary',
  'Sunrise Elementary School',
  'Sunrise High (continuation)',
  'Sunrise High School',
  'Sunrise Intermediate (holmes)',
  'Sunrise Middle',
  'Sunrise Middle School',
  'Sunrise Mountain High School',
  'Sunrise Park Area Learning Center',
  'Sunrise Park Elementary School',
  'Sunrise Park Middle',
  'Sunrise Point Elementary',
  'Sunrise Pre-school',
  'Sunrise Preschool',
  'Sunrise Primary (chamberlain)',
  'Sunrise Ridge Intermediate',
  'Sunrise River Elementary',
  'Sunrise School',
  'Sunrise Valley Elementary',
  'Sunset',
  'Sunset Acres Elementary School',
  'Sunset Avenue Elementary',
  'Sunset Beach Elementary School',
  'Sunset Canyon School',
  'Sunset Colony Elementary - 04',
  'Sunset Colony High School - 06',
  'Sunset El',
  'Sunset Elem',
  'Sunset Elementary',
  'Sunset Elementary School',
  'Sunset Es',
  'Sunset H S',
  'Sunset Heights Elementary School',
  'Sunset Heights School',
  'Sunset High',
  'Sunset High (continuation)',
  'Sunset High School',
  'Sunset Hill Elem',
  'Sunset Hill Elementary',
  'Sunset Hills Elementary',
  'Sunset Hills Elementary School',
  'Sunset Jr High',
  'Sunset Lake Elementary School',
  'Sunset Lakes Elementary School',
  'Sunset Lane Elementary',
  'Sunset Middle School',
  'Sunset Palms Elementary School',
  'Sunset Park Avenue Elementary School',
  'Sunset Park Center For Accelerated Studies',
  'Sunset Park Elementary',
  'Sunset Park Elementary School',
  'Sunset Park High School',
  'Sunset Park Prep',
  'Sunset Primary',
  'Sunset Primary School',
  'Sunset Ranch Elementary',
  'Sunset Ridge Elem School',
  'Sunset Ridge Elementary',
  'Sunset Ridge Elementary School',
  'Sunset Ridge Middle',
  'Sunset Ridge Middle School',
  'Sunset School',
  'Sunset Terrace Elementary',
  'Sunset Valley El',
  'Sunset Valley El Sch',
  'Sunset Valley Elem.',
  'Sunset View Elementary',
  'Sunset View Elementary School',
  'Sunset View School',
  'Sunset Vista',
  'Sunshine Center',
  'Sunshine Elem.',
  'Sunshine Elementary Charter School',
  'Sunshine Elementary School',
  'Sunshine Gardens Elementary',
  'Sunshine High Charter',
  'Sunshine School',
  'Sunshine Special Education',
  'Suntex Elementary School',
  'Suntree Elementary School',
  'Sunview Elementary School',
  'Sunway University College',
  'Sup Voc Manuel Mediavilla',
  "Superintendent's Region 8 Gov's Health/science Academy",
  'Superior 7-8',
  'Superior Academy',
  'Superior Berwind',
  'Superior Catalina Morales Flores',
  'Superior Central School',
  'Superior Community Preschool',
  'Superior Elementary',
  'Superior Elementary School',
  'Superior High',
  'Superior High School',
  'Superior Hills Elementary School',
  'Superior Intermediate School',
  'Superior Isidro A Sanchez',
  'Superior Jardines De Ponce',
  'Superior Jr/sr High School',
  'Superior Juan Serrales',
  'Superior Junior High School',
  'Superior Junior/senior High School',
  'Superior Middle',
  'Superior Ocupacional Y Tecnica De Yauco',
  'Superior Street Elementary',
  'Superior Urbana',
  'Superior Urbana Nueva',
  'Superior Vocacional',
  'Superior Vocacional (antonio Luchetti)',
  'Superior Vocacional Nueva',
  'Superstition Mountain Elementary School',
  'Superstition Mountain On-line School',
  'Superstition Springs Elementary',
  'Supply Elementary',
  'Support School',
  'Suquamish Elementary School',
  'Sur University College',
  'Suranaree University of Technology',
  'Surf City Elementary',
  'Surf City Middle',
  'Surfside Elementary School',
  'Surfside Middle School',
  'Surgoinsville Elementary',
  'Surgoinsville Middle School',
  'Surgut State University',
  'Suring Elementary',
  'Suring High',
  'Surline Elementary School',
  'Surline Middle School',
  'Surprise Creek School',
  'Surprise Elementary School',
  'Surprise Lake Middle School',
  'Surprise Valley Elementary',
  'Surprise Valley High',
  'Surrarrer Elementary School',
  'Surrattsville High',
  'Surrey Elementary School',
  'Surrey High School',
  'Surrey Hills Es',
  'Surry Central High',
  'Surry Community College',
  'Surry County High',
  'Surry Early College',
  'Surry Elementary',
  'Surry Elementary School',
  'Surry Village Charter School',
  'Surugadai University',
  'Susan B Anthony Middle School',
  'Susan B English School',
  'Susan B. Anthony Elementary',
  'Susan B. Anthony Elementary - 66',
  'Susan B. Anthony Middle School',
  'Susan E Wagner High School',
  'Susan E Wiley School',
  'Susan H. Nelson',
  'Susan La Vorgna Elementary',
  'Susan Lindgren Elementary',
  'Susan Miller Dorsey Senior High',
  'Susan Moore Elementary School',
  'Susan Moore High School',
  'Susana Rivera-nueva (elemental Bo Llanos)',
  'Susanna Dickinson El',
  'Susick Elementary School',
  'Susie B West Elementary School',
  'Susie C Altmayer Elementary',
  'Susie Dasher',
  'Susie E. Tolbert Elementary School',
  'Susie Fuentes El',
  'Susie King Taylor Community School',
  'Susie R. Marmon Elementary',
  'Susitna Elementary',
  'Susitna Valley High',
  'Susq-cyber Cs',
  'Susquehanna Community El Sch',
  'Susquehanna Community Jshs',
  'Susquehanna County Ctc',
  'Susquehanna Twp Hs',
  'Susquehanna Twp Ms',
  'Susquehanna University',
  'Susquehanna Valley Senior High School',
  'Susquehannock Hs',
  'Susquenita El Sch',
  'Susquenita Hs',
  'Susquenita Ms',
  'Sussex Academy Of Arts And Sciences',
  'Sussex Avenue School',
  'Sussex Avenue School Burnet Street School',
  'Sussex Central Elementary',
  'Sussex Central High',
  'Sussex Central High School',
  'Sussex Central Middle',
  'Sussex Consortium',
  'Sussex County Community College',
  'Sussex County Technical School',
  'Sussex County Technology Charter School',
  'Sussex Elementary',
  'Sussex Middle School',
  'Sussex Orthopedic Program',
  'Sussex Technical High School',
  'Sussman Middle',
  'Sustainability Academy At Lawrence Barnes',
  'Sustainable Futures Pcs',
  'Sutherland Elem School',
  'Sutherland Elementary',
  'Sutherland Elementary School',
  'Sutherland High School',
  'Sutherlin High School',
  'Sutherlin Middle School',
  'Sutherlin Valley Online Academy',
  'Sutro Elementary',
  'Sutro Elementary School',
  'Sutter County Special Education',
  'Sutter Creek Elementary',
  'Sutter Elementary',
  'Sutter High',
  'Sutter Middle',
  'Sutter Park Elementary School',
  'Sutter Peak Charter Academy',
  'Sutters Mill',
  'Sutterville Elementary',
  'Sutton Central Elementary School',
  'Sutton Early Learning',
  'Sutton El',
  'Sutton Elementary',
  'Sutton Elementary School',
  'Sutton High School',
  'Sutton Middle School',
  'Sutton Secondary School',
  'Sutton Village School',
  'Suttons Bay Elementary School',
  'Suttons Bay Senior High School',
  'Suttons Bay Virtual School',
  'Suva Elementary',
  'Suva Intermediate',
  'Suwanee Elementary School',
  'Suwannee Elementary School',
  'Suwannee High School',
  'Suwannee Intermediate School',
  'Suwannee Middle School',
  'Suwannee Primary School',
  'Suwannee Virtual Franchise',
  'Suwannee Virtual Instruction Program',
  'Suwannee Virtual School',
  'Suwon Catholic University',
  'Suwon University',
  'Suzanna Dickinson El',
  'Suzanne M Smith Elementary School',
  'Suzanne M. Henseler Quidnesset',
  'Suzanne Middle',
  'Suzhou University',
  'Suzuka International University',
  'Suzuka University of Medical Science',
  'Sven Lokrantz Special Education Center',
  'Svey Reong University',
  'Svi 1418 Re-engagement Program',
  'Svsd Open Doors',
  'Svusd Virtual Academy',
  'Sw Aeronautics Mathematics And Science Academy',
  'Sw Alabama Regional School Deafblind',
  'Sw Fl Addiction Services-the Vince Smith Center',
  'Sw Metro Alc',
  'Sw Metro Ccj',
  'Sw Metro Day Treatment',
  'Sw Metro Independent Study',
  'Sw Metro Jaf',
  'Sw Metro Level 4 Programs',
  'Sw Metro Online Learning',
  'Sw Metro Seat Based',
  'Sw Shakopee Special Ed',
  'Swain County East Elementary',
  'Swain County High School',
  'Swain County Middle School',
  'Swain County West Elementary',
  'Swain Elementary School',
  'Swainsboro Elementary School',
  'Swainsboro High School',
  'Swainsboro Middle School',
  'Swainsboro Primary School',
  'Swallow Elementary',
  'Swallow/union School',
  'Swallows Charter Academy',
  'Swallows Charter Academy High School',
  'Swami Ramanand Teerth Marathwada University',
  'Swampscott High',
  'Swampscott Middle',
  'Swan Hillman Elem School',
  'Swan Meadow School',
  'Swan River 7-8',
  'Swan River Montessori Charter Sch',
  'Swan River School',
  'Swan Valley 7-8',
  'Swan Valley Adult And Alternative Education',
  'Swan Valley Elementary School',
  'Swan Valley High School',
  'Swan Valley Middle School',
  'Swan Valley School',
  'Swaney Elem',
  'Swann Middle School',
  'Swans Creek Elementary',
  'Swans Island Elementary School',
  'Swansboro Elementary',
  'Swansboro High',
  'Swansboro Middle',
  'Swansea Elementary School',
  'Swansea High',
  'Swansea High Freshman Academy',
  'Swansea Metropolitan University',
  'Swansfield Elementary',
  'Swanson Elementary',
  'Swanson Elementary School',
  'Swanson Middle',
  'Swanson Primary Center',
  'Swanson Road Intermediate School',
  'Swanton Early Education Center',
  'Swanton Elementary School',
  'Swanton High School',
  'Swanton Middle School',
  'Swanton Schools',
  'Swanville Elementary',
  'Swanville Secondary',
  'Sward Elem School',
  'Swarthmore College',
  'Swarthmore-rutledge Sch',
  'Swartz Creek Academy',
  'Swartz Creek High School',
  'Swartz Creek Middle School',
  'Swartz Lower Elementary School',
  'Swartz Upper Elementary School',
  'Swasey Central School',
  'Swatara Ms',
  'Swayze Elementary School',
  'Swayzee Elementary School',
  'Swede Preschool Academy',
  'Swedeborg Elem.',
  'Swedish School of Economics and Business Administration Finland',
  'Swedish University of Agricultural Sciences',
  'Sweeney Elementary',
  'Sweeny El',
  'Sweeny Elementary',
  'Sweeny H S',
  'Sweeny J H',
  'Sweet Apple Elementary School',
  'Sweet Briar College',
  'Sweet Briar School',
  'Sweet Grass Co High Schl',
  'Sweet Home Charter School',
  'Sweet Home El',
  'Sweet Home High School',
  'Sweet Home Junior High School',
  'Sweet Home Middle School',
  'Sweet Home Senior High School',
  'Sweet Montour Elementary School',
  'Sweet Springs Elem.',
  'Sweet Springs High',
  'Sweet Water High School',
  'Sweetbriar Elementary School',
  'Sweetser Elementary School',
  'Sweetwater Accelerated H S',
  'Sweetwater Community Day',
  'Sweetwater Community School',
  'Sweetwater Elementary',
  'Sweetwater Elementary School',
  'Sweetwater Es',
  'Sweetwater H S',
  'Sweetwater High',
  'Sweetwater High School',
  'Sweetwater Hs',
  'Sweetwater Int',
  'Sweetwater Jr High School',
  'Sweetwater Middle',
  'Sweetwater Middle School',
  'Sweetwater Primary School',
  'Sweetwater School',
  'Sweetwater Secondary',
  'Sweetwater Springs Elementary',
  'Swegle Elementary School',
  'Swenke El',
  'Swenson Arts & Technology Hs',
  'Swenson Early Childhood Education Center',
  'Swift Creek Elementary',
  'Swift Creek High School',
  'Swift Creek Middle',
  'Swift Creek Middle School',
  'Swift El',
  'Swift Elem Specialty School',
  'Swift Elementary School',
  'Swift Middle School',
  'Swift Ms',
  'Swift River',
  'Swift River Elementary',
  'Swifton Middle School',
  'Swiftwater El Ctr',
  'Swiftwater Interm Sch',
  'Swiftwater Learning Center',
  'Swigert International School',
  'Swimming Pen Creek Elementary School',
  'Swimming River School',
  'Swinburne University of Technology',
  'Swinburne University of Technology Sarawak Campus',
  'Swink Elementary School',
  'Swink Junior-senior High School',
  'Swink Public School',
  'Swint Elementary School',
  'Swiss Business School Zurich (SBS)',
  'Swiss German University',
  'Swiss Hills Career Center',
  'Swiss Management Center',
  'Swiss Memorial Elementary',
  'Switlik Elementary School',
  'Switzer Elementary School',
  'Switzerland Co Elementary School',
  'Switzerland Co Middle School',
  'Switzerland Co Senior High School',
  'Switzerland Point Middle School',
  'Swords Creek Elementary',
  'Sybil N. Crookham Elementary',
  'Sycamore Academy Of Science And Cultural Arts',
  'Sycamore Avenue Elementary School',
  'Sycamore Canyon',
  'Sycamore Canyon Elementary',
  'Sycamore Creek Elementary',
  'Sycamore Creek Elementary School',
  'Sycamore Drive Early Childhood Learning Center',
  'Sycamore El',
  'Sycamore Elementary',
  'Sycamore Elementary School',
  'Sycamore High School',
  'Sycamore Hills Elem.',
  'Sycamore Hills Elementary',
  'Sycamore Junior High',
  'Sycamore Junior High School',
  'Sycamore Lane Elementary',
  'Sycamore Middle',
  'Sycamore Middle School',
  'Sycamore Park Elementary',
  'Sycamore Ridge',
  'Sycamore Rocks Elementary',
  'Sycamore School',
  'Sycamore Springs El',
  'Sycamore Springs Middle',
  'Sycamore Trails Elementary School',
  'Sycamore Valley Academy',
  'Sycamore Valley Elementary',
  'Sycolin Creek Elementary',
  'Sydnor Jennings Elementary',
  'Sykes Elementary School',
  'Sykesville El Sch',
  'Sykesville Middle',
  'Syktyvkar State University',
  'Sylacauga High School',
  'Sylhet Agricultural University',
  'Sylhet Engineering College',
  'Sylhet International University',
  'Sylmar Biotech Health Academy',
  'Sylmar Charter High',
  'Sylmar Elementary',
  'Sylmar Leadership Academy',
  'Sylvan Avenue Elementary School',
  'Sylvan Elementary',
  'Sylvan Elementary School',
  'Sylvan Heights Science Cs',
  'Sylvan Hills Elementary School',
  'Sylvan Hills High School',
  'Sylvan Hills Middle School',
  'Sylvan Middle',
  'Sylvan Park Elementary',
  'Sylvan-lucas Unified Jr. Sr',
  'Sylvandale Middle',
  'Sylvania Arbor Hills Junior High School',
  'Sylvania Heights Elementary School',
  'Sylvania Mccord Junior High School',
  'Sylvania Northview High School',
  'Sylvania School',
  'Sylvania Southview High School',
  'Sylvania Timberstone Junior High School',
  'Sylvanie Williams College Prep',
  'Sylvester',
  'Sylvester Elementary School',
  'Sylvester Middle School',
  'Sylvia Cassell Elementary',
  'Sylvia Enarson Elementary School',
  'Sylvia Rosenauer Elementary School',
  'Sylvio J Gilbert School',
  'Symbiosis International University',
  'Symington Elem.',
  'Symmes Elementary School',
  'Symmes Valley Elementary School',
  'Symmes Valley High School',
  'Symonds Elementary School',
  'Symsonia Elementary School',
  'Synergy Charter Academy',
  'Synergy Kinetic Academy',
  'Synergy Public School',
  'Synergy Quantum Academy',
  'Syosset Senior High School',
  'Syracuse Academy Of Science Charter School',
  'Syracuse Arts Academy - Antelope',
  'Syracuse Arts Academy - North',
  'Syracuse Elem',
  'Syracuse Elementary School',
  'Syracuse High',
  'Syracuse Jr High',
  'Syracuse Latin School',
  'Syracuse School',
  'Syracuse University',
  'Syrian International Private University for Science & Technology',
  'Syrian Virtual University',
  'Syring Elementary School',
  'Syringa Elementary School',
  'Syringa Middle School',
  'Syringa Mountain Charter School',
  'System Phoenix',
  'Szent István University',
  'Szetela Early Childhood Center',
  'Séchenyi István University',
  'Södertörn University College',
  'T A Dugger Junior High School',
  'T A Howard Middle',
  'T A Lowery Elementary School',
  'T A Sims El',
  'T C Cherry Elementary School',
  'T C HARRIS SCHOOL AT IDTC LAF',
  'T C Henderson',
  'T C Knapp Elementary School',
  'T E Baxter El',
  'T E Culbertson Elem School',
  'T F Birmingham El',
  'T F Riggs High School - 01',
  'T G Barr School',
  'T G Mccord El',
  'T G Terry El',
  'T H Mcdonald J H',
  'T H Rogers School',
  'T J Austin El',
  'T J Connor Elementary School',
  'T J Harris Elementary',
  'T J Loftiss Ii Regional Youth Detention Center',
  'T L Pink El',
  'T Lawson Early Childhood Education Center',
  'T Or C Elementary',
  'T Or C Middle',
  'T Price El',
  'T R Simmons Elementary School',
  'T R U C E Learning Ctr',
  'T Roosevelt Elem School',
  'T S Cooper Elementary',
  'T Sanchez El / H Ochoa El',
  'T W Browne Middle',
  'T W Kelly Dirigo Middle School',
  'T W Ogg El',
  'T Wingate Andrews High',
  "T'iis Nazbas Community School",
  "T'iis Ts'ozi Bi'olta'",
  'T. Baldwin Demarest Elementary School',
  'T. Benton Gayle Middle',
  'T. C. Martin Elementary School',
  'T. Clay Wood Elementary',
  'T. Dale Hancock Elementary School',
  'T. Dewitt Taylor Middle-high School',
  'T. E. Harrington Middle School',
  'T. E. Mabry Middle',
  'T. H. Watkins Elementary School',
  'T. J. Elder Middle School',
  'T. L. Hanna High',
  'T. L. Waggoner Elementary',
  'T. L. Whitehead Elementary',
  'T. R. Jackson Prek Center',
  'T. R. Smedberg Middle',
  'T. Ryland Sanford Elementary',
  'T. S. Cooley Elementary Magnet School',
  'T. S. Hill Middle',
  'T. S. Macquiddy Elementary',
  'T. W. Hunter Middle School',
  'T.I.E.S.',
  'T.O.P ACADEMY',
  'T.R.O.Y. CENTER',
  'T.c. Miller Elementary For Innovation',
  'T.c. Williams High',
  'T.c.p. World Academy',
  'T.g. Scott Elementary School',
  'T.h. Bell Jr High',
  'T.h. Harris Middle School',
  'T.h.e Leadership Academy',
  'T.l. Rodes Elementary School',
  'T.l. Weston Middle School',
  'T.r. Paul Academy Of Arts & Knowledge',
  'T.r.u.t.h. Preparatory Academy Char',
  'T2 Honors Academy',
  'TABERNACLE BAPTIST ACADEMY',
  'TABERNACLE BAPTIST SCHOOL',
  'TABERNACLE CHRISTIAN ACADEMY',
  'TABERNACLE CHRISTIAN ACADEMY & REVELATOR CHILDCARE',
  'TABERNACLE CHRISTIAN SCHOOL',
  'TABERNACLE CHRISTIAN SCHOOLS, INC.',
  'TABERNACLE OF DAVID CHRISTIAN ACADEMY',
  'TABOR ACADEMY',
  'TACOMA BAPTIST SCHOOLS',
  'TACOMA CHRISTIAN ACADEMY',
  'TACOMA WALDORF SCHOOL',
  'TAG HIGH SCHOOL',
  'TAHOE EXPEDITION ACADEMY',
  'TAILGRASS SUDBURY SCHOOL',
  'TAKOMA ACADEMY',
  'TAKOMA ACADEMY PREPARATORY SCHOOL',
  'TALCOTT MOUNTAIN ACADEMY OF SCIENCE MATHEMATICS',
  'TALENT EDUCATION & ART CHRISTIAN ACADEMY (TEA)',
  'TALK INSTITUTE & SCHOOL',
  'TALL OAKS MONTESSORI SCHOOL',
  'TALLAHASSEE ADVENTIST CHRISTIAN ACADEMY',
  'TALLAVANA CHRISTIAN SCHOOL',
  'TALLMADGE KIDDIE KOLLEGE / FACT ACADEMY',
  'TALLULAH ACADEMY',
  'TALLULAH FALLS SCHOOL',
  'TALMUD TORAH BAIS AVROHOM',
  'TALMUD TORAH BAIS YECHIEL-NITRA',
  'TALMUD TORAH DARCHEI AVOSEINU',
  'TALMUD TORAH DRABINU JOEL',
  'TALMUD TORAH IMREI CHAIM',
  'TALMUD TORAH MEORHACHAIM VIZNITZ',
  'TALMUD TORAH OF BOBOV 45',
  'TALMUD TORAH OF KASHO',
  'TALMUD TORAH OF LAKEWOOD',
  'TALMUD TORAH OF ST PAUL',
  'TALMUD TORAH OHEL YOCHANAN SCHOOL',
  'TALMUD TORAH TOLDOS HILLEL-KRASNA',
  'TALMUD TORAH TZEMACH TZADIK VIZNITZ',
  'TALMUD TORAH ZECHER YOCHANAN',
  'TALMUDICAL ACADEMY-BALTIMORE',
  'TALMUDICAL INSTITUTE OF UPSTATE NY',
  'TALMUDICAL YESHIVA OF PHILADELPHIA',
  'TAMARACK WALDORF SCHOOL',
  'TAMIAMI UNITED METHODIST DAYCARE & ELEM SCHOOL',
  'TAMPA ADVENTIST ACADEMY',
  'TAMPA BAY CHRISTIAN ACADEMY',
  'TAMPA CATHOLIC HIGH SCHOOL',
  'TAMPA DAY SCHOOL',
  'TAMPA PREPARATORY SCHOOL',
  'TAMU S. KANYAMA PREP ACADEMY',
  'TANDEM FRIENDS SCHOOL',
  'TANGLEWOOD SCHOOL',
  'TANQUE VERDE LUTHERAN PRESCHOOL & KINDERGARTEN',
  'TAPPAHANNOCK JUNIOR ACADEMY',
  'TAPROOT MONTESSORI PRESCHOOL & KINDERGARTEN',
  'TAPROOT MONTESSORI-LA MESA',
  'TARA HALL SCHOOL',
  'TARA MONTESSORI SCHOOL',
  'TARA PERFORMING ARTS HIGH SCHOOL',
  'TARBIYAH ISLAMIC SCHOOL OF DELAWARE',
  'TARE CREEK',
  'TARLETON SCHOOL',
  'TAS MONTESSORI SCHOOL',
  'TASHBAR OF LAKEWOOD',
  'TASHBAR SEPHARDIC YESHIVA KETANA',
  'TATE SPRINGS CHRISTIAN SCHOOL',
  'TATES SCHOOL',
  'TATTNALL SQUARE ACADEMY',
  'TAUXEMONT COOPERATIVE PRESCHOOL',
  'TAVARES EDUCATIONAL CENTER',
  'TAWHEED CENTER OF DETROIT SCHOOL',
  'TAYLOR CHRISTIAN SCHOOL',
  'TAYLOR MILL CHRISTIAN ACADEMY',
  'TAYLORS FIRST PRE-ACADEMY',
  'TCELA',
  'TDH CHRISTIAN ACADEMY',
  'TDR LEARNING ACADEMY',
  'TEA RUN C/O ERMA EICHER',
  'TEACHING ALL CHILDREN (TAC) ACADEMY',
  'TEAYS VALLEY CHRISTIAN SCHOOL',
  'TEC EDUCATION CENTER',
  'TECATE CHRISTIAN SCHOOL',
  'TED DIRENZO MONTESSORI SCHOOL',
  'TED University',
  'TEDDY BEAR NURSERY SCHOOL #3',
  'TEHIYAH DAY SCHOOL',
  'TELLURIDE MOUNTAIN SCHOOL',
  'TELOS ACADEMY',
  'TELSHE HIGH SCHOOL',
  'TELSTAR MONTESSORI CHILD CARE CENTER',
  'TEMECULA MONTESSORI ACADEMY',
  'TEMIMA, THE RICHARD AND JEAN KATZ HIGH SCHOOL FOR',
  'TEMPE MONTESSORI SCHOOL LTD',
  'TEMPLE ACADEMY',
  'TEMPLE ADATH ISRAELEARLYCHILDHOOD CENTER',
  'TEMPLE ALIYAH PRESCHOOL AND KINDERGARTEN',
  'TEMPLE BAPTIST ACADEMY',
  'TEMPLE BAPTIST CHRISTIAN SCHOOL',
  'TEMPLE BETH AM DAY SCHOOL',
  'TEMPLE BETH EMET DAY SCHOOL',
  'TEMPLE BETH HILLEL-BETH EL EARLY CHILDHOOD CENTER',
  'TEMPLE BETH SHOLOM INNOVATIVE SCHOOL',
  'TEMPLE BETH TORAH SCHOOL OF EARLY LEARNING AT OYRT',
  'TEMPLE BETH-EL SCHOOL',
  'TEMPLE CHRISTIAN ACADEMY',
  'TEMPLE CHRISTIAN SCHOOL',
  'TEMPLE CHRISTIAN SCHOOLS',
  'TEMPLE COLLEGE PREPARATORY SCHOOL',
  'TEMPLE EMANU-EL EARLY CHILDHOOD EDUCATION PROGRAM',
  'TEMPLE GRANDIN SCHOOL',
  'TEMPLE ISRAEL OF HOLLYWOOD DAY SCHOOL',
  'TEMPLE PHILADELPHIA CHRISTIAN ACADEMY',
  'TEMPLE TRAGER EARLY CHILDHOOD EDUCATION CENTER',
  'TEMPLE-CHRISTIAN SCHOOL',
  'TEMPLETON HILLS 7TH-DAY ADVENTIST SCHOOL',
  'TENACRE COUNTRY DAY SCHOOL',
  'TENAFLY CHABAD ACADEMY',
  'TENDER AGES LEARNING CENTER',
  'TENDER CARE LEARNING CENTER',
  'TENDER LEARNING CARE',
  'TENDER LEARNING CENTRE',
  'TENDER LOVING CARE MONTESSORI',
  'TENDER YEARS',
  'TENNESSEE AVENUE CHRISTIAN ACADEMY',
  'TENNESSEE CHRISTIAN PREPARATORY SCHOOL',
  'TENNYSON CENTER FOR CHILDREN AT CCH',
  'TENSAS ACADEMY',
  'TERI LEARNING ACADEMY',
  'TERI, INC. THE COUNTRY SCHOOL',
  'TERRA CEIA CHRISTIAN SCHOOL',
  'TERRA NICHOL ACADEMY DF THE ARTS',
  'TERRA VERDE DISCOVERY SCHOOL',
  'TERRE HILL MENNONITE HIGH SCHOOL',
  'TERRY S MONTESSORI SCHOOL',
  'TETON VALLEY COMMUNITY SCHOOL',
  'TEURLINGS CATHOLIC HIGH SCHOOL',
  'TEXAS BUFKIN CHRISTIAN ACADEMY',
  'TEXAS CHRISTIAN SCHOOL',
  'TEXAS SUCCESS ACADEMY',
  'TEXAS TORAH INSTITUTE',
  'TEXOMA CHRISTIAN SCHOOL',
  'THACHER MONTESSORI SCHOOL',
  'THADDEUS STEVENS SCHOOL',
  'THALES ACADEMY',
  'THALES ACADEMY APEX K-5',
  'THALES ACADEMY JH/HS',
  'THANKFUL LEARNING CENTER, INC.',
  'THAYER ACADEMY',
  'THE ACADEMY',
  "THE ACADEMY AT MORGAN'S WONDERLAND",
  'THE ACADEMY AT OCEAN REEF',
  'THE ACADEMY AT PENGUIN HALL',
  'THE ACADEMY AT RIVERDALE',
  'THE ACADEMY AT SAINT MARY',
  'THE ACADEMY AT SOAR',
  'THE ACADEMY CHRISTIAN SCHOOL',
  'THE ACADEMY FOR CHILDREN',
  'THE ACADEMY FOR G.O.D.',
  'THE ACADEMY OF SAINT MATTHIAS THE APOSTLE',
  'THE ACADEMY PDPCP',
  'THE ACES-TEMPE',
  'THE ADELSON EDUCATIONAL CAMPUS-LAS VEGAS',
  'THE ADVENT SCHOOL',
  'THE ADVENTIST SCHOOL ACADEMY LEARNING CENTER',
  'THE AGNES IRWIN SCHOOL',
  'THE ALBROOK SCHOOL',
  'THE ALLEN-STEVENSON SCHOOL',
  'THE ALTAMONT SCHOOL',
  'THE AMBROSE SCHOOL',
  'THE ANNESLEY INSTITUTE',
  'THE ANTIOCH SCHOOL',
  'THE APPLESEED CHRISTIAN ACADEMY',
  'THE AQUINAS MONTESSORI SCHOOL',
  'THE ARBOR SCHOOL',
  'THE ARC ERIE COUNTY NEW YORK',
  'THE ARC KOHLER SCHOOL',
  'THE ARCHWAY SCHOOL',
  'THE ARMAND HAMMER UNITED WORLD COLLEGE',
  'THE ATHENIAN SCHOOL',
  'THE ATLANTA ACADEMY',
  'THE ATLAS SCHOOL',
  'THE AUBURN SCHOOL',
  'THE AUBURN SCHOOL BALTIMORE',
  'THE AUBURN SCHOOL SILVER SPRING CAMPUS',
  'THE AURORA SCHOOL',
  'THE AVALON ACADEMY',
  'THE AWTY INTERNATIONAL SCHOOL',
  'THE BAAN DEK MONTESSORI SCHOOL',
  'THE BALA HOUSE MONTESSORI SCHOOL',
  'THE BALDWIN SCHOOL',
  'THE BALTIMORE ACADEMY',
  'THE BANFF SCHOOL',
  'THE BANNER SCHOOL',
  'THE BAPTIST ACADEMY',
  'THE BAPTIST PREPARATORY SCHOOL',
  'THE BAPTIST SCHOOL',
  'THE BARBEREUX SCHOOL',
  'THE BARDAY SCHOOL',
  'THE BARRY ROBINSON CENTER',
  'THE BASILICA SCHOOL',
  'THE BAY SCHOOL',
  'THE BAY SCHOOL OF SAN FRANCISCO',
  'THE BEANSTALK ACADEMY',
  'THE BEAR CREEK SCHOOL',
  'THE BEDFORD SCHOOL',
  'THE BEECH HILL SCHOOL',
  'THE BEEKMAN SCHOOL',
  'THE BEGINNING SCHOOL',
  'THE BELLWETHER SCHOOL',
  'THE BEMENT SCHOOL',
  'THE BENAJA CHRISTIAN ACADEMY',
  'THE BENEDICTINE SCHOOL',
  'THE BENJAMIN SCHOOL',
  'THE BERKELEY SCHOOL',
  'THE BESS & PAUL SIGEL HEBREW ACADEMY',
  'THE BETHESDA MONTESSORI SCHOOL',
  'THE BETHLEHEM CHILDRENS SCHOOL',
  'THE BEVERLY INSTITUTE',
  'THE BIBLE SPEAKS ACADEMY',
  'THE BIBLE WAY CHRISTIAN ACADEMY',
  'THE BILTMORE SCHOOL',
  'THE BIONDI SCHOOL',
  'THE BIRCH WATHEN LENOX SCHOOL',
  'THE BIRCHTREE CENTER',
  "THE BISHOP'S SCHOOL",
  'THE BLAKE SCHOOL',
  'THE BLUE HILL MONTESSORI SCHOOL',
  'THE BODINE SCHOOL',
  'THE BOLLES SCHOOL',
  'THE BRANCH SCHOOL',
  'THE BRANDEIS SCHOOL OF SAN FRANCISCO',
  'THE BRANDON MONTESSORI SCHOOL AT RIVER HILLS',
  'THE BRANDUS SCHOOL',
  'THE BRANSON SCHOOL',
  'THE BREARLEY SCHOOL',
  'THE BRIARWOOD SCHOOL',
  'THE BRIDGE',
  'THE BRIDGE ACADEMY',
  'THE BRIDGE AVENUE SCHOOL',
  'THE BRIDGE HIGH SCHOOL',
  'THE BRIDGE SARASOTA',
  'THE BRIDGE SCHOOL',
  'THE BRIGHT SCHOOL',
  'THE BRIGHTON SCHOOL',
  'THE BROACH SCHOOL OF TAMPA',
  'THE BROACH SCHOOL ORANGE PARK',
  'THE BROOK HILL SCHOOL',
  'THE BROOKSIDE SCHOOL',
  'THE BROWN COUNTY CHRISTIAN ACADEMY',
  'THE BRYN MAWR SCHOOL',
  'THE BUCKLEY SCHOOL',
  'THE BURLINGTON SCHOOL',
  'THE BUSH SCHOOL',
  'THE CAEDMON SCHOOL',
  'THE CALADIUM LEARNING CENTER',
  'THE CALVERTON SCHOOL',
  'THE CAMBRIDGE SCHOOL',
  'THE CAMBRIDGE SCHOOL OF DALLAS',
  'THE CAMBRIDGE SCHOOL OF WESTON',
  'THE CAMPUS AT KIDS FIRST',
  'THE CAMPUS SCHOOL OF CARLOW UNIVERSITY',
  'THE CAMPUS SCHOOLS',
  'THE CANTERBURY EPISCOPAL SCHOOL',
  'THE CANTERBURY SCHOOL OF FLORIDA',
  'THE CAPE ACADEMY',
  'THE CAPITOL SCHOOL',
  'THE CAREY SCHOOL',
  'THE CARMEL SCHOOL',
  'THE CAROLINA ACADEMY',
  'THE CAROLYN BARRON MONTESSORI SCHOOL',
  'THE CARROLL SCHOOL',
  'THE CATHEDRAL PRESCHOOL',
  'THE CATHEDRAL SCHOOL',
  'THE CATHEDRAL SCHOOL OF BROOKLYN',
  'THE CATHOLIC HIGH SCHOOL OF BALTIMORE',
  'THE CENTER FOR DEVELOPMENTAL DISABILITIES',
  'THE CENTER FOR DISCOVERY',
  'THE CENTER SCHOOL',
  'THE CHANTICLEER LEARNING CENTER',
  'THE CHAPEL SCHOOL',
  'THE CHAPIN SCHOOL LTD',
  'THE CHARLESTON CATHOLIC SCHOOL',
  'THE CHARTWELL CENTER',
  'THE CHASE ACADEMY, INC.',
  'THE CHESHIRE ACADEMY',
  'THE CHESTNUT HILLSCHOOL',
  'THE CHICAGO ACADEMY FOR THE ARTS',
  'THE CHILD DAY SCHOOL - LAFAYETTE',
  "THE CHILD'S PRIMARY SCHOOL",
  'THE CHILDHOOD DEVELOPMENT CENTER',
  "THE CHILDREN'S ACADEMY",
  "THE CHILDREN'S CENTER AT UCP",
  "THE CHILDREN'S CORNERSTONE",
  "THE CHILDREN'S HOUSE",
  "THE CHILDREN'S HOUSE - DELHI",
  "THE CHILDREN'S HOUSE AT SERENBE",
  "THE CHILDREN'S HOUSE OF WEST CHESTER",
  "THE CHILDREN'S HOUSE-AUBURN MONTESSORI",
  "THE CHILDREN'S LEARNING CENTER",
  "THE CHILDREN'S SCHOOL",
  "THE CHILDREN'S SCHOOL AT STEPHENS C",
  "THE CHILDREN'S SCHOOL AT URMC",
  "THE CHILDREN'S SCHOOL OF BOISE",
  "THE CHILDREN'S TREE MONTESSORI SCHOOL",
  "THE CHILDREN'S WORKSHOP",
  'THE CHILDRENS COURTYARD 3052',
  'THE CHILDRENS GARDEN',
  'THE CHILDRENS GARDEN OF ST JOHNS LUTHERAN CHURCH',
  'THE CHILDRENS GUILD',
  'THE CHILDRENS HOUSE',
  'THE CHILDRENS HOUSE MONTESSORI SCHOOL',
  'THE CHILDRENS HOUSE OF GOLDSBORO',
  'THE CHILDRENS PLACE',
  'THE CHILDRENS PLACE SCHOOL',
  'THE CHILDRENS SCHOOL',
  'THE CHILDRENS SCHOOL OF CABRINI UNIVERSITY',
  'THE CHILDS-PACE FOUNDATION, INC.',
  'THE CHRISTIAN ACADEMY',
  'THE CHRISTIAN ACADEMY OF ACTSCHOOLS',
  'THE CHRISTIAN SCHOOL AT CASTLE HILLS',
  'THE CHRISTIAN SCHOOL OF GRACE BAPTIST CHURCH',
  'THE CHURCH ACADEMY',
  'THE CHURCH CHRISTIAN ACADEMY',
  'THE CHURCHILL SCHOOL & CENTER',
  'THE CINDAY ACADEMY',
  'THE CIRCLE OF FRIENDS SCHOOL',
  'THE CIRCLE SCHOOL',
  'THE CITY SCHOOL',
  'THE CITY SCHOOL- SPRUCE HILL',
  'THE CLARIDEN SCHOOL',
  'THE CLARKSBURG CHILDRENS MONTESSORI SCHOOL',
  'THE CLASSICAL ACADEMY OF SARASOTA',
  'THE CLEARWATER SCHOOL',
  'THE CO-OP SCHOOL',
  'THE COLLEGE PREPARATORY SCHOOL',
  'THE COLLEGE SCHOOL',
  'THE COLORADO SPRINGS SCHOOL',
  'THE COLUMBUS JEWISH DAY SCHOOL',
  'THE COMMON SCHOOL',
  'THE COMMUNITY SCHOOL',
  'THE COMPASS SCHOOL OF MANASSAS',
  'THE CONCEPT SCHOOL',
  'THE CONCORDIA SCHOOL',
  'THE CONNECTION SCHOOL OF HOUSTON',
  'THE CONTOOCOOK SCHOOL',
  'THE COOPER SCHOOL',
  'THE CORNERSTONE CHRISTIAN SCHOOL',
  'THE CORNERSTONE SCHOOL',
  'THE COTTAGE CHILDRENS CENTER',
  'THE COTTAGE MONTESSORI SCHOOL',
  'THE COTTAGE SCHOOL',
  'THE COTTAGE SCHOOL - TERRY',
  'THE COUNTRY DAY SCHOOL',
  'THE COUNTRY SCHOOL',
  'THE COVENANT PREPARATORY SCHOOL',
  'THE COVENANT SCHOOL',
  'THE CRAIG SCHOOL',
  'THE CREATIVE CHILD DAY CARE LEARNING CENTER',
  'THE CREATIVE LEARNING CENTER',
  'THE CREEK',
  'THE CREEKSIDE SCHOOL',
  'THE CREFELD SCHOOL',
  'THE CRENSHAW SCHOOL',
  'THE CRESCENT ACADEMY',
  'THE CROSS CHRISTIAN ACADEMY',
  'THE CROSS CHRISTIAN SCHOOL',
  'THE CROSSROADS SCHOOL',
  'THE CROWDEN SCHOOL',
  'THE CURIOSITY SHOPPE',
  'THE CURIOUS MINDS MONTESSORI SCHOOL',
  'THE DA VINCI SCHOOL',
  'THE DANIEL ACADEMY',
  'THE DAVID SCHOOL',
  'THE DAVIS ACADEMY MIDDLE SCHOOL',
  'THE DAY PROGRAM',
  'THE DAY SCHOOL AT CHILDRENS INSTITUTE PITTSBURGH',
  'THE DE PAUL SCHOOL',
  'THE DECK HOUSE SCHOOL',
  'THE DEL RAY MONTESSORI SCHOOL',
  'THE DEPAUL CATHOLIC SCHOOL',
  'THE DEPAUL SCHOOL FOR DYSLEXIA',
  'THE DERON SCHOOL OF NJ',
  'THE DERRYFIELD SCHOOL',
  'THE DEVELOPMENT CENTER',
  'THE DEVINE SCHOOL',
  'THE DIENER SCHOOL',
  'THE DISCOVERY CENTER SCHOOL',
  'THE DISCOVERY SCHOOL',
  'THE DISCOVERY SCHOOL OF VIRGINIA',
  'THE DOME SCHOOL',
  'THE DOMINION SCHOOL',
  'THE DONOHO SCHOOL',
  'THE DRAKE SCHOOL',
  'THE DURAND SCHOOL',
  'THE DURHAM CENTER FOR EDUCATION',
  'THE EARLY CHILD DEVELOPMENT EDUCATIONAL CENTER',
  'THE EARLY LEARNING CENTER',
  'THE EAST ORANGE YOUTH CULTURAL COMPLEX',
  'THE EASTSIDE MONTESSORI SCHOOL',
  'THE EDDY',
  'THE EDUCATION ACADEMY',
  'THE EINSTEIN ACADEMY',
  'THE ELIJA SCHOOL',
  'THE ELISABETH MORROW SCHOOL',
  'THE ELLIS SCHOOL OF ATLANTA',
  'THE ELMWOOD ACADEMY',
  'THE EMBANKMENT SCHOOL',
  'THE EMERGE CENTER',
  'THE EMERY/WEINER CENTER FOR JEWISH EDUCATION',
  'THE EMERY/WEINER SCHOOL',
  'THE ENSWORTH SCHOOL',
  'THE EPIPHANY SCHOOL',
  'THE EPIPHANY SCHOOL OF GLOBAL STUDIES',
  'THE EPISCOPAL ACADEMY',
  'THE EPISCOPAL COLLEGIATE SCHOOL',
  'THE EPISCOPAL SCHOOL OF KNOXVILLE',
  'THE EPSTEIN SCHOOL',
  'THE EVERGREEN SCHOOL',
  'THE FAISON SCHOOL',
  'THE FAMILY ACADEMY OF BETHESDA',
  'THE FAMILY SCHOOL',
  'THE FARM MONTESSORI',
  'THE FARM SCHOOL',
  'THE FAY SCHOOL',
  'THE FENN SCHOOL',
  'THE FESSENDEN SCHOOL',
  'THE FIRST UNITED METHODIST SCHOOL CENTER',
  'THE FLETCHER ACADEMY',
  'THE FLETCHER SCHOOL',
  'THE FOOTE SCHOOL',
  'THE FORBUSH SCHOOL AT GLYNDON',
  'THE FORUM SCHOOL',
  'THE FOUNDATION ACADEMY',
  'THE FOUNDATION FOR BLIND CHILDREN',
  'THE FOUNDATION SCHOOL',
  'THE FOUNDATION SCHOOL ORANGE',
  'THE FOUNDERS CENTER',
  'THE FRANCISCAN SCHOOL',
  'THE FRANKFORT CHRISTIAN ACADEMY',
  'THE FREE SCHOOL',
  'THE FREEDOM SCHOOL',
  'THE FRENCH AMERICAN ACADEMY',
  'THE FRENCH AMERICAN SCHOOL OF MIAMI',
  'THE FRENCH MATERNAL SCHOOL',
  'THE FRIENDS SCHOOL OF ATLANTA',
  'THE FRISCH SCHOOL',
  'THE FROST SCHOOL',
  'THE FULL WILL OF GOD CHRISTIAN ACADEMY',
  'THE FULTON SCHOOL',
  'THE FULTON SCHOOL AT ST ALBANS',
  'THE G.I.F.T.ED LEARNING CENTER INC.',
  'THE GALLOWAY SCHOOL',
  'THE GALVESTON MULTICULTURAL ACADEMY',
  'THE GAN',
  'THE GAN MONTESSORI',
  'THE GARDNER SCHOOL OF ARTS & SCIENCES',
  'THE GARDNER SCHOOL OF DUBLIN',
  'THE GATEWAY ACADEMY',
  'THE GATEWAY SCHOOL',
  'THE GENEVA SCHOOL',
  'THE GENEVA SCHOOL OF MANHATTAN',
  'THE GENEVA SCHOOL OF MANHATTAN WEST CAMPUS',
  'THE GENTLEMEN AND LADIES ACADEMY',
  'THE GILLISPIE SCHOOL',
  'THE GIRLS MIDDLE SCHOOL',
  'THE GIVING NEST PRESCHOOL',
  'THE GIVING NEST PRESCHOOL IN MARTINSVILLE',
  'THE GLADWYNE MONTESSORI SCHOOL',
  'THE GLADYS H OBERLE SCHOOL',
  'THE GLEN MONTESSORI SCHOOL',
  'THE GODDARD SCHOOL',
  'THE GODDARD SCHOOL (CHESAPEAKE)',
  'THE GODDARD SCHOOL - ASHLAND',
  'THE GODDARD SCHOOL - CANTON I',
  'THE GODDARD SCHOOL - CENTERVILLE',
  'THE GODDARD SCHOOL - FLORENCE',
  'THE GODDARD SCHOOL - LEWIS CENTER',
  'THE GODDARD SCHOOL - MASON I',
  'THE GODDARD SCHOOL - MEDINA',
  'THE GODDARD SCHOOL - NUCKOLS',
  'THE GODDARD SCHOOL - SANDY SPRINGS',
  'THE GODDARD SCHOOL - SNELLVILLE',
  'THE GODDARD SCHOOL - SPARKS',
  'THE GODDARD SCHOOL - SUWANEE III',
  'THE GODDARD SCHOOL - WEST CHESTER',
  'THE GODDARD SCHOOL CEDAR PARK',
  'THE GODDARD SCHOOL FOR EARLY CHILDHOOD DEVT',
  'THE GODDARD SCHOOL IN GROVE CITY',
  'THE GODDARD SCHOOL IN ROUND LAKE',
  'THE GODDARD SCHOOL LAKE RIDGE',
  'THE GODDARD SCHOOL OF ALLEN',
  'THE GODDARD SCHOOL OF FREDERICK',
  'THE GODDARD SCHOOL OF SPRINGBORO',
  'THE GODDARD SCHOOL OF WALL',
  'THE GODDARD SCHOOL OF WOODBRIDGE',
  'THE GODDARD SCHOOL THIRD LAKE',
  'THE GODDARD SCHOOL-REYNOLDSBURG',
  'THE GODDARD SCHOOL-SKIPPACK',
  'THE GOOD SHEPHERD CATHOLIC MONTESSORI',
  'THE GOOD SHEPHERD NATIVITY MISSION SCHOOL',
  'THE GORDON SCHOOL OF BETH DAVID CONGREGATION',
  'THE GOSPEL LIGHTHOUSE CHRISTIAN ACADEMY',
  'THE GOVERNORS ACADEMY',
  'THE GOW SCHOOL',
  'THE GRACE S WEBB SCHOOL',
  'THE GRAMMAR SCHOOL',
  'THE GRAMON SCHOOL',
  'THE GRANT ACADEMY',
  'THE GRAYSON SCHOOL',
  'THE GREENE SCHOOL',
  'THE GREENMOUNT SCHOOL',
  'THE GREENVALE SCHOOL',
  'THE GREENVILLE LEARNING CENTER',
  'THE GREENWICH JAPANESE SCHOOL',
  'THE GREENWOOD SCHOOL',
  'THE GREGORY SCHOOL',
  'THE GRODEN CENTER',
  'THE GROSSE POINTE ACADEMY',
  'THE GROWING GARDEN NURSERY SCHOOL',
  'THE GROWING PLACE',
  'THE GROWING PLACE MONTESSORI',
  'THE GUNNERY',
  'THE GUNSTON SCHOOL',
  'THE GW COMMUNITY SCHOOL',
  'THE HABERSHAM SCHOOL',
  'THE HAGEDORN LITTLE VILLAGE SCHOOL',
  'THE HALLEN SCHOOL',
  'THE HAMLIN SCHOOL',
  'THE HAMMOND SCHOOL',
  'THE HARBOUR SCHOOL AT ANNAPOLIS',
  'THE HARID CONSERVATORY',
  'THE HARLEY SCHOOL',
  'THE HARRIS SCHOOL',
  'THE HARTSBROOK SCHOOL',
  'THE HARVEY SCHOOL',
  'THE HAVERFORD SCHOOL',
  'THE HAYGROUND SCHOOL',
  'THE HEALDSBURG SCHOOL',
  'THE HEBREW ACADEMY OHR MENACHEM-CHABAD',
  'THE HEIGHTS SCHOOL',
  "THE HELP GROUP'S PARKHILL SCHOOL",
  'THE HENRY BUCKNER SCHOOL',
  'THE HERITAGE SCHOOL',
  'THE HEWITT SCHOOL',
  'THE HIGH SCHOOL OF SAINT THOMAS MORE',
  'THE HIGHLAND SCHOOL',
  'THE HIGHLANDS SCHOOL',
  'THE HILL CENTER',
  'THE HILL SCHOOL',
  'THE HILLIER SCHOOL',
  'THE HILLSIDE SCHOOL',
  'THE HILLSIDE SCHOOL & LEARNING CENTER',
  'THE HILLSPRINGS LEARNING CENTER',
  'THE HIRSCH ACADEMY',
  'THE HOCKESSIN MONTESSORI SCHOOL',
  'THE HOLY NAME OF JESUS ACADEMY',
  'THE HOMESTEAD SCHOOL',
  'THE HONOR ROLL SCHOOL',
  'THE HOPE CHRISTIAN ACADEMY',
  'THE HOPE SCHOOL LEARNING CENTER',
  'THE HOTCHKISS SCHOOL',
  'THE HOUSE AT POOH CORNER',
  'THE HOWARD GARDNER SCHOOL',
  'THE HOWARD SCHOOL',
  'THE HUB HOUSTON INC.',
  'THE HUDA ACADEMY',
  'THE HUDSON SCHOOL',
  'THE HUGHES CENTER',
  'THE HUN SCHOOL OF PRINCETON',
  'THE IDEAL SCHOOL OF MANHATTAN',
  'THE IMAGO SCHOOL ASSOCIATION I',
  'THE IMANI SCHOOL',
  'THE INCREASE LEARNING CENTER, INC.',
  'THE INDEPENDENCE ACADEMY',
  'THE INDEPENDENCE SCHOOL',
  'THE INDEPENDENT DAY SCHOOL',
  'THE INDEPENDENT SCHOOL',
  'THE INDEPENDENT SCHOOL OF WINCHESTER INC',
  'THE INTERNATIONAL SCHOOL',
  'THE INTERNATIONAL SCHOOL OF CHARLOTTESVILLE',
  'THE INTERNATIONAL SCHOOL OF MINNESOTA LLC',
  'THE ISLAMIC ACADEMY FOR PEACE',
  'THE ISLAMIC EDUCATION SCHOOL',
  'THE ISLAMIC EDUCATION SCHOOL -TIES',
  'THE ISLAND SCHOOL',
  'THE JACKSON ACADEMY',
  'THE JANE JUSTIN SCHOOL',
  'THE JANUS SCHOOL',
  'THE JEFFERSON SCHOOL',
  'THE JEFFERSON SCHOOL AT FINAN CENTER',
  'THE JENKINS SCHOOL',
  'THE JERICHO SCHOOL FOR CHILDREN WITH AUTISM',
  'THE JOHN COOPER SCHOOL',
  'THE JOHN CROSLAND SCHOOL',
  'THE JONES-GORDON SCHOOL',
  'THE JOSHUA SCHOOL',
  'THE JOULE SCHOOL',
  'THE JOY SCHOOL',
  'THE JUDGE ROTENBERG CENTER',
  'THE JULIA BROWN SCHOOL',
  'THE KARAFIN SCHOOL',
  'THE KATHERINE THOMAS SCHOOL',
  'THE KATHY HERWARD CHILD CARE CENTER',
  'THE KELLAR SCHOOL OF INOVA KELLAR CENTER',
  'THE KENLEY SCHOOL',
  'THE KESSLER SCHOOL',
  'THE KILDONAN SCHOOL',
  "THE KING'S ACADEMY",
  'THE KINGS ACADEMY',
  'THE KINGS CHRISTIAN ACADEMY',
  'THE KINGS DAUGHTERS SCHOOL',
  'THE KINKAID SCHOOL',
  'THE KIPLING SCHOOL',
  'THE KISKI SCHOOL',
  'THE KNOWING GARDEN',
  'THE KNOX SCHOOL',
  'THE KNOX SCHOOL OF SANTA BARBARA',
  'THE KOONINGS CENTER',
  'THE LAB SCHOOL OF WASHINGTON',
  'THE LAKE AND PARK SCHOOL',
  'THE LAKESIDE SCHOOL',
  'THE LAMB OF GOD SCHOOL',
  'THE LAMBS WORKSHOP',
  'THE LAMPLIGHTER SCHOOL',
  'THE LANGLEY SCHOOL',
  'THE LATIN SCHOOL OF CHICAGO',
  'THE LAUREL HILL SCHOOL',
  'THE LAUREL SCHOOL',
  'THE LAWRENCEVILLE SCHOOL',
  'THE LEAD CENTER LTD',
  'THE LEARNING ACADEMY OF TETON VALLEY',
  'THE LEARNING CENTER',
  'THE LEARNING CENTER AT FIRST BAPTIST',
  'THE LEARNING CENTER FOR THE DEAF',
  'THE LEARNING COMMUNITY DAY SCHOOL',
  'THE LEARNING COMMUNITY SCHOOL',
  'THE LEARNING CORNER EARLY CHILDHOOD CENTER I',
  'THE LEARNING CURVE ACADEMY INC',
  'THE LEARNING EXPERIENCE',
  'THE LEARNING EXPERIENCE AT PARKVILLE',
  'THE LEARNING EXPERIENCE DOYLESTOWN',
  'THE LEARNING FOUNDATION OF FLORIDA',
  'THE LEARNING PATH CHILDCARE CTR & KINDERGARTEN',
  'THE LEARNING PLACE ACADEMY',
  'THE LEARNING STATION',
  'THE LEARNING TREE',
  'THE LEELANAU SCHOOL',
  'THE LEXINGTON SCHOOL',
  'THE LIBERI SCHOOL',
  'THE LIBERTY SCHOOL',
  'THE LIFE ACADEMY OF EXCELLENCE',
  'THE LIGHT HOUSE VOC-ED CTR',
  'THE LIGHT OF THE CHILD MONTESSORI SCHOOL, LLC',
  'THE LIGHTHOUSE SCHOOL',
  'THE LILLIAN & BETTY RATNER SCHOOL',
  'THE LINCROFT CENTER FOR CHILDREN',
  'THE LINK SCHOOL',
  'THE LINSLY SCHOOL',
  'THE LIONHEART SCHOOL',
  'THE LIPPMAN SCHOOL',
  'THE LITTLE COUNTRY SCHOOL',
  'THE LITTLE DOLPHIN SCHOOL FOUNDATION',
  'THE LITTLE ENGINE ACADEMY',
  'THE LITTLE FRENCH SCHOOL',
  'THE LITTLE SCHOOL',
  'THE LIVING WATER SCHOOL',
  'THE LONG RIDGE SCHOOL',
  'THE LORDS CHILDREN',
  'THE LORGE SCHOOL',
  'THE LOTUS SCHOOL OF LIBERAL ARTS',
  'THE LOURIE CTR SCHOOL OF THE REGINALD S LOURIE',
  'THE LOVETT SCHOOL',
  'THE LOWELL SCHOOL',
  'THE LUTHERAN ACADEMY SCRANTON',
  'THE LYMAN SCHOOL',
  'THE MACDUFFIE SCHOOL',
  'THE MADDUX SCHOOL',
  'THE MADEIRA SCHOOL',
  'THE MADELEINE CHOIR SCHOOL',
  'THE MADELEINE ELEMENTARY SCHOOL',
  'THE MADRONE SCHOOL',
  'THE MAGELLAN INTERNATIONAL SCHOOL',
  'THE MAGICAL PALACE OF KNOWLEDGE',
  'THE MAGNOLIA SCHOOL',
  'THE MAINE GIRLS ACADEMY',
  'THE MANDELSTAM SCHOOL',
  'THE MANOR MONTESSORI SCHOOL',
  'THE MARIA MONTESSORI SCHOOL',
  'THE MARIN SCHOOL',
  'THE MARSHALL SCHOOL',
  'THE MARVELWOOD SCHOOL',
  'THE MARY LOUIS ACADEMY',
  'THE MARYLAND INTERNATIONAL DAY SCHOOL',
  'THE MARYLAND SCHOOL FOR THE BLIND',
  'THE MARYLHURST SCHOOL',
  "THE MASTER'S SCHOOL",
  'THE MASTERS SCHOOL',
  'THE MAY CENTER SCHOOL FOR AUTISM AND DVLPMNTL DIS',
  'THE MCCLELLAND SCHOOL',
  'THE MCGILLIS SCHOOL',
  'THE MCKAY ACADEMY',
  'THE MEAD SCHOOL',
  'THE MEADOWBROOK SCHOOL',
  'THE MEADOWRIDGE ACADEMY',
  'THE MEADOWS SCHOOL',
  'THE MERIDIAN SCHOOL',
  'THE MERIT SCHOOL OF MANASSAS',
  'THE MESIVTA HIGH SCHOOL OF GREATER PHILA',
  'THE MIAMI MONTESSORI SCHOOL',
  'THE MIDLAND SCHOOL',
  'THE MILL CREEK SCHOOL',
  'THE MIQUON SCHOOL',
  'THE MOMENTUM ACADEMY',
  'THE MONARCH MONTESSORI SCHOOL',
  'THE MONARCH SCHOOL',
  'THE MONROE SCHOOL',
  'THE MONTCLAIR KIMBERLEY ACADEMY',
  'THE MONTESSORI ACADEMY AT EDISON LAKES',
  'THE MONTESSORI ACADEMY OF ANAHEIM',
  'THE MONTESSORI ACADEMY OF ARLINGTON',
  'THE MONTESSORI ACADEMY OF JACKSON',
  'THE MONTESSORI CENTER',
  "THE MONTESSORI CHILDREN'S ACADEMY OF CHATHAM",
  "THE MONTESSORI CHILDREN'S HOUSE OF HYDE PARK",
  "THE MONTESSORI CHILDREN'S HOUSE OF YORK",
  "THE MONTESSORI CHILDREN'S SCHOOL",
  'THE MONTESSORI CHILDRENS ACADEMY',
  'THE MONTESSORI EXPERIENCE',
  'THE MONTESSORI GARDEN INC',
  'THE MONTESSORI GIVING TREE',
  'THE MONTESSORI HOUSE',
  'THE MONTESSORI HOUSE DAY SCHOOL',
  'THE MONTESSORI SCHOOL',
  'THE MONTESSORI SCHOOL OF CAMDEN',
  'THE MONTESSORI SCHOOL OF CLEVELAND',
  'THE MONTESSORI SCHOOL OF FORT SMITH',
  'THE MONTESSORI SCHOOL OF HAMILTON',
  'THE MONTESSORI SCHOOL OF HUNTSVILLE',
  'THE MONTESSORI SCHOOL OF LOUISVILLE',
  'THE MONTESSORI SCHOOL OF NORTHAMPTON',
  'THE MONTESSORI SCHOOL OF SAN ANTONIO',
  'THE MONTESSORI SCHOOL OF THE ANGELS, INC',
  'THE MONTESSORI SCHOOL OF THE BERKSHIRES',
  'THE MONTESSORI SCHOOL OF THE MAHONING VALLEY',
  'THE MONTESSORI SCHOOL OF WASHINGTON PARK',
  'THE MONTESSORI SCHOOL-RICHLAND CAMPUS',
  'THE MONTESSORI SCHOOLS OF CENTRAL TEXAS',
  'THE MONTESSOURI SCHOOL',
  'THE MOOG CENTER FOR DEAF EDUCATION',
  'THE MOOSE PROJECT',
  'THE MORIAH SCHOOL',
  'THE MORRISTOWN-BEARD SCHOOL',
  'THE MOVEMENT SCHOOL',
  'THE NATIVITY SCHOOL',
  'THE NATIVITY SCHOOL OF HARRISBURG',
  'THE NATIVITY SCHOOL OF WORCESTER',
  'THE NEIGHBORHOOD ACADEMY',
  'THE NEW CLASSICAL ACADEMY',
  'THE NEW COMMUNITY SCHOOL',
  'THE NEW ENGLAND CENTER FOR CHILDREN',
  'THE NEW FOUNDATION-MARION BURTON SCHOOL',
  'THE NEW HAMPTON SCHOOL',
  'THE NEW JERSEY JAPANESE SCHOOL',
  'THE NEW MILLENNIUM ACADEMY',
  'THE NEW SCHOOL',
  'THE NEW SCHOOL MONTESSORI',
  'THE NEW SCHOOL OF LANCASTER',
  'THE NEW SCHOOL OF MONTPELIER',
  'THE NEW SCHOOL OF NORTHERN VIRGINIA',
  'THE NEW VILLAGE SCHOOL',
  'THE NEWMAN SCHOOL',
  'THE NEWTON SCHOOL',
  'THE NICHOLAS SCHOOL',
  'THE NORA SCHOOL',
  'THE NORMAN HOWARD SCHOOL',
  'THE NORTH FORK SCHOOL',
  'THE NORTHERN TIER CHRISTIAN SCHOOL',
  'THE NORTHWEST SCHOOL',
  'THE NOVUS ACADEMY',
  'THE NUEVA MIDDLE SCHOOL',
  'THE NUEVA SCHOOL',
  'THE NURTURING CENTER',
  'THE NURTURY',
  "THE O'NEAL SCHOOL",
  'THE OAK HILL SCHOOL',
  'THE OAKS ACADEMY',
  'THE OAKS ACADEMY - BROOKSIDE',
  'THE OAKS ACADEMY - MIDDLE SCHOOL',
  'THE OAKS ADVENTIST CHRISTIAN SCHOOL',
  'THE OAKS CHRISTIAN SCHOOL',
  'THE OAKS PRIVATE SCHOOL',
  'THE OAKS SCHOOL',
  'THE OAKWOOD SCHOOL',
  'THE OPEN DOORWAY INC',
  'THE OPEN SCHOOL',
  'THE ORCHARD SCHOOL',
  'THE ORME SCHOOL',
  'THE OUTDOOR ACADEMY OF THE SOUTHERN APPALACHIANS',
  'THE OVERLAKE SCHOOL',
  'THE OXBOW SCHOOL',
  'THE OXFORD SCHOOL OF DUBLIN',
  'THE PACEM SCHOOL',
  'THE PAIDEIA SCHOOL OF TAMPA BAY',
  'THE PALMS CHRISTIAN SCHOOL',
  'THE PARAGON SCHOOL',
  'THE PARISH SCHOOL',
  'THE PARK SCHOOL',
  'THE PARK SCHOOL OF BALTIMORE',
  'THE PARK SCHOOL OF BUFFALO',
  'THE PARKE HOUSE ACADEMY',
  'THE PARKSIDE SCHOOL',
  'THE PATHFINDER SCHOOL',
  'THE PATHWAY SCHOOL',
  'THE PAVILION AT BEANTREE',
  'THE PEAK SCHOOL',
  'THE PECK SCHOOL',
  'THE PEGASUS SCHOOL',
  'THE PEN RYN SCHOOL',
  'THE PENNFIELD SCHOOL',
  'THE PENNINGTON SCHOOL',
  'THE PENTECOSTAL CHRISTIAN ACADEMY',
  'THE PERFECT PLACE FOR CHILDREN',
  'THE PHARR ORATORY OF ST PHILIP NERI SCHOOL SYSTEM',
  'THE PHELPS SCHOOL',
  'THE PHOENIX CENTER',
  'THE PHOENIX MONTESSORI SCHOOL',
  'THE PHOENIX RISING SCHOOL',
  'THE PHOENIX SCHOOL',
  'THE PHOENIX SCHOOL FOR CREATIVE LEARNING',
  'THE PHOENIX SCHOOLS',
  'THE PHONICS PHACTORY',
  'THE PIARIST SCHOOL',
  'THE PIEDMONT SCHOOL',
  'THE PIEDMONT SCHOOL OF ATLANTA',
  'THE PIKE SCHOOL',
  'THE PILGRIM ACADEMY',
  'THE PILOT SCHOOL',
  'THE PINE SCHOOL',
  'THE PINECROFT SCHOOL',
  'THE PINGRY LOWER SCHOOL',
  'THE PINGRY SCHOOL',
  'THE PINNACLE SCHOOL',
  'THE PINNACLE SCHOOLS - ELK RIVER TREATMENT PROGRAM',
  'THE PIONEER SCHOOL',
  'THE PLAZA ACADEMY',
  'THE POINT CHRISTIAN ACADEMY',
  'THE POINTE DAY TREATMENT',
  'THE PORTLAND MONTESSORI SCHOOL',
  'THE POST OAK SCHOOL',
  'THE POTOMAC SCHOOL',
  "THE POTTER'S HOUSE CHRISTIAN ACADEMY",
  'THE PREMERON',
  'THE PRENTICE SCHOOL',
  'THE PREP SCHOOL',
  'THE PREPARATORY SCHOOL OF D.C.',
  'THE PRESCHOOL PLACE & KINDERGARTEN AT TEMPLE SHOLO',
  'THE PRESENTATION SCHOOL',
  'THE PRIMARY DAY SCHOOL',
  'THE PRINCIPLED ACADEMY',
  'THE QUAKER SCHOOL AT HORSHAM',
  'THE RABBI NAFTALI RIFF YESHIVA',
  'THE RAINBOW TREE CHRISTIAN SCHOOL',
  'THE RALEIGH SCHOOL',
  'THE RANDOLPH SCHOOL',
  'THE RASHI SCHOOL',
  'THE READING CLINIC',
  'THE RED APPLE SCHOOL INC',
  'THE RED CEDAR SCHOOL',
  'THE RED OAKS SCHOOL',
  "THE REDEEMER'S SCHOOL",
  'THE RENAISSANCE ACADEMY PREP SCHOOL FOR BOYS',
  'THE RENAISSANCE INTERNATIONAL SCHOOL',
  'THE RENAISSANCE SCHOOL',
  'THE REVOLUTION SCHOOL',
  'THE RICHARDSON SCHOOL',
  'THE RIDGE HILL SCHOOL',
  'THE RILEY BEHAVIORAL AND EDUCATIONAL CENTER',
  'THE RIVER ACADEMY',
  'THE RIVERS SCHOOL',
  'THE RIVERSIDE SCHOOL',
  'THE ROBERTS ACADEMY',
  'THE ROCK ACADEMY',
  'THE ROCK CHRISTIAN SCHOOL',
  'THE ROCK ROSE VILLAGE SCHOOL',
  'THE ROCK SCHOOL',
  'THE ROIG ACADEMY',
  'THE RON CLARK ACADEMY',
  'THE RSEC ACADEMY',
  'THE RUBICON ACADEMY',
  'THE SAE SCHOOL',
  'THE SAGE SCHOOL',
  'THE SAGEMONT SCHOOL',
  'THE SAINTS ACADEMY',
  'THE SAKLAN SCHOOL',
  'THE SALISBURY SCHOOL',
  'THE SAMMAMISH MONTESSORI SCHOOL',
  'THE SAMUEL SCHOOL',
  'THE SAN MIGUEL SCHOOL',
  'THE SCHENCK SCHOOL',
  'THE SCHOOL AT COLUMBIA UNIVERSITY',
  'THE SCHOOL AT MCGUIRE MEMORIAL',
  'THE SCHOOL AT SPRINGBROOK',
  'THE SCHOOL AT SWEETSER',
  'THE SCHOOL FOR ETHICS AND GLOBAL LEADERSHIP',
  'THE SCHOOL FOR YOUNG CHILDREN',
  'THE SCHOOL IN ROSE VALLEY',
  'THE SCHOOL OF SACRED HEART ST FRANCIS DE SALES',
  'THE SCHOOL OF SAINTS FAITH, HOPE & CHARITY',
  'THE SCHOOLHOUSE',
  'THE SCHOOLHOUSE INC',
  'THE SCHOOLHOUSE NURSERY SCHOOL AND KINDERGARTEN',
  'THE SCHOOLHOUSE, INC.',
  'THE SEABURY SCHOOL',
  'THE SECOND HOUSE CORP',
  'THE SEER SCHOOL',
  'THE SETTLEMENT PRESCHOOL AND KINDERGARTEN',
  'THE SHEPHERD S SCHOOL',
  'THE SHIPLEY SCHOOL',
  'THE SIENA SCHOOL',
  'THE SISKIYOU SCHOOL',
  'THE SKILL BUILDING CENTER',
  'THE SLOAN SCHOOL',
  'THE SMITH SCHOOL',
  'THE SOLOMON SCHECHTER SCHOOL OF WESTCHESTER',
  'THE SOUTHPORT SCHOOL',
  'THE SPENCE SCHOOL',
  'THE SPEYER LEGACY SCHOOL',
  'THE SPIRE SCHOOL',
  'THE SPRING HILL SCHOOL',
  'THE SPRINGS A MONTESSORI SCHOOL',
  'THE SPURWINK SCHOOL',
  'THE ST ANDREWS SCHOOLS',
  'THE ST ANTHONY SCHOOL',
  'THE ST MICHAEL SCHOOL OF CLAYTON',
  'THE STANLEY CLARK SCHOOL',
  'THE STANWICH SCHOOL',
  'THE STERLING SCHOOL',
  'THE STEWARD SCHOOL',
  'THE STONEHAVEN SCHOOL',
  'THE STONY BROOK SCHOOL',
  "THE STORK'S NEST CHILD ACADEMY - EAST GREENWICH",
  'THE STORM KING SCHOOL',
  'THE STUDIO SCHOOL',
  'THE STUDY CENTER',
  'THE SUMMIT',
  'THE SUMMIT CENTER',
  'THE SUMMIT COUNTRY DAY SCHOOL',
  'THE SUMMIT PREPARATORY ACADEMY',
  'THE SUMMIT SCHOOL',
  'THE SUSAN AND RABBI HAROLD LOSS ECC',
  'THE SWAIN SCHOOL',
  'THE SWIFT SCHOOL',
  'THE TAFT SCHOOL',
  'THE TENNEY SCHOOL',
  'THE THACHER SCHOOL',
  'THE TIMMERMAN SCHOOL',
  'THE TIMOTHY SCHOOL',
  'THE TITUSVILLE ACADEMY',
  'THE TOBIN SCHOOL',
  'THE TOME SCHOOL',
  'THE TORCH CHRISTIAN ACADEMY',
  'THE TOWN SCHOOL',
  'THE TOWNE CREEK SCHOOL',
  'THE TREE HOUSE',
  'THE TRELLIS SCHOOL',
  'THE TREMONT SCHOOL',
  'THE TRIAD SCHOOL',
  'THE TRILOGY SCHOOL',
  'THE TUTORIAL SCHOOL',
  'THE UNDERWOOD SCHOOL',
  'THE UNIVERSITY OF AMERICA',
  'THE UNIVERSITY OF CHILDREN',
  'THE UNIVERSITY SCHOOL',
  "THE UPPER SADDLE RIVER CHILDREN'S ACADEMY",
  'THE URSULINE SCHOOL',
  'THE VALLEY SCHOOL',
  'THE VANGUARD ACADEMY',
  'THE VANGUARD SCHOOL',
  'THE VICTOR SCHOOL',
  'THE VILLAGE ACADEMY',
  "THE VILLAGE CHILDREN'S ACADEMY",
  'THE VILLAGE MONTESSORI SCHOOL',
  'THE VILLAGE SCHOOL',
  'THE VINE CHRISTIAN ACADEMY',
  'THE VINE SCHOOL',
  'THE VIRGINIA CHANCE SCHOOL',
  'THE VON WEDEL MONTESSORI SCHOOL',
  'THE WALDEN SCHOOL',
  'THE WALDEN STREET SCHOOL',
  'THE WALDORF SCHOOL OF ATLANTA',
  'THE WALDORF SCHOOL OF BEND',
  'THE WALDORF SCHOOL OF GARDEN CITY',
  'THE WALDORF SCHOOL OF MENDOCINO COUNTY',
  'THE WALDORF SCHOOL OF ORANGE COUNTY',
  'THE WALDORF SCHOOL OF SAN DIEGO',
  'THE WALKER SCHOOL',
  'THE WATERFORD SCHOOL',
  'THE WAVERLY SCHOOL',
  'THE WAY CHRISTIAN ACADEMY',
  'THE WAY OF THE SHEPHERD MONTESSORI',
  'THE WEBB SCHOOL',
  'THE WEBB SCHOOLS',
  'THE WELL SCHOOL',
  'THE WELLINGTON SCHOOL',
  'THE WESLEY SCHOOL',
  'THE WESTFIELD DAY SCHOOL',
  'THE WESTMINSTER SCHOOLS',
  'THE WESTWOOD SCHOOL',
  'THE WIDE SCHOOL',
  "THE WILKESSCHOOL AT GRACE AND ST. PETER'S",
  'THE WILLIAMS SCHOOL',
  'THE WILLISTON NORTHAMPTON SCHOOL',
  'THE WILLOW SCHOOL',
  'THE WILLOWS ACADEMY',
  'THE WILLOWS COMMUNITY SCHOOL',
  'THE WILSON SCHOOL',
  'THE WINCHESTER SCHOOL',
  'THE WINSTON KNOLLS SCHOOL',
  'THE WINSTON SCHOOL',
  'THE WINSTON SCHOOL SAN ANTONIO',
  'THE WOLF SCHOOL',
  'THE WOOD ACRES SCHOOL',
  'THE WOODHALL SCHOOL',
  'THE WOODLANDS METHODIST SCHOOL',
  'THE WOODLANDS PREPORATORY SCHOOL',
  'THE WOODS ACADEMY',
  'THE WOODWARD SCHOOL',
  'THEOPHANY SCHOOL',
  'THERAPEUTIC LEARNING CENTER - FAMILYLINKS',
  'THEVENET MONTESSORI SCHOOL',
  'THINK GLOBAL SCHOOL',
  'THINKING CHILD CHRISTIAN ACADEMY',
  'THIRD STREET ACADEMY',
  'THIS OLDE HOUSE II',
  'THISTLEWAITHE LEARNING CENTER',
  'THOMAS HART ACADEMY',
  'THOMAS HEYWARD ACADEMY',
  'THOMAS JEFFERSON ACADEMY',
  'THOMAS JEFFERSON INDEPENDENT DAY SCHOOL',
  'THOMAS JEFFERSON SCHOOL',
  'THOMAS MORE PREP-MARIAN HIGH',
  'THOMAS SUMTER ACADEMY',
  'THOMPSONVILLE CHRISTIAN JUNIOR ACADEMY',
  'THORN HILL SCHOOL',
  'THORNCREEK CHRISTIAN SCHOOL',
  'THORNTON ACADEMY',
  'THORP CATHOLIC SCHOOL',
  'THORP MENNONITE SCHOOL',
  'THREE ANGELS CHRISTIAN SCHOOL',
  'THREE ANGELS SEVENTH-DAY ADVENTIST SCHOOL',
  'THREE BEARS LEARNING CENTER',
  'THREE FLAGS ACADEMY',
  'THREE POINTS ELEMENTARY SCHOOL CAMPUS',
  'THREE RIVERS CHRISTIAN SCHOOL',
  'THREE RIVERS VILLAGE SCHOOL',
  'THREE SISTERS ADVENTIST CHRISTIAN SCHOOL',
  'THREE TREE MONTESSORI SCHOOL',
  'THRIVE CHRISTIAN ACADEMY',
  'THUMB AMISH PAROCHIAL SCHOOL',
  'THUMBELINA ACADEMY',
  'THUMBELINA LEARNING CENTER 2',
  'THW LOGAN SCHOOL FOR CREATIVE LEARNING',
  'TIDEWATER ACADEMY',
  'TIDEWATER CLASSICAL ACADEMY',
  'TIDEWATER SCHOOL',
  'TIDINGS OF PEACE CHRISTIAN SCHOOL',
  'TIERRA VISTA MONTESSORI SCHOOL',
  'TIFERES BAIS YAAKOV',
  'TIFERES BNOS / CONG AHAVAZ SHULEM NADVERNA',
  'TIFERES MIRIAM SCHOOL FOR GIRLS',
  'TILDEN PREPARATORY SCHOOL',
  'TILDEN SCHOOL',
  'TILLAMOOK ADVENTIST SCHOOL',
  'TILTON SCHOOL',
  'TIMBER RIDGE SCHOOL',
  'TIMBER VIEW SCHOOL',
  'TIMBERSONG ACADEMY',
  'TIMBERSONG BLACK MOUNTAIN',
  'TIMBERVIEW',
  'TIME TO LEARN ACADEMY',
  'TIMOTHY ACADEMY',
  'TIMOTHY CHRISTIAN ACADEMY',
  'TIMOTHY CHRISTIAN SCHOOL',
  'TIMOTHY CHRISTIAN SCHOOLS',
  'TIMOTHY LUTHERAN PRESCHOOL',
  'TIMOTHY LUTHERAN SCHOOL',
  'TIMOTHY MURPHY SCHOOL',
  'TINKER BELL DAYCARE',
  'TINY MIRACLES LEARNING CENTER',
  'TINY SMILE LEARNING CENTER',
  'TINY STEPS DAYCARE LEARNING CENTER',
  'TINY TYKES ACADEMY',
  'TIPTON CATHOLIC HIGH SCHOOL',
  'TIPTON CHRISTIAN ACADEMY',
  'TIPTON-ROSEMARK ACADEMY',
  'TITAN ACADEMY OF LAKELAND',
  'TITUS HOSTETTER',
  'TITUSVILLE CHILD DEVELOPMENT CENTER',
  'TLC EARLY LEARNING CENTER',
  'TLC KIDS CARE',
  'TLC LEADERSHIP ACADEMY',
  'TLC LEARNING CENTER',
  'TLC MONTESSORI',
  'TLE CHRISTIAN ACADEMY',
  'TLE CHRISTIAN ACADEMY AT GOSPEL OUTREACH INC',
  'TMI EPISCOPAL',
  'TNT ACADEMY',
  'TOBINWORLD',
  'TOCAH VYIRAH OF BORO PARK',
  'TODDLER TECH UNIVERSITY',
  'TOLATR ACADEMY',
  'TOLEDO CHRISTIAN SCHOOLS',
  'TOLEDO JUNIOR ACADEMY',
  "TOLUCA 'CROSSROADS' SCHOOL PRESCHOOL & KINDERGARTE",
  'TOMAH BAPTIST ACADEMY',
  'TOMER DEVORAH HIGH SCHOOL',
  'TOMER DVORA SCHOOL',
  'TOMORROW LAND ACADEMY',
  "TOMORROW'S CHILDREN",
  "TOMORROW'S PROMISE",
  'TOMORROWS HOPE',
  'TONG LE MONTESSORI SCHOOL',
  'TOOBA ACADEMY',
  'TOP HAT MONTESSORI',
  'TOPANGA MOUNTAIN SCHOOL',
  'TOPEKA ADVENTIST SCHOOL',
  'TOPEKA COLLEGIATE SCHOOL',
  'TOPEKA LUTHERAN SCHOOL',
  'TOPSAIL MONTESSORI',
  'TORAH ACADEMY',
  'TORAH ACADEMY FOR GIRLS',
  'TORAH ACADEMY OF BERGEN COUNTY',
  'TORAH ACADEMY OF GREATER PHILADELPHIA',
  'TORAH ACADEMY OF JACKSONVILLE',
  'TORAH ACADEMY OF MILWAUKEE',
  'TORAH ACADEMY OF SAN ANTONIO',
  'TORAH DAY SCHOOL',
  'TORAH DAY SCHOOL OF ATLANTA',
  'TORAH DAY SCHOOL OF PHOENIX',
  'TORAH DAY SCHOOL OF SEATTLE',
  'TORAH GIRLS ACADEMY OF TEXAS',
  'TORAH HIGH SCHOOL LONG BEACH',
  'TORAH HIGH SCHOOL OF SAN DIEGO',
  'TORAH INSTITUTE',
  'TORAH INSTITUTE OF LAKEWOOD / YESHIVA BAIS HATORAH',
  'TORAH PREP SCHOOL - BOYS DIVISION',
  'TORAH PREP SCHOOL - GIRLS DIVISION',
  'TORAH SCHOOL OF GREATER WASHINGTON',
  'TORAS EMACHU',
  'TORAS EMES ACADEMY KLURMAN ELEMENTARY SCHOOL',
  'TORCHBEARERS CHRISTIAN ACADEMY',
  'TORCHLIGHT PREPARATORY ACADEMY',
  'TORRANCE MONTESSORI SCHOOL',
  'TORREON CHRISTIAN SCHOOL',
  'TORREY PINES MONTESSORI CENTER',
  'TORREY RIDGE SCHOOL',
  'TOT STOP PRESCHOOL',
  'TOT TOWN CHILD DEVELOPMENT CENTER',
  'TOTINO-GRACE HIGH SCHOOL',
  'TOUCHED BY GRACE ACADEMY',
  'TOUCHSTONE COMMUNITY SCHOOL',
  'TOUCHSTONE SCHOOL',
  'TOWANITS SCHOOL',
  'TOWER GROVE CHRISTIAN ACADEMY',
  'TOWER HILL SCHOOL',
  'TOWER ROAD CHRISTIAN ACADEMY',
  'TOWER SCHOOL',
  'TOWER VIEV C/O JOHN MILLER',
  'TOWERING OAKS CHRISTIAN SCHOOL',
  'TOWLE INSTITUTE',
  'TOWN & COUNTRY DAY SCHOOL',
  'TOWN & COUNTRY EARLY EDUCATION CENTER',
  'TOWN & COUNTRY SCHOOL',
  "TOWN 'N COUNTRY CHRISTIAN ACADEMY",
  'TOWN CENTER PRESCHOOL',
  'TOWN EAST CHRISTIAN SCHOOL',
  'TOWN SCHOOL FOR BOYS',
  'TOWNLINE SCHOOL',
  'TOWNLINE SQUARE SCHOOL AMISH',
  'TPM College',
  'TRACE ACADEMY',
  'TRACKSIDE SCHOOL',
  'TRACY SDA SCHOOL',
  'TRADERS POINT CHRISTIAN SCHOOLS',
  'TRAFTON ACADEMY',
  'TRAFTON ACADEMY AT HAMMOND',
  'TRAIL VIEW SCHOOLS',
  'TRAINING CENTER CHRISTIAN SCHOOL',
  'TRANQUILITY ADVENTIST SCHOOL',
  'TRANSCENTER FOR YOUTH/EL PUENTE HIGH',
  'TRANSFIGURATION ACADEMY',
  'TRANSFIGURATION ELEMENTARY SCHOOL',
  'TRANSFIGURATION SCHOOL',
  'TRANSFIGURATION UPPER AND LOWER CAMPUS',
  'TRANSFORMATION CHRISTIAN PRESCHOOL',
  'TRANSFORMATION LEARNING',
  'TRANSITIONS ACADEMY',
  'TRANSITIONS SCHOOL',
  'TRAVERSE BAY MENNONITE SCHOOL',
  'TRAVERSE CITY CHRISTIAN SCHOOL',
  'TRAVERSE CITY SEVENTH-DAY ADVENTIST SCHOOL',
  'TREASURE OF KNOWLEDGE CHRISTIAN ACADEMY',
  'TREASURE STATE ACADEMY',
  'TREASURE VALLEY CHRISTIAN SCHOOL',
  'TREASURES OF LIFE ACADEMY',
  'TREE LINE AMISH SCHOOL',
  'TREE OF KNOWLEDGE',
  'TREE OF LIFE ACADEMY',
  'TREE OF LIFE CHRISTIAN PREP SCHOOL',
  'TREE OF LIFE CHRISTIAN SCHOOLS',
  'TREE OF LIFE SCHOOL',
  'TREE OF LIFE-INDIANOLA BRANCH',
  'TREEHOUSE LEARNING',
  'TREEHOUSE MONTESSORI CHRISTIAN SCHOOL',
  'TRELAND ACADEMY',
  'TRENT INTERNATIONAL SCHOOL',
  'TRENT MONTESSORI PRESCHOOL',
  'TRENTON CATHOLIC ACADEMY-UPPER',
  'TRENTON CATHOLIC LOWER SCHOOL',
  'TREVOR DAY SCHOOL',
  'TREVOSE DAY SCHOOL',
  'TRI-CITIES CHRISTIAN SCHOOLS',
  'TRI-CITIES PREP',
  'TRI-CITY ADVENTIST SCHOOL',
  'TRI-CITY CHRISTIAN ACADEMY',
  'TRI-CITY CHRISTIAN SCHOOL',
  'TRI-CITY CHRISTIAN SCHOOLS',
  'TRI-CITY SDA SCHOOL',
  'TRI-COUNTY CHRISTIAN SCHOOL',
  'TRI-COUNTY SCHOOLS',
  'TRI-L CHRISTIAN ACADEMY',
  'TRI-STATE CHRISTIAN ACADEMY',
  'TRI-STATE CHRISTIAN SCHOOL',
  'TRI-UNITY CHRISTIAN SCHOOL',
  'TRI-VALLEY',
  'TRIAD SCHOOL',
  'TRIANGLE DAY SCHOOL',
  'TRIANGLE MONTESSORI ACADEMY',
  'TRIBORO CHRISTIAN ACADEMY',
  'TRICOUNTY ACADEMY',
  'TRIDENT ACADEMY',
  'TRILOGY SCHOOL',
  'TRIMONT CHRISTIAN ACADEMY',
  'TRINITAS CHRISTIAN SCHOOL',
  'TRINITY ACADEMY',
  'TRINITY BAPTIST ACADEMY',
  'TRINITY BAPTIST PRESCHOOL',
  'TRINITY BAPTIST SCHOOL',
  'TRINITY BUCKINGHAM ACADEMY',
  'TRINITY CATHOLIC ACADEMY',
  'TRINITY CATHOLIC HIGH SCHOOL',
  'TRINITY CATHOLIC JR/SR HIGH SCHOOL',
  'TRINITY CATHOLIC SCHOOL',
  'TRINITY CHRISTIAN ACADEMY',
  'TRINITY CHRISTIAN HIGH SCHOOL',
  'TRINITY CHRISTIAN SCHOOL',
  'TRINITY CHRISTIAN SCHOOL OF FAIRFAX',
  'TRINITY CHRISTIAN SCHOOL OF PALM BEACH GARDENS',
  'TRINITY CHRISTIAN SCHOOLS',
  'TRINITY COLLEGIATE SCHOOL',
  'TRINITY DAY SCHOOL',
  'TRINITY ELEMENTARY',
  'TRINITY ELEMENTARY EAST',
  'TRINITY ELEMENTARY SCHOOLS',
  'TRINITY ELEMENTARY WEST',
  'TRINITY EPISCOPAL DAY SCHOOL',
  'TRINITY EPISCOPAL SCHOOL',
  'TRINITY EPISCOPAL SCHOOL OF AUSTIN',
  'TRINITY EVANGELICAL LUTHERAN',
  'TRINITY EVANGELICAL LUTHERAN CHRCH OF DEL RAY BCH',
  'TRINITY EVANGELICAL LUTHERAN SCHOOL',
  'TRINITY FIRST LUTHERAN SCHOOL',
  'TRINITY HALL',
  'TRINITY HIGH SCHOOL',
  'TRINITY JUNIOR HIGH SCHOOL',
  'TRINITY KINDERGARTEN PROGRAM',
  'TRINITY LEARNING CTR-HOLY',
  'TRINITY LONE OAK LUTHERAN SCHOOL',
  'TRINITY LUTHERAN ACADEMY',
  'TRINITY LUTHERAN ACADEMY -FTL',
  'TRINITY LUTHERAN CHILD CARE CTR & KINDERGARTEN',
  'TRINITY LUTHERAN CHRISTIAN SCHOOL',
  'TRINITY LUTHERAN CHRISTIAN SCHOOL & EARLY LRNG CTR',
  'TRINITY LUTHERAN CHURCH & SCHOOL',
  'TRINITY LUTHERAN CLASSICAL SCHOOL',
  'TRINITY LUTHERAN ELEMENTARY SCHOOL',
  'TRINITY LUTHERAN HIGH SCHOOL',
  'TRINITY LUTHERAN SCHOOL',
  'TRINITY LUTHERAN SCHOOL, MONITOR',
  'TRINITY MONTESSORI SCHOOL',
  'TRINITY NURSERY & KINDERGARTEN',
  'TRINITY NURSERY AND KINDERGARTEN',
  'TRINITY OAKS CHRISTIAN ACADEMY',
  'TRINITY PREPARATORY SCHOOL',
  'TRINITY PREPARATORY SCHOOL INC.',
  'TRINITY PRESCHOOL',
  'TRINITY REGIONAL SCHOOL-EAST NORTHPORT CAMPUS',
  'TRINITY RUN AMISH PAROCHIAL SCHOOL',
  'TRINITY SCHOOL',
  'TRINITY SCHOOL AT GREENLAWN',
  'TRINITY SCHOOL AT MEADOW VIEW',
  'TRINITY SCHOOL AT RIVER RIDGE',
  'TRINITY SCHOOL OF CARTERSVILLE',
  'TRINITY SCHOOL OF DURHAM & CHAPEL HILL',
  'TRINITY SCHOOL OF TEXAS',
  'TRINITY SERVICES -TRINITY SCHOOL',
  'TRINITY TEMPLE ACADEMY',
  'TRINITY UMC PRESCHOOL & KINDERGARTEN',
  'TRINITY UNITED METHODIST SCHOOL',
  'TRINITY VALLEY SCHOOL',
  'TRINITY VICTORIOUS ACADEMY',
  'TRINITY-PAWLING SCHOOL',
  'TRINITY-ST JOHN LUTHERAN SCHOOL',
  'TRINITY-ST LUKES LUTHERAN ELEMENTARY SCHOOL',
  'TRINITY-ST LUKES LUTHERAN MIDDLE SCHOOL',
  'TRINTY',
  'TRIPLE SPRINGS AMISH PAROCHIAL SCHOOL',
  'TRIUMPH ACADEMY',
  'TRIUMPHANT LEARNING CENTER',
  'TRIUMPHANT LIFE CHRISTIAN ACADEMY',
  'TRIVIUM ACADEMY OF NEW JERSEY',
  'TRIVIUM SCHOOL',
  'TROSTLE HILL SCHOOL',
  'TROUT RUN SCHOOL',
  'TROY ACRES',
  'TROY ADVENTIST ACADEMY',
  'TROY CHRISTIAN ELEMENTARY',
  'TROY CHRISTIAN HIGH SCHOOL',
  'TROY HOLINESS SCHOOL',
  'TROY MILL LANE',
  'TROY PAROCHIAL',
  'TROYER RIDGE',
  'TROYER VALLEY',
  'TRU SCHOOL',
  'TRUCKSVILLE EARLY CHILDHOOD EDUCATION CENTER',
  'TRUE CROSS SCHOOL - DICKINSON',
  'TRUE FAITH ACADEMY',
  'TRUE GOSPEL TABERNACLE LEARNING CENTER',
  'TRUE LIGHT CHRISTIAN SCHOOL',
  'TRUE LIGHT SHINES ACADEMY',
  'TRUE NORTH WILDERNESS PROGRAM',
  'TRUE PARTNERSHIP CHRISTIAN ACADEMY',
  'TRUE VINE CHRISTIAN ACADEMY SCHOOL',
  'TRUNK BUTTE CHRISTIAN SCHOOL',
  'TRURO PRESCHOOL & KINDERGARTEN',
  'TRUST MONTESSORI SCHOOL',
  'TRUSTING HEARTS PRESCHOOL & KINDERGARTEN',
  'TRUTH CHRISTIAN ACADEMY',
  'TRUTH CHRISTIAN SCHOOL',
  'TRUTH TABERNACLE CHRISTIAN ACADEMY',
  'TRUTH TABERNACLE CHRISTIAN SCHOOL',
  'TRUTHVILLE CHRISTIAN ACADEMY',
  'TSUMIKI',
  'TUALATIN VALLEY ACADEMY',
  'TUCKAHOE MONTESSORI SCHOOL',
  'TUCKER MAXON SCHOOL',
  'TUCKERTOWN AMISH SCHOOL',
  'TUCSON BAPTIST ACADEMY',
  'TUCSON WALDORF SCHOOL',
  "TULANE MONTESSORI CHILDREN'S HOUSE",
  'TULLAHOMA SDA SCHOOL',
  'TULSA ADVENTIST ACADEMY',
  'TUNNEL RUN SCHOOL',
  'TUPELO CHRISTIAN ACADEMY',
  'TUPELO CHRISTIAN PREPARATORY SCHOOL',
  'TURLOCK CHRISTIAN SCHOOL',
  'TURN ABOUT RANCH',
  'TURNING POINT CHRISTIAN ACADEMY',
  'TURNING POINT CHRISTIAN SCHOOL',
  'TURNING POINT SCHOOL',
  'TURNING POINT SCHOOLS',
  'TURNING POINTE CN DAY SCHOOL',
  'TURNING POINTS SCHOOL',
  'TURNING WINDS ACADEMIC INSTITUTE',
  'TURTLE CREEK LEARNING ACADEMY',
  'TURTLE ROCK PRESCHOOL & KINDERGARTEN',
  'TUSCALOOSA ACADEMY',
  'TUSCALOOSA CHRISTIAN SCHOOL',
  'TUSCARAWAS CENTRAL CATHOLIC ELEMENTARY SCHOOL',
  'TUSCARAWAS CENTRAL CATHOLIC JR/SR HIGH SCHOOL',
  'TUSKAWILLA MONTESSORI ACADEMY',
  'TUTOR ALL SCHOOL',
  'TUTOR TIME',
  'TUTOR TIME CHILD CARE',
  'TVT COMMUNITY DAY SCHOOL',
  'TWIN CITY CHRISTIAN ACADEMY',
  'TWIN CREEK',
  'TWIN ELM SCHOOL',
  'TWIN ELM SCHOOL/AMOS FISHER, CHAIRMAN',
  'TWIN LAKES AMISH SCHOOL',
  'TWIN LAKES CHRISTIAN SCHOOL',
  'TWIN MAPLES SCHOOL',
  'TWIN OAK SCHOOL',
  'TWIN OAKS AMISH SCHOOL',
  'TWIN PARKS MONTESSORI SCHOOL - CENTRAL PARK',
  'TWIN PORTS BAPTIST SCHOOL',
  'TWIN SPRING FARM DAY SCHOOL',
  'TWIN TIERS CHRISTIAN ACADEMY',
  'TWIN VALLEY BIBLE ACADEMY',
  'TWO LOG SCHOOL',
  'TWO SISTERS ANGELS PRESCHOOL',
  'TYGART VALLEY CHRISTIAN ACADEMY',
  'TYLER ADVENTIST SCHOOL',
  'TYLER STREET CHRISTIAN ACADEMY',
  'TYRO COMMUNITY CHRISTIAN SCHOOL',
  'TZU CHI GREAT LOVE ELEMENTARY',
  'T`siya Day School',
  'Ta Wilson School',
  'TaTung University',
  'Tabari Institute of Higher Education',
  'Tabasco El',
  'Tabb Elementary',
  'Tabb High',
  'Tabb Middle',
  'Taberg Residential Center',
  'Tabernacle Elementary',
  'Tabernacle Elementary School',
  'Tabiona High',
  'Tabiona School',
  'Table Mound Elementary School',
  'Table Mountain',
  'Table Rock Elementary',
  'Table Rock Middle',
  'Tabor City Elementary',
  'Tabor City Middle',
  'Tabor College',
  'Tabor Elementary - 05',
  'Tabriz College of Technology',
  'Tabriz Islamic Art University',
  'Tabriz University',
  'Tabriz University of Medical Sciences',
  'Tac Alternative School',
  'Tackan Elementary School',
  'Tacoma Business Academy',
  'Tacoma Community College',
  'Tacoma Pierce County Education Center',
  'Tacoma School Of The Arts',
  'Taconic High',
  'Taconic Hills Elementary School',
  'Taconic Hillsjunior/senior High School',
  'Tacony Academy Charter School',
  'Tadlock El',
  'Tadmore Elementary School',
  'Tadpole Lrn Ctr',
  'Taegu National University of Education',
  'Taegu University',
  'Taejon National University of Technology',
  'Taejon University',
  'Taeshin Christian University',
  'Tafa At Saghalie',
  'Tafila Technical University',
  'Tafolla Middle',
  'Taft Charter High',
  'Taft College',
  'Taft Early Learning Center',
  'Taft El',
  'Taft Elementary',
  'Taft Elementary School',
  'Taft Es',
  'Taft Grade School',
  'Taft H S',
  'Taft High',
  'Taft High School',
  'Taft Intermediate School',
  'Taft J H',
  'Taft Middle',
  'Taft Middle School',
  'Taft Ms',
  'Taft Primary',
  'Taft Primary School',
  'Taft School',
  'Taft T. Newman Leadership Academy',
  'Taft Union High',
  'Taftgalloway Elementary School',
  'Tafuna Elementary',
  'Tafuna High School',
  'Tag Young Scholars',
  'Taganrog State Pedagogical Institute',
  'Taganrog State University of Radioengineering',
  'Taggart John H Sch',
  'Tagos Leadership Acad',
  'Tahanto Regional High',
  'Tahlequah Hs',
  'Tahlequah Ms',
  'Tahoe Elementary',
  'Tahoe Lake Elementary',
  'Tahoe Truckee High',
  'Tahoe Valley Elementary',
  'Tahoka El',
  'Tahoka H S',
  'Tahoka Middle',
  'Taholah Elementary & Middle School',
  'Taholah High School',
  'Tahoma Elementary',
  'Tahoma Jr High',
  'Tahoma Senior High School',
  'Tahquitz High',
  'Tai Solarin University of Education',
  'TaiChung Healthcare and Management University',
  'Taibah University',
  'Taif University',
  'Tainan National College of the Arts',
  'Tainter Elementary',
  'Taipei Medical College',
  'Taipei Municipal Teachers College',
  'Taipei Physical Education College',
  'Taisho University',
  'Taiyuan University of Technology',
  'Taiz University',
  'Taizhou College',
  'Taj International College',
  'Tajik Agrarian University',
  'Tajik National University',
  'Tajikistan International University',
  'Takachiho University',
  'Takamatsu University',
  'Takarazuka University of Art and Design',
  'Takasaki City University of Economics',
  'Takena Elementary School',
  'Takestan Islamic Azad University',
  'Takhar University',
  'Takhatmal Shrivallabh Homoeopathic Medical College',
  'Takini School',
  'Takoma Ec',
  'Takoma Park Elementary',
  'Takoma Park Middle School',
  'Takoradi Polytechnic',
  'Takotna Community School',
  'Taks',
  'Taku Elementary',
  'Takushoku University',
  'Talahi Community Elementary',
  'Talala Elementary School',
  'Talawanda High School',
  'Talawanda Middle School',
  'Talbert (samuel E.) Middle',
  'Talbot Hill Elementary School',
  'Talbot Innovation School',
  'Talbott Elementary',
  'Talbott Springs Elementary',
  'Talbott Steam Innovation School',
  'Talcott Elem School',
  'Talcott Elementary School',
  'Talent Elementary School',
  'Talent Middle School',
  'Talent Unlimited High School',
  'Taliaferro County School',
  'Talihina Es',
  'Talihina Hs',
  'Talihina Jhs',
  'Talkeetna Elementary',
  'Talkington School For Young Women Leaders',
  'Tall Oaks High',
  'Tall Pines Stem Academy',
  'Tallaboa Alta Intermedia',
  'Tallaboa Poniente',
  'Talladega Career Technical Center',
  'Talladega County Central High',
  'Talladega County Genesis School',
  'Talladega High School',
  'Tallahassee Community College',
  'Tallahassee School Of Math & Science',
  'Tallapoosa Primary School',
  'Tallassee Elementary School',
  'Tallassee High School',
  'Talley El',
  'Talley High School',
  'Talley Middle School',
  'Tallinn University',
  'Tallinn University of Technology',
  'Tallmadge Elementary School',
  'Tallmadge High School',
  'Tallmadge Middle School',
  'Tallulah Charter School',
  'Tallulah Elementary School',
  'Tallwood Elementary',
  'Tallwood High',
  'Talmadge Middle School',
  'Talman Elem School',
  'Talofofo Elementary School',
  'Taloga Es',
  'Taloga Hs',
  'Talton K Stone Middle School',
  'Tama Art University',
  'Tama Institute of Management and Information Sciences',
  'Tamagawa University',
  'Tamalpais High',
  'Tamalpais Valley Elementary',
  'Tamanend Ms',
  'Tamaqua Area Ms',
  'Tamaqua Area Shs',
  'Tamaqua El Sch',
  'Tamaques Elementary School',
  'Tamarac Elementary School',
  'Tamarac Middle School High School',
  'Tamarack Elementary',
  'Tamarack Elementary School',
  'Tamarisk',
  'Tamaroa Elem School',
  'Tamassee-salem Elementary',
  'Tambov State Technical University',
  'Tamil Nadu Agricultural University',
  'Tamil Nadu Dr. Ambedkar Law University',
  'Tamil Nadu Dr. M.G.R. Medical University',
  'Tamil Nadu Veterinary and Animal Sciences University',
  'Tamil University',
  'Tamiscal High (alternative)',
  'Tamkang University',
  'Tammany Alternative School',
  'Tampa Bay Boulevard Elementary School',
  'Tampa Bay Tech High School',
  'Tampa Palms Elementary School',
  'Tampa Residential Facility',
  'Tampere Polytechnic',
  'Tampere University of Technology',
  'Tampico Elem School',
  'Tamuning Elementary School',
  'Tamura (hisamatsu) Elementary',
  'Tanacross School',
  'Tanaina Elementary',
  'Tanalian School',
  'Tanana Middle School',
  'Taneytown Elementary',
  'Taneyville Elem.',
  'Tangelo Park Elementary',
  'Tangent Elementary School',
  'Tangi Academy',
  'Tangier Combined',
  'Tangier Smith Elementary School',
  'Tangipahoa Alternative Solutions Program',
  'Tanglewood El',
  'Tanglewood Elem',
  'Tanglewood Elementary',
  'Tanglewood Elementary School',
  'Tanglewood Middle',
  'Tanglewood Regional Center',
  'Tank Elementary',
  'Tannahill Int',
  'Tannehill Public School',
  'Tanner Elem School',
  'Tanner Elementary School',
  'Tanner High School',
  'Tanner Williams Elementary School',
  'Tanners Creek Elementary',
  'Tanque Verde Elementary School',
  'Tanque Verde High School',
  'Tansian University',
  'Tanta University',
  'Tantasqua Regional Jr High',
  'Tantasqua Regional Sr High',
  'Tantasqua Regional Vocational',
  'Tantie Juvenile Residential Facility',
  'Taoist College Singapore',
  'Taos Academy',
  'Taos Cyber Magnet',
  'Taos Day School',
  'Taos High',
  'Taos Integrated School Of Arts',
  'Taos International School',
  'Taos Middle',
  'Taos Municipal Charter',
  'Tap Pk Babies',
  'Tapbi',
  'Taper Avenue Elementary',
  'Tapestry Alop',
  'Tapestry Charter School',
  'Tapestry Public Charter School',
  'Tapp Child Care',
  'Tapp Childcare Center',
  'Tapp Middle School',
  'Tappahannock Elementary',
  'Tappan Middle School',
  'Tappan Zee High School',
  'Tappen Elementary School',
  'Tappen High School',
  'Tapteal Elementary School',
  'Tar Heel Middle',
  'Tar River Academy',
  'Tar River Elementary',
  'Tara Elementary School',
  'Tara Heights Elementary School',
  'Tara High School',
  'Tara Hills Elementary',
  'Tara Oaks Elementary School',
  'Taraba State University',
  'Tarbiat Modares University',
  'Tarboro High',
  'Target Range 7-8',
  'Target Range School',
  'Targu-Mures University of Theatre',
  'Tarhe Trails Elementary School',
  'Tariffville School',
  'Tarkington Elem School',
  'Tarkington H S',
  'Tarkington Int',
  'Tarkington Middle',
  'Tarkington Pri',
  'Tarkington Traditional Center',
  'Tarkio Elem.',
  'Tarkio High',
  'Tarlac State University',
  'Tarleton State University',
  'Tarpey Elementary',
  'Tarpon Springs Elementary School',
  'Tarpon Springs Fundamental Ele',
  'Tarpon Springs High School',
  'Tarpon Springs Middle School',
  'Tarrallton Elementary',
  'Tarrant Co College South/fort Worth Collegiate H S',
  'Tarrant Co J J A E P',
  'Tarrant Co Juvenile Justice Ctr',
  'Tarrant County J J A E P',
  'Tarrant County Jjaep',
  'Tarrant County Jjaep School',
  'Tarrant Elementary School',
  'Tarrant High School',
  'Tarrant Intermediate School',
  'Tarrant Youth Recovery',
  'Tartan Senior High',
  'Tartesso Elementary School',
  'Tartu Health Care College',
  'Tarver El',
  'Tarver Elementary School',
  'Tarver-rendon El',
  'Tarzana Elementary',
  'Tascosa H S',
  'Tashkent Automobile and Roads Institute',
  'Tashkent Institute of Irrigation and Melioration',
  'Tashkent Islam University',
  'Tashkent Medical Academy',
  'Tashkent Pharmaceutical Institute',
  'Tashkent School of Finance',
  'Tashkent State Agrarian University',
  'Tashkent State Technical University',
  'Tashkent State University of Economics',
  'Tashkent State University of Oriental Studies',
  'Tashkent University of Information Technologies',
  'Tashua School',
  'Tassajara Hills Elementary',
  'Tassel Transition',
  'Tata Institute of Social Sciences',
  'Tatanka Elementary',
  'Tate Elementary School',
  'Tate Topa Tribal School',
  'Tate Woods Elem School',
  'Tates Creek Elementary School',
  'Tates Creek High School',
  'Tates Creek Middle School',
  'Tatham',
  'Tatitlek Community School',
  'Tatnuck',
  'Tatom El',
  'Tattnall County High School',
  'Tatum El',
  'Tatum Elementary',
  'Tatum H S',
  'Tatum High',
  'Tatum Jr High',
  'Tatum Middle',
  'Tatum Pri',
  'Tatum Ridge Elementary School',
  'Tatung Institute of Technology',
  'Tau Beta School',
  'Taunton Alternative High School',
  'Taunton Elementary School',
  'Taunton Forge Elementary School',
  'Taunton High',
  'Taurida National V.I.Vernadsky University',
  'Tavan Elementary School',
  'Tavares Elementary School',
  'Tavares High School',
  'Tavares Middle School',
  'Tavelli Elementary School',
  'Tavola El',
  'Tawanka El Sch',
  'Tawas Area High School',
  'Tawas Area Middle School',
  'Tay Nguyen University',
  'Tayac Elementary',
  'Taycheedah Correctional Institution',
  'Taylion High Desert Academy/adelanto',
  'Taylion San Diego Academy',
  'Taylor (bertha) Elementary',
  'Taylor (edward R.) Elementary',
  'Taylor (ida Redmond) Elementary',
  'Taylor Bayard Sch',
  'Taylor Canyon School',
  'Taylor Career And Technical Center',
  'Taylor County Accelerated School',
  'Taylor County Elementary School',
  'Taylor County High School',
  'Taylor County Learning Center',
  'Taylor County Middle School',
  'Taylor County Primary Center',
  'Taylor County Primary School',
  'Taylor County Upper Elementary',
  'Taylor County Vocational Center',
  'Taylor Creek El',
  'Taylor El',
  'Taylor Elem School',
  'Taylor Elementary',
  'Taylor Elementary School',
  'Taylor Exemplar Academy',
  'Taylor H S',
  'Taylor Hicks School',
  'Taylor High School',
  'Taylor Intermediate School',
  'Taylor International Academy',
  'Taylor Junior High School',
  'Taylor Leadership Academy',
  'Taylor Middle',
  'Taylor Middle School',
  'Taylor Mill Elementary School',
  'Taylor Mills School',
  'Taylor Mountain Elementary',
  'Taylor Park Elem School',
  'Taylor Parks Elementary School',
  'Taylor Prairie Elementary',
  'Taylor Preparatory High School',
  'Taylor Ranch Elementary School',
  'Taylor Ray El',
  'Taylor Ridge Elem School',
  'Taylor Road Elementary School',
  'Taylor Road Middle School',
  'Taylor School',
  'Taylor Street Elementary',
  'Taylor Street Elementary School',
  'Taylor University',
  'Taylor Virtual Franchise',
  'Taylor Virtual Instruction Program',
  'Taylor Virtual Learning Academy',
  'Taylor White Elementary School',
  "Taylor's University College",
  'Taylor-richardton Elementary School',
  'Taylors Creek Elementary School',
  'Taylors Crossing Charter School',
  'Taylors Elementary',
  'Taylors Falls Elementary',
  'Taylorsville Attendance Center',
  'Taylorsville Elementary',
  'Taylorsville Elementary School',
  'Taylorsville High',
  'Taylorsville School',
  'Taylorview Middle School',
  'Taylorville Jr High School',
  'Taylorville Primary School',
  'Taylorville Sr High School',
  'Tays J H',
  'Tazewell County Career & Technical Center',
  'Tazewell Elementary',
  'Tazewell High',
  'Tazewell Middle',
  'Tazewell-new Tazewell Elementary',
  'Tba Career Tech Center',
  'Tba Isd Oak Park Special Education',
  'Tba Isd Traverse Heights',
  'Tba Se Early Childhood',
  'Tbd Early Childhood Center',
  'Tbilisi Institute of Asia and Africa',
  'Tbilisi Pedagogical University',
  'Tbilisi State Medical University',
  'Tbilisi State University',
  'Tc Holbrook',
  'Tca College Pathways',
  'Tcaps Montessori School',
  'Tcc Fresh Start',
  'Tccse Early College H S At Timberview',
  'Tchefuncte Middle School',
  'Tcs Alternative School',
  'Te Tsu Geh Oweenge Day School',
  'Tea',
  'Tea Area Frontier Elementary - 05',
  'Tea Area High School - 03',
  'Tea Area Legacy Elementary - 01',
  'Tea Area Middle School - 02',
  'Teach',
  'Teach Academy Of Technologies',
  'Teach Elementary',
  'Teach Tech Charter High',
  'Teacher Training College of Revalidation Rehabilitation and Physical Education',
  'Teachers College Community School',
  'Teachers Preparatory High School',
  "Teachers' Memorial Global Studies Magnet Middle School",
  'Teachwell Academy High School - 01',
  'Teachwell Academy Middle School - 04',
  'Teachwell Transitions - 03',
  'Teague Daep',
  'Teague El',
  'Teague Elementary',
  'Teague H S',
  'Teague Int',
  'Teague J H',
  'Teague Lion Academy',
  'Teague Middle',
  'Teague Middle School',
  'Teague Park School',
  'Team Academy',
  'Team Academy Charter School',
  'Team Charter',
  'Team Charter Academy',
  'Team Englewood Comm Acad Hs',
  'Team High School',
  'Team Sch',
  'Team Success A School Of Excellence',
  'Teams Charter School',
  'Teaneck Community Charter School',
  'Teaneck High School',
  'Teasley Elementary School',
  'Teasley Middle School',
  'Teaticket',
  'Teays Valley East Middle School',
  'Teays Valley High School',
  'Teays Valley West Middle School',
  'Tebughna School',
  'Tec Connections Academy Commonwealth Virtual School',
  'Tech Freire Cs',
  'Tech High',
  'Tech International Charter School',
  'Tech Valley High School',
  'Techboston Academy',
  'Teche Elementary School',
  'Technical & Career Ed. Ctr.',
  'Technical Career Institutes',
  'Technical Careers High School',
  'Technical Center For Careeer & Adult Education',
  'Technical College of the Lowcountry',
  'Technical Education & Career Center',
  'Technical Education Center',
  'Technical Education Center Osceola',
  'Technical Education Center Osceola St Cloud Campus',
  'Technical Education Center Osceola-poinciana Campus',
  'Technical Institute of Dubnica in Dubnica nad Váhom',
  'Technical Senior High',
  'Technical University in Kosice',
  'Technical University in Zvolen',
  'Technical University of Bialystok',
  'Technical University of Budapest',
  'Technical University of Civil Engineering Bucharest',
  'Technical University of Cluj-Napoca',
  'Technical University of Cracow',
  'Technical University of Crete',
  'Technical University of Czestochowa',
  'Technical University of Denmark',
  'Technical University of Gabrovo',
  'Technical University of Gdansk',
  'Technical University of Iasi',
  'Technical University of Kenya',
  'Technical University of Kielce',
  'Technical University of Koszalin',
  'Technical University of Liberec',
  'Technical University of Lodz',
  'Technical University of Lublin',
  'Technical University of Mining and Metallurgy Ostrava',
  'Technical University of Mombasa',
  'Technical University of Opole',
  'Technical University of Poznan',
  'Technical University of Radom',
  'Technical University of Rzeszow',
  'Technical University of Sofia',
  'Technical University of Szczecin',
  'Technical University of Timisoara',
  'Technical University of Varna',
  'Technical University of Wroclaw',
  'Technical University of Zielona Gora',
  'Technikum Wien',
  'Technion - Israel Institute of Technology',
  'Technische Fachhochschule Berlin',
  'Technische Fachhochschule Georg Agricola für Rohstoff Energie und, Umwelt zu Bochum',
  'Technische Fachhochschule Wildau',
  'Technische Hochschule Deggendorf',
  'Technische Universität Bergakademie Freiberg',
  'Technische Universität Berlin',
  'Technische Universität Carolo-Wilhelmina Braunschweig',
  'Technische Universität Chemnitz',
  'Technische Universität Clausthal',
  'Technische Universität Darmstadt',
  'Technische Universität Dresden',
  'Technische Universität Graz',
  'Technische Universität Hamburg-Harburg',
  'Technische Universität Ilmenau',
  'Technische Universität München',
  'Technische Universität Wien',
  'Technological Education Institute of Athens',
  'Technological Education Institute of Epiros',
  'Technological Education Institute of Halkida Euboea',
  'Technological Education Institute of Heraklion',
  'Technological Education Institute of Kalamata',
  'Technological Education Institute of Kavala',
  'Technological Education Institute of Kozani',
  'Technological Education Institute of Lamia',
  'Technological Education Institute of Larissa',
  'Technological Education Institute of Mesologgi',
  'Technological Education Institute of Patras',
  'Technological Education Institute of Piraeus',
  'Technological Education Institute of Serres',
  'Technological Education Institute of Thessaloniki',
  'Technological University (Banmaw)',
  'Technological University (Dawei)',
  'Technological University (Hinthada)',
  'Technological University (Hmawbi)',
  'Technological University (Hpaan)',
  'Technological University (Kalay)',
  'Technological University (Kyaing Tong)',
  'Technological University (Kyaukse)',
  'Technological University (Lashio)',
  'Technological University (Loikaw)',
  'Technological University (Magway)',
  'Technological University (Mandalay)',
  'Technological University (Maubin)',
  'Technological University (Mawlamyaing)',
  'Technological University (Meikhtila)',
  'Technological University (Monywa)',
  'Technological University (Myeik)',
  'Technological University (Myitkyina)',
  'Technological University (Pakokku)',
  'Technological University (Pathein)',
  'Technological University (Pinlon)',
  'Technological University (Pyay)',
  'Technological University (Sittwe)',
  'Technological University (Taunggyi)',
  'Technological University (Taungoo)',
  'Technological University (Thanlyin)',
  'Technological University (West Yangon)',
  'Technological University (Yangon)',
  'Technological University of Iceland',
  'Technological University of Podillya',
  'Technological University of the Philippines',
  'Technology - Engineering & Communications',
  'Technology Access Foundation Academy',
  'Technology Arts And Sciences Studio School',
  'Technology Center',
  'Technology High',
  'Technology High School',
  'Technology Leadership',
  'Technology Middle',
  'Tecnologico de Baja California Universidad',
  'Tecopa-francis Elementary',
  'Tecumseh East Steam Center',
  'Tecumseh Elementary School',
  'Tecumseh High School',
  'Tecumseh Hs',
  'Tecumseh Middle School',
  'Tecumseh Ms',
  'Tecumseh North Early Learning Center',
  'Tecumseh North Elem',
  'Tecumseh Options Institute',
  'Tecumseh Smcsi',
  'Tecumseh South Early Learning Center',
  'Tecumseh South Elem',
  'Tecumseh Virtual Academy',
  'Tecumseh West Steam Center',
  'Tecumseh-harrison Elementary Sch',
  'Ted Flores El',
  'Ted Hunsberger Elementary School',
  'Ted Porter Elementary',
  'Tedder Elementary School',
  'Teddy Bear Preschool',
  'Teeland Middle School',
  'Teen Parent Indiantown',
  'Teen Parent Program - Pk',
  'Teen Parent Program Pk',
  'Teen Parent Service Center',
  'Teenage Parent Program',
  'Teenage Parent Program - Tapp',
  'Tefft Middle School',
  'Tega Cay Elementary',
  'Tegeler Career Center',
  'Tehachapi High',
  'Tehama County Juvenile Justice Center',
  'Tehama County Rop',
  'Tehama County Special Education',
  'Tehama Elearning Academy',
  'Tehipite Middle',
  'Tehran University of Medical Sciences',
  'Tehran University of Teacher Training',
  'Teikyo Heisei University',
  'Teikyo University of Science and Technology',
  'Tejas School Of Choice',
  'Tejeda Junior Academy',
  'Tejeda Middle',
  'Tekoa Academy Of Accelerated Studies',
  'Tekoa Academy Of Accelerated Studies - Orange',
  'Tekoa Elementary School',
  'Tekoa High School',
  'Tekonsha Schools',
  'Tekoppel Elementary School',
  'Tel Aviv University',
  'Telegraph Intermediate',
  'Telesforo Velez Oliver',
  'Telesis Academy Of Science & Math',
  'Telesis Preparatory',
  'Telesis Preparatory Academy',
  'Telfair Alternative Preparation School (taps)',
  'Telfair Avenue Elementary',
  'Telfair County Elementary',
  'Telfair County High School',
  'Telfair County Middle School',
  'Tell City Jr-sr High School',
  'Teller Elementary School',
  'Telles Academy',
  'Telles Academy J J A E P',
  'Tellico Plains Elementary',
  'Tellico Plains High School',
  'Tellico Plains Junior High School',
  'Telluride Elementary School',
  'Telluride High School',
  'Telluride Intermediate School',
  'Telluride Middle School',
  'Telpochcalli Elem School',
  'Telstar Es',
  'Telstar High School',
  'Telstar Middle School',
  'Telugu University',
  'Temecula Elementary',
  'Temecula International Academy',
  'Temecula Luiseno Elementary',
  'Temecula Middle',
  'Temecula Preparatory',
  'Temecula Valley Charter',
  'Temecula Valley High',
  'Temescal Canyon High',
  'Temescal Valley Elementary',
  'Tempe Academy Of International Studies Mckemy Campus',
  'Tempe High School',
  'Tempe Preparatory Academy',
  'Tempe Preparatory Junior Academy',
  'Tempe Thomas J. Pappas Regional Elementary School',
  'Temperance Elementary',
  'Temperance-kutner Elementary',
  'Temple (roger W.) Intermediate',
  'Temple Academy',
  'Temple Charter Academy',
  'Temple City Alternative',
  'Temple City High',
  'Temple College',
  'Temple Elementary School',
  'Temple Es',
  'Temple H S',
  'Temple High School',
  'Temple Hill Elementary',
  'Temple Hill Elementary School',
  'Temple Hill School',
  'Temple Hs',
  'Temple Middle School',
  'Temple Terrace Elementary School',
  'Temple University',
  'Temple University Japan',
  'Temple View Elementary School',
  'Templeton Center',
  'Templeton Elementary',
  'Templeton Elementary School',
  'Templeton High',
  'Templeton Home',
  'Templeton Independent Study High',
  'Templeton Middle',
  'Ten Oaks Middle',
  'Ten Sleep K-12',
  'Tenafly High School',
  'Tenafly Middle School',
  'Tenaha Schools',
  'Tenakill Middle School',
  'Tenaya Elementary',
  'Tenaya Middle',
  'Tench Tilghman Elementary',
  'Tenderloin Community',
  'Tendoy Elementary School',
  'Tenie Holmes El',
  'Tenino Elementary School',
  'Tenino High School',
  'Tenino Middle School',
  'Tenkiller Public School',
  'Tenn School For Blind',
  'Tennerton Elementary',
  'Tennessee Elementary School For Deaf',
  'Tennessee High School',
  'Tennessee High School For Deaf',
  'Tennessee Middle School For The Deaf',
  'Tennessee Online Public School',
  'Tennessee Ridge Elementary',
  'Tennessee State University',
  'Tennessee Technological University',
  'Tennessee Virtual Academy',
  'Tennessee Virtual Learning Academy',
  'Tennessee Virtual On-line School',
  'Tenney Grammar School',
  'Tenniswood Elementary School',
  'Tennyson Elementary School',
  'Tennyson High',
  'Tennyson Knolls Elementary School',
  'Tennyson Middle',
  'Tenor High',
  'Tenoroc High School',
  'Tenri University',
  'Tensas Elementary School',
  'Tensas High School',
  'Tenth & Green El Sch',
  'Tenth & Penn El Sch',
  'Tenth Street El Sch',
  'Tenth Street Elementary',
  'Tenth Street Elementary School',
  'Tenth Street School',
  'Teodomiro Taboas',
  'Teodoro Aguilar Mora',
  'Teodoro Roosevelt',
  'Teofilo Mendoza',
  'Tequesta Trace Middle School',
  'Teravista El',
  'Terence C. Reilly School # 7',
  'Teresa Burke Elementary',
  'Teresa C Berrien Elementary',
  'Teresa C Hamlin Elem School',
  'Teresa Hughes Elementary',
  'Teresa Prestamo',
  'Terman Middle',
  'Terminal Park Elementary School',
  'Ternopil Academy of National Economy',
  "Ternopil State Ivan Pul'uj Technical University",
  'Terra Academy',
  'Terra Alta/east Preston School',
  'Terra Bella Elementary',
  'Terra Centre Elementary',
  'Terra Cotta Middle',
  'Terra Environmental Research Institute',
  'Terra Linda Elementary School',
  'Terra Linda High',
  'Terra Linda School',
  'Terra Nova High',
  'Terra State Community College',
  'Terra Vista Elementary',
  'Terra Vista Middle',
  'Terrace Community Middle School',
  'Terrace El',
  'Terrace Elem School',
  'Terrace Elementary',
  'Terrace Elementary School',
  'Terrace Heights Elementary',
  'Terrace Hills Middle',
  'Terrace Manor Elementary School',
  'Terrace Middle',
  'Terrace Park Elementary',
  'Terrace Park Elementary School',
  'Terrace View Elementary',
  'Terral Public School',
  'Terramar Elementary',
  'Terrant County College',
  'Terraset Elementary',
  'Terre Haute North Vigo High School',
  'Terre Haute South Vigo High School',
  'Terre Town Elementary School',
  'Terrebonne Community School',
  'Terrebonne High School',
  'Terrell Alternative Education Center/phoenix Sch',
  'Terrell El',
  'Terrell Elementary',
  'Terrell H S',
  'Terrell High School',
  'Terrell Lane Middle',
  'Terrell Middle School',
  'Terrell Regional Youth Detention Center',
  'Terrell Wells Middle',
  'Terreton Elementary School',
  'Terrill Middle School',
  'Territorial Early Childhood Center',
  'Territorial Elementary School',
  'Terry Elementary',
  'Terry Elementary School',
  'Terry High School',
  'Terry Middle',
  'Terry Middle School',
  'Terry Parker High School',
  'Terry Redlin Elementary - 11',
  'Terry Sanford High',
  'Terry School',
  'Terry Taylor Elementary School',
  'Terrytown Elementary School',
  'Terryville High School',
  'Terryville Road School',
  'Tesago Elementary School',
  'Tescott Elem',
  'Tescott Junior High/high School',
  'Tesfa International School',
  'Tesla Engineering Charter School',
  'Tesla Stem High School',
  'Tesnatee Gap Elementary (old White Co. Intermediate)',
  'Tesoro Del Valle Elementary',
  'Tesoro High',
  'Tess Corners Elementary',
  'Test Intermediate School',
  'Tesuque Elementary',
  'Tetlin School',
  'Teton Elementary School',
  'Teton High School',
  'Teton Middle School',
  'Tetonia Elementary School',
  'Tetzlaff (martin B.) Middle',
  'Teutopolis Grade School',
  'Teutopolis High School',
  'Teutopolis Junior High School',
  'Tevis Junior High',
  'Tewksbury Elementary School',
  'Tewksbury Memorial High',
  'Texarkana Area Vocational Center',
  'Texarkana College',
  'Texas A&M International University',
  'Texas A&M University - College Station',
  'Texas A&M University - Commerce',
  'Texas A&M University - Corpus Christi',
  'Texas A&M University - Kingsville',
  'Texas Academy Of Biomedical',
  'Texas Avenue School',
  'Texas Christian University',
  'Texas City H S',
  'Texas City J J A E P',
  'Texas Connections Academy At Houston',
  'Texas Early College H S',
  'Texas Empowerment Academy',
  'Texas Empowerment Academy El',
  'Texas H S',
  'Texas Leadership Of Abilene',
  'Texas Leadership Of Arlington',
  'Texas Leadership Of Midland',
  'Texas Middle',
  'Texas Online Preparatory El',
  'Texas Online Preparatory H S',
  'Texas Online Preparatory Middle',
  'Texas Preparatory School',
  'Texas Preparatory School - Austin Campus',
  'Texas School For The Blind',
  'Texas School For The Deaf',
  'Texas School Of The Arts',
  'Texas Serenity Academy',
  'Texas Serenity Academy - Gano',
  'Texas Southern University',
  'Texas State Technical College-​Harlingen',
  'Texas State Technical College-​Marshall',
  'Texas State Technical College-​Waco',
  'Texas State Technical College-​West Texas',
  'Texas State University',
  'Texas Tech University',
  'Texas Tech University-Health Sciences Center',
  'Texas Virtual Academy',
  "Texas Woman's University",
  'Texhoma El',
  'Texhoma Es',
  'Texhoma Hs',
  'Texico Elementary',
  'Texico High',
  'Texico Middle',
  'Texila American University',
  'Texline School',
  'Textile Institute of Pakistan',
  'Tezpur University',
  'Tezukayama Gakuin University',
  'Tezukayama University',
  'Tgu-granville Elementary School',
  'Tgu-granville High School',
  'Tgu-towner Elementary School',
  'Tgu-towner High School',
  'Th Johnson El',
  'Thacker Avenue Elementary For International Studies',
  'Thackerville Es',
  'Thackerville Hs',
  'Thackerville Ms',
  'Thaddeus Stevens College of Technology',
  'Thai Nguyen University of Agriculture and Forestry',
  'Thaksin University',
  'Thalberg Elementary School',
  'Thalia Elementary',
  'Thamar University',
  'Thames Elementary School',
  'Thames Valley University',
  'Thammasat University',
  'Thapar Institute of Engineering and Technology',
  'Tharptown Elementary School',
  'Tharptown High School',
  'Thatcher Brook Primary School',
  'Thatcher Elementary School',
  'Thatcher High School',
  'Thatcher Middle School',
  'Thayer Central Elementary',
  'Thayer Central High School',
  'Thayer Elem.',
  'Thayer J Hill Middle School',
  'Thayer Schools',
  'Thayer Sr. High',
  'Thayne Elementary',
  'The Acad Of Sci & Entrepreneurship',
  'The Academic Recovery Center Of San Luis Valley',
  'The Academies At Jonesboro High School',
  'The Academies Of West Memphis Charter School',
  'The Academy',
  'The Academy @ Shawnee',
  'The Academy At 11th Street',
  'The Academy At Forest View',
  'The Academy At Hickory Hollow',
  'The Academy At Mingus',
  'The Academy At Nola Dunn',
  'The Academy At Old Cockrill',
  'The Academy At Opry Mills',
  'The Academy At Youth Care Lane',
  'The Academy Community Day',
  'The Academy For Urban Scholars',
  'The Academy Of Alameda',
  'The Academy Of Alameda Elementary',
  'The Academy Of Early Learning At North Parish',
  'The Academy Of Information Technology',
  'The Academy Of International Studies At Rosemont',
  'The Academy Of Moore County',
  'The Academy of Public Administration',
  'The Access School At The Danuta M. Thibodeau Education Cent',
  'The Achievers University',
  'The Alb Talent Development Charter',
  'The Alberta School Of Performing Arts',
  'The Alpha School',
  'The Alternative Program (tap)',
  'The Alva School',
  'The American University in London',
  'The Anicca Program',
  'The Ann Windle School For Young Children',
  'The Arch Community School',
  'The Art Institutes',
  'The Arts Based School',
  'The Ask Academy',
  'The Autism Academy Of Learning',
  "The Bahá'í Institute For Higher Education (BIHE)",
  'The Barack Obama Green Charter High School',
  'The Bear Blvd School',
  'The Bijou School',
  'The Biome',
  'The Birches Academy Of Academics - Art A Public Charter Sch',
  'The Bloomington Project School',
  'The Bostic Head Start Center',
  'The Boys School Of Denver',
  'The Bridge',
  'The Bridge Academy',
  'The Bridge School',
  'The Bridgeport School District - Elementary',
  'The Bridgeport School District - High School',
  'The Bridgeport School District - Middle School',
  'The Bridges Acad Alter',
  'The Bronx School Of Young Leaders',
  'The Brook-dupont',
  'The Brook-kmi',
  'The Burnham School',
  'The CTL Eurocollege',
  'The Cannon Beach Academy',
  'The Capella Institute',
  'The Capitol Encore Academy',
  'The Career Academy',
  'The Career Center Of The Scesc',
  'The Center For Creativity Innovation And Discovery',
  'The Center For Technology - Essex',
  'The Center School',
  'The Central Academy',
  'The Champion Middle Theme School',
  'The Charter School At Waterstone',
  "The Children's Reading Center",
  "The Children's School At Sylvia Circle",
  'The Childrens Guild Pcs',
  'The Chiles Academy',
  'The Chinese School',
  'The Citadel',
  'The City',
  'The Classical Academy Charter',
  'The Classical Academy High School',
  'The Classical Academy Middle School',
  'The Cleveland Academy Of Leadership',
  'The Cohn Learning Center',
  'The Collaboratory Preparatory Academy',
  'The College And Career Academy At Pruden',
  'The College of New Jersey',
  'The College of The Bahamas',
  'The Colony H S',
  'The Colwyck Center',
  'The Communications Program',
  'The Community Learning Center @ Pinckney',
  'The Community Roots School',
  'The Community School',
  'The Compass School',
  'The Connect Charter School',
  'The Conservatory School At North Palm Beach',
  'The Cottage At Holly Ridge',
  'The Cottonwood School Of Civics And Science',
  'The Cougars Den',
  'The Creeks',
  'The Crossroads School',
  'The Da Vinci Academy School',
  'The Dalles High School',
  'The Dalles Middle School',
  'The Davidson Academy Of Nevada',
  'The Dearborn Academy',
  'The Discovery School',
  'The District 54 Early Lrning Ctr',
  'The Dove (developing Opportunities Thru Voc. Ed.)',
  'The Downtown School',
  'The Dufrocq School',
  'The Early Career Academy',
  'The Early Childhood Center @ Forest Glen',
  'The Early College Lansing Community College',
  'The East Austin College Prep At Mlk',
  'The East Austin College Prep At Southwest Key',
  'The Education Corps',
  'The Einstein School Inc.',
  'The Emirates Academy of Hotel Managment',
  'The English Center',
  'The English High',
  'The Equity Project Charter School',
  'The Ethical Community Charter School',
  'The Excel Center',
  'The Excel Center (for Adults)',
  'The Expedition School',
  'The Experiential School Of Greensboro',
  'The Exploris School',
  'The Fair School - Crystal',
  'The Family School',
  'The Farm At Mission Montessori Academy',
  'The Federal Polytechnic Offa',
  'The Florida Center For Child And Family Development',
  'The Foundation School For Autism',
  'The Founders Academy Charter School (e)',
  'The Founders Academy Charter School (h)',
  'The Franklin School Of Innovation',
  'The French School',
  'The Friendship School',
  'The Gateway To College',
  'The Georgetown School',
  'The Gilbert School',
  'The Global College Lahore',
  'The Gloria Dusek Compass School',
  "The Governor's Career And Technical Education Academy For St",
  "The Governor's School @ Innovation Park",
  'The Grace England Early Childhood/pre-k Center',
  'The Grande Innovation',
  'The Gray Charter School',
  'The Great Academy',
  'The Green School',
  'The Greene School',
  'The Greenspire School',
  'The Haley School',
  'The Hanover Center For Trades And Technology',
  'The Hawbridge School',
  'The Healing Lodge',
  'The Heights Charter',
  'The Heights Community School',
  'The High School At Moorpark College',
  'The Historic Samuel Coleridge-taylor Elementary',
  'The Hope Academy',
  'The Hope Charter Center For Autism',
  'The Howard School',
  'The Incubator',
  'The Innovation Academy',
  'The Innovation Academy At South Campus',
  'The Institute Development Young Leaders',
  'The Integrated Preschool Program',
  'The Interdisciplinary Center Herzliya',
  'The International School',
  'The International School At Gregory',
  'The International School At Thornton Middle',
  'The Island School',
  'The Ivy School',
  'The James And Grace Lee Boggs School',
  'The Jewish Theological Seminary',
  'The Joe Barnhart Academy',
  'The John Hope-charles Walter Hill Elementary Schools',
  'The Joseph Sears School',
  'The Journey School',
  'The Jp Knapp Ech (i)school',
  'The Judy Jester Learning Center',
  'The Juniper School',
  'The Kindergarten Learning Center',
  'The Kingdom Charter School Of Leadership',
  'The Kingdom University',
  'The Lafayette Academy',
  'The Lane Elem School',
  'The Language Academy Of Sacramento',
  'The Larry Brown School',
  'The Lawson Academy',
  'The Leadership Academy',
  'The Learning Academy At The Els Center Of Excellence',
  'The Learning Center',
  'The Learning Center At East Hampton',
  'The Learning Center At The Els Center Of Excellence',
  'The Learning Center Developmental Preschool',
  'The Learning Community',
  'The Learning Ctr',
  'The Life Connection',
  'The Linc',
  'The Lincoln Center',
  'The Lion Lane School',
  "The Loudoun Governor's Career & Technical Academy",
  'The Lynch Center',
  'The Manchester Metropolitan University',
  'The Mary Catherine Harris School-school Of Choice',
  'The Master Program',
  'The Max Charter School',
  'The Meadows El',
  'The Met',
  'The Mind Program High School',
  'The Montessori School Of Camden',
  'The Montrose Early Childhood Center',
  'The Morgan School',
  'The Mount Washington School',
  'The Mountain Community Sch',
  'The Nbisd Learning Center',
  'The Net 2 Charter High School',
  'The Net Charter High School',
  'The New College',
  'The New Haven School',
  'The New Path High School',
  'The New School Of San Francisco',
  'The New Standard Academy',
  'The Nokesville School',
  'The Northside Preschool',
  "The O'farrell Charter",
  'The Oaks Academy',
  'The Odyssey Preparatory Academy',
  'The Odyssey Preparatory Academy - Sienna Hills',
  'The Odyssey Preparatory Academy Goodyear',
  'The Olive Tree Montessori Academy',
  'The One Room School House Project',
  'The Outpost',
  'The Outreach Program',
  'The Pace Program',
  'The Paideia Academy Of South Phoenix',
  'The Palmetto School At The Children?s Attention Home',
  'The Panda Path School',
  'The Paris Academy',
  'The Pathway',
  'The Patriot Center',
  'The Peak School',
  'The Pell Annex @ John F. Kenne',
  'The Pembroke Pines Florida',
  'The Petroleum Institute',
  'The Philadelphia Cs For Arts And Science',
  'The Phoenix Academy',
  'The Phoenix Program Naples',
  'The Phoenix Program-immokalee',
  'The Phoenix School',
  'The Phoenix School Of Discovery',
  'The Pinnacle Charter School Elementary',
  'The Pinnacle Charter School High',
  'The Pinnacle Charter School Middle',
  'The Plains Elementary School',
  'The Port Academy',
  'The Principia',
  'The Providence School',
  'The Queen City Academy Charter School',
  "The Queen's University Belfast",
  'The Quest Center',
  'The R.y.s.e School',
  'The Rac Center',
  'The Reach! Partnership School',
  'The Reading Edge Academy',
  'The Real Sch',
  'The Regional Career And Tech',
  'The Regional Community Alternative Ed Continuum',
  'The Renaissance Academy',
  'The Renaissance Center',
  'The Renaissance Center For Academic And Career Development',
  'The Rhodes School - Humble',
  'The Rhodes School - Northshore',
  'The Rhodes School - Nw',
  'The Rhodes School - Southeast',
  'The Richland School Of Academic Arts',
  'The Rising School',
  'The Robert Gordon University',
  'The Robert L. Arvin Education Center',
  'The Sanibel School',
  'The School For Creative Studies',
  'The School Of Arts & Science Centre',
  'The School Of Arts And Science On Thomasville',
  'The School Of Liberal Studies At Savannah High',
  'The School Of One',
  'The Science Academy Of South Texas',
  'The Science Leadership Academy At Beeber',
  'The Score Center',
  'The Sd Of Philadelphia Virtual Academy',
  'The Seed School Of Maryland',
  'The Seed School Of Miami',
  'The Shelby School',
  'The Skokie School',
  "The Special Children's School",
  'The Spring',
  'The Springfield Renaissance School An Expeditionary Learning',
  'The Stables',
  'The Stem Academy At Bartlett',
  'The Sterling School',
  'The Studio School',
  'The Success Academy',
  'The Summit (high School)',
  'The Summit (intermediate)',
  'The Superior College',
  'The T.e.c.h. Center',
  'The Tiger Trail School',
  'The U School: Innovative Lab',
  'The University of Nottingham Ningbo China',
  'The Valley School Of Southern Oregon',
  'The Vanguard School (elementary)',
  'The Vanguard School (high)',
  'The Vanguard School (middle)',
  'The Varnett School - East',
  'The Varnett School - Northeast',
  'The Varnett School Southeast',
  'The Village Charter School',
  'The Villages Elementary Of Lady Lake School',
  'The Wallace Wallin School',
  'The Webster School',
  'The Wheatlands Elementary School',
  'The Wildcat Way School',
  'The Willows',
  'The Woodlands H S',
  'The Workshop School',
  'The World Islamic Sciences & Education University',
  'The Wright Place',
  'Thea Bowman Leadership Academy',
  'Theatre Academy Finland',
  'Theatre Arts Production Company School',
  'Thedford Elem Attendance Cntr',
  'Thedford High School',
  'Theisen Middle',
  'Theiss El',
  'Thelma B. Bedell Elementary',
  'Thelma B. Johnson Early Learning Center',
  'Thelma Barker Elementary',
  'Thelma Crenshaw Elementary',
  'Thelma Elizabeth Page Richardson El',
  'Thelma Jones El',
  'Thelma L. Sandmeier Elementary School',
  'Thelma R. Parks Es',
  'Thelma Rosa Salinas Stem Early College H S',
  'Thena Crowder Early Childhood Diagnostic Special Education C',
  'Theo Roosevelt Elem',
  'Theo V Eddy Elementary School',
  'Theodore Bird High',
  'Theodore G. Davis Middle School',
  'Theodore Herberg Middle',
  'Theodore High School',
  'Theodore Jamerson Elementary',
  'Theodore Jones Elem. School',
  'Theodore Judah Elementary',
  'Theodore Lester Elementary',
  'Theodore Potter School 74',
  'Theodore R Durgee Junior High School',
  'Theodore R. And Thelma A. Gibson Charter School',
  'Theodore Robinson Intermediate School',
  'Theodore Roosevelt',
  'Theodore Roosevelt Car & Tech Acad',
  'Theodore Roosevelt Elem School',
  'Theodore Roosevelt Elementary',
  'Theodore Roosevelt Elementary School',
  'Theodore Roosevelt High School',
  'Theodore Roosevelt Middle',
  'Theodore Roosevelt Middle School',
  'Theodore Roosevelt School',
  'Theodore Roosevelt School No. 10',
  'Theodore Roosevelt Senior High',
  'Theodore Schor Middle School',
  'Theodore Vick Elementary',
  'Theologische Fakultät Paderborn',
  'Theologische Fakultät Trier',
  'Theologische Hochschule Friedensau',
  'Therapeutic Academic Program',
  'Therapeutic Day Program',
  'Therapeutic Day School',
  'Therapeutic Education Program',
  'Therapeutic Educational Alternative Model (team)',
  'Therapeutic Educational Setting (tep)',
  'Therapeutic Learning Center',
  'Theresa Bunker Elementary School',
  'Theresa Elementary',
  'Theresa Primary School',
  'Thermopolis Middle School',
  'Theron H & Naomi D Goynes Elementary School',
  'Theron Jones Early Literacy Center',
  'Theron L Swainston Middle School',
  'Therrell High School',
  'Thetford Elementary School',
  'Theuerkauf Elementary',
  'Theunis Dey Elementary School',
  'Thi Qar University',
  'Thiagarajar College of Engineering',
  'Thibodaux Elementary School',
  'Thibodaux High School',
  'Thiells Elementary School',
  'Thierry Graduate School of Leadership',
  'Thigpen-zavala El',
  'Think Academy-together Helping Inspire Kids',
  'Think College Now',
  'Third Avenue El Sch',
  'Third Creek Elementary',
  'Third Street Elementary',
  'Third Street Elementary School',
  'Third University of Rome',
  'Thirkell Elementary School',
  'Thirteen Acres Special Education Center',
  'Thirteenth & Green El Sch',
  'Thirteenth & Union El Sch',
  'Thirteenth Avenue School',
  'Thirty-fourth Ave. Elem. Sch.',
  'Thirty-second Street Usc Performing Arts',
  'Thiruvalluvar University',
  'Thoman Ranch Elementary',
  'Thomas A Edison Career And Technical High School',
  'Thomas A Edison Elem',
  'Thomas A Edison Elem School',
  'Thomas A Edison Elementary School',
  'Thomas A Edison High School',
  'Thomas A Edison Jr-sr Hs',
  'Thomas A Edison Middle Learning Center',
  'Thomas A Edison School',
  'Thomas A Hendricks Elementary Sch',
  'Thomas A. Edison Career And Technical Academy',
  'Thomas A. Edison Elementary',
  'Thomas A. Edison Elementary School',
  'Thomas A. Edison Middle',
  'Thomas Academy',
  'Thomas Armstrong Toro',
  'Thomas Arnold El',
  'Thomas B. Chinn Elem.',
  'Thomas B. Conley Elementary School',
  'Thomas B. Moffitt Elementary',
  'Thomas Benton Elem.',
  'Thomas Blake Middle',
  'Thomas Buzbee Vocational School',
  'Thomas C Giordano Middle School 45',
  'Thomas C Marsh Middle',
  'Thomas C. Boushall Middle',
  'Thomas C. Cario Middle',
  'Thomas C. Mcswain Elementary',
  'Thomas Carr Howe Comm High School',
  'Thomas Carroll',
  'Thomas College',
  'Thomas Cornell Academy',
  'Thomas County Central High School',
  'Thomas County Middle School',
  'Thomas Crossroads Elementary School',
  'Thomas D Gregg School 15',
  'Thomas Dale High',
  'Thomas Ditson',
  'Thomas Dooley Elem School',
  'Thomas Downey High',
  'Thomas E. Bowe School',
  'Thomas E. Kerns Elementary',
  'Thomas E. Mathews Community',
  'Thomas E. Weightman Middle School',
  'Thomas Early Childhood Center',
  'Thomas Eaton Middle',
  'Thomas Edison - North',
  'Thomas Edison - South',
  'Thomas Edison Elem School',
  'Thomas Edison Elementary',
  'Thomas Edison Energysmart Charter School',
  'Thomas Edison High School Of Technology',
  'Thomas Edison Intermediate School',
  'Thomas Edison Language Institute K-8',
  'Thomas Edison Magnet Middle School',
  'Thomas Edison Preparatory Hs',
  'Thomas Edison State College',
  'Thomas Edison State University',
  'Thomas El',
  'Thomas Elementary',
  'Thomas Elementary School',
  'Thomas Es',
  'Thomas Ewing Junior High School',
  'Thomas Fleming School',
  'Thomas G. Connors',
  'Thomas G. Pullen School',
  'Thomas H. Dudley Family School',
  'Thomas Hancock El',
  'Thomas Harrison Middle',
  'Thomas Hart Benton Elem.',
  'Thomas High School',
  'Thomas Hooker School',
  'Thomas Hunter Middle',
  'Thomas Ies',
  'Thomas J Kenny',
  'Thomas J Lahey Elementary School',
  'Thomas J Mcmahon Elementary Sch',
  'Thomas J Rusk El',
  'Thomas J Rusk Middle',
  'Thomas J Watson Sr Elementary School',
  'Thomas J. Pappas Regional High School',
  'Thomas J. Ultican Elem.',
  'Thomas Jefferson Arts Academy',
  'Thomas Jefferson Charter',
  'Thomas Jefferson Class Academy',
  'Thomas Jefferson Early Learning Center',
  'Thomas Jefferson El Sch',
  'Thomas Jefferson Elem Sch',
  'Thomas Jefferson Elem School',
  'Thomas Jefferson Elem. School',
  'Thomas Jefferson Elementary',
  'Thomas Jefferson Elementary School',
  'Thomas Jefferson Es',
  'Thomas Jefferson H S',
  'Thomas Jefferson High',
  'Thomas Jefferson High For Science And Technology',
  'Thomas Jefferson High School',
  'Thomas Jefferson High School For Advanced Studies',
  'Thomas Jefferson Hs',
  'Thomas Jefferson Int',
  'Thomas Jefferson Jr High',
  'Thomas Jefferson Jr High School',
  'Thomas Jefferson Middle',
  'Thomas Jefferson Middle School',
  'Thomas Jefferson Primary School',
  'Thomas Jefferson School',
  'Thomas Jefferson School No. 1',
  'Thomas Jefferson Senior High',
  'Thomas Jefferson University',
  'Thomas Johnson Elementary',
  'Thomas Johnson Middle',
  'Thomas K Beecher School',
  'Thomas Kelly Elementary',
  'Thomas L Marsalis El',
  'Thomas L Reeves Elementary School',
  'Thomas L Sims Middle School',
  'Thomas Lake Elementary',
  'Thomas Law Reed Elementary',
  'Thomas M Balliet',
  'Thomas M Knoles Elementary School',
  'Thomas M Ryan Intermediate School',
  'Thomas Maclaren State Charter School',
  'Thomas Magnet',
  'Thomas Manor',
  'Thomas Metcalf School',
  'Thomas Middle',
  'Thomas Middle School',
  'Thomas Miller Elementary School',
  'Thomas More College',
  'Thomas Nelson Community College',
  'Thomas Nelson High School',
  "Thomas O'roarke Elementary School",
  'Thomas O. Smith School',
  'Thomas Olaeta Elementary',
  'Thomas P. Hughes School',
  'Thomas P. Ryan Elementary',
  'Thomas Page Academy',
  'Thomas Paine Elem School',
  'Thomas Paine Elementary',
  'Thomas Paine Elementary School',
  'Thomas Prince',
  'Thomas R Fowler Middle School',
  'Thomas R Grover Middle School',
  'Thomas R Plympton Elementary School',
  'Thomas R Proctor High School',
  'Thomas R Rodman',
  'Thomas R. Pollicita Middle',
  'Thomas Read Elementary School',
  'Thomas Richards Elementary',
  'Thomas Riley High',
  'Thomas Russell Middle',
  "Thomas S O'brien Academy Of Science & Technology",
  'Thomas S. Hart Middle',
  'Thomas S. Stone Elementary',
  'Thomas S. Wootton High',
  'Thomas Sharp Elementary School',
  'Thomas Simpson Middle School',
  'Thomas Starr King Middle School Film And Media Magnet',
  'Thomas Stone High School',
  'Thomas Street Elementary School',
  'Thomas Tolbert El',
  'Thomas V Nash',
  'Thomas Viaduct',
  'Thomas W Holtzman Jr El Sch',
  'Thomas W. Bacchus School',
  'Thomas W. Hamilton Primary School',
  'Thomas W. Kelly High',
  'Thomas W. Mahan School',
  'Thomas W. Pyle Middle School',
  'Thomas W. Wallace Jr. Middle School',
  'Thomas Walker High',
  'Thomas Wharton El Sch',
  'Thomas White School',
  'Thomas Willett Elementary School',
  'Thomas Worthington High School',
  'Thomas-fay-custer Unified Hs',
  'Thomas-fay-custer Unified Jhs',
  'Thomasboro Academy',
  'Thomasboro Grade School',
  'Thomaston Center School',
  'Thomaston Grammar School',
  'Thomaston High School',
  'Thomasville Elementary School',
  'Thomasville Heights Elementary School',
  'Thomasville High',
  'Thomasville High School',
  'Thomasville Middle',
  'Thomasville Middle School',
  'Thomasville Primary',
  'Thome Ed School',
  'Thompkins Middle School',
  'Thompson',
  'Thompson Brook School',
  'Thompson Correctional Center',
  'Thompson Crossing Elementary Sch',
  'Thompson El',
  'Thompson Elementary',
  'Thompson Elementary School',
  'Thompson Falls 7-8',
  'Thompson Falls Elem Schl',
  'Thompson Falls High Schl',
  'Thompson High School',
  'Thompson Instructional Center',
  'Thompson Int',
  'Thompson Integrated Early Childhood',
  'Thompson Intermediate School',
  'Thompson Jr High School',
  'Thompson K8 International Academy',
  'Thompson Middle',
  'Thompson Middle School',
  'Thompson Montessori',
  'Thompson Preschool',
  'Thompson Ranch Elementary',
  'Thompson Rivers University',
  'Thompson Sixth Grade Center',
  'Thompson Valley High School',
  'Thompsontown-delaware El Sch',
  'Thompsonville Grade School',
  'Thompsonville High School',
  'Thomson',
  'Thomson Elementary School',
  'Thomson Es',
  'Thomson Estates Elementary',
  'Thomson High School',
  'Thomson Middle School',
  'Thomson Primary School',
  'Thomson-mcduffie Middle School',
  'Thonotosassa Elementary School',
  'Thoreau',
  'Thoreau Demonstration Academy',
  'Thoreau Elementary',
  'Thoreau High',
  'Thoreau Middle',
  'Thoreau Park Elementary School',
  'Thornapple Elementary School',
  'Thornapple Kellogg High School',
  'Thornapple Kellogg Middle School',
  'Thornburg Middle',
  'Thorndale El',
  'Thorndale H S',
  'Thorndale Middle',
  'Thorndyke Elementary',
  'Thorndyke Road',
  'Thorne Bay School',
  'Thorne Elementary School',
  'Thorne Middle School',
  'Thornebrooke Elementary',
  'Thornell Road School',
  'Thornfield Elem.',
  'Thornhill Elementary',
  'Thornridge High School',
  'Thornton Burgess',
  'Thornton Central School',
  'Thornton Creek Elementary School',
  'Thornton El',
  'Thornton Elementary School',
  'Thornton Fractnl No High School',
  'Thornton Fractnl So High School',
  'Thornton High',
  'Thornton High School',
  'Thornton Junior High',
  'Thornton Middle',
  'Thornton School',
  'Thornton Township High School',
  'Thorntons Ferry School',
  'Thorntown Elementary School',
  'Thornville Elementary School',
  'Thornwell School For The Arts',
  'Thornwilde Elementary School',
  'Thornwood El',
  'Thornwood High School',
  'Thornydale Elementary School',
  'Thoroughgood Elementary',
  'Thorp Elem & Jr Sr High',
  'Thorp Elementary',
  'Thorp High',
  'Thorp J N Elem School',
  'Thorp O A Elem Scholastic Acad',
  'Thorpe Creek Elementary',
  'Thorpe J. Gordon Elem.',
  'Thorsby High School',
  'Thorson Elementary',
  'Thousand Islands High School',
  'Thousand Islands Middle School',
  'Thousand Oaks El',
  'Thousand Oaks Elementary',
  'Thousand Oaks High',
  'Thrall El',
  'Thrall H S',
  'Thrall Middle',
  'Thrasher Elementary',
  'Thrasher High School',
  'Threadgill Elementary School',
  'Threadgill Primary School',
  'Three Bridges School',
  'Three Chopt Elementary',
  'Three Creek Elem/jr High School',
  'Three Creeks Elem School',
  'Three Creeks Elementary School',
  'Three Falls School',
  'Three Fires Elementary',
  'Three Forks 7-8',
  'Three Forks Elem School',
  'Three Forks High School',
  'Three Lakes Academy',
  'Three Lakes Elementary',
  'Three Lakes High',
  'Three Lakes High School',
  'Three Lakes Junior High',
  'Three Lakes Middle',
  'Three Mile Creek School',
  'Three Oaks Elementary',
  'Three Oaks Elementary School',
  'Three Oaks Middle School',
  'Three Oaks Public School Academy',
  'Three Oaks School',
  'Three Peaks Pre School',
  'Three Peaks School',
  'Three Points Elementary',
  'Three Rings Ranch Elementary',
  'Three Rivers Charter',
  'Three Rivers Charter School',
  'Three Rivers Community College',
  'Three Rivers El',
  'Three Rivers Elementary',
  'Three Rivers Elementary School',
  'Three Rivers High School',
  'Three Rivers Home Link',
  'Three Rivers Jr/sr H S',
  'Three Rivers Middle College Magnet School',
  'Three Rivers Middle School',
  'Three Rivers Montessori School',
  'Three Rivers School',
  'Three Springs High School',
  'Three Trails Elem.',
  'Three Trails Preschool',
  'Three Way El',
  'Thrive Academy',
  'Thrive Public',
  'Throckmorton School',
  'Throop Elementary School',
  'Thumb Correctional Facility',
  'Thunder Basin High School',
  'Thunder Bay Junior High School',
  'Thunder Hill Elementary',
  'Thunder Mountain Elementary School',
  'Thunder Mountain High School',
  'Thunder Mountain Middle School',
  'Thunder Ridge Elementary',
  'Thunder Ridge High School',
  'Thunder Ridge Middle School',
  'Thunder Ridge School',
  'Thunder Valley Pk-8',
  'Thunderbird Colony Elementary - 10',
  'Thunderbird El',
  'Thunderbird High School',
  'Thunderbird Preparatory Academy',
  'Thunderbird School of Global Management',
  'Thunderbolt Career And Technology Center',
  'Thunderbolt Elementary School',
  'Thunderbolt Middle School',
  'Thunderridge High School',
  'Thurgood Marshall',
  'Thurgood Marshall Academy For Learning & Social Change',
  'Thurgood Marshall Academy Lower School',
  'Thurgood Marshall Academy Pcs',
  'Thurgood Marshall El',
  'Thurgood Marshall Elem School',
  'Thurgood Marshall Elementary',
  'Thurgood Marshall Elementary School',
  'Thurgood Marshall Fundamental',
  'Thurgood Marshall H S',
  'Thurgood Marshall High School',
  'Thurgood Marshall Leadership Acad',
  'Thurgood Marshall Leadership Academy',
  'Thurgood Marshall Learning Ctr',
  'Thurgood Marshall Mid',
  'Thurgood Marshall Middle',
  'Thurgood Marshall Middle School',
  'Thurgood Marshall School',
  'Thurman Francis Arts Academy/magnet School For The Arts',
  'Thurman G. Smith Elem. School',
  'Thurman White Middle School',
  'Thurmont Elementary',
  'Thurmont Middle',
  'Thurmont Primary',
  'Thurs Co Juv Det/tumwater West E',
  'Thurston Elementary School',
  'Thurston High School',
  'Thurston Middle',
  'Thurston Middle School',
  'Thurston T Nelson Elementary School',
  'Thurston Woods Elementary',
  'Tia East',
  'Tia West',
  'Tianjin Academy of Fine Art',
  'Tianjin Medical University',
  'Tianjin Normal University',
  'Tianjin Open University',
  'Tianjin Polytechnic University',
  'Tianjin University',
  'Tianjin University of Commerce',
  'Tianjin University of Finance & Economics',
  'Tianjin University of Technology',
  'Tibbals El',
  'Tibbets Elementary',
  'Tibbetts Middle School',
  'Tibby Elementary',
  'Tibet Agricultural and Animal Husbandry College',
  'Tibet Tibetan Medical College',
  'Tibet University',
  'Tibet University of Nationalities',
  'Ticasuk Brown Elementary',
  'Tice Creek',
  'Tice El',
  'Tice Elementary School',
  'Tichenor Middle School',
  'Ticonderoga Elementary School',
  'Ticonderoga Middle School',
  'Ticonderoga Senior High School',
  'Tidehaven H S',
  'Tidehaven Int',
  'Tidewater Community College',
  'Tidewater Juvenile Detention Home - Chesapeake',
  'Tidewater Park Elementary',
  'Tidewater Regional Alternative Ed Project',
  'Tidioute Community Cs',
  'Tiebreaker Elementary School',
  'Tiefort View Intermediate',
  'Tier Iii Intervention Academy',
  'Tierra Adentro',
  'Tierra Amarilla Elementary',
  'Tierra Antigua Elementary',
  'Tierra Blanca El',
  'Tierra Bonita Elementary',
  'Tierra Buena Elementary',
  'Tierra Del Sol Continuation High',
  'Tierra Del Sol El',
  'Tierra Del Sol Elementary School',
  'Tierra Del Sol Middle',
  'Tierra Encantada Charter School',
  'Tierra Linda Elementary',
  'Tierra Linda Middle',
  'Tierra Pacifica Charter',
  'Tierra Vista Elementary',
  'Tierrasanta Elementary',
  'Tierärztliche Hochschule Hannover',
  'Tietgen Business College',
  'Tieton Intermediate School',
  'Tiffany (burton C.) Elementary',
  'Tiffany Creek Elementary',
  'Tiffany Park Elementary School',
  'Tiffany Ridge Elementary Schl',
  'Tiffin Elementary',
  'Tiffin Elementary School',
  'Tiffin Middle School',
  'Tiffin University Prague',
  'Tift County High School',
  'Tigard High School',
  'Tiger Academy',
  'Tiger Creek Elementary School',
  'Tigert Middle School',
  'Tigerton Elementary',
  'Tigerton High',
  'Tigerville Elementary',
  'Tijeras Creek Elementary',
  'Tijerina El',
  'Tikigaq School',
  'Tikrit University',
  'Tilak Maharashtra Vidyapeeth',
  'Tilburg University',
  'Tilden Career Communty Academy Hs',
  'Tilden El Center',
  'Tilden Hogge Elementary School',
  'Tilden Middle School',
  'Tilden William T Ms',
  'Tildenville Elementary',
  'Tilford Elementary School',
  'Tilghman Elementary',
  'Tilka Manjhi Bhagalpur University',
  'Till Elem Math & Science Academy',
  'Tillamook Bay Community College',
  'Tillamook High School',
  'Tillamook Junior High School',
  'Tiller School',
  'Tilles Elementary School',
  'Tillicum Elementary School',
  'Tillicum Middle School',
  'Tilton',
  'Tilton Elem School',
  'Timber Creek El',
  'Timber Creek Elem.',
  'Timber Creek Elementary School',
  'Timber Creek Es',
  'Timber Creek H S',
  'Timber Creek High',
  'Timber Creek Regional High School',
  'Timber Drive Elementary',
  'Timber Grove Elementary',
  'Timber Lake Elementary - 02',
  'Timber Lake High School - 01',
  'Timber Lake Middle School - 03',
  'Timber Lakes Elementary',
  'Timber Lane Elementary',
  'Timber Point Elementary',
  'Timber Point Elementary School',
  'Timber Pups Learning Center',
  'Timber Ridge El',
  'Timber Ridge Elementary',
  'Timber Ridge Elementary School',
  'Timber Ridge Es',
  'Timber Ridge Middle School',
  'Timber Ridge School',
  'Timber Springs Middle',
  'Timber Trace Elementary School',
  'Timber Trail Elementary School',
  'Timber Trails Elementary School',
  'Timbercrest Elementary School',
  'Timbercrest Middle School',
  'Timberlake Es',
  'Timberlake Hs',
  'Timberlake Junior High School',
  'Timberlake Senior High School',
  'Timberland Academy',
  'Timberland High',
  'Timberlane Learning Center',
  'Timberlane Middle School',
  'Timberlane Regional High School',
  'Timberlane Regional Middle School',
  'Timberlawn Elementary School',
  'Timberlin Creek Elementary School',
  'Timberline El',
  'Timberline Elementary',
  'Timberline Elementary School',
  'Timberline High School',
  'Timberline Middle',
  'Timberline Pk-8',
  'Timberline School',
  'Timbers El',
  'Timberview Middle',
  'Timberview Middle School',
  'Timberwilde El',
  'Timberwood Middle',
  'Timberwood Park El',
  'Timbo Elementary School',
  'Timbo High School',
  'Timbuktu Academy',
  'Time 4 Learning Charter School',
  'Times2 Academy',
  'Timirjazev Moscow Academy of Agricultutre',
  'Timmerman El',
  'Timmerman Elementary',
  'Timmons Elementary School',
  'Timmonsville High',
  'Timnath Elementary School',
  'Timonium Elementary',
  'Timoteo Delgado',
  'Timothy Ball Elementary School',
  'Timothy Edwards School',
  'Timothy Elementary School',
  'Timothy L Johnson Academy',
  'Timpanogos Academy',
  'Timpanogos High',
  'Timpanogos Intermediate',
  'Timpanogos Middle School',
  'Timpanogos School',
  'Timpson El',
  'Timpson Middle',
  'Timpson School',
  'Timpview High',
  'Timucuan Elementary School',
  'Tina-avalon Elem.',
  'Tina-avalon High',
  'Tinc Rd. Elementary School',
  'Tincher Preparatory',
  'Tindley Collegiate Academy',
  'Tindley Genesis Academy',
  'Tindley Preparatory Academy',
  'Tindley Renaissance Academy',
  'Tindley Summit Academy',
  'Tinicum El Sch',
  'Tinicum Sch',
  'Tinker Es',
  'Tinker K-8 School',
  'Tinkham Alternative Education',
  'Tinley Park High School',
  'Tinmouth Elementary School',
  'Tinora Elementary School',
  'Tinora High School',
  'Tinora Junior High School',
  'Tinsley El',
  'Tintic High',
  'Tintic Preschool',
  'Tinton Falls Middle School',
  'Tiny Turtles Preschool',
  'Tioga Community',
  'Tioga Elementary School',
  'Tioga High',
  'Tioga High School',
  'Tioga Hills Elementary School',
  'Tioga Junior High School',
  'Tioga Middle',
  'Tioga Middle School',
  'Tioga School',
  'Tioga Senior High School',
  'Tiogue School',
  'Tiospa Zina Tribal School',
  'Tiospaye Topa School',
  'Tioughnioga Riverside Academy',
  'Tipler Middle',
  'Tippecanoe High School',
  'Tippecanoe Middle School',
  'Tippecanoe Valley High School',
  'Tippecanoe Valley Middle School',
  'Tippin El',
  'Tipps El',
  'Tipton Academy 47',
  'Tipton Academy Pk3',
  'Tipton Community School',
  'Tipton County Alternative Learning Center',
  'Tipton Durant Middle School',
  'Tipton Elem.',
  'Tipton Elementary',
  'Tipton Elementary School',
  'Tipton Es',
  'Tipton High',
  'Tipton High School',
  'Tipton Hs',
  'Tipton Middle School',
  'Tiraspol State University',
  'Tisbury Elementary',
  'Tisd Child & Adolescent School',
  'Tish. Co. Career And Technical Cent',
  'Tishomingo Co Alternative School',
  'Tishomingo County High School',
  'Tishomingo Elementary',
  'Tishomingo Es',
  'Tishomingo Hs',
  'Tishomingo Ms',
  'Tishreen University',
  'Tisinger El',
  'Tison Middle',
  'Titan Hill Intermediate School',
  'Titche Blanton Area',
  'Title I Migrant Education Program',
  'Titus El Sch',
  'Titusville High School',
  'Titusville Intermediate',
  'Titusville Ms',
  'Titusville Shs',
  'Tiverton High School',
  'Tiverton Middle School',
  'Tivy H S',
  'Tiyan High School',
  'Tl Faulkner School',
  'Tlc Academy',
  'Tmcc Magnet High School',
  'Tnc Campus (texas Neurorehabilitation Center)',
  "To'hajiilee Day School",
  'Toano Middle',
  'Tobacco Road Elementary School',
  'Tobb Economics and Technology University',
  'Tobe Turpen Elementary',
  'Tobey Elementary School',
  'Tobias El',
  'Tobias Elementary School',
  'Toby Farms Intermediate School',
  'Toby Johnson Middle',
  'Tobyhanna El Ctr',
  'Toccoa Elementary School',
  "Today's Fresh Start Charter School Inglewood",
  "Today's Fresh Start-compton",
  'Todd Beamer High School',
  'Todd County Achievement High School - 17',
  'Todd County Achievement Middle School - 18',
  'Todd County Central High School',
  'Todd County Elementary - 16',
  'Todd County High School - 01',
  'Todd County Middle School',
  'Todd County Middle School - 10',
  'Todd Early Childhood Center',
  'Todd Elementary',
  'Todd Elementary School',
  'Todd Grant Elementary School',
  'Todd Hall Elem School',
  'Todd Lane El Sch',
  'Toddler Learning Center',
  'Toddy Thomas Elementary',
  'Together 4 Kids School',
  'Togiak School',
  'Togliatti State University',
  "Tohaali' Community School",
  'Tohatchi Elementary',
  'Tohatchi High',
  'Tohatchi Middle',
  'Tohickon Ms',
  'Tohickon Valley El Sch',
  'Toho College of Music',
  'Toho Gakuen School of Music',
  'Toho University',
  'Tohoku Bunka Gakuen University',
  'Tohoku College of Pharmacy',
  'Tohoku Fukushi University',
  'Tohoku Gakuin University',
  'Tohoku Institute of Technology',
  'Tohoku University',
  'Tohoku University of Art and Design',
  "Tohoku Women's College",
  "Tohono O'Odham Community College",
  'Tohono O`odham High School',
  'Tohwa University',
  'Toin University of Yokohama',
  'Toisnot Middle',
  'Tok School',
  'Tokai Gakuen University',
  'Tokai University Educational System',
  "Tokai Women's College",
  'Tokay Elementary',
  'Tokay High',
  'Token Springs Elementary',
  'Tokeneke Elementary School',
  'Toki Middle',
  'Tokiwa University',
  'Tokoha Gakuen University',
  'Tokushima Bunri University',
  'Tokushima University',
  'Tokuyama University',
  'Tokyo College of Music',
  'Tokyo Denki University',
  'Tokyo Dental College',
  'Tokyo Engineering University',
  'Tokyo Gakugei University',
  'Tokyo Institute of Polytechnics',
  'Tokyo Institute of Technology',
  'Tokyo International University',
  'Tokyo Kasei University',
  'Tokyo Keizai University',
  'Tokyo Medical College',
  'Tokyo Medical and Dental University',
  'Tokyo Metropolitan Institute of Technology',
  'Tokyo Metropolitan University',
  'Tokyo Metropolitan University of Health Sciences',
  'Tokyo National University of Fine Arts and Music',
  'Tokyo University',
  'Tokyo University of Agriculture',
  'Tokyo University of Agriculture and Technology',
  'Tokyo University of Art and Design',
  'Tokyo University of Fisheries',
  'Tokyo University of Foreign Studies',
  'Tokyo University of Information Sciences',
  'Tokyo University of Mercantile Marine',
  'Tokyo University of Pharmacy and Life Science',
  "Tokyo Woman's Christian University",
  "Tokyo Women's College of Physical Education",
  "Tokyo Women's Medial College",
  'Toland Way Elementary',
  'Tolar El',
  'Tolar H S',
  'Tolar J H',
  'Tolbert Elementary School',
  'Toledo Blade Elementary School',
  'Toledo Early College High School',
  'Toledo Elementary School',
  'Toledo High School',
  'Toledo Jr. High',
  'Toledo Middle School',
  'Toledo Preparatory And Fitness Academy',
  'Toledo School For The Arts',
  'Toledo Senior High School',
  'Toledo Smart Elementary School',
  'Toledo Technology Academy High School',
  'Tolenas Elementary',
  'Toler El',
  'Toler Elementary',
  'Toliver Elementary School',
  'Toll Gate Elementary',
  'Toll Gate Grammar School',
  'Toll Gate High School',
  'Toll Gate Middle School',
  'Tolland High School',
  'Tolland Intermediate School',
  'Tolland Middle School',
  'Tolles Career & Technical Center',
  'Tollesboro Elementary School',
  'Tolleson #206',
  'Tolleson Elementary School',
  'Tolleson Union High School',
  'Tollgate Elementary School Of Expeditionary Learni',
  'Tolman School',
  'Tolsia High School',
  'Tolson Elementary School',
  'Tolt Middle School',
  'Toltec Elementary School',
  'Toltecali High School',
  'Toluca Lake Elementary',
  'Tom And Nita Nichols Middle',
  'Tom Bean El',
  'Tom Bean H S',
  'Tom Bean Middle',
  'Tom Bradley Global Awareness Magnet',
  'Tom C Gooch El',
  'Tom Green El',
  'Tom Hawkins Elementary',
  'Tom Hicks El',
  'Tom Joy Elementary',
  'Tom Kitayama Elementary',
  'Tom Lea Jr El',
  'Tom Maloney Elementary',
  'Tom Matsumoto Elementary',
  'Tom Mccall Elementary School',
  'Tom Mccall Upper Elementary',
  'Tom P. Haney Technical Center',
  'Tom R Ellisor El',
  'Tom W Field El',
  'Tom Williams Elementary School',
  'Tom Wilson El',
  'Tomah Area Montessori School',
  'Tomah High',
  'Tomah Middle',
  'Tomahawk Creek Middle',
  'Tomahawk Elem',
  'Tomahawk Elementary',
  'Tomahawk High',
  'Tomahawk Intermediate School',
  'Tomahawk Middle',
  'Tomahawk School',
  'Tomakomai Komazawa University',
  'Tomales Elementary',
  'Tomales High',
  'Tomas Bata University in Zlin',
  'Tomas C. Ongay',
  'Tomas Carrion Maduro',
  'Tomas Masso Rivera Morales',
  'Tomas Rivera Elementary',
  'Tomas Rivera Middle',
  'Tomas Vera Ayala',
  'Tomasita Elementary',
  'Tomball Alternative Education Center',
  'Tomball El',
  'Tomball H S',
  'Tomball Int',
  'Tomball J H',
  'Tomball J J A E P Campus',
  'Tomball Memorial H S',
  'Tomball Star Academy',
  'Tombaugh Elementary',
  'Tombstone High School',
  'Tome Elementary',
  'Tomekeastern Elem School',
  'Tomlin Middle School',
  'Tomlinson Adult Learning Center',
  'Tomlinson Middle School',
  'Tomlinson Ms',
  'Tommie Barfield Elementary School',
  'Tommie F. Brown International Academy',
  'Tommy Smith Elementary School',
  "Tommy's Road Elementary",
  'Tomoka Elementary School',
  'Tomorrow Center',
  'Tomorrow River Community Charter',
  'Tomorrow River Virtual Charter School (trvcs)',
  'Tompkins Cortland Community College',
  'Tompkins Elementary',
  'Tompkins H S',
  'Tompkins Square Middle School',
  'Tompkins-seneca-tioga Boces',
  'Tompkinsville Elem',
  'Toms River High School East',
  'Toms River High School North',
  'Toms River High School South',
  'Toms River Intermediate School East',
  'Toms River Intermediate School North',
  'Toms River Intermediate School South',
  'Tomsk Polytechnic University',
  'Tomsk State Pedagogical University',
  'Tomsk State University',
  'Tomsk State University of Architecture and Building',
  'Tomsk State University of Control Systems and Radioelectronics',
  'Tonalea Day School',
  'Tonalea K-8',
  'Tonaquint Intermediate',
  'Tonasket Elementary School',
  'Tonasket High School',
  'Tonasket Middle School',
  'Tonawanda Elementary',
  'Tonawanda Middle/high School',
  'Tonda Elementary School',
  'Toney Elementary School',
  'Tonganoxie Elem',
  'Tonganoxie High',
  'Tonganoxie Middle School',
  'Tongass School Of Arts And Sciences Charter School',
  'Tongji Medical University',
  'Tongji University',
  'Tongmyung University of Information Technology',
  'Tongue River Elementary',
  'Tongue River High School',
  'Tongue River Middle School',
  'Toni Wofford Morrison Es',
  'Tonica Grade School',
  'Tonka Online',
  'Tonkawa Es',
  'Tonkawa Hs',
  'Tonkawa Ms',
  'Tonopah Elementary School',
  'Tonopah High School',
  'Tonopah Middle School',
  'Tonopah Valley High School',
  'Tonti Elem School',
  'Tonto Basin Elementary',
  'Tony Alamo Elementary School',
  'Tony Goetz Es',
  'Tony Gonzalez El',
  'Tony Hillerman Middle School',
  'Tony Quintana Elementary',
  'Tony Serna Jr. Elementary',
  'Tony Tobin Elementary',
  'Tooele Community Learning Center',
  'Tooele High',
  'Tooele Jr High',
  'Tooele Online K-8',
  'Tooker Avenue School',
  'Tool El',
  'Toombs Central Elementary School',
  'Toombs County High School',
  'Toombs County Middle School',
  'Toomer Elementary School',
  'Toone Elementary',
  "Tootin' Hills School",
  'Top Of Texas Accelerated Education Center',
  'Top Of The Kuskokwim School',
  'Top Of The World Elementary',
  'Topa Topa Elementary',
  'Topanga Elementary Charter',
  'Topaz Elementary',
  'Topaz Preparatory Academy',
  'Tope Elementary School',
  'Topeka Charter School For Advanced Studies',
  'Topeka Correctional Facility',
  'Topeka Elementary School',
  'Topeka High',
  'Topeka Tilton Attendance Center',
  'Topeka West High',
  'Topock Elementary School',
  'Toppenish High School',
  'Toppenish Middle School',
  'Toppenish Pre School',
  'Topper Freshman Academy',
  'Topping Elem.',
  'Tops K-8 School',
  'Topsail Elementary',
  'Topsail High',
  'Topsail Middle',
  'Toquam Magnet School',
  'Torbat Heydarieh University of Medical Sciences',
  'Torch Middle',
  'Torchlight Academy',
  'Torey J. Sabatini School',
  'Tornillo Daep',
  'Tornillo El',
  'Tornillo H S',
  'Tornillo Int',
  'Tornillo Jh',
  'Toro Canyon Middle',
  'Toro Park Elementary',
  'Toro Preschool',
  'Toronto Jr. / Sr. High School',
  'Toros University',
  'Torrance Elementary',
  'Torrance High',
  'Torrence Creek Elementary',
  'Torrence Elementary',
  'Torrey Hill Intermediate School',
  'Torrey Hills',
  'Torrey Pines Elementary',
  'Torrey Pines High',
  'Torringford Community Outreach',
  'Torringford School',
  'Torrington High School',
  'Torrington Middle School',
  'Tortolita Middle School',
  'Tortuga Preserve Elementary School',
  'Tosch El',
  'Total',
  'Totaro Elementary',
  'Totem Correspondence School',
  'Totem Falls',
  'Totem Middle School',
  'Toth Elementary School',
  'Totoket Valley Elementary School',
  'Tottenville High School',
  'Tottori University',
  'Tottori University of Environmental Studies',
  'Touchet Elem & High School',
  'Touchstone',
  'Tough El',
  'Tournament Hills Elementary',
  'Touro College',
  'Tourtellotte Memorial High School',
  "Toussaint L'ouverture Elementary",
  "Toussaint L'ouverture High",
  'Toussaint Louverture-marquis De Lafayette School No. 6',
  'Toutle Lake Elementary',
  'Toutle Lake High School',
  'Tovashal Elementary',
  'Towamensing El Sch',
  'Towanda Area El Sch',
  'Towanda Area Jshs',
  'Towanda Elem School',
  'Tower Heights Middle School',
  'Tower Rock Elementary',
  'Tower School',
  'Tower View Alternative High School',
  'Tower-soudan Elementary',
  'Towers Elementary',
  'Towers High School',
  'Town & Country Charter High School',
  'Town & Country Elementary School',
  'Town Campus Learning Center',
  'Town Center El',
  'Town Center Elementary School At Plainsboro',
  'Town Creek Elementary',
  'Town Of Webb School',
  'Towne Acres Elementary',
  'Towne Avenue Elementary',
  'Towne Meadow Elementary School',
  'Towne Meadows Elementary School',
  'Towne Point Elementary School',
  'Townewest El',
  'Towngate Elementary',
  'Townley El',
  'Townline Elementary',
  'Towns County Elementary School',
  'Towns County High School',
  'Towns County Middle School',
  'Towns Elementary School',
  'Townsend 7-8',
  'Townsend Early Childhood Center',
  'Townsend Elem.',
  'Townsend Elementary',
  'Townsend Elementary School',
  'Townsend Es',
  'Townsend Harris High School',
  'Townsend Middle',
  'Townsend North Community School',
  'Townsend Street Elementary',
  'Townshend Village School',
  'Township Elementary',
  'Township Of Ocean Intermediate School',
  'Township Three Elementary',
  'Townview Elementary',
  'Townville Elementary',
  'Towpath Trail High School',
  'Towson High Law & Public Policy',
  'Towson State University',
  'Toy Town Elementary',
  'Toyama Medical and Pharmaceutical University',
  'Toyama Prefectural University',
  'Toyama University',
  'Toyama University of International Studies',
  "Toyo Eiwa Women's University",
  'Toyo Gakuen University',
  'Toyo University',
  'Toyohashi University of Technology',
  'Toyon Elementary',
  'Toyon Middle',
  'Toyota Technological Institute',
  'Tozer Elementary School',
  'Tps Mosaic Ministries Preschool',
  'Tr Davis Elementary School',
  'Tr Miller High School',
  'Trabuco Elementary',
  'Trabuco Hills High',
  'Trabuco Mesa Elementary',
  'Trace',
  'Trace Crossings Elementary School',
  'Tracey School',
  'Traceys Elementary',
  'Tracy',
  'Tracy (wilbur) High (continuation)',
  'Tracy El Sch',
  'Tracy Elementary',
  'Tracy High',
  'Tracy Secondary',
  'Trade Logistics Aviation Academy',
  'Tradewind El',
  'Tradewinds Elementary School',
  'Tradewinds Middle School',
  'Tradition El',
  'Traditional Sch.',
  'Traeger Elementary',
  'Traeger Middle',
  'Traep - Virginia Beach - Renaissance Academy',
  'Traep Norfolk',
  'Trafalgar Elementary School',
  'Trafalgar Middle School',
  'Trafford El Sch',
  'Trafford Ms',
  'Traice',
  'Traice Ms',
  'Trail Elementary',
  'Trail Ridge Middle School',
  'Trail Wind Elementary',
  'Trailblazer Elementary School',
  'Trailblazers Academy',
  'Trailridge Elem.',
  'Trailridge Middle',
  'Trails West Elementary School',
  'Trailside Elementary',
  'Trailside Middle',
  'Trailside Point Performing Arts Academy',
  'Trailside Preschool',
  'Trailside School',
  'Trailwood Elem',
  'Trailwoods Elem.',
  'Trajan Elementary',
  'Trakia University Stara Zagora',
  'Trakya University',
  'Tramway Elementary',
  'Tranquillity Elementary',
  'Tranquillity High',
  'Transformational Learning Center',
  'Transforming Lives Cooperative (tlc)',
  'Transit Middle School',
  'Transit Tech Career And Technical Education High School',
  'Transition',
  'Transition 15',
  'Transition 2 Success',
  'Transition Center',
  'Transition Ctr',
  'Transition Day Students',
  'Transition Expulsion Program',
  'Transition For Success',
  'Transition High',
  'Transition High (continuation)',
  'Transition Learning Center',
  'Transition Plus',
  'Transition Plus Services',
  'Transition Program',
  'Transition Program At Career Center',
  'Transition Program At Pva',
  'Transition Program At Unmc Nebraska Medicine',
  'Transition Program South Omaha Site',
  'Transition School',
  'Transition Services',
  'Transition Support Resource Center/fairfax',
  'Transitional Learning Center (continuation)',
  'Transitional Skills Center',
  'Transitions',
  'Transitions 110',
  'Transitions At Bass',
  'Transitions Program',
  'Transitions School',
  'Transmountain Early College H S',
  'Transport and Telecommunication Institute',
  'Transportation And Law',
  'Transylvania University',
  'Trantwood Elementary',
  'Trap Hill Middle School',
  'Traphagen School',
  'Traphill Elementary',
  'Trapnell Elementary School',
  'Trapp Elementary',
  'Trapper Creek Elementary',
  'Trask River High School',
  'Traughber Jr High School',
  'Traut Core Elementary School',
  'Trautmann El',
  'Trautmann Middle',
  'Trautwein Elem.',
  'Travelers Rest High',
  'Travell Elementary School',
  'Traver Elementary',
  'Traver Road Primary School',
  'Traverse City High School',
  'Traverse Heights Elem School',
  'Traverse Mountain School',
  'Travilah Elementary',
  'Travis 6th Grade Campus',
  'Travis B Bryan H S',
  'Travis Co J J A E P',
  'Travis Community Day',
  'Travis County Day School',
  'Travis County J J A E P',
  'Travis County Jjaep',
  'Travis County Juvenile Detention Center',
  'Travis Early College H S',
  'Travis Education Center',
  'Travis El',
  'Travis Elementary',
  'Travis H S',
  'Travis H S Of Choice',
  'Travis Hts El',
  'Travis Independent Study',
  'Travis Int',
  'Travis Middle',
  'Travis Ranch',
  'Travis Science Academy',
  'Traweek Middle',
  'Traylor Academy',
  'Treadway Elementary School',
  'Treadwell Elementary',
  'Treadwell Middle School',
  'Treasure Coast Elementary School',
  'Treasure Coast High School',
  'Treasure Forest El',
  'Treasure Hills El',
  'Treasure Island Elementary School',
  'Treasure Mtn. Junior High School',
  'Treasure Valley Community College',
  'Treasure Valley Leadership Academy',
  'Treasure Valley Math/science',
  'Treasure Village Montessori Charter School',
  'Treatment & Learning Center',
  'Treatment & Learning Center-h',
  'Trebein Elementary School',
  'Treca Digital Academy',
  'Tredyffrin-easttown Ms',
  'Tree Of Life Charter',
  'Tree Of Life International Charter',
  'Treeline Elementary School',
  'Treeside Charter School',
  'Treetops School International',
  'Treeview Elementary',
  'Trego Community High',
  'Trego Grade School',
  'Trego School',
  'Trek North Middle School',
  'Trek Program',
  'Treknorth High School',
  'Tremont Attendance Center',
  'Tremont Consolidated School',
  'Tremont Elem School',
  'Tremont Elementary',
  'Tremont Elementary School',
  'Tremont High School',
  'Tremont Middle School',
  'Tremont Montessori School',
  'Trempealeau Elementary',
  'Tremper High',
  'Trent Independent School District',
  'Trent Lott Academy',
  'Trent Middle',
  'Trent Park Elementary',
  'Trent School',
  'Trent University',
  'Trenton Central High School - Main Campus',
  'Trenton Central High School - West Campus',
  'Trenton El',
  'Trenton Elem School',
  'Trenton Elementary',
  'Trenton Elementary School',
  'Trenton H S',
  'Trenton High',
  'Trenton High School',
  'Trenton Middle',
  'Trenton Middle School',
  'Trenton Psychiatric Hospital',
  'Trenton Sr. High',
  'Trenton State College',
  'Trenton Stem-to-civics Charter School',
  'Trentwood School',
  'Tres Pinos Elementary',
  'Tres Rios Elementary School',
  'Treutlen Elementary School',
  'Treutlen Middle/high School',
  'Trevilians Elementary',
  'Trevino El',
  'Trevista At Horace Mann',
  'Trevitt Elementary School',
  'Trevor Browne High School',
  'Trevor-wilmot Grade',
  'Trewyn K-8 School',
  'Trexler Middle',
  'Trexler Ms',
  'Treynor Elementary School',
  'Treynor High School',
  'Treynor Middle School',
  'Trezevant High',
  'Tri Central Elementary',
  'Tri Central Middle-high School',
  'Tri City Elementary School',
  'Tri Co Juvenile Detent',
  'Tri Community Preschool',
  'Tri County Educational Center',
  'Tri County Elementary School',
  'Tri County Jr-sr High School',
  'Tri County Middle School',
  'Tri County Senior High School',
  'Tri Junior-senior High School',
  'Tri Point Elementary School',
  'Tri Valley Middle School',
  'Tri-c Community Day',
  'Tri-c Elementary School',
  'Tri-center Elementary School',
  'Tri-center High School',
  'Tri-center Middle School',
  'Tri-cities High School',
  'Tri-cities Rop',
  'Tri-city College Prep High School',
  'Tri-city Elem School',
  'Tri-city High School',
  'Tri-city Jr High School',
  'Tri-city United High School',
  'Tri-community El Sch',
  'Tri-county Career Center',
  'Tri-county Center',
  'Tri-county Early College High',
  'Tri-county Elem.',
  'Tri-county Elementary',
  'Tri-county Elementary School',
  'Tri-county High',
  'Tri-county High School',
  'Tri-county Intermediate School',
  'Tri-county Jr/sr High School',
  'Tri-county Junior High School',
  'Tri-county Middle',
  'Tri-county North Elementary School',
  'Tri-county North High School',
  'Tri-county North Middle School',
  'Tri-county Primary School',
  'Tri-county Regional Vocational Technical',
  'Tri-county Rop',
  'Tri-county Sacred Heart School',
  'Tri-county Secondary',
  'Tri-county South - Choate',
  'Tri-county Technical Center',
  'Tri-county Ward',
  'Tri-elementary School',
  'Tri-lakes Specl. Educ. Coop.',
  'Tri-north Middle School',
  'Tri-point High School',
  'Tri-point Jr High/elementary',
  'Tri-rivers Career Center',
  'Tri-state Stem+m Early College High School',
  'Tri-tech Skills Center',
  'Tri-valley Elem School',
  'Tri-valley Elementary - 02',
  'Tri-valley Elementary School',
  'Tri-valley High School',
  'Tri-valley High School - 01',
  'Tri-valley Jr. High School - 03',
  'Tri-valley Jshs',
  'Tri-valley Middle School',
  'Tri-valley Rop',
  'Tri-valley School',
  'Tri-valley Secondary School',
  'Tri-village Elementary School',
  'Tri-village High School',
  'Tri-west Middle School',
  'Tri-west Senior High School',
  'Tri-​County Community College',
  'Tri-​County Technical College',
  'Triad',
  'Triad Elementary School',
  'Triad High School',
  'Triad Math And Science Academy',
  'Triad Middle School',
  'Triadelphia Middle School',
  'Triadelphia Ridge Elementary',
  'Triangle Elementary',
  'Triangle Elementary School',
  'Triangle Elementary Shcool',
  'Triangle Lake Charter School',
  'Triangle Lake Montessori Elem',
  'Triangle Math And Science Academy',
  'Tribhuvan University Kathmandu',
  'Trickum Middle School',
  'Trico Elementary School',
  'Trico Jr High School',
  'Trico Senior High School',
  'Trident Technical College',
  'Trigg County High School',
  'Trigg County Intermediate School',
  'Trigg County Middle School',
  'Trigg County Primary School',
  'Trigg Elementary School',
  'Trillium',
  'Trillium Academy',
  'Trillium Charter',
  'Trillium Creek Primary School',
  'Trillium Springs Montessori',
  'Trimble County High School',
  'Trimble County Middle School',
  'Trimble Elementary',
  'Trimble Elementary School',
  'Trimble High School',
  'Trimble Junior High',
  'Trimble Technical H S',
  'Trimmer El Sch',
  'Trimmier El',
  'Trimont Elementary',
  'Trina Padilla De Sanz',
  'Trindale Elementary',
  'Trine University',
  'Trinidad Garza Early College At Mt View',
  'Trinidad High School',
  'Trinidad Middle School',
  'Trinidad School',
  'Trinidad State Junior College',
  'Trinidad Union',
  'Trinity Academy Performing Art',
  'Trinity Basin Preparatory',
  'Trinity Center Elementary',
  'Trinity Charter School',
  'Trinity College (CT)',
  'Trinity College (DC)',
  'Trinity College Carmarthen',
  'Trinity College of Music',
  'Trinity County Community',
  'Trinity County Juvenile Hall',
  'Trinity County Special Education',
  'Trinity Day Academy',
  'Trinity East El Sch',
  'Trinity Elementary',
  'Trinity Elementary School',
  'Trinity Environmental Academy',
  'Trinity H S',
  'Trinity High',
  'Trinity J H',
  'Trinity Meadows Int',
  'Trinity Ms',
  'Trinity North El Sch',
  'Trinity Oaks Elementary School',
  'Trinity River Elementary Community Day',
  'Trinity School',
  'Trinity School For Children',
  'Trinity Shs',
  'Trinity South El Sch',
  'Trinity Springs Middle',
  'Trinity Street Elementary',
  'Trinity Theological Seminary',
  'Trinity University',
  'Trinity University School of Medicine',
  'Trinity Valley Community College',
  'Trinity Valley Elementary',
  'Trinity West El Sch',
  'Trinity Western University',
  'Trio Wolf Creek Distance Learning',
  'Trion Elementary School',
  'Trion High School',
  'Trion Middle School',
  'Triopia Grade School',
  'Triopia Jr-sr High School',
  'Trip Elementary School',
  'Triple Crown Elementary',
  'Triplett Business & Tech.',
  'Tripoli Elementary School',
  'Tripoli Middle/sr High School',
  'Tripp Elementary - 03',
  'Tripp Middle School',
  'Tripp School',
  'Tripp-delmont High School - 01',
  'Tripp-delmont Jr. High - 02',
  'Tripura University',
  'Trisakti Institute of Tourism',
  'Tristar Acad Safe Sch Program',
  'Triton Academy',
  'Triton Central Elementary School',
  'Triton Central High School',
  'Triton Central Middle School',
  'Triton College',
  'Triton Elementary',
  'Triton Elementary School',
  'Triton High',
  'Triton High School',
  'Triton Jr-sr High School',
  'Triton Middle School',
  'Triton Regional High School',
  'Triton Regional Middle School',
  'Tritownship School',
  'Tritt Elementary School',
  'Triumph Academy',
  'Triumph Elementary School',
  'Triumph High School',
  'Triumphant Learning Center',
  'Trivium Academy',
  'Trivium Charter',
  'Triway',
  'Triway High School',
  'Triway Junior High School',
  'Trix Academy',
  'Trojan Alternative School',
  'Trojan Elem',
  'Trojan Intermediate',
  'Trona Elementary',
  'Trona High',
  'Troost Elem.',
  'Tropic Isles Elementary School',
  'Tropical Elementary School',
  'Tropico Middle',
  'Troth Street Elementary',
  'Trotwood Preparatory & Fitness Academy',
  'Trotwood-madison Early Learning Center',
  'Trotwood-madison High School',
  'Trotwood-madison Middle School',
  'Troup County High School',
  'Troup El',
  'Troup H S',
  'Troup Middle',
  'Trousdale Co Elementary',
  'Trousdale Co High School',
  'Trout Creek 7-8',
  'Trout Creek School',
  'Trout Lake Elementary',
  'Trout Lake School',
  'Trout Pri',
  'Troutdale Elementary School',
  'Troutman Elementary',
  'Troutman Middle',
  'Troutville Elementary',
  'Trowbridge Street School Of Great Lakes Studies',
  'Troy 7-8',
  'Troy A Howard Middle School',
  'Troy Area Jshs',
  'Troy Buchanan High',
  'Troy Center For Transition',
  'Troy Central School',
  'Troy College And Career High School',
  'Troy Continuing Education',
  'Troy Craughwell School',
  'Troy Cronin Elem School',
  'Troy Early Childhood',
  'Troy El',
  'Troy Elem',
  'Troy Elementary',
  'Troy Elementary School',
  'Troy H S',
  'Troy Heritage Trail School',
  'Troy High',
  'Troy High And Middle School',
  'Troy High School',
  'Troy Hills Elementary School',
  'Troy Hofer Elem Sch',
  'Troy Intermediate Elementary School',
  'Troy Intrmd Sch',
  'Troy J J A E P',
  'Troy Jr/sr High School',
  'Troy Junior High School',
  'Troy Middle',
  'Troy Middle School',
  'Troy School',
  'Troy Shorewood School',
  'Troy South Middle School',
  'Troy State University',
  'Troy Union Elementary School',
  'Troypike Center For Technology',
  'Truckee Elementary',
  'Truckee Meadows Community College',
  'True North Classical Academy',
  'True North Classical Academy High School',
  'True North Classical Academy South',
  'True North Rochester Prep-west Camp',
  'True North Rochester Preparatory Charter School',
  'True North Troy Preparatory Charter School',
  'Truebright Science Academy Cs',
  'Truesdell Ec',
  'Truesdell Middle School',
  'Truett Wilson Middle',
  'Truitt Intermediate',
  'Truitt Middle',
  'Truman',
  'Truman Benedict Elementary',
  'Truman El',
  'Truman Elem.',
  'Truman Elementary',
  'Truman Elementary School',
  'Truman Es',
  'Truman High',
  'Truman High School',
  'Truman Learning Center',
  'Truman Middle',
  'Truman Middle School',
  'Truman Primary School',
  'Truman School',
  'Truman Secondary',
  'Truman Shs',
  'Truman State University',
  'Trumann High School',
  'Trumann Intermediate Sch 5-6',
  'Trumann Intermediate School7-8',
  'Trumansburg Elementary School',
  'Trumbauersville El Sch',
  'Trumble Park Elementary School',
  'Trumbull Career & Tech Center',
  'Trumbull Early Childhood Education Center',
  'Trumbull High School',
  'Trunnell Elementary',
  'Truro Central',
  'Truscott Elementary School',
  'Trusty Elementary School',
  'Tryon Elementary',
  'Tryon Elementary School',
  'Ts Nurnberger Middle School',
  'Tsaile Elementary School',
  'Tschetter Colony Elementary - 03',
  "Tse'bii'nidzisgai School",
  "Tse'bit'ai Middle",
  "Tse'ii'ahi' Community School",
  "Tse'yaato' High School",
  "Tse'yi'gai High",
  "Tsehootsooi Dine Bi'olta",
  'Tsehootsooi Intermediate Learning Center',
  'Tsehootsooi Middle School',
  'Tsehootsooi Primary Learning Center',
  'Tshwane University of Technology',
  'Tsinghua University',
  'Tsu Charter Lab Sch',
  'Tsuda College',
  'Tsuk Taih School',
  'Tsukuba University',
  'Tsurai High (continuation)',
  'Tsuru University',
  'Tsurumi University',
  'Tuacahn High School For The Performing Arts',
  'Tualatin Elementary School',
  'Tualatin High School',
  'Tuba City Alternative School',
  'Tuba City Boarding School',
  'Tuba City High School',
  'Tuba City Junior High School',
  'Tuba City Primary School',
  'Tubman Es',
  'Tuckahoe Elementary',
  'Tuckahoe High School',
  'Tuckahoe Middle',
  'Tuckahoe Middle School',
  'Tuckahoe School',
  'Tuckaseegee Elementary',
  'Tucker',
  'Tucker Area Voc Tech Center',
  'Tucker County High School',
  'Tucker Creek Middle',
  'Tucker Elementary School',
  'Tucker High School',
  'Tucker Memorial Elementary School',
  'Tucker Middle School',
  'Tucker Valley Elementary Middle School',
  'Tucker-capps Elementary',
  'Tuckerman Elementary School',
  'Tuckerman High School',
  'Tuckers Crossroads Elementary',
  'Tuckerton Elementary School',
  'Tucson Chic Preschool',
  'Tucson Collegiate Prep',
  'Tucson Country Day School',
  'Tucson International Academy',
  'Tucson International Academy Midvale',
  'Tucson Magnet High School',
  'Tucson Preparatory School',
  'Tucson University',
  'Tucson Vip',
  'Tucumcari Elementary',
  'Tucumcari High',
  'Tucumcari Middle',
  'Tudor Elementary',
  'Tuftonboro Central School',
  'Tufts University',
  'Tug Valley High School',
  'Tuggle Elementary School',
  'Tuition Grant Dcps Non Public',
  'Tukes Valley Middle School',
  'Tukes Valley Primary',
  'Tukurngailnguq School',
  'Tukwila Elementary',
  'Tula State University',
  'Tulakes Es',
  'Tulane University',
  'Tularcitos Elementary',
  'Tulare City Community Day',
  'Tulare County Community',
  'Tulare County Court',
  'Tulare Support',
  'Tulare Technical Preparatory High',
  'Tulare Union High',
  'Tulare Western High',
  'Tularosa Elementary',
  'Tularosa High',
  'Tularosa Intermediate',
  'Tularosa Middle',
  'Tule Continuation High',
  'Tule Elk Park Children Center',
  'Tulelake Basin Elementary',
  'Tulelake Continuation High',
  'Tulelake High',
  'Tulia H S',
  'Tulia Highland El',
  'Tulia J H',
  'Tulio Larrinaga',
  'Tulip Grove Elementary',
  'Tulita Elementary',
  'Tullahoma High School',
  'Tullar Elementary',
  'Tully C. Knoles',
  'Tully Elementary',
  'Tully Elementary Accelerated Magnet School',
  'Tully Elementary School',
  'Tully Junior-senior High School',
  'Tuloso-midway Academic Career Center',
  'Tuloso-midway H S',
  'Tuloso-midway Int',
  'Tuloso-midway Middle',
  'Tuloso-midway Pri',
  'Tulpehocken Jshs',
  'Tulsa Community College',
  'Tulsa Honor Academy Charter',
  'Tulsa Legacy Charter Schl Inc',
  'Tulsa Met Hs',
  'Tulsa Met Jhs',
  'Tulsa Schl Arts Sciences Hs',
  'Tulsa Schl Arts Sciences Ms',
  'Tulsa Schl Of Arts/sciences',
  'Tulsa Street Elementary',
  'Tulsa Trail Elementary School',
  'Tuluksak School',
  'Tumaini University - Iringa University College',
  'Tumalo Community School',
  'Tumbleweed Elementary',
  'Tumbleweed Elementary School',
  'Tumkur University',
  'Tumwater High School',
  'Tumwater Hill Elementary',
  'Tumwater Middle School',
  'Tunbridge Central School',
  'Tunbridge Public Charter School',
  'Tunceli University',
  'Tunghai University',
  'Tunica Elementary School',
  'Tunica Middle School',
  'Tunkhannock Hs',
  'Tunkhannock Ms',
  'Tunku Abdul Rahman Chinese College',
  'Tunku Abdul Rahman University (Chinese University)',
  'Tunku Syed Sirajuddin Polytechnic',
  'Tunnel Hill Elementary School',
  'Tunnell (martin Luther) Elementary',
  'Tunstall High',
  'Tunstall Middle',
  'Tunxis Community College',
  'Tuolumne County Cal-safe Program',
  'Tuolumne County Community/isp',
  'Tuolumne County Special Education',
  'Tuolumne County Special Education Preschool',
  'Tuolumne Elementary',
  'Tupelo Alternative School',
  'Tupelo Es',
  'Tupelo High School',
  'Tupelo Hs',
  'Tupelo Lee Co Voc Tech School',
  'Tupelo Middle School',
  'Tupper Lake Middle-high School',
  'Turar Ryskulov Kazakh Economic University',
  'Turbeville High',
  'Turbotville El Sch',
  'Turie T. Small Elementary School',
  'Turin Polytechnic University In Tashkent',
  'Turkey Creek Middle School',
  'Turkey Foot Middle School',
  'Turkey Ford Public School',
  'Turkey Hill Elementary School',
  'Turkey Hill School',
  'Turkey Run Elementary School',
  'Turkey Run Jr-sr High School',
  'Turkey Valley Elementary School',
  'Turkey Valley Jr-sr High School',
  'Turkeyfoot Valley Area El Sch',
  'Turkeyfoot Valley Area Jshs',
  'Turkish Aeronautical Association University',
  'Turkish Naval Academy',
  'Turku School of Economics and Business Administration',
  'Turlington Woods Alternative',
  'Turlock High',
  'Turlock Junior High',
  'Turman Elementary School',
  'Turn Around Graduate',
  'Turn Of River School',
  'Turnagain Elementary',
  'Turnberry Elementary',
  'Turnbow Elementary School',
  'Turner 7-8',
  'Turner Academy At Tokay Colony',
  'Turner Ashby High',
  'Turner County Elementary School',
  'Turner County High School',
  'Turner County Middle School',
  'Turner County Specialty School',
  'Turner Creek Elementary',
  'Turner El',
  'Turner El Sch',
  'Turner Elem',
  'Turner Elem School',
  'Turner Elementary',
  'Turner Elementary School',
  'Turner Elementary/6th Grade Academy',
  'Turner Es',
  'Turner H S',
  'Turner High',
  'Turner High School',
  'Turner Hs',
  'Turner Middle',
  'Turner Middle School',
  'Turner Prekindergarten Academy',
  'Turner Primary School',
  'Turner School',
  'Turner Sixth Grade Academy',
  'Turner Woods Elementary School',
  'Turner-bartels K-8 School',
  'Turner-drew Elem Language Academy',
  'Turner/guilford/knight',
  'Turners Fall High',
  'Turning Point',
  'Turning Point Academy',
  'Turning Point Academy At Fleming',
  'Turning Point Academy At Henry Ford',
  'Turning Point Academy Public Charter',
  'Turning Point Alter J H',
  'Turning Point Alternative Education',
  'Turning Point Secondary School',
  'Turning Points Academy',
  'Turnpike Elementary School',
  'Turpin Es',
  'Turpin High School',
  'Turpin Hs',
  'Turquoise Dawn Alternative - Closed',
  'Turquoise Trail Charter School',
  'Turrentine Middle',
  'Turrill Elementary School',
  'Turtle Bay',
  'Turtle Creek Elementary',
  'Turtle Hook Middle School',
  'Turtle Lake Elementary',
  'Turtle Lake High',
  'Turtle Lake Middle',
  'Turtle Lake-mercer Elementary School',
  'Turtle Lake-mercer High School',
  'Turtle Mountain Community Elementary School',
  'Turtle Mountain Community High School',
  'Turtle Mountain Community Middle School',
  'Turtle Mountain Elementary School',
  'Turtle Mountain High School',
  'Turtle Mountain Middle School',
  'Turtle Mountain Spec Ed Unit',
  'Turtle Rock Elementary',
  'Turtleback Elementary',
  'Tuscaloosa Career And Technology Academy',
  'Tuscaloosa County High School',
  'Tuscaloosa County Juvenile Detention Center',
  'Tuscaloosa Magnet School Elementary',
  'Tuscaloosa Magnet School Middle',
  'Tuscan Elementary',
  'Tuscano Elementary School',
  'Tuscany Heights',
  'Tuscany Hills Elementary',
  'Tuscarawas Middle School',
  'Tuscarawas Valley High School',
  'Tuscarawas Valley Intermediate',
  'Tuscarawas Valley Middle School',
  'Tuscarawas Valley Primary',
  'Tuscarora Elementary',
  'Tuscarora Elementary School',
  'Tuscarora High',
  'Tuscarora Ms',
  'Tuscarora Valley El Sch',
  'Tuscola High',
  'Tuscola High School',
  'Tuscola Technology Center',
  'Tusculum Elementary',
  'Tusculum View Elementary',
  'Tuscumbia High',
  'Tushka Es',
  'Tushka Hs',
  'Tuskahoma Public School',
  'Tuskawilla Middle School',
  'Tuskegee Airman Global Academy',
  'Tuskegee Institute Middle School',
  'Tuskegee Public Elementary',
  'Tuskegee University',
  'Tuslaw Elementary School',
  'Tuslaw High School',
  'Tuslaw Middle School',
  'Tussahaw Elementary',
  'Tussey Mountain El',
  'Tussey Mountain Hs',
  'Tussey Mountain Jh',
  'Tussey Mountain Ms',
  'Tussing Elementary',
  'Tussing Elementary School',
  'Tustin Connect',
  'Tustin Connect Center',
  'Tustin High',
  'Tustin Memorial Elementary',
  'Tustin Ranch Elementary',
  'Tustumena Elementary',
  'Tutt Middle School',
  'Tuttle Avenue School',
  'Tuttle Early Childhood Center',
  'Tuttle Elementary School',
  'Tuttle Es',
  'Tuttle Hs',
  'Tuttle Intermediate Es',
  'Tuttle Ms',
  'Tuva State University',
  'Tver State Medical Academy',
  'Tver State Technical University',
  'Tver State University',
  'Twain El',
  'Twain Elem School',
  'Twain Elementary',
  'Twain Elementary School',
  'Twain Harte',
  'Twain High',
  'Twain Middle',
  'Twality Middle School',
  'Tweedy Elementary',
  'Twelfth & Marion El Sch',
  'Twelve Bridges Elementary',
  'Twelve Bridges Middle',
  'Twelve Corners Middle School',
  'Twenhofel Middle School',
  'Twentieth Street Elementary',
  'Twenty Eighth St Elem',
  'Twenty-eighth Street Elementary',
  'Twenty-first Century',
  'Twenty-fourth Street Elementary',
  'Twentynine Palms Elementary',
  'Twentynine Palms High',
  'Twentynine Palms Junior High',
  'Twiggs County High School',
  'Twiggs Middle School',
  'Twila Reid Elementary',
  'Twillman Elem.',
  'Twin Bluff Middle School',
  'Twin Branch Elementary School',
  'Twin Bridges 7-8',
  'Twin Bridges High School',
  'Twin Bridges School',
  'Twin Buttes Day School',
  'Twin Buttes Elementary School',
  'Twin Buttes High',
  'Twin Cedars Elementary School',
  'Twin Cedars High School',
  'Twin Cedars Jr-sr High School',
  'Twin Chimneys Elem.',
  'Twin Cities Academy',
  'Twin Cities German Immersion Chrtr',
  'Twin Cities International Elem Sch',
  'Twin Cities International Schools',
  'Twin City Elementary',
  'Twin City Elementary School',
  'Twin City High',
  'Twin Creeks Elementary',
  'Twin Creeks Middle',
  'Twin Echo Elem School',
  'Twin Falls High School',
  'Twin Falls Middle School',
  'Twin Falls Virtual Schoolhouse',
  'Twin Groves Middle School',
  'Twin Harbors - A Branch Of New Market Skills Center',
  'Twin Hickory Elementary',
  'Twin Hills Charter Middle',
  'Twin Hills Elementary School',
  'Twin Hills Public School',
  'Twin Hills School',
  'Twin Lake School',
  'Twin Lakes Academy Elementary School',
  'Twin Lakes Academy Middle School',
  'Twin Lakes Elementary',
  'Twin Lakes Elementary School',
  'Twin Lakes Senior High School',
  'Twin Oak Elementary School',
  'Twin Oaks Academy',
  'Twin Oaks Elementary',
  'Twin Oaks Elementary School',
  'Twin Oaks High',
  'Twin Oaks Middle School',
  'Twin Palms Continuation',
  'Twin Peaks Charter Academy',
  'Twin Peaks Elementary School',
  'Twin Peaks Middle',
  'Twin Peaks School',
  'Twin Pines Elementary School',
  'Twin Ridge Elementary',
  'Twin Ridge Elementary School',
  'Twin Ridges Home Study Charter',
  'Twin Ridges Special Education Preschool',
  'Twin River Elem-genoa',
  'Twin River Elem-silver Creek',
  'Twin River Sr High School',
  'Twin Rivers',
  'Twin Rivers Career & Tech Ed Area',
  'Twin Rivers Charter',
  'Twin Rivers Charter School',
  'Twin Rivers El Sch',
  'Twin Rivers Elementary School',
  'Twin Rivers Group Home',
  'Twin Rivers High',
  'Twin Rivers Intrmd Sch',
  'Twin Rivers Middle School',
  'Twin Rivers Primary Sch',
  'Twin Springs Elementary',
  'Twin Springs High',
  'Twin Spruce Junior High School',
  'Twin Valley El Ctr',
  'Twin Valley Elementary School',
  'Twin Valley Elementary/middle',
  'Twin Valley High',
  'Twin Valley Hs',
  'Twin Valley Middle High School',
  'Twin Valley Ms',
  'Twin Valley South Elementary School',
  'Twin Valley South High School',
  'Twin Valley South Middle School',
  'Twinbrook Elementary',
  'Twindly Bridge Charter School',
  'Twinfield Usd #33',
  'Twinhill Elementary',
  'Twinsburg High School',
  'Two Bunch Palms Elementary',
  'Two Dimensions At Corsicana',
  'Two Dimensions Preparatory Academy',
  'Two Dimensions/vickery',
  'Two Eagle River School',
  'Two Harbors Secondary',
  'Two Hundred Thirty-second Place',
  'Two Mile Prairie Elem.',
  'Two Rivers Community School',
  'Two Rivers Dos Rios Elementary',
  'Two Rivers Elementary',
  'Two Rivers High',
  'Two Rivers High School',
  'Two Rivers Magnet High School',
  'Two Rivers Magnet Middle School',
  'Two Rivers Middle',
  'Two Rivers Pcs 4th St',
  'Two Rivers Pcs Young',
  'Two Rivers School',
  'Two Roads Charter School',
  'Two Rock Elementary',
  'Two Springs Elementary School',
  'Twombly Elementary School',
  'Tybee Island Maritime Academy School',
  'Tye River Elementary',
  'Tyee High School',
  'Tyee Middle School',
  'Tyee Park Elementary School',
  'Tygart Creek Elementary School',
  'Tygarts Valley Middle/high School',
  'Tyger River High',
  'Tyhee Elementary School',
  'Tyler Classical Academy',
  'Tyler Consolidated High School',
  'Tyler Consolidated Middle School',
  'Tyler Elementary School',
  'Tyler Es',
  'Tyler Heights Elementary',
  'Tyler Isd Early College H S',
  'Tyler Junior College',
  'Tyler Road Elementary School',
  'Tyler Run Elementary School',
  'Tyler Skills Elementary',
  'Tylertown Elementary School',
  'Tylertown High School',
  'Tylertown Primary School',
  'Tynan Early Childhood Campus',
  'Tyndall Center',
  'Tyndall Elementary - 04',
  'Tyndall Elementary School',
  'Tyner Academy',
  'Tyner Elementary School',
  'Tyner Middle Academy',
  'Tynes Elementary School',
  'Tyngsborough Elementary',
  'Tyngsborough High School',
  'Tyngsborough Middle',
  'Tyro Elementary',
  'Tyro Middle',
  'Tyrone Area El Sch',
  'Tyrone Area Hs',
  'Tyrone Area Ms',
  'Tyrone Elementary School',
  'Tyrone Es',
  'Tyrone Hs',
  'Tyrone Middle School',
  'Tyronza Elementary',
  'Tyrrell El',
  'Tyrrell Elementary',
  'Tyrrell Middle School',
  'Tyson-schoener El Sch',
  'Tyumen State Agricultural Academy',
  'Tyumen State University',
  'Tyumen State University of Gas and Oil',
  'Tzouanakis Intermediate School',
  'Tzu Chi College of Medicine and Humanities',
  'Télé-université Université du Québec',
  'Türkisch-Deutsche Universität',
  'U Of L Pact Program',
  'U S Grant',
  'U S Grant Elem School',
  'U S Grant Middle School',
  'U U WORLD OF CHILDREN',
  'U-32 High School (uhsd #32)',
  'U. B. Kinsey/palmview Elementary',
  'U. S. Grant Hs',
  'U.SCHOOL',
  'UAB ENGEL THERAPEUTIC SCHOOL AND DAY TREATMENT',
  'UBIC ACADEMY',
  'UCAN ACADEMY',
  'UCIC SCHOOL',
  'UCO EARLY BEGINNINGS ACADEMY',
  'UCP-ASSOC GREATER SUFFOLK INC',
  'UCSI University',
  'UINTA ACADEMY',
  'UINTAH BASIN CHRISTIAN ACADEMY',
  'UMC OF THE COVENANT CHILD DEVELOPMENT CENTER',
  'UMMATI SCHOOL OF TAMPA',
  'UMPQUA VALLEY CHRISTIAN SCHOOL',
  "UNDER CARREY'S CARE CHILDCARE CENTER",
  'UNDER THE SUN LEARNING CENTER',
  'UNDERCROFT MONTESSORI SCHOOL',
  'UNESCO-IHE Institute for Water Education',
  'UNICORN VILLAGE ACADEMY',
  'UNION BAPTIST KINDERGARTEN',
  'UNION BIBLE ACADEMY',
  'UNION CATHOLIC REGIONAL HIGH SCHOOL',
  'UNION CHRISTIAN ACADEMY',
  'UNION CHRISTIAN ACADEMY ELEMENTARY CAMPUS',
  'UNION CHRISTIAN DAY SCHOOL',
  'UNION GROVE CHRISTIAN SCHOOL',
  'UNION GROVE MENNONITE SCHOOL',
  'UNION HILL',
  'UNION HILL DAY SCHOOL',
  'UNION UMC KINDERGARTEN',
  'UNION VALLEY CHRISTIAN SCHOOL',
  'UNIQUES PRESCHOOL',
  'UNITARIAN MONTESSORI SCHOOL',
  'UNITED AUBURN INDIAN COMMUNITY TRIBAL SCHOOL',
  'UNITED CEREBRAL PALSY OF ULSTER COUNTY',
  'UNITED CEREBRAL PALSY TRANSITIONAL LEARNING',
  'UNITED CHINESE LEARNING CENTER',
  'UNITED CHRISTIAN ACADEMY',
  'UNITED CHRISTIAN SCHOOL',
  'UNITED DAY SCHOOL',
  'UNITED FAITH COMMUNITY DAY CARE CENTER',
  'UNITED FRIENDS SCHOOL',
  'UNITED IN CHRIST LUTHERAN SCHOOL',
  'UNITED METHODIST CHURCH OF THE GOOD SHEPHERD',
  'UNITED NATIONS INTERNATIONAL SCHOOL',
  'UNITED NATIONS INTL SCHOOL',
  'UNITED SEATTLE & BELLEVUE KOREAN SCHOOL',
  'UNITED TALMUDICAL ACADEMY',
  'UNITED TALMUDICAL ACADEMY OF BOROUGH PARK',
  'UNITED VALLEY CHRISTIAN ACADEMY',
  'UNITY CHILD CARE DEVELOPMENT LLC',
  'UNITY CHRISTIAN ACADEMY',
  'UNITY CHRISTIAN ELEMENTARY SCHOOL',
  'UNITY CHRISTIAN HIGH SCHOOL',
  'UNITY CHRISTIAN SCHOOL',
  'UNITY FOR KIDS SCHOOL',
  'UNITY LUTHERAN CHRISTIAN ELEMENTARY SCHOOL',
  'UNITY SCHOOL',
  'UNIV OF CHICAGO LAB SCHOOL',
  'UNIV OF MEMPHIS LIPMAN EARLY CHILDHOOD SCHOOL',
  'UNIVERSAL ACADEMY OF PITTSBURGH',
  'UNIVERSAL MUSLIM ACADEMY',
  'UNIVERSAL SCHOOL',
  'UNIVERSE OF FUTURE LEARNING',
  'UNIVERSITY ACADEMY',
  'UNIVERSITY ACADEMY OF CENTRAL LA',
  'UNIVERSITY BAPTIST ACADEMY',
  'UNIVERSITY CHILD DEVELOPMENT SCHOOL',
  'UNIVERSITY CHRISTIAN CHURCH WEEKDAY SCHOOL',
  'UNIVERSITY CHRISTIAN HIGH SCHOOL',
  'UNIVERSITY CHRISTIAN SCHOOL',
  'UNIVERSITY HIGH SCHOOL OF INDIANA',
  'UNIVERSITY LAKE SCHOOL',
  'UNIVERSITY MONTESSORI SCHOOL',
  'UNIVERSITY PARK ACADEMY',
  'UNIVERSITY PREP',
  'UNIVERSITY PRIMARY SCHOOL',
  'UNIVERSITY SCHOOL',
  'UNIVERSITY SCHOOL OF JACKSON',
  'UNIVERSITY SCHOOL OF NASHVILLE',
  'UNIVERSITY SCHOOL OF THE LOWCOUNTRY',
  'UNIVERSITY SCHOOL-MILWAUKEE',
  'UNIVERSITY SEVENTH-DAY ADVENTIST SCHOOL',
  'UPLAND CHRISTIAN ACADEMY',
  'UPLAND COUNTRY DAY SCHOOL',
  'UPLAND HILLS SCHOOL',
  'UPPER COLUMBIA ACADEMY - HIGH SCHOOL',
  'UPPER COLUMBIA ACADEMY ELEMENTARY SCHOOL',
  'UPPER GWYNEDD CHILD LEARNING CENTER',
  'UPPER MOUNTAIN VIEW SCHOOL',
  'UPPER ROOM MINISTRIES CHRISTIAN ACADEMY',
  'UPPER VALLEY CHRISTIAN SCHOOL',
  'UPPER VALLEY WALDORF SCHOOL',
  'UPSTATE CEREBRAL PALSY INC',
  'UPWARD ADVENTIST SCHOOL',
  'UPWARD HOPE ACADEMY',
  'UPWARD INTERNATIONAL SCHOOLS, PINES ACADEMY CAMPUS',
  'URBAN CHRISTIAN ACADEMY',
  'URBAN PRAIRIE WALFORF SCHOOL',
  'URBAN PROMISE ACADEMY',
  'URBAN PROMISE SCHOOL',
  'URBAN SCHOOL OF SAN FRANCISCO',
  'URBAN SKILLS CENTER',
  'URSULINE ACADEMY',
  'URSULINE ACADEMY (PK-12)',
  'URSULINE ACADEMY HIGH SCHOOL',
  'URSULINE ACADEMY OF CINCINNATI',
  'URSULINE ACADEMY OF DALLAS',
  'URSULINE HIGH SCHOOL',
  'URSULINE PRESCHOOL & KINDERGARTEN',
  'UTA OF KIRYAS JOEL-GIRLS',
  'UTICA CHRISTIAN SCHOOL',
  'UWHARRIE LEARNING CENTER',
  'Ubah Medical Academy Charter School',
  'Ubly Community Elementary School',
  'Ubly Community High School',
  'Ubonratchathani University',
  'Ucla Community K-12',
  'Ucon Elementary School',
  'Ucp Early Beginnings',
  'Ucp East Charter',
  'Ucp Of Mobile Inc',
  'Ucp Orange Charter',
  'Ucp Osceola Charter School',
  'Ucp Pine Hills Charter',
  'Ucp Seminole Child Development',
  'Ucp Transitional Learning Academy High Charter',
  'Ucp West Orange Charter',
  'Ucs Alternative Learning Center',
  'Udall Elem',
  'Udall High',
  'Udall Middle School',
  'Udall Road Middle School',
  'Udmurt State University',
  'Ueno Gakuen College',
  'Ufa State Aviation Technical University',
  'Ufa State Institute of Arts',
  'Ufa State Petroleum Technological University',
  'Uft Charter School',
  'Ufuk University',
  'Uganda Christian University',
  "Uganda Martyr's University",
  'Ugrad ? Esd123 Re-engagement Program',
  'Uhland El',
  'Uhs Of Laurel Heights',
  'Uhs Of Savannah Coastal Harbor Treatment Center',
  'Uinta Meadows Elementary',
  'Uintah High',
  'Uintah Middle School',
  'Uintah Online School',
  'Uintah River High',
  'Uintah School',
  'Uintah Specialized Preschool',
  'Uinversity of Babylon',
  'Uk Early Childhood Lab',
  'Ukhta State Technical University',
  'Ukiah High',
  'Ukiah Independent Study Academy',
  'Ukiah School',
  'Ukrainian Academy of Banking',
  'Ukrainian Academy of Pharmacy',
  'Ukrainian Medical Stomatological Academy',
  'Ukrainian National Forestry University',
  'Ulaanbaatar University',
  'Ulen-hitterdal Elementary',
  'Ulen-hitterdal Secondary',
  'Ulis Newton Elementary School',
  'Ulla F Muller Elementary School',
  'Ulloa Elementary',
  'Ulm 7-8',
  'Ulm School',
  'Ulrich Int',
  'Ulsan University',
  'Ulster Boces',
  'Ulster County Community College',
  'Uludag University',
  'Ulyanovsk Higher School of Civil Aviation',
  'Ulyanovsk State Agricultural Academy',
  'Ulyanovsk State Pedagogical University',
  'Ulyanovsk State Technical University',
  'Ulyanovsk State University',
  'Ulysses Byas Elementary School',
  'Ulysses Grant Elementary',
  'Ulysses High',
  'Ulysses S Grant Intermediate Sch',
  'Ulysses S. Grant Elem.',
  'Ulysses S. Grant Middle School',
  'Ulysses S. Grant School No. 7',
  'Ulysses S. Grant Senior High',
  "Umaru Musa Yar'Adua University",
  'Umatilla Elementary School',
  'Umatilla High School',
  'Umatilla Middle School',
  'Umdnj - Ctr',
  'Ume Preparatory Academy',
  'Ume Preparatory Academy - Duncanville',
  'Umea University',
  'Umm Al-Qura University',
  'Ummah University',
  'Umo N Ho N Nation Elem School',
  'Umo N Ho N Nation High School',
  'Umo N Ho N Nation Middle Sch',
  'Umphrey Lee El',
  'Umpire K-12 School',
  'Umpqua Community College',
  'Umutara Polytechnic',
  'Una Elementary',
  'Unadilla Elementary School',
  'Unadilla Valley Elementary School',
  'Unadilla Valley Secondary School',
  'Unaka Elementary',
  'Unaka High School',
  'Unalakleet School',
  'Unalaska Jr/sr High School',
  'Unami Ms',
  'Unassigned School',
  'Unatego Junior-senior High School',
  'Unatego Middle School',
  'Unc Hospital School',
  'Uncas Network School',
  'Uncg Early/middle College',
  'Uncharted Shores Academy',
  'Underdown Junior High School',
  'Underhill Central School',
  'Underhill Graded School',
  'Undermountain',
  'Underwood',
  'Underwood Alp',
  'Underwood El',
  'Underwood Elem.',
  'Underwood Elementary',
  'Underwood Elementary School',
  'Underwood High School',
  'Underwood Hills Early Childhood Learning Center',
  'Underwood Middle School',
  'Underwood Secondary',
  'Ungku Omar Premier Polytechnic',
  'Unicoi Co High School',
  'Unicoi Co Middle School',
  'Unicoi Elementary',
  'Unidos Dual Language School',
  'Unified Middle Virtual Academy',
  'Uniformed Services University of the Health Sciences',
  'Union 6th-7th Grade Ctr',
  'Union 8th Grade Ctr',
  'Union Academy',
  'Union Area Hs',
  'Union Area Ms',
  'Union Avenue Elementary',
  'Union Avenue Middle School',
  'Union Canal El Sch',
  'Union Center Elementary School',
  'Union Central Elementary School',
  'Union Chapel Elem.',
  'Union Chapel Elementary',
  'Union City Community Jr/sr High',
  'Union City El Sch',
  'Union City Elementary School',
  'Union City Es',
  'Union City High School',
  'Union City High School Early College',
  'Union City Hs',
  'Union City Middle School',
  'Union City Ms',
  'Union College',
  'Union Colony Elementary School',
  'Union Colony Preparatory School',
  'Union County Adult High School',
  'Union County Alternative Center',
  'Union County Area Vocational Ed Center',
  'Union County Career & Technical Institute',
  'Union County Career And Technology Center',
  'Union County College',
  'Union County Early College',
  'Union County Elementary School',
  'Union County High',
  'Union County High School',
  'Union County Learning Academy',
  'Union County Magnet High School',
  'Union County Middle School',
  'Union County Preschool And Headstart',
  'Union County Primary School',
  'Union County Teams Charter School',
  'Union County Tech',
  'Union County Virtual Instruction Program',
  'Union Cross Traditional Acad',
  'Union Day School',
  'Union East Elementary School',
  'Union Educational Complex',
  'Union Elem School',
  'Union Elementary',
  'Union Elementary School',
  'Union Es',
  'Union Furnace Elementary School',
  'Union Gap School',
  'Union Grove El',
  'Union Grove Elementary',
  'Union Grove Elementary School',
  'Union Grove H S',
  'Union Grove High',
  'Union Grove J H',
  'Union Grove Middle',
  'Union Grove Middle School',
  'Union Hall Elementary',
  'Union Heights Elementary',
  'Union High',
  'Union High School',
  'Union Hill El',
  'Union Hill Elementary',
  'Union Hill H S',
  'Union Hill Middle',
  'Union Hill Middle School',
  'Union Hill School',
  'Union House Elementary',
  'Union Hs',
  'Union Institute',
  'Union Intermediate',
  'Union Junior & High School',
  'Union Juvenile Residential Facility-three Springs',
  'Union Local Elementary School',
  'Union Local High School',
  'Union Local Middle School',
  'Union Memorial El Sch',
  'Union Memorial School',
  'Union Middle',
  'Union Middle School',
  'Union Mill Elementary',
  'Union Mine High',
  'Union Ninth Grade Center',
  'Union Parish Alternative Center',
  'Union Parish Elementary School',
  'Union Parish High School',
  'Union Parish Junior High School',
  'Union Park Elementary',
  'Union Park Middle',
  'Union Pines High',
  'Union Pleasant Avenue Elementary School',
  'Union Point Elementary',
  'Union Prep Academy At Indian Trail',
  'Union Primary School',
  'Union Regional School',
  'Union Ridge Elem School',
  'Union Ridge Elementary',
  'Union Sanborn School',
  'Union School',
  'Union Senior High',
  'Union Springs Elementary School',
  'Union Springs High School',
  'Union Springs Middle School',
  'Union Square Academy For Health Sciences',
  'Union Star Elem.',
  'Union Star High',
  'Union Street Charter',
  'Union Terrace El Sch',
  'Union Township Elementary School',
  'Union Township Middle School',
  'Union University',
  'Union Vale Middle School',
  'Union Valley Elem',
  'Union Valley Elementary School',
  'Union Virtual Franchise',
  'Union Virtual Instruction (course Offerings)',
  'Union Virtual Instruction Program (district Provided)',
  'Union-endicott High School',
  'Uniondale High School',
  'Uniontown Area Shs',
  'Uniontown Elementary School',
  'Uniontown High School',
  'Unionville El Sch',
  'Unionville Elementary',
  'Unionville Elementary School',
  'Unionville Hs',
  'Unionvillesebewaing Elem School',
  'Unionvillesebewaing High School',
  'Unionvillesebewaing Middle School',
  'Unioto Elementary',
  'Unioto High School',
  'Unioto Middle School',
  'Unis Middle School',
  'Unitec Career Ctr.',
  'United Arab Emirates University',
  'United Business Institute',
  'United Community Center Acosta Middle School',
  'United Community School',
  'United El Sch',
  'United Elem Sch - North',
  'United Elem Sch - West',
  'United Elementary School',
  'United For Success Academy',
  'United H S',
  'United High School',
  'United Hospital Adolescent Program',
  'United International University',
  'United Jr High Sch',
  'United Jshs',
  'United Medical and Dental Schools University of London',
  'United Middle',
  'United Nations University',
  'United North',
  'United Oaks Elementary School',
  'United Preparatory Academy',
  'United Preparatory Academy East',
  'United South',
  'United South Central Elementary',
  'United South Central High School',
  'United South H S',
  'United South Middle',
  'United States Air Force Academy',
  'United States International University',
  'United States Merchant Marine Academy',
  'United States Military Academy',
  'United States Naval Academy',
  'United Step Academy',
  'United Technical Center',
  'United Technologies Ctr-region 4',
  'United Twp High School',
  'Unitomo Surabaya',
  'Unity  University',
  'Unity Alternative School',
  'Unity Campus',
  'Unity Center For Urban Technologies',
  'Unity Charter School',
  'Unity Charter School Of Cape Coral',
  'Unity Charter School Of Fort Myers',
  'Unity Classical Charter',
  'Unity Drive Kindergarten-pre K Center',
  'Unity East',
  'Unity Elem School',
  'Unity Elementary',
  'Unity Elementary School',
  'Unity Elementary School (pk-5)',
  'Unity Grove Elementary School',
  'Unity High',
  'Unity High School',
  'Unity Jr High School',
  'Unity Middle',
  'Unity Middle College High',
  'Unity Middle School',
  'Unity Point Elem School',
  'Unity Preparatory Charter School Of Brooklyn',
  'Unity School',
  'Unity West',
  'Univ Of Chicago Charter Schools',
  'Univ Of Chicago Chtr - Woodson',
  'Univ Of Chicago Chtr-donoghue',
  'Univ Of Chicago Chtr-nth Kenwood',
  'Univ Of Chicago Chtr-woodlawn',
  'Univ Of Houston Charter Sch-tech',
  'Universal Academy',
  'Universal Academy - Coppell',
  'Universal Academy - Dallas',
  'Universal Academy Charter School',
  'Universal Academy For The College Bound',
  'Universal Alcorn Cs',
  'Universal Audenried Charter School',
  'Universal Bluford Charter School',
  'Universal Creighton Charter School',
  'Universal Daroff Charter School',
  'Universal Education Center',
  'Universal Institute Cs',
  'Universal Learning Academy',
  'Universal Vare Charter School',
  'Universidad "Adolfo Ibañez"',
  'Universidad "Arturo Prat"',
  'Universidad "Juan Agustín Maza"',
  'Universidad APEC',
  'Universidad Abierta Interactiva',
  'Universidad Abierta Interamericana',
  'Universidad Abierta Para Adultos',
  'Universidad Abierta y a Distancia de Panama',
  'Universidad Academia de Humanismo Cristiano',
  'Universidad Adolfo Ibáñez',
  'Universidad Adventista Dominicana',
  'Universidad Adventista de Bolivia',
  'Universidad Adventista de Centro América',
  'Universidad Adventista de Chile',
  'Universidad Adventista de Colombia',
  'Universidad Adventista del Plata',
  'Universidad Agraria del Ecuador',
  'Universidad Agroforestal Fernando A.Meriño',
  'Universidad Alas Peruanas',
  'Universidad Albert Einstein',
  'Universidad Alberto Hurtado',
  'Universidad Alfonso X El Sabio',
  'Universidad Amazonica de Pando',
  'Universidad Americana',
  'Universidad Andina Nestor Caceares Velasquez',
  'Universidad Andina Simón Bolivar',
  'Universidad Andina Simón Bolívar',
  'Universidad Andina del Cusco',
  'Universidad Antonio Nariño',
  'Universidad Antonio de Nebrija',
  'Universidad Anáhuac',
  'Universidad Anáhuac del Sur',
  'Universidad Arcis',
  'Universidad Argentina "John F. Kennedy"',
  'Universidad Argentina de la Empresa',
  'Universidad Atlantida Argentina',
  'Universidad Austral Buenos Aires',
  'Universidad Austral de Chile',
  'Universidad Autonoma  de Durango',
  'Universidad Autonoma España de Durango',
  'Universidad Autonoma Monterrey',
  'Universidad Autonoma de Chiriqui',
  'Universidad Autonoma del Sur',
  'Universidad Autónoma "Benito Juárez" de Oaxaca',
  'Universidad Autónoma Agraria "Antonio Narro"',
  'Universidad Autónoma Gabriel René Moreno',
  'Universidad Autónoma Juan Misael Saracho',
  'Universidad Autónoma Latinoamericana',
  'Universidad Autónoma Metropolitana',
  'Universidad Autónoma Metropolitana - Azcapotzalco',
  'Universidad Autónoma Metropolitana - Iztapalapa',
  'Universidad Autónoma Metropolitana - Xochimilco',
  'Universidad Autónoma Tomás Frías',
  'Universidad Autónoma de Aguascalientes',
  'Universidad Autónoma de Asunción',
  'Universidad Autónoma de Baja California',
  'Universidad Autónoma de Baja California Sur',
  'Universidad Autónoma de Bucaramanga',
  'Universidad Autónoma de Campeche',
  'Universidad Autónoma de Centro América',
  'Universidad Autónoma de Chapingo',
  'Universidad Autónoma de Chiapas',
  'Universidad Autónoma de Chihuahua',
  'Universidad Autónoma de Ciudad Juárez',
  'Universidad Autónoma de Coahuila',
  'Universidad Autónoma de Colombia',
  'Universidad Autónoma de Entre Ríos',
  'Universidad Autónoma de Fresnillo',
  'Universidad Autónoma de Guadalajara',
  'Universidad Autónoma de Guerrero',
  'Universidad Autónoma de Las Américas',
  'Universidad Autónoma de Madrid',
  'Universidad Autónoma de Manizales',
  'Universidad Autónoma de Nayarit',
  'Universidad Autónoma de Nuevo León',
  'Universidad Autónoma de Occidente',
  'Universidad Autónoma de Querétaro',
  'Universidad Autónoma de San Luis Potosí',
  'Universidad Autónoma de Santa Ana',
  'Universidad Autónoma de Santo Domingo',
  'Universidad Autónoma de Sinaloa',
  'Universidad Autónoma de Tamaulipas',
  'Universidad Autónoma de Tlaxcala',
  'Universidad Autónoma de Veracruz Villa Rica',
  'Universidad Autónoma de Yucatán',
  'Universidad Autónoma de Zacatecas',
  'Universidad Autónoma de la Ciudad de México',
  'Universidad Autónoma de la Laguna',
  'Universidad Autónoma del Beni "José Ballivián"',
  'Universidad Autónoma del Caribe',
  'Universidad Autónoma del Carmen',
  'Universidad Autónoma del Estado de Hidalgo',
  'Universidad Autónoma del Estado de Morelos',
  'Universidad Autónoma del Estado de México',
  'Universidad Autónoma del Noreste',
  'Universidad Autónoma del Paraguay',
  'Universidad Bellas Artes',
  "Universidad Bernardo O'Higgins",
  'Universidad Bicentenaria de Aragua',
  'Universidad Blas Pascal',
  'Universidad Bolivariana',
  'Universidad Bolivariana de Venezuela',
  'Universidad Braulio Carrillo',
  'Universidad CAECE Buenos Aires',
  'Universidad CES',
  'Universidad Capitain General Gerardo Barrios',
  'Universidad Cardenal Herrera-CEU',
  'Universidad Carlos III de Madrid',
  'Universidad Casa Grande',
  'Universidad Catolica "Redemptoris Mater"',
  'Universidad Catolica de Azogues',
  'Universidad Catolica de La Santísima Concepción',
  'Universidad Catolica de Temuco',
  'Universidad Catolica de Valparaiso',
  'Universidad Catolica del Maule',
  'Universidad Catolica del Norte',
  'Universidad Católica Andres Bello',
  'Universidad Católica Anselmo Llorente',
  'Universidad Católica Argentina Santa Maria de los Buenos Aires',
  'Universidad Católica Boliviana Cochabamba',
  'Universidad Católica Boliviana La Paz',
  'Universidad Católica Boliviana Santa Cruz',
  'Universidad Católica Boliviana Tarija',
  'Universidad Católica Cardenal Raúl Silva Henríquez',
  'Universidad Católica Cecilio Acosta',
  'Universidad Católica Madre y Maestra',
  'Universidad Católica Nordestana',
  'Universidad Católica Nuestra Senora de la Asunción',
  'Universidad Católica Popular del Risaralda',
  'Universidad Católica San Antonio',
  'Universidad Católica Santa María La Antigua',
  'Universidad Católica Santo Toribio de Mogrovejo',
  'Universidad Católica Tecnológica del Cibao',
  'Universidad Católica de Colombia',
  'Universidad Católica de Cuenca',
  'Universidad Católica de Cuyo',
  'Universidad Católica de Córdoba',
  'Universidad Católica de Honduras',
  'Universidad Católica de La Plata',
  'Universidad Católica de Manizales',
  'Universidad Católica de Occidente',
  'Universidad Católica de Salta',
  'Universidad Católica de Santa Fé',
  'Universidad Católica de Santa María',
  'Universidad Católica de Santiago de Guayaquil',
  'Universidad Católica de Santiago del Estero',
  'Universidad Católica de Santo Domingo',
  'Universidad Católica de Valencia',
  'Universidad Católica de Ávila',
  'Universidad Católica del Norte',
  'Universidad Católica del Oriente',
  'Universidad Católica del Táchira',
  'Universidad Católica del Uruguay',
  'Universidad Central',
  'Universidad Central Dominicana de Estudio Profesionales',
  'Universidad Central de Bayamón',
  'Universidad Central de Las Villas',
  'Universidad Central de Venezuela',
  'Universidad Central del Caribe',
  'Universidad Central del Ecuador',
  'Universidad Central del Este',
  'Universidad Central del Valle del Cauca',
  'Universidad Centro Occidental Lisandro Alvarado',
  'Universidad Centroamericana',
  'Universidad Centroamericana "José Simeón Canas"',
  'Universidad Centroamericana de Ciencias Empresariales (UCEM)',
  'Universidad Champagnat',
  'Universidad Chapultepéc',
  'Universidad Chileno Britanica de Cultura',
  'Universidad Ciencias Comerciales',
  'Universidad Cientifica Latinoamericana de Hidalgo',
  'Universidad Cientifica del Sur',
  'Universidad Columbia del Paraguay',
  'Universidad Complutense de Madrid',
  'Universidad Comunera',
  'Universidad Cooperativa de Colombia',
  'Universidad Cristiana de las Asambleas de Dios',
  'Universidad Cristóbal Colón',
  'Universidad Cuauhtémoc',
  'Universidad César Vallejo',
  'Universidad Diego Portales',
  'Universidad Distral "Francisco José de Caldas"',
  'Universidad Domingo Savio',
  'Universidad Dominicana O&M',
  'Universidad Dominico-Americana',
  'Universidad Don Bosco',
  'Universidad Don Vasco',
  'Universidad Dr. Andreas Bello',
  'Universidad Dr. Jose Matias Delgado',
  'Universidad Dr. Rafael Belloso Chacín',
  'Universidad EAFIT',
  'Universidad El Bosque',
  'Universidad Empresarial',
  'Universidad Empresarial Mateo Kuljis',
  'Universidad Empresarial Siglio 21',
  'Universidad España de Durango',
  'Universidad Especializada de Las Americas',
  'Universidad Estatal a Distancia',
  'Universidad Estatal de Bolívar',
  'Universidad Eugenio Maria de Hostos',
  'Universidad Europea de Madrid',
  'Universidad Evangélica de El Salvador',
  'Universidad Experimental Felix Adam',
  'Universidad Externado de Colombia',
  'Universidad Federico Henríquez y Carvajal',
  'Universidad Femenina del Sagrado Corazón',
  'Universidad Fermin Toro',
  'Universidad Fidélitas',
  'Universidad Finis Terrae',
  'Universidad Franciscana de México',
  'Universidad Francisco Gavidia',
  'Universidad Francisco Marroquín',
  'Universidad Francisco de Aguirre',
  'Universidad Francisco de Paula Santander',
  'Universidad Francisco de Vitoria',
  'Universidad Franco-Mexicana',
  'Universidad Fray Luca Paccioli',
  'Universidad Fundepos Alma Mater',
  'Universidad Gabriela Mistral',
  'Universidad Galileo',
  'Universidad Gran Mariscal de Ayacucho',
  'Universidad Hispanoamericana',
  'Universidad ICESI',
  'Universidad INCCA de Colombia',
  'Universidad Iberoamericana',
  'Universidad Iberoamericana Campus León',
  'Universidad Iberoamericana de Ciencia y Tecnologia',
  'Universidad Iberoamericana de Ciencias y Tecnologia',
  'Universidad Industrial de Santander',
  'Universidad Insurgentes Plantel León',
  'Universidad Interamericana',
  'Universidad Interamericana de Educacion a Distancia de Panama',
  'Universidad Interamericana de Puerto Rico',
  'Universidad Interamericana de Puerto Rico Bayamón',
  'Universidad Interamericana de Puerto Rico San Germán',
  'Universidad Intercontinental',
  'Universidad Internacional Menéndez Pelayo',
  'Universidad Internacional SEK Quito',
  'Universidad Internacional de Andalucía',
  'Universidad Internacional de Andalucía Sede Antonio Machado de Baeza',
  'Universidad Internacional de Andalucía Sede La Rábida',
  'Universidad Internacional de Integración de América Latina',
  'Universidad Internacional de las Américas',
  'Universidad Internacional del Ecuador',
  'Universidad International SEK Santiago',
  'Universidad Isaac Newton',
  'Universidad Itaca',
  'Universidad José Antonio Páez',
  'Universidad José Cecilio del Valle',
  'Universidad José Maria Vargas',
  'Universidad José Santos Ossa',
  'Universidad Juan Misael Saracho',
  'Universidad Justo Sierra',
  'Universidad Juárez Autónoma de Tabasco',
  'Universidad Juárez del Estado de Durango',
  'Universidad Jóse Vasconcelos',
  'Universidad Kino',
  'Universidad La Gran Colombia',
  'Universidad La Republica',
  'Universidad La Salle',
  'Universidad La Salle Cuernavaca',
  'Universidad Laica "Eloy Alfaro" de Manabi',
  'Universidad Laica "Vicente Rocafuerte" de Guayaquil',
  'Universidad Lasallista Benavente',
  'Universidad Latina',
  'Universidad Latina de Costa Rica',
  'Universidad Latina de Panamá',
  'Universidad Latinoamericana',
  'Universidad Latinoamericana de Ciencia y Tecnologia Panamá',
  'Universidad Latinoamericana de Ciencia y Tecnología',
  'Universidad Libre de Colombia',
  'Universidad Libre de Costa Rica',
  'Universidad Los Andes',
  'Universidad Los Angeles de Chimbote',
  'Universidad Luterana Salvadorena',
  'Universidad Madero',
  'Universidad Maimónides',
  'Universidad Marcelino Champagnat',
  'Universidad Mariana',
  'Universidad Mariano Egaña',
  'Universidad Mariano Gálvez',
  'Universidad Maritima de Chile',
  'Universidad Mayor',
  'Universidad Mayor de San Andrés',
  'Universidad Mayor de San Simón',
  'Universidad Mesoamericana de San Agustín',
  'Universidad Metropolitana',
  'Universidad Metropolitana Castro Carazo',
  'Universidad Metropolitana Latin Campus',
  'Universidad Metropolitana de Asunción',
  'Universidad Metropolitana de Ciencias de la Educación',
  'Universidad Metropolitana de Honduras',
  'Universidad Metropolitana de Monterrey',
  'Universidad Mexicana',
  'Universidad Mexicana del Noreste',
  'Universidad Michoacana de San Nicolás de Hidalgo',
  'Universidad Miguel Hernández de Elche',
  'Universidad Miguel de Cervantes',
  'Universidad Militar Nueva Granada',
  'Universidad Modular Abierta',
  'Universidad Monsenor Oscar Arnulfo Romeo',
  'Universidad Monteávila',
  'Universidad Motolinía del Pedegral',
  'Universidad Motolinía del Pedregal',
  'Universidad México Americana del Norte',
  'Universidad México Internacional',
  'Universidad NUR',
  'Universidad Nacional',
  'Universidad Nacional Abierta',
  'Universidad Nacional Abierta y a Distancia',
  'Universidad Nacional Agraria',
  'Universidad Nacional Agraria La Molina',
  'Universidad Nacional Agraria de la Selva',
  'Universidad Nacional Andrés Bello',
  'Universidad Nacional Autonoma de Nicaragua',
  'Universidad Nacional Autónoma de Honduras',
  'Universidad Nacional Autónoma de México',
  'Universidad Nacional Daniel Alcides Carrion',
  'Universidad Nacional Evangélica',
  'Universidad Nacional Experimental "Francisco de Miranda"',
  'Universidad Nacional Experimental "Rafael Maria Baralt"',
  'Universidad Nacional Experimental "Simón Rodriguez"',
  'Universidad Nacional Experimental Politécnica "Antonio José de Sucre"',
  'Universidad Nacional Experimental Romulo Gallegos',
  'Universidad Nacional Experimental Sur del Lago "Jesús Maria Semprum"',
  'Universidad Nacional Experimental de Guayana',
  'Universidad Nacional Experimental de la Fuerza Armada',
  'Universidad Nacional Experimental de los Llanos Occidentales "Ezequiel Zamora"',
  'Universidad Nacional Experimental del Táchira',
  'Universidad Nacional Federico Villarreal',
  'Universidad Nacional Hermilio Valdizan',
  'Universidad Nacional Jorge Basadre Grohmann',
  'Universidad Nacional José Faustino Sánchez Carrión',
  'Universidad Nacional Mayor de San Marcos',
  'Universidad Nacional Pedro Henríquez Ureña',
  'Universidad Nacional Pedro Ruíz Gallo',
  'Universidad Nacional San Luis Gonzaga',
  'Universidad Nacional Santiago Antúnez de Mayolo',
  'Universidad Nacional Siglo XX Llallagua',
  'Universidad Nacional de Asunción',
  'Universidad Nacional de Cajamarca',
  'Universidad Nacional de Catamarca',
  'Universidad Nacional de Chimborazo',
  'Universidad Nacional de Colombia',
  'Universidad Nacional de Cuyo',
  'Universidad Nacional de Córdoba',
  'Universidad Nacional de Educación Enrique Guzmán y Valle',
  'Universidad Nacional de Educación a Distancia',
  'Universidad Nacional de Entre Ríos',
  'Universidad Nacional de Formosa',
  'Universidad Nacional de General San Martín',
  'Universidad Nacional de General Sarmiento',
  'Universidad Nacional de Guinea Ecuatorial',
  'Universidad Nacional de Ingenieria',
  'Universidad Nacional de Ingeniería',
  'Universidad Nacional de Jujuy',
  'Universidad Nacional de La Libertad Trujillo',
  'Universidad Nacional de La Matanza',
  'Universidad Nacional de La Pampa',
  'Universidad Nacional de La Plata',
  'Universidad Nacional de La Rioja',
  'Universidad Nacional de Lanus',
  'Universidad Nacional de Loja',
  'Universidad Nacional de Lomas de Zamora',
  'Universidad Nacional de Luján',
  'Universidad Nacional de Mar del Plata',
  'Universidad Nacional de Misiones',
  'Universidad Nacional de Piura',
  'Universidad Nacional de Quilmes',
  'Universidad Nacional de Rosario',
  'Universidad Nacional de Río Cuarto',
  'Universidad Nacional de Salta',
  'Universidad Nacional de San Agustin',
  'Universidad Nacional de San Antonio Abad',
  'Universidad Nacional de San Cristóbal de Huamanga',
  'Universidad Nacional de San Juan',
  'Universidad Nacional de San Luis',
  'Universidad Nacional de San Martín',
  'Universidad Nacional de Santa - Chimbote',
  'Universidad Nacional de Santiago del Estero',
  'Universidad Nacional de Tres de Febrero',
  'Universidad Nacional de Tucumán',
  'Universidad Nacional de Tumbes',
  'Universidad Nacional de Ucayali',
  'Universidad Nacional de Villa María',
  'Universidad Nacional de la Amazonía Peruana',
  'Universidad Nacional de la Patagonia "San Juan Bosco"',
  'Universidad Nacional de la Patagonia Austral',
  'Universidad Nacional del Altiplano',
  'Universidad Nacional del Callao',
  'Universidad Nacional del Centro de la Provincia de Buenos Aires',
  'Universidad Nacional del Centro del Perú',
  'Universidad Nacional del Comahue',
  'Universidad Nacional del Este',
  'Universidad Nacional del Littoral',
  'Universidad Nacional del Nordeste',
  'Universidad Nacional del Noroeste de la Provincia de Buenos Aires',
  'Universidad Nacional del Sur',
  'Universidad Nicaragüense de Ciencia y Tecnológica',
  'Universidad Norbert Wiener',
  'Universidad Notarial Argentina',
  'Universidad Nuestra Senora de La Paz',
  'Universidad Nueva Esparta',
  'Universidad Nueva San Salvador',
  'Universidad Nuevo Mundo',
  'Universidad ORT Uruguay',
  'Universidad Obrera de Mexico',
  'Universidad Odontológica Dominicana',
  'Universidad Olmeca',
  'Universidad Pablo de Olavide',
  'Universidad Panamericana',
  'Universidad Panamericana de San Salvador',
  'Universidad Particular Inca Garcilaso de la Vega',
  'Universidad Particular de Chiclayo',
  'Universidad Pedagogica Nacional',
  'Universidad Pedagógica "José Martí" Camagüey',
  'Universidad Pedagógica Experimental Libertador',
  'Universidad Pedagógica Nacional',
  'Universidad Pedagógica Nacional "Francisco Morazán"',
  'Universidad Pedagógica Nacional Unidad León',
  'Universidad Pedagógica Veracruzana',
  'Universidad Pedagógica de El Salvador',
  'Universidad Pedagógica y Tecnológica de Colombia',
  'Universidad Peruana Cayetano Heredia',
  'Universidad Peruana Union',
  'Universidad Peruana de Ciencias Aplicadas',
  'Universidad Peruana de Ciencias e Informática',
  'Universidad Piloto de Colombia',
  'Universidad Politecnica de Nicaragua',
  'Universidad Politecnica de Puerto Rico',
  'Universidad Politécnica Salesiana',
  'Universidad Politécnica de Cartagena',
  'Universidad Politécnica de Cataluna',
  'Universidad Politécnica de El Salvador',
  'Universidad Politécnica de Madrid',
  'Universidad Politécnica de Valencia',
  'Universidad Pontificia Bolivariana',
  'Universidad Pontificia Comillas',
  'Universidad Pontificia de Salamanca',
  'Universidad Popular Autonóma del Estado de Puebla',
  'Universidad Popular de Nicaragua (UPONIC)',
  'Universidad Popular del Cesar',
  'Universidad Privada Abierta Latinoamericana',
  'Universidad Privada Antenor Orrego',
  'Universidad Privada Boliviana',
  'Universidad Privada Franz Tamayo (UNIFRANZ)',
  'Universidad Privada Juan Mejía Baca',
  'Universidad Privada Los Andes',
  'Universidad Privada San Juan Bautista',
  'Universidad Privada San Pedro',
  'Universidad Privada Sergio Bernales',
  'Universidad Privada de Santa Cruz de la Sierra',
  'Universidad Privada de Tacna',
  'Universidad Privada del Este',
  'Universidad Privada del Norte',
  'Universidad Privada del Valle',
  'Universidad Pública de Navarra',
  'Universidad Rafael Landívar',
  'Universidad Rafael Urdaneta',
  'Universidad Regiomontana',
  'Universidad Regional Miguel Hidalgo',
  'Universidad Regional del Sureste',
  'Universidad Rey Juan Carlos',
  'Universidad Ricardo Palma',
  'Universidad Rural de Guatemala',
  'Universidad Salesiana',
  'Universidad Salesiana de Bolivia',
  'Universidad Salvadorena "Alberto Masferrer"',
  'Universidad San Francisco Xavier',
  'Universidad San Francisco Xavier de Chuquisaca',
  'Universidad San Francisco de Quito',
  'Universidad San Ignacio de Loyola',
  'Universidad San Juan de la Cruz',
  'Universidad San Marcos',
  'Universidad San Martin de Porres',
  'Universidad San Pablo',
  'Universidad San Sebastian',
  'Universidad Santa Fe',
  'Universidad Santa Maria Caracas',
  'Universidad Santa María Campus Guayaquil',
  'Universidad Santa Paula',
  'Universidad Santiago de Cali',
  'Universidad Santo Tomás',
  'Universidad Sergio Arboleda',
  'Universidad Señor de Sipán',
  'Universidad Simón Bolivar',
  'Universidad Surcolombiana',
  'Universidad Tecnologica "Vicente Perez Rosales"',
  'Universidad Tecnologica Israel',
  'Universidad Tecnologica de Nezahualcoyotl',
  'Universidad Tecnologica de la Mixteca',
  'Universidad Tecnológica América',
  'Universidad Tecnológica Centroamericana',
  'Universidad Tecnológica Equinoccial',
  'Universidad Tecnológica Indoamérica',
  'Universidad Tecnológica Metropolitana',
  'Universidad Tecnológica Nacional',
  'Universidad Tecnológica Oteima',
  'Universidad Tecnológica San Antonio de Machala',
  'Universidad Tecnológica de El Salvador',
  'Universidad Tecnológica de Honduras',
  'Universidad Tecnológica de México',
  'Universidad Tecnológica de Panamá',
  'Universidad Tecnológica de Pereira',
  'Universidad Tecnológica de Santiago',
  'Universidad Tecnológica de los Andes de Apurímac',
  'Universidad Tecnológica del Centro',
  'Universidad Tecnológica del Choco "Diego Luis Cordoba"',
  'Universidad Tecnológica del Peru',
  'Universidad Tecnológica del Sur',
  'Universidad Torcuato di Tella',
  'Universidad Técnica "Federico Santa María"',
  'Universidad Técnica Estatal de Quevedo',
  'Universidad Técnica Latinoamericana',
  'Universidad Técnica Particular de Loja',
  'Universidad Técnica de Ambato',
  'Universidad Técnica de Babahoyo',
  'Universidad Técnica de Cotopaxi',
  'Universidad Técnica de Esmeraldas "Luis Vargas Torres"',
  'Universidad Técnica de Machala',
  'Universidad Técnica de Manabí',
  'Universidad Técnica de Oruro',
  'Universidad Técnica del Norte',
  'Universidad Ulises Francisco Espaillat',
  'Universidad Valle del Grijalva',
  'Universidad Valle del Momboy',
  'Universidad Veracruzana',
  'Universidad Veritas',
  'Universidad Xicotepetl',
  'Universidad Yacambu',
  'Universidad de Aconcagua',
  'Universidad de Alcalá de Henares',
  'Universidad de Alicante',
  'Universidad de Almería',
  'Universidad de América Bogotá',
  'Universidad de Antioquia',
  'Universidad de Antioquía',
  'Universidad de Antofagasta',
  'Universidad de Aquino Bolivia',
  'Universidad de Artes Ciencias y Comunicación',
  'Universidad de Atacama',
  'Universidad de Belgrano',
  'Universidad de Bogotá "Jorge Tadeo Lozano"',
  'Universidad de Bologna - Representación en Buenos Aires',
  'Universidad de Buenos Aires',
  'Universidad de Burgos',
  'Universidad de Caldas',
  'Universidad de Camagüey',
  'Universidad de Cantabria',
  'Universidad de Carabobo',
  'Universidad de Cartagena',
  'Universidad de Cartago',
  'Universidad de Cartago Florencio del Castillo',
  'Universidad de Castilla La Mancha',
  'Universidad de Celaya',
  'Universidad de Chile',
  'Universidad de Ciego de Avila',
  'Universidad de Ciencias Aplicadas y Ambientales (UDCA)',
  'Universidad de Ciencias Empresariales y Sociales',
  'Universidad de Ciencias de la Informatica',
  'Universidad de Ciencias y Artes de Chiapas',
  'Universidad de Ciencias y Humanidades',
  'Universidad de Cienfuegos',
  'Universidad de Colima',
  'Universidad de Comunicación Avanzada',
  'Universidad de Concepcion',
  'Universidad de Concepción del Uruguay',
  'Universidad de Congreso',
  'Universidad de Costa Rica',
  'Universidad de Cuautitlan Izcalli',
  'Universidad de Cuenca',
  'Universidad de Cádiz',
  'Universidad de Córdoba',
  'Universidad de Deusto',
  'Universidad de El Salvador',
  'Universidad de Especialidades Turísticas',
  'Universidad de Especialidades del Espíritu Santo',
  'Universidad de Extremadura',
  'Universidad de Flores - Buenos Aires',
  'Universidad de Gerona',
  'Universidad de Granada',
  'Universidad de Granma',
  'Universidad de Guadalajara',
  'Universidad de Guanajuato',
  'Universidad de Guanajuato Campus León',
  'Universidad de Guayaquil',
  'Universidad de Hermosillo',
  'Universidad de Holguín',
  'Universidad de Huelva',
  'Universidad de Huánuco',
  'Universidad de Iberoamérica',
  'Universidad de Jaén',
  'Universidad de La Amazonia',
  'Universidad de La Coruña',
  'Universidad de La Frontera',
  'Universidad de La Guajira',
  'Universidad de La Habana',
  'Universidad de La Laguna',
  'Universidad de La Rioja',
  'Universidad de La Sabana',
  'Universidad de La Salle',
  'Universidad de La Salle Bajío',
  'Universidad de La Serena',
  'Universidad de Lambayeque',
  'Universidad de Las Américas',
  'Universidad de Las Palmas de Gran Canaria',
  'Universidad de León',
  'Universidad de Lima',
  'Universidad de Los Andes',
  'Universidad de Los Lagos',
  'Universidad de Los Llanos',
  'Universidad de Magallanes',
  'Universidad de Managua (U de M)',
  'Universidad de Manizales',
  'Universidad de Matanzas Camilo Cienfuegos',
  'Universidad de Medellín',
  'Universidad de Mendoza',
  'Universidad de Montemorelos',
  'Universidad de Monterrey',
  'Universidad de Montevideo',
  'Universidad de Morón',
  'Universidad de Murcia',
  'Universidad de Málaga',
  'Universidad de Nariño',
  'Universidad de Navarra',
  'Universidad de Norteamerica',
  'Universidad de Occidente',
  'Universidad de Oriente',
  'Universidad de Oviedo',
  'Universidad de Palermo',
  'Universidad de Pamplona',
  'Universidad de Panamá',
  'Universidad de Pinar del Río',
  'Universidad de Piura',
  'Universidad de Playa Ancha de Ciencias de la Educacion',
  'Universidad de Puerto Rico',
  'Universidad de Puerto Rico Aguadilla',
  'Universidad de Puerto Rico Arecibo',
  'Universidad de Puerto Rico Bayamon',
  'Universidad de Puerto Rico Carolina',
  'Universidad de Puerto Rico Cayey',
  'Universidad de Puerto Rico Ciencias Medicas',
  'Universidad de Puerto Rico Humacao',
  'Universidad de Puerto Rico Mayaguez',
  'Universidad de Puerto Rico Ponce',
  'Universidad de Puerto Rico Rio Pedras',
  'Universidad de Puerto Rico Utuado',
  'Universidad de Quintana Roo',
  'Universidad de Salamanca',
  'Universidad de San Andres',
  'Universidad de San Buenaventura',
  'Universidad de San Buenaventura Medellin',
  'Universidad de San Carlos de Guatemala',
  'Universidad de San José',
  'Universidad de San Miguel',
  'Universidad de San Pablo CEU',
  'Universidad de San Pedro Sula',
  'Universidad de Santiago de Chile',
  'Universidad de Santiago de Compostela',
  'Universidad de Sevilla',
  'Universidad de Sonora',
  'Universidad de Sonsonate',
  'Universidad de Sucre',
  'Universidad de Talca',
  'Universidad de Tarapacá',
  'Universidad de Valencia',
  'Universidad de Valladolid',
  'Universidad de Valparaiso',
  'Universidad de Vigo',
  'Universidad de Viña del Mar',
  'Universidad de Zaragoza',
  'Universidad de la Cuenca del Plata',
  'Universidad de la Empresa (UDE)',
  'Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)',
  'Universidad de la Integración de las Americas (UNIDAD)',
  'Universidad de la Marina Mercante',
  'Universidad de la República',
  'Universidad de la Sierra A.C.',
  'Universidad de la Tercera Edad',
  'Universidad de las Américas',
  'Universidad de las Américas (ULAM)',
  'Universidad de las Américas - Puebla',
  'Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense',
  'Universidad del Aconcagua',
  'Universidad del Atlántico',
  'Universidad del Azuay',
  'Universidad del Bajío',
  'Universidad del Bío-Bío',
  'Universidad del Caribe',
  'Universidad del Cauca',
  'Universidad del Centro Educativo Latinoamericano Rosario',
  'Universidad del Centro de México',
  'Universidad del Cine - Buenos Aires',
  'Universidad del Cono Sur de las Américas',
  'Universidad del Desarrollo',
  'Universidad del Istmo',
  'Universidad del Magdalena',
  'Universidad del Mar',
  'Universidad del Mayab',
  'Universidad del Museo Social Argentino',
  'Universidad del Noreste',
  'Universidad del Noroeste',
  'Universidad del Norte',
  'Universidad del Norte "Santo Tomás de Aquino"',
  'Universidad del Pacifico',
  'Universidad del Pacífico',
  'Universidad del País Vasco',
  'Universidad del Quindío',
  'Universidad del Rosario',
  'Universidad del Sagrado Corazon',
  'Universidad del Salvador',
  'Universidad del Sinú',
  'Universidad del Tepeyac',
  'Universidad del Tolima',
  'Universidad del Turabo',
  'Universidad del Turismo',
  'Universidad del Valle de Atemajac',
  'Universidad del Valle de Guatemala',
  'Universidad del Valle de México',
  'Universidad del Valle de Puebla',
  'Universidad del Valle de Toluca',
  'Universidad del Valle del Bravo',
  'Universidad del Valle del Cauca',
  'Universidad del Zulia',
  'Universidad la Concordia',
  'Universidad para la Cooperación Internacional',
  'Universidade Aberta Lisboa',
  'Universidade Atlântica',
  'Universidade Autónoma de Lisboa Luís de Camoes',
  'Universidade Bandeirante de São Paulo',
  'Universidade Braz Cubas',
  'Universidade Camilo Castelo Branco',
  'Universidade Candido Mendes',
  'Universidade Castelo Branco',
  'Universidade Catolica Portuguesa',
  'Universidade Católica Dom Bosco',
  'Universidade Católica de Angola',
  'Universidade Católica de Brasília',
  'Universidade Católica de Goiás',
  'Universidade Católica de Moçambique',
  'Universidade Católica de Pelotas',
  'Universidade Católica de Pernambuco',
  'Universidade Católica de Petrópolis',
  'Universidade Católica de Salvador',
  'Universidade Católica de Santos',
  'Universidade Cidade de São Paulo',
  'Universidade Cruzeiro do Sul',
  'Universidade Eduardo Mondlane',
  'Universidade Estadual Paulista',
  'Universidade Estadual Sudoeste da Bahia',
  'Universidade Estadual da Paraíba',
  'Universidade Estadual de Campinas',
  'Universidade Estadual de Feira de Santana',
  'Universidade Estadual de Londrina',
  'Universidade Estadual de Maringá',
  'Universidade Estadual de Montes Claros',
  'Universidade Estadual de Ponta Grossa',
  'Universidade Estadual de Santa Cruz',
  'Universidade Estadual do Ceará',
  'Universidade Estadual do Maranhão',
  'Universidade Estadual do Norte Fluminense',
  'Universidade Estadual do Oeste do Paraná',
  'Universidade Estadual do Piauí',
  'Universidade Estadual do Vale do Acaraú',
  'Universidade Estácio de Sá',
  'Universidade Federal Fluminense',
  'Universidade Federal Rural de Pernambuco',
  'Universidade Federal Rural do Rio de Janeiro',
  'Universidade Federal da Bahia',
  'Universidade Federal da Grande Dourados',
  'Universidade Federal da Paraíba',
  'Universidade Federal de Alagoas',
  'Universidade Federal de Campina Grande',
  'Universidade Federal de Goiás',
  'Universidade Federal de Juiz de Fora',
  'Universidade Federal de Lavras',
  'Universidade Federal de Mato Grosso',
  'Universidade Federal de Mato Grosso do Sul',
  'Universidade Federal de Minas Gerais',
  'Universidade Federal de Ouro Preto',
  'Universidade Federal de Pelotas',
  'Universidade Federal de Pernambuco',
  'Universidade Federal de Rondônia',
  'Universidade Federal de Roraima',
  'Universidade Federal de Santa Catarina',
  'Universidade Federal de Santa Maria',
  'Universidade Federal de Sergipe',
  'Universidade Federal de São Carlos',
  'Universidade Federal de São Paulo',
  'Universidade Federal de Uberlândia',
  'Universidade Federal de Viçosa',
  'Universidade Federal do ABC',
  'Universidade Federal do Acre',
  'Universidade Federal do Amapá',
  'Universidade Federal do Ceará',
  'Universidade Federal do Espírito Santo',
  'Universidade Federal do Maranhão',
  'Universidade Federal do Paraná',
  'Universidade Federal do Pará',
  'Universidade Federal do Piauí',
  'Universidade Federal do Rio Grande do Norte',
  'Universidade Federal do Rio Grande do Sul',
  'Universidade Federal do Rio de Janeiro',
  'Universidade Federal do Vale do São Francisco',
  'Universidade Fernando Pessoa',
  'Universidade Gama Filho',
  'Universidade Gregorio Semedo',
  'Universidade Ibirapuera',
  'Universidade Independente',
  'Universidade Independente de Angola',
  'Universidade Internacional Lisboa',
  'Universidade Jean Piaget de Cabo Verde',
  'Universidade Lusiada',
  'Universidade Lusíada de Angola',
  'Universidade Lusófona de Humanidades e Technologias',
  'Universidade Luterana do Brasil',
  'Universidade Mackenzie',
  'Universidade Metodista de Angola',
  'Universidade Metodista de Piracicaba',
  'Universidade Metropolitana de Angola',
  'Universidade Metropolitana de Santos',
  'Universidade Moderna de Lisboa',
  'Universidade Nova de Lisboa',
  'Universidade Paulista',
  'Universidade Portucalense Infante D. Henrique',
  'Universidade Potiguar',
  'Universidade Regional Integrada do Alto Uruguai e das Missões',
  'Universidade Regional de Blumenau',
  'Universidade Regional do Cariri',
  'Universidade Regional do Noroeste do Estado do Rio Grande do Sul',
  'Universidade Salgado de Oliveira',
  'Universidade Salvador',
  'Universidade Santa Cecília dos Bandeirantes',
  'Universidade Santa Úrsula',
  'Universidade São Francisco',
  'Universidade São Judas Tadeu',
  'Universidade São Marcos',
  'Universidade Tecnica de Angola',
  'Universidade Tecnica de Lisboa',
  'Universidade Tiradentes',
  'Universidade Vale do Paraiba',
  'Universidade Vale do Rio Doce',
  'Universidade Vale do Rio dos Sinos',
  'Universidade Veiga de Almeida',
  'Universidade da Amazônia',
  'Universidade da Beira Interior',
  'Universidade da Madeira',
  'Universidade da Região da Campanha',
  'Universidade de Alfenas',
  'Universidade de Aveiro',
  'Universidade de Brasília',
  'Universidade de Caxias do Sul',
  'Universidade de Coimbra',
  'Universidade de Cruz Alta',
  'Universidade de Evora',
  'Universidade de Fortaleza',
  'Universidade de Franca',
  'Universidade de Guarulhos',
  'Universidade de Itaúna',
  'Universidade de Lisboa',
  'Universidade de Marília',
  'Universidade de Mogi das Cruzes',
  'Universidade de Nova Iguaçu',
  'Universidade de Passo Fundo',
  'Universidade de Pernambuco',
  'Universidade de Ribeirão Preto',
  'Universidade de Santa Cruz do Sul',
  'Universidade de Santo Amaro',
  'Universidade de São Paulo',
  'Universidade de Taubaté',
  'Universidade de Tocantins',
  'Universidade de Tras-os-Montes e Alto Douro',
  'Universidade de Uberaba',
  'Universidade do Algarve',
  'Universidade do Amazonas',
  'Universidade do Estado da Bahia',
  'Universidade do Estado de Santa Catarina',
  'Universidade do Estado do Rio Grande do Norte',
  'Universidade do Estado do Rio de Janeiro',
  'Universidade do Extremo Sul Catarinense',
  'Universidade do Grande Rio',
  'Universidade do Minho',
  'Universidade do Oeste Paulista',
  'Universidade do Oeste de Santa Catarina',
  'Universidade do Porto',
  'Universidade do Rio Grande',
  'Universidade do Rio de Janeiro',
  'Universidade do Sagrado Coração',
  'Universidade do Sul de Santa Catarina',
  'Universidade do Vale do Itajaí',
  'Universidade dos Acores',
  'Universidade para o Desenvolvimento do Alto Vale do Itajaí',
  'Universita della Svizzera Italiana',
  'Universitas 17 Agustus 1945 Banyuwangi',
  'Universitas 17 Agustus 1945 Cirebon',
  'Universitas 17 Agustus 1945 Jakarta',
  'Universitas 17 Agustus 1945 Samarinda',
  'Universitas 17 Agustus 1945 Semarang',
  'Universitas 17 Agustus 1945 Surabaya',
  'Universitas Advent Indonesia',
  'Universitas Ahmad Dahlan',
  'Universitas Airlangga',
  'Universitas Amir Hamzah',
  'Universitas Andalas',
  'Universitas Atma Jaya Yogyakarta',
  'Universitas Bakrie',
  'Universitas Bengkulu',
  'Universitas Bhayangkara Jakarta Raya',
  'Universitas Bhayangkara Surabaya',
  'Universitas Bojonegoro',
  'Universitas Brawijaya',
  'Universitas Bunda Mulia Jakarta',
  'Universitas Bung Hatta',
  'Universitas Cenderawasih',
  'Universitas Darma Agung',
  'Universitas Darma Persada',
  'Universitas Dian Nuswantoro',
  'Universitas Diponegoro',
  'Universitas Dr. R. Moestopo',
  'Universitas Gadjah Mada',
  'Universitas Gunadarma',
  'Universitas HKBP Nommensen',
  'Universitas Haluoleo',
  'Universitas Hasanuddin',
  'Universitas Ibn Chaldun Jakarta',
  'Universitas Ibn Khaldun Bogor',
  'Universitas Indonesia',
  'Universitas Indonusa Esa Unggul',
  'Universitas Islam Bandung',
  'Universitas Islam Indonesia',
  'Universitas Islam Jakarta',
  'Universitas Islam Malang',
  'Universitas Islam Nusantara',
  'Universitas Islam Riau',
  'Universitas Islam Sultan Agung',
  'Universitas Islam Sumatera Utara',
  'Universitas Islam Syekh-Yusuf',
  'Universitas Jambi',
  'Universitas Janabadra',
  'Universitas Jayabaya',
  'Universitas Jember',
  'Universitas Jenderal Achmad Yani',
  'Universitas Jenderal Soedirman',
  'Universitas Katolik Indonesia Atma Jaya',
  'Universitas Katolik Parahyangan',
  'Universitas Katolik Soegijapranoto',
  'Universitas Katolik Widya Karya',
  'Universitas Katolik Widya Manadala',
  'Universitas Kediri',
  'Universitas Khairun',
  'Universitas Klabat',
  'Universitas Komputer Indonesia',
  'Universitas Krisnadwipayana',
  'Universitas Kristen Duta Wacana',
  'Universitas Kristen Indonesia',
  'Universitas Kristen Indonesia Tomohon',
  'Universitas Kristen Krida Wacana',
  'Universitas Kristen Maranatha',
  'Universitas Kristen Petra',
  'Universitas Kristen Satya Wacana',
  'Universitas Lambung Mangkurat',
  'Universitas Lampung',
  'Universitas Lancang Kuning',
  'Universitas Ma Chung',
  'Universitas Madura',
  'Universitas Mahasaraswati Denpasar',
  'Universitas Mahendradatta',
  'Universitas Mataram',
  'Universitas Mercu Buana',
  'Universitas Merdeka Madiun',
  'Universitas Merdeka Malang',
  'Universitas Methodist Indonesia',
  'Universitas Muhammadiyah Jakarta',
  'Universitas Muhammadiyah Jember',
  'Universitas Muhammadiyah Magelang',
  'Universitas Muhammadiyah Makassar',
  'Universitas Muhammadiyah Malang',
  'Universitas Muhammadiyah Mataram',
  'Universitas Muhammadiyah Sumatra Utara',
  'Universitas Muhammadiyah Surakarta',
  'Universitas Muhammadiyah Yogyakarta',
  'Universitas Mulawarman',
  'Universitas Muria Kudus',
  'Universitas Muslim Indonesia',
  'Universitas Narotama',
  'Universitas Nasional Jakarta',
  'Universitas Nasional Pasim',
  'Universitas Negeri Gorontalo',
  'Universitas Negeri Jakarta',
  'Universitas Negeri Makassar',
  'Universitas Negeri Malang',
  'Universitas Negeri Manado',
  'Universitas Negeri Medan',
  'Universitas Negeri Padang',
  'Universitas Negeri Semarang',
  'Universitas Negeri Surabaya',
  'Universitas Negeri Yogyakarta',
  'Universitas Ngurah Rai',
  'Universitas Nusa Cendana',
  'Universitas Padjadjaran',
  'Universitas Pakuan',
  'Universitas Palangka Raya',
  'Universitas Pancasakti Tegal',
  'Universitas Pancasila',
  'Universitas Paramadina Mulya',
  'Universitas Pasundan',
  'Universitas Pattimura',
  'Universitas Pekalongan',
  'Universitas Pelita Harapan',
  'Universitas Pembangunan Nasional "Veteran" East Java',
  'Universitas Pembangunan Panca Budi',
  'Universitas Pendidikan Indonesia',
  'Universitas Persada Indonesia Y.A.I',
  'Universitas Pesantren Darul Ulum Jombang',
  'Universitas Proklamasi 45',
  'Universitas Putera Batam',
  'Universitas Riau',
  'Universitas Sam Ratulangi',
  'Universitas Sanata Dharma',
  'Universitas Sarjanawiyata Tamansiswa',
  'Universitas Sebelas Maret',
  'Universitas Semarang',
  'Universitas Siliwangi',
  'Universitas Simalungun',
  'Universitas Slamet Riyadi Surakarta',
  'Universitas Sriwijaya',
  'Universitas Sultan Ageng Tirtayasa',
  'Universitas Sumatera Utara',
  'Universitas Surabaya',
  'Universitas Swadaya Gunung Djati',
  'Universitas Syiah Kuala',
  'Universitas Tadulako',
  'Universitas Tanjungpura',
  'Universitas Tarumanagara',
  'Universitas Telkom',
  'Universitas Terbuka Indonesia',
  'Universitas Tidar Magelang',
  'Universitas Trilogi',
  'Universitas Trisakti',
  'Universitas Tunas Pembangunan',
  'Universitas Udayana',
  'Universitas Widya Gama Malang',
  'Universitas Widyatama',
  'Universitas Wijaya Kusuma Purwokerto',
  'Universitas Wijaya Kusuma Surabaya',
  'Universitas Wisnuwardhana',
  'Universitas Yarsi',
  'Universitat Autónoma de Barcelona',
  'Universitat Internacional de Catalunya',
  'Universitat Jaume I de Castellón',
  'Universitat Oberta de Catalunya',
  'Universitat Pompeu Fabra',
  'Universitat Rovira I Virgili Tarragona',
  'Universitat Rámon Llull',
  'Universitat de Barcelona',
  'Universitat de Lleida',
  'Universitat de Vic',
  'Universitat de les Illes Balears',
  'Universitatea de Vest "Vasile Goldi&#351;"',
  'Universite Amadou Hampaté Ba',
  'Universite des Sciences Islamiques de Constantine',
  'Universiteit Antwerpen',
  'Universiteit Antwerpen Management School',
  'Universiteit Antwerpen UFSIA',
  'Universiteit Gent',
  'Universiti Darul Iman',
  'Universiti Industri Selangor',
  'Universiti Islam Sultan Sharif Ali',
  'Universiti Kebangsaan Malaysia',
  'Universiti Kuala Lumpur',
  'Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)',
  'Universiti Malaya',
  'Universiti Malaysia Kelantan',
  'Universiti Malaysia Perlis',
  'Universiti Malaysia Sabah',
  'Universiti Malaysia Sarawak',
  'Universiti Malaysia Terengganu',
  'Universiti Pendidikan Sultan Idris',
  'Universiti Putra Malaysia',
  'Universiti Sains Malaysia',
  'Universiti Sultan Zainal Abidin',
  'Universiti Teknikal Malaysia Melaka',
  'Universiti Teknologi Malaysia',
  'Universiti Teknologi Mara',
  'Universiti Teknologi Petronas',
  'Universiti Tenaga Nasional',
  'Universiti Tun Abdul Razak',
  'Universiti Tun Hussein Onn Malaysia',
  'Universiti Tunku Abdul Rahman',
  'Universiti Utara Malaysia',
  'University "Aurel Vlaicu" Arad',
  'University "Petre Andrei" Iasi',
  'University "Titu Maiorescu"',
  'University "Transilvany" of Brasov',
  'University Academy',
  'University Academy Charter High School',
  'University Academy Sabl Inc',
  'University Academy-lower',
  'University Academy-middle',
  'University Academy-upper',
  'University Behavioral Center',
  'University Campus Suffolk',
  'University Canada West',
  'University Center "César Ritz"',
  'University Center Preschool',
  'University Centre of the Westfjords',
  'University Charter School',
  'University City High',
  'University City Sr. High',
  'University College Bahrain',
  'University College Cork',
  'University College Dublin',
  'University College London University of London',
  'University College of Applied Sciences',
  'University College of Arts Crafts and Design',
  'University College of Borås',
  'University College of Cape Breton',
  'University College of Gävle',
  'University College of Kristianstad',
  'University College of Nabi Akram',
  'University College of Saint-Boniface',
  'University College of Skövde',
  'University College of Technology & Innovation (UCTI)',
  'University College of Trollhättan/Uddevalla',
  'University College of the Cariboo',
  'University College of the Fraser Valley',
  'University Elementary',
  'University Elementary At La Fiesta',
  'University Elementary School',
  'University Gardens (especializada)',
  'University Goce Delcev',
  'University H S',
  'University Heights Charter School',
  'University Heights Elementary School',
  'University Heights Intermediate Center',
  'University Heights Middle',
  'University Heights Secondary School-bronx Community College',
  'University Hgts. Elem. School',
  'University High',
  'University High School',
  'University High School Academy',
  'University High School Of Science And Engineering',
  'University High School Preparatory Academy',
  'University Hill Elementary School',
  'University Hills Elem School',
  'University Hills Elementary',
  'University Hospital Zurich',
  'University Institute of Architecture Venice',
  'University Institute of Modern Languages',
  'University Institute of Naval Studies',
  'University Institute of Oriental Studies',
  'University Institute of Teacher Training "Suor Orsola Benincasa"',
  'University Konstantina Filozov in Nitra',
  'University Laboratory School',
  'University Lucian Blaga of Sibiu',
  'University Malaysia Pahang',
  'University Marien Ngouabi Brazzaville',
  'University Meadows Elementary',
  'University Medical School of Debrecen',
  'University Medical School of Pécs',
  'University Neighborhood High School',
  'University Neighborhood Middle School',
  'University Of Cleveland Preparatory School',
  'University Of Dubai',
  'University Of Hartford Magnet School',
  'University Of Illinois High Sch',
  'University Of Medical Sciences & Technology (UMST)',
  'University Of Nebraska High School',
  'University Of Texas El Charter School',
  'University Oil- Gas Ploiesti',
  'University Park Creative Arts',
  'University Park El Sch',
  'University Park Elementary',
  'University Park Elementary School',
  'University Pk Campus School',
  'University Place Elementary School',
  'University Place Middle School',
  'University Place Primary',
  'University Place Special Educ',
  'University Politehnica of Bucharest',
  'University Prep - Arapahoe St.',
  'University Prep - Steele St.',
  'University Prep Charter High School',
  'University Preparaory Academy Hillsborough',
  'University Preparation Charter School At Csu Channel Islands',
  'University Preparatory',
  'University Preparatory Academy Charter',
  'University Preparatory Academy Palm Beach',
  'University Preparatory Academy Psad Elementary',
  'University Preparatory Academy Psad High School',
  'University Preparatory Academy Psad Middle',
  'University Preparatory Charter School For Young Men',
  'University Preparatory High',
  'University Preparatory Science And Math Elementary',
  'University Preparatory Science And Math Psad High School',
  'University Preparatory Science And Math Psad Middle School',
  'University Preparatory Value High',
  'University School',
  'University School of Physical Education',
  'University Schools',
  'University Senior High',
  'University Terrace Elementary School',
  'University Tun Abdul Razak',
  'University View Academy Inc. (frm La Connections)',
  'University Vitez In Travnik',
  'University Yes Academy',
  'University for Development Studies',
  'University for Humanistics (UH)',
  'University for Information Science and Technology  "St. Paul The Apostle"',
  'University for Peace',
  'University of Aberdeen',
  'University of Abertay Dundee',
  'University of Abuja',
  'University of Adelaide',
  'University of Aden',
  'University of Advancing Technology',
  'University of Agricultural Sciences Bangalore',
  'University of Agricultural Sciences Dharwad',
  'University of Agriculture Abeokuta',
  'University of Agriculture Faisalabad',
  'University of Agriculture Makurdi',
  'University of Agriculture and Veterinary Medicine Bucharest',
  'University of Agriculture and Veterinary Medicine Cluj-Napoca',
  'University of Agriculture and Veterinary Medicine Iasi',
  'University of Agriculture and Veterinary Medicine Timisoara',
  'University of Aizu',
  'University of Akron',
  'University of Akron Wayne College',
  'University of Akureyri',
  'University of Al-Qadisiyah',
  'University of Alabama at Birmingham',
  'University of Alabama at Huntsville',
  'University of Alabama at Tuscaloosa',
  'University of Alaska',
  'University of Alaska - Anchorage',
  'University of Alaska - Fairbanks',
  'University of Alaska - Southeast',
  'University of Alberta',
  'University of Aleppo',
  'University of Alexandra Dubček in Trenčín',
  'University of Amsterdam',
  'University of Anbar',
  'University of Ancona',
  'University of Andorra',
  'University of Applied Science and Technology',
  'University of Applied Sciences',
  'University of Applied Sciences Aargau',
  'University of Applied Sciences Basel (FHBB )',
  'University of Applied Sciences Chur',
  'University of Applied Sciences Solothurn Northwestern Switzerland',
  'University of Applied Sciences of Moldova',
  'University of Aquila',
  'University of Architecture Civil Engineering and Geodesy',
  'University of Arizona',
  'University of Arkansas - Fayetteville',
  'University of Arkansas - Little Rock',
  'University of Arkansas - Monticello',
  'University of Arkansas Community College-​Batesville',
  'University of Arkansas Community College-​Hope',
  'University of Arkansas Community College-​Morrilton',
  'University of Arkansas for Medical Sciences',
  'University of Art and Design Cluj-Napoca',
  'University of Art and Design Helsinki',
  'University of Asia Pacific Dhanmondi',
  'University of Asia and the Pacific',
  'University of Athens',
  'University of Auckland',
  'University of Aviation and Technology',
  'University of Bacau',
  'University of Baghdad',
  'University of Baguio',
  'University of Bahrain',
  'University of Ballarat',
  'University of Baltimore',
  'University of Baluchistan',
  'University of Banja Luka',
  'University of Bari',
  'University of Basel',
  'University of Basrah',
  'University of Batangas',
  'University of Bath',
  'University of Bedfordshire',
  'University of Belgrade',
  'University of Belize',
  'University of Benghazi',
  'University of Benin',
  'University of Bergamo',
  'University of Bergen',
  'University of Bialystok',
  'University of Bihac',
  'University of Birmingham',
  'University of Bohol',
  'University of Bologna',
  'University of Bolton',
  'University of Botswana',
  'University of Bradford',
  'University of Brescia',
  'University of Bridgeport',
  'University of Brighton',
  'University of Bristol',
  'University of British Columbia',
  'University of Brunei Darussalam',
  'University of Bucharest',
  'University of Buckingham',
  'University of Bunkyo',
  'University of Burao',
  'University of Burdwan',
  'University of Cagayan Valley',
  'University of Cagliari',
  'University of Calabar',
  'University of Calabria',
  'University of Calcutta',
  'University of Calgary',
  'University of Calicut',
  'University of California Berkeley',
  'University of California Davis',
  'University of California Irvine',
  'University of California Los Angeles',
  'University of California Merced',
  'University of California Riverside',
  'University of California San Diego',
  'University of California San Francisco',
  'University of California Santa Barbara',
  'University of California Santa Cruz',
  'University of Cambodia',
  'University of Cambridge',
  'University of Camerino',
  'University of Canberra',
  'University of Canterbury',
  'University of Cape Coast',
  'University of Cape Town',
  'University of Cassino',
  'University of Catania',
  'University of Cebu',
  'University of Central Arkansas',
  'University of Central Europe in Skalica',
  'University of Central Florida',
  'University of Central Greece',
  'University of Central Lancashire',
  'University of Central Missouri',
  'University of Central Oklahoma',
  'University of Central Punjab',
  'University of Charleston',
  'University of Chemical Technology and Metallurgy',
  'University of Chester',
  'University of Chicago',
  'University of Chieti',
  'University of Chittagong',
  'University of Cincinnati',
  'University of Colombo',
  'University of Colorado Health Sciences Center',
  'University of Colorado at Boulder',
  'University of Colorado at Colorado Springs',
  'University of Colorado at Denver',
  'University of Computer Studies Yangon',
  'University of Connecticut',
  'University of Constanta',
  'University of Constanta Medical School',
  'University of Craiova',
  'University of Crete',
  'University of Cyprus',
  'University of Da Lat',
  'University of Da Nang',
  'University of Dallas',
  'University of Dammam',
  'University of Dar Es Salaam',
  'University of Dayton',
  'University of Debrecen',
  'University of Delaware',
  'University of Delhi',
  'University of Denver',
  'University of Derby',
  'University of Detroit Mercy',
  'University of Development Alternative',
  'University of Dhaka',
  'University of Dodoma',
  'University of Dohuk (Kurdistan Region)',
  'University of Dongola',
  'University of Dublin Trinity College',
  'University of Dubrovnik',
  'University of Dubuque',
  'University of Dundee',
  'University of Durham',
  'University of East',
  'University of East Anglia',
  'University of East Asia',
  'University of East London',
  'University of East Srarajevo',
  'University of Eastern Africa Baraton',
  'University of Eastern Philippines',
  'University of Economics Prague',
  'University of Economics Varna',
  'University of Economics in Bratislava',
  'University of Edinburgh',
  'University of Education',
  'University of Education Hradec Kralove',
  'University of Education Winneba',
  'University of El Imam El Mahdi University',
  'University of Elbasan "Aleksander Xhuvani"',
  'University of Eldoret',
  'University of Electro-Communications',
  'University of Electronic Science and Technology of China',
  'University of Engineering and Technology Lahore',
  'University of Engineering and Technology Peshawar',
  'University of Engineering and Technology Taxila',
  'University of Essex',
  'University of Evansville',
  'University of Exeter',
  'University of Faisalabad',
  'University of Ferrara',
  'University of Fiji',
  'University of Finance and Management in Bialystok',
  'University of Fine Arts Belgrade',
  'University of Florence',
  'University of Florida',
  'University of Foggia',
  'University of Food Technology',
  'University of Forestry Sofia',
  'University of Fort Hare',
  'University of Fribourg',
  'University of Gadarif',
  'University of Galatzi',
  'University of Gastronomic Sciences',
  'University of Gdansk',
  'University of Geneva',
  'University of Genoa',
  'University of Georgia',
  'University of Gezira',
  'University of Ghana',
  'University of Gjirokstra "Eqerem Cabej"',
  'University of Glamorgan',
  'University of Glasgow',
  'University of Gloucestershire',
  'University of Goroka',
  'University of Great Falls',
  'University of Greenland',
  'University of Greenwich',
  'University of Grigol Robakidze',
  'University of Groningen',
  'University of Guam',
  'University of Guelph',
  'University of Gujrat',
  'University of Guyana',
  'University of Haifa',
  'University of Hail',
  'University of Halifax Birmingham Campus',
  'University of Hargeisa',
  'University of Hartford',
  'University of Hawaii System',
  'University of Hawaii at Hilo',
  'University of Hawaii at Manoa',
  'University of Health Sciences Andhra Pradesh',
  'University of Health Sciences Antigua',
  'University of Health and Science',
  'University of Helsinki',
  'University of Hertfordshire',
  'University of Holy Quran and Islamic Siences',
  'University of Hong Kong',
  'University of Hormozgan',
  'University of Horticulture and Food Industry',
  'University of Houston',
  'University of Houston-Downtown',
  'University of Huddersfield',
  'University of Hull',
  'University of Human Development (Kurdistan Region)',
  'University of Hyderabad',
  'University of Hydraulic Electric Engineering',
  'University of Iasi',
  'University of Ibadan',
  'University of Iceland',
  'University of Idaho',
  'University of Illinois at Chicago',
  'University of Illinois at Springfield',
  'University of Illinois at Urbana-Champaign',
  'University of Ilorin',
  'University of Indianapolis',
  'University of Indianapolis in Athens',
  'University of Information Technology & Sciences',
  'University of Information Technology and Management in Rzeszow',
  'University of Insubria',
  'University of Insurance and Banking in Warsaw',
  'University of International Business',
  'University of International Business and Economics',
  'University of Ioannina',
  'University of Iowa',
  'University of Islamic Studies',
  'University of Italian Studies for Foreigners of Perugia',
  'University of Italian Studies for Foreigners of Siena',
  'University of Jaffna',
  'University of Jamestown',
  'University of Jammu',
  'University of Jan Evangelista Purkyne',
  'University of Jazeera',
  'University of Jinan',
  'University of Joensuu',
  'University of Johannesburg',
  'University of Jordan',
  'University of Jos',
  'University of Juba',
  'University of Jyväskylä',
  'University of Kabianga',
  'University of Kalamoon',
  'University of Kalyani',
  'University of Kansas',
  'University of Kansas School of Medicine',
  'University of Karachi',
  'University of Kashmir',
  'University of Kelaniya',
  'University of Kent at Canterbury',
  'University of Kentucky',
  'University of Kerala',
  'University of Khartoum',
  "University of King's College",
  'University of Kirkuk',
  'University of Korca "Fan Noli"',
  'University of Kordofan',
  'University of Kota',
  'University of Kragujevac',
  'University of Kufa',
  'University of Kuopio',
  'University of Kurdistan',
  'University of Kurdistan - Hawler',
  'University of KwaZulu-Natal',
  'University of La Verne',
  'University of LaVerne in Athens',
  'University of Lagos',
  'University of Lahore',
  'University of Lancaster',
  'University of Lapland',
  'University of Latvia',
  'University of Lavasanat Pnu',
  'University of Lecce',
  'University of Leeds',
  'University of Leicester',
  'University of Lethbridge',
  'University of Liberal Arts',
  'University of Liberia',
  'University of Limerick',
  'University of Limpopo',
  'University of Lincoln',
  'University of Liverpool',
  'University of Livingstonia',
  'University of Ljubljana',
  'University of Lodz',
  'University of London',
  'University of Louisiana at Lafeyette',
  'University of Louisiana at Monroe',
  'University of Louisville',
  'University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.',
  'University of Lucknow',
  'University of Lusaka',
  'University of Luxemburg',
  'University of Macau',
  'University of Macedonia',
  'University of Macerata',
  'University of Madras',
  'University of Maiduguri',
  'University of Maine',
  'University of Maine System',
  'University of Maine at Farmington',
  'University of Maine at Fort Kent',
  'University of Maine at Machias',
  'University of Maine at Presque Island',
  'University of Malakand',
  'University of Malawi',
  'University of Malta',
  'University of Management and Marketing',
  'University of Management and Technology',
  'University of Manchester',
  'University of Manila',
  'University of Manitoba',
  'University of Maribor',
  'University of Marketing and Distribution Sciences',
  'University of Maryland - University College',
  'University of Maryland Baltimore',
  'University of Maryland Baltimore County',
  'University of Maryland College Park',
  'University of Maryland Eastern Shore',
  'University of Mashhad Pnu',
  'University of Massachusetts at Amherst',
  'University of Massachusetts at Dartmouth',
  'University of Massachusetts at Lowell',
  'University of Mauritius',
  'University of Media Arts',
  'University of Medicine 1 Yangon',
  'University of Medicine Magway',
  'University of Medicine and Pharmacology of Oradea',
  'University of Medicine and Pharmacy of Bucharest',
  'University of Medicine and Pharmacy of Cluj-Napoca',
  'University of Medicine and Pharmacy of Iasi',
  'University of Medicine and Pharmacy of Targu Mures',
  'University of Medicine and Pharmacy of Timisoara',
  'University of Melbourne',
  'University of Memphis',
  'University of Messina',
  'University of Miami',
  'University of Michigan - Ann Arbor',
  'University of Michigan - Dearborn',
  'University of Milan',
  'University of Milan - Bicocca',
  'University of Mindanao',
  'University of Mining and Geology "St. Ivan Rils"',
  'University of Minnesota',
  'University of Minnesota - Crookston',
  'University of Minnesota - Duluth',
  'University of Minnesota - Morris',
  'University of Minnesota - Twin Cities',
  'University of Miskolc',
  'University of Mississippi',
  'University of Mississippi Medical Center',
  'University of Missouri - Columbia',
  'University of Missouri - Kansas City',
  'University of Missouri - Rolla',
  'University of Missouri - Saint Louis',
  'University of Mkar',
  'University of Modena',
  'University of Mohaghegh',
  'University of Molise',
  'University of Moncton',
  'University of Moncton Edmundston',
  'University of Moncton Shippagan',
  'University of Montana',
  'University of Montenegro',
  'University of Moratuwa',
  'University of Mostar',
  'University of Mosul',
  'University of Mumbai',
  'University of Munawwar-ul-Islam',
  'University of Music and Fine Arts',
  'University of Mustansiriyah',
  'University of Mysore',
  'University of Nairobi',
  'University of Namibia',
  'University of Naples Federico II',
  'University of National and World Economy',
  'University of Nebraska Kearney',
  'University of Nebraska Lincoln',
  'University of Nebraska Omaha',
  'University of Negros Occidental-Recoletos',
  'University of Nevada Las Vegas',
  'University of Nevada Reno',
  'University of New Brunswick',
  'University of New Brunswick Saint John',
  'University of New Caledonia',
  'University of New England',
  'University of New Hampshire Durham',
  'University of New Haven',
  'University of New Mexico',
  'University of New Orleans',
  'University of New South Wales',
  'University of New York Tirana',
  'University of New York in Prague',
  'University of Newcastle',
  'University of Newcastle-upon-Tyne',
  'University of Nicosia',
  'University of Nigeria',
  'University of Nis',
  'University of Nizwa',
  'University of North Bengal',
  'University of North Carolina at Asheville',
  'University of North Carolina at Chapel Hill',
  'University of North Carolina at Charlotte',
  'University of North Carolina at Greensboro',
  'University of North Carolina at Wilmington',
  'University of North Dakota',
  'University of North Florida',
  'University of North Georgia',
  'University of North London',
  'University of North Texas',
  'University of North Texas Health Science Center',
  'University of North West',
  'University of NorthWest',
  'University of Northampton',
  'University of Northeastern Philippines',
  'University of Northern Bahr El-Ghazal',
  'University of Northern British Columbia',
  'University of Northern Colorado',
  'University of Northern Iowa',
  'University of Northern Philippines',
  'University of Northern Virginia Prague Campus',
  'University of Northumbria at Newcastle',
  'University of Notre Dame',
  'University of Notre Dame Australia',
  'University of Nottingham',
  'University of Nottingham Malaysia Campus',
  'University of Nova Gorica',
  'University of Novi Sad',
  'University of Nueva Caceres',
  'University of Occupational and Environmental Health Japan',
  'University of Oklahoma',
  'University of Ontario Institute of Technology',
  'University of Opole',
  'University of Oradea',
  'University of Oregon',
  'University of Osijek',
  'University of Oslo',
  'University of Ostrava',
  'University of Otago',
  'University of Ottawa',
  'University of Oulu',
  'University of Oxford',
  'University of Padua',
  'University of Paisley',
  'University of Palermo',
  'University of Palestine',
  'University of Pangasinan',
  'University of Pannonia',
  'University of Papua New Guinea',
  'University of Pardubice',
  'University of Parma',
  'University of Patras',
  'University of Pavia',
  'University of Pecs',
  'University of Peloponnese',
  'University of Pennsylvania',
  'University of Peradeniya',
  'University of Performing Arts in Bratislava',
  'University of Perpetual Help',
  'University of Perpetual Help System Dalta',
  'University of Perugia',
  'University of Peshawar',
  'University of Petra',
  'University of Petroleum (East China)',
  'University of Petrosani',
  'University of Phoenix',
  'University of Piraeus',
  'University of Pisa',
  'University of Pitesti',
  'University of Pittsburgh',
  'University of Pittsburgh at Johnstown',
  'University of Pittsburgh-​Titusville',
  'University of Plovdiv',
  'University of Plymouth',
  'University of Portharcourt',
  'University of Portland',
  'University of Portsmouth',
  'University of Presov',
  'University of Pretoria',
  'University of Primorska',
  'University of Prince Edward Island',
  'University of Prishtina',
  'University of Prizren "Ukshin Hoti"',
  'University of Priština',
  'University of Puerto Rico',
  'University of Puget Sound',
  'University of Pula',
  'University of Pune',
  'University of Qatar',
  'University of Queensland',
  'University of Québec',
  'University of Rajasthan',
  'University of Rajshahi',
  'University of Reading',
  'University of Redlands',
  'University of Reggio Calabria',
  'University of Regina',
  'University of Regina Carmeli',
  'University of Resita',
  'University of Rhode Island',
  'University of Richmond',
  'University of Rijeka',
  'University of Rochester',
  'University of Roma "La Sapienza"',
  'University of Roma "Tor Vergata"',
  'University of Roorkee',
  'University of Rousse',
  'University of Ruhuna',
  'University of SS. Cyril and Methodius in Trnava',
  'University of Saint Joseph',
  'University of Saint La Salle',
  'University of Saint Thomas (MN)',
  'University of Saint Thomas (TX)',
  'University of Salerno',
  'University of Salford',
  'University of San Agustin',
  'University of San Carlos',
  'University of San Diego',
  'University of San Francisco',
  'University of San Jose Recoletos',
  'University of San Marino',
  'University of Sannio',
  'University of Santo Tomas',
  'University of Sarajevo',
  'University of Sargodha',
  'University of Saskatchewan',
  'University of Sassari',
  'University of Science & Arts of Oklahoma',
  'University of Science & Technology Bannu',
  'University of Science & Technology Chittagong',
  'University of Science Arts and Technology',
  'University of Science and Culture',
  'University of Science and Technology',
  'University of Science and Technology Beijing',
  "University of Science and Technology Sana'a",
  'University of Science and Technology of China',
  'University of Scranton',
  'University of Seychelles',
  'University of Seychelles - American Institute of Medicine',
  'University of Sharjah',
  'University of Sheffield',
  'University of Shiga Prefecture',
  'University of Shkodra "Luigj Gurakuqi"',
  'University of Sibiu',
  'University of Siena',
  'University of Sierra Leone',
  'University of Silesia',
  'University of Silvaner',
  'University of Sindh',
  'University of Sint Eustatius School of Medicine',
  'University of Sioux Falls',
  'University of Social Welfare and Rehabilitation Scinences',
  'University of Somalia',
  'University of Sopron',
  'University of South Africa',
  'University of South Alabama',
  'University of South Asia',
  'University of South Australia',
  'University of South Bohemia',
  'University of South Carolina',
  'University of South Carolina - Aiken',
  'University of South Dakota',
  'University of South Florida',
  'University of Southampton',
  'University of Southeastern Philippines',
  'University of Southern California',
  'University of Southern Denmark',
  'University of Southern Europe (Monaco Business School)',
  'University of Southern Indiana',
  'University of Southern Maine',
  'University of Southern Mindanao',
  'University of Southern Mississippi',
  'University of Southern Philippines Foundation',
  'University of Southern Queensland',
  'University of Southwestern Louisiana',
  'University of Split',
  'University of Sri Jayawardenapura',
  'University of St. Andrews',
  "University of St. Jerome's College",
  "University of St. Michael's College",
  'University of Stavanger',
  'University of Stellenbosch',
  'University of Stirling',
  'University of Strathclyde',
  'University of Suceava',
  'University of Sudbury',
  'University of Sulaimania (Kurdistan Region)',
  'University of Sunderland',
  'University of Surrey',
  'University of Sussex',
  'University of Swaziland',
  'University of Switzerland',
  'University of Sydney',
  'University of Szczecin',
  'University of Szeged',
  'University of Tabuk',
  'University of Tampa',
  'University of Tampere',
  'University of Targu Jiu',
  'University of Tartu',
  'University of Tasmania',
  'University of Technical Education Ho Chi Minh City',
  'University of Technology',
  'University of Technology - Iraq',
  'University of Technology Jamaica',
  'University of Technology Phnom Penh',
  'University of Technology Sydney',
  'University of Technology and Life Sciences',
  'University of Teesside',
  'University of Tehran',
  'University of Tennessee Knoxville',
  'University of Tennessee Martin',
  'University of Tennessee at Chattanooga',
  'University of Teramo',
  'University of Texas - Pan American',
  'University of Texas Medical Branch',
  'University of Texas Southwestern Medical Center at Dallas',
  'University of Texas at Arlington',
  'University of Texas at Austin',
  'University of Texas at Brownsville',
  'University of Texas at Dallas',
  'University of Texas at El Paso',
  'University of Texas at San Antonio',
  'University of Texas at Tyler',
  'University of Thessaly',
  'University of Tirana',
  'University of Toledo',
  'University of Toronto',
  'University of Toronto Mississauga',
  'University of Toronto Scarborough',
  'University of Trento',
  'University of Trieste',
  'University of Trinidad and Tobago',
  'University of Trinity College',
  'University of Tripoli',
  'University of Trnava',
  'University of Tromsø',
  'University of Tulsa',
  'University of Turin',
  'University of Turku',
  'University of Tuzla',
  'University of Twente',
  'University of Udine',
  'University of Ulster',
  'University of Urbino',
  'University of Utah',
  'University of Uyo',
  'University of Vaasa',
  'University of Venda',
  'University of Venice',
  'University of Vermont',
  'University of Verona',
  'University of Veterinary & Animal Science',
  'University of Veterinary Medicine in Kosice',
  'University of Veterinary Science',
  'University of Veterinary and Pharmaceutical Science',
  'University of Victoria',
  'University of Virginia Charlottesville',
  'University of Vlora "Ismail Qemali"',
  'University of Wah',
  'University of Waikato',
  'University of Wales',
  'University of Wales Aberystwyth',
  'University of Wales Bangor',
  'University of Wales College of Medicine',
  'University of Wales Institute Cardiff',
  'University of Wales Lampeter',
  'University of Wales Newport',
  'University of Wales Swansea',
  'University of Wamia and Masuria in Olsztyn',
  'University of Warsaw',
  'University of Warwick',
  'University of Washington',
  'University of Wassit',
  'University of Waterloo',
  'University of West Alabama',
  'University of West Bohemia',
  'University of West Florida',
  'University of West Hungary',
  'University of Western Australia',
  'University of Western Macedonia',
  'University of Western Ontario',
  'University of Western Sydney',
  'University of Westminster',
  'University of Windsor',
  'University of Winnipeg',
  'University of Wisconsin - Eau Claire',
  'University of Wisconsin - Green Bay',
  'University of Wisconsin - LaCrosse',
  'University of Wisconsin - Madison',
  'University of Wisconsin - Milwaukee',
  'University of Wisconsin - Oshkosh',
  'University of Wisconsin - Parkside',
  'University of Wisconsin - Platteville',
  'University of Wisconsin - River Falls',
  'University of Wisconsin - Stevens Point',
  'University of Wisconsin - Stout',
  'University of Wisconsin - Superior',
  'University of Wisconsin - Whitewater',
  'University of Wisconsin Colleges',
  'University of Wisconsin System',
  'University of Witwatersrand',
  'University of Wollongong',
  'University of Wollongong - Dubai Campus',
  'University of Wolverhampton',
  'University of Worcester',
  'University of World Economy and Diplomacy',
  'University of Wroclaw',
  'University of Wyoming',
  'University of York',
  'University of Zadar',
  'University of Zagreb',
  'University of Zambia',
  'University of Zenica',
  'University of Zilinska',
  'University of Zimbabwe',
  'University of Zululand',
  'University of Zurich',
  'University of Zürich',
  'University of lloilo',
  'University of the Air',
  'University of the Assumption',
  'University of the City of Manila',
  'University of the Cordilleras',
  'University of the District of Columbia',
  'University of the East Coloocan',
  'University of the East Manila',
  'University of the East Ramon Magsaysay Memorial Medical Center',
  'University of the Faroe Islands',
  'University of the Free State',
  'University of the Gambia',
  'University of the Humanities',
  'University of the Immaculate Conception',
  'University of the Incarnate Word',
  'University of the Netherlands Antilles Curacao',
  'University of the Ozarks',
  'University of the Pacific',
  'University of the People',
  'University of the Philippines Baguio',
  'University of the Philippines Diliman',
  'University of the Philippines Los Banos',
  'University of the Philippines Manila',
  'University of the Philippines Mindanao',
  'University of the Philippines Open University',
  'University of the Philippines Visayas',
  'University of the Philippines Visayas - Cebu High School',
  'University of the Punjab',
  'University of the Punjab Gujranwala Campus',
  'University of the Ryukyus',
  'University of the Sacred Heart Tokyo',
  'University of the South Pacific',
  'University of the South Pacific Centre',
  'University of the South Pacific School of Agriculture',
  'University of the Southern Caribbean',
  'University of the Sunshine Coast',
  'University of the Thai Chamber of Commerce',
  'University of the Virgin Islands',
  'University of the Visayas',
  'University of the Visual & Performing Arts',
  'University of the West Indies Cave Hill',
  'University of the West Indies Mona',
  'University of the West Indies St. Augustine',
  'University of the West of England Bristol',
  'University of the Western Cape',
  'University “Pavaresia” Vlore',
  'Università Pontifcia Salesiana',
  'Università Pontificia Salesiana',
  'Universität Augsburg',
  'Universität Bayreuth',
  'Universität Bern',
  'Universität Bielefeld',
  'Universität Bremen',
  'Universität Dortmund',
  'Universität Duisburg-Essen',
  'Universität Erfurt',
  'Universität Flensburg',
  'Universität Fridericana Karlsruhe (Technische Hochschule)',
  'Universität Graz',
  'Universität Hamburg',
  'Universität Hannover',
  'Universität Hildesheim',
  'Universität Hohenheim',
  'Universität Innsbruck',
  'Universität Kaiserslautern',
  'Universität Kassel',
  'Universität Klagenfurt',
  'Universität Koblenz-Landau',
  'Universität Konstanz',
  'Universität Köln',
  'Universität Leipzig',
  'Universität Liechtenstein',
  'Universität Linz',
  'Universität Luzern',
  'Universität Lüneburg',
  'Universität Mannheim',
  'Universität Mozarteum Salzburg',
  'Universität Osnabrück',
  'Universität Paderborn',
  'Universität Passau',
  'Universität Polsdam',
  'Universität Potsdam',
  'Universität Regensburg',
  'Universität Rostock',
  'Universität Salzburg',
  'Universität Siegen',
  'Universität St. Gallen',
  'Universität Stuttgart',
  'Universität Trier',
  'Universität Ulm',
  'Universität Vienna',
  'Universität der Bundeswehr Hamburg',
  'Universität der Bundeswehr München',
  'Universität des Saarlandes',
  'Universität für Bodenkultur Wien',
  'Universität für Musik und darstellende Kunst  Wien',
  'Universität für Musik und darstellende Kunst Graz',
  'Universität für angewandte Kunst Wien',
  'Universität für künstlerische und industrielle Gestaltung Linz',
  'Université Abdelmalek Essadi',
  'Université Abdou Moumouni de Niamey',
  'Université Abou Bekr Belkaid Tlemcen',
  'Université Alioune Diop de Bambey',
  'Université Amar Telidji',
  'Université Badji Mokhtar',
  'Université Blaise Pascal (Clermont-Ferrand II)',
  'Université Bordeaux I',
  'Université Bourgogne - Franche-Comté',
  'Université Cadi Ayyad',
  'Université Catholique de Bukavu',
  'Université Catholique de Kabgayi',
  'Université Catholique de Lille',
  'Université Catholique de Louvain',
  'Université Catholique de Lyon',
  "Université Catholique de l' Ouest",
  'Université Centrale',
  'Université Charles-de-Gaulle (Lille III)',
  'Université Cheikh Anta Diop',
  'Université Chouaib Doukkali',
  'Université Chrétienne Bilingue du Congo',
  "Université Chrétienne du Nord d'Haiti",
  'Université Claude Bernard (Lyon I)',
  'Université Dakar Bourguiba',
  'Université Denis Diderot (Paris VII)',
  'Université Euro-Afrique',
  'Université Ez-Zitouna',
  "Université Française d'Égypte",
  'Université François Rabelais de Tours',
  'Université Gamal Abdel Nasser de Conakry',
  'Université Gaston Berger Saint-Louis',
  'Université Hassan II - Aïn Chock',
  'Université Hassan II - Mohammadia',
  'Université Hassan Ier - Settat',
  'Université Henri Poincaré (Nancy I)',
  'Université Ibn Toufail',
  'Université Ibn Zohr Agadir',
  'Université Jean Monnet',
  'Université Jean Moulin (Lyon III)',
  'Université Joseph Fourier (Grenoble I)',
  'Université Julius Nyerere Kankan',
  'Université Kasdi Merbah Ouargla',
  'Université Kofi Annan',
  'Université Kongo',
  'Université Laval',
  'Université Libre de Bruxelles',
  'Université Libre de Tunis',
  'Université Louis Pasteur (Strasbourg I)',
  'Université Lumière de Bujumbura',
  'Université Lumiére (Lyon II)',
  "Université M'hamed Bouguerra de Boumerdes",
  'Université Mentouri Constantine',
  'Université Michel de Montaigne (Bordeaux III )',
  'Université Mohammed Ier',
  'Université Mohammed V - Agdal',
  'Université Mohammed V - Souissi',
  'Université Montesquieu (Bordeaux IV)',
  'Université Moulay Ismail Meknès',
  'Université Mouloud Mammeri de Tizi Ouzou',
  'Université Nancy II',
  "Université Notre Dame d'Haïti",
  'Université Omar Bongo',
  'Université Panthéon-Assas (Paris II)',
  'Université Panthéon-Sorbonne (Paris I)',
  'Université Paris Nanterre (Paris X)',
  'Université Paris Nord (Paris XIII)',
  'Université Paris Sud (Paris XI)',
  'Université Paris-Dauphine (Paris IX)',
  'Université Paris-Sorbonne (Paris IV)',
  'Université Paul Sabatier (Toulouse III)',
  'Université Paul Valéry (Montpellier III)',
  'Université Pierre Mendes-France (Grenoble II)',
  'Université Pierre et Marie Curie (Paris VI)',
  'Université Protestante au Congo',
  'Université Quisqueya',
  'Université Rennes I',
  'Université Rennes II - Haute-Bretagne',
  'Université René Descartes (Paris V)',
  'Université Robert Schuman (Strasbourg III)',
  "Université Senghor d'Alexandrie",
  'Université Shalom de Bunia',
  'Université Sidi Mohammed Ben Abdellah',
  'Université Sorbonne-Nouvelle (Paris III)',
  'Université Stendhal (Grenoble III)',
  'Université Thierno Amadou Diallo',
  'Université Val-de-Marne (Paris XII)',
  'Université Victor Segalen (Bordeaux II)',
  'Université Vincennes Saint-Denis (Paris VIII)',
  'Université Virtuelle de Tunis',
  "Université d'Abobo-Adjamé",
  "Université d'Abomey-Calavi (UAC)",
  "Université d'Aix-Marseille III",
  "Université d'Alger",
  "Université d'Alger 3",
  "Université d'Angers",
  "Université d'Antananarivo",
  "Université d'Antsiranana",
  "Université d'Artois",
  "Université d'Auvergne (Clermont-Ferrand I)",
  "Université d'Avignon",
  "Université d'Etat d'Haiti",
  "Université d'Evry Val d'Essonne",
  "Université d'Oran Es-Senia",
  "Université d'Orléans",
  'Université de 7 Novembre à Carthage',
  'Université de Bamako',
  'Université de Bamenda',
  'Université de Bandundu Ville',
  'Université de Bangui',
  'Université de Batna',
  'Université de Biskra',
  'Université de Blida',
  'Université de Bouaké',
  'Université de Bretagne Occidentale',
  'Université de Bretagne Sud',
  'Université de Buéa',
  'Université de Béjaia',
  'Université de Caen Basse Normandie',
  'Université de Cergy-Pontoise',
  'Université de Chlef',
  'Université de Cocody',
  'Université de Corse Pascal Paoli',
  'Université de Djibouti',
  'Université de Douala',
  'Université de Dschang',
  'Université de Fianarantsoa',
  'Université de Gabès',
  'Université de Gafsa',
  'Université de Guelma',
  'Université de Haute-Alsace',
  'Université de Jendouba',
  'Université de Kairouan',
  'Université de Kinshasa',
  'Université de Kisangani',
  'Université de La Rochelle',
  'Université de Lausanne',
  'Université de Limoges',
  'Université de Liège',
  'Université de Lomé',
  'Université de Lorraine',
  'Université de Lubumbashi',
  "Université de M'sila",
  'Université de Mahajanga',
  'Université de Marne la Vallée',
  'Université de Mbuji Mayi',
  'Université de Metz',
  'Université de Monastir',
  'Université de Mons-Hainaut',
  'Université de Montpellier I',
  'Université de Montpellier II',
  'Université de Montréal',
  'Université de Mostaganem',
  "Université de N'Djamena",
  'Université de Nantes',
  'Université de Neuchâtel',
  'Université de Ngaoundéré',
  'Université de Nice-Sophia Antipolis',
  'Université de Nouakchott',
  'Université de Ouagadougou',
  "Université de Pau et des Pays de l'Adour",
  'Université de Perpignan',
  'Université de Picardie Jules-Verne',
  'Université de Poitiers',
  'Université de Provence (Aix-Marseille I)',
  'Université de Reims Champagne-Ardenne',
  'Université de Rouen - Haute Normandie',
  'Université de Saida',
  'Université de Savoie',
  'Université de Sfax',
  'Université de Sherbrooke',
  'Université de Sidi-Bel-Abbès (Djillali Liabès)',
  'Université de Skikda',
  'Université de Sétif',
  'Université de Technologie de Belfort Montbéliard',
  'Université de Technologie de Compiègne',
  'Université de Technologie de Troyes',
  'Université de Thiès',
  'Université de Toamasina',
  'Université de Toliary',
  'Université de Toulouse',
  'Université de Toulouse-le-Mirail (Toulouse II)',
  'Université de Tunis',
  'Université de Tunis El Manar',
  'Université de Valenciennes et du Hainaut-Cambrésis',
  'Université de Versailles Saint-Quentin-en-Yvelines',
  'Université de Yaoundé I',
  'Université de Yaoundé II',
  'Université de Ziguinchor',
  'Université de la Manouba',
  'Université de la Méditerranée (Aix Marseille II)',
  'Université de la Polynésie Française',
  'Université de la Reunion',
  'Université des Antilles et de la Guyane',
  'Université des Montagnes',
  'Université des Sciences Humaines (Strasbourg II)',
  'Université des Sciences Sociales (Toulouse I)',
  'Université des Sciences et Technologies de Lille (Lille I)',
  'Université des Sciences et de la Technologie Houari Boumediène',
  "Université des Sciences et de la Technologie d'Oran",
  'Université du Burundi',
  'Université du Centre Sousse',
  'Université du Droit et de la Sante (Lille II)',
  'Université du Havre',
  "Université du Littoral Cote d'Opale",
  'Université du Maine',
  'Université du Québec en Abitibi-Témiscamingue',
  'Université du Québec en Outaouais',
  'Université du Québec à Chicoutimi',
  'Université du Québec à Montréal',
  'Université du Québec à Rimouski',
  'Université du Québec à Trois-Rivières',
  'Université du Sahel',
  'Université du Sud Toulon et Var',
  "Université Épiscopale d'Haiti",
  'Universiy Middle School',
  'Universum College',
  'Univerzitet u Mostaru "Dzemal Bijedic"',
  'Univesidade Agostinho Neto',
  'Unlimited Visions Aftercare',
  'Uno Academy Charter Schools',
  'Uno/ops Middle College Program',
  'Unqua Elementary School',
  'Unschool',
  'Unsworth Elementary',
  'Up Academy Charter School Of Boston',
  'Up Academy Charter School Of Dorchester',
  'Up Academy Holland',
  'Up Academy Leonard Middle School',
  'Up Academy Oliver Middle School',
  'Upchurch Elementary',
  'Uphaus Early Childhood Center',
  'Upi Elementary School',
  'Upland Colony Elementary - 05',
  'Upland Elementary',
  'Upland Heights El',
  'Upland High',
  'Upland Junior High',
  'Upland Terrace School',
  'Uplift Academy',
  'Uplift California North Charter',
  'Uplift California Santa Barbara',
  'Uplift California South Charter',
  'Uplift Community High School',
  'Uplift Community School',
  'Uplift Educatiion-north Hills Prep Pri',
  'Uplift Education - Infinity Preparatory Middle Sch',
  'Uplift Education - Infinity Preparatory Pri',
  'Uplift Education - Uplift Grand Preparatory',
  'Uplift Education-north Hills Prep H S',
  'Uplift Education-north Hills Prep Middle',
  'Uplift Gradus Preparatory',
  'Uplift Grand H S',
  'Uplift Grand Secondary',
  'Uplift Hampton Preparatory H S',
  'Uplift Hampton Preparatory Middle',
  'Uplift Hampton Preparatory Pri',
  'Uplift Heights Preparatory H S',
  'Uplift Heights Preparatory Middle',
  'Uplift Heights Preparatory Pri',
  'Uplift Infinity H S',
  'Uplift Lee',
  'Uplift Luna Preparatory H S',
  'Uplift Luna Preparatory Pri',
  'Uplift Luna Secondary School',
  'Uplift Meridian School',
  'Uplift Mighty H S',
  'Uplift Mighty Middle',
  'Uplift Mighty School',
  'Uplift Monterey',
  'Uplift Peak Pre-k',
  'Uplift Peak Preparatory H S',
  'Uplift Peak Preparatory Middle',
  'Uplift Peak Preparatory Pri',
  'Uplift Pinnacle Preparatory Pri',
  'Uplift Pinnacle Secondary',
  'Uplift Summit International H S',
  'Uplift Summit International Middle',
  'Uplift Summit International Pri',
  'Uplift Triumph Preparatory School',
  'Uplift White Rock Hills Prep',
  'Uplift Williams Preparatory H S',
  'Uplift Williams Preparatory Middle',
  'Uplift Williams Preparatory Pri',
  'Upper Adams Ms',
  'Upper Allen El Sch',
  'Upper Arlington High School',
  'Upper Blue Elementary School',
  'Upper Bucks County Technical School',
  'Upper Cape Cod Vocational Technical',
  'Upper Carmen Charter High School',
  'Upper Carmen Public Charter School',
  'Upper Darby Kdg Ctr',
  'Upper Darby Shs',
  'Upper Dauphin Area El Sch',
  'Upper Dauphin Area Hs',
  'Upper Dauphin Area Ms',
  'Upper Dublin Hs',
  'Upper Elementary',
  'Upper Greenwood Lake Elementary School',
  'Upper Iowa University',
  'Upper Kennebec Valley Middle/senior High School',
  'Upper Kennebec Valley Senior Hs',
  'Upper Lake Community Day',
  'Upper Lake Elementary',
  'Upper Lake High',
  'Upper Lake Middle',
  'Upper Little Caillou Elementary School',
  'Upper Merion Hs',
  'Upper Merion Ms',
  'Upper Mississippi High Academy',
  'Upper Mississippi Middle Academy',
  'Upper Moreland Hs',
  'Upper Moreland Intermediate Sch',
  'Upper Moreland Ms',
  'Upper Moreland Primary Sch',
  'Upper Nile University',
  'Upper Nyack School',
  'Upper Perkiomen Hs',
  'Upper Perkiomen Ms',
  'Upper Pittsgrove School',
  'Upper Pointe Coupee Elementary School',
  'Upper Providence El Sch',
  'Upper Saint Clair Hs',
  'Upper Sandusky High School',
  'Upper Sandusky Middle School',
  'Upper School',
  'Upper Scioto Valley Elementary School',
  'Upper Scioto Valley High School',
  'Upper Scioto Valley Middle School',
  'Upper Township Elementary School',
  'Upper Township Middle School',
  'Upper Township Primary School',
  'Upper Valley Career Center',
  'Upper Valley Spec Ed Unit',
  'Upperman High School',
  'Upperman Middle School',
  'Uppsala University',
  'Upriver Elementary School',
  'Uproar Leadership Academy',
  'Upsala Elementary',
  'Upsala Secondary',
  'Upson-lee Elementary School',
  'Upson-lee High School',
  'Upson-lee Middle School',
  'Upson-lee Primary School',
  'Upstream Learning Correspondence',
  'Upton Elementary',
  'Upton High School',
  'Upton Middle School',
  'Uptown School Complex',
  'Upward Elementary',
  'Ural Academy of Public Administration',
  'Ural Gorkij State University',
  'Ural State Academy of Architecture and Arts',
  'Ural State Academy of Law',
  'Ural State Academy of Mining and Geology',
  'Ural State Academy of Railway Transport',
  'Ural State Conservatory',
  'Ural State Forestry Technical Academy',
  'Ural State Technical University',
  'Ural State University of Economics',
  'Urban Academy Charter School',
  'Urban Academy Laboratory High School',
  'Urban Academy Of Greater Pittsburgh Cs',
  'Urban Action Academy',
  'Urban Assembly Academy For Future Leaders',
  'Urban Assembly Academy Of Government And Law',
  'Urban Assembly Bronx Academy Of Letters (the)',
  'Urban Assembly For The Performing Arts',
  'Urban Assembly Gateway School For Technology',
  'Urban Assembly Institute Of Math And Science For Young Women',
  'Urban Assembly Maker Academy',
  'Urban Assembly New York Harbor School',
  'Urban Assembly School For Applied Math And Science',
  'Urban Assembly School For Collaborative Healthcare',
  'Urban Assembly School For Criminal Justice',
  'Urban Assembly School For Emergency Management (the)',
  'Urban Assembly School For Global Commerce (the)',
  'Urban Assembly School For Green Careers (the)',
  'Urban Assembly School For Law And Justice',
  'Urban Assembly School For Media Studies',
  'Urban Assembly School For Music And Art',
  'Urban Assembly School For Wildlife Conservation',
  'Urban Assembly School Of Business For Young Women',
  'Urban Assembly School Of Design And Construction',
  'Urban Assembly Unison School (the)',
  'Urban Choice Charter School',
  'Urban Collaborative Program',
  'Urban College of Boston',
  'Urban Corps Of San Diego County Charter',
  'Urban Discovery Academy Charter',
  'Urban Dove Team Charter School',
  'Urban Early College Network',
  'Urban Institute Of Mathematics',
  'Urban Leadership Academy',
  'Urban Middle',
  'Urban Montessori Charter',
  'Urban Park El',
  'Urban Pathways 6-12 Cs',
  'Urban Pathways K-5 College Charter Schoo',
  'Urban Prep Chtr Acad Englewood Hs',
  'Urban Prep Chtr Bronzeville Hs',
  'Urban Prep Chtr West Campus Hs',
  'Urban Promise Academy',
  'Urban Scholars Community School',
  'Urban Science Academy',
  'Urbana Community School',
  'Urbana Early Childhood Ctr',
  'Urbana Elementary',
  'Urbana Gerber School',
  'Urbana High',
  'Urbana High School',
  'Urbana Junior High School',
  'Urbana Middle',
  'Urbana Middle School',
  'Urbandale High School',
  'Urbandale Middle School',
  'Urbin T. Kelley School',
  'Urbita Elementary',
  'Urceo-upper Rogue Center For Educational Opportunities',
  'Uriah H Lawton School',
  'Uriah Hill School',
  'Urie Elementary',
  'Urmia University',
  'Urmia University of Medical Sciences',
  'Urmia University of Technology',
  'Ursa Major Elementary',
  'Ursa Minor Elementary',
  'Ursinus College',
  'Ursula Stephens El',
  'Urumqi Vocational University',
  'Us Jones Elementary School',
  'Usak University',
  'Usc College Prep Santa Ana Campus',
  'Usd 331 Virtual School',
  'Usf/patel Partnership Elementary School',
  'Uskudar University',
  'Usmanu Danfodiyo University Sokoto',
  'Ustick Elementary School',
  'Ut - University Charter School At High Point',
  'Ut - University Charter School At Memorial Hermann',
  'Ut Tyler Innovation Academy - Longview',
  'Ut Tyler Innovation Academy - Palestine',
  'Ut Tyler Innovation Academy - Tyler',
  'Uta Halee Academy Program',
  'Utah Career Path High School',
  'Utah Connections Academy',
  'Utah County Academy Of Science',
  'Utah Electronic High School',
  'Utah International Charter School',
  'Utah Military Academy',
  'Utah Military Academy - Camp Williams',
  'Utah Online 7-12',
  'Utah Online K8',
  'Utah State University',
  'Utah Street Elementary',
  'Utah Valley State College',
  'Utah Virtual Academy',
  'Ute Meadows Elementary School',
  'Ute Pass Elementary School',
  'Ute Perkins Elementary School',
  'Uthoff Valley Elem.',
  'Utica Academy Of Science Charter School',
  'Utica College',
  'Utica Elem. / Middle School',
  'Utica Elementary School',
  'Utica High School',
  'Utica Middle School',
  'Utica Shale Academy Of Ohio',
  'Utkal University',
  'Utopia School',
  'Utopian Academy For The Arts Charter School',
  'Utpb Stem Academy',
  'Utrecht University',
  'Utsalady Elementary',
  'Utsunomiya University',
  'Uttar Pradesh Technical University',
  'Uttarakhand Open University',
  'Uttarakhand Technical University',
  'Utterback 4-6',
  'Utterback 7-8',
  'Utterback Middle School',
  'Uva Wellassa University',
  'Uvalde H S',
  'Uw Laboratory School',
  'Uwchlan Hills El Sch',
  'Uwharrie Charter Academy',
  'Uwharrie Ridge Six-twelve',
  'Uxbridge High',
  'Uzbek State World Languages University',
  'Uzhgorod National University',
  'Uzima University College',
  'V Blanche Graham Elementary',
  'V G Hawkins Middle School',
  'V H Lassen Elementary School',
  'V H Nelson Elem School',
  'V I T Elementary School',
  'V I T Jr High School',
  'V I T Sr High School',
  'V LINDSAY SDA SCHOOL',
  'V M Adams El',
  'V O Isom Central Elem School',
  'V R Eaton H S',
  'V Sue Cleveland High',
  'V. B. Glencoe Charter School',
  'V. V. REID ACADEMY',
  'V.V.REID ACADEMY',
  'VACAVILLE CHRISTIAN SCHOOLS',
  'VAIL CHRISTIAN ACADEMY',
  'VAIL CHRISTIAN HIGH SCHOOL',
  'VAIL MOUNTAIN SCHOOL',
  'VALDOSTA CHRISTIAN ACADEMY',
  'VALIANT CHRISTIAN ACADEMY',
  'VALIANT CROSS ACADEMY',
  'VALLE CATHOLIC GRADE SCHOOL',
  'VALLEY ACADEMY',
  'VALLEY ADVENTIST CHRISTIAN SCHOOL',
  'VALLEY BAPTIST ACADEMY',
  'VALLEY BAPTIST CHRISTIAN SCHOOL',
  'VALLEY BELL SCHOOL',
  'VALLEY BETH SHALOM DAY SCHOOL',
  'VALLEY CATHOLIC ELEMENTARY SCHOOL',
  'VALLEY CHRISTIAN ACADEMY',
  'VALLEY CHRISTIAN HIGH SCHOOL',
  'VALLEY CHRISTIAN JUNIOR HIGH SCHOOL',
  'VALLEY CHRISTIAN SCHOOL',
  'VALLEY CHRISTIAN SCHOOLS',
  'VALLEY COMMUNITY CHAPEL SCHOOL & DAYCARE',
  'VALLEY CRESCENT SCHOOL',
  'VALLEY DAY SCHOOL',
  'VALLEY FELLOWSHIP CHRISTIAN ACADEMY',
  'VALLEY FORGE MILITARY ACADEMY',
  'VALLEY HEIGHTS CHRISTIAN ACADEMY',
  'VALLEY HIGH SCHOOL & LEARN',
  'VALLEY INTERNATIONAL ACADEMY',
  'VALLEY LINE SCHOOL',
  'VALLEY LUTHERAN HIGH SCHOOL',
  'VALLEY LUTHERAN SCHOOL',
  'VALLEY MONTESSORI SCHOOL',
  'VALLEY PREPARATORY SCHOOL',
  'VALLEY PRESBYTERIAN SCHOOL',
  'VALLEY ROAD SCHOOL',
  'VALLEY SCHOOL',
  'VALLEY SCHOOL OF LIGONIER',
  'VALLEY SCHOOLS',
  'VALLEY STREAM CHRISTIAN ACADEMY',
  'VALLEY TORAH HIGH SCHOOL',
  'VALLEY VIEW',
  'VALLEY VIEW ADVENTIST ACADEMY',
  'VALLEY VIEW AMISH SCHOOL',
  'VALLEY VIEW C/O REBECCA YODER',
  'VALLEY VIEW CHRISTIAN SCHOOL',
  'VALLEY VIEW DAYSCHOOL',
  'VALLEY VIEW SCHOOL',
  'VALLEY VIEW SDA SCHOOL',
  'VALLEY WEST SCHOOL',
  'VALLEYVIEW AMISH SCHOOL',
  'VALMONT ACADEMY',
  'VALOR ACADEMY',
  'VALOR CHRISTIAN ACADEMY',
  'VALOR CHRISTIAN ACADEMY- INC',
  'VALOR CHRISTIAN HIGH SCHOOL',
  'VALOR CHRISTIAN SCHOOL INTERNATIONAL',
  'VALOR TRADITIONAL ACADEMY',
  'VALWOOD SCHOOL',
  'VAN DER KOLK GLENHAVEN ACADEMY',
  'VANCOUVER MONTESSORI SCHOOL',
  'VANDALIA CHRISTIAN ACADEMY',
  'VANDALIA CHRISTIAN SCHOOL',
  'VANDEBILT CATHOLIC HIGH SCHOOL',
  'VANGUARD COLLEGE PREPARATORY SCHOOL',
  'VANGUARD PREPARATORY SCHOOL',
  'VANGUARD SCHOOL',
  'VANGUARD SCHOOL OF COCONUT GROVE FL',
  'VANN ACADEMY',
  'VARANGON ACADEMY',
  'VARIETY CHILD LEARNING CENTER',
  'VARIETY SCHOOL OF HAWAII',
  'VBS Purvanchal University',
  'VCMS',
  'VENANGO CATHOLIC HIGH SCHOOL',
  'VENERINI ACADEMY',
  'VENICE CHRISTIAN SCHOOL',
  'VENICE LUTHERAN SCHOOL',
  'VENN ACADEMY',
  'VENTANA SCHOOL',
  'VENTURA CHRISTIAN ACADEMY',
  'VENTURA COUNTY CHRISTIAN SCHOOL',
  'VENTURA MONTESSORI SCHOOL',
  'VERBUM DEI HIGH SCHOOL',
  'VERDANT VALLEY SCHOOL/MERVIN STOLTZFUS, SECRETARY',
  'VERDE VALLEY SCHOOL',
  'VERITAS ACADEMY',
  'VERITAS ACADEMY OF CENTRAL FLORIDA',
  'VERITAS ACADEMY OF TUCSON',
  'VERITAS CHRISTI CATHOLIC H.S.',
  'VERITAS CHRISTIAN ACADEMY',
  'VERITAS CHRISTIAN COMMUNITY SCHOOL',
  'VERITAS CHRISTIAN SCHOOL',
  'VERITAS CLASSICAL ACADEMY',
  'VERITAS CLASSICAL CHRISTIAN ACADEMY',
  'VERITAS COLLEGIATE ACADEMY',
  'VERITAS SCHOOL',
  'VERMILION CATHOLIC HIGH SCHOOL',
  'VERMONT CHRISTIAN SCHOOL',
  'VERMONT COMMONS SCHOOL',
  'VERMONT DAY SCHOOL',
  'VERNA MONTESSORI SCHOOL',
  'VERNON HILLS MONTESSORI ACADEMY',
  'VFKH MONTESSORI SCHOOL',
  'VHM CHRISTIAN SCHOOL',
  'VIA CENTER',
  'VICKSBURG COMMUNITY SCHOOL',
  'VICTOR VALLEY CHRISTIAN SCHOOLS',
  'VICTOR VALLEY SDA SCHOOL',
  'VICTORIA CHRISTIAN SCHOOL',
  "VICTORIA'S PLAYHOUSE DAYCARE PRE-K",
  "VICTORIA'S PLAYHOUSE PRE-K",
  'VICTORIOUS KIDZ ACADEMY',
  'VICTORY ACADEMY',
  'VICTORY ACADEMY FOR BOYS',
  'VICTORY ACADEMY OCALA',
  'VICTORY BAPTIST ACADEMY',
  'VICTORY BAPTIST CHRISTIAN SCHOOL - VBCS',
  'VICTORY BAPTIST SCHOOL',
  'VICTORY CHRISITIAN ACADEMY',
  'VICTORY CHRISTIAN ACADEMY',
  'VICTORY CHRISTIAN ACADEMY, INC',
  'VICTORY CHRISTIAN ACADEMY/TSHUWAH HEBREW ACADEMY',
  'VICTORY CHRISTIAN CENTER SCHOOL',
  'VICTORY CHRISTIAN HIGH SCHOOL',
  'VICTORY CHRISTIAN PRESCHOOL & ACADEMY',
  'VICTORY CHRISTIAN SCHOOL',
  'VICTORY DAY SCHOOL',
  'VICTORY HIGH SCHOOL',
  'VICTORY IN CHRIST CLASSICAL LUTHERAN ACADEMY',
  'VICTORY LEARNING CENTER',
  'VICTORY LIFE ACADEMY',
  'VICTORY LIFE CHILD DEVELOPMENT CENTER',
  'VICTORY PREPARATORY SCHOOL',
  'VICTORY WORLD CHRISTIAN SCHOOL',
  'VIENNA ADVENTIST ACADEMY',
  'VIEWPOINT CHRISTIAN SCHOOL',
  'VIEWPOINT SCHOOL',
  'VILLA ACADEMY',
  'VILLA DI MARIA MONTESSORI SCHOOL',
  'VILLA DUCHESNE & OAK HILL SCHOOL',
  'VILLA ESPERANZA SERVICES',
  'VILLA JOSEPH MARIE HIGH SCHOOL',
  'VILLA MADONNA MONTESSORI',
  'VILLA MARIA ACADEMY',
  'VILLA MARIA EDUCATION CENTER',
  'VILLA MARIA SCHOOL',
  'VILLA MARIA TERESA OBLATE SISTERS OF-SACRED HEART',
  'VILLA MARIE SCHOOL',
  'VILLA MONTESSORI',
  'VILLA MONTESSORI SCHOOL',
  'VILLA OF HOPE',
  'VILLA VICTORIA ACADEMY',
  'VILLA WALSH ACADEMY',
  'VILLAGE ACADEMY',
  'VILLAGE ACADEMY AT CHILDHELP EAST',
  'VILLAGE ACADEMY OF MARYLAND',
  'VILLAGE ACADEMY SCHOOLS',
  'VILLAGE CHILD LEARNING CENTER',
  "VILLAGE CHILDREN'S ACADEMY",
  'VILLAGE CHRISTIAN ACADEMY',
  'VILLAGE CHRISTIAN SCHOOL',
  'VILLAGE CHRISTIAN SCHOOLS',
  'VILLAGE COMMUNITY SCHOOL',
  'VILLAGE FREE SCHOOL',
  "VILLAGE GATE CHILDREN'S ACADEMY",
  'VILLAGE GLEN WEST',
  'VILLAGE MONTESSORI ACADEMY',
  'VILLAGE MONTESSORI AND PREPARATORY SCHOOL',
  'VILLAGE MONTESSORI CENTER',
  'VILLAGE MONTESSORI DAY SCHOOL',
  'VILLAGE MONTESSORI SCHOOL',
  'VILLAGE MONTESSORI SCHOOL AT BLUEMONT',
  'VILLAGE MONTESSORI SCHOOL OF IRVINE',
  'VILLAGE MONTESSORI SCHOOL, LLC',
  'VILLAGE PARKWAY CHRISTIAN SCHOOL',
  'VILLAGE PLAYSCHOOL',
  'VILLAGE SCHOOL',
  'VILLAGE SCHOOL OF NO.BENNINGTON',
  'VILLAGE SDA ELEMENTARY SCHOOL',
  'VILLALYAN',
  'VILLANOVA ACADEMY FOR HONOR STUDIES',
  'VILLANOVA PREPARATORY SCHOOL',
  'VINCENT MEMORIAL CATHOLIC HIGH SCHOOL',
  'VINCENT S MASTRO MONTESSORI ACADEMY',
  'VINCENT SMITH SCHOOL',
  'VINE ACADEMY',
  'VINE HAVEN ADVENTIST SCHOOL',
  'VINEMONT CHRISTIAN ACADEMY',
  'VINEYARD ACADEMY',
  'VINEYARD CHRISTIAN SCHOOL',
  'VINEYARD MONTESSORI SCHOOL',
  'VIOLA M BERARD SCHOOL',
  'VIRGINIA ACADEMY',
  'VIRGINIA BEACH FRIENDS SCHOOL',
  'VIRGINIA EPISCOPAL SCHOOL',
  'VIRGINIA INSTITUTE OF AUTISM',
  'VIRGINIA INTERNATIONAL ACADEMY',
  'VIRTUAL SCHOOLS OF EXCELLENCE',
  'VISALIA MONTESSORI SCHOOL',
  'VISION CHRISTIAN ACADEMY',
  'VISION EDUCATIONAL LEARNING CENTER',
  'VISION WAY CHRISTIAN SCHOOL',
  'VISIONQUEST-BIG LODGE',
  'VISIONS JUNIOR SENIOR HIGH SCHOOL',
  'VISITATION ACADEMY',
  'VISITATION ACADEMY HIGH SCHOOL',
  'VISITATION BVM SCHOOL',
  'VISITATION CATHOLIC SCHOOL',
  'VISITATION CATHOLIC STEM ACADEMY',
  'VISITATION INTER-PARISH SCHOOL',
  'VISITATION OF OUR LADY SCHOOL',
  'VISITATION SCHOOL',
  'VISTA CHRISTIAN SCHOOL',
  'VISTA RIDGE ACADEMY',
  'VISTA SCHOOL',
  'VIZNITZER CHAIDER TIFERES YISROEL',
  'VJ & ANGELA SKUTT CATHOLIC HIGH SCHOOL',
  'VOICES OF FAITH CHRISTIAN ACADEMY',
  'VOLGA CHRISTIAN SCHOOL',
  'VON WEDEL MONTESSORI SCHOOL',
  "VOYAGER'S COMMUNITY SCHOOL",
  'Vaal University of Technology',
  'Vaasa University of Applied Sciences',
  'Vaca Pena Middle',
  'Vacaville High',
  'Vacherie Elementary School',
  'Vacte - Camp Verde Unified School District',
  'Vacte - Clarkdale Jerome School District',
  'Vacte - Cottonwood-oak Creek Unified School District',
  'Vacte - Mingus Union High School',
  'Vacte - Sedona-oak Creek Joint Unified School District',
  'Vacte - Valley Academy For Career And Technology Education',
  'Vadnais Heights Elementary',
  'Vado Elementary',
  'Vae View School',
  'Vaganova Academy of Russian Ballet',
  'Vail Academy & High School',
  'Vail Elementary',
  'Vail Farm Elementary School',
  'Vail High (continuation)',
  'Vail Innovation Center',
  'Vail Pittman Elementary School',
  'Vail Ranch Middle',
  'Vail Ski And Snowboard Academy (vssa)',
  'Vaile Elementary School',
  'Vails Gate Steam Academy',
  'Val Verde Academy',
  'Val Verde Elementary',
  'Val Verde High',
  'Val Verde Student Success Academy',
  'Val Vista Lakes Elementary School',
  'Valadez Middle School Academy',
  'Valahia University of Targoviste',
  'Valders Elementary',
  'Valders High',
  'Valders Middle',
  'Valdese Elementary',
  'Valdez Elementary School',
  'Valdez High School',
  'Valdez Home School',
  'Valdosta High School',
  'Valdosta Middle School',
  'Valdosta State University',
  'Vale Elementary School',
  'Vale High School',
  'Vale Middle',
  'Vale Middle School',
  'Valencia Academy Of The Arts',
  'Valencia College',
  'Valencia Elementary',
  'Valencia High',
  'Valencia Middle School',
  'Valencia Park Elementary',
  'Valencia Valley Elementary',
  'Valentine Elementary',
  'Valentine Elementary School',
  'Valentine High School',
  'Valentine Hills Elementary',
  'Valentine Middle School',
  'Valentine Peyton Elementary',
  'Valentine School',
  'Valerie Prek-6 School',
  'Valerio Street Elementary',
  'Valerius Elementary School',
  'Valeska Hinton Early Ch Ed Ctr',
  'Valhalla Elementary',
  'Valhalla Elementary School',
  'Valhalla High',
  'Valhalla High School',
  'Valhalla Middle School',
  'Vali-e-Asr University',
  'Valiant Academy',
  'Valiant Academy Of Los Angeles',
  'Valiant Academy Of Southern California',
  'Valiant Santa Barbara',
  'Validus Preparatory Academy',
  'Valiente College Preparatory Charter',
  'Valier 7-8',
  'Valier High School',
  'Valier School',
  'Valinda School Of Academics',
  'Valle Crucis Elementary',
  'Valle Del Encanto Learning Center',
  'Valle Del Sol Elementary',
  'Valle Lindo Elementary',
  'Valle Verde Early College H S',
  'Valle Verde Elementary',
  'Valle Vista Elementary',
  'Vallecito Continuation High',
  'Vallecito Elementary',
  'Vallecitos Elementary',
  'Vallejo Adult Transition',
  'Vallejo Charter',
  'Vallejo Education Academy',
  'Vallejo High',
  'Vallejo Mill Elementary',
  'Vallemar Elementary',
  'Valley Academy',
  'Valley Academy Of Arts And Sciences',
  'Valley Academy Of Learning',
  'Valley Alternative High (continuation)',
  'Valley Alternative Magnet',
  'Valley Atwater Community',
  'Valley Career And Technical Center',
  'Valley Center Elementary',
  'Valley Center High',
  'Valley Center Intermediate School',
  'Valley Center Middle',
  'Valley Center Middle School',
  'Valley Center Prep',
  'Valley Center Primary',
  'Valley Center School',
  'Valley Central High School',
  'Valley Central Middle School',
  'Valley Charter Elementary',
  'Valley Charter High',
  'Valley Charter Middle',
  "Valley Children's Hospital",
  'Valley City High School',
  'Valley City Junior High School',
  'Valley City State University',
  'Valley Community Day',
  'Valley Cottage School',
  'Valley County Juvenile Detention',
  'Valley Creek El',
  'Valley Crest School',
  'Valley Crossing Elementary',
  'Valley Early Childhood School',
  'Valley Early Learning Center',
  'Valley El Sch',
  'Valley El/ms',
  'Valley Elementary',
  'Valley Elementary School',
  'Valley Falls Elem',
  'Valley Falls High',
  'Valley Forge El Sch',
  'Valley Forge Elementary',
  'Valley Forge Elementary School',
  'Valley Forge High School',
  'Valley Forge Military College',
  'Valley Forge Ms',
  'Valley Grande Elementary School',
  'Valley Grove El Sch',
  'Valley Grove Elementary School',
  'Valley Head Elementary School',
  'Valley Head High School',
  'Valley Heights Elem',
  'Valley Heights Jr/sr High',
  'Valley Hi El',
  'Valley High',
  'Valley High (continuation)',
  'Valley High School',
  'Valley Home Elementary',
  'Valley Horizon Elementary School',
  'Valley Inquiry Charter School',
  'Valley Institute Elementary',
  'Valley Jr High',
  'Valley Jshs',
  'Valley Junior High School',
  'Valley Life Charter',
  'Valley Los Banos Community',
  'Valley Merced Community',
  'Valley Middle',
  'Valley Middle School',
  'Valley Mills Daep',
  'Valley Mills El',
  'Valley Mills Elementary School',
  'Valley Mills H S',
  'Valley Mills J H',
  'Valley New School',
  'Valley Oak Charter',
  'Valley Oak Continuation High',
  'Valley Oak Elementary',
  'Valley Oak High',
  'Valley Oak Junior And Senior High',
  'Valley Oak Middle',
  'Valley Oaks',
  'Valley Oaks Charter',
  'Valley Oaks El',
  'Valley Oaks Elementary',
  'Valley Oaks Elementary (alternative)',
  'Valley Oaks High (alternative)',
  'Valley Of Enchantment Elementary',
  'Valley Park Elem.',
  'Valley Park Elementary',
  'Valley Park Elementary School',
  'Valley Park Middle',
  'Valley Park Sr. High',
  'Valley Pathways',
  'Valley Point Elementary School',
  'Valley Point Middle School',
  'Valley Preparatory Academy',
  'Valley Ranch El',
  'Valley Regional High School',
  'Valley Ridge Academy',
  'Valley Ridge El',
  'Valley Rivers Middle School',
  'Valley Road School',
  'Valley Rop',
  'Valley School',
  'Valley Southwoods',
  'Valley Springs Elem. School',
  'Valley Springs Elementary',
  'Valley Springs Elementary - 04',
  'Valley Springs High School',
  'Valley Springs Middle',
  'Valley Springs Middle School',
  'Valley Stem+me2 Academy',
  'Valley Stream Central High School',
  'Valley Stream Memorial Junior High School',
  'Valley Stream North High School',
  'Valley Stream South High School',
  'Valley Teen Ranch Community Day',
  'Valley Union High School',
  'Valley View 5th Grade Campus',
  'Valley View Alternative High School',
  'Valley View Alternative Program',
  'Valley View Charter Prep',
  'Valley View Community Elementary',
  'Valley View Community School',
  'Valley View Early Childhood Center',
  'Valley View Early Childhood Cntr',
  'Valley View Early College Campus',
  'Valley View Education Center',
  'Valley View El',
  'Valley View El Ctr',
  'Valley View El Sch',
  'Valley View Elem School',
  'Valley View Elementary',
  'Valley View Elementary (tk-6)',
  'Valley View Elementary - 13',
  'Valley View Elementary School',
  'Valley View H S',
  'Valley View High',
  'Valley View High (continuation)',
  'Valley View High School',
  'Valley View Hs',
  'Valley View Intermediate School',
  'Valley View Intrmd Sch',
  'Valley View J H',
  'Valley View J J A E P',
  'Valley View Junior High School',
  'Valley View K-8',
  'Valley View Middle',
  'Valley View Middle School',
  'Valley View Ms',
  'Valley View North El',
  'Valley View Primary School',
  'Valley View School',
  'Valley View South El',
  'Valley View University',
  'Valley Vista Elementary',
  'Valley Vista Elementary School',
  'Valley Vista High (continuation)',
  'Valley Vista High School',
  'Valley West El',
  'Valley-edinburg Elementary School-crystal',
  'Valley-edinburg Elementary School-hoople',
  'Valley-edinburg High School',
  'Valleydale Elementary',
  'Valleyview Elementary School',
  'Valleyview Middle School',
  'Valleywood Middle School',
  'Valliant Es',
  'Valliant Hs',
  'Valliant Ms',
  'Vallivue Academy',
  'Vallivue High School',
  'Vallivue Middle School',
  'Vallivue Virtual Academy',
  'Valmead Elementary',
  'Valmeyer Elementary School',
  'Valmeyer High School',
  'Valmeyer Jr High',
  'Valmonte Elementary',
  'Valor Academy Elementary',
  'Valor Academy High',
  'Valor Academy Middle',
  'Valor Academy Of Leadership Middle School',
  'Valor Academy Of Leadership-high School',
  'Valor Flagship Academy',
  'Valor Middle School',
  'Valor Public Schools',
  'Valor Voyager Academy',
  'Valparaiso High School',
  'Valparaiso University',
  'Valrico Elementary School',
  'Valrico Lake Advantage Academy',
  'Valverda Elementary School',
  'Valverde Elementary School',
  'Van Allen Elementary',
  'Van Allen Elementary School',
  'Van Alstyne El',
  'Van Alstyne H S',
  'Van Alstyne J H',
  'Van Antwerp Middle School',
  'Van Arsdale Elementary School',
  'Van Asselt Elementary School',
  'Van Bokkelen Elementary',
  'Van Brunt Elementary',
  'Van Buren Co High School',
  'Van Buren District Secondary Sch',
  'Van Buren El',
  'Van Buren Elem.',
  'Van Buren Elementary',
  'Van Buren Elementary School',
  'Van Buren Es',
  'Van Buren Freshman Academy',
  'Van Buren High',
  'Van Buren High School',
  'Van Buren Middle',
  'Van Buren Middle School',
  'Van Buren Regional Technology Ctr',
  'Van Buren Technology Center',
  'Van Buskirk Elementary School',
  'Van Cleve Elementary School',
  'Van Corlaer Elementary School',
  'Van Cortlandtville School',
  'Van Cove Elementary School',
  'Van Deene Avenue Elementary',
  'Van Derveer Elementary School',
  'Van Devender Middle School',
  'Van Duyn Elementary School',
  'Van Duzen Elementary',
  'Van E. Blanton Elementary School',
  'Van Elementary',
  'Van Gogh Charter',
  'Van Gorder Elementary School',
  'Van H S',
  'Van Hise Elementary',
  'Van Holten Primary School',
  'Van Hoosen Middle School',
  'Van Horn High',
  'Van Horn School',
  'Van Horne Elementary School - Closed',
  'Van Int',
  'Van J H',
  'Van Junior/senior High School',
  'Van Meter Elementary School',
  'Van Meter High School School',
  'Van Meter Middle School',
  'Van Middle',
  'Van Ness Avenue Elementary',
  'Van Ness Es',
  'Van Nuys Elementary',
  'Van Nuys Middle',
  'Van Nuys Senior High',
  'Van Orin Elem School',
  'Van R. Butler Elementary School',
  'Van Raub El',
  'Van Rensselaer Elementary School',
  'Van Schaick Island School',
  'Van Sciver Elementary School',
  'Van Sickle Academy',
  'Van Siclen Community Middle School',
  'Van Vleck El',
  'Van Vleck H S',
  'Van Wert Early Childhood Center',
  'Van Wert Elementary School',
  'Van Wert High School',
  'Van Wert Middle School',
  'Van Winkle Elementary School',
  'Van Wyck Junior High School',
  'Van Zandt Alternative Education Prog Coop',
  'Van Zandt Co Youth Multi-service Ctr',
  'Van Zandt-guinn El',
  'Van-far Elem.',
  'Van-far Jr./sr. High',
  'Vanalden Avenue Elementary',
  'Vance Charter School',
  'Vance Co Early College',
  'Vance Elementary',
  'Vance Elementary School',
  'Vance High',
  'Vance Middle School',
  'Vance-Granville Community College',
  'Vance-providence Elementary',
  'Vanceboro-farm Life Elementary',
  'Vancleave High School',
  'Vancleave Lower Elementary',
  'Vancleave Middle School',
  'Vancleave Upper Elementary',
  'Vancouver Alternative Programs',
  'Vancouver Community College',
  'Vancouver Contracted Programs',
  'Vancouver Home Connection',
  'Vancouver Itech Preparatory',
  'Vancouver School Of Arts And Academics',
  'Vancouver Virtual Learning Academy',
  'Vandagriff El',
  'Vandalia Community High School',
  'Vandalia Elementary',
  'Vandalia Elementary School',
  'Vandalia Junior High School',
  'Vandegrift H S',
  'Vanden High',
  'Vandenberg Elementary - 02',
  'Vandenberg Elementary School',
  'Vandenberg Middle',
  'Vandenberge Middle School',
  'Vandenboom Alternative High School',
  'Vanderbilt Area School',
  'Vanderbilt Charter Academy',
  'Vanderbilt Elementary School',
  'Vanderbilt University',
  'Vandercook Lake High School',
  'Vandercook Lake Virtual Academy',
  'Vandergriff Elementary School',
  'Vanderhoof Elementary School',
  'Vanderlyn Elementary School',
  'Vanderpoel Elem Magnet School',
  'Vandeventer Middle',
  'Vandever Es',
  'Vandora Springs Elementary',
  'Vandyke Elementary',
  'Vang Pao Elementary',
  'Vangorden Elementary School',
  'Vanguard Academy',
  'Vanguard Beethoven',
  'Vanguard Charter Academy',
  'Vanguard Classical School - East',
  'Vanguard Classical School - West',
  'Vanguard Collegiate High School',
  'Vanguard Collegiate Middle School',
  'Vanguard High School',
  'Vanguard Learning Center',
  'Vanguard Mozart',
  'Vanguard Picasso',
  'Vanguard Preparatory',
  'Vanguard Rembrandt',
  'Vanguard School',
  'Vanguard Tech Center',
  'Vanleer Elementary',
  'Vanlue Elementary School',
  'Vanlue High School',
  'Vannoy Elementary',
  'Vanoss Es',
  'Vanoss Hs',
  'Vanston Middle',
  'Vanstone Elementary',
  'Vanstory Hills Elementary',
  'Vansville Elementary',
  'Vantage Career Center',
  'Vantage Point',
  'Vantage Point Charter',
  'Vardaman Elementary School',
  'Vardaman High School',
  'Vardhaman Mahaveer Open University',
  'Vare-washington El Sch',
  'Varennes Elementary',
  'Vargas Elementary',
  'Variety Elementary School',
  'Variety School',
  'Varina Elementary',
  'Varina High',
  'Varna Free University',
  'Varnado High School',
  'Varnell Elementary School',
  'Varner Elementary School',
  'Varnett Charter School',
  'Varnum Brook',
  'Varnum Es',
  'Varnum Hs',
  'Varnville Elementary',
  'Varsity Lakes Middle School',
  'Vartan Gregorian El. School',
  'Vashon High',
  'Vashon Island High School',
  'Vasquez High',
  'Vass-lakeview Elementary',
  'Vassal Lane Upper School',
  'Vassalboro Community School',
  'Vassar College',
  'Vassar Elementary School',
  'Vassar Middle School',
  'Vassar Road Elementary School',
  'Vassar Senior High School',
  'Vaughan Academy Of Technology',
  'Vaughan Elementary',
  'Vaughan Elementary School',
  'Vaughn 7-8',
  'Vaughn El',
  'Vaughn Elementary',
  'Vaughn Elementary School',
  'Vaughn High',
  'Vaughn Hugie Family Ed Center',
  'Vaughn Next Century Learning Center',
  'Vaughn Occupational High School',
  'Vaughn Road Elementary School',
  'Vaughn School',
  'Vaux Hs: A Big Picture School',
  'Veale Elementary School',
  'Veazie Community School',
  'Veazie Street School',
  'Vector Basic',
  'Vector Low Incidence',
  'Vector Prep And Arts Academy',
  'Veeder Elementary School',
  'Veer Kunwar Singh University',
  'Veer Surendra Sai University of Technology',
  'Vega Collegiate Academy',
  'Vega El',
  'Vega H S',
  'Vega J H',
  'Vegas Verdes Elementary School',
  'Vejar Elementary',
  'Vela Middle',
  'Velarde Elementary',
  'Velasco El',
  'Veliko Turnovo University "Cyril and Methodius"',
  'Vellore Institute of Technology',
  'Velma Jackson High School',
  'Velma Linford Elementary',
  'Velma Matson Upper Elementary School',
  'Velma Penny El',
  'Velma-alma Es',
  'Velma-alma Hs',
  'Velma-alma Ms',
  'Velva Elementary School',
  'Velva High School',
  'Vena Avenue Elementary',
  'Vena Stuart Elementary',
  'Venable Elementary',
  'Venable Village El',
  'Venado Middle',
  'Venango Technology Center',
  'Vencil Brown Elementary',
  'Veneta Elementary School',
  'Venetia Elementary School',
  'Venetia Valley K-8',
  'Venetucci Elementary School',
  'Venice Elem School',
  'Venice Elementary School',
  'Venice Heights Elementary School',
  'Venice Middle School',
  'Venice Park School',
  'Venice Senior High',
  'Venice Senior High School',
  'Ventana Ranch Elementary',
  'Ventana Vista Elementary School',
  'Ventnor Elementary School',
  'Ventnor Middle School',
  'Ventura Charter School Of Arts And Global Education',
  'Ventura College',
  'Ventura County Special Education',
  'Ventura Elementary',
  'Ventura Elementary School',
  'Ventura High',
  'Ventura Park Elementary School',
  'Venture',
  'Venture (alternative)',
  'Venture Academy',
  'Venture Academy-meriden School District',
  'Venture Alter H S',
  'Venture High School',
  'Venture Prep High School',
  'Venture Transition',
  'Venus El',
  'Venus Gardens',
  'Venus H S',
  'Venus Middle',
  'Venus Pri',
  "Vera C O'leary Middle School",
  'Vera Kilpatrick Elem. School',
  'Vera Ralya Elementary School',
  'Vera Wilsie Elementary School',
  'Veramendi El',
  'Verbena High School',
  'Verda Dierzen Early Learning Ctr',
  'Verda Elementary School',
  'Verda James Elementary',
  'Verde Elementary',
  'Verde Elementary School',
  'Verde Valley Montessori School - A Center For Creative Educa',
  'Verden Es',
  'Verden Hs',
  'Verdi Elementary School',
  'Verdigre Elementary School',
  'Verdigre High School',
  'Verdigre Middle School',
  'Verdigris Es',
  'Verdigris Hs',
  'Verdigris Jhs',
  'Verdigris Upper Es',
  'Verdugo Academy',
  'Verdugo Hills Senior High',
  'Verdugo Woodlands Elementary',
  'Verdunville Elementary School',
  'Verelle Peniston School',
  'Verellen Elementary School',
  'Vergennes Union Elementary School',
  'Vergennes Union High School',
  'Veribest Daep',
  'Veribest El',
  'Veribest H S',
  'Veribest Ppcd',
  'Veribest Special Programs',
  'Veritas',
  'Veritas Academy',
  'Veritas College Preparatory',
  'Veritas Community School Cfa',
  'Veritas Elementary',
  'Veritas High',
  'Veritas Preparatory Charter School',
  'Veritas University',
  'Vermilion Community College',
  'Vermilion Country School',
  'Vermilion Elementary School',
  'Vermilion High School',
  'Vermillion Elementary School',
  'Vermillion High School - 01',
  'Vermillion Middle School - 02',
  'Vermillion Road El',
  'Vermont Avenue Elementary',
  'Vermont Avenue Elementary School',
  'Vermont Elementary',
  'Vermont Technical College',
  'Vern Patrick Elementary School',
  'Vern Riffe Career Technology C',
  'Vernal Lister El',
  'Vernal Middle',
  'Verndale Elementary',
  'Verndale Secondary',
  'Verne A Duncan Elementary School',
  'Verne W Critz Elementary School',
  'Verner El Sch',
  'Verner Elementary School',
  'Vernfield El Sch',
  'Vernon Center Middle School',
  'Vernon City Elementary',
  'Vernon College',
  'Vernon County Area Better Futures High',
  'Vernon E Wightman Primary School',
  'Vernon E. Greer Elementary',
  'Vernon Elementary',
  'Vernon Elementary School',
  'Vernon H S',
  'Vernon High School',
  'Vernon Hill School',
  'Vernon Hills High School',
  'Vernon Johns Middle',
  'Vernon L Barkstall Elementary Sch',
  'Vernon Malone College And Career Academy',
  'Vernon Middle',
  'Vernon Middle School',
  'Vernon School',
  'Vernon Schrade Middle',
  'Vernon Township High School',
  'Vernon-verona-sherrill Middle School',
  'Vernon-verona-sherrill Senior High School',
  'Vernonia Elementary School',
  'Vernonia High School',
  'Vernonia Middle School',
  'Vernor Elementary School',
  'Vero Beach Elementary School',
  'Vero Beach High School',
  'Verona Area High',
  'Verona Area International School',
  'Verona Area K4',
  'Verona Elem.',
  'Verona Elementary',
  'Verona Elementary School',
  'Verona High',
  'Verona High School',
  'Verona Mills School',
  'Veronica E Connor Middle School',
  'Verplanck School',
  'Verrado Elementary School',
  'Verrado Heritage Elementary School',
  'Verrado High School',
  'Verrado Middle School',
  'Versailles Elementary School',
  'Versailles High School',
  'Versailles Middle School',
  'Versia Williams El',
  'Vertus Charter School',
  'Vesalius College',
  'Vesey Elementary School',
  'Vesper Community Academy',
  'Vestaburg Community Elem School',
  'Vestaburg Community High School',
  'Vestal El',
  'Vestal Elementary School',
  'Vestal Hills Elementary School',
  'Vestal Middle School',
  'Vestal Senior High School',
  'Vestavia Hills Elementary Cahaba Heights',
  'Vestavia Hills Elementary Central',
  'Vestavia Hills High School',
  'Vet',
  'Veterans Elem.',
  'Veterans Elementary',
  'Veterans Elementary School',
  'Veterans High School',
  'Veterans Hill El',
  'Veterans Memorial',
  'Veterans Memorial Early College H S',
  'Veterans Memorial El',
  'Veterans Memorial Elementary',
  'Veterans Memorial Elementary School',
  'Veterans Memorial Family School',
  'Veterans Memorial H S',
  'Veterans Memorial Middle',
  'Veterans Memorial Middle Sch',
  'Veterans Memorial Middle School',
  'Veterans Memorial Stem Academy',
  'Veterans Middle',
  'Veterans Park Academy For The Arts',
  'Veterans Park Elementary',
  'Veterans Park Elementary School',
  'Veterans Tribute Career Techincal Academy',
  "Veterans' Memorial Elementary School",
  "Veterans' Memorial School",
  'Veterinärmedizinische Universität Wien',
  'Veva Blunt Elementary',
  'Vian Es',
  'Vian Hs',
  'Vian Ms',
  'Viborg-hurley Elementary - 02',
  'Viborg-hurley High School - 01',
  'Viborg-hurley Middle School - 03',
  'Viburnum Elementary',
  'Viburnum High',
  'Vic A. Pitre Elementary School',
  'Vic Robertson El',
  'Vicc',
  'Vicente Acevedo Ballester',
  'Vicente Benavente Middle School',
  'Vicente Martinez High',
  'Vicente Pales Anes',
  'Vicentia Elementary',
  'Vichy Elementary',
  'Vici Es',
  'Vici Hs',
  'Vick Early Childhood & Family Ctr',
  'Vick Elementary',
  'Vickers El',
  'Vickery Creek Elementary School',
  'Vickery Creek Middle School',
  'Vickery Mill Elementary',
  'Vickery School',
  'Vicki Douglas Juvenile Center',
  'Vicksburg High School',
  'Vicksburg Intermediate',
  'Vicksburg Junior High School',
  'Vicksburg Middle School',
  'Vicksburg Pathways High School',
  'Victor Early Childhood School',
  'Victor Elementary',
  'Victor Elementary School',
  'Victor F. Hodge Elementary',
  'Victor Falls Elementary',
  'Victor H Hexter El',
  'Victor High School',
  'Victor Intermediate School',
  'Victor J Andrew High School',
  'Victor Junior High School',
  'Victor Middle School',
  'Victor Mravlag School No. 21',
  'Victor Ornelas Elem',
  'Victor Parez Collazo',
  'Victor Point Elementary School',
  'Victor Primary School',
  'Victor Rojas 1',
  'Victor Rojas 2',
  'Victor School',
  'Victor Senior High School',
  'Victor Solheim Elementary School',
  'Victor Valley College',
  'Victor Valley High',
  'Victoria Avenue Elementary',
  'Victoria College',
  'Victoria East H S',
  'Victoria Elementary',
  'Victoria Fertitta Middle School',
  'Victoria Groves Elementary',
  'Victoria Heights El',
  'Victoria International University',
  'Victoria Junior-senior High School',
  'Victoria Magathan Elementary',
  'Victoria Regional Juvenile Justice Center',
  'Victoria Santiago Colon (quebrada)',
  'Victoria University',
  'Victoria University Toronto University of Toronto',
  'Victoria University of Wellington',
  'Victoria Walker El',
  'Victoria West H S',
  'Victoriano Elementary',
  'Victory Academy/ready For Success',
  'Victory Boulevard Elementary',
  'Victory Charter School',
  'Victory Collegiate Academy',
  'Victory Collegiate High School',
  'Victory Early College H S',
  'Victory El Sch',
  'Victory Elementary',
  'Victory High',
  'Victory High School - Campus',
  'Victory Lakes Int',
  'Victory Middle School',
  'Victory Place At Coppell',
  'Victory Preparatory Academy High State Charter Sch',
  'Victory Preparatory Academy Middle State Charter S',
  'Victory Preparatory Academy North',
  'Victory Preparatory Academy South',
  'Victory Preparatory K-8 Academy',
  'Victory Ridge Academy',
  'Victory Villa Elementary',
  'Victress Bower School For Exceptional Students',
  'Vida Bogart School For All Children (the)',
  'Vida Charter School',
  'Vida N Clover El',
  'Vida School',
  'Vidalia Comprehensive High School',
  'Vidalia High School',
  'Vidalia Junior High School',
  'Vidalia Lower Elementary School',
  'Vidalia Upper Elementary School',
  'Vidant Health',
  'Vidor El',
  'Vidor H S',
  'Vidor J H',
  'Vidor Middle',
  'Vidrine Elementary School',
  'Vidyasagar University',
  'Vidzeme University College',
  'Vieau Elementary',
  'Vieja Valley Elementary',
  'Viejo Elementary',
  'Vienna Elem School Dist 55',
  'Vienna Elem.',
  'Vienna Elementary',
  'Vienna Elementary School',
  'Vienna High',
  'Vienna High School',
  'Vienna-finley Elementary School',
  'Viera Charter School',
  'Viera High School',
  'Viers Mill Elementary',
  'Vietnam Maritime University',
  'Vietnam National University Hanoi',
  'Vietnam National University Ho Chi Minh City',
  'Vietnamese - German University',
  'View Acres Elementary School',
  'View Park Continuation',
  'View Ridge Elementary',
  'View Ridge Elementary School',
  'View Ridge Middle School',
  'Viewlands Elementary School',
  'Viewmont Elementary',
  'Viewmont High',
  'Viewmont School',
  'Vignan University',
  'Vikan Middle School',
  'Viking Elementary',
  'Viking Elementary School',
  'Viking Middle',
  'Viking School',
  'Viking Virtual Academy',
  'Vikram University',
  'Vikrama Simhapuri University',
  'Vilas Elementary School',
  'Vilas Undivided High School',
  'Villa Capri',
  'Villa Cresta Elementary',
  'Villa De Paz Elementary School',
  'Villa Del Rey Elementary School',
  'Villa Granada',
  'Villa Grove Elem School',
  'Villa Grove High School',
  'Villa Grove Jr High School',
  'Villa Heights Elementary',
  'Villa Julie College',
  'Villa Marina',
  'Villa Montessori - Phoenix Campus',
  'Villa Nueva El',
  'Villa Oasis Interscholastic Center For Education (voice)',
  'Villa Park Elementary',
  'Villa Park High',
  'Villa Rica Elementary School',
  'Villa Rica High School',
  'Villa Rica Middle',
  'Villacorta Elementary',
  'Village',
  'Village Academy',
  'Village Academy High School At Indian Hill',
  'Village Academy On The Art & Sara Jo Kobacker Campus',
  'Village At North',
  'Village Charter',
  'Village Charter Academy',
  'Village East Community Elementary School',
  'Village East Middle School',
  'Village El',
  'Village Elem',
  'Village Elementary',
  'Village Elementary Charter',
  'Village Elementary School',
  'Village Elementary School-gorham',
  'Village Elementary School-york',
  'Village Green Elementary School',
  'Village Green Environmental Studies School',
  'Village Green Virtual',
  'Village High',
  'Village Meadows Elementary School',
  'Village Oaks Elementary School',
  'Village Oaks High',
  'Village Of Barboursville Elementary Sch',
  'Village Of Excellence Academy',
  'Village Of Excellence Academy Middle School',
  'Village Preparatory School',
  'Village Preparatory School Willard',
  'Village Preparatory School:: Woodland Hills Campus',
  'Village Ranch Alternative Program',
  'Village School',
  'Village Tech Schools',
  'Village View Elementary',
  'Villages Charter School',
  'Villago Middle School',
  'Villanova University',
  'Villareal El',
  'Villarreal El',
  'Villas Elementary School',
  'Ville Platte Elementary School',
  'Ville Platte High School',
  'Vilnius Academy of Arts',
  'Vilnius Gediminas Technical University',
  'Vilnius Pedagogical University',
  'Vilnius University',
  'Vilonia Elementary School',
  'Vilonia High School',
  'Vilonia Junior High School',
  'Vilonia Middle School',
  'Vilonia Primary School',
  'Vina Chattin School',
  'Vina Danks Middle',
  'Vina Elementary',
  'Vina High School',
  'Vinal Technical High School',
  'Vinalhaven School',
  "Vinayaka Mission's Research Foundation-Deemed University",
  'Vincent Academy',
  'Vincent Accelerated Academy',
  'Vincent Capuana School No. 15',
  "Vincent Children's Center",
  'Vincent Elementary School',
  'Vincent Farm Elementary',
  'Vincent High',
  'Vincent J. Gallagher Middle',
  'Vincent L Triggs Elementary School',
  'Vincent M Igo Elementary',
  'Vincent Middle',
  'Vincent Middle High School',
  'Vincent Settlement Elementary School',
  'Vinci Park Elementary',
  'Vine Elementary',
  'Vine Grove Elementary School',
  'Vine Hill Elementary',
  'Vine Middle/magnet',
  'Vine Street Community Day',
  'Vine Street Elementary',
  'Vine Street School',
  'Vinedale Elementary',
  'Vineland Elem.',
  'Vineland Elementary',
  'Vineland Elementary School',
  'Vineland K-8 Center',
  'Vineland Middle School',
  'Vineland Preparatory Academy',
  'Vineland Public Charter School',
  'Vineland Senior High School',
  'Vinemont Elementary School',
  'Vinemont High School',
  'Vinemont Middle School',
  'Vines Ec/pre-k Center',
  'Vines H S',
  'Vineville Academy',
  'Vinewood Elementary',
  'Vineyard Alternative',
  'Vineyard Elementary',
  'Vineyard Junior High',
  'Vineyard Ranch El',
  'Vineyard School',
  'Vineyards Elementary School',
  'Vinita Es',
  'Vinita Hs',
  'Vinland Elementary',
  'Vinnica National Technical University',
  'Vinoba Bhave University',
  'Vinson Middle School',
  'Vinson-bynum Elementary',
  'Vinson-owen Elementary',
  'Vintage High',
  'Vintage Hills Elementary',
  'Vintage Math/science/technology Magnet',
  'Vintage Oaks Area El',
  'Vintage Parkway Elementary',
  'Vinton County High School',
  'Vinton County Middle School',
  'Vinton Elementary School',
  'Vinton High School',
  'Vinton Middle School',
  'Vinton School',
  'Vinton-shellsburg High School',
  'Vinton-shellsburg Middle School',
  'Viola Cobb El',
  'Viola Dewalt H S',
  'Viola Elementary School',
  'Viola Gibson Elementary School',
  'Viola High School',
  'Viola L Sickles School',
  'Viola M Coleman H S',
  'Viola Rand School',
  'Violanta Jimenez',
  'Violet Avenue School',
  'Violet Elementary School',
  'Violet Heintz Education Academy',
  'Violeta Reyes Perez (elemental Urbana K-6)',
  'Violetville Elementary/middle',
  'Vip Village Preschool',
  'Viper Elementary School',
  'Virgen Milagrosa University Foundation',
  'Virgie Robinson Elementary',
  'Virgil Cooper Ms',
  'Virgil Elementary School',
  'Virgil Grissom Elementary School',
  'Virgil Grissom High School',
  'Virgil I Bailey Elementary School',
  'Virgil I Grissom Middle School',
  'Virgil I. Grissom Elementary School',
  'Virgil Jones Jr. Elementary School',
  'Virgil Middle',
  'Virgil Mills Elementary School',
  'Virgilio Davila',
  'Virgin Valley Elementary School',
  'Virgin Valley High School',
  'Virginia A Boone-highland Oaks School',
  'Virginia Allred Stacey Jr/sr H S',
  'Virginia Avenue Charlotte Dehart Elementary',
  'Virginia Avenue Elementary',
  'Virginia Beach Juvenile Detention Home',
  'Virginia Beach Middle',
  'Virginia Beach Secep',
  'Virginia Beach Secep - Re-ed - Renaissance Academy',
  'Virginia City High School',
  'Virginia City Middle School',
  'Virginia Commonwealth University',
  'Virginia Community College System',
  'Virginia Court Elementary School',
  'Virginia Cross Elementary',
  'Virginia E. George Elem.',
  'Virginia Elem School',
  'Virginia Heights Elementary',
  'Virginia High',
  'Virginia Highlands Community College',
  'Virginia Hills E.c. Resource Ctr.',
  'Virginia Intermont College',
  'Virginia Jr/ Sr High School',
  'Virginia L. Murray Elementary',
  'Virginia Lake Elem School',
  'Virginia Lee Rose Elementary',
  'Virginia Middle',
  'Virginia Military Institute',
  'Virginia Palmer Elementary School',
  'Virginia Parks Elementary',
  'Virginia Peterson Elementary',
  'Virginia Polytechnic Institute and State University',
  'Virginia Primrose Elementary',
  'Virginia R. Boris Elementary',
  'Virginia Randolph Education Center',
  'Virginia Reinhardt El',
  'Virginia Road Elementary',
  'Virginia Road Elementary School',
  'Virginia Rocca Barton Elementary',
  'Virginia Run Elementary',
  'Virginia School For Blind',
  'Virginia School For Deaf',
  'Virginia School For The Deaf And Blind Elementary',
  'Virginia School For The Deaf And Blind High',
  'Virginia School For The Deaf And Blind Middle',
  'Virginia Secondary',
  'Virginia Shuman Young Elementary School',
  'Virginia Smith Es',
  'Virginia State University',
  'Virginia Stevenson Elementary School',
  'Virginia Treatment Ctr.',
  'Virginia Vazquez Mendoza',
  'Virginia Wesleyan College',
  'Virginia Western Community College',
  'Virginia Williamson Elem',
  'Viridian El',
  'Viroqua Area Montessori School',
  'Viroqua Elementary',
  'Viroqua High',
  'Viroqua Middle',
  'Virtual Academy Of Lafourche',
  'Virtual Community School Of Ohio',
  'Virtual High School',
  'Virtual Learning Academy',
  'Virtual Learning Academy (e)',
  'Virtual Learning Academy (h)',
  'Virtual Learning Academy Consortium',
  'Virtual Learning Academyof St Clair County',
  'Virtual Pre',
  'Virtual School House',
  'Virtual University of Pakistan',
  'Virtue Arts And Science High School',
  'Virtue Arts And Science Middle School',
  'Virtus Academy Of South Carolina',
  'Visalia Charter Independent Study',
  'Visalia Technical Early College',
  'Visible Men Academy',
  'Visintainer Middle School',
  'Vision',
  'Vision Academy',
  'Vision Academy Charter School',
  'Vision Academy Of Excellence',
  'Vision Charter Academy',
  'Vision Charter School',
  'Vision Preparatory Charter School',
  'Vision Quest Alt Middle',
  'Visions (seamar Youth Center)',
  'Visions In Education',
  'Visions Unlimited',
  'Visions Unlimited Academy',
  'Visitacion Pagan',
  'Visitacion Valley Elementary',
  'Visitacion Valley Middle',
  'Vista (alternative)',
  'Vista Academy',
  'Vista Academy Of Austin-mueller',
  'Vista Academy Of Beaumont',
  'Vista Academy Of Crockett',
  'Vista Academy Of Dallas',
  'Vista Academy Of Edinburg',
  'Vista Academy Of Garland',
  'Vista Academy Of Humble',
  'Vista Academy Of Huntsville',
  'Vista Academy Of Jasper',
  'Vista Academy Of Pasadena',
  'Vista Academy Of The Woodlands',
  'Vista Academy Of Visual And Performing Arts',
  'Vista Academy Of Willis',
  'Vista Adult Transition Center',
  'Vista Alternative',
  'Vista Alternative School',
  'Vista At Entrada School Of Performing Arts And Technology',
  'Vista Charter Academy',
  'Vista Charter Middle',
  'Vista Charter School',
  'Vista College Prep - Maryvale',
  'Vista College Preparatory',
  'Vista Colorado Elementary',
  'Vista Continuation High',
  'Vista De Las Cruces',
  'Vista Del Futuro Charter School',
  'Vista Del Lago High',
  'Vista Del Mar',
  'Vista Del Mar Elementary',
  'Vista Del Mar Middle',
  'Vista Del Monte Elementary',
  'Vista Del Sol El',
  'Vista Del Sur Accelerated',
  'Vista Del Valle Dual Language Academy',
  'Vista Del Valle Elementary',
  'Vista Elementary',
  'Vista Elementary School',
  'Vista Grande Elementary',
  'Vista Grande High School',
  'Vista Grove Preparatory Academy Middle School',
  'Vista Heights Middle',
  'Vista Heritage Charter Middle',
  'Vista High',
  'Vista High (alternative)',
  'Vista High (continuation)',
  'Vista Hills El',
  'Vista Innovation And Design Academy',
  'Vista La Mesa Academy',
  'Vista Lakes Elementary',
  'Vista Magnet Middle School Of Technology Science And Math',
  'Vista Meadows Academy',
  'Vista Middle',
  'Vista Middle School',
  'Vista Murrieta High',
  'Vista Nueva Career And Technology High',
  'Vista Nueva High',
  'Vista Oaks Charter',
  'Vista Peak',
  'Vista Peak 9-12 Preparatory',
  'Vista Peak P-8 Exploratory',
  'Vista Preparatory Academy',
  'Vista Real Charter High',
  'Vista Ridge H S',
  'Vista Ridge High School',
  'Vista Ridge Middle',
  'Vista San Gabriel Elementary',
  'Vista School',
  'Vista Square Elementary',
  'Vista Verde',
  'Vista Verde Elementary',
  'Vista Verde Middle',
  'Vista Verde Middle School',
  'Vista View Elementary',
  'Vista View Middle',
  'Vista Visions Academy',
  'Vista West Continuation High',
  'Vistancia Elementary School',
  'Vistas H S',
  'Visual & Performing Art Magnet',
  'Visual And Performing Arts At Legacy High School Complex',
  'Visually Impaired Programs',
  'Visva-Bharati University',
  'Visvesvaraya National Institute of Technology',
  'Visvesvaraya Technological University',
  'Vital: Upper Cumberland E-learning Network',
  'Vitebsk State Academy of Veterinary Medicine',
  'Vitebsk State Medical University',
  'Vitebsk State Technological University',
  'Vitebsk State University',
  'Viterbo State University',
  'Vivian Adams Early Child Ctr',
  'Vivian Banks Charter',
  'Vivian Elementary School',
  'Vivian Fowler El',
  'Vivien T. Thomas Medical Arts Academy',
  'Vladimir State University',
  'Vladivostock State University of Economics',
  'Vlerick Leuven Gent Management School',
  'Vo. Tech. Tuition',
  'Voa - Adolescent Treatment - 73',
  'Voa High School',
  'Vocational/adult/community Education',
  'Vogel El',
  'Vogel Elementary School',
  'Vogel Int',
  'Vogel-wetmore School',
  'Vogt Elem.',
  'Voice Charter School Of New York',
  'Voices College-bound Language Academy',
  'Voices College-bound Language Academy At Morgan Hill',
  'Voices College-bound Language Academy At Mt. Pleasant',
  'Volcano School Of Arts & Sciences - A Community Pcs',
  'Volcano Vista High',
  'Volga State Academy of Water Transport',
  'Volga-century Elementary',
  'Volgograd Medical Academy',
  'Volgograd State Academy of Physical Education',
  'Volgograd State Pedagogical University',
  'Volgograd State Technical University',
  'Volgograd State University',
  'Volgograd State University of Architecture and Civil Engineering (VolgGASU)',
  'Volinia Outcome Base School',
  'Vollentine Elementary',
  'Volney Elementary School',
  'Volney Rogers School',
  'Vologda State Pedagogical University',
  'Volta Elem School',
  'Volta Elementary',
  'Voluntary Prekindergarten School',
  'Volunteer High School',
  'Volunteer State Community College',
  'Volunteers Of America Extended',
  'Voluntown Elementary School',
  'Volusia County Virtual Instruction Program',
  'Volusia Pines Elementary School',
  'Volusia Regional Juvenile Detention Center',
  'Volusia Virtual Instruction (course Offerings)',
  'Volusia Virtual Instruction Program District Provided',
  'Volyn National University Lesja Ukrainka',
  'Von E Mauger Middle School',
  'Von Renner Elementary',
  'Von Steuben Metro Science Hs',
  'Von Steuben Middle School',
  'Vongchavalitkul University',
  'Vonore Elementary',
  'Vonore Middle School',
  'Voorhees High School',
  'Voorhees Middle School',
  'Voorhees Rgc',
  'Voorheesville Elementary School',
  'Voorheesville Middle School',
  'Voorhies Elementary',
  'Voris Community Learning Center',
  'Voronezh State Academy of Technology',
  'Voronezh State Agricultural University',
  'Voronezh State Medical Academy',
  'Voronezh State Pedagogical University',
  'Voronezh State Technical University',
  'Voronezh State University',
  'Vose Elementary School',
  'Voss Farms El',
  'Vossbeck Elementary School',
  'Vowles School',
  'Voy Spears Jr. Elem.',
  'Voyage Academy',
  'Voyager - A Public Charter School',
  'Voyager Academy',
  'Voyager Elementary',
  'Voyager Elementary School',
  'Voyager Middle School',
  'Voyages Prep-south Queens',
  'Voyages Preparatory',
  'Voyageur Academy',
  'Voyageur College Prep',
  'Voyageurs Exp Chtr-credit Recovery',
  'Voyageurs Exp Chtr-extended Year',
  'Voyageurs Expeditionary Ms',
  'Voyageurs Expeditionary School',
  'Voznesenka School',
  'Vrije Universiteit Amsterdam',
  'Vrije Universiteit Brussel',
  'Vulcan Middle School',
  'Vulture Peak Middle School',
  'Vyatka State Pedagogical University',
  'Vysehrad El',
  'Vytautas Magnus University',
  'Växjö University',
  'W A Bess Elementary School',
  'W A Blair El',
  'W A Johnson Sch',
  'W A Kieberger El',
  'W A Lecroy Career Technical Center',
  'W A Martin El',
  'W A Olmsted Elementary School',
  'W A Pattillo Middle',
  'W A Porter El',
  'W A Todd Middle',
  'W A Wettel Elementary School',
  'W A White Elem',
  'W A Wright Elementary',
  'W A Young Elementary',
  'W Arthur Sewell Elementary School',
  'W B Beam Intermediate School',
  'W B Bizzell Academy',
  'W B Cooley & Acad Internationa',
  'W B Evans Magnet Sch',
  'W B Green J H',
  'W B Muncy Elementary School',
  'W C Friday Middle School',
  'W C Petty Elementary School',
  'W Chester Elementary School',
  'W D Richards Elementary School',
  'W E B Dubois Academic High School',
  'W E Chalmers El',
  'W E Cherry Elementary School',
  'W E Greiner Exploratory Arts Academy',
  'W E Hoover El',
  'W E Pete Ford Middle',
  'W E Striplin Elementary School',
  'W E Wilson Elementary',
  'W Edward Balmer',
  'W F Burns Middle School',
  'W F George Middle',
  'W F Killip Elementary School',
  'W F Morrison School',
  'W F Peavy Pri',
  'W F Prior Elementary School',
  'W F West High School',
  'W G Mallett School',
  'W G Pearson Elementary',
  'W G Rhea Elementary',
  'W H Adamson H S',
  'W H Bonner El',
  'W H Burnett El',
  'W H Council Traditional School',
  'W H Gaston Middle',
  'W H Heaton Middle School',
  'W H Knuckles',
  'W H Maxwell Career And Technical Education High School',
  'W H Robinson Elementary',
  'W J Carroll Intermediate School',
  'W J Murphy Elem School',
  'W J Turner El',
  'W J Zahnow Elem School',
  'W James Middle School',
  'W Jesse Gurganus Elementary',
  'W L Henry Elementary School',
  'W L Higgins El',
  'W L Kissam El',
  'W L Morse School',
  'W Las Vegas High',
  'W Las Vegas Middle',
  'W Lloyd Meador El',
  'W M Green El',
  'W M Irvin Elementary',
  'W M Jenkins Elementary',
  'W M Pearce Pri',
  'W Mount Houston Middle',
  'W O Cline Elementary School',
  'W O Inman Middle School',
  'W O Lance Elementary',
  'W P Grier Middle School',
  'W R (bill) Fort El',
  'W R Castle Memorial Elementary School',
  'W R Croman Primary Sch',
  'W R Hatfield El',
  'W R Mcneill Elementary School',
  'W R Odell Elementary',
  'W R Odell Primary',
  'W S Beaupre Elem School',
  'W S Neal Elementary School',
  'W S Neal High School',
  'W S Neal Middle School',
  'W S Permenter Middle',
  'W St. Paul Area Learning Cntr.',
  'W T Francisco El',
  'W T Griggs Elementary',
  'W T Moore Elementary School',
  'W T White H S',
  'W Tresper Clarke High School',
  'W V Swinburn El',
  'W V Whitmore Elementary School',
  'W Verne Mckinney Elementary School',
  'W W Bushman El',
  'W W Evans Memorial El Sch',
  'W W Jones Elementary School',
  'W W Samuell H S',
  'W W Walker Elem School',
  'W W Woodbury Elem School',
  'W WYMAN KING ACADEMY',
  'W Wayne Skill Center',
  'W-alt-is',
  'W. A. Kendrick Elementary',
  'W. A. Metcalfe Elementary School',
  'W. A. Perry Middle',
  'W. B. Goodwin Elementary',
  'W. B. Simpson Elementary School',
  'W. B. Sweeney School',
  'W. C. Pryor Middle School',
  'W. C. Sullivan Middle',
  'W. D. & Mary Baker Smith Career Center',
  'W. D. Hall Elementary',
  'W. D. Sugg Middle School',
  'W. Douglas Hartley Elementary',
  'W. E. Mitchell Middle',
  'W. E. Parker Elementary',
  'W. E. Sears Youth Ctr.',
  'W. F. Kaynor Technical High School',
  'W. G. Computer School Elem.',
  'W. G. Sanders Middle',
  'W. H. A . Alternative Program',
  'W. H. Rhodes Elementary School',
  'W. Herbert Chapman Elementary',
  'W. J. Bryan Elementary',
  'W. Melbourne Elementary School For Science',
  'W. R. Dutemple School',
  'W. R. James Sr. Elementary School',
  'W. R. Nelson Elementary',
  'W. R. Powell Elementary',
  'W. R. Thomas Middle School',
  'W. R. Tolar K-8 School',
  'W. Reily Brown Elementary School',
  'W. S. Stinson Elementary School',
  'W. Smith Jr. Elementary School',
  'W. T. Cheney Elementary School',
  'W. T. Henning Elementary School',
  'W. W. Irby Elementary School',
  'W. W. Keysor Elem.',
  'W. W. Lewis Middle School',
  'W. W. Stewart Elementary School',
  'W.a. Bass Alternative Learning Center',
  'W.a. Higgins Middle School Academy',
  'W.a. Mccreery El Sch',
  'W.c. Taylor Middle',
  'W.c.k. Walls Elementary School',
  'W.d. Osborne Elementary School',
  'W.e. Cundiff Elementary',
  'W.e.b. Dubois Academy',
  'W.e.b.dubois Public Charter',
  'W.g. Coleman Elementary',
  'W.g. Nunn Elementary',
  'W.g. Rice El Sch',
  'W.h.a. Elementary',
  'W.j. Keenan High',
  'W.l. Abney Elementary School',
  'W.m. Anderson Primary',
  'W.o. Hall Elementary School',
  'W.o.r.k. Program',
  'W.p. Foster Elementary School',
  'W.s. Hornsby Elementary School',
  'W.s. Hornsby Middle School',
  'W.s. Lafargue Elementary School',
  'W.t. Cooke Elementary',
  'W.t. Lewis Elementary School',
  'W.w. Gordon Elementary',
  'W.w. Moore Jr. Juvenile Detention Home',
  'W.w. Robinson Elementary',
  'WABASH SCHOOL',
  'WACHUSETT HILLS CHRISTIAN SCHOOL',
  'WACO MONTESSORI SCHOOL',
  'WADE CHRISTIAN ACADEMY',
  'WAGONER CHRISTIAN SCHOOL',
  'WAHLERT CATHOLIC HIGH SCHOOL',
  'WAKE CHRISTIAN ACADEMY',
  'WAKE-EDEN CHRISTIAN ACADEMY',
  'WAKEFIELD COUNTY DAY SCHOOL',
  'WAKEFIELD SCHOOL',
  'WAKULLA CHRISTIAN SCHOOL',
  'WALBRIDGE SCHOOL',
  'WALDEN CENTER & SCHOOL',
  'WALDEN COMMUNITY SCHOOL',
  'WALDEN SCHOOL',
  'WALDEN SCHOOL CORPORATION',
  'WALDORF BAPTIST KINDERGARTEN & PRESCHOOL',
  'WALDORF EARLY CHILDHOOD CENTER',
  'WALDORF HIGH SCHOOL OF MASSACHUSETTS BAY',
  'WALDORF SCHOOL AT MORAINE FARM',
  'WALDORF SCHOOL OF CAPE COD',
  'WALDORF SCHOOL OF LEXINGTON',
  'WALDORF SCHOOL OF LOUISVILLE',
  'WALDORF SCHOOL OF NEW ORLEANS',
  'WALDORF SCHOOL OF PITTSBURGH',
  'WALDORF SCHOOL OF PRINCETON',
  'WALDORF SCHOOL OF SANTA BARBARA',
  'WALDORF SCHOOL OF SARATOGA SPRINGS',
  'WALDORF SCHOOL OF THE PENINSULA',
  'WALDORF SCHOOL ON THE ROARING FORK',
  'WALDORF SCHOOL-PENINSULA',
  'WALDRON MERCY ACADEMY',
  'WALKER MEMORIAL ACADEMY',
  'WALLA WALLA VALLEY ACADEMY',
  'WALLACE CHILDCARE AND LEARNING CENTER',
  'WALLER CHRISTIAN ACADEMY',
  'WALNUT CORNER SCHOOL',
  'WALNUT CREEK CHRISTIAN ACADEMY',
  'WALNUT CREEK SCHOOL',
  'WALNUT FARM MONTESSORI SCHOOL',
  'WALNUT GROVE C/O FANNIE YODER',
  'WALNUT GROVE CHRISTIAN SCHOOL',
  'WALNUT HILL',
  'WALNUT HILL CHRISTIAN SCHOOL',
  'WALNUT HILL DAY SCHOOL',
  'WALNUT HILL SCHOOL FOR THE ARTS',
  'WALNUT HOLLOW AMISH SCHOOL',
  'WALNUT INTERNATIONAL MONTESSORI PRESCHOOL',
  'WALNUT LEVEL SCHOOL',
  'WALNUT MONTESSORI PRESCHOOL ACADEMY',
  'WALNUT PARK MONTESSORI',
  'WALNUT STREET CHRISTIAN SCHOOL',
  'WALNUT VALLEY',
  'WALSH JESUIT HIGH SCHOOL',
  'WALSINGHAM ACADEMY',
  'WALTER LAWSON CHILDRENS HOME',
  'WALTON ACADEMY',
  'WANCHESE CHRISTIAN ACADEMY',
  'WAOLANI JUDD NAZARENE SCHOOL',
  'WAR HILL CHRISTIAN ACADEMY',
  'WARE ACADEMY',
  'WARING SCHOOL',
  'WARM WORLD SCHOOL',
  'WARNER CHRISTIAN ACADEMY',
  'WARNER ROBINS CHRISTIAN ACADEMY',
  'WARNER ROBINS MONTESSORI ACADEMY',
  "WARNER'S HOLLOW SCHOOL",
  'WARREN COUNTY CHRISTIAN SCHOOL',
  'WARREN MONTESSORI',
  'WARREN SDA ELEMENTARY SCHOOL',
  'WARREN WOODS CHRISTIAN SCHOOL',
  'WARREN-WALKER SCHOOL',
  'WARREN-WALKER SCHOOL-LA MESA',
  'WARSAW CHRISTIAN SCHOOL',
  'WARWICK KIDS ACADEMY',
  'WARWICK RIVER CHRISTIAN SCHOOL',
  'WASATCH ACADEMY',
  'WASATCH CHRISTIAN SCHOOL',
  'WASECCA MONTESORI SCHOOL',
  'WASHINGTON ACADEMY',
  'WASHINGTON CATHOLIC ELEMENTARY',
  'WASHINGTON CATHOLIC SCHOOLS',
  'WASHINGTON CHRISTIAN ACADEMY',
  'WASHINGTON COUNTY CHRISTIAN SCHOOL',
  'WASHINGTON EPISCOPAL SCHOOL',
  'WASHINGTON INTERNATIONAL SCHOOL',
  'WASHINGTON JESUIT ACADEMY',
  'WASHINGTON MONTESSORI SCHOOL',
  'WASHINGTON NEW CHURCH SCHOOL',
  'WASHINGTON SCHOOL FOR GIRLS',
  'WASHINGTON UNITED CHRISTIAN ACADEMY',
  'WASHINGTON WALDORF SCHOOL',
  'WASILLA LAKE CHRISTIAN SCHOOL',
  'WATCH ME GROW LEARNING CENTER II INC',
  'WATCH ME GROW LEARNING CENTER III',
  'WATCH ME GROW LEARNING CENTER INC',
  'WATER OF LIFE LUTHERAN SCHOOL',
  'WATERFORD ADVENTIST SCHOOL',
  'WATERFORD COUNTRY SCHOOL',
  'WATERFRONT ACADEMY',
  'WATERFRONT MONTESSORI',
  'WATERLOO AMISH SCHOOL/EMANUEL STOLTZFUS, CHAIRMAN',
  'WATERLOO CHRISTIAN SCHOOL',
  'WATERSHED SCHOOL',
  'WATERSPRINGS SCHOOL',
  'WATERTOWN CHRISTIAN SCHOOL',
  'WATERVIEW LEARNING ACADEMY',
  'WATERVILLE VALLEY ACADEMY',
  'WATKINSON SCHOOL',
  'WATSON HALL MONTESSORI SCHOOL',
  'WATSON INSTITUT SOCIAL CTR FOR ACADEMIC ACHIEVEMNT',
  'WATSON INSTITUTE FRIENDSHIP ACADEMY THE',
  'WATTERS MONTESSORI ACADEMY',
  'WAUCOUSTA LUTHERAN SCHOOL',
  'WAUKESHA CATHOLIC',
  'WAUKESHA CATHOLIC SCHOOL',
  'WAUWATOSA CATHOLIC SCHOOL',
  'WAVECREST ACADEMY',
  'WAVERLY HALL CHRISTIAN ACADEMY',
  'WAVES OF WONDER MONTESSORI SCHOOL',
  'WAWARSING CHRISTIAN ACADEMY',
  'WAXAHACHIE PREPARATORY ACADEMY',
  'WAYFINDER SCHOOLS',
  'WAYLAND ACADEMY',
  'WAYNE ACADEMY',
  'WAYNE CHRISTIAN SCHOOL',
  'WAYNE COUNTRY DAY SCHOOL',
  'WAYNE MONTESSORI SCHOOL',
  'WAYNE MULT-PURPOSE JUVENILE SCHOOL',
  'WAYNESBURG SCHOOL',
  'WAYNFLETE SCHOOL',
  'WAYPOINT ACADEMY',
  'WAYPOINT MONTESSORI',
  'WAYSIDE ACADEMY',
  'WAYSIDE CHRISTIAN SCHOOL',
  'WE R FAMILY CHRISTIAN SCHOOL',
  'WEATHERFORD ACADEMY',
  'WEATHERFORD CHRISTIAN SCHOOL',
  'WEAVER CHILD DEVELOPMENT CENTER- INC',
  'WEAVER DAY SCHOOL',
  'WEAVERS',
  'WEAVERTOWN MENNONITE SCHOOL',
  'WEB OF WISDOM',
  'WEBB RIVER SDA SCHOOL',
  'WEBB SCHOOL OF KNOXVILLE',
  'WEBER ROAD SCHOOL',
  'WEBER SCHOOL',
  'WEBSTER CO PAROCHIAL #1',
  'WEBSTER CO PAROCHIAL #10',
  'WEBSTER CO PAROCHIAL #2',
  'WEBSTER CO PAROCHIAL #3',
  'WEBSTER CO PAROCHIAL #4',
  'WEBSTER CO PAROCHIAL #5',
  'WEBSTER CO PAROCHIAL #6',
  'WEBSTER CO PAROCHIAL #7',
  'WEBSTER CO PAROCHIAL #8',
  'WEBSTER CO PAROCHIAL #9',
  'WEBSTER CO. PAROCHIAL #1',
  'WEBSTER COUNTY AMISH-FORDLAND',
  'WEBSTER MONTESSORI SCHOOL',
  'WEDDINGTON CHRISTIAN ACADEMY',
  'WEDGEFIELD UNIVERSITY FOR KIDS',
  'WEDIKO SCHOOL',
  'WEE CARE CHILD DEVELOPMENT CENTER',
  'WEE CARE DAY SCHOOL',
  'WEE CARE NURSERY SCHOOL',
  'WEE DISCIPLES CHRISTIAN ACADEMY',
  'WEE DISCIPLES LUTHERAN SCHOOL AND CHILDCARE',
  'WEE PLAY SCHOOL',
  'WEE WISDOM',
  'WEEKDAY EARLY EDUCATION',
  'WEEKDAY KIDS AT FIRST',
  'WEEMS CREEK NURSERY SCHOOL & KINDERGARTEN',
  'WEGNER SCHOOL',
  'WEIL TENNIS ACADEMY AND COLLEGE PREPARATORY SCHOOL',
  'WEIRTON MADONNA HIGH SCHOOL',
  'WEIZMANN DAY SCHOOL',
  'WELDY AMISH SCHOOL',
  'WELLINGTON CHRISTIAN ACADEMY',
  'WELLINGTON INSTITUTE OF LEARNING DEVELOPM',
  'WELLINGTON PREPARATORY SCHOOL',
  'WELLMONT ACADEMY',
  'WELLS STREET ACADEMY',
  'WELLSPRING EDUCATIONAL SERVICES INC',
  'WELLSPRING ELEMENTARY',
  'WELLSPRINGS FRIENDS SCHOOL',
  'WELSH HILLS SCHOOL',
  'WEN JIAN YING',
  'WESCARE CHRISTIAN ACADEMY',
  'WESLEY ACADEMY',
  'WESLEY CHRISTIAN ACADEMY',
  'WESLEY CHRISTIAN SCHOOL',
  'WESLEY PREP',
  'WESLEY SPECTRUM HIGH SCHOOL',
  'WESLEY SPECTURM SCHOOL',
  'WESLEYAN ACADEME',
  'WESLEYAN CHRISTIAN ACADEMY',
  'WESLEYAN CHRISTIAN SCHOOL',
  'WESLEYAN METHODIST ACADEMY',
  'WESLEYAN PRESCHOOL & KINDERGARTEN',
  'WESLEYAN SCHOOL',
  'WEST ALABAMA CHRISTIAN SCHOOL',
  'WEST AMISH SCHOOL',
  'WEST ANDERSON CHRISTIAN ACADEMY',
  'WEST BAY CHRISTIAN ACADEMY (ELEMENTARY)',
  'WEST BEAVER SCHOOL',
  'WEST BEIDLER',
  'WEST BOGARD SCHOOL',
  'WEST BRANCH CHRISTIAN ACADEMY',
  'WEST BRANCH PAROCHIAL SCHOOL',
  'WEST BRANCH SCHOOL',
  'WEST BRANDYWINE SCHOOL',
  'WEST CATHOLIC HIGH SCHOOL',
  'WEST CATHOLIC PREPARATORY H.S.',
  'WEST CENTER AMISH SCHOOL',
  'WEST CHARLESTON ENRICHMENT ACADEMY',
  'WEST CHESTER FRIENDS SCHOOL',
  'WEST CHRISTIAN ACADEMY',
  'WEST COAST BAPTIST SCHOOL',
  'WEST COAST CHRISTIAN ACADEMY',
  'WEST COAST CHRISTIAN SCHOOL',
  'WEST COCALICO MENNONITE SCHOOL',
  'WEST COVINA CHRISTIAN SCHOOL',
  'WEST COVINA HILLS ADVENTIST SCHOOL',
  'WEST CREEK SCHOOL',
  'WEST DADE ACADEMY',
  'WEST DALLAS COMMUNITY SCHOOL',
  'WEST DUPLIN CHRISTIAN ACADEMY',
  'WEST EDGE SCHOOL',
  'WEST END ACADEMY',
  'WEST END CHRISTIAN SCHOOL',
  'WEST END DAY SCHOOL',
  'WEST END MONTESSORI SCHOOL',
  'WEST FAIRVIEW SCHOOL',
  'WEST FALLOWFIELD CHRISTIAN SCHOOL',
  'WEST FLORIDA BAPTIST ACADEMY',
  'WEST GATE CHRISTIAN SCHOOL',
  'WEST GHENT SCHOOL',
  'WEST GLADES MONTESSORI SCHOOL',
  'WEST HASTINGS',
  'WEST HERNANDO CHRISTIAN SCHOOL',
  'WEST HIGHLAND CHRISTIAN ACADEMY',
  'WEST HILLS BAPTIST PRESCHOOL & KINDERGARTEN',
  'WEST HILLS CHRISTIAN SCHOOL',
  'WEST HILLS LEARNING CENTER',
  'WEST HILLS MONTESSORI SCHOOL',
  'WEST HILLS MONTESSORI SCHOOL-LAKE OSWEGO',
  'WEST HILLS MONTESSORI SCHOOL-VERMONT',
  'WEST HOLLYWOOD COLLEGE PREPARATORY SCHOOL',
  'WEST LUTHERAN HIGH SCHOOL',
  'WEST MAYSVILLE',
  'WEST MEADOWS BAPTIST ACADEMY',
  'WEST MELBOURNE CHRISTIAN ACADEMY',
  'WEST MEMPHIS CHRISTIAN',
  'WEST MICHIGAN LUTHERAN SCHOOL',
  'WEST MOBILE BAPTIST CHURCH',
  'WEST MONTESSORI SCHOOL OF COPPERFIELD',
  'WEST NOTTINGHAM ACADEMY',
  'WEST OAKS ACADEMY',
  'WEST ORANGE MONTESSORI SCHOOL',
  'WEST PALM BEACH JR ACADEMY',
  'WEST PARK HIGH SCHOOL',
  'WEST PARK LUTHERAN SCHOOL',
  'WEST PERU AMISH SCHOOL',
  'WEST POINT CHILD LEARNING CENTER',
  'WEST POINT CHRISTIAN ACADEMY',
  'WEST PORTAL LUTHERAN SCHOOL',
  'WEST ROAD SCHOOL',
  'WEST SALEM CHRISTIAN EDUCATORS',
  'WEST SAYVILLE CHRISTIAN SCHOOL',
  'WEST SENECA CHRISTIAN SCHOOL',
  'WEST SHORE CHRISTIAN ACADEMY',
  'WEST SIDE CHRISTIAN ACADEMY',
  'WEST SIDE CHRISTIAN SCHOOL',
  'WEST SIDE MONTESSORI',
  'WEST SIDE MONTESSORI SCHOOL',
  'WEST SOUND ACADEMY',
  'WEST SUBURBAN MONTESSORI SCHOOL',
  'WEST UNION CENTER SCHOOL',
  'WEST VALLEY CHRISTIAN ACADEMY',
  'WEST VALLEY CHRISTIAN SCHOOL',
  'WEST VALLEY MIDDLE SCHOOL',
  'WEST WABASH SCHOOL',
  'WEST WALDRON SCHOOL',
  'WEST WOOD MENNONITE SCHOOL',
  'WEST-MONT CHRISTIAN ACADEMY',
  'WESTBROOK CHRISTIAN SCHOOL',
  'WESTBURY CHRISTIAN SCHOOL',
  'WESTBURY FRIENDS SCHOOL',
  'WESTCHESTER ACADEMY AND LEARNING CENTER',
  'WESTCHESTER AREA SCHOOL',
  'WESTCHESTER CHRISTIAN SCHOOL',
  'WESTCHESTER COUNTRY DAY SCHOOL',
  'WESTCHESTER EXCEPTIONAL CHILDRENS SCHOOL',
  'WESTCHESTER LUTHERAN ELEMENTARY SCHOOL',
  'WESTCHESTER MUSLIM CENTER SCHOOL',
  'WESTCHESTER SCHOOL FOR SPECIAL CHILDREN',
  'WESTCHESTER TORAH ACADEMY',
  'WESTCOAST SCHOOL FOR HUMAN DEVELOPMENT',
  'WESTERLY SCHOOL OF LONG BEACH',
  'WESTERN ACADEMY',
  'WESTERN CHRISTIAN HIGH SCHOOL',
  'WESTERN CHRISTIAN SCHOOL',
  'WESTERN EDUCATION CENTER',
  'WESTERN GENERAL EDUCATION',
  'WESTERN HEIGHTS CHRISTIAN SCHOOL',
  'WESTERN HILLS ACADEMY',
  'WESTERN MENNONITE SCHOOL',
  'WESTERN PA MONTESSORI SCHOOL',
  'WESTERN PENNSYLVANIA SCHOOL FOR BLIND CHILDREN',
  'WESTERN PENNSYLVANIA SCHOOL FOR THE DEAF',
  'WESTERN RESERVE ACADEMY',
  'WESTFAIR CHRISTIAN ACADEMY',
  'WESTFIELD AMISH PAROCHIAL SCHOOL',
  'WESTFIELD AREA Y EARLY LEARNING CENTER',
  'WESTFIELD AREA Y KINDERGARTEN',
  'WESTFIELD CHRISTIAN ACADEMY',
  'WESTFIELD DAYCARE CENTER',
  'WESTFIELD Y CHILDRENS CENTER',
  'WESTGATE CHRISTIAN SCHOOL',
  'WESTHAM AMISH PAROCHIAL SCHOOL',
  'WESTLAKE CHRISTIAN ACADEMY',
  'WESTLAKE CHRISTIAN SCHOOL',
  'WESTLAKE MONTESSORI SCHOOL',
  'WESTLAKE MONTESSORI SCHOOL & CHILDCARE CENTER',
  'WESTLAKE PREPARATORY LUTHERAN ACADEMY',
  'WESTLAKE PREPARATORY SCHOOL',
  'WESTLAKE SEVENTH-DAY ADVENTIST SCHOOL',
  'WESTLAND SCHOOL',
  'WESTMARK SCHOOL',
  'WESTMINSTER ACADEMY',
  'WESTMINSTER CATAWBA CHRISTIAN SCHOOL',
  'WESTMINSTER CHRISTIAN ACAD - LOWER SCHOOL CAMPUS',
  'WESTMINSTER CHRISTIAN ACADEMY',
  'WESTMINSTER CHRISTIAN SCHOOL',
  'WESTMINSTER DAY SCHOOL',
  'WESTMINSTER PRESBYTERIAN PRESCHOOL',
  'WESTMINSTER PRESBYTERIAN PRESCHOOL & KINDERGARTEN',
  'WESTMINSTER SCHOOL',
  'WESTMINSTER SCHOOL AT OAK MOUNTAIN',
  'WESTMINSTER SCHOOLS OF AUGUSTA',
  'WESTMINSTER WEEKDAY SCHOOL',
  'WESTMONT MONTESSORI SCHOOL',
  'WESTMORELAND CHRISTIAN ACADEMY',
  'WESTMORLAND BASIC CHRISTIAN SCHOOL',
  'WESTOVER AMISH PAROCHIAL SCHOOL',
  'WESTOVER CHRISTIAN ACADEMY',
  'WESTOVER SCHOOL',
  'WESTPARK MONTESSORI SCHOOL OF IRVINE',
  'WESTRIDGE AMISH SCHOOL',
  'WESTRIDGE SCHOOL FOR GIRLS',
  'WESTSHORE MONTESSORI SCHOOL',
  'WESTSIDE CATHOLIC SCHOOL',
  'WESTSIDE CATHOLIC SCHOOL/ST BONIFACE CAMPUS',
  'WESTSIDE CHRISTIAN ACADEMY',
  'WESTSIDE CHRISTIAN HIGH SCHOOL',
  'WESTSIDE CHRISTIAN SCHOOL',
  'WESTSIDE MONTESSORI SCHOOL',
  'WESTSIDE NEIGHBORHOOD SCHOOL',
  'WESTSIDE SCHOOL',
  'WESTSIDE WALDORF SCHOOL',
  'WESTTOWN SCHOOL',
  'WESTVIEW CHRISTIAN SCHOOL (AMISH)',
  'WESTVIEW SCHOOL',
  'WESTWOOD BAPTIST ACADEMY',
  'WESTWOOD BAPTIST WEEKDAY EDU MINISTRY',
  'WESTWOOD CHRISTIAN ACADEMY',
  'WESTWOOD CHRISTIAN SCHOOL',
  'WESTWOOD DAY SCHOOL',
  'WESTWOOD EARLY CHILDHOOD CENTER',
  'WESTWOOD SCHOOL',
  'WESTWOOD SCHOOLS',
  'WETHERILL SCHOOL',
  'WHATCOM HILLS WALDORF SCHOOL',
  'WHEATLAND SALEM CHRISTIAN ACADEMY',
  'WHEATON ACADEMY',
  'WHEATON CHRISTIAN GRAMMAR SCHOOL',
  'WHEATON MONTESSORI SCHOOL',
  'WHEELER SCHOOL',
  'WHEELING COUNTRY DAY SCHOOL',
  'WHERRY ACADEMY',
  'WHETSTONE ACADEMY LLC',
  'WHETSTONE CHRISTIAN ACADEMY',
  'WHIDBEY ISLAND WALDORF SCHOOL',
  'WHIPPORWILL SCHOOL',
  'WHISPERING BROOK',
  'WHISPERING FARMS MONTESSORI ACADEMY',
  'WHISPERING PINES',
  'WHISPERING PINES CHRISTIAN DAY SCHOOL',
  'WHISPERING PINES SCHOOL',
  'WHISPERING WILLOW',
  'WHISPERING WIND SCHOOL',
  'WHISTILING PINES AMISH PAROCHIAL SCHOOL',
  'WHITBY SCHOOL',
  'WHITE CLAY IMMERSION SCHOOL',
  'WHITE COUNTY MENNONITE CHRISTIAN DAY SCHOOL',
  'WHITE CREEK CHRISTIAN ACADEMY',
  'WHITE CREEK LUTHERAN SCHOOL',
  'WHITE CREEK SCHOOL',
  'WHITE DEER SCHOOL',
  'WHITE DOVE MONTESSORI SCHOOL',
  'WHITE EAGLE CHRISTIAN ACADEMY',
  'WHITE EYES SCHOOL',
  'WHITE HALL SCHOOL',
  'WHITE MEMORIAL ADVENTIST SCHOOL',
  'WHITE MOUNTAIN MONTESSORI SCHOOL',
  'WHITE MOUNTAIN SCHOOL',
  'WHITE MOUNTAIN WALDORF SCHOOL',
  'WHITE OAK BAPTIST CHURCH KINDERGARTEN',
  'WHITE OAK RIDGE',
  'WHITE OAK SCHOOL',
  'WHITE PINE MONTESSORI',
  "WHITE PINE MONTESSORI CHILDREN'S CENTER",
  'WHITE PLAINS CHRISTIAN SCHOOL',
  'WHITE RIVER ACADEMY',
  'WHITE ROCK MONTESSORI',
  'WHITE ROCK NORTH SCHOOL',
  'WHITE SPRINGS SCHOOL',
  'WHITEFIELD ACADEMY',
  'WHITEFISH CHRISTIAN ACADEMY',
  'WHITELAND MONTESSORI SCHOOL',
  'WHITESBURG CHRISTIAN ACADEMY',
  'WHITESTONE ACADEMY',
  'WHITESTONE FARMS TRAINING CENTER / WHITESTONE HS',
  'WHITINSVILLE CHRISTIAN SCHOOL',
  'WHITNEY ACADEMY',
  'WHITNEY HALL SCHOOL',
  'WHITTIER CHRISTIAN HIGH SCHOOL',
  'WHITTIER FRIENDS SCHOOL',
  'WHITTIER MONTERSSORI SCHOOL',
  'WHNCHESTER THURSTON SCHOOL - NORTH HILLS',
  'WHOLE CHILD MONTESSORI CENTER',
  'WHOLE LIFE CHRISTIAN ACADEMY',
  'WICHITA ADVENTIST CHRISTIAN ACADEMY',
  'WICHITA CHRISTIAN SCHOOL',
  'WICHITA COLLEGIATE SCHOOL',
  'WICHITA FRIENDS SCHOOL',
  'WICKENBURG CHRISTIAN ACADEMY',
  'WICOMICO DAY SCHOOL',
  'WIDENER UNIVERSITY CHILD DEVELOPMENT CENTER',
  'WIDER HORIZONS SCHOOL',
  'WILBERFORCE SCHOOL',
  'WILBERT F MAYS SEVENTH-DAY ADVENTIST SCHOOL',
  'WILBRAHAM & MONSON ACADEMY',
  'WILCOX ACADEMY',
  'WILDCAT CHRISTIAN ACADEMY',
  'WILDWOOD CATHOLIC HIGH SCHOOL',
  'WILDWOOD CHRISTIAN ACADEMY',
  'WILDWOOD SCHOOL',
  'WILDWOOD SCHOOL K-12',
  'WILEYS SCHOOL',
  'WILKES-BARRE ACADEMY',
  'WILKES-BARRE MENNONITE SCHOOL',
  'WILKINSON SCHOOL',
  'WILLAMETTE CHRISTIAN SCHOOL',
  'WILLAMETTE VALLEY CHRISTIAN SCHOOL',
  'WILLIAM & REED ACADEMY',
  'WILLIAM A KIRLEW JR ACADEMY',
  'WILLIAM BRADFORD CHRISTIAN SCHOOL',
  'WILLIAM SPYROPOULOS GREEK-AMERICAN DAY SCHOOL',
  'WILLIAM T DILLON CHILD STUDY CENTER',
  'WILLIAM V FISHER CATHOLIC HIGH SCHOOL',
  'WILLIAMETTE VALLEY BAPTIST SCHOOL',
  'WILLIAMS & BROWN ACADEMY',
  'WILLIAMS COMMUNITY SCHOOL',
  'WILLIAMSBURG ACADEMY',
  'WILLIAMSBURG CHRISTIAN ACADEMY',
  'WILLIAMSBURG KINDERGARTEN DAYCARE',
  'WILLIAMSBURG MONTESSORI SCHOOL',
  'WILLIAMSBURG NORTHSIDE SCHOOL',
  'WILLIAMSON LEARNING CENTER',
  'WILLIE ROSS SCHOOL FOR THE DEAF',
  'WILLISTON CENTRAL CHRISTIAN ACADEMY',
  'WILLISTON TRINITY CHRISTIAN SCHOOL',
  'WILLO-HILL CHRISTIAN SCHOOL',
  'WILLOUGHBY MONTESSORI DAY SCHOOL',
  'WILLOW BROOK SCHOOL',
  'WILLOW CREEK',
  'WILLOW CREEK MONTESSORI',
  'WILLOW CREEK SCHOOL',
  'WILLOW DRIVE SCHOOL',
  'WILLOW HILL SCHOOL',
  'WILLOW OAK MONTESSORI CHILDRENS HOUSE',
  'WILLOW SCHOOL OF WINTER SPRINGS',
  'WILLOW SPRING SCHOOL',
  'WILLOW VIEW',
  'WILLOWBROOK WEEKDAY EARLY EDUCATION PROGRAM',
  'WILLOWGLEN ACADEMY',
  'WILLOWS CHILD LEARNING CENTER',
  'WILLOWS PREPARATORY SCHOOL',
  'WILLOWWIND SCHOOL',
  'WILMINGTON ACADEMY OF ARTS & SCIENCES',
  'WILMINGTON CHRISTIAN ACADEMY',
  'WILMINGTON CHRISTIAN SCHOOL, INC.',
  'WILMINGTON FRIENDS SCHOOL',
  'WILMINGTON JUNIOR ACADEMY',
  'WILMINGTON MONTESSORI SCHOOL',
  'WILMINGTON SDA SCHOOL',
  'WILSHIRE PRIVATE SCHOOL',
  'WILSON CHRISTIAN ACADEMY',
  'WILSON HALL',
  'WILSON MONTESSORI ACADEMY',
  'WILSON SEVENTH-DAY ADVENTIST JUNIOR ACADEMY',
  'WILTON BAPTIST ACADEMY',
  'WIMBERLEY MONTESSORI',
  'WIMBISH ADVENTIST SCHOOL',
  'WIMPFHEIMER NURSERY SCHOOL',
  'WINCHESTER AMISH SCHOOL',
  'WINCHESTER CHRISTIAN ACADEMY',
  'WINCHESTER MONTESSORI SCHOOL',
  'WINCHESTER THURSTON SCHOOL',
  'WINDEMERE PREPARATORY SCHOOL',
  'WINDHAM CDT',
  'WINDHAM CHRISTIAN ACADEMY',
  'WINDHAM LEARNING TREE ACADEMY',
  'WINDMILL DAY SCHOOL',
  'WINDMILL MONTESSORI SCHOOL',
  'WINDSOR ACADEMY',
  'WINDSOR AMISH SCHOOLS',
  'WINDSOR CHRISTIAN ACADEMY',
  'WINDSOR LEARNING ACADEMY',
  'WINDSOR MILLS SCHOOL',
  'WINDSOR MONTESSORI SCHOOL',
  'WINDSOR SCHOOL',
  'WINDSOR STREET MONTESSORI SCHL',
  'WINDSWEPT ACADEMY',
  'WINDWARD NAZARENE ACADEMY',
  'WINDWARD SCHOOL',
  'WINDY ACRES SCHOOL',
  'WINDY HILL SCHOOL',
  'WINDY HOLLOW AMISH SCHOOL',
  'WINDY KNOLL AMISH SCHOOL',
  'WINDY KNOLL SCHOOL',
  'WINDY POPLARS SCHOOL',
  'WINDY RIDGE SCHOOL',
  'WINDY VALLEY',
  'WINDY VALLEY SCHOOL',
  'WINEWOOD CHRISTIAN SCHOOL',
  'WINFIELD CHRISTIAN ACADEMY',
  'WINFIELD HEIGHTS CHRISTIAN KINDERGARTEN',
  'WINGRA SCHOOL',
  'WINGS LEARNING CENTER',
  'WINGS OF EAGLES CHRISTIAN ACADEMY',
  'WINGS OF FAITH ACADEMY',
  'WINNEBAGO LUTHERAN ACADEMY',
  'WINONA AREA CATHOLIC SCHOOLS',
  'WINONA CHRISTIAN SCHOOL',
  'WINSOR SCHOOL',
  'WINSTON ACADEMY',
  'WINSTON HALL',
  'WINSTON PREPARATORY SCHOOL',
  'WINSTON SCHOOL',
  'WINSTON-SALEM STREET SCHOOL',
  'WINTER HAVEN ADVENTIST ACADEMY',
  'WINTER HAVEN CHRISTIAN SCHOOL',
  'WINTER HAVEN MONTESSORI',
  'WINTER PARK CHRISTIAN SCHOOL',
  'WINTERGARTEN CHRISTIAN SCHOOL',
  'WINTERSIDE SCHOOL',
  'WINTON PLACE BAPTIST ACADEMY',
  'WINWOOD CHILDRENS CENTER',
  'WIREGRASS CHRISTIAN ACADEMY',
  'WISCASSET CHRISTIAN ACADEMY',
  'WISCONSIN ACADEMY',
  'WISCONSIN INSTITUTE FOR TORAH STUDY',
  'WISCONSIN LUTHERAN HIGH SCHOOL',
  'WISCONSIN LUTHERAN SCHOOL',
  'WISDOM MISSION SCHOOL',
  'WISE COUNTY CHRISTIAN SCHOOL',
  'WISE INDEPENDENT PRIVATE SCHOOL SYSTEM',
  'WISE SCHOOL',
  'WISHING WELL SCHOOL',
  'WITMER AMISH SCHOOL',
  'WIXOM CHRISTIAN SCHOOL',
  'WIZ SCHOOLS',
  'WOLF CREEK',
  'WOLF CREEK ACADEMY',
  'WONDER CLUB EARLY LEARNING CTR S LANCASTER CITY',
  'WONDER MONTESSORI SCHOOL',
  'WONDER WORLD LEARNING CENTER',
  'WONDER YEARS LEARNING CENTER-DERRY',
  'WONDER YEARS MONTESSORI',
  'WONDERLAND ANGELS EARLY LEARNING CENTER',
  'WONDERLAND MONTESSORI ACADEMY',
  'WONDERLAND PRESCHOOL',
  'WONDERLAND SCHOOL',
  'WOOD CREEK ACADEMY- LLC',
  'WOOD LAND',
  'WOOD LAWN SCHOOL',
  'WOOD ROSE ACADEMY',
  'WOODBERRY FOREST SCHOOL',
  'WOODBINE ACADEMY',
  'WOODBOURNE SCHOOL AT CONRAD',
  'WOODBURN HILL AMISH PAROCHIAL SCHOOL',
  'WOODBURN LUTHERAN SCHOOL',
  'WOODBURY MENNONITE SCHOOL',
  'WOODCHUCK KNOLL',
  'WOODCREST BAPTIST ACADEMY',
  'WOODCREST CHRISTIAN SCHOOL SYSTEM',
  'WOODCREST NAZARENE CHRISTIAN SCHOOL',
  'WOODCREST PRESCHOOL',
  'WOODCREST SCHOOL',
  'WOODFIELD ACADEMY',
  'WOODFORD CHRISTIAN SCHOOL',
  'WOODINVILLE MONTESSORI SCHOOL',
  'WOODLAND',
  'WOODLAND BAPTIST CHRISTIAN SCHOOL',
  'WOODLAND CHRISTIAN SCHOOL',
  'WOODLAND COMMUNITY SCHOOL',
  'WOODLAND COUNTRY DAY SCHOOL',
  'WOODLAND EARLY CHILDHOOD /DUNN PREPARATORY SCHOOL',
  'WOODLAND HALL ACADEMY',
  'WOODLAND HILL MONTESSORI SCHOOL',
  'WOODLAND HILLS PRIVATE SCHOOL - OXNARD',
  'WOODLAND MONTESSORI',
  'WOODLAND MONTESSORI CHILDRENS HOUSE',
  'WOODLAND MONTESSORI SCHOOL',
  'WOODLAND PRESBYTERIAN SCHOOL',
  'WOODLAND SCHOOL',
  'WOODLAND VIEW SCHOOL',
  'WOODLANDS CHRISTIAN ACADEMY',
  'WOODLAWN CATHOLIC REGIONAL',
  'WOODLAWN CHRISTIAN SCHOOL',
  'WOODLAWN COMMUNITY ACADEMY',
  'WOODLAWN PREPARATORY SCHOOL',
  'WOODLAWN SCHOOL',
  'WOODLYNDE SCHOOL',
  'WOODPARK MONTESSORI',
  'WOODS FORK',
  'WOODS FORK AMISH SCHOOL',
  'WOODS RIDGE SCHOOL',
  'WOODS SERVICES',
  'WOODSIDE',
  'WOODSIDE INTERNATIONAL SCHOOL',
  'WOODSIDE MONTESSORI ACADEMY',
  'WOODSIDE PRIORY SCHOOL',
  'WOODSIDE SCHOOL',
  'WOODSTOCK DAY SCHOOL',
  'WOODSTREAM CHRISTIAN ACADEMY',
  'WOODVIEW TERRACE MONTESSORI',
  'WOODWARD ACADEMY',
  'WOODWARD CHRISTIAN ACADEMY',
  'WOODWARD MENTAL HEALTH CENTER',
  'WOODWAY CHRISTIAN SCHOOL',
  'WOOSTER CHRISTIAN SCHOOL',
  'WOOSTER SCHOOL',
  'WORCESTER ACADEMY',
  'WORCESTER PREPARATORY SCHOOL',
  'WORCESTER SEVENTH-DAY ADVENTIST SCHOOL',
  'WORD OF FAITH CHRISTIAN SCHOOL',
  'WORD OF GOD ACADEMY',
  'WORD OF GOD CHRISTIAN ACADEMY',
  'WORD OF LIFE CHRISTIAN ACADEMY',
  'WORD OF LIFE CHRISTIAN SCHOOL',
  'WORD OF LIFE INSTITUTE CHRISTIAN SCHOOL',
  'WORD OF LIFE LUTHERAN SCHOOL',
  'WORDSWORTH ACADEMY',
  'WORLD CHANGERS CHRISTIAN ACADEMY',
  'WORLD CHANGERS CHRISTIAN ACADEMY INC.',
  'WORLD CHANGERS SCHOOLS OF THE ARTS INC',
  "WORLD CHILDREN'S ACADEMY",
  'WORLD CLASS ACADEMY',
  'WORLD CUP NURSERY SCHOOL & KINDERGARTEN',
  'WORLD HARVEST CHRISTIAN SCHOOL',
  'WORLD HARVEST DELIVERANCE CTR CHRISTIAN ACADEMY',
  'WORLD HOPE ACADEMY',
  'WORLD MISSION OF JESUS CHRIST CHRISTIAN ACADEMY',
  'WORLD OF DISCOVERY',
  'WORLD OF KIDS ACADEMY',
  'WORLD OF KNOWLEDGE: A MONTESSORI SCHOOL',
  "WORSHIPER'S HOUSE OF PRAYER ACADEMY",
  'WORTHINGTON ADVENTIST ACADEMY',
  'WORTHINGTON CHRISTIAN HIGH SCHOOL',
  'WORTHINGTON CHRISTIAN SCHOOL',
  'WORTHMORE ACADEMY',
  'WOVENLIFE CHILD DEVELOPMENT PROGRAM',
  'WRIGHT CHRISTIAN ACADEMY',
  'WRIGHT WAY TO HIGHER LEARNING',
  'WYCKOFF CHRISTIAN PRESCHOOL & KINDERGARTEN',
  'WYE RIVER UPPER SCHOOL',
  'WYLDEWOOD CHRISTIAN SCHOOL',
  'WYNCOTE ACADEMY',
  'WYNDCROFT SCHOOL',
  'WYNEKEN MEMORIAL LUTHERAN SCHOOL',
  'WYNFIELD CHRISTIAN ACADEMY',
  'WYNNBROOK CHRISTIAN SCHOOL',
  'WYOMING BEHAVIORAL INSTITUTE',
  'WYOMING VALLEY MONTESSORI SCHOOL',
  'WYOMING VALLEY SDA ELEMENTARY',
  'Wa He Lut Indian School',
  'Wa Threadgill Primary School',
  "Waave (w Hartford Applied Academic/voc'l Experience)",
  'Wabash College',
  'Wabash Elementary',
  'Wabash High School',
  'Wabash Middle School',
  'Wabash/miami Area Prog Excpt',
  'Wabasha-kellogg Elementary',
  'Wabasha-kellogg Secondary',
  'Wabasso Elementary',
  'Wabasso School',
  'Wabasso Secondary',
  'Wabaunsee Junior High',
  'Wabaunsee Sr High',
  'Wabeno Elementary',
  'Wabeno High',
  'Waccamaw',
  'Waccamaw Elementary',
  'Waccamaw High',
  'Waccamaw Intermediate',
  'Waccamaw Middle',
  'Wachter Middle School',
  'Wachusett Regional High',
  'Wacker Elem School',
  'Waco Charter School',
  'Waco Ctr For Youth',
  'Waco Elementary School',
  'Waco H S',
  'Waco High School',
  'Wacona Elementary School',
  'Waconia High School',
  'Waconia Learning Center',
  'Waconia Learning Center Ss/as',
  'Waconia Middle School',
  'Wacoochee Elementary School',
  'Wacousta Elementary School',
  'Waddell Elementary School',
  'Waddell Language Academy',
  'Waddell School',
  'Waddington School',
  'Wade Carpenter Middle School',
  'Wade Hampton High',
  'Wade King Elementary School',
  'Wade Park',
  'Wade Thomas Elementary',
  'Wadena-deer Creek 5th And 6th Grade',
  'Wadena-deer Creek Elementary',
  'Wadena-deer Creek Senior High',
  'Wadesboro Elementary',
  'Wadesboro Primary',
  'Wadewitz Elementary',
  'Wadi International University',
  'Wading River School',
  'Wadleigh Perf And Visual Arts',
  'Wadley High School',
  'Wadsworth Avenue Elementary',
  'Wadsworth Elem School',
  'Wadsworth High School',
  'Wadsworth Magnet School For High Achievers',
  'Wadsworth Middle School',
  'Waelder School',
  'Wagar 56 Middle School',
  'Wagar 78 Middle School',
  'Wagener-sally High',
  'Wageningen University',
  'Waggener High',
  'Waggoner Creek El',
  'Waggoner Road Junior High',
  'Waggoner Road Middle School',
  'Wagner Elementary - 02',
  'Wagner Gen Louis Ms',
  'Wagner High School - 01',
  'Wagner Middle School - 03',
  'Wagner Primary - 04',
  'Wagner Ranch Elementary',
  'Wagner-holt Elementary',
  'Wagon Mound Elementary',
  'Wagon Mound High',
  'Wagon Wheel Elementary',
  'Wagoner Elem',
  'Wagoner Hs',
  'Wagoner Ms',
  'Wagonwheel Elementary',
  'Wagram Elementary',
  'Wah Medical College',
  'Wahama High School',
  'Wahconah Regional High',
  'Wahiawa Elementary School',
  'Wahiawa Middle School',
  'Wahitis Elementary School',
  'Wahkiakum High School',
  'Wahl Coates Elementary',
  'Wahlquist Jr High',
  'Wahlstrom Early Childhood Academy',
  'Wahluke High School',
  'Wahluke Junior High',
  'Wahneta Elementary School',
  'Wahoo Elementary School',
  'Wahoo High School',
  'Wahoo Middle School',
  'Wahoo Pre-school',
  'Wahpeton Elementary School',
  'Wahpeton High School',
  'Wahpeton Middle School',
  'Wahpeton Spec Ed Unit',
  'Wahtonka Community School',
  'Waiahole Elementary School',
  'Waiakea Elementary School',
  'Waiakea High School',
  'Waiakea Intermediate School',
  'Waiakeawaena Elementary School',
  'Waialae Elementary - Pcs',
  'Waialua Elementary School',
  'Waialua High & Intermediate School',
  'Waianae Elementary School',
  'Waianae High School',
  'Waianae Intermediate School',
  'Waiau Elementary School',
  'Waihee Elementary School',
  'Waikele Elementary School',
  'Waikiki Elementary School',
  'Waikoloa Elementary And Middle School',
  'Wailuku Elementary School',
  'Waimalu Elementary School',
  'Waimanalo Elementary & Intermediate School',
  'Waimea Canyon Middle School',
  'Waimea Elementary School',
  'Waimea High School',
  'Waimea Middle - Pccs',
  'Wainscott School',
  'Wainwright',
  'Wainwright El',
  'Wainwright Middle School',
  'Wainwright Public School',
  'Waipahu Elementary School',
  'Waipahu High School',
  'Waipahu Intermediate School',
  'Wait Primary Elementary School',
  'Waite High School',
  'Waite Park Elementary',
  'Waits River Valley Usd #36',
  'Waitsburg Elementary School',
  'Waitsburg High School',
  'Waitsfield Elementary School',
  'Wakanda Elementary',
  'Wakapa Academy',
  'Wakarusa Elementary School',
  'Wakayama Medical College',
  'Wakayama University',
  'Wake Early College Of Health And Science',
  'Wake Forest Charter Academy',
  'Wake Forest Elementary',
  'Wake Forest High School',
  'Wake Forest Middle School',
  'Wake Forest University',
  'Wake Robin Elementary School',
  'Wake Stem Early College High School',
  'Wake Technical Community College',
  'Wake Village El',
  "Wake Young Men's Leadership Academy",
  "Wake Young Women's Leadership Academy",
  'Wakefield El',
  'Wakefield Elem',
  'Wakefield Elementary',
  'Wakefield Elementary School',
  'Wakefield Forest Elementary',
  'Wakefield High',
  'Wakefield High School',
  'Wakefield Hills El. School',
  'Wakefield Memorial High',
  'Wakefield Middle',
  'Wakefield School',
  'Wakefieldmarenisco School',
  'Wakeham Elementary',
  'Wakeland H S',
  'Wakelee School',
  'Wakelon Elementary',
  'Wakisha Cs',
  'Wakkanai Hokusei Gakuen University',
  'Wako University',
  'Wakonda Elem School',
  'Wakpala Elementary - 02',
  'Wakpala High School - 01',
  'Wakpala Middle School - 04',
  'Wakulla Coast Charter School Of Arts Science & Technology',
  'Wakulla County Virtual Instruction Program',
  'Wakulla High School',
  'Wakulla Institute',
  'Wakulla Middle School',
  'Wakulla Virtual Franchise',
  'Walailak University',
  'Walatowa Charter High',
  'Walberta Park Primary School',
  'Walbridge Elem. Community Ed.',
  'Walbridge Elementary School',
  'Walcott El',
  'Walcott Elementary School',
  'Walcott Intermediate',
  'Walcz College',
  'Walden Academy',
  'Walden Elem School',
  'Walden Elementary School',
  'Walden Green Montessori',
  'Walden Grove High School',
  'Walden Iii High',
  'Walden Iii Middle',
  'Walden Lake Elementary School',
  'Walden School',
  'Walden School Of Liberal Arts',
  'Walden University',
  'Waldo C Falkener Sr Elementary',
  'Waldo County Tech Ctr-region 7',
  'Waldo J Wood Memorial High',
  'Waldo J Wood Memorial Jhs',
  'Waldo Middle School',
  'Waldo Pafford Elementary School',
  'Waldo Rohnert Intermediate',
  'Waldo T Skillin Elementary School',
  'Waldon Middle School',
  'Waldon Woods Elementary',
  'Waldport High School',
  'Waldport Middle School',
  'Waldron Elementary And Middle School',
  'Waldron Elementary School',
  'Waldron High School',
  'Waldron Island School',
  'Waldron Jr-sr High School',
  'Waldron Learning Center',
  'Waldron Middle School',
  'Waldwick High School',
  'Waldwick Middle School',
  'Wales Elementary',
  'Wales Primary School',
  'Wales School',
  'Walgrove Avenue Elementary',
  'Walhalla Elementary',
  'Walhalla High',
  'Walhalla Middle',
  'Walker Butte K-8',
  'Walker Career Center',
  'Walker Charter Academy',
  'Walker County Center Of Technology',
  'Walker County Schools 180 Program',
  'Walker Creek El',
  'Walker Creek Elementary',
  'Walker Early Learning Center',
  'Walker El',
  'Walker Elem School',
  'Walker Elem.',
  'Walker Elementary',
  'Walker Elementary School',
  'Walker Elementary School (magnet)',
  'Walker Freshman High School',
  'Walker High School',
  'Walker Intermediate School',
  'Walker J H',
  'Walker Jones Ec',
  'Walker Junior High',
  'Walker Memorial School',
  'Walker Middle',
  'Walker Middle Magnet School',
  'Walker Middle School',
  'Walker Mill Middle',
  'Walker Park Elementary School',
  'Walker Pre-k Center',
  'Walker School',
  'Walker Spivey Elementary',
  'Walker Station El',
  'Walker Traditional Elementary School',
  'Walker Twp El Sch',
  'Walker Upper Elementary',
  'Walker Valley High School',
  'Walker-gamble Elementary',
  'Walker-grant Early Childhood Center',
  'Walker-grant Middle',
  'Walker-hackensack-akeley Alc',
  'Walker-hackensack-akeley Sec.',
  'Walkers Grove Elementary School',
  'Walkersville Elementary',
  'Walkersville High',
  'Walkersville Middle',
  'Walkerton Elementary School',
  'Walkertown Elementary',
  'Walkertown High',
  'Walkertown Middle',
  'Walkerville Public School',
  'Walkerwinter Elementary School',
  'Wall El',
  'Wall Elementary - 02',
  'Wall H S',
  'Wall High School',
  'Wall High School - 01',
  'Wall Intermediate School',
  'Wall Middle',
  'Wall Middle School - 06',
  'Wall Ppcd',
  'Wall Primary School',
  'Wall School',
  'Wall Sp Prog',
  'Wall Sp Prog (flc/bac)',
  'Wall Special Programs',
  'Walla Walla College',
  'Walla Walla Community College',
  'Walla Walla County Juvenile Detention',
  'Walla Walla High School',
  'Wallace & Priscilla Stevenson Intermediate School',
  'Wallace A. Smith Elementary',
  'Wallace Accelerated H S',
  'Wallace Alternative Campus',
  'Wallace Aylesworth Elementary',
  'Wallace Byrd Ms',
  'Wallace County High',
  'Wallace El',
  'Wallace El Sch',
  'Wallace Elem School',
  'Wallace Elementary',
  'Wallace Elementary School',
  'Wallace Elementary/middle',
  'Wallace Gregg Elementary',
  'Wallace H Braden Middle School',
  'Wallace H S',
  'Wallace High School',
  'Wallace Jr High School',
  'Wallace Jr/sr High School',
  'Wallace Middle',
  'Wallace Middle School',
  'Wallace R. Davis Elementary',
  'Wallace Stegner Academy',
  'Wallace-rose Hill High',
  'Wallagrass Elementary School',
  'Walland Elementary School',
  'Wallburg Elementary',
  'Walled Lake Central High School',
  'Walled Lake Elementary School',
  'Walled Lake Northern High School',
  'Walled Lake Schools Special Services',
  'Walled Lake Western High School',
  'Wallen L. Andrews Elementary',
  'Wallenberg (raoul) Traditional High',
  'Wallenpaupack Area Hs',
  'Wallenpaupack Area Ms',
  'Wallenpaupack North Intrmd Sch',
  'Wallenpaupack Pri Sch',
  'Wallenpaupack South El Sch',
  'Waller Elementary',
  'Waller Elementary School',
  'Waller H S',
  'Waller J H',
  'Waller J J A E P',
  'Waller Mill Elementary',
  'Waller Road Elementary',
  'Waller-williams Environmental',
  'Wallingford El Sch',
  'Wallingford Village School',
  'Wallington Junior Senior High School',
  'Wallins Elementary School',
  'Wallis Annenberg High',
  'Wallkill Senior High School',
  'Wallkill Valley Regional High School',
  'Wallowa Elementary School',
  'Wallowa High School',
  'Walls Elementary School',
  'Wally Watkins El',
  'Walnut Acres Elementary',
  'Walnut Attendance Center',
  'Walnut Avenue Elementary',
  'Walnut Avenue School',
  'Walnut Bend El',
  'Walnut Canyon Elementary',
  'Walnut Court Early Years Prgm',
  'Walnut Cove Elementary',
  'Walnut Creek Campus',
  'Walnut Creek El',
  'Walnut Creek Elementary',
  'Walnut Creek Elementary School',
  'Walnut Creek Intermediate',
  'Walnut Creek Middle School',
  'Walnut Creek Ms',
  'Walnut Elem',
  'Walnut Elementary',
  'Walnut Elementary Education Center',
  'Walnut Elementary School',
  'Walnut Glen Acad For Excel',
  'Walnut Grove El',
  'Walnut Grove Elem',
  'Walnut Grove Elem.',
  'Walnut Grove Elementary',
  'Walnut Grove Elementary School',
  'Walnut Grove High',
  'Walnut Grove High School',
  'Walnut Grove Intermediate',
  'Walnut Grove Middle',
  'Walnut Grove School',
  'Walnut Heights Elementary',
  'Walnut High',
  'Walnut Hill El',
  'Walnut Hill Elem School',
  'Walnut Hill Elementary',
  'Walnut Hill Elementary School',
  'Walnut Hill Elementary/middle School',
  'Walnut Hills Community Elementary School',
  'Walnut Hills Elementary',
  'Walnut Hills Elementary School',
  'Walnut Hills High School',
  'Walnut Middle School',
  'Walnut Park Elementary',
  'Walnut Park Elementary School',
  'Walnut Park Mid A Sch Of Soc Just And Service Lrng',
  'Walnut Park Mid B Sci Tech Engr And Math Acad',
  'Walnut Ridge Elementary School',
  'Walnut Ridge High School',
  'Walnut Ridge Primary School',
  'Walnut Springs Daep',
  'Walnut Springs El',
  'Walnut Springs Middle School',
  'Walnut Springs School',
  'Walnut Square',
  'Walnut Street El Sch',
  'Walnut Street Elementary School',
  'Walnut Street School',
  'Walnut Trails Elem School',
  'Walnutwood High (independent Study)',
  'Walpole Elementary School',
  'Walpole High',
  'Walpole Primary School',
  'Walsh College',
  'Walsh El',
  'Walsh Elem School',
  'Walsh Elementary School',
  'Walsh High School',
  'Walsh Middle',
  'Walsh School',
  'Walsingham Elementary School',
  'Walt Clark Middle School',
  'Walt Disney',
  'Walt Disney El',
  'Walt Disney El Sch',
  'Walt Disney Elem.',
  'Walt Disney Elementary',
  'Walt Disney Elementary School',
  'Walt Disney School',
  'Walt Morey Middle School',
  'Walt Tyler Elementary',
  'Walt Whitman Elem School',
  'Walt Whitman Elementary School',
  'Walt Whitman High',
  'Walt Whitman High School',
  'Walter & Lois Curtis Middle',
  'Walter Ambrose Family Center',
  'Walter B Howard Elementary School',
  'Walter Bickett Elementary',
  'Walter Bracken Elementary School',
  'Walter C. Black Elementary School',
  'Walter C. Polson Upper Middle School',
  'Walter C. Young Middle School',
  'Walter Caldwell Elementary School',
  'Walter Colton',
  'Walter D Palmer Leadership Learning Part',
  'Walter Douglas Elementary School',
  'Walter F Fierke Ed Center',
  'Walter F. Dexter Middle',
  'Walter F. Robinson Community School',
  'Walter G Byers School',
  "Walter G O'connell Copiague High School",
  'Walter G. Cady School',
  'Walter G. Nord Middle School',
  'Walter Hall El',
  'Walter Hays Elementary',
  'Walter Henri Dyett Hs For Arts',
  'Walter Herron Taylor Elementary',
  'Walter Hill Elementary',
  'Walter Hill School',
  'Walter J Meyer School',
  'Walter J Paton',
  'Walter J. Baird Middle School',
  'Walter J. Katnich Community Day',
  'Walter J. Mitchell Elementary',
  'Walter Jackson Elementary School',
  'Walter Jacobson Elementary School',
  'Walter Johnson High',
  'Walter Johnson Junior High School Academy Of International S',
  'Walter Kidder Elementary School',
  'Walter L Kraxberger Middle School',
  'Walter L Parsley Elementary',
  'Walter L. Cohen College Prep',
  'Walter M Kennedy School',
  'Walter M Senkow El Sch',
  'Walter M Williams High',
  'Walter M. Brown Elementary',
  'Walter M. Schirra Elementary School',
  'Walter Mcjones',
  'Walter Moses Burton El',
  'Walter Northway School',
  'Walter O. Krumbiegel Middle School',
  'Walter P Reuther Psychiatric Hospital',
  'Walter P. Carter Elementary',
  'Walter Panas High School',
  'Walter R Johnson Middle',
  'Walter R Sundling Jr High Sch',
  'Walter Reed Middle',
  'Walter S Parker Middle',
  'Walter S. Mills - Parole Elementary',
  'Walter Schneckenburger Elem School',
  'Walter Sisulu University for Technology and Science',
  'Walter Stiern Middle',
  'Walter Stillman School',
  'Walter Strom Middle School',
  'Walter T. Bergen Middle School',
  'Walter V Long Elementary School',
  'Walter White Elementary',
  'Walter Woodward Elementary',
  'Walter Zimmerman Elementary',
  'Walteria Elementary',
  'Walters Elementary',
  'Walters Es',
  'Walters Hs',
  'Walters Ms',
  'Walters State Community College',
  'Waltersville School',
  'Walterville Elementary School',
  'Walthall Co Career And Tech Center',
  'Waltham Elementary School',
  'Waltham Public Schools Dual Language Program',
  'Waltham Sr High',
  'Walthill Elementary School',
  'Walthill High School',
  'Walton',
  'Walton Academy',
  'Walton Academy Inc.',
  'Walton Avenue School (the)',
  'Walton Charter Academy',
  'Walton County Jail Program',
  'Walton Development Center',
  'Walton Elementary School',
  'Walton Elementary/middle School',
  'Walton Farm El Sch',
  'Walton Ferry Elementary',
  'Walton High School',
  'Walton Initiative For Success In Education (wise)',
  'Walton Learning Center',
  'Walton Middle',
  'Walton Middle School',
  'Walton Rural Life Center',
  'Walton School',
  'Walton Virtual Franchise',
  'Walton-verona Elementary School',
  'Walton-verona High School',
  'Walton-verona Middle School',
  'Waltonville Grade School',
  'Waltonville High School',
  'Waltrip H S',
  'Walworth Co Educ Alt High',
  'Walworth Elementary',
  'Walzem El',
  'Wamego High',
  'Wamego Middle School',
  'Wamogo Regional High School',
  'Wampatuck Elementary',
  'Wampus School',
  'Wamsley Elementary School',
  'Wamsutta Middle School',
  'Wanamaker Elem',
  'Wanamassa Elementary School',
  'Wanaque Elementary School',
  'Wanaque Preschool',
  'Wanaque Regional School',
  'Wanatah School',
  'Wanda Hirsch Elementary',
  'Wanda Kendall Elem School',
  'Wandell School',
  'Wando High',
  'Wanette Es',
  'Wanette Hs',
  'Wangenheim Middle',
  'Wanke El',
  'Wantage Elementary School',
  'Wantagh Middle School',
  'Wantagh School',
  'Wantagh Senior High School',
  'Wapahani High School',
  'Wapakoneta Elementary',
  'Wapakoneta High School',
  'Wapakoneta Jr. High',
  'Wapakoneta Middle School',
  'Wapanucka Es',
  'Wapanucka Hs',
  'Wapato High School',
  'Wapato Middle School',
  'Wapello Elementary School',
  'Wapello Junior High School',
  'Wapello Senior High School',
  'Wapiti Elementary',
  'Waples Mill Elementary',
  'Wapping Elementary School',
  'Wappingers Junior High School',
  'Wapsie Valley High School',
  'Warburg Institute University of London',
  'Ward Central Elementary',
  'Ward El',
  'Ward Elementary',
  'Ward Elementary School',
  'Ward Es',
  'Ward Es East',
  'Ward Es West',
  'Ward J Elem School',
  'Ward L Elem School',
  'Ward L Myers El Sch',
  'Ward Melville Senior High School',
  'Ward Traditional Academy',
  'Ward-highlands Elementary School',
  'Warden Elementary',
  'Warden High School',
  'Warden Middle School',
  'Warder Elementary School',
  'Warder Park-wayne Elementary School',
  'Wards Creek Elementary School',
  'Wardsboro Central School',
  'Ware County High School',
  'Ware County Middle School',
  'Ware El',
  'Ware Elem',
  'Ware Junior/senior High School',
  'Ware Middle School',
  'Ware Shoals Elementary Middle',
  'Ware Shoals High',
  'Ware Shoals Primary',
  'Ware Youth Center',
  'Wareham Cooperative Alternative School',
  'Wareham Middle',
  'Wareham Senior High',
  'Wares Ferry Elementary School',
  'Waresboro Elementary School',
  'Waretown Elementary School',
  'Warfield Elementary School',
  'Warford Elem.',
  'Warhill High',
  'Waring Laura W Sch',
  'Warlick Academy',
  'Warm Springs Elementary',
  'Warm Springs Elementary/middle School',
  'Warm Springs Intermediate School',
  'Warm Springs K-8 Academy',
  'Warm Springs Middle',
  'Warm Springs Middle School',
  'Warnborough University',
  'Warner',
  'Warner Avenue Elementary',
  'Warner El',
  'Warner Elementary',
  'Warner Elementary - 02',
  'Warner Elementary School',
  'Warner Es',
  'Warner Girls Leadership Academy',
  'Warner High',
  'Warner High School - 01',
  'Warner Hs',
  'Warner Junior/senior High',
  'Warner Middle',
  'Warner Middle School',
  'Warner Middle School - 03',
  'Warner Robins High School',
  'Warner Robins Middle School',
  'Warnsdorfer Elementary School',
  'Warren A. Allison Elementary',
  'Warren Area El Ctr',
  'Warren Area Hs',
  'Warren Central Elementary School',
  'Warren Central High School',
  'Warren Central Intermediate',
  'Warren Central Junior High School',
  'Warren Co. Area Technology Center',
  'Warren Community School',
  'Warren Consolidated Community High School',
  'Warren Consolidated Early Childhood Program',
  'Warren County Alternative Academy',
  'Warren County Avts',
  'Warren County Career Center',
  'Warren County Community College',
  'Warren County Day Treatment',
  'Warren County High',
  'Warren County High School',
  'Warren County Middle',
  'Warren County Middle School',
  'Warren County Special Services School',
  'Warren County Vocational Technical School',
  'Warren Dupree Elem. School',
  'Warren E. Hearnes Elem.',
  'Warren E. Hyde Middle',
  'Warren E. Sooy Jr. Elementary School',
  'Warren Early Childhood Center',
  'Warren Early College High',
  'Warren East High School',
  'Warren East Middle School',
  'Warren Easton Senior High School',
  'Warren El',
  'Warren Elem School',
  'Warren Elem.',
  'Warren Elementary',
  'Warren Elementary School',
  'Warren G Harding Elementary School',
  'Warren G Harding High School',
  'Warren G. Harding Elementary School',
  'Warren H S',
  'Warren H. Wolf Elementary School',
  'Warren Harding High School',
  'Warren High',
  'Warren High School',
  'Warren High School District Conversion Charter',
  'Warren Hills Elem.',
  'Warren Hills Regional High School',
  'Warren Hills Regional Middle School',
  'Warren Hope Dawson Elementary',
  'Warren Hunt Elem',
  'Warren J H',
  'Warren Jjaep',
  'Warren Jr/sr High School',
  'Warren L Miller El Sch',
  'Warren Lane Elementary',
  'Warren Middle',
  'Warren Middle School',
  'Warren Mott High School',
  'Warren New Tech High',
  'Warren Park School',
  'Warren Point Elementary School',
  'Warren Primary Center',
  'Warren Regional Juvenile Detention Ctr',
  'Warren Regional School',
  'Warren Rgc',
  'Warren Road Elementary School',
  'Warren School',
  'Warren Snyder-john Girotti El Sch',
  'Warren Street Academy',
  'Warren Street School',
  'Warren T. Eich Middle',
  'Warren Tech Central',
  'Warren Tech North',
  'Warren Township High School',
  'Warren Village School',
  'Warren Williams Elementary Alternative',
  'Warren Wilson College',
  'Warren Woods Adult Education',
  'Warren Woods Early Childhood Center',
  'Warren Woods Education Center',
  'Warren Woods Enterprise Hs',
  'Warren Woods Middle School',
  'Warren Woods Tower High School',
  'Warren-alvarado-olso Alp',
  'Warren-alvarado-oslo Secondary',
  'Warren-prescott',
  'Warrendale Charter Academy',
  'Warrens Elementary',
  'Warrensburg Area Career Ctr.',
  'Warrensburg Elementary School',
  'Warrensburg High',
  'Warrensburg Junior-senior High School',
  'Warrensburg Middle',
  'Warrensburg-latham Elem Sch',
  'Warrensburg-latham High School',
  'Warrensburg-latham Middle Sch',
  'Warrensville Heights High School',
  'Warrensville Heights Middle School',
  'Warrenton Elementary School',
  'Warrenton Grade School',
  'Warrenton High',
  'Warrenton High School',
  'Warrenton Middle',
  'Warrenville Elementary',
  'Warrenwood Elementary',
  'Warrick County Preschool',
  'Warring Magnet Academy Of Science And Technology',
  'Warrington Elementary School',
  'Warrington Middle School',
  'Warrior Elementary School',
  'Warrior Ridge Elem.',
  'Warrior Run Hs',
  'Warrior Run Ms',
  'Warroad Alternative Learning Progra',
  'Warroad Elementary',
  'Warroad High School',
  'Warsaw Community High School',
  'Warsaw Elem School',
  'Warsaw Elementary',
  'Warsaw Elementary School',
  'Warsaw High',
  'Warsaw Middle',
  'Warsaw Middle School',
  'Warsaw Middle/senior High School',
  'Warsaw School of Economics',
  'Warsaw School of Information Technology',
  'Warsaw School of Social Psychology',
  'Warsaw Sr High School',
  'Warsaw University of Technology',
  'Wartburg College',
  'Warwick Career And Tech Center',
  'Warwick Community School',
  'Warwick El Sch',
  'Warwick Elementary',
  'Warwick Elementary School',
  'Warwick High',
  'Warwick High School',
  'Warwick Middle School',
  'Warwick Ms',
  'Warwick Neck School',
  'Warwick Shs',
  'Warwick Valley High School',
  'Warwick Valley Middle School',
  'Warwick Veterans Jr. High Sch',
  'Warwood School',
  'Wasatch High',
  'Wasatch Jr High',
  'Wasatch Learning Academy',
  'Wasatch Peak Academy',
  'Wasatch School',
  'Wasatch Waldorf Charter School',
  'Wascher Elementary School',
  'Wasco Elem School',
  'Wasco High',
  'Wasco Independence High',
  'Waseca Area Learning Center',
  'Waseca Early Childhood',
  'Waseca Intermediate School',
  'Waseca Junior High',
  'Waseca Middle Level Alc',
  'Waseca Senior High',
  'Waseda University',
  'Wasena Elementary',
  'Washburn District Elem School',
  'Washburn District High School',
  'Washburn Elementary',
  'Washburn Elementary School',
  'Washburn High',
  'Washburn High School',
  'Washburn High School - Sws',
  'Washburn Middle',
  'Washburn Rural High',
  'Washburn Rural Middle School',
  'Washburn School',
  'Washburn Senior High',
  'Washburn University',
  'Washington',
  'Washington & Lee High',
  'Washington & Lee University',
  'Washington &amp; Jefferson College',
  'Washington (george) High',
  'Washington 4-5 Center - 20',
  'Washington 7-8',
  'Washington Academic Middle',
  'Washington Academy',
  'Washington Academy And High School',
  'Washington Academy Charter School',
  'Washington Academy Of Varying Exceptionalities (wave)',
  'Washington Accelerated Learning Elem',
  'Washington Attendance Center',
  'Washington Ave Elementary',
  'Washington Avenue Elementary School',
  'Washington Avenue School',
  'Washington B T H S',
  'Washington Bible College/Capital Bible Seminary',
  'Washington Career & Technical Education Center',
  'Washington Center',
  'Washington Center Elementary Sch',
  'Washington Co Correctional Facility',
  'Washington College',
  'Washington Colony Elementary',
  'Washington Comm High School',
  'Washington Community School',
  'Washington Community School #9',
  'Washington Connections Academy',
  'Washington Continuation Junior High School',
  'Washington County Career & Technical Education Center',
  'Washington County Career Technical Center',
  'Washington County Cc Use 051706',
  'Washington County Community College',
  'Washington County Elementary School',
  'Washington County Head Start & West Bend Title I',
  'Washington County High School',
  'Washington County Job Development Center',
  'Washington County Middle School',
  'Washington County Technical High',
  'Washington County Union',
  'Washington Discovery Academy',
  'Washington District Elementary',
  'Washington Drive Primary School',
  'Washington Dual Language Academy',
  'Washington Early Childhood Center',
  'Washington Early College High',
  'Washington Ech Ctr',
  'Washington El',
  'Washington El Sch',
  'Washington Elem',
  'Washington Elem School',
  'Washington Elem.',
  'Washington Elementary',
  'Washington Elementary Magnet School',
  'Washington Elementary School',
  'Washington Es',
  'Washington G Elem School',
  'Washington G High School',
  'Washington George Hs',
  'Washington Gifted School',
  'Washington Global Pcs',
  'Washington Grove Elementary',
  'Washington Grover Jr Sch',
  'Washington H Elem School',
  'Washington Heights Academy',
  'Washington Heights El',
  'Washington Heights El Sch',
  'Washington Heights Expeditionary Learning School',
  'Washington High',
  'Washington High School',
  'Washington High School - 01',
  'Washington Hs',
  'Washington I',
  'Washington Institute For Specialized Education',
  'Washington Intermediate',
  'Washington Intermediate School',
  'Washington Intrmd Sch',
  'Washington Irving Elementary Sch',
  'Washington Irving Es',
  'Washington Irving Interm School',
  'Washington Irving Mid Sch Math Music And Engr Magnet',
  'Washington Irving Middle',
  'Washington Irving Middle School',
  'Washington Irving School #4',
  'Washington Irving School 14',
  'Washington Island Elementary',
  'Washington Island High',
  'Washington J H',
  'Washington Jr High & Academy Prgm',
  'Washington Jr High School',
  'Washington Junior High',
  'Washington Junior High School',
  'Washington K-1 Elementary School',
  'Washington Lands Elementary School',
  'Washington Latin Pcs Middle School',
  'Washington Latin Pcs Upper School',
  'Washington Leadership Academy Pcs',
  'Washington Magnet Elem. School',
  'Washington Manor Middle',
  'Washington Martha Sch',
  'Washington Mathematics Science Technology Pchs',
  'Washington Memorial Elementary School',
  'Washington Metropolitan Hs',
  'Washington Middle',
  'Washington Middle College High',
  'Washington Middle School',
  'Washington Mill Elementary',
  'Washington Montessori',
  'Washington Ms',
  'Washington Network For Innovative Careers',
  'Washington Oak School',
  'Washington Park',
  'Washington Park Community School',
  'Washington Park El Sch',
  'Washington Park Elementary School',
  'Washington Park School',
  'Washington Pre Kg Center',
  'Washington Preschool',
  'Washington Primary Center',
  'Washington Primary Elementary School',
  'Washington Primary School',
  'Washington Rose School',
  'Washington School',
  'Washington School Of Early Learning',
  'Washington School Readiness Plus',
  'Washington Senior High School',
  'Washington Shores Elementary',
  'Washington St. Elementary',
  'Washington State Community College',
  'Washington State School For The Blind',
  'Washington State School For The Deaf',
  'Washington State University',
  'Washington State University at Tri-Cities',
  'Washington State University at Vancouver',
  'Washington Stem Academy',
  'Washington Street Education Center',
  'Washington Street Elementary',
  'Washington Street Elementary School',
  'Washington Street School',
  'Washington Tech Secondary Magnet',
  'Washington Terrace School',
  'Washington Township Elementary School',
  'Washington Township High School',
  'Washington Traditional School',
  'Washington Twp Elementary School',
  'Washington Twp Middle/high School',
  'Washington University Saint Louis',
  'Washington Village School',
  'Washington Virtual Academy Omak Elementary',
  'Washington Virtual Academy Omak High School',
  'Washington Virtual Academy Omak Middle School',
  'Washington Virtual Franchise',
  'Washington Virtual Instruction Program',
  'Washington West Elem.',
  'Washington West Elementary School',
  'Washington Woods Elementary School',
  'Washington Woods Middle School',
  'Washington Writers Academy',
  'Washington Youth Academy',
  'Washington Yu Ying Pcs',
  'Washington-franklin Elem.',
  'Washington-henry Elementary',
  'Washington-kosciusko Elementary',
  'Washington-lee Elementary',
  'Washington-lee High',
  'Washington-mckinley Elem Sch',
  'Washington-monroe Elem School',
  'Washington-saratoga-warren-hamilton-essex Boces',
  'Washington-wilkes Comprehensive High School',
  'Washington-wilkes Elementary School',
  'Washington-wilkes Middle School',
  'Washington-wilkes Primary School',
  'Washington/marion Magnet High School',
  'Washingtonparks Academy',
  'Washingtonville Middle School',
  'Washingtonville Senior High School',
  'Washoe Innovations High School',
  'Washoe Inspire Academy',
  'Washoe Online Learning',
  'Washougal High School',
  'Washougal Special Services',
  'Washtenaw Alliance For Virtual Education',
  'Washtenaw Alliance For Virtual Education Home',
  'Washtenaw Community College',
  'Washtenaw County Youth Center Educational Programs',
  'Washtenaw Deaf And Hard Of Hearing Program',
  'Washtenaw International High School',
  'Washtenaw International Middle Academy',
  'Washtenaw Technical Middle College',
  'Washtucna Elementary/high School',
  'Wasilla High School',
  'Wasilla Middle School',
  'Waskom Daep',
  'Waskom El',
  'Waskom H S',
  'Waskom Middle',
  'Wasmer Elementary School',
  'Wass Elementary School',
  'Wasson Avenue El Sch',
  'Wasuma Elementary',
  'Watauga El',
  'Watauga Elementary',
  'Watauga High',
  'Watauga Innovation Academy',
  'Watauga Middle',
  'Watchung Elementary School',
  'Watchung Hills Regional High School',
  'Water Canyon High',
  'Water Canyon School',
  'Water Resources University',
  'Water Valley / Veribest Special Programs',
  'Water Valley El',
  'Water Valley H S',
  'Water Valley High School',
  'Water Valley Ppcd',
  'Water Valley Voc Train',
  'Water Valley Vt',
  "Water's Edge Village School",
  'Waterboro Elementary School',
  'Waterbridge Elementary',
  'Waterbury Arts Magnet School (high)',
  'Waterbury Arts Magnet School (middle)',
  'Waterbury Career Academy',
  'Waterbury Elem School',
  'Wateree Elementary',
  'Wateree River High',
  'Waterford Durant High School',
  'Waterford El Sch',
  'Waterford Elementary',
  'Waterford Elementary School',
  'Waterford High',
  'Waterford High School',
  'Waterford Institute Of Technology',
  'Waterford Junior',
  'Waterford Kettering High School',
  'Waterford Lifetracks Program Crary Campus',
  'Waterford Memorial School',
  'Waterford Montessori Academy',
  'Waterford Mott High School',
  'Waterford Oaks El',
  'Waterford Street',
  'Waterford-halfmoon Elementary School',
  'Waterford-halfmoon Junior-senior High School',
  'Waterfront Elementary School',
  'Watergrass Elementary School',
  'Waterleaf Elementary',
  'Waterloo Elementary',
  'Waterloo Elementary School',
  'Waterloo High',
  'Waterloo High School',
  'Waterloo Intermediate',
  'Waterloo Junior High School',
  'Waterloo Middle',
  'Waterloo Middle School',
  'Waterloo School',
  'Waterman Elementary',
  'Waterman Elementary School',
  'Waters (alfred G.) Middle School',
  'Waters Career Center',
  'Waters Edge Elementary School',
  'Waters El',
  'Waters Elem School',
  'Waters Elementary',
  'Waters Landing Elementary',
  'Waterset Charter School',
  'Watershed Charter School',
  'Watershed High School',
  "Waterside Children's Studio School",
  'Waterside School For Leadership',
  'Watersmeet Township School',
  'Watertown 4 Kids',
  'Watertown Elementary',
  'Watertown High',
  'Watertown High School',
  'Watertown High School - 01',
  'Watertown Intermediate School - 13',
  'Watertown Mayer High',
  'Watertown Middle',
  'Watertown Middle School',
  'Watertown Middle School - 02',
  'Watertown Senior High School',
  'Watertown-mayer Elementary School',
  'Watertown-mayer Middle',
  'Watertown-mayer Primary School',
  'Waterville Community Elementary School',
  'Waterville Elementary',
  'Waterville Elementary School',
  'Waterville High School',
  'Waterville Jr/sr High School',
  'Waterville Junior High School',
  'Waterville Primary School',
  'Waterville Senior High School',
  'Waterville Valley Elementary School',
  'Waterville-elysian-morristown Jr.',
  'Waterville-elysian-morristown Sr.',
  'Watervliet Elementary School',
  'Watervliet Junior-senior High School',
  'Watervliet Middle School',
  'Watervliet Senior High School',
  'Waterway Elementary',
  'Watford City Elementary School',
  'Watford City High School',
  'Watford City Middle School',
  'Watkins Academy',
  'Watkins Early Childhood Center',
  'Watkins Elem.',
  'Watkins Elementary',
  'Watkins Elementary School',
  'Watkins Es Capitol Hill Cluster',
  'Watkins Glen Central High School',
  'Watkins Glen Elementary School',
  'Watkins Memorial High School',
  'Watkins Middle',
  'Watkins Middle School',
  'Watkins Mill Elementary',
  'Watkins Mill High',
  'Watkins Mill Park Camp',
  'Watkins-nance Elementary',
  'Watonga Es',
  'Watonga Hs',
  'Watonga Ms',
  'Watseka Comm High School',
  'Watsessing Elementary',
  'Watson B. Duncan Middle School',
  'Watson Chapel High School',
  'Watson Chapel Jr. High School',
  'Watson Elementary School',
  'Watson J H',
  'Watson Junior High School',
  'Watson Lane Elementary',
  'Watson Primary School',
  'Watson School',
  'Watson Technology Center',
  'Watson Williams Elementary School',
  'Watsonville Charter School Of The Arts',
  'Watsonville High',
  'Watt Hardison Elementary',
  'Watterson Elementary',
  'Wattles Elementary School',
  'Wattles Park Elementary School',
  'Watts El',
  'Watts Es',
  'Watts Hs',
  'Watts Learning Center',
  'Watts Learning Center Charter Middle',
  'Wattsburg Area El Ctr',
  'Wattsburg Area Ms',
  'Waubay Elementary - 02',
  'Waubay High School - 01',
  'Waubay Middle School - 03',
  'Waubesa Intermediate',
  'Waubonsee Community College',
  'Waubonsie Valley High School',
  'Waubun Alc',
  'Waubun Area Learning Program',
  'Waubun Elementary',
  'Waubun Secondary',
  'Wauchula Elementary School',
  'Wauconda Cusd 118 Alop',
  'Wauconda Grade School',
  'Wauconda High School',
  'Wauconda Middle School',
  'Waugh Chapel Elementary',
  'Wauka Mountain Elementary School',
  'Waukazoo Elementary School',
  'Waukee Elementary School',
  'Waukee Middle School',
  'Waukee Senior High School',
  'Waukee South Ms',
  'Waukegan High School',
  'Waukena Joint Union Elementary',
  'Waukesha Academy Of Health Professions',
  'Waukesha County Technical College',
  'Waukesha East Alternative School',
  'Waukesha Engineering Preparatory Academy',
  'Waukesha Stem Academy',
  'Waukesha Transition Academy',
  'Waukomis Es',
  'Waukomis Hs',
  'Waukon High School',
  'Waukon Middle School',
  'Waunakee Community 4k School',
  'Waunakee Heritage Elementary',
  'Waunakee High',
  'Waunakee Intermediate',
  'Waunakee Middle',
  'Waunakee Prairie Elementary',
  'Wauneta Palisade Middle School',
  'Wauneta-palisade High School',
  'Waupaca Community 4-year Old Kindergarten',
  'Waupaca County Charter',
  'Waupaca High',
  'Waupaca Learning Center Elementary',
  'Waupaca Middle',
  'Waupun Area Junior High',
  'Waupun Area Senior High',
  'Waupun Correctional Institution',
  'Waurika Es',
  'Waurika Hs',
  'Waurika Ms',
  'Wausa Elementary School',
  'Wausa High School',
  'Wausau Area Montessori Charter School',
  'Wausau Area Virtual Education',
  'Wausau Engineering And Global Leadership Academy',
  'Wausaukee Elementary',
  'Wausaukee High',
  'Wausaukee Junior High',
  'Wauseon Elementary School',
  'Wauseon High School',
  'Wauseon Middle School',
  'Wauseon Primary School',
  'Wautoma High',
  'Wauwatosa Montessori School',
  'Wauwatosa Stem',
  'Wauwatosa Virtual Academy',
  'Wauzeka Elementary',
  'Wauzeka High',
  'Wauzeka Middle',
  'Wave - Arizona Western College',
  'Wave - Kingman High School',
  'Wave - Lake Havasu High School',
  'Wave - Mohave High School',
  'Wave - Parker High School',
  'Wave - River Valley High School',
  'Wave Preparatory Elementary School',
  'Wave- Lee Williams High School',
  'Wave-culinary Arts And Business',
  'Wave-mohave Community College',
  'Waveland Elementary School',
  'Waverley Elementary',
  'Waverly Academy',
  'Waverly Avenue School',
  'Waverly Central High School',
  'Waverly Early Childhood Center',
  'Waverly Elem',
  'Waverly Elementary',
  'Waverly Elementary School',
  'Waverly High',
  'Waverly High School',
  'Waverly Intermediate School',
  'Waverly Jr High School',
  'Waverly Jr/sr High School',
  'Waverly Junior High School',
  'Waverly Middle School',
  'Waverly Park El',
  'Waverly Park School',
  'Waverly Primary School',
  'Waverly Regional Youth Ctr.',
  'Waverly Sch',
  'Waverly Senior High School',
  'Waverly Yowell Elementary',
  'Waverly-belmont Elementary School',
  'Waverly-shell Rock Middle School',
  'Waverly-shell Rock Senior High School',
  'Waverly-south Shore Elementary - 02',
  'Waverly-south Shore High School - 01',
  'Waverly-south Shore Middle School - 03',
  'Wawaloam School',
  'Wawasan Open University',
  'Wawasee High School',
  'Wawasee Middle School',
  'Wawecus Road School',
  'Wawona K-8',
  'Wawona Middle',
  'Waxahachie Family Faith Academy',
  'Waxahachie Global H S',
  'Waxahachie H S',
  'Waxahachie H S Of Choice',
  'Waxhaw Elementary',
  'Way Academy Chicago Site',
  'Way Academy Flint Flint Campus',
  'Way Academy Flint Washtenaw Campus',
  'Way Academy Roseville Site',
  'Way Academy Vernor Site',
  'Way East Jackson',
  'Way Elementary School',
  'Way Michigan',
  'Way Niles',
  'Way Oasis',
  'Way Watervliet High School',
  'Wayamba University of Sri Lanka',
  'Waycross Middle School',
  'Waycross Regional Youth Detention Center',
  'Wayland Alexander Elementary School',
  'Wayland Bonds Es',
  'Wayland Elementary School',
  'Wayland High School',
  'Wayland Middle School',
  'Wayland Union Middle School',
  'Wayland-cohocton High School',
  'Wayland-cohocton Middle School',
  'Wayman Academy Of The Arts',
  'Wayne A Cox El',
  'Wayne Avenue Elem',
  'Wayne Bartels Middle',
  'Wayne Builta Elementary School',
  'Wayne Carle Middle School',
  'Wayne Center Elem School',
  'Wayne Central Elementary School',
  'Wayne Central Middle School',
  'Wayne Central Primary School',
  'Wayne City Attendance Center',
  'Wayne City High School',
  'Wayne Co Career & Technical',
  'Wayne Community College',
  'Wayne Community Jr-sr High School',
  'Wayne County Alternative School',
  'Wayne County Area Technology Center',
  'Wayne County Community College District',
  'Wayne County High School',
  'Wayne County Learning Academy',
  'Wayne County Middle School',
  'Wayne County Schools Career Center',
  'Wayne Early / Middle College High',
  'Wayne Early Learning Center',
  'Wayne El Sch',
  'Wayne Elem School',
  'Wayne Elementary School',
  'Wayne Es',
  'Wayne High',
  'Wayne High School',
  'Wayne Highlands Ms',
  'Wayne Hills Center',
  'Wayne Hills High School',
  'Wayne Hs',
  'Wayne Junior High School',
  'Wayne M Henkle Middle School',
  'Wayne Memorial High School',
  'Wayne Middle',
  'Wayne Middle / High Academy',
  'Wayne Middle School',
  'Wayne Ms',
  'Wayne N Tanaka Elementary School',
  'Wayne Preparatory Academy',
  'Wayne Ruble Middle',
  'Wayne Sch',
  'Wayne School Of Engineering',
  'Wayne Senior High School',
  'Wayne State University',
  'Wayne Thomas Elem School',
  'Wayne Trace High School',
  'Wayne Trail Elementary School',
  'Wayne Valley High School',
  'Wayne Van Horn Elementary',
  'Waynedale Elementary School',
  'Waynedale High School',
  'Waynesboro Area Mid Sch',
  'Waynesboro Area Ms',
  'Waynesboro Area Shs',
  'Waynesboro Elementary',
  'Waynesboro High',
  'Waynesboro Middle School',
  'Waynesboro Primary School',
  'Waynesboro Riverview Ele School',
  'Waynesburg Central El School',
  'Waynesburg Central Hs',
  'Waynesburg College',
  'Waynesburg Elementary School',
  'Waynesfield-goshen Local Elementary School',
  'Waynesfield-goshen Local High School',
  'Waynesville Career Center',
  'Waynesville East Elem.',
  'Waynesville Elementary School',
  'Waynesville High School',
  'Waynesville Middle',
  'Waynesville Middle School',
  'Waynesville Primary School',
  'Waynesville Sr. High',
  'Waynewood Elementary',
  'Waynoka Es',
  'Waynoka Hs',
  "Wayo Women's University",
  'Wayside Elementary',
  'Wayside Elementary School',
  'Wayside Es',
  'Wayside Middle',
  'Wayzata Central Middle',
  'Wayzata East Middle',
  'Wayzata High',
  'Wayzata West Middle',
  'Wc Abney Elementary',
  'Wc Daycare Preschool',
  'Wc Griggs Elementary School',
  'Wc Tel Center',
  'Wca Middle School',
  'Wcjc',
  'Wcoc - Day Treatment',
  'Wcsea Black Patridge Pub Day',
  'Wd Carroll Elementary',
  'Wd Gattis Middle School',
  'Wd Robbins Elementary School',
  'We Putnam Middle Schoolmagnet',
  'Wea Ridge Elementary School',
  'Wea Ridge Middle School',
  'Wealthy School',
  'Weare Middle School',
  'Wearwood Elementary',
  'Weatherbee Elementary School',
  'Weatherford College',
  'Weatherford East Es',
  'Weatherford El',
  'Weatherford H S',
  'Weatherford Hs',
  'Weatherford Ms',
  'Weatherford West Es',
  'Weatherly Area El Sch',
  'Weatherly Area Ms',
  'Weatherly Area Shs',
  'Weatherly Heights Elementary School',
  'Weathersfield Elementary',
  'Weathersfield School',
  'Weatherstone Elementary',
  'Weaubleau Elem.',
  'Weaubleau High',
  'Weaver El',
  'Weaver Elem.',
  'Weaver Elementary',
  'Weaver Elementary School',
  'Weaver High School',
  'Weaver Lake Science Math & Tech Sch',
  'Weaver Middle',
  'Weaver Middle School',
  'Weaver Preschool',
  'Weaverville Elementary',
  'Weaverville Primary',
  'Webb A Murray Elementary',
  'Webb Bridge Middle School',
  'Webb City High',
  'Webb City Jr. High',
  'Webb City Middle',
  'Webb County J J A E P',
  'Webb D A E P',
  'Webb El',
  'Webb Elementary School',
  'Webb M S',
  'Webb Middle',
  'Webb Middle School',
  'Webb Preparatory School',
  'Webb Pri Center',
  'Webb Street School',
  'Webber Elementary',
  'Webber High School',
  'Webber Middle School',
  'Webber School',
  'Webbers Falls Es',
  'Webbers Falls Hs',
  'Webberville Elementary School',
  'Webberville High School',
  'Webberville Middle School',
  'Weber City Elementary',
  'Weber Elementary',
  'Weber Elementary School',
  'Weber High',
  'Weber Innovation High School',
  'Weber Institute',
  'Weber Online K-6',
  'Weber State University',
  'Weber State University Charter Academy',
  'Weberwood Elementary School',
  'Webster',
  'Webster (daniel) Elementary',
  'Webster Area Elementary - 03',
  'Webster Area High School - 01',
  'Webster Area Middle School - 02',
  'Webster Avenue School',
  'Webster Building',
  'Webster City High School',
  'Webster City Middle School',
  'Webster Co Career & Technol Ctr',
  'Webster Co Early Lrn-blue Hill',
  'Webster County Area Technology Center',
  'Webster County Elementary/middle School',
  'Webster County High School',
  'Webster County Middle School',
  'Webster Elem School',
  'Webster Elementary',
  'Webster Elementary - 06',
  'Webster Elementary School',
  'Webster Groves High',
  'Webster High',
  'Webster Hill School',
  'Webster Junior High School',
  'Webster Micro Society Magnet School',
  'Webster Middle',
  'Webster Middle School',
  'Webster Ms',
  'Webster Primary Ctr.',
  'Webster Sch',
  'Webster School',
  'Webster Springs Elementary School',
  'Webster Stanley Elementary',
  'Webster Stanley Middle',
  'Webster Street Elementary School',
  'Webster University',
  'Webster University Geneva',
  'Webster University Thailand',
  'Webster University Vienna',
  'Webster-schroeder High School',
  'Webutuck Elementary School',
  'Webutuck High School',
  'Weddington Elementary',
  'Weddington Elementary School',
  'Weddington High',
  'Weddington Hills Elementary',
  'Weddington Middle',
  'Weddle Elementary School',
  'Wedgefield School',
  'Wedgewood El',
  'Wedgewood Elementary School',
  'Wedgewood Middle School',
  'Wedgewood Park School',
  'Wedgeworth El',
  'Wedgeworth Elementary',
  'Wedgwood 6th Gr Sch',
  'Wedgwood Elem.',
  'Wedgwood Elementary School',
  'Wedgwood Middle',
  'Wedowee Elementary School',
  'Wedowee Middle School',
  'Weed Elementary',
  'Weed High',
  'Weeden Elementary School',
  'Weedsport Elementary School',
  'Weedsport Junior-senior High School',
  'Weehawken High School',
  'Weeki Wachee High School',
  'Weeks Middle School',
  'Weeksville Elementary',
  'Weems Elementary',
  'Weeping Water Elementary School',
  'Weeping Water High School',
  'Weeping Water Middle School',
  'Weequahic High School',
  'Wegeforth Elementary',
  'Wegienka Elementary School',
  'Wegner Elementary School',
  'Wei Yu International Charter',
  'Weidman Elementary School',
  'Weigand Avenue Elementary',
  'Weigel Elementary School',
  'Weigelstown El Sch',
  'Weikel Elementary School',
  'Weilenmann School Of Discovery',
  'Weill (raphael) Children Center',
  'Weimar El',
  'Weimar H S',
  'Weimar Hills',
  'Weimar J H',
  'Weinberg Elementary School',
  'Weiner Elementary',
  'Weinert El',
  'Weinland Park Elementary School',
  'Weir Elementary School',
  'Weir High School',
  'Weir Middle School',
  'Weir Preparatory Academy',
  'Weirton Elementary',
  'Weis Middle',
  'Weisenberg El Sch',
  'Weisenborn Junior High',
  'Weiser High School',
  'Weiser Middle School',
  'Weiss Elementary School',
  'Weiss H S',
  'Weisser Park Elementary School',
  'Weitchpec Elementary',
  "Weitzel's Puente De Hozho Bilingual Magnet School",
  'Weizmann Institute of Science',
  'Wekiva Elementary School',
  'Wekiva High',
  'Welborn Elem',
  'Welborn Middle',
  'Welby Community School',
  'Welby School',
  'Welby Way Charter Elem Sch And Gifted-high Ability Magnet',
  'Welch Elementary School',
  'Welch Es',
  'Welch Hs',
  'Welch Middle',
  'Welch Ms',
  'Welches Elementary School',
  'Welches Middle School',
  'Welchester Elementary School',
  'Welcome Elementary',
  'Weld Central Middle School',
  'Weld Central Senior High School',
  'Welder El',
  'Weldon El',
  'Weldon Elementary',
  'Weldon Elementary Global Academy',
  'Weldon Hafley Development Ctr',
  'Weldon Middle',
  'Weldon Stem High School Career Academies',
  'Weldon Valley Elementary School',
  'Weldon Valley Jr/sr High School',
  'Weleetka Hs',
  'Weleetka Jhs',
  'Wellborn Elementary School',
  'Wellborn High School',
  'Wellborn Middle',
  'Wellcome Middle',
  'Welleby Elementary School',
  'Weller Elem.',
  'Weller Elementary',
  'Weller Elementary School',
  'Weller Road Elementary',
  'Wellesley College',
  'Wellesley Middle',
  'Wellesley Sr High',
  'Wellfleet Elementary',
  'Wellford Academy Of Science And Technology',
  'Wellington C Mepham High School',
  'Wellington El',
  'Wellington Elementary',
  'Wellington Elementary School',
  'Wellington H S',
  'Wellington High Adult Education Center',
  'Wellington High School',
  'Wellington J H',
  'Wellington Landings Middle',
  'Wellington M. Smith Jr.',
  'Wellington Middle School',
  'Wellington School',
  'Wellington-napoleon Elem.',
  'Wellington-napoleon High',
  'Wellman-union School',
  'Wellness Business And Sports School',
  'Wellpinit Alliance High School',
  'Wellpinit Elementary School',
  'Wellpinit Fort Simcoe Sea',
  'Wellpinit High School',
  'Wellpinit Middle School',
  'Wellpinit-fort Simcoe High School',
  'Wells (ida B.) High',
  'Wells Academy',
  'Wells Alternative Middle School',
  'Wells Branch El',
  'Wells College',
  'Wells Community Academy Hs',
  'Wells El',
  'Wells Elem School',
  'Wells Elementary',
  'Wells Elementary School',
  'Wells H S',
  'Wells High School',
  'Wells Junior High School',
  'Wells Memorial School',
  'Wells Middle',
  'Wells Ms',
  'Wells Preparatory Elem Academy',
  'Wells Road Intermediate School',
  'Wells School',
  'Wells Station Elementary',
  'Wells Township School',
  'Wells Village School',
  'Wellsboro Area Hs',
  'Wellsburg Middle School',
  'Wellsburg Primary School',
  'Wellspring Living',
  'Wellspring Preparatory High School',
  'Wellspring University',
  'Wellston Es',
  'Wellston High School',
  'Wellston Hs',
  'Wellston Intermediate School',
  'Wellston Middle School',
  'Wellston Ms',
  'Wellstone High School - Sws',
  'Wellstone International High',
  'Wellsville Campus',
  'Wellsville Elem',
  'Wellsville Elem.',
  'Wellsville Elementary School',
  'Wellsville High',
  'Wellsville Jr. Sr. High',
  'Wellsville Middle School',
  'Wellsville School',
  'Wellsville Secondary School',
  'Wellton Elementary School',
  'Wellwood International Elementary',
  'Wellwood Middle School',
  'Wels North',
  'Wels South',
  'Welsh Elementary School',
  'Welsh High School',
  'Welsh John Sch',
  'Welsh Valley Ms',
  'Welsh-roanoke Junior High School',
  'Welte Education Center',
  'Welti Elementary School',
  'Wemrock Brook School',
  'Wenatchee High School',
  'Wenatchee Valley College',
  'Wenatchee Valley Technical Skills Center',
  'Wendell Elementary',
  'Wendell Elementary School',
  'Wendell High School',
  'Wendell Krinn Technical High School',
  'Wendell L. Cross School',
  'Wendell Middle',
  'Wendell Middle School',
  'Wendell P. Williams Elementary School',
  'Wendell Phillips Elem.',
  'Wendell Phillips School 63',
  'Wendell Watson Elementary School',
  'Wenden Elementary School',
  'Wendler Middle School',
  'Wendover High',
  'Wendover Ms',
  'Wenona Center Home Of Wenona Highmiddle School',
  'Wenonah Elementary',
  'Wenonah Elementary School',
  'Wenonah High School',
  'Wenonah School',
  'Wentworth Elem School',
  'Wentworth Elementary',
  'Wentworth Elementary School',
  'Wentworth Institute of Technology',
  'Wentworth Intermediate School',
  'Wentworth Jr High School',
  'Wentworth Military Academy & Junior College',
  'Wentworth School',
  'Wentzville Middle',
  'Wentzville South Middle',
  'Wenzel School',
  'Wenzhou Medical College',
  'Wenzhou University',
  'Wequiock Elementary',
  'Wequonnoc Magnet School',
  'Werner Elementary School',
  'Werner Park Elementary School',
  'Wernert Elementary School',
  'Wes-del Elementary School',
  'Wes-del Middle/senior High School',
  'Wes-del Preschool',
  'Wesclin Middle School',
  'Wesclin Sr High School',
  'Wescosville El Sch',
  'Wescott Elementary School',
  'Wescove Elementary',
  'Weskan Elem',
  'Weskan High',
  'Weslaco 21st Century Cte Early College H S',
  'Weslaco East H S',
  'Weslaco H S',
  'Wesley Chapel Elementary',
  'Wesley Chapel Elementary School',
  'Wesley Chapel High Adult Education',
  'Wesley Chapel High School',
  'Wesley College',
  'Wesley D Tisdale Elementary School',
  'Wesley El',
  'Wesley Elem School',
  'Wesley Elementary School',
  'Wesley Gaines',
  'Wesley Heights Elementary School',
  'Wesley International Academy Charter Facility',
  'Wesley Lakes Elementary School',
  'Wesley Matthews Elementary School',
  'Wesley Ray Elementary School',
  'Wesley School',
  'Wesley University of Science and Technology',
  'Wesleyan University',
  'Wesleyan University Philippines',
  'Wesm Health/sports Medicine',
  'Wesmere Elementary School',
  'Wessagusset',
  'Wessington Springs Elementary - 02',
  'Wessington Springs Elementary School Cyber School - 94',
  'Wessington Springs High School - 01',
  'Wessington Springs High School Cyber School - 92',
  'Wessington Springs Junior High - 04',
  'Wessington Springs Middle School Cyber School - 93',
  'Wesson Attendance Center',
  'West',
  'West 40 Alop Achievers',
  'West 40 Reg Safe Sch Middle Prg',
  'West 40-sr. Plus',
  'West Academy',
  'West Academy Alternative School',
  'West Adams Preparatory High',
  'West Albany High School',
  'West Alexander Middle',
  'West Allegheny Ms',
  'West Allegheny Shs',
  'West Allis-west Milwaukee Learning Center',
  'West Amory School',
  'West Amwell Twp School',
  'West Annapolis Elementary',
  'West Area',
  'West Area Adult/community School',
  'West Ashley High',
  'West Ashley Middle',
  'West Athens Elementary',
  'West Auburn Senior High School',
  'West Aurora High School',
  'West Aurora Hs Rssp',
  'West Avenue El',
  'West Avenue School',
  'West Babylon Junior High School',
  'West Babylon Senior High School',
  'West Bainbridge Elementary School',
  'West Barnstable Elementary',
  'West Bath School',
  'West Bay Early Ed Center',
  'West Bay Elementary School',
  'West Belmar Elementary School',
  'West Bend School District 4k',
  'West Bend-mallard High School',
  'West Bend-mallard Middle School',
  'West Bendle Elementary School',
  'West Bengal University of Animal and Fishery Sciences',
  'West Bengal University of Technology',
  'West Bertie Elementary',
  'West Berwick El Sch',
  'West Birdville El',
  'West Bladen High',
  'West Blocton Elementary School',
  'West Blocton High School',
  'West Blocton Middle School',
  'West Bloomfield High School',
  'West Bloomfield Transition Center',
  'West Blvd. Elem.',
  'West Boca Raton Community High School Adult Education Center',
  'West Boca Raton High School',
  'West Bolivar Dist High School',
  'West Bolivar District Middle School',
  'West Bolivar Elem',
  'West Boron Elementary',
  'West Boulevard Elementary School',
  'West Bountiful School',
  'West Bourbon Elementary',
  'West Boyd Elementary School',
  'West Boyd High School',
  'West Boyd Middle School',
  'West Boylston Junior/senior High',
  'West Bradford El Sch',
  'West Branch Area El Sch',
  'West Branch Area Jshs',
  'West Branch High School',
  'West Branch Middle School',
  'West Brazos J H',
  'West Briar Middle',
  'West Bridgewater Junior/senior',
  'West Bristol School',
  'West Broad Elementary School',
  'West Broad Street El Sch',
  'West Broad Street School',
  'West Broadway Elementary School',
  'West Broadway Middle School',
  'West Bronx Academy For The Future',
  'West Brook Middle School',
  'West Brook Sr H S',
  'West Brookfield Elementary',
  'West Brookhaven Youth Ranch School',
  'West Brooklyn Community High School',
  'West Broward Academy',
  'West Broward High School',
  'West Brunswick High',
  'West Buffalo Charter School',
  'West Buncombe Elementary',
  'West Burlington Elem School',
  'West Burlington High School',
  'West Burlington Junior High School',
  'West Cabarrus High',
  'West Caldwell High',
  'West Caldwell Tech',
  'West Campus',
  'West Campus Elementary School',
  'West Campus Facility',
  'West Canada Valley Elementary School',
  'West Canada Valley Junior-senior High School',
  'West Canyon Elementary School',
  'West Cape May Elementary School',
  'West Career & Technical Academy',
  'West Career Academy',
  'West Carroll Elementary School',
  'West Carroll High School',
  'West Carroll Junior/senior High School',
  'West Carroll Middle School',
  'West Carroll Primary',
  'West Carrollton High School',
  'West Carrollton Middle School',
  'West Carrollton Secondary Academy',
  'West Carter County High School',
  'West Carter Middle School',
  'West Carteret High',
  'West Carthage Elementary School',
  'West Cary Middle',
  'West Cedar Elementary School',
  'West Central Area Learning Center',
  'West Central Area N. El.',
  'West Central Area S. El.',
  'West Central Area Secondary',
  'West Central Charter High School',
  'West Central Early Childhood',
  'West Central Education District B-2',
  'West Central El',
  'West Central Elem.',
  'West Central Elementary School',
  'West Central Hartford Elementary - 05',
  'West Central High School',
  'West Central High School - 01',
  'West Central Humboldt Elementary - 02',
  'West Central Juvenile Center',
  'West Central Learning Academy Ii',
  'West Central Middle School',
  'West Central Middle School - 04',
  'West Central Pk - 8 School',
  'West Central Primary School',
  'West Central Senior High School',
  'West Central Valley High School',
  'West Central Valley Middle School',
  'West Chapmanville Elementary School',
  'West Charlotte High',
  'West Chatham Elementary School',
  'West Chatham Middle School',
  'West Cheatham Elementary',
  'West Chester Bayard Rustin Hs',
  'West Chester East Hs',
  'West Chester Henderson Hs',
  'West Chester University of Pennsylvania',
  'West China University of Medical Sciences',
  'West Clay Elementary School',
  'West Clayton Elementary',
  'West Clayton Elementary School',
  'West Clermont High School',
  'West Clermont Middle School',
  'West Clinton School',
  'West Coast University',
  'West Coast University (WCU)',
  'West Columbia El',
  'West Columbus High',
  'West Cottonwood Junior High',
  'West County Charter Middle',
  'West County Elem.',
  'West County High',
  'West County Mandarin',
  'West County Middle',
  'West Covina High',
  'West Craven High',
  'West Craven Middle',
  'West Crawford El Sch',
  'West Crawford Elementary School',
  'West Creek Academy',
  'West Creek Elementary',
  'West Creek High',
  'West Creek Hills El Sch',
  'West Creek Middle',
  'West Cypress Hills El',
  'West Dallas Area',
  'West Davidson High',
  'West De Pere High',
  'West De Pere Middle',
  'West Decatur Elementary School',
  'West Defuniak Elementary School',
  'West Delaware High School',
  'West Delaware Middle School',
  'West Deptford High School',
  'West Deptford Middle School',
  'West Desert High School',
  'West Desert School',
  'West District School',
  'West Dodge Station Early Ed Center',
  'West Dodge Station Elementary',
  'West Dover Elementary School',
  'West Early Childhood Center',
  'West Early College',
  'West Ec',
  'West Edgecombe Middle',
  'West Education Center Alternative',
  'West El',
  'West Elem',
  'West Elem School',
  'West Elem.',
  'West Elementary',
  'West Elementary - 03',
  'West Elementary School',
  'West Elementary-kindergarten Center',
  'West Elk Schools',
  'West Elkton Intermediate School',
  'West End Academy',
  'West End El',
  'West End El Sch',
  'West End Elementary',
  'West End Elementary School',
  'West End High School',
  'West End Memorial Elementary School',
  'West End Middle',
  'West End School',
  'West End Secondary School',
  'West Englewood Elem.',
  'West Essex High School',
  'West Essex Middle School',
  'West Fairmont Middle School',
  'West Fannin Elementary School',
  'West Fargo High School',
  'West Fargo Sheyenne High School',
  'West Fargo Sheyenne Middle School',
  'West Fargo Spec Ed Unit',
  'West Feliciana High School',
  'West Feliciana Middle School',
  'West Field Es',
  'West Florence High',
  'West Florida High School/technical',
  'West Forest El Sch',
  'West Forest Intermediate School',
  'West Forest Jshs',
  'West Fork Elementary At Rockwell',
  'West Fork Elementary School',
  'West Fork Elementary Sheffield',
  'West Fork High School',
  'West Fork Middle School',
  'West Forsyth High',
  'West Forsyth High School',
  'West Foundation El',
  'West Franklin Elementary School',
  'West Franklin High School',
  'West Franklin Learning Center High - Charter',
  'West Franklin Middle School',
  'West Frederick Middle',
  'West Freehold School',
  'West Fresno Elementary',
  'West Fresno Middle',
  'West Friendship Elementary',
  'West Gadsden Middle School',
  'West Gate Elementary',
  'West Gate Elementary School',
  'West Gate K-8 School',
  'West Gates Avenue School',
  'West Geauga High School',
  'West Geauga Middle School',
  'West Genesee Middle School',
  'West Genesee Senior High School',
  'West Georgia College',
  'West Georgia Technical College',
  'West Glacier School',
  'West Glades School',
  'West Glocester Elementary',
  'West Godwin Elementary School',
  'West Goshen Elementary School',
  'West Grand Elementary And Middle School',
  'West Grand High School',
  'West Green Elementary School',
  'West Greene El Ctr',
  'West Greene Elementary',
  'West Greene High School',
  'West Greene Hs',
  'West Greene Ms',
  'West Gresham Elementary School',
  'West Groves Early Learning Center',
  'West H S',
  'West Hall High School',
  'West Hall Middle School',
  'West Hamlin Elementary',
  'West Hancock Elementary School',
  'West Hancock High School',
  'West Hancock Middle School',
  'West Handley El',
  'West Hanover El Sch',
  'West Haralson Elementary School',
  'West Hardin El',
  'West Hardin Elementary',
  'West Hardin H S',
  'West Hardin Middle School',
  'West Harrison Elementary',
  'West Harrison High School',
  'West Hartsville Elementary',
  'West Haven Elementary',
  'West Haven High School',
  'West Haven School',
  'West Haverstraw Elementary School',
  'West Hawaii Explorations Academy',
  'West Hazleton El/ms',
  'West Heights Alc',
  'West Heights Extended Day',
  'West Hempfield El Sch',
  'West Hempfield Ms',
  'West Hempstead High School',
  'West Hempstead Middle School',
  'West Henderson High',
  'West Heritage Elementary',
  'West Hernando Middle School',
  'West Hertel Elementary School',
  'West Hialeah Gardens Elementary School',
  'West High',
  'West High School',
  'West Hill El Sch',
  'West Hill Middle School',
  'West Hill School',
  'West Hills College-​Coalinga',
  'West Hills Elementary',
  'West Hills Elementary School',
  'West Hills High',
  'West Hills Intermediate Sch',
  'West Hills Juvenile Hall Court',
  'West Hills Middle',
  'West Hills Middle School',
  'West Hills Primary Sch',
  'West Hills S.t.e.m. Academy',
  'West Hillsborough',
  'West Hoke Elementary',
  'West Hoke Middle',
  'West Hollow Middle School',
  'West Hollywood Elementary',
  'West Hollywood Elementary School',
  'West Hollywood Opportunity',
  'West Holmes High School',
  'West Holmes Middle School',
  'West Holt High School',
  'West Holt Little Paws Preschool',
  'West Homer Elementary',
  'West Homestead K-8 Center',
  'West Hopkins School',
  'West Hurst El',
  'West Indianola Elem',
  'West Intermediate',
  'West Iredell High',
  'West Iredell Middle',
  'West Iron County High School',
  'West Iron County Middle School',
  'West Irvine Intermediate',
  'West Islip Senior High School',
  'West Jackson Elementary School',
  'West Jackson Middle School',
  'West Jasper Elementary School',
  'West Jay County Middle School',
  'West Jefferson Elementary School',
  'West Jefferson High School',
  'West Jefferson Middle School',
  'West Jessamine High School',
  'West Jessamine Middle School',
  'West Jhs',
  'West Johnston High',
  'West Jones Elementary School',
  'West Jones High School',
  'West Jordan High',
  'West Jordan Middle',
  'West Jordan School',
  'West Jr High School',
  'West Junior High',
  'West Junior High School',
  'West Kearns School',
  'West Kelloggsville School',
  'West Kemper Elementary School',
  'West Kentucky Community and Technical College',
  'West Kingston School',
  'West Ky Technical College',
  'West Lafayette Jr/sr High School',
  'West Lake Elementary',
  'West Lake Jr High',
  'West Lake Middle',
  'West Lake Spec Ed Coop',
  'West Lakes Preparatory Academy',
  'West Lane Elem.',
  'West Lane Technology Learning Center',
  'West Lauderdale Elementary School',
  'West Lauderdale High School',
  'West Lauderdale Middle School',
  'West Laurens High School',
  'West Laurens Middle School',
  'West Lawn Elementary School',
  'West Leadership Academy',
  'West Lee Elementary',
  'West Lee Middle',
  'West Leesville Elementary School',
  'West Lenoir Elementary',
  'West Leyden Elementary School',
  'West Leyden High School',
  'West Liberty Elementary School',
  'West Liberty High School',
  'West Liberty Middle School',
  'West Liberty State College',
  'West Liberty-salem Elementary School',
  'West Liberty-salem Middle/high School',
  'West Limestone High School',
  'West Lincoln Elementary School',
  'West Lincoln High',
  'West Lincoln Middle',
  'West Lincoln School',
  'West Lincoln-broadwell Elem Sch',
  'West Linn High School',
  'West Los Angeles College',
  'West Louisville Elementary School',
  'West Lowndes Elementary',
  'West Lowndes High School',
  'West Lumberton Elementary',
  'West Lyon Elementary School',
  'West Lyon High School',
  'West Lyon Junior High School',
  'West Madison Elementary School',
  'West Magnet School',
  'West Main El',
  'West Main Elementary School',
  'West Mall Alternative',
  'West Manheim El Sch',
  'West Manor Elementary School',
  'West Maple Elementary School',
  'West Marin Elementary',
  'West Marion Elementary',
  'West Marion Elementary School',
  'West Marion High School',
  'West Marion Primary School',
  'West Market School Of Early Education',
  'West Marshall Elementary School',
  'West Marshall High School',
  'West Marshall Middle School',
  'West Mcdowell Middle',
  'West Meade Early Education Center',
  'West Mecklenburg High',
  'West Memorial',
  'West Memorial El',
  'West Memorial J H',
  'West Mercer Elementary',
  'West Mesa High',
  'West Mesquite H S',
  'West Mi Academy Of Arts And Academics',
  'West Mi Academy Of Environmental Science',
  'West Miami Middle School',
  'West Michigan Aviation Academy',
  'West Michigan Virtual Battle Creek Hs',
  'West Michigan Virtual Battle Creek Ms',
  'West Middle',
  'West Middle Island School',
  'West Middle School',
  'West Middle School - 37',
  'West Middlesex Area Jshs',
  'West Middleton Elementary',
  'West Mifflin Area Hs',
  'West Mifflin Area Ms',
  'West Milford Elementary School',
  'West Milford High School',
  'West Millbrook Middle',
  'West Milwaukee Intermediate',
  'West Minico Middle School',
  'West Minster International College',
  'West Monona Elementary',
  'West Monona High School',
  'West Monona Middle Sch',
  'West Monroe High School',
  'West Montgomery High',
  'West Morgan Elementary School',
  'West Morgan High School',
  'West Morgan Middle School',
  'West Morris Central High School',
  'West Morris Mendham High School',
  'West Mound Elementary School',
  'West Muskingum Elementary School',
  'West Muskingum High School',
  'West Muskingum Middle School',
  'West Nassau County High School',
  'West Navarre Intermediate School',
  'West Navarre Primary School',
  'West New York Middle School',
  'West Newton El Sch',
  'West Newton Elementary School',
  'West Nichols Hills Es',
  'West Noble Elementary School',
  'West Noble High School',
  'West Noble Middle School',
  'West Noble Primary School',
  'West Nodaway High',
  'West Nodaway R-i Elem.',
  'West Nyack Elementary School',
  'West Oak Lane Cs',
  'West Oak Middle',
  'West Oak Middle School',
  'West Oakland Middle',
  'West Oaks Elementary',
  'West Oakview Elementary School',
  'West Orange Elementary',
  'West Orange High',
  'West Orange High School',
  'West Orange-stark El',
  'West Orange-stark H S',
  'West Orange-stark Middle',
  'West Orient Middle School',
  'West Oso El',
  'West Oso H S',
  'West Oso Isd Jjaep',
  'West Oso J H',
  'West Ottawa High School Campus',
  'West Ouachita High School',
  'West Oxford Elementary',
  'West Palms Conservatory',
  'West Parish',
  'West Park Academy',
  'West Park Charter Academy',
  'West Park Elem Academy',
  'West Park Elementary',
  'West Park Elementary School',
  'West Park Place Elementary School',
  'West Park School',
  'West Patent Elementary School',
  'West Pelzer Elementary',
  'West Pender Middle',
  'West Penn Twp El Sch',
  'West Pensacola Elementary School',
  'West Perry Elementary School',
  'West Perry Ms',
  'West Perry Shs',
  'West Phila. Achievement Ces',
  'West Philadelphia Hs',
  'West Phoenix High School',
  'West Pine Elementary',
  'West Pine Middle',
  'West Pines Elementary',
  'West Plains Elem.',
  'West Plains Middle',
  'West Plains Sr. High',
  'West Platte High',
  'West Point - Beemer Middle School',
  'West Point El Sch',
  'West Point Elementary',
  'West Point Elementary School',
  'West Point High',
  'West Point High School',
  'West Point Intermediate School',
  'West Point Jr High',
  'West Point Learning Center',
  'West Point Middle',
  'West Point Middle School',
  'West Point School',
  'West Point Voc Comp',
  'West Point-beemer High School',
  'West Pomeranian Business School in Szczecin',
  'West Port High School',
  'West Portal Elementary',
  'West Potomac High',
  'West Potomac High School Academy',
  'West Pottsgrove El Sch',
  'West Powellhurst Elementary School',
  'West Prairie High School',
  'West Prairie Middle School',
  'West Prairie North Elementary',
  'West Prairie South Elementary',
  'West Prep Academy',
  'West Preparatory Academy',
  'West Prepatory Academy At Charles I West Hall Elem',
  'West Prepatory Academy At Charlles I West Hall Sec',
  'West Preston School',
  'West Putnam Elementary',
  'West Ranch High',
  'West Randall Elementary',
  'West Reading El Ctr',
  'West Ridge Academy',
  'West Ridge Elem School',
  'West Ridge Elementary',
  'West Ridge Elementary School',
  'West Ridge Middle',
  'West Ridge Middle School',
  'West River Student Services',
  'West Riverside Elementary',
  'West Riverside Elementary School',
  'West Riviera Elementary School',
  "West Road/d'aquannis Intermediate School",
  'West Rock Authors Academy',
  'West Rockhill El Sch',
  'West Rockingham Elementary',
  'West Rocks Middle School',
  'West Rowan Elementary',
  'West Rowan High',
  'West Rowan Middle',
  'West Roxbury Academy',
  'West Running Brook Middle School',
  'West Rusk El',
  'West Rusk H S',
  'West Rusk Int',
  'West Rusk Middle',
  'West Rutland School',
  'West Sabine El',
  'West Sabine H S',
  'West Sacramento School For Independent Study',
  'West Salem Elementary',
  'West Salem Grade School',
  'West Salem High',
  'West Salem High School',
  'West Salem Middle',
  'West Sand Lake Elementary School',
  'West School',
  'West Scranton Hs',
  'West Scranton Intrmd Sch',
  'West Seaford Elementary School',
  'West Seattle Elementary School',
  'West Seattle High School',
  'West Sedona Elementary School',
  'West Seneca East Senior High School',
  'West Seneca West Senior High School',
  'West Senior High',
  'West Senior High School',
  'West Shamokin Jshs',
  'West Shore Community College',
  'West Shore Elementary School',
  'West Shore Esd Career Tech Center',
  'West Shore Middle School',
  'West Shoreline Correctional Facility',
  'West Shores High',
  'West Side Ctc',
  'West Side El',
  'West Side El Sch',
  'West Side Elem Sch',
  'West Side Elementary',
  'West Side Elementary School',
  'West Side High School',
  'West Side High Scool',
  'West Side Leadership Academy',
  'West Side Middle School',
  'West Side Primary School',
  'West Side Rop',
  'West Side School',
  'West Side Senior High School',
  'West Side Summit Charter School',
  'West Side Valley High (continuation)',
  'West Sioux High School',
  'West Sioux Middle School',
  'West Smithfield Elementary',
  'West Smiths Station Elementary School',
  'West Snyder El Sch',
  'West Somerville Neighborhood',
  'West Sonoma County Consortium',
  'West Sound Technical Skills Center',
  'West Springfield Elementary',
  'West Springfield High',
  'West Springfield Middle',
  'West St. John Elementary School (k-7)',
  'West St. John High School',
  'West St. Mary High School',
  'West Stafford School',
  'West Stanly High',
  'West Stanly Middle School',
  'West Stokes High',
  'West Street',
  'West Street Elementary',
  'West Street Elementary School',
  'West Sutherlin Intermediate',
  'West Sylvan Middle School',
  'West Tallahatchie High School',
  'West Tampa Elementary School',
  'West Tatnuck',
  'West Taylor Elementary School',
  'West Teays Elementary',
  'West Technical Education Center',
  'West Tennessee School For Deaf',
  'West Terrace Elementary School',
  'West Texas A&M University',
  'West Texas El',
  'West Texas H S',
  'West Texas Middle',
  'West Thibodaux Middle School',
  'West Tisbury Elementary',
  'West Town Elementary School',
  'West Towson Elementary',
  'West Transition',
  'West Tualatin View Elementary School',
  'West Union Attendance Center',
  'West Union Elementary School',
  'West Union High School',
  'West University Charter High',
  'West University El',
  'West University of Timisoara',
  'West Utica Elementary School',
  'West Valley Central School',
  'West Valley City School',
  'West Valley College',
  'West Valley Early Learning Center',
  'West Valley Elementary',
  'West Valley High',
  'West Valley High School',
  'West Valley High School Freshman Campus',
  'West Valley Jr High',
  'West Valley Middle School',
  'West Valley Preschool',
  'West Valley School',
  'West Valley Virtual Academy 7-8',
  'West Valley Virtual Academy 9-12',
  'West Valley Virtual Academy K-6',
  'West Vernon Avenue Elementary',
  'West View El Sch',
  'West View Elem School',
  'West View Elementary',
  'West View Elementary School',
  'West View Middle School',
  'West View School',
  'West Vigo Elementary School',
  'West Vigo High School',
  'West Vigo Middle School',
  'West Village Academy',
  'West Villages Elementary School',
  'West Vincent El Sch',
  'West Vine Street School',
  'West Virginia Northern Community College',
  'West Virginia School For The Blind',
  'West Virginia School For The Deaf-elem',
  'West Virginia School For The Deaf-second',
  'West Virginia University',
  'West Virginia University at Parkersburg',
  'West Virginia Wesleyan College',
  'West Visayas State University',
  'West Ward El',
  'West Ward Elementary School',
  'West Warwick High School',
  'West Washington Elementary School',
  'West Washington Jr-sr High School',
  'West Weber School',
  'West Wendover Elementary School',
  'West Wendover High School',
  'West Wendover Junior High School',
  'West Whitesburg Elementary School',
  'West Whittier Elementary',
  'West Wilkes High',
  'West Wilkes Middle',
  'West Wilson Middle School',
  'West Wind Elementary',
  'West Windsor-plainsboro High School North',
  'West Windsor-plainsboro High School South',
  'West Wing Elementary',
  'West Woodland Elementary School',
  'West Woods Elementary School',
  'West Woods School',
  'West Woods Upper Elementary School',
  'West Wortham Elementary And Middle',
  'West Yadkin Elementary',
  'West Yellowstone 7-8',
  'West Yellowstone Hs',
  'West Yellowstone School',
  'West York Area Hs',
  'West York Area Ms',
  'West Zephyrhills Elementary School',
  'West-bemis Middle School',
  'West-mec - Agua Fria High School',
  'West-mec - Apollo High School',
  'West-mec - Barry Goldwater High School',
  'West-mec - Boulder Creek High School',
  'West-mec - Buckeye Union High School',
  'West-mec - Cactus High School',
  'West-mec - Centennial High School',
  'West-mec - Central Campus',
  'West-mec - Copper Canyon High School',
  'West-mec - Cortez High School',
  'West-mec - Deer Valley High School',
  'West-mec - Desert Edge High School',
  'West-mec - Dysart High School',
  'West-mec - Estrella Foothills High School',
  'West-mec - Glendale High School',
  'West-mec - Greenway High School',
  'West-mec - Horizon High School',
  'West-mec - Independence High School',
  'West-mec - Ironwood High School',
  'West-mec - La Joya High School',
  'West-mec - Liberty High School',
  'West-mec - Metrocenter Academy',
  'West-mec - Millennium High School',
  'West-mec - Moon Valley High School',
  'West-mec - Mountain Ridge High School',
  'West-mec - North Canyon High School',
  'West-mec - Paradise Valley High School',
  'West-mec - Peoria High School',
  'West-mec - Peoria Transition Center',
  'West-mec - Pinnacle High School',
  'West-mec - Raymond S. Kellis',
  "West-mec - Sandra Day O'connor High School",
  'West-mec - Shadow Mountain High School',
  'West-mec - Shadow Ridge High School',
  'West-mec - Sierra Linda High School',
  'West-mec - Sunnyslope High School',
  'West-mec - Sunrise Mountain High School',
  'West-mec - Thunderbird High School',
  'West-mec - Tolleson Union High School',
  'West-mec - Tonopah Valley High School',
  'West-mec - Valley Vista High School',
  'West-mec - Verrado High School',
  'West-mec - Virtual Learning Academy',
  'West-mec - Washington High School',
  'West-mec - West View High School',
  'West-mec - Wickenburg High School',
  'West-mec - Willow Canyon High School',
  'West-mec - Youngker High School',
  'West-oak High',
  'Westampton Campus',
  'Westampton Township Middle School',
  'Westar Elementary School',
  'Westarea Elementary',
  'Westark Technical Center',
  'Westbank Community School',
  'Westborough High',
  'Westborough Middle',
  'Westbriar Elementary',
  'Westbrook Early Learning Cntr',
  'Westbrook Elementary',
  'Westbrook Elementary School',
  'Westbrook High School',
  'Westbrook Int',
  'Westbrook Middle School',
  'Westbrook Park El Sch',
  'Westbrook Regional Technology Ctr',
  'Westbrook School',
  'Westbrook-walnut Grove Secondary',
  'Westbrooke Elementary',
  'Westbrooke Village',
  'Westbury H S',
  'Westbury High School',
  'Westbury Middle School',
  'Westby 7-8',
  'Westby Elementary',
  'Westby High',
  'Westby High School',
  'Westby Middle',
  'Westby School',
  'Westchase Elementary School',
  'Westchester Academy For International Studies',
  'Westchester Boces',
  'Westchester Elem.',
  'Westchester Elementary',
  'Westchester Elementary School',
  'Westchester Intermediate School',
  'Westchester Middle School',
  'Westchester Primary School',
  'Westchester Secondary Charter',
  'Westchester Square Academy',
  'Westcliff El',
  'Westcliffe Elementary',
  'Westcott Elem School',
  'Westcreek El',
  'Westdale Elem School',
  'Westdale Elementary School',
  'Westdale Heights Academic Magnet School',
  'Westdale Middle School',
  'Wester El',
  'Wester Middle',
  'Westerly Creek Elementary',
  'Westerly Elementary School',
  'Westerly High School',
  'Westerly Hills Academy',
  'Westerly Inclusion Preschool P',
  'Westerly Middle School',
  'Western Academy Charter School',
  'Western Alamance High',
  'Western Albemarle High',
  'Western Area Ctc',
  'Western Avenue Elem School',
  'Western Avenue Elementary',
  'Western Barry Elem',
  'Western Beaver Co Jshs',
  'Western Boone Jr-sr High School',
  'Western Branch High',
  'Western Branch Intermediate',
  'Western Branch Middle',
  'Western Branch Primary',
  'Western Brown High School',
  'Western Carolina University',
  'Western Center Academy',
  'Western Connecticut State University',
  'Western Coventry School',
  'Western Ct Academy Of International Studies Elementary Magn',
  'Western Dakota Technical Institute',
  'Western Day Treatment',
  'Western Delta University',
  'Western Dubuque High School',
  'Western Elementary School',
  'Western Galilee College',
  'Western Governors University',
  'Western Greenbrier Middle School',
  'Western Grove Elem. School',
  'Western Grove High School',
  'Western Grove K-12 School',
  'Western Guilford High',
  'Western Guilford Middle',
  'Western Harnett High',
  'Western Harnett Middle',
  'Western Heights 9th Grade Ctr',
  'Western Heights Elementary School',
  'Western Heights Hs',
  'Western Heights Middle',
  'Western Heights Ms',
  'Western High',
  'Western High School',
  'Western Hills El',
  'Western Hills Elem. School',
  'Western Hills Elementary School',
  'Western Hills H S',
  'Western Hills High School',
  'Western Hills Magnet Center',
  'Western Hills Middle School',
  'Western Hills Pri',
  'Western Hills School',
  'Western Hills University High School',
  'Western Illinois University',
  'Western Intermediate School',
  'Western International High School',
  'Western Iowa Tech Community College',
  'Western Jr High School',
  'Western Kazakhstan Agricultural University',
  'Western Kentucky University',
  'Western Ky Education Center',
  'Western Learning Center',
  'Western Michigan University',
  'Western Middle',
  'Western Middle School',
  'Western Middle School For The Arts',
  'Western Mindanao State University',
  'Western Mo Correctional Center',
  'Western Montana College',
  'Western Montgomery Ctc',
  'Western Nebrask Child Dev Center (wncdc)',
  'Western Nebraska Community College',
  'Western New England College',
  'Western New Mexico University',
  'Western New York Maritime Charter School',
  'Western Nm Correction',
  'Western Oaks Es',
  'Western Oaks Ms',
  'Western Oklahoma State College',
  'Western Peaks Elementary',
  'Western Piedmont Community College',
  'Western Pines Community Middle',
  'Western Plains High',
  'Western Plains North Elem',
  'Western Plains South Elem/jr High',
  'Western Plateau El',
  'Western Primary',
  'Western Primary School',
  'Western Psychiatric Center',
  'Western Recept/diag Corr Centr',
  'Western Region Treatment Centr',
  'Western Reserve Elementary School',
  'Western Reserve High School',
  'Western Reserve Middle School',
  'Western Reserve Preschool',
  'Western Rockingham Middle',
  'Western Row Elementary School',
  'Western Salisbury El Sch',
  'Western School',
  'Western School Of Science And Technology',
  'Western School Of Technology & Env. Science',
  'Western Sierra Collegiate Academy',
  'Western Sky Middle School',
  'Western St. Hosp. Ed. Pr.',
  'Western State Colorado University',
  'Western Suffolk Boces',
  'Western Technical College',
  'Western Texas College',
  'Western Trails Elem School',
  'Western Union Elementary',
  'Western University',
  'Western Valley Elementary School',
  'Western Valley Middle School',
  'Western Washington University',
  'Western Wayne Elementary School',
  'Western Wayne Hs',
  'Western Wayne Ms',
  'Western Wyoming Community College',
  'Western Yell Co. Elem. School',
  'Western Yell Co. High School',
  'Westernport Elementary',
  'Westerville Central High School',
  'Westerville-north High School',
  'Westerville-south High School',
  'Westfall Elementary School',
  'Westfall High School',
  'Westfall Middle School',
  'Westfield Achievement School',
  'Westfield Area El Sch',
  'Westfield Area High',
  'Westfield Area Middle',
  'Westfield Community School',
  'Westfield Elem School',
  'Westfield Elementary',
  'Westfield Elementary School',
  'Westfield H S',
  'Westfield High',
  'Westfield High School',
  'Westfield Intermediate School',
  'Westfield Middle School',
  'Westfield School',
  'Westfield Senior High School',
  'Westfield State College',
  'Westfield Technical Academy',
  'Westfield Village Elementary',
  'Westfield/robinson Achievement',
  'Westford Academy',
  'Westford Elementary School',
  'Westfälische Wilhelms-Universität Münster',
  'Westgate Alternative Elementary School',
  'Westgate Charter',
  'Westgate Elem School',
  'Westgate Elementary',
  'Westgate Elementary School',
  'Westgate High School',
  'Westgate Middle School',
  'Westglades Middle School',
  'Westhampton Beach Elementary School',
  'Westhampton Beach Senior High School',
  'Westhampton Elementary School',
  'Westhampton Middle School',
  'Westhaven Elementary',
  'Westhill Elementary',
  'Westhill High School',
  'Westhill University',
  'Westhills Elementary',
  'Westhills Elementary School',
  'Westhoff El',
  'Westhoff Elem.',
  'Westhope Elementary School',
  'Westhope High School',
  'Westinghouse Arts Academy Cs',
  'Westinghouse High School',
  'Westlake',
  'Westlake Academy',
  'Westlake Alter',
  'Westlake Charter',
  'Westlake Elementary',
  'Westlake Elementary School',
  'Westlake H S',
  'Westlake High',
  'Westlake High School',
  'Westlake Hills Elementary',
  'Westlake Middle',
  'Westlake Middle School',
  'Westlake Ms',
  'Westland Hialeah Senior High School',
  'Westland High School',
  'Westland Middle',
  'Westland School',
  'Westland School Brighton Campus',
  'Westlane Middle School',
  'Westlawn El',
  'Westlawn Elementary',
  'Westlawn Elementary School',
  'Westlawn Middle School',
  'Westmar Middle',
  'Westmeade Elementary',
  'Westmere Elementary School',
  'Westminster Academy For International Studies',
  'Westminster Avenue Elementary',
  'Westminster College',
  'Westminster College of Salt Lake City',
  'Westminster Community Charter School',
  'Westminster East Middle',
  'Westminster Elementary',
  'Westminster Elementary School',
  'Westminster High',
  'Westminster High School',
  'Westminster International University in Tashkent',
  'Westminster Public Schools Early Learning Center',
  'Westminster Schools',
  'Westminster Theological Seminary',
  'Westminster West Middle',
  'Westmont College',
  'Westmont Elementary',
  'Westmont Elementary School',
  'Westmont High',
  'Westmont High School',
  'Westmont Hilltop El Sch',
  'Westmont Hilltop Jshs',
  'Westmont Jr High School',
  'Westmoor Elem School',
  'Westmoor Elementary School',
  'Westmoor High',
  'Westmoor Middle School',
  'Westmoore Elementary',
  'Westmoore Hs',
  'Westmore Elem School',
  'Westmore Oaks Elementary',
  'Westmore School',
  'Westmoreland County Community College',
  'Westmoreland Elem',
  'Westmoreland Elementary',
  'Westmoreland Elementary School',
  'Westmoreland High School',
  'Westmoreland Middle School',
  'Westmoreland Road Elementary School',
  'Westmoreland School',
  'Westmorland Elementary',
  'Weston Elementary',
  'Weston Elementary School',
  'Weston High',
  'Weston High School',
  'Weston Intermediate School',
  'Weston Middle',
  'Weston Middle School',
  'Weston Preparatory Academy',
  'Weston Ranch High',
  'Weston School',
  'Weston-mcewen High School',
  'Westonka Sp Ed Ecfe',
  'Westorchard School',
  'Westover Elementary',
  'Westover High',
  'Westover High School',
  'Westover Hills Elementary',
  'Westover Middle',
  'Westover Park J H',
  'Westover School',
  'Westowne Elementary',
  'Westpark El',
  'Westpark Elementary',
  'Westpark Elementary School',
  'Westphalia',
  'Westphalia El',
  'Westpine Middle School',
  'Westpointe Elementary',
  'Westport Academy',
  'Westport Central School',
  'Westport Elem.',
  'Westport Elementary',
  'Westport Heights Elementary',
  'Westport Junior/senior High School',
  'Westport Middle',
  'Westport Middle School',
  'Westport Tapp',
  'Westran Elem.',
  'Westran High',
  'Westran Middle',
  'Westridge Early Ed Center',
  'Westridge Elem.',
  'Westridge Elementary',
  'Westridge Elementary School',
  'Westridge Middle',
  'Westridge Middle School',
  'Westridge School',
  'Westshire Elementary School',
  'Westshore Elementary School',
  'Westshore Junior/senior High School',
  'Westside Academy',
  'Westside Academy At Blodgett',
  'Westside Achievement Middle School',
  'Westside Alternative Program',
  'Westside Atlanta Charter School',
  'Westside Community Day Middle High',
  'Westside El',
  'Westside Elem',
  'Westside Elementary',
  'Westside Elementary - 03',
  'Westside Elementary School',
  'Westside Es',
  'Westside Global Awareness Magnet',
  'Westside H S',
  'Westside High',
  'Westside High School',
  'Westside High School West Campus',
  'Westside Innovative School House',
  'Westside Innovative School House Charter Middle',
  'Westside Intermediate',
  'Westside Junior High School',
  'Westside Junior-senior High School',
  'Westside K-8 School',
  'Westside Middle School',
  'Westside Middle School Academy',
  'Westside Park Elementary',
  'Westside Preparatory Charter',
  'Westside Primary',
  'Westside School',
  'Westside Union Elementary',
  'Westside Village Magnet School At Kingston Elementary School',
  'Westsächsische Hochschule Zwickau (FH)',
  'Westtown-thornbury El Sch',
  'Westvale School',
  'Westview Early Childhood Education Center',
  'Westview Elem',
  'Westview Elem School',
  'Westview Elem.',
  'Westview Elementary',
  'Westview Elementary School',
  'Westview High',
  'Westview High School',
  'Westview Hills Middle School',
  'Westview Jr-sr High School',
  'Westview K-8',
  'Westview Middle',
  'Westview Middle School',
  'Westview Primary',
  'Westville Elementary School',
  'Westville Es',
  'Westville High School',
  'Westville Hs',
  'Westville Jhs',
  'Westville Jr High School',
  'Westward Elementary School',
  'Westwind El',
  'Westwind Elementary School',
  'Westwind Intermediate School - Closed',
  'Westwood Alternative Education High School North',
  'Westwood Charter',
  'Westwood Charter Elementary',
  'Westwood Cyber High School',
  'Westwood El',
  'Westwood El.',
  'Westwood Elem',
  'Westwood Elem School',
  'Westwood Elementary',
  'Westwood Elementary School',
  'Westwood Es',
  'Westwood H S',
  'Westwood Heights Elementary School',
  'Westwood High',
  'Westwood High School',
  'Westwood Hills Elementary',
  'Westwood Integrated Preschool',
  'Westwood Intermediate School',
  'Westwood J H',
  'Westwood Junionr/senior High School',
  'Westwood Math Science Leadership Magnet',
  'Westwood Middle',
  'Westwood Middle School',
  'Westwood Prek-6 School',
  'Westwood Preparatory Academy',
  'Westwood Preschool',
  'Westwood Pri',
  'Westwood Primary School',
  'Westwood Regional Middle School',
  'Westwood Rural Elementary - 05',
  'Westwood Terrace El',
  'Westwood View Elem',
  'Westwoods Elementary School',
  'Wetherbee Elementary',
  'Wethersfield Elem School',
  'Wethersfield High School',
  'Wethersfield Jr/sr High School',
  'Wetmore El',
  'Wetmore Elementary',
  'Wetmore High',
  'Wettengel Elementary School',
  'Wetumka Es',
  'Wetumka Hs',
  'Wetumpka Elementary School',
  'Wetumpka High School',
  'Wetumpka Middle School',
  'Wetzel Co. Ctr. For Children & Families',
  'Wewahitchka Elementary School',
  'Wewahitchka High School',
  'Wewoka Es',
  'Wewoka Hs',
  'Wewoka Ms',
  'Wexford El Sch',
  'Wexford Montessori Magnet School',
  'Wexfordmissaukee Isd Career Center',
  'Wexfordmissaukee Isd Special Education',
  'Wexler/grant Community School',
  'Weyanoke Elementary',
  'Weyauwega Elementary',
  'Weyauwega High',
  'Weyauwega Middle',
  'Weybridge Elementary School',
  'Weyerhaeuser Elementary',
  'Weymouth Child Development Center',
  'Weymouth High School',
  'Weymouth Township Elementary School',
  'Wh Ford H S',
  'Whale Branch Early College High',
  'Whale Branch Elementary',
  'Whale Branch Middle',
  'Whale Gulch Elementary',
  'Whale Gulch High',
  'Whale Pass School',
  'Whaley Middle',
  'Whaley School',
  'Whaling City Junior/senior High School',
  'Wharton County Junior College',
  'Wharton El',
  'Wharton H S',
  'Wharton High School',
  'Wharton J H',
  'Wharton K-8 Dual Language Academy',
  'Wharton Sch',
  'Whatcom Co Detention Ctr',
  'Whatcom Community College',
  'Whatcom Middle School',
  'Whately Elementary',
  'Wheat Ridge High School',
  'Wheatfield Elementary School',
  'Wheatland Center Elementary',
  'Wheatland Charter Academy',
  'Wheatland Colony School',
  'Wheatland Community Day High',
  'Wheatland Elem',
  'Wheatland Elem.',
  'Wheatland Elementary',
  'Wheatland Elementary School',
  'Wheatland High',
  'Wheatland High School',
  'Wheatland Middle School',
  'Wheatland Ms',
  'Wheatland Union High',
  'Wheatland-chili High School',
  'Wheatley Alternative Education Center',
  'Wheatley Ec',
  'Wheatley Elementary',
  'Wheatley H S',
  'Wheatley Middle',
  'Wheatley School',
  'Wheatley School Of Early Childhood Programs',
  'Wheatmore High',
  'Wheaton College',
  'Wheaton College Norton MA',
  'Wheaton Elem.',
  'Wheaton High',
  'Wheaton North High School',
  'Wheaton Secondary',
  'Wheaton Warrenville South H S',
  'Wheaton Woods Elementary',
  'Wheatridge Middle School',
  'Wheeler Avenue School',
  'Wheeler Central Elementary #45',
  'Wheeler Central High',
  'Wheeler County Elementary School',
  'Wheeler County High School',
  'Wheeler County Middle School',
  'Wheeler Elementary',
  'Wheeler Elementary School',
  'Wheeler Es',
  'Wheeler High',
  'Wheeler High School',
  'Wheeler Middle',
  'Wheeler School',
  'Wheelersburg Elementary School',
  'Wheelersburg High School',
  'Wheelersburg Middle School',
  'Wheelerville School',
  'Wheeless Road Elementary School',
  'Wheeling Elementary School',
  'Wheeling High School',
  'Wheeling Jesuit College',
  'Wheeling Middle School',
  'Wheeling Park High School',
  'Wheelock El',
  'Wheelock Elementary School',
  'Wherry Elementary',
  'Whetstone Elementary',
  'Whetstone High School',
  'Whidby El',
  'Whiddon Rodgers Education Center',
  'Whigham Elementary School',
  'Whin Music Community Charter School',
  'Whippany Park High School',
  'Whipple Heights Elementary School',
  'Whipple Ranch Elementary School',
  'Whisconier Middle School',
  'Whispering Forest Elementary School',
  'Whispering Hills Achievement Center',
  'Whispering Meadow Elementary Sch',
  'Whispering Oak Elementary',
  'Whispering Pines El',
  'Whispering Pines Elementary School',
  'Whispering Pines Exceptional Education Center',
  'Whispering Wind Academy',
  'Whispering Winds Charter School',
  'Whistler Elem School',
  'Whit Davis Road Elementary School',
  'Whitaker El',
  'Whitaker Elementary',
  'Whitaker Elementary School',
  'Whitcomb Continuation High',
  'Whitcomb Elementary School',
  'Whitcomb Junior/senior High School',
  'White Bear Area Learning Center',
  'White Bear Lake District Center',
  'White Bear Lake North Campus Alc',
  'White Bear North Campus Senior',
  'White Bear South Campus Senior',
  'White Bear Transition Plus Program',
  'White Bluff Elementary',
  'White Bluff Elementary School',
  'White Bluffs Elementary School',
  'White Brook Middle School',
  'White Castle High School',
  'White Center Heights Elementary',
  'White Church Elem',
  'White City Elem',
  'White City Elementary School',
  'White City High',
  'White Cliffs Middle School',
  'White Cloud Elementary School',
  'White Cloud High School',
  'White Cloud Junior High School',
  'White Co Middle School',
  'White Co. Central Elem. School',
  'White Co. Central High School',
  'White Cone - Inactive',
  'White Cone High School',
  'White County 9th Grade Academy',
  'White County High School',
  'White County Middle School',
  'White Deer El',
  'White Deer El Sch',
  'White Deer H S',
  'White E El',
  'White Eagle Elementary',
  'White Earth Tribal and Community College',
  'White Elem',
  'White Elem Career Academy',
  'White Elementary',
  'White Elementary School',
  'White Hall Elementary School',
  'White Hall High School',
  'White Hall Junior High School',
  'White Heath Elem School',
  'White Hill Middle',
  'White Hills Elementary School',
  'White House Heritage Elementary School',
  'White House Heritage High School',
  'White House High School',
  'White House Middle School',
  'White J H',
  'White Knoll Elementary',
  'White Knoll High',
  'White Knoll Middle',
  'White Lake Area Community Education',
  'White Lake Elementary',
  'White Lake Elementary - 02',
  'White Lake High',
  'White Lake High School - 01',
  'White Lake Jr. High - 03',
  'White Lake Middle School',
  'White Lick Elementary School',
  'White Marsh Elementary',
  'White Middle',
  'White Mountain Academy',
  'White Mountain Elementary',
  'White Mountain Institute',
  'White Mountain Middle School',
  'White Mountain School',
  'White Mountains Community College',
  'White Mountains Regional High School',
  'White Oak Elementary',
  'White Oak Elementary School',
  'White Oak H S',
  'White Oak High',
  'White Oak Int',
  'White Oak Middle',
  'White Oak Middle School',
  'White Oak Pri',
  'White Oak Public School',
  'White Oak School',
  'White Oaks Elementary',
  'White Pass Elementary School',
  'White Pass Jr. Sr. High School',
  'White Pigeon Jrsr High School',
  'White Pine Academy',
  'White Pine Charter School',
  'White Pine Elementary',
  'White Pine Elementary School',
  'White Pine High School',
  'White Pine Middle',
  'White Pine Middle School',
  'White Pine School',
  'White Pines Intermediate School',
  'White Plains Academy',
  'White Plains Elementary',
  'White Plains Elementary School',
  'White Plains High School',
  'White Plains Middle School',
  'White Plains Senior High School',
  'White Point Elementary',
  'White River Elementary - 02',
  'White River Elementary School',
  'White River High School',
  'White River High School - 01',
  'White River Homeschool',
  'White River Middle School - 04',
  'White River Reengagement Program',
  'White River School',
  'White River Special Ed Services',
  'White River Valley Elementary Sch',
  'White River Valley High School',
  'White River Valley Middle School',
  'White Rock Campus',
  'White Rock Colony Elementary - 03',
  'White Rock El',
  'White Rock Elem.',
  'White Rock Elementary',
  'White Rock Elementary School',
  'White Rock Public School',
  'White Salmon Academy',
  'White Sands Elementary',
  'White Settlement Disciplinary Campus',
  'White Shield Elementary School',
  'White Shield High School',
  'White Shield School',
  'White Station Elementary',
  'White Station High',
  'White Station Middle',
  'White Street',
  'White Sulphur Elementary',
  'White Sulphur Elementary School',
  'White Sulphur Springs 7-8',
  'White Sulphur Springs El',
  'White Sulphur Springs Hs',
  'White Swan Elem School',
  'White Swan High School',
  'White Tanks Learning Center',
  'White Township Consolidated School',
  "White's Jr-sr High School",
  "White's Tower Elementary School",
  'Whiteaker Middle School',
  'Whitebead Public School',
  'Whited Elementary Charter',
  'Whiteface El',
  'Whiteface School',
  'Whitefield Elementary School',
  'Whitefield Public School',
  'Whitefish Bay High',
  'Whitefish Bay Middle',
  'Whitefish High School',
  'Whitefish Middle 5-8',
  'Whitefish Township School',
  'Whitefoord Elementary School',
  'Whiteford (june) Special Education Preschool',
  'Whiteford Elementary School',
  'Whiteford High School',
  'Whiteford Middle School',
  'Whitehall 6-8',
  'Whitehall Ealy Elem School',
  'Whitehall El Sch',
  'Whitehall Elementary',
  'Whitehall Elementary School',
  'Whitehall High School',
  'Whitehall Hs',
  'Whitehall Junior-senior High School',
  'Whitehall Memorial Elementary',
  'Whitehall Memorial Junior/senior High',
  'Whitehall Middle School',
  'Whitehall Pre-k Center',
  'Whitehall Preparatory And Fitness Academy',
  'Whitehall Senior High School',
  'Whitehall Yearling High School',
  'Whitehall-coplay Ms',
  'Whitehaven Elementary',
  'Whitehaven High',
  'Whitehead Elem School',
  'Whitehead Road Elementary School',
  'Whitehills Elementary School',
  'Whitehorse High',
  'Whitehorse Middle',
  'Whitehouse A E P',
  'Whitehouse Elementary School',
  'Whitehouse H S',
  'Whitehouse Isd - Jjaep',
  'Whitehouse J H',
  'Whitehouse Primary School',
  'Whitehouse School',
  'Whiteland Community High School',
  'Whiteland Elementary School',
  'Whiteman A.f.b. Elem.',
  'Whitemarsh El Sch',
  'Whiteoak High School',
  'Whiteoak Junior High School',
  'Whiteriver Elementary',
  'Whites Creek High',
  'Whitesboro Elementary School',
  'Whitesboro Es',
  'Whitesboro H S',
  'Whitesboro High School',
  'Whitesboro Hs',
  'Whitesboro Intermediate',
  'Whitesboro Middle',
  'Whitesboro Middle School',
  'Whitesburg Elementary',
  'Whitesburg Elementary School',
  'Whitesburg Middle School',
  'Whitesburg P8',
  'Whiteside El',
  'Whiteside Elem School',
  'Whiteside Middle School',
  'Whitesides School',
  'Whitestone El',
  'Whitesville Central School',
  'Whitesville Elementary',
  'Whitesville Elementary School',
  'Whitesville Road Elementary School',
  'Whitetail Creek Elementary',
  'Whitethorn Elementary',
  'Whitethorn Elementary School',
  'Whiteville Elementary',
  'Whiteville High',
  'Whiteville Primary',
  'Whitewater 4k',
  'Whitewater 6-8',
  'Whitewater Academy',
  'Whitewater Career Center',
  'Whitewater High',
  'Whitewater High School',
  'Whitewater Middle',
  'Whitewater Middle School',
  'Whitewater School',
  'Whitewater Valley Elementary School',
  'Whitewood Elementary - 04',
  'Whitewright El',
  'Whitewright H S',
  'Whitewright Middle',
  'Whitfield El Sch',
  'Whitford Middle School',
  'Whitharral School',
  'Whitin Elementary School',
  'Whiting Elementary School',
  'Whiting High School',
  'Whiting Lane Learning Center',
  'Whiting Lane School',
  'Whiting Middle School',
  'Whiting Senior High School',
  'Whiting Sudbury Learning Academy',
  'Whiting Village School',
  'Whitko High School',
  'Whitko Middle School',
  'Whitley Central Intermediate School',
  'Whitley City Elementary School',
  'Whitley County Alternative School',
  'Whitley County Central Primary School',
  'Whitley County East Elementary School',
  'Whitley County High School',
  'Whitley County Middle School',
  'Whitley County North Elementary School',
  'Whitley Elementary School',
  'Whitley Road El',
  'Whitman',
  'Whitman College',
  'Whitman Continuation',
  'Whitman Elementary',
  'Whitman Elementary School',
  'Whitman Es',
  'Whitman Hanson Regional',
  'Whitman Middle',
  'Whitman Middle School',
  'Whitman Post Elem School',
  'Whitmer High School',
  'Whitmire Community School (elementary)',
  'Whitmire Community School (high)',
  'Whitmore Charter High',
  'Whitmore Charter School Of Art & Technology',
  'Whitmore Charter School Of Personalized Learning',
  'Whitmore Elementary',
  'Whitmore Lake Early Childhood',
  'Whitmore Lake Elementary School',
  'Whitmore Lake High School',
  'Whitmorebolles Elementary School',
  'Whitnall High',
  'Whitnall Middle',
  'Whitnel Elementary',
  'Whitney (gretchen) High',
  'Whitney Achievement Elementary School',
  'Whitney Alternative Education Center',
  'Whitney Avenue Elementary',
  'Whitney E. Houston Academy',
  'Whitney El',
  'Whitney Elem School',
  'Whitney Elementary Anacortes',
  'Whitney Elementary School',
  'Whitney Elementary Yakima',
  'Whitney H S',
  'Whitney High',
  'Whitney Int',
  'Whitney M Young Early Childhood',
  'Whitney M Young Jr El',
  'Whitney M. Young Jr. School',
  'Whitney Middle',
  'Whitney Point Senior High School',
  'Whitney Young Continuation',
  'Whitney Young School',
  'Whiton Elementary School',
  'Whitson Elem',
  'Whitstran Elementary',
  'Whittaker Elementary',
  'Whittemore Park Middle',
  'Whittemoreprescott Area Elementary',
  'Whittemoreprescott Jrsr High School',
  'Whitten Middle School',
  'Whitthorne Middle School',
  'Whittier',
  'Whittier Area Community Day',
  'Whittier College',
  'Whittier Community School',
  'Whittier Ec',
  'Whittier El',
  'Whittier Elem',
  'Whittier Elem School',
  'Whittier Elem.',
  'Whittier Elementary',
  'Whittier Elementary School',
  'Whittier Es',
  'Whittier High',
  'Whittier Independent Study',
  'Whittier International',
  'Whittier K-12',
  'Whittier K-8 School',
  'Whittier Middle',
  'Whittier Middle School - 08',
  'Whittier Ms',
  'Whittier Primary School',
  'Whittier Regional Vocational',
  'Whittier School',
  'Whittle Springs Middle School',
  'Whitwell Elementary',
  'Whitwell High School',
  'Whitwell Middle School',
  'Whitworth College',
  'Whitworth Elementary School',
  'Whitworth-buchanan Middle School',
  'Whole Grade Sharing With Burke - 04',
  'Whs Information Technology',
  'Wi Ctr For The Blind & Visually Impaired',
  'Wibaux 7-8',
  'Wibaux Elementary School',
  'Wibaux High School',
  'Wichita Alternative High',
  'Wichita Area Technical College',
  'Wichita Co Jjaep',
  'Wichita County Elementary',
  'Wichita County J J A E P',
  'Wichita County Junior-senior High School',
  'Wichita County Juvenile Justice Aep',
  'Wichita Falls H S',
  'Wichita Learning Center',
  'Wichita State University',
  'Wichita Virtual School',
  'Wick Elementary School',
  'Wickenburg High School',
  'Wickenburg Virtual Academy',
  'Wickersham El Sch',
  'Wickes Elementary School',
  'Wickes School',
  'Wickford Middle School',
  'Wickham Elementary',
  'Wickliffe Elementary School',
  'Wickliffe High School',
  'Wickliffe Middle School',
  'Wickliffe Public School',
  'Wicklow Elementary School',
  'Wicklund Elementary',
  'Wicksburg High School',
  'Wicomico County Evening High',
  'Wicomico High',
  'Wicomico Middle',
  'Wide Hollow Elementary',
  'Wide Ruins Community School',
  'Widefield District 3 Preschool',
  'Widefield Elementary School',
  'Widefield High School',
  'Widen El',
  'Widener Memorial Sch',
  'Widener Partnership Cs',
  'Widener University',
  'Widewater Elementary',
  'Widmyer Elementary',
  'Wiederstein El',
  'Wieland El',
  'Wiesbrook Elem School',
  'Wiggin Street Elementary School',
  'Wiggins Elementary School',
  'Wiggins High School',
  'Wiggins Middle School',
  'Wiggs Middle',
  'Wigwam Creek Middle School',
  'Wilbanks Middle School',
  'Wilber-clatonia High School',
  'Wilberforce University',
  'Wilbraham Middle',
  'Wilbur & Theresa Faiss Middle School',
  'Wilbur (kathleen H.) Elementary',
  'Wilbur And Mcmahon Schools',
  'Wilbur Charter For Enriched Academics',
  'Wilbur Cross High School',
  'Wilbur Cross School',
  'Wilbur D. Mills High School',
  'Wilbur E Lucas El',
  'Wilbur Elementary School',
  'Wilbur H Lynch Literacy Academy',
  'Wilbur Middle School',
  'Wilbur S. Pence Middle',
  'Wilbur Secondary School',
  'Wilbur Trimpe Middle School',
  'Wilbur Watts Intermediate School',
  'Wilbur Wright Elementary School',
  'Wilbur Wright Middle School',
  'Wilbur Wright School',
  'Wilburn Elementary',
  'Wilburton Es',
  'Wilburton Hs',
  'Wilburton Ms',
  'Wilby Esc',
  'Wilby High School',
  'Wilchester El',
  'Wilcox Central High School',
  'Wilcox County Alternative School',
  'Wilcox County Elementary School',
  'Wilcox County High School',
  'Wilcox County Middle School',
  'Wilcox Elem School',
  'Wilcox Elementary',
  'Wilcox Elementary School',
  'Wilcox-hildreth Elementary Sch',
  'Wilcox-hildreth High School',
  'Wilcox-hildreth Middle School',
  'Wilcoxson School',
  'Wild Horse Elem.',
  'Wild Peach El',
  'Wild Rose Elem School',
  'Wild Rose Elementary',
  'Wild Rose High',
  'Wildcat Mountain Elementary School',
  'Wilde Elementary School',
  'Wilde Lake High',
  'Wilde Lake Middle',
  'Wilder Elem.',
  'Wilder Elementary',
  'Wilder Elementary School',
  'Wilder High School',
  'Wilder Middle School',
  "Wilder's Preparatory Academy Charter",
  "Wilder's Preparatory Academy Charter Middle",
  'Wilder-waite Grade School',
  'Wilderness Elementary',
  'Wilderness Oak El',
  'Wildewood Elementary School',
  'Wildfire Elementary School',
  'Wildflower Elementary School',
  'Wildflower Open Classroom',
  'Wildflower School',
  'Wildlands Charter School',
  'Wildlight Elementary',
  'Wildomar Elementary',
  'Wildorado El',
  'Wildwood',
  'Wildwood El',
  'Wildwood Elem School',
  'Wildwood Elem.',
  'Wildwood Elementary',
  'Wildwood Elementary School',
  'Wildwood Environmental Academy',
  'Wildwood Forest Elementary',
  'Wildwood High School',
  'Wildwood Middle',
  'Wildwood Middle School',
  'Wildwood Middle/ High School',
  'Wileman Elementary',
  'Wiles Head Start',
  'Wiley Accel/enrichment',
  'Wiley Canyon Elementary',
  'Wiley Elem',
  'Wiley Elementary',
  'Wiley Elementary School',
  'Wiley Ford Primary School',
  'Wiley H. Bates Middle',
  'Wiley Junior-senior High School',
  'Wiley Magnet Middle',
  'Wiley Post Es',
  'Wilferth Elementary',
  'Wilfredo Lafuente Ortiz',
  'Wilfrid Laurier University',
  'Wilhelmina Henry Elementary',
  'Wilkerson Elementary',
  'Wilkerson Elementary School',
  'Wilkerson Int',
  'Wilkerson Middle School',
  'Wilkerson Traditional Elementary',
  'Wilkes Central High',
  'Wilkes Community College',
  'Wilkes Early College High',
  'Wilkes Elementary School',
  'Wilkes University',
  'Wilkes-barre Area Ctc',
  'Wilkesboro Elementary',
  'Wilkeson Elementary School',
  'Wilkins El Sch',
  'Wilkins Elementary',
  'Wilkins Elementary School',
  'Wilkinsburg Ms',
  'Wilkinsburg Shs',
  'Wilkinson County Elementary',
  'Wilkinson County Elementary School',
  'Wilkinson County High',
  'Wilkinson County High School',
  'Wilkinson County Middle School',
  'Wilkinson County Primary School',
  'Wilkinson Early Childhood Ctr.',
  'Wilkinson El',
  'Wilkinson Elementary School',
  'Wilkinson Gardens Elementary School',
  'Wilkinson Junior High School',
  'Wilkinson Middle',
  'Wilkinson Middle School',
  'Wilkshire School',
  'Will Beckley Elementary School',
  'Will C. Wood High',
  'Will C. Wood Middle',
  'Will Carleton Charter School Academy',
  'Will James Middle School',
  'Will L Lee School',
  'Will Rogers College Hs',
  'Will Rogers College Jhs',
  'Will Rogers Continuation',
  'Will Rogers Elementary',
  'Will Rogers Es',
  'Will Rogers Jhs',
  'Will Rogers Middle',
  'Will Rogers Ms',
  'Will Rogers Pre-k Ctr',
  'Will-moore Elementary School',
  'Willa Cather Elementary School',
  'Willacoochee Elementary School',
  'Willadsen Elementary',
  'Willagillespie Elementary School',
  'Willamette High School',
  'Willamette Leadership Academy',
  'Willamette Primary School',
  'Willamette University',
  'Willamina Elementary School',
  'Willamina High School',
  'Willamina Middle School',
  'Willapa Elementary',
  'Willapa Valley Middle-high',
  'Willard',
  'Willard (frances E.) Elementary',
  'Willard Avenue Pk-8 School',
  'Willard Central Elem.',
  'Willard East Elem.',
  'Willard Elem School',
  'Willard Elementary',
  'Willard Elementary School',
  'Willard Es',
  'Willard F. Payne Elementary',
  'Willard Frances E Sch',
  'Willard Grizzell Middle School',
  'Willard High',
  'Willard High School',
  'Willard Intermediate',
  'Willard Intermediate School',
  'Willard Middle',
  'Willard Middle School',
  'Willard Model Elementary',
  'Willard North Elem.',
  'Willard Orchard Hills Elem',
  'Willard School',
  'Willard Shambaugh Elementary Sch',
  'Willard South Elem.',
  'Willard/cybulski Correctional Institution',
  'Willards Elementary',
  'Willbern El',
  'Willcox Elementary School',
  'Willcox High School',
  'Willcox Middle School',
  'Willets Road School',
  'Willetts Middle School',
  'William & Marian Ghidotti High',
  'William & Mary Scherkenbach Elementary School',
  'William (bill) Roberts K-8 School',
  'William A Berkowitz Elementary',
  'William A Brummer Elementary School',
  'William A Carter School',
  'William A Pearson Elementary',
  'William A Welch Sr',
  'William A. Diggs Elementary',
  'William A. Miller Elementary School',
  'William A. Walton Elementary',
  'William Allen Hs',
  'William Allen Middle School',
  'William Allen School',
  'William Amos Hough High',
  'William Anderson Elementary',
  'William Annin Middle School',
  'William Appleby Elementary School',
  'William B Miller El',
  'William B Tecler Arts In Education',
  'William B Travis Acad/vangrd For Academically Tag',
  'William B Travis Acdmy/vngrd For Academically Tag',
  'William B Travis H S',
  'William B Travis World Language Academy',
  'William B Ward Elementary School',
  'William B. Bimat Elementary',
  'William B. Bristow Middle',
  'William B. Cruise Memorial School No. 11',
  'William B. Gibbs Jr.',
  'William B. Wade Elementary School',
  'William Beanes Elementary',
  'William Beye Elem School',
  'William Blount High School',
  'William Boyce Thompson School',
  'William Brooks Elementary',
  'William Bruce Elementary School',
  'William Bryant Elem.',
  'William Burnett Elementary',
  'William Byrd High',
  'William Byrd Middle',
  'William Byrne Elementary',
  'William C Abney Academy Elementary',
  'William C Abney Academy Middle School',
  'William C Bryant Elementary School',
  'William C Herrera El',
  'William C Jack School',
  'William C Keane Elementary School',
  'William C Munn School',
  'William C Schenck Elementary School',
  'William C. Mcginnis Middle School',
  'William C. Overfelt High',
  'William Callahan School',
  'William Campbell High',
  'William Chrisman High',
  'William Collier Elementary',
  'William Crawford Intermediate Schools',
  'William Cullen Bryant High School',
  'William Cullen Bryant School',
  'William D Miller Elem School',
  'William D Slider Middle',
  "William D'abate Elem. School",
  'William D. Boyce',
  'William D. Moseley Elementary School',
  'William Dandy Middle School',
  'William Davies Middle School',
  'William Dean Jr. Elementary School',
  'William E Burkett Multihandicapped Center',
  'William E Cottle School',
  'William E Deluca Jr Elementary School',
  'William E Ferron Elementary School',
  'William E Grady Career And Technical Education High School',
  'William E Miller Elementary',
  'William E Norris',
  'William E Orr Middle School',
  'William E Russell',
  'William E Severn Elementary School',
  'William E Sheehan',
  'William E Snyder Elementary',
  'William E Wylie El',
  'William E Young School',
  'William E. Fanning Elementary',
  'William E. Lancaster School',
  'William E. Tolman High',
  'William E. Waters Middle',
  'William Ellery Channing',
  'William Ellis Middle',
  'William F Loper Elementary School',
  'William F Murphy Elem School',
  'William F. Cooke Jr. Elementary',
  'William F. Halloran School No.22',
  'William F. Stanley Elementary School',
  'William Faria Elementary',
  'William Fegely Middle School',
  'William Finch',
  'William Fleming High',
  'William Floyd Elementary School',
  'William Floyd High School',
  'William Floyd Learning Center',
  'William Floyd Middle School',
  'William Ford Careertech Center',
  'William Ford Elementary School',
  'William Foster Elementary School',
  'William Fox Elementary',
  'William Frangus Elementary',
  'William G Bennett Elementary School',
  'William G Gravitt J H',
  'William G Mather Elementary School',
  'William G Vinal',
  'William G. Conkwright Elementary',
  'William G. Hasty- Sr. Elementary School',
  'William G. Jehue Middle',
  'William G. Paden Elementary',
  'William G. Rohrer Middle School',
  'William Garrison Elementary',
  'William Gay Ec Ctr',
  'William Gilbert College',
  'William Green Elementary',
  'William H Barkley Microsociety',
  'William H Barton Intermediate School',
  'William H Byrd Middle',
  'William H Golding Middle School',
  'William H Lincoln',
  'William H Natcher Elementary School',
  'William H Ohrenberger',
  'William H Owen Elementary',
  'William H Rowe School',
  'William H Seward Elementary School',
  'William H Taft Elementary School',
  'William H Taylor',
  'William H. Bashaw Elementary',
  'William H. Farquhar Middle',
  'William H. Frazier Elementary',
  'William H. Lehman Elementary School',
  'William H. Natcher Elementary',
  'William H. Ross Iii School',
  'William H. Ruffner Middle',
  'William H. Seward Elementary School',
  'William H. Taft Elementary School',
  'William H. Turner Technical Adult',
  'William H. Turner Technical Arts High School',
  'William H. Wetsel Middle',
  'William Harris Elem School',
  'William Hart Elementary School',
  'William Hatch Elem School',
  'William Hawley Atwell Law Academy',
  'William Henry Brazier Elementary School',
  'William Henry Burkhart Elementary',
  'William Henry Harrison High School',
  'William Henry Middle School',
  'William Henry Oliver Middle',
  'William Herbert Brewster Elementary School',
  'William Holliday Elem School',
  'William Holmes Mcguffey Elementary School',
  'William Hopkins Junior High',
  'William Howard Taft Elementary',
  'William Howard Taft University',
  'William J Berry Elementary School',
  'William J Butler School',
  'William J Clinton El',
  'William J Montford Iii Middle School',
  'William J Raglin Center',
  'William J Winkley El',
  'William J. (pete) Knight High',
  'William J. Clark Middle',
  'William J. Fischer Accelerated Academy',
  'William J. Johnston Community Day',
  'William J. Johnston Middle School',
  'William J. Mcginn Elementary School',
  'William James Middle School',
  'William Jefferson Clinton Middle',
  'William Jewell College',
  'William K. Moore Elementary School',
  'William Kaseberg Elementary',
  'William Kelley Elementary',
  'William Knight Elementary School',
  'William L Buck School',
  'William L Cabell El',
  'William L Dickinson High School',
  'William L Radney Elementary School',
  'William L Thompson School',
  'William Land Elementary',
  'William Lee Hastings El',
  'William Lenoir Middle',
  'William Lipscomb El',
  'William Lummis Elementary School',
  'William M Anderson El',
  'William M Colmer Middle School',
  'William M. Bass Elementary',
  'William M. Mcgarrah Elementary School',
  'William M. Metteer Elementary',
  'William M. Raines High School',
  'William M. Reeves Elementary',
  'William Mason Cooper Elementary',
  'William Mason High School',
  'William Mason School',
  'William Matthew Middle School',
  'William Mckinley',
  'William Mckinley Elementary',
  'William Mckinley Elementary School',
  'William Mckinley School 39',
  'William Memorial Elementary',
  'William Mendenhall Middle',
  'William Miller Memorial School',
  'William Mitchell',
  'William Mitchell College of Law',
  'William Monroe High',
  'William Monroe Middle',
  'William Monroe Rowlett Academy For Arts And Communication',
  'William Monroe Trotter',
  'William Moreno Junior High',
  'William Mulholland Middle',
  'William N. Deberry',
  'William Northrup Elementary',
  'William O Schaefer Elementary School',
  "William O'brien Middle School",
  'William O. Darby Jr. High Sch.',
  'William P Jarrett Middle School',
  'William P Lord High School',
  'William P Tatem Elementary School',
  'William P. Gorman/fort Banks Elementary',
  'William Paca Elementary',
  'William Paca Middle School',
  'William Paca/old Post Road Elementary',
  'William Paschall El',
  'William Paterson University',
  'William Penn College',
  'William Penn El Sch',
  'William Penn Elementary',
  'William Penn Ms',
  'William Penn School',
  'William Penn School 49',
  'William Penn Shs',
  'William Perry Elementary',
  'William Pinderhughes Elementary',
  'William Pitcher Junior High School',
  'William Pittaway Elementary',
  'William Prescott #38',
  'William R Boone High',
  'William R Burton',
  'William R Davie Elementary',
  'William R Davie Middle S.t.e.m. Academy',
  'William R Fallon',
  'William R Martin Middle School',
  'William R Sullivan Elementary School',
  'William R. Anton Elementary',
  'William R. Buckley Elementary',
  'William R. Peck School',
  'William R. Rouse Rop',
  'William R. Satz School',
  'William R. Teague Es',
  'William Rall School',
  'William Ramsay Elementary',
  'William Regnart Elementary',
  'William Rivera Betancourt (bo San Isidro Vocacional)',
  'William Roper Early Childhood Learning Center',
  'William S Covert School',
  'William S Greene',
  'William S Gussner Elementary School',
  'William S Guy Middle School',
  'William S Hackett Middle School',
  'William S Maxey Elementary',
  'William S. Baer School',
  'William S. Cohen School',
  'William S. Hart High',
  'William S. James Elementary',
  'William S. Talbot Elem School',
  'William Saroyan Elementary',
  'William Schmidt Environmental Center',
  'William Seach',
  'William Sheppard Middle',
  'William Sidney Mount Elementary School',
  'William Smith High School',
  'William Southern Elem.',
  'William Street School',
  'William Stribling El',
  'William T Brown Elementary',
  'William T Machan Elementary School',
  'William T Rogers Middle School',
  'William T. Barron Elementary School',
  'William T. Dwyer High School',
  'William T. Mcfatter Technical College',
  'William Tell Aggeler Opportunity High',
  'William Tell Elementary School',
  'William Tennent Hs',
  'William Thomas Middle School',
  'William Tyler Page Elementary',
  'William Tyson Elementary',
  'William V Wright Elementary School',
  'William Velasquez',
  'William W & Josephine Dorn Charter Community School',
  'William W Borden Elementary School',
  'William W Borden High School',
  'William W. Hall Academy',
  'William W. Orr Elementary',
  'William Walker Elementary School',
  'William Wells Brown Elementary',
  'William Wiley Elementary School',
  'William Winans Middle School',
  'William Winchester Elementary',
  'William Winsor School',
  'William Wirt Middle',
  'William Wood El',
  'William Woodruff School',
  'William Woods University',
  'William Workman High',
  'William Yates Elem.',
  'William and Catherine Booth College',
  'Williams',
  'Williams Academy',
  'Williams Avenue Elementary School',
  'Williams Bay Elementary',
  'Williams Bay High',
  'Williams Bay Middle',
  'Williams College',
  'Williams Educational Center',
  'Williams El',
  'Williams Elem.',
  'Williams Elementary',
  'Williams Elementary School',
  'Williams Elementary/middle School',
  'Williams Field High School',
  'Williams H S',
  'Williams Heights Elementary School',
  'Williams High School',
  'Williams Intermediate',
  'Williams Intermediate School',
  'Williams Junior High School',
  'Williams Junior/senior High',
  'Williams Magnet Elem. School',
  'Williams Medical Prep High Sch',
  'Williams Middle',
  'Williams Middle Magnet School',
  'Williams Primary Elementary',
  'Williams Ranch Elementary',
  'Williams School',
  'Williams Science And Fine Arts Magnet School',
  'Williams Township',
  'Williams Township El Sch',
  'Williams Upper Elementary',
  'Williams Valley El Sch',
  'Williams Valley Jshs',
  'Williams-cone School',
  'Williams-sullivan Elementary',
  'Williamsburg Charter High School',
  'Williamsburg City School',
  'Williamsburg Collegiate Charter School',
  'Williamsburg Community El Sch',
  'Williamsburg Community Jshs',
  'Williamsburg Elem School',
  'Williamsburg Elem.',
  'Williamsburg Elementary',
  'Williamsburg Elementary School',
  'Williamsburg High School',
  'Williamsburg High School For Architecture And Design',
  'Williamsburg High School For Art And Technology',
  'Williamsburg Jr-sr High School',
  'Williamsburg Middle',
  'Williamsburg Preparatory School',
  'Williamsburg Technical College',
  'Williamsfield Elem School',
  'Williamsfield High School',
  'Williamsfield Middle School',
  'Williamson Co Academy',
  'Williamson Co J J A E P',
  'Williamson Co Jjaep',
  'Williamson Co Juvenile Detention Center',
  'Williamson Co Learning Cntr',
  'Williamson County Academy',
  'Williamson County Core-jjaep',
  'Williamson County Core/jjaep',
  'Williamson County Detention Center',
  'Williamson County Jjaep',
  'Williamson County Juvenile Detention',
  'Williamson County Juvenile Detention Center',
  'Williamson Elementary',
  'Williamson Elementary School',
  'Williamson Middle School',
  'Williamson Pre K-8',
  'Williamson Senior High School',
  'Williamson Shs',
  'Williamsport Area Ms',
  'Williamsport Area Shs',
  'Williamsport Elementary',
  'Williamsport Elementary School',
  'Williamsport High',
  'Williamston Discovery Elementary School',
  'Williamston Explorer Elementary School',
  'Williamston High School',
  'Williamston Middle School',
  'Williamston Primary',
  'Williamstown Elementary',
  'Williamstown Elementary School',
  'Williamstown High School',
  'Williamstown Jr. High',
  'Williamstown Middle School',
  'Williamstown Middle/high School',
  'Williamstown Preschool Headstart',
  'Williamstown Sr. High',
  'Williamsville East High School',
  'Williamsville Elem.',
  'Williamsville High School',
  'Williamsville Jr High School',
  'Williamsville Middle School',
  'Williamsville North High School',
  'Williamsville South High School',
  'Willie & Coy Payne Jr. High',
  'Willie Brown El',
  'Willie E Williams El',
  'Willie J. Williams Middle School',
  'Williford Elementary',
  'Willingboro High School',
  'Willingboro Memorial Middle School',
  'Willink Middle School',
  'Willis A. Jenkins Elementary',
  'Willis C Adams Middle School',
  'Willis E Thorpe',
  'Willis Elementary',
  'Willis Foreman Elementary School',
  'Willis H S',
  'Willis H. Justice Elementary School',
  'Willis Hare Elementary',
  'Willis Jepson Middle',
  'Willis Junior High School',
  'Willis Lane El',
  'Willis Peters Exceptional Center',
  'Willis Road Elementary',
  'Willis Shaw Elementary Sch',
  'Williston Elementary School',
  'Williston High School',
  'Williston Middle',
  'Williston Middle High School',
  'Williston Middle School',
  'Williston Schools',
  'Williston State College',
  'Williston-elko High',
  'Williston-elko Middle',
  'Willits Charter',
  'Willits Elementary Charter',
  'Willits Elementary School',
  'Willits High',
  'Williwaw Elementary',
  'Willma Cavitt Junior High',
  'Willmar Area Learning Center',
  'Willmar Middle School',
  'Willmar Senior High',
  'Willmar Voluntary Pre-k',
  'Willmore Elementary',
  'Willoughby Early Childhood Center',
  'Willoughby House',
  'Willoughby Learning Center',
  'Willoughby Middle School',
  'Willoughby-eastlake Preschool',
  'Willow Avenue Elementary School',
  'Willow Bank Colony School',
  'Willow Bend El',
  'Willow Bend Elem School',
  'Willow Brook Elem.',
  'Willow Brook Elementary',
  'Willow Brook Es',
  'Willow Canyon High School',
  'Willow Canyon School',
  'Willow Charter Academy',
  'Willow Community Day',
  'Willow Cove Elementary',
  'Willow Creek 7-8',
  'Willow Creek Academy',
  'Willow Creek Charter School',
  'Willow Creek El',
  'Willow Creek El Sch',
  'Willow Creek Elem School',
  'Willow Creek Elementary',
  'Willow Creek Elementary School',
  'Willow Creek High School',
  'Willow Creek Intr. Elementary',
  'Willow Creek Middle',
  'Willow Creek School',
  'Willow Crest Elementary',
  'Willow Dale El Sch',
  'Willow Drive Elementary',
  'Willow Elem School',
  'Willow Elementary',
  'Willow Elementary School',
  'Willow Field Elementary School',
  'Willow Glen Elementary',
  'Willow Glen High',
  'Willow Glen Middle',
  'Willow Glen Primary School',
  'Willow Grove Early Learning Ctr',
  'Willow Grove Elem School',
  'Willow Grove Elementary',
  'Willow Grove Elementary School',
  'Willow High',
  'Willow Hill Elementary School',
  'Willow Hill Sch',
  'Willow Lake Elementary - 02',
  'Willow Lake High School - 01',
  'Willow Lake Middle School - 03',
  'Willow Lane El Sch',
  'Willow Lane Elementary',
  'Willow Lane School',
  'Willow Oak Montessori',
  'Willow Oaks Elementary',
  'Willow Park Elementary School',
  'Willow Ridge Elementary School',
  'Willow River Elementary',
  'Willow River Secondary',
  'Willow Road School',
  'Willow School',
  'Willow Springs El',
  'Willow Springs Elem School',
  'Willow Springs Elem.',
  'Willow Springs Elementary',
  'Willow Springs High',
  'Willow Springs Learning Center',
  'Willow Springs Middle',
  'Willow Springs School',
  'Willow Vista Int',
  'Willow Wood J H',
  'Willow Wood Preschool',
  'Willow Woods Elementary School',
  'Willowbrook Elem School',
  'Willowbrook Elementary',
  'Willowbrook Elementary School',
  'Willowbrook High School',
  'Willowbrook Middle',
  'Willowbrook Middle School',
  'Willowcreek Elementary School',
  'Willowcreek Middle',
  'Willowcreek Middle School',
  'Willowdale Elementary School',
  'Willowick Middle School',
  'Willowridge H S',
  'Willows Community High',
  'Willows High',
  'Willows Intermediate',
  'Willowville Elementary School',
  'Wills El',
  'Wills Point H S',
  'Wills Point J H',
  'Wills Point Middle',
  'Wills Point Pri',
  'Wills Valley Elementary School',
  'Willsboro Central School',
  'Willson Elementary',
  'Willson School',
  'Willyard Elementary School',
  'Wilmac Spec Ed Unit',
  'Wilmer Amina Carter High',
  'Wilmer Elementary School',
  'Wilmer Tbd',
  'Wilmer-hutchins El',
  'Wilmer-hutchins H S',
  'Wilmette Junior High School',
  'Wilmington Area El Sch',
  'Wilmington Area Hs',
  'Wilmington Area Ms',
  'Wilmington College',
  'Wilmington Early College High',
  'Wilmington High',
  'Wilmington High School',
  'Wilmington Manor Elementary School',
  'Wilmington Mid Sci Tech Engr Arts Math (steam) Magnet',
  'Wilmington Middle School',
  'Wilmington Park Elementary',
  'Wilmington Preparatory Academy',
  'Wilmore Davis Elementary School',
  'Wilmore Elementary School',
  'Wilmot Elem School',
  'Wilmot Elementary - 02',
  'Wilmot Elementary School',
  'Wilmot High',
  'Wilmot High School - 01',
  'Wilmot Jr. High - 03',
  'Wilroads Gardens Elem',
  'Wilshire Crest Elementary',
  'Wilshire El',
  'Wilshire Park Elementary',
  'Wilson',
  'Wilson & Young Medal Of Honor Middle',
  'Wilson (etta J.) Elementary School',
  'Wilson Academy Of Applied Technology',
  'Wilson Alternative',
  'Wilson Area Hs',
  'Wilson Area Intermediate Sch',
  'Wilson Aspire',
  'Wilson Avenue School',
  'Wilson Borough El Sch',
  'Wilson C. Riles Middle',
  'Wilson Center',
  'Wilson Central High School',
  'Wilson Charter School',
  'Wilson Co J J A E P',
  'Wilson Co Jjaep',
  'Wilson Community College',
  'Wilson Community School',
  'Wilson County Boot Camp',
  'Wilson Creek Elem Sch',
  'Wilson Creek Elementary',
  'Wilson Creek Elementary School',
  'Wilson Creek Group Home',
  'Wilson Creek High',
  'Wilson Early College Academy',
  'Wilson El',
  'Wilson El Sch',
  'Wilson Elem School',
  'Wilson Elementary',
  'Wilson Elementary School',
  'Wilson Es',
  'Wilson Focus School',
  'Wilson Hall Middle School',
  'Wilson High',
  'Wilson High School',
  'Wilson Hill Elementary School',
  'Wilson Hs',
  'Wilson Int',
  'Wilson Intermediate School',
  'Wilson J H',
  'Wilson Jr. High',
  'Wilson Junior High',
  'Wilson Junior/senior High School',
  'Wilson Memorial High',
  'Wilson Middle',
  'Wilson Middle School',
  'Wilson Montessori',
  'Wilson Ms',
  'Wilson Preparatory Academy',
  'Wilson Primary Center',
  'Wilson Primary School',
  'Wilson School',
  'Wilson Southern Ms',
  'Wilson Talent Center',
  'Wilson Vance Elementary School',
  'Wilson West Ms',
  'Wilson Wims Elementary School',
  'Wilson Woodrow Ms',
  "Wilson's Creek 5-6 Inter. Ctr.",
  "Wilson's Mills Elementary",
  'Wilsona Achievement Academy',
  'Wilsonburg Elementary School',
  'Wilsonville Elementary School',
  'Wilsonville High School',
  'Wilt Elementary',
  'Wilton Elementary',
  'Wilton Elementary School',
  'Wilton High School',
  'Wilton Jr-sr High School',
  'Wilton Manors Elementary School',
  'Wilton Place Elementary',
  'Wilton-lyndeboro Middle School',
  'Wilton-lyndeboro Senior High School',
  'Wimauma Elementary School',
  'Wimberley H S',
  'Wimbish El',
  'Wimbledon School of Art',
  'Win Program',
  'Win-e-mac Elementary',
  'Win-e-mac Secondary',
  'Winamac Community High School',
  'Winamac Community Middle School',
  'Winand Elementary',
  'Winans Elementary School',
  'Winbourne Elementary School',
  'Winburn Middle School',
  'Winchell Elementary',
  'Winchell Elementary School',
  'Winchendon Preschool Program',
  'Winchester Avenue Elementary School',
  'Winchester Community High School',
  'Winchester Elem School',
  'Winchester Elementary',
  'Winchester Elementary School',
  'Winchester High School',
  'Winchester School',
  'Winchester Trail Elementary',
  'Winchester Village Elementary',
  'Wind Gap Ms',
  'Wind River Elementary',
  'Wind River High School',
  'Wind River Learning Academy',
  'Wind River Middle School',
  'Windber Area Hs',
  'Windber Area Ms',
  'Windber El Sch',
  'Windcrest El',
  'Windemere Clc',
  'Windemere Park Charter Academy',
  'Windemere Ranch Middle',
  'Winder Elementary School',
  'Winder-barrow High School',
  'Windermere Blvd School',
  'Windermere El',
  'Windermere Elementary',
  'Windermere Elementary School',
  'Windermere High School',
  'Windermere Pri',
  'Windermere School',
  'Windham Academy Public Charter School',
  'Windham Ashland Central School',
  'Windham Center School',
  'Windham Early Childhood Prospect Street',
  'Windham Early Childhood Tuckie Road',
  'Windham Elementary School',
  'Windham High School',
  'Windham Junior High School',
  'Windham Middle School',
  'Windham Preschool',
  'Windham Primary School',
  'Windham Regional Career Center',
  'Windham Technical High School',
  'Winding Creek Elementary',
  'Winding Creek Es',
  'Winding Ridge Elementary School',
  'Winding Springs Elementary',
  'Winding Waters K-8',
  'Windmill Point Elementary School',
  'Windmill Springs Elementary',
  'Windom Elem',
  'Windom Elementary School',
  'Windom Middle',
  'Windom School',
  'Windom Senior High',
  'Windover High School',
  'Window Rock High School',
  'Windridge School',
  'Windrows Elementary',
  'Winds West Es',
  'Windsong Int',
  'Windsong Ranch El',
  'Windsor Central High School',
  'Windsor Charter Academy Elementary School',
  'Windsor Charter Academy Middle And Early College H',
  'Windsor Creek Elementary',
  'Windsor El',
  'Windsor Elem School',
  'Windsor Elem.',
  'Windsor Elementary',
  'Windsor Elementary School',
  'Windsor Farm Elementary',
  'Windsor Forest Elementary School',
  'Windsor Forest High School',
  'Windsor High',
  'Windsor High School',
  'Windsor Hill Arts Infused Elementary',
  'Windsor Hills Elementary',
  'Windsor Hills Es',
  'Windsor Hills Math Science',
  'Windsor Intermediate',
  'Windsor Jr & Sr High School',
  'Windsor Knolls Middle',
  'Windsor Locks High School',
  'Windsor Locks Middle School',
  'Windsor Manor El Sch',
  'Windsor Middle',
  'Windsor Middle School',
  'Windsor Mill Middle',
  'Windsor Oaks Academy',
  'Windsor Oaks Elementary',
  'Windsor Park Elementary',
  'Windsor Park G/t',
  'Windsor School',
  'Windsor Spring Road Elementary School',
  'Windsor Stem Academy (k-6)',
  'Windsor Terrace School (the)',
  'Windsor University School of Medicine',
  'Windsor Village El',
  'Windsor Woods Elementary',
  'Windthorst El',
  'Windthorst H S',
  'Windthorst Jh',
  'Windward Community College',
  'Windward High School',
  'Windy Gap Elementary',
  'Windy Hill Elementary',
  'Windy Hill Elementary School',
  'Windy Hill Middle',
  'Windy Hill Middle School',
  'Windy Hills Elementary School',
  'Windy Ridge K-8',
  'Windy River Elementary School',
  'Winecoff Elementary',
  'Winegard Elementary',
  'Wines Elementary School',
  'Winesburg Elementary School',
  'Wineteer Elem',
  'Winfair Elementary',
  'Winfield Central School',
  'Winfield Correctional Institution',
  'Winfield El',
  'Winfield Elem.',
  'Winfield Elementary',
  'Winfield Elementary School',
  'Winfield High',
  'Winfield High School',
  'Winfield Intermediate',
  'Winfield Middle',
  'Winfield Middle School',
  'Winfield Primary School',
  'Winfield Scott Elem',
  'Winfield Scott School No. 2',
  'Winfield Street Elementary School',
  'Winfield Township',
  'Winfield-mt Union Jr-sr High School',
  'Winfree Academy Charter School (irving)',
  'Winfree Academy Charter School (lewisville)',
  'Winfree Academy Charter School (richardson)',
  'Winfree Academy Charter School - Grand Prairie',
  'Winfree Academy Charter School Dallas',
  'Winfree Academy North Richland Hills',
  'Winfree Bryant Middle School',
  'Wing Elementary School',
  'Wing High School',
  'Wing Lake Developmental Center',
  'Wing Lane Elementary',
  'Wing Luke Elementary School',
  'Wingate El Sch',
  'Wingate Elementary',
  'Wingate Elementary School',
  'Wingate High School',
  'Wingate Oaks Center',
  'Wingdale Elementary School',
  'Winget Park Elementary',
  'Wingfield High School',
  'Wingland Elementary',
  'Wingo Elementary School',
  'Wings (where I Now Gain Success)',
  'Wings Academy',
  'Wings Academy 1',
  'Wings Academy 2',
  'Wings Alternative Program',
  'Wings Park Elementary School',
  'Winifred 6-8',
  'Winifred Harelson Elementary School',
  'Winifred High School',
  'Winifred Pifer Elementary',
  'Winifred School',
  'Wink El',
  'Wink H S',
  'Winkler Elementary',
  'Winlock Middle School',
  'Winlock Miller Elementary',
  'Winlock Senior High',
  'Winman Junior High School',
  'Winn Brook',
  'Winn Correctional Center',
  'Winn El',
  'Winn Elementary School',
  'Winn Holt Elementary School',
  'Winnacunnet High School',
  'Winnebago',
  'Winnebago Elem School',
  'Winnebago Elementary School',
  'Winnebago High School',
  'Winnebago Mental Health Institution',
  'Winnebago Middle School',
  'Winneconne Elementary',
  'Winneconne High',
  'Winneconne Middle',
  'Winnemucca Grammar School',
  'Winnemucca Junior High School',
  'Winnequah School',
  'Winner Elementary - 04',
  'Winner High School - 01',
  'Winner Middle School - 02',
  'Winnetka Avenue Elementary',
  'Winnetka Covenant Preschool',
  'Winnetka El',
  'Winnetonka High',
  'Winnett 7-8',
  'Winnett High School',
  'Winnett School',
  'Winnfield High School',
  'Winnfield Kindergarten',
  'Winnfield Middle School',
  'Winnfield Primary School',
  'Winnie Mandela High School',
  'Winnisquam Regional High School',
  'Winnisquam Regional Middle School',
  'Winnona Park Elementary School',
  'Winnsboro El',
  'Winnsboro Elementary School',
  'Winnsboro H S',
  'Winnwood Elem.',
  'Winograd K-8 Elementary School',
  'Winola Elementary School',
  'Winolequa Learning Academy',
  'Winona Area Learning Center',
  'Winona Early Childhood Special Ed.',
  'Winona El',
  'Winona Elem',
  'Winona Elem.',
  'Winona Elementary School',
  'Winona H S',
  'Winona High',
  'Winona Int',
  'Winona Middle',
  'Winona Secondary School',
  'Winona Senior High',
  'Winona State University',
  'Winona Voc Complex',
  'Winooski High School',
  'Winooski Middle School',
  'Winridge Elementary',
  'Winship Community',
  'Winship Elementary',
  'Winship Elementary School',
  'Winship Middle',
  'Winside Elementary School',
  'Winside High School',
  'Winskill Elementary',
  'Winslow Elementary School',
  'Winslow High School',
  'Winslow Junior High School',
  'Winslow Township Elementary School Five',
  'Winslow Township Elementary School Four',
  'Winslow Township Elementary School One',
  'Winslow Township Elementary School Six',
  'Winslow Township Elementary School Three',
  'Winslow Township Elementary School Two',
  'Winslow Township High School',
  'Winslow Township Middle School',
  'Winsor Hill School',
  'Winstead Avenue Elementary',
  'Winstead Elementary',
  'Winstead Elementary School',
  'Winsted Elementary',
  'Winston Academy Of Engineering',
  'Winston Campus Elementary',
  'Winston Campus Jr High',
  'Winston Churchill Elem School',
  'Winston Churchill High',
  'Winston Churchill Middle',
  'Winston Churchill School',
  'Winston County High School',
  'Winston County Technical Center',
  'Winston El',
  'Winston Elem.',
  'Winston Elementary School',
  'Winston High',
  'Winston Louisville Voc Center',
  'Winston Middle School',
  'Winston Park Elementary School',
  'Winston Park K-8 Center',
  'Winston-salem Preparatory Acad',
  'Winter Elementary',
  'Winter Gardens Elementary',
  'Winter Haven Head Start',
  'Winter Haven Senior High School',
  'Winter High',
  'Winter Hill Community',
  'Winter Lakes School',
  'Winter Middle',
  'Winter Park High',
  'Winter Park Model Elementary',
  'Winter Sports School',
  'Winter Springs Elementary School',
  'Winter Springs High School',
  'Winterberry School',
  'Winterboro High School',
  'Winterfield Elementary',
  'Winterfield Venture Academy',
  'Wintergreen Interdistrict Magnet School',
  'Wintergreen Intermediate',
  'Wintergreen Primary',
  'Winterhaven School',
  'Winterpock Elementary',
  'Winterquist Elementary',
  'Winters El',
  'Winters Elementary',
  'Winters H S',
  'Winters High',
  'Winters J H',
  'Winters Middle',
  'Winters Mill High',
  'Winterset Bridges School',
  'Winterset Elementary School',
  'Winterset Junior High School',
  'Winterset Middle School',
  'Winterset Senior High School',
  'Wintersville Elementary School',
  'Winterville Charter Academy',
  'Winterville Elementary School',
  'Winthrop',
  'Winthrop Avenue School',
  'Winthrop Charter High School',
  'Winthrop Charter School',
  'Winthrop Grade School',
  'Winthrop High School',
  'Winthrop L Chenery Middle',
  'Winthrop Middle School',
  'Winthrop School',
  'Winthrop Stem Elementary Magnet School',
  'Winthrop University',
  'Winton Elementary School',
  'Winton Hills Academy Elementary School',
  'Winton Middle',
  'Winton Preparatory Academy',
  'Winton Woods Elementary School',
  'Winton Woods High School',
  'Winton Woods Intermediate School',
  'Winton Woods Middle School',
  'Winton Woods Primary North',
  'Winton Woods Primary South',
  'Wintonbury Early Childhood Magnet School',
  'Wire Village School',
  'Wiregrass Elementary School',
  'Wiregrass Georgia Technical College',
  'Wiregrass Ranch High Adult Education',
  'Wiregrass Ranch High School',
  'Wiregrass Regional College And Career Academy',
  'Wirt County High School',
  'Wirt County Middle School',
  'Wirt County Primary Center',
  'Wirtschaftsuniversität Wien',
  'Wiscasset Elementary School',
  'Wiscasset Middle/high School',
  'Wisconsin Connections Academy',
  'Wisconsin Conservatory Of Lifelong Learning',
  'Wisconsin Dells High',
  'Wisconsin Heights High',
  'Wisconsin Heights Middle',
  'Wisconsin Hills Middle',
  'Wisconsin Indianhead Technical College',
  'Wisconsin Rapids Area Middle',
  'Wisconsin Resource Center',
  'Wisconsin School For The Deaf',
  'Wisconsin Secure Program Facility',
  'Wisconsin Virtual Academy High (wiva)',
  'Wisconsin Virtual Academy K-8 (wiva)',
  'Wisconsin Virtual Learning',
  'Wisd Early On',
  'Wisdom Elementary',
  'Wisdom H S',
  'Wisdom Lane Middle School',
  'Wisdom Middle High School',
  'Wisdom School',
  'Wisdomquest Education Center',
  'Wise Academy',
  'Wise Co. Alternative Ed. Ctr.',
  'Wise County Career-technical Center',
  'Wise El Fine Arts Magnet',
  'Wise Elementary School',
  'Wise Primary',
  'Wise River School',
  'Wish Academy High',
  'Wish Community',
  'Wish Museum School',
  'Wishek Elementary School',
  'Wishek High School',
  'Wishkah Valley Elementary/high School',
  'Wishon Elementary',
  'Wishram High And Elementary Schl',
  'Wisner-pilger Elem School',
  'Wisner-pilger High School',
  'Wisner-pilger Middle School',
  'Wissahickon Cs',
  'Wissahickon Ms',
  'Wissahickon Shs',
  'Wissenschaftliche Hochschule für Unternehmensführung Otto-Beisheim Hochschule',
  'Wister Es',
  'Wister Hs',
  'Witch Hazel Elementary School',
  'Witchcraft Heights',
  'Withamsville-tobasco Elementary School',
  'Withlacoochee Technical College',
  'Withrow Elementary',
  'Withrow University High School',
  'Witt Elementary',
  'Witt Elementary School',
  'Wittenberg Elementary',
  'Wittenberg University',
  'Wittenberg-birnamwood High',
  'Wittenborg University',
  'Wittenburg Elementary',
  'Witter Elementary School',
  'Witter Ranch Elementary',
  'Wittmann (helen) Elementary',
  'Wixom Elementary School',
  'Wj Quarles Elementary School',
  'Wk Kellogg Preparatory High School',
  'Wl Antheil Elementary School',
  'Wlv Family Partnership',
  'Wm - Connections',
  'Wm A White Elem',
  'Wm A Wirt/emerson Vpa',
  'Wm B Travis El',
  'Wm David Surratt El',
  'Wm Diamond Middle',
  'Wm E Bishop Elementary School',
  'Wm F Gurrie Middle School',
  'Wm Fremd High School',
  'Wm H Galvin Middle',
  'Wm Hammerschmidt Elem School',
  'Wm J Dean Vocational Technical High',
  'Wm L Foster Elementary',
  'Wm Nashold Early Childhood Center',
  'Wm Nashold Elem School',
  'Wm P Connery',
  'Wm S Lott Juvenile Ctr',
  'Wm. H. Lynch Elem.',
  'Wm. Jefferson Clinton Primary',
  'Wm. M. Kelley Secondary',
  'Wm. N. Clark Middle',
  'Wm. R. Cappel Elem.',
  'Wmi On Line',
  'Wo Parmer Elementary School',
  'Woburn High',
  'Woburn Street',
  'Woden El',
  'Woden H S',
  'Woden J H',
  'Woerner Elem.',
  'Woerther Elem.',
  'Wofford College',
  'Wogaman Middle School',
  'Wohlwend Elem.',
  'Wolaita Sodo University',
  'Wolcott Elementary School',
  'Wolcott High School',
  'Wolcott Learning Center',
  'Wolcott Mills Elementary School',
  'Wolcott School',
  'Wolcott Street School',
  'Wolf Branch Elem School',
  'Wolf Branch Middle School',
  'Wolf Canyon Elementary',
  'Wolf Creek Colony Elementary - 04',
  'Wolf Creek Elementary',
  'Wolf Creek Elementary School',
  'Wolf Creek Es',
  'Wolf Creek School',
  'Wolf Creek School - 02',
  'Wolf Hill Elementary School',
  'Wolf Lake Elementary',
  'Wolf Lake Elementary School',
  'Wolf Lake Middle',
  'Wolf Meadow Elementary',
  'Wolf Point 7-8',
  'Wolf Point High School',
  'Wolf Ridge Education Center',
  'Wolf Ridge Junior High School',
  'Wolf Springs Elementary School',
  'Wolf Swamp Road',
  'Wolfe City El',
  'Wolfe City H S',
  'Wolfe City Middle',
  'Wolfe County High School',
  'Wolfe County Middle School',
  'Wolfe Middle School',
  'Wolfe School',
  'Wolfe Street Academy',
  'Wolffarth El',
  'Wolflin El',
  'Wolford Elementary School',
  'Wolford High School',
  'Wolfpit School',
  'Wolfs Crossing Elem School',
  'Wolfskill High',
  'Wolfsville Elementary',
  'Wolftever Creek Elementary',
  'Wolftrap Elementary',
  'Wolkite University',
  'Wollaston School',
  'Wollega University',
  'Wollo University',
  'Wolsey Wessington Elementary - 02',
  'Wolsey Wessington High School - 01',
  'Wolsey Wessington Middle School - 03',
  'Wolters Elementary',
  'Wolverine Education Center',
  'Wolverine Elementary School',
  'Wolverine Middlehigh School',
  'Wolverine Program',
  'Women E. Recep/diag Cor C/acad',
  'Women E. Recep/diag Cor C/diag',
  "Women's Academy Of Excellence",
  "Women's College of Fine Arts",
  "Women's East Region Trtmt Ctr",
  "Women's Haven",
  "Women's University in Africa",
  'Wonder Elementary School',
  'Wonder Junior High School',
  'Wonder Park Elementary',
  'Wonderful College Prep Academy',
  'Wonderful College Prep Academy - Lost Hills',
  'Wonderland Avenue Elementary',
  'Wonderland Cs',
  'Wonderview Elementary School',
  'Wonderview High School',
  'Wonewoc-center Elementary',
  'Wonewoc-center High',
  'Wonewoc-center Junior High',
  'Wonewoc-center Virtual Academy',
  'Wonkwang University',
  'Wood Acres Elementary',
  'Wood Canyon Elementary',
  'Wood Co Voc Tech Ctr',
  'Wood County Preschool',
  'Wood Creek Elementary School',
  'Wood Dale Jr High School',
  'Wood El',
  'Wood Elem.',
  'Wood Elementary',
  'Wood Elementary - 05',
  'Wood End Elementary School',
  'Wood Hill Middle School',
  'Wood Intermediate',
  'Wood Lake School',
  'Wood Middle',
  'Wood Oaks Jr High School',
  'Wood Park School',
  'Wood Ranch Elementary',
  'Wood Ridge Middle School',
  'Wood River Elementary School',
  'Wood River High School',
  'Wood River Middle School',
  'Wood River Rural High School',
  'Wood River Rural Middle School',
  'Wood Road Elementary School',
  'Wood School',
  'Wood School Bangor Twp 8',
  'Wood View Elem School',
  'Wood-gormley Elementary',
  'Wood-ridge Intermediate School',
  'Wood-ridge Jr/sr High School',
  'Woodall Public School',
  'Woodard El',
  'Woodberry Hills Elementary',
  'Woodbine Avenue Elementary School',
  'Woodbine Elem School',
  'Woodbine Elementary',
  'Woodbine Elementary School',
  'Woodbine High School',
  'Woodbine Pre-school Center',
  'Woodbridge',
  'Woodbridge Early Childhood Education Center',
  'Woodbridge Elementary',
  'Woodbridge Elementary School',
  'Woodbridge High',
  'Woodbridge High School',
  'Woodbridge Middle',
  'Woodbridge Middle School',
  'Woodbrook Elementary',
  'Woodbrook Elementary School',
  'Woodbrook Middle School',
  'Woodburn Academy Of Art Science And Technology',
  'Woodburn Arthur Academy',
  'Woodburn Arts And Communications Academy',
  'Woodburn Elementary',
  'Woodburn Success',
  'Woodbury Central High School',
  'Woodbury Central Middle School',
  'Woodbury Elementary',
  'Woodbury Elementary School',
  'Woodbury Grammar',
  'Woodbury Heights Elementary',
  'Woodbury Jr-sr High School',
  'Woodbury Leadership Academy',
  'Woodbury Middle School',
  'Woodbury School',
  'Woodbury Senior High',
  'Woodbury University',
  'Woodcliff Middle School',
  'Woodcreek Achievement Center',
  'Woodcreek El',
  'Woodcreek High',
  'Woodcreek J H',
  'Woodcreek Middle',
  'Woodcrest El',
  'Woodcrest El. Spanish Immersion',
  'Woodcrest Elementary',
  'Woodcrest Elementary School',
  'Woodcrest Junior High',
  'Wooddale High',
  'Wooddale Middle School',
  'Wooden El',
  'Woodfern Elementary School',
  'Woodfield Elementary',
  'Woodfields Elementary',
  'Woodfill Elementary School',
  'Woodfin Elementary',
  'Woodford County High School',
  'Woodford County Middle School',
  'Woodford Hollow School',
  'Woodford Paideia Elementary School',
  'Woodgate Elem School',
  'Woodgate Int',
  'Woodglen Elementary School',
  'Woodglen School',
  'Woodgrove High',
  'Woodham Middle School',
  'Woodhaven High School',
  'Woodhill Elementary',
  'Woodholme Elementary',
  'Woodhome Elementary/middle',
  'Woodhull Intermediate School',
  'Woodhull School',
  'Woodin Elementary',
  'Woodington Middle',
  'Woodinville Community Center',
  'Woodinville Hs',
  'Woodlake Community Day',
  'Woodlake El',
  'Woodlake Elementary',
  'Woodlake Elementary Community Charter',
  'Woodlake Elementary School',
  'Woodlake High',
  'Woodlake Hills Middle',
  'Woodlake Valley Middle',
  'Woodlan Elementary School',
  'Woodlan Jr/sr High School',
  'Woodland',
  'Woodland Academy',
  'Woodland Acres El',
  'Woodland Acres Elementary School',
  'Woodland Acres Middle',
  'Woodland Avenue School',
  'Woodland Charter School',
  'Woodland Community College',
  'Woodland Community Day',
  'Woodland Consolidated Sch',
  'Woodland Developmental Center',
  'Woodland El',
  'Woodland El Sch',
  'Woodland Elem',
  'Woodland Elem School',
  'Woodland Elem.',
  'Woodland Elementary',
  'Woodland Elementary School',
  'Woodland Elementary/jr High Sch',
  'Woodland Es',
  'Woodland Forrest Elementary School',
  'Woodland Health / Wellness Magnet Elem',
  'Woodland Heights El',
  'Woodland Heights Elem. School',
  'Woodland Heights Elementary',
  'Woodland Heights Elementary School',
  'Woodland High',
  'Woodland High School',
  'Woodland Hills',
  'Woodland Hills Academy',
  'Woodland Hills El',
  'Woodland Hills Elementary',
  'Woodland Hills Elementary Charter For Enriched Studies',
  'Woodland Hills Es',
  'Woodland Hills Intermediate Sch',
  'Woodland Hills Jshs',
  'Woodland Hs',
  'Woodland Intermediate',
  'Woodland Intermediate Sch',
  'Woodland Intermediate School',
  'Woodland Jr-sr High School',
  'Woodland Junior High School',
  'Woodland Meadows Elementary School',
  'Woodland Middle',
  'Woodland Middle School',
  'Woodland Middle School At Euharlee',
  'Woodland Park Academy',
  'Woodland Park Elementary',
  'Woodland Park Elementary Sch',
  'Woodland Park High School',
  'Woodland Park Magnet',
  'Woodland Park Middle',
  'Woodland Park Middle School',
  'Woodland Prairie Elementary',
  'Woodland Primary School',
  'Woodland Progressive School For 21st Century Citizens',
  'Woodland Regional High School',
  'Woodland School',
  'Woodland Senior High',
  'Woodland Springs El',
  'Woodland Star Charter',
  'Woodland Upper Es',
  'Woodland West Elementary School',
  'Woodlands El',
  'Woodlands Elementary',
  'Woodlands Elementary School',
  'Woodlands Es',
  'Woodlands Intermediate School',
  'Woodlands Middle School',
  'Woodlands Middle/high School',
  'Woodlands School',
  'Woodlands School East',
  'Woodlawn Academy',
  'Woodlawn Avenue Elementary',
  'Woodlawn Beach Middle School',
  'Woodlawn Early Childhood Center',
  'Woodlawn El',
  'Woodlawn Elem',
  'Woodlawn Elem Community School',
  'Woodlawn Elementary',
  'Woodlawn Elementary School',
  'Woodlawn Grade School',
  'Woodlawn High Center For Pre-eng. Res.',
  'Woodlawn High School',
  'Woodlawn High Schoolmagnet',
  'Woodlawn Hills El',
  'Woodlawn Leadership Academy',
  'Woodlawn Middle',
  'Woodlawn Middle School',
  'Woodlawn Pre-school Learning Center',
  'Woodleaf Elementary',
  'Woodley Elementary School',
  'Woodley Hills Elementary',
  'Woodlin Elementary',
  'Woodlin Elementary School',
  'Woodlin Undivided High School',
  'Woodlyn El Sch',
  'Woodlynne Boro Public School',
  'Woodman 7-8',
  'Woodman Elem',
  'Woodman Park School',
  'Woodman School',
  'Woodmeade Elementary School',
  'Woodmen Hills Elementary School',
  'Woodmen-roberts Elementary School',
  'Woodmere',
  'Woodmere Elementary School',
  'Woodmere Middle School',
  'Woodmont Charter School',
  'Woodmont High',
  'Woodmont K-8 School',
  'Woodmont Middle',
  'Woodmont School',
  'Woodmoor Elementary',
  'Woodmore Elementary',
  'Woodmore Elementary School',
  'Woodmore High School',
  'Woodmore Middle School',
  'Woodridge El',
  'Woodridge Elementary',
  'Woodridge Elementary School',
  'Woodridge Forest Middle',
  'Woodridge High School',
  'Woodridge Intermediate Elementary School',
  'Woodridge Middle School',
  'Woodridge Primary Elementary School',
  'Woodridge School',
  'Woodriver Elementary',
  'Woodroe Petty El',
  'Woodrow Cummins Elementary Sch',
  'Woodrow Elementary',
  'Woodrow W. Wallace Elementary',
  'Woodrow Wallace Middle',
  'Woodrow Wilson',
  'Woodrow Wilson Alternative High School',
  'Woodrow Wilson Bdlc',
  'Woodrow Wilson Charter Academy',
  'Woodrow Wilson Community School',
  'Woodrow Wilson Daep',
  'Woodrow Wilson Elem',
  'Woodrow Wilson Elem School',
  'Woodrow Wilson Elem. School',
  'Woodrow Wilson Elementary',
  'Woodrow Wilson Elementary - 17',
  'Woodrow Wilson Elementary School',
  'Woodrow Wilson Es',
  'Woodrow Wilson H S',
  'Woodrow Wilson High',
  'Woodrow Wilson High School',
  'Woodrow Wilson Hs',
  'Woodrow Wilson Intermediate',
  'Woodrow Wilson Junior High',
  'Woodrow Wilson Middle',
  'Woodrow Wilson Middle School',
  'Woodrow Wilson Ms',
  'Woodrow Wilson Primary School',
  'Woodrow Wilson Rehab Ctr',
  'Woodrow Wilson School',
  'Woodrow Wilson School #5',
  'Woodrow Wilson School No. 19',
  'Woodrow Wilson Senior High',
  'Woodruff Career & Tech Center',
  'Woodruff Early Childhood Ctr',
  'Woodruff Elementary',
  'Woodruff High',
  'Woodruff Middle',
  'Woodruff Middle School',
  'Woodruff Primary',
  'Woodruff School',
  'Woods (harold L.) Elementary',
  'Woods Charter',
  'Woods Creek Elementary School',
  'Woods Cross High',
  'Woods Cross School',
  'Woods El',
  'Woods Elementary',
  'Woods Lake Elementarya Magnet Center For The Arts',
  'Woods Learning Center',
  'Woods Road Elementary School',
  'Woodsbend Youth Development Center',
  'Woodsboro El',
  'Woodsboro Elementary',
  'Woodsboro H S',
  'Woodsdale Elementary School',
  'Woodsedge Learning Center',
  'Woodsfield Elementary School',
  'Woodside Avenue School',
  'Woodside Community School (the)',
  'Woodside Elementary',
  'Woodside Elementary School',
  'Woodside High',
  'Woodside Intermediate School',
  'Woodside Juvenile Rehabilitation Center',
  'Woodside K-8',
  'Woodside Middle School',
  'Woodside School',
  'Woodson Center For Excellence',
  'Woodson Early Headstart',
  'Woodson Ecc',
  'Woodson Elementary',
  'Woodson High',
  'Woodson Kindergarten Center',
  'Woodson Park Academy',
  'Woodson School',
  'Woodson South Elem School',
  'Woodstation Elementary School',
  'Woodstock Academy',
  'Woodstock Elementary',
  'Woodstock Elementary School',
  'Woodstock High School',
  'Woodstock Middle School',
  'Woodstock North High School',
  'Woodstock School',
  'Woodstock Senior Uhsd #4',
  'Woodstock Union Middle School',
  'Woodstone El',
  'Woodstown High School',
  'Woodstown Middle School',
  'Woodsville Elementary School',
  'Woodsville High School',
  'Woodvale Elementary School',
  'Woodview El',
  'Woodview Elementary',
  'Woodview Elementary School',
  'Woodview School',
  'Woodville Community Center',
  'Woodville El',
  'Woodville Elementary',
  'Woodville Elementary School',
  'Woodville H S',
  'Woodville Heights Elementary School',
  'Woodville High School',
  'Woodville Int',
  'Woodville Middle',
  'Woodville School',
  'Woodville-tompkins Technical And Career High School',
  'Woodward Academy',
  'Woodward Avenue Elementary School',
  'Woodward Career Technical High School',
  'Woodward Ec Ctr',
  'Woodward El Sch',
  'Woodward Elem.',
  'Woodward Elementary School',
  'Woodward High School',
  'Woodward Hs',
  'Woodward Leadership Academy',
  'Woodward Middle School',
  'Woodward Mill Elementary School',
  'Woodward Ms North Campus',
  'Woodward Ms South Campus',
  'Woodward Park Middle School',
  'Woodward Parkway Elementary School',
  'Woodward School For Technology And Research',
  'Woodward-granger Early Learning Center',
  'Woodward-granger Elementary School',
  'Woodward-granger High School',
  'Woodward-granger Middle School',
  'Woodway El',
  'Woodworth Elementary School',
  'Woodworth Middle',
  'Woodworth Middle School',
  'Woody Gap High/elementary School',
  'Wooldridge El',
  'Woolmarket Elementary School',
  'Woolridge Elementary',
  'Woolwich Central School',
  'Woolwine Elementary',
  'Woonsocket Career An Tech Ctr.',
  'Woonsocket Elementary - 03',
  'Woonsocket High School',
  'Woonsocket High School - 01',
  'Woonsocket Middle @ Villa Nova',
  'Woonsocket Middle At Hamlet',
  'Woonsocket Middle School',
  'Wooster High School',
  'Wooster Littlest Generals Preschool',
  'Wooster Twp Elementary School',
  'Woosuk University',
  'Wooten El',
  'Wor-​Wic Community College',
  'Worcester Arts Magnet School',
  'Worcester East Middle',
  'Worcester El Sch',
  'Worcester Polytechnic Institute',
  'Worcester School',
  'Worcester Technical High',
  'Worcester Technical High School',
  'Worden Elementary School',
  'Workforce Advantage Academy Charter',
  'Workman Avenue Elementary',
  'Workman Elementary',
  'Workman Elementary School',
  'Workman J H',
  'Worland High School',
  'Worland Middle School',
  'World Academy',
  'World Academy For Total Community Health',
  'World Communications Cs',
  'World Compass Academy',
  'World Journalism Preparatory: A College Board School',
  'World Language Academy',
  'World Language High School',
  'World Languages Institute',
  'World Learner Charter School',
  'World Maritime University',
  'World Of Wonder Prek-6 School',
  'World University of Bangladesh',
  'World View High School',
  'Worley Elementary School',
  'Worrall El Sch',
  'Worsham El',
  'Worth Co. Elementary',
  'Worth Co. High',
  'Worth County Achievement Center',
  'Worth County Elementary School',
  'Worth County High School',
  'Worth County Middle School',
  'Worth County Primary School',
  'Worth Elem School',
  'Worth Heights El',
  'Worth Jr High School',
  'Wortham Daep',
  'Wortham El',
  'Wortham H S',
  'Wortham Middle',
  'Wortham Oaks El',
  'Worthing Elementary - 05',
  'Worthing H S',
  'Worthington 518 (virtual Instruct',
  'Worthington Alc Night',
  'Worthington Area Learning Center',
  'Worthington Elementary',
  'Worthington Elementary School',
  'Worthington Estates Elementary School',
  'Worthington High School',
  'Worthington Hills Elementary School',
  'Worthington Hooker School',
  'Worthington Kilbourne High School',
  'Worthington Middle',
  'Worthington Park Elementary School',
  'Worthington Senior High',
  'Worthingway Middle School',
  'Worthridge School',
  'Worthwoods Elem School',
  'Worton Elementary',
  'Wossman High School',
  'Wounded Knee District School',
  'Wp Davidson High School',
  'Wr Bush Elementary School',
  'Wrangell High School',
  'Wray Elementary School',
  'Wray High School',
  'Wredling Middle School',
  'Wren Avenue Elementary',
  'Wren Elementary',
  'Wren High',
  'Wren Hollow Elem.',
  'Wren Middle',
  'Wrens Elementary School',
  'Wrens Middle School',
  'Wrenshall Elementary',
  'Wrenshall Secondary',
  'Wright Brothers Elementary School',
  'Wright Brothers Middle School',
  'Wright Charter',
  'Wright City East Elem',
  'Wright City Es',
  'Wright City High',
  'Wright City Hs',
  'Wright City Jhs',
  'Wright City Middle',
  'Wright City West Elem.',
  'Wright Denny Intermediate School',
  'Wright El',
  'Wright Elementary',
  'Wright Elementary School',
  'Wright Es',
  'Wright Jr. & Sr. High School',
  'Wright Middle',
  'Wright Richard R Sch',
  'Wright Start Preschool',
  'Wright State University',
  'Wright Technical Center',
  'Wright Technical Center Alc',
  'Wright Technical Center Alc Middle',
  'Wrights Mill Road Elementary School',
  'Wrightsboro Elementary',
  'Wrightstown El Sch',
  'Wrightstown Elementary',
  'Wrightstown Elementary - Closed',
  'Wrightstown High',
  'Wrightstown Middle',
  'Wrightsville Beach Elem',
  'Wrightsville El Sch',
  'Wrightwood Elementary',
  'Wrigley Elementary School',
  'Wrps Four-year-old Kindergarten And Pk Early Childhood',
  'Ws Harlan Elementary School',
  'Wsc Academy Ypsilanti Campus',
  'Wscuhsd Community Day',
  'Wsd Columbia Basin J.c.',
  'Wudanshan Taoist College',
  'Wuhan Automobile Polytechnical University',
  'Wuhan Technical University of Surveying and Mapping',
  'Wuhan Transportation University',
  'Wuhan University',
  'Wuhan University School of Medicine',
  'Wuhan University of Hydraulic and Electric Engineering',
  'Wuhan University of Technology',
  'Wunderlich Int',
  'Wurtland Elementary School',
  'Wurtland Middle School',
  'Wuxi University of Light Industry',
  'Wuyi University',
  'Wv Childrens Home',
  'Wv Industrial Home For Youth',
  'Wyalusing Valley El Sch',
  'Wyalusing Valley Jshs',
  'Wyan-pine Grove Elementary',
  'Wyandanch Memorial High School',
  'Wyandot Early Childhood School',
  'Wyandot Elementary School',
  'Wyandot Middle School',
  'Wyandot Run Elementary School',
  'Wyandotte Academy',
  'Wyandotte Elementary',
  'Wyandotte Elementary School',
  'Wyandotte Es',
  'Wyandotte High',
  'Wyandotte Hs',
  'Wyandotte Ms',
  'Wyandotte Public Schools Early Childhood Center',
  'Wyatt Academy',
  'Wyatt Academy Middle School',
  'Wyatt El',
  'Wycallis El Sch',
  'Wydown Middle',
  'Wyeast Middle School',
  'Wyeville Elementary',
  'Wylam K8',
  'Wyland El Sch',
  'Wyland Elem.',
  'Wylandville El Sch',
  'Wylie E Groves High School',
  'Wylie Early Childhood Ctr',
  'Wylie East El',
  'Wylie East H S',
  'Wylie El',
  'Wylie Elementary School',
  'Wylie H S',
  'Wylie Int',
  'Wylie J H',
  'Wylie Middle',
  'Wyman',
  'Wyman School',
  'Wynbrooke Elementary School',
  'Wyncote El Sch',
  'Wyndham Lakes Elementary',
  'Wyndmere Elementary School',
  'Wyndmere High School',
  'Wynford Elementary School',
  'Wynford High School',
  'Wynford Junior High',
  'Wyngate Elementary',
  'Wynn Habersham Elementary',
  'Wynn Seale Metropolitan School Of Design',
  'Wynne High School',
  'Wynne Intermediate School',
  'Wynne Junior High School',
  'Wynne Primary School',
  'Wynnebrook Elementary School',
  'Wynnewood Hs',
  'Wynnewood Ms',
  'Wynnton Elementary School',
  'Wynona Es',
  'Wynona Hs',
  'Wynot Elementary School',
  'Wynot High School',
  'Wynot Middle School',
  'Wyola 7-8',
  'Wyola School',
  'Wyomina Park Elementary School',
  'Wyoming Area Intermediate Ctr',
  'Wyoming Area Kindergarten Ctr',
  'Wyoming Area Primary Ctr',
  'Wyoming Area Sec Ctr',
  'Wyoming Behavioral Institute',
  'Wyoming Boys School',
  'Wyoming Central School',
  'Wyoming County Career & Technical Center',
  'Wyoming County East High School',
  'Wyoming County Jail',
  'Wyoming Elementary',
  'Wyoming Girls School',
  'Wyoming High School',
  'Wyoming Indian Elementary',
  'Wyoming Indian High School',
  'Wyoming Indian Middle School',
  'Wyoming Intermediate',
  'Wyoming Junior High',
  'Wyoming Middle School',
  'Wyoming Regional Center',
  'Wyoming School',
  'Wyoming Valley West Ms',
  'Wyoming Valley West Shs',
  'Wyomissing Area Jshs',
  'Wyomissing Hills El Ctr',
  'Wysong Elementary',
  'Wythe County Tech Center',
  'Wytheville Community College',
  'Wyvetter Younge Altrntv Cntr',
  'X I T Secondary School',
  'XAVERIAN BROTHERS HIGH SCHOOL',
  'XAVERIAN HIGH SCHOOL',
  'XAVIER COLLEGE PREPARATORY',
  'XAVIER COLLEGE PREPARATORY HIGH SCHOOL',
  'XAVIER EDUCATIONAL ACADEMY',
  'XAVIER HIGH SCHOOL',
  'XAVIER UNIVERSITY MONTESSORI LAB SCHOOL',
  'Xavier Charter School',
  'Xavier School Of Excellence',
  'Xavier University',
  'Xavier University of Louisiana',
  'Xenia High School',
  'Xenia Preschool',
  'Xenia Voigt El',
  "Xi'an Academy of Fine Art",
  "Xi'an Eurasia University",
  "Xi'an Highway University",
  "Xi'an International Studies University",
  "Xi'an Jiaotong University",
  "Xi'an Jiaotong-Liverpool University",
  "Xi'an University of Architecture and Technology",
  "Xi'an University of Electronic Science and Technology",
  "Xi'an University of Technology",
  'Xiamen University',
  'Xiangtan Normal University',
  'Xiangtan University',
  'Xihua University',
  'Xijiang University',
  'Xinghai Conservatory of Music',
  'Xinjiang Agriculture University',
  'Xinjiang Normal University',
  'Xinjiang University',
  'Xinjiang University of Finance and Economics',
  'Xinxing Academy',
  'Xuzhou Normal University',
  'Y C S FORT LEE EDUCATION CENTER',
  'Y E Smith Elementary',
  'Y.A.L.E. ATLANTIC',
  'Y.A.L.E. SCHOOL',
  "Y.A.L.E. SCHOOL NORTH II KIRBY'S MILL",
  'Y.A.L.E. SCHOOL PHILADELPHIA',
  'Y.A.L.E. SCHOOL WEST - ELLISBURG',
  'YACHAD KIDS ACADEMY',
  'YAKIMA ADVENTIST CHRISTIAN SCHOOL',
  'YALE SCHOOL SOUTHEAST',
  'YALE SCHOOL SOUTHEAST III',
  'YALE SCHOOL- CENTRAL',
  'YALE SOUTHEAST II',
  'YANCEY COUNTY CHRISTIAN SCHOOL',
  'YANG ACADEMY',
  'YARNELL SCHOOLS',
  'YATTIYR ISEE CHRISTIAN SCHOOL',
  'YAVNEH ACADEMY',
  'YAVNEH ACADEMY OF DALLAS',
  'YAVNEH DAY SCHOOL',
  'YBH OF PASSAIC-HILLEL',
  'YCS GEORGE WASHINGTON SCHOOL',
  'YELADIM EARLY LEARNING CENTER',
  'YELLOW SCHOOL-MDPC',
  'YELLOWSTONE ACADEMY',
  'YES HOUSE ALTERNATIVE SCHOOL',
  "YESHIVA & MESIVTA V'YOEL MOSHE",
  'YESHIVA AHAVAS TORAH',
  'YESHIVA AISH KODOSH TALMUDICAL ACADEMY',
  'YESHIVA AT THE JERSEY SHORE',
  'YESHIVA ATERET TORAH',
  'YESHIVA BAIS HACHINUCH',
  'YESHIVA BEIS DOVID SHLOMO',
  'YESHIVA BETH HILLEL OF WILLIAMSBURG',
  'YESHIVA BETH JOSEPH ZVI DUSHINSKY',
  'YESHIVA BETH MIKROH',
  'YESHIVA BETH YEHUDAH',
  'YESHIVA BIRCHAS CHAIM',
  'YESHIVA BIRCHAS SHMUEL',
  'YESHIVA BONIM LAMOKOM',
  'YESHIVA CHEMDAT HATORAH',
  'YESHIVA DARCHEI ALIYA',
  'YESHIVA DARCHEI NOAM',
  'YESHIVA DAY SCHOOL OF LAS VEGAS',
  'YESHIVA DEGAL HATORAH',
  'YESHIVA DERECH HATORAH',
  'YESHIVA ELEMENTARY SCHOOL',
  'YESHIVA EMEK HATORAH',
  'YESHIVA FARM SETTLEMENT SCHOOL',
  'YESHIVA GEDOLA OF WOODLAKE VILLAGE',
  'YESHIVA GEDOLA OHR HATALMUD',
  'YESHIVA GEDOLAH ATERES MORDECHAI',
  'YESHIVA GEDOLAH OF LOS ANGELES',
  'YESHIVA HAR TORAH',
  'YESHIVA HIGH SCHOOL OF ARIZONA',
  'YESHIVA HIGH SCHOOL OF MONSEY',
  'YESHIVA KARLIN STOLIN',
  'YESHIVA KETANA OF LONG ISLAND',
  'YESHIVA KETANA OF LOS ANGELES',
  'YESHIVA KETANA OF QUEENS',
  "YESHIVA KTANA OF PASSAIC BOYS'",
  'YESHIVA MACHZIKEI HADAS 2',
  'YESHIVA MASORAS AVOS',
  'YESHIVA MESIVTA ARUGATH HABOSEM',
  'YESHIVA MESORAS HATORAH',
  'YESHIVA NER ARYEH',
  'YESHIVA OF BELLE HARBOR/YESHIVAL BITALLON',
  'YESHIVA OF CENTRAL QUEENS',
  'YESHIVA OF FAR ROCKAWAY',
  'YESHIVA OF GREATER WASHINGTON GIRLS DIVISION',
  'YESHIVA OF MINNEAPOLIS',
  'YESHIVA OF SOUTH SHORE',
  'YESHIVA OF SPRING VALLEY',
  'YESHIVA OF STATEN ISLAND',
  'YESHIVA OHAVEI TORAH OF RIVERDALE',
  'YESHIVA OHOLEI SHEM DNITRA',
  'YESHIVA OHR ELCHONON CHABAD - WEST COAST',
  'YESHIVA OHR HATALMUD OF ENGLEWOOD',
  'YESHIVA OHR REUVEN',
  'YESHIVA OHR TORAH',
  'YESHIVA OHR YISRAEL',
  'YESHIVA OHR YISRAEL HIGH SCHOOL FOR BOYS',
  'YESHIVA OHR YISRAEL OF ATLANTA',
  'YESHIVA ORCHOS CHAIM',
  'YESHIVA RABBI SAMSON RAPHAEL HIRSCH',
  'YESHIVA RAV ISACSOHN / TORATH EM',
  'YESHIVA SCHOOLS OF PITTSBURGH',
  'YESHIVA SHAAR HATORAH',
  'YESHIVA SHAARE TORAH INC',
  "YESHIVA SHAAREI TZION BOYS'",
  'YESHIVA SHAAREI TZION GIRLS SCHOOL',
  'YESHIVA SHAGAS ARYEN',
  'YESHIVA TIFERES ELIMELECH',
  'YESHIVA TIFERES NAFTOLI OF CENTRAL NJ',
  "YESHIVA TIFERES SHMIEL D'ALEKSANDER",
  'YESHIVA TIFERES TORAH',
  'YESHIVA TIFERETH MOSHE',
  'YESHIVA TIFERETH MOSHE DOV',
  'YESHIVA TORAS ARON',
  'YESHIVA TORAS CHAIM',
  'YESHIVA TORAS CHAIM HIGH SCHOOL',
  'YESHIVA TORAS EMMES KAMENITZ',
  'YESHIVA TORAS MENACHEM',
  'YESHIVA TORAS YISROEL INC',
  'YESHIVA TORAT EMET',
  'YESHIVA TZEMACH TZADIK VIZNITZ',
  'YESHIVA TZOIN YOSEF',
  'YESHIVA UNIVERSITY HIGH SCHOOL',
  'YESHIVA UNIVERSITY HIGH SCHOOL GIRLS',
  'YESHIVA VIZNITZ',
  'YESHIVA VYELIPOL',
  'YESHIVA YESODE HATORAH',
  'YESHIVA YESODEI HACHAIM',
  'YESHIVAH OF CROWN HEIGHTS',
  'YESHIVAH OF FLATBUSH',
  'YESHIVAH OF FLATBUSH JOEL BRAVERMAN HIGH SCHOOL',
  'YESHIVAH OHEL MOSHE',
  'YESHIVAH PREP HIGH SCHOOL',
  'YESHIVANT MEKOT HAIM',
  'YESHIVAS DORESH',
  'YESHIVAS MEOR HATORAH OF CHICAGO',
  'YESHIVAS OHR HATORAH',
  'YESHIVAS OHR YISSOCHOR',
  'YESHIVAS TIFERES TZVI',
  'YESHIVAS TORAS SIMCHA',
  'YESHIVAT DARCHE ERES INC',
  "YESHIVAT HE'ATID",
  'YESHIVAT KADIMAH HIGH SCHOOL',
  'YESHIVAT MAGEN ABRAHAM',
  'YESHIVAT NETIVOT MONTESSORI',
  'YESHIVAT NOAM SCHOOL',
  'YESHIVAT OHEL TORAH',
  'YESHIVAT SHAARE TORAH GIRLS ELEMENTARY SCHOOL',
  'YESHIVAT YAGDIL TORAH',
  'YESHIVATH GESHER',
  'YESHIVE TORAS EMES',
  'YEW CHUNG INTERNATIONAL SCHOOL (SV)',
  'YGNACIO VALLEY CHRISTIAN SCHOOL',
  'YIN YANG ACADEMY',
  'YING HUA INTERNATIONAL SCHOOL',
  'YMCA KINDERGARTEN ACADEMY',
  'YOCUM INSTITUTE FOR ARTS EDUCATION',
  'YODER CENTER',
  'YORBA LINDA PRESCHOOL & KINDERGARTEN',
  'YORK ADVENTIST CHRISTIAN SCHOOL',
  'YORK COUNTRY DAY SCHOOL',
  'YORK PREP SCHOOL',
  'YORK SCHOOL',
  'YORKSHIRE ACADEMY',
  'YORKTOWN CHRISTIAN ACADEMY',
  'YORKTOWN EDUCATION',
  'YORKVILLE CHRISITAN HIGH SCHOOL',
  'YOUNG AMERICANS CHRISTIAN SCHOOL',
  'YOUNG KIDS IN MOTION LEARNING ACADEMY',
  'YOUNG LEARNERS PRESCHOOL',
  'YOUNG SCHOLAR EDUCATION CENTER',
  'YOUNG SCHOLARS MONTESSORI SCHOOL',
  'YOUNG WORLD DAY SCHOOL',
  'YOUNGSVILLE CHRISTIAN SCHOOL',
  'YOURTH PROGRESS LEARNING CENTER',
  'YOUTH DEVELOPMENT INSTITUTE SCHOOL',
  'YOUTH EMERGENCY SERVICES',
  'YOUTH FOCUS MELL-BURTON SCHOOL',
  'YOUTH FOR TOMORROW',
  'YOUTH IN TRANSITION SCHOOL',
  'YOUTH INITIATIVE HIGH SCHOOL',
  'YOUTHBUILD MCLEAN COUNTY ACADEMY',
  'YPC-iTWEB College',
  'YREKA SDA CHRISTIAN SCHOOL',
  'YSC ACADEMY',
  'YTI Career Institute',
  'YULA BOYS HIGH SCHOOL',
  'YULA GIRLS HIGH SCHOOL',
  'YUMA CATHOLIC HIGH SCHOOL',
  'YUMA LUTHERAN SCHOOL',
  'YUMA SDA SCHOOL',
  'Yaak School',
  'Yaakoosge Daakahidi Alt. H.s.',
  'Yaba College of Technology',
  'Yacolt Primary',
  'Yadkin Early College',
  'Yadkin Success Academy',
  'Yadkin Valley Regional Career Academy',
  'Yadkinville Elementary',
  'Yahara Elementary',
  'Yakama Nation Tribal School',
  'Yake Elementary School',
  'Yakima Adult Jail',
  'Yakima Online',
  'Yakima Open Doors',
  'Yakima Satellite Alternative Programs',
  'Yakima Valley Community College',
  'Yakima Valley Technical Skills Center',
  'Yakov E. Netsvetov School',
  'Yakutat School',
  'Yakutsk State University',
  'Yale El',
  'Yale Elementary',
  'Yale Elementary School',
  'Yale Es',
  'Yale Hs',
  'Yale Junior High School',
  'Yale Ms',
  'Yale Senior High School',
  'Yale University',
  'Yalova University',
  'Yamagata University',
  'Yamaguchi Prefectural University',
  'Yamaguchi University',
  'Yamanashi Gakuin University',
  'Yamanashi Medical University',
  'Yamanashi University',
  'Yamato Colony Elementary',
  'Yamhill Carlton Elementary School',
  'Yamhill Carlton High School',
  'Yamhill Carlton Intermediate School',
  'Yampa Valley High School',
  'Yampah Mountain School',
  'Yan Shan University',
  "Yan'an University",
  'Yanbian University',
  'Yanbian University of Science and Technology',
  'Yanbu Industrial College',
  'Yanbu Technical Institute',
  'Yanbu University College',
  'Yangtze Normal University',
  'Yangtze University',
  'Yangzhou University',
  'Yankee Ridge Elem School',
  'Yankeetown Elementary School',
  'Yankeetown School',
  'Yankton High School - 01',
  'Yankton Middle School - 02',
  'Yantacaw School',
  'Yantai Education Institute & Yantai Television University',
  'Yantai University',
  'Yantis El',
  'Yantis School',
  'Yaquina View Elementary',
  'Yarbro Elementary',
  'Yarbrough Es',
  'Yarbrough Hs',
  'Yardville Elementary School',
  'Yardville Heights Elementary School',
  'Yargo Elementary School',
  'Yarmouk Private University',
  'Yarmouk University',
  'Yarmouth Elementary School',
  'Yarmouth High School',
  'Yarnell Elementary School',
  'Yaroslavl International University of Business and New Technologies',
  'Yaroslavl State Medical Academy',
  'Yaroslavl State Pedagogical University',
  'Yaroslavl State Technical University',
  'Yaroslavl State Theatre Institute',
  'Yaroslavl State University',
  'Yasar University',
  'Yashawantrao Chavan Maharashtra Open University',
  "Yasuda Women's University",
  'Yasuj University',
  'Yasuj University of Medical Sciences',
  'Yates Center Elem',
  'Yates Center High',
  'Yates Elem School',
  'Yates Elementary School',
  'Yates H S',
  'Yates Mill Elementary',
  'Yav Pem Suab Academy - Preparing For The Future Charter',
  'Yavapai College',
  'Yavapai County High School',
  'Yavapai Elementary School',
  'Yazd Medical University',
  'Yazd University',
  'Yazoo City High School',
  'Yazoo City Voc Complex',
  'Yazoo County High School',
  'Yazoo County Middle School',
  'Ybarra Academy For The Arts And Technology',
  'Ybmc Charter Sch',
  'Yccs Chtr - Chatham',
  'Yccs-albizu Campus Puerto Rican',
  'Yccs-aspira Pantoja Alt Hs',
  'Yccs-assoc Hse El Cuarto Ano Hs',
  'Yccs-austin Career Ed Cntr Hs',
  'Yccs-cca Academy Hs',
  'Yccs-community Youth Dev Inst Hs',
  'Yccs-innovations Of Arts Integr',
  'Yccs-jane Addams Alternative Hs',
  'Yccs-latino Youth Alternative Hs',
  'Yccs-mckinley-lakeside Leadership',
  'Yccs-olive Harvey Mid College Hs',
  'Yccs-progressive Leadership Acad',
  'Yccs-scholastic Achievement Hs',
  'Yccs-sullivan House Alt Hs',
  'Yccs-truman Middle College Hs',
  'Yccs-virtual Hs',
  'Yccs-west Town Academy Alt Hs',
  'Yccs-westside Holistic Ldr Acad',
  'Yccs-youth Connection Leadership',
  'Yea Program',
  'Yeager El',
  'Yeager Middle School',
  'Yearling Middle School',
  'Yeatman-liddell Middle School',
  'Yeditepe University',
  'Yelets State University',
  'Yellen Learning Center',
  'Yellow Branch Elementary',
  'Yellow Breeches Ms',
  'Yellow Creek School Center',
  'Yellow Medicine East High School',
  'Yellow Medicine East Middle School',
  'Yellow Springs Elementary',
  'Yellow Springs/mckinney High School',
  'Yellowstone Academy 7-8',
  'Yellowstone Academy Elem',
  'Yellowstone College Preparatory',
  'Yellville-summit Elem. School',
  'Yellville-summit High School',
  'Yelm Extension School',
  'Yelm High School 12',
  'Yelm Middle School',
  'Yelm Prairie Elementary',
  'Yemenia University',
  'Yeni Yuzyil University',
  'Yerba Buena Elementary',
  'Yerba Buena High',
  'Yerevan Haibusak University',
  'Yerevan State Medical University',
  'Yerevan State University',
  'Yerger Junior High School',
  'Yerington Elementary School',
  'Yerington High School',
  'Yerington Intermediate School',
  'Yermo Elementary',
  'Yes Academy',
  'Yes Prep - 5th Ward',
  'Yes Prep - Brays Oaks',
  'Yes Prep - East End',
  'Yes Prep - Gulfton',
  'Yes Prep - North Central',
  'Yes Prep - Northline',
  'Yes Prep - Northside',
  'Yes Prep - Southeast',
  'Yes Prep - Southside',
  'Yes Prep - Southwest',
  'Yes Prep - West',
  'Yes Prep - White Oak',
  'Yes Prep North Forest',
  'Yeshiva University',
  'Yeso Elementary',
  'Yeungnam University',
  'Ygnacio Valley Elementary',
  'Ygnacio Valley High',
  'Yick Wo Elementary',
  'Yildirim Beyazit University',
  'Yildiz Technical University',
  'Yinghua Academy',
  'Ymla At John F Kennedy Middle',
  'Ynes B Escobar El',
  'Ynez Elementary',
  'Yoakum H S',
  'Yoakum Int',
  'Yoakum J H',
  'Yoakum Pri',
  'Yocum Elementary School',
  'Yoder Charter Elem School',
  'Yokayo Elementary',
  'Yokkaichi University',
  'Yokohama City University',
  'Yokohama College of Commerce',
  'Yokohama National University',
  'Yolanda Elementary School',
  'Yolo County Rop',
  'Yolo County Special Education',
  'Yolo High',
  'Yolo Junior High',
  'Yoncalla Elementary School',
  'Yoncalla High School',
  'Yonkers Early Childhood Academy',
  'Yonkers High School',
  'Yonkers Middle School',
  'Yonkers Montessori Academy',
  'Yonok University',
  'Yonsei University',
  'Yorba Linda High',
  'Yorba Linda Middle',
  'Yorba Middle',
  'Yorbita Elementary',
  'York',
  'York Academy Regional Charter School',
  'York Alternative High School',
  'York Avenue El Sch',
  'York Center Elem School',
  'York Central Elementary School',
  'York Chester Middle',
  'York Co School Of Technology',
  'York College of Pennsylvania',
  'York Comm High School',
  'York Comprehensive High',
  'York Correctional Institution',
  'York County Community College',
  'York Early College Academy',
  'York Elem.',
  'York Elementary',
  'York Elementary School',
  'York Haven El Sch',
  'York High',
  'York High School',
  'York Intermediate',
  'York International',
  'York J H',
  'York Middle',
  'York Middle School',
  'York Middle/high School',
  'York Preparatory Academy',
  'York River Academy',
  'York Road Elementary',
  'York Suburban Ms',
  'York Suburban Shs',
  'York Technical College',
  'York Twp El Sch',
  'York University',
  'York West End Junior High School',
  'Yorkdale Elementary',
  'Yorker International University Athens',
  'Yorker International University Milano',
  'Yorkship Elementary School',
  'Yorkshire El Sch',
  'Yorkshire Elementary',
  'Yorktown El',
  'Yorktown Elementary',
  'Yorktown Elementary School',
  'Yorktown H S',
  'Yorktown High',
  'Yorktown High School',
  'Yorktown J H',
  'Yorktown Middle',
  'Yorktown Middle School',
  'Yorkville Community School',
  'Yorkville East Middle School',
  'Yorkville Elementary',
  'Yorkville Grade School',
  'Yorkville High School',
  'Yorkville Intermediate School',
  'Yorkville Middle School',
  'Yorkwood Elementary',
  'Yosemite Falls Education Center',
  'Yosemite High',
  'Yosemite High (continuation)',
  'Yosemite Middle',
  'Yosemite National Park El Portal',
  'Yosemite National Park Valley Elementary',
  'Yosemite Park High',
  'Yosemite Rop',
  'Yosemite-wawona Elementary Charter',
  'Yoshikai Elementary School',
  'Yosu National University',
  'You Turn Program',
  'Youens El',
  'Yough Glades Elementary',
  'Yough Intrmd/ms Sch',
  'Yough Shs',
  'Young Achievers',
  'Young Adult Program',
  'Young Adult Programs',
  'Young Audiences Charter School',
  'Young Coleman A Elementary',
  'Young El',
  'Young Elem School',
  'Young Elementary',
  'Young Elementary School',
  'Young High School',
  'Young Intermediate',
  'Young J H',
  'Young Leaders Elementary School',
  'Young Learners',
  'Young Magnet High School',
  "Young Men's Leadership Academy",
  "Young Men's Leadership Academy At Fred R Florence",
  "Young Men's Preparatory Academy",
  'Young Mens Academy',
  'Young Middle Magnet School',
  'Young Middle School',
  'Young Oak Kim Academy',
  'Young Scholars Academy',
  'Young Scholars Academy For Excellence',
  'Young Scholars Academy Of The Bronx (the)',
  'Young Scholars Cs',
  'Young Scholars Kenderton Cs',
  'Young Scholars Of Central Pa Cs',
  'Young Scholars Of Mckeesport Charter Sch',
  'Young Scholars Of Western Pennsylvania C',
  'Young School',
  'Young Voices Academy Of The Bronx',
  "Young Women's College Prep Academy",
  "Young Women's College Prep Charter School Of Rochester",
  "Young Women's Leadership Academy",
  "Young Women's Leadership School",
  "Young Women's Leadership School Of Brooklyn",
  "Young Women's Leadership School Of Queens",
  "Young Women's Leadership School Of The Bronx",
  "Young Women's Steam Academy At Balch Springs Middl",
  "Young Women's Steam Research & Preparatory Academy",
  'Young Womens Leadership Academy/ Young Mens Leadership Acade',
  'Young Womens Leadership Chartr Hs',
  'Young Womens Leadership School',
  'Young Womens Preparatory Academy',
  'Youngblood Int',
  'Youngker High School',
  'Youngs Grove Elementary School',
  'Youngstown Academy Of Excellence',
  'Youngstown Community School',
  'Youngstown Early College',
  'Youngstown State University',
  'Youngstown Virtual Academy',
  'Youngsville Academy',
  'Youngsville El/ms',
  'Youngsville Elementary',
  'Youngsville Hs',
  'Youngsville Middle School',
  'Yountville Elementary',
  'Youree Dr. Middle Advanced Placement Magnet School',
  'Youth & Family Academy Charter',
  'Youth Advancement Academy',
  'Youth Build Phila Cs',
  'Youth Center School',
  'Youth Co-op Charter School',
  'Youth Co-op Preparatory High School',
  'Youth Connections Charter Hs',
  'Youth Court School',
  'Youth Ctr Of High Plains',
  'Youth Development Center',
  'Youth Educational Support School',
  'Youth Elementary School',
  'Youth Emergency Services Inc.',
  'Youth Forestry Camp #2',
  'Youth Forestry Camp #3',
  'Youth Home Jackson Isd',
  'Youth Leadership Academy',
  'Youth Links-heartland Family',
  'Youth Middle School',
  'Youth Opportunities Unlimited',
  'Youth Opportunity Center',
  'Youth Re-engagement',
  'Youth Recovery Home',
  'Youth Services Center',
  'Youth Study Center',
  'Youth Transition Program',
  'Youth Village Detention Center',
  'Youth Villages At Inner Harbour',
  'Youth Works Charter High School',
  'Youthbuild Camden',
  'Youthbuild Charter School Of California',
  'Youthbuild Columbus Community',
  'Youthful Offender Program At High Desert State Prison',
  'Youths Benefit Elementary',
  'Youthsource',
  'Youtz Elementary School',
  'Yowell Elementary',
  'Ypsilanti Community Middle School',
  'Ypsilanti International Elementary School',
  'Ypsilanti New Tech High School',
  'Ypsilanti Stemm Middle College',
  'Yreka Community Day',
  'Yreka High',
  'Yreka Union High Community Day',
  'Ysabel Barnett Elementary',
  'Ysleta El',
  'Ysleta H S',
  'Ysleta Middle',
  'Ysleta Pk Center',
  'Ysmael Villegas Middle',
  'Yturria El',
  'Yu (alice Fong) Elementary',
  'Yu Ming Charter',
  'Yuan Ze University',
  'Yuba City Charter',
  'Yuba City High',
  'Yuba City Independence Academy',
  'Yuba City Special Education Preschool',
  'Yuba College',
  'Yuba County Career Preparatory Charter',
  'Yuba County Special Education',
  'Yuba Environmental Science Charter Academy',
  'Yuba Feather Elementary',
  'Yuba Gardens Intermediate',
  'Yuba River Charter',
  'Yucaipa Early Childhood Education Center',
  'Yucaipa High',
  'Yucca Elementary',
  'Yucca Elementary School',
  'Yucca Heights Elementary',
  'Yucca Loma Elementary',
  'Yucca Mesa Elementary',
  'Yucca Middle',
  'Yucca Valley Elementary',
  'Yucca Valley High',
  'Yugra State University',
  'Yujin Gakuen Elementary School',
  'Yukon College',
  'Yukon Elementary',
  'Yukon Flats Distance Education Program',
  'Yukon Hs',
  'Yukon Ms',
  'Yuksek ihtisas University',
  'Yulee Elementary School',
  'Yulee High School',
  'Yulee Middle School',
  'Yulee Primary School',
  'Yulupa Elementary',
  'Yuma District One Digital Learning Academy',
  'Yuma Elementary',
  'Yuma High School',
  'Yuma Middle School',
  'Yuma Online Distance Academy',
  'Yunnan Agriculture University',
  'Yunnan Normal University',
  'Yunnan University',
  'Yutan Elementary School',
  'Yutan High School',
  'Yvonne B. Miller High School',
  'Yvonne Bowsky Elementary School',
  'Yvonne Harmon',
  'Yvonne Shaw Middle School',
  'Ywla At Bill Arnold',
  'Yüzüncü Yil (Centennial) University',
  'Z - International Academy Of Smyrna',
  'Z L SUNG SDA SCHOOL',
  'Z. John Williams Memorial School',
  'Z.e.c.a. School Of Arts And Technology',
  'ZACHARY CHRISTIAN ACADEMY',
  'ZACHERAL AMISH SCHOOL',
  'ZADIE CREEK SCHOOL',
  'ZANESVILLE CHRISTIAN SCHOOL',
  'ZANESVILLE SDA ELEMENTARY SCHOOL',
  'ZARAPHATH ACADEMY',
  'ZEELAND CHRISTIAN SCHOOL',
  'ZELIA STEPHENS EARLY CHILDHOOD CENTER',
  'ZELTENRIECH SCHOOL',
  'ZEMORAH CHRISTIAN ACADEMY',
  'ZEPHYRHILLS CHRISTIAN ACADEMY',
  'ZHAW - Zürcher Hochschule für Angewandte Wissenschaften',
  'ZHdK - Zurich University of the Arts',
  'ZION BAPTIST ACADEMY',
  'ZION BUDS OF PROMISE CHRISTIAN ACADEMY',
  'ZION CHRISTIAN',
  'ZION CHRISTIAN ACADEMY',
  'ZION CHRISTIAN SCHOOL',
  'ZION EVANGELICAL LUTHERAN SCHOOL',
  'ZION HARVESTER LUTHERAN SCHOOL',
  'ZION LUTHERAN EARLY CHILDHOOD CENTER',
  'ZION LUTHERAN SCHOOL',
  'ZION LUTHERAN SCHOOL & PRESCHOOL',
  'ZION LUTHERAN SCHOOL OF WAYSIDE',
  'ZION-ST JOHN LUTHERAN SCHOOL',
  'ZIPPORAH ABRAMSON CENTER FOR EARLY CHILDHOOD ED',
  'ZUNI CHRISTIAN MISSION SCHOOL',
  'Zablocki Elementary',
  'Zabol University',
  'Zach Elementary School',
  'Zach White El',
  'Zachary Early Learning Center',
  'Zachary Elementary School',
  'Zachary High School',
  'Zachary Lane Elementary',
  'Zachary Taylor Elementary',
  'Zachodniopomorska School of Science and Engineering',
  'Zachry Middle',
  'Zackar Levi Elementary',
  'Zadok Casey Middle School',
  'Zagazig University',
  'Zagreb School of Economics and Management',
  'Zaharis Elementary',
  'Zahedan University of Medical Sciences',
  'Zahns Middle School',
  'Zalma Elem.',
  'Zalma High',
  'Zaman University',
  'Zambian Open University',
  'Zamora Elementary',
  'Zamorano Elementary',
  'Zan Wesley Holmes Jr Middle',
  'Zane Grey Continuation',
  'Zane Grey Elementary School',
  'Zane North Elementary School',
  'Zane State College',
  'Zane Trace Elementary School',
  'Zane Trace High School',
  'Zane Trace Middle School',
  'Zaneis Public School',
  'Zanesville Campus',
  'Zanesville Community School',
  'Zanesville High School',
  'Zanesville Middle School',
  'Zanesville Preschool',
  'Zanewood Community School',
  'Zanjan Institute for Advanced Studies in Basic Sciences',
  'Zanjan University',
  'Zanjan University of Medical Sciences',
  'Zapata Elem Academy',
  'Zapata H S',
  'Zapata Middle',
  'Zapata North El',
  'Zapata South El',
  'Zaporizhzhe National Technical University',
  'Zaporizhzhe National University',
  'Zarka Private University',
  'Zavala El',
  'Zavala Elem',
  'Zavalla El',
  'Zavalla H S',
  'Zawiya Academy',
  'Zawiya University',
  'Zayed University',
  'Zeb Vance Elementary',
  'Zebulon Elementary',
  'Zebulon High School',
  'Zebulon Middle',
  'Zebulon Pike',
  'Zeeland East High School',
  'Zeeland Elementary School',
  'Zeeland High School',
  'Zeeland Quest',
  'Zeeland West High School',
  'Zeferino Farias El',
  'Zehnder Ranch Elementary',
  'Zeiger Elementary',
  'Zeigler-royalton Elem School',
  'Zeigler-royalton High School',
  'Zeigler-royalton Jr High School',
  'Zel & Mary Lowman Elementary',
  'Zela Davis',
  'Zela Elementary School',
  'Zellwood Elementary',
  'Zelma Hutsell El',
  'Zeman Elementary School',
  'Zemmer Campus 89',
  'Zenith Academy',
  'Zenith Academy East',
  'Zenith Academy West',
  'Zenith Accelerated Academy',
  'Zenith Elementary',
  'Zenon J Sykuta School',
  'Zephyr Cove Elementary School',
  'Zephyr El Sch',
  'Zephyr Lane Elementary',
  'Zephyr School',
  'Zephyrhills High Adult Education',
  'Zephyrhills High School',
  'Zervas',
  'Zetech College',
  'Zhanjiang Ocean University',
  'Zhejiang Forestry University',
  'Zhejiang Gongshang University',
  'Zhejiang Normal University',
  'Zhejiang Sci-Tech University',
  'Zhejiang University',
  'Zhejiang University of Technology',
  'Zhengda Software College',
  'Zhengzhou Grain University',
  'Zhengzhou University of Technology',
  'Zhetysu State University',
  'Zhezkazgan Baikonurov University',
  'Zhongnan University of Economics and Law',
  'Zhongnan University of Finance and Economics',
  'Zhongnan University of Technology',
  'Zhongshan University',
  'Zia Elementary',
  'Zia Middle',
  'Zia-ud-Din Medical University',
  'Ziccolella Elementary School',
  'Ziccolella Middle School',
  'Ziebarth Alternative Learning Ctr.',
  'Ziegler William H Sch',
  'Zielanis Elementary',
  'Zilker El',
  'Zillah High School',
  'Zillah Intermediate School',
  'Zillah Middle School',
  'Zilwaukee K8 School',
  'Zimbabwe Ezekiel Guti University',
  'Zimbabwe Open University',
  'Zimmerly Elementary',
  'Zimmerman Elementary',
  'Zimmerman Elementary School',
  'Zimmerman High School',
  'Zimmerman Middle',
  'Zinser Elementary School',
  'Zion Central Middle School',
  'Zion Chapel High School',
  'Zion Public School',
  'Zion-benton Twnshp Hi Sch',
  'Zionsville Community High School',
  'Zionsville Middle School',
  'Zionsville Pleasant View Elem Sch',
  'Zionsville West Middle School',
  'Zitzman Elem.',
  'Zoe Barnum High',
  'Zoe Learning Acad - Ambassador Campus',
  'Zoe Learning Academy',
  'Zoilo Cajigas Sotomayor',
  'Zolfo Springs Elementary School',
  'Zoneton Middle School',
  'Zonguldak Karaelmas University',
  'Zora Ellis Junior High School',
  'Zora Neale Hurston Elementary School',
  'Zue S Bales Int',
  'Zumbro Area Learning Center',
  'Zumbro Ed. Dist. Futures/choice',
  'Zumbro Education District',
  'Zumbrota-mazeppa Credit Recovery',
  'Zumbrota-mazeppa Elementary School',
  'Zumbrota-mazeppa Middle School',
  'Zumbrota-mazeppa Primary School',
  'Zumbrota-mazeppa Senior High',
  'Zundelowitz El',
  'Zuni Elementary',
  'Zuni High',
  'Zuni Hills Elementary School',
  'Zuni Middle',
  'Zupanic High',
  'Zurich School',
  'Zurich University of Applied Sciences Winterthur',
  'Zuyd University',
  'Zwink El',
  'Zwolle Elementary School',
  'Zwolle High School',
  'ifs University College',
  'karamanoglu mehmet bey University',
  'yale-NUS',
  "École Nationale Supérieure d'Ingénieurs de Constructions Aéronautiques",
  'École Polytechnique de Montréal Université de Montréal',
  'École de technologie supérieure Université du Québec',
  'École des Hautes Études Commerciales',
  "École nationale d'administration publique Université du Québec",
  'Örebro University',
  'İgdir University',
  'İskenderun Tecnical University',
  'İstanbul 29Mayis  University',
  'İstanbul University',
  'İstanbul Şehir University',
  'İzmir Katip celebi University',
];

export const getSchools = () => schools;
