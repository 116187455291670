import {
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonRow,
  IonList,
  IonTitle,
  IonCol,
  useIonToast,
  isPlatform
} from "@ionic/react";
import { add } from 'ionicons/icons';
import "./AcademicsSection.css";
import { useHistory } from "react-router-dom";
import { UserService } from "../../services/UserService";
import { useRef, useState, useEffect } from 'react';

import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { ConnectionItem } from '../../data/connection-list';
import PendingConnectionListItem from '../../components/PendingConnectionListItem';

interface AcademicsSectionProps {
  data: { title: string };
  className: string;
  academicData?: AcademicData;
  myProfile: boolean;
  myUserType?: string;
  userId: number;
}

export interface AcademicData {
  gpa?: string;
  sat?: string;
  act?: string;
  extracurriculars?: string[];
  transcript?: string;
  transcriptValidated?: boolean;
}

interface ExportProps {
  isLoading: boolean;
}

const AcademicsSection: React.FC<AcademicsSectionProps> = ({
  data,
  userId,
  className,
  academicData,
  myProfile,
  myUserType
}) => {
  const history = useHistory();
  const userService = new UserService();
  const transcriptModal = useRef<HTMLIonModalElement>(null);
  const [pendingConnections, setPendingConnections] = useState<ConnectionItem[]>([]);
  const [canViewTranscript, setCanViewTranscript] = useState<boolean>(false);
  const [present, dismiss] = useIonToast();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadTranscriptPendingConnections();
    loadTranscriptConnections();
    setIsLoading(false)
  }, []);

  function showMessage(message: string, duration: number) {
    let errColor = 'primary';

    present({
      buttons: [{ handler: () => dismiss() }],
      message,
      color: errColor,
      duration: duration,
      position: 'top',
    });
  }

  function loadTranscriptPendingConnections() {
    userService
      .getPendingTranscriptConnections()
      .then((res) => res.json())
      .then((data) => {
        setPendingConnections(data)
      })
  }

  function requestTranscript() {
    userService
      .createTranscriptConnection(userId)
      .then((res) => res.json())
      .then((data) => {
        showMessage("Transcript connection request sent!", 2000)
      })
  }

  function loadTranscriptConnections() {
    let myUserID = userService.getUserId();
    userService.getTranscriptConnections()
      .then((res: any) => res.json())
      .then((data: any) => {
        data.forEach((connection: { id: number, requester: number, requestee: number, status: number }) => {
          if (connection.requester === myUserID && connection.status === 2) {
            setCanViewTranscript(true);
          }
        });
      });
  }

  function rejectTranscriptConnection(conn: ConnectionItem) {
    userService.rejectTranscriptConnection(conn.id)
      .then(() => {
        loadTranscriptPendingConnections();
      });
  }

  function acceptTranscriptConnection(conn: ConnectionItem) {
    userService.acceptTranscriptConnection(conn.id)
      .then(() => {
        loadTranscriptPendingConnections();
      });
  }

  const ExportButton = ({ isLoading }: ExportProps) => {
    const handleClick = async () => {
      showMessage("Downloading transcript...", 2000);

      const pdfUrl = `https://api.urpplus.com/user/${userId}/transcript`
      const fileName = 'transcript.pdf';
      const path = 'transcript.pdf';

      try {
        console.log("Sending Fetch: ")
        const response = await fetch(pdfUrl);
        console.log("Response: ", response)
        if (!response.ok) {
          throw new Error(`Error fetching PDF: ${response.statusText}`);
        }

        const arrayBuffer = await response.arrayBuffer();
        const base64 = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );

        await Filesystem.writeFile({
          path,
          data: base64,
          directory: Directory.Documents,
        });

        dismiss();
        setTimeout( () => {
          let msg = 'Transcript Downloaded.'
          if (isPlatform('ios')) {
            msg = "Download complete! Find it in URP+ folder in Files app."
          }
          showMessage(msg, 5000)
        }, 500);

      } catch (error) {
        console.error('Error:', error);
      }
    };

    return (
      <IonButton
        onClick={handleClick}
        disabled={isLoading}
        style={{
          height: '2rem',
          float: 'right',
        }}
      >
        {isLoading ? 'Loading...' : 'Download Transcript'}
      </IonButton>
    );
  };

  const academicList = [];

  if (academicData?.gpa !== '') {
    academicList.push(
      <IonItem lines="none" className="urp-section-item" detail={false}>
        <div>GPA</div>
        <div>{academicData?.gpa}</div>
      </IonItem>
    );
  }

  if (academicData?.sat !== '') {
    academicList.push(
      <IonItem lines="none" className="urp-section-item" detail={false}>
        <div>SAT Score</div>
        <div>{academicData?.sat}</div>
      </IonItem>
    );
  }

  if (academicData?.act !== '') {
    academicList.push(
      <IonItem lines="none" className="urp-section-item" detail={false}>
        <div>ACT Score</div>
        <div>{academicData?.act}</div>
      </IonItem>
    );
  }

  if (academicData?.extracurriculars !== []) {
    academicData?.extracurriculars?.forEach(ec => {
      if (ec !== '') {
        academicList.push(
          <IonItem lines="none" className="urp-section-item urp-academics-item" detail={false}>
            <div>Extracurricular</div>
            <div>{ec}</div>
          </IonItem>
        );
      }
    });
  }

  if (academicData?.transcript && academicData?.transcript !== "") {
    academicList.push(
      <IonItem lines="none" className="urp-section-item" detail={false}>
        <div>Transcript</div>
        <div>Uploaded</div>
        {academicData?.transcriptValidated && (
          <div style={{paddingLeft: '1rem'}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#1DA1F2"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
          </div>
        )}
      </IonItem>
    );
  }


  if (academicData?.transcript && academicData?.transcript !== "" && !myProfile && myUserType === 'coach' && !canViewTranscript) {
    academicList.push(
      <div className="game-schedule-add-container" onClick={ () => requestTranscript()}>
        <div className="game-schedule-add-container-btn">
          <div style={{ backgroundColor: '#00D6B6' }}>
            <IonIcon
              style={{
                position: 'relative',
                top: '1px',
                color: 'white',
                width: '17px',
                height: '17px',
              }}
              icon={add}
            ></IonIcon>
          </div>
          <div
            style={{ textAlign: 'end', paddingLeft: '5px' }}
          >
            <IonLabel style={{ color: '#00D6B6' }}>
              <h3>Request Transcript</h3>
            </IonLabel>
          </div>
        </div>
      </div>
    )
  }

  if (academicData?.transcript && academicData?.transcript !== "" && !myProfile && myUserType === 'coach' && canViewTranscript) {
    academicList.push(
      <IonLabel style={{ color: '#00D6B6', marginRight: '20px' }}>
        <h3 style={{float: 'left', marginTop: '12px'}}>View Transcript Below</h3>
      </IonLabel>
    )
    academicList.push(<ExportButton isLoading={isLoading} />)
    academicList.push(
      <div className="game-schedule-add-container">
        <div className="pdf-container">
          <iframe
            title="pdf-viewer"
            src={academicData?.transcript}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
          ></iframe>
        </div>
      </div>
    )
  }

  if (academicData?.transcript && academicData?.transcript !== "" && myProfile) {
    academicList.push(
      <div className="game-schedule-add-container" onClick={ () => loadTranscriptPendingConnections()}>
        <div className="game-schedule-add-container-btn">
          <div
            id="open-transcript-modal"
            style={{ textAlign: 'end', paddingLeft: '5px' }}
          >
            <IonLabel style={{ color: '#00D6B6' }}>
              <h3>View Transcript Requests</h3>
            </IonLabel>
          </div>
        </div>
      </div>
    )
  } 

  if (myProfile && academicList.length === 0) {
    academicList.push(
      <div className="urp-highlight-add-media-container urp-highlight-add-media-container">
        <p className="urp-highlight-add-media-title">
          Uh! Looks like you haven’t added any academics to your account yet
        </p>
        <p
          className="urp-highlight-add-media-text"
          onClick={e => {
            e.preventDefault();
            history.push(`edit-athlete/${userId}`);
          }}
        >
          Add Academics
        </p>
      </div>
    );
  }

  if (!myProfile && academicList.length === 0) {
    academicList.push(
      <div className="urp-highlight-add-media-container">
        <p className="urp-highlight-add-media-title">
          Uh! Looks like the user hasn't added any academics yet
        </p>
      </div>
    );
  }

  return <div className={className}>
    <IonContent scrollY={true}>
      {academicList}
      <IonModal
        // isOpen={editMode}
        className="new-deal-modal"
        ref={transcriptModal}
        initialBreakpoint={0.9}
        breakpoints={[0, 0.9]}
        trigger="open-transcript-modal"
        // onWillDismiss={(ev) => onWillDismiss(ev)}
      >
        <IonHeader>
          <IonToolbar
            style={{
              '--background': 'white',
              padding: '10px 5px',
              marginTop: '40px',
            }}
          >
            <IonButtons slot="start">
              <IonButton
                style={{ '--color': '#9BC9C1' }}
                onClick={() => transcriptModal.current?.dismiss()}
              >
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle>View Transcript Requests</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollY={true}>
        <IonRow className="connections-container">
          <IonCol>
            {pendingConnections
              .map(c => <PendingConnectionListItem key={c.user_id + 1} connection={c} onDelete={(conn) => rejectTranscriptConnection(conn)} onAccept={(conn) => acceptTranscriptConnection(conn)} />)
            }
            {pendingConnections.length < 1 && (
              <h5 style={{textAlign: 'center'}}>No transcript requests</h5>
            )}
          </IonCol>
        </IonRow>
        </IonContent>
      </IonModal>
    </IonContent></div>;
};

export default AcademicsSection;
