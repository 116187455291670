import { environment } from '../environments/environment';
import { Auth } from 'aws-amplify';

type CustomProgressEvent = {
  loaded: number;
  total: number;
};

export class BaseService {
  getHeaders(others: { [key: string]: string } | null | undefined): {
    [key: string]: string;
  } {
    const storage: any = window.localStorage.getItem('persistedState');
    const user = storage ? JSON.parse(storage) : undefined;

    const headers: { [key: string]: string } = {};
    if (user) {
      headers['X-URP-UserId'] = user.user['user_id'];
    } else {
      headers['X-URP-UserId'] = '1';
    }
    return Object.assign(headers, others || {});
  }

  async fetch(
    method: string,
    path: string,
    data?: any,
    headers?: { [key: string]: string } | null | undefined,
    onProgress?: (event: CustomProgressEvent) => void
  ) {
    const url = `${environment.serverUrl}${path.startsWith('/') ? '' : '/'}${path}`;
    headers = headers || {};
    let body: any = undefined;
    if (data !== undefined && data !== null) {
      if (data instanceof FormData) {
        body = data;
      } else {
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(data);
      }
      headers['Content-length'] = data.length;
    }

    const storage: any = window.localStorage.getItem('persistedState');
    const user = storage ? JSON.parse(storage) : undefined;
    if (user) {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();
      headers['Authorization'] = accessToken;
    }

    const options: RequestInit = {
      method,
      cache: 'no-cache',
      headers: this.getHeaders(headers),
      body,
    };

    if (onProgress) {
      options.signal = new AbortController().signal;
    }

    const response = await fetch(url, options);

    if (onProgress) {
      const reader = response.body?.getReader();
      if (reader) {
        let receivedLength = 0;
        let totalLength = parseInt(response.headers.get('content-length') || '0', 10);
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          receivedLength += value?.length || 0;
          onProgress({ loaded: receivedLength, total: totalLength });
        }
      }
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response
  }
}
