export interface UniversityFull {
  alpha_two_code: string;
  country: string;
  domain: string;
  name: string;
  web_page: string;
  img: string;
  id: number;
  acronym: string;
}

export const universities: UniversityFull[] = [
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'calbaptist.edu',
    name: 'California Baptist University',
    web_page: 'http://www.calbaptist.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAbimT1H_-D47E9NTqtLuhXOJvWx4qkJ_RZdQV4cYN02s-Ybt1G1cWLA',
    id: 0,
    acronym: 'CBU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stevenson.edu',
    name: 'Stevenson University',
    web_page: 'http://www.stevenson.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrE9u63qSAlYX--BxC_J9pf6Bxs_OLjVzDOW0qJex3trTUYUizU242bw',
    id: 1,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mbl.edu',
    name: 'Marine Biological Laboratory',
    web_page: 'http://www.mbl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBFKhLOgF4wpkmtxVkcFYGwX-VsLeEdCNHvHjzlwqYBM6g1LdOBeoz2dKT',
    id: 2,
    acronym: 'MBL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'maryville.edu',
    name: 'Maryville University',
    web_page: 'http://www.maryville.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPPmEnh0J4zJSCSzEVO10Wo3uvaaDAmP6YIJmwopklLaEQ3ZaKDtawZRE',
    id: 3,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aii.edu',
    name: 'The Art Institutes',
    web_page: 'http://www.aii.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk0gd1eGqW5LtdWTFsjgrdvpghsRd1vh2yLSN8q-7JOGWIzNGFWs4Akw',
    id: 4,
    acronym: 'AI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umc.edu',
    name: 'University of Mississippi Medical Center',
    web_page: 'http://www.umc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2tiswea0tA0XasNcaFlPdkZpNYuu237v5aKh_GdtW9EfzJBYArvyxFQ',
    id: 5,
    acronym: 'UMMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uco.edu',
    name: 'University of Central Oklahoma',
    web_page: 'http://www.uco.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJsRiXt6HG917xRtf5xrayXjumbctwr7mdBnIIW9FIwFb80Hrm3sGStsGE',
    id: 6,
    acronym: 'UCO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hallmarkuniversity.edu',
    name: 'Hallmark University',
    web_page: 'http://www.hallmarkuniversity.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkjFjTySaMPhs_XjLnlsxkuVzmmWBbIhCMH8I5ECXCFMspbH-yq7OgVNs',
    id: 7,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'keiseruniversity.edu',
    name: 'Keiser University',
    web_page: 'http://www.keiseruniversity.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQmhIEKOamHV7xK8aMuvvqE2CM6EvCN0GT_jGvEpLYRo06XrZwG268CFY',
    id: 8,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjrstate.edu',
    name: 'St. Johns River State College',
    web_page: 'http://www.sjrstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQibRhPUgnxmuxIvrF8gqaBdy88vqzviIGMWQnH3-LejesoIFO_2BGOafQ',
    id: 9,
    acronym: 'SJRSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'irsc.edu',
    name: 'Indian River State College',
    web_page: 'http://www.irsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo0K5qVWVyR0N9SzsieJ_yVfyZql6v5-gNOIrBxd8A365dkzYZcitZSKk',
    id: 10,
    acronym: 'IRSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'barnard.edu',
    name: 'Barnard College',
    web_page: 'http://www.barnard.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxm3GAeEdA422J4STJBTUX696O38XQ3w1A27WbG4gPXEZ0ZRBzJlJ7LYg',
    id: 11,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tesu.edu',
    name: 'Thomas Edison State University',
    web_page: 'http://www.tesu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjrltBIHy42YT3z8NV5ZgQ_6YvkHDnfZ1rnb19fzKZKmTZnkns54fbwtQ',
    id: 12,
    acronym: 'TESU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'calsouthern.edu',
    name: 'California Southern University',
    web_page: 'http://www.calsouthern.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFxskHGgaKf42o1aRSSkSzhlPlSYrtirta2d3vHjtoptS1APJO16WFyA',
    id: 13,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'laverne.edu',
    name: 'University of La Verne',
    web_page: 'http://www.laverne.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0jDY7POIDkvEapsLsS7CXfL_gg4-VhgHMncd8jdcw9meLvSCT7k72LA',
    id: 14,
    acronym: 'ULV',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'washjeff.edu',
    name: 'Washington &amp; Jefferson College',
    web_page: 'http://www.washjeff.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBBoG9NhzgKbsEXvW1ASo7XsgqYJeNvmJQPcqlPA9Ce4UF4Wmcg9e3QfU',
    id: 15,
    acronym: 'W&JC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uopeople.edu',
    name: 'University of the People',
    web_page: 'http://www.uopeople.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsn76_gDu3sAlcWtg_nWsQpjvv4RAsgD2HPVQxFvV2a_PENa0CNy1ViK4',
    id: 16,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'touro.edu',
    name: 'Touro College',
    web_page: 'http://www.touro.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuun5OaEkF2ePE8PVFke0PiULI-C9k9b4TDx85AgfwE_vEqGANhFYa89DMJA',
    id: 17,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'njcu.edu',
    name: 'New Jersey City University',
    web_page: 'http://www.njcu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4lH2qgXjo6EMhCrhqAdIjlHf6mflkxIGesE3fJ5JlRw7Qn3x9YQWKAno',
    id: 18,
    acronym: 'NJCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lander.edu',
    name: 'Lander University',
    web_page: 'http://www.lander.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2E-lEmfiVaOTi-wD5zfvIWvBNB7za9a0Npkpd83EezFDCgGROwOkVnGg',
    id: 19,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunymaritime.edu',
    name: 'SUNY Maritime College',
    web_page: 'http://www.sunymaritime.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkdS0LILH68k_xYRdsZ3G1_o1Q8gdxYH-Fu_Jx997Tfp9EmhnbLqLHrrg',
    id: 20,
    acronym: 'SMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lesley.edu',
    name: 'Lesley University',
    web_page: 'http://www.lesley.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7i-SXbaAJpFxNS6fNK_WQHD3cnlUUXUN9K3gbI88UjJNzk9BizUIs4w',
    id: 21,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lauruscollege.edu',
    name: 'Laurus College',
    web_page: 'http://www.lauruscollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNjR6glerVNubpuLNSQFtgS3wOQhaNJ5OgGhsz7YAVKaHBllDcdNBaIOk',
    id: 22,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'np.edu',
    name: 'National Park College',
    web_page: 'http://www.np.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzQ2nUg_RpSy_5qkZlxLQmuvTlyqi49GO4vC0fGCIum6vC6rzZKa2bQ',
    id: 23,
    acronym: 'NPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oc.edu',
    name: 'Oklahoma Christian University',
    web_page: 'http://www.oc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN0oGZb6UC8iWVZHVlUzwNpcdVuzTn9lqXE3fgUbg0EnjyDMFJHa4rDI84',
    id: 24,
    acronym: 'OCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sdcc.edu',
    name: 'San Diego Christian College',
    web_page: 'http://www.sdcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAmtq2Tu-yzpcBYzRJq0bnr71TtXvyDj4YK155yZn8o9DOywAnWWA6QY8',
    id: 25,
    acronym: 'SDCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sans.edu',
    name: 'SANS Technology Institute',
    web_page: 'http://www.sans.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCIDEvqfDXR8OdZys3EY5cUfa44yoQCcf6Ai1CQucWP7Q0MqQNOBzdXbw',
    id: 26,
    acronym: 'STI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'phsc.edu',
    name: 'Pasco-Hernando State College',
    web_page: 'http://www.phsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBcBdVg_d8smYAj67_ofgeTbAtpNP1GCDCxiMeG75cACcHkcfgBmUHtQ',
    id: 27,
    acronym: 'PSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncf.edu',
    name: 'New College of Florida',
    web_page: 'http://www.ncf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYZAkOIjE8lbHL3kLH_MY4ZFDEPnU7uh6hOBOFi35e4-XTlg4dfHpOooI',
    id: 28,
    acronym: 'NCF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gcu.edu',
    name: 'Grand Canyon University',
    web_page: 'http://www.gcu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuIDh1mepWVYK8iVr3gVQFxSya6nLcmsOzkcbaPjIr0-I1nXa_SEokbw',
    id: 29,
    acronym: 'GCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cshl.edu',
    name: 'Cold Spring Harbor Laboratory',
    web_page: 'http://www.cshl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRB5T4RqKzVY2bryi5RRpV7Tgo3GUyfKhc3Ah5fA_yUTcQGbRjf4L3aVY',
    id: 30,
    acronym: 'CSHL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'asurams.edu',
    name: 'Albany State University',
    web_page: 'http://www.asurams.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEuhcPYbEW0M-qWV-mo_cwKlq9HbLu43dDzr3tGk2d30SPKu68xIkPcLg',
    id: 31,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lasell.edu',
    name: 'Lasell College',
    web_page: 'http://www.lasell.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSC3U2AK0JE4Ld9WGARfHn2dO1UeU-c5iCbE3MM9BwAhTZ6DL12X6M9iaZ',
    id: 32,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: '4cd.edu',
    name: 'Contra Costa Community College District',
    web_page: 'http://www.4cd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR5dryhF6-yyVjwYWU2WyNG5Nnn6FFPsWdTBTZ0J0mp16m_mRGNxs-mts',
    id: 33,
    acronym: 'CCCCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msmary.edu',
    name: "Mount St. Mary's University",
    web_page: 'http://www.msmary.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSanMjm8v3XlfEeaq8XU8ZrvmhEQvuiL_eDoW4lMZ7gUxDURtI-16dG9Q',
    id: 34,
    acronym: 'MSMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwfsc.edu',
    name: 'Northwest Florida State College',
    web_page: 'http://www.nwfsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5NDnf8xt_mLEwGqXITG5Ud7cIxGjHT3vhLfUxrrjR_hCkVXF3PC_cRsY',
    id: 36,
    acronym: 'NFSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wvwc.edu',
    name: 'West Virginia Wesleyan College',
    web_page: 'http://www.wvwc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5Cpok_M69z3_UqBXW8YclYyy1wlhGbJcopymwjdf-2uckITQR3r7Tjmk',
    id: 37,
    acronym: 'WVWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utica.edu',
    name: 'Utica College',
    web_page: 'http://www.utica.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd65JKpgLPijjEdwDxP9LYGCe-PKxe1GI0Bgld4Ga2UhHoMxvQkJ2vxv4',
    id: 38,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccnn.edu',
    name: 'Career College of Northern Nevada',
    web_page: 'http://www.ccnn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQstdLcL81fMuxqufiIrjZQTo5YtHPu2twhg77scsn1u-afvIJFJJvNPA',
    id: 39,
    acronym: 'CCNN',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ung.edu',
    name: 'University of North Georgia',
    web_page: 'http://www.ung.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy-q5P29RlP2w22MhU-3BP0Ug5yOD4hotljSYdWRYFFRfDlChPDiYt6Q',
    id: 40,
    acronym: 'UNG',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'principia.edu',
    name: 'The Principia',
    web_page: 'http://www.principia.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0goIkhGovfZQP3IN8E3HD8pdfHmzAlzPyN___sOSljz0iajRQIpu97A',
    id: 41,
    acronym: 'P',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lynn.edu',
    name: 'Lynn University',
    web_page: 'http://www.lynn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-hQnqTmU5HOAaUaHIwl1AHcVsa8jDWJKipul1Uqwr_au2Mg5tADtSYsY',
    id: 42,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cccs.edu',
    name: 'Colorado Community College System',
    web_page: 'http://www.cccs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6L_Eo7gWJoe8aqUiGKZpdQYt5DrHO-jR8bXVJjY3zuHZ8QyeeHNqUJg',
    id: 43,
    acronym: 'CCCS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'valenciacollege.edu',
    name: 'Valencia College',
    web_page: 'http://www.valenciacollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUTUI6s8VBbyo_3dqUOhE7nPajx6tHBGH6QFuf2zlByxRw31m51Jbdu7I',
    id: 44,
    acronym: 'VC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'spcollege.edu',
    name: 'St. Petersburg College',
    web_page: 'http://www.spcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9XKnqRhdcVZGfkQGPmNZsIrlobHXY-ZoK98V_sDJKOw1S2DKB6o2BPFJl',
    id: 45,
    acronym: 'SPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msbcollege.edu',
    name: 'Globe University &amp; Minnesota School of Business',
    web_page: 'http://www.msbcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMAotJzs0D7WVy5ztsISnHTo-BGTWydu_zLSFqCMdgydmzUUiEoaxnM-8',
    id: 46,
    acronym: 'GU&MSB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'everest.edu',
    name: 'Everest College',
    web_page: 'http://www.everest.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtSwYA9EHRNjjLkmkHdD9OKHl47plLgYIsik2epPcDmVPaTOrFlxcsew',
    id: 47,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swri.edu',
    name: 'Southwest Research Institute',
    web_page: 'http://www.swri.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAXdpGyjiDNMqsrnQ8Dsvn1iHFF-DHcY0E1iLbVc4DpqdNFX4b_N2Xsl8',
    id: 48,
    acronym: 'SRI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'apus.edu',
    name: 'American Public University System',
    web_page: 'http://www.apus.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4Mub23TwUS5hkxu37mlNWUwDcPYAwbxMXaCIiaSrVkIHFfB-ZX2OW_g',
    id: 49,
    acronym: 'APUS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fullsail.edu',
    name: 'Full Sail University',
    web_page: 'http://www.fullsail.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQYbQsmxXzSw-woteISwj_YbgBEG9MeWZ7FZ9ZzIGkOEJpjhnqn7YUDm0',
    id: 50,
    acronym: 'FSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'yti.edu',
    name: 'YTI Career Institute',
    web_page: 'http://www.yti.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-Iidtd5dDwOSCYWh4f72DyEixYhdk_lRbM6kKDQIZOVxeWgLGoS__qxg',
    id: 51,
    acronym: 'YCI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dliflc.edu',
    name: 'Defense Language Institute Foreign Language Center',
    web_page: 'http://www.dliflc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5kBTyz_cIhQ3079lRVdsaeM6WU0HrVyOft-DIneru53C1W5UuRDkodJg',
    id: 52,
    acronym: 'DLIFLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'columbusstate.edu',
    name: 'Columbus State University',
    web_page: 'http://www.columbusstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxy3tJssyFGo8u8dOy2E-XWXJMlmA4RJLuVIgx4tMswGb2lk51ioHbcw',
    id: 53,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'moreheadstate.edu',
    name: 'Morehead State University',
    web_page: 'http://www.moreheadstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14943college.jpg',
    id: 54,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csuglobal.edu',
    name: 'Colorado State University - Global Campus',
    web_page: 'http://www.csuglobal.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm74Nk9oUzowk7oGapGJhaO72KZ6kA3Lc3fdFhB4g-beeGPzD6mau_9io',
    id: 55,
    acronym: 'CSU-GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ndus.edu',
    name: 'North Dakota University System',
    web_page: 'http://www.ndus.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCCcRMupY2peVQh5w32nhZf6rcnAOLpbKDKUVVF5W2acPmMxdJcjWMUg',
    id: 56,
    acronym: 'NDUS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uhd.edu',
    name: 'University of Houston-Downtown',
    web_page: 'http://www.uhd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZHOcpVZGBWFICh89YGXu5Ry-_iMBZJ9bkqQiMvCI1vo3QAyu3TryICQ',
    id: 57,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stratford.edu',
    name: 'Stratford University',
    web_page: 'http://www.stratford.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkm1EbHH99zq20j-rW41-eJmqu00vwXK_8etw0lYhAETYrIg6SmeRkXQ',
    id: 58,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cpp.edu',
    name: 'California Polytechnic State University Pomona',
    web_page: 'http://www.cpp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtWQ_g_gss61B6XI0MxD_zXdAi0a93rqDfH6ZGn2AiZP8SKN2HhbFNgjo',
    id: 59,
    acronym: 'CPSUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'baker.edu',
    name: 'Baker College',
    web_page: 'http://www.baker.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpFRj4I61lsqOSqm54OUV4utzgGiOGgQ1X67Py6Lcl7JpsyymbT3eg1g',
    id: 60,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tcnj.edu',
    name: 'The College of New Jersey',
    web_page: 'http://www.tcnj.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTbZaVYGjTM5pcNManVQSqMrFc-szenspTw_R_3cIAGdLiOcyrdyFEP_NJzg',
    id: 61,
    acronym: 'CNJ',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'acu.edu',
    name: 'Abilene Christian University',
    web_page: 'http://www.acu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/16032college.jpg',
    id: 62,
    acronym: 'ACU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'adelphi.edu',
    name: 'Adelphi University',
    web_page: 'http://www.adelphi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4S5C7c457aJIZtAYFfIve4kbFEwvk9OTiSX0ep_hZ-wLGVjUOxuOizQ',
    id: 63,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'agnesscott.edu',
    name: 'Agnes Scott College',
    web_page: 'http://www.agnesscott.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKlTvaVoDwEYwUxPbx_yldIV8fJIGEH9-d4xTMzEcs_mMQ1niqUTam_ZU',
    id: 64,
    acronym: 'ASC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'atc.edu',
    name: 'Aiken Technical College',
    web_page: 'http://www.atc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHpe-_lXl8wI74-okn6kzodeoIriqrkmcuqSpknk6yL04JHxQgiwkb9w',
    id: 65,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'afit.af.mil',
    name: 'Air Force Institute of Technology',
    web_page: 'http://www.afit.af.mil/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVgI13RFbCWgOJcQM4DpEEjxJIaSzMF7mGVMigG-bqSg_8YkybjBmnZY4',
    id: 66,
    acronym: 'AFIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aamu.edu',
    name: 'Alabama A&M University',
    web_page: 'http://www.aamu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/13859college.jpg',
    id: 68,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alasu.edu',
    name: 'Alabama State University',
    web_page: 'http://www.alasu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/13861college.jpg',
    id: 69,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aloma.edu',
    name: 'Alamo Colleges',
    web_page: 'http://www.alamo.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn1pTYQpzGehD6z5dvwjGKfvqsYmRv-OeWsvWLrkRkecmwZ4EgyruuLaM',
    id: 70,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'acofi.edu',
    name: 'Albertson College of Idaho',
    web_page: 'http://www.acofi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9r_LFWmNFw0lrAx18icvRDjANlatJtIW0XaMob4fvBz4bBLSshX76yQ',
    id: 71,
    acronym: 'ACI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'albion.edu',
    name: 'Albion College',
    web_page: 'http://www.albion.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfbrOFd86l1o7_LnmJZyoSL6G2oiFVeHqs-vV_fYn83IhQSMhCapNfcg',
    id: 72,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alfred.edu',
    name: 'Alfred University',
    web_page: 'http://www.alfred.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvoYhuUBR-TRShpFJhzKzMIHevZELjj10jaK3jmfMasjAisC-PUTmfXa4',
    id: 73,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alleg.edu',
    name: 'Allegheny College',
    web_page: 'http://www.alleg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3wvPKSB_DkTfNfglzMjzLTfk_73xIDiBCOdRf8KPexikzjDWS_hc9yw',
    id: 74,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'allencol.edu',
    name: 'Allentown College of Saint Francis de Sales',
    web_page: 'http://www.allencol.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4hzwLiKv7mx8JEwSTPP9yOFetyq-xlH7NX_IEkdzYpPbNPC148iv2x_s',
    id: 75,
    acronym: 'ACSFDS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alma.edu',
    name: 'Alma College',
    web_page: 'http://www.alma.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXiWYfJsqJmCQi4dbvjFvHHLUugNOZ8Dp_j3xTBJaHWgY-m1N7aI1Ehg',
    id: 76,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alverno.edu',
    name: 'Alverno College',
    web_page: 'http://www.alverno.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS0TE2I1jnDm2nwMUH-rElN9mNHRd7xu8V9aCf9YSlCDOpTx9ax07-J33-Tg',
    id: 77,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ambassador.edu',
    name: 'Ambassador University',
    web_page: 'http://www.ambassador.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5SmSiKyRHleL_SxsgLRcuUsjG0-tbB8a_wYR8kuSalJgic5KnHVF0CA',
    id: 78,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'amercoastuniv.edu',
    name: 'American Coastline University',
    web_page: 'http://www.amercoastuniv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiOoTtiAuAhCfEPxrj46VfmVeWTBYDSnHLy50g4rTND0oU-7-E8XTTlw',
    id: 79,
    acronym: 'ACU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aic.edu',
    name: 'American International College',
    web_page: 'http://www.aic.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT63oYN-rKamUADRZMYQmNk8OzrhF_CYZhxrKRiXz4yKGahyZOl2bZVjRE',
    id: 80,
    acronym: 'AIC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'american.edu',
    name: 'American University',
    web_page: 'http://www.american.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnAzEBZgkqUnEz9EEwdQW1ATjewIhco6sRuFFLcKHGB8bU3oM7Yzm-hg',
    id: 81,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'amherst.edu',
    name: 'Amherst College',
    web_page: 'http://www.amherst.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfxpecL3ibSjnguKPuy4j5G_KngQzvvPstj8dXUz3bEdkoPExyS_O4Ylg',
    id: 82,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'andrews.edu',
    name: 'Andrews University',
    web_page: 'http://www.andrews.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC-mRBHa1461SnrhgCGi9UABcbIAO8w8tDC0v3VJBFwiGM9dQPanqD_w',
    id: 83,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'angelo.edu',
    name: 'Angelo State University',
    web_page: 'http://www.angelo.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDbXGiKU3Q2xnTpp1dmEc0jVXc9WV-B11Cymwg_0MDfeZAdTcBtp2UmA',
    id: 84,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aacc.edu',
    name: 'Anne Arundel Community College',
    web_page: 'http://www.aacc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOcxorXIh51kcdcBTbRfIJv3Mly4ue8FF49gJyPGLvTLbW_caziz_nK-g',
    id: 85,
    acronym: 'AACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'antiochne.edu',
    name: 'Antioch New England',
    web_page: 'http://www.antiochne.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhYBlSdD2tXXStFLoSpsYXlahXQDnWh--ghrbUoPa5hDmq96xq-Pe0yA',
    id: 86,
    acronym: 'ANE',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'antioch.edu',
    name: 'Antioch University',
    web_page: 'http://www.antioch.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS27RBkJl0ComWiRHj2-ZgKIDDxs7OFccfQhZD1HUgt9eXBCNOR8ZC1iPk',
    id: 87,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'antiochla.edu',
    name: 'Antioch University - Los Angeles',
    web_page: 'http://www.antiochla.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMwzVMZnt6Kq2rR2wavc5SC4t18HBqQCv0mawB0fnf6qA_j2CXhL9zkJTUmQ',
    id: 88,
    acronym: 'AU-LA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'seattleantioch.edu',
    name: 'Antioch University - Seattle',
    web_page: 'http://www.seattleantioch.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkso2smlEXvjCvrRGUpZRZRYgT8Ah162Hdw_P4B_57vkcjvNf8l4-oZg',
    id: 89,
    acronym: 'AU-S',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'appstate.edu',
    name: 'Appalachian State University',
    web_page: 'http://www.appstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Appalachian-State-University.svg',
    id: 90,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aquinas.edu',
    name: 'Aquinas College',
    web_page: 'http://www.aquinas.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0DPyD8kF764623yDgkIon2K2h6YGkIX_zR4aahDIQh4UtbS9NywIPkw',
    id: 91,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'arcadia.edu',
    name: 'Arcadia College',
    web_page: 'http://www.arcadia.edu/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTwF-zULvD6M2dc99uapGH8vodw-B-IdJ736AlJ53e-AIiLTNflRv6ONA',
    id: 92,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'asu.edu',
    name: 'Arizona State University',
    web_page: 'http://www.asu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Arizona-State-University.svg',
    id: 93,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'azwestern.edu',
    name: 'Arizona Western College',
    web_page: 'http://www.azwestern.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKXcNa7C8Cp8d1QOMk6V1ulHqQiXMmYPaTZhshDIhoFcNGLr-Npqq90g',
    id: 94,
    acronym: 'AWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'astate.edu',
    name: 'Arkansas State University',
    web_page: 'http://www.astate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Arkansas-State-University.svg',
    id: 95,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'atu.edu',
    name: 'Arkansas Tech University',
    web_page: 'http://www.atu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvy_e1Riit81a7UUS3Q2fuMnEOBbgJ1UiQ4z5C1WgT_lxXx73mh5p9UQ',
    id: 96,
    acronym: 'ATU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'armstrong.edu',
    name: 'Armstrong State College',
    web_page: 'http://www.armstrong.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWn4mKwsGoGozoo91ZfjeO5LqwS6QKvltFReUgejqPbXtfwLQYu-d9w7s',
    id: 97,
    acronym: 'ASC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ashland.edu',
    name: 'Ashland University',
    web_page: 'http://www.ashland.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLK-iuVajRo6DQ7SreWwNKGAquWa_Ko4t35FbPGH8-NNvGncxxgfQyzqA',
    id: 98,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'assumption.edu',
    name: 'Assumption College',
    web_page: 'http://www.assumption.edu/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcR3U7J7QpkfCdyYpdsT98fkXcRLCMbWzZ_8a0CfpsmrgRRdIQCKzsLljQE',
    id: 99,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'auburn.edu',
    name: 'Auburn University',
    web_page: 'http://www.auburn.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Auburn-University.svg',
    id: 100,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aum.edu',
    name: 'Auburn University at Montgomery',
    web_page: 'http://www.aum.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkNICogAIHHNNjWgIc1-J_ShpUXYdLEv00RfFmsTxyMqfuw0Fm3e7EWB0',
    id: 101,
    acronym: 'AUAM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'augsburg.edu',
    name: 'Augsburg College',
    web_page: 'http://www.augsburg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt_DptimUHpp-IL8zmJDI1S7RDRz4jKMh46PFJdLTUarV4GPeIbfz1beQ',
    id: 102,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'augustana.edu',
    name: 'Augustana College (IL)',
    web_page: 'http://www.augustana.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6J0CmZpXA7Pe0Bs5Azq4nV2KRG9evc8G5kXHnHlJoD1ODGrV3uuFx1_U',
    id: 103,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'augie.edu',
    name: 'Augustana College (SD)',
    web_page: 'http://www.augie.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ0BCgtifwbOsyB5xIUbP2Aj1QCVj9-YhFJhN5KwpPBsonUwCKkxBtQ1M',
    id: 104,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'augusta.edu',
    name: 'Augusta University',
    web_page: 'http://www.augusta.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2kfodOT4NQrgbUZWeVOE1b_8Q0sY3_scXKK5h7MJ6ea1aPZ3MESgGk3lOwg',
    id: 105,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aurora.edu',
    name: 'Aurora University',
    web_page: 'http://www.aurora.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyhCKnXXthf-sUD_CUGIrx0HRo3mg2coOI50NaMHoqr3fsM7Np0Sen2A',
    id: 107,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'austincollege.edu',
    name: 'Austin College',
    web_page: 'http://www.austincollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNa2FXQFoTxrfJoA9oXdxNjta0J_kqysdhtmUkZ9GgvOMP4e-8H3bs4A',
    id: 108,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'austincc.edu',
    name: 'Austin Community College',
    web_page: 'http://www.austincc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZd0PQoFY5iljP30XZD4dhWIC63F24vIJrJXPDA0Ayr4-f3_gNqt00dg',
    id: 109,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'apsu.edu',
    name: 'Austin Peay State University',
    web_page: 'http://www.apsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/13939college.jpg',
    id: 110,
    acronym: 'APSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'averett.edu',
    name: 'Averett College',
    web_page: 'http://www.averett.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC1vnXKxYDHFs2LquijH_rgav5RHYRaMkFHUGPYP5jkbmmCeOR2ZOKeg',
    id: 111,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'avila.edu',
    name: 'Avila College',
    web_page: 'http://www.avila.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw05hFQ2j9bWYBs_MDXM06Xe5AgCylFuc5hxRZVEbnLyhVaULkHhM2Mg',
    id: 112,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'apu.edu',
    name: 'Azusa Pacific University',
    web_page: 'http://www.apu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-K9oW-ksF3k2aWM8eAOguSMqRPwK5GkMC_kdkXWbSOBu4RyD4EHhqNbo',
    id: 113,
    acronym: 'APU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'babson.edu',
    name: 'Babson College',
    web_page: 'http://www.babson.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIeQEUkSbrOvF24IvzjqZD2GuhIHysk751EewV8eNUWbCemZD4Zfm8cSw',
    id: 114,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bakeru.edu',
    name: 'Baker University',
    web_page: 'http://www.bakeru.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRm4KfsH1tGxdn3HU36qpb5xPgWQuDnovhbxcyOFNNJgBZNNyTguHbGH1cM',
    id: 115,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'baldwinw.edu',
    name: 'Baldwin-Wallace College',
    web_page: 'http://www.baldwinw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNPZqxI0TAOPNxPlCroUT4pVeV0huyBJu26UgIlSG4uVXejQatNhmrG_k',
    id: 116,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bsu.edu',
    name: 'Ball State University',
    web_page: 'http://www.bsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Ball-State-University.svg',
    id: 117,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bbc.edu',
    name: 'Baptist Bible College',
    web_page: 'http://www.bbc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDQjOceOzK5Sj9rycvYO_lZA7J0sCbg-af1NL32XQFCMOaIk1Isukjdp0',
    id: 118,
    acronym: 'BBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bard.edu',
    name: 'Bard College',
    web_page: 'http://www.bard.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAWVdb4MXdGG-IRaoxde0vnc6AbMQNlTXNhoIb_CAFqxb0uJABNx8l3Q',
    id: 119,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'barry.edu',
    name: 'Barry University',
    web_page: 'http://www.barry.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfBsrRXIC_Q81IALbzMkBWNegiAF-JFGZXxJR-rz9ceyVOgU49uyplFw',
    id: 120,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bastyr.edu',
    name: 'Bastyr University',
    web_page: 'http://www.bastyr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAnPPZQyGfL7FJC2am9JKqzyQVXg0zPXW9omxypWlC_MRWoamcjw8wv60',
    id: 121,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bates.edu',
    name: 'Bates College',
    web_page: 'http://www.bates.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbvgpuGIHF86RAWwFsc25uwjpW3OwEriLzdlOdlLXdE0BCa7dj5K_FF10',
    id: 122,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bcm.edu',
    name: 'Baylor College of Medicine',
    web_page: 'http://www.bcm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAdyX92QQ3FUQCK3JO1trezLI2bnOFdmBW_dflKWSCDOqmYU7LRswPSw',
    id: 123,
    acronym: 'BCM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'baylor.edu',
    name: 'Baylor University',
    web_page: 'http://www.baylor.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Baylor-University.svg',
    id: 124,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bellevue.edu',
    name: 'Bellevue University',
    web_page: 'http://www.bellevue.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-2XuDnILw7RvRgqHYNY2zz1tQ5tSxhsdLG-9APAO7NF20WuUe2mlDBA',
    id: 125,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'belmont.edu',
    name: 'Belmont University',
    web_page: 'http://www.belmont.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT-dhbEMIG6NmwQ-lNYcLnB2HRynT_BqWAjWKFpV2GduC85q3R512VHg',
    id: 126,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'beloit.edu',
    name: 'Beloit College',
    web_page: 'http://www.beloit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrR8VYPpAQsEOioHjFd2QcOEvJCHyQFwGofstn_hSvgsmdc3gF8lzSstyn',
    id: 127,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bemidjistate.edu',
    name: 'Bemidji State University',
    web_page: 'http://www.bemidjistate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSokK_2mxq8UO1_Bh7qTuCvnCWN1Uc65dc9_yfbDlUZXL1HFJebCNF0EA',
    id: 128,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'benedictine.edu',
    name: 'Benedictine College',
    web_page: 'http://www.benedictine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdmjekBnQkVvVxAWfEXr9Lf6AAFaa3BfKIwLisu5ZkCc-t6NP1eVBCouU',
    id: 129,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bennington.edu',
    name: 'Bennington College',
    web_page: 'http://www.bennington.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReEyyP7rXRmWD9UHDJ6Kp9DI2yv3yP2qgmpX3zjI-olUp1FRzNDEZwomY',
    id: 130,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bentley.edu',
    name: 'Bentley College',
    web_page: 'http://www.bentley.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvZF5gb06ucIuuR_3kcB3n5tCJW4AXqmvXsfJHeO2s__SLd9msNKkH1w',
    id: 131,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'berea.edu',
    name: 'Berea College',
    web_page: 'http://www.berea.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0jYASrZ1929dG50rsY1ze98tB6z-De2tYHaN26Sj8-iqT0Bvtxn3Vpnc',
    id: 132,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'berklee.edu',
    name: 'Berklee College of Music',
    web_page: 'http://www.berklee.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYrRxM13n780k7DxwUnGIUdnooyp61BNjfwpIffVtOt-LY-5I7JecOo9U',
    id: 133,
    acronym: 'BCM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bethanywv.edu',
    name: 'Bethany College',
    web_page: 'http://www.bethanywv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRIkg9jly938NP41q9PYSWCI7v33p3YrT240syT32gs7S-SoHXwK4M-w',
    id: 134,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bethelks.edu',
    name: 'Bethel College (KS)',
    web_page: 'http://www.bethelks.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0ygEkIYowHzgx7mbMesdpFK4GukXjZQO1pDMePugI0mAGBpA-Dvq5k8I',
    id: 135,
    acronym: 'BC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bethel.edu',
    name: 'Bethel University',
    web_page: 'http://www.bethel.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzs26HdNyDAhfThBKeiiqI-XK3axiTTsalxuT3TRgEAKsTITE-0DdiAQ',
    id: 136,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'biola.edu',
    name: 'Biola University',
    web_page: 'http://www.biola.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6BhXT5_paYbyHQCPsHpzZBMt2WEy3JWDNP97QD5VmyM2OH9ce2vbUDg',
    id: 137,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bsc.edu',
    name: 'Birmingham-Southern College',
    web_page: 'http://www.bsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcOSHI7YGLIVZxWCxxtF14MkYcSe9gBgUz9kDarJFTgf3Ghl1FH_ItitQ',
    id: 138,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bhsu.edu',
    name: 'Black Hills State University',
    web_page: 'http://www.bhsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE2ysrMVmGDFuL61W5zeenSwQkO1TMbOfgFuWCu8bCrqCfHBRoA51MD4g',
    id: 139,
    acronym: 'BHSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'upenn.edu',
    name: 'University of Pennsylvania',
    web_page: 'http://www.upenn.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15694college.jpg',
    id: 140,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bluffton.edu',
    name: 'Bluffton College',
    web_page: 'http://www.bluffton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeCW0yRNsDCebfTQgv-KpqeZQFvZe3xWNuLa9V0XNLFaTws50ISLpnbTk',
    id: 141,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bju.edu',
    name: 'Bob Jones University',
    web_page: 'http://www.bju.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNa-B6mkMQLg3NnlBzY_PW7nF5pdmFUjCvLOetcX3mt_BB4uG4J7qXtHI',
    id: 142,
    acronym: 'BJU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'idbsu.edu',
    name: 'Boise State University',
    web_page: 'http://www.boisestate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Boise-State-University.svg',
    id: 143,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bc.edu',
    name: 'Boston College',
    web_page: 'http://www.bc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Boston-College.svg',
    id: 145,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bgsp.edu',
    name: 'Boston Graduate School of Psychoanalysis',
    web_page: 'http://www.bgsp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZF6RMJQMy1mo1tzvQhV0QJABhAfGrBx5rwaA9az1jnItgZy9V-oWczSY',
    id: 146,
    acronym: 'BGSP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bu.edu',
    name: 'Boston University',
    web_page: 'http://www.bu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQR5oqF-C8tD0g1V8bWKYo7oqotQcuk-P1yy4vABKaYO4VIWw6dtPNJht4',
    id: 147,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bowdoin.edu',
    name: 'Bowdoin College',
    web_page: 'http://www.bowdoin.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBfKL256HU1-Nce933ssIVjmifkBPBEyQjSOQqkswl7U93rWzb0enCeoY',
    id: 148,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bowiestate.edu',
    name: 'Bowie State University',
    web_page: 'http://www.bowiestate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaEZ1l3-KZBTG-1M63gVY4Xky1tkLzOzCKDc571MjO6OBzYNO25BXThg',
    id: 149,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bgsu.edu',
    name: 'Bowling Green State University',
    web_page: 'http://www.bgsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Bowling-Green-State-University.svg',
    id: 150,
    acronym: 'BGSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bradley.edu',
    name: 'Bradley University',
    web_page: 'http://www.bradley.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiI9Qu4aT_70tvQmAcP618Dy2h9pP4BaqArWzSIvBiUY5LzEOcIFBY9g',
    id: 151,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'brandeis.edu',
    name: 'Brandeis University',
    web_page: 'http://www.brandeis.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhELBy68gn98U0QGxSL3heyKF4WFkDx3gTncnbEpFTeMBa_SIOlJ1qLQ',
    id: 152,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'brenau.edu',
    name: 'Brenau University',
    web_page: 'http://www.brenau.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkdNPe-kujV1tC8RzIVlWQ429BK288JZluRYQXzwXnTZG5o2YTcrWft8w',
    id: 153,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'briar-cliff.edu',
    name: 'Briar Cliff College',
    web_page: 'http://www.briar-cliff.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6Ye2s95ziHiTgEUzmjLV2tQLxoVacgFTBA0y5FT5LBepprUmNJoMi_6mQ',
    id: 154,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bridgewater.edu',
    name: 'Bridgewater College',
    web_page: 'http://www.bridgewater.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJMLZ3_D4vPzmhc7z4eEksn155w0SYz4dMzwKxTZAlGpPx3xuANdjIOZ4B',
    id: 155,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'byu.edu',
    name: 'Brigham Young University',
    web_page: 'http://www.byu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Brigham-Young-University.svg',
    id: 156,
    acronym: 'BYU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'byui.edu',
    name: 'Brigham Young University - Idaho',
    web_page: 'http://www.byui.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8lRwT9qrh61kuFd2RwHZuMatPfTptqCtlk4v81xckKxdM4_b-qY2f92Q',
    id: 157,
    acronym: 'BYU-I',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'byuh.edu',
    name: 'Brigham Young University Hawaii',
    web_page: 'http://www.byuh.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBvO_UyvtS8xVDfPA-AfS7-KmxeQdjN2sbpA5KVRmByQxJWrcztrffnQ',
    id: 158,
    acronym: 'BYUH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'broward.edu',
    name: 'Broward College',
    web_page: 'http://www.broward.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYOZycscG0g4VqkfwvhTIG80zBOoyb5J3w4GHc5sAlWuIPmK-sSsaTfl0',
    id: 159,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'brown.edu',
    name: 'Brown University',
    web_page: 'http://www.brown.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14036college.jpg',
    id: 160,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bryant.edu',
    name: 'Bryant University',
    web_page: 'http://www.bryant.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14039college.jpg',
    id: 161,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'brynmawr.edu',
    name: 'Bryn Mawr College',
    web_page: 'http://www.brynmawr.edu/college',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWxY0Caj-5Uzui3_gS16xfYtLvmdAthR0_lLaISZUtua6Pe4cIqGYZ0A',
    id: 162,
    acronym: 'BMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bucknell.edu',
    name: 'Bucknell University',
    web_page: 'http://www.bucknell.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14041college.jpg',
    id: 163,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bvu.edu',
    name: 'Buena Vista University',
    web_page: 'http://www.bvu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3DSOMc-gFxj3osylbVUlZ4Xtalguda8vAJgE6prjivWZIXQd79LW1204',
    id: 164,
    acronym: 'BVU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'butler.edu',
    name: 'Butler University',
    web_page: 'http://www.butler.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/butler-bulldogs-logo.png',
    id: 165,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'calcoast.edu',
    name: 'California Coast University',
    web_page: 'http://www.calcoast.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMF9zHfXSJtrO6hhiYLmzpYlOjafxsxQf1UwD9upuQkaAFmcQj1JBLM3g',
    id: 166,
    acronym: 'CCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'caltech.edu',
    name: 'California Institute of Technology',
    web_page: 'http://www.caltech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRic5NG70pQIbVT00GDDPkLREj_3SwplglM0lbUIU-BI0NESBVKRkgshg',
    id: 167,
    acronym: 'CIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'callutheran.edu',
    name: 'California Lutheran University',
    web_page: 'http://www.callutheran.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgWXMjdAqtEiObeyQr7XRynbr0yZcorkxsICcwUW8XWwGuMu9YR7nMALc',
    id: 168,
    acronym: 'CLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csum.edu',
    name: 'California Maritime Academy',
    web_page: 'http://www.csum.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuHIZ7Tu_v6KDqAlVnXEiOFZF__xK8O0D5etKRciIpbLLvbtqNDdFMndM',
    id: 169,
    acronym: 'CMA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cnuas.edu',
    name: 'California National University',
    web_page: 'http://www.cnuas.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi9aW5YA_E5IdEHyXV2-bN4OQ2i1WZXf-U34FvpGX_MolVRCFwM4fHWik',
    id: 170,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'calpoly.edu',
    name: 'California Polytechnic State University San Luis Obispo',
    web_page: 'http://www.calpoly.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14054college.jpg',
    id: 171,
    acronym: 'CPSUSLO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csub.edu',
    name: 'California State University Bakersfield',
    web_page: 'http://www.csub.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaExTT7FFPAqNMNyE7-HZrjG4eywt42ASiqfNQ3MWwW9NUigkjjwqLn4M',
    id: 172,
    acronym: 'CSUB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csuchico.edu',
    name: 'California State University Chico',
    web_page: 'http://www.csuchico.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHJ6AI8e8wTsmsFxwOJyyNAOT4GYrNTUKBiO-Sa5DlIKssSox6AyrK7UE',
    id: 174,
    acronym: 'CSUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csudh.edu',
    name: 'California State University Dominguez Hills',
    web_page: 'http://www.csudh.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAbpKSIXKzkLthfZJDsdIc4dzRvdSllf50t2maaMf8_ixL-XMYBCo6sQ',
    id: 175,
    acronym: 'CSUDH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csufresno.edu',
    name: 'California State University Fresno',
    web_page: 'http://www.csufresno.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/California-State-University-Fresno.svg',
    id: 176,
    acronym: 'CSUF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fullerton.edu',
    name: 'California State University Fullerton',
    web_page: 'http://www.fullerton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw7pY-dP_jm-O-Mk6xJef8wAR0rKpY4pflAkL06BJqBaizwRC42gV7xcA',
    id: 177,
    acronym: 'CSUF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csuhayward.edu',
    name: 'California State University Hayward',
    web_page: 'http://www.csuhayward.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkQdMxEICRhC-NP16nKOqv-bTY0vWGqEw-bw20PMvDU0v6s43M8VIuVIU',
    id: 178,
    acronym: 'CSUH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csulb.edu',
    name: 'California State University Long Beach',
    web_page: 'http://www.csulb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9Q0EsIBcxpAmKWUB_tvLM6v-7Wu1wqA94Pc_JncW6yN0B_qCXPdjXvQ',
    id: 179,
    acronym: 'CSULB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'calstatela.edu',
    name: 'California State University Los Angeles',
    web_page: 'http://www.calstatela.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4Jl8_hcd13LkVvouNx6Omu3YUWjoVFai01ypMP_0TXR-to1ajriaJjg',
    id: 180,
    acronym: 'CSULA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'monterey.edu',
    name: 'California State University Monterey Bay',
    web_page: 'http://www.monterey.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvWGn43UwCQzZIcEIODC2MlhqieZCIrzcRt2z7akYEplosr6Xu7RATsw',
    id: 181,
    acronym: 'CSUMB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csun.edu',
    name: 'California State University Northridge',
    web_page: 'http://www.csun.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYy_q1aWZs5Ua-rtdGWftvdgTfudW4saiEQcQIICfsl_iFW32NnRLQock',
    id: 182,
    acronym: 'CSUN',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csus.edu',
    name: 'California State University Sacramento',
    web_page: 'http://www.csus.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/california-state-university.png',
    id: 183,
    acronym: 'CSUS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csusb.edu',
    name: 'California State University San Bernardino',
    web_page: 'http://www.csusb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShOX12d8LFLDJJ5HZr87VzLdl2rtjBCqslULiYDYtdLCBiLf1fQBp00CM',
    id: 184,
    acronym: 'CSUSB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjsu.edu',
    name: 'California State University San Jose',
    web_page: 'http://www.sjsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhkkwtJj61Vuc3HJdiA0K-_fsl6L8kJWnn7gDQOUV2kIAkN92pFciGXg',
    id: 185,
    acronym: 'CSUSJ',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csusm.edu',
    name: 'California State University San Marcos',
    web_page: 'http://www.csusm.edu/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcStI34rNtEPm6AcxdUSfe5Soyr7X0Sisx34aCj5RIJ4PINKGqNPSEKm1TVg',
    id: 186,
    acronym: 'CSUSM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csustan.edu',
    name: 'California State University Stanislaus',
    web_page: 'http://www.csustan.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEtgk33wjgCO5sw2zdwuwHso-qKoZYzjWxIA5lAlEh88TSfpH0XUPWzg',
    id: 187,
    acronym: 'CSUS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'calstate.edu',
    name: 'California State University System',
    web_page: 'http://www.calstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoe47AkinFBS6fEnf4nKm2nt_x9mfBqMx5B-ydisoZTUDzFWcrOn7vOwM',
    id: 188,
    acronym: 'CSUS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cup.edu',
    name: 'California University of Pennsylvania',
    web_page: 'http://www.cup.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlcTNLczEKA2sIEwv6ilJGTpbF5tJIJiIu2JyBIFdPXSqA5GrSSyC4dIE',
    id: 189,
    acronym: 'CUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'calvin.edu',
    name: 'Calvin College',
    web_page: 'http://www.calvin.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjGZkJntZNK1p-_ftgTMrUIbYvpmbwqGulxa4C2U1E4v3Cx4otbjBxyA',
    id: 190,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cameron.edu',
    name: 'Cameron University',
    web_page: 'http://www.cameron.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoxZWhC7AYYYjOL29PX1ghRtDA1Kptk5pAeitRYoxUbvyZM6sH92SojKA',
    id: 191,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'campbellsville.edu',
    name: 'Campbellsville College',
    web_page: 'http://www.campbellsville.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8DW2Zcwnj7H0uLxaDHhQWCjSF80a6kfrUB4pWGybhHwsBiKoDuat4rA',
    id: 192,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'campbell.edu',
    name: 'Campbell University',
    web_page: 'http://www.campbell.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14080college.jpg',
    id: 193,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'canisius.edu',
    name: 'Canisius College',
    web_page: 'http://www.canisius.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYfytvAkPAIbu4mJyWW81zTqodkfcXlVC3GQ-3huOhRKdFJPLYOI2Lmg',
    id: 194,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'capella.edu',
    name: 'Capella University',
    web_page: 'http://www.capella.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkCfrOBng9fAsl-Blvic0_93qvWk_qJxgkX9OkhmSbvwcPezNg4aU-36k',
    id: 195,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'captechu.edu',
    name: 'Capitol Technology University',
    web_page: 'http://www.captechu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR057NqZRvZmb18WpxRwCHOk3KGs_mKQlxHozS6zMTWmwb-0cI4brpsg6w',
    id: 197,
    acronym: 'CTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carleton.edu',
    name: 'Carleton College',
    web_page: 'http://www.carleton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfQ4W_DqK_N8U8YIVOe3wQWRcuxeRNBiKtUgk4dp76kJrjoHAJnZ4wewg',
    id: 198,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carlow.edu',
    name: 'Carlow College',
    web_page: 'http://www.carlow.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOE_KztF-brtBmqywUsEkXsW_xvXzRic-QsVp1ifSsxPgaZRYg0tRZGug',
    id: 199,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cmu.edu',
    name: 'Carnegie Mellon University',
    web_page: 'http://www.cmu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCDVJ0WPDLCmMsZ5CJExV0tOfgnJqw1lu7kzQvQUnaXT6hd_TmbohAWug',
    id: 200,
    acronym: 'CMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carroll.edu',
    name: 'Carroll College',
    web_page: 'http://www.carroll.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAjsY3ok1OQ7WgvZuHu1kSN7cJw6brSpC55EQQHz6EqRsYqnRI_IIfvw',
    id: 201,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carrollu.edu',
    name: 'Carroll University',
    web_page: 'http://www.carrollu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmqzYmqkgOKsjWsBIBBTyRm2zCBXM5dF0t5hMgmTpiqBSLg0GIlmd2yw',
    id: 202,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cn.edu',
    name: 'Carson-Newman College',
    web_page: 'http://www.cn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfygtvzxud7chvog2I9Ihg8KmJlCUKN7MpyMqIGWZznLWGlAzEQngkuNY',
    id: 203,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carthage.edu',
    name: 'Carthage College',
    web_page: 'http://www.carthage.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThzYEqiTmC6j0QgqwaP3KPfdHcsqOH3i3wO0YPF8epsXWltNuWYPLi6f8',
    id: 204,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'case.edu',
    name: 'Case Western Reserve University',
    web_page: 'http://www.case.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwCeDdIv-FRFXKTap1-zbkac47TexGtW797FmeD7HX2u_Nq5gZNDY-Rkg',
    id: 205,
    acronym: 'CWRU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'castleton.edu',
    name: 'Castleton State University',
    web_page: 'http://www.castleton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSq_KnCkgXPTllatfpwf8zn6hMEkIQ0YO-_e3EkX7IODS4pzvJz6Ys92g',
    id: 206,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cua.edu',
    name: 'Catholic University of America',
    web_page: 'http://www.cua.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYUSvXFtbKpYOTdWzuKJMfZkFfOny3dYY2iyBAZNlJ3rV_0OBHU6YdZ5I',
    id: 207,
    acronym: 'CUA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cedarville.edu',
    name: 'Cedarville College',
    web_page: 'http://www.cedarville.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJwAk1lkXiveDujH2k5oHA5VEwyF42YXNoBd0fWCUlVPdzndRXE0UIYN4',
    id: 208,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'centenary.edu',
    name: 'Centenary College of Louisiana',
    web_page: 'http://www.centenary.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS94nv7SB4gtC5-KbBpoCPGBZBjOMHkKz5lOB2jOz7F7gHNJsbF_cKHBA',
    id: 209,
    acronym: 'CCL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'central.edu',
    name: 'Central College',
    web_page: 'http://www.central.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZvJHGDgCvWDFRILctbl7d6UpZ9_Rb_IwzFUNwzBrxcSwG9A8McjrgrKY',
    id: 210,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccsu.edu',
    name: 'Central Connecticut State University',
    web_page: 'http://www.ccsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14130college.jpg',
    id: 211,
    acronym: 'CCSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cmc.edu',
    name: 'Central Methodist College',
    web_page: 'http://www.cmc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1wxxJaBO7Gs58L7xtNR6ZHqgWIwPM76J00HLuta8p7IOeZWhHJjh5dhc',
    id: 212,
    acronym: 'CMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cmich.edu',
    name: 'Central Michigan University',
    web_page: 'http://www.cmich.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14135college.jpg',
    id: 213,
    acronym: 'CMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cwu.edu',
    name: 'Central Washington University',
    web_page: 'http://www.cwu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYG-_xgbgO-vrCDE7tQIYqgbLGifAAeneeLBtsDWSIe6kZ4URV-nPfjA',
    id: 214,
    acronym: 'CWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'centre.edu',
    name: 'Centre College',
    web_page: 'http://www.centre.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRqoBcJPd3Bs6diyPdYuH6zvXUOx3Yrvw1dXKq_HcGTx-cMttLKINjNw',
    id: 215,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csc.edu',
    name: 'Chadron State College',
    web_page: 'http://www.csc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8hqB89moALYqKXyRrzORpHY9RlRLWGLyW8ErK_P9BD87sjKHFJ5fa82w',
    id: 216,
    acronym: 'CSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'champlain.edu',
    name: 'Champlain College',
    web_page: 'http://www.champlain.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw2n0diFXEpT6XRIyXi_jt0JYR2qjMoiiB2u-bOzjkXVwQPfpSZBqm7dc',
    id: 217,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chapman.edu',
    name: 'Chapman University',
    web_page: 'http://www.chapman.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrYRKeYxcgLiVooEZBvheI8Itj-Nd65t1M5TOKYaAemWpiVk-glQ7AAMc',
    id: 218,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chatham.edu',
    name: 'Chatham College',
    web_page: 'http://www.chatham.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDjU7zB4gRpuC-KI3S_nL5x53B1175uT_gs9lC8inUidzy7q363nVqndE',
    id: 219,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chesapeake.edu',
    name: 'Chesapeake College',
    web_page: 'http://www.chesapeake.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyomDem2kpXsShlGvx2ClwpyJ8MYp50YcIIrJCsjCZwP37Xblm3-NEbA',
    id: 220,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cheyney.edu',
    name: 'Cheyney University',
    web_page: 'http://www.cheyney.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJEISJVemjL8ByVU1z4P54Ukt5A8yM8eIEssbKOX8PuWICFjW86fsJeA',
    id: 221,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csopp.edu',
    name: 'Chicago School of Professional Psychology',
    web_page: 'http://www.csopp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9WM4gvi6mSqsCsS7kNaqAF3AJpwF_fcj1gdHTm-yQKjMYsyiUzpu8qDg',
    id: 222,
    acronym: 'CSPP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cbu.edu',
    name: 'Christian Brothers University',
    web_page: 'http://www.cbu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUmbeCjWdhyT6F-1U7bvOLxiC_1fATEo1ho5Vf21PkCDqT6ImqqpzKSQ',
    id: 223,
    acronym: 'CBU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cts.edu',
    name: 'Christian Theological Seminary',
    web_page: 'http://www.cts.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_Y1MhZKyDHo3ygQjtg8t7FUR9z_7aVK5Zi_XBIdqUBhlGzB8qQYkTZxhy',
    id: 224,
    acronym: 'CTS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cnu.edu',
    name: 'Christopher Newport University',
    web_page: 'http://www.cnu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0eEzlKqjI36PTSAGbv5Q095Mr65QcmfkGbYOODSlRb9he2wDbQ2j8UE4',
    id: 225,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cityu.edu',
    name: 'City University',
    web_page: 'http://www.cityu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2A0NnF7Y1zptJXeFo5xHznMHZSwpxRtzK78II0TKe3Sa3WmGkSsldq48',
    id: 226,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cuny.edu',
    name: 'City University of New York',
    web_page: 'http://www.cuny.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtkgRcYq2HpDczh99rxPWwi2zJZnSveAKL51gIPmoZrvy-j51k-9ZfSNk',
    id: 228,
    acronym: 'CUNY',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cgs.edu',
    name: 'Claremont Graduate School',
    web_page: 'http://www.cgs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw4R2ixt5khirouJNAcBC8JevPVwoSmzTOWmzdjLC5aKFv8DfFmUGx1m0a',
    id: 229,
    acronym: 'CGS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mckenna.edu',
    name: 'Claremont McKenna College',
    web_page: 'http://www.mckenna.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9bRk3mYA0lK8J-KGhJtVKRzNz8xXd8HoBC99HlSi-tOGIf1SRnlWw7cI',
    id: 230,
    acronym: 'CMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clarion.edu',
    name: 'Clarion University of Pennsylvania',
    web_page: 'http://www.clarion.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6MSNrWdJ_kc8wA7JejiaYWVPxrTD8zMvLRlQax7VmNKOaiQ5Ry5-fmg',
    id: 231,
    acronym: 'CUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clarke.edu',
    name: 'Clarke College',
    web_page: 'http://www.clarke.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj0-m1F1TxCb1nsqY2tg3uG3nSseuVMXoWN5m3zLjOMg1JzXxa5S2wdQA',
    id: 232,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clarkson.edu',
    name: 'Clarkson University',
    web_page: 'http://www.clarkson.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2OCM85dJx7O3GohbkRHk8LWATvSLxnEamD46Bxc8K3XCBGjruAayJpA',
    id: 233,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clarku.edu',
    name: 'Clark University',
    web_page: 'http://www.clarku.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScMUxSxNJtqyZQbvWEgyyPqB0poH-UfhuvEjWfRwv1LaUtAjGqJkCo7_g',
    id: 234,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clayton.edu',
    name: 'Clayton State College',
    web_page: 'http://www.clayton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL-BbIfEx82XL19ZdyaxzsxVN7pdfc9-B_9CF9ES1C8nQK_saATCRm5g',
    id: 235,
    acronym: 'CSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clemson.edu',
    name: 'Clemson University',
    web_page: 'http://www.clemson.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Clemson-University.svg',
    id: 236,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csuohio.edu',
    name: 'Cleveland State University',
    web_page: 'http://www.csuohio.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXWSKHn_zdLXm42LBA34qRFH-NBUFZcoedZn7A9vXjkvSFeauTuJuDzg',
    id: 237,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clinch.edu',
    name: 'Clinch Valley College',
    web_page: 'http://www.clinch.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL_4xrSI3-8SLWP_zwDzVMax4iC-1XSBrQhpcmjNozktzpY6xw58ktSg',
    id: 238,
    acronym: 'CVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coastal.edu',
    name: 'Coastal Carolina University',
    web_page: 'http://www.coastal.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Coastal-Carolina-University.svg',
    id: 239,
    acronym: 'CCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cccd.edu',
    name: 'Coast Colleges',
    web_page: 'http://www.cccd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvtr_boiJhplCBImeJ4batamtkgnmgi2sQkOg0XKb0coYqEfcPcJOHdN4',
    id: 240,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coe.edu',
    name: 'Coe College',
    web_page: 'http://www.coe.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTaLGa-4XXEcYUdwJN8treEn3GLxGejnzE838Zc7I8-Uk30qAEyPWhzQk',
    id: 241,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coker.edu',
    name: 'Coker College',
    web_page: 'http://www.coker.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS47D_7XRdVEr98TZaa16jK5umBoZ7B6CaJ11sU468YLKWS0nHAfELjalo',
    id: 242,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'colby.edu',
    name: 'Colby College',
    web_page: 'http://www.colby.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB2XOO0VVN7YjTvLVQITgKElKdoiZzEYqZFnVgVbElAtjqt8mRHE-uPc8',
    id: 243,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'colgate.edu',
    name: 'Colgate University',
    web_page: 'http://www.colgate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14202college.jpg',
    id: 244,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cofc.edu',
    name: 'College of Charleston',
    web_page: 'http://www.cofc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB6qmdL2iYtBfB7kCINwBfOuILwUlQcE6gPFCUSor05uC9Otpyjty4s8M',
    id: 245,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ceu.edu',
    name: 'College of Eastern Utah',
    web_page: 'http://www.ceu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6CCiuAOJ4BUZb57mCn3LDenjSrMvRqDsfICIR_vaD0MXl6nMhqH2J6A',
    id: 246,
    acronym: 'CEU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cnr.edu',
    name: 'College of New Rochelle',
    web_page: 'http://www.cnr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzcGraj1s52FQLF9jwBvM8oq8ZPd_Y2D1A35tuZYBzeqy5YpEX-JfZDw',
    id: 247,
    acronym: 'CNR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csbsju.edu',
    name: 'College of Saint Benedict',
    web_page: 'http://www.csbsju.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaa6PJ71tQRHUSSAOFbfB56_b1QbRwQ_vOQNFio-QZ3h2h-BZdIbxB9Q',
    id: 248,
    acronym: 'CSB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stkate.edu',
    name: 'College of Saint Catherine',
    web_page: 'http://www.stkate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqwYjKg5EDxyrkiKpSDApIG5Y-wzeZo5QnLomAKbuRIzVqPrgznx7OePs',
    id: 249,
    acronym: 'CSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'strose.edu',
    name: 'College of Saint Rose',
    web_page: 'http://www.strose.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl0TrGWsPCKRXzE0podA3pTiC9qL4RWlbsNuS5ygQDtRxluJG0vQUbYg',
    id: 250,
    acronym: 'CSR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stfrancis.edu',
    name: 'College of St. Francis',
    web_page: 'http://www.stfrancis.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF9R6GEzpnHZ55v4aNttKRdo1Z-h6lzymZnFBFIeDngGK2IFsH6pajbg',
    id: 251,
    acronym: 'CSF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'css.edu',
    name: 'College of St. Scholastica',
    web_page: 'http://www.css.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdwN51KduQmg5twzixv6c0wRC-_xUWewixoppQUUKIKJ58drZkLCB1oJI',
    id: 252,
    acronym: 'CSS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coa.edu',
    name: 'College of the Atlantic',
    web_page: 'http://www.coa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWTJJuyy3DpDZ-ueDTbnP7fQDOsB-dSOeoBoYMGBzqTUvB7vlKgXNVU1o',
    id: 253,
    acronym: 'CA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'holycross.edu',
    name: 'College of the Holy Cross',
    web_page: 'http://www.holycross.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14228college.jpg',
    id: 254,
    acronym: 'CHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wm.edu',
    name: 'College of William and Mary',
    web_page: 'http://www.wm.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/college-of-william-and-mary.jpeg',
    id: 255,
    acronym: 'CWM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wooster.edu',
    name: 'College of Wooster',
    web_page: 'http://www.wooster.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeSkGdPuAt2OxJyQIRX5JJ0drjsuuoGtMDsui-54C8lErUZ6vG0HKuiA',
    id: 256,
    acronym: 'CW',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccu.edu',
    name: 'Colorado Christian University',
    web_page: 'http://www.ccu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ-oYBuSxEVwIEcJsvabLCXoU-2qMP6bUtPUPzxxJWqHM5pUdfGEwHOg',
    id: 257,
    acronym: 'CCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coloradocollege.edu',
    name: 'Colorado College',
    web_page: 'http://www.coloradocollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnG4FH7y0cXkgLHJKXJEcwZ9ajHayfGn6O9ddE3qrwId_d6vq3Xkv0Zg',
    id: 258,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coloradomesa.edu',
    name: 'Colorado Mesa University',
    web_page: 'http://www.coloradomesa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnstrXPCZ9WVFVL0I0BKktSHlOzbwzzgj6BbZ13IZq76e6pSV8Wa7Wfw',
    id: 259,
    acronym: 'CMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mines.edu',
    name: 'Colorado School of Mines',
    web_page: 'http://www.mines.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrjUwhw4Tc8INZtw0eiLPN6p3o9CgGqy6MKA35J8bukyawQkWYrCfEX-Q',
    id: 260,
    acronym: 'CSM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'colostate.edu',
    name: 'Colorado State University',
    web_page: 'http://www.colostate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Colorado-State-University.svg',
    id: 261,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coloradotech.edu',
    name: 'Colorado Technical University',
    web_page: 'http://www.coloradotech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAgymuojiAspwiP2PMZDFTarYDn2OjV0-oT3yVv83mzfcAZ0RHCEvDJYU',
    id: 262,
    acronym: 'CTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'colum.edu',
    name: 'Columbia College Chicago',
    web_page: 'http://www.colum.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjmMErg_EVFMAxh57nR5urAx1NSiXCBTyaJYLrjnhNdtKm6iq3-ZvdyA4',
    id: 264,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'colsouth.edu',
    name: 'Columbia Southern University',
    web_page: 'http://www.colsouth.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8MZImflj4iuJYvNfoP0jdkHbJC9sbR0243H_77XZl2dLuErmaePCz4zw',
    id: 265,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cuc.edu',
    name: 'Columbia Union College',
    web_page: 'http://www.cuc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaKi9GrwMI0s5TFuZ9owo5FvjQOc_R9vozXxnr3JIGQAVAUbvFJNkWPDs',
    id: 266,
    acronym: 'CUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'columbia.edu',
    name: 'Columbia University',
    web_page: 'http://www.columbia.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14247college.jpg',
    id: 267,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccbcmd.edu',
    name: 'Community College of Baltimore County',
    web_page: 'http://www.ccbcmd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3yBz6ZSXJM_VlZn4JyT5l0AzOpwwH6u2-tGhOEqlggtUhBvl4bLAR-w',
    id: 268,
    acronym: 'CCBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccaa.edu',
    name: 'Concordia College - Ann Arbor',
    web_page: 'http://www.ccaa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx5RH3dEiE1SkndFEoeenfqY8BZ50OjKleVGZwnpma_tTJYgaUWvkh7Ns',
    id: 269,
    acronym: 'CC-AA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cord.edu',
    name: 'Concordia College - Moorhead',
    web_page: 'http://www.cord.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLGBOPMXuDUE1Bgh6-xYLJSev2euC0d_pbBCOphf2SeOi-YbjSjDhFBg',
    id: 270,
    acronym: 'CC-M',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccsn.edu',
    name: 'Concordia College - Seward',
    web_page: 'http://www.ccsn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2bNrZvaMXyYxKqnM2HZjcXKOf-SsD701OQoYHT0DJeOkewOiHgcu2Pw',
    id: 271,
    acronym: 'CC-S',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csp.edu',
    name: 'Concordia College - St. Paul',
    web_page: 'http://www.csp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8rM5wobPLd4gmZZYk9PZSg4xeXoCO_dq0DbHs10b7lwUdKQxNzwSpevqn',
    id: 272,
    acronym: 'CC-SP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'conncoll.edu',
    name: 'Connecticut College',
    web_page: 'http://www.conncoll.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX5c1A-elJfVNPxn3QtdhtkSjA3S-WgAkAkW5chUw_5mQNyUpZQ7QhLw',
    id: 273,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ct.edu',
    name: 'Connecticut State University System',
    web_page: 'http://www.ct.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQKeFv06Cy_7darb2cT2zPEWoLfvuytMsvcRQ493Pen6Xo29XyDQ6Yjd4',
    id: 274,
    acronym: 'CSUS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cooper.edu',
    name: 'Cooper Union for the Advancement of Science and Art',
    web_page: 'http://www.cooper.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUQivTm_L0DxWqCBkGW_uHUWxWZSaHE7JDzzg16HOuoLTqAZf22a2UAaA',
    id: 275,
    acronym: 'CUFASA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coppin.edu',
    name: 'Coppin State University',
    web_page: 'http://www.coppin.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYjZm5-2wEXJNQ7m0DiQyj6mwr8CUvYRVlfcgjAtO1lwzI6IAUfO8zlfo',
    id: 276,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cornell-iowa.edu',
    name: 'Cornell College',
    web_page: 'http://www.cornell-iowa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8bn_LHfKtSDebDOfITsVo0QH72fG9_ZOy2BggB6tyofEkb04HBZrtrz4',
    id: 277,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cornell.edu',
    name: 'Cornell University',
    web_page: 'http://www.cornell.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14273college.jpg',
    id: 278,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'corning-cc.edu',
    name: 'Corning Community College',
    web_page: 'http://www.corning-cc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxyQf9tgOEcIc2I0h-pgRdleWePjhk5Y0H2RahF2g8rjMD-CIA8y-XyA',
    id: 279,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'creighton.edu',
    name: 'Creighton University',
    web_page: 'http://www.creighton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjxLsZJvgcn3SnnBnPKMPNqTLcfJ29mxm8aupfxKGR0JHuAxObP2pP_OQ',
    id: 280,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'curry.edu',
    name: 'Curry College',
    web_page: 'http://www.curry.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY1-GW64BCmlSKTs7jPYVcVgpaRYC0CEXi6SXbBKwT4Zc2lqnpWhCjG80',
    id: 281,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'daemen.edu',
    name: 'Daemen College',
    web_page: 'http://www.daemen.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-cbkltApwoasR2h76IwCSpOCfjAF2YB45wCAQchl4unaPCDUlNwEGVpSWCw',
    id: 282,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dsu.edu',
    name: 'Dakota State University',
    web_page: 'http://www.dsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrC0e0KJPmRuW5evnoOWKo4TH4USCk8NpryxXRTF6zQTHoCeFBWVzMPQ',
    id: 283,
    acronym: 'DSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dwu.edu',
    name: 'Dakota Wesleyan University',
    web_page: 'http://www.dwu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnjZYCuzgOOpQE56tffothXMvwSAJsTmqo_8VDQVO5Ux37k3NQMukW3ME',
    id: 284,
    acronym: 'DWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dbu.edu',
    name: 'Dallas Baptist University',
    web_page: 'http://www.dbu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHRe7s_j6Nh8-HY8_FTM6dvHLAEbkUCTMv6_tOuSzmt9QT8v1obNYEW2I',
    id: 285,
    acronym: 'DBU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dcccd.edu',
    name: 'Dallas County Community College',
    web_page: 'http://www.dcccd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyiOFOe393iBIi9hJ1RgGkBkmdgzCs8t53dUBbGcpKMpJWG5bCoefSgiI',
    id: 286,
    acronym: 'DCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dana.edu',
    name: 'Dana College',
    web_page: 'http://www.dana.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdmoGyJwddVzedDn5BvVrNIfZPkz_y1v4uhuu2SljQy6X6uO1xbCnBYA',
    id: 287,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dwc.edu',
    name: 'Daniel Webster College',
    web_page: 'http://www.dwc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRutoAu2Fvi7z5BkcMKyCh_P5xo2bjA-FCVM40cBe96Opg88xquvam_agB6',
    id: 288,
    acronym: 'DWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dartmouth.edu',
    name: 'Dartmouth College',
    web_page: 'http://www.dartmouth.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14313college.jpg',
    id: 289,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'davenport.edu',
    name: 'Davenport University',
    web_page: 'http://www.davenport.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDqxZbifYPCvd32CmgPvNSSjMSPcHfn0VLfVoErqmJTM4ACrsigIkCAw',
    id: 290,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'davidson.edu',
    name: 'Davidson College',
    web_page: 'http://www.davidson.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14317college.jpg',
    id: 291,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dne.wvnet.edu',
    name: 'Davis & Elkins College',
    web_page: 'http://www.dne.wvnet.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZILxM3mhbX9FWNwqwkXbnSC6_8Sc30tVNk2eFEDlglTb5zv4tteMb5A',
    id: 292,
    acronym: 'D&EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'daytonastate.edu',
    name: 'Daytona State College',
    web_page: 'http://www.daytonastate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrEXIIpHpo3P2lXiPCDFnaVKKGUSE2m5M4kEVwLdCQKij6EXMUumE15njN',
    id: 293,
    acronym: 'DSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dsc.edu',
    name: 'Delaware State University',
    web_page: 'http://www.dsc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14325college.jpg',
    id: 294,
    acronym: 'DSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'deltastate.edu',
    name: 'Delta State University',
    web_page: 'http://www.deltastate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrdW6ZypH0ELOORQ9ta-JXXDlgOUHLC11oCL-vkMLN7jagBExMy7hMsIQ',
    id: 295,
    acronym: 'DSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'denison.edu',
    name: 'Denison University',
    web_page: 'http://www.denison.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIJiqlrHp7y3PJUd5SqsuBf49Hf2RaVrvmKoGOqsEUrkwI-qeDoNZxuQ',
    id: 296,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'depaul.edu',
    name: 'DePaul University',
    web_page: 'http://www.depaul.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpMPKmO1D1gxFcy4Q7fHlFjqGcIJ9f1BtFiDWIn75t79jm3o7gWS2s5w',
    id: 297,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'depauw.edu',
    name: 'DePauw University',
    web_page: 'http://www.depauw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6Bcz3qlCvDkl6dycc_Z1lwHniEvR7OxR7XEvxzst-7PWJ-EfomMxZwmk',
    id: 298,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'devry.edu',
    name: 'DeVry Institute of Technology',
    web_page: 'http://www.devry.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG-g5O8StpJniT0zwIwzhGbiAGM2kXQxyQKUOicBDAgswfVR-gmRmihA',
    id: 299,
    acronym: 'DIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dickinson.edu',
    name: 'Dickinson College',
    web_page: 'http://www.dickinson.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUurzSWRJ0usQHmPwca7aT0_ezsNKohLFTLrOmC9rOxjKtWcEltJ6_Ff4',
    id: 300,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dickinsonstate.edu',
    name: 'Dickinson State University',
    web_page: 'http://www.dickinsonstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2IFpiC4Ccz1wvqfZX8p1jCgwTh7jxI1cySzr6M_BS6DYt1p4ECv92fQ',
    id: 301,
    acronym: 'DSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dillard.edu',
    name: 'Dillard University',
    web_page: 'http://www.dillard.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT26HEKufuTeeTD-BpH6Vulx3Ci9CP5XocegDJlUDIIUW-aGsUKLhlKoGE',
    id: 302,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dominican.edu',
    name: 'Dominican College',
    web_page: 'http://www.dominican.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6CLxqkJvix15_YU-B8brF3xiXPLK2lIs-Hgd-8qkI81zPOH4SvO_4fA',
    id: 303,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dordt.edu',
    name: 'Dordt College',
    web_page: 'http://www.dordt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROhgyvyAAB8jrsWObAN4WEZtU9v1U8UdoxJMHyciC4yk68So7BS5B9P40',
    id: 304,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dowling.edu',
    name: 'Dowling College',
    web_page: 'http://www.dowling.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJuTluwgGoXCDrQBRHsYLHkEiRWQBrWEf0Z5OZKQxsBxd4_5vyIZs3XA',
    id: 305,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'drake.edu',
    name: 'Drake University',
    web_page: 'http://www.drake.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14352college.jpg',
    id: 306,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'drew.edu',
    name: 'Drew University',
    web_page: 'http://www.drew.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpG9_b8mAYGlUsGMRk5Kb-ae5Eg1KXZmSljz7JaQB3oMlZCbPb-xatCtI',
    id: 307,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'drexel.edu',
    name: 'Drexel University',
    web_page: 'http://www.drexel.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLeMWFxZJEioccjsDi8fyZibiNvKUGijD-SbruSuUG9Fo01-1mBFtpPrI',
    id: 308,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'drury.edu',
    name: 'Drury College',
    web_page: 'http://www.drury.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3E23ml44BC6lgmRfehy_qTlUJAmC2tOXy4JQFuO3sywerAB1GIW0oUQ',
    id: 309,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'duke.edu',
    name: 'Duke University',
    web_page: 'http://www.duke.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14356college.jpg',
    id: 310,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'duq.edu',
    name: 'Duquesne University',
    web_page: 'http://www.duq.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/16959college.jpg',
    id: 311,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'earlham.edu',
    name: 'Earlham College',
    web_page: 'http://www.earlham.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWwzoyyBJe_XFFCigxCCY0F5_SATdQ35K-iM8ZJB7-hArMjTL62roeGA8',
    id: 312,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ecu.edu',
    name: 'East Carolina University',
    web_page: 'http://www.ecu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/East-Carolina-University.svg',
    id: 313,
    acronym: 'ECU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ecok.edu',
    name: 'East Central University',
    web_page: 'http://www.ecok.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzZK9r7Uz7bauEzrQDQz3vmcy60C08Fezv5pdwpY3_3LnxgB8ikhayFQ',
    id: 314,
    acronym: 'ECU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'easternct.edu',
    name: 'Eastern Connecticut State University',
    web_page: 'http://www.easternct.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUqDrYYLq6N4zQIwLPLYLgRjWHokg3V89Yw9rfxCDvAu7nHY_IYeuzhoFXyw',
    id: 315,
    acronym: 'ECSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eiu.edu',
    name: 'Eastern Illinois University',
    web_page: 'http://www.eiu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14374college.jpg',
    id: 316,
    acronym: 'EIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eku.edu',
    name: 'Eastern Kentucky University',
    web_page: 'http://www.eku.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14375college.jpg',
    id: 317,
    acronym: 'EKU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'emu.edu',
    name: 'Eastern Mennonite University',
    web_page: 'http://www.emu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNy3_SRpJwp49v_-aQ9EcS9Z-Y0mEJIsmI6ISinaXePySGTC7Hcvj7fp8sfw',
    id: 318,
    acronym: 'EMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'emich.edu',
    name: 'Eastern Michigan University',
    web_page: 'http://www.emich.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Eastern-Michigan-University.svg',
    id: 319,
    acronym: 'EMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'enmu.edu',
    name: 'Eastern New Mexico University',
    web_page: 'http://www.enmu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxbx-77pY_NynL08loUH5bSM48Of7fBL5HyBHCvLxL7HvXvieil134Ow',
    id: 320,
    acronym: 'ENMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eou.edu',
    name: 'Eastern Oregon State College',
    web_page: 'http://www.eou.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNMgEacRzTjbW9VrrbJZ_PPCuY7RPXrC_GTNJXjayD17V4ynPJM6HQ4A',
    id: 321,
    acronym: 'EOSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ewu.edu',
    name: 'Eastern Washington University',
    web_page: 'http://www.ewu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14383college.jpg',
    id: 322,
    acronym: 'EWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'esu.edu',
    name: 'East Stroudsburg State University of Pennsylvania',
    web_page: 'http://www.esu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlXDpEZcBig5o0l16RBYXbNbF6bhwaoh1s7a-TluVtcsE2u9iCHACaaw',
    id: 323,
    acronym: 'ESSUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'etsu.edu',
    name: 'East Tennessee State University',
    web_page: 'http://www.etsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14370college.jpg',
    id: 324,
    acronym: 'ETSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'edgewood.edu',
    name: 'Edgewood College',
    web_page: 'http://www.edgewood.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2igBYBIhTn1dd0KRxIxfP4B2Jx2Cl5aScUtRBGmNU4AAS3qHkuEradw',
    id: 325,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'edinboro.edu',
    name: 'Edinboro University of Pennsylvania',
    web_page: 'http://www.edinboro.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyfelrwtT5X1iNfgvEMcONQNiDnv5COaoLv6zX0FuiLrCE3rOY6LF-fw',
    id: 326,
    acronym: 'EUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ecsu.edu',
    name: 'Elizabeth City State University',
    web_page: 'http://www.ecsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToRLsH5xp96pV9lv7Ke1IMWAJu_JmdHC42EDuGnSE8oNS22mF8JV4cQNc',
    id: 327,
    acronym: 'ECSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'etown.edu',
    name: 'Elizabethtown College',
    web_page: 'http://www.etown.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROwE0yvwQipy7hdT2awIuGF7FvV1Z9QTEaP-jWIuGX7D3TKw7jvYu6Vg',
    id: 328,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'elmhurst.edu',
    name: 'Elmhurst College',
    web_page: 'http://www.elmhurst.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTroxy0_eQ0bOqZUGrEIzTMbudrfPfX2FJ9d6xCw2RhnqAelY7ls6e5bQ',
    id: 329,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'elon.edu',
    name: 'Elon College',
    web_page: 'http://www.elon.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14402college.jpg',
    id: 330,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'erau.edu',
    name: 'Embry-Riddle Aeronautical University',
    web_page: 'http://www.erau.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWgTZ7lNOCZbcuk5zsMQK1f-ha7qSx8ktIAb7DSkrLLV30qj6QmUj4A9Y',
    id: 331,
    acronym: 'EAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'emerson.edu',
    name: 'Emerson College',
    web_page: 'http://www.emerson.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhZd63nqG2PB2rEUmtsTNLObkL1Ggp1a5IS5L8huisj3YCNCxxN1nDEoas4g',
    id: 332,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'emmanuel.edu',
    name: 'Emmanuel College',
    web_page: 'http://www.emmanuel.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREXhj6g-Si5kV8m3HYZY93iu3K3AedO5sDF8cjHnBO8nPY_T20MR4Lcg',
    id: 333,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'emory.edu',
    name: 'Emory University',
    web_page: 'http://www.emory.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpTswaKemZL49H8zwnfYW98UPZjfaHxyQNvyp_C8hN5ea4HjVHAQGgExo',
    id: 334,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'emporia.edu',
    name: 'Emporia State University',
    web_page: 'http://www.emporia.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQagBmY_LoxSaVpnossVHzgxi3Ugmz5mxUHxHjJPzyNCuVaZBuOeUqtLrq0',
    id: 335,
    acronym: 'ESU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ecc.edu',
    name: 'Erie Community College',
    web_page: 'http://www.ecc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbwSjqxWrtXcT1XgJHQ6-QlF-FCW5al7NyIDwieaphMorGHyPDRZlCzg',
    id: 336,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'evergreen.edu',
    name: 'Evergreen State College',
    web_page: 'http://www.evergreen.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKPqPVPRWaKQnDbmF0-_3S0E_vDKCsRbHyHUAFsbpLZ5rn3CjRN4B1Jw',
    id: 337,
    acronym: 'ESC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fdu.edu',
    name: 'Fairleigh Dickinson University',
    web_page: 'http://www.fdu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTegYWbd4pdDxB9ZZKWGuviXBRGC9MS_LnsRcsc20n_jW6CNnRloApWg7w',
    id: 338,
    acronym: 'FDU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fairmontstate.edu',
    name: 'Fairmont State College',
    web_page: 'http://www.fairmontstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgx9uE8FibiTJLfG8QMFrlAckeS28QyKq_Gf74sYSXgx47Ndsn2KaY0w',
    id: 339,
    acronym: 'FSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uncfsu.edu',
    name: 'Fayetteville State University',
    web_page: 'http://www.uncfsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNbyWlizBk0pvG_D3UdbqC-m4sYlrjNbb88YBt2tQtscL_2zchbn-7NM4',
    id: 340,
    acronym: 'FSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ferris.edu',
    name: 'Ferris State University',
    web_page: 'http://www.ferris.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB6SYICksKpQRhQJ2riCvBHN7aBN4_f0fh95mfxyIQY6vj7rKRUjqjQg',
    id: 341,
    acronym: 'FSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fielding.edu',
    name: 'Fielding Institute',
    web_page: 'http://www.fielding.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_XciM6uOUWZdNspYy91jD6TVZ1Fn-GBjpWYyjIzrQL02UciTu2Ix8yA',
    id: 342,
    acronym: 'FI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fisk.edu',
    name: 'Fisk University',
    web_page: 'http://www.fisk.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfnVm0ReuaNc1X3Idi7Da7ud1na1igo4df0f_yiQ2k6WeqeTTjf_Xoveo',
    id: 343,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fsc.edu',
    name: 'Fitchburg State College',
    web_page: 'http://www.fsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1J5_qNXFif8b38OLHjLAVPSpm9CdvGgFmmPdzd0fvrJgw4yzveAqdfg',
    id: 344,
    acronym: 'FSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'famu.edu',
    name: 'Florida Agricultural and Mechanical University',
    web_page: 'http://www.famu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14439college.jpg',
    id: 345,
    acronym: 'FAMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fau.edu',
    name: 'Florida Atlantic University',
    web_page: 'http://www.fau.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Florida-Atlantic-University.svg',
    id: 346,
    acronym: 'FAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fgcu.edu',
    name: 'Florida Gulf Coast University',
    web_page: 'http://www.fgcu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-fAnJZt4Ft93JVlguUeKEPK2lDfBDWYdoWTrlXyo2kqdQjF96rutm1cw',
    id: 347,
    acronym: 'FGCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fit.edu',
    name: 'Florida Institute of Technology',
    web_page: 'http://www.fit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc4cfNfh70jzxqrFXwbq8UL_93MvkXM9pOQTmwtT7v13wPq3qlHLiUxw',
    id: 348,
    acronym: 'FIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fiu.edu',
    name: 'Florida International University',
    web_page: 'http://www.fiu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Florida-International-University.svg',
    id: 349,
    acronym: 'FIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fsu.edu',
    name: 'Florida State University',
    web_page: 'http://www.fsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Florida-State-University.svg',
    id: 350,
    acronym: 'FSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fontbonne.edu',
    name: 'Fontbonne College',
    web_page: 'http://www.fontbonne.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTBb61Gu6nMOTXN16Z3Hkmo5VVZaVgeHz8eKACCZ5qVZ9VIu1zg-QaKe9C',
    id: 351,
    acronym: 'FC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fordham.edu',
    name: 'Fordham University',
    web_page: 'http://www.fordham.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14451college.jpg',
    id: 352,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fhsu.edu',
    name: 'Fort Hays State University',
    web_page: 'http://www.fhsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGGALx-A3iXqPuYlTP6eDeWJytnzW8lncS7FKaBIAzsX_ukZPsmqYMfPo',
    id: 353,
    acronym: 'FHSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fortlewis.edu',
    name: 'Fort Lewis College',
    web_page: 'http://www.fortlewis.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGUH9K4yHN62CzncFzBSnva9IgMgUHrdTmT1uQlIRqLbpLMw6xdEGw_g',
    id: 354,
    acronym: 'FLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fountainheadcollege.edu',
    name: 'Fountainhead College of Technology',
    web_page: 'http://fountainheadcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjlkxvwPnfvucMerHEVbmZh40wK4o7l0cxB7AIJeHEjKuIRmkcRi0SXg',
    id: 355,
    acronym: 'FCT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fandm.edu',
    name: 'Franklin and Marshall College',
    web_page: 'http://www.fandm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeRBq9xeCFaNgUrONGYDYubUlwDqYWVfRZOIUfm7J_QiB4kqgDWLlMbLo',
    id: 356,
    acronym: 'FMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fplc.edu',
    name: 'Franklin Pierce Law Center',
    web_page: 'http://www.fplc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTggVlDzpr0BYz8oRfBJ4Z63sQaeRBe8ttcQsG2dWvGYtiCYgJdx2ujrQ',
    id: 357,
    acronym: 'FPLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'franklin.edu',
    name: 'Franklin University',
    web_page: 'http://www.franklin.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh8xT7DC0s2WgRcwhk3KAdSf57v9kd5jz5GTa6KZ0EOVKto9b8oqgRnA',
    id: 358,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'frederick.edu',
    name: 'Frederick Community College',
    web_page: 'http://www.frederick.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuBROb7cdwvB6tQLzrrbUWFHqzlQEHyYVBN28u44aknKV7PCFwSlrnrw',
    id: 359,
    acronym: 'FCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fresno.edu',
    name: 'Fresno Pacific University',
    web_page: 'http://www.fresno.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRBWhtla2OtW5_8Q49ZWeObPOHCOJjnEKXfMu3JUzrku97eZDakUOUfQ',
    id: 360,
    acronym: 'FPU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'friends.edu',
    name: 'Friends University',
    web_page: 'http://www.friends.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFH14OnGcEdErU5HPYZKGHdfcMQnM34-SndC2h1E-eJ9Q5MyrYKohToHw',
    id: 361,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'frostburg.edu',
    name: 'Frostburg State University',
    web_page: 'http://www.frostburg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdpCmYVyxmjXl3RN1PYdOkOSlb4rddvT_MiAPV0hREuL77ufZtV9ivkrM',
    id: 362,
    acronym: 'FSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fuller.edu',
    name: 'Fuller Theological Seminary',
    web_page: 'http://www.fuller.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrwSUzS-8GF7FtfRJMzNaHOlUaEoepNO8UGfPjL-acr6U2uE9xlYq_hw',
    id: 363,
    acronym: 'FTS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'furman.edu',
    name: 'Furman University',
    web_page: 'http://www.furman.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14473college.jpg',
    id: 364,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gallaudet.edu',
    name: 'Gallaudet University',
    web_page: 'http://www.gallaudet.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThhc1N1upWho5uV_bwN-g8hAdfsvIyojSDqKeJ3LpJPZMow7WIUI4apA',
    id: 365,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gannon.edu',
    name: 'Gannon University',
    web_page: 'http://www.gannon.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA9KdznLmH4_EtlSDBMz1fP30Qhj1FYwDqpTVDS4X5GUixOVcML5JntoQ',
    id: 366,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'geneva.edu',
    name: 'Geneva College',
    web_page: 'http://www.geneva.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ0OpR6ZKwC9_IOhYJUE4xhVrBOlZfUKYfRcg1lbRjjWOIXyOcOWHD3wQ',
    id: 367,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gfc.edu',
    name: 'George Fox College',
    web_page: 'http://www.gfc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX4h9FvsqLsLLTY6YA2A6zyKvw7tMxgGwKJZTFxG-nrAJ08h7cABFoDGV19Q',
    id: 368,
    acronym: 'GFC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gmu.edu',
    name: 'George Mason University',
    web_page: 'http://www.gmu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO3iLKU3Glz0qbOfZscnQldiYhK5pm994WheKSpf7wIgyi9eQn_DnwOEc',
    id: 369,
    acronym: 'GMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'georgetown.edu',
    name: 'Georgetown University',
    web_page: 'http://www.georgetown.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14491college.jpg',
    id: 370,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gwu.edu',
    name: 'George Washington University',
    web_page: 'http://www.gwu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi_qToNmZn033VlIJrxP2WntBa51YFaUGWaGmlMpt63AOESg94wx56L9w',
    id: 371,
    acronym: 'GWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gcsu.edu',
    name: 'Georgia College',
    web_page: 'http://www.gcsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyGgKHfN4GRr4EuAJDuUaN0tYSyM5zDHZT_ygbe9NTbaa8wFEfZYKfog',
    id: 372,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gatech.edu',
    name: 'Georgia Institute of Technology',
    web_page: 'http://www.gatech.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Georgia-Institute-of-Technology.svg',
    id: 373,
    acronym: 'GIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'georgian.edu',
    name: 'Georgian Court College',
    web_page: 'http://www.georgian.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-sacT_hxryWJxPH5uzc3Ur9rqrJaxcl6bVYwkx6U-r25dqeBtCq2nCtI',
    id: 374,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'georgiasouthern.edu',
    name: 'Georgia Southern University',
    web_page: 'http://www.georgiasouthern.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Georgia-Southern-University.svg',
    id: 375,
    acronym: 'GSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gsw.edu',
    name: 'Georgia Southwestern State University',
    web_page: 'http://gsw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdIn7fLguXuHFKUv6TSuNOcQpRZp5qg4e8UoV3pQrrbpughDiy72tbt2A',
    id: 376,
    acronym: 'GSSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gsu.edu',
    name: 'Georgia State University',
    web_page: 'http://www.gsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Georgia-State-University.svg',
    id: 377,
    acronym: 'GSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gettysburg.edu',
    name: 'Gettysburg College',
    web_page: 'http://www.gettysburg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZMM383xuwVt_6VvC8iiM4DofcM41l42oCAVXIZrW-xiLuxAvggBumklM',
    id: 378,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gmi.edu',
    name: 'GMI Engineering and Management Institute',
    web_page: 'http://www.gmi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQz57aYcjvLjix1Ay_RZkIA-HlFcwLeT4qYLU9bPyY-O0tv5G1VC8t3zA',
    id: 379,
    acronym: 'GEMI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ggu.edu',
    name: 'Golden Gate University',
    web_page: 'http://www.ggu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ787T2ZIdEhlkXbnPG18A7_Edx9M1uch5OCmLiK7GsKvROpX6FkDda1g',
    id: 380,
    acronym: 'GGU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gbc.edu',
    name: 'Goldey-Beacom College',
    web_page: 'http://www.gbc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRoXJjoD0jTtsUXyWs3vOrU9XT9A4nOQcsN6mYOml4807-noosKinmYms',
    id: 381,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gonzaga.edu',
    name: 'Gonzaga University',
    web_page: 'http://www.gonzaga.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDVIzhOLHlHPLsEDL5qbmXUCPk6cdpcmfqFVaY7b12ilRYEYHoX11ETQ',
    id: 382,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'goshen.edu',
    name: 'Goshen College',
    web_page: 'http://www.goshen.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCTKdTw9PMv9mK57CjY_rMXYEaTGojyo7mipyAHOpMcKiZG4nrfECZdV4',
    id: 383,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'goucher.edu',
    name: 'Goucher College',
    web_page: 'http://www.goucher.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhuKsR5CtzG4zrHcivzJL7QTkA3Gu6S5WbWOK2sMUGVG9mTd5QrE7ojjs',
    id: 384,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'govst.edu',
    name: 'Governors State University',
    web_page: 'http://www.govst.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1ZvvE01Mlx-pCxclhW7F3W8XYfya8Zjh5sruuot46WopR2igCsOnGEeE',
    id: 385,
    acronym: 'GSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'grace.edu',
    name: 'Grace College',
    web_page: 'http://www.grace.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDnPrSywGoO44DR2ecmkTjfqIWqdQRj2aH5FAwHK_8RUpGJS-Xaj6HyPKF',
    id: 386,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'graceland.edu',
    name: 'Graceland College',
    web_page: 'http://www.graceland.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj1QHwqnPtOCDyk_5Nxg8FFQhzikYr--EzsUUKdwHA0F_ZxYhUrgU7ZH3z',
    id: 387,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gvsu.edu',
    name: 'Grand Valley State University',
    web_page: 'http://www.gvsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyzcsBasIBJmLoU74TMVwb0K96vLnJ-VWlvCc3xdGW_7nXbe_0C1zUIQ',
    id: 388,
    acronym: 'GVSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'greenleaf.edu',
    name: 'Greenleaf University',
    web_page: 'http://www.greenleaf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJiqzRvHLDWHZydRjO2uYuDlHf0e3bL-wtOK59QZN24iYAJyG8RSalQw',
    id: 389,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'grinnell.edu',
    name: 'Grinnell College',
    web_page: 'http://www.grinnell.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3Kk4dALThIj_0fucYnPafATBgbrXFHrl9u7-FGArpgM3ey649YEoNHQ',
    id: 390,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'guilford.edu',
    name: 'Guilford College',
    web_page: 'http://www.guilford.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAXx1aQG8W79JEGmc05fD96PBNFMn7x-GTEklBOPJM7-6BgHUDcRhkXg',
    id: 391,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gac.edu',
    name: 'Gustavus Adolphus College',
    web_page: 'http://www.gac.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJsgM8nfBU1ytUSk-nVYhTFTHTI_1xlftUNXKsNSDIazwa_T45BfqLTWygfA',
    id: 392,
    acronym: 'GAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hagerstowncc.edu',
    name: 'Hagerstown Community College',
    web_page: 'http://www.hagerstowncc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt27-l3RTzE0xuYAKKGLLMcAmwEtCT8CfV5S27AgVfQ0pZgaFb3KD5LKw',
    id: 393,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hamilton.edu',
    name: 'Hamilton College',
    web_page: 'http://www.hamilton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxf_kI9MIbo4OWBTWgMwHiTQBylfP2JyjluiZ4elKJXVejLzBPDx5BVg',
    id: 394,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hamline.edu',
    name: 'Hamline University',
    web_page: 'http://www.hamline.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQuQtxnjREQYeF0CuFa8mIqphuVaRzf8dptTLIiYBZ-E4AkNqdbVAVXUo',
    id: 395,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hsc.edu',
    name: 'Hampden-Sydney College',
    web_page: 'http://www.hsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfDB9jKLAfeY2LOGHo481qfaYevJEKcd6Q88CezyP9zcUDFHtHxn9QnaE',
    id: 396,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hampshire.edu',
    name: 'Hampshire College',
    web_page: 'http://www.hampshire.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmLoMqok6W6b7Pe0bq_aWms20QFwxi7ukDPBzom9YBX7H1eY947v8ieQ',
    id: 397,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hamptonu.edu',
    name: 'Hampton University',
    web_page: 'http://www.hamptonu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14542college.jpg',
    id: 398,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hanover.edu',
    name: 'Hanover College',
    web_page: 'http://www.hanover.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-U5tcJIgcX9vzvF1MD9_PggCZiv1NEEmXdWQfdcC5cwqL8IGHHotzpRo',
    id: 399,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'harding.edu',
    name: 'Harding University',
    web_page: 'http://www.harding.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQI5R0imL7PpNTDP5DBtL6v0RIfEIGnClNgK7GZBuXjYuVx-aV00N2pg',
    id: 400,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'harford.edu',
    name: 'Harford Community College',
    web_page: 'http://www.harford.edu/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcS1UMCsncseAFRJl5eAtWOAPBpkkw6gOVOhzpyNxIYkb8jtZcclYI8Go2w',
    id: 401,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hartwick.edu',
    name: 'Hartwick College',
    web_page: 'http://www.hartwick.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR17lIlq8v81PwhthuC_CpfTKNWJAhlwq1RYxkiMdod5X-dnT-tUHx9VOl1',
    id: 402,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'harvard.edu',
    name: 'Harvard University',
    web_page: 'http://www.harvard.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14552college.jpg',
    id: 403,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hmc.edu',
    name: 'Harvey Mudd College',
    web_page: 'http://www.hmc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQXAGk-gB2yRUDadYC-2qZeETLRAp59R3SK3w4SqM_fmTanv0JdG1ecHE',
    id: 404,
    acronym: 'HMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'haskell.edu',
    name: 'Haskell Indian Nations University',
    web_page: 'http://www.haskell.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBnjPoWa9BBV5pr-P6V9iGpND8SgiwuOl4mJYmxiaRvvQoACa4FGgb-w',
    id: 405,
    acronym: 'HINU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hastings.edu',
    name: 'Hastings College',
    web_page: 'http://www.hastings.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIFnF2qu9h3brUjaZnMu8Cg3Sp-9i4IEq7wgtsiKGT-eAbrXzx1p3uqA',
    id: 406,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'haverford.edu',
    name: 'Haverford College in Pennsylvania',
    web_page: 'http://www.haverford.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRADaSaMlMuoxoyCPXIn5wW_S5kmBcoe4qQkHpnosGPvwAcgRW_Gn19FQ',
    id: 407,
    acronym: 'HCIP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hpu.edu',
    name: 'Hawaii Pacific University',
    web_page: 'http://www.hpu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj-Fs_SfSttWSqhdJgAXKU7t1u5xUCSx7MrAkg1jHZ2kUFfk7lqoGPRV8',
    id: 408,
    acronym: 'HPU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'heidelberg.edu',
    name: 'Heidelberg College',
    web_page: 'http://www.heidelberg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyHAa40qiYw26xWsfmGZFqW72vymg57sv-NMKln8NjLTAscgnE9nu3pNTs',
    id: 409,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hendrix.edu',
    name: 'Hendrix College',
    web_page: 'http://www.hendrix.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcNZ2lM2NlVVTmNBgAYRgLmN1Ln3dRi17v36WaeQdvnwAvEho9bU4uEQI',
    id: 410,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hesston.edu',
    name: 'Hesston College',
    web_page: 'http://www.hesston.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFYAxXSAqCtZ63oANbBC4hPhjCeyF_OkWzeu4j2vXrB2dqX2yZAIdHyGU',
    id: 411,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'highpoint.edu',
    name: 'High Point University',
    web_page: 'http://www.highpoint.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRe4L92TGv9jL-GeGNZjvHCOS-LaqqHDOK1fG-7VtZqPcet7T9IvCib8ZM',
    id: 412,
    acronym: 'HPU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hillsdale.edu',
    name: 'Hillsdale College',
    web_page: 'http://www.hillsdale.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWzjE_nZSfekJ0jhbgF6-MQsiyAlTDJer1MaQL1v0N2MBsxXk1Pl-fbEQ',
    id: 413,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hiram.edu',
    name: 'Hiram College',
    web_page: 'http://www.hiram.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnqo4anpRI1f7jd5fjTpmv4VaBH_UGv8Zmmdjvv89-cMuc7Szn-l6eJQ',
    id: 414,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hws.edu',
    name: 'Hobart and William Smith Colleges',
    web_page: 'http://www.hws.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDKgg26zd1NP5zB1aibICRt4DlI_8AMI4QwMv8prW0YDi3YQlqfKFqWQ',
    id: 415,
    acronym: 'HWSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hofstra.edu',
    name: 'Hofstra University',
    web_page: 'http://www.hofstra.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRllN3_cPQWvQFcpzjs-WzF5J13ZcXew9HVvwKk7mrd8L4bt1qfHrGg2w',
    id: 416,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hollins.edu',
    name: 'Hollins College',
    web_page: 'http://www.hollins.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX8xkS1TkeFVu2HP6o-JWqQ70E7kGV66Sej4mgtNP4Z5bIXcFuS8wvIEE',
    id: 417,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hood.edu',
    name: 'Hood College',
    web_page: 'http://www.hood.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwZlTG6r6CnhaGWWrrQgMXDP6d26a20fUfcCb_v1vwRDJks4YcMfKhqX8',
    id: 418,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hope.edu',
    name: 'Hope College',
    web_page: 'http://www.hope.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbFBCtGDoN9g82GnbOTWY_k4xD215TftokslPUFFAJN_zPFuzRZ_OL4w',
    id: 419,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'howardcc.edu',
    name: 'Howard Community College',
    web_page: 'http://www.howardcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-x2ez-DbH7MNrh_UxDFS_1xFtmxB19N0juvjFTe_wiyJryrisEn6-5Mw',
    id: 420,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'howard.edu',
    name: 'Howard University',
    web_page: 'http://www.howard.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/howard-university.png',
    id: 421,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'humboldt.edu',
    name: 'Humboldt State University',
    web_page: 'http://www.humboldt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1-jDmOQz64-zU2uMywrcdGy47bxpxgQyeroaT2_gT2ZZ0MU9sM03tlcI',
    id: 422,
    acronym: 'HSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hunter.cuny.edu',
    name: 'Hunter College',
    web_page: 'http://www.hunter.cuny.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR25SLtod5deJOVWY054qngPqYxNu0uqh108aGFlEh5ysc-QmMHsSSAAA',
    id: 423,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'huntingdon.edu',
    name: 'Huntingdon College',
    web_page: 'http://www.huntingdon.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_A0OnL2rMdCn8S8Wq4ls-g--cjFGeDgb0sLfOvm4-a7wNmanrnX-VRUM',
    id: 424,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'huntington.edu',
    name: 'Huntington University',
    web_page: 'http://www.huntington.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOje0-gkULrxhyWyCne2Mk84cpNoWo9GZ7TFFhwy95Xti8PwdCsNUNLw',
    id: 425,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ici.edu',
    name: 'ICI University',
    web_page: 'http://www.ici.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-grcUiX14cX58wCklFdG_upZHhZuuz8ttsahXyM1kRG9dd2_c0_Rtc3o',
    id: 426,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'isu.edu',
    name: 'Idaho State University',
    web_page: 'http://www.isu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/idaho-state-university.jpeg',
    id: 427,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ibc.edu',
    name: 'Illinois Benedictine College',
    web_page: 'http://www.ibc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVc0aoywgt-6RqEQCg7Zn8-3GSxQhx7Tk3skmxPQn8ehcOIINEddbQhKU',
    id: 428,
    acronym: 'IBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iit.edu',
    name: 'Illinois Institute of Technology',
    web_page: 'http://www.iit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNhXSt_CriDy2Z2OrrhCpetWEaHRHxr3WpGn4A3ptUF8HS0szOJTGPNkw',
    id: 429,
    acronym: 'IIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'illinoisstate.edu',
    name: 'Illinois State University',
    web_page: 'http://www.illinoisstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/illinois-state-university.png',
    id: 430,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'indianatech.edu',
    name: 'Indiana Institute of Technology',
    web_page: 'http://www.indianatech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKkU4KatMM9NoIQ0UPY2MFohM39lMXt9n63bU3-kRLRFTJ7ubGxC5z35A',
    id: 432,
    acronym: 'IIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'indstate.edu',
    name: 'Indiana State University',
    web_page: 'http://www.indstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/indiana-state-university.png',
    id: 433,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iu.edu',
    name: 'Indiana University',
    web_page: 'http://www.iu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Indiana-University.svg',
    id: 434,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'indiana.edu',
    name: 'Indiana University at Bloomington',
    web_page: 'http://www.indiana.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Indiana-University.svg',
    id: 435,
    acronym: 'IUAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iusb.edu',
    name: 'Indiana University at South Bend',
    web_page: 'http://www.iusb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3aLO8CQ9tj3gm1jeq275U5titCvJT22nuyGtLqmFlQse2yDvDspWuHA',
    id: 437,
    acronym: 'IUASB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iup.edu',
    name: 'Indiana University of Pennsylvania',
    web_page: 'http://www.iup.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX9YUWo7u-Gw7BNMtLV2fgMcLewqc9zxuMRVoKpR6BzBPd7ssG6eyQcBc',
    id: 438,
    acronym: 'IUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iupuc.edu',
    name: 'Indiana University/Purdue University at Columbus',
    web_page: 'http://www.iupuc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJiFc8YA0UfWeO_ZxbepVboKgfiClMWZ7plScXF8RP9P_kKo9tv6Zbzg',
    id: 439,
    acronym: 'IUUAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ipfw.edu',
    name: 'Indiana University/Purdue University at Fort Wayne',
    web_page: 'http://www.ipfw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp9lM2jTlDBEwOKLLZIh9ppZjBG8vP8ed3taBel4CTtEFYW5c-yN8kQqbg',
    id: 440,
    acronym: 'IUUAFW',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iupui.edu',
    name: 'Indiana University/Purdue University at Indianapolis',
    web_page: 'http://www.iupui.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJiFc8YA0UfWeO_ZxbepVboKgfiClMWZ7plScXF8RP9P_kKo9tv6Zbzg',
    id: 441,
    acronym: 'IUUAI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ius.edu',
    name: 'Indiana University Southeast',
    web_page: 'http://www.ius.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD5zvKlt4URhq2E3KIBn5xp9zUzVtj1yYCEpyJVEt9iRIZyTmK6HvC9g',
    id: 442,
    acronym: 'IUS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'indwes.edu',
    name: 'Indiana Wesleyan University Marion',
    web_page: 'http://www.indwes.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvjW--S-R03LihVD12v77V_3TgJLEM2WlXW9uUXc7l8oeIeeK5bgUYV6Rhag',
    id: 443,
    acronym: 'IWUM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iwp.edu',
    name: 'Institute for World Politics',
    web_page: 'http://www.iwp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOx2ujMvV7fC6wGK-6XXCVBCApyrG34HEONdeQq4lcoErsrVS-RnQ2dCUCyQ',
    id: 444,
    acronym: 'IFWP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iona.edu',
    name: 'Iona College',
    web_page: 'http://www.iona.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSizeBnw6p6XKXulu2LXfm0k5_uINAoqe7WEFvHPDstDEAdasSri3bJVK0',
    id: 445,
    acronym: 'IC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iastate.edu',
    name: 'Iowa State University',
    web_page: 'http://www.iastate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Iowa-State-University.svg',
    id: 446,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ivc.edu',
    name: 'Irvine Valley College',
    web_page: 'http://www.ivc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXKmt_DXQwrFFlLBTM_d9WJQ9fp_bygcM-URUHx8WidZF4p-UpQp-N7Q',
    id: 447,
    acronym: 'IVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ithaca.edu',
    name: 'Ithaca College',
    web_page: 'http://www.ithaca.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7pKdQCwgmKk6hPb20cLgTKc6qQ6eNci2FMjsLEwPuwygcG5xM2imv3ZI',
    id: 448,
    acronym: 'IC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jsums.edu',
    name: 'Jackson State University',
    web_page: 'http://www.jsums.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/jackson-state-university.png',
    id: 449,
    acronym: 'JSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jsu.edu',
    name: 'Jacksonville State University',
    web_page: 'http://www.jsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/jacksonville-state-university.png',
    id: 450,
    acronym: 'JSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ju.edu',
    name: 'Jacksonville University',
    web_page: 'http://www.ju.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrxl3H7rKNhMc3S_I70GHZq8b1laCKw-IDcr7_X4jZNZPB2pxGG60tkA',
    id: 451,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jmu.edu',
    name: 'James Madison University',
    web_page: 'http://www.jmu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/James-Madison-University.svg',
    id: 452,
    acronym: 'JMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jbu.edu',
    name: 'John Brown University',
    web_page: 'http://www.jbu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_WNe5grHQVsMaYPwLtHsyDlHggmP3TBK-X01iqw3A4ZzoL6KLyEyj4gw',
    id: 453,
    acronym: 'JBU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jfku.edu',
    name: 'John F. Kennedy University',
    web_page: 'http://www.jfku.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa5ym787mVwhLSPToY4-GK4-TQ_Vqe-9EI49-YIjJYQibUh9qUQgA2YA',
    id: 454,
    acronym: 'JFKU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jh.edu',
    name: 'Johns Hopkins University',
    web_page: 'http://www.jhu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQier3Zcgm52uPlDFt2_Lr_S3Pl9H0sbkQq7rpH0Qz6CtRlyEc_9nFsYmGT',
    id: 455,
    acronym: 'JHU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jhuapl.edu',
    name: 'Johns Hopkins University Applied Physics Laboratory',
    web_page: 'http://www.jhuapl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYMSC9AXZzJ_cwhae4uioqWuIvhaWi-PM9pUwkbNant0NMxEN4tsa08Asb',
    id: 457,
    acronym: 'JHUAPL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jwu.edu',
    name: 'Johnson & Wales University',
    web_page: 'http://www.jwu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPdL2AuRe4xs_mPQGrFLAHm006dwKyvwoMhGXR6_mxXjVqKlwHScOFSA',
    id: 458,
    acronym: 'J&WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jbc.edu',
    name: 'Johnson Bible College',
    web_page: 'http://www.jbc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQktwxlbODvIpbyOtNfkohsS_te54EqWY0xKtlpLxDcUKOkrYZwOOVukWZO',
    id: 459,
    acronym: 'JBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jcsu.edu',
    name: 'Johnson C. Smith University',
    web_page: 'http://www.jcsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR--0mRvYz_fsY7k1cIwnYn5NLSMW32BR6t6L_GfcvVrqI0aW-8sXMIg',
    id: 460,
    acronym: 'JCSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jones.edu',
    name: 'Jones College',
    web_page: 'http://www.jones.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_neggtZ6MVEOrhFpGiiuAgDYqnjHquV50LBw_u0nnLOEDzXMR1T_bXl0',
    id: 461,
    acronym: 'JC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'judson.edu',
    name: 'Judson College',
    web_page: 'http://www.judson.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy64IPMT697avrvolgOP79_e3ZZcgff78KAWPWNc-5FeK-TKgxcb670g',
    id: 462,
    acronym: 'JC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'juniata.edu',
    name: 'Juniata College',
    web_page: 'http://www.juniata.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRA73Zu8W9EMTTgaarhPFnzlEZLfQcEDq4y1tY1M_fTcWHvyr1aY7wYQ',
    id: 463,
    acronym: 'JC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kzoo.edu',
    name: 'Kalamazoo College',
    web_page: 'http://www.kzoo.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSousYSBoxK626jA9IoWlU3QcbjsRm0Td2nQu7IipcOnzEbOBV5T2smY_A',
    id: 464,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ksu.edu',
    name: 'Kansas State University',
    web_page: 'http://www.ksu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Kansas-State-University.svg',
    id: 465,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kwu.edu',
    name: 'Kansas Wesleyan University',
    web_page: 'http://www.kwu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ54Mny3SzDYcNbCivH1oQrlljbZnbyK4Jo2ZmLCT0_RQDl2G_GKKCo96NJ',
    id: 466,
    acronym: 'KWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kaplan.edu',
    name: 'Kaplan University',
    web_page: 'http://www.kaplan.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRatZ3dcMQ7Kg_gBrtn-th4Jylp5UXQGwji30qPHHRcZ9bXud6u9voB4w',
    id: 467,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kean.edu',
    name: 'Kean College',
    web_page: 'http://www.kean.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpJJaaWjyYC0b5qsL6pjxNHNx3ulxEw4gjtEFzBIlK3dHRNey1qsZvcZ0',
    id: 468,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'keene.edu',
    name: 'Keene State College',
    web_page: 'http://www.keene.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ1OfuSKJHjhSfXTAWQBZW28WJuzYPCSWc8TV6dqskmq_0J5pBXgpETx0',
    id: 469,
    acronym: 'KSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kennesaw.edu',
    name: 'Kennesaw State University',
    web_page: 'http://www.kennesaw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCEeh5aFKcH562gZG4X1mCXAv7mRoZXHVGV7M8a3Fh6bVRNQJ9MkGSrg',
    id: 470,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kent.edu',
    name: 'Kent State University',
    web_page: 'http://www.kent.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Kent-State-University.svg',
    id: 471,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kctcs.edu',
    name: 'Kentucky Community & Technical College System',
    web_page: 'http://www.kctcs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxRCeixILVu_cqAvhw9_q8ZSndNracWlXqXz1394LrpaloX3gqCN_cgiWe',
    id: 472,
    acronym: 'KC&TCS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kenyon.edu',
    name: 'Kenyon College',
    web_page: 'http://www.kenyon.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEgMKs32yGbsRulnoiOpmvq-uuv01smM_N3Mvg70Ktj7hW1K2HTEAEWw',
    id: 473,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kettering.edu',
    name: 'Kettering University',
    web_page: 'http://www.kettering.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvMKLabdhpn4VEg1K_11Fo3NVPWW-kq2_mHDkY-QaB3M7rWvyeH9p59Z34',
    id: 474,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kings.edu',
    name: "King's College",
    web_page: 'http://www.kings.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRISScEHEjIf7iFCnjV4OFI-C5_AlZ0lL9yajh8OleCNJnFeDTKUOnpbw',
    id: 475,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'knox.edu',
    name: 'Knox College',
    web_page: 'http://www.knox.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXAwn5HhBBRkke2j1X4KAT_DmgLjs9-JmCyTM4FLiOx3Lo2j_YZQ8jwQ',
    id: 476,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kutztown.edu',
    name: 'Kutztown University of Pennsylvania',
    web_page: 'http://www.kutztown.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJd22WFYirMzEgYR5xVZYK6uypH3XQX53CEWfWF_Sq2fbXtZLzpzziB4E',
    id: 477,
    acronym: 'KUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lafayette.edu',
    name: 'Lafayette College',
    web_page: 'http://www.lafayette.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14705college.jpg',
    id: 478,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lagrange.edu',
    name: 'LaGrange College',
    web_page: 'http://www.lagrange.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG1WVW51ucFr1fGGbNPzdzZPdwdDbpvYQepIq0W7LnP6Z5zLcpr2yWmug',
    id: 479,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lfc.edu',
    name: 'Lake Forest College',
    web_page: 'http://www.lfc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW8bzn8PekX2NuwsOa5daOm7q1LKhjaKESbai5WzgvQ9AylbmqVBOt3A',
    id: 480,
    acronym: 'LFC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lsc.edu',
    name: 'Lake Superior College',
    web_page: 'http://www.lsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwUHZadi5EHdLMrTLnf_LUW5UPgu9IwQMBod2vnusQv9T2pEQxKkzCEAo',
    id: 481,
    acronym: 'LSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lssu.edu',
    name: 'Lake Superior State University',
    web_page: 'http://www.lssu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKv9vlVUmOOeQ2ByHQi8RDw9k0m2kLJGW9LYPgHP8XgyPcXVTJW9pEKZ8',
    id: 482,
    acronym: 'LSSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lamar.edu',
    name: 'Lamar University',
    web_page: 'http://www.lamar.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/lamar-university.png',
    id: 483,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'langston.edu',
    name: 'Langston University',
    web_page: 'http://www.langston.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRey-ASUkDH-87ZeAdLGc6TMKGPJGwj02BZhIU5mKVfu3lnTNwMbND68GUz',
    id: 484,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lasalle.edu',
    name: 'LaSalle University',
    web_page: 'http://www.lasalle.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuWCmB3BwWRo6dSC1dCdfo4GCMfefBfMS73ik8Gzd55kLDk5FfxUoUy6M',
    id: 485,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lasierra.edu',
    name: 'La Sierra University',
    web_page: 'http://www.lasierra.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJsbyv7k9bZPZovmIKo3cn39qYqZLfVy9BdiLouWOMXFIKGJw6fImlJQ',
    id: 486,
    acronym: 'LSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ltu.edu',
    name: 'Lawrence Technological University',
    web_page: 'http://www.ltu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIm9oPVvWJviz5UkPQpkoXo3FGZATMvs9E5DQ6zgHt1Y8CV4HPo27bmw',
    id: 487,
    acronym: 'LTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lawrence.edu',
    name: 'Lawrence University',
    web_page: 'http://www.lawrence.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp8zpYh5KWsO7PehUBki1V6VaSFwnpzWYTeXKzirT7qx2sTuQssILtBw',
    id: 488,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lvc.edu',
    name: 'Lebanon Valley College',
    web_page: 'http://www.lvc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCI8Cv64GhK_1_bMgFw_T4-SauHrjYLtt_ruc9k2LZk9hzgs1lPcKW6Yk',
    id: 489,
    acronym: 'LVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lehigh.edu',
    name: 'Lehigh University',
    web_page: 'http://www.lehigh.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14741college.jpg',
    id: 490,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lemoyne.edu',
    name: 'Le Moyne College',
    web_page: 'http://www.lemoyne.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7KbzkCdLjieCgW64FY_NbW1_P45I22Tb73OuFqtrLNLaTRR7Df9_7Qg',
    id: 491,
    acronym: 'LMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lenoircc.edu',
    name: 'Lenoir Community College',
    web_page: 'http://www.lenoircc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHTQ80knYH8US_OkbhYkSNQiLXeWRvh0rDcYus1RHAM2NwV5hUAVnC1dHM',
    id: 492,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lrc.edu',
    name: 'Lenoir-Rhyne College',
    web_page: 'http://www.lrc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfuTTbL-Eu_KulAF2YYywmsK96iNIuYUKJURQUEtW5fGIE50J_T9n3GQ',
    id: 493,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'letu.edu',
    name: 'LeTourneau University',
    web_page: 'http://www.letu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw-GiiqpKMjhZ9mZn9UsnhH_3-t9oZYSjH6n8PTd7lKXcTbvaifxqBjg',
    id: 494,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lclark.edu',
    name: 'Lewis & Clark College',
    web_page: 'http://www.lclark.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj0-m1F1TxCb1nsqY2tg3uG3nSseuVMXoWN5m3zLjOMg1JzXxa5S2wdQA',
    id: 495,
    acronym: 'L&CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lcsc.edu',
    name: 'Lewis-Clark State College',
    web_page: 'http://www.lcsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGTBn4TGZ5X9H7Sy34LVZt1S4vmrP1urV5EVQBsJATIGngEmjExECvBcA',
    id: 496,
    acronym: 'LSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lewisu.edu',
    name: 'Lewis University',
    web_page: 'http://www.lewisu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShNNoDa6tPeyuoPWjD_60BBM8jRbwaGZD5AxKxSAe9vUPdx-hVIu4w1gtv',
    id: 497,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'liberty.edu',
    name: 'Liberty University',
    web_page: 'http://www.liberty.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Liberty-University.svg',
    id: 498,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lincoln.edu',
    name: 'Lincoln University',
    web_page: 'http://www.lincoln.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeiMCmzqQxHo76N-OGGuhTilIap8hlCNe25uHNUmNhq3b81Xhf7yDQ5g',
    id: 499,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'linfield.edu',
    name: 'Linfield College',
    web_page: 'http://www.linfield.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNmKjG7TmVeZm7jv5IEmJnOAPtXI-65GlNYw9TzbqtUXNlTPT2rdCw1ZTs',
    id: 500,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lhup.edu',
    name: 'Lock Haven University of Pennsylvania',
    web_page: 'http://www.lhup.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGJivkTudhm4A7sUjABFfvaTFFZx2ifOpsuZC-zJUrqf7m28vsrmPFIN4',
    id: 501,
    acronym: 'LHUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'llu.edu',
    name: 'Loma Linda University',
    web_page: 'http://www.llu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaLm_MRrkLcWQG9iCLJ7VAPsJcZUMspAYtZ09voqhKcJgrFPxBb8FNYUBf',
    id: 502,
    acronym: 'LLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'liunet.edu',
    name: 'Long Island University',
    web_page: 'http://www.liu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14770college.jpg',
    id: 503,
    acronym: 'LIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'longwood.edu',
    name: 'Longwood University',
    web_page: 'http://www.longwood.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ__BC4y0XwZF7R5VP5D_JnPl5mMlWFV8x-djSU7xh7lmHU5sFpmwebd2Y',
    id: 504,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'loras.edu',
    name: 'Loras College',
    web_page: 'http://www.loras.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYY6qh8aS-i166K-1taTYddGqD5lgCiCTD4rIA6PChl95iiuE4wc4tPA',
    id: 505,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'laccd.edu',
    name: 'Los Angeles Community College District',
    web_page: 'http://www.laccd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ94VQ_pGwBBwAZjxblsS66jRarIWzhm4G3sQX-3a9KUpryyZeIAG4wI_M',
    id: 506,
    acronym: 'LACCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lacollege.edu',
    name: 'Louisiana College',
    web_page: 'http://www.lacollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPQeAR47jZnhucHH-V2ytN7eQ8u2T11n2SZueO7urAHf8YZFxxD48Bpg',
    id: 507,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lsu.edu',
    name: 'Louisiana State University',
    web_page: 'http://www.lsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Louisiana-State-University.svg',
    id: 508,
    acronym: 'LSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lsua.edu',
    name: 'Louisiana State University at Alexandria',
    web_page: 'http://www.lsua.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5gl1oXGDfEgc2rTEQyzN9G38t8eZkHHKYBjNmCEEM7q6_T9zKbR4qEg',
    id: 509,
    acronym: 'LSUAA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'latech.edu',
    name: 'Louisiana Tech University',
    web_page: 'http://www.latech.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Louisiana-Tech-University.svg',
    id: 510,
    acronym: 'LTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'loyola.edu',
    name: 'Loyola College',
    web_page: 'http://www.loyola.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJC5SehNHN1PrTVvOIK8SrBt93UtiFPBcH4fcAwmcYvBriF7GBCSVhJWw',
    id: 511,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lmu.edu',
    name: 'Loyola Marymount University',
    web_page: 'http://www.lmu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI53uDVsXNIIODAu5uvU_7R85hHUCSFFC0eM4C5H6e1JtHbCMRwALSqw',
    id: 512,
    acronym: 'LMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'luc.edu',
    name: 'Loyola University Chicago',
    web_page: 'http://www.luc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaRmFqaczRFlmd9hz_MH2lkvXO8LZaJAEYFH4-CXlfDoDWZxtyKXCnPQk',
    id: 513,
    acronym: 'LUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'luther.edu',
    name: 'Luther College',
    web_page: 'http://www.luther.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8RMrU_hxtP7NrXm9_cJr7Eapvl5lePCPGmtg78sbupWu1uXSCdjaBiNE',
    id: 514,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'luthersem.edu',
    name: 'Luther Seminary',
    web_page: 'http://www.luthersem.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSis4azswZW2VbXJU48v4A9RT7SerS9Qsr-WLXu3tB_0sU8Bf2K82xd_Gw',
    id: 515,
    acronym: 'LS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lycoming.edu',
    name: 'Lycoming College',
    web_page: 'http://www.lycoming.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDtcsfr1HKuZYeihK8CFCeHUr6ScbPNJMb2ML1Cra2aUQQwN9Zv6Y-wnf4',
    id: 516,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lynchburg.edu',
    name: 'Lynchburg College',
    web_page: 'http://www.lynchburg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsApDzEgiEQBcPTqk9i1MExM0EcOXbD7k3Le2VIdlcwf5O7Sn6lJjaIxG5',
    id: 517,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lyndonstate.edu',
    name: 'Lyndon State College',
    web_page: 'http://www.lyndonstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0923j3pCmNGu6yqFMw9fVvDQfa-ix5tZuwcuga8kGGiuM8RBVvuGCck0',
    id: 518,
    acronym: 'LSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lyon.edu',
    name: 'Lyon College',
    web_page: 'http://www.lyon.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShbX_Nk-ynoJLh0xYMpR1pHazhKGCArxg5uIYInkW-FAOCAOm5ATP5wCs',
    id: 519,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'macalester.edu',
    name: 'Macalester College',
    web_page: 'http://www.macalester.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2dOalHzEY_o_7fYpDso1K9jZfcsT1kKqGmG4o5WADtfeuaDVHQPrP',
    id: 520,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mum.edu',
    name: 'Maharishi University of Management',
    web_page: 'http://www.mum.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1AV33RdQYsMpVRl5yAdMfdUyw0tSll5XvTPv8gfPtZrY6JEBalkICZQ',
    id: 521,
    acronym: 'MUM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'malone.edu',
    name: 'Malone College',
    web_page: 'http://www.malone.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJawf4Qri5zrxQpuzxYtkb_T4SFFpcE_Y988_VXqmq5ToZJJ17WPb3sw',
    id: 522,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'manhattan.edu',
    name: 'Manhattan College',
    web_page: 'http://www.manhattan.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4gLfsiEhdJt4WfBfR1XEowo25sxn3Ciy9RKB-pbaoYQODmGar_HriRA',
    id: 523,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mansfield.edu',
    name: 'Mansfield University of Pennsylvania',
    web_page: 'http://www.mansfield.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNgroMvr5dwlecPWMd6AJuzRyFoJher4M7V21Bj57GKH6PJoN61R1I0tqF',
    id: 524,
    acronym: 'MUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'maricopa.edu',
    name: 'Maricopa County Community College District',
    web_page: 'http://www.maricopa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoJ62vuKP9L4tTr-zEDqf890eJjlOspE4V_QQaou-psdvTGeWTkJZfsxY',
    id: 525,
    acronym: 'MCCCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marietta.edu',
    name: 'Marietta College',
    web_page: 'http://www.marietta.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoKIWII07NDblHa-UZDdcKRm2H502Cg3VapEskOCJnGk5cwPkFgsR0nA',
    id: 526,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marist.edu',
    name: 'Marist College',
    web_page: 'http://www.marist.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/marist-college.jpeg',
    id: 527,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marlboro.edu',
    name: 'Marlboro College',
    web_page: 'http://www.marlboro.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS0IEXH4EsFq3NcQ_c3kHUH2OWGsXVO5noklWjGk60w5FxmdsGxxEXncA',
    id: 528,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marquette.edu',
    name: 'Marquette University',
    web_page: 'http://www.marquette.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZgRRMiuRbF8rwGEkxZQm0b1D5PuiCE2f8-iG6u4894SQCWL8PA-Xn4-M',
    id: 529,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marshall.edu',
    name: 'Marshall University',
    web_page: 'http://www.marshall.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14825college.jpg',
    id: 530,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mbc.edu',
    name: 'Mary Baldwin College',
    web_page: 'http://www.mbc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkL8rVGq7u-JHRA0gdEFiVAH5jMgG2O-y3Ku17pC0J2xn0mK-rJLrDig',
    id: 531,
    acronym: 'MBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mmm.edu',
    name: 'Marymount Manhattan College',
    web_page: 'http://www.mmm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF4ijGQ65KfDUq0q3RzwxnRw79SjywLnwx0zs2wBK0sDeN6Be_OGMQsw',
    id: 532,
    acronym: 'MMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marymount.edu',
    name: 'Marymount University',
    web_page: 'http://www.marymount.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6tNVy60wzc6Iea-kt48RYD0rvjGhH7eQARppj_seEXpmrR8MaZKR9HQ',
    id: 533,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mwc.edu',
    name: 'Mary Washington College',
    web_page: 'http://www.mwc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2fYfl0B8bspm636V31cOJ-TYIVkpxlJc32eDpfWzxYa38s073pdFJmg',
    id: 534,
    acronym: 'MWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mit.edu',
    name: 'Massachusetts Institute of Technology',
    web_page: 'http://web.mit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_eXpaatPo61EakP_o3L1TwPp9c1gaA-HTULBHRU57_unKv5czejIXXtU',
    id: 535,
    acronym: 'MIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcdaniel.edu',
    name: 'McDaniel College',
    web_page: 'http://www.mcdaniel.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWhRgbwR8i7ECWtF8WN1yTADA7kujNZVIlOP6qj98jHuomi7p87hJe7g',
    id: 536,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcm.edu',
    name: 'McMurry University',
    web_page: 'http://www2.mcm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSES1j3_JyEKQeqfjvPqweMfm5FTmuF7RoVHHPDs8p_4BEkopyGXVG-apA',
    id: 537,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcneese.edu',
    name: 'McNeese State University',
    web_page: 'http://www.mcneese.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14851college.jpg',
    id: 538,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcg.edu',
    name: 'Medical College of Georgia',
    web_page: 'http://www.mcg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6L0ZcTRUIhGxv_EsyLxlA05eZkMpbbht44GUq4mVXAvoM4aF6jag01g',
    id: 539,
    acronym: 'MCG',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcw.edu',
    name: 'Medical College of Wisconsin',
    web_page: 'http://www.mcw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTenkUXd1JykgQMG4wZdRaH7IWyjgGstlxbFIak3zG4hnqEcJOP23exet8',
    id: 540,
    acronym: 'MCW',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mccc.edu',
    name: 'Mercer County Community College',
    web_page: 'http://www.mccc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwOwQb2biidnsGzDyAiI1prxZ4SKBIocelGOvKvSCRzKeRYd2YLAZung',
    id: 541,
    acronym: 'MCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mercer.edu',
    name: 'Mercer University',
    web_page: 'http://www.mercer.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14858college.jpg',
    id: 542,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mercymavericks.edu',
    name: 'Mercy College',
    web_page: 'http://www.mercy.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnD9kJ6xhrDvZVcH82n1g5ZpRPctvSMQXG6uaZ_t6oleyl8wnNzIc8Bg',
    id: 543,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mercyhurst.edu',
    name: 'Mercyhurst College',
    web_page: 'http://mercyhurst.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw5dQ52a7R_RkDRforhylt1Zpd6Cekib6zHLlfYIGtB-C5seKNDfVBpao',
    id: 545,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'meredith.edu',
    name: 'Meredith College',
    web_page: 'http://www.meredith.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXZrUp2oLLF3XU9cBeisy78QVlmMYuGujxfnLc5OLDmcflClaFn1oDEvg',
    id: 546,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'messiah.edu',
    name: 'Messiah College',
    web_page: 'http://www.messiah.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdII_KIHCGg4Cm_iPXTy79gEB7v-jLKTWACbz1Raug7gLvW4AdoWOCLxv2',
    id: 547,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcckc.edu',
    name: 'Metropolitan Community College (MO)',
    web_page: 'http://www.mcckc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF0B6NXRRSvlmypDNCqqNCVjbGB5IkfGlBddGh_xKvXlL_gD8SydxCeA',
    id: 548,
    acronym: 'MCC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mccneb.edu',
    name: 'Metropolitan Community College (NE)',
    web_page: 'http://www.mccneb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRloWkzHfazIgKBO0snHc8s7s6QQgMLT4h0w0UX7CuMoQi_OPKYiw2a3Cg',
    id: 549,
    acronym: 'MCC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msudenver.edu',
    name: 'Metropolitan State College of Denver',
    web_page: 'http://www.msudenver.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn66mVAZfCkR3_wez8NzXHH9VXBuEYGnMrhwjfk2ZPA5qJprVBP_MBy88',
    id: 550,
    acronym: 'MSCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'metrostate.edu',
    name: 'Metropolitan State University',
    web_page: 'http://www.metrostate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqM6QdY6W7PgGW2uuPnpbsShUKY78smir099CszbRABnxYhyDVARicjQ',
    id: 551,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'miamioh.edu',
    name: 'Miami University of Ohio',
    web_page: 'http://www.miamioh.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Miami-University-of-Ohio.svg',
    id: 552,
    acronym: 'MUO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msu.edu',
    name: 'Michigan State University',
    web_page: 'http://www.msu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Michigan-State-University.svg',
    id: 553,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mtu.edu',
    name: 'Michigan Technological University',
    web_page: 'http://www.mtu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTganb2qOepd80L5cTCHIuMp271OUJq4upE7_MmiWHV9mKBSnVXQXzyIA',
    id: 554,
    acronym: 'MTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'manc.edu',
    name: 'Mid-America Nazarene College',
    web_page: 'http://www.manc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwDMeoI3SEjL1fq7YQz8fP-waXJMApWynrkbtD0sydotM9668i172QcCo',
    id: 555,
    acronym: 'MNC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'middlebury.edu',
    name: 'Middlebury College',
    web_page: 'http://www.middlebury.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX9ufJe38lXtoNtbcfv19QDlipg6jYy4FUCedynw6TUj19rzLaRk-yUg',
    id: 556,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mga.edu',
    name: 'Middle Georgia State College',
    web_page: 'http://www.mga.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_QCo_RYfv7QuE4oESGzHrRZziuqRHveJviKuMvEI2VYNaYidUY4-WNE4',
    id: 557,
    acronym: 'MGSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mtsu.edu',
    name: 'Middle Tennessee State University',
    web_page: 'http://www.mtsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Middle-Tennessee-State-University.svg',
    id: 558,
    acronym: 'MTSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mwsu.edu',
    name: 'Midwestern State University',
    web_page: 'http://www.mwsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRV6gQFmO2RyUlN5qCWmv2bPwAskd1yMQbKx_P0oeYAYH5c6sD1QfK4J40',
    id: 559,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'millersville.edu',
    name: 'Millersville University of Pennsylvania',
    web_page: 'http://www.millersville.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJ0_n6ur8YwszIY4YbExpPwewFgsB1n_9eGUe2S2_S0-w-3P-b0gO8iS7W',
    id: 560,
    acronym: 'MUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'milligan.edu',
    name: 'Milligan College',
    web_page: 'http://www.milligan.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0WjC7l4bYvVi-Ha-ikPzcGNYJ_AVEeZT3DIDAVuTjlKxzjZj9B9VDpQ',
    id: 561,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'millikin.edu',
    name: 'Millikin University',
    web_page: 'http://www.millikin.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKd0Q-USnLilf-rCbc-q6tii85cwbT7uMw36BG-MIkGUFoJbJyKtOzuw',
    id: 562,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'millsaps.edu',
    name: 'Millsaps College',
    web_page: 'http://www.millsaps.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI9mICjWIp0Mg5eiFFSA8zd_z9BfT27h2_v08FgnZX4WDKjgshYk4p7c0',
    id: 563,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msoe.edu',
    name: 'Milwaukee School of Engineering',
    web_page: 'http://www.msoe.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgOEzVacCctS0VXPSatUeMYW6Y0Wu4jYDfpYePhJM7nxZ9SNarm3ocRJo',
    id: 564,
    acronym: 'MSE',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcad.edu',
    name: 'Minneapolis College of Art and Design',
    web_page: 'http://www.mcad.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt7U-Us1CXQ6YP4zdzxZcOI1yigjpKIU232tejS0lXrN-DaBS3PDY4FQ',
    id: 565,
    acronym: 'MCAD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mnsu.edu',
    name: 'Minnesota State University Mankato',
    web_page: 'http://www.mnsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_pl1-pd0Stm472bp7TOfbwr9IDeWY2c0hXeXvaZLUp88tOZZLA_0F4A',
    id: 566,
    acronym: 'MSUM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mnstate.edu',
    name: 'Minnesota State University Moorhead',
    web_page: 'http://www.mnstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEGh7zKhcEndaMdrBZ8aCGghHCm_0E04kBRyK8AwxCP7K9sgHMOkL23kd2',
    id: 567,
    acronym: 'MSUM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'minotstateu.edu',
    name: 'Minot State University',
    web_page: 'http://www.minotstateu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVdFWjSx2UVHFVl25DHH8TsLC3w54KxKY4oBl12nbdD6ATP46ncL9rcw',
    id: 568,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mc.edu',
    name: 'Mississippi College',
    web_page: 'http://www.mc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxlVtEukUVRyGjcUc75QR-PWUhMovO0mnC4s2r1ADUApmfwLL7ifZdp84',
    id: 569,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msstate.edu',
    name: 'Mississippi State University',
    web_page: 'http://www.msstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Mississippi-State-University.svg',
    id: 570,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'muw.edu',
    name: 'Mississippi University for Women',
    web_page: 'http://www.muw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlhBQ0FULDHbuwpY-Y561_KkkbQdmHZmWhVGOZQmz5dDVzi8PL_zRwgA',
    id: 571,
    acronym: 'MUFW',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mssc.edu',
    name: 'Missouri Southern State College',
    web_page: 'http://www.mssc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhMYqXBZwnvJIS_y_ZfAs1BsD_kzNXJxOzZ2CcuqlNyNXfm4Q0voXc67k',
    id: 572,
    acronym: 'MSSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mst.edu',
    name: 'Missouri University of Science and Technology',
    web_page: 'http://www.mst.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU4xIl4awNTr7GDPyYcuoFQNPHvwcrGKzsxqcIwaAoZZEFeMfmBzWycg',
    id: 573,
    acronym: 'MUST',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mwsc.edu',
    name: 'Missouri Western State College',
    web_page: 'http://www.mwsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaEOoBtlFMG45T58ZQ6EC812uuAs9hz2CJ-lqVV1J9BOzL-H8ayCYiUS4',
    id: 574,
    acronym: 'MWSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'molloy.edu',
    name: 'Molloy College',
    web_page: 'http://www.molloy.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5C3j4wMOOSSGvJMSOAJGaUiukpwDUny1KGFelYtQby49Ny4024TP0_A',
    id: 575,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'monmouthcollege.edu',
    name: 'Monmouth College',
    web_page: 'http://www.monmouthcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZMEkeUcP5rZGNo8TN3r21eF8rLgoRGMyBGFzfnoJMUIFBxwYIpREDsA',
    id: 576,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'monmouth.edu',
    name: 'Monmouth University',
    web_page: 'http://www.monmouth.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14925college.jpg',
    id: 577,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msubillings.edu',
    name: 'Montana State University - Billings',
    web_page: 'http://www.msubillings.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkoEC-OP6ZIMhJgO-6-V0eAgtK5ZqzfJAxZUqgt6DzTb7i9_N1OmVIzA',
    id: 578,
    acronym: 'MSU-B',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'montana.edu',
    name: 'Montana State University - Bozeman',
    web_page: 'http://www.montana.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14929college.jpg',
    id: 579,
    acronym: 'MSU-B',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msun.edu',
    name: 'Montana State University - Northern',
    web_page: 'http://www.msun.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwUJKjzGEW8cYdSF9sMNPlbNWgExo1Z3IhKiIKk1vXmRK_H-kxef3cQkI',
    id: 580,
    acronym: 'MSU-N',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mtech.edu',
    name: 'Montana Tech',
    web_page: 'http://www.mtech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbKRhEAYGQc0kUJcRs0CtaVHZDpTzNfczxf3DVOtsqUy_SNrglOJQRvzk',
    id: 581,
    acronym: 'MT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'montclair.edu',
    name: 'Montclair State University',
    web_page: 'http://www.montclair.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCFeOZqw8F3WtWZ5yqRajgXo2em9deW1nKJOWKzUFOYbzXfzjHq_T7v7U',
    id: 582,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'montgomerycollege.edu',
    name: 'Montgomery College',
    web_page: 'http://www.montgomerycollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTagElZArY2NzQnIIQ22RharkBekTxvmg_oCYtmEPRYDan8ZfssnXfjuA',
    id: 583,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'montreat.edu',
    name: 'Montreat College',
    web_page: 'http://www.montreat.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg6jYA1Tllq7_Hu09X089QdD-LfFYH2GHsRvwxhAdpbvA0C0KmoivbYbY',
    id: 584,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'moravian.edu',
    name: 'Moravian College',
    web_page: 'http://www.moravian.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdf-g3bDNX4jvyH3jD9Gdz4wzjvtK2lBUNhMWKmCYAMlxcqXrB4skepQ',
    id: 585,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'morgan.edu',
    name: 'Morgan State University',
    web_page: 'http://www.morgan.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14945college.jpg',
    id: 586,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcc.edu',
    name: 'Mott Community College',
    web_page: 'http://www.mcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1z-3H6AdvQUVNiT8fZ0-tJtUqBowQkw-FPEALj6kxCtbefNnnPSZ_kw',
    id: 587,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mtholyoke.edu',
    name: 'Mount Holyoke College',
    web_page: 'http://www.mtholyoke.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1iTqb1ZojYhc-oz8UXN3RVfJTfEV0SkFmBY-h1Bf9N0g6pkBKWfFVsA',
    id: 588,
    acronym: 'MHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msj.edu',
    name: 'Mount Saint Joseph College',
    web_page: 'http://www.msj.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRYWBDrmln28l_4y8txROxpDutHkWyZx7TXQFkhRvo7A7qfmWcJLLyjw',
    id: 589,
    acronym: 'MSJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msmc.edu',
    name: 'Mount Saint Mary College',
    web_page: 'http://www.msmc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMzoZ3pSD8w8pisS4U_ElaQpQ5NAauvafanQebcTQeI_x9Wpig5qElkiCs',
    id: 590,
    acronym: 'MSMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mscfs.edu',
    name: 'Mount Senario College',
    web_page: 'http://www.mscfs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ4RXZSeI9uZQ-NwoLT7CFvfcH6dxvYPQdtrdQnrvKYVt9Mjg87hW3Wg',
    id: 591,
    acronym: 'MSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'muc.edu',
    name: 'Mount Union College',
    web_page: 'http://www.muc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiRNsj12Ts_zamjZscxuOIr0d0x1A8P4NGNhQghm4GBPr2CtBcD7ir8Q',
    id: 592,
    acronym: 'MUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mursuky.edu',
    name: 'Murray State University',
    web_page: 'http://www.mursuky.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14974college.jpg',
    id: 593,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'muskingum.edu',
    name: 'Muskingum College',
    web_page: 'http://www.muskingum.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLYeqzWk9YI8Fa10pc_XhvFODHzIcb742nPcxq2Tk8EEC0CECl0c1NZA',
    id: 594,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nscc.edu',
    name: 'Nashville State Community College',
    web_page: 'http://www.nscc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnW9fNdxXo6gVf_GPgqG_rfKuCp9BEnlGnrG6lyVMAc_4deCTdeFIz9CM',
    id: 595,
    acronym: 'NSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ndu.edu',
    name: 'National Defense University',
    web_page: 'http://www.ndu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuGKqdFdWM6cZbJyyty9W4bCkQQwXsvnIDTHHVfnGfpotovXe-2MkRzFoL',
    id: 596,
    acronym: 'NDU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nl.edu',
    name: 'National-Louis University',
    web_page: 'http://www.nl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiqj1g9z9ffTu-Y4usGyIqZ4B3BR41rvFtFpriJ17I6anN4aqC_3NocA',
    id: 597,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nrao.edu',
    name: 'National Radio Astronomy Observatory',
    web_page: 'http://www.nrao.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbfcGV9cVraLbs8kmR5DBVYuUSOlNP2fZ5cUCFBU3uT5P3xYROnCaU8kQ',
    id: 598,
    acronym: 'NRAO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ntu.edu',
    name: 'National Technological University',
    web_page: 'http://www.ntu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMaLZz_xoEntmtto3z9I9Jp3Kem_eytyElPxZ-SAlm43FqPTvCjVIA_-Y',
    id: 599,
    acronym: 'NTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nu.edu',
    name: 'National University',
    web_page: 'http://www.nu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0rwulcf2oAm_q416xUtf7_AabfCmQOMHAZWTiECjGH95MmZdNMk3HIw',
    id: 600,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nps.edu',
    name: 'Naval Postgraduate School',
    web_page: 'http://www.nps.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrnCTg0q0aSfnajCqaPvejkM6HsEXl-zf1Of9sMOBtSBS7pvOsYYzHwA',
    id: 601,
    acronym: 'NPS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'naz.edu',
    name: 'Nazareth College',
    web_page: 'http://www2.naz.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiOHFVZNY0N5T2E20609xxu_BT54uYtMt_EJ8e1iO-uebN2wBeiWdsTA',
    id: 602,
    acronym: 'NC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nevada.edu',
    name: 'Nevada System of Higher Education',
    web_page: 'http://system.nevada.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRINtvNECmeBVOUBrBGpj77IafNanOSDIkazUyIoYnQgg2RLAY-Mq3wqA',
    id: 603,
    acronym: 'NSHE',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'newberry.edu',
    name: 'Newberry College',
    web_page: 'http://www.newberry.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiPC0EdLzX7a2Qj7k8ZsrUrhfHo9oaWld9m23-uAVAuzTUfd2_Bt2a2Q',
    id: 604,
    acronym: 'NC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'newcollege.edu',
    name: 'New College of California',
    web_page: 'http://www.newcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5PFOPRifE0WJmcA68Krxknvu_ynJ_MwwfeCvFKA3XZhL5ZQd2ywqIkrs',
    id: 605,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'neit.edu',
    name: 'New England Institute of Technology',
    web_page: 'http://www.neit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkIcxUdH_spwiRsOyJNyfourHkH7DR805dgUHQqFS19QNo3Pf_-YuPSrI',
    id: 606,
    acronym: 'NEIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nhc.edu',
    name: 'New Hampshire College',
    web_page: 'http://www.nhc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-UzYhaKxbbbTWU3Ib-CiBzDmDOvQl6Q2iaelWaV3Bf6GN4PYxmjJ_Kw',
    id: 607,
    acronym: 'NHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'njit.edu',
    name: 'New Jersey Institute of Technology',
    web_page: 'http://www.njit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQHcDgzhSocLpTCDQEIUFuiV29nV1lD1Gql4Ch5FpOXBxIEf1zYMhCo0M',
    id: 608,
    acronym: 'NJIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nmhu.edu',
    name: 'New Mexico Highlands University',
    web_page: 'http://www.nmhu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs_W0TFQpm0uhFGZ71TtZXxwZiSCcPtdHINO8vS3NmDy2K4VCavytMEw',
    id: 609,
    acronym: 'NMHU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nmt.edu',
    name: 'New Mexico Institute of Mining & Technology',
    web_page: 'http://www.nmt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4vzGJFwhqPsRNP2hSljbkyuhIHBnIJRC78j9y3fE2aStCYDpR1bjUupM',
    id: 610,
    acronym: 'NMIM&T',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nmsu.edu',
    name: 'New Mexico State University',
    web_page: 'http://www.nmsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/New-Mexico-State-University.svg',
    id: 611,
    acronym: 'NMSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nyit.edu',
    name: 'New York Institute of Technology',
    web_page: 'http://www.nyit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHZDfHD2Gzmb5LtoE9DyG_sME__91_HTzPZuHbLHhT7IGrUsDFqhpqIA',
    id: 612,
    acronym: 'NYIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nyu.edu',
    name: 'New York University',
    web_page: 'http://www.nyu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqUpe6bbADqmd0kkUdxtpZFLM5VNjDL8uFVTL2jCNuNdkKIMkkBVf5Qf4',
    id: 613,
    acronym: 'NYU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'niagara.edu',
    name: 'Niagara University',
    web_page: 'http://www.niagara.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxtKjJNAbCHzEtdb_Kin0ZdtDeVathJRRwuf23dIIeLttD01E_jrz-NA',
    id: 614,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nicholls.edu',
    name: 'Nicholls State University',
    web_page: 'http://www.nicholls.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15003college.jpg',
    id: 615,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nsu.edu',
    name: 'Norfolk State University',
    web_page: 'http://www.nsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15005college.jpg',
    id: 616,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'na.edu',
    name: 'North American University',
    web_page: 'http://www.na.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKCX-9fpkK0tB1kU1znbvTUePgPXIPgWDKkjWPjnyQeiEMsL-0K-3crnU',
    id: 617,
    acronym: 'NAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northampton.edu',
    name: 'Northampton Community College',
    web_page: 'http://www.northampton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2zq7YSoVlZMlLInnlzjGerhKApZbW7843BZ8aQDbhtp6njBXdiveL3DI4',
    id: 618,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncat.edu',
    name: 'North Carolina A&T State University',
    web_page: 'http://www.ncat.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_bNzFVZ85rPjTOxiYMzTLMtyUjDQ1WOv-vIvNo1cjYikYx3Xk8uSSzfE',
    id: 619,
    acronym: 'NCASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nccu.edu',
    name: 'North Carolina Central University',
    web_page: 'http://www.nccu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15008college.jpg',
    id: 620,
    acronym: 'NCCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncsu.edu',
    name: 'North Carolina State University',
    web_page: 'http://www.ncsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/North-Carolina-State-University.svg',
    id: 621,
    acronym: 'NCSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncwc.edu',
    name: 'North Carolina Wesleyan College',
    web_page: 'http://www.ncwc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx-sAnB1GwwHUf-gath0gyKOG7ANZWOBLwziSchXQDggatBrjxgtO-9bD7',
    id: 622,
    acronym: 'NCWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncbc.edu',
    name: 'North Central Bible College',
    web_page: 'http://www.ncbc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRp7uql5qr8Te5eE_cppgSTTKDyxmoJYObx0mK4_Z7BPIdYvRwm8N9PpXQ',
    id: 623,
    acronym: 'NCBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ndsu.edu',
    name: 'North Dakota State University',
    web_page: 'http://www.ndsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/north-dakota-state-university.png',
    id: 624,
    acronym: 'NDSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nlu.edu',
    name: 'Northeastern Louisiana University',
    web_page: 'http://www.nlu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReaW4ih8iE1Sc3EDEamy_gDH9aiK6HO7aBa2Ubcew8lXAT_HZ_2tabXS0',
    id: 625,
    acronym: 'NLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nsuok.edu',
    name: 'Northeastern State University',
    web_page: 'http://www.nsuok.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6X2J5oocAOdK_JzW87C_A1XA4fBwZ-J921cjjQUzfGq2MdD4-eIsHFgg',
    id: 626,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'neu.edu',
    name: 'Northeastern University',
    web_page: 'http://www.northeastern.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUWlTNSiR4eeLEF19ec4jqhTkkSWLWMyfSZgdkpsI0xJ3iL6hooG92DX8',
    id: 627,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nau.edu',
    name: 'Northern Arizona University',
    web_page: 'http://www.nau.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/northern-arizona-university.png',
    id: 629,
    acronym: 'NAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'niu.edu',
    name: 'Northern Illinois University',
    web_page: 'http://www.niu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Northern-Illinois-University.svg',
    id: 630,
    acronym: 'NIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nku.edu',
    name: 'Northern Kentucky University',
    web_page: 'http://www.nku.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBECGTxUtUpGYYYk4UTswyd7pJ2ccbSL4OgpJz_UhKZ1QFFAVmGDOg4g',
    id: 631,
    acronym: 'NKU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nmu.edu',
    name: 'Northern Michigan University',
    web_page: 'http://www.nmu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVZZgekNK1nC04Bip4wUUiGT_2lhFEc47hQ5jDlBhiDidlZxBm-m5PUuA',
    id: 632,
    acronym: 'NMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northern.edu',
    name: 'Northern State University',
    web_page: 'http://www.northern.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw6t3Ild9RfpwFvhrPLsWlOiELa-Wj9bb4ubLMol5EtpOGlOVaDAfDOEA',
    id: 633,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northland.edu',
    name: 'Northland College',
    web_page: 'http://www.northland.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPT06KywVX9M3R53uVKv9ZqSfClkJyizBF8KSoq78zjVo42TsD_Sh-oD9Q',
    id: 634,
    acronym: 'NC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'npcts.edu',
    name: 'North Park College and Theological Seminary',
    web_page: 'http://www.npcts.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCoLPRFyDwgk3uax7_U1N0DIIjFHDj1zSnREMUKQeFu0-RkgEtFFnvN8M',
    id: 635,
    acronym: 'NPCTS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwciowa.edu',
    name: 'Northwestern College of Iowa',
    web_page: 'http://www.nwciowa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuN81rovtk5avQ_akrluIAJBEh-saya31S3SXQTkZjByKXsqdUCPtzMiU',
    id: 636,
    acronym: 'NCI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nsula.edu',
    name: 'Northwestern State University',
    web_page: 'http://www.nsula.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/northwestern-state-university-of-louisiana.png',
    id: 637,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northwestern.edu',
    name: 'Northwestern University',
    web_page: 'http://www.northwestern.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Northwestern-University.svg',
    id: 638,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwmissouri.edu',
    name: 'Northwest Missouri State University',
    web_page: 'http://www.nwmissouri.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQklaI7QSsglvnKMioR6hxxuqrcHdaZF_andJ4j-3DqtlVarvTbuvBj2Hc',
    id: 639,
    acronym: 'NMSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nnc.edu',
    name: 'Northwest Nazarene College',
    web_page: 'http://www.nnc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrRU9gnhmFy987jnwD5zjTs2GDYT1nydI-IpiWCKqQvY1jn4bpB5og2xE5',
    id: 640,
    acronym: 'NNC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'norwich.edu',
    name: 'Norwich University',
    web_page: 'http://www.norwich.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx-ZACvSE8u3vVZlig330me0WUdfG2qBspxcDXCrUsMzWzBiotpOxsw2LaDA',
    id: 641,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nova.edu',
    name: 'Nova Southeastern University',
    web_page: 'http://www.nova.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWBimcHAMHtkrW3M3no4W_1UMmmJ-WkTcxKknaizyQmkEtLa7aLKxoAA',
    id: 642,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oakland.edu',
    name: 'Oakland University',
    web_page: 'http://www.oakland.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFYwiR-Er3KntTOicdsouU7k0YpatF4p4xkER6oiJbGKTrg58RoglT5Iw',
    id: 643,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oakwood.edu',
    name: 'Oakwood University',
    web_page: 'http://www.oakwood.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZF3K88ufkRmvNFkL8EMcHtNwBo5ucrROg1uPi-cdManoUHy9D_JU2CEk',
    id: 644,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oberlin.edu',
    name: 'Oberlin College',
    web_page: 'http://www.oberlin.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzmnd9UifAqwMLNqn2VSIzTz7XdlSdwgV1UtoxE3wlrpx_FFmC6ceU7Mo',
    id: 645,
    acronym: 'OC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oxy.edu',
    name: 'Occidental College',
    web_page: 'http://www.oxy.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGnhTYxrqc_9QzDhSrTNkpJKUp1iOgUJw2k3w7z2zqwrcbfafndOQ2hg',
    id: 646,
    acronym: 'OC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'odc.edu',
    name: 'Ohio Dominican College',
    web_page: 'http://www.odc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAMCbIGmZ2mI_f9QncUh_5SgB_cCG6hh89hZOBGba4MztZTTH11g5cFw',
    id: 647,
    acronym: 'ODC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'onu.edu',
    name: 'Ohio Northern University',
    web_page: 'http://www.onu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBMAiVbJxkTFrE4gdLzXEavFPtETlSGt-In4cq6nUkRJt03PCY__08BH0',
    id: 648,
    acronym: 'ONU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'osu.edu',
    name: 'Ohio State University Columbus',
    web_page: 'http://www.osu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Ohio-State-University-Columbus.svg',
    id: 649,
    acronym: 'OSUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ohio.edu',
    name: 'Ohio University',
    web_page: 'http://www.ohio.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Ohio-University.svg',
    id: 650,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'owu.edu',
    name: 'Ohio Wesleyan University',
    web_page: 'http://www.owu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC2kdmfqb0tGaJqUAy4BHsuPtBqABLCqbHEi-fR94GSEIV1ah1Jm1XxUc',
    id: 652,
    acronym: 'OWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'okbu.edu',
    name: 'Oklahoma Baptist University',
    web_page: 'http://www.okbu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIzzXrff7BmjZX85QeqVbmqjHRPMAaj3ynY3bVgNzVj-PhNUN74m7S8w',
    id: 653,
    acronym: 'OBU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'okcu.edu',
    name: 'Oklahoma City University',
    web_page: 'http://www.okcu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOPafHL8EXr9cZZ2J3Okazkl62RYj8Kv2vpczOoSNLUWRK3fi8En5gzDA',
    id: 654,
    acronym: 'OCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'okstate.edu',
    name: 'Oklahoma State University',
    web_page: 'http://www.okstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Oklahoma-State-University.svg',
    id: 655,
    acronym: 'OSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'odu.edu',
    name: 'Old Dominion University',
    web_page: 'http://www.odu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Old-Dominion-University.svg',
    id: 656,
    acronym: 'ODU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'olivet.edu',
    name: 'Olivet Nazarene University',
    web_page: 'http://www.olivet.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR36nKxHHyZ7_xbgb-dwP8dF6rXK1zqZNEdaJI0z7skDg9EALNbvGIfswc',
    id: 657,
    acronym: 'ONU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oru.edu',
    name: 'Oral Roberts University',
    web_page: 'http://www.oru.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-f-Qbz_9OP4YMJZ7mcP7d7Wms468i-VZ1vh3f6Im326r8ze0aNe_zXg',
    id: 658,
    acronym: 'ORU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ogi.edu',
    name: 'Oregon Graduate Institute of Science and Technology',
    web_page: 'http://www.ogi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ27M4-kmwzUT5Q5M92Y9qwz_xh6VCsMh7K7J5r29snYoSGnveKl02YzA',
    id: 659,
    acronym: 'OGIST',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ohsu.edu',
    name: 'Oregon Health Sciences University',
    web_page: 'http://www.ohsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEYWiXs5uiTYbO3tPDOmpN_WqFfYSq59PsOP_nF0VRsehy-l_W2Wjs_h8',
    id: 660,
    acronym: 'OHSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oit.edu',
    name: 'Oregon Institute of Technology',
    web_page: 'http://www.oit.edu/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTE0v_2w3_nRGFB5lVBwOz8EO9AfV4BV9k5frp04l5ez3yRwjiO7PGP3w',
    id: 661,
    acronym: 'OIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oregonstate.edu',
    name: 'Oregon State University',
    web_page: 'http://www.oregonstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Oregon-State-University.svg',
    id: 662,
    acronym: 'OSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'otterbein.edu',
    name: 'Otterbein College',
    web_page: 'http://www.otterbein.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOp3LKRKqpatPAeIhBHptuZvRpMhmO2QdJl3KKU3Mp30AQDpS2VCU5AQ',
    id: 663,
    acronym: 'OC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ollusa.edu',
    name: 'Our Lady of the Lake University',
    web_page: 'http://www.ollusa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6omuS-NxZbDEq8KR-BakNCfYj-z1U_t_NpHe9KaJrUFRZ4JM0QHiBi0hE',
    id: 664,
    acronym: 'OLLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pace.edu',
    name: 'Pace University',
    web_page: 'http://www.pace.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNuR9SyH5S-Wh7nRZnppNS-r6Za0zDWPc-yjJTezZagJ2zQeeY2gWgsw',
    id: 665,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'plu.edu',
    name: 'Pacific Lutheran University',
    web_page: 'http://www.plu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEgg6gbF7Q_GzaoLuoYfx7EUUGR7Wn2_IvAF6SgklQP6Aqe8m4OpIaQqM',
    id: 666,
    acronym: 'PLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'puc.edu',
    name: 'Pacific Union College',
    web_page: 'http://www.puc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRv-Db4h-Wrb55b1nnc2EGHAZr010lrzzCxssO7Nnv__pK2948KnBGl4g',
    id: 667,
    acronym: 'PUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pacificu.edu',
    name: 'Pacific University',
    web_page: 'http://www.pacificu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHnsgusElW5g1vkWtoWsl6MSe1RD1qFRW0Ty9IpRsdk8Qi6o8L8CVs4A',
    id: 668,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pwu.com',
    name: 'Pacific Western University',
    web_page: 'http://www.pwu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLqUdTL4pgoOHADXzCgb_8khfhADbAZ4JIoTk-XPYZZd5uO2r8hEUWcA',
    id: 669,
    acronym: 'PWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pbac.edu',
    name: 'Palm Beach Atlantic College',
    web_page: 'http://www.pbac.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRe9pnEbdIFDE6ji_rayFDILtvuDQYumv_oDpLA6iu8uLeYdizeE3WgyyY',
    id: 670,
    acronym: 'PBAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'palmbeachstate.edu',
    name: 'Palm Beach State College',
    web_page: 'http://www.palmbeachstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd9bxZm3HtE-thC-nR9tJXkoQdOKvIU99Qod-IB0vrZhzXXXbsDIP8IH8',
    id: 671,
    acronym: 'PBSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'peace.edu',
    name: 'Peace College',
    web_page: 'http://www.peace.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7qHOam6Hq4HXY6GlCNqdXE9YoBrMNEwIu9fm3OfPr2BaD6_MmKoZr7xE',
    id: 672,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pembroke.edu',
    name: 'Pembroke State University',
    web_page: 'http://www.pembroke.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd4t0MA7VXD18dhBOy4QbtY9YuJxrvzemejFBYAS47hX1GGtQ9H6EIiw',
    id: 673,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pct.edu',
    name: 'Pennsylvania College of Technology',
    web_page: 'http://www.pct.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ3_PWjMw8Gr6j0YtN5p_Hde0wwSb76Fr0Zuekv3v0VmDZ2m8Rt6lkxg',
    id: 674,
    acronym: 'PCT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'psu.edu',
    name: 'Pennsylvania State University',
    web_page: 'http://www.psu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Pennsylvania-State-University.svg',
    id: 675,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sl.psu.edu',
    name: 'Pennsylvania State University - Schuylkill Campus',
    web_page: 'http://www.sl.psu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQV23QHKtiBQwPvQF0abn4ykbguMvOBiqTISNUpKWDrCtEdxUbMQy_-fEM',
    id: 676,
    acronym: 'PSU-SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pcci.edu',
    name: 'Pensacola Christian College',
    web_page: 'http://www.pcci.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXATtiA5VslHyXQLUG1HWjf7OaUFzzJfdLIs8qrrwynpni_9UFjEeZa8A',
    id: 677,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pensacolastate.edu',
    name: 'Pensacola State College',
    web_page: 'http://www.pensacolastate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcT8GJgcENPIcCQqvP812WC24ZgSWTY_DAjJQxRaplibbaM10U8iiMvA',
    id: 678,
    acronym: 'PSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pepperdine.edu',
    name: 'Pepperdine University',
    web_page: 'http://www.pepperdine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcT9S7HKi-F3Is3t7d3hOXjpncgFKMaPyH3Z4Z29VhmbNEUVJPLAj9yng',
    id: 679,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'peru.edu',
    name: 'Peru State College',
    web_page: 'http://www.peru.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI3jLmRIaVL2uwE6S6G8vwfrWyEKARdvt9XF_m_Z2sOozGHTo6F-oZ-Q',
    id: 680,
    acronym: 'PSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'philau.edu',
    name: 'Philadelphia University',
    web_page: 'http://www.philau.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkFkhtBhN4Vx1IoxkYfRw1MTpwnqHMr2T9IXl6KF62ndQapRCbMD-0DA',
    id: 681,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'phillips.edu',
    name: 'Phillips University',
    web_page: 'http://www.phillips.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWkcjYA-Q6FKQb7AZBkpuzJ88qXY-ChNhcdfzZ_OQgPq42akly9j53Lgk',
    id: 682,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pittstate.edu',
    name: 'Pittsburg State University',
    web_page: 'http://www.pittstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZ6TNNGF012YvwO0Q11YFalCWn92iFyoEClRMWb7SfgGfHWZjQaVhI2mU',
    id: 683,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pitzer.edu',
    name: 'Pitzer College',
    web_page: 'http://www.pitzer.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlzbBI0KH6MB6WtwGgF4t-pPYDv0w7rYKSZwvF_n5FqmW5V-Tq4I6IfA',
    id: 684,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'platt.edu',
    name: 'Platt College',
    web_page: 'http://www.platt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwZz6p0NaRuoft0dV3Ek-pdT-IiLmS1WCOd78OQ3hLATM9Gbx6fR4pr3I',
    id: 685,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'plymouth.edu',
    name: 'Plymouth State College',
    web_page: 'http://www.plymouth.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwtWcQFlUmH8JkCYnO_y7sli-c4W9DG4K_VCQCTnQGD00UEglPaQACDg',
    id: 686,
    acronym: 'PSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'poly.edu',
    name: 'Polytechnic University of New York',
    web_page: 'http://www.poly.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvNK_h43umVyazBBE-6V92q-5nCbx6pW5gHrkX_qEAs7m19GE58AWHbQ',
    id: 687,
    acronym: 'PUNY',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pupr.edu',
    name: 'Polytechnic University of Puerto Rico',
    web_page: 'http://www.pupr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRf4HwIWe-Ton-nYA6fXCptLgenGOVRPnpSkRwQS6ESMrhowXruDdCd4eo',
    id: 688,
    acronym: 'PUPR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pomona.edu',
    name: 'Pomona College',
    web_page: 'http://www.pomona.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRry4lWKEJxf9JzV77Syb-uuvf2mcBXr2TvnzbE8NUqcp6hd5SWzYC7NA',
    id: 689,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pcc.edu',
    name: 'Portland Community College',
    web_page: 'http://www.pcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThhmu-7PfleifAAISmUdeqJ143ta46l7XbwmyyKm13XhXkmDDUc5vxL6y7',
    id: 690,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pdx.edu',
    name: 'Portland State University',
    web_page: 'http://www.ee.pdx.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15182college.jpg',
    id: 691,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pvamu.edu',
    name: 'Prairie View A&M University',
    web_page: 'http://www.pvamu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/prairie-view-am-university.png',
    id: 692,
    acronym: 'PVAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pratt.edu',
    name: 'Pratt Institute',
    web_page: 'http://www.pratt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbD_5xpkn_AagqdglgFkq5OINpUOtBPoOd6nd_yN0zHOlchpLycoqOTPSOuQ',
    id: 693,
    acronym: 'PI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'presby.edu',
    name: 'Presbyterian College',
    web_page: 'http://www.presby.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15190college.jpg',
    id: 694,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'prescott.edu',
    name: 'Prescott College',
    web_page: 'http://www.prescott.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP0jKBqS8pnec0ciFjCAvxaYpXkyrpxPjyOfSBqLEgYPmKW32m-lwbcaQ',
    id: 695,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'princeton.edu',
    name: 'Princeton University',
    web_page: 'http://www.princeton.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15193college.jpg',
    id: 696,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'providence.edu',
    name: 'Providence College',
    web_page: 'http://www.providence.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQveUgEnKiiCD9JHX8CgPhiokjCSMtroVfpOBLAxAURIJpOWMMHKaGp_nw',
    id: 697,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'purdue.edu',
    name: 'Purdue University',
    web_page: 'http://www.purdue.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Purdue-University.svg',
    id: 698,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'purduecal.edu',
    name: 'Purdue University Calumet',
    web_page: 'http://webs.purduecal.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgE6l64eVc0eImLlh5KgEXmzS5v4vNEYLISIV7Mh_SGwLJoAZ4PlTiWi8',
    id: 699,
    acronym: 'PUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pnc.edu',
    name: 'Purdue University North Central',
    web_page: 'http://www.pnc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcYGhoWmaYrV86Rmf-xu3jpqU-kx36DZYnzO5eswbnwaBjCj1Zax8PpLo',
    id: 700,
    acronym: 'PUNC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'quincy.edu',
    name: 'Quincy University',
    web_page: 'http://www.quincy.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPD8CgD2UVe7ziPiqsSAKqPsFXC8piJQm3OMFcQEKlQYK9AWwCdICxCdg',
    id: 701,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'quinnipiac.edu',
    name: 'Quinnipiac College',
    web_page: 'http://www.quinnipiac.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEWIeGUjW4XgvqHNBigcgCOVFrelNZ6XthdtsoenP97J1vNafBwVfxiQ',
    id: 702,
    acronym: 'QC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'radford.edu',
    name: 'Radford University',
    web_page: 'http://www.radford.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTSiacMv2P3Vgoe-kb7kHi9DHjlmV9QBupNNFCzpUaOnR1w1tkcDk-PA',
    id: 703,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ramapo.edu',
    name: 'Ramapo College',
    web_page: 'http://www.ramapo.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYVz8Xmi18cgTOek96LafoIFyWsKu3zOrCq8UfS-h3DYS55H-MknNMfA',
    id: 704,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rmc.edu',
    name: 'Randolph-Macon College',
    web_page: 'http://www.rmc.edu/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQRnr0nQjNgdJdgbdRowwU6yS1GWXZ5LaAWRJnKCC4w2vhBVzYOyYEQFw',
    id: 705,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rmwc.edu',
    name: "Randolph-Macon Woman's College",
    web_page: 'http://www.rmwc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRnr0nQjNgdJdgbdRowwU6yS1GWXZ5LaAWRJnKCC4w2vhBVzYOyYEQFw',
    id: 706,
    acronym: 'RWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'reed.edu',
    name: 'Reed College',
    web_page: 'http://www.reed.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqjHMDc0_scA9e8COXjJ69SPdY_EckUUtAW11Ax4Pn12iO_cFVjxX0iQ',
    id: 707,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'regent.edu',
    name: 'Regent University',
    web_page: 'http://www.regent.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5n4eBAkexRBPKFUHiW_9EItuZKYT6RhKs7sZfamf4jtlhcpMI1PdJ9w',
    id: 708,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'regis.edu',
    name: 'Regis University',
    web_page: 'http://www.regis.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtnKAHnvJWQ8-ylQzW2hIAzTC6J4hlI-XpdShjn6vTIP2Sy0YCVeO6sxPa',
    id: 709,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rpi.edu',
    name: 'Rensselaer Polytechnic Institute',
    web_page: 'http://www.rpi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnkJogMpc-O9jyoaBiPPyCWjL_mrmLN_D62-yP1guVYADo_7socjqSwQ',
    id: 710,
    acronym: 'RPI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ric.edu',
    name: 'Rhode Island College',
    web_page: 'http://www.ric.edu/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTliL7d1WR2D9a7wTpQJMH_pt4vLhBGkOkWV0Mczj3_Y_fdB7zdEx2s7w',
    id: 711,
    acronym: 'RIC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rhodes.edu',
    name: 'Rhodes College',
    web_page: 'http://www.rhodes.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQo1str_0GEx9kHlFOcI-qg7yjLK161oPzFJ-xebFfyN73EFS84ILfiOeWhaw',
    id: 712,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rice.edu',
    name: 'Rice University',
    web_page: 'http://www.rice.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Rice-University.svg',
    id: 713,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stockton.edu',
    name: 'Richard Stockton College of New Jersey',
    web_page: 'http://www.stockton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoybjcNOMlFKuaTrk1jT0LgchdAPQ8zEpjTv7Fl32bwX9lXe_ZtQPG0fk',
    id: 714,
    acronym: 'RSCNJ',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rider.edu',
    name: 'Rider University',
    web_page: 'http://www.rider.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRCRT9xZyLZCyeo0krbtlHM1750JkEA8tTwYIMuM8Qxm7WLqow8i-Z1A',
    id: 715,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ripon.edu',
    name: 'Ripon College',
    web_page: 'http://www.ripon.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL0P0MK8Ic4TWvkmDfd8-OmAgWg4zHW7MBzO74iIZLgodmRrJ58fpQ0AU',
    id: 716,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'riv.edu',
    name: 'Rivier College',
    web_page: 'http://www.riv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7F2kCXL1z8S-OlChtSmxvMmkke44Ugo4HZz8Jt2dB6w-un2B_Oq2yGsE',
    id: 717,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'roanoke.edu',
    name: 'Roanoke College',
    web_page: 'http://www.roanoke.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9GmQfYbAVN5KuJ2dNNb16l2wo7J4HZzY0d9lxbIjPKWlF1bJrYL_sHOk',
    id: 718,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rmu.edu',
    name: 'Robert Morris University',
    web_page: 'http://www.rmu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15236college.jpg',
    id: 719,
    acronym: 'RMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rit.edu',
    name: 'Rochester Institute of Technology',
    web_page: 'http://www.rit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVCZ2d3lPZh-rNog9cumESK5HySbuL52uyamGdexFnIxQJS5RxQII3Kbo',
    id: 720,
    acronym: 'RIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rockefeller.edu',
    name: 'Rockefeller University',
    web_page: 'http://www.rockefeller.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcY5X0GETZR5V7GMm6vq-xPUMCGjGKKRkTVSFs6QxFQUU2F-xqJLJO2A8',
    id: 721,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rockford.edu',
    name: 'Rockford College',
    web_page: 'http://www.rockford.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwK0cAt0X-aDYv4aIk2vt7ATWtz0Sa9GDd9p1cCWNFEkyqAAtfBL5ToB7XhA',
    id: 722,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rockhurst.edu',
    name: 'Rockhurst College',
    web_page: 'http://www.rockhurst.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6zKCym9wwnK1YvstufjeA5hUXMn5y81oQTJBrHAmWHV1Bga3mDm1c6A',
    id: 723,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rocky.edu',
    name: 'Rocky Mountain College',
    web_page: 'http://www.rocky.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTtsMZlcGSMECxOPOl8TaIdgqfBHm4HgkXkDQFvXWo_c8zsjjL4IQOaw',
    id: 724,
    acronym: 'RMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rwu.edu',
    name: 'Roger Williams University',
    web_page: 'http://www.rwu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3iwtLX5kFn9GskM94pCPmz7Rzlt7lx8pUiKy2Rs04HIcVJDPwANaIFQ',
    id: 725,
    acronym: 'RWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rollins.edu',
    name: 'Rollins College',
    web_page: 'http://www.rollins.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtWfyYs3H1tAY3qe0mZAF5sQU5hV5_cRXLJGtfVTf83EWkHjmlQMCpNA',
    id: 726,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rosary.edu',
    name: 'Rosary College',
    web_page: 'http://www.rosary.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxIsQqQmYERnbbN_1KydP1ePhWrwiNBiLG4Dhzc1n5MFCoOJfn5wW7IA',
    id: 727,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rose-hulman.edu',
    name: 'Rose-Hulman Institute of Technology',
    web_page: 'http://www.rose-hulman.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRznw6E9aY85O0rS4zqTfTe-nt-p6NM-41l1RkjKsyXg8RR7VDhcUjbXA',
    id: 728,
    acronym: 'RIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rowan.edu',
    name: 'Rowan College',
    web_page: 'http://www.rowan.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlTpeoSAGv8Lgek9pqgt1_6ceYm_LrGrGDhuK4RC7QRyzsh74rVGdeXHSN',
    id: 729,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rutgers.edu',
    name: 'Rutgers University',
    web_page: 'http://www.rutgers.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Rutgers-University.svg',
    id: 730,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rutgers.edu',
    name: 'Rutgers University Camden',
    web_page: 'http://www.camden.rutgers.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPzdtTQKNvJLaXZ2mEEmo8wzEypkVHxOQBeZGoaCT1Mc2fvqwrrKmOwjM',
    id: 731,
    acronym: 'RUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rutgers.edu',
    name: 'Rutgers University Newark',
    web_page: 'http://www.newark.rutgers.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPzdtTQKNvJLaXZ2mEEmo8wzEypkVHxOQBeZGoaCT1Mc2fvqwrrKmOwjM',
    id: 732,
    acronym: 'RUN',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sacredheart.edu',
    name: 'Sacred Heart University',
    web_page: 'http://www.sacredheart.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/sacred-heart-university.png',
    id: 733,
    acronym: 'SHU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sage.edu',
    name: 'Sage Colleges',
    web_page: 'http://www.sage.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQThm2Fj643DorofWTAFTkCI-okCdzHXMKYly_o_OTqtbXlWBaKLEPiyg',
    id: 734,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'svsu.edu',
    name: 'Saginaw Valley State University',
    web_page: 'http://www.svsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw4MbWpi3yGEfHqyqI7sRt0tVyG84diJY5jJPgD2kXYB7YDFFdFzSELfg',
    id: 735,
    acronym: 'SVSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'anselm.edu',
    name: 'Saint Anselm College',
    web_page: 'http://www.anselm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Q2Y-CjSo0SAvDRpPLlADHgXl55Rnj73R7n_sURit8orA3d0mcBOujQY',
    id: 736,
    acronym: 'SAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stcloudstate.edu',
    name: 'Saint Cloud State University',
    web_page: 'http://www.stcloudstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJTEdXblV4i4bG_jorOUB_vgDrr-rUjMzN6Ix1vJsSzASS9t71OQcHoA',
    id: 737,
    acronym: 'SCSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stedwards.edu',
    name: "Saint Edward's University",
    web_page: 'http://www.stedwards.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSuoU9jIoKga8ef4Lb63EF97qrNdh7k34hltutKsFx-bXYWh__FlR20A',
    id: 738,
    acronym: 'SEU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sfcpa.edu',
    name: 'Saint Francis College',
    web_page: 'http://www.sfcpa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGjgVPwI4y4xImzHWZhANOu9NlyOk0uRYiVfEGFoCD8IWJzF-UNsiR3Q',
    id: 739,
    acronym: 'SFC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csbsju.edu',
    name: "Saint John's University (MN)",
    web_page: 'http://www.csbsju.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT73Klc7WH0CL4Jl1QCiLCCJ15lh36i2OtqF_L0eYshVFbVvMhobuGlK3M',
    id: 740,
    acronym: 'SJU(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stjohns.edu',
    name: "Saint John's University (NY)",
    web_page: 'http://www.stjohns.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNHZJil7euuA_8N-IXrXKAbCxrAhQ6V7_J4ths5nPfgiEPDaGrMGDbKA',
    id: 741,
    acronym: 'SJU(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'saintjoe.edu',
    name: "Saint Joseph's College (IN)",
    web_page: 'http://www.saintjoe.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYFe0VwApdEsI8N6IK3stCFHIrzXVEX7y5iCEbYBDgNMWU5FsfEan3vn4',
    id: 742,
    acronym: 'SJC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sju.edu',
    name: "Saint Joseph's University",
    web_page: 'http://www.sju.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_y4r5fEdCDwom5qeDK8CbmKqNzPMoIjMnH2AE1bV6hCR8irw_Nsqu-g',
    id: 743,
    acronym: 'SJU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'saintleo.edu',
    name: 'Saint Leo University',
    web_page: 'http://www.saintleo.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT416927NglkqzALnKX1fov8GtqjscYxr5Tx0CftkTsK1gJA2gHJ7w6g',
    id: 744,
    acronym: 'SLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'slu.edu',
    name: 'Saint Louis University',
    web_page: 'http://www.slu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXe6KYDkEYZAANkbjFHa1hdgkOmmckqP6_fEmtpQlwDt6R6Wtmwmb8-A',
    id: 745,
    acronym: 'SLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smcks.edu',
    name: 'Saint Mary College',
    web_page: 'http://www.smcks.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgqDmKx3tABzfdWBURKqbgEODBGpaxcwhbmS1VuuKCOBvGE3e_23WrHDk',
    id: 746,
    acronym: 'SMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'saintmarys.edu',
    name: "Saint Mary's College (IN)",
    web_page: 'http://www.saintmarys.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgqDmKx3tABzfdWBURKqbgEODBGpaxcwhbmS1VuuKCOBvGE3e_23WrHDk',
    id: 747,
    acronym: 'SMC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stmarys-ca.edu',
    name: "Saint Mary's College of California",
    web_page: 'http://www.stmarys-ca.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKLzDklkebnNZL2Whj3IVw9Q2ClJUuk8vlOOhygHXPJWX6IcVwhSBN_g',
    id: 748,
    acronym: 'SMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smumn.edu',
    name: "Saint Mary's University of Minnesota",
    web_page: 'http://www.smumn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlHB7MMyGg4qKtbubQesawBgPCX85nGPc6TKtr7uDQYF3Q6Es5gkTkSg',
    id: 749,
    acronym: 'SMUM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smcvt.edu',
    name: "Saint Michael's College",
    web_page: 'http://www.smcvt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQQchyMvgvCVEAhgP-1Pf3KneHdveVZZ0QcBpwn88vTBM2TTuLlmqbpdA',
    id: 750,
    acronym: 'SMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stolaf.edu',
    name: 'Saint Olaf College',
    web_page: 'http://www.stolaf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZQTS8Af6aQMQvdMV1U4kqr5PGSeBdOUQ8m3KcLDJUddKMoO5ow8npZAk',
    id: 751,
    acronym: 'SOC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stvincent.edu',
    name: 'Saint Vincent College',
    web_page: 'http://www.stvincent.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLhvrhINh5lQJ7GdE7g1WWOurmXX3f2bi73r49v0SRVEOTUWraYNh6zDU',
    id: 752,
    acronym: 'SVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sxu.edu',
    name: 'Saint Xavier University',
    web_page: 'http://www.sxu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYVs2xQSaCLr3Ixs0xjS2PcHlMe725pInjoT0zSaTxDocwYS2ZoiD4xmUE',
    id: 753,
    acronym: 'SXU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'salisbury.edu',
    name: 'Salisbury University',
    web_page: 'http://www.salisbury.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD19TR9VfCfzfEP_a1EFAjPMMx_mlqPiPiwXOZ5NJH6BZw-fP1VUcbHI4',
    id: 754,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'skc.edu',
    name: 'Salish Kootenai College',
    web_page: 'http://www.skc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR391y0x4jJMj0YLsnGn2bjv5Awm-Rj5Kg6TN4rLKYnTGcirtS87quiKNII',
    id: 755,
    acronym: 'SKC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'samford.edu',
    name: 'Samford University',
    web_page: 'http://www.samford.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15272college.jpg',
    id: 756,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'shsu.edu',
    name: 'Sam Houston State University',
    web_page: 'http://www.shsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15271college.jpg',
    id: 757,
    acronym: 'SHSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sdsu.edu',
    name: 'San Diego State University',
    web_page: 'http://www.sdsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/San-Diego-State-University.svg',
    id: 758,
    acronym: 'SDSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sfsu.edu',
    name: 'San Francisco State University',
    web_page: 'http://www.sfsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQV17E04YWmFIqW-eDyZp-I5rWBvuLcvuXHz-2BOgWEBbYo00Lxl4ODAQ',
    id: 759,
    acronym: 'SFSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjsu.edu',
    name: 'San Jose State University',
    web_page: 'http://www.sjsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/San-Jose-State-University.svg',
    id: 760,
    acronym: 'SJSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'scu.edu',
    name: 'Santa Clara University',
    web_page: 'http://www.scu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI-q5IGM5vGtrJ1l4C8WVxIsKaf0ukSnarEw-4BwsSuWmlxO5wJn6YJUA',
    id: 761,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'slc.edu',
    name: 'Sarah Lawrence College',
    web_page: 'http://www.slc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAEdvTYnB90mtSK-0eWWZP676vOfdG_0dDTswV4dGBXR6A6dVVEEoP_A',
    id: 762,
    acronym: 'SLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'saic.edu',
    name: 'School of the Art Institute of Chicago',
    web_page: 'http://www.saic.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQphJqXNKQskjHD8zP76POkMkvGvKI-sldoYoiLWUXHGnT1tECcu9KwRA',
    id: 763,
    acronym: 'SAIC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'seattlecentral.edu',
    name: 'Seattle Central College',
    web_page: 'http://seattlecentral.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCIQV1djJr_sCNOUKwDMN5BwBLIeBP2IfcLfs01u8YQ_NrlHrDvMshDXkG',
    id: 764,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'spu.edu',
    name: 'Seattle Pacific University',
    web_page: 'http://www.spu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHnsgusElW5g1vkWtoWsl6MSe1RD1qFRW0Ty9IpRsdk8Qi6o8L8CVs4A',
    id: 765,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'seattleu.edu',
    name: 'Seattle University',
    web_page: 'http://www.seattleu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK59Yt-OaHB-rWtSfbZsXy00wNSMpJjw86nipTod_Q794OiFtuRkbuSog',
    id: 766,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'shu.edu',
    name: 'Seton Hall University',
    web_page: 'http://www.shu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwitsIWBD08Q66BiX0aHMy0FHIIhpj_Ylj7Yt_issEXj_HFB0abwiqUw',
    id: 767,
    acronym: 'SHU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sewanee.edu',
    name: 'Sewanee University of the South',
    web_page: 'http://www.sewanee.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeUK-M16TLc6lv_wgIJALsz65UGoPbjCEzL0ZpcU_nRxcncbbAhGK723E',
    id: 768,
    acronym: 'SUS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'shawnee.edu',
    name: 'Shawnee State University',
    web_page: 'http://www.shawnee.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ0DXqgWOKyvIdzocmEsBfaat03Tb5KN32EbDEB9ysDm9JG2SmkC6g6Z0',
    id: 769,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'su.edu',
    name: 'Shenandoah University',
    web_page: 'http://www.su.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1YLEagtJ6e7bzBFhe3UXluH-39bOmQOAZEHmeyw2j5kFHUTaxWGTQXJdxIg',
    id: 770,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ship.edu',
    name: 'Shippensburg University of Pennsylvania',
    web_page: 'http://www.ship.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAc4-S8u8UuOuQcSjaVXG5tIItq8YXqKg6849H0HTBtRhn8AOcbqJ8Nr4',
    id: 771,
    acronym: 'SUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'shorter.edu',
    name: 'Shorter College',
    web_page: 'http://www.shorter.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX72AHdjTr62pzt6R0quuxwr5jTtCauDOzGSq0Js5XqPJd4y27VGP7kmc',
    id: 772,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'simmons.edu',
    name: 'Simmons College',
    web_page: 'http://www.simmons.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToP8uFISRHtKjoKqmUMXza-jb8r-Fp3Hv6nC4skUiRnCh4KDwlqdu7GHI',
    id: 773,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'simons-rock.edu',
    name: "Simon's Rock College",
    web_page: 'http://www.simons-rock.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq1ZAjuYfYZMbIdRd7oM6Emx8rTXXw2ZzyU8XapbZrYdDgTxlFJxFgPA',
    id: 774,
    acronym: 'SRC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'simpson.edu',
    name: 'Simpson College',
    web_page: 'http://www.simpson.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGmg1efS_zoELN2QNhDKNIJwwW1JioS5Vje-h0_e23cuS3ubetdZy6SdQ',
    id: 775,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sinclair.edu',
    name: 'Sinclair Community College',
    web_page: 'http://www.sinclair.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC_4DadB2E_BN4BVtNJWtjpdFV_y1yFQMzodM5ZPLk7vgi3SCnB6ZkjGE',
    id: 776,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'skidmore.edu',
    name: 'Skidmore College',
    web_page: 'http://www.skidmore.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4Qe1lHH1qHcB4-9TR-ZA3tnKzfTbsh6tvC-FYwwsqQGjGA-6T-5V25Q',
    id: 777,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sru.edu',
    name: 'Slippery Rock University of Pennsylvania',
    web_page: 'http://www.sru.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXKhgxtrgrsgk2FfiDlGs9E3FNkYiQm29OcY8ipclQsN4B9E7m6-mRPGsv',
    id: 778,
    acronym: 'SRUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smith.edu',
    name: 'Smith College',
    web_page: 'http://www.smith.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7dWt_z5SC0xfN3IcCau5Yt6RL8LzhS6pw2Of08MwAMFaWIxzAO0GjBWE',
    id: 779,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sonoma.edu',
    name: 'Sonoma State University',
    web_page: 'http://www.sonoma.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ74fJ9-ZHZtOTLPfCLDtu8ajoipSIVYpRrG8nb8x44FEI8BJvVSmvxnQ',
    id: 780,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sdsmt.edu',
    name: 'South Dakota School of Mines and Technology',
    web_page: 'http://www.sdsmt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAF1skA3WXWgJsAlv6CR0P0O0UqrH0NqrtaK6k0tpVECJoS-rddz7YsW8',
    id: 781,
    acronym: 'SDSMT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sdstate.edu',
    name: 'South Dakota State University',
    web_page: 'http://www.sdstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15336college.jpg',
    id: 782,
    acronym: 'SDSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'selu.edu',
    name: 'Southeastern Louisiana University',
    web_page: 'http://www.selu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15353college.jpg',
    id: 783,
    acronym: 'SLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'semo.edu',
    name: 'Southeast Missouri State University',
    web_page: 'http://www.semo.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15347college.jpg',
    id: 784,
    acronym: 'SMSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southern.edu',
    name: 'Southern College',
    web_page: 'http://www.southern.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7xji3AZ1y6_5e34ktwH03sZaFA4wlC2rZ2HP6rO_yRN3pppPSUqm1lw',
    id: 785,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sct.edu',
    name: 'Southern College of Technology',
    web_page: 'http://www.sct.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUm_0ypZ8kexOGR1oGLi7CJGL8-qNaDQGT4RZRqXtDv_zx_9HLjmYyuQ',
    id: 786,
    acronym: 'SCT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southernct.edu',
    name: 'Southern Connecticut State University',
    web_page: 'http://www.southernct.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtkQ9U8UuXu2-HVZgIb0mMmcoBVaKYRT2H7-KO-s_BHe3F64XRt2C5JNQ',
    id: 787,
    acronym: 'SCSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'siu.edu',
    name: 'Southern Illinois University-Carbondale',
    web_page: 'http://www.siu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/southern-illinois-university-carbondale.png',
    id: 788,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'siue.edu',
    name: 'Southern Illinois University-Edwardsville',
    web_page: 'http://www.siue.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROmWswNzZMy8YbgYtYUPGgzMvPYE3BGjqW-QADtiGvB-kdCxTmMKaaCw',
    id: 789,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smu.edu',
    name: 'Southern Methodist University',
    web_page: 'http://www.smu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Southern-Methodist-University.svg',
    id: 790,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'snu.edu',
    name: 'Southern Nazarene University',
    web_page: 'http://www.snu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHwAkIWWtQILD-h5mqC-y_6rZb_6tx4yhLAWbBp9-2EIfXlftyI4CD-qlv',
    id: 791,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'snhu.edu',
    name: 'Southern New Hampshire University',
    web_page: 'http://www.snhu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqBeEHXS647tq4PpH-d6TiyVbteJkcPtBUA8bayrwEOWIDhWxbEd9goa0',
    id: 792,
    acronym: 'SNHU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sou.edu',
    name: 'Southern Oregon State College',
    web_page: 'http://www.sou.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNJvsqjh967WoWzotppQ2KHdQNXuKBg5KwEwKL2eo-uokO485ZNkfK2A',
    id: 793,
    acronym: 'SOSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'subr.edu',
    name: 'Southern University and A&M College',
    web_page: 'http://www.subr.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15368college.jpg',
    id: 794,
    acronym: 'SUAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'suu.edu',
    name: 'Southern Utah University',
    web_page: 'http://www.suu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/southern-utah-university.jpeg',
    id: 795,
    acronym: 'SUU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stcl.edu',
    name: 'South Texas College of Law',
    web_page: 'http://www.stcl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7tMQq5MvfVrBStiua-DuniDE2F09L_D4I73_DB-deQ8NRSDcvHtSzUfU',
    id: 796,
    acronym: 'STCL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sbuniv.edu',
    name: 'Southwest Baptist University',
    web_page: 'http://www.sbuniv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiKmQE82cOEgBu4cojoKWLmpKe_mVGgtU6ACuoSg-QEHVVNiJtoA8B6nE',
    id: 797,
    acronym: 'SBU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swac.edu',
    name: 'Southwestern Adventist College',
    web_page: 'http://www.swac.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvbcULcbixpLut6CtxxBAQaomqH1rrTyv-g_nd-ew2bEPMa_zAFx5o2Q8',
    id: 798,
    acronym: 'SAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sagu.edu',
    name: 'Southwestern Assemblies of God University',
    web_page: 'http://www.sagu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD4fAXG5EqgeguxIx2x9UOVMiSodFxoFwKaYGNYm-ZvsAMxKjA4thKB9KCmQ',
    id: 799,
    acronym: 'SAGU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sckans.edu',
    name: 'Southwestern College',
    web_page: 'http://www.sckans.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC65jPfL2a4e4MAsiOo0GwCHTnIghke_I9AddgWyikWnzWLqGGyguxp50',
    id: 800,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southwestern.edu',
    name: 'Southwestern University',
    web_page: 'http://www.southwestern.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYLNd88AY88xdng6tcl0YWZzef4ZAX97NtEeucQCIJkwNA2JNv1tnkiQ',
    id: 801,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smsu.edu',
    name: 'Southwest Missouri State University',
    web_page: 'http://www.smsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVGjnsi4TDRRnpQhPoMl8gFCzeLpFS_x1oLHsbqzaDjbNwg4lzroxFv1U',
    id: 802,
    acronym: 'SMSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swt.edu',
    name: 'Southwest Texas State University',
    web_page: 'http://www.swt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAA3W5yyhACZNxxQeNCzZ0bSSx2XpMfxWJmMYw99v_cbQVsUpRESkO_dOpGw',
    id: 803,
    acronym: 'STSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'auc.edu',
    name: 'Spelman College',
    web_page: 'http://www.auc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjurX9hnlmiG7VCe8_bd2Xo_SumkooA8yxhaWBr5HVyzMjvnRxN3IyymM',
    id: 804,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'arbor.edu',
    name: 'Spring Arbor College',
    web_page: 'http://www.arbor.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXNVg-lq795dMCSctptZPkN-g2w7NB4PnB_tYsLnVbB7325697xvC2_Fg',
    id: 805,
    acronym: 'SAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'shc.edu',
    name: 'Spring Hill College',
    web_page: 'http://www.shc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHTSDSj80s9gIDQ_DalQs7X3Cnicfo8mG97gU8vGKQ9sVZTiL7EFrx3y8',
    id: 806,
    acronym: 'SHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sau.edu',
    name: 'St. Ambrose University',
    web_page: 'http://www.sau.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiuAxzhpUu6dAJiFwgQRoCKnnnGj1Wk5wm14enX4bWgFHdYBx5zEpcFg',
    id: 807,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sapc.edu',
    name: 'St. Andrews Presbyterian College',
    web_page: 'http://www.sapc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoSwVPz0u-DPRBkyc4PflkVsYxinmuqCzhwW1v4BuQmsPjJ3DdieJvFb4',
    id: 808,
    acronym: 'SAPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stanford.edu',
    name: 'Stanford University',
    web_page: 'http://www.stanford.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Stanford-University.svg',
    id: 809,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'albany.edu',
    name: 'State University of New York at Albany',
    web_page: 'http://www.albany.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15463college.jpg',
    id: 810,
    acronym: 'SUNYAA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'binghamton.edu',
    name: 'State University of New York at Binghamton',
    web_page: 'http://www.binghamton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_VCUId9vyh3xAuYdeEJXCGSX7Tu1Z6E_mGmoSLcpUDnEYqcSGuQcWog',
    id: 811,
    acronym: 'SUNYAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'buffalo.edu',
    name: 'State University of New York at Buffalo',
    web_page: 'http://www.buffalo.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/State-University-of-New-York-at-Buffalo.svg',
    id: 812,
    acronym: 'SUNYAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'farmingdale.edu',
    name: 'State University of New York at Farmingdale',
    web_page: 'http://www.farmingdale.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtFQW6bXJ69nYZ6t03ffgPpkw97rIJVGaFaoGY3T2LaqNtkqcqbK0n-Q',
    id: 813,
    acronym: 'SUNYAF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fredonia.edu',
    name: 'State University of New York at Fredonia',
    web_page: 'http://home.fredonia.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPvDaHjDsxw0VQnzbz_VlFRhxJfVcedeTyF9qidZkCb4b4OGzVuiNETdo',
    id: 814,
    acronym: 'SUNYAF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oswego.edu',
    name: 'State University of New York at Oswego',
    web_page: 'http://www.oswego.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROcE4pKcp7wOMLtgsn3ut_7K-Dpf7pYMJD8J-ymOSYq66nAPRR1-Pd7AdZ',
    id: 815,
    acronym: 'SUNYAO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'plattsburgh.edu',
    name: 'State University of New York at Plattsburgh',
    web_page: 'http://www.plattsburgh.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmESYLOmCndwA-iFK6TS7bD9v2OrbMP26Qdo0HHKoFve0-8hq4-Mg_v7M',
    id: 816,
    acronym: 'SUNYAP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stonybrook.edu',
    name: 'State University of New York at Stony Brook',
    web_page: 'http://www.stonybrook.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/stony-brook-university.png',
    id: 817,
    acronym: 'SUNYASB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stonybrookmedicine.edu',
    name: 'Academic medical center at State University of New York at Stony Brook',
    web_page: 'http://www.stonybrookmedicine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPYA-HPcvyJHx6vHJsAAzAT1JPFcQqhxtwnHrzpkgFnML39la0zKmV7g',
    id: 818,
    acronym: 'AMCASUNYASB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'brockport.edu',
    name: 'State University of New York College at Brockport',
    web_page: 'http://www.brockport.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH-kshgwMWlQ1zCqVJjFIInNQYphAYRhZlqR5_nSuniZgKt_2vd2KWFg',
    id: 819,
    acronym: 'SUNYCAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'buffalostate.edu',
    name: 'State University of New York College at Buffalo',
    web_page: 'http://suny.buffalostate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15478college.jpg',
    id: 820,
    acronym: 'SUNYCAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cortland.edu',
    name: 'State University of New York College at Cortland',
    web_page: 'http://www.cortland.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCz30NVXgCoqfEtI5llqpvURqnPwllE-s7L89JJy2miB8Jh7NFIE_rSNc',
    id: 821,
    acronym: 'SUNYCAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'geneseo.edu',
    name: 'State University of New York College at Geneseo',
    web_page: 'http://www.geneseo.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiTeKG0WqfSxTl1LwxuJbw0VqzBHlmCdSP1-qjS-zstPGmPJTZYT1s9A',
    id: 822,
    acronym: 'SUNYCAG',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'newpaltz.edu',
    name: 'State University of New York College at New Paltz',
    web_page: 'http://www.newpaltz.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTZ1762LMofpOVmAD4qPaI7JkOUSDREWxDASiBTErphJ7IsQUr1Hfa4q8',
    id: 823,
    acronym: 'SUNYCANP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'potsdam.edu',
    name: 'State University of New York College at Potsdam',
    web_page: 'http://www.potsdam.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ4HnAAlA75FfGcCUuQtxMXEbYG1aQi9tE1QvOrJTrpeP92lYUux5u8TJ0',
    id: 824,
    acronym: 'SUNYCAP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cobleskill.edu',
    name: 'State University of New York College of Agriculture and Technology at Cobleskill',
    web_page: 'http://www.cobleskill.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP8NPqsLWJjnJ0gbRQm9z3mdORFFtRUtdBz-Op3bGk-45WNXmyTtsQWA',
    id: 825,
    acronym: 'SUNYCATAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'esf.edu',
    name: 'State University of New York College of Environmental Science and Forestry',
    web_page: 'http://www.esf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIpUhHj36y8akqKEnwgC87nV-zYwrdr0kCW-hab5BTeMlwnZAwIV1PBA',
    id: 826,
    acronym: 'SUNYCESF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alfredtech.edu',
    name: 'State University of New York College of Technology at Alfred',
    web_page: 'http://www.alfredtech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9VsSlYHUq_d9C_6jMA7esJEer8g4qFwJBjq5Cg96HeqoBqCvgllcSQw',
    id: 827,
    acronym: 'SUNYCTAA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunyit.edu',
    name: 'State University of New York Polytechnic Institute',
    web_page: 'http://www.sunypoly.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU7vAJxTQuArRZbo6maKn6UcZtVC0M2S1GkIYR9l9aB-p34AUrxIAsyA',
    id: 828,
    acronym: 'SUNYPI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunycentral.edu',
    name: 'State University of New York System',
    web_page: 'http://www.sunycentral.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9anMhBiXdtdECehi6AxmY8PhWcwS8DwqI7sUJtYfy2okOFub8jXPPzw',
    id: 829,
    acronym: 'SUNYS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sbu.edu',
    name: 'St. Bonaventure University',
    web_page: 'http://www.sbu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRREk3xOTRAho6UTp41MuOOb5kPozELNUb5GnZ-hwQmyV8FFiDaLKe0ag',
    id: 830,
    acronym: 'SBU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sfasu.edu',
    name: 'Stephen F. Austin State University',
    web_page: 'http://www.sfasu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15452college.jpg',
    id: 831,
    acronym: 'SFASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stephens.edu',
    name: 'Stephens College',
    web_page: 'http://www.stephens.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL5gNRqj92oPMrcRvgxQFDH2ma2n_o4kgZDzknbX4lgRc2chp4mlsZAw',
    id: 832,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stetson.edu',
    name: 'Stetson University',
    web_page: 'http://www.stetson.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15454college.jpg',
    id: 833,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stevens.edu',
    name: 'Stevens Institute of Technology',
    web_page: 'http://www.stevens.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGNuRWLsdCnkLdBgGoKk4hBXNgfc1E6xHPsVkSapKUd_atsBv8cQVu_ag',
    id: 834,
    acronym: 'SIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjca.edu',
    name: "St. John's College - Annapolis",
    web_page: 'http://www.sjca.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjvuNm3aQUq0Vnr9JC6jtOdCSpxTMU-Kxl-xSbmYO4ysWCuYpqFf8tmA',
    id: 836,
    acronym: 'SJC-A',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjcsf.edu',
    name: "St. John's College - Santa Fe",
    web_page: 'http://www.sjcsf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEzsM4bqtxQVq5xQPrVIJHw6PQ2HLnY6fT5WXGIN7ZOSqYgwJxOCv6Mwg',
    id: 837,
    acronym: 'SJC-SF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjc.edu',
    name: 'St. Joseph College (CT)',
    web_page: 'http://www.sjc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZLMkS5kVusi0x6TdNxK0rTB32BoXl0GQ1Dyce34pzgB1e3X0E_kPYXA',
    id: 838,
    acronym: 'SJC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjcme.edu',
    name: "St. Joseph's College (ME)",
    web_page: 'http://www.sjcme.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSMpZpjRVVPO8PtV8IN36i4wO1xdfan51P-grsCSeMQ74Uc-OC9K1NpQ',
    id: 839,
    acronym: 'SJC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stlawu.edu',
    name: 'St. Lawrence University',
    web_page: 'http://www.stlawu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp8zpYh5KWsO7PehUBki1V6VaSFwnpzWYTeXKzirT7qx2sTuQssILtBw',
    id: 840,
    acronym: 'SLU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stlcop.edu',
    name: 'St. Louis College of Pharmacy',
    web_page: 'http://www.stlcop.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzM4bAyRTrCVgZErcIH4q8-hrw8FdDZkQ_SVM-eLq21bR5wgDqiXSPEF8',
    id: 841,
    acronym: 'SLCP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stmartin.edu',
    name: "St. Martin's College",
    web_page: 'http://www.stmartin.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOLL3lDdZuvpTvVwn3Mo-15ppwvHbWAeaEWFUI2GKo004sfK5nUtFF6n6U',
    id: 842,
    acronym: 'SMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smcm.edu',
    name: "St. Mary's College of Maryland",
    web_page: 'http://www.smcm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2NWKToY98vvY0M4GREZFUadj5ydo5c_Y20a3bU2De6RjhTMjb7fd-Iva6',
    id: 843,
    acronym: 'SMCM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'strayer.edu',
    name: 'Strayer College',
    web_page: 'http://www.strayer.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa9UEJqTnb1WzAWL9_nHkkxYPPCjIlxhIccF47iZ9YgbfCuPRRnTyut_I',
    id: 844,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stu.edu',
    name: 'St. Thomas University (FL)',
    web_page: 'http://www.stu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-PnufcbgJ0TF194vJ8WSm3f7DCt5Qwbz-HJYDhlL69Av2DjSaPHuySEdwkA',
    id: 845,
    acronym: 'STU(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'suffolk.edu',
    name: 'Suffolk University',
    web_page: 'http://www.suffolk.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxeW-hpvsCGKUz1VGxB2TypwWsaayXPAkoTHMSWqmsnbYzov3QfKNH79U',
    id: 846,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sulross.edu',
    name: 'Sul Ross State University',
    web_page: 'http://www.sulross.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4qZlY_MKcQ8nwsFjPAeHisjWc0WwCMvaZbkO2n6hqOQiUuybBgKN7rA',
    id: 847,
    acronym: 'SRSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'summitunivofla.edu',
    name: 'Summit University of Louisiana',
    web_page: 'http://www.summitunivofla.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR44N-PLrr5Gj2_UgIgXlh1jrsXRm9IMZkX_2QQY92of6pJUhHCA1b6XDo',
    id: 848,
    acronym: 'SUL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'susqu.edu',
    name: 'Susquehanna University',
    web_page: 'http://www.susqu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4RFQwA2jcdAF6P5hqwfa-VdJJOKbmFWyICCZSRVfQPo1VEjrJgu2ztrI',
    id: 849,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swarthmore.edu',
    name: 'Swarthmore College',
    web_page: 'http://www.swarthmore.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJADJzsZktWGBT_sC_51H53NviZRBdwfdrt_2zFf8fsBYDuSNFQL6z2W6r',
    id: 850,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sbc.edu',
    name: 'Sweet Briar College',
    web_page: 'http://www.sbc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0sLgKlZiSkyymb6nWl-L3f_UJvam8YWX3cmWh55YVuNQ262mBrKgbSNU',
    id: 851,
    acronym: 'SBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'syr.edu',
    name: 'Syracuse University',
    web_page: 'http://www.syr.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Syracuse-University.svg',
    id: 852,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tabor.edu',
    name: 'Tabor College',
    web_page: 'http://www.tabor.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmhaa0HwxXvPLzYQHYNO_vtqo_RafXWOHf22eIe4txF14pXE7ka7oCP6Q',
    id: 853,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tarleton.edu',
    name: 'Tarleton State University',
    web_page: 'http://www.tarleton.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15498college.jpg',
    id: 854,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tayloru.edu',
    name: 'Taylor University',
    web_page: 'http://www.tayloru.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE5BoPkuR37PpBW9q6xXZLIREgoMG_v47K0IVX9ss2Oezqpmt4Dd3TLQ',
    id: 855,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'temple.edu',
    name: 'Temple University',
    web_page: 'http://www.temple.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Temple-University.svg',
    id: 856,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tnstate.edu',
    name: 'Tennessee State University',
    web_page: 'http://www.tnstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/tennessee-state-university.jpeg',
    id: 857,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tntech.edu',
    name: 'Tennessee Technological University',
    web_page: 'http://www.tntech.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15506college.jpg',
    id: 858,
    acronym: 'TTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tccd.edu',
    name: 'Terrant County College',
    web_page: 'http://www.tccd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP4i_x5UOYKcabbb6OYGs8-AVyyKu2xcnmZO6AveHIe26Jbl7j7HJ5DQ',
    id: 859,
    acronym: 'TCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tamiu.edu',
    name: 'Texas A&M International University',
    web_page: 'http://www.tamiu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1UlKOVse_TjQT3iUb6i6JHE40ckx6Wu8WPzzlZoc4FpI1Uw916ykPEw',
    id: 860,
    acronym: 'TAIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tamu.edu',
    name: 'Texas A&M University - College Station',
    web_page: 'http://www.tamu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Texas-A%26M-University-College-Station.svg',
    id: 861,
    acronym: 'TAU-CS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tamuc.edu',
    name: 'Texas A&M University - Commerce',
    web_page: 'http://www.tamuc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFshzNZb9IvA6sGbIyK7eju-cdbL5EWFvaWZKkklO4dTi9VL6favF44Jg',
    id: 862,
    acronym: 'TAU-C',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tamucc.edu',
    name: 'Texas A&M University - Corpus Christi',
    web_page: 'http://www.tamucc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnwUFTcUwTD16tdIBK1mbvUn-L6DCU-dvu7cohmFDxraJp3sx5bi9b7kI',
    id: 863,
    acronym: 'TAU-CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tamuk.edu',
    name: 'Texas A&M University - Kingsville',
    web_page: 'http://www.tamuk.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq8KKj1lgnWHDXdmCJs7CxC-pn-qv6_aOrMgSWTSMFvzyDvTtdpYFsza4',
    id: 864,
    acronym: 'TAU-K',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tcu.edu',
    name: 'Texas Christian University',
    web_page: 'http://www.tcu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Texas-Christian-University.svg',
    id: 865,
    acronym: 'TCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tsu.edu',
    name: 'Texas Southern University',
    web_page: 'http://www.tsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15519college.jpg',
    id: 866,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'txstate.edu',
    name: 'Texas State University',
    web_page: 'http://www.txstate.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Texas-State-University.svg',
    id: 867,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ttu.edu',
    name: 'Texas Tech University',
    web_page: 'http://www.ttu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Texas-Tech-University.svg',
    id: 868,
    acronym: 'TTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ttuhsc.edu',
    name: 'Texas Tech University-Health Sciences Center',
    web_page: 'http://www.ttuhsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF7aOce8YtyH_rSRRqi3xWtkdkFoSC4giu_nxZITUkp0JSHKU7QebrW28u',
    id: 869,
    acronym: 'TTUSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'twu.edu',
    name: "Texas Woman's University",
    web_page: 'http://www.twu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRfeHCF9HiiUsFSLtyaa7SNuOan55QiFfrJ4fKTikEscNrTTsaQ8_gcw',
    id: 870,
    acronym: 'TWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'citadel.edu',
    name: 'The Citadel',
    web_page: 'http://www.citadel.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15524college.jpg',
    id: 871,
    acronym: 'C',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jtsa.edu',
    name: 'The Jewish Theological Seminary',
    web_page: 'http://www.jtsa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ2R1lsOutOURJkmzsOwvH4AdvhLLTfztcR_-VwWHp06H4dpc3ERL31Q',
    id: 872,
    acronym: 'JTS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'thomas.edu',
    name: 'Thomas College',
    web_page: 'http://www.thomas.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0DPyD8kF764623yDgkIon2K2h6YGkIX_zR4aahDIQh4UtbS9NywIPkw',
    id: 873,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tesc.edu',
    name: 'Thomas Edison State College',
    web_page: 'http://www.tesc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjrltBIHy42YT3z8NV5ZgQ_6YvkHDnfZ1rnb19fzKZKmTZnkns54fbwtQ',
    id: 874,
    acronym: 'TESC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tju.edu',
    name: 'Thomas Jefferson University',
    web_page: 'http://www.tju.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpiWAOq80PEtc3M1BJh_eP-NtSkLFyMNj4Mq9ztvhMtx_UWYxGigDVlw',
    id: 875,
    acronym: 'TJU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'thomasmore.edu',
    name: 'Thomas More College',
    web_page: 'http://www.thomasmore.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-TFTRnyY5fB1Paig5Sun1ffyV5-4bTTzfmL0Xb1BQxIyOWu9HSbQ97g',
    id: 876,
    acronym: 'TMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'thunderbird.edu',
    name: 'Thunderbird School of Global Management',
    web_page: 'http://www.thunderbird.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmC9sN-iT9vlctTT3VJBPUKLhesrtZ5Msv9qBPZF2L0mG3yNOSgqS3jMg',
    id: 877,
    acronym: 'TSGM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'towson.edu',
    name: 'Towson State University',
    web_page: 'http://www.towson.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15537college.jpg',
    id: 878,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'transy.edu',
    name: 'Transylvania University',
    web_page: 'http://www.transy.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqYEGemIDnmVud_5ZCUUqpZzVJe8gof3awawfS5SDarzgkC4Yc-iEl3eU',
    id: 879,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'trenton.edu',
    name: 'Trenton State College',
    web_page: 'http://www.trenton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRSji4hmJpfZZ2FUVNOgb_vvpehyEABCdHczuH5ny2NJF4rs7hCkYvBw',
    id: 880,
    acronym: 'TSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'trine.edu',
    name: 'Trine University',
    web_page: 'http://www.trine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjbESSYCpmVob-m9vCKXzY_ozKu8nVnSbA3APNjvNnc4OkM4h4Z2qVrw',
    id: 881,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'trincoll.edu',
    name: 'Trinity College (CT)',
    web_page: 'http://www.trincoll.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHUHH_sXnqpriPDzQwNrAMll06N2UmtW8Em854P50NmhKYF8YE6-pnhg',
    id: 882,
    acronym: 'TC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'trinitydc.edu',
    name: 'Trinity College (DC)',
    web_page: 'http://www.trinitydc.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTnYQuVt-6MvptfjiEXreg3QSKSUJt24TCU-jeQgkalQiDtJ-k2Zmtho6Af',
    id: 883,
    acronym: 'TC(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'trinity.edu',
    name: 'Trinity University',
    web_page: 'http://www.trinity.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRc4MXKLOznpq02zyENDebvC6OkY8lGIkxP6wzLf7T9S72H67gP3vmVaU',
    id: 884,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'troy.edu',
    name: 'Troy University',
    web_page: 'https://www.troy.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Troy-University.svg',
    id: 885,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'truman.edu',
    name: 'Truman State University',
    web_page: 'http://www.truman.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBTs2E1ALQUmkCXXyHrUZcF87fZb2Z-DJi-kSay66UPqpA6DsY3QtcAQ',
    id: 886,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tucsonu.edu',
    name: 'Tucson University',
    web_page: 'http://www.tucsonu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxUZNmeoSonVM3WEff2q9x7ZeWHOoDzqzIaIkkqs0kMXnmwwFt1HUa8oVF',
    id: 887,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tufts.edu',
    name: 'Tufts University',
    web_page: 'http://www.tufts.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkPsI_7IoggrUF-C_SM4-3j9dwNZDy8dMCFkIWDyOu5TApB6NTBOQ8KzjJ',
    id: 888,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tulane.edu',
    name: 'Tulane University',
    web_page: 'http://www.tulane.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Tulane-University.svg',
    id: 889,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tuskegee.edu',
    name: 'Tuskegee University',
    web_page: 'http://www.tuskegee.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS01lGpmB7TNURNx3MD4rNPNMc_ZusVRrc8z0rsyEkhrK2_h4b4RyGnrvP2Ng',
    id: 890,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usuhs.mil',
    name: 'Uniformed Services University of the Health Sciences',
    web_page: 'http://www.usuhs.mil/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQgCHj64tE-TOxplQtYeaLfp9FaBSbEqXUYbjyT-clG4S0StKcItH1lyU',
    id: 891,
    acronym: 'USUHS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'union.edu',
    name: 'Union College',
    web_page: 'http://www.union.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSv-884N3OOM936udmyh4rnwQ9aGhOzsGayaPdEcfkMnu6tXSBMdB_Ccg',
    id: 892,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tui.edu',
    name: 'Union Institute',
    web_page: 'http://www.tui.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJKuh34upyrr9W-nWYafxZGh8S_aP8nSJUrYP3HKDi-jFSjLFTKadIPg',
    id: 893,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uu.edu',
    name: 'Union University',
    web_page: 'http://www.uu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3LgIavEG3LbJFC2czBvYua5-uyoFbJCTgNsxRfji2Zra47080MybUTQ',
    id: 894,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usafa.af.mil',
    name: 'United States Air Force Academy',
    web_page: 'http://www.usafa.af.mil/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/United-States-Air-Force-Academy.svg',
    id: 895,
    acronym: 'USAFA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usiu.edu',
    name: 'United States International University',
    web_page: 'http://www.usiu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHQJR5Ep3XIJzZyuEIe3d2QXF-NJ8D6mv7DrKyRlg4pSi3YfhAE6tJpUyn',
    id: 897,
    acronym: 'USIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usmma.edu',
    name: 'United States Merchant Marine Academy',
    web_page: 'http://www.usmma.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIh9x6POysU0mmAVMPJICgjL58tkE9OU8GvbWBquuiIibLEIBSwjz_4g',
    id: 898,
    acronym: 'USMMA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usma.edu',
    name: 'United States Military Academy',
    web_page: 'http://www.usma.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/United-States-Military-Academy.svg',
    id: 899,
    acronym: 'USMA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usna.edu',
    name: 'United States Naval Academy',
    web_page: 'http://www.usna.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/United-States-Naval-Academy.svg',
    id: 900,
    acronym: 'USNA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uat.edu',
    name: 'University of Advancing Technology',
    web_page: 'http://www.uat.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4pxbl_5zUjd0tPHlwzZQ26lEK0DKikQfo7TkGx77rZg3FMwlzQtZ2xg',
    id: 901,
    acronym: 'UAT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uakron.edu',
    name: 'University of Akron',
    web_page: 'http://www.uakron.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Akron.svg',
    id: 902,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uab.edu',
    name: 'University of Alabama at Birmingham',
    web_page: 'http://www.uab.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Alabama-at-Birmingham.svg',
    id: 903,
    acronym: 'UAAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uah.edu',
    name: 'University of Alabama at Huntsville',
    web_page: 'http://www.uah.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSwC-oE7_cLv0d3nrtwKCg_csf_HFYIwMmUnDgTOWC18VSvcwDGbNtl90',
    id: 904,
    acronym: 'UAAH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ua.edu',
    name: 'University of Alabama at Tuscaloosa',
    web_page: 'http://www.ua.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Alabama-at-Tuscaloosa.svg',
    id: 905,
    acronym: 'UAAT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alaska.edu',
    name: 'University of Alaska',
    web_page: 'http://www.alaska.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCgx1lWYrWiXMFR6Ak7CS_1CjoaUP_3F1UDA6LEMRFm8gtqNZfyKoUBA',
    id: 906,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uaa.alaska.edu',
    name: 'University of Alaska - Anchorage',
    web_page: 'http://www.uaa.alaska.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_Veehxh2nbV9GAmGpXfaolP_47Mu60an76mfwC9cJETqKFIfBmeYmWbE',
    id: 907,
    acronym: 'UA-A',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uaf.edu',
    name: 'University of Alaska - Fairbanks',
    web_page: 'http://www.uaf.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQs4z3m87JdJEqcGHbLl6C29OQh_ydf5AvBIZTGrNrBTB3gjFD8QlBBlwo',
    id: 908,
    acronym: 'UA-F',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uas.alaska.edu',
    name: 'University of Alaska - Southeast',
    web_page: 'http://www.uas.alaska.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa8nQA-6xPGX6xLcmtMssXEHEv5AcMjuZiN-9l1NMO4cedTQlQ3rjKp7UJ',
    id: 909,
    acronym: 'UA-S',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'arizona.edu',
    name: 'University of Arizona',
    web_page: 'http://www.arizona.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Arizona.svg',
    id: 910,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uark.edu',
    name: 'University of Arkansas - Fayetteville',
    web_page: 'http://www.uark.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Arkansas-Fayetteville.svg',
    id: 911,
    acronym: 'UA-F',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uams.edu',
    name: 'University of Arkansas for Medical Sciences',
    web_page: 'http://www.uams.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDm9ESA4xWf8rz2Me9wkCSEY63aSsj_B3JqGJMyH8QJ_Ek2g3ZrodBbg',
    id: 912,
    acronym: 'UAFMS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ualr.edu',
    name: 'University of Arkansas - Little Rock',
    web_page: 'http://www.ualr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp3AOiSqo5-dF3NVcwiXbjRPS8-TU0Sr9Rq7rj11SNy6RdOM-C8vqhvA',
    id: 913,
    acronym: 'UA-LR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uamont.edu',
    name: 'University of Arkansas - Monticello',
    web_page: 'http://www.uamont.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS48UX2Xd9RHpam8JGkdXqVoi8A4pt7y36yE4RUZl8263gVhxD-Btq7xw',
    id: 914,
    acronym: 'UA-M',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ubalt.edu',
    name: 'University of Baltimore',
    web_page: 'http://www.ubalt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd_VnlNuYRugjLQ0rjUZsCRNb5KAxDGQ663UwZy165Fuj_pp4XSc_NZgM',
    id: 915,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bridgeport.edu',
    name: 'University of Bridgeport',
    web_page: 'http://www.bridgeport.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1bwt9frET9g5UCXAlwE0w_diJQgsH9blQS3T4g7W8ztxUA31Iwlv2Ng',
    id: 916,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'berkeley.edu',
    name: 'University of California Berkeley',
    web_page: 'http://www.berkeley.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-California-Berkeley.svg',
    id: 917,
    acronym: 'UCB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucdavis.edu',
    name: 'University of California Davis',
    web_page: 'http://www.ucdavis.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15767college.jpg',
    id: 918,
    acronym: 'UCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uci.edu',
    name: 'University of California Irvine',
    web_page: 'http://www.uci.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjJvYnWrsUTQnDj_zSV5_IS5tsVOmVnxo4GMYefeuZcMINuPsaQtQX1pI',
    id: 919,
    acronym: 'UCI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucla.edu',
    name: 'University of California Los Angeles',
    web_page: 'http://www.ucla.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-California-Los-Angeles.svg',
    id: 920,
    acronym: 'UCLA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucmerced.edu',
    name: 'University of California Merced',
    web_page: 'http://www.ucmerced.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUkmE3MeFQk8lyz0HRhXNXk-L7VQ4CW3x2TOXn_n6xrPqjoQIoVqK9-Q',
    id: 921,
    acronym: 'UCM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucr.edu',
    name: 'University of California Riverside',
    web_page: 'http://www.ucr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwgemdKIPTkOgvJqf10kCko6KQ4VbFdzBBxEffqmhgijiP7Gk5mfokahU',
    id: 922,
    acronym: 'UCR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucsd.edu',
    name: 'University of California San Diego',
    web_page: 'http://www.ucsd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtHuD5IVfmQMIRo_MQ9PQv4irBPXFotsEsQxK_tEUtz28EQ97XYTHYHxU',
    id: 923,
    acronym: 'UCSD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucsf.edu',
    name: 'University of California San Francisco',
    web_page: 'http://www.ucsf.edu/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcS22LBfrN1h1DZqi8JFYXh8ppE-veNTTc-lUYvi7TuhxptrzYKc4pWwZ2U',
    id: 924,
    acronym: 'UCSF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucsb.edu',
    name: 'University of California Santa Barbara',
    web_page: 'http://www.ucsb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpHQCd9S7QEx7UECA_yD33FsSYLRJSZBl4_4okQSF7UJI2KBVuWhs42A',
    id: 925,
    acronym: 'UCSB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucsc.edu',
    name: 'University of California Santa Cruz',
    web_page: 'http://www.ucsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT21oFYn-92gBJDDIegH9gsEruRlYzUr-p6VqmwIG63fEZmb5zydlnJicE',
    id: 926,
    acronym: 'UCSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uca.edu',
    name: 'University of Central Arkansas',
    web_page: 'http://www.uca.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15600college.jpg',
    id: 927,
    acronym: 'UCA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucf.edu',
    name: 'University of Central Florida',
    web_page: 'http://www.ucf.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Central-Florida.svg',
    id: 928,
    acronym: 'UCF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cmsu.edu',
    name: 'University of Central Missouri',
    web_page: 'http://www.ucmo.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo9dQ4C_BoDT9x0cyjD99dJvvzAlQoldJeQzjmLpZG4DZQ48ZP2NJnBg',
    id: 929,
    acronym: 'UCM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uchaswv.edu',
    name: 'University of Charleston',
    web_page: 'http://www.uchaswv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlj0X--NHsCQ-GZIM-b3Oc6NP7GE0fi_xxD3yPmtcwLP0ZwXFn_hNN_V0',
    id: 930,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uchicago.edu',
    name: 'University of Chicago',
    web_page: 'http://www.uchicago.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnpVaJ7QDxEPPpO0OI8PqnPBORNdGJU-5WKzlT2pa9KIXU8BliI36ewg',
    id: 931,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uc.edu',
    name: 'University of Cincinnati',
    web_page: 'http://www.uc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Cincinnati.svg',
    id: 932,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'colorado.edu',
    name: 'University of Colorado at Boulder',
    web_page: 'http://www.colorado.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Colorado-at-Boulder.svg',
    id: 933,
    acronym: 'UCAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uccs.edu',
    name: 'University of Colorado at Colorado Springs',
    web_page: 'http://www.uccs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQajXannB0tgEi2OS62JKzcQow7dk4NQx6V_xSYC-G4fda4McGGf7hn-A',
    id: 934,
    acronym: 'UCACS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cudenver.edu',
    name: 'University of Colorado at Denver',
    web_page: 'http://www.cudenver.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwGkc05sR9Lh0LoMECnrDQeXBnIiEi_7cnSIUhepN6SQCFgaSVAYyODAo',
    id: 935,
    acronym: 'UCAD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hsc.colorado.edu',
    name: 'University of Colorado Health Sciences Center',
    web_page: 'http://www.hsc.colorado.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwGkc05sR9Lh0LoMECnrDQeXBnIiEi_7cnSIUhepN6SQCFgaSVAYyODAo',
    id: 936,
    acronym: 'UCHSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uconn.edu',
    name: 'University of Connecticut',
    web_page: 'http://www.uconn.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Connecticut.svg',
    id: 937,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'udallas.edu',
    name: 'University of Dallas',
    web_page: 'http://www.udallas.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkiEsyVQYcV10jNQ3YLDhyDVViZRcSDeHaVAOCwbCOMnnDtTcIRQ0dL_I',
    id: 938,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'udayton.edu',
    name: 'University of Dayton',
    web_page: 'http://www.udayton.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15610college.jpg',
    id: 939,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'udel.edu',
    name: 'University of Delaware',
    web_page: 'http://www.udel.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15611college.jpg',
    id: 940,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'du.edu',
    name: 'University of Denver',
    web_page: 'http://www.du.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzlCOKF0Os8pSCDLVTEjoC9vC2O7mpPUmblISSSXDDeM__IYCB8dQbY_k',
    id: 941,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'udmercy.edu',
    name: 'University of Detroit Mercy',
    web_page: 'http://www.udmercy.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQpRvtkROgIMZuxGUNQtIu05VIzvksdsyqa3q4gTV6JU54o_rut9B1Uw',
    id: 942,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dbq.edu',
    name: 'University of Dubuque',
    web_page: 'http://www.dbq.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVNOnlKKzu2Ny4PuP4ss_aMKKZ-l0i_QsVR6tO1aTpNFuvfuUtMSDFhfk',
    id: 943,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'evansville.edu',
    name: 'University of Evansville',
    web_page: 'http://www.evansville.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzMSMTUJNrn9ES5TQcoweGPLLoxOKyaCX3FPN2qK6b3biwafteEvDkzQ',
    id: 944,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ufl.edu',
    name: 'University of Florida',
    web_page: 'http://www.ufl.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Florida.svg',
    id: 945,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uga.edu',
    name: 'University of Georgia',
    web_page: 'http://www.uga.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Georgia.svg',
    id: 946,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ugf.edu',
    name: 'University of Great Falls',
    web_page: 'http://www.ugf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAAlQaEGOsArgXSHGbU7t1y6ln3Z0qKGQh7Ox11_lt7t6L1lQffoL87Aox',
    id: 947,
    acronym: 'UGF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uog.edu',
    name: 'University of Guam',
    web_page: 'http://www.uog.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdy9K0EhjaiwXrpG0qbvZAvbSpwR6ZRSCeR8ZJ5sqqsUN6rYRZigBQtQ',
    id: 948,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hartford.edu',
    name: 'University of Hartford',
    web_page: 'http://www.hartford.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNCWcK-_zd3jgzCPNk5nil4wvxaqaUeebYtok8brlVGvCBn9ferK73ag',
    id: 949,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hilo.hawaii.edu',
    name: 'University of Hawaii at Hilo',
    web_page: 'http://hilo.hawaii.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt_eieQnIYMJ1x81a_0aU0mN3ELCs0NryRVmH8aHGRQn_Y3dUFKlpyDaAwbw',
    id: 950,
    acronym: 'UHAH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'manoa.hawaii.edu',
    name: 'University of Hawaii at Manoa',
    web_page: 'http://manoa.hawaii.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Hawaii-at-Manoa.svg',
    id: 951,
    acronym: 'UHAM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hawaii.edu',
    name: 'University of Hawaii System',
    web_page: 'http://www.hawaii.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZeY0Blm1oY30WoikhvnSSvnyJCCc3NHXxjboI5tdGgqfDPedNYsqkqP3Vrg',
    id: 952,
    acronym: 'UHS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uh.edu',
    name: 'University of Houston',
    web_page: 'http://www.uh.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Houston.svg',
    id: 953,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uidaho.edu',
    name: 'University of Idaho',
    web_page: 'http://www.uidaho.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15625college.jpg',
    id: 954,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uic.edu',
    name: 'University of Illinois at Chicago',
    web_page: 'http://www.uic.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSatlwJCCjpA1QyBXF7igpxisACDAi5LkC0V35GBlYnoSlNfFg8erCBykg',
    id: 955,
    acronym: 'UIAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uis.edu',
    name: 'University of Illinois at Springfield',
    web_page: 'http://www.uis.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsdx2jMS_pJdOwpq0yOhDwQq0DZBPLMjXHnyua_myz8I971TFoUQ0VH1M',
    id: 956,
    acronym: 'UIAS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uiuc.edu',
    name: 'University of Illinois at Urbana-Champaign',
    web_page: 'http://www.illinois.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Illinois-at-Urbana-Champaign.svg',
    id: 957,
    acronym: 'UIAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uindy.edu',
    name: 'University of Indianapolis',
    web_page: 'http://www.uindy.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs-5WMdY_LKlaPKCCaLvKZM945v5TKAQg9lrF4kmWqETtkU_DjGIzOU8k',
    id: 959,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uiowa.edu',
    name: 'University of Iowa',
    web_page: 'http://www.uiowa.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Iowa.svg',
    id: 960,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uj.edu',
    name: 'University of Jamestown',
    web_page: 'http://www.uj.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuLFVWqMvLDyE2_ogl5gempqz1rihlPmdsMF5uEFqQQWqGFa7P03dL3Q',
    id: 961,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ku.edu',
    name: 'University of Kansas',
    web_page: 'http://www.ku.edu',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Kansas.svg',
    id: 962,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kumc.edu',
    name: 'University of Kansas School of Medicine',
    web_page: 'http://www.kumc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoHlRaI6dCloW7dUr9P1CiEhSyhpgjNTW3jZkKYtteCBowIDf05UgXE4c',
    id: 963,
    acronym: 'UKSM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uky.edu',
    name: 'University of Kentucky',
    web_page: 'http://www.uky.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Kentucky.svg',
    id: 964,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'louisiana.edu',
    name: 'University of Louisiana at Lafeyette',
    web_page: 'http://www.louisiana.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Louisiana-at-Lafeyette.svg',
    id: 966,
    acronym: 'ULAL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ulm.edu',
    name: 'University of Louisiana at Monroe',
    web_page: 'http://www.ulm.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Louisiana-at-Monroe.svg',
    id: 967,
    acronym: 'ULAM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'louisville.edu',
    name: 'University of Louisville',
    web_page: 'http://www.louisville.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Louisville.svg',
    id: 968,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umaine.edu',
    name: 'University of Maine',
    web_page: 'http://www.umaine.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15641college.jpg',
    id: 969,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umf.maine.edu',
    name: 'University of Maine at Farmington',
    web_page: 'http://www.umf.maine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbJBnrBgedW_HSu5hU7xSg-PaPTa5VOFnY6TV_oVSnDL22B35QbO3JJw',
    id: 970,
    acronym: 'UMAF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umfk.maine.edu',
    name: 'University of Maine at Fort Kent',
    web_page: 'http://www.umfk.maine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgUhLnBbvIk6WklBYvqFTc8X8W46aG6twDvssyBY6xk9oKsjScFti9hIU',
    id: 971,
    acronym: 'UMAFK',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umm.maine.edu',
    name: 'University of Maine at Machias',
    web_page: 'http://www.umm.maine.edu/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTuBBkNQkmyQUHxxBke1MHe88L0EN6oM9KE-k7GfcK8D3mbT7HWQPYRCw',
    id: 972,
    acronym: 'UMAM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umpi.maine.edu',
    name: 'University of Maine at Presque Island',
    web_page: 'http://www.umpi.maine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnpTzm8sqiGrW_mN24QbesGOwuLw_gOH5RP5YA4wX5MaiXJSchD0cxmPI',
    id: 973,
    acronym: 'UMAPI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'maine.edu',
    name: 'University of Maine System',
    web_page: 'http://www.maine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT81wrZzCyNG4JMKhNL-J_O6w-AhLa0YNcuURuIvcDhb0KYlmhUdcijc6oD',
    id: 974,
    acronym: 'UMS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umaryland.edu',
    name: 'University of Maryland Baltimore',
    web_page: 'http://www.umaryland.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsaAki35QxyV04zIGpgvo0WAXkqaEqC0VIRdhH_-B4u9uLkZOF1IrnefY',
    id: 975,
    acronym: 'UMB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umbc.edu',
    name: 'University of Maryland Baltimore County',
    web_page: 'http://www.umbc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsaAki35QxyV04zIGpgvo0WAXkqaEqC0VIRdhH_-B4u9uLkZOF1IrnefY',
    id: 976,
    acronym: 'UMBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umd.edu',
    name: 'University of Maryland College Park',
    web_page: 'http://www.umd.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Maryland-College-Park.svg',
    id: 977,
    acronym: 'UMCP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umes.edu',
    name: 'University of Maryland Eastern Shore',
    web_page: 'http://www.umes.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxhXLhtCRZSh3AnAHL1FM6pqhnuQ5sFc6aeBob9itY4cThlCUo-dUkZw',
    id: 978,
    acronym: 'UMES',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umuc.edu',
    name: 'University of Maryland - University College',
    web_page: 'http://www.umuc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYTtIX0GFwESXJFG-a6LIwEtaPgdHAdIidhfTHhwlmBtwQ22gOZckLXPE',
    id: 979,
    acronym: 'UM-UC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umass.edu',
    name: 'University of Massachusetts at Amherst',
    web_page: 'http://www.umass.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Massachusetts-at-Amherst.svg',
    id: 980,
    acronym: 'UMAA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umassd.edu',
    name: 'University of Massachusetts at Dartmouth',
    web_page: 'http://www.umassd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVIJ9kti624_idOXsoxfsgWGwBuVi8lHfaAZbNNONmVD97A2NYzRzuJYQ',
    id: 981,
    acronym: 'UMAD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uml.edu',
    name: 'University of Massachusetts at Lowell',
    web_page: 'http://www.uml.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdW42yUB34o5aWFfhp13SutTllNWW7mit0bH7S6PM-6PjNTZbwlm_g5gE',
    id: 982,
    acronym: 'UMAL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'memphis.edu',
    name: 'University of Memphis',
    web_page: 'http://www.memphis.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Memphis.svg',
    id: 983,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'miami.edu',
    name: 'University of Miami',
    web_page: 'http://welcome.miami.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Miami.svg',
    id: 984,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umich.edu',
    name: 'University of Michigan - Ann Arbor',
    web_page: 'http://www.umich.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Michigan-Ann-Arbor.svg',
    id: 985,
    acronym: 'UM-AA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umdearborn.edu',
    name: 'University of Michigan - Dearborn',
    web_page: 'http://www.umdearborn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSCw8caZvTaT1maqSnw7K8li2y_ivdpblUPXKnZk3-2yVN7jHnVOJn4u0',
    id: 986,
    acronym: 'UM-D',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umn.edu',
    name: 'University of Minnesota',
    web_page: 'http://www.umn.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Minnesota.svg',
    id: 987,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'crk.umn.edu',
    name: 'University of Minnesota - Crookston',
    web_page: 'http://www.crk.umn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROW1D6QrEqcV4lxnQmQvn73sMFuZKdnZRRzLsN86OFSxEsZMut133bxlE',
    id: 988,
    acronym: 'UM-C',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'd.umn.edu',
    name: 'University of Minnesota - Duluth',
    web_page: 'http://www.d.umn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZE2rba02o0jGK9WhdwOqUnPYGQAro08ptAdbemVSxvODp4pk0oc1DwA',
    id: 989,
    acronym: 'UM-D',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mrs.umn.edu',
    name: 'University of Minnesota - Morris',
    web_page: 'http://www.mrs.umn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbFBrr0TK0fLI-TuPQe-2vni5fOOBZeoVp73vF-B9EkX4BRRUH-CiiHps',
    id: 990,
    acronym: 'UM-M',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umn.edu',
    name: 'University of Minnesota - Twin Cities',
    web_page: 'http://twin-cities.umn.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15658college.jpg',
    id: 991,
    acronym: 'UM-TC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'olemiss.edu',
    name: 'University of Mississippi',
    web_page: 'http://www.olemiss.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Mississippi.svg',
    id: 992,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'missouri.edu',
    name: 'University of Missouri - Columbia',
    web_page: 'http://www.missouri.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Missouri-Columbia.svg',
    id: 994,
    acronym: 'UM-C',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umkc.edu',
    name: 'University of Missouri - Kansas City',
    web_page: 'http://www.umkc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkbIt-mB2BrzXDFNKpWD_Zs-N5r5xRaoI4mf7cOngrU3q18Iv0-Jf2QxY',
    id: 995,
    acronym: 'UM-KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umr.edu',
    name: 'University of Missouri - Rolla',
    web_page: 'http://www.umr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQehTuILBo-bITtc0aVbPy4rAb_K_GPd5sz56uxh-VpHZZMNJ83SAMNh9UJ',
    id: 996,
    acronym: 'UM-R',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umsl.edu',
    name: 'University of Missouri - Saint Louis',
    web_page: 'http://www.umsl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq_u-JPG1l34BgmYf-tQRBvsgqyw5BFe-20VepWgSp470iJ1Qyseyrf71V',
    id: 997,
    acronym: 'UM-SL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umt.edu',
    name: 'University of Montana',
    web_page: 'http://www.umt.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15666college.jpg',
    id: 998,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unk.edu',
    name: 'University of Nebraska Kearney',
    web_page: 'http://www.unk.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa6UKhx4UF95t9mTCu_-SdQz__x7crEBw4zfiH95dcmdgX-NdpWuniKA',
    id: 999,
    acronym: 'UNK',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unl.edu',
    name: 'University of Nebraska Lincoln',
    web_page: 'http://www.unl.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Nebraska-Lincoln.svg',
    id: 1000,
    acronym: 'UNL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unomaha.edu',
    name: 'University of Nebraska Omaha',
    web_page: 'http://www.unomaha.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNWegOSDfm1LpYhZAAcsDd7zTV4qUrkzRWdc6U6n5AznkpgmWj3gbWZw',
    id: 1001,
    acronym: 'UNO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unlv.edu',
    name: 'University of Nevada Las Vegas',
    web_page: 'http://www.unlv.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Nevada-Las-Vegas.svg',
    id: 1002,
    acronym: 'UNLV',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unr.edu',
    name: 'University of Nevada Reno',
    web_page: 'http://www.unr.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Nevada-Reno.svg',
    id: 1003,
    acronym: 'UNR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'une.edu',
    name: 'University of New England',
    web_page: 'http://www.une.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4I6QWu1rwTZHc8YI89cjfZl1nlsTZ2SZWqPXSf28eDKidXQpRzz4ggSU',
    id: 1004,
    acronym: 'UNE',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unh.edu',
    name: 'University of New Hampshire Durham',
    web_page: 'http://www.unh.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15675college.jpg',
    id: 1005,
    acronym: 'UNHD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'newhaven.edu',
    name: 'University of New Haven',
    web_page: 'http://www.newhaven.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi_6sfR2uzs3Gc_W2usUL6qt0XkwBZEsjiNyEVYGpYeSxhYj-SBjzBEg',
    id: 1006,
    acronym: 'UNH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unm.edu',
    name: 'University of New Mexico',
    web_page: 'http://www.unm.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-New-Mexico.svg',
    id: 1007,
    acronym: 'UNM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uno.edu',
    name: 'University of New Orleans',
    web_page: 'http://www.uno.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXz8crWdTSW4Gl_aPxx4xHkKe3VhYN8euD_lgAx1cReux_C6GnePz8aEo',
    id: 1008,
    acronym: 'UNO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unca.edu',
    name: 'University of North Carolina at Asheville',
    web_page: 'http://www.unca.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnxrJKOUNXy1kw5Ab7L9c1zNKRDF-upTdMOfU2x7zurCwfywcQigOHR9U',
    id: 1009,
    acronym: 'UNCAA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unc.edu',
    name: 'University of North Carolina at Chapel Hill',
    web_page: 'http://www.unc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-North-Carolina-at-Chapel-Hill.svg',
    id: 1010,
    acronym: 'UNCACH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uncc.edu',
    name: 'University of North Carolina at Charlotte',
    web_page: 'http://www.uncc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-North-Carolina-at-Charlotte.svg',
    id: 1011,
    acronym: 'UNCAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uncg.edu',
    name: 'University of North Carolina at Greensboro',
    web_page: 'http://www.uncg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXbF07WFJ9I6CtODyDxAh2YibMk3e8ZM6NpHh_BufCa8HQaByG7vJCnbI',
    id: 1012,
    acronym: 'UNCAG',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uncw.edu',
    name: 'University of North Carolina at Wilmington',
    web_page: 'http://uncw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN4ivqE1z95Th1qn6JkH_PfzMsIkh98_dLaibtGh3QoK6BC2sk4fz3Kw',
    id: 1013,
    acronym: 'UNCAW',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'und.edu',
    name: 'University of North Dakota',
    web_page: 'http://und.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15685college.jpg',
    id: 1014,
    acronym: 'UND',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unco.edu',
    name: 'University of Northern Colorado',
    web_page: 'http://www.unco.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/university-of-northern-colorado.png',
    id: 1015,
    acronym: 'UNC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uni.edu',
    name: 'University of Northern Iowa',
    web_page: 'http://www.uni.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15689college.jpg',
    id: 1016,
    acronym: 'UNI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unf.edu',
    name: 'University of North Florida',
    web_page: 'http://www.unf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3aiQYybQjbaHSagRlqmS3KqGqml10MxV5Fu2ARhrAgc9YipDouVEQcw',
    id: 1017,
    acronym: 'UNF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'unt.edu',
    name: 'University of North Texas',
    web_page: 'http://www.unt.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-North-Texas.svg',
    id: 1018,
    acronym: 'UNT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hsc.unt.edu',
    name: 'University of North Texas Health Science Center',
    web_page: 'http://www.hsc.unt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWeaSdGDpKPK8NkMPl8UKM1b3y6B6H0zsVECaeAstYIPvf4vlqa0vAyw4',
    id: 1019,
    acronym: 'UNTHSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nd.edu',
    name: 'University of Notre Dame',
    web_page: 'http://www.nd.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Notre-Dame.svg',
    id: 1020,
    acronym: 'UND',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ou.edu',
    name: 'University of Oklahoma',
    web_page: 'http://www.ou.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Oklahoma.svg',
    id: 1021,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uoregon.edu',
    name: 'University of Oregon',
    web_page: 'http://www.uoregon.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Oregon.svg',
    id: 1022,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bloomu.edu',
    name: 'Bloomsburg University of Pennsylvania',
    web_page: 'http://www.bloomu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4QUOrKGeLp8BFhGZtdSfx_vT24MmJEO-d6DCHZ-obaEJ1QqhTXt-94w',
    id: 1023,
    acronym: 'BUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'phoenix.edu',
    name: 'University of Phoenix',
    web_page: 'http://www.phoenix.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjt6KZ7tAjyaVItxmiLUQo3fJ8plXX8sDVAtlWY_c-e-R3EPw7L1Lr7vM',
    id: 1024,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pitt.edu',
    name: 'University of Pittsburgh',
    web_page: 'http://www.pitt.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Pittsburgh.svg',
    id: 1025,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'upj.pitt.edu',
    name: 'University of Pittsburgh at Johnstown',
    web_page: 'http://www.upj.pitt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRp0AHP8Sa-RrXk2rjpRcL1wR5_orfEabdJ1uyq2ZMHS-Xk79qRxLHf4Ws',
    id: 1026,
    acronym: 'UPAJ',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'up.edu',
    name: 'University of Portland',
    web_page: 'http://www.up.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCJAzW_CfDxTuj2mCh2bGLzl1fsi2WzT5MjIsJz-yZpbwA4Jc7EhWL-74',
    id: 1027,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'upr.edu',
    name: 'University of Puerto Rico',
    web_page: 'http://www.upr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUlfTN9k0yVmhoUSd_kchx74JPVJw2NTwE8_NdiHHTgtw55IPZo9489Q',
    id: 1028,
    acronym: 'UPR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ups.edu',
    name: 'University of Puget Sound',
    web_page: 'http://www.ups.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGmhqHUk8LZwR5OfpWxLwx1BZgRfAZEvxc9JiEVZmAjz-edj3rdFMp4Ps',
    id: 1029,
    acronym: 'UPS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uor.edu',
    name: 'University of Redlands',
    web_page: 'http://www.uor.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuz2Ie36irvJxPLUCe5CmYNGeFihvVnlyFZGMLxG3JzqHoTncIZBPGSC4',
    id: 1030,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uri.edu',
    name: 'University of Rhode Island',
    web_page: 'http://www.uri.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15704college.jpg',
    id: 1031,
    acronym: 'URI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'urich.edu',
    name: 'University of Richmond',
    web_page: 'http://www.urich.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15705college.jpg',
    id: 1032,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rochester.edu',
    name: 'University of Rochester',
    web_page: 'http://www.rochester.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHV2t0o9eUG38mnCwjfu-EAHEVQxzh3r6uNdXMO9uVGGM7btG5tmvY_Q',
    id: 1033,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stthomas.edu',
    name: 'University of Saint Thomas (MN)',
    web_page: 'http://www.stthomas.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15724college.jpg',
    id: 1034,
    acronym: 'UST(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stthom.edu',
    name: 'University of Saint Thomas (TX)',
    web_page: 'http://www.stthom.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjN_FaNpI_LDqHc5-46IBdydpan0We5R5IDcEN4NvGESSnGsZTtUj5mqI',
    id: 1035,
    acronym: 'UST(',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sandiego.edu',
    name: 'University of San Diego',
    web_page: 'http://www.sandiego.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15708college.jpg',
    id: 1036,
    acronym: 'USD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usfca.edu',
    name: 'University of San Francisco',
    web_page: 'http://www.usfca.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxDgiMH8yAP-wtajf9eYxjEt-XUIRS5K6LZmLOANg4Llw5BKcTfD8uQg',
    id: 1037,
    acronym: 'USF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usao.edu',
    name: 'University of Science & Arts of Oklahoma',
    web_page: 'http://usao.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcJeaE7BLrYqdcJ52pCnUacUH7pceeOh1ygEfoXVSxgu-UrqwUmjNKRA',
    id: 1038,
    acronym: 'US&AO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'scranton.edu',
    name: 'University of Scranton',
    web_page: 'http://www.scranton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHsoCuZ30brqDLMzQEXquB9zbj8RmOk3lulFQOvLEu9AhJSgHAaYI70A',
    id: 1039,
    acronym: 'US',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usouixfalls.edu',
    name: 'University of Sioux Falls',
    web_page: 'http://www.usouixfalls.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIb1V-K-RFktZcCaZA9fYl7d9zuFKauL7Pd4bpynds-1E6NtGYtKzeFQ',
    id: 1040,
    acronym: 'USF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southalabama.edu',
    name: 'University of South Alabama',
    web_page: 'http://www.southalabama.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-South-Alabama.svg',
    id: 1041,
    acronym: 'USA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sc.edu',
    name: 'University of South Carolina',
    web_page: 'http://www.sc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-South-Carolina.svg',
    id: 1042,
    acronym: 'USC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usca.edu',
    name: 'University of South Carolina - Aiken',
    web_page: 'http://www.usca.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd-O2PmllNUX_VZgP6oadU6DtS_k5fhdUnqrKeQbrljI3OP5pL35rjJA',
    id: 1043,
    acronym: 'USC-A',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usd.edu',
    name: 'University of South Dakota',
    web_page: 'http://www.usd.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15717college.jpg',
    id: 1044,
    acronym: 'USD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usc.edu',
    name: 'University of Southern California',
    web_page: 'http://www.usc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Southern-California.svg',
    id: 1045,
    acronym: 'USC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usi.edu',
    name: 'University of Southern Indiana',
    web_page: 'http://www.usi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROsY6nYV68ktOBgY8Jjk5sIScPs5qkqolUQFTIZ8IkaSEL3j6trFOaqg',
    id: 1046,
    acronym: 'USI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usm.maine.edu',
    name: 'University of Southern Maine',
    web_page: 'http://www.usm.maine.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK8m4r59Jk6umVEqKuhz0KVWWdu7Mi61L0YBfjV4O5V3twwjZMRBZVKg',
    id: 1047,
    acronym: 'USM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usm.edu',
    name: 'University of Southern Mississippi',
    web_page: 'http://www.usm.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Southern-Mississippi.svg',
    id: 1048,
    acronym: 'USM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usf.edu',
    name: 'University of South Florida',
    web_page: 'http://www.usf.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-South-Florida.svg',
    id: 1049,
    acronym: 'USF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usl.edu',
    name: 'University of Southwestern Louisiana',
    web_page: 'http://www.usl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKwK8ENuzHZG8p5lOC3oS1jsUNpWpZg6VKPXIL1xoceXg3pbej5G2RJw',
    id: 1050,
    acronym: 'USL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ut.edu',
    name: 'University of Tampa',
    web_page: 'http://www.ut.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnLOaQRhghNiJC-Z7hJySgQdlsjL9a8OdxjbI3DB-hm9c_WagvV8K1fA',
    id: 1051,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utc.edu',
    name: 'University of Tennessee at Chattanooga',
    web_page: 'http://www.utc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15726college.jpg',
    id: 1052,
    acronym: 'UTAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utk.edu',
    name: 'University of Tennessee Knoxville',
    web_page: 'http://www.utk.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Tennessee-Knoxville.svg',
    id: 1053,
    acronym: 'UTK',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utm.edu',
    name: 'University of Tennessee Martin',
    web_page: 'http://www.utm.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15728college.jpg',
    id: 1054,
    acronym: 'UTM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uta.edu',
    name: 'University of Texas at Arlington',
    web_page: 'http://www.uta.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSY01kjxB7uHuzULMaYzqDcJSwKWC2PVNaaa-hZC9w_PgwtwKkrW1Nd1Y8',
    id: 1055,
    acronym: 'UTAA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utexas.edu',
    name: 'University of Texas at Austin',
    web_page: 'http://www.utexas.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Texas-at-Austin.svg',
    id: 1056,
    acronym: 'UTAA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utb.edu',
    name: 'University of Texas at Brownsville',
    web_page: 'http://www.utb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQaW908jjH90w-dnoVR5olJTpAuWINBEQKoISZJhJBm4rl-ylbxg0PsA',
    id: 1057,
    acronym: 'UTAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utd.edu',
    name: 'University of Texas at Dallas',
    web_page: 'http://www.utdallas.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU492xLTy4gUXpMJagHAWb1VsYFJ36owueMitBP-EcvvsqBk7_NNwpCQ',
    id: 1058,
    acronym: 'UTAD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utep.edu',
    name: 'University of Texas at El Paso',
    web_page: 'http://www.utep.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Texas-at-El-Paso.svg',
    id: 1060,
    acronym: 'UTAEP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utsa.edu',
    name: 'University of Texas at San Antonio',
    web_page: 'http://www.utsa.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Texas-at-San-Antonio.svg',
    id: 1061,
    acronym: 'UTASA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uttyler.edu',
    name: 'University of Texas at Tyler',
    web_page: 'http://www.uttyler.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIIaHMFTM3m5MfrxWRhs0ve4S-OMcv5iZfmT3Y6DngaCls7lRKofxcKw',
    id: 1062,
    acronym: 'UTAT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utmb.edu',
    name: 'University of Texas Medical Branch',
    web_page: 'http://www.utmb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSivgeqdU5zqeCBSrqiqAAbvfP0sFOFeExIAp8jt5jr75m0F_UOYlcizo',
    id: 1063,
    acronym: 'UTMB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'panam.edu',
    name: 'University of Texas - Pan American',
    web_page: 'http://www.panam.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDSe5wj7-3FH9-Efx7nBHQ7d0zDD-UwIjOb9RIVCmXxGk2O3asSiuG3w',
    id: 1064,
    acronym: 'UT-PA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swmed.edu',
    name: 'University of Texas Southwestern Medical Center at Dallas',
    web_page: 'http://www.swmed.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfBXEN0lxLcoxaBO7oPHSqrmSacNHCaBUT9p0c1oPcLasJnRbhfUtiX3o',
    id: 1065,
    acronym: 'UTSMCAD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'udc.edu',
    name: 'University of the District of Columbia',
    web_page: 'http://www.udc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQICkD3T2S_eqLO9tjoUoqGqm-D1ESfIz6S7DZFzuUBWG0K0pmWUvnMwA',
    id: 1066,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uiw.edu',
    name: 'University of the Incarnate Word',
    web_page: 'http://www.uiw.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15738college.jpg',
    id: 1067,
    acronym: 'UIW',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ozarks.edu',
    name: 'University of the Ozarks',
    web_page: 'http://www.ozarks.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlbCNIlLFnAVq3jV_VGtnHQ6oOEXXtBFPYN19g4-gSlsdpgJMVpUK0TxA',
    id: 1068,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uop.edu',
    name: 'University of the Pacific',
    web_page: 'http://www.uop.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiyZr6b6LMohbmKsDap_0KN5kPxddEcFLY78gykdlsTMcsXLfTrbGPkqA',
    id: 1069,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uvi.edu',
    name: 'University of the Virgin Islands',
    web_page: 'http://www.uvi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvk5mxmVORwUDmna0178nHYAXO7UJemP1HuYQ7T8SPrELOWtc5QmJBLhs',
    id: 1070,
    acronym: 'UVI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utoledo.edu',
    name: 'University of Toledo',
    web_page: 'http://www.utoledo.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Toledo.svg',
    id: 1071,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utulsa.edu',
    name: 'University of Tulsa',
    web_page: 'http://utulsa.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Tulsa.svg',
    id: 1072,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utah.edu',
    name: 'University of Utah',
    web_page: 'http://www.utah.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Utah.svg',
    id: 1073,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uvm.edu',
    name: 'University of Vermont',
    web_page: 'http://www.uvm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYb-8DaND6awTP-dD4Mesrz7htz_L7vkBbboGmRpC2pFuveWPK-mcIlA',
    id: 1074,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'virginia.edu',
    name: 'University of Virginia Charlottesville',
    web_page: 'http://www.virginia.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Virginia-Charlottesville.svg',
    id: 1075,
    acronym: 'UVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uw.edu',
    name: 'University of Washington',
    web_page: 'http://www.washington.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Washington.svg',
    id: 1076,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westal.edu',
    name: 'University of West Alabama',
    web_page: 'http://www.westal.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzQPz8trk7d75K4QCNoGvatdK2xepJvS08jpHa1JuqaR_0E5n950U4Zaw',
    id: 1078,
    acronym: 'UWA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwf.edu',
    name: 'University of West Florida',
    web_page: 'http://www.uwf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZNGDGsSxxUvlPMngV5YQpe6dH2J8G-U6IIeC7YUcnky1M-4h8enABgg',
    id: 1079,
    acronym: 'UWF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwec.edu',
    name: 'University of Wisconsin - Eau Claire',
    web_page: 'http://www.uwec.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7Ow3QX57nfEW5bkow1EgAng1KgaT51VSJvzPFhaZ-z0oUr2d2QyyC-x0',
    id: 1080,
    acronym: 'UW-EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwgb.edu',
    name: 'University of Wisconsin - Green Bay',
    web_page: 'http://www.uwgb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSZZ8VR5QS3iguS6tObss8VbFeH809LjYIeepI3Xei-YPMs2ZK6z2LcQ',
    id: 1081,
    acronym: 'UW-GB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwlax.edu',
    name: 'University of Wisconsin - LaCrosse',
    web_page: 'http://www.uwlax.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPG2P06azqOT7y7RI2-PA60oYNStIe6ST8KuBR3-2gqfSSiJ2_m8D9WpI',
    id: 1082,
    acronym: 'UW-L',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wisc.edu',
    name: 'University of Wisconsin - Madison',
    web_page: 'http://www.wisc.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Wisconsin-Madison.svg',
    id: 1083,
    acronym: 'UW-M',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwm.edu',
    name: 'University of Wisconsin - Milwaukee',
    web_page: 'http://www.uwm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmsX4a-TdYMT0Hh05GOG0asx71WnSX2IXsp_0UJkNkV26UEsiidTm6Mg',
    id: 1084,
    acronym: 'UW-M',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwosh.edu',
    name: 'University of Wisconsin - Oshkosh',
    web_page: 'http://www.uwosh.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC7cEvG9mqXVctULUp2ePOVJ7SVtmI2CJNeDCv2gj-7IAdXMwFN2TAbQ',
    id: 1085,
    acronym: 'UW-O',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwp.edu',
    name: 'University of Wisconsin - Parkside',
    web_page: 'http://www.uwp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjw8R1kmIADOujU6OwpzXw_KxutHZT2jp2WkaX_ViYatoirFB2R7BTjloU',
    id: 1086,
    acronym: 'UW-P',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwplatt.edu',
    name: 'University of Wisconsin - Platteville',
    web_page: 'http://www.uwplatt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStb4938pvUU5GDg3e99P4eUxjIZALJ_IPxXHBJo8f_UPPr0nNe19Iqlg',
    id: 1087,
    acronym: 'UW-P',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwrf.edu',
    name: 'University of Wisconsin - River Falls',
    web_page: 'http://www.uwrf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDnK8olfQhwELr3FM_MnCaj_WRDyijNeToFtLK39EqZfMBa2mIzTTQmA',
    id: 1088,
    acronym: 'UW-RF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwsp.edu',
    name: 'University of Wisconsin - Stevens Point',
    web_page: 'http://www.uwsp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAnmqE6LCyBOoyrmcwiF6hd1SGaqg8fex6rHP2ERPEZ2hAJ7hVblbl8CI',
    id: 1089,
    acronym: 'UW-SP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwstout.edu',
    name: 'University of Wisconsin - Stout',
    web_page: 'http://www.uwstout.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkysxhfWU4PxRPHre9iBuoyyqdPZ3mlm8SQz50-qd5mYI58FJ8fH2Ot8c',
    id: 1090,
    acronym: 'UW-S',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwsuper.edu',
    name: 'University of Wisconsin - Superior',
    web_page: 'http://www.uwsuper.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsAlpbOctwXuHXaJe-Ir-ZQPdhJz20w7caqWxd0zbm76Ae-cKRyXRpawpH7g',
    id: 1091,
    acronym: 'UW-S',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwsa.edu',
    name: 'University of Wisconsin System',
    web_page: 'http://www.uwsa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5cfJtIf7daj2-KSz54ZX3SwYY9J6QuIUm5jPwDC68wimQ4b6QSaRdxh8',
    id: 1092,
    acronym: 'UWS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uww.edu',
    name: 'University of Wisconsin - Whitewater',
    web_page: 'http://www.uww.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbPr1jFau_ougIsCP7RG-qgXarKiw4abifgm3aUy3wzrK_kfwarWtu-w',
    id: 1093,
    acronym: 'UW-W',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwyo.edu',
    name: 'University of Wyoming',
    web_page: 'http://www.uwyo.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/University-of-Wyoming.svg',
    id: 1094,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uiu.edu',
    name: 'Upper Iowa University',
    web_page: 'http://www.uiu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvuVu5kcHf66edZIZlEUp0BIQ9v27mfuFIyv88q2XSqWSM--DHdZN6zGAA',
    id: 1095,
    acronym: 'UIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ursinus.edu',
    name: 'Ursinus College',
    web_page: 'http://www.ursinus.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwUclfOS8cc5vBRukq9Su1qqitaowt10k1JHOqtleKmTQiLZqZkKnBaw',
    id: 1096,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'usu.edu',
    name: 'Utah State University',
    web_page: 'http://www.usu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Utah-State-University.svg',
    id: 1097,
    acronym: 'USU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uvsc.edu',
    name: 'Utah Valley State College',
    web_page: 'http://www.uvsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1wD-J2NqCDU3y7vr6m5rtlD2BYu0bnfkIwJ2pw6thZlnIoQqfK_kj21E',
    id: 1098,
    acronym: 'UVSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'valdosta.edu',
    name: 'Valdosta State University',
    web_page: 'http://www.valdosta.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRY-k9eXXajWz7F9VJopRE2raps3zx9f1ZqiWPxRqrLq1OyOQx9oVm4Q',
    id: 1099,
    acronym: 'VSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vcsu.nodak.edu',
    name: 'Valley City State University',
    web_page: 'http://www.vcsu.nodak.edu/home.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHlJx1G87ZpKTgjbIT9sCfheQL9V0NS9g6ZYNBVsrDCGPI1l-3cxq1oA',
    id: 1100,
    acronym: 'VCSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'valpo.edu',
    name: 'Valparaiso University',
    web_page: 'http://www.valpo.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/valparaiso-university.jpeg',
    id: 1101,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vgcc.edu',
    name: 'Vance-Granville Community College',
    web_page: 'http://www.vgcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtNr3rY929D4JrINRG7kzjTxnKur2_G_KTGfPQhhHG6x26QsX9enFmeKc',
    id: 1102,
    acronym: 'VCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vanderbilt.edu',
    name: 'Vanderbilt University',
    web_page: 'http://www.vanderbilt.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Vanderbilt-University.svg',
    id: 1103,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vassar.edu',
    name: 'Vassar College',
    web_page: 'http://www.vassar.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMtiNSNZzGtqf3dgvXGOV71Prn8xx4JlQW_ahgp5Qj5UxBEis51D0DU10',
    id: 1104,
    acronym: 'VC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vtc.edu',
    name: 'Vermont Technical College',
    web_page: 'http://www.vtc.edu/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQ69nVMb-DSNKm-LcT3Lf3lvVORf-PjQcc4hRG8kQjjReouFJroGtx4',
    id: 1105,
    acronym: 'VTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vjc.edu',
    name: 'Villa Julie College',
    web_page: 'http://www.vjc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyRxVWmeqlZrOcc_5PBCrsFCcCsnpKo795O7R8VHWEs0vUOkGrYDErtws',
    id: 1106,
    acronym: 'VJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'villanova.edu',
    name: 'Villanova University',
    web_page: 'http://www.villanova.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15791college.jpg',
    id: 1107,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vcu.edu',
    name: 'Virginia Commonwealth University',
    web_page: 'http://vcu.vcu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbwt-3ZM9ZftyMsLhnI7o1J_nkStbDp-xFxkP2rxX6ZNtkE4QbyBlAgMc',
    id: 1108,
    acronym: 'VCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vccs.edu',
    name: 'Virginia Community College System',
    web_page: 'http://www.vccs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmXZqdsEEUXTO4Fc25CQctBJ-J2RR_xBAtcHkn-rr_9K2bYm6VB_4ttw',
    id: 1109,
    acronym: 'VCCS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vic.edu',
    name: 'Virginia Intermont College',
    web_page: 'http://www.vic.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeVZJQGP4NRPhW2hBMWUi1gEx9ihKJUcX7DaZbMwH4o2KtpfxNv7k6pw',
    id: 1110,
    acronym: 'VIC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vmi.edu',
    name: 'Virginia Military Institute',
    web_page: 'http://www.vmi.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15795college.jpg',
    id: 1111,
    acronym: 'VMI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vt.edu',
    name: 'Virginia Polytechnic Institute and State University',
    web_page: 'http://www.vt.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Virginia-Polytechnic-Institute-and-State-University.svg',
    id: 1112,
    acronym: 'VT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vsu.edu',
    name: 'Virginia State University',
    web_page: 'http://www.vsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZmpNkeOO3Pt2-nLl_oep4dmLwqT6e2bTXND3wruidKwlHouUzqK0DvoY',
    id: 1113,
    acronym: 'VSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vwc.edu',
    name: 'Virginia Wesleyan College',
    web_page: 'http://www.vwc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU0Z8UA1bBXOTkRCca4AB2obclwM8zDQ0ONitYbJYva6vdSQ8Z75fHiu4',
    id: 1114,
    acronym: 'VWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wabash.edu',
    name: 'Wabash College',
    web_page: 'http://www.wabash.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmLX7M9PJv7cRh7aoWSV9Uhw4OxiN5IgM0UDifCdF8X9bduuHfUmbPtJE',
    id: 1115,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wfu.edu',
    name: 'Wake Forest University',
    web_page: 'http://www.wfu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Wake-Forest-University.svg',
    id: 1116,
    acronym: 'WFU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'waldenu.edu',
    name: 'Walden University',
    web_page: 'http://www.waldenu.edu/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTUWM-KWSxsD4gpRu1s4961QYlO3OdTPCHoJrfS8I5V2uK4q4DjZ65OndY',
    id: 1117,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wwc.edu',
    name: 'Walla Walla College',
    web_page: 'http://www.wwc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkZC8BkuD589dyYfxDienImPokiwHOvSb0KpU_Ffi0zKn1IOW1wZWH3A',
    id: 1118,
    acronym: 'WWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'walshcollege.edu',
    name: 'Walsh College',
    web_page: 'http://www.walshcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsJRB1BXi6AK83KttkWQYR8sfpNJoIRWim74817Sud4NG-_ZHOMY_W96M',
    id: 1119,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'warren-wilson.edu',
    name: 'Warren Wilson College',
    web_page: 'http://www.warren-wilson.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa_oLNWDSiPDkbPLqBTA6qZ4eRSQPr_HaV3YFCy5hWfu2NRvkgmQZ7dQ',
    id: 1120,
    acronym: 'WWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wartburg.edu',
    name: 'Wartburg College',
    web_page: 'http://www.wartburg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXxQLWagcDSzo2-PkDXB8EdwAkOrMgilq00CcCXQf2Z9F7YE1JboVV1mU',
    id: 1121,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wuacc.edu',
    name: 'Washburn University',
    web_page: 'http://www.wuacc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXbd_yYLjqAom6iNusmOMsgz5EWVfQWKI0kFaKrNhhYIHunGMCYJmoSFc',
    id: 1122,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wlu.edu',
    name: 'Washington & Lee University',
    web_page: 'http://www.wlu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnvQYyq3mEDq8sB9Y3yCJLk9AiS81TzrW5qXyqS8kmGbnhg_sBXYoc-6c',
    id: 1123,
    acronym: 'W&LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bible.edu',
    name: 'Washington Bible College/Capital Bible Seminary',
    web_page: 'http://www.bible.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbGsE7cwzRmL7i573es-qY6gfk9kkSUZ93ENq7Lu0bPNnvkiZJcWTevQ',
    id: 1124,
    acronym: 'WBCBS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'washcoll.edu',
    name: 'Washington College',
    web_page: 'http://www.washcoll.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFfoPU2J9Skr0DMp4afqtrnbPJ56oawY1kVBVOkpYE1Yebr06IXZ4EZp4',
    id: 1125,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wsu.edu',
    name: 'Washington State University',
    web_page: 'http://www.wsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Washington-State-University.svg',
    id: 1126,
    acronym: 'WSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wsu.edu',
    name: 'Washington State University at Tri-Cities',
    web_page: 'http://www.tricity.wsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyVJ-5O9rzBkmYb0_c17NipUdSmVllQxHDVzuTnkKxppXQZB9a3UvgzB_OqA',
    id: 1127,
    acronym: 'WSUAT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vancouver.wsu.edu',
    name: 'Washington State University at Vancouver',
    web_page: 'http://www.vancouver.wsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDRjk5PVc-zYxZt5PYp322wiT9ymhNTq1JDRLEWa-QBBJamghgwQWr6Q',
    id: 1128,
    acronym: 'WSUAV',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wustl.edu',
    name: 'Washington University Saint Louis',
    web_page: 'http://www.wustl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk_-qKjlJtKj599y5ypdTB3Io8LI9M3k_nx_XMztNiTR7oQzhH6aZzmAM',
    id: 1129,
    acronym: 'WUSL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'waubonsee.edu',
    name: 'Waubonsee Community College',
    web_page: 'http://www.waubonsee.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRryeJi6o43F4iBZFTXKO58VMI9vn5KC8ECWRuEwYmC8s9WLPEerCxgwiA',
    id: 1130,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'waynesburg.edu',
    name: 'Waynesburg College',
    web_page: 'http://waynesburg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYd4EtbB9ghGcEubnyVj8AXks_Itby5lnGaFFKLbsCp-Um2fj_MbvQKCA',
    id: 1131,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wayne.edu',
    name: 'Wayne State University',
    web_page: 'http://www.wayne.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUXymjo82wH9Nk59mUjRxeP2BS00OC16-ddbLBW69ypG5HYAH7WWfH7w',
    id: 1132,
    acronym: 'WSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'weber.edu',
    name: 'Weber State University',
    web_page: 'http://www.weber.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15831college.jpg',
    id: 1133,
    acronym: 'WSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'webster.edu',
    name: 'Webster University',
    web_page: 'http://www.webster.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrFDCnPwhjgzerCPTKZhLf17BW7w-lHNMW8NEYaoev4Rxk-fd2rQKkOxH-',
    id: 1134,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wellesley.edu',
    name: 'Wellesley College',
    web_page: 'http://www.wellesley.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5__E2ZUKpYyj2VhJFSMrlcbhugIijjGK__ps_YR1hDZsdA2j5zrZdUQ',
    id: 1135,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wells.edu',
    name: 'Wells College',
    web_page: 'http://www.wells.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7SiGjMyL37MTV9x_aYw88sAChw9ctXM4q-Htt_HQpNkk0e-kqLeZ1Og',
    id: 1136,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wit.edu',
    name: 'Wentworth Institute of Technology',
    web_page: 'http://www.wit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdLVaOUDzsdgoOsMSJf-GWGuaTjF_DBlAZVv99c-YmeftGifQLe1f7WA0',
    id: 1137,
    acronym: 'WIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wesleyan.edu',
    name: 'Wesleyan University',
    web_page: 'http://www.wesleyan.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrN_mbBr_fRvM1M3vjwNqaz7X4F3L6PehTAYYj2aQgnUMA20UjC7jrzg',
    id: 1138,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wesley.edu',
    name: 'Wesley College',
    web_page: 'http://www.wesley.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpCYT3EEiWyr_BbhvhdmmPQjrxbKrc24N4wPh-Y1H1TtNIuQnvjWGF1oY',
    id: 1139,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wcupa.edu',
    name: 'West Chester University of Pennsylvania',
    web_page: 'http://www.wcupa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT-z3u-GLwJibX5dmxu0Fvbej-e4H_dNu7xxSAUPYGzZ6Si_A2y_64EQ',
    id: 1140,
    acronym: 'WCUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westcoastuniversity.edu',
    name: 'West Coast University',
    web_page: 'http://westcoastuniversity.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQESRszr0RGjv45J_2PWmrbjcnc8Na21YomM6xeoPCg3f1Nc7B9UOMWyQ',
    id: 1141,
    acronym: 'WCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wcu.edu',
    name: 'Western Carolina University',
    web_page: 'http://www.wcu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15853college.jpg',
    id: 1142,
    acronym: 'WCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wcsu.edu',
    name: 'Western Connecticut State University',
    web_page: 'http://www.wcsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf-IxGZhg7n0KCJfp7-Q5C40mL7JzOFAW2Ny8jYHGPhhZ9qAeHaFAxAyk',
    id: 1143,
    acronym: 'WCSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wgu.edu',
    name: 'Western Governors University',
    web_page: 'http://www.wgu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEOKr2NzkT0k0R7wAAtiq5DUPfqICnb7p-ORWJRnYLB4X8xd9-RZf1tFjz',
    id: 1144,
    acronym: 'WGU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wiu.edu',
    name: 'Western Illinois University',
    web_page: 'http://www.wiu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15855college.jpg',
    id: 1145,
    acronym: 'WIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wku.edu',
    name: 'Western Kentucky University',
    web_page: 'http://www.wku.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Western-Kentucky-University.svg',
    id: 1146,
    acronym: 'WKU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wmich.edu',
    name: 'Western Michigan University',
    web_page: 'http://www.wmich.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/Western-Michigan-University.svg',
    id: 1147,
    acronym: 'WMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wmc.edu',
    name: 'Western Montana College',
    web_page: 'http://www.wmc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTaxuqlSg8BZyRYIRwc20oiVqhj9oVMLm6jipMT5eYokbH1PiGBo3qm3k',
    id: 1148,
    acronym: 'WMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wnec.edu',
    name: 'Western New England College',
    web_page: 'http://www.wnec.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6T52moG7JDda7UxEdmsvaxVvsbUpVrz_LVPmXpRgRbIZLmi2SE9w-wrKUcg',
    id: 1149,
    acronym: 'WNEC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wnmu.edu',
    name: 'Western New Mexico University',
    web_page: 'http://www.wnmu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ6tQemzDvwwBAEXNGRXwfIGCzkIvkjixJFgvcnzItirbmepbdcMkDEw',
    id: 1150,
    acronym: 'WNMU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'western.edu',
    name: 'Western State Colorado University',
    web_page: 'http://www.western.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxX2MrV2GzMDORLqQrpLaBKR0HZCDyBHWcIVcY1OblpeZXn5N_O4ZEvQ',
    id: 1151,
    acronym: 'WSCU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wwu.edu',
    name: 'Western Washington University',
    web_page: 'http://www.wwu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaFWtMMeB4WxVqBewnAndjX0HAqi5A5-uQ42A2fpTdDrsYKyC8wv9B-ck',
    id: 1152,
    acronym: 'WWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westfield.mass.edu',
    name: 'Westfield State College',
    web_page: 'http://www.westfield.mass.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd0b7Wcbe_162NhJv86ckof-Ok_bHtIg0zzES_kZL0qX52JIfd3LqRwF85',
    id: 1153,
    acronym: 'WSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westga.edu',
    name: 'West Georgia College',
    web_page: 'http://www.westga.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFSzTQjCd40h8-7SqYFaN-kTg4mZtdYpYlsWlC01dVWGL_3QPEdYz9bqiLOQ',
    id: 1154,
    acronym: 'WGC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wlsc.wvnet.edu',
    name: 'West Liberty State College',
    web_page: 'http://www.wlsc.wvnet.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx620FJeRmsaB_4dTB-yJKC6g2oczI65WkLRcxcLSNtsgVd9ljpG4ijeo',
    id: 1155,
    acronym: 'WLSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westminster.edu',
    name: 'Westminster College',
    web_page: 'http://www.westminster.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3ouYxWDjd9Do8Yyt0AdCchMYlIQpsGuKBCFSbGoRHLFMWWUHGN5vXjbk',
    id: 1156,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wcslc.edu',
    name: 'Westminster College of Salt Lake City',
    web_page: 'http://www.wcslc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8aHWu3n9uvawNhtJEXL7RwQ_Dv7194IOzY0QZ4boZafmF43-LMlXZkTQ',
    id: 1158,
    acronym: 'WCSLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wts.edu',
    name: 'Westminster Theological Seminary',
    web_page: 'http://www.wts.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbrfUVEjnwWwDW71f5YjC98P0RCgmPHNPwLAVQQExOQ3BhQYukUXpjNe4',
    id: 1159,
    acronym: 'WTS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westmont.edu',
    name: 'Westmont College',
    web_page: 'http://www.westmont.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA-EhmMxYJcj6WJrU79CSj0Yecied4JL489xPnE0J0aQI-ZsXB-EWnBqU',
    id: 1160,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wtamu.edu',
    name: 'West Texas A&M University',
    web_page: 'http://www.wtamu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUbjErUZHNamPeXBd5jxNdSMg26cwEr_slxvSLt0BjFr9WaJeFRbAeLq8',
    id: 1161,
    acronym: 'WTAU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wvu.edu',
    name: 'West Virginia University',
    web_page: 'http://www.wvu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/university/West-Virginia-University.svg',
    id: 1162,
    acronym: 'WVU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westliberty.edu',
    name: 'West Virginia University at Parkersburg',
    web_page: 'http://www.westliberty.edu/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRol73YYJwu446bJvqfJheWdYAEtKW1sX8wugXbKDMCLvhJWDMlVwpTkwrWGg',
    id: 1163,
    acronym: 'WVUAP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wheaton.edu',
    name: 'Wheaton College',
    web_page: 'http://www.wheaton.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT_F88TdhqsxwOJKp1Uk2UMhBd9PlW3OC59cX6-8OjgXBbKQzpvCQC8g',
    id: 1164,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wheatonma.edu',
    name: 'Wheaton College Norton MA',
    web_page: 'http://www.wheatonma.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT_F88TdhqsxwOJKp1Uk2UMhBd9PlW3OC59cX6-8OjgXBbKQzpvCQC8g',
    id: 1165,
    acronym: 'WCNM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wjc.edu',
    name: 'Wheeling Jesuit College',
    web_page: 'http://www.wjc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH2n54vxMSFKoYqB6bDcsZ8Vv-byEqTy7LN16rDdQHHts3r27Luk1Nbw',
    id: 1166,
    acronym: 'WJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'whitman.edu',
    name: 'Whitman College',
    web_page: 'http://www.whitman.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqoykNB39sDazjVRrjTSed9okEJGLhx5ofAD_zR28hHgPALnrC0KMHkJs',
    id: 1167,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'whittier.edu',
    name: 'Whittier College',
    web_page: 'http://www.whittier.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT55ZepcjcDWVKSQE0UvoGsLjHENjcV5lYP-_zrw5969HM3Q5LTgETijqg',
    id: 1168,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'whitworth.edu',
    name: 'Whitworth College',
    web_page: 'http://www.whitworth.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdPCnWS6yc1VU2H8nknp89PvT2ZfJ-UmlPP-9NWXz0PkaPFLetV3uqWnY',
    id: 1169,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wichita.edu',
    name: 'Wichita State University',
    web_page: 'http://www.wichita.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4gprBQE8304wDFOhX6VkQLtsEDH48t0U6HZaZSEBPzMoOy29cKd5NZts',
    id: 1170,
    acronym: 'WSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'widener.edu',
    name: 'Widener University',
    web_page: 'http://www.widener.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdLHQJ2K1tdNkjKtDOgFPcUwq6wLtldFeI_mXwDcmHy7KKSmjCNok4iAu0',
    id: 1172,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wilberforce.edu',
    name: 'Wilberforce University',
    web_page: 'http://www.wilberforce.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9myVxuS-WrvVxN9jlP4o94Yus6FAnEy0zPptgXh1MZBJn384a6BKSxQ',
    id: 1173,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wilkes.edu',
    name: 'Wilkes University',
    web_page: 'http://www.wilkes.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-UE7b_naaWdaxeBOV8A76iMO-XMaUQ_xdjs671SRV-7FkXhVMAJ2xeA-Q',
    id: 1174,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'willamette.edu',
    name: 'Willamette University',
    web_page: 'http://www.willamette.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQmC9Jj5x8j2iuJapQX0lsb90lQJWWp_UEjZRWCLkPQUoDrNZCfq6FYw',
    id: 1175,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'taftu.edu',
    name: 'William Howard Taft University',
    web_page: 'http://www.taftu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeWjzGKCq3ZVMe9EHc3eTtcSt-5kKSe12gHFg6uPVXySLRibypYUve0g',
    id: 1176,
    acronym: 'WHTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jewell.edu',
    name: 'William Jewell College',
    web_page: 'http://www.jewell.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9-LWjkUrW_PpnG67SfRCZJ3-YEBmppH0xBb9ptihiOoT1l8nGd7Xvlw',
    id: 1177,
    acronym: 'WJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wmitchell.edu',
    name: 'William Mitchell College of Law',
    web_page: 'http://www.wmitchell.edu/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQ9FaIdwrlZWpR3w59pVhSbyscS98X7A4NF_0W9wVpTiJfIy6ZbpYWHSw',
    id: 1178,
    acronym: 'WMCL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wpunj.edu',
    name: 'William Paterson University',
    web_page: 'http://www.wpunj.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr97RoZx--EYKN6oz9omUgKzNkNy7Lrg394vmPk0OFXVxhCOBt6QVpqw',
    id: 1179,
    acronym: 'WPU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wmpenn.edu',
    name: 'William Penn College',
    web_page: 'http://www.wmpenn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLKG_VZ-8KhwtE75Vh6u484wi7xhYbUfErVHmGSKCa3u4cLy-Yd8reZxM',
    id: 1180,
    acronym: 'WPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'williams.edu',
    name: 'Williams College',
    web_page: 'http://www.williams.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh5g0UdSH7ydXaTs1DiwW__Cwd2ubZUfD844UrDX8-QispFrLwZt-tNA',
    id: 1181,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wmwoods.edu',
    name: 'William Woods University',
    web_page: 'http://www.wmwoods.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTu6UizEa73oJtuPfG_Vi6vw-Zzmzg7si-shz9yV2pL9BC1gaxNfquSLg',
    id: 1182,
    acronym: 'WWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wilmington.edu',
    name: 'Wilmington College',
    web_page: 'http://www.wilmington.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLqgjlXAWVjAP8B6_b8H5ZK4jBKhCta0v6yf4Idv7J6IXZL00feSA35w',
    id: 1183,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'winona.msus.edu',
    name: 'Winona State University',
    web_page: 'http://www.winona.msus.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS39M9Hl3qDDYI23AJzFW7QrgGmMhu_DBGmrsv98hRYaFRGcdBf4qMTxqZi',
    id: 1184,
    acronym: 'WSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'winthrop.edu',
    name: 'Winthrop University',
    web_page: 'http://www.winthrop.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3T-S5kgQYNEb4fzwTHMYbT_avNhshpfgwkH5tbJv0bXooBlGpWTJqVjQ',
    id: 1185,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wittenberg.edu',
    name: 'Wittenberg University',
    web_page: 'http://www.wittenberg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpaj-DaxJ84ovxcaEw_l56Q91lBnlTO52lioGSmitq31qjsHYK3udzQA',
    id: 1186,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wofford.edu',
    name: 'Wofford College',
    web_page: 'http://www.wofford.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15909college.jpg',
    id: 1187,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'woodbury.edu',
    name: 'Woodbury University',
    web_page: 'http://www.woodbury.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqYPXjroh1vDfEP3f_TcTEUGvGKxr6W_GP1gjSf012qX-mTAJV9pK1cVo',
    id: 1188,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wpi.edu',
    name: 'Worcester Polytechnic Institute',
    web_page: 'http://www.wpi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPh3uHKWpcbhf8KwjVeYqCUqQerHhYcg4_b0-nuEVxl4x96V1CRdE8RQ',
    id: 1189,
    acronym: 'WPI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wright.edu',
    name: 'Wright State University',
    web_page: 'http://www.wright.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxm31wFuqbk8IQjvPl_AiL6bMN8UR-Bjx91v0XIi_oY2S2z2vvcxOomCs',
    id: 1190,
    acronym: 'WSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'xula.edu',
    name: 'Xavier University of Louisiana',
    web_page: 'http://www.xula.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3LX2Cy1606XP3eKrA_Vqnhlf0RYKv92M4EsSjrUwXSrbAu1W3xbOaWg8u',
    id: 1191,
    acronym: 'XUL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'yale.edu',
    name: 'Yale University',
    web_page: 'http://www.yale.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15916college.jpg',
    id: 1192,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'yu.edu',
    name: 'Yeshiva University',
    web_page: 'http://www.yu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1CMIhKcgFJb0bPOr6J8H8-Yr9vWDPvVwhiLGyiULFGYtIjPGQwHW6HA',
    id: 1193,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ycp.edu',
    name: 'York College of Pennsylvania',
    web_page: 'http://www.ycp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvBB380uIjj4LkKWUesiuTgOk607EIjJ-El7aOImfx56q2ePsn-KlI1w',
    id: 1194,
    acronym: 'YCP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ysu.edu',
    name: 'Youngstown State University',
    web_page: 'http://www.ysu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15922college.jpg',
    id: 1195,
    acronym: 'YSU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'afghanuniversity.edu.af',
    name: 'Afghan University',
    web_page: 'http://www.afghanuniversity.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnPH4NGY-1T9LmU3r2zEMIp6wPl-Di8SN-R7yrbMy67X2OcglVgzWm1Q',
    id: 1196,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'aria.edu.af',
    name: 'Aria Institute of Higher Education',
    web_page: 'http://www.aria.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1197,
    acronym: 'AIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'auaf.edu.af',
    name: 'American University of Afghanistan',
    web_page: 'http://www.auaf.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvdOmsBMx1djNouJptuiluBxyl63Ej8z0NmLxuEGNaQ5veWJvpK2DPDg',
    id: 1198,
    acronym: 'AUA',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'au.edu.af',
    name: 'Al-Birony University',
    web_page: 'http://www.au.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROsK-eTVIV2b1wkwx6ckcTkfgesEz0E8blHcVVp6hev_adKY4MgIuW5UY',
    id: 1199,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'badakhshan.edu.af',
    name: 'Badakhshan University',
    web_page: 'http://www.badakhshan.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKR1gw7D7v8vvsfULt4EwN3SPqbkCMMqyfaMUR3otXnHg45LlFlQ7OqBU',
    id: 1200,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'ba.edu.af',
    name: 'Balkh University',
    web_page: 'http://www.ba.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoHGx9HW4qWz3YXFpnMCtA6uhZO9O2KNXtbVXRIwFD_0ZSa2ePQhwzrw',
    id: 1201,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'baghlan.edu.af',
    name: 'Baghlan University',
    web_page: 'http://www.baghlan.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm0qL0pdLuExcO74nmgl5wiymc88qDxxeG3bwm-Ii46mP1vC1Mc3MfeGid',
    id: 1202,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'bakhtar.edu.af',
    name: 'Bakhtar University',
    web_page: 'http://www.bakhtar.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcqY5urnf8vxUuaRx28iwXH7Xk9NDeWzAcj6S9-kv4US61NlxTkN8PDA',
    id: 1203,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'bost.edu.af',
    name: 'Bost University',
    web_page: 'http://www.bost.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQR5oqF-C8tD0g1V8bWKYo7oqotQcuk-P1yy4vABKaYO4VIWw6dtPNJht4',
    id: 1204,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'bu.edu.af',
    name: 'Bamiyan University',
    web_page: 'http://www.bu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSnYEUSHVeS64MIdBPQcRD8w3zcbz5wLAqFqW-Z7GiIXflEKbPgzMV6dQ',
    id: 1205,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'dawat.edu.af',
    name: 'Dawat University',
    web_page: 'http://www.dawat.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGNd5xff2I0a93H9ueFdkxyxnFOI7rwzpqxAQrCviiljKzbLIqP6WTpSU',
    id: 1206,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'dunya.edu.af',
    name: 'Dunya Institute of Higher Education',
    web_page: 'http://www.dunya.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnbLe3E99-LPI8MjonC8O8FwnBxY1FBL5WbrEmelep4E3uCSvHeZzpPg',
    id: 1207,
    acronym: 'DIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'faryab.edu.af',
    name: 'Faryab Higher Education Institute',
    web_page: 'http://www.faryab.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7uMBthGl3hfZFBegaT4HP2nfj8iyQVr20wqv9bOywTjvUxs4ZRWepuPQ',
    id: 1208,
    acronym: 'FHEI',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'gu.edu.af',
    name: 'Ghazni University',
    web_page: 'http://www.gu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRwlFhJk7hcdZYW0qcmkSXyWiK-ZrBdft08eAqpC_vVLNTHhkOmuNd7M4',
    id: 1209,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'hu.edu.af',
    name: 'Herat University',
    web_page: 'http://www.hu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUDqx5_6Wq0rpm3LDll2WCgM_KqHUPI0JixcxBTgmRzW7s1PecOxwRMg',
    id: 1210,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'ibnesina.edu.af',
    name: 'Ibn Sina University',
    web_page: 'http://www.ibnesina.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm6NFEhyRIYSjCscW6PiQxE2xrACFP8Hh3nNyBflGoZd1ZNsJenVRs0gPt',
    id: 1211,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'ju.edu.af',
    name: 'Jawzjan University',
    web_page: 'http://www.ju.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4hR8iyh7SPCjuTcJbHJWks-YEn0GCdz-pFUAlRDwPqvLzh_46pxrBw1ItBg',
    id: 1212,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'kaboora.edu.af',
    name: 'Kaboora Institute of Higher Education',
    web_page: 'http://www.kaboora.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLpKje8jTOWpqZQdhO7f6zqX27s3KBm8-yIEbL0IZZHqhAr-fpvjbGeA',
    id: 1213,
    acronym: 'KIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'kabuli.edu.af',
    name: 'Kabul Health Sciences Institute',
    web_page: 'http://www.kabuli.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJLJ9PI9VsmhWKxoMPxyn5uS09JacG3-7lN-mAD2OXUZHy0jrzYgtd_w8',
    id: 1214,
    acronym: 'KHSI',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'kan.edu.af',
    name: 'Kandahar University',
    web_page: 'http://www.kan.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDfEziWvo3xGJ1WPX1gLv2xkB7LxSAsjBTlRsIjwvJ5cEAn5bUZ21jYm0',
    id: 1215,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'kardan.edu.af',
    name: 'Kardan University',
    web_page: 'http://www.kardan.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz6NN0HdsfSgfE0BB9kJw-E71GfMCfzfUTOZWgwNyQyBT_hSo-7wL-4GM',
    id: 1216,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'karwan.edu.af',
    name: 'Karwan Institute of Higher Education',
    web_page: 'http://www.karwan.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1217,
    acronym: 'KIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'kateb.edu.af',
    name: 'Kateb Institute of Higher Education',
    web_page: 'http://www.kateb.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-9EaG8RT9kK5sBrMu17TVP9kbbEE5B4g4YspJHzVeqQQ8qVl9wbeVeiE',
    id: 1218,
    acronym: 'KIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'ken.edu.af',
    name: 'Khana-e-Noor Institute of Higher Education',
    web_page: 'http://www.ken.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkLM4k0ZrEH9O4HxlddkH0XvPRa_eXlDJ1mcm3jT7PY3lTD4HtbXMmlE',
    id: 1219,
    acronym: 'KIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'keu.edu.af',
    name: 'Kabul Education University',
    web_page: 'http://www.keu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz6NN0HdsfSgfE0BB9kJw-E71GfMCfzfUTOZWgwNyQyBT_hSo-7wL-4GM',
    id: 1220,
    acronym: 'KEU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'kmu.edu.af',
    name: 'Kabul Medical University',
    web_page: 'http://www.kmu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaMvQ8UAT0hQJgvwwr24e0F__olVWU6Y5TBQzfYs8WxU3qfeJwmoEjojM',
    id: 1221,
    acronym: 'KMU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'ku.edu.af',
    name: 'Kabul University',
    web_page: 'http://www.ku.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM6wYK7aDcgOc1tRBaYK6vRPoq42beIRRlX5xQrpM8s9wApf1XoP0Utg',
    id: 1222,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'kumsa.net',
    name: 'Khurasan University',
    web_page: 'http://www.kumsa.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR49BLPjmiWMSU5AxE8WYt5pUArOTrnBo3ioGe_2sjuhVN4_A2BmibhkQ',
    id: 1223,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'maryam.edu.af',
    name: 'Maryam Institute of Higher Education',
    web_page: 'http://www.maryam.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREeFa_M92wwvUT5BMmzHD6YRlBsnjDAZXf3H2rP-8pz5vgpddBsYx7hQ',
    id: 1224,
    acronym: 'MIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'nmaa.edu.af',
    name: 'National Military Academy of Afghanistan',
    web_page: 'http://www.nmaa.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5EuxnWx6BpNSRmzTGIx7uUFCs2tz-OQtGtaXMa392MUSq3DFvpnvaFH2X',
    id: 1225,
    acronym: 'NMAA',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'nu.edu.af',
    name: 'Nangarhar University',
    web_page: 'http://www.nu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8KusqNqW6wG5B74y2z5y_zsO54ZHXYKoVsSFGGVM2hcF2ub4fKgHogUas',
    id: 1226,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'pamir.edu.af',
    name: 'Pamir University',
    web_page: 'http://www.pamir.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1227,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'parwan.edu.af',
    name: 'Parwan University',
    web_page: 'http://www.parwan.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdQf52y_zAUQWkefVeGpUcZTCliHfHSTKm696xcNHE6Z2Hp6AjmdW_912q',
    id: 1228,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'polytechnic-kabul.org',
    name: 'Polytechnical University of Kabul',
    web_page: 'http://www.polytechnic-kabul.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcqY5urnf8vxUuaRx28iwXH7Xk9NDeWzAcj6S9-kv4US61NlxTkN8PDA',
    id: 1229,
    acronym: 'PUK',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'pu.edu.af',
    name: 'Paktia University',
    web_page: 'http://www.pu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsM7azvOnIE-4OUwLS-0QHD3Lhc7DMsBygHVAVQ2xfVK8OPxy8qaSEuQ',
    id: 1230,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'rihs.edu.af',
    name: 'Rana Institute of Higher Education',
    web_page: 'http://www.rihs.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzNoyNKp5qtBNJW2rHurARZd-GAA1vA6E04utoLKQ9K0RwhFcgGFHGpQ',
    id: 1231,
    acronym: 'RIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'sadat.edu.af',
    name: 'Sadat Institute of Higher Education',
    web_page: 'http://www.sadat.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAzL9Ai1cPA5Np771Dq05xwIZDxWI7AHUw9sbF9R0JOjcLCdgn8kUYgEk',
    id: 1232,
    acronym: 'SIHE',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'salam.edu.af',
    name: 'Salam University',
    web_page: 'http://www.salam.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj51VPrn-m8LSB-15WgJPyaQvXVwtsS4hUtyJSIrb_pHt0G3cKn1kwpyg',
    id: 1233,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'szu.edu.af',
    name: 'Shaikh Zayed University',
    web_page: 'http://www.szu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3oEwRAmGyxUk6O278fS-kcazkCkviOqer9jz6z4sXozzEzYdD7M6EEQ',
    id: 1234,
    acronym: 'SZU',
  },
  {
    alpha_two_code: 'AF',
    country: 'Afghanistan',
    domain: 'tu.edu.af',
    name: 'Takhar University',
    web_page: 'http://www.tu.edu.af/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1235,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'aefs.edu.al',
    name: 'Academy of Sports and Physical Training',
    web_page: 'http://www.aefs.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDvUlaNmz-5LemYpzSmtERM15xN38ccqt5dpvHgeHqvSvBTlKDKpVwBw',
    id: 1236,
    acronym: 'ASPT',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'akademiaearteve.edu.al',
    name: 'Academy of Arts',
    web_page: 'http://www.akademiaearteve.edu.al/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTJoLvdHslnKNExHdVx7RhnU3JVFaSAnEmC1TlqI_g4x5et-8dhGzMQgA',
    id: 1237,
    acronym: 'AA',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'beder.edu.al',
    name: 'Beder University',
    web_page: 'http://www.beder.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIP5ByMM-so8RnKz5zHPB775AMI7zPUwz2SbKwiyU1d5xwqdcmwzEvK9M',
    id: 1238,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'epoka.edu.al',
    name: 'Epoka University',
    web_page: 'http://www.epoka.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ51bTs6PoBhmsWSfxTRZdjJTqoMIGyX7LeGoJxXs_vVZ_euaC0BzD0j4p',
    id: 1239,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'uat.edu.al',
    name: 'American University of Tirana',
    web_page: 'http://www.uat.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd7vXymL523T2DiHjW43YijeojhqWUs_rk7BhTNwNtKFyH2RVmXghtC24',
    id: 1240,
    acronym: 'AUT',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'ubt.edu.al',
    name: 'Agricultural University of Tirane',
    web_page: 'http://www.ubt.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUfA3CuGDjywtHciXxCaFTJidy9EPhhsi2CIWG53qarVfnWW90IhBKTiU',
    id: 1241,
    acronym: 'AUT',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'uniel.edu.al',
    name: 'University of Elbasan "Aleksander Xhuvani"',
    web_page: 'http://www.uniel.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAE_wASRYLDpMq0f0q8wnmI5dtfng0B6V89n-RIEanarH_MXjlu6BMWw',
    id: 1242,
    acronym: 'UE"X',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'unipavaresia.edu.al',
    name: 'University “Pavaresia” Vlore',
    web_page: 'http://www.unipavaresia.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJaYyOOVo1bGOC3j8lhu9feU0FEz99CQuP213Lfp2HXa6nbQiylySMpo',
    id: 1243,
    acronym: 'U“V',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'unishk.edu.al',
    name: 'University of Shkodra "Luigj Gurakuqi"',
    web_page: 'http://www.unishk.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlnTvLkte4voz_iohyKV-kgajLxCbeU5igxAWMH2LUgUWV85cPH43arw',
    id: 1244,
    acronym: 'US"G',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'unitir.edu.al',
    name: 'University of Tirana',
    web_page: 'http://www.unitir.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwPkO1SP6o9H4QtOFAH520mOtqvEx75RIVwouvtTLEdrMIaTFuBTRSQA',
    id: 1245,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'univlora.edu.al',
    name: 'University of Vlora "Ismail Qemali"',
    web_page: 'http://www.univlora.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm19gZrhdHUcv6o0OVrz6Ds2fmO3C9tgzsd4cOJqmKqd9nKSckEdCuoPDX',
    id: 1246,
    acronym: 'UV"Q',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'unkorce.edu.al',
    name: 'University of Korca "Fan Noli"',
    web_page: 'http://www.unkorce.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlnTvLkte4voz_iohyKV-kgajLxCbeU5igxAWMH2LUgUWV85cPH43arw',
    id: 1247,
    acronym: 'UK"N',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'unyt.edu.al',
    name: 'University of New York Tirana',
    web_page: 'http://www.unyt.edu.al/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSM4F4td1hiy0Nnz6Fa8rEOAZdemo6p7U15ivENPj889yVI3gZMZnE6vA',
    id: 1248,
    acronym: 'UNYT',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'uogj.edu.al',
    name: 'University of Gjirokstra "Eqerem Cabej"',
    web_page: 'http://www.uogj.edu.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS1ZfEDEbFHVHEzRxTY5OSGf7g0O6ujBlx0Xjxywi8WZbVgDdXE3LphOzN',
    id: 1249,
    acronym: 'UG"C',
  },
  {
    alpha_two_code: 'AL',
    country: 'Albania',
    domain: 'upt.al',
    name: 'Polytechnic University of Tirane',
    web_page: 'http://www.upt.al/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZRJyWa_7vtmWINnvdJVCce_8mfVGXc-ca3wVasgSycqQmVYWcFoMyL_o',
    id: 1250,
    acronym: 'PUT',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'lagh-univ.dz',
    name: 'Université Amar Telidji',
    web_page: 'http://www.lagh-univ.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxHwm9y-o_bJ4WfyJnWbZ_3csWwF0e7n29Wk_39oeNqANd5OYrQ7fDqw',
    id: 1251,
    acronym: 'UAT',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'umbb.dz',
    name: "Université M'hamed Bouguerra de Boumerdes",
    web_page: 'http://www.umbb.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSOXK3EPB1cr2A8c56vxn3LPU8_tB-H8QE5PwCjunF1DJtQuu7kpQzEEbC',
    id: 1252,
    acronym: 'UMBDB',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'umc.edu.dz',
    name: 'Université Mentouri Constantine',
    web_page: 'http://www.umc.edu.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEzkl9id_IftYJAASoAqfjiHgAy1DvYkUF6m9L2e9YucXrxKrhD9A68yU',
    id: 1253,
    acronym: 'UMC',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'ummto.dz',
    name: 'Université Mouloud Mammeri de Tizi Ouzou',
    web_page: 'http://www.ummto.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo3Gfh6bnouQ_TgNQMrKlapVwRL1wT3TZ39fbM75Ehn8Aidh_f0Q-OuH4',
    id: 1254,
    acronym: 'UMMDTO',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-alger3.dz',
    name: "Université d'Alger 3",
    web_page: 'http://www.univ-alger3.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSySeH5VemtlXuFR5eOFdLV6MlByS9DQCiFMCaBC1BwajMoSBYgmgta-w',
    id: 1255,
    acronym: 'UD3',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-alger.dz',
    name: "Université d'Alger",
    web_page: 'http://www.univ-alger.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSySeH5VemtlXuFR5eOFdLV6MlByS9DQCiFMCaBC1BwajMoSBYgmgta-w',
    id: 1256,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-annaba.dz',
    name: 'Université Badji Mokhtar',
    web_page: 'http://www.univ-annaba.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQs7VKx4B-IzhGuVj3EfuuGAAI9HzVTc4bqJv6DBW3E0s91Vg3tzEur_90',
    id: 1257,
    acronym: 'UBM',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-batna.dz',
    name: 'Université de Batna',
    web_page: 'http://www.univ-batna.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH3zl-GlOWbvN6qWEHbw9YVsWuxpdDnw682xOElZoo1gr8Gkuxy_jrSg',
    id: 1258,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-bejaia.dz',
    name: 'Université de Béjaia',
    web_page: 'http://www.univ-bejaia.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRarFV2FFHr2pIsXayk9caeiKgLXHZOL37y9e1syhJdhpItGgHxYVeuG2Tk',
    id: 1259,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-biskra.dz',
    name: 'Université de Biskra',
    web_page: 'http://www.univ-biskra.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeafABGUCeQ-1i2BOUcllXixTe9k6EJV8p9XIv99x06k3YZYUsSsDIilc',
    id: 1260,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-blida.dz',
    name: 'Université de Blida',
    web_page: 'http://www.univ-blida.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt5YIq6WJmFixMXovPpZDkahFhF2qMslQtFhv3Wbjl6NwXnAeR8QnxXA',
    id: 1261,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-chlef.dz',
    name: 'Université de Chlef',
    web_page: 'http://www.univ-chlef.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7BICmMJNz-EtiGUNHrY7xGa1dsQuUVt4FDqdYr7IVmXHjpHFhjC_pjtAU',
    id: 1262,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-emir.dz',
    name: 'Universite des Sciences Islamiques de Constantine',
    web_page: 'http://www.univ-emir.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQshdkQQKGS5_gfI-bZ3GTqW2UyLPFhCT588WytZXQuZBe8lztpHhHyu53i',
    id: 1263,
    acronym: 'UDSIDC',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-guelma.dz',
    name: 'Université de Guelma',
    web_page: 'http://www.univ-guelma.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfF1BlnhNJERU1g1_qgL1O3Eo7MHYt-SfXketKZUH4um2RtXk0Y2ftTzo',
    id: 1264,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-jijel.dz',
    name: 'Centre Universitaire de Jijel',
    web_page: 'http://www.univ-jijel.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUc6JGc902B1H0eK66NcngMNpdSVRE0NKKvwTvqNod3wpMJQNMTqpnlA',
    id: 1265,
    acronym: 'CUDJ',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-mosta.dz',
    name: 'Université de Mostaganem',
    web_page: 'http://www.univ-mosta.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMuvclp9K9DX6sUnwrgq_JH_agmBj7LAjlA_MmKUyNwJ6tdj11U_Uyzs8',
    id: 1266,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-msila.dz',
    name: "Université de M'sila",
    web_page: 'http://www.univ-msila.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn86Sq6GCFIbVxsXNcLbwG05RMJ9GaFfYiGn0Zhooq2xDpK_qwmRWLxzit2A',
    id: 1267,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-oeb.dz',
    name: "Centre Universitaire d'Oum El Bouaghi",
    web_page: 'http://www.univ-oeb.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUgvQG7_UyuVw13t0AVOH5lGeTfx2mUaihxEdDmeTw7SjTFQHPcksLcwI',
    id: 1268,
    acronym: 'CUDEB',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-oran.dz',
    name: "Université d'Oran Es-Senia",
    web_page: 'http://www.univ-oran.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoVKZNmTJNzZsNntJDMDuxGPTGRDwfMgnO9MIJrRF0dfkN40Lt4fvTEuA',
    id: 1269,
    acronym: 'UDE',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-ouargla.dz',
    name: 'Université Kasdi Merbah Ouargla',
    web_page: 'http://www.univ-ouargla.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAryKIfWejfJ4XKqiGa0QkBsM1y6DleDME7_h4_LyT90TJsMXcPRhOKiw',
    id: 1270,
    acronym: 'UKMO',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-saida.dz',
    name: 'Université de Saida',
    web_page: 'http://www.univ-saida.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTv31u_9pVdK2rqslRaTjHx1SpmjpDdf6rnDTrosE8E9aKMw6X7uiMVAI',
    id: 1271,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-sba.dz',
    name: 'Université de Sidi-Bel-Abbès (Djillali Liabès)',
    web_page: 'http://www.univ-sba.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY3WSDQGiO4S8oHIQAOVS3zJL95niaCn1aL-2G1pTQKvmQQjifJ-Ockg',
    id: 1272,
    acronym: 'UDS(L',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-setif.dz',
    name: 'Université de Sétif',
    web_page: 'http://www.univ-setif.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLVphwe5tKGDRyJDLiLNPssQG8h4-bjL_bbLrFgbJlS1kmE67FLM5vd6g',
    id: 1273,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-skikda.dz',
    name: 'Université de Skikda',
    web_page: 'http://www.univ-skikda.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4r7ps-AjVTHXX48qoQUjJuabmGGnwJDBJHWgb7XRIRLi7SpT59TuHNQ',
    id: 1274,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-tebessa.dz',
    name: 'Centre Universitaire de Tebessa',
    web_page: 'http://www.univ-tebessa.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1275,
    acronym: 'CUDT',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-tiaret.dz',
    name: 'Centre Universitaire de Tiaret',
    web_page: 'http://www.univ-tiaret.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1276,
    acronym: 'CUDT',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-tlemcen.dz',
    name: 'Université Abou Bekr Belkaid Tlemcen',
    web_page: 'http://www.univ-tlemcen.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuS_Ah5yyYXbjy26vgnxtHLqLhm2jvbwdjZh994kDV_NDdJshWLxawOw',
    id: 1277,
    acronym: 'UABBT',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'univ-usto.dz',
    name: "Université des Sciences et de la Technologie d'Oran",
    web_page: 'http://www.univ-usto.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStOgd1OB6PUNOjFDa3DD-NKoVQ4x29bufOIQi7ZHDuB39yM6QyNxB_c24',
    id: 1278,
    acronym: 'UDSEDLTD',
  },
  {
    alpha_two_code: 'DZ',
    country: 'Algeria',
    domain: 'usthb.dz',
    name: 'Université des Sciences et de la Technologie Houari Boumediène',
    web_page: 'http://www.usthb.dz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUJ5d0_U0NoNqEjltcR4-34Hbm9nD6neLP31wDQMWr5ONc9IIlh0R_ig',
    id: 1279,
    acronym: 'UDSEDLTHB',
  },
  {
    alpha_two_code: 'AD',
    country: 'Andorra',
    domain: 'uda.ad',
    name: 'University of Andorra',
    web_page: 'http://www.uda.ad/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHehbOWyuw2PWaoRJTLBaBsgpK0ensZyO_EpB7YY7MygAMY7qNMw_UOw',
    id: 1280,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'AO',
    country: 'Angola',
    domain: 'uan.ao',
    name: 'Univesidade Agostinho Neto',
    web_page: 'http://www.uan.ao/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-Rt29MS0CzPnLd8vpy_FF_yk84Wc6Iu81s3eiO4CCXqwvbWEx-6lAEIk',
    id: 1281,
    acronym: 'UAN',
  },
  {
    alpha_two_code: 'AO',
    country: 'Angola',
    domain: 'ucan.edu',
    name: 'Universidade Católica de Angola',
    web_page: 'http://www.ucan.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoALFyCwYfaNaVBC_5hhLPZN8Xcy7p2pArZuJUUB6Nru4YRlTPW0Bcg1g',
    id: 1282,
    acronym: 'UCDA',
  },
  {
    alpha_two_code: 'AO',
    country: 'Angola',
    domain: 'ugs.ed.ao',
    name: 'Universidade Gregorio Semedo',
    web_page: 'http://www.ugs.ed.ao/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfZmrfzygcHfgho3rUMVSthxbe5qgDiXsNgPJM669TivQloyc1IrgedDU',
    id: 1283,
    acronym: 'UGS',
  },
  {
    alpha_two_code: 'AO',
    country: 'Angola',
    domain: 'ulangola.net',
    name: 'Universidade Lusíada de Angola',
    web_page: 'http://www.ulangola.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgzCGur6STFUVPfXlq_xuDqt8J13XH8Ry5WEc4coNPWiQDTxW4scK0ghn7',
    id: 1284,
    acronym: 'ULDA',
  },
  {
    alpha_two_code: 'AO',
    country: 'Angola',
    domain: 'uma.co.ao',
    name: 'Universidade Metodista de Angola',
    web_page: 'http://www.uma.co.ao/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRacE1YDjA0bFh7XFifJadoCnruCsVYOsKPp0ZyFPvjpAUYZIbE0xGvJRrL',
    id: 1285,
    acronym: 'UMDA',
  },
  {
    alpha_two_code: 'AO',
    country: 'Angola',
    domain: 'unia.ao',
    name: 'Universidade Independente de Angola',
    web_page: 'http://www.unia.ao/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwkZGpqNVafume2xraPDc3A6JoP8RlS0aFGS-Kz6FuVQEgUiP5fjNrDg',
    id: 1286,
    acronym: 'UIDA',
  },
  {
    alpha_two_code: 'AO',
    country: 'Angola',
    domain: 'unimetroangola.com',
    name: 'Universidade Metropolitana de Angola',
    web_page: 'http://www.unimetroangola.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQltB01i0gfaaAA7gg4yS4rXA73ysYj668-qk6Dng7v-7rH-CI037vgxhc',
    id: 1287,
    acronym: 'UMDA',
  },
  {
    alpha_two_code: 'AO',
    country: 'Angola',
    domain: 'utanga.co.ao',
    name: 'Universidade Tecnica de Angola',
    web_page: 'http://www.utanga.co.ao/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwLPF-lSPrxo4MefFf9B6fSpVvq4PXzxUIqBktGYvxcqjG9HScMF1PnA',
    id: 1288,
    acronym: 'UTDA',
  },
  {
    alpha_two_code: 'AG',
    country: 'Antigua and Barbuda',
    domain: 'auamed.org',
    name: 'American University of Antigua',
    web_page: 'http://www.auamed.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1hDhH5dZqRDDoB7I_mjsWlZSVoQnZqgjlOU78S808M0XWhmvOZ8EQmA',
    id: 1289,
    acronym: 'AUA',
  },
  {
    alpha_two_code: 'AG',
    country: 'Antigua and Barbuda',
    domain: 'uhsa.ag',
    name: 'University of Health Sciences Antigua',
    web_page: 'http://www.uhsa.ag/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcqT6Tarusd2quYxV83gC7ndCZPDRq7wq-unepFTS9RchHvjnXhZmdXiE',
    id: 1290,
    acronym: 'UHSA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'atlantida.edu.ar',
    name: 'Universidad Atlantida Argentina',
    web_page: 'http://www.atlantida.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBULRcZci5eRXnw66bMrW9Wc8DoIHMh4z8iEVbVkW5XaSxuTUyLJ_fXGM',
    id: 1291,
    acronym: 'UAA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'austral.edu.ar',
    name: 'Universidad Austral Buenos Aires',
    web_page: 'http://www.austral.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCxFyKhjCQOmO6fMokun1z_Otd1DirHsBslPDUGuhJqJAb0AbhPpHpV3U',
    id: 1292,
    acronym: 'UABA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'caece.edu.ar',
    name: 'Universidad CAECE Buenos Aires',
    web_page: 'http://www.caece.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvMzC90bqVwLBHdVOkA7AtwsmHTgpcoorBk8LhR0-rleQBH4HYhaHU5eM',
    id: 1293,
    acronym: 'UCBA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'cema.edu.ar',
    name: 'Instituto Universitario CEMA',
    web_page: 'http://www.cema.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1294,
    acronym: 'IUC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'iese.edu.ar',
    name: 'Instituto de Enseñanza Superior del Ejército',
    web_page: 'http://www.iese.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfwJeLC1AKaYNRPuluVl5Ft7eLhsOIaMcEAiUH73M-jRCx7FlUSfsfFw',
    id: 1295,
    acronym: 'IDESDE',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'inun.edu.ar',
    name: 'Instituto Universitario de Estudios Navales y Marítimos',
    web_page: 'http://www.inun.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaiLCCuxxeQAkaIXgIDxtY_Zdb675rEGPBsM71yoynWDHIbvMq3szyZHM',
    id: 1296,
    acronym: 'IUDENYM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'itba.edu.ar',
    name: 'Instituto Tecnológico de Buenos Aires',
    web_page: 'http://www.itba.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS72tneeLiMDZ5-5P9OSacu5DQ7uZ5IVY3YWuBPR9VGHAFsEo46KYht1w',
    id: 1297,
    acronym: 'ITDBA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'iua.edu.ar',
    name: 'Instituto Universitario Aeronáutico',
    web_page: 'http://www.iua.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu8Rjs0Bwxz582oGavHfhKFTsN7eeJAOjmVLr_Ts7KIF7gfFPVpmHCJw',
    id: 1298,
    acronym: 'IUA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'iucs.uncoma.edu.ar',
    name: 'Instituto Universitario de Ciencias de la Salud - Buenos Aires',
    web_page: 'http://iucs.uncoma.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfwJeLC1AKaYNRPuluVl5Ft7eLhsOIaMcEAiUH73M-jRCx7FlUSfsfFw',
    id: 1299,
    acronym: 'IUDCDLS-BA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'iuna.edu.ar',
    name: 'Instituto Universitario Nacional del Arte',
    web_page: 'http://www.iuna.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3DQnWpYnubqQpWK7u2AvTpSluD0G3ZUHWFgSbc7efhZbgZnTVV53yGSM',
    id: 1300,
    acronym: 'IUNDA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'kennedy.edu.ar',
    name: 'Universidad Argentina "John F. Kennedy"',
    web_page: 'http://www.kennedy.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRA-JaohwHGgCam-fnAHZvcX1r1L3ohzEa2WpEoz_lNbRvc4DamzrJqxQ',
    id: 1301,
    acronym: 'UA"FK',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'maimonides.edu.ar',
    name: 'Universidad Maimónides',
    web_page: 'http://www.maimonides.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBK2k0DQACHA5MVCAtFmZop3Gnk1-PC8cRdKSPow_2-rUIJUPJoZurvtY',
    id: 1302,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'mdp.edu.ar',
    name: 'Universidad Nacional de Mar del Plata',
    web_page: 'http://www.mdp.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRAiZK6n0m-zq63YqrS08CFvah7L_45_7uqPV2K6xBmlvPnYj1mhcWzw',
    id: 1303,
    acronym: 'UNDMDP',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'palermo.edu.ar',
    name: 'Universidad de Palermo',
    web_page: 'http://www.palermo.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFTXtznllH5rh4NKs1s0wKQ5wzkxqkDwF616CARQIA-NcNkPoBAUFYsw61',
    id: 1304,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'salvador.edu.ar',
    name: 'Universidad del Salvador',
    web_page: 'http://www.salvador.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQc-HPeU7xCKZM993ZSzTGm-N0fd83wTTyAqZY-0TR72wyKGHSpLY1L_4smA',
    id: 1305,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uade.edu.ar',
    name: 'Universidad Argentina de la Empresa',
    web_page: 'http://www.uade.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXsaAdzDz5tgjjveUhRgQhIbIBbtecW-C_qyPwVPGpueL3xn3i0rkMxvZ9',
    id: 1306,
    acronym: 'UADLE',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uader.edu.ar',
    name: 'Universidad Autónoma de Entre Ríos',
    web_page: 'http://www.uader.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSx6BsytiHR8N6AdWqaLfmvrkXl23cICe7EOUBdjF998D1grtc5utY-HpDj',
    id: 1307,
    acronym: 'UADER',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uapar.edu',
    name: 'Universidad Adventista del Plata',
    web_page: 'http://www.uapar.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ61DnHPjsP1ZAHTahta5SrhS85brq5Go6dq6k0-NKHTBQbtC9tfQOgFA',
    id: 1308,
    acronym: 'UADP',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uba.ar',
    name: 'Universidad de Buenos Aires',
    web_page: 'http://www.uba.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjep2z3KmF_TCFtfbmsMl5IyDB94w4m1hPIX1gPEOKdpLbObZ--BcPYdCl',
    id: 1309,
    acronym: 'UDBA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ub.edu.ar',
    name: 'Universidad de Belgrano',
    web_page: 'http://www.ub.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCcc3RG-8VNPjawB8HoZD8p8qWyCjsXQ4XHnGOB8md6l3C-HKyEZTEkQ',
    id: 1310,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ubp.edu.ar',
    name: 'Universidad Blas Pascal',
    web_page: 'http://www.ubp.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc0yGVRy8XfczctxOkdyiMkX3iUOf-3U1Ga4mKbNNyBTZLM9pwH0Wr7snh',
    id: 1311,
    acronym: 'UBP',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uca.edu.ar',
    name: 'Universidad Católica Argentina Santa Maria de los Buenos Aires',
    web_page: 'http://www.uca.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_hqVitemSVsMEvjEEQpAQonwzdV5t4st6tnfb5ifMdXqnOBoddWGC5g',
    id: 1312,
    acronym: 'UCASMDLBA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucalp.edu.ar',
    name: 'Universidad Católica de La Plata',
    web_page: 'http://www.ucalp.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdZW44sgX0k7955w5tjvdPmi2VjOFfA_mYjuaSTDjjz1ewSeaWZZBTmQ',
    id: 1313,
    acronym: 'UCDLP',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucasal.net',
    name: 'Universidad Católica de Salta',
    web_page: 'http://www.ucasal.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREuYGLYNbs6aydG9p2EAkVHFOs35uqJSTeeMqd-3jdeuPJ_nAsHiU0nL0',
    id: 1314,
    acronym: 'UCDS',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uccor.edu.ar',
    name: 'Universidad Católica de Córdoba',
    web_page: 'http://www.uccor.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0eh294zBCAk9w-dvoXD7TPKC1UI1FaVlbZRVK3GqRjtbCSpSSsf-0iSk',
    id: 1315,
    acronym: 'UCDC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uccuyo.edu.ar',
    name: 'Universidad Católica de Cuyo',
    web_page: 'http://www.uccuyo.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6QWSdjzpXdbgVCmFWHqTYooWgQnquI1icCR4HRmE2CwUxrX7-drELlg',
    id: 1316,
    acronym: 'UCDC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucel.edu.ar',
    name: 'Universidad del Centro Educativo Latinoamericano Rosario',
    web_page: 'http://www.ucel.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrnOju8BniSINRb8Ldj5KyvvWuY88m6GxOsIoL4eUpeJ1J_Z_uRefrq4w',
    id: 1317,
    acronym: 'UDCELR',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uces.edu.ar',
    name: 'Universidad de Ciencias Empresariales y Sociales',
    web_page: 'http://www.uces.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaYF9UOKA0SzFRzjF-3ETgBFR8SwCSuWRbX89JgPoCE7xQTkAdMSqbKhsL',
    id: 1318,
    acronym: 'UDCEYS',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uch.edu.ar',
    name: 'Universidad Champagnat',
    web_page: 'http://www.uch.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS29KxWooRlanjt7dqHQiq-bhMt-kJHfqgJXxG8kwv8ZnUfqKQ2U3ZxAmk',
    id: 1319,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucine.edu.ar',
    name: 'Universidad del Cine - Buenos Aires',
    web_page: 'http://www.ucine.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdwa_zuSHX-hVgPnbobrtp4zJ7u0rGCPVqSZa5C0ZeD4S8P4Pa9nOqsQ',
    id: 1320,
    acronym: 'UDC-BA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucongres.edu.ar',
    name: 'Universidad de Congreso',
    web_page: 'http://www.ucongres.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROjrdRSxWsJOvFbeC_FZV6EAN3H4HiDY4TaNoXp6FPIFiLVDQIAooBvfg',
    id: 1321,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucp.edu.ar',
    name: 'Universidad de la Cuenca del Plata',
    web_page: 'http://www.ucp.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0yLBO1Y-38JTmTa3hQhhul1CYRFA4GBirsJTN4tNKx7eb_NPAPXJsd_I',
    id: 1322,
    acronym: 'UDLCDP',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucse.edu.ar',
    name: 'Universidad Católica de Santiago del Estero',
    web_page: 'http://www.ucse.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTnT84P_mGqjBBkp4cKVthl8OBljFKuSdW-2rc-GvTrCNE8LFGzauoTEU',
    id: 1323,
    acronym: 'UCDSDE',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucsf.edu.ar',
    name: 'Universidad Católica de Santa Fé',
    web_page: 'http://www.ucsf.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAkFMHrNg4oBz_r4FDJcHqemYDvRK44CoXJwDsELVpC-6q2G85iwYTVA',
    id: 1324,
    acronym: 'UCDSF',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ucu.edu.ar',
    name: 'Universidad de Concepción del Uruguay',
    web_page: 'http://www.ucu.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNNoymvLPndldwk-qRUoX6ybmjTvBjZ-vZrfzPoNcH7-rTgZUtOj9FVPA',
    id: 1325,
    acronym: 'UDCDU',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uda.edu.ar',
    name: 'Universidad del Aconcagua',
    web_page: 'http://www.uda.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTerxe3VOoikqA6eH40PP5nBEOF4yyNmPynVG7iXHc-uspUPpOgDlZfoOA',
    id: 1326,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'udemm.edu.ar',
    name: 'Universidad de la Marina Mercante',
    web_page: 'http://www.udemm.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw4tSygsc3J6Q_yVTZTf06yDkDQ17v5HE5TsEqk82QUKS2ye2gJC9BrQ',
    id: 1327,
    acronym: 'UDLMM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'udesa.edu.ar',
    name: 'Universidad de San Andres',
    web_page: 'http://www.udesa.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhXCe24Ht0Qk7bMahqK-K4_GUTl73IdVt4ZvYSDRUOLvtsul48vo_MThs',
    id: 1328,
    acronym: 'UDSA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uesiglo21.edu.ar',
    name: 'Universidad Empresarial Siglio 21',
    web_page: 'http://www.uesiglo21.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStyzlcFYPpM58w8p_CzLPKfj8-ecq_Mffgz-4x1OCrZuc1wsJThdkykQ',
    id: 1329,
    acronym: 'UES2',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ufasta.edu.ar',
    name: 'Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)',
    web_page: 'http://www.ufasta.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxzI1nJrbDfMNAS6p8TYr21E6Q43xkPTFx_3WdKVnm4dyRLChPNGaSm00',
    id: 1330,
    acronym: 'UDLFDASTDA(',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uflo.edu.ar',
    name: 'Universidad de Flores - Buenos Aires',
    web_page: 'http://www.uflo.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSROJGF5pPoNQxDL6B7jrTh9l0iLQwRjjIX4QUClUodD7d1ycXdY3E-vg',
    id: 1331,
    acronym: 'UDF-BA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'umaza.edu.ar',
    name: 'Universidad "Juan Agustín Maza"',
    web_page: 'http://www.umaza.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEJnypE9fLLlxcuwTOMwUnQ6xhELvt9xq6KkTD3gBQmRcnTalI_oewesE',
    id: 1332,
    acronym: 'U"AM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'um.edu.ar',
    name: 'Universidad de Mendoza',
    web_page: 'http://www.um.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMLjg_YkZDr43L8pSnZ5lJH0hmsS4ypoQ148Z20yurdiwUPlqy_e9LG9c',
    id: 1333,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'umsa.edu.ar',
    name: 'Universidad del Museo Social Argentino',
    web_page: 'http://www.umsa.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqAVNfPUuatHRFilGIE1dzERqKwKfx9uCvpWFspevKhm1yv5EhUmPG7Ho',
    id: 1334,
    acronym: 'UDMSA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unam.edu.ar',
    name: 'Universidad Nacional de Misiones',
    web_page: 'http://www.unam.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaNiorl1MlzB45YG9enbQ4FctNZH5vUb9J_s8M6p_cFa2tr7sGB85AngGd',
    id: 1335,
    acronym: 'UNDM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unca.edu.ar',
    name: 'Universidad Nacional de Catamarca',
    web_page: 'http://www.unca.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpVkV6eKfwOwBhTEZkC4C7W1cFAmpziX71ixsH4gvhD1luGcjK0d4hLTo',
    id: 1336,
    acronym: 'UNDC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unc.edu.ar',
    name: 'Universidad Nacional de Córdoba',
    web_page: 'http://www.unc.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4yz41ja6oDfuHk9tM7lXZMfxlAxB6YNt213klxkuOoR6LpVwibvDTA1w',
    id: 1337,
    acronym: 'UNDC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uncoma.edu.ar',
    name: 'Universidad Nacional del Comahue',
    web_page: 'http://www.uncoma.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1Gt5whfEAkUZO0i91kbiXDynbCbdPAE8ox2cqPK7a7JUOSqPzJUM1ZQ',
    id: 1338,
    acronym: 'UNDC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uncu.edu.ar',
    name: 'Universidad Nacional de Cuyo',
    web_page: 'http://www.uncu.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXfeBfiIWGi_lZRcKCLQ-ImKMA3EO6g90kt91lZ_vTSpE4BC1JnG9TJA',
    id: 1339,
    acronym: 'UNDC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uner.edu.ar',
    name: 'Universidad Nacional de Entre Ríos',
    web_page: 'http://www.uner.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIB3-6-bUQAx-plFgyyDkCwbsSdlWbyQ-z-QqQa8yGzez4s4GbZ6Cey9M',
    id: 1340,
    acronym: 'UNDER',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unf.edu.ar',
    name: 'Universidad Nacional de Formosa',
    web_page: 'http://www.unf.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYHzh6tF2YDCS2A3fQcUC9ckb0t0xhse7hl3gC2lsbk3dZvCcYVF0LhQ',
    id: 1341,
    acronym: 'UNDF',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'ungs.edu.ar',
    name: 'Universidad Nacional de General Sarmiento',
    web_page: 'http://www.ungs.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAWmH0Gd_DJzX5AzmynKnLsNfo3WKVSmW-vgmdRC5kg-5MlI-GemC3nc_0Jw',
    id: 1342,
    acronym: 'UNDGS',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unibo.edu.ar',
    name: 'Universidad de Bologna - Representación en Buenos Aires',
    web_page: 'http://www.unibo.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl98GNvqb_zgo7Ob-YQgagX-y3LVrhxw7hFpaWBqQRcuA8Y0ehw-30JMU',
    id: 1343,
    acronym: 'UDB-REBA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unicen.edu.ar',
    name: 'Universidad Nacional del Centro de la Provincia de Buenos Aires',
    web_page: 'http://www.unicen.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuzatoDKBRncnP5LYnsrHhNxIM9UPCh7HShShSTwY8UgHa_IN9XlDSdF0g7A',
    id: 1344,
    acronym: 'UNDCDLPDBA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unimoron.edu.ar',
    name: 'Universidad de Morón',
    web_page: 'http://www.unimoron.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0xRocE44U_EtBOmjVGuBdqipseID0ymcJ9ozXJ0QGyPzRbRIPkdyhNSo',
    id: 1345,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'universidadnotarial.edu.ar',
    name: 'Universidad Notarial Argentina',
    web_page: 'http://www.universidadnotarial.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNcWdxngPLxLV-yj9ntJGEHSN5lSEcfCaY3gJYYJiIKE2_QpRTc4psl_A',
    id: 1346,
    acronym: 'UNA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'universidad-policial.edu.ar',
    name: 'Instituto Universitario de la Policia Federal',
    web_page: 'http://www.universidad-policial.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREbFDDaEF1cJSJHC27QVomJiBAeBgSac3cpvaZ3P9N_reRHMGbMj9gl5A',
    id: 1347,
    acronym: 'IUDLPF',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unju.edu.ar',
    name: 'Universidad Nacional de Jujuy',
    web_page: 'http://www.unju.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLiWtqwmoGd3W1L1n6Mtl8tj-SnXZyeng_pAC03c9v_xS-PmwGUONe2Kc',
    id: 1348,
    acronym: 'UNDJ',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unla.edu.ar',
    name: 'Universidad Nacional de Lanus',
    web_page: 'http://www.unla.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_bLsJwmfSmAgtyp8_5xsCm0jo8fBJQU9sL62ycAjvs_66aWt5AgzL6Q',
    id: 1349,
    acronym: 'UNDL',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unlam.edu.ar',
    name: 'Universidad Nacional de La Matanza',
    web_page: 'http://www.unlam.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTufLi0y48ywGpHYPTVIV3eDHollNGufR6PAbpk5nKlGBZL0Ci4OD-JtA',
    id: 1350,
    acronym: 'UNDLM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unlar.edu.ar',
    name: 'Universidad Nacional de La Rioja',
    web_page: 'http://www.unlar.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-GXtTOFjYnauIBguiLtqCiT13O18Fkj5ujSiwbsMK65onyVh0AYdXPHFY',
    id: 1351,
    acronym: 'UNDLR',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unl.edu.ar',
    name: 'Universidad Nacional del Littoral',
    web_page: 'http://www.unl.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_ebHLXWfTamJp3G32mXeWEiNZir2_sLHXcUU-8gJ1dkXNfsXM-Id7Mw',
    id: 1352,
    acronym: 'UNDL',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unlpam.edu.ar',
    name: 'Universidad Nacional de La Pampa',
    web_page: 'http://www.unlpam.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTY1n495c301T119DVxsM9E2CGULDgiGf9sH_Lg8t4SfWkHCMojeHFdrQo',
    id: 1353,
    acronym: 'UNDLP',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unlp.edu.ar',
    name: 'Universidad Nacional de La Plata',
    web_page: 'http://www.unlp.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ65GqqCJ8OgWiM0NJqJDUqG9uiEAc1mszrP2TFUa1rtbyp-lFH9bAfVvE',
    id: 1354,
    acronym: 'UNDLP',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unlu.edu.ar',
    name: 'Instituto Nacional de Educación Física "General Manuel Belgrano"',
    web_page: 'http://www.unlu.edu.ar/Belgrano.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYBSWiK_fX9uRD1NEFpqWprKurMs4Lm7Q2-zGajGzwb3tSkVvsO4IZNZM',
    id: 1355,
    acronym: 'INDEF"MB',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unlu.edu.ar',
    name: 'Universidad Nacional de Luján',
    web_page: 'http://www.unlu.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQVLWpIjR8t0B6Pk-7lybGZ_2jABvZGJsi76Ms07A2ZVMLqPMOQMmjvw',
    id: 1356,
    acronym: 'UNDL',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unlz.edu.ar',
    name: 'Universidad Nacional de Lomas de Zamora',
    web_page: 'http://www.unlz.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsxMH1YMl10vbhKW-586xXcVNmUxWALgqO9IToEVrYGhsQcVoWDByhtAo',
    id: 1357,
    acronym: 'UNDLDZ',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unne.edu.ar',
    name: 'Universidad Nacional del Nordeste',
    web_page: 'http://www.unne.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoXkm72NwbzCy2Bi8kErBgGmRTuL_dv4kdCciETg47my-3MihvyfODSKvz',
    id: 1358,
    acronym: 'UNDN',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unnoba.edu.ar',
    name: 'Universidad Nacional del Noroeste de la Provincia de Buenos Aires',
    web_page: 'http://www.unnoba.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDzxqO1K0aTdaziL2H1nXW6rIjL6mqayZekCn5UaaioR3AcFBUHUyw0_kR',
    id: 1359,
    acronym: 'UNDNDLPDBA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unpa.edu.ar',
    name: 'Universidad Nacional de la Patagonia Austral',
    web_page: 'http://www.unpa.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn9Bds6xvpKKjegBkR4VVHxsEyRd60oijEYbDCrwuMUdkwulMdQlO1xXM',
    id: 1360,
    acronym: 'UNDLPA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unp.edu.ar',
    name: 'Universidad Nacional de la Patagonia "San Juan Bosco"',
    web_page: 'http://www.unp.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNANU3clt7GFq2b-eeRDGf7eDED8yBpvM4Lz41vEHdOiiN28EOY3HUnA',
    id: 1361,
    acronym: 'UNDLP"JB',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unq.edu.ar',
    name: 'Universidad Nacional de Quilmes',
    web_page: 'http://www.unq.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEVo3t7hytm5bhdCXhItfGN5o6zNXETTF3SZC7MuE3WhmkXHTODlcl5Ms',
    id: 1362,
    acronym: 'UNDQ',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unrc.edu.ar',
    name: 'Universidad Nacional de Río Cuarto',
    web_page: 'http://www.unrc.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDa23tTQzLlUH1Mfl9KCPap_pQ68jPY-zXaZVJ5kh1XrxiYvq8PAJSdw',
    id: 1363,
    acronym: 'UNDRC',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unr.edu.ar',
    name: 'Universidad Nacional de Rosario',
    web_page: 'http://www.unr.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZQi7hU6hilMLLaFhcyz09sV71XH7Q-y4nKCC-lE5lr44b8J3ZXZoxfJo',
    id: 1364,
    acronym: 'UNDR',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unsa.edu.ar',
    name: 'Universidad Nacional de Salta',
    web_page: 'http://www.unsa.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPhF07GZaeq1OrRuSVxuf7qmDYTXAhBQk4-IPc4SapabcC0iPuekTgPeHC',
    id: 1365,
    acronym: 'UNDS',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unsam.edu.ar',
    name: 'Universidad Nacional de General San Martín',
    web_page: 'http://www.unsam.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCq9n1ajVZv0TTeElRJEZGJ3FHllP__X73X-h0OKvdW56cxsFVj6GnUQ',
    id: 1366,
    acronym: 'UNDGSM',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'uns.edu.ar',
    name: 'Universidad Nacional del Sur',
    web_page: 'http://www.uns.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEjzDBfe9mIZ47E6HjoY3tHRvdBgH8G5ylYXDT4uqGC_p9vz9PYpes',
    id: 1367,
    acronym: 'UNDS',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unse.edu.ar',
    name: 'Universidad Nacional de Santiago del Estero',
    web_page: 'http://www.unse.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB3Jj8-WVCXlR2lxzJk1atsytTtkJyMFJpgYnrDRhsJDUvs4kimWIiUy8',
    id: 1368,
    acronym: 'UNDSDE',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unsj.edu.ar',
    name: 'Universidad Nacional de San Juan',
    web_page: 'http://www.unsj.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2Q6lCgY6bI7uqistNZQZdF-01n4oUvGdtn4fRG1vwP3XaimwwpSs9et6l',
    id: 1369,
    acronym: 'UNDSJ',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unsl.edu.ar',
    name: 'Universidad Nacional de San Luis',
    web_page: 'http://www.unsl.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLhGrE7YF1feP4x0PJuATqIzNOWPz3jKSVZDSLJNtoMce52MZoUynPpw',
    id: 1370,
    acronym: 'UNDSL',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unsta.edu.ar',
    name: 'Universidad del Norte "Santo Tomás de Aquino"',
    web_page: 'http://www.unsta.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnII_LkVx63edqdvZtMlgrP_b2ouWhwY1e-mqg_Cxfx8_fggkmQGNq2Q',
    id: 1371,
    acronym: 'UDN"TDA',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'unt.edu.ar',
    name: 'Universidad Nacional de Tucumán',
    web_page: 'http://www.unt.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzOapsGqrNrxMHHQLulSmnsn4VxF7hc2-Xuoccn3PFhGbXfFQ_SlKx9nA',
    id: 1372,
    acronym: 'UNDT',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'untref.edu.ar',
    name: 'Universidad Nacional de Tres de Febrero',
    web_page: 'http://www.untref.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDU2JpXPy2c_6XvccOoW37QxXmKa95do9yOny0fDVJuJyD12LReAFWxg',
    id: 1373,
    acronym: 'UNDTDF',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'utdt.edu',
    name: 'Universidad Torcuato di Tella',
    web_page: 'http://www.utdt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6znk32DRlbAI3AtxHRn9q1bdGiaiffQYzJkjAQa9SH39p9xqujS0yfSw',
    id: 1374,
    acronym: 'UTDT',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'utn.edu.ar',
    name: 'Universidad Tecnológica Nacional',
    web_page: 'http://www.utn.edu.ar/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIx4xfbxo9kxS69FD_Fg7df9gBYK0LIxFUkCVOoZxH2pu7wiL4lYcCFqk',
    id: 1375,
    acronym: 'UTN',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'vaneduc.edu.ar',
    name: 'Universidad Abierta Interamericana',
    web_page: 'http://www.vaneduc.edu.ar/uai/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnzH1y7vp-hYGieD1veKKCYqJqHTMrgXLHFv-ntOdt2QXjeAy94-hMK0A',
    id: 1376,
    acronym: 'UAI',
  },
  {
    alpha_two_code: 'AR',
    country: 'Argentina',
    domain: 'webs.satlink.com',
    name: 'Universidad Nacional de Villa María',
    web_page: 'http://webs.satlink.com/usuarios/i/iiunvm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3WCPf8NIv_f-Sfvnc3uLuEw6_iqTcewPCRb32RAUdIg1TUb-bm_Oc5g',
    id: 1377,
    acronym: 'UNDVM',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'armmed.am',
    name: 'Mehrabyan Medical Institute and Medical College',
    web_page: 'http://www.armmed.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdRpkyeFPjDgnK-TYYwUW0PHGrc7VZ0V32JCFaVAsOvoXEclQLfM8ZGw',
    id: 1378,
    acronym: 'MMIMC',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'asau.am',
    name: 'Armenian State Agrarian University',
    web_page: 'http://www.asau.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkgbJH_lOzVEZd52APIwai-HMaNIsfUGIsjG4XUYXWEyzinYp92DHiDnE',
    id: 1379,
    acronym: 'ASAU',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'asue.am',
    name: 'Armenian State University of Economics',
    web_page: 'http://www.asue.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRozY81wRyykvylvfF9bBefhzHzRaiermRvL5vldODjeqoIaaNzTpVMOQc',
    id: 1380,
    acronym: 'ASUE',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'aua.am',
    name: 'American University of Armenia',
    web_page: 'http://www.aua.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIQRrfqlJNUoksGNs-R04zkqfLEAzhdyvvpQgkBc4eGP_ue0Ybg-B2cv4',
    id: 1381,
    acronym: 'AUA',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'eiu.am',
    name: 'Eurasia International University',
    web_page: 'http://www.eiu.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyamLusCaIpnD9dnWNMZIZxklLG2-1sHdakliNOOrDtJqjrZIKQaKH7w',
    id: 1382,
    acronym: 'EIU',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'eriicta.am',
    name: 'European Regional Educational Academy of Armenia',
    web_page: 'http://www.eriicta.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFqKZSmqTeWVuO1ugFzjqLPc8CwuaZ2JaSmN6NQgio-Nfj3jM4PT1AvvI',
    id: 1383,
    acronym: 'EREAA',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'haybusak.org',
    name: 'Yerevan Haibusak University',
    web_page: 'http://www.haybusak.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYcXUjz3kyw7NUXrg_GTugsEG5d505nLSJBe7xu8W6d5fW75-8fEEYLA',
    id: 1384,
    acronym: 'YHU',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'rau.am',
    name: 'Russian-Armenian (Slavonic) State University',
    web_page: 'http://www.rau.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIEh_eNo2kGQBPUWElM3_aBi3nAxCjKxqsjrXCxpq-GccAKHuQYvXGug',
    id: 1385,
    acronym: 'R(SU',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'seua.am',
    name: 'State Engineering University of Armenia',
    web_page: 'http://www.seua.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6TRqZH0hxYzUw-6xvcSvcRev5s93xZG3sKvq37rwOaa3xP0Z_-6vYdA',
    id: 1386,
    acronym: 'SEUA',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'ufar.am',
    name: 'French University in Armenia (UFAR)',
    web_page: 'http://www.ufar.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsTVfrXtlo6ZqgHAgEvuMK3V_Q1q_c7VjVIiawgtQfGVQn1C--vtgzdWA',
    id: 1387,
    acronym: 'FUIA(',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'ysmu.am',
    name: 'Yerevan State Medical University',
    web_page: 'http://www.ysmu.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpPL61lQ605ANT0QzlP_w6l2-q8a-puPvfmbbYKKtaFeAdmzpCmSFNqb4K',
    id: 1388,
    acronym: 'YSMU',
  },
  {
    alpha_two_code: 'AM',
    country: 'Armenia',
    domain: 'ysu.am',
    name: 'Yerevan State University',
    web_page: 'http://www.ysu.am/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMDDioaac-orCfQxxOnAqoIgWtkusKL7BPoujBGsN7XcEg9L0xLYSBNF9UDQ',
    id: 1389,
    acronym: 'YSU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'acs.edu.au',
    name: 'Australian Correspondence Schools',
    web_page: 'http://www.acs.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzJXV5yCNXI22rsgdoiXM4OqPduISAU9dNz45Q9QU8brAAYXvBdhqoyko',
    id: 1390,
    acronym: 'ACS',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'acu.edu.au',
    name: 'Australian Catholic University',
    web_page: 'http://www.acu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNtAiIjrH9G-kQtno8Wr4akHhs4XzaH_OyTFMPlgCyqF1N6q37l0Q-qB0A',
    id: 1391,
    acronym: 'ACU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'adelaide.edu.au',
    name: 'University of Adelaide',
    web_page: 'http://www.adelaide.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE7w7kwewvFKKJcrYcmxRnTc8BkRqE_WJOOyMYgbH2r8GmN_vf57hbHzQ',
    id: 1392,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'adfa.oz.au',
    name: 'Australian Defence Force Academy',
    web_page: 'http://www.adfa.oz.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqHYoOmUy9RD1eMdkF6bRiI9To-M5E-NGxd4yZCw66MJtY9FYZiUp1idA',
    id: 1393,
    acronym: 'ADFA',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'alc.edu.au',
    name: 'Australian Lutheran College',
    web_page: 'http://www.alc.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcCQ56aRNq5ZWc2GjXxGS3IJdKmeuJiuBWC4FswGRmaQp5nTOh55dNsic',
    id: 1394,
    acronym: 'ALC',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'amc.edu.au',
    name: 'Australian Maritime College',
    web_page: 'http://www.amc.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgF_KqX1e-sbFQSXP8-3uBPWkeZk6caZH_wY6O02cH10eLbZbQ4QzQMio',
    id: 1395,
    acronym: 'AMC',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'anu.edu.au',
    name: 'Australian National University',
    web_page: 'http://www.anu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiCgQhYBoEhUcoJ-QCYw-Is2k0hbXt01XQtgPE6c9EQlddMO_1GMqu5_c',
    id: 1396,
    acronym: 'ANU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'avondale.edu.au',
    name: 'Avondale College',
    web_page: 'http://www.avondale.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFvnso_E-zdQyxtjLX5-bUd-UN1Lz-oUMu876sJrzKtZBSIJ8lxlq4bGo',
    id: 1397,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'ballarat.edu.au',
    name: 'University of Ballarat',
    web_page: 'http://www.ballarat.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTnEcyQa8lb5kkJ3ZXgwYIQoQBYll6QOKs4eCYjIp-KpmYztUvCGzp2lw',
    id: 1398,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'bond.edu.au',
    name: 'Bond University',
    web_page: 'http://www.bond.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWxMNUYtRMqiABGvDa23TebDvnBZb0MvQw_bJg2YeGkMDik918ehVw7Go',
    id: 1399,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'canberra.edu.au',
    name: 'University of Canberra',
    web_page: 'http://www.canberra.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnvOMW--yFHZwa7SnNzBBEW3YUxVOdNHJ6OewsZAjkvWPrk_cuH6sgzO4',
    id: 1400,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'cdu.edu.au',
    name: 'Charles Darwin University',
    web_page: 'http://www.cdu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1b20tSNGudIFN3YELucwZ9-UVBeB4Nh1ZnXD1kVXOj5IjWa142SzxJbM',
    id: 1401,
    acronym: 'CDU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'cqu.edu.au',
    name: 'Central Queensland University',
    web_page: 'http://www.cqu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeUYANwneKgMyvoanU7N3zP4IwHY7O--ShBFVTus8WioGmEOgB1AOKW-Gg',
    id: 1402,
    acronym: 'CQU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'csu.edu.au',
    name: 'Charles Sturt University',
    web_page: 'http://www.csu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMzTmjhviS7Yw-rkDjmC8x_bFDIwQP5wOHWnwpo87DV-FZOg7VpAEP2zkK',
    id: 1403,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'curtin.edu.au',
    name: 'Curtin University of Technology',
    web_page: 'http://www.curtin.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdLO6st2HtI7nqUR1-6c0ViYEdUiXDniLO0tmXCH33bqMwwcyw7UufXpY',
    id: 1404,
    acronym: 'CUT',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'deakin.edu.au',
    name: 'Deakin University',
    web_page: 'http://www.deakin.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtAH9aR0LB6FsjfP0mP8KALKYbnGNkxtHeexJclSC4U89Z35T9HOVGCH9wOg',
    id: 1405,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'ecu.edu.au',
    name: 'Edith Cowan University',
    web_page: 'http://www.ecu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcz1lCOYF-a4ZmskcnWVefKVPh3mj45JtZCQ2XKwTuw2dnnRt1xjSXZaY7FA',
    id: 1406,
    acronym: 'ECU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'flinders.edu.au',
    name: 'Flinders University of South Australia',
    web_page: 'http://www.flinders.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCtYI_ysuWCM28kZQxNb8J0WsCBd53k6B6sjZu5-EiikvY5kqTSbPzJzyT',
    id: 1407,
    acronym: 'FUSA',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'gu.edu.au',
    name: 'Griffith University',
    web_page: 'http://www.gu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdkg-XjnFCvUdoNEFihxrUZIWAQotIixnXvlnwSqctxFvcouxy0blrEQ',
    id: 1408,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'holmes.edu.au',
    name: 'Holmes Institute',
    web_page: 'http://www.holmes.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVY9Ev8-zyPdUSAMYEPoSOeG2fq4MXa_5m5_RPRkpYI-vwxe74Nav6ZwY',
    id: 1409,
    acronym: 'HI',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'hotelschool.com.au',
    name: 'Blue Mountains Hotel School',
    web_page: 'http://www.hotelschool.com.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE5faexCS_Jr8R7Y2XfLKDogug_kH2EUu2Jt0hpHPsOqgW3UbgkgBZ14c',
    id: 1410,
    acronym: 'BMHS',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'icthm.edu.au',
    name: 'International College of Tourism and Hotel Management',
    web_page: 'http://www.icthm.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuTbGNJEsmfy8FgBuHyLvfuEzsOId46iwtGmxNV8V8KUjQgrTjB4ZQ6yA',
    id: 1411,
    acronym: 'ICTHM',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'iota.edu.au',
    name: 'Institute Of Technology Australia',
    web_page: 'http://www.iota.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 1412,
    acronym: 'ITA',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'jcu.edu.au',
    name: 'James Cook University of North Queensland',
    web_page: 'http://www.jcu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2Fa3hiEvZA0RpeH1zh9S-mUzv2GhdR7ZkO7JwWGbLsepbPM-K5FzHhQ',
    id: 1413,
    acronym: 'JCUNQ',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'latrobe.edu.au',
    name: 'La Trobe University',
    web_page: 'http://www.latrobe.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQe3j-q2LEUZG4Hrgkugi8H6f-6XlE5IN8SwqZRdJy7opdd6xgcRh4W9lU',
    id: 1414,
    acronym: 'LTU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'marcusoldham.vic.edu.au',
    name: 'Marcus Oldham College',
    web_page: 'http://www.marcusoldham.vic.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzf-jniGG_enMlCc0yBlVBVLuR9i5Q_-1yfFrwHI-PLun_D--SHTvwP72F',
    id: 1415,
    acronym: 'MOC',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'monash.edu',
    name: 'Monash University',
    web_page: 'http://www.monash.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS195W_ASuyWjuzujQVSXlChQpcl9ZzA-_OymfqQnHOSuRydhKJBBYb6w',
    id: 1416,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'mq.edu.au',
    name: 'Macquarie University',
    web_page: 'http://www.mq.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI1O-qCKONvvNcxPw4kNNo_R6Cgx40_k2ZiN782MHmUSbqx8kS8xOZig',
    id: 1418,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'murdoch.edu.au',
    name: 'Murdoch University',
    web_page: 'http://www.murdoch.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYxOfbExQrF9kLHyEywAzO5pGAHhGaoFJFX2PjxJwOF8jfvmIN5IF4sjx9',
    id: 1419,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'nd.edu.au',
    name: 'University of Notre Dame Australia',
    web_page: 'http://www.nd.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_-w0KlgGsc3AK7pTNdexgZSdzxZ6Ij4g02Jxe8OxV8p2O8dw1k3ucWmU',
    id: 1420,
    acronym: 'UNDA',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'newcastle.edu.au',
    name: 'University of Newcastle',
    web_page: 'http://www.newcastle.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5BalOx4qRFqwoNUJo3QHLr3epKZKIZaoxlVKTwNZt-BR3apLjT15p6KQ',
    id: 1421,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'nt.gov.au',
    name: 'Batchelor Institute of Indigenous Tertiary Education',
    web_page: 'http://www.nt.gov.au/batc/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIiucH3QofQc4RAGTSlXe3udSHqIOqyUooolsyypSsTOxrMijoNVzLqQ',
    id: 1422,
    acronym: 'BIITE',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'qut.edu.au',
    name: 'Queensland University of Technology',
    web_page: 'http://www.qut.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwv4t65tHfKmTmXRzwAl68FEdwBO4VvLEExqU3glrQv6DFoAazpemxD1A',
    id: 1423,
    acronym: 'QUT',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'rmit.edu.au',
    name: 'Royal Melbourne Institute of Technology',
    web_page: 'http://www.rmit.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNCExbOHGPjKFHc5OJQ2cVn6nzikCyOmII3I6VChp7CTYOk_uFyHHSAQ',
    id: 1424,
    acronym: 'RMIT',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'scu.edu.au',
    name: 'Southern Cross University',
    web_page: 'http://www.scu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuBnBMvTRGR4aKBxSUvQ52vcQZqH3b7dIU_LV5uBoni0vPcrO3H9vNhQ',
    id: 1425,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'swin.edu.au',
    name: 'Swinburne University of Technology',
    web_page: 'http://www.swin.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcNuZTCyLstkFkSY8gORGu8J_ur9LhESRq8Chi8Y-J7gWLmQhjD04AQcVkiQ',
    id: 1426,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'unimelb.edu.au',
    name: 'University of Melbourne',
    web_page: 'http://www.unimelb.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVRZ_ZKRE-nnJwM5k7Wgou9Uf01bQ9Sa7UVN-RV_hiP8IaL43CoRHfwsk',
    id: 1428,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'unisa.edu.au',
    name: 'University of South Australia',
    web_page: 'http://www.unisa.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8hxbkKQUba-FkNwytwnQTG9cVmdZLrkWElHi4HYkXcgKuLWJT-uxf896K',
    id: 1429,
    acronym: 'USA',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'unsw.edu.au',
    name: 'University of New South Wales',
    web_page: 'http://www.unsw.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScpow9rPNjxkZADsr4aocOKPLU7pQvgcqmaxy5wnPmOv31-b2yv1E_jA',
    id: 1430,
    acronym: 'UNSW',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'uow.edu.au',
    name: 'University of Wollongong',
    web_page: 'http://www.uow.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWDl8IpRUhCIx6Zmx6EkrAZdQQhwkmyiUHzZv8bmltJjsYCziX5ATBuA',
    id: 1431,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'uq.edu.au',
    name: 'University of Queensland',
    web_page: 'http://www.uq.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjITj7aPtK4C71bbu__BNCdYDFGxWsyFRjVc_G8s7QcNpCodxZffCkDg',
    id: 1432,
    acronym: 'UQ',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'usc.edu.au',
    name: 'University of the Sunshine Coast',
    web_page: 'http://www.usc.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRZJp2WbXMo3OSQLGT5ih4B6uHxli6WXS_qa3LlUZBXhQ3js3q0iXPwJc',
    id: 1433,
    acronym: 'USC',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'usq.edu.au',
    name: 'University of Southern Queensland',
    web_page: 'http://www.usq.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvV6SHbPb3_AL98lazbTIWN0Itc31HeRQ3xsYa5AZ6FXspkPnpfbW98tA',
    id: 1434,
    acronym: 'USQ',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'sydney.edu.au',
    name: 'University of Sydney',
    web_page: 'http://sydney.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ14f16EVlQRgBLYSZguDlxNPxKb7hE9gYpLkIPhGnPmSF6IPbD0LaMW8o',
    id: 1435,
    acronym: 'US',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'utas.edu.au',
    name: 'University of Tasmania',
    web_page: 'http://www.utas.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9QiT76ij0VXMd1EYgQajN6Vnaa0j3ZEFfDFzBs7xo7Wby8_4YQWCwnBk',
    id: 1436,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'uts.edu.au',
    name: 'University of Technology Sydney',
    web_page: 'http://www.uts.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbpRw80nYG7cDkGe1P3XWckHsWD9wgsOpHXYQymg-8mmVaeyvGmnVeng',
    id: 1437,
    acronym: 'UTS',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'uwa.edu.au',
    name: 'University of Western Australia',
    web_page: 'http://www.uwa.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY40vUzQEvvCuWp2amHh1IWXl-HIkhWyShyNxl84kdiYdCXv9okgl_nQ',
    id: 1438,
    acronym: 'UWA',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'uws.edu.au',
    name: 'University of Western Sydney',
    web_page: 'http://www.uws.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdILPNUy7ynqXyaFu0bbVxEg22_Gnr3QEnDKxo3v1Fgk1kT2gqyJgndw',
    id: 1439,
    acronym: 'UWS',
  },
  {
    alpha_two_code: 'AU',
    country: 'Australia',
    domain: 'vu.edu.au',
    name: 'Victoria University',
    web_page: 'http://www.vu.edu.au/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR831kCaf9-Lra79JS15jalzl0IEuDTaHYpCkdus3mMULPBjcVyxOikDMqU',
    id: 1440,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'akbild.ac.at',
    name: 'Akademie der bildenden Künste Wien',
    web_page: 'http://www.akbild.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfdrczaaTZWJ2utTqf3IEKmJOU30mVdDSvNBrIE7JWKxdRWeeGPitiwQ',
    id: 1441,
    acronym: 'ADBKW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'boku.ac.at',
    name: 'Universität für Bodenkultur Wien',
    web_page: 'http://www.boku.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj-K7GePEyZve1qgbh89GGmtLuHZY_b8bGZdtONdQdpEpk6KyK_rcf4Q',
    id: 1442,
    acronym: 'UFBW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'donau-uni.ac.at',
    name: 'Donau-Universität Krems',
    web_page: 'http://www.donau-uni.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm4Wmrr0y85N5nmVExzNREmMa34JwpmQsxXBNd0qN_gaITmxAi_7dpClI',
    id: 1443,
    acronym: 'DK',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-burgenland.at',
    name: 'Fachhochschule Burgenland',
    web_page: 'http://www.fh-burgenland.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy1o7uT8A1GGSfKhQBvUIQ4wFaRYCONp9O-W_avRU5dsDPDiZDB7p3C_LW',
    id: 1444,
    acronym: 'FB',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-hagenberg.at',
    name: 'Fachhochschulstudiengänge Hagenberg',
    web_page: 'http://www.fh-hagenberg.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1445,
    acronym: 'FH',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-joanneum.at',
    name: 'Fachhochschule JOANNEUM',
    web_page: 'http://www.fh-joanneum.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsIN7OTqwZVX3Yo5lPGpNn5Q4gr0tRTRfBWTIx7Q4Yig0tj74-yxe-5g',
    id: 1446,
    acronym: 'FJ',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-kaernten.ac.at',
    name: 'Fachhochschule Kärnten',
    web_page: 'http://www.fh-kaernten.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTGcdASPvq5uPBJrwhh3W61qTdjNpHnUezbycTFsqJZUeDUtvnoifxkYg',
    id: 1447,
    acronym: 'FK',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-krems.ac.at',
    name: 'Fachhochschule Krems',
    web_page: 'http://www.fh-krems.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsrqD1TK1ZWBhWAaOl_fXX8Dvc94P0IszQrHqkCC6n3zSt1N7RV5QZsL_Shw',
    id: 1448,
    acronym: 'FK',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-kufstein.ac.at',
    name: 'Fachhochschule Kufstein (Tirol)',
    web_page: 'http://www.fh-kufstein.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL07Oaydxo6tNYZpPCkeUSKvmG919agFgjEm_vGPpxTn3GuBPAAhhzMg',
    id: 1449,
    acronym: 'FK(',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-sbg.ac.at',
    name: 'Fachhochschule Salzburg',
    web_page: 'http://www.fh-sbg.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnHVo1bkWBAqs8VSVJZJinzC_3w_gFWAIArqJ8qbLm8uOLKkVzVWi0nA',
    id: 1450,
    acronym: 'FS',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-steyr.at',
    name: 'Fachhochschulstudiengänge Steyr',
    web_page: 'http://www.fh-steyr.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-ez6rDxD0vzt9e_QB6DdkFzdNzvE_DztH3g2FbWujV0RurDMM2CgJIbw',
    id: 1451,
    acronym: 'FS',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-stpoelten.ac.at',
    name: 'Fachhochschule St. Pölten',
    web_page: 'http://www.fh-stpoelten.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV-sVtaCHd5cRZZO26meVjkY4WmBChp5W7Uh3KggsiDiBANm2cHO3OWuQ',
    id: 1452,
    acronym: 'FSP',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fhv.at',
    name: 'Fachhochschule Vorarlberg',
    web_page: 'http://www.fhv.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoZNWMHMBXORJ1hjguSOXEXlaCi-cJVFjr2NYGcbqtjbJvEDrSnT8z8w',
    id: 1453,
    acronym: 'FV',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-vie.ac.at',
    name: 'Fachhochschule für Bank- und Finanzwirtschaft',
    web_page: 'http://www.fh-vie.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs7phA5LuaKUsKZiXgBDGZAndMITXRLuReEmKDWfB6-w2VB9RQJXjuB4mh',
    id: 1454,
    acronym: 'FFBUF',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fhw.at',
    name: 'Fachhochschulstudiengänge der Wiener Wirtschaft',
    web_page: 'http://www.fhw.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsh9hqvj59VKeRSZyVMpGqvWa8Cky0yKWsHImfavKAoI5khOL3hhHQ5so',
    id: 1455,
    acronym: 'FDWW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fh-wels.at',
    name: 'Fachhochschulstudiengänge Wels',
    web_page: 'http://www.fh-wels.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS6A3SFFn0LO8eo4qVUneOd8MBh_lVCP1GpIz-I7J38mhcEOaaHuLESIk',
    id: 1456,
    acronym: 'FW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'fhwn.ac.at',
    name: 'Fachhochschule Wiener Neustadt',
    web_page: 'http://www.fhwn.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5Z1McPabc34y7YgN-PMserCwXBW4Xo6Ya26u2q-gilZBgHK5Dq3BwmWU',
    id: 1457,
    acronym: 'FWN',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'imc-krems.ac.at',
    name: 'Fachhochschulstudiengänge Krems IMC',
    web_page: 'http://www.imc-krems.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl12Lgz3ouE-BdSy-16cHxfUo6NLPrZma1jetlBX-c5bCnvADYdgK0lg',
    id: 1458,
    acronym: 'FKI',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'i-med.ac.at',
    name: 'Medizinische Universität Innsbruck',
    web_page: 'http://www.i-med.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxA5TPW9RcWHtnsv0iKRvZt_S4bRklxkJ3RWVMnwyi8Hlcm2W7cVpTaq__',
    id: 1459,
    acronym: 'MUI',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'ist.ac.at',
    name: 'Institute of Science and Technology',
    web_page: 'http://www.ist.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 1460,
    acronym: 'IST',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'kfunigraz.ac.at',
    name: 'Universität Graz',
    web_page: 'http://www.kfunigraz.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtG054UguKRuZnvyTNppUzUZaubJKwdl-8YjIeLM6VryITyuVGLRby8w',
    id: 1461,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'khs-linz.ac.at',
    name: 'Universität für künstlerische und industrielle Gestaltung Linz',
    web_page: 'http://www.khs-linz.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbzat-dNpHeI5mrG8CIOPTM31jKd76zkmhU7LL0iEZDvKkhZl8kBS4G28',
    id: 1462,
    acronym: 'UFKUIGL',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'kth-linz.ac.at',
    name: 'Katholisch-Theologische Privatuniversität Linz',
    web_page: 'http://www.kth-linz.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThpGRj7WHqTNzZsa6TCXX36voxghNknfqW1nfc0oDfhqsCA0cto7kcOw',
    id: 1463,
    acronym: 'KPL',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'kug.ac.at',
    name: 'Universität für Musik und darstellende Kunst Graz',
    web_page: 'http://www.kug.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsBw_lNoKUXmnSWRQ-2zBb6GCu1dspQmu0xojum8I-f_EbI8rmd7_4aKO8',
    id: 1464,
    acronym: 'UFMUDKG',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'mci.edu',
    name: 'MCI-Management Center Innsbruck',
    web_page: 'http://www.mci.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRslwy9aWsWVEoPQCZKf7n_yVGuxZiJQDO5GVvDtgJ3dZ0wOUusDJM-y60',
    id: 1465,
    acronym: 'MCI',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'mdw.ac.at',
    name: 'Universität für Musik und darstellende Kunst  Wien',
    web_page: 'http://www.mdw.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSGaTFB17DFMb8CjX50R4SaT0d-mACbQHTW7a-v8CnlllS5Hy8gB9FTg',
    id: 1466,
    acronym: 'UFMUDKW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'meduni-graz.at',
    name: 'Medizinische Universität Graz',
    web_page: 'http://www.meduni-graz.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4VVZJ6VsZZYjRHny0WKSJNWKZ-HhMXD0GUS2X2C_ZwBxLZyZGaAiGuw',
    id: 1467,
    acronym: 'MUG',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'meduniwien.at',
    name: 'Medizinische Universität Wien',
    web_page: 'http://www.meduniwien.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTierk_xQYYojxKnzgdZ-dH1UA8r4OwMs4P7pbIE6DvOCv1y1_NPbDt2uY',
    id: 1468,
    acronym: 'MUW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'moz.ac.at',
    name: 'Universität Mozarteum Salzburg',
    web_page: 'http://www.moz.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5yWT4GELa1b7XsjLHmN_Bk7CvfoNXI6WEmwSo0mCw4VL3b20WMSC9uco',
    id: 1469,
    acronym: 'UMS',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'ns2.uni-ak.ac.at',
    name: 'Universität für angewandte Kunst Wien',
    web_page: 'http://ns2.uni-ak.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfpTailPEtHra4QEVso-0R4OMo1TjcoKMXg7kqScbANOXRaOxT0eF2LA',
    id: 1470,
    acronym: 'UFAKW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'sbg.ac.at',
    name: 'Universität Salzburg',
    web_page: 'http://www.sbg.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRXsHzhMwKt3lPqPIUBit0UPEYSPdrC1QbHdlZ9nmighoyrc-fU029auw',
    id: 1471,
    acronym: 'US',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'technikum-wien.at',
    name: 'Technikum Wien',
    web_page: 'http://www.technikum-wien.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8_nK63K56xkeMreuOiuwkWVWBb8Z4G1oRt3O3Kkft6ZqJSzd2C_wzSRQ',
    id: 1472,
    acronym: 'TW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'tugraz.at',
    name: 'Technische Universität Graz',
    web_page: 'http://www.tugraz.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlrc21QRr63UjYKjmTa5ObFc4C3ccW1lk_8q3U4bicSh9HgQw9uXyHeZo',
    id: 1473,
    acronym: 'TUG',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'tuwien.ac.at',
    name: 'Technische Universität Wien',
    web_page: 'http://www.tuwien.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_xYbTUAt96vx-My2vMaMitO6bzcZXx1nMjNg8Ay-tkKPpg6MYRMY7c-w_',
    id: 1474,
    acronym: 'TUW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'uibk.ac.at',
    name: 'Universität Innsbruck',
    web_page: 'http://www.uibk.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdlmL7x0nKo6oW-XN-4Z3AlqibDz22afH_kAJZJZJVzkBz-ogcOmN-US7S',
    id: 1475,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'umit.at',
    name: 'Private Universität für Medizinische Informatik und Technik Tirol',
    web_page: 'http://www.umit.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8sdlO9QdgQpSoOTKXksVs8oo3j2irdZFPC3PnYeqOlI3-0ddmysiZgw',
    id: 1476,
    acronym: 'PUFMIUTT',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'uni-klu.ac.at',
    name: 'Universität Klagenfurt',
    web_page: 'http://www.uni-klu.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZgJ2wt4R2vNKXL_6hGxudkDLR2jvJL0PxiVkqXgca1AKa4VF5NW9Y-tk',
    id: 1477,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'unileoben.ac.at',
    name: 'Montanuniversität Leoben',
    web_page: 'http://www.unileoben.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSELC9CfG9SCdsfYDGoMBw_Y72Gz0cb0LsRGcVoUM2V1d9mbtVX28ZLIA',
    id: 1478,
    acronym: 'ML',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'uni-linz.ac.at',
    name: 'Universität Linz',
    web_page: 'http://www.uni-linz.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8ZWaVobZjcNDsHNTjWvGVIDixleqVuvrTRpzgWCU3z8tm8xJIRRROeTE',
    id: 1479,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'univie.ac.at',
    name: 'Universität Vienna',
    web_page: 'http://www.univie.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj9-h2UQdq5-_cub5TlohniGMZ9C07Ef7wMdD1ywG5QSUEpDG_k0cPFDum',
    id: 1480,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'vu-wien.ac.at',
    name: 'Veterinärmedizinische Universität Wien',
    web_page: 'http://www.vu-wien.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTierk_xQYYojxKnzgdZ-dH1UA8r4OwMs4P7pbIE6DvOCv1y1_NPbDt2uY',
    id: 1481,
    acronym: 'VUW',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'webster.ac.at',
    name: 'Webster University Vienna',
    web_page: 'http://www.webster.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrFDCnPwhjgzerCPTKZhLf17BW7w-lHNMW8NEYaoev4Rxk-fd2rQKkOxH-',
    id: 1482,
    acronym: 'WUV',
  },
  {
    alpha_two_code: 'AT',
    country: 'Austria',
    domain: 'wu-wien.ac.at',
    name: 'Wirtschaftsuniversität Wien',
    web_page: 'http://www.wu-wien.ac.at/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq2c71QVpIQqjFFTpk3naw5Uk2YVTHTICnBzYHJdWe24euFDi7mPGpfQ',
    id: 1483,
    acronym: 'WW',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'ada.edu.az',
    name: 'Azerbaijan Diplomatic Academy',
    web_page: 'http://www.ada.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_-qaKPAx7FioDbWvuvE6atNB6Xd2OZAK7AVQgYCiEHl_c6baOAiY6vg',
    id: 1484,
    acronym: 'ADA',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'adau.edu.az',
    name: 'Azerbaijan State Agricultural Academy',
    web_page: 'http://www.adau.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyQ2jOEcEBgQuPZyi3EGiqct9DQVE9Ke-nzBEZD04kFgeD3qvwT8NDcg',
    id: 1485,
    acronym: 'ASAA',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'ascca.gov.az',
    name: 'Azerbaijan State Customs Committee Academy',
    web_page: 'http://www.ascca.gov.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwQeyeBGa_ISC7W28-BlKJMrAQ8D6LrlSm-vau_LtepTSLAeKe-Q4uMWTz',
    id: 1486,
    acronym: 'ASCCA',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'asoiu.edu.az',
    name: 'Azerbaijan State University of Oil and Industry',
    web_page: 'http://www.asoiu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ14Lk79LKoM5VMZiBb9SAunRBE-iOrlodTXhNeGNNi-R4bJuzz4QDzCA',
    id: 1487,
    acronym: 'ASUOI',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'adda.edu.az',
    name: 'Azerbaijan State Marine Academy',
    web_page: 'http://www.adda.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjAUW8mLEDOEh-wx761_0gEDiuKBL7ccD8AyXwI8m59IKNebft3FdOYBE7bw',
    id: 1488,
    acronym: 'ASMA',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'adpu.edu.az',
    name: 'Azerbaijan State Pedagogic University',
    web_page: 'http://www.adpu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2n8G8V_brISOP0ISCvDuiTHkgRSkS4XCztqL8jX91Bqb0Azsh84cWFRY',
    id: 1489,
    acronym: 'ASPU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'azmiu.edu.az',
    name: 'Azerbaijan University of Architecture and Construction',
    web_page: 'http://www.azmiu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTOCA6i2gf5gfbfNnkKOS9-Hu17JiYDec2qFMHswKGnmAR2n7lHi3kZqg',
    id: 1490,
    acronym: 'AUAC',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'adu.edu.az',
    name: 'Azerbaijan University of Languages',
    web_page: 'http://www.adu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGjbni1pgfGaHlJFlFOYGgNyzy40dWdw0tAUqY3mPyeSIukj8dadHr4Rk',
    id: 1491,
    acronym: 'AUL',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'amu.edu.az',
    name: 'Azerbaijan Medical University',
    web_page: 'http://amu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZXRVsKfobfVc1BABFJ4ZWbDhxa2G3BdtP3bgl-UVPp4bDN76mg8brUCY',
    id: 1492,
    acronym: 'AMU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'unec.edu.az',
    name: 'Azerbaijan State University of Economics',
    web_page: 'http://unec.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvsnGNmx951_oKVPxU9rCl8oQEosCAduJ8g-fS-aYPI1xlh2FAXROHv05xyg',
    id: 1493,
    acronym: 'ASUE',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'admiu.edu.az',
    name: 'Azerbaijan State University of Culture and Arts',
    web_page: 'http://www.admiu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDClUyUiQDKznRnns7C1yNmRIvDafeWCePmPjauvGaWVDHoEA9PvW1mfp3ow',
    id: 1494,
    acronym: 'ASUCA',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'au.edu.az',
    name: 'Azerbaijan University',
    web_page: 'http://www.au.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGjbni1pgfGaHlJFlFOYGgNyzy40dWdw0tAUqY3mPyeSIukj8dadHr4Rk',
    id: 1495,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'az-npu.org',
    name: 'Nakhchivan Private University',
    web_page: 'http://www.az-npu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRul962NiDIZigW6i5ldHSckMEoJsHR0VWfNPfNN3mosd2lXkdivDlvWg',
    id: 1496,
    acronym: 'NPU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'aztu.edu.az',
    name: 'Azerbaijan Technical University',
    web_page: 'http://www.aztu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGjbni1pgfGaHlJFlFOYGgNyzy40dWdw0tAUqY3mPyeSIukj8dadHr4Rk',
    id: 1497,
    acronym: 'ATU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'musicacademy.edu.az',
    name: 'Baku Academy of Music',
    web_page: 'http://www.musicacademy.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1jZljbGSizMFxIoGEH91GCiJ87XpzRVE5Ec1h7P1rhwAr5Rc9lmBEON0',
    id: 1498,
    acronym: 'BAM',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'bbu.edu.az',
    name: 'Baku Business University',
    web_page: 'http://www.bbu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ9_97n2Rjd2hA_ZXSiTiKWm2KwvOXYADpgi0lUBOqRGK1LHES64oensE',
    id: 1499,
    acronym: 'BBU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'bsu.edu.az',
    name: 'Baku State University',
    web_page: 'http://bsu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGjbni1pgfGaHlJFlFOYGgNyzy40dWdw0tAUqY3mPyeSIukj8dadHr4Rk',
    id: 1500,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'bsu-edu.org',
    name: 'Baku Slavic University',
    web_page: 'http://www.bsu-edu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1501,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'conservatory.az',
    name: 'Azerbaijan National Conservatorie',
    web_page: 'http://www.conservatory.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1502,
    acronym: 'ANC',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'dia.edu.az',
    name: 'The Academy of Public Administration',
    web_page: 'http://www.dia.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIPW1lGQVjEUPMenbFVtO0FZSQjlSujq-R8Wz7_0RTu9iFJHnUS4kMbP0',
    id: 1503,
    acronym: 'APA',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'gdu.edu.az',
    name: 'Ganja State University',
    web_page: 'http://gdu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhz5QMfOO0-X-B8JkngnaJnzvIHEi-a9yLl2V9ul7vW0EKu_UTLw29zXK_Qg',
    id: 1504,
    acronym: 'GSU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'khazar.org',
    name: 'Khazar University',
    web_page: 'http://www.khazar.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtWcrH3OoWhD6HfQrwzVRwKw9QMe9mroq6lROtEcUbBbn2ETYMwDdWpTjjpw',
    id: 1505,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'lsu.edu.az',
    name: 'Lankaran State University',
    web_page: 'http://www.lsu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgCuG_0UTAX4VzzqopMBDVwXMiaaOLZnQ6jSwM29-miMcJJ4RsSW5Z3Q',
    id: 1506,
    acronym: 'LSU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'mdu.edu.az',
    name: 'Mingechevir State University',
    web_page: 'http://www.mdu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO9Vfajm_v4ecNaPfALwGVndjrRE57Zac8CDRoR7ohzbOvirezj_cW9SY',
    id: 1507,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'naa.edu.az',
    name: 'National Aviation Academy',
    web_page: 'http://www.naa.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTluLRmbFDi41x7hES-QduZqml4wvfM6k2jvZNCeaEGAbOCAFtESfUgrPk',
    id: 1508,
    acronym: 'NAA',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'ndu.edu.az',
    name: 'Nakhchivan State University',
    web_page: 'http://www.ndu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGh6hJZcNMmzuc21ak0nXDGrP8WZZkkeWVYsU85gJyI3S6xyWqwJRpBtg',
    id: 1509,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'oyu.edu.az',
    name: 'Odlar Yurdu University',
    web_page: 'http://www.oyu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXIB5pt0WyBmJ9w5KhBvAR_rEw5OPp6pZpNw_oKj1OM4XT64un5OACKyY',
    id: 1510,
    acronym: 'OYU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'pa.edu.az',
    name: 'Police Academy',
    web_page: 'http://www.pa.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR-BF1tmPNrwm6un89zfnS0pbVXSBGx8cUWnhiQLuo_TDG-8x9LYGZZC0',
    id: 1511,
    acronym: 'PA',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'qu.edu.az',
    name: 'Qafqaz University',
    web_page: 'http://www.qu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH9-wwtvcxOLJEnpq4J1gIGyTSQ9m0EYriGALzdzSSUQAszdoKqRg4reY',
    id: 1512,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'sdu.edu.az',
    name: 'Sumgait State University',
    web_page: 'http://www.sdu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT3y3-G5TDMXfFI9mgcFcfizj124-z8YL7pt0Wsl2AjVT0fAXMKRTpJl2C',
    id: 1513,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'tourism.edu.az',
    name: 'Azerbaijan Toursim Institute',
    web_page: 'http://www.tourism.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNhlcyayujAspKBlbZo-ml-vs7E5QPsQlLlW9NMSdQOkXijSn3hzHAow',
    id: 1514,
    acronym: 'ATI',
  },
  {
    alpha_two_code: 'AZ',
    country: 'Azerbaijan',
    domain: 'wu.edu.az',
    name: 'Western University',
    web_page: 'http://www.wu.edu.az/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbDshgbioGHxy__2jkr3MxpxeMNCc487bGAaGWSSeOe419T5aHvGDckD0',
    id: 1515,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'BS',
    country: 'Bahamas',
    domain: 'cob.edu.bs',
    name: 'The College of The Bahamas',
    web_page: 'http://www.cob.edu.bs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJZKbFSAMuaoLAZ30SHXz9d2PrY_TGwl43ILi35czCTuV2MrTnC7Dh658',
    id: 1516,
    acronym: 'CB',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'agu.edu.bh',
    name: 'Arabian Gulf University',
    web_page: 'http://www.agu.edu.bh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgvU6TON-JCMAc14L661Wa8me3QM3znYqZ2heVEO8CH_zDksl5AfKX5Q',
    id: 1517,
    acronym: 'AGU',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'ahliauniversity.org',
    name: 'Al Ahlia University',
    web_page: 'http://www.ahliauniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLYmNUita_jXKszgia5wdMsL21-MLmjzkFClPvfWLaW0yQym-BeuwRHA',
    id: 1518,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'amaiu.edu.bh',
    name: 'Ama International University',
    web_page: 'http://www.amaiu.edu.bh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXuaS00VP1mqnuJsjK82-Ti9qwzVdHXnhIUiB4uOKWnsGJ1W_2errDog',
    id: 1519,
    acronym: 'AIU',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'aou.org.bh',
    name: 'Arab Open University',
    web_page: 'http://www.aou.org.bh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLfOw0Oue3iwLCw7NtngUUdevLfWqtHqAJEOSgESoWsFwNSwJdmpln0Qw',
    id: 1520,
    acronym: 'AOU',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'asu.edu.bh',
    name: 'Applied Science University',
    web_page: 'http://www.asu.edu.bh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJQsgN5TCmp6lh4VjJ9ZUip88kYyfNGxo4EgY-zq-4zJLyaN8EFg7fd_I',
    id: 1521,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'gulfuniversity.net',
    name: 'Gulf University College',
    web_page: 'http://www.gulfuniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiOoTtiAuAhCfEPxrj46VfmVeWTBYDSnHLy50g4rTND0oU-7-E8XTTlw',
    id: 1522,
    acronym: 'GUC',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'ku.edu.bh',
    name: 'The Kingdom University',
    web_page: 'http://www.ku.edu.bh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjwyxkNa2EM-wafJhX5nTnrIsJcgjaEs9u0uyCEPGNjaV-V8sd3rPKGbc',
    id: 1523,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'nyit.edu.bh',
    name: 'New York Instiute of Technology',
    web_page: 'http://www.nyit.edu.bh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHZDfHD2Gzmb5LtoE9DyG_sME__91_HTzPZuHbLHhT7IGrUsDFqhpqIA',
    id: 1524,
    acronym: 'NYIT',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'rcsi-mub.com',
    name: 'RCSI-Medical University of Bahrain',
    web_page: 'http://www.rcsi-mub.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShoSAXl-2ef-adZ2Hfomv9GdyPbG7_XWTFGh-pxBkiaEXMuLMfCXZnaEss',
    id: 1525,
    acronym: 'RUB',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'ucb.edu.bh',
    name: 'University College Bahrain',
    web_page: 'http://www.ucb.edu.bh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJCzqXl4BjkrN8EtaGXy6ZotW423GS1UAh7wiT3Apbbc8vdNQvcvTvwQ',
    id: 1526,
    acronym: 'UCB',
  },
  {
    alpha_two_code: 'BH',
    country: 'Bahrain',
    domain: 'uob.edu.bh',
    name: 'University of Bahrain',
    web_page: 'http://www.uob.edu.bh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR06au-J4R4sEOqGC0zcn6wjsE9miEQoEn7dzC0cvV8CANqIz67I965XFNr',
    id: 1527,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'aiub.edu',
    name: 'American International University - Bangladesh',
    web_page: 'http://www.aiub.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmLyS6ZJKW05U5Zn9WwQxpCSGPjEwdRTTvvHOk2bZ4Ii_HOhqoW1iCIQ',
    id: 1528,
    acronym: 'AIU-B',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'asaub.edu.bd',
    name: 'Asa University Bangladesh',
    web_page: 'http://www.asaub.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCKkjjB6a2tzbByBXAIUzHcZ-sNSFMerOUNSiQ3MYRXFxDhFlA2Sii0sXXmA',
    id: 1529,
    acronym: 'AUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'atishdipankaruniversity.edu.bd',
    name: 'Atish Dipankar University',
    web_page: 'http://www.atishdipankaruniversity.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpGuFMs3OBHIy5X2cOOfRpaUabdnZubNE0hO_rnvCZgzXmEWYMpddoc1U',
    id: 1530,
    acronym: 'ADU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'aub-bd.org',
    name: 'Asian University of Bangladesh',
    web_page: 'http://www.aub-bd.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZRCfeeAvu5JAAKirb6QjLP_NUcWEHhXEvo2gMg-12RlDm2kf_PEcJ8NM',
    id: 1531,
    acronym: 'AUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'aust.edu',
    name: 'Ahsanullah University of Science & Technology',
    web_page: 'http://www.aust.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsKHshF4yu_ZkYAHRN9bqX6sjm6VJph0Sm0cOONu78rQ2pSf-VbEx_VA',
    id: 1532,
    acronym: 'AUS&T',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bau.edu.bd',
    name: 'Bangladesh Agricultural University',
    web_page: 'http://www.bau.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSms_rpbWTJH8ltrsEnmHGsG7nOvBpVDhpNtjisu6ZYZaLbfVypz8O-2os',
    id: 1533,
    acronym: 'BAU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bgctrustbd.org',
    name: 'BGC Trust University Bangladesh',
    web_page: 'http://www.bgctrustbd.org/btub/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG9zL4VTS3xmhHCZ1sjJEVdNPCdGiUV77wbKFt1_xFHqeJGtB1Mz6JlTT5ug',
    id: 1534,
    acronym: 'BTUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bou.edu.bd',
    name: 'Bangladesh Open University',
    web_page: 'http://www.bou.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI8PRJjkzIKQ6cpCpXhWK7JvlTGZb0XDv9QmkSTN5ApA9rz2adnTlLkpem',
    id: 1535,
    acronym: 'BOU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bracuniversity.net',
    name: 'Brac University',
    web_page: 'http://www.bracuniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQamDl0Bz0gkZWjIhggVE7U6XTxurka9Y16Jk2wkfGyiVogxXfawh3Xu0Y',
    id: 1536,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'brur.ac.bd',
    name: 'Begum Rokeya University Rangpur',
    web_page: 'http://www.brur.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT34TWqx9pYONFg7mf5KVCqYzZvHHxgRugyNtjEOw29kLVKylUSvBG5jI0',
    id: 1537,
    acronym: 'BRUR',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bsmmu.edu.bd',
    name: 'Bangabandhu Sheikh Mujibur Rahman Medical University',
    web_page: 'http://www.bsmmu.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ96KDe5mw93XtT_j47CDKtuIXMaJ7qNzBQtuIPErmVJ4rX1ZNZqspyHyE',
    id: 1538,
    acronym: 'BSMRMU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bsmrau.edu.bd',
    name: 'Bangabandhu Sheikh Mujibur Rahman Agricultural University',
    web_page: 'http://www.bsmrau.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQW9R4crLRAj1ddtkb2hegi9_jilra-3YN1NZLjQ38C1Zva1TTyUX93euY',
    id: 1539,
    acronym: 'BSMRAU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bubt.edu.bd',
    name: 'Bangladesh University of Business & Technology',
    web_page: 'http://www.bubt.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt3Z5G7jjW9G0efG5fSJY28yiaNbFju97ltNcus1AwNKu4VMPLShsF_V4',
    id: 1540,
    acronym: 'BUB&T',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bu.edu.bd',
    name: 'Bangladesh University',
    web_page: 'http://www.bu.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSms_rpbWTJH8ltrsEnmHGsG7nOvBpVDhpNtjisu6ZYZaLbfVypz8O-2os',
    id: 1541,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'buet.ac.bd',
    name: 'Bangladesh University of Engineering and Technology',
    web_page: 'http://www.buet.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgZtOskL9ipCYkHQV4M2nEkumUxwMlqk5kK5QyAzP_lz0FqsZN3WDSp9fo',
    id: 1542,
    acronym: 'BUET',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'bup.edu.bd',
    name: 'Bangladesh University of Professionals',
    web_page: 'http://www.bup.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXiUnrdWssH0MwdL26OV4RUo8xZgUoVyNyH1SawGY-hm8KRtaGCvGbNp48',
    id: 1543,
    acronym: 'BUP',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'butex.edu.bd',
    name: 'Bangladesh University of Textiles',
    web_page: 'http://www.butex.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd2b_MllYQVwfMJP2AE4AQtGNIQhfWcVgL6_rDuRBH8Ij7cIQARYa1hA',
    id: 1544,
    acronym: 'BUT',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'cou.ac.bd',
    name: 'Comilla University',
    web_page: 'http://www.cou.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqtPepUjJQ-PQh5GqwiStVQ72GS_WDUpwWh5RJqsrch9C35JCCsA0A2Q',
    id: 1546,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'cu.ac.bd',
    name: 'University of Chittagong',
    web_page: 'http://www.cu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUzJ91TiNtSrNMI07equ8JBzyIuaPEhXwMd5tGUDJ2UXCMBZujytCBsg',
    id: 1547,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'cuet.ac.bd',
    name: 'Chittagong University of Engineering and Technology',
    web_page: 'http://www.cuet.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIFo3HDKD2z3BJMEgMyp9Up24wuYPRW7sL5XgK__QW6pp09Wak5egnwb0',
    id: 1548,
    acronym: 'CUET',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'daffodilvarsity.edu.bd',
    name: 'Daffodil International University',
    web_page: 'http://www.daffodilvarsity.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYC-5E7AIyGa8wlYC60BiK-6FlcQRze9dydzL1cMkqQj1Td1BXnEsoHw',
    id: 1549,
    acronym: 'DIU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'diu.ac.bd',
    name: 'Darul Ihsan University',
    web_page: 'http://www.diu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq8ttv_Hta2260Vqych9FSRFyRWsnEPwuGAWP7Woz24gdyNevlI1rShR4G',
    id: 1550,
    acronym: 'DIU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'diu.net.bd',
    name: 'Dhaka International University',
    web_page: 'http://www.diu.net.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPCt4-WsUwkVE0MvD-EUmuqhLQ7A5NzOP7acDq5eTikehdS4cqouimRYKT',
    id: 1551,
    acronym: 'DIU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'du.ac.bd',
    name: 'University of Dhaka',
    web_page: 'http://www.du.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVb4hCVJrKLSfITI6A6CBP3yzPFfLB4rfGjNifIOqTlku1bNEW5IQDBQ',
    id: 1552,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'duet.ac.bd',
    name: 'Dhaka University of Engineering and Technology',
    web_page: 'http://www.duet.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC6_qQijE7S1VzdzkJ8k3sInki46RHdCKNYAHCP2-6OBqhXW8_UjOESUQ',
    id: 1553,
    acronym: 'DUET',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'eastdelta.edu.bd',
    name: 'East Delta University',
    web_page: 'http://www.eastdelta.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR7umawI04m3d07M5KN-OoEmQEZi_vLd5ucRaNKERCWTDLsuNiGANLHg',
    id: 1554,
    acronym: 'EDU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'easternuni.edu.bd',
    name: 'Eastern University',
    web_page: 'http://www.easternuni.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrnIFxj2Z8xlPwqwH5FmJzABDdAej_U0CHzNNlkmwMDfQ_E42Xj15lmw',
    id: 1555,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'ewubd.edu',
    name: 'East West University',
    web_page: 'http://www.ewubd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo-0zxOZ_18sLM_QAMeZ3jCuX39zjeXOymXDNl5zo2PVlDMwn07J96Cks',
    id: 1556,
    acronym: 'EWU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'ewubd.edu',
    name: 'East-West University Mohakhali',
    web_page: 'http://www.ewubd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWzumwZM4-FyAsWidwFWmFHIcCDAFXStRgv5r1hFg-WjgiRA1y_1Rv-GA',
    id: 1557,
    acronym: 'EUM',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'green.edu.bd',
    name: 'Green University of Bangladesh',
    web_page: 'http://www.green.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS63eIsc653BcAl1YEX7hHtUTNclX-y6K2uyrvluZ2j6tUXAJpPzB3g5VWe',
    id: 1558,
    acronym: 'GUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'hstu.ac.bd',
    name: 'Hajee Mohammad Danesh Science and Technology University',
    web_page: 'http://www.hstu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJTQYKjSvDMMIcIu4VAovrjzxQ1a_DUM8MP4eveAH9HBvINll6S6gOA',
    id: 1559,
    acronym: 'HMDSTU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'ibaisuniv.edu.bd',
    name: 'IBAIS University',
    web_page: 'http://www.ibaisuniv.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQo0kB4ZFjexZlK-WosXM70dRUORVe6_eDiXrw5GNTqQQRo_UPVGED3D88X',
    id: 1560,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'icu-edu.org',
    name: 'International Culture University',
    web_page: 'http://www.icu-edu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFPMsgTdj5PSGgrIGebWMYg7s165P0mKc2S8en3JDS4zVG0sE4RRYyOmM',
    id: 1561,
    acronym: 'ICU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'iiuc.ac.bd',
    name: 'International Islamic University Chittagong',
    web_page: 'http://www.iiuc.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV2wP0BLmWYquhyTNscOgoutFWCJrlbOimhTu9dJy6I598dDp6QesI_Ko',
    id: 1562,
    acronym: 'IIUC',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'iu.ac.bd',
    name: 'Islamic University Kushtia',
    web_page: 'http://www.iu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6hs0TThtF16WTwBMQifM8xirDqFn7iKZHO_DmTE7axPPqI1cgRvSPbtI',
    id: 1563,
    acronym: 'IUK',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'iubat.edu',
    name: 'International University of Business Agriculture and Technology',
    web_page: 'http://www.iubat.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5rYZOua2pT_A4VbE-3JyK2nI4so3ww0W-OREBnYfGb2ECNUFbhBgTPqI',
    id: 1564,
    acronym: 'IUBAT',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'iub.edu.bd',
    name: 'Independent University Bangladesh',
    web_page: 'http://www.iub.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqcadjvBxPAnkqqeiyIUH5hQzBqwmTAZKLwYhb-R93SRI6hU3YLxkZzdoF',
    id: 1565,
    acronym: 'IUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'iutoic-dhaka.edu',
    name: 'Islamic University of Technology',
    web_page: 'http://www.iutoic-dhaka.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyznJrHLIXtklhq50oQcegXlpQ-wsJzO74xuby4HcXRZfamoifhvKiIpE',
    id: 1566,
    acronym: 'IUT',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'jkkniu.edu.bd',
    name: 'Jatiya Kabi Kazi Nazrul Islam University',
    web_page: 'http://www.jkkniu.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLwKZvx6jf6faOnA_TH_oW6Qco4BTM-SRlGEe9Kc4r6Y99IGtxH5N41xQ',
    id: 1567,
    acronym: 'JKKNIU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'jnu.ac.bd',
    name: 'Jagannath University',
    web_page: 'http://www.jnu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS05fO1AlolGz1ApTubSgwue46vH7puP0DKOFDYyW6gmmzWE2ltbV47joU',
    id: 1568,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'juniv.edu',
    name: 'Jahangirnagar University',
    web_page: 'http://www.juniv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjCxHRx8DPWw6S44pFGnGxk-nX9-D5AHgBIJvmRZ9rZac8-BQm5ZGIkA',
    id: 1569,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'ku.ac.bd',
    name: 'Khulna University',
    web_page: 'http://www.ku.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHq33QLDwddzzLCCqvnx9sZgjCWJ9ZuIrLkluAyJUF6cX9c2dj1NPK7Q',
    id: 1570,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'kuet.ac.bd',
    name: 'Khulna University of Engineering And Technology',
    web_page: 'http://www.kuet.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRom38UEuGoK9ctZGLSjEMgCONWq6Hx5aUBrZBY7vEVI_7PNY2uYcU3SXM',
    id: 1571,
    acronym: 'KUET',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'lus.ac.bd',
    name: 'Leading University',
    web_page: 'http://www.lus.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpXciFb3tEcR1SZNgFbJslSrQogFIIWrhS0-lUT4PJqvODgAPrrmZ_Zg',
    id: 1572,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'manarat.ac.bd',
    name: 'Manarat International University',
    web_page: 'http://www.manarat.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLQD0ZUzE49OLE7JxXLruZrWtXtfRj6FwhraFsC9PNEKpFogyhbJg1tdk',
    id: 1573,
    acronym: 'MIU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'mbstu.ac.bd',
    name: 'Mawlana Bhashani Science And Technology University',
    web_page: 'http://www.mbstu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWEa4kqTK3-kFSx8l-3-SQTuntdhcbfd2iCtB3hmdKPNBvRFH_Mb2yIXU',
    id: 1574,
    acronym: 'MBSTU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'metrouni.edu.bd',
    name: 'Metropolitan University',
    web_page: 'http://www.metrouni.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKm5QZVSfPle__LaPOd8T4kBSWtkxjg9kPsxP6qqwo-paHFf_HeodvpA',
    id: 1575,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'mist.ac.bd',
    name: 'Military Institute of Science and Technology',
    web_page: 'http://www.mist.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRfldxHH7Xd8PkTdvHN7aHg2iPDBJAUzpiY9DTy8uLxgcE4bFRX59cWX635A',
    id: 1576,
    acronym: 'MIST',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'neub.edu.bd',
    name: 'North East University Bangladesh',
    web_page: 'http://www.neub.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3M4DY4q7ZmT_Vlw60kzYcVeLQwAsaB75BN9zpYydPcLtXhbDiw0kb9w',
    id: 1577,
    acronym: 'NEUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'northsouth.edu',
    name: 'North South University',
    web_page: 'http://www.northsouth.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM3ZgZzB8eRZ-Sew5iz-hIWXY0-e91dj98AfBeLSNzwv3bWrSph3CH6w',
    id: 1578,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'nstu.edu.bd',
    name: 'Noakhali University of Science and Technology',
    web_page: 'http://www.nstu.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCnVJU-0vhXazpUE_xtmzinL3_Ti6AJ-4Q8NfkUBTmRZt1rj7O2KLBAA',
    id: 1579,
    acronym: 'NUST',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'nub.ac.bd',
    name: 'Northern University Bangladesh',
    web_page: 'http://www.nub.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIHZP6OmdYc-dwyiI0rqr8qGd2m03WPS4ie-bkxA-XZCKMHlD0zm2tvk2Flg',
    id: 1580,
    acronym: 'NUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'presidency.edu.bd',
    name: 'Presidency University',
    web_page: 'http://www.presidency.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFjwbQ80I-wfkatac5R3doPoZt36U1JsRvKEZctdxM5wM6rLD2dRRSQg',
    id: 1582,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'pstu.ac.bd',
    name: 'Patuakhali Science and Technology University',
    web_page: 'http://www.pstu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShhBXnQ78nZDt5k5IA496fChRTFGu-5Q_B7yOByK86PwMDfwSfq4qmh_k3',
    id: 1583,
    acronym: 'PSTU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'pub.ac.bd',
    name: "People's University of Bangladesh",
    web_page: 'http://www.pub.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFq0fUuXk_LmOk07AxpNlM9_u5D0cJIedmQ7TaJq_Bwr6l0ZP21fAS_dRUmw',
    id: 1584,
    acronym: 'PUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'puc.ac.bd',
    name: 'Premier University',
    web_page: 'http://www.puc.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2QTUnHbiLeZDdVGJ4LUx85FjMQVvjfY8r6kuJ5VsHAC0fcXqVbvT6etM',
    id: 1585,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'pust.ac.bd',
    name: 'Pabna University of Science and Technology',
    web_page: 'http://www.pust.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5tAixffZ5yw2Xd19YOfGruO-SAIsJOPkBtn8Vw7beEd9T-Rr4Htx-8g',
    id: 1586,
    acronym: 'PUST',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'queensuniversity.edu.bd',
    name: 'Queens University',
    web_page: 'http://www.queensuniversity.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkqdhD64a3t5zdxQzVn2hnIXSFKZ9xZDviFoo4cH6d73npIjsE89B8eCg',
    id: 1587,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'ru.ac.bd',
    name: 'University of Rajshahi',
    web_page: 'http://www.ru.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaqCf5rfDVWcLusSvYSdwZqJzXD5WrnlHG5sCrqsw3BIKPEBcuT8mUDM8',
    id: 1588,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'ruet.ac.bd',
    name: 'Rajshahi University of Engineering and Technology',
    web_page: 'http://www.ruet.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmeb6kdPMbcKLEK1O-egkIX-TEvfuPRoDrurDzUUKRTiFpwL9kuhClk7A',
    id: 1589,
    acronym: 'RUET',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'sau.ac.bd',
    name: 'Sylhet Agricultural University',
    web_page: 'http://www.sau.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBn7adaI9n9U5fUxGL9AeMmDHc7SomZebmN2hNrLfWbJ7H-HXVjRGkkg',
    id: 1590,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'sec.ac.bd',
    name: 'Sylhet Engineering College',
    web_page: 'http://www.sec.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC7O6h-9oeSicc51bxyIwTH7L9rtblve_W1iQzkJLpboCyZen47Y4XSfY',
    id: 1591,
    acronym: 'SEC',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'seu.ac.bd',
    name: 'Southeast University',
    web_page: 'http://www.seu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuDochylVAFgl3HG4gAx1v5D6Hf0kk-D3StHGPqu-T84KAvoxG9WSEywg',
    id: 1592,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'siu.edu.bd',
    name: 'Sylhet International University',
    web_page: 'http://www.siu.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxR54CafVE1G_j3W20wvMFrLlZI2ydRECfaeFCO1w3dzWOVN2JmJx_Hd4',
    id: 1593,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'southern.edu.bd',
    name: 'Southern University Bangladesh',
    web_page: 'http://www.southern.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzq2nZomn4gphAmsroBVVNKDym0KzysFsc6tfvB6V8kkOm35HiZjpC6gCa',
    id: 1594,
    acronym: 'SUB',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'stamforduniversity.edu.bd',
    name: 'Stamford University',
    web_page: 'http://www.stamforduniversity.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ28p5HDGYXxfwNIV3pxzLi4X0bgIhcxRsD9dFfZiP77T5q8ZSpZzCnWA',
    id: 1595,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'sust.edu',
    name: 'Shahjalal University of Science and Technology',
    web_page: 'http://www.sust.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqobBE-UJz1isyYBhDkwdD_8mWnsbD7Pl79PuN4VaH4QeERUgwMbmMew',
    id: 1596,
    acronym: 'SUST',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'uap-bd.edu',
    name: 'University of Asia Pacific Dhanmondi',
    web_page: 'http://www.uap-bd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS72ypHNKeF-4K0__dDaUy2FnaLLGOyfYubUCFN_ObPz41xYmeSCD7JwA',
    id: 1597,
    acronym: 'UAPD',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'uits.edu.bd',
    name: 'University of Information Technology & Sciences',
    web_page: 'http://www.uits.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxFBVKB-lHG4hcKmAkO7FycL33XmvHMp6UpiGgTamBQwhuuUBOjW5smQ',
    id: 1598,
    acronym: 'UIT&S',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'uiu.ac.bd',
    name: 'United International University',
    web_page: 'http://www.uiu.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI59JUrz7gA9fUYkqTbC7N7F1fQuspikePUOFhNuAlATEpTaM7CwlVclFo',
    id: 1599,
    acronym: 'UIU',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'ulab.edu.bd',
    name: 'University of Liberal Arts',
    web_page: 'http://www.ulab.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYVx4L6j6CUOKvWsd26WuJDiWDSed3WndEuorZoWLZggsiMdQvMeMu1uY',
    id: 1600,
    acronym: 'ULA',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'uoda.edu.bd',
    name: 'University of Development Alternative',
    web_page: 'http://www.uoda.edu.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9yh8fDjcNaaCfd8gYCEDubsRgplTKKLlRsy1TUEdK-wgJdr5QjP0vvW4',
    id: 1601,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'ustc.ac.bd',
    name: 'University of Science & Technology Chittagong',
    web_page: 'http://www.ustc.ac.bd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRADbKLMORiF3j-nk2cSGkkRI5mRfPrLu-U3Ymu3BOqnJ-eQEDKrRLZTEY',
    id: 1602,
    acronym: 'US&TC',
  },
  {
    alpha_two_code: 'BD',
    country: 'Bangladesh',
    domain: 'wub.edu',
    name: 'World University of Bangladesh',
    web_page: 'http://www.wub.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdsjtXjNvgQBM5qyQWNJ7g7SMNZwYmDxb8oRQ2_OfibrdCCCyLNt6VoQ',
    id: 1603,
    acronym: 'WUB',
  },
  {
    alpha_two_code: 'BB',
    country: 'Barbados',
    domain: 'uwichill.edu.bb',
    name: 'University of the West Indies Cave Hill',
    web_page: 'http://www.uwichill.edu.bb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgmdok9WHrbBB1RiVTCxRqHkoylvgQvGMFOGCd6kh6BaN-I03B3aYh0A',
    id: 1604,
    acronym: 'UWICH',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'academy.mia.by',
    name: 'Academy of the Ministry of Internal Affairs of the Republic of Belarus',
    web_page: 'http://academy.mia.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlu4baraEX18D45GJbVRI0d2Hlzd-oRcrEjo2beI0GsGmKk9nOdDDGMzA',
    id: 1605,
    acronym: 'AMIARB',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'baa.by',
    name: 'Belarussian State Agricultural Academy',
    web_page: 'http://www.baa.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTghnxn6xny98e-69mPJ4VvivIhQP2H5GJXKvSIU3gBQ7pUSAcjF56TgaY',
    id: 1606,
    acronym: 'BSAA',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'batu.edu.by',
    name: 'Belarussian State Agrarian Technical University',
    web_page: 'http://www.batu.edu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuJ9SH8dGCW1S0SGM1NrU1TrtGayjIethrsb0VsjBd8VmCABfLDnka0p0',
    id: 1607,
    acronym: 'BSATU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'belsut.gomel.by',
    name: 'Belarussian State University of Transport',
    web_page: 'http://www.belsut.gomel.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6dGBG97JHmsI4c8XLKrlsKKeDeu85QM625dz2WdBRUjXsyhB3ueAxTQk',
    id: 1608,
    acronym: 'BSUT',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bgam.edu.by',
    name: 'Belarussian State Academy of Music',
    web_page: 'http://www.bgam.edu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTghnxn6xny98e-69mPJ4VvivIhQP2H5GJXKvSIU3gBQ7pUSAcjF56TgaY',
    id: 1609,
    acronym: 'BSAM',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bntu.by',
    name: 'Belarussian National Technical University',
    web_page: 'http://www.bntu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2uLm8gZyP3QUwubq2MhSB2yNikxys5dj5ebiSEv9bMAVxxwUTtHP75w',
    id: 1610,
    acronym: 'BNTU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'brsu.brest.by',
    name: 'Brest State University',
    web_page: 'http://www.brsu.brest.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHmQwU9s8IxsYpSyfLTz2KWY-7it3cyZYg6VLPj_7VZJthemgCDFYxrLc',
    id: 1611,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bru.mogilev.by',
    name: 'Belarusian-Russian University',
    web_page: 'http://www.bru.mogilev.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7c1OnSC7ylQ-0UUTj85-vstydITvPcfz-Cf8rvyvafwaweKX8GiyS65Do',
    id: 1612,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bseu.by',
    name: 'Belarussian State Economic University',
    web_page: 'http://www.bseu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvBwR99v9UfTNLhzbS4sn3wLi08TKodpKXKgBgjrzVYbmpPGnu1kNsT2rq',
    id: 1613,
    acronym: 'BSEU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bsmu.by',
    name: 'Belarussian State Medical University',
    web_page: 'http://www.bsmu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUTSKH-_1CIK2QdfsF3fWTZn-h1cf5CUqgihcuIwsUAghXz0O4MJIvow',
    id: 1614,
    acronym: 'BSMU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bspa.unibel.by',
    name: 'Belarussian State Polytechnical Academy',
    web_page: 'http://www.bspa.unibel.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOCgqUDU5AjUwwl7wIpKc-6nQdceZe0GjUSh6kES5GlL4Nn-6naRs1S0I',
    id: 1615,
    acronym: 'BSPA',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bspu.unibel.by',
    name: 'Belarussian State Pedagogical University M. Tanka',
    web_page: 'http://www.bspu.unibel.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1616,
    acronym: 'BSPUMT',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bstu.by',
    name: 'Brest State Technical University',
    web_page: 'http://www.bstu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUyb2L3umIjxNVrf5PVPUOy4EyTmwsOCR18bsT0vHRBwefjXSiW6FJzPGr',
    id: 1617,
    acronym: 'BSTU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bstu.unibel.by',
    name: 'Belarussian State Technological University',
    web_page: 'http://www.bstu.unibel.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt5kywUniL90CaQhdTlESssMB20wvzyo3hbnmN0VhuN_uycHt6t-i_IAc',
    id: 1618,
    acronym: 'BSTU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bsu.by',
    name: 'Belarussian State University',
    web_page: 'http://www.bsu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_nsASoOTH_kVLDCwdsQC-XdINplBJDztq9d1PRlifIyh7qJleSANkJQ',
    id: 1619,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'bsuir.by',
    name: 'Belarussian State University of Informatics and Radioelectronics',
    web_page: 'http://www.bsuir.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg3CAq1Q24iq7vUKEGx6GILXey_CuanW3DvvmZXeDVjd1QtoFdD56r-w',
    id: 1620,
    acronym: 'BSUIR',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'buk.by',
    name: 'Belarussian State University of Culture and Arts',
    web_page: 'http://www.buk.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6dGBG97JHmsI4c8XLKrlsKKeDeu85QM625dz2WdBRUjXsyhB3ueAxTQk',
    id: 1621,
    acronym: 'BSUCA',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'data.minsk.by',
    name: 'European Humanities University',
    web_page: 'http://www.data.minsk.by/ehu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZDY70WWbZf-AEdxTGxxitn4Eq0naYmIk8YlBTFWol6cadOOyIYXz-Q74',
    id: 1622,
    acronym: 'EHU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'grsmu.by',
    name: 'Grodno State Medical University',
    web_page: 'http://www.grsmu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQfSKvqZWXrd8waemISvpKe2uM1yzR9_dkznj5W6ye28eQ-Ol0EXhRkRJT',
    id: 1623,
    acronym: 'GSMU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'grsu.by',
    name: 'Grodno State University Yanka Kupaly',
    web_page: 'http://www.grsu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1hufq0rv9GjiyvA69IpTBDx40H032eME1o7bMWwWwvR9cDxp44KRE90g',
    id: 1624,
    acronym: 'GSUYK',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'gstu.gomel.by',
    name: 'Gomel State Technical University Pavel Sukhoi',
    web_page: 'http://www.gstu.gomel.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxudiE0mBl1UsDR8h0a4hzzPDQSYbI9BJmal0RNNnny2Da-M_qn7j3OSk',
    id: 1625,
    acronym: 'GSTUPS',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'gsu.unibel.by',
    name: 'Gomel State University Francisk Scarnia',
    web_page: 'http://www.gsu.unibel.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPcofWfNI5CSvMUL-x59sua1ujZzBo-ynL5czWokDOvhMZGffkOYndtQ',
    id: 1626,
    acronym: 'GSUFS',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'iseu.by',
    name: 'International Sakharov Environmental University',
    web_page: 'http://www.iseu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIxs32sURXZ5Bo1x0Y4SPurII9SovzT8iL8bzsOcOxWKAR-5NZia7E90A',
    id: 1627,
    acronym: 'ISEU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'kii.gov.by',
    name: 'Institute for Command Engineers of The Ministry for Emergency Situations',
    web_page: 'http://www.kii.gov.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt-4IXuUWihnjCOK-6TGIDDCvn2pGsksI01T8HNUMB77rSAmJsyEpy0Y_Qiw',
    id: 1628,
    acronym: 'IFCEMFES',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'meduniver.gomel.by',
    name: 'Gomel State Medical University',
    web_page: 'http://www.meduniver.gomel.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaZMx6g9D8FVTWNFYtbJ2jCNIe4CfIDRBMmoRgNC7h6NFUhbY_9XcgV_w',
    id: 1629,
    acronym: 'GSMU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'mgpu.gomel.by',
    name: 'Mozyr State Pedagogical Institute',
    web_page: 'http://www.mgpu.gomel.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDVXO1lSrfnf_oxe7m0WUN1CWTmW3TT7PeZNCAH7Aq_8NX6lh5KXP11f4',
    id: 1630,
    acronym: 'MSPI',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'miu.by',
    name: 'Minsk Institute of Management',
    web_page: 'http://www.miu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgRiWzbe9RqR7DFRrHdtSkyFGrUzSDzlwuXfSbXxKeDw0RApcrFgFWVAMM',
    id: 1631,
    acronym: 'MIM',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'mslu.by',
    name: 'Minsk State Linguistic University',
    web_page: 'http://www.mslu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfabi_xqpbAGXryOexFcdk4i0RDf-YOSYmqVC_2heoz_kN3EzDWvGbIdo',
    id: 1632,
    acronym: 'MSLU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'msu.mogilev.by',
    name: 'Mogilev State University A.A. Kuleshov',
    web_page: 'http://msu.mogilev.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNUull33gYjAeKnBISaAhZZf0YLgTeG6p5yWaML0WFBZg_XkEO1FknlDWY',
    id: 1633,
    acronym: 'MSUAK',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'pac.by',
    name: 'Academy of Public Administration of Belarus',
    web_page: 'http://www.pac.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAUMm2VAvUWi1H7PdUZBx9WnkgmK7c_QAzqBf6QIp9h_HBsRHLhT_BYwQ',
    id: 1634,
    acronym: 'APAB',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'psu.by',
    name: 'Polotsk State University',
    web_page: 'http://www.psu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1lTz_syKAOsqEqAfywZfJbxKS4gnd-fql93MrR08I-F3wt05PBKuMRiM',
    id: 1635,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'uni-agro.grodno.by',
    name: 'Grodno State Agrarian University',
    web_page: 'http://www.uni-agro.grodno.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYig-VzTfbOF-aEZr24O3X5FUM1zsXL_SROWSQIqyZiJ5gJZ59g-DHUz1p',
    id: 1636,
    acronym: 'GSAU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'vgmu.vitebsk.by',
    name: 'Vitebsk State Medical University',
    web_page: 'http://www.vgmu.vitebsk.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIUKh7PhFA0o3Z3veHZomCcc7zGYfIem5mWCq3YQoOlRG3KvR-izy1YNmZ',
    id: 1637,
    acronym: 'VSMU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'vsavm.com',
    name: 'Vitebsk State Academy of Veterinary Medicine',
    web_page: 'http://www.vsavm.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT_EHL3iJUnHKd9sUt9xOXyYOYMWi8iGrK537GHlx6VY4BZR9vyNiDKKs',
    id: 1638,
    acronym: 'VSAVM',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'vstu.vitebsk.by',
    name: 'Vitebsk State Technological University',
    web_page: 'http://www.vstu.vitebsk.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJFkV4BTKBTqrXmxuQuLiCB6rrSXaxjwfDE9dVwwpL3yWZRXTNPofOYMCh',
    id: 1639,
    acronym: 'VSTU',
  },
  {
    alpha_two_code: 'BY',
    country: 'Belarus',
    domain: 'vsu.by',
    name: 'Vitebsk State University',
    web_page: 'http://www.vsu.by/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnOlzaRR8198z1NGSqLAQHHglqePoAoUKDaVFuPGHQTRw8FNQ2KMn_R08',
    id: 1640,
    acronym: 'VSU',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'brexgata.eu',
    name: 'Brexgata University Academy',
    web_page: 'http://www.brexgata.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLLexTW-1aCK8VEoCCrGIEU6RmmjUIV_JjpyC4bwYpW8MCeWMSdYPTLg',
    id: 1641,
    acronym: 'BUA',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'carolus-magnus-university.eu',
    name: 'European Carolus Magnus University',
    web_page: 'http://www.carolus-magnus-university.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQerY1ZtXj0Zd5HiEKwQoyufch4JHnvJ51jLVUAP272WBG_7IXZJRnxE-u1',
    id: 1642,
    acronym: 'ECMU',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'coleurope.eu',
    name: 'College of Europe',
    web_page: 'http://www.coleurope.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjZS2wTikFCtnhEqNS2t4-G3u4lY1FctwDo64fB4o7jz4gWIB3i3NamzU',
    id: 1643,
    acronym: 'CE',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ctsem.edu',
    name: 'Continental Theological Seminary',
    web_page: 'http://www.ctsem.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo-JRLdbSCMtwVjVVb_Z7Vn9WGBKWOzzrTXOe0h-CH9Vc9MxjLG0Nkuw',
    id: 1644,
    acronym: 'CTS',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ecam.be',
    name: 'ECAM - Institut Supérieur Industriel',
    web_page: 'http://www.ecam.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxQJZphyu_b_-bkOhy6LBOZ6tkgpyMW8eNJyu8eC2exZAhb28VD2ewJrfZ',
    id: 1645,
    acronym: 'E-ISI',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ehb.be',
    name: 'Erasmushogeschool Brussel',
    web_page: 'http://www.ehb.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTksn8NCyU573UUTXgKZsAz4g7x2S5p84FkY2KJo7dGDI3mGlBWsGMLl1U',
    id: 1646,
    acronym: 'EB',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ehsal.be',
    name: 'EHSAL - Europese Hogeschool Brussel',
    web_page: 'http://www.ehsal.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1647,
    acronym: 'E-EHB',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'etf.edu',
    name: 'Evangelische Theologische Faculteit Leuven',
    web_page: 'http://www.etf.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw7VSS0RKipSa0vcJDBOrCLsfs9L8RyyieBe-umIILvjvg2jqTQsGaDg',
    id: 1648,
    acronym: 'ETFL',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'europeaniu.org',
    name: 'European International University',
    web_page: 'http://www.europeaniu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPfERku0eyiIVGPyjttgx958_d7jVXh_U-CeiPv_rRZjdr5qlNw7i3qA',
    id: 1649,
    acronym: 'EIU',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'fpms.ac.be',
    name: 'Faculté Polytechnique de Mons',
    web_page: 'http://www.fpms.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm62ccDFykh2HU4E8L8EbZ5ypNgm6JNfWMMfYmNsAfTuWkc2uxsSu8KQ',
    id: 1650,
    acronym: 'FPDM',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'fsagx.ac.be',
    name: 'Faculté Universitaire des Sciences Agronomiques de Gembloux',
    web_page: 'http://www.fsagx.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0ibvPG-5SDkC1aWU5WS0kq9kLBI6rFRnJLjqYBtAoMSoKHMO2J3ow100',
    id: 1651,
    acronym: 'FUDSADG',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'fucam.ac.be',
    name: 'Facultés Universitaires Catholiques de Mons',
    web_page: 'http://www.fucam.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqSbCFBoKu-mdH1jcs9Kwg-XduLNLcuGGDIqqXYRwtE-u_Xci8Fqf_Qg',
    id: 1652,
    acronym: 'FUCDM',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ful.ac.be',
    name: 'Fondation Universitaire Luxembourgeoise',
    web_page: 'http://www.ful.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt5kcadFCOi3_5YkDtfXyX2QbzlqdyFsg4faxC7Z_7x8_wXq5l3Jbw2A',
    id: 1653,
    acronym: 'FUL',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'fundp.ac.be',
    name: 'Facultés Universitaires Notre-Dame de la Paix',
    web_page: 'http://www.fundp.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv34D0WeJIDtXdcXxxxPTAFgjIQRKZitN_bLN6dsxG3h8zlsaDmxHCCFI',
    id: 1654,
    acronym: 'FUNDLP',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'fusl.ac.be',
    name: 'Facultés Universitaires Saint-Louis',
    web_page: 'http://www.fusl.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzbwJ5-NqDu5bBE-OQufIPOjvLFn1gNHfghUq5oJJml7mKh9GQf2PYeA',
    id: 1655,
    acronym: 'FUS',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ha.be',
    name: 'Hogeschool Antwerpen',
    web_page: 'http://www.ha.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuO81I6K-0ITFfIjdNRqiFZdWMiIFHpIYIW6i9sIHmJjlwnyWaxdn_lpQ',
    id: 1656,
    acronym: 'HA',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'howest.be',
    name: 'Hogeschool West-Vlaanderen (TU)',
    web_page: 'http://www.howest.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3xwyDR8xPYDmUvpOeBRp71A1thSx25BQmAA9DjJbSnbZYPX9OOLVn4KB0',
    id: 1657,
    acronym: 'HW(',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'hzs.be',
    name: 'Hogere Zeevaartschool - Maritime Academy',
    web_page: 'http://www.hzs.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpQJyLR7wqtn-hKEHhsEUATHaOMNh0Wn-tK9FbH7l2SB_n-VEE5mw5-p0',
    id: 1658,
    acronym: 'HZ-MA',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ichec.be',
    name: 'Brussels Management School (ICHEC)',
    web_page: 'http://www.ichec.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-FDCdfC2BRpouDU5gkJFBnpLMcUrjgy5wUozye32xvhlhPqXfhBOIde2j',
    id: 1659,
    acronym: 'BMS(',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'kdg.be',
    name: 'Karel De Grote Hogeschool',
    web_page: 'http://www.kdg.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-37TduujvCjq5jDGiGI2yFa8ullGOJoJlK5e_0hKyAn2pSBV7GxtzeQ',
    id: 1660,
    acronym: 'KDGH',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'khk.be',
    name: 'Katholieke Hogeschool Kempen',
    web_page: 'http://www.khk.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD9dfBbKuxGkN2O6eP2tOuKA82wyEHri-Z9BubXl1xpS0wRM5ijdjPib4',
    id: 1661,
    acronym: 'KHK',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'khleuven.be',
    name: 'Katholieke Hogeschool Leuven',
    web_page: 'http://www.khleuven.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd3_AZeqlKk8TN0CZ6cGqWZu9dmH1brqWQP4XxmfxDccymTuTFpKa0qZFx',
    id: 1662,
    acronym: 'KHL',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'khlim.be',
    name: 'Katholieke Hogeschool Limburg',
    web_page: 'http://www.khlim.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX5BpcpWnOpnJRy6xa0XsLJir8aJzqP8lRHJXJbS4m7aIqnmnrS0vBBpg',
    id: 1663,
    acronym: 'KHL',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'kubrussel.ac.be',
    name: 'Katholieke Universiteit Brussel',
    web_page: 'http://www.kubrussel.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNG-z49JyMP5t_fRFU359lN6TgvGaGsFNlKju7QnR1yp2OdwI-S5B4HA',
    id: 1664,
    acronym: 'KUB',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'kulak.ac.be',
    name: 'Katholieke Universiteit Leuven Campus Kortrijk',
    web_page: 'http://www.kulak.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrl9H-wnZAZqNGFSmxUVkTyrJZjZnFrAw0BqfO_n3fucAA6KtSvT6U0B9W',
    id: 1665,
    acronym: 'KULCK',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'kuleuven.ac.be',
    name: 'Katholieke Universiteit Leuven',
    web_page: 'http://www.kuleuven.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGLnSo-j3VZHrTjLBAKwQVGyGDwEj2lJ1mj87cJA0ZQhcCwJnA6UV1a30',
    id: 1666,
    acronym: 'KUL',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'rma.ac.be',
    name: 'Royal Military Academy',
    web_page: 'http://www.rma.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFFDA9DqLSMh7EH3Qe4m5WMh26-9IskwoXjiFgU0rHVTsdicSvMFNI09Y',
    id: 1667,
    acronym: 'RMA',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'rug.ac.be',
    name: 'Universiteit Gent',
    web_page: 'http://www.rug.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQESY9hf52MonY2wzcOcYkRn83UbWY4tRNpgsh5evrlgkoJl75NBZ-YCF0',
    id: 1668,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'thierryschool.org',
    name: 'Thierry Graduate School of Leadership',
    web_page: 'http://www.thierryschool.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStzPh_hb7CqP4zZkAreL-u5P8b25_edJUt5hkWHBILCjaxGzZ-zCkIdg',
    id: 1669,
    acronym: 'TGSL',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ua.ac.be',
    name: 'Universiteit Antwerpen',
    web_page: 'http://www.ua.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGZMQ12tLgdpN7L-9qkV8sg8ob-2ZcNvLhe2xYDa-ojDg6q8BnyBwxm5E',
    id: 1670,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'uams.be',
    name: 'Universiteit Antwerpen Management School',
    web_page: 'http://www.uams.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvREjq-c5oQOpWfR_uwr_JA2v8ppg3bZBw6r4HmF6Ae_PEasPQ2Mp6WjM',
    id: 1671,
    acronym: 'UAMS',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ubi.edu',
    name: 'United Business Institute',
    web_page: 'http://www.ubi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRujAMi0cw5QZDPkUH8xoA8ic_NFC3AgwTDIhyRTgjKcnwNYqbenoZPKqsU',
    id: 1672,
    acronym: 'UBI',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ucl.ac.be',
    name: 'Université Catholique de Louvain',
    web_page: 'http://www.ucl.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFdzaADuD5fD35dIQ9IhAoZ7k3zuM52mQD5cy-3nN3yjHhvrur5XlhqA',
    id: 1673,
    acronym: 'UCDL',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ufsia.ac.be',
    name: 'Universiteit Antwerpen UFSIA',
    web_page: 'http://www.ufsia.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRO01intc1HpoCsJirCHzaQSgQO_UjMLqFY35YNrFZfYMvtyGOKozX9zs',
    id: 1674,
    acronym: 'UAU',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'uhasselt.be',
    name: 'Hasselt University',
    web_page: 'http://www.uhasselt.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrh4ebe38ifz2K0p0-f498J-ZXU2BLEY2NP6pxfjHUaxHr4YCnA1WWDcE',
    id: 1675,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ukc.ac.uk',
    name: 'Brussels School of International Studies',
    web_page: 'http://www.ukc.ac.uk/international/bsis/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF_FPHscXvEm6jKxwJIt-_I0sqkUyHHN-1fQLzhGRNz37UjwvM_Xu3kQ',
    id: 1676,
    acronym: 'BSIS',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ulb.ac.be',
    name: 'Université Libre de Bruxelles',
    web_page: 'http://www.ulb.ac.be/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRglUEVBzpmEP1wmbCAlfWSVNBNDEcH65z8GhRxm_4h_aeiIcucAk62sl0',
    id: 1677,
    acronym: 'ULDB',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'ulg.ac.be',
    name: 'Université de Liège',
    web_page: 'http://www.ulg.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX-yXrlCFduVzv3fSWep2ePeL0AmGFnkmn1LOO7gmUfzrTaCJpEkVy_DVt',
    id: 1678,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'umh.ac.be',
    name: 'Université de Mons-Hainaut',
    web_page: 'http://www.umh.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZbN_TJBBwJCgJetivYtelEclazRmIV8B1VKkqgXHPxlz_tLrKRIAoXGk',
    id: 1679,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'vesalius.edu',
    name: 'Vesalius College',
    web_page: 'http://www.vesalius.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgbIm8RdE3EUPLAhRLSWIDhr4OPDJTKGPrZF8HZBdNSgmUaALVfTn5yA',
    id: 1680,
    acronym: 'VC',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'vlekho.be',
    name: 'Hogeschool voor Wetenschap en Kunst (VLEKHO) Brussel',
    web_page: 'http://www.vlekho.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJUxfXGBSCcVdxStB8I4O7KW6RVCIMCCMmBNTo1qgNPf6fR5GCeZ7fqQ',
    id: 1681,
    acronym: 'HVWEK(B',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'vlerick.be',
    name: 'Vlerick Leuven Gent Management School',
    web_page: 'http://www.vlerick.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlW5TsQCUDvqEnql8wCd30j-cmGpBHsiSu-vJqe5eeyjxmOi7kZCFksmk',
    id: 1682,
    acronym: 'VLGMS',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'vub.ac.be',
    name: 'Vrije Universiteit Brussel',
    web_page: 'http://www.vub.ac.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNG-z49JyMP5t_fRFU359lN6TgvGaGsFNlKju7QnR1yp2OdwI-S5B4HA',
    id: 1683,
    acronym: 'VUB',
  },
  {
    alpha_two_code: 'BE',
    country: 'Belgium',
    domain: 'wenk.be',
    name: 'Hogeschool voor Wetenschap & Kunst',
    web_page: 'http://www.wenk.be/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9SvaOthZ7AS8RzH_EzeCEgw8tv6SycgVWpxoiyocN318yt1kaVExhww',
    id: 1684,
    acronym: 'HVW&K',
  },
  {
    alpha_two_code: 'BZ',
    country: 'Belize',
    domain: 'aucmed.edu',
    name: 'American University of the Caribbean School of Medicine',
    web_page: 'http://www.aucmed.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlltK_I3i2XRGo0a0HdeYHtE6sLEph3TRtJPvn_iL3nRM7IcPu0mq6xJk',
    id: 1685,
    acronym: 'AUCSM',
  },
  {
    alpha_two_code: 'BZ',
    country: 'Belize',
    domain: 'cahsu.edu',
    name: 'Central America Health Sciences University (Belize Medical College)',
    web_page: 'http://www.cahsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnCmVhgffUq1fwKS441RMeRNhy-I_gtMtb7kh_k8HTT4jZka7T6i2m8Lo',
    id: 1686,
    acronym: 'CAHSU(MC',
  },
  {
    alpha_two_code: 'BZ',
    country: 'Belize',
    domain: 'giuedu.bz',
    name: 'Greenford International University',
    web_page: 'http://www.giuedu.bz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQlIlgVr18YEcQo4HAJlvBU03GZDI3FH4gZtVNzjZEJRQN-EMBvgOz7rw',
    id: 1687,
    acronym: 'GIU',
  },
  {
    alpha_two_code: 'BZ',
    country: 'Belize',
    domain: 'ub.edu.bz',
    name: 'University of Belize',
    web_page: 'http://www.ub.edu.bz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmy6cdfcanc3F1xJ1rzRrvR9bdx5QqzzcLJmHWDpnEuj5SaBuBdKizXvc',
    id: 1688,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'BZ',
    country: 'Belize',
    domain: 'westcoastuniversity.bz',
    name: 'West Coast University (WCU)',
    web_page: 'http://www.westcoastuniversity.bz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAYORfn1XI6XkLyLjFTW9PKYfwZN87bbbyxP5UZuV3oIUBskLMS2sTTQ',
    id: 1689,
    acronym: 'WCU(',
  },
  {
    alpha_two_code: 'BJ',
    country: 'Benin',
    domain: 'espam-formationuc.org',
    name: 'Espam Formation University',
    web_page: 'http://www.espam-formationuc.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXXIRmw8DCFKMdK8B2nqZXoM95ypSGHxPOEZ7rmaZ8pGr9n6f5zHTUz2c',
    id: 1690,
    acronym: 'EFU',
  },
  {
    alpha_two_code: 'BJ',
    country: 'Benin',
    domain: 'hnaubenin.org',
    name: 'Houdegbe North American University Benin',
    web_page: 'http://www.hnaubenin.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWMd4cqgQhIBjOdtOVXemwHTh7gMQouDNZoTUzspBz73HMjVe-YOS6HVWc',
    id: 1691,
    acronym: 'HNAUB',
  },
  {
    alpha_two_code: 'BJ',
    country: 'Benin',
    domain: 'uac.bj',
    name: "Université d'Abomey-Calavi (UAC)",
    web_page: 'http://www.uac.bj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREwCpo7DkRq1PMNr6Cv1BNakP0c2cIsEUElT4xV-aEdOombl5lJ-lMGg',
    id: 1692,
    acronym: 'UD(',
  },
  {
    alpha_two_code: 'BM',
    country: 'Bermuda',
    domain: 'bercol.bm',
    name: 'Bermuda College',
    web_page: 'http://www.bercol.bm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfEKNT8LuCsRrw39zojjQcOgZzoPOhsmkY8jBHiGGN1nxIjzU840dcgg',
    id: 1693,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'BT',
    country: 'Bhutan',
    domain: 'rub.edu.bt',
    name: 'Royal University of Bhutan',
    web_page: 'http://www.rub.edu.bt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEkaRkUeuy4L1N7UxJWcGKenU5BfgLkvUiYiz8N4Vjgk9JnVqNx9aL4ww',
    id: 1694,
    acronym: 'RUB',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'emi.edu.bo',
    name: 'Escuela Militar de Ingeniería',
    web_page: 'http://www.emi.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTysG5J6I2d297mWyBXjW9Kld6RKdaQJm7YNq2LCR9Iij5xf3BFYiq96A',
    id: 1695,
    acronym: 'EMDI',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'nur.edu',
    name: 'Universidad NUR',
    web_page: 'http://www.nur.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHese8zj_E7FiOYllAutET8rcHjav-bfxSCuS-MX4hkDKur_yU2zQ8CrI',
    id: 1696,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uab.edu.bo',
    name: 'Universidad Adventista de Bolivia',
    web_page: 'http://www.uab.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEbRbd-7XJH4OIqi003uv_qRwhAVTIuJnKsJUF5iYL1VpkpLs1RgnhRt4',
    id: 1697,
    acronym: 'UADB',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uabjb.edu.bo',
    name: 'Universidad Autónoma del Beni "José Ballivián"',
    web_page: 'http://www.uabjb.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeWaTFMVC63J80J-hZU7zfEvpxw67JvUo5OVuxcLuZ1Gwyz6d36yruPJQ',
    id: 1698,
    acronym: 'UADB"B',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uagrm.edu.bo',
    name: 'Universidad Autónoma Gabriel René Moreno',
    web_page: 'http://www.uagrm.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPt6w9aetDi0BtZWGDD7br5lJmJuk1PEJYSfWUfez4SFkTvVGV0nEYns4',
    id: 1699,
    acronym: 'UAGRM',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uajms.edu.bo',
    name: 'Universidad Autónoma Juan Misael Saracho',
    web_page: 'http://www.uajms.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbbuedEWMyGOzXg5C_ALPqNMjNBGvlxve99RGhQIOtcOXJCPk_69LJaj4',
    id: 1700,
    acronym: 'UAJMS',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uajms.edu.bo',
    name: 'Universidad Juan Misael Saracho',
    web_page: 'http://www.uajms.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmMFmLMSQOVRw-zidZVho-CGZSnH-k5mRRnZlAa9dnLMfE_fCfXTO-veI',
    id: 1701,
    acronym: 'UJMS',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uapnet.edu.bo',
    name: 'Universidad Amazonica de Pando',
    web_page: 'http://www.uapnet.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc3Y8rw6CiF9Mm8mx6jmQIY4TWdLH8XCOlz75luYW8mTbE82juhhc9nQ',
    id: 1702,
    acronym: 'UADP',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uasb.edu.bo',
    name: 'Universidad Andina Simón Bolivar',
    web_page: 'http://www.uasb.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1LJW9EV99qkAEdm_9yXuZQ-JtRua6-34uJ8wYhDk_HfEpxHVmV1xkS-I',
    id: 1703,
    acronym: 'UASB',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uatf.edu.bo',
    name: 'Universidad Autónoma Tomás Frías',
    web_page: 'http://www.uatf.edu.bo/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTnoDhy8yfdAF5aHyVBxOeVdfaDLDWeXiahsM5A0CzFZAGrjpsuOH06bms',
    id: 1704,
    acronym: 'UATF',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'ucbcba.edu.bo',
    name: 'Universidad Católica Boliviana Cochabamba',
    web_page: 'http://www.ucbcba.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK2Uj9LXbdFxAQ7yNJ3nc1Ypaep-Sgqp2h7TMUJwCYwv4IvsgApxSzgw',
    id: 1705,
    acronym: 'UCBC',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'ucb.edu.bo',
    name: 'Universidad Católica Boliviana La Paz',
    web_page: 'http://www.ucb.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVWX7AjezDAAjJSQMBbWtjtE_XgwcIAySe7r7Gp-BicGjC3-s3bfZeF3h1',
    id: 1706,
    acronym: 'UCBLP',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'ucbscz.edu.bo',
    name: 'Universidad Católica Boliviana Santa Cruz',
    web_page: 'http://www.ucbscz.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVWX7AjezDAAjJSQMBbWtjtE_XgwcIAySe7r7Gp-BicGjC3-s3bfZeF3h1',
    id: 1707,
    acronym: 'UCBSC',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'ucbtja.edu.bo',
    name: 'Universidad Católica Boliviana Tarija',
    web_page: 'http://www.ucbtja.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeYXmBzDyUg7Vm0etMi7Mipi1aO2L_tFC29UFp-lRGZ6-iiCubtLdaWHGT',
    id: 1708,
    acronym: 'UCBT',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'udabol.edu.bo',
    name: 'Universidad de Aquino Bolivia',
    web_page: 'http://www.udabol.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-wl08SCdNh6fMqXhg7p2J6qadJu43DDijL7Sb2V9v0feYbNk2CoSkXyBwBA',
    id: 1709,
    acronym: 'UDAB',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'udelosandes.edu.bo',
    name: 'Universidad Los Andes',
    web_page: 'http://www.udelosandes.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaPf9sv9NDvl0rZ8m4TEulMn1faoHLgWIEDNJBDhhoOyrDk-G5djbXBhp6Vw',
    id: 1710,
    acronym: 'ULA',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'umsanet.edu.bo',
    name: 'Universidad Mayor de San Andrés',
    web_page: 'http://www.umsanet.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk5u1vImaVfbJ2i_4GmAYn1SpI9AzQeaUJWaka0gzgJxNbjCMf05QtE7c',
    id: 1711,
    acronym: 'UMDSA',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'umss.edu.bo',
    name: 'Universidad Mayor de San Simón',
    web_page: 'http://www.umss.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgejjtpnPgGrfNG_vHIkh8GIaHPDT8Ucat12MboJAC1rNgLY6jpF_5DPw',
    id: 1712,
    acronym: 'UMDSS',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'unicen.edu.bo',
    name: 'Universidad Central',
    web_page: 'http://www.unicen.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAuWjzGl5gKR2cGnuTVZeP2UzlEU43PblcLTexV5TmW-Ra2lhiX5eDGA',
    id: 1713,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'unifranz.edu.bo',
    name: 'Universidad Privada Franz Tamayo (UNIFRANZ)',
    web_page: 'http://www.unifranz.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnRXlQuC7jTNdYj7Vy3HfcUk3AkYrRcGaV8w864IlvRJxb6hL_i6iG2eCU',
    id: 1714,
    acronym: 'UPFT(',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'unikuljis.edu.bo',
    name: 'Universidad Empresarial Mateo Kuljis',
    web_page: 'http://www.unikuljis.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqOJguIREtz2ZkLF1Pth8E1i2M5fy3f6aLABr-RXeuXhBEWN_o2dsYyGk',
    id: 1715,
    acronym: 'UEMK',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'univalle.edu',
    name: 'Universidad Privada del Valle',
    web_page: 'http://www.univalle.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBLamzlSH4-NfJwJpAMbGQub_-86T1XanIFtZBNgcjMIR-1u9vm509Bg',
    id: 1716,
    acronym: 'UPDV',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'unslp.edu.bo',
    name: 'Universidad Nuestra Senora de La Paz',
    web_page: 'http://www.unslp.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzZsVafCvCqS5wSdhYw_5s2Ed1UVgHJKXqz6yksCwd6znm0PKx00W5T5-5',
    id: 1717,
    acronym: 'UNSDLP',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'unsxx.edu.bo',
    name: 'Universidad Nacional Siglo XX Llallagua',
    web_page: 'http://www.unsxx.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDG5-1AyD5xIN1XbeVqp8S--XXoXW0KU98EMnh2EGcazRkI8sCkLZyrw',
    id: 1718,
    acronym: 'UNSXL',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'upal.edu',
    name: 'Universidad Privada Abierta Latinoamericana',
    web_page: 'http://www.upal.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmkpcStmHE_K3sP0iic1F7J2KJVtkpVSuHigZ5ngH1UdOaD8_Unw41Aw',
    id: 1719,
    acronym: 'UPAL',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'upb.edu',
    name: 'Universidad Privada Boliviana',
    web_page: 'http://www.upb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQlF3y-1fDADGOv3UFON24C9RC64lI65bJTFwD9LFrOCjrmDN3CA3ZVg',
    id: 1720,
    acronym: 'UPB',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'upds.edu.bo',
    name: 'Universidad Domingo Savio',
    web_page: 'http://www.upds.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHahG7ersjeSgWlzMDZWecmAAkX50CGgmSQvueh4Ph_sAQNrRRdJyMmYk',
    id: 1721,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'upsa.edu.bo',
    name: 'Universidad Privada de Santa Cruz de la Sierra',
    web_page: 'http://www.upsa.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiLNZlaZZqtuFOhG3jgTtQvxLG-I8PeYDxneDg-6VxCWE8KXrK3EdrRI8',
    id: 1722,
    acronym: 'UPDSCDLS',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'usalesiana.edu.bo',
    name: 'Universidad Salesiana de Bolivia',
    web_page: 'http://www.usalesiana.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6WCey7h9jjYY_-w5JE5Cw3ryaEFaZe09jFhrPfufuFcXpbnxoTbjVzdw',
    id: 1723,
    acronym: 'USDB',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'usfx.edu.bo',
    name: 'Universidad San Francisco Xavier de Chuquisaca',
    web_page: 'http://www.usfx.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQy5GJZZqzJ6Jx5tHnrU0vs98HoZMCUQjFiTVKWbTB-XrOTizoxQkpydP9v',
    id: 1724,
    acronym: 'USFXDC',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'usfx.info',
    name: 'Universidad San Francisco Xavier',
    web_page: 'http://www.usfx.info/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcR5FNau4tGBrJm8tbVTF7JbkPkROVmuu-PIhQ_Eobq7TrxJ43Q8r7L9JFcs',
    id: 1725,
    acronym: 'USFX',
  },
  {
    alpha_two_code: 'BO',
    country: 'Bolivia, Plurinational State of',
    domain: 'uto.edu.bo',
    name: 'Universidad Técnica de Oruro',
    web_page: 'http://www.uto.edu.bo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAS39wfB6gk_tettrzyGr92jPtlEGXT5jaAdJjzw-r2QQNB-uPp8bRAy4',
    id: 1726,
    acronym: 'UTDO',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'ibu.edu.ba',
    name: 'International Burch University',
    web_page: 'http://www.ibu.edu.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnHYBTRLT3SM7WAzHHn5f1yBcgci7jgsKNMJS1iMvmF-GXZ-vzeyAHr5UO',
    id: 1728,
    acronym: 'IBU',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'ius.edu.ba',
    name: 'International University of Sarajevo',
    web_page: 'http://www.ius.edu.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpXciFb3tEcR1SZNgFbJslSrQogFIIWrhS0-lUT4PJqvODgAPrrmZ_Zg',
    id: 1729,
    acronym: 'IUS',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'iu-travnik.com',
    name: 'International University of Travnik',
    web_page: 'http://www.iu-travnik.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR430B3sF_wHd39XBzl3No4wV8pJ_PSYkXosKdn_cVHshSysp6q57esAis',
    id: 1730,
    acronym: 'IUT',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'sfa.ba',
    name: 'Sarajevo Film Academy',
    web_page: 'http://www.sfa.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxZYTnHeN9bT3ipQxRflsF7MgXi1a8MD7Wq7Vhp9lTU8jFdcXKqZCmSPs',
    id: 1731,
    acronym: 'SFA',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'spu.ba',
    name: 'Slobomir P Univerzitet',
    web_page: 'http://www.spu.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2G0Bvoq_QZsjD-ebzR2Q8v18jecgrOIOyNT-kJPGBU-imyZrlRqtWOWAm',
    id: 1732,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'sve-mo.ba',
    name: 'University of Mostar',
    web_page: 'http://www.sve-mo.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjPASRJEiSrouXqJK0Oog2pgNdokX5xVr1QqSY8AaeEQF96fng16r0iQ',
    id: 1733,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'unbi.ba',
    name: 'University of Bihac',
    web_page: 'http://www.unbi.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjPASRJEiSrouXqJK0Oog2pgNdokX5xVr1QqSY8AaeEQF96fng16r0iQ',
    id: 1734,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'unibl.org',
    name: 'University of Banja Luka',
    web_page: 'http://www.unibl.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJdf3q6wVZVAQPjHzXnKad1OT5sR_YpQ8fg1c3I2f15fsnFxAhPvxt7Q',
    id: 1735,
    acronym: 'UBL',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'unmo.ba',
    name: 'Univerzitet u Mostaru "Dzemal Bijedic"',
    web_page: 'http://www.unmo.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcST9UIWNldKb3MLOUpcnDLVPzgKGMA98PNhHS3Pt9LrlO8lOgLOZTE2gQ',
    id: 1736,
    acronym: 'UUM"B',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'unsa.ba',
    name: 'University of Sarajevo',
    web_page: 'http://www.unsa.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpXciFb3tEcR1SZNgFbJslSrQogFIIWrhS0-lUT4PJqvODgAPrrmZ_Zg',
    id: 1737,
    acronym: 'US',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'unssa.rs.ba',
    name: 'University of East Srarajevo',
    web_page: 'http://www.unssa.rs.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgi_iCP1MQxvNA_ZnUYmQGkh_sg2lE0coR3U7oyYMPaq5KZxJDFub6CA',
    id: 1738,
    acronym: 'UES',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'untz.ba',
    name: 'University of Tuzla',
    web_page: 'http://www.untz.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoqKJ_e3XZBM__HTVZvqtLbbZIvSOWo2tpvsn1EU1cp5IU30c7YKbV9Q',
    id: 1739,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'unvi.edu.ba',
    name: 'University Vitez In Travnik',
    web_page: 'http://www.unvi.edu.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMWYgw15VgML2iCRqoY1DbUgPjFmrojRbIANLJuaXHmheMb_CIyqioAw',
    id: 1740,
    acronym: 'UVIT',
  },
  {
    alpha_two_code: 'BA',
    country: 'Bosnia and Herzegovina',
    domain: 'unze.ba',
    name: 'University of Zenica',
    web_page: 'http://www.unze.ba/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT03bJn1tDbpC7-GnRev2yUln57AJxxCRAQGsrZk3mBiNZsGKSAwoxo0Q',
    id: 1741,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'BW',
    country: 'Botswana',
    domain: 'abm.ac.bw',
    name: 'ABM University College',
    web_page: 'http://www.abm.ac.bw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQywCpPgqaOF7n9OeswNNP0a7-s_gcgbZnjn-JiuU75NdZBHrwUGyiiSQ',
    id: 1742,
    acronym: 'AUC',
  },
  {
    alpha_two_code: 'BW',
    country: 'Botswana',
    domain: 'bac.ac.bw',
    name: 'Botswana Accountancy College',
    web_page: 'http://www.bac.ac.bw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-b7pEygr-Ur9NjAyRluQbhrTQxbKjvzFXJxmZfmzSuI1TjwkWlN78Vqk',
    id: 1743,
    acronym: 'BAC',
  },
  {
    alpha_two_code: 'BW',
    country: 'Botswana',
    domain: 'bca.bw',
    name: 'Botswana College of Agriculture',
    web_page: 'http://www.bca.bw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj571hVW4gSH5mmaMsnuW0M_-9vgNDXZBEFAoh_UsWGaWTC2Dl0QPUi4U',
    id: 1744,
    acronym: 'BCA',
  },
  {
    alpha_two_code: 'BW',
    country: 'Botswana',
    domain: 'biust.ac.bw',
    name: 'Botswana International University of Science & Technology',
    web_page: 'http://www.biust.ac.bw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPp4cjnOCookmRqbBBHDVdZzzLJ1ZjbQKOONQO0xv87WpZZyZrefVl0qc',
    id: 1745,
    acronym: 'BIUS&T',
  },
  {
    alpha_two_code: 'BW',
    country: 'Botswana',
    domain: 'bothocollege.ac.bw',
    name: 'Botho University',
    web_page: 'http://www.bothocollege.ac.bw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw3McBvjGk8PTOYUqIjt-YLfsJAhn0B1N4EE8ZKAk5e7SfWuOKsJ5omPE',
    id: 1746,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'BW',
    country: 'Botswana',
    domain: 'guc.co.bw',
    name: 'Gaborone Universal College of Law',
    web_page: 'http://www.guc.co.bw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbe443s-3bskVRtjr5tl9xLF3zcxZH5hmOSD876dw9BpCyF8lmvr4Aaw',
    id: 1747,
    acronym: 'GUCL',
  },
  {
    alpha_two_code: 'BW',
    country: 'Botswana',
    domain: 'neweracollege.ac.bw',
    name: 'New Era College of Arts Science & Technology',
    web_page: 'http://www.neweracollege.ac.bw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiKqHebgyKQ9_yZYbYombnROzDnoiexVVVv_iKlM_2lVYt9jaIdc3P2w',
    id: 1748,
    acronym: 'NECAS&T',
  },
  {
    alpha_two_code: 'BW',
    country: 'Botswana',
    domain: 'ub.bw',
    name: 'University of Botswana',
    web_page: 'http://www.ub.bw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRah9BgeVnfAysAU2LfmxLmuV_HPM_3heiGPA1qg_MXVrCdPdXSweg1iw',
    id: 1749,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'baraodemaua.br',
    name: 'Centro Universitário Barao de Maua',
    web_page: 'http://www.baraodemaua.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXcJNow2nXQML1uKUYwvWsV-x5yvqX4EZGjmLTewP-A4qCAoFnCupLBUk',
    id: 1750,
    acronym: 'CUBDM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'brazcubas.br',
    name: 'Universidade Braz Cubas',
    web_page: 'http://www.brazcubas.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzyDuWO9H-KjSUjRfBsKLBNrxBQ-mUWAKGHEB0M4JaMP78i-pRvDVtrmY',
    id: 1751,
    acronym: 'UBC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'candidomendes.br',
    name: 'Universidade Candido Mendes',
    web_page: 'http://www.candidomendes.br/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRCnGw3wpPV4ax56YaPpXKuj3CNvHuOuS6ek4-H7-ZDzvs9w2aj_qgo1w',
    id: 1752,
    acronym: 'UCM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'castelobranco.br',
    name: 'Universidade Castelo Branco',
    web_page: 'http://www.castelobranco.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCUe68K7hntbs_b_oBnB8TyJC6Jns7LSR2kHg_gq0poIdT8q7Pk7vu9-UM',
    id: 1753,
    acronym: 'UCB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'claretiano.edu.br',
    name: 'Centro Universitário Claretiano',
    web_page: 'http://www.claretiano.edu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQstvMF_ZaYUaN6Wv7NUkN87o6HMsuI8JYV_R5jNIArZ69mvi24mCTC13cD',
    id: 1754,
    acronym: 'CUC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'creupi.br',
    name: 'Centro Regional Universitário de Espiríto Santo do Pinhal',
    web_page: 'http://www.creupi.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFVLa-aZ88DDJI7OiL2vffLlwGp2vU4KixTefzKv8EyNXF6lep9F5GfTk',
    id: 1755,
    acronym: 'CRUDESDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'emescam.br',
    name: 'EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória',
    web_page: 'http://www.emescam.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTLm9FS68CK501VTKICVcM5SEeqw-lyPZ6fW8vCkIGCMWpte9Ni9uh_-8',
    id: 1756,
    acronym: 'E-ESDCDSCDMDV',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'epm.br',
    name: 'Universidade Federal de São Paulo',
    web_page: 'http://www.epm.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFIwAMQ_MlwcQczLZI5cduiLNSgQmjYNth8TSoqTxUXkwn6mSiqOYFuDs',
    id: 1757,
    acronym: 'UFDSP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'estacio.br',
    name: 'Universidade Estácio de Sá',
    web_page: 'http://www.estacio.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEgMni9fw-tNhGAfPCFpo-QY1VjnI1jPrBqIBj4i3vI0zHOdBPcDMTs_0',
    id: 1758,
    acronym: 'UEDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'faap.br',
    name: 'FAAP - Fundação Armando Alvares Penteado',
    web_page: 'http://www.faap.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaEbd4QWEIRylmBJujX_0NlqqlQFxBclH3GsOJxnWD1VhEriixlootBME',
    id: 1759,
    acronym: 'F-FAAP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'faculdadescuritiba.br',
    name: 'Faculdades Integradas Curitiba',
    web_page: 'http://www.faculdadescuritiba.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3VHFc9jl8Evmu8HsKf49G_JgjbFYnHMlM_WfjCazS869I5BHo_2t9I-I',
    id: 1760,
    acronym: 'FIC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'fae.edu',
    name: 'FAE Business School - Faculdade de Administração e Economia',
    web_page: 'http://www.fae.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDEpMo0aYseZJYyq7c10LxwQjkA0wsAyy2kjayh6ieMUY8sLKk4j596-E',
    id: 1761,
    acronym: 'FBS-FDAEE',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'feituverava.com.br',
    name: 'Fundação Educacional de Ituverava',
    web_page: 'http://www.feituverava.com.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXCWrURUMHRyVCf48bi57rz9AGGprsIyqX7smEmNrH8evn76Ta-_RpLA',
    id: 1762,
    acronym: 'FEDI',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'fic.br',
    name: 'Faculdade Integradas do Ceará',
    web_page: 'http://www.fic.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROiOoUw_rp7A1RohYbOZaatVJOI03atG9BCZLx-lmkMjyCEOD4_522yF4',
    id: 1763,
    acronym: 'FIDC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'fua.br',
    name: 'Universidade do Amazonas',
    web_page: 'http://www.fua.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQulq1qoR7oaWkrx_5dpHNtn3QSfjEitKh5V84fXQ9Eg4GOq79JU7fEUA',
    id: 1764,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'furb.rct-sc.br',
    name: 'Universidade Regional de Blumenau',
    web_page: 'http://www.furb.rct-sc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK2UUl5j6xpBY6SUl5weo4dkfpFzJQHnchAAENPVg9I97FdEU2ehQRtHI',
    id: 1765,
    acronym: 'URDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'furg.br',
    name: 'Universidade do Rio Grande',
    web_page: 'http://www.furg.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNb6st5xL7r6Bwj_tR1VnOWIoYcq2AuKEEl31Bym3DlrvpTaEAUnz1Pxg',
    id: 1766,
    acronym: 'UDRG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'impa.br',
    name: 'Instituto Nacional de Matemática Pura e Aplicada - IMPA',
    web_page: 'http://www.impa.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFwscFvUGRxOs5pwqbh64ttz7k508GxGfqP756DrhiqF8o7nbgcswJbhIl',
    id: 1767,
    acronym: 'INDMPEA-I',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ita.br',
    name: 'Instituto Tecnológico de Aeronáutica',
    web_page: 'http://www.ita.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrWJxajkcUnSZnxY22Cr0bydKHuUy6CdDfnzbHzSbPPDdt6T4HwsZIbQ',
    id: 1768,
    acronym: 'ITDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'italo.br',
    name: 'Faculdade Italo Brasileira',
    web_page: 'http://www.italo.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQvEATWp8-raERUOF2rMluoVPqSBFUlb7U6k8xE1zyXImEUCWqAwJaiNo3',
    id: 1769,
    acronym: 'FIB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'mackenzie.br',
    name: 'Universidade Mackenzie',
    web_page: 'http://www.mackenzie.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwNR6YQ1WL4Kg8LDVDzYeVa6leMMPyiHZrQX_D54FKOywhMwahSLfByQ',
    id: 1770,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'maua.br',
    name: 'Instituto Mauá de Tecnologia',
    web_page: 'http://www.maua.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt0EEUWdsWkl6P7gyLwbmTAKbT2VxtSrl1MUJJDQcZOEn_rX87pKlXbsji',
    id: 1771,
    acronym: 'IMDT',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'newtonpaiva.br',
    name: 'Centro Universitário Newton Paiva',
    web_page: 'http://www.newtonpaiva.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmafV3_87geBeaoZAa1UEW55_0RtuS6s0ulfv3gOyiUJRVDayNL30Wsok',
    id: 1772,
    acronym: 'CUNP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'pop-to.rnp.br',
    name: 'Universidade de Tocantins',
    web_page: 'http://www.pop-to.rnp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH-UPOHPETNLn5rjEpgn-GLf-8yRrayqnDhxJledAjDZsWK1w9GGLwfA',
    id: 1773,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'puccamp.br',
    name: 'Pontifícia Universidade Católica de Campinas',
    web_page: 'http://www.puccamp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScgqo9gB-E-1IGFilbxcbQrgidtm8azKpRr02vLmNaNkM1k8r6UoMJww',
    id: 1774,
    acronym: 'PUCDC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'pucminas.br',
    name: 'Pontifícia Universidade Católica de Minas Gerais',
    web_page: 'http://www.pucminas.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQG6SwxusgAd4KTdRTQD8A0_psp6mLboagCAEmq7fLWwp3Nx9FBPORmt6g',
    id: 1775,
    acronym: 'PUCDMG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'pucpr.br',
    name: 'Pontificia Universidade Católica do Paraná',
    web_page: 'http://www.pucpr.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxDjFei3gBa-GKmxpOAotZjk7GiCzLACP2MwPnlZB4Hzot4uO-ofW0jA',
    id: 1776,
    acronym: 'PUCDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'puc-rio.br',
    name: 'Pontifícia Universidade Católica do Rio de Janeiro',
    web_page: 'http://www.puc-rio.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvpdGt9qLemXkzRhNxeuKf38YblPimKEF5SlP3HLsPjb5zr88SOUO4jQ',
    id: 1777,
    acronym: 'PUCDRDJ',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'pucrs.br',
    name: 'Pontifícia Universidade Católica do Rio Grande do Sul',
    web_page: 'http://www.pucrs.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ84CPyuxSlYfO4GE7TMXdRk7T6PxfUF-FGELS1VrWMwugesJaAjJFl4w',
    id: 1778,
    acronym: 'PUCDRGDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'pucsp.br',
    name: 'Pontifícia Universidade Católica de São Paulo',
    web_page: 'http://www.pucsp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYtBkBPKb1gDeHa1KbKUjxAzpK1n_j-fjsv6ePefoXqF_ttAfijFRUSJA',
    id: 1779,
    acronym: 'PUCDSP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'saojudas.br',
    name: 'Universidade São Judas Tadeu',
    web_page: 'http://www.saojudas.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV9lPTtwFSwOtAYQ7duNmHmizdeB3H0cjK2xUPAfZNJ0TKifYMzhKPcdc',
    id: 1780,
    acronym: 'USJT',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'smarcos.br',
    name: 'Universidade São Marcos',
    web_page: 'http://www.smarcos.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWcD8AHYMBPNXggdVMpe2TgAK_IV_dvZOlli9mY-KaEp2IbVdA05hmew',
    id: 1781,
    acronym: 'USM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'sp.senac.br',
    name: 'Centro Universitário Senac',
    web_page: 'http://www.sp.senac.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRogTMoie4X_7K-4JVkrmeu68NP8UDVVXk7HOo4XNgy1LjLXyr8rKWl5Zw',
    id: 1782,
    acronym: 'CUS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'stcecilia.br',
    name: 'Universidade Santa Cecília dos Bandeirantes',
    web_page: 'http://www.stcecilia.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrcTxqj44E6IdlRt9xn6CSH6f_IGUbXSTX4D7lger8JBr4KdP1596fALs',
    id: 1783,
    acronym: 'USCDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'technet.com.br',
    name: 'Universidade Federal de Roraima',
    web_page: 'http://www.technet.com.br/vestibular/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXq_cdrAPbzQhMVx2-P89M6mNW9_50DJizfqwZNulb0s9zG84-z_MCr5Q',
    id: 1784,
    acronym: 'UFDR',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'toledo.br',
    name: 'Faculdades Integradas Toledo',
    web_page: 'http://www.toledo.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPHXlTwDVlhKweiZNDc9IQBFIlztO40SwWt78u0l44zW1vD0S_WSNJh4c',
    id: 1785,
    acronym: 'FIT',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ucb.br',
    name: 'Universidade Católica de Brasília',
    web_page: 'http://www.ucb.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKSYkmFCKU2JXIcPvthnUPsOYCns2iSMRFeBC37VYCcEz7XbhIetWkLWHg',
    id: 1786,
    acronym: 'UCDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ucg.br',
    name: 'Universidade Católica de Goiás',
    web_page: 'http://www.ucg.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBzlHyfX-2ipMLb6o6105jfPGFbB1njwFhhRssD3Jtz8FI321pRVsTx6Y',
    id: 1787,
    acronym: 'UCDG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ucp.br',
    name: 'Universidade Católica de Petrópolis',
    web_page: 'http://www.ucp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxMBXYvRDSP14YbVF2AKV6D9l0zDC_6s6_ctmwIhUzHRUvhn921a-R0sg',
    id: 1788,
    acronym: 'UCDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ucpel.tche.br',
    name: 'Universidade Católica de Pelotas',
    web_page: 'http://www.ucpel.tche.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS5JyP6F5vecW2UnrTgYq07hzHpLAzLU2rVpwtb9NY2hRJl9476Y4O5g',
    id: 1789,
    acronym: 'UCDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ucsal.br',
    name: 'Universidade Católica de Salvador',
    web_page: 'http://www.ucsal.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxm6g5TaLuFt0_M21ubBl1PRUjK2XW9UQ3agpR0wgOv5BqPohyg65PPw',
    id: 1790,
    acronym: 'UCDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ucs.tche.br',
    name: 'Universidade de Caxias do Sul',
    web_page: 'http://www.ucs.tche.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTC6NPSU6gCLpdD8oSNvYR5o--MHLCdAb5IRGHhA4Viha044O8_l9BZQ',
    id: 1791,
    acronym: 'UDCDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'udesc.br',
    name: 'Universidade do Estado de Santa Catarina',
    web_page: 'http://www.udesc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCHg9ntmGaWqW2KxT6h3tRN9f7nnFt_14jLLdcUXu5PXTvUEuzUUwAi3w',
    id: 1792,
    acronym: 'UDEDSC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uece.br',
    name: 'Universidade Estadual do Ceará',
    web_page: 'http://www.uece.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQW6fzvVD_sL3ztXjhf4BwvxkdYlH6kuvz7AtGSeXgWrLgJtoyd2Gubcw',
    id: 1793,
    acronym: 'UEDC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uefs.br',
    name: 'Universidade Estadual de Feira de Santana',
    web_page: 'http://www.uefs.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBIp1SAk1E234w-FRbhTUkPEsaXTt9OYbIeLhTArJ81eFc-IKDzLOFCQ',
    id: 1794,
    acronym: 'UEDFDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uel.br',
    name: 'Universidade Estadual de Londrina',
    web_page: 'http://www.uel.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkLpvPkWTQpvf8XzRNPD4etNKfobIX1V7_BuRC1wnl3C_42KpX_uxY10M',
    id: 1795,
    acronym: 'UEDL',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uema.br',
    name: 'Universidade Estadual do Maranhão',
    web_page: 'http://www.uema.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn_6nNmPf4HaPd0N6EnIpseJuLRjwXHGrGpZDDzvm7TAqERFLLYk_rMyUX',
    id: 1796,
    acronym: 'UEDM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uem.br',
    name: 'Universidade Estadual de Maringá',
    web_page: 'http://www.uem.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRerYxK5DT_XTFFBDs6IavmE49oKaE0luYzZR0TSfL0DzuEbcRgQdbg8g',
    id: 1797,
    acronym: 'UEDM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uenf.br',
    name: 'Universidade Estadual do Norte Fluminense',
    web_page: 'http://www.uenf.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAnw8IYQSsqY3XcNHaEtDV2riG2dPatCKkUnRCzW3e9QLzDngLzXcLDg',
    id: 1798,
    acronym: 'UEDNF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uepb.edu.br',
    name: 'Universidade Estadual da Paraíba',
    web_page: 'http://www.uepb.edu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGGeTojhXX9FW-hdXzrw6arLAl3OBHjp9OAuR-Xm3xEDdxVOwjfafkng',
    id: 1799,
    acronym: 'UEDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uepg.br',
    name: 'Universidade Estadual de Ponta Grossa',
    web_page: 'http://www.uepg.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtQmSN_OtcekiFjJEs4yjSOhhNqHbR3uceSyHuPL_P2ERvXK8AXdfk1tgL',
    id: 1800,
    acronym: 'UEDPG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uerj.br',
    name: 'Universidade do Estado do Rio de Janeiro',
    web_page: 'http://www.uerj.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQVj0usnWalR7Px6Z4cQVe9oaghWKrj-NDhTkffEanQOHpz50jrv7zCTFc',
    id: 1801,
    acronym: 'UDEDRDJ',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uern.br',
    name: 'Universidade do Estado do Rio Grande do Norte',
    web_page: 'http://www.uern.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8j1nPDsYkz_hB2uBvqYmiwe9hZAkrkzOvnYciDk1jpi7V45W2AKmI6Vo',
    id: 1802,
    acronym: 'UDEDRGDN',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uesb.br',
    name: 'Universidade Estadual Sudoeste da Bahia',
    web_page: 'http://www.uesb.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzSqP-bLewLEzEBn3s5pT8STIx9iwhAJnyHe_t0oOtqk1oL7neOleE524',
    id: 1803,
    acronym: 'UESDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uesc.br',
    name: 'Universidade Estadual de Santa Cruz',
    web_page: 'http://www.uesc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC7wvraWUK_nB4aaDfpKpxzKZ86orHMQwQEKxzHVimx3tmbF-46hWQGS97',
    id: 1804,
    acronym: 'UEDSC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uespi.br',
    name: 'Universidade Estadual do Piauí',
    web_page: 'http://www.uespi.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrJp-sCjugAQ3YktKRrVpwBmSWqvu_W9TzDJcqYX_wSG0xbS02nNiyaw',
    id: 1805,
    acronym: 'UEDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufabc.edu.br',
    name: 'Universidade Federal do ABC',
    web_page: 'http://www.ufabc.edu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRKBfinDbcgXCjZ-zWNFGSGOp1xoSAKnuiNnBXsNZ950-gTUVQLr0Ur0o',
    id: 1806,
    acronym: 'UFDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufac.br',
    name: 'Universidade Federal do Acre',
    web_page: 'http://www.ufac.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBI2MFelFoQ4NCLqeGbUDJT4iuU7t62GTCfiFeAiEDk5s75a6dBLSldA',
    id: 1807,
    acronym: 'UFDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufal.br',
    name: 'Universidade Federal de Alagoas',
    web_page: 'http://www.ufal.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRthr7Flx4oGPb1OpWKVEajKiNrgUSYZekbZqFPuRUmJRbnMcLgWe1SBXdu',
    id: 1808,
    acronym: 'UFDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufba.br',
    name: 'Universidade Federal da Bahia',
    web_page: 'http://www.ufba.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6BRdO1pfTJxgOnP3wQjp6wMjFBsag3SfUuDMcqLaPB089n0tdHqPNag',
    id: 1809,
    acronym: 'UFDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufc.br',
    name: 'Universidade Federal do Ceará',
    web_page: 'http://www.ufc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaPeLeWVLFjg1MRlZZ4tEolimDYv3dR1mhflJSlODraKS4xkLSSWYkQlE',
    id: 1810,
    acronym: 'UFDC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufcg.edu.br',
    name: 'Universidade Federal de Campina Grande',
    web_page: 'http://www.ufcg.edu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0Q4N9TpIjdkG8QS7wskS4OW2ESuNl-aRcQwfXvxftMAZg_E0_MLGrAZo',
    id: 1811,
    acronym: 'UFDCG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufes.br',
    name: 'Universidade Federal do Espírito Santo',
    web_page: 'http://www.ufes.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ14bjI2AF7uMOsv4yAHrnYYiKfbhf2mFiLpdx7HhA0bWUCw7zZQwHTeQ',
    id: 1812,
    acronym: 'UFDES',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uff.br',
    name: 'Universidade Federal Fluminense',
    web_page: 'http://www.uff.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFzgDxwqYKxfe1FtLwZSHKnHjB7fSJrdomjVHu1hdkCUaQVFRaE6KapCfu',
    id: 1813,
    acronym: 'UFF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufg.br',
    name: 'Universidade Federal de Goiás',
    web_page: 'http://www.ufg.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS56rPRF2RJFGcx-2giCiPZB3Y-HNojD0PkH8eNILqWt75jDzpqRN4qg',
    id: 1814,
    acronym: 'UFDG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufgd.edu.br',
    name: 'Universidade Federal da Grande Dourados',
    web_page: 'http://www.ufgd.edu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHMolWUiSBK86hHqsw59X3Oab2ciIybQDpO12KfBHpenG-Vh1kUijZEg',
    id: 1815,
    acronym: 'UFDGD',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufjf.br',
    name: 'Universidade Federal de Juiz de Fora',
    web_page: 'http://www.ufjf.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9Xv8_fE-M3xyewvJhQLXRsnCnN_3uHoQ1RIP14iFp-UAKFeH6uMkt9oM',
    id: 1816,
    acronym: 'UFDJDF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufla.br',
    name: 'Universidade Federal de Lavras',
    web_page: 'http://www.ufla.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV4MIvKx7R8Q-jJ-zitlZnnAxns49ivDMN_8Fxi8-L5gmKU_dSvh340Q',
    id: 1817,
    acronym: 'UFDL',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufma.br',
    name: 'Universidade Federal do Maranhão',
    web_page: 'http://www.ufma.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLm9nIiWh28SR38bAMUP3oTA5Kn5P4CTmYb76FzpjgkiSED6YG-JEdUA',
    id: 1818,
    acronym: 'UFDM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufmg.br',
    name: 'Universidade Federal de Minas Gerais',
    web_page: 'http://www.ufmg.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpzu7qzyA8uvM6oDPoxWPhgGXmc43o_ms3O3QnB_xZNwaJJYuYdSeS0kk',
    id: 1819,
    acronym: 'UFDMG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufms.br',
    name: 'Universidade Federal de Mato Grosso do Sul',
    web_page: 'http://www.ufms.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkMBWpbOkiNO8ZMKLWCAdClTyLt1hojoEz0pLSAUGJuNWJ0gsMQeJfQMk',
    id: 1820,
    acronym: 'UFDMGDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufmt.br',
    name: 'Universidade Federal de Mato Grosso',
    web_page: 'http://www.ufmt.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1MPtsCqLGtwf5KVyonjPHRiGtqmuTbmGYBz0Bvk06ha82l9AEN6aCEx9b',
    id: 1821,
    acronym: 'UFDMG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufop.br',
    name: 'Universidade Federal de Ouro Preto',
    web_page: 'http://www.ufop.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvyun0CAyjUocX5kTHD_2lAzuC8s7tSpABPTxQqgtYfCKxXsA6bC3amkGO',
    id: 1822,
    acronym: 'UFDOP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufpa.br',
    name: 'Universidade Federal do Pará',
    web_page: 'http://www.ufpa.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSezvNk0IlAtGvdwBiHAgTPV2bs44X7_LdBkYfWgfaaUEu0nr-b-S85HE8',
    id: 1823,
    acronym: 'UFDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufpb.br',
    name: 'Universidade Federal da Paraíba',
    web_page: 'http://www.ufpb.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSot6alx90vukvyFISIv0BTzAAoxhYI4LV6RvzgOfxmw4zQM0E43dzRGGM',
    id: 1824,
    acronym: 'UFDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufpe.br',
    name: 'Universidade Federal de Pernambuco',
    web_page: 'http://www.ufpe.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPy1FviDtqAgpK8OVfj1s65THLCcYeTZgsTdu8TyhfMOXsNHIVAqMaiWJF2w',
    id: 1825,
    acronym: 'UFDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufpel.tche.br',
    name: 'Universidade Federal de Pelotas',
    web_page: 'http://www.ufpel.tche.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdo0msx8daLFs7i6nLahdTbRi8FKIW-4nb-nyH_mvWG6zij5jBDaO42Q',
    id: 1826,
    acronym: 'UFDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufpi.br',
    name: 'Universidade Federal do Piauí',
    web_page: 'http://www.ufpi.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREYD8jMmwAIgtE2-RweURkQ0KK0_3pbPbW6xuO4OYM9CwxtPmRy03BmLQ',
    id: 1827,
    acronym: 'UFDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufpr.br',
    name: 'Universidade Federal do Paraná',
    web_page: 'http://www.ufpr.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5M9YDJAH87I8uN8Z3W4p5QA4M1HaCWdgqtW9eJmobaxjrfDUTx2T7Xw',
    id: 1828,
    acronym: 'UFDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufrgs.br',
    name: 'Universidade Federal do Rio Grande do Sul',
    web_page: 'http://www.ufrgs.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNb6st5xL7r6Bwj_tR1VnOWIoYcq2AuKEEl31Bym3DlrvpTaEAUnz1Pxg',
    id: 1829,
    acronym: 'UFDRGDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufrj.br',
    name: 'Universidade Federal do Rio de Janeiro',
    web_page: 'http://www.ufrj.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0qglFciH5q0bBXzi6b01F075ZyNJ_WcR4QaMzLnpyQ9EUE3HXLhHCDg',
    id: 1830,
    acronym: 'UFDRDJ',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufrn.br',
    name: 'Universidade Federal do Rio Grande do Norte',
    web_page: 'http://www.ufrn.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3AVzAmL_A8Hz9yBFcXTnM6wkqpv4Tpse1dkGYB6oAEfkd_e2MnRSN-_oD',
    id: 1831,
    acronym: 'UFDRGDN',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufrpe.br',
    name: 'Universidade Federal Rural de Pernambuco',
    web_page: 'http://www.ufrpe.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpIe0hRQot-ZNHQfh1qQSEFwrpPIRxG-DQSfEq-g0mkgkWFl7PTHuoJRg',
    id: 1832,
    acronym: 'UFRDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufrrj.br',
    name: 'Universidade Federal Rural do Rio de Janeiro',
    web_page: 'http://www.ufrrj.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaIMRmc_LBJ9QWnW3IBq3mJ2LMw3M6cHsF0YS4o-GHGqS8KkEaVBtbZJE',
    id: 1833,
    acronym: 'UFRDRDJ',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufs.br',
    name: 'Universidade Federal de Sergipe',
    web_page: 'http://www.ufs.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCfVv05V1-Of2Babz06gBrCJNGyq9p6AVn7d0hqOPCaNByAJOblrXa9Q',
    id: 1834,
    acronym: 'UFDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufscar.br',
    name: 'Universidade Federal de São Carlos',
    web_page: 'http://www.ufscar.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTRtIJB-FHs7U76g0Kvt0Z0io8Zi-PvSluuOJkRZABFnEjFjY4juPpJA',
    id: 1835,
    acronym: 'UFDSC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufsc.br',
    name: 'Universidade Federal de Santa Catarina',
    web_page: 'http://www.ufsc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdlMbexXbdiASwFMFhvMYdkVV8-zuS2WUy3-MaCSuQbaWumLTjjvUqwkY',
    id: 1836,
    acronym: 'UFDSC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufsm.br',
    name: 'Universidade Federal de Santa Maria',
    web_page: 'http://www.ufsm.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXQWqiIdoRSem0VF6m6TNBp-ugzrjqJbgTNQxIC9LtMSD1RIBgKn1Lig',
    id: 1837,
    acronym: 'UFDSM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufu.br',
    name: 'Universidade Federal de Uberlândia',
    web_page: 'http://www.ufu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgKJWUPwCE4Lr3zUIBJwpTlSyIQalR0cErdMVlVXkg-xCCq05RN4873zg',
    id: 1838,
    acronym: 'UFDU',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ufv.br',
    name: 'Universidade Federal de Viçosa',
    web_page: 'http://www.ufv.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMiq33vtEhSE6ocbRi1luhN-hiKHSMY7-zwhNnH2taASw8vdD-lOD9CA',
    id: 1839,
    acronym: 'UFDV',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ugf.br',
    name: 'Universidade Gama Filho',
    web_page: 'http://www.ugf.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWq2Rz1KBVm-LQyUsEjnH9APBCzxXAyXMAOlx2gq_ZCtSRMI_J3t7Q7LE',
    id: 1840,
    acronym: 'UGF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ulbra.br',
    name: 'Universidade Luterana do Brasil',
    web_page: 'http://www.ulbra.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSwo_bnirU4em_QOFZJ2ZVZKrYFU6TMAk1OKOrourOmibu0cp78YfZFsI',
    id: 1841,
    acronym: 'ULDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'umc.br',
    name: 'Universidade de Mogi das Cruzes',
    web_page: 'http://www.umc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXvZAot1LmkHMWsjO5IVQjVLLWbLOzC2G60nAjzwrGYIqLJkONYh6_uw',
    id: 1842,
    acronym: 'UDMDC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unaerp.br',
    name: 'Universidade de Ribeirão Preto',
    web_page: 'http://www.unaerp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5iS_rdrcBCnIo89Uc6-IiHpaZkRPn_EVSQnAL7rIKjytJETNQVc0tiQ',
    id: 1843,
    acronym: 'UDRP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unama.br',
    name: 'Universidade da Amazônia',
    web_page: 'http://www.unama.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS39-DQ7bPFzpZi3o9L-FRSwO1L7XVzUzGIaycSDm2W3868LKJxUCYIVA',
    id: 1844,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unb.br',
    name: 'Universidade de Brasília',
    web_page: 'http://www.unb.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx5CkDzTwhh5jWKOmqg8hl1WbGQG2O5FJjpLFSqsj_sJmduyr6XrSBD1E',
    id: 1845,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uneb.br',
    name: 'Universidade do Estado da Bahia',
    web_page: 'http://www.uneb.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREW3SUXodPYiAKHkbzHyC8LtNU_Y4U4BjlBuZKVDysADsonn38l1gC8A',
    id: 1846,
    acronym: 'UDEDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unesc.rct-sc.br',
    name: 'Universidade do Extremo Sul Catarinense',
    web_page: 'http://www.unesc.rct-sc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1BJtXZKjZ76JpsSekEGvziCKa15EgNAMNiyhAdaSTs9Zy2rIdNXxcww',
    id: 1847,
    acronym: 'UDESC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unesp.br',
    name: 'Universidade Estadual Paulista',
    web_page: 'http://www.unesp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbGRO_4i1RkNvaWL1YVPzt1kb5PQAoc1x6Je3m5Z4Ab9cmsiFQgzdbHw',
    id: 1848,
    acronym: 'UEP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'ung.br',
    name: 'Universidade de Guarulhos',
    web_page: 'http://www.ung.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnUT57f2oTZ6vrkqQy5LoZcibidmgTvoEo4nuwVHmdIE3WQoaGgTYy0A',
    id: 1849,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uniara.com.br',
    name: 'Centro Universitário de Araraquara',
    web_page: 'http://www.uniara.com.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1850,
    acronym: 'CUDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uniban.br',
    name: 'Universidade Bandeirante de São Paulo',
    web_page: 'http://www.uniban.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKyX1w5bwz7JzUvYFebmf3IwyYxHgUqCnDRycXy5eRz2uj7dAXScxjLQk',
    id: 1851,
    acronym: 'UBDSP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unib.br',
    name: 'Universidade Ibirapuera',
    web_page: 'http://www.unib.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6n0kfuXGT6Y-AZ1aTzPCY7mzRBdBKJIWr4Yffw1ZdHui421hip_y9www',
    id: 1852,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unibosco.br',
    name: 'Universidade Católica Dom Bosco',
    web_page: 'http://www.unibosco.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkW6oU9lFEOWjpsOjflfho1gKoSrV4aNK3fTe_VkJJ-yODtJi8BsnoeYo',
    id: 1853,
    acronym: 'UCDB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unibrasil.com.br',
    name: 'Faculdades Integradas do Brasil - UNIBRASIL',
    web_page: 'http://www.unibrasil.com.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9JE3OryMfLuTP9fLPN6HRcqUEzb7j2YgMkduLyWIo7gg_eSz8oWcIrg',
    id: 1854,
    acronym: 'FIDB-U',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unicamp.br',
    name: 'Universidade Estadual de Campinas',
    web_page: 'http://www.unicamp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxQluxejTnhgxl2tc1JrFcgfZKLD4hmP5cIlyhSaXQ-yLaH8Juf15FA-E',
    id: 1855,
    acronym: 'UEDC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unicap.br',
    name: 'Universidade Católica de Pernambuco',
    web_page: 'http://www.unicap.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBiw9qMXC27w-jFrGz20O8UJSkKEB3_iNueQ4m8Q3qgZtn8us-B0USo00',
    id: 1856,
    acronym: 'UCDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unicastelo.br',
    name: 'Universidade Camilo Castelo Branco',
    web_page: 'http://www.unicastelo.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPzMLVxq8ZCM5LdiD-Ldy4qjTBf6tu-Y3r7HgRsdsOEx8dUznqa9QHwjs',
    id: 1857,
    acronym: 'UCCB',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unicid.br',
    name: 'Universidade Cidade de São Paulo',
    web_page: 'http://www.unicid.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgwXE_WJUdTdVlzUZ3VPJUXaTk7JiXvIotr85QcayhcJrW7P7qpTcRug',
    id: 1858,
    acronym: 'UCDSP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unicruz.tche.br',
    name: 'Universidade de Cruz Alta',
    web_page: 'http://www.unicruz.tche.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJJoC_KWUnHNRw2_bbpZsdYil0p7_UZQD-RGWM8tWo0TONruaoJdGIDg',
    id: 1859,
    acronym: 'UDCA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unicsul.br',
    name: 'Universidade Cruzeiro do Sul',
    web_page: 'http://www.unicsul.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW1SKvDmZ9jLyzOuOI-Gc38LirAm_yuiSM7WOI_8sVC0nUE2T1HEDiBA',
    id: 1860,
    acronym: 'UCDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unidavi.rct-sc.br',
    name: 'Universidade para o Desenvolvimento do Alto Vale do Itajaí',
    web_page: 'http://www.unidavi.rct-sc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1861,
    acronym: 'UPODDAVDI',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unifacs.br',
    name: 'Universidade Salvador',
    web_page: 'http://www.unifacs.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRkbl1NLaS25VOiMq5iAtSdwi9104piBANPJ2B5UD0V8U2jcCWqcOQDg',
    id: 1862,
    acronym: 'US',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unifap.br',
    name: 'Universidade Federal do Amapá',
    web_page: 'http://www.unifap.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3j8yfEI0w1_M2KETNll6hKGFvAYjlFx8wVE_lEFwozfInsWn_e5BeYw',
    id: 1863,
    acronym: 'UFDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unifenas.br',
    name: 'Universidade de Alfenas',
    web_page: 'http://www.unifenas.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYgXOSJ12ssEaEc-mxeJE7K_HG4TKibPLLD7wr1jVRDKmPrzx0-lrNv-8',
    id: 1864,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unifeso.edu.br',
    name: 'Centro Universitário Serra dos Órgãos',
    web_page: 'http://www.unifeso.edu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1HzVEYqqeh8wh-kCzltyKovkYp3RmxIsxtMrPOB9wthmegdd-GwgJCS4',
    id: 1865,
    acronym: 'CUSDÓ',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unifor.br',
    name: 'Universidade de Fortaleza',
    web_page: 'http://www.unifor.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrm1dpmUf6tJrIxyjkq-Uv86cS-w1Nr7zrLiPpCA_KgmOUe9OvurJH-fc',
    id: 1866,
    acronym: 'UDF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unifran.br',
    name: 'Universidade de Franca',
    web_page: 'http://www.unifran.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpoRFWMe0HqBUg7kEgEcW4uvlbVhHnj2OiwTDLb4UOfeFv21_9dxQ-SbY',
    id: 1867,
    acronym: 'UDF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unig.br',
    name: 'Universidade de Nova Iguaçu',
    web_page: 'http://www.unig.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToJWIZhkWYae8579vbc90YmKMzVMHMnxQzndHkMnWLsPyDXF-s5hEOyQ',
    id: 1868,
    acronym: 'UDNI',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unigranrio.br',
    name: 'Universidade do Grande Rio',
    web_page: 'http://www.unigranrio.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNb6st5xL7r6Bwj_tR1VnOWIoYcq2AuKEEl31Bym3DlrvpTaEAUnz1Pxg',
    id: 1869,
    acronym: 'UDGR',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unijui.tche.br',
    name: 'Universidade Regional do Noroeste do Estado do Rio Grande do Sul',
    web_page: 'http://www.unijui.tche.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4qlqbnqQe89RMSuV8iYYGKjlQSNM9ltH9EdFKjb9dMGc6axUHa-fLeQkt',
    id: 1870,
    acronym: 'URDNDEDRGDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unimar.br',
    name: 'Universidade de Marília',
    web_page: 'http://www.unimar.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9be_xRdGCrS9q_IzBWz5_i_KP_ZTiHyIi9T5pvABWR2VklNAWUaMmMpE',
    id: 1871,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unimep.br',
    name: 'Universidade Metodista de Piracicaba',
    web_page: 'http://www.unimep.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHC7ezTpogWw2BwKg5eXzACovzpGJQqu6HddQdd0XJy2pWtB096U68t88',
    id: 1872,
    acronym: 'UMDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unimes.com.br',
    name: 'Universidade Metropolitana de Santos',
    web_page: 'http://www.unimes.com.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxUpPpD5tHIXG50okoISL_p2NfT4TfnsLKtS7RipsyQJWyXF1CKliBL3E',
    id: 1873,
    acronym: 'UMDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unimonte.br',
    name: 'Centro Universitário Monte Serrat',
    web_page: 'http://www.unimonte.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1874,
    acronym: 'CUMS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unimontes.br',
    name: 'Universidade Estadual de Montes Claros',
    web_page: 'http://www.unimontes.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwBVdw9u8_RR_iGGspgB4-iySJ-x-6gbf-vOVrqS1PipaHl42LbiN-BiA',
    id: 1875,
    acronym: 'UEDMC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unioeste.br',
    name: 'Universidade Estadual do Oeste do Paraná',
    web_page: 'http://www.unioeste.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ35QKP45Sm2WMv-YtGc4at1839nK4gIUlA8B1C0qETbLR6Ae_dO0NpawQ',
    id: 1876,
    acronym: 'UEDODP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unipe.br',
    name: 'Centro Universitário de João Pessoa',
    web_page: 'http://www.unipe.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVdf0lTjNtDVvcTV_iI_mcVFG4za6S3x_BV2jPkJFhoZb6KFLemOuU-w',
    id: 1877,
    acronym: 'CUDJP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unipli.com.br',
    name: 'Centro Universitário Plinio Leite',
    web_page: 'http://www.unipli.com.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTndP2LL8AhdFIION7nmUAop9PU1DrS1nHUtuSwkC3vqYdWCAx-boysGA',
    id: 1878,
    acronym: 'CUPL',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unip-objetivo.br',
    name: 'Universidade Paulista',
    web_page: 'http://www.unip-objetivo.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQwVZXG6M5NkysbkX4pZa7qluxiujAo4WKrUjiOec30k0rqyeJOAY5Hw',
    id: 1879,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unir.br',
    name: 'Universidade Federal de Rondônia',
    web_page: 'http://www.unir.br/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRG6fHjXlMPzf91kBmzgOSK-LGWf4I1z6tpeSB3gpFA3zW0jmFgbNNnh1U',
    id: 1880,
    acronym: 'UFDR',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unirio.br',
    name: 'Universidade do Rio de Janeiro',
    web_page: 'http://www.unirio.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4a0pwCgdf9uh4nxVm0mA0EYrFdvR-d9Owv4KsHi-ZLuWyexa0OeoHCQo',
    id: 1881,
    acronym: 'UDRDJ',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unisa.br',
    name: 'Universidade de Santo Amaro',
    web_page: 'http://www.unisa.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_WM1F09vlR1vMQ4EbaaHjT8CTU1g1aPeUeavSP45qn8wtSq_LfjlIQeY',
    id: 1882,
    acronym: 'UDSA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unisantos.com.br',
    name: 'Universidade Católica de Santos',
    web_page: 'http://www.unisantos.com.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8ReGjoUn8YH85xQ-BYRevYYXXNzkfGajgXq_NDRwVEkR5ihJJE2hxlrE',
    id: 1883,
    acronym: 'UCDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unisc.br',
    name: 'Universidade de Santa Cruz do Sul',
    web_page: 'http://www.unisc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVdlKV5duwwonmoE6ThZ1WUspdTgqZF5LHK9S8UPrK-BFQ2XwsuUCqnw',
    id: 1884,
    acronym: 'UDSCDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unisinos.br',
    name: 'Universidade Vale do Rio dos Sinos',
    web_page: 'http://www.unisinos.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQstqY-_D9vWiyDYcj1Ri-oumXSvofF4_OzfbC7JN2JjhPbk9L-VP2-T-U',
    id: 1885,
    acronym: 'UVDRDS',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unisul.br',
    name: 'Universidade do Sul de Santa Catarina',
    web_page: 'http://www.unisul.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdktvKB6-Zg-7Zek1uD9gJLSnnWtei_SzYqjLGP7WxfyDbxJWJAvEfSb8',
    id: 1886,
    acronym: 'UDSDSC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unitau.br',
    name: 'Universidade de Taubaté',
    web_page: 'http://www.unitau.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqwoWURItffT3BMgayvaSMYNfLSpxrjEfNJqBeXYF5Mva3CHjkaaCv-P1R',
    id: 1887,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unit.br',
    name: 'Universidade Tiradentes',
    web_page: 'http://www.unit.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKrhCif06fjHGtmKjlBatJgkbjdsLLoTxvcsiztqZXlwwKj3gpWetpH9k',
    id: 1888,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uniube.br',
    name: 'Universidade de Uberaba',
    web_page: 'http://www.uniube.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEI9rBybHhaklzBznXQ0h4Ad55sy9TTB2V_3rUBCUrsK0EkFnBLozLJw',
    id: 1889,
    acronym: 'UDU',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'univale.br',
    name: 'Universidade Vale do Rio Doce',
    web_page: 'http://www.univale.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjmaLpuiBacj6OeRiRUdWSnMiHWpxXY0cY7w5242RDAAmI-p9pdaApCAM',
    id: 1890,
    acronym: 'UVDRD',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'univali.rct-sc.br',
    name: 'Universidade do Vale do Itajaí',
    web_page: 'http://www.univali.rct-sc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_10QEoPlpzmAye5KHpYK4MnoLblqQaphrT2j4j-BbxIkAoYhU7nKZtg',
    id: 1891,
    acronym: 'UDVDI',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'univap.br',
    name: 'Universidade Vale do Paraiba',
    web_page: 'http://www.univap.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnUMBPjm-kMD68G5_jvEgJAhhkmiHKZfQJ1qeI6R_5CPplje986qlNNA',
    id: 1892,
    acronym: 'UVDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'univasf.edu.br',
    name: 'Universidade Federal do Vale do São Francisco',
    web_page: 'http://www.univasf.edu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3F_TZIuodDb4v6zXqd0AyUpn6vDhJr_cm-TcXmQKJliEKxLi04Qz80iy9',
    id: 1893,
    acronym: 'UFDVDSF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'universo.br',
    name: 'Universidade Salgado de Oliveira',
    web_page: 'http://www.universo.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBVulRLYduifT4f-BzZGUHb6Q3B_CrxJ1P4KFBrZqsfuJXx1s5UEro7PY',
    id: 1894,
    acronym: 'USDO',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unoesc.edu.br',
    name: 'Universidade do Oeste de Santa Catarina',
    web_page: 'http://www.unoesc.edu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTCeXmtU_gcTs9GLWdXa3rb5JTIVlxEMXscoWvAHuehWt0oTBKLjipeXnz',
    id: 1895,
    acronym: 'UDODSC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unoeste.br',
    name: 'Universidade do Oeste Paulista',
    web_page: 'http://www.unoeste.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5Ca97VMN_Ov-cHu02WvjYC_V1QaxwiC0Mrzx9dsbdhndCsspS-nE0Y44',
    id: 1896,
    acronym: 'UDOP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'unp.br',
    name: 'Universidade Potiguar',
    web_page: 'http://www.unp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtwJXsy4mTXdzpYpTSHR6dzxBWyX6cfU0IJ5ItewAEWG1DGeCZJdiY_OYNAA',
    id: 1897,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'upe.br',
    name: 'Universidade de Pernambuco',
    web_page: 'http://www.upe.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh4TRwu-4Ily5jwRgnN2LII50MkkWnwjAyv5I0u3pyrhxzyWZYDTKkDg',
    id: 1898,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'upf.tche.br',
    name: 'Universidade de Passo Fundo',
    web_page: 'http://www.upf.tche.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxP7kh0mOWSDt0BJRlldbhdB7cq1FuSsJ_FLCgr5sya-ViAHquNQE_nd4',
    id: 1899,
    acronym: 'UDPF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'upis.br',
    name: 'Faculdades Integradas UPIS',
    web_page: 'http://www.upis.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5yB7d6w415CAzjyuQkxNgqq2MewDBZs-hi5RQCki947K_i52-S06KHA',
    id: 1900,
    acronym: 'FIU',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'urca.br',
    name: 'Universidade Regional do Cariri',
    web_page: 'http://www.urca.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRSRO5RrnlS4eHaFlZmeKvy5cJhwheINYiZRtQUJCBVTx-a2VIuUzSuoo',
    id: 1901,
    acronym: 'URDC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'urcamp.tche.br',
    name: 'Universidade da Região da Campanha',
    web_page: 'http://www.urcamp.tche.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9aUDdZ6xew0ebZSM7s6rRe6FwbsUdm4DajZBDzczzQwKf0vETCVC6vg',
    id: 1902,
    acronym: 'UDRDC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uri.br',
    name: 'Universidade Regional Integrada do Alto Uruguai e das Missões',
    web_page: 'http://www.uri.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS99MRFeXbliS0Ni3z2ql4e4bztJDBQ9bzf_3x2aiQfdXRJGD5jaknHrcQ',
    id: 1903,
    acronym: 'URIDAUEDM',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'usc.br',
    name: 'Universidade do Sagrado Coração',
    web_page: 'http://www.usc.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRr0HDRWwQKmyNvS1K08GaSRol2pD7aJ5fURITAkFEw7tDtZONaCnnlFw',
    id: 1904,
    acronym: 'UDSC',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'usf.br',
    name: 'Universidade São Francisco',
    web_page: 'http://www.usf.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlLNzZK6aSz0wMZ1A94u3kw6vNTdk-Nfg_fOukRxIO6n16DLOD9r5KqeM',
    id: 1905,
    acronym: 'USF',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'usp.br',
    name: 'Universidade de São Paulo',
    web_page: 'http://www.usp.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTwask4URCtXejlEPcTvzUdOcVy1e7ZtJWJKQIsZDwFdlMPwtgoMMSq1c',
    id: 1906,
    acronym: 'UDSP',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'usu.br',
    name: 'Universidade Santa Úrsula',
    web_page: 'http://www.usu.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ8tSdfXpG4LL6bdwJAQdCOmOGdXWG9fOPLodcuNLKiDU7o1seC7oI_8s',
    id: 1907,
    acronym: 'USÚ',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uva.br',
    name: 'Universidade Veiga de Almeida',
    web_page: 'http://www.uva.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDz6Di-0ZHyvqym_Wl7nPGHcgr6kHYIBD6pGO1OglZJUaIo-BNb9r4zao',
    id: 1908,
    acronym: 'UVDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'uvanet.br',
    name: 'Universidade Estadual do Vale do Acaraú',
    web_page: 'http://www.uvanet.br/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHqinMrTXuSdnc8wnk5t7ELiJP99qIE4GwX5XvM6pV5ubWSkmpD0R_-EE',
    id: 1909,
    acronym: 'UEDVDA',
  },
  {
    alpha_two_code: 'BR',
    country: 'Brazil',
    domain: 'web.prover.com.br',
    name: 'Universidade de Itaúna',
    web_page: 'http://web.prover.com.br/fui/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl1VE09TNESbM4G8zy1IJRrBEhZXXIGld2XoofBSjyUJ93JEHMMCmiRfou6g',
    id: 1910,
    acronym: 'UDI',
  },
  {
    alpha_two_code: 'BN',
    country: 'Brunei Darussalam',
    domain: 'itb.edu.bn',
    name: 'Institut Teknologi Brunei',
    web_page: 'http://www.itb.edu.bn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4znnhPfARBfnfQhK8zMykLPn843Fa9eCufIFM58xLxRAKcjihLiuvOOI',
    id: 1911,
    acronym: 'ITB',
  },
  {
    alpha_two_code: 'BN',
    country: 'Brunei Darussalam',
    domain: 'ubd.edu.bn',
    name: 'University of Brunei Darussalam',
    web_page: 'http://www.ubd.edu.bn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbuEYxN38IV8Ya9Z7stKEY2ra5CohSmUdBvs5Fx1IwwVzP4P-kKzMioCA',
    id: 1912,
    acronym: 'UBD',
  },
  {
    alpha_two_code: 'BN',
    country: 'Brunei Darussalam',
    domain: 'unissa.edu.bn',
    name: 'Universiti Islam Sultan Sharif Ali',
    web_page: 'http://www.unissa.edu.bn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxJIlJnEEkjyt7RQbwrSQxeJCb_j1s4-fxhyXyh93hltvsWq72v1NWjfY',
    id: 1913,
    acronym: 'UISSA',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'amti.hit.bg',
    name: 'Academy of Music Dance and Fine Arts',
    web_page: 'http://amti.hit.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvOX0AdY0ZzpT9n452SLoDMtTjXS-4OhTcyqtLoofqzJ2ze5aWsxcu3Wk',
    id: 1914,
    acronym: 'AMDFA',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'aubg.bg',
    name: 'American University in Bulgaria',
    web_page: 'http://www.aubg.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRp4yJ2cQfliJ6JeQusF95B6Nsm9mgmUfkmN_wfM9okUjKDS5f7gO5WZYQ',
    id: 1915,
    acronym: 'AUIB',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'au-plovdiv.bg',
    name: 'Agricultural University of Plovdiv',
    web_page: 'http://www.au-plovdiv.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuF-p9k31hi6GR4U6g3uaQ5oU742xt0niumbfVXoJrm6e_3hfFOdK8fj8',
    id: 1916,
    acronym: 'AUP',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'bfu.bg',
    name: 'Bourgas Free University',
    web_page: 'http://www.bfu.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDVGV5r_NpBBT-UGaKIhShiX0SkZzjOO1PLud9BC7XRrtuUefuY1qVF9Q',
    id: 1917,
    acronym: 'BFU',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'btu.bg',
    name: 'Bourgas University "Prof. Assen Zlatarov"',
    web_page: 'http://www.btu.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbQJajXTwmjkpVCKVx1iNoolkEQHgha3zVmC6jIZcYNbVLW1Bn5PE2nw',
    id: 1918,
    acronym: 'BU"AZ',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'cityu.bg',
    name: 'City University Programs in Bulgaria',
    web_page: 'http://www.cityu.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo4LVhrdkGCSLGgIlLGk6jbLx8ZquUKNp_O3VmU7iVf0DueMYQGjg2nVk',
    id: 1919,
    acronym: 'CUPIB',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'ltu.bg',
    name: 'University of Forestry Sofia',
    web_page: 'http://www.ltu.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPW85OxMzDSn0sRMXfFVwhjXhOb-eIxeirzZZgt_eeSoIKfIoHb9ELeg',
    id: 1920,
    acronym: 'UFS',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'meduniversity-plovdiv.bg',
    name: 'Medical University Plovdiv',
    web_page: 'http://www.meduniversity-plovdiv.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeC1wb9k8FS6slv_lts0g3T40JBiPVBnzy0C7Fgo_yafg0h_2659FSwhk',
    id: 1921,
    acronym: 'MUP',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'mgu.bg',
    name: 'University of Mining and Geology "St. Ivan Rils"',
    web_page: 'http://www.mgu.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-M3-h35fKKZyMQswlvujkA7Uk31klw_M8rjoiqXXg4HA4duDQ7UdNk1ZW',
    id: 1922,
    acronym: 'UMG"IR',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'mu-pleven.bg',
    name: 'Medical University Pleven',
    web_page: 'http://www.mu-pleven.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_EhhnaHgBS0BfaHXe5wts8dGJwteEECLqkWJImBohL28Qcnf7RTQaDEU',
    id: 1923,
    acronym: 'MUP',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'mu-sofia.bg',
    name: 'Medical University of Sofia',
    web_page: 'http://www.mu-sofia.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXZ0mInlWtZPK31G09iUqWPrnWcBBZEBq50ls8N4zOIL-MYiXhaipJu74',
    id: 1924,
    acronym: 'MUS',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'mu-varna.bg',
    name: 'Medical University Varna',
    web_page: 'http://www.mu-varna.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQao9ylJrGKYFhfI8qzoawrphEgouufsTZ-4Z2VRUkGtrnn8y1xcI2W9_8',
    id: 1925,
    acronym: 'MUV',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'natfiz.bg',
    name: 'National Academy for Theatre and Film Arts "Krustju Sarafov"',
    web_page: 'http://www.natfiz.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1926,
    acronym: 'NAFTFA"S',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'nbu.bg',
    name: 'New Bulgarian University',
    web_page: 'http://www.nbu.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwOJZB-ugUHT-qzRL8S4CHRrY5DOCmH_qrElNj7CFH5g-tI5-3Eo4bneI',
    id: 1927,
    acronym: 'NBU',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'nha-bg.org',
    name: 'National Academy of Arts',
    web_page: 'http://www.nha-bg.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ7TIaecufsJUkXo08LEw9cppllEQ6DqpQpukuqs4SlhpDdWSZvq1S3hg',
    id: 1928,
    acronym: 'NAA',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'nma.bg',
    name: 'National Academy of Music "Pantcho Vladigerov"',
    web_page: 'http://www.nma.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSbiOR4T9FIHY5EV9Bp_bwqes3s0daDI03JvaPF4MGcM7PTnJa4aNV_w',
    id: 1929,
    acronym: 'NAM"V',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'nsa.bg',
    name: 'National Sports Academy Sofia',
    web_page: 'http://www.nsa.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5OHnCumXMwdxCFOJRkak38LbOe825_Uw9j43TWKdYjeLbzMBullYQIaTD',
    id: 1930,
    acronym: 'NSAS',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'pv-ma.bg',
    name: 'Military University Shoumen',
    web_page: 'http://www.pv-ma.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvc_jEEjRxA3NhH-U_V2uDzDZC0SAzJjPznx2h4V4fjxw4YPIMVt0cjQ',
    id: 1931,
    acronym: 'MUS',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'ru.acad.bg',
    name: 'University of Rousse',
    web_page: 'http://www.ru.acad.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfn47fLI5q46qfhyFdRU2pIdoDA1BidKjAsh4YpjUvU4hzfaPkul623BM',
    id: 1932,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'shu-bg.net',
    name: 'Shoumen University "Konstantin Preslavski"',
    web_page: 'http://www.shu-bg.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeF1a-luOx8LQ01EIBSGaPZuom3M6JDPVpOC49uvx2Sg9KMg4VIhKmTmk',
    id: 1933,
    acronym: 'SU"P',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'swu.bg',
    name: 'South-West University "Neofit Rilski"',
    web_page: 'http://www.swu.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReIZLxKo-0hBb0B2U2EJ6GqtypAZNxxl8tbHsEPqwzJmx9630z3bC93Fc',
    id: 1934,
    acronym: 'SU"R',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'tugab.bg',
    name: 'Technical University of Gabrovo',
    web_page: 'http://www.tugab.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfn47fLI5q46qfhyFdRU2pIdoDA1BidKjAsh4YpjUvU4hzfaPkul623BM',
    id: 1935,
    acronym: 'TUG',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'tu-sofia.bg',
    name: 'Technical University of Sofia',
    web_page: 'http://www.tu-sofia.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHelNi3Du_SS2wdhYeoI0kcZK8vyzN_ub9rAG2_41zUkeXNZMs608nUQ',
    id: 1936,
    acronym: 'TUS',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'tu-varna.acad.bg',
    name: 'Technical University of Varna',
    web_page: 'http://www.tu-varna.acad.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7DRW0R30kogOwfewQCMIjiCRprrGgbeBrTblEvkc4Njflzi6xjSEvOw',
    id: 1937,
    acronym: 'TUV',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'uacg.bg',
    name: 'University of Architecture Civil Engineering and Geodesy',
    web_page: 'http://www.uacg.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuB84UzPtBGxZN8xWnnaqqpCP5AaHfOvLk5nGr2_UpBpJupZRHIioBgoE',
    id: 1938,
    acronym: 'UACEG',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'uctm.edu',
    name: 'University of Chemical Technology and Metallurgy',
    web_page: 'http://www.uctm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSm05eVMO--YlkhY7KoKjysNWsEA7TR-3LP3pFP1GbaCoA8B6hLTBK3A',
    id: 1939,
    acronym: 'UCTM',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'ue-varna.bg',
    name: 'University of Economics Varna',
    web_page: 'http://www.ue-varna.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoUtRjaZgcCBrr0kIGJ9NqoWH4_qTW5X2nxQagR_n-16Z2t2xBMU4E8fY',
    id: 1940,
    acronym: 'UEV',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'uft-plovdiv.bg',
    name: 'University of Food Technology',
    web_page: 'http://www.uft-plovdiv.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFyvl78GONjuiVa_CZ3xl5i4DXF9xgrwZfVemelUjfoyid0uWI1EyPSWE',
    id: 1941,
    acronym: 'UFT',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'uni-plovdiv.bg',
    name: 'University of Plovdiv',
    web_page: 'http://www.uni-plovdiv.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeC1wb9k8FS6slv_lts0g3T40JBiPVBnzy0C7Fgo_yafg0h_2659FSwhk',
    id: 1942,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'uni-sofia.bg',
    name: 'Sofia University "St. Kliment Ohridski"',
    web_page: 'http://www.uni-sofia.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoEenJwCm_GLb_75QNox8vjxCOJRfDOGpTFpVml1DT93qI-Bbdc-iAueo',
    id: 1943,
    acronym: 'SU"KO',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'uni-svishtov.bg',
    name: 'Academy of Economics "Dimitur A. Tscenov"',
    web_page: 'http://www.uni-svishtov.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 1944,
    acronym: 'AE"AT',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'uni-sz.bg',
    name: 'Trakia University Stara Zagora',
    web_page: 'http://www.uni-sz.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9KprlrNEE8cmfxl1BmytWtEI__HrlJjk1HnkgKv6oWMxQC3EJMap9lEY',
    id: 1945,
    acronym: 'TUSZ',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'uni-vt.bg',
    name: 'Veliko Turnovo University "Cyril and Methodius"',
    web_page: 'http://www.uni-vt.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRas2zRAFKycWIVjRxwVBbDdlw-_REKd1C_YT-oCt8wvod_AR477OgoYutH',
    id: 1946,
    acronym: 'VTU"M',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'unwe.acad.bg',
    name: 'University of National and World Economy',
    web_page: 'http://www.unwe.acad.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrwDbqhflUxaBOI02rXxLoi4918pyN3FSjdDVaC4XvWKmCnVh4ECovug',
    id: 1947,
    acronym: 'UNWE',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'vfu.bg',
    name: 'Varna Free University',
    web_page: 'http://www.vfu.bg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT39_r53ycMSoFqjUNibazav_zL41yJPW_PATT27ppw1Xt0DwxFPRXS2M0',
    id: 1948,
    acronym: 'VFU',
  },
  {
    alpha_two_code: 'BG',
    country: 'Bulgaria',
    domain: 'vumk.eu',
    name: 'International University College',
    web_page: 'http://www.vumk.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTJU8Yx8xtMBr1nPDpKyCXgOA8u_mhJSVnm-BZRrnVCCrBXO9nVs6j1LA',
    id: 1949,
    acronym: 'IUC',
  },
  {
    alpha_two_code: 'BF',
    country: 'Burkina Faso',
    domain: 'univ-ouaga.bf',
    name: 'Université de Ouagadougou',
    web_page: 'http://www.univ-ouaga.bf/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPGKf56E6TXPr4E6xQiipvh68bNByG5i7I3VrlY7znJcsINIgQ0Y-kOg',
    id: 1950,
    acronym: 'UDO',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'itbmu.org.mm',
    name: 'International Theravada Buddhist Missionary University',
    web_page: 'http://www.itbmu.org.mm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8h6_e1sHhufnZ1nlgFTSFOZybE_-fDduOpT3KYg1xps3tzo1WarDy5pQ',
    id: 1951,
    acronym: 'ITBMU',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Mandalay Technological University',
    web_page: 'http://www.most.gov.mm/mtu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROnHiUb6nMXR_3GJxJPPOHAYP2RhrQiweOjwfu3oBnx3ODo8burIjf0g',
    id: 1952,
    acronym: 'MTU',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Myanmar Aerospace Engineering University',
    web_page: 'http://www.most.gov.mm/maeu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwn8-xOzLThl6lRuj0evGihjfcXktCZ61lmVjEEn3lBTqNjOxybFZ9CuAw',
    id: 1953,
    acronym: 'MAEU',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Banmaw)',
    web_page: 'http://www.most.gov.mm/banmawtu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTctofaMLzeHUXs25b7HY2d0bIVdQALIXF6kCHG10N_MNTR_CZGFSfinm0',
    id: 1954,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Dawei)',
    web_page: 'http://www.most.gov.mm/daweitu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIUh2Cvn2UvfYwB0TWCfA7quISEeX8ZqXfq5pYle-3QRroZ0mNZwq71oVg',
    id: 1955,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Hinthada)',
    web_page: 'http://www.most.gov.mm/hinthadatu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8MxU9DUa2b_Gfiz2MyY7O9b5UUKbOH6FhfqumKaFxTljka6L3doyIMN0',
    id: 1956,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Hmawbi)',
    web_page: 'http://www.most.gov.mm/hmawbitu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ77_mKMu---6T7Dzk-4uO_glDQOfMhTIVgsNmyH5ci2wBsDHu18koEw',
    id: 1957,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Hpaan)',
    web_page: 'http://www.most.gov.mm/hpaantu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQs50xDktSoOcvRTguLWXpMNa8EHnNSykXtUIuUe-8kuWycouKcZPR0mYl-',
    id: 1958,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Kalay)',
    web_page: 'http://www.most.gov.mm/kalaytu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPsEXs--ggbVkx0icAvCZhoOtjKPh2mdkeF4XMCtcRYcCjwKngTRAKyG5Q',
    id: 1959,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Kyaing Tong)',
    web_page: 'http://www.most.gov.mm/kyaingtongtu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqo7_N-D3lXCJfTNxvSo-brRPDHqjSR6ze6bWtWfNTHI3QF0TxHQ2Gguw',
    id: 1960,
    acronym: 'TU(T',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Kyaukse)',
    web_page: 'http://www.most.gov.mm/kyauksetu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNPXBOB7Wi5c14RULGGNqr2r4vMATEok6VkYG-_qS9dHdgQE9OMe5gEOI',
    id: 1961,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Lashio)',
    web_page: 'http://www.most.gov.mm/lashiotu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6vfnuP31LqhGCvEt7tJJV-8MhNLLZKh2ADEhmLl_h5l0CdMQ0IWpobTw',
    id: 1962,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Loikaw)',
    web_page: 'http://www.most.gov.mm/loikawtu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQhsglixqNhg78h_EgUHnXYIDFtiWa-qL2TQqOrFKZRIflbXQv1Qh-Pw',
    id: 1963,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Magway)',
    web_page: 'http://www.most.gov.mm/magwaytu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYHGwJ715pbuK08jB7-p-WG8YZ24uObGeE8LHDRV9b2GCxLayO4EQ1MTo',
    id: 1964,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Mandalay)',
    web_page: 'http://www.most.gov.mm/mdytu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8RsxD9505JAU4Hl8GxkhNQ9noTzBcRBuT5eJGDxguU_ofY-TRfBqQuQ',
    id: 1965,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Maubin)',
    web_page: 'http://www.most.gov.mm/maubintu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrhLFlKb8GCevTLHlHA055vPQ8EwkcunLWhaEEOYYqOtNsbcQd9gtT-9kO',
    id: 1966,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Mawlamyaing)',
    web_page: 'http://www.most.gov.mm/mawlamyaingtu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQopsP1H4tNxCHnQnQeI7wvTNKbpUuVqumIUsak1O083ORA4Q_iIW5clYU',
    id: 1967,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Meikhtila)',
    web_page: 'http://www.most.gov.mm/meikhtilatu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIEWc7ODEEKCepIoSSv5UGslyJQr8xWLcmS0abGND14Xvuv-wuQ956-pQ',
    id: 1968,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Monywa)',
    web_page: 'http://www.most.gov.mm/monywatu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJkH_4yGvRYT5q6Ck45hyDl0r_JWKQLUXJQWjQ9l6sA0PUKF243qYd6KM',
    id: 1969,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Myeik)',
    web_page: 'http://www.most.gov.mm/myeiktu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOdmVx8jeXMdg6JyhzoGV61HD5c4OeUSuWwBNoMXOc_t_7S48fwgicaQ',
    id: 1970,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Myitkyina)',
    web_page: 'http://www.most.gov.mm/myitkyinatu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcFKB5aH2Kt_bobcMqb42Vl0AvghJRq2jIg2MV1nGRapUChilNOihbPp7h',
    id: 1971,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Pakokku)',
    web_page: 'http://www.most.gov.mm/pakokkutu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDcro4BQ33g9RoYQUHzcDQiRH9NVG8fb3IPtIO__ZI1fU2GGR6vlAdI8nM',
    id: 1972,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Pathein)',
    web_page: 'http://www.most.gov.mm/patheintu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtvAiaFwDIPSJ8P9Q-m0SijP4t4xNTSx4PT3N4aJUCi8jqJgntbSMAS-DT',
    id: 1973,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Pinlon)',
    web_page: 'http://www.most.gov.mm/pinlontu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3wisEyMID1b87ga7_KbFck-WHq_nmdH_dDvHM70eJJL8dZdGdcjjFbA',
    id: 1974,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Pyay)',
    web_page: 'http://www.most.gov.mm/ptu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0BBxCELoIPLzFlDcTn5f8zHFMATJdDS3H9-rrWhO43XQAgqUyJIbVZA',
    id: 1975,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Sittwe)',
    web_page: 'http://www.most.gov.mm/sittwetu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg4GFhQmRNZiN1yL-1bzXENtSjcW3jFQmZiRiXJ0FB9NWpZgyzYiZCmrxG',
    id: 1976,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Taunggyi)',
    web_page: 'http://www.most.gov.mm/taunggyitu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtwOBYOFLXx855biQgAibrWuO5yDshd9khyQybDSW-qUj0_ebPU9CRqe4',
    id: 1977,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Taungoo)',
    web_page: 'http://www.most.gov.mm/taungootu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS47HpOlb6O3z9s1X8avOR9o5S3sX8-dYHBQmCafzE15b9qMD7HoJBDYrM',
    id: 1978,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Thanlyin)',
    web_page: 'http://www.most.gov.mm/thanlyintu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9N9PPbQMALftjPkaPwgBqYLV4eS_ZvtmeMpThIzB30WdES6m7wsIMzg',
    id: 1979,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (West Yangon)',
    web_page: 'http://www.most.gov.mm/wytu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwP4stZGB7Z2wj9VTA-anvUKqLUE1JjgaIIvdE7e1741nlUd0a5Bu62kw',
    id: 1980,
    acronym: 'TU(Y',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'most.gov.mm',
    name: 'Technological University (Yangon)',
    web_page: 'http://www.most.gov.mm/ytu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROnHiUb6nMXR_3GJxJPPOHAYP2RhrQiweOjwfu3oBnx3ODo8burIjf0g',
    id: 1981,
    acronym: 'TU(',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'ucsy.edu.mm',
    name: 'University of Computer Studies Yangon',
    web_page: 'http://www.ucsy.edu.mm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqo7_N-D3lXCJfTNxvSo-brRPDHqjSR6ze6bWtWfNTHI3QF0TxHQ2Gguw',
    id: 1982,
    acronym: 'UCSY',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'um1ygn.edu.mm',
    name: 'University of Medicine 1 Yangon',
    web_page: 'http://www.um1ygn.edu.mm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeqJ2v4rAENxIwFM0IiRlYPaH8oMh6_d4HxlD32LkCQjO9IqMFaM-0Mw',
    id: 1983,
    acronym: 'UM1Y',
  },
  {
    alpha_two_code: 'MM',
    country: 'Myanmar',
    domain: 'ummg.edu.mm',
    name: 'University of Medicine Magway',
    web_page: 'http://www.ummg.edu.mm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYPwE_noyCM3rlpkPotSdOE7HhVDFF8ANGr5GSNhubdj0E-dMEK3zTSA8',
    id: 1984,
    acronym: 'UMM',
  },
  {
    alpha_two_code: 'BI',
    country: 'Burundi',
    domain: 'hopeafricauniversity.org',
    name: 'Hope Africa University',
    web_page: 'http://hopeafricauniversity.org/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcT7EcnfSUAVkOT94VBnGHDv31hKDzuXa-8-AstuiNNP5r7ZpHGquAXDsA',
    id: 1985,
    acronym: 'HAU',
  },
  {
    alpha_two_code: 'BI',
    country: 'Burundi',
    domain: 'ub.edu.bi',
    name: 'Université du Burundi',
    web_page: 'http://www.ub.edu.bi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA-fMSVG8v3nXZKQlYM1tPhe6suMAkOqlOL_gSJovbI6iqS5N8ykDNYA',
    id: 1986,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'BI',
    country: 'Burundi',
    domain: 'ulbu.bi',
    name: 'Université Lumière de Bujumbura',
    web_page: 'http://www.ulbu.bi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlkboUMuCPeoHW95Y7axLm2oDggaLywtmzx6JFCh6BXofZqmU4Gn4gMw',
    id: 1987,
    acronym: 'ULDB',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'aeu.edu.kh',
    name: 'Asia Europe University',
    web_page: 'http://www.aeu.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdT2LrhDMe86ALTLMNQXBZk5ZZf7BjPWn-VZO9G08zwyCgzzcHfN5udl82',
    id: 1988,
    acronym: 'AEU',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'angkor.edu.kh',
    name: 'Angkor University',
    web_page: 'http://www.angkor.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ1QGNud4s3XuOQkL4rD3kUintZSp2XYLCjvsHCFdHQldZDpyAO_b5fg',
    id: 1989,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'bbu.edu.kh',
    name: 'Build Bright University',
    web_page: 'http://www.bbu.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ1QGNud4s3XuOQkL4rD3kUintZSp2XYLCjvsHCFdHQldZDpyAO_b5fg',
    id: 1990,
    acronym: 'BBU',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'cup.edu.kh',
    name: 'Chamreun University of Poly Technology',
    web_page: 'http://www.cup.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEW3lcvJ0veOQ_jAem67ueIEA22K-eKedoFHmIjl-Zr8JRh9MfLgiCfQ',
    id: 1991,
    acronym: 'CUPT',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'cus.edu.kh',
    name: 'Cambodia University of Specialties',
    web_page: 'http://www.cus.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCmbPeJoehnGulxpNxb2kX9auUSfQX_IBUqsHt88YeXEzC9k0pVXYQVB4',
    id: 1992,
    acronym: 'CUS',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'ine.edu.kh',
    name: 'National Institute of Education',
    web_page: 'http://www.ine.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCy5qsubiPBvvPD9AoZA3vT3U8-WUG2vXlLH5E-5l1NWcqhVzvjZzuK9A',
    id: 1993,
    acronym: 'NIE',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'iu.edu.kh',
    name: 'International University',
    web_page: 'http://www.iu.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE0vq04O3CBOQv9fYdcz5SWBuXd201hxYx7yIGsd78FGOrrrPi_p7dq9Y',
    id: 1994,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'mekong.edu.kh',
    name: 'Cambodian Mekong University',
    web_page: 'http://www.mekong.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsT2xR3nHm65pQK5nIThPaA-hncgAN8P3e1mu6HzTub_QDKN2TVwCnVjU',
    id: 1995,
    acronym: 'CMU',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'norton.edu.kh',
    name: 'Norton University',
    web_page: 'http://www.norton.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgdEgWkSvhIu464bsWY4rwTiVuGvzCwSRb4XTG0CX0qrxE52bBxKbxkxI',
    id: 1996,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'num.edu.kh',
    name: 'National University of Management',
    web_page: 'http://www.num.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUMa0xDNJjqk1pKs8qJna4LXKAxbUe8db-a1X6k7xyziVpFAnUpnGD7lE',
    id: 1997,
    acronym: 'NUM',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'puc.edu.kh',
    name: 'Pannnasatra University of Cambodia',
    web_page: 'http://www.puc.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRthfdIBeVXgRSHyr4JxcA_CHTVmgeBEYeqdGvtrRcvdc88rhaJqvdYFTE',
    id: 1998,
    acronym: 'PUC',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'rua.edu.kh',
    name: 'Royal University of Agriculture',
    web_page: 'http://www.rua.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXuRUH7Ryu2iUUU_ztW8TS6sL24bLTS9vIR9ydJZ2UAcddqBw_GRTj5A',
    id: 1999,
    acronym: 'RUA',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'rufa.edu.kh',
    name: 'Royal University of Fine Arts',
    web_page: 'http://www.rufa.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK0bb5zX4g92i6gVExn8vLrlIBOVzLlu49p7k1Hp8_XLIWleO7uXuUnw',
    id: 2000,
    acronym: 'RUFA',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'rule.edu.kh',
    name: 'Royal University of Law and Economics',
    web_page: 'http://www.rule.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUnUWZqQAPsv2Vh9Zv2dahcIfSW5y7E9bmx5lh6Gb_BQI98NCff71nS_I',
    id: 2001,
    acronym: 'RULE',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'rupp.edu.kh',
    name: 'Royal University of Phnom Penh',
    web_page: 'http://www.rupp.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR14FslxMFrVASMktzoAXnrGjj09g5tGE6HBp7skq_MJ9h2OIMKsfLx27KG',
    id: 2002,
    acronym: 'RUPP',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'sru.edu.kh',
    name: 'Svey Reong University',
    web_page: 'http://www.sru.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEDTb9a9Gjr7CV9m5PrTAe28tP4dE_TN5xF-Rfge3Gm9MQfvK8pGRwJg',
    id: 2003,
    acronym: 'SRU',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'uc.edu.kh',
    name: 'University of Cambodia',
    web_page: 'http://www.uc.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRthfdIBeVXgRSHyr4JxcA_CHTVmgeBEYeqdGvtrRcvdc88rhaJqvdYFTE',
    id: 2004,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'univ-sante.edu.kh',
    name: 'University of Health and Science',
    web_page: 'http://www.univ-sante.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSX8HozsHx-SP5PK1Cg6VfRRzeozc-hMo9oYGbo5I1k8XFdFRHEaPo_Q',
    id: 2005,
    acronym: 'UHS',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'utpp.edu.kh',
    name: 'University of Technology Phnom Penh',
    web_page: 'http://www.utpp.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0_nj2Uwe7uZKb_QIQEBYITHWy092TSCHl8bXfttBub47MA9HYiM1MAA',
    id: 2006,
    acronym: 'UTPP',
  },
  {
    alpha_two_code: 'KH',
    country: 'Cambodia',
    domain: 'zamanuniversity.edu.kh',
    name: 'Zaman University',
    web_page: 'http://www.zamanuniversity.edu.kh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMpSE_KAC0tq4v3l1_UzP9hk9Z2truXD6dI0G_DXidr-nOBHnVi2LJEF0',
    id: 2008,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'bamendauniversity.com',
    name: 'Bamenda University of Science & Technology',
    web_page: 'http://www.bamendauniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHxXwvUrX4N-hQZtEL2z4AAE7BPFur6JXwQMqG6uBFeGK1ijiV5YyO8Uw',
    id: 2009,
    acronym: 'BUS&T',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'fomicgroup.cm',
    name: 'Fomic Polytechnic',
    web_page: 'http://www.fomicgroup.cm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsjcG2Oh8utvFIujCWpcVxkmlxg_EJnaVtZDjX9bcHEqo1KJdJfX8aIA',
    id: 2010,
    acronym: 'FP',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'ubuea.cm',
    name: 'Université de Buéa',
    web_page: 'http://www.ubuea.cm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyGoRAtSxhXcVXIu5HPXc3D_dpZpGnUYffSuZ7_9_leB07HLmICp28518',
    id: 2011,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'udesmontagnes.org',
    name: 'Université des Montagnes',
    web_page: 'http://www.udesmontagnes.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1zWKZzT5RI4P2-dU2rpXyKJjgWUW2Z5EyBxua8wAKX5p0XmA0KSILBP8',
    id: 2012,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'unibda.net',
    name: 'Université de Bamenda',
    web_page: 'http://www.unibda.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiUL05sYNsHU65DcQZ11I8NT6tfcvkprls0Cfou-SHV_3tnfM5pohbyQ',
    id: 2013,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'univ-douala.com',
    name: 'Université de Douala',
    web_page: 'http://www.univ-douala.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7HpMNw9xZRuRoJXbe0DVqIFYB57xGGOxevJpJXdkTlLWZhhMVkhd0zw',
    id: 2014,
    acronym: 'UDD',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'univ-dschang.org',
    name: 'Université de Dschang',
    web_page: 'http://www.univ-dschang.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwS6yn0H2vdwN_Fga3Uqk_qAlnAUYdNWLHgdK1LpyozdJqV3rflQJV8x6o',
    id: 2015,
    acronym: 'UDD',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'universite-yde2.org',
    name: 'Université de Yaoundé II',
    web_page: 'http://www.universite-yde2.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQy0qe20XzPDO5LnR2aXUgz7P2uMTdqGtKUcBXygUZuWm9ZgJxsgpyH8DI',
    id: 2016,
    acronym: 'UDYI',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'univ-ndere.cm',
    name: 'Université de Ngaoundéré',
    web_page: 'http://www.univ-ndere.cm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJwaWU44jJijGb12_dCO4tF7B5b1Ps5qFO79za7CWnq_kcw2c3ZBVw',
    id: 2017,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'CM',
    country: 'Cameroon',
    domain: 'uy1.uninet.cm',
    name: 'Université de Yaoundé I',
    web_page: 'http://www.uy1.uninet.cm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQy0qe20XzPDO5LnR2aXUgz7P2uMTdqGtKUcBXygUZuWm9ZgJxsgpyH8DI',
    id: 2018,
    acronym: 'UDYI',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'acadiau.ca',
    name: 'Acadia University',
    web_page: 'http://www.acadiau.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_75s_sxhFzqsZreKeenz3Ol7nmQb3_QRh28WQLV6gGIt9uqWqIkeWIQ',
    id: 2019,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'algonquincollege.com',
    name: 'Algonquin College',
    web_page: 'http://www.algonquincollege.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNGvzKzu3Q0inugrg_PuurwVROJfsi6N-hc04O7U1LiPhJBi2_80DO6w',
    id: 2020,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ashtoncollege.com',
    name: 'Ashton College',
    web_page: 'http://www.ashtoncollege.com/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQNJek3ukE6zRJsThFJsv4nG8FzXzBTAUlMXY8SHw9w06QXqa6wjrZf3A',
    id: 2021,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'assumptionu.ca',
    name: 'Assumption University',
    web_page: 'http://www.assumptionu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2SF3oec3tVD9MNnz20SPInuw-ziyu7PJjmQ4g5tMAWbJgWKGperfkczQ',
    id: 2022,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'athabascau.ca',
    name: 'Athabasca University',
    web_page: 'http://www.athabascau.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAnK9qCXvqDx3Fp1uG-lEPqdAg3Blnb-jDuwiBCbzrxYmKzitGu51H9Q',
    id: 2023,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'augustana.ab.ca',
    name: 'Augustana University College',
    web_page: 'http://www.augustana.ab.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ0BCgtifwbOsyB5xIUbP2Aj1QCVj9-YhFJhN5KwpPBsonUwCKkxBtQ1M',
    id: 2024,
    acronym: 'AUC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'bcit.ca',
    name: 'British Columbia Institute of Technology',
    web_page: 'http://www.bcit.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwmULWlVTfLWColh4M-CcyAJk_RaIvursUl1h0Faw4e3I2gh4Tpm7MjFw',
    id: 2025,
    acronym: 'BCIT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'bcou.ca',
    name: 'British Columbia Open University',
    web_page: 'http://www.bcou.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCG7YufWA28jCgry6smMCTH72R6vMujXIybIX-HtrLsIBOhA_6PRehVN4',
    id: 2026,
    acronym: 'BCOU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'boothcollege.ca',
    name: 'William and Catherine Booth College',
    web_page: 'http://www.boothcollege.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj8z9BvS3KuI1E4Gg4TVGhUGA3R_WW3HCkseieaTKJcGyZqLy2eQf6KA',
    id: 2027,
    acronym: 'WCBC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'borealc.on.ca',
    name: 'Collège Boréal',
    web_page: 'http://www.borealc.on.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIvEKaZcIci5KuOJPH5vU1vxIJGTI8uLm0MGx_UcG8pgwEogU1JJGCQw',
    id: 2028,
    acronym: 'CB',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'bowvalleycollege.ca',
    name: 'Bow Valley College',
    web_page: 'http://www.bowvalleycollege.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpIKB255GXhTmPSYyZIuHEwhE4TjBGNLdtCllw4Ed27MRyqVPmsbYQSCE',
    id: 2029,
    acronym: 'BVC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'brandonu.ca',
    name: 'Brandon University',
    web_page: 'http://www.brandonu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNyC1UMpckEzHnXzvocaYIlfGGdCaNOEr3bmRP8Onz2NKhiLcpVGJBXQ',
    id: 2030,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'brocku.ca',
    name: 'Brock University',
    web_page: 'http://www.brocku.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjIsjJ3ZyMFC8f9_1P778GCBAKBmmW-4hOAucIoW8wx3rJ9Q9XYCYn2A',
    id: 2031,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'camosun.bc.ca',
    name: 'Camosun College',
    web_page: 'http://www.camosun.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ6_N6tXGFF5Ag_-9mD7d8QQo5vFCCsT7OvJlreRq2y7nS4KBPN05Y0g',
    id: 2033,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'capcollege.bc.ca',
    name: 'Capilano College',
    web_page: 'http://www.capcollege.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsYuYN76Umxj_XE7HIhUgtT_nxr5paIROauvFHtgNK9EWlOGmMD5xPMg',
    id: 2034,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'cariboo.bc.ca',
    name: 'University College of the Cariboo',
    web_page: 'http://www.cariboo.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzMqefcrB1pRBMwfnApEANMLVBkKqQVfL2qH50mXgHqicjBD0HzksT11Y',
    id: 2035,
    acronym: 'UCC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'carleton.ca',
    name: 'Carleton University',
    web_page: 'http://www.carleton.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIjYLA7cDcwL7snKmYZLMDzelhAu0S_I0Fu4wy91vBZhBp95oT7pz6QeA',
    id: 2036,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ccbc.ca',
    name: 'Canadian College of Business & Computers',
    web_page: 'http://www.ccbc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUUl1IguvX0wAsB4UT5cFA_yr6lxXqjMqXpz-ZNmXNMFEbgTUMcA8z6w',
    id: 2037,
    acronym: 'CCB&C',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'centennialcollege.ca',
    name: 'Centennial College',
    web_page: 'http://www.centennialcollege.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR52htAMWODbJj1_v1Q2UBRnrQPVuEukeMUDF-bs3ExjLwjXky_p0baLp4',
    id: 2038,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'cmu.ca',
    name: 'Canadian Mennonite University',
    web_page: 'http://www.cmu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_sgNxkJWA4K48-UXEAk9OZdOxBkHygWfYGZrxcJ1iHqJhD4Pph5aq3g',
    id: 2039,
    acronym: 'CMU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'cnc.bc.ca',
    name: 'College of New Caledonia',
    web_page: 'http://www.cnc.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPlzbmEeImKab0FRaiH35GOpUbBrkEsM1XuiUBezmG5_KgMu2qoZdTrus',
    id: 2040,
    acronym: 'CNC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'columbiacollege.ca',
    name: 'Columbia College',
    web_page: 'http://www.columbiacollege.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjmMErg_EVFMAxh57nR5urAx1NSiXCBTyaJYLrjnhNdtKm6iq3-ZvdyA4',
    id: 2041,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'concordia.ab.ca',
    name: 'Concordia University College of Alberta',
    web_page: 'http://www.concordia.ab.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG5dysE5oHtq2fqxjjmDwD1FVqZWXEctcy_woz4JHWw_BI2IkFk2gZoA',
    id: 2042,
    acronym: 'CUCA',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'concordia.ca',
    name: 'Concordia University',
    web_page: 'http://www.concordia.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPTjBwhZx_9EGugiVupk0p7E3IJC-7MngCIUpAuRVLPlTJvD7zmdeVDVc',
    id: 2043,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'conestogac.on.ca',
    name: 'Conestoga College',
    web_page: 'http://www.conestogac.on.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSH-oGHUwS1_zMwoenCc2yyG5GbhhYcOzErUqzwEwartzC7gec11mSBA',
    id: 2044,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'cotr.bc.ca',
    name: 'College of the Rockies',
    web_page: 'http://www.cotr.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRikzrXT7RSf2-0APGpGN3s_3qfJr6Ybl97O_dT5kT3bd0hQagzYrB2GG8',
    id: 2045,
    acronym: 'CR',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'cus.ca',
    name: 'University of Moncton Shippagan',
    web_page: 'http://www.cus.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbbFyDMWWULgHFk9ac3Wgo1hDm3-OTFmB2TMJvTc-ekrXROJrNq_Qqlhkn',
    id: 2046,
    acronym: 'UMS',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'cuslm.ca',
    name: 'University of Moncton Edmundston',
    web_page: 'http://www.cuslm.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxaYWBRbfoePgnIQZQQkrNWX0obo9g75LD7dXPBrXGUSP5V19gaqZeNKE',
    id: 2047,
    acronym: 'UME',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'dal.ca',
    name: 'Dalhousie University',
    web_page: 'http://www.dal.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLLQpSgeUcZ_QgppVCsE8IZZBmlGcp4TwBekR8AcwMYBHUX38dYfop_Q',
    id: 2048,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'douglas.bc.ca',
    name: 'Douglas College',
    web_page: 'http://www.douglas.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjTMnnTkaJmg3IUWmse7j9Tt0OxrOH3NjsHxw37oYpjIhXxRIMY42nEw',
    id: 2049,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'durhamc.on.ca',
    name: 'Durham College',
    web_page: 'http://www.durhamc.on.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR79Ljcwu-QAtV3tYbbqJYkBtd0tVLcCcP_9vc4NEuFsU-kdzCkYE9AQg',
    id: 2050,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'eciad.bc.ca',
    name: 'Emily Carr Institute of Art + Design',
    web_page: 'http://www.eciad.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpUvLZ0ZfDrKycFfeQdN7xLbyPRpJQYMtMJimIECt01jzBG0Omb6oJ0o8',
    id: 2051,
    acronym: 'ECIA+D',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'enap.uquebec.ca',
    name: "École nationale d'administration publique Université du Québec",
    web_page: 'http://www.enap.uquebec.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiWApuHe5dtZeF6583bywkPXimt5bHataZgV3wHkD0w7TaCY2zTlc3mZjd',
    id: 2052,
    acronym: 'ÉNDPUDQ',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'etsmtl.ca',
    name: 'École de technologie supérieure Université du Québec',
    web_page: 'http://www.etsmtl.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYakTQa4oMpckRDGI28veaLPwTMAdzGHSnNfClUiMaHTNIz7CHWvmMQoE',
    id: 2053,
    acronym: 'ÉDTSUDQ',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'firstnationsuniversity.ca',
    name: 'First Nations University of Canada',
    web_page: 'http://www.firstnationsuniversity.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrBy1LWMJ_gp1FDnO-clXSCuiI7m_d8PojXS8UtQPnvvvjK0QPff-k5xs',
    id: 2054,
    acronym: 'FNUC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'gbrownc.on.ca',
    name: 'George Brown College',
    web_page: 'http://www.gbrownc.on.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAIafFFStdX5SX-AhF5Pxz9Db9hF8znQV_Frgaba_jV8PPMh8FAkgZo1E',
    id: 2055,
    acronym: 'GBC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'hec.ca',
    name: 'École des Hautes Études Commerciales',
    web_page: 'http://www.hec.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc4NhjIcosGETzo2klRFOb7UTnbhyosC-FVPZkZrHyZ-QqO44Sdtc9b5M',
    id: 2056,
    acronym: 'ÉDHÉC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'humber.ca',
    name: 'Humber College',
    web_page: 'http://www.humber.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6UYZ4PC_ig7TlwB-qFJLN-C4kON--LeTbRHNoHRcuWq6iwmPIszbhFgc',
    id: 2057,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'huronuc.on.ca',
    name: 'Huron University College',
    web_page: 'http://www.huronuc.on.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk-N1Wl1fGOD9qdQTcf4HqIDEODkhKEyCv4_Bnw2OhdmE9vvgWXy2VfNY',
    id: 2058,
    acronym: 'HUC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'iaf.inrs.ca',
    name: 'Institut Armand-Frappier Université du Québec',
    web_page: 'http://www.iaf.inrs.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRp-VPweA1UbxMPB-9Ew_-hrkSBGkZWABbdyr9wyA76WVxASeACRQLbWg',
    id: 2059,
    acronym: 'IAUDQ',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'inrs.uquebec.ca',
    name: 'Institut National de la Recherche Scientifique Université du Québec',
    web_page: 'http://www.inrs.uquebec.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw0fYgiWTX0KMR9NUGuEx5e_zGJPQ1tmPpj55rdyWpmzFIJqJSMBU2rg',
    id: 2060,
    acronym: 'INDLRSUDQ',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'kingston.edu',
    name: 'Kingston College',
    web_page: 'http://www.kingston.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQFj0yU-n8rahw-avuCV7Bxa6MUqQXSi9e9rzz9vAkithM2FvyOiR6mQ',
    id: 2061,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'kingsu.ab.ca',
    name: "King's University College",
    web_page: 'http://www.kingsu.ab.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUofUKky-2PxqLzjBls3WAyNnr0RDyzzPamlyKoWoBYjsw9U-vMf1WgtA',
    id: 2062,
    acronym: 'KUC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'kwantlen.bc.ca',
    name: 'Kwantleen University College',
    web_page: 'http://www.kwantlen.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlzCR6THlkCffUfi2ml1foclW_4RQ2iuPLkHLdrF_Rj4Tbw9IvDMxiJRc',
    id: 2063,
    acronym: 'KUC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'lakeheadu.ca',
    name: 'Lakehead University',
    web_page: 'http://www.lakeheadu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHpJGWru0gtO0i5cnvA0rdE3xxFvH8lITfk3VDdCt_WxTIw9TKER7VMw',
    id: 2064,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'langara.bc.ca',
    name: 'Langara College',
    web_page: 'http://www.langara.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQas9ErsMm11_qJrs60JU_YYI7QwLrD0oSqAsa5rPWOQ36_vwgoEMSuhQ',
    id: 2065,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'lansbridge.com',
    name: 'Lansbridge University',
    web_page: 'http://www.lansbridge.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNosN5NrJXlJy7g0LlX1Sj_EpPeb04qECeMD64fKdtnOWW2UIJ0K-FMgc',
    id: 2066,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'laurentian.ca',
    name: 'Laurentian University of Sudbury',
    web_page: 'http://www.laurentian.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdjYXC3t1b-6y3jkubMoR2RYEhQ6kURqQ1hE-XyoQh63szvkeEGUW-IA',
    id: 2067,
    acronym: 'LUS',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'macewan.ca',
    name: 'MacEwan University',
    web_page: 'http://www.macewan.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvkTE7iblBmQOq-PYE9fw-SxK_Z-ocDg5i2mVuwmA29qX6cKIrGUSPetw',
    id: 2068,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'mala.bc.ca',
    name: 'Malaspina University College',
    web_page: 'http://www.mala.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSktcY0GFvw9DZGRowP144VX1ytYGZLj-Gdb56osUeiEzBBmuddLrxmLej9',
    id: 2069,
    acronym: 'MUC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'mcgill.ca',
    name: 'McGill University',
    web_page: 'http://www.mcgill.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFlSp_bUrmjZQTUAZyvVcKtKX7LqtihzMpsPMSGPDQZ1e7ADO8-iLoTg',
    id: 2070,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'mcmaster.ca',
    name: 'McMaster University',
    web_page: 'http://www.mcmaster.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTCfzTC2IgQdw-4nJh8z_Ppm3FdEXgRPsP0jWytblTG2NIAoOsDq6qwEo',
    id: 2071,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'msvu.ca',
    name: 'Mount Saint Vincent University',
    web_page: 'http://www.msvu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh0yNfrH6hAaJeLvM5LMkyNlrrQFS8cfctAYYmIZehTC5okWzRKR_Eykw',
    id: 2072,
    acronym: 'MSVU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'mta.ca',
    name: 'Mount Allison University',
    web_page: 'http://www.mta.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU0zrrr4lR1p_MtTuiR2Fvqt86DeU3hOqKymmm68P82Kam4A2NL4amUBw',
    id: 2073,
    acronym: 'MAU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'mtroyal.ab.ca',
    name: 'Mount Royal College',
    web_page: 'http://www.mtroyal.ab.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSysqLZtx0CzQnP0BvhuBLf7xBj3_ZG-1eepg3VKCg3mXd48v27mvdsSLn3',
    id: 2074,
    acronym: 'MRC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'nait.ca',
    name: 'Northern Alberta Institute of Technology',
    web_page: 'http://www.nait.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFVtav4X-1--ePasZIaxWMNvvoXdbzk6GDE0roavsyGEDIhx3EQ2XTc-g',
    id: 2075,
    acronym: 'NAIT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'nic.bc.ca',
    name: 'North Island College',
    web_page: 'http://www.nic.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS40TlLCZ2QCPLJ08NUikHPIF5qf7d06BfbCnHjWN6ONU92YWncTsXy4sY',
    id: 2076,
    acronym: 'NIC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'nipissingu.ca',
    name: 'Nipissing University',
    web_page: 'http://www.nipissingu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOdRhsTv3EoVMKVEOlaNTQeV5p5dpKaT8xq-Y91Ypey8wKbkK6ZMs0Rg',
    id: 2077,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'nlc.bc.ca',
    name: 'Northern Lights College',
    web_page: 'http://www.nlc.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrglavAn0XY_wrX_akG0cPfqc4rYzNDzmPDH1AwCkXG2v2dOKcPq0GYA',
    id: 2078,
    acronym: 'NLC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'nsac.ns.ca',
    name: 'Nova Scotia Agricultural College',
    web_page: 'http://www.nsac.ns.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpwlHdQ8OumVTLuQncukIF6N_nTN6gN4i2seiYjHuT95oVZ9f3dVqBYVUE',
    id: 2079,
    acronym: 'NSAC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'nscad.ns.ca',
    name: 'Nova Scotia College of Art and Design',
    web_page: 'http://www.nscad.ns.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGAH8mKaoEq8gMgjFuSQERrPLRvw_9LCctLx4il4_prAGGt2AKwtJi6TbL',
    id: 2080,
    acronym: 'NSCAD',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'nvit.bc.ca',
    name: 'Nicola Valley Institute of Technology',
    web_page: 'http://www.nvit.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVAvUEjywzexAeDPQ0AB1tpYvAQYjpSRptaWxlqGZYOSCtCv__YeR8TObb',
    id: 2081,
    acronym: 'NVIT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ocad.ca',
    name: 'Ontario College of Art and Design',
    web_page: 'http://www.ocad.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXO0WNO2exSvLrBk3BW7qhcjOe60areyjOPSnJsIfXN_l_Cp54UTJE60tD',
    id: 2082,
    acronym: 'OCAD',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'okanagan.bc.ca',
    name: 'Okanagan University College',
    web_page: 'http://www.okanagan.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2uQ2LokID839iPxnnGroQs0Viv3idfrWfR4NWxFiJZajV56uMxpYdQkA',
    id: 2083,
    acronym: 'OUC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'op.org',
    name: 'Dominican College of Philosophy and Theology',
    web_page: 'http://www.op.org/canada/college.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTx8nnHfDU_gCcID6yH-LtRvsaTWxvMWJXsnn13XQekcZHzNw9EGkCY9Q',
    id: 2084,
    acronym: 'DCPT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'picollege.ca',
    name: 'Pacific International College',
    web_page: 'http://www.picollege.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-1xxIZC09LqOrQv0ZGXmHte5YbueExQkOCsgqIoQsL_j8uiVYYM17ww',
    id: 2085,
    acronym: 'PIC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'polymtl.ca',
    name: 'École Polytechnique de Montréal Université de Montréal',
    web_page: 'http://www.polymtl.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3nUSfoFfn8zNzMYNHtsM5o3YP48glkZFznYlTW78gm3s8s2Wi682OKy4',
    id: 2086,
    acronym: 'ÉPDMUDM',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'queensu.ca',
    name: "Queen's University",
    web_page: 'http://www.queensu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkqdhD64a3t5zdxQzVn2hnIXSFKZ9xZDviFoo4cH6d73npIjsE89B8eCg',
    id: 2087,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'questu.ca',
    name: 'Quest University',
    web_page: 'http://www.questu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVD9o01zQUCPViie-uvgZt6IdMmkQLxwdSc5jq4v1Vya8O7SCx2IAO7qAh',
    id: 2088,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'redeemer.ca',
    name: 'Redeemer College',
    web_page: 'http://www.redeemer.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCvzVF1Plf7HJuDrwj2VjeBh07AT73QKz11HQQ0xL1lIZJWETwnA4Epg',
    id: 2089,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'regent-college.edu',
    name: 'Regent College',
    web_page: 'http://www.regent-college.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf5DCtc7vhyghfwgG0XjDsBUrrsfh5-SZPi7nKXi8tbAsu1OTsdtrkdg',
    id: 2090,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'rmc.ca',
    name: 'Royal Military College of Canada',
    web_page: 'http://www.rmc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWTPbrhc2xkmR633TG8hzI8NoInMvcbVgEbTveZ6dMR98sUHgyMQwWD8s',
    id: 2091,
    acronym: 'RMCC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'royalroads.ca',
    name: 'Royal Roads University',
    web_page: 'http://www.royalroads.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrZCqJthjx0Vll5t-FuGq5hYPYaCtmdIc9funpSXgQQijZklHtlnbXRtI',
    id: 2092,
    acronym: 'RRU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ryerson.ca',
    name: 'Ryerson Polytechnic University',
    web_page: 'http://www.ryerson.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTeVU_KwWoUMD_42GMX_BoDGDf1NFlIKuWQ3c6WBpl2LtLCXmsNNyvNg',
    id: 2093,
    acronym: 'RPU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'sait.ca',
    name: 'Southern Alberta Institute of Technology',
    web_page: 'http://www.sait.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnZdF8MbmKJVUyc0tamth6vvZceP-_xqBynd1MkqlJcA3-3f422S6H-wxZ',
    id: 2094,
    acronym: 'SAIT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'sauder.ubc.ca',
    name: 'Sauder School of Business',
    web_page: 'http://www.sauder.ubc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEFFAERIiR1PA3PXrBYY400IvsKYadyjgjy9aO7PNqEHlIcAP7BsP7m9g',
    id: 2096,
    acronym: 'SSB',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'scar.utoronto.ca',
    name: 'University of Toronto Scarborough',
    web_page: 'http://www.scar.utoronto.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoUzZEILrqHd3xbM-A4LnkRYtPNTQrjOQvDKLK4X_iezG8jul3S41N86U',
    id: 2097,
    acronym: 'UTS',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'selkirk.bc.ca',
    name: 'Selkirk College',
    web_page: 'http://www.selkirk.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpeM9mJp3o19TXnsO35A9Dj-sMrX9UGckSBR7lZC0eHyhltXVovTg6bIk',
    id: 2098,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'senecacollege.ca',
    name: 'Seneca College',
    web_page: 'http://www.senecacollege.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ2LJ_NX7RMye5zMOdN4ZKw5hktVZAz-ZKlsdJcrwIm2tp3Oe9nwX98oc',
    id: 2099,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'sfu.ca',
    name: 'Simon Fraser University',
    web_page: 'http://www.sfu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWZp2BwvwyW8mUeCIjJOlpD7nREx-FLde8kHdhWAPFG5PvEBBtvQcmpC4',
    id: 2100,
    acronym: 'SFU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'sheridancollege.ca',
    name: 'Sheridan College',
    web_page: 'http://www.sheridancollege.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaQRSM71Caxx9Bcgl0ICNF8nD2sryltxDyPnsugQFVbi1SZqL9m3Ev9LA',
    id: 2101,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'sifc.edu',
    name: 'Saskatchewan Indian Federated College',
    web_page: 'http://www.sifc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZgYllRRblsbAh82SWJ1POMxsbofGMg1pzZMukpNzXy0WeSlW6Cl0qyc4',
    id: 2102,
    acronym: 'SIFC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'stclairc.on.ca',
    name: 'St. Clair College',
    web_page: 'http://www.stclairc.on.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6Bc23xuKLiihAuls5clKoFzh4VqMdXtvT1TCDKIk7hFcBV52htLbXPggD',
    id: 2103,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'stfx.ca',
    name: 'St. Francis Xavier University',
    web_page: 'http://www.stfx.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh6Estp5QGag-Ynx8D0bfz_wYvA71UpIusj86qX1n_dY6J9Y-wX34BPNk',
    id: 2104,
    acronym: 'SFXU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'stmarys.ca',
    name: "St. Mary's University",
    web_page: 'http://www.stmarys.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSanMjm8v3XlfEeaq8XU8ZrvmhEQvuiL_eDoW4lMZ7gUxDURtI-16dG9Q',
    id: 2105,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'stthomasu.ca',
    name: 'St. Thomas University',
    web_page: 'http://www.stthomasu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHwJ2UVs8YaV6ZwT4NK8bMgVbCgs5o-fMGI4HEI0HygbAZ9KPjdiVBIg',
    id: 2106,
    acronym: 'STU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'teluq.uquebec.ca',
    name: 'Télé-université Université du Québec',
    web_page: 'http://www.teluq.uquebec.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCQtwZC95lHTbLxkfSrpXn-1394wT4djhtFyBHri5_4--OHuk4C_u3YZ0',
    id: 2107,
    acronym: 'TUDQ',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'trentu.ca',
    name: 'Trent University',
    web_page: 'http://www.trentu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRmAcYmuWsvEOCJKagk5pBiWJtdTiQcJkuHiEqTOcF41PeAMhbW-xzNA',
    id: 2108,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'trinity.utoronto.ca',
    name: 'University of Trinity College',
    web_page: 'http://www.trinity.utoronto.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLZkccSge6PSoQyzjueRx8lIHMK6dewtnDjI7A2IzaqAYSgdUI2KSklKs',
    id: 2109,
    acronym: 'UTC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'tru.ca',
    name: 'Thompson Rivers University',
    web_page: 'http://www.tru.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8ScTSZc_QmCI5g463zIlYN7yyVxIz2WjVDk-EH9o8AOWIxo_h7V1ysw',
    id: 2110,
    acronym: 'TRU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'twu.ca',
    name: 'Trinity Western University',
    web_page: 'http://www.twu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9YyoMUWZGlWDuK8vSi3hzfLelGbO111dsb7kq_-P-FLT9bYMgi09CsPs',
    id: 2111,
    acronym: 'TWU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ualberta.ca',
    name: 'University of Alberta',
    web_page: 'http://www.ualberta.ca/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcScQMnbLOKYFfQQ4Hkgcb1522ET5ZEnNdDkMyVkScI4OC0sZ5qk-foJxVEI',
    id: 2112,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ubc.ca',
    name: 'University of British Columbia',
    web_page: 'http://www.ubc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfrJn4x_0pN6BTbKt-OiHeDCtaH8XGLa6QzDe3WIhVECY6UDjWmRcIFQ',
    id: 2113,
    acronym: 'UBC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ubishops.ca',
    name: "Bishop's University",
    web_page: 'http://www.ubishops.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlMxizM0l_UfdpsOKrbRA7Kqvy9a84kEE34BEFBIXjUYO_B26_D82QPw',
    id: 2114,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ucalgary.ca',
    name: 'University of Calgary',
    web_page: 'http://www.ucalgary.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVqTS2Ky2MdRoxq03g47R-bu3RlM0-onzYb6FXkf9i3kDo2nA7GzZAUBg',
    id: 2115,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uccb.ns.ca',
    name: 'University College of Cape Breton',
    web_page: 'http://www.uccb.ns.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7ys3ZsNKG_EeSws-drd2K-IKVAlW1f1uQHQNuWXFaI5BwcLih-wla-w',
    id: 2116,
    acronym: 'UCCB',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ucfv.bc.ca',
    name: 'University College of the Fraser Valley',
    web_page: 'http://www.ucfv.bc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHbApE9htxrWg4Hf3tpwfZO7UdrIhqRFHUZKTlkjih7g9PiU4ndjvF88fi',
    id: 2117,
    acronym: 'UCFV',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ucs.mun.ca',
    name: 'Memorial University of Newfoundland',
    web_page: 'http://www.ucs.mun.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-08SlBQaxidKkZl7NoGaek2zb4uFmmmSivor0HDx8IoiVgETp_go9SY0',
    id: 2118,
    acronym: 'MUN',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ukings.ns.ca',
    name: "University of King's College",
    web_page: 'http://www.ukings.ns.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWb9KQc1deE1jjV2q3Wrfyb9bcM6r4zL23alZbe3CTzptG_1SWV_GjIA',
    id: 2119,
    acronym: 'UKC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ulaval.ca',
    name: 'Université Laval',
    web_page: 'http://www.ulaval.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdpTkCK5uRaHV4paERyQ-VGoAo2wb54aR0cHPNTOGYGQp1uyrkfiuYbw',
    id: 2120,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uleth.ca',
    name: 'University of Lethbridge',
    web_page: 'http://www.uleth.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVYWJFRTx9q_MwaO70P7ZvibhMOf4TIb9ESUKLR9mJ1pahn0wRsP6EzqE',
    id: 2121,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'umanitoba.ca',
    name: 'University of Manitoba',
    web_page: 'http://www.umanitoba.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnWJXS08_JWC0Fz7OlG6YP7nFDUMk36UkyXwuodfCo4gY77wo49nFxLq8',
    id: 2122,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'umoncton.ca',
    name: 'University of Moncton',
    web_page: 'http://www.umoncton.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNb9U4qV_s2ya68cqyL3fDhw2ePhCBbFfRBTD9FORXVVGlpbvaG0tP12RR',
    id: 2123,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'umontreal.ca',
    name: 'Université de Montréal',
    web_page: 'http://www.umontreal.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3nUSfoFfn8zNzMYNHtsM5o3YP48glkZFznYlTW78gm3s8s2Wi682OKy4',
    id: 2124,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'unb.ca',
    name: 'University of New Brunswick',
    web_page: 'http://www.unb.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8p-G3QOEIx-nk_lg7pK1pdr1zoYT_eu0SJjf0lNKhCxqpv1s-xBgbnw',
    id: 2125,
    acronym: 'UNB',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'unbc.ca',
    name: 'University of Northern British Columbia',
    web_page: 'http://www.unbc.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnBSqI24wbTwynlINyDFffKsQqNCnuVpUxO6S9G9iGUs39pISr_DHHLU36',
    id: 2126,
    acronym: 'UNBC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'unbsj.ca',
    name: 'University of New Brunswick Saint John',
    web_page: 'http://www.unbsj.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3FG5Xmhs6q12O7by2I58ff37BtEM_a1rogSZKk_2zpELCsKNYKuObeW5O',
    id: 2127,
    acronym: 'UNBSJ',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'universitycanadawest.ca',
    name: 'University Canada West',
    web_page: 'http://www.universitycanadawest.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoRrFiG44NvHfWYj1SFmcNdSqQPeNQSr4JKVoVw42gbgOgVayHjRYq_a0',
    id: 2128,
    acronym: 'UCW',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uoguelph.ca',
    name: 'University of Guelph',
    web_page: 'http://www.uoguelph.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPFZ3Hy4Ig11ARJhaH0NuZAocouyRTDZzmndDF1M0khf-wdjaJN-bG9No',
    id: 2129,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uoit.ca',
    name: 'University of Ontario Institute of Technology',
    web_page: 'http://www.uoit.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMzLq7rljYFuVRzroZr4jmYQYDGJUlyO0QHCXYegz8MKihjnqXfLk4mA',
    id: 2130,
    acronym: 'UOIT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uottawa.ca',
    name: 'University of Ottawa',
    web_page: 'http://www.uottawa.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRg3cxMFHkYWjPXlxtXpTmBQg7PMhwehzvWvST2XUxgmTSDjrEYVwEhRW_d',
    id: 2131,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'upei.ca',
    name: 'University of Prince Edward Island',
    web_page: 'http://www.upei.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0qG6HKMLKf_plC1MRvE7VVKPCmngoLD8B6JtVsEEhB5eJ3qg_FkKfBYQ',
    id: 2132,
    acronym: 'UPEI',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uqac.ca',
    name: 'Université du Québec à Chicoutimi',
    web_page: 'http://www.uqac.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsuHWbAyVeGvIluQftnX3PVpydPc8si3qAvEzpgE6YEgkLI9wB_QXMCw',
    id: 2133,
    acronym: 'UDQÀC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uqam.ca',
    name: 'Université du Québec à Montréal',
    web_page: 'http://www.uqam.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm956sc9f-8xArrP8eqnvDLsoUZqMrwz98T-BZKZUizPCqvXZf6GFJVqvo',
    id: 2134,
    acronym: 'UDQÀM',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uqar.uquebec.ca',
    name: 'Université du Québec à Rimouski',
    web_page: 'http://www.uqar.uquebec.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvQSd59ROQg6h-LA9jfSOz53ATMXlFPX1jArG8rVvLvk0cziJ8kW5czvQ',
    id: 2135,
    acronym: 'UDQÀR',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uqat.uquebec.ca',
    name: 'Université du Québec en Abitibi-Témiscamingue',
    web_page: 'http://www.uqat.uquebec.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL7Q9WgZydxiI-AUg59x2wC1UezYm3HcdHSOpfKr9WRpOaXin0VUqh6jni',
    id: 2136,
    acronym: 'UDQEA',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uqo.ca',
    name: 'Université du Québec en Outaouais',
    web_page: 'http://www.uqo.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsVz56ll5mbW7Byp4KWKK92k-GEaXyy2VQGbxOmSGLwDp3f5gi4aHvHtA',
    id: 2137,
    acronym: 'UDQEO',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uqtr.uquebec.ca',
    name: 'Université du Québec à Trois-Rivières',
    web_page: 'http://www.uqtr.uquebec.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt33M3F_mRZwkHRAugxJP8S4SpwMfcsshDyW0PrwgXERniBJhpRILjizk',
    id: 2138,
    acronym: 'UDQÀT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uquebec.ca',
    name: 'University of Québec',
    web_page: 'http://www.uquebec.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIbke_0BYq6p7EWFUckC35GGA3BuaRTO8s0L_BqpvwIzSWRUMPwm5NtB0',
    id: 2139,
    acronym: 'UQ',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uregina.ca',
    name: 'Campion College',
    web_page: 'http://www.uregina.ca/campion/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwMm-ro-RzC9gCu047wbM0vpfqVNZPtP2CKDXxiIrHzVMLrH0NfimvaMJe',
    id: 2140,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uregina.ca',
    name: 'University of Regina',
    web_page: 'http://www.uregina.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy38MTzFzvAQN7lAu9-EQKEk5kAO-Il6cH7CgxDhgwMn8WIdGA9_spbnSe',
    id: 2141,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'usask.ca',
    name: 'University of Saskatchewan',
    web_page: 'http://www.usask.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS8b8_MZ4_SneUmEWHwtGU9pfLrjBjO_NGf5mSLrenp1UDxC-AbHp1DKBB',
    id: 2142,
    acronym: 'US',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'usherb.ca',
    name: 'Université de Sherbrooke',
    web_page: 'http://www.usherb.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWjKJHtwsuKrJSafYBU7NBW79ZVcGtVhL3Q24oIjm-DxVNmLOazdEGGOI1',
    id: 2143,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'usjc.uwaterloo.ca',
    name: "University of St. Jerome's College",
    web_page: 'http://www.usjc.uwaterloo.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUYD72kmT15Cb4Ie5BF0Y1_tSiPlUdNP-CIfmxxFVpHeQwSvID9Zheyw',
    id: 2144,
    acronym: 'USJC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ustanne.ednet.ns.ca',
    name: 'St. Anne University',
    web_page: 'http://www.ustanne.ednet.ns.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfpiSJjaxN6Byn1BbVmmhwSOQ9dxULTjc6jeUfXMVO_SX4pnZx4rWgYsA',
    id: 2145,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ustboniface.mb.ca',
    name: 'University College of Saint-Boniface',
    web_page: 'http://www.ustboniface.mb.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb5DX4AjAENG2qJoe2DZayOiU1FVKb-fMlGsfO_DmRrE4wRVfZtHDS2WA',
    id: 2146,
    acronym: 'UCS',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'ustpaul.ca',
    name: 'St. Paul University',
    web_page: 'http://www.ustpaul.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlcT9ZZtpgWDtwGD8MdtTmQKpAZRmr1buG59NBvh3HjM_AsHKzqyR3Fw',
    id: 2147,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'usudbury.com',
    name: 'University of Sudbury',
    web_page: 'http://www.usudbury.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdjYXC3t1b-6y3jkubMoR2RYEhQ6kURqQ1hE-XyoQh63szvkeEGUW-IA',
    id: 2148,
    acronym: 'US',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'utm.utoronto.ca',
    name: 'University of Toronto Mississauga',
    web_page: 'http://www.utm.utoronto.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0E4zJfW0E7x4VqWFVY5GfjHyIvFQ5lrqa81Wjh3cQwHHOKIxLi2eUvhJh',
    id: 2149,
    acronym: 'UTM',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'utoronto.ca',
    name: "University of St. Michael's College",
    web_page: 'http://www.utoronto.ca/stmikes/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJq4lxzn_c8mavjQzSgPLalFmCGfzOP19WgMZMaKJsCTcbR2gAhgFORw',
    id: 2150,
    acronym: 'USMC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'utoronto.ca',
    name: 'University of Toronto',
    web_page: 'http://www.utoronto.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBqdC0UnMNAGFbAjmcNLrt2AcOQXGNsgIKVv43XuzIVuvRQFu3zZL5Zg',
    id: 2151,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uvic.ca',
    name: 'University of Victoria',
    web_page: 'http://www.uvic.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVoiMTsN05ArSeIjq8bjJMMUbp-sQTR7FVebqe6jZNIIkbj4iEcsec9w',
    id: 2152,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uwaterloo.ca',
    name: 'University of Waterloo',
    web_page: 'http://www.uwaterloo.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX9_BxENfpSxmSIfllYDVrMukNBhlSgfdWH4chYrxMks9qDGWyOCXFYGA',
    id: 2153,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uwindsor.ca',
    name: 'University of Windsor',
    web_page: 'http://www.uwindsor.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwabcPrQj-mtLpUY_k0zoSDmK8pzr6Upk5Nm5LYWDB4AEH540qWLCxgxY',
    id: 2154,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uwinnipeg.ca',
    name: 'University of Winnipeg',
    web_page: 'http://www.uwinnipeg.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEyV583DKX53hWtd8VpwuVkWCg1xYklU0rfXQi00m0SagvCDmlRhRmMokU',
    id: 2155,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uwo.ca',
    name: 'Brescia College',
    web_page: 'http://www.uwo.ca/brescia/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWR1xfInRzLm9UiKH66_3Y830rySb1GdGZQ7pVFwehgmC_5UwcWY-Cag',
    id: 2156,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'uwo.ca',
    name: 'University of Western Ontario',
    web_page: 'http://www.uwo.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRf1uJvsv2iewax1LqR6o3gMkRAG4UE61AICh2TaAnHzDrh-buVMoWkk3A',
    id: 2158,
    acronym: 'UWO',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'vcc.ca',
    name: 'Vancouver Community College',
    web_page: 'http://www.vcc.ca/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSmJof0vlkznCZngir6zjJyr4KF7el8kVSZuE1RQelp0LD70fWrFRAecw',
    id: 2159,
    acronym: 'VCC',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'vicu.utoronto.ca',
    name: 'Victoria University Toronto University of Toronto',
    web_page: 'http://vicu.utoronto.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBOFmZGSe6sAsLkiaFIRsTCN28j1l6WLLBsTtjg7RZdk8si-MXk5KoI1w',
    id: 2160,
    acronym: 'VUTUT',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'wlu.ca',
    name: 'Wilfrid Laurier University',
    web_page: 'http://www.wlu.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5ioTxCbFJhgo1U4C096SdtR03l6MFFX9EeZgI9IQQgtpgreCiAYpiaQ',
    id: 2161,
    acronym: 'WLU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'yorku.ca',
    name: 'York University',
    web_page: 'http://www.yorku.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeTOx3wpWlVIav8UQDHFEWjRt6RlsEFZRxv3tMfgnov4ORLR8Frw0zOW8',
    id: 2162,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'CA',
    country: 'Canada',
    domain: 'yukoncollege.yk.ca',
    name: 'Yukon College',
    web_page: 'http://www.yukoncollege.yk.ca/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT42QX3dVxjwk4QsgSbQfIYIcJEm-D427lbPIwmxI8HjEcQD5_XsJEsVHw',
    id: 2163,
    acronym: 'YC',
  },
  {
    alpha_two_code: 'CV',
    country: 'Cape Verde',
    domain: 'cv.unipiaget.org',
    name: 'Universidade Jean Piaget de Cabo Verde',
    web_page: 'http://cv.unipiaget.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9QKW2gVribmp484Lp4rOVLr2MHCikqv9HK0A-HxjAsC6JBriRH_qAGt0',
    id: 2164,
    acronym: 'UJPDCV',
  },
  {
    alpha_two_code: 'KY',
    country: 'Cayman Islands',
    domain: 'icci.edu.ky',
    name: 'International College of the Cayman Islands',
    web_page: 'http://www.icci.edu.ky/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZrIqx7VIqCTQxbK8L-1xcLDcRVlsCjJ9PVvom5Begti8w0X1Hnm9Qyg',
    id: 2165,
    acronym: 'ICCI',
  },
  {
    alpha_two_code: 'KY',
    country: 'Cayman Islands',
    domain: 'stmatthews.edu',
    name: 'St. Matthews University',
    web_page: 'http://www.stmatthews.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQQGjqU7luL23OKxh5LNMQdDPx8_4S48zE0OoLUDo7r_Mh52dRCTwADw',
    id: 2166,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'CF',
    country: 'Central African Republic',
    domain: 'univ-bangui.net',
    name: 'Université de Bangui',
    web_page: 'http://www.univ-bangui.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 2167,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'TD',
    country: 'Chad',
    domain: 'univ-ndjamena.org',
    name: "Université de N'Djamena",
    web_page: 'http://www.univ-ndjamena.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpxdenLC58T6Cq84UuyzhQ_hw2DJpCQVfiXiRBOJa-6w0Njw-2r3Axng',
    id: 2168,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'academia.cl',
    name: 'Universidad Academia de Humanismo Cristiano',
    web_page: 'http://www.academia.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpH0y2Bj4bCb5Szh34Ml_AQot30CyVIKtDXtvCGIWmZVGl1O46s-0kVw',
    id: 2169,
    acronym: 'UADHC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'arquitecturaucv.cl',
    name: 'Escuela de Arquitectura y Diseño',
    web_page: 'http://www.arquitecturaucv.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdsO_Y1E4LmVSiUNUr1HYvtDG5snkRH_qxVgUHX9GaBr08s6OqFHuyNQ',
    id: 2170,
    acronym: 'EDAYD',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'puc.cl',
    name: 'Pontificia Universidad Catolica de Chile',
    web_page: 'http://www.puc.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqUqZXJe1XhGcb6pEAnhMpKeNMm9H3Gjno_nglI7A3RFPFZaCE1gWK0wP2hg',
    id: 2171,
    acronym: 'PUCDC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uach.cl',
    name: 'Universidad Austral de Chile',
    web_page: 'http://www.uach.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqf1YIpZ5X_MyVz3az5qyl_M7lSZ-zzmcbdPo0KnKwGTv2Gov3MsQr5Q',
    id: 2172,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uaconcagua.cl',
    name: 'Universidad de Aconcagua',
    web_page: 'http://www.uaconcagua.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdoahK7aSREwjTPS3TqK4Qp8Xje-TwnWUYtp2qyJQDTdRF_femkKeilCEJ',
    id: 2173,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uahurtado.cl',
    name: 'Universidad Alberto Hurtado',
    web_page: 'http://www.uahurtado.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI950IvfKjBaSDdHJzpek9_p4ev8C8p4P78924gfTVG45sRIqwbQIC4A',
    id: 2174,
    acronym: 'UAH',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uai.cl',
    name: 'Universidad Adolfo Ibáñez',
    web_page: 'http://www.uai.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEKqrvqKK6EA9FFESSSBRZ8W5niBTcuOgOnZqYrsMmkfK7ySWuIOIaig',
    id: 2175,
    acronym: 'UAI',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uai.cl',
    name: 'Universidad "Adolfo Ibañez"',
    web_page: 'http://www.uai.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEKqrvqKK6EA9FFESSSBRZ8W5niBTcuOgOnZqYrsMmkfK7ySWuIOIaig',
    id: 2176,
    acronym: 'U"I',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uamericas.cl',
    name: 'Universidad de Las Américas',
    web_page: 'http://www.uamericas.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUmk-dlT-UcZW0NwfrVmXB8EEI9eKAPUoO8Ng-ER4ZIkaA9EWDXJyoVg',
    id: 2177,
    acronym: 'UDLA',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uandes.cl',
    name: 'Universidad de Los Andes',
    web_page: 'http://www.uandes.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf6H1dXrdmPwhttJE9WFbupG6pHenjH5B8Jk4KZyDJT0vzMa_y1VZBML8',
    id: 2178,
    acronym: 'UDLA',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uantof.cl',
    name: 'Universidad de Antofagasta',
    web_page: 'http://www.uantof.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQb0uYPQELS80s6rdH-daS-2TICMREa3g3XtD7goJ_gMy7H10f1G9tccw',
    id: 2179,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uas.cl',
    name: 'Universidad Autonoma del Sur',
    web_page: 'http://www.uas.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZa1GjMRxUxiJiq7hxGD9zGmQfEvnolBl9nwAfb7Xqphm1B6EGTwrx998LhQ',
    id: 2180,
    acronym: 'UADS',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ubiobio.cl',
    name: 'Universidad del Bío-Bío',
    web_page: 'http://www.ubiobio.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeaoDPzy_08OqmIgN84_WIM6tZ9hu5gej29AVliTD50fI7aLh4_0-huA',
    id: 2181,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ubohiggins.cl',
    name: "Universidad Bernardo O'Higgins",
    web_page: 'http://www.ubohiggins.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb0M8OsJElV0WhIylYUkekAAfaycPOlc5gu9Sx6uGUj50Pc9Abd18Q2g',
    id: 2182,
    acronym: 'UBO',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ubolivariana.cl',
    name: 'Universidad Bolivariana',
    web_page: 'http://www.ubolivariana.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDReoU9_HOWMdjGygPQHKDSV_i5dIEnlSsMLvnAbMiLWlIB8sK4fxAa7w',
    id: 2183,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ubritanica.cl',
    name: 'Universidad Chileno Britanica de Cultura',
    web_page: 'http://www.ubritanica.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlv5Lz5lBBnUwtJt4WqF9xFYhj_GXgMgUmIW4rKGMBRAkRfjnwhOQXkA',
    id: 2184,
    acronym: 'UCBDC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ucervantes.cl',
    name: 'Universidad Miguel de Cervantes',
    web_page: 'http://www.ucervantes.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnBmzIArUfQCV3BDM1fiyESzZx5H6QCkNCn68gO4hICba-6g6_SsJaKw',
    id: 2186,
    acronym: 'UMDC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uchile.cl',
    name: 'Universidad de Chile',
    web_page: 'http://www.uchile.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8lwYiDUZgb7tYrzgLYSZkaL6SGUWJ8jyz3yLzkkdVE9fptVqCDKbc_mk',
    id: 2187,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ucinf.cl',
    name: 'Universidad de Ciencias de la Informatica',
    web_page: 'http://www.ucinf.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdGIiEnA5Kvtqu6gI5OXvJSk1ofwSnqu1OOwg0vZUj8tpSxACAN40pVA',
    id: 2188,
    acronym: 'UDCDLI',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ucm.cl',
    name: 'Universidad Catolica del Maule',
    web_page: 'http://www.ucm.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpVrkrFzAwi0jtwEGbojMsjBVZwl2DoHX4jJPNs2F_w7bwHw5M_sPFqycB',
    id: 2189,
    acronym: 'UCDM',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ucn.cl',
    name: 'Universidad Catolica del Norte',
    web_page: 'http://www.ucn.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDqRaj1NXFcWF2BQ1pQ8nGBCWCrcmMZouiZLuDBmRUlU7YW-NU22DyVw',
    id: 2190,
    acronym: 'UCDN',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ucsc.cl',
    name: 'Universidad Catolica de La Santísima Concepción',
    web_page: 'http://www.ucsc.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAayvs0eIY4fbZVDNGHMoOXTXB-HYoPaB28p3shRdO0sAORoBDgnAuuvI',
    id: 2191,
    acronym: 'UCDLSC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ucsh.cl',
    name: 'Universidad Católica Cardenal Raúl Silva Henríquez',
    web_page: 'http://www.ucsh.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMBI_GMWclRWAWeTmE1wuMqpenKS7sdpBEB62zr1jcw4yUNH_cUrNe6JUS',
    id: 2192,
    acronym: 'UCCRSH',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uctem.cl',
    name: 'Universidad Catolica de Temuco',
    web_page: 'http://www.uctem.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1hoi109AaaQUTcHRcKqMS17rYOshUycmKVb_lXAh-SEC6bAv_1zMDmmE',
    id: 2193,
    acronym: 'UCDT',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ucv.cl',
    name: 'Universidad Catolica de Valparaiso',
    web_page: 'http://www.ucv.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRziA-AFO5E89_olzZOENRUQwHrc9qXlfAlWzWXGTlo9yByeYrJkjvlIFw',
    id: 2194,
    acronym: 'UCDV',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uda.cl',
    name: 'Universidad de Atacama',
    web_page: 'http://www.uda.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrNMmyqUOy1Dy10da9u9a_cyXzZsul_tr6hjfGJRcFoaRj359nGMt5Yw',
    id: 2195,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'udec.cl',
    name: 'Universidad de Concepcion',
    web_page: 'http://www.udec.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDIS_abkSzsCOZ2OVW7_atY8PONx2TF4Q4kQLGGdtOe4A671qzPHueiw',
    id: 2196,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'udelmar.cl',
    name: 'Universidad del Mar',
    web_page: 'http://www.udelmar.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxEWBH1cXdYTJoDs5KsGHcW8PGm_7T0Z7PbrwxfOY_7DqzfybUjAezFw',
    id: 2197,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'udesarrollo.cl',
    name: 'Universidad del Desarrollo',
    web_page: 'http://www.udesarrollo.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuDxd4irbb0Hog3wjmLL6Qkw8BnbtGIyquGOmx3xMWHsP8nGrIQy3sjQ',
    id: 2198,
    acronym: 'UDD',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'udp.cl',
    name: 'Universidad Diego Portales',
    web_page: 'http://www.udp.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKjkr99qm8pISm1oWoGbBi8l_F0uYQHfocu9AiME5j25UvzzMUDfc2PJI',
    id: 2199,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ufinis.cl',
    name: 'Universidad Finis Terrae',
    web_page: 'http://www.ufinis.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROItk25g9fl65FpaNgWLZtTr19IBh6tlTSD-laxWYtfHE0rX6Hvy1mzg',
    id: 2200,
    acronym: 'UFT',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ufro.cl',
    name: 'Universidad de La Frontera',
    web_page: 'http://www.ufro.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLtc0TW0gqzk-1Xc0uGTG3_9BMYW-QbsckB9QEWjpy5kCGosa_g7y5GCB34g',
    id: 2201,
    acronym: 'UDLF',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ugm.cl',
    name: 'Universidad Gabriela Mistral',
    web_page: 'http://www.ugm.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8MvngAS_CeOZYt9KLtYRFJEH4kVSxOMWtg4-bXcryQqqafk3T37aTbA',
    id: 2202,
    acronym: 'UGM',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uisek.cl',
    name: 'Universidad International SEK Santiago',
    web_page: 'http://www.uisek.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlVHx8X8zLsr9NPPMoQijZ4ZYDtcWgkw6yDgf9z_UjntUAMcB2UxbKXBqB',
    id: 2203,
    acronym: 'UISS',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ujso.cl',
    name: 'Universidad José Santos Ossa',
    web_page: 'http://www.ujso.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGRRBfbd4MBkujPAF4Ajn_f3keFPT_D1_DWvvRGPo4uweMgh-MpmYiNw',
    id: 2204,
    acronym: 'UJSO',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ulagos.cl',
    name: 'Universidad de Los Lagos',
    web_page: 'http://www.ulagos.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWGAUmtBr1bH3wZeif3QO6IcwcSVL2fN6v8Oj9uLpqWEsNvKg39B8zVg',
    id: 2205,
    acronym: 'UDLL',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ulare.cl',
    name: 'Universidad La Republica',
    web_page: 'http://www.ulare.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-OjMBrJGr-Yevy-KOOoEOQvyYmeQKiDabjBrtAyEt-TxbJg6cjTgQv8o',
    id: 2206,
    acronym: 'ULR',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'umag.cl',
    name: 'Universidad de Magallanes',
    web_page: 'http://www.umag.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsfLar-mdSim3GEzMDPuqxWKvjnI1SS6wwrMpOU9pNBnO4WSRusEuUsg',
    id: 2207,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'umaritima.cl',
    name: 'Universidad Maritima de Chile',
    web_page: 'http://www.umaritima.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjVioDR9KFclxrFYKbMaw318qUcSfYORWhr1HL-8zG11OX-JBOwgQyq_E',
    id: 2208,
    acronym: 'UMDC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'umayor.cl',
    name: 'Universidad Mayor',
    web_page: 'http://www.umayor.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCXnMO2ZT4xloQlEcHenhymWsgeXwhn-46FEuIUS8vB7-bdoYDcGwDTdV3aw',
    id: 2209,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'umce.cl',
    name: 'Universidad Metropolitana de Ciencias de la Educación',
    web_page: 'http://www.umce.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB3vA6GoyLQhEhzd2MgJLzwT6XkFUC7O-sOXXda-qUVugJdPskUXpAXNhd',
    id: 2210,
    acronym: 'UMDCDLE',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ume.cl',
    name: 'Universidad Mariano Egaña',
    web_page: 'http://www.ume.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRdRr5SpwJl882lU0J8bE5sjfNAjfVX3yrd0A0NEhpiLTHAjH9S70uOjo',
    id: 2211,
    acronym: 'UME',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'unab.cl',
    name: 'Universidad Nacional Andrés Bello',
    web_page: 'http://www.unab.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn_7paNI-JiHEYXRk7vYDBllHbZQ4OV_fpxuGuan5I9w9cG5-TW7pA00Xs',
    id: 2212,
    acronym: 'UNAB',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'unachile.cl',
    name: 'Universidad Adventista de Chile',
    web_page: 'http://www.unachile.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEGkONKt18wCuvjReViRRDdSDJfNTySzbnkm-AelycQt3_RgVoA7EHmNA',
    id: 2213,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'unap.cl',
    name: 'Universidad "Arturo Prat"',
    web_page: 'http://www.unap.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHLZVc5J0tDsx7FhUPRTiLwYqIPOJ6a5P3WKP97y9oYhy9CzN1BLJEVbc',
    id: 2214,
    acronym: 'U"P',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'unfa.cl',
    name: 'Universidad Francisco de Aguirre',
    web_page: 'http://www.unfa.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQb0uYPQELS80s6rdH-daS-2TICMREa3g3XtD7goJ_gMy7H10f1G9tccw',
    id: 2215,
    acronym: 'UFDA',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uniacc.cl',
    name: 'Universidad de Artes Ciencias y Comunicación',
    web_page: 'http://www.uniacc.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTx4TaOTTgd0lbanG6UkSya1Wi-sZuurBtXkA-_Oodu0Ky9mu9L_fkS5w',
    id: 2216,
    acronym: 'UDACYC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'unicit.cl',
    name: 'Universidad Iberoamericana de Ciencias y Tecnologia',
    web_page: 'http://www.unicit.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvvz83HTQt-n4aC8H6jMEKgztzUrhXGiuB58otCiiyflX3E0OxkUhYwTqm',
    id: 2217,
    acronym: 'UIDCYT',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'universidadarcis.cl',
    name: 'Universidad Arcis',
    web_page: 'http://www.universidadarcis.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3TSw8cu_7HZNh2WVR_ggiO2n8EDHZDVsnr1LgC-GIuvVjdvbiqpqZ7ovw',
    id: 2218,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'upacifico.cl',
    name: 'Universidad del Pacífico',
    web_page: 'http://www.upacifico.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLdluMyuoo6UbQtagFegVDLspG1sjcuPHPxq5NQrSj4Ie-_kqugdi64xu9',
    id: 2219,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'upa.cl',
    name: 'Universidad de Playa Ancha de Ciencias de la Educacion',
    web_page: 'http://www.upa.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9IXxJ-ZPgbKu3LadbZ6_ohLQz2j-MRLB-gt0qoet5rp1GcIEOQ69OAd-M',
    id: 2220,
    acronym: 'UDPADCDLE',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'usach.cl',
    name: 'Universidad de Santiago de Chile',
    web_page: 'http://www.usach.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG5WvfiV29j9ASEZ1x5fG2L1HW09D4mo-VpKv-lLheVWpyl6B4rWTQFRo',
    id: 2221,
    acronym: 'UDSDC',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'userena.cl',
    name: 'Universidad de La Serena',
    web_page: 'http://www.userena.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQs610qcziUkBJtm9L989XWaToF_ibZfGqiF44670UUlN3UuntKHTc6lg',
    id: 2223,
    acronym: 'UDLS',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uss.cl',
    name: 'Universidad San Sebastian',
    web_page: 'http://www.uss.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlm4R1-N58UTfNVFA9GqBKBBDQ0UJk_9lod6pTtAp_zb1SQxrZ5ubUqY8',
    id: 2224,
    acronym: 'USS',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'ust.cl',
    name: 'Universidad Santo Tomás',
    web_page: 'http://www.ust.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyfQwZNfN_gEILwfWdshWRIqjcST7_ISLuLk7ncd3TV1y8Cv0GyG-tGu3gqQ',
    id: 2225,
    acronym: 'UST',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uta.cl',
    name: 'Universidad de Tarapacá',
    web_page: 'http://www.uta.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6PepTifBo8oAltchH912uDxi4o-9jvo1gsTUoQXSawEMcJB_CY3TrQrg',
    id: 2226,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'utalca.cl',
    name: 'Universidad de Talca',
    web_page: 'http://www.utalca.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScPJ_ip2RU3qavpm6-odG7K-f25mofLh-98K_eYuL--S4SNh9iWGFdiFcj9Q',
    id: 2227,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'utem.cl',
    name: 'Universidad Tecnológica Metropolitana',
    web_page: 'http://www.utem.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNWmSs-VdLUCRqDVppliVcwY6c4RoN3Q_CYzKywPYckC-Vw7J4LyAGzDuT',
    id: 2228,
    acronym: 'UTM',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'utfsm.cl',
    name: 'Universidad Técnica "Federico Santa María"',
    web_page: 'http://www.utfsm.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStztF_0w2B2g09PMGUiB-GXEeVxENoIiz8rQpGe5VjRIOxvP9LaJXvNQ',
    id: 2229,
    acronym: 'UT"SM',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uv.cl',
    name: 'Universidad de Valparaiso',
    web_page: 'http://www.uv.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQymMsk-lYifyO7E6CalC1LgeOkVejCcSyd812RLGNX1iMa9wK52ZZpe0k',
    id: 2230,
    acronym: 'UDV',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uvipro.cl',
    name: 'Universidad Tecnologica "Vicente Perez Rosales"',
    web_page: 'http://www.uvipro.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeC4NHbCl4rRYR_JZmmibFVPJvXfhRca0aAZfHq-kWeKxKv-IuwhGNBB5U',
    id: 2231,
    acronym: 'UT"PR',
  },
  {
    alpha_two_code: 'CL',
    country: 'Chile',
    domain: 'uvm.cl',
    name: 'Universidad de Viña del Mar',
    web_page: 'http://www.uvm.cl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7CXbH5XYJoH25S7xx2Ufd7DJD5hk9Z99M5kyqZRlKNDuDtqRIZX1-5w7J',
    id: 2232,
    acronym: 'UDVDM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ahmu.edu.cn',
    name: 'Anhui Medical University',
    web_page: 'http://www.ahmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAHdEEJ0AAEVMMJuiNZLjUVrS5C-1VeU-zzlPJeIVlzpnHazrDS45A75g',
    id: 2233,
    acronym: 'AMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ahnu.edu.cn',
    name: 'Anhui Normal University',
    web_page: 'http://www.ahnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJLW5WRF7DKgJyLS9IygaBlM1igqfpxqfwbZrZmt4XZNMJuUwRlNZZebw',
    id: 2234,
    acronym: 'ANU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ahsdxy.ah.edu.cn',
    name: 'Anhui Technical College of Water Resources and Hydroelectric Power',
    web_page: 'http://www.ahsdxy.ah.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiI7xHPrqoaXz3ND4skB-JDXuIRvRVKSh2p7jXNxz2hXfUluq3M7esP4gy',
    id: 2235,
    acronym: 'ATCWRHP',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ahtcm.edu.cn',
    name: 'Anhui University of Traditional Chinese Medicine',
    web_page: 'http://www.ahtcm.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh-bGz1Ve3BCtnW3LqGviDsoEV5EiPgxBYXtVMEgAP3lLlapI3Q3ZrwOw',
    id: 2236,
    acronym: 'AUTCM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ahu.edu.cn',
    name: 'Anhui University',
    web_page: 'http://www.ahu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOZXzMtEdmCk89afqkyVLA2Ysi3fOGR59Y_wMlztQhGRmp13YJvMXUOKc',
    id: 2237,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'aufe.edu.cn',
    name: 'Anhui University of Finance and Economics',
    web_page: 'http://www.aufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQb_gEEdKxk7ZuTh_0bXowBNxyAoq3U6NpnoQp7-dPX_YNiAn4J1LFdO6Aq',
    id: 2238,
    acronym: 'AUFE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bac.edu.cn',
    name: 'Beijing University of Agriculture',
    web_page: 'http://www.bac.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSClBrSDRGbCaQ5KVgxYC-lvk0uPQzr4J2wFvXqU_r0GXxHf_N0nYNmvJk',
    id: 2239,
    acronym: 'BUA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bfsu.edu.cn',
    name: 'Beijing Foreign Studies University',
    web_page: 'http://www.bfsu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0GLpJLuRHEBTgt7HzEOjV5-N5H500BwNsd8R4bshsQm3P3Vo4iaLMHw8',
    id: 2240,
    acronym: 'BFSU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bhu.edu.cn',
    name: 'Bohai University',
    web_page: 'http://www.bhu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDHSjUb-1trXPqgR45e6SMFJjmkzRNj3ZOmdMXvzdFMEfqeaPpF7hC--U',
    id: 2241,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bisu.edu.cn',
    name: 'Beijing International Studies University',
    web_page: 'http://www.bisu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-Zq4VE2LtbKBHTlbKTsECxQ_0DcZxGs4dwqXMjfNz0P2M5o4SO0Nt5XI',
    id: 2242,
    acronym: 'BISU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bit.edu.cn',
    name: 'Beijing Institute of Technology',
    web_page: 'http://www.bit.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyhJk7kTaceKsKHedmIYT2YAuSXGz-lEWgmxNMcnLz86U0wPm1e6yLtw',
    id: 2243,
    acronym: 'BIT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'biti.edu.cn',
    name: 'Beijing Information Science and Technology University',
    web_page: 'http://www.biti.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6nRS-j7NjcCczYHXYhFZw7BwqkVTmEEii_FoV3YYUUDMoyvQ1amvCbg',
    id: 2244,
    acronym: 'BISTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bjaeu.edu.cn',
    name: 'China Agriculture University East',
    web_page: 'http://www.bjaeu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjEwBQ8WBWqVWFqvuujAQXmmBtHGCXeSxm0eVBgCOkKX3qFdlBGH95HHXGXg',
    id: 2245,
    acronym: 'CAUE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bjfu.edu.cn',
    name: 'Beijing Forestry University',
    web_page: 'http://www.bjfu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRqU__JngBcA92aij9ZvRQlxLK3moBdJ-5nTYAnS2EOUCQEHqN2mG4xmg',
    id: 2246,
    acronym: 'BFU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bjmu.edu.cn',
    name: 'Beijing Medical University',
    web_page: 'http://www.bjmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8g2m5uqxQiVqVIzlEw2y8j6bZC5D3s0hkOoYZrbdfuPZjN6KQncH1Kms',
    id: 2247,
    acronym: 'BMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bjpeu.edu.cn',
    name: 'Beijing Petroleum University',
    web_page: 'http://www.bjpeu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFRA4rr5pk6JwmDCufnnzqcmUkipeRlBJs1G94hbFE7-khw3jysPmJBkdk',
    id: 2248,
    acronym: 'BPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bjpu.edu.cn',
    name: 'Beijing Polytechnic University',
    web_page: 'http://www.bjpu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKNZPiIycW033K2Ws2qCGEiKHI0a6rYW1RjzZQZG0FHu2V2PbTHsEwdMM',
    id: 2249,
    acronym: 'BPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bjucmp.edu.cn',
    name: 'Beijing University of Chinese Medicine and Pharmacology',
    web_page: 'http://www.bjucmp.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRko8FjqBm0eSZ7qjvn_4LtJeidpr9x6XnucA5Ck60AhA1Dwd65JcL-u51L',
    id: 2250,
    acronym: 'BUCMP',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'blcu.edu.cn',
    name: 'Beijing Language and Culture University',
    web_page: 'http://www.blcu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLo7-QyyEEO9EiDUoscFuS0UMrbTGvbAZVuZzAka1bswveCKCBj5HlJcY',
    id: 2251,
    acronym: 'BLCU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bnu.edu.cn',
    name: 'Beijing Normal University',
    web_page: 'http://www.bnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbsiNQZ_I-AdzDCEQ7Cby5eRshcR3oXvtWlLpIp4s_XfzjClBUdcXvag',
    id: 2252,
    acronym: 'BNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bsu.edu.cn',
    name: 'Beijing Sport University',
    web_page: 'http://www.bsu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLAc9rMgU4tYIif_gNju96mCC-zNtCj6CcuoyAXs_zeRvc92JZv8NCU4GF',
    id: 2253,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'buaa.edu.cn',
    name: 'Beijing University of Aeronautics and Astronautics',
    web_page: 'http://www.buaa.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVICN7X2xahDFU9e5o_fVtGDjoTkIfAtbJoWlI56pCrnlcTi1vta6hSg',
    id: 2254,
    acronym: 'BUAA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'buct.edu.cn',
    name: 'Beijing University of Chemical Technology',
    web_page: 'http://www.buct.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlTloWgHaZ2VFtgZNalXXkBGUoc68q3-hOLkUhu8ToGY8kgaLoxJcDM7k',
    id: 2255,
    acronym: 'BUCT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'bupt.edu.cn',
    name: 'Beijing University of Posts and Telecommunications',
    web_page: 'http://www.bupt.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScvFXdr0_alCZqs3o4XynPKWtVuyp6e104AoA1IGDj13eXoY70BGpP55U',
    id: 2256,
    acronym: 'BUPT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cafa.edu.cn',
    name: 'Central Academy of  Fine Art',
    web_page: 'http://www.cafa.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvOX0AdY0ZzpT9n452SLoDMtTjXS-4OhTcyqtLoofqzJ2ze5aWsxcu3Wk',
    id: 2257,
    acronym: 'CAFA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cauc.edu',
    name: 'Civil Aviation University of China',
    web_page: 'http://www.cauc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6pNgZZE_gXxMX4QS0kibX4-skkCgFgWpyDuEU2pWseVWJzQJDVTBetPuO',
    id: 2258,
    acronym: 'CAUC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cau.edu.cn',
    name: 'China Agricultural University',
    web_page: 'http://www.cau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjlYu3P21mbzESd3TwtuaNlGps2_h5aSCX84csH5-LZc76wTMBqpDq5Q',
    id: 2259,
    acronym: 'CAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ccnu.edu.cn',
    name: 'Central China Normal University',
    web_page: 'http://www.ccnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeZ3U_cTKMeQR9qdZdsvjSenkaTUHoq8_2Eu2iaO4Fhwj-7XUCcgBqsw',
    id: 2260,
    acronym: 'CCNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ccut.edu.cn',
    name: 'Changchun University of Technology',
    web_page: 'http://www.ccut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRN1HulFmp4te8pQGC8G_W0URobkk6AAm9R2HMp1BiIYx8RKsQM0htOA',
    id: 2261,
    acronym: 'CUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cdu.edu.cn',
    name: 'Chengdu University',
    web_page: 'http://www.cdu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyx54aTpcf4gpJ8bOqt3ePsd0_cAr4FPlCAGGnNzGGXRhSXjG9fc1WoQ',
    id: 2262,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cdutcm.edu.cn',
    name: 'Chengdu University of Traditional Chinese Medicine',
    web_page: 'http://www.cdutcm.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7qsyY902PcEMuUE-w3bWUeXCM-Wk-krncrf_T9yrxZlD8mVjDBZuTlUE',
    id: 2263,
    acronym: 'CUTCM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cdut.edu.cn',
    name: 'Chengdu University of Technology',
    web_page: 'http://www.cdut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZIBZMQZZti6jqz0NLsiAyoyoArYhIJVsrnuHV_uOEOKa88_A0N4SCtis',
    id: 2264,
    acronym: 'CUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cfau.edu.cn',
    name: 'China Foreign Affairs University',
    web_page: 'http://www.cfau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrH5_bpg_4ThVjVKc7phiAhQtSaOt8U-ifA-ypcPoWnhlZC0Eb_xM5ng',
    id: 2265,
    acronym: 'CFAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'chinaacademyofart.com',
    name: 'China Academy of Art',
    web_page: 'http://www.chinaacademyofart.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkaae-5uL9vY1wesLw6X4M_bO-IASuGBB1i0BwPo-0Em5ZcVM0CA3qRac',
    id: 2266,
    acronym: 'CAA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cisisu.edu.cn',
    name: 'Chengdu Institute of Sichuan International Studies University',
    web_page: 'http://www.cisisu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfHddRJzEk4zP4Yma3PK6O4xtmDiB0f0-5i_Vz0BjBJBt-whkG0zay1g',
    id: 2267,
    acronym: 'CISISU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cmu.edu.cn',
    name: 'China Medical University Shenyang',
    web_page: 'http://www.cmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3lQRIGkJwC1lgK3Ktwutpj__uHfG6X3zBbkfcNf8TQT0C7V5Zol8YnM7NRQ',
    id: 2268,
    acronym: 'CMUS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cncnc.edu.cn',
    name: 'Changchun Teachers College',
    web_page: 'http://www.cncnc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtC-OyCMLOBj2o_3Aj594_9J2kIooKc1pdWvh1HHhC1U_9ilPtzfsWkQ',
    id: 2269,
    acronym: 'CTC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cnu.edu.cn',
    name: 'Capital Normal University',
    web_page: 'http://www.cnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-U9gCsw-r8aK8QO7DvRMOcd2dmd3qEUpgPpLhkdF9eFmdDSRY3_yuvsc',
    id: 2270,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cppsu.edu.cn',
    name: "Chinese People's Public Security University",
    web_page: 'http://www.cppsu.edu.cn/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcToCz6lKPE3tOMTNynKMwovHUFVsZMH3TV8coA0Sz_Xdp_Z1h0ezXK3MQ',
    id: 2271,
    acronym: 'CPPSU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cpu.edu.cn',
    name: 'China Pharmaceutical University Nanjing',
    web_page: 'http://www.cpu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTuOudcb9hdihqTE-uZVf99Vimee9jeaDtJNK58PBnrRhze5qWiFyIyxdN',
    id: 2272,
    acronym: 'CPUN',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cpums.edu.cn',
    name: 'Capital University of Medical Sciences',
    web_page: 'http://www.cpums.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBSpfevY9esVBeHH2pKcphOtPncPcT4HIUA9Ar4cq73f9uJManLU9KrQ',
    id: 2273,
    acronym: 'CUMS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqec.net.cn',
    name: 'Chongqing Education College',
    web_page: 'http://www.cqec.net.cn/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTe94TBPqbZQeCIGMmYB7wQB0mCKePhXvpFfMTtJm74KOM83UVeLZOi1Uo',
    id: 2274,
    acronym: 'CEC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqgyzy.com',
    name: 'Chongqing Vocational College of Public Transportation',
    web_page: 'http://www.cqgyzy.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx6AleTagS41b18BkK9ngrLodusUBU3a3HiN9-UBLc2QZabYY65h9BQqnb',
    id: 2275,
    acronym: 'CVCPT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqmu.edu.cn',
    name: 'Chongqing Medical University',
    web_page: 'http://www.cqmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6uF0daanyXJGkt-ZVPOv2FjjvjKX9nnEJBGOnpWhufyvCYdBwvyc7MZo',
    id: 2276,
    acronym: 'CMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqnu.edu.cn',
    name: 'Chongqing Normal University',
    web_page: 'http://www.cqnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWFsItNW5sFFUbmFfEdvsEhEexIUmPHG71Si2G8a6XFysCKtfU_KrfXRoX',
    id: 2277,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqtcedu.com',
    name: 'Chongqing Telecommunication College',
    web_page: 'http://www.cqtcedu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe94TBPqbZQeCIGMmYB7wQB0mCKePhXvpFfMTtJm74KOM83UVeLZOi1Uo',
    id: 2278,
    acronym: 'CTC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cquc.edu.cn',
    name: 'Chongqing University of Communications',
    web_page: 'http://www.cquc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXCm3QcW845z2M0Os6KjKAwR5ZaZYu2J_IxO4HCCNaueBK-7vmCzRh-3E',
    id: 2279,
    acronym: 'CUC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqu.edu.cn',
    name: 'Chongqing University',
    web_page: 'http://www.cqu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNAQC-hIelWDNr3AqC02QR0kU-LOOB1fKU248rHvbf9mpmLXawYxp5LuU',
    id: 2280,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqupt.edu.cn',
    name: 'Chongqing University of Post and Telecommunications',
    web_page: 'http://www.cqupt.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbp4NPwQ_9f2htbS80Ap_qlnVyvyUTmznwhQ96knoiQDTiTWFR3nvg7w',
    id: 2281,
    acronym: 'CUPT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqust.cn',
    name: 'Chongqing University of Science and Technology',
    web_page: 'http://www.cqust.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQepu5oiBgPDaJk0MxIT9p-ikxPxSAfbrgOVpBwCBPC8FigUfHSiDtJbg',
    id: 2282,
    acronym: 'CUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqut.edu.cn',
    name: 'Chongqing University of Technology',
    web_page: 'http://www.cqut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQepu5oiBgPDaJk0MxIT9p-ikxPxSAfbrgOVpBwCBPC8FigUfHSiDtJbg',
    id: 2283,
    acronym: 'CUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cqwu.net',
    name: 'Chongqing Wenli University',
    web_page: 'http://www.cqwu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe94TBPqbZQeCIGMmYB7wQB0mCKePhXvpFfMTtJm74KOM83UVeLZOi1Uo',
    id: 2284,
    acronym: 'CWU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'crtvu.edu.cn',
    name: 'Central Radio and TV University',
    web_page: 'http://www.crtvu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTu8pv0haNwk3GmZFxYkiZ1C6OyT-7_7KKgxLETPlGect1CZ_pol8_pkA',
    id: 2285,
    acronym: 'CRTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'csfu.edu.cn',
    name: 'Central South Forestry University',
    web_page: 'http://www.csfu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyXKf45flCjNbPuDDEyQ82mSZZljZtVnYKtkQhXFh6LScUbuIEMT-aSFI',
    id: 2286,
    acronym: 'CSFU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'csru.edu.cn',
    name: 'Changsha Railway University',
    web_page: 'http://www.csru.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuwOBXW9S_65hH-44i7b43WO6n3FKV_H5B4vXYS-W-CKgRJiFA1bYHpQ',
    id: 2287,
    acronym: 'CRU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'csu.edu.cn',
    name: 'Central South University',
    web_page: 'http://www.csu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG7mn5aBS9coZ3Bx54LObVwb9aMc5B72AV1I9Vhhobd4PxTno1_rjusLHu',
    id: 2288,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'csuep.edu.cn',
    name: 'Changsha University of Electric Power',
    web_page: 'http://www.csuep.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoT3-nA7X8v6nnEMbazsWKatI0MjsBQMwxw2uUAQIkQbaliQcDlcD1ew',
    id: 2289,
    acronym: 'CUEP',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ctbu.edu.cn',
    name: 'Chongqing Technology and Business University',
    web_page: 'http://www.ctbu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw2X6x9Uw63_fkjGf6U1NK5UsnU_G66TxGJplWcjbmV23QurqSM5A71a0',
    id: 2290,
    acronym: 'CTBU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ctgu.edu.cn',
    name: 'China Three Gorges University',
    web_page: 'http://www.ctgu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIpl2qs_ECTe54-C1tUaeAxHh83suu2WevSSWsnaLB6aylkYc4WsTVsHE',
    id: 2291,
    acronym: 'CTGU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cubu.edu',
    name: 'China USA Business University',
    web_page: 'http://www.cubu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_l5X-JDBwIQPz-DVAFt9aBtEY107uQ6zKxZYNSc4c1-kUqnnCp6ev4Xc',
    id: 2292,
    acronym: 'CUBU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cuc.edu.cn',
    name: 'Communication University of China',
    web_page: 'http://www.cuc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQWwfZ27niPSjhuzyNuDB41hmMGLxTof2osO6FXaQpEw0mp-N6xRKXnbo',
    id: 2293,
    acronym: 'CUC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cueb.edu.cn',
    name: 'Capital University of Economics and Business',
    web_page: 'http://www.cueb.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShwAUxOCkqQFiLJ_4I-OuidBWUOSEjsTdqhwk_AHSqau24-n8_j9FywDU',
    id: 2294,
    acronym: 'CUEB',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cufe.edu.cn',
    name: 'Central University of Finance and Economics',
    web_page: 'http://www.cufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBrbSzGSqRbKWtcDdXTPTlAdtbdN8S49-b4sR0XogZi997Wmq2PzU5jQ',
    id: 2295,
    acronym: 'CUFE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cugb.edu.cn',
    name: 'China University of Geoscience Beijing',
    web_page: 'http://www.cugb.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdQFt5xdGHqqT_1nh12o6olFRZs9l82z8YL-95pMk0tmC9qbhaO4_Fgg',
    id: 2296,
    acronym: 'CUGB',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cug.edu.cn',
    name: 'China University of Geosciences Wuhan',
    web_page: 'http://www.cug.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdQFt5xdGHqqT_1nh12o6olFRZs9l82z8YL-95pMk0tmC9qbhaO4_Fgg',
    id: 2297,
    acronym: 'CUGW',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cumtb.edu.cn',
    name: 'China University of Mining Technology - Beijing',
    web_page: 'http://www.cumtb.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnn6OVukfNeGvDt4344lrWoLcKcZQvG5NJ2Jgc2sH9fWfP2OJ-QNVF3Q',
    id: 2298,
    acronym: 'CUMT-B',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cumt.edu.cn',
    name: 'China University of Mining Technology - Xuzhou',
    web_page: 'http://www.cumt.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdj6VU3OimeaM13Vl3M1VIPhWkUEBavaNZboks9cguT-UOzD1vPY8zN7A',
    id: 2299,
    acronym: 'CUMT-X',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cun.edu.cn',
    name: 'Central University for Nationalities',
    web_page: 'http://www.cun.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0IW72LzmBMblYsCvE52-9OgBzclXBPz4kVfoijal4ia4B2Sf0D2p3KmI',
    id: 2300,
    acronym: 'CUFN',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cupl.edu.cn',
    name: 'China University of Political Science and Law',
    web_page: 'http://www.cupl.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRajLBeXZRNg_uIhB8ufilUl7zigmVIVI_T-kfLNSxLuNBRBB9gGEjAZw',
    id: 2301,
    acronym: 'CUPSL',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'cyc.edu.cn',
    name: 'China youth college for political science',
    web_page: 'http://www.cyc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJmhElqJ6PSU0zGaXpYGzqbm2dDLGoQoi_UsUEZqOKRBE-EyFFY82rCw',
    id: 2302,
    acronym: 'CYCFPS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'dhu.edu.cn',
    name: 'Donghua University Shanghai',
    web_page: 'http://www.dhu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgAeo9cDHObZcjuKbxjyPJXWim0qYm9hdCH0Y-kaGkPLG9Z9od5btQlA',
    id: 2303,
    acronym: 'DUS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'dlmedu.edu.cn',
    name: 'Dalian Medical University',
    web_page: 'http://www.dlmedu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTViurTd8aXDU2fpxzySFIbWIRxV0ZR-JAYAkWPN9P2rq5VOT__4kEmG9Q',
    id: 2304,
    acronym: 'DMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'dlmu.edu.cn',
    name: 'Dalian Martime University',
    web_page: 'http://www.dlmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC7m6Q8rEGmyTM-EM3yYzInpx2j3UI6MHEHAaCyRNgDnSPC9_hk7VspY8',
    id: 2305,
    acronym: 'DMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'dlu.edu.cn',
    name: 'Dalian University',
    web_page: 'http://www.dlu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTViurTd8aXDU2fpxzySFIbWIRxV0ZR-JAYAkWPN9P2rq5VOT__4kEmG9Q',
    id: 2306,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'dlufl.edu.cn',
    name: 'Dalian University of Foreign Language',
    web_page: 'http://www.dlufl.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHi0iZSWG1mBp97tqnmEB9ljxLEWnQ6FXoWhpDP3iUyW8VIeUunEQUnBY',
    id: 2307,
    acronym: 'DUFL',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'dlut.edu.cn',
    name: 'Dalian University of Technology',
    web_page: 'http://www.dlut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5eYCgyJK66qK7B48niewB1jutWo-5uizkqTeC5iQLde2AjE6Uhj46q_0',
    id: 2308,
    acronym: 'DUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'dufe.edu.cn',
    name: 'Dongbei University of Finance And Economics',
    web_page: 'http://www.dufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2jcwWJTFA2EvSoewRukvOSk7m-bzUe0Ez2uBIjoYgaAYFbEzdfccehYsf',
    id: 2309,
    acronym: 'DUFE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ecjtu.jx.cn',
    name: 'East China Jiao Tong University',
    web_page: 'http://www.ecjtu.jx.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2310,
    acronym: 'ECJTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ecnu.edu.cn',
    name: 'East China Normal University',
    web_page: 'http://www.ecnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLLusg5DFoV3POYX6ewE0EMJOWPE3xjQbVl8tECuqCbJnITWta-kGWbQ',
    id: 2311,
    acronym: 'ECNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ecust.edu.cn',
    name: 'East China University of Science and Technology',
    web_page: 'http://www.ecust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK4MFXni2bSVXc39lzWIXvQDvpaUxhQmvQc_nR-eHhB4H8RqDoZkCv2P0',
    id: 2312,
    acronym: 'ECUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'emsfj.com',
    name: 'Emeishan Buddhist College',
    web_page: 'http://www.emsfj.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSws2JGUnu4amJRip5w9UL1w_zg6Oct0s8UBCGUTsehY1A4Lmkd7tnX2cw',
    id: 2313,
    acronym: 'EBC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fimmu.edu.cn',
    name: 'Southern Medial University',
    web_page: 'http://www.fimmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4kVYsp5AS14eOqXdh7LWSPk5cAMovnX_HCpc2BoqC9cOTG9usxCmWCyp3',
    id: 2314,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fjau.edu.cn',
    name: 'Fujian Agricultural University',
    web_page: 'http://www.fjau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXhUJQ9NG6Hr-kgdHSeWYZumBiB_XJ6hxk7eHs2MrEZE5A6xgwchz8_zY',
    id: 2315,
    acronym: 'FAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fjmu.edu.cn',
    name: 'Fujian Medical University',
    web_page: 'http://www.fjmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhUGHXT8J-bExyV9oR-eKAj1yK0yU1BYbU6lMnjeUx_5hqt_-iPUi7XA',
    id: 2316,
    acronym: 'FMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fjnu.edu.cn',
    name: 'Fujian Normal University',
    web_page: 'http://www.fjnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUb9d0CsxVUa2jNGRxpXcLcfsEqyfyuSWHmLViR9HLoRRMn5R5DDuLaw',
    id: 2317,
    acronym: 'FNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fjtcm.edu.cn',
    name: 'Fujian University of Traditional Chinese Medicine',
    web_page: 'http://www.fjtcm.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFJc6DdJ2k8lPkAc7QHM6G01WdWfiDqXwK7xW27jqoWbh2oEwLX53Xgg',
    id: 2318,
    acronym: 'FUTCM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fmmu.edu.cn',
    name: '4th Military Medical University',
    web_page: 'http://www.fmmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4RCySf9uR_zbiuB8XhEqQAl0j6SqMjvWVAqzxEyv-_6FDXnZtrObsKw',
    id: 2319,
    acronym: '4MMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fosu.edu.cn',
    name: 'Foshan University',
    web_page: 'http://www.fosu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxOGvplmSPnUiDas_k3TN2mJTyDAlCM_m2Fk0xY26XC3m0cIcgaqmDMto',
    id: 2320,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fspu.edu.cn',
    name: 'Fushun Petroleum University',
    web_page: 'http://www.fspu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpDqyoTF4jlcK5idzg6pGS-Dvy0JCNVbU9w4YdtMs8fQ36fKvE3iqzfd0',
    id: 2321,
    acronym: 'FPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fudan.edu.cn',
    name: 'Fudan University',
    web_page: 'http://www.fudan.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFuScWpzBnG68iYGLLgXPN6TK7n43h5_dQSLgMbawtB3Ysf8zjie0GzZ0',
    id: 2322,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'fzu.edu.cn',
    name: 'Fuzhou University',
    web_page: 'http://www.fzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJXfhWIaLmLRf7nGTixPos-UTGp7W2C9jt6mL8FP2ldAuj1K_0AfyuB4g',
    id: 2323,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gdin.edu.cn',
    name: 'Guangdong Polytechnic Normal University',
    web_page: 'http://www.gdin.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpUULM8gJLrzpMNyKaQUdceFwOrac_wigJeEv_5oLibMBo6EYJdvjIKIY',
    id: 2324,
    acronym: 'GPNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gdrtvu.edu.cn',
    name: 'Guangdong Radio & TV University',
    web_page: 'http://www.gdrtvu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOPDkUdnUBXNn_q-wIVtdLWyM_RRiA-UC5kCcKuvgssWCfffuLJgYE-Q',
    id: 2325,
    acronym: 'GR&TU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gdufs.edu.cn',
    name: 'Guangdong University of Foreign Studies',
    web_page: 'http://www.gdufs.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRfAC2S9gv9_6tcEqeICUcoeDb93btCzxlH6jLrQBY12kBohywbVdQpEo',
    id: 2326,
    acronym: 'GUFS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gdut.edu.cn',
    name: 'Guangdong University of Technology',
    web_page: 'http://www.gdut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy69ONeulwg8rjUF9JVbgAlkE5xfixhXv869ZgwKFLACjZWD6H72Id8w',
    id: 2327,
    acronym: 'GUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gsau.edu.cn',
    name: 'Gansu Agricultural University',
    web_page: 'http://www.gsau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1-3-MX9PHPnJCEv5LMt-S1b9FUMBvHdJktPJ6Ap7QG9Jp3va04VvukUQ',
    id: 2328,
    acronym: 'GAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gsut.edu.cn',
    name: 'Gansu University of Technology',
    web_page: 'http://www.gsut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqD16d01wIHo5i7pQl1OoE4x6ub0NWUcbu3_zgj-Rrq1cG-jyrrqQ5aw',
    id: 2329,
    acronym: 'GUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'guangztc.edu.cn',
    name: 'Guangzhou Normal University',
    web_page: 'http://www.guangztc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQah1taWp_3kmRAUFfvUvhBkfBC8Ld-f4oSM5moLp_7IGSI-EfazIh57LbL',
    id: 2330,
    acronym: 'GNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gxmu.edu.cn',
    name: 'Guangxi Medical University',
    web_page: 'http://www.gxmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPSQTaf0SPVTYvtpnfvLmC0ahSrwAN0YJmo8Hwng8X6RkgEf6VA3W8uig',
    id: 2331,
    acronym: 'GMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gxnu.edu.cn',
    name: 'Guangxi Normal University',
    web_page: 'http://www.gxnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSITa_BVWQ5_CXm-l1YgQHJJQ734eJJiHbMlafi0ggbsjTDicY5nFvDANw',
    id: 2332,
    acronym: 'GNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gxtcmu.edu.cn',
    name: 'Guangxi Traditional Chinese Medical University',
    web_page: 'http://www.gxtcmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKJGzr3j67wkk3ErHUwBrd9FL3XIIucdfU4Jd_pD45fRsbdCZ1-NdeSrdp5Q',
    id: 2333,
    acronym: 'GTCMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gxu.edu.cn',
    name: 'Guangxi University',
    web_page: 'http://www.gxu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs1ijelmTggnduemP4IFWTL_QGC4ZKRKmC0JO5tYJ_mEmXXBNXojx84JkM8Q',
    id: 2334,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gxun.edu.cn',
    name: 'Guangxi University for Nationalities',
    web_page: 'http://www.gxun.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCCYcN2Sg2jsIripcd7f_jts6s_YL_V-vllahkPU45axBbvQosKF9ZDA',
    id: 2335,
    acronym: 'GUFN',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gzarts.edu.cn',
    name: 'Guangzhou Academy of Fine Art',
    web_page: 'http://www.gzarts.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfWeMTUAPaoD51k13CwZGSMOyuvKDzeIjUEeS0ViRn8dLkF2uPUrzEVw',
    id: 2336,
    acronym: 'GAFA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gzhtcm.edu.cn',
    name: 'Guangzhou University of Traditional Chinese Medicine',
    web_page: 'http://www.gzhtcm.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRfuvXZ1Of6x80I5QsFEZJ7edh3w9pFeZwn-T3hr9Pt2IeD1bRGKz3Vrg',
    id: 2337,
    acronym: 'GUTCM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gzhu.edu.cn',
    name: 'Guangzhou University',
    web_page: 'http://www.gzhu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMJ-PtwsBdm97noGxjT8B83Use_ix8Pq3ALkWgv60u4_vyZ2MnGqvevg',
    id: 2338,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gznu.edu.cn',
    name: 'Guizhou Normal University',
    web_page: 'http://www.gznu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXOjo2Rtm4AOf8xEoF2G7kvBwQ8lpsqHOhJBTzvptvZmhsOcFJFvJmbPs',
    id: 2339,
    acronym: 'GNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gzsums.edu.cn',
    name: 'Sun Yat-Sen University of Medical Sciences',
    web_page: 'http://www.gzsums.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYb1tN0EZs8ql8IHF2T54eGVq5H7RMSHRZYmnmpdw6AqOAdny5zMG8I8s',
    id: 2340,
    acronym: 'SYUMS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'gzu.edu.cn',
    name: 'Guizhou University',
    web_page: 'http://www.gzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLmu4qIknk_TElNOMMNM2Hv0y81fAcb0-HHbJ4mqZpgLiKaWE_RfBK0aKr',
    id: 2341,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hainnu.edu.cn',
    name: 'Hainan Normal University',
    web_page: 'http://www.hainnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu-leb5ynS8aHClqm0sVZ5T3HabuPkSIhzukFE50OEpx_giqX4UzMxCg',
    id: 2342,
    acronym: 'HNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hainu.edu.cn',
    name: 'Hainan University',
    web_page: 'http://www.hainu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCvl1qn6KbUncYJ4fKDxLVJ2l26YAMjG35y0NuWyUYu_ZiTxe9BK0VOTA',
    id: 2343,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hbafa.com',
    name: 'Hebei Academy of Fine Art',
    web_page: 'http://www.hbafa.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThlGe0O4qpqMCAgCdRxXp52Fz9ISpeR8BRC-PqKezpb7TjJweWQhycFNk',
    id: 2344,
    acronym: 'HAFA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hbu.edu.cn',
    name: 'Hebei University',
    web_page: 'http://www.hbu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSecsewQLVa3Nk2lpg_ne15qbARckA3cwQx1Kq7QyNy8CHLlRlB0UOa644',
    id: 2345,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hdpu.edu.cn',
    name: 'University of Petroleum (East China)',
    web_page: 'http://www.hdpu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtR3ZRR0XmJpd-rTvLStmGSUgmKb5KeLAP7prFvipoENLcPcoQ3PZcrXv0xw',
    id: 2346,
    acronym: 'UP(C',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hebau.edu.cn',
    name: 'Hebei Agricultural University',
    web_page: 'http://www.hebau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN7t85hSKxACZap9WS7-SqJYck4y9SPft8ni4WF9HoEl5nr5N2Y8JRGg',
    id: 2347,
    acronym: 'HAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hebeiuniteduniversity.com',
    name: 'Hebei United University',
    web_page: 'http://www.hebeiuniteduniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6jwDuaLGh6RlYgcXgj79P5OfIQTHtCeQuUO4ejkfi7JkTFWIEdAoeEdg',
    id: 2348,
    acronym: 'HUU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hebmu.edu.cn',
    name: 'Hebei Medical University',
    web_page: 'http://www.hebmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSecsewQLVa3Nk2lpg_ne15qbARckA3cwQx1Kq7QyNy8CHLlRlB0UOa644',
    id: 2349,
    acronym: 'HMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hebnu.edu.cn',
    name: 'Hebei Normal University',
    web_page: 'http://www.hebnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7kyNdDoheROa_7-K-1EbxYBjpkLkUAtxFUdXXTqXRZgqedRqpq7RoiMA',
    id: 2350,
    acronym: 'HNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hebust.edu.cn',
    name: 'Hebei University of Science and Technology',
    web_page: 'http://www.hebust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5eqDqeVYk5yDKJituVuD1bQWw-dRzIml77FXn8DUHlVSFWjNWkYnXJQ',
    id: 2351,
    acronym: 'HUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hebut.edu.cn',
    name: 'Hebei University of Technology',
    web_page: 'http://www.hebut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5eqDqeVYk5yDKJituVuD1bQWw-dRzIml77FXn8DUHlVSFWjNWkYnXJQ',
    id: 2352,
    acronym: 'HUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'henannu.edu.cn',
    name: 'Henan Normal University',
    web_page: 'http://www.henannu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2xvRoPyfFjtw6UX9lbgZK6e2n6B0dH6oW9rOpKAlc0onOZim-GEcXlw',
    id: 2353,
    acronym: 'HNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'henau.edu.cn',
    name: 'Henan Agriculture University',
    web_page: 'http://www.henau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlqTwZ9HWSq2cbFcxbMcO8UMhrncCBWq0Q16s7Ig0_86UAu9ufyg2n3tmD',
    id: 2354,
    acronym: 'HAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'henu.edu.cn',
    name: 'Henan Univeristy',
    web_page: 'http://www.henu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvHfvW5SvmWzIP7xySBZ1sb5E3qkgkjhHGcenrQynbcn3cOQpiThIRJi2_',
    id: 2355,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'heuet.edu.cn',
    name: 'Hebei University of Economics and Trade',
    web_page: 'http://www.heuet.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiZWpZfnGRpD6oKbN2lc6zWi-6BikH3M7nHb86H6um0eCAJgC5fGs1J3Y',
    id: 2356,
    acronym: 'HUET',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hfut.edu.cn',
    name: 'Hefei University of Technology',
    web_page: 'http://www.hfut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYQSkaa4KWHWAS7vC0N-46qVgamMavHcwsXtFEMaFtzRmOlvcQ7KnJkw',
    id: 2357,
    acronym: 'HUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hhit.edu.cn',
    name: 'Huaihai Institute of Technology',
    web_page: 'http://www.hhit.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWnDW3sxDXIKkOlB3BnizB8IufPLQ9rCnB0ir_jbNVNmX61sw6DaBGdT6aIg',
    id: 2358,
    acronym: 'HIT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hhrtu.com',
    name: 'Huaihua Radio and Television University',
    web_page: 'http://www.hhrtu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-18D4RxkbnK28MdOf-5I6VUvntAZIdrtFkDr84g16B9iYoLp-Oe4kRg',
    id: 2359,
    acronym: 'HRTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hhstu.edu.cn',
    name: 'Huanghe Science & Technology University',
    web_page: 'http://www.hhstu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_Wh51iTBz34QADElfJwPPSESMifs13yDxPrjcQ4hW7ypaUX_LhL-Xtg',
    id: 2360,
    acronym: 'HS&TU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hhuc.edu.cn',
    name: 'Hohai University Changzhou',
    web_page: 'http://www.hhuc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZqmkb5-dFTWKgAqhm9qdJ5Uz5wGbrI5ah5qyMZeaBVVk1ckrqlIi6vH_R',
    id: 2361,
    acronym: 'HUC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hhu.edu.cn',
    name: 'Hehai University',
    web_page: 'http://www.hhu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC48mzWtwRGdswLI5MrtT8pr6BsWJr7DQq6wesmW5f0m1n1Rwdt3A5jA',
    id: 2362,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hhu.edu.cn',
    name: 'Huaihua University',
    web_page: 'http://www.hhu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8YsCjBXOuBPva_AXWUzRMaPdZ3bG_3NaR_Unvsfn9cgSQcW1WTTRO7Oc',
    id: 2363,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hit.edu.cn',
    name: 'Harbin Institute of Technology',
    web_page: 'http://www.hit.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsf7tPcGV7AQ97LhtsTNbIGAL8wWCKTs8wmRtO7TKnYMVmC4idZz5CZBo',
    id: 2364,
    acronym: 'HIT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hlaflru.edu.cn',
    name: 'Heilongjiang August 1st Reclamation University',
    web_page: 'http://www.hlaflru.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZCJ5Nxr3xF_sp89njyrGSVTET5vYaF0ET2BNNutKQxgh_kj9BA7yEQlA',
    id: 2365,
    acronym: 'HA1RU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hljcu.edu.cn',
    name: 'Heilongjiang Commercial University',
    web_page: 'http://www.hljcu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7XmZelTLCk9Sei5vEFH-OGkuW5G4vTpgkEOU4Vgcr5R2imc0WnS9EcQ',
    id: 2366,
    acronym: 'HCU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hlju.edu.cn',
    name: 'Heilongjiang University',
    web_page: 'http://www.hlju.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI_-HGhQekKcC5NQwhvqZ68611Ppv9YrBOy01E-W5dAqz8EiiFi22Q0ec',
    id: 2367,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hnfjxy.cn',
    name: 'Henan Buddhist College',
    web_page: 'http://www.hnfjxy.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMcwcKvhDNQEKKhqSPWLERiPzIgQuxMLhlcMjPcmXnq0i9ZJWnjMOL9a4',
    id: 2368,
    acronym: 'HBC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hqgc.net',
    name: 'Logistics Engineering University of PLA',
    web_page: 'http://www.hqgc.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe94TBPqbZQeCIGMmYB7wQB0mCKePhXvpFfMTtJm74KOM83UVeLZOi1Uo',
    id: 2369,
    acronym: 'LEUP',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hqu.edu.cn',
    name: 'Huaqiao University Quanzhuo',
    web_page: 'http://www.hqu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJho4mmouF1iXb_Y9Fb_Tn7UieX-toTwK8ZNRwYpjiQ8kqDVYl7bU_-N6-Nw',
    id: 2370,
    acronym: 'HUQ',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hrbeu.edu.cn',
    name: 'Harbin Engineering University',
    web_page: 'http://www.hrbeu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyqU-Dab16fT68zH81xkhvNqoHJVyr3GFj7HMcPCK6vruVB1uqMHG8o8Q',
    id: 2371,
    acronym: 'HEU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hrbmu.edu.cn',
    name: 'Harbin Medical University',
    web_page: 'http://www.hrbmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9Bfv9Ix3nnXbXhlbSkAic9eCGKQPtDNQVGXfOC13cZ8aymgCVEmAxnA',
    id: 2372,
    acronym: 'HMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hrbnu.edu.cn',
    name: 'Harbin Normal University',
    web_page: 'http://www.hrbnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB8-b1EAdTPMk8OmF6VzW7ShaETsdSjQINiOetZaWw1uX6kg56-rB1MMI',
    id: 2373,
    acronym: 'HNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hrbucea.edu.cn',
    name: 'Harbin University of Civil Engineering & Architecture',
    web_page: 'http://www.hrbucea.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsf7tPcGV7AQ97LhtsTNbIGAL8wWCKTs8wmRtO7TKnYMVmC4idZz5CZBo',
    id: 2374,
    acronym: 'HUCE&A',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hrbust.edu.cn',
    name: 'Harbin University of Science and Technology',
    web_page: 'http://www.hrbust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyuZ4BrTqWiOziFdSZsWycyfHJwGPolRybLxsMN8xEuNUrUdJ2gexnWaE',
    id: 2375,
    acronym: 'HUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hubu.edu.cn',
    name: 'Hubei University',
    web_page: 'http://www.hubu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaGrREmOJSfZ4WE-FYulQ1zo0iFz0SZUNyrICvPRsaaN3Va9WZUQDdsg',
    id: 2376,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hunau.net',
    name: 'Hunan Agricultural University',
    web_page: 'http://www.hunau.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxKlv6DuJwDHlP4G6V76P43cmUBFEu1G5aHFjDcNGWJ-mobYTiqc1rSw',
    id: 2377,
    acronym: 'HAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hunnu.edu.cn',
    name: 'Hunan Normal University',
    web_page: 'http://www.hunnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2xvRoPyfFjtw6UX9lbgZK6e2n6B0dH6oW9rOpKAlc0onOZim-GEcXlw',
    id: 2378,
    acronym: 'HNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hunu.edu.cn',
    name: 'Hunan University',
    web_page: 'http://www.hunu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzbsECRsp4ZKRvxoJRo4P2kCfBkkjG497jk_F3bCXA4tbVzjXVxcuGqg',
    id: 2379,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hust.edu.cn',
    name: 'Huazhong University of Science and Technology',
    web_page: 'http://www.hust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1AlJoZ8gxo2usrYc06iSf3CG-H2fzyaDYcctXcQCD9qYLi1vM3lK7QpA',
    id: 2380,
    acronym: 'HUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hzau.edu.cn',
    name: 'Huazhong Agricultural University',
    web_page: 'http://www.hzau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTugw-_3tXekoeePgm0Uzb52qS34Oh--0LfOOvbhjuHxAxRf0A4UNWX-O8t',
    id: 2381,
    acronym: 'HAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'hzu.edu.cn',
    name: 'Huizhou University',
    web_page: 'http://www.hzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtPHZc1Xjc1fsY5vTq5BmOOfVbTDwke9Lk5mZaVP7yzsuu8a5Jj0imAmE',
    id: 2382,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ibub.edu.cn',
    name: 'International Business University of Beijing',
    web_page: 'http://www.ibub.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0GLpJLuRHEBTgt7HzEOjV5-N5H500BwNsd8R4bshsQm3P3Vo4iaLMHw8',
    id: 2383,
    acronym: 'IBUB',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'imau.edu.cn',
    name: 'Inner Mongolia Agricultural University',
    web_page: 'http://www.imau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTM7I4LPXC6_ajVM1v5lMMoJuniqNqJe6_H9GesYKYzIXgtMynbScgKjIU',
    id: 2384,
    acronym: 'IMAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'imnu.edu.cn',
    name: 'Inner Mongolia Normal University',
    web_page: 'http://www.imnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLLusg5DFoV3POYX6ewE0EMJOWPE3xjQbVl8tECuqCbJnITWta-kGWbQ',
    id: 2385,
    acronym: 'IMNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'impu.edu.cn',
    name: 'Inner Mongolia Polytechnic University',
    web_page: 'http://www.impu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9FDucGL3iq_THuEE5PLj6_sFnDRUz14xpO6Nla-m8glpBjqhRvFLnIQ',
    id: 2386,
    acronym: 'IMPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'imu.edu.cn',
    name: 'Inner Mongolia University',
    web_page: 'http://www.imu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9FDucGL3iq_THuEE5PLj6_sFnDRUz14xpO6Nla-m8glpBjqhRvFLnIQ',
    id: 2387,
    acronym: 'IMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ipdbuu.com.cn',
    name: 'Beijing Union University',
    web_page: 'http://www.ipdbuu.com.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO2WO7axVbFxTWtE0yRGHmk7U3q_Ww3OSpC7r8devSocfzrxKIt6P4jw',
    id: 2388,
    acronym: 'BUU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jci.edu.cn',
    name: 'Jingdezhen China Institute',
    web_page: 'http://www.jci.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREoX_rtAxNGTZ6DDJtVqsnuhJU-BuuT6mBCVJ7lrpBgV9T9DVG0SymPrY',
    id: 2389,
    acronym: 'JCI',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jlau.edu.cn',
    name: 'Jilin Agricultural University',
    web_page: 'http://www.jlau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBZhUy2njSTbp2SjNYu_joS4Jt2P2Yycfxose51STXSczOz7rpG4vR5g',
    id: 2390,
    acronym: 'JAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jlu.edu.cn',
    name: 'Jilin University',
    web_page: 'http://www.jlu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd-1Hi_hk8AqSPsqcYUlM4Y40i5FhQtaBODXHngMTcGGLp4UQtKCOo4g',
    id: 2391,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jmu.edu.cn',
    name: 'Jimei University',
    web_page: 'http://www.jmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3Iyzdb5ErPYLWX4bdxAwglf2Rygya2OBM1vm3eJ2YoQ6EnbFmLI6ISro',
    id: 2392,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jnu.edu.cn',
    name: 'Jinan University',
    web_page: 'http://www.jnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMN1Mls81eWxKy7crAEVsrtczsjFs3KpWZDGLIwJlz4WwczCmEqGFEew',
    id: 2393,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jsu.edu.cn',
    name: 'Jishou University',
    web_page: 'http://www.jsu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReop_wRgnlKi1LjR767jTbL-xWeQTQOK_vLv6wXNr7yskI-GRkXCk-BvY',
    id: 2394,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jsust.edu.cn',
    name: 'Jiangsu University of Science and Technology',
    web_page: 'http://www.jsust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUV7jTCi7TuGyuB-SoiIC5r8NPd2IOKaXS4p3v5lFWKZn6nucb4l59aOxU',
    id: 2395,
    acronym: 'JUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jutcm.com',
    name: 'Jiangxi University of Traditional Chinese Medicine',
    web_page: 'http://www.jutcm.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsODlir00q4aQTAFVygQ7DkIOqwmWRiMLyBziGADWrlORQdDEVcjQKxg',
    id: 2396,
    acronym: 'JUTCM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jut.edu.cn',
    name: 'Jilin University of Technology',
    web_page: 'http://www.jut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5eYCgyJK66qK7B48niewB1jutWo-5uizkqTeC5iQLde2AjE6Uhj46q_0',
    id: 2397,
    acronym: 'JUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jxau.edu.cn',
    name: 'Jiangxi Agricultural University',
    web_page: 'http://www.jxau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN7XKv9sUG7Ybnyvkvjww73QJXgWr5GCUPUUmplaIIa9lcMKiLdu9GTw',
    id: 2398,
    acronym: 'JAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jxnu.edu.cn',
    name: 'Jiangxi Normal University',
    web_page: 'http://www.jxnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0IOJimLqWxvKalEF9Y7-vpaVsTC1mI8BVNoEZbTqxqA0fuCqQSh_8yfk',
    id: 2399,
    acronym: 'JNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jxufe.edu.cn',
    name: 'Jiangxi University of Finance and Economics',
    web_page: 'http://www.jxufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwCaDtRwaYQFJWEGaV0uXNRKCmPdDh-2l5rC0hLkMsPNxvjqv0AbcY1w',
    id: 2400,
    acronym: 'JUFE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'jyu.edu.cn',
    name: 'Jiaying University',
    web_page: 'http://www.jyu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1migWVgbqqRNvMb1ZsBcSs8HDW3e4dQ3EvqI7Vo1Zlwd5B7Ob13KqRg',
    id: 2401,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'kmust.edu.cn',
    name: 'Kunmimg University of Science and Technology',
    web_page: 'http://www.kmust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1yXjvqIKOj8ZHaeBy8PCic2YY-p2wWKtEoKh0WJcWARhbjdVKBMv3kj0',
    id: 2402,
    acronym: 'KUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lctu.edu.cn',
    name: 'Liaocheng Teachers University',
    web_page: 'http://www.lctu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFAClZ-gYLfH5fUNFaTpdQsCPJoxEyTrOuADYWl6MoL6X9CwGcCeeJyA',
    id: 2403,
    acronym: 'LTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lidapoly.com',
    name: 'Shanghai Lida Polytechnic Institute',
    web_page: 'http://www.lidapoly.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvFDSvvCYY5RyCusYMdPoO6Hi3XPpeOzJsZ6h2qYXlDHkyrTKMqvo1QngJ',
    id: 2404,
    acronym: 'SLPI',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lixin.edu.cn',
    name: 'Shanghai Lixin University of Commerce',
    web_page: 'http://www.lixin.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqMM4coVW4L5uzRoWOYCfr66wp0fLRJ1EGSNEemzSoCoEDVdFLe09H_g',
    id: 2405,
    acronym: 'SLUC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lnnu.edu.cn',
    name: 'Liaoning Normal University',
    web_page: 'http://www.lnnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPbS7ZHtnimc0CwFe81l-fk3JRV_Af4bZ2WoJkhWjrFTyab8zR-OYYbGmC',
    id: 2406,
    acronym: 'LNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lntu.edu.cn',
    name: 'Liaoning Technical University',
    web_page: 'http://www.lntu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9YOqrHruBg8XZAP65rRj_ZA5ug27jsttRngn5f7_xzVZjtPtTmQkyluP_',
    id: 2407,
    acronym: 'LTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lnu.edu.cn',
    name: 'Liaoning University',
    web_page: 'http://www.lnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSh8ukdu00CX7O7QogqfjtY8Zgj4p8v6QGTzgH5fMVRy1e2Cv6gha7lvw',
    id: 2408,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lumei.edu.cn',
    name: 'Luxun Academy of Fine Art',
    web_page: 'http://www.lumei.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ87ZGpkTQ5qgnoB3Ek_5YLunhmkiO6c2GbA_F7xsKQQsCnD7o5D2PM3g',
    id: 2409,
    acronym: 'LAFA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lzmc.edu.cn',
    name: 'Luzhou Medical College',
    web_page: 'http://www.lzmc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOsdfu_6cxItuXB_eB5Udr3sFbNBcDvDFcPwWYi3vMDC-x7ZQ5jQTd9Ts',
    id: 2410,
    acronym: 'LMC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'lzu.edu.cn',
    name: 'Lanzhou University',
    web_page: 'http://www.lzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReNXukbWJGftlFjQqbCvqUvwZB70NJl-JmgTqfjZtB7zp1sxKW4HEKLC4',
    id: 2411,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nbu.edu.cn',
    name: 'Ningbo University',
    web_page: 'http://www.nbu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQI-SVB601A84J7RanMhukpagFYIyoQT4XZVJMxvAK_XGgKbCMauDVHw',
    id: 2412,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nbut.edu.cn',
    name: 'Ningbo University of Technology',
    web_page: 'http://www.nbut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1zzTiZwPYDhxJQ0mxZ35DSTBlBqGuNVqpplucwoE2yIOa7OsPxlr8Ww',
    id: 2413,
    acronym: 'NUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ncepubj.edu.cn',
    name: 'North China Electric Power University',
    web_page: 'http://www.ncepubj.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqBv1QvmgbzqY5GEU9Qw3aZLNALML8KHEVa--lVlSGe2HuOg6E0OvAR_o',
    id: 2414,
    acronym: 'NCEPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ncu.edu.cn',
    name: 'Nanchang University',
    web_page: 'http://www.ncu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7nAa3cRBsahnlzHbKP-mwsnj04US6yTiq4sUmsEFll8fBp28x8GXLtLo',
    id: 2415,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ncut.edu.cn',
    name: 'North China University of Technology',
    web_page: 'http://www.ncut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWvOaV1oke8eDDy_vuBlmOnrcPgcSRNw4kvCh9hHaFETAqayvDGkgT5T6B',
    id: 2416,
    acronym: 'NCUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'neau.edu.cn',
    name: 'Northeast Agricultural University',
    web_page: 'http://www.neau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS12ihy6ynArRFnADEe1SpgDqvqe_hUz-n0CIjGTrwIqgSR-14DseLFS5w',
    id: 2417,
    acronym: 'NAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nefu.edu.cn',
    name: 'Northeast Forest University',
    web_page: 'http://www.nefu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqOtfxiDI95_UFjQemvppfCY_2X81r7PauSL4-k_fHOxOLgHgkm4PJEekt0A',
    id: 2418,
    acronym: 'NFU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'neijtc.edu.cn',
    name: 'Neijiang Teacher University',
    web_page: 'http://www.neijtc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTzo5niC7yqVIBsXVHy6ZZSIM-6n1uW2VEK9p3CKnrPs5JgRgkBvx9jQ',
    id: 2419,
    acronym: 'NTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nenu.edu.cn',
    name: 'Northeast Normal University',
    web_page: 'http://www.nenu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlnDl3bRhb0RtlLBagELP-N4fanwJe5Umd2h5chYcnhcFCbEYvQcrDwg',
    id: 2420,
    acronym: 'NNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'neuq.edu.cn',
    name: 'Northeast University at Qinhuangdao Campus',
    web_page: 'http://www.neuq.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS-TXGRv9n_CoJkHBerMURz3ZsCaz1pZuUZ8aPL6-m3tphvlaCo1fksPs',
    id: 2422,
    acronym: 'NUAQC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'newasia.bj.edu.cn',
    name: 'Beijing New Asia University',
    web_page: 'http://www.newasia.bj.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuZginlHDZOg77KinfQgDmAxcX7tLx-Ye1MHdjagrJB8Qq9FVjK3ZIDLTe',
    id: 2423,
    acronym: 'BNAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njau.edu.cn',
    name: 'Nanjing Agricultural University',
    web_page: 'http://www.njau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSgywLT86SzXKsCalfANC-lbotLWJ6XJ-xRNyVuezaH1dIn5fBcDI4V9o',
    id: 2424,
    acronym: 'NAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njfu.edu.cn',
    name: 'Nanjing Forestry University',
    web_page: 'http://www.njfu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQolbSZXFhsP5Hs2jj1SOHH_hQ3IgAQw2V3ZlMKQFgmVLV-NPwUYCH-1L8',
    id: 2425,
    acronym: 'NFU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njim.edu.cn',
    name: 'Nanjing Institute of Meteorology',
    web_page: 'http://www.njim.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTweC2T-y2MSkUJuw-S15KLwD_S8fibLAGUkYVIb9UzxXeHfsvnT-_ntg',
    id: 2426,
    acronym: 'NIM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njmu.edu.cn',
    name: 'Nanjing Medical University',
    web_page: 'http://www.njmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqUBTLKRihV2KiXnBmbRpEFJkA3P9whD-p3K0KGSvRCJopdHNoO1VVIl0',
    id: 2427,
    acronym: 'NMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njnu.edu.cn',
    name: 'Nanjing Normal University',
    web_page: 'http://www.njnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7d6um9P8JSsel-F0MhxbzXtu0DawpbjrM6-PTGPDxv97cCiBVDW0Os6U',
    id: 2428,
    acronym: 'NNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njtu.edu.cn',
    name: 'Northern Jiaotong University',
    web_page: 'http://www.njtu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2429,
    acronym: 'NJU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njuct.edu.cn',
    name: 'Nanjing University of Chemical Technology',
    web_page: 'http://www.njuct.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLDqFaxTj0m0e-f1jcrSsM6721Qof4tCgQgdY3oKpFcJWPNe5jDLCCRKry',
    id: 2430,
    acronym: 'NUCT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nju.edu.cn',
    name: 'Nanjing University',
    web_page: 'http://www.nju.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQUA6OqXXSsmZ4FJd4HJriUyiWyWeGZgHfWQt4jmKPvzU5EHSGWcg7DSg',
    id: 2431,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njue.edu.cn',
    name: 'Nanjing University of Economics',
    web_page: 'http://www.njue.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5nsD3XFH2cLlrOfosGFwac_doOZoZUXsMGFY1CVxKz7hDw8aw6wEQHg',
    id: 2432,
    acronym: 'NUE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njupt.edu.cn',
    name: 'Nanjing University of Posts and Telecommunications',
    web_page: 'http://www.njupt.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH8UbJv3nba52vgUqHR-mphvoOp0fbvzJwFuh9nNX-Ik3JEfXSoFuhq04',
    id: 2433,
    acronym: 'NUPT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njust.edu.cn',
    name: 'Nanjing University of Science and Technology',
    web_page: 'http://www.njust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKqmKBZ3qhTGVlxQXHDKGwJ6VQtKph13hQWVVb3SDhc374kvjAwUQbLw',
    id: 2434,
    acronym: 'NUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njutcm.edu.cn',
    name: 'Nanjing University of Traditional Chinese Medicine',
    web_page: 'http://www.njutcm.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOvWXMHuxWPysjs5jL-gEObzS2-6vPGEe7xh28O3FieBnEfTxdk_XD5cNr',
    id: 2435,
    acronym: 'NUTCM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'njuts.cn',
    name: 'Nanjing Union Theological Seminary',
    web_page: 'http://www.njuts.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS47d4bLfuYnlSZpDYses6yDBsqF2TYIRymMgbAzLNcKhnnSF5UWqrMvM',
    id: 2436,
    acronym: 'NUTS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nku.nankai.edu.cn',
    name: 'Nankai University',
    web_page: 'http://nku.nankai.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxWh3MHIP-3_iWu69Czs-JeuwbZDV3XW5soj2V1pfphk9Um6sKCZ_GxxM',
    id: 2437,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nottingham.edu.cn',
    name: 'The University of Nottingham Ningbo China',
    web_page: 'http://www.nottingham.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxJhic-IBB5P0s69YtdXP_87U3KScWRRSwLjTqbwJFtrASTQ5YSKq34A',
    id: 2438,
    acronym: 'UNNC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nsmc.edu.cn',
    name: 'Northern Sichuan Medical College',
    web_page: 'http://www.nsmc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0t5v_Got0dxkKthyVPl2Kv6mNMOUTg8XYB15HJl_zkq2gtEVz4LodNec',
    id: 2439,
    acronym: 'NSMC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nuaa.edu.cn',
    name: 'Nanjing University of Aeronautics and Astronautics',
    web_page: 'http://www.nuaa.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxs8Ep2dLVJPVmI8gwn4G9Bu51f5JGuPSqfkr4S4VqFbnxJAOPWAL-NAo',
    id: 2440,
    acronym: 'NUAA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nudt.edu.cn',
    name: 'National University of Defense Technology',
    web_page: 'http://www.nudt.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnRCBOi8h3nhPP4Xd5HeLaRi7kUOMm8LB3ZnpnmkVchcRBTVcOI4hzNg',
    id: 2441,
    acronym: 'NUDT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nwau.edu.cn',
    name: 'Northwest A&F University',
    web_page: 'http://www.nwau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNCbbtjlg0S64nWddzYAlpy4PffuG78rYHm84EJ29lE9D2ngi_odYhOjg',
    id: 2442,
    acronym: 'NAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nwnu.edu.cn',
    name: 'Northwest Normal University Lanzhou',
    web_page: 'http://www.nwnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd2opZcD_CqcJQl2SwFAmVjeo2_BoRmo0RNGQN7RxleUbXvviwqdJlZg',
    id: 2443,
    acronym: 'NNUL',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nwpu.edu.cn',
    name: "Northwest Polytechnical University Xi'an",
    web_page: 'http://www.nwpu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL52EzSTPZREiMVWXW3QFTgDkwioWGvZYdAGXelmHKjLZ9EgtwEsqgDII',
    id: 2444,
    acronym: 'NPUX',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nwu.edu.cn',
    name: "Northwest University Xi'an",
    web_page: 'http://www.nwu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiild3SdvImUclj4AWOHwDwnzOcA62AcX3PW9pJTpHC2OBjlOlhN--f_G7ww',
    id: 2445,
    acronym: 'NUX',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nxmc.edu.cn',
    name: 'Ningxia Medical College',
    web_page: 'http://www.nxmc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkWc5ATwALSSzCBsuG4MFqNekueEe_F1lFjXWfV0WfMoUlXo4QoHhznbhf',
    id: 2446,
    acronym: 'NMC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'nxu.edu.cn',
    name: 'Ningxia University',
    web_page: 'http://www.nxu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKG-7jrr1UMgbhY3Kx5Ifu36qCM7JMeKexqcV1MtRRuYdqOZ0WFNnrGcQ',
    id: 2447,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ouc.edu.cn',
    name: 'Ocean University of China',
    web_page: 'http://www.ouc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQep_DLpp1Fl61mCh-fDdBmCQqJZmsQIrKoHGaySAuvysG_tX5MEMzmTcY',
    id: 2448,
    acronym: 'OUC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'peizheng.com.cn',
    name: 'Guangdong Peizheng College',
    web_page: 'http://www.peizheng.com.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 2449,
    acronym: 'GPC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'pku.edu.cn',
    name: 'Peking University',
    web_page: 'http://www.pku.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN5dAUC82b7xVl8NQv2XIb6xoz8scQjmAjfdD8EMB_eupYFdQUy7YYjW4',
    id: 2450,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'pzhu.edu.cn',
    name: 'Panzhihua University',
    web_page: 'http://www.pzhu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlQVD2Tys9YSfs8akl-_zIC7Se8WTp2gihscEnvEbJnaNaWJ7iX3-HBA',
    id: 2451,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'qdu.edu.cn',
    name: 'Qingdao University',
    web_page: 'http://www.qdu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQb-PtmxZQskjl1W0UUq2EZydafElj0D-sN6Vddq2wO2XiGgwrLJeLT9qY',
    id: 2452,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'qfnu.edu.cn',
    name: 'Qufu Normal University',
    web_page: 'http://www.qfnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP05b97hus3Hj_Stv6_JjOUTzeHa2VuZf66EB898LC7aQOyuH1zCkjXYw',
    id: 2453,
    acronym: 'QNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'qhnu.edu.cn',
    name: 'Qinghai Normal University',
    web_page: 'http://www.qhnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl1ceKppnOEdYrqe_coTv2qvsH8x1aS3NOS3LjYmMDUYBN_MNKpE_rqXE',
    id: 2454,
    acronym: 'QNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'qhrtvu.edu.cn',
    name: 'Qinghai Radio & Television University',
    web_page: 'http://www.qhrtvu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl1ceKppnOEdYrqe_coTv2qvsH8x1aS3NOS3LjYmMDUYBN_MNKpE_rqXE',
    id: 2455,
    acronym: 'QR&TU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'qhu.edu.cn',
    name: 'Qinghai University',
    web_page: 'http://www.qhu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl1ceKppnOEdYrqe_coTv2qvsH8x1aS3NOS3LjYmMDUYBN_MNKpE_rqXE',
    id: 2456,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'qust.edu.cn',
    name: 'Qingdao University of Science and Technology',
    web_page: 'http://www.qust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQb-PtmxZQskjl1W0UUq2EZydafElj0D-sN6Vddq2wO2XiGgwrLJeLT9qY',
    id: 2457,
    acronym: 'QUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'qzu.edu.cn',
    name: 'Qiongzhou University',
    web_page: 'http://www.qzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMrlpFXq9pMP4RfT1EkCiWplLdd8pIh5nVBTiDJ9w5WSdlbxdOw8F3D6Y',
    id: 2458,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ruc.edu.cn',
    name: 'Renmin University of China',
    web_page: 'http://www.ruc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCZCok9LGCNsEtpzU_m2cMe2uCRqQqpParDrgisOkieyAFoIfKRLXNKA',
    id: 2459,
    acronym: 'RUC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sandau.edu.cn',
    name: 'Shanghai Sanda University',
    web_page: 'http://www.sandau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcC8idxfM-b5n8t15f-znIqW6CXpc-Qsnr2V_K0PqGIRGgjXivspyUYg',
    id: 2460,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sanxiau.edu.cn',
    name: 'Chongqing Three Gorges University',
    web_page: 'http://www.sanxiau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOtDc-QyWByXpi4sq7931odE3KbGXCJqSgx3rGC3AmTJH2Wne4FY75mmU',
    id: 2461,
    acronym: 'CTGU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sbs.edu.cn',
    name: 'Shanghai Business School',
    web_page: 'http://www.sbs.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQdFQ4dgQKyfkkefnLpTR-6TIp3ZZMLQ2PCr2f1ULV3xhop1Aid-qsfh0',
    id: 2462,
    acronym: 'SBS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'scau.edu.cn',
    name: 'South China Agricultural University',
    web_page: 'http://www.scau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzVFtD9jylHA970ixHmMOCvWKO-LdxHcCdia_pKSGsm0LWQhm2YV6iBA',
    id: 2463,
    acronym: 'SCAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sccu.edu.cn',
    name: 'South China Construction University',
    web_page: 'http://www.sccu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3Fz-acp4NDYkRrneyZoVDq25uOYBzZo5D-X1fkJxOkoyLvHEYGa0gCSkY',
    id: 2464,
    acronym: 'SCCU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'scnu.edu.cn',
    name: 'South China Normal University',
    web_page: 'http://www.scnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQah1taWp_3kmRAUFfvUvhBkfBC8Ld-f4oSM5moLp_7IGSI-EfazIh57LbL',
    id: 2465,
    acronym: 'SCNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'scu.edu.cn',
    name: 'Sichuan University',
    web_page: 'http://www.scu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6CpvlZhSSJY0IpYr207AEZ5Dvn0DPG4mwZyeafo8K4JRlRE59IYL3LeI',
    id: 2466,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'scut.edu.cn',
    name: 'South China University of Technology',
    web_page: 'http://www.scut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvO-yHZR56bCrBKxAMv4zTT3kp1BPQcEbRTaY0nKYAa1WvxVo9OSvstQ',
    id: 2467,
    acronym: 'SCUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdada.edu.cn',
    name: 'Shandong University of Art and Design',
    web_page: 'http://www.sdada.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQo9ciZFMNbjrAbI9UedEOPjWw-WSDE9mWSmzm7UL6QQzVoVv6ZUrGe4Q',
    id: 2468,
    acronym: 'SUAD',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdau.edu.cn',
    name: 'Shandong Agricultural University',
    web_page: 'http://www.sdau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFwQEOJowBr7Cgpw8Selp7iAfH1INixWT-v3sdgwiQrrLsqkHbnaTRjA',
    id: 2469,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdie.edu.cn',
    name: 'Shandong Economic University',
    web_page: 'http://www.sdie.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_KKGBGFtkkSdL7H7srWNCPwM-SfZyMIlIxmqKkO-GQYNlJPIQDxZAtP8',
    id: 2470,
    acronym: 'SEU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdju.edu.cn',
    name: 'Shanghai Dainji University',
    web_page: 'http://www.sdju.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6NAMmljUmRA37EBHU91tfRtqPHvvGmKIkfuM2SRoUuui9gSdDKk1H7xo',
    id: 2471,
    acronym: 'SDU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdmu.edu.cn',
    name: 'Shandong Medical University',
    web_page: 'http://www.sdmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDKrZ056foOXT_XuBw67OZngL89futFKQtm0_OpIFRTshdSluWVfz5ng',
    id: 2472,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdnu.edu.cn',
    name: 'Shandong Normal University',
    web_page: 'http://www.sdnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaiwwCgRLz0meS41nlh0xEKFbLm9SpKWvWMD6ksE_vp_I5QaipvyTVdKD8',
    id: 2473,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdu.edu.cn',
    name: 'Shandong University',
    web_page: 'http://www.sdu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqUOSIY1gvBqFrDOPWWTFnRf8WcJ5J4-xDKZB1XJCCb6NbfW0mhWNM8w',
    id: 2474,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdust.edu.cn',
    name: 'Shandong University of Science and Technology',
    web_page: 'http://www.sdust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYQ44saQqbCrjZulXZY2JWfrX_0N6SQqCIgu-NxEXxBybDe1QtPO-BQKKyig',
    id: 2475,
    acronym: 'SUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdutcm.edu.cn',
    name: 'Shandong University of Triaditional Chinese Medicine',
    web_page: 'http://www.sdutcm.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDKrZ056foOXT_XuBw67OZngL89futFKQtm0_OpIFRTshdSluWVfz5ng',
    id: 2476,
    acronym: 'SUTCM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sdut.edu.cn',
    name: 'Shandong University of Technology',
    web_page: 'http://www.sdut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXJPObxljU4OiZUQd6Ju5Z3DphFJAMqPtK4Nk9WW_gKGL0lIR-Duf4w1k',
    id: 2477,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sgu.edu.cn',
    name: 'Shaoguan University',
    web_page: 'http://www.sgu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKUiIpnQtw3nX-zrU0wD8SGtiaPyBUy0tSdIqHGKXo6PQY0I0hkKg_Fg',
    id: 2479,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shanghai_edu.customs.gov.cn',
    name: 'Shanghai Customs College',
    web_page: 'http://shanghai_edu.customs.gov.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdXkX2yxjxqsb7XuJm-2N2lRTxpnuf_8HoD0kWLIy0IR0dTdcax44UJgs',
    id: 2480,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shfu.edu.cn',
    name: 'Shanghai Fisheries University',
    web_page: 'http://www.shfu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgOxeBoCR5RXZffjHeWesVp4IPpRGDLlzLH0k654tdnToiDrWyah0UO7wL',
    id: 2481,
    acronym: 'SFU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shift.edu.cn',
    name: 'Shanghai Institue of Foreign Trade',
    web_page: 'http://www.shift.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2w6CK5WmTKk3NSDizSn-Fcn-HkYc96OAbvLOUYugo5Ra8ms-7iNNsW_I',
    id: 2482,
    acronym: 'SIFT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shisu.edu.cn',
    name: 'Shanghai International Studies University',
    web_page: 'http://www.shisu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf0810cZ2R1vEzZ9dAC3MwW7v-yNi9PH2aLCeczbz1DDxcy977l-wQ_VY',
    id: 2483,
    acronym: 'SISU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shmtu.edu.cn',
    name: 'Shanghai Maritime University',
    web_page: 'http://www.shmtu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF_sx3x-hGS8UpdNLD9TfyENIfSEhSo6fEoBH4fiCNfEOr4Oine5WI9cn6',
    id: 2484,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shmu.edu.cn',
    name: 'Shanghai Medical University',
    web_page: 'http://www.shmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfPqrdnW1fE9gD9u8IrIOPGKJ7UAf7QZf-93k3jmY0o9FvBpWBIXdI6A',
    id: 2485,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shouhua.net.cn',
    name: 'Shanghai Ouhua Vocational Technical College',
    web_page: 'http://www.shouhua.net.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc0--jTeoibTYRQXPyqNXX3cMx61pjCX9Vi9SQz1-RO_fFqsRb_s4YhvrJ',
    id: 2486,
    acronym: 'SOVTC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sh-sipopolytechnic.com',
    name: 'Shanghai Sipo Polytechnic',
    web_page: 'http://www.sh-sipopolytechnic.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 2487,
    acronym: 'SSP',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shsmu.edu.cn',
    name: 'Shanghai Second Medical University',
    web_page: 'http://www.shsmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2488,
    acronym: 'SSMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shspu.edu.cn',
    name: 'Shanghai Second Polytechnic University',
    web_page: 'http://www.shspu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvFDSvvCYY5RyCusYMdPoO6Hi3XPpeOzJsZ6h2qYXlDHkyrTKMqvo1QngJ',
    id: 2489,
    acronym: 'SSPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shtdu.edu.cn',
    name: 'Shanghai TieDao University',
    web_page: 'http://www.shtdu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_BQuHJPRMwrItbmqRUsS4xe3YBKQwv4C9wQk8vH0U7MRN6uHdt2e-OaOK',
    id: 2490,
    acronym: 'STU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shtu.edu.cn',
    name: 'Shanghai Normal University',
    web_page: 'http://www.shtu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXOGDaAWxQzA5I2Z2Q37DdavghJIXfrj6U1YOSmT-kzgHtFuHmzwfsBWg',
    id: 2491,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shtvu.edu.cn',
    name: 'Shanghai Television University',
    web_page: 'http://www.shtvu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXb-TOGcW3ITqc5vt8iRX680QfxILH7fY9slMfNy6E4vZIvmigVEf0AnI',
    id: 2492,
    acronym: 'STU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shu.edu.cn',
    name: 'Shanghai University',
    web_page: 'http://www.shu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRajLBeXZRNg_uIhB8ufilUl7zigmVIVI_T-kfLNSxLuNBRBB9gGEjAZw',
    id: 2493,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shufe.edu.cn',
    name: 'Shanghai University of Finance and Economics',
    web_page: 'http://www.shufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOtUC_Ax-3_3w-le8FwYNwkYvlYZbhKYA6LkuwWUl5Vq1RUkY-wyxCAzc',
    id: 2494,
    acronym: 'SUFE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shutcm.edu.cn',
    name: 'Shanghai University of Traditional Chinese Medicine and Pharmacology',
    web_page: 'http://www.shutcm.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfPqrdnW1fE9gD9u8IrIOPGKJ7UAf7QZf-93k3jmY0o9FvBpWBIXdI6A',
    id: 2495,
    acronym: 'SUTCMP',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'shzu.edu.cn',
    name: 'Shihezi University',
    web_page: 'http://www.shzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs6Hbyz-nkq060o-3YngJxB-BmrnR2T7ABBY3A5t2niryn7j6GM-2r9DI',
    id: 2496,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sicau.edu.cn',
    name: 'Sichuan Agricultural University',
    web_page: 'http://www.sicau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNTk_6vx2dPch3FRtQjEgaqSP8R_ShjjqUalDxEx01dEdNkUF6-uc7luw',
    id: 2497,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sicnu.edu.cn',
    name: 'Sichuan Normal University',
    web_page: 'http://www.sicnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaU_uJC7AV8hqNJkrBqbgwnJ97JezCHxWMIT69F4vV2Gq9xW9qYOXJtY0',
    id: 2498,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sisu.edu.cn',
    name: 'Sichuan International Studies University',
    web_page: 'http://www.sisu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf0810cZ2R1vEzZ9dAC3MwW7v-yNi9PH2aLCeczbz1DDxcy977l-wQ_VY',
    id: 2499,
    acronym: 'SISU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sjtu.edu.cn',
    name: 'Shanghai Jiaotong University',
    web_page: 'http://www.sjtu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2500,
    acronym: 'SJU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sjzu.edu.cn',
    name: 'Shenyang Jianzhu University',
    web_page: 'http://www.sjzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkMYkyKJ9KlOFIeF0D9-j66_vBwEaFMB6gm4u-REHGghJZc-bWMPbsizw',
    id: 2501,
    acronym: 'SJU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'smmu.edu.cn',
    name: '2nd Military Medical University',
    web_page: 'http://www.smmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2502,
    acronym: '2MMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'snnu.edu.cn',
    name: 'Shaanxi Normal University',
    web_page: 'http://www.snnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcAOGwc3XiMxpFW6g_nXZ0MhLEbPwbBjFA_5PNYGITd-77wkxq3TuVJeY',
    id: 2503,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'snnu.edu.cn',
    name: 'Shanxi Normal University',
    web_page: 'http://www.snnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcAOGwc3XiMxpFW6g_nXZ0MhLEbPwbBjFA_5PNYGITd-77wkxq3TuVJeY',
    id: 2504,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'stu.edu.cn',
    name: 'Shantou University',
    web_page: 'http://www.stu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA2PZH1mVBMN_EpdakEf6i443uis3MPXSrvOqqGLwH6tA7Pd1TueXO6Q',
    id: 2505,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'suda.edu.cn',
    name: 'Suzhou University',
    web_page: 'http://www.suda.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpQAK4XIaJLFmo-Fzqaa5_vI6FsYfBMDQORbZqk7AF00Hg7Q2Zzq3c7ho',
    id: 2506,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sues.edu.cn',
    name: 'Shanghai University of Engineering Science',
    web_page: 'http://www.sues.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRajLBeXZRNg_uIhB8ufilUl7zigmVIVI_T-kfLNSxLuNBRBB9gGEjAZw',
    id: 2507,
    acronym: 'SUES',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sustc.edu.cn',
    name: 'South University of Science and Technology of China',
    web_page: 'http://www.sustc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8Sn2w5vf3oNagfWyhv3PN4OBWJC5KnYraoK80f7ofHOLPrZ2FhI9DHO2y',
    id: 2508,
    acronym: 'SUSTC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swau.edu.cn',
    name: 'Southwest Agricultural University',
    web_page: 'http://www.swau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB2wxCSPKugA706PDMrC-H-DDyRlhZj2KTrFQraJROqCGB2_3HYyoDuG8E',
    id: 2509,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swfc.edu.cn',
    name: 'Southwest Forestry University',
    web_page: 'http://www.swfc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2510,
    acronym: 'SFU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swjtu.edu.cn',
    name: 'Southwest Jiaotong University',
    web_page: 'http://www.swjtu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2511,
    acronym: 'SJU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swnu.edu.cn',
    name: 'Southwest University',
    web_page: 'http://www.swnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYLNd88AY88xdng6tcl0YWZzef4ZAX97NtEeucQCIJkwNA2JNv1tnkiQ',
    id: 2512,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swpu.edu.cn',
    name: 'Southwest Petroleum University',
    web_page: 'http://www.swpu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB2wxCSPKugA706PDMrC-H-DDyRlhZj2KTrFQraJROqCGB2_3HYyoDuG8E',
    id: 2513,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swsm.cn',
    name: 'Chongqing Normal University Foreign Trade and Business College',
    web_page: 'http://www.swsm.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTZ7AhbfXD9KZGAT_zuy1zGU99dm6NwBuNLgTr4AiYqOQPzO3JngxpiWY',
    id: 2514,
    acronym: 'CNUFTBC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swufe.edu.cn',
    name: 'Southwest University of Finance and Economics',
    web_page: 'http://www.swufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEf2ZrxC60Hshduee4Uqy1W4awiTUe130qz8xPSVIwntKbzx0oMIjbDQ',
    id: 2515,
    acronym: 'SUFE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swun.edu.cn',
    name: 'Southwest University of Nationalities',
    web_page: 'http://www.swun.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMROXjHCDb86T0SHjckyZUBYI4xywg6G57jBXycNAzuxQWGVEciRHhpeI',
    id: 2516,
    acronym: 'SUN',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swupl.edu.cn',
    name: 'Southwest University of Political Science and Law',
    web_page: 'http://www.swupl.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIcG7zGqmAmhn0n_7CV9soAMVwDEgDBJv5Tiz9CHZmTYCKlQdGEUO-nw',
    id: 2517,
    acronym: 'SUPSL',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swust.edu.cn',
    name: 'Southwest University of Science and Technology',
    web_page: 'http://www.swust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX0781oxqMHeWK_iCcpCxlHbVAshNW_T2u3myqnx510cL95QvXPyHthuk',
    id: 2518,
    acronym: 'SUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'swuyc.edu.cn',
    name: 'South West University Yucai College',
    web_page: 'http://www.swuyc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt9-4NwqxkDfCcgzC8uO91Gr9b8YaYDQbCVluiMigEqJqO8Cn4XzuE1w',
    id: 2519,
    acronym: 'SWUYC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sxau.edu.cn',
    name: 'Shanxi Agricultural University',
    web_page: 'http://www.sxau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTIpQRGgfre2hvOfUzdtvWDC0myfhxMV2I73kYsAtAnNoYAXNRs3hB4oE',
    id: 2520,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sxu.edu.cn',
    name: 'Shanxi University',
    web_page: 'http://www.sxu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTIpQRGgfre2hvOfUzdtvWDC0myfhxMV2I73kYsAtAnNoYAXNRs3hB4oE',
    id: 2521,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'syict.edu.cn',
    name: 'Shenyang Institute of Chemical Technology',
    web_page: 'http://www.syict.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzsFKm4z7ktf82JK85cbRGfz2gOwBIcIU6kEzOuE3oFzZDxO9pKKTYOpM',
    id: 2522,
    acronym: 'SICT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'syphu.edu.cn',
    name: 'Shenyang Pharmaceutical University',
    web_page: 'http://www.syphu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8pH4eqhR5oPdxA03c9c9n0DI5DlSchiDcL5Idhe68Adl-cslOyzphrfI',
    id: 2523,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sypu.edu.cn',
    name: 'Shenyang Polytechnic University',
    web_page: 'http://www.sypu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQz3JwsayB3qhqUVohkOEWX3DUyfUrVZXvkD8hJx8Sj4E-9l94pyZdNqaA',
    id: 2524,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'sytu.edu.cn',
    name: 'Southern Yangtze University',
    web_page: 'http://www.sytu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-fDFBq3D_AX3FngigROPef0pBK3NTCsXtWh8e51eMxdKnCaNPNZW74w',
    id: 2525,
    acronym: 'SYU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'syu.edu.cn',
    name: 'Shenyang University',
    web_page: 'http://www.syu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPwUWm0NocwI85NSOzoOBS9J_Ign0sR51NfWfGsxCUmVHEs_fDjckvu5s',
    id: 2526,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'szu.edu.cn',
    name: 'Shenzhen University',
    web_page: 'http://www.szu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQRI24_TAoYUUmz6qOU6oLeI2roWe0XMAcU7yjz-X-UG2c80-F_oc_Qw',
    id: 2527,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tijmu.edu.cn',
    name: 'Tianjin Medical University',
    web_page: 'http://www.tijmu.edu.cn/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcS1B9-2i0bXx7w4BYY7_j9i5ihYzNfl4O88m2XErXE7uq6v1003q1ZySEk',
    id: 2528,
    acronym: 'TMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tjarts.edu.cn',
    name: 'Tianjin Academy of Fine Art',
    web_page: 'http://www.tjarts.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGUGW05SuRRPKZb4tTit0qhGAE0shMdIiJH_144kdaTdztJPmyZrmCuQ',
    id: 2529,
    acronym: 'TAFA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tjcu.edu.cn',
    name: 'Tianjin University of Commerce',
    web_page: 'http://www.tjcu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSemXjB7MIrY_wULoQ6yaMfPxzl0Ko_uxeO8u8t_4L-1OBWzqHNsaldg3hb',
    id: 2530,
    acronym: 'TUC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tjmu.edu.cn',
    name: 'Tongji Medical University',
    web_page: 'http://www.tjmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6hFvUW9Tvnh0XIOFL3KuE3VsxnCa_aqBP5CILZZ16o2mwkNIPe-AHHUE',
    id: 2531,
    acronym: 'TMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tjnu.edu.cn',
    name: 'Tianjin Normal University',
    web_page: 'http://www.tjnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlKv1pbSAQHfEA0uccBv0hsj4klhfCVIua8TZ8VgkVd9DBEqjvJlcXhzY',
    id: 2532,
    acronym: 'TNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tjpu.edu.cn',
    name: 'Tianjin Polytechnic University',
    web_page: 'http://www.tjpu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaZy7I1-4k11SRiABZt5D3yzPcZQnYPQagbVJAiRHDtMBs8Ow5rYBZtVM',
    id: 2533,
    acronym: 'TPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tjrtvu.edu.cn',
    name: 'Tianjin Open University',
    web_page: 'http://www.tjrtvu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwHFeto1mSt6rqdtcHu20egXTMBcTywnF-r9H9NqO1GStckIyJAIM7KAQA5w',
    id: 2534,
    acronym: 'TOU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tju.edu.cn',
    name: 'Tianjin University',
    web_page: 'http://www.tju.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0OYIe9QDFa2kpFcvrptqfLyHHu7lZIGoJRZY7nWk7QI8oUsH5BzQwugo',
    id: 2535,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tjufe.edu.cn',
    name: 'Tianjin University of Finance & Economics',
    web_page: 'http://www.tjufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStqUdrLIWG3vhHce5LzrXtrFJ4wvI0bpHE02BxsP4q42GoNguSB2TuUig',
    id: 2536,
    acronym: 'TUF&E',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tjut.edu.cn',
    name: 'Tianjin University of Technology',
    web_page: 'http://www.tjut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPp4cjnOCookmRqbBBHDVdZzzLJ1ZjbQKOONQO0xv87WpZZyZrefVl0qc',
    id: 2537,
    acronym: 'TUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tmmu.edu.cn',
    name: '3rd Military Medical University',
    web_page: 'http://www.tmmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX6eK3GRhdv1QknFGDHxDbgX4Nn2qDzigwDFJ7mwxMNogyKhlB1IkLA4En1w',
    id: 2538,
    acronym: '3MMU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tongji.edu.cn',
    name: 'Tongji University',
    web_page: 'http://www.tongji.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOIAVTF4dZtbf58OFVU88Mcg6h9uWTcnhGKT8tGlHEcpkDE3NVfHLO1A',
    id: 2539,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tsinghua.edu.cn',
    name: 'Tsinghua University',
    web_page: 'http://www.tsinghua.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV7Ol0N3jGxrfAhhVxZqYco1dzYP7vHiUSALrC_2xH2JL0Ag2MyXPdxbY',
    id: 2540,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ttmc.edu.cn',
    name: 'Tibet Tibetan Medical College',
    web_page: 'http://www.ttmc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX06fUuhXWqlSDiLfuD9W3EeJv0HjE-3oqoI1H-fh3y1yX2xFeDC_ya4XmcA',
    id: 2541,
    acronym: 'TTMC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tyut.edu.cn',
    name: 'Taiyuan University of Technology',
    web_page: 'http://www.tyut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3ngmssoZMCfBGlObWygGagxK7gcIYlJCe2JtGmbqolFOTI2d5v3BTHg',
    id: 2542,
    acronym: 'TUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'tzc.edu.cn',
    name: 'Taizhou College',
    web_page: 'http://www.tzc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmk3j46oczNrE5nCuLZ0XNDgSrnSBN-aBk_05jJKN9rj7UYmtNZDjdgwiP',
    id: 2543,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'uestc.edu.cn',
    name: 'University of Electronic Science and Technology of China',
    web_page: 'http://www.uestc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9lTUoj9MKQQjZ_taOCBVCvlLYuV79fbm4PZ5YOa0U9Q33s1ki1JVqRrE',
    id: 2544,
    acronym: 'UESTC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'uheeyc.edu.cn',
    name: 'University of Hydraulic Electric Engineering',
    web_page: 'http://www.uheeyc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmBKopiaZsnkvd3qi-AdwPKSHyI0D9p4NTTi0NcWrOFg1t_drKuGujrA',
    id: 2545,
    acronym: 'UHEE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'uibe.edu.cn',
    name: 'University of International Business and Economics',
    web_page: 'http://www.uibe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqLJFhsHyf7npj9E7nWvZnvd252qohDBGqYkNhZjTM2Uyp2J5UpCx6UIA',
    id: 2546,
    acronym: 'UIBE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ujn.edu.cn',
    name: 'University of Jinan',
    web_page: 'http://www.ujn.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWsYBhaXq8OkcqFi1UN1TGsGAyG9ruAguZcIRe9oUX7jReuRyKPuoR5QmT',
    id: 2547,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'umcollege.com',
    name: 'Shanghai City College',
    web_page: 'http://www.umcollege.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGgj93hcthxxP0tKyIzKv2NdS3EpTJPjsdRSPmgspKSvAcPgPU5Y-jkw',
    id: 2548,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'usc.edu.cn',
    name: 'South China University',
    web_page: 'http://www.usc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvO-yHZR56bCrBKxAMv4zTT3kp1BPQcEbRTaY0nKYAa1WvxVo9OSvstQ',
    id: 2549,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'usst.edu.cn',
    name: 'Shanghai University of Science and Technology',
    web_page: 'http://www.usst.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9lTUoj9MKQQjZ_taOCBVCvlLYuV79fbm4PZ5YOa0U9Q33s1ki1JVqRrE',
    id: 2550,
    acronym: 'SUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ustb.edu.cn',
    name: 'Beijing University of Science and Technology',
    web_page: 'http://www.ustb.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzYutMauMUcMgKftYgnbioAbS1Aiqg_Mh3juA0Rc1mTYeH-SY11xkwzQ',
    id: 2551,
    acronym: 'BUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ustb.edu.cn',
    name: 'University of Science and Technology Beijing',
    web_page: 'http://www.ustb.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzYutMauMUcMgKftYgnbioAbS1Aiqg_Mh3juA0Rc1mTYeH-SY11xkwzQ',
    id: 2552,
    acronym: 'USTB',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ustc.edu.cn',
    name: 'University of Science and Technology of China',
    web_page: 'http://www.ustc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9lTUoj9MKQQjZ_taOCBVCvlLYuV79fbm4PZ5YOa0U9Q33s1ki1JVqRrE',
    id: 2553,
    acronym: 'USTC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'utibet.edu.cn',
    name: 'Tibet University',
    web_page: 'http://www.utibet.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHrWfb7hw6RefpHklPLLPrZJwZ29WwsYvcSzzXRxm5DVlQTyksjeBACVk',
    id: 2554,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'uvu.edu.cn',
    name: 'Urumqi Vocational University',
    web_page: 'http://www.uvu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqDi8I2rJCuen0wYQUsNhkkJNGh5ouVpMO0ouQNaLM--aONSewlAxnbPw',
    id: 2555,
    acronym: 'UVU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wcums.edu.cn',
    name: 'West China University of Medical Sciences',
    web_page: 'http://www.wcums.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8Sn2w5vf3oNagfWyhv3PN4OBWJC5KnYraoK80f7ofHOLPrZ2FhI9DHO2y',
    id: 2556,
    acronym: 'WCUMS',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wdsdjxy.com',
    name: 'Wudanshan Taoist College',
    web_page: 'http://www.wdsdjxy.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL7dvL-cp1aDxgZqC5zrOa8oBA2JNUYiJ2Tco3mow9-MAyFSB-4A-BIA',
    id: 2557,
    acronym: 'WTC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'whapu.edu.cn',
    name: 'Wuhan Automobile Polytechnical University',
    web_page: 'http://www.whapu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0RhFUUut9JGmpqy19VSyuuM2QqSzGQdX2gf_cqsAGvQ33p93M_qf9dQ',
    id: 2558,
    acronym: 'WAPU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'whtu.edu.cn',
    name: 'Wuhan Transportation University',
    web_page: 'http://www.whtu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4ucZQLJR5oZSFP520w1CIGFI6vIfFW8u4lGH87j0lcwZYlt05VZ6k5mnNSw',
    id: 2559,
    acronym: 'WTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'whu.edu.cn',
    name: 'Wuhan University',
    web_page: 'http://www.whu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWUgYGXl1JIBYsh5QlAQG8askCI0wwzR-FmxUCertngGm7mW9tBBwUrA',
    id: 2560,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'whut.edu.cn',
    name: 'Wuhan University of Technology',
    web_page: 'http://www.whut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7AHzIlojn5goZRFw5YT_s29ljtagAnge899DVb8ZWXSHQ83bsqcVwHxk',
    id: 2561,
    acronym: 'WUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wsm.whu.edu.cn',
    name: 'Wuhan University School of Medicine',
    web_page: 'http://wsm.whu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWUgYGXl1JIBYsh5QlAQG8askCI0wwzR-FmxUCertngGm7mW9tBBwUrA',
    id: 2562,
    acronym: 'WUSM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wtusm.edu.cn',
    name: 'Wuhan Technical University of Surveying and Mapping',
    web_page: 'http://www.wtusm.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTAVgJyoXjUdzKFObMDMvpdAnb_drp4nl1Pvs3xfawxsgZKP0je-27PWI',
    id: 2563,
    acronym: 'WTUSM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wuhee.edu.cn',
    name: 'Wuhan University of Hydraulic and Electric Engineering',
    web_page: 'http://www.wuhee.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBBXPw0JrnLkFw0PZODg4GGUBT-5j26ZdMgxb0qfzvsX7QAyE_SAsw7Q',
    id: 2564,
    acronym: 'WUHEE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wxuli.edu.cn',
    name: 'Wuxi University of Light Industry',
    web_page: 'http://www.wxuli.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxV14Dfz7ej_9m65POQlarSnTjOkposMBQ1PQhplBoMB5TM4MTHYewrA',
    id: 2565,
    acronym: 'WULI',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wyu.edu.cn',
    name: 'Wuyi University',
    web_page: 'http://www.wyu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHNBMWgu5q9TKBarbEQYYgozPmxIpNCtQbPc5qK8TSPdsWbQnOdrn6ZoQ',
    id: 2566,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wzmu.net',
    name: 'Wenzhou Medical College',
    web_page: 'http://www.wzmu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCagc5STLHs0-uCWrhNOWuaUNQOcUYNLWtSdgrCyAjhYFYCKf__R3ILg',
    id: 2567,
    acronym: 'WMC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'wzu.edu.cn',
    name: 'Wenzhou University',
    web_page: 'http://www.wzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvPAF9vPLtk8zyQJWdBGfjf58FbPHE7m_8Lj7LDBojaZKBGTc-jriXhsI',
    id: 2568,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xafa.edu.cn',
    name: "Xi'an Academy of Fine Art",
    web_page: 'http://www.xafa.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvOX0AdY0ZzpT9n452SLoDMtTjXS-4OhTcyqtLoofqzJ2ze5aWsxcu3Wk',
    id: 2569,
    acronym: 'XAFA',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xahu.edu.cn',
    name: "Xi'an Highway University",
    web_page: 'http://www.xahu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQs4Ye7lv7E0a9KT0QjktwPmw45RU0iLc-ke0h1AOVOymbdf21b5Pwanw',
    id: 2570,
    acronym: 'XHU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xauat.edu.cn',
    name: "Xi'an University of Architecture and Technology",
    web_page: 'http://www.xauat.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD_B5_3GHUYjVugZRVd94qOAqazzQKf0yFgu9XADTw-hsHtsBfAAu2og',
    id: 2571,
    acronym: 'XUAT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xaut.edu.cn',
    name: "Xi'an University of Technology",
    web_page: 'http://www.xaut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2572,
    acronym: 'XUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xhcom.edu.cn',
    name: 'Xinghai Conservatory of Music',
    web_page: 'http://www.xhcom.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0qni3NRAiVcQaw5YuxVOlnh4H68C92YflG0vdthDjV1sF23cDP-2Qdg',
    id: 2573,
    acronym: 'XCM',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xhu.edu.cn',
    name: 'Xihua University',
    web_page: 'http://www.xhu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxIa9HEns8UlFuIr9aYEa_QWh32cBxb3wIrQrjLUUppVEu9KJ3PrQ8tQ',
    id: 2574,
    acronym: 'XU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xidian.edu.cn',
    name: "Xi'an University of Electronic Science and Technology",
    web_page: 'http://www.xidian.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcoas501X6uygTGSMPKh9NcyvyHSEgdPH8pqSh1MqjQPl-7GCFGZij34Q',
    id: 2575,
    acronym: 'XUEST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xiju.edu.cn',
    name: 'Xijiang University',
    web_page: 'http://www.xiju.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl1Fir0r-JULiRboFLEY2ySrTHzgl_k2Xjr6jyOygFgquCUZNTHeQ_HCI',
    id: 2576,
    acronym: 'XU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xisu.edu.cn',
    name: "Xi'an International Studies University",
    web_page: 'http://www.xisu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxs9dk6CTCmElmXhA4CgmYWBSO9wENOgaMtMiBLuO_8NPXZdfCweyl074',
    id: 2577,
    acronym: 'XISU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xjau.edu.cn',
    name: 'Xinjiang Agriculture University',
    web_page: 'http://www.xjau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7WEeV9NpQP5Q--ZTP2JBs_Y2NhnTdobrUoSm0GJynnlQPTPaix1GRMQ',
    id: 2578,
    acronym: 'XAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xjnu.edu.cn',
    name: 'Xinjiang Normal University',
    web_page: 'http://www.xjnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMX9FWVHIBsXIjH4ZPB_y5aXvooTFGInH4Q41cYcjdO_upj3785qmGPQ',
    id: 2579,
    acronym: 'XNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xjtlu.edu.cn',
    name: "Xi'an Jiaotong-Liverpool University",
    web_page: 'http://www.xjtlu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQs0OZTjuCe1HRufFhHQB5VghkIREuZelgJXSj5M9JU38nJeVk0F26PcBit',
    id: 2580,
    acronym: 'XJU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'eurasia.edu',
    name: "Xi'an Eurasia University",
    web_page: 'http://en.eurasia.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2581,
    acronym: 'XEU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xjtu.edu.cn',
    name: "Xi'an Jiaotong University",
    web_page: 'http://www.xjtu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2582,
    acronym: 'XJU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xju.edu.cn',
    name: 'Xinjiang University',
    web_page: 'http://www.xju.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJzafu5nXDdlpoH7fVLBAO_8Tk9b5Q62RtxciLBnTkTtniZbmWEtBgAqo',
    id: 2583,
    acronym: 'XU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xjufe.edu.cn',
    name: 'Xinjiang University of Finance and Economics',
    web_page: 'http://www.xjufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStL8ixJhV-DLSvVLgB3K0I4XEh6354eNFE4aCGJORKYYA8WsrpPDTCXoI',
    id: 2584,
    acronym: 'XUFE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xmu.edu.cn',
    name: 'Xiamen University',
    web_page: 'http://www.xmu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeE44Qh8Fq8pBw7FjEx31Y4GUzK0huQnjiUAUUkf_jIMAMyrc3STnIkqs',
    id: 2585,
    acronym: 'XU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xtnu.edu.cn',
    name: 'Xiangtan Normal University',
    web_page: 'http://www.xtnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRC0n2C5H1XScalnAR1RWdPsnKDAHTksBDcGTF4vtxN4lyljy8NmzAEA',
    id: 2586,
    acronym: 'XNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xtu.edu.cn',
    name: 'Xiangtan University',
    web_page: 'http://www.xtu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ961hHFWUwI5-tAPkAJiVERmSM6Ow2mENqldODfCv63dbQY6e5tKr6vs',
    id: 2587,
    acronym: 'XU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xza.cn',
    name: 'Tibet Agricultural and Animal Husbandry College',
    web_page: 'http://www.xza.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQrSAOWS4xjp2e4EzOnon1xw9PLWulVDKUK3XkMHL6rvp9Ynfle-LdXMg',
    id: 2588,
    acronym: 'TAAHC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xzmy.edu.cn',
    name: 'Tibet University of Nationalities',
    web_page: 'http://www.xzmy.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHrWfb7hw6RefpHklPLLPrZJwZ29WwsYvcSzzXRxm5DVlQTyksjeBACVk',
    id: 2589,
    acronym: 'TUN',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'xznu.edu.cn',
    name: 'Xuzhou Normal University',
    web_page: 'http://www.xznu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOGCilInXPcW8korE8uSwSGy2Mhk8MuUqiOlCAUvdMZ0kbU0F0ucuwXK4',
    id: 2590,
    acronym: 'XNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'yangtzeu.edu.cn',
    name: 'Yangtze University',
    web_page: 'http://www.yangtzeu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2A5SJ7BkjerDw6FzZGR6-kPnl2P33p1FUFwQw8RVEUbSRi8hTXq7Lscs',
    id: 2591,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'yau.edu.cn',
    name: "Yan'an University",
    web_page: 'http://www.yau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1nBiYhUI20rEK4hMpdZerkdRMFJg4o8dgP9koewxvIYBbaYY6wXCrw',
    id: 2592,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ybu.edu.cn',
    name: 'Yanbian University',
    web_page: 'http://www.ybu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQunTR8Veyha3VkKEmqWwmswi8OzWtozF-1dWRgLhSKH5sOVPGErWXIM-MN',
    id: 2593,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ybust.edu.cn',
    name: 'Yanbian University of Science and Technology',
    web_page: 'http://www.ybust.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQunTR8Veyha3VkKEmqWwmswi8OzWtozF-1dWRgLhSKH5sOVPGErWXIM-MN',
    id: 2594,
    acronym: 'YUST',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'yizhuan.com',
    name: 'Huaihua Medical College',
    web_page: 'http://www.yizhuan.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc9AvTshKwhEaFkB8opuYMfrBQCZ0j51IpGlCTRZk6wSzJriUW8uOdnw',
    id: 2595,
    acronym: 'HMC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ynau.edu.cn',
    name: 'Yunnan Agriculture University',
    web_page: 'http://www.ynau.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8riTVcJbD9VZqmQfEUiDikQkhVevneLSYHY_oEaAXWIiO8AU2DVufL5aD',
    id: 2596,
    acronym: 'YAU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ynnu.edu.cn',
    name: 'Yunnan Normal University',
    web_page: 'http://www.ynnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8riTVcJbD9VZqmQfEUiDikQkhVevneLSYHY_oEaAXWIiO8AU2DVufL5aD',
    id: 2597,
    acronym: 'YNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ynu.edu.cn',
    name: 'Yunnan University',
    web_page: 'http://www.ynu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-vdgUZP5Q8Kf1VsABgUc6jbvdr6elwidz3xJ45Kp80j5--bMAvR2_aw',
    id: 2598,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ysu.edu.cn',
    name: 'Yan Shan University',
    web_page: 'http://www.ysu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq4DRG266iVUQBo3wxNpZyROrUU00FA56swEWnmwsxXROd_zn7Gwqxww',
    id: 2599,
    acronym: 'YSU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ytet.edu.cn',
    name: 'Yantai Education Institute & Yantai Television University',
    web_page: 'http://www.ytet.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT605kzUIWN2ONWsT6sRbYktN292LHlKx0lIajoleVYshQHjj5hAZP5DoI',
    id: 2600,
    acronym: 'YEI&YTU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ytnc.edu.cn',
    name: 'Ludong University',
    web_page: 'http://www.ytnc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFGotRRfiPFiBlSSnGQs7zbuK254wQQzVsER5xw9oWc88ZaPR8uTi5cTA',
    id: 2601,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'ytu.edu.cn',
    name: 'Yantai University',
    web_page: 'http://www.ytu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9A4t_3geqiHH4M6lHYsu3pWsC7PYkfZya7MqQY0RQ5dUpyp5eA7Qq1UE',
    id: 2602,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'yznu.cn',
    name: 'Yangtze Normal University',
    web_page: 'http://www.yznu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZp03FlFMXMKdVv15cNrNi0H68KVUMRsmjQSsfeM-sNwqYC4KLVUYzPig',
    id: 2603,
    acronym: 'YNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'yzu.edu.cn',
    name: 'Yangzhou University',
    web_page: 'http://www.yzu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQjNz5V-CE-NQxZ88Cuf6ybgZMIzxNHqjibMCOQdsyRgEpfHO0Ov5eiQ',
    id: 2604,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zdsoft.com.cn',
    name: 'Zhengda Software College',
    web_page: 'http://www.zdsoft.com.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOBL2l_8Gky_YEBsxV1pMadic9_ihe6Mvs-P3edBSZgjYcUVPcspj0F9k',
    id: 2605,
    acronym: 'ZSC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zgfxy.cn',
    name: 'Buddhist Acamedy of China',
    web_page: 'http://www.zgfxy.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_DQgQt9uyRWDfnave-Gk7t2-3MG3yTLdC8ZGLh9xaOp5CGfqKAkn9_w',
    id: 2606,
    acronym: 'BAC',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zhnut.edu.cn',
    name: 'Zhongnan University of Technology',
    web_page: 'http://www.zhnut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVJINfBeYw2S-thHlp_g1_6BhE06pxgKmU9rp7n14sQUTrO0w7XN0TsA',
    id: 2607,
    acronym: 'ZUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zjfc.edu.cn',
    name: 'Zhejiang Forestry University',
    web_page: 'http://www.zjfc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHgbZ26G38KLGshJqxOC8IipFiDcjjsib_K-4sJz3pUNpQCyz830dxW1OW',
    id: 2608,
    acronym: 'ZFU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zjgsu.edu.cn',
    name: 'Zhejiang Gongshang University',
    web_page: 'http://www.zjgsu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrp1StOlLo55WhmIZEv_LzjYT63eDQ2wgmVsqCSRBS8SkGu49yKTw7JqDUig',
    id: 2609,
    acronym: 'ZGU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zjnu.edu.cn',
    name: 'Zhejiang Normal University',
    web_page: 'http://www.zjnu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdQGeUyeCnoclkgXIcpfIADwnErNiRRJDTC0N-v_kkiXWwoPZ4djvtar2j',
    id: 2610,
    acronym: 'ZNU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zjou.edu.cn',
    name: 'Zhanjiang Ocean University',
    web_page: 'http://www.zjou.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-ggiRq7rDdMJo4N5KjymkxNkGQE8i1W8-t0thP9kh-JQe4tP-SpvDjv66pA',
    id: 2611,
    acronym: 'ZOU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zju.edu.cn',
    name: 'Zhejiang University',
    web_page: 'http://www.zju.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXqI3kTsCa0_TjNythvFa7HI8P-aN2QM9LHBTo0WO2OC1-ORoGxNDFDw',
    id: 2612,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zjut.edu.cn',
    name: 'Zhejiang University of Technology',
    web_page: 'http://www.zjut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr8FZYVtfPS-XDI4tkbTcHrtHm752WSPT8HFySW66Cp1XaWyuczz7WJZg',
    id: 2613,
    acronym: 'ZUT',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'znuel.net',
    name: 'Zhongnan University of Economics and Law',
    web_page: 'http://www.znuel.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTikytYteuOSH-dO4SB-fowAgW3qV6NVnCl_nakCBmbQor99UjlY9zVl3A',
    id: 2614,
    acronym: 'ZUEL',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'znufe.edu.cn',
    name: 'Zhongnan University of Finance and Economics',
    web_page: 'http://www.znufe.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBrbSzGSqRbKWtcDdXTPTlAdtbdN8S49-b4sR0XogZi997Wmq2PzU5jQ',
    id: 2615,
    acronym: 'ZUFE',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zsb.scfai.edu.cn',
    name: 'Sichuan Fine Art Institute',
    web_page: 'http://zsb.scfai.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7gEOnTsqLXxgUWQj_WjnPI4UjLxLQB2pFk37nxAJ2Ir9zWCazmv4dTFs',
    id: 2616,
    acronym: 'SFAI',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zstu.edu.cn',
    name: 'Zhejiang Sci-Tech University',
    web_page: 'http://www.zstu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZs09gVUBZKl57TElfdrTqHoNNBuihDJr0MI0VdsdSl0cfzs7G9M-qtQ',
    id: 2617,
    acronym: 'ZSU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zsu.edu.cn',
    name: 'Zhongshan University',
    web_page: 'http://www.zsu.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcQjm6hnWX8MxJTCglXyHTA5Or7QfESECLPzUohmEeB6gLsf4EafpCdw',
    id: 2618,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zzgc.edu.cn',
    name: 'Zhengzhou Grain University',
    web_page: 'http://www.zzgc.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGoQnCTlyZ7LSsPY8m--8NEHcYW1VtnUdy3Mmem1MduqW8gfbrR-SzzkhGqQ',
    id: 2619,
    acronym: 'ZGU',
  },
  {
    alpha_two_code: 'CN',
    country: 'China',
    domain: 'zzut.edu.cn',
    name: 'Zhengzhou University of Technology',
    web_page: 'http://www.zzut.edu.cn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVaCAjy4qLLj4H4BrifMS7ZtDWNTPHnM1rC7sGahcVsDWrOPYoxJT9Ug',
    id: 2620,
    acronym: 'ZUT',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'autonoma.edu.co',
    name: 'Universidad Autónoma de Manizales',
    web_page: 'http://www.autonoma.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT-rCEuIdi7LpKNffMh6WjWpwsQ34y-Xs6x6P5y0TWk3m2eRooIIDNyw',
    id: 2621,
    acronym: 'UADM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'bellasartes.edu.co',
    name: 'Universidad Bellas Artes',
    web_page: 'http://www.bellasartes.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8rrEphkv5jW1TmEriH369P52OFTUS8Lry0yjSu-WnluZqjUVJQ3TeQYA',
    id: 2622,
    acronym: 'UBA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'bundenet.com',
    name: 'Conservatorio del Tolima',
    web_page: 'http://www.bundenet.com/umusical/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiZXQICE8dk3SNxlq1qhNBIYtkkyQccBc9vfpSZMFpdR-plgbzYuXsam4R',
    id: 2623,
    acronym: 'CDT',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'cesa.edu.co',
    name: 'Colegio de Estudios Superiores de Administración (CESA)',
    web_page: 'http://www.cesa.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDwas6e490RNKXU8UVphGOqChw7TcZjS6jXsiU5bHNH5OMM8MHw4QkuA',
    id: 2624,
    acronym: 'CDESDA(',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ces.edu.co',
    name: 'Universidad CES',
    web_page: 'http://www.ces.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMtv6FfWRrRdjSYs8piDRDK0ga2zRMRhXHQKcg46zkYrWaxVRBdD54hYA',
    id: 2625,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'cuao.edu.co',
    name: 'Universidad Autónoma de Occidente',
    web_page: 'http://www.cuao.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx9DW_TrVaHSTkzedcSt-uW6tRgYVeIRHejoATqnXjGsj-uQ6KBJjQ7CI',
    id: 2626,
    acronym: 'UADO',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'cuc.edu.co',
    name: 'Corporación Universitaria de la Costa (UNICOSTA)',
    web_page: 'http://www.cuc.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEChipfyHXDR6QZZJ8TrT92JzcX6XWSNzMTrV6OBO-6MpGdqrh7IAA',
    id: 2627,
    acronym: 'CUDLC(',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'cui.edu.co',
    name: 'Corporación Universitaria de Ibagué (CORUNIVERSITARIA)',
    web_page: 'http://www.cui.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxBh_B7WIy9uW6lqSdNCtktEjQUdBiNGyAAEF-2S5PHgX3UkdS-O5FJaU',
    id: 2628,
    acronym: 'CUDI(',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'cutb.edu.co',
    name: 'Corporación Universitaria Tecnológica de Bolivar',
    web_page: 'http://www.cutb.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqyXojftCE2NbOEHST9sSEtZ0XB2-o_VgcHTAWSo57HWKxGZ2OPnQtFw',
    id: 2629,
    acronym: 'CUTDB',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'eafit.edu.co',
    name: 'Universidad EAFIT',
    web_page: 'http://www.eafit.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsfSpL5zvj7KOUsnunFCaiUTY7VN90FP_29uzvgaG3DqO0GjeWGqP1hIc',
    id: 2630,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ean.edu.co',
    name: 'Escuela de Administración de Negocios',
    web_page: 'http://www.ean.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZYmiV4fuFTpt_ngN7TxgBrNSKQZT-t8JmaplhA920i_HJMtwKQBzpYZ01',
    id: 2631,
    acronym: 'EDADN',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ecci.edu.co',
    name: 'Escuela Colombiana de Carreras Industriales',
    web_page: 'http://www.ecci.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX8f-BzcLukzJcH4l6zwKaODPm-zbOAV-AyVZOdnXHHyLQrqUNPxEm5R4',
    id: 2632,
    acronym: 'ECDCI',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ecr.edu.co',
    name: 'Fundacion Escuela Colombiana de Rehabiliación',
    web_page: 'http://www.ecr.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFa5o1eKwhAwvWv1883vBCtPhCPt7XGZq9Mkt2ltGez6ro9ze8NVQY8Co',
    id: 2633,
    acronym: 'FECDR',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'eia.edu.co',
    name: 'Escuela de Ingeniería de Antioquia',
    web_page: 'http://www.eia.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUbZEqGYuhGqPDdL3pgPdZueAUtNQp1saCR6jfCd_xyKTDtCx70FmTsQ',
    id: 2634,
    acronym: 'EDIDA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'esap.edu.co',
    name: 'Escuela Superiore de Administración Pública',
    web_page: 'http://www.esap.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs75V2r2AFwFjkPQ08zhAa-rTFxNuRRLons9eV__OWwuuMmFSQ7qWNKw',
    id: 2635,
    acronym: 'ESDAP',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'escueladepolicia.edu.co',
    name: 'Escuela de Policia "General Santander"',
    web_page: 'http://www.escueladepolicia.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWG8Su9ljNYUM18T0FKQCoIYKVcFB1YsUrJtB2PDt_kvHhqJrxtqaLMqA',
    id: 2636,
    acronym: 'EDP"S',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'escuelaing.edu.co',
    name: 'Escuela Colombiana de Ingeniería Julio Garavito',
    web_page: 'http://www.escuelaing.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUW-mSihHImhNRuet_V8pwX9sdSH415ESQ1lEuAF_rKJUyQF1iGmr5xv0',
    id: 2637,
    acronym: 'ECDIJG',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'fuac.edu.co',
    name: 'Universidad Autónoma de Colombia',
    web_page: 'http://www.fuac.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXf8jBOCmCV3AStW9sXICvrt6jVSZoeDlNZWA1JeGo_liaN-WPZ0RpVNU',
    id: 2638,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'funandi.edu.co',
    name: 'Fundación Universitaria del Area Andina. Sede Pereira',
    web_page: 'http://www.funandi.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLzUa3JmB6Kj9Bs6faXjhrCHKswRJx0gKhEiVi4RcWo4dzjCs0VwgG9A0',
    id: 2639,
    acronym: 'FUDAASP',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'funlam.edu.co',
    name: 'Fundación Universitaria Luis Amigó',
    web_page: 'http://www.funlam.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUmh4F2cfj0lkt4YAEGPVCcutKanrnZWZgN75JETPJLCQNYv6mtVFOFPQ1',
    id: 2640,
    acronym: 'FULA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'iberoamericana.edu.co',
    name: 'Institución Universitaria Iberoamericana',
    web_page: 'http://www.iberoamericana.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTz7SsqMXc_THTDHh7iU4gMZErD5hSLdlgYnQdmdSQCVh9iiloZCc5_jfA',
    id: 2641,
    acronym: 'IUI',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'iceit.edu.co',
    name: 'Centro de Estudios Investigación y Tecnología (CEIT)',
    web_page: 'http://www.iceit.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZeVSKm9R5Zn8XunjNj0Quatyk--NNeJbQSaa3OJ1xmbsI4RyLkhgm0lA',
    id: 2642,
    acronym: 'CDEIYT(',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'icesi.edu.co',
    name: 'Universidad ICESI',
    web_page: 'http://www.icesi.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9oabitARRp2spxWYMqdujP2f2S6O-_79AGVtYyUnfefFennLAmu3i9g',
    id: 2643,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'itm.edu.co',
    name: 'Instituto Tecnológico Metropolitano',
    web_page: 'http://www.itm.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRq9LKjCaZDMVTlcsAWhoBs135PfFTO1qcBlLRfX9IkiBHs47MDML7vLXc',
    id: 2644,
    acronym: 'ITM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'iue.edu.co',
    name: 'Institucion Universitaria de Envigado',
    web_page: 'http://www.iue.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ72ohZoPvU05Dh5dTHfHhqEZHzfEHfPaNyelguH4VkzbV8X_bqapheclD4',
    id: 2645,
    acronym: 'IUDE',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'javeriana.edu.co',
    name: 'Pontificia Universidad Javeriana',
    web_page: 'http://www.javeriana.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYjfGTzwNHHyFFKWr023qI2fABCGbq81bzvhVCfysRhzuSq_aTv3EFag',
    id: 2646,
    acronym: 'PUJ',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'lasalle.edu.co',
    name: 'Universidad de La Salle',
    web_page: 'http://www.lasalle.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLQv5VIaLIpIxXvgZKwE1C4iDMpKds4KtlXBUht7ziLw8G0jrUbjZHSfg',
    id: 2647,
    acronym: 'UDLS',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'pedagogica.edu.co',
    name: 'Universidad Pedagogica Nacional',
    web_page: 'http://www.pedagogica.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQthqVyuPW3qtJIyMmV7g1z594qwoq1zJQwkz974OZguLjmrUhejCaBkw',
    id: 2648,
    acronym: 'UPN',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'poligran.edu.co',
    name: 'Politécnico Grancolombiano - Institución Universitaria',
    web_page: 'http://www.poligran.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8qn5TzXD6CZCUEgHaSnbgAJ0yvfdD3RCaBCJdiiFd2Gkyfkn-zOJ0RJ8',
    id: 2649,
    acronym: 'PG-IU',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'politecjic.edu.co',
    name: 'Politécnico Columbiano "Jaime Isaza Cadavid"',
    web_page: 'http://www.politecjic.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7uNZgVBgVCimAsoTEouDH5UC-ne_nSrg4ioIshCaA_RlHpMivtnjGs7w',
    id: 2650,
    acronym: 'PC"IC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'sanmartin.edu.co',
    name: 'Fundación Universitaria San Martín',
    web_page: 'http://www.sanmartin.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeuFvSwdsyeBQtOBFLQTs2uHNcqid_zCAtdTIqYeAu-yV7-gmbvxz-weWr1w',
    id: 2651,
    acronym: 'FUSM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uac.edu.co',
    name: 'Universidad Autónoma del Caribe',
    web_page: 'http://www.uac.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5HzKyLvbB68PKsLo8H3vkCNliH2zsFcTkz6S_4dxXefbsYA2Ry92vvGrJ9g',
    id: 2652,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uam.edu.co',
    name: 'Universidad Autónoma de Las Américas',
    web_page: 'http://www.uam.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiW4ZVlNqOSHesoYx__Dtrt-3PMVXS6s0d_fbelCYNfzRMzmYUvLciFg',
    id: 2653,
    acronym: 'UADLA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uamerica.edu.co',
    name: 'Universidad de América Bogotá',
    web_page: 'http://www.uamerica.edu.co/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRkKZMCp4r4BdrvFp2hfGfHkewLIjvfVX9B1hwMoSKVAEtdNznVbZK1IQ',
    id: 2654,
    acronym: 'UDAB',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uanarino.edu.co',
    name: 'Universidad Antonio Nariño',
    web_page: 'http://www.uanarino.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_ugfaffhGJkiS-DkUioF1-Lv_r_mV0JWY5u_e8xWUpccC4Qvv9QPjAg',
    id: 2655,
    acronym: 'UAN',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ucaldas.edu.co',
    name: 'Universidad de Caldas',
    web_page: 'http://www.ucaldas.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5_8bTv-J0wQXCt91XdifrSbgokvNkEVoMdD1lk3ek0cyXrGd6OieofCk',
    id: 2656,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ucatolica.edu.co',
    name: 'Universidad Católica de Colombia',
    web_page: 'http://www.ucatolica.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2ECW0WYrO9BUDYxOcyETEdwc4JRWnjK4LPyKLdjytBujQnWiUA_p9P7I',
    id: 2657,
    acronym: 'UCDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ucatolicamz.edu.co',
    name: 'Universidad Católica de Manizales',
    web_page: 'http://www.ucatolicamz.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPOwTKK0Gc14_bXKZOeQwui57F_us8kgitwz7rbEtN9GuDXm5N-8sU8A',
    id: 2658,
    acronym: 'UCDM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ucauca.edu.co',
    name: 'Universidad del Cauca',
    web_page: 'http://www.ucauca.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRxRGD9x9foQbQ7eDg7e5kQZWRxQsofjj2fuUOqaoY6b3Xes68beGkAQ',
    id: 2659,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ucc.edu.co',
    name: 'Universidad Cooperativa de Colombia',
    web_page: 'http://www.ucc.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuskKQiMqNHuQbnK_1BN7ERvlzv8FkowaosmRYxTTxE9rmyoJyXLDzng',
    id: 2660,
    acronym: 'UCDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uceva.edu.co',
    name: 'Universidad Central del Valle del Cauca',
    web_page: 'http://www.uceva.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnNScTe22GHKlJ5LZLB2L1jSzg7_DM1Ug3eP5EYcMSunJ61_s1ZW9qdXU',
    id: 2662,
    acronym: 'UCDVDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ucn.edu.co',
    name: 'Universidad Católica del Norte',
    web_page: 'http://www.ucn.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9QJ9waIplgMg4lZaXzpdLnXfBbzVoNPXcdbDHKcPEdhdJHJt_fQ5SivzjnQ',
    id: 2663,
    acronym: 'UCDN',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uco.edu.co',
    name: 'Universidad Católica del Oriente',
    web_page: 'http://www.uco.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJf1ubg5lG4syDyxgbzHNKQzKrTboH3qHV-D6TtEp4ckCWqqRb2E2l5gk',
    id: 2664,
    acronym: 'UCDO',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ucpr.edu.co',
    name: 'Universidad Católica Popular del Risaralda',
    web_page: 'http://www.ucpr.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYSNImNSlMwxTNU2dvFNd4H4YzwH9G5cSks2traCg_SFjoocfPLt12BhKG',
    id: 2665,
    acronym: 'UCPDR',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'udca.edu.co',
    name: 'Universidad de Ciencias Aplicadas y Ambientales (UDCA)',
    web_page: 'http://www.udca.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwUeLkIBB2wdrSyNQmSLBdtfc2ujARJ6QpNzSvdHKXQ-D8uHSTDyjhog',
    id: 2666,
    acronym: 'UDCAYA(',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'udea.edu.co',
    name: 'Universidad de Antioquia',
    web_page: 'http://www.udea.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxM7RB1NBAXz1M3YkQMtyCIjuoG0jUiMWRNsQWUwEvp1f9RZaPMiwb1qJo',
    id: 2667,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'udea.edu.co',
    name: 'Universidad de Antioquía',
    web_page: 'http://www.udea.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGf0Bswbr8aqBhNhg8QCKeAzjVAtV3upx-348kZTslmSIoVx4j5LYRRsw',
    id: 2668,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'udem.edu.co',
    name: 'Universidad de Medellín',
    web_page: 'http://www.udem.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnJVgYC7ETfcOgOd05rE3kufyD2nFfFLjpHKjA_OFyZBRDvxCe3KlIFutbxg',
    id: 2669,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'udenar.edu.co',
    name: 'Universidad de Nariño',
    web_page: 'http://www.udenar.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcKezTzBUGF2MMHmuSk7vei0NcmHaW0kDYi53cKhzgSXXP9YgTnPpn83U',
    id: 2670,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'udes.edu.co',
    name: 'Corporación Universitaria de Santander (UDES)',
    web_page: 'http://www.udes.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjhLrZpzm5dqLFJVmpDyaTslfyVPlrRXBTRlc-yOTlgenHqBBKyTu6OS3A',
    id: 2671,
    acronym: 'CUDS(',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'udistrital.edu.co',
    name: 'Universidad Distral "Francisco José de Caldas"',
    web_page: 'http://www.udistrital.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkEARmjjDgGI8LVBdWWHTrvQ6WS0DsPpn8VqtgQU9qYTHCUpBewiljnQ',
    id: 2672,
    acronym: 'UD"JDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uexternado.edu.co',
    name: 'Universidad Externado de Colombia',
    web_page: 'http://www.uexternado.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSfdtZvf620MdxBvkTH63vNoXY_qqWe5ijSMkmiQkDYX5CAhH6IUn1lw',
    id: 2673,
    acronym: 'UEDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ufps.edu.co',
    name: 'Universidad Francisco de Paula Santander',
    web_page: 'http://www.ufps.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7cHuk5YX8SB-BkYVDsUo0xYH0zdVjK7wNOv3G6Nid4INnZbsLtuWXDawz',
    id: 2674,
    acronym: 'UFDPS',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ugrancolombia.edu.co',
    name: 'Universidad La Gran Colombia',
    web_page: 'http://www.ugrancolombia.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGu5rWPFT2rKlMjC_mEWeufBs5qVdYDT0ImuzklpryCejq0ibQ9qkJ_Ik',
    id: 2675,
    acronym: 'ULGC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uis.edu.co',
    name: 'Universidad Industrial de Santander',
    web_page: 'http://www.uis.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyJqCA926727q3lCOBMso_RHKkmlPF0390YIvfzol-hUpuv6BTRtO7Lb8',
    id: 2676,
    acronym: 'UIDS',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'umanizales.edu.co',
    name: 'Universidad de Manizales',
    web_page: 'http://www.umanizales.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCNicbSZjqn-taS9vy9DlDAPjLhHe5gYmJik5dE2Tx3C_T3R74dDwVMik',
    id: 2677,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'umariana.edu.co',
    name: 'Universidad Mariana',
    web_page: 'http://www.umariana.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwUYASzpHCnlJk-hcD5qCENFA7fOQHlFWgZsnqeXVetDHU903djYnRTPI',
    id: 2678,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'umb.edu.co',
    name: 'Fundación Universitaria Manuela Beltrán',
    web_page: 'http://www.umb.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuDMeWUV_NuYfqNUReQFb21k8Ekd9ZXAAO8Dev9XVKqrGJJiqhzfykmuU',
    id: 2679,
    acronym: 'FUMB',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'umng.edu.co',
    name: 'Universidad Militar Nueva Granada',
    web_page: 'http://www.umng.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRseIwDAZfbTjcqodVSmFiTXNe1UN8tx4ZckMsaAA_aTBQBD88_uUKN5V8',
    id: 2680,
    acronym: 'UMNG',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unab.edu.co',
    name: 'Universidad Autónoma de Bucaramanga',
    web_page: 'http://www.unab.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl875c6uZolBjeKVtYOk1zRx2-T6WMRhbRWLdIkk_mLoR5G4AoOWyIMA',
    id: 2681,
    acronym: 'UADB',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unac.edu.co',
    name: 'Universidad Adventista de Colombia',
    web_page: 'http://www.unac.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Ni4XePpniABzj3thp6QPI0LbQ7DZjSLq4c_d7m5M99y6_yGu2723Vws',
    id: 2682,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unad.edu.co',
    name: 'Universidad Nacional Abierta y a Distancia',
    web_page: 'http://www.unad.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFwiJmLhZpkQjzIrRyEqpkRnVuphdwr1HW7vkZCjtYDNRPXnFn8mSJmg',
    id: 2683,
    acronym: 'UNAYAD',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unal.edu.co',
    name: 'Universidad Nacional de Colombia',
    web_page: 'http://www.unal.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQei8XO7yO6tkHgbFf_7Cmqg4IkkiC0lpCWCoH_Ahc_OYVSWS7ZyfyZYA',
    id: 2684,
    acronym: 'UNDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unaula.edu.co',
    name: 'Universidad Autónoma Latinoamericana',
    web_page: 'http://www.unaula.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8FvihuEBTXpB8taX_GE7qQROOtHPRTmwsOxFr4cFUGIgrBPlrqbHZ7Fc',
    id: 2685,
    acronym: 'UAL',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unbosque.edu.co',
    name: 'Universidad El Bosque',
    web_page: 'http://www.unbosque.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQReDaOEgSjMisx9m5DJOy1gdeYIzaEYBmHmLhoA9iTMMyQ82Xo1tEWOA',
    id: 2686,
    acronym: 'UEB',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uniamazonia.edu.co',
    name: 'Universidad de La Amazonia',
    web_page: 'http://www.uniamazonia.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSymu6MDrDPFfuQj8cp3a8OORauLQRTIzTi5pPpX4btfPWL1fr3xxHLTg',
    id: 2687,
    acronym: 'UDLA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uniatlantico.edu.co',
    name: 'Universidad del Atlántico',
    web_page: 'http://www.uniatlantico.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvc884GQ43PG_GlCTRUZWdbKVM53rUE41aalBoNHbdXAoTV7dpgtawKTE',
    id: 2689,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uniboyaca.edu.co',
    name: 'Fundación Universitaria de Boyacá',
    web_page: 'http://www.uniboyaca.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp0v-VLbih-GcfTTYCnb6aIkKQ71zhyO5K_WX6dCmO8p317YHCJe67Q3qh',
    id: 2690,
    acronym: 'FUDB',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unicartagena.edu.co',
    name: 'Universidad de Cartagena',
    web_page: 'http://www.unicartagena.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSieG3jMeVDsQ61VFdRUS7ZghRAPlt8xGKnv9DaQEuf6YKdz4tojUUrfA',
    id: 2691,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unicesar.edu.co',
    name: 'Universidad Popular del Cesar',
    web_page: 'http://www.unicesar.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROAcFPEtSPNhhfUnbLerC_eoPdDZeu4iUg7nniij3ydJElgQMFeUt-xQ',
    id: 2692,
    acronym: 'UPDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unicordoba.edu.co',
    name: 'Universidad de Córdoba',
    web_page: 'http://www.unicordoba.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLqvVZ9mPYCBf6r092D_IrxUQz2HKt__TC18XYLQwPKOm8iOJTrNr0a60',
    id: 2693,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uniguajira.edu.co',
    name: 'Universidad de La Guajira',
    web_page: 'http://www.uniguajira.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhGN_XEeWY7T49Val44amut_7-uWlt1Wi8gkBp8ywBps3OwHF-N7wgZkyb',
    id: 2694,
    acronym: 'UDLG',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unilibre.edu.co',
    name: 'Universidad Libre de Colombia',
    web_page: 'http://www.unilibre.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJYiMKJ2WKZEMXnVQlyBD7JjUN7Pwq7kkoxPPB82fN5Yr_4fJHTqJd6g',
    id: 2695,
    acronym: 'ULDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unillanos.edu.co',
    name: 'Universidad de Los Llanos',
    web_page: 'http://www.unillanos.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3aDEQ2ZG5MAHAq4pM8gYfuGsGUVSIggvxGsxVrXZ84aU3Ht37KdbWgA',
    id: 2696,
    acronym: 'UDLL',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unimagdalena.edu.co',
    name: 'Universidad del Magdalena',
    web_page: 'http://www.unimagdalena.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvesJb7klSUIavTB5lhYb205GRsiyezYwk4YNi5JIUe0okv1L9YQ77Vg',
    id: 2697,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unimetro.edu.co',
    name: 'Universidad Metropolitana',
    web_page: 'http://www.unimetro.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-fZvA5zR6TKPNgu6iT_OQ9fEDklh8s69-INoWn0rkUriW8QcvSm6LEg',
    id: 2698,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unincca.edu.co',
    name: 'Universidad INCCA de Colombia',
    web_page: 'http://www.unincca.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-hp1GnBci63O73bftiGV_DP2wkhDkkLdudi99uLootVaWAj79sSwpKIaRxw',
    id: 2699,
    acronym: 'UIDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uninorte.edu.co',
    name: 'Universidad del Norte',
    web_page: 'http://www.uninorte.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIZRLS-dbX7brwrpVwte5LZPHMIwdNmb8DZfasxGWAAHNsxHVAibO_wQ',
    id: 2700,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unipamplona.edu.co',
    name: 'Universidad de Pamplona',
    web_page: 'http://www.unipamplona.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6xcIiWtcalQpmoCyZxNFN-losFj60_wfJCa2i20BBRekbps1JHWfI7aE',
    id: 2701,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unipaz.edu.co',
    name: 'Instituto Universitario de La Paz',
    web_page: 'http://www.unipaz.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOzj38tw7cLG5rx5J-9Oq5NT_KM2Ar0GswyU1cFEwn7rNUvzDJFscqMbkA',
    id: 2702,
    acronym: 'IUDLP',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unipiloto.edu.co',
    name: 'Universidad Piloto de Colombia',
    web_page: 'http://www.unipiloto.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHet4MWrAffjHqf7FGUcKjOzyirfx5hZDE1FKlDYaZ1GaEvcxtcGC-dOg',
    id: 2703,
    acronym: 'UPDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uniquindio.edu.co',
    name: 'Universidad del Quindío',
    web_page: 'http://www.uniquindio.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVluARsUJ2sr3kdJqMKWEiDQa0lZJYGhiVzUHd8B7ROkX5gCZKvqyYgSk',
    id: 2704,
    acronym: 'UDQ',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unisabana.edu.co',
    name: 'Universidad de La Sabana',
    web_page: 'http://www.unisabana.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT--I5oikQxVBYgFpi9M6z3pmZvj_dNPkIQMHd7eypzfWtHiW16QH0egXY',
    id: 2705,
    acronym: 'UDLS',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unisimonbolivar.edu.co',
    name: 'Corporación Educativa Mayor del Desarrollo "Simón Bolivar"',
    web_page: 'http://www.unisimonbolivar.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVk39_14vfPw3nvFkL5gk1rnIHRz2zMuXHfhlo5jyLqJJm2iCDC3_DAt9A',
    id: 2706,
    acronym: 'CEMDD"B',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unisinucartagena.edu.co',
    name: 'Universidad del Sinú',
    web_page: 'http://www.unisinucartagena.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH09gdhDcCNjVYd1FN75spNviIW8mGnJq5-G70l6w36bsI21sLoL-fzCg',
    id: 2707,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'unisucre.edu.co',
    name: 'Universidad de Sucre',
    web_page: 'http://www.unisucre.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVTwouGGKyoAx7ARn9mGqXt8vpGh8R9V-rh5zeba7KQe4dIGXAciX-pA',
    id: 2708,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'univalle.edu.co',
    name: 'Universidad del Valle del Cauca',
    web_page: 'http://www.univalle.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdAKzyGIH044mqu_IhXK5rTkc_kjBjnn0pGLwQRPpk8Fs6v40QzAOu64rx',
    id: 2709,
    acronym: 'UDVDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'upb.edu.co',
    name: 'Universidad Pontificia Bolivariana',
    web_page: 'http://www.upb.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsLJexULFtzNikxOoVaaZ7z01gFgljquAe5-a1Qcgw5Wld2Q5by_1Nog',
    id: 2710,
    acronym: 'UPB',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'uptc.edu.co',
    name: 'Universidad Pedagógica y Tecnológica de Colombia',
    web_page: 'http://www.uptc.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY-GwiJhZKS8w899KMB8rPw23R_NGAAP7bULz9kqRE6uX9obD-RudOTZI',
    id: 2711,
    acronym: 'UPYTDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'urosario.edu.co',
    name: 'Universidad del Rosario',
    web_page: 'http://www.urosario.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuY9RQKf9G7-XFQPhjpCLd_VmYayCqHsUMgqVtTfZum4AtYtuEQ4F3VT4',
    id: 2712,
    acronym: 'UDR',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'usaca.edu.co',
    name: 'Universidad Santiago de Cali',
    web_page: 'http://www.usaca.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMIbWs8oafd8OZqZ_X_NeKhpSbeXAJ9KjRDeEGi4haRU-wxw2T75vB4yY',
    id: 2713,
    acronym: 'USDC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'usb.edu.co',
    name: 'Universidad de San Buenaventura',
    web_page: 'http://www.usb.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSosoXqZoNhMPnK6VF9qCF2QsJXhu9SXD0tB9PAR8E6HW4_U6vzmiv9iA',
    id: 2714,
    acronym: 'UDSB',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'usbmed.edu.co',
    name: 'Universidad de San Buenaventura Medellin',
    web_page: 'http://www.usbmed.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU-znMo1FkOlYLFOT6j2bn9n0AxA-jk5WVRo6Yr8tcyUaLbbnrJ0zxug',
    id: 2715,
    acronym: 'UDSBM',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'usergioarboleda.edu.co',
    name: 'Universidad Sergio Arboleda',
    web_page: 'http://www.usergioarboleda.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT-fky3SlJdzPUQjEZiNZAO_ajr-HuBHu9KvWsMkk5etodntgKnraSyQ',
    id: 2716,
    acronym: 'USA',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'usurcolombia.com',
    name: 'Universidad Surcolombiana',
    web_page: 'http://www.usurcolombia.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQorXT1CnMGyBiJ3zdNdmd95Tq8JfDB1Tjj1ECa1x1L3F18J0Yb5rFxAFs',
    id: 2718,
    acronym: 'US',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'utadeo.edu.co',
    name: 'Universidad de Bogotá "Jorge Tadeo Lozano"',
    web_page: 'http://www.utadeo.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCXx8Ko0pUoL0JgLJagE1DFSNKp8OloGmklHYNJyHGbDOeXmKAd4e2WHs',
    id: 2719,
    acronym: 'UDB"TL',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'utch.edu.co',
    name: 'Universidad Tecnológica del Choco "Diego Luis Cordoba"',
    web_page: 'http://www.utch.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ24lODdankmaq9YSMbSzhsESjv5Z2JGdBr16MTtD9UrN-w3einpA_Mo8A',
    id: 2720,
    acronym: 'UTDC"LC',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'ut.edu.co',
    name: 'Universidad del Tolima',
    web_page: 'http://www.ut.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWcdCaojf1Tfw9PP6-Y03dRrJjHPlg2zW4of2565sqvdcv8uPHDkLZgyU',
    id: 2721,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'CO',
    country: 'Colombia',
    domain: 'utp.edu.co',
    name: 'Universidad Tecnológica de Pereira',
    web_page: 'http://www.utp.edu.co/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-q_GOWxTwmab7D5cHaOt-xYmn4ofr-R5O3PzO7_S5mgrSkNhBBCR7wIw',
    id: 2722,
    acronym: 'UTDP',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'ucbc.org',
    name: 'Université Chrétienne Bilingue du Congo',
    web_page: 'http://www.ucbc.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgTwduX6AmrG1HQOmaL13taSbAx7UoZLkTrTCY0TMkUvTpXlmVR7RC5A',
    id: 2723,
    acronym: 'UCBDC',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'ucbukavu.ac.cd',
    name: 'Université Catholique de Bukavu',
    web_page: 'http://www.ucbukavu.ac.cd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuL8_W5-VU8DaJ0ZJACl4TFe1lAMlGj41OBXuIf4hCyaJHgGahmEskDin2',
    id: 2724,
    acronym: 'UCDB',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'um-rdc.org',
    name: 'Université de Mbuji Mayi',
    web_page: 'http://um-rdc.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXba1_Kk2RGoYdQeZPMBMlKm-89kyb6y9PlBu9KSN6XkJ7VrM8slr56lUj',
    id: 2725,
    acronym: 'UDMM',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'uniband.org',
    name: 'Université de Bandundu Ville',
    web_page: 'http://www.uniband.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 2726,
    acronym: 'UDBV',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'unikin.cd',
    name: 'Université de Kinshasa',
    web_page: 'http://www.unikin.cd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgbBSZO40gmbtkJkPbuACylj5qqB4N8xXl2T1QQFQdYGIu282UwUsLUedU',
    id: 2727,
    acronym: 'UDK',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'unikis.ac.cd',
    name: 'Université de Kisangani',
    web_page: 'http://www.unikis.ac.cd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYyO9Zl5Uvpa0noOs5SH_0S4skJOtNQ6aCXK-y1LI2ggvFLyBuoi1hbw',
    id: 2728,
    acronym: 'UDK',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'unilu.ac.cd',
    name: 'Université de Lubumbashi',
    web_page: 'http://www.unilu.ac.cd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJkFr1rirLzEfREZjsReXDISSsurPCuH-saouGszpXvl-wUitcwmVuL6W7',
    id: 2729,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'unishabunia.org',
    name: 'Université Shalom de Bunia',
    web_page: 'http://www.unishabunia.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxVL65Jnp_vbvsmcv9ROe1plLq27w4l09ts8Xls5aIWvzUDA96DpRWG4vH',
    id: 2730,
    acronym: 'USDB',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'universitekongo.org',
    name: 'Université Kongo',
    web_page: 'http://www.universitekongo.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgTwduX6AmrG1HQOmaL13taSbAx7UoZLkTrTCY0TMkUvTpXlmVR7RC5A',
    id: 2731,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'CD',
    country: 'Congo, the Democratic Republic of the',
    domain: 'upc-rdc.cd',
    name: 'Université Protestante au Congo',
    web_page: 'http://www.upc-rdc.cd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWjdqNbwNLn8rxfdH-c_3UWE-nTEtUxBAhC1Qyey1KWSn-GJrPVsGrBg',
    id: 2732,
    acronym: 'UPAC',
  },
  {
    alpha_two_code: 'CG',
    country: 'Congo',
    domain: 'univ-mngb.net',
    name: 'University Marien Ngouabi Brazzaville',
    web_page: 'http://www.univ-mngb.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwqwUn05K6FAAUmrOpGXiCtLeFnY99aRm3fnBFQnxjunhapksTEtJxUGWz',
    id: 2733,
    acronym: 'UMNB',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'fundepos.ac.cr',
    name: 'Universidad Fundepos Alma Mater',
    web_page: 'http://www.fundepos.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjc0iLjnKEBcrCM0FmmEMZlYwjxpCmIeoeGq3HnhC98mK1dDiJNUi5Pw',
    id: 2734,
    acronym: 'UFAM',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'isaacnewtoncr.com',
    name: 'Universidad Isaac Newton',
    web_page: 'http://www.isaacnewtoncr.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxtPOqiuyuYGySO4UH_fI_aT--d7OS9pRvdz37dZGbXAvLeLTwR5fE7mUpkQ',
    id: 2735,
    acronym: 'UIN',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'itcr.ac.cr',
    name: 'Instituto Tecnológico de Costa Rica',
    web_page: 'http://www.itcr.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeIC8hj2SgD-Fjgr2KgJKCLq8sXkeZLOvp0S0AiFnNwCIyrttUli5P7ks',
    id: 2736,
    acronym: 'ITDCR',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'sjdlc.com',
    name: 'Universidad San Juan de la Cruz',
    web_page: 'http://www.sjdlc.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSYwB_r-hpjvhgaZJFYdHu9DoDOmSEA5DF0WjbcEGT0wTeP6r-2f7VbIk',
    id: 2737,
    acronym: 'USJDLC',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'uaca.ac.cr',
    name: 'Universidad Autónoma de Centro América',
    web_page: 'http://www.uaca.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGzYEyztyFS2xBnYGuX52S88hxIj9BfPSAUEeits8zVC0EaLQF5R0h7w',
    id: 2738,
    acronym: 'UADCA',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'uca.ac.cr',
    name: 'Universidad de Cartago Florencio del Castillo',
    web_page: 'http://www.uca.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScGk2H1EqRE28-or3U3M1GLaejKo7ymmR_ot8F2FpN53Ux5kZ3xbh-SQ',
    id: 2739,
    acronym: 'UDCFDC',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'ucatolica.ac.cr',
    name: 'Universidad Católica Anselmo Llorente',
    web_page: 'http://www.ucatolica.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj4DeEhWJcbOcn3fvXyVQnOYadhf08w_3p7IC6DQla8AzDtboulfoULckT',
    id: 2740,
    acronym: 'UCAL',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'uci.ac.cr',
    name: 'Universidad para la Cooperación Internacional',
    web_page: 'http://www.uci.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfUtHnbx0BopruDSuxJjRH8T_jue6t40frsZ0qUR0i2CKM_GlCYETaNaY',
    id: 2741,
    acronym: 'UPLCI',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'ucr.ac.cr',
    name: 'Universidad de Costa Rica',
    web_page: 'http://www.ucr.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpPiWiqRHLiI9bQDqPU9mEePnyUGj243hMab2ONPJhLD_6MKFLhyMr5A',
    id: 2742,
    acronym: 'UDCR',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'ufidelitas.ac.cr',
    name: 'Universidad Fidélitas',
    web_page: 'http://www.ufidelitas.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR60_tUsn6ptZMfwmcF64a1HiHlRBlZy9t1me54LTEoXT4wJagLaFyMHw',
    id: 2743,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'uhispanoamericana.ac.cr',
    name: 'Universidad Hispanoamericana',
    web_page: 'http://www.uhispanoamericana.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlWfGrtKRtJSXnT1kODl8bqZichGvC443cHXhDPlojHhebDxmDlDleaNU',
    id: 2744,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'uia.ac.cr',
    name: 'Universidad Internacional de las Américas',
    web_page: 'http://www.uia.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwwu_F6zKeZVxFdkgBxnZURiAI_36hksHqDRdwt9zmx3axDUgsy-Ph0Q',
    id: 2745,
    acronym: 'UIDLA',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'ulacit.ac.cr',
    name: 'Universidad Latinoamericana de Ciencia y Tecnología',
    web_page: 'http://www.ulacit.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5szmvQXKTc9j2_CxaJVLEaJG7iV5UO3AxhtXjpc3dNFHGRZgsIDAyIT4',
    id: 2746,
    acronym: 'ULDCYT',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'ulatina.ac.cr',
    name: 'Universidad Latina de Costa Rica',
    web_page: 'http://www.ulatina.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgJX3RNztq0aR07D9MnvOg48_4W9nun3WZg9sHGdZB1HNRKEiLRV82st4L',
    id: 2748,
    acronym: 'ULDCR',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'ulicori.ac.cr',
    name: 'Universidad Libre de Costa Rica',
    web_page: 'http://www.ulicori.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpPiWiqRHLiI9bQDqPU9mEePnyUGj243hMab2ONPJhLD_6MKFLhyMr5A',
    id: 2749,
    acronym: 'ULDCR',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'umca.net',
    name: 'Universidad Metropolitana Castro Carazo',
    web_page: 'http://www.umca.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHuT1_bOwubiGijjtqk3lUj7v-q9QRZopmcxp0yjCxIu2JyiiO35ul6Q',
    id: 2750,
    acronym: 'UMCC',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'una.ac.cr',
    name: 'Universidad Nacional',
    web_page: 'http://www.una.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu71DXWUqc2NfQCqSoNwvTI36CxAAtyuQmU-pN28yLZGMGbicMoRIUHhI',
    id: 2751,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'unadeca.ac.cr',
    name: 'Universidad Adventista de Centro América',
    web_page: 'http://www.unadeca.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyNl3uFfFWzlbt_LBf9AY3IL6jqPUUQCCBYQGCoK9nGgY10Obg4t1PBcKE',
    id: 2752,
    acronym: 'UADCA',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'unam.ac.cr',
    name: 'Universidad Autonoma Monterrey',
    web_page: 'http://www.unam.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE-Iim5xlBWFkHrp-Z1IOwz0qcO1oQqKK-hQl41cOirjTVNT9VlnZhiNc',
    id: 2753,
    acronym: 'UAM',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'uned.ac.cr',
    name: 'Universidad Estatal a Distancia',
    web_page: 'http://www.uned.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ84nyG7jtLsUdQIJjOpb_iRZLkoavflBwH9qIfQeHEyln3-7nLyxo-ZfU',
    id: 2754,
    acronym: 'UEAD',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'unem.edu',
    name: 'Universidad Empresarial',
    web_page: 'http://www.unem.edu/internacional/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStyzlcFYPpM58w8p_CzLPKfj8-ecq_Mffgz-4x1OCrZuc1wsJThdkykQ',
    id: 2755,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'unibe.ac.cr',
    name: 'Universidad de Iberoamérica',
    web_page: 'http://www.unibe.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4c4vjfGf24iMrLXnxx4qqTl-8oYWvPhLuQg1dglx7Fm9wYKtbSJxeRw',
    id: 2756,
    acronym: 'UDI',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'universidadbrauliocarrillo.com',
    name: 'Universidad Braulio Carrillo',
    web_page: 'http://www.universidadbrauliocarrillo.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDv7UiFDjd7IiiJTXz0f5ZnukSs_13v2ftR0NhvkuzuxhxFEXAJQeM7w3y',
    id: 2757,
    acronym: 'UBC',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'universidadsanjosecr.com',
    name: 'Universidad de San José',
    web_page: 'http://www.universidadsanjosecr.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK3JTTkgzW8YKf1S0gnWWZHvHIAorjgSccu53kqMf2cNKXfnfGoHR4kA',
    id: 2759,
    acronym: 'UDSJ',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'upeace.org',
    name: 'University for Peace',
    web_page: 'http://www.upeace.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRq3XqglGAr0T-sDbUgZX_vSHFLY_tE5pbNBeuJcxSzRK4oCfAJIme-CAU',
    id: 2760,
    acronym: 'UFP',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'usam.ac.cr',
    name: 'Universidad San Marcos',
    web_page: 'http://www.usam.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1G7oiSqEQ3XerWx1NuQ6vN2sIgZ6mxMHp4thhEm5lJgMBt4huH1Vn9EAi',
    id: 2761,
    acronym: 'USM',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'uspsantapaula.com',
    name: 'Universidad Santa Paula',
    web_page: 'http://www.uspsantapaula.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD120GmotFca0704mqHG5UtqeCy1emUseL95VHBQDLfOXuZ2n2jsI9_Tev',
    id: 2762,
    acronym: 'USP',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'utur.ac.cr',
    name: 'Universidad del Turismo',
    web_page: 'http://www.utur.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXLBaaN2OiiUzlbCVxuPxCeMVSz7vz6yYUqTBjLERyjp38SG7pM2sQt2s',
    id: 2763,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'CR',
    country: 'Costa Rica',
    domain: 'uveritas.ac.cr',
    name: 'Universidad Veritas',
    web_page: 'http://www.uveritas.ac.cr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPAQydle0-tfVLDMAe6YIU4PgNExBcCUyXBI5ofkgJikgYaQfCbyQqIA',
    id: 2764,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'CI',
    country: "Côte d'Ivoire",
    domain: 'uabobo.ci',
    name: "Université d'Abobo-Adjamé",
    web_page: 'http://www.uabobo.ci/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIi5K1QMzkD1QvAwy1kMymQzmtXwwxHJ1Tm_CmiWN-zkaucsRlsVQM0h-baA',
    id: 2765,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'CI',
    country: "Côte d'Ivoire",
    domain: 'ubouake.ci',
    name: 'Université de Bouaké',
    web_page: 'http://www.ubouake.ci/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQW6RNA-EbkS1doN6b-CU-VOQq4stTn6zgQfJcF-AQzbhXg7SUQNx5lyyc',
    id: 2766,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'CI',
    country: "Côte d'Ivoire",
    domain: 'univ-cocody.ci',
    name: 'Université de Cocody',
    web_page: 'http://www.univ-cocody.ci/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQetiw7mTTTtgb9TS9dEX-Sq9jUupbtOfvf96zdIBTC-i4_qAOej8_sGZs',
    id: 2767,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'tabari.ac.ir',
    name: 'Tabari Institute of Higher Education',
    web_page: 'http://www.tabari.ac.ir/ ',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOLL3lDdZuvpTvVwn3Mo-15ppwvHbWAeaEWFUI2GKo004sfK5nUtFF6n6U',
    id: 2768,
    acronym: 'TIHE',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'zut.edu.pl',
    name: 'Zachodniopomorska School of Science and Engineering',
    web_page: ' http://www.zut.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRgComhAIRvFm270BccyRS5FNb6cnPCvCZN-5kPi3hH6bFKOKYpHl2Cg',
    id: 2769,
    acronym: 'ZSSE',
  },
  {
    alpha_two_code: 'HR',
    country: 'Croatia',
    domain: 'unidu.hr',
    name: 'University of Dubrovnik',
    web_page: 'http://www.unidu.hr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtRwVgaMNCXW7BALzMO5OfLXY6lr_6HtlW16WAJiTrPkE4WfwNN_qcMFo',
    id: 2770,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'HR',
    country: 'Croatia',
    domain: 'unios.hr',
    name: 'University of Osijek',
    web_page: 'http://www.unios.hr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRulZI-lNtlGntgG9bnoHp_h99cSuoobEyt95zvaJKcgA58ANK49vG27w',
    id: 2771,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'HR',
    country: 'Croatia',
    domain: 'unipu.hr',
    name: 'University of Pula',
    web_page: 'http://www.unipu.hr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUH91wm8Ne-jCNwBOsJlqxTD0e-one97Vk61NF-c7La3upb7x904ra3Uc',
    id: 2772,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'HR',
    country: 'Croatia',
    domain: 'uniri.hr',
    name: 'University of Rijeka',
    web_page: 'http://www.uniri.hr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlEXle5vpMdajS4RRBjv1my0vAFtfDpHRXj7SS8FzVSBvqNTX5ESRpbg',
    id: 2773,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'HR',
    country: 'Croatia',
    domain: 'unist.hr',
    name: 'University of Split',
    web_page: 'http://www.unist.hr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9kt6sopuZYrVcklfDravxiH7RZQffinNkF3cPgN_mAvVgkOo8tE07MjY',
    id: 2774,
    acronym: 'US',
  },
  {
    alpha_two_code: 'HR',
    country: 'Croatia',
    domain: 'unizd.hr',
    name: 'University of Zadar',
    web_page: 'http://www.unizd.hr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMbOQpzit85NOACqjCR5RsP_c79pO1J4UMH9mh1YiAJTY74N0j7wJ5bA',
    id: 2775,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'HR',
    country: 'Croatia',
    domain: 'unizg.hr',
    name: 'University of Zagreb',
    web_page: 'http://www.unizg.hr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhdlt3FeMlySC-E_XFwDcuzunTRHx1jpfzyjPlDCHfP-98ExSZ6gt5hBg',
    id: 2776,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'HR',
    country: 'Croatia',
    domain: 'zsem.hr',
    name: 'Zagreb School of Economics and Management',
    web_page: 'http://www.zsem.hr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSelm9yhQEjUqUKex5dSuRwQLxnOyD_3E3JyBPZ1WrrXKsSQ8Vvie5aPW8',
    id: 2777,
    acronym: 'ZSEM',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'cujae.edu.cu',
    name: 'Instituto Superior Politécnico José Antonio Echeverría',
    web_page: 'http://www.cujae.edu.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfwHLrq17ss1yukzXS61U0QoXFCH7ltXCHvWYh0Vpa4WYUzPFzslrRTQ',
    id: 2778,
    acronym: 'ISPJAE',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'ismm.edu.cu',
    name: 'Instituto Superior Minero Metalúrgico "Dr. Antonio Núñez Jiménez"',
    web_page: 'http://www.ismm.edu.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlE12yNTDYDXOKuUAfLV7SJq8_urE0Xv0jJ0jvMFcb9vp22vzb9VRuMcA',
    id: 2779,
    acronym: 'ISMM"ANJ',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'ispcmw.rimed.cu',
    name: 'Universidad Pedagógica "José Martí" Camagüey',
    web_page: 'http://www.ispcmw.rimed.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5pnmZ6DjFP0gnkPMSRJM3Nr4km92-i_3afD9nG77pA5hv6bRf1lKmMQ',
    id: 2780,
    acronym: 'UP"MC',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'reduc.edu.cu',
    name: 'Universidad de Camagüey',
    web_page: 'http://www.reduc.edu.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbk87za7eYV_y3VIEWEo0Fwaqj2Lgd4J0ZUacztmio-akqgUjOxbc96qQ',
    id: 2781,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'ucf.edu.cu',
    name: 'Universidad de Cienfuegos',
    web_page: 'http://www.ucf.edu.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHh-q7DFXjSoUy4hL-MuQ6YtTSUPcS8fVwfOPNwBUr_UFLTM-nDpOASD4',
    id: 2782,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'uclv.edu.cu',
    name: 'Universidad Central de Las Villas',
    web_page: 'http://www.uclv.edu.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLKrT1W8v4Zv5wgIjoFw-MQQY_SrzgoDuZ-bewYoBUSNBIxrlyfK44oDk',
    id: 2783,
    acronym: 'UCDLV',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'udg.co.cu',
    name: 'Universidad de Granma',
    web_page: 'http://www.udg.co.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWvkMu7ek3M0wVarkZnntULoKzVijKUdwToaW8P6Qo814r-anJ12gcDlA',
    id: 2784,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'uh.cu',
    name: 'Universidad de La Habana',
    web_page: 'http://www.uh.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3iLez7_mRnlDaaNjNYCRjGAmCL8tVQ1Q9fQh3LRkq8W2G5wXi72DgwHeE',
    id: 2785,
    acronym: 'UDLH',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'uho.edu.cu',
    name: 'Universidad de Holguín',
    web_page: 'http://www.uho.edu.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 2786,
    acronym: 'UDH',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'umcc.cu',
    name: 'Universidad de Matanzas Camilo Cienfuegos',
    web_page: 'http://www.umcc.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuEyGop3lzMCKNnB0ERmsFrMfmJmSvmujM_95_Z19lN5CDTde5YcbR84M',
    id: 2787,
    acronym: 'UDMCC',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'unica.cu',
    name: 'Universidad de Ciego de Avila',
    web_page: 'http://www.unica.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRf-UlPtqQ-lP2JlrLQ7pkK8kjbXI_3Qhu31L0EgYSPD51Q9RI9n5fEy954',
    id: 2788,
    acronym: 'UDCDA',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'uo.edu.cu',
    name: 'Universidad de Oriente',
    web_page: 'http://www.uo.edu.cu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIlmFjlCkFKZC3Odf6O98XNKMZzwJv3EuMc_8QqffvWXd4nhzN3Z_35A',
    id: 2789,
    acronym: 'UDO',
  },
  {
    alpha_two_code: 'CU',
    country: 'Cuba',
    domain: 'upr.edu.cu',
    name: 'Universidad de Pinar del Río',
    web_page: 'http://www.upr.edu.cu/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRVchgHM0CyG0WBli_Ua7xuxI4mB4Tnvg3i-PrwCX1xPhyuGxCb9RchCP6D',
    id: 2790,
    acronym: 'UDPDR',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'ac.ac.cy',
    name: 'Americanos College',
    web_page: 'http://www.ac.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTueySK8oGoaqslM5Qf4EeADGs2pl53NN_of9Wi5j4C2Nr5BWCSYIwK_w',
    id: 2791,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'ciim.ac.cy',
    name: 'Cyprus International Institute of Management (CIIM)',
    web_page: 'http://www.ciim.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAsQN_P4QHqSxnA3oagCtm3auj-qb9OG6geE5sDI8mMw_zQ4GVykYaQO8',
    id: 2792,
    acronym: 'CIIM(',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'ciu.edu.tr',
    name: 'Cyprus International University',
    web_page: 'http://www.ciu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStF3hIup1B5naAAO_6z1oa4kgZ3GbslMaoquIlBXOeF6oiqXpw8oODzg',
    id: 2793,
    acronym: 'CIU',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'ctleuro.ac.cy',
    name: 'The CTL Eurocollege',
    web_page: 'http://www.ctleuro.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb7iv8r-AqMVd_FAcKpFklXTyXnWE3Fn-FAGHjtwVoPON0kVhU3kqIAzA',
    id: 2794,
    acronym: 'CE',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'cut.ac.cy',
    name: 'Cyprus University of Technology',
    web_page: 'http://www.cut.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvSDqgke_-LUscexBCgo5DJrSGknyJw0sw5ZJk7mT0mTiXl1860rHjDjE',
    id: 2795,
    acronym: 'CUT',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'cycollege.ac.cy',
    name: 'Cyprus College',
    web_page: 'http://www.cycollege.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNmlOlVLgNOXu7e_avQx1y99qoXiatCxRFUFEVm7HakD5bXBnjg0CPrw',
    id: 2796,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'emu.edu.tr',
    name: 'Eastern Mediterranean University',
    web_page: 'http://www.emu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgTT34udM5Tr5Yw-gP0vjGCxIMdKqWQxQEWm7M5LML-VaNMEcfQ5WJJQ',
    id: 2797,
    acronym: 'EMU',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'euc.ac.cy',
    name: 'European University Cyprus',
    web_page: 'http://www.euc.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1HgG_b2gZkdmM5K_6qz0agALvzouU0MgsCE79X3TSBZS4w63lo0rye6g',
    id: 2798,
    acronym: 'EUC',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'frederick.ac.cy',
    name: 'Frederick University',
    web_page: 'http://www.frederick.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6UJO-XyPy8bQYvF3UC27nIAIxI-3ELS2nO_gulte_M5_pW3bSXmsPLgg',
    id: 2799,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'gau.edu.tr',
    name: 'Girne American University',
    web_page: 'http://www.gau.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtyid690uxujPLGt-68oJFhOPNZ7HKfJLt0E3Hj26McuS3vGrjBPpVNcw',
    id: 2800,
    acronym: 'GAU',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'intercollege.ac.cy',
    name: 'Intercollege',
    web_page: 'http://www.intercollege.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLtwOnjhLHH1PE6jcsZ8YzjsUTVheSHcDA2166iTERtt1-ISitn7-VZc0',
    id: 2801,
    acronym: 'I',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'lefke.edu.tr',
    name: 'European University of Lefke',
    web_page: 'http://www.lefke.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyTf3MGO1KLV_95IiL5qqbyo44x4z2ooQojHD349Qh9XGkoydrOm8MYw',
    id: 2802,
    acronym: 'EUL',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'neu.edu.tr',
    name: 'Near East University',
    web_page: 'http://www.neu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_YVK6OmQeJGtXpJcfPs3u4FXFyblQ6TdLBjtcKLYUdKvIhuOBmLYW5g',
    id: 2803,
    acronym: 'NEU',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'ouc.ac.cy',
    name: 'Open University of Cyprus',
    web_page: 'http://www.ouc.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkUFhkpyOEKkh3byhCxeXar6VV4gWcf_AHS4J0bN9oBuoewaCDx_Z5128',
    id: 2804,
    acronym: 'OUC',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'ucy.ac.cy',
    name: 'University of Cyprus',
    web_page: 'http://www.ucy.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkUFhkpyOEKkh3byhCxeXar6VV4gWcf_AHS4J0bN9oBuoewaCDx_Z5128',
    id: 2805,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'CY',
    country: 'Cyprus',
    domain: 'unic.ac.cy',
    name: 'University of Nicosia',
    web_page: 'http://www.unic.ac.cy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLr7eKml2663bW4-LdjH-dR-4qSLydFynuGPXviQULs42Bt1t_ZLRA-w',
    id: 2806,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'aauni.edu',
    name: 'Anglo-American University',
    web_page: 'http://www.aauni.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtxJksnhk4F1mOtvMkczD-Wz63Bh_Is_mO_O-6wWPA2yW7r-5ctC5IYkA',
    id: 2807,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'cuni.cz',
    name: 'Charles University Prague',
    web_page: 'http://www.cuni.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqJv9WcAHgeLOsABxbErGqSiqXNJE-qIzcy9ldmsikULaNFl8gJ8IuKg',
    id: 2808,
    acronym: 'CUP',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'cvut.cz',
    name: 'Czech Technical University of Prague',
    web_page: 'http://www.cvut.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2aYeuSHGUBsRGnDbDwh-H4dgQ_Z_lALxn8dDCAc9dxf5i-meG2c7D4VA',
    id: 2809,
    acronym: 'CTUP',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'czu.cz',
    name: 'Czech University of Agriculture Prague',
    web_page: 'http://www.czu.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeVJPhGh1c_H_iQeLbX1K09bQO3lRCpEdNfXE02oOxCNNbF31HPJJirw',
    id: 2810,
    acronym: 'CUAP',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'famu.cz',
    name: 'Academy of Performing Arts Film and TV Fakulty',
    web_page: 'http://www.famu.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLPe093ScS4agINLhL_NmA8LSekOT6U7oMZHirh_MtZIckRbdI2a6X-w4E',
    id: 2811,
    acronym: 'APAFTF',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'jcu.cz',
    name: 'University of South Bohemia',
    web_page: 'http://www.jcu.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjUP4tXXBUuDJwCljNfutJoLnZEq2XjkdLhGqkgZqPSEqnYEhFFswNdew',
    id: 2812,
    acronym: 'USB',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'mendelu.cz',
    name: 'Mendel University of Agriculture and Forestry',
    web_page: 'http://www.mendelu.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-gZvsRt-kXQklqe6SIoiowxDzKvsxa_rP3ht3p-Ae5vTuuHyJstJFjw',
    id: 2813,
    acronym: 'MUAF',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'mpu-prague.cz',
    name: 'Prague International University',
    web_page: 'http://www.mpu-prague.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKo4CZKfd1yLB0hY1FrgVZAoB6aTKjcY27NyRzd3qy-to-V6U_A7rMqg',
    id: 2814,
    acronym: 'PIU',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'muni.cz',
    name: 'Masaryk University',
    web_page: 'http://www.muni.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTalQdRkjtkqvzuc63N_v6_9R06wKIiiAAw84SnGDHm2NZqcfkCQozo2JE',
    id: 2815,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'osu.cz',
    name: 'University of Ostrava',
    web_page: 'http://www.osu.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQORqFAJ52qMAlvCI-cB6knTyduWM-CwXy1lEqYCJXLuyMUAmGaB6El9A',
    id: 2816,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'praguecollege.cz',
    name: 'Prague College',
    web_page: 'http://www.praguecollege.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu7zNYn8P3905skqr5LyqjneuT694o3RcfFClXLc1xvxzbeqKFumUBW9A',
    id: 2817,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'slu.cz',
    name: 'Silesian University',
    web_page: 'http://www.slu.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6wy-an_O2BzK9u-TRAc85E8TZtQ8RvjHxQNqWyWi-WArohGnuILT-XGA',
    id: 2818,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'tiffinprague.cz',
    name: 'Tiffin University Prague',
    web_page: 'http://www.tiffinprague.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIyQuMUsrAlX68qA12ISN8S4D74fHW-2urooaJqKxSopcq4-8pVwD-MQ',
    id: 2819,
    acronym: 'TUP',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'ujep.cz',
    name: 'University of Jan Evangelista Purkyne',
    web_page: 'http://www.ujep.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTM3-Di38fT9NH7F4um90OcHmVSDk8-_Dy7uAsEBcZkPR0gFDCAwoZVTSw',
    id: 2820,
    acronym: 'UJEP',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'unva.cz',
    name: 'University of Northern Virginia Prague Campus',
    web_page: 'http://www.unva.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThRA8VZx3IvxQPc-EhXWUs0xSbD6WnkJpUTIztVjMHX2Li92Dfi3ogJg',
    id: 2821,
    acronym: 'UNVPC',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'unyp.cz',
    name: 'University of New York in Prague',
    web_page: 'http://www.unyp.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6cyg8u5kFZ1gdoYSSzou0737Enfft8P9vQM1wfPB0U1CXZiEKA7JZPTs',
    id: 2822,
    acronym: 'UNYIP',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'upce.cz',
    name: 'University of Pardubice',
    web_page: 'http://www.upce.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL5rAzB6N-g7h9bG3PKiLaH1kAR6ckyumPNLaoThP7DuBE26WQx-Iy0SM',
    id: 2823,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'upol.cz',
    name: 'Palacky University',
    web_page: 'http://www.upol.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs7yk9AX3hmfRDeodFDdU1XJzlTw2pDk_FqbpjOzTjksVrQ_wrV12Iasc',
    id: 2824,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'utb.cz',
    name: 'Tomas Bata University in Zlin',
    web_page: 'http://www.utb.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXSJ58rUR46XvAT7-EpCKi31kTj8_mJihV8qy1ocvYxHsf8Cb-ZstQbA8',
    id: 2825,
    acronym: 'TBUIZ',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'vfu.cz',
    name: 'University of Veterinary and Pharmaceutical Science',
    web_page: 'http://www.vfu.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRydP-Cl8YAMSmVyAjMEsKnWG4kZ3zopLpWEDM86W2kDDOGIloy1iJLlZo',
    id: 2826,
    acronym: 'UVPS',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'vsb.cz',
    name: 'Technical University of Mining and Metallurgy Ostrava',
    web_page: 'http://www.vsb.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTop7np-5I3QieTb0vY2mSC31yXn_l2B3iDH0l4w9HSgjwWb9QllHknSQ',
    id: 2827,
    acronym: 'TUMMO',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'vscht.cz',
    name: 'Prague Institute of Chemical Technology',
    web_page: 'http://www.vscht.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLhPmj6iHDw8WYzjv5gtMu-4LEWchI9YtM5RR0yM_Uv8YU2PXmmIVpXg',
    id: 2828,
    acronym: 'PICT',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'vsci.cz',
    name: 'Cevro Institut College',
    web_page: 'http://www.vsci.cz/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcS-AmNpm0FCoYTsrchGFIIVkWiLRPfLeVMfu5-SJCySq7rZL-_pkffpnA',
    id: 2829,
    acronym: 'CIC',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'vse.cz',
    name: 'University of Economics Prague',
    web_page: 'http://www.vse.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbyHh6oo-P_T2DoqB4t_SCHJvHar88qQX97actV0d0GeVq5PivXIcDLg',
    id: 2830,
    acronym: 'UEP',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'vslib.cz',
    name: 'Technical University of Liberec',
    web_page: 'http://www.vslib.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsuvqMXWEhYykGCLga1Scz1Kw6vrkSmrIC2Jos4iZOFWZrL15LuLK42A',
    id: 2831,
    acronym: 'TUL',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'vsp.cz',
    name: 'University of Education Hradec Kralove',
    web_page: 'http://www.vsp.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTflmQRc8xOuQMFDMyHh62MzmPVCdeY0S5utUsHpsRyUxdBHPnD4EL070o8',
    id: 2832,
    acronym: 'UEHK',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'vutbr.cz',
    name: 'Brno University of Technology',
    web_page: 'http://www.vutbr.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSo8S6ki6Tsw_ZSGEb50epj_2sHLECggR4_9jLKabJI0oejkwLEflJPw',
    id: 2833,
    acronym: 'BUT',
  },
  {
    alpha_two_code: 'CZ',
    country: 'Czech Republic',
    domain: 'zcu.cz',
    name: 'University of West Bohemia',
    web_page: 'http://www.zcu.cz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV-vXgQKMo5PqKlnBWLyceB8eF9QYAFtMrw6rzTEcwIysubb7Fjo1p8YuC',
    id: 2834,
    acronym: 'UWB',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'a-aarhus.dk',
    name: 'Aarhus School of Architecture',
    web_page: 'http://www.a-aarhus.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbnErCFL8upxguWlu1xoPtH79HsNutTGC8wVDIf3yUw50u17VXGqA2LV4',
    id: 2835,
    acronym: 'ASA',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ah.dk',
    name: 'Aalborg Business College',
    web_page: 'http://www.ah.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVouTz3tKouSCvOiMXkhZrniCGDrsTfA3wurvzsSQXtVaEJUS4B14LSwA',
    id: 2836,
    acronym: 'ABC',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ats.dk',
    name: 'Aarhus Technical College',
    web_page: 'http://www.ats.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 2837,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'auc.dk',
    name: 'Aalborg University',
    web_page: 'http://www.auc.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnqsHck9Kx9jmuDJbmfaGvB2bJcAIIP1uQqfaAvx9LR7OY5vgmKoLmTw',
    id: 2838,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'au.dk',
    name: 'Aarhus University',
    web_page: 'http://www.au.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 2839,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'cbs.dk',
    name: 'Copenhagen Business School',
    web_page: 'http://www.cbs.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHoFIWBqJOaRhq7_CZfcEBXh9YOodIbOxbpb_S1qK4mVxIHyHh5MM3KFM',
    id: 2840,
    acronym: 'CBS',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'db.dk',
    name: 'Royal School of Library and Information Science',
    web_page: 'http://www.db.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEmP27dZk-rzXBSKiv9kC91CBu5jF53FNS7cCMvRdcfKy79IA01lVAuw',
    id: 2841,
    acronym: 'RSLIS',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ddea.dk',
    name: 'Danish Business Academy',
    web_page: 'http://www.ddea.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvEs1jPhkYlLF-vyjQtsg1UY5osojdwBbYU4WIbTZzgcI7p7u-pXFxkw',
    id: 2842,
    acronym: 'DBA',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'dfh.dk',
    name: 'Royal Danish School of Pharmacy',
    web_page: 'http://www.dfh.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsER-yqo7gfeqCdbpBpWtg3-cGjeGKPqBIY74rhOt5KVncRt9ZwNHGS4U3',
    id: 2843,
    acronym: 'RDSP',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'dkdm.dk',
    name: 'Royal Danish Academy of Music',
    web_page: 'http://www.dkdm.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1zkJhLN04uI0P_u0_qaAZpd989LcfMiZlV9XGfuGPdseOgLAXYmoiwibh',
    id: 2844,
    acronym: 'RDAM',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'dlh.dk',
    name: 'Royal Danish School of Educational Sciences',
    web_page: 'http://www.dlh.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEmP27dZk-rzXBSKiv9kC91CBu5jF53FNS7cCMvRdcfKy79IA01lVAuw',
    id: 2845,
    acronym: 'RDSES',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'dpu.dk',
    name: 'Danish University of Education',
    web_page: 'http://www.dpu.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUZO2bFDsJUzUDl8VRHGRNrAC9zBr1ZKirVcwSn7zodqbqJrl_UOEh2O8',
    id: 2846,
    acronym: 'DUE',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'dtu.dk',
    name: 'Technical University of Denmark',
    web_page: 'http://www.dtu.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRReXMeJjVnVjncxriFmZVyr8cNsTrB5LQbBs6rSDtLVvfvlD7JJ6a8JWc',
    id: 2847,
    acronym: 'TUD',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'hha.dk',
    name: 'Aarhus School of Business',
    web_page: 'http://www.hha.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT96zf76fUGCKTKVvEJq-10HXllpWg-1v9LgMhVjzfMDIIFd5RSPff2g',
    id: 2848,
    acronym: 'ASB',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'hhs.dk',
    name: 'Southern Denmark Business School',
    web_page: 'http://www.hhs.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHoFIWBqJOaRhq7_CZfcEBXh9YOodIbOxbpb_S1qK4mVxIHyHh5MM3KFM',
    id: 2849,
    acronym: 'SDBS',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ibss.eu',
    name: 'International Business School of Scandinavia',
    web_page: 'http://www.ibss.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrNd878S76nvopLnOJwcwqFJNofxLOzWK_yNC2zYZAyALYRZ1ZjTFYjYE7',
    id: 2850,
    acronym: 'IBSS',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'iha.dk',
    name: 'Engineering College of Aarhus',
    web_page: 'http://www.iha.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBCinkiewYqa8fz5dh0MWRYh9dxPxluTQbMTwsZQ1y_GY-zpi8OlZSbg',
    id: 2851,
    acronym: 'ECA',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ihk.dk',
    name: 'Engineering College of Copenhagen',
    web_page: 'http://www.ihk.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtMQeZeABfbVyRxVB4lCkxa2tSc5vjbPWrra0lphPcgGKPPRZPZK2-NA',
    id: 2852,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ipc.dk',
    name: "International People's College",
    web_page: 'http://www.ipc.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-2qkELf-R9OsG5OEg0Pygvp8JhDJnWnCA-YgnwXklPshQR-9gPPUXBW7Y',
    id: 2853,
    acronym: 'IPC',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'itu.dk',
    name: 'IT University of Copenhagen',
    web_page: 'http://www.itu.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRDGVHAxl4-j8RK1oJWwgglw4_H_5zbhgec9wrzRQe5bMKsGSdF7xkbg',
    id: 2854,
    acronym: 'IUC',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'karch.dk',
    name: 'Royal Danish Academy of Fine Arts School of Architecture',
    web_page: 'http://www.karch.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfBI8GUmWYtPSjJiSFLAnKlDTOTkDcTlGgGgTUmnOWw27TAyNw5xEwCQ',
    id: 2855,
    acronym: 'RDAFASA',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'knightsbridgeuniversity.com',
    name: 'Knightsbridge University',
    web_page: 'http://www.knightsbridgeuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJU9QJjRkpGwdlPew8PNlmHCLl-IqdyKURhUrVz8RP4oSQQJWU9UeM5Q',
    id: 2856,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ku.dk',
    name: 'Copenhagen University',
    web_page: 'http://www.ku.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlbiAs_tlE52g-qQ99oKsFbJqCenQO44-qnAZVw2gKW2ofem82CIhvIA',
    id: 2857,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'kunstakademiet.dk',
    name: 'Royal Danish Academy of Fine Arts School of Visual Art',
    web_page: 'http://www.kunstakademiet.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI8pvkCO6_P3K-A9xVkA0rsUf1Rj7svWDX6nXEDSs-w35QIQirhXnJhb4',
    id: 2858,
    acronym: 'RDAFASVA',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'kvl.dk',
    name: 'Royal Veterinary and Agricultural University',
    web_page: 'http://www.kvl.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRhJav1KiCssxL1bS8tS9HXsweD39kcLrBdvY9jljNR_Wsm6dgtKuDDmw',
    id: 2859,
    acronym: 'RVAU',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'musik-kons.dk',
    name: 'Royal Academy of Music',
    web_page: 'http://www.musik-kons.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1cVftItVq-rwPx9xS9iidtBkTwhyq5ENdVtp97YwdSw3hNqNvAhGgHQks',
    id: 2860,
    acronym: 'RAM',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ou.dk',
    name: 'Odense University',
    web_page: 'http://www.ou.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOH0Df8jEEk_jIVWCszcSJ49J6KGPmHIgzbzBWiGDdsXqxhEANGBgZ_VU',
    id: 2861,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'ruc.dk',
    name: 'Roskilde University',
    web_page: 'http://www.ruc.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkYB-yukmnvrYpo4oe98po0I1-vdCOu17rLTr3ZDC4kNzQj1WXGCdZya0',
    id: 2862,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'sdu.dk',
    name: 'University of Southern Denmark',
    web_page: 'http://www.sdu.dk/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQUZO2bFDsJUzUDl8VRHGRNrAC9zBr1ZKirVcwSn7zodqbqJrl_UOEh2O8',
    id: 2863,
    acronym: 'USD',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'teologi.dk',
    name: 'Lutheran School Of Theology In Aarhus',
    web_page: 'http://www.teologi.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuR7x3hk7DiAcj454XzKVSYDz0omw7IHBrc8UYf5CpJBHPaFFL8_cQDA',
    id: 2864,
    acronym: 'LSTIA',
  },
  {
    alpha_two_code: 'DK',
    country: 'Denmark',
    domain: 'tietgen.dk',
    name: 'Tietgen Business College',
    web_page: 'http://www.tietgen.dk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr0Xb8F4cxo9mRBi1C7Ns7G43qahFxveobmf_-BhJf5vvXZEHMIHHHuY0',
    id: 2865,
    acronym: 'TBC',
  },
  {
    alpha_two_code: 'DJ',
    country: 'Djibouti',
    domain: 'univ.edu.dj',
    name: 'Université de Djibouti',
    web_page: 'http://www.univ.edu.dj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRDZGEUVqAHy5L8NFXCyKY-pqm5LmRJm8Yf2QXB_JPg9ZtZahrpLXyW50',
    id: 2866,
    acronym: 'UDD',
  },
  {
    alpha_two_code: 'DM',
    country: 'Dominica',
    domain: 'dsc.dm',
    name: 'Dominica State College',
    web_page: 'http://www.dsc.dm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0R8x4wiunDkJ0jgB7ar2NUnBg0nxhGVfpSXGet4LpHl50pQfGuDMNkwQ',
    id: 2867,
    acronym: 'DSC',
  },
  {
    alpha_two_code: 'DM',
    country: 'Dominica',
    domain: 'newworld.ac',
    name: 'New World University',
    web_page: 'http://www.newworld.ac/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeEUITfWC8AqqpZpxvXR8nnhBGwqtrj1OdQpnsVIVS65C9WSZ7k5hmIgs',
    id: 2868,
    acronym: 'NWU',
  },
  {
    alpha_two_code: 'DM',
    country: 'Dominica',
    domain: 'rossu.edu',
    name: 'Ross University Caribbean School of Medicine',
    web_page: 'http://www.rossu.edu/medical-school/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4mjkJn8CQ-X2ibDwJuxeSSjHwplFgBdgXKDuVLtLPUaDuN6Y_5tx20K4',
    id: 2869,
    acronym: 'RUCSM',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: '66.98.64.31',
    name: 'Universidad Central Dominicana de Estudio Profesionales',
    web_page: 'http://66.98.64.31/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR16ZBSv7wDNDpMD0jE5s7Gm3aT3qyj1ID66qNTc8iEvQzbX_H_jqz_ZPo3',
    id: 2870,
    acronym: 'UCDDEP',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'icda.edu.do',
    name: 'Universidad Dominico-Americana',
    web_page: 'http://www.icda.edu.do/espanol/unicda/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYLOmLWXbpOpPWkN5gplNz95aifWy342kw_2gcqDVx72V8LOyY6lArXOs-',
    id: 2871,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'intec.edu.do',
    name: 'Instituto Tecnológico de Santo Domingo',
    web_page: 'http://www.intec.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0LbeczmobhFHR98d8SiiAaIiHj-K2GRbIHm6g9DgGXvHHnO6BYrs6boo',
    id: 2872,
    acronym: 'ITDSD',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'pucmm.edu.do',
    name: 'Pontificia Universidad Católica Madre y Maestra (PUCMM)',
    web_page: 'http://www.pucmm.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdynpCeRLIZPyalFC0PjZF6dt9U0fCUK0ByXQwC7mXLLAcufRW9zKDvw',
    id: 2873,
    acronym: 'PUCMYM(',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'pucmm.edu.do',
    name: 'Universidad Católica Madre y Maestra',
    web_page: 'http://www.pucmm.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdynpCeRLIZPyalFC0PjZF6dt9U0fCUK0ByXQwC7mXLLAcufRW9zKDvw',
    id: 2874,
    acronym: 'UCMYM',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'uafam.edu.do',
    name: 'Universidad Agroforestal Fernando A.Meriño',
    web_page: 'http://www.uafam.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoU1SRHt29XKcOsts_fBYn0ZBDtLdD1L5qPCTvQz_TtAJaWSMXIRC8yyk',
    id: 2875,
    acronym: 'UAFA',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'uapa.edu.do',
    name: 'Universidad Abierta Para Adultos',
    web_page: 'http://www.uapa.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSu93BVuieSOReAETQn0JG98UtGODBComyFQEuQEVjYJlJqg-jQ7-kUglU',
    id: 2876,
    acronym: 'UAPA',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'uasd.edu.do',
    name: 'Universidad Autónoma de Santo Domingo',
    web_page: 'http://www.uasd.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpF9IO6ZGHCSI56rbbL_lhCXj44PzJF_HuHEUZrUKITBIpSBWLwtVzoA',
    id: 2877,
    acronym: 'UADSD',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'ucateci.edu.do',
    name: 'Universidad Católica Tecnológica del Cibao',
    web_page: 'http://www.ucateci.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWuXlImK99YN7MU7lMBdN35HrsL1woCfEmdBil3wjATBbdPsW7hK2LcA',
    id: 2878,
    acronym: 'UCTDC',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'uce.edu.do',
    name: 'Universidad Central del Este',
    web_page: 'http://www.uce.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSgnO6FhuNIidKi2s-eFiYKZ9sEx11YUHuJ7NTgg2oOD6wvlErOIdSsWg',
    id: 2879,
    acronym: 'UCDE',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'ucne.edu.do',
    name: 'Universidad Católica Nordestana',
    web_page: 'http://www.ucne.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDOEBX79wHEnqBiPOekf7SKDEBFU0yynY8-hgrBFNbBd9PXROg2AUA15M',
    id: 2880,
    acronym: 'UCN',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'ucsd.edu.do',
    name: 'Universidad Católica de Santo Domingo',
    web_page: 'http://www.ucsd.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUYbtBDsM1W0UqM9o6I-sisBYe_7W9a5YXyTQKL3nNGLpg9MLNRBLLRWQ',
    id: 2881,
    acronym: 'UCDSD',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'udoym.edu.do',
    name: 'Universidad Dominicana O&M',
    web_page: 'http://www.udoym.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0V_SQSckRS7pItaeA0Tf0euk1GOKYDA-5pGpl8i1kST_zV5h1PUJI-q0',
    id: 2882,
    acronym: 'UDO',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'ufhec.edu.do',
    name: 'Universidad Federico Henríquez y Carvajal',
    web_page: 'http://www.ufhec.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT8gXfAYNPJwE4bzrWGOoiYCvQR8NRUkVbFDvAwFCNmw_SAq_zskvWcYo',
    id: 2883,
    acronym: 'UFHYC',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'ufhec.edu.do',
    name: 'Universidad Ulises Francisco Espaillat',
    web_page: 'http://www.ufhec.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCu7mDUP1ZGHEz6Oj1FAmHAlEaX8LyZKxsmKRI1UnuiZLYpBJLJ8pK2w',
    id: 2884,
    acronym: 'UUFE',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'unad.edu.do',
    name: 'Universidad Adventista Dominicana',
    web_page: 'http://www.unad.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOfyiDhTPNGZQItBRGgxhXoJq138mfQLJ7V-9jFfbITrMOYhAQ5uBTMA',
    id: 2885,
    acronym: 'UAD',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'unapec.edu.do',
    name: 'Universidad APEC',
    web_page: 'http://www.unapec.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsgeGIjIJrrzrWXj--nhcDQlHpOwBbzoezQvUZVu-Z_-h18v_4yzClfg',
    id: 2886,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'unefa.edu.do',
    name: 'Universidad Experimental Felix Adam',
    web_page: 'http://www.unefa.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8LJx9X9PLMTSCkv033Y3tUmYQVKsbC3lwBkTA7MNtDOEyhC8t0-PPYxg',
    id: 2887,
    acronym: 'UEFA',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'unev-rd.edu.do',
    name: 'Universidad Nacional Evangélica',
    web_page: 'http://www.unev-rd.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyVZzQFa-63hpdCvusMtV0RA6C6Ek1OMOQUpAOs9q9A4YWpRKEdJwPjrj3',
    id: 2888,
    acronym: 'UNE',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'unibe.edu.do',
    name: 'Universidad Iberoamericana',
    web_page: 'http://www.unibe.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6CFd8845FJzm95wI9yE6fQysBXhWvmB0IKIFuOt8yCHlRKWG_uuHBV2A',
    id: 2889,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'unica.edu.do',
    name: 'Universidad Interamericana',
    web_page: 'http://www.unica.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgS_a3Sftmpo30q6xxjtKdAulmxIjXMbSbVq5JP1cXUrzUHUE-SOXMuA',
    id: 2890,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'unicaribe.edu.do',
    name: 'Universidad del Caribe',
    web_page: 'http://www.unicaribe.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5HzKyLvbB68PKsLo8H3vkCNliH2zsFcTkz6S_4dxXefbsYA2Ry92vvGrJ9g',
    id: 2891,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'uniremhos.edu.do',
    name: 'Universidad Eugenio Maria de Hostos',
    web_page: 'http://www.uniremhos.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNeL5V33LSKBkVY_7CEFpmOprnM2ltA_GjGo8aLqx1OUDJB2Bh-Fz42Q',
    id: 2892,
    acronym: 'UEMDH',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'unphu.edu.do',
    name: 'Universidad Nacional Pedro Henríquez Ureña',
    web_page: 'http://www.unphu.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSLQPYJFTfAfqSy8peSASLqNISAnCQ5Er7yt68L-DjBZVJ75vkQiKkKw',
    id: 2893,
    acronym: 'UNPHU',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'uod.edu.do',
    name: 'Universidad Odontológica Dominicana',
    web_page: 'http://www.uod.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9HVTSk8q21P6aAfd4Z72ILeJsD2r-ed8jCm1RHuPbtCIiEQN1B7uSEaY',
    id: 2894,
    acronym: 'UOD',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'ute.edu.do',
    name: 'Universidad de la Tercera Edad',
    web_page: 'http://www.ute.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbagQ-ydR2jgXjH9eeHcBdonnMDTW6kZffEmgAPRkrRsp897hS_7o19vI',
    id: 2895,
    acronym: 'UDLTE',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'utesa.edu',
    name: 'Universidad Tecnológica de Santiago',
    web_page: 'http://www.utesa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0Gdbl6N57KHRr5k1_0TbyTjPvyJ42jsmPgiG5eIVMQz24Rt3ogEI6yEar',
    id: 2896,
    acronym: 'UTDS',
  },
  {
    alpha_two_code: 'DO',
    country: 'Dominican Republic',
    domain: 'utesur.edu.do',
    name: 'Universidad Tecnológica del Sur',
    web_page: 'http://www.utesur.edu.do/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuqbDcDwAUfHLkG1FAwc4z8NnDu4ThjgEAjj3ZTOm12N8WKXgl63hcBjg',
    id: 2897,
    acronym: 'UTDS',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'brookdale.edu.ec',
    name: 'Brookdale Community College',
    web_page: 'http://www.brookdale.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrttO5k7SeBrVdmxfYfVozRwNUllN-RgXYHhH4Nqw3CMCpXl1g9fVDzP6B',
    id: 2898,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'casagrande.edu.ec',
    name: 'Universidad Casa Grande',
    web_page: 'http://www.casagrande.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNutRV8yIkm31bv3SJ8gSALPPz7hWEbeG2LOVhylthuY_QfMrgJgPuBg',
    id: 2899,
    acronym: 'UCG',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'epn.edu.ec',
    name: 'Escuela Politécnica Nacional',
    web_page: 'http://www.epn.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbKfzr-HxDAgDtpTGEwiKanYnxJ3BKM3wDXAh2rQpdxWogoGVUZqHuOw',
    id: 2900,
    acronym: 'EPN',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'espe.edu.e',
    name: 'Escuela Politécnica del Ejercito',
    web_page: 'http://www.espe.edu.ec',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL2QHnJ7LbHnFTQlGDJwsP-5tOodCAPWO09g71Ra6g33cPV8Lk0dg_s54',
    id: 2901,
    acronym: 'EPDE',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'espoch.edu',
    name: 'Escuela Politécnica de Chimborazo',
    web_page: 'http://www.espoch.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDPvlcJKNLt51UhIEjm0hNx75pl9IMqjdne05EEcVgY_7R_1VcUzFhig',
    id: 2902,
    acronym: 'EPDC',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'espol.edu.ec',
    name: 'Escuela Superior Politécnica del Litoral',
    web_page: 'http://www.espol.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyshWOv0VljeXTXgDN27AtF5TzCpqRNHkUqDr9SflXMQXBxRc93845TwueyA',
    id: 2903,
    acronym: 'ESPDL',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'indoamerica.edu.ec',
    name: 'Universidad Tecnológica Indoamérica',
    web_page: 'http://www.indoamerica.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYDj40eD76L-8Bif3Ys2L7qO4eAVVHrhbhmPCdmA8hSbgTubVb01cOGT0',
    id: 2904,
    acronym: 'UTI',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'internacional.edu.ec',
    name: 'Universidad Internacional del Ecuador',
    web_page: 'http://www.internacional.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ3LlC11fBJ4o3FCFT0upyXUIyR768OJWh8Uj-MRZUYH6XFxE3BjaECw',
    id: 2905,
    acronym: 'UIDE',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'puce.edu.ec',
    name: 'Pontificia Universidad Católica del Ecuador',
    web_page: 'http://www.puce.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnMooA8OMrL_lIVorTzNCr4zq-f_xYFgle164pXdad_7H9rNneAwIF-BI',
    id: 2906,
    acronym: 'PUCDE',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'sek.edu',
    name: 'Universidad Internacional SEK Quito',
    web_page: 'http://www.sek.edu/html/ecuador.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT90XUQGdzKE36mgBIptUg4v7Ajs9KgQaAoGRfKm4XaDnIvFGiQ6Un8NA',
    id: 2907,
    acronym: 'UISQ',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uagraria.edu.ec',
    name: 'Universidad Agraria del Ecuador',
    web_page: 'http://www.uagraria.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjg4mx7F7NwMGgHN-xPkTVZTLhyEPjh5H3zuYXJW4uDOwRm0UVles3RYVyUQ',
    id: 2908,
    acronym: 'UADE',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uasb.edu.ec',
    name: 'Universidad Andina Simón Bolívar',
    web_page: 'http://www.uasb.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1LJW9EV99qkAEdm_9yXuZQ-JtRua6-34uJ8wYhDk_HfEpxHVmV1xkS-I',
    id: 2909,
    acronym: 'UASB',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uazuay.edu.ec',
    name: 'Universidad del Azuay',
    web_page: 'http://www.uazuay.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ769oJ2MXfCGmX5x5OjcKmGPzzEYc3kDjYUJLOX9o4vMNvD-6rLnqWrdY',
    id: 2910,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ucaazo.edu.ec',
    name: 'Universidad Catolica de Azogues',
    web_page: 'http://www.ucaazo.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWRbL-sBVuPpftMEhfFl9tyXhDvG4EOMFNGrSscHHq9csTmO25zTh-grA',
    id: 2911,
    acronym: 'UCDA',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ucacue.edu.ec',
    name: 'Universidad Católica de Cuenca',
    web_page: 'http://www.ucacue.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvJJrTY_7gKhE2KS5jbrCAch1tbbHOlYCErB1cUYBRaJDkTXnCL02hM9o',
    id: 2912,
    acronym: 'UCDC',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ucentral.edu.ec',
    name: 'Universidad Central del Ecuador',
    web_page: 'http://www.ucentral.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw1DkRfUphlBQpy7gJyVzxxfO9STIhkFHKiU9hJlNF2uxO_4qEFpuIFQ',
    id: 2913,
    acronym: 'UCDE',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ucsg.edu.ec',
    name: 'Universidad Católica de Santiago de Guayaquil',
    web_page: 'http://www.ucsg.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRstjsGM09955qmIcGiTE6ev-kuLCO8azlptrm01_qd4Chn9oRkjfoPhpE',
    id: 2914,
    acronym: 'UCDSDG',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uct.edu.ec',
    name: 'Universidad de Especialidades Turísticas',
    web_page: 'http://www.uct.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzJjl4NSonDD0O7rJNQG_6uecb2pSjSto-JDtKm1c0ZbRNIrpXyX8lj6M',
    id: 2915,
    acronym: 'UDET',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ucuenca.edu.ec',
    name: 'Universidad de Cuenca',
    web_page: 'http://www.ucuenca.edu.ec/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQli76TgoSYZKVVMboIff7zuhM8_YTEA48ys-Dvk92ujeuwGpvIAq2EOfQ',
    id: 2916,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ueb.isfun.net',
    name: 'Universidad Estatal de Bolívar',
    web_page: 'http://www.ueb.isfun.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDmkNxYWyzZI1-uEKvsqSUCQKEdq8d8arcZbMCTK_Zwv8RgmQu8S1zqUw',
    id: 2917,
    acronym: 'UEDB',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uees.edu.ec',
    name: 'Universidad de Especialidades del Espíritu Santo',
    web_page: 'http://www.uees.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS-82SEVAqMKReY76iPPBaXw4CfxMuvulfrm5I_rrqJirSvLPehQkjMNw',
    id: 2918,
    acronym: 'UDEDES',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ug.edu.ec',
    name: 'Universidad de Guayaquil',
    web_page: 'http://www.ug.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2hgDKLzZsFRDC0KjjkDP6QV-BvTu6k4gDRibfrS8UzBIIlthFYhFwb_w',
    id: 2919,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uisrael.ec',
    name: 'Universidad Tecnologica Israel',
    web_page: 'http://www.uisrael.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIeH1isjZ3opFIOYWWe8-Qp1dEYAkd7jl2JeUmTMkcQA6N5rUWxPUjnQ',
    id: 2920,
    acronym: 'UTI',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ulaicavr.com',
    name: 'Universidad Laica "Vicente Rocafuerte" de Guayaquil',
    web_page: 'http://www.ulaicavr.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdfJtGBqiXxhRd3Vdi-veXrf6mnxGLICFeXE9av7vomAcWDecVGN48MA',
    id: 2921,
    acronym: 'UL"RDG',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uleam.edu.ec',
    name: 'Universidad Laica "Eloy Alfaro" de Manabi',
    web_page: 'http://www.uleam.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1v2jusauo5vs1Jrx0xlvCnBqQSbp_7CjanJ1Qyqjz2ZxEiRzzokqdTw',
    id: 2922,
    acronym: 'UL"ADM',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'unach.edu.ec',
    name: 'Universidad Nacional de Chimborazo',
    web_page: 'http://www.unach.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpT2f4HTSq8piidHyFLdHsilJW6zCbCXFlng6yqwPfEFOb2jaqCE5IYA0',
    id: 2923,
    acronym: 'UNDC',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'unita.edu.ec',
    name: 'Universidad Tecnológica América',
    web_page: 'http://www.unita.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwaahUJG8psiYubaBvfy7-HSyRztcd1QBrlk3YStDy-7msPiW7V6m7XQ',
    id: 2924,
    acronym: 'UTA',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'unl.edu.ec',
    name: 'Universidad Nacional de Loja',
    web_page: 'http://www.unl.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQelGpatYj6RNGFLBZikMxBMqJJXbIhPOqqlaRuCbtIkjJqHLUXPgeSS54',
    id: 2925,
    acronym: 'UNDL',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'upacifico.edu.ec',
    name: 'Universidad del Pacifico',
    web_page: 'http://www.upacifico.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcGYSkZXfKT2gnzdYBzyHBQjmXp0sfQFdLWMRxT_w04qRVdQfw_Sr14zA',
    id: 2926,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ups.edu.ec',
    name: 'Universidad Politécnica Salesiana',
    web_page: 'http://www.ups.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFk582DK9nPQWyfP6JJWucmLDtwgCnvusrlABNkrgoR-l68DFUYyyVcg',
    id: 2927,
    acronym: 'UPS',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'usfq.edu.ec',
    name: 'Universidad San Francisco de Quito',
    web_page: 'http://www.usfq.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqeaOvtXTvUVCt1lmN2BmGf4NkAh21pbaQe7_JiMH-OAYdVJ3O0_bf4KY',
    id: 2928,
    acronym: 'USFDQ',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'usm.edu.ec',
    name: 'Universidad Santa María Campus Guayaquil',
    web_page: 'http://www.usm.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU49uJIb_1p8eEh2rRFCIDENYQlWNTWpVUECaZqkYl_4QBBVKllGlJ1MjV',
    id: 2929,
    acronym: 'USMCG',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uta.edu.ec',
    name: 'Universidad Técnica de Ambato',
    web_page: 'http://www.uta.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdXqUfBiQt4k1WFVDwlP-BVI57aXWedF-9y1dYYa-PHXeCu2Y6qWVmPnk',
    id: 2930,
    acronym: 'UTDA',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'utb.edu.ec',
    name: 'Universidad Técnica de Babahoyo',
    web_page: 'http://www.utb.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHU50n6GN9EiH2YRRh8Lybrm9t6g_7ZYFga3r7heXhq1y_eXzeudrcZy0',
    id: 2931,
    acronym: 'UTDB',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'utc.edu.ec',
    name: 'Universidad Técnica de Cotopaxi',
    web_page: 'http://www.utc.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThZ4rWnJ37DBfyqfXZ7qLw75gEZMyWBBUCByHju6g6ztWIOjAiXMbLAoA',
    id: 2932,
    acronym: 'UTDC',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'ute.edu.ec',
    name: 'Universidad Tecnológica Equinoccial',
    web_page: 'http://www.ute.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhwCe1_tUGSczVFSYj0btNFNHAeyHrC-aEpqrK_k_7ijwye-_t-A-EOgg',
    id: 2933,
    acronym: 'UTE',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'utelvt.edu.ec',
    name: 'Universidad Técnica de Esmeraldas "Luis Vargas Torres"',
    web_page: 'http://www.utelvt.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR54YtfnQ43mrUu_gQQUMvL2PeZviaHfie3Quh0xYnP7eypfyIzkKUJ22M',
    id: 2934,
    acronym: 'UTDE"VT',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'uteq.edu.ec',
    name: 'Universidad Técnica Estatal de Quevedo',
    web_page: 'http://www.uteq.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCLD1wcVtbVSVPyEErUySFYx5C91-xnLSg52aByFWERRYf7eACkbyihTU',
    id: 2935,
    acronym: 'UTEDQ',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'utmachala.edu.ec',
    name: 'Universidad Técnica de Machala',
    web_page: 'http://www.utmachala.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBAuVxYVhglsXcuA1neASEr2Y_gO98GftntzSWto12tA68yIMJBdbgVg',
    id: 2936,
    acronym: 'UTDM',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'utm.edu.ec',
    name: 'Universidad Técnica de Manabí',
    web_page: 'http://www.utm.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJoq5Qrf4iUEgFSZMB6Z2Qg4j6gsXyb-Wn3T1Out0lB5-UQFkkWX36ew',
    id: 2937,
    acronym: 'UTDM',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'utn.edu.ec',
    name: 'Universidad Técnica del Norte',
    web_page: 'http://www.utn.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIGmxf6mxsQEgW8jhE9mjO0sKH8_I4qShEhh6ZflVGpskVI7hupFlo8g',
    id: 2938,
    acronym: 'UTDN',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'utpl.edu.ec',
    name: 'Universidad Técnica Particular de Loja',
    web_page: 'http://www.utpl.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGPkZwhk9IvlVdVIvnuWdZ0t_uvOPMbJPjcMoeF16yMf3r0hQxbou_Xg',
    id: 2939,
    acronym: 'UTPDL',
  },
  {
    alpha_two_code: 'EC',
    country: 'Ecuador',
    domain: 'utsam.edu.ec',
    name: 'Universidad Tecnológica San Antonio de Machala',
    web_page: 'http://www.utsam.edu.ec/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLxObA3RUrdRB2g6J29GwCwQ1UTyInUck-jUDa78KZJAM2tL69StZbXg',
    id: 2940,
    acronym: 'UTSADM',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'aast.edu',
    name: 'Arab Academy for Science & Technology',
    web_page: 'http://www.aast.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiQuMIr8Fp9_96mKcskSit3Uw5qksU0DROI7zfhrR2du7iPEvqiAgyuVE',
    id: 2941,
    acronym: 'AAFS&T',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'akhbaracademy.edu.eg',
    name: 'Akhbar El Yom Academy',
    web_page: 'http://www.akhbaracademy.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 2942,
    acronym: 'AEYA',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'alex.edu.eg',
    name: 'Alexandria University',
    web_page: 'http://www.alex.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypuKYpNMAUnv6GNwvVRKGMYxnN59sXnBL8Bfohi-3HLb3iz5wAY7Txw',
    id: 2943,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'aucegypt.edu',
    name: 'American University in Cairo',
    web_page: 'http://www.aucegypt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK5SorkuTRx3U_AEbj3R3aEtF3EUsJTnFn45imoiWASkT2Xx1JheLtGpk',
    id: 2945,
    acronym: 'AUIC',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'aun.edu.eg',
    name: 'Assiut University',
    web_page: 'http://www.aun.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ8d9xMG-yi_YjMq-IX1xLN94ucXq3IzSsAIjKGhkOnycCBzizmUtdPUM',
    id: 2946,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'azhar.edu.eg',
    name: 'Al Azhar University',
    web_page: 'http://www.azhar.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO74TL-WRBRWASpb0qo5XpTwxuCadHq8wRgsQwWohnCfBlESiXZfcdPA',
    id: 2947,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'bsu.edu.eg',
    name: 'Beni Suef University',
    web_page: 'http://www.bsu.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUOGoW3V9wHZmcJOOByuQ44oJ507Lkp3jqo3X2jxDV3TA1C9UjQI8VDg',
    id: 2948,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'bu.edu.eg',
    name: 'Benha University',
    web_page: 'http://www.bu.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeRERcJvXp3o3qN2nQSa9YrzGUF8vG6Ppcf0H15jjqv-Ln39vqXU1LuQ',
    id: 2949,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'cu.edu.eg',
    name: 'Cairo University',
    web_page: 'http://www.cu.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3ElLk91Ra4lhH9R0F-pmOw9t4d48-60oW7dzpzyfa54CRbyLW3aB13A',
    id: 2950,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'damanhour.edu.eg',
    name: 'Damanhour University',
    web_page: 'http://www.damanhour.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE6Yb6lToxAFomtYwZwkuB2Ve9zjiJvHQ4_2pzWF-RzkKZ81ciztbayUoRpg',
    id: 2951,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'du.edu.eg',
    name: 'Damietta University',
    web_page: 'http://www.du.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrGIVCzM7GEu2yhVRIYtYsuZsvMRx_idbU-rp4RqhrY98Del4OX5qCrVDZ',
    id: 2952,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'elshoroukacademy.edu.eg',
    name: 'El Shorouk Academy',
    web_page: 'http://www.elshoroukacademy.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKw0HZ-dpgkXhMvX7lnZqLlb7DZMICaLrVi42tT279KIO510-9mP_HUis',
    id: 2953,
    acronym: 'ESA',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'fayoum.edu.eg',
    name: 'Fayoum University',
    web_page: 'http://www.fayoum.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCC1b6ufMXJt62DfKZZ_xo-xw9UAQTUqYVT7ZiaCvpVD8lhibutWkOeKU',
    id: 2954,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'futureuniversity.edu.eg',
    name: 'Future University',
    web_page: 'http://www.futureuniversity.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEhdbDlLWOkepWTLz0DphQWZ7khQzI9FXP7W0sc4P17ggx_WQrcKWjAw',
    id: 2955,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'guc.edu.eg',
    name: 'German University in Cairo',
    web_page: 'http://www.guc.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4-_Wo_ArOGmeq7PKSwFxC4-IMrKN8CV3_Gj19e74Y_kZnXJfRwpzaSg',
    id: 2956,
    acronym: 'GUIC',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'helwan.edu.eg',
    name: 'Helwan University',
    web_page: 'http://www.helwan.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-QZLQ7FtLOTRfkZOc6IZ5pmKvvURHVlBW1Wv2HCX0OEhkfw61-YT7vA',
    id: 2957,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'hti.edu.eg',
    name: 'Higher Technological Institute',
    web_page: 'http://www.hti.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTI6WLHTtbnDlB9N6OcuCFLX_TjgBbmY9wjyhtn2Xjy4lEEJk0eWBFCA',
    id: 2958,
    acronym: 'HTI',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'kfs.edu.eg',
    name: 'Kafr El-Sheikh University',
    web_page: 'http://www.kfs.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIe8VUTnoDAsHukvJuELjTaPjivL0fzvRCl6HwDGgE3gDl6oesWwGLig',
    id: 2959,
    acronym: 'KEU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'mans.edu.eg',
    name: 'Mansoura University',
    web_page: 'http://www.mans.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlyMEHBA7PgGQSS6x6TnwJEuK3c3dg62kJYnpNZcf0xZA6KuspWi3pcA',
    id: 2960,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'menofia.edu.eg',
    name: 'Menoufia University',
    web_page: 'http://www.menofia.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxEk6E_3TrtnitX4qfQtmRWpNVsu19uvL43YRhmcXYijnoVKbmj0v2qw',
    id: 2961,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'minia.edu.eg',
    name: 'Minia University',
    web_page: 'http://www.minia.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbILXqLefSnaaCfY8zVTTt-2HZ2UCrQND1KCoJpqVFjn3vKo_2worY6VFL',
    id: 2962,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'miuegypt.edu.eg',
    name: 'Misr International University',
    web_page: 'http://www.miuegypt.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu0Jn9ahE9ihABDi4bvMbrPrxwN8cTDzFG-L_c0R7xEl98UEcIJmevqu0',
    id: 2963,
    acronym: 'MIU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'modern-academy.edu.eg',
    name: 'Modern Acadmy',
    web_page: 'http://www.modern-academy.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfgC1iHJeHoN0QTgxm2OqkowZ1Jqqmm6ESIz0DQL2XYi48__YaecFPMrA',
    id: 2964,
    acronym: 'MA',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'msa.eun.eg',
    name: 'Modern Sciences & Arts University',
    web_page: 'http://www.msa.eun.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdTYlg9Zq5qXiAlkR8m5Sb8G3KgjBLRpJh3_NaxgvfAC3RLoMpgBqrSw',
    id: 2965,
    acronym: 'MS&AU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'mtc.edu.eg',
    name: 'Military Technical College',
    web_page: 'http://www.mtc.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_yJACpFRfoM7dpQ-PBlGfO5Zaqjbbx4GIuIltkApZ9OJ2MgSbKHsy6J62',
    id: 2966,
    acronym: 'MTC',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'mti.edu.eg',
    name: 'Modern University For Technology and Information',
    web_page: 'http://www.mti.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFyQGUamRUOQEIsfQWovpib-G2iFpZhTdn-niJz_-kzf0W8i4N96Kb3Q',
    id: 2967,
    acronym: 'MUFTI',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'must.edu.eg',
    name: 'Misr University for Sience and Technology',
    web_page: 'http://www.must.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW4eKbW5k6bV7DYI_7PvH9dPLJ0nWSJvf5w5JredaF0oTTkUtPdy6-jA',
    id: 2968,
    acronym: 'MUFST',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'nileu.edu.eg',
    name: 'Nile University',
    web_page: 'http://www.nileu.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGp9t6iFffgoBW_dXi8ITjalDPJu9R0VRFNYEQobA0S4AJVoHqUvhoaw',
    id: 2969,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'o6u.edu.eg',
    name: 'October 6 university',
    web_page: 'http://www.o6u.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5GnQX3uVc5-6-QwMQDDsqhfSprKnRh4W9fAww95q8O3WSUsc-ZGEu8Q',
    id: 2970,
    acronym: 'O6U',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'pua.edu.eg',
    name: 'Pharos International University',
    web_page: 'http://www.pua.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-3KMQ1GXu_eZk9rnMWcG31TYj4ciTeItDKMlgzW7es8GAHOm9tXyzQA',
    id: 2971,
    acronym: 'PIU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'sadatacademy.edu.eg',
    name: 'Sadat Academy for Management Sciences',
    web_page: 'http://www.sadatacademy.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR76hU9oUv1EejVl9a_tYecerah7xaVVvoJJZP-NaOLajdYYoTEQujITOk',
    id: 2972,
    acronym: 'SAFMS',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'shams.edu.eg',
    name: 'Ain Shams University',
    web_page: 'http://www.shams.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCjB5LAfB-FDO-NHuuEwxOt9mDE-_qKMf9XWbNRkh2YbireHYqRMcgdg',
    id: 2973,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'sohag-univ.edu.eg',
    name: 'Sohag University',
    web_page: 'http://www.sohag-univ.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT64e_w9UFgYMa9AN6fe7_S5d1ZB9zpqz0isa5CHa9Uk_fRLnx-yoAVhDM',
    id: 2974,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'su.edu.eg',
    name: 'Sinai University',
    web_page: 'http://www.su.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDR83QLZZNUD3YmRD6R3QxjX0TC9yT8-fkkuxjM1v8xGIS8MsQNOH8sA',
    id: 2975,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'suez.edu.eg',
    name: 'Suez Canal University',
    web_page: 'http://www.suez.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN_qKtnIAsR67a35jwDrA1ncMFDd5_SSkTm37smdflsBXHDqa5pIEnssBO',
    id: 2976,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'svu.edu.eg',
    name: 'South Valley University',
    web_page: 'http://www.svu.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMTsT7lzhpgcB8F4KE8eU70dcwF1HPYBYJF4dxw3BmX8lquZGaYt0kYQ',
    id: 2977,
    acronym: 'SVU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'tanta.edu.eg',
    name: 'Tanta University',
    web_page: 'http://www.tanta.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtyvc5A4HXoaUFu1obCJUKvRwkkdK4Uvs9wjp6WWboZ8AzMHWZT6ItSxtRSA',
    id: 2978,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'ufe.edu.eg',
    name: "Université Française d'Égypte",
    web_page: 'http://www.ufe.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0hzcafLnN2O5pp_n9vBoKeA1ZG4LXv9QMtVfL_XZY9v_3j5H_la29XA',
    id: 2979,
    acronym: 'UFD',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'usenghor-francophonie.org',
    name: "Université Senghor d'Alexandrie",
    web_page: 'http://www.usenghor-francophonie.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFnIcz1oOFB8hNdWUVmnPNNlIVEm3_OuKw1pQf_OpyEPXgsmOHG04AiQ',
    id: 2980,
    acronym: 'USD',
  },
  {
    alpha_two_code: 'EG',
    country: 'Egypt',
    domain: 'zu.edu.eg',
    name: 'Zagazig University',
    web_page: 'http://www.zu.edu.eg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkUcZUSGO8yDSJKdfqz4n_wt7dbYMndrTdLJzwJ9tPOzn3Oqd_czFB3Lc',
    id: 2981,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'cdb.edu.sv',
    name: 'Universidad Don Bosco',
    web_page: 'http://www.cdb.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQid5wIfx4P5LU0Qlu1PP_mmHSgnAi3PhXqivEWQfA38hMEAOhHn2y6naIZ3g',
    id: 2982,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'netcomsa.com',
    name: 'Universidad Luterana Salvadorena',
    web_page: 'http://www.netcomsa.com/uls/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFZYeWIkuEse1S2R8fxArG0RtbafWtOTveygX6JuObamFIYZ8DuMv_UA',
    id: 2983,
    acronym: 'ULS',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'uae.edu.sv',
    name: 'Universidad Albert Einstein',
    web_page: 'http://www.uae.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScKehcjcxHMMRZ9zMhqaQ32Z7TX5Id_W1CbcajS8jeTqaQYCfiqd4tyuw',
    id: 2984,
    acronym: 'UAE',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'ucad.edu.sv',
    name: 'Universidad Cristiana de las Asambleas de Dios',
    web_page: 'http://www.ucad.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1sHteuTJGS04BkQEZA2K_Ujvbltl9IVvKrMX5iHtxQxPid8UpN1gjBw',
    id: 2985,
    acronym: 'UCDLADD',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'uca.edu.sv',
    name: 'Universidad Centroamericana "José Simeón Canas"',
    web_page: 'http://www.uca.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVJRTHszwItqvdN9pFMmKYDOjqkOj36IjuRWH-sSKRwECNlI5I6TvVsbk',
    id: 2986,
    acronym: 'UC"SC',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'uees.edu.sv',
    name: 'Universidad Evangélica de El Salvador',
    web_page: 'http://www.uees.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFupsmWmvLHyAcsZYtnFyzgKkNYwmTGZF_li1B_WqIX8jLw1pPIBcVsg',
    id: 2987,
    acronym: 'UEDES',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'ues.edu.sv',
    name: 'Universidad de El Salvador',
    web_page: 'http://www.ues.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQc-HPeU7xCKZM993ZSzTGm-N0fd83wTTyAqZY-0TR72wyKGHSpLY1L_4smA',
    id: 2988,
    acronym: 'UDES',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'ufg.edu.sv',
    name: 'Universidad Francisco Gavidia',
    web_page: 'http://www.ufg.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqjzIz-d-JLS2UFeJTWwcu-6_qAkudRVJViOZlze9uniajHUqgF8WTh7o',
    id: 2989,
    acronym: 'UFG',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'ugb.edu.sv',
    name: 'Universidad Capitain General Gerardo Barrios',
    web_page: 'http://www.ugb.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT62UwRU4bl9Q42cget3JORwDvEYppaRKQGiNsHQUrh3_p05t9sC67pz5s',
    id: 2990,
    acronym: 'UCGGB',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'ujmd.edu.sv',
    name: 'Universidad Dr. Jose Matias Delgado',
    web_page: 'http://www.ujmd.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiPCUZ_eCRTa0klsTPJE3_ULZPxN0UUS22dLoKMb4ybXrYBOeS_AwF2nQ',
    id: 2991,
    acronym: 'UDJMD',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'uma.edu.sv',
    name: 'Universidad Modular Abierta',
    web_page: 'http://www.uma.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRkehNx519o2Su-dn_LJ0auS9VA1OC8GaXFnw7a_lSwoXuNV4VNd6ISg',
    id: 2992,
    acronym: 'UMA',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'umoar.edu.sv',
    name: 'Universidad Monsenor Oscar Arnulfo Romeo',
    web_page: 'http://www.umoar.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa0L8ufxTGYUK2wrvIz17zesy7Pby0lYKoRiN_xRXwUnsqQmwXi20Md1in',
    id: 2993,
    acronym: 'UMOAR',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'unab.edu.sv',
    name: 'Universidad Dr. Andreas Bello',
    web_page: 'http://www.unab.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRm4lBUV4XVqmZYjWfZmLGWkhs6JpDbgf_dERKYpbt2qcN2fojgaA6BaI8',
    id: 2994,
    acronym: 'UDAB',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'unasa.edu.sv',
    name: 'Universidad Autónoma de Santa Ana',
    web_page: 'http://www.unasa.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-DI57UHE772pThT9QK8JYQNp67XB_c9gipmTBSxi2gVi1ct9HmpNUcRb_',
    id: 2995,
    acronym: 'UADSA',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'unico.edu.sv',
    name: 'Universidad Católica de Occidente',
    web_page: 'http://www.unico.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3NIIQNbSdIMDsQkrqZh_gQZpLfKtODEhhFpWvIMCatzlgH_6KASJw9sk',
    id: 2996,
    acronym: 'UCDO',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'universidadpedagogica.com',
    name: 'Universidad Pedagógica de El Salvador',
    web_page: 'http://www.universidadpedagogica.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr_ybuXziquc6SMMqrQYHbXLm3N48Xubl4SXZm_iCOt_LiwdJqcR59Xw',
    id: 2997,
    acronym: 'UPDES',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'unssa.edu.sv',
    name: 'Universidad Nueva San Salvador',
    web_page: 'http://www.unssa.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJdl7amDivBFOhEHsuLbNQY_zAy20l2xjf8Nx_iuqVypWKgxUzYeiscFow',
    id: 2999,
    acronym: 'UNSS',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'upan.edu.sv',
    name: 'Universidad Panamericana de San Salvador',
    web_page: 'http://www.upan.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzqh8lY5dzBkWyDifgTSb2UbH2zd316UKR8oK8f4hNaRkNlqF4aALlRA',
    id: 3000,
    acronym: 'UPDSS',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'upes.edu.sv',
    name: 'Universidad Politécnica de El Salvador',
    web_page: 'http://www.upes.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyYKaS4vhm9HikXkvzuBTFxDQcGalzaZ2_90Q6ryMMiI0hbwrgTK7c4To',
    id: 3001,
    acronym: 'UPDES',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'usam.edu.sv',
    name: 'Universidad Salvadorena "Alberto Masferrer"',
    web_page: 'http://www.usam.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHqBd_c9EQryfVG1HMh_pZt1MpAoQT4WgbfM9Bt1YqrJc8mIWIn0bt2Q',
    id: 3002,
    acronym: 'US"M',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'usonsonate.edu.sv',
    name: 'Universidad de Sonsonate',
    web_page: 'http://www.usonsonate.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa6YyHc7XK1a8pSHSDcirKvvRaZ12dzM3aO-m9p3yrP90WA0nPXwSIEck',
    id: 3004,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'utec.edu.sv',
    name: 'Universidad Tecnológica de El Salvador',
    web_page: 'http://www.utec.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxIzkpB6jdqMk_9Wc_iBW0CASqlR9J1cfJJd3W5U5BxfRSbmOgBy6TBA',
    id: 3005,
    acronym: 'UTDES',
  },
  {
    alpha_two_code: 'SV',
    country: 'El Salvador',
    domain: 'utla.edu.sv',
    name: 'Universidad Técnica Latinoamericana',
    web_page: 'http://www.utla.edu.sv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdS-IZqSjX6Pr-OIt_iWeASzhyYnIhpXX3lZCflYlD6LHZS91d5UbfoUT5',
    id: 3006,
    acronym: 'UTL',
  },
  {
    alpha_two_code: 'GQ',
    country: 'Equatorial Guinea',
    domain: 'unge.gq',
    name: 'Universidad Nacional de Guinea Ecuatorial',
    web_page: 'http://www.unge.gq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRM9JnRiiLa2_tcZz9rm95hkjVHEU3KvBaGrKVpF3r5s_QovWfRIoSHvC8',
    id: 3007,
    acronym: 'UNDGE',
  },
  {
    alpha_two_code: 'ER',
    country: 'Eritrea',
    domain: 'eit.edu.er',
    name: 'Eritrea Institute of Technology',
    web_page: 'http://www.eit.edu.er/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScGDAOTp4t9-DsMV3UpXHJ9SY5sIki-oW_Ue60QBLeaqckTzu3Bh9Xx9s',
    id: 3008,
    acronym: 'EIT',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'artun.ee',
    name: 'Estonian Academy of Arts',
    web_page: 'http://www.artun.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCh9eFcqQJaOJ_n7rtA0sI7kFTOHa1388_nrA4cnqcArf46MJhvZEF7g',
    id: 3009,
    acronym: 'EAA',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'ebs.ee',
    name: 'Estonian Business School',
    web_page: 'http://www.ebs.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2gjNLJOgEKlBcDMAHROYkQcZqIZgHlfczMAxMfLsdMn6LXCvFxXfrVgo',
    id: 3010,
    acronym: 'EBS',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'ema.edu.ee',
    name: 'Estonian Academy of Music and Theatre',
    web_page: 'http://www.ema.edu.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2Cec7AoTLqa7SUT2DQYHikn2b3N0E1yWL3nCVhvdjI-yz3AUobD7vQg',
    id: 3011,
    acronym: 'EAMT',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'emu.ee',
    name: 'Estonian University of Life Sciences',
    web_page: 'http://www.emu.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoB1RLLxYWgRqH8NesEaIfq_aRkz7v5rdDCjdrrSxpev15WnZL7oeDaQ',
    id: 3012,
    acronym: 'EULS',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'nooruse.ee',
    name: 'Tartu Health Care College',
    web_page: 'http://www.nooruse.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD3u6eAjliqdAnyDCOLrrv4j086JgCUNeeqEx9WD0mpfM_dqGr948LzmM',
    id: 3013,
    acronym: 'THCC',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'sisekaitse.ee',
    name: 'Estonian Academy of Security Sciences',
    web_page: 'http://www.sisekaitse.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2Cec7AoTLqa7SUT2DQYHikn2b3N0E1yWL3nCVhvdjI-yz3AUobD7vQg',
    id: 3014,
    acronym: 'EASS',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'tktk.ee',
    name: 'University of Applied Sciences',
    web_page: 'http://www.tktk.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJQsgN5TCmp6lh4VjJ9ZUip88kYyfNGxo4EgY-zq-4zJLyaN8EFg7fd_I',
    id: 3015,
    acronym: 'UAS',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'tlu.ee',
    name: 'Tallinn University',
    web_page: 'http://www.tlu.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdapUxI0nVEAYp-XESZ-RNJQnv5breU7eg4INrxfsYOW5oGe7EruMcNQ',
    id: 3016,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'ttu.ee',
    name: 'Tallinn University of Technology',
    web_page: 'http://www.ttu.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT49ASS6wi4zqAVFrw9xS762JMkzncQLUoZ9u25HAM87IpFjRyn8MXUzeNl',
    id: 3017,
    acronym: 'TUT',
  },
  {
    alpha_two_code: 'EE',
    country: 'Estonia',
    domain: 'ut.ee',
    name: 'University of Tartu',
    web_page: 'http://www.ut.ee/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4wRwkg1OHjZQnmEIyXhnE7whpe5mdEyluxWGF6h5THV8bPmbFABEQ_A',
    id: 3018,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'aastu.org',
    name: 'Addis Ababa Science & Technology University',
    web_page: 'http://www.aastu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKRPAFLXewWLlEWK4cS5FjcnwtRvQ_bF_1jNfRIanVMM-9fxbZ7lxaXeU',
    id: 3019,
    acronym: 'AAS&TU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'aau.edu.et',
    name: 'Addis Ababa University',
    web_page: 'http://www.aau.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZiUV7f1EbkLjoj1cEVlY4ssItl0x6vhJnu3HlZsmyMcny-LbMy2K_Au0',
    id: 3020,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'adu.edu.et',
    name: 'Adigrat University',
    web_page: 'http://www.adu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShOgHG2k0QHfMcTjqojb0GBXu_cIHyYgHpJmfaG4Ez11FiC2A23qLu1w',
    id: 3021,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'aku.edu.et',
    name: 'Aksum University',
    web_page: 'http://www.aku.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4xDNpS_ao94UXfpR1Unbd8a7gYbZ7ve115XYBFWYcYhfN74fpbLiN0Q',
    id: 3022,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'ambou.edu.et',
    name: 'Ambo University',
    web_page: 'http://www.ambou.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlp31JOlaZutu5GEtJfZ5u3oDu88K0OG2PccE2b658YRU9Kp9ENsRqKys',
    id: 3023,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'amu.edu.et',
    name: 'Arba Minch University',
    web_page: 'http://www.amu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZVWa_yNvFhxZ4-PYh5Vg0a0abSjUCGJKkvsv5vNRH75DO3smQHSFi7w',
    id: 3024,
    acronym: 'AMU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'astu.edu.et',
    name: 'Adama Science and Technology University',
    web_page: 'http://www.astu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBzUK0bmf3jiF0h53EbRLPfmIWFwlOmiBCjlsr1YfFcQbhK8Zq_UV8ZQ',
    id: 3025,
    acronym: 'ASTU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'asu.edu.et',
    name: 'Assosa University',
    web_page: 'http://www.asu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvEIHyNPYSyO8hQ86KaCDUk7t9qC9wAxQYuuWzVFw11VHHoqjcsQcrDuHm',
    id: 3026,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'bdu.edu.et',
    name: 'Bahir Dar University',
    web_page: 'http://www.bdu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqVFoh2FA6mbE4YkVjrJG7O5gEypKIZczigHOc_G2ub4Iixb5ymBbkOww',
    id: 3027,
    acronym: 'BDU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'bulehorauniversity.com',
    name: 'Bule Hora  University',
    web_page: 'http://www.bulehorauniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzH9upTKlvPpkZbHTFPMiV0_91VXYXc2ePtzp7trmzItloVOnd55LnYZM',
    id: 3028,
    acronym: 'BHU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'dbu.edu.et',
    name: 'Debre Birhan University',
    web_page: 'http://www.dbu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdvKPQ8_DSAIZugwAe2yNfQL3j0GHQfC36nNA3Y49v0AiE60Mn5Sy4LrI',
    id: 3029,
    acronym: 'DBU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'ddu.edu.et',
    name: 'Dire Dawa University',
    web_page: 'http://www.ddu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWypUH5iCGIVjGMRQ9oLN0Axxq8vtsL7tPI4z8VQLwLpdw9j9HGrV3c7qlCA',
    id: 3030,
    acronym: 'DDU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'dillauniversity.edu.et',
    name: 'Dilla University',
    web_page: 'http://www.dillauniversity.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKOWW8m1P8s5LWFYwVzCvGBlPxlNj09s3gO8CmqmE4eTMn0rbgodG-yWg',
    id: 3031,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'dmu.edu.et',
    name: 'Debre Markos University',
    web_page: 'http://www.dmu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1j-VuiIwxaj5P6u3AZ5YGtXq_gxTxR7Vx9nlPSLEmtVTuBXg3AOB3LhE',
    id: 3032,
    acronym: 'DMU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'ecsc.edu.et',
    name: 'Ethiopian Civil Service University',
    web_page: 'http://www.ecsc.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzRFkhPMI1PcEOcc04Nk69ZgmwJJDyObq42I_C_FUZBA7-BHJ1JU14r20',
    id: 3033,
    acronym: 'ECSU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'haramaya.edu.et',
    name: 'Haramaya University',
    web_page: 'http://www.haramaya.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt-hesZDr5snHynMRgQf_-zCnQOlfABTVEOnQxDylNE8vegqUUh3ALZQI',
    id: 3034,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'hu.edu.et',
    name: 'Hawassa University',
    web_page: 'http://www.hu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcl0v-xgL-nmZdcNpweE5igSEsFLJxHSR4kHYLBHOvQGvnM2xA3MeaDqHT',
    id: 3035,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'jju.edu.et',
    name: 'Jigjiga University',
    web_page: 'http://www.jju.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR30JAIuUp0_Qgtp0DcL4uytE2tF1ZPo8mJX4xOADLtTWI1X7RwQhPyVhc',
    id: 3036,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'ju.edu.et',
    name: 'Jimma University',
    web_page: 'http://www.ju.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr2onnF1TPlVUUCpcBWLZbcJuesApxlXd9w2AzEIpc-xtL98xtm-HoUg',
    id: 3037,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'mitethiopia.edu.et',
    name: 'Mekelle Institute of Technology',
    web_page: 'http://www.mitethiopia.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLGXhvYlKv1cOSukCdPVKw0S-x1oUlweL0-iso8DGoQE489b1EmUt5LEYQ',
    id: 3038,
    acronym: 'MIT',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'mtu.edu.et',
    name: 'Mizan Tepi University',
    web_page: 'http://www.mtu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPw4jyeLfS51NgreSh803E2kRf6WJYPIkiOjkkVT7_0R8Xyu9LIOMEj9JC',
    id: 3039,
    acronym: 'MTU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'mu.edu.et',
    name: 'Mekelle University',
    web_page: 'http://www.mu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmXFq3XVLlrgFnBM98Ii6NnZ8B7h8IVLzZs_sOda3xCaXHWqksdYHusA',
    id: 3040,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'mwu.edu.et',
    name: 'Madawalabu University',
    web_page: 'http://www.mwu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmXFq3XVLlrgFnBM98Ii6NnZ8B7h8IVLzZs_sOda3xCaXHWqksdYHusA',
    id: 3041,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'su.edu.et',
    name: 'Semera University',
    web_page: 'http://www.su.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3VPgezIs27DrIvkruHbNpsCl32WE_AuOx5RNCdwP0XRamDBlXuFZHsjY',
    id: 3042,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'uog.edu.et',
    name: 'Gondar University',
    web_page: 'http://www.uog.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXSfPqYcy_RANoITOslhgNuAsy9fe7tm4XXLfUUspLCwlrXvrgFQCZnJc4',
    id: 3043,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'uuc.edu.et',
    name: 'Unity  University',
    web_page: 'http://www.uuc.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyQgVcgkjy9Ip9X5BFOdY1UUfVDkXMZD8k8-sknhuBJV1nKc8470vOcw',
    id: 3044,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'wku.edu.et',
    name: 'Wolkite University',
    web_page: 'http://www.wku.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlswjX1imYAXe6R-OEx8aam0cTglGUHJnRFnefETlyhMVvL9bilDH1IH0',
    id: 3045,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'wsu.edu.et',
    name: 'Wolaita Sodo University',
    web_page: 'http://www.wsu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8hNXHl57I52BX8Njzkid1gNbWT3J4N3HtyaMCgaVwg8HB31FSAl5lWw',
    id: 3046,
    acronym: 'WSU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'wu.edu.et',
    name: 'Wollo University',
    web_page: 'http://www.wu.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZhpyKeD05Zo1gRTFnGlQdUVm7XfzYg5SSjUKG7-8RCxwzrIHvX3jM2-rZnw',
    id: 3047,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'ET',
    country: 'Ethiopia',
    domain: 'wuni.edu.et',
    name: 'Wollega University',
    web_page: 'http://www.wuni.edu.et/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7xpvVWv7IDhBJcaWmfZXqvtVxSRBCkxKL0WZaSA9MyOaTJ72D61q4qw',
    id: 3048,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'FO',
    country: 'Faroe Islands',
    domain: 'setur.fo',
    name: 'University of the Faroe Islands',
    web_page: 'http://www.setur.fo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiIfHZZ8gkPj94MHnJhs6SaevgHzx3XtODrQMfBqFUlnXFi-2OVtLvuxA',
    id: 3049,
    acronym: 'UFI',
  },
  {
    alpha_two_code: 'FJ',
    country: 'Fiji',
    domain: 'fnu.ac.fj',
    name: 'Fiji National University',
    web_page: 'http://www.fnu.ac.fj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwmEU3bTn2hE0rRA9bD6EKpbNBZVv-6K4T0YsmOvoioZm8wn83NsCnypw',
    id: 3050,
    acronym: 'FNU',
  },
  {
    alpha_two_code: 'FJ',
    country: 'Fiji',
    domain: 'fsm.ac.fj',
    name: 'Fiji School of Medicine',
    web_page: 'http://www.fsm.ac.fj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOtgQeBCtnpomX_9byQnJ-UPXWHh-ZpNRl5wkpMj19LHZDpRTwCeu4tQ',
    id: 3051,
    acronym: 'FSM',
  },
  {
    alpha_two_code: 'FJ',
    country: 'Fiji',
    domain: 'unifiji.ac.fj',
    name: 'University of Fiji',
    web_page: 'http://www.unifiji.ac.fj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwmEU3bTn2hE0rRA9bD6EKpbNBZVv-6K4T0YsmOvoioZm8wn83NsCnypw',
    id: 3052,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'FJ',
    country: 'Fiji',
    domain: 'usp.ac.fj',
    name: 'University of the South Pacific',
    web_page: 'http://www.usp.ac.fj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqd3U-IJtMdPSiGoVJhGaPhvJ043AwBZfh2RZXbeakB6yd0wUiti7LTpI',
    id: 3053,
    acronym: 'USP',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'abo.fi',
    name: 'Abo Akademi University',
    web_page: 'http://www.abo.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAt3-wy4fDHsOfWVWtGCjdjvFu9hGKhNEQxZrefxolWFkQMhBoquI8RQ',
    id: 3054,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'cou.fi',
    name: 'Central Ostrobothnia University of Applied Sciences',
    web_page: 'http://www.cou.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLgdKLOtgI2_O0zqHkpjusCveb0KKoQAn1fZyfinqFJTwH3E5-qEcy94ra',
    id: 3055,
    acronym: 'COUAS',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'diak.fi',
    name: 'Diaconia University of Applied Sciences',
    web_page: 'http://www.diak.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsDAziiv7K9unKeuKzf4XETZAo5kR6hrexwc-zqQoOnj6mngMfdtjKcso',
    id: 3056,
    acronym: 'DUAS',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'evtek.fi',
    name: 'EVTEK University of Applied Sciences',
    web_page: 'http://www.evtek.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJQsgN5TCmp6lh4VjJ9ZUip88kYyfNGxo4EgY-zq-4zJLyaN8EFg7fd_I',
    id: 3057,
    acronym: 'EUAS',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'helsinki.fi',
    name: 'University of Helsinki',
    web_page: 'http://www.helsinki.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRztFSrRFPcDtkQ8X6c44qdAc5iJi8eJkwIrScRgT2WO7-zlMO8IfKEnQ',
    id: 3058,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'hkkk.fi',
    name: 'Helsinki School of Economics and Business Administration',
    web_page: 'http://www.hkkk.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS9NQ76F6hSp3NNB_UyG6XZxIosvxEYkzYA6NfcprKpmFIHM7dcUs3NT0',
    id: 3059,
    acronym: 'HSEBA',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'hut.fi',
    name: 'Helsinki University of Technology',
    web_page: 'http://www.hut.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-2HnMXY_EZQfCa6enOTWL02UGIL2nEN5D2lYeuvhUyMba0c4nZ_AT8M4',
    id: 3060,
    acronym: 'HUT',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'joensuu.fi',
    name: 'University of Joensuu',
    web_page: 'http://www.joensuu.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr5J6wtsZyoldOJqUt7hbqAFcm_5EvhWL--qfGYZ8eQ_NXcsJsEnO8jQ',
    id: 3061,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'jyu.fi',
    name: 'University of Jyväskylä',
    web_page: 'http://www.jyu.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGrZk8JhtjUWNabeheauPkcQ7WkxRDaTGCRCbmcgSvlsBdbK-lLOFJsOw',
    id: 3062,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'kuva.fi',
    name: 'Academy of Fine Arts',
    web_page: 'http://www.kuva.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvOX0AdY0ZzpT9n452SLoDMtTjXS-4OhTcyqtLoofqzJ2ze5aWsxcu3Wk',
    id: 3063,
    acronym: 'AFA',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'lamk.fi',
    name: 'Lahti Polytechnic',
    web_page: 'http://www.lamk.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnxop4QdVVYQ1owLy6ililczTiStACB1kh29kKRB_PkbPZ9lYifMyh7w',
    id: 3064,
    acronym: 'LP',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'lut.fi',
    name: 'Lappeenranta University of Technology',
    web_page: 'http://www.lut.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu40CaLg58AYPFrFVtSdEtqA5_USGAOBg3eWu6XcJvKxjMjvJ-TXtfyIM',
    id: 3065,
    acronym: 'LUT',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'oamk.fi',
    name: 'Oulu Polytechnic',
    web_page: 'http://www.oamk.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv8kbLEE0HDi6nfj2xrCWuE4Aw66BMyM3GE56ahiJKyE7wsggZSqiEjg',
    id: 3066,
    acronym: 'OP',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'oulu.fi',
    name: 'University of Oulu',
    web_page: 'http://www.oulu.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdGqBNlfMmFtd5Wk7cPNrKlN6JNUV2YMxR0BWypwwj7RR7ZWP7aA8BJBk',
    id: 3067,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'puv.fi',
    name: 'Vaasa University of Applied Sciences',
    web_page: 'http://www.puv.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNktVFvfp_mzvoRCVMma6J9i3ByM89hRCjWtLeVHvx80M8GYZgM-DXGjw',
    id: 3068,
    acronym: 'VUAS',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'ramk.fi',
    name: 'Rovaniemi University of Applied Sciences',
    web_page: 'http://www.ramk.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9v3UfEthUl8LTHvJGIbfJQXF_k00WNmptq_3tME6dJyPalh7TuR0KRkSp',
    id: 3069,
    acronym: 'RUAS',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'sabi.eu.com',
    name: 'Scandinavian Art and Business Institute',
    web_page: 'http://www.sabi.eu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpKILkLpOKEWq8kITKfYuXIfpuGF9EQTOwKxCnj4qzH64hvQhWTlra7ko',
    id: 3070,
    acronym: 'SABI',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'samk.fi',
    name: 'Satakunta University Of Applied Scinces',
    web_page: 'http://www.samk.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNikYx2z3ocMem6RQ6g3N5QV1Lf7EizEq6ITKUqsor1bw6n0u0GJwc7-A',
    id: 3071,
    acronym: 'SUAS',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'scp.fi',
    name: 'South Carelian Polytechnic',
    web_page: 'http://www.scp.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpmSXKwNDWI_WIVbnd4fgy9kII3SyY9xYu4fCDk_Ek92Ln75cUkyvzDis',
    id: 3072,
    acronym: 'SCP',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'shh.fi',
    name: 'Swedish School of Economics and Business Administration Finland',
    web_page: 'http://www.shh.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZN4MYDUN23rD_nmdlETa-vAI4MJU858TA1kzadkkdKzu7JWzD2x3-AA',
    id: 3073,
    acronym: 'SSEBAF',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'siba.fi',
    name: 'Sibelius Academy',
    web_page: 'http://www.siba.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYYqTXkIK4ZIACYrcWLyLGZQQZFNaM4w7u2rVeIGavUme0x0UbJk4rnQ',
    id: 3074,
    acronym: 'SA',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'teak.fi',
    name: 'Theatre Academy Finland',
    web_page: 'http://www.teak.fi/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQo8I7w_inVQEPW8HFq8hFEbEP4ynQ6R2X-QWf2ADrEElBRip3-HalZw4U',
    id: 3075,
    acronym: 'TAF',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'tpu.fi',
    name: 'Tampere Polytechnic',
    web_page: 'http://www.tpu.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpPMhgRtm-mof_twbMEdEbmh8OWLuR8L-kXUQZPl33VzT3SRN5hPvTMA',
    id: 3076,
    acronym: 'TP',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'tukkk.fi',
    name: 'Turku School of Economics and Business Administration',
    web_page: 'http://www.tukkk.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZkBbBSCqW7anuTMKCmtIZgm-6uaHnPDF9BzR8g3Jgl3UNRQwwtS_UyYM',
    id: 3077,
    acronym: 'TSEBA',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'tut.fi',
    name: 'Tampere University of Technology',
    web_page: 'http://www.tut.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ25NHeO_gKRt5qrMH-Lcl0PE5RD16axXXr-NR48myXWPg1LbEnSYb45pDG',
    id: 3078,
    acronym: 'TUT',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'uiah.fi',
    name: 'University of Art and Design Helsinki',
    web_page: 'http://www.uiah.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kkyQu1gGgNLfaix0o5oiy2BmVPzLRwTgehoybcH3gtL28Gk2wD5LblU',
    id: 3079,
    acronym: 'UADH',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'uku.fi',
    name: 'University of Kuopio',
    web_page: 'http://www.uku.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuH3Dw4TltID9gLFhyGBsw1Ex48YjoRayVDfSSzIEfcR5EA8zxAmJhvC0',
    id: 3080,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'ulapland.fi',
    name: 'University of Lapland',
    web_page: 'http://www.ulapland.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnvoTh-KycR4sPJOhyQuyYORXqbGHHqeFrPSJpVAXZoSRMuP8mm954s7E',
    id: 3081,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'uta.fi',
    name: 'University of Tampere',
    web_page: 'http://www.uta.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuZCwvuyBYoIA3juF9nhmwesnCEFS83xuu5k_SYi8NYZEbHaQbgTQLx4uc',
    id: 3082,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'utu.fi',
    name: 'University of Turku',
    web_page: 'http://www.utu.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpKTmGNof3X7cOxoTWzacvsycOE9jQAGAFwhcUcLtY1u8idlpV3M7NSCc',
    id: 3083,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'FI',
    country: 'Finland',
    domain: 'uwasa.fi',
    name: 'University of Vaasa',
    web_page: 'http://www.uwasa.fi/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnnDGTw8vUIsU6h8_wDa3Dwz21eDsREHqfLfI7pBYWCAsrafYQWhKmFg',
    id: 3084,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'aea.asso.fr',
    name: "Ecole de l'Air",
    web_page: 'http://www.aea.asso.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUMrlo4AY9Sptd-7ATdYhvU66hEheQYodPkG51rVSIo0p2v7S1DKrjsw',
    id: 3085,
    acronym: 'EDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'agroparistech.fr',
    name: 'AgroParisTech',
    web_page: 'http://www.agroparistech.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9GNnSKd_A3kbZLXIvtGHlgzONGgOyXUoIDYpghM80dkM8qa8Rdyr3UyU',
    id: 3086,
    acronym: 'A',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'agro.roazhon.inra.fr',
    name: "Ecole Nationale Supérieure d'Agronomie de Rennes",
    web_page: 'http://agro.roazhon.inra.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmnRduTOFhhTZvCWv708ePRKk7AsNHhk9PVYcUNIxfL6JjukfT2i9XutXr',
    id: 3087,
    acronym: 'ENSDDR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'agro.roazhon.inra.fr',
    name: 'Institut National Supérieur de Formation Agro-Alimentaire',
    web_page: 'http://agro.roazhon.inra.fr/etab/insfa/accueil.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLpeIojAZxZcnF2gyVpijV8Y2KBYMBtxNAB9Kdwqi5NFCTdN0dyX7U7zU',
    id: 3088,
    acronym: 'INSDFA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'agrosupdijon.fr',
    name: 'AgroSup Dijon',
    web_page: 'http://www.agrosupdijon.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrsh5oAmV-R2FLSueBaipBuXCyVbcNhI9XzShgTef9eML4Ym3xV-HYsFc',
    id: 3089,
    acronym: 'AD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ags.edu',
    name: 'American Graduate School in Paris',
    web_page: 'http://www.ags.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPViMZNIFk8fsf86VFqwjv1IENcTOOamAFPl6h0DI3SIRMF9v-U5PewsA',
    id: 3090,
    acronym: 'AGSIP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'aup.fr',
    name: 'American University of Paris',
    web_page: 'http://www.aup.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEp0-TQCBYei8H8rqC_0uR54wDQDTmZEPbcSCGtI3I40R0qwU7HOgMjg',
    id: 3091,
    acronym: 'AUP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'bip.lon.ac.uk',
    name: 'British Institute in Paris University of London',
    web_page: 'http://www.bip.lon.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQET7X71sH7WiNhhL5eUtLzYda-iYWvM7D3Is3MkNpUNkv8FXoQfSJ53g',
    id: 3092,
    acronym: 'BIIPUL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ceram.edu',
    name: 'Ecole Supérieure de Commerce de Sophia Antipolis',
    web_page: 'http://www.ceram.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT-KdyA81Wiqdo2Wbv2ZSuDEKesG6y37rBK561K_Q6r9XaSM2iTEkKHBs',
    id: 3093,
    acronym: 'ESDCDSA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'cesi.fr',
    name: "Centre d'Etudes Supérieures Industrielles Paris",
    web_page: 'http://www.cesi.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmNc-2jE-9z0-S0XA1BbKClBG7KSNNk2xpLhJsQUxFMTrCCVRMWSqMLJ8',
    id: 3094,
    acronym: 'CDSIP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'cfpj.com',
    name: 'Centre de Formation et de Perfectionnement des Journalistes',
    web_page: 'http://www.cfpj.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmBoI2ig1ugyRTWjkKhVpNjR7mUncU43oZM3lPqBdQJ9D4lsdqQIDRFsw',
    id: 3095,
    acronym: 'CDFEDPDJ',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'cnam.fr',
    name: 'Conservatoire National des Arts et Métiers',
    web_page: 'http://www.cnam.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVZQbD_tvVAcEdfhw_Tbvx15apl_tOj0phiDBnNQiYYk-fhAyesfkbP4E',
    id: 3096,
    acronym: 'CNDAEM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'cnearc.fr',
    name: "Centre National d'Etudes Agronomiques des Régions Chaudes",
    web_page: 'http://www.cnearc.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYM6NvFQX9u_x55sGIHig5ljArqFvsHxe5jNk_Yv7Nh_h0dy3auu--QmM',
    id: 3097,
    acronym: 'CNDADRC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'cpe.fr',
    name: 'Ecole Supérieure de Chimie Physique Electronique de Lyon',
    web_page: 'http://www.cpe.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4JAED5rDanZlEYKoFbsU87l-iJ-_7g3kitocC2vrStn6D7x10GqEGCkI',
    id: 3098,
    acronym: 'ESDCPEDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'cribx1.u-bordeaux.fr',
    name: 'Université Bordeaux I',
    web_page: 'http://www.cribx1.u-bordeaux.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQWEfT56dZG3e0IvUPb98qEOYrsm0-zSCausTHNkxqXCDrc7gqB6t4s-I',
    id: 3099,
    acronym: 'UBI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'cust.univ-bpclermont.fr',
    name: "Institut des Sciences de l'Ingénieur de Clermont-Ferrand",
    web_page: 'http://cust.univ-bpclermont.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmmzAttB92t7tGzoLp2OeLxnlDGAaMTduD_RPp6h6C1MwbH4A4Bv0F7H1z',
    id: 3100,
    acronym: 'IDSDLDC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'dauphine.fr',
    name: 'Université Paris-Dauphine (Paris IX)',
    web_page: 'http://www.dauphine.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDP_cxgXg-VA8VEEyx0knbKnMmvcLvOQdKcnJM9xqdWQxynH8rsBONV8Y',
    id: 3101,
    acronym: 'UP(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'devinci.fr',
    name: "Ecole Superieur d'Ingenieurs Leonard de Vinci",
    web_page: 'http://www.devinci.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNDET2j0qQ8ipNknRarBBAF_l9plF7ptpKAK4xBX11XSmMMDPUe10jX2k',
    id: 3102,
    acronym: 'ESDLDV',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'e3i.univ-tours.fr',
    name: "Ecole d'Ingénieurs en Informatique pour l'Industrie",
    web_page: 'http://www.e3i.univ-tours.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQroYMTazZdRBhoAo0J_h2VRYH347vCEcg9ZDZNi6AfmUEaT3-giIgusV3J',
    id: 3103,
    acronym: 'EDEIPL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'eap.fr',
    name: 'Ecole Européen des Affaires',
    web_page: 'http://www.eap.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6TDpBfzEGIJJ3RPQ8UybMUUhtHQMUUMfufYjwZrLTTgq32TZm9QTWIw',
    id: 3104,
    acronym: 'EEDA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ebs-paris.com',
    name: 'European Business School',
    web_page: 'http://www.ebs-paris.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeDJj3dQoaV5FsTy-0DnPX4u1BLMNnJrMxgbAUqL3f8Z0n3tGyvKLUf2Rw',
    id: 3105,
    acronym: 'EBS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ecam.fr',
    name: "Ecole Catholique d'Arts & Metiers",
    web_page: 'http://www.ecam.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ1dNZaoDR7NDEeIkqROgfWUI5tNMRp-dchdWBfL87czfmCWwm1pMsD6Jc',
    id: 3106,
    acronym: 'ECD&M',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ece.fr',
    name: "Ecole Centrale d'Electronique - ECE",
    web_page: 'http://www.ece.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDLSTMvtXqZdR2nMMXKdZ-cSx5QPtXN7xt8fw81wljfNww1xrHCSmkUhix',
    id: 3107,
    acronym: 'ECD-E',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ec-lille.fr',
    name: 'Ecole Centrale de Lille',
    web_page: 'http://www.ec-lille.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSmx_QjKvI-cbi9pdoxpn1kBLG8MSQX_7U6Zs74z8FshYhvPi5A83cFcY',
    id: 3108,
    acronym: 'ECDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ec-lyon.fr',
    name: 'Ecole Centrale de Lyon',
    web_page: 'http://www.ec-lyon.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxCf39QLuFHsXRsfB4dsQZrTK5p6NKOzJF23lYqdR3-b-8eXz83bDUgc_L',
    id: 3109,
    acronym: 'ECDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ec-nantes.fr',
    name: 'Ecole Centrale de Nantes',
    web_page: 'http://www.ec-nantes.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ76EfKntew1lGrqhPF3h8zZfMGTvqz2HO_itYJnpCAfW0nG3L4gQpiw',
    id: 3110,
    acronym: 'ECDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'centrale-marseille.fr',
    name: 'Ecole Centrale de Marseille',
    web_page: 'https://www.centrale-marseille.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVPFsWBIHlWuqBh94xFrnM6nbHdok-VAHL56gws4cbLGnd5HuBdZaCQg',
    id: 3111,
    acronym: 'ECDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ecoles-idrac.com',
    name: 'IDRAC (Institut de recherche en action commerciale)',
    web_page: 'http://www.ecoles-idrac.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5TVahpOF25LoD93Id5VM8SQ568mir5GA3kCi7VfZFmjdd6vzdZ9Kotg',
    id: 3112,
    acronym: 'I(DREAC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'edu.edhec.com',
    name: 'Ecole des Hautes Etudes Commerciales du Nord',
    web_page: 'http://edu.edhec.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRf4-zLO2NFC5ULQeJEQCWr_ECN6zzY51UC50aUb_jxl6WFyBCgALUh-w',
    id: 3113,
    acronym: 'EDHECDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'eerie.fr',
    name: 'Ecole pour les Etudes et la Recherche en Informatique et Electronique',
    web_page: 'http://www.eerie.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd7oa-fsHqiDTe5cmAltDfLxnpRFkMIX1BwqKdwG8M3uGSJ3XFQUpbYNt_',
    id: 3114,
    acronym: 'EPLEELREIEE',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'efpg.inpg.fr',
    name: 'Ecole Française de Papeterie et des Industries Graphiques',
    web_page: 'http://www.efpg.inpg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJiS-f0UxROxK2QqtL3XK8s6kBcxnXPqAqPHYF18aXmzMlyI8nYlMTIA',
    id: 3115,
    acronym: 'EFDPEDIG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'efrei.fr',
    name: "Ecole Française d'Electronique et d'Informatique",
    web_page: 'http://www.efrei.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBQAZxiRdxLQzkE5DcD-iHVSap8ooqsFwX6jS7dIBJw48dSDMZ1Xowpg',
    id: 3116,
    acronym: 'EFDED',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'eivp-paris.fr',
    name: 'Ecole des Ingénieurs de la Ville de Paris',
    web_page: 'http://www.eivp-paris.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqAfka2-i4Z5eCYeJ8_pciHRgabNm6Ui2McC1xfxeNT_V2mwNBgFybvH0',
    id: 3117,
    acronym: 'EDIDLVDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'emc-campus.com',
    name: 'European Management Center Paris',
    web_page: 'http://www.emc-campus.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrICChhKgUiF9GyKPCZ_oHXVbUAQjX0XwEpQiY1ppn1cQYJgaFcAi5NGcR',
    id: 3118,
    acronym: 'EMCP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'em-lyon.com',
    name: 'Ecole Supérieure de Commerce de Lyon',
    web_page: 'http://www.em-lyon.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT-KdyA81Wiqdo2Wbv2ZSuDEKesG6y37rBK561K_Q6r9XaSM2iTEkKHBs',
    id: 3119,
    acronym: 'ESDCDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'emse.fr',
    name: 'Ecole Nationale Supérieure des Mines de St-Etienne',
    web_page: 'http://www.emse.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFP5Zm1SWstKrnT7iZpfxWI1_co64uD6b7tQNMtOAc1UkPok0aCIfTOg',
    id: 3120,
    acronym: 'ENSDMDS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enac.fr',
    name: "Ecole Nationale de l'Aviation Civile",
    web_page: 'http://www.enac.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRtPkkYQKzQI8uERjShcgUjSlGuFUt1veZOXrf8Xt8ApOhhPj2gO0CCg',
    id: 3121,
    acronym: 'ENDLC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ena.fr',
    name: "Ecole Nationale d'Administration",
    web_page: 'http://www.ena.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS866aXQitkNk5wDikWGemH8csitdAlsMp2y8eltolP332PEvVG4s5SFg',
    id: 3122,
    acronym: 'END',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enic.fr',
    name: 'ENIC Telecom Lille 1',
    web_page: 'http://www.enic.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHgruTx2SMBVJfwcgeDJoDl0n7QmOOUbMMmXWwTd7iZ2T_kubDankZFPk',
    id: 3123,
    acronym: 'ETL1',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enim.fr',
    name: "Ecole Nationale d'Ingénieurs de Metz",
    web_page: 'http://www.enim.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwld3rsKqFa-6tkhCdtyj_7VeSWgwH9UUQsccLIPjAM8LnQ-buVtOUs50',
    id: 3124,
    acronym: 'ENDDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enise.fr',
    name: "Ecole Nationale d'Ingénieurs de Saint-Etienne",
    web_page: 'http://www.enise.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGnc7JFeJl5fyWNzFUZgVJQFMNGmrA3oqkKr2KKyCZQJLpoIjme-_J2pQ',
    id: 3125,
    acronym: 'ENDDS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enitab.fr',
    name: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux",
    web_page: 'http://www.enitab.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSr5GGBedUP4ddYcTiVHLJlHYbxFHDb5i6Yay3wtygQ0-f2REJveKRGZxY',
    id: 3126,
    acronym: 'ENDDTADB',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enitac.fr',
    name: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand",
    web_page: 'http://www.enitac.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdM5lu3Hz6l_mwkHgYAomJ5XyaJDSVDK5LqOSvIbL0KZMRCZMXo2_2og',
    id: 3127,
    acronym: 'ENDDTADC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enit.fr',
    name: "Ecole Nationale d'Ingénieurs de Tarbes",
    web_page: 'http://www.enit.fr/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcR3VyaZXLmO3MWydGPXpT2mPlWQri9-0rb0vOlbcoCsBWbgPAjU6qrHuA',
    id: 3128,
    acronym: 'ENDDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enitiaa-nantes.fr',
    name: "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires",
    web_page: 'http://www.enitiaa-nantes.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3129,
    acronym: 'ENDDTDIAEA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enm.meteo.fr',
    name: 'Ecole Nationale de la Météorologie',
    web_page: 'http://www.enm.meteo.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx0FTsju4wygTNESi8e490J-jBVLuDOyRD95YUUdcO1b1qVmMhHI_1Ac4',
    id: 3130,
    acronym: 'ENDLM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enpc.fr',
    name: 'Ecole Nationale des Ponts et Chausees',
    web_page: 'http://www.enpc.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuzqL3s1dJpeIH2FmXWebTYQeruzrYqpfg5fsQNMTSlgW4sJN1E4LDts0',
    id: 3131,
    acronym: 'ENDPEC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ens2m.fr',
    name: 'Ecole Nationale Supérieur de Mécaniques et des Microtechniques',
    web_page: 'http://www.ens2m.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQasqMLZ0gRcJJlq_-XZ9pNQCwHw-Q8o88V5O3WEn3YXx9N_8ai59zFroea',
    id: 3132,
    acronym: 'ENSDMEDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensae.fr',
    name: "Ecole Nationale de la Statistique et de l'Administration Economique",
    web_page: 'http://www.ensae.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxaSjFBOvUAOhsMMTMz1CKc-uUhyaWB9zYNCg6WhKOzY8nr5GuyepZDvPB',
    id: 3133,
    acronym: 'ENDLSEDLE',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensaia.u-nancy.fr',
    name: "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires",
    web_page: 'http://www.ensaia.u-nancy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnbhZ95zn_UbY43Gtgb4J3BxdnSWy028_Jsz7axnf357fOiiWrUjN3rhc',
    id: 3134,
    acronym: 'ENSDEDIA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensai.fr',
    name: "Ecole Nationale de la Statistique et de l'Analyse de l'information",
    web_page: 'http://www.ensai.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvGEEZAw7-yJATSNQX1kALFUKwdKe2PjmwTLSeN_3yaJcFwLIK5Ghv-17B',
    id: 3135,
    acronym: 'ENDLSEDLDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensait.fr',
    name: 'Ecole Nationale Supérieure des Arts et Industries Textiles',
    web_page: 'http://www.ensait.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjPolD1Zuh5tE4l8QJBqz21KnBj437hQP2gHBwjjpzu4DeNJofR-1x1w',
    id: 3136,
    acronym: 'ENSDAEIT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensam.inra.fr',
    name: "Ecole Nationale Supérieure d'Agronomie de Montpellier",
    web_page: 'http://www.ensam.inra.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUyMdXcLOJOh0QWtDYuQC_tH5QZpqIR9Lff83H04RraoS8xSIZ-sYhMI',
    id: 3137,
    acronym: 'ENSDDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensat.fr',
    name: 'Ecole Nationale Supérieure Agronomique de Toulouse',
    web_page: 'http://www.ensat.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3FqJyqBzge2qy99zDu3n7PtfB6EwxRDNhJxX-DchUyrNvJRRxVb7bqYA',
    id: 3138,
    acronym: 'ENSADT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ens-cachan.fr',
    name: 'Ecole Normale Supérieure de Cachan',
    web_page: 'http://www.ens-cachan.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB4SjIlvDPePbusYL00ZpNbH5EHnMVvt2K6wKGlPxp-nYvAKD9Fazy3a0',
    id: 3139,
    acronym: 'ENSDC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensccf.univ-bpclermont.fr',
    name: 'Ecole Nationale Supérieure de Chimie de Clermont-Ferrand',
    web_page: 'http://ensccf.univ-bpclermont.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzrQvnqMkA8-0OoSgilKdUyvsxJroCsfeN1sk8MJ7q45rUlgXPQlOznSc',
    id: 3140,
    acronym: 'ENSDCDC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensc-lille.fr',
    name: 'Ecole Nationale Supérieure de Chimie de Lille',
    web_page: 'http://www.ensc-lille.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSmwnEFXAzOq3LcwJ8Xy_Wx1p09TS7GlELY4tqlhwQ8ukUChRrE3ckJoQ',
    id: 3141,
    acronym: 'ENSDCDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enscm.fr',
    name: 'Ecole Nationale Supérieure de Chimie de Montpellier',
    web_page: 'http://www.enscm.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsszwBxmXp2XMopvNpUvHzWgpJKMAvT48Js7wNb0rhcdJA8flSNL3LNW4',
    id: 3142,
    acronym: 'ENSDCDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enscmu.univ-mulhouse.fr',
    name: 'Ecole Nationale Supérieure de Chimie de Mulhouse',
    web_page: 'http://www.enscmu.univ-mulhouse.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNIjLgNKs4yNvrS-MwmZAmW1Vd-HeTboIJ9-u41ySQd8_azU3olMI8lCk',
    id: 3143,
    acronym: 'ENSDCDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enscpb.u-bordeaux.fr',
    name: 'Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux',
    web_page: 'http://www.enscpb.u-bordeaux.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSucSxll4WjOBwBKmy6HjhMeZmzLDj4Lo_bCGZIG-QAmlaEb9Fr4YRv8A',
    id: 3144,
    acronym: 'ENSDCEDPDB',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enscp.jussieu.fr',
    name: 'Ecole Nationale Supérieure de Chimie de Paris',
    web_page: 'http://www.enscp.jussieu.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR32vU0ollKwpKilHCrifZ4v2BavP1PbxGvG6PegpmPFuVF5Hx9nj1HUu4',
    id: 3145,
    acronym: 'ENSDCDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensc-rennes.fr',
    name: 'Ecole Nationale Supérieure de Chimie de Rennes',
    web_page: 'http://www.ensc-rennes.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-D2FveZkg5xj0SBTLHOhrq21DVt0MbTh86KBQSuRHpUvBbX1WQ8UP3Q',
    id: 3146,
    acronym: 'ENSDCDR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensea.fr',
    name: "Ecole Nationale Supérieure de l'Electronique et de ses Applications",
    web_page: 'http://www.ensea.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdM5lu3Hz6l_mwkHgYAomJ5XyaJDSVDK5LqOSvIbL0KZMRCZMXo2_2og',
    id: 3147,
    acronym: 'ENSDLEDSA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enseeiht.fr',
    name: "Ecole Nationale Supérieure d'Electronique d'Electrotechnique d'Informatique et d'Hydraulique de Toulouse",
    web_page: 'http://www.enseeiht.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrKkywqJc9akMRzfey55hhaLFHT6z-Bga5dhAExNqpb2bRHlGcpf3Ujg',
    id: 3148,
    acronym: 'ENSDDDEDDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enseeiht.fr',
    name: 'Ecole Nationale Supérieure en Electrotechnique Electronique Informatique et Hydraulique de Toulouse',
    web_page: 'http://www.enseeiht.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuoHdjcR7aCvK0lZ6tlK6l3t0F_DdjuWQjqYD14lJ9xpm_M1-IgGeSLg',
    id: 3149,
    acronym: 'ENSEEEIEHDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensem.u-nancy.fr',
    name: 'Ecole Nationale Supérieure Electricité et Mécanique',
    web_page: 'http://www.ensem.u-nancy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdM5lu3Hz6l_mwkHgYAomJ5XyaJDSVDK5LqOSvIbL0KZMRCZMXo2_2og',
    id: 3150,
    acronym: 'ENSEEM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enserb.u-bordeaux.fr',
    name: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux",
    web_page: 'http://www.enserb.u-bordeaux.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3151,
    acronym: 'ENSDEDRDB',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enserg.fr',
    name: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Grenoble",
    web_page: 'http://www.enserg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR15BLp0QyeQkVXAHRSuqKpbMrK98SD-SSX9NtD9VzUM0EkalDo0JPUOw',
    id: 3152,
    acronym: 'ENSDEDRDG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ens-fcl.fr',
    name: 'Ecole Normale Supérieure de Fontenay-Saint Cloud',
    web_page: 'http://www.ens-fcl.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS8qESA4XZvY2BjhUvZfrlptN4Uw6euR5D5Kand3KQKGCHgnJaqi6TBow',
    id: 3153,
    acronym: 'ENSDFC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ens.fr',
    name: 'Ecole Normale Supérieure de Paris',
    web_page: 'http://www.ens.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9UtlpZ3MJ7FZEdCFE9_G0i6oTOSYujdaBYlIR2O0ss-z_kG3aY50AEA',
    id: 3154,
    acronym: 'ENSDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensg.ign.fr',
    name: 'Ecole Nationale des Sciences Géographiques',
    web_page: 'http://www.ensg.ign.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmCyYhn53J_rgwzFViDc2cmanzJzHz0xPlfBqi2s5ROueexs-4sNM1bWw',
    id: 3155,
    acronym: 'ENDSG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensg.u-nancy.fr',
    name: 'Ecole Nationale Supérieur de Géologie de Nancy',
    web_page: 'http://www.ensg.u-nancy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3156,
    acronym: 'ENSDGDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensica.fr',
    name: "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique",
    web_page: 'http://www.ensica.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZU838i7gySdaB-Uo9oDsLhnD7muGEoB2Woa0D11YmVmZ2eCe1v5P7KA',
    id: 3157,
    acronym: 'ENSDDCA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensica.fr',
    name: "École Nationale Supérieure d'Ingénieurs de Constructions Aéronautiques",
    web_page: 'http://www.ensica.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZU838i7gySdaB-Uo9oDsLhnD7muGEoB2Woa0D11YmVmZ2eCe1v5P7KA',
    id: 3158,
    acronym: 'ÉNSDDCA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensic.u-nancy.fr',
    name: 'Ecole Nationale Supérieure des Industries Chimiques de Nancy',
    web_page: 'http://www.ensic.u-nancy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT17gHlASK30cmTR9RTaCEEZj3DEnHwgmlVFCLAsIMxNSF3GpATbp-URp8',
    id: 3159,
    acronym: 'ENSDICDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensieg.inpg.fr',
    name: "Ecole Nationale Supérieure d'Ingenieurs Electriciens de Grenoble",
    web_page: 'http://www.ensieg.inpg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbzjyvMZRvJgFvjUKIKOJS_zIy2ZkIPYSW_fQ48hI2nEJU24ElHa-rxh9y',
    id: 3160,
    acronym: 'ENSDEDG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensieta.fr',
    name: "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement",
    web_page: 'http://www.ensieta.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT59w2hzFgoDI9Z1fIXt3g2aEQvw6aql-uHwefrlNewmjzmG0gaTlUoBEIc',
    id: 3161,
    acronym: 'ENSDIDEETD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensitm.fr',
    name: 'Ecole Nationale Supérieure des Industries Textiles de Mulhouse',
    web_page: 'http://www.ensitm.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3162,
    acronym: 'ENSDITDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ens-lyon.fr',
    name: 'Ecole Normale Supérieure de Lyon',
    web_page: 'http://www.ens-lyon.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQasaGaz56yygxmV_M7Vgzzg5wMCCIsRR0fdeXTvfeAQCPyijv2_zHIC4M',
    id: 3163,
    acronym: 'ENSDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensma.fr',
    name: "Ecole Nationale Supérieur de Mécanique et d'Aéronautique",
    web_page: 'http://www.ensma.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvVPbG6EnPnTFG3EeMz2seAeiWu4VNs9updd4CRwsLJZfDzaWteozt2Aa3',
    id: 3164,
    acronym: 'ENSDMED',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensm-ales.fr',
    name: "Ecole Nationale Supérieure des Mines d'Alès",
    web_page: 'http://www.ensm-ales.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Nw2BbBbRvhI8STMx62Nvidr-HjtTL-bhq1YiSFek6G6TPCiq_iVVljg',
    id: 3165,
    acronym: 'ENSDMD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensm-douai.fr',
    name: 'Ecole Nationale Supérieure des Mines de Douai',
    web_page: 'http://www.ensm-douai.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsVQcrS-Y5csxstS6IMYvBTp_SOvcQLEItG1OXFp7H91xImwivsz9MEvU',
    id: 3166,
    acronym: 'ENSDMDD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensmp.fr',
    name: 'Ecole Nationale Supérieure des Mines de Paris',
    web_page: 'http://www.ensmp.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUSgKbS8H3hNdFX_Ep9emAXchy9tY1NevFcCHk5Tme5cUL0UgYPy2d2w',
    id: 3167,
    acronym: 'ENSDMDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enspm.u-3mrs.fr',
    name: 'Ecole Nationale Supérieure de Physique de Marseille',
    web_page: 'http://www.enspm.u-3mrs.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEQ2x7kSoEDaqjeR6N1lkLRfVNsgDhR5Vnn_ezT-vtDJp5fIZP6LH3W14',
    id: 3168,
    acronym: 'ENSDPDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ensta.fr',
    name: 'Ecole Nationale Supérieure de Techniques Avancées',
    web_page: 'http://www.ensta.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdM5lu3Hz6l_mwkHgYAomJ5XyaJDSVDK5LqOSvIbL0KZMRCZMXo2_2og',
    id: 3169,
    acronym: 'ENSDTA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enst-bretagne.fr',
    name: 'Ecole Nationale Supérieure des Telecommunications de Bretagne',
    web_page: 'http://www.enst-bretagne.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOOdPzFLLqfauHFTeCAFLec-16UNw9-eafKyjzdySIfmBopetRJjU2dCnd',
    id: 3170,
    acronym: 'ENSDTDB',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'enst.fr',
    name: 'Ecole Nationale Supérieure des Telecommunications de Paris',
    web_page: 'http://www.enst.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT3p3ThJ6yR1Q19wB6zMBDobL7DC5RhkRgHWiOvi4TPgs25dTXj0yGyLs',
    id: 3171,
    acronym: 'ENSDTDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'entpe.fr',
    name: "Ecole Nationale des Travaux Publics de l'Etat",
    web_page: 'http://www.entpe.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSOkFrpUjbGwBg9eSojJHEO5UXmo1VPz0gLc4Pcgjn4Osodhn29w9Biw',
    id: 3172,
    acronym: 'ENDTPDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'envt.fr',
    name: 'Ecole Nationale Vétérinaire de Toulouse',
    web_page: 'http://www.envt.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA9yIHvJVPZTn1uT85WSblqfN1QSwN3qKm0ONP0q6-EjMZZJxLb_G0OCs',
    id: 3173,
    acronym: 'ENVDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'epf.fr',
    name: "EPF Ecole d'Ingénieurs",
    web_page: 'http://www.epf.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwzcw23FWm4ed-FseTMbJYIuNfoyF65zyQmGype1kIcLQc0UpVjkNKG-FP',
    id: 3174,
    acronym: 'EED',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esa-purpan.fr',
    name: "Ecole Supérieure d'Agriculture de Purpan",
    web_page: 'http://www.esa-purpan.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOcObD6GQFGvfuT6--e3mld9pIJlXnzT2qwCWVesLci_VZuhthiOXhDWE',
    id: 3175,
    acronym: 'ESDDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-bordeaux.fr',
    name: 'Ecole Supérieure de Commerce de Bordeaux',
    web_page: 'http://www.esc-bordeaux.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3176,
    acronym: 'ESDCDB',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-brest.fr',
    name: 'Ecole Supérieure de Commerce de Brest',
    web_page: 'http://www.esc-brest.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGAcnyIOZ9xiBljeSzQGg7_hOo4zyF-85qENTsGryA-jX0rWruOJV7HlQ',
    id: 3177,
    acronym: 'ESDCDB',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-clermont.fr',
    name: 'Ecole Supérieure de Commerce de Clermont-Ferrand',
    web_page: 'http://www.esc-clermont.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3178,
    acronym: 'ESDCDC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'escdijon.com',
    name: 'Ecole Supérieure de Commerce de Dijon',
    web_page: 'http://www.escdijon.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3179,
    acronym: 'ESDCDD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'escem.fr',
    name: 'Ecole Supérieure de Commerce et Management',
    web_page: 'http://www.escem.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo1jSenpuQwDZjA_PD2glRg2pGlgZfOL8jUA3KAeSldIuAHCIhD7swhGAzeg',
    id: 3180,
    acronym: 'ESDCEM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-grenoble.fr',
    name: 'Ecole Supérieure de Commerce de Grenoble',
    web_page: 'http://www.esc-grenoble.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAc_bGQs9rgRDN4M0c5E3RQ8c-2iGqguzq90oXPVi8gKmEF6cOXj46k38',
    id: 3181,
    acronym: 'ESDCDG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-lille.fr',
    name: 'Ecole Supérieure de Commerce de Lille',
    web_page: 'http://www.esc-lille.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSmwnEFXAzOq3LcwJ8Xy_Wx1p09TS7GlELY4tqlhwQ8ukUChRrE3ckJoQ',
    id: 3182,
    acronym: 'ESDCDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-marseille.fr',
    name: 'Ecole Supérieure de Commerce de Marseille-Provence',
    web_page: 'http://www.esc-marseille.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRIjK4fQn2V9Yu80yUj9jgt3_pRq3B1lRvTw--PZa8o-1F1rjOyaHeh6o',
    id: 3183,
    acronym: 'ESDCDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'escna.fr',
    name: 'Ecole Supérieure de Commerce de Nantes-Atlantique',
    web_page: 'http://www.escna.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSmwnEFXAzOq3LcwJ8Xy_Wx1p09TS7GlELY4tqlhwQ8ukUChRrE3ckJoQ',
    id: 3184,
    acronym: 'ESDCDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-normandie.fr',
    name: 'Ecole Supérieure de Commerce de Le Havre/Caen',
    web_page: 'http://www.esc-normandie.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZtr3WuIVDr66IqZeCdBbmkRe4fsBv-uS4D-CSp0Lvc0hJrTAXbh_Q0Z8',
    id: 3185,
    acronym: 'ESDCDLH',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'escom.fr',
    name: 'Ecole Supérieure de Chimie Organique et Minérale',
    web_page: 'http://www.escom.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuS4v_GH9VCXdEvkZ-P-dsrvIkb5XGnnP7JFFMk0Cg-zKRG28HDY1gflI',
    id: 3186,
    acronym: 'ESDCOEM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-pau.fr',
    name: 'Ecole Supérieure de Commerce de Pau',
    web_page: 'http://www.esc-pau.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3187,
    acronym: 'ESDCDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'escp.fr',
    name: 'Ecole Supérieure de Commerce de Paris',
    web_page: 'http://www.escp.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3188,
    acronym: 'ESDCDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-reims.edu',
    name: 'Ecole Supérieure de Commerce de Reims',
    web_page: 'http://www.esc-reims.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTQh1r80nR-w3-3kB6h2CM4l1P4G8Uzzf7_4wDhLSMqyd_R5i0DeCZDQ',
    id: 3189,
    acronym: 'ESDCDR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-rouen.fr',
    name: 'Ecole Supérieure de Commerce de Rouen',
    web_page: 'http://www.esc-rouen.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpGlSAcaKJY2rwGdNK2i_MDmCUMwudphR3U5iJcY3AffAgwzYpEqSU26g',
    id: 3190,
    acronym: 'ESDCDR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esc-toulouse.fr',
    name: 'Ecole Supérieure de Commerce de Toulouse',
    web_page: 'http://www.esc-toulouse.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3191,
    acronym: 'ESDCDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'eseo.fr',
    name: "Ecole Supérieure d'Electronique de l'Ouest",
    web_page: 'http://www.eseo.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDRPXzYgn2MpHL-cgG6IoK2kf9oMG1IH67ORxgckMUug-BWRpiebrtuw',
    id: 3192,
    acronym: 'ESDDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esiae.fr',
    name: "Ecole Supérieure Internationale d'Administration des Entreprises",
    web_page: 'http://www.esiae.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYR6PKcTTIUVJ7YXXm-cJKfS8NQx9sxvPzPmGKWBz7BwLydtiOq72HrXwq',
    id: 3193,
    acronym: 'ESIDDE',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esiea.fr',
    name: "Ecole Supérieure d'Informatique-Electronique-Automatique",
    web_page: 'http://www.esiea.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeTXtxwKJqzrnldYGsr156FEICcbLDZWR2ipd9b0Yiswyc6TvUdldup0c',
    id: 3194,
    acronym: 'ESD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esiee.fr',
    name: "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique",
    web_page: 'http://www.esiee.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBQAZxiRdxLQzkE5DcD-iHVSap8ooqsFwX6jS7dIBJw48dSDMZ1Xowpg',
    id: 3195,
    acronym: 'ESDEEEE',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esigelec.fr',
    name: "Ecole Supérieure d'Ingénieurs en Génie Electrique",
    web_page: 'http://www.esigelec.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoQibBcwCmUeiB1mxc5JBFm50KxSiTQ3htaupzLxHQBDhv6nwtQK5oaKVP',
    id: 3196,
    acronym: 'ESDEGE',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esim.imt-mrs.fr',
    name: "Ecole Supérieure d'Ingénieurs de Marseille",
    web_page: 'http://esim.imt-mrs.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMi0KjfSkMlVuIN31bzLaHE3NQJwOxrgcA1H0XW2M0U19BvibqkFsKaw',
    id: 3197,
    acronym: 'ESDDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esitpa.org',
    name: "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture",
    web_page: 'http://www.esitpa.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUyMdXcLOJOh0QWtDYuQC_tH5QZpqIR9Lff83H04RraoS8xSIZ-sYhMI',
    id: 3198,
    acronym: 'ESDEDTPL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esme.fr',
    name: "Ecole Spéciale de Mécanique et d'Electricité",
    web_page: 'http://www.esme.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtnonzGQhnUIv0Q0I9pgFWh7Y0rfxAWenQAbvbB1GhwS7XRDR9AmfvPng',
    id: 3199,
    acronym: 'ESDMED',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'espci.fr',
    name: 'Ecole Supérieure de Physique et de Chimie Industrielles',
    web_page: 'http://www.espci.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT9G7oq2YOjP_qaUhf9jnHK-BDHtcyh-R-aDj7jhFeZj8xUZCO5gQvDxekvQ',
    id: 3200,
    acronym: 'ESDPEDCI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'essca.asso.fr',
    name: "Ecole Supérieure des Sciences Commerciales d'Angers",
    web_page: 'http://www.essca.asso.fr/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSSmwnEFXAzOq3LcwJ8Xy_Wx1p09TS7GlELY4tqlhwQ8ukUChRrE3ckJoQ',
    id: 3201,
    acronym: 'ESDSCD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'essec.fr',
    name: 'Ecole Supérieure des Sciences Economiques et Commerciales',
    web_page: 'http://www.essec.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzaQ2HG0D7pA_bqbtQiEvATNYbC506bR-Q3Iew7mdpV0_TUMhMtYhBUg',
    id: 3202,
    acronym: 'ESDSEEC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'esstin.u-nancy.fr',
    name: "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy",
    web_page: 'http://www.esstin.u-nancy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-n3VPRw7uIMlyzfIAkiGQ2M_HiVkzSlLi2DWqzihtzh4M8ZSbabZqFTI',
    id: 3203,
    acronym: 'ESDSETDLDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'estp.fr',
    name: "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
    web_page: 'http://www.estp.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNHgyc4eYOMxD9h-1IPp9vHk73JjBlvfQqHS_6rQBnBrZavyy8_BvBDRxi',
    id: 3204,
    acronym: 'ESDTPDBEDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'eudil.fr',
    name: "Ecole Universitaire d'Ingénieurs de Lille",
    web_page: 'http://www.eudil.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnierN0BFwmmp6sQCPQ4v9kL5QjBYHNZSC6W7gmu0shOWaBj7fMEfgYkw',
    id: 3205,
    acronym: 'EUDDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'fupl.asso.fr',
    name: 'Ecole Supérieure des Techniques Industrielles et des Textiles',
    web_page: 'http://www.fupl.asso.fr/estit/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwJV2hNLQgQyaTixxOSMhMQuchnVoaW8_AhaaYNOkrqkjG9bH9Hd4Auu0',
    id: 3206,
    acronym: 'ESDTIEDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'fupl.asso.fr',
    name: 'Université Catholique de Lille',
    web_page: 'http://www.fupl.asso.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1hmXsPGHoX_HBwj5CVODQhgC-nAm7sJxofr5iCgT4dYzC-9o9wVM5hJc',
    id: 3207,
    acronym: 'UCDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'grenoble-em.com',
    name: 'Grenoble Ecole de Management',
    web_page: 'http://www.grenoble-em.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAc_bGQs9rgRDN4M0c5E3RQ8c-2iGqguzq90oXPVi8gKmEF6cOXj46k38',
    id: 3208,
    acronym: 'GEDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'groupe-esa.com',
    name: "Ecole Supérieure d'Agriculture d'Angers",
    web_page: 'http://www.groupe-esa.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnUut9Xq85WYsMpN8IX3oVP1gkd77YuFOUfKdFDLLo6ZFDoXp0BVdKRw',
    id: 3209,
    acronym: 'ESDD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'hec.fr',
    name: 'Ecole des Hautes Etudes Commerciales',
    web_page: 'http://www.hec.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc4NhjIcosGETzo2klRFOb7UTnbhyosC-FVPZkZrHyZ-QqO44Sdtc9b5M',
    id: 3210,
    acronym: 'EDHEC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'hei.fr',
    name: 'Ecole des Hautes Etudes Industrielles de Lille',
    web_page: 'http://www.hei.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGzIGZ-nbyKw84_1UQSHg3R8NHDBURf259gmkrQQmQSqUYM8hc-6MXkwE',
    id: 3211,
    acronym: 'EDHEIDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'hmg.inpg.fr',
    name: "Ecole Nationale Supérieure d'Hydraulique et de Mécanique de Grenoble",
    web_page: 'http://www.hmg.inpg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJD2E56pm4ITZNFefecNE03HF5zWrJCIe3TDrjyzo7xx04yX6K5XPvZQ',
    id: 3212,
    acronym: 'ENSDEDMDG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'icam.fr',
    name: "Institut Catholique d'Arts et Métiers Lille",
    web_page: 'http://www.icam.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2O6VO6nLHrV_nM9nxd3JGcCtlF4Qhvz1K6sNqYsolT8NE1GhE3wTtKM7H',
    id: 3213,
    acronym: 'ICDEML',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'icam.fr',
    name: "Institut Catholique d'Arts et Métiers Nantes",
    web_page: 'http://www.icam.fr/presentation/nantes.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnrWTIdETBHh6np_xv4rn8Epwz2PJSAsdFfu9RW0UtKQboyprbPqmmssk',
    id: 3214,
    acronym: 'ICDEMN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'icn-nancy.com',
    name: 'Institut Commercial de Nancy',
    web_page: 'http://www.icn-nancy.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHGgaombIcZ0-OYlQJeg9ydX787KrPAvIqPK0Wn4OaGOmwBYSqiOzhbb4',
    id: 3215,
    acronym: 'ICDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'icp.fr',
    name: 'Institut Catholique de Paris',
    web_page: 'http://www.icp.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUwGElcLZcO41RGHrml9OJsw6ZefvCsoNhPhyYHeErrbgugq54DlGLzhlX',
    id: 3216,
    acronym: 'ICDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ict-toulouse.asso.fr',
    name: 'Institut Catholique de Toulouse',
    web_page: 'http://www.ict-toulouse.asso.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv3xgnqrWi1se3-pqAn--7O7kiSTfObgpSKfuh1dI1WTQxu0L_blhXWhmU',
    id: 3217,
    acronym: 'ICDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'iep.u-bordeaux.fr',
    name: "Institut d'Etudes Politiques de Bordeaux",
    web_page: 'http://www.iep.u-bordeaux.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRJfVWJoUwNm3YYmNnIoJA3sJp87QwSQ5KxEoyPauEWMwTZLWtDWYSVoY',
    id: 3218,
    acronym: 'IDPDB',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ifp.fr',
    name: 'Ecole Nationale Supérieure du Pétrole et des Monteurs',
    web_page: 'http://www.ifp.fr/EC/EC000GP1.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3219,
    acronym: 'ENSDPEDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'inp-fc.fr',
    name: "Ecole Nationale Supérieure d'Ingénieurs de Génie Chimique",
    web_page: 'http://www.inp-fc.fr/~ensigc/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4VOsD6tLGmpsVsXtGPkMazy-e6bPbwT4KNFq5yT8jJueaLn_uJPBMySA',
    id: 3220,
    acronym: 'ENSDDGC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'inp-fc.fr',
    name: 'Institut National Polytechnique de Toulouse',
    web_page: 'http://www.inp-fc.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHr6SunSqYhNNpP6TSuXQJhl9yGsVt5PsgzQ_LCGmZqx5lVZJtCuKR5g',
    id: 3221,
    acronym: 'INPDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'inpg.fr',
    name: "Ecole Nationale Supérieure d'Electrochimie et d'Electrométallurgie de Gernoble",
    web_page: 'http://www.inpg.fr/INPG/fr_see.html',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3222,
    acronym: 'ENSDEDDG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'inpg.fr',
    name: 'Institut National Polytechnique de Grenoble',
    web_page: 'http://www.inpg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsmE___q1j_MxQGiLC3fOByfQY42CEn2IVIYrZQgJxciwY49RGFG0NeGU',
    id: 3223,
    acronym: 'INPDG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'inpl-nancy.fr',
    name: 'Institut National Polytechnique de Lorraine',
    web_page: 'http://www.inpl-nancy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhVbtbhT-osgBWJ4Tbk-y-mQN_LCZna9vimNIfkCe7qa4e5w9a2t-2vQ',
    id: 3224,
    acronym: 'INPDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'insa-lyon.fr',
    name: 'Institut National des Sciences Appliquées de Lyon',
    web_page: 'http://www.insa-lyon.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2LsctyM3xt1SYLTOVMiT-3Gh0k95ivbYLHl6OIhHtNyw1J0ALr0lo1g',
    id: 3225,
    acronym: 'INDSADL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'insa-rennes.fr',
    name: 'Institut National des Sciences Appliquées de Rennes',
    web_page: 'http://www.insa-rennes.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV92JcOvULiXa_IgZC2rm0Sqk81FbZQ5yvEH0jwu0dUF_81msGfN53gfA',
    id: 3226,
    acronym: 'INDSADR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'insa-rouen.fr',
    name: 'Institut National des Sciences Appliquées de Rouen',
    web_page: 'http://www.insa-rouen.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCnElEN8CNvU-kz6ctbrjr6fuJCc5JtwuhcCIjioinWWwODFmBSdrkSc',
    id: 3227,
    acronym: 'INDSADR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'insa-tlse.fr',
    name: 'Institut National des Sciences Appliquées de Toulouse',
    web_page: 'http://www.insa-tlse.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKAJr5Hd6jsrxWIG11TbX_I9DrX4Cg-sIcOf6desYCGK4Z5yExCaJsamA',
    id: 3228,
    acronym: 'INDSADT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'inseec.fr',
    name: 'Institut des hautes études économiques et commerciales',
    web_page: 'http://www.inseec.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZOPwHjESzfS6FDtnVfSyXQMMb6PJ65TZdXFM7lo0gSKw7vGIJdSob-dU',
    id: 3229,
    acronym: 'IDHÉÉEC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'int-evry.fr',
    name: 'Institut National des Télécommunications',
    web_page: 'http://www.int-evry.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSX6-EDbguDlXkDdM4uE6-2NwJ3pLiuTib288Kws3_L5x82uUCulPPeYQ',
    id: 3230,
    acronym: 'INDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'iota.u-psud.fr',
    name: "Ecole Supérieure d'Optique",
    web_page: 'http://www.iota.u-psud.fr/~ecole/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6zmUlPqvTKjihdB4bvahKZ25ZLbdvLM3koHQM24DHl7l5dfUWfiPWvg',
    id: 3231,
    acronym: 'ESD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ireste.fr',
    name: "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique",
    web_page: 'http://www.ireste.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3232,
    acronym: 'IDREDSATDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'isab.fr',
    name: 'Institut Supérieur Agricole de Beauvais',
    web_page: 'http://www.isab.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSLp679SMAX9_7fn5G7BP4fELH-KvJ6rnA6N3DJh4lcSmpeaOl-6slR_g',
    id: 3233,
    acronym: 'ISADB',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'isa.fupl.asso.fr',
    name: "Institut Supérieur d'Agriculture Lille",
    web_page: 'http://www.isa.fupl.asso.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE5ZrcLtonJmvad_SW-29cyFfHrIDhWC4-tR29pFawepAjSpg3TCVRTg',
    id: 3234,
    acronym: 'ISDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'isara.fr',
    name: "Institut Supérieur d'Agriculture Rhone-Alpes",
    web_page: 'http://www.isara.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfwuIMbxCnVK8G-tZfWsKVmACaoGDZV-Q2lemKU1aoOZUERlKol4wePzo',
    id: 3235,
    acronym: 'ISDR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'isen.fr',
    name: "Institut Supérieure d'Electronique du Nord",
    web_page: 'http://www.isen.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3236,
    acronym: 'ISDDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'isep.fr',
    name: "Institut Supérieure d'Electronique de Paris",
    web_page: 'http://www.isep.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBQAZxiRdxLQzkE5DcD-iHVSap8ooqsFwX6jS7dIBJw48dSDMZ1Xowpg',
    id: 3237,
    acronym: 'ISDDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'isim.univ-montp2.fr',
    name: "Institut des Sciences de l'Ingénieur de Montpellier",
    web_page: 'http://www.isim.univ-montp2.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvnq1TClSCxCsz3Kp2kpcGkVwyeHgchyewdtHFzUNIalRek9OqfE6vyQ',
    id: 3238,
    acronym: 'IDSDLDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ismcm-cesti.fr',
    name: "Centre d'Etudes Supérieures des Techniques Industrielles",
    web_page: 'http://www.ismcm-cesti.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaatmPCj3RHJPhlgdjB_dokP6-pcW63ZBKTSjW51UMQmsHLDC3gNK3ynCb',
    id: 3239,
    acronym: 'CDSDTI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ismra.fr',
    name: 'Institut des Sciences de la Matière et du Rayonnement',
    web_page: 'http://www.ismra.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3240,
    acronym: 'IDSDLMEDR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'itech.fr',
    name: 'Institut Textile et Chimique de Lyon',
    web_page: 'http://www.itech.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD9OyHPTV4YOeLoGZa6CIVBvWwzJDCaizaSuWCKHAKIpwW0BJEe5Ql8hY',
    id: 3241,
    acronym: 'ITECDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'jussieu.fr',
    name: 'Université Pierre et Marie Curie (Paris VI)',
    web_page: 'http://www.jussieu.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4de_ARn7v-EqkIGhIJYDmYXm1gNPplDS98mDUSHqLHZt3f2tIxMC4RQ',
    id: 3242,
    acronym: 'UPEMC(V',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'mines.u-nancy.fr',
    name: 'Ecole Nationale Supérieure des Mines de Nancy',
    web_page: 'http://www.mines.u-nancy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8Q2IuGP1gOugSsd9KgSSnPS3LjpoMhsuNUt3ngKTwcm16A5dEXCTc71gi',
    id: 3243,
    acronym: 'ENSDMDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'montaigne.u-bordeaux.fr',
    name: 'Université Michel de Montaigne (Bordeaux III )',
    web_page: 'http://www.montaigne.u-bordeaux.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEb3rhuDf9VpC8DsPWNXYhnXbijlWE2GtMLtm4qFQ-7c72UzwP3hfst3vt',
    id: 3244,
    acronym: 'UMDM(I)',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'montesquieu.u-bordeaux.fr',
    name: 'Université Montesquieu (Bordeaux IV)',
    web_page: 'http://www.montesquieu.u-bordeaux.fr/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQd4sBalOLxgzwyZdPavKZCyWURLDUrSlLwtTOzrtS5jQv8_EHFx3i66w',
    id: 3245,
    acronym: 'UM(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'nancy.archi.fr',
    name: "Ecole d'Architecture de Nancy",
    web_page: 'http://www.nancy.archi.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpd1OvnmD7EKgkV45J1hkOO4O0e6bYn-USrjwHnbg69CnS3P3dC__n2l8',
    id: 3246,
    acronym: 'EDDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'paris4.sorbonne.fr',
    name: 'Université Paris-Sorbonne (Paris IV)',
    web_page: 'http://www.paris4.sorbonne.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShn49gvsuDt52h7PbSTXY26hJomUdSjf2BbjkCfhk0gJ0c1L4Dr1DFLA',
    id: 3247,
    acronym: 'UP(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'paris.ensam.fr',
    name: "Ecole Nationale Supérieure d'Arts et Métiers de Paris",
    web_page: 'http://www.paris.ensam.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT82_wYFWlPa0X0L_KmlnIQAYKNJb4K_Zy_E627ltQgOpPXh6iZBt4aglaF',
    id: 3248,
    acronym: 'ENSDEMDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'polytech-lille.fr',
    name: 'Ecole Polytechnique Universitaire de Lille',
    web_page: 'http://www.polytech-lille.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSmwnEFXAzOq3LcwJ8Xy_Wx1p09TS7GlELY4tqlhwQ8ukUChRrE3ckJoQ',
    id: 3249,
    acronym: 'EPUDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'polytech-marseille.fr',
    name: 'Ecole Polytechnique Marseille',
    web_page: 'http://www.polytech-marseille.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3250,
    acronym: 'EPM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'polytechnique.fr',
    name: 'Ecole Polytechnique',
    web_page: 'http://www.polytechnique.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbl2lhNtpao24qJAJDPi1x5MJi4aiDkUiVxYktrF0H9CJxH1lOx9GU2Pac',
    id: 3251,
    acronym: 'EP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'schillerparis.com',
    name: 'Schiller International University Paris',
    web_page: 'http://www.schillerparis.com/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRkISPjUVdvvh6s8X1Obpv02ab06y2hH3cg5gAxuvhud8Posb4IOXmtxBA',
    id: 3252,
    acronym: 'SIUP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'schillerstrasbourg.com',
    name: 'Schiller International University Strasbourg',
    web_page: 'http://www.schillerstrasbourg.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvju9FinwVFBmedHQ0FhjpnfIKof7gxfdbns_agU94dVmvro8cytYH0rY',
    id: 3253,
    acronym: 'SIUS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'sciences-po.fr',
    name: "Institut d'Etudes Politiques de Paris (Sciences Po)",
    web_page: 'http://www.sciences-po.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGNMBFXzfpGW93SLrpGsSjSSnnGpZ3ggN1iBY0HMZpTCwNHAxhE-bgdsQ',
    id: 3254,
    acronym: 'IDPDP(P',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'sigu7.jussieu.fr',
    name: 'Université Denis Diderot (Paris VII)',
    web_page: 'http://www.sigu7.jussieu.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8wnXren8BhvucVCMH8z2YusXxzYm8qdUdFckSEjmq5gsJAMCxsFBdzh0',
    id: 3255,
    acronym: 'UDD(V',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'sorbon.fr',
    name: 'Ecole Superieure Robert de Sorbon',
    web_page: 'http://www.sorbon.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrxhdNYIKoEV5H3TWiKscOefccbzu6_UyOs8VHEg3kIi7gamzcEr2CvhM',
    id: 3256,
    acronym: 'ESRDS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'supaero.fr',
    name: "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace",
    web_page: 'http://www.supaero.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMrhlFlahwoBBnY1QuUJqRFHsd8jexjRS-tPAjbvvctkSpIDQDSGCkxaY',
    id: 3257,
    acronym: 'ENSDLEDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'supco-amiens.fr',
    name: 'Groupe Sup de Co Amiens Picardie',
    web_page: 'http://www.supco-amiens.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCwZD1bwbVZDXgvjbwPWnyAxY9kLp3OsCTo_A68AlwB0A0cE4K9kv13AZd',
    id: 3258,
    acronym: 'GSDCAP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'supco-montpellier.fr',
    name: 'Groupe Sup de Co Montpellier',
    web_page: 'http://www.supco-montpellier.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3259,
    acronym: 'GSDCM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'supelec.fr',
    name: "Ecole Supérieure d'Electricité",
    web_page: 'http://www.supelec.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR070rWJxnjF4ut6wlu2gXM4kCKQ8eEauT6Tu_VsFfNE3ak5_6OWHxXpU',
    id: 3260,
    acronym: 'ESD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-3mrs.fr',
    name: "Université d'Aix-Marseille III",
    web_page: 'http://www.u-3mrs.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQywpmEq_jyrmnIVT1KdarT4cqlGyjUKJv9QPZBZkEUpqfLuWc9TdwG1ms',
    id: 3261,
    acronym: 'UDI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-bordeaux2.fr',
    name: 'Université Victor Segalen (Bordeaux II)',
    web_page: 'http://www.u-bordeaux2.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ28UbZbBZOTSeWm2wprVG0SekWU4eaopXXQ6DVYi2b903mbadN448KmA',
    id: 3262,
    acronym: 'UVS(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ubfc.fr',
    name: 'Université Bourgogne - Franche-Comté',
    web_page: 'http://www.ubfc.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRe43r0Qh_Y3f8Cmu-tOS2I4JWPlVCFKDZOZqAOjp6BPoAkhoxGiCSRjvo',
    id: 3263,
    acronym: 'UB-F',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-cergy.fr',
    name: 'Université de Cergy-Pontoise',
    web_page: 'http://www.u-cergy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbMb-WwGZ9E9PQlBOoGDBBfFbiRdR9QTZeU78QrjTxG3uh_BP3NzVHfA',
    id: 3264,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-clermont1.fr',
    name: "Université d'Auvergne (Clermont-Ferrand I)",
    web_page: 'http://www.u-clermont1.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS08knkRUfI78IyQXjaJA3doOYtBBPWIYTtmRThT8MrEwUTN4E5coqpbA',
    id: 3265,
    acronym: 'UD(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'uco.fr',
    name: "Université Catholique de l' Ouest",
    web_page: 'http://www.uco.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDIEg4gNjUU0_vWUYTee6JhC60Ji8GZ9Y9HqQMP8vIvdmdm_cPvCp2Xmf4',
    id: 3266,
    acronym: 'UCDLO',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-grenoble3.fr',
    name: 'Université Stendhal (Grenoble III)',
    web_page: 'http://www.u-grenoble3.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg4BzpkO8GkrxvITjQuacBoVOhkplWpOQ6GRS4-pgpnM1BEnYAm81QPA',
    id: 3267,
    acronym: 'US(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'uhb.fr',
    name: 'Université Rennes II - Haute-Bretagne',
    web_page: 'http://www.uhb.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV92JcOvULiXa_IgZC2rm0Sqk81FbZQ5yvEH0jwu0dUF_81msGfN53gfA',
    id: 3268,
    acronym: 'URI-H',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'uhp-nancy.fr',
    name: 'Université Henri Poincaré (Nancy I)',
    web_page: 'http://www.uhp-nancy.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkjo0HXpanykI_hlWRE2PRuEBpr3llc4g1irAzXAkjuiLqwg6_MsxIfIk',
    id: 3269,
    acronym: 'UHP(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ujf-grenoble.fr',
    name: 'Université Joseph Fourier (Grenoble I)',
    web_page: 'http://www.ujf-grenoble.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSruR75qQoR82_t3n3odlkh51edVH9oxf5ywxkp9vFz9fS_dyjdhZIv-g',
    id: 3270,
    acronym: 'UJF(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'unicaen.fr',
    name: 'Université de Caen Basse Normandie',
    web_page: 'http://www.unicaen.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPqCa6_ONZeKsI9sx8NuhrZaRMGAQnEdFDQQeuX2PMnqW4S9wUreaWpOw',
    id: 3271,
    acronym: 'UDCBN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'unice.fr',
    name: 'Université de Nice-Sophia Antipolis',
    web_page: 'http://www.unice.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSINM6wJ4CNqwZ24z7B10wwJZgQEY1japVe1UOlrRIrAqlNhOG9KMYUFxY',
    id: 3272,
    acronym: 'UDNA',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'unilim.fr',
    name: 'Université de Limoges',
    web_page: 'http://www.unilim.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP3Nk-Yz-DX4CoPDJIJORjRCkKnRK3gtrtqLoew66cKegHRdf5c46S9wk',
    id: 3273,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-angers.fr',
    name: "Université d'Angers",
    web_page: 'http://www.univ-angers.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuaHGrjwJiRmEluI2zh2wRSbT31STnFx1LM4OIqfYaLJDSiKfj5wEtxi8',
    id: 3274,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-artois.fr',
    name: "Université d'Artois",
    web_page: 'http://www.univ-artois.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRalzOKfl0T2pR099wN8r4wlRU4ax4839tbawnGXSWV3NXqh7d5beT4miAE',
    id: 3275,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-avignon.fr',
    name: "Université d'Avignon",
    web_page: 'http://www.univ-avignon.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8OntrZbznxTPTIVRHa6QN5Zr2Xy83q3IwcDlLMblssqelnER4mI8gl2-R',
    id: 3276,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-bpclermont.fr',
    name: 'Université Blaise Pascal (Clermont-Ferrand II)',
    web_page: 'http://www.univ-bpclermont.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTgAntTKaQ9dlBeIXod5dbD-pqhzgo8JslWkZqdf2pLlqJzEvIEPaCi5U',
    id: 3277,
    acronym: 'UBP(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-brest.fr',
    name: 'Université de Bretagne Occidentale',
    web_page: 'http://www.univ-brest.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfd7nv-zhtT9lro3YKX8sLcVoe5uO3wA_jb0KV-Cd70gE0eUdRjJnKfVdx',
    id: 3278,
    acronym: 'UDBO',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-catholyon.fr',
    name: 'Université Catholique de Lyon',
    web_page: 'http://www.univ-catholyon.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6bHT78GV8kTQYgf1B72P0vIQJRv2nU1i_J28eb0lIkd8VNNRXE_JAcVM',
    id: 3279,
    acronym: 'UCDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-corse.fr',
    name: 'Université de Corse Pascal Paoli',
    web_page: 'http://www.univ-corse.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWLyXmZoBokuHNU9_-ws4Ouva9jOqQXk04KnDdZLTnBq10gxAoUcoHlehegg',
    id: 3280,
    acronym: 'UDCPP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-evry.fr',
    name: "Université d'Evry Val d'Essonne",
    web_page: 'http://www.univ-evry.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRttSwkK7ArqvaAdkaQHvHKg7orK-ip-6vOlGg92tYXANHeeRRGNthwNBgiXw',
    id: 3281,
    acronym: 'UDVD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-inpt.fr',
    name: 'Ecole Nationale Supérieure de Chimie de Toulouse',
    web_page: 'http://www.univ-inpt.fr/~ensct/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6mM4zWuG-8DhRMZQx2wQfscAoOoKDixyFHS3tyPcD37fFyUiOtA0GLQ',
    id: 3282,
    acronym: 'ENSDCDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lehavre.fr',
    name: 'Université du Havre',
    web_page: 'http://www.univ-lehavre.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8LFtCJvBtsz21Tft8eB3wxwH1a-DFlqAbQuGEF1KvabKV7HJXaUmHyNk',
    id: 3283,
    acronym: 'UDH',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lemans.fr',
    name: 'Université du Maine',
    web_page: 'http://www.univ-lemans.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTogVe2h1FIbP3QA-dghs-q5GfjD8Op80TtTfBgSXR9ROpZuIPywj3k-rY',
    id: 3284,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lille1.fr',
    name: 'Université des Sciences et Technologies de Lille (Lille I)',
    web_page: 'http://www.univ-lille1.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH21qf9g5zx3HoJ0ZNrZ2TZQdYAtmDAE0hy6WYHZN55kH84gK6S8SXEQ',
    id: 3285,
    acronym: 'UDSETDL(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lille2.fr',
    name: 'Université du Droit et de la Sante (Lille II)',
    web_page: 'http://www.univ-lille2.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREA1t7N8t3M4PUmvR6-aeX-OkpYAoo91a0zAko4E_8rMFxVAKyQaXUlk0',
    id: 3286,
    acronym: 'UDDEDLS(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lille3.fr',
    name: 'Université Charles-de-Gaulle (Lille III)',
    web_page: 'http://www.univ-lille3.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiO-frDO0pjPcSiPedTz9Jr8BM6KTeNoQ_XMlMCFTsYXhU2hXanp-NU1k',
    id: 3287,
    acronym: 'UC(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-littoral.fr',
    name: "Université du Littoral Cote d'Opale",
    web_page: 'http://www.univ-littoral.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4tRgMkmKz7YK2llkDzcwAkwM-9juK4IlvxOSPb6bDLIuydKpSPTQix0Q',
    id: 3288,
    acronym: 'UDLCD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lorraine.fr',
    name: 'Université de Lorraine',
    web_page: 'http://www.univ-lorraine.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlItKjydyfcmdCIP-R4GYRukz9SD5vdj2G8QCnk0mn9liJvKCw36lLTtw',
    id: 3289,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lr.fr',
    name: 'Université de La Rochelle',
    web_page: 'http://www.univ-lr.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLhcsKwHjFyAP9NRAeya9U74d0SFgvUBnrnH5dRTkqw82AzQplaezqLQ',
    id: 3290,
    acronym: 'UDLR',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lyon1.fr',
    name: 'Université Claude Bernard (Lyon I)',
    web_page: 'http://www.univ-lyon1.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLFS6Bfdt2nrxfDg_Aawjh3vq74kaZn6x5DDIE-W-mPcZF7NE9_b8VdWU',
    id: 3291,
    acronym: 'UCB(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lyon2.fr',
    name: 'Université Lumiére (Lyon II)',
    web_page: 'http://www.univ-lyon2.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm7cgxMxkw2pEHeyOPIOxm_1BqK7RSYMdsBziH6XSpN0leKhfIEdZMhQ',
    id: 3292,
    acronym: 'UL(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-lyon3.fr',
    name: 'Université Jean Moulin (Lyon III)',
    web_page: 'http://www.univ-lyon3.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ114AqUQTR1mOHVAVrR9yTWGvNlJQhCKab3Okw84Dgnjys5KUmMPKpV08',
    id: 3293,
    acronym: 'UJM(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univmed.fr',
    name: 'Université de la Méditerranée (Aix Marseille II)',
    web_page: 'http://www.univmed.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpstEv6hCNx8Uxnj-rsFsCDVnEyNqNSecx_eDFGT0MjREV29FFmOAc2vA',
    id: 3294,
    acronym: 'UDLM(MI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-metz.fr',
    name: 'Université de Metz',
    web_page: 'http://www.univ-metz.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUqI3_681ObbZD-yniwxV-qzzH3gOt6uoJLLPvRFKcs7nOwTJZ0J7QQSk',
    id: 3295,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-mlv.fr',
    name: 'Université de Marne la Vallée',
    web_page: 'http://www.univ-mlv.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ77Vf0zDJLUQzKzwUz5xUVyDZpW4JXRYM1_Czjf0D7RjBq5RsNwsmqNu0',
    id: 3296,
    acronym: 'UDMLV',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-montp1.fr',
    name: 'Université de Montpellier I',
    web_page: 'http://www.univ-montp1.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDX0_4sWIF5VwMHCEAKo6TNFWeUfL4cGwmaP1f7lw6j7n1oZe7pQyS',
    id: 3297,
    acronym: 'UDMI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-montp2.fr',
    name: 'Université de Montpellier II',
    web_page: 'http://www.univ-montp2.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDX0_4sWIF5VwMHCEAKo6TNFWeUfL4cGwmaP1f7lw6j7n1oZe7pQyS',
    id: 3298,
    acronym: 'UDMI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-montp3.fr',
    name: 'Université Paul Valéry (Montpellier III)',
    web_page: 'http://www.univ-montp3.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOc5P5TUwmLpXma4H2gQYZvQgxoUip8WFjDHsUTiHFylc9x7DCgOpQzWE',
    id: 3299,
    acronym: 'UPV(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-mulhouse.fr',
    name: 'Université de Haute-Alsace',
    web_page: 'http://www.univ-mulhouse.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlbPFabGDd4q6jiIsqv7RjKO_11uGL4buBagoGgv3znCM2-HOMFX7eXWSM',
    id: 3300,
    acronym: 'UDH',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-nancy2.fr',
    name: 'Université Nancy II',
    web_page: 'http://www.univ-nancy2.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrBVR2krKU42HNkQEW6KFatJjY5M16NLa07CnwLfYYv8ZyG5x4HYF8sjA',
    id: 3301,
    acronym: 'UNI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-nantes.fr',
    name: 'Université de Nantes',
    web_page: 'http://www.univ-nantes.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQltqSTROYuOmagWsY__ee8aR2Ddzfx7reJAatNH6kWWKIaX4RHXUWeI-I',
    id: 3302,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-orleans.fr',
    name: "Université d'Orléans",
    web_page: 'http://www.univ-orleans.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1EPqwn7_A18lip7ZIgY1HEOXGVzybhjF9be3kxLIGqANygkqmupd6pbfj',
    id: 3303,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-paris12.fr',
    name: 'Université Val-de-Marne (Paris XII)',
    web_page: 'http://www.univ-paris12.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDDnaIqFt8m-c-YkFpHeCjmjXSwyZdVGvYzuMT92BCSFtJoXpfw5Jqedc',
    id: 3304,
    acronym: 'UV(X',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-paris13.fr',
    name: 'Université Paris Nord (Paris XIII)',
    web_page: 'http://www.univ-paris13.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTuSmE52PXXbWWNkFYdMU0V80w3P5jJ-8LPMKCPRiqYbWp0dsbHVu0BaZ_',
    id: 3305,
    acronym: 'UPN(X',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-paris1.fr',
    name: 'Université Panthéon-Sorbonne (Paris I)',
    web_page: 'http://www.univ-paris1.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8xNAPyyKtLacsHEX43ZvD6LwmR_ohYJ8SGa4VKmWFjCI-RgBjdejUgVc',
    id: 3306,
    acronym: 'UP(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-paris3.fr',
    name: 'Université Sorbonne-Nouvelle (Paris III)',
    web_page: 'http://www.univ-paris3.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMwvZVUf06-NCvmoUDH0s3fBawmtzIVzqzK5IxppDrjq9Ycqr0v8YX3JvF',
    id: 3307,
    acronym: 'US(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-paris5.fr',
    name: 'Université René Descartes (Paris V)',
    web_page: 'http://www.univ-paris5.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO2UFBx0XlH9PuoFyHY_PG63w6xPK87438n3kZY-obVoE1GFhm1ugdTVk',
    id: 3308,
    acronym: 'URD(V',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-paris8.fr',
    name: 'Université Vincennes Saint-Denis (Paris VIII)',
    web_page: 'http://www.univ-paris8.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-jtGxfxKoPLy62aXhX_JV6aIg6vnpqU8XKFn5Iay2E1Zue2n31d7bACVtMw',
    id: 3309,
    acronym: 'UVS(V',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-pau.fr',
    name: "Université de Pau et des Pays de l'Adour",
    web_page: 'http://www.univ-pau.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEhu3pf0HVwrqP9Cf7T1RZNd-THh4Un8gPUVhu3WyT17ShPoICG6nG49M',
    id: 3310,
    acronym: 'UDPEDPDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-perp.fr',
    name: 'Université de Perpignan',
    web_page: 'http://www.univ-perp.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0AR2rKO8N4I7DvEu7_ez6xJ62HGeps9dBoBuFPctUTLM-NFtFgfzEK7o',
    id: 3311,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-poitiers.fr',
    name: 'Université de Poitiers',
    web_page: 'http://www.univ-poitiers.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4mRWGow4TqWG_sCPOqX26LR3WTbTQVPp_I42cs3I6CBiHUQ7hAuhuIaPv',
    id: 3312,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-reims.fr',
    name: 'Université de Reims Champagne-Ardenne',
    web_page: 'http://www.univ-reims.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlqWhWGdia6n3d4P2VpHoe862TyVE3unSBmsmKEAhMqV2InqtFimnHADvs',
    id: 3313,
    acronym: 'UDRC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-rennes1.fr',
    name: 'Université Rennes I',
    web_page: 'http://www.univ-rennes1.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4NWso0TV2a2LDtn-ERyyriZ14NkiIeimuKgK5Hw0cIgRXs7zCYHPOrA',
    id: 3314,
    acronym: 'URI',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-rouen.fr',
    name: 'Université de Rouen - Haute Normandie',
    web_page: 'http://www.univ-rouen.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIc2R3_4y2h7s9lMSlE0GLNY33V4SvUZZXLXMqNfmGJLE4Q8crs_r67yU',
    id: 3315,
    acronym: 'UDR-HN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-savoie.fr',
    name: 'Université de Savoie',
    web_page: 'http://www.univ-savoie.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZuT1_AmiGQ2QK-lU41PxgxVUvvbZ3ojBBU6Wogz7T8FJf3I0r1w3ETu8',
    id: 3316,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-st-etienne.fr',
    name: 'Université Jean Monnet',
    web_page: 'http://www.univ-st-etienne.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVxUtb4ZI3sZvuWsrk9k9WdDSrZRRroIAdYQklVrricaTBB6hug8ivuCA',
    id: 3317,
    acronym: 'UJM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-tln.fr',
    name: 'Université du Sud Toulon et Var',
    web_page: 'http://www.univ-tln.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS5NVwJzejZhjJGHGMXF26PplVI9S3aZQJ1I-Lk3uCzczj2DjgYGFg9A',
    id: 3318,
    acronym: 'UDSTEV',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-tlse1.fr',
    name: 'Université des Sciences Sociales (Toulouse I)',
    web_page: 'http://www.univ-tlse1.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE398AEbA8u0kRyxnM-mjh8Sjvq-f4uzJUyMjLIKZ_NaW1P3b01Ylc4xQ',
    id: 3319,
    acronym: 'UDSS(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-tlse2.fr',
    name: 'Université de Toulouse-le-Mirail (Toulouse II)',
    web_page: 'http://www.univ-tlse2.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-XoyFe43g7_SxkqqCYYUQ5HvgQuSffPLoakcgA8UkT_cAUPlYWJbN4Q',
    id: 3320,
    acronym: 'UDT(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-toulouse.fr',
    name: 'Université de Toulouse',
    web_page: 'http://www.univ-toulouse.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGhR7pUpe6ekjujpzWKbrHqFnhIrwtk6fgD3btd0bW2YvpKQcJcsW5lw',
    id: 3321,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-tours.fr',
    name: 'Université François Rabelais de Tours',
    web_page: 'http://www.univ-tours.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD57J6dt360Q7TViJ-pk_SZcjKZIWuh4kT3DoP-LQsqPAeE0x2D0bnf9s',
    id: 3322,
    acronym: 'UFRDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-ubs.fr',
    name: 'Université de Bretagne Sud',
    web_page: 'http://www.univ-ubs.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToCdZ5iCN2lGcRyC2D0KQVRb-feg4kl-u2I-WnHFyHNe1xfR_QTyN0A_0',
    id: 3323,
    acronym: 'UDBS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-valenciennes.fr',
    name: "Ecole Nationale Supérieure d'Ingénieurs en Mécanique et Energétique de Valenciennes",
    web_page: 'http://www.univ-valenciennes.fr/ensimev/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBNb_7NloGXqOn_8Jhzn-wPd12GxDe3LeMfwjd_ph8z5MwtRId_qAdCw',
    id: 3324,
    acronym: 'ENSDEMEEDV',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'univ-valenciennes.fr',
    name: 'Université de Valenciennes et du Hainaut-Cambrésis',
    web_page: 'http://www.univ-valenciennes.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9K6rcmsGudreC6zo5XCY-acZ8A0ODO2OcuOklt081uCS5hGH9M6-XxKU',
    id: 3325,
    acronym: 'UDVEDH',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-paris10.fr',
    name: 'Université Paris Nanterre (Paris X)',
    web_page: 'http://www.u-paris10.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIbz8XehYAdK59GyZHTvI_qguMq2rf5pw9t-GOKggGQ3RVQpOBDOCmMMI',
    id: 3326,
    acronym: 'UPN(X',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-paris2.fr',
    name: 'Université Panthéon-Assas (Paris II)',
    web_page: 'http://www.u-paris2.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9COQOejKRoWkV0FPRzsPLX-G_ISrIHg2qvg3NhpTfiAUUo79PbaIQNEH6',
    id: 3327,
    acronym: 'UP(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-picardie.fr',
    name: 'Université de Picardie Jules-Verne',
    web_page: 'http://www.u-picardie.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ02ZKsMCmRFjUVc6OuOtZCJbvol0nNlvotI1KQaVvXePw8SNL8iKupZQ',
    id: 3328,
    acronym: 'UDPJ',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'upmf-grenoble.fr',
    name: 'Université Pierre Mendes-France (Grenoble II)',
    web_page: 'http://www.upmf-grenoble.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5x4A31iuJ7bDLRmmUScPJojNiH6bUqiz-o0ba8BYhCQF9BBSbtjD29-w',
    id: 3329,
    acronym: 'UPM(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ups-tlse.fr',
    name: 'Université Paul Sabatier (Toulouse III)',
    web_page: 'http://www.ups-tlse.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzAO5MPtofoNZCGPfL15TAR2hqNM_LklUggarKmItsPhekUGRuQyyCyeor',
    id: 3330,
    acronym: 'UPS(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'u-psud.fr',
    name: 'Université Paris Sud (Paris XI)',
    web_page: 'http://www.u-psud.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8wB5R7LkFNbdKVr8vjRJM7lnHyKc8CtQPHWfRhnhE39uvzNf_DQ1UB3A',
    id: 3331,
    acronym: 'UPS(X',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'up.univ-mrs.fr',
    name: 'Université de Provence (Aix-Marseille I)',
    web_page: 'http://www.up.univ-mrs.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQywpmEq_jyrmnIVT1KdarT4cqlGyjUKJv9QPZBZkEUpqfLuWc9TdwG1ms',
    id: 3332,
    acronym: 'UDP(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'ushs.u-strasbg.fr',
    name: 'Université des Sciences Humaines (Strasbourg II)',
    web_page: 'http://ushs.u-strasbg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeNGj9zC-TRNX5vOSXiqcC69Tqr7yy0KDp3owSGGYXE9_7b9-H-c4N5Is',
    id: 3333,
    acronym: 'UDSH(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'utbm.fr',
    name: 'Université de Technologie de Belfort Montbéliard',
    web_page: 'http://www.utbm.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6pv3BYe8Ley-3QQwGzD3cKhv_aPNYJJ1zAOWja7wmPwm3i0AyBYmlqg',
    id: 3334,
    acronym: 'UDTDBM',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'utc.fr',
    name: 'Université de Technologie de Compiègne',
    web_page: 'http://www.utc.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiqBlmvoIxeDIhy-Os9dd81RGkP47kftQrIDQPP57lbqzPCOuOMFteoUo',
    id: 3335,
    acronym: 'UDTDC',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'utt.fr',
    name: 'Université de Technologie de Troyes',
    web_page: 'http://www.utt.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuXeghDeg0FaMzvNMmdHn2F6nJWojlYX2gl_hrG7C62gkgxWQQSx3SCMc',
    id: 3336,
    acronym: 'UDTDT',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'uvsq.fr',
    name: 'Université de Versailles Saint-Quentin-en-Yvelines',
    web_page: 'http://www.uvsq.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdFQXeneKTNtYjq7uTinS-GbFa0YsiZw7cxdAIIYarCFLpQGwiM9i0kw',
    id: 3337,
    acronym: 'UDVS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'vet-alfort.fr',
    name: "Ecole Nationale Vétérinaire d'Alfort",
    web_page: 'http://www.vet-alfort.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuVMVCV1B2rbBmWWlITMDIfue5Z1hBQmD0upznnzN0mq_kuqNd60T5ARQ',
    id: 3338,
    acronym: 'ENVD',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'vet-lyon.fr',
    name: 'Ecole Nationale Vétérinaire de Lyon',
    web_page: 'http://www.vet-lyon.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRITTCcBB8-EH2qHG3eu7QB8MQLZr34KnV5vGrItqD1oQ6bTJ1dQGHE0PU',
    id: 3339,
    acronym: 'ENVDL',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'vet-nantes.fr',
    name: 'Ecole Nationale Vétérinaire de Nantes',
    web_page: 'http://www.vet-nantes.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlXeh3vnY5CG4lCkULFyMxZ5bAaocXhFQoW_dv965ggAJGnnM7Vq6s9M0',
    id: 3340,
    acronym: 'ENVDN',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'www-ecpm.u-strasbg.fr',
    name: 'Ecole Européenne de Chimie Polymères et Matériaux de Strasbourg',
    web_page: 'http://www-ecpm.u-strasbg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf-Wer3vnELtbU4EQ8FKoWUAHqTjCgc3CZZfj1-dpK6_fxpjXhFU8e3w',
    id: 3341,
    acronym: 'EEDCPEMDS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'www-engees.u-strasbg.fr',
    name: "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg",
    web_page: 'http://www-engees.u-strasbg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHZGJ9sgR32lzY-dkJqI87OqSPM_EGWQ8nlpVcV9g3nUDfcH41iKT-tw',
    id: 3342,
    acronym: 'ENDGDLEDLDS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'www-ensais.u-strasbg.fr',
    name: 'Ecole Nationale Supérieure des Arts et Industries de Strasbourg',
    web_page: 'http://www-ensais.u-strasbg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREQQb-1epA_El-Z-Cya2N-ATPPgJ11v_tjvcskl6KsyAhHkoQm62eCkm-qhA',
    id: 3343,
    acronym: 'ENSDAEIDS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'www-ensimag.imag.fr',
    name: "Ecole Nationale Superieure d'Informatique et de Mathematiques Appliquees de Grenoble",
    web_page: 'http://www-ensimag.imag.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhNtDRGF953V1giko8ITA_I2W-ePohNR2nOZbUOIH773RqMAPXStwKHzE',
    id: 3344,
    acronym: 'ENSDEDMADG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'www-enspg.inpg.fr',
    name: 'Ecole Nationale Supérieure de Physique de Grenoble',
    web_page: 'http://www-enspg.inpg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhNtDRGF953V1giko8ITA_I2W-ePohNR2nOZbUOIH773RqMAPXStwKHzE',
    id: 3345,
    acronym: 'ENSDPDG',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'www-ensps.u-strasbg.fr',
    name: 'Ecole Nationale Supérieure de Physique de Strasbourg',
    web_page: 'http://www-ensps.u-strasbg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyXOc0BIX1fNoIxfDEh24wHlGyz3X-UL-gl6zvDXFWj_v5HlrO_3GyuaE',
    id: 3346,
    acronym: 'ENSDPDS',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'www-ulp.u-strasbg.fr',
    name: 'Université Louis Pasteur (Strasbourg I)',
    web_page: 'http://www-ulp.u-strasbg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQASv1FDaDj5URKMo2eEkUfFsIvrpAPqTgBscv9161d0ycQti4IMa4GSCE9',
    id: 3347,
    acronym: 'ULP(I',
  },
  {
    alpha_two_code: 'FR',
    country: 'France',
    domain: 'www-urs.u-strasbg.fr',
    name: 'Université Robert Schuman (Strasbourg III)',
    web_page: 'http://www-urs.u-strasbg.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL4BG6XBg6oSVZX9I8LHWQmhhbXOJKxflBb0ZZ6DwAT6u12ykCyAMecZ0',
    id: 3348,
    acronym: 'URS(I',
  },
  {
    alpha_two_code: 'GF',
    country: 'French Guiana',
    domain: 'univ-ag.fr',
    name: 'Université des Antilles et de la Guyane',
    web_page: 'http://www.univ-ag.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl2K08KqisEclG1oxQ21lzV-pVNGtHObSpRQwXF4dGQdUuVTCTPgFo21mW',
    id: 3349,
    acronym: 'UDAEDLG',
  },
  {
    alpha_two_code: 'PF',
    country: 'French Polynesia',
    domain: 'upf.pf',
    name: 'Université de la Polynésie Française',
    web_page: 'http://www.upf.pf/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjOLujkc39ptRCExl7aowIcsNVzsn1tg2MO4OfmaIldKS8apN7O4C6ksQ',
    id: 3350,
    acronym: 'UDLPF',
  },
  {
    alpha_two_code: 'GA',
    country: 'Gabon',
    domain: 'uob.ga',
    name: 'Université Omar Bongo',
    web_page: 'http://www.uob.ga/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGEP36GXo-JYcqwMH0jG1dzIPMvvx-5h67G3h7CFBfD2JCFfYzEwEZpoQ',
    id: 3351,
    acronym: 'UOB',
  },
  {
    alpha_two_code: 'GM',
    country: 'Gambia',
    domain: 'aiu.edu.gm',
    name: 'American International University West Africa',
    web_page: 'http://www.aiu.edu.gm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHQJR5Ep3XIJzZyuEIe3d2QXF-NJ8D6mv7DrKyRlg4pSi3YfhAE6tJpUyn',
    id: 3352,
    acronym: 'AIUWA',
  },
  {
    alpha_two_code: 'GM',
    country: 'Gambia',
    domain: 'euclid.int',
    name: 'EUCLID University',
    web_page: 'http://www.euclid.int/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgpoCsK7t_naEd9eQ_OAgafIFW7VJBpgwkb6MLs3BiibL1e61lJ75JeqQ',
    id: 3353,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'GM',
    country: 'Gambia',
    domain: 'utg.edu.gm',
    name: 'University of the Gambia',
    web_page: 'http://www.utg.edu.gm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPpa1LyrX0IHWtP5CbzaI0YuY9RhnjNYJ0i6iGWU_GOmLMdaicKS2IPXs',
    id: 3354,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'atsu.edu.ge',
    name: 'Akaki Tsereteli State University',
    web_page: 'http://www.atsu.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcMsP_yjmhp-63N-1PJFNr0sJfCKREOtwwzn1yK8V_usBbUiCEYdQ5EBLg',
    id: 3355,
    acronym: 'ATSU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'cu.edu.ge',
    name: 'Caucasus University',
    web_page: 'http://www.cu.edu.ge/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSymacbpS5Yta4RIZv1pKH4vj_Mtis7TC9HPEygB5rglMm5Go1rEIK_Nok',
    id: 3356,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'esm-tbilisi.ge',
    name: 'European School of Management - Tbilisi',
    web_page: 'http://www.esm-tbilisi.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRzZpe83GdLHbfz67ROLeG5d-sz6YqXE_AZYs6LlZmz84_aWFrGv0CX0c',
    id: 3357,
    acronym: 'ESM-T',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'gruni.edu.ge',
    name: 'University of Grigol Robakidze',
    web_page: 'http://www.gruni.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROxv9B8Kl2TXmVgxPstG9LWtF-TaWA0PDmd5d7BK7mLO1LUMxlYioO5Q',
    id: 3358,
    acronym: 'UGR',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'gsau.edu.ge',
    name: 'Georgian Agricultural University',
    web_page: 'http://www.gsau.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT466j7wN6DoWZ29ADDdZKfOkT8gWEfvOxTWPzQDrdl55ACAILxiJplyGU',
    id: 3359,
    acronym: 'GAU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'gtu.edu.ge',
    name: 'Georgian Technical University',
    web_page: 'http://www.gtu.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqVg8ElBwRVj8jFMGoWxkr6MZ-iZex7vBG6t0N2Y73scXC5bkZQpbcaU8R',
    id: 3360,
    acronym: 'GTU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'ibsu.edu.ge',
    name: 'International Blacksea University',
    web_page: 'http://www.ibsu.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3HzrTf4omMqY2hpI_rc-ZNgbLQB46JxOz_OYZeOjCEiMZXJ2hqBLnH8oL',
    id: 3361,
    acronym: 'IBU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'iliauni.edu.ge',
    name: 'Ilia Chavchavadze State University',
    web_page: 'http://www.iliauni.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxAMxKz-IgNpRHduvfOofKzn4cRiRl7tgajG0Yw8oUMMPdK-La_MHdOdVuDA',
    id: 3362,
    acronym: 'ICSU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'saba.edu.ge',
    name: 'Tbilisi Pedagogical University',
    web_page: 'http://www.saba.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzW4MnVR-IfzEs4-0-cv-ayYOm2fYtdQzJDeYyagy9Dh_E0VPPJakDM5Uo',
    id: 3363,
    acronym: 'TPU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'sangu.ge',
    name: 'St. Andrew Georgian University',
    web_page: 'http://www.sangu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSDnkSrCVeNgzVa-SLFKP3JwUJQdfYb-NeVOXxbjLHF2qQ_MJ7MVaPHw',
    id: 3364,
    acronym: 'SAGU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'tiaa.edu.ge',
    name: 'Tbilisi Institute of Asia and Africa',
    web_page: 'http://www.tiaa.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0tttVZRw_xhzmRzyp4mPDs-FKd4Zn0KZTEMAbpLBmbaaqrpUxzrSk5j8',
    id: 3365,
    acronym: 'TIAA',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'tsmu.edu',
    name: 'Tbilisi State Medical University',
    web_page: 'http://www.tsmu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTqagpainQgj-by1jXR8HBmxMIO52STy9DcvFR3wZm5pjl737F2te8mg',
    id: 3366,
    acronym: 'TSMU',
  },
  {
    alpha_two_code: 'GE',
    country: 'Georgia',
    domain: 'tsu.edu.ge',
    name: 'Tbilisi State University',
    web_page: 'http://www.tsu.edu.ge/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlEyQx0v8x6txlAh2hJLicygqIXOEg8UG9sD8fVlcmJZ-bQdTns5dzTg',
    id: 3367,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'akad.de',
    name: 'AKAD Hochschulen für Berufstätige Fachhochschule Leipzig',
    web_page: 'http://www.akad.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk5SjK6x-p0_Iu-kIFdWcCPWpsTkKVmZtXbUx3UDpQ4E1Z9jbE9hFcArs',
    id: 3368,
    acronym: 'AHFBFL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'akad.de',
    name: 'Hochschule für Berufstätige Rendsburg',
    web_page: 'http://www.akad.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3369,
    acronym: 'HFBR',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'asfh-berlin.de',
    name: 'Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin',
    web_page: 'http://www.asfh-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqNiHVQrLioV91kA5J0gZQD916EC4-qtAJQpEjxymWG8vMeGQNU5_R9eYM',
    id: 3370,
    acronym: 'AFSUSB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'augustana.de',
    name: 'Augustana Hochschule Neuendettelsau',
    web_page: 'http://www.augustana.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTndOCQrXhYu1TDZ1qnt2gUgHP1o18gxWDRwEnKMGgjMIBzAmY__DEqLl4',
    id: 3371,
    acronym: 'AHN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'bethel.de',
    name: 'Kirchliche Hochschule Bethel',
    web_page: 'http://www.bethel.de/kiho/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRgkObsofMQj2ZTh6q4pTPeNX2kXYdvwAFVllho953aVXouYRbdGra4Fbt',
    id: 3372,
    acronym: 'KHB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'bits-iserlohn.de',
    name: 'BiTS - Business and Information Technology School gGmbH',
    web_page: 'http://www.bits-iserlohn.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ55KHIlCf-75a1mFJCev-6WjXeZGAypC93WQw3xVDnGu_bZ5IX5HUv6Is',
    id: 3373,
    acronym: 'B-BITSG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'cbs.de',
    name: 'Cologne Business School',
    web_page: 'http://www.cbs.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRaSzuEixyVJR1tADrOjfXGucMXt97pVTDCqY7XzvNH9p-ejhNIakWHcU',
    id: 3374,
    acronym: 'CBS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'dhbw.de',
    name: 'Duale Hochschule Baden-Württemberg',
    web_page: 'http://www.dhbw.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2ys0C7aVeH_hBUuE4OA8yNqt9fvAe8wFQOYiGkx93bOyfImKrzvOrKw',
    id: 3375,
    acronym: 'DHB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'dhv-speyer.de',
    name: 'Deutsche Hochschule für Verwaltungswissenschaften Speyer',
    web_page: 'http://www.dhv-speyer.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfsARENvr3M2zx0Fre46P1LdY26mbofBRxLGpw3Z8Jd9w_aqyHc2WHzK0',
    id: 3376,
    acronym: 'DHFVS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'diploma.de',
    name: 'DIPLOMA-Fachhochschule Ölsnitz/Vogtland',
    web_page: 'http://www.diploma.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJDIvfy0nSr2iKvoWGX9PUVDW036M0WUb95Ek6997W3DbtO7W3kS0vqeI',
    id: 3377,
    acronym: 'DÖ',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'diploma.de',
    name: 'Fachhochschule Nordhessen',
    web_page: 'http://www.diploma.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7mwKv-mD--NbMoB2UaquHfMwJmhJOXNF-Cs_B3QmSLiZAtX0xAMd6NMw',
    id: 3378,
    acronym: 'FN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'dshs-koeln.de',
    name: 'Deutsche Sporthochschule Köln',
    web_page: 'http://www.dshs-koeln.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStUiCEoh3XzT5VcAUljrA7zw-h9QRt-gCh2soizzjLFIqMfnHTeIHfqbM',
    id: 3379,
    acronym: 'DSK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'eap.net',
    name: 'E.A.P. Europäische Wirtschaftshochschule Berlin',
    web_page: 'http://www.eap.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWQk9GBmydlqKvSkMsWkzct_MjXdtbgthDFFrRMHvXwOrEISaZ75yu9j0',
    id: 3380,
    acronym: 'EEWB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'eba-muenchen.de',
    name: 'Europäische Betriebswirtschafts-Akademie',
    web_page: 'http://www.eba-muenchen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW16NpTAp9vChYcy2csm1tsLRWxBFclMYF_-GPdXHu9rlD-v205LBHHnf5',
    id: 3381,
    acronym: 'EB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ebs.de',
    name: 'European Business School Schloß Reichartshausen',
    web_page: 'http://www.ebs.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYILq4a9c4UoUZxLn5KlNs4uW6lC1bMruf5AOVUUDPgePY_wuspt1W0g',
    id: 3382,
    acronym: 'EBSSR',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ecla.de',
    name: 'European College of Liberal Arts',
    web_page: 'http://www.ecla.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFTNaT220NJmbpSpSDil-0zG1zmex9ywnH8Wv95Kzr9DtsVynW_erKBA',
    id: 3383,
    acronym: 'ECLA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'efh-bochum.de',
    name: 'Evangelische Fachhochschule Rheinland-Westfalen-Lippe',
    web_page: 'http://www.efh-bochum.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKnk_eUP4F6roXxlkggoxihN6FBlyZwJhrh4vLPU3T7B-lWLaKdPjThQ',
    id: 3384,
    acronym: 'EFR',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'efh-darmstadt.de',
    name: 'Evangelische Fachhochschule Darmstadt',
    web_page: 'http://www.efh-darmstadt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpHlXWHBK18qUnZNOJwTPNneKSDFcOFD5gvNdN-srPUNDTcbO0VdWbrwfX',
    id: 3385,
    acronym: 'EFD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'efh-freiburg.de',
    name: 'Evangelische Fachhochschule Freiburg Hochschule für Soziale Arbeit Diakonie und Religionspädagogik',
    web_page: 'http://www.efh-freiburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkNaUdjQ_BuFRFcT7qxHthu-xHq-J9j7aZgtegAFMZOkVBBsesxlXrTg',
    id: 3386,
    acronym: 'EFFHFSADUR',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'efh-hannover.de',
    name: 'Evangelische Fachhochschule Hannover',
    web_page: 'http://www.efh-hannover.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvgkx3Ca9mAif8aDKrFyQDhGO11kAExAe0D0R0Kx6qIjMjPW0aVsVl7-Q',
    id: 3387,
    acronym: 'EFH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'efhlu.de',
    name: 'Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen',
    web_page: 'http://www.efhlu.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGmW4-Eqyif9kMrFhfgIknHExx1mhurpTNb3tqvFT1onYQG3JgIEZpPXpy',
    id: 3388,
    acronym: 'EFLHFSUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'efh-reutlingen-ludwigsburg.de',
    name: 'Evangelische Fachhochschule Reutlingen-Ludwigsburg Hochschule für Soziale Arbeit Religionspädagogik und Diakonie',
    web_page: 'http://www.efh-reutlingen-ludwigsburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkNaUdjQ_BuFRFcT7qxHthu-xHq-J9j7aZgtegAFMZOkVBBsesxlXrTg',
    id: 3389,
    acronym: 'EFRHFSARUD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ehs-dresden.de',
    name: 'Evangelische Hochschule für Soziale Arbeit Dresden (FH)',
    web_page: 'http://www.ehs-dresden.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0kiSue9PzgjK933ppv79yGBZ41hZJ0CuxqE0pqUbYyLR9BHns5Z4vvQ',
    id: 3390,
    acronym: 'EHFSAD(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ems-mainz.de',
    name: 'European Management School',
    web_page: 'http://www.ems-mainz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv6CTSCGiuuObuRmjKt3nd-oGqQ1JBGsMmJHh1KWqpK5T92FCQDy448BM',
    id: 3391,
    acronym: 'EMS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'eufh.de',
    name: 'Europäische Fachhochschule',
    web_page: 'http://www.eufh.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr-DCFxX3pcWowDkHarNJf5i0sFXbk57AYTWw0KzcZXxLpxKbl_KJxf4w',
    id: 3392,
    acronym: 'EF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'euv-frankfurt-o.de',
    name: 'Europa-Universität Viadrina Frankfurt (Oder)',
    web_page: 'http://www.euv-frankfurt-o.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhov3lnYWZHBVM3coHRCLfUZR6SJVBIap0r3IFljMZGJ-8YoaiPz3ZXZU0',
    id: 3393,
    acronym: 'EVF(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'evfh-berlin.de',
    name: 'Evangelische Fachhochschule Berlin Fachhochschule für Sozialarbeit und Sozialpädagogik',
    web_page: 'http://www.evfh-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIdaOXW7WPf-liE_8u0s9G_GjS6nKTDg0CLgT4o3wJE56HQS_3VYNdmg',
    id: 3394,
    acronym: 'EFBFFSUS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'evfh-nuernberg.de',
    name: 'Evangelische Fachhochschule Nürnberg',
    web_page: 'http://www.evfh-nuernberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU0B-pF5FSEcnnG3vMu8AUGJnGDeo8yRCt1kBnOfZy4uzp2z6DXxnHyg',
    id: 3395,
    acronym: 'EFN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fern-fh.de',
    name: 'Fern-Fachhochschule Hamburg',
    web_page: 'http://www.fern-fh.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxh2Lclb48n3-5-SmtFdJMo8DzORCsOrdX6HMamJb3Xc6nsmHMA5pK2WEH',
    id: 3396,
    acronym: 'FH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fernuni-hagen.de',
    name: 'Fernuniversität Gesamthochschule Hagen',
    web_page: 'http://www.fernuni-hagen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCpwvAMrozXy4qSAjShNyF6k7H5j-SukIlDDvZm7GjRIdPJ2gjWMtaYJc',
    id: 3397,
    acronym: 'FGH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-aachen.de',
    name: 'Fachhochschule Aachen',
    web_page: 'http://www.fh-aachen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY9F8goHXViZOGvH30c1-iWvFTXsJmASdTgJzn0toIdEsmuKDz0yJI-aiy',
    id: 3398,
    acronym: 'FA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-aschaffenburg.de',
    name: 'Fachhochschule Aschaffenburg',
    web_page: 'http://www.fh-aschaffenburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNBjx6ZLBydxlGXiymU2gM3IX7qYaId-sd9g-_xh4ucOIcClWlN_pt7zoU',
    id: 3399,
    acronym: 'FA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-augsburg.de',
    name: 'Fachhochschule Augsburg',
    web_page: 'http://www.fh-augsburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdwxsxxZcgDlwaTRrts3YTXiqKQqsnrkKKgRknaI6kof0M_P9XGeddQCI4',
    id: 3400,
    acronym: 'FA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-bad-honnef.de',
    name: 'Internationale Fachhochschule Bad Honnef',
    web_page: 'http://www.fh-bad-honnef.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2ys0C7aVeH_hBUuE4OA8yNqt9fvAe8wFQOYiGkx93bOyfImKrzvOrKw',
    id: 3401,
    acronym: 'IFBH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-biberach.de',
    name: 'Fachhochschule Biberach Hochschule für Bauwesen und Wirtschaft',
    web_page: 'http://www.fh-biberach.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXdO87L6bTxVatlnMQegWqVwymr6BMLlePA1sg2uIgUUfsK-JoftyfjLk',
    id: 3402,
    acronym: 'FBHFBUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-bielefeld.de',
    name: 'Fachhochschule Bielefeld',
    web_page: 'http://www.fh-bielefeld.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLBH6_BPpbHRBG-v-NEeXXbBYEipYrXGBPsEaMQ1eDbtCvNTr7OusjqQ',
    id: 3403,
    acronym: 'FB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-bingen.de',
    name: 'Fachhochschule Bingen',
    web_page: 'http://www.fh-bingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-7VMALVBVibskbnlimgncJs1MxFlx9rrcqWNF4430jW8XToUZDTG9kQ',
    id: 3404,
    acronym: 'FB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-bochum.de',
    name: 'Fachhochschule Bochum',
    web_page: 'http://www.fh-bochum.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCU97JhxHJYTf6zYHYJQyxmHHy6a04HiscFMuJp7BwsG7LMk-Xbz5S9lA',
    id: 3405,
    acronym: 'FB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-bonn-rhein-sieg.de',
    name: 'Fachhochschule Bonn-Rhein-Sieg',
    web_page: 'http://www.fh-bonn-rhein-sieg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZj0oMopbgX0wd6AMcenothasbHj8T5P_1VrhNqfMmBSA4_XL-FIwpGw',
    id: 3406,
    acronym: 'FB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-brandenburg.de',
    name: 'Fachhochschule Brandenburg',
    web_page: 'http://www.fh-brandenburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1_qlUTdDz69xSbABmuedZClt5Up8oWmn0rHa5CTqgL_xU5goYy8uku_A',
    id: 3407,
    acronym: 'FB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'th-deg.de',
    name: 'Technische Hochschule Deggendorf',
    web_page: 'http://www.th-deg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtL-LGdMswjpMTJmpnjTeFHetGMl9A4b1qQJijq0HzeeDFCncwV5D4OYDv',
    id: 3408,
    acronym: 'THD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-dortmund.de',
    name: 'Fachhochschule Dortmund',
    web_page: 'http://www.fh-dortmund.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTRg8UzQJWjtTljcX2ptEq254lMTwJxpUsCIOPpUjGXect5ZWd8ua1PP3-2w',
    id: 3409,
    acronym: 'FD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-duesseldorf.de',
    name: 'Fachhochschule Düsseldorf',
    web_page: 'http://www.fh-duesseldorf.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF8XYJkeOJ9FPhJlcmIaN2b0QTU_eDZ1YYzThmuZEC1VAVRUX9pvd_fz5u',
    id: 3410,
    acronym: 'FD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhdw.bib.de',
    name: 'Fachhochschule für die Wirtschaft',
    web_page: 'http://www.fhdw.bib.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXdO87L6bTxVatlnMQegWqVwymr6BMLlePA1sg2uIgUUfsK-JoftyfjLk',
    id: 3411,
    acronym: 'FFDW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhdw.de',
    name: 'Fachhochschule der Wirtschaft',
    web_page: 'http://www.fhdw.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXdO87L6bTxVatlnMQegWqVwymr6BMLlePA1sg2uIgUUfsK-JoftyfjLk',
    id: 3412,
    acronym: 'FDW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-eberswalde.de',
    name: 'Fachhochschule Eberswalde',
    web_page: 'http://www.fh-eberswalde.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIthuYYqJDHmlJpyvBTcp2tYFprvK_TErzdv5lP68QkMLT1XS3UzLh7RA',
    id: 3413,
    acronym: 'FE',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-erfurt.de',
    name: 'Fachhochschule Erfurt',
    web_page: 'http://www.fh-erfurt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLMiWLQXuNso056Q--yXMJmRjMYscxAay34PkhcWuUJ3hAve7Qs2rVMKs',
    id: 3414,
    acronym: 'FE',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-flensburg.de',
    name: 'Fachhochschule Flensburg',
    web_page: 'http://www.fh-flensburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoKcipVNr6SE2aEpnfwD43kajMVXraJ6l7YN8chpIYMt_RDl0rwuFsYmU',
    id: 3415,
    acronym: 'FF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-frankfurt.de',
    name: 'Fachhochschule Frankfurt am Main',
    web_page: 'http://www.fh-frankfurt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjdBon0wkwur_Edf0lukoRYluXxNylxp5bAYadWPqmgdwSW6j66ivSbgI',
    id: 3416,
    acronym: 'FFAM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-fresenius.de',
    name: 'Europa Fachhochschule Fresenius',
    web_page: 'http://www.fh-fresenius.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT21IvAK1m7_i9MgA-o9p2c24Y2LDmBqr_WVp-4ZJq7DsF4WxflWpCdaCJ6',
    id: 3417,
    acronym: 'EFF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-furtwangen.de',
    name: 'Fachhochschule Furtwangen Hochschule für Technik und Wirtschaft',
    web_page: 'http://www.fh-furtwangen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKYy3rZWf-T3AucRT60Ddis77WesgC_NdqXckYEaM7iqUrsBM6SFLh77Q',
    id: 3418,
    acronym: 'FFHFTUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-gelsenkirchen.de',
    name: 'Fachhochschule Gelsenkirchen',
    web_page: 'http://www.fh-gelsenkirchen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1Ck-F0Km4yFsIk2pqGQM1XOAPQHGYcZj4aU9OjVMOo7hR_ksV1cB_9g',
    id: 3419,
    acronym: 'FG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-giessen.de',
    name: 'Fachhochschule Gießen-Friedberg',
    web_page: 'http://www.fh-giessen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoRMyYBj2IAt4rsBfVavntP2MxQp1uxMgNHGJMrVFM30iiiciQAvKmGVE',
    id: 3420,
    acronym: 'FG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-hamburg.de',
    name: 'Fachhochschule Hamburg',
    web_page: 'http://www.fh-hamburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZv21QYpNN5SH-rdjA7Pg58jSSAzLGuRsPzd55zWKlyZmR89iYrUWGYA',
    id: 3421,
    acronym: 'FH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-hannover.de',
    name: 'Fachhochschule Hannover',
    web_page: 'http://www.fh-hannover.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0uzavOB7Wutoqh_5rrtWzuCqD15yr-BEF-xMoJff_WUYVl2arj8JWnk8_',
    id: 3422,
    acronym: 'FH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-heidelberg.de',
    name: 'Fachhochschule Heidelberg',
    web_page: 'http://www.fh-heidelberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-UZY70l706G7Q5qvg4VIEE4UQwMU5At5yxndjFITTL4R82CRoC7dInJI',
    id: 3423,
    acronym: 'FH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-heilbronn.de',
    name: 'Fachhochschule Heilbronn Hochschule für Technik und Wirtschaft',
    web_page: 'http://www.fh-heilbronn.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtJRvlbTkm9-XCsCWwmzDaAEarzifJM5hZoBBxHIDI2Hx9H3kMULuBSec',
    id: 3424,
    acronym: 'FHHFTUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-hildesheim.de',
    name: 'Fachhochschule Hildesheim/Holzminden/Göttingen Hochschule für angewandte Wissenschaft und Kunst',
    web_page: 'http://www.fh-hildesheim.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgri0i4a48mj1LkTwQ-39S5_lpyFdltcdf7MZ2AYOyOPNDWtDHSdWfZhk',
    id: 3425,
    acronym: 'FHHFAWUK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-hof.de',
    name: 'Fachhochschule Hof',
    web_page: 'http://www.fh-hof.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXD4QncJZvom90VvoBnZeVIP2mRKRW-K3zoxJArilHwgsWZWkbm2VpRuotBw',
    id: 3426,
    acronym: 'FH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-ingolstadt.de',
    name: 'Fachhochschule Ingolstadt',
    web_page: 'http://www.fh-ingolstadt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnB-50eFYC6-fAsCvkQ2f5GAmqJse-c3HLoK8T485IlOOA8bgaMqscR1k',
    id: 3427,
    acronym: 'FI',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-isny.de',
    name: 'Fachhochschule und Berufskollegs NTA Prof.Dr. Grübler gemein. GmbH',
    web_page: 'http://www.fh-isny.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe18m1yuiSx-QSBQ3yYGA5h64hNufJlKYztlv-s0pKdqMC9nrSSV7E794',
    id: 3428,
    acronym: 'FUBNPGGG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-jena.de',
    name: 'Fachhochschule Jena',
    web_page: 'http://www.fh-jena.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRglQzkFCBbTrBohJssedAO2PgyC_QrZBGJNM_2-OcfAuvx2ba-sDpT1IQQ',
    id: 3429,
    acronym: 'FJ',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-karlsruhe.de',
    name: 'Fachhochschule Karlsruhe Hochschule für Technik',
    web_page: 'http://www.fh-karlsruhe.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGSeHqICL1a0aSqQshh57sJKk4-W8dORYemrQJOCjhWMKm9XaQX902_g',
    id: 3430,
    acronym: 'FKHFT',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-Kempten.de',
    name: 'Fachhochschule Kempten Hochschule für Technik und Wirtschaft',
    web_page: 'http://www.fh-Kempten.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3431,
    acronym: 'FKHFTUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-kiel.de',
    name: 'Fachhochschule Kiel',
    web_page: 'http://www.fh-kiel.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLbxRhZUOF3QzHv6iU3Km0F8R2HsGrZx7hNhw12i2K4oGZLIUML1WpGfY',
    id: 3432,
    acronym: 'FK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-kl.de',
    name: 'Fachhochschule Kaiserslautern',
    web_page: 'http://www.fh-kl.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrEavlBSLDEa4iZy-PlFa8TgTzThyprv0RRwzx9JFpeLa0MQu4qmym_ws',
    id: 3433,
    acronym: 'FK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-koblenz.de',
    name: 'Fachhochschule Koblenz',
    web_page: 'http://www.fh-koblenz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlke-qWZVrLHgVUn589fxfHIu8pFM21__oI3ZP6ZHIR9VQtbJxDx-f9dFt',
    id: 3434,
    acronym: 'FK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-koeln.de',
    name: 'Fachhochschule Köln',
    web_page: 'http://www.fh-koeln.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFkshkcFvz7KB9yfRDX1anG_V60EHF9xBBMXGr7BTcYcHb4M7zWR18_Q',
    id: 3435,
    acronym: 'FK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-konstanz.de',
    name: 'Fachhochschule Konstanz Hochschule für Technik Wirtschaft und Gestaltung',
    web_page: 'http://www.fh-konstanz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNxB-dyslOWD1EyX6kdbUCAX24J_ArgebR9iPwrR8Ry8i7PASwngc50A',
    id: 3436,
    acronym: 'FKHFTWUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhkt.de',
    name: 'Staatlich anerkannte Fachhochschule für Kunsttherapie',
    web_page: 'http://www.fhkt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQG2N8IZK-Tplor4LgkzfYpT0HoV77lAwvVR3DWkwxFMzIUxUrlkC_VbJQ',
    id: 3437,
    acronym: 'SAFFK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-landshut.de',
    name: 'Fachhochschule Landshut Hochschule für Wirtschaft - Sozialwesen - Technik',
    web_page: 'http://www.fh-landshut.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3438,
    acronym: 'FLHFW-S-T',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-lausitz.de',
    name: 'Fachhochschule Lausitz',
    web_page: 'http://www.fh-lausitz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVczIeO2m1LvPC59QKHXChj5fRced6WTdFwjkeuaQfLBn_PrCp8fEg6Q',
    id: 3439,
    acronym: 'FL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-lippe.de',
    name: 'Fachhochschule Lippe',
    web_page: 'http://www.fh-lippe.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3mLIp1W_PkSi9lhmFuiHxjSx_26Xo3MbYMmt9r872vYfRMejVyT8LTMOh',
    id: 3440,
    acronym: 'FL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-ludwigshafen.de',
    name: 'Fachhochschule Ludwigshafen Hochschule für Wirtschaft',
    web_page: 'http://www.fh-ludwigshafen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnA7zEJhH33Xn3U7Bwg_F7md0V-zq-jmKlJvTBf11wxaJDngoi5o66',
    id: 3441,
    acronym: 'FLHFW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-luebeck.de',
    name: 'Fachhochschule Lübeck',
    web_page: 'http://www.fh-luebeck.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBHYnaMMUMQe5kBLZ4crkqNe4LhzJYUKGORof0KrsO3ebGaDXDbOl_gbof',
    id: 3442,
    acronym: 'FL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-mainz.de',
    name: 'Fachhochschule Mainz',
    web_page: 'http://www.fh-mainz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwmY19jv1ycmsYvYFKvd4zXLe9ter83lsX1s151vCzRIodv_nLtKEG52U',
    id: 3443,
    acronym: 'FM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-mannheim.de',
    name: 'Fachhochschule Mannheim Hochschule für Technik und Gestaltung',
    web_page: 'http://www.fh-mannheim.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3ePZZK8mksq5aWC7dN9apinOriKKVzzY_SFnvIWWCE0UmFRvQP7MXsJ0',
    id: 3444,
    acronym: 'FMHFTUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-merseburg.de',
    name: 'Fachhochschule Merseburg',
    web_page: 'http://www.fh-merseburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsQRlksneC6dts9QrlLYI5xXA4GIyCATEI6536HsfKqOkw5pc8sjs3f2s',
    id: 3445,
    acronym: 'FM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhm-mittelstand.de',
    name: 'Fachhochschule des Mittelstandes (FHM)',
    web_page: 'http://www.fhm-mittelstand.de/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcS1jxOUNvp5slo69IrxwzEIYiPaSoVoIlbgMe8W6HNQQXYdgHP7Nnh3QaJL',
    id: 3446,
    acronym: 'FDM(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-muenchen.de',
    name: 'Fachhochschule München',
    web_page: 'http://www.fh-muenchen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ4sB3onuW3TCcNKotr6nxoBDtoSlO9m7EgvtZww4v-pqN0oAj59a6MLc',
    id: 3447,
    acronym: 'FM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hm.edu',
    name: 'Hochschule München',
    web_page: 'http://www.hm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeANyywtyoK8f216aQuTTiDtHAfe0pAHKozPpSdO5hn0ji4gmzyJ2myxf7',
    id: 3448,
    acronym: 'HM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-muenster.de',
    name: 'Fachhochschule Münster',
    web_page: 'http://www.fh-muenster.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHMVCX779sIqYRGQPG-7YrwYVy8o81DZc37TPzNqpAF_SpNj8q8l0y8R4',
    id: 3449,
    acronym: 'FM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-nb.de',
    name: 'Fachhochschule Neubrandenburg',
    web_page: 'http://www.fh-nb.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEqaVVeUK_DTLig7MIqDGeRLRi7VKLI9JLDFIqSHFjbYL77jULMaFEvbRcaw',
    id: 3450,
    acronym: 'FN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-neu-ulm.de',
    name: 'Fachhochschule Neu-Ulm',
    web_page: 'http://www.fh-neu-ulm.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfWjFbi0LZGXJlPeWNr3n8Yfrt9ei4SsSQO7R0iBZ_s8aULB1_QiI8lA',
    id: 3451,
    acronym: 'FN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-niederrhein.de',
    name: 'Fachhochschule Niederrhein',
    web_page: 'http://www.fh-niederrhein.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZxakHiMGmH5fFKPbdbG9MFhk_-XTG_mNmEw9MPH6jvw8cvBfXHACbYkYq',
    id: 3452,
    acronym: 'FN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhnon.de',
    name: 'Fachhochschule Nordostniedersachsen',
    web_page: 'http://www.fhnon.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVczIeO2m1LvPC59QKHXChj5fRced6WTdFwjkeuaQfLBn_PrCp8fEg6Q',
    id: 3453,
    acronym: 'FN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-nordhausen.de',
    name: 'Fachhochschule Nordhausen',
    web_page: 'http://www.fh-nordhausen.de/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSIXXRDO7YwAf8u5RUAFLfjpUsvoX35Ym5p__lsgs-ow0ppVI6PGM_PDzDk',
    id: 3454,
    acronym: 'FN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-nuernberg.de',
    name: 'Georg-Simon-Ohm-Fachhochschule Nürnberg',
    web_page: 'http://www.fh-nuernberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy3kQpneEw2sl7hyFbRX81NKxGej_LhDTkKrzmxJDHnKiQkDFtZddPHw',
    id: 3455,
    acronym: 'GN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-nuertingen.de',
    name: 'Fachhochschule Nürtingen Hochschule für Wirtschaft Landwirtschaft und Landespflege',
    web_page: 'http://www.fh-nuertingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7KAFgMNBrhOgjzcSq-71IekRJsbF0aeaiQBc-XQTxsv-wdgo4ZU56_2zC',
    id: 3456,
    acronym: 'FNHFWLUL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhoebb.de',
    name: 'Fachhochschule für das öffentliche Bibliothekswesen Bonn',
    web_page: 'http://www.fhoebb.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1peOfc6X1lQljJrb73J5kT8kyerSN68MoqQ_Gep0Y2ERlZYgvLmyKUfK6aQ',
    id: 3457,
    acronym: 'FFDÖBB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-offenburg.de',
    name: 'Fachhochschule Offenburg Hochschule für Technik und Wirtschaft',
    web_page: 'http://www.fh-offenburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtnOI0vy8QUpNarajJoTaoR9gogpKvm1HoRaxZq5D4A9caU8AlA4wgsQ',
    id: 3458,
    acronym: 'FOHFTUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-oow.de',
    name: 'Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven',
    web_page: 'http://www.fh-oow.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpnnqIqQy4wodB9UZEY3Ngfns_eJ5X_d7jPcfPhu9FNr5ZBjCbUhT23no',
    id: 3459,
    acronym: 'FO',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-osnabrueck.de',
    name: 'Fachhochschule Osnabrück',
    web_page: 'http://www.fh-osnabrueck.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCbZjl_0UUanTaerxZWc9Ucjhr-7kBwGmZjYrI-xP-JfvR5Q1qHG7uGg',
    id: 3460,
    acronym: 'FO',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-ottersberg.de',
    name: 'Freie Kunst-Studienstätte Ottersberg',
    web_page: 'http://www.fh-ottersberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9YeOegfzkOAbUyL87xkJIzXKzegiI8EWLkfKFZIIl6WFC0d-sLW0PzCw',
    id: 3461,
    acronym: 'FKO',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-pforzheim.de',
    name: 'Fachhochschule Pforzheim Hochschule für Gestaltung Technik und Wirtschaft',
    web_page: 'http://www.fh-pforzheim.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqNRB1C_bu6c0AgNBhGmUHXiR2qXhe5Zcl0Qr-WaG6fb3txU5WaZe5PAY',
    id: 3462,
    acronym: 'FPHFGTUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-potsdam.de',
    name: 'Fachhochschule Potsdam',
    web_page: 'http://www.fh-potsdam.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTIwR0jES6SqLm74_H7NLpyWyCqylGfxTLTl1rrPna0B64mtg7t38Gkbsi',
    id: 3463,
    acronym: 'FP',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-regensburg.de',
    name: 'Fachhochschule Regensburg',
    web_page: 'http://www.fh-regensburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVckHx5q0CFVRFae-erCwZ52WX4AfLkk3ePVeFnl-3RR0FXPgcJZ8PKPk',
    id: 3464,
    acronym: 'FR',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-reutlingen.de',
    name: 'Fachhochschule Reutlingen Hochschule für Technik und Wirtschaft',
    web_page: 'http://www.fh-reutlingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpAilb0jUI14il45rAZ8HM1WPXvQM-AnvAbQrvf_wHkdTTtnpIAI4xIIeW',
    id: 3465,
    acronym: 'FRHFTUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-riedlingen.de',
    name: 'Deutsch-Ordens Fachhochschule Riedlingen Hochschule für Wirtschaft',
    web_page: 'http://www.fh-riedlingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRSPKiSyFb7HzKao_wGwk7rSfilSAPBx6ep-hI52RhqZEsHQw_An3dGw',
    id: 3466,
    acronym: 'DFRHFW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-rosenheim.de',
    name: 'Fachhochschule Rosenheim Hochschule für Technik und Wirtschaft',
    web_page: 'http://www.fh-rosenheim.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH47Sf8oW4bhzSYoc5q_O0bJPMgQJ1aotZgIOQ9WBboVsdJaTZCab-DwRY',
    id: 3467,
    acronym: 'FRHFTUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-rottenburg.de',
    name: 'Fachhochschule Rottenburg Hochschule für Forstwirtschaft',
    web_page: 'http://www.fh-rottenburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQUVc9QXDcL9O8xabu2JdItLSNdS2KcWsr9IfqO3bpfndKuiS37td6i_U',
    id: 3468,
    acronym: 'FRHFF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-schmalkalden.de',
    name: 'Fachhochschule Schmalkalden',
    web_page: 'http://www.fh-schmalkalden.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTRk0ihV6tXkjRz50PLnjfpQ1F5orS1uQqhwzDa7NX-Qm45aGN_7pjcQ',
    id: 3469,
    acronym: 'FS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-schwaebischhall.de',
    name: 'Fachhochschule Schwäbisch Hall Hochschule für Gestaltung',
    web_page: 'http://www.fh-schwaebischhall.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbfZngRlmZ_kqHuP0sVmO3nlyh6y3Tj6bByQLisQrRHoLwjIOSiyabGBA',
    id: 3470,
    acronym: 'FSHHFG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhs-mannheim.de',
    name: 'Fachhochschule Mannheim Hochschule für Sozialwesen',
    web_page: 'http://www.fhs-mannheim.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-XlmodRrB1ccUACw26IDeQUT9ZuuAgal0q31v4H1C5IFNm8nWV7cVLpA',
    id: 3471,
    acronym: 'FMHFS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhs-moritzburg.de',
    name: 'Evangelische Fachhochschule für Religionspädagogik und Gemeindediakonie Moritzburg',
    web_page: 'http://www.fhs-moritzburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0lBxgh2Q6d9Vm4WbhYe9Ci5kpgc-1PsnJLkz_5a6eUezxrcgF9rlAp9A',
    id: 3472,
    acronym: 'EFFRUGM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-stralsund.de',
    name: 'Fachhochschule Stralsund',
    web_page: 'http://www.fh-stralsund.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwypb-bsVzpqyOtWqwGuQCNMD5KTfCSpnaLtWMqhlFFeTegImiM1s474A',
    id: 3473,
    acronym: 'FS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-telekom-leipzig.de',
    name: 'Deutsche Telekom Fachhochschule Leipzig',
    web_page: 'http://www.fh-telekom-leipzig.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJZbHTskEzQkioqREnXhYL41gmc015LUSmbEWR6UkVq0J0bMJJdNissQ',
    id: 3474,
    acronym: 'DTFL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-trier.de',
    name: 'Fachhochschule Trier Hochschule für Technik Wirtschaft und Gestaltung',
    web_page: 'http://www.fh-trier.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR7pW5coOeiZUb-jN06PfO4Gma4KSoNasyy_ZL7JhIvSjwsdx7FSk_ams',
    id: 3475,
    acronym: 'FTHFTWUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fht-stuttgart.de',
    name: 'Fachhochschule Stuttgart Hochschule für Technik',
    web_page: 'http://www.fht-stuttgart.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrM069iXDW2ngxDUYfigYh-yGwmOCvywBxa5orlVnhXrPVkIMoi3XZyoH8',
    id: 3476,
    acronym: 'FSHFT',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhtw-berlin.de',
    name: 'Fachhochschule für Technik und Wirtschaft Berlin',
    web_page: 'http://www.fhtw-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgQ4MCnid3uZLnb6gaVnijNHQvp0hHsoegHxh_gq6zLDk9kOOd0utKo7g',
    id: 3477,
    acronym: 'FFTUWB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-ulm.de',
    name: 'Fachhochschule Ulm Hochschule für Technik',
    web_page: 'http://www.fh-ulm.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt-Oi1r7OnELWZkCWCviqa3Vf1xr2OIa7Qecg-8eMf8KtLXCNLUgF5tA',
    id: 3478,
    acronym: 'FUHFT',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhvr.berlin.de',
    name: 'Fachhochschule für Verwaltung und Rechtspflege Berlin',
    web_page: 'http://www.fhvr.berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3ji0x8BsiRLqTxwCcS5BNmecS3DQ_Tq8qaqEdz6RChfQj3iss-jzMyxQ',
    id: 3479,
    acronym: 'FFVURB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhw-berlin.de',
    name: 'Fachhochschule für Wirtschaft Berlin',
    web_page: 'http://www.fhw-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgQ4MCnid3uZLnb6gaVnijNHQvp0hHsoegHxh_gq6zLDk9kOOd0utKo7g',
    id: 3480,
    acronym: 'FFWB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-wedel.de',
    name: 'Fachhochschule Wedel',
    web_page: 'http://www.fh-wedel.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3Fa-mZyC2yNojC_3jrXR97arBwnq7t10n0nhM8K2LhyaKpC4ie7Vsrw',
    id: 3481,
    acronym: 'FW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-weihenstephan.de',
    name: 'Fachhochschule Weihenstephan',
    web_page: 'http://www.fh-weihenstephan.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2nrYOmf9Ii-9Zh5lVjQOFvMHXRJj0iDhHJVJso8QaM97DMUyqTwzcOQ',
    id: 3482,
    acronym: 'FW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-weingarten.de',
    name: 'Fachhochschule Ravensburg-Weingarten',
    web_page: 'http://www.fh-weingarten.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIH3GsUfTnfYsvCvgizWFcC56sDGRkRY6wCXp_nvNN_qoVoqhvOCdPRg0',
    id: 3483,
    acronym: 'FR',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-westkueste.de',
    name: 'Fachhochschule Westküste Hochschule für Wirtschaft und Technik',
    web_page: 'http://www.fh-westkueste.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCw3FkooyY6TM0mXHO27_9YI_lIpd-npn3ncdX6_n1tz8MiKhjoJepxw',
    id: 3484,
    acronym: 'FWHFWUT',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-wiesbaden.de',
    name: 'Fachhochschule Wiesbaden',
    web_page: 'http://www.fh-wiesbaden.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVczIeO2m1LvPC59QKHXChj5fRced6WTdFwjkeuaQfLBn_PrCp8fEg6Q',
    id: 3485,
    acronym: 'FW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-wolfenbuettel.de',
    name: 'Fachhochschule Braunschweig/Wolfenbüttel',
    web_page: 'http://www.fh-wolfenbuettel.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRhQ3eSo-YMnRb-9i4LXf_YnchpYf6Lrmru0auh-MEG9S8z_jILprN-Wc',
    id: 3486,
    acronym: 'FB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-worms.de',
    name: 'Fachhochschule Worms',
    web_page: 'http://www.fh-worms.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVczIeO2m1LvPC59QKHXChj5fRced6WTdFwjkeuaQfLBn_PrCp8fEg6Q',
    id: 3487,
    acronym: 'FW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fhwt.de',
    name: 'Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz',
    web_page: 'http://www.fhwt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8s29vcvCuZQD0fduQR4L-Mm67dKcUfUldhXqCe1CETOmj-g_8iyhLG750',
    id: 3488,
    acronym: 'PFFWUTV',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-wuerzburg.de',
    name: 'Fachhochschule Würzburg - Schweinfurt',
    web_page: 'http://www.fh-wuerzburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpDPxYEtC_HEFH9SCVnPND3-U78aoeZMfrYwhjZMFS3dVIiSotuh8XpQ',
    id: 3489,
    acronym: 'FW-S',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fh-zwickau.de',
    name: 'Westsächsische Hochschule Zwickau (FH)',
    web_page: 'http://www.fh-zwickau.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_gjcKkZqiygIQpncoQwXViiqMS1lXACtLtzCxrPXzOZaj81ME2G-4WZY',
    id: 3490,
    acronym: 'WHZ(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fom.de',
    name: 'Fachhochschule für Oekonomie und Management (FOM)',
    web_page: 'http://www.fom.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt7oBOjEYmvtcugEXbO7nLJR_XcS0pG0AnQ5ZkYda8dqQU61hUGopaJ3l9',
    id: 3491,
    acronym: 'FFOUM(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'fu-berlin.de',
    name: 'Freie Universität Berlin',
    web_page: 'http://www.fu-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA7SPj7TfeZn7G5s8QoGouZQxkrEDUKKjL9AW_02cSm8_iR0b4s6C4Xnw',
    id: 3492,
    acronym: 'FUB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hanseuni.de',
    name: 'Private Hanseuniversität',
    web_page: 'http://www.hanseuni.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGp9t6iFffgoBW_dXi8ITjalDPJu9R0VRFNYEQobA0S4AJVoHqUvhoaw',
    id: 3493,
    acronym: 'PH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hcu-hamburg.de',
    name: 'Hafencity Universität Hamburg',
    web_page: 'http://www.hcu-hamburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzf1aICFYXd0C8lKgn35zTDA-pEfG5fnelufaIb2cV2sW5fbhp2Q_DJ-4f',
    id: 3494,
    acronym: 'HUH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hdm-stuttgart.de',
    name: 'Fachhochschule Stuttgart Hochschule der Medien',
    web_page: 'http://www.hdm-stuttgart.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHuJic5j6aG9mWFVbZP5Lxf7kIJXb0OTbuuWMmQ-P8b8AuxTC1ZbCAGkc',
    id: 3495,
    acronym: 'FSHDM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hertie-school.org',
    name: 'Hertie School of Governance',
    web_page: 'http://www.hertie-school.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRA5ysi4F6XwJ8OtD97DxDHi3oVgaNRitO0FJ-WbyKkjeQg3ZiY7qw6Q',
    id: 3496,
    acronym: 'HSG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hfb.de',
    name: 'Hochschule für Bankwirtschaft (HfB) Private Fachhochschule der Bankakademie',
    web_page: 'http://www.hfb.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0XURZ8dCR9JvpctRFxpdBiwOFCI3hTYqpNagCysSuKd-yJPZNIcNv9Kih',
    id: 3497,
    acronym: 'HFB(PFDB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hfg-gmuend.de',
    name: 'Fachhochschule Schwäbisch Gmünd Hochschule für Gestaltung',
    web_page: 'http://www.hfg-gmuend.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD7woaJkgOwUgWHX8BFuw7cH6LbkM2H4D4v5SspFO4dIXkE1FQMlnk3p0',
    id: 3498,
    acronym: 'FSGHFG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hfph.mwn.de',
    name: 'Hochschule für Philosophie München',
    web_page: 'http://www.hfph.mwn.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTN7N58pcQC7lraBRg8_NskWgCUsesWrOHSNqyrGnlPXvCRS0aZ2ykDDU',
    id: 3499,
    acronym: 'HFPM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hfp.mhn.de',
    name: 'Hochschule für Politik (HFP)',
    web_page: 'http://www.hfp.mhn.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTSwxgDAEsiRQw6YOSlyTj4vUJwBNYvEysEqZVnGIsJixdL_2W7W6dcao',
    id: 3500,
    acronym: 'HFP(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hhl.de',
    name: 'Handelshochschule Leipzig',
    web_page: 'http://www.hhl.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPfcWcHlHf_21SKqmVDBOKsdMGvPakTIeBYecM3izT76GpWAVwmSRZl2c',
    id: 3501,
    acronym: 'HL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'himh.de',
    name: 'Hochschule für Internationales Management',
    web_page: 'http://www.himh.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXLdsIUxeQWqhVYLKnODgqcfZJyDCzGaeRTs438mPOzOBTFSGSZSv09gk',
    id: 3502,
    acronym: 'HFIM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hjs.uni-heidelberg.de',
    name: 'Hochschule für Jüdische Studien Heidelberg',
    web_page: 'http://www.hjs.uni-heidelberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfWt-VH3I5xro5pAuAB_m48MUreg_ShTejKyh08vNQzVbXwgZcsEWNlCttrA',
    id: 3503,
    acronym: 'HFJSH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-albsig.de',
    name: 'Hochschule Albstadt-Sigmaringen',
    web_page: 'http://www.hs-albsig.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBwY49tni_NeDafFNVxyb7BWUvX7cjMC2f53oYuS3iNm0iZpHoYdyZ9Ig',
    id: 3504,
    acronym: 'HA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-anhalt.de',
    name: 'Hochschule Anhalt (FH) Hochschule für angewandte Wissenschaften',
    web_page: 'http://www.hs-anhalt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZv21QYpNN5SH-rdjA7Pg58jSSAzLGuRsPzd55zWKlyZmR89iYrUWGYA',
    id: 3505,
    acronym: 'HA(HFAW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-bremen.de',
    name: 'Hochschule Bremen',
    web_page: 'http://www.hs-bremen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGZ2MtqOofI1xjIpHJ6exjH5cKwHTOXG2b3n2x_DlbN8H5SP0FViXUMQ',
    id: 3506,
    acronym: 'HB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-bremerhaven.de',
    name: 'Hochschule Bremerhaven',
    web_page: 'http://www.hs-bremerhaven.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyCVc4Le5n9V2vf0JhLe73h5hkjFLeBEi_TCkJQR559B_cQAlD83Gcpw',
    id: 3507,
    acronym: 'HB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-coburg.de',
    name: 'Hochschule Coburg',
    web_page: 'http://www.hs-coburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRazpwvuco35OlQizkxw4SB9h8_fp4UdEpi6eOOY0qsA9kWHmrit35RKg',
    id: 3508,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-darmstadt.de',
    name: 'Hochschule Darmstadt',
    web_page: 'http://www.hs-darmstadt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh9bFg12cO1ARBQJ0pXmeh-sLszzTCnxfl8jDVjlVvQGm0mQncetdN3z8',
    id: 3509,
    acronym: 'HD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-esslingen.de',
    name: 'Hochschule Esslingen',
    web_page: 'http://www.hs-esslingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWwY8nie_ywkT9mgol5gG4viois0wQeD83zZGUrrcpZs5g6QnTvtlDHEc',
    id: 3510,
    acronym: 'HE',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-fulda.de',
    name: 'Hochschule Fulda',
    web_page: 'http://www.hs-fulda.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE0wDIw9jFx3B7kPcu0xV6q768MuLAFQ37Ml_TRT6PANj3cpc_ZhgyYGY',
    id: 3511,
    acronym: 'HF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-harz.de',
    name: 'Hochschule Harz Hochschule für angewandte Wissenschaften (FH)',
    web_page: 'http://www.hs-harz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR57L6ZwfBRj_F6MoURkG16bm31qm-JP8PrFVM0Ng2fMCvYycwfQRctTg',
    id: 3512,
    acronym: 'HHHFAW(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-magdeburg.de',
    name: 'Hochschule Magdeburg-Stendal (FH)',
    web_page: 'http://www.hs-magdeburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQPDfXiKsRhEno8fgYgq9VNB7HybmfU7B_pcZ7CEV62u2RfXD3wNIcU0I',
    id: 3513,
    acronym: 'HM(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-wismar.de',
    name: 'Hochschule Wismar Fachhochschule für Technik Wirtschaft und Gestaltung',
    web_page: 'http://www.hs-wismar.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_AsCyKbBvy5oqPwOFGEOkkbNz4n7GoTGvC9oBaRnaiR0YCwNmePIS4A',
    id: 3514,
    acronym: 'HWFFTWUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hs-zigr.de',
    name: 'Hochschule Zittau/Görlitz (FH)',
    web_page: 'http://www.hs-zigr.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZv21QYpNN5SH-rdjA7Pg58jSSAzLGuRsPzd55zWKlyZmR89iYrUWGYA',
    id: 3515,
    acronym: 'HZ(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'htw-dresden.de',
    name: 'Hochschule für Technik und Wirtschaft Dresden (FH)',
    web_page: 'http://www.htw-dresden.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGgNYCz6r4Bg--1tXqjex_NZV2wJPKt4OCLabVw7xjqTx8hk2AO8m2Gg',
    id: 3516,
    acronym: 'HFTUWD(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'htwk-leipzig.de',
    name: 'Hochschule für Technik Wirtschaft und Kultur Leipzig (FH)',
    web_page: 'http://www.htwk-leipzig.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFblqkHbRHOcoBw-k-csPFU4ULb40x9lAyn0Ba6URnUFpkMwVtVQ3JeA',
    id: 3517,
    acronym: 'HFTWUKL(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'htwm.de',
    name: 'Hochschule Mittweida (FH)',
    web_page: 'http://www.htwm.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5_EjueA72SE0ZegKZn-YdPp0IIjKRS0N752YOQcCD1fuSMXpRtIbSBjpy',
    id: 3518,
    acronym: 'HM(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'htw-saarland.de',
    name: 'Hochschule für Technik und Wirtschaft des Saarlandes',
    web_page: 'http://www.htw-saarland.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQshau5L_GKgf6w9A86uiWe9BoUjSaZlNiJ0yppeI30wpJoC8s9KZsAqA',
    id: 3519,
    acronym: 'HFTUWDS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hu-berlin.de',
    name: 'Humboldt Universität Berlin',
    web_page: 'http://www.hu-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCWD6ucRhb_GoBNwMzdMcSyQIIMV7_ug49iNkmImnAWuz-sj6idNJY1UU_',
    id: 3520,
    acronym: 'HUB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'hwp-hamburg.de',
    name: 'HWP - Hamburger Universität für Wirtschaft und Politik',
    web_page: 'http://www.hwp-hamburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCAcA4VjZ8xOjX9RW0PVnKgDOAuXqyHFAKG9tlUyYzVUIyltOJF39LWg',
    id: 3521,
    acronym: 'H-HUFWUP',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ihi-zittau.de',
    name: 'Internationales Hochschulinstitut Zittau',
    web_page: 'http://www.ihi-zittau.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvf9ec_OmMRNP23mMskMP_nxLjiooGsZH4tes6tkmOSWZDf6xC4XIPt5A',
    id: 3522,
    acronym: 'IHZ',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'international.fh-aalen.de',
    name: 'Internationale Fachhochschule Aalen',
    web_page: 'http://www.international.fh-aalen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEP9IjwCDWmuTlNFlCQZwW6khrqRXr4Ph52oqjEWzHYEUV5aUjTcXIicA',
    id: 3523,
    acronym: 'IFA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ism-dortmund.de',
    name: 'International School of Management ISM Dortmund',
    web_page: 'http://www.ism-dortmund.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRM_-M0HVaEbh3osr50LY-tmC7aVojROXRfzyKqTrq6gS8MZixicH6AqDKG',
    id: 3524,
    acronym: 'ISMID',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'isnm.de',
    name: 'International School of New Media University of Lübeck',
    web_page: 'http://www.isnm.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0xitDPDxunc8u_oTOI17lepGTH3aDBOuMJ3kK3R2JYos-2D2Eu-xpCat4',
    id: 3525,
    acronym: 'ISNMUL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'i-u.de',
    name: 'International University in Germany',
    web_page: 'http://www.i-u.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTlQLeaNrfBpNBDwIcLmaaoKjmZUVPTP7fa1pnF7Hp33ZM1AMDR86xKA',
    id: 3526,
    acronym: 'IUIG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'jacobs-university.de',
    name: 'Jacobs University Bremen',
    web_page: 'http://www.jacobs-university.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkA3R1foTF9HVfTKGoNXamtX0ELa45WkJa2asAdiAwiqz9KhsCb-ptI6A',
    id: 3527,
    acronym: 'JUB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'karlshochschule.de',
    name: 'Karlshochschule International University',
    web_page: 'http://www.karlshochschule.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwGjjzrMJqONawKJbfl8P_2ztDUILAsXDb7TbgFYmd1JepBaCBe0L2_Q',
    id: 3528,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'kath-fh-nord.de',
    name: 'Katholische Fachhochschule Norddeutschland',
    web_page: 'http://www.kath-fh-nord.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt0CKb3VuXIJP0AK-VJemrX56GseSyOUNRSRcsBLvyO83KwSm4mgyUEWEI-Q',
    id: 3529,
    acronym: 'KFN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'kfb-berlin.de',
    name: 'Katholische Fachhochschule Berlin (KFB)',
    web_page: 'http://www.kfb-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSHSCwv46Yh6Gy3catnK4S1hZ2zjdghOgv2A4b_gmEYlkXb1cn5z6RDDLx',
    id: 3530,
    acronym: 'KFB(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'kfh-Freiburg.de',
    name: 'Katholische Fachhochschule Freiburg Hochschule für Sozialwesen Religionspädagogik und Pflege',
    web_page: 'http://www.kfh-Freiburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKr9IzQEHNv-ViBdRC8ABylaTzZn-1WSFp7mfqWgU6nDFUkBi8jrXDiW8',
    id: 3531,
    acronym: 'KFFHFSRUP',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'kfh-mainz.de',
    name: 'Katholische Fachhochschule Mainz',
    web_page: 'http://www.kfh-mainz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzLUGg3kOojYKqGkj5tXfAMBEh_gbx9GCladKRlxrQNBwwCBrcHv-_g',
    id: 3532,
    acronym: 'KFM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'kfhnw.de',
    name: 'Katholische Fachhochschule Nordrhein-Westfalen',
    web_page: 'http://www.kfhnw.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVMqfdWqIn4fJ9tmKW-65MFvAlaKpYaKTGrBAv57AROY6cb_-CWB8MH7MP',
    id: 3533,
    acronym: 'KFN',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'kh-berlin.de',
    name: 'Kunsthochschule Berlin-Weissensee Hochschule für Gestaltung',
    web_page: 'http://www.kh-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVDpqUVRCbBKObKccsy5-yME7VoUAjoPHVo82EZT0_ctA22EkYxttue2M',
    id: 3534,
    acronym: 'KBHFG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'khsa.de',
    name: 'Katholische Hochschule für Soziale Arbeit Saarbrücken',
    web_page: 'http://www.khsa.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUTTXaFD1Oe3MwhU1_akvw8TdalhjREHOsx0vqIl9A7oMKpyzzyEqKfuk3',
    id: 3535,
    acronym: 'KHFSAS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ksfh.de',
    name: 'Katholische Stiftungsfachhochschule München',
    web_page: 'http://www.ksfh.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPNfVKZTpsfM1N6cmEEQAW_jiX7vwt828C7qvpBOmWlBfq_fW_6iaG_-NM',
    id: 3536,
    acronym: 'KSM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ku-eichstaett.de',
    name: 'Katholische Universität Eichstätt',
    web_page: 'http://www.ku-eichstaett.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBW93O1YQdtBNmAk9ZM0CrAzRGU_edh012LDZgHMBU_dtWGogFWvNIxg',
    id: 3537,
    acronym: 'KUE',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'kunstakademie-duesseldorf.de',
    name: 'Kunstakademie Düsseldorf.',
    web_page: 'http://www.kunstakademie-duesseldorf.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVdRUHuPeXgDJwNExH3YJK20oUxLqR6Oeab84uAPTeX4Haby0OqBl8VNg',
    id: 3538,
    acronym: 'KD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'merkur-fh.org',
    name: 'Merkur Internationale FH Karlsruhe',
    web_page: 'http://www.merkur-fh.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3539,
    acronym: 'MIFK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'merz-akademie.de',
    name: 'Merz Akademie Hochschule für Gestaltung Stuttgart',
    web_page: 'http://www.merz-akademie.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT91DRhqoNrlgNjwNgvGa_DBeObGeLuhAb1KVgFMpi8s8chSqa49xYZVw',
    id: 3540,
    acronym: 'MAHFGS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'mfh-iserlohn.de',
    name: 'Märkische Fachhochschule Iserlohn',
    web_page: 'http://www.mfh-iserlohn.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSq7hOd3j5xJAs8JqhglRHaz9BBmWTOHnepGCYrcinvyqEMHGIwQHKy',
    id: 3541,
    acronym: 'MFI',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'mh-hannover.de',
    name: 'Medizinische Hochschule Hannover',
    web_page: 'http://www.mh-hannover.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2qQ7RH07ie-yLWFviiMYd2v_loNAkEd-j_qD6OciXk6XKZPozlqx_lYs',
    id: 3542,
    acronym: 'MHH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'mh-trossingen.de',
    name: 'Staatliche Hochschule für Musik',
    web_page: 'http://www.mh-trossingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCVECWs1uGxvqoG1b5mnsFlVR9IDwFeWUZzcYwIEGBp9-9qbNxOMXu7T0',
    id: 3543,
    acronym: 'SHFM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'mu-luebeck.de',
    name: 'Medizinische Universität Lübeck',
    web_page: 'http://www.mu-luebeck.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWviPXzRb7-oTMtzYlSAktAFXvGs64_CBylosjzhYLJ7kJro9wdnuOHOUl',
    id: 3544,
    acronym: 'MUL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'muthesius.de',
    name: 'Muthesius-Hochschule Fachhochschule für Kunst und Gestaltung',
    web_page: 'http://www.muthesius.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3545,
    acronym: 'MFFKUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'nordakademie.de',
    name: 'Nordakademie Staatlich anerkannte private Fachhochschule mit dualen Studiengängen',
    web_page: 'http://www.nordakademie.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS8vB3IwImZmh7z66E0f-SXSX5r-I5C9_Uuv9dwkvYLR9RBTh4FQr0sBQ',
    id: 3546,
    acronym: 'NSAPFMDS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'paderborn.de',
    name: 'Theologische Fakultät Paderborn',
    web_page: 'http://www.paderborn.de/theofak/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQnhoyU_TDsfDjNKrtF1B1urZrelOUWQaof-c6Hla61Dz9MtJO-PfWxT-U',
    id: 3547,
    acronym: 'TFP',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'pfh-goettingen.de',
    name: 'Private Fachhochschule Göttingen',
    web_page: 'http://www.pfh-goettingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSw3uuSZ6284lnyfZcQo6mmbp7wmbRQ-cCBDb4CaHM3MTej6-FBL8C7Lw',
    id: 3548,
    acronym: 'PFG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ph-erfurt.de',
    name: 'Pädagogische Hochschule Erfurt/Mühlhausen',
    web_page: 'http://www.ph-erfurt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB1_0i8c63tYQnGqmpkrRK43p-IxUfApyJ9N8Ep5gWX8hG9Cbg9ZCpXsg',
    id: 3549,
    acronym: 'PHE',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ph-freiburg.de',
    name: 'Pädagogische Hochschule Freiburg',
    web_page: 'http://www.ph-freiburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV9_gpYvwJXQ5aXfleoJ_HTDQLeOJAnn2_FiH7fJ5hb-OlDbk8Cuwexg',
    id: 3550,
    acronym: 'PHF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ph-gmuend.de',
    name: 'Pädagogische Hochschule Schwäbisch Gmünd',
    web_page: 'http://www.ph-gmuend.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfFRXO38qENFjqr3vqXwzP3IxCS1MDo7-u-8y7-BRba_9FFuxFiYDcyCQ',
    id: 3551,
    acronym: 'PHSG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ph-heidelberg.de',
    name: 'Pädagogische Hochschule Heidelberg',
    web_page: 'http://www.ph-heidelberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHDzh52ggz4YBN43V6qPM759RcXzBsbeQna9b0ceLBCsEMdiI6NzaQOYo',
    id: 3552,
    acronym: 'PHH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'philtheol-augustin.de',
    name: 'Philosophisch-Theologische Hochschule SVD Sankt Augustin',
    web_page: 'http://www.philtheol-augustin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlCv0o-2TJzQj1BS91G5DvOEySWDAIocD0HCZ4msP6hh9gaZoBfnExYA',
    id: 3553,
    acronym: 'PHSSA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ph-karlsruhe.de',
    name: 'Pädagogische Hochschule Karlsruhe',
    web_page: 'http://www.ph-karlsruhe.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3yiciUioWS2YkPaEk1oNNS22nFF8RbniEBzIWM7ezm3o1aIHvMOTCG08',
    id: 3554,
    acronym: 'PHK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ph-ludwigsburg.de',
    name: 'Pädagogische Hochschule Ludwigsburg',
    web_page: 'http://www.ph-ludwigsburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdaRu8HTQ0oQBUR-AhFYLOsXtxr11KhukyginIHMZLW4z0Bx0FlGUIej8',
    id: 3555,
    acronym: 'PHL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ph-weingarten.de',
    name: 'Pädagogische Hochschule Weingarten',
    web_page: 'http://www.ph-weingarten.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLMyHrhsQx7c3GwKKRSyfP7eDTv5xMK0O5TcHqM5VoYE9Shxjdf79KNHo',
    id: 3556,
    acronym: 'PHW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'popakademie.de',
    name: 'Popakademie Baden-Württemberg',
    web_page: 'http://www.popakademie.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1siHH2GvbrCZdGzQu0-CxY9Nk-UdOuUEZOaUVpQiitc5WuC_jWN_hnQiB',
    id: 3557,
    acronym: 'PB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'privatfh-da.de',
    name: 'Private FernFachhochschule Darmstadt',
    web_page: 'http://www.privatfh-da.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 3558,
    acronym: 'PFD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'pth-bb.de',
    name: 'Philosophisch-Theologische Hochschule der Salesianer Don Boscos',
    web_page: 'http://www.pth-bb.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb1021bLNZj5svGJ2OnXKgQKkwtSRyP5mPpRtNbpxogYqU5IlSS60yAPE',
    id: 3559,
    acronym: 'PHDSDB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'pth-muenster.de',
    name: 'Philosophisch-Theologische Hochschule Münster',
    web_page: 'http://www.pth-muenster.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTULcLnGKIjVWwsEg-jLrBCKF3VXVIwgU1-DNLtMAvx22tZPBRKGTbYvhs_',
    id: 3560,
    acronym: 'PHM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'pthv.de',
    name: 'Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)',
    web_page: 'http://www.pthv.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMOEZGG8qdZ0ut_5addkAFk_Es46dEze2IvO2HD1w5WTYggUE7Oc2bqIU',
    id: 3561,
    acronym: 'PHVDGDKA(',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'rauheshaus.de',
    name: 'Evangelische Fachhochschule für Sozialpädagogik der "Diakonenanstalt des Rauhen Hauses" Hamburg',
    web_page: 'http://www.rauheshaus.de/fachhochschule/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl46G5Wp2McRE2VHDNQBIcqWg3HkGLTxrYqSeGNEtjQ7qGrQricl9fg25Q',
    id: 3562,
    acronym: 'EFFSD"DRHH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'rfh-koeln.de',
    name: 'Rheinische Fachhochschule Köln',
    web_page: 'http://www.rfh-koeln.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR57_KzAu5ikEcKXDr2qgrnxBJwa7ndeiPEpLRQ7YIRK6TIqz4Kp6wIew',
    id: 3563,
    acronym: 'RFK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'ruhr-uni-bochum.de',
    name: 'Ruhr-Universität Bochum',
    web_page: 'http://www.ruhr-uni-bochum.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMyE1Ex9NJkq_J9BEHjai3ACZZcT12LAXM5lJIBzk6zxPz6e8FFG-ZnA',
    id: 3564,
    acronym: 'RB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'rwth-aachen.de',
    name: 'Rheinisch Westfälische Technische Hochschule Aachen',
    web_page: 'http://www.rwth-aachen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShmb0eTDo5rewC2wcwSzHalsQb3-7MDnFd80oFb9EqvPL-bOQK2RI9qpM',
    id: 3565,
    acronym: 'RWTHA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'selk.de',
    name: 'Lutherische Theologische Hochschule Oberursel',
    web_page: 'http://www.selk.de/LThH/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp2Spl8I6kLDeyg3nsj4K7eKAgPN0ZuVVk-gJ0vSho_2CbZ1zjARqCXA',
    id: 3566,
    acronym: 'LTHO',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'siewerth-akademie.de',
    name: 'Gustav-Siewerth-Akademie',
    web_page: 'http://www.siewerth-akademie.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLniwJvrsqU_sR_4EaRYDJKLtI5hcq98GBw3W4l1fnndvcFcCQmv807yk',
    id: 3567,
    acronym: 'G',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'siu-heidelberg.de',
    name: 'Schiller International University Heidelberg',
    web_page: 'http://www.siu-heidelberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkISPjUVdvvh6s8X1Obpv02ab06y2hH3cg5gAxuvhud8Posb4IOXmtxBA',
    id: 3568,
    acronym: 'SIUH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'srh-berlin.de',
    name: 'SRH University of Applied Sciences',
    web_page: 'http://www.srh-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL5323zacbySLY5pfNLs_0XoPvcM8GJ217aSooV5B5tV6R2zrHdyIuSi7_Gw',
    id: 3569,
    acronym: 'SUAS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'st-georgen.uni-frankfurt.de',
    name: 'Philosophisch-Theologische Hochschule Sankt Georgen',
    web_page: 'http://www.st-georgen.uni-frankfurt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkJtmTls6COpfNyZMWGeigayJM8lxktjZ27rYpYitIi7ghEzl51efzLA',
    id: 3570,
    acronym: 'PHSG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'stw.de',
    name: 'Steinbeis-Hochschule-Berlin',
    web_page: 'http://www.stw.de/shb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8m7JaGGyAn_4MDx6YLLzG1oy-x1WN4zo0wr_iuY-DM-Pn_VkUSzzEww',
    id: 3571,
    acronym: 'S',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tfh-berlin.de',
    name: 'Technische Fachhochschule Berlin',
    web_page: 'http://www.tfh-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUAEpFN9925W3jAe0PB53YDeEzB5FcRyI5quVs-iooZf7Qz6n5enEJ_A',
    id: 3572,
    acronym: 'TFB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tfh-bochum.de',
    name: 'Technische Fachhochschule Georg Agricola für Rohstoff Energie und Umwelt zu Bochum',
    web_page: 'http://www.tfh-bochum.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0ixxE1mlB6_rgx3q39l1DLKioVHBZWh6zIg9eEN3TJx1y-yBGFK29iQ',
    id: 3573,
    acronym: 'TFGAFREUUZB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tfh-wildau.de',
    name: 'Technische Fachhochschule Wildau',
    web_page: 'http://www.tfh-wildau.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRf1fSEpRYSM0_l_Aoy8z5jU2Kj9ulvFlhLk64opPrTBcV8qubkgw8N6Ts',
    id: 3574,
    acronym: 'TFW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'thh-friedensau.de',
    name: 'Theologische Hochschule Friedensau',
    web_page: 'http://www.thh-friedensau.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjWAKX7YArpC-jZpMMFnMx_xLsPDJOhga09fIvWDcJp39-wnSQEsnZSA',
    id: 3575,
    acronym: 'THF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tiho-hannover.de',
    name: 'Tierärztliche Hochschule Hannover',
    web_page: 'http://www.tiho-hannover.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUW3EaouiDnEl6q9txPe7CmwGg_N_UZPz_QIuV0MO2WliVnjlUPO8DSsM',
    id: 3576,
    acronym: 'THH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-berlin.de',
    name: 'Technische Universität Berlin',
    web_page: 'http://www.tu-berlin.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB1wWjdmipaqyarTvJjjdzurVq24bLcOq0XDqvZCeyTCjG2Q4Wzitc0vk',
    id: 3577,
    acronym: 'TUB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-bs.de',
    name: 'Technische Universität Carolo-Wilhelmina Braunschweig',
    web_page: 'http://www.tu-bs.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvMC0_WOTcHRLD79B00sMHJyLszvXtigQ8Q4whobCyXq5QlvRXJbqOPNEZ',
    id: 3578,
    acronym: 'TUCB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-chemnitz.de',
    name: 'Technische Universität Chemnitz',
    web_page: 'http://www.tu-chemnitz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6OFWRSSUnXmPniYvF9biRbMhUVPsNYl8opPZXgbQBrJpQ0rkDxRw5Of49',
    id: 3579,
    acronym: 'TUC',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-clausthal.de',
    name: 'Technische Universität Clausthal',
    web_page: 'http://www.tu-clausthal.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAmFy3-wsDgCX-TEDGBvFBZzCejimvZ_ECylQmlKM97avjnVFOY7tS5x4',
    id: 3580,
    acronym: 'TUC',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-cottbus.de',
    name: 'Brandenburgische Technische Universität Cottbus',
    web_page: 'http://www.tu-cottbus.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiiaVWsYrzaKBDWWdBi8w-WPZXJiEU3SfUE1SlxzscN0CzwWQCTLKdmwA',
    id: 3581,
    acronym: 'BTUC',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-darmstadt.de',
    name: 'Technische Universität Darmstadt',
    web_page: 'http://www.tu-darmstadt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMDxoVT8lCVkECMUGnLjlVCQWIWydF6QDhiMqmd0M2CIbAkKVdjKC4_NE',
    id: 3582,
    acronym: 'TUD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-dresden.de',
    name: 'Technische Universität Dresden',
    web_page: 'http://www.tu-dresden.de/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQ5LlU3sAWwa7n2YPpMTlubWkgGwDoZ_uTT0HfrOCwDr4k8akoe-2X3sQg',
    id: 3583,
    acronym: 'TUD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-freiberg.de',
    name: 'Technische Universität Bergakademie Freiberg',
    web_page: 'http://www.tu-freiberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnJF7bAnCON13tiQhvJ7Ok80L_9vKfcfD5rUJLTyrxZP1vcy6Ohstl0A',
    id: 3584,
    acronym: 'TUBF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-harburg.de',
    name: 'Technische Universität Hamburg-Harburg',
    web_page: 'http://www.tu-harburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRysyC8X4i3hO5OBpdiNOyo1cEMLI7V95eX5n05y755dBmxwZfwP8Nosxw',
    id: 3585,
    acronym: 'TUH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tu-ilmenau.de',
    name: 'Technische Universität Ilmenau',
    web_page: 'http://www.tu-ilmenau.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJaA-l8zxO5GGudPO-VC2dzv8CQyP-9sIWKZ1kVfjY8FdN5NCKT9YB9tqG',
    id: 3586,
    acronym: 'TUI',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'tum.de',
    name: 'Technische Universität München',
    web_page: 'http://www.tum.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRlp5V-H0ddNS_s4wTrPN3q6AU7oGlINRYz6ZBpxw-dhYgSLiTKblDNlQ',
    id: 3587,
    acronym: 'TUM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-augsburg.de',
    name: 'Universität Augsburg',
    web_page: 'http://www.uni-augsburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSenYSBBuCc_iPvPZD4dICJrp1fgBR0K8R5SrhSN5E1INx3BlZlUWL7CPg',
    id: 3588,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-bamberg.de',
    name: 'Otto-Friedrich Universität Bamberg',
    web_page: 'http://www.uni-bamberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSylFPKEta-RKYn8t2hoVHt3Z2IBLxRHM_H_0wmg_9hQjoabivpmRtpow',
    id: 3589,
    acronym: 'OUB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-bayreuth.de',
    name: 'Universität Bayreuth',
    web_page: 'http://www.uni-bayreuth.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHAAtwwAklYjkRw3QxmaBa-ANOoL51xR2eK2kMHFtD15BFyqBleuzVwIU',
    id: 3590,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-bielefeld.de',
    name: 'Universität Bielefeld',
    web_page: 'http://www.uni-bielefeld.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbXutkZPSbQvMvlO4i8jpQn-wN7tZgd5ceO9QrNsLJK5v3o_YPrB7ylVSz',
    id: 3591,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-bonn.de',
    name: 'Rheinische Friedrich-Wilhelms Universität Bonn',
    web_page: 'http://www.uni-bonn.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcOQmPpGdWWunzUK1WODww_gmbv4VzfydfP_Bc5GGvul4kvDs0IBCjVgw',
    id: 3592,
    acronym: 'RFUB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-bremen.de',
    name: 'Universität Bremen',
    web_page: 'http://www.uni-bremen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZxy2fgESsvKr-H_Y3nVx2SxYQV5rS9xDq5gDMmTK5DeLAlodwYN7WMy49',
    id: 3593,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'unibw-hamburg.de',
    name: 'Universität der Bundeswehr Hamburg',
    web_page: 'http://www.unibw-hamburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTygtoDXawo43H6L_OAVbsrbYI2S9PM0_EnyOvIe29weHG21d589Qar-e8',
    id: 3594,
    acronym: 'UDBH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'unibw-muenchen.de',
    name: 'Universität der Bundeswehr München',
    web_page: 'http://www.unibw-muenchen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7CBOAHrdyDAytzqW3RZVzhDQgOtZQPioBr-ZlhKCgpbQVwuK9yfake04',
    id: 3595,
    acronym: 'UDBM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-dortmund.de',
    name: 'Universität Dortmund',
    web_page: 'http://www.uni-dortmund.de/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcS0sWpmsNTe2Man1YbpPguNTsvRRqr6yqEXru50k1oKeI-WIOZdkhPc_g',
    id: 3596,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-duesseldorf.de',
    name: 'Heinrich-Heine Universität Düsseldorf',
    web_page: 'http://www.uni-duesseldorf.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6pniJs4u-5xCG5mcGI74bF3TSuV8j7MiMcbZXw_sNOER_wpanx9tXdlCa',
    id: 3597,
    acronym: 'HUD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-duisburg-essen.de',
    name: 'Universität Duisburg-Essen',
    web_page: 'http://www.uni-duisburg-essen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSex9eri_jn1oF-OxmVser9XThLGS3jL5evix0nMNzULAClCcyT8Df3hA',
    id: 3598,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-erfurt.de',
    name: 'Philosophisch-Theologisches Studium Erfurt Staatlich anerkannte Wissenschaftliche Hochschule',
    web_page: 'http://www.uni-erfurt.de/phil-theol/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf4xFjFKPMHVfrLvDqJxrkSFGWqSOpIMX_EpvDQUb-8sMo9UlyJLOxyM4',
    id: 3599,
    acronym: 'PSESAWH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-erfurt.de',
    name: 'Universität Erfurt',
    web_page: 'http://www.uni-erfurt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf6A-WiJplzh2zrRIY8el7aDVxp153sApnbwy7W-A_nwakxWMSnZmWYec',
    id: 3600,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-erlangen.de',
    name: 'Friedrich-Alexander Universität Erlangen-Nürnberg',
    web_page: 'http://www.uni-erlangen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKPJmOsy4nxw_wAccBmRwXZlfko2JJ9-CM-pHa7PN0I8gnbPSdPzrXs_8',
    id: 3601,
    acronym: 'FUE',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-flensburg.de',
    name: 'Universität Flensburg',
    web_page: 'http://www.uni-flensburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTcQg_YfR6Of8d4rhjMFNxijO-uFxRfjDnT8AUmTtk5en68WdYcsr7y-Q',
    id: 3602,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-frankfurt.de',
    name: 'Johann Wolfgang Goethe Universität Frankfurt am Main',
    web_page: 'http://www.uni-frankfurt.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQl48ebpw3P6rMnCj_tl3J561lrsRaVvdo3OTzWh9Q4Pl_EsTy59YAd1A',
    id: 3603,
    acronym: 'JWGUFAM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-freiburg.de',
    name: 'Albert-Ludwigs-Universität Freiburg',
    web_page: 'http://www.uni-freiburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzI8qqpvgCOg5Wc-Y0KDiRcFiEcoYB9rlngXMZ1PRqyebnJRMsuN0dUw',
    id: 3604,
    acronym: 'AF',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-giessen.de',
    name: 'Justus Liebig Universität Gießen',
    web_page: 'http://www.uni-giessen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdHb1Qd6qVuYWsSjMduZj_Ke52qyJu3uN6vWpqWsPPJ_EcJTnKDgKob5Q',
    id: 3605,
    acronym: 'JLUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-goettingen.de',
    name: 'Georg-August Universität Göttingen',
    web_page: 'http://www.uni-goettingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4ULfOllfsO8BFhiEx_CTFEMzISv38GB-zN7uv8pENB_Ks7X3PXAolsA5W',
    id: 3606,
    acronym: 'GUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-greifswald.de',
    name: 'Ernst-Moritz-Arndt Universität Greifswald',
    web_page: 'http://www.uni-greifswald.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA58hc9fDeanHO-UWFq3DzZqadfSE_9R-ffjyKiFv_6YzJ-JwSm_Pk3gg',
    id: 3607,
    acronym: 'EUG',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-halle.de',
    name: 'Martin-Luther Universität Halle-Wittenberg',
    web_page: 'http://www.uni-halle.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqNFkOZDJhBQXaZ13Pl7XGtBcKcmkbTdB5aI1lxWItTvefHCjmSVY6JTM',
    id: 3608,
    acronym: 'MUH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-hamburg.de',
    name: 'Universität Hamburg',
    web_page: 'http://www.uni-hamburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTzj4VAj2LPK09Pb-CEsl7QJn8oRnqtt6DOywQiqSDP_9enHYo2GYziQBt',
    id: 3609,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-hannover.de',
    name: 'Universität Hannover',
    web_page: 'http://www.uni-hannover.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS10NQLISikdWD4iDLOP5s20ZgdGyKdTmcfNLym0rkcqtufG0GFNMq_Dmk',
    id: 3610,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-heidelberg.de',
    name: 'Ruprecht-Karls-Universität Heidelberg',
    web_page: 'http://www.uni-heidelberg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw6O462L94F2o2JYvCNdEQB_uWfFMqdzRE7dfYvnrN7AV-0UqfpUJeSQ521A',
    id: 3611,
    acronym: 'RH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-hildesheim.de',
    name: 'Universität Hildesheim',
    web_page: 'http://www.uni-hildesheim.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRq-y4ds2EeYSUXGdXAmqxg80oloxdJiXYT0e9EoeHYnOyAZIedYDP_PH0',
    id: 3612,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-hohenheim.de',
    name: 'Universität Hohenheim',
    web_page: 'http://www.uni-hohenheim.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRujsqoMA5TLTyTHDDjXVwV0TDsH6mjh2OZVAWtImyh9KwOW-tQijlvYg',
    id: 3613,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-jena.de',
    name: 'Friedrich-Schiller Universität Jena',
    web_page: 'http://www.uni-jena.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToGXoZG-EGR2_wI2erqoErgR2_SnSV29Ox5BkugUoOVOExT2F72ug8fJR8',
    id: 3614,
    acronym: 'FUJ',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-karlsruhe.de',
    name: 'Universität Fridericana Karlsruhe (Technische Hochschule)',
    web_page: 'http://www.uni-karlsruhe.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZTQ6uv1me5tbPpKvLBBpw9P2cfp68UPs5mMYDs4_qVZVgOxXpNe9X2Q',
    id: 3615,
    acronym: 'UFK(H',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-kassel.de',
    name: 'Universität Kassel',
    web_page: 'http://www.uni-kassel.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9ZZHML8yXMTAjhM4BWkPbeLZgJqxiLeDWOQWAnkbcK6pJbPNzthfWUw',
    id: 3616,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-kiel.de',
    name: 'Christian-Albrechts-Universität Kiel',
    web_page: 'http://www.uni-kiel.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfLEW2GJSSKbm5-THalMsI_U3SZkqe3xEoFq6XQH6nf_eEkfJrcie4jg',
    id: 3617,
    acronym: 'CK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-kl.de',
    name: 'Universität Kaiserslautern',
    web_page: 'http://www.uni-kl.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVOpiMrr_YtsWfYtd2bYW2oeHyOHxGqO2DS9GPdmHXFibrjd6GsSa9uA',
    id: 3618,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-koblenz-landau.de',
    name: 'Universität Koblenz-Landau',
    web_page: 'http://www.uni-koblenz-landau.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0ie2sflBsS_kOdYDilkJ8cIxJXUotGcH3dVXlPuF2wX77UCfqjaZyFGkL',
    id: 3619,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-koeln.de',
    name: 'Universität Köln',
    web_page: 'http://www.uni-koeln.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZHDBtYtZJUZNQuxIncRy2zYH_daSusIZzTwRPlFZGIifvmwxAhAX4FLID',
    id: 3620,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-konstanz.de',
    name: 'Universität Konstanz',
    web_page: 'http://www.uni-konstanz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_8VBpRSMWfmBpX5_x60PxYvsxuoUJyLpN03kFnNQ9W_RYza7hDsWq4g',
    id: 3621,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-leipzig.de',
    name: 'Universität Leipzig',
    web_page: 'http://www.uni-leipzig.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2GD2VcQJOtAz7wVm9-OjBsLxSDH3yUfcGAx8TY4uBMswFKFMfb2lGDw',
    id: 3622,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-lueneburg.de',
    name: 'Universität Lüneburg',
    web_page: 'http://www.uni-lueneburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReejHaD2wLy0SWCL8xQVc8fn7JY2mS-TPu6lLWYQVl6b0_UBvcZHbQ-wE',
    id: 3623,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-magdeburg.de',
    name: 'Otto-von-Guericke Universität Magdeburg',
    web_page: 'http://www.uni-magdeburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlBaOfNK56_ReB5S0V2dVpIRB1zmT_udicTpRBF5W5oU0XWG8ASwd5_YY',
    id: 3624,
    acronym: 'OUM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-mainz.de',
    name: 'Johannes-Gutenberg Universität Mainz',
    web_page: 'http://www.uni-mainz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuyYkWqjbNGr9UA2bw_O5TjHW1SZYzKkPGlzoQd10HwOe8L_JcF3s41Q',
    id: 3625,
    acronym: 'JUM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-mannheim.de',
    name: 'Universität Mannheim',
    web_page: 'http://www.uni-mannheim.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5rwffEYaqcHFkFMveCMA_tZjSoZSedPpGDl-q8DCvOj6PPJYbXKVUuhw',
    id: 3626,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-marburg.de',
    name: 'Phillips-Universität Marburg',
    web_page: 'http://www.uni-marburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5DquaJl0uIbntRJC34VXrYZe4FK_Npq4aj2t-wDWZQGETbIMiF2HtGk7Yhg',
    id: 3627,
    acronym: 'PM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-muenchen.de',
    name: 'Ludwig-Maximilians-Universität München',
    web_page: 'http://www.uni-muenchen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVAbA5byXKF70E-T7DRfJJt-qQ6fedY43qkLrB69GjZmvGF1mO5vN8zGOG',
    id: 3628,
    acronym: 'LM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-muenster.de',
    name: 'Westfälische Wilhelms-Universität Münster',
    web_page: 'http://www.uni-muenster.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvvYP6w_1julOilvlduPj35_0JsRndbw01XbSK3Ck5Jd24HMWf4mFcNQ',
    id: 3630,
    acronym: 'WWM',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-oldenburg.de',
    name: 'Carl von Ossietzky Universität Oldenburg',
    web_page: 'http://www.uni-oldenburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRUMKb6AvzOgzQ__Ffe11Eketc-9VKyR7UGpE_bvM8chiL7adHLjZ_5_2g',
    id: 3631,
    acronym: 'CVOUO',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-osnabrueck.de',
    name: 'Universität Osnabrück',
    web_page: 'http://www.uni-osnabrueck.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdxRXlbudcXFM0Uq8cvVnJHHe4V_dJ_Q29I-PsSiE_W7pOzxfdV_ZFOg',
    id: 3632,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-paderborn.de',
    name: 'Universität Paderborn',
    web_page: 'http://www.uni-paderborn.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRseWqp-Hgy4GPjfW0NA1PY-pnyXzbSjqGHz0QyFB0qU6aSDpmaX30X2bQ',
    id: 3633,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-passau.de',
    name: 'Universität Passau',
    web_page: 'http://www.uni-passau.de/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcR-Bw62faN8s3nd-792GLCMDzrd7nP7AHaRDqNUsfzTDO2y91MhWUP0-mMq',
    id: 3634,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-potsdam.de',
    name: 'Universität Potsdam',
    web_page: 'http://www.uni-potsdam.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR66Glc46YoDFJEsucEZTtZCT6BcYanjbmJgR7N2idHEJw8CvAOrMOf-g',
    id: 3635,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-polsdam.de',
    name: 'Universität Polsdam',
    web_page: 'http://www.uni-polsdam.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR66Glc46YoDFJEsucEZTtZCT6BcYanjbmJgR7N2idHEJw8CvAOrMOf-g',
    id: 3636,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-regensburg.de',
    name: 'Universität Regensburg',
    web_page: 'http://www.uni-regensburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_TeZ7LmWm4It2rPdd1F8Y12ghNT2FFs6e_fz6xlRQQAqcUkkJHtQHofY',
    id: 3637,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-rostock.de',
    name: 'Universität Rostock',
    web_page: 'http://www.uni-rostock.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYtVkst3NdokiFYSzxeIZbGQBTZvNPqQLORh8bbydjZrMrWQS4tbFwvn6J4g',
    id: 3638,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-saarland.de',
    name: 'Universität des Saarlandes',
    web_page: 'http://www.uni-saarland.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDOdpDjXkrdDrhKu9KlL1vUIgLclJdkhnL5C9mifNWZX6ddzcBIN78rTU-',
    id: 3639,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-siegen.de',
    name: 'Universität Siegen',
    web_page: 'http://www.uni-siegen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpxxwYWJzW2zqyaw3-QbZQBnjI4r8SH73H-ziTxcprf5MGSCdBIB2Pls9C',
    id: 3640,
    acronym: 'US',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-stuttgart.de',
    name: 'Universität Stuttgart',
    web_page: 'http://www.uni-stuttgart.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJEVkSTFBfV88I-AYQkvRlmSo7EW7KhwCB7w1clHvO-tK-63__6-N0wvEz',
    id: 3641,
    acronym: 'US',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-trier.de',
    name: 'Theologische Fakultät Trier',
    web_page: 'http://www.uni-trier.de/uni/theo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqL7HwCt6GPJPN8NR5srKR2jSCReK-W0B2rWh6Gaw4L3PG5Fb7Z938M4YO',
    id: 3642,
    acronym: 'TFT',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-trier.de',
    name: 'Universität Trier',
    web_page: 'http://www.uni-trier.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKlQ6FFGcA2aP-TE4Sja_jAxAzqHztZzpm_7pQv0S5Y6dKFmhauu4B3-A',
    id: 3643,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-tuebingen.de',
    name: 'Eberhard-Karls-Universität Tübingen',
    web_page: 'http://www.uni-tuebingen.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU30eidZzk107BrvhF1fJZbocdvD1vt25FKAY74gTP55J5gzYNvr5M5Sc',
    id: 3644,
    acronym: 'ET',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-ulm.de',
    name: 'Universität Ulm',
    web_page: 'http://www.uni-ulm.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlCfTKUnu2el5vXgzIsZD5dq2spFIn2l_7J0NLTFfY3IE7cKy00w2Usg',
    id: 3645,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-vechta.de',
    name: 'Hochschule Vechta',
    web_page: 'http://www.uni-vechta.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSULKHw-CO9z_xvFAV9YZDDNcJ_VhuCGm5e54YjjMufauZCLo9ZD-_vSw',
    id: 3646,
    acronym: 'HV',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-weimar.de',
    name: 'Bauhaus Universität Weimar',
    web_page: 'http://www.uni-weimar.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrBZeAZZEPCB6aVviZyxt32MXAfK_gigWIKCvDOfnA3p0TAdIOcf3KD40',
    id: 3647,
    acronym: 'BUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-wh.de',
    name: 'Private Universität Witten/Herdecke',
    web_page: 'http://www.uni-wh.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt6jVUpL1YDmgV5iNxt5T9o9od64oKyjnlyG3-o5S7qMiLTWxB65gN7UY',
    id: 3648,
    acronym: 'PUW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-wuerzburg.de',
    name: 'Bayerische Julius-Maximilians-Universität Würzburg',
    web_page: 'http://www.uni-wuerzburg.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgWKTp2OqGVcXJaxOJWMiBw3MkmipLKH30cJZQrEzL9PV9elntcsDl6Gc',
    id: 3649,
    acronym: 'BJW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-wuppertal.de',
    name: 'Bergische Universität Gesamthochschule Wuppertal',
    web_page: 'http://www.uni-wuppertal.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ1ZfOZUcGxx3EAPIsgGsPRht_2alZxX3Qn3r0d4LDw794LX6ARmlOuce_',
    id: 3650,
    acronym: 'BUGW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'uni-wuppertal.de',
    name: 'Kirchliche Hochschule Wuppertal',
    web_page: 'http://www.uni-wuppertal.de/inst/kiho/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu7ymwUzcACzFrDx5tx50MVNxlICH5pCJvNURNUfsiMXLJJ4vpntDzLrf8',
    id: 3651,
    acronym: 'KHW',
  },
  {
    alpha_two_code: 'DE',
    country: 'Germany',
    domain: 'whu-koblenz.de',
    name: 'Wissenschaftliche Hochschule für Unternehmensführung Otto-Beisheim Hochschule',
    web_page: 'http://www.whu-koblenz.de/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1HGCiSW4lUwx-H9AkeLU5m0J31Tl2Xljf8ClMwdvmNmqppipSPvHFeA_0',
    id: 3652,
    acronym: 'WHFUOH',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'accrapolytechnic.edu.gh',
    name: 'Accra Polytechnic',
    web_page: 'http://www.accrapolytechnic.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoYgbt7QXPW7ur1aOS9AJ4l0c0K4zfcYtwI7UsxeBIXymzUwDNX7AVq4s',
    id: 3653,
    acronym: 'AP',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'acighana.org',
    name: 'Akrofi-Christaller Institute of Theeology Mission and  Culture',
    web_page: 'http://www.acighana.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKhuQJ-oRGiuOFAJk3BSVRF6HIKPWcgLDbUw4gr_FDmvnIr17_Jl-9h_8i',
    id: 3654,
    acronym: 'AITMC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'anuc.edu.gh',
    name: 'All Nations University College',
    web_page: 'http://www.anuc.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNeoK3zD-P5pOaXba-pyFr778NVc481LTSb3aztmSx-X6_UTi8ENn6X2E',
    id: 3655,
    acronym: 'ANUC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'ashesi.edu.gh',
    name: 'Ashesi University',
    web_page: 'http://www.ashesi.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrK_nLyHeGuXbLILDw2w4yBeIk7BFVughG4NIAdB3eypMqUQHHIFhDv4I',
    id: 3656,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'central.edu.gh',
    name: 'Central University College',
    web_page: 'http://www.central.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-U6QqMopWUzFCO9JjlHzD-yBEcrZs_fq8mlK2fvxI23dwidufDKKeVg',
    id: 3657,
    acronym: 'CUC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'cpoly.edu.gh',
    name: 'Cape Coast Polytechnic',
    web_page: 'http://www.cpoly.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQsGAlAY5gJKPdUCkN4ZhrT5faJ1qoxciiqUpmDnHbS5PrxzVDcvpYyg',
    id: 3658,
    acronym: 'CCP',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'ghanacu.org',
    name: 'Ghana Christian University College',
    web_page: 'http://www.ghanacu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-_U0NS8gm_L4UtgutC85WxWWuFUtz7ZTFK47sg1SEkUv3PL9vHg--QX0',
    id: 3659,
    acronym: 'GCUC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'gimpa.edu.gh',
    name: 'Ghana Institute of Management and Public Administration (GIMPA)',
    web_page: 'http://www.gimpa.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT_s8hMGvuv95xvvgmvydquuniA8WuokSp1lALkIRzOsMO9Hv_HiiPdNg',
    id: 3660,
    acronym: 'GIMPA(',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'gtuc.edu.gh',
    name: 'Ghana Telecom University College',
    web_page: 'http://www.gtuc.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbV1aSjRSlgzqEjyYakAU79gfpXc_HkECfIPWuGAaMDBc6kM3hTzw0-A',
    id: 3661,
    acronym: 'GTUC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'hopoly.edu.gh',
    name: 'Ho Polytechnic',
    web_page: 'http://www.hopoly.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDvb8IQJ0C66VaKbrfEwwU1sFSbeN1JadZMN-qo1BygErgJr5xGE99Dg',
    id: 3662,
    acronym: 'HP',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'iug.edu.gh',
    name: 'Islamic University College',
    web_page: 'http://www.iug.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4U3K8b3WypHuOEe_e5L1ITaJxWFIpQQSo7oBOaQWViI7A67q6Uey4kW5baQ',
    id: 3663,
    acronym: 'IUC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'knust.edu.gh',
    name: 'Kwame Nkrumah University of Science and Technology',
    web_page: 'http://www.knust.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA77DHQGMpNey06uSDZUpSdWxvbJHIDpn7LKvl4s_oyWxrRmMDATAjdJY',
    id: 3664,
    acronym: 'KNUST',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'koforiduapoly.edu.gh',
    name: 'Koforidua Polytechnic',
    web_page: 'http://www.koforiduapoly.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY8LGxUqJZbsc5KqcHKgGkU6dWAJ30UHdz_nHFliHbf4hjfg8391WDAFA',
    id: 3665,
    acronym: 'KP',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'kpoly.edu.gh',
    name: 'Kumasi Polytechnic',
    web_page: 'http://www.kpoly.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2f7CUZM6d-1xYO7fIzhWm8HC8z0wstVwnjazszQvQZKwsPmKleF-6YMUn',
    id: 3666,
    acronym: 'KP',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'pacuc.edu.gh',
    name: 'Pan African Christian University College',
    web_page: 'http://www.pacuc.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvt02WlU_OVLv3N7CfdisW3Vt0qfgxUn1aUZiZFQoVS3B8u4tlNZg3Dvk',
    id: 3667,
    acronym: 'PACUC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'prempeh-college.com',
    name: 'Prempeh College',
    web_page: 'http://www.prempeh-college.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd9CUaUp2UcIzN9K570HczinWJBr4WE2Gfv60xTwnSNJBF1tB4fUfnDQ',
    id: 3668,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'presbyuniversity.edu.gh',
    name: 'Presbyterian University College',
    web_page: 'http://www.presbyuniversity.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8EiYbFCv1NWKMOvOyfvcdxRhyv8VaJvFo_hJI4T04xZiQvaAemkqcNs0',
    id: 3669,
    acronym: 'PUC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'regentghana.net',
    name: 'Regent University College of Science and Technology',
    web_page: 'http://www.regentghana.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWgr1Q8UR42CNxp_qgfWgOzFrMkHvjM6l8MWw5nKp0p-_nUWFU8RZCBRY',
    id: 3670,
    acronym: 'RUCST',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'tpoly.edu.gh',
    name: 'Takoradi Polytechnic',
    web_page: 'http://www.tpoly.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa-CLz34aJHU2fp58TPbFntZKrLwdnx9eHD5Cy1GrfKMXIahLx_P4SXCA',
    id: 3671,
    acronym: 'TP',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'trinity.edu.gh',
    name: 'Trinity Theological Seminary',
    web_page: 'http://www.trinity.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbGsE7cwzRmL7i573es-qY6gfk9kkSUZ93ENq7Lu0bPNnvkiZJcWTevQ',
    id: 3672,
    acronym: 'TTS',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'ucc.edu.gh',
    name: 'University of Cape Coast',
    web_page: 'http://www.ucc.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3ZiCQIejdJ5KFgJ0Bsv5byA2ORnlh8wj3avFwwDch3hmSwXfxdLQj8g',
    id: 3673,
    acronym: 'UCC',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'uds.edu.gh',
    name: 'University for Development Studies',
    web_page: 'http://www.uds.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUcxrtvlN_OyXL0Q4ZPl4CYBF0CFMla2ra8WCRkIqYUcFs2fjlOOckoqE',
    id: 3674,
    acronym: 'UFDS',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'uew.edu.gh',
    name: 'University of Education Winneba',
    web_page: 'http://www.uew.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPAYELSfDtfjh0QBFBEAALpVDBiEoL5AjcYlXa_b0OtPRgn_JB_Tgs1Ek',
    id: 3675,
    acronym: 'UEW',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'ug.edu.gh',
    name: 'University of Ghana',
    web_page: 'http://www.ug.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-oKkGSN1bjbh7jFCfbBUzH4WMA8yVwJiR05mbxlPdGSb5G8oJATz8DYI',
    id: 3676,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'GH',
    country: 'Ghana',
    domain: 'vvu.edu.gh',
    name: 'Valley View University',
    web_page: 'http://www.vvu.edu.gh/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYT-4LeRtKpWhlPNlR07qDlSCC9sNMIAVsyPOWjJ6QHokLMTAqdD_zzsuCCg',
    id: 3677,
    acronym: 'VVU',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'acg.gr',
    name: 'American College of Greece',
    web_page: 'http://www.acg.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjf-MBzLdP8rvsTPUCua8viPA1oIiAaA-xoug2KmXZfAVlarKcKd6VCw',
    id: 3678,
    acronym: 'ACG',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'act.edu',
    name: 'American College of Thessaloniki',
    web_page: 'http://www.act.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUqnMvIrUquvAFoNKnuNV9a36isvME2AHPo50JDPpSwdz6-sQabDnmuw',
    id: 3679,
    acronym: 'ACT',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'aegean.gr',
    name: 'Aegean University',
    web_page: 'http://www.aegean.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXm2dNK6zGoNEksquFUWfB6AxpBhHonJ69dnHfEJha4eRaycxvlpoJ_so',
    id: 3680,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'agsm.gr',
    name: 'Athens Graduate School of Management (AGSM)',
    web_page: 'http://www.agsm.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2muLswPOQnjXzI0IATXN_KGEeNF7XJA-JMo2miZSnPX1R0lsE6L3bAAs',
    id: 3681,
    acronym: 'AGSM(',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'alba.edu.gr',
    name: 'Athens Laboratory of Business Administration (ALBA)',
    web_page: 'http://www.alba.edu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEHm6wF7SWa7bNNsTUMjKKOuHZXts0UDyPA3FKDRrkT7VdIIvcZs8r1g',
    id: 3682,
    acronym: 'ALBA(',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'asfa.gr',
    name: 'Athens School of Fine Arts',
    web_page: 'http://www.asfa.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTM3mJ0aN0qrkwzhdjn5f-YT-zXmGC4P3JeLn5z4zEJLgOWgVbb-3lgA',
    id: 3683,
    acronym: 'ASFA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'aua.gr',
    name: 'Agricultural University of Athens',
    web_page: 'http://www.aua.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpS3k9R4a0RU-DUftzDqYBzw10sp99CzOD_1mXpFkuHyCAGjQMpRfHxQ',
    id: 3684,
    acronym: 'AUA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'aueb.gr',
    name: 'Athens University of Economics and Business',
    web_page: 'http://www.aueb.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLtLsRnoZ3FWDXCNLEz8iToEmeUtm0RIdVLdSGfNtVDqBuR-XOBthzEw',
    id: 3685,
    acronym: 'AUEB',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'auth.gr',
    name: 'Aristotle University of Thessaloniki',
    web_page: 'http://www.auth.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy_r2wdBH_mXVbWQ4JJImU3yTQ2Yq5tvqChVzfq9-My-g7weINJPXxSg',
    id: 3686,
    acronym: 'AUT',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'cityu.gr',
    name: 'City University Athens',
    web_page: 'http://www.cityu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS84ckZj-NFcdF1nXAbW_eJSge0i5YDWdbWwcTQxcYfHhFHaGPbAIg9UEU',
    id: 3687,
    acronym: 'CUA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'dei.edu.gr',
    name: 'DEI Bachelor & Master Degrees',
    web_page: 'http://www.dei.edu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqkIk6AJ_gNxbsA1IuHqO7k1I34xDHGAxbm5gs9KPVNm4YcqN38yEkUkc',
    id: 3688,
    acronym: 'DB&MD',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'duth.gr',
    name: 'Dimocritus University of Thrace',
    web_page: 'http://www.duth.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThYwtqHgGACmYnToF85R-6mhpKMD3zYBj_yazKtgxlJRHfu5wIQ9iSTg',
    id: 3689,
    acronym: 'DUT',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'eap.gr',
    name: 'Hellenic Open University',
    web_page: 'http://www.eap.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTqUXtPihly_ZQHfi6W9kEDbRpec6Yz4uUGrIzYQMUBrBngHzl5c6MHQ',
    id: 3690,
    acronym: 'HOU',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'euruni.edu',
    name: 'European University Athens Campus',
    web_page: 'http://www.euruni.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeDJj3dQoaV5FsTy-0DnPX4u1BLMNnJrMxgbAUqL3f8Z0n3tGyvKLUf2Rw',
    id: 3691,
    acronym: 'EUAC',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'hua.gr',
    name: 'Harokopio University',
    web_page: 'https://www.hua.gr',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsmkb1pGwDiIS-JhmW5AKZbonWXjTRJSkG7GtMC-murbvuwWgCf4ScCQ',
    id: 3692,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'ihu.edu.gr',
    name: 'International Hellenic University',
    web_page: 'http://www.ihu.edu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcMVD8X6SEwO2_RVqeLT8OVM7idM_S0j5jsgrLqUfGuJYoA8awIRtBTw',
    id: 3693,
    acronym: 'IHU',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'imd.edu.gr',
    name: 'Institute of Management Development - Ohrid',
    web_page: 'http://www.imd.edu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzGlpomgRHTnWKhZMfjpHcQJYdkiGH-MAMGt8_fqVvN8SKCMbSbPyyVjM',
    id: 3694,
    acronym: 'IMD-O',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'imese.gr',
    name: 'Institute of Management & Enteurpreneurship of South East Europe',
    web_page: 'http://www.imese.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2tLcMwjGlKf8o3ECpFfgMwF_9zh_OsohYYmEdAI0f_cpG25YrSvtAwg',
    id: 3695,
    acronym: 'IM&ESEE',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'laverne.edu.gr',
    name: 'University of LaVerne in Athens',
    web_page: 'http://www.laverne.edu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsjqXLIIktWRIQ4ERbYpjvJpNWhj75syOeaBoqchiLyciNE9dltOtp5TM',
    id: 3696,
    acronym: 'ULIA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'ntua.gr',
    name: 'National Technical University of Athens',
    web_page: 'http://www.ntua.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDPdOGIuRf_YhX5si9kQ3cZcfj79Se1pIit11GQoqJlFiqAoyBZlwmFVyz',
    id: 3697,
    acronym: 'NTUA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'nyuniversity.net',
    name: 'Yorker International University Athens',
    web_page: 'http://www.nyuniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBaZVEai6skt6xNs4cbOFhzBxxxXTb-NEwcE8gjB_a-dAFxyOQe7Vvnc1rCg',
    id: 3698,
    acronym: 'YIUA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'panteion.gr',
    name: 'Panteios University of Economics and Political Sciences Athens',
    web_page: 'http://www.panteion.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStvaaohPOgYs4n8HPG_V-p5kqVQqshIPwOvJyyqYB4KHA6tx1gde23Ypc',
    id: 3699,
    acronym: 'PUEPSA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'sse.gr',
    name: 'Hellenic Army Academy',
    web_page: 'http://www.sse.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkQKYsV9Bv3CYNCEi1Syz2gFB05TchH0YUP8j7fhslJZZBBnHip8MDsfk',
    id: 3700,
    acronym: 'HAA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teiath.gr',
    name: 'Technological Education Institute of Athens',
    web_page: 'http://www.teiath.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH6_oIoSuw9jLbeuwVaNBhiwzccR7j6aMEznu4hMaPov-azZQNR5IcrQ',
    id: 3701,
    acronym: 'TEIA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teiep.gr',
    name: 'Technological Education Institute of Epiros',
    web_page: 'http://www.teiep.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTnbVNUi2sfGDJJvvW9MKWhoMuXObtYq74FlE_JEzCLRvDTAnJMBi7ODU',
    id: 3702,
    acronym: 'TEIE',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teihal.gr',
    name: 'Technological Education Institute of Halkida Euboea',
    web_page: 'http://www.teihal.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPflNJTuSCsJnfR0P-5Z-Oi2tPdpOWKKI30bd4UHsTPzu9h9VllWqJl-w',
    id: 3703,
    acronym: 'TEIHE',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teiher.gr',
    name: 'Technological Education Institute of Heraklion',
    web_page: 'http://www.teiher.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9Ozj0mS4y3tpEl7XrHIPIydWfRNPsV3q0VV1RMttSXJ5dJo9vEJDXW-As',
    id: 3704,
    acronym: 'TEIH',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teikal.gr',
    name: 'Technological Education Institute of Kalamata',
    web_page: 'http://www.teikal.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMAQAWTl_X5hYcUrXpB7KGrRJJlmrmffVdlsdZ-6DnCoDMSduwuMo4BCg',
    id: 3705,
    acronym: 'TEIK',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teikav.edu.gr',
    name: 'Technological Education Institute of Kavala',
    web_page: 'http://www.teikav.edu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ87YI_VJbeJ1SgTTcKD-SXXg5yLPkP4JOlSz3G9zNh3Xcm0DObtJIITA',
    id: 3706,
    acronym: 'TEIK',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teikoz.gr',
    name: 'Technological Education Institute of Kozani',
    web_page: 'http://www.teikoz.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3AayRYhStwWoP9MEZP6rfI13ScqlzHC-YbAbtuLpCfDU7K14i0sgP-Cc',
    id: 3707,
    acronym: 'TEIK',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teilam.gr',
    name: 'Technological Education Institute of Lamia',
    web_page: 'http://www.teilam.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3AayRYhStwWoP9MEZP6rfI13ScqlzHC-YbAbtuLpCfDU7K14i0sgP-Cc',
    id: 3708,
    acronym: 'TEIL',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teilar.gr',
    name: 'Technological Education Institute of Larissa',
    web_page: 'http://www.teilar.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJF-yNWf4gIbxZvgZl5kWVW3ociIlvKA-4-Nd6C1kvmRx7RT-LasU3mF5B',
    id: 3709,
    acronym: 'TEIL',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teimes.gr',
    name: 'Technological Education Institute of Mesologgi',
    web_page: 'http://www.teimes.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN6tWlDyba6KqyMgMGpFB_i2ti7ZvGog0vR6zxIYD-hiAdxpG7r-hSiOA',
    id: 3710,
    acronym: 'TEIM',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teipat.gr',
    name: 'Technological Education Institute of Patras',
    web_page: 'http://www.teipat.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBnn4ePQ-lohb8nZWXN7QQHzcA_jwRSO4AiCqLY5clY9g2fATRwHIIQ3E',
    id: 3711,
    acronym: 'TEIP',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teipir.gr',
    name: 'Technological Education Institute of Piraeus',
    web_page: 'http://www.teipir.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfYc-wjEQsVMqXaVlOjcL7rx3V8Ij4p4V5CnrBK51B8JsOBzfLm3U8uoU',
    id: 3712,
    acronym: 'TEIP',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teiser.gr',
    name: 'Technological Education Institute of Serres',
    web_page: 'http://www.teiser.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLFxBNqbxlVuJkvyyrKkEIArRz0X1K2QKUitiP7Q0i4EiFpThhUQCQI-s',
    id: 3713,
    acronym: 'TEIS',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'teithe.gr',
    name: 'Technological Education Institute of Thessaloniki',
    web_page: 'http://www.teithe.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuiJPF3h8YmblveZg6RNqW4a1RbrRFY66yz_tXfrUDjpvSuag7ZK1n1QU',
    id: 3714,
    acronym: 'TEIT',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'tuc.gr',
    name: 'Technical University of Crete',
    web_page: 'http://www.tuc.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdR3xnXqB2p4pp2UEpCuiG26Ni9YclpP_f_5JJZ-XJXXHNL20s4w7Yt9g',
    id: 3715,
    acronym: 'TUC',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'ucg.gr',
    name: 'University of Central Greece',
    web_page: 'http://www.ucg.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjf-MBzLdP8rvsTPUCua8viPA1oIiAaA-xoug2KmXZfAVlarKcKd6VCw',
    id: 3716,
    acronym: 'UCG',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uindy.edu.gr',
    name: 'University of Indianapolis in Athens',
    web_page: 'http://www.uindy.edu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8dwVpk6tzP8wQlVxi6Ao46ITl3bfkZXxGR6ked9amVPjCe0gVJPIq8vk',
    id: 3717,
    acronym: 'UIIA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uion.edu.gr',
    name: 'Ionian University Corfu',
    web_page: 'http://www.uion.edu.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScOV881eYpOsJh3amrwsb9MkGdyg4DunfPEIL2IlXK84ChHvhD7ZgNN8o',
    id: 3718,
    acronym: 'IUC',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'unipi.gr',
    name: 'University of Piraeus',
    web_page: 'http://www.unipi.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9N60Gxr0hlvVTdWLXDpqSLV-vNQnO6GNu7S9Bcu2TzWa9Xkz7LmfSfQ',
    id: 3719,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uoa.gr',
    name: 'University of Athens',
    web_page: 'http://www.uoa.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeUiVxLkwnnT02elRpeVLE57kUTiN9EDk_pbwqRqzN74QLMglKFh6SbA',
    id: 3720,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uoc.gr',
    name: 'University of Crete',
    web_page: 'http://www.uoc.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV9ArGy4MRFBE_AvQe8mOxRJI7Zdj4-RlWb0yBJQBfuUFZ6SuXzzqYwds',
    id: 3721,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uoi.gr',
    name: 'University of Ioannina',
    web_page: 'http://www.uoi.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRWun1ZeIRKQ6xTc3J3ApeH-3z48WS40lXDmyOQJcl8w7gOMd6H-xSYCc',
    id: 3722,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uom.gr',
    name: 'University of Macedonia',
    web_page: 'http://www.uom.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPWXisM1xUd7HRTn0jzxONoYugIwvYwM13czf4Fxoj-yTItVcLv2tQX3g',
    id: 3723,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uop.gr',
    name: 'University of Peloponnese',
    web_page: 'http://www.uop.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ66HpS1rut51IWY_CdK94z3z65C6jbfl3MWGvNiI9Ke6-8ESxN4kBQad0',
    id: 3724,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uowm.gr',
    name: 'University of Western Macedonia',
    web_page: 'http://www.uowm.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTspgo734LOInujJJ-VC5RidqjYDPvBraESXUKkbv65_a5zB-Lt8KPONg',
    id: 3725,
    acronym: 'UWM',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'upatras.gr',
    name: 'University of Patras',
    web_page: 'http://www.upatras.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN0GQf4-1IWqh7V904FpI8CZHa9mXJCZ6qNsqHMXRIpEIgDTVW3DqlPQ',
    id: 3726,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'GR',
    country: 'Greece',
    domain: 'uth.gr',
    name: 'University of Thessaly',
    web_page: 'http://www.uth.gr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSME8p3wLJtU56PKmWqC3qsyhDhyG4hcsBwUSJIq28EPHtKxSMi5tV90Q',
    id: 3727,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'GL',
    country: 'Greenland',
    domain: 'ilisimatusarfik.gl',
    name: 'University of Greenland',
    web_page: 'http://www.ilisimatusarfik.gl/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTVUkjFj1vQkvT4lkuvz7Dy-F0iYYm2V5mKaLkg_BPUvUu4SrHZjWUIcsg',
    id: 3728,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'GD',
    country: 'Grenada',
    domain: 'sgu.edu',
    name: "St. George's University",
    web_page: 'http://www.sgu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnJOTJ8KQnJhNbuI5tlZXw3Sf0iy6emFBfhupT-ofzhTtIR1DFkYwfaUY',
    id: 3729,
    acronym: 'SGU',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'cucv.edu.gt',
    name: 'Centro Universitario Ciudad Vieja',
    web_page: 'http://www.cucv.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK5Ahrt5-zZX4dkQRTpE4y_6IAMp92zPAuug297zVmiibeOumdNKcgtGyN',
    id: 3732,
    acronym: 'CUCV',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'cunoc.edu.gt',
    name: 'Centro Universitario de Occidente',
    web_page: 'http://www.cunoc.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgLOG1-b2SlYOhUs_LXTFxmKtUrqSXcFV3jZfp5TfZkHw-zVN5CgAMphw',
    id: 3733,
    acronym: 'CUDO',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'galileo.edu',
    name: 'Universidad Galileo',
    web_page: 'http://www.galileo.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmNr8tTNBJZxP-w__vwLDpZrR5lTpL5i8k5cnB5os3GUHBi05iVsNcxw',
    id: 3734,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'ufm.edu.gt',
    name: 'Universidad Francisco Marroquín',
    web_page: 'http://www.ufm.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSEEiQG2uSZEXF0tsz_CxNuL0jDxYoWKUUR4ys3ogRrS5t3P9_WsGyeYCV',
    id: 3735,
    acronym: 'UFM',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'umg.edu.gt',
    name: 'Universidad Mariano Gálvez',
    web_page: 'http://www.umg.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg_01qJ1xntyGR7_iOkFS4-IFdzRC-AYDnANMeL6SHRv0mVtkv6X7k6A',
    id: 3736,
    acronym: 'UMG',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'unis.edu.gt',
    name: 'Universidad del Istmo',
    web_page: 'http://www.unis.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZifc4SsRpmETGXAyIIpJptFkFe5E5jMeniyTiJiZXIQo5hx43k8namuhj',
    id: 3737,
    acronym: 'UDI',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'upana.edu.gt',
    name: 'Universidad Panamericana',
    web_page: 'http://www.upana.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwqKpZaAl7tOVbd_MMaBh2ZfvHq9P0H0YAYbkMW1-v7JrvcWTCfiPfmA',
    id: 3738,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'url.edu.gt',
    name: 'Universidad Rafael Landívar',
    web_page: 'http://www.url.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiLTEJcktY6J33PvE2UidlXqF248RPvQcjHy4wdd2OHaSGBvT_HIGXnAI',
    id: 3739,
    acronym: 'URL',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'urural.edu.gt',
    name: 'Universidad Rural de Guatemala',
    web_page: 'http://www.urural.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0ozs9q3PALZO9H-O2r-xpXVObjK0xlky59Em8WrGFBcDuAoKPYzpF2XY',
    id: 3740,
    acronym: 'URDG',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'usac.edu.gt',
    name: 'Universidad de San Carlos de Guatemala',
    web_page: 'http://www.usac.edu.gt/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSJvMJvH1ZDjb8mywvgGb6nbjgAMf4BDVu9hbRHenLlYki35cgnFZAc-IRI',
    id: 3741,
    acronym: 'UDSCDG',
  },
  {
    alpha_two_code: 'GT',
    country: 'Guatemala',
    domain: 'uvg.edu.gt',
    name: 'Universidad del Valle de Guatemala',
    web_page: 'http://www.uvg.edu.gt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2EL72YWi3OYkdnw__-kaRXy6sKQeMonTRvB58bLVqexYxNR4Oms7wkGgz',
    id: 3742,
    acronym: 'UDVDG',
  },
  {
    alpha_two_code: 'GN',
    country: 'Guinea',
    domain: 'uganc.org',
    name: 'Université Gamal Abdel Nasser de Conakry',
    web_page: 'http://www.uganc.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXr20cu5Cc6lXGDiXUMl6IpwZesqUSDNctSs2mXexMcXZhIsolq05oAv1f',
    id: 3743,
    acronym: 'UGANDC',
  },
  {
    alpha_two_code: 'GN',
    country: 'Guinea',
    domain: 'ujnk.org',
    name: 'Université Julius Nyerere Kankan',
    web_page: 'http://www.ujnk.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBk3YHOwIAQp8riWAjR1nNH58AuYArEyD5Lj4h2sWAbBhiebl2KA_0bKw',
    id: 3744,
    acronym: 'UJNK',
  },
  {
    alpha_two_code: 'GN',
    country: 'Guinea',
    domain: 'univ-kag.org',
    name: 'Université Kofi Annan',
    web_page: 'http://www.univ-kag.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaSKDL96HlJYBcfzf6vlb134hbx9X22hr9eobOhpCStueKvQbGrLngDqmL',
    id: 3745,
    acronym: 'UKA',
  },
  {
    alpha_two_code: 'GN',
    country: 'Guinea',
    domain: 'utad-petel-edu.org',
    name: 'Université Thierno Amadou Diallo',
    web_page: 'http://www.utad-petel-edu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa07cWLWxgoVc3m6nskZrYF3TOf6z2psxoLGAy5EdOLIUc4I4J1P9huqc',
    id: 3746,
    acronym: 'UTAD',
  },
  {
    alpha_two_code: 'GY',
    country: 'Guyana',
    domain: 'gemsvilleuniversity.com',
    name: 'Gemsville Technical University',
    web_page: 'http://www.gemsvilleuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2ZATDOHt6UPDmU-njJWtkLKMHOtJ89wQWB_I92hRh_Y7bDqC1kC10MsE',
    id: 3747,
    acronym: 'GTU',
  },
  {
    alpha_two_code: 'GY',
    country: 'Guyana',
    domain: 'greenheartmed.org',
    name: 'Greenheart Medical School',
    web_page: 'http://www.greenheartmed.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Ro4Tc_Ro49jzRp_K2Ioq1bNTUM_PUo2z9EaLI2jDDljaCx63cDBhzA',
    id: 3748,
    acronym: 'GMS',
  },
  {
    alpha_two_code: 'GY',
    country: 'Guyana',
    domain: 'tauedu.org',
    name: 'Texila American University',
    web_page: 'http://www.tauedu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRM8Otvo6NwAWhCle1ireibBr8E7LTf22GsCG58uPXZIy0796Es0EjJyv4K',
    id: 3749,
    acronym: 'TAU',
  },
  {
    alpha_two_code: 'GY',
    country: 'Guyana',
    domain: 'uog.edu.gy',
    name: 'University of Guyana',
    web_page: 'http://www.uog.edu.gy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwnDKrVUZ3wa8Y0kKU-3Btj1k6C-xj1BNp0oiZmUGcHuR4mu9A_3Z73NM',
    id: 3750,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'HT',
    country: 'Haiti',
    domain: 'auchaiti.org',
    name: 'American University of the Caribbean',
    web_page: 'http://www.auchaiti.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJZi4ZaMgQ293U6cNdT4cYfhqHbIDwYUYFkqMJitBblLOD-mP07Y2E2ro',
    id: 3751,
    acronym: 'AUC',
  },
  {
    alpha_two_code: 'HT',
    country: 'Haiti',
    domain: 'ucnh.org',
    name: "Université Chrétienne du Nord d'Haiti",
    web_page: 'http://www.ucnh.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmvKq3qowUZxXza0GWcuKOjgxcbHpdtTk_mw74sJTlyn4pzH7sfd3Znw',
    id: 3752,
    acronym: 'UCDND',
  },
  {
    alpha_two_code: 'HT',
    country: 'Haiti',
    domain: 'ueh.edu.ht',
    name: "Université d'Etat d'Haiti",
    web_page: 'http://www.ueh.edu.ht/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXtBuKlnBm00aQiR4JEDix2y10b4jY5Ve4_NTcEx57uD8_CVkVLKDLPwNT',
    id: 3753,
    acronym: 'UDD',
  },
  {
    alpha_two_code: 'HT',
    country: 'Haiti',
    domain: 'undh.org',
    name: "Université Notre Dame d'Haïti",
    web_page: 'http://www.undh.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiF_JiWRPCdOD05YcrvJ5qRzuV7zKDH6ydiGOle69F2aboRgZtrFMb5Eg',
    id: 3754,
    acronym: 'UNDD',
  },
  {
    alpha_two_code: 'HT',
    country: 'Haiti',
    domain: 'uneph.org',
    name: "Université Épiscopale d'Haiti",
    web_page: 'http://www.uneph.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsTLo-KNgay74L_fdahIE8aqzeCgxxvhZ51gxIVINoujPnAy1HUbzy-sg',
    id: 3755,
    acronym: 'UÉD',
  },
  {
    alpha_two_code: 'HT',
    country: 'Haiti',
    domain: 'uniq.edu',
    name: 'Université Quisqueya',
    web_page: 'http://www.uniq.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZlfj_vb0Y2UmZiipzMuUoid5LdRROjT-JQe7aDI1hxuiR6PYIzW0Vx8Q',
    id: 3756,
    acronym: 'UQ',
  },
  {
    alpha_two_code: 'VA',
    country: 'Holy See (Vatican City State)',
    domain: 'pul.it',
    name: 'Pontifcia Universitas Lateranensis',
    web_page: 'http://www.pul.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRozUc37ftR81ze3guEAxnt0KTXSOIDcPrRpKpLcZmMjvVhxn4VFUyTfLY',
    id: 3757,
    acronym: 'PUL',
  },
  {
    alpha_two_code: 'VA',
    country: 'Holy See (Vatican City State)',
    domain: 'pust.urbe.it',
    name: 'Pontifcia Universitas a S.Thomas Aquinate in Urbe',
    web_page: 'http://www.pust.urbe.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScmM41eurI8oOx6OKeXQLnxPO78N7ui_SLMgHP5Ld9o6pGuJ069GSJXI8',
    id: 3758,
    acronym: 'PUASAIU',
  },
  {
    alpha_two_code: 'VA',
    country: 'Holy See (Vatican City State)',
    domain: 'unigre.urbe.it',
    name: 'Pontifcia Università Gregoriana',
    web_page: 'http://www.unigre.urbe.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfwjMnqrsa57GIDDsbSzVt4Kv6-dKW2Tze3UwDlDZdaJRfMC0CyGOr_BuQbQ',
    id: 3759,
    acronym: 'PUG',
  },
  {
    alpha_two_code: 'VA',
    country: 'Holy See (Vatican City State)',
    domain: 'upra.org',
    name: 'Athenaeum Pontificium Regina Apostolorum',
    web_page: 'http://www.upra.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCsr6jdwc0AhjMa5EEobMWJJjgsj7Axoud8aXjztu7IgeV7sN2hlsSfFs',
    id: 3760,
    acronym: 'APRA',
  },
  {
    alpha_two_code: 'VA',
    country: 'Holy See (Vatican City State)',
    domain: 'ups.urbe.it',
    name: 'Università Pontifcia Salesiana',
    web_page: 'http://www.ups.urbe.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgleBJbo3pEiZbyLtRQkVGbCp99tiCzfKEgz3VVIebySsnJjK5-Q-3VBO3',
    id: 3761,
    acronym: 'UPS',
  },
  {
    alpha_two_code: 'VA',
    country: 'Holy See (Vatican City State)',
    domain: 'urbaniana.edu',
    name: 'Pontifcia Università Urbaniana',
    web_page: 'http://www.urbaniana.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAocoNCHnSaXzuAz5w9ktcdPRt_sSCSQNaK3JzZ_oQLVnsx4XR_LmHUx0',
    id: 3762,
    acronym: 'PUU',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'ujcv.edu.hn',
    name: 'Universidad José Cecilio del Valle',
    web_page: 'http://www.ujcv.edu.hn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM0nkhulRnPlf8LMb1ONNeg6WvKeXr65dQAanZERyqNsm4nQoENImJHg',
    id: 3763,
    acronym: 'UJCDV',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'unah.edu.hn',
    name: 'Universidad Nacional Autónoma de Honduras',
    web_page: 'http://www.unah.edu.hn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGyIbJElqncoI8PYN59cJIFatu-Hvrez6LS9hkLikRTu0y82_bjwHqDg',
    id: 3764,
    acronym: 'UNADH',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'unicah.edu',
    name: 'Universidad Católica de Honduras',
    web_page: 'http://www.unicah.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSU1JWQZ7vJa2-zTJlUvUs7JWz9VWqJkoTrgg1XcnarrTEfnFn287aoIA',
    id: 3765,
    acronym: 'UCDH',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'unimetro.edu.hn',
    name: 'Universidad Metropolitana de Honduras',
    web_page: 'http://www.unimetro.edu.hn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFcDKgrtnZByy8aqWLPE97BHplpU_xZvvm5KTE1LB4RHB_2xA_PJnAECE',
    id: 3766,
    acronym: 'UMDH',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'unitec.edu',
    name: 'Universidad Tecnológica Centroamericana',
    web_page: 'http://www.unitec.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbWKifATEE3J3xEeFEroNAAbtFXhq0rZz-vc47ydBS9t1sy0UfQ2Vrkw',
    id: 3767,
    acronym: 'UTC',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'upnfm.edu.hn',
    name: 'Universidad Pedagógica Nacional "Francisco Morazán"',
    web_page: 'http://www.upnfm.edu.hn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6AW0itWZP7lxC44tah3tUmGf7LMdqURbTsMNCQTR5yOEqivUlEgX_NbI',
    id: 3768,
    acronym: 'UPN"M',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'usps.edu.hn',
    name: 'Universidad de San Pedro Sula',
    web_page: 'http://www.usps.edu.hn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkcH9arxs1PUqAiNI-c3TohdYFoGMmpPTHtmkq_u5BmqFRD_EVbcmhjA',
    id: 3769,
    acronym: 'UDSPS',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'uth.hn',
    name: 'Universidad Tecnológica de Honduras',
    web_page: 'http://www.uth.hn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT516LRnmybN9Y-IwA7HGLBq3AD2Q8rbrCK2MKysj0zIrDrqh_omSworp8',
    id: 3770,
    acronym: 'UTDH',
  },
  {
    alpha_two_code: 'HN',
    country: 'Honduras',
    domain: 'zamorano.edu',
    name: 'Escuela Agricola Panamericana Zamorano',
    web_page: 'http://www.zamorano.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFCkE-7zapJmBrgyNfJdirVeTafeQ1BW6YbPItY4mA4IR__6CwhmnmlXo',
    id: 3771,
    acronym: 'EAPZ',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'chuhai.edu.hk',
    name: 'Chu Hai College',
    web_page: 'http://www.chuhai.edu.hk/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcT080jFQ00Tl3UpSDRWyX6C9_AlgHbtFeneco6i6bmYXOjj0kwbuqfdsV8',
    id: 3772,
    acronym: 'CHC',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'cityu.edu.hk',
    name: 'City University of Hong Kong',
    web_page: 'http://www.cityu.edu.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_jmifEOzFmptKc34up0_KMbUxlMbJDhj_89XHawXFZV5tgPllLSRTMg',
    id: 3773,
    acronym: 'CUHK',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'cuhk.hk',
    name: 'Chinese University of Hong Kong',
    web_page: 'http://www.cuhk.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwjEQjRLFS0ZvGnh-8B8xYTVBgCg41Qq3GP6GgjV4GPEp7svSgXYS6kbiV',
    id: 3774,
    acronym: 'CUHK',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'hkapa.edu',
    name: 'Hong Kong Academy for Performing Arts',
    web_page: 'http://www.hkapa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVkvsn__IQpvlBsDxgexLiikX92WaCCzMiXhUSs2-7Upw89HVXErYGWCM',
    id: 3775,
    acronym: 'HKAFPA',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'hkbu.edu.hk',
    name: 'Hong Kong Baptist University',
    web_page: 'http://www.hkbu.edu.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJugz1lowa46EJ3xABCUoaLJeADnF-QFP2RZ8uyzu82jS7EaoU5_UcrA',
    id: 3776,
    acronym: 'HKBU',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'hksyc.edu',
    name: 'Hong Kong Shue Yan College',
    web_page: 'http://www.hksyc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRXVMDvDMfWYCJhKTLjtcdITz_8ILa_2zKOyjc79MaBqXx8QRhTNbyLj4',
    id: 3777,
    acronym: 'HKSYC',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'hku.hk',
    name: 'University of Hong Kong',
    web_page: 'http://www.hku.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFE-x13JURt4hwqwtUWkf2MwJ51c4gO9N8MuW61Yu8Z2jfLcj1WQVDPAQ',
    id: 3778,
    acronym: 'UHK',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'ied.edu.hk',
    name: 'Hong Kong Institute of Education',
    web_page: 'http://www.ied.edu.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi4qNy33woXLD6PkrthbwNPtjM43YbCCoJdr2nYo8lqfsX5i7Yvwp9Og',
    id: 3779,
    acronym: 'HKIE',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'ln.edu.hk',
    name: 'Lingnan University',
    web_page: 'http://www.ln.edu.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiKx6LosAh1_4tqNz9QJ7iDxMbct6Zk7qlVm_6gqiE7QxQfy5sKNhhFgU',
    id: 3780,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'ouhk.edu.hk',
    name: 'Open University of Hong Kong',
    web_page: 'http://www.ouhk.edu.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAmieMA6Eev_ZKgwDP_L3cVOUHS3OqURRhWGiL5JskmtpXZTXUs8W34w',
    id: 3781,
    acronym: 'OUHK',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'polyu.edu.hk',
    name: 'Hong Kong Polytechnic University',
    web_page: 'http://www.polyu.edu.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkD19SSXDpBKnhV7xHpSTkmm2Yf1TDH2t5ykfMnjscvgnP_yW3aqqkoA',
    id: 3782,
    acronym: 'HKPU',
  },
  {
    alpha_two_code: 'HK',
    country: 'Hong Kong',
    domain: 'ust.hk',
    name: 'Hong Kong University of Science and Technology',
    web_page: 'http://www.ust.hk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6QS3bToFjgaK2q6wb5l-4oUpJko6fKtRcxdgZ6nl810O53JyZIJWJLw',
    id: 3783,
    acronym: 'HKUST',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'agr.unideb.hu',
    name: 'Debrecen University of Agricultural Sciences',
    web_page: 'http://www.agr.unideb.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReAsQP_wTQs8aUtER_iktgFBNW7yvCZrLGin58U8J4aR9GWi4ndAUrm2i4',
    id: 3784,
    acronym: 'DUAS',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'andrassyuni.hu',
    name: 'Andrassy Gyula German Speaking University Budapest',
    web_page: 'http://www.andrassyuni.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4GXTiwUy2iuXO9Y1coTAY9Sr2QLohpeq_SmsUWQILTp3blhVQEx7hghn1',
    id: 3785,
    acronym: 'AGGSUB',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'arts7.hu',
    name: 'Hungarian Academy of Fine Arts Budapest',
    web_page: 'http://www.arts7.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUVd6UYt5jLXTCwbFyIMZ7AbucZpd9zbyTkTO7s1TZcYdtUmL968ga29g',
    id: 3786,
    acronym: 'HAFAB',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'avicenna.hu',
    name: 'Avicenna International College',
    web_page: 'http://www.avicenna.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbnYJ4I2F8PnCvemFQ2g_pb-iS_WHXw0VwDvapdNNzhnkE9V2C12L0KA',
    id: 3787,
    acronym: 'AIC',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'bdtf.hu',
    name: 'Dániel Berzsenyi Teacher Training College',
    web_page: 'http://www.bdtf.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxHGMAac9XzOxqgiQtqFW2xMKpX3_QbQJgfRd8sB_WXZTllcp053aekx8',
    id: 3788,
    acronym: 'DBTTC',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'bke.hu',
    name: 'Budapest University of Economic Sciences and Public Administration',
    web_page: 'http://www.bke.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfYt8G0tb0OW_rESGqfG7cvIwRbt8NL_wUIzQCHjqlKxGEIue3HnbPrQ',
    id: 3789,
    acronym: 'BUESPA',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'bme.hu',
    name: 'Technical University of Budapest',
    web_page: 'http://www.bme.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcoooZ08IopuvIKrFkpFHCI4ovtEJYLTGdXnJqNZxThwkgHBEAmI7HHEmG',
    id: 3790,
    acronym: 'TUB',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'ceu.hu',
    name: 'Central European University',
    web_page: 'http://www.ceu.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwqbzuXIgGhU50giLDyyzBqyOMXQGir3SUfa0rcEjJ4ensA31DceIVRAi9',
    id: 3791,
    acronym: 'CEU',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'dote.hu',
    name: 'University Medical School of Debrecen',
    web_page: 'http://www.dote.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfFObLqeFzZU_fyITfYSUdvNlxwwu-4yoDF1ozp-q5ynYGKAx0vxsAW1uEsQ',
    id: 3792,
    acronym: 'UMSD',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'drk.hu',
    name: 'Reformed Theological Academy of Debrecen',
    web_page: 'http://www.drk.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOL6WuA8ifUK7ISz_96OUQhgIFgXwEqJIxbb_dlXIGkjkz7IRjlvzNY2c',
    id: 3793,
    acronym: 'RTAD',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'duf.hu',
    name: 'College of Dunaujvaros',
    web_page: 'http://www.duf.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-dThP0j3aXA5dEo_Pi2w5HAjAdcZjkUAD5z9BrmjopoOFKZeH-r6c2Q',
    id: 3794,
    acronym: 'CD',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'efe.hu',
    name: 'University of Sopron',
    web_page: 'http://www.efe.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrlXK_IoaMe3fLYfVUYri_Dk1ZnpCnj_HeRDq9I3MzFGki3TJj9Blm8Q',
    id: 3795,
    acronym: 'US',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'ehf.hu',
    name: 'Esztergom Theological College',
    web_page: 'http://www.ehf.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMMZKZOzmD42V7p2U9mQvKpLd-oowe6Yc3v_tLEY8vD1KmDHFRYAMHmQ',
    id: 3796,
    acronym: 'ETC',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'elte.hu',
    name: 'Eötvös Lorand University',
    web_page: 'http://www.elte.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiAnzt4JqEI6uQNeI_MPgLSwoVBRtqi4FBvF3vzTdYHGv-vOvrlZw28e0',
    id: 3797,
    acronym: 'ELU',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'filmacademy.hu',
    name: 'Academy of Drama and Film',
    web_page: 'http://www.filmacademy.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHW0WntePxBwKDt084Dn45-z98ZgcJees0CpPp5WL3Vgs7SakJOFPjrw',
    id: 3798,
    acronym: 'ADF',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'hiete.hu',
    name: 'Haynal Imre University of Health Sciences Budapest',
    web_page: 'http://www.hiete.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPDzOWipscvOK4pRT0nU49vynFhFPZCWjgl_Pj-gzski3bzdtH7edEFSM',
    id: 3799,
    acronym: 'HIUHSB',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'hupe.hu',
    name: 'Hungarian University of Physical Education',
    web_page: 'http://www.hupe.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIa_KGB5TRjEJXvnrg28pTCInUmOlgQVhRwSFF0T86DUwGXIZAKPFNjKo',
    id: 3800,
    acronym: 'HUPE',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'jpte.hu',
    name: 'Janus Pannonius University',
    web_page: 'http://www.jpte.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7CsHju7e6XsOUJnfiWDcVGhXu6n1hXPgnaHXDaMJhdA6R6AN976Yv8kI',
    id: 3801,
    acronym: 'JPU',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'kee.hu',
    name: 'University of Horticulture and Food Industry',
    web_page: 'http://www.kee.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4RyFfRoSaDeQysVTQrKtkcdsArsNxmh2vZKKKG0ttpMarMmLWpTyo8g',
    id: 3802,
    acronym: 'UHFI',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'kgre.hu',
    name: 'Károl Gáspár University of the Reformed Church',
    web_page: 'http://www.kgre.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7u7HiQoyL_esTtqTP1TIdgOC_veProP78Yac61N-UMsBhdqJnrGTPgfBhKA',
    id: 3803,
    acronym: 'KGURC',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'kodolanyi.hu',
    name: 'Kodolanyi Janos University College',
    web_page: 'http://www.kodolanyi.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvLevO8MlZ8sttAoSQ894SLPG29x_XC5_m51ZrQ-eNLz5OqoUELM998ZA',
    id: 3804,
    acronym: 'KJUC',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'lfze.hu',
    name: 'Liszt Ferenc Academy of Music Budapest',
    web_page: 'http://www.lfze.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnrU71rp5JVhDoBWTitPrD3SZulrmKMyPecyOIE4A8sYxPMDIRsQBQZJ0',
    id: 3805,
    acronym: 'LFAMB',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'lib.klte.hu',
    name: 'Kossuth Lajos University',
    web_page: 'http://www.lib.klte.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR_ucQVS1_eeBw7W0C7y23LsYSFDuOU3niR1nf7HQR_lePdsVwSKvEAw',
    id: 3806,
    acronym: 'KLU',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'mif.hu',
    name: 'Hungarian Academy of Craft and Design',
    web_page: 'http://www.mif.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTskvNPYYGqDI5cEuwn8uj0utGQfOtCFStP5Z-j18ZVGOY7cBIO5YXzYqY',
    id: 3807,
    acronym: 'HACD',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'nyme.hu',
    name: 'University of West Hungary',
    web_page: 'http://www.nyme.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs9CHfDkLERI0zb04EmA1GJvhIlecwJPIQCHJiLMsfazBh2MOpClro_A',
    id: 3808,
    acronym: 'UWH',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'pote.hu',
    name: 'University Medical School of Pécs',
    web_page: 'http://www.pote.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1Bve9J4F_lHw35qXLanJwo9__Unpwq7u20qh93lkQkwXMymgyMHZpOw',
    id: 3809,
    acronym: 'UMSP',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'ppke.hu',
    name: 'Pázmány Péter Catholic University',
    web_page: 'http://www.ppke.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA0YZ2JaZBST1J444OAOLhyXrkZLcXLWPVBj09iyrrcaERxtaP4RC2lU0',
    id: 3810,
    acronym: 'PPCU',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'pte.hu',
    name: 'University of Pecs',
    web_page: 'http://www.pte.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXuepkdk4nhre31R2c3oHnO0MJi6xAaLhJPeXDFipTFpFuOOmewLkrdW3L',
    id: 3811,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'sote.hu',
    name: 'Semmelweis University of Medical Sciences',
    web_page: 'http://www.sote.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzmO07rfAvzPZEa1NQqoTWAePqSAyRSwR_ZICGTl-bNw1wUB_2FQEb4jY',
    id: 3812,
    acronym: 'SUMS',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'sze.hu',
    name: 'Séchenyi István University',
    web_page: 'http://www.sze.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHIFc5vjkeA7GXDIuD2yk6x6SOpTt_E4ahIcWFPMu2tGlG9C1DuqMQyrk',
    id: 3813,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'szie.hu',
    name: 'Szent István University',
    web_page: 'http://www.szie.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTRN3EWfO1o2wfT4x4GE2bMQstk5TCKj0UDLQ4KhO2X0Ae9T6kT12GV18',
    id: 3814,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'tkbf.hu',
    name: 'Budapest Buddhist University',
    web_page: 'http://www.tkbf.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiAnkBp-6ambxlwjAzArr8DNfwJoesEfcT3raEdMqHoU-jF-YbtE8eWNB3',
    id: 3815,
    acronym: 'BBU',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'unideb.hu',
    name: 'University of Debrecen',
    web_page: 'http://www.unideb.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR_ucQVS1_eeBw7W0C7y23LsYSFDuOU3niR1nf7HQR_lePdsVwSKvEAw',
    id: 3816,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'uni-miskolc.hu',
    name: 'University of Miskolc',
    web_page: 'http://www.uni-miskolc.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwzp183sbFlzkXLoP6zT8G_cdp5JZrYogSrLj6pMxiWwVnqXC9ALwzUMoo',
    id: 3817,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'uni-pannon.hu',
    name: 'University of Pannonia',
    web_page: 'http://www.uni-pannon.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI-DcCdfzfS9XKZvaGgcIPfcl-ZAS9BSWesxFjbBItX_FX2EqKLGXiLRya',
    id: 3818,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'univet.hu',
    name: 'University of Veterinary Science',
    web_page: 'http://www.univet.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVTWNCurRKhtMlf-DZGlxtzN9V3--45svHkf-SoMRMqGfkBzk7qhJ0JYk',
    id: 3819,
    acronym: 'UVS',
  },
  {
    alpha_two_code: 'HU',
    country: 'Hungary',
    domain: 'u-szeged.hu',
    name: 'University of Szeged',
    web_page: 'http://www.u-szeged.hu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaD7ALOdQTOw1mAYEbXN39t8vkakEUb2lEQN6xmbibttcXKo0ICA0mjQ',
    id: 3820,
    acronym: 'US',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'bifrost.is',
    name: 'Bifrost School of Business',
    web_page: 'http://www.bifrost.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYQbPVZ0ZLcmccFvH7bR3I_voowhbC1lCpvhrwxVvouuTbv5Rqnn1juA',
    id: 3821,
    acronym: 'BSB',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'hi.is',
    name: 'University of Iceland',
    web_page: 'http://www.hi.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLrrSWUJVQ5oeOpVBbYkYyTyzN_YiQR2d3dQlvJUOtLCnS7nyOulv8hw',
    id: 3822,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'holar.is',
    name: 'Holar University College',
    web_page: 'http://www.holar.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm5VE5Yu5AsSCDK2qx-7G6o0pauj9fuDlvCSyK7LCEHxobfAcVRdOt2A',
    id: 3823,
    acronym: 'HUC',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'hsvest.is',
    name: 'University Centre of the Westfjords',
    web_page: 'http://www.hsvest.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT401Odpms1JeU1pTxzgwNoEpIMJU1bhvJWslvdsF_4nTVecVgxFH4b2I0Y',
    id: 3824,
    acronym: 'UCW',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'hvanneyri.is',
    name: 'Hvanneyri Agricultural University',
    web_page: 'http://www.hvanneyri.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnvtEFTFIRPAfwmn1hEuHXuYqf0n1qrxg32G__sqao75nqQn98XM8YHVg',
    id: 3825,
    acronym: 'HAU',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'iti.is',
    name: 'Technological University of Iceland',
    web_page: 'http://www.iti.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHUAJA9lnRqYRHYrddbLVLHEUEMYmTVWPzy3IRbrZvHsOsaazlF7ZamA',
    id: 3826,
    acronym: 'TUI',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'khi.is',
    name: 'Iceland University of Education',
    web_page: 'http://www.khi.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0FcLhcq7FhUIPw1HFwc2t4fgQKKif03ypM3PEbww5OEUcDolvVnEh5Q',
    id: 3827,
    acronym: 'IUE',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'lhi.is',
    name: 'Iceland Academy of the Arts',
    web_page: 'http://www.lhi.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbXSZAy_eXlAzFa9DHfWwxf1k1tV6uVSBW4ucR2ywg8kwzvRQmrVmgbQ',
    id: 3828,
    acronym: 'IAA',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'ru.is',
    name: 'Reykjavík University',
    web_page: 'http://www.ru.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_BpfnLkHZ5s_7p5zfk_soIUavexLMnqOfNz52EnrWUSd4zOIxpo7d9QbQ-A',
    id: 3829,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'IS',
    country: 'Iceland',
    domain: 'unak.is',
    name: 'University of Akureyri',
    web_page: 'http://www.unak.is/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQATQ61SlUmAEqNMbBls-SIyOUogmF8WjPgKQGhyVzvgsui8PgulKeHXA',
    id: 3830,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: '59.163.116.210',
    name: 'University of Health Sciences Andhra Pradesh',
    web_page: 'http://59.163.116.210/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTx2na2u_r0JIlCAUrKEOvJroqokPltWwo6xeSrS8YUs-ZYOEjaAKl9g',
    id: 3831,
    acronym: 'UHSAP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'aaidu.org',
    name: 'Allahabad Agricultural Institute Deemed University',
    web_page: 'http://www.aaidu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR95TkqWBZRj4DGNhllLgsZBUgaPU3qtVrgajQvAqHyV0-k03FLG9MUQw9I',
    id: 3832,
    acronym: 'AAIDU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'aau.ac.in',
    name: 'Assam Agricultural University',
    web_page: 'http://www.aau.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbNBp2ZBhdbg2CANmYmQZ5USOngNOM-30WELakkMPZo91gNKhDpmA1vWc',
    id: 3833,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ahduni.edu.in',
    name: 'Ahmedabad University',
    web_page: 'http://www.ahduni.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_kcd4fu0KJUDRUgyyHvcYMaOxPsc1P-Ie_MF-oC3tpGS-_cEBqXI6TjE',
    id: 3834,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'aiims.ac.in',
    name: 'All India Institute of Medical Sciences',
    web_page: 'http://www.aiims.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOJrgwssaEhwSlE7Kh0ijZbsRkcF_rSkCNtiFmI669NP8hXElfiuCWweDq',
    id: 3835,
    acronym: 'AIIMS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'aisectuniversity.ac.in',
    name: 'AISECT University',
    web_page: 'http://www.aisectuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQYhyBliFcRCBqMF78DGc2AYMmXqjKHHW20cBeT-d7CoQIvE87ULz7fg',
    id: 3836,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'alagappauniversity.ac.in',
    name: 'Alagappa University',
    web_page: 'http://www.alagappauniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc3pft1i8paskB4liBU9xn__P55dMLfdnUZgYnxs6SV2KxMUie_mQoPnc',
    id: 3837,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'alldunivpio.org',
    name: 'Allahabad University',
    web_page: 'http://www.alldunivpio.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaOvTxUGr911bHoUHl-tjkf8RkjwKmi39meBQb4JQNjIT3eQup2X7CcA',
    id: 3838,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'altmeduniversity.com',
    name: 'Open International University for Alternative Medicines',
    web_page: 'http://www.altmeduniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHQJR5Ep3XIJzZyuEIe3d2QXF-NJ8D6mv7DrKyRlg4pSi3YfhAE6tJpUyn',
    id: 3839,
    acronym: 'OIUFAM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'altmedworld.net',
    name: 'Indian Board of Alternative Medicine',
    web_page: 'http://www.altmedworld.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC0pPj0YAPmSS6CZuFOSxM8M5p8mHaZ7u73BT_qnTx184toRIZDpYFJw',
    id: 3840,
    acronym: 'IBAM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'amity.edu',
    name: 'Amity University',
    web_page: 'http://www.amity.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJxdTTOsYGjLVRU-zMKcWmfbilarEkfomPv6SCa0uAIJ1zsBVGIaGG1g',
    id: 3841,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'amrita.edu',
    name: 'Amrita Vishwa Vidyapeetham (Deemed University)',
    web_page: 'http://www.amrita.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZt4GK4y9bGpfV4zlKGkr_yB3xG1JX1y3Rlq4pDGXABo3Sandz7IE8Ks0',
    id: 3842,
    acronym: 'AVV(U',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'amtuni.com',
    name: 'Amravati University',
    web_page: 'http://www.amtuni.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT44BZM0fGbI1-5v1Sw4Ndw1Bto6LZg310iWMxmKkBEIyTRGBmGy8hTTlkI',
    id: 3843,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'amu.ac.in',
    name: 'Aligarh Muslim University',
    web_page: 'http://www.amu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS2EtyxqWAPB-NLPDN3phcYuO2MghLWJvnuCYTvr3NvrquUehqpk3Iyq8',
    id: 3844,
    acronym: 'AMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'andhrauniversity.info',
    name: 'Andhra University',
    web_page: 'http://www.andhrauniversity.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjyYlM-kMYpXIr93O656bpG1qQqRLoCyULNvJx9WrMov23NoH4SziOBeU',
    id: 3845,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'angrau.net',
    name: 'Acharya Ranga Agricultural University',
    web_page: 'http://www.angrau.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJTfQ66_2O1RZnuQLY4BJu2a2cqOSMmW4bRJ3znam7wKaAeOu250zwjkA',
    id: 3846,
    acronym: 'ARAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'annamalaiuniversity.ac.in',
    name: 'Annamalai University',
    web_page: 'http://www.annamalaiuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLq6e8tXKzSeptSlyBAANx-aV0nL8sNXeVkHn_phbzu9WNqdaSmk_Hcg',
    id: 3847,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'annauniv.edu.in',
    name: 'Anna University of Technology Tirunelveli',
    web_page: 'http://www.annauniv.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuwzZsBYY3_yrtQYO4Ve2b_gH8lyRhdi-KmJopVvKN8NKAljni0oxMcA',
    id: 3848,
    acronym: 'AUTT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'annauniv.edu',
    name: 'Anna University',
    web_page: 'http://www.annauniv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuwzZsBYY3_yrtQYO4Ve2b_gH8lyRhdi-KmJopVvKN8NKAljni0oxMcA',
    id: 3849,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'apsurewa.nic.in',
    name: 'Awadhesh Pratap Singh University',
    web_page: 'http://www.apsurewa.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeUUQxkcusStJsBCzf9JSNMFr_NZt4hXpDdSttf2DrbgP2sfoex1JsBg',
    id: 3850,
    acronym: 'APSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'aryainstitutejpr.com',
    name: 'ARYA Institute of Engineering & Technology',
    web_page: 'http://www.aryainstitutejpr.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVRbbgc4jmUiiD7KCFd62dtqeHhNiikopRjhXN4mLc8gby9H7CwHdI0wY',
    id: 3851,
    acronym: 'AIE&T',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'assamuniversity.nic.in',
    name: 'Assam University',
    web_page: 'http://www.assamuniversity.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9_pnMc1wcodv5H10XgISLF6dRmuSkpWDFaHRGXqBTcKH6oAywxs0Oqg',
    id: 3852,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ayurveduniversity.com',
    name: 'Gujarat Ayurved University',
    web_page: 'http://www.ayurveduniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUc9wsUgJNo6Xtrhh42wpz8xt7Md73EIVt_eS1NPi3XA1GcvUCcTmxUQ',
    id: 3853,
    acronym: 'GAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bamu.net',
    name: 'Dr. Babasaheb Ambedkar Marathwada Universtiy',
    web_page: 'http://www.bamu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3yV2oh0n1LCzCv70FtCJncO5eqp1A3diVVcaAdoN5vFTUkxPOI5S1IQ',
    id: 3854,
    acronym: 'DBAMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bamu.nic.in',
    name: 'Berhampur University',
    web_page: 'http://www.bamu.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDpw1_ddzKkTURetx1j6EelK0el7FJF6lbj1_QNmMiLOxmTZ-HE1sNhmU',
    id: 3855,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'banasthali.org',
    name: 'Banasthali University',
    web_page: 'http://www.banasthali.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgCyZ1ibz34C0k0lADk7ZKtpahHI8WAhIMUEaa8pG375XxmlhK5xpeBg',
    id: 3856,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bankurauniv.com',
    name: 'Bankura University',
    web_page: 'http://www.bankurauniv.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkzHPxIi1mL8Y3nSakmNMYW3Xg5QQWWC5O_eIggGWmU-WtpMt85ITYBL0',
    id: 3857,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bau.nic.in',
    name: 'Birsa Agricultural University',
    web_page: 'http://www.bau.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBwAyDBsuTK997sEg62MyhmPnwh46_w6eTZT3MPo2qgrtIsTIQELb8NQ',
    id: 3858,
    acronym: 'BAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bbauindia.org',
    name: 'Babasaheb Bhimrao Ambedkar University',
    web_page: 'http://www.bbauindia.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqeXrSNBAq56iU2_BCABVAvtZnZ6hMavkLr4HMJF-VvNCGNJdwepOG0YU',
    id: 3859,
    acronym: 'BBAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bckv.edu.in',
    name: 'Bidhan Chandra Agricultural University',
    web_page: 'http://www.bckv.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5pj-f9fiRI2UdOdx1UFTJYKXSqWDawx1jFaAC1lRZ754bY7RkcsHSldDP',
    id: 3860,
    acronym: 'BCAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bdu.ernet.in',
    name: 'Bharathidasan University',
    web_page: 'http://www.bdu.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVdVqme088gpRpxLwFngUXgY5yVSMlIgMsLbaE-dryDBYdJtvbB8UmwpTg',
    id: 3861,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'becs.ac.in',
    name: 'Bengal Engineering College',
    web_page: 'http://www.becs.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvd4oAE1LSX3nFji0yuQN8Kapj-G1hGc1MHvtVFZ-0OPtiZqtrGiLC4A',
    id: 3862,
    acronym: 'BEC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bengaluruuniversity.com',
    name: 'Bangalore University',
    web_page: 'http://bengaluruuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-Cce5WZRJuKFtiiC4QEtYtqqP1E7hPNJ87kJudEzjPsXsZ5rPHGyUbA',
    id: 3863,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bhagwantuniversity.com',
    name: 'Bhagwant University',
    web_page: 'http://www.bhagwantuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZi0kq_EnuSnVfKNhIs425I5sUjgjS2nL8-rB5jqK0fPsiv7UtFLql0ksf',
    id: 3864,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bharathi.ernet.in',
    name: 'Bharathiar University',
    web_page: 'http://www.bharathi.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRplKgbn7iVRX_M3oIik_C9LARKx2RzYVNR6ebhOH-3i8OLvGXivsDMUKc',
    id: 3865,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bharathuniv.com',
    name: 'Bharath Institue Of Higher Education & Research',
    web_page: 'http://www.bharathuniv.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtOv-O7_SGbcdf9HbfdnJjpRNjK5CX_ix8IscFoLmSEF7MRJiEaEDA2g',
    id: 3866,
    acronym: 'BIHE&R',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bharatividyapeeth.edu',
    name: 'Bharati Vidyapeeth University',
    web_page: 'http://www.bharatividyapeeth.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhCkxyDWEkfGrEiGoA-zwPd1kKmCaeBqb0Ii1GYu_Tyd0J7U2XR-m917I',
    id: 3867,
    acronym: 'BVU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bhavuni.edu',
    name: 'Bhavnagar University',
    web_page: 'http://www.bhavuni.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGgKcN-30Gjp_tMpuEjM_dN4LuwR8F-kqvp5ZCLaOyBSVc2fH3T5h1Uzo',
    id: 3868,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bhu.ac.in',
    name: 'Banaras Hindu University',
    web_page: 'http://www.bhu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-GYvuzL-1LLag6hQw4I9KuCMzivCzkrasK_eddCwFCaNLNy5rBQGLIJ4',
    id: 3869,
    acronym: 'BHU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bhub.ori.nic.in',
    name: 'Orissa University of Agriculture and Technology',
    web_page: 'http://bhub.ori.nic.in/ouat/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ49kBtd53g9n0L9rPpvG5T55msaibvCjj9RG20ZR2YAPgS9byMTNlN4w',
    id: 3870,
    acronym: 'OUAT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bitmesraranchi.org',
    name: 'Birla Institute of Technology Ranchi',
    web_page: 'http://bitmesraranchi.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv_fNWW2f93L56s1ejKTubXWQrxRhxgAUfvb4DVJ8NHjnuanAO42-Ng4k',
    id: 3871,
    acronym: 'BITR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bits-pilani.ac.in',
    name: 'Birla Institute of Technology and Science',
    web_page: 'http://www.bits-pilani.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRTzU4AT1iOjZBjAR1kDLg548-cR0Xch_-t6UHRm6XhGdZnosFbQPRfjw',
    id: 3872,
    acronym: 'BITS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bnmu.in',
    name: 'Bhupendra Narayan Mandal University',
    web_page: 'http://www.bnmu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRls6af_HTbbePF-4urq_uOOKbqrQ43mjsn1QvHBGYU0ISOmxVkLp8WSA',
    id: 3873,
    acronym: 'BNMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bput.org',
    name: 'Biju Pattnaik University of Technology',
    web_page: 'http://www.bput.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbN_lXe0GI1Z9j2jtHKC0T_TptfgTwXvEnkr2man_sh_1zEb0_SLV51g',
    id: 3874,
    acronym: 'BPUT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'brabu.net',
    name: 'B. R. Ambedkar Bihar University',
    web_page: 'http://www.brabu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqeXrSNBAq56iU2_BCABVAvtZnZ6hMavkLr4HMJF-VvNCGNJdwepOG0YU',
    id: 3875,
    acronym: 'BRABU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'braou.ac.in',
    name: 'Dr. B.R. Ambedkar Open University',
    web_page: 'http://www.braou.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY3sckvGRRki7LA-fIk4ZYxzzquCd4eLYrJks_JlpCfq1CQzx3QjfFHME',
    id: 3876,
    acronym: 'DBAOU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitj.ac.in',
    name: 'Dr. B. R. Ambedkar National Institute of Technology Jalandhar',
    web_page: 'http://www.nitj.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_ufYG3wjSoQPUr6_YuaMPOD8DsrR08MfFVkfhaiLz23Aok3cKlQJ9jzQ',
    id: 3877,
    acronym: 'DBRANITJ',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'brauagra.com',
    name: 'Dr. Bhim Rao Abdekar University',
    web_page: 'http://www.brauagra.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYmUZsCXIf_AS0Shwz0c6c3bBwYld9UAiLsPuE7O0XmSpxlbRoei75TQ',
    id: 3878,
    acronym: 'DBRAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bsauniv.ac.in',
    name: 'B. S. Abdur Rahman University',
    web_page: 'http://www.bsauniv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTazqhOf79k4e8TVFGuIdKnLWKJh0QJhlwW-I-IY1lGmEI2F3XFJpSByBE',
    id: 3879,
    acronym: 'BSARU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bubhopal.nic.in',
    name: 'Barkatullah University',
    web_page: 'http://www.bubhopal.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQz9gZlPkxBljhJ6cnauJo11APUFx0ldvBPotpg6HcGEjVo3EC9-IXPkQ',
    id: 3880,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'bundelkhanduniversity.org',
    name: 'Bundelkhand University',
    web_page: 'http://www.bundelkhanduniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFiH5Uir_zilvirOCfP7QuPi79gUGTuhpIYIq4zq3fnCqFQvcK1zKi71s',
    id: 3881,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'burdwanuniversity.ac.in',
    name: 'University of Burdwan',
    web_page: 'http://www.burdwanuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoH35LrVcciC0C2CKn5ow66ApAGCsCN6rNe5gQydzqk8rY-zz2kz4JBg',
    id: 3882,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'caluniv.ac.in',
    name: 'University of Calcutta',
    web_page: 'http://www.caluniv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqlLgOegUy5VY7zJjDxdCusV7L2lbAyWLPJZULgnjnz57iOuDz-o0lQIs',
    id: 3883,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'cau.org.in',
    name: 'Central Agricultural University',
    web_page: 'http://www.cau.org.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbkHUyuNX8gj6pbTVHbIcN9Sm4352VwyB3tjFxuw118rEXWZ_pV1U6d9Q',
    id: 3884,
    acronym: 'CAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ccsuniversity.ac.in',
    name: 'Chaudhary Charan Singh University',
    web_page: 'http://www.ccsuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfOfypF20C3UGRHiHSjxT3_ZzMfOK4oq3rBNUYTaGzit6HHwyPQdvipA',
    id: 3885,
    acronym: 'CCSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ccsuniversity.org',
    name: 'Ch. Charan Singh University',
    web_page: 'http://www.ccsuniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfOfypF20C3UGRHiHSjxT3_ZzMfOK4oq3rBNUYTaGzit6HHwyPQdvipA',
    id: 3886,
    acronym: 'CCSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'cesbm.ac.in',
    name: 'Center for Entrepreneurship and Small Business Management',
    web_page: 'http://www.cesbm.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMHy-Pv51Kyr_7lPl1d4hm_BILlOcXdUO7-cIAntN9Pc_Ea4tqdXehTQk',
    id: 3887,
    acronym: 'CFESBM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'christuniversity.in',
    name: 'Christ University',
    web_page: 'http://www.christuniversity.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb-Ax-gnfUlPjTCwgUbFnor5NYnDc5XVAs0MiYMGtjYU4PkJnUPexTJA',
    id: 3888,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ciefl.org',
    name: 'Central Institute of English and Foreign Languages',
    web_page: 'http://www.ciefl.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3eOPa7XvOnemyIGADNZQ0SsnC7XbeWQ347yWRS7bbWVzbHfBvpOZLAro',
    id: 3889,
    acronym: 'CIEFL',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'cife.edu.in',
    name: 'Central Institute of Fisheries Education',
    web_page: 'http://www.cife.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmHnq5X19dBNZQXLkYqFUHyqvhz6r2iKJdgBu8XgonPwlAL3KjAVchd2A',
    id: 3890,
    acronym: 'CIFE',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'cihts.ac.in',
    name: 'Central Institute of Higher Tibetan Studies',
    web_page: 'http://www.cihts.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGCHbTomYHRDQNBvg9DyKCoYrkcpAwcoYfybtbhjKidyAz49NKYpfg0w',
    id: 3891,
    acronym: 'CIHTS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'cmjuniversity.edu.in',
    name: 'CMJ University',
    web_page: 'http://www.cmjuniversity.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2GpSyhZMjyZRkjKEtxURozGwqlksM1ssYF-OJ5XithT0ad9y8B6f8vFA',
    id: 3892,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'csauk.ac.in',
    name: 'Chandra Shekhar Azad University of Agriculture and Technology',
    web_page: 'http://www.csauk.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu_H0l-Qv8HjuH4x0dlw7BfZXVES0dpzTvsTakXXOtbnt2jPnXPJWRquY',
    id: 3893,
    acronym: 'CSAUAT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'cusat.ac.in',
    name: 'Cochin University of Science and Technology',
    web_page: 'http://www.cusat.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDNERBNgu0tCtuejcrzTQrqk3801OxojzkP4237QV0bYOiIro1X6Xb9c_e6w',
    id: 3894,
    acronym: 'CUST',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'cvru.ac.in',
    name: 'Dr. C.V. Raman University',
    web_page: 'http://www.cvru.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX2ywYcRiYGEY_1VYVphj6RRV_keBafzvWGxARirJskRkqKaU14fvaaL8',
    id: 3895,
    acronym: 'DCRU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'da-iict.org',
    name: 'Dhirubhai Ambani Institute of Information and Communication Technology',
    web_page: 'http://www.da-iict.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlAKGcLxze6ir2YTOZZCZpkFmPvSJ1PLauf4rgiXrAZzDSa5NC0PZQ7w',
    id: 3896,
    acronym: 'DAIICT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'darulhuda.com',
    name: 'Darul Huda Islamic University',
    web_page: 'http://www.darulhuda.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfe8in5fpFq1U0o055ixF8HbZn4tTGJTKDWyZief_McnDkHtfm7UL7KTs4',
    id: 3897,
    acronym: 'DHIU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'davuniversity.org',
    name: 'Dav University',
    web_page: 'http://www.davuniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7U_-cGFeknrtqpf-1PUiIdgFA0t9hsAXXFZLwrRQRAlmf4BRgWUE2cQ',
    id: 3898,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'davv.ac.in',
    name: 'Devi Ahilya University of Indore',
    web_page: 'http://www.davv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS--5vXDcF-iZL_JxDPPPj-ssnNJrsQF11olKqopm5tyCaP9dbv55tF5dRujQ',
    id: 3899,
    acronym: 'DAUI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'dbatechuni.org',
    name: 'Dr. Babasaheb Ambedkar Technological University',
    web_page: 'http://www.dbatechuni.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV5UvBJQ2vtSoTD-rVT8XYq85wKNN4O24tmsiDYDzrM9xg2PleRBpbkM-Z',
    id: 3900,
    acronym: 'DBATU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'dbskkv.org',
    name: 'Konkan Agricultural University',
    web_page: 'http://www.dbskkv.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeJW6-6GyKcAFv6CszfdV5fX19ON7CwAHRl56sU7kfua2ZpcgCl-0mFdw',
    id: 3901,
    acronym: 'KAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'dbs.tn.nic.in',
    name: 'Tamil Nadu Agricultural University',
    web_page: 'http://dbs.tn.nic.in/tnau/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzPG0DgOfpnII7ey4wthWhqAPogqiTRChRqr2duIyjxk5BcrMuh11yAqJl',
    id: 3902,
    acronym: 'TNAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'dceonline.net',
    name: 'Delhi College of Engineering (DCE)',
    web_page: 'http://www.dceonline.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRJpFAsaVjVQYx0xuze_8tf8yLWK_FZsf8eM0HisSHagsvdn02ayCscQ',
    id: 3903,
    acronym: 'DCE(',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ddugu.edu.in',
    name: 'Deen Dayal Upadhyay Gorakhpur University',
    web_page: 'http://www.ddugu.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvG0bYabtky-gyLYB1VYlK06QgvYDm4cRmUiZaqwZDPfMrbnhNkiovVA',
    id: 3904,
    acronym: 'DDUGU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'deccancollegepune.ac.in',
    name: 'Deccan College Postgraduate and Research Institute',
    web_page: 'http://www.deccancollegepune.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWitZCSQdfOU9g7O8HiFmfDYtwIUq09af_Qsyo9-KXrEKUnvMBYqcEsTd7',
    id: 3905,
    acronym: 'DCPRI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'dei.ac.in',
    name: 'Dayalbagh Educational Institute',
    web_page: 'http://www.dei.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkBQT7ONrENWzzOipNAzKgpJBQtEKDktJ0kgTNUKBE_RLNcQ5bSJ-nqg',
    id: 3906,
    acronym: 'DEI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'dibru.ernet.in',
    name: 'Dibrugarh University',
    web_page: 'http://www.dibru.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiRClLObcDe73zoR5iBFUx1QIBPQjgKY0BhlcegS3FNN7lp6zG6GBI1I4',
    id: 3907,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'dravidianuniversity.ac.in',
    name: 'Dravidian University',
    web_page: 'http://www.dravidianuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfNB_AVSqCg06drf2EtCK3iGN3FEkUbe6EN7I7YZjMuCk1hD5-6Oyqdp4',
    id: 3908,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'du.ac.in',
    name: 'University of Delhi',
    web_page: 'http://www.du.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV6TKfLNX0GVXbd6nzi974MYl7m9KdyRP0pBayMLvb2alvszHXfU2Tihs',
    id: 3909,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'dypatiluniversity.org',
    name: 'Dr. D.Y. Patil University',
    web_page: 'http://www.dypatiluniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgkLlP3qIraSqqVCtYfghUSqs-r4uevCF-2neiY8a_uOgOi8hShHiwp9U',
    id: 3910,
    acronym: 'DDPU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'eiilmuniversity.ac.in',
    name: 'Eiilm University',
    web_page: 'http://www.eiilmuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1b32RtkKIBdOJqviFZEaYxo0BLTIlZ_DVdKJxyFAS-he_tMD6WeElRA',
    id: 3911,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'fatimacollege.net',
    name: 'Fatima mata national college kollam kerala',
    web_page: 'http://www.fatimacollege.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDnkwt8MeGrOu7Cv-188nWXAUDS3IVLmWZTX1eHQrxWgDpgirJyBD-kyC6',
    id: 3912,
    acronym: 'FMNCKK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'fri.icfre.gov.in',
    name: 'Forest Research Institute Dehradun',
    web_page: 'http://fri.icfre.gov.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR85ylHV1s_BSXKCvC94yDeXfyVIqiU2uex3Y0rv-WrKGtg1QhIe1Dl34Xa',
    id: 3913,
    acronym: 'FRID',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gbpuat.ac.in',
    name: 'Govind Ballabh Pant University of Agriculture and Technology',
    web_page: 'http://www.gbpuat.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrIEWUicHM6Ds3tQlutcviV_fOu1JC811yNyk5jPnugF9A-52gmgh_D8A_eA',
    id: 3914,
    acronym: 'GBPUAT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ggu.ac.in',
    name: 'Guru Ghasidas University',
    web_page: 'http://www.ggu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwdjdVSCzcHVZEPbGnDCxb7RthqWAb8bfH6SKFGnJvsRPAV5JYN_2Af7w',
    id: 3915,
    acronym: 'GGU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gipe.ernet.in',
    name: 'Gokhale Institute of Politics and Economics',
    web_page: 'http://www.gipe.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlzpCzAcoF-TRMjr1AGFbWxGKVaQfY6kSFDvo3wB0c29ovXQjNmyLQew',
    id: 3916,
    acronym: 'GIPE',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gitam.edu',
    name: 'Gandhi Institute of Technology and Managment',
    web_page: 'http://www.gitam.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9y5mH96r5ex_nMH73oFNRSY7nrMGf_woiLlytVfVurTXA9U5M8REsmA',
    id: 3917,
    acronym: 'GITM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gju.ernet.in',
    name: 'Guru Jambeshwar University',
    web_page: 'http://www.gju.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyGT2p6oZEXqXc-bSD7Fggs7jdbPT5k8l8O55WPywKR-Oye3YWIWh0nw',
    id: 3918,
    acronym: 'GJU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gkvharidwar.org',
    name: 'Gurukula Kangri University',
    web_page: 'http://www.gkvharidwar.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ78lY6Ao0O0g_wNLfAospePEQvtUdc_ppO_d3fT2PkqhDJGXQqwC-nvOg',
    id: 3919,
    acronym: 'GKU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gnduonline.org',
    name: 'Guru Nanak Dev University',
    web_page: 'http://www.gnduonline.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAtxZ0L047D7-3f25-a6pbtunS_AeVZ7VQJJTPVKGcUU29rZLNZu6e9oOm5Q',
    id: 3920,
    acronym: 'GNDU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'government.ind.in',
    name: 'Global Open University  Nagaland',
    web_page: 'http://www.government.ind.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSfDmusFLSkxpdQleGMKs3C3X18_n6xlpF7_k_bJkj5g-JYlrPWVqlBA',
    id: 3921,
    acronym: 'GOUN',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gtu.ac.in',
    name: 'Gujarat Technological University Ahmedabad',
    web_page: 'http://www.gtu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCPcwdZJI7kZtw8wz1lmcs1jlCC7vFVfBs9i3vzTBywI-Z74d-1ELyGrU',
    id: 3922,
    acronym: 'GTUA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gujaratuniversity.org.in',
    name: 'Gujarat University Ahmedabad',
    web_page: 'http://www.gujaratuniversity.org.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMpUeIF3ILPaZqjztZ8VI6ML1iqtSNpNhMTf5S7KAgBNeDD0AnrHSi2w',
    id: 3923,
    acronym: 'GUA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gulbargauniversity.kar.nic.in',
    name: 'Gulbarga University',
    web_page: 'http://www.gulbargauniversity.kar.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQfjBZLDB-S2G26Bqe3PwWPpX16rXX_wjIZYD6qgpf_w24ysdzwKWBPA',
    id: 3924,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gu.nic.in',
    name: 'Gauhati University',
    web_page: 'http://www.gu.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDUeY1qqSBTJmY8KNBQ0fUD9RKrbp4FQaRsGlgWMYIMGBRiCaQwfFyYQ',
    id: 3925,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'gurukuluniversity.org',
    name: 'Gurukul University',
    web_page: 'http://www.gurukuluniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9a8OFvaA1tFEml9pVK6oIVzyki367NO88poDoJKP8LzzFJu_UQzad0xU',
    id: 3926,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'hau.ernet.in',
    name: 'CCS Haryana Agricultural University',
    web_page: 'http://hau.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfOfypF20C3UGRHiHSjxT3_ZzMfOK4oq3rBNUYTaGzit6HHwyPQdvipA',
    id: 3927,
    acronym: 'CHAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'hau.ernet.in',
    name: 'Chaudhary Charan Singh Haryana Agricultural University',
    web_page: 'http://www.hau.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfOfypF20C3UGRHiHSjxT3_ZzMfOK4oq3rBNUYTaGzit6HHwyPQdvipA',
    id: 3928,
    acronym: 'CCSHAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'hillagric.ernet.in',
    name: 'Himachal Pradesh Agricultural University',
    web_page: 'http://hillagric.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG5HTpVUe6vX2ecNW31GACQgeefTYNsQ16YSA7iOzmYN6-KKcQt4Rkcw',
    id: 3929,
    acronym: 'HPAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'hnbgu.ac.in',
    name: 'Hemwati Nandan Bahuguna Garhwal University',
    web_page: 'http://www.hnbgu.ac.in/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcR1gGls1CheJK0V5v7yLc0l6ofSTABaRm_2YkbVuB4kDfysuaJa7Uuung',
    id: 3930,
    acronym: 'HNBGU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'hnluraipur.com',
    name: 'Hidayatullah National Law University Raipur',
    web_page: 'http://www.hnluraipur.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHzMIUgfzRxE66hqC2ZPejjSs6t2N5ljb2z4AG9qbvQQhrkx9vYK2DRDN7',
    id: 3931,
    acronym: 'HNLUR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'hostindia.com',
    name: 'Sri Ramachardra Medical College and Research Institute',
    web_page: 'http://www.hostindia.com/srmc/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIhA5ow_hOrDiuMvIG0Yoj1PW2m-S91wNR1mA7lAfGwjkHGXkWYbNQz1A',
    id: 3932,
    acronym: 'SRMCRI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'hpuniv.nic.in',
    name: 'Himachal Pradesh University',
    web_page: 'http://hpuniv.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGiktAEZYwnce_CWyHlSE0bsIbOa12_lePutPtFtaNDJaHwWzGMrv9uvQ',
    id: 3933,
    acronym: 'HPU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iari.ernet.in',
    name: 'Indian Agricultural Research Institute',
    web_page: 'http://www.iari.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-GfcV5s1TfvpM_kUGUu7Hc1NA2eQimCDHW5nOfUFw25dAQgs7C-NSsQ',
    id: 3934,
    acronym: 'IARI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iaseduniv.org',
    name: 'IASE (Institute of Advanced Studies) Deemed University',
    web_page: 'http://www.iaseduniv.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAqw7YZ-xnPB9rpXvUoqNgcMHRDF2joZf1fiVt-CN2wp36ViZTR5CXxJKb',
    id: 3935,
    acronym: 'I(ASDU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'icfai.org',
    name: 'ICFAI University',
    web_page: 'http://www.icfai.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHyZ4zOuvPZop166kQ52HyeWqhxwoyaq441QQEInMm2XLe_-VC0WSUSw',
    id: 3936,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'igau.edu.in',
    name: 'Indira Gandhi Agricultural University',
    web_page: 'http://www.igau.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTABlTTA0M_43_5L0SDQtUwCwmkLcamNNhCssBfa6tvNWPPycQp_ykAcg',
    id: 3937,
    acronym: 'IGAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'igidr.ac.in',
    name: 'Indira Gandhi Institute of Development and Research',
    web_page: 'http://www.igidr.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLCtIxImw9yZozZTibys3drj6mzLgk6Fnp2ziatRpZWUI_bJXNOIpdyDA',
    id: 3938,
    acronym: 'IGIDR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'igims.org',
    name: 'Indira Gandhi Institute of Medical Sciences',
    web_page: 'http://www.igims.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfGDFDLOvWqD_ORMI0JRWGfP211FOqlO-dRZfP1Fde06z-V98Bt0baIg',
    id: 3939,
    acronym: 'IGIMS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ignou.ac.in',
    name: 'Indira Gandhi National Open University',
    web_page: 'http://www.ignou.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsfxUajZBcHBfSazx4u4M5Umw3d_BVJ8LuziWBjkaup_KXl4_rfiyBdMg',
    id: 3940,
    acronym: 'IGNOU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiita.com',
    name: 'Indian Institute of Information Technology',
    web_page: 'http://www.iiita.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB-BwACaC_Mn8b9cDkh3hpIaivYUweO9KF8uVDd5qwShLvherimyaqzQ',
    id: 3941,
    acronym: 'IIIT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiitmk.ac.in',
    name: 'Indian Institute of Information Technology and Management - Kerala',
    web_page: 'http://www.iiitmk.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJbe8XfaVCLM7Xe2fGiZ-WSPFzlWxrPvpmcM1A7jXH-8LOgZuVFPctBA',
    id: 3942,
    acronym: 'IIITM-K',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiitm.ac.in',
    name: 'Indian Institute of Information Technology and Management - Gwalior',
    web_page: 'http://www.iiitm.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi-p_F7mgAYqOLZghaijyhGHC5OBMEEHpOy5i22xsANLqL_muTgBsWByCy',
    id: 3943,
    acronym: 'IIITM-G',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiitd.ac.in',
    name: 'Indraprastha Institute of Information Technology Delhi',
    web_page: 'http://www.iiitd.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjVJPYuaN4I6Kpl-Hwn4akbqx3jeo-gYNl4qjL2inRUUZ1rOBFtJ1W1Vg',
    id: 3944,
    acronym: 'IIITD',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'squareit.ac.in',
    name: 'International Institute of Information Technology Pune',
    web_page: 'www.isquareit.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROSxM8tDa5xx0MnD5vrED58KtuFBhLZkVegdSC28E426wsAkcpQu_3uN0',
    id: 3945,
    acronym: 'IIITP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiit-bh.ac.in',
    name: 'International Institute of Information Technology Bhubaneswar',
    web_page: 'www.iiit-bh.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEuNs3LPJgW7TBN6zZldlO4g9jO4JqCYCrekB7m3r09TLqF4oFCs6qzj8',
    id: 3946,
    acronym: 'IIITB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiitb.ac.in',
    name: 'International Institute of Information Technology Bangalore',
    web_page: 'www.iiitb.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR75SCb4Bm6sHRgUnzeK5CNxQUFGHalnX-H6PIUf0fOpla1qNt6e6EgXQ',
    id: 3947,
    acronym: 'IIITB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iimtrichy.ac.in',
    name: 'Indian Institute of Management Tiruchirappalli',
    web_page: 'http://www.iimtrichy.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7mlH8KIbDaAI59lIDEdwWo9uIeTaMlUN0BAQXn7X4CpF7aD9MbqQty5ug',
    id: 3948,
    acronym: 'IIMT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiitg.ac.in',
    name: 'Indian Institute of Information Technology Guwahati',
    web_page: 'http://www.iimtrichy.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB-BwACaC_Mn8b9cDkh3hpIaivYUweO9KF8uVDd5qwShLvherimyaqzQ',
    id: 3949,
    acronym: 'IIITG',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiitvadodara.ac.in/',
    name: 'Indian Institute of Information Technology Vadodara',
    web_page: 'http://iiitvadodara.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs5Z66MKIsW69xasAhkzscE_C21sitcWO08-uhOqIREUCwCss51mxvu3JWeg',
    id: 3950,
    acronym: 'IIITV',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiitkota.ac.in/',
    name: 'Indian Institute of Information Technology Kota',
    web_page: 'http://iiitkota.ac.in/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcT6o-VrRDoVKB4SYXRZq0Peaxv-SlKu4BVPgJVznrzmQ9sWhTCAafhUivg',
    id: 3951,
    acronym: 'IIITK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iipsindia.org',
    name: 'International Institute for Population Sciences',
    web_page: 'http://www.iipsindia.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWpT6ArkLAbRYm-iB1pA_52xzr3utibD8j9KUjw39hTaHjY7o7EUA2NQ',
    id: 3952,
    acronym: 'IIFPS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iisc.ernet.in',
    name: 'Indian Institute of Science',
    web_page: 'http://www.iisc.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgSsJNJ_arCnzRSwkxcjG4-zQIJbcWW4vNEGVtuJFQNEEfo9V2HD2Z3r8',
    id: 3953,
    acronym: 'IIS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitb.ac.in',
    name: 'Indian Institute of Technology Bombay',
    web_page: 'http://www.iitb.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSno_LGiJo0rL0Nj6C1H2th5euNyacFfL0AWPywf_x_FYbMeKQjkCRgpBw',
    id: 3954,
    acronym: 'IITB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitd.ernet.in',
    name: 'Indian Institute of Technology Delhi',
    web_page: 'http://www.iitd.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT46vpcqbM3GjvEiP29nfKf8mKRWYP2zLoRPVnSzoxRqrgGhv0dx0JrNTT-mw',
    id: 3955,
    acronym: 'IITD',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitg.ernet.in',
    name: 'Indian Institute of Technology Guwahati',
    web_page: 'http://www.iitg.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6o5nBRAXXPnyn52lzbsDQ3mIFr-P8MnfDm-PPgwuovaWYkCHuaEGc1w',
    id: 3956,
    acronym: 'IITG',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iith.ac.in',
    name: 'Indian Institute of Technology Hyderabad',
    web_page: 'http://www.iith.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmWFIGhPPRmU3Ava9F6M_ediLUD2WrZ3qnw6BzDe7KQ0kNCKwY3cGTGw',
    id: 3957,
    acronym: 'IITH',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitk.ac.in',
    name: 'Indian Institute of Technology Kanpur',
    web_page: 'http://www.iitk.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsdnw-kADSZj97KzdNwzePIOiw12P0uWoBXWqpchYg5kxfkfRwd1JGFA',
    id: 3958,
    acronym: 'IITK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitkgp.ernet.in',
    name: 'Indian Institute of Technology Kharagpur',
    web_page: 'http://www.iitkgp.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5hCjGAhsvyTEjmMnL1algevOF-0ojH4jdgDcUpvJGoPp6DNRSBnwZVISC4A',
    id: 3959,
    acronym: 'IITK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitm.ac.in',
    name: 'Indian Institute of Technology Madras',
    web_page: 'http://www.iitm.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsmGPSiIie6SgcoLLvZIOGHAepQHoNrptJbeuhE_wQw93D37mJtS8AceZ_CA',
    id: 3960,
    acronym: 'IITM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitr.ac.in',
    name: 'Indian Institute of Technology Roorkee',
    web_page: 'http://www.iitr.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjmYDWw83PZ4BatC4uhFS4YzUbAuh0IGxacnBGioZICXLJUUw9r1x79w',
    id: 3961,
    acronym: 'IITR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitp.ac.in',
    name: 'Indian Institute of Technology Patna',
    web_page: 'http://www.iitp.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR--tWR8fmwsk5un0g7XPB2-uf7d27UJpCGdwxSuiuf0Rjo8NhkD-SwMak',
    id: 3962,
    acronym: 'IITP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iiti.ac.in',
    name: 'Indian Institute of Technology Indore',
    web_page: 'http://www.iiti.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyRDfc0K1RS35dyF7DYcryEl3JTH7Ev_8pK5WrmTDphVL64nmf2hXUHQ',
    id: 3963,
    acronym: 'IITI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iitj.ac.in',
    name: 'Indian Institute of Technology Jodhpur',
    web_page: 'http://www.iitj.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp5obNlZCl-PDFsStLP8V71i1q47YHYPw3rrb5bZkAvVB1ALy_xz397lGI',
    id: 3966,
    acronym: 'IITJ',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iksvv.com',
    name: 'University of Music and Fine Arts',
    web_page: 'http://www.iksvv.com/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQa_VCQljzJwoPUK7fQmHUlnEFeTg7xGVKw_2gRnoiwyionOCgvbYzRc7M',
    id: 3967,
    acronym: 'UMFA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ilidelhi.org',
    name: 'Indian Law Institue',
    web_page: 'http://www.ilidelhi.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4C6gpyghQg0EuefnTHkVZGJ8PeRIOaud4QXcWsSsdqmx3wkbLte97_g',
    id: 3968,
    acronym: 'ILI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'integraluniversity.ac.in',
    name: 'Integral University',
    web_page: 'http://www.integraluniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgUfkerRdTPObvrbXXBDhM9gXb6aAW76Ho0zNdSLD2UvYjFRy2kEuFLw',
    id: 3969,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ipu.ac.in',
    name: 'Guru Gobind Singh Indraprastha University',
    web_page: 'http://www.ipu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYfryVJ_8YtHQfRmSlZNv1VfD2AfXlTTK0uShOgbysFY55wxwP7aIVGA',
    id: 3970,
    acronym: 'GGSIU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'isbm.org.in',
    name: 'Indian School of Business Management and Administration',
    web_page: 'http://isbm.org.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiUoJ1SX3pjGrUYLyoI-X1RkuxMuzrn-4bTkRDD52F4HxeMgtudbqHNQw',
    id: 3971,
    acronym: 'ISBMA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'isical.ac.in',
    name: 'Indian Statistical Institute',
    web_page: 'http://www.isical.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjZTJgcDBAe7XNUCVgIn1YoztnDdWu6mKzqPO1LxG6Q6V_jcoSKvL8GA',
    id: 3972,
    acronym: 'ISI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ismdhanbad.ac.in',
    name: 'Indian School of Mines (ISM)',
    web_page: 'http://www.ismdhanbad.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkYFEgwbtDB74JA5aLI66E675kF-lnZ3VzDhoKiVCICu3qDQSF4UD_iEs',
    id: 3973,
    acronym: 'ISM(',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'itmindia.edu',
    name: 'Institute of Technology and Management',
    web_page: 'http://www.itmindia.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9y5mH96r5ex_nMH73oFNRSY7nrMGf_woiLlytVfVurTXA9U5M8REsmA',
    id: 3974,
    acronym: 'ITM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'iur.ac.in',
    name: 'Indian University',
    web_page: 'http://www.iur.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR81HlRWGZpkeDTbnVn3fN1myqGiODgL3m2gtrOUmOnUUP6Cv_Zo0pbIXHSfw',
    id: 3975,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ivri.nic.in',
    name: 'Indian Veterinary Research Institute',
    web_page: 'http://www.ivri.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5B8aIpCO8N0FM_mBuagNRtgV9lzkayCsvpxSpZPt3VQwtgRAdFM3U04Y',
    id: 3976,
    acronym: 'IVRI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jadavpur.edu',
    name: 'Jadavpur University',
    web_page: 'http://www.jadavpur.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSv_l5V_W7KBJU1nz8clREmWRewvJnfCOOLYJkE4n_yxxSePSuV4BB3NEA',
    id: 3977,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jainuniversity.org',
    name: 'First Global University to teaching Jainism',
    web_page: 'http://www.jainuniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVnQWXTSbtT2vklFPUZuf0X0QWWmxjIeOJvBuu3qQX32ngQ-JJtgbQ40k',
    id: 3978,
    acronym: 'FGUTTJ',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jamiahamdard.edu',
    name: 'Jamia Hamdard University',
    web_page: 'http://www.jamiahamdard.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn88HygJVtFfH6Et-GIqiW2BSORk6igmzqcRMzjvlKSMvyK_Fhm6R-aHMlwQ',
    id: 3979,
    acronym: 'JHU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jammuvarsity.org',
    name: 'University of Jammu',
    web_page: 'http://www.jammuvarsity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvFp-dpBGroqLS5EKmbntQRr7QxJBdreFFWKP1tUKnPCtrEz7G_fYgIA',
    id: 3980,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jiit.ac.in',
    name: 'Jaypee Institute of Information Technology',
    web_page: 'http://www.jiit.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7h_slUBizON1_-Dk5_wQGXxYAd9zJdSLmOkXxzJW7ZlM75-wf3xz8BA',
    id: 3981,
    acronym: 'JIIT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jiwaji.edu',
    name: 'Jiwaji University',
    web_page: 'http://www.jiwaji.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5OFaqEL8s9RYLCZqpKYTnjUW5czOmzuRJke0NPurjROirYhgjXZOjsA',
    id: 3982,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jmi.nic.in',
    name: 'Jamia Millia Islamia University',
    web_page: 'http://jmi.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa88g5IRDfl61Em0RmCFJNXR91aQbRZD2urPpVW4rQHVjFWjFokfCAxRuB',
    id: 3983,
    acronym: 'JMIU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jncasr.ac.in',
    name: 'Jawaharlal Nehru Centre for Advanced  Scientific Research',
    web_page: 'http://www.jncasr.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8DljMTFiVnI_dS_efBNjWJobAzM27XBmOkRZPYmnWjLE5xsg7JOQ0iw',
    id: 3984,
    acronym: 'JNCFASR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jnkvv.nic.in',
    name: 'Jawaharlal Nehru Agricultural University',
    web_page: 'http://www.jnkvv.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQwnevkbuMKZjG-hYmrN8tjOU4RrrQV1qiugFfMQSxPgP7c2ybutIHRg',
    id: 3985,
    acronym: 'JNAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jntu.ac.in',
    name: 'Jawaharlal Nehru Technological University',
    web_page: 'http://www.jntu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5kAwXm0vkss0elYs_y35n6pzjdCGNDawMA2Nj7_b_KQ8gIBvoIjSRA9c',
    id: 3986,
    acronym: 'JNTU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jnu.ac.in',
    name: 'Jawaharlal Nehru University',
    web_page: 'http://www.jnu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVNyJkwXCafGki_CA6QLYo6c-sfsr8w4xTYlU52ETLsMItMWL85n_ymw',
    id: 3987,
    acronym: 'JNU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jnujaipur.ac.in',
    name: 'Jaipur National University',
    web_page: 'http://www.jnujaipur.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtVcKgdpNRqXHwYymYm9zVgHiKycBg0cYXg7Zk4gRcyRbISROkFOEiVx8',
    id: 3988,
    acronym: 'JNU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jodhpurnationaluniversity.com',
    name: 'Jodhpur National University',
    web_page: 'http://www.jodhpurnationaluniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGPV5bZuyT--Y5ZBWFJNzNNYRWQ9RQXq9amsBSkLqS0awvDdhVBVDyWw',
    id: 3989,
    acronym: 'JNU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jpv.bih.nic.in',
    name: 'Jai Prakash University',
    web_page: 'http://jpv.bih.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSh43vXhuToq_qlFw5zWu9fceZsC9iSi3BrcbKwyyfblMc3x1RxQH3WWQ',
    id: 3990,
    acronym: 'JPU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jrnrvu.org',
    name: 'Rajasthan Vidyapeeth University',
    web_page: 'http://www.jrnrvu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-62zXjuB_SzlOFDK_fBc-G9vLqY5dY50G7Isw_beRineZGPCq20-f_Q',
    id: 3991,
    acronym: 'RVU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'jvbi.ac.in',
    name: 'Jain Vishva Bharati Institute',
    web_page: 'http://www.jvbi.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7jrQuZgF0tIOtrPYj9LYuYZRja36r8bRsvSpIAkRmno8ZcXKiGAErUdkL',
    id: 3992,
    acronym: 'JVBI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kalingauniversity.com',
    name: 'Kalinga University',
    web_page: 'http://www.kalingauniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRj6iw8efW_yBmMm99z9BvC4qloVslmSOdaRqc2W-MQqxlRs6ca5TaPcig',
    id: 3993,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kanchiuniv.ac.in',
    name: 'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya',
    web_page: 'http://www.kanchiuniv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVTxF9xk7rc--3OwgE6Iq9c9EigA1r5B3188iinvb85NwGSTyV1oXf5Q',
    id: 3994,
    acronym: 'SCSVM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kannadauniversity.org',
    name: 'Kannada University',
    web_page: 'http://www.kannadauniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTljR__w4d3LdkyZQPon39PHZvx0JW9vrL66xCc_VVIyPcY36A6xNGDX0c',
    id: 3995,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kannuruniversity.ac.in',
    name: 'Kannur University',
    web_page: 'http://www.kannuruniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNjtkoTfu1StDAj_0WpCXvjFmXuzTHKI1FbHw7iB3OBhTj6f3-iQKAtVc',
    id: 3996,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kanpuruniversity.org',
    name: 'Chhatrapati Shahu Ji Maharaj University',
    web_page: 'http://www.kanpuruniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTou2Mq9nGjWw18qw_MghQJEVP8vdVNpBROp8uZbTyeI88BxQvn9qKqg',
    id: 3997,
    acronym: 'CSJMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kanpuruniversity.org',
    name: 'Kanpur University',
    web_page: 'http://www.kanpuruniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_BI_OI852xNBf8isgkvzP-gohbwuYJXdSLqMf7LAyMokAA_qZgTsy7g',
    id: 3998,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'karnatakastateopenuniversity.in',
    name: 'Karnataka State Open University',
    web_page: 'http://www.karnatakastateopenuniversity.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRb8YwKpdQ3jqMyP3BHl1NY1NoqhleaNsl8ewIdEz8DuCKgbKJpKRAfdfk',
    id: 3999,
    acronym: 'KSOU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'karpagamuniv.com',
    name: 'Karpagam University',
    web_page: 'http://www.karpagamuniv.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyTudgeqidR9yfsNAtVzLKqUtTAHKkUvgWMMDXyWFgdbUBMpTydHmOyWEJ',
    id: 4000,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'karunya.edu',
    name: 'Karunya Institute Of Technology ( Deemed University)',
    web_page: 'http://www.karunya.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgxJc4t3CBiGtV7vZ00Ch6xisYrkT8OpzsqCkGQS2eYvMguN0MOlCBzUEo',
    id: 4001,
    acronym: 'KIT(DU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kashmiruniversity.net',
    name: 'University of Kashmir',
    web_page: 'http://kashmiruniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS4xPkW-mhS_WnTGWpVWudd8S0Tw0N5-Zd_r0Uit8ZxSAi92X36MJusA',
    id: 4002,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kau.edu',
    name: 'Kerala Agricultural University',
    web_page: 'http://www.kau.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgust6k7lV6PNOgqFgQtIDnodaqmKKDGH83xVQax6lfpACsIjw2KeUYA',
    id: 4003,
    acronym: 'KAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'keralauniversity.ac.in',
    name: 'University of Kerala',
    web_page: 'http://www.keralauniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB48oY7IRaDlNSptegYbJkH5obuAQUJuH1llDPdd0LdKGUbYFlQpS7Xxk',
    id: 4004,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kimi.kohinoor.ac.in',
    name: 'Kohinoor School of Hospitality Management',
    web_page: 'http://kimi.kohinoor.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCAS_vnVfDoV7YUsFaFyYl2zEu4dUd6IUsQ91ImVs6g9sblpZqG3f-wg',
    id: 4005,
    acronym: 'KSHM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'klyuniv.ac.in',
    name: 'University of Kalyani',
    web_page: 'http://www.klyuniv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZbzUFSE9l_MrICf0g3g8MVXcyIKBLqwRuKlxZSeV0VzYXJXuTAE0uL8Q',
    id: 4006,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'krishnauniversity.ac.in',
    name: 'Krishna University',
    web_page: 'http://www.krishnauniversity.ac.in/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRJSN48IzFlWsc-5fvnHE9YdXnQwvYjJFopof29zRDt8zxxpVz9NFcDYw',
    id: 4007,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ksdsu.edu.in',
    name: 'Kameshwar Singh Darbhanga Sanskrit University',
    web_page: 'http://www.ksdsu.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuNmDvQa6g34Wh2NLd4L-LmFu2a85_AeGI0XMdbyPuhO6LLQtkUYu2ak0',
    id: 4008,
    acronym: 'KSDSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kslu.ac.in',
    name: 'Karnataka State Law University',
    web_page: 'http://www.kslu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGXXwqjbHrTZkWIxUG2P4MQEeXTrLdcSpeMUizVRyS1zUHoS6UaJZhv-4L',
    id: 4009,
    acronym: 'KSLU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kud.ac.in',
    name: 'Karnatak University',
    web_page: 'http://www.kud.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTteFlITDil-e3J9qql7d8zx-YlrolDgUGcyeEvSWklkM0t1uuTLg3f30nTGg',
    id: 4010,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kufos.ac.in',
    name: 'Kerala University of Fisheries and Ocean Sciences',
    web_page: 'http://www.kufos.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB0Y3yM9ryx0YubWIkaMPZ4r9VBQI6l-QM_BoHd5uWrHSgXAnvjmpw5u4',
    id: 4011,
    acronym: 'KUFOS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kukinfo.com',
    name: 'Kurukshetra University',
    web_page: 'http://www.kukinfo.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl7HOx8Q59fATwN-Zc6DjnAFh00Qu2ji54VZbP2pOnQaZGVkHhaDzFQ9g',
    id: 4012,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kct.ac.in',
    name: 'Kumaraguru College of Technology',
    web_page: 'http://www.kct.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT428vs4os5NFHVwzfuWbgq4OAowCdUasq9QfQj682bRNCtJyTxvzdonw',
    id: 4013,
    acronym: 'KCT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kumaununiversity.org',
    name: 'Kumaun University',
    web_page: 'http://www.kumaununiversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU2VlwfjV-Qxz17AcdCyo-5GFyA61bLS-6Hn-o42GlvqGFVAj11870hA',
    id: 4014,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kuvempu.ac.in',
    name: 'Kuvempu University',
    web_page: 'http://www.kuvempu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFrZfcehfI5CyIuUtflV7NqSjjMRgQaQ-msnQDZ5uQBBL7q5p1vAWVLg',
    id: 4015,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'kuwarangal.com',
    name: 'Kakatiya University',
    web_page: 'http://www.kuwarangal.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnHqtzhdDmKkZzZALP_uozw-qPOng_eCg_QKPq6LqREf5tgB35g1zu0g',
    id: 4016,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ldceahd.org',
    name: 'L.D.College of Engineering',
    web_page: 'http://www.ldceahd.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8GYXwPJXxpOnxn2fgGqfwTLT_BZOmyHN3zvoOjBQ8tgyM6FffE0SEyQ',
    id: 4017,
    acronym: 'LE',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'lnipe.edu',
    name: 'Lakshmibai National Institute of Physical Education',
    web_page: 'http://www.lnipe.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPTrGPpRzaYc9XFGfJi-AhtHHnuDhul2DUawesiP5eKbdLogGGVdUUJ3k',
    id: 4018,
    acronym: 'LNIPE',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'lnmu.bih.nic.in',
    name: 'Lalit Narayan Mithila University',
    web_page: 'http://lnmu.bih.nic.in/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSGw5LqANY6r8sM6jGZdjg1pKhYsCvPhwMZSBxQpNKNjOsOLbNpj0R66Q',
    id: 4019,
    acronym: 'LNMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'lucknowuniv.org',
    name: 'University of Lucknow',
    web_page: 'http://www.lucknowuniv.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3ft4a-zypRTIc_gpIr4uRq7VZxItfdZXk3jHEoQQUFGUH2gwMzguPxw0',
    id: 4020,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'macfast.org',
    name: 'Mar Athanasios College for Advanced Studies',
    web_page: 'http://www.macfast.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEaY6bbHG_tZBQgVNWjCC30WG5NLaaI6W2UP2YuTVM2Ihx2tRFeTInjg',
    id: 4021,
    acronym: 'MACFAS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'magadhuniversity.org',
    name: 'Magadh University',
    web_page: 'http://www.magadhuniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR697otshIKQ8_1qQaxzAP2WIZ3YuzkB3PrABDDDtFPGLvmpcF9s9m1wNY',
    id: 4022,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mangaloreuniversity.ac.in',
    name: 'Mangalore University',
    web_page: 'http://www.mangaloreuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5PdB47vdOzVehmaCcmwIiXkGzvm_y9n2YLU_gJb_oUcatVZHx4PE2j_Y',
    id: 4023,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'manipal.edu',
    name: 'Manipal University',
    web_page: 'http://www.manipal.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYlHr0l6ibl8KplGFeNAbTTgaDkLsryA4yd0umUAErK1soYUSfCFrO7Dw',
    id: 4024,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'manipur.nic.in',
    name: 'Manipur University',
    web_page: 'http://www.manipur.nic.in/maniuniversity.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIr4RoxwOKCtMMKz0Js_Ryfze6tgn4B2JK2TB5UH2L6KMuQXKmEafI_g',
    id: 4025,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'manuu.ac.in',
    name: 'Moulana Azad national Urdu University',
    web_page: 'http://www.manuu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqvqLS7_zbut6fW41hE2Y3dIVT1gZqXqEZJFhMLU6kgjWzfc_u-QrnZg',
    id: 4026,
    acronym: 'MANUU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mcu.ac.in',
    name: 'Makanlal Chutur Vedi University',
    web_page: 'http://www.mcu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5Kqy2YoSN7XCgLa9puYsTDuJ9C082PhibBThzbSABlXI_OEndS-0HLLk',
    id: 4027,
    acronym: 'MCVU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mcu.ac.in',
    name: 'Makhanlal Chaturvedi National University of Journalism and Communication',
    web_page: 'http://www.mcu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpCfel1WX3j39BnJZcZAqsZQHTXhEkucKImOCzKbMSJ19kw95EN2yd59Y',
    id: 4028,
    acronym: 'MCNUJC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mdsuajmer.ac.in',
    name: 'Maharshi Dayanand Sarswati University Ajmer',
    web_page: 'http://www.mdsuajmer.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfQJz4QZ2hr_1ni1py3ljDI6MIzCQCTDr9XfLjF7i2WJMOkua1eENXA7c',
    id: 4029,
    acronym: 'MDSUA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mdurohtak.com',
    name: 'Maharishi Dayanand University Rohtak (Haryana )',
    web_page: 'http://www.mdurohtak.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiJB2_IHu0ynagQVdf7BlwpKy4iOsZN-Xxy43MmwRfo10cDvdoBRiq0g',
    id: 4030,
    acronym: 'MDUR()',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mgkvp.ac.in',
    name: 'Mahatma Gandhi Kashi Vidyapith',
    web_page: 'http://www.mgkvp.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrAjVnIfRLVui6YpLG7uexEaehRfflSpifelKrTaPbSM6tAgCrKU0j8Q',
    id: 4031,
    acronym: 'MGKV',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mgsubikaner.ac.in',
    name: 'Maharaja Ganga Singh University Bikaner',
    web_page: 'http://www.mgsubikaner.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQircDLhem3FDHSKarFCOg6lU_sgZQ91G57D10EPTFb3eJjNV4pxCa4aA',
    id: 4032,
    acronym: 'MGSUB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mgu.ac.in',
    name: 'Mahatma Gandhi University Kerala',
    web_page: 'http://www.mgu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4QsLrj2CvkHybFzWFavB72UfSm9J7S0ZVtrjF_L9UlmbO9PlGbJfaAg',
    id: 4033,
    acronym: 'MGUK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mjpru.ac.in',
    name: 'Mahatma Jyotiba Phule Rohilkhand University Bareilly',
    web_page: 'http://www.mjpru.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2qF4F7E6L0VMkVlbrGKwvESfEFaaNYTTh5eD11CpyYa_xclJeHeo7tPo',
    id: 4034,
    acronym: 'MJPRUB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mkuhyd.com',
    name: 'Madurai Kamaraj University',
    web_page: 'http://www.mkuhyd.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF7NXbSwTcVbFxTKn2fBWBNlO14LiOdX8uh7JRciX4Uxcqqdy-TTn97Ctg',
    id: 4035,
    acronym: 'MKU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mkv2.mah.nic.in',
    name: 'Marathwada Agricultural University',
    web_page: 'http://mkv2.mah.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTOK1gwFqmQnPb0sagi0pgCePkr-iqh1b-TdKMSlnF46XqJaTFar5j4A',
    id: 4036,
    acronym: 'MAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mlsu.ac.in',
    name: 'Mohan Lal Sukhadia University',
    web_page: 'http://www.mlsu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIAxQbKD_-tNjd6029tcj9v_CByxVOOlOvsqLVLGvh-iJgFYrnIsZQ4w',
    id: 4037,
    acronym: 'MLSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mnnit.ac.in',
    name: 'Motilal Nehru National Institute Of Technology',
    web_page: 'http://www.mnnit.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6HwvILG_TMOyBqcJaSxeDf94RC8lCTbY9mn1shepJnAufVq2281PVy4Xm',
    id: 4038,
    acronym: 'MNNIT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mpbou.org',
    name: 'Madhya Pradesh Bhoj (Open) University',
    web_page: 'http://www.mpbou.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyKat5l0Qocuxcf_J2Bn9d3UA7vi5EIImkmFhPZh1uoRF3GPrcxI3SAjY',
    id: 4039,
    acronym: 'MPB(U',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mpkv.mah.nic.in',
    name: 'Mahatma Phule Agricultural University',
    web_page: 'http://mpkv.mah.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpG3klrh3XY6SabRqrz_HAEw7LLTHkSw_Nll0qddAFbvlbyuIiW1UyTRQy',
    id: 4040,
    acronym: 'MPAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'msubaroda.ac.in',
    name: 'Maharaja Sayajirao University of Baroda',
    web_page: 'http://www.msubaroda.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7rgWmCENDFXD1TF8ry36a1aTMXUfYuhAlok3eB9AK4lbra3ZoH0AYZg',
    id: 4041,
    acronym: 'MSUB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mu.ac.in',
    name: 'University of Mumbai',
    web_page: 'http://www.mu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjsbsTQMzfBYKQKc9dOoD68qADpeGElFj2cLnZZQwIgwfBJ0xZQSYhDg',
    id: 4042,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nagarjunauniversity.ac.in',
    name: 'Acharya Nagarjuna University',
    web_page: 'http://www.nagarjunauniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4D6vdnivdy1iXPvOVXELQxIDHOHqcPLXZ2FYXiuH-cuKbwqaCiR8QpIc',
    id: 4043,
    acronym: 'ANU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nagauniv.org.in',
    name: 'Nagaland University',
    web_page: 'http://www.nagauniv.org.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRdlWqjEbVU1MvLcVm_1bHQzH1Hhl7O5tSwBJEvRyrMmBos7oqps0BAD8',
    id: 4044,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nagpur-university.com',
    name: 'Nagpur University',
    web_page: 'http://www.nagpur-university.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHFIgFKpw62ImIIX7FBWpjI43PhB-GmtT-aqFg4vyE1YuVpb1hb9zSYg',
    id: 4045,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nalandaopenuniversity.info',
    name: 'Nalanda Open University',
    web_page: 'http://www.nalandaopenuniversity.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNeGghpWLRPNHSLvObHAE4v8V61-h4s-a2KwY0apax-9XkXJKaGDBRKQ',
    id: 4046,
    acronym: 'NOU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nationalmuseumindia.org',
    name: 'National Museum Institute of History of Art Conservation and Museology',
    web_page: 'http://www.nationalmuseumindia.org/nmi/index.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSipw1RNofRH4kpZLvz_dwz59YmovO2ELB_yIrRz-HPF7KffAIPOmCe11c',
    id: 4047,
    acronym: 'NMIHACM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nbu.ac.in',
    name: 'University of North Bengal',
    web_page: 'http://www.nbu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaDk2YD2hrzMqmZ8RI-p7TmuCe8uN61GAZFSm1Nw3RhqhI3d2MEREFfw',
    id: 4048,
    acronym: 'UNB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ndri.nic.in',
    name: 'National Dairy Research Institute',
    web_page: 'http://ndri.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxREbb0GmdSJXnQhIrLUMSJO2M09UBZx-8mYW6Z0lXu7WT47qsGCa23zc',
    id: 4049,
    acronym: 'NDRI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nduat.nic.in',
    name: 'Narendra Deva University of Agriculture and Technology',
    web_page: 'http://www.nduat.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJw0zPkvJ691z-pLJdNvmZtodPoBevxXE7MrcGkQ9gZH-qEprBwa5ldGQ',
    id: 4050,
    acronym: 'NDUAT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nehu.ac.in',
    name: 'North Eastern Hill University',
    web_page: 'http://www.nehu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP5Lvls1J5X8X5LITOPhwEFWop-_Me8koaS8k4wVSkcLmuKySBoerRPA',
    id: 4051,
    acronym: 'NEHU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ngu.ac.in',
    name: 'Hemchandracharay North Gujarat University',
    web_page: 'http://www.ngu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1WUw6jeOQEc3yiVX_NqOEeqDUoXELn9DbwXcZzcHBCeIv8mEEe44NNQ',
    id: 4052,
    acronym: 'HNGU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nimhans.kar.nic.in',
    name: 'National Institute of Mental Health and Neuro Sciences',
    web_page: 'http://www.nimhans.kar.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwSFtcVUgGBk0TJr5U0nLc0M6a1P4bj1HfIHntwZsNmJIFELI4yU4wBv4',
    id: 4053,
    acronym: 'NIMHNS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nims.ap.nic.in',
    name: "Nizam's Institute of Medical Sciences",
    web_page: 'http://nims.ap.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_rE__Lk7nz3LfKdhWoO3xVz5BZREzqVSqRh9QsXzkLSj1TuLmAqFY8JI',
    id: 4054,
    acronym: 'NIMS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nims.net.in',
    name: 'Institute of Management Studies',
    web_page: 'http://www.nims.net.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTI90SdPcxFeYbHorZG-6PWJwy67bKG3ALfU7wCn1pk2LHRR34agTmBw',
    id: 4055,
    acronym: 'IMS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nirmauni.ac.in',
    name: 'Nirma University',
    web_page: 'http://www.nirmauni.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXza95O8WD1r51SqfKcVH2YdDWw4UgIzR5zum7mqZ3rfo_U9o6sEE5aA',
    id: 4056,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitie.edu',
    name: 'National Institute of Industrial Engineering',
    web_page: 'http://www.nitie.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4rFAQqiv6xb7outjqkubFp3k9L9QpOvylV1ZXHGxwMYA5qbQTQ6J89w',
    id: 4057,
    acronym: 'NIIE',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nita.ac.in',
    name: 'National Institute of Technology Agartala',
    web_page: 'http://www.nita.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7qh0lVRpqPL-AyK494EY8duMaiolLxk-Sk1euZtiAANwGV0NYuYItuPg',
    id: 4058,
    acronym: 'NITA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitandhra.ac.in',
    name: 'National Institute of Technology Andhra Pradesh',
    web_page: 'http://www.nitandhra.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAPbf_d0Mdku_9Dne0xa15aSeTNQyG8XbCM0uJbWVkcjjLXbhCMUdyL_4',
    id: 4059,
    acronym: 'NITAP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitap.in',
    name: 'National Institute of Technology Arunachal Pradesh',
    web_page: 'http://www.nitap.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS1o_t0lCm35DGgkwLDVnIjfy-5FGVwsIGYC1xGu-XMcLE6dFN0jvLBA',
    id: 4060,
    acronym: 'NITAP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitc.ac.in',
    name: 'National Institute of Technology Calicut',
    web_page: 'http://www.nitc.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzckQeDITQTE0Nvq_sSghjJcIhN7Lmjc9JuGSaeIx2Jg-pa8Nti1iVcQ',
    id: 4061,
    acronym: 'NITC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitdelhi.ac.in',
    name: 'National Institute of Technology Delhi',
    web_page: 'http://www.nitdelhi.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4J-PNjSJlBRZwhjWYL3ykAolDVsoWwWV1adnwWw266aktXMvqYrD3fg',
    id: 4062,
    acronym: 'NITD',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitdgp.ac.in',
    name: 'National Institute of Technology Durgapur',
    web_page: 'http://www.nitdgp.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5L4abO3nlWZ1ElHIpqReSUwN2AF-XmENRBIs42gbHoINIboss3sGKk7P-pA',
    id: 4063,
    acronym: 'NITD',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitgoa.ac.in',
    name: 'National Institute of Technology Goa',
    web_page: 'http://www.nitgoa.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyi46gd5GxsWpIF_qnxYto5vAa0j-CpTN5i66PM-PQcJeYjZ7bacSIRGKx',
    id: 4064,
    acronym: 'NITG',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nith.ac.in',
    name: 'National Institute of Technology Hamirpur',
    web_page: 'http://www.nith.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3dd8qsyOGFyfzAdHb7Ah2SyOZ2RlhkoeEG_xA6Kq1G1XoQES0fH_IC_Y',
    id: 4065,
    acronym: 'NITH',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitjsr.ac.in',
    name: 'National Institute of Technology Jamshedpur',
    web_page: 'http://www.nitjsr.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJR8PGJL__cdmJSYsyq98tEPyOSNlLJCFi4FC1vFHBEeZBBc3vcUt8ejY',
    id: 4066,
    acronym: 'NITJ',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitkkr.net',
    name: 'National Institute of Technology Kurukshetra',
    web_page: 'http://www.nitkkr.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPo45m1yxay7NqY-6PqdalgUvy904um7DfylLsTQ8GgsthDQ7BemPjzw',
    id: 4067,
    acronym: 'NITK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitmanipur.ac.in',
    name: 'National Institute of Technology Manipur',
    web_page: 'http://www.nitmanipur.ac.in',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUiXHSNdjMKN7ntbjwlZmwMlBp2NS3MoMIxuALfxDr_MQHSBgFpm_-pzrS',
    id: 4068,
    acronym: 'NITM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitm.ac.in',
    name: 'National Institute of Technology Meghalaya',
    web_page: 'http://www.nitm.ac.in',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWGA6-Y5YKArggQ_YvIKyvhYqWpwuPzKmLMTrXdQcJtj51XgJa06mP1w',
    id: 4069,
    acronym: 'NITM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitmz.ac.in',
    name: 'National Institute of Technology Mizoram',
    web_page: 'http://www.nitmz.ac.in',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqKFtKXGzvTuL36UXL_Xsu1vYQbHjGkwBM67N7Aq2ijko4mOziSUhFDrM',
    id: 4070,
    acronym: 'NITM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitnagaland.ac.in',
    name: 'National Institute of Technology Nagaland',
    web_page: 'http://www.nitnagaland.ac.in',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-ifaUxw5R1L7qDP6lqZwHcZ3CT6bEN_aqul7q7Rk-As9kwU8Ljb2uxA',
    id: 4071,
    acronym: 'NITN',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitp.ac.in',
    name: 'National Institute of Technology Patna',
    web_page: 'http://www.nitp.ac.in',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJhFux2u_4EPkzPtGgr62umf-NMmF3d_OQMNlNtwt4cSuhEm9Rx9a_vg',
    id: 4072,
    acronym: 'NITP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitpy.ac.in',
    name: 'National Institute of Technology Puducherry',
    web_page: 'http://www.nitpy.ac.in',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdmuXE-kBk5krJqz0EPbt8Q-MSvjsCBLdPFCt-5H3dDqmCDbhkEQByQA',
    id: 4073,
    acronym: 'NITP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitrr.ac.in',
    name: 'National Institute of Technology Raipur',
    web_page: 'http://www.nitrr.ac.in',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNx9jid7TxD13a6lFmVzUXAxywVCjavxX090IMMv9hVu9aBvuVjLZxJC4',
    id: 4074,
    acronym: 'NITR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitrkl.ac.in',
    name: 'National Institute of Technology Rourkela',
    web_page: 'http://www.nitrkl.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSWtCT486jrAjhICvY1AJgpa6KKlhijaEIQSR3kPx4YRnFon3VGst3taoJ_w',
    id: 4075,
    acronym: 'NITR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitsikkim.ac.in',
    name: 'National Institute of Technology Sikkim',
    web_page: 'http://www.nitsikkim.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_IkFxDuUKDkmXLcI907Bq9_PX5ThWMJVfXwN8ozDIOLqUPVGtt0c1gw',
    id: 4076,
    acronym: 'NITS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nits.ac.in',
    name: 'National Institute of Technology Silchar',
    web_page: 'http://www.nits.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE6Eo0p8zhAVvmYo91TRkTu8fhOxkESHtHJYgljZNBhqEboK7UedCBwno',
    id: 4077,
    acronym: 'NITS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitsri.net',
    name: 'National Institute of Technology Srinagar',
    web_page: 'http://www.nitsri.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA-54-t5jab5QQpYBY7usdgoj0a2tnBDkZMQ8PImYDvxdg5siZrmzJFvM',
    id: 4078,
    acronym: 'NITS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitk.ac.in',
    name: 'National Institute of Technology Surathkal',
    web_page: 'http://www.nitk.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqXe_f4b8D9o9WKQUoRO6hgmpYXZd32RK2OZ8HAkFHFf1nont4xeOwgrez',
    id: 4079,
    acronym: 'NITS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitt.edu',
    name: 'National Institute of Technology Trichy',
    web_page: 'http://www.nitt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZYHkKh3kk3Z5Cuvbfb_2rUJd32B8KYeHZa4BY05Fzweee-gma9XiSuA',
    id: 4080,
    acronym: 'NITT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nituk.ac.in',
    name: 'National Institute of Technology Uttarakhand',
    web_page: 'http://www.nituk.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGXxR5vsiT5Vs0tBcTs2FUF_wQaKXFsHezH3DWtwC5uPmduVwJuEruBQ',
    id: 4081,
    acronym: 'NITU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nitw.ernet.in',
    name: 'National Institute of Technology Warangal',
    web_page: 'http://www.nitw.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiqkFV9YHpszmp_Z9VAs2dtXxSRCIyJHYHqGgjBDUfBdctdsSimT1-iNTE',
    id: 4082,
    acronym: 'NITW',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nls.ac.in',
    name: 'National Law School of India University',
    web_page: 'http://www.nls.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQodweh-BBBTe3Ox8dkmU2bB9ElUrVVA5p-8m-mEokP_a0ovyC52EcPDE8l',
    id: 4083,
    acronym: 'NLSIU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nmims.edu',
    name: 'Narsee Monjee Institute of Management Studies',
    web_page: 'http://www.nmims.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMrKZR2wPUr7n9oxem1n8F-feyo571HDccHn6nZcvYJ-PN1Atb2-DHOFg',
    id: 4084,
    acronym: 'NMIMS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nmu.ac.in',
    name: 'North Maharashtra University',
    web_page: 'http://www.nmu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjECu1D5VoHLqHsLhJeRF8jSRTEKZ7jWq-YlLcwFxd-y1WmIGuNzAP2A',
    id: 4085,
    acronym: 'NMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'nsit.ac.in',
    name: 'Netaji Subhas Institute of Technology',
    web_page: 'http://www.nsit.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMbYzEz-bUpQiIucy0gll7kk_I3jSMAEW_jPJuIwQVfxtuRJkG79kBHNCn',
    id: 4086,
    acronym: 'NSIT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'osmania.ac.in',
    name: 'Osmania University',
    web_page: 'http://www.osmania.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSodLnuGn6s-N1j9Af7uQDg06jUxwigGWM6PBL-LiCisOkd_d2wrm0TS-c',
    id: 4087,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'padmavatiwomen-univ.org',
    name: "Sri Padmavati Women's University",
    web_page: 'http://www.padmavatiwomen-univ.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToskSX324qR8gLzHoiXh7Qbe-Pc7AdBsFBSQXLRdQ8OXNI-dCSHwicAVc',
    id: 4088,
    acronym: 'SPWU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'pau.edu',
    name: 'Punjab Agricultural University',
    web_page: 'http://www.pau.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwyV9rwo13R5b-yjEBHQdY3hQEwYq0sushjJF1dsWXJIWqLbi-1iLCIQ',
    id: 4089,
    acronym: 'PAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'pdkv.ac.in',
    name: 'Dr. Panjabrao Deshmukh Krishi Vidyapeeth',
    web_page: 'http://www.pdkv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQn5Pfjqc9Zb_U_ixHgcwQNMp6Uw0q4qjDqP226aua44ci4qjuewIl8ibSVw',
    id: 4090,
    acronym: 'DPDKV',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'pec.ac.in',
    name: 'Punjab Engineering College',
    web_page: 'http://www.pec.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6QGBVW-ZJAGwl2QYyZdvTcHjUCdZGaWnEFAwVwi5jBhnGu7-j4i_N7at7',
    id: 4091,
    acronym: 'PEC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'pes.edu',
    name: 'Pes University',
    web_page: 'http://www.pes.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNJYS8-OP53Kt5q8H0kVtr-3rzbFM149A0KwKry4hdLrQ0H-DouS-m2V0',
    id: 4092,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'pgimer.nic.in',
    name: 'Postgraduate lnstitute of Medical Education and Research',
    web_page: 'http://www.pgimer.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD6YfUn2xn7aBark4PWlHIvP5DrRavvtq51hD5CGF5lqYMuxS7JOpLiaw',
    id: 4093,
    acronym: 'PLMER',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'pondiuni.org',
    name: 'Pondicherry University',
    web_page: 'http://www.pondiuni.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaLtncif9wHFVnmsP6mTpo_rYARz9BkxtMy85PGKkNEBbaxCtBjyE7ww',
    id: 4094,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'psgtech.edu',
    name: 'PSG College of Technology',
    web_page: 'http://www.psgtech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSadzBDEBoI5-S_OyOFgit3L-FoNoezplBs4T3eEERQdrAN2ZFMrXcUe0-',
    id: 4095,
    acronym: 'PCT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ptetjnvu.org',
    name: 'Jai Narain Vyas University',
    web_page: 'http://www.ptetjnvu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvWqSnYKlAk84v2ZJVHSrdtmbNZ00NGiKlZIBLn4leJ-zCxlVIgrC-FUI',
    id: 4096,
    acronym: 'JNVU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ptu.ac.in',
    name: 'Punjab Technical University',
    web_page: 'http://www.ptu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeKdV8Tg2x3do2P-zMBjwS933NweeHnX5F47JIgEzzqUGAiOHGsYyX534',
    id: 4097,
    acronym: 'PTU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'puchd.ac.in',
    name: 'Panjab University',
    web_page: 'http://www.puchd.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8BN6iiNmZq9GpAp3f4IQu9HaSDyiEf5JEmHGgvMbdUMuDyYA-umVzBTM',
    id: 4098,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'punjabiuniversity.ac.in',
    name: 'Punjabi University Patiala',
    web_page: 'http://www.punjabiuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNPezZ3M-uoKjuXMAqHbJFE12hUvagHdXsp-K__f-qKiYCDrRXNNZYdLQ',
    id: 4099,
    acronym: 'PUP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'puonline.bih.nic.in',
    name: 'Patna University',
    web_page: 'http://puonline.bih.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTEJEYPdOBoqpxfEfphjqTj1L2FZTiETkhC1AO8drqWUOdp-zwHsb8bQ',
    id: 4100,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'pusavarsity.org.in',
    name: 'Rajendra Agricultural University',
    web_page: 'http://www.pusavarsity.org.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQpPaTdnukl7lABjcf8AXTv1MEO2vmrvqzu8zEF1Fdge-o12qkqGeJRg',
    id: 4101,
    acronym: 'RAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'rabindrabharatiuniversity.net',
    name: 'Rabindra Bharati University',
    web_page: 'http://www.rabindrabharatiuniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Vd3HAFY_TUZDO7VHeOHYulYu6v-bcPJqFRjTao6sN7e9WmYAjNLZryU',
    id: 4102,
    acronym: 'RBU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ranchiuniversity.org.in',
    name: 'Ranchi University',
    web_page: 'http://www.ranchiuniversity.org.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu4HezA2C_IY9toIgDZdMCH94H6TAhMiMOpf7-I3d5tszxTuLquWtQjJk',
    id: 4103,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'raubikaner.org',
    name: 'Rajasthan Agricultural University Bikaner',
    web_page: 'http://www.raubikaner.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3luJJcs70zOAWwNeRwBewbbk71rE-9hYMkC1MiPzxdlDglbr5UWueDw',
    id: 4104,
    acronym: 'RAUB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'rdunijbpin.org',
    name: 'Rani Durgavati University',
    web_page: 'http://www.rdunijbpin.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYn3LWJ_CzQB-CabzxWfQ0zTmq4wLI19xIQYcsd4gJawtOMUo4QY5UrA',
    id: 4105,
    acronym: 'RDU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'rgtu.net',
    name: 'Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)',
    web_page: 'http://www.rgtu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTijQa6GuNEg9kLbqs7XOTaYqc-WdgSyYuC8EwMmlMnwboYE1kQn8BWBw',
    id: 4106,
    acronym: 'RGTU(TMP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'rgu.ac.in',
    name: 'Rajiv Gandhi University',
    web_page: 'http://www.rgu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRblxiRjMm-9b-F3U3Rjs54RkfDDW08MoMtEvnkf7vrJfD_hy29kSMBtno',
    id: 4107,
    acronym: 'RGU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'riths.ac.in',
    name: 'Rajitlal Institute of Technology & Health Sciences (RITHS)',
    web_page: 'http://www.riths.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScAq9vyuFBOflWxaHLDRiI6rxt88IMiEKOthD929dl9MH35pw6VZAyLYc',
    id: 4108,
    acronym: 'RIT&HS(',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'rmlau.ac.in',
    name: 'Dr. Ram Manohar Lohia Avadh University',
    web_page: 'http://www.rmlau.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeGcw_oWc8f9P5VYqFgn0gJvdjIOi2Rokf_PPrhTtaVtd50T8g5XE4Ww',
    id: 4109,
    acronym: 'DRMLAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'rsuniversity.com',
    name: 'Pandit Ravi Shankar Shukla University',
    web_page: 'http://www.rsuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMf7qGYEedWCR9GiYltvqU573Lyma6tcW_9CGReXuzOOpNDnDYbL3pvw',
    id: 4110,
    acronym: 'PRSSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'rtu.ac.in',
    name: 'Rajasthan Technical University',
    web_page: 'http://www.rtu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaZ7ckZaA_qVW5uGEFBlpVLogbOXryv1Azq6xXKsbSEi8P1GKx-xPzYRzw',
    id: 4111,
    acronym: 'RTU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ruraluniversity-chitrakoot.org',
    name: 'Mahatma Gandhi Chitrakut Gramoday University',
    web_page: 'http://www.ruraluniversity-chitrakoot.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqOV6RD-KagjhTeCWZ7-Ng5foIhJh_WV_6SrHYT8ZZDVs2_VQjNHtJBg',
    id: 4112,
    acronym: 'MGCGU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sagaruniversity.nic.in',
    name: 'Dr. Hari Singh Gour University formerly University of Sagar',
    web_page: 'http://www.sagaruniversity.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrYyWgs3_gh6rEhl3XS5xHXPr9gRwbsyCX9axx-7DsQr9UI3v505auIoY',
    id: 4113,
    acronym: 'DHSGUFUS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sambalpuruniversitypgc.in',
    name: 'Sambalpur University',
    web_page: 'http://www.sambalpuruniversitypgc.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQODH9ZpWGmckz5LkKdCayPWPyJbAw2Cpmz5nuZ3pyqi4ycLWQKknIpYw',
    id: 4114,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sanskrit.nic.in',
    name: 'National Sanskrit University',
    web_page: 'http://www.sanskrit.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ2-fRUe6emO_j37OA2epRTtya4o0XCxCW5NsOGg0G51dW6QL9cAbwTg',
    id: 4115,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sastra.edu',
    name: 'SASTRA Deemed University',
    web_page: 'http://www.sastra.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjISOe48V_oFyMkFYHhrGaTbl1-F-aViRX6gb3FT-M6d0dR0g5VUWCXQ',
    id: 4116,
    acronym: 'SDU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sathyabamauniv.ac.in',
    name: 'Sathyabama University',
    web_page: 'http://www.sathyabamauniv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSdmsgusV1jKz2hrLXdQmBpxwO-UKIsAMtsQjFusF4QO-G2Gj_Uj_uc4E',
    id: 4117,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'saurashtrauniversity.edu',
    name: 'Saurashtra University',
    web_page: 'http://www.saurashtrauniversity.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIWbhSInBAZsfmGS3nb12hbtH9EsgPDjxosaQnNqerSlnAC3fgLDsbhA',
    id: 4118,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sce.ac.in',
    name: 'Shanmugha Arts Science Technology and Research Academy',
    web_page: 'http://www.sce.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1QI4edOHifL5iH7OMBRTYoarik69bXJBUOgCqhw215qHXzuoqiJChZg',
    id: 4119,
    acronym: 'SASTRA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sctimst.ker.nic.in',
    name: 'Sree Chitra Tirunal Institute for Medical Sciences and Technology',
    web_page: 'http://sctimst.ker.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbbgUrfzNq52JAdVGxP9PHtRXOkLhWnSfkW0Ne8QRotCcXUnYln7pjLw',
    id: 4120,
    acronym: 'SCTIFMST',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sdau.edu.in',
    name: 'Sardarkrushinagar Dantiwada Agricultural University',
    web_page: 'http://www.sdau.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDHjYU1zPrqn09XD-WcWaRnFyOlVceNlis4QBvSBfto6BsdfcgiKzKTH4',
    id: 4121,
    acronym: 'SDAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sgpgi.ac.in',
    name: 'Sanjay Gandhi Postgraduate lnstitute of Medical Sciences',
    web_page: 'http://www.sgpgi.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuSZKc_qwVnOxnnpDB-ge28mNEqBZ_-ZM-v_5ClTEzYRuNi1eAwVoIvR0',
    id: 4122,
    acronym: 'SGPLMS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sgu.ernet.in',
    name: 'South Gujarat University',
    web_page: 'http://www.sgu.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTXegNb8VzaXG4iUTLqLm3mHGZJmG1HRoh8Kk7unEhzakKh8Rv-S35GcUK',
    id: 4123,
    acronym: 'SGU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'shobhituniversity.ac.in',
    name: 'Shobhit University Meerut',
    web_page: 'http://www.shobhituniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTasFzJpc2Oac32yVG8VhUTCxPdwi_XS-LaA76zEsWL2I1AFXx-AJ3oorJJ',
    id: 4124,
    acronym: 'SUM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'shridharuniversity.ac.in',
    name: 'Shridhar University',
    web_page: 'http://www.shridharuniversity.ac.in/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRQw1sHqqL34srZbBKXi1zcDM8vNi3pDDH_YnefIpXNJ-yNa0M4JzWskGo',
    id: 4125,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'simhapuriuniv.ac.in',
    name: 'Vikrama Simhapuri University',
    web_page: 'http://www.simhapuriuniv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDyNbxNgZaiZOZSzeFjskPEdolnbuP6EsiXhjzdYBfOlEltNcQ3SLgAek',
    id: 4126,
    acronym: 'VSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'siu.edu.in',
    name: 'Symbiosis International University',
    web_page: 'http://www.siu.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgHnVF-IyUc9m2MySX_5YXQ9cTYZ0acXu7BnmXIKDHKUXEz8qS5y0XrOUj',
    id: 4127,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sjsv.nic.in',
    name: 'Shri Jagannath Sanskrit University',
    web_page: 'http://www.sjsv.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBUhAr7Q5MYuBD9-VstJAPrYlN9xX44S9nEVK-6RaHCoph-3nhbq-npa8',
    id: 4128,
    acronym: 'SJSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'skmu.edu.in',
    name: 'Sido Kanhu Murmu University',
    web_page: 'http://www.skmu.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMWJOUXLJg2nKjAAgQZicPe16HV1pXQ3xUfzk2mx9JnoK8IOf2LNks3GE',
    id: 4129,
    acronym: 'SKMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'skuastkashmir.ac.in',
    name: 'Sher-E-Kashmir University of Agricultural Sciences and Technology',
    web_page: 'http://www.skuastkashmir.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB0kvtUP4ElMJxIAncxC2bfz-k_DgFtZSRV5Nv2B0Ly3P5HShNL_cH1e4',
    id: 4130,
    acronym: 'SUAST',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'skuniv.ap.nic.in',
    name: 'Sri Krishnadevaraya University',
    web_page: 'http://skuniv.ap.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC_nyoM_BxDqVeQNGqivvUFd7MKaqpDedtVNk6V_Yad04gpUI1uv_P1w',
    id: 4131,
    acronym: 'SKU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'slbsrsv.ac.in',
    name: 'Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha',
    web_page: 'http://www.slbsrsv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkDPLTQlnorGXy7GBnFWz7c4Lv-xXKnGNUUNtirJfPm1o23N54QKlv4Dg',
    id: 4132,
    acronym: 'SLBSRSV',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'smkfomra.net',
    name: 'SMK Fomra Institute of Technology',
    web_page: 'http://www.smkfomra.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRyLMElbfnGeB_wDtrtFeN8YdB0fCVHDn17c-F50WbVkG7FBksib-Zc5E',
    id: 4133,
    acronym: 'SFIT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'smu.edu.in',
    name: 'Sikkim Manipal University of Health Medical and Technological Sciences',
    web_page: 'http://www.smu.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShtw6zb8ai7an2rdK-BRIxW7f-YCv2qHnnWsxnVg8x4ZK7UhmjIehsm6Q',
    id: 4134,
    acronym: 'SMUHMTS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'sndt.edu',
    name: "Shreemati Nathibai Damodar Thackersey Women's University",
    web_page: 'http://www.sndt.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReCWs6alwy9BU8aV1h13i-aaTgbzcuapZwb3D3c32b3YkFAcruoIyj55Trfg',
    id: 4135,
    acronym: 'SNDTWU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'soauniversity.ac.in',
    name: "Sikha 'O' Anushandhan University",
    web_page: 'http://www.soauniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5p9iErbSDC0EjlnB47lt5PA1q5ml0mAFW6NghgPGrx0N8N573J6rwaIFlww',
    id: 4136,
    acronym: "S'AU",
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'southasianuniversity.org',
    name: 'South Asian University',
    web_page: 'http://www.southasianuniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS013upfJk_TEkjvZkk9cNt8BvO7A9-FaGpA_sxupwD6MxqUIlzdLoR9MA',
    id: 4137,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'spa.ac.in',
    name: 'School of Planning and Architecture',
    web_page: 'http://www.spa.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlnqba-gkYFPb6AuC54hGzNg24N2GFOyIQgTpy5DvM301N5XU8L-JMTQ',
    id: 4138,
    acronym: 'SPA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'spuniv.org',
    name: 'Periyar University',
    web_page: 'http://www.spuniv.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTz48uWKezlliP2Op2k4ou1xuBRMh5jxb7SJWYq_X-zhjgBGF4K43Hscw',
    id: 4139,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'spuvvn.edu',
    name: 'Sardar Patel University',
    web_page: 'http://www.spuvvn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgCejZcCjzADNh06yNK5B_gSt1Gi6-t5U4u7VRdhtodySClZ5In2jpMA',
    id: 4140,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'srisathyasai.org.in',
    name: 'Sri Sathya Sai Institute of Higher Learning',
    web_page: 'http://www.srisathyasai.org.in/pages/instts/HighLearn.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_ud49fFUtb03cRWqjM4e3tz6dQ49WLMgTfvxHSHOOAULwTmiWKopaucs',
    id: 4141,
    acronym: 'SSSIHL',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'srkdc.org',
    name: 'Sree Rama Krishna Degree College',
    web_page: 'http://www.srkdc.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5nEs2d9xGcXjru08wFL42EMF6MD3hBf6MImwYWNpG4Mk8EHYIaRblSqKu',
    id: 4142,
    acronym: 'SRKDC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'srmuniv.ac.in',
    name: 'SRM Institute Of Science & Technology ( Deemed University)',
    web_page: 'http://www.srmuniv.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSm1fZi39PnoSFNvNGs3ZQHwoogzWkvmNMrE1p5D8qM_WllyWx3W4Xsng',
    id: 4143,
    acronym: 'SIS&T(DU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'srtmun.org',
    name: 'Swami Ramanand Teerth Marathwada University',
    web_page: 'http://www.srtmun.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgvk4Ql4hjOEzCAfkv_ztGy8Wovlx0KNMsaOCYiHeQBCgXn12JQk0cvRo',
    id: 4144,
    acronym: 'SRTMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ssgmce.org',
    name: 'Sri Sant Gajanan Maharaj College of Engineering',
    web_page: 'http://www.ssgmce.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp1ArQla0yhreO5Q2edJhSCDcMFbtJUaj-TSymelzpRHcBw1qHAe6VSQ',
    id: 4145,
    acronym: 'SSGMCE',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ssus.ac.in',
    name: 'Sree Sankaracharya University of Sanskrit',
    web_page: 'http://www.ssus.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRJydrQNV6e80EiAMJkwfgWBfWo01akDu0bvVEKV_k3jlohw_2NumRu5A',
    id: 4146,
    acronym: 'SSUS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ssvv.up.nic.in',
    name: 'Sampurnanand Sanskrit University',
    web_page: 'http://ssvv.up.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtwbI2Qsf_wNeAhjf1ijJj-BucUCHGY1HRg5gXRmKAzlDfkX3MWRTA8Ac',
    id: 4147,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'su.digitaluniversity.ac',
    name: 'Solapur University',
    web_page: 'http://su.digitaluniversity.ac/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTx3raReayP2nNGBHUIDEQe747Xr1RoSYVv7UHoAmGSIEjfue43J5wuqA',
    id: 4148,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'svbpuctm.org',
    name: 'Sardar Vallabhai Patel University of Agriculture Amd Technology',
    web_page: 'http://www.svbpuctm.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeObRs4A4f0LAYFWFsTnczNNTA3nnDmnhsMEdE44EYjiIPurN271ajhf8',
    id: 4149,
    acronym: 'SVPUAAT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'svnit.ac.in',
    name: 'Sardar Vallabhbhai National Institute of Technology',
    web_page: 'http://www.svnit.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPo45m1yxay7NqY-6PqdalgUvy904um7DfylLsTQ8GgsthDQ7BemPjzw',
    id: 4150,
    acronym: 'SVNIT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'svimstpt.ap.nic.in',
    name: 'Sri Venkateswara Institute of Medical Sciences',
    web_page: 'http://svimstpt.ap.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpU4Qs4zDVNuy0AlUjPf4wk5tNAKanF3YONgzRl0fgTWSWQeKlv5bMAKs',
    id: 4151,
    acronym: 'SVIMS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'svuni.ap.nic.in',
    name: 'Sri Venkateswara University',
    web_page: 'http://svuni.ap.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0Ip7tN87QpxhLljBDY7X5hxcCyHS_SJdC4QfyRZ5iXWlndZ1MqOhlRw',
    id: 4152,
    acronym: 'SVU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tamiluniversity.ac.in',
    name: 'Tamil University',
    web_page: 'http://www.tamiluniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsCulpzcJmyizNcL43rjqHSG5BZxi3SFqS6wMexbwySt17KRpzOIhTFQ',
    id: 4153,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tanuvas.com',
    name: 'Tamil Nadu Veterinary and Animal Sciences University',
    web_page: 'http://www.tanuvas.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSro2Jg5IVBTTgtgxixpeA3cWhajKEmXeEO11i7WdAI0LFY3kzeZxz-Bg',
    id: 4154,
    acronym: 'TNVASU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tce.edu',
    name: 'Thiagarajar College of Engineering',
    web_page: 'http://www.tce.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVTEq1jOBLsQ98Zpp4FFMH-4EwVCtkVTyFxHVv6d2NsRfRSquKSbiCqnxh',
    id: 4155,
    acronym: 'TCE',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'teluguuniversity.ac.in',
    name: 'Potti Sreeramulu Telugu University',
    web_page: 'http://www.teluguuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3wOnUUFIQpjWkedWAgdonyN1q5q3fOZZicydVO-aM-JWjIbpk-6wXzI4',
    id: 4156,
    acronym: 'PSTU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'teluguuniversity.ac.in',
    name: 'Telugu University',
    web_page: 'http://www.teluguuniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCplSo7yg_ok_bVZoAsU9Y1sEkSeAC39AzJyDQCSnAvk3C7fCf-kliNks',
    id: 4157,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tezu.ernet.in',
    name: 'Tezpur University',
    web_page: 'http://www.tezu.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrel0_uOddNe6ky0fXuLlBc56Bv4utD-CrwJ6zg-1KwS9cwulK_2YzWA',
    id: 4158,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'thenewcollege.org',
    name: 'The New College',
    web_page: 'http://www.thenewcollege.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkAOFC2O9cCVZwEDPa9PgkTYueOQ9LRQTnaOFo9ZMNvxhvoK66jMusS3I',
    id: 4159,
    acronym: 'NC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tiet.ac.in',
    name: 'Thapar Institute of Engineering and Technology',
    web_page: 'http://www.tiet.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgc2zBfzXF7XXgg9eJ9CHZPmGV98IVL1BV6byPZStjTKCw9rFCYnJE9w',
    id: 4160,
    acronym: 'TIET',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tiss.edu',
    name: 'Tata Institute of Social Sciences',
    web_page: 'http://www.tiss.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt3HJcJt5Me2iAo_-H4lrMsopucJ1xOQBNe4wprvIcCoDuh1AskcTLoeY',
    id: 4161,
    acronym: 'TISS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tmbu.org',
    name: 'Tilka Manjhi Bhagalpur University',
    web_page: 'http://www.tmbu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZiaFawi-yZlRG9jr9yTIf3BXvBN0EZuO8KCmQ5_MAaHTQHu339M9zIA',
    id: 4162,
    acronym: 'TMBU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tmv.edu.in',
    name: 'Tilak Maharashtra Vidyapeeth',
    web_page: 'http://www.tmv.edu.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQyvM3QKq9ypQeJ3UZ-xwrnSfvrtBj8aRoRQnqX6h2g09Fm9FpTu_XOvQ',
    id: 4163,
    acronym: 'TMV',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tndalu.org',
    name: 'Tamil Nadu Dr. Ambedkar Law University',
    web_page: 'http://www.tndalu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB5gNsf2SOcu8Gipz3atzarsG0gz5oYmX6CTT19deBA9qhYDaIIxFdUfI',
    id: 4164,
    acronym: 'TNDALU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tnmmu.ac.in',
    name: 'Tamil Nadu Dr. M.G.R. Medical University',
    web_page: 'http://www.tnmmu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0eEX-qepAQavsdUVJtmayvAjjqAyAlm3sl1ch8Yb676U4Fz5u-cOtkg',
    id: 4165,
    acronym: 'TNDMMU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tnuniv.ac.in',
    name: 'Avinashilingam Institute for Home Science and Higher Education for Women',
    web_page: 'http://www.tnuniv.ac.in/adu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmD0llmXrad7qjGu7F5ObxEJiS9bdtVYEiRIcm40kE-eu0BBGuRoRtbg',
    id: 4166,
    acronym: 'AIFHSHEFW',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tnuniv.ac.in',
    name: 'Gandhigram Rural Institute',
    web_page: 'http://www.tnuniv.ac.in/gri/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOb_qczI0Lpxiamns_Ckf8FhvopzYokElcKD8BGz356g5T070H-rEw8z8',
    id: 4167,
    acronym: 'GRI',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tnuniv.ac.in',
    name: 'Manonmaniam Sundaranar University',
    web_page: 'http://www.tnuniv.ac.in/msu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpyj53jxMeTCytsWdxo5QES8r1O6lkdr5QSSkZlj6x9-Yjz_676TPbKQ',
    id: 4168,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tnuniv.ac.in',
    name: "Mother Teresa Women's University",
    web_page: 'http://www.tnuniv.ac.in/mteresa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZ4V4G5ujgmu4s0ocvC5K9jrIGmvSvr0cqmbaGtW8OLnNGnI8WX3abTw0',
    id: 4169,
    acronym: 'MTWU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'manit.ac.in',
    name: 'Maulana Azad National Institute of Technology Bhopal',
    web_page: 'http://www.manit.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTimtZan5eNf4AKBZ-zVyyWORStc4K6l2TVUkG60T-CCuRyP9R_RsVfYA',
    id: 4170,
    acronym: 'MANITB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'mnit.ac.in',
    name: 'Malaviya National Institute of Technology Jaipur',
    web_page: 'http://www.mnit.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMr5NE7_SC40xzXN1QUaT1v-DYlP-i2Tk41j-CriWQ7iIJAPg7K2KahVw',
    id: 4171,
    acronym: 'MNITJ',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tripurauniv.in',
    name: 'Tripura University',
    web_page: 'http://www.tripurauniv.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo-mV_Myek3MYmZmyl4mCc4HRj1MG_5bTnWCJEixVGt4dHtXyQ117V7NA',
    id: 4172,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tshmc.org',
    name: 'Takhatmal Shrivallabh Homoeopathic Medical College',
    web_page: 'http://www.tshmc.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXwOLYbgiaOx2UMrOKasay6zI5v1Vjr0hDy-w-muvBKHCeXhGNgzRekYY',
    id: 4173,
    acronym: 'TSHMC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tumkuruniversity.ac.in',
    name: 'Tumkur University',
    web_page: 'http://www.tumkuruniversity.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3OglvjHye-bJO-Z-bBMyzgNhVMwQs1Q5IeO0Xz4tuw5Pkp5le-24iFdM',
    id: 4174,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'tvuni.in',
    name: 'Thiruvalluvar University',
    web_page: 'http://www.tvuni.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOaC-CHE63ws_Rx4Ry-X3Mrp0sXEl8INUK538zpJVwvZMu5MRuv0uCfM4',
    id: 4175,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uasbng.kar.nic.in',
    name: 'University of Agricultural Sciences Bangalore',
    web_page: 'http://uasbng.kar.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-Omotz8o9HXjs_nl6X0sneB231Ysv4vMo5hs8rRfUyamG6XAMIPcm763V',
    id: 4176,
    acronym: 'UASB',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uasd.net',
    name: 'University of Agricultural Sciences Dharwad',
    web_page: 'http://www.uasd.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc5U9VXkWNz9byPDKj-KpNll9ENmjyffgxuZrmKnIoAvyh5cz38yp6992x',
    id: 4177,
    acronym: 'UASD',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uktech.ac.in',
    name: 'Uttarakhand Technical University',
    web_page: 'http://www.uktech.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPLNaVfaZzxGtHCdi8gTmkj-Wpqf8fe5FB26BndeY8Ewo1aiFu7tnxTyc',
    id: 4178,
    acronym: 'UTU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uma.ac.in',
    name: 'University of Media Arts',
    web_page: 'http://www.uma.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgF0DRBaOSqH2iqA3h0ZfvCx_UF7_ZXdpoJdwD7E-cLGXOhzjM6N-jFQ',
    id: 4179,
    acronym: 'UMA',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'unical.ac.in',
    name: 'University of Calicut',
    web_page: 'http://unical.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGHViyGkveTg6ujPd5r375aRNUT3cuqF3qCndW0OG2_K4X9U1nIzlnB30',
    id: 4180,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'unigoa.ac.in',
    name: 'Goa University',
    web_page: 'http://www.unigoa.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsvg1u3YPHqqwVDOtf3ePLGIJgk-Dc9xr6nSllbh_ack9GnLlHcxaqJQ',
    id: 4181,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uni-mysore.ac.in',
    name: 'University of Mysore',
    web_page: 'http://www.uni-mysore.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNMY_VQsMB2qHP9JUV_TiuybPBxgDYWsnmI_JtG_CJ71AafzbE8Ej0yw',
    id: 4182,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'unipune.ernet.in',
    name: 'University of Pune',
    web_page: 'http://www.unipune.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2nievz4j10yj4leu9zXtCU4TkJmMSjiWDNJR628k1jbf6rEIcC1cj_Q0',
    id: 4183,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uniraj.org',
    name: 'University of Rajasthan',
    web_page: 'http://www.uniraj.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtoYPb1cM4yI0mIOVgv7lm0IoDldCy99QmvMWimny59F0tx6lsylyi8A',
    id: 4184,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'unishivaji.ac.in',
    name: 'Shivaji University',
    web_page: 'http://www.unishivaji.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUnlpgBtf_t1wSJyZMjRD_6k_rMFSOZYcd7NW8kLOvzZIKXS3JatCrNAM',
    id: 4185,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'unom.ac.in',
    name: 'University of Madras',
    web_page: 'http://www.unom.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh8PAEwwxZDS8KYNl_Sf5BzdPqU98T6nLbFQ44SAT-u5qUig4xrI2oanc',
    id: 4186,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uohyd.ernet.in',
    name: 'University of Hyderabad',
    web_page: 'http://www.uohyd.ernet.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhOU7ZhKecbQgMbwkhRtVHVV-IM_TiWSFqVnLIy1BE0-gHdobeL0kpbw8',
    id: 4187,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uok.ac.in',
    name: 'University of Kota',
    web_page: 'http://www.uok.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5wol2SCINCSG7UNaK8B8OtqvnEfnwrunUoqvDZcjs_Uopf3vIBTn73A',
    id: 4188,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uor.org',
    name: 'University of Roorkee',
    web_page: 'http://www.uor.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS5occhIVlIkegSuDn89hFSLFXfCtGxE5n6WzY53u-8vzZFkBJSEbXlw',
    id: 4189,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uou.ac.in',
    name: 'Uttarakhand Open University',
    web_page: 'http://www.uou.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAZ7BmlbLdzZute_jW3UZUJemMtUDyYymO0BzsGEIKqJ84OTRbibqU7g',
    id: 4190,
    acronym: 'UOU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'uptu.ac.in',
    name: 'Uttar Pradesh Technical University',
    web_page: 'http://www.uptu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTits5jjx6aNZRK2u83bW9Kuk8Ghs_yh7lrkghR8lYxWnHKkZGH09M5yw',
    id: 4191,
    acronym: 'UPTU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'utkaluniv.org',
    name: 'Utkal University',
    web_page: 'http://www.utkaluniv.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVxrB_4G9JRd54Kk3nrEKjMXEeBA_JoXqNRodT3sRFnUI7byzhHunnoQ',
    id: 4192,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vbspu.ac.in',
    name: 'VBS Purvanchal University',
    web_page: 'http://vbspu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpVdBndRQHwxXGiP9PvrUZiUKSN79APv59y7TCRYfloZDv2sbHymE4uw',
    id: 4193,
    acronym: 'VPU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vbu.co.in',
    name: 'Vinoba Bhave University',
    web_page: 'http://www.vbu.co.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxJssKVolyswt02nAmaQdB1QHC9lut1aWig5rWeCd9DNNmgwvaE4he1g',
    id: 4194,
    acronym: 'VBU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vidyasagar.ac.in',
    name: 'Vidyasagar University',
    web_page: 'http://www.vidyasagar.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdqn1Ij9-TdZ13kWutVjhLL5C_oHNWSw6nPzcI79lHF-kgqXgvo5wx2w',
    id: 4195,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vignanuniversity.org',
    name: 'Vignan University',
    web_page: 'http://www.vignanuniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLLvluOwmgtM4HZjPt5oFVfgLcTDy_Ra5ITELhbBW7DJotUarqjAWgyG4',
    id: 4196,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vikramuniversity.org',
    name: 'Vikram University',
    web_page: 'http://www.vikramuniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfvsmkRdycpFnJGMYEBVV6tx5Mq7hZ7BeWUSbCS-u0O39X4neWQwx1Vw',
    id: 4197,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vinayakamissions.com',
    name: "Vinayaka Mission's Research Foundation-Deemed University",
    web_page: 'http://www.vinayakamissions.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQomno22jryhuSpfyuNkLAAoFjzMxjUzVm3G2cRtPwqULFwOrx7UiO6phI',
    id: 4198,
    acronym: 'VMRFU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'visva-bharati.ac.in',
    name: 'Visva-Bharati University',
    web_page: 'http://www.visva-bharati.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGrojbeQv9NZIBi4JKJRDBj6T2v177i1hBexoVFCVI6RL0VkTWYNr0Lg',
    id: 4199,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vit.ac.in',
    name: 'Vellore Institute of Technology',
    web_page: 'http://www.vit.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0rPNCeRfUBMzV1HyderdZZY7NZFn3u8SSAQZM8rAtn30dn4I-iZhNJv_7',
    id: 4200,
    acronym: 'VIT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vksu-ara.org',
    name: 'Veer Kunwar Singh University',
    web_page: 'http://www.vksu-ara.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWpu7yx4gxBy1nTFBZ6cr7BUcgiJjj7DEDLEBCEPZJqzxu_KcOXyVxjSc',
    id: 4201,
    acronym: 'VKSU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vmou.ac.in',
    name: 'Vardhaman Mahaveer Open University',
    web_page: 'http://www.vmou.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOMNgCOKu96ly6pH26eXO_9D1CURNcdZMQXLTPl6CmazuUyyXcLr2NqzIv',
    id: 4202,
    acronym: 'VMOU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vnitnagpur.ac.in',
    name: 'Visvesvaraya National Institute of Technology',
    web_page: 'http://www.vnitnagpur.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXC5HGflq62fGGXrnLBHhV_4jrw_tANVTbfW_Q6VofJ3FLIesL6A_Ftl4',
    id: 4203,
    acronym: 'VNIT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vssut.ac.in',
    name: 'Veer Surendra Sai University of Technology',
    web_page: 'http://www.vssut.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdQf1y_5Q7Rs27FWeGsQmbd-KJnxpsXJYGUKARLmwyqy7xmc7YbtCbV88',
    id: 4204,
    acronym: 'VSSUT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'vtu.ac.in',
    name: 'Visvesvaraya Technological University',
    web_page: 'http://www.vtu.ac.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzuuJpJXDLjJwsukX-SLQ7R-lUY-y3iCjPOF8psEnGp3OayBpA_2AlQ0-OFg',
    id: 4205,
    acronym: 'VTU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'wbuafs.nic.in',
    name: 'West Bengal University of Animal and Fishery Sciences',
    web_page: 'http://www.wbuafs.nic.in/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV7sRc92Vh_tIEWgLKUAcG2KO3FccLlD1P4We5e3O71StnpCAAszVfcg',
    id: 4206,
    acronym: 'WBUAFS',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'wbut.net',
    name: 'West Bengal University of Technology',
    web_page: 'http://www.wbut.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg6A1wShiDAMhYC9B6zpBSqdzLIXKp0oHogQs86Bg2xP1TZMXeOhdBZA',
    id: 4207,
    acronym: 'WBUT',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ycmou.com',
    name: 'Yashawantrao Chavan Maharashtra Open University',
    web_page: 'http://www.ycmou.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRitrTRwHmYU1XUCvfARDiw4nOaGM5k7seSoek1TF7GrGEalu7qPfZpFg',
    id: 4208,
    acronym: 'YCMOU',
  },
  {
    alpha_two_code: 'IN',
    country: 'India',
    domain: 'ysparmaruniversity.org',
    name: 'Dr. YS Parmar University of Horticulture and Forestry',
    web_page: 'http://www.ysparmaruniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs8b4WwLSZu7MAkMg_xYf1GyYDuESZkgQYQgib6f5zvT6HOcTrchRe6Wkmig',
    id: 4209,
    acronym: 'DYPUHF',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'akprind.ac.id',
    name: 'Institut Sains & Teknologi Akprind',
    web_page: 'http://www.akprind.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXY-mdIrPluzEJApVvGLEBkWW6yyq1o0jObTgGfY3cAzXgE3PMycKWN6-h',
    id: 4210,
    acronym: 'IS&TA',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'amikom.ac.id',
    name: 'STMIK AMIKOM Yogyakarta',
    web_page: 'http://www.amikom.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmo8aJg9P7ZW9NpOVFSag6XcNAAYIVIkog60OyJ1y1N4Y4s6RlobF_lg',
    id: 4211,
    acronym: 'SAY',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'atmajaya.ac.id',
    name: 'Universitas Katolik Indonesia Atma Jaya',
    web_page: 'http://www.atmajaya.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnamXOtCOOXlhQ0VsiM949UHSkD7TKCcmxR8elKo3DRydX294Qjt_U8vR0',
    id: 4212,
    acronym: 'UKIAJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'bakrie.ac.id',
    name: 'Universitas Bakrie',
    web_page: 'http://www.bakrie.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIiWaXTmvb8i8FECggUoqBoMUubdbsu5YgJe7hUBug91lqdsrKwLBzipk',
    id: 4213,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'binus.ac.id',
    name: 'Binus University',
    web_page: 'http://www.binus.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPDyUIvGHc_Ivdz-HzdOUzHvVvk31nz6ylybq0x8oyiFdCzVg1J7ITVeMN',
    id: 4214,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'bunghatta.ac.id',
    name: 'Universitas Bung Hatta',
    web_page: 'http://www.bunghatta.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_E55mR0pj9dOr5T6Fy3TvFSQ17U3sw8R2zwNGVj-oXFkywrrCWQTC_fM',
    id: 4215,
    acronym: 'UBH',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'dinus.ac.id',
    name: 'Universitas Dian Nuswantoro',
    web_page: 'http://www.dinus.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtKqZLDKc-HXUaeJcRStx7igvbOLNSvCKN8fjzWpWveLWOdHM6u8cbtBg',
    id: 4216,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'gunadarma.ac.id',
    name: 'Universitas Gunadarma',
    web_page: 'http://www.gunadarma.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReqeZw38VcDAcvEgi7e7hEMZgVy2XPR7drThWvTBT7zO30VixR80xdDg',
    id: 4217,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ibnuchaldun.ac.id',
    name: 'Universitas Ibn Chaldun Jakarta',
    web_page: 'http://www.ibnuchaldun.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHe2uCpE4_UxFnyGk-_UA9-CrAgW7Kwo9uVuDi1uAYysxeo5bv-wpZ3wM',
    id: 4218,
    acronym: 'UICJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'indonusa.ac.id',
    name: 'Universitas Indonusa Esa Unggul',
    web_page: 'http://www.indonusa.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7-xIZFCn_KLZUVefxWC7Ck4KEivvad3i5elaJjcRR3wHzYB6gkecjPINR',
    id: 4219,
    acronym: 'UIEU',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ipb.ac.id',
    name: 'Bogor Agricultural University',
    web_page: 'http://www.ipb.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAb2uoNCAfxaQ5D6Z27UbwalIfbmmnESxjpwRq7NJhcA9Lv6gd4BobPQ',
    id: 4220,
    acronym: 'BAU',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ista.ac.id',
    name: 'Institut Sains dan Teknologi Al Kamal',
    web_page: 'http://www.ista.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxyATLcwfnaLg6rnvfvFiBVnDqg-_wG_3_yrCCEUpIzAKcEIJcPWAIHFdY',
    id: 4221,
    acronym: 'ISDTAK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'itats.ac.id',
    name: 'Institut Teknologi Adhi Tama Surabaya',
    web_page: 'http://www.itats.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThfW7KjipHGr7hJ4WYSRw52CHN7iCwBc71msCumy9bpZr22uWBxKJzdtQ',
    id: 4222,
    acronym: 'ITATS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'itb.ac.id',
    name: 'Institut Teknologi Bandung',
    web_page: 'http://www.itb.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRCO8WV2FkSG70XzY-yTbgkj7TnipIwR2xQOaL6YFRakXYTqzIrU3Gcog',
    id: 4223,
    acronym: 'ITB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'its.ac.id',
    name: 'Institut Teknologi Sepuluh Nopember',
    web_page: 'http://www.its.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRCO8WV2FkSG70XzY-yTbgkj7TnipIwR2xQOaL6YFRakXYTqzIrU3Gcog',
    id: 4224,
    acronym: 'ITSN',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'janabadra.ac.id',
    name: 'Universitas Janabadra',
    web_page: 'http://www.janabadra.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4FCwMzGwgmuTGlPFh08_OBzjRNovGdPcBVeiSTj70rQxpIypMMjnDw9K',
    id: 4225,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'jayabaya.ac.id',
    name: 'Universitas Jayabaya',
    web_page: 'http://www.jayabaya.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqVQkhq6OYVeEB7Bj92J18Q96vorwi8drleA6EbGqP1e6xlI3-k71VEAY',
    id: 4226,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'machung.ac.id',
    name: 'Universitas Ma Chung',
    web_page: 'http://www.machung.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR9A4H51McVn2HuHsWjpZedJ5eC9e94RfhjelZM_5dW3C9CVf3pBv1yko',
    id: 4227,
    acronym: 'UMC',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'mahendradatta.org',
    name: 'Universitas Mahendradatta',
    web_page: 'http://www.mahendradatta.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxWdN44NH6IlWhoDewH0z0DehezQcagJQFksXRWQPrm4AUvZAOBLszhIA',
    id: 4228,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'um.ac.id',
    name: 'Universitas Negeri Malang',
    web_page: 'http://www.um.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ298hJtCjVCqlhDzTYX4J5oq7JgX6jUuPXdClSJViS8Umkpfse-Bn9yWQ',
    id: 4229,
    acronym: 'UNM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'maranatha.edu',
    name: 'Universitas Kristen Maranatha',
    web_page: 'http://www.maranatha.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9svgNllnotXNvDatTGC6NGWq2A3kIIBDnm7cveQv45iCOTcD3uS1S2w',
    id: 4230,
    acronym: 'UKM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'mercubuana.ac.id',
    name: 'Universitas Mercu Buana',
    web_page: 'http://www.mercubuana.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRySHXJm4Mv803K_R_OAjNsD_ZB3Aas1RUWBZsM8H4uIwVzgj6b_4yJhWs',
    id: 4231,
    acronym: 'UMB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'moestopo.ac.id',
    name: 'Universitas Dr. R. Moestopo',
    web_page: 'http://www.moestopo.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxY0EBJLIkhMgV-y_pj5herGxORo4ioogjjYnnjEyiTvrizd4TVekVXbU',
    id: 4232,
    acronym: 'UDRM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'narotama.ac.id',
    name: 'Universitas Narotama',
    web_page: 'http://www.narotama.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCEdffDMF7kWQsKD-OXTGf4AGGq1lfNlE72Em4ni0hDvF5mVM2V76NN0Q',
    id: 4233,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'nommensen.org',
    name: 'Universitas HKBP Nommensen',
    web_page: 'http://www.nommensen.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7p-L815Jv19dbPIsjsBpTwlGP6hoy9exXQ0jIT3SjaBC0CXFKnGYfDOI',
    id: 4234,
    acronym: 'UHN',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'pancabudi.ac.id',
    name: 'Universitas Pembangunan Panca Budi',
    web_page: 'http://www.pancabudi.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZG79DX6-2Vv23AnrFhZQHYvP6fqDmG521l9hYiqOFO4wcLwz2xOF2pw',
    id: 4235,
    acronym: 'UPPB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'paramadina.ac.id',
    name: 'Universitas Paramadina Mulya',
    web_page: 'http://www.paramadina.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdGckxrfVj0vsWQi3O2tfqUpYqI_2E2drBtL7pD0G5XbuZVTRjPsuIkDyr',
    id: 4236,
    acronym: 'UPM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'pasim.ac.id',
    name: 'Universitas Nasional Pasim',
    web_page: 'http://www.pasim.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRryqXDSiflu9hM1GppNvXxnxbnbo2ziYgA49sMkmZGCuyhhwmlZZ2-Q',
    id: 4237,
    acronym: 'UNP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'perbanas.ac.id',
    name: 'STIE Perbanas',
    web_page: 'http://www.perbanas.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9E8oiNELHNC0xrSUFZ0uUgko4TiahN3u6Kqkhf09CGAah35NMIcJncMI5',
    id: 4238,
    acronym: 'SP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'perbanas.id',
    name: 'Perbanas Institute',
    web_page: 'http://perbanas.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi0Hf5uVQUKCxCtM8s4gt0YaHb96ZUcMeMbUkeM8BSDLA2q0HPeOR0Xg',
    id: 4239,
    acronym: 'PI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'petra.ac.id',
    name: 'Universitas Kristen Petra',
    web_page: 'http://www.petra.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsZf_tScndzWYi98PY2oik2TxSYgWJbfk-kOaE-YpDHY16H9hYdoG6Iuc',
    id: 4240,
    acronym: 'UKP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'pmbs.ac.id',
    name: 'Prasetiya Mulya Business School',
    web_page: 'http://www.pmbs.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTURxjsuDiLC45G_YVHaCUGIquzyE1f8J23Dv2lJRnv8loMHUMBm06SoX4',
    id: 4241,
    acronym: 'PMBS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'pnj.ac.id',
    name: 'Politeknik Negeri Jakarta',
    web_page: 'http://www.pnj.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6EkzoPW9wxKT0uaVn9ZY6CQRYC_0ERm26xHFXyPXYFVW2WwvW5gzJFQ',
    id: 4242,
    acronym: 'PNJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'pnl.ac.id',
    name: 'Politeknik Negeri Lhokseumawe',
    web_page: 'http://www.pnl.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSHrj9zk3IWCStWT1Qg6WAIs4wag5pSO6WkErZC0koGNer3reZeWVzUY4',
    id: 4243,
    acronym: 'PNL',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'polban.ac.id',
    name: 'Politeknik Negeri Bandung',
    web_page: 'http://www.polban.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ12fMchM00F6aOZi808VVTpzi9BLQPF7_Y1alye8WwgI6fFHbvBioPDWU',
    id: 4244,
    acronym: 'PNB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'polines.ac.id',
    name: 'Politeknik Negeri Semarang',
    web_page: 'http://www.polines.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRp3plq9D6Hw1DPiyf9cvefzzDE_lFSLilMpYSL27kRLpZEv4UMCFsxEQ',
    id: 4245,
    acronym: 'PNS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'polinpdg.ac.id',
    name: 'Politeknik Negeri Padang',
    web_page: 'http://www.polinpdg.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRp6J5wq-V-ENA4V7w5up00WF2zblKX7zGydzWwxLMMQmNa5lSLpX03COgo',
    id: 4246,
    acronym: 'PNP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'polnep.ac.id',
    name: 'Politeknik Negeri Pontianak',
    web_page: 'http://www.polnep.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBjge05lSQxvHe2UL-LIOeMriQxni__CZC4uixd5COYRxhUxfb-dzy0w',
    id: 4247,
    acronym: 'PNP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'poltek-malang.ac.id',
    name: 'Politeknik Negeri Malang',
    web_page: 'http://www.poltek-malang.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmZkfGIJROfvtI32OP7XEG0OnAMzFSRSjcJCAWZ0s8mOJsKhc3HMxC2NkirQ',
    id: 4248,
    acronym: 'PNM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'poltekpos.ac.id',
    name: 'Politeknik Pos Indonesia',
    web_page: 'http://www.poltekpos.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTUvCys3CxTNr4CQNwGyogVAcsl6-wVmin9ndojM9AFwrfHWSbO4nZsgJI',
    id: 4249,
    acronym: 'PPI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'poltesa.ac.id',
    name: 'Politeknik Negeri Sambas',
    web_page: 'http://www.poltesa.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrE2_Sg7TFkPcVFEh7THqYAP53D2201aNBgQPmoZx4VQAZa0PpqD8hGwF1sA',
    id: 4250,
    acronym: 'PNS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'puterabatam.ac.id',
    name: 'Universitas Putera Batam',
    web_page: 'http://www.puterabatam.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf6dzKtylukNfhiSnpJeBhjOwAwKYcPax2dAaRz8_fhb0yzv__TXSDzsE',
    id: 4251,
    acronym: 'UPB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'sarjanawiyata.ac.id',
    name: 'Universitas Sarjanawiyata Tamansiswa',
    web_page: 'http://www.sarjanawiyata.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZco9gkPeaFPpMXgsa7o-yOtpAD3nSEKDt_jgMLbjmWMfxJa4ADs0tCg',
    id: 4252,
    acronym: 'UST',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'sgu.ac.id',
    name: 'Swiss German University',
    web_page: 'http://www.sgu.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhM7m16oftxlr5kllplH-b8Ig8w0b15eq2vLMjhqmfFjEiFVkzKOUUgQ',
    id: 4253,
    acronym: 'SGU',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'sinus.ac.id',
    name: 'STMIK Sinar Nusantara',
    web_page: 'http://www.sinus.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrradycmYF4NJ-wYN9gO2sBZZ083AWvQZkfiycCerJopHhXWL_EcqApoYE',
    id: 4254,
    acronym: 'SSN',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'stan.ac.id',
    name: 'Sekolah Tinggi Akuntansi Negara (STAN)',
    web_page: 'http://www.stan.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMCdkecSpDnDqSHhsksdeYoxqSXAMJpa7W9fR1K8RMst19TiKa5-Xfhg',
    id: 4255,
    acronym: 'STAN(',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'stptrisakti.ac.id',
    name: 'Trisakti Institute of Tourism',
    web_page: 'http://www.stptrisakti.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRchmm0uvSOdw_WJpAwRq2gxzpwP5e7XyJEHmU3hRxz3KKorRqZFvTB7SY',
    id: 4256,
    acronym: 'TIT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'telkomuniversity.ac.id',
    name: 'Universitas Telkom',
    web_page: 'http://www.telkomuniversity.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnvOInplC2AtLiLYa_iSy2wnEd5HwiU_WKqaOA47s7c93KSUD1wQ4ATZUN',
    id: 4257,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unklab.ac.id',
    name: 'Universitas Klabat',
    web_page: 'http://www.unklab.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9wlguixXacB_YisK5skquK66EMxWQs5SLtX00c7Wtn-7aTOuJATiGiQ',
    id: 4258,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'trisakti.ac.id',
    name: 'Universitas Trisakti',
    web_page: 'http://www.trisakti.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQy7l0jeGGJgJXTiQeZJxd3cw5tDH_dOQeJrwAGcAn3RK6yA3pFfrkmw',
    id: 4259,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uad.ac.id',
    name: 'Universitas Ahmad Dahlan',
    web_page: 'http://www.uad.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjok2q1QBDdw28zNRYjdi61S0-MVAUtpOeMTK7GAH8CLMgq6Sv7UuMKA',
    id: 4260,
    acronym: 'UAD',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uajy.ac.id',
    name: 'Universitas Atma Jaya Yogyakarta',
    web_page: 'http://www.uajy.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG6cPx4dGUEoP0TtZ0O9GCsEkolWRFLq6s0AYblvEAojlwAykCauRRp6U',
    id: 4261,
    acronym: 'UAJY',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ub.ac.id',
    name: 'Universitas Brawijaya',
    web_page: 'http://www.ub.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1Y0Eynbpug5Wx6FTgWFJlkrOpae_-8kic2Xcvpw2-rWBrC4Aq7YZP6zo',
    id: 4262,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ubaya.ac.id',
    name: 'Universitas Surabaya',
    web_page: 'http://www.ubaya.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnRaJyfH_AQvD1yjtMUKDJSIkpucNwvWUcn1-T2oYaekVeUwL37mz1j2Y',
    id: 4263,
    acronym: 'US',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ubhara.ac.id',
    name: 'Universitas Bhayangkara Surabaya',
    web_page: 'http://www.ubhara.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqBFLb4DB9MHFvXZJdpDP0fyeC1p9Ei1Z9ilVo-9fKiUOIpzIRmVbguDo',
    id: 4264,
    acronym: 'UBS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ubharajaya.ac.id',
    name: 'Universitas Bhayangkara Jakarta Raya',
    web_page: 'http://www.ubharajaya.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVQhmfeOwANK_3f05AFfni9Pz-whnCC2bwme25GSdm673sw2V5wSU8RIOgOA',
    id: 4265,
    acronym: 'UBJR',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ubm.ac.id',
    name: 'Universitas Bunda Mulia Jakarta',
    web_page: 'http://www.ubm.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyaYnaXfhqGmM48Xd0WgT-0MU83yrn7LRhV-9wP0ppmcJLIqRt_ArysGQ',
    id: 4266,
    acronym: 'UBMJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uda.ac.id',
    name: 'Universitas Darma Agung',
    web_page: 'http://www.uda.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4P75bbbSR-kq1z4G_bA4xBu945s3iuDTq4RNc7RjL7MKQB-Z12MuXOGA',
    id: 4267,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ugm.ac.id',
    name: 'Universitas Gadjah Mada',
    web_page: 'http://www.ugm.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEfDfXimgj1pM0OzEXzjFtDqiLcArnGcLYd89hcQ_MFurZ8RQECpABoQ',
    id: 4268,
    acronym: 'UGM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ui.ac.id',
    name: 'Universitas Indonesia',
    web_page: 'http://www.ui.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqw6qaoc41KRbDtsyIIjdIxVlUVNkMAECQLdBMTKSVva5miydGSF7DDbk',
    id: 4269,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uii.ac.id',
    name: 'Universitas Islam Indonesia',
    web_page: 'http://www.uii.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_qdqBdAP1GojhsgytuZ_lyZtU-T2sVUmDt21rXkz0_-bkvsY-4gn-uQQ',
    id: 4270,
    acronym: 'UII',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uika-bogor.ac.id',
    name: 'Universitas Ibn Khaldun Bogor',
    web_page: 'http://www.uika-bogor.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHqYc0Wam5so-AUMHaQFsOzJ7M-eLTOAkX8aY5tormwfImJ3TsENLbvYGe',
    id: 4271,
    acronym: 'UIKB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uinjkt.ac.id',
    name: 'Universitas Islam Jakarta',
    web_page: 'http://www.uinjkt.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStPrNW3R9dDrVf8bxH3S22lNMbKoksocVbu1DGLkgkVfSmBwneRCGpFyeiXw',
    id: 4272,
    acronym: 'UIJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uir.ac.id',
    name: 'Universitas Islam Riau',
    web_page: 'http://www.uir.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy6tpSZ2ladAeOEeIpidMyZknnJeh-pnFnIRtC7pQLyO6BJ5jijy8LjU4',
    id: 4273,
    acronym: 'UIR',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uisu.ac.id',
    name: 'Universitas Islam Sumatera Utara',
    web_page: 'http://www.uisu.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQAf_4TMPcPQ7Wkwa-NDeIzbAegRSDVsM5TKkof7zJWEWFDD09nP2Jbg',
    id: 4274,
    acronym: 'UISU',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ukdw.ac.id',
    name: 'Universitas Kristen Duta Wacana',
    web_page: 'http://www.ukdw.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkbCW4ZruBqMu_NtUczySuYuyRzaYxCxSLsr1Uk1rnYgkqehk05Zp6n0o3',
    id: 4275,
    acronym: 'UKDW',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uki.ac.id',
    name: 'Universitas Kristen Indonesia',
    web_page: 'http://www.uki.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-1EAg70qxCTXzg3fSABPOTCP3a9A1fmg06u4LPSCoklZjyt_0qFK-aRs',
    id: 4276,
    acronym: 'UKI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ukit-tomohon.org',
    name: 'Universitas Kristen Indonesia Tomohon',
    web_page: 'http://www.ukit-tomohon.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoOCbSiWjoZOJPzwhYGGhkawBypl7yfkpy0iKUe5d9YEJbzw6YOu74uQ',
    id: 4277,
    acronym: 'UKIT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ukrida.ac.id',
    name: 'Universitas Kristen Krida Wacana',
    web_page: 'http://www.ukrida.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTen8c93wfxhGjxYKu9mpJX5wAG6xy6u6LQuQNPrEwkwUnAXvPFm5OU_UbF',
    id: 4278,
    acronym: 'UKKW',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uksw.edu',
    name: 'Universitas Kristen Satya Wacana',
    web_page: 'http://www.uksw.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQW-2ksDz7l-GWxaOwDiEbedmd0M8zgskO5Qk7IlmuxFaZ_1o9yQvb_8Bk',
    id: 4279,
    acronym: 'UKSW',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'umi.ac.id',
    name: 'Universitas Muslim Indonesia',
    web_page: 'http://www.umi.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_V5y_2p2mRMXJwJwfCBz630JloP4MDkwaudlC0hvVIi-NRlymii-eYS4',
    id: 4280,
    acronym: 'UMI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'umi-mdn.edu',
    name: 'Universitas Methodist Indonesia',
    web_page: 'http://www.umi-mdn.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYHq8GqIssjBcO-t2uTuXpR-I57AkiR2Bv_y9_rWWfQwzfOBIPteH5Tis',
    id: 4281,
    acronym: 'UMI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'umj.ac.id',
    name: 'Universitas Muhammadiyah Jakarta',
    web_page: 'http://www.umj.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2nvJRwN93mrM3VNTYyDkhQVcRlqpJvjXfwlBhL8lxd56XmAvYfSqpvAs',
    id: 4282,
    acronym: 'UMJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'umk.ac.id',
    name: 'Universitas Muria Kudus',
    web_page: 'http://www.umk.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvFwaxB1A4AGeDuvm2WPhtEZfOHDjQXPlyxH2trK-mVWfFv9XjLE7iLw',
    id: 4283,
    acronym: 'UMK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'umm.ac.id',
    name: 'Universitas Muhammadiyah Malang',
    web_page: 'http://www.umm.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGut5ExzIX-BOmJQbYxndqVZZ-FuVd_H0SFtRDXeNnJXWpybvjpldhuJY',
    id: 4284,
    acronym: 'UMM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ummgl.ac.id',
    name: 'Universitas Muhammadiyah Magelang',
    web_page: 'http://www.ummgl.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbgBJGU7HM4n-gq8MNplKYrQYjUWctdF6JXtI1a9Ze7CeeZM2QLHeoIg',
    id: 4285,
    acronym: 'UMM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ums.ac.id',
    name: 'Universitas Muhammadiyah Surakarta',
    web_page: 'http://www.ums.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1kY7BqmVvl93s_XoOnQkDKFVkRLykpPwFd2_MjiZ8qKrHO-f7qgugXqA-VA',
    id: 4286,
    acronym: 'UMS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'umsu.ac.id',
    name: 'Universitas Muhammadiyah Sumatra Utara',
    web_page: 'http://www.umsu.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVvEWFrrBQ7tqN_21qdt0AQkNMHY7gB6lJfBLMxQxvn5C_Ujs96zKR8A',
    id: 4287,
    acronym: 'UMSU',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'umy.ac.id',
    name: 'Universitas Muhammadiyah Yogyakarta',
    web_page: 'http://www.umy.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbXgkVcmrbn-tde5mCmiGYVYHwGFQhJhev-qqE1ncVmro5lgkRBn6BiJA',
    id: 4288,
    acronym: 'UMY',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unai.edu',
    name: 'Universitas Advent Indonesia',
    web_page: 'http://www.unai.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqJ_oYrlxDr2TNm8aN4ALvOIGsJbrC3-06B0GE5-qLHsSlJX1DaoOOMLM',
    id: 4289,
    acronym: 'UAI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unair.ac.id',
    name: 'Universitas Airlangga',
    web_page: 'http://www.unair.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlSCOVIBcjexmcFobzGviYv-mwTyR0yUgSO630KwKiMBEmAe70sKGJ5g',
    id: 4290,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unand.ac.id',
    name: 'Universitas Andalas',
    web_page: 'http://www.unand.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxhXgueq9U3MY9pzRiE5_pZ1NwJ4TP318qZV0Ff4ffziBk8zCcih7_Jfu9',
    id: 4291,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unas.ac.id',
    name: 'Universitas Nasional Jakarta',
    web_page: 'http://www.unas.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUPOKdxbPXiexYQ2TvRN3HQSsJJ5detGxX2mcvxcqekQiw1PxWxQBS3w',
    id: 4292,
    acronym: 'UNJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uncen.ac.id',
    name: 'Universitas Cenderawasih',
    web_page: 'http://www.uncen.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR4AxJWt20oYuHqAgaJysIPtO57A4E3TUMD7i5ZTItE9tpuphrZG2fmnY',
    id: 4293,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'undana.ac.id',
    name: 'Universitas Nusa Cendana',
    web_page: 'http://www.undana.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvXx7lHkq7fVSF_yyimF2cAfB6D8Weev5fVGlp33TvTkir_qNg3r0fU6Q',
    id: 4294,
    acronym: 'UNC',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'undip.ac.id',
    name: 'Universitas Diponegoro',
    web_page: 'http://www.undip.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcOIyxOdQKWAEDXu6lnwkg5uKwCSv1cuk9zrNwAB7IH9ssF6u3PEjZKj0',
    id: 4295,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unej.ac.id',
    name: 'Universitas Jember',
    web_page: 'http://www.unej.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNn4EcV7ZTgjDwHiBAaKkRIWcZoeCIFfPBMjT71JWqB1ysilen5lkQz7s',
    id: 4296,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unesa.ac.id',
    name: 'Universitas Negeri Surabaya',
    web_page: 'http://www.unesa.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgChyLIiY3ksbwLVAffOsDibOQI__pVEU5TRXm7ruw1PT_do_wo3jDK64',
    id: 4297,
    acronym: 'UNS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ung.ac.id',
    name: 'Universitas Negeri Gorontalo',
    web_page: 'http://www.ung.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhjipPB3zbh9hl9nUmH5A8WfZbeceDVl2D-iKIFoF7OAhXXBdOB6WkCu_2',
    id: 4298,
    acronym: 'UNG',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unhalu.ac.id',
    name: 'Universitas Haluoleo',
    web_page: 'http://www.unhalu.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcxEjUnBTKHA_KAjX8dRpheX0QkZ9TvoX9HDtdZ2HA7BmMBcqgQDz2rEHtsw',
    id: 4299,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unhamzah.ac.id',
    name: 'Universitas Amir Hamzah',
    web_page: 'http://www.unhamzah.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScRgQp4Y0332NP0ydlqy3fdwK85_OlkNKsomeu1Uhb6kXp3AE8EkD2rKJH',
    id: 4300,
    acronym: 'UAH',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unhas.ac.id',
    name: 'Universitas Hasanuddin',
    web_page: 'http://www.unhas.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ATjK2ljcQGCkeleW9SqOAfdj7eBmeMj35f3kZbAjQxNBOXjCvbRW0LA',
    id: 4301,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unib.ac.id',
    name: 'Universitas Bengkulu',
    web_page: 'http://www.unib.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmd8l666lW5ZvxpHSi-OURdsUS-Hz28y0ympHTfybloV2v0e3htQH5NnAghg',
    id: 4302,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unigoro.ac.id',
    name: 'Universitas Bojonegoro',
    web_page: 'http://www.unigoro.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLzmT9FvGKfykyOdQc-6EjQJvbQoMiVtBOnhrGo9u-VWVmMWPn-tsGKd8',
    id: 4303,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unika.ac.id',
    name: 'Universitas Katolik Soegijapranoto',
    web_page: 'http://www.unika.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBfMMzTfvo4W9zOpKJ7ira1ZeWSbfVG-wX7FAuO3gf2LSJxuE7Rp-pjOk',
    id: 4304,
    acronym: 'UKS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unikal.ac.id',
    name: 'Universitas Pekalongan',
    web_page: 'http://www.unikal.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE4pNckqwss-trTKyKBpvZDoe4HXjxWEtdqGytTmK_QDyFGFy4U_5VnEI',
    id: 4305,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unik-kediri.ac.id',
    name: 'Universitas Kediri',
    web_page: 'http://www.unik-kediri.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJmwFNdfyBDIg8krPdCU6cAMeVD35dGZUu8W0RaLCpQqb8UTzqFv1MExRR',
    id: 4306,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unikom.ac.id',
    name: 'Universitas Komputer Indonesia',
    web_page: 'http://www.unikom.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxj0_o4GDssGl7Hj_QkKDKzV3H4DzOdXu-NYsnZET6V5LhTUwdf4XE0w',
    id: 4307,
    acronym: 'UKI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unila.ac.id',
    name: 'Universitas Lampung',
    web_page: 'http://www.unila.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYOm6aFQ0moEEAjvJ5U78sbhVaoDsVPxoupPk9C-OBE7G7Bk0EVRTzsRE',
    id: 4308,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unilak.ac.id',
    name: 'Universitas Lancang Kuning',
    web_page: 'http://unilak.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaJqDtJxEzBpbyHS2aaDMuY4OSVCltORi10-bFlmY9NB3CC7cuezWqoRA',
    id: 4309,
    acronym: 'ULK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unima.ac.id',
    name: 'Universitas Negeri Manado',
    web_page: 'http://www.unima.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCJVR6kSpFQHtyAcwPY_BySvzygBFlJa5PB8BTYm5XFkSSEuzv-KfvRQ',
    id: 4310,
    acronym: 'UNM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unimed.ac.id',
    name: 'Universitas Negeri Medan',
    web_page: 'http://www.unimed.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz36mTXL_GE25HfWbe1B1lOTDscbwv7P0SGoe3XMvRXns9wuEFsSllGQ',
    id: 4311,
    acronym: 'UNM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uninus.ac.id',
    name: 'Universitas Islam Nusantara',
    web_page: 'http://www.uninus.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPWaf5RtijiuH8ZthSHDl-_6uxaa6qyAA3t8pxZ4qUnuEpSyt1fv5bvA',
    id: 4312,
    acronym: 'UIN',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unipdu.ac.id',
    name: 'Universitas Pesantren Darul Ulum Jombang',
    web_page: 'http://www.unipdu.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN2h2rleYvQMthfm_17Per4s_QQ1gtZZuVbmhTtHAoATJiNnxthHY8NSbB',
    id: 4313,
    acronym: 'UPDUJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unira-pmk.net',
    name: 'Universitas Madura',
    web_page: 'http://www.unira-pmk.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2QheD_EJJUqfvhQ8nXdw3NY9DOhlihDLcPc1GY52pG-IT5V0m7jMcfEp0',
    id: 4314,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unisba.ac.id',
    name: 'Universitas Islam Bandung',
    web_page: 'http://www.unisba.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn9ydpAY3WZVz0i6DeQG2hAV7GxpDRuII55IGKh7YwR8-sHjxw7fayA6c',
    id: 4315,
    acronym: 'UIB',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unisma.ac.id',
    name: 'Universitas Islam Malang',
    web_page: 'http://www.unisma.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkfz94zbjmlLRiyhas-ftGS5iDtYsRgdzpyE4NVIeOnNtJ_A8_3lblFQE',
    id: 4316,
    acronym: 'UIM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unismuh.ac.id',
    name: 'Universitas Muhammadiyah Makassar',
    web_page: 'http://www.unismuh.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDWk73oCqNolFBqg-3HINicelfmm8aABkPmHEbxf_vWk_jAoG_uEBPGAY',
    id: 4317,
    acronym: 'UMM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unisri.ac.id',
    name: 'Universitas Slamet Riyadi Surakarta',
    web_page: 'http://www.unisri.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsGracKeG8CV9vWiukxum4Its7gC--6arCrcoFsrv8vx3jkvWMdf_iX49U',
    id: 4318,
    acronym: 'USRS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unissula.ac.id',
    name: 'Universitas Islam Sultan Agung',
    web_page: 'http://www.unissula.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSqcqeudBZvJ10_rF94b02pdzAME5jncXk7QTfLsUppDt6kmPIROgRlg',
    id: 4319,
    acronym: 'UISA',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unistangerang.ac.id',
    name: 'Universitas Islam Syekh-Yusuf',
    web_page: 'http://www.unistangerang.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNbsBkCTFpnlwvF6sJYrOhKaqmJRUZA_Uxn8TQEHklgfWeMGvwV2ueqw',
    id: 4320,
    acronym: 'UIS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unitomo.ac.id',
    name: 'Unitomo Surabaya',
    web_page: 'http://www.unitomo.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgbqDCGFW9Y2kn700WWn3Q_VaQxhFIhrGJfzQU7mOVDaEvRl5o_QTkIKk',
    id: 4321,
    acronym: 'US',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'universitas-trilogi.ac.id',
    name: 'Universitas Trilogi',
    web_page: 'http://www.universitas-trilogi.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWZAsV4yuoKOUmzzSkLTkpcAt_vSZ-tJeKxoDIOyzmJ0NqkPI3TpmVIeTp',
    id: 4322,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'univpancasila.ac.id',
    name: 'Universitas Pancasila',
    web_page: 'http://www.univpancasila.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSinDM2RtlwfCOuMot7jftB1oVxqrl6CWxA1ronBFbkhFpimNCX59vOhg',
    id: 4323,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unja.ac.id',
    name: 'Universitas Jambi',
    web_page: 'http://www.unja.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXqF-huDpjraB1l-q-I0oitjzfQJSJhuESQWHbUKLgL4XhiJWIzO5HCg',
    id: 4324,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unj.ac.id',
    name: 'Universitas Negeri Jakarta',
    web_page: 'http://www.unj.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQd-Qut-jbffB7rC0CsTVazWYgjgcT6IW5VkTPDdK0t21KHlIm9MPaiNA',
    id: 4325,
    acronym: 'UNJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unjani.ac.id',
    name: 'Universitas Jenderal Achmad Yani',
    web_page: 'http://www.unjani.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTeaUOoMZjrBlwnmrbUKI2KfOYgze0WQgzzoYQsjHfR1uOmU8E93_4RB0',
    id: 4326,
    acronym: 'UJAY',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unkhair.ac.id',
    name: 'Universitas Khairun',
    web_page: 'http://www.unkhair.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSld-NLJBqEI7UbFUE5P1SFck8ExtIVNLM-H8V-hPlBRpqDSJBoMxgu4N-nXg',
    id: 4327,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unkris.ac.id',
    name: 'Universitas Krisnadwipayana',
    web_page: 'http://www.unkris.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5sXr--UJndOnfrfb1ML9jyZRpa67l3MfSHH2PG1EaJTybwirP5B7BHDU',
    id: 4328,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unlam.ac.id',
    name: 'Universitas Lambung Mangkurat',
    web_page: 'http://www.unlam.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReOBimkWuDK-LtcRXdmLUpkRhq-DSv-grfww-dco5L9FNrp-ue-g6130-D-Q',
    id: 4329,
    acronym: 'ULM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unm.ac.id',
    name: 'Universitas Negeri Makassar',
    web_page: 'http://www.unm.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPXWV7X72aUVcI48wUehf7UUeoKmNQuE6QcOCGMDTv9sNDhu0B9jLYiBM',
    id: 4330,
    acronym: 'UNM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unmas.org',
    name: 'Universitas Mahasaraswati Denpasar',
    web_page: 'http://www.unmas.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7C5L5QeWeSqCYHGckQJeFWPSVnGBZurPLbCnPKIYApZb2aiSpN7Mau3I',
    id: 4331,
    acronym: 'UMD',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unmer.ac.id',
    name: 'Universitas Merdeka Malang',
    web_page: 'http://www.unmer.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOYJXzIcdjh8AGY5rSyPl814EJH4H543rH0mX9bjIax2VL4ZEmyeO8Px78',
    id: 4332,
    acronym: 'UMM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unmer-madiun.ac.id',
    name: 'Universitas Merdeka Madiun',
    web_page: 'http://www.unmer-madiun.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOYJXzIcdjh8AGY5rSyPl814EJH4H543rH0mX9bjIax2VL4ZEmyeO8Px78',
    id: 4333,
    acronym: 'UMM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unmuhjember.ac.id',
    name: 'Universitas Muhammadiyah Jember',
    web_page: 'http://www.unmuhjember.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7J7lq0cHRr9S1Be2o3A-RlnOqanwPAVkPgo3AMY-vueOUrRKcY5kdvhtO',
    id: 4334,
    acronym: 'UMJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unmuhmataram.com',
    name: 'Universitas Muhammadiyah Mataram',
    web_page: 'http://www.unmuhmataram.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxon49gA4YDLF2CB014noVvyxPT4TISjjYy7QibMke_tLWpzGOqExwM7s',
    id: 4335,
    acronym: 'UMM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unmul.ac.id',
    name: 'Universitas Mulawarman',
    web_page: 'http://www.unmul.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzhR0dg4A0RQN6WkH6MDKV-CZEVUBdeawXfnjFm1APpv3xfvdDLaC-b248',
    id: 4336,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unnes.ac.id',
    name: 'Universitas Negeri Semarang',
    web_page: 'http://www.unnes.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTyGOxlpKRVSDu_nuTfWA_wJGT-bAJDem8U4nYkicV2YOITKlET_-eSw',
    id: 4337,
    acronym: 'UNS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unp.ac.id',
    name: 'Universitas Negeri Padang',
    web_page: 'http://www.unp.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaeZyq37QhtYAVKwJ2kEvJ81PnpqAt6Gze_h1-uSh3Hv42U1Yey4zdLjA',
    id: 4338,
    acronym: 'UNP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unpad.ac.id',
    name: 'Universitas Padjadjaran',
    web_page: 'http://www.unpad.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSnHjdZldL-mwzql8Qawo4zAKmJeHlW3ZnWWttOs2fet0akUaO8l7Vxg',
    id: 4339,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unpak.ac.id',
    name: 'Universitas Pakuan',
    web_page: 'http://www.unpak.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFsLMUTtyQZLxvTWoD3xgtiLsQt2pUnsKQjot0Z90YhmraxI-HUt6aSg',
    id: 4340,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unpar.ac.id',
    name: 'Universitas Katolik Parahyangan',
    web_page: 'http://www.unpar.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFc1KI34HtxYwLqBeL_-uFfZmKEW4GitZmLpOXYo9sZvHdSlp3FMRDxDM',
    id: 4341,
    acronym: 'UKP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unpas.ac.id',
    name: 'Universitas Pasundan',
    web_page: 'http://www.unpas.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLZYboZD81X__69vpBgSsJYgtWYCUE4b9mjEPKid_5uQ7HJ7QfSWu0lkA',
    id: 4342,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unpatti.ac.id',
    name: 'Universitas Pattimura',
    web_page: 'http://www.unpatti.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjRRryS3b3nl9hg8O2Bbw5PZ_ElbBV6kP5S2LmWxrburek9FoWmJDLRg',
    id: 4343,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unr.ac.id',
    name: 'Universitas Ngurah Rai',
    web_page: 'http://www.unr.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0FBoAzN-aOjX-GERMMw7uepLvBsWOrdKBEpyVRae70gWSMRmns94XDg',
    id: 4344,
    acronym: 'UNR',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unram.ac.id',
    name: 'Universitas Mataram',
    web_page: 'http://www.unram.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQHOBJVoLRi186fSPHPDQp5SNxxGROc8HVYpwYBuDwS0ySVzgQsvXTsZmxaQ',
    id: 4345,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unri.ac.id',
    name: 'Universitas Riau',
    web_page: 'http://www.unri.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFW35_FpUt7yLoYjSm_ME6OQL-x2TBF54gj6OVl-YXBjdSPX9FVYQTpwxc',
    id: 4346,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uns.ac.id',
    name: 'Universitas Sebelas Maret',
    web_page: 'http://www.uns.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhu5YQmh60ZgxmsmkKgZpk9y4tyjzCFfCb9P62erMfXeaKmSy19kLoHQ',
    id: 4347,
    acronym: 'USM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unsada.ac.id',
    name: 'Universitas Darma Persada',
    web_page: 'http://www.unsada.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT16Z41IbxgDmzgbgtWcSYYQPuIHBURr55haUZfU-aVUPAcnuCbO0cGSA',
    id: 4348,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unsil.ac.id',
    name: 'Universitas Siliwangi',
    web_page: 'http://www.unsil.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK-uOLEUZEUXds4scKIqbvfeJT-EeitCznoy1Kc9IW0oM0GW4qdzmyog',
    id: 4349,
    acronym: 'US',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unsoed.ac.id',
    name: 'Universitas Jenderal Soedirman',
    web_page: 'http://www.unsoed.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt6SRZs1KEO7g55cUZAa8fr-4vHdery0RL6Y2f73o6wMah733YSXLxnNs',
    id: 4350,
    acronym: 'UJS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unsrat.ac.id',
    name: 'Universitas Sam Ratulangi',
    web_page: 'http://www.unsrat.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEnbA88lezG03Exn32FFRdly5E4AlI6FnkYCpa-nfcBhpiOWWbP6rr7w',
    id: 4351,
    acronym: 'USR',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unsri.ac.id',
    name: 'Universitas Sriwijaya',
    web_page: 'http://www.unsri.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ6v05HMYZTJeYpsAyV8yeoHu09GYVdOquNtXiixo8K_QKU1MhrLvM6Vo',
    id: 4352,
    acronym: 'US',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unswagati-crb.ac.id',
    name: 'Universitas Swadaya Gunung Djati',
    web_page: 'http://www.unswagati-crb.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3pRVO_AGtbsbRb_u_8oKV4nl9ed8Ez_N0gqmrXGGE-vghFZEeh7Mu1gJwnw',
    id: 4353,
    acronym: 'USGD',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unsyiah.ac.id',
    name: 'Universitas Syiah Kuala',
    web_page: 'http://www.unsyiah.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShflZbzQQYgA81s1R2ObkU9t3l5aEYtaDOF7vlUeiZX2-NEwNGEK-Zjw',
    id: 4354,
    acronym: 'USK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untad.ac.id',
    name: 'Universitas Tadulako',
    web_page: 'http://www.untad.ac.id/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSabettZkp6jPKd3k_JTUQghXjLc7e2mDthJf5mO5Zqq4fIiS8-AIXMCGhOVQ',
    id: 4355,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untag.ac.id',
    name: 'Universitas 17 Agustus 1945 Surabaya',
    web_page: 'http://www.untag.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-UBLGeDjya1d850kf4MltEJXMtcQfYenBGOOaAQ726ma-61puxOgwZA',
    id: 4356,
    acronym: 'U1A1S',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untag-banyuwangi.ac.id',
    name: 'Universitas 17 Agustus 1945 Banyuwangi',
    web_page: 'http://www.untag-banyuwangi.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeqiw11ohowBe0Q_p_OmoQQ674TihqV-rCSIy1Uau3emR_DrcbSIHwHQ',
    id: 4357,
    acronym: 'U1A1B',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untagcirebon.info',
    name: 'Universitas 17 Agustus 1945 Cirebon',
    web_page: 'http://www.untagcirebon.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvvxyCFkKkXClb9JiDXfVBEUTPAFlza40aPhTrKQeXL69DfEENfH0tjA',
    id: 4358,
    acronym: 'U1A1C',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untag-jkt.org',
    name: 'Universitas 17 Agustus 1945 Jakarta',
    web_page: 'http://www.untag-jkt.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqB3CLO9F8QuMbqiiZBfvhWW5MIFHinD1SvNE-HW4wZsaTxkrg8w99QEU',
    id: 4359,
    acronym: 'U1A1J',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untag-smd.ac.id',
    name: 'Universitas 17 Agustus 1945 Samarinda',
    web_page: 'http://www.untag-smd.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqB3CLO9F8QuMbqiiZBfvhWW5MIFHinD1SvNE-HW4wZsaTxkrg8w99QEU',
    id: 4360,
    acronym: 'U1A1S',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untagsmg.ac.id',
    name: 'Universitas 17 Agustus 1945 Semarang',
    web_page: 'http://www.untagsmg.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmVwPl8M4dQJoqXje6dzSAmg7jFbgODGrOMjN7yDTY0j2up6qyR7kPoA',
    id: 4361,
    acronym: 'U1A1S',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untan.ac.id',
    name: 'Universitas Tanjungpura',
    web_page: 'http://www.untan.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0pX8_sikf1NDMdHZwJK1i1aZO2FWyktHEo2MkbhMYPVtr1_CpPvJLWw',
    id: 4362,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untar.ac.id',
    name: 'Universitas Tarumanagara',
    web_page: 'http://www.untar.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQz3GDgJ_teb1cms6Y59whgYQFLwTBOOWZKILqfPVv64H6v06ykqZxiQ',
    id: 4363,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'untirta.ac.id',
    name: 'Universitas Sultan Ageng Tirtayasa',
    web_page: 'http://www.untirta.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ21y_kViRDDCVp6sIl7IyCXYbJt_6VQWgsJGT2eM5bpeRe4PMHWPQdIRY',
    id: 4364,
    acronym: 'USAT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unud.ac.id',
    name: 'Universitas Udayana',
    web_page: 'http://www.unud.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJLWpu-c9r3rTyJN8i44fK306h4MXYThf217BCzp3jToQxWhXGk0Cn40s',
    id: 4365,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'unwiku.ac.id',
    name: 'Universitas Wijaya Kusuma Purwokerto',
    web_page: 'http://www.unwiku.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxawsjKR-6I30vb6j5yxOVe6uuoqsW1_JaKnl7M6i1BcyKVmDL6Nb-GQ',
    id: 4366,
    acronym: 'UWKP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uny.ac.id',
    name: 'Universitas Negeri Yogyakarta',
    web_page: 'http://www.uny.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7yZSsKmCQVa3QUP4xiJjYeqS5TBniGPWzGiImsXtg9U-p9bJ4WJqQ80M',
    id: 4367,
    acronym: 'UNY',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'up45.ac.id',
    name: 'Universitas Proklamasi 45',
    web_page: 'http://www.up45.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuRAqN3US07E4PYVk-7D0THuc-7SN-nO0lvzJWsfwSU3cxXViWu4NXtQ',
    id: 4368,
    acronym: 'UP4',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'uph.edu',
    name: 'Universitas Pelita Harapan',
    web_page: 'http://www.uph.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIpGxGRSrPCSeH1APP7J1LnCcmvUehg97xPkNZq6JtGbijoDBdUsOA-BCO',
    id: 4369,
    acronym: 'UPH',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'upi.edu',
    name: 'Universitas Pendidikan Indonesia',
    web_page: 'http://www.upi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4Tqot4ii8Stszx0rob1Subdb2qGkIshw0ZDMCoyYPJkSyH9VmPMrHjA',
    id: 4370,
    acronym: 'UPI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'upnjatim.ac.id',
    name: 'Universitas Pembangunan Nasional "Veteran" East Java',
    web_page: 'http://www.upnjatim.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnEorcWI5blmcXQNw41GPgJ76qObMuPrkxkgpFVeX45F8EhHAT8sPd1A',
    id: 4371,
    acronym: 'UPN"EJ',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'upr.ac.id',
    name: 'Universitas Palangka Raya',
    web_page: 'http://www.upr.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7OM6BBWSilg11VchYH8K7QX7pHkDAi9lKqI0SUC0074e3cuGUKyOBqA',
    id: 4372,
    acronym: 'UPR',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'upstegal.ac.id',
    name: 'Universitas Pancasakti Tegal',
    web_page: 'http://www.upstegal.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGYN4-w-25D2WSbt9AltmS5RpXy7PFO9NaeR9rihe1uo4FQf6GY8kFCQ',
    id: 4373,
    acronym: 'UPT',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'usd.ac.id',
    name: 'Universitas Sanata Dharma',
    web_page: 'http://www.usd.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtQZIgsq1a1wHwW_P-RbLM6vXe9zq6M-RvSJRloLIVpNAJS7FTijQ8-w',
    id: 4374,
    acronym: 'USD',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'usi.ac.id',
    name: 'Universitas Simalungun',
    web_page: 'http://www.usi.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToOUAti78LvU2MaryxzRV7QoPgAfSIU-quhCz8sG7Vx9SApWQFmOsXyew',
    id: 4375,
    acronym: 'US',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'usm.ac.id',
    name: 'Universitas Semarang',
    web_page: 'http://www.usm.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTApo9XoZ5RlfnaQ4lK9ed0km8byUkDl-ci_-2NtxwuoipwBQD7sfLJTtJ4',
    id: 4376,
    acronym: 'US',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'usu.ac.id',
    name: 'Universitas Sumatera Utara',
    web_page: 'http://www.usu.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRejhZJy87F3nMrYHuTkyMj1SamfthKiO7m70J-MMC5zLJesyKr4VVzoU3h',
    id: 4377,
    acronym: 'USU',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'ut.ac.id',
    name: 'Universitas Terbuka Indonesia',
    web_page: 'http://www.ut.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzMIrkodkW6gXox2xS9IOxwfuOuHCzzwDKZQrqmQdy3-PXquqc3UNp83hw',
    id: 4378,
    acronym: 'UTI',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'utm.ac.id',
    name: 'Universitas Tidar Magelang',
    web_page: 'http://www.utm.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStc50F_A3o_U-dXPxABiqawkkRit-VMJnhW6odMwtD3qPAwKJKhqKRcg',
    id: 4379,
    acronym: 'UTM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'utp.ac.id',
    name: 'Universitas Tunas Pembangunan',
    web_page: 'http://www.utp.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTACC2LcctaRbAIo1OQW1uaHMSmI3LJD5Icu0fZ9BZ2rI8dllifpjaac4V0',
    id: 4380,
    acronym: 'UTP',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'widyagama.ac.id',
    name: 'Universitas Widya Gama Malang',
    web_page: 'http://www.widyagama.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPbBZ8r91QpKklep7WAT_roJI26G3weSM6HBBmmlgsm6fXsMpIYSu-pqsoUQ',
    id: 4381,
    acronym: 'UWGM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'widyakarya.ac.id',
    name: 'Universitas Katolik Widya Karya',
    web_page: 'http://www.widyakarya.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhzZjkifPVtiNdHnIyAwjRdsqqZvuVdhQPpKPpjH9L54nfAFirE1MTwfU',
    id: 4382,
    acronym: 'UKWK',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'widyatama.ac.id',
    name: 'Universitas Widyatama',
    web_page: 'http://www.widyatama.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKl9H3LKtnbAP5nqc2SS3lutjYu3FdG3ZeZvsYqD6XReBa_bxT-_FS-2I',
    id: 4383,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'wijayakusumasby.ac.id',
    name: 'Universitas Wijaya Kusuma Surabaya',
    web_page: 'http://www.wijayakusumasby.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcST-l5ybzYIqkQsw1nsVWxREHaLGIAH89Lwrpj_lZxuoCQhQMn6jnyxCpI',
    id: 4384,
    acronym: 'UWKS',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'wima.ac.id',
    name: 'Universitas Katolik Widya Manadala',
    web_page: 'http://www.wima.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD_88npAbNnOYNoLOXzao0M_vafh-h7MDhUg7fuc-Xm6CO1JsFYm1ePKc',
    id: 4385,
    acronym: 'UKWM',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'wisnuwardhana.ac.id',
    name: 'Universitas Wisnuwardhana',
    web_page: 'http://www.wisnuwardhana.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPLTIHDkm4svTl9hJH9xvGV5EoTzlH-9hvwOnywZOqNuhLTM51x0DLtms',
    id: 4386,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'yai.ac.id',
    name: 'Universitas Persada Indonesia Y.A.I',
    web_page: 'http://www.yai.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsfLreeTY-E56IQxWa57Ar1YrCMUHRclW59R09Qv9yrxt5TFH9PK0NcpE',
    id: 4387,
    acronym: 'UPIY',
  },
  {
    alpha_two_code: 'ID',
    country: 'Indonesia',
    domain: 'yarsi.ac.id',
    name: 'Universitas Yarsi',
    web_page: 'http://www.yarsi.ac.id/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcWGYd_JNGyKybJ5btgWRBS3fH2Rr4IhC02ylXXSFGGbxFAtcebES7GA',
    id: 4388,
    acronym: 'UY',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'acmt.ac.ir',
    name: 'Amirkabir College of Managmant & Technology',
    web_page: 'http://www.acmt.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbBLdgDJgQtE5YshvoEKmpi0TmYm-YPo99Pue1IzhQUlsv9CVENXECFcI',
    id: 4389,
    acronym: 'ACM&T',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'adiban.ac.ir',
    name: 'Adiban Higher Education Institue',
    web_page: 'http://www.adiban.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN6tWlDyba6KqyMgMGpFB_i2ti7ZvGog0vR6zxIYD-hiAdxpG7r-hSiOA',
    id: 4390,
    acronym: 'AHEI',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ajums.ac.ir',
    name: 'Ahwaz Jondishapour University of Medical Sciences',
    web_page: 'http://www.ajums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfaCljax0IPxrj98DtKQ2Xqspa2ga3u7HCXcIVU6rYHr8WWXSjjWEI87Q',
    id: 4391,
    acronym: 'AJUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'aliabadiau.ac.ir',
    name: 'Islamic Azad University Aliabad',
    web_page: 'http://www.aliabadiau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4392,
    acronym: 'IAUA',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'almustafaou.ir',
    name: 'Almustafa Open University',
    web_page: 'http://www.almustafaou.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT80PZbf6eH9XtVgh6tTkuYjMTEUItYXlFP1aLrGIScgyhmnqDc-UIndw',
    id: 4393,
    acronym: 'AOU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'arakmu.ac.ir',
    name: 'Arak University of Medical Sciences',
    web_page: 'http://www.arakmu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi9rVyG2Ku8mgprO-8gKvQcHH3aOtdOQFFYW3Tlq3sAloZUQDibj4W1DI',
    id: 4394,
    acronym: 'AUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'araku.ac.ir',
    name: 'Arak University',
    web_page: 'http://www.araku.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReuMaCTFqyhPYRSJF5VnfKPyXVaIrxblVukbNZvH_PfSpsKjj1f1XFWg',
    id: 4395,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'arakut.ac.ir',
    name: 'Arak University of Technology',
    web_page: 'http://www.arakut.ac.ir/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQuqbQBKvESXJUVjCRc5l9FSU4qElaKcBF3g9B-vLUyigt_zU7nRbPGO3Sl',
    id: 4396,
    acronym: 'AUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'art.ac.ir',
    name: 'Art University',
    web_page: 'http://www.art.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmN6YVC0-Uqiwp7FWmwYhFRLrvLvkEJ-dcdR8yBmi7CZcHHdiEkCtAzw',
    id: 4397,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'arums.ac.ir',
    name: 'Ardabil University of Medical Sciences',
    web_page: 'http://www.arums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ62eczc2GYf3wGyA7-MpVrFUxqwVXTHsEc0p4s0mHgKOwvpnar4iEcfC0W',
    id: 4398,
    acronym: 'AUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'atu.ac.ir',
    name: 'Allameh Tabatabaie University',
    web_page: 'http://www.atu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThudv3mblUH3_hpPZnpcpKLE1rS6gcu_j_uvNalPqZUQdBIkgh3wI-nQ',
    id: 4399,
    acronym: 'ATU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'aums.ac.ir',
    name: 'Ahvaz University of Medical Sciences',
    web_page: 'http://www.aums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJZIluUhlnRhxwuVvqnqpG4fBGPD66m6OnXBDxcuq3UEcr0sJoZRYbWFY',
    id: 4400,
    acronym: 'AUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'aut.ac.ir',
    name: 'Amirkabir University of Technology',
    web_page: 'http://www.aut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjVm8WcgrWUFHvYfRTwYvzk8hE0UNzJxRbW2W91vq3UdUgUOdY5iGRnWg',
    id: 4401,
    acronym: 'AUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'azad.ac.ir',
    name: 'Islamic Azad University Tehran South',
    web_page: 'http://www.azad.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo1tIhOaD0GGObETZB0tuGFG6sBY6TEk_uOauCQSJQtRh5Q7YWV6duSdU',
    id: 4402,
    acronym: 'IAUTS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'azzahra.ac.ir',
    name: 'Azzahra University',
    web_page: 'http://www.azzahra.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb3lkzvu3RfsPyrclmeQr5AYk3HHjiTBgrwKXPeMUeRkDeh8YXTvNaZw',
    id: 4403,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'basu.ac.ir',
    name: 'Bu Ali Sina University',
    web_page: 'http://www.basu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSraIhFTCD6aiAfXewCeFyQoYJLZBad7bsbDOoFdCAfWSacymqnELshyA',
    id: 4404,
    acronym: 'BASU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'bihe.org',
    name: "Baha'i Institute for Higher Education",
    web_page: 'http://www.bihe.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8CJK3gLdOm8ba6zNIsKUu7rz0MqVXQba5Y1jkQNaMNazrpg3SXKy3Q1Eb',
    id: 4405,
    acronym: 'BIFHE',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'bihe.org',
    name: "The Bahá'í Institute For Higher Education (BIHE)",
    web_page: 'http://www.bihe.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeYI40fRKBni_p-5RgYUDwRBVLodlhV0KIHbd0IuJyHmYQz30EeFv0CzY8',
    id: 4406,
    acronym: 'BIFHE(',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'birjand.ac.ir',
    name: 'Birjand University',
    web_page: 'http://www.birjand.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCSu81ps9YSe6ZUBv0i81rB1wkvhtDLOuph9MjzImajnLmmtyFpoGPkl8',
    id: 4407,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'bmsu.ac.ir',
    name: 'Baghyatoolah Medical Sciences University',
    web_page: 'http://www.bmsu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxcqCUdLUxHv1lZ8i4RlTpR9rr4CoP4qKyZ3CwYhFhwvmz9ykYfmc63g',
    id: 4408,
    acronym: 'BMSU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'bojnourdiau.ac.ir',
    name: 'Islamic Azad University Bojnourd',
    web_page: 'http://www.bojnourdiau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4409,
    acronym: 'IAUB',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'bpums.ac.ir',
    name: 'Bushehr University of Medical Sciences',
    web_page: 'http://www.bpums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtWQOZPhJmyB9Uq-5JkL0QzPSXtM-dbSk5-3WSl9OVVnZ2kupTtEyLsbg',
    id: 4410,
    acronym: 'BUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'bums.ac.ir',
    name: 'Birjand University of Medical Sciences',
    web_page: 'http://www.bums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpiBuzcNMw59SXHNWbYoxVVvoFC7l63HAiwhIwGJSK-lYPNrmZjtrXuQ',
    id: 4411,
    acronym: 'BUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'cua.ac.ir',
    name: 'Shaheed Chamran University',
    web_page: 'http://www.cua.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNTWAreyYkxXLxj4eu6aCndqA0bZjf7wBBS35YJGJ0io2xKGYcfCFBRZs',
    id: 4412,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'dehaghan.ac.ir',
    name: 'Islamic Azad University Dehaghan',
    web_page: 'http://www.dehaghan.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC8cwlbDRo3WHzMrj26jT5liwN3zTe27kFurX4hIWwmY1VSqqz2rMQsfI',
    id: 4413,
    acronym: 'IAUD',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'delijanpnu.ac.ir',
    name: 'Delijan Payame Noor University',
    web_page: 'http://www.delijanpnu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDlrmGTPJxK7MaYqA-caKU1FBtlu436ftI8Q_-12Pd_Csnz0DqV5odDdD_PQ',
    id: 4414,
    acronym: 'DPNU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'deylaman.ac.ir',
    name: 'Deylaman Institute of Higher Education',
    web_page: 'http://www.deylaman.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8RrCf3SGCbYL6Xh8PEcKAN9oan3MjtTFDGCpPSTDiTDWs9SZ_9Pbd6Q',
    id: 4415,
    acronym: 'DIHE',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'dubs.ac.ir',
    name: 'Damghan University of Basic Sciences',
    web_page: 'http://www.dubs.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTabtIww1wB4042HSm3HNb1cHc0VrqQwuLLnKFKuubra_Hi-p4wcE6xAw',
    id: 4416,
    acronym: 'DUBS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'falavarjan.ac.ir',
    name: 'Islamic Azad University Falavarjan',
    web_page: 'http://www.falavarjan.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4417,
    acronym: 'IAUF',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'fsriau.ac.ir',
    name: 'Islamic Azad University Fars Science & Research Branch',
    web_page: 'http://www.fsriau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjUcPTyA-fpMre4SeJv1cVGvCF5ukmZfQSJ0sRv5LbSYJ8DEgZh8C_Rg',
    id: 4418,
    acronym: 'IAUFS&RB',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'fums.ac.ir',
    name: 'Fasa Faculty of Medical Sciences',
    web_page: 'http://www.fums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPm_iAKgSyhsCoKoZHn9HEuBoE7_bsfz9FoTalEcrx3o7Ce9yhlD1-8TY-pQ',
    id: 4419,
    acronym: 'FFMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'gau.ac.ir',
    name: 'Gorgan University of Agricultural Sciences and Natural Resources',
    web_page: 'http://www.gau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQHfVun_GcRi8rgzXPG9FGC15L2NZcr1O7nLWnRe1UiokjuAnOVZ5UwsXuXQ',
    id: 4420,
    acronym: 'GUASNR',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'gmu.ac.ir',
    name: 'Gonabad University of Medical Sciences',
    web_page: 'http://www.gmu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm6asvI03mcOHqtppcTAjlhxQtq_ZfRcS7BomhI4uWKgp8Xf8rlFw-tgs',
    id: 4421,
    acronym: 'GUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'gorganiau.ir',
    name: 'Islamic Azad University Gorgan',
    web_page: 'http://www.gorganiau.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4422,
    acronym: 'IAUG',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'goums.ac.ir',
    name: 'Golestan University of Medical Sciences',
    web_page: 'http://www.goums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOTSLSko86Q8y-87jBILwNYg4NxOVbyYDZzgphTmJLDWzxg8PnOYN2wJQ',
    id: 4423,
    acronym: 'GUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'guilan.ac.ir',
    name: 'Guilan University',
    web_page: 'http://www.guilan.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIz3zyFmv457huq_T2N3alnnxB7JOnHrrrDlv-Ewbg8vGQ97fwH5CGId8',
    id: 4424,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'gums.ac.ir',
    name: 'Guilan University of Medical Sciences',
    web_page: 'http://www.gums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEnhuzjS4ExzZ_1oMf9DVN_Kg8fJ30lTxViLQ69CHBU-89-0CuUQAWRLk',
    id: 4425,
    acronym: 'GUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'hormozgan.ac.ir',
    name: 'University of Hormozgan',
    web_page: 'http://www.hormozgan.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6Hg7-lKaQ9As3oR5BaLVqE4ROgNJXyS31hLtsi0p0qYrA60Pj7yThhw',
    id: 4426,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'hums.ac.ir',
    name: 'Hormozgan University of Medical Sciences',
    web_page: 'http://www.hums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThABjnhihL4itAhdMdvjrR9TYl33MkGA_mYlR90criunajbRpjlPd5dN4s',
    id: 4427,
    acronym: 'HUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'hyrcania.ac.ir',
    name: 'Hyrcania Institute of Higher Education',
    web_page: 'http://www.hyrcania.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQURuCfO5uFYvfC_LkAipBb8MagfJhEepvjZ6nJOlaq4bdLp1O_yX81jA',
    id: 4428,
    acronym: 'HIHE',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iasbs.ac.ir',
    name: 'Zanjan Institute for Advanced Studies in Basic Sciences',
    web_page: 'http://www.iasbs.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDwunvfNNFs1VEdNzp6FvPuckoOeS9eb-kP5-Rt0IP0T8vHNOr8I0gRLw',
    id: 4429,
    acronym: 'ZIFASIBS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-ahar.ac.ir',
    name: 'Islamic Azad University Ahar',
    web_page: 'http://www.iau-ahar.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfqQ55BFSmpdpTmyMJX7DtjRWr23LFxKnNAnDXMvhVSf5eVsqISGPQvQ',
    id: 4430,
    acronym: 'IAUA',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-arak.ac.ir',
    name: 'Islamic Azad University Arak',
    web_page: 'http://www.iau-arak.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsYc8GjQBdZEXjdbPMFzNdRRG0TAwWEYEkptLxBhBj8MpEjjGM9iFpwn8',
    id: 4431,
    acronym: 'IAUA',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauardabil.ac.ir',
    name: 'Islamic Azad University Ardabil',
    web_page: 'http://www.iauardabil.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4432,
    acronym: 'IAUA',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauba.ac.ir',
    name: 'Islamic Azad University Bandar Abbas',
    web_page: 'http://www.iauba.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4433,
    acronym: 'IAUBA',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iaubos.ac.ir',
    name: 'Islamic Azad University Bostanabad',
    web_page: 'http://www.iaubos.ac.ir/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTb5sVz71KHHrBf3K5JoNNQq6obXIYl2ycGE7k_qS3wCpHk8uYIVP7t_60',
    id: 4434,
    acronym: 'IAUB',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-boukan.ac.i',
    name: 'Islamic Azad University Boukan',
    web_page: 'http://www.iau-boukan.ac.ir',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb5sVz71KHHrBf3K5JoNNQq6obXIYl2ycGE7k_qS3wCpHk8uYIVP7t_60',
    id: 4435,
    acronym: 'IAUB',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauctb.ac.ir',
    name: 'Islamic Azad University Central Tehran Branch',
    web_page: 'http://www.iauctb.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbQDMgFpMPqYvNbGOTNWZeN6XYJao7jteINyqLEFreP1SWcZDn4BZz4w',
    id: 4436,
    acronym: 'IAUCTB',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-garmsar.ac.ir',
    name: 'Islamic Azad University Garmsar',
    web_page: 'http://www.iau-garmsar.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfbJU-UE4ljzJklJP9Par7CTPZtFVKnrjZIYfW0rtZLueOgi86chuCGZk',
    id: 4437,
    acronym: 'IAUG',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauh.ac.ir',
    name: 'Islamic Azad University Hamedan',
    web_page: 'http://www.iauh.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4438,
    acronym: 'IAUH',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauk.ac.ir',
    name: 'Islamic Azad University Kerman',
    web_page: 'http://www.iauk.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuTCU9-g7YUbz-B1x2W6XR5JYHS5HLnhPZhZOgK6x4RJWav9_6pEI6VQ',
    id: 4439,
    acronym: 'IAUK',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iaukb.ir',
    name: 'Islamic Azad University Karaj',
    web_page: 'http://www.iaukb.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKAlU7lSroQfS2OIM_tzGEbYY8LkoBrmWctHkYZWSkJVLf_LtRliF9lJ1pAg',
    id: 4440,
    acronym: 'IAUK',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-lahijan.ac.ir',
    name: 'Islamic Azad University Lahijan',
    web_page: 'http://www.iau-lahijan.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4441,
    acronym: 'IAUL',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iaumajlesi.ac.ir',
    name: 'Islamic Azad University Majlesi',
    web_page: 'http://www.iaumajlesi.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4442,
    acronym: 'IAUM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iaumehriz.com',
    name: 'Islamic Azad University Mehriz',
    web_page: 'http://www.iaumehriz.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHgHLnaZKFQoS48-dTANLFLhT6HxICHRW7nzxU1FinNrlkTt20KA3pC0Y',
    id: 4443,
    acronym: 'IAUM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iaun.ac.ir',
    name: 'Islamic Azad University Najaf Abad',
    web_page: 'http://www.iaun.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShl1JNJkyDqPAgxRn6deIwoF0ee0e0lkwO_S2vstDJLHTrfKXIxux8WHY',
    id: 4444,
    acronym: 'IAUNA',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauq.ac.ir',
    name: 'Islamic Azad University Quchan',
    web_page: 'http://www.iauq.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4445,
    acronym: 'IAUQ',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauro.ac.ir',
    name: 'Islamic Azad University Region One',
    web_page: 'http://www.iauro.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0XOUpxdM_ySdSoQjeULmvJycTwL-miKvZFhD2lrDy64lAMFBLQd9NF_E',
    id: 4446,
    acronym: 'IAURO',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-saveh.ac.ir',
    name: 'Islamic Azad University Saveh',
    web_page: 'http://www.iau-saveh.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRah2COYIgGkI8pxn5KkF4h-ZccHXyflTXnMoWHsz-sKPALg59iBni_LzKg',
    id: 4447,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iausdj.ac.ir',
    name: 'Islamic Azad University Sanandaj',
    web_page: 'http://www.iausdj.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHgHLnaZKFQoS48-dTANLFLhT6HxICHRW7nzxU1FinNrlkTt20KA3pC0Y',
    id: 4448,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iaushab.ac.ir',
    name: 'Islamic Azad University Shabestar',
    web_page: 'http://www.iaushab.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4449,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-shahrood.ac.ir',
    name: 'Islamic Azad University Shahrood',
    web_page: 'http://www.iau-shahrood.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScgx8NjBh2VZ8n8P_ym6gFH099wwgXRObqsSgOVkVX1q_vh_LaNvQUQgc',
    id: 4450,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iaushiraz.ac.ir',
    name: 'Islamic Azad University Shiraz',
    web_page: 'http://www.iaushiraz.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ3GP1cnEcDaoBWxRnefOmD_n5C1LDl9ytI8zaI0_PJBUHey-gxx0WB6Q',
    id: 4451,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-shirvan.ac.ir',
    name: 'Islamic Azad University Shirvan',
    web_page: 'http://www.iau-shirvan.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHgHLnaZKFQoS48-dTANLFLhT6HxICHRW7nzxU1FinNrlkTt20KA3pC0Y',
    id: 4452,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iausr.ac.ir',
    name: 'Islamic Azad University Shahr-e-rey Branch',
    web_page: 'http://www.iausr.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4453,
    acronym: 'IAUSB',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iaut.ac.ir',
    name: 'Islamic Azad University Tabriz',
    web_page: 'http://www.iaut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4454,
    acronym: 'IAUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iautb.ac.ir',
    name: 'Islamic Azad University Tafresh',
    web_page: 'http://www.iautb.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRah2COYIgGkI8pxn5KkF4h-ZccHXyflTXnMoWHsz-sKPALg59iBni_LzKg',
    id: 4455,
    acronym: 'IAUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iautmu.ac.ir',
    name: 'Islamic Azad University Tehran Medical',
    web_page: 'http://www.iautmu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW6XFnlnWC-pzcIIjR4FXJljBoonCT5mYBaw2NXPzNuHR7SE8X7_1bKU0',
    id: 4456,
    acronym: 'IAUTM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iau-tnb.ac.ir',
    name: 'Islamic Azad University North Tehran',
    web_page: 'http://www.iau-tnb.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo1tIhOaD0GGObETZB0tuGFG6sBY6TEk_uOauCQSJQtRh5Q7YWV6duSdU',
    id: 4457,
    acronym: 'IAUNT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauvaramin.ac.ir',
    name: 'Islamic Azad University Varamin',
    web_page: 'http://www.iauvaramin.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4458,
    acronym: 'IAUV',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iauyazd.ac.ir',
    name: 'Islamic Azad University Yazd',
    web_page: 'http://www.iauyazd.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4459,
    acronym: 'IAUY',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ikiu.ac.ir',
    name: 'Imam Khomeini International University',
    web_page: 'http://www.ikiu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOKXvnlYi96RmbR_VlG6_QP0Zo8MJ11TxekJmw4wzun83M-LPXxPN2kBo',
    id: 4460,
    acronym: 'IKIU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ilam.ac.ir',
    name: 'Ilam University',
    web_page: 'http://www.ilam.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1b32RtkKIBdOJqviFZEaYxo0BLTIlZ_DVdKJxyFAS-he_tMD6WeElRA',
    id: 4461,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'imamreza.ac.ir',
    name: 'Imam Reza University',
    web_page: 'http://www.imamreza.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEWDY-nLreiGjzgDeHVmLwAfVZp44955_dNcT7BkbInzzmcTKH2AueHLA',
    id: 4462,
    acronym: 'IRU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'isu.ac.ir',
    name: 'Imam Sadiq University',
    web_page: 'http://www.isu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGN88gYxNFbs34w9J6g8ANTFWjNRKnDSNO3pNlBlpoxEj3cRXUmDYdtuc',
    id: 4463,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iums.ac.ir',
    name: 'Iran University of Medical Sciences',
    web_page: 'http://www.iums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS6BtZwib3ztiIMLkJOutnQDqiDsl14IW2MXH1uVQwG8lSgaLsHNGt6Q',
    id: 4464,
    acronym: 'IUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iust.ac.ir',
    name: 'Iran University of Science and Technology Tehran',
    web_page: 'http://www.iust.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvj8VUOpepqHW3zMCpdQckkWhfkIgwKrgmKR7q8EVfbXXxbR_5wQqmyvA',
    id: 4465,
    acronym: 'IUSTT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'iut.ac.ir',
    name: 'Isfahan University of Technology',
    web_page: 'http://www.iut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv8kagM8eBsTpxjskGWM4S-cMxbwn6NOtRxMfb2pIn1yEWItjZddTRXA',
    id: 4466,
    acronym: 'IUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'jami.ac.ir',
    name: 'Jami Institute of Technology',
    web_page: 'http://www.jami.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIDqMMSx_-gEjXewrRIqrf7SsJbvWcsNKhoJZD6cJmzsM6KdkzXq8sVCI',
    id: 4467,
    acronym: 'JIT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'janahiau.ac.ir',
    name: 'Islamic Azad University Janah',
    web_page: 'http://www.janahiau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb5sVz71KHHrBf3K5JoNNQq6obXIYl2ycGE7k_qS3wCpHk8uYIVP7t_60',
    id: 4468,
    acronym: 'IAUJ',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'jums.ac.ir',
    name: 'Jahrom University of Medical Sciences',
    web_page: 'http://www.jums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ28GtPzLNmQTIVZotvKhruBMXsl-TfYWXTDxuS915Uic21KGC9O1r-5OA',
    id: 4469,
    acronym: 'JUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kamalolmolk.ac.ir',
    name: 'Kamalolmolk Art & Architecture Higher Education Institute of Noshahr',
    web_page: 'http://www.kamalolmolk.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlu0u7hkdDvXpfYjZDRDVTA8x7g_hlURsJcU5GS-sZMoJTPvKC6Tl-yg',
    id: 4470,
    acronym: 'KA&AHEIN',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'karuniversity.com',
    name: 'Kar University',
    web_page: 'http://www.karuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeQAoq8W-m37_WlEfMm5WyjiBQ87DlC8nduVdsMOUbahTuA7dV57B3gh0',
    id: 4471,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kashanu.ac.ir',
    name: 'Kashan University',
    web_page: 'http://www.kashanu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCsAmWPC6Cyj9yObVGezX54O0mQkFZKqPOt4qGYyuuQMzi5vDaLoNudy0',
    id: 4472,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kau.ac.ir',
    name: 'Islamic Azad University Kazeron',
    web_page: 'http://www.kau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4473,
    acronym: 'IAUK',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kaums.ac.ir',
    name: 'Kashan University of Medical Sciences',
    web_page: 'http://www.kaums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQV3IwpsfDiHWIuLSgQ_nh5EK01DkZcPwhzn-ljlgYeC5rtLx2nbgTkuPNF',
    id: 4474,
    acronym: 'KUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kgut.ac.ir',
    name: 'Kerman Graduate University of Technology',
    web_page: 'http://www.kgut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Gd687NhPNXFQUiiQkPBvNnA7hTMZXjVfIzC7ultALDr9hzbZowPUsQ',
    id: 4475,
    acronym: 'KGUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'khuisf.ac.ir',
    name: 'Islamic Azad University Khorasgan',
    web_page: 'http://www.khuisf.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgxrLuYzdqdkGgExgqsc-9kh5MAQi-aJN1g6bUdaeVQ8gZRTMDzLC0dKk',
    id: 4476,
    acronym: 'IAUK',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kish.ac.ir',
    name: 'Sharif University of Technology Kish Campus',
    web_page: 'http://www.kish.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcASJJxMbSXpeyORNXMBf-RpsG-pSJldNgT1Baq8GR3-Yz0n__MRQjpw',
    id: 4477,
    acronym: 'SUTKC',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'km.isfpnu.ac.ir',
    name: 'Khomeinishahr Payame Noor University',
    web_page: 'http://km.isfpnu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrJjPwqSZqm0hDf3OnkPNug9oM7q7BPfL3HLQFGQNEfy8eFbjAlQloU9c',
    id: 4478,
    acronym: 'KPNU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kmu.ac.ir',
    name: 'Kerman University of Medical Sciences',
    web_page: 'http://www.kmu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUdn_MvYV1YW3xh-WT24P4RihJuzgbPttLznGqgFr8rccVoP7a3dcsAw',
    id: 4479,
    acronym: 'KUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kntu.ac.ir',
    name: 'K.N.Toosi University of Technology',
    web_page: 'http://www.kntu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUfQenZioC6rhH6lJTX9xF3JsCTeIYaG6lCWNhq_QgqldAPnz77ZElPQ',
    id: 4480,
    acronym: 'KUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'kums.ac.ir',
    name: 'Kermanshah University of Medical Sciences',
    web_page: 'http://www.kums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSY_k87fAnW3ek0gX4_NFVEHJra845tBm6ZZKtDcyITBH_sE14TxZJFUsg',
    id: 4481,
    acronym: 'KUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'lavasanpnu.ac.ir',
    name: 'University of Lavasanat Pnu',
    web_page: 'http://www.lavasanpnu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLghd57gZFLql-HX5EoxyJsrbB2t3SZyfQ0gt8GE48GoKY33ZrvO3QmVxd',
    id: 4482,
    acronym: 'ULP',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'lu.ac.ir',
    name: 'Lorestan University',
    web_page: 'http://www.lu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTTqzNr8Q2o99qSn0SLHx8BYxKZznsOmvjW2_X8zlMj-BvMfBl-H81XA',
    id: 4483,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'lums.ac.ir',
    name: 'Lorestan University of Medical Sciences',
    web_page: 'http://www.lums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZfVvvxMAyVCPMRcGHwk9pQoSDXLjefHN9fsv6oYzlMABFOO_wY_s_mfo',
    id: 4484,
    acronym: 'LUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mahshahriau.ac.ir',
    name: 'Islamic Azad University Mahshar',
    web_page: 'http://www.mahshahriau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHgHLnaZKFQoS48-dTANLFLhT6HxICHRW7nzxU1FinNrlkTt20KA3pC0Y',
    id: 4485,
    acronym: 'IAUM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'malayeru.ac.ir',
    name: 'Malayer University',
    web_page: 'http://www.malayeru.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 4486,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'malekanpnu.ac.ir',
    name: 'Malekan Payame Nour University',
    web_page: 'http://www.malekanpnu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrJjPwqSZqm0hDf3OnkPNug9oM7q7BPfL3HLQFGQNEfy8eFbjAlQloU9c',
    id: 4487,
    acronym: 'MPNU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mamasaniiau.ac.ir',
    name: 'Mamasani University of Technology',
    web_page: 'http://www.mamasaniiau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGVk_6WVds3TkBOrjjrspsx3iMDG0-hxz7Iq7dkNeOqHIdM7cqje9Rkw',
    id: 4488,
    acronym: 'MUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mau.ac.ir',
    name: 'Islamic Azad University Mobarakeh',
    web_page: 'http://www.mau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb5sVz71KHHrBf3K5JoNNQq6obXIYl2ycGE7k_qS3wCpHk8uYIVP7t_60',
    id: 4489,
    acronym: 'IAUM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'maybod.ac.ir',
    name: 'Islamic Azad University Maybod',
    web_page: 'http://www.maybod.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4490,
    acronym: 'IAUM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mazums.ac.ir',
    name: 'Mazandaran University of Medical Science',
    web_page: 'http://www.mazums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCa4Y2DZpyy_R8uU_UuYVV9Doosatavcz7fEynzSF_xTsKFNI3XPLSJJM',
    id: 4491,
    acronym: 'MUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'medilam.hbi.ir',
    name: 'Ilam University of Medical Sciences',
    web_page: 'http://www.medilam.hbi.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrlkf2wivZnt1_nbV6aTvlXX31b4d_PZ9jrtG9nQg6-Hdu2ZcdcQXLsW8',
    id: 4492,
    acronym: 'IUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'medsab.ac.ir',
    name: 'Sabzevar School of Medical Sciences',
    web_page: 'http://www.medsab.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvkKWt35EV-lYZ-qYOdSvu0kIUheomuDm_h-W6lCbGCjOqJNTDMRANDA',
    id: 4493,
    acronym: 'SSMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'miu.ac.ir',
    name: 'Mustafa International University',
    web_page: 'http://www.miu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVvId32eP7aEIozXFwhFMZ_ify2q2VK5u3JiKmqoXajZ4FRpBVImpnCUYy',
    id: 4494,
    acronym: 'MIU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'modares.ac.ir',
    name: 'Tarbiat Modares University',
    web_page: 'http://www.modares.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOQ96XZMnqK6A1KDMjqRhM20ZichXuCCl0BRJOK2B-wuXYjnTE5Pa8jQ',
    id: 4495,
    acronym: 'TMU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mofidu.ac.ir',
    name: 'Mofid University',
    web_page: 'http://www.mofidu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 4496,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mshdiau.ac.ir',
    name: 'Islamic Azad University Mashhad',
    web_page: 'http://www.mshdiau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS20W5fwVY8vUWxCZ1LO3xR0XYUy3YxCdRmXsSj_3QtF5mu49wOp00kY88',
    id: 4497,
    acronym: 'IAUM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mubabol.ac.ir',
    name: 'Babol University of Medical Sciences',
    web_page: 'http://www.mubabol.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNLZTl6jnf1XfOcrKO4CHHdIkLsIc7BOt8sB6cKEN6PEvDuWB0Zj90SUE',
    id: 4498,
    acronym: 'BUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mui.ac.ir',
    name: 'Isfahan University of Medical Sciences',
    web_page: 'http://www.mui.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK8XZ62-oBJDj2LwZUgzh9zKnKJNjK-imNSZdfbTFblyESm9PkUh1PQXwA_Q',
    id: 4499,
    acronym: 'IUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'muk.ac.ir',
    name: 'Kurdistan University of Medical Sciences',
    web_page: 'http://www.muk.ac.ir/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRxdi31N9UeHvwEtmJfJSezO7YTVz5I_LRx5a2K3swqzVmmxinD-9am2_Y',
    id: 4500,
    acronym: 'KUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'mums.ac.ir',
    name: 'Mashhad University of Medical Sciences',
    web_page: 'http://www.mums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU81vymTszrHz28tFT-ZGpHQQeCo6LRxNuXVXcmlIcD2d-8Vm4QptOr14',
    id: 4501,
    acronym: 'MUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'muq.ac.ir',
    name: 'Qom University of Medical Sciences',
    web_page: 'http://www.muq.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzl4STi833TFrAK0snb1c12Ss8i26MYeoNU4NJV-xMWhiwkjHDEDU0bNw',
    id: 4502,
    acronym: 'QUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'najafabad-uast.ac.ir',
    name: 'Najaf Abad University of Applied Science and Technology',
    web_page: 'http://www.najafabad-uast.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTka2fvSGFApPNd8PGh4wjRD-pFyWOA_Coj4Za4oud_pxlU7LLQfgB_yQ',
    id: 4503,
    acronym: 'NAUAST',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'nit.ac.ir',
    name: 'Babol Noshirvani University of Technology',
    web_page: 'http://www.nit.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQR-uJjOQHhzjhBXq5XSrlke_PJNUmdyLPWusGXjH-aWJDvgbbdVeNBUw',
    id: 4504,
    acronym: 'BNUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'persianacademy.ir',
    name: 'Iranian Academy of Persian Language and Literature',
    web_page: 'http://www.persianacademy.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6lel0PpSljhhXdIXnFJ1J8ylWgLkH_po55a4GGaKt1IuCX3gFdA1NzA',
    id: 4505,
    acronym: 'IAPLL',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'pgu.ac.ir',
    name: 'Persian Gulf University',
    web_page: 'http://www.pgu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn8Cy7ecf7wlvcpEu6eH016yhFF6A2RvfMsI41sOGNjOJgHi9b283uCxcPTQ',
    id: 4506,
    acronym: 'PGU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'piau.ac.ir',
    name: 'Islamic Azad University Parand',
    web_page: 'http://www.piau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4507,
    acronym: 'IAUP',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'pnu.ac.ir',
    name: 'Payame Noor University',
    web_page: 'http://www.pnu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb0ARpdARZbjWndu4shXLKehQKtLWzzlGao2iDiFBgRYOeK0tZAUHvWDw',
    id: 4508,
    acronym: 'PNU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'pnum.ac.ir',
    name: 'University of Mashhad Pnu',
    web_page: 'http://www.pnum.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwGGmMwkNIFrUaYOl4nltXjCh_gFj-meW51pS4sO9ypJn6VuOCZYqDoA',
    id: 4509,
    acronym: 'UMP',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'pnut.ac.ir',
    name: 'Payame Noor University Tafresh',
    web_page: 'http://www.pnut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrJjPwqSZqm0hDf3OnkPNug9oM7q7BPfL3HLQFGQNEfy8eFbjAlQloU9c',
    id: 4510,
    acronym: 'PNUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'put.ac.ir',
    name: 'Petroleum University of Technology',
    web_page: 'http://www.put.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU9aJ8mhzo7Eo6rwlAn4XlEPtksruTU6QakpUFy518Fcm5iz7MpWf95w',
    id: 4511,
    acronym: 'PUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'pwit.ac.ir',
    name: 'Power and Water Institute of Technology',
    web_page: 'http://www.pwit.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa7vxPpxBXzJ2f8LPP9bb7aQCwU_NxFS9jVjpc9UkN6JaRaNRkoD42OVU',
    id: 4512,
    acronym: 'PWIT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'qazviniau.ac.ir',
    name: 'Islamic Azad University Qazvin',
    web_page: 'http://www.qazviniau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvNfpWBbFMcUyQCNsFQrf4b5nDnJ5n-hoJpbwSgNpiTNKLX-IoUftbzg',
    id: 4513,
    acronym: 'IAUQ',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'qom.ac.ir',
    name: 'Qom University',
    web_page: 'http://www.qom.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4514,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'qom-iau.ac.ir',
    name: 'Islamic Azad University Qom',
    web_page: 'http://www.qom-iau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4515,
    acronym: 'IAUQ',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'qums.ac.ir',
    name: 'Qazvin University of Medical Sciences',
    web_page: 'http://www.qums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOxtpmFbVEPo2rU_xJkv48YPirsBb0vTCBBJJRtbwof2p2G11mJvYwbw',
    id: 4516,
    acronym: 'QUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'qut.ac.ir',
    name: 'Qom University of Technology',
    web_page: 'http://www.qut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD3F5tSeF9TJrGpxs5K36VqfkbLr0t-LhbMOoy0Ig7WoLS9XjFBvxWpPw',
    id: 4517,
    acronym: 'QUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'raghebisf.ac.ir',
    name: 'Raghebe Esfahani University',
    web_page: 'http://www.raghebisf.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHC0hO0j7hxPOHrDpZCeDXUyyuJ5m94piDnfbF7_mxUB0huPEzPfVmQhw',
    id: 4518,
    acronym: 'REU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'razi.ac.ir',
    name: 'Razi University',
    web_page: 'http://www.razi.ac.ir/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQBpdiZ3jX8ULTs_i7_x_83XYIsgc_fuP-HmPTC0dahqG7OUUUXwJnAngrL',
    id: 4519,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'riau.ac.ir',
    name: 'Islamic Azad University Roodehen',
    web_page: 'http://www.riau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4520,
    acronym: 'IAUR',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'rums.ac.ir',
    name: 'Rafsanjan University of Medical Sciences',
    web_page: 'http://www.rums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNuDGji1Yvd_Gk8vZ47jiUr3gULIVGTWMc2M8tkuFcKZUpgWTqefiF1TA',
    id: 4521,
    acronym: 'RUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sadjad.ac.ir',
    name: 'Sadjad Institute of Technology',
    web_page: 'http://www.sadjad.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyMXmgLZ0AgZyfQ47iJ8KaSUdh7neU7kVF2UESrrWE1hnNrD2J4c4_ug',
    id: 4522,
    acronym: 'SIT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sadra.ac.ir',
    name: 'Sadra University',
    web_page: 'http://www.sadra.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 4523,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sbmu.ac.ir',
    name: 'Shaheed Beheshti University of Medical Sciences',
    web_page: 'http://www.sbmu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWVQ5VT5C2ifPQVY4Q-yJvqTVKJLcqCCg1CdfVcn8MfYpjzR8pa3IsYg',
    id: 4524,
    acronym: 'SBUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sbu.ac.ir',
    name: 'Shaheed Beheshti University',
    web_page: 'http://www.sbu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiV5y1imB3DGySxk7n1gspGrL8GMjescbOHBBYnDHSWWIWoCYgbq9kCw',
    id: 4525,
    acronym: 'SBU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sdi.ac.ir',
    name: 'Daneshestan Institute of Higher Education',
    web_page: 'http://www.sdi.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-cJLyEGw6lbcedwStJh_stPzruWzR5GEqzUoNNYwdPdkkkol20AJ7qw',
    id: 4526,
    acronym: 'DIHE',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'semnan.ac.ir',
    name: 'Semnan University',
    web_page: 'http://www.semnan.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtZM63bsLvGtEJwrL7J7h2MLo-qdUJCQeO_TStUwmSee8nYUaX0u9s-HiG',
    id: 4527,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'semnaniau.ac.ir',
    name: 'Islamic Azad University Semnan',
    web_page: 'http://www.semnaniau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyzCLFafPsFt_olusoeGMtUB79Fx5GmeIuwJnssKSiBIXKNUa6WOwwqy4',
    id: 4528,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sem-ums.ac.ir',
    name: 'Semnan University of Medical Sciences',
    web_page: 'http://www.sem-ums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmhoTxmXsc2VLm1HEjeH0LKAUfgw6NB-6YPTGlV_9toJcyrx8xRYwWbsg',
    id: 4529,
    acronym: 'SUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ses.ac.ir',
    name: 'School of Economic Sciences',
    web_page: 'http://www.ses.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV-VfLRQiPVKofeZ1OSBfRraz5gFR2FbW0sPT3ibU6z6E2EjA2CWvxVYs',
    id: 4530,
    acronym: 'SES',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'shahed.ac.ir',
    name: 'Shahed University',
    web_page: 'http://www.shahed.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQf0C-alWRtmWySv3rJXWf1TkwZCDVoqtJbcrdkyXE6LiCqgUR_eQoks8',
    id: 4531,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'shahroodut.ac.ir',
    name: 'Shahrood University of Technology',
    web_page: 'http://www.shahroodut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh-sJP-h1V_uQv3V_WYP51QqaFwpUwGNbLFgmLmnIw4rx17Ym0Ox8llg',
    id: 4532,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'shahryariau.ac.ir',
    name: 'Islamic Azad University Shahryar',
    web_page: 'http://www.shahryariau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8HBCE-GfoTSzVFOvsLddJEIX1RVE9lsJUSOumHRrxgbZgfJv1c5AnlRk',
    id: 4533,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sharif.ac.ir',
    name: 'Sharif University of Technology',
    web_page: 'http://www.sharif.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgFN9BYZulZVJZyUcfgxN-LJm0ZiFDQX2vNPQl_etE8-0cusc6GOBHgg',
    id: 4534,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'shbu.ac.ir',
    name: 'Sheikhbahaee University',
    web_page: 'http://www.shbu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4wRwkg1OHjZQnmEIyXhnE7whpe5mdEyluxWGF6h5THV8bPmbFABEQ_A',
    id: 4535,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'shirazu.ac.ir',
    name: 'Shiraz University',
    web_page: 'http://www.shirazu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6z9tsbvPkIfF1h06hf2wndauOehNJ-tF-wNkkuwIc9UDJc0bwxs55_U4',
    id: 4536,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'shmu.ac.ir',
    name: 'Shahrood University of Medical Sciences',
    web_page: 'http://www.shmu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfHMdPFXiPe8UGUVjXD2lx_ttwNG28rStfdg-N-h2z4qAD4p6aUdF-Jg',
    id: 4537,
    acronym: 'SUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'shomal.ac.ir',
    name: 'Shomal University',
    web_page: 'http://www.shomal.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo1tIhOaD0GGObETZB0tuGFG6sBY6TEk_uOauCQSJQtRh5Q7YWV6duSdU',
    id: 4538,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sku.ac.ir',
    name: 'Shahrekord University',
    web_page: 'http://www.sku.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTreHShl-tgyv1ZfEstr4_p_QDob_MzSx87NQQQm5-e5jkQQK0BpCrj60oy',
    id: 4539,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'skums.ac.ir',
    name: 'Shahrekord University of Medical Sciences',
    web_page: 'http://www.skums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCA-FQIoUseiT-TR0KiftarRnLpY5IVKmbpn-bOzn-NCi9JOhdKaGyIsc',
    id: 4540,
    acronym: 'SUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sowmesaraiau.ac.ir',
    name: 'Islamic Azad University Sowmesara',
    web_page: 'http://www.sowmesaraiau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTb5sVz71KHHrBf3K5JoNNQq6obXIYl2ycGE7k_qS3wCpHk8uYIVP7t_60',
    id: 4541,
    acronym: 'IAUS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'spnu.ac.ir',
    name: 'Shiraz Payamnoor University',
    web_page: 'http://www.spnu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbgiQI8_MImzROA8F8onn1J2WkxT7oz25_xt_uMrRM7CPS8qsSyU3SDQ',
    id: 4542,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'srbiau.ac.ir',
    name: 'Islamic Azad University Tehran Science & Research Branch',
    web_page: 'http://www.srbiau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_ubFUyrnNl6AzmPCMndmnKOSOcL_JD-E3vr3bBXywSpDRA6x7nsFBzw',
    id: 4543,
    acronym: 'IAUTS&RB',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'srttu.edu',
    name: 'Shaheed Rajaei Teacher Training University',
    web_page: 'http://www.srttu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCfsaLr30lNs-V3WPLHcMyD9Z4At1HnxWmrb_2ZsZNuJshkxd3hyffbFQ',
    id: 4544,
    acronym: 'SRTTU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ssu.ac.ir',
    name: 'Yazd Medical University',
    web_page: 'http://www.ssu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVg6UNfKuH6z56E0c1rOzYlDdpD5WvTF2xmTjYHpk856-bpNWqZoIIMZ8',
    id: 4545,
    acronym: 'YMU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sttu.ac.ir',
    name: 'Sabzevar Teacher Training University',
    web_page: 'http://www.sttu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYs3EOgJUZqNLiVNHgEVSXO-uytRbz6qK30c0fVVleheXBcnSPxYYAJQ',
    id: 4546,
    acronym: 'STTU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sums.ac.ir',
    name: 'Shiraz University of Medical Sciences',
    web_page: 'http://www.sums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbO63CofJMM65u3wHlRSqfxQErtSHpk5ot5tpaFgwY0YsApSEhyx7XUw',
    id: 4547,
    acronym: 'SUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sut.ac.ir',
    name: 'Sahand University of Technology',
    web_page: 'http://www.sut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC9iy_HcGKoJSjY5bxfKilKXMRKe2XFRHJoqEWK1IzZ7WZnIZ8p8qO050',
    id: 4548,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'sutech.ac.ir',
    name: 'Shiraz University of Technology',
    web_page: 'http://www.sutech.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2XtFXtMF7pWJ-cBoLlH4IjTEG01--r_EpdxlDD8IfD1rhR2n4wq13Dg',
    id: 4549,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'tabriziau.ac.ir',
    name: 'Tabriz Islamic Art University',
    web_page: 'http://www.tabriziau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXI1UyUXFMq9zAAWuRNoCq2Mmg2z5sdJRPMjHEjxaIxlhBn7OzdPw1MxGGjQ',
    id: 4550,
    acronym: 'TIAU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'tabrizu.ac.ir',
    name: 'Tabriz University',
    web_page: 'http://www.tabrizu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC_V9GFvc05vdBt5vn1PYSxYvdwpX72bzPLie2HsZa2k35tRRlbeHEvYk',
    id: 4551,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'tbzmed.ac.ir',
    name: 'Tabriz University of Medical Sciences',
    web_page: 'http://www.tbzmed.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC_V9GFvc05vdBt5vn1PYSxYvdwpX72bzPLie2HsZa2k35tRRlbeHEvYk',
    id: 4552,
    acronym: 'TUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'tct.ac.ir',
    name: 'Tabriz College of Technology',
    web_page: 'http://www.tct.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1yumqkcwO_K42aFTilMYqtF5JAsAgWBeAxtcoeik8AWTz5E3XotxqTck',
    id: 4553,
    acronym: 'TCT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'thums.ac.ir',
    name: 'Torbat Heydarieh University of Medical Sciences',
    web_page: 'http://www.thums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7e02KiHv_fZ34ZZxHIVP-ObEPhPAEkpbpf_ARaBMzeHqXJNBTvoWmSQ',
    id: 4554,
    acronym: 'THUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'tiau.ac.ir',
    name: 'Takestan Islamic Azad University',
    web_page: 'http://www.tiau.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHgHLnaZKFQoS48-dTANLFLhT6HxICHRW7nzxU1FinNrlkTt20KA3pC0Y',
    id: 4555,
    acronym: 'TIAU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'tmu.ac.ir',
    name: 'Tehran University of Teacher Training',
    web_page: 'http://www.tmu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo1tIhOaD0GGObETZB0tuGFG6sBY6TEk_uOauCQSJQtRh5Q7YWV6duSdU',
    id: 4556,
    acronym: 'TUTT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'tums.ac.ir',
    name: 'Tehran University of Medical Sciences',
    web_page: 'http://www.tums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZJWHwAbR5JHLYgzqGC6ERF18q0NyI481EosVXUZhYNAGL1fmvPQYKxw',
    id: 4557,
    acronym: 'TUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'uast.ac.ir',
    name: 'University of Applied Science and Technology',
    web_page: 'http://www.uast.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwhCwEyqYZvBmgsFcNZpGdLmZpX2C3JUwhFbsw7LuvrWuDJ48H4oRnFw',
    id: 4558,
    acronym: 'UAST',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ucna.info',
    name: 'University College of Nabi Akram',
    web_page: 'http://www.ucna.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqDVt9VO2ilVnSEcnWg7MT1cVUlaud8vg09ZApreAgjlVt9vX6qp9ymm7N',
    id: 4559,
    acronym: 'UCNA',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ui.ac.ir',
    name: 'Isfahan University',
    web_page: 'http://www.ui.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyfptoVkLSdj7_mNkeraarfsHcU1o8_pSzgiQnFvosrXTMfXRODt6qoA',
    id: 4560,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'uk.ac.ir',
    name: 'Shahid Bahonar University of Kerman',
    web_page: 'http://www.uk.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTPxv_71hq8byveuJQO0FCQBJX4_KBXYjYVuBHhYV0xVpSaHzw7w_-ka0',
    id: 4561,
    acronym: 'SBUK',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'uma.ac.ir',
    name: 'University of Mohaghegh',
    web_page: 'http://www.uma.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1X7umEjC2gJo__EdeIMLNvEUQCmdVwb-osBcsRJe3LbnRoo5jDcxFYC0',
    id: 4562,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'um.ac.ir',
    name: 'Ferdowsi University of Mashhad',
    web_page: 'http://www.um.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTneag9G6CSRqXFH4Y1Bx8iE5WicSHKT9CY9mo0SXp0GFBwJR0nCCG-AuA',
    id: 4563,
    acronym: 'FUM',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'umsha.ac.ir',
    name: 'Hamadan University of Medical Sciences',
    web_page: 'http://www.umsha.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXM860nE1qSkDrem9GuS_dn6tP4wLOSM9C3NqXHzwoB7c0qktgSo-MlQ',
    id: 4564,
    acronym: 'HUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'umsu.ac.ir',
    name: 'Urmia University of Medical Sciences',
    web_page: 'http://www.umsu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR602Q1_h08-iysIWYli62okyVSEA8V3n8_24W161oyMUkZEz5C2HncdpU',
    id: 4565,
    acronym: 'UUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'umz.ac.ir',
    name: 'Mazandaran University of Iran',
    web_page: 'http://www.umz.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcklIFAjaIbmC0_uO9xUiCFMrIPXv2Xbrdb2KTbdM65lDLUzq_QL_7zgM',
    id: 4566,
    acronym: 'MUI',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'uok.ac.ir',
    name: 'University of Kurdistan',
    web_page: 'http://www.uok.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB5mxhtA_X4PE4erYMDmdiCO0qHOd1R4DB3dt0XNwBQYusIPZRFWNXbQ',
    id: 4567,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'uoz.ac.ir',
    name: 'Zabol University',
    web_page: 'http://www.uoz.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCJg3Ik9bJFtU-xxafa0Xo84VB5CAMEJ5e1ckVos84I8oDn3Nhx7Ru1gA',
    id: 4568,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'urmia.ac.ir',
    name: 'Urmia University',
    web_page: 'http://www.urmia.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzPNWapg4NgqIICFZB0R-sKQsO7rAnG1t4oFKPAPaNPS4zt7RoFcN5YtzRnA',
    id: 4569,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'usb.ac.ir',
    name: 'Sistan & Balouchestan University',
    web_page: 'http://www.usb.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJJLZYLhhVIS0uMs610PKCze1JGkZeOpxhXofiFkrdQr7dJpG0RYKZseQT',
    id: 4570,
    acronym: 'S&BU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'usc.ac.ir',
    name: 'University of Science and Culture',
    web_page: 'http://www.usc.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe94TBPqbZQeCIGMmYB7wQB0mCKePhXvpFfMTtJm74KOM83UVeLZOi1Uo',
    id: 4571,
    acronym: 'USC',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ustmb.ac.ir',
    name: 'Mazandaran University of Science & Technology',
    web_page: 'http://www.ustmb.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcklIFAjaIbmC0_uO9xUiCFMrIPXv2Xbrdb2KTbdM65lDLUzq_QL_7zgM',
    id: 4572,
    acronym: 'MUS&T',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'uswr.ac.ir',
    name: 'University of Social Welfare and Rehabilitation Scinences',
    web_page: 'http://www.uswr.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUdn_MvYV1YW3xh-WT24P4RihJuzgbPttLznGqgFr8rccVoP7a3dcsAw',
    id: 4573,
    acronym: 'USWRS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'ut.ac.ir',
    name: 'University of Tehran',
    web_page: 'http://www.ut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo1tIhOaD0GGObETZB0tuGFG6sBY6TEk_uOauCQSJQtRh5Q7YWV6duSdU',
    id: 4574,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'uut.ac.ir',
    name: 'Urmia University of Technology',
    web_page: 'http://www.uut.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwfYwI-hoRXzy4pufK4qFpLk5gUtKdz6Ci0qjVHezxXI5_6MC8fkHH4A',
    id: 4575,
    acronym: 'UUT',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'vru.ac.ir',
    name: 'Vali-e-Asr University',
    web_page: 'http://www.vru.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8W6kF0-HYiCv1SwzuKHUyvoUn1Mg9iwTOpIHe9g_azWZ6t2S0-4HX8Q',
    id: 4576,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'yazduni.ac.ir',
    name: 'Yazd University',
    web_page: 'http://www.yazduni.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6z9tsbvPkIfF1h06hf2wndauOehNJ-tF-wNkkuwIc9UDJc0bwxs55_U4',
    id: 4577,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'yu.ac.ir',
    name: 'Yasuj University',
    web_page: 'http://www.yu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyO4J7xdsQXW7A7VnB8HoBVOyOFGUev7vURgajYw9dxo918hUd_T-4a-OUgQ',
    id: 4578,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'yums.ac.ir',
    name: 'Yasuj University of Medical Sciences',
    web_page: 'http://www.yums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxKNg-ym_G8bfDaq9iiq_wN9xfeJ7oKmE-6CwoJk6lR8CrEX64Yr2li5Q',
    id: 4579,
    acronym: 'YUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'zdmu.ac.ir',
    name: 'Zahedan University of Medical Sciences',
    web_page: 'http://www.zdmu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqQUV2v2gzUovq9Sqpkgr4ZvdZprx1w-wsaWlkXFap-KELy-eG6-2LSoem',
    id: 4580,
    acronym: 'ZUMS',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'znu.ac.ir',
    name: 'Zanjan University',
    web_page: 'http://www.znu.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_uVSEafGTc3M95O73Ph88sR9reKm7nH6LznXde3xpX4a-VNw_IyxeRA',
    id: 4581,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'IR',
    country: 'Iran, Islamic Republic of',
    domain: 'zums.ac.ir',
    name: 'Zanjan University of Medical Sciences',
    web_page: 'http://www.zums.ac.ir/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp2PT6yo4wh6dDEO6_x2ol2hupROTjQocg_YIG7N7eaiZ-di7pu_J_SQ',
    id: 4582,
    acronym: 'ZUMS',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'ahlulbaitonline.com',
    name: 'Ahlulbait International University',
    web_page: 'http://www.ahlulbaitonline.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzty4jz3Fn1w-Z--NQOZQLJ6Zu7UHANYsDppoc6fc1WBgos8pZEMnaLlMO',
    id: 4583,
    acronym: 'AIU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'almamonuc.org',
    name: 'Al Mamon University College',
    web_page: 'http://www.almamonuc.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR93Y8iZ0tfLkqLmafycY32k-GrI909Gw3-EYxjf1oULaqCEnbJHeUlR5o',
    id: 4584,
    acronym: 'AMUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'alrasheed-uc.com',
    name: 'Al Rasheed University College',
    web_page: 'http://www.alrasheed-uc.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNGtiUcTUlXB8TdmRxW7TnxlzNf-V0--Z_qOtVIjc9j_ZIhl_MyzAa6js',
    id: 4585,
    acronym: 'ARUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'alsadrain.org',
    name: 'Alsadrain University',
    web_page: 'http://www.alsadrain.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8LKGtqZPT99JxKUgLul56pScP8RULFfo9wxABIWWaer05ndz_r3TxEkA',
    id: 4586,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'auc-edu.org',
    name: 'Al Maarif University College',
    web_page: 'http://www.auc-edu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyDyrLRfa6o7vWBVd5Ix9-0V8yiF-KndfQxHXFXelk9tP88e9-yUkuaQ',
    id: 4587,
    acronym: 'AMUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'auis.edu.iq',
    name: 'American University of Iraq Sulaimani (Kurdistan Region)',
    web_page: 'http://www.auis.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmciJy7sB2jy6j87kPd7VvHIM4k1tTzAVYAfHEUYTK8i_u9xfcLSKh7XtY',
    id: 4588,
    acronym: 'AUIS(R',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'baghdadcollege.edu.iq',
    name: 'Baghdad College of Economic Sciences University',
    web_page: 'http://www.baghdadcollege.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiCOZjRY8zOqoO38mZhqLk9r1N6G8HDzAH7xQBNv3HxRHBSScRK5C3qA',
    id: 4589,
    acronym: 'BCESU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'bpc.edu.iq',
    name: 'Baghdad College of Pharmacy',
    web_page: 'http://www.bpc.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYnI3ir-du_6Pw_qopypNBZkLbRdMRkGInbbiAEmcyWyEC573EVOmgjA',
    id: 4590,
    acronym: 'BCP',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'broyalu.net',
    name: 'British Royal University',
    web_page: 'http://broyalu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLy1fNdYMQ2_o83plZcDXxvYLmiKCXp6ovRtvN6SorjEu9jzvCQDZNvSM',
    id: 4591,
    acronym: 'BRU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'cihanuniversity.edu.iq',
    name: 'Cihan University',
    web_page: 'http://www.cihanuniversity.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzHI-mOLFvwJmEkKZ2uM7yEyNxgkZiLvSlfsSKMOf-1w43GpqVgI3IrmI',
    id: 4592,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'coalrafidain.edu.iq',
    name: 'Al Rafidain University College',
    web_page: 'http://www.coalrafidain.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZfVd9xKih1nlccGZp0-7Z_ZNmRTCXm01TlAZ5-0VDwfQNC4oa7G31OQ',
    id: 4593,
    acronym: 'ARUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'duc.edu.iq',
    name: 'Dijla University College',
    web_page: 'http://www.duc.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7HO64GvSNkKS_3dEQObNGTCoy3eZkoFoqCbCmG9knMW_X0xJW5Kw8AA',
    id: 4594,
    acronym: 'DUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'hawlermu.org',
    name: 'Hawler Medical University',
    web_page: 'http://www.hawlermu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbguDrS4K8nQPPBpy8NSH_rpfxtz9eCTjesISKGhW_lBSl6-8bUaOpFMQ',
    id: 4595,
    acronym: 'HMU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'iraquniversity.net',
    name: 'Iraq University College',
    web_page: 'http://www.iraquniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyMAm-cq05uXen4FEAaVPGY7ilvcOnSgESoHteeNayuW1Hc7Lquwm3uw',
    id: 4596,
    acronym: 'IUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'iu.edu.iq',
    name: 'Ishik University',
    web_page: 'http://www.iu.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXSXMFaYbunPaRqPTLDkD4E91_v52vVOreHBCHDqQzuj32oOAMqPv6M4g',
    id: 4597,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'komar.edu.iq',
    name: 'Komar University of Science and Technology',
    web_page: 'http://www.komar.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQEANvsXt9E-6AgijkxgkWbw-TTnam23W-5IQYoeeRKnFD42OH7-MLbg',
    id: 4598,
    acronym: 'KUST',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'koyauniversity.org',
    name: 'Koya University (Kurdistan Region)',
    web_page: 'http://www.koyauniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMvevXZ8RByMFsY0QEzVWbGplJgXa7XyULICXgI00YJxhVdfUU4F2qj1Mu',
    id: 4599,
    acronym: 'KU(R',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'madenatalelem.com',
    name: 'Madenat Alelem University College',
    web_page: 'http://www.madenatalelem.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRY30IioNpEZBb0VzVyS0MLmXLt-IX0bowsu4mvkmoME4w3auVvjR0AgQ',
    id: 4600,
    acronym: 'MAUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'muc.edu.iq',
    name: 'Al Mansour University College',
    web_page: 'http://www.muc.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVk8vbxvdRkKXRfc6-tkGwLHkfKvLkoqlmorHtnSBrSl6REsv4UJQiZXk',
    id: 4601,
    acronym: 'AMUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'nahrainuniv.edu.iq',
    name: 'Al Nahrain University',
    web_page: 'http://www.nahrainuniv.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGFGwcxc_mNMzy1TPIOa8_mgvwIBoUszTQksCMPKs4ES-KU8J_RtHCww',
    id: 4602,
    acronym: 'ANU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'nawrozuniversity.com',
    name: 'Nawroz University',
    web_page: 'http://www.nawrozuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLZhK3ZOWvdvd04USEnJ4jgdm-Yg_JbEe72VFO6jhtqXgaL3B3QWu64ens',
    id: 4603,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'qadissuni.edu.iq',
    name: 'University of Al-Qadisiyah',
    web_page: 'http://www.qadissuni.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMmEHpX0GnPtCXylVN95QQneJH87FIuMCRa4reFjklZhYCKtKCEYZ0vA',
    id: 4604,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'scbaghdad.edu.iq',
    name: 'College of Science Baghdad University',
    web_page: 'http://www.scbaghdad.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYnI3ir-du_6Pw_qopypNBZkLbRdMRkGInbbiAEmcyWyEC573EVOmgjA',
    id: 4605,
    acronym: 'CSBU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'soran.edu.iq',
    name: 'Soran University',
    web_page: 'http://www.soran.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiI9RCT5duJ4gK72soVhL_vRYoHHT8M8I3_7sLgsHcEXTsDU4oe7Ors6s',
    id: 4606,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'suh-edu.com',
    name: 'Salahddin University (Kurdistan Region)',
    web_page: 'http://www.suh-edu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeb3_7oOQYq4M3C7URNF5QunNwLXwWmYymv9oGvMQ7Hj3iqoIVixyyNNY6',
    id: 4607,
    acronym: 'SU(R',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'thiqaruni.org',
    name: 'Thi Qar University',
    web_page: 'http://www.thiqaruni.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzN6AucIxe91EUyRtvYoEoN-J-WjwfUO_yjpnDcU6WO_Zi1CQ4ukdxCb8',
    id: 4608,
    acronym: 'TQU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'tu.edu.iq',
    name: 'Tikrit University',
    web_page: 'http://www.tu.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8LKGtqZPT99JxKUgLul56pScP8RULFfo9wxABIWWaer05ndz_r3TxEkA',
    id: 4609,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'turathun.com',
    name: 'Al Turath University College',
    web_page: 'http://www.turathun.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKCpghxKud_P-tm3Z8Eiepcuiq702DomPX9_ecJdQNtogMJMPoxDUufXw',
    id: 4610,
    acronym: 'ATUC',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uhd.edu.iq',
    name: 'University of Human Development (Kurdistan Region)',
    web_page: 'http://www.uhd.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoRlH-2bjS46HKIHoDUGPxQP2_SB18XyC74ABlL2q7KM7jIfORAYZcKUs',
    id: 4611,
    acronym: 'UHD(R',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'ukh.ac',
    name: 'University of Kurdistan - Hawler',
    web_page: 'http://www.ukh.ac/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3fZbjPns0UhWzyEIbRxnqJ7hykzYTr24ojWnxRAXaB55_Ji8vnukFzcM',
    id: 4612,
    acronym: 'UK-H',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'univsul.org',
    name: 'University of Sulaimania (Kurdistan Region)',
    web_page: 'http://www.univsul.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAn_BYlgFBWkhAnHuEMLFKy4-Jt_-0AuJ7T1yaCU3LBJcEJu2ZbD0WKoSz',
    id: 4613,
    acronym: 'US(R',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uoalmuthana.edu.iq',
    name: 'Al Muthanna University',
    web_page: 'http://www.uoalmuthana.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm4VHj34A0yBxiiz_uYGedMMHBoGcT0VpW9SpLHsiQBjwyMKAF9wC27P0',
    id: 4614,
    acronym: 'AMU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uoanbar.edu.iq',
    name: 'University of Anbar',
    web_page: 'http://www.uoanbar.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ14Lk79LKoM5VMZiBb9SAunRBE-iOrlodTXhNeGNNi-R4bJuzz4QDzCA',
    id: 4615,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uobabylon.edu.iq',
    name: 'Uinversity of Babylon',
    web_page: 'http://www.uobabylon.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQppVR9-aiAOENca-8cuVwGPdb1pkdmVD-fIbrTb19I2uT-ylK6-y4BK0Q',
    id: 4616,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uobaghdad.edu.iq',
    name: 'University of Baghdad',
    web_page: 'http://www.uobaghdad.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYnI3ir-du_6Pw_qopypNBZkLbRdMRkGInbbiAEmcyWyEC573EVOmgjA',
    id: 4617,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uobasrah.edu.iq',
    name: 'University of Basrah',
    web_page: 'http://www.uobasrah.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREDGDAAob10FWw9lYijAFx5KD9jaYxeYf3fXHQp4QbkjPVgq_gohqmJJuf',
    id: 4618,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uod.ac',
    name: 'University of Dohuk (Kurdistan Region)',
    web_page: 'http://www.uod.ac/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDKSc5Xyl6fmsoZs0bGFHTapMnYu35Ymw3ijShPdqZNRjejZ-ZKMj4AJM',
    id: 4619,
    acronym: 'UD(R',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uodiyala.edu.iq',
    name: 'Diyala University',
    web_page: 'http://www.uodiyala.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ74gEYiL4S-xR5uy8HpHns0m3XJhCrYZc9q5uCZmVS_c_-ombFBh1GAHk',
    id: 4620,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uokerbala.edu.iq',
    name: 'Kerbala University',
    web_page: 'http://www.uokerbala.edu.iq/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSAEAY1KfydDt4xz1q-XlrfYNapB3amFnV9Y8lFICTxatAVKUXV-zWSeA',
    id: 4621,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uokirkuk.edu.iq',
    name: 'University of Kirkuk',
    web_page: 'http://www.uokirkuk.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDroe-1q1gCTWrXJqk_ZVAID3Lx5kgbgaqMqp_HO_5ujF6BkWEpY1IKt21',
    id: 4622,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uokufa.edu.iq',
    name: 'University of Kufa',
    web_page: 'http://www.uokufa.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_pd0f5vnjytLF2z_sDAMP8LRetgeV_fA8vLb1w8YaZbV9MozQaBVsKyQ',
    id: 4623,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uomisan.edu.iq',
    name: 'Misan University',
    web_page: 'http://www.uomisan.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSh3UrV5JJ_7vxwuIxGV5qXm_t_d9NNWoXwHEsRGV5CMm9tNHpIZ0SFI4A',
    id: 4624,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uomosul.edu.iq',
    name: 'University of Mosul',
    web_page: 'http://www.uomosul.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOShyQ_n4OvRgtE76Jw1cJFfUinWNpVCECntyGvZAr_5UjzH1vkS0NJ5Iv',
    id: 4625,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uomustansiriyah.edu.iq',
    name: 'University of Mustansiriyah',
    web_page: 'http://www.uomustansiriyah.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcO8XVs_nvBjK3QJlrlhwJlJI3ic1GW3El-kpBWfT2-TKTB-A3NkwMzKU',
    id: 4626,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uotechnology.edu.iq',
    name: 'University of Technology - Iraq',
    web_page: 'http://www.uotechnology.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeg7JNpAiGVFGolocGF0Zvx58eAvaS1ahez79FnEbyExvaEGtCnCjRwIA',
    id: 4627,
    acronym: 'UT-I',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'uowasit.edu.iq',
    name: 'University of Wassit',
    web_page: 'http://www.uowasit.edu.iq/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT61inpFEDbPzxLf5IQE36XNDs_za5k4IoWvvTIgpxXNnZBo80VGmS5dw',
    id: 4628,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'IQ',
    country: 'Iraq',
    domain: 'yu.edu.jo',
    name: 'Al Yarmouk University College',
    web_page: 'http://www.yu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtFzFu1foVY7GS90W8qSN5-OaD2OOMiDpAlzwIlrUi5Co7whDpWRJ5gU0',
    id: 4629,
    acronym: 'AYUC',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'ait.ie',
    name: 'Athlone Institute of Technology',
    web_page: 'http://www.ait.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmh_KgIZEQ7GGSRMjIvzYMXplrE7pwH4LfgG8VwDeceneG0BSmNS4FRA',
    id: 4630,
    acronym: 'AIT',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'cit.ie',
    name: 'Cork Institute of Technology',
    web_page: 'http://www.cit.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT55AJjNhMF0nm9sPi_42Mf4N0vH9qVTVa3eQBYkNleqcTEZl7ora-LuX4',
    id: 4631,
    acronym: 'CIT',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'dcu.ie',
    name: 'Dublin City University',
    web_page: 'http://www.dcu.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHTPLm_JLXKa2PZg7cUpS-_RR4wLZU618WaELv8BducGttpl5hugRGog',
    id: 4632,
    acronym: 'DCU',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'dias.ie',
    name: 'Dublin Institute for Advanced Studies',
    web_page: 'http://www.dias.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm9iB-OTKpux06u8392KaPQEDRfsf67AO4heDJ61cRm_xRGc_E6Mzw8e4',
    id: 4633,
    acronym: 'DIFAS',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'dit.ie',
    name: 'Dublin Institute of Technology',
    web_page: 'http://www.dit.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI8Am13PCI1k6Uwpqp8LrvvBhaPgeABSCxAOyL_4xQ9wVkq37kHgFEkw',
    id: 4634,
    acronym: 'DIT',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'gcd.ie',
    name: 'Griffith College',
    web_page: 'http://www.gcd.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiaC_bd4Y6Yh7VCrtdp6eEALcYf_2IMHy9fQJSiWTUvZJ1RlyduSAcUQ',
    id: 4635,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'gmit.ie',
    name: 'Galway Mayo Institute of Technology',
    web_page: 'http://www.gmit.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkGCvIRst2ZN6hlDPB9SM6gq45Cjm-SBNzrT9E4LuuNZhTFFEDypuRXho',
    id: 4636,
    acronym: 'GMIT',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'iiuedu.ie',
    name: 'Irish International University (Distance Education)',
    web_page: 'http://www.iiuedu.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeBTScUCWzp1PWsL_z8gpeLI5mIGdmXcajqP5b3CaTyHHwGTePvNq2R4a-',
    id: 4637,
    acronym: 'IIU(E',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'itcarlow.ie',
    name: 'Carlow Institute of Technology',
    web_page: 'http://www.itcarlow.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrDsBpZL8-Iyf5yAZLcFqV3VMIBdzQ2GapblH4Jp4VnVzMtQcvnQjZxgI',
    id: 4638,
    acronym: 'CIT',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'ittralee.ie',
    name: 'Institue of Technology Tralee',
    web_page: 'http://www.ittralee.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjTvtnOJ1lGPwkRAd9rv-nYEjw6OzyBfgqw2_3kHEazkhTN3djULTwag',
    id: 4639,
    acronym: 'ITT',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'lsb.ie',
    name: 'LSB College',
    web_page: 'http://www.lsb.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkiMUwpecBbDJvLetRhlSGrjRiUGWz-ssNmHmFKnZLiOvUQ_JuugyVow',
    id: 4640,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'may.ie',
    name: 'National University of Ireland Maynooth',
    web_page: 'http://www.may.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2NnR3DUGcXB18VSsjV4ndgBrTpJM5FaMNv1a7qxHQrKt7PrYMlp9cPBc',
    id: 4641,
    acronym: 'NUIM',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'ncirl.ie',
    name: 'National College of Ireland',
    web_page: 'http://www.ncirl.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm5CCudpuRPP0T2feePEJ-DnU22kFGomJvW07HqaWJ8VF3pTWlRRl3wA',
    id: 4642,
    acronym: 'NCI',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'nui.ie',
    name: 'National University of Ireland',
    web_page: 'http://www.nui.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4G_wFVzOMLQH553S2QCg3uRtWkUVV1bpevZT1JhHRiMQ4zq7Al62kyCY',
    id: 4643,
    acronym: 'NUI',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'rcpi.ie',
    name: 'Royal College of Physicians of Ireland',
    web_page: 'http://www.rcpi.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiJhgbAw_JIl0pQZVcFRHhSPYvutBbhpHgkWMEIK1sysTReriH9EoPy9as',
    id: 4644,
    acronym: 'RCPI',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'rcsi.ie',
    name: 'Royal College of Surgeons',
    web_page: 'http://www.rcsi.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOBe64Lr0i91-McfRvmEE6_fqVZOh6lTXR2POsxJMgrJ_WnD8342-k3xI',
    id: 4645,
    acronym: 'RCS',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'shannoncollege.com',
    name: 'Shannon College of Hotel Management',
    web_page: 'http://www.shannoncollege.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOMycU6BFSOolyBET7k9s7vDl-GB9HZpu-nz7feZvha0QuE8o39V3B2Bk',
    id: 4646,
    acronym: 'SCHM',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'tcd.ie',
    name: 'University of Dublin Trinity College',
    web_page: 'http://www.tcd.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLZkccSge6PSoQyzjueRx8lIHMK6dewtnDjI7A2IzaqAYSgdUI2KSklKs',
    id: 4647,
    acronym: 'UDTC',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'ucc.ie',
    name: 'University College Cork',
    web_page: 'http://www.ucc.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2mqEdOXItsHObedCUUhFuB6G9eKMicXpONDXqlqW2AHp6N--TLDYngw',
    id: 4648,
    acronym: 'UCC',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'ucd.ie',
    name: 'University College Dublin',
    web_page: 'http://www.ucd.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfOyIpllKQ59jyb5RLJ8SWs9Dm1oUBZDaDOJ9GRDCwZsbNhUgr3mA31w',
    id: 4649,
    acronym: 'UCD',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'ucg.ie',
    name: 'National University of Ireland Galway',
    web_page: 'http://www.ucg.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4G_wFVzOMLQH553S2QCg3uRtWkUVV1bpevZT1JhHRiMQ4zq7Al62kyCY',
    id: 4650,
    acronym: 'NUIG',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'ul.ie',
    name: 'University of Limerick',
    web_page: 'http://www.ul.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkBkqNgD0VJoiyDZSNKLkNhcA_EctGYloeVhBIrHNSMD9V64M8ffcptlU',
    id: 4651,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'warnborough.edu',
    name: 'Warnborough University',
    web_page: 'http://www.warnborough.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRah0zKbi3fakh5jRrSvNaJ2TLqpWFSFQkyI_jGMZgipMqNNWHwagJdidw',
    id: 4652,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'IE',
    country: 'Ireland',
    domain: 'wit.ie',
    name: 'Waterford Institute Of Technology',
    web_page: 'http://www.wit.ie/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlltZ7IOj9Taln_D7KZyhkDow0XNRbmTBLtT3x_P8XE5a2rWNSoBAge3QY',
    id: 4653,
    acronym: 'WIT',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'afeka.ac.il',
    name: 'Afeka Tel Aviv Academic College of Engineering',
    web_page: 'http://www.afeka.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrcPBQqDM3OPA942NCORWi-wOvqBTr_b5myt88JkpnmzHk3RCX4bQ3tCMi',
    id: 4654,
    acronym: 'ATAACE',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'ariel.ac.il',
    name: 'Ariel University Center of Samaria',
    web_page: 'http://www.ariel.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOjg09-o2xezoYxNtMZqBfKEieo0x0zEwqMAQCNRJyNhIHsx2fNSWO6uWd',
    id: 4655,
    acronym: 'AUCS',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'ash-college.ac.il',
    name: 'Ashkelon Academic College',
    web_page: 'http://www.ash-college.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs9uaahzE4qKaVeunXHO1ceF2wIIemkA0W7dCQ_y_ZwpsAkb4MkAIq-oU',
    id: 4656,
    acronym: 'AAC',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'bezalel.ac.il',
    name: 'Bezalel Academy of Art and Design',
    web_page: 'http://www.bezalel.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfEvORNepikal9DDeQaATX6lTUentyxhuSrFSzoi2ZpBbgD5TL4MfhGXI',
    id: 4657,
    acronym: 'BAAD',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'bgu.ac.il',
    name: 'Ben-Gurion University of the Negev',
    web_page: 'http://www.bgu.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF3LjIi0UC5uxJomrmy05pl3y-svPvm_dlMbk5OM8s_A5khakEjd11tOc',
    id: 4658,
    acronym: 'BUN',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'biu.ac.il',
    name: 'Bar-Ilan University',
    web_page: 'http://www.biu.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2qj_1kZi5C8XZjEu7O_FH9ZlHhpxrWtn5s5B-2ZFdWslQ29d390baIg',
    id: 4659,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'clb.ac.il',
    name: 'Acdemic Center for Law and Business',
    web_page: 'http://www.clb.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQncCcNUlv8MTyMbO-yP7xvN_dtrLC2u7G4H-9SKYoQPt7ZlbeMVeUZaks',
    id: 4660,
    acronym: 'ACFLB',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'colman.ac.il',
    name: 'College of Management',
    web_page: 'http://www.colman.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx5PRYiXIWgSIXWYyp7k3f6v_liLJEhA1iukfzj87VVU17-WUIHpS65A',
    id: 4661,
    acronym: 'CM',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'galilcol.ac.il',
    name: 'Galillee College',
    web_page: 'http://www.galilcol.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7OOPkI8gkIvIOpjGW_0ACKWKyEWAnDwE6MLRF0xEd-13j7fvq7VX7pg',
    id: 4662,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'haifa.ac.il',
    name: 'University of Haifa',
    web_page: 'http://www.haifa.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSw3RVj-tMvQw00KQG5N75vGWW_vsRE2vJAgTdn5nq-alUnZUX7mwQb2Q',
    id: 4663,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'huji.ac.il',
    name: 'Hebrew University of Jerusalem',
    web_page: 'http://www.huji.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp6BJ9PK5xhCnzy5R1CMqOSgNij8apKAN7XUVYuj6vuNFZvrV5uLHdIg',
    id: 4664,
    acronym: 'HUJ',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'idc.ac.il',
    name: 'The Interdisciplinary Center Herzliya',
    web_page: 'http://www.idc.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFTrk-AQfOWswo8fE2HHLboHNw805TJeuFi7nUTHm--nj0vDYpWeNEExc',
    id: 4665,
    acronym: 'ICH',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'juc.edu',
    name: 'Jerusalem University College',
    web_page: 'http://www.juc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcJAoaEmXdN1_QKlNrENPuXm5u2UXiQbXfzf7rCoq4Zyu71iFthq702nA',
    id: 4666,
    acronym: 'JUC',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'openu.ac.il',
    name: 'Open University of Israel',
    web_page: 'http://www.openu.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzhsJg-FyMTnc48NiEcYhktfnRHl8pM7vOOnTu-IR-11KAAF33_QLFOg',
    id: 4667,
    acronym: 'OUI',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'rbni.technion.ac.il',
    name: 'Russell Berrie Nanotechnology Institute',
    web_page: 'http://rbni.technion.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhB9XuV5LdsBf1G_B7HHSNBg1ItnJBkoMoyGInegwcTyuTWMruDuOoyY4',
    id: 4668,
    acronym: 'RBNI',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'sce.ac.il',
    name: 'Sami Shamoon College of Engineering',
    web_page: 'http://www.sce.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8IdIvUKQKHw7HDdXIcrLTkZ5urdG2AcyjbPlqcdYj0OLQbArsjQvCywbC',
    id: 4669,
    acronym: 'SSCE',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'shenkar.ac.il',
    name: 'Shenkar School of Engineering & Design',
    web_page: 'http://www.shenkar.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqDTJg0uqb5H07rmkSJPo45AYOpHtANphvFFETMyJLbcAB23kdzECWCg',
    id: 4670,
    acronym: 'SSE&D',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'tau.ac.il',
    name: 'Tel Aviv University',
    web_page: 'http://www.tau.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyiIa6arlxccmPJExReexA8Stv_y5IByk-ckkVqQI49UUpCcK4asg-pg',
    id: 4671,
    acronym: 'TAU',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'technion.ac.il',
    name: 'Technion - Israel Institute of Technology',
    web_page: 'http://www.technion.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMNQeOVp8Nui1nttsFG0cHVw8y2-oVJTtIU62HgA_bZz3VEoZn4Nkrlm8',
    id: 4672,
    acronym: 'T-IIT',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'weizmann.ac.il',
    name: 'Weizmann Institute of Science',
    web_page: 'http://www.weizmann.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjhhYr8d6KXXgtBoK0jPondnpGVXY5u4zktMtXLnLTEV9iBFtKAu6aSA',
    id: 4673,
    acronym: 'WIS',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'wgalil.ac.il',
    name: 'Western Galilee College',
    web_page: 'http://www.wgalil.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdKF_20M8EC2gtJ-sJrkOI0tSKJC2GkyVt5w7ZWRnx6h3zII_C9Gofguo',
    id: 4674,
    acronym: 'WGC',
  },
  {
    alpha_two_code: 'IL',
    country: 'Israel',
    domain: 'yvc.ac.il',
    name: 'Emeq Yizrael College',
    web_page: 'http://www.yvc.ac.il/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTrYShC2Ei309P-9OQEvGY82lIY3ATMs4VHBCE1ugfJP5UAJ7Kt0N08qI',
    id: 4675,
    acronym: 'EYC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'angelicum.org',
    name: 'Pontificia Università S. Tommaso',
    web_page: 'http://www.angelicum.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7NZVvSPYLXzzzwSiysrgAF2jIk0uKwPzFLPWlE5gLSOXe8hUmhLb27rw',
    id: 4676,
    acronym: 'PUST',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'antonianum.ofm.org',
    name: 'Pontificio Ateneo Antonianum',
    web_page: 'http://www.antonianum.ofm.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5GZgPsqaJup7Tm872VlsXpg89OstWsjX25YYPLF1V4yJSPD4cUUsCJKc',
    id: 4677,
    acronym: 'PAA',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'casa.unimo.it',
    name: 'University of Modena',
    web_page: 'http://www.casa.unimo.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTms71wOudt1ago8J-2iYrJIJYaRd0jGudMNk-r6rV2WxD5PhlunU-oiA',
    id: 4678,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'ictp.trieste.it',
    name: 'Abdus Salam international centre for theoretical physics',
    web_page: 'http://www.ictp.trieste.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjU9Y1bK1z1UcqRTySt6z1UgNJVJUL3ko8FPisMc3QnR3n77r8-d12y_6A',
    id: 4679,
    acronym: 'ASICFTP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'imtlucca.it',
    name: 'Institute for Advanced Studies Lucca',
    web_page: 'http://www.imtlucca.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHs3E4dzCKCTsjfjjpG-lRoghv-1RCgRzMXDq6Ld-zZWleDKZ3TXgRFFw',
    id: 4680,
    acronym: 'IFASL',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'iuav.unive.it',
    name: 'University Institute of Architecture Venice',
    web_page: 'http://www.iuav.unive.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu0tQsLrmcw2AA5oq8sRqmOGsFHxR7WQjzvn_n9cK1R0EqzZZ0esOQJRTP',
    id: 4681,
    acronym: 'UIAV',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'iue.it',
    name: 'European University Institute',
    web_page: 'http://www.iue.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGqTuJUV4TllNTLsE2a95nC4RKNvoJ5RvCmE2NvElEUN-zdAHUZgc0wjY',
    id: 4682,
    acronym: 'EUI',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'iulm.it',
    name: 'University Institute of Modern Languages',
    web_page: 'http://www.iulm.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP54x5ovobq9SSDFM2_wYOKwP3oHuaX176s5s9SmmY3_vP9-lTTc8tgkY',
    id: 4683,
    acronym: 'UIML',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'iuoart.org',
    name: 'Dutch University Institute for Art History (DUIA)',
    web_page: 'http://www.iuoart.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfg7khdfNJf_fbllygkcta7xrwf2fEjJFvQ6IMpCVUZzGvKwuFL3lxFQ',
    id: 4684,
    acronym: 'DUIFAH(',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'iuo.it',
    name: 'University Institute of Oriental Studies',
    web_page: 'http://www.iuo.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqPSg04RY_ZpBtO-PN9R1PP0AvxZ8kQYyZp2CXqdN7p8spQ0psrTpnpkpa',
    id: 4685,
    acronym: 'UIOS',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'iusspavia.it',
    name: 'Istituto Universitario di Studi Superiori',
    web_page: 'http://www.iusspavia.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhRsr_DXNne3JdZmzQ4tFujvjM4oTMojmx3KP36AhJlAEA0aw95cWiXvg',
    id: 4686,
    acronym: 'IUDSS',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'jhubc.it',
    name: 'Johns Hopkins University SAIS Bologna Center',
    web_page: 'http://www.jhubc.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_voHEzzvPvyEzXhY32UNLenxUYS6Zawh79SARxy4570q28KcTGtm98w',
    id: 4687,
    acronym: 'JHUSBC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'johncabot.edu',
    name: 'John Cabot University',
    web_page: 'http://www.johncabot.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhGEpd4QXsgrp3q0_g1o2oqwEvyUekZ0h_wzuTRCqcE75h866O7AUlMFTr',
    id: 4688,
    acronym: 'JCU',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'liuc.it',
    name: 'Free University Institute "Carlo Cattaneo"',
    web_page: 'http://www.liuc.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJaYyOOVo1bGOC3j8lhu9feU0FEz99CQuP213Lfp2HXa6nbQiylySMpo',
    id: 4689,
    acronym: 'FUI"C',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'luiss.it',
    name: 'Free International University of Social Studies',
    web_page: 'http://www.luiss.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiODaS72ygH-WB-vn-OwTw518OdDUnh2ihW3YRFMFG73emd4PYKPSpt0w',
    id: 4690,
    acronym: 'FIUSS',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'lumsa.it',
    name: 'Free University "Maria Santissima Assunta"',
    web_page: 'http://www.lumsa.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCPShVz_bdsY7MkPObEDbsb34Y8JvD0q7DrTqAlS86piWEu8ZlZ2tfcQ',
    id: 4691,
    acronym: 'FU"SA',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'naba.it',
    name: 'NABA - Nuova Accademia di Belle Arti Milan',
    web_page: 'http://www.naba.it/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcToojgwvZP-ow5G-n5cIIZyvOanRYAcHwzfgXf2dxBzGamzR0uZqWwJ7g',
    id: 4692,
    acronym: 'N-NADBAM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'nyuniversity.net',
    name: 'Yorker International University Milano',
    web_page: 'http://www.nyuniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnxwsDstZYFI5rj9kPCbxMgwWKG8TReZCp6ll549t1QY5FouGtoSB2EZnV',
    id: 4693,
    acronym: 'YIUM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'pio.urbe.it',
    name: 'Pontificio Istituto Orientale',
    web_page: 'http://www.pio.urbe.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf9aMEh5wGh36gp6nkySn8zMDdqzV6pOHNzDpv1rAOy1X4gQ3mKXki6zzK',
    id: 4694,
    acronym: 'PIO',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'poliba.it',
    name: 'Polytechnic Institute of Bari',
    web_page: 'http://www.poliba.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFsS-2bf4HRwKxj-kzg-Ysb3gqcUQm1AAHnB6HWQorpB7L7YVFge8TWMc',
    id: 4695,
    acronym: 'PIB',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'polimi.it',
    name: 'Polytechnic Institute of Milan',
    web_page: 'http://www.polimi.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXXoEh5D8Twa8DCOP943sf_LezHZl3hQtQZCbzkQn3Tpbtt-KMTxtH1g',
    id: 4696,
    acronym: 'PIM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'polito.it',
    name: 'Polytechnic Institute of Turin',
    web_page: 'http://www.polito.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0AthfkrDPjOek5ItDtMqNZEJujexvz-Abem7pCTDPwOcVCXmwzhd72QqE8Q',
    id: 4697,
    acronym: 'PIT',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'pul.it',
    name: 'Pontificia Università Lateranense',
    web_page: 'http://www.pul.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRozUc37ftR81ze3guEAxnt0KTXSOIDcPrRpKpLcZmMjvVhxn4VFUyTfLY',
    id: 4698,
    acronym: 'PUL',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'pusc.it',
    name: 'Pontificia Università della Santa Croce',
    web_page: 'http://www.pusc.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSctkzQa-a2UkEu1_vg9V1mp3tScxRPaTgsKOKui4VQooJnBvHzl50cxFD-',
    id: 4699,
    acronym: 'PUDSC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'sacrocuore.org',
    name: 'Fondazione Sacro Cuore',
    web_page: 'http://www.sacrocuore.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOXZBCrYbWUshTMebN2RP1rCFvsrh86y-zNRZk6UlsmIFCG-aiMcpQOdav',
    id: 4700,
    acronym: 'FSC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'sissa.it',
    name: 'International Higher School for Advanced Studies Trieste',
    web_page: 'http://www.sissa.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8OF9uaLRL1Z9sgz0JTHDAd8uw4EgaQh-IWD_UlJxYZiY7ZING5mu_L1NJ',
    id: 4701,
    acronym: 'IHSFAST',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'sns.it',
    name: 'School of Education Pisa',
    web_page: 'http://www.sns.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4ZOnyNxvknDkHgVs7PiddS3aAyiRcbxH4zg5l19MtwKNrU5cRvvH4Ew',
    id: 4702,
    acronym: 'SEP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'sssup.it',
    name: 'Higher School of University and Advanced Studies Pisa',
    web_page: 'http://www.sssup.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1ofGPi5qyg12c_VpuaaJ0zovtR321o-y6yI1wLghuA_HhDE6Wz4PYTHYK9g',
    id: 4703,
    acronym: 'HSUASP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unian.it',
    name: 'University of Ancona',
    web_page: 'http://www.unian.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9KM-IuKtJHLYuu7ClflLwAOPzTMq_-DJc8T18bU52XIMOg9Y0Dd3bbwU',
    id: 4704,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uniba.it',
    name: 'University of Bari',
    web_page: 'http://www.uniba.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP-f_ezW262R2ObO-FM8K8qN_g6sOH5tt9klPyrPIh1l2rD_QFKfR6uzs',
    id: 4705,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unibas.it',
    name: 'Basilicata University Potenza',
    web_page: 'http://www.unibas.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzTLdKfiOh-6ERq4t3-bnI723L9vAEULG7WOopV2HHagI8Tq1zOZCwkI4',
    id: 4706,
    acronym: 'BUP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unibg.it',
    name: 'University of Bergamo',
    web_page: 'http://www.unibg.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-tyXr4FTBHu4F3-Bl2HVkGk2oujNnNyHov9vwhuG61Zw8olKZt5_y8zM',
    id: 4707,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unibocconi.it',
    name: 'Bocconi University',
    web_page: 'http://www.unibocconi.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLJdBSqVRGAoqXz9-b7o8E0D9yY3N8hy22pbWLS3-GNXyORXj4VgtrchQC',
    id: 4708,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unibo.it',
    name: 'University of Bologna',
    web_page: 'http://www.unibo.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU7bpz5mAyxu7uGOPXH5_otoVC6IiYtyNlZK1IsQKCx7W2rmwfg2igRQ',
    id: 4709,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unibs.it',
    name: 'University of Brescia',
    web_page: 'http://www.unibs.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWR1xfInRzLm9UiKH66_3Y830rySb1GdGZQ7pVFwehgmC_5UwcWY-Cag',
    id: 4710,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unibz.it',
    name: 'Free University of Bozen',
    web_page: 'http://www.unibz.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTio0rysodPcImo8Y89e_aNLH_0ZkILXDrDMisE0wjdhsln7T0sSg5zw',
    id: 4711,
    acronym: 'FUB',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unica.it',
    name: 'University of Cagliari',
    web_page: 'http://www.unica.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpilZTy9C0DmXk436bubvaEDq8jKXaWil4WW3YcPSZbjrD5nirf_K7rHE',
    id: 4712,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unical.it',
    name: 'University of Calabria',
    web_page: 'http://www.unical.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEqLbS4Gx00ylloXSLkN5vrCHK7WR_crHANwiL55UQPz4dm-SiYS1FGw',
    id: 4713,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unicam.it',
    name: 'University of Camerino',
    web_page: 'http://www.unicam.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqw2661541XrbZK9ZV3lzgvrkXYiTD8EVxkrkartIUQWlBWlzib41pcw',
    id: 4714,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unicampus.it',
    name: 'Campus Bio-Medico University of Rome',
    web_page: 'http://www.unicampus.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH_MIQtuNqJrxj-5L4isssLk0a_e0UnAV_W7ZUWWpbzHmGg3PdXe1jnBk',
    id: 4715,
    acronym: 'CBUR',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unicas.it',
    name: 'University of Cassino',
    web_page: 'http://www.unicas.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw4ECDM2gHT12NNxy0JCwkBKKTHzISv5kkqnycs83Z5bVLxoAM8VUyg38',
    id: 4716,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unicatt.it',
    name: 'Catholic University of the Sacred Heart',
    web_page: 'http://www.unicatt.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYLT6utU69S_6Uc27T1Yofm-Zi16SG_IppJi25n2gQQnYZujgrcBWHjaCW',
    id: 4717,
    acronym: 'CUSH',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unich.it',
    name: 'University of Chieti',
    web_page: 'http://www.unich.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv8dqeDVXht-ChvM8vvDSecMTazyflTLO7xt2-YPKZA7Xb9L9BDWu1aQ',
    id: 4718,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unict.it',
    name: 'University of Catania',
    web_page: 'http://www.unict.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrJidA0oxF5bNOfmhAwprexT4OqByYOZYjJnS0RIH2X_w8dhUxYjv7kEM',
    id: 4719,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uniese.it',
    name: 'European School of Economics',
    web_page: 'http://www.uniese.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh1hpqWv4D0yoGH27MtfEaGzplUDDdIbhW6c84-pZwvzv9WhNiIqfMnQ',
    id: 4720,
    acronym: 'ESE',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unife.it',
    name: 'University of Ferrara',
    web_page: 'http://www.unife.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4YVXQSyORT7dpm-zny7REvSsIDdLi6mkn3TyEHCi0Tfy1s8eJpH8uzw',
    id: 4721,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unifg.it',
    name: 'University of Foggia',
    web_page: 'http://www.unifg.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPLO927sZER1aQcdBcimyf8SzI5vbjwPbvBKbmNWqcQ2erRTUg29Z_VegO',
    id: 4722,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unifi.it',
    name: 'University of Florence',
    web_page: 'http://www.unifi.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJtLhvUsDYuI71AN-koM_dlI84LzM_XBcT-dfNmc2lv7qB1DBBo5EAaQ',
    id: 4723,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unige.it',
    name: 'University of Genoa',
    web_page: 'http://www.unige.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZDq9Xu0vZtz9Vp8uqhWHcxqB9AUIKYJRBT0SWkURzetKTs4rjRYAG2A',
    id: 4724,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unigre.it',
    name: 'Pontifica Università Gregoriana',
    web_page: 'http://www.unigre.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfwjMnqrsa57GIDDsbSzVt4Kv6-dKW2Tze3UwDlDZdaJRfMC0CyGOr_BuQbQ',
    id: 4725,
    acronym: 'PUG',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unile.it',
    name: 'University of Lecce',
    web_page: 'http://www.unile.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0wTHvJ8qlQxgm8gt9NLyx4FOtEi9ND5QZ5Y5IbC4w59G8HFuunAVN5nI',
    id: 4726,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unilink.it',
    name: 'Link Campus University of Malta',
    web_page: 'http://www.unilink.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHD5u1lOAts1GFthS5xCZ2OPm4wkQALm3jk2ontKt42jKidscGkegTqg',
    id: 4727,
    acronym: 'LCUM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unimc.it',
    name: 'University of Macerata',
    web_page: 'http://www.unimc.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1OzYOrULVtOlqjUJDC2JEH_hlgGCODuq5VYFomeSESp9XrAomsdLVBEU',
    id: 4728,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unime.it',
    name: 'University of Messina',
    web_page: 'http://www.unime.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg3luRdvhepF9NmjntOh4M-M0eHa8f_4MARjOzj_5fKHEiX0xyw-EgIio',
    id: 4729,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unimib.it',
    name: 'University of Milan - Bicocca',
    web_page: 'http://www.unimib.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQulgiapcoJdOBGhRJfc9crqfNh9XZLGpZwp16eYkXASs-VPHsxzmyUBAFL',
    id: 4730,
    acronym: 'UM-B',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unimi.it',
    name: 'University of Milan',
    web_page: 'http://www.unimi.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwi4y2SoJNjqEoTVM9NztAtOyZabYqK6I3iseBP6XWLKQNhLEQhr8dfFQ',
    id: 4731,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unimol.it',
    name: 'University of Molise',
    web_page: 'http://www.unimol.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_imTgXVzN8zy0-49Vx_KumGBAKh9mByoJD_ZhFuSCpzCBTx-fLl2b350',
    id: 4732,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unina2.it',
    name: 'Second University of Naples',
    web_page: 'http://www.unina2.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTCA-i6bX54QfCQ1dmH43geEJvQBUjMP3mBTO32dV9MiUZBNpgTa0gzmI',
    id: 4733,
    acronym: 'SUN',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unina.it',
    name: 'University of Naples Federico II',
    web_page: 'http://www.unina.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbzoyCCbIOPUK3lzgbo33WEci5_8vsdhlR2AhULjs0_f0FfD7cSAz6WL-p',
    id: 4734,
    acronym: 'UNFI',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uninav.it',
    name: 'University Institute of Naval Studies',
    web_page: 'http://www.uninav.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeRLdsv87beqUyA5v0FlpiJ-4jAg6pB-byTJV8pAyWupRuO83S8qXif4c6',
    id: 4735,
    acronym: 'UINS',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uninsubria.it',
    name: 'University of Insubria',
    web_page: 'http://www.uninsubria.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtbXYYEfAG_h5-spMeFxLW7smLLKmYrTW2v8QQOGkvG0hJZeLaLnkYQqE',
    id: 4736,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unipa.it',
    name: 'University of Palermo',
    web_page: 'http://www.unipa.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYNF8XSCe5YT1TM1CLG0XbZzo5PAukv1PlbzIQi9BujsPWXC1TJsyVDGc',
    id: 4737,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unipd.it',
    name: 'University of Padua',
    web_page: 'http://www.unipd.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBgLcN_IJxxW1EkgVxEqi60k2Q8HvCNjKuuj9soTGxVtRMOQtKTglzqg',
    id: 4738,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unipg.it',
    name: 'University of Perugia',
    web_page: 'http://www.unipg.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_1Qi3kM0WjhWSan4gyoYNyvVxl9nrd7XyPcjE3xF0bmc8LfPGpOlQIgU',
    id: 4739,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unipi.it',
    name: 'University of Pisa',
    web_page: 'http://www.unipi.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYPR0ICz8pQQa3OxLb7wDCkWQOIsWpBUlHNUSsbSDiFSNHRIM5M81JTLc',
    id: 4740,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unipr.it',
    name: 'University of Parma',
    web_page: 'http://www.unipr.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6Psg2lzPoTvJhak6MUA3cHvqqufmuLCcqT4DrdbpLVWa_Uve1Uram66I',
    id: 4741,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unipv.it',
    name: 'University of Pavia',
    web_page: 'http://www.unipv.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE3lzuo7cRZx3izgxP2lxDh9xwswAPBmXPhlC-ilYvfP3HiAohINzX6ug',
    id: 4742,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unirc.it',
    name: 'University of Reggio Calabria',
    web_page: 'http://www.unirc.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR386QnOvy31oLFGBmgrd2LRvSK4V-KI0vZdW0TwpEXoIbDVv7jrVPf6Q',
    id: 4743,
    acronym: 'URC',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uniroma1.it',
    name: 'University of Roma "La Sapienza"',
    web_page: 'http://www.uniroma1.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8sMBb_yMYVoNdy9f2DGhxAoJhZptBMGV1qRvJGI1xPZ4533q6lCqGcg',
    id: 4744,
    acronym: 'UR"S',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uniroma2.eu',
    name: 'University of Roma "Tor Vergata"',
    web_page: 'http://www.uniroma2.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-vrQVIkvPbbiUqRy2wqdEJWBykHzk4lfn7lLUyhE9fWDSPHlQJyeHyoG2',
    id: 4745,
    acronym: 'UR"V',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uniroma3.it',
    name: 'Third University of Rome',
    web_page: 'http://www.uniroma3.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHyeK51qdpI0S5fC0mFR8tfQIHlbSYl2ONZ3RXWX6BoMDWoA3IZjH0jw',
    id: 4746,
    acronym: 'TUR',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unisa.it',
    name: 'University of Salerno',
    web_page: 'http://www.unisa.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlMDBNBn2MxPeYyCbzWrV-joa7jnMaswXjvkuSXKZvA63AAsUOg8wr6Q',
    id: 4747,
    acronym: 'US',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unisal.it',
    name: 'Università Pontificia Salesiana',
    web_page: 'http://www.unisal.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgleBJbo3pEiZbyLtRQkVGbCp99tiCzfKEgz3VVIebySsnJjK5-Q-3VBO3',
    id: 4748,
    acronym: 'UPS',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unisannio.it',
    name: 'University of Sannio',
    web_page: 'http://www.unisannio.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6YO2jj4i2Qz82HoMQMaW__YhI4OVcGmvnb2WaJONaPj6M_Y1bm84MKZc',
    id: 4749,
    acronym: 'US',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unisg.it',
    name: 'University of Gastronomic Sciences',
    web_page: 'http://www.unisg.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRycYvmV5bJ_nyAvY_QrAR2SySKy7riTpSUus0YKdwxKa2yc6Eh_cT9WtSU',
    id: 4750,
    acronym: 'UGS',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unisi.it',
    name: 'University of Siena',
    web_page: 'http://www.unisi.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCX2SfNP2WIy75r0lxhpRW1MIVe8auVj70MwhttRUtPi6cOixeULbPwg',
    id: 4751,
    acronym: 'US',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unisob.na.it',
    name: 'University Institute of Teacher Training "Suor Orsola Benincasa"',
    web_page: 'http://www.unisob.na.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4752,
    acronym: 'UITT"OB',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uniss.it',
    name: 'University of Sassari',
    web_page: 'http://www.uniss.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJRlt-m46quUJrc7pW297ysqfYrOaLxbe-lRqwCvVML6ezhmPveVPI7g',
    id: 4753,
    acronym: 'US',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unistrapg.it',
    name: 'University of Italian Studies for Foreigners of Perugia',
    web_page: 'http://www.unistrapg.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_1Qi3kM0WjhWSan4gyoYNyvVxl9nrd7XyPcjE3xF0bmc8LfPGpOlQIgU',
    id: 4754,
    acronym: 'UISFFP',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unistrasi.it',
    name: 'University of Italian Studies for Foreigners of Siena',
    web_page: 'http://www.unistrasi.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMjqdDUqO2wOmy9niL-WUV_YzdW5mHKLd2w0fZ1TFKKY0yGVd9pT--N74',
    id: 4755,
    acronym: 'UISFFS',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unite.it',
    name: 'University of Teramo',
    web_page: 'http://www.unite.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmxlb3-I5m--G2VBmWU1YJG5--nXZ4dm0ACyanX14SX1_9AF2bPowpmA',
    id: 4756,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unitn.it',
    name: 'University of Trento',
    web_page: 'http://www.unitn.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf_Llc2g8KnKCOqCTe-WSULz8eGQoRoSM1NFQYoSuODul2QfgElaw_6n8',
    id: 4757,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unito.it',
    name: 'University of Turin',
    web_page: 'http://www.unito.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgIrvmnV56eetWAkOqtCuTShEdR8N94_tiOLwYQDTOIVN8Y9yCqsguqQ',
    id: 4758,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unitus.it',
    name: 'Viterbo State University',
    web_page: 'http://www.unitus.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThY_sEUpyto9KsB-aHxjyDfdIwUwoDVSFTHzgOjm5Ff5bowUuTBAsDlII',
    id: 4759,
    acronym: 'VSU',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uniud.it',
    name: 'University of Udine',
    web_page: 'http://www.uniud.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnB9eKy2rVaDSg2H4UArXVSOpqq7HCocGfA5nu8fMue9-jQ8uCdyUlKbU',
    id: 4760,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'uniurb.it',
    name: 'University of Urbino',
    web_page: 'http://www.uniurb.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZtced6racHAyOktm6dbxpSr_wlgMePrX7AGzKi7le74RMyOsXmx7xykkB',
    id: 4761,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'univaq.it',
    name: 'University of Aquila',
    web_page: 'http://www.univaq.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn0UiORmEdOL6ntZs5tHXVm3qAgOy8sG4rGZm1X4lzTMg1KKW1bIF5lK4',
    id: 4762,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'unive.it',
    name: 'University of Venice',
    web_page: 'http://www.unive.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPfERku0eyiIVGPyjttgx958_d7jVXh_U-CeiPv_rRZjdr5qlNw7i3qA',
    id: 4763,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'univr.it',
    name: 'University of Verona',
    web_page: 'http://www.univr.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGj5ghxSiahF-5GLNhORRjRTukA8yN96-VuKLtFE6bp4qnlKFlTC96bw',
    id: 4764,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'IT',
    country: 'Italy',
    domain: 'univ.trieste.it',
    name: 'University of Trieste',
    web_page: 'http://www.univ.trieste.it/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxnCp82l2VyibsVheG12fk3z77t34PV1Mpzi_Aon-7gehYCbS_ftY4YQ0',
    id: 4765,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'JM',
    country: 'Jamaica',
    domain: 'ncu.edu.jm',
    name: 'Northern Caribbean University',
    web_page: 'http://www.ncu.edu.jm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHb5L6p5T61s-3nIGxNR7qVtnaO0BOAu7qgJF0KlFGTjmug-7lxk0Zf2U',
    id: 4766,
    acronym: 'NCU',
  },
  {
    alpha_two_code: 'JM',
    country: 'Jamaica',
    domain: 'utech.edu.jm',
    name: 'University of Technology Jamaica',
    web_page: 'http://www.utech.edu.jm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOsFq0EJLHhRKaB1_Be27WLiAwfq3qm-xAr3EnNotCDkjVNvzb0Flecg',
    id: 4767,
    acronym: 'UTJ',
  },
  {
    alpha_two_code: 'JM',
    country: 'Jamaica',
    domain: 'uwimona.edu.jm',
    name: 'University of the West Indies Mona',
    web_page: 'http://www.uwimona.edu.jm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJpQi38NmiWvkYqdvil8xWHHm4RJm5LVB4QZ_MLudmKy-Z5xktYAyWyg',
    id: 4768,
    acronym: 'UWIM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aasa.ac.jp',
    name: 'Aichi Shukutoku University',
    web_page: 'http://www.aasa.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYAadQo-epTcL00zzHbg7w_v4BWh7fH0VtCf4vVcWxzuEOqE4Cv1mKsT8',
    id: 4769,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'abu.ac.jp',
    name: 'Aichi Bunkyo University',
    web_page: 'http://www.abu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhVkx2DxbYXd36kgd6QGrq63EWKdAvBvIgTB5Q6zawjvoopmL4i0ifxQQ',
    id: 4770,
    acronym: 'ABU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aichi-edu.ac.jp',
    name: 'Aichi University of Education',
    web_page: 'http://www.aichi-edu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkucggiSYn0qI4OXE8pHHEM7_rUMbCewT2inkeS9zP3qMmNcy77aSByaU',
    id: 4771,
    acronym: 'AUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aichi-fam-u.ac.jp',
    name: 'Aichi Prefectural University of Fine Arts & Music',
    web_page: 'http://www.aichi-fam-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRimY3VKGtBNe0w9v_CXc-S7g0V9gBx0-kuh87m55NUEbU2m2X4a5a82GM',
    id: 4772,
    acronym: 'APUFA&M',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aichi-gakuin.ac.jp',
    name: 'Aichi Gakuin University',
    web_page: 'http://www.aichi-gakuin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkucggiSYn0qI4OXE8pHHEM7_rUMbCewT2inkeS9zP3qMmNcy77aSByaU',
    id: 4773,
    acronym: 'AGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aichi-med-u.ac.jp',
    name: 'Aichi Medical University',
    web_page: 'http://www.aichi-med-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm9rvh-IIrhSV7dIByONfTnJFFAcNTnOR6gVcHpfzn3hlCQk_i7YLiew',
    id: 4774,
    acronym: 'AMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aichi-pu.ac.jp',
    name: 'Aichi Prefectural University',
    web_page: 'http://www.aichi-pu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkucggiSYn0qI4OXE8pHHEM7_rUMbCewT2inkeS9zP3qMmNcy77aSByaU',
    id: 4775,
    acronym: 'APU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aichi-u.ac.jp',
    name: 'Aichi University',
    web_page: 'http://www.aichi-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkucggiSYn0qI4OXE8pHHEM7_rUMbCewT2inkeS9zP3qMmNcy77aSByaU',
    id: 4776,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aikoku-u.ac.jp',
    name: 'Aikoku Gakuen University',
    web_page: 'http://www.aikoku-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4777,
    acronym: 'AGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aitech.ac.jp',
    name: 'Aichi Institute of Technology',
    web_page: 'http://www.aitech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSS21qysBnPvBZRSdzzOI8e_LkwYorFLQ2NlUArgG61-DrdheL5KFQtYc3',
    id: 4778,
    acronym: 'AIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'akeihou-u.ac.jp',
    name: 'Akita University of Economics and Law',
    web_page: 'http://www.akeihou-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoDovheG2qLUi-xznerXD5XAcadQVJEcYI4XNXMD4ZOC2o1CdlaTW-It0',
    id: 4779,
    acronym: 'AUEL',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'akita-u.ac.jp',
    name: 'Akita University',
    web_page: 'http://www.akita-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGGxP1qZtEevhCl6hNQB64u5ocjByOGDSCoXrlkSRVFy08zpsaCXHZCfg',
    id: 4780,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'andrew.ac.jp',
    name: "St. Andrew's University",
    web_page: 'http://www.andrew.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh0cBHR3WlkBdvJU0xLln_9oUlCwgTP-vxhFUtq0jcI2EaVaWFa0tGEx90',
    id: 4781,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aomoricgu.ac.jp',
    name: 'Aomori Chuoh Gakuin University',
    web_page: 'http://www.aomoricgu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTckK6QKpQAMkDA-b-rx_DkySBhMA0Q0bwhbe1W-AJ7SAPvtTeA-flNnA',
    id: 4782,
    acronym: 'ACGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aomori-u.ac.jp',
    name: 'Aomori University',
    web_page: 'http://www.aomori-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSny5gkMW11swYuFcd8PX-yRfVmIZpo1DhqvN_ifcWw1CuY1MbDpn9nZn0',
    id: 4783,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aoyama.ac.jp',
    name: 'Aoyama Gakuin University',
    web_page: 'http://www.aoyama.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwegBKk0--DJYp9Ksrx_ziZzpRmHo5SiCpkEcKXvU4LQiNEph1WWmYivc',
    id: 4784,
    acronym: 'AGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'apu.ac.jp',
    name: 'Ritsumeikan Asia Pacific University',
    web_page: 'http://www.apu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0RbxuJxmMeIGUs9aiKqlmgLPTfTJ8ZR7BVRX4a9TnOWPSvy4e8AHg3Q',
    id: 4785,
    acronym: 'RAPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'asahikawa-med.ac.jp',
    name: 'Asahikawa Medical College',
    web_page: 'http://www.asahikawa-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiuvKtXi6YjvFPzgzxtcLSCCDayE_-Dj68SmAnZ_w_F5ngTOTvaYs8n0Bd',
    id: 4786,
    acronym: 'AMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'asahikawa-u.ac.jp',
    name: 'Asahikawa University',
    web_page: 'http://www.asahikawa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpVikRTCBqyIIUreSxRJgMQ4xB26ujk4dUmmTKpadU9xWMKn5Z7Gyw4A',
    id: 4787,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'asahi-u.ac.jp',
    name: 'Asahi University',
    web_page: 'http://www.asahi-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK-_m_XeTMHRaGnEqvKPgT7SQW4G17zq4LUKT--mh7bxY0aTh4A7n8bgY',
    id: 4788,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ashitech.ac.jp',
    name: 'Ashikaga Institute of Technology',
    web_page: 'http://www.ashitech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4789,
    acronym: 'AIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ashiya-u.ac.jp',
    name: 'Ashiya University',
    web_page: 'http://www.ashiya-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4790,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'asia-u.ac.jp',
    name: 'Asia University',
    web_page: 'http://www.asia-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-8CaZTz8X_hYfvJqOHN-npB4VligMxq55-xSvUrSyFPiblk8H6emMCA',
    id: 4791,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'asu.ac.jp',
    name: 'Aichi Sangyo University',
    web_page: 'http://www.asu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbP_XsO0zDcnEf2ZZ6Ia7wX-2vDiRKY17wCrMzW3HijEilNTa0FFa-cZqU',
    id: 4792,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'atomi.ac.jp',
    name: 'Atomi College',
    web_page: 'http://www.atomi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0ENB7nu0HvhUpor7In1rd7ySTBgVq4eZNT1jxUdD6Xe2WgpT8gGWZk4A',
    id: 4793,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'aue-okinawa.ac.jp',
    name: 'American University Extension Okinawa',
    web_page: 'http://www.aue-okinawa.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCttFXICscEXGIUXvb-sTB6BxqhJ2LhKRITe9JJuBUnVVw61nXF_20ww',
    id: 4794,
    acronym: 'AUEO',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'auhw.ac.jp',
    name: 'Aomori University of Health and Welfare',
    web_page: 'http://www.auhw.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrF992FoTQwPWlaKWwozdcFFhQUZhazDgHuRBWYUdQl_547mBKW3EICQ',
    id: 4795,
    acronym: 'AUHW',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'azabu-u.ac.jp',
    name: 'Azabu University',
    web_page: 'http://www.azabu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeaqc8HsdA6GCnpFaoypq1k5-RaIOBw4htq1hMSuwm172LI_cndm2z8i32',
    id: 4796,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'baika.ac.jp',
    name: "Baika Women's College",
    web_page: 'http://www.baika.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4797,
    acronym: 'BWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'baiko.ac.jp',
    name: "Baiko Women's College",
    web_page: 'http://www.baiko.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwoydH7WeRKjTtwZzft5lCiTs1-oPAgUMd-KGMUj9UE-ocFww-L9PkPQ4',
    id: 4798,
    acronym: 'BWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'beppu-u.ac.jp',
    name: 'Beppu University',
    web_page: 'http://www.beppu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzp_2bW5CcwIv1ZdalMcAgc3McQq7PWDvIDdbpi7ZGmCvJxDUGYYML-fIV',
    id: 4799,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'budo-u.ac.jp',
    name: 'International Budo University',
    web_page: 'http://www.budo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPDzx-LxlUUoJPkOk6PgOH_a4TIek8s6r5-qpOPXyrjGd3hxHzhdKWsg',
    id: 4800,
    acronym: 'IBU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'bukkyo-u.ac.jp',
    name: 'Bukkyo University',
    web_page: 'http://www.bukkyo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyf4l1yuQ7SS9ECKIK7CCPMyi9r3vil8I25v996Ieo-dticFzZqqW8Lx8',
    id: 4801,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'bunka-wu.ac.jp',
    name: "Bunka Women's University",
    web_page: 'http://www.bunka-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdoCSvxgTDFjuAuTEKOxi2duHu3jBf2LgCgRjaSnlQjUCpMaIbiy6lvA',
    id: 4802,
    acronym: 'BWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'bunkyo.ac.jp',
    name: 'Bunkyo University',
    web_page: 'http://www.bunkyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvvHfGT4Cvgmfguv9abZOhiAaq8RfC2QR1iJr7Ipbq1Ldukn8nPj_7LQ',
    id: 4803,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'bunri-u.ac.jp',
    name: 'Tokushima Bunri University',
    web_page: 'http://www.bunri-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf2ONORT1sXTMQy2m9AaaEI5Es9imqOGV75O2Y45Q6FuQgaXDOWs7zbA',
    id: 4804,
    acronym: 'TBU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'cgu.ac.jp',
    name: 'Chuo Gakuin University',
    web_page: 'http://www.cgu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTckK6QKpQAMkDA-b-rx_DkySBhMA0Q0bwhbe1W-AJ7SAPvtTeA-flNnA',
    id: 4805,
    acronym: 'CGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'chiba-u.ac.jp',
    name: 'Chiba University',
    web_page: 'http://www.chiba-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRITu46IptTKyPU49oma0ElZ02GrjlJdqaeuqkpdltnDjtRAMESgXqr1w',
    id: 4806,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'chikushi.ac.jp',
    name: 'Chikushi Jogakuen University',
    web_page: 'http://www.chikushi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV9J6Cmv40BTgKfD8MKmxwj3dY7UaXlCRjAgA1YAoW3YwtqXXkHV3DJzLe',
    id: 4807,
    acronym: 'CJU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'chubu.ac.jp',
    name: 'Chubu University',
    web_page: 'http://www.chubu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzshpWjOrcZm8JF7VtBFsgKjB3H1Bl6wgzD-7ENHiDSyN6hyKAKsnnZ4I',
    id: 4808,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'chubu-gu.ac.jp',
    name: "Chubu Gakuin University & Chubu Women's College",
    web_page: 'http://www.chubu-gu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjoe8UasfM8J594yZpEPFJCO8BulVvWKeLSeC0TXuQA41mZjAvKENnHx36',
    id: 4809,
    acronym: 'CGU&CWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'chujo-u.ac.jp',
    name: "Chukyo Women's University",
    web_page: 'http://www.chujo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2_R93QPH_s7xDnfsEAzIIJtI5Kh4s7HNWp42l-y782Rb76bWo-3phWdA',
    id: 4810,
    acronym: 'CWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'chukyogakuin-u.ac.jp',
    name: 'Chukyo Gakuin University',
    web_page: 'http://www.chukyogakuin-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTckK6QKpQAMkDA-b-rx_DkySBhMA0Q0bwhbe1W-AJ7SAPvtTeA-flNnA',
    id: 4811,
    acronym: 'CGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'chukyo-u.ac.jp',
    name: 'Chukyo University',
    web_page: 'http://www.chukyo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwph9DH1kBG0CEQeq7VxP5XT0mnR_dFX663k0AmWQkfZ6fAzqRACGJIw',
    id: 4812,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'chuo-u.ac.jp',
    name: 'Chuo University',
    web_page: 'http://www.chuo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjziugIz0a6p-XE0YD36IPWceE3fzxJnVOS2RV013DyHFT8He0jx86mCE',
    id: 4813,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'cku.ac.jp',
    name: 'Chiba Keizai University',
    web_page: 'http://www.cku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQqgygMMx4hqUt3UiVyX9RyCJY5khQc-2w0_YR8acWBSlkVwYfIa9ckhc',
    id: 4814,
    acronym: 'CKU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'cuc.ac.jp',
    name: 'Chiba University of Commerce',
    web_page: 'http://www.cuc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWNGERBbcZNVloIb0pFNgI03Vg7DBThltyB3P_FGvRKctPQlQzfSg0eD8',
    id: 4815,
    acronym: 'CUC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'daido-it.ac.jp',
    name: 'Daido Institute of Technology',
    web_page: 'http://www.daido-it.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4816,
    acronym: 'DIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'daiichi-cps.ac.jp',
    name: 'Daiichi College of Pharmaceutical Sciences',
    web_page: 'http://www.daiichi-cps.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFrkzcYzRkEg_xqhBWraudK_B90GJAJ4lX9otA21_S3fCAUJU2EQ_7gJg',
    id: 4817,
    acronym: 'DCPS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'daiichi-koudai.ac.jp',
    name: 'Dai Ichi University College of Technology',
    web_page: 'http://www.daiichi-koudai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOZyr3cwXoL-zmfAdLz4m4ZMkNYMGf6cpAOGu5JMYLiHvV-YUaCV9fPhM',
    id: 4818,
    acronym: 'DIUCT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'daiichi-ue.ac.jp',
    name: 'Daiichi University of Economics',
    web_page: 'http://www.daiichi-ue.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4819,
    acronym: 'DUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'daion.ac.jp',
    name: 'Osaka College of Music',
    web_page: 'http://www.daion.ac.jp/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQUB2DM-LlfI7N78FYi9EcGF0FOwQmk8ev7Mwm_4vq5DIfmda9wKWjrhg',
    id: 4820,
    acronym: 'OCM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'daishodai.ac.jp',
    name: 'Osaka University of Commerce',
    web_page: 'http://www.daishodai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzDQPb24kvP77EGt_iGl4WFY9XmOIrThS6qOjGlJqSlZRgIjLd9jFWhkY',
    id: 4821,
    acronym: 'OUC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'daito.ac.jp',
    name: 'Daito Bunka University',
    web_page: 'http://www.daito.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfbo9h_W1MIM2vqs2Oy6WGhV77PEEoM4PMfcWnUYN7fK0SJrd8KJFIQJ4',
    id: 4822,
    acronym: 'DBU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'dendai.ac.jp',
    name: 'Tokyo Denki University',
    web_page: 'http://www.dendai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfCTEMH_q8pKdOFS6ZPmZq296JFMRQRtK5sPGrE7iJS0PuU6cuXwkRGi0I',
    id: 4823,
    acronym: 'TDU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'doho.ac.jp',
    name: 'Doho University',
    web_page: 'http://www.doho.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKaauCDTk8JhgEhhRWTtVAmAAJLcQxL10W-wVmcvjHt-Ai2oK_WPBEkr8',
    id: 4824,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'doho.ac.jp',
    name: 'Nagoya College of Music',
    web_page: 'http://www.doho.ac.jp/~meion/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRk_PGFeZ2wlp_fhkDkLoPjAdXljMQi9zUFAjC1W_IxAdX3_qgQGePMKis',
    id: 4825,
    acronym: 'NCM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'dohto.ac.jp',
    name: 'Dohto University',
    web_page: 'http://www.dohto.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyATgZYTwHQAPpAu_j7lozzIPf0TJw2gLEl9vqNjVSm2LHOoM-zy2aWg',
    id: 4826,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'do-johodai.ac.jp',
    name: 'Hokkaido Information University',
    web_page: 'http://www.do-johodai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyATgZYTwHQAPpAu_j7lozzIPf0TJw2gLEl9vqNjVSm2LHOoM-zy2aWg',
    id: 4827,
    acronym: 'HIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'dokkyo.ac.jp',
    name: 'Dokkyo University',
    web_page: 'http://www.dokkyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzdnazcgm18kQHan1F12sMkFYdjKImVeMmNNLcxtMVGW14RVDhvX4ykg',
    id: 4828,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'dokkyomed.ac.jp',
    name: 'Dokkyo University School of Medicine',
    web_page: 'http://www.dokkyomed.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAdOVjoVa-3G6UByXEFnDeFctc5dIQwd7J3Yy5n-5YB-KIv9UMambjXvw',
    id: 4829,
    acronym: 'DUSM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'doshisha.ac.jp',
    name: 'Doshisha University',
    web_page: 'http://www.doshisha.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHldM3U3J3jf2f927xdOxqXvkn9oOIHjEgD3jbyis2mR3fd3YL6kHgAA',
    id: 4830,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'dwc.doshisha.ac.jp',
    name: "Doshisha Women's College of Liberal Arts",
    web_page: 'http://www.dwc.doshisha.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd7PzxzbxLKsROPweOVXdz-cCOsmWX4aqE5Ze29rH9sJhBymCXRDQhzoQ',
    id: 4831,
    acronym: 'DWCLA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'edogawa-u.ac.jp',
    name: 'Edogawa University',
    web_page: 'http://www.edogawa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT28UL83K0f4CsfIe9fzimAZVXG1NGyP5E6b-6OFRjFBfsJIUiH57FUw',
    id: 4832,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ehime-u.ac.jp',
    name: 'Ehime University',
    web_page: 'http://www.ehime-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9syMWP0Jz6OnXwxVvXmn0OOqI7VfXEzHtfuvcnwvJLyQT0_-F1pds20I',
    id: 4833,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'eichi-u.ac.jp',
    name: 'Eichi University',
    web_page: 'http://www.eichi-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6hvfShACcsn7Z22oprQgu6Yf--IevYo7jaFNH6UHy6vulLwHZDqr8U-k',
    id: 4834,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'eiyo.ac.jp',
    name: 'Kagawa Institute of Nutrition',
    web_page: 'http://www.eiyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1kdQX_H3d29xrZlVzws8UJ0IRMYBGZWw123jegH3lphhKgZVCJrljBw',
    id: 4835,
    acronym: 'KIN',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'eum.ac.jp',
    name: 'Elisabeth University of Music',
    web_page: 'http://www.eum.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIiMFJzEQM3_D-7FiPfDUUojboUVrRTrLZuh3BonV3lY96d0ZTfUyWfQ',
    id: 4836,
    acronym: 'EUM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fdcnet.ac.jp',
    name: 'Fukuoka Dental College',
    web_page: 'http://www.fdcnet.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8aexP-q0JDNAaOY_aRXz7aeyG6A0P6BDPXjv7XmvenJmQZU9VTmB4nE0',
    id: 4837,
    acronym: 'FDC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ferris.ac.jp',
    name: 'Ferris University',
    web_page: 'http://www.ferris.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSu8CAqzqyUEQRdbZwFFQPjRCrZPR1OoIEyi1EAjArpchKsik_UowVdQjx',
    id: 4838,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fish-u.ac.jp',
    name: 'National Fisheries University',
    web_page: 'http://www.fish-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ3ccu1L537ycM95QH_JYp2vKlQkz7O_iwJQJSG8x17g9l8XKTtYNp-hA',
    id: 4839,
    acronym: 'NFU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fit.ac.jp',
    name: 'Fukuoka Institute of Technology',
    web_page: 'http://www.fit.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRb-d73KwtmuGXMDnApwBEvIDXlRaUazch30B0m2pWyt0fX_v3aYFH4-Q',
    id: 4840,
    acronym: 'FIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fmu.ac.jp',
    name: 'Fukushima Medical College',
    web_page: 'http://www.fmu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrC9Muvwh3EBZJ19pROCQHRfuHASxt-T3o5A90i9RXo2j3F2t_gsZvlQ',
    id: 4841,
    acronym: 'FMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fpu.ac.jp',
    name: 'Fukui Prefectural University',
    web_page: 'http://www.fpu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0l8wWTlIdlIJ_aX35iHGiS2wy2d6KBU6-LhNTzZiX7PiPSFmXrkwz2UQ',
    id: 4842,
    acronym: 'FPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fujijoshi.ac.jp',
    name: "Fuji Women's College",
    web_page: 'http://www.fujijoshi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaEpJACc-wkXf4pwB8eIKCxCud0OibRl7LtRCFnEyRLEjb0nzpwAjgg2M',
    id: 4843,
    acronym: 'FWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fujita-hu.ac.jp',
    name: 'Fujita Health University',
    web_page: 'http://www.fujita-hu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZF84_Pa1f10Excne2UX6pgOK83HhOp1QFePBst0i6xoZWFotifoP-lC8XQw',
    id: 4844,
    acronym: 'FHU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukui-med.ac.jp',
    name: 'Fukui Medical School',
    web_page: 'http://www.fukui-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyazN_lnh1EPL5gIgmkalskGllorKSfXkN7YtVocYvkPy7Og_PDV-zoA',
    id: 4845,
    acronym: 'FMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukui-u.ac.jp',
    name: 'Fukui University',
    web_page: 'http://www.fukui-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaEuAeZFCKHWG5nmTLPYr37QrqnTa65itLh8-EcOL0KY4Ti8-p0TW3rSk',
    id: 4846,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukui-ut.ac.jp',
    name: 'Fukui University of Technology',
    web_page: 'http://www.fukui-ut.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL6mrgwKM9kVYjGQXj4R2P3FBldd87t_Rd5GspfP-IgCEKBG7yT3nGA5wt',
    id: 4847,
    acronym: 'FUT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukuoka-edu.ac.jp',
    name: 'Fukuoka University of Education',
    web_page: 'http://www.fukuoka-edu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdehSFS-ZJ2ImFOiFTQtxKjKW2JNVsIWUTEQ87d3Qy9Ld1B__9K2awjXFt',
    id: 4848,
    acronym: 'FUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukuoka-int-u.ac.jp',
    name: 'Fukuoka International University',
    web_page: 'http://www.fukuoka-int-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_AqJtJokSKfwAKwwPEzWNHZYUATAqNCwjHOeNG2eRk-riHscvoAdZTl-5',
    id: 4849,
    acronym: 'FIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukuoka-pu.ac.jp',
    name: 'Fukuoka Prefectural University',
    web_page: 'http://www.fukuoka-pu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5oHZII-GV-NcBpB11hiGrSANAqJiRWB0SSaIGVAzaZvlOWZryp2xJyJk',
    id: 4850,
    acronym: 'FPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukuoka-u.ac.jp',
    name: 'Fukuoka University',
    web_page: 'http://www.fukuoka-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbDs6iizDAMUgODdyw2VK75Gs4vJ_R5Fi9EnOyOEr7lcvp--mhFUo43eLXxA',
    id: 4851,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukushima-u.ac.jp',
    name: 'Fukushima University',
    web_page: 'http://www.fukushima-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrC9Muvwh3EBZJ19pROCQHRfuHASxt-T3o5A90i9RXo2j3F2t_gsZvlQ',
    id: 4852,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fukuyama-u.ac.jp',
    name: 'Fukuyama University',
    web_page: 'http://www.fukuyama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZXWxluD9vBGRiN8KVMGyQoogNZBGh1cjVn_x65Dklsqya5f-n676i8W8',
    id: 4853,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'fwu.ac.jp',
    name: "Fukuoka Women's University",
    web_page: 'http://www.fwu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6MLyLvKnvBvMz2du2WRN1ImL7GB6HorhUNoOJX1YusrW221ObobzmiNs',
    id: 4854,
    acronym: 'FWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gakusen.ac.jp',
    name: 'Aichi Gakusen University',
    web_page: 'http://www.gakusen.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkucggiSYn0qI4OXE8pHHEM7_rUMbCewT2inkeS9zP3qMmNcy77aSByaU',
    id: 4855,
    acronym: 'AGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gakushuin.ac.jp',
    name: 'Gakushuin University',
    web_page: 'http://www.gakushuin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBzlk8OQIs6j9Yr1ynDN8LprbljpOevN51XQXjjmZOFVJbcwjeNqi-tw',
    id: 4856,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'geidai.ac.jp',
    name: 'Tokyo National University of Fine Arts and Music',
    web_page: 'http://www.geidai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvEdV2dQoX3bNjpbkOAXuwg_8xYCOfhloiUALPqnIz3mQl92Vvc0P6Edg',
    id: 4857,
    acronym: 'TNUFAM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gifu-keizai.ac.jp',
    name: 'Gifu Keizai University',
    web_page: 'http://www.gifu-keizai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQqgygMMx4hqUt3UiVyX9RyCJY5khQc-2w0_YR8acWBSlkVwYfIa9ckhc',
    id: 4858,
    acronym: 'GKU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gifu-kyoiku.ac.jp',
    name: 'Gifu University for Education and Languages',
    web_page: 'http://www.gifu-kyoiku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGhrb9q4h0Mhb4_9X-yJWZ0sm5fny5U4GNX00RsAesqxvFwDze5DPnJ1A',
    id: 4859,
    acronym: 'GUFEL',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gifu-pu.ac.jp',
    name: 'Gifu Pharmaceutical University',
    web_page: 'http://www.gifu-pu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaEuAeZFCKHWG5nmTLPYr37QrqnTa65itLh8-EcOL0KY4Ti8-p0TW3rSk',
    id: 4860,
    acronym: 'GPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gifu-u.ac.jp',
    name: 'Gifu University',
    web_page: 'http://www.gifu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZe6JrS7QhpzaU6XVi27SxjNminMnHRY4m9MBw7hthHzoQ-Xh7QGzJ5w',
    id: 4861,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gijodai.ac.jp',
    name: "Gifu Women's University",
    web_page: 'http://www.gijodai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiSKZpXL0upvHwZL5s6do7GcM6i1iqIsrg-vyQxfrNfBBl8I1ZQJTvO1U',
    id: 4862,
    acronym: 'GWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gpwu.ac.jp',
    name: "Gunma Prefectural Women's University",
    web_page: 'http://www.gpwu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 4863,
    acronym: 'GPWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'gunma-u.ac.jp',
    name: 'Gunma University',
    web_page: 'http://www.gunma-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnToJuxJgMS6ibtbEQmrkBdMcZlPU5BAgvDsE7fDM_RBqc5fpykDnNJw',
    id: 4864,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hachinohe-u.ac.jp',
    name: 'Hachinohe University',
    web_page: 'http://www.hachinohe-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUHvavbqlJ72wAqMuvpKn0CMHmxdBOhYk-yz_bkRx8ecckuNo20Q8jZEo',
    id: 4865,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hakodate-u.ac.jp',
    name: 'Hakodate University',
    web_page: 'http://www.hakodate-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4s6uz-w7EtvUHSrhmCgJGTmc9Nw5GBlIz0VdCa4NVfFDe2PX2BZoeZRc',
    id: 4866,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hakuoh.ac.jp',
    name: 'Hakuoh University',
    web_page: 'http://www.hakuoh.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4867,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hamamatsu-u.ac.jp',
    name: 'Hamamatsu University',
    web_page: 'http://www.hamamatsu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3Zt_3e19qMaVtKKcp6kqy0wKGWJgqYCVdKKYzJrI4f9uanWnMIfqRZK4',
    id: 4868,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hama-med.ac.jp',
    name: 'Hamamatsu University School of Medicine',
    web_page: 'http://www.hama-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOTcQoHqkcvJxwSarDaSdAPJJl8m1nCqwHPCMyaG0ittW2WQ7ZcbYyUfI',
    id: 4869,
    acronym: 'HUSM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hanazono.ac.jp',
    name: 'Hanazono University',
    web_page: 'http://www.hanazono.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyf4l1yuQ7SS9ECKIK7CCPMyi9r3vil8I25v996Ieo-dticFzZqqW8Lx8',
    id: 4870,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hannan-u.ac.jp',
    name: 'Hannan University',
    web_page: 'http://www.hannan-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtnVx4v1GgAhHk0TF1vYSyV7Mpop3Dx3Uq1OEl9-gdSRa2SVX73xvHOxw',
    id: 4871,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'h-bunkyo.ac.jp',
    name: "Hiroshima Bunkyo Women's University",
    web_page: 'http://www.h-bunkyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAAZGY7Dq_J6LtX19TTH9Gp3MURWN0TN1Xv1KOhUkvBpUXzNv40oSjZg',
    id: 4872,
    acronym: 'HBWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'heisei-u.ac.jp',
    name: 'Fukuyama Heisei University',
    web_page: 'http://www.heisei-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 4873,
    acronym: 'FHU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hijiyama-u.ac.jp',
    name: 'Hijiyama University',
    web_page: 'http://www.hijiyama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGKBuqZdBQdwx1m2weuHDb0sGmUZXQEc13dVs2gLfqzoIvc6FCKyq2f5Q',
    id: 4874,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'himeji-du.ac.jp',
    name: 'Himeji Dokkyo University',
    web_page: 'http://www.himeji-du.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmhYlF6Bv-bST4k0MVysr8PDmODM4ppxktvhuWcVyY-Arx1DBluchqYF0',
    id: 4875,
    acronym: 'HDU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'himeji-tech.ac.jp',
    name: 'Himeji Institute of Technology',
    web_page: 'http://www.himeji-tech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFJd-ckrmbxoAUbhF5Am09o3u5MimVad3kTwiyUNoXMcC9n1YgyDHmniNP',
    id: 4876,
    acronym: 'HIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hirogaku-u.ac.jp',
    name: 'Hirosaki Gakuin University',
    web_page: 'http://www.hirogaku-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS31hl7ww8hjawjajZ8PKizQEq_uy-1-ep3lno2HpG4_HeqZqNs_mdNKg',
    id: 4877,
    acronym: 'HGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hirojo-u.ac.jp',
    name: "Hiroshima Women's University",
    web_page: 'http://www.hirojo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhcrQkGYy6c2wrMmjV-JYNyDdoihXct672f35E6BxZQFOQd3dyxxqQCPrM',
    id: 4878,
    acronym: 'HWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hirokoku-u.ac.jp',
    name: 'Hiroshima International University',
    web_page: 'http://www.hirokoku-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmMPmcI4dj-Cm4wX4H39uJDnZ1yfs54N-8HV7XM79DJ0Gj8PZ8dxMPctDC',
    id: 4879,
    acronym: 'HIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hirosaki-u.ac.jp',
    name: 'Hirosaki University',
    web_page: 'http://www.hirosaki-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF8S4l9e2v-epnOvDuxZH_YDbJodsIDEjfur1_phFaXccrY9lAEXfWxC0',
    id: 4880,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hiroshima-cu.ac.jp',
    name: 'Hiroshima City University',
    web_page: 'http://www.hiroshima-cu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvWYb2XRGdDf4oVV-V-7Tl-FQQOdL70EiRIyWHgutB_NRh6mLF_-5Zxg',
    id: 4881,
    acronym: 'HCU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hiroshima-pu.ac.jp',
    name: 'Hiroshima Prefectural University',
    web_page: 'http://www.hiroshima-pu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQiK3vWEqpgAxvpG8hnfDQ0kPz2OXUxUmu8Ayb-egKdePPJtQhD0uMAAA',
    id: 4882,
    acronym: 'HPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hiroshima-u.ac.jp',
    name: 'Hiroshima University',
    web_page: 'http://www.hiroshima-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpMo1PwEYvr9AL0YMKuUhf0a_syyhcQzGclkakbbQVHM2DdboJrLLrVw',
    id: 4883,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hit.ac.jp',
    name: 'Hokkaido Institute of Technology',
    web_page: 'http://www.hit.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy6Kf9puQJSBoolmpKGKSzzLYT29PjOC6JqwrQceOK-_mQJdTg-jC-jg',
    id: 4884,
    acronym: 'HIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hi-tech.ac.jp',
    name: 'Hachinohe Institute of Technology',
    web_page: 'http://www.hi-tech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBuE83TabTZmukz4R_WAXbqZLstBGTAAwhNxUEgHWjnwjafHnF74Kjp_Y',
    id: 4885,
    acronym: 'HIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hit-u.ac.jp',
    name: 'Hitotsubashi University',
    web_page: 'http://www.hit-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHHyAIQFDIZ2wERB1lNN2OgMdObetziZilDNtdrA5tVuoI-g6II-hoRQ',
    id: 4886,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hiu.ac.jp',
    name: 'Heisei International University',
    web_page: 'http://www.hiu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ857I-Bc_CBHt00aqidovc2ifxj8q1EBSxshJFOvcRCfWmu0tUNdLn_gPA',
    id: 4887,
    acronym: 'HIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hju.ac.jp',
    name: 'Hiroshima Jogakuin University',
    web_page: 'http://www.hju.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpc43tLTW5lw6rozyvB1_V-ixdU4Daa5TE2-F2JzNLJAyOE57sJB2CCw',
    id: 4888,
    acronym: 'HJU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hkg.ac.jp',
    name: 'Hiroshima Kokusai Gakuin University',
    web_page: 'http://www.hkg.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 4889,
    acronym: 'HKGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hokkai-k-u.ac.jp',
    name: 'Hokkaigakuen University of Kitami',
    web_page: 'http://www.hokkai-k-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLMGt-1PzUEboslGLzHp2GIicEYGFbcuyCwR4Lar5Vq3HVuYS7gkMqsgk',
    id: 4890,
    acronym: 'HUK',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hokkai-s-u.ac.jp',
    name: 'Hokkaigakuen University',
    web_page: 'http://www.hokkai-s-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPKz5LW-zAdjLBaUGX4XdL3YXvtTtlY9b2ArRc45bkXQJoMx6zGRmjbzI',
    id: 4891,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hokkyodai.ac.jp',
    name: 'Hokkaido University of Education',
    web_page: 'http://www.hokkyodai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyATgZYTwHQAPpAu_j7lozzIPf0TJw2gLEl9vqNjVSm2LHOoM-zy2aWg',
    id: 4892,
    acronym: 'HUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hokudai.ac.jp',
    name: 'Hokkaido University',
    web_page: 'http://www.hokudai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyATgZYTwHQAPpAu_j7lozzIPf0TJw2gLEl9vqNjVSm2LHOoM-zy2aWg',
    id: 4893,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hoku-iryo-u.ac.jp',
    name: 'Hokkaido University of Health Sciences',
    web_page: 'http://www.hoku-iryo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyATgZYTwHQAPpAu_j7lozzIPf0TJw2gLEl9vqNjVSm2LHOoM-zy2aWg',
    id: 4894,
    acronym: 'HUHS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hokuriku-u.ac.jp',
    name: 'Hokuriku University',
    web_page: 'http://www.hokuriku-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdxzJvkSgC_K0xOwmGQpCKVE7JZuagLPN95E8dOw3cl4aiwta0XY7sAPg',
    id: 4895,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hokusei.ac.jp',
    name: 'Hokusei Gakuen University',
    web_page: 'http://www.hokusei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSzdZTbioGmMiQRNIrulPMie89u5ZKpRz4MNQe17EVEBd0L1KXLBmQagA',
    id: 4896,
    acronym: 'HGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hokuyakudai.ac.jp',
    name: 'Hokkaido Institute of Pharmaceutical Sciences',
    web_page: 'http://www.hokuyakudai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmTPE6HYa2WyBQyYnMTKWk-arRwYtTdp6UngH4RrC1e3pNSyuGfp4oREg',
    id: 4897,
    acronym: 'HIPS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hosei.ac.jp',
    name: 'Hosei University',
    web_page: 'http://www.hosei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-NMdVTA4HM_B-lppAUjZ-_e6ygJtuEB8KKN5yisv_FmgApIElTVQleQ',
    id: 4898,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hoshi.ac.jp',
    name: 'Hoshi University',
    web_page: 'http://www.hoshi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0_D4WRu9ok8XjcwMIazWqCj96gwq0ClC3JKTo0kbAHzcmJqcI3puRHg',
    id: 4899,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hue.ac.jp',
    name: 'Hiroshima University of Economics',
    web_page: 'http://www.hue.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_fCzjYF1HCDKLPbNx4UIxPM2hvbolGPeIWuGXqA2UfinNeCbRU5NzrA',
    id: 4900,
    acronym: 'HUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hyogo-dai.ac.jp',
    name: 'Hyogo University',
    web_page: 'http://www.hyogo-dai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnq7eITLYyqRklddAftIQ1Oe4PzJ-6mNjjib0TmDWxvmh40ljZhN5K7iA',
    id: 4901,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hyogo-u.ac.jp',
    name: 'Hyogo University of Education',
    web_page: 'http://www.hyogo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnq7eITLYyqRklddAftIQ1Oe4PzJ-6mNjjib0TmDWxvmh40ljZhN5K7iA',
    id: 4902,
    acronym: 'HUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'hyo-med.ac.jp',
    name: 'Hyogo College of Medicine',
    web_page: 'http://www.hyo-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4903,
    acronym: 'HCM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ibaraki.ac.jp',
    name: 'Ibaraki University',
    web_page: 'http://www.ibaraki.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo8ug7dpqiX8iLz0ytwqfewB5ZRQY-kyNY7lrHp12GVTRRdx6LNuvc1Q',
    id: 4904,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'icc.ac.jp',
    name: 'Ibaraki Christian College',
    web_page: 'http://www.icc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZCX3py3JZESPaC0ASxAntrmAvqsW8zc79Is7dYeMKkWcElpRioxHnaw',
    id: 4905,
    acronym: 'ICC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'icu.ac.jp',
    name: 'International Christian University',
    web_page: 'http://www.icu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgGuFkyiOrNCbs7ec9XCXOgYvOzr71AV8kFW8RyH8C2wXKbfxowV_GwD8',
    id: 4906,
    acronym: 'ICU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ip.kyusan-u.ac.jp',
    name: 'Kyushu Sangyo University',
    web_page: 'http://www.ip.kyusan-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbP_XsO0zDcnEf2ZZ6Ia7wX-2vDiRKY17wCrMzW3HijEilNTa0FFa-cZqU',
    id: 4907,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ipu.ac.jp',
    name: 'Ibaraki Prefectural University of Health Sciences',
    web_page: 'http://www.ipu.ac.jp/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4908,
    acronym: 'IPUHS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'isenshu-u.ac.jp',
    name: 'Ishinomaki Senshu University',
    web_page: 'http://www.isenshu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4909,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'it-chiba.ac.jp',
    name: 'Chiba Institute of Technology',
    web_page: 'http://www.it-chiba.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ9qNyj1Kzs88uXiZhwywW_KF5YLLHTjrQNArQm0jIpE_Yk7uwHY0BSQ',
    id: 4910,
    acronym: 'CIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'it-hiroshima.ac.jp',
    name: 'Hiroshima Institute of Technology',
    web_page: 'http://www.it-hiroshima.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXNzsDxz4DxSW9SkvYA3OWnawXhKnK9XQ2VLhl_13JECD1mSn3coB_YA',
    id: 4911,
    acronym: 'HIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'iuhw.ac.jp',
    name: 'International University of Health and Welfare',
    web_page: 'http://www.iuhw.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0vkyVzes3XkHs-iS-zG0I3s3BZuyY4mzJ37t2yEmCrWS4CWuN1NVCd5s',
    id: 4912,
    acronym: 'IUHW',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'iuj.ac.jp',
    name: 'International University of Japan',
    web_page: 'http://www.iuj.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTJU8Yx8xtMBr1nPDpKyCXgOA8u_mhJSVnm-BZRrnVCCrBXO9nVs6j1LA',
    id: 4913,
    acronym: 'IUJ',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'iuk.ac.jp',
    name: 'International University of Kagoshima',
    web_page: 'http://www.iuk.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXn5sdUhSijVRqY0hzR0rDaNr_3haRu3onC3VP4OUt3sp9q6wugYDfyw',
    id: 4914,
    acronym: 'IUK',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'iwakimu.ac.jp',
    name: 'Iwaki Meisei University',
    web_page: 'http://www.iwakimu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4915,
    acronym: 'IMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'iwate-med.ac.jp',
    name: 'Iwate Medical University',
    web_page: 'http://www.iwate-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxIPgVnIQsUHmAdrjblys2c-CuC7WbAPLeWSU_MPc5zbxOKpBYBB6qUg',
    id: 4916,
    acronym: 'IMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'iwate-pu.ac.jp',
    name: 'Iwate Prefectural University',
    web_page: 'http://www.iwate-pu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgn5wbpF3M-7zORLSSV9G6R-k1AV1lsyjxQx_I0WmWd4gK_2vRngPi5Y0',
    id: 4917,
    acronym: 'IPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'iwate-u.ac.jp',
    name: 'Iwate University',
    web_page: 'http://www.iwate-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8IBrxtpJ2kYtO03aJas2kHcPcDE-yt2Q0_1ZIi0QIxcN-EBOBXwBAWfez',
    id: 4918,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'jaist.ac.jp',
    name: 'Japan Advanced Institute of Science and Technology',
    web_page: 'http://www.jaist.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQRbL89HErZDISRZQwKK2RKY5u9QOEkL9j-vdxU1W-uPNcPQMIezCCCVA',
    id: 4919,
    acronym: 'JAIST',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'jichi.ac.jp',
    name: 'Jichi Medical School',
    web_page: 'http://www.jichi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtDgXKEperZgpBdaOQbK1xHgr4ve6CuhG7IjKeucJbcgg129hRTgfzObU',
    id: 4920,
    acronym: 'JMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'jikei.ac.jp',
    name: 'Jikei University School of Medicine',
    web_page: 'http://www.jikei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReSItgwq15rJjUQoMxrk3vqu2AabwG7lswL5JW_bduTrCIO8IKyCgx2w',
    id: 4921,
    acronym: 'JUSM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'jissen.ac.jp',
    name: "Jissen Women's University",
    web_page: 'http://www.jissen.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4922,
    acronym: 'JWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'jiu.ac.jp',
    name: 'Josai International University',
    web_page: 'http://www.jiu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNOKrGDYTO0tCu-tB9kcP5rj-brZKuPHe55M_82wDFQwIX_mkjHD9OvYg',
    id: 4923,
    acronym: 'JIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'jobu.ac.jp',
    name: 'Jobu University',
    web_page: 'http://www.jobu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREqHMlzzv18a66N3rdTpCyT3Hvd915mpkeRVzLcsoLa4fgNjcjdYmQ9Q',
    id: 4924,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'josai.ac.jp',
    name: 'Josai University',
    web_page: 'http://www.josai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdZOCr6X_b-lvIdeeqZr6iOjYGY0Ev1jsIeCk05_iUaZAevA6Uh9KrUA',
    id: 4925,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'joshibi.ac.jp',
    name: "Women's College of Fine Arts",
    web_page: 'http://www.joshibi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6TOlmg4rRQDWQDtMkIjk5qPZtdIraKHtWVXWZFihJma4iTjuzqy-nkO8',
    id: 4926,
    acronym: 'WCFA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'juen.ac.jp',
    name: 'Joetsu University of Education',
    web_page: 'http://www.juen.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCjis58lbLLCNSiyGriH5eUjiGo2rhmVJpshqzQnZrkAtgJrh0vywzTkU',
    id: 4927,
    acronym: 'JUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'juntan.k-junshin.ac.jp',
    name: 'Kagoshima Immaculate Heart University',
    web_page: 'http://www.juntan.k-junshin.ac.jp/jundai/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 4928,
    acronym: 'KIHU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'juntendo.ac.jp',
    name: 'Juntendo University',
    web_page: 'http://www.juntendo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvuIFVszaR_pjEfK3Mmq8DUpivhJ3fde21u7ZYbW3aoufpbv_jpHn9Wd4',
    id: 4929,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'jwu.ac.jp',
    name: "Japan Women's University",
    web_page: 'http://www.jwu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyx1FOiiwnrArZkSMfn6n8KRKNak9oZA4JPf_wVhJENBxjvm03rFhk9SM',
    id: 4930,
    acronym: 'JWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kagawa-u.ac.jp',
    name: 'Kagawa University',
    web_page: 'http://www.kagawa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2eyO4383lhZMFILfkcoDq7TR3VT810VWkyGr_5D2kFT_aikgIfoRSyAM',
    id: 4931,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kagoshima-u.ac.jp',
    name: 'Kagoshima University',
    web_page: 'http://www.kagoshima-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRty1VSUQMc5xMNPr59l10QToKAPCjONOJBKR1L9IcQCjvUcglOGZsRdg8',
    id: 4932,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kamakura-u.ac.jp',
    name: "Kamakura Women's College",
    web_page: 'http://www.kamakura-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStNLvlgKAbsOg95AXdbuwpbeK36XGrchIvn8dVDeIObNWBxViSSXW4IQ',
    id: 4933,
    acronym: 'KWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanagawa-it.ac.jp',
    name: 'Kanagawa Institute of Technology',
    web_page: 'http://www.kanagawa-it.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnzWZrj-6xKdZN89Ct50qutlTBkL0U5ZqJhtRATZSBjllRDKeOWpH8PT0',
    id: 4934,
    acronym: 'KIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanagawa-u.ac.jp',
    name: 'Kanagawa University',
    web_page: 'http://www.kanagawa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcevUpnEz7IT99-hT8PGNJL3atFUa9waD0MfjhgToERpS_6t4Sc9Qb8Q',
    id: 4935,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanazawa-bidai.ac.jp',
    name: 'Kanazawa College of Art',
    web_page: 'http://www.kanazawa-bidai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcqdesq9Q8KQfUj8qD6LTdKGX4DrHY8nMhNJ63rI3niLSK8g5qbipsfCw8',
    id: 4936,
    acronym: 'KCA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanazawa-eco.ac.jp',
    name: 'Kanazawa College of Economics',
    web_page: 'http://www.kanazawa-eco.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe8vozeHMW2J2l_H5_VgcEhzzw_YgwzTvh8a9KZV4CwZcum9KNbe19Cw',
    id: 4937,
    acronym: 'KCE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanazawa-gu.ac.jp',
    name: 'Kanazawa Gakuin University',
    web_page: 'http://www.kanazawa-gu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbqx74MAeN0kljSBvMkLGg78tNK43BwCC71g5de6qWwhw_bnJbcUKOCqs',
    id: 4938,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanazawa-it.ac.jp',
    name: 'Kanazawa Institute of Technology',
    web_page: 'http://www.kanazawa-it.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe8vozeHMW2J2l_H5_VgcEhzzw_YgwzTvh8a9KZV4CwZcum9KNbe19Cw',
    id: 4939,
    acronym: 'KIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanazawa-med.ac.jp',
    name: 'Kanazawa Medical University',
    web_page: 'http://www.kanazawa-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4940,
    acronym: 'KMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanazawa-u.ac.jp',
    name: 'Kanazawa University',
    web_page: 'http://www.kanazawa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRCkQL_kv32xTdFNM5WMEii3fYGdzqVuqp4FBvJ577jIGFkvW5aO8iowE',
    id: 4941,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kankyo-u.ac.jp',
    name: 'Tottori University of Environmental Studies',
    web_page: 'http://www.kankyo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe8vozeHMW2J2l_H5_VgcEhzzw_YgwzTvh8a9KZV4CwZcum9KNbe19Cw',
    id: 4942,
    acronym: 'TUES',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kansai-gaidai-u.ac.jp',
    name: 'Kansai Gaidai University',
    web_page: 'http://www.kansai-gaidai-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS24f9is_dw50AQd6UruaKoLTeMcHX7Te-Md2ajPqDqrfvq40zHVW8pytjzbw',
    id: 4943,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kansai-u.ac.jp',
    name: 'Kansai University',
    web_page: 'http://www.kansai-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROA_-CjxXnzzc9J7YeN-GcP3J4XfumLpZSMzHEh320FQhe_LHGWoQwdA',
    id: 4944,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanto-gakuen.ac.jp',
    name: 'Kanto Gakuen University',
    web_page: 'http://www.kanto-gakuen.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4945,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kanto-gakuin.ac.jp',
    name: 'Kanto Gakuin University',
    web_page: 'http://www.kanto-gakuin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP8jnWeYrpTNy28sANJhrIGLy2iaeBaOMLe7VtFRwcMC7ctPRVSexyqA',
    id: 4946,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kawasaki-m.ac.jp',
    name: 'Kawasaki Medical School',
    web_page: 'http://www.kawasaki-m.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjuWqrRMEUZm4aJCILtCJm-eLzid4Ol7DNk9boienjzQbjIUfuCXXTuHs',
    id: 4947,
    acronym: 'KMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kawasaki-m.ac.jp',
    name: 'Kawasaki University of Medical Care',
    web_page: 'http://www.kawasaki-m.ac.jp/mw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgjbY83zChwc7gm4mYv7wWxCe5wZa6LopWjIcnkinKJ83FWkZaLq3axnQn',
    id: 4948,
    acronym: 'KUMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kbu.ac.jp',
    name: 'Kyoto Bunkyo University',
    web_page: 'http://www.kbu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyf4l1yuQ7SS9ECKIK7CCPMyi9r3vil8I25v996Ieo-dticFzZqqW8Lx8',
    id: 4949,
    acronym: 'KBU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kcua.ac.jp',
    name: 'Kyoto City University of Arts',
    web_page: 'http://www.kcua.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLI3QYFA_5vbjC-xvvX-Cb_RR1kJSNa1Y6WDub3pS0DD9yKLBhShES__g',
    id: 4950,
    acronym: 'KCUA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kdcnet.ac.jp',
    name: 'Kanagawa Dental College',
    web_page: 'http://www.kdcnet.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvFe6LCjWXYM55ffcT5jUCqK_4ebk8rQZD6TuTYs3sFcSGmPl8sSoA6hM',
    id: 4951,
    acronym: 'KDC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'keiho-u.ac.jp',
    name: 'Osaka University of Economics & Law',
    web_page: 'http://www.keiho-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgbb8QwWhVHvTbo6Jd4TzMzkWPbKDnVlE9rSs1Af2T-ngE8_KzVTumYXs',
    id: 4952,
    acronym: 'OUE&L',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'keio.ac.jp',
    name: 'Keio University',
    web_page: 'http://www.keio.ac.jp/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTm2LAPCyLU--AALcr_OXAxFjiLHW09KYJ67VyA3wwQQQTotuXfql51-UE',
    id: 4953,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'keisen.ac.jp',
    name: 'Keisen Jogaku-en College',
    web_page: 'http://www.keisen.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrr4Kr0Vy__JbtsYi-dJTlBVYd5h_2cf5DUxYcNA-7pBUlmMY4nQb6ItM',
    id: 4954,
    acronym: 'KJC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'keiwa-c.ac.jp',
    name: 'Keiwa College',
    web_page: 'http://www.keiwa-c.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaftaFy1PehktkU-7KPk-NkbbFj7eT_xgnozGTUTUtY_WVw49ZJ2kXb277',
    id: 4955,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kgwu.ac.jp',
    name: "Kawamura Gakuen Woman's University",
    web_page: 'http://www.kgwu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4956,
    acronym: 'KGWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kindai.ac.jp',
    name: 'Kinki University',
    web_page: 'http://www.kindai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHHYiibs9IgToFwhR2FTA9jw_vD54YvMGnbKu3ZX8U0dihoBkkcTD6d9c',
    id: 4957,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kinjo-u.ac.jp',
    name: 'Kinjo Gakuin University',
    web_page: 'http://www.kinjo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8YEIE0YZaAczYVMwSXvrwFESHFa9Z0kYSfy1SEqnHQRdMsFg5AAMOg8k',
    id: 4958,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kit.ac.jp',
    name: 'Kyoto Institute of Technology',
    web_page: 'http://www.kit.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXlytBXdIyjY5110vQPAnqaWcIVMSBbuQY933TWK97-qeTlwRsRRJgHg',
    id: 4959,
    acronym: 'KIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kitakyu-u.ac.jp',
    name: 'Kitakyushu University',
    web_page: 'http://www.kitakyu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtvVRyQNO9K5hQkGXf0jOazyhNSSwF45ZEHicUamfxHtrLqg8oV7mpQAA',
    id: 4960,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kitami-it.ac.jp',
    name: 'Kitami Institute of Technology',
    web_page: 'http://www.kitami-it.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL4rfknElrNEw2UyNrSJT1aTmfh5WmAM3Isv-nvYT92BLa4yixdlifn_vn',
    id: 4961,
    acronym: 'KIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kitasato-u.ac.jp',
    name: 'Kitasato University',
    web_page: 'http://www.kitasato-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUZIy0IvE4tUJsd4ov6lAn5lApzJFHdflIFZGpeY2-g6kEcHaUyyxo_w4',
    id: 4962,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kiu.ac.jp',
    name: 'Kyushu International University',
    web_page: 'http://www.kiu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNQfI1JtLG1TIoIkAZj0uaZQQtixZ7XSpOvaLBvnmKNsoRnh6hxIIVh28',
    id: 4963,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kiui.ac.jp',
    name: 'Kibi International University',
    web_page: 'http://www.kiui.ac.jp/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRJprViIJSRupTlMNS_EvLsTG4hZoS5IE76-YfRj_YeoAMTiQaD4AlFQqb5',
    id: 4964,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kms.ac.jp',
    name: 'Kagawa Medical School',
    web_page: 'http://www.kms.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6KQPxyLlkZc3vJoMaO4JBUzQqxkfBvxqlDWkp2U-PgHo5eavhfIe4nw',
    id: 4965,
    acronym: 'KMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kmu.ac.jp',
    name: 'Kansai Medical University',
    web_page: 'http://www.kmu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOTcQoHqkcvJxwSarDaSdAPJJl8m1nCqwHPCMyaG0ittW2WQ7ZcbYyUfI',
    id: 4966,
    acronym: 'KMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'knct.ac.jp',
    name: 'Kumamoto Institute of Technology',
    web_page: 'http://www.knct.ac.jp/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTAOdiJnYesrq4fyp8pLa11umyhgmzBRDivhjSEO0jd7B6d8bwzBUBEmQ',
    id: 4967,
    acronym: 'KIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kobe-c.ac.jp',
    name: 'Kobe Jogakuin University',
    web_page: 'http://www.kobe-c.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP9thwykXc6OcbyoZ83mFbDePU7HVbi-RStM5Xdl8aa6Gby8AT5dH34wHb-A',
    id: 4968,
    acronym: 'KJU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kobe-cufs.ac.jp',
    name: 'Kobe City University of ForeignStudies',
    web_page: 'http://www.kobe-cufs.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi_9jZvVu7Ot-sd8QmY-sGDZXHGd-bMCQ1GgRB_FHdmcA_lan35JV4nYc',
    id: 4969,
    acronym: 'KCUF',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kobe-du.ac.jp',
    name: 'Kobe Design University',
    web_page: 'http://www.kobe-du.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiprims1-ayFLwq1VnGbk1qcGo4Pj2zBK0nZGr9poYH6bLQlstgdObdyU',
    id: 4970,
    acronym: 'KDU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kobegakuin.ac.jp',
    name: 'Kobe Gakuin University',
    web_page: 'http://www.kobegakuin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp5EzSPEhDkHkaZupHY6sAh5zf6XBbdi080a4Xbj_ZdT_Zyhj43oL45lU',
    id: 4971,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kobe-kiu.ac.jp',
    name: 'Kobe International University',
    web_page: 'http://www.kobe-kiu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjDSLw31AoYSC4W6ufUDvXccqhBzNXxZ-o_ui4_vT9h6hclL7h7nkc51kR',
    id: 4972,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kobepharma-u.ac.jp',
    name: 'Kobe Pharmaceutical University',
    web_page: 'http://www.kobepharma-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp5EzSPEhDkHkaZupHY6sAh5zf6XBbdi080a4Xbj_ZdT_Zyhj43oL45lU',
    id: 4973,
    acronym: 'KPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kobe-shinwa.ac.jp',
    name: "Kobe Shinwa Women's University",
    web_page: 'http://www.kobe-shinwa.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOCX8iSY9Rfe0lNKxsgcB7p4Wcw6Q3nM0ISAAkltfVdTVPsVWu0HMtQGlo',
    id: 4974,
    acronym: 'KSWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kobe-u.ac.jp',
    name: 'Kobe University',
    web_page: 'http://www.kobe-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjDSLw31AoYSC4W6ufUDvXccqhBzNXxZ-o_ui4_vT9h6hclL7h7nkc51kR',
    id: 4975,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kochi-ms.ac.jp',
    name: 'Kochi Medical School',
    web_page: 'http://www.kochi-ms.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqCDMoUoMk4NQBhjOvcMLLAug3ce2J0Fmdmk3JEi7rB18KYiraVXGFlQ',
    id: 4976,
    acronym: 'KMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kochi-tech.ac.jp',
    name: 'Kochi University of Technology',
    web_page: 'http://www.kochi-tech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSShpYtcGJIsHOBrvsUXBjKKrVOxDPG2Mu4sVnNSc5MXpd3SnZrA5fZbg',
    id: 4977,
    acronym: 'KUT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kochi-u.ac.jp',
    name: 'Kochi University',
    web_page: 'http://www.kochi-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDNERBNgu0tCtuejcrzTQrqk3801OxojzkP4237QV0bYOiIro1X6Xb9c_e6w',
    id: 4978,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kochi-wu.ac.jp',
    name: "Kochi Women's University",
    web_page: 'http://www.kochi-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUkpvINdGVoq2LkZOzeBKcfQpU8Fi-7wD_zX1Ge1_bYBMKl4CP22bwgA',
    id: 4979,
    acronym: 'KWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kogakkan-u.ac.jp',
    name: 'Kogakkan University',
    web_page: 'http://www.kogakkan-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTkYtCm0z71igUNAVwCjUO055KGq38JOXYzswv4wLwMapdBDTkDiAQdpo',
    id: 4980,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kogakuin.ac.jp',
    name: 'Kogakuin University',
    web_page: 'http://www.kogakuin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdKhdSM7KAO6Sx3Vwd_2XeI8MeMvRXCcS1i2IioLY6TPt6Qvt4x7xnKU8x',
    id: 4981,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'koka.ac.jp',
    name: "Koka Women's College",
    web_page: 'http://www.koka.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwoydH7WeRKjTtwZzft5lCiTs1-oPAgUMd-KGMUj9UE-ocFww-L9PkPQ4',
    id: 4982,
    acronym: 'KWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kokugakuin.ac.jp',
    name: 'Kokugakuin University',
    web_page: 'http://www.kokugakuin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRu47CvzK2Xdf_SekrlJQAdJluAu16SQdkaIti1gszYWRDHsudcb6Kejs',
    id: 4983,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kokushikan.ac.jp',
    name: 'Kokushikan University',
    web_page: 'http://www.kokushikan.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeIvlcsQzSW34blpdwwcrjF41cDWPzdIylflxYsYCWqA_cOj68B0c8Olc',
    id: 4984,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'komazawa-u.ac.jp',
    name: 'Komazawa University',
    web_page: 'http://www.komazawa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIgT-IxdppHllWnMjjkoCbjfN3Zrd9I0k5zIoIe7Ne4-NMCsvTChSEfQ',
    id: 4985,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'konan-u.ac.jp',
    name: 'Konan University',
    web_page: 'http://www.konan-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWew9SyYdVfCuv64XjpkmnEUWsj5DgizYopElSkTGQfDVQL5g0Wsh_AQo',
    id: 4986,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'konan-wu.ac.jp',
    name: "Konan Women's University",
    web_page: 'http://www.konan-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg0e8kKcSgfSgfqlw2J9KaDwMQ3i8FndqbAuMakQhoCnBrzbBkwdZOPQ',
    id: 4987,
    acronym: 'KWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'korea-u.ac.jp',
    name: 'Korea University',
    web_page: 'http://www.korea-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1PurZo547AoiO0DfEm309DPDgZRYkj41ksdlZ40Jwu-MtXMBORGZPPA',
    id: 4988,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'koriyama-kgc.ac.jp',
    name: "Koriyama Women's University and College",
    web_page: 'http://www.koriyama-kgc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDcMnI3yX7cNqZnbcr2MR-p-XsUppniQAY7X_yKh1btFyu-039vNkO9_M',
    id: 4989,
    acronym: 'KWUC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'koshien.ac.jp',
    name: 'Koshien University',
    web_page: 'http://www.koshien.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRalBGObykkogvYxmWXZQnEb2KbMkrYNPOggcNQMZH9Y2tqAGH63ikBDoQ',
    id: 4990,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'koyasan-u.ac.jp',
    name: 'Koyasan University',
    web_page: 'http://www.koyasan-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8mV_CxM4qrUfAkwXK3607h6zBGz1KTvZ0Cm3MJjkjaLCzu5Xo-_mcAFg',
    id: 4991,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kpu.ac.jp',
    name: 'Kyoto Prefectural University',
    web_page: 'http://www.kpu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyf4l1yuQ7SS9ECKIK7CCPMyi9r3vil8I25v996Ieo-dticFzZqqW8Lx8',
    id: 4992,
    acronym: 'KPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kpu-m.ac.jp',
    name: 'Kyoto Prefectural University of Medicine',
    web_page: 'http://www.kpu-m.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMWa9lEF5XswOH745132Na7BunPFXa-9z5XAhKWKv57s5EX335-RJTsnxw',
    id: 4993,
    acronym: 'KPUM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kshosen.ac.jp',
    name: 'Kobe University of Mercantile Marine',
    web_page: 'http://www.kshosen.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRf4Jdu1OkywmcUrQl4HAZG2BT2gXHuuJDfoz5j-KNthN9vdvgNf9Q1ZAs',
    id: 4994,
    acronym: 'KUMM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ksu.ac.jp',
    name: 'Kurashiki Sakuyo University',
    web_page: 'http://www.ksu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 4995,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ktokai-u.ac.jp',
    name: 'Kyushu Tokai University',
    web_page: 'http://www.ktokai-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSASmmiYVGdn6OYuhhK4JtZDeRHXia3qud68FnPwxIwIFAepLih3ZeCHwQ',
    id: 4996,
    acronym: 'KTU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kufs.ac.jp',
    name: 'Kyoto University of Foreign Studies',
    web_page: 'http://www.kufs.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThYPMgzSywsJAB_BBhjcqi9fGvdv76UaQQK1XArmKa-ScI2qQokwHJpVY',
    id: 4997,
    acronym: 'KUFS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kuins.ac.jp',
    name: 'Kansai University of International Studies',
    web_page: 'http://www.kuins.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKTJLDqMqc7pHlP9yE169RomhsbhX_xGK8Z4L2bH7do9CAKgWGhPmy0Z5w',
    id: 4998,
    acronym: 'KUIS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kuis.ac.jp',
    name: 'Kanda University of International Studies',
    web_page: 'http://www.kuis.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZQJO35wxm-QyXvEmSa17Vnzt8N7DYcESEr3D9SRr9XKN_LEsd8OdbVfI',
    id: 4999,
    acronym: 'KUIS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kumagaku.ac.jp',
    name: 'Kumamoto Gakuen University',
    web_page: 'http://www.kumagaku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7J2T54niK80bdUp1PFlvlauiYMdfVi36ijLyqPB_qnghKQ-o_6rRYbkA',
    id: 5000,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kumamoto-u.ac.jp',
    name: 'Kumamoto University',
    web_page: 'http://www.kumamoto-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd1rZ3xCO47Y0rkps_734Z6BvzZ63z8pLRFxSg1AAWXqLNoJbki0s1W1tJ',
    id: 5001,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kunitachi.ac.jp',
    name: 'Kunitachi College of Music',
    web_page: 'http://www.kunitachi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-YXi4zynkKp5yiPuhC1ZeVC6VuHACIs1_ugqCXbBBGYlPJcVEjMLcFA',
    id: 5002,
    acronym: 'KCM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kure-u.ac.jp',
    name: 'Kure University',
    web_page: 'http://www.kure-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-zp-ZOtQzJFUb-t2xaOAHaPD7Khd6Jk7DGfd8yqzp6Otw91DYJHS-or4',
    id: 5003,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kurume-it.ac.jp',
    name: 'Kurume Institute of Technology',
    web_page: 'http://www.kurume-it.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUnkKb6u69MV43ngTEJMsG7F897ALHEPlRE8oh8UH25LV7olX3edXNQQ',
    id: 5004,
    acronym: 'KIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kurume-u.ac.jp',
    name: 'Kurume University',
    web_page: 'http://www.kurume-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYRzZCYw09-F5qsfPKlyK758DgON9OcCbfwGLBvu_9AP155vqLgef5TZeP',
    id: 5005,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kusa.ac.jp',
    name: 'Kurashiki University of Science and the Arts',
    web_page: 'http://www.kusa.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrAFXUx-zW7st5xshjZRLTcDRn4lqGUjDmLWaw10vN5OfSDB6hj_dhnw',
    id: 5006,
    acronym: 'KUSA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kushiro-pu.ac.jp',
    name: 'Kushiro Public University of Economics',
    web_page: 'http://www.kushiro-pu.ac.jp/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRXqeSCPYvVUi8irTAUjyUyPI644z4py1zdZ3gmXia64XR2y4xSza3Y95P6',
    id: 5007,
    acronym: 'KPUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kusw.ac.jp',
    name: 'Kansai University of Social Welfare',
    web_page: 'http://www.kusw.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSSAPnRrKOlf392yOPHDFQpmDG5jYTcAe4Kofyfz6T9vbphuCRSd4vew',
    id: 5008,
    acronym: 'KUSW',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kwansei.ac.jp',
    name: 'Kwansei Gakuin University',
    web_page: 'http://www.kwansei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUDkAn08LX_9RBXTtP7ygnvmUlyLz4K0AvWfkvjGuDK1DIk7I7K0qECo1n',
    id: 5009,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kwassui.ac.jp',
    name: "Kwassui Women's College",
    web_page: 'http://www.kwassui.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSYqnTTt1xLugSuo8EEh2kBsKOF_8kB0-6M9mO86Z273OnJTMA_YgskJC9',
    id: 5010,
    acronym: 'KWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kwc-u.ac.jp',
    name: "Kagoshima Women's College",
    web_page: 'http://www.kwc-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3F9NEELxtsIMvWBhD02OHUAbYFGVEbk2UY86xWVkBZUb4uq2ZQNbr2BI',
    id: 5011,
    acronym: 'KWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kwuc.ac.jp',
    name: "Kyushu Women's University",
    web_page: 'http://www.kwuc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC5hjoyzDoazZ7jrnDJuiDHfbnDWNZfqmsTGI5NivQt_yg9Y7obwVn5Rfs',
    id: 5012,
    acronym: 'KWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyokyo-u.ac.jp',
    name: 'Kyoto University of Education',
    web_page: 'http://www.kyokyo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuv7tebOATylgFWkOk7iI7F3uQBUcBEgqKk_8_dYbdi9HyH37uU3E3',
    id: 5013,
    acronym: 'KUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyorin-u.ac.jp',
    name: 'Kyorin University',
    web_page: 'http://www.kyorin-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZm-cWDPcW-OTFeGlm-CgBkEvDrIBMm6tHK6WSku_YSd-6eGSYih903Q',
    id: 5014,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyoritsu-ph.ac.jp',
    name: 'Kyoritsu Pharmaceutical University',
    web_page: 'http://www.kyoritsu-ph.ac.jp/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRJq_aGn1uKFe4U6fsNp2-8Rqpt8UAB3gIg_D7b5JIAXlVTBloTlfhaNEk',
    id: 5015,
    acronym: 'KPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyoritsu-wu.ac.jp',
    name: "Kyoritsu Woman's University",
    web_page: 'http://www.kyoritsu-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4BsZdrlqyFhA4c0r7RKNCrlwnWkzgKpGbAYCbHN9k1aJMoPQE4NPrQic',
    id: 5016,
    acronym: 'KWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyoto-art.ac.jp',
    name: 'Kyoto University of Art and Design',
    web_page: 'http://www.kyoto-art.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAETELEBB-7z3aBgRvYXM_M-y_DAcEuaIF-tXz9Ax15RzPfls9Ja3OqXI',
    id: 5017,
    acronym: 'KUAD',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyotogakuen-u.ac.jp',
    name: 'Kyoto Gakuen University',
    web_page: 'http://www.kyotogakuen-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRWg0Sn4kin8FB1M6VVr23iDGVr3xszxEdUSVpmJ0qa7Z5CHyEM1knGGcP',
    id: 5018,
    acronym: 'KGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyoto-phu.ac.jp',
    name: 'Kyoto Pharmaceutical University',
    web_page: 'http://www.kyoto-phu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8_kG8Qp_8noRayPNgHbEejSprCtl6aB67OJiREbLjZ15djUdUxU2cR-E',
    id: 5019,
    acronym: 'KPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyoto-seika.ac.jp',
    name: 'Kyoto Seika University',
    web_page: 'http://www.kyoto-seika.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjIYQfhas3B1weRbwrU4t5Rxx13XL4kn0ZOZFphja__EPwr16doZ20lu8',
    id: 5020,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyoto-su.ac.jp',
    name: 'Kyoto Sangyo University',
    web_page: 'http://www.kyoto-su.ac.jp/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRLY1h3aavMHVbGe-8TzTnH_XHRL00tuD6d68IatMnkFPKx0EV4Xzhm9w',
    id: 5021,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyoto-u.ac.jp',
    name: 'Kyoto University',
    web_page: 'http://www.kyoto-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuv7tebOATylgFWkOk7iI7F3uQBUcBEgqKk_8_dYbdi9HyH37uU3E3',
    id: 5022,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyoto-wu.ac.jp',
    name: "Kyoto Women's University",
    web_page: 'http://www.kyoto-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyf4l1yuQ7SS9ECKIK7CCPMyi9r3vil8I25v996Ieo-dticFzZqqW8Lx8',
    id: 5023,
    acronym: 'KWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyu-dent.ac.jp',
    name: 'Kyushu Dental College',
    web_page: 'http://www.kyu-dent.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRny4r3N4YW4qxP_e41X0AgFuju-ZKLNciOS5lf22tbwgqUOCCXczqedhI',
    id: 5024,
    acronym: 'KDC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyukyo-u.ac.jp',
    name: 'Kyushu Kyoritsu University',
    web_page: 'http://www.kyukyo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToDqVXsK2zQfPTq7IXC5FEmBmZ4sYS9nhdX2OPR5hpu6-fPR1t7PCTSZt8',
    id: 5025,
    acronym: 'KKU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyushu-id.ac.jp',
    name: 'Kyushu Institute of Design',
    web_page: 'http://www.kyushu-id.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmRAamGhD607AKk18Ud1mx2wyCQq08ihM3nyBSnFO4yQCWERx3PX9oiCY',
    id: 5026,
    acronym: 'KID',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyushu-ns.ac.jp',
    name: 'Kyushu University of Nursing and SocialWelfare',
    web_page: 'http://www.kyushu-ns.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC5hjoyzDoazZ7jrnDJuiDHfbnDWNZfqmsTGI5NivQt_yg9Y7obwVn5Rfs',
    id: 5027,
    acronym: 'KUNS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyushu-u.ac.jp',
    name: 'Kyushu University',
    web_page: 'http://www.kyushu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbDs6iizDAMUgODdyw2VK75Gs4vJ_R5Fi9EnOyOEr7lcvp--mhFUo43eLXxA',
    id: 5028,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'kyutech.ac.jp',
    name: 'Kyushu Institute of Technology',
    web_page: 'http://www.kyutech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJGIwI6qzV0bWCU3FcMLNC1DSqYLzlOMzgOHo1mu5Fzu8OpK5cFnwMiw',
    id: 5029,
    acronym: 'KIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'marianna-u.ac.jp',
    name: 'St. Marianna University School of Medicine',
    web_page: 'http://www.marianna-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvYGbmL6F_aWn3b5SMprNc2S2imv11ixfjmwC9DNdn3HWf0MeJwLYQrlM',
    id: 5030,
    acronym: 'SMUSM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'matsuyama-u.ac.jp',
    name: 'Matsuyama University',
    web_page: 'http://www.matsuyama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIPLYx9tXJSNE8A0K7FE3dvYoatkARyRQcduEiG_TzraT9ac0K8jzP90w',
    id: 5031,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'mdu.ac.jp',
    name: 'Matsumoto Dental University',
    web_page: 'http://www.mdu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQb0FcF2PC92VEjTFykMnAXjLV2st-uQ0eRExWBNTOuCxkPDpKPzfz6NKo',
    id: 5032,
    acronym: 'MDU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'meiji.ac.jp',
    name: 'Meiji University',
    web_page: 'http://www.meiji.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB0n09UQry9PtZudVZFPx6NZJ7PFcVzTG1ITsG8WAjn8EPbk-uT2Swn3m8',
    id: 5033,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'meijigakuin.ac.jp',
    name: 'Meiji Gakuin University',
    web_page: 'http://www.meijigakuin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWmjREexwIjC2SVx0UYqOgQNmhvjJ_Hlu0uIniMFs01DAUbS16g8HqXbQ',
    id: 5034,
    acronym: 'MGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'meiji-u.ac.jp',
    name: 'Meiji University of Oriental Medicine',
    web_page: 'http://www.meiji-u.ac.jp/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTO-kzEDeClBgkpBME2_oSJ9NzR-7AnepiGNTXDnTF2xKgHvKU1xjvCUw',
    id: 5035,
    acronym: 'MUOM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'meijo-u.ac.jp',
    name: 'Meijo University',
    web_page: 'http://www.meijo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRetRBOzCdK1acUFACaelLHrrPTrFCakWNPGi9YvIRowge2GX23beZcYoVj',
    id: 5036,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'meikai.ac.jp',
    name: 'Meikai University',
    web_page: 'http://www.meikai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5037,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'meio-u.ac.jp',
    name: 'Meio University',
    web_page: 'http://www.meio-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuhnYxmvtfnLNOvhN0rHexTTPrNBPSv9ojrmjQspVMrIWuQu26Dt_d_X8',
    id: 5038,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'meisei-u.ac.jp',
    name: 'Meisei University',
    web_page: 'http://www.meisei-u.ac.jp/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSXHCe1qPHq6AmaCesCzDtEHyTlLqmhOjLjGDXPXBaMJ4VKH6kKg8NWEDT-',
    id: 5039,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'mejiro.ac.jp',
    name: 'Mejiro University',
    web_page: 'http://www.mejiro.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCronAcNop-gAOlYOmL4BWkeJTvPI3zTtYu-CZgva_SkE8zhLurAKiaRjSsw',
    id: 5040,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'metro-hs.ac.jp',
    name: 'Tokyo Metropolitan University of Health Sciences',
    web_page: 'http://www.metro-hs.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 5041,
    acronym: 'TMUHS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'metro-u.ac.jp',
    name: 'Tokyo Metropolitan University',
    web_page: 'http://www.metro-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMa8FEFQZVvcoYkY3HHRL1BCPk5FOaG-7F6PPZyJqD2Ww-nmW7bwSCPA',
    id: 5042,
    acronym: 'TMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'mgu.ac.jp',
    name: "Miyagi Gakuin Women's College",
    web_page: 'http://www.mgu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 5043,
    acronym: 'MGWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'mie-chukyo-u.ac.jp',
    name: 'Matsusaka University',
    web_page: 'http://www.mie-chukyo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3J-5SU9sWqj_X3KoLta4PHLbs6Lc0-OWhDmI4Rn9Y73EdI2US5009UA',
    id: 5044,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'mie-u.ac.jp',
    name: 'Mie University',
    web_page: 'http://www.mie-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1qIwAxDuVKIzLmSDgodBpv2_XMANuK47EytiHBEnFmZsVFo8zld5KjfQ',
    id: 5045,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'mimasaka.ac.jp',
    name: "Mimasaka Women's College",
    web_page: 'http://www.mimasaka.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwoydH7WeRKjTtwZzft5lCiTs1-oPAgUMd-KGMUj9UE-ocFww-L9PkPQ4',
    id: 5046,
    acronym: 'MWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'miyakyo-u.ac.jp',
    name: 'Miyagi University of Education',
    web_page: 'http://www.miyakyo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpS5o6iiTfxOUcVcSSZ9tORrVV4PFxDlU_EdYrk3Z7IP4QRjFw5iUDbt4',
    id: 5047,
    acronym: 'MUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'miyasankei-u.ac.jp',
    name: 'Sankei University',
    web_page: 'http://www.miyasankei-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 5048,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'miyazaki-med.ac.jp',
    name: 'Miyazaki Medical College',
    web_page: 'http://www.miyazaki-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ3decv3AnUZmZgLknvA9VyLgivzC3KR9CTjNR4mHN-DuJd3f3LD4Ysg',
    id: 5049,
    acronym: 'MMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'miyazaki-mu.ac.jp',
    name: 'Miyazaki Municipal University',
    web_page: 'http://www.miyazaki-mu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_ufJsrxZPKCJTPXpZ1aYIMyufUETz3y3K8EZ8UgOimkHl95yexa0Kcyk',
    id: 5050,
    acronym: 'MMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'miyazaki-u.ac.jp',
    name: 'Miyazaki University',
    web_page: 'http://www.miyazaki-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLAEZEvCh8q962zLTgea0lR9SF52JcQ8iS7JRw-7_E2nyJqgjBjUwvhQ',
    id: 5051,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'morioka-u.ac.jp',
    name: 'Morioka College',
    web_page: 'http://www.morioka-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSImByu0yqVIIDloDs2fT_FHuSvtyNSIl3KY2ZAWB7tDp5oqAEmfsIJiqzs',
    id: 5052,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'mpu.ac.jp',
    name: 'Miyazaki Prefectural Nursing University',
    web_page: 'http://www.mpu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvYCcEV6_XtR8R9BnbTDSfd17bIUTJpUG9_x_ll4OpOa6uZmKzc6nUxbo',
    id: 5053,
    acronym: 'MPNU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'mukogawa-u.ac.jp',
    name: "Mukogawa Women's University",
    web_page: 'http://www.mukogawa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnjjTe82LIOT08Qp7xXsYWgcxObnNKwOJbQg89ZXDTLglWqHeWamfEcTE',
    id: 5054,
    acronym: 'MWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'muroran-it.ac.jp',
    name: 'Muroran Institute of Technology',
    web_page: 'http://www.muroran-it.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvJqc7QvLM8S0ZV61Sa0rOVy-3b5cEtWNvdlPfBVLl91_Xk0J7Tzhf4Qc',
    id: 5055,
    acronym: 'MIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'musabi.ac.jp',
    name: 'Musashino Art University',
    web_page: 'http://www.musabi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnQDV-8sJYBz7RqEb7COc7YNHhwq6Vlq3avZBtBFFHPqIeig2b6cqGh6lN',
    id: 5056,
    acronym: 'MAU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'musashi.ac.jp',
    name: 'Musashi University',
    web_page: 'http://www.musashi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-cHm1nCqLwOYBoIZoHRo4GKt8rRJSHaN5stdio971Pk_t4syE2_dmSu0',
    id: 5057,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'musashino-music.ac.jp',
    name: 'Musashino Academy of Music',
    web_page: 'http://www.musashino-music.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7LJ9lwOVLkyQTzEv09PMuN5-9vj-B6y_skHQTgJTdXhSLIvdeGMxaVw',
    id: 5058,
    acronym: 'MAM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'musashino-wu.ac.jp',
    name: "Musashino Women's University",
    web_page: 'http://www.musashino-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR50kyYagw7RDfF_VE7nNDywoSgIRB7b9LJnmVXl4U3_HZYhkxI3ZEySfk',
    id: 5059,
    acronym: 'MWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'musashi-tech.ac.jp',
    name: 'Musashi Institute of Technology',
    web_page: 'http://www.musashi-tech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-cHm1nCqLwOYBoIZoHRo4GKt8rRJSHaN5stdio971Pk_t4syE2_dmSu0',
    id: 5060,
    acronym: 'MIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'my-pharm.ac.jp',
    name: 'Meiji College of Pharmacy',
    web_page: 'http://www.my-pharm.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQoQyABLtykWknuaMIKpRohhyoVK_X_ozMo4OxvgCnQpypk2S0m-33FgQ',
    id: 5061,
    acronym: 'MCP',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'myu.ac.jp',
    name: 'Miyagi University',
    web_page: 'http://www.myu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpS5o6iiTfxOUcVcSSZ9tORrVV4PFxDlU_EdYrk3Z7IP4QRjFw5iUDbt4',
    id: 5062,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nagano.ac.jp',
    name: 'Nagano University',
    web_page: 'http://www.nagano.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvtXHd21i295bfrjafruOaQv_7TKMjpv6dQelwv_gO6rgSIJLnwRSJgIc',
    id: 5063,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nagaokaut.ac.jp',
    name: 'Nagaoka University of Technology',
    web_page: 'http://www.nagaokaut.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCgA55WrUhtt3KksOr2UJqv8LndgUb405Ajdcr4tpFm9tRlQYp9mfDgw',
    id: 5064,
    acronym: 'NUT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nagasakipu.ac.jp',
    name: 'Nagasaki Prefectural University',
    web_page: 'http://www.nagasakipu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmrQ3HIFUtW3POPnXo8oRHGP_JbfB6ddF8WTgcHfFGpi-1woZH1f1j4j2P',
    id: 5065,
    acronym: 'NPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nagasaki-u.ac.jp',
    name: 'Nagasaki University',
    web_page: 'http://www.nagasaki-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGV9k7QhpEDQNyWW-PCdxTjAH0NvhCLLC_e9L2UpZjPXVFxxkknBBmiQ',
    id: 5066,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nagoya-cu.ac.jp',
    name: 'Nagoya City University',
    web_page: 'http://www.nagoya-cu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJhyj7gYJLv7hXh08u8og9vjPBR17lnDJ65Qf7bHcOQmYmZ3RaeU7tOolr',
    id: 5067,
    acronym: 'NCU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nagoya-ku.ac.jp',
    name: 'Nagoya Economics University',
    web_page: 'http://www.nagoya-ku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdehSFS-ZJ2ImFOiFTQtxKjKW2JNVsIWUTEQ87d3Qy9Ld1B__9K2awjXFt',
    id: 5068,
    acronym: 'NEU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nagoya-u.ac.jp',
    name: 'Nagoya University',
    web_page: 'http://www.nagoya-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdehSFS-ZJ2ImFOiFTQtxKjKW2JNVsIWUTEQ87d3Qy9Ld1B__9K2awjXFt',
    id: 5069,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nagoya-wu.ac.jp',
    name: "Nagoya Women's University",
    web_page: 'http://www.nagoya-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgHjq2gVg9TK3eF1PfdutpYIBqz1mRvnhpmOCuoVE1PJJ3fSdlCLzpMmQ',
    id: 5070,
    acronym: 'NWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nakamura-u.ac.jp',
    name: 'Nakamura Gakuen University',
    web_page: 'http://www.nakamura-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBKy5XM-TYwlkSZu-pzkGgFHNBmp3eq2iLw6ewpoF515kT5agSzbl-4LE',
    id: 5071,
    acronym: 'NGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nankyudai.ac.jp',
    name: 'Minamikyushu University',
    web_page: 'http://www.nankyudai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 5072,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nanzan-u.ac.jp',
    name: 'Nanzan University',
    web_page: 'http://www.nanzan-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7pIygguBqWFiWfthm8N9kY7CO9OOzFNIZzeZTM70zYp2qnLqQ9rLVOQ',
    id: 5073,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nara-edu.ac.jp',
    name: 'Nara University of Education',
    web_page: 'http://www.nara-edu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_uOrArmzOuOQW65h8RzgNz90G6yBn8m0rtEWkNu8a3ieYpDjyMK1TQs0',
    id: 5074,
    acronym: 'NUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'naramed-u.ac.jp',
    name: 'Nara Medical University',
    web_page: 'http://www.naramed-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_pEaUtOol0coBT7PFtHOtm5oEWzPLJwD15Sot298wmchqgTLpwGcEJE0',
    id: 5075,
    acronym: 'NMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nara-su.ac.jp',
    name: 'Nara Sangyo University',
    web_page: 'http://www.nara-su.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMonSsqTEtQ5ee-IMNtW4gJW_gJi1v9C_qlWYFcHMNFruG7Vlpm5VAxoY',
    id: 5076,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nara-u.ac.jp',
    name: 'Nara University',
    web_page: 'http://www.nara-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvyms2YyyP4l61MC1789L-gsgmHCuoHCZmWESVlpgrFJa1pbwvYfS4gw',
    id: 5077,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'narauc.ac.jp',
    name: 'Nara University of Commerce',
    web_page: 'http://www.narauc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4sC1vq0uzuTkhuSgL239NzdySo8j-_LGg7wAJcRvGZNHpIJCbggN-4TbzIQ',
    id: 5078,
    acronym: 'NUC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nara-wu.ac.jp',
    name: "Nara Women's University",
    web_page: 'http://www.nara-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScfS7yvwO7FL3aEE9shZxQBJ8toUItkq4OFMweCfJMFjl5nTRrrQRn9nQ',
    id: 5079,
    acronym: 'NWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'naruto-u.ac.jp',
    name: 'Naruto University of Education',
    web_page: 'http://www.naruto-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToBh7cgiOzcWmkhjM0OUh-xL35-3zje1MRzDIuFnJYLqvAEpWvVUCNeA',
    id: 5080,
    acronym: 'NUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nbu.ac.jp',
    name: 'Nippon Bunri University',
    web_page: 'http://www.nbu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYrI2_ybOE0C8txdBglr1tpVF5ia0jsYm3wS6y1MQdwzwrMU2ZrKcM4NY',
    id: 5081,
    acronym: 'NBU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ndmc.ac.jp',
    name: 'National Defence Medical College',
    web_page: 'http://www.ndmc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkjmkoyA2kl0Oh8esOKG0Ke7sergSfTv0VSUTzFgzMHPikKpR5jurG7Oc',
    id: 5082,
    acronym: 'NDMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ndsu.ac.jp',
    name: 'Nortre Dame Seishin University',
    web_page: 'http://www.ndsu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkkLloELekF3xr-Z9UySJ2p101_hnlJYq5KBM_r3DVAhn9yH7fQToDNxA',
    id: 5083,
    acronym: 'NDSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ndu.ac.jp',
    name: 'Nippon Dental University',
    web_page: 'http://www.ndu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7AnNHIjmmpn413JSgMdl_xH2-eJoIxs9eyI_XSzv7BB8YHX3FJgYPwjrU',
    id: 5084,
    acronym: 'NDU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nebuta.ac.jp',
    name: 'Aomori Public College',
    web_page: 'http://www.nebuta.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiOxusYa4xh6HR2mCXqnDoaRgmVDHmSvlElX1Bx8xyEhJATV631sywHgE',
    id: 5085,
    acronym: 'APC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'n-fukushi.ac.jp',
    name: 'Nihon Fukushi University',
    web_page: 'http://www.n-fukushi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 5086,
    acronym: 'NFU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ngu.ac.jp',
    name: 'Nagoya Gakuin University',
    web_page: 'http://www.ngu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWEtB-TqfkhTpgK7XuAXUmubaclLY76EPjAI2yVW-E98AkxfWFRI734fg',
    id: 5087,
    acronym: 'NGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nias.ac.jp',
    name: 'Nagasaki Institute of Applied Science',
    web_page: 'http://www.nias.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmEeaOKZEsTK5qMGV84vJb-UzpgLXbftpx2tAXVjfN9sHTRu9Ti_dCNA',
    id: 5088,
    acronym: 'NIAS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nifs-k.ac.jp',
    name: 'National Institute of Fitness and Sports Kanoya',
    web_page: 'http://www.nifs-k.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKKwHC54zgIkZxiN-0gFvZ06cT70l_v_l7m4CqrVNmVFvHVJksqaTjx6Q',
    id: 5089,
    acronym: 'NIFSK',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nihon-u.ac.jp',
    name: 'Nihon University',
    web_page: 'http://www.nihon-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvw2WqglXX0eBtfg_Kmn8FUnshq-_a7WmUP1zB701feIm39-gmgYguhQ',
    id: 5090,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'niigata-pharm.ac.jp',
    name: 'Niigata College of Pharmacy',
    web_page: 'http://www.niigata-pharm.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaU4NGAQKPFpIO6Fk_Iu29JC1T0q15rG_J2ObvoeC8DghEa5kYBMkMQg',
    id: 5091,
    acronym: 'NCP',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'niigata-u.ac.jp',
    name: 'Niigata University',
    web_page: 'http://www.niigata-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlFhuklp7VZWEoLtnuKHlwdwhP4cp_Jqh8a93wgE05WKt1zDuR5EcA8osk',
    id: 5092,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'niigataum.ac.jp',
    name: 'Niigata University of Management',
    web_page: 'http://www.niigataum.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrnVJnWilLEEoe7fiW2Am4cv2njW8mXwraZ2vj5vU4mrvh328qdfCUkA',
    id: 5093,
    acronym: 'NUM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nishitech.ac.jp',
    name: 'Nishinippon Institute of Technology',
    web_page: 'http://www.nishitech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVFe09CXuXd_44nRiVp11H4Wr6fzfZe24wiIiRX0nx_2B8WvZLRyM1r4dAuw',
    id: 5094,
    acronym: 'NIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nishogakusha-u.ac.jp',
    name: 'Nisho Gakusha University',
    web_page: 'http://www.nishogakusha-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHC0hO0j7hxPOHrDpZCeDXUyyuJ5m94piDnfbF7_mxUB0huPEzPfVmQhw',
    id: 5095,
    acronym: 'NGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nisikyu-u.ac.jp',
    name: 'Nishikyushu University',
    web_page: 'http://www.nisikyu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5096,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nit.ac.jp',
    name: 'Nippon Institute of Technology',
    web_page: 'http://www.nit.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSv8evuDul2oolSwW2oe9V2evxhp0eLvqxmM_oQ6nb-BPyWoJIGf6Ethg',
    id: 5097,
    acronym: 'NIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nitech.ac.jp',
    name: 'Nagoya Institute of Technology',
    web_page: 'http://www.nitech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ25DaK_wI0TTo3W-ggbaNu-qfVhwiIi4pBxqRR4uMqMje0KQ8vXlahg',
    id: 5098,
    acronym: 'NIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nittai.ac.jp',
    name: 'Nippon Sport Science University',
    web_page: 'http://www.nittai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyCra6COZCY8BpTZNFga4MzELgseaX7NMBHr2sPcjADN04qLf4tvOE0g',
    id: 5099,
    acronym: 'NSSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nms.ac.jp',
    name: 'Nippon Medical School',
    web_page: 'http://www.nms.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVpl8-lLkLweexX5mWKiYV869-YIeaZXCjzx6fECObICp2_rlnJMTHuA',
    id: 5100,
    acronym: 'NMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nodai.ac.jp',
    name: 'Tokyo University of Agriculture',
    web_page: 'http://www.nodai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxzs9fW0xmrHuwjvR-Q1yqcG1MsSPzMMoVQN0ALukRa13ygbNMhU3EIg',
    id: 5101,
    acronym: 'TUA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'notredame.ac.jp',
    name: "Notre Dame Women's College",
    web_page: 'http://www.notredame.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-RLufCZDm0plONiRdrt3nL76nu2KC6_qCgkMeo6KwTPu8QNnuSyhemF-XEA',
    id: 5102,
    acronym: 'NDWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nsu.ac.jp',
    name: 'Niigata Sangyo University',
    web_page: 'http://www.nsu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLY1h3aavMHVbGe-8TzTnH_XHRL00tuD6d68IatMnkFPKx0EV4Xzhm9w',
    id: 5103,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ntu.ac.jp',
    name: 'Teikyo University of Science and Technology',
    web_page: 'http://www.ntu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmckPTETKIR-csEkESblgY5IHruJesahG3IqaUzTYFf4rb1Jvhk1A-Cw',
    id: 5104,
    acronym: 'TUST',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nua.ac.jp',
    name: 'Nagoya University of Arts',
    web_page: 'http://www.nua.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyU4AYy99xHtZy4yuJwbDHCYeh0JiXpebEZI4XbANBEKkp4lNvancZqLU',
    id: 5105,
    acronym: 'NUA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nucba.ac.jp',
    name: 'Nagoya University of Commerce and Business Administration',
    web_page: 'http://www.nucba.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVCA6PqH5oL-Y_8ulQTzobjmBbjXQHgHW3hfnhJVJ1hOt5B0AaoL52KQ',
    id: 5106,
    acronym: 'NUCBA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nufs.nakanishi.ac.jp',
    name: 'Nagoya University of Foreign Studies',
    web_page: 'http://www.nufs.nakanishi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOADLzmLvDplj3fB2F6iQXMVe43C9R1JD1-3kfDaMTamw7PTA-YNKrPr0',
    id: 5107,
    acronym: 'NUFS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nuis.ac.jp',
    name: 'Niigata University of International and Information Studies',
    web_page: 'http://www.nuis.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCWsUEIwQQ0wDTJBwl0WJb-L7ePjJ88uGRopaSNe1BvZPJMyK1xYCb-Q',
    id: 5108,
    acronym: 'NUIIS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'nvau.ac.jp',
    name: 'Nippon Veterinary and Animalscience University',
    web_page: 'http://www.nvau.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnYoOqYLF4RhcYlBOzKzbwicCj5TZOKkC5mPAYjrslRGmPblirodi4aIMl',
    id: 5109,
    acronym: 'NVAU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'obihiro.ac.jp',
    name: 'Obihiro University of Agriculture and Veterinary Medicine',
    web_page: 'http://www.obihiro.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIOLkXD1dXkOp5CXzVqDGknZIoTj85ce-8tX3twFgvpvm8aczwVji3QQ',
    id: 5110,
    acronym: 'OUAVM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'obirin.ac.jp',
    name: 'Obirin University',
    web_page: 'http://www.obirin.ac.jp/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQzmnd9UifAqwMLNqn2VSIzTz7XdlSdwgV1UtoxE3wlrpx_FFmC6ceU7Mo',
    id: 5111,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ocha.ac.jp',
    name: "Ochanomizu Women's University",
    web_page: 'http://www.ocha.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToZjBe6DFTeOI3BKXdVa1V9winHwe0y4tIwyMgMyk21gQwijjsuQo-hNXu',
    id: 5112,
    acronym: 'OWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ohkagakuen-u.ac.jp',
    name: 'Ohka Gakuen University',
    web_page: 'http://www.ohkagakuen-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5113,
    acronym: 'OGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ohtani-w.ac.jp',
    name: "Ohtani Women's University",
    web_page: 'http://www.ohtani-w.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNXtEuVgtG2jon_eBP7zsS9iWky56e6IuONP9iPPNZt3eX9zx73Bvg-Dg',
    id: 5114,
    acronym: 'OWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ohu-u.ac.jp',
    name: 'Ohu University',
    web_page: 'http://www.ohu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQl2YJ9y1tLM9R85Alh9p9OIv_lPXrE7gBS7_VgnUu9qC1MZm4OdZb5a_g',
    id: 5115,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'oit.ac.jp',
    name: 'Osaka Institute of Technology',
    web_page: 'http://www.oit.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn-1Z58TBcwmwBLYfxuY_nreZ9I-Sic1uU8uxduGgGvD7LNJPbnlkQ2w',
    id: 5116,
    acronym: 'OIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'oita-med.ac.jp',
    name: 'Oita Medical University',
    web_page: 'http://www.oita-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRny4r3N4YW4qxP_e41X0AgFuju-ZKLNciOS5lf22tbwgqUOCCXczqedhI',
    id: 5117,
    acronym: 'OMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'oita-nhs.ac.jp',
    name: 'Oita University of Nursing and Health Sciences',
    web_page: 'http://www.oita-nhs.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReyFIp8to1n4ph-TRurUSfvxGAquUoLzs-Hp5sF3rjAEX3XI-2vns0B_k',
    id: 5118,
    acronym: 'OUNHS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'oita-u.ac.jp',
    name: 'Oita University',
    web_page: 'http://www.oita-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReyFIp8to1n4ph-TRurUSfvxGAquUoLzs-Hp5sF3rjAEX3XI-2vns0B_k',
    id: 5119,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'oiu.oiu.ac.jp',
    name: 'Osaka International University',
    web_page: 'http://www.oiu.oiu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNJMLfkHBkFKVa4nAxN3pcwxItO205FWQOMq-dRuoAiYyrnYri88_JPA',
    id: 5120,
    acronym: 'OIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'oiuw.oiu.ac.jp',
    name: 'Osaka International University for Women',
    web_page: 'http://www.oiuw.oiu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNJMLfkHBkFKVa4nAxN3pcwxItO205FWQOMq-dRuoAiYyrnYri88_JPA',
    id: 5121,
    acronym: 'OIUFW',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'oka-pu.ac.jp',
    name: 'Okayama Prefectural University',
    web_page: 'http://www.oka-pu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBVRLv-t7JNAkeP48uWdEbIGaaW2lgyu9KwN-lyotBl8HTi3e_EpQbnXU',
    id: 5122,
    acronym: 'OPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'okayama-u.ac.jp',
    name: 'Okayama University',
    web_page: 'http://www.okayama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3vt9irEBSAJPO09hryTkNTO-ljZ6mp_yf26-p2xo4Acqd70NMQ9O7aQ',
    id: 5123,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'okigei.ac.jp',
    name: 'Okinawa Prefectural University of Fine Arts',
    web_page: 'http://www.okigei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdEJpx4QPjPjpXk57s4tinrJ6pp82qeKNvQGOguJ4KqCMJ-TIP3gPKFm4',
    id: 5124,
    acronym: 'OPUFA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'okinawa-u.ac.jp',
    name: 'Okinawa University',
    web_page: 'http://www.okinawa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi-HtmTeGTIoeYVEYQIJhmTwo3cnuWxw8AjfQOvlK8w-trijdCChgDLKs',
    id: 5125,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'okiu.ac.jp',
    name: 'Okinawa International University',
    web_page: 'http://www.okiu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7x3I22kF5KAM32BTWU5JZBSRwIElhZct7hHKD0WaXwTZ1mHDbWVjEOw',
    id: 5126,
    acronym: 'OIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osakac.ac.jp',
    name: 'Osaka Electro-Communication University',
    web_page: 'http://www.osakac.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2WoVqBm-AdY6qxhrCxhg4eqWKunnOzPtpuUsdexkZO2yMfu_mjzhwGq0',
    id: 5127,
    acronym: 'OEU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-cu.ac.jp',
    name: 'Osaka City University',
    web_page: 'http://www.osaka-cu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUHtZhujG0ONg1O-MS5cUpah_Kw2D1RJekS5I9seFaoyD-N3cMnAa-1w',
    id: 5128,
    acronym: 'OCU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-dent.ac.jp',
    name: 'Osaka Dental University',
    web_page: 'http://www.osaka-dent.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScRK_LS3a7Q79oYEEBJrnkm0kk5Lok50x73KlX8ZynmI6sdV0pWlN7JXNt',
    id: 5129,
    acronym: 'ODU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osakafu-u.ac.jp',
    name: 'Osaka Prefectural University',
    web_page: 'http://www.osakafu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYvg-MRu4VG1F_dKtQTaqIhn_Zpv8ofTAX0PsT8cZUY5Idg5MvNnohmMA',
    id: 5130,
    acronym: 'OPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-gaidai.ac.jp',
    name: 'Osaka University of Foreign Studies',
    web_page: 'http://www.osaka-gaidai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgbb8QwWhVHvTbo6Jd4TzMzkWPbKDnVlE9rSs1Af2T-ngE8_KzVTumYXs',
    id: 5131,
    acronym: 'OUFS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-geidai.ac.jp',
    name: 'Osaka University of Arts',
    web_page: 'http://www.osaka-geidai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjk7hvloQWpWDVXC4TBgRL_FDhI_8GlL4mDziXYfJRCbR4uw_ytXZT9uw',
    id: 5132,
    acronym: 'OUA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-gu.ac.jp',
    name: 'Osaka Gakuin University',
    web_page: 'http://www.osaka-gu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSnDKxUwpofktqewxXFbA-CRDngycw_UXBvF7dHDy4xWsUMo68SBqQWbwJ',
    id: 5133,
    acronym: 'OGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-kyoiku.ac.jp',
    name: 'Osaka University of Education',
    web_page: 'http://www.osaka-kyoiku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTigtRy9P8LvXfzX3SFSdkLCSvQGiXHsCjf-JHuXfO0byn6fm9QrAkPQZfhFQ',
    id: 5134,
    acronym: 'OUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-med.ac.jp',
    name: 'Osaka Medical College',
    web_page: 'http://www.osaka-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUB2DM-LlfI7N78FYi9EcGF0FOwQmk8ev7Mwm_4vq5DIfmda9wKWjrhg',
    id: 5135,
    acronym: 'OMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-sandai.ac.jp',
    name: 'Osaka Sangyo University',
    web_page: 'http://www.osaka-sandai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSabN_jV9bjdd3EaFJZeMWi-2iwGovztQVjW8a6KhKpkCOAmt63RxylSQ',
    id: 5136,
    acronym: 'OSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-shoin.ac.jp',
    name: "Osaka Shoin Women's College",
    web_page: 'http://www.osaka-shoin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAFrTjt34TRTR_NFyGhQhZf0syrYu7ue90CJdvM7sk-NWDCD1UONHdEs4',
    id: 5137,
    acronym: 'OSWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-u.ac.jp',
    name: 'Osaka University',
    web_page: 'http://www.osaka-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXSAC5Pr-imstEBi8yz4WL04hl1zzP5pY2d0zW63NvHLEtliJCcHcubk8',
    id: 5138,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-ue.ac.jp',
    name: 'Osaka University of Economics',
    web_page: 'http://www.osaka-ue.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXSAC5Pr-imstEBi8yz4WL04hl1zzP5pY2d0zW63NvHLEtliJCcHcubk8',
    id: 5139,
    acronym: 'OUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osaka-wu.ac.jp',
    name: "Osaka Women's University",
    web_page: 'http://www.osaka-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUkpvINdGVoq2LkZOzeBKcfQpU8Fi-7wD_zX1Ge1_bYBMKl4CP22bwgA',
    id: 5140,
    acronym: 'OWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'osu.ac.jp',
    name: 'Okayama Shoka University',
    web_page: 'http://www.osu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbBwu10gwY8qdwpT5rK2AE1RpxIxNqA_F6ILaMfIjChkH3g5Z99C4HQTM',
    id: 5141,
    acronym: 'OSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'otani.ac.jp',
    name: 'Otani University',
    web_page: 'http://www.otani.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7FgRbFOr6r3BwifvU5hM0MugJc8EpUFq-O6VsGYKfwMAHlyQogeNlG1g',
    id: 5142,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'otaru-uc.ac.jp',
    name: 'Otaru University of Commerce',
    web_page: 'http://www.otaru-uc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM5HlLGuTpwQ0tDkH8QcwBdeFZhiAdAuPVAUcEwVbjsjlQgc-MQySqZg',
    id: 5143,
    acronym: 'OUC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'otemae.ac.jp',
    name: 'Otemae University',
    web_page: 'http://www.otemae.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuv7tebOATylgFWkOk7iI7F3uQBUcBEgqKk_8_dYbdi9HyH37uU3E3',
    id: 5144,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'otemon.ac.jp',
    name: 'Otemon Gakuin University',
    web_page: 'http://www.otemon.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn9bi1aisg2UKI9mlR_Xb5jcUoIq6IDI0K0fe837HgwTBNva9B5X5AcA',
    id: 5145,
    acronym: 'OGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'otsuma.ac.jp',
    name: "Otsuma Women's University",
    web_page: 'http://www.otsuma.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5aDHdRzIWf2m1lCNJJGYTj8UA4mwpqG1yPMzgL7t8664606o5qACOlCE',
    id: 5146,
    acronym: 'OWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ouhs.ac.jp',
    name: 'Osaka University of Health and Sport Sciences',
    web_page: 'http://www.ouhs.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgbb8QwWhVHvTbo6Jd4TzMzkWPbKDnVlE9rSs1Af2T-ngE8_KzVTumYXs',
    id: 5147,
    acronym: 'OUHSS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'oups.ac.jp',
    name: 'Osaka University of Pharmaceutical Sciences',
    web_page: 'http://www.oups.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgbb8QwWhVHvTbo6Jd4TzMzkWPbKDnVlE9rSs1Af2T-ngE8_KzVTumYXs',
    id: 5148,
    acronym: 'OUPS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ous.ac.jp',
    name: 'Okayama University of Science',
    web_page: 'http://www.ous.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI_v8fHViF_6lUdiG8qBvx2vCWTUqIN8xUl12kNIeu-149yKgiiSVuQQ',
    id: 5149,
    acronym: 'OUS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'poole.ac.jp',
    name: 'Poole Gakuin University',
    web_page: 'http://www.poole.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnL92dPYqbODOs0dz0FEuCJ2CxOThFhV7lbnJvBsIMT287WGNDO6Z6t2Q',
    id: 5150,
    acronym: 'PGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'pu-kumamoto.ac.jp',
    name: 'Kumamoto Prefectural University',
    web_page: 'http://www.pu-kumamoto.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBuUQWiiy9SzY3FbtEaDWAHTUMAu9mm_Xy-AiITqjLxgdrMPOdQ0GyTw',
    id: 5151,
    acronym: 'KPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'pu-toyama.ac.jp',
    name: 'Toyama Prefectural University',
    web_page: 'http://www.pu-toyama.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVg41VvngxlN-x8v5wPJxk3ZlSv9IjLYxLcuzVnXsD_H_lnXWrf3gGzXM',
    id: 5152,
    acronym: 'TPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'rakuno.ac.jp',
    name: 'Rakuno Gakuen University',
    web_page: 'http://www.rakuno.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5153,
    acronym: 'RGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'redcross.ac.jp',
    name: 'Japanese Red Cross College of Nursing',
    web_page: 'http://www.redcross.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREIQ2BV0GVW-bl9A-sMI726hclwAByATRtzWxp2nXwrYxIuBTf67sSMgo',
    id: 5154,
    acronym: 'JRCCN',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'reitaku-u.ac.jp',
    name: 'Reitaku University',
    web_page: 'http://www.reitaku-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3FZvi5-9jLWz5lWNuL1g_EHz1V4pzvKQuNS8OSLvpXksV3oDIZs9FjA',
    id: 5155,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'rikkyo.ac.jp',
    name: "Rikkyo University (St. Paul's University)",
    web_page: 'http://www.rikkyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWKC9e_zWjlzXLXnDB3-3K3vN9WW5f8cTw51CatAxuvcAJ6SxrTdYbc8HE',
    id: 5156,
    acronym: 'RU(PU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ris.ac.jp',
    name: 'Rissho University',
    web_page: 'http://www.ris.ac.jp/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSdaxY8OIgvfQe4TtA3FFIN-X-CW0223UCUih8NHG9as6CGPbvbuMiYujU',
    id: 5157,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ritsumei.ac.jp',
    name: 'Ritsumeikan University',
    web_page: 'http://www.ritsumei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDvsoKBSCgeGLxI8FVnbwbwNGnQdCEqGlyGkOIAFsDNPDMvEulkzcmmjhi',
    id: 5158,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'rku.ac.jp',
    name: 'Ryutsu Keizai University',
    web_page: 'http://www.rku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLESyKmRdHqfnAFtefXqQvTE6rxl0kOrALBjy9i3BNsEvE_QgaLDwqQ9s',
    id: 5159,
    acronym: 'RKU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ryukoku.ac.jp',
    name: 'Ryukoku University',
    web_page: 'http://www.ryukoku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn6CI6YmRCGsN-wqwUhb65-3Z6-FMARfRrTCLhFonJptmUvjOZLMgfDbg',
    id: 5160,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'saga-med.ac.jp',
    name: 'Saga Medical School',
    web_page: 'http://www.saga-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCaTPFTF7rS4unVkMb23koaiHVjhkAp7_Y8gsXD1dC98EiflNvrhwHkw',
    id: 5161,
    acronym: 'SMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sagami-wu.ac.jp',
    name: "Sagami Women's University",
    web_page: 'http://www.sagami-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn_0g_sbvtCG2JwBZQLS3jJYNDcFF66eP98rBS7Nyi8ePVrih1L1f0pAM',
    id: 5162,
    acronym: 'SWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'saga-u.ac.jp',
    name: 'Saga University',
    web_page: 'http://www.saga-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUZqodme8hdEQSdPp1EzvUFU1t178CZX9684W62otiBDrQZeLpMRyC46tR',
    id: 5163,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'saitama-med.ac.jp',
    name: 'Saitama Medical School',
    web_page: 'http://www.saitama-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_zvTHXEeAIqa4FBm31rwxRz9zwQF6rNutLRgZqtdTIbqxOIawB9OY0A',
    id: 5164,
    acronym: 'SMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'saitama-u.ac.jp',
    name: 'Saitama University',
    web_page: 'http://www.saitama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpgjbLHXrLb7bguy8870lOr4nMQJI9lU6GpmK4HbKVnCVOhTHXZG5kXmg',
    id: 5165,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sakushin-u.ac.jp',
    name: 'Sakushin Gakuin University',
    web_page: 'http://www.sakushin-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKxklTEpI4jSTMJbBNgazOHRO6Xi3icmwRD47kdrOh9YxNDpOkdyi8Vg',
    id: 5166,
    acronym: 'SGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sanno.ac.jp',
    name: 'Sanno University',
    web_page: 'http://www.sanno.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg-Os3aOw6H3AkUQmerFMLG0A5KDQc6sMj-GT-ybq05nyeP_HxtG4OFGM',
    id: 5167,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sapmed.ac.jp',
    name: 'Sapporo Medical University',
    web_page: 'http://www.sapmed.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyATgZYTwHQAPpAu_j7lozzIPf0TJw2gLEl9vqNjVSm2LHOoM-zy2aWg',
    id: 5168,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sapporo-u.ac.jp',
    name: 'Sapporo University',
    web_page: 'http://www.sapporo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyATgZYTwHQAPpAu_j7lozzIPf0TJw2gLEl9vqNjVSm2LHOoM-zy2aWg',
    id: 5169,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'seian.ac.jp',
    name: 'Seian University of Art & Design',
    web_page: 'http://www.seian.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAETELEBB-7z3aBgRvYXM_M-y_DAcEuaIF-tXz9Ax15RzPfls9Ja3OqXI',
    id: 5170,
    acronym: 'SUA&D',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'seigakuin-univ.ac.jp',
    name: 'Seigakuin University',
    web_page: 'http://www.seigakuin-univ.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5171,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'seijo.ac.jp',
    name: 'Seijo University',
    web_page: 'http://www.seijo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl7bdxLzeCfIIGcW1M49jCn87jkaAN6oSvJHYk9kTf0R8kafOO1di5nQg',
    id: 5172,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'seikei.ac.jp',
    name: 'Seikei University',
    web_page: 'http://www.seikei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB0Vz6gS1yhiZmbTfkS1URx88qpmh6tq_Aq3eunAtHM3rmVsD6eCdXkHiF',
    id: 5173,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'seinan-gu.ac.jp',
    name: 'Seinan Gakuin University',
    web_page: 'http://www.seinan-gu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKxklTEpI4jSTMJbBNgazOHRO6Xi3icmwRD47kdrOh9YxNDpOkdyi8Vg',
    id: 5174,
    acronym: 'SGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'seisen-u.ac.jp',
    name: 'Seisen University',
    web_page: 'http://www.seisen-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAa1yuTFVq5b6mKxjYoHzm-bbzNFMuNWUu6_YA1WqTu3nPphcrWLC8RQ',
    id: 5175,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'seiwa-u.ac.jp',
    name: 'Seiwa College',
    web_page: 'http://www.seiwa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5176,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sendaidaigaku.jp',
    name: 'Sendai University',
    web_page: 'http://www.sendaidaigaku.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtzatJ6jYGtercz8blQIGT6vGH798yL-9gEDE9gVgY0TCUZwYVP-oYIEOT',
    id: 5177,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'senshu-u.ac.jp',
    name: 'Senshu University',
    web_page: 'http://www.senshu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP_-yZKb7iIXHk3tOZhnsAm5Fc68UBAlNlJONvJbeJLbjvaSV3Nxkd',
    id: 5178,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'senzoku.ac.jp',
    name: 'Senzoku Gakuen College',
    web_page: 'http://www.senzoku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUAWywI3Tg7AlEfOGSG4E-Oq31M4vIrKwX69__kR8tB1p74iqZERceKvWy',
    id: 5179,
    acronym: 'SGC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'setsunan.ac.jp',
    name: 'Setsunan University',
    web_page: 'http://www.setsunan.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq1NEyf6xYREMUMHot6sEBwcXRWlwd1OvtLwXvUqK14giINw0VbnR1HTc',
    id: 5180,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sgu.ac.jp',
    name: 'Sapporo Gakuin University',
    web_page: 'http://www.sgu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSySP2imTi7ueDh9oxV8hRBUP1nXCgHfk26bMModLjdfTd3YUaEPcRbu_s',
    id: 5181,
    acronym: 'SGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sg-u.ac.jp',
    name: 'Shikoku Christian College',
    web_page: 'http://www.sg-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtn_LI7L5nxn3ggyrnYlbFM0VYWTQl7e4sSghLgerGdE0eyRafI2nIqFc',
    id: 5182,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sguc.ac.jp',
    name: 'Sanyo Gakuen University',
    web_page: 'http://www.sguc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 5183,
    acronym: 'SGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shakaijigyo.ac.jp',
    name: 'Japan College of Social Work',
    web_page: 'http://www.shakaijigyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlWdHAQ7vCuHXWxaHNvhRe1Ho0PyqoGQ60iLULZd770ZB8GHQmVIydvw',
    id: 5184,
    acronym: 'JCSW',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shibaura-it.ac.jp',
    name: 'Shibaura Institute of Technology',
    web_page: 'http://www.shibaura-it.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLjBl1HH6b0CjS-Hd87w1umPAWpImqJIsEDxqGJY5QKaPyCQzEn1hYjxg',
    id: 5185,
    acronym: 'SIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shiga-med.ac.jp',
    name: 'Shiga University of Medical Science',
    web_page: 'http://www.shiga-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdQTvzlGdWZwRH3Vzlo07NtBuqFQIssGctE_szFGJHJqQHhBCwHvQQTA',
    id: 5186,
    acronym: 'SUMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shiga-u.ac.jp',
    name: 'Shiga University',
    web_page: 'http://www.shiga-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBMOJR-E9I5Hvxy-UfffQl5KjRvGR4uvoS8SnacooHsn-Jd5pM8uZFxzzP',
    id: 5187,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shikoku-u.ac.jp',
    name: 'Shikoku University',
    web_page: 'http://www.shikoku-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMnI2sPp3QoUaAraZA8jyoDKnUvHLS5HPYSXeeYAOq47zS1XQ0CwJj91I',
    id: 5188,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shimane-med.ac.jp',
    name: 'Shimane University Faculty of Medicine',
    web_page: 'http://www.shimane-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQs_NnGPVHzFnVF_6QMefmocHh9lQhwADwqYAH2R9n2a-7XS5FcDfnU9w',
    id: 5189,
    acronym: 'SUFM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shimane-u.ac.jp',
    name: 'Shimane University',
    web_page: 'http://www.shimane-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 5190,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shimonoseki-cu.ac.jp',
    name: 'Shimonoseki City University',
    web_page: 'http://www.shimonoseki-cu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjfxf1TLVn9Cw8VtOctpEEGnbIebV5LbSZkELgFwySH0nARdUQ5YnMGg',
    id: 5191,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shinshu-u.ac.jp',
    name: 'Shinshu University',
    web_page: 'http://www.shinshu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN95COWCKTF6ymQhb3tcwmXJX5YgQTo9A14FbCX8RvrimHjGSjuNoimA',
    id: 5192,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shirayuri.ac.jp',
    name: "Shirayuri Women's College",
    web_page: 'http://www.shirayuri.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo77KzxlVUCUlO_I3_i1WPxOza7L5F2pnlnXP0uWkW7TjV0Pvoa69tOeI',
    id: 5193,
    acronym: 'SWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shitennoji.ac.jp',
    name: 'International Buddhist University',
    web_page: 'http://www.shitennoji.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-5VheTGRd4WIt2wu-ZMKF9euBOQq620rxBCVoBgoAdTzuYsjBb8eHmKo',
    id: 5194,
    acronym: 'IBU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shizuoka.ac.jp',
    name: 'Shizuoka University',
    web_page: 'http://www.shizuoka.ac.jp/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTuDCME-DNwzB1kERvsiqbgBerV3lNb6iTcQgYE9RVuqqk9nWitXWUMZA',
    id: 5195,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shodai.ac.jp',
    name: 'Yokohama College of Commerce',
    web_page: 'http://www.shodai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLmez6qQ0rgIzAWt3JX7xoBJdlu38-9zGVLYGkue73XokVCW98hbHe8MY',
    id: 5196,
    acronym: 'YCC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shoin.ac.jp',
    name: "Kobe Shoin Women's University",
    web_page: 'http://www.shoin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN3UoTqcawPhnmVV_-RbcvQFPWmoNP6klHta-VWiP2zq2AshbGltKx-w',
    id: 5197,
    acronym: 'KSWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shokei-gakuen.ac.jp',
    name: 'Shokei College',
    web_page: 'http://www.shokei-gakuen.ac.jp/shokeicol/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThzYEqiTmC6j0QgqwaP3KPfdHcsqOH3i3wO0YPF8epsXWltNuWYPLi6f8',
    id: 5198,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shonan-it.ac.jp',
    name: 'Shonan Institute of Technology',
    web_page: 'http://www.shonan-it.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGaNJ0KnbpicnpaFcSH_PHvwqvzWgzGutxWtDvfSP6bYiLmDb3jCXrYWv6',
    id: 5199,
    acronym: 'SIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shotoku.ac.jp',
    name: 'Gifu Shotoku Gakuen University',
    web_page: 'http://www.shotoku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyf4l1yuQ7SS9ECKIK7CCPMyi9r3vil8I25v996Ieo-dticFzZqqW8Lx8',
    id: 5200,
    acronym: 'GSGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'showa-u.ac.jp',
    name: 'Showa University',
    web_page: 'http://www.showa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToDNYvP_BQf2Pde7SBcgUgzPSE0F73ya-JEgcMCxbC8HLN8xJfkugGAfA',
    id: 5201,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shoyaku.ac.jp',
    name: 'Showa College of Pharmaceutical Sciences',
    web_page: 'http://www.shoyaku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZARd_QOckhMKJWSgFYv0DJ6_yBRSsE7g96K-JzJ35K2dWjNr0gTuYbA',
    id: 5202,
    acronym: 'SCPS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shuchiin.ac.jp',
    name: 'Shuchiin College',
    web_page: 'http://www.shuchiin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5203,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shudo-u.ac.jp',
    name: 'Hiroshima Shudo University',
    web_page: 'http://www.shudo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy31csh9zVF8_jDWQsFuHCOLXw3D_ANbxqHC9SJ67pr6N_3R9em3ZIIMA',
    id: 5204,
    acronym: 'HSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shujitsu.ac.jp',
    name: "Shujitsu Women's University",
    web_page: 'http://www.shujitsu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGrTDSJa8_UU7bXIDQQwPWJmGbr6rWKHbIYTNKyFMKsg7LM8U42BtVWbw',
    id: 5205,
    acronym: 'SWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shukutoku.ac.jp',
    name: 'Shukutoku University',
    web_page: 'http://www.shukutoku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQls8t_QC0xpJ7xEWJDq7ssOfkUF5c5IKnbraOdjLwiICIOwaOh1_MGTw',
    id: 5206,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'shumei-u.ac.jp',
    name: 'Shumei University',
    web_page: 'http://www.shumei-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF_yfCpFyeg1kRDHqhOLnVcdZ9wzI3pRUF-EcTGWgG3yq6T482xib7RWOB',
    id: 5207,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sit.ac.jp',
    name: 'Saitama Institute of Technology',
    web_page: 'http://www.sit.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5208,
    acronym: 'SIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'siu.ac.jp',
    name: 'Sapporo International University',
    web_page: 'http://www.siu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-854upXC-FrWqpxI6shG91QQlGy34ImPufo99FjmvqHt8c7bd7gfnRw',
    id: 5209,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'slcn.ac.jp',
    name: "St. Luke' s College of Nursing",
    web_page: 'http://www.slcn.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBY1VVT08YVo_EGvRQuuyZhnl2ZPbYZMltgXkvIPFa5Z_wfitRabwDew8',
    id: 5210,
    acronym: 'SLSCN',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'soai.ac.jp',
    name: 'Soai University',
    web_page: 'http://www.soai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTkYtCm0z71igUNAVwCjUO055KGq38JOXYzswv4wLwMapdBDTkDiAQdpo',
    id: 5211,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'soka.ac.jp',
    name: 'Soka University',
    web_page: 'http://www.soka.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqovTn7t1f34ETe7UqpOkt1D2WlUgVbHQ9K4ZGqRSiubbhhB8SjXnyHx2R',
    id: 5212,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'soken.ac.jp',
    name: 'Graduate University for Advanced Studies',
    web_page: 'http://www.soken.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZ0a_5M1gj8e_ADmJJsyGNvEx222V7CQ8CUmlwJpuGAdN5go5zqURjSm4',
    id: 5213,
    acronym: 'GUFAS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sonoda-u.ac.jp',
    name: "Sonoda Women's University",
    web_page: 'http://www.sonoda-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRQnzWo-HGRfMkHjTru8woCPObG4Kpdi0H2cGk8KeXrKcxYh6JWlUUFOeP',
    id: 5214,
    acronym: 'SWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sophia.ac.jp',
    name: 'Sophia University',
    web_page: 'http://www.sophia.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOqhKaPBsDwAYPuxuAgSkHeFCPDXSv6ll9LmG2lN3v4aCdMOXBdDiLPp8',
    id: 5215,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'spu.ac.jp',
    name: 'Saitama Prefectural University',
    web_page: 'http://www.spu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQqgygMMx4hqUt3UiVyX9RyCJY5khQc-2w0_YR8acWBSlkVwYfIa9ckhc',
    id: 5216,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ssu.ac.jp',
    name: 'Shizuoka Sangyo University',
    web_page: 'http://www.ssu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9Q0Qvid6IF9cACs1kxTdvb7cbDU0UtBQsKwsupAtzK3jeDzG7LOjF0g',
    id: 5217,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sugino.ac.jp',
    name: "Sugino Women's College",
    web_page: 'http://www.sugino.ac.jp/daiindex.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwoydH7WeRKjTtwZzft5lCiTs1-oPAgUMd-KGMUj9UE-ocFww-L9PkPQ4',
    id: 5218,
    acronym: 'SWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sugiyama-u.ac.jp',
    name: 'Sugiyama Jogakuen University',
    web_page: 'http://www.sugiyama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzrsLVUZcClsOsIyjatBShvMtuAC2yp9b5SQR4tkdhGheCTQ8N3Ky0aM4',
    id: 5219,
    acronym: 'SJU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'suma.kobe-wu.ac.jp',
    name: "Kobe Women's University",
    web_page: 'http://www.suma.kobe-wu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLKZ2WD5oHDYbSaUA2gL0vprIn-_557RhpdY5dl0Wy8ooyyUkQ9EJW7g',
    id: 5220,
    acronym: 'KWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sun.ac.jp',
    name: 'Siebold University of Nagasaki',
    web_page: 'http://www.sun.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 5221,
    acronym: 'SUN',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'surugadai.ac.jp',
    name: 'Surugadai University',
    web_page: 'http://www.surugadai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD4Bp3_ppSpr38IrF3qN8XZs6ElQ8XbD2QIedcGz4YIFjorW3Vti9Scnw',
    id: 5222,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'sut.ac.jp',
    name: 'Science University of Tokyo',
    web_page: 'http://www.sut.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRj2lDwPWCLe9WVvyANitDIBENNjrIRp-QTrwHxlPo2z076EWMDGTSEew',
    id: 5223,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'suzuka-iu.ac.jp',
    name: 'Suzuka International University',
    web_page: 'http://www.suzuka-iu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9jE5fUFU4_pqobLdR8FpG5OiepZAGP6-UDD89HL-L2PXam8zuh7kMLSE',
    id: 5224,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'suzuka-u.ac.jp',
    name: 'Suzuka University of Medical Science',
    web_page: 'http://www.suzuka-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWqa4Cdyd45r56fpgc1VVZgWuXPdB8q9vhzQzKbZ-5IXJrkwf2Yg1SWw',
    id: 5225,
    acronym: 'SUMS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'swu.ac.jp',
    name: "Showa Women's University",
    web_page: 'http://www.swu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY5LQrYNLs3VV-YbHfNl3RmaOlYWTHtfiWE6rqBvBTlPAZbFA6hWNNEn8',
    id: 5226,
    acronym: 'SWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tachibana-u.ac.jp',
    name: "Kyoto Tachibana Women's University",
    web_page: 'http://www.tachibana-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc-Iq7e1izTM8oqx46t2dYWXVtH25ENn-RgXayhCWBq0X4tzRpoXt7DPs',
    id: 5227,
    acronym: 'KTWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tais.ac.jp',
    name: 'Taisho University',
    web_page: 'http://www.tais.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8cZ_NdgPl60nJIm0Q5CTyA8qQok_3BT41UzGml83RrtONtUsXyz8ByQ',
    id: 5228,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'takachiho.ac.jp',
    name: 'Takachiho University',
    web_page: 'http://www.takachiho.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQWxjEn-aUoJXqLvjyXszccl42bnUtN0JEMLhEyOUwGQhYqrZy2UYPpA',
    id: 5229,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'takamatsu-u.ac.jp',
    name: 'Takamatsu University',
    web_page: 'http://www.takamatsu-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlTespUBsdPRvCj4xXkqwFhO590AP1Of6sDNqAX8xh3l8gFwn5DZ-TCQ',
    id: 5230,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'takara-univ.ac.jp',
    name: 'Takarazuka University of Art and Design',
    web_page: 'http://www.takara-univ.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAETELEBB-7z3aBgRvYXM_M-y_DAcEuaIF-tXz9Ax15RzPfls9Ja3OqXI',
    id: 5231,
    acronym: 'TUAD',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'takushoku-u.ac.jp',
    name: 'Takushoku University',
    web_page: 'http://www.takushoku-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrGXK11__IQee0IBBA_maL2symKXc5cg_7RHx_hTxHsxpg2Gdq5kZV2Q',
    id: 5232,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tamabi.ac.jp',
    name: 'Tama Art University',
    web_page: 'http://www.tamabi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0RW0L8a8OXWqzUll3QwerjIc7DU1Z6dM4usM1bPS0WDZGNk4PhBDguA',
    id: 5233,
    acronym: 'TAU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tamagawa.ac.jp',
    name: 'Tamagawa University',
    web_page: 'http://www.tamagawa.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5234,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tbgu.ac.jp',
    name: 'Tohoku Bunka Gakuen University',
    web_page: 'http://www.tbgu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUrgGNK6P-XqjpRMcORmvFjMFDhSPkCIDZFe5dbeczfojDf3jKX1m4PQ',
    id: 5235,
    acronym: 'TBGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tcue.ac.jp',
    name: 'Takasaki City University of Economics',
    web_page: 'http://www.tcue.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnkGVusfml_5_EtNfbX2LWfU1EYaLic-oCjn2F3TINv-oINNo1Ix9rVmo',
    id: 5236,
    acronym: 'TCUE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tdc.ac.jp',
    name: 'Tokyo Dental College',
    web_page: 'http://www.tdc.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxP98TgqgcGm7E8zMUL640vpy4grle5b-RHZyPk6XG4hBgMeZxSGawKec',
    id: 5237,
    acronym: 'TDC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'teikyo-u.ac.jp',
    name: 'Teikyo Heisei University',
    web_page: 'http://www.teikyo-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTuS3tibbjlkzpVeboldU11mDvSybgb5kgu1HLH3ep1-ZlUmCGczIAql8A',
    id: 5238,
    acronym: 'THU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tenri-u.ac.jp',
    name: 'Tenri University',
    web_page: 'http://www.tenri-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0ihGkfU3hZZU1Q3lx6Bxr_-A6r9acMoqN61eKmrRqCTeRtNI_HaUtB3I',
    id: 5239,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'termnet.co.jp',
    name: 'Fuji University',
    web_page: 'http://www.termnet.co.jp/fujidai/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdhVMAbEP5ojqwqlg6zX4LUgR2WxEL-LRyXslSQyCUEUFgNE8wL8qjWw',
    id: 5240,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'teu.ac.jp',
    name: 'Tokyo Engineering University',
    web_page: 'http://www.teu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWxbrTFHKuqT_6rsQ5f0pmQsAxNidinffb7j4x78rKkTPcO5YrkeKYrA',
    id: 5241,
    acronym: 'TEU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tezuka-gu.ac.jp',
    name: 'Tezukayama Gakuin University',
    web_page: 'http://www.tezuka-gu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5242,
    acronym: 'TGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tezukayama-u.ac.jp',
    name: 'Tezukayama University',
    web_page: 'http://www.tezukayama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyf4l1yuQ7SS9ECKIK7CCPMyi9r3vil8I25v996Ieo-dticFzZqqW8Lx8',
    id: 5243,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tfu.ac.jp',
    name: 'Tohoku Fukushi University',
    web_page: 'http://www.tfu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8VHnf4HqlV9vQ3uRZlqXDiqhIhxu_WsQgMVOkeUOlfdZPusV3xvIPkKQ',
    id: 5244,
    acronym: 'TFU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'timis.ac.jp',
    name: 'Tama Institute of Management and Information Sciences',
    web_page: 'http://www.timis.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5245,
    acronym: 'TIMIS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'titech.ac.jp',
    name: 'Tokyo Institute of Technology',
    web_page: 'http://www.titech.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQiEQTLHEtsja0ooPBubKKUT57BLJyyA8nLDGT6Aajc4lFJqD54-y_otc9',
    id: 5246,
    acronym: 'TIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tiu.ac.jp',
    name: 'Tokyo International University',
    web_page: 'http://www.tiu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmf9VdfEATSYsV_U28lTSDMygIO_8CE4FWYtPdDFLcviv7p_cwmxsc6g',
    id: 5247,
    acronym: 'TIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 't-komazawa.ac.jp',
    name: 'Tomakomai Komazawa University',
    web_page: 'http://www.t-komazawa.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT90XUQGdzKE36mgBIptUg4v7Ajs9KgQaAoGRfKm4XaDnIvFGiQ6Un8NA',
    id: 5248,
    acronym: 'TKU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 't-kougei.ac.jp',
    name: 'Tokyo Institute of Polytechnics',
    web_page: 'http://www.t-kougei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqKKNigoUHHgDpccuJia05Sj-kXgtkkwoAKFHGNRpMC8nU8bEF2UpMdzIQ',
    id: 5249,
    acronym: 'TIP',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tku.ac.jp',
    name: 'Tokyo Keizai University',
    web_page: 'http://www.tku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR9jJTACoTI_6hKo7T1irz4rCIxwexwNzkAm2K4o7qbRGbijbd87fs5w',
    id: 5250,
    acronym: 'TKU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tmd.ac.jp',
    name: 'Tokyo Medical and Dental University',
    web_page: 'http://www.tmd.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRClAPl6Ss6rtjX8hYR8fTwMXZlnhqLxoOJAdMyyeMLhPUjeUxW2_3uHnc',
    id: 5251,
    acronym: 'TMDU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tmit.ac.jp',
    name: 'Tokyo Metropolitan Institute of Technology',
    web_page: 'http://www.tmit.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-4bmYUh2I5nSBVgWHZQ6x__tTcKhv7TTW35jEnVAptffd3lbh-E-hnZc',
    id: 5252,
    acronym: 'TMIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tohoku.ac.jp',
    name: 'Tohoku University',
    web_page: 'http://www.tohoku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQGbw8hXvD3jcbIvFSxewtmv0J7SJv6Emd5KLMkHC9SOPsXP5oyDxdZOQ',
    id: 5253,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tohoku-gakuin.ac.jp',
    name: 'Tohoku Gakuin University',
    web_page: 'http://www.tohoku-gakuin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqxXZOpDbKWx4ABI4nwdu5IfdyGanF1UMF7oP1HI7GL8jHITrnDMfhXw',
    id: 5254,
    acronym: 'TGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tohoku-pharm.ac.jp',
    name: 'Tohoku College of Pharmacy',
    web_page: 'http://www.tohoku-pharm.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3VBzpCRHX4617xBn78qoF7OoK0YPXaMWpPz7SLYBb-dzWQpf5EVbi2vLF',
    id: 5255,
    acronym: 'TCP',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toho-music.ac.jp',
    name: 'Toho College of Music',
    web_page: 'http://www.toho-music.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjK84rbIsOlQoNGugWjfS7QmvE_Ds-crvEkN5PhgkC30A1ETJv5PiPnA',
    id: 5256,
    acronym: 'TCM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tohomusic.ac.jp',
    name: 'Toho Gakuen School of Music',
    web_page: 'http://www.tohomusic.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6KZcWRbE27Kxr8i4N7xH7BCmnaRRt-ppdvL1euL8oqxMnp0pHhN_MKSY',
    id: 5257,
    acronym: 'TGSM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toho-u.ac.jp',
    name: 'Toho University',
    web_page: 'http://www.toho-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_XqgFskPPwg-HHZ1DTGH4he19YQU0TSS-0v5e9t6fCOlCWpuTN7-3n_-T',
    id: 5258,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tohtech.ac.j',
    name: 'Tohoku Institute of Technology',
    web_page: 'http://www.tohtech.ac.jp',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLC7KELbvDQmzx37aB45jWopm1jGiSFCj5Zj4Z0875VVrJKOH-Ci7qYR8',
    id: 5259,
    acronym: 'TIT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tohwa-u.ac.jp',
    name: 'Tohwa University',
    web_page: 'http://www.tohwa-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzhAsDrSL5qoaxY1tzuAYG9I1VArFsp-PJXpwItJIPyO-EivjX64iZ7N8e',
    id: 5260,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toin.ac.jp',
    name: 'Toin University of Yokohama',
    web_page: 'http://www.toin.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8CgwpmKm8AitHPpvfUUohPOilS-0yXJ7xhUvos41JlRPU2T_JrjZOeyM',
    id: 5261,
    acronym: 'TUY',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tojo.ac.jp',
    name: "Tohoku Women's College",
    web_page: 'http://www.tojo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJzlJf4mLRxtYR6CwwoV5BExhFKfGsrxT_IhlGjgLQR8sb5Wv71Lh9HA',
    id: 5262,
    acronym: 'TWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokai.ac.jp',
    name: 'Tokai University Educational System',
    web_page: 'http://www.tokai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSfxDyb3brAqN3r8HHznrrT8iv6CiTADbm6hs5YNqZk_xzjfMBOqWqQQ',
    id: 5263,
    acronym: 'TUES',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokaigakuen-u.ac.jp',
    name: 'Tokai Gakuen University',
    web_page: 'http://www.tokaigakuen-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5264,
    acronym: 'TGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokaijoshi-u.ac.jp',
    name: "Tokai Women's College",
    web_page: 'http://www.tokaijoshi-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNk-bsZtSlVAR9OXjAFsKQUZ5wWAAwzWGHAc5v82snTlDHi8CHKngB9c0p',
    id: 5265,
    acronym: 'TWC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokiwa.ac.jp',
    name: 'Tokiwa University',
    web_page: 'http://www.tokiwa.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpVikRTCBqyIIUreSxRJgMQ4xB26ujk4dUmmTKpadU9xWMKn5Z7Gyw4A',
    id: 5266,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokoha-u.ac.jp',
    name: 'Tokoha Gakuen University',
    web_page: 'http://www.tokoha-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI5o76cQUUh67WILb4N-WKlQ2fV98TrCLzA_wWRIB5qweKiGGxnPMFczLP',
    id: 5267,
    acronym: 'TGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokushima-u.ac.jp',
    name: 'Tokushima University',
    web_page: 'http://www.tokushima-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8rlsF8yFyPGjuZmWmMScNEoonv36Z4hM3Vc2-gQvlTZjY8hOX5TOQWg',
    id: 5268,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokuyama-u.ac.jp',
    name: 'Tokuyama University',
    web_page: 'http://www.tokuyama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzXhHZLsBsCjS8q8S-t7O287ZGmoopGWi6B6iGIsluD5_0Q5b5sSfiZwJo',
    id: 5269,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokyo-kasei.ac.jp',
    name: 'Tokyo Kasei University',
    web_page: 'http://www.tokyo-kasei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRoyB_d4xP06_XSrL69prWwg5ZLV_ghBxC9icI8_a_VJcFQjq4IPwlTjH6',
    id: 5270,
    acronym: 'TKU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokyo-med.ac.jp',
    name: 'Tokyo Medical College',
    web_page: 'http://www.tokyo-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxP98TgqgcGm7E8zMUL640vpy4grle5b-RHZyPk6XG4hBgMeZxSGawKec',
    id: 5271,
    acronym: 'TMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokyo-ondai.ac.jp',
    name: 'Tokyo College of Music',
    web_page: 'http://www.tokyo-ondai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTajjJtCWjQ56Q-Fy8UJfCEUAXx1qz2Lp27v4NFRwSfmhRZQ-4qlWSR9oRM',
    id: 5272,
    acronym: 'TCM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tokyo-u-fish.ac.jp',
    name: 'Tokyo University of Fisheries',
    web_page: 'http://www.tokyo-u-fish.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUTzCOMF65eGfhWMVdt3GlmZ-3OZe9QLInALiAZZNKWwf2CxSsRiTKJOI',
    id: 5273,
    acronym: 'TUF',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tonichi-kokusai-u.ac.jp',
    name: 'Higashi Nippon International University',
    web_page: 'http://www.tonichi-kokusai-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5274,
    acronym: 'HNIU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tosho-u.ac.jp',
    name: 'Tokyo University of Mercantile Marine',
    web_page: 'http://www.tosho-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRr5a_wgHiy9Th8jgwz7nhp0iS6dSB_aCULEVAfCNbVwxOnRVlgV0oRA',
    id: 5275,
    acronym: 'TUMM',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tottori-u.ac.jp',
    name: 'Tottori University',
    web_page: 'http://www.tottori-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJRM__FXTKWHIunYh7GlVUXWbcDdgzfXZWAB2ujrVe4w3GIhLKVe4ewde8',
    id: 5276,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toua-u.ac.jp',
    name: 'University of East Asia',
    web_page: 'http://www.toua-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF66iahccWdQh9p2c_6LO7hlDe2MJ2Oy8bhOZXExc7Wvj367t4A2Fm-sE',
    id: 5277,
    acronym: 'UEA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toyaku.ac.jp',
    name: 'Tokyo University of Pharmacy and Life Science',
    web_page: 'http://www.toyaku.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_pphVrDn4fPO1EFxJw5-PO-I1e1ZDKVAH6tF_7Go_N5Kv69hGWAOb_g',
    id: 5278,
    acronym: 'TUPLS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toyama-mpu.ac.jp',
    name: 'Toyama Medical and Pharmaceutical University',
    web_page: 'http://www.toyama-mpu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj6R3h2PilMbl2OqosO-tHXOgDqCn7BSWjeQwwa2ZlXeJpPGur5gxAZAA',
    id: 5279,
    acronym: 'TMPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toyama-u.ac.jp',
    name: 'Toyama University',
    web_page: 'http://www.toyama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDvtpTr2LfT5YmNBUh0X8GYEtdYdOR4lBlcr8plrkj7eMqnJgoDx2lBIipIg',
    id: 5280,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toyo.ac.jp',
    name: 'Toyo University',
    web_page: 'http://www.toyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr_TFH-vi1c1TpX8g8lf4JOe_FD1nueY8eI-hRTWWlxiNUm25-mSNqVxs',
    id: 5281,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toyoeiwa.ac.jp',
    name: "Toyo Eiwa Women's University",
    web_page: 'http://www.toyoeiwa.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZwK5n4162A1Nz7cep9hOsJlWhWFNnHpijSJwUIYXLTsx5gY8VUmGJVzGM',
    id: 5282,
    acronym: 'TEWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toyogakuen-u.ac.jp',
    name: 'Toyo Gakuen University',
    web_page: 'http://www.toyogakuen-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyATgZYTwHQAPpAu_j7lozzIPf0TJw2gLEl9vqNjVSm2LHOoM-zy2aWg',
    id: 5283,
    acronym: 'TGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'toyota-ti.ac.jp',
    name: 'Toyota Technological Institute',
    web_page: 'http://www.toyota-ti.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_okddsx0Cr9N-hrgZKuvlfo5ET9l_ntAHQjrQ0x5n0OWqKtBjirC6WRc',
    id: 5284,
    acronym: 'TTI',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tsuda.ac.jp',
    name: 'Tsuda College',
    web_page: 'http://www.tsuda.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIp3MFqi4Wd1ThzGQyvUL7Sy_Bvf2S_QgBreBP_Tun86I6u6344MrKifo',
    id: 5285,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tsukuba.ac.jp',
    name: 'Tsukuba University',
    web_page: 'http://www.tsukuba.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgKckVmFacKaDYb5ldc-2MHYT9eqIFVE3mFfgjFOm7w1obW8Q9O126rn4',
    id: 5286,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tsuru.ac.jp',
    name: 'Tsuru University',
    web_page: 'http://www.tsuru.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4shMey6GVm7t6r4YObrrFSqx4XSp0y12VYMHeg6n6gAPCFOeGZPICfQ4',
    id: 5287,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tsurumi-u.ac.jp',
    name: 'Tsurumi University',
    web_page: 'http://www.tsurumi-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQV_C8D6_F-He-VXQAG82EWW8D2zO6mpS5ms548pO_ReeTw7MbCOOVNx8RD',
    id: 5288,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tuad.ac.jp',
    name: 'Tohoku University of Art and Design',
    web_page: 'http://www.tuad.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpMql1QC2iMX0MMoOkGcjbFm3_hCBOlwbk2XrbjY2BJX3RGVmPAcHynfR8',
    id: 5289,
    acronym: 'TUAD',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tuat.ac.jp',
    name: 'Tokyo University of Agriculture and Technology',
    web_page: 'http://www.tuat.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdq5B6qKFV5Yez_W_JOaqO__qfdEFGrrMOIurW93EZVAX7v73JbliUZA',
    id: 5290,
    acronym: 'TUAT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tufs.ac.jp',
    name: 'Tokyo University of Foreign Studies',
    web_page: 'http://www.tufs.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-1fIxpTtUdnigPktVkuBfNVE3K1cmE3Z8UVQgdk6wzDITx__k1jnE4g',
    id: 5291,
    acronym: 'TUFS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tuins.ac.jp',
    name: 'Toyama University of International Studies',
    web_page: 'http://www.tuins.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5oNsrSrCcUtfK_0atiLGqHgUEOs1jWFMgvI6PLt5l4145LaqAHw4drQ',
    id: 5292,
    acronym: 'TUIS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tuis.ac.jp',
    name: 'Tokyo University of Information Sciences',
    web_page: 'http://www.tuis.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaMT5lJT7QEstvf196pbXHrSxDIeaol_48_FJQRmPPUUV9_t8VUTdSNNK7',
    id: 5293,
    acronym: 'TUIS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tuj.ac.jp',
    name: 'Temple University Japan',
    web_page: 'http://www.tuj.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyM7eYwq2eGTaq2nnQQiw0L7Wyb0E_psYXd8gg4tTRdShrPRr4fgbpqA',
    id: 5294,
    acronym: 'TUJ',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'tut.ac.jp',
    name: 'Toyohashi University of Technology',
    web_page: 'http://www.tut.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgjMqUuCxK5zOGsM2VCN8-9mLKSnhVuKZxdwUvWciQjJo-TlPH5bpTQg',
    id: 5295,
    acronym: 'TUT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'twcpe.ac.jp',
    name: "Tokyo Women's College of Physical Education",
    web_page: 'http://www.twcpe.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2Gbkam6kIBIpLWLF7FF5ccj7QJJXBydz-Km82gG9gKxwVYjauAoE3ZOw',
    id: 5296,
    acronym: 'TWCPE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'twcu.ac.jp',
    name: "Tokyo Woman's Christian University",
    web_page: 'http://www.twcu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrCP7QpP_pP-ZuaP5orUFI4z37WG4YxZbSw85EJa7-7Jm2Zfhqy7yUIZM',
    id: 5297,
    acronym: 'TWCU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'twmu.ac.jp',
    name: "Tokyo Women's Medial College",
    web_page: 'http://www.twmu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEQsEt5iPVMfbF4t2V4iUUv8yz7iLeIwopQ4a1T6CA-6qX1g0zPGyuJ0g',
    id: 5298,
    acronym: 'TWMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-air.ac.jp',
    name: 'University of the Air',
    web_page: 'http://www.u-air.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo2BgzG7HLbAlYq8GNvLr5lLDHezwKenJiBKiMnDkdKCnt9LRF5F1e1Q',
    id: 5299,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-aizu.ac.jp',
    name: 'University of Aizu',
    web_page: 'http://www.u-aizu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0bmoPHWPWdBBABUYJ7t4lvSR0S23BE75G0uBvoxDRAlb6X6lmqaFx6g',
    id: 5300,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-bunkyo.ac.jp',
    name: 'University of Bunkyo',
    web_page: 'http://www.u-bunkyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRokF0OXgwowlOm9hy6z8MNXLVgX9mPTA5acrY9vD22SJP4RDDUC9ix1qI',
    id: 5301,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'uec.ac.jp',
    name: 'University of Electro-Communications',
    web_page: 'http://www.uec.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2WoVqBm-AdY6qxhrCxhg4eqWKunnOzPtpuUsdexkZO2yMfu_mjzhwGq0',
    id: 5302,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'uenogakuen.ac.jp',
    name: 'Ueno Gakuen College',
    web_page: 'http://www.uenogakuen.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfGbLoSwq48NvgOUGWoEGazInVqiKvnbADvsW8z9R1oQCj7oxJLrFRMcE',
    id: 5303,
    acronym: 'UGC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-gakugei.ac.jp',
    name: 'Tokyo Gakugei University',
    web_page: 'http://www.u-gakugei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAlL9s_QkpzMd4H3LD7R6mcvHFUNHOTpUNWZsXGlWL-Fu1EYiRcyFRIeQ',
    id: 5304,
    acronym: 'TGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'uitec.ac.jp',
    name: 'Polytechnic University',
    web_page: 'http://www.uitec.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkD19SSXDpBKnhV7xHpSTkmm2Yf1TDH2t5ykfMnjscvgnP_yW3aqqkoA',
    id: 5305,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-keiai.ac.jp',
    name: 'Keiai University',
    web_page: 'http://www.u-keiai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5306,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'umds.ac.jp',
    name: 'University of Marketing and Distribution Sciences',
    web_page: 'http://www.umds.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIO2Y-8ihm7Kjy-zoe9uegsoO0DaOU6dGRrIV22ixCkc2e-cMgsMnpjA',
    id: 5307,
    acronym: 'UMDS',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'unu.edu',
    name: 'United Nations University',
    web_page: 'http://www.unu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsVBUA0oer6oRVm-OZNrLYJBEJjMweZqGtwj76wcmcl00crz2TjktgWio',
    id: 5308,
    acronym: 'UNU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'uoeh-u.ac.jp',
    name: 'University of Occupational and Environmental Health Japan',
    web_page: 'http://www.uoeh-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKY-xk1ZMhdpZRQ90h9sg5ExS4A1DQ-WhLgZ8w0r6s_YZ4aewSYkMYTa4',
    id: 5309,
    acronym: 'UOEHJ',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-ryukyu.ac.jp',
    name: 'University of the Ryukyus',
    web_page: 'http://www.u-ryukyu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQDFQ4U6fLaGHQ2vl6Cir9y_vX4x9TCvhXRneh06-qtYVF7PKbqf48eV8',
    id: 5310,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-sacred-heart.ac.jp',
    name: 'University of the Sacred Heart Tokyo',
    web_page: 'http://www.u-sacred-heart.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqugbhdCXGrx-PwqtGovPBFdTddrSJE6NEG_VNe71IoGJDU1V1wZaj8UveIg',
    id: 5311,
    acronym: 'USHT',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-shizuoka-ken.ac.jp',
    name: 'Shizuoka Prefectural University',
    web_page: 'http://www.u-shizuoka-ken.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrAPdnxq0PcAKcYeps5gj89HPa6tP0LzXUqkAGWTE6X9hYdpk-EtHaUQ',
    id: 5312,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'usp.ac.jp',
    name: 'University of Shiga Prefecture',
    web_page: 'http://www.usp.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBMOJR-E9I5Hvxy-UfffQl5KjRvGR4uvoS8SnacooHsn-Jd5pM8uZFxzzP',
    id: 5313,
    acronym: 'USP',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-tokai.ac.jp',
    name: 'Hokkaido Tokai University',
    web_page: 'http://www.u-tokai.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP7lPb4nGsGN0EioYJBAi3Fplp_3syjJSgG0GT4UFoaVZaf-CifFCkKK4',
    id: 5314,
    acronym: 'HTU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'u-tokyo.ac.jp',
    name: 'Tokyo University',
    web_page: 'http://www.u-tokyo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRj2lDwPWCLe9WVvyANitDIBENNjrIRp-QTrwHxlPo2z076EWMDGTSEew',
    id: 5315,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'utsunomiya-u.ac.jp',
    name: 'Utsunomiya University',
    web_page: 'http://www.utsunomiya-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBUrgCrEHHT0PYhGfWwiPHdbg0X9quqWm9z_gBKFGIViAcbMO9zu_towU',
    id: 5316,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'wakayama-med.ac.jp',
    name: 'Wakayama Medical College',
    web_page: 'http://www.wakayama-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5317,
    acronym: 'WMC',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'wakayama-u.ac.jp',
    name: 'Wakayama University',
    web_page: 'http://www.wakayama-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXBn23UOz0WkyC2CLnO8T8xCv8WkxnGpGanQSNF2e8bJsXgnXfvEnTHUw',
    id: 5318,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'wakhok.ac.jp',
    name: 'Wakkanai Hokusei Gakuen University',
    web_page: 'http://www.wakhok.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwRqRoGI7EVu7SuTgsyDYz7GUYjci5w5W10BdmgHvyaUbcgDis2Hh-I9A',
    id: 5319,
    acronym: 'WHGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'wako.ac.jp',
    name: 'Wako University',
    web_page: 'http://www.wako.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSpguAJYc-2FZYVrhci13fJrqhSRnwpE-8Tz2do7jr8vbWMAJaCNTuOQ',
    id: 5320,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'waseda.ac.jp',
    name: 'Waseda University',
    web_page: 'http://www.waseda.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4xe4_va9mNF3klXTnA1TWP1XkSpOALrHVORUZYCsmQv1CQkEyezea0A',
    id: 5321,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'wayo.ac.jp',
    name: "Wayo Women's University",
    web_page: 'http://www.wayo.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5322,
    acronym: 'WWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yamagata-u.ac.jp',
    name: 'Yamagata University',
    web_page: 'http://www.yamagata-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVE2XMqIp-IIxH5Cj4ngowj1M0spNIS5O1plhGRDyROiWXrnhARQsOOlic',
    id: 5323,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yamaguchi-pu.ac.jp',
    name: 'Yamaguchi Prefectural University',
    web_page: 'http://www.yamaguchi-pu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1G-TStN-IXbE-WHxTC8oZngxaHqhxS2WtTkl3c-zt75DfuIRMBE8PLw',
    id: 5324,
    acronym: 'YPU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yamaguchi-u.ac.jp',
    name: 'Yamaguchi University',
    web_page: 'http://www.yamaguchi-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL7KZrWeaOHZdOLm6lxanJz-HmPGCGrpJIBE_kFWAUoVjwR74ERZTadz8',
    id: 5325,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yamanashi.ac.jp',
    name: 'Yamanashi University',
    web_page: 'http://www.yamanashi.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTckK6QKpQAMkDA-b-rx_DkySBhMA0Q0bwhbe1W-AJ7SAPvtTeA-flNnA',
    id: 5326,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yamanashi-med.ac.jp',
    name: 'Yamanashi Medical University',
    web_page: 'http://www.yamanashi-med.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKW6tzDaDfz4Bf3JoWqeZiQKTzOIqSsVPQr-ebIilxiId17nSHhfXwy3VY',
    id: 5327,
    acronym: 'YMU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yama.sut.ac.jp',
    name: 'Science University of Tokyo in Yamaguchi',
    web_page: 'http://www.yama.sut.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXHORuDGKqEhK_N5OlBzH4q5YYa4-lz4tmOazePW_1X35bDsH7t3Ipf4Ji',
    id: 5328,
    acronym: 'SUTIY',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yasuda-u.ac.jp',
    name: "Yasuda Women's University",
    web_page: 'http://www.yasuda-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6B5XQXkdHDBwzJQot5ZaZxfFKcC_7QA6RuufVIr6tSnYREB65n1DLtVg',
    id: 5329,
    acronym: 'YWU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ygu.ac.jp',
    name: 'Yamanashi Gakuin University',
    web_page: 'http://www.ygu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTckK6QKpQAMkDA-b-rx_DkySBhMA0Q0bwhbe1W-AJ7SAPvtTeA-flNnA',
    id: 5330,
    acronym: 'YGU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'ynu.ac.jp',
    name: 'Yokohama National University',
    web_page: 'http://www.ynu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7guSa7YE5GJOQ8JNdmNgCKoXFPy7VbKoar2-T0ErosWhAJtEkMMtZYjY',
    id: 5331,
    acronym: 'YNU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yokkaichi-u.ac.jp',
    name: 'Yokkaichi University',
    web_page: 'http://www.yokkaichi-u.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwkLqtzXGl_zMF3ZHHQLldHhIK8PbLOQqQkkjxp6JQkE5lvzIAqLpuEQ',
    id: 5332,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'yokohama-cu.ac.jp',
    name: 'Yokohama City University',
    web_page: 'http://www.yokohama-cu.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkfbtUDagJ3VfEbjCBM6pOEECwhEV5BlQtQSJ8Lco0f_J46Mqn5oCTlA',
    id: 5333,
    acronym: 'YCU',
  },
  {
    alpha_two_code: 'JP',
    country: 'Japan',
    domain: 'zokei.ac.jp',
    name: 'Tokyo University of Art and Design',
    web_page: 'http://www.zokei.ac.jp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAETELEBB-7z3aBgRvYXM_M-y_DAcEuaIF-tXz9Ax15RzPfls9Ja3OqXI',
    id: 5334,
    acronym: 'TUAD',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'aabfs.org',
    name: 'Arab Academy for Banking and Financial Sciences',
    web_page: 'http://www.aabfs.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFyoCJhPF5kGaEvX61Pg2dSW5uRIZULxUVz19f8BYEHgYvh-psnp-j5Ek',
    id: 5335,
    acronym: 'AAFBFS',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'aabu.edu.jo',
    name: 'Al al-Bayt University',
    web_page: 'http://www.aabu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7Nk3uA3IAcwiFpd5NF-n3N1jdpNYUN51dY28__-ebJkM6nKjD9CjVdPg',
    id: 5336,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'aau.edu.jo',
    name: 'Amman Arab University for Higher Studies',
    web_page: 'http://www.aau.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm8Ro4RAtZmyqRSq0Q1nZgqua8zamEPVH78T9N679nY4lcS3CBDjDCqpY',
    id: 5337,
    acronym: 'AAUFHS',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'ahu.edu.jo',
    name: 'Al Hussein Bin Talal University',
    web_page: 'http://www.ahu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSljajld-04lNxGZlsgnvSvGR2Vgu8l2bAzVb-H1vHiJT3XpyE6Lf-URfI',
    id: 5338,
    acronym: 'AHBTU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'alzaytoonah.edu.jo',
    name: 'Al-Zaytoonah University',
    web_page: 'http://www.alzaytoonah.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYuleE5XHk0oJDD7o6CHRcvAQr3fSaXs-gh5rvs9bzWsu4zEbGa_vRkw',
    id: 5339,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'amman.edu',
    name: 'Amman University',
    web_page: 'http://www.amman.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4EmZZdFELK7L0N9qjpH2lj9eAaQ2TcZeBzwaAA2ojFwI015REvUkvy7M',
    id: 5340,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'ammanu.edu.jo',
    name: 'Al-Ahliyya Amman University',
    web_page: 'http://www.ammanu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4EmZZdFELK7L0N9qjpH2lj9eAaQ2TcZeBzwaAA2ojFwI015REvUkvy7M',
    id: 5341,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'bau.edu.jo',
    name: 'Al-Balqa Applied University',
    web_page: 'http://www.bau.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGu3A0ztFyBC3W6UTMWvkowijXyW0kqrZ3N9vUzV5eRyUkqwsIAn_wFCQ',
    id: 5344,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'gju.edu.jo',
    name: 'German Jordanian University',
    web_page: 'http://www.gju.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuvkterRYU7Yjv9yRQnRw-uSq1CXN2UKD5E6J6n7Iwua3sKz_g909x8Uiy',
    id: 5345,
    acronym: 'GJU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'hu.edu.jo',
    name: 'Hashemite University',
    web_page: 'http://www.hu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaMRRdM13ScumIqSCVkI-c_j0HAmlWrljSwxwfaIxiMPVWaGyAEympAdQ',
    id: 5346,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'inu.edu.jo',
    name: 'Irbid National University',
    web_page: 'http://www.inu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQduiWA2OxBH6GeJToBESlaE_kchdaK0-PnYyUqDK5N6Kn1eD_Z1hiISk8',
    id: 5347,
    acronym: 'INU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'isra.edu.jo',
    name: 'Al-Isra University',
    web_page: 'http://www.isra.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSAcO7z2iJgXSUZ_cNzl98_nVrjlGLMKt1D8x_1OE0QL4Am3tWXKvbWg',
    id: 5348,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'jadara.edu.jo',
    name: 'Jadara University',
    web_page: 'http://www.jadara.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-SCXpy1TG5ZO2d9HJZtFhJXL0BNZgXTY4iLQvXsvNjmYBZd9KBXnsA5Lw',
    id: 5349,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'jam.edu.jo',
    name: 'Jordan Academy of Music / Higher Institute of Music',
    web_page: 'http://www.jam.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ304oFIGVzK43J5AkJD1GJyTz3ot3jE5lEOdGcn4WSZ_KScyQH69QXMg',
    id: 5350,
    acronym: 'JAM/HIM',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'jerashun.edu.jo',
    name: 'Jerash Private University',
    web_page: 'http://www.jerashun.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS97BljxwfDwjvvxuSeMVi3Mhrl5npohzzVmBgW-hyex6Pz-ou5FMq2ZUM',
    id: 5351,
    acronym: 'JPU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'ju.edu.jo',
    name: 'University of Jordan',
    web_page: 'http://www.ju.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAPAklnP-7QCgploKoLiXhYfzdH6d0wMHggqAflIoJAGNao4ge8UaG0A',
    id: 5352,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'just.edu.jo',
    name: 'Jordan University of Science and Technology',
    web_page: 'http://www.just.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiMz72yQ08cT-iEfo9Fjk8e2HfcRPH1u9cbQSHOoDtzVGsup63LRdVvSw',
    id: 5353,
    acronym: 'JUST',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'meu.edu.jo',
    name: 'Middle East University',
    web_page: 'http://www.meu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMCumveGxYCMdcn02u588vpRudlMbeqBUR5bxcbnC6vjbEoZzYw73T9Q',
    id: 5354,
    acronym: 'MEU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'mutah.edu.jo',
    name: "Mu' tah University",
    web_page: 'http://www.mutah.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwYq1oLrG_oVIdjpWFdNWPNpy2dRVcA37Izi6XvVRfO59qKmmVcjRiYoq5',
    id: 5355,
    acronym: 'MTU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'psut.edu.jo',
    name: 'Princess Sumaya University for Technology',
    web_page: 'http://www.psut.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvFN3Y78qxrhfYhaZDAW108HHNwdhY0WntcmoYWpXtbAklE3wYoHwd_E0',
    id: 5358,
    acronym: 'PSUFT',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'ttu.edu.jo',
    name: 'Tafila Technical University',
    web_page: 'http://www.ttu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7vc4jS1sfeoJ_v9iExNqKUfdk1OxljsKV_RrZxjodD5PtJhO2R1soqGc',
    id: 5359,
    acronym: 'TTU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'uop.edu.jo',
    name: 'University of Petra',
    web_page: 'http://www.uop.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg1GEqXl6ouDs5vZ5J9H4G8TWlbeSctstHwMjN2eJxbcI-F3SEOl7hog',
    id: 5360,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'wise.edu.jo',
    name: 'The World Islamic Sciences & Education University',
    web_page: 'http://www.wise.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR27_GI5aVg1klQw1NRNi_QwPz9AzUnydprhZ0iNUIU7ikdzlE9_8R5N_Y',
    id: 5361,
    acronym: 'WIS&EU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'yu.edu.jo',
    name: 'Yarmouk University',
    web_page: 'http://www.yu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfqLEapxjSTUyHKpA76Vrr1-0AJN0Wnc4mMyFCV4b75-aOElNNmuFw8V9t2Q',
    id: 5362,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'JO',
    country: 'Jordan',
    domain: 'zpu.edu.jo',
    name: 'Zarka Private University',
    web_page: 'http://www.zpu.edu.jo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUPSeKvsje3lJ1jqc2rSEZeR3GHHSSStmp5KWcfzDOyWiCKns-hZ3EN5U',
    id: 5363,
    acronym: 'ZPU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'ablaikhan.kz',
    name: 'Abylai Khan University',
    web_page: 'http://www.ablaikhan.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTwfEDOsNb8t_Ofzt8AGGluaXJcx51oWP-4bQAiCWR5G453oG1fw3ot-8',
    id: 5364,
    acronym: 'AKU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'agriun.almaty.kz',
    name: 'Kazak State University of Agriculture',
    web_page: 'http://www.agriun.almaty.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoVrrgmcgF158ja3t4ml6DKqsgaKrpXmq91n_0mdPGCqO1lPPy3BUu6w',
    id: 5365,
    acronym: 'KSUA',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'aktsu.kz',
    name: 'Aktau State University',
    web_page: 'http://www.aktsu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO9Vfajm_v4ecNaPfALwGVndjrRE57Zac8CDRoR7ohzbOvirezj_cW9SY',
    id: 5366,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'atgu.kz',
    name: 'Atyrau State University',
    web_page: 'http://www.atgu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 5367,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'ektu.kz',
    name: 'East Kazakstan State Technical University',
    web_page: 'http://www.ektu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS7AfLRUUiXpj-WPEI3iCHGddLob4WvY8w3tPxOm5a4YFnCUl0o9rLyg',
    id: 5368,
    acronym: 'EKSTU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'enu.kz',
    name: 'L.N. Gumilyov Eurasian National University',
    web_page: 'http://www.enu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQROFwo7XOZA60kqyy8eV94boNQ8fYcxXB8bQeQrs9nnzV0g0mx3p_arcA',
    id: 5369,
    acronym: 'LGENU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'eurazir.kz',
    name: 'Eurasian Institute of market',
    web_page: 'http://www.eurazir.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmJv9b4o6_eQr_p8Ayyj2LsdQiwpOVzhZribyTxIxiw5OKqRKw8vN-SYU',
    id: 5370,
    acronym: 'EIM',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'kau.kz',
    name: 'Kazak American University',
    web_page: 'http://www.kau.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYLISL25s9ARX0TZv6Rq3IbgXwFEaYhNEQ0FgGJdssj7F4v7ZbyvqO6g',
    id: 5371,
    acronym: 'KAU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'kazeu.kz',
    name: 'Turar Ryskulov Kazakh Economic University',
    web_page: 'http://www.kazeu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwok5Zu_77AeXGuXyf4yqduo9mubI0JyUfsjEqCGnyHYCV999hAM0Gxw',
    id: 5372,
    acronym: 'TRKEU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'kaznpu.kz',
    name: 'Abai State University',
    web_page: 'http://www.kaznpu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXSvIUkQUyQzI11I-Jyb4Rwr54TKK5soCwK3NWJP5R9pi0oqAvc-Kzpx0',
    id: 5373,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'kazntu.kz',
    name: 'Kazak National Technical University',
    web_page: 'http://www.kazntu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVuTuAkJaNaX_2TgYXeuv2LuLHjFQ6ZulWlaa574aXjetcRcIfUlBcWJQ',
    id: 5374,
    acronym: 'KNTU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'kaznu.kz',
    name: 'Al-Farabi Kazakh National University',
    web_page: 'http://www.kaznu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8IeBrB2ubQhT8IWI08sE_ZdvBOvCyKLUbdTsIlK2UBkq_oz4jCh44IA',
    id: 5375,
    acronym: 'AKNU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'kbtu.kz',
    name: 'Kazakh British Technical University',
    web_page: 'http://www.kbtu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-QP1XZy5zLuVIQ7J_zOvhi2VnDGBlHHz5U-jN1MgQmTxO6KT7f8anT5Y',
    id: 5376,
    acronym: 'KBTU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'kimep.kz',
    name: 'Kazakhstan Institute of Management Economics and Strategic Research',
    web_page: 'http://www.kimep.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeNLfh4DZ-5KhxTY30xHzjJGuaQPf7qPbqokBaP6lqxdMTgIrK8GhBfw',
    id: 5377,
    acronym: 'KIMESR',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'kstu.kz',
    name: 'Karaganda State Technical University',
    web_page: 'http://www.kstu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgB8A_dv94Kx9PqQQ0mAPLof2zS8Mn0ru0YrlGeFDXuYjsPSKRrO-6wg',
    id: 5378,
    acronym: 'KSTU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'ksu.kst.kz',
    name: 'Kostanai State University',
    web_page: 'http://www.ksu.kst.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSE5CtE-DcNx3yQYJMuOEdqipCYYjejiNoWuYoFN94K8r0MklFVPMbn8A',
    id: 5379,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'ksu.kz',
    name: 'Karaganda State Buketov University',
    web_page: 'http://www.ksu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnt9NRQe9dG-HCllszOAzSDNK2J2bourOt78xP44kUxR4j7JxWIMZpkhciEQ',
    id: 5380,
    acronym: 'KSBU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'nkzu.kz',
    name: 'North Kazakhstan State University',
    web_page: 'http://www.nkzu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQemJPrwAepdi_aUuR9fZFdU__jtQX1-uHTMH7nIPpI1FyRggWn1y1yoQY',
    id: 5381,
    acronym: 'NKSU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'psu.kz',
    name: 'Pavlodar University',
    web_page: 'http://www.psu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnpZ8zOU_CubZ4KKj84usdcZKMjBTPi07BnKZLClR8KU4k7B7YdVw6qwg',
    id: 5382,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'sdu.edu.kz',
    name: 'Suleyman Demirel University',
    web_page: 'http://www.sdu.edu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-5nZACsnvbuu241NGSiGm_M9u1IMm61y-P7saOqauQ_YC8q0jH6vmVCwZ',
    id: 5383,
    acronym: 'SDU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'semgu.kz',
    name: 'Semey State University',
    web_page: 'http://www.semgu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPLf61Ny13prJIwNZnTvDj2r-6KhIGDZ8PEV22DSj8aThDYJrd9lub39M',
    id: 5384,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'sgma.kz',
    name: 'Semey State Medical University',
    web_page: 'http://www.sgma.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPLf61Ny13prJIwNZnTvDj2r-6KhIGDZ8PEV22DSj8aThDYJrd9lub39M',
    id: 5385,
    acronym: 'SSMU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'turkistan.kz',
    name: 'International Kazakh-Turkish University',
    web_page: 'http://www.turkistan.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyNBwZCF1OQwf1JWRUu1Mgz1y6iVT-AwQk4PcpEIHGQFLhpAcc23SuQg',
    id: 5386,
    acronym: 'IKU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'uib.kz',
    name: 'University of International Business',
    web_page: 'http://www.uib.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX-QxFbA4TumOLeVDTa2twB6F6R-ZPESoWDPbQ8P1g-W1G6TR3A4LxTw',
    id: 5387,
    acronym: 'UIB',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'vkgu.kz',
    name: 'East Kazakhstan State University',
    web_page: 'http://www.vkgu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhUho-JdU12_yQwiZSypl9Rf7Ezq755Fks0vbmBHpw2mKac7IRA63IDMo',
    id: 5388,
    acronym: 'EKSU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'wkau.kz',
    name: 'Western Kazakhstan Agricultural University',
    web_page: 'http://www.wkau.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwCeDdIv-FRFXKTap1-zbkac47TexGtW797FmeD7HX2u_Nq5gZNDY-Rkg',
    id: 5389,
    acronym: 'WKAU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'zhezu.kz',
    name: 'Zhezkazgan Baikonurov University',
    web_page: 'http://www.zhezu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1I4453MyeHvdrSMUzrQp81MfOn3-99lQT7nkhaHaNK2Q7Z8NIQ-bBO6Da',
    id: 5390,
    acronym: 'ZBU',
  },
  {
    alpha_two_code: 'KZ',
    country: 'Kazakhstan',
    domain: 'zhgu.kz',
    name: 'Zhetysu State University',
    web_page: 'http://www.zhgu.kz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXSvIUkQUyQzI11I-Jyb4Rwr54TKK5soCwK3NWJP5R9pi0oqAvc-Kzpx0',
    id: 5391,
    acronym: 'ZSU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'aiu.ac.ke',
    name: 'Africa International University',
    web_page: 'http://www.aiu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9FReQDeyYYCnG1F6zE6XXs53a_RRj_AdZKMgAprwvr6QMPoYBlgEhgw',
    id: 5392,
    acronym: 'AIU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'aku.edu',
    name: 'Aga Khan University',
    web_page: 'http://www.aku.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpHWh6KtfnCAvdFj3qoSHJdRX2FaahsyQXwHseW5sQVMW3PvqM0CEW5Iw',
    id: 5393,
    acronym: 'AKU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'anu.ac.ke',
    name: 'Africa Nazarene University',
    web_page: 'http://www.anu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ9JO7kAeFWdz1nAe6lcj1rcGGuIlIC46FkhdJGCzD0_FNcG4AANlPtN4',
    id: 5394,
    acronym: 'ANU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'aua.ac.ke',
    name: 'Adventist University of Africa',
    web_page: 'http://www.aua.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkakNWkuZ7ka3MR9i3BvjkEiOCEI-n9_GVr1yOvV_AQEGZFCQlcZUBeA',
    id: 5395,
    acronym: 'AUA',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'chuka.ac.ke',
    name: 'Chuka University',
    web_page: 'http://www.chuka.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmznMJjfZpXfVqW6en6-BxVfdRWkIeFubVtOaNQDhR8r-H5i5Il26_RZ8',
    id: 5396,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'cuea.edu',
    name: 'Catholic University of Eastern Africa',
    web_page: 'http://www.cuea.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcST6-i-JjjmcNb8IDyyNVCQHwrA4vOL0gpGn3ykZ0SwNjVVEFmXcYvXRknU',
    id: 5397,
    acronym: 'CUEA',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'daystar.ac.ke',
    name: 'Daystar University',
    web_page: 'http://www.daystar.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTied120DgtnkzDQFMOg-1CqXLx7JBpm_-ePaO0ZSJpKEAeEO8t6sCzpBY',
    id: 5398,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'dkut.ac.ke',
    name: 'Dedan Kimathi University of Technology',
    web_page: 'http://www.dkut.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_bnPCCeLqZscTv_zAwMmrnyF9EEn-8sLDpU-2nbWSS3eS6ep4D47LNw',
    id: 5399,
    acronym: 'DKUT',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'egerton.ac.ke',
    name: 'Egerton University',
    web_page: 'http://www.egerton.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_I_x-K-egkU3BFVi0cFRv5rAI73un7aqgkcoug6pY-fb1k0zBgNla_A',
    id: 5400,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'gluk.ac.ke',
    name: 'Great Lakes University of Kisumu',
    web_page: 'http://www.gluk.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQy--X4tshFLsAzV1dn9TTUdqWi0kPnamOIrFjtYQ4JSOjKw5DfjW7OFbnA',
    id: 5401,
    acronym: 'GLUK',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'gretsauniversity.ac.ke',
    name: 'Gretsa Universtiy',
    web_page: 'http://www.gretsauniversity.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRREmzsNFYc6ImkPEKrThFG2LIOGPm7LnDogfd3YomDMcJDakEtoconfA',
    id: 5402,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'iu.ac.ke',
    name: 'Inoorero University',
    web_page: 'http://www.iu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiOWayhlQpkFITv_A4fILs1wcYdvvpGUpoZ2ba1H3KuVaEgBEysI_KbQY',
    id: 5403,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'jkuat.ac.ke',
    name: 'Jomo Kenyatta University of Agriculture and Technology',
    web_page: 'http://www.jkuat.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReisBrWwfbhTywd1k9J9IHmthf9oYw6XaON85f7qTIMrfDia4cX6u8jA',
    id: 5404,
    acronym: 'JKUAT',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'jooust.ac.ke',
    name: 'Jaramogi Oginga Odinga University of Science and Technology',
    web_page: 'http://www.jooust.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYXTAIIwy1SlOlRM6ZHTv6PlVucRbecJ8G689HN4g9jf5W8dQGm2RznM8',
    id: 5405,
    acronym: 'JOOUST',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'kabarak.ac.ke',
    name: 'Kabarak University',
    web_page: 'http://www.kabarak.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsDfvt_Nn7tS95xCrhVYE7cVYjcqAswdBkgxPq8sO4fc_gil2nZ5nhkQ',
    id: 5406,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'kabianga.ac.ke',
    name: 'University of Kabianga',
    web_page: 'http://www.kabianga.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIoJSxE0jEXq3I4fzQISHezHBZRPfqQaI82OTlsfNv8ACgX12beuM0uL8',
    id: 5407,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'karatinauniversity.ac.ke',
    name: 'Karatina University',
    web_page: 'http://www.karatinauniversity.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0YiBJj7SSAiEISm82WoyzFStCIqW2hBVCl4rd7N4IJsrSFR8DBoCLYA',
    id: 5408,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'kca.ac.ke',
    name: 'Kenya College of Accountancy',
    web_page: 'http://www.kca.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwIRo4KnZb2M08rB9RclS9_ULA6svFoKn90M945WacqY-Z3ILVA1wfvw',
    id: 5409,
    acronym: 'KCA',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'kemu.ac.ke',
    name: 'Kenya Methodist University',
    web_page: 'http://www.kemu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmkoNIkTEzE303mLxAmHwT3xOTwi6_YBop491vBljJvbkPAVyV_SUbb3I',
    id: 5410,
    acronym: 'KMU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'kisiiuniversity.ac.ke',
    name: 'Kisii University',
    web_page: 'http://www.kisiiuniversity.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmkTCcFLVtJq9ZCvv5bcJSA-sXclLDo7g_RIFo6wDKfLDVzMevnv16c18',
    id: 5411,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'kmtc.ac.ke',
    name: 'Kenya Medical Training College',
    web_page: 'http://www.kmtc.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaDeWm9XKECpZI13U13j0_djSvSf9gZkUEbYPp00oMoiaaOEC33eyPpHghug',
    id: 5412,
    acronym: 'KMTC',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'ku.ac.ke',
    name: 'Kenyatta University',
    web_page: 'http://www.ku.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvMYTtkmBu2yebqaKqyf27nKOsxvE6lQTNG8Y1ZyrnPATBJkWzeuxn6w',
    id: 5413,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'kwust.ac.ke',
    name: 'Kiriri Womens University of Science and Technology',
    web_page: 'http://www.kwust.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlb8rguZAfUFF1Lu3mizlzlSTbvI5iCTM1jepx3hQXc7hvZ42QaGtlW145',
    id: 5414,
    acronym: 'KWUST',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'laikipia.ac.ke',
    name: 'Laikipia University',
    web_page: 'http://www.laikipia.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCXHkF_yrfCiKVGRdI4MyTG2kOcxIlu2_hXiOjSfkSs-gH5Is6eOQgpA',
    id: 5415,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'maseno.ac.ke',
    name: 'Maseno University',
    web_page: 'http://www.maseno.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrWT4c-n1aWvaijfvPoPvUHoPb4gmZGj0bDSvVOAUh-Ao2w3DyOdDHKxG1',
    id: 5416,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'mku.ac.ke',
    name: 'Mount Kenya University',
    web_page: 'http://www.mku.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPa9GdSb7UzPBPSZzo2guQmDRXVfySokIW_-96_NINgHPmgy6tsfabuOI',
    id: 5417,
    acronym: 'MKU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'mmarau.ac.ke',
    name: 'Maasai Mara University',
    web_page: 'http://www.mmarau.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJbztlqWTx8LCEa3rEyhhQeMdf_zX8cs9uFX_gN2gnBBsIaDDmxi_F5A',
    id: 5418,
    acronym: 'MMU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'mmu.ac.ke',
    name: 'Multimedia University of Kenya',
    web_page: 'http://www.mmu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNmMNYVvCeGajlSrARjFKIb6tRDXEjvb-VMiazB4agFz8aY_cztyvj-g',
    id: 5419,
    acronym: 'MUK',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'mmust.ac.ke',
    name: 'Masinde Muliro University of Science and Technology',
    web_page: 'http://www.mmust.ac.ke/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcR-fSot_U_loHjeD4WA7EA-464kJdUYTr0qGnJ4h-3Du4IFUHS_r00WI1E',
    id: 5420,
    acronym: 'MMUST',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'mu.ac.ke',
    name: 'Moi University',
    web_page: 'http://www.mu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRv3XDViLRJZLJ_gIx8Yp1htnJ0gW-HQiZNGWmDHY2w4cp5jPyEG0HTF74',
    id: 5421,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'must.ac.ke',
    name: 'Meru University of Science and Technology',
    web_page: 'http://www.must.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT69ESyQzdZj3R7Sko-YI9Wen6afCMhBOe4YNsgqvANCM5KrvdVZhqgbjQ',
    id: 5422,
    acronym: 'MUST',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'nibs.ac.ke',
    name: 'Nairobi Institute of Business Studies',
    web_page: 'http://www.nibs.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrQsBOOqK48QdcSQMBTT99haR1esv-VvnYSjWOLrNW47HG8jh_MecDLg',
    id: 5423,
    acronym: 'NIBS',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'ntti.ac.ke',
    name: 'Nairobi Technical Training Institute',
    web_page: 'http://www.ntti.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiTwDdhk6iJ4rC3N15Q1NqXeqovwzr1RDo4SPv53M8BQEEA0fz17sSvQ',
    id: 5424,
    acronym: 'NTTI',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'pacuniversity.ac.ke',
    name: 'Pan Africa Christian University',
    web_page: 'http://www.pacuniversity.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3CVFy2iSxUA3c_xKVfhSsBZcM3gePHOJhBRadkQDhndEXr0fy5DUlYyc',
    id: 5425,
    acronym: 'PACU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'piu.ac.ke',
    name: 'Pioneer International University',
    web_page: 'http://www.piu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbDNLVzv-qwGJNr_oJ129dRuxn6yjqY8hti04uUGk_SxFSRgARNi9M',
    id: 5426,
    acronym: 'PIU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'pu.ac.ke',
    name: 'Pwani University',
    web_page: 'http://www.pu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWOwbnkH0I_Ko12eeQZEtwVDbFVIdcrrjcYrHpvFtHFLEUDCZlp5KuejAD',
    id: 5427,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'puea.ac.ke',
    name: 'Presbyterian University of East Africa',
    web_page: 'http://www.puea.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfQcdx0unuDR3iLAbLagFmJ0C9hlUuHoxaqmqeG5Ipceigi5pQJ4F9Hg',
    id: 5428,
    acronym: 'PUEA',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'riarauniversity.ac.ke',
    name: 'Riara University School of Business and Law',
    web_page: 'http://www.riarauniversity.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdcUGFJxXQTefD1zXFYlWxK_0ySx4CCaH3bBGpYU7q9jmEF-uZHOjfAgpj',
    id: 5429,
    acronym: 'RUSBL',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'scott.ac.ke',
    name: 'Scott Christian University',
    web_page: 'http://www.scott.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLqQhKyXwVMXYTCxF6puMxdcn8CvE_L29NX1Oo4IHpcNw7L_jEGVp0CRQ',
    id: 5430,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'seku.ac.ke',
    name: 'South Eastern Kenya University',
    web_page: 'http://www.seku.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlQfxiiuxI37JrilzsKxh0HIvfkEt5Yg4nC-udR06ZfOkpMjdjRCwn5eY',
    id: 5431,
    acronym: 'SEKU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'spu.ac.ke',
    name: 'St. Pauls University',
    web_page: 'http://www.spu.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlcT9ZZtpgWDtwGD8MdtTmQKpAZRmr1buG59NBvh3HjM_AsHKzqyR3Fw',
    id: 5432,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'strathmore.edu',
    name: 'Strathmore University',
    web_page: 'http://www.strathmore.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA5UfyPBTKHtOlCZU7m3719GdFAHrci6ap8Pe1XGah0kErSInH5dGnfA',
    id: 5433,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'tukenya.ac.ke',
    name: 'Technical University of Kenya',
    web_page: 'http://www.tukenya.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrUfeycQQaSyZXK4g2VFu_1pFmWbFeSdmWATsp-Vk5DcXwFISC_m6IIaE',
    id: 5434,
    acronym: 'TUK',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'tum.ac.ke',
    name: 'Technical University of Mombasa',
    web_page: 'http://www.tum.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToXBaH0A_gDrIrEYAkz_2hCqZn-Ixrfwm-efMZeylA5mr8TK6ziqeHatE',
    id: 5435,
    acronym: 'TUM',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'ueab.ac.ke',
    name: 'University of Eastern Africa Baraton',
    web_page: 'http://www.ueab.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVsRSc_ulceRMRJZ-U3ABw0-iYMXxymzXzA3SVHWGE8PxmNDLsey5gBLo',
    id: 5436,
    acronym: 'UEAB',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'uoeld.ac.ke',
    name: 'University of Eldoret',
    web_page: 'http://www.uoeld.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy3D0mAl1KFiR1ZMqKRrUcAALQEANft2CRBBM1HfXKp-A035HrKcjPbQ',
    id: 5437,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'uonbi.ac.ke',
    name: 'University of Nairobi',
    web_page: 'http://www.uonbi.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-pEv179iOrHIOo2wjmeSQ4IyNAUWYAosrApmz9fd6BD24zH_Nr57S7ME',
    id: 5438,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'uzimauniversity.ac.ke',
    name: 'Uzima University College',
    web_page: 'http://www.uzimauniversity.ac.ke/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLaH5aK2FtinKODbKRqLRyhsZEbfYPuMvj5MM3JvERQEeIB6ZjPuUvAsk',
    id: 5440,
    acronym: 'UUC',
  },
  {
    alpha_two_code: 'KE',
    country: 'Kenya',
    domain: 'zetechcollege.com',
    name: 'Zetech College',
    web_page: 'http://www.zetechcollege.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMfSFctIa_QDYsypoGLxSUtLH3CMZhLj9XyIatVcX1saYJyldpgjDdgQ',
    id: 5441,
    acronym: 'ZC',
  },
  {
    alpha_two_code: 'KP',
    country: "Korea, Democratic People's Republic of",
    domain: 'pust.net',
    name: 'Pyongyang University of Science and Technology',
    web_page: 'http://www.pust.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn-7ULhnpmDQSfMf2478IBgRil8PGP-V6J74MYreSacxcTAGMTOFjpyvg',
    id: 5442,
    acronym: 'PUST',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'ajou.ac.kr',
    name: 'Ajou University',
    web_page: 'http://www.ajou.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcSinwjU0ITGkYkFKL4kSWs_uvnyUVpYCN4dbKKCm_tx53atjbHOSjZm8',
    id: 5443,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'andong.ac.kr',
    name: 'Andong National University',
    web_page: 'http://www.andong.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkE9tqWi_PohEw9pNK8uNMz0pxfM_44gYANC-fhFgXCtLKSoqB-CqGkA',
    id: 5444,
    acronym: 'ANU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'cataegu.ac.kr',
    name: 'Catholic University of Taegu-Hyosung',
    web_page: 'http://www.cataegu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdySVvzTgfplLIQ04gGK2Fj5qw0lHAlMsCgaiTMpuGWUoKpeDOmSd1uBQ',
    id: 5445,
    acronym: 'CUT',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'cau.ac.kr',
    name: 'Chung-Ang University',
    web_page: 'http://www.cau.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGGJrlFXjlN9EaC3nRmgAd2gAqKyqmlxmPbYjQgd9o3Oac-RSEPTAWZQ8',
    id: 5446,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'changwon.ac.kr',
    name: 'Changwon National University',
    web_page: 'http://www.changwon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdJR2R22NxX8jEaKhYG5tRw2OErZ3h9sIzrwCMJ7M2NyWJYFVtWTLhRw',
    id: 5447,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'cheju.ac.kr',
    name: 'Cheju National University',
    web_page: 'http://www.cheju.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbzq061VjVlS0ywpcZ0Jgu2c4IIxROCaA3qTEHMPYY4dtKy55Em9jC6yE',
    id: 5448,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'cheju-e.ac.kr',
    name: 'Cheju National University of Education',
    web_page: 'http://www.cheju-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo-RPZWeR-iz-lK836kp-O4uuKTxuG2vNba7PC0II_MM9O1S93JHYoux0',
    id: 5449,
    acronym: 'CNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chinju.ac.kr',
    name: 'Chinju National University',
    web_page: 'http://www.chinju.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa6XCcpsl_Efgzken8k66uf13j54_q5YxCun-ID5Xcmi5XnqTKFVrrww',
    id: 5450,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chinju-e.ac.kr',
    name: 'Chinju National University of Education',
    web_page: 'http://www.chinju-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw9TdZ9hleZVbLQyWAgrTPJR4vJ8tQ5Ezuv602csizXQM4H8gQjjvy9Q',
    id: 5451,
    acronym: 'CNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chodang.ac.kr',
    name: 'Chodang University',
    web_page: 'http://www.chodang.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOZ-keGT0qAwh2eIXCHCJToI58OvR0rYgWfl9US69hVkmoJVDVyJYyQA',
    id: 5452,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chonbuk.ac.kr',
    name: 'Chonbuk National University',
    web_page: 'http://www.chonbuk.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo-RPZWeR-iz-lK836kp-O4uuKTxuG2vNba7PC0II_MM9O1S93JHYoux0',
    id: 5453,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chongju.ac.kr',
    name: 'Chongju University',
    web_page: 'http://www.chongju.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzso4dXB5EcrpHIJ4-2Q-kQG-icAyHggwmBLsVQXYe8ZJyYwG5JGX2GAM',
    id: 5454,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chongju-e.ac.kr',
    name: 'Chongju National University of Education',
    web_page: 'http://www.chongju-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjZY5jdpmokDMeFb70guuCtCL5cJVpANO25W5ZlBJtdjIQWIynwgw3krs',
    id: 5455,
    acronym: 'CNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chonju-e.ac.kr',
    name: 'Chonju National University of Education',
    web_page: 'http://www.chonju-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP_WfW-otNn01HLqeZeGuMVXy-gbpMZaEe8XgYhhaX9tlZbP_bOYFW6bQvHQ',
    id: 5456,
    acronym: 'CNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chonnam.ac.kr',
    name: 'Chonnam National University',
    web_page: 'http://www.chonnam.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQgQMtCjzMsuDGHFuU9Rf8KsNhYvNKn60K6dosYtjh3zDqtiVgHjj4QlA',
    id: 5457,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chosun.ac.kr',
    name: 'Chosun University',
    web_page: 'http://www.chosun.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT407OMnCO0whxHRE26I0rdnugYi6GFN2M9fGcpN_D7GV9HMlZR-7q5H6E2',
    id: 5458,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chungbuk.ac.kr',
    name: 'Chungbuk National University',
    web_page: 'http://www.chungbuk.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzso4dXB5EcrpHIJ4-2Q-kQG-icAyHggwmBLsVQXYe8ZJyYwG5JGX2GAM',
    id: 5459,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chungju.ac.kr',
    name: 'Chung-Ju National University',
    web_page: 'http://www.chungju.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRj6eEGjd1CwdGQa8WvGVm75T_cmEkiPoKFauXPr2jEVdAJvfXX03vRzg',
    id: 5460,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'chungnam.ac.kr',
    name: 'Chungnam National University',
    web_page: 'http://www.chungnam.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcDdERiE1PnajmNYMSZUoh2ifPVtK5ARm6sU4k8ZCAhQ4xI1sRD6gOjA',
    id: 5461,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'cnue-e.ac.kr',
    name: 'Chunchon National University of Education',
    web_page: 'http://www.cnue-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRw9TdZ9hleZVbLQyWAgrTPJR4vJ8tQ5Ezuv602csizXQM4H8gQjjvy9Q',
    id: 5462,
    acronym: 'CNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'cuk.ac.kr',
    name: 'Catholic University of Korea',
    web_page: 'http://www.cuk.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-vz9zdZKv41B3LUVxlnHFPGR9Djww2yuS0Ysn87jqu8NXuKXpvb05Yw',
    id: 5463,
    acronym: 'CUK',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'cup.ac.kr',
    name: 'Catholic University of Pusan',
    web_page: 'http://www.cup.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqjqv0VSFFLiK38vOBtA7TagKqCK2Ybmf1A-RgOeEOnRxtYehMc4nydt4',
    id: 5464,
    acronym: 'CUP',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'daebul.ac.kr',
    name: 'Daebul University',
    web_page: 'http://www.daebul.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3DEX7bG62-yLiSSvVuTuP80CoJE2OH2k9axlQOQQQ6Ysjm_hyexL0y0M',
    id: 5465,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'dankook.ac.kr',
    name: 'Dankook University',
    web_page: 'http://www.dankook.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaKhg3qkZ8fyfah9GK37vuNplaJTpLiv252IWfAlfGxHdbRoNMAFCjv1k',
    id: 5466,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'donga.ac.kr',
    name: 'Dong-A University',
    web_page: 'http://www.donga.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4j8ZhZbd7Ysr1Yj0mkjc7rtzPYkXOpQ7CtHrOyJVNzbvTmSJiAkgPDA',
    id: 5467,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'dongduk.ac.kr',
    name: "Dongduk Women's University",
    web_page: 'http://www.dongduk.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhajl8OYZQtOpOJDgnq6SyW8hhAQ5LoI3M6Uj2BTEGlnaadojCioUBGd8',
    id: 5468,
    acronym: 'DWU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'dongeui.ac.kr',
    name: 'Dong Eui University',
    web_page: 'http://www.dongeui.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRt5LF8S6auPL9gcbGK8Z97CR7cOUVM0uF8iVP_Uk_HYEJbYv09v9Icg',
    id: 5469,
    acronym: 'DEU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'dongguk.ac.kr',
    name: 'Dongguk University',
    web_page: 'http://www.dongguk.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQo60ZHsecrDeQy5oVCA4FVyCvF99hIbiGLIv7w4zIV8flRxKIMKQaJx6I',
    id: 5470,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'dongseo.ac.kr',
    name: 'Dongseo University',
    web_page: 'http://www.dongseo.ac.kr/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQubgZO5hfpA9_cn5A1aybLkYpohUEyr8fG_qgqEDb1WeE-emaqZFWr_Ahu',
    id: 5471,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'dongshinu.ac.kr',
    name: 'Dongshin University',
    web_page: 'http://www.dongshinu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4Qmau7tl3fa3-jOtckXVGG0QkM52-1J2QeB64qL4gs7-rgircaAlIHao',
    id: 5472,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'duksung.ac.kr',
    name: "Duksung Women's University",
    web_page: 'http://www.duksung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_mj49aoSDIDQDUczeKRLsx-bfQxjWyWQRPpGV1F2z7Vh8BLH0pjKTQec',
    id: 5473,
    acronym: 'DWU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'dytc.ac.kr',
    name: 'Dong Yang University of Technology',
    web_page: 'http://www.dytc.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnrTxVqI9syMJDVpy0DcJ0E4ge0USKoVTl-HcH3rmdvEpmpm0J_986ew',
    id: 5474,
    acronym: 'DYUT',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'ewha.ac.kr',
    name: "Ewha Women's University",
    web_page: 'http://www.ewha.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLbsCBD_i3l5kfpGeaR2q6kCzWmdyht4eXsxgF3vy5rzdvDvnmnDHTEjfNkg',
    id: 5475,
    acronym: 'EWU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'gsnu.ac.kr',
    name: 'Gyeongsang National University',
    web_page: 'http://www.gsnu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSz_tbypDq9_NgVyab1mMkkeSiIhefqBJeDobPlTeinIfGOxqtNmQy_m6Y',
    id: 5476,
    acronym: 'GNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hallym.ac.kr',
    name: 'Hallym University',
    web_page: 'http://www.hallym.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFPmGzvmQIdoPHbyee4qVr22E000bK5c2pD45yZqu-p0pEE94WpVw8IJo',
    id: 5477,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hangkong.ac.kr',
    name: 'Hankuk Aviation University',
    web_page: 'http://www.hangkong.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjepZkuBc0XEvVJTy5sIKCN2iJ-g3S8_TdE_hhiys98zHGo-DGuxw2Stk',
    id: 5478,
    acronym: 'HAU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hannam.ac.kr',
    name: 'Hannam University',
    web_page: 'http://www.hannam.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWFG89yr72KFztECuH2RjcK13SZA8IxZVSYNePYz3EVeJA7q7Ga0zQnLI',
    id: 5479,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hansei.ac.kr',
    name: 'Hansei University',
    web_page: 'http://www.hansei.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUgKFtH5vF-YPCupL-aehST4JaR83G6474CyZr7riBTI_992v8RPHXcw',
    id: 5480,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hanseo.ac.kr',
    name: 'Hanseo University',
    web_page: 'http://www.hanseo.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI4SUn_SuAT_rsUJJ85vZyt9MHzuCLtaF2sytipWOa6oPHPi-WFKaP3Xo',
    id: 5481,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hanshin.ac.kr',
    name: 'Hanshin University',
    web_page: 'http://www.hanshin.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF5caRELCsTQ14xQurcjkOJ8hnYrVCplYo-BlE5gycjuZpPy2eIfJobJ8',
    id: 5482,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hansung.ac.kr',
    name: 'Hansung University Seoul',
    web_page: 'http://www.hansung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDnXHZo51DsiUuS75XgJz1H1deiDqLJkbqkaNORDVoJ1eZG3tiKqkSP4lH',
    id: 5483,
    acronym: 'HUS',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hanyang.ac.kr',
    name: 'Hanyang University',
    web_page: 'http://www.hanyang.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFgdFbQtgYZt0sEWMioOURSWRczFXJ9otltnbF4chYwCr0QQDGdTJF2g',
    id: 5484,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hknu.ac.kr',
    name: 'Hankyong National University',
    web_page: 'http://www.hknu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStilm_Bu-NpPJKsckgq-iga3gxIWr7o-0ZypCrRHLIIfE27BfcPC-iI0U',
    id: 5485,
    acronym: 'HNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'honam.ac.kr',
    name: 'Honam University',
    web_page: 'http://www.honam.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQgQMtCjzMsuDGHFuU9Rf8KsNhYvNKn60K6dosYtjh3zDqtiVgHjj4QlA',
    id: 5486,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hongik.ac.kr',
    name: 'Hongik University',
    web_page: 'http://www.hongik.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0OGi_zzZ2PysvNslSHZNxwB0ktdDyCMudNmmmDpuSnEH3Ba6xaH2QDQ',
    id: 5487,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hoseo.ac.kr',
    name: 'Hoseo University',
    web_page: 'http://www.hoseo.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSN_b8CP6d85Pm0DtdmIInlt5WsBKGL9XGorTqP_EZ51dHd5xyQbfOPmn0',
    id: 5488,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'howon.ac.kr',
    name: 'Chonbuk Sanup University of Technology (Howon University)',
    web_page: 'http://www.howon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1vrXBj2U3sumiengTs0WRFFM08O0yZGU31QxRpqtJHOHLic-OuCZtKw',
    id: 5489,
    acronym: 'CSUT(U',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hufs.ac.kr',
    name: 'Hankuk University of Foreign Studies',
    web_page: 'http://www.hufs.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjepZkuBc0XEvVJTy5sIKCN2iJ-g3S8_TdE_hhiys98zHGo-DGuxw2Stk',
    id: 5490,
    acronym: 'HUFS',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'hyupsung.ac.kr',
    name: 'Hyupsung University',
    web_page: 'http://www.hyupsung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-J38HJVbMdY3kmcJaxE07oOmcahBDCVe0EVkbSgiNs2oP17Tr_9adG1a1',
    id: 5491,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'icu.ac.kr',
    name: 'Information and Communications University',
    web_page: 'http://www.icu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7fxcpmG6PrF5KzmNdOKIk0-ZRJI5EryQpa6rDEeD9VoBpyOtidMX4ey0',
    id: 5492,
    acronym: 'ICU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'inchon.ac.kr',
    name: 'Inchon University',
    web_page: 'http://www.inchon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSw-3uGw4o0v5tQxTZb_Ubjv1XB5MpxqlZaPORh0GstvbROwI7JsiVoFaw',
    id: 5493,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'inchon-e.ac.kr',
    name: 'Inchon National University of Education',
    web_page: 'http://www.inchon-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSw-3uGw4o0v5tQxTZb_Ubjv1XB5MpxqlZaPORh0GstvbROwI7JsiVoFaw',
    id: 5494,
    acronym: 'INUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'inha.ac.kr',
    name: 'Inha University',
    web_page: 'http://www.inha.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaZdste8t3-7Ny85d_cB0jSJEDc1eX8BmbxS1xcxGn3uOcUimASilZXMo',
    id: 5495,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'inje.ac.kr',
    name: 'Inje University',
    web_page: 'http://www.inje.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiwQeHKimXHBvx0mTOdXlVIodN-k9P-ubHba8iM85vLJYLw2owkYaiq_pz',
    id: 5496,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'jeonju.ac.kr',
    name: 'Jeonju University',
    web_page: 'http://www.jeonju.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ03IUX8jtDjK9lq5aSwtWd1vBImTDjt3NU8qTyBJX9OHrmQr8DaPF-Ww',
    id: 5497,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kaist.ac.kr',
    name: 'Korea Advanced Institute of Science & Technology',
    web_page: 'http://www.kaist.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZgREqrDiBt6anRPdSqmfoHfrjBphgqk9hb2Q97fSK7DKtreeLL7fMyww',
    id: 5498,
    acronym: 'KAIS&T',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kangnam.ac.kr',
    name: 'Kangnam University',
    web_page: 'http://www.kangnam.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC36uK2kbkg-Z5PTKibWMVEuvktlGPfX_qL2Wm7lBzZMLbJ-ncwymjWh4',
    id: 5499,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kangnung.ac.kr',
    name: 'Kangnung National University',
    web_page: 'http://www.kangnung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJs7wdzjORR40768M8jrpWwn6HFycN6kEO3opNVeY_t56VDQ-Sl4_QVkEw',
    id: 5500,
    acronym: 'KNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kangwon.ac.kr',
    name: 'Kangwon National University',
    web_page: 'http://www.kangwon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6374WmZWgNPXkW1TLoKdLI8-dz0c00Pvkvmr96AkB2tGO0hfMFmK34So',
    id: 5501,
    acronym: 'KNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kaya.ac.kr',
    name: 'Kaya University',
    web_page: 'http://www.kaya.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ985LDi9YCLVLEwt5bzYA-WGviWLvzDJq7oHe8kmUaYA_vzLywMDk-4fw',
    id: 5502,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kdischool.ac.kr',
    name: 'KDI School of Public Policy and Management',
    web_page: 'http://www.kdischool.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbPtmRflKxhlW4C60cmGfPeXeY7j7phvBBEazk_BzZnEQwbedwQoRQeBY',
    id: 5503,
    acronym: 'KSPPM',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'keimyung.ac.kr',
    name: 'Keimyung University',
    web_page: 'http://www.keimyung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTDx4oGLQSkAmkw6C_dw68WPzE4U2pgrE34KfIHK9B6gEpQbmFCngnRQ',
    id: 5504,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kmaritime.ac.kr',
    name: 'Korea Maritime University',
    web_page: 'http://www.kmaritime.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX4tk9HVMzM3tniBgJTyOE6e9CIRDR4o9JyIs4qpHNfnMEn7_9NYFcLf0',
    id: 5505,
    acronym: 'KMU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'knou.ac.kr',
    name: 'Korea National Open University',
    web_page: 'http://www.knou.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRk6G3WGHss3NVyFWempQVf57Y7Gsccxqxx_YyveZzr7V_CN-ZaiAGVxawZkw',
    id: 5506,
    acronym: 'KNOU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'knua.ac.kr',
    name: 'Korea National University of Arts',
    web_page: 'http://www.knua.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbJhKpoGMQylSFXaB4Ni9G7j0lLjZ4-56jWVg3PMsb-fqgNzJkKMRHLg',
    id: 5507,
    acronym: 'KNUA',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'knue.ac.kr',
    name: 'Korea National University of Education',
    web_page: 'http://www.knue.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDrMBbgscaiQ4_J4xQeDal88wmTnVEeUJrlbsmJ9YrM_B-YuwAvk08H6o',
    id: 5508,
    acronym: 'KNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'knupe.ac.kr',
    name: 'Korea National University of Physical Education',
    web_page: 'http://www.knupe.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDrMBbgscaiQ4_J4xQeDal88wmTnVEeUJrlbsmJ9YrM_B-YuwAvk08H6o',
    id: 5509,
    acronym: 'KNUPE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kongju-e.ac.kr',
    name: 'Kongju National University of Education',
    web_page: 'http://www.kongju-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQlTgXZEfl72_HT1Hvgiew7YtMFPKov_lC72C2rmJNOpQ3yAsm3FCAGPf5',
    id: 5510,
    acronym: 'KNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'konkuk.ac.kr',
    name: 'Konkuk University',
    web_page: 'http://www.konkuk.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGUDsfIjHFraBbwEga7j3z_qDYVDa2KuYyW-WSUSV0Pl7St-Ew4jgd6A',
    id: 5511,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'konyang.ac.kr',
    name: 'Konyang University',
    web_page: 'http://www.konyang.ac.kr/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTO1BM7aPszpg2R4i4kRywZwLALs6KFGih73wpzkutFAPGiZj5uJ3rTU80',
    id: 5512,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kookmin.ac.kr',
    name: 'Kookmin University',
    web_page: 'http://www.kookmin.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbzgknByraH6HR4JwZ1LwkIOvEhYF5q6tY9llchjK_SYfV7cri_7SZ06E',
    id: 5513,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kosin.ac.kr',
    name: 'Kosin University',
    web_page: 'http://www.kosin.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKHeEjFLUPiwcX4YGQQ4jyaWUH3yYEZVxRCq41Cx2avAdbIuPL23QUvCQ',
    id: 5515,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kumoh.ac.kr',
    name: 'Kumoh National University of Technology',
    web_page: 'http://www.kumoh.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_cfbqT97fkYIDrG4j9MBBqzY6MYUXqXgSG_cc3iwKfsnjKHcR2tNqbks',
    id: 5516,
    acronym: 'KNUT',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kunsan.ac.kr',
    name: 'Kunsan National University',
    web_page: 'http://www.kunsan.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQgQMtCjzMsuDGHFuU9Rf8KsNhYvNKn60K6dosYtjh3zDqtiVgHjj4QlA',
    id: 5517,
    acronym: 'KNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kwandong.ac.kr',
    name: 'Kwan Dong University',
    web_page: 'http://www.kwandong.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVi030Ku0d1gX6GnNLVu7ho9lqbhkPrDyRyeXaTTBHX8MKeZevfDpQDg',
    id: 5518,
    acronym: 'KDU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kwangju.ac.kr',
    name: 'Kwangju University',
    web_page: 'http://www.kwangju.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuL_FiK3IvWy8IXazP76crx3YWhRvMaEtE1kthtf6UaSty-r7OZmNf1Q',
    id: 5519,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kwangju-e.ac.kr',
    name: 'Kwangju National University of Education',
    web_page: 'http://www.kwangju-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQgQMtCjzMsuDGHFuU9Rf8KsNhYvNKn60K6dosYtjh3zDqtiVgHjj4QlA',
    id: 5520,
    acronym: 'KNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kwangwoon.ac.kr',
    name: 'Kwangwoon University',
    web_page: 'http://www.kwangwoon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpeBntWtonBggeGvVon6tuJF_p4JHytSmwo4UC_K81Bapra5cXrkWlPQ',
    id: 5521,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kyonggi.ac.kr',
    name: 'Kyonggi University',
    web_page: 'http://www.kyonggi.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6VerczpnLT_R73iB0Pae3wt2Y1pYF9GjQNZs0kKlreplbiWBscgTAcg',
    id: 5522,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kyongju.ac.kr',
    name: 'Kongju National University',
    web_page: 'http://www.kyongju.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMD9ONVd-lhCx4vhqDXSBDotYzBrthscC_OWXT8gZMqUJLp4BblAlKJw',
    id: 5523,
    acronym: 'KNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kyunghee.ac.kr',
    name: 'Kyunghee University',
    web_page: 'http://www.kyunghee.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr-jDxNMUh-o2Kx7QdxNJ33OvoQCLUGn7mwzNKPQkgFfuoGXJnVl8D5dI',
    id: 5524,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kyungil.ac.kr',
    name: 'Kyungil University',
    web_page: 'http://www.kyungil.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN4E2WF5QCAOYtOiLwZ4KEom_lRPya5CaAaSdaSmU7ighrXE-Xoh7vLL8',
    id: 5525,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kyungnam.ac.kr',
    name: 'Kyungnam University',
    web_page: 'http://www.kyungnam.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXnVd_BLwbKxG6dTnT7pTAzYSjFS3Xh94q8X3K0N51vQLoddVuEq0pl8Op',
    id: 5526,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kyungpook.ac.kr',
    name: 'Kyungpook National University',
    web_page: 'http://www.kyungpook.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoTfN0qR9DH8tU7MkHSDVLf1LOWpgeBtcOEvQs2jtvAnwD3kBxU1hUDz8',
    id: 5527,
    acronym: 'KNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kyungsung.ac.kr',
    name: 'Kyungsung University',
    web_page: 'http://www.kyungsung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfSrHwJoWmuUR4YYw-roUGRrygqwc6gn8pvv-XMIfAd1sW8MooGT8Vbp0',
    id: 5528,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'kyungwon.ac.kr',
    name: 'Kyungwon University',
    web_page: 'http://www.kyungwon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY8wq4XarQPuqIIQ7dlVx0By0QdaLkdO6Y89yNmdL9g1qXc1q_NxAoYA',
    id: 5529,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'miryang.ac.kr',
    name: 'Miryang National University',
    web_page: 'http://www.miryang.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0rwulcf2oAm_q416xUtf7_AabfCmQOMHAZWTiECjGH95MmZdNMk3HIw',
    id: 5530,
    acronym: 'MNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'mmu.ac.kr',
    name: 'Mokpo National Maritime University',
    web_page: 'http://www.mmu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgjVYTIf9XqUH0PpDMdWyuOysz9hD6f_gadn5yEzfvzCxJ2SAMfwXatw',
    id: 5531,
    acronym: 'MNMU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'mokpo.ac.kr',
    name: 'Mokpo National University',
    web_page: 'http://www.mokpo.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQgQMtCjzMsuDGHFuU9Rf8KsNhYvNKn60K6dosYtjh3zDqtiVgHjj4QlA',
    id: 5532,
    acronym: 'MNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'mokwon.ac.kr',
    name: 'Mokwon University Taejon',
    web_page: 'http://www.mokwon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ-F8htwW4-yj2Quj8JhnME-PNNGppG6dHcAWay7c1gFUF3bGwE_qeRTk',
    id: 5533,
    acronym: 'MUT',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'myongji.ac.kr',
    name: 'Myongji University',
    web_page: 'http://www.myongji.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnHhM02lO1Qe6G7KPWH94y40upTi40PB2bibLJ2k_npA1K2R8mc_s96Tc',
    id: 5534,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'paichai.ac.kr',
    name: 'Paichai University',
    web_page: 'http://www.paichai.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCzI6spHf_whY717Forz56mxiQciats-Q_Wdnq_z46hu_5WX6VTP0I4v4',
    id: 5535,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'pknu.ac.kr',
    name: 'Pukyong National University',
    web_page: 'http://www.pknu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQabaq6Mt0EkO79xqHzxbWqKA7-btflvqlO9Wq_EKQPWKfxesDZgqpUDI',
    id: 5536,
    acronym: 'PNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'postech.ac.kr',
    name: 'Pohang University of Science and Technology',
    web_page: 'http://www.postech.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvaTfbxRW7fpGbf3xUsLLRThu2knTRUx630kZNUOcr8dYQEFU5u2ff07U',
    id: 5537,
    acronym: 'PUST',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'ptuniv.ac.kr',
    name: 'Pyongtaek University',
    web_page: 'http://www.ptuniv.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFytmHKh5TXY7jDyr-DSEQNno8tBnIOnmEtfRDhIV2wXqfZxmEASJnXQ',
    id: 5538,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'pufs.ac.kr',
    name: 'Pusan University of Foreign Studies',
    web_page: 'http://www.pufs.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQciP_X1W19klBuQu-Ya_uUbo0X9GtIDQJRrWl3gERen5fShYMEXE9xCw',
    id: 5539,
    acronym: 'PUFS',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'pusan.ac.kr',
    name: 'Pusan National University',
    web_page: 'http://www.pusan.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKJ-EabWSTfEnKg9cFPUIRMUeZSRXOhzf5u_Nsl_jWRsKLdhwKlZJfZlDf',
    id: 5540,
    acronym: 'PNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'pusan-e.ac.kr',
    name: 'Pusan National University of Education',
    web_page: 'http://www.pusan-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPhfmPBG_OVQbYtEgpWGty8eIhnu89etKjbg4JrxkN0zafCR7RdrMuvg',
    id: 5541,
    acronym: 'PNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'pwc.ac.kr',
    name: "Pusan Women's University",
    web_page: 'http://www.pwc.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKJ-EabWSTfEnKg9cFPUIRMUeZSRXOhzf5u_Nsl_jWRsKLdhwKlZJfZlDf',
    id: 5542,
    acronym: 'PWU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'samchok.ac.kr',
    name: 'Samchok National University',
    web_page: 'http://www.samchok.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0rwulcf2oAm_q416xUtf7_AabfCmQOMHAZWTiECjGH95MmZdNMk3HIw',
    id: 5543,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sangji.ac.kr',
    name: 'Sang Ji University',
    web_page: 'http://www.sangji.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKfDG171QwNBxu5t5cIgfRDp9Yb9nORiWc8Zigl34CzQaYSidOK9TCdp4',
    id: 5544,
    acronym: 'SJU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sangmyung.ac.kr',
    name: 'Sang Myung University',
    web_page: 'http://www.sangmyung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9xIJL33EfVrLxZzD6JrRqVAJrMMoS5wQifbu6IgnnzEp9UBzgzNkjhwc',
    id: 5545,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sch.ac.kr',
    name: 'Soonchunhyang University',
    web_page: 'http://www.sch.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhDybe4FLHbOAVEuo3i8crYRhvfH_sPgjpufkol5SeTnUmwwZG8p-tCLuT',
    id: 5546,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sejong.ac.kr',
    name: 'Sejong University',
    web_page: 'http://www.sejong.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMzzD5ZwpEAyK0ywY-4b4W94LKt3KfjTy6j8QAHSqUKyoHg3A4rA133h4',
    id: 5547,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'semyung.ac.kr',
    name: 'Semyung University',
    web_page: 'http://www.semyung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdd59BPlCnavlUQiJzAslGF_KcsZKN-D7UbaiJTvel2fKf-mymrXirvLM',
    id: 5548,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'seokyeong.ac.kr',
    name: 'Seokyeong University',
    web_page: 'http://www.seokyeong.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwAfXWySNbT0dIVmx7Q7twskYG9kxVJG_Y1AFXTpGv-M-w18K5v7RJKl0',
    id: 5549,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'seonam.ac.kr',
    name: 'Seonam University',
    web_page: 'http://www.seonam.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQgQMtCjzMsuDGHFuU9Rf8KsNhYvNKn60K6dosYtjh3zDqtiVgHjj4QlA',
    id: 5550,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'seoul-e.ac.kr',
    name: 'Seoul National University of Education',
    web_page: 'http://www.seoul-e.ac.kr/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTtKw9gVFhKmIh93fQQqH3RhzzWbb0a7R2_7l8jpPEPi6nGKI9v7dzRDtY',
    id: 5551,
    acronym: 'SNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'seowon.ac.kr',
    name: 'Seowon University',
    web_page: 'http://www.seowon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd_TQTIBkRLJCe5LLZKDfMiWUVzHZFJvtWBjQlK5dinaJV66JDa1NPYg',
    id: 5552,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'silla.ac.kr',
    name: 'Silla University',
    web_page: 'http://www.silla.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyo0gJCGBglFrVwYPnKXyNeWKvfn0UtuQ34Obz-1ZyNiMNXqYcMWk6gQ',
    id: 5553,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'skku.ac.kr',
    name: 'Sung Kyun Kwan University',
    web_page: 'http://www.skku.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQy82tz4mj7p14KgXICU4ELEH2ojwENcDaYFSxpjL-QwURk4R_4_GJAw',
    id: 5554,
    acronym: 'SKKU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'snu.ac.kr',
    name: 'Seoul National University',
    web_page: 'http://www.snu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtKw9gVFhKmIh93fQQqH3RhzzWbb0a7R2_7l8jpPEPi6nGKI9v7dzRDtY',
    id: 5556,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'snut.ac.kr',
    name: 'Seoul National University of Technology',
    web_page: 'http://www.snut.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQadHbH8hsRVTvQPmP41cNkMN2hCHgEuYuPfJ-c5gVzfPZ39wvGVfCjglu9',
    id: 5557,
    acronym: 'SNUT',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sogang.ac.kr',
    name: 'Sogang University',
    web_page: 'http://www.sogang.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6LaVRpI0YL6ZYGXTgYBX5Tptz7QJm-YHSEev6neGsWgTJZ8A0OAHQ',
    id: 5558,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sookmyung.ac.kr',
    name: "Sookmyung Women's University",
    web_page: 'http://www.sookmyung.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUkpvINdGVoq2LkZOzeBKcfQpU8Fi-7wD_zX1Ge1_bYBMKl4CP22bwgA',
    id: 5559,
    acronym: 'SWU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'soongsil.ac.kr',
    name: 'Soongsil University',
    web_page: 'http://www.soongsil.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWEkatXpHbGZaniknKP1uk9u8wdrsdCnrvutUHnZIwe2wERqNDpE7c7g',
    id: 5560,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'stu.ac.kr',
    name: 'Seoul Teological University',
    web_page: 'http://www.stu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyYSZWyuPHmKs497bsfvridNzEwpIQCRRwo2np3xc5JJgCWPenyfwXEXo',
    id: 5561,
    acronym: 'STU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sunchon.ac.kr',
    name: 'Sunchon National University',
    web_page: 'http://www.sunchon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWmvHAFjESlRaMwht9PVIcQ_a9KYDJNtjyvC4tNI1Q1UPPQV6L2k16JQY',
    id: 5562,
    acronym: 'SNU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sungkyul.ac.kr',
    name: 'Sungkyul University',
    web_page: 'http://www.sungkyul.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc4pgWB6L3pYzUFOMdXvmcux-9nLD0YuafX2WasLSOxH0XjD50cDmITos',
    id: 5563,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sungshin.ac.kr',
    name: "Sungshin Women's University",
    web_page: 'http://www.sungshin.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQocR9V3bLGf_nQwz9OHXNdZkw3FnYLqtIfrHPasmZtPh3BZkde6QOAE4k',
    id: 5564,
    acronym: 'SWU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'sunmoon.ac.kr',
    name: 'Sunmoon University',
    web_page: 'http://www.sunmoon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2Btu8O6DYymxfjUj5_RPE-D1H50u3A18YLjTOj-qEvCe3ZBN3CtjVPw',
    id: 5565,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'suwon.ac.kr',
    name: 'Suwon University',
    web_page: 'http://www.suwon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGyb6kzS3HVC9J50wIJfs_xiZzZb-3LBFynz4Eg1tYqd58c6LY7rtPKLU',
    id: 5566,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'suwoncatholic.ac.kr',
    name: 'Suwon Catholic University',
    web_page: 'http://www.suwoncatholic.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGyb6kzS3HVC9J50wIJfs_xiZzZb-3LBFynz4Eg1tYqd58c6LY7rtPKLU',
    id: 5567,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'swu.ac.kr',
    name: "Seoul Women's University",
    web_page: 'http://www.swu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ8zWyuyye-kdsm2JzKk00-OM6eG1qdkmd-IP0iIhaxMNATCaLoj4Yu58',
    id: 5568,
    acronym: 'SWU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'syu.ac.kr',
    name: 'Sahmyook University',
    web_page: 'http://www.syu.ac.kr/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcReJwlBz0NBWOqY8u42ZV3_k_RBYYERjZiOcYsTRIaF89c4_Y5a2wKCBMU',
    id: 5569,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'taegu.ac.kr',
    name: 'Taegu University',
    web_page: 'http://www.taegu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSor2BkJ8FjBMXdBoyPEKubh61XasTYBE2VNO2XO5taZ7aQ6kKupL4S-os',
    id: 5570,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'taegu-e.ac.kr',
    name: 'Taegu National University of Education',
    web_page: 'http://www.taegu-e.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRG6XBROqMdxEXc082y5ehV39ZfWSrXQcs2O6hxzhqGw8SUw8pC4t404g',
    id: 5571,
    acronym: 'TNUE',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'taejon.ac.kr',
    name: 'Taejon University',
    web_page: 'http://www.taejon.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTScpj7nDD1fJdc8aIwJ5tODao_qwwpY20J9KHDJRf9jPepdkJkArFkIA',
    id: 5572,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'taeshin.ac.kr',
    name: 'Taeshin Christian University',
    web_page: 'http://www.taeshin.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgGuFkyiOrNCbs7ec9XCXOgYvOzr71AV8kFW8RyH8C2wXKbfxowV_GwD8',
    id: 5573,
    acronym: 'TCU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'tit.ac.kr',
    name: 'Tongmyung University of Information Technology',
    web_page: 'http://www.tit.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 5574,
    acronym: 'TUIT',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'tnut.ac.kr',
    name: 'Taejon National University of Technology',
    web_page: 'http://www.tnut.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVrlHq0H4CPxnHyN5AILVhllKdp4FbJHe7x865R6kmoeo1qRWXJgxXnqg',
    id: 5575,
    acronym: 'TNUT',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'ulsan.ac.kr',
    name: 'Ulsan University',
    web_page: 'http://www.ulsan.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9NBuHq805-Slj6kvUh17xqcZbR5OX9IluoKgW3QM6bqTXQAyP1Hv0Dg',
    id: 5576,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'uos.ac.kr',
    name: 'Seoul City University',
    web_page: 'http://www.uos.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqrFCsfdpx_GG7m-H2YAtNNB40xfJzBOmmxX86sir_bIkZhjYwh54eXVk',
    id: 5577,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'ust.ac.kr',
    name: 'University of Science and Technology',
    web_page: 'http://www.ust.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvaTfbxRW7fpGbf3xUsLLRThu2knTRUx630kZNUOcr8dYQEFU5u2ff07U',
    id: 5578,
    acronym: 'UST',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'wonkwang.ac.kr',
    name: 'Wonkwang University',
    web_page: 'http://www.wonkwang.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWC2zzzQenMMOhQrLLni7edsEk3tdRapgdnwyKjbwv7NPt27NTLK6RaTQ',
    id: 5579,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'woosuk.ac.kr',
    name: 'Woosuk University',
    web_page: 'http://www.woosuk.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQurySKpDycJnQ77iQD3u6O14H8CycMJv54ENTE0HQZSjsiv4H3TYdlw',
    id: 5580,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'yeungnam.ac.kr',
    name: 'Yeungnam University',
    web_page: 'http://www.yeungnam.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-01ftrqehq1lFmepHHIyF4AC0KDwUS_FrZnW0jtLl2Acc1DPUzXQVFpk',
    id: 5581,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'yonsei.ac.kr',
    name: 'Yonsei University',
    web_page: 'http://www.yonsei.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzATxqadJjpfjCWfZ0TyFNfdV3YoZkGYyrnc8P3sdtio5xA616pkiEOe0',
    id: 5582,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'KR',
    country: 'Korea, Republic of',
    domain: 'yosu.ac.kr',
    name: 'Yosu National University',
    web_page: 'http://www.yosu.ac.kr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0rwulcf2oAm_q416xUtf7_AabfCmQOMHAZWTiECjGH95MmZdNMk3HIw',
    id: 5583,
    acronym: 'YNU',
  },
  {
    alpha_two_code: 'XK',
    country: 'Kosovo',
    domain: 'aukonline.org',
    name: 'American University In Kosovo',
    web_page: 'http://www.aukonline.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm2gAVW3N9sIZ9Lxdjcd25TnIBOTmnr-Oua8t5DLZLwt2orUMauO4RgcI',
    id: 5584,
    acronym: 'AUIK',
  },
  {
    alpha_two_code: 'XK',
    country: 'Kosovo',
    domain: 'uiliria.org',
    name: 'ILIRIA College',
    web_page: 'http://www.uiliria.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9thLheKGgOTthet7ohf3s0C2pMXPkY24alovENYOx2aCLOVGAFbotQg',
    id: 5585,
    acronym: 'IC',
  },
  {
    alpha_two_code: 'XK',
    country: 'Kosovo',
    domain: 'uni-pr.edu',
    name: 'University of Prishtina',
    web_page: 'http://www.uni-pr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd7U8QkLq-o3h2gZvLesnUFj1t0PEThVzFCs55rY6q_WE5_ot8BTqmWg',
    id: 5586,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'XK',
    country: 'Kosovo',
    domain: 'uni-prizren.com',
    name: 'University of Prizren "Ukshin Hoti"',
    web_page: 'http://www.uni-prizren.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqn6S8TL0_PREjTQ9kgg7IdhL3L6o4Snm8SLWi3hQyRcI7eZ8uDmftEvA',
    id: 5587,
    acronym: 'UP"H',
  },
  {
    alpha_two_code: 'XK',
    country: 'Kosovo',
    domain: 'universum-ks.org',
    name: 'Universum College',
    web_page: 'http://www.universum-ks.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_EM7OKKd6o2w0nR484FU5aNDv25ypSLzzvJ0x-odE4d3YmdVQRmGGwaQ',
    id: 5588,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'KW',
    country: 'Kuwait',
    domain: 'ackonline.com',
    name: 'Australlian College of Kuwait',
    web_page: 'http://www.ackonline.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC99KejSsDiNyQ8sen61kxBwfO6oN7R-Wl0EZcR6ioCl9IgdMKsmaxuro',
    id: 5589,
    acronym: 'ACK',
  },
  {
    alpha_two_code: 'KW',
    country: 'Kuwait',
    domain: 'aou.edu.kw',
    name: 'Arab Open University Kuwait Branch',
    web_page: 'http://www.aou.edu.kw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT31ZnpdgS2SpRR16ZSuTrHradQaGqmY45bf0Ywvry5VIUUXu9SJpnBA',
    id: 5590,
    acronym: 'AOUKB',
  },
  {
    alpha_two_code: 'KW',
    country: 'Kuwait',
    domain: 'auk.edu.kw',
    name: 'American University of Kuwait',
    web_page: 'http://www.auk.edu.kw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPusvsYW7z1JcH_9ryK-_U6FL2bcu3CL8KSmjzKPU70LmhtT-HkwDV7w',
    id: 5591,
    acronym: 'AUK',
  },
  {
    alpha_two_code: 'KW',
    country: 'Kuwait',
    domain: 'aum.edu.kw',
    name: 'American University of Middle East',
    web_page: 'http://www.aum.edu.kw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfHE9cXnfG52-zwdx5DpZJ-DfDDneGXpWJVHTGb59u9G11A4q6z4sQusw',
    id: 5592,
    acronym: 'AUME',
  },
  {
    alpha_two_code: 'KW',
    country: 'Kuwait',
    domain: 'gust.edu.kw',
    name: 'Gulf University for Science and Technology',
    web_page: 'http://www.gust.edu.kw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgArAC2r5XxkgAUabtQSFcgnCnchcpxc4PmTXeoHwBLRW-6BjzESBR_A',
    id: 5593,
    acronym: 'GUFST',
  },
  {
    alpha_two_code: 'KW',
    country: 'Kuwait',
    domain: 'kilaw.edu.kw',
    name: 'Kuwait International Law School',
    web_page: 'http://www.kilaw.edu.kw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXnWlBMUaNQ90W4GunyAMKJOSvXRFpsQJAi5AbYddWOCfIAsvJ-g8bjG8',
    id: 5594,
    acronym: 'KILS',
  },
  {
    alpha_two_code: 'KW',
    country: 'Kuwait',
    domain: 'kmbs.edu.kw',
    name: 'Kuwait Maastricht Business School',
    web_page: 'http://www.kmbs.edu.kw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmLtvTSsSa8557XUs_KzvmVpa4kx-bbCBsUVl0trZ4CqtLlRjZ9uPlZeix',
    id: 5595,
    acronym: 'KMBS',
  },
  {
    alpha_two_code: 'KW',
    country: 'Kuwait',
    domain: 'kuniv.edu.kw',
    name: 'Kuwait University',
    web_page: 'http://www.kuniv.edu.kw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp4TNZGk_KdRDZmazOI-UAeqqbJpddibR8aOsMEoT8Cd29JMRB1p-IIgA',
    id: 5596,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'asmi.edu.kg',
    name: 'Asian Medical Institute',
    web_page: 'http://www.asmi.edu.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxzE90QULZ8Ohodqo95MEUBMt434o4Ei3IZdksVB00Fri8Xn5UpcmgQZRq',
    id: 5597,
    acronym: 'AMI',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'auca.kg',
    name: 'American University of Central Asia',
    web_page: 'http://www.auca.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYLISL25s9ARX0TZv6Rq3IbgXwFEaYhNEQ0FgGJdssj7F4v7ZbyvqO6g',
    id: 5598,
    acronym: 'AUCA',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'bhu.kg',
    name: 'Bishkek Humanities University',
    web_page: 'http://www.bhu.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtE651TfcyXKzWjuNjD8oju5wLkJj25_3YdrPkoFwaBdTsPh463iiMjQ',
    id: 5599,
    acronym: 'BHU',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'iaau.edu.kg',
    name: 'International Ataturk Alatoo University',
    web_page: 'http://www.iaau.edu.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ43XPczdV-pQvzoNkdTjiFnFsDdsFDj7DLHF9CFDcMbVQQR4XZ35RHZTEh',
    id: 5600,
    acronym: 'IAAU',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'iuk.kg',
    name: 'International University of Kyrgyzstan',
    web_page: 'http://www.iuk.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYDKfkCaOemQfKZNXfSz75x9VjaO1IWarDBachxQvqstzuekoTzzsUPA8',
    id: 5601,
    acronym: 'IUK',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'jasu.edu.kg',
    name: 'Jalalabad State University Kyrgyzstan',
    web_page: 'http://www.jasu.edu.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxft4nDMXZ1OJgg8C1NCVN12DmvQKqvcXFxtJpsTMKQ9IxU0a3yp1m0Q',
    id: 5602,
    acronym: 'JSUK',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'knau.kg',
    name: 'Kyrgyz National Agrarian University',
    web_page: 'http://www.knau.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVuZ4VL5FGlMgqF2yDel3w9RMQU3_-JlSxMVTGCFedpE1TLPvYN1xk1KQ',
    id: 5603,
    acronym: 'KNAU',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'krsu.edu.kg',
    name: 'Kyrgyz Russian Slavic University',
    web_page: 'http://www.krsu.edu.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4mX1snHamuM5TaIGXrJToRXq181ZrH06QRffyMuheABf59p33pd6sVw',
    id: 5604,
    acronym: 'KRSU',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'kstu.kg',
    name: 'Kyrgyz State Technical University',
    web_page: 'http://www.kstu.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ8HNhIQkwVyWsakxRoZ7r2U52hPH6-2ag6yiHK2RCbSH4KxQOrUo2EsbS',
    id: 5605,
    acronym: 'KSTU',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'ksucta.kg',
    name: 'Kyrgyz State University of Construction Transportation and Architecture',
    web_page: 'http://www.ksucta.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB1uWfp8jKNCLK01dZxLZs5td879hcft5hdpiRznERQbsvMNYYgz12jnI',
    id: 5606,
    acronym: 'KSUCTA',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'manas.kg',
    name: 'Kyrgyz Turkish  Manas University',
    web_page: 'http://www.manas.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2S7od2NDKfEznlbeHIw1toSYrko9AcptRJRft6jgunt8X3kw-LyBZwUM',
    id: 5607,
    acronym: 'KTMU',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'oshmed.com',
    name: 'Medical Faculty Osh State University',
    web_page: 'http://www.oshmed.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8fxqlUasHCLUSbg5p6YLoTiO7b-iIlcJojPA5W4OTOaTbSXDh0Ws0_hc4',
    id: 5608,
    acronym: 'MFOSU',
  },
  {
    alpha_two_code: 'KG',
    country: 'Kyrgyzstan',
    domain: 'university.kg',
    name: 'Kyrgyz National University',
    web_page: 'http://www.university.kg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiCgQhYBoEhUcoJ-QCYw-Is2k0hbXt01XQtgPE6c9EQlddMO_1GMqu5_c',
    id: 5609,
    acronym: 'KNU',
  },
  {
    alpha_two_code: 'LA',
    country: "Lao People's Democratic Republic",
    domain: 'nuol.edu.la',
    name: 'National University of Laos',
    web_page: 'http://www.nuol.edu.la/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1aDtR3qQnA_qf27xxRNXCefRAkRXcIKqnp8pEe0U0ronJlalOkrNvKSE',
    id: 5610,
    acronym: 'NUL',
  },
  {
    alpha_two_code: 'LA',
    country: "Lao People's Democratic Republic",
    domain: 'simt.edu.la',
    name: 'Soutsaka Institute of management and Technology',
    web_page: 'http://www.simt.edu.la/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmmR_CLid2IEGfmHGWpI834sIR68x0eFzW7wxHdfh1Xp7hKtPzOK1rYyCS',
    id: 5611,
    acronym: 'SIMT',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'aml.lv',
    name: 'Medical Academy of Latvia',
    web_page: 'http://www.aml.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5Gk8D-HxLT8J30t9RRM3LnrtrYQJ-ml9rQAylkw3KqztmNNfe8tXHvA',
    id: 5612,
    acronym: 'MAL',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'ba.lv',
    name: 'School of Business and Finance',
    web_page: 'http://www.ba.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNmDpebHJShDdNsQhSL6mif5rJBL3swYOvi8ca5PwkOi3NtSFDGc-mBw',
    id: 5613,
    acronym: 'SBF',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'bsa.edu.lv',
    name: 'Baltic International Academy',
    web_page: 'http://www.bsa.edu.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkfjNLg9oMO_9XIjqt8HQxNdPJ9m247NnEYjmMw5npBV4EEm5ORnc22zU',
    id: 5614,
    acronym: 'BIA',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'cs.lpu.lv',
    name: 'Liepaja Pedagogical Higher School',
    web_page: 'http://www.cs.lpu.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4fGiBVTRlql1UEZYD27bc1Q_m3pW9DrTsR7NUgtkCtcriL-MtcgkedEg',
    id: 5615,
    acronym: 'LPHS',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'dau.lv',
    name: 'Daugavpils University',
    web_page: 'http://www.dau.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmRoL6NgLI6TAVordb2ufUuEwsONh9bb6uEVdYwJ8rzi5vgBr884i5TQ',
    id: 5616,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'lama.lv',
    name: 'Latvian Maritime Academy',
    web_page: 'http://www.lama.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCZBlsIK3Lv8zzaGi4MZcB4KG6CuRaMg7N1b9V7zxlt1c1A19ZdTFUzRE',
    id: 5617,
    acronym: 'LMA',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'lka.edu.lv',
    name: 'Latvian Academy of Culture',
    web_page: 'http://www.lka.edu.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNSmplUY0miIjNul67KYRv4X24ojaA5BVrSGMjUMocAtthJU9VhJRB2q8',
    id: 5618,
    acronym: 'LAC',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'llu.lv',
    name: 'Latvian University of Agriculture',
    web_page: 'http://www.llu.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV7XY_bX74_T_qDnG5BWnKQumJMoBQ4Go4KUmAkU24uKv_6-QPi-rl-F4X',
    id: 5619,
    acronym: 'LUA',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'lma.lv',
    name: 'Latvian Academy of Arts',
    web_page: 'http://www.lma.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA7EA7UvlRHAvdhSJ1P3qM0who3HEXCHgtMmFm1CdUr_QYcyFGYDfEQzM',
    id: 5620,
    acronym: 'LAA',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'lmuza.lv',
    name: 'Latvian Academy of Music',
    web_page: 'http://www.lmuza.lv/academy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA-URdVrXCoNDTrC_UyyF5UV4cvxMKC69wWGeU_t7Umpc0VCmCet6LUQ',
    id: 5621,
    acronym: 'LAM',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'lspa.lanet.lv',
    name: 'Latvian Academy of Sports Education',
    web_page: 'http://lspa.lanet.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7Z0n5AJECW-vz1XzgquCe3NMnAWekpfhDqxGeT0565Qh2aMNvUm8keneL',
    id: 5622,
    acronym: 'LASE',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'lu.lv',
    name: 'University of Latvia',
    web_page: 'http://www.lu.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkBHoVMik8bYNoVjiPBJeGpCr4_txXkqyof0SDS6QU9Jr05glHr3DdTg',
    id: 5623,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'mil.lv',
    name: 'National Defence Academy of Latvia',
    web_page: 'http://www.mil.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJflrVMKjZJIfk_vBbC33gjWRK4HDdwAe6UKB94ehNzE2XdNUH_9QGG3Q',
    id: 5624,
    acronym: 'NDAL',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'polak.edu.lv',
    name: 'Police Academy of Latvia',
    web_page: 'http://www.polak.edu.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJMufllhVZ8EbMnyWDQ-DIleSSXMV545cpTtrvln3Xr6GM6nYS6aaKTN-P0g',
    id: 5625,
    acronym: 'PAL',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'psy.lv',
    name: 'Higher School of Psychology',
    web_page: 'http://www.psy.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWUYr21PX6cJBPtCUCixPZxFQy657oyvS8eh4JpkP7bl3cHXgRCWv02w',
    id: 5626,
    acronym: 'HSP',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'rau.lv',
    name: 'Riga Aviation University',
    web_page: 'http://www.rau.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEvlcsVv9l3_AVbBNrnG96X2NhHM24_uKViLB-vmy5ggRKw8KbFmTZkA',
    id: 5627,
    acronym: 'RAU',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'rpiva.lv',
    name: 'Riga Teacher Training and Educational Management Academy',
    web_page: 'http://www.rpiva.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCyykDBzGpsG8U5n4uHdAZMwUptoUujxg21N--ZnI1EBd4tIFWlYmDVA',
    id: 5628,
    acronym: 'RTTEMA',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'rtu.lv',
    name: 'Riga Technical University',
    web_page: 'http://www.rtu.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX69dzth4ztWVO1k9gmAJra1Ra54BbN7mXbMnOEnOH8usJE0-YppKdq4S7',
    id: 5629,
    acronym: 'RTU',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'ru.lv',
    name: 'Rezekne Higher School',
    web_page: 'http://www.ru.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTpZ7k0ARzDUaDv92fG6FLe15wLF0-1YQ1ZdAS1Y7KlK1moA8vRzMaXg',
    id: 5630,
    acronym: 'RHS',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'sseriga.edu.lv',
    name: 'Stockholm School of Economics in Riga',
    web_page: 'http://www.sseriga.edu.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMLtkL6Oj4uJueeMBLIgqaU0zKkMA4a3A11JsOJ3vBiierc4fMqKWPyw',
    id: 5631,
    acronym: 'SSEIR',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'tsi.lv',
    name: 'Transport and Telecommunication Institute',
    web_page: 'http://www.tsi.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHvOjOoAwHIJ6DDFjuqst3eQBRix659GVr0A_NteNQxnkwSvcpDVbTzA',
    id: 5632,
    acronym: 'TTI',
  },
  {
    alpha_two_code: 'LV',
    country: 'Latvia',
    domain: 'va.lv',
    name: 'Vidzeme University College',
    web_page: 'http://www.va.lv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZpriuVSo8JGKQuqLDbJHH260F3PSnmMRIkfgYNgy7ccBUcO4IoIc_7Hw',
    id: 5633,
    acronym: 'VUC',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'aub.edu.lb',
    name: 'American University of Beirut',
    web_page: 'http://www.aub.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa2POunlKciNL1SW76FOaHikVeoOi4Q5OQWmGQOlHvlFwZ1x5jpR8dBw',
    id: 5635,
    acronym: 'AUB',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'aul.edu.lb',
    name: 'Arts Sciences and Technology University',
    web_page: 'http://www.aul.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGtgVem2hyxG3SamLhp00dOVSlaEK4R3Vgbm45kJSeUtOiZeX-w_RaDxw',
    id: 5636,
    acronym: 'ASTU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'aust.edu.lb',
    name: 'American University of Science and Technology',
    web_page: 'http://www.aust.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2NpSb1PGHLKZnuVmR0eBm662CgMGbAZXiVwHqJ9PNEIyXms8NFMRyLw',
    id: 5637,
    acronym: 'AUST',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'aut.edu',
    name: 'American University College of Technology',
    web_page: 'http://www.aut.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZRBpuizV1KRoGTdPhxArFjKBRkGW9xvr9LVG4NOjV2FbbdOTqT7XZITOv',
    id: 5638,
    acronym: 'AUCT',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'balamand.edu.lb',
    name: 'Balamand University',
    web_page: 'http://www.balamand.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPihbpBeq1s55iFtNKcgxJDGQDnOiEWJDiHiH5TRX33CG20f_BfS0FMoOc3w',
    id: 5639,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'bau.edu.lb',
    name: 'Beirut Arab University',
    web_page: 'http://www.bau.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqAh4BpD2O5pj_DWUTcbWk2IJboP3rtl7uigzk1rWtZmMlV7TFEWB2i1w',
    id: 5640,
    acronym: 'BAU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'cut.edu.lb',
    name: 'Centre Universitaire de Technologie',
    web_page: 'http://www.cut.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCsmGYZvb_b8nVvG1lGjh_L0ZXfxx5Bl-snMWK1M_37X59JzjsfRAET-Q',
    id: 5641,
    acronym: 'CUDT',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'gu.edu.lb',
    name: 'Global University',
    web_page: 'http://www.gu.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD44LbGfaL2R4_hZmjcAMEy9sqf93DLUjaB_1ajiFHkEij1yNtQhW57Mg',
    id: 5642,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'haigazian.edu.lb',
    name: 'Haigazian University',
    web_page: 'http://www.haigazian.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfnUhH1zaMsGWm5WZ5xce53DNxakb1Hj25WXFk80qG8fcRY42zS8k4V4c',
    id: 5643,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'hariricanadian.edu.lb',
    name: 'Hariri Canadian University',
    web_page: 'http://www.hariricanadian.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMcnDn5SCYqtcLCxCfV1S0dA7c6bYfaSPuFECkNFg2f-6DATYO8htaTOg',
    id: 5644,
    acronym: 'HCU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'hu.edu.lb',
    name: 'Business & Computer University College',
    web_page: 'http://www.hu.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRv0XdUUdt_N92plSCU9cHAOdrpHe_iRRHTODRZfy0Slt7_m72hGaeTei8',
    id: 5645,
    acronym: 'B&CUC',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'islahonline.org',
    name: 'Al-Islah University',
    web_page: 'http://www.islahonline.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYFnUC3NZ-QE91hNH-uigDddM9KSQPX7wvREsPapy3Gf5J0KCPfMvy_OM',
    id: 5646,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'lau.edu.lb',
    name: 'Lebanese American University',
    web_page: 'http://www.lau.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKF-gtX9w4QFzC43XSmgjynmsA8M0VyagjuyPRbyp0SBmuQfffijPfQRQ',
    id: 5648,
    acronym: 'LAU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'liu.edu.lb',
    name: 'Lebanese International University',
    web_page: 'http://www.liu.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsjbrkxsU8X9swV-uo2ndOfUFC3mAC6fMH28w9iQLQw86q_fjpn3LhoSM',
    id: 5649,
    acronym: 'LIU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'matnu.edu.lb',
    name: 'Matn University',
    web_page: 'http://www.matnu.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5BM_Aq7lkPRXY39ihf8shlE08HdEZ82NgEIgvbjwpnIWDX16g8-sbUnsR',
    id: 5650,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'mut.edu.lb',
    name: 'Al-Manar University',
    web_page: 'http://www.mut.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcML4eKK66bbGJFopSiMTSCThDoP9ldnvJINYH2zfmknEv4AvfVOwbjQ',
    id: 5651,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'ndu.edu.lb',
    name: 'Notre Dame University',
    web_page: 'http://www.ndu.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzmeqMFITLb0FtBLiZCnkbh6A7B5yhPlS1pj-8LLoBYHmQXN-Uaduujmc',
    id: 5652,
    acronym: 'NDU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'theonest.edu.lb',
    name: 'Near East School of Theology',
    web_page: 'http://www.theonest.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7IL45FH9RT1UxlNZ8UUaGbD5YyOx-H99BDsjjZCZUE8CngAb9TQS2eA',
    id: 5653,
    acronym: 'NEST',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'ul.edu.lb',
    name: 'Lebanese University',
    web_page: 'http://www.ul.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkeUs8bDaJvCmkpXVql9cFKe171dAYIrPgz6_OPQ_8EAPVzTVeO3SIFA',
    id: 5654,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'usek.edu.lb',
    name: 'Holy Spirit University of Kaslik',
    web_page: 'http://www.usek.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpMR4ohIZ1DCVaik9PLjVZn9cUMVIonXPHDv5qRWgU0j0WlzZj3CLVNgiw',
    id: 5655,
    acronym: 'HSUK',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'usj.edu.lb',
    name: 'St. Joseph University Beirut',
    web_page: 'http://www.usj.edu.lb/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKRXF4vOSQDml91Em7LMEtEMlc-LPVMSsB14qdubW5OD69FlG09PSFddQ',
    id: 5656,
    acronym: 'SJUB',
  },
  {
    alpha_two_code: 'LB',
    country: 'Lebanon',
    domain: 'wakf.org',
    name: 'Al Imam Al-Ouzai University',
    web_page: 'http://www.wakf.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJaYyOOVo1bGOC3j8lhu9feU0FEz99CQuP213Lfp2HXa6nbQiylySMpo',
    id: 5657,
    acronym: 'AIAU',
  },
  {
    alpha_two_code: 'LS',
    country: 'Lesotho',
    domain: 'nul.ls',
    name: 'National University of Lesotho',
    web_page: 'http://www.nul.ls/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHxca7yWqv8uf1XzezQz_zXDBS9PdjvSD92SuIueW2QZ--ujT-Y2aqhxM',
    id: 5658,
    acronym: 'NUL',
  },
  {
    alpha_two_code: 'LR',
    country: 'Liberia',
    domain: 'universityliberia.org',
    name: 'University of Liberia',
    web_page: 'http://www.universityliberia.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJngw9o7wD4essO_k4WCm9HARUIbuClpW1X0nhq-pNfwCrCeRB4OOSnpzvDg',
    id: 5659,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'asmarya.edu.ly',
    name: 'Al-Asmarya University for Islamic Studies',
    web_page: 'http://www.asmarya.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH43idcT_mxW2hRM-4XazmSkfvkFPEaJXS-xaeUpo-mf8aUxp7la16WJk',
    id: 5660,
    acronym: 'AUFIS',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'elmergib.edu.ly',
    name: 'Elmergib University',
    web_page: 'http://www.elmergib.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTlQLeaNrfBpNBDwIcLmaaoKjmZUVPTP7fa1pnF7Hp33ZM1AMDR86xKA',
    id: 5661,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'jgu.edu.ly',
    name: 'Al-Jabal Al-Gharbi University',
    web_page: 'http://www.jgu.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJsF6zKrG4UAIF4rdHyYJzHjy3POATekyI4cuO-_DUhk8JMirdMuV2bXU',
    id: 5662,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'libopenuniv-edu.org',
    name: 'Libya Open University',
    web_page: 'http://www.libopenuniv-edu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTqUXtPihly_ZQHfi6W9kEDbRpec6Yz4uUGrIzYQMUBrBngHzl5c6MHQ',
    id: 5663,
    acronym: 'LOU',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'limu.edu.ly',
    name: 'Libyan International Medical University (LIMU)',
    web_page: 'http://www.limu.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtAS4F-N1X6i6fPOVAehd5dnu4iJwXrPfwdXYG3gV0rVFshtjS9qFKMBM',
    id: 5664,
    acronym: 'LIMU(',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'omu.edu.ly',
    name: 'Omar Al-Mukhtar University',
    web_page: 'http://www.omu.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRom4bDjjEZfuN_RhKHYBX_gmme1XBXMrF3OpG8R7bpzrgyV55Mpb7F2A',
    id: 5665,
    acronym: 'OAU',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'sebhau.edu.ly',
    name: 'Sebha University',
    web_page: 'http://www.sebhau.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9J2ylwMBho3QvKM-vC2gM4g4RtrA3XLemuQK7JUwnqHUyPNbqgtlWR8o',
    id: 5666,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'su.edu.ly',
    name: 'Sirte University',
    web_page: 'http://www.su.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_Rum3A77YX46qXfjiZQ_4v4i-hHODkJhTkgo7LV51BMpTicP1XzFGDrY',
    id: 5667,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'uob.edu.ly',
    name: 'University of Benghazi',
    web_page: 'http://www.uob.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReBnUmLAUQg3sCgc-AJvYQT18sLjrEjHA7mC0NXPrwzglYd6NplQTjgw',
    id: 5668,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'uot.edu.ly',
    name: 'University of Tripoli',
    web_page: 'http://www.uot.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRahCa-IRPyOEvV_w0lZRXeN9M8MhtQ4vw4w4ZI7xZd-NFYOIBIYTiS3g',
    id: 5669,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'LY',
    country: 'Libya',
    domain: 'zu.edu.ly',
    name: 'Zawiya University',
    web_page: 'http://www.zu.edu.ly/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4wRwkg1OHjZQnmEIyXhnE7whpe5mdEyluxWGF6h5THV8bPmbFABEQ_A',
    id: 5670,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'LI',
    country: 'Liechtenstein',
    domain: 'iap.li',
    name: 'Internationle Akademie für Philosophie',
    web_page: 'http://www.iap.li/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXY5iTw-B8gj72UNDVr5_LN7U9LJWYs_f027uEteYorJnCBD0eswye_g',
    id: 5671,
    acronym: 'IAFP',
  },
  {
    alpha_two_code: 'LI',
    country: 'Liechtenstein',
    domain: 'uni.li',
    name: 'Universität Liechtenstein',
    web_page: 'http://www.uni.li/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFsYCwe72OY4ZSC8VBf5fCAB3M-f3qAZiGUTUS9f9C4gOuNuY0xhZh-A',
    id: 5672,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'info.kma.lt',
    name: 'Kaunas Medical Academy',
    web_page: 'http://info.kma.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeZuYgWXnA0-VRTncGSGj7BYYNirvVfiFnt7D5jGjA5yo-0K5veEuDLyE',
    id: 5673,
    acronym: 'KMA',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'ism.lt',
    name: 'International School of Management',
    web_page: 'http://www.ism.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRABs4g3CM6pZ5o65r5A2GDBo0Z59UopJj3dXqp0UL3JM04E46RQn69Dw',
    id: 5674,
    acronym: 'ISM',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'ktu.lt',
    name: 'Kaunas University of Technology',
    web_page: 'http://www.ktu.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIDdQqYYp1-RC4y8f1ulYgiOo2nGHVxamz7WqePRmz86ypTcTasEkDpPJy',
    id: 5675,
    acronym: 'KUT',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'ku.lt',
    name: 'Klaipeda University',
    web_page: 'http://www.ku.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZivOLzcyZ3rhUVKul-Ai3S6WbHUw9zkbVgsQb7-KRyVbk_IBDK0w0kUbB',
    id: 5676,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'lcc.lt',
    name: 'Lithuania Christian College',
    web_page: 'http://www.lcc.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd0_f9DZKPVdWmG_u3clLUNeSZzrcG8m1aNiMjKIQ5ujiLBNPkkkLDAg',
    id: 5677,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'lka.lt',
    name: 'Military academy of Lithuania',
    web_page: 'http://www.lka.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSKJwScgEQd5LAitfI1Yhz4UgpqQzMj2bjb8crWJJSf-Af23qJQLNBt2k',
    id: 5678,
    acronym: 'MAL',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'lkka.lt',
    name: 'Lithunian Institute of Physical Education',
    web_page: 'http://www.lkka.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSNe7ztbQVsEacF_rLhSUCoqLidtDY275idGmeNKTeTAcg0zP1MTupOw',
    id: 5679,
    acronym: 'LIPE',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'lmta.lt',
    name: 'Lithunian Academy of Music and Theatre',
    web_page: 'http://www.lmta.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHKQtbJB1MpHr7Hh2EziltUIEIrvteyouCNsEe6YFWUNb0c4lD4tzefSY',
    id: 5680,
    acronym: 'LAMT',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'lva.lt',
    name: 'Lithunian Veterinary Academy',
    web_page: 'http://www.lva.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd1fJKiK2y-3uvC-pzjBxNJDF7DGaKejaVAVxeIh0lWyF7QwyT56ZrMnuR3Q',
    id: 5681,
    acronym: 'LVA',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'lzua.lt',
    name: 'Lithunian University of Agriculture',
    web_page: 'http://www.lzua.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC9vtTJYbINozpGI7gGRKIt4LqLTeJBFYeWZvRwVTSqDESx1VVKWWhEAE',
    id: 5682,
    acronym: 'LUA',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'mch.mii.lt',
    name: 'Vilnius Academy of Arts',
    web_page: 'http://www.mch.mii.lt/more/vda/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3574kLW-P_kbNnlK5Q1p22gRfx4kAH0W_wie3YZv3tHw92ORddcjkOnc',
    id: 5683,
    acronym: 'VAA',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'mruni.eu',
    name: 'Mykolo Romerio university',
    web_page: 'http://www.mruni.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyd8Qe9o0P7NteTlvjxUPHWGrMDCdq_OpsKF01MmI-xsYfve5WbBUAyuRN',
    id: 5684,
    acronym: 'MRU',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'su.lt',
    name: 'Siauliai University',
    web_page: 'http://www.su.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAmETebx-h_XPnJRjh_pQApTgZlqZpnhdCGOgg84NGZSL523cNKsbBKi0o',
    id: 5685,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'vdu.lt',
    name: 'Vytautas Magnus University',
    web_page: 'http://www.vdu.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQerY1ZtXj0Zd5HiEKwQoyufch4JHnvJ51jLVUAP272WBG_7IXZJRnxE-u1',
    id: 5686,
    acronym: 'VMU',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'vgtu.lt',
    name: 'Vilnius Gediminas Technical University',
    web_page: 'http://www.vgtu.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThUFBzaOq5ghyFdzJmucaYmOvygsTZX80-cMwIj9eyz-yJLwKUmHCU5fc',
    id: 5687,
    acronym: 'VGTU',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'vpu.lt',
    name: 'Vilnius Pedagogical University',
    web_page: 'http://www.vpu.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcfdG1Nh9iMdiHhzqUvhIwn0SvSk0X21TXww3oKle0lGySJsIRK9dT0og',
    id: 5688,
    acronym: 'VPU',
  },
  {
    alpha_two_code: 'LT',
    country: 'Lithuania',
    domain: 'vu.lt',
    name: 'Vilnius University',
    web_page: 'http://www.vu.lt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcfdG1Nh9iMdiHhzqUvhIwn0SvSk0X21TXww3oKle0lGySJsIRK9dT0og',
    id: 5689,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'LU',
    country: 'Luxembourg',
    domain: 'iuil.lu',
    name: 'International University Institute of Luxembourg',
    web_page: 'http://www.iuil.lu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWeQAkVT3yJjZRCOMoVbdNhrkmAZ5QW9uvF-aUb2SZBGOu3d22pdl_SA',
    id: 5690,
    acronym: 'IUIL',
  },
  {
    alpha_two_code: 'LU',
    country: 'Luxembourg',
    domain: 'uni.lu',
    name: 'University of Luxemburg',
    web_page: 'http://www.uni.lu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB1jm0zUmmmufLYxbRipZYi3gQhywuwaG-vtIPlGBZ4m2Br0yjF1XCbo_P',
    id: 5691,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'MO',
    country: 'Macao',
    domain: 'ipm.edu.mo',
    name: 'Macau Polytechnic Institute',
    web_page: 'http://www.ipm.edu.mo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCOlyGV5UDRnD_EVdGCdhFC39J7uUGlX09oh3ylw8braQZkB7K6KuOEQ',
    id: 5692,
    acronym: 'MPI',
  },
  {
    alpha_two_code: 'MO',
    country: 'Macao',
    domain: 'umac.mo',
    name: 'University of Macau',
    web_page: 'http://www.umac.mo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReBsdySVlYZYRxd0CljwJHUD3ICA19Lcm37bvyvG5Ic5MUD8A0byaqoUI',
    id: 5693,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'MO',
    country: 'Macao',
    domain: 'usj.edu.mo',
    name: 'University of Saint Joseph',
    web_page: 'http://www.usj.edu.mo/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOcJpge_5uCZK5GPPnw__zH1NVNhwWNPgJiyfAOrNg1swWKd7Wz6C2meE',
    id: 5694,
    acronym: 'USJ',
  },
  {
    alpha_two_code: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    domain: 'fon.edu.mk',
    name: 'FON University',
    web_page: 'http://www.fon.edu.mk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQwiZ0wAHPGtKJcPTorvnQjht3hGdA-i5RCssQJJ2a_HfCQsREXKeRtfo',
    id: 5695,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    domain: 'seeu.edu.mk',
    name: 'South East European University',
    web_page: 'http://www.seeu.edu.mk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSr29iENhrQv3WTAWkKOKm9vrPM46AAyuYXDqZZSKaTvZqBqnDmxQiK7ss',
    id: 5696,
    acronym: 'SEEU',
  },
  {
    alpha_two_code: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    domain: 'ugd.edu.mk',
    name: 'University Goce Delcev',
    web_page: 'http://www.ugd.edu.mk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPiZUA6Q7VeE39r3ny_9tjluSFAavkosTElUeXI9YvWcY0teKbVU3HeVE',
    id: 5697,
    acronym: 'UGD',
  },
  {
    alpha_two_code: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    domain: 'uist.edu.mk',
    name: 'University for Information Science and Technology  "St. Paul The Apostle"',
    web_page: 'http://www.uist.edu.mk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUXlhV-MTmKwWR37CG8DYOlXt2SwyS6aLxdgyirBPaXirRVfyw-8zfPM8',
    id: 5698,
    acronym: 'UFIST"PA',
  },
  {
    alpha_two_code: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    domain: 'ukim.edu.mk',
    name: 'St.Cyril and Methodius University',
    web_page: 'http://www.ukim.edu.mk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRokL7_dHZBEQPYA3wsS4q-gUal06ZIu73HjbKT45UgL34Ln0yhUlHSQU',
    id: 5699,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    domain: 'uklo.edu.mk',
    name: 'St.Kliment Ohridski University',
    web_page: 'http://www.uklo.edu.mk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTls2diAYs8HJYpigOICLR3DMS5ptSciaCzfcWvcVR4ryCb3_WVGG70tA',
    id: 5700,
    acronym: 'SOU',
  },
  {
    alpha_two_code: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    domain: 'unite.edu.mk',
    name: 'State University of Tetova',
    web_page: 'http://www.unite.edu.mk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU3BQis2JSs41WYBpwHW8ffH8y5GKZSEi6mr9rjeyyTlN03kXdh6W69eM',
    id: 5701,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'MG',
    country: 'Madagascar',
    domain: 'univ-antananarivo.mg',
    name: "Université d'Antananarivo",
    web_page: 'http://www.univ-antananarivo.mg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ9bWEiPgX4GJM4_xtC3INdcBZ6HS7U9ORfUOzB3xiH0o6k7uxeknGAg',
    id: 5702,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'MG',
    country: 'Madagascar',
    domain: 'univ-antsiranana.mg',
    name: "Université d'Antsiranana",
    web_page: 'http://www.univ-antsiranana.mg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1Ev0yy1sfAkJ3bHuX1lxkrHqTvC0zfzPcSF0yuKUbmrlBd9V5A8p4uH8',
    id: 5703,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'MG',
    country: 'Madagascar',
    domain: 'univ-fianar.mg',
    name: 'Université de Fianarantsoa',
    web_page: 'http://www.univ-fianar.mg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcIOtzhfpBwnNCVaSaPP68ELvvT5WC8465zymbRvmBWnrbWB7MPw71WP0',
    id: 5704,
    acronym: 'UDF',
  },
  {
    alpha_two_code: 'MG',
    country: 'Madagascar',
    domain: 'univ-mahajanga.mg',
    name: 'Université de Mahajanga',
    web_page: 'http://www.univ-mahajanga.mg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOJXld04r-X0Gv5QZssRyHpQVt67VdfFfLTNJV45LssnCqhvxbAXL7Yw',
    id: 5705,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'MG',
    country: 'Madagascar',
    domain: 'univ-toamasina.mg',
    name: 'Université de Toamasina',
    web_page: 'http://www.univ-toamasina.mg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCefU-J7H745ez3nlDgcC1odSCjVdw2zQPcsagCI7i9EFuGnTSwrFKJu0',
    id: 5706,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'MG',
    country: 'Madagascar',
    domain: 'univ-toliara.mg',
    name: 'Université de Toliary',
    web_page: 'http://www.univ-toliara.mg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvs9U3Q8uEMJzaL_KIh-GNSmlw2eiM2EA-mgAx2Y8QtdQ_PccNAuBla2s',
    id: 5707,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'MW',
    country: 'Malawi',
    domain: 'cunima.net',
    name: 'Catholic University of Malawi',
    web_page: 'http://www.cunima.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3W7aGnRY_9TqZqad5k7uSNXRNPjzFnQS7jJYDQ1Sk-ufhGgaISGIp1w',
    id: 5708,
    acronym: 'CUM',
  },
  {
    alpha_two_code: 'MW',
    country: 'Malawi',
    domain: 'mzuni.ac.mw',
    name: 'Mzuzu University',
    web_page: 'http://www.mzuni.ac.mw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIb0R63kGB8X85Klm6uBK6JUOCyUUFA3UaKCDClZsjGkGbtx9V-s-IfA',
    id: 5709,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'MW',
    country: 'Malawi',
    domain: 'ulivingstonia.com',
    name: 'University of Livingstonia',
    web_page: 'http://www.ulivingstonia.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5Agax6QNnkTUGPGBcm1Fc8RBGPrceoj3bP8Yy8f5k96mfE9OG99hwSA',
    id: 5710,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'MW',
    country: 'Malawi',
    domain: 'unima.mw',
    name: 'University of Malawi',
    web_page: 'http://www.unima.mw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo1dkaRpBO2ImKo4mqx4LVoUsgXQM2MC1UzbTpv0FH3VZdDDBpjJpkvg',
    id: 5711,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'acms.edu.my',
    name: 'Allianze College of Medical Sciences (ACMS)',
    web_page: 'http://www.acms.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVVcSxpFJmQY18ByR0GTT_r9GT0VvvU0lXvwLAFjcyueS1APoURpibmNU',
    id: 5712,
    acronym: 'ACMS(',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'aeu.edu.my',
    name: 'Asia E University',
    web_page: 'http://www.aeu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZ2MxEk9ICcAxwmIXMava-dvNnGCuU00-L4QGspOnScX0c8THr2-UksQ',
    id: 5713,
    acronym: 'AEU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'aimst.edu.my',
    name: 'Aimst University',
    web_page: 'http://www.aimst.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpByFa26uX6Bt8kpfulTxh_nJp4wO3-e2SRDupO4v0LUX_TCHllEwSJQ',
    id: 5714,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'aiu.edu.my',
    name: 'Al-Bukhari International University',
    web_page: 'http://www.aiu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvgz5--7NleNfuKgROBflp26qXuxq6RGKtjbNwPru-url6OLHYwNbqEk4',
    id: 5715,
    acronym: 'AIU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'apiit.edu.my',
    name: 'Asia Pacific Institute of Information Technology (APIIT)',
    web_page: 'http://www.apiit.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRliybNqk6p-ZQq-QY5vxYX-BsJZvXddBFqTTQvF_K9pTT0l317ulgxYA',
    id: 5716,
    acronym: 'APIIT(',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'atc2u.com',
    name: 'Advance Tertiary College',
    web_page: 'http://www.atc2u.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZrCiOrn_tHIGJOLWzzXnc6z6gmW3Uh7GZ3xD45c8fa4pdrNoRMabHnQ',
    id: 5717,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'bac.edu.my',
    name: 'Brickfields Asia College',
    web_page: 'http://www.bac.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSII9Ml7FZNHcRmlaHH-JpmW9_Go-cz7nKch_OebqMSYPXKZClVW3NOsCA',
    id: 5718,
    acronym: 'BAC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'binary.edu.my',
    name: 'Binary University College of Managemant & Entrepreneurship',
    web_page: 'http://www.binary.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTIT-nvNLRcAb4QCzdD25IU7b3l7qReaiiZtfiQ0G5GYOl3kuf876STuM',
    id: 5719,
    acronym: 'BUCM&E',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'bmi.edu.my',
    name: 'British Malaysian Institute',
    web_page: 'http://www.bmi.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7FMbrlg_P4SffnOHIH7FvSNBv4Zn2jdz1nUlMXu0YlKwIKf_hFlc-bUYSHQ',
    id: 5720,
    acronym: 'BMI',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'city.edu.my',
    name: 'City University College of Science and Technology',
    web_page: 'http://www.city.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT8iGZylM9Y6Vxsotu4CKV2bRXMwf5XheI4u1Yp1qpwbFPXiYgXYmVbTEB',
    id: 5721,
    acronym: 'CUCST',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'curtin.edu.my',
    name: 'Curtin University of Technology Sarawak Campus',
    web_page: 'http://www.curtin.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxGGMdm6-l_5pOJM9DEFh5vRvRUT_Po7SqyMbYWd33n5QzUHN6_gLKImg',
    id: 5722,
    acronym: 'CUTSC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'cybermed.edu.my',
    name: 'Cyberjaya University College of Medical Science',
    web_page: 'http://www.cybermed.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRioaDBbK2EoGrERcoA6H19ROefoH-JpWPQYPI0_B_lAbSyzAIKDMjOI6I',
    id: 5723,
    acronym: 'CUCMS',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ftmsglobal.com',
    name: 'FTMS Global Academy',
    web_page: 'http://www.ftmsglobal.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1hTyo3kAgnbxz2BJE0mEq98SWFIhyaGI4RuXDPaI-0NYOHDDILaW5gWQ',
    id: 5724,
    acronym: 'FGA',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'help.edu.my',
    name: 'Help University College',
    web_page: 'http://www.help.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMlf9zxuoKPTB3eKsAq8mO626kKL5BIitfkYivlNfKb8YEhRxiothBpw',
    id: 5725,
    acronym: 'HUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'hikmah.edu.my',
    name: 'Darul Hikmah Islamic College',
    web_page: 'http://www.hikmah.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRJO1F1MeZwa0m2WZmVDqnKoBBAbaPD7XiAuUxPNeFfjvEA111t7SbRIA',
    id: 5726,
    acronym: 'DHIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'i4p.edu.my',
    name: 'Institute of Teachers Education Penang',
    web_page: 'http://www.i4p.edu.my/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcS7gkMBB08bv7ZbnkQPf10WygYGEGKVjA_aBmyDDs6YMJU0SCJrz5_vSeM',
    id: 5727,
    acronym: 'ITEP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iact.edu.my',
    name: 'Iact College',
    web_page: 'http://www.iact.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIfX2Bi4azPPfGXNz-ledyCLq-p9W46vfjZ_hbYakxIfvehojXINjLKA',
    id: 5728,
    acronym: 'IC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iic.edu.my',
    name: 'International Islamic College',
    web_page: 'http://www.iic.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjhGMDUp-Q4UXse9UtzVTBnVboZP0vG8l0nv1lajRXCcWgETKvwSPmkkl6',
    id: 5729,
    acronym: 'IIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iiu.edu.my',
    name: 'International Islamic University',
    web_page: 'http://www.iiu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4U3K8b3WypHuOEe_e5L1ITaJxWFIpQQSo7oBOaQWViI7A67q6Uey4kW5baQ',
    id: 5730,
    acronym: 'IIU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'imu.edu.my',
    name: 'International Medical University',
    web_page: 'http://www.imu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT39L4Hp0i616qxt_BbePqrjG6e_fpsoKJnChgOo8ftyR9l-Kk1pbRfG9E',
    id: 5731,
    acronym: 'IMU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'instedt.edu.my',
    name: 'Darul Takzim Institute of Technology',
    web_page: 'http://www.instedt.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStwSP8PT5R_HFt2IGwxnslEXJSfxGCPjB3rM1iGolKBulbias5Gso_BDk',
    id: 5732,
    acronym: 'DTIT',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipb.edu.my',
    name: 'Baitulmal Management Institute (IPB)',
    web_page: 'http://www.ipb.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9IPkZLYCbU_A4t3uLqykHBlxVPXzyDvUMnJpbxnPRlsepD4A1PozoJw',
    id: 5733,
    acronym: 'BMI(',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipbl.edu.my',
    name: 'Institute of Teachers Education Batu Lintang',
    web_page: 'http://www.ipbl.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRvokF7GrIEGmiAGg-7NI2_7lnv6hSt3tTkgiSlpSldsh_Lix-B3h3ujs',
    id: 5734,
    acronym: 'ITEBL',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipbmm.edu.my',
    name: 'Institute of Teachers Education Malay Language',
    web_page: 'http://www.ipbmm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT5AZ36dnePtyWD_NB0fxiJi7M1ojF5ZzNJIlswMbD27tsLhMlRZBadTU',
    id: 5735,
    acronym: 'ITEML',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipda.edu.my',
    name: 'Institute of Teachers Education Darul Aman',
    web_page: 'http://www.ipda.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1hKdiuK2flhr9N850V3-Ne4ZCT6WwBjrUzIqwXUpz6fVtzEgOcMFp3G8',
    id: 5736,
    acronym: 'ITEDA',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipgkkb.edu.my',
    name: 'Institute of Teachers Education Kota Bharu',
    web_page: 'http://www.ipgkkb.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAndsEnF-dAHOAfLQEJiNbDXXp3I3QoK_KCk6hvrL9Q3M5YUwCQnpQ_fQ',
    id: 5737,
    acronym: 'ITEKB',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipgkrajang.edu.my',
    name: 'Institute of Teachers Education Rajang',
    web_page: 'http://www.ipgkrajang.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7gkMBB08bv7ZbnkQPf10WygYGEGKVjA_aBmyDDs6YMJU0SCJrz5_vSeM',
    id: 5738,
    acronym: 'ITER',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipgkti.edu.my',
    name: 'Institute of Teachers Education Temenggong Ibrahim',
    web_page: 'http://www.ipgkti.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWLrswZ3CkNZ_1PPjiubxuYN0AB9OLGIet5zIbOpQv8pJJ4iykLmr7TA',
    id: 5739,
    acronym: 'ITETI',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipgmkdri.edu.my',
    name: "Institute of Teachers Education Dato' Razali Ismail",
    web_page: 'http://www.ipgmkdri.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROsGDf2DswpcZtnNs7tN5iwZ2RHklP0p6XY6vSQQl0nT9od4wNo-y-LHU',
    id: 5740,
    acronym: 'ITEDRI',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipgmksm.edu.my',
    name: 'Institute of Teachers Education Sultan Mizan',
    web_page: 'http://www.ipgmksm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7HI3xKl0idI6C5WOfNxTay04kApG6fFHFlpwPNbrAGMGSm7WBu4d5sAg',
    id: 5741,
    acronym: 'ITESM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipgmtawau.edu.my',
    name: 'Institute of Teachers Education Tawau',
    web_page: 'http://www.ipgmtawau.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7gkMBB08bv7ZbnkQPf10WygYGEGKVjA_aBmyDDs6YMJU0SCJrz5_vSeM',
    id: 5742,
    acronym: 'ITET',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipgperlis.edu.my',
    name: 'Institute of Teachers Education Perlis',
    web_page: 'http://www.ipgperlis.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7gkMBB08bv7ZbnkQPf10WygYGEGKVjA_aBmyDDs6YMJU0SCJrz5_vSeM',
    id: 5743,
    acronym: 'ITEP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipik.edu.my',
    name: 'Institute of Teachers Education Ilmu Khas',
    web_page: 'http://www.ipik.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2J-esKL8qg_m4dHBXyEwH3AMqDGHTQnblWE_FNFLY9zNBI0fJkigibl4',
    id: 5744,
    acronym: 'ITEIK',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipip.edu.my',
    name: 'Institute of Teachers Education Ipoh',
    web_page: 'http://www.ipip.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7gkMBB08bv7ZbnkQPf10WygYGEGKVjA_aBmyDDs6YMJU0SCJrz5_vSeM',
    id: 5745,
    acronym: 'ITEI',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipislam.edu.my',
    name: 'Institute of Teachers Education Islamic Education',
    web_page: 'http://www.ipislam.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSruVh59hgLKVjdG2cQSG9yqm86bVVfH63ywk0hAlVNK5OlwbRDwKjsfVY',
    id: 5746,
    acronym: 'ITEIE',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipkent.edu.my',
    name: 'Institute of Teachers Education Kent',
    web_page: 'http://www.ipkent.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-5PwfDudThGNXfszMprdgQD9YX0yLP35R3uTsHq-_054iifeoM_4FoGw',
    id: 5747,
    acronym: 'ITEK',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipks.edu.my',
    name: 'Institute of Teachers Education Keningau',
    web_page: 'http://www.ipks.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT55wL0s4kR97m3KA1EGHPGi7ypkZywMJdPdhIuHGeOgaXrFtQPYy-t21Ba',
    id: 5748,
    acronym: 'ITEK',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ippm.edu.my',
    name: 'Institute of Teachers Education Melaka',
    web_page: 'http://www.ippm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 5749,
    acronym: 'ITEM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iprm.edu.my',
    name: 'Institute of Teachers Education Raja Melewar',
    web_page: 'http://www.iprm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeVN7VsZJ9EYa58nfHVn0tsScithSV1ehKmQdUdjNY17Oj_9IgNYgzmeI',
    id: 5750,
    acronym: 'ITERM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipsah.edu.my',
    name: 'Institute of Teachers Education Sultan Abdul Halim',
    web_page: 'http://www.ipsah.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvGYrbmj7dqQZN2HWM8KK8Qv2NWaFXfpIFkpge7lEIYhIxIwxF3-VWGD4',
    id: 5751,
    acronym: 'ITESAH',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipsmiri.edu.my',
    name: 'Institute of Teachers Education Sarawak',
    web_page: 'http://www.ipsmiri.edu.my/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTf54eDTuNrzZs0-t0__FqLdvdH1eRqOkkFu8dg531OAVnTJDrGFeqFeno',
    id: 5752,
    acronym: 'ITES',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iptaa.edu.my',
    name: 'Institute of Teachers Education Tengku Ampuan Afzan',
    web_page: 'http://www.iptaa.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1HEAmz4QH8_BXg-y5kgQ52yNoWOuQJfQfuA8B_7jvWyau3Q5-RT6K_tq0',
    id: 5753,
    acronym: 'ITETAA',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iptb.edu.my',
    name: 'Institute of Teachers Education Tuanku Bainun',
    web_page: 'http://www.iptb.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvGYrbmj7dqQZN2HWM8KK8Qv2NWaFXfpIFkpge7lEIYhIxIwxF3-VWGD4',
    id: 5754,
    acronym: 'ITETB',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ipteknik.edu.my',
    name: 'Institute of Teachers Education Technical Education',
    web_page: 'http://www.ipteknik.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXvi4pa0DvAne3-C6kKSRnZrirO4GANcrYcv-m7OAVSTxpSRP9OUjS4MbuFg',
    id: 5755,
    acronym: 'ITETE',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iptho.edu.my',
    name: 'Institute of Teachers Education Tun Hussein Onn',
    web_page: 'http://www.iptho.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHFfwvfQTzJZVoQY4TdnOcBtaSUgrxKeDdLco9Wo8hZJdZ53m6kKBFhw',
    id: 5756,
    acronym: 'ITETHO',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iptips.edu.my',
    name: 'Perlis Islamic Higher Learning Institute',
    web_page: 'http://www.iptips.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs0pmHqEZhphjUd4ukFR6JJGtrCymTkPkViqlryUC2u3X07xzBcacmrlYcYg',
    id: 5757,
    acronym: 'PIHLI',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iucn.edu.my',
    name: 'International University College of Nursing (IUCN)',
    web_page: 'http://www.iucn.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqTV7quqRNZOq5n4j1QkTdhhZz0rImOzQhLIzHLHFyMs3wxtqezHwvXg',
    id: 5758,
    acronym: 'IUCN(',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'iuctt.edu.my',
    name: 'International University College of Technology Twintech (IUCTT)',
    web_page: 'http://www.iuctt.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkYinbTDwrBqyFK-NvnHG7ZD6KHSgBcB7HTNE6cbVfUdb8lWse4AVdbX8',
    id: 5759,
    acronym: 'IUCTT(',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kbu.edu.my',
    name: 'KBU International College',
    web_page: 'http://www.kbu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIJ39MyTZrd6V3V7Pdi30lHYHZgyPchCq-ZD4WgzmCV6K1a9v80b1H18su',
    id: 5760,
    acronym: 'KIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kdu.edu.my',
    name: 'KDU College Sdn Bhd',
    web_page: 'http://www.kdu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYWuEC7LrlwIUxW1nyATOXwZ-vGxIGZ8dJLjwacOxzg4Ve-GGe63co1r8',
    id: 5761,
    acronym: 'KCSB',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kias.edu.my',
    name: 'Sultan Ismail Petra International Islamic College',
    web_page: 'http://www.kias.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1rE8Tx3wFpGE5lKlNprWXToXBI37rsu_LOjax7z6WLZQ6l6kG6cL3kew',
    id: 5762,
    acronym: 'SIPIIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kidu-darululum.blogspot.com',
    name: 'Darul Ulum Islamic College',
    web_page: 'http://kidu-darululum.blogspot.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIVccLKLhsuXDd57swImieOrv52sKBqmKhsKq22GWNLqktN2RM2l8bYV8',
    id: 5763,
    acronym: 'DUIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kiperak.edu.my',
    name: 'Perak Islamic College',
    web_page: 'http://www.kiperak.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrhlQ4VbL7KbQvYgrQDmlb8XFUQhuDtN2O7NMSAaX7qpxOAPZ4LKsdeZhF',
    id: 5764,
    acronym: 'PIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kipsas.edu.my',
    name: 'Sultan Ahmad Shah Islamic College',
    web_page: 'http://www.kipsas.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRigXI6FObJm483hSRQxW7Yb_d2CbRFOubWe8NGN7e6hGrLHgbrL7PaXUk',
    id: 5765,
    acronym: 'SASIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kist.edu.my',
    name: 'Islamic College for Sciences and Technologies',
    web_page: 'http://www.kist.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI5Pm3046jcpJayP-mO4qzo5I0OuEUP8WcYzoSrcOIN0NZT2S10C0now',
    id: 5766,
    acronym: 'ICFST',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kitab.edu.my',
    name: 'International Islamic College of Penang',
    web_page: 'http://www.kitab.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI3PK6hccod_9z2kRCYevpKy7R4uWAs9rGFFbepi7xViaTiEU0fwUoLuQ5',
    id: 5767,
    acronym: 'IICP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kliuc.edu.my',
    name: 'Kuala Lumpur Infrastructure University College',
    web_page: 'http://www.kliuc.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0S6Pl0-9-bNtMzZZmycozcs_a_pw_jI8dBdcjjFZeXf0lPJyWExPvcCU',
    id: 5768,
    acronym: 'KLIUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'klmu.edu.my',
    name: 'Kuala Lumpur Metropolitan University',
    web_page: 'http://www.klmu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-KLq4gg0V2vOoOJqZC1C7v8HhfVmMLAqiCDV6n6XsYkOmKVSWKDLV6_s',
    id: 5769,
    acronym: 'KLMU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kolejshahputra.edu.my',
    name: 'Shahputra College',
    web_page: 'http://www.kolejshahputra.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwhmo5ZvU59MRV3M_9NizLTWyqwa2wS6guLhsiMXRS-myvkwxgd_WvT8o',
    id: 5770,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kolejypc.edu.my',
    name: 'YPC-iTWEB College',
    web_page: 'http://www.kolejypc.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLg-XsK8UpyngRev4yPh9_utqFEVDkBYxiUPIpy7b5omSWYPfCTCadXw',
    id: 5771,
    acronym: 'YC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kptm.edu.my',
    name: 'Mara Poly-Tech College',
    web_page: 'http://www.kptm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKsFJUcYkkuBWtNZ23tlsaWc_GcsHrSdYJ8HRYOmHBMc0C9fYa75L4fiI',
    id: 5772,
    acronym: 'MPC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ktd.edu.my',
    name: 'Darul Naim College of Technology',
    web_page: 'http://www.ktd.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4nI8jhMXNlDfpFk9lCHwAgH7E5xaTHVziGgiZUlaOkwuOf2pC4xIQQ6G5',
    id: 5773,
    acronym: 'DNCT',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kudqi.net.my',
    name: 'Darul Quran Islamic College University',
    web_page: 'http://kudqi.net.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMchNP00hY36VRoHfOc1wH1okjFNgocVUJt3CzdH1Knb1YIkYs6K0fivI',
    id: 5774,
    acronym: 'DQICU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kuim.edu.my',
    name: 'Melaka Islamic University College',
    web_page: 'http://www.kuim.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkEqK-lb4MY1p1xqtrSnBzEv1rZpP6M1h9GA8BKhtrQJm_DnipQXovi2o',
    id: 5775,
    acronym: 'MIUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kuin.edu.my',
    name: 'Kolej Universiti Insaniah',
    web_page: 'http://www.kuin.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBNF02VRHPXwNBTPJEy6zc-WGSPp7ci7iZmDvbsn_TF2SqawPMDkBiSvU',
    id: 5776,
    acronym: 'KUI',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'kuis.edu.my',
    name: 'Selangor Islamic University College',
    web_page: 'http://www.kuis.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTopVDP5eteP9vp_nhOuO3378HTn2ng1vJaxnD0876KGU_WwFsy792VIQ',
    id: 5777,
    acronym: 'SIUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'limkokwing.edu.my',
    name: 'Limkokwing University College of Creative Technology',
    web_page: 'http://www.limkokwing.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgT5dds3JDAbNknT3aSyMLg90TZ4wxn9UokCc8WAoyiYsbkdrUyZ3h1vA',
    id: 5778,
    acronym: 'LUCCT',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'linton.edu.my',
    name: 'Linton University College',
    web_page: 'http://www.linton.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpYkI94nW1cjPkwcQVvdakh84JuNuFNGcDGVfVG8BW1qAJzXH_h3xPbw',
    id: 5779,
    acronym: 'LUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'mahsa.edu.my',
    name: 'Mahsa University College for Health and Medical Science',
    web_page: 'http://www.mahsa.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB4yAz2gXj0F2BbNV4v8LeqVHO1yqi_cYZTIAKsSoEKoaX5g9EyhRUyOI',
    id: 5780,
    acronym: 'MUCFHMS',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'mediu.edu.my',
    name: 'Al Madinah International University',
    web_page: 'http://www.mediu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWOr_AgM6fvuYdOmtImLKyYvb9_BUkzYthWlcZcgASOtZjp5RY7osFyQ',
    id: 5781,
    acronym: 'AMIU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'miit.unikl.edu.my',
    name: 'Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)',
    web_page: 'http://miit.unikl.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfnCoqt7xqob8qybAOpsaGS1gnm4K3TtVRsKn2ccLzz5pBfkxpu_K7Ee0I',
    id: 5782,
    acronym: 'UKLMIIT(',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'mmu.edu.my',
    name: 'Multimedia University',
    web_page: 'http://www.mmu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNmMNYVvCeGajlSrARjFKIb6tRDXEjvb-VMiazB4agFz8aY_cztyvj-g',
    id: 5783,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'monash.edu.my',
    name: 'Monash University Malaysia Campus',
    web_page: 'http://www.monash.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSckpdRVoxQoZ-IbijzCfv6tKD-_m9ZnXn_AXNDNPl9CMefeLFuP7A9dUk',
    id: 5784,
    acronym: 'MUMC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'msu.edu.my',
    name: 'Management and Science University',
    web_page: 'http://www.msu.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy2Sb4E2viDm7BdzwCGkPeryo3zL3Iv17jekGQ63Z5jQYcbxFjDkrVOE4',
    id: 5785,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'murni.edu.my',
    name: 'Murni Nursing College',
    web_page: 'http://www.murni.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUYZuJ5jQLDpLeedHneJDcWIJYVVvketr-_MUj0Wm3MMcokGhagY3NlA',
    id: 5786,
    acronym: 'MNC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'must.edu.my',
    name: 'Malaysia University of Science and Technology (MUST)',
    web_page: 'http://www.must.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuNfA3Quo11miBF6BhgHPwqdfW61n5v5HDFxNkURdmQOl49aGJaCLkXg',
    id: 5787,
    acronym: 'MUST(',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'nilai.edu.my',
    name: 'Nilai University College',
    web_page: 'http://www.nilai.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9zMAXlc9LlxNYFr6bWpsc1EOTddacJr8AEeAg9Kkk_WTsEmr_q3saCCk',
    id: 5788,
    acronym: 'NUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'nottingham.edu.my',
    name: 'University of Nottingham Malaysia Campus',
    web_page: 'http://www.nottingham.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_X4xYVyqCRuNpvz4I_hMJxAepnKNOQoVRlk7t6t8C8886qcV5MJi-fNo',
    id: 5789,
    acronym: 'UNMC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'numed.ncl.ac.uk',
    name: 'Newcastle University Medicine Malaysia',
    web_page: 'http://numed.ncl.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6BSXTYmj9d3Cp4ZDZjgi6cLm5rct64Xg4uURUgW2sFMc2kobAvJ2_DBk',
    id: 5790,
    acronym: 'NUMM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'olympia.edu.my',
    name: 'Olympia College',
    web_page: 'http://www.olympia.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZR0StjahEo18q-vpUr1FYy-mgpugyY-QZ51yTrDBLBSP26IcA2W1bZg',
    id: 5791,
    acronym: 'OC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'oum.edu.my',
    name: 'Open University Malaysia',
    web_page: 'http://www.oum.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhSi5x-CFMpK1jDjuv-eYHCWtAJ6D59HQ71KuWWsFGptPmNT5qn3kKdA',
    id: 5792,
    acronym: 'OUM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'perdanauniversity.edu.my',
    name: 'Perdana University',
    web_page: 'http://www.perdanauniversity.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxqBKMMIniBF7U1Jmj3Vofti6CJtADktBc-G5d2RHlMcGtTc37-CRJPA',
    id: 5793,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'pidc.edu.my',
    name: 'Penang International Dental College',
    web_page: 'http://www.pidc.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9mnx7k30m7KwuP9PhuAf2HRbofgfyto_Vj83LONR6BuKNMawfO3t5jQU',
    id: 5794,
    acronym: 'PIDC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'pine.edu.my',
    name: 'Institut Prima Bestari - Pine Academy',
    web_page: 'http://www.pine.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2hNBWsDoIT7Nrh3SISpSp1mJ0eYfeLQz1eTLLDxnWy1dvNT_LBm-aLzxX',
    id: 5795,
    acronym: 'IPB-PA',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'piup.edu.my',
    name: 'Primier International University Perak',
    web_page: 'http://www.piup.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI2hG8pB-Atj6-60CjqxlJrwKQzEEiF5Mv_cW4bxB9fPj4mX8Uty3lZ8Y',
    id: 5796,
    acronym: 'PIUP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'pkb.edu.my',
    name: 'Kota Bharu Polytechnic',
    web_page: 'http://www.pkb.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7lXvhnYBFqH8HhYaj_KNixzLcVEnOExGmO1CXK5WOMvm8BuUZYDrlAbU',
    id: 5797,
    acronym: 'KBP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'pkksabah.edu.my',
    name: 'Kota Kinabalu Polytechnic',
    web_page: 'http://www.pkksabah.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsmNvKLGCZ9Ed4k5GycuXB8ZUNDAT-hwTmjkaX29KbF1UYSfIiK8r-SQ',
    id: 5798,
    acronym: 'KKP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'pkkt.edu.my',
    name: 'Kuala Terengganu City Polytechnic',
    web_page: 'http://www.pkkt.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL9aLfau9FY58AlzoRP01GyphwOOZn1UmhhVvWNQ3xPeMToMv6GEICmVlxBw',
    id: 5799,
    acronym: 'KTCP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'pmm.edu.my',
    name: 'Merlimau Polytechnic',
    web_page: 'http://www.pmm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDK3sK7CpPPm0MQoqtt4pGulQI1it09JFDy3kngMN9Kg6zd0XhUn6Ttvw',
    id: 5800,
    acronym: 'MP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'polijb.edu.my',
    name: 'Johore Bharu Primeir Polytechnic',
    web_page: 'http://www.polijb.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMvbkGn6_0jz-VXTHdY_9qSGwcAsaEw2MNolaK0vXI66l5Ay56UO0mBg',
    id: 5801,
    acronym: 'JBPP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'poliku.edu.my',
    name: 'Kuching Polytechnic',
    web_page: 'http://www.poliku.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZnAuf7XFSOQZ3uoqxhuKg2q7Jg_fxFAnyDnbcTq-1qcbFuIUOxhRiC6G1',
    id: 5802,
    acronym: 'KP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'polimas.edu.my',
    name: 'Sultan Abdul Halim Muadzam Shah Polytechnic',
    web_page: 'http://www.polimas.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9cKt57UWIm9HVnbM2385SQQb53UEDGNE3KusAqjc_O7J0wGe3ZFP4KM4',
    id: 5803,
    acronym: 'SAHMSP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'polimelaka.edu.my',
    name: 'Melaka City Polytechnic',
    web_page: 'http://www.polimelaka.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRHiyDyHhRQsw0jyanzgLn0v-9qc7KQsCDLwbI6QslGo3UhNuBIB1WUNE',
    id: 5804,
    acronym: 'MCP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'polimuadzam.edu.my',
    name: 'Muadzam Shah Polytechnic',
    web_page: 'http://www.polimuadzam.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc4Oyacuu7TG7HzTokwww8QyoKiPWzDwWg-u_GyvlI2nxABn9HQvgPx88',
    id: 5805,
    acronym: 'MSP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'polipd.edu.my',
    name: 'Port Dickson Polytechnic',
    web_page: 'http://www.polipd.edu.my/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSqtzP3oXGcDqdoIlRti9bjFlGAH5qBELlXEPpISohvKtn3j_HK_EhPoDs',
    id: 5806,
    acronym: 'PDP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'polisas.edu.my',
    name: 'Sultan Haji Ahmad Shah Polytechnic',
    web_page: 'http://www.polisas.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4pBIWxTbWJoJabgV9fgoO2fQiqYP88GjUgyRMP64mWBv0-LRWznXabA',
    id: 5807,
    acronym: 'SHASP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'psa.edu.my',
    name: 'Sultan Salahuddin Abdul Aziz Shah Polytechnic',
    web_page: 'http://www.psa.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXvdIXRl6v7z1xMyzM5xIpwte7Wxcmuo2vz-WaZ-mzPXwaw0eE2aPEr5ry',
    id: 5808,
    acronym: 'SSAASP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'psas.edu.my',
    name: 'Sultan Azlan Shah Polytechnic',
    web_page: 'http://www.psas.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI3BLCt84ehrq9EcNwUiN7StSpxaY9plG-KT_Sj3RPaYyl_BFpGvylkMuh',
    id: 5809,
    acronym: 'SASP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'psis.edu.my',
    name: 'Sultan Idris Shah Polytechnic',
    web_page: 'http://www.psis.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTV9Eeh7eH-iU197WNh24010SdSxKDxz-ci7hj6dtC9zWVRMky9UCFUSQ',
    id: 5810,
    acronym: 'SISP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'psmza.edu.my',
    name: 'Sultan Mizan Zainal Abidin Polytechnic',
    web_page: 'http://www.psmza.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4AYZ0Fi8UR23FVkcgcSqmwo_UzRSDTVQUnrFpYRlkxR9FqzKPxz36lA',
    id: 5811,
    acronym: 'SMZAP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'psp.edu.my',
    name: 'Seberang Perai Polytechnic',
    web_page: 'http://www.psp.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkUPJW5TeE6v3hGw5nPVHoewctcFeR4irBi51OcxkWcfVEvN43Q68WjQ',
    id: 5812,
    acronym: 'SPP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ptpl.edu.my',
    name: 'PTPL College',
    web_page: 'http://www.ptpl.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWHjdHyAa_rR3dViUSkn7BQuNVJ1Qo2K7bH4GmNOJhluDmi7h5oEjsDw',
    id: 5813,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ptsb.edu.my',
    name: 'Sultanah Bahiyah Polytechnic',
    web_page: 'http://www.ptsb.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHwQWTLJGRoIbJjzh28KByWAIVdKEvJ8hL7f7LaiCf6fgajsxiWuKb_3A',
    id: 5814,
    acronym: 'SBP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ptss.edu.my',
    name: 'Tunku Syed Sirajuddin Polytechnic',
    web_page: 'http://www.ptss.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR00E6zNT3CP9-0c73jsPGLHC_Uj8Gt6JAWWUn5Y6Fx1SgnsdIzApY-ybvX',
    id: 5815,
    acronym: 'TSSP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'puo.edu.my',
    name: 'Ungku Omar Premier Polytechnic',
    web_page: 'http://www.puo.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSabnGp_vHdt9Th0G46AkTZhU9eXMlrijnvxqEF5-N6Bjm-3g2SjQe6Ohs',
    id: 5816,
    acronym: 'UOPP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'raffles-university.edu.my',
    name: 'Raffles University',
    web_page: 'http://www.raffles-university.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQonhROjzsOKm-F8_4nB0eM5ym-YFdZROu-bPZ4CODfKhvquv_sBil8rWc',
    id: 5817,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'saito.edu.my',
    name: 'Saito College',
    web_page: 'http://www.saito.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfBhrafM8m-fQnyA6GIyjzuUu1RCtYtgBDL1_hkRzEBAN1hCWyZCSPclk',
    id: 5818,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'segi.edu.my',
    name: 'Segi University College',
    web_page: 'http://www.segi.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJwPlZSJ5inpm0JNpe66gQhN4AAe5vrFXmybawPF_MVZN3VP4IdxpUwT3Q',
    id: 5819,
    acronym: 'SUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'sunway.edu.my',
    name: 'Sunway University College',
    web_page: 'http://www.sunway.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ29fDgB-zvncaMWoIcrkb7_1NLrSHBRyocG-EyUMu3OtqmqqO_pvJrAI',
    id: 5820,
    acronym: 'SUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'swinburne.edu.my',
    name: 'Swinburne University of Technology Sarawak Campus',
    web_page: 'http://www.swinburne.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsqY6HFuZpq_eK988vvZgRySfxeAxbcnApUkGIOAhguLfCvPfAMRbdh1mL',
    id: 5821,
    acronym: 'SUTSC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'taj.edu.my',
    name: 'Taj International College',
    web_page: 'http://www.taj.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYJdpJfFPaT1HJa1XcuiIqQAjtG8fZ-75tVavkg733rJ7zdtpap6QLWak',
    id: 5822,
    acronym: 'TIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'tarc.edu.my',
    name: 'Tunku Abdul Rahman Chinese College',
    web_page: 'http://www.tarc.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc9W5dVTiVnt4XyjdLVxgq9YmBGaUHVoiOhyEkMWro7NXr5-GDGggnW4o',
    id: 5823,
    acronym: 'TARCC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'taylors.edu.my',
    name: "Taylor's University College",
    web_page: 'http://www.taylors.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE5BoPkuR37PpBW9q6xXZLIREgoMG_v47K0IVX9ss2Oezqpmt4Dd3TLQ',
    id: 5824,
    acronym: 'TUC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'tpmcollege.edu.my',
    name: 'TPM College',
    web_page: 'http://www.tpmcollege.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtRkkXhWOuBb9VfVDKCn4jophteDqzoWUX5NRvV6Dloq6u5KSf1vOQm1Y',
    id: 5825,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ucsi.edu.my',
    name: 'UCSI University',
    web_page: 'http://www.ucsi.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1Y2kD0QlExKIG42yhBeemUfr-Smcoaysyq8c21Ul6AZwaVu6xOBTFwUQ',
    id: 5826,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ucti.edu.my',
    name: 'University College of Technology & Innovation (UCTI)',
    web_page: 'http://www.ucti.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmzbxo_JJX-u-YL5JMIVQHum8sJLf2TmLbswZ9Y8ckmemj_Ip6r9Ee8pM',
    id: 5827,
    acronym: 'UCT&I(',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'udm.edu.my',
    name: 'Universiti Darul Iman',
    web_page: 'http://www.udm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJjH2MplMTbyIPADigHlXw5Sv6mYkjbQV7QI6_BKabyZG2ISo-eaiIWw',
    id: 5828,
    acronym: 'UDI',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'uitm.edu.my',
    name: 'Universiti Teknologi Mara',
    web_page: 'http://www.uitm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLy3BFuzVy71LKHHjzEFLTm_5UKcmem9TE_JuVd7fEqnvvweLDXQFlmw',
    id: 5829,
    acronym: 'UTM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ukm.my',
    name: 'Universiti Kebangsaan Malaysia',
    web_page: 'http://www.ukm.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZl7lyDjbEQPwQTCBuEdl2-aPwpK-2_qsXjtrM3o3NaOHfR-q-v1AyEDQ',
    id: 5830,
    acronym: 'UKM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'um.edu.my',
    name: 'Universiti Malaya',
    web_page: 'http://www.um.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkTUbaLyHqJ18Oy916x-BSD4MsAtVEA6Dfl_4odFCWyWAhM5RGB0G0bQ',
    id: 5831,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'umk.edu.my',
    name: 'Universiti Malaysia Kelantan',
    web_page: 'http://www.umk.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW6CP84yIDiLuak_6hDDfxI8N13TvC9EUCLYvW-6e9IRLxjNnlbcuKGg',
    id: 5832,
    acronym: 'UMK',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ump.edu.my',
    name: 'University Malaysia Pahang',
    web_page: 'http://www.ump.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZFjZikKLZkN38wSh0AfU-FPpp4V2gqFEOw4BZFtQj2jAq_5u7XhBc1w',
    id: 5833,
    acronym: 'UMP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'ums.edu.my',
    name: 'Universiti Malaysia Sabah',
    web_page: 'http://www.ums.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5Yv6uYV8x8Q9fvckuXmXuQOuNalMvsP_FNZYH7ueYoTZ1r_Yw3iFb9A',
    id: 5834,
    acronym: 'UMS',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'umtech.edu.my',
    name: 'University of Management and Technology',
    web_page: 'http://www.umtech.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOnPvKEXIBDcQGRQhK7oyDUWPxU_9WWBLQvNLghJAkr_t7peFRIfRjqg',
    id: 5835,
    acronym: 'UMT',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'umt.edu.my',
    name: 'Universiti Malaysia Terengganu',
    web_page: 'http://www.umt.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0x74nq9_Ca6effJOD9mRzgcU1aMXhoc2-TobkIpBds2nnT7E-jZ41Ug',
    id: 5836,
    acronym: 'UMT',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'unikl.edu.my',
    name: 'Universiti Kuala Lumpur',
    web_page: 'http://www.unikl.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4GBCcV9JvGY5sE_tycY-1qoQUDX7ycnoXBly2N8ofoL3tUervW_X2XMM',
    id: 5837,
    acronym: 'UKL',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'unimap.edu.my',
    name: 'Universiti Malaysia Perlis',
    web_page: 'http://www.unimap.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfFU-rkKtCTRBS3xtwjOKoPRuNzJ8I_qpk9PCRRVI6fDrPYhjjsVrHNX0',
    id: 5838,
    acronym: 'UMP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'unimas.my',
    name: 'Universiti Malaysia Sarawak',
    web_page: 'http://www.unimas.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdwpzzYCLGe6MAY7Hi2XNb69JsFbBpEJkxXI8coaZ_tqGcbr8FGvJ4Ng',
    id: 5839,
    acronym: 'UMS',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'unirazak.edu.my',
    name: 'University Tun Abdul Razak',
    web_page: 'http://www.unirazak.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTnr7qJkmCtsF_UMyGaLphvZNZvPzb93N-oyH8oUgSURjk0AF0qJa4nd4b',
    id: 5840,
    acronym: 'UTAR',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'unisel.edu.my',
    name: 'Universiti Industri Selangor',
    web_page: 'http://www.unisel.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEjWW-1bvCEm6uWO-qXyd_9cfmFEpxJfyOwHgHS5JXG3Aawz5vLQEYKQ8',
    id: 5841,
    acronym: 'UIS',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'unisza.edu.my',
    name: 'Universiti Sultan Zainal Abidin',
    web_page: 'http://www.unisza.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt7iM6RAXmqxyp4sN1DT2Da6h7PumjxhkgRxoQaa_jcIZYK5ezqQKcD0v2',
    id: 5842,
    acronym: 'USZA',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'unitar.edu.my',
    name: 'Universiti Tun Abdul Razak',
    web_page: 'http://www.unitar.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTnr7qJkmCtsF_UMyGaLphvZNZvPzb93N-oyH8oUgSURjk0AF0qJa4nd4b',
    id: 5843,
    acronym: 'UTAR',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'uniten.edu.my',
    name: 'Universiti Tenaga Nasional',
    web_page: 'http://www.uniten.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSYOTvu5ym8cVTkldGshQuWN4vhPKUQSRr5ABqRy8o4BdKkuKZa1PDte8',
    id: 5844,
    acronym: 'UTN',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'upm.edu.my',
    name: 'Universiti Putra Malaysia',
    web_page: 'http://www.upm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9noGs3iYgWJzR_3qAFsYfxqkBvQbV0WdGiSL2lfDwESepSNzvZEtLETA',
    id: 5845,
    acronym: 'UPM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'upsi.edu.my',
    name: 'Universiti Pendidikan Sultan Idris',
    web_page: 'http://www.upsi.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFliGzc0WLFNX8M4h93tFsiiGXV5UvQmyjfAVQcD2l-FR9N2KqxmX1drs',
    id: 5846,
    acronym: 'UPSI',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'usm.my',
    name: 'Universiti Sains Malaysia',
    web_page: 'http://www.usm.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7a_cCwllOv0dKtNLoVSpOKShdvLDa9aYTEx4BE1HieoArY-GoCx_gHZI',
    id: 5847,
    acronym: 'USM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'utar.edu.my',
    name: 'Tunku Abdul Rahman University (Chinese University)',
    web_page: 'http://www.utar.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRc9W5dVTiVnt4XyjdLVxgq9YmBGaUHVoiOhyEkMWro7NXr5-GDGggnW4o',
    id: 5848,
    acronym: 'TARU(U',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'utar.edu.my',
    name: 'Universiti Tunku Abdul Rahman',
    web_page: 'http://www.utar.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNxvQOjs8lNeU_PD04RZ-_IdpQAdVMStOY2KZ-Zvy1eA6mtQ_iFaci5es',
    id: 5849,
    acronym: 'UTAR',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'utem.edu.my',
    name: 'Universiti Teknikal Malaysia Melaka',
    web_page: 'http://www.utem.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTDGG7Eewkj5td6kVYob1JicJTDx3Tsi-_x2kJc2cnUeYJpAq9k8coO3Sg',
    id: 5850,
    acronym: 'UTMM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'uthm.edu.my',
    name: 'Universiti Tun Hussein Onn Malaysia',
    web_page: 'http://www.uthm.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXwXmSrOrvWh41UwTKwWbO7O1AFinuefcqOlJb3VXe_iWWR9hsm4RIow',
    id: 5851,
    acronym: 'UTHOM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'utm.my',
    name: 'Universiti Teknologi Malaysia',
    web_page: 'http://www.utm.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKxJGa1sIMZ9U106dKP58h8Ce6cLHxAjH3hT9OjwSP7dc4DELaBJjiC448',
    id: 5852,
    acronym: 'UTM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'utp.edu.my',
    name: 'Petronas Technology University',
    web_page: 'http://www.utp.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyo6ILz31feXR-gdEmfN_42djkwN2zLZxcm75sW9YLsJig0Z_2uFmAdNw',
    id: 5853,
    acronym: 'PTU',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'utp.edu.my',
    name: 'Universiti Teknologi Petronas',
    web_page: 'http://www.utp.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVWHuykSNYf0L--utZw1AeM09osLnVqtPJ9jv0IywBqeLI8hB6_2IQ49JZ',
    id: 5854,
    acronym: 'UTP',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'uum.edu.my',
    name: 'Universiti Utara Malaysia',
    web_page: 'http://www.uum.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4lOZxFAPh-UL8QwSDpHZQCUD8i9FojFYhXODYafa0xrgyDnaIvGyoZiY',
    id: 5855,
    acronym: 'UUM',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'westminster.edu.my',
    name: 'West Minster International College',
    web_page: 'http://www.westminster.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFFQG_BliXwIItHazFknenacptDrvl6WREi0mn8JjHg520swHeidgxYTvd',
    id: 5856,
    acronym: 'WMIC',
  },
  {
    alpha_two_code: 'MY',
    country: 'Malaysia',
    domain: 'wou.edu.my',
    name: 'Wawasan Open University',
    web_page: 'http://www.wou.edu.my/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2uOnQs93ACpmSbXaPWVKsfw6Vn4v4sDUoNYXkqkoFXvgM7SVgOEcrIc4',
    id: 5857,
    acronym: 'WOU',
  },
  {
    alpha_two_code: 'MV',
    country: 'Maldives',
    domain: 'cyryxcollege.edu.mv',
    name: 'Cyryx College',
    web_page: 'http://www.cyryxcollege.edu.mv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrvnlj06r3VN5VNtiAQTOumgylOFkMpPrk7j7OeeWOwcv5CoRdOxR_zeo',
    id: 5858,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'MV',
    country: 'Maldives',
    domain: 'mandhu.com',
    name: 'Mandhu College',
    web_page: 'http://www.mandhu.com/edu/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSKZBr_K9hG1AiiZ9i4jAPMHHMrAIGU5bdDHcIAckWnsTFAeFv4vPI0EzY',
    id: 5859,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'MV',
    country: 'Maldives',
    domain: 'mnu.edu.mv',
    name: 'Maldives National University',
    web_page: 'http://www.mnu.edu.mv/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtUVA25rpMbQasbbxcSZxQwWDyQf6aX6GS2rqfpl7RPT-qi398-M_PY-s',
    id: 5860,
    acronym: 'MNU',
  },
  {
    alpha_two_code: 'ML',
    country: 'Mali',
    domain: 'ml.refer.org',
    name: 'Université de Bamako',
    web_page: 'http://www.ml.refer.org/u-bamako/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRBzpyyg8F2uSDYm_W_tlcTRzyWXj6A1VIVz2FpR7hDFPruOp4k3daVg',
    id: 5861,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'MT',
    country: 'Malta',
    domain: 'eieonline.com',
    name: 'European Institute of Education',
    web_page: 'http://www.eieonline.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRmX6ZAKIwOhtkJReaAoKC4JIt-1xURp_yTaC0fukH-gtUKu7IwVXw-A',
    id: 5862,
    acronym: 'EIE',
  },
  {
    alpha_two_code: 'MT',
    country: 'Malta',
    domain: 'itismalta.com',
    name: 'International Tourism Institute',
    web_page: 'http://www.itismalta.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0Am1WUWPsVqdqHiXHY9rqbBL4iZhsGM6OrZs6Q3HGOzN74-FwCBRQlaFybA',
    id: 5863,
    acronym: 'ITI',
  },
  {
    alpha_two_code: 'MT',
    country: 'Malta',
    domain: 'um.edu.mt',
    name: 'University of Malta',
    web_page: 'http://www.um.edu.mt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDab7dh4t3mDTx4jLQ0V2XL7V-Zh4chFtoPFQnSDb0Sz_tqg3iSNoAHQ',
    id: 5864,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'MR',
    country: 'Mauritania',
    domain: 'univ-nkc.mr',
    name: 'Université de Nouakchott',
    web_page: 'http://www.univ-nkc.mr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD57J6dt360Q7TViJ-pk_SZcjKZIWuh4kT3DoP-LQsqPAeE0x2D0bnf9s',
    id: 5866,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'MU',
    country: 'Mauritius',
    domain: 'uom.ac.mu',
    name: 'University of Mauritius',
    web_page: 'http://www.uom.ac.mu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTntkYeBe54uRfIr7MY98_tFkD4J9R0cFKzP12EsYxhBDxtrueve0zcLlBp',
    id: 5867,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'MU',
    country: 'Mauritius',
    domain: 'utm.ac.mu',
    name: 'University of Technology',
    web_page: 'http://www.utm.ac.mu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkYinbTDwrBqyFK-NvnHG7ZD6KHSgBcB7HTNE6cbVfUdb8lWse4AVdbX8',
    id: 5868,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'anahuac.mx',
    name: 'Universidad Anáhuac',
    web_page: 'http://www.anahuac.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY4L89e2EoFF2gYePAp2bcGK6rQTY6hNE6Y35qWwSNEvXExV3YymlXxw',
    id: 5869,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'bajio.delasalle.edu.mx',
    name: 'Universidad de La Salle Bajío',
    web_page: 'http://bajio.delasalle.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8kdVe2VZ1S5LIC9GarCE0KE2TQ3SBShCoawi3FcRO5JyXKfbtvRGlqA',
    id: 5870,
    acronym: 'UDLSB',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'buap.mx',
    name: 'Benemerita Universidad Autónoma de Puebla',
    web_page: 'http://www.buap.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT5NZomGhzwiXJ-9HQwZQZuzEojD3I-isC5ug7kdzhZG6dM8axbcOoJr4',
    id: 5871,
    acronym: 'BUADP',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'campusleon.ugto.mx',
    name: 'Universidad de Guanajuato Campus León',
    web_page: 'http://www.campusleon.ugto.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMuuQo_HVPWTdLLLTlNGC_Fc2ulN5BBt4Fd7I2BSd4dQ6tqLy6--Tt-g',
    id: 5872,
    acronym: 'UDGCL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ceti.mx',
    name: 'Centro de Enseñanza Técnica Industrial',
    web_page: 'http://www.ceti.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlAYUaTUJJmICPiX155jkQKjI-CQmu2vf8QKJ-9HbumziZu70TPROja6I',
    id: 5873,
    acronym: 'CDETI',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'cetys.mx',
    name: 'CETYS Universidad',
    web_page: 'http://www.cetys.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB7lQxZEZwdx2irBx5NJJ8-BsgfkVDvYS_ZjSxW-2lOpJZVjxmUFsDQsI',
    id: 5874,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ceu.edu.mx',
    name: 'Centro de Estudios Universitarios Monterrey',
    web_page: 'http://www.ceu.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3lxwB0pV0iAcCArTN1HIDukvn7TxuFpoH1XX3kUAZaujc9kvVZMUeXhQ',
    id: 5875,
    acronym: 'CDEUM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ceunix.com.mx',
    name: 'Centro Universitario Ixtlahuaca',
    web_page: 'http://www.ceunix.com.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9fRDCH_svM1vwAmGALoZ4ohd3mJQuziUUEZc_PrnIqCtrdsCjlhlOfJVT',
    id: 5876,
    acronym: 'CUI',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ceux.mx',
    name: 'Centro de Estudios Universitarios Xochicalco',
    web_page: 'http://www.ceux.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYv89Nm8-IU01hlIfRY37KgOmVaSsPxpGcRZ3vRgrib0-g_odJDPe3WYg',
    id: 5877,
    acronym: 'CDEUX',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'chapingo.mx',
    name: 'Universidad Autónoma de Chapingo',
    web_page: 'http://www.chapingo.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWhT4rKcKay6SPZIjF8GJPrS7TTsyXPp3XHYax_b-hUQuB53FnrJTlWYQ',
    id: 5879,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'colmex.mx',
    name: 'El Colegio de México',
    web_page: 'http://www.colmex.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_py8vdMcU8DfYknMKsjDcFuhB3h7bxiCEDi4jDjRtlIY8iWOcg4mpKj52',
    id: 5880,
    acronym: 'ECDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'cuauhtemoc.edu.mx',
    name: 'Universidad Cuauhtémoc',
    web_page: 'http://www.cuauhtemoc.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjQSFrMd1NFaD71qwWbEylcHiaXxW34liV2chWVcFs2SwiB0IgY3trSQ',
    id: 5881,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'cueyatl.uam.mx',
    name: 'Universidad Autónoma Metropolitana - Xochimilco',
    web_page: 'http://cueyatl.uam.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY6zVdWzaA-damwQ1MzOXgozqEVv4Ki7yD2dQswp7eTEigLMFRzORrU0o',
    id: 5882,
    acronym: 'UAM-X',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'dgit.gob.mx',
    name: 'Dirección General de Institutos Tecnológicos',
    web_page: 'http://www.dgit.gob.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8Poro7j8E0OuazW5oKkG9bzB-PxYBuY3LWgQMA3NjiOhJUwNZy8KuEbA',
    id: 5883,
    acronym: 'DGDIT',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ebc.mx',
    name: 'Escuela Bancaria y Comercial',
    web_page: 'http://www.ebc.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlNCPOCNHJSIYk-99kD6LGDy4Z-l9RnkV2puduL39CuryQoDlelOZMSI4',
    id: 5884,
    acronym: 'EBYC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'enes.unam.mx',
    name: 'Escuela Nacional de Estudios Superiores Unidad León',
    web_page: 'http://www.enes.unam.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_XO_sWctWzdj6f8orV26_JcTdDTgFBgKoRDQm_SHigbj9bGWt5YxZ-Q',
    id: 5885,
    acronym: 'ENDESUL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'frayluca.edu.mx',
    name: 'Universidad Fray Luca Paccioli',
    web_page: 'http://www.frayluca.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbzSW5eCNo7LjfzwpKe0AdHbP8KMpZhc56sWuSF_FkIbx9SeUDzNwp_MoIDQ',
    id: 5886,
    acronym: 'UFLP',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'i.edu.mx',
    name: 'Universidad Itaca',
    web_page: 'http://www.i.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7pKdQCwgmKk6hPb20cLgTKc6qQ6eNci2FMjsLEwPuwygcG5xM2imv3ZI',
    id: 5888,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ipn.edu.mx',
    name: 'Instituto Politécnico Nacional',
    web_page: 'http://www.ipn.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNHSFag1_tXgE4uZffGRVZMSfahXzjQy6Tx2Kw3LW4lXMRRr3wPwa4VIw',
    id: 5889,
    acronym: 'IPN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itam.mx',
    name: 'Instituto Tecnológico Autonómo de México',
    web_page: 'http://www.itam.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS23Ghoubxsjgs7YCraQjCeR0014HFgbbHdx-nps47lRyNhfDfYejEHSGk',
    id: 5890,
    acronym: 'ITADM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ita.mx',
    name: 'Instituto Tecnológico de Aguascalientes',
    web_page: 'http://www.ita.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK-z8sjLZFNiZJCEx-cTMAHbFIAp6_LbSTpOBLc3l7oc9Usyk6zPWeF_3u',
    id: 5891,
    acronym: 'ITDA',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itc.mx',
    name: 'Instituto Tecnológico de Celaya',
    web_page: 'http://www.itc.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQitqYRv5Gc5p6A0yuA0x8gZHTbEej0C9Pl1Z3HZRQhGkI_R1fLV7AZJCM',
    id: 5892,
    acronym: 'ITDC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itdurango.edu.mx',
    name: 'Instituto Tecnologico de Durango',
    web_page: 'http://www.itdurango.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3sra0PRQ0_ffuowHI2RVE9dXaiW8KAtA657ZfM8D9yj35QBUdA8QvBg',
    id: 5893,
    acronym: 'ITDD',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itesm.mx',
    name: 'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)',
    web_page: 'http://www.itesm.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6bG185G-wYc2610G2gmgZTIlJ6nqX_l2hs4a4UqV6IKfW71o7DEjjSPI',
    id: 5894,
    acronym: 'ITYDESDM(',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'iteso.mx',
    name: 'Instituto Tecnológico y de Estudios Superiores de Occidente',
    web_page: 'http://www.iteso.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyeEouUgX28qZUYTdgmbyEXFnHtD9hcIjd3cZSWMJ_9hxfahENnUYEYQ',
    id: 5895,
    acronym: 'ITYDESDO',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itleon.edu.mx',
    name: 'Instituto Tecnológico de León',
    web_page: 'http://www.itleon.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnrf_PdkrZRFHzLG0FnNBlY61ssSN_eRjTw2unXidf0nI7EYfYqje1DeM',
    id: 5896,
    acronym: 'ITDL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itmina.edu.mx',
    name: 'Instituto Tecnologico de Minatitlan',
    web_page: 'http://www.itmina.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDDjaVS6WYW5tySbLeAfi7nmP6DATOyXaujRYSy4WUEekjTn3AgdG-oA',
    id: 5897,
    acronym: 'ITDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itmorelia.edu.mx',
    name: 'Instituto Tecnológico de Morelia',
    web_page: 'http://www.itmorelia.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBJ1jol9JpLoV3oFz2ijwOu9O9f-HvPB7_asPl6IzES0TWuSnvumHfA0Q',
    id: 5898,
    acronym: 'ITDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itq.edu.mx',
    name: 'Instituto Tecnológico de Querétaro',
    web_page: 'http://www.itq.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9pZbGwJArAn9A22fGj-I_azGhwfuJefMe2aY-itfln20X2-foIvOB7us',
    id: 5899,
    acronym: 'ITDQ',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itslp.edu.mx',
    name: 'Instituto Tecnológico de San Luis Potosí',
    web_page: 'http://www.itslp.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmaGslbJajhUpAVqphZeVJrD4C7AbX6DgIRGhjlkZUQZmoFh1ecwfnrQ',
    id: 5900,
    acronym: 'ITDSLP',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'itson.mx',
    name: 'Instituto Tecnológico de Sonora',
    web_page: 'http://www.itson.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4cOQ6J3hBoul0Y5yrZvbzYx-RWiTb593d6MLZ_poyzcLTHIndJETMLXk',
    id: 5901,
    acronym: 'ITDS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'iztapalapa.uam.mx',
    name: 'Universidad Autónoma Metropolitana - Iztapalapa',
    web_page: 'http://www.iztapalapa.uam.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMkpN5GEVaUR70cEtGEFl-oa72-HFAmHde5ZjCB5PBxH6-Jsc3MS0b8Hcg',
    id: 5902,
    acronym: 'UAM-I',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'lef.upn.mx',
    name: 'Universidad Pedagógica Nacional Unidad León',
    web_page: 'http://www.lef.upn.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYPeI3etzxxeR6cfDgTrW4u3CY9424OoFXX73cKbS8malmUxmLFUCPcw',
    id: 5903,
    acronym: 'UPNUL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'leon.uia.mx',
    name: 'Universidad Iberoamericana Campus León',
    web_page: 'http://www.leon.uia.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRawCYpvWkcC-yMLkWUl9Tftm-jNmsZiaqPXem0iS4P2PHKOo8tMqGAOs',
    id: 5904,
    acronym: 'UICL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'members.rotfl.com',
    name: 'Universidad del Valle de Puebla',
    web_page: 'http://members.rotfl.com/uvp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxDqI1fE3OKXPHF9pt9362CEEC5BvS7qC7XpEB0nPEoh5QtMu6R-FkqA',
    id: 5905,
    acronym: 'UDVDP',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'motolinia.com.mx',
    name: 'Universidad Motolinía del Pedregal',
    web_page: 'http://www.motolinia.com.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRILrsLkDBiB0qHMjp39TEN4sFUdTvwzyvCPOxbtPR5lMky0T3FEzKa4g',
    id: 5906,
    acronym: 'UMDP',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'olmeca.edu.mx',
    name: 'Universidad Olmeca',
    web_page: 'http://www.olmeca.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROHcExa26lRWXThY4Tb4pK0klqX12t0eLsqwik5SYbpBHMmHtwXK4Xnyy9',
    id: 5907,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'poligto.edu.mx',
    name: 'Politécnico de Guanajuato',
    web_page: 'http://www.poligto.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLaanZ9RiieqpJPOg7miLpPPCSuUBsThioA27Pw1Ga2g2m4dMzFA1QdBA',
    id: 5908,
    acronym: 'PDG',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'reduaeh.mx',
    name: 'Universidad Autónoma del Estado de Hidalgo',
    web_page: 'http://www.reduaeh.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrd0ubmbeDPrWy8OCekTrU2dVHJIz5e-pC3z8h9WpVmY5MPeae6T5wLw',
    id: 5909,
    acronym: 'UADEDH',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'reduaz.mx',
    name: 'Universidad Autónoma de Zacatecas',
    web_page: 'http://www.reduaz.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzxVkrdTEE4Qx9KaNOykDrnK-UxD0FUJKwOk2ORZndN3MsGbwxL6Ejg0A',
    id: 5910,
    acronym: 'UADZ',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'secupv.org',
    name: 'Universidad Pedagógica Veracruzana',
    web_page: 'http://www.secupv.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWj5Lr2s6xngoPWQyizvmkiN2ccCCT3I-rnrGFKBTMWRCusr1M02wfIc8',
    id: 5911,
    acronym: 'UPV',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'tecbc.mx',
    name: 'Tecnologico de Baja California Universidad',
    web_page: 'http://www.tecbc.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4CciAV00z9euTg--bhYrknudUlkDIo-EzNv9AECulMrjsRgLklhjrNQ',
    id: 5912,
    acronym: 'TDBCU',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'tepeyac.edu.mx',
    name: 'Universidad del Tepeyac',
    web_page: 'http://www.tepeyac.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3Bg8KtizsajMbAIHADN13cQAnCFl5VlFl1NiJXRF06-sz-nNPlxDl4g',
    id: 5913,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ties.com.mx',
    name: 'Instituto de Estudios Superiores de la Sierra',
    web_page: 'http://www.ties.com.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVK8gUHEQ4LtRde2Ho-cGZD34jMkTeewZFfBcB-89xrr7kcHW1Ep1HPX8',
    id: 5914,
    acronym: 'IDESDLS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uaaan.mx',
    name: 'Universidad Autónoma Agraria "Antonio Narro"',
    web_page: 'http://www.uaaan.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbpBgd6u4Sl5xXjyGb18mbnnnRxRoVlS6sMBbEixe10iKFCzps1uF_J9ne',
    id: 5915,
    acronym: 'UAA"N',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uaa.mx',
    name: 'Universidad Autónoma de Aguascalientes',
    web_page: 'http://www.uaa.mx:8001/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAYtCnxrLlznUn-h9kytGGtB1fi6_XDBGaFbgwpCjZVlWJhXE063Zotg',
    id: 5916,
    acronym: 'UADA',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uabc.mx',
    name: 'Universidad Autónoma de Baja California',
    web_page: 'http://www.uabc.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQithfGScu94HuZQO1LlDYf8rYbR3zge-vsTKJOxYvw1Z3PAFpOqML83Kg',
    id: 5917,
    acronym: 'UADBC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uabcs.mx',
    name: 'Universidad Autónoma de Baja California Sur',
    web_page: 'http://www.uabcs.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXWdPUlz6drCTJZU_jUMlflopG37EvX9Xj_fu9gat1BZna_7W6xpAXdXii',
    id: 5918,
    acronym: 'UADBCS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uabjo.mx',
    name: 'Universidad Autónoma "Benito Juárez" de Oaxaca',
    web_page: 'http://www.uabjo.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYPCH16SJsX1V0NSzVRG2GCI2jrC2bMcQRfzjJt_wf7eNE4-GErjfl2w',
    id: 5919,
    acronym: 'UA"JDO',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uacam.mx',
    name: 'Universidad Autónoma de Campeche',
    web_page: 'http://www.uacam.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHNUEdsslSZ0E3wFyMfW4pkefxsrj2ANYHV1V_DDNToy6yjg77fS0U9Uc',
    id: 5920,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uach.mx',
    name: 'Universidad Autónoma de Chihuahua',
    web_page: 'http://www.uach.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX5zQGB6GTX9q8YWTL1uP3t99xHraxxPG4wxO98fYY3VhkVEI1XkEdBd0m',
    id: 5921,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uacj.mx',
    name: 'Universidad Autónoma de Ciudad Juárez',
    web_page: 'http://www.uacj.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBXqUEmMHBL6G1_3xs3T2AlQ9GvNBywcm38LwVgOVIeCDR9yFYTyxYI-I',
    id: 5922,
    acronym: 'UADCJ',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uacm.edu.mx',
    name: 'Universidad Autónoma de la Ciudad de México',
    web_page: 'http://www.uacm.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJui4fq7C_dyo5d_efQpdQYGuHUz28V3z7MKAJXhb6ERqQYfjkC0rFSubf',
    id: 5923,
    acronym: 'UADLCDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uadec.mx',
    name: 'Universidad Autónoma de Coahuila',
    web_page: 'http://www.uadec.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqFXzJ2JXU52Qr3eJyZWH2vzsQM7iZf_mT4Iu6pNQeRya-lWNssED8mkM',
    id: 5924,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uad.edu.mx',
    name: 'Universidad Autonoma  de Durango',
    web_page: 'http://www.uad.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRflVauZUQlvQfiqdg8WaZ6dNaxiLa2bOPu2JmkynJknawW483Dd3w-FrQ',
    id: 5925,
    acronym: 'UADD',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uady.mx',
    name: 'Universidad Autónoma de Yucatán',
    web_page: 'http://www.uady.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPB0DxzJ6NKoje-jzt4040_OLfb-ZYa2l9bPN-MTRv3C03Ack9zbQvY_I',
    id: 5926,
    acronym: 'UADY',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uaed.edu.mx',
    name: 'Universidad Autonoma España de Durango',
    web_page: 'http://www.uaed.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnuiqQxGbUV0e0Cak7vI01Q-H6OPpJO2US_TmxjEAsiKY-vE-nniI1JYE',
    id: 5927,
    acronym: 'UAEDD',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uaemex.mx',
    name: 'Universidad Autónoma del Estado de México',
    web_page: 'http://www.uaemex.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjclnLtDZqnziyFvbsYHMa8x272OupE-UuM-40HCajKmM0hHVOKgT9Ww',
    id: 5928,
    acronym: 'UADEDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uaem.mx',
    name: 'Universidad Autónoma del Estado de Morelos',
    web_page: 'http://www.uaem.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAN3ETAflk9q3i7QS7PqFAQLupBYhQumX04vC7kEuKLYUv4Rym_XIsbm0',
    id: 5929,
    acronym: 'UADEDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uaf.mx',
    name: 'Universidad Autónoma de Fresnillo',
    web_page: 'http://www.uaf.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUiyxVTWsF_vpimvib0y5Y0OPjPV2zivUvRNoKPGyxUN-R8bdAgx3JXA',
    id: 5930,
    acronym: 'UADF',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uag.mx',
    name: 'Universidad Autónoma de Guadalajara',
    web_page: 'http://www.uag.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRALTlTxt8rDAL6Wfw40VtV9EUmtV_EOuKn6tQUARJfcY_XfAyVVMk-i64',
    id: 5931,
    acronym: 'UADG',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uagro.mx',
    name: 'Universidad Autónoma de Guerrero',
    web_page: 'http://www.uagro.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd6Oki1bIt656pR2Wuw5kkhd6DGxUWj_7xLEGJnVUVohl2pkA3yh6ocg',
    id: 5932,
    acronym: 'UADG',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ual.mx',
    name: 'Universidad Autónoma de la Laguna',
    web_page: 'http://www.ual.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAEE0alPUxDY7LKbqDFnW5cDs3u5eZcaw5VC8aZnbXEbIpiw2L9CI03-k',
    id: 5933,
    acronym: 'UADLL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uam.mx',
    name: 'Universidad Autónoma Metropolitana',
    web_page: 'http://www.uam.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY6zVdWzaA-damwQ1MzOXgozqEVv4Ki7yD2dQswp7eTEigLMFRzORrU0o',
    id: 5934,
    acronym: 'UAM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uane.edu.mx',
    name: 'Universidad Autónoma del Noreste',
    web_page: 'http://www.uane.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHtNYRIxAT0CS0CJe1PWb-xLGrM7i5Oi1J0wQpvMVoBTRtEWe2IZncUg',
    id: 5935,
    acronym: 'UADN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uanl.mx',
    name: 'Universidad Autónoma de Nuevo León',
    web_page: 'http://www.uanl.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE-Iim5xlBWFkHrp-Z1IOwz0qcO1oQqKK-hQl41cOirjTVNT9VlnZhiNc',
    id: 5936,
    acronym: 'UADNL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uan.mx',
    name: 'Universidad Autónoma de Nayarit',
    web_page: 'http://www.uan.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROFEk1SY091c_gUvHkuhCAiQxkDRV7SBafQISul-s9t6HRjqUJ_fLFfA',
    id: 5937,
    acronym: 'UADN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uaq.mx',
    name: 'Universidad Autónoma de Querétaro',
    web_page: 'http://www.uaq.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRctbzu-5RTtdUJSjvJmmItj3LLDua3oIhXApiXU_JjrmpGbvKf4EF2NQ',
    id: 5938,
    acronym: 'UADQ',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uaslp.mx',
    name: 'Universidad Autónoma de San Luis Potosí',
    web_page: 'http://www.uaslp.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqveQxAfXpQ0SMP5hl2heiXIc0oY7K2YFjRCXMQk5eODGqu8Gkw1Z0zA',
    id: 5939,
    acronym: 'UADSLP',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uas.mx',
    name: 'Universidad Anáhuac del Sur',
    web_page: 'http://www.uas.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJRSQblJwUTKC5bnxKUAxqxgMnBvUIDWGrpVrykaNkFdjLfGxvpsswPnM',
    id: 5940,
    acronym: 'UADS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uasnet.mx',
    name: 'Universidad Autónoma de Sinaloa',
    web_page: 'http://www.uasnet.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5T_pfKGkQXB7l_SsYVD7nU7XcXPutzhTXTMSmh8466bSi5G-qEsheug',
    id: 5941,
    acronym: 'UADS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uat.mx',
    name: 'Universidad Autónoma de Tamaulipas',
    web_page: 'http://www.uat.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLdefLyJyOKxIeE8kdHV1wiV_LfvIvudoxAvNlfC73Bvw34VvFntZlfxRG',
    id: 5942,
    acronym: 'UADT',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uatx.mx',
    name: 'Universidad Autónoma de Tlaxcala',
    web_page: 'http://www.uatx.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQUtndSz61Gt3lps3gd_eQG5FE_nJcNOxD-mPqG5m-QjxqosaJlYh1GqU',
    id: 5943,
    acronym: 'UADT',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ubac.edu.mx',
    name: 'Universidad del Bajío',
    web_page: 'http://www.ubac.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8ug0nwwbaAd_JJ2UqMJpYrkiUQDezanZQYoO23ajyzqR7EzYPRiMi5ik',
    id: 5944,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ucem.edu.mx',
    name: 'Universidad del Centro de México',
    web_page: 'http://www.ucem.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl3jg_dMskIHs4iBqiD28izyCgIYqFGDmQXMXyWhl5ciHp7BGbcuzS6w',
    id: 5945,
    acronym: 'UDCDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uclah.edu.mx',
    name: 'Universidad Cientifica Latinoamericana de Hidalgo',
    web_page: 'http://www.uclah.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxB60XHS33EcVScTX1J1gOJ47chAO7IHA_gWpqusXHc_c1i1oLi2YiMA',
    id: 5946,
    acronym: 'UCLDH',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ucol.mx',
    name: 'Universidad de Colima',
    web_page: 'http://www.ucol.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI5I0jDeWBStheZff5B0hgIREnl__UUdDa5Lp2yGlDiYAv7GZ7KQFXpw',
    id: 5947,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udec.edu.mx',
    name: 'Universidad de Celaya',
    web_page: 'http://www.udec.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnbmo1K0K4CxW98L78NhNc2l78Op5OsXuqrrZ5s3ANICelJxppjHjodQ',
    id: 5948,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udeci.edu.mx',
    name: 'Universidad de Cuautitlan Izcalli',
    web_page: 'http://www.udeci.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf1anuVKU1Ql-F3NrIgugNvWo5xtDJLlJHCB4hE8ECsByKnt4GZocmsJ8oIQ',
    id: 5949,
    acronym: 'UDCI',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udeh.edu.mx',
    name: 'Universidad de Hermosillo',
    web_page: 'http://www.udeh.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrTmmvMT4ZV7H6MA4g969lxMd3y8jrHwIfpdsZk8MZBgmQMEjN4E8ldRc',
    id: 5950,
    acronym: 'UDH',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udem.edu',
    name: 'Universidad de Monterrey',
    web_page: 'http://www.udem.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRD1AbelUo2n2vPZggxRlUXNQotiBS729jquFePqLgAoOMqMlffkxZbse8',
    id: 5951,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udesanmiguel.edu.mx',
    name: 'Universidad de San Miguel',
    web_page: 'http://www.udesanmiguel.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRqpCA1IMMCjnScZ5FRU_xs4uEJ1m1HfHtNl5neHiqeev1TUJqB4uLcRs',
    id: 5953,
    acronym: 'UDSM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udg.mx',
    name: 'Universidad de Guadalajara',
    web_page: 'http://www.udg.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTknKUPeiCXkj3owchKG74mgP8xjMzOImMmXR8VvfuzgaNQLbqNC9JRlg',
    id: 5954,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udla.mx',
    name: 'Universidad de las Américas',
    web_page: 'http://www.udla.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUmk-dlT-UcZW0NwfrVmXB8EEI9eKAPUoO8Ng-ER4ZIkaA9EWDXJyoVg',
    id: 5955,
    acronym: 'UDLA',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udlap.mx',
    name: 'Universidad de las Américas - Puebla',
    web_page: 'http://www.udlap.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPLR4XKkx3Snwv8-d8V5hZAv_N6hr9NDX-daZBdIvhcNZIkjcs2a2-bA',
    id: 5956,
    acronym: 'UDLA-P',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udo.mx',
    name: 'Universidad de Occidente',
    web_page: 'http://www.udo.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx9DW_TrVaHSTkzedcSt-uW6tRgYVeIRHejoATqnXjGsj-uQ6KBJjQ7CI',
    id: 5957,
    acronym: 'UDO',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'udv.edu.mx',
    name: 'Universidad Don Vasco',
    web_page: 'http://www.udv.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTkHOX9eE0-0pejclNMnPrqAGhqBvu0dc5FXegU431ps41NbTrGL8u1Ys',
    id: 5958,
    acronym: 'UDV',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ufm.edu.mx',
    name: 'Universidad Franciscana de México',
    web_page: 'http://www.ufm.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqMeL4Q-Z7uYDozTTZchL3Ippcin-uZaPaLhshb3cOajaAUBA58RrZmY0',
    id: 5959,
    acronym: 'UFDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ufram.edu.mx',
    name: 'Universidad Franco-Mexicana',
    web_page: 'http://www.ufram.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz46bo0jr0wKWtjUJq7y4sosTZ8yZa8X3mf7iZG1memzGdX10_Ii2lVzU',
    id: 5960,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ugto.mx',
    name: 'Universidad de Guanajuato',
    web_page: 'http://www.ugto.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuqo5KcjlNCtlR2fvtaAN5Z9C20VKHWpUm5q4ut0GbUxHQeZ1w-z-d5A',
    id: 5961,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uic.edu.mx',
    name: 'Universidad Intercontinental',
    web_page: 'http://www.uic.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYEuuIqkYh5RlaAH1e9JOOsOoOV4gwOoytHOdFPQytmIPXzTvGV5yyUA',
    id: 5963,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uileon.edu.mx',
    name: 'Universidad Insurgentes Plantel León',
    web_page: 'http://www.uileon.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbbSZDMZtd8KbE2pJRhQNvolMZ8U6BJWq3s6YKSi3a6R-vChl07d1JS7Y',
    id: 5964,
    acronym: 'UIPL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ujat.mx',
    name: 'Universidad Juárez Autónoma de Tabasco',
    web_page: 'http://www.ujat.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc8vmXYVRiLXPZWYvKt3CIZgJAaZ5910Up4Y_ax6HFPZlYed4jV7WUkZk',
    id: 5965,
    acronym: 'UJADT',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ujed.mx',
    name: 'Universidad Juárez del Estado de Durango',
    web_page: 'http://www.ujed.mx/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRzassdI2tIAJwaGHUO_3P7x9BjW_aodYi5xb3-9fzEZGB2CgXfW8CVgN8',
    id: 5966,
    acronym: 'UJDEDD',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ujsierra.mx',
    name: 'Universidad Justo Sierra',
    web_page: 'http://www.ujsierra.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS70Fb1xeURbXGhVz86kouE9HKm1vAK9R0I_8VQVWCYOhIsnTu2PbO62Eo',
    id: 5967,
    acronym: 'UJS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ula.edu.mx',
    name: 'Universidad Latinoamericana',
    web_page: 'http://www.ula.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwQ4fnIaOd2FFH_MkqRmjYm_aSYAr2cH3_HKYebSdtqfcF3pF4ZS65S_g',
    id: 5968,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ulsab.edu.mx',
    name: 'Universidad Lasallista Benavente',
    web_page: 'http://www.ulsab.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDNdtZv0Te0WQuN11XBmmCCcXbJx3fAgFTT90dUOvbTvUK42faZ_d_HQ',
    id: 5969,
    acronym: 'ULB',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ulsac.edu.mx',
    name: 'Universidad La Salle Cuernavaca',
    web_page: 'http://www.ulsac.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDhtcYIqkHuBCRr7Hpu4cFIlVkwHiAu1Xhh3q2Q1nk1Y3LOxE2Xna6YQ0',
    id: 5970,
    acronym: 'ULSC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ulsa.edu.mx',
    name: 'Universidad La Salle',
    web_page: 'http://www.ulsa.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStoY4oc44cT15Kegb2t1PoVTocofb0MMIICc08bMVeF7KvcVHE2z-ZDA',
    id: 5971,
    acronym: 'ULS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'umad.edu.mx',
    name: 'Universidad Madero',
    web_page: 'http://www.umad.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmKbuWEIt4MN9shOV3N_nWmHSu-YkNvCl5VIbRDNjXgtvpbBehNYYCiqSB',
    id: 5972,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uman.edu.mx',
    name: 'Universidad México Americana del Norte',
    web_page: 'http://www.uman.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlonMn4WWiKVOkAKXRP0btos89bJo-OWFcFzS50HLBqw84nTj28kLKF3bhjQ',
    id: 5973,
    acronym: 'UMADN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'umich.mx',
    name: 'Universidad Michoacana de San Nicolás de Hidalgo',
    web_page: 'http://www.umich.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0F8k6-tUTr-WMdonUzSAGZa7JnQTPF_6l4rQTPtkdAO_XcW5m4mmpGg',
    id: 5975,
    acronym: 'UMDSNDH',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'umi.edu.mx',
    name: 'Universidad México Internacional',
    web_page: 'http://www.umi.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaoJImJJuYSKEiWsdNWLl3teQH6UPcZXiFk_2GXlZB2unLN7jgulznPEE',
    id: 5976,
    acronym: 'UMI',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'umla.edu.mx',
    name: 'Universidad Metropolitana Latin Campus',
    web_page: 'http://www.umla.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMVOGZNI5y4GLYhE2zCSN1vL22r_nYv1o5UQeWH2fP4qxjASNHbH_8DA',
    id: 5977,
    acronym: 'UMLC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'umm.edu.mx',
    name: 'Universidad Metropolitana de Monterrey',
    web_page: 'http://www.umm.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3y--aYiPxzqQZNRtWCHxp4LYBe7JePPKIGfcN2NkBDi0tFdKUCTkew-8',
    id: 5978,
    acronym: 'UMDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'umne.edu.mx',
    name: 'Universidad Mexicana del Noreste',
    web_page: 'http://www.umne.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjTRLSZe9h7EaxKXk1A8Pwa7DZFndLua0rfOUKjdqFE9g4QnQadeXDng',
    id: 5979,
    acronym: 'UMDN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'umontemorelos.edu.mx',
    name: 'Universidad de Montemorelos',
    web_page: 'http://www.umontemorelos.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_enuakXxms7pgiG1WLPBCvX_tmHEkKAd1u503Eww-A_gvVNQ6iwl39pg',
    id: 5980,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'umsa.edu.mx',
    name: 'Universidad Mesoamericana de San Agustín',
    web_page: 'http://www.umsa.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR46vxndgd7z46nbecbCWtiYVfK3WPBUmF7jZ441rqpXszcXdi3S3chnend',
    id: 5981,
    acronym: 'UMDSA',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unacar.mx',
    name: 'Universidad Autónoma del Carmen',
    web_page: 'http://www.unacar.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi531Wl6Uv-s0dI1HnfvHZkHLKLmh0FMNLylXSZYa_h5qh_qWcacbX7c4',
    id: 5982,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unach.mx',
    name: 'Universidad Autónoma de Chiapas',
    web_page: 'http://www.unach.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTQXIdvvxOcG6avywEyo0MJHmwWVPfQ5pyVovOLR_wc1U_LZb3bNp-UH9WyA',
    id: 5983,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unam.mx',
    name: 'Universidad Nacional Autónoma de México',
    web_page: 'http://www.unam.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI333A0f-aHP5AUCKXiynnPg9LqH55E_4pTVKo7WJsfKFwE6eFrhjprD0V',
    id: 5984,
    acronym: 'UNADM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'une.edu.mx',
    name: 'Universidad del Noreste',
    web_page: 'http://www.une.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGtzxOXDjPnzTNxVmDuHH-LSWIC60Mvy7-MY38pi41i-dLKlpim8iDtj4',
    id: 5986,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unes.edu.mx',
    name: 'Universidad España de Durango',
    web_page: 'http://www.unes.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnuiqQxGbUV0e0Cak7vI01Q-H6OPpJO2US_TmxjEAsiKY-vE-nniI1JYE',
    id: 5987,
    acronym: 'UEDD',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unicach.edu.mx',
    name: 'Universidad de Ciencias y Artes de Chiapas',
    web_page: 'http://www.unicach.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNuDlkGIJgTbm1uOC7ZIbvDsWwDCiM0ztNXGEEc2wLGqUxMBPrJ7Wj8_0',
    id: 5988,
    acronym: 'UDCYADC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unica.edu.mx',
    name: 'Universidad de Comunicación Avanzada',
    web_page: 'http://www.unica.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy_b1u6pc0HnYO6n7RFLd8AJRs9d5gVBbrDn_3hcRirHSBNYuNAtttJw',
    id: 5989,
    acronym: 'UDCA',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unikino.mx',
    name: 'Universidad Kino',
    web_page: 'http://www.unikino.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3gKniY3fWr8c9Y3zpN2DtTU0X7wDwfyk-izE1f-thDTkFIhiaGrTOIQ',
    id: 5990,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unimayab.edu.mx',
    name: 'Universidad del Mayab',
    web_page: 'http://www.unimayab.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdczCvl0mr_6t9UisMqmZcj3PmhuwQFTx8HYGKpO6rdSazXyr5iGGe050',
    id: 5991,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unimex.edu.mx',
    name: 'Universidad Mexicana',
    web_page: 'http://www.unimex.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_WGzUkiWMfsp4zuuncl--EFghVpO1-qGUBFjhxaYUpDbdydSebOUKwA',
    id: 5992,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unitec.mx',
    name: 'Universidad Tecnológica de México',
    web_page: 'http://www.unitec.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJhQqBVunvfdaaPVNS76I6kkM2b_va3ax7NfYvO2vgY8jAy3ku75JMfEM',
    id: 5993,
    acronym: 'UTDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'univa.mx',
    name: 'Universidad del Valle de Atemajac',
    web_page: 'http://www.univa.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8yD3u-H_MbbjJA1BnyLgGkUUSK9v04rd2Tm4q-JskqLO6Lwm4Fod4rQ',
    id: 5994,
    acronym: 'UDVDA',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'univas.edu.mx',
    name: 'Universidad Jóse Vasconcelos',
    web_page: 'http://www.univas.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRV5lYWTe1dvm37fvfhR96UehTlAP3RdsNUIhXcGJld13oGPUGvoxvjOho',
    id: 5995,
    acronym: 'UJV',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'univchapultepec.edu.mx',
    name: 'Universidad Chapultepéc',
    web_page: 'http://www.univchapultepec.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIduH_DqO9PeEm9FtHsQ2xAIEh0ImNJHTaU9Ex1z8QCufQ7PaSKZqP3cA',
    id: 5996,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'universidaddeleon.edu.mx',
    name: 'Universidad de León',
    web_page: 'http://www.universidaddeleon.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgnUbJFXR3EdCjjF2xI52wKHxTHCs39QjuezL4LItWhSvFZZEi-S2YZCw',
    id: 5997,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'universidadlaconcordia.edu.mx',
    name: 'Universidad la Concordia',
    web_page: 'http://www.universidadlaconcordia.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRECJjVS0nO0uod_7vjH9Bm4GuAMJCLnRD__B_fKFol62XtFZRWrgMKjeZQ',
    id: 5998,
    acronym: 'ULC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'universidadmotolinia.edu.mx',
    name: 'Universidad Motolinía del Pedegral',
    web_page: 'http://www.universidadmotolinia.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRILrsLkDBiB0qHMjp39TEN4sFUdTvwzyvCPOxbtPR5lMky0T3FEzKa4g',
    id: 5999,
    acronym: 'UMDP',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'universidadsalesiana.edu.mx',
    name: 'Universidad Salesiana',
    web_page: 'http://www.universidadsalesiana.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFk582DK9nPQWyfP6JJWucmLDtwgCnvusrlABNkrgoR-l68DFUYyyVcg',
    id: 6000,
    acronym: 'US',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'univillarica.mx',
    name: 'Universidad Autónoma de Veracruz Villa Rica',
    web_page: 'http://www.univillarica.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbkm4nkQY62q4yeZiQQoBYJ7IfvMZ_FzD8TayHGb9Dia43DOUYmGWjwxg',
    id: 6001,
    acronym: 'UADVVR',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unla.edu.mx',
    name: 'Universidad Latina',
    web_page: 'http://www.unla.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGriHW4-CJkO4fAxq-p9QvBAImfVEwOrOUZDE2MNInhcNqk3ZJucPiCw',
    id: 6002,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'un.mx',
    name: 'Universidad de Norteamerica',
    web_page: 'http://www.un.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTaxuqlSg8BZyRYIRwc20oiVqhj9oVMLm6jipMT5eYokbH1PiGBo3qm3k',
    id: 6003,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uno.mx',
    name: 'Universidad del Noroeste',
    web_page: 'http://www.uno.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGtzxOXDjPnzTNxVmDuHH-LSWIC60Mvy7-MY38pi41i-dLKlpim8iDtj4',
    id: 6004,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'unum.edu.mx',
    name: 'Universidad Nuevo Mundo',
    web_page: 'http://www.unum.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMeYAq99tYupWfuSL8lhAS2B7Z5sgcLQcWkG_CrTeDVd_l2ZQN_tYP-OA',
    id: 6005,
    acronym: 'UNM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uom.edu.mx',
    name: 'Universidad Obrera de Mexico',
    web_page: 'http://www.uom.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA2VmOviQPIMT1-r4z2TJhahGAYb9VGqGijcvJcQeV5VbIsSdoyVy3Ydsr',
    id: 6006,
    acronym: 'UODM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'upaep.mx',
    name: 'Universidad Popular Autonóma del Estado de Puebla',
    web_page: 'http://www.upaep.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGQhXGVtX8aYLlAvuKo0GpA2i5aKlVfPGt7uYQVAazPxZeDvHQQwfCSLY',
    id: 6007,
    acronym: 'UPADEDP',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'upiig.ipn.mx',
    name: 'Instituto Politécnico Nacional Campus Guanajuato',
    web_page: 'http://www.upiig.ipn.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPoojZwkd1BhwNpNQDM49UNUIYGSp1o06JN7X18EzcYAaxzhwIaIY4mLVa',
    id: 6008,
    acronym: 'IPNCG',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'upn.mx',
    name: 'Universidad Pedagógica Nacional',
    web_page: 'http://www.upn.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQthqVyuPW3qtJIyMmV7g1z594qwoq1zJQwkz974OZguLjmrUhejCaBkw',
    id: 6010,
    acronym: 'UPN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uqroo.mx',
    name: 'Universidad de Quintana Roo',
    web_page: 'http://www.uqroo.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX6Zo18H3WWutTN_KeiTKmw1OBnm_VvDOoXzpBi0VZ3xUlIa8M9016Efo',
    id: 6011,
    acronym: 'UDQR',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'urmh.edu.mx',
    name: 'Universidad Regional Miguel Hidalgo',
    web_page: 'http://www.urmh.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6d-qE5erMaeHuOuHlXg1f-y-fT-vPEVb5HJ9hy5_7r64gf6BLh2YEqJ0',
    id: 6012,
    acronym: 'URMH',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ur.mx',
    name: 'Universidad Regiomontana',
    web_page: 'http://www.ur.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiVa8wehTfCXBgwfATtI3-3Gakalb1Ltg4LdexSATjNjMas4s9rFqLZfI',
    id: 6013,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'urse.edu.mx',
    name: 'Universidad Regional del Sureste',
    web_page: 'http://www.urse.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-ZOsNM2VcreXdkEEHeavWQ_fj_bHkbCbFRrjos3CUfzafqNPjDpabB4g',
    id: 6014,
    acronym: 'URDS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'usac.edu.mx',
    name: 'Universidad de la Sierra A.C.',
    web_page: 'http://www.usac.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXJXXGapmDnPU260ryrwBSGyiVLcUUT2eSaQU71bRje02fjgx4GUHOHDU',
    id: 6015,
    acronym: 'UDLSA',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'usb.edu.mx',
    name: 'Universidad Simón Bolivar',
    web_page: 'http://www.usb.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyHfigZjrbmiY_42Xg3BWl2O13AatjR6nnr23L6ofSpk2C6byh-TpGxFg',
    id: 6016,
    acronym: 'USB',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'usf.com.mx',
    name: 'Universidad Santa Fe',
    web_page: 'http://www.usf.com.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKPP4e8qwrVCSiEVD1DbGrTky_Cn6kPOFpQ5I8zl2vAEyeg1nXPpJrVlg',
    id: 6017,
    acronym: 'USF',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uson.mx',
    name: 'Universidad de Sonora',
    web_page: 'http://www.uson.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmO_DaKpz3DROAkfXK_HJ5HONu2uFI17atbDMDzohIjBDH2hvxzveP4Q',
    id: 6018,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'utm.mx',
    name: 'Universidad Tecnologica de la Mixteca',
    web_page: 'http://www.utm.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSIpq6d8vehMMfCSgGjKesO8DxB4CdPu6T7lR2HltzQWUAy6gomXRiOA',
    id: 6019,
    acronym: 'UTDLM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'utneza.edu.mx',
    name: 'Universidad Tecnologica de Nezahualcoyotl',
    web_page: 'http://www.utneza.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTic4Y3ijQ4OGB25Di4aeaOJteS6MTtJ0sODs6jCmX2KnCWVV2o6dG8exlCDw',
    id: 6020,
    acronym: 'UTDN',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uvb.edu.mx',
    name: 'Universidad del Valle del Bravo',
    web_page: 'http://www.uvb.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYEIFnS7B2ovJGFusH19WNHss3Ysc9Iux5Xy76nV-DD8GuuEDE8I0plw',
    id: 6021,
    acronym: 'UDVDB',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uvg1.net',
    name: 'Universidad Valle del Grijalva',
    web_page: 'http://www.uvg1.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9IjxvuqXggl9txLLg6_pnWRBoEoMYVUKLeeSZp-yUQfRxgwy411nNoQ',
    id: 6022,
    acronym: 'UVDG',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uvmnet.edu',
    name: 'Universidad del Valle de México',
    web_page: 'http://www.uvmnet.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFPlpXtboh7bB2VV3dnMHozAQcDrFL5GFmx3Yz5S0UVJc2hqfTeXkKC_U',
    id: 6023,
    acronym: 'UDVDM',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uv.mx',
    name: 'Universidad Veracruzana',
    web_page: 'http://www.uv.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2GXq-MWlCZQYeAbvEF-sVbJ5gmJTz8ZvcOTpmYkFRvnSsSWC3z-MslEw',
    id: 6024,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uvt.edu.mx',
    name: 'Universidad del Valle de Toluca',
    web_page: 'http://www.uvt.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWd9NB17MlBEmGNZ_NDJD5xcx-Muq0KTysyKUWGCOabzTDpUOiTIBw_bk',
    id: 6025,
    acronym: 'UDVDT',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'uxac.edu.mx',
    name: 'Universidad Xicotepetl',
    web_page: 'http://www.uxac.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwQCInvxmlyKl_JNH1qplXbDnUinM94_ui3pFforylVITl5SNkmBcpww',
    id: 6026,
    acronym: 'UX',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'ver.ucc.mx',
    name: 'Universidad Cristóbal Colón',
    web_page: 'http://www.ver.ucc.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyDHB-26MV27FTU5uykrFLRxaFtv98-4o-QtHNSn1piSYr30bajU_3s34',
    id: 6027,
    acronym: 'UCC',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'westhill.edu.mx',
    name: 'Westhill University',
    web_page: 'http://www.westhill.edu.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeOngMUAyUtVZwzTlBKC6tBGSkG_rnBoZaFt2eV0JBjbj4mRuJP5im3Os',
    id: 6028,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'MX',
    country: 'Mexico',
    domain: 'www-azc.uam.mx',
    name: 'Universidad Autónoma Metropolitana - Azcapotzalco',
    web_page: 'http://www-azc.uam.mx/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-2IZuPhdrtikUdjAYoGwFZORjZg4owoCI8bhqW_EnO3yJkMKcPPQ0fPU',
    id: 6029,
    acronym: 'UAM-A',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'ase.md',
    name: 'Academy of Economic Studies of Moldova',
    web_page: 'http://www.ase.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsl3j1zwS8vvF4YBWfysmbuT480BcwXFtKlgpkJVjCYIpLUerYsCD6SUU',
    id: 6030,
    acronym: 'AESM',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'kdu.md',
    name: 'Comrat State University',
    web_page: 'http://www.kdu.md/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTrdW6ZypH0ELOORQ9ta-JXXDlgOUHLC11oCL-vkMLN7jagBExMy7hMsIQ',
    id: 6031,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'uasm.md',
    name: 'Moldova State Agricultural University',
    web_page: 'http://www.uasm.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6diST5yNnl09tqOWJhkO6pgt4J0gT1gCYLZqVB-Gss7u4OCTtgFqW-EyC',
    id: 6032,
    acronym: 'MSAU',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'uccm.md',
    name: 'Moldova Cooperative Trade University',
    web_page: 'http://www.uccm.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmYjqMHRNuxBbl4Kl-179hiW3KBd49aKtOPcM1WvGp0jLaWQyau0_TJ10',
    id: 6033,
    acronym: 'MCTU',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'ulim.md',
    name: 'Free International University of Moldova',
    web_page: 'http://www.ulim.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6_M3P8WsT-hgdH8LUDkzwqZPHyIfXZKgOm-_Btngfr88xbc6cL9wg-Q',
    id: 6034,
    acronym: 'FIUM',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'upm.moldnet.md',
    name: 'Pedagogical State University "Ion Creanga"',
    web_page: 'http://upm.moldnet.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwgVWNezFmDkXxBoUO79pfM9O3EOtiVUCFiN2NNtbksQaEj6essC0JYQE',
    id: 6035,
    acronym: 'PSU"C',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'usam.md',
    name: 'University of Applied Sciences of Moldova',
    web_page: 'http://www.usam.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVczIeO2m1LvPC59QKHXChj5fRced6WTdFwjkeuaQfLBn_PrCp8fEg6Q',
    id: 6036,
    acronym: 'UASM',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'usb.md',
    name: 'Balti State University "Alecu Russo"',
    web_page: 'http://www.usb.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbGl9h-sYWmYIZQIc4_lIc574JeaeEJ8VPnkhVDYJ_oTaD6BzkRhlenFc',
    id: 6037,
    acronym: 'BSU"R',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'usmf.md',
    name: 'Moldova State University of Medicine and Pharmacy "N. Testemitsanu"',
    web_page: 'http://www.usmf.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThZ1wN57ThEKODv0DgNUkMUr8vz9vCLJkbQ7pwHMmS0n5O6YAcAoJhElLA',
    id: 6038,
    acronym: 'MSUMP"T',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'usm.md',
    name: 'Moldova State University',
    web_page: 'http://www.usm.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBXO7vcqlMCmKLtsmekKkuXQ6L_hQw7C1ubGgnALv7pbr5OuAQ2p-stPYE',
    id: 6039,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'ust.md',
    name: 'Tiraspol State University',
    web_page: 'http://www.ust.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIo-M3J47iqdoYiPHNrokrjLVENK4Hk4XDYtDtnx8r_ODZ4rjvrcvicA',
    id: 6040,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'MD',
    country: 'Moldova, Republic of',
    domain: 'utm.md',
    name: 'Moldova Technical University',
    web_page: 'http://www.utm.md/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyGyLyOvE0XygVs40urioIJVoSaMEL9VLCV_6dto3Jk41sWA1FhEJR_g',
    id: 6041,
    acronym: 'MTU',
  },
  {
    alpha_two_code: 'MC',
    country: 'Monaco',
    domain: 'riviera.fr',
    name: 'University of Southern Europe (Monaco Business School)',
    web_page: 'http://www.riviera.fr/usehome.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKnKTS5P0cd7FieJpmw_PdqCnMft9IiaHTQXZNUUB8cylaiBsb7EAzMPg',
    id: 6042,
    acronym: 'USE(BS',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'hsum-ac.mn',
    name: 'Health sciences University of Mongolia',
    web_page: 'http://www.hsum-ac.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWeQAkVT3yJjZRCOMoVbdNhrkmAZ5QW9uvF-aUb2SZBGOu3d22pdl_SA',
    id: 6043,
    acronym: 'HSUM',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'humanities.mn',
    name: 'University of the Humanities',
    web_page: 'http://www.humanities.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxctoEbt5UOP088eKWDEatbd2KGS-2vvMMLa6TbLsCL9BVQ07K9R1D1hM',
    id: 6044,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'icb.edu.mn',
    name: 'Institute of Commerce and Business',
    web_page: 'http://www.icb.edu.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlQRQSX3o6hzAJSDB00xW5J7WO3MIUFVBnJGlwWLVd3X1cGrmTW0yThg',
    id: 6045,
    acronym: 'ICB',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'msuac.edu.mn',
    name: 'Mongolian State University of Arts and Culture',
    web_page: 'http://www.msuac.edu.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWMToc0FOqYkQSyqAnY77hujM3SqOAo6UmToqi7how437eCbcWMIKuHpX4HQ',
    id: 6046,
    acronym: 'MSUAC',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'msua.edu.mn',
    name: 'Mongolian State University of Agriculture',
    web_page: 'http://www.msua.edu.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRm3uxDq8tBxPnH6-R7KkyaAxUFY8KA3pXdUVebUMdtC_mF7Y0Sr7CzGu4',
    id: 6047,
    acronym: 'MSUA',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'msue.edu.mn',
    name: 'Mongolian State University of Education',
    web_page: 'http://www.msue.edu.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDfC1Ptpqyk_NGUn4_bdEcS2SVRsT6whW3GHS7JRNeF_T4vXSPA7mj7Rw',
    id: 6048,
    acronym: 'MSUE',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'must.edu.mn',
    name: 'Mongolian University of Science and Technology',
    web_page: 'http://www.must.edu.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeFChKsfm01TTveXA3U1bYUmSX77OOgMrRmCyhXAIHd0T6_z9t5B_xijo',
    id: 6049,
    acronym: 'MUST',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'num.edu.mn',
    name: 'Mongolian National University',
    web_page: 'http://www.num.edu.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsUykA2Sg9pRyJi6-Pt8a-k2XWz1HVo-uKD79iV6TQC5h_SXkYcdLvww',
    id: 6050,
    acronym: 'MNU',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'orkhon.edu.mn',
    name: 'Orkhon University',
    web_page: 'http://www.orkhon.edu.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc4pgWB6L3pYzUFOMdXvmcux-9nLD0YuafX2WasLSOxH0XjD50cDmITos',
    id: 6051,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'MN',
    country: 'Mongolia',
    domain: 'ulaanbaatar.edu.mn',
    name: 'Ulaanbaatar University',
    web_page: 'http://www.ulaanbaatar.edu.mn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9-Ts7KIm0dpUvzXKv1bwWHBkhTEYInYh0zALYjVfSbH4EyVjwraUBbA',
    id: 6052,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'ME',
    country: 'Montenegro',
    domain: 'ucg.cg.ac.yu',
    name: 'University of Montenegro',
    web_page: 'http://www.ucg.cg.ac.yu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLGEOIxY_JUV-b2btNjUCkISy8Z5t7X3wBofsbVnCs25sWQ7qv_ruxXESa',
    id: 6053,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'MS',
    country: 'Montserrat',
    domain: 'usat.ms',
    name: 'University of Science Arts and Technology',
    web_page: 'http://www.usat.ms/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU9aJ8mhzo7Eo6rwlAn4XlEPtksruTU6QakpUFy518Fcm5iz7MpWf95w',
    id: 6054,
    acronym: 'USAT',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'archi.ac.ma',
    name: "Ecole Nationale d'Architecture",
    web_page: 'http://www.archi.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPTs220jmlVM3bWiQZN1QqFTGGzA0gPTGPha5RXTIOOFf24x57Gbq0uNY',
    id: 6055,
    acronym: 'END',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'aui.ma',
    name: 'Al Akhawayn University',
    web_page: 'http://www.aui.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_k9ZOBgIQyjND3J4G0XJgS-PkjDq-1l85DcnPB0OXmF3EkXQSnapimg',
    id: 6056,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'edhec.ac.ma',
    name: 'Ecole des Hautes Etudes en Gestion Informatique et Communication',
    web_page: 'http://www.edhec.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVQ4Lm-uV1si9B9yemtWOZdJ3iPZSpyAkP9A2iUlM80ci1xU9TTCMOKQ',
    id: 6057,
    acronym: 'EDHEEGIEC',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'emi.ac.ma',
    name: "Ecole Mohammadia d'Ingénieurs",
    web_page: 'http://www.emi.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa-rTXmWPh8Y_pPJlT1DTNURMoqYcHBlmocJ4rS9CAJeCs9abrEfV4UAU',
    id: 6058,
    acronym: 'EMD',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'enameknes.ac.ma',
    name: "Ecole National d'Agriculture de Meknes",
    web_page: 'http://www.enameknes.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQmXqKhHAaDvOluDuEAuEii95hW_P_R797z9QflQQf_KsxJbuMWUK6Ud0',
    id: 6059,
    acronym: 'ENDDM',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'esca.ac.ma',
    name: 'Ecole Supérieure de Commerce et des Affaires',
    web_page: 'http://www.esca.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRipXcxqemx-XAXyfG96BMD0Q8WZftOpjEiJZXg8_zuhloWh84u4dzL9Iw',
    id: 6060,
    acronym: 'ESDCEDA',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'esta.ac.ma',
    name: 'Université Ibn Zohr Agadir',
    web_page: 'http://www.esta.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjUAHQ0gVQG1qAm2njEcS-W9menm9fF_XjtTt-zVlbFeNUvuxOjsgTT30',
    id: 6061,
    acronym: 'UIZA',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'groupeiscae.ma',
    name: "Institut Supérieur de Commerce et d'Administration des Entreprises",
    web_page: 'http://www.groupeiscae.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDS6Z9P_xbK-rOtuPJTbFYdsCwjAl4vnxTsshMMfXVuR5N0JFjyVjVeQ',
    id: 6062,
    acronym: 'ISDCEDDE',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'hec.ac.ma',
    name: 'Ecole des Hautes Etudes Commerciales MAROC',
    web_page: 'http://www.hec.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq_5WnR9zhBzQcsw4mYZMp2drOpB9lbkp-k2incRYwj_Ci9BncnZ-6PCtOgg',
    id: 6063,
    acronym: 'EDHECM',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'hem.ac.ma',
    name: 'Institut des Hautes Etudes de Management',
    web_page: 'http://www.hem.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIT6nLU13AuR0fxULpsiBNTl9BE44GisxUF-bof4I95cOdVHh1aCcy0Q',
    id: 6064,
    acronym: 'IDHEDM',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'hightech.edu',
    name: "Ecole Supérieure d'Informatique et de Management",
    web_page: 'http://www.hightech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ7QYz4bqBWqudSKKKSttf-6nFKuC2zIL1Tns6TbFTe6xxvrvKqnqWoy4',
    id: 6065,
    acronym: 'ESDEDM',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'iihem.ac.ma',
    name: 'International Institute for Higher Education',
    web_page: 'http://www.iihem.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDf8uM-E_M4h43afcYG5ZKIwGuW1fzibc7_81ieOVz4OpB7bC4gYJEXA',
    id: 6066,
    acronym: 'IIFHE',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'imbt.ma',
    name: 'Institute of Management and Business Technology',
    web_page: 'http://www.imbt.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6Dpey20eUm2aVP1ffRGDJOeyazyRgdP5bfw56rkVoPTbRnPbd_xvrVn8',
    id: 6067,
    acronym: 'IMBT',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'inpt.ac.ma',
    name: 'Institut National des Postes et Télécommunications',
    web_page: 'http://www.inpt.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7PzBdpKc-eUr25AE2UlRqumcUWYg1B2Ri_iqOGkk10P3AIQLSMxdkC3Q',
    id: 6068,
    acronym: 'INDPET',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'isfort.ac.ma',
    name: 'ISFORT - Institut de Formation en Technologie Alimentaire',
    web_page: 'http://www.isfort.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvGW5v__kd5fV3cxx3n7BLYDaSSz0OsmIofXToAJesyvnJt0xMhAVDNHo',
    id: 6069,
    acronym: 'I-IDFETA',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'matci.ac.ma',
    name: 'Institut Supérieur de Management et de Technologie (MATCI)',
    web_page: 'http://www.matci.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeT5YQTyj7rklKAXUzURu5US9sQOLkhd2C6nLFWUom2sZLqRyCHusr-A',
    id: 6070,
    acronym: 'ISDMEDT(',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'rectorat-uh2c.ac.ma',
    name: 'Université Hassan II - Aïn Chock',
    web_page: 'http://www.rectorat-uh2c.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg5EFIDQx4tQ-YWDLuGSg5VSbvYw3fiKajCqqw27QJ3eQcAGHcGABE9zwB',
    id: 6071,
    acronym: 'UHI-AC',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'rumi.ac.ma',
    name: 'Université Moulay Ismail Meknès',
    web_page: 'http://www.rumi.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoRUhjeHWzCqxxp8kQX2n9-8YtC1BYQy2nR9SjEa7JkMHPiZ2b7VILv18jHQ',
    id: 6072,
    acronym: 'UMIM',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'suptelecom.net.ma',
    name: 'Ecole Superieure des Télécommunications',
    web_page: 'http://www.suptelecom.net.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-dOXOpxtrSe1mZZ3VhgMdFDnBcfd_P765nFY466Dptnd6sg3YfzrvwJVw',
    id: 6073,
    acronym: 'ESDT',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'uae.ac.ma',
    name: 'Université Abdelmalek Essadi',
    web_page: 'http://www.uae.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzKcYjgO68vPKTY-NO9nRjiLsYu7HTjYh855ul_oriaq3cQtCDRDGOTw',
    id: 6074,
    acronym: 'UAE',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'ucam.ac.ma',
    name: 'Université Cadi Ayyad',
    web_page: 'http://www.ucam.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOX8EK1fqtTycvPPl_5eC83I1AcHEey_iend_DGGJAJefB2o8_H78kpLA',
    id: 6075,
    acronym: 'UCA',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'ucd.ac.ma',
    name: 'Université Chouaib Doukkali',
    web_page: 'http://www.ucd.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5Gopj0PCNwB1bycloQ3z33EPm1eyidqPN-rH3VWHDM_Yn9NCc3W9XMI4',
    id: 6076,
    acronym: 'UCD',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'uh1.ac.ma',
    name: 'Université Hassan Ier - Settat',
    web_page: 'http://www.uh1.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR_vcSEkzwrtHYDSpJdAwSCzxKuQa4z2_vNF6KNLph45o7QZ6Zb_G7Aw',
    id: 6077,
    acronym: 'UHI-S',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'uh2m.ac.ma',
    name: 'Université Hassan II - Mohammadia',
    web_page: 'http://www.uh2m.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR97b7fb1gVcSIVEq9HS0j05_9IqKSwhx8Qz8XspcEHhmrLRPRrnzi9jw',
    id: 6078,
    acronym: 'UHI-M',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'um5a.ac.ma',
    name: 'Université Mohammed V - Agdal',
    web_page: 'http://www.um5a.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZIhKgI6ZKldTYBO4VVRI2kTOL4QpdZoYVu0W9IzSSujnN4RMwtCnXog',
    id: 6079,
    acronym: 'UMV-A',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'um5s.ac.ma',
    name: 'Université Mohammed V - Souissi',
    web_page: 'http://www.um5s.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTApfIQ6la1bWFuONga89XZ8UnG7qKc-Eo4Y6_dz9uteKOvdBeVxb148T4',
    id: 6080,
    acronym: 'UMV-S',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'univ-ibntofail.ac.ma',
    name: 'Université Ibn Toufail',
    web_page: 'http://www.univ-ibntofail.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXep9QoKsD3CyILBbPHIEWRhXY064xlBlxUCSau1yhAyM6t79oH3ryrB8',
    id: 6081,
    acronym: 'UIT',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'univ-oujda.ac.ma',
    name: 'Université Mohammed Ier',
    web_page: 'http://www.univ-oujda.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ5PYjpLGuiy5XxdGN69c4FzwMVQyMoUN3XHmTiiBJcMVc58bh68qRaw',
    id: 6082,
    acronym: 'UMI',
  },
  {
    alpha_two_code: 'MA',
    country: 'Morocco',
    domain: 'usmba.ac.ma',
    name: 'Université Sidi Mohammed Ben Abdellah',
    web_page: 'http://www.usmba.ac.ma/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROS-0DQzLx-3C35r2l0TZpl5XOJ5iTSKk2m0jQfEMoVFNiDJ3vntuFbM4',
    id: 6083,
    acronym: 'USMBA',
  },
  {
    alpha_two_code: 'MZ',
    country: 'Mozambique',
    domain: 'isctem.ac.mz',
    name: 'Instituto Superior de Ciênicas e Tecnologia de Moçambique',
    web_page: 'http://www.isctem.ac.mz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXgu1YoL08brPF8Gatkf-FD0Si07jCCFNCtoYyNMn-ohCMKGaLMGzAypA',
    id: 6084,
    acronym: 'ISDCETDM',
  },
  {
    alpha_two_code: 'MZ',
    country: 'Mozambique',
    domain: 'ispu.ac.mz',
    name: 'Instituto Superior Politécnico e Universitário',
    web_page: 'http://www.ispu.ac.mz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXmVW-CvtE-ToAuGsbzJuWeMVX8GfBfh9NiutqxlKRMcSaWk-4gAEg1-k',
    id: 6085,
    acronym: 'ISPEU',
  },
  {
    alpha_two_code: 'MZ',
    country: 'Mozambique',
    domain: 'isri.ac.mz',
    name: 'Instituto Superior de Relações Internacionais (ISRI)',
    web_page: 'http://www.isri.ac.mz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5KjxshAtjzhNaCgw6eX1jmo1Vyq7Lvigc9MlHnlvzgjV0Hi5gq58BPkk',
    id: 6086,
    acronym: 'ISDRI(',
  },
  {
    alpha_two_code: 'MZ',
    country: 'Mozambique',
    domain: 'ucm.ac.mz',
    name: 'Universidade Católica de Moçambique',
    web_page: 'http://www.ucm.ac.mz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp00E_otrxqV7XTVoED8EjM53Thr69E_ekiC889kk8syC3JZB4ih43QbRt',
    id: 6087,
    acronym: 'UCDM',
  },
  {
    alpha_two_code: 'MZ',
    country: 'Mozambique',
    domain: 'uem.mz',
    name: 'Universidade Eduardo Mondlane',
    web_page: 'http://www.uem.mz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS16kr4fXxtXJC1Fbt12PIRLZK8WlIif_wnSDlELAJh8rgCrXUUFTozmbIT',
    id: 6088,
    acronym: 'UEM',
  },
  {
    alpha_two_code: 'NA',
    country: 'Namibia',
    domain: 'iit.com.na',
    name: 'Institute of Information Technology',
    web_page: 'http://www.iit.com.na/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 6089,
    acronym: 'IIT',
  },
  {
    alpha_two_code: 'NA',
    country: 'Namibia',
    domain: 'ilsacollege.edu.na',
    name: 'Ilsa Independent College',
    web_page: 'http://www.ilsacollege.edu.na/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS74uehWpqR9UtH5Os-JTyAu4YMPZVCL6-ZsXDxRaVgJBvCWVHtRgtCw_OV',
    id: 6090,
    acronym: 'IIC',
  },
  {
    alpha_two_code: 'NA',
    country: 'Namibia',
    domain: 'ium.edu.na',
    name: 'International University of Management',
    web_page: 'http://www.ium.edu.na/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSu2iWZ-h7kerxE0u5ceL_TCJoQ7rFfVoi_TKeSnFG-06QVhtoqSCpbzEM',
    id: 6091,
    acronym: 'IUM',
  },
  {
    alpha_two_code: 'NA',
    country: 'Namibia',
    domain: 'polytechnic.edu.na',
    name: 'Polytechnic of Namibia',
    web_page: 'http://www.polytechnic.edu.na/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUcGeHYMF27bbAMhj4NbxI7vQG3pvKdvsjYnde4X0sJRQnbBBEXvpVmg',
    id: 6092,
    acronym: 'PN',
  },
  {
    alpha_two_code: 'NA',
    country: 'Namibia',
    domain: 'unam.na',
    name: 'University of Namibia',
    web_page: 'http://www.unam.na/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7ZNdmzJY2XcyUyyX0MkoLOOXWDWnkExavhrQYnteoDeOmvGoJ9SwRP0Ov',
    id: 6093,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'NP',
    country: 'Nepal',
    domain: 'afu.edu.np',
    name: 'Agriculture and Forestry University',
    web_page: 'http://www.afu.edu.np/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSY4eSzM6KXnxwkG-EF75n9cWsmNZwm88znbiQ9zOmu2haK0eZqSA5whXJo0w',
    id: 6094,
    acronym: 'AFU',
  },
  {
    alpha_two_code: 'NP',
    country: 'Nepal',
    domain: 'bpkihs.edu',
    name: 'B.P.Koirala Institute of Health Sciences',
    web_page: 'http://www.bpkihs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTKXrqQsYymmZnqrR8tfpniSmOESQd8hBSyF9pGUt9QyPHVsrMvvEWEkI',
    id: 6095,
    acronym: 'BIHS',
  },
  {
    alpha_two_code: 'NP',
    country: 'Nepal',
    domain: 'ku.edu.np',
    name: 'Kathmandu University',
    web_page: 'http://www.ku.edu.np/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPjTtqjDajQdsemHpzWzkH8X-YOuhLX_kzgiAJj1LJNrG8tjPnVUeL7uhuLw',
    id: 6096,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'NP',
    country: 'Nepal',
    domain: 'lbu.edu.np',
    name: 'Lumbini Buddhist University',
    web_page: 'http://www.lbu.edu.np/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmRNwupiCHlVnG0PU3J4J0BjVbzRdzcn7WCpBG8ntpJI8YqLmZ5KsjRHmQ',
    id: 6097,
    acronym: 'LBU',
  },
  {
    alpha_two_code: 'NP',
    country: 'Nepal',
    domain: 'nsu.edu.np',
    name: 'Nepal Sanskrit University',
    web_page: 'http://www.nsu.edu.np/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ2-fRUe6emO_j37OA2epRTtya4o0XCxCW5NsOGg0G51dW6QL9cAbwTg',
    id: 6098,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'NP',
    country: 'Nepal',
    domain: 'pu.edu.np',
    name: 'Pokhara University',
    web_page: 'http://www.pu.edu.np/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi8S7lUal3YacjHq_fPKaHaQ83Lioc-VK_fulFPB1GpHwo4xew8_NkKg',
    id: 6099,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'NP',
    country: 'Nepal',
    domain: 'purbuniv.edu.np',
    name: 'Purbanchal University',
    web_page: 'http://www.purbuniv.edu.np/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfZlrDixHiaPtNYFRDl4Jx_gL-SXWimn82_AAWDeXdnTFbcTMbQjAkARI',
    id: 6100,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'NP',
    country: 'Nepal',
    domain: 'tribhuvan-university.edu.np',
    name: 'Tribhuvan University Kathmandu',
    web_page: 'http://www.tribhuvan-university.edu.np/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvMV75whw2SOG8BMncN2EvXx6EsYa_7CqelR_29NXrxAthgxgclt463A',
    id: 6101,
    acronym: 'TUK',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'aucmed.edu',
    name: 'American University of the Caribbean Sint Maarten',
    web_page: 'http://www.aucmed.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnfzBm2Qja7EV0AHv-gIw77Gsl1cI-xXl5imQKKWtTX4w3cZGu0ZQgFgz3',
    id: 6102,
    acronym: 'AUCSM',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'eustatiusmed.edu',
    name: 'University of Sint Eustatius School of Medicine',
    web_page: 'http://www.eustatiusmed.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5kKP4TC7Ae2a5Iqdk27u65JQtpvWWDcFprLo58HRTLHBJUyig_UjZY6k',
    id: 6103,
    acronym: 'USESM',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'internationaluniversity-schoolofmedicine.org',
    name: 'International University School of Medicine (IUSOM)',
    web_page: 'http://www.internationaluniversity-schoolofmedicine.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLtBhIuzLp2uzZ45RLNK8GFwpsuC8EBUaSnA11iVOwU8uZ52XchpKp2Us',
    id: 6104,
    acronym: 'IUSM(',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'sjsm.org',
    name: "St.James's School of Medicine Bonaire",
    web_page: 'http://www.sjsm.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT06TmDlvL1wb1bVh4VnIRWUuARS8D_qbo2V5LcHKsLTbq8eWkPfrr3iQA',
    id: 6105,
    acronym: 'SSMB',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'una.an',
    name: 'University of the Netherlands Antilles Curacao',
    web_page: 'http://www.una.an/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuV-iSdg2LwmLzIqToOTtSYujIWdjbMcUdmwNSpJ0i9VOkr2Cvqt7RMZ3o',
    id: 6106,
    acronym: 'UNAC',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'ddu.nl',
    name: 'Dutch Delta University',
    web_page: 'http://www.ddu.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzrBKYzjVPZONK8y0aHscXINZtIODNM7YEX5SWHfhUyM2Fsl-I8dYRHX8',
    id: 6107,
    acronym: 'DDU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'dehaagsehogeschool.nl',
    name: 'De Haagse Hogeschool',
    web_page: 'http://www.dehaagsehogeschool.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOq1Sce0XNwUUnqSGARU58eZE-vGBUAOCnwxca7DTEZkkP-3VP8ynYOHk',
    id: 6108,
    acronym: 'DHH',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'eur.nl',
    name: 'Erasmus University Rotterdam',
    web_page: 'http://www.eur.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFnkvY8xykvbFAGmAkWd5rtYLeQjMiZafIonWBaXSi9WdbdyC3tRSNXA',
    id: 6109,
    acronym: 'EUR',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'europeanopenuniversity.com',
    name: 'European Open University',
    web_page: 'http://www.europeanopenuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT38ItIQp6gWUwTfjohwVfAmQydZXxV1ExzoYIoPg4kIcncuxMVuUAAag',
    id: 6110,
    acronym: 'EOU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'fontys.edu',
    name: 'Fontys University of Applied Sciences',
    web_page: 'http://www.fontys.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5b-IasMz3cEpB05pkBAxpuYp24hgXffhcMKN_QQxzTzBRc0FxDvxVzg',
    id: 6111,
    acronym: 'FUAS',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'foundationuniversity.com',
    name: 'Foundation University',
    web_page: 'http://www.foundationuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKM5IwTqTcXvLd7P_8dBRHQHjaiYW9iZa09saP36EaLVFiQdIO7PR4lQ',
    id: 6112,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'hanze.nl',
    name: 'Hanze University Groningen',
    web_page: 'http://www.hanze.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqwkxJ8339skd9JcmW7fuuPIVnImOpudrjS73fHstE8BnvSTZfNw2XqA',
    id: 6113,
    acronym: 'HUG',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'hku.nl',
    name: 'Hogeschool voor de Kunsten Utrecht (HKU)',
    web_page: 'http://www.hku.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHQYml-JutMnrHDo6TClQJK-id0QBlil145otRORfc3VTX69bFXeMDCw',
    id: 6114,
    acronym: 'HVDKU(',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'hotelschool.nl',
    name: 'Hotelschool The Hague',
    web_page: 'http://www.hotelschool.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlQ1bvxuBKAf0rWL9_qSfdHvZXF9bKIrFO6KizYbzpNgNuQbx-laZXEKI',
    id: 6115,
    acronym: 'HH',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'hro.nl',
    name: 'Hogeschool Rotterdam',
    web_page: 'http://www.hro.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8f1i4rZfITW71aetR9HXOSKt2KXGbabGgpR8rrJd77cQ0RnBLy3ViUzg',
    id: 6116,
    acronym: 'HR',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'hsleiden.nl',
    name: 'Hogeschool Leiden',
    web_page: 'http://www.hsleiden.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTswmIjxz1Sv1r1_4hgNAEhQL0i0JZ3K8O5xBDeKuN_eAcGm_g5fvBwHA',
    id: 6117,
    acronym: 'HL',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'hu.nl',
    name: 'Hogeschool Utrecht',
    web_page: 'http://www.hu.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRvchjLl6A3eEoHdtBAB5oGQ9nOM1p1UH-IOEQm0jdFZk79Wsvdo2ZupVZ',
    id: 6118,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'hva.nl',
    name: 'Hogeschool van Amsterdam',
    web_page: 'http://www.hva.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1dGnRjJZEwXaODabKUx4gzHsAahmJy5EFpjlJhRGYTfsoHEVE1BoCQNA',
    id: 6119,
    acronym: 'HVA',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'inholland.nl',
    name: 'Hogeschool Inholland',
    web_page: 'http://www.inholland.nl/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQOXIuDK-vFncDKEipF_gdxQ9FtTesEyOhAOIHrbjpxzIbYBlFEKv9jL9g',
    id: 6120,
    acronym: 'HI',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'islamicuniversity.nl',
    name: 'Islamic University of Rotterdam',
    web_page: 'http://www.islamicuniversity.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFnkvY8xykvbFAGmAkWd5rtYLeQjMiZafIonWBaXSi9WdbdyC3tRSNXA',
    id: 6121,
    acronym: 'IUR',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'iss.nl',
    name: 'Institute of Social Studies',
    web_page: 'http://www.iss.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiCBjONWaEHc2XxaRac0GAMfMwgudNCaSYYSvNgjzkpGR-ChJ85G023wk',
    id: 6122,
    acronym: 'ISS',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'itc.nl',
    name: 'International Institute for Geo - Information Science and Earth Observation',
    web_page: 'http://www.itc.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMLxgCvLT1zAyY20-3VbtsnDXgSuhtnOXTja2OwrPbl_M2ib4DiQLydp0u',
    id: 6123,
    acronym: 'IIFG-ISEO',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'leidenuniv.nl',
    name: 'Leiden University',
    web_page: 'http://www.leidenuniv.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS17ynYccNwRAHSPaoNYdGPLMGMqEPG4zf_GfdaPFfkhlrS8BjzittuWL0',
    id: 6124,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'nhtv.nl',
    name: 'NHTV Breda University of Professional Education',
    web_page: 'http://www.nhtv.nl',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQWiQMzM-9iLykRLBt9pB4_FNNpIJoWmi1omTppGRBc51EheALibVyYiLCq1g',
    id: 6125,
    acronym: 'NBUPE',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'nyenrode.nl',
    name: 'Nyenrode Business University',
    web_page: 'http://www.nyenrode.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2_LIOQo3rmOlqIa4xvH_53Bu_vlb-o9_jwCO8WXnR8fql_YJ1hdxBflA',
    id: 6126,
    acronym: 'NBU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'ou.nl',
    name: 'Open University of the Netherlands',
    web_page: 'http://www.ou.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTqUXtPihly_ZQHfi6W9kEDbRpec6Yz4uUGrIzYQMUBrBngHzl5c6MHQ',
    id: 6127,
    acronym: 'OUN',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'roac.nl',
    name: 'Roosevelt Academy University College',
    web_page: 'http://www.roac.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIFN_WFn9gDNJYByGZA4w521pRT8bQrMizYy121LiLVgrvyEs-CfLKpvsQ',
    id: 6128,
    acronym: 'RAUC',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'rug.nl',
    name: 'University of Groningen',
    web_page: 'http://www.rug.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3lfkq4lfzActCQ6xZlzAybd40TM-i916CBjspeVH49KR1n8IjhMlJxw',
    id: 6129,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'ru.nl',
    name: 'Radboud University',
    web_page: 'http://www.ru.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN0-2xJCn9Hhta7cV-kmDxHeym24TJc-_zINUzbYyPd6_yCsCGq2iKmw',
    id: 6130,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'saxion.edu',
    name: 'Saxion Universities',
    web_page: 'http://saxion.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkChPMamVM1ZaW6U-qcnG0XA0IOoy7RtfwQ4-xCfd9WEaJp7JS26nZ8To',
    id: 6131,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'stenden.com',
    name: 'Stenden University',
    web_page: 'http://www.stenden.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbb5MeN10US1bXrFWXK1q8Su4CnK_4EZGiV_4hgaXI9TLN1mhldeG6qA',
    id: 6132,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'tilburguniversity.nl',
    name: 'Tilburg University',
    web_page: 'http://www.tilburguniversity.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_0YwR4V5nXCNXBHOZQzvkwsgnjK1vv_zdIDJEqKaVyvO7kSjrjttW6zo',
    id: 6133,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'tudelft.nl',
    name: 'Delft University of Technology',
    web_page: 'http://www.tudelft.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTox8aMClgO95RmWTVSTIn49RcIScjRB2xmNbnO87QvJoXZNJZmI_0WKg',
    id: 6134,
    acronym: 'DUT',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'tue.nl',
    name: 'Eindhoven University of Technology',
    web_page: 'http://www.tue.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSbZHBONwAhp2p5mfZu-2CJA1iT37pzsHnQG7K8ejrFdDvYcLDO9mBrQ',
    id: 6135,
    acronym: 'EUT',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'unesco-ihe.org',
    name: 'UNESCO-IHE Institute for Water Education',
    web_page: 'http://www.unesco-ihe.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAzPEKwWExBHZ3o_bAQaYWP3RtSIKg9_DLMJy1fLnbeCkOPlSsbyaWNGM',
    id: 6136,
    acronym: 'UIFWE',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'unimaas.nl',
    name: 'Maastricht University',
    web_page: 'http://www.unimaas.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkKrqabkQBi2zmLS0QBzzWHH4ZKlbHH_K23oWjWG3f6R_XKj75f0Gevk1R',
    id: 6137,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'utwente.nl',
    name: 'University of Twente',
    web_page: 'http://www.utwente.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGd-63bqGCYCN-1imrw7weD2KDw0sgEAUfDjKUX3Xm7soprD34hc6qgZQ',
    id: 6138,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'uu.nl',
    name: 'Utrecht University',
    web_page: 'http://www.uu.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAaN8JRBjaTRWUPhH2R82AaqhRmsNBYJQ3rNnMCDwybTiLq-QPp-v-lg',
    id: 6139,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'uva.nl',
    name: 'University of Amsterdam',
    web_page: 'http://www.uva.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiY-3WYfXBTMp6mv5QSPWxINdYcHhwOYg8YKx5C5vTLozN4IbR3KIb',
    id: 6140,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'uvh.nl',
    name: 'University for Humanistics (UH)',
    web_page: 'http://www.uvh.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQD7ooj5v-oqkur4v40D39rGP2NuiMUA_9I-EHJxYaJ9cs_SySqLxUzg',
    id: 6141,
    acronym: 'UFH(',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'vu.nl',
    name: 'Vrije Universiteit Amsterdam',
    web_page: 'http://www.vu.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToWN5d7-PmyFxUoLFMLvfTweMI8AVTPwnnSVecbMAJt72u52dBg29cn3w',
    id: 6142,
    acronym: 'VUA',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'wageningenuniversity.nl',
    name: 'Wageningen University',
    web_page: 'http://www.wageningenuniversity.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxI43z2gumRzgr6pghcNHgUjScvb5ZBCigHMyunY_jbR3OorOjLLvmfTo',
    id: 6143,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'windesheim.nl',
    name: 'Christelijke Hogeschool Windesheim',
    web_page: 'http://www.windesheim.nl/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTTk7llYXB-8muAhMxKaOB9LmPXRruFWxUqb6VggzRS1MGKq2Rwky5WHQ',
    id: 6144,
    acronym: 'CHW',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'wittenborg.eu',
    name: 'Wittenborg University',
    web_page: 'http://www.wittenborg.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSjsPB3UiChG96RZJ-FrPBqoaKPuOdv2vwosIrVHRcbrYPejrp0g4flak',
    id: 6145,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'NL',
    country: 'Netherlands',
    domain: 'zuyd.nl',
    name: 'Zuyd University',
    web_page: 'http://www.zuyd.nl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYxi6udEqBo2FoVTrkGae1vk2bZ_3vq30TyBDTAXWXqqSMDBlzhUnLxg',
    id: 6146,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'NC',
    country: 'New Caledonia',
    domain: 'ufp.nc',
    name: 'University of New Caledonia',
    web_page: 'http://www.ufp.nc/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPlzbmEeImKab0FRaiH35GOpUbBrkEsM1XuiUBezmG5_KgMu2qoZdTrus',
    id: 6147,
    acronym: 'UNC',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'auckland.ac.nz',
    name: 'University of Auckland',
    web_page: 'http://www.auckland.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgW264N7XCThW3lOcFbaDiG-ucloTsdEwaMrduWm3Qqv08pPkaDyhqWis',
    id: 6148,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'aut.ac.nz',
    name: 'Auckland University of Technology',
    web_page: 'http://www.aut.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXQHoNFQd3r-yGPwRfdgT_lJtLDQl_i1lNo6ba1Ad0iVTfl-nxSTsZ7WM',
    id: 6149,
    acronym: 'AUT',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'canterbury.ac.nz',
    name: 'University of Canterbury',
    web_page: 'http://www.canterbury.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuFMKLHWecEwzWQCnksxGa3VJacb7onw8Tz69V7gIPsok-roLCd8aSyun1',
    id: 6150,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'cpit.ac.nz',
    name: 'Christchurch Polytechnic Institute of Technology',
    web_page: 'http://www.cpit.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmx4eslp2mdeUVHDPmdSn_7BXDas-8phuDVJ7_Vxs9c7KK5bGWuGpu4w',
    id: 6151,
    acronym: 'CPIT',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'massey.ac.nz',
    name: 'Massey University',
    web_page: 'http://www.massey.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOJZQ_YW0M_HzHHr9VtTVvlDPI69WdgaviGF4Udr4EoeIfgVnxKvq44Ds',
    id: 6153,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'openpolytechnic.ac.nz',
    name: 'Open Polytechnic',
    web_page: 'http://www.openpolytechnic.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-0GtNJWSMzHJIKf9x0Yc86n4af_YUfJiIQf2cZ1-ynYcsupjQD6iZAVY',
    id: 6154,
    acronym: 'OP',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'otago.ac.nz',
    name: 'University of Otago',
    web_page: 'http://www.otago.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnnMmyaJiAQgOOAEgCTLIup_Vux53wz5sVCY_s69vAnTJFUyrpBx-w7Ik',
    id: 6155,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'vuw.ac.nz',
    name: 'Victoria University of Wellington',
    web_page: 'http://www.vuw.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYSOUeeh78D3dDVcZM77uUQy0FI5zaVYqv4bLgPvyano8p0LRWx4CK1Q',
    id: 6156,
    acronym: 'VUW',
  },
  {
    alpha_two_code: 'NZ',
    country: 'New Zealand',
    domain: 'waikato.ac.nz',
    name: 'University of Waikato',
    web_page: 'http://www.waikato.ac.nz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ69GrIdYv1lZCLeQlv68RPDb4wOQQetuOU-xsO_Gsmh4XH-5GtU970eYzs',
    id: 6157,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'uam.edu.ni',
    name: 'Universidad Americana',
    web_page: 'http://www.uam.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbH9GTwMlZhtrz--C0rD24e5AnlA2zYkvobH4-mABWiuWDRm4wkAR1jQ',
    id: 6158,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'uca.edu.ni',
    name: 'Universidad Centroamericana',
    web_page: 'http://www.uca.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsIyb4pGI5NERYzoEqfC1o8Sm2qlvPBz7urvxzF4FHMc5_s8B56QBVh2s',
    id: 6159,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'ucc.edu.ni',
    name: 'Universidad Ciencias Comerciales',
    web_page: 'http://www.ucc.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPbeWVJf4CwpySicTvw4NX7SG2TAeGSz1B4XkfBtiWFVGedYsU5G7QGYU',
    id: 6160,
    acronym: 'UCC',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'ucem.edu.ni',
    name: 'Universidad Centroamericana de Ciencias Empresariales (UCEM)',
    web_page: 'http://www.ucem.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcMfMEQTsdS1NDn2Qdw8TUFo_dLWesz9ZLOHnbs6XoChso9c7YTDUfAw',
    id: 6161,
    acronym: 'UCDCE(',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'ucyt.edu.ni',
    name: 'Universidad Nicaragüense de Ciencia y Tecnológica',
    web_page: 'http://www.ucyt.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuqbDcDwAUfHLkG1FAwc4z8NnDu4ThjgEAjj3ZTOm12N8WKXgl63hcBjg',
    id: 6162,
    acronym: 'UNDCYT',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'udem.edu.ni',
    name: 'Universidad de Managua (U de M)',
    web_page: 'http://www.udem.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdF3vhiQyHR2-rPZdMsNKGoi8oryDZHyhSu2vW23ZDaNAp_2RL5cxpwQ',
    id: 6163,
    acronym: 'UDM(DM',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'ulam.edu.ni',
    name: 'Universidad de las Américas (ULAM)',
    web_page: 'http://www.ulam.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa8Id4sy0EqJFsiBKBO8jqrqpt1lMQYdBsfdfe8SG_2VhgDAKujNYt5_g',
    id: 6165,
    acronym: 'UDLA(',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'una.edu.ni',
    name: 'Universidad Nacional Agraria',
    web_page: 'http://www.una.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNAKLAOoJo24CInqw-oV9-Gpo7MXDIQzcz9GUXfRM4JCpVrhcqhRM5gYI',
    id: 6166,
    acronym: 'UNA',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'unan.edu.ni',
    name: 'Universidad Nacional Autonoma de Nicaragua',
    web_page: 'http://www.unan.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt80-a4bxxTqmZ2Yl-WJejkXvYisDpCscRnvLiWyM5O_kPxw5jMm_30CYm',
    id: 6167,
    acronym: 'UNADN',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'unica.edu.ni',
    name: 'Universidad Catolica "Redemptoris Mater"',
    web_page: 'http://www.unica.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXjpRSCmCKqN-wg6yvlPouqGyrV9sUcrcAn2M5yvMmTckIgdm_A8o-IA',
    id: 6168,
    acronym: 'UC"M',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'unicit.edu.ni',
    name: 'Universidad Iberoamericana de Ciencia y Tecnologia',
    web_page: 'http://www.unicit.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvvz83HTQt-n4aC8H6jMEKgztzUrhXGiuB58otCiiyflX3E0OxkUhYwTqm',
    id: 6169,
    acronym: 'UIDCYT',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'uni.edu.ni',
    name: 'Universidad Nacional de Ingenieria',
    web_page: 'http://www.uni.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTisWVJJbpvepP1VO5EiK8EsBiCuyETtrgcTYMZyrcPFw-MflPLxmnF4lg',
    id: 6170,
    acronym: 'UNDI',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'unival.edu.ni',
    name: 'Universidad Internacional de Integración de América Latina',
    web_page: 'http://www.unival.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlNfbfWG30-0-DvH8nmcdzItKFCaCjeOyGQk4HURWPJMHRYNu9D31UbxU',
    id: 6171,
    acronym: 'UIDIDAL',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'upoli.edu.ni',
    name: 'Universidad Politecnica de Nicaragua',
    web_page: 'http://www.upoli.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgzZnYm22AZok2Topz1PW2JR6JX77Us4px_7jU7OiXGan3DWJl3WKFVo8',
    id: 6172,
    acronym: 'UPDN',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'uponic.edu.ni',
    name: 'Universidad Popular de Nicaragua (UPONIC)',
    web_page: 'http://www.uponic.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKHqpHyszc5fHcxjnQQ_RsA304PjWN-cl-DNyOVVzEbFnd3U-M4leTwe0',
    id: 6173,
    acronym: 'UPDN(',
  },
  {
    alpha_two_code: 'NI',
    country: 'Nicaragua',
    domain: 'uraccan.edu.ni',
    name: 'Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense',
    web_page: 'http://www.uraccan.edu.ni/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3ZKs2hGqR151Q27WDLg4qgNM8VaBHiz9R2i4ccVkTesqupKnj1d7l_sU',
    id: 6174,
    acronym: 'UDLRADLCCN',
  },
  {
    alpha_two_code: 'NE',
    country: 'Niger',
    domain: 'uam.refer.ne',
    name: 'Université Abdou Moumouni de Niamey',
    web_page: 'http://uam.refer.ne/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmok-sO5dnBQVokPmx6MOH9rX6rLA-RkuemcB9ed_TItgFs4AB9wtXBQ',
    id: 6175,
    acronym: 'UAMDN',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'aaua.edu.ng',
    name: 'Adekunle Ajasin University',
    web_page: 'http://www.aaua.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRkW2z2uLNWT3znIILHk1c7z3ttICymgGD2HM7pCbJAXiUnOSq4tpGs922',
    id: 6176,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'aauekpoma.edu.ng',
    name: 'Ambrose Alli University',
    web_page: 'http://www.aauekpoma.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1ER3l3GH50_VIkYTmo4BVPR_m4LS3VHUZDB5Eax_bZM9ywvlO5hsIX9I',
    id: 6177,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'abiapoly.edu.ng',
    name: 'Abia State Polytechnic',
    web_page: 'http://www.abiapoly.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiYxPByV2bwhkt7SHWgobx3TXTUbYVEcrHGvhIBPJVWqvTKkVL9ceqIyM',
    id: 6178,
    acronym: 'ASP',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'absu.edu.ng',
    name: 'Abia State University',
    web_page: 'http://www.absu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa3AZzHUl2cIU7q9CPY6DCez4SuOQkAKdycoRk9E3XSFM36KyRffV9hNKH',
    id: 6179,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'abuad.edu.ng',
    name: 'Afe Babalola University',
    web_page: 'http://www.abuad.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWmPc8OTbAie2n7BcurUnuIKGH51NEJZBKYjOTUr9zOogeO4T6OXQMaRAk',
    id: 6180,
    acronym: 'ABU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'abu.edu.ng',
    name: 'Ahmadu Bello University',
    web_page: 'http://www.abu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHEYGz5msHWS0BDYfViwSk1yavqWSmYCh8f9SgWH1qbpphjYPxSpf1eao',
    id: 6181,
    acronym: 'ABU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'aceondo-ng.com',
    name: 'Adeyemi College of Education',
    web_page: 'http://www.aceondo-ng.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXvV9aU1UfTxeFna8Srv8d4Qw7cFMhosqD6VUfVZQcwmpmrL6U-zHtwg',
    id: 6182,
    acronym: 'ACE',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'achievers.edu.ng',
    name: 'The Achievers University',
    web_page: 'http://www.achievers.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-hlB5zF7cpprnhxwZQgeF7EFMLkx_7D-Ij0mX101SzDs3wCtWZ-n9rg',
    id: 6183,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'acu.edu.ng',
    name: 'Ajayi Crowther University',
    web_page: 'http://www.acu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT17wlOHssSLFzEuxW8SzdQO26qB0uonGl8dRZQYBrnurwi1U7t0CShPYo',
    id: 6184,
    acronym: 'ACU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'adelekeuniversity.edu.ng',
    name: 'Adeleke University',
    web_page: 'http://www.adelekeuniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwagG7gBrGmdpOY-EYMYszGrOy1HFQoGtYdoVQUTFd-CEMyEr0tL81Eyw',
    id: 6185,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'adsu.edu.ng',
    name: 'Adamawa State University',
    web_page: 'http://www.adsu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK3YG_Qe8a-n8wgkB090BZfWRSOuXDUhaYC6bIP_qewPpj_grLLkDBFA',
    id: 6186,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'akutech.edu.ng',
    name: 'Akwa Ibom State University of Technology',
    web_page: 'http://www.akutech.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQddarkLy8LiN3mB4ZC6PzP_K5EP_gHapKwVTZ0iEhRY2ObMqF4JhgsQ9Bi',
    id: 6187,
    acronym: 'AISUT',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'alhikmah.edu.ng',
    name: 'Al-hikmah University',
    web_page: 'http://www.alhikmah.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX-glDKBF1uC0WhwkOGYwwT6K553BRpgeK1rDJBywyUtH3hr7XL0AwqA',
    id: 6188,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ansu.edu.ng',
    name: 'Anambra State University of Science and Technology',
    web_page: 'http://www.ansu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSByDreJliGLDGRPib6lXrXq9_CkYC-IK40kAixi1fKUd5DfOGRCHUs1Pv3',
    id: 6189,
    acronym: 'ASUST',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'atbu.edu.ng',
    name: 'Abubakar Tafawa Balewa University',
    web_page: 'http://www.atbu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaAmICBTMSDuY3ymePrJniJt1yUhewF4V9i_NMPyRysZzf9mGHFAbgNEl7',
    id: 6190,
    acronym: 'ATBU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'auchipoly.edu.ng',
    name: 'Auchi Polytechnic',
    web_page: 'http://www.auchipoly.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0MqjsL7HE4RqioFiDP3SobOVuKMLS8ZeIfmx1vqAXf3x1ssZjoKnSa2c',
    id: 6191,
    acronym: 'AP',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'aun.edu.ng',
    name: 'ABTI American University of Nigeria',
    web_page: 'http://www.aun.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0sMStM5IS6N61dffKb5u6lYtpgv0kugUmYrD1pJsORDaRRjuG05TfBg',
    id: 6192,
    acronym: 'AAUN',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'aust-abuja.org',
    name: 'African University of Science and Technology',
    web_page: 'http://www.aust-abuja.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-ZeiNwBrODwFpS2-4HtsGGlx8VHgfZfU650t-FtENmhRwKG3xrEGUdg',
    id: 6193,
    acronym: 'AUST',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'babcockuni.edu.ng',
    name: 'Babcock University',
    web_page: 'http://www.babcockuni.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWZoiPRDVDE4HDZ_YsSuYvobQeoh_AnHqwNc3ojrfi0RLHmVG0FzPHBhk',
    id: 6194,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'basug.edu.ng',
    name: 'Bauchi State University Gadau',
    web_page: 'http://www.basug.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8ZqWcxmyIykWGt4QQwRK6VKcIYBVLTfYNbcnNrAZk-z9Ml6Wn3wyUTHc',
    id: 6195,
    acronym: 'BSUG',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'bazeuniversity.edu.n',
    name: 'Baze University Abuja',
    web_page: 'http://www.bazeuniversity.edu.ng',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrkOTjlaCRJW5Mq4ON8Cd6aV-84pen3QR_0hZnbOKVIZGZaaaXgb_YPA',
    id: 6196,
    acronym: 'BUA',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'bellsuniversity.org',
    name: 'Bells University of Technology',
    web_page: 'http://www.bellsuniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvP-4-25NN9zEs9a1TkeG3wAowJRXV83UbgumdTxZNq5okNVbDMzs0PA',
    id: 6197,
    acronym: 'BUT',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'binghamuni.edu.ng',
    name: 'Bingham University',
    web_page: 'http://www.binghamuni.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP6qVtLGFTiaHdXXmJ4u4bu5Mdmj6bV1Jthz9XFw7-ilAxaaslr4NVrA',
    id: 6198,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'biu.edu.ng',
    name: 'Benson Idahosa University',
    web_page: 'http://www.biu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqa4-L2dHiVWebZ93C0bkS3zqUJxUGHcv1-_SCdGQzPeBQ2pYWVmQWzDk',
    id: 6199,
    acronym: 'BIU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'bowenuniversity-edu.org',
    name: 'Bowen University',
    web_page: 'http://bowenuniversity-edu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS58lgsC3yYmKaaPgi5mmuC_9h3TGz8jFuP9-qHFq_0-foKRqJJDuwuDKE',
    id: 6200,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'bsum.edu.ng',
    name: 'Benue State University',
    web_page: 'http://www.bsum.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN8j-vjvFyI31aQ9EPwzSBtKy8WN8XWqvcei2ukmttf1jsJVua08DhSw',
    id: 6201,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'buk.edu.ng',
    name: 'Bayero University Kano',
    web_page: 'http://www.buk.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUuqS-wKD_mp1DUzpX_MzPW3CwpR6cq8bVse8GlqPbTWWiXh8bR0lL3qU',
    id: 6202,
    acronym: 'BUK',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'calebuniversity.edu.ng',
    name: 'Caleb University',
    web_page: 'http://www.calebuniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJZmrg99vDnHEW5jwHlTcc2cEvJ38am733ptzFrE5cMIlbEeXtAaMJxVzd',
    id: 6203,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'caritasuni.edu.ng',
    name: 'Caritas University',
    web_page: 'http://www.caritasuni.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWH3PmiUAGduFwhuhBeb-V0rJzsQ5fVhkadO_bMhJ8YkHfShBrGSwudcg',
    id: 6204,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'coeikere.edu.ng',
    name: 'College of Education Ikere',
    web_page: 'http://www.coeikere.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3kZuywJDt_-f2V6qDfQmn0xyqppphffr6n6bhiKx_JQNbQ7eU4PaQLMA',
    id: 6205,
    acronym: 'CEI',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'coeoju.com',
    name: 'College of Education Oju',
    web_page: 'http://www.coeoju.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTynm72JnYPBABCpHK7Waoi8f6H6FJ2N_gikcLYzMOVLUK3yZa4aJc3Hhs',
    id: 6206,
    acronym: 'CEO',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'covenantuniversity.edu.ng',
    name: 'Covenant University',
    web_page: 'http://www.covenantuniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiSnPTbVJJNDOSU-ZlFlFVND9_GLRQDJymb9SMohrm_ipt17NnBv6T12s',
    id: 6207,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'crawforduniversity.edu.ng',
    name: 'Crawford University',
    web_page: 'http://www.crawforduniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuFSuM5kBC7hZrCjikUZOeLQH6tBIltFS1Qs5HN3PCBxYv_K2ODvUoJQ',
    id: 6208,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'crescent-university.edu.ng',
    name: 'Crescent University',
    web_page: 'http://www.crescent-university.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWvqKOhMpTy57mVp3AUchuWarNOxkpRf6du9onUfkWMYqcNxUUClRVtw',
    id: 6209,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'crutech.edu.ng',
    name: 'Cross River University of Science and Technology',
    web_page: 'http://www.crutech.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0wcPP35ihyeylrf3pAwtqi1GrT1iTerhzOuyTIiEHoQS4k2qMB_hyRt_4',
    id: 6210,
    acronym: 'CRUST',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ebsu-edu.net',
    name: 'Ebonyi State University',
    web_page: 'http://www.ebsu-edu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvWJpBPTEuz6-rHKbzmY2fkk0A8wO7gdw68skQOuTAzJuEkG3b1i-e4G8',
    id: 6211,
    acronym: 'ESU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'eksu.edu.ng',
    name: 'Ekiti State University',
    web_page: 'http://www.eksu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDNRJPd1Q3vSsnFc8ufuIkt4xAscq3glD7wsFbCNvUwGQgwyqBZFf44fc',
    id: 6212,
    acronym: 'ESU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'esut.edu.ng',
    name: 'Enugu State University of Science and Technology',
    web_page: 'http://www.esut.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzL9omXfXQzLfkmAImJJ5lovRQ8loI-sU9ZKsJo0MpR4xbXneJ7YwRkbE',
    id: 6213,
    acronym: 'ESUST',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'fcetakoka-edu.net',
    name: 'Federal College Of Education (Technical) Akoka',
    web_page: 'http://www.fcetakoka-edu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBPyQxIle_KITFG3z3SfOXgDYMm41prwCodvwnqMVxSignwCvbVWjxgA37',
    id: 6214,
    acronym: 'FCE(A',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'fedpoffa.edu.ng',
    name: 'The Federal Polytechnic Offa',
    web_page: 'http://www.fedpoffa.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUZIgGYv2JZRkq7MlppSWDd-x_M1voYfrRhsyIUzTBmeVRm3y3LVmv6Hk',
    id: 6215,
    acronym: 'FPO',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'fountainuniversity.edu.ng',
    name: 'Fountain University',
    web_page: 'http://www.fountainuniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYq2hZZHIpLAfaAsSiHsLsNLIj60-cocK0iyAtZpoq4xC4DnSpPfI8mvGi',
    id: 6216,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'fupre.edu.ng',
    name: 'Federal University of Petroleum Resources',
    web_page: 'http://www.fupre.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCnXhWzU01JU2yAC34zAAn73pxvoFgpR5DVLCD_dq-p-gK7yaRqwRTRw',
    id: 6217,
    acronym: 'FUPR',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'futa.edu.ng',
    name: 'Federal University of Technology Akure',
    web_page: 'http://www.futa.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzq7LHw_mkIrU9SOjeiEAeLh7yVmq2xbIN2dEFdDLFSHg95iGq3awj4G9a',
    id: 6218,
    acronym: 'FUTA',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'futminna.edu.ng',
    name: 'Federal University of Technology Minna',
    web_page: 'http://www.futminna.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkqEYca0xouaWS5vrOigGSbBYbEXV4f3lET__3ZN1ebed-wta7s75dBQ',
    id: 6219,
    acronym: 'FUTM',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'futo.edu.ng',
    name: 'Federal University of Technology Owerri',
    web_page: 'http://www.futo.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKkgzpPE9qJn5Y3VaVJqkiczywroeboVQhp6qYi1zYUL-UMb3r3UNLYw',
    id: 6220,
    acronym: 'FUTO',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'futy.edu.ng',
    name: 'Federal University of Technology Yola',
    web_page: 'http://www.futy.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0n_vlF2s-G4fxKR8sLGhnWw7wonWuzN9naBysq8WFIuODUshVO4K7qTg8jw',
    id: 6221,
    acronym: 'FUTY',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'gouni.edu.ng',
    name: 'Godfrey Okoye University',
    web_page: 'http://www.gouni.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPV3DLwIb_dya0UgQwEGfwKxc1rCht-PNla8Kwbr-4qTfPAlzxRbpMKCI',
    id: 6222,
    acronym: 'GOU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'gsu.edu.ng',
    name: 'Gombe State University',
    web_page: 'http://www.gsu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtNRPFHvONGDvSPoIU3FdebAhfj3wu8oNiL_gbCV3BV6Gy0H988nPRhoQ',
    id: 6224,
    acronym: 'GSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ibbu.edu.ng',
    name: 'Ibrahim Babangida University',
    web_page: 'http://www.ibbu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4hphK7sNptHLFe08Ov6nWI5584jYK1t1DIMTFJlM-eWaEUICPk-0RFLzd',
    id: 6225,
    acronym: 'IBU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'imsu.edu.ng',
    name: 'Imo State University',
    web_page: 'http://www.imsu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1Upyck8TB86z316X8TnkNsJFTFu8QSvnvSnF7_i0de0sMAL7PiHNKN7oT',
    id: 6226,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'iuokada.edu.ng',
    name: 'Igbinedion University',
    web_page: 'http://www.iuokada.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzaXWexV2MSuxUfKeU41tYQrUIQ22_skhc4fLzah61UXpRywypneIzgQ',
    id: 6227,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'jabu.edu.ng',
    name: 'Joseph Ayo Babalola University',
    web_page: 'http://www.jabu.edu.ng/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQYY9GtvKzMhk8HLJcYvjwirfx-ttYKgX6UdbfKai_6hM7jeXt0kzwoltY',
    id: 6228,
    acronym: 'JABU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'kasuportal.net',
    name: 'Kaduna State University',
    web_page: 'http://www.kasuportal.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQjFA0vu4VxDoYo4TH2zJVfMxAz8oMxIottPAUtdPhBdWSCUa5vnzZyA',
    id: 6229,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ksuportal.edu.ng',
    name: 'Kogi State University',
    web_page: 'http://www.ksuportal.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ941dCumYZA6oXxJse_dJOzpZok13o0NPZmpjNK_25qyy8YmaBtZYtaA',
    id: 6230,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ksusta.edu.ng',
    name: 'Kebbi State University of Science and Technology',
    web_page: 'http://www.ksusta.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMPAqWOUx1da6iHMoqZ920KoHGrkDW5tKCE5XtTXViDwgRLckC_Ew6pe8',
    id: 6231,
    acronym: 'KSUST',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'kuk.edu.ng',
    name: 'Katsina University',
    web_page: 'http://www.kuk.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHGsojGwsmgBDf4b2Ze2UeC-r-DKSdTgaDQHScPR6fcbBCawsYZrbBMQ',
    id: 6232,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'kustwudil.edu.ng',
    name: 'Kano State University of Technology Wudil',
    web_page: 'http://www.kustwudil.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGSlmliM2iHN2-kVOLLg15ZK9nyv8PVbotLafUsZuDI1He3-fxaP4Ev4Y',
    id: 6233,
    acronym: 'KSUTW',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'kwarapoly.edu.ng',
    name: 'Kwara State Polytecnic',
    web_page: 'http://www.kwarapoly.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLdBZmNIkmiHRwtlFENBtJXxtAOLH66IPkVBMWpIdjrquoPiAcm61DFjM',
    id: 6234,
    acronym: 'KSP',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'kwararafauniversity.edu.ng',
    name: 'Kwararafa University',
    web_page: 'http://www.kwararafauniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaS4BvyOu8_Z7fZXYJZrPf3n7X0AyidYJskDfmIGZKkSIOA2NlCtKkGls-Ag',
    id: 6235,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'kwasu.edu.ng',
    name: 'Kwara State University',
    web_page: 'http://www.kwasu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbU6tv2MjcqgjeWPoKT7y80UTTxio-pZCu2mWnhALHqMrPpONFSlz8gX8NmA',
    id: 6236,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'laspotech.net',
    name: 'Lagos State Polytechnic',
    web_page: 'http://www.laspotech.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBYM2BlyiN4OuJ1JtAORpySQHxxHxSlKibfW1BrICHxDN6o-BiK5aJ2kg',
    id: 6237,
    acronym: 'LSP',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'lasunigeria.org',
    name: 'Lagos State University',
    web_page: 'http://www.lasunigeria.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJUBcZ3y9n59oeB5EVQ7wb0nQIhLD1I35SZdZYywcFWBZrnx4gGVa4bg',
    id: 6238,
    acronym: 'LSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'lautech.edu.ng',
    name: 'Ladoke Akintola University of Technology',
    web_page: 'http://www.lautech.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6RJ8RpZwPbFrwsOkeqpy08fzRXlGgHsgAD8aH2BDUfohTQsql0Ctk2g',
    id: 6239,
    acronym: 'LAUT',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'lcu.edu.ng',
    name: 'Lead City University of Ibadan',
    web_page: 'http://www.lcu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZPxRsG9bdIbVuCWnWZZ65CeRSCdleToX_1O9z8dTm3vQyyzeQCK6D2g',
    id: 6240,
    acronym: 'LCUI',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'lmu.edu.ng',
    name: 'Landmark University',
    web_page: 'http://www.lmu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhGshJmZXVbLqYq4opZ-ve2Tp6uT4m5yTjCaUI9ZBda2fZnlN0FAUIZWk',
    id: 6241,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'madonnauniversity.edu.ng',
    name: 'Madonna University',
    web_page: 'http://www.madonnauniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJxgp5qUmbex2PAA4AOVcYcyNYS_nweEoUEAFUCWCCnlxyH75gZ7F_hv8',
    id: 6242,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'mouau.edu.ng',
    name: 'Michael Okpara University of Agriculture',
    web_page: 'http://www.mouau.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdbjTNVuYX4K-TJHxsMvMMLBdgND6eYBFBHN6ROZqOOMIk_oZ0Yv95yui5',
    id: 6243,
    acronym: 'MOUA',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'mylcp.net',
    name: 'Lagos City Polytechnic',
    web_page: 'http://www.mylcp.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBbNHL02BcOxYIvqxxLyludhW8Kix2gPaKtS4LGvBsfLQh2dpqEWIjiQ',
    id: 6244,
    acronym: 'LCP',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'nda.edu.ng',
    name: 'Nigerian Defence Academy',
    web_page: 'http://www.nda.edu.ng/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQpoECDXX4zt4KDwDN2To91x-sS2yb-Cr_DVgKTBtaX6x6IW2nExLRe5yU',
    id: 6245,
    acronym: 'NDA',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ndu.edu.ng',
    name: 'Niger Delta University',
    web_page: 'http://www.ndu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6Kd6m4rDJlPPyfJU0c5EwOCIFvlCut0ML9GFMf_AXIcAoX4Aao38G5Ig',
    id: 6246,
    acronym: 'NDU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'nou.edu.ng',
    name: 'National Open University of Nigeria',
    web_page: 'http://www.nou.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREjnsXyzKoRgF9dD2grCUJhMf9uuP1Pc5msLl_NeZZx_nciNuQPewdsvs',
    id: 6247,
    acronym: 'NOUN',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'novenauniversity.edu.ng',
    name: 'Novena University',
    web_page: 'http://www.novenauniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzcqrUoIq0UFl0lJaPzDGRMnq6J-mO_lvQxEHGY-9Z-gwgp7jufO_Pif4',
    id: 6248,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'nsuk.edu.ng',
    name: 'Nasarawa State University Keffi',
    web_page: 'http://www.nsuk.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqQ85MWU_fv9QYPYnFd6sVEM4buZPlbECkee7uMJ-sev_CuBQ1Vzu2GWur',
    id: 6249,
    acronym: 'NSUK',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ntnu.edu.ng',
    name: 'Nigerian Turkish Nile University',
    web_page: 'http://www.ntnu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQkcY6RJgj6faEb0ynHr3sWlsO4YZOZcRrDskd0YXCpSehZ8y6QVnJhg',
    id: 6250,
    acronym: 'NTNU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'oauife.edu.ng',
    name: 'Obafemi Awolowo University Ile-Ife',
    web_page: 'http://www.oauife.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRztGOV638ag6UjwNGqb60rlxR9hJGSdnnDRt9nZOn7zDoWJkLfEQvRCw',
    id: 6251,
    acronym: 'OAUI',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'obonguniversity.net',
    name: 'Obong University',
    web_page: 'http://www.obonguniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPIcvNpmpvcnn14RgDemHwe4flwrQ-IQRcond3WoXSKJaNvSEsV5FgzA1i',
    id: 6252,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'oduduwauniversity.edu.ng',
    name: 'Oduduwa University',
    web_page: 'http://www.oduduwauniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlelW6867U-ELEDeNcy4z0HW0JXYze-CrybiTRXqsFsqwUQ4kFxKkpyGw',
    id: 6253,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'oouagoiwoye.edu.ng',
    name: 'Olabisi Onabanjo University',
    web_page: 'http://www.oouagoiwoye.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQs75a9bNXMFbl1DvEmLqquor6CUGmUBH3zoLXfvISYlQMm5v75_GBPA',
    id: 6254,
    acronym: 'OOU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'osustech.edu.ng',
    name: 'Ondo State University of Science and Technology',
    web_page: 'http://www.osustech.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuivyAeSLea7CWriZubyznxMBpLNVkcRIUaaGGTI_tAOh-MW3XVoE',
    id: 6255,
    acronym: 'OSUST',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'pau.edu.ng',
    name: 'Pan-African University',
    web_page: 'http://www.pau.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9SmcoO68-H5XRQFxQfeocQNNbHdrUummrScijO80gUZ5OgPXlV7KqjHg',
    id: 6256,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'pauluniversity.edu.ng',
    name: 'Paul University',
    web_page: 'http://www.pauluniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlcT9ZZtpgWDtwGD8MdtTmQKpAZRmr1buG59NBvh3HjM_AsHKzqyR3Fw',
    id: 6257,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'polyibadan.edu.ng',
    name: 'Polytechnic Ibadan',
    web_page: 'http://www.polyibadan.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGa_0FYqRX1XP9LgFHU3IQ_nPWXd1DDmjwsU9ekhyLd27TdOaj1pIR9g',
    id: 6258,
    acronym: 'PI',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'polyunwana.net',
    name: 'Akanu Ibiam Federal Polytechnic Unwana',
    web_page: 'http://www.polyunwana.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXpF44mhQyyuyT4ang5OBHPnOgJP5T3aHkXfOYg5tM6qmZzBRhYMuUBXI',
    id: 6259,
    acronym: 'AIFPU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'rnu.edu.ng',
    name: 'Renaissance University',
    web_page: 'http://www.rnu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrwzxuj29UhIcpElUU_4KV0aRm4EOwJZnyN4Q7TnYPHtSRiExJwNEoEQ',
    id: 6260,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'run.edu.ng',
    name: 'Redeemers University',
    web_page: 'http://www.run.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMFlQgBRmuDWKYkwjUsV-MxUQzRvlBaa_QhF6hsbyZSjPmCMcrZDBso1MQZg',
    id: 6261,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'salemuniversity.edu.ng',
    name: 'Salem University',
    web_page: 'http://www.salemuniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj51VPrn-m8LSB-15WgJPyaQvXVwtsS4hUtyJSIrb_pHt0G3cKn1kwpyg',
    id: 6262,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ssu.edu.ng',
    name: 'Sokoto State University',
    web_page: 'http://www.ssu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4FioJNWZe3iV8XZKrXCnW72tWiYAqdVfPlawo8KsFUrxRqz10awvdPR0',
    id: 6263,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'tansianuniversity.edu.ng',
    name: 'Tansian University',
    web_page: 'http://www.tansianuniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkxB0C945r-HV25GWNiHItKc2rko0eq9I4VBYH7u9uRORBm7GsS5SuPw',
    id: 6264,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'tasued.edu.ng',
    name: 'Tai Solarin University of Education',
    web_page: 'http://www.tasued.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTESlX0za2AyqCMtLu_f3ercA5rp6eg0yFK0HnN82T0Refo7hgkmRlwmwUe',
    id: 6265,
    acronym: 'TSUE',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'tsuniversity.edu.ng',
    name: 'Taraba State University',
    web_page: 'http://www.tsuniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrgU1JVGj1VEspadKezXAZpHDD_iLrpUhly0h27SZhweeI-ZQwzGXFSQ',
    id: 6266,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'uam.edu.ng',
    name: 'University of Agriculture Makurdi',
    web_page: 'http://www.uam.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy1U04bsBkt0gJblMg6KGFRHR2SVSYGpP8uYjkwxn_aiZ4JNLSS30Zxw',
    id: 6267,
    acronym: 'UAM',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'udusok.edu.ng',
    name: 'Usmanu Danfodiyo University Sokoto',
    web_page: 'http://www.udusok.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_MFG2IQBEF9-cgFuz3AEdEaavNvlV_JaaGp_DQXj7g6_euA9OipwULgM',
    id: 6268,
    acronym: 'UDUS',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ui.edu.ng',
    name: 'University of Ibadan',
    web_page: 'http://www.ui.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyQVGL7fiErkoooC2FhfUvWDHqD2PttO3mPOwxwB6pIVOEFHFMRDvRMxA',
    id: 6269,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'umyu.edu.ng',
    name: "Umaru Musa Yar'Adua University",
    web_page: 'http://www.umyu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRD4a3NzlBzsElBVaTg5Vj-Y8KGNfjfIlsKHp_vIdaOnbRfJ2ffx8LSXs',
    id: 6270,
    acronym: 'UMYU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unaab.edu.ng',
    name: 'University of Agriculture Abeokuta',
    web_page: 'http://www.unaab.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSkM7oXuw-gF7hnLRafzaUZOMoPi7IgGED7LzKICzg8xkZ6uxDJ82YtVQ',
    id: 6271,
    acronym: 'UAA',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'uniben.edu',
    name: 'University of Benin',
    web_page: 'http://www.uniben.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9N-cRxBmof1hlLomo1b9PZZOZ4wgYUD5tylkwaYozeZGzWEHM_-l5hg',
    id: 6272,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unibuja.edu.ng',
    name: 'University of Abuja',
    web_page: 'http://www.unibuja.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj0qRNDUzV7kJ5bVTc3DwNA5peMQUXXxF2mVZcaVyPSrBfGvJaEWmjWw',
    id: 6273,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unical.edu.ng',
    name: 'University of Calabar',
    web_page: 'http://www.unical.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO1OiN0QZQWrBpSBiomFCyl7SH1Cf58PzmZuVhGzeSJHpOx8XTa0wn5Q',
    id: 6274,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unijos.edu.ng',
    name: 'University of Jos',
    web_page: 'http://www.unijos.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJw_USUPcuFiAX55bYKGdxE5mFCRuYCJ2l9P5WRBoYBbu3yO_jsNatiI8',
    id: 6275,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unilag.edu.ng',
    name: 'University of Lagos',
    web_page: 'http://www.unilag.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHmD_y63Y_RC-ABteF18afSeaRO-ZQINI3pRyQPqgRzBqUwVg4EJxCng',
    id: 6276,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unilorin.edu.ng',
    name: 'University of Ilorin',
    web_page: 'http://www.unilorin.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHp_gotN2AhBghjck0t82RaZzg-Dx-dPvlwTQd_mcfqEZqvCB26cFcTw',
    id: 6277,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unimaid.edu.ng',
    name: 'University of Maiduguri',
    web_page: 'http://www.unimaid.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHat7WM5c5IHZT6kxZh30YDTbuEC1__zOx216TTsHPQtVJs6ziK1OICqnA',
    id: 6278,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unimkar.edu.ng',
    name: 'University of Mkar',
    web_page: 'http://www.unimkar.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwV7At3F0uIOgTiAcOcw9bc8ftM09APrafP62SVwzuhVtUA5it1vdQ7wo',
    id: 6279,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'uniosun.edu.ng',
    name: 'Osun State University',
    web_page: 'http://www.uniosun.edu.ng/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRXwMENFemAnTz4eiHPzo8ehfqEGsNxposlG6gLF8mle7pYaMJ8pIl2qPE',
    id: 6280,
    acronym: 'OSU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'uniport.edu.ng',
    name: 'University of Portharcourt',
    web_page: 'http://www.uniport.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaXl1xcetrXz8UsbIVrFKXc14b1_a8tC6SkbuuqtXUoYagAVtJ12Eh8AA',
    id: 6281,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'uniuyo.edu.ng',
    name: 'University of Uyo',
    web_page: 'http://www.uniuyo.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW3Y4WoLzlqgavkWGwb3Tl7SVUeRZeO6smPVGmb6_3UWTjNSf_kDeJZYdl',
    id: 6282,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unizik.edu.ng',
    name: 'Nnamdi Azikiwe University',
    web_page: 'http://www.unizik.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWfikh9wp4_NMTbC2P-JS7p6xVBQkcV-dAm2CP15RPGaLN3DicpQuJOxA',
    id: 6283,
    acronym: 'NAU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'unn.edu.ng',
    name: 'University of Nigeria',
    web_page: 'http://www.unn.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwV7At3F0uIOgTiAcOcw9bc8ftM09APrafP62SVwzuhVtUA5it1vdQ7wo',
    id: 6284,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'ust.edu.ng',
    name: 'Rivers State University of Science and Technology',
    web_page: 'http://www.ust.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9Q4UpAAkU7IXdRrxcoONZ7kpl1emiURJthUujSYKC0zJW-wYisLCaMw',
    id: 6285,
    acronym: 'RSUST',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'veritas.edu.ng',
    name: 'Veritas University',
    web_page: 'http://www.veritas.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRduD18cJohEJUpCoUbA9EW-fc7ljEr7TOuqVxx1XvyP4epmCms49tNZQ',
    id: 6286,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'wdu.edu.ng',
    name: 'Western Delta University',
    web_page: 'http://www.wdu.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdYFtEzBkHvdBjduJKfQBG5mWeYzlm2irDcS9JhNZ1hhXGVKHXVXjrjrc',
    id: 6287,
    acronym: 'WDU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'wellspringuniversity.edu.ng',
    name: 'Wellspring University',
    web_page: 'http://www.wellspringuniversity.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWLZWK2fhyec1pF4uJd9ox_wDW33uVYov5gUn2-t-HHG_1Z0egobPQfw',
    id: 6288,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'wustoportal.edu.ng',
    name: 'Wesley University of Science and Technology',
    web_page: 'http://www.wustoportal.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqAw7Y24dOO6fjBrNhQ-V4IjZe_jSocbJDq8YmZKYWG-LHeeTYXAmm4g',
    id: 6289,
    acronym: 'WUST',
  },
  {
    alpha_two_code: 'NG',
    country: 'Nigeria',
    domain: 'yabatech.edu.ng',
    name: 'Yaba College of Technology',
    web_page: 'http://www.yabatech.edu.ng/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRA1gDhw7r1Q9wP8ghFx2BKDnxn3nguYM2TLbw760rXmpvUl8fQXt2QxdQF',
    id: 6290,
    acronym: 'YCT',
  },
  {
    alpha_two_code: 'NU',
    country: 'Niue',
    domain: 'stclements.edu.nu',
    name: 'St Clements University - Higher Education School',
    web_page: 'http://www.stclements.edu.nu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8jViW7Zopvs7iQCC6fk44l060Mt2X4TgeuaY1tXKNPGCXRaVCvQoVPsnp',
    id: 6291,
    acronym: 'SCU-HES',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'aho.no',
    name: 'Oslo School of Architecture',
    web_page: 'http://www.aho.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyVde3jf4ecxTfGIqq9FhS2knI785r6DvLI5KvNWiRD6VlCueiviL5Jg',
    id: 6292,
    acronym: 'OSA',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'bi.no',
    name: 'Norwegian School of Management BI',
    web_page: 'http://www.bi.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6EWDP8WIGv5A4gdTNRoyvP_jYItyB8tpPuLKbFAMjydUcpOfbZLwj9w',
    id: 6293,
    acronym: 'NSMB',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'hia.no',
    name: 'Agder University College',
    web_page: 'http://www.hia.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSs1-h6p6WxLg5c9apsVK9Y9cZtEQzzRj19O6qP50qpep_I7MY0N6l4ulcG6A',
    id: 6294,
    acronym: 'AUC',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'hib.no',
    name: 'Bergen University College',
    web_page: 'http://www.hib.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqpRivLwMH9t-AJBQZUr-5sRLYPPMNcKHQqWaRys7vOnuTU6EAtSxd6HU',
    id: 6295,
    acronym: 'BUC',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'hibo.no',
    name: 'Bodo Regional University',
    web_page: 'http://www.hibo.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIUE2Y1sEL1Zb0W-3TMU-EhPBspTXWtWvZmNvAsTEPdrlT9Zqo_P7f_xs',
    id: 6296,
    acronym: 'BRU',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'hifm.no',
    name: 'Finnmark University College',
    web_page: 'http://www.hifm.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrP0CINghMhOeEsQ6qx6sMAdTjA9LP_j9bmVhHKZzjfClo6N_A3tq-ksY',
    id: 6297,
    acronym: 'FUC',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'hil.no',
    name: 'Lillehammer University College',
    web_page: 'http://www.hil.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-MaMlieJDjzkZEwpH-MEr_A_o6kYYwhisUvZL7drKkQCTzNweassKaZA',
    id: 6298,
    acronym: 'LUC',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'himolde.no',
    name: 'Molde University College',
    web_page: 'http://www.himolde.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRk2Xedrr5adkMYM4f0IT18qbiVUOMYCNBVgQFYsUD8ebDLtnunGdTvhw',
    id: 6299,
    acronym: 'MUC',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'khio.no',
    name: 'National College of Art and Design',
    web_page: 'http://www.khio.no/shks/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdbi38RxiegGcsWErqnzeKpbBA_KtaqYWye-KZ_iYA59-TGrf4ztSPcVc',
    id: 6300,
    acronym: 'NCAD',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'nhh.no',
    name: 'Norwegian School of Economics and Business Administration',
    web_page: 'http://www.nhh.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdYX-RMYkE4gQcvtkvMYVZoP6XP9iZY1MV2v0zJedIGAs14XH7vPe_lGpm',
    id: 6301,
    acronym: 'NSEBA',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'nih.no',
    name: 'Norwegian University of Sport and Physical Education',
    web_page: 'http://www.nih.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGfKzrNzpv22ieIw2b5gQf6UeiItYjqVnNMAtf3Tlk-wO1PFdTqO6TAQ',
    id: 6302,
    acronym: 'NUSPE',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'nith.no',
    name: "Norway's Information Technology University College",
    web_page: 'http://www.nith.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ukPoCyND8nD9S6nhf0nFrZ9tECvUKPa2vwF2i_YZ0VIR6iOYhEDY0A',
    id: 6303,
    acronym: 'NITUC',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'nla.no',
    name: 'Norwegian Teacher Academy for Studies in Religion and Education',
    web_page: 'http://www.nla.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS90AXqLUaZ5hc-KzDVGGcpgDjg4DLDOQ1jVlR3sE2IVx9U9GyvWJfyZfbEw',
    id: 6304,
    acronym: 'NTAFSIRE',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'nlh.no',
    name: 'Agricultural University of Norway',
    web_page: 'http://www.nlh.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ72v2SsT4_dtVYo-zPKRORaV23DNJhoLNQcshi4bF8wxEgn88SQ78W9Ww',
    id: 6305,
    acronym: 'AUN',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'nmh.no',
    name: 'Norwegian State Academy of Music',
    web_page: 'http://www.nmh.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn_SGEYWkwRllQsfx2PjLKlyHlHza8PPPbQCbmguHNTPaX_hb_gjmRag',
    id: 6306,
    acronym: 'NSAM',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'ntnu.no',
    name: 'Norwegian University of Science and Technology',
    web_page: 'http://www.ntnu.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU9aJ8mhzo7Eo6rwlAn4XlEPtksruTU6QakpUFy518Fcm5iz7MpWf95w',
    id: 6307,
    acronym: 'NUST',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'ska.khio.no',
    name: 'National Academy of Fine Arts',
    web_page: 'http://ska.khio.no/main',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvOX0AdY0ZzpT9n452SLoDMtTjXS-4OhTcyqtLoofqzJ2ze5aWsxcu3Wk',
    id: 6308,
    acronym: 'NAFA',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'uib.no',
    name: 'University of Bergen',
    web_page: 'http://www.uib.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNGajBaDucU_UQuOBzT1FyeTU3R7tOZqPluWR9cPXaO6MvTXAqoRmvo4o',
    id: 6309,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'uio.no',
    name: 'University of Oslo',
    web_page: 'http://www.uio.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn3UJq-aB-CLjXEXectGqr7_bfZbyLryEo88NdVK-7PUoV0DfLrCkRFs2UIQ',
    id: 6310,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'uis.no',
    name: 'University of Stavanger',
    web_page: 'http://www.uis.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9FQhqd4EGgHuGPtgYpEuObdcF0Up1qqEmVK642ssxpBjum-BD3Q2YM2CrKQ',
    id: 6311,
    acronym: 'US',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'uit.no',
    name: 'University of Tromsø',
    web_page: 'http://www.uit.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRg6yp-KSSd-dq9yQL4Xq10IYlmA2lYnq-z9AA79CyRZJfZ5eCf1qBKVw',
    id: 6312,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'umb.no',
    name: 'Norwegian University of Life Sciences',
    web_page: 'http://www.umb.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT196c7LHdPbMBPiXyHVGePmh_0daHoAnxiZ_ak0KQEtbeiL0p09GZRsA',
    id: 6313,
    acronym: 'NULS',
  },
  {
    alpha_two_code: 'NO',
    country: 'Norway',
    domain: 'veths.no',
    name: 'Norwegian College of Veterinary Medicine',
    web_page: 'http://www.veths.no/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ41yfPtY7WskSt-P3wRH928nPhCzdrwbJt3r7ZI4P2dVyOLb1TYScymaY',
    id: 6314,
    acronym: 'NCVM',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'buc.edu.om',
    name: 'Al-Buraimi University College',
    web_page: 'http://www.buc.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7uM3JlTrEUcO24xvb4TiqBqhN2Ofk9BiUCfIjN6yO3c3xlZyFHpAkGm8',
    id: 6316,
    acronym: 'AUC',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'du.edu.om',
    name: 'Dhofar University',
    web_page: 'http://www.du.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-8FqeuaXZ77pQbnMZVrgXW4tnw-r7g7WRKz9FX9me7RBiZEosNNvjzpEJ',
    id: 6317,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'ict.edu.om',
    name: 'Ibra College of Technology',
    web_page: 'http://www.ict.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtrK0ZicKTpm9n0K7FMIU_YqJZaNrO2-GCTcWE1h8QILfASGYyQ0Er1A',
    id: 6318,
    acronym: 'ICT',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'majancollege.edu.om',
    name: 'Majan University College',
    web_page: 'http://www.majancollege.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0OIA-keWsE4f_opBSOUh6L2jj5jFJuDq5mv0GsHQjBGgE--i2xklCD7k',
    id: 6319,
    acronym: 'MUC',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'muscatcollege.edu.om',
    name: 'Muscat College',
    web_page: 'http://www.muscatcollege.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCD-O9HB0for43pPCLBTovlqdaKOqfSTBpLlb68KrataAsqvD8NyvIgYXM6Q',
    id: 6320,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'omancollege.edu.om',
    name: 'Oman College Of Management & Technology',
    web_page: 'http://www.omancollege.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5S1K5kmo2ZzK0L0toGIUDxnqf-xhC0t1hcZEWR-RRCTBJ-M9avy9tQBs',
    id: 6321,
    acronym: 'OCM&T',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'soharuni.edu.om',
    name: 'Sohar University',
    web_page: 'http://www.soharuni.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBW-bLFBHboucN72FIV_jwHT02cFay93aGJ_16aIvG0fGRENaVIE26ng',
    id: 6322,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'squ.edu.om',
    name: 'Sultan Qaboos University',
    web_page: 'http://www.squ.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzBXVs1QjRrdPyqMvkByPvVBA4JgBEbH0Su8R-2WCBNVjnKtKMktNGg-4E',
    id: 6323,
    acronym: 'SQU',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'suc.edu.om',
    name: 'Sur University College',
    web_page: 'http://www.suc.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTv31u_9pVdK2rqslRaTjHx1SpmjpDdf6rnDTrosE8E9aKMw6X7uiMVAI',
    id: 6324,
    acronym: 'SUC',
  },
  {
    alpha_two_code: 'OM',
    country: 'Oman',
    domain: 'unizwa.edu.om',
    name: 'University of Nizwa',
    web_page: 'http://www.unizwa.edu.om/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3gUsYPi5do__B7zL-hYGPQPkMw83IUMCaKSrkvVCwRMZqzAZ7R2xKng',
    id: 6325,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'abasyn.edu.pk',
    name: 'Abasyn University Peshawar',
    web_page: 'http://www.abasyn.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzQU6bU7VNWIdO7nuqiA6CzNfvd8gq07gLhWs8esFwQizLPDqJdLtmNNc',
    id: 6326,
    acronym: 'AUP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'aiou.edu.pk',
    name: 'Allama Iqbal Open University',
    web_page: 'http://www.aiou.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBiL5yMbG3-Rb794p9xXjaHByVF433pR4N8nQgtDXXdLrlo_Fs3QiTRQ',
    id: 6327,
    acronym: 'AIOU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ajku.edu.pk',
    name: 'Azad Jammu and Kashmir University',
    web_page: 'http://www.ajku.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_J3JIPecLPAcaDoyVnOxdWpvIVGJPf9PZHR-0EUXd8NDCskE7Ww7E9w',
    id: 6328,
    acronym: 'AJKU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'aldirasat.edu.pk',
    name: 'University of Islamic Studies',
    web_page: 'http://www.aldirasat.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWrveYdobDWByFXAbjcTNVTQ7NGNX_GL70oT25lL2Pf1-Gh0-c-2GjVw',
    id: 6330,
    acronym: 'UIS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'alkhair.edu.pk',
    name: 'Al-Khair University',
    web_page: 'http://www.alkhair.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAn98751RctJyk80BIBbOB2tMXbE0Q5RBSibYof1215aA5y8f7N23cEA',
    id: 6331,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'alphaomega.edu.pk',
    name: 'Alpha Omega University',
    web_page: 'http://www.alphaomega.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpNDyvAK6T64MOQCVClZCf5_hGqATZR-9IGiVt3xkHryhtFL9228KNGA',
    id: 6332,
    acronym: 'AOU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ami.edu.pk',
    name: 'Asian Management Institute',
    web_page: 'http://www.ami.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCHODUWVYRYYXaXsGueYMpeR6lhh5aHB48sQD1nRt3_OpeAzBoK-H1_EY',
    id: 6333,
    acronym: 'AMI',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'apiit.edu.pk',
    name: 'Asia Pacific Institute of Information Technology Pakistan (APIIT PAK)',
    web_page: 'http://www.apiit.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0gqWBNkYMPDwkgQRYuF-7MIe1jssrEOx_bmT2Ksy6EilOeRR_p2yncfA',
    id: 6334,
    acronym: 'APIITP(P',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'au.edu.pk',
    name: 'Air University',
    web_page: 'http://www.au.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCF_CqV0U1ncwShj34yLbgvLjAJ22g9UvTxX2K6fTY1R7OYgWJMM9Q5aE',
    id: 6335,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'auh.edu.pk',
    name: 'American University of Hawaii Punjab Campus',
    web_page: 'http://www.auh.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO0tGejpJT8_gObPmrswGKYRvKzyjmM9y6s0ZTE5451khXOelIHnl8qA',
    id: 6336,
    acronym: 'AUHPC',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'aup.edu.pk',
    name: 'North West Frontier Province Agricultural University',
    web_page: 'http://www.aup.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwCX8-ZqyMomTest3mWFU8i77rmuwsmaICgg5PlJObdcknl3dwH6lP21AXPg',
    id: 6337,
    acronym: 'NWFPAU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'bahria.edu.pk',
    name: 'Bahria University',
    web_page: 'http://www.bahria.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjiHsJBG6aQ9FD35fM40iRd8YeX1pRROqVWPjB9FH5mdM2MW6fim4i2Q',
    id: 6338,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'baqai.freeyellow.com',
    name: 'Baqai Medical University',
    web_page: 'http://baqai.freeyellow.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTha9wjlVoqJ-bsbFAdqVdo4SYU9EOq-PU6VMZUwALXzRulneaT3DsC-w',
    id: 6339,
    acronym: 'BMU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'biztek.edu.pk',
    name: 'Institute of Business & Technology',
    web_page: 'http://www.biztek.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn8JmI0tD1dGS4Gb4oJIdK6ew4QkJuW_g6Dzsq-lhdLNrBj2PZjPiuXOk',
    id: 6340,
    acronym: 'IB&T',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'buetk.edu.pk',
    name: 'Balochistan University of Engineering and Technology Khuzdar',
    web_page: 'http://www.buetk.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA07Ii--W1Kh-sU5llWDIbB6fShEVhkYFINMsK-pjji1pjAN881kpwAC4',
    id: 6341,
    acronym: 'BUETK',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'buitms.edu.pk',
    name: 'Balochistan University of Information Technology & Management Sciences',
    web_page: 'http://www.buitms.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn2H6l7dTdFaS0NeYMRumyzXg4KzXH7pM8p2cuQ4HpFig7ciAjgU6ekqk',
    id: 6342,
    acronym: 'BUIT&MS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'bzu.edu.pk',
    name: 'Bahauddin Zakariya University Multan',
    web_page: 'http://www.bzu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVRsox1UDYRs4SRvDO5ZuwCHiEDmiJey1aThCStkahPJQwnphT_zQqNw',
    id: 6343,
    acronym: 'BZUM',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'cbm.edu.pk',
    name: 'College of Business Management ( CBM )',
    web_page: 'http://www.cbm.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrThS_XpItQe-NNbfXakE8YjoMSlaP6P_BNdhXMxYOfZytiTAMtrE7ng',
    id: 6344,
    acronym: 'CBM(C)',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'cecos.edu.pk',
    name: 'Cecos Univeristy of Information Technology',
    web_page: 'http://www.cecos.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjHcOeRmLVf1LivUS-0zJ6Mq4hbFhSj7vTE-AzqautBxFmHR22SbDCRA',
    id: 6345,
    acronym: 'CUIT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ciit-atd.edu.pk',
    name: 'COMSATS Institute of Information Technology Abbottabad',
    web_page: 'http://www.ciit-atd.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsEIwxBPiKc1AB42ywGoUWF--K5tQQiHOWL59ta4cHXCk1--NknSTn-ZcQ',
    id: 6346,
    acronym: 'CIITA',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ciit-attock.edu.pk',
    name: 'COMSATS Institute of Information Technology Attock',
    web_page: 'http://www.ciit-attock.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsEIwxBPiKc1AB42ywGoUWF--K5tQQiHOWL59ta4cHXCk1--NknSTn-ZcQ',
    id: 6347,
    acronym: 'CIITA',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ciit.edu.pk',
    name: 'COMSATS Institute of Information Technology',
    web_page: 'http://www.ciit.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsEIwxBPiKc1AB42ywGoUWF--K5tQQiHOWL59ta4cHXCk1--NknSTn-ZcQ',
    id: 6348,
    acronym: 'CIIT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ciitlahore.edu.pk',
    name: 'COMSATS Institute of Information Technology Lahore',
    web_page: 'http://www.ciitlahore.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDL3GDRvThKnNfkDeuwCsYgJM6gTTY0N_SQ2dsX9oo8g3TeOzpprjq4uUr8Q',
    id: 6349,
    acronym: 'CIITL',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ciit-wah.edu.pk',
    name: 'COMSATS Institute of Information Technology Wah',
    web_page: 'http://www.ciit-wah.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUnH_Va6HlUraE0iKk18Tf2fK4iuD8vh3j6XUUn008IHIboHOy8poI7Q',
    id: 6350,
    acronym: 'CIITW',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'cosmiq.edu.pk',
    name: 'COSMIQ Institute of Technology',
    web_page: 'http://www.cosmiq.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRITNWldeXL26Cz2mcLP8UJkahDLppPcsBqylY7ZdHuWt9W1rubwK_OEQ',
    id: 6351,
    acronym: 'CIT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'dadabhoy.edu.pk',
    name: 'Dadabhoy Institute of Higher Education',
    web_page: 'http://www.dadabhoy.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToslvQnoiVQxDm9jSdXbJBWSK-I_tyYxD6DXiFEanIRTra7NDzD54sVw',
    id: 6352,
    acronym: 'DIHE',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'dcet.edu.pk',
    name: 'Dawood College of Engineering and Technology',
    web_page: 'http://www.dcet.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR92aCvnrvcqemquNq_smw7r2mG8Fb5s2wQx191HJF3RRkY7IbxO4s7FQ',
    id: 6353,
    acronym: 'DCET',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'duhs.edu.pk',
    name: 'Dow University of Health Sciences',
    web_page: 'http://www.duhs.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQL2CfuyjHtP9NyeUeLM1tYk7dL1cNMISDgYusPE3FjOX9nzTDxir6VbA',
    id: 6354,
    acronym: 'DUHS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'fitfd.edu.pk',
    name: 'Faisalabad Institute of Textile and Fashion Design',
    web_page: 'http://www.fitfd.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFab9Q36kum79dzB80OqtUpxrP7b7oM_q6lTuG2qegEgrWB1xX_FAMDmg',
    id: 6355,
    acronym: 'FITFD',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'fuuast.edu.pk',
    name: 'Federal Urdu University of ArtsScience and Technology',
    web_page: 'http://www.fuuast.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhMAqPFyHgr7hZw4SEhvsGPzxNiwg35g0MrndclyetDwuhDQEMHbTuECQ',
    id: 6356,
    acronym: 'FUUAT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'fwu.edu.pk',
    name: 'Shaheed Benazir Bhutto Women University',
    web_page: 'http://www.fwu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi4gBMCIdDob-rRXOGcAe3N5MkCuceoxmyrHCwepk_dWazuzKdHL88F3s',
    id: 6357,
    acronym: 'SBBWU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'gandhara.edu.pk',
    name: 'Gandhara Institute of Medical Sciences',
    web_page: 'http://www.gandhara.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrdNUTPo-Uch6lmJveNIH2JtO2FiHZwOi87LU6Rm3LQwfIWN9EyI5eYz4',
    id: 6358,
    acronym: 'GIMS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'gcu.edu.pk',
    name: 'Government College University Lahore',
    web_page: 'http://www.gcu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjOCMOznPvYsjmLFCDUF5HA_IYjxgSu8ahBPr6giaP8KUL4C6PmZpZcQ',
    id: 6359,
    acronym: 'GCUL',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'gcuf.edu.pk',
    name: 'Government College University Faisalabad',
    web_page: 'http://www.gcuf.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIX4NdKEbfggm2135mtgbNgiYxyg2cOEAUjhpphWifhtZupgfQKYs-gg',
    id: 6360,
    acronym: 'GCUF',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'geocities.com',
    name: 'University of Baluchistan',
    web_page: 'http://www.geocities.com/Tokyo/7507/uob.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLjMr34TNJ9MpRH45mVxlKmpeX_9a-kYhX6KEITBHYr36qNGtNtcpLvWM',
    id: 6361,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'geocities.com',
    name: 'Zia-ud-Din Medical University',
    web_page: 'http://www.geocities.com/CollegePark/7329/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpKAJW4bbYyndN7S-bqGuZXAWzymRXH3AmKViB21fHo8AH1ot_0WAwEmg',
    id: 6362,
    acronym: 'ZMU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'gift.edu.pk',
    name: 'Gift University',
    web_page: 'http://www.gift.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdjRsXpi4ozwNbhSWtH1E3E64KMolKQZ1bfLr0IF_GPGeXNlalThdZ9W4',
    id: 6363,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'giki.edu.pk',
    name: 'Ghulam Ishaq Khan Institute of Science & Technology',
    web_page: 'http://www.giki.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcST2MQKy39SNvHQyg-paZ55VkFdX9KpOR5saop2Ljeqv6jfAn6fgfCiltA',
    id: 6364,
    acronym: 'GIKIS&T',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'gist.edu.pk',
    name: 'Gangdara Institute Of Science & Technology',
    web_page: 'http://www.gist.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 6365,
    acronym: 'GIS&T',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'global.edu.pk',
    name: 'The Global College Lahore',
    web_page: 'http://www.global.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqNOrPxm-yqi5FTGurxxiPCmU7bwkhH6BbXI0BKzSrwRO0y2S9TO1BQg',
    id: 6366,
    acronym: 'GCL',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'gu.edu.pk',
    name: 'Gomal University',
    web_page: 'http://www.gu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYRw8kk3eeF7-nJOnpfn_JSjpb03zAFPNJpYAKnARSId4A3rOxbyMLR8Ov',
    id: 6367,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'guic.edu.pk',
    name: 'Greenwich University',
    web_page: 'http://www.guic.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjDXC3cHV8cawJE5-qtPyaGkVqRJkmSsy6jILJ-HXJxRoDZHuk8-WQBA',
    id: 6368,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'hajvery.edu.pk',
    name: 'Hajvery University Lahore for Women',
    web_page: 'http://www.hajvery.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5_xZuSzSWm8TVsHzs3dY61T4OfynC5gzTCJ-YNWXV16IldOQrQ0mBdRs',
    id: 6369,
    acronym: 'HULFW',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'hamdard.edu.pk',
    name: 'Hamdard University',
    web_page: 'http://www.hamdard.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTjQaElDN77OkJjFrPgneTtwnmZKb7DN_X9CtWIR01X2JuTiBodu9pfT0',
    id: 6370,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'hitecuni.edu.pk',
    name: 'Hitec University',
    web_page: 'http://www.hitecuni.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlzYts-8YQLdq0-AaM56OpnKpNyhr0x1CaWRP0IPfHhw35Na4Rfc4RyQ',
    id: 6371,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'hu.edu.pk',
    name: 'Hazara University',
    web_page: 'http://www.hu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSx7EBLQVw0-dss0mbZnLqukQ9rJ2XkO3Mfr2yFO3gESvwqhpBvdiuqipY',
    id: 6372,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'iba.edu.pk',
    name: 'Institute of Business Administration (IBA)',
    web_page: 'http://www.iba.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwDJHN_vVNclYSsgyrP5KeNpv5DQnx29j47RJnXN1ynLyfFVQIKwp2_OI',
    id: 6373,
    acronym: 'IBA(',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'iba-suk.edu.pk',
    name: 'Institute of Business Administration Sukkur',
    web_page: 'http://www.iba-suk.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLQsGeNBPjQw4Jizyz6vvaNMzt4Oa8kNQXpa0iDAiSh7Tu_vp09y5SJno',
    id: 6374,
    acronym: 'IBAS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'iiee.edu.pk',
    name: 'Institute of Industrial Electronics Engineering',
    web_page: 'http://www.iiee.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMcfGFI0O9RK6uP1qmq_UxDa5pFkb6l7glyhQYA2XS9Uyq6nOCKNadNlM',
    id: 6375,
    acronym: 'IIEE',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'imsciences.edu.pk',
    name: 'Institute of Management Sciences Peshawar',
    web_page: 'http://www.imsciences.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4lD78tH1ECBkFPOKFxU5-1-aT9f7ZkK4rZaH3R3fDc_B2QP1PI-oNRA',
    id: 6377,
    acronym: 'IMSP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'indus.edu.pk',
    name: 'Indus Institute of Higher Education',
    web_page: 'http://www.indus.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAV_-ORlEGsEwPCrhw1zO_udEYAJJp6lHTcPA4ril-LrLXs_-ehObOdA',
    id: 6378,
    acronym: 'IIHE',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'iqra.edu.pk',
    name: 'Iqra University',
    web_page: 'http://www.iqra.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrpqq-uwPHSc3ihDqWhBS72rbfddMHwVfTg9NIaEcWc7Ys1-ApjkbIQ',
    id: 6379,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'isra.edu.pk',
    name: 'Isra University',
    web_page: 'http://www.isra.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGSkl_GI-q9a-8iHYP7WXBO5uUXOX97aDC4kOflEKKKBPVrXiACaBGxQ',
    id: 6380,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ist.edu.pk',
    name: 'Institute of Space Technology',
    web_page: 'http://www.ist.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlx0GtpK2hvkYLNIFwD5I88rKHIjSVECNuutQ0FFIBbHSpTdOz1AsUoVA',
    id: 6381,
    acronym: 'IST',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'iub.edu.pk',
    name: 'Islamia University of Bahawalpur',
    web_page: 'http://www.iub.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt3OXnvoVEAiCt6AuV3Jt5mkYJxXuLmYTTL8ORxRoYkDUS-gpBFysX9n4',
    id: 6382,
    acronym: 'IUB',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'jinnah.edu',
    name: 'Mohammad Ali Jinnah University Karachi',
    web_page: 'http://www.jinnah.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDzC6WSWgZEM4yXdnIeTaCtV8xeXEBqXy3fIIMmeohtIU27yZm_znVL2g',
    id: 6383,
    acronym: 'MAJUK',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'jinnah.edu.pk',
    name: 'Mohammad Ali Jinnah University',
    web_page: 'http://www.jinnah.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNqrWY268UCj9P50_z0gdb23eInhxF7F47dIjh-MLFKhGc41wDz40Rx6R3',
    id: 6384,
    acronym: 'MAJU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'juw.edu.pk',
    name: 'Jinnah University for Women',
    web_page: 'http://www.juw.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRPUEGOSZBpiLKDPUqI6S1nIW5omeokbTeeKXR-yvtFYWA3g7m0EXnVA',
    id: 6385,
    acronym: 'JUFW',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'kasbit.edu.pk',
    name: 'KASB Institute of Technology',
    web_page: 'http://www.kasbit.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBFg83YxzyjY54eGcnBW4PDq4V1n2MgizDOu-rPf__M221R_Y10k4QFA',
    id: 6386,
    acronym: 'KIT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'kiit.edu.pk',
    name: 'Karachi Institute of Information Technology',
    web_page: 'http://www.kiit.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRClhTYy0yuwu6DVDqS4WPBDxBuBdBZVyzgGmDT5qjRVHK7IsRIbHm_XQ8',
    id: 6387,
    acronym: 'KIIT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'kiu.edu.pk',
    name: 'Karakoram International University',
    web_page: 'http://www.kiu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQge6O_RE0psx2hwu03V8J5BFl8-rsg9Mi23G-SJjWA-xueEE37aIGf84',
    id: 6388,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'kmdc.edu.pk',
    name: 'Karachi Medical and Dental College',
    web_page: 'http://www.kmdc.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnmliJ4FI-Gsgd7JjjKXHJoMpb1_wEpbujwq7EX7S9OLb91OXB73fDlx8',
    id: 6389,
    acronym: 'KMDC',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ksa.edu.pk',
    name: 'Karachi School of Art',
    web_page: 'http://www.ksa.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTheBJxDDYBx2CV3gSLQfR9cAbVl8RTCwmm8Pb0tZGe3ajdGgyOkyA3pQ',
    id: 6390,
    acronym: 'KSA',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'kust.edu.pk',
    name: 'Kohat University of Science and Technology  (KUST)',
    web_page: 'http://www.kust.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLumuQ_IYHw6XWsMRQUn5vQKxJ2XU7JBnQeEFsKTyeueNYVgN56WC7bg',
    id: 6391,
    acronym: 'KUST(',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'lcwu.edu.pk',
    name: 'Lahore College for Women University',
    web_page: 'http://www.lcwu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnaPpRwN7wTht25etzOqJYF_sDiYjS1HBPtc9JjLXGJZDvSDzt5GhO9g',
    id: 6392,
    acronym: 'LCFWU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'lumhs.edu.pk',
    name: 'Liaquat University of Medical & Health Sciences Jamshoro',
    web_page: 'http://www.lumhs.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4sg3wsfik7f222-O3cX3JLKXU-jvclTR22XBhR0qSdymMHQFSdq4rl-E',
    id: 6393,
    acronym: 'LUM&HSJ',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'lums.edu.pk',
    name: 'Lahore University of Management Sciences',
    web_page: 'http://www.lums.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFZ4FiO1JtDEbqMUcMd4_kZ4C36YY6r_SktDxrmvMm_RKV8BayFd4w8QI',
    id: 6394,
    acronym: 'LUMS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'miu.edu.pk',
    name: 'Mohi-ud-Din Islamic University',
    web_page: 'http://www.miu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvyLCshAlujttFpkV8QskFm08lODXtqUpqKO2wCY-7ws75CwyUqB7-Qj4',
    id: 6395,
    acronym: 'MIU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'moorelanduniversity.com',
    name: 'Mooreland University',
    web_page: 'http://www.moorelanduniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQClB6LViUo9lHcg4pI_6jB14mFUhyjO0oh1La3GVq6k1bvDYqPfuBQ4Ib',
    id: 6396,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'muet.edu.pk',
    name: 'Mehran University of Engineering & Technology',
    web_page: 'http://www.muet.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTccMllurcQA1ndVe8xJdF7V3EmrKXgIsfrsZ0cxoglQV06vhSBPR-quA',
    id: 6397,
    acronym: 'MUE&T',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'mul.edu.pk',
    name: 'Minhaj University Lahore',
    web_page: 'http://www.mul.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQf6dTNOwwfdFhfcabnamcwQ3kWhkuH9LOAlXTlkbDejuygsDlfO1_u623',
    id: 6398,
    acronym: 'MUL',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'nca.edu.pk',
    name: 'National College of Arts',
    web_page: 'http://www.nca.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxATMDahwINj8OrLSOaJqB9k9DkDnCtpCADhjbFu01KJKZVRRva3P7ZV0',
    id: 6399,
    acronym: 'NCA',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'neduet.edu.pk',
    name: 'NED University of Engineering and Technology Karachi',
    web_page: 'http://www.neduet.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFnkCXgpbAypABzklBeWDpuciv0YoEYD_Sg6E2CMv_QhhOWwKzEM1DSDAx',
    id: 6400,
    acronym: 'NUETK',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'newports.edu.pk',
    name: 'Newports Institute of Communication & Economics',
    web_page: 'http://www.newports.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGnkum2xBWE56eEXoX9AQA5Eurzcm-Z8ct-6QUEo-v8K7uYDSPX8H0JS4E',
    id: 6401,
    acronym: 'NIC&E',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'niab.org.pk',
    name: 'Nuclear Institute for Agriculture and Biology (NIAB)',
    web_page: 'http://www.niab.org.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxAoXCnI7NP1WIStnEIrwhmFIdRsB4CPISdz2K0kP8lgkkZV5j64YXcVA',
    id: 6402,
    acronym: 'NIFAB(',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'nu.edu.pk',
    name: 'FAST - National University of Computer and Emerging Sciences (NUCES)',
    web_page: 'http://www.nu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5elN0FFEU5wB_rpU7CYsRzdoe0YkoYV-KCLpKpAZnjLJ80cqiVcsiL08',
    id: 6403,
    acronym: 'F-NUCES(',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'numl.edu.pk',
    name: 'National University of Modern Languages',
    web_page: 'http://www.numl.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8GjAnexycPx-02B_JMEFypWTLnhZHcLTa_HbeNbYpqTFF3zRJIVDRq4A',
    id: 6404,
    acronym: 'NUML',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'nust.edu.pk',
    name: 'National University of Science and Technology',
    web_page: 'http://www.nust.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3xgD5FARDftr6x1QtsxtYTvheLKECRqaiajwWIfUhgW-SDq90HiuT6g',
    id: 6405,
    acronym: 'NUST',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'nwfpuet.edu.pk',
    name: 'University of Engineering and Technology Peshawar',
    web_page: 'http://www.nwfpuet.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAFq7QNLCBrer5Tb7mBcXEX3zoAjU8PB5j6BvAt77NFARafpGHlgfxKI4',
    id: 6406,
    acronym: 'UETP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pafkiet.edu.pk',
    name: 'Karachi Institute Of Economics And Technology',
    web_page: 'http://www.pafkiet.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRClhTYy0yuwu6DVDqS4WPBDxBuBdBZVyzgGmDT5qjRVHK7IsRIbHm_XQ8',
    id: 6407,
    acronym: 'KIET',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pakaims.edu',
    name: 'Pak-AIMS (American Institute of Management Sciences)',
    web_page: 'http://www.pakaims.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkeLtwjRNJvhudKfDH8oEvvKYHHKV9ju9hlPJSnu99thQoa0ZnzMRiDbM',
    id: 6408,
    acronym: 'P(IMS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pakaims.edu.pk',
    name: 'Institute of Management Sciences Lahore (IMS)',
    web_page: 'http://www.pakaims.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0or0FBT9y9A1yFy6p3jiq-lIDHQ9w3dMk1bLvrmyofkhIuGunP5ihhNw',
    id: 6409,
    acronym: 'IMSL(',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pakistanhomoeopathy.com',
    name: 'Hahnamann Honoeopathic Medical College',
    web_page: 'http://www.pakistanhomoeopathy.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxCmFWMkMYCjtWAITUblAmZxq22QZLbv626AsaRnwc7wMTjjWg5ckYOA48',
    id: 6410,
    acronym: 'HHMC',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'panasia.org.sg',
    name: 'Qauid-e-Awam University of Engineering Sciences & Technology',
    web_page: 'http://www.panasia.org.sg/tcdc/pakistan/qauid_sindh/index.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7g07Ohd1tZVvdOVR1Hr72PyoZzO6Xb_58pP6_dxYN5qJ6HtMhh_0rHQM',
    id: 6411,
    acronym: 'QUES&T',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pedu.edu.pk',
    name: 'Pyramid Education Center',
    web_page: 'http://www.pedu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSPtvvIAZTxK494Pvyq5E_jPpmOzPmjGT0n0RIApixOD_7puMdzl_SWQ',
    id: 6412,
    acronym: 'PEC',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pics.edu.pk',
    name: 'Punjab Institute of Computer science',
    web_page: 'http://www.pics.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIF18N0DTmNvSW_8U4wY-bXAtp3Fey8GVpCYeILFxF5sHDzOB8mYUB7w',
    id: 6413,
    acronym: 'PICS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pieas.edu.pk',
    name: 'Pakistan Institute of Engineering and Applied Sciences (PIEAS)',
    web_page: 'http://www.pieas.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_V0gGVLIptHYHE6Y8KYBO_ClVs8fhPs1HNWsT4dr4NQbXno7rF50xAOs',
    id: 6414,
    acronym: 'PIEAS(',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pimsat-khi.edu.pk',
    name: 'Preston Institute of Management Sciences and Technology (PIMSAT)',
    web_page: 'http://www.pimsat-khi.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYf7uISV8SixoIpszjY9_viTcVuNqhNJoIO9JI85RuarDphH8GdOLDnw',
    id: 6415,
    acronym: 'PIMST(',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'planwel.edu',
    name: 'Planwel University',
    web_page: 'http://www.planwel.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4n6ke5S-Innev1oiWK3IeHUMlH2wPlRdt8-3OxrDoFQqF3T1Hv5ZG3Qc',
    id: 6416,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'prestonpak.edu.pk',
    name: 'Preston University Pakistan Campus',
    web_page: 'http://www.prestonpak.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmvKxKqRBze0OgMbLTqQt58Y64GnJeM6SfggFDtYMfm2nbnnGRBVABJu3y',
    id: 6417,
    acronym: 'PUPC',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pucit.edu.pk',
    name: 'Punjab University College of Information Tecnology',
    web_page: 'http://www.pucit.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjpfEbY0l9WnaOtuYABaSS1JGOyvbmqWGSAH7OS4T3BGIpGjgraHUOwTI',
    id: 6418,
    acronym: 'PUCIT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pu.edu.pk',
    name: 'University of the Punjab',
    web_page: 'http://www.pu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZ-Pp_DFWbRz7UFUfpTNkRRvTlXjPx7PYiRRH2gC-wmWIby1Cg5zontg',
    id: 6419,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'pugc.edu.pk',
    name: 'University of the Punjab Gujranwala Campus',
    web_page: 'http://www.pugc.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReeCwKbKkEEHmEM-hAMz5apX33dHj3Q1VYTV92HY1jQSRMDqLUKfU9hCE',
    id: 6420,
    acronym: 'UPGC',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'qau.edu.pk',
    name: 'Quaid-i-Azam University',
    web_page: 'http://www.qau.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQofPy9KC2mMDijgAdbX0vnlHkhYsPwmIB6KHz3dbnD6tTfnkc_dZIoW1M',
    id: 6421,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'qurtuba.edu.pk',
    name: 'Qurtuba University of Science and Infromation Technology',
    web_page: 'http://www.qurtuba.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrQXRxn4d0c9TYJH-yveYXh4rCyMK0cJd4dOxgfnqC_RQZId6S0lt51vC2',
    id: 6422,
    acronym: 'QUSIT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'riphah.edu.pk',
    name: 'Riphah International Univeristy',
    web_page: 'http://www.riphah.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzWNzQepVFFSSvthHsMHUmy3IYPqD9Uv_hhDdHxDtldVtwxfMXFY1clK0',
    id: 6423,
    acronym: 'RIU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'salu.edu.pk',
    name: 'Shah Abdul Latif University Khairpur',
    web_page: 'http://www.salu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfgGD4pSZK6-GVNWYPrzGFU8JznZbqp0xqMaNRdbR6AQ9AAFt7lVhr-A',
    id: 6424,
    acronym: 'SALUK',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'sau.edu.pk',
    name: 'Sindh Agricultural University',
    web_page: 'http://www.sau.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuwgAEaQu1oIHERIgJQOgOrlJtjNMEownUjf1PEXMGQzS7ODqL3VPNxw',
    id: 6425,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ssms.edu.pk',
    name: 'Sir Syed Institute Of Technology Islamabad',
    web_page: 'http://www.ssms.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYe1Q1nWQ00ejIkbEnZez-GVbj9ATCKDQJiQTaJtR3CjWiymcN3OADtbXQ',
    id: 6426,
    acronym: 'SSITI',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ssuet.edu.pk',
    name: 'Sir Syed University of Engineering and Technology',
    web_page: 'http://www.ssuet.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFOGTFOYTDUoM6Frk2HrNv7FDHFN6J6zO4ULJ_Hfi_vCIWexPX-7OW1bQ',
    id: 6427,
    acronym: 'SSUET',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'suit.edu.pk',
    name: 'Sarhad University of Science & Information Technology Peshawar',
    web_page: 'http://www.suit.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7Qwj6GjsoCiOq8GknG-P2ztEsnuVn2Ee-64qZV3tRvesi_7m8qDB1Xw',
    id: 6428,
    acronym: 'SUS&ITP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'superior.edu.pk',
    name: 'The Superior College',
    web_page: 'http://www.superior.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoWKKLd09-cnwPmW1t_8RoV6bL3drR0Oswpv8Ytjv_7e7aKhQTc8sXOA',
    id: 6429,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'szabist.edu.pk',
    name: 'Shaheed Zulifkar Ali Bhutto Institute of Science and Technology',
    web_page: 'http://www.szabist.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSreehNGnUJlmasiMFwF8XTcJEn4lhehU0OlibnktqCfUvT1VYwR-mw167p',
    id: 6430,
    acronym: 'SZABIST',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'tip.edu.pk',
    name: 'Textile Institute of Pakistan',
    web_page: 'http://www.tip.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-i8Eg05tgi77Nz3Jo_imHIuZ43aQGfQj-wK_0KStm_7VXL3DKY7N8xEc',
    id: 6431,
    acronym: 'TIP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'tuf.edu.pk',
    name: 'University of Faisalabad',
    web_page: 'http://www.tuf.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIX4NdKEbfggm2135mtgbNgiYxyg2cOEAUjhpphWifhtZupgfQKYs-gg',
    id: 6432,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uaar.edu.pk',
    name: 'Arid Agriculture University',
    web_page: 'http://www.uaar.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgrWt38fhXLH8-ZvYjhBCqT-TQ1Cv7-fvhY-z-fhErEpEw80rJ6rP_eQ',
    id: 6433,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uaf.edu.pk',
    name: 'University of Agriculture Faisalabad',
    web_page: 'http://www.uaf.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmNAYvO7Ekm0K5AsBi3NuMWYwWSfmQNTwIYM2MTOcEMjT0Tr0fh3af4Q',
    id: 6434,
    acronym: 'UAF',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ucp.edu.pk',
    name: 'University of Central Punjab',
    web_page: 'http://www.ucp.edu.pk/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSi0uvAOL2W9Mt8yCC5wH7f-1K_wUfKaeB4A7GOIPV3F-I0mn71K-W5Rw',
    id: 6435,
    acronym: 'UCP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ue.edu.pk',
    name: 'University of Education',
    web_page: 'http://www.ue.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSblX2aE4YGLyOD5liyLYpI00W07oT65ozU2vsClij5B1fbPvUkTatiSg',
    id: 6436,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uet.edu',
    name: 'University of Engineering and Technology Lahore',
    web_page: 'http://www.uet.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdlJORpv5dc59wTYW2IUK56b-e7uPxSJBp0xtbDIb0RsyHRQIYkjyDvQU',
    id: 6437,
    acronym: 'UETL',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uettaxila.edu.pk',
    name: 'University of Engineering and Technology Taxila',
    web_page: 'http://www.uettaxila.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREtfPllBUnHFHLxgWkzjzTbTnAhKVxAZuYMJl0piPum1DahmMw_wXcrzKbbQ',
    id: 6438,
    acronym: 'UETT',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'umi.edu.pk',
    name: 'University of Munawwar-ul-Islam',
    web_page: 'http://www.umi.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhfyDehdsHX7yLp6nbShb0MAaa1ldCnJvMBlCDDKSBgTlilam4vGHtTNb6',
    id: 6439,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'unw.edu.pk',
    name: 'University of NorthWest',
    web_page: 'http://www.unw.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFNque5LN72pkMI9XYLeEOCiwbw43NfIGP_w5I6OWF5QV73vopglksKA',
    id: 6441,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uoe.edu.pk',
    name: 'University of East',
    web_page: 'http://www.uoe.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9FReQDeyYYCnG1F6zE6XXs53a_RRj_AdZKMgAprwvr6QMPoYBlgEhgw',
    id: 6442,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uog.edu.pk',
    name: 'University of Gujrat',
    web_page: 'http://www.uog.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAynx8GSgv0nL6hMGhklFQ3GaPAGccshVufX4_f9tboTyFhk1s0IugS0Tr',
    id: 6443,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uok.edu.pk',
    name: 'University of Karachi',
    web_page: 'http://www.uok.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlk7D3zejmAaWQHkGnFvsq4fU-GJfN5PWU6BD8lFBKzoRijSdSqzJdQEQ',
    id: 6444,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uol.edu.pk',
    name: 'University of Lahore',
    web_page: 'http://www.uol.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUqI95w6hghtcl5pjV5S7n0vgzl5Qm8bNKHJUm5A26mVTZhliB5XwPiQY',
    id: 6445,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uom.edu.pk',
    name: 'University of Malakand',
    web_page: 'http://www.uom.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfXIjD2GvxDxRKYyD4VxlblFye2QrW5gHZCTgcccYGi46GttgbOj9ifzEh',
    id: 6446,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uos.edu.pk',
    name: 'University of Sargodha',
    web_page: 'http://www.uos.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-pu_fStls7K2uhUXeEBWSgUqg7MyozojCVmwZGAR9SVzJCw-mfCyni-o',
    id: 6447,
    acronym: 'US',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'upesh.edu',
    name: 'University of Peshawar',
    web_page: 'http://www.upesh.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzQU6bU7VNWIdO7nuqiA6CzNfvd8gq07gLhWs8esFwQizLPDqJdLtmNNc',
    id: 6448,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'usa.edu.pk',
    name: 'University of South Asia',
    web_page: 'http://www.usa.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF66iahccWdQh9p2c_6LO7hlDe2MJ2Oy8bhOZXExc7Wvj367t4A2Fm-sE',
    id: 6449,
    acronym: 'USA',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'usindh.edu.pk',
    name: 'University of Sindh',
    web_page: 'http://www.usindh.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIN4B0R33UBLYw2EwdELVn4obFpQAk5P5gKY4S6KlKfSjKkDeP9OrzLw',
    id: 6450,
    acronym: 'US',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'ustb.edu.pk',
    name: 'University of Science & Technology Bannu',
    web_page: 'http://www.ustb.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9T3z2bdJ4sQc8h5Dilvfn58jlVKl9UtUwI6SSoWILsgOQTMI21_bzZQ',
    id: 6451,
    acronym: 'US&TB',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uvas.edu.pk',
    name: 'University of Veterinary & Animal Science',
    web_page: 'http://www.uvas.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVTWNCurRKhtMlf-DZGlxtzN9V3--45svHkf-SoMRMqGfkBzk7qhJ0JYk',
    id: 6452,
    acronym: 'UV&AS',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'uw.edu.pk',
    name: 'University of Wah',
    web_page: 'http://www.uw.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2gHROm6MxCq9sUZH9A1eZ6eRe3vmaHczV6I3R4g2FALTIPsxgSnP2_g',
    id: 6453,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'vu.edu.pk',
    name: 'Virtual University of Pakistan',
    web_page: 'http://www.vu.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrENuYwWimnOgFtjIj3-k4fT4KDdCqCBgb9q7-4iKnvAUdhvaWTb48vQ',
    id: 6454,
    acronym: 'VUP',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'wahmedicalcollege.edu.pk',
    name: 'Wah Medical College',
    web_page: 'http://www.wahmedicalcollege.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfnkQiC_l6V6wT7cBrdXeRusHw1nJNFT9PG28XqNITcFZtqyCBRvd9RO5X-w',
    id: 6455,
    acronym: 'WMC',
  },
  {
    alpha_two_code: 'PK',
    country: 'Pakistan',
    domain: 'zawiya.edu.pk',
    name: 'Zawiya Academy',
    web_page: 'http://www.zawiya.edu.pk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAxFECF0UCFy3-8xltQuKj26p5OFzhXafDYoxP8DHv_v6uaalw9y4yDLc',
    id: 6456,
    acronym: 'ZA',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'aauj.edu',
    name: 'Arab American University - Jenin',
    web_page: 'http://www.aauj.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFjPlpdA3Xzgb8VztfAF0-XgFoBWaT73KjsD8WZRRu8zwkCAyC4D-BkAE',
    id: 6457,
    acronym: 'AAU-J',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'alaqsa.edu.ps',
    name: 'Al-Aqsa University',
    web_page: 'http://www.alaqsa.edu.ps/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR8ziZZWhZVeq3dwYfml5do5lrCRRScX9jFgstklM8RxFjfeH5inomzA',
    id: 6458,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'alazhar.edu.ps',
    name: 'Al-Azhar University of Gaza',
    web_page: 'http://www.alazhar.edu.ps/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYcVywII47Cyacz90em3LgYXX0sVzk0HXEjsUCX1NGYE_r4obv8nQuhIIv',
    id: 6459,
    acronym: 'AUG',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'alquds.edu',
    name: 'Al-Quds University - The Arab University in Jerusalem',
    web_page: 'http://www.alquds.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV7JjdmPvwEFz10EqQWYnFY-LKoPTQhz9JiK4wkXCMxLBPFzJ0I3VrTtA',
    id: 6460,
    acronym: 'AU-AUIJ',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'bethlehem.edu',
    name: 'Bethlehem University',
    web_page: 'http://www.bethlehem.edu/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSkxcZ9FnrHpSgjFOZ-PxE6hTFDsbVqqBbrXTeCXEUcG8S0wJQ9Gbb81A',
    id: 6461,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'birzeit.edu',
    name: 'Birzeit University',
    web_page: 'http://www.birzeit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBrrNNiI3XSWBWUBqyHBQmjGJHwO0apq7_ge22Z8S0YH5NZGvO4M_vXg',
    id: 6462,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'hebron.edu',
    name: 'Hebron University',
    web_page: 'http://www.hebron.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgypR6WUhLn6kn67hEGnqTLZu18P1bEa-pnAHsg7nSPaRjDJdwTYhdskA',
    id: 6463,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'iugaza.edu.ps',
    name: 'Islamic University of Gaza',
    web_page: 'http://www.iugaza.edu.ps/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI5ZY7l6B5Go_Mg_h9ovT4snY6XvBBaPVs8TMJzr9mBlgmg_60TU7DNJY',
    id: 6464,
    acronym: 'IUG',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'najah.edu',
    name: 'An-Najah National University',
    web_page: 'http://www.najah.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQduiWA2OxBH6GeJToBESlaE_kchdaK0-PnYyUqDK5N6Kn1eD_Z1hiISk8',
    id: 6465,
    acronym: 'ANU',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'ppu.edu',
    name: 'Palestine Polytechnic University',
    web_page: 'http://www.ppu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWh4X7F6wLTth1E9lJjiGgqhZKw4cgW_T3EXrV3CB8hZ4hp6i1eLWyPPI',
    id: 6466,
    acronym: 'PPU',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'ptcdb.edu.ps',
    name: 'Palestine Technical College-Dier Elbalah',
    web_page: 'http://www.ptcdb.edu.ps/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNFAZNorET3vS5Gf89FrwjKI5gPvLd95a8XKZ-ZGNTVFkH9xEcRFvXQ1w',
    id: 6467,
    acronym: 'PTCE',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'ptuk.edu.ps',
    name: 'Palestine Technical University - Kadoorie',
    web_page: 'http://www.ptuk.edu.ps/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd5VUmeeQ59BfX6qNLCmJSvJ9_u1lHpvSVSWFlRofPqJVFuBZBRVI3pag',
    id: 6468,
    acronym: 'PTU-K',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'qou.edu',
    name: 'Al-Quds Open University',
    web_page: 'http://www.qou.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNVBwm2ffqXK5fLfBqVFkAGsRTTZKYlTvggJdX5cSQl4GgzzWbve153QI',
    id: 6469,
    acronym: 'AOU',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'ucas.edu.ps',
    name: 'University College of Applied Sciences',
    web_page: 'http://www.ucas.edu.ps/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUKogsQDYewbfldecEqdCy4DCfaMQzai4kCSZnIz_39S1klqn_p74CLw',
    id: 6470,
    acronym: 'UCAS',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'uou.edu.ps',
    name: 'Ummah University',
    web_page: 'http://www.uou.edu.ps/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlhQ8tVpglSXwFQoaksWDirZG8zhrHiqF9UhM9BY0gUie0_6fltzniM9Y',
    id: 6471,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'PS',
    country: 'Palestine, State of',
    domain: 'up.edu.ps',
    name: 'University of Palestine',
    web_page: 'http://www.up.edu.ps/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnhrqysATHPK4safGMKI3Aecq2_QWmVMM1fdEputq-HwuELJZqc78fsw',
    id: 6472,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'columbus.edu',
    name: 'Columbus University',
    web_page: 'http://www.columbus.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxy3tJssyFGo8u8dOy2E-XWXJMlmA4RJLuVIgx4tMswGb2lk51ioHbcw',
    id: 6473,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'floret.edu.pa',
    name: 'Floret Global University',
    web_page: 'http://www.floret.edu.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdiRZHzXgn3s8cR6l8vMKc_8imiynz9IfcXs-tOQ75cKWEE5vIlhX5Bk8',
    id: 6474,
    acronym: 'FGU',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'institutoteima.ac.pa',
    name: 'Instituto de Enseñanza Superior Oteima',
    web_page: 'http://www.institutoteima.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRx1TD-fvdEOeE5mO0FqXmrF8mK-Y6YQNrkkLHb_HDKEmZpyivuE6nqag8',
    id: 6475,
    acronym: 'IDESO',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'oteima.ac.pa',
    name: 'Universidad Tecnológica Oteima',
    web_page: 'http://www.oteima.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrIBAaeTlWZVXyxTmnfXDjuqAYhQQo0bajyWHWfYLbQOSNeiBBYAB2m70',
    id: 6476,
    acronym: 'UTO',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'ucapanama.org',
    name: 'Universidad de Cartago',
    web_page: 'http://www.ucapanama.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmtWKEQEQsF3hk6v5zzxEOSRMXMY30tjmzpC2z1LFjncurE2a-klpvCQ',
    id: 6477,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'udelas.ac.pa',
    name: 'Universidad Especializada de Las Americas',
    web_page: 'http://www.udelas.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrjHsgvtGxjSvYnfhJkXGsqv5hzQ9Brfr8WQjki4b_2uIgTVsbeOqs1KI',
    id: 6478,
    acronym: 'UEDLA',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'ulacit.ac.pa',
    name: 'Universidad Latinoamericana de Ciencia y Tecnologia Panamá',
    web_page: 'http://www.ulacit.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQscbtZwWUyymGzSmiZojCoLtt84_9Eo-JosLF2Dn0eADJW7H2To8_-DA',
    id: 6480,
    acronym: 'ULDCYTP',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'ulat.ac.pa',
    name: 'Universidad Latina de Panamá',
    web_page: 'http://www.ulat.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGriHW4-CJkO4fAxq-p9QvBAImfVEwOrOUZDE2MNInhcNqk3ZJucPiCw',
    id: 6481,
    acronym: 'ULDP',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'unachi.ac.pa',
    name: 'Universidad Autonoma de Chiriqui',
    web_page: 'http://www.unachi.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6OZj55XHFyOC1A2rx0qk1GTtozyfPFGSjsqbHFeccMx1ERv1bf5_JC90',
    id: 6482,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'unadp.ac.pa',
    name: 'Universidad Abierta y a Distancia de Panama',
    web_page: 'http://www.unadp.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzK4YZqocUhHPGxUK7NPDOdHB2ghd1WymDG-5RNedUYp1XOqlzP2NvpZ8',
    id: 6483,
    acronym: 'UAYADDP',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'uniedpa.com',
    name: 'Universidad Interamericana de Educacion a Distancia de Panama',
    web_page: 'http://www.uniedpa.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiQOvfC0QwOdtJq-pvF_uDUHa_LyrOBnjzROQd54sf0tB-88rbocU71A',
    id: 6484,
    acronym: 'UIDEADDP',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'up.ac.pa',
    name: 'Universidad de Panamá',
    web_page: 'http://www.up.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzECSC4R1pBy-AncpaaEzheb0u6GOdk_hRaR0yHjnPTDRH-VFKq0vxMg',
    id: 6485,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'usma.ac.pa',
    name: 'Universidad Católica Santa María La Antigua',
    web_page: 'http://www.usma.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR714HYmvzMHzWGNmZUvEvHPbD2giJfFb78vrZ5tAhnkWqD_5az6MKpBLI',
    id: 6486,
    acronym: 'UCSMLA',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'utp.ac.pa',
    name: 'Universidad Tecnológica de Panamá',
    web_page: 'http://www.utp.ac.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgXaETdeS6mfy_UJlODziqXaTiFhiwTkFhqLkUGR2fsy4byivpIGhatQ',
    id: 6487,
    acronym: 'UTDP',
  },
  {
    alpha_two_code: 'PA',
    country: 'Panama',
    domain: 'viu.edu.pa',
    name: 'Victoria International University',
    web_page: 'http://www.viu.edu.pa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU2smK6GjUyJOkPWP5OyMalfkl6tm7Kzd5k6mWqnlSYxeUu8rmaET439Y',
    id: 6488,
    acronym: 'VIU',
  },
  {
    alpha_two_code: 'PG',
    country: 'Papua New Guinea',
    domain: 'dwu.ac.pg',
    name: 'Divine Word University',
    web_page: 'http://www.dwu.ac.pg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrWS003wUQt3NlKsWu0R0UMwspsfIKm1gOd5oLVX_rXmE7MHgjnka0LMU',
    id: 6490,
    acronym: 'DWU',
  },
  {
    alpha_two_code: 'PG',
    country: 'Papua New Guinea',
    domain: 'pau.ac.pg',
    name: 'Pacific Adventist University',
    web_page: 'http://www.pau.ac.pg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlRe7OMtV_9uw4kwXmtRUGRuRiGve5hwf5bQGY1VZ1Sr-n5r4-Mkftd7o',
    id: 6491,
    acronym: 'PAU',
  },
  {
    alpha_two_code: 'PG',
    country: 'Papua New Guinea',
    domain: 'unitech.ac.pg',
    name: 'Papua New Guinea University of Technology',
    web_page: 'http://www.unitech.ac.pg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlVtKxtO5x3RynoFXjLJ-XlAH99G1OjnZL-GovUpiMFhvAFJY0Uidpcws',
    id: 6492,
    acronym: 'PNGUT',
  },
  {
    alpha_two_code: 'PG',
    country: 'Papua New Guinea',
    domain: 'uog.ac.pg',
    name: 'University of Goroka',
    web_page: 'http://www.uog.ac.pg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5EJdfv3YGCy2OIHuV7ed0it58Lt0xcGvXWS6k5buZdNtHFWLtdTYdrQ',
    id: 6493,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'PG',
    country: 'Papua New Guinea',
    domain: 'upng.ac.pg',
    name: 'University of Papua New Guinea',
    web_page: 'http://www.upng.ac.pg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDErVJnV6gaQr7XhBfVRCmXA2lZ8SvUKNWrLLIx6MhwkYfYB6HeW3V6RM',
    id: 6494,
    acronym: 'UPNG',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'columbia.edu.py',
    name: 'Universidad Columbia del Paraguay',
    web_page: 'http://www.columbia.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOHgj2jvKWbrySXfKHzqi4JlGC80WVMAcfeLv6ggjz3IXXrRU8uLl47i4Npg',
    id: 6495,
    acronym: 'UCDP',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'uaa.edu.py',
    name: 'Universidad Autónoma de Asunción',
    web_page: 'http://www.uaa.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzF_T_iM5OeykOHIOuVjRsqUbW9rwD-JRP9MkFcx73O-E1PDuNC0mkHQ',
    id: 6496,
    acronym: 'UADA',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'uap.edu.py',
    name: 'Universidad Autónoma del Paraguay',
    web_page: 'http://www.uap.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZa1GjMRxUxiJiq7hxGD9zGmQfEvnolBl9nwAfb7Xqphm1B6EGTwrx998LhQ',
    id: 6498,
    acronym: 'UADP',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'uc.edu.py',
    name: 'Universidad Católica Nuestra Senora de la Asunción',
    web_page: 'http://www.uc.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBo2etfvHA6R5U-BgQKFKkQ6rphSgh0TyW6Ad-AEXmAbtQxh-TCM53Iw',
    id: 6499,
    acronym: 'UCNSDLA',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'ucom.edu.py',
    name: 'Universidad Comunera',
    web_page: 'http://www.ucom.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmeWF5NNadxNNeVObPX8d60blWUs_1FhNDa9lC9NMkx-5NUaMzrx4T_nM',
    id: 6500,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'ucsa.edu.py',
    name: 'Universidad del Cono Sur de las Américas',
    web_page: 'http://www.ucsa.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUZMc5sv-EoF4DnPdBwJzSHCjRmnltf227RDcFM97rvyyDG9cF12QQ_fFK',
    id: 6501,
    acronym: 'UDCSDLA',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'uma.edu.py',
    name: 'Universidad Metropolitana de Asunción',
    web_page: 'http://www.uma.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgt0QXAAX-Ow_f8KylDRPhCWX60JTNDRk_vKlCNcwP7KVTVKkIIr5JS9Q',
    id: 6502,
    acronym: 'UMDA',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'una.py',
    name: 'Universidad Nacional de Asunción',
    web_page: 'http://www.una.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_x0zEcw7JRO5JcEgkpk3X6f4CxkrASp0qGxj1GBB4IBuIOqboz1BSJglD',
    id: 6503,
    acronym: 'UNDA',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'une.edu.py',
    name: 'Universidad Nacional del Este',
    web_page: 'http://www.une.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2sfj0LibWbmQSMNYRHxGM-KHTMKGLoQWcbgEh8VOkL0_PwgsqQoi6Sl_0',
    id: 6504,
    acronym: 'UNDE',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'unida.edu.py',
    name: 'Universidad de la Integración de las Americas (UNIDAD)',
    web_page: 'http://www.unida.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkib43ducK1cgy0OLZwsL5rVT1ZjifjvfFZ5hg0AYU89ZemDtpdHSjbg',
    id: 6505,
    acronym: 'UDLIDLA(',
  },
  {
    alpha_two_code: 'PY',
    country: 'Paraguay',
    domain: 'upe.edu.py',
    name: 'Universidad Privada del Este',
    web_page: 'http://www.upe.edu.py/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGkMJro45y0kobHZ9PDRIEZXQpserYeRoDkPODu7N7pmDyma3fQ56gqQ4',
    id: 6507,
    acronym: 'UPDE',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'champagnat.edu.pe',
    name: 'Universidad Marcelino Champagnat',
    web_page: 'http://www.champagnat.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyVVFlxJomeaZnYGKNxsrZfrssaesEd6WVCdrvHqSxzxU7oSw2xDlumBc',
    id: 6508,
    acronym: 'UMC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'lamolina.edu.pe',
    name: 'Universidad Nacional Agraria La Molina',
    web_page: 'http://www.lamolina.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNAKLAOoJo24CInqw-oV9-Gpo7MXDIQzcz9GUXfRM4JCpVrhcqhRM5gYI',
    id: 6509,
    acronym: 'UNALM',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'pucp.edu.pe',
    name: 'Pontificia Universidad Católica del Perú',
    web_page: 'http://www.pucp.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLy9iz1Mfq6wqEHc8_VQItMLh5Mf1UhROHHRgi0ok_K0N1o5rVLwYIiik',
    id: 6510,
    acronym: 'PUCDP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'sil.edu.pe',
    name: 'Universidad San Ignacio de Loyola',
    web_page: 'http://www.sil.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtx1YF00wAg_IXiriPhipRMfuQtfcDoNmSzZfbiUnsdt3ZHNZijm0WXQ',
    id: 6511,
    acronym: 'USIDL',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uancv.edu.pe',
    name: 'Universidad Andina Nestor Caceares Velasquez',
    web_page: 'http://www.uancv.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTko5dHpwO30LpSEEQpbV9EaYj87WN_mb3aZj6UaUuhWIxPmrTZ9q3BLg',
    id: 6512,
    acronym: 'UANCV',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uandina.edu.pe',
    name: 'Universidad Andina del Cusco',
    web_page: 'http://www.uandina.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRb9bFEZ9tvsJe0MkGTrg1jNOy4MDOs9CJV7s5T3I8N6CIIzR9SMOKWXoc',
    id: 6513,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uap.edu.pe',
    name: 'Universidad Alas Peruanas',
    web_page: 'http://www.uap.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSx3lZL86MSIBjGHuXDJYxh4RDPOkc-_pF-UlfUxK7F_akwN45tqrO1Gg',
    id: 6514,
    acronym: 'UAP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uch.edu.pe',
    name: 'Universidad de Ciencias y Humanidades',
    web_page: 'http://www.uch.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQonjYBmm3qV4_OasBHHDuIjZH4HhI9e1jbNcFLv-fmO47YF2zfhSgYizU',
    id: 6515,
    acronym: 'UDCYH',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'ucsm.edu.pe',
    name: 'Universidad Católica de Santa María',
    web_page: 'http://www.ucsm.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwPR30ThItUzGh2wMUrDswldjtAHQ7jVXXQbmcyJASVnzUB8hA2ffu4IHl',
    id: 6516,
    acronym: 'UCDSM',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'ucsur.edu.pe',
    name: 'Universidad Cientifica del Sur',
    web_page: 'http://www.ucsur.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS42IEmExkauq7nhXqb0a-S1BrrSM9P4tPhCf3Wq7vV8K797pjBkM6JQw',
    id: 6517,
    acronym: 'UCDS',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'ucv.edu.pe',
    name: 'Universidad César Vallejo',
    web_page: 'http://www.ucv.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVKJ5mFwHs7P26pPqSUgNyYd8Svd-a3dkAZR6RewqT-Z7XlhTrF3tCRxsB',
    id: 6518,
    acronym: 'UCV',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'udch.edu.pe',
    name: 'Universidad Particular de Chiclayo',
    web_page: 'http://www.udch.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuOlnEaosaVo74h6rCEG15Z4MQ3v9pAx3mxn7VOGk08dy95R1OUZhoHoozkg',
    id: 6519,
    acronym: 'UPDC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'udep.edu.pe',
    name: 'Universidad de Piura',
    web_page: 'http://www.udep.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSh0TyD_SS8PsRcpE4C_XLRwQS5V2ZNSSprjXEaqGBg3vdQH4OdWPLhEA',
    id: 6520,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'udh.edu.pe',
    name: 'Universidad de Huánuco',
    web_page: 'http://www.udh.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1imys0JqbEWZc_E-8YRUPM31RkOW-7pek9kVeycMmCon8cflvhCwr-3I',
    id: 6521,
    acronym: 'UDH',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'udl.edu.pe',
    name: 'Universidad de Lambayeque',
    web_page: 'http://www.udl.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN3EkeEAlqAqR3YhTL8uw1BuA4tRuEU2oUQe0m2OD_f80eYZhAtRUwnPE',
    id: 6522,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uigv.edu.pe',
    name: 'Universidad Particular Inca Garcilaso de la Vega',
    web_page: 'http://www.uigv.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8KhxnNBXv6_fGCyQ03jaFbBqgDZ4Ic9E0VER_SuR6TxNO10l9HLzUIAo',
    id: 6523,
    acronym: 'UPIGDLV',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uladech.edu.pe',
    name: 'Universidad Los Angeles de Chimbote',
    web_page: 'http://www.uladech.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRA_RQ_CaQl2ZC42hTpWdo77hMVDuAUYf6r0nV-bEkclxBdQKpcWmy1Tp4',
    id: 6524,
    acronym: 'ULADC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'ulima.edu.pe',
    name: 'Universidad de Lima',
    web_page: 'http://www.ulima.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4XN96ttuWH14G-iQKXz86gFTh4-3UjYxpqV_K5flwYeoX_uaJNhJhCw',
    id: 6525,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'umb.edu.pe',
    name: 'Universidad Privada Juan Mejía Baca',
    web_page: 'http://www.umb.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCMx0RRY_4FeoL4otlKKVamaLp0ch6MrKIXh_SKsUsgJ4uLVk-cRE92hk',
    id: 6526,
    acronym: 'UPJMB',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unac.edu.pe',
    name: 'Universidad Nacional del Callao',
    web_page: 'http://www.unac.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgk-YNgTVFTNfKz9QqXaJkSNTbm33ujOeYRTYJxxxyMwAu6CaDLe4vI1ra4w',
    id: 6527,
    acronym: 'UNDC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unap.edu.pe',
    name: 'Universidad Nacional del Altiplano',
    web_page: 'http://www.unap.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSomRSVxgWexLYHjx_PEGVROJyBO15nZz84XXL1Wyh7PbnA-XHmTfE7atM',
    id: 6528,
    acronym: 'UNDA',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unapiquitos.edu.pe',
    name: 'Universidad Nacional de la Amazonía Peruana',
    web_page: 'http://www.unapiquitos.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6PM_CBNNQuF3TvCE62PMOFCmTQinJOwHYjWKQb0disghx35a8RGY_Vw',
    id: 6529,
    acronym: 'UNDLAP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unasam.edu.pe',
    name: 'Universidad Nacional Santiago Antúnez de Mayolo',
    web_page: 'http://www.unasam.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvnqm8Wyz9ZiDRiH1ZXD_UzXXwnF-NnNjDVGbLhBJYpAznKsKdQXYaYMw',
    id: 6530,
    acronym: 'UNSADM',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unas.edu.pe',
    name: 'Universidad Nacional Agraria de la Selva',
    web_page: 'http://www.unas.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4DVEpzXHCb3zsyaHB2AjXd69V1kI9-gXM9su3hR1TnXlVBJZX34igyUM',
    id: 6531,
    acronym: 'UNADLS',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unc.edu.pe',
    name: 'Universidad Nacional de Cajamarca',
    web_page: 'http://www.unc.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrVEf-B28tF4HZfMSDjxdiVmOmuAd1ShCCZiyJZMKamD5WL1Wyinq32A',
    id: 6532,
    acronym: 'UNDC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uncp.edu.pe',
    name: 'Universidad Nacional del Centro del Perú',
    web_page: 'http://www.uncp.edu.pe/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTih6FnQf7icKl2NPbMyBccHY9cTc9jn2uLS__CMbDGnurkkzhLA3-QHyde',
    id: 6533,
    acronym: 'UNDCDP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'undac.edu.pe',
    name: 'Universidad Nacional Daniel Alcides Carrion',
    web_page: 'http://www.undac.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJaYyOOVo1bGOC3j8lhu9feU0FEz99CQuP213Lfp2HXa6nbQiylySMpo',
    id: 6534,
    acronym: 'UNDAC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'une.edu.pe',
    name: 'Universidad Nacional de Educación Enrique Guzmán y Valle',
    web_page: 'http://www.une.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRO3-It0LsCwHCCuJYj-Pm5jAcjsJXSP5OnEPza2vcjg7DdACAn2lT9SgwJ',
    id: 6535,
    acronym: 'UNDEEGYV',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unfv.edu.pe',
    name: 'Universidad Nacional Federico Villarreal',
    web_page: 'http://www.unfv.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP806Odz1Fc9TxpWCRQs4NrmsL0du4ym3u5ZLYZrV3kkGPdm4mNo2wng',
    id: 6536,
    acronym: 'UNFV',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unheval.edu.pe',
    name: 'Universidad Nacional Hermilio Valdizan',
    web_page: 'http://www.unheval.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0l-fPuedYJ-JmwuX9r1WsaBDCMOV_WupKET040k79lYqmTKhXPxS28aA',
    id: 6537,
    acronym: 'UNHV',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unica.edu.pe',
    name: 'Universidad Nacional San Luis Gonzaga',
    web_page: 'http://www.unica.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQALWehWNze3IK-9oVduSaAVJydG3yLXWjtHBOWuk6AgRzaQjOCsR59Kw',
    id: 6538,
    acronym: 'UNSLG',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uni.edu.pe',
    name: 'Universidad Nacional de Ingeniería',
    web_page: 'http://www.uni.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTisWVJJbpvepP1VO5EiK8EsBiCuyETtrgcTYMZyrcPFw-MflPLxmnF4lg',
    id: 6539,
    acronym: 'UNDI',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unife.edu.pe',
    name: 'Universidad Femenina del Sagrado Corazón',
    web_page: 'http://www.unife.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ8liuN9u3zyKzs_X_DA-Mvo2ocIfrWHnhp5HWfJSNEhht-7yoxELxwQ',
    id: 6540,
    acronym: 'UFDSC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unitru.edu.pe',
    name: 'Universidad Nacional de La Libertad Trujillo',
    web_page: 'http://www.unitru.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSybVAmpR9KQHC6QBj-nIycNPPLySIOkVIezgdSu4GaU0iVGDS5UPLCwVE',
    id: 6541,
    acronym: 'UNDLLT',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unjbg.edu.pe',
    name: 'Universidad Nacional Jorge Basadre Grohmann',
    web_page: 'http://www.unjbg.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeLVEkoIj8bhyu7AcLF2qBczuTUt5cO445AUO_xBW-3xScvQ0d-Ov94A',
    id: 6542,
    acronym: 'UNJBG',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unjfsc.edu.pe',
    name: 'Universidad Nacional José Faustino Sánchez Carrión',
    web_page: 'http://www.unjfsc.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdvPzveqwZldYT-Gaqsr6F-YitSkYKJT9-O-rRogO3g0lWW3sCW_zHT-M',
    id: 6543,
    acronym: 'UNJFSC',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unmsm.edu.pe',
    name: 'Universidad Nacional Mayor de San Marcos',
    web_page: 'http://www.unmsm.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1G7oiSqEQ3XerWx1NuQ6vN2sIgZ6mxMHp4thhEm5lJgMBt4huH1Vn9EAi',
    id: 6544,
    acronym: 'UNMDSM',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unp.edu.pe',
    name: 'Universidad Nacional de Piura',
    web_page: 'http://www.unp.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaD05lvMARCZtPiKmQog65vguFj4U9Lz15VkpIg-Cs6yWbQmYSjJiNoLg',
    id: 6545,
    acronym: 'UNDP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unprg.edu.pe',
    name: 'Universidad Nacional Pedro Ruíz Gallo',
    web_page: 'http://www.unprg.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFBW9E_DBK6BEEh3pF9G6l1gQthOElvXoNw6-M83BS3Owd39bMKqBVbw',
    id: 6546,
    acronym: 'UNPRG',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unsaac.edu.pe',
    name: 'Universidad Nacional de San Antonio Abad',
    web_page: 'http://www.unsaac.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCjwiGah2qi9Mgxyc06f-6N0id5ElowU9BszRa6JBiXOdsP8M9YMzpUg',
    id: 6547,
    acronym: 'UNDSAA',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unsa.edu.pe',
    name: 'Universidad Nacional de San Agustin',
    web_page: 'http://www.unsa.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9UqDR8imJNaQ43oBRFNekun27gKfWC9LsHt3lxAlhtpxU4vQIh-QN0x0',
    id: 6548,
    acronym: 'UNDSA',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unsch.edu.pe',
    name: 'Universidad Nacional de San Cristóbal de Huamanga',
    web_page: 'http://www.unsch.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMWPnlwd-CsXC1DDmbKFxgsbEik8DtjrqoAlYGOtBqHBkBediCIMy8wjg',
    id: 6549,
    acronym: 'UNDSCDH',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uns.edu.pe',
    name: 'Universidad Nacional de Santa - Chimbote',
    web_page: 'http://www.uns.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6KFuo021jQoaDLAYrXzKhWQPXH-v8xlMkA231jgimYfzaU8CaT7rIQdg',
    id: 6550,
    acronym: 'UNDS-C',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unsm.edu.pe',
    name: 'Universidad Nacional de San Martín',
    web_page: 'http://www.unsm.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCq9n1ajVZv0TTeElRJEZGJ3FHllP__X73X-h0OKvdW56cxsFVj6GnUQ',
    id: 6551,
    acronym: 'UNDSM',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'untumbes.edu.pe',
    name: 'Universidad Nacional de Tumbes',
    web_page: 'http://www.untumbes.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9cYqYk7isR4X0bcjE19mejgqqSebfmPaZxPU3QBR03Ei0s1lKlure3Q',
    id: 6552,
    acronym: 'UNDT',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'unu.edu.pe',
    name: 'Universidad Nacional de Ucayali',
    web_page: 'http://www.unu.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-M39T9fArERddcxD3CiucSG8-UpWEKl4AJWHctaxLKgLCmZtzaCSUiQ',
    id: 6553,
    acronym: 'UNDU',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upao.edu.pe',
    name: 'Universidad Privada Antenor Orrego',
    web_page: 'http://www.upao.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1VSI4Iw8CUrjCBSeXyPzW1hZiMsgcJrpJIXDXTfavzc4LZXXdakVyVKI',
    id: 6554,
    acronym: 'UPAO',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upc.edu.pe',
    name: 'Universidad Peruana de Ciencias Aplicadas',
    web_page: 'http://www.upc.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9ylGbVFEhpcd8xrwLtMTS8VtKGEJnjrSF4Nuk6kFzvKGi-_1uy3gjbw',
    id: 6555,
    acronym: 'UPDCA',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upch.edu.pe',
    name: 'Universidad Peruana Cayetano Heredia',
    web_page: 'http://www.upch.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyMfL_tskqsxTtHDr2Mz8UGhhak2ziuFRG7Fros5quABV0tg_HsmFldHE',
    id: 6556,
    acronym: 'UPCH',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upci.edu.pe',
    name: 'Universidad Peruana de Ciencias e Informática',
    web_page: 'http://www.upci.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0j-s5z0niTJNBEOG9Nvn1K-xqj09sf1PKuZkXmJ82dtFs8q23dKt6Dxs',
    id: 6557,
    acronym: 'UPDCEI',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upeu.edu.pe',
    name: 'Universidad Peruana Union',
    web_page: 'http://www.upeu.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVSaI-BMRZ172CUVckdW3Ux321BUEZZx45AICbYq49tbhh4_oDf4iUnaY',
    id: 6559,
    acronym: 'UPU',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upla.edu.pe',
    name: 'Universidad Privada Los Andes',
    web_page: 'http://www.upla.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaPf9sv9NDvl0rZ8m4TEulMn1faoHLgWIEDNJBDhhoOyrDk-G5djbXBhp6Vw',
    id: 6560,
    acronym: 'UPLA',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upnorte.edu.pe',
    name: 'Universidad Privada del Norte',
    web_page: 'http://www.upnorte.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIZRLS-dbX7brwrpVwte5LZPHMIwdNmb8DZfasxGWAAHNsxHVAibO_wQ',
    id: 6561,
    acronym: 'UPDN',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upsb.edu.pe',
    name: 'Universidad Privada Sergio Bernales',
    web_page: 'http://www.upsb.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8-64zLpViTsHEDJcC1-x-LMmScUiGLvxmjL6Jbmo2JZ6SdHQtYCymgQ',
    id: 6562,
    acronym: 'UPSB',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upsjb.edu.pe',
    name: 'Universidad Privada San Juan Bautista',
    web_page: 'http://www.upsjb.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN10ICGyNpcepNfhSV7T1Gp8zvS4ZCHpLHOIjw7b8g7UMMpi_QHnXj9nY',
    id: 6563,
    acronym: 'UPSJB',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'upt.edu.pe',
    name: 'Universidad Privada de Tacna',
    web_page: 'http://www.upt.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt1e_rah2OwXTs505EkwGHx5dS6Wi87ka1Yr1_58K0acZfhGWVv3R18IQ',
    id: 6564,
    acronym: 'UPDT',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'urp.edu.pe',
    name: 'Universidad Ricardo Palma',
    web_page: 'http://www.urp.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrAQ72FCgwJ6G3gir6k5jkaBWsu4lxZ12Wo-V18u3MHDxrM6CAaEsmZA',
    id: 6565,
    acronym: 'URP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'usanpedro.edu.pe',
    name: 'Universidad Privada San Pedro',
    web_page: 'http://www.usanpedro.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLc6Db_ePgkI2Sln1IaFGwxSsY6DoW4wcY1LX1r1uFgE7t56boNSG141A',
    id: 6566,
    acronym: 'UPSP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'usat.edu.pe',
    name: 'Universidad Católica Santo Toribio de Mogrovejo',
    web_page: 'http://www.usat.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqQLPqKniQnCBnqCdihtGVtZrbZS7oVUS5-epGRc1NLrnLhRcJ4Au3_NJL6g',
    id: 6567,
    acronym: 'UCSTDM',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'usmp.edu.pe',
    name: 'Universidad San Martin de Porres',
    web_page: 'http://www.usmp.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE0j73b22hkof_T6SAOQS-Ls5DxV0MJgPMEw1gCIP3pR6soyyetH07SAg',
    id: 6568,
    acronym: 'USMDP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'usp.edu.pe',
    name: 'Universidad San Pablo',
    web_page: 'http://www.usp.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGk_fvziYNjTujn7r_VgNfHHlh7u-r7KP3wXmr9ik1dY6rzCXS9KQ1WQ',
    id: 6569,
    acronym: 'USP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uss.edu.pe',
    name: 'Universidad Señor de Sipán',
    web_page: 'http://www.uss.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKVK8YBoy1tj53TSQ1dhyfmHKyWYFYPp4mCnDGaTufPAE-IAJ5zb20MQ',
    id: 6570,
    acronym: 'USDS',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'utea.edu.pe',
    name: 'Universidad Tecnológica de los Andes de Apurímac',
    web_page: 'http://www.utea.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGODlvQWgzEzEo1cxffiVOPtOw-MR001Tjew8hePwS0ou3PEY6xnyRCck',
    id: 6571,
    acronym: 'UTDLADA',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'utp.edu.pe',
    name: 'Universidad Tecnológica del Peru',
    web_page: 'http://www.utp.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY5J1KMbeS177dQDXG6-n0BNkThIMoYs4KbKHyroe_WoHIsGXR-cURog',
    id: 6572,
    acronym: 'UTDP',
  },
  {
    alpha_two_code: 'PE',
    country: 'Peru',
    domain: 'uwiener.edu.pe',
    name: 'Universidad Norbert Wiener',
    web_page: 'http://www.uwiener.edu.pe/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQvOlfnT9d7bBMZjFsywf4Nqsi4fwJustC1_7_JxI290B6YcCLGNdJOw',
    id: 6573,
    acronym: 'UNW',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'adamson.edu.ph',
    name: 'Adamson University',
    web_page: 'http://www.adamson.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQl5tMz4fbtYUKdyIg_OHBBjQSVuKGAMRgnmXFjt2Fyt_E65q8xb7d9Q',
    id: 6574,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'addu.edu.ph',
    name: 'Ateneo de Davao University',
    web_page: 'http://www.addu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFsrF47qHn4MtQWT50SY_EInM9eiDl2fxixpXTv-5IFKyIdiKNKfCIIM4',
    id: 6575,
    acronym: 'ADDU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'admu.edu.ph',
    name: 'Ateneo de Manila University',
    web_page: 'http://www.admu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVT6jxGhtaXmtLo5CZwOCSxmqrJg89ZvtEN4u8v7WBgxGB3TySE9NZBw',
    id: 6576,
    acronym: 'ADMU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'adnu.edu.ph',
    name: 'Ateneo de Naga University',
    web_page: 'http://www.adnu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm-3Q5XZJpkKcxgTu-cD-p_pC0AhsrWbZPVdZOX3kw65zvhE31Lx8-9DGSdw',
    id: 6577,
    acronym: 'ADNU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'adzu.edu.ph',
    name: 'Ateneo de Zamboanga University',
    web_page: 'http://www.adzu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRj1s_C2bSZNkhUcvCc6sjeT7hkblvR439AoeEBcE9oBjAh6Ii2MLUw4A',
    id: 6578,
    acronym: 'ADZU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'aquinas-university.edu',
    name: 'Aquinas University',
    web_page: 'http://www.aquinas-university.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8XC9kJppw4JZgQfycuRLhch3PxoRhIoMTP6fv_BNBitx7U65QeITuDCs',
    id: 6579,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'araneta.dlsu.edu.ph',
    name: 'De La Salle University Araneta',
    web_page: 'http://www.araneta.dlsu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlKRYHVCfPZeBUaxj5B4eCSJWmX-31w7mw5bW_GeZ0nfrvootJIKvu0g',
    id: 6580,
    acronym: 'DLSUA',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'arellano.edu.ph',
    name: 'Arellano University',
    web_page: 'http://www.arellano.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnrl7oxgaNndyECf3PDIU8vUlNj6cOPf0UfKWMhUuk3q7JBIgpqYKutw',
    id: 6581,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'auf.edu.ph',
    name: 'Angeles University',
    web_page: 'http://www.auf.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW-IN1Pfu5gG9pFeRBkhQAWLdqJMEU6PkPDMP53KWTs8XNTIHlrOd7hQ',
    id: 6582,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'aup.edu.ph',
    name: 'Adventist University of the Philippines',
    web_page: 'http://www.aup.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSynsZUUY5mBAhEYXKFFC_vUy6xnvpLdyfr0HUA_yA3K5qNM228uZX-xw',
    id: 6583,
    acronym: 'AUP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'aupen.edu.ph',
    name: 'Araullo University',
    web_page: 'http://www.aupen.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8L-Wa_RV5BweggD1UMX5LJkGj9K0Tp2FGW-1UPCiFS9N0o4EatFRUGQ',
    id: 6584,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'bicol-u.edu.ph',
    name: 'Bicol University',
    web_page: 'http://www.bicol-u.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR11k17HWllYK3W6JQgLryTqKBjLpgcJNMkUvGub-u7gGCIUjHdMxC4iA',
    id: 6585,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'bsu.edu.ph',
    name: 'Benguet State University',
    web_page: 'http://www.bsu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9gZGCKiEpFlG51aVKm-5wZkOxePJ-xndBp_kh-f0BwuSo7qB638mrkWA',
    id: 6586,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'bulsu.edu.ph',
    name: 'Bulacan State University',
    web_page: 'http://www.bulsu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj7zVYwyoM3Y-ReCxAF0l3rh3QObTKlnrk4C7yQP5XQ9OkOc__SAofIsbtEg',
    id: 6587,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ceu.edu.ph',
    name: 'Centro Escolar University',
    web_page: 'http://www.ceu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLarcewIlmFWH6gMRh_DPugEZZW6eYuRLFrk4pooCLSHP5SwJLVHZYiQ',
    id: 6588,
    acronym: 'CEU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'cmu.edu.ph',
    name: 'Central Mindanao University',
    web_page: 'http://www.cmu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrLmb_G-Xrw62I-mjeKBA8hOhi1UVRePtKsdAPsE5PJQxjrjcL3QC0zOw',
    id: 6589,
    acronym: 'CMU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'cpuic.edu',
    name: 'Central Philippine University',
    web_page: 'http://www.cpuic.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGJZu7E6CLUu0xKsqcl4Fcoz3bg3rZ6MYvJYVpyXxdS5q1fv5fxJFan_Q',
    id: 6590,
    acronym: 'CPU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'csu.edu.ph',
    name: 'Cagayan State University',
    web_page: 'http://www.csu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdLYI0I4cLVYJ5yVERfUsbF_M1ymqxzdi3pl7srEnMtl7Byf51FKbHDAE',
    id: 6591,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'cu-cdo.edu.ph',
    name: 'Capitol University',
    web_page: 'http://www.cu-cdo.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSblX2aE4YGLyOD5liyLYpI00W07oT65ozU2vsClij5B1fbPvUkTatiSg',
    id: 6592,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'davaodoctors.edu.ph',
    name: 'Davao Doctors College',
    web_page: 'http://www.davaodoctors.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLp3Rvj2V68rAH_WE1VtX_lV6Rxz2OPeVls4l0nf8LM4Z8dOA2QWzFSCk',
    id: 6593,
    acronym: 'DDC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'dlsu.edu.ph',
    name: 'De La Salle University',
    web_page: 'http://www.dlsu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLQv5VIaLIpIxXvgZKwE1C4iDMpKds4KtlXBUht7ziLw8G0jrUbjZHSfg',
    id: 6594,
    acronym: 'DLSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'dmmmsu.edu.ph',
    name: 'Don Mariano Marcos Memorial State University',
    web_page: 'http://www.dmmmsu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCvhleGW0HQE8f9BVy2qSlPd0fEts33PE4BXKFMlXsQG55ueNTrFhF5g',
    id: 6595,
    acronym: 'DMMMSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'dwc-legazpi.edu',
    name: 'Divine Word College of Legazpi',
    web_page: 'http://www.dwc-legazpi.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzvG6DVhMpjUB1EEX7I2IjgYPmf5kLqg5gRqtsqWnGzayTybTmfrUofVo',
    id: 6596,
    acronym: 'DWCL',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'fatima.edu.ph',
    name: 'Our Lady of Fatima University',
    web_page: 'http://www.fatima.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiLWJXmrAWiESXRkuQEoc_gO93CXIeTuJclN0A2CtfnmmoHibFUOKJDgc',
    id: 6597,
    acronym: 'OLFU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'featiu.edu.ph',
    name: 'Feati University',
    web_page: 'http://www.featiu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzwY3K4QInaHcvGv9wHasbns7erGYomEXHGKM2mJbLiGS27ku8dEwU0-P0fg',
    id: 6598,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'feu.edu.ph',
    name: 'Far Eastern University',
    web_page: 'http://www.feu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMQznfw9yGVoiXU-GSXMJjg2KCpAgtlQp_FUM2Dw9EJkXW_VKmWfA6cw',
    id: 6599,
    acronym: 'FEU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'hau.edu.ph',
    name: 'Holy Angel University',
    web_page: 'http://www.hau.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA8kLs-xohJ7g81q9sz6FW3QviCJJe-yWShmOOG4OTh6RcR-0LWIqZHCA',
    id: 6601,
    acronym: 'HAU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'holyspirit.edu.ph',
    name: 'College of the Holy Spirit',
    web_page: 'http://www.holyspirit.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhbAd84yVprP2aNRc-aP3sjJX_bzSGGcUBrPCr1WQUNKs_zrnryBmudQ',
    id: 6602,
    acronym: 'CHS',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'iacademy.ph',
    name: 'Information and Communications Technology Academy',
    web_page: 'http://www.iacademy.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpuuJRCrYRvn_FB9cQG4vNIEPMbTnzpumMms7murS5_87H2y-Fr3cp7Os',
    id: 6603,
    acronym: 'ICTA',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'isu.edu.ph',
    name: 'Isabela State University',
    web_page: 'http://www.isu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhq0G94RzwGDFHGH0r5c7PGrvpYsUSMzCRFhE6KUWYafqJY8j8cOTPAg',
    id: 6604,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'iubs.co.uk',
    name: 'Irish University Business School Cebu',
    web_page: 'http://www.iubs.co.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR26HChtd1c7WauWGI8Zj3366vd-jQ-zUOxUa7facKHcT_inRkVBdLcD_kV',
    id: 6605,
    acronym: 'IUBSC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ldcu.edu.ph',
    name: 'Liceo de Cagayan University',
    web_page: 'http://www.ldcu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvErZRuhFdSg7iVuszYSLU-pLXXuqNQ3BWB3cYkxE4pnFRwb2Da5p2MQ',
    id: 6606,
    acronym: 'LDCU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'letran.edu',
    name: 'Colegio de San Juan de Letran',
    web_page: 'http://www.letran.edu/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTuBX1X2ApbGEVnOxbOCABfGcjJoQoZ5lGmAU2m1WldkfojZfe70CxSlqM',
    id: 6607,
    acronym: 'CDSJDL',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'lnu.evis.net.ph',
    name: 'Leyte Normal University',
    web_page: 'http://lnu.evis.net.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4NXumy3NBdd5KbxK0uAM1nJwmJ_5bMvZyQQeNsoJypTRjtE9xUkpRnRk',
    id: 6608,
    acronym: 'LNU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'lpu.edu.ph',
    name: 'Lyceum of the Philippines University',
    web_page: 'http://www.lpu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXNY-YJNiplf0vZjNTmGJTjNZ-7w35Q5ExWfSGxG3t1Ge9Ow5sfvj8rw',
    id: 6609,
    acronym: 'LPU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'lsu-visca.edu.ph',
    name: 'Leyte State University',
    web_page: 'http://www.lsu-visca.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUiPoz42HNNAvhNE4T6cd4m6okGMJ5L3w6BZBQtbvjcKcvnLYb3w-rXw',
    id: 6610,
    acronym: 'LSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'mcu.edu.ph',
    name: 'Manila Central University',
    web_page: 'http://www.mcu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQreEb2wPeH9lIkqre9RNuTdIqbi2OiGjNBIMgES6gurEJxjlPxULL-Evk',
    id: 6611,
    acronym: 'MCU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'mlqu.edu.ph',
    name: 'Manuel L. Quezon University',
    web_page: 'http://www.mlqu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSBRQ3Z8hxR4xmcocaF8kM4tH5yk37yd7uc6rxN-PfqwkbMhsCV8S3LOc',
    id: 6612,
    acronym: 'MLQU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'mmsu.edu.ph',
    name: 'Mariano Marcos State University',
    web_page: 'http://www.mmsu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbAzXFsRu6rJyj8aRER7zasPpPZ5v841qA0IMIOzgUlhoLlRZa5GL8PLQ',
    id: 6613,
    acronym: 'MMSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'mseuf.edu.ph',
    name: 'Manuel S. Enverga University Foundation',
    web_page: 'http://www.mseuf.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQvBYB7XcJM1E8mDuYtQLDGq4r_KVUvflxo-EpUfSReLeb4KNeSkFbjAU',
    id: 6614,
    acronym: 'MSEUF',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'msuiit.edu.ph',
    name: 'Mindanao State University - Iligan Institute of Technology',
    web_page: 'http://www.msuiit.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM3RCyzsUR8_FI80wVYEP3YM5X-8-IHk7XuEvD9POEYdxv2NVGv4lT6Rs',
    id: 6615,
    acronym: 'MSU-IIT',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'msumain.edu.ph',
    name: 'Mindanao State University',
    web_page: 'http://www.msumain.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKQ8mBbCRSDhrsA7e9Rl6n7XWCpGYHMF4c9aIhN2QdGVWvRjkGCO_7DQ',
    id: 6616,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'mu.fapenet.org',
    name: 'Misamis University',
    web_page: 'http://www.mu.fapenet.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMauQL46k_0em23ZxXii0daO1TBH5oHEg7wVau_eP0_mbxC2hJIFa_YQ',
    id: 6617,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'mvc.edu.ph',
    name: 'Mountain View College',
    web_page: 'http://www.mvc.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIqcNReNRPbicCXLC26dXrBWDL3qydeRyZGpttYzSoVXSbhpeXqV7Nyg',
    id: 6618,
    acronym: 'MVC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ndmu.edu.ph',
    name: 'Notre Dame of Marbel University',
    web_page: 'http://www.ndmu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCPz4aBalhRCW7b11o6XlMOQ33R75gWsOFZMlLUs-VcyIUXq4j9-5I4vY',
    id: 6619,
    acronym: 'NDMU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'neu.edu.ph',
    name: 'New Era University',
    web_page: 'http://www.neu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRv0XdUUdt_N92plSCU9cHAOdrpHe_iRRHTODRZfy0Slt7_m72hGaeTei8',
    id: 6621,
    acronym: 'NEU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'nulaoag.com',
    name: 'Northwestern University of the Philippines',
    web_page: 'http://www.nulaoag.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBAMmothH2fWyq0l2Sxpa5BHzZg3Hq9qGvJNx2WUohgIbmP9rwIrFCCTBB',
    id: 6623,
    acronym: 'NUP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'pcu.edu.ph',
    name: 'Philippine Christian University',
    web_page: 'http://www.pcu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEWXf6-FTSPMHH-DAIeyYAlAxVV7RzMTLX7bPgTawnkM3n4KeoyEB1ty4',
    id: 6624,
    acronym: 'PCU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'perpetualdalta.edu.ph',
    name: 'University of Perpetual Help System Dalta',
    web_page: 'http://www.perpetualdalta.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTv85Fn5Qgx39gsvDXaQMZQfzmy-e-Qi_PwDcWUv5jyV3lUO-opGB6vw',
    id: 6625,
    acronym: 'UPHSD',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'plm.edu.ph',
    name: 'University of the City of Manila',
    web_page: 'http://www.plm.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTqV8Efyf8CW1_HsxbRnkEsY6ZwEcZaaRmDfBkBM5PJzf3q-iOIal4-g',
    id: 6626,
    acronym: 'UCM',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'pma.ph',
    name: 'Philippine Military Academy',
    web_page: 'http://www.pma.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLN_0-HfIsJXLrxxZ30RJesjJnDo2ARlKBZ89VvOled8mu4J7oCyo6c1c',
    id: 6627,
    acronym: 'PMA',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'pnumanila.com.ph',
    name: 'Philippine Normal University',
    web_page: 'http://www.pnumanila.com.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwGGmMwkNIFrUaYOl4nltXjCh_gFj-meW51pS4sO9ypJn6VuOCZYqDoA',
    id: 6628,
    acronym: 'PNU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'psu.edu.ph',
    name: 'Pangasinan State University',
    web_page: 'http://www.psu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXKyQ2xnBYSFHo14T4mz6LUQMRpNaiH-LkaPnZm7SelA9a2BN9KOTjlEgq2A',
    id: 6629,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'psu-online.edu.ph',
    name: 'Palawan State University',
    web_page: 'http://www.psu-online.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ42XLrPRIXm3H3v2UpNK4vMaEN97JNRtY6GVY4IM5eKRY5ctS27o3Gjnw',
    id: 6630,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'pup.edu.ph',
    name: 'Polytechnic University of the Philippines',
    web_page: 'http://www.pup.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRcHKryFoer2tIKqXdpjhL93Q88grMwagUF1yAF45bG0MqrJl_NQSnqg',
    id: 6631,
    acronym: 'PUP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'pwu.edu',
    name: "Philippine Women's University",
    web_page: 'http://www.pwu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2Q0UMXbxWdp5VgHUOWEa6_uP4OWXEP04w9W-rbWY-2VWm8ajIn7TVauo',
    id: 6632,
    acronym: 'PWU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'rtu.edu.ph',
    name: 'Rizal Technological University',
    web_page: 'http://www.rtu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxfT9Gx1ZdGa6nHsJ4tiXIW5voN2t3z31mOFFanWvm43PlBWug00u4KP4',
    id: 6633,
    acronym: 'RTU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'sanbeda.edu.ph',
    name: 'San Beda College',
    web_page: 'http://www.sanbeda.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfGLEf_3JMgjdvDZZAfblvKBf7lhW3q4NyCseFby3UvlTbb-3H2ZCHhQ',
    id: 6634,
    acronym: 'SBC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'slc-sflu.edu.ph',
    name: 'Saint Louis College',
    web_page: 'http://www.slc-sflu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUzSZukoYqO_Z1eumpgJ12dF2fXIGycctnbAIKk89Ilw0cTBQ7hAv44Hw',
    id: 6635,
    acronym: 'SLC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'smu.edu.ph',
    name: "Saint Mary's University",
    web_page: 'http://www.smu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSanMjm8v3XlfEeaq8XU8ZrvmhEQvuiL_eDoW4lMZ7gUxDURtI-16dG9Q',
    id: 6637,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'spu.edu.ph',
    name: 'Saint Paul University',
    web_page: 'http://www.spu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlcT9ZZtpgWDtwGD8MdtTmQKpAZRmr1buG59NBvh3HjM_AsHKzqyR3Fw',
    id: 6638,
    acronym: 'SPU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ssu.edu.ph',
    name: 'Samar State University',
    web_page: 'http://www.ssu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWK4tOwEEY4W6LCZTBNPHwvm654Ljibx7pmmKkigLfwrDOJkbO3zB38Q',
    id: 6639,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'su.edu.ph',
    name: 'Silliman University',
    web_page: 'http://www.su.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcaalJBMdMc-If4rN9vh4T3NkyWW9j6-2YYGSr44YuGi6VeOe8-aVtZME',
    id: 6640,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'tup.edu.ph',
    name: 'Technological University of the Philippines',
    web_page: 'http://www.tup.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9xBzkqmQERp-XDaMX8E4R_ePzCmpc3ZBL_IPva1_4Nxj3Lrx1qbBf7tE',
    id: 6642,
    acronym: 'TUP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ua.edu.ph',
    name: 'University of the Assumption',
    web_page: 'http://www.ua.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqwH5U4-H9V30uNbrUAEgoE-shRAmK6SgX1qhL2kfbsRYcbSsto21Ltg',
    id: 6643,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uap.edu.ph',
    name: 'University of Asia and the Pacific',
    web_page: 'http://www.uap.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPQXOBKUSbrksyz_KzvltlPKGzISnSp84Pfpq9uYD4nvomm_sqKNMS-Q',
    id: 6644,
    acronym: 'UAP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ubaguio.edu',
    name: 'University of Baguio',
    web_page: 'http://www.ubaguio.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtRYNz2lH9h-e4Yvo3Zy4snuQgNDOrEblOViu3dz71WNaRfUsB71YeM0c',
    id: 6645,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ub.edu.ph',
    name: 'University of Batangas',
    web_page: 'http://www.ub.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNpF-3AWmsuaV3XbhVQD_KCRodwtDPLSPOucDGbv9kjKXB5gjxkurw1GNopQ',
    id: 6646,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uc-bcf.edu.ph',
    name: 'University of the Cordilleras',
    web_page: 'http://www.uc-bcf.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlSQ8x4RpGHakemXcMhDAzk_Kj9_MtJy6WaZmipAj_e9UJ29uhBkAndnQ',
    id: 6647,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ucv.edu.ph',
    name: 'University of Cagayan Valley',
    web_page: 'http://www.ucv.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-JL8oKHIpBaTFJi2UZE2Gw3DIJHWwOvuL_7OACrxNaNxCtPDVpJHhM-qB',
    id: 6648,
    acronym: 'UCV',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ue.edu.ph',
    name: 'University of the East Coloocan',
    web_page: 'http://www.ue.edu.ph/caloocan/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQo7ZpckMCaeAjk0T8DXytY2MXCwlcuuzhM_VrlERBqnY1Xb_Kzo_0vg',
    id: 6649,
    acronym: 'UEC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ue.edu.ph',
    name: 'University of the East Manila',
    web_page: 'http://www.ue.edu.ph/manila/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqMqC_CY2wV1r14XM-Iz-zyqA6HFMmUXyCGd3NrFIQc2q5nxmCsuf_5A',
    id: 6650,
    acronym: 'UEM',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uep.educ.ph',
    name: 'University of Eastern Philippines',
    web_page: 'http://www.uep.educ.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQ_IhxDLa_w7f4BY-tws9TgCXlLVaSKm8j3Cr86fNa-iQM1fF1-985aBs',
    id: 6651,
    acronym: 'UEP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uerm.edu.ph',
    name: 'University of the East Ramon Magsaysay Memorial Medical Center',
    web_page: 'http://www.uerm.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSO8fJutyiROJyB-y5bkXpEaqXfYFA501Wt4bcv360bWdpEOfGO-k9MeiQ',
    id: 6652,
    acronym: 'UERMMMC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uic.globe.com.ph',
    name: 'University of the Immaculate Conception',
    web_page: 'http://uic.globe.com.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY5vOKVfblq40lr3Q1Zo7EZOFX7YvAKI6fKD1Ym1hn4hiA_sF3fapcgQ',
    id: 6653,
    acronym: 'UIC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ui.phinma.edu.ph',
    name: 'University of lloilo',
    web_page: 'http://www.ui.phinma.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8L-Wa_RV5BweggD1UMX5LJkGj9K0Tp2FGW-1UPCiFS9N0o4EatFRUGQ',
    id: 6654,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'umindanao.edu.ph',
    name: 'University of Mindanao',
    web_page: 'http://www.umindanao.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTihPhHW7pPidg4JM1lBFtOLYERt3lIXZgHGkRWlCuLGpJdLfvfrBLbFg',
    id: 6655,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'unc.edu.ph',
    name: 'University of Nueva Caceres',
    web_page: 'http://www.unc.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQy-ofIqpdhzIy5XC0_VVW1bdCK1b9MKcQzS56eJTJUlotU_5jN2-np7II',
    id: 6656,
    acronym: 'UNC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'unep.edu.ph',
    name: 'University of Northeastern Philippines',
    web_page: 'http://www.unep.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEGpKIpA56X0xEhoYl9yPIlq1Yho408gum-X1L-PXSFQtlBJ48az7Qmg',
    id: 6657,
    acronym: 'UNP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'universityofbohol.com',
    name: 'University of Bohol',
    web_page: 'http://www.universityofbohol.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk-E6Us5n1JJzMT3koDwCiREYO68MkDgailoL-7rkvnWNANyZd0b4lR54',
    id: 6658,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'universityofcebu.edu.ph',
    name: 'University of Cebu',
    web_page: 'http://www.universityofcebu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSPE2IG0uei9psSc_TM1vaP4g8Y3Vmrc0slKZiLI_a9jCilJO1H1bdT70',
    id: 6659,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'univman.edu.ph',
    name: 'University of Manila',
    web_page: 'http://www.univman.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0rwulcf2oAm_q416xUtf7_AabfCmQOMHAZWTiECjGH95MmZdNMk3HIw',
    id: 6660,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uno-r.edu.ph',
    name: 'University of Negros Occidental-Recoletos',
    web_page: 'http://www.uno-r.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuqP-EiqxZLcttvg1SDPXNXShfFL_Yu5IAg3OkRfc6ZYm5XhaJ33OBHSA',
    id: 6661,
    acronym: 'UNO',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'unp.edu.ph',
    name: 'University of Northern Philippines',
    web_page: 'http://www.unp.edu.ph/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSEGpKIpA56X0xEhoYl9yPIlq1Yho408gum-X1L-PXSFQtlBJ48az7Qmg',
    id: 6662,
    acronym: 'UNP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'upang.edu.ph',
    name: 'University of Pangasinan',
    web_page: 'http://www.upang.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8L-Wa_RV5BweggD1UMX5LJkGj9K0Tp2FGW-1UPCiFS9N0o4EatFRUGQ',
    id: 6663,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'upb.edu.ph',
    name: 'University of the Philippines Baguio',
    web_page: 'http://www.upb.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnNS9hFccMsb2d6nkxxvDJtwAKz48Up8BWqFBaaSs2oWFmgIBrTjT9Vwk',
    id: 6664,
    acronym: 'UPB',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'upd.edu.ph',
    name: 'University of the Philippines Diliman',
    web_page: 'http://www.upd.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC7xrQVGT77JhhSlckpr5yFlM3EvdgpKx1ad06Ia9gwewZD1xMqqclYHU',
    id: 6665,
    acronym: 'UPD',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uphighschoolcebu.edu.ph',
    name: 'University of the Philippines Visayas - Cebu High School',
    web_page: 'http://www.uphighschoolcebu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnNS9hFccMsb2d6nkxxvDJtwAKz48Up8BWqFBaaSs2oWFmgIBrTjT9Vwk',
    id: 6666,
    acronym: 'UPV-CHS',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uphr.edu.ph',
    name: 'University of Perpetual Help',
    web_page: 'http://www.uphr.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi9xgiRm-Ahvqzz2xiOJ56LP_dZXYEVTKALHjUHPSF7R3IkP8eVaEKdg',
    id: 6667,
    acronym: 'UPH',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uplb.edu.ph',
    name: 'University of the Philippines Los Banos',
    web_page: 'http://www.uplb.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi2DvlsVlYuksEl3cBd22Zyjgq-ZVUAuuAARPv9MHWWcCYalFm1aO3Ww',
    id: 6668,
    acronym: 'UPLB',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'upm.edu.ph',
    name: 'University of the Philippines Manila',
    web_page: 'http://www.upm.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnNS9hFccMsb2d6nkxxvDJtwAKz48Up8BWqFBaaSs2oWFmgIBrTjT9Vwk',
    id: 6669,
    acronym: 'UPM',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'upmin.edu.ph',
    name: 'University of the Philippines Mindanao',
    web_page: 'http://www.upmin.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJpHI79VnCXMAW4c_j0uSFm2hTJoTegTg1F4_PKm9lEitNT22MBaNMkCa5',
    id: 6670,
    acronym: 'UPM',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'upou.org',
    name: 'University of the Philippines Open University',
    web_page: 'http://www.upou.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9hHTAp4f3ZUZIrjukAH3BH6jhnn0vWYkaeUwBCe4yksRsRklbtwRLtaU',
    id: 6671,
    acronym: 'UPOU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'upv.edu.ph',
    name: 'University of the Philippines Visayas',
    web_page: 'http://www.upv.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnNS9hFccMsb2d6nkxxvDJtwAKz48Up8BWqFBaaSs2oWFmgIBrTjT9Vwk',
    id: 6672,
    acronym: 'UPV',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'urc.edu.ph',
    name: 'University of Regina Carmeli',
    web_page: 'http://www.urc.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc0Dud2ttWRzTteVTW8zutMYGthsPI-5i10C66qvRKSSaG6ZdIX5Q2cRnD',
    id: 6673,
    acronym: 'URC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'usa.edu.ph',
    name: 'University of San Agustin',
    web_page: 'http://www.usa.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAyr56bPC6qexCZfI7xTy8ONNZSUNIk8s3KwtDumE0vG0ZNOwCotmexg',
    id: 6674,
    acronym: 'USA',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'usc.edu.ph',
    name: 'University of San Carlos',
    web_page: 'http://www.usc.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScOsGIBM5NrHRs5RXoawVvZFmcVLi36JOD8emw7NDnVvWPZTiql7g1NJk',
    id: 6675,
    acronym: 'USC',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'usep.edu.ph',
    name: 'University of Southeastern Philippines',
    web_page: 'http://www.usep.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQS9lv03augaQvbhNuBoW9UyT7trHVdljJEWrR2DEUOI0xnrQUDl0xkRQN-Cg',
    id: 6676,
    acronym: 'USP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'usjr.edu.ph',
    name: 'University of San Jose Recoletos',
    web_page: 'http://www.usjr.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD9Sm7CVQr-hBrqI7MYqPI5Qbreh73gLRUq_W_sH1i27Fk_QOcWRPjFp0',
    id: 6677,
    acronym: 'USJR',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'usls.edu',
    name: 'University of Saint La Salle',
    web_page: 'http://www.usls.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAIA3-mrgh6Z52KgAt1mjnK96E-slQHDrm0WRJIxv8-wkQ39i9enyIPmQ',
    id: 6678,
    acronym: 'USLS',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'usm.edu.ph',
    name: 'University of Southern Mindanao',
    web_page: 'http://www.usm.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc-PSMYLuJaEYxYkh-qL2-8R2eqrIF_L6TZhd6ZPcbjYE9ifAuCxddDTg',
    id: 6679,
    acronym: 'USM',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'usp.ph',
    name: 'University of Southern Philippines Foundation',
    web_page: 'http://www.usp.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgXKczka6aLQkRQfWCYfdTlOkBZoyP8LUOhGZ69JF7I14yTXNcEKyAlk0',
    id: 6680,
    acronym: 'USPF',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'ust.edu.ph',
    name: 'University of Santo Tomas',
    web_page: 'http://www.ust.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqOgomydr5mYxXa6_4PyZ3N08sVkcsEeFggIPDc0JMIwLFi6iFSCwTJw',
    id: 6681,
    acronym: 'UST',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'uv.edu.ph',
    name: 'University of the Visayas',
    web_page: 'http://www.uv.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2Ml23zrue3VMWoCYcHiUnf_bq5-bTJWHQod2R5MfbWg6yicFwKmN9cQ',
    id: 6682,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'vmuf.edu.ph',
    name: 'Virgen Milagrosa University Foundation',
    web_page: 'http://www.vmuf.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFWXF5Pzc1Q0Ks3t3SZSTAUCnQcXygOPZluuVFm7GGOTTHuLq7yNhzlB8',
    id: 6683,
    acronym: 'VMUF',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'wesleyan.edu.ph',
    name: 'Wesleyan University Philippines',
    web_page: 'http://www.wesleyan.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB-uE153hj_4-yAo1WSDf5gnOJYjrKjqN9--761CAIDpuCEy-YVoFIAmY',
    id: 6684,
    acronym: 'WUP',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'wmsu.edu.ph',
    name: 'Western Mindanao State University',
    web_page: 'http://www.wmsu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJfiMkSTaVsxEPIxot7oAI_gbYy3kyXlYNC8ARp-KocrwhnSM-RvXosA',
    id: 6685,
    acronym: 'WMSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'wvsu.edu.ph',
    name: 'West Visayas State University',
    web_page: 'http://www.wvsu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpL-A3Toy2fexweRHhijIep9St_39XColELAJ7GzgaXIwh_ad33l7UFH0',
    id: 6686,
    acronym: 'WVSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'www2.mozcom.com',
    name: 'Central Luzon State University',
    web_page: 'http://www2.mozcom.com/~clsu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREXgyJDyYrkMmMH3IUw-mgWVcarSOVfogpvfyGqLTZAGu6EDe6R3MU_g',
    id: 6687,
    acronym: 'CLSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'www2.mozcom.com',
    name: 'Tarlac State University',
    web_page: 'http://www2.mozcom.com/~tsu-ics/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQG090gZSQ4XCkeWF1FCW9AJc8THX8_y7-jYvN8m2Hl95rL6EmXALeoiAc',
    id: 6688,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'PH',
    country: 'Philippines',
    domain: 'xu.edu.ph',
    name: 'Xavier University',
    web_page: 'http://www.xu.edu.ph/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThbOt4zKE6DacUd5FX4MZy7FSUbjy9TsTqG5DS_GshivQ4goevzYENr5U',
    id: 6689,
    acronym: 'XU',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: '149.156.19.10',
    name: 'Fine Arts Academy "Jan Matejko" in Cracow',
    web_page: 'http://149.156.19.10/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkAznPochoawkQ2uge4oT3cohvGmC7J_OHY5jSeudoxBa4dCo509xA8SUK',
    id: 6690,
    acronym: 'FAA"MIC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ae.katowice.pl',
    name: 'Academy of Economics in Katowice',
    web_page: 'http://www.ae.katowice.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgr3MxORVTUNngHt3ZedrRED1J4VswrWlXj4pDOCcNFHCAKx_jBUBh8eo',
    id: 6691,
    acronym: 'AEIK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ae.krakow.pl',
    name: 'Academy of Economics in Cracow',
    web_page: 'http://www.ae.krakow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1whlTaowCPDuBs_P9nn4URuEGU-7LsKhnmM1ySSdbrflX2U_KEfmz0FI',
    id: 6692,
    acronym: 'AEIC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ae.poznan.pl',
    name: 'Academy of Economics in Poznan',
    web_page: 'http://www.ae.poznan.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzpNbol0u97n1YjYQh9HjUVkFfS5UjZ0MwcFliAXM-O352ClwyjI8DlF9t',
    id: 6693,
    acronym: 'AEIP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ae.wroc.pl',
    name: 'Academy of Economics in Wroclaw',
    web_page: 'http://www.ae.wroc.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6pH1mecieE4yeMaItJhm9rRCtv8X5uCD11Ccp9oWWTYql34yy3gG4nQ',
    id: 6694,
    acronym: 'AEIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'agh.edu.pl',
    name: 'AGH University of Science and Technology',
    web_page: 'http://www.agh.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNlfHe3enINyOCFejrgfZQRL4zr-HJmfaAhlaPCGSNqV2RLYypi2slV0g',
    id: 6695,
    acronym: 'AUST',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ahe.edu.pl',
    name: 'Academy of Humanities and Economics in Lodz',
    web_page: 'http://www.ahe.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYFwbaJybvyARmN2Iwg9JzFwBPMPg16BxGLe4ULRWEb0XCqQPniyxtQZU',
    id: 6696,
    acronym: 'AHEIL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amb.bydgoszcz.pl',
    name: 'Medical Academy Ludwik Rydygier in Bydgoszcz',
    web_page: 'http://www.amb.bydgoszcz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaxa70rsj16dvd185tBtJPJLoBL4JZ9A_T4s-MGbOgNuidb8pUS0Tqx7Y',
    id: 6697,
    acronym: 'MALRIB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amb.edu.pl',
    name: 'Medical Academy in Bialystok',
    web_page: 'http://www.amb.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKLPidxC0s1qLpcOqVcLGDDhZe7qrMRwHuc_x6bqnY05WY--D2ICPuRvdk',
    id: 6698,
    acronym: 'MAIB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amg.gda.pl',
    name: 'Medical Academy in Gdansk',
    web_page: 'http://www.amg.gda.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_5n2KyF7oGlWqtjXGzaFXexDaxwih-CvC11jUV21u5SzjtLQj_i5nDQv3',
    id: 6699,
    acronym: 'MAIG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'am.katowice.pl',
    name: 'Music Academy "Karol Szymanowski" in Katowice',
    web_page: 'http://www.am.katowice.pl/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRxxM1SC4rRJgaOf1Xx9hvfxLEtUYP-qQwkrZ-tjKmuRtxZMQYJKtITNlte',
    id: 6700,
    acronym: 'MA"SIK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'am.lodz.pl',
    name: 'Medical Academy in Lodz',
    web_page: 'http://www.am.lodz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqXqHLMtLv3SmRHcz72GDNjJL097TnfLK13_5groZU0B693CZF0q18uhA',
    id: 6701,
    acronym: 'MAIL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'am.lublin.pl',
    name: 'Medical Academy in Lublin',
    web_page: 'http://www.am.lublin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBbQzU0NyNyxOnCLw52IydEO5IaFhuUqe0ZFH0qWMlJYldoNl1SKXx2g',
    id: 6702,
    acronym: 'MAIL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amu.edu.pl',
    name: 'Adam Mickiewicz University of Poznan',
    web_page: 'http://www.amu.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxHXRC2i84UD69W3RqcX9fK7L02me2fbUuIKsBwwtYayD3RcWqJGckO98x4w',
    id: 6703,
    acronym: 'AMUP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amuz.bydgoszcz.pl',
    name: 'Music Academy "Felix Nowowiejski" in Bydgoszcz',
    web_page: 'http://www.amuz.bydgoszcz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGOb7bLLdR4y8eporHT7pYU9ccOzxn5EQXelU87cY8sydU0hyebvlr01g',
    id: 6704,
    acronym: 'MA"NIB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amuz.gda.pl',
    name: 'Music Academy "Stanislaw Moniuszko" in Gdansk',
    web_page: 'http://www.amuz.gda.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTR-GufDEnN_aLaMo70qPiAiH5ZE4XpR_7t88E0s1e82NSIyKtBFv250U',
    id: 6705,
    acronym: 'MA"MIG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amuz.krakow.pl',
    name: 'Music Academy in Cracow',
    web_page: 'http://www.amuz.krakow.pl/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQwlsPR-jQ2s3KtgRKsumwCU3gTg16Sv37H-IwCBULNitw6CZRDPaAbkCQ',
    id: 6706,
    acronym: 'MAIC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amuz.lodz.pl',
    name: 'Music Academy in Lodz',
    web_page: 'http://www.amuz.lodz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPMfjnGTOkRTuI7BPNcQIwSOXxiY0fYc0fRfjES-D08IVRDmm6lG86VA',
    id: 6707,
    acronym: 'MAIL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amuz.poznan.pl',
    name: 'Music Academy "Ignacy Jana Paderewski" in Poznan',
    web_page: 'http://www.amuz.poznan.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBAjH5vkyqziowxzqA0Q4JmjVPLyE6zHVjNw4eoP3c3JZ5pZjdJj4y06lk',
    id: 6708,
    acronym: 'MA"JPIP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amuz.wroc.pl',
    name: 'Music Academy "Karol Lipinski" in Wroclaw',
    web_page: 'http://www.amuz.wroc.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0q4-v8H0INmPsCKcRWPDRFaij_keJs1OA-w0HHWGLQ7DIXBGpVbk1CDC3',
    id: 6709,
    acronym: 'MA"LIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'amwaw.edu.pl',
    name: 'Medical Academy in Warszawa',
    web_page: 'http://www.amwaw.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOodEzRL-hVfUI6OGJVb2zKIgRHbQ3cGT25BIRoEXw2Bi27b4UcR7IC_M',
    id: 6710,
    acronym: 'MAIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'am.wroc.pl',
    name: 'Medical Academy in Wroclaw',
    web_page: 'http://www.am.wroc.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOodEzRL-hVfUI6OGJVb2zKIgRHbQ3cGT25BIRoEXw2Bi27b4UcR7IC_M',
    id: 6711,
    acronym: 'MAIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ap.edu.pl',
    name: 'Polonia University in Czêstochowa',
    web_page: 'http://www.ap.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNlfHe3enINyOCFejrgfZQRL4zr-HJmfaAhlaPCGSNqV2RLYypi2slV0g',
    id: 6712,
    acronym: 'PUIC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ap.siedlce.pl',
    name: 'Akademia Podlaska',
    web_page: 'http://www.ap.siedlce.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT76F6yg0PAxV2ksjWyQabJ1e3smzYcbKgMR9d1pEAS3ShEpETc7B0CSQ',
    id: 6713,
    acronym: 'AP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ar.krakow.pl',
    name: 'Agricultural University of Cracow',
    web_page: 'http://www.ar.krakow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3FvtVzDqCb7T4ktL4ReEwY-_HjofqgVgiR36ozmJP_50B-jQykUAHhZ4',
    id: 6714,
    acronym: 'AUC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ar.lublin.pl',
    name: 'Agricultural University of Lublin',
    web_page: 'http://www.ar.lublin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBbQzU0NyNyxOnCLw52IydEO5IaFhuUqe0ZFH0qWMlJYldoNl1SKXx2g',
    id: 6715,
    acronym: 'AUL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ar.szczecin.pl',
    name: 'Agricultural University of Szczecin',
    web_page: 'http://www.ar.szczecin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHde6cXYfqSa-9rFKUFlGauY1F0IG_UpKuE3OY4cNTnfamKC0jGvwFNQ',
    id: 6716,
    acronym: 'AUS',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'art.olsztyn.pl',
    name: 'Agricultural-Technical Academy in Olsztyn',
    web_page: 'http://www.art.olsztyn.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_B0iPGERFLLOiXlMiugpKAbKsdRNBSSZQobuCVfUYZlrX8ZXqGCXzO6U',
    id: 6717,
    acronym: 'AAIO',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ar.wroc.pl',
    name: 'Agricultural University of Wroclaw',
    web_page: 'http://www.ar.wroc.pl/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQ3FvtVzDqCb7T4ktL4ReEwY-_HjofqgVgiR36ozmJP_50B-jQykUAHhZ4',
    id: 6718,
    acronym: 'AUW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'asp.gda.pl',
    name: 'Fine Arts Academy in Gdansk',
    web_page: 'http://www.asp.gda.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRv_huPNNoaIlJXlZKnhfk2zeHSQyRUWvMlLR0IXEjpGShFpbigeCb3Qw',
    id: 6719,
    acronym: 'FAAIG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'aspkat.edu.pl',
    name: 'Fine Arts Academy in Katowice',
    web_page: 'http://www.aspkat.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVqmfqRiQSilm_AHvA2LS07t4gQG78UdMBO0O4d9nDWaVBimtQ3bwp4w',
    id: 6720,
    acronym: 'FAAIK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'asp.lodz.pl',
    name: 'Fine Arts Academy Wladyslaw Strzeminski in Lodz',
    web_page: 'http://www.asp.lodz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl9kKKYCbuRsdBte62NhVZYILudRE24deqt_BX3e9fV3Yxgtr56tQmOGlv',
    id: 6721,
    acronym: 'FAASIL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'asp.poznan.pl',
    name: 'Fine Arts Academy in Poznan',
    web_page: 'http://www.asp.poznan.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtk0wLb6qbjDpRPn46RkGLq9q-uUS_FzbUhsKesQN4CJ95fttc5oNRCwpu',
    id: 6722,
    acronym: 'FAAIP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'asp.waw.pl',
    name: 'Fine Arts Academy in Warsaw',
    web_page: 'http://www.asp.waw.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq3EAJaqqV6NPCVyGYzNkBTASWHUUHWmjQ3eQT1_clLm0QfUijMQe3cM4',
    id: 6723,
    acronym: 'FAAIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'asp.wroc.pl',
    name: 'Fine Arts Academy in Wroclaw',
    web_page: 'http://www.asp.wroc.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2_RUOzLcOm1GTjIOfts2KT3mmPPEiN_jD7UVcUKepOxgyBpG7xpkbSY3C',
    id: 6724,
    acronym: 'FAAIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'at.edu.pl',
    name: 'Aleksander Zelwerowicz State Theatre Academy',
    web_page: 'http://www.at.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqqmkfdvR9BhqjaZzaxFX1IZrTzdpGOMZuhcEyl_yyPbeCd57JL3dmcLg',
    id: 6725,
    acronym: 'AZSTA',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'au.poznan.pl',
    name: 'Agricultural University of Poznan',
    web_page: 'http://www.au.poznan.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPWQdlyc8L-Pyy7PP2oxofhw0JDtC-hBEcJozEWyhTMFgdlvJYrzQWbvuP',
    id: 6726,
    acronym: 'AUP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'awf.edu.pl',
    name: 'Physical Education Academy "Jozef Pilsudski" in Warsaw',
    web_page: 'http://www.awf.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQevXk7uZxHI0ubfIpIeF3gF-bHsaQY0NbI7QoEOttzORu0UqKChf4F-z4',
    id: 6727,
    acronym: 'PEA"PIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'awf.gda.pl',
    name: 'Physical Education Academy "Jedrzej Sniadecki" in Gdansk',
    web_page: 'http://www.awf.gda.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_RC-6y8Yp7il61NdyR0euBpf7IofhBPmbXpThJmEcn2yxK60z_hJecflV',
    id: 6728,
    acronym: 'PEA"SIG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'awf.katowice.pl',
    name: 'Physical Education Academy in Katowice',
    web_page: 'http://www.awf.katowice.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0w0OVObVDoPIAh3LKr_63QQVdKyDdRXW0hWqwFQh9PwwZgU-YEkHcIF89JA',
    id: 6729,
    acronym: 'PEAIK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'awf.krakow.pl',
    name: 'University School of Physical Education',
    web_page: 'http://www.awf.krakow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHmudeRk7xByO8vIu5EVY5qekv5ntPoJJopJjfyH2Fn69M7qwsfq0ZHQ',
    id: 6730,
    acronym: 'USPE',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'awf.poznan.pl',
    name: 'Physical Education Academy "Eugeniusz Piasecki" in Poznan',
    web_page: 'http://www.awf.poznan.pl/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTfdkw6IvGMj8WigzMEg2sXYTRW9li5_zXZm7bTy0FQ8ctPI50DD7jvBt37MQ',
    id: 6731,
    acronym: 'PEA"PIP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'awf.wroc.pl',
    name: 'Physical Education Academy in Wroclaw',
    web_page: 'http://www.awf.wroc.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK-AWGMwZ88MHuSQ8-AXxUH2pKtV2rI5AgShWjLkNOKEnzZAehsYEXMwAQ',
    id: 6732,
    acronym: 'PEAIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'chopin.edu.pl',
    name: 'Music Academy "Fryderyk Chopin" in Warszaw',
    web_page: 'http://www.chopin.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvxlSr6OIX1Ak308xpVofkPjmVLHr0eDtkMBGzKFT4WRGcNDMi02ang_w',
    id: 6733,
    acronym: 'MA"CIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'collegium.edu.pl',
    name: 'Collegium Civitas',
    web_page: 'http://www.collegium.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1DWbBON8Ovb9N6uzi2yNWHtt5_Qa8L59mDlMbeptWxFYUbx0E6P5mN9E',
    id: 6734,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'dswe.wroc.pl',
    name: 'University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.',
    web_page: 'http://www.dswe.wroc.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9RA0nLdR4BuQdsdzSXYrn0CYr4BfNdxxnyhkYYI_Z7XC-pb-FoARxRxU',
    id: 6735,
    acronym: 'ULSAFAEIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'eas.edu.pl',
    name: 'European Academy of Arts in Warsaw',
    web_page: 'http://www.eas.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq3EAJaqqV6NPCVyGYzNkBTASWHUUHWmjQ3eQT1_clLm0QfUijMQe3cM4',
    id: 6736,
    acronym: 'EAAIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'filmowka.lodz.pl',
    name: 'State Film Television and Theatre Higher School Leon Schiller in Lodz',
    web_page: 'http://www.filmowka.lodz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6yTVGIX1iXxGtDXqjVm4hndcoDSDqL6d3eCJErYMCc7Px2Bx9e9y4iF0',
    id: 6737,
    acronym: 'SFTTHSLSIL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'gallus.pl',
    name: 'Silesian School of Economics and Languages',
    web_page: 'http://www.gallus.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbb8vQYdPFoDh-5H-4ukTJ63ZfC9mCOEP0alICz56oByxu7IE-Fa3l1BcWyw',
    id: 6738,
    acronym: 'SSEL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'gwsh.pl',
    name: 'Katowice School of Economics',
    web_page: 'http://www.gwsh.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgr3MxORVTUNngHt3ZedrRED1J4VswrWlXj4pDOCcNFHCAKx_jBUBh8eo',
    id: 6739,
    acronym: 'KSE',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ifg.com.pl',
    name: 'French Institute of Management',
    web_page: 'http://www.ifg.com.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1x0c5LGgkZFcZqJSSvLPw_MQIFk4PFvMSI_-ZdCkwdtyW-k-EdwSX-Q',
    id: 6740,
    acronym: 'FIM',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ksw.edu.pl',
    name: 'Andrzej Frycz Modrzewski Cracow College',
    web_page: 'http://www.ksw.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0CQ-HG2KxqfoJs0UsuS9uLKdjdVpt59my8npLngQ_z3uwcou9ZyhU46E',
    id: 6741,
    acronym: 'AFMCC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'kul.lublin.pl',
    name: 'Catholic University of Lublin',
    web_page: 'http://www.kul.lublin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiKIG8iJmFtf7QNxaZB78mhSOF-wN3x89SJcVha2caCUnSffziHWi0pw',
    id: 6742,
    acronym: 'CUL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'lazarski.pl',
    name: 'Lazarski School of Commerce and Law',
    web_page: 'http://www.lazarski.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRIjI_17d6oGTAzVgFiNQ-Qoyb83ZvRLpy4Mhhaeju5y-hCHVVPhUVo5cn',
    id: 6743,
    acronym: 'LSCL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'man.radom.pl',
    name: 'Technical University of Radom',
    web_page: 'http://www.man.radom.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREkMaI1hrfj8wIb4tpgZQY1ZcynXI3zdEsw9KepWZ6pqetZ1O-oU3Mnw',
    id: 6744,
    acronym: 'TUR',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'nkrriwf.pl',
    name: 'Teacher Training College of Revalidation Rehabilitation and Physical Education',
    web_page: 'http://www.nkrriwf.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzOYFCY-IVNuoB8_Ob4TiWnSuIEIm60w59qjFfY3zGR-n8CMAMPOcUCul_',
    id: 6745,
    acronym: 'TTCRRPE',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pam.szczecin.pl',
    name: 'Pomeranian Academy of Medicine in Szczecin',
    web_page: 'http://www.pam.szczecin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTse6xn9PzKY-OlIXn7KwHs_duiupD-cECOIjAnroHkOsx5OEHwo5Jiyii-',
    id: 6746,
    acronym: 'PAMIS',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pb.bialystok.pl',
    name: 'Technical University of Bialystok',
    web_page: 'http://www.pb.bialystok.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZL_rooRInGC5wAJIDhAzlMEA115gm3nks_eh0NC61ago_dFzPtGh05do',
    id: 6747,
    acronym: 'TUB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pcz.czest.pl',
    name: 'Technical University of Czestochowa',
    web_page: 'http://www.pcz.czest.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhgnQXao3G3320xD7RLls9AVjQvZ8Ex6DK5-qEpiY4hIdJok4rk_wvKhB-',
    id: 6748,
    acronym: 'TUC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pg.gda.pl',
    name: 'Technical University of Gdansk',
    web_page: 'http://www.pg.gda.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH7PjbmDFUUFv8o9lnGHlhEOVt9hDj8MHRa3Rm7QEzUdrr4HM1HE-6WEA',
    id: 6749,
    acronym: 'TUG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pjwstk.edu.pl',
    name: 'Polish-Japanese Institute of Information Technology in Warsaw',
    web_page: 'http://www.pjwstk.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQypK05Y5rsr3TiU3o342o3yJLtmK4pzKBwKl81bM0kahm3WjWp7TliwUM',
    id: 6750,
    acronym: 'PIITIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pk.edu.pl',
    name: 'Technical University of Cracow',
    web_page: 'http://www.pk.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1whlTaowCPDuBs_P9nn4URuEGU-7LsKhnmM1ySSdbrflX2U_KEfmz0FI',
    id: 6751,
    acronym: 'TUC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'p.lodz.pl',
    name: 'Technical University of Lodz',
    web_page: 'http://www.p.lodz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj73tIW4RzhHf1FsQFpC0KxhFRpCYReu3hr-sD72B3TSUqk05BhH8FOOg',
    id: 6752,
    acronym: 'TUL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pol.lublin.pl',
    name: 'Technical University of Lublin',
    web_page: 'http://www.pol.lublin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3sm9XnT4eBzqpNM4llwtcAagjH1tO_lZV04_bciLt-pQK7XaaNmP51eo',
    id: 6753,
    acronym: 'TUL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'polsl.gliwice.pl',
    name: 'Silesian Technical University of Gliwice',
    web_page: 'http://www.polsl.gliwice.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM7TAz00_yM4XvkGTswJWr4l0lWASAvJ5ARFEekEdaikcEiAgHtkcyB3p1',
    id: 6754,
    acronym: 'STUG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'po.opole.pl',
    name: 'Technical University of Opole',
    web_page: 'http://www.po.opole.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiMfLy-PqQIXPRBX-GUtDHOqm0JkDhrVjdOko53F6132zzZt071PfX13kE',
    id: 6755,
    acronym: 'TUO',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'prz.rzeszow.pl',
    name: 'Technical University of Rzeszow',
    web_page: 'http://www.prz.rzeszow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTFe_2ACU-JyK2AHA4G26bzd7lsV27POfWZd3x6HIiOyHlAbVBvd4pZWLC',
    id: 6756,
    acronym: 'TUR',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pu.kielce.pl',
    name: 'Pedagogical University of Kielce',
    web_page: 'http://www.pu.kielce.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa_Q3CqqzwzBNYAHiRwPwb53i0Ip1FfZqhX2V4Kv-DgF7Buu2NWY8lalM',
    id: 6757,
    acronym: 'PUK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'puls.edu.pl',
    name: 'Poznan University of Life Sciences',
    web_page: 'http://www.puls.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy53hiDqpsgUOLSePYD1k2LTm3ljoSdaTdsEgqd2TLiMtDkIjxed7cHt8',
    id: 6758,
    acronym: 'PULS',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'put.poznan.pl',
    name: 'Technical University of Poznan',
    web_page: 'http://www.put.poznan.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPWQdlyc8L-Pyy7PP2oxofhw0JDtC-hBEcJozEWyhTMFgdlvJYrzQWbvuP',
    id: 6759,
    acronym: 'TUP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pw.edu.pl',
    name: 'Warsaw University of Technology',
    web_page: 'http://www.pw.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjfpWj4QRH10l2WhBonn2yCCECfkaA5HP-O6GUaxmHqXC3Q4kbIcDQqQ',
    id: 6760,
    acronym: 'WUT',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pwr.wroc.pl',
    name: 'Technical University of Wroclaw',
    web_page: 'http://www.pwr.wroc.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLpa-kdkVE4c6fsiKGZVGg33W10-TIhIy6xlbPPQF3rob5pK5xL_fFyBA',
    id: 6761,
    acronym: 'TUW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pwst.krakow.pl',
    name: 'Ludwik Solski State Academy of Theatre in Cracow',
    web_page: 'http://www.pwst.krakow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXF7ZGoyGnftxa-HGWbFa1wbWFP8sIERLvxwMsMqu-haqRBfdNzdmPFcRI',
    id: 6762,
    acronym: 'LSSATIC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pwsz.eu',
    name: 'Walcz College',
    web_page: 'http://www.pwsz.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzQ2nUg_RpSy_5qkZlxLQmuvTlyqi49GO4vC0fGCIum6vC6rzZKa2bQ',
    id: 6763,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pwsz.pila.pl',
    name: 'Staszic Pila College',
    web_page: 'http://www.pwsz.pila.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 6764,
    acronym: 'SPC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'pz.zgora.pl',
    name: 'Technical University of Zielona Gora',
    web_page: 'http://www.pz.zgora.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyULvxb2JMBGl7jsPyXAyfdJv_KpouaXJtitM4Gvjrjk1LtzXLFYpjDe0',
    id: 6765,
    acronym: 'TUZG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'qdnet.pl',
    name: 'Christian Theological Academy in Warszaw',
    web_page: 'http://www.qdnet.pl/ChAT/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjm3Uxp9Df0kWzggPSVYMkV2TBhyrKW-ouaEBfhGzBJe_yKtvxwxaA8A',
    id: 6766,
    acronym: 'CTAIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'sggw.waw.pl',
    name: 'Agricultural University of Warsaw',
    web_page: 'http://www.sggw.waw.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHde6cXYfqSa-9rFKUFlGauY1F0IG_UpKuE3OY4cNTnfamKC0jGvwFNQ',
    id: 6767,
    acronym: 'AUW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'sgh.waw.pl',
    name: 'Warsaw School of Economics',
    web_page: 'http://www.sgh.waw.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbb8vQYdPFoDh-5H-4ukTJ63ZfC9mCOEP0alICz56oByxu7IE-Fa3l1BcWyw',
    id: 6768,
    acronym: 'WSE',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'slam.katowice.pl',
    name: 'Silesian Academy of Medicine in Katowice',
    web_page: 'http://www.slam.katowice.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcmxROPm2BBgXfPltQeR-frR3ruhZPhAEvzjWEV7UvKTXo2OAKiklg6w',
    id: 6769,
    acronym: 'SAMIK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'swps.edu.pl',
    name: 'Warsaw School of Social Psychology',
    web_page: 'http://www.swps.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-9OzXiIZ4GDMwwR_plnMIEhEL4jZRdwKnCrWORwNpYH328_VtDRB5Tg',
    id: 6770,
    acronym: 'WSSP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'tu.kielce.pl',
    name: 'Technical University of Kielce',
    web_page: 'http://www.tu.kielce.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTd3QH9a3kxe2xcQsuP254as-SQA7HsjMPexStwT_1u27J-StAfxmVAsA',
    id: 6771,
    acronym: 'TUK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'tu.koszalin.pl',
    name: 'Technical University of Koszalin',
    web_page: 'http://www.tu.koszalin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhS6axbOSopRaiWeqGfl4sCsRc9m43QtEkosLRKNqPE7Dls4628vIflyA',
    id: 6772,
    acronym: 'TUK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'tuniv.szczecin.pl',
    name: 'Technical University of Szczecin',
    web_page: 'http://www.tuniv.szczecin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1SFXv3VczlWXQft0AaUH0mReGCt99brBlkv4NdO-WTOHd0cNAfl9Cpw',
    id: 6773,
    acronym: 'TUS',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'tyszkiewicz.edu.pl',
    name: 'Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala',
    web_page: 'http://www.tyszkiewicz.edu.pl/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQsTS9s0_KESZTFYuQDrn6dzygw-WMpRWn12VgkymDvgCo0O3B_0k703TM',
    id: 6774,
    acronym: 'JTCBCSIB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'ub.edu.pl',
    name: 'University of Bialystok',
    web_page: 'http://www.ub.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTio1rb_kYdWssOQ00Sw3tv9h0hUrFsyghGNNUa2hU4DsNw3K8EMl3jjA',
    id: 6775,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'uj.edu.pl',
    name: 'Jagiellonian University Cracow',
    web_page: 'http://www.uj.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMrQ_ELW2cYXLTKNhSaqyovt_vqN9KG1SIWJXGQTvohhyHg1Pnii22EEdz',
    id: 6776,
    acronym: 'JUC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'uksw.edu.pl',
    name: 'Cardinal Stefan Wyszynski University in Warsaw',
    web_page: 'http://www.uksw.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD9xqY4MYKdXO7dL4INW0mng9k50gdeDU8S1Fc5qnaUJ1G9lRR2Qc1dQ',
    id: 6777,
    acronym: 'CSWUIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'umcs.lublin.pl',
    name: 'Maria Curie-Sklodowska University Lublin',
    web_page: 'http://www.umcs.lublin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIAlOaNUaaMkZkK5jnLHE-uufRqpeWLCWsZpCWubaK5ejRX75srm3hUhJz',
    id: 6778,
    acronym: 'MCUL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'uni.lodz.pl',
    name: 'University of Lodz',
    web_page: 'http://www.uni.lodz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsyJKj5TEmSOeVOdXUyHy2TDgszFKghLOaE_nn8vQLnntv33BfvAyqiIvX6A',
    id: 6779,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'uni.opole.pl',
    name: 'University of Opole',
    web_page: 'http://www.uni.opole.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_UaWaIEWeBAdzdlSWg0vrIkEeSD0hXRH0AcVmM5tixNXDZOv2ObKoDbw',
    id: 6780,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'uni.torun.pl',
    name: 'Nicolaus Copernicus University of Torun',
    web_page: 'http://www.uni.torun.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4SGwFc-C0gm_eDcsIJoJj0thSYUtKxh1gq80SXeKvmqWrU8evp8ughQ',
    id: 6781,
    acronym: 'NCUT',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'univ.gda.pl',
    name: 'University of Gdansk',
    web_page: 'http://www.univ.gda.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbNBA2IUNgVuKm6gjEJr6UNIcbLHRyRl-qiAXMoZwcVJpFzCFqJdU3Zhg',
    id: 6782,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'univ.rzeszow.pl',
    name: 'Pedagogical University of Rzeszow',
    web_page: 'http://www.univ.rzeszow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo7qBVi7J9Mt3LxCipftH5Fm9PrhPCuvzzwIxXLAfySB_8fiDT3ilLzQ',
    id: 6783,
    acronym: 'PUR',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'univ.szczecin.pl',
    name: 'University of Szczecin',
    web_page: 'http://www.univ.szczecin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-9OzXiIZ4GDMwwR_plnMIEhEL4jZRdwKnCrWORwNpYH328_VtDRB5Tg',
    id: 6784,
    acronym: 'US',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'uni.wroc.pl',
    name: 'University of Wroclaw',
    web_page: 'http://www.uni.wroc.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpl4uv86w-wgLxLKSKFvuqJPWighgAStsY3e8DVuPlmnnl2d0A1mehkQ',
    id: 6785,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'us.edu.pl',
    name: 'University of Silesia',
    web_page: 'http://www.us.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSokzoclcUdf6kGKqeRXjlg8ngX6lrsdJK58jSKqWSDrjIByEfqBxBOez0',
    id: 6786,
    acronym: 'US',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'usoms.poznan.pl',
    name: 'Medical Academy Karol Marcinkowski in Poznan',
    web_page: 'http://www.usoms.poznan.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3PI9xvNDBaOAwJ8n6GM2NF7HnjZoBs9YdsIBWHzzgIf2uiOSWvaAFzA',
    id: 6787,
    acronym: 'MAMIP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'utp.edu.pl',
    name: 'University of Technology and Life Sciences',
    web_page: 'http://www.utp.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnhKzB1k_W55ff-0s4Lh42V8Dy6p4S97LYBodXOtru6y_KvtdAIr9AVC8',
    id: 6788,
    acronym: 'UTLS',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'uw.edu.pl',
    name: 'University of Warsaw',
    web_page: 'http://www.uw.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9EDtVTyT2nOyGWF_r5xG5MCxU9yp3wjkwnuTwDpqr3fHUxEcFDxSfcg',
    id: 6789,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'uwm.edu.pl',
    name: 'University of Wamia and Masuria in Olsztyn',
    web_page: 'http://www.uwm.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI3ySt5vAifnzEvWs3U43WGJZBVvSFtmClj5GxPs9t4XL_ZCWyGT9rw5U84w',
    id: 6790,
    acronym: 'UWMIO',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wlodkowic.pl',
    name: 'Pawel Wlodkowic University College in Plock',
    web_page: 'http://www.wlodkowic.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA3TFuVFsDVLYiaxAJe-2JyA60C12Pe3zyWPm_gaIiKXvcZ0ceROhMjg',
    id: 6791,
    acronym: 'PWUCIP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsb-nlu.edu.pl',
    name: 'Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz',
    web_page: 'http://www.wsb-nlu.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTutyym2z5gfmhZiZ9gotCaNElw5qED6Z9SWnMu7SvhR0is0hweVBueB00',
    id: 6792,
    acronym: 'HSOBLUINS',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsb.poznan.pl',
    name: 'Poznan School of Banking',
    web_page: 'http://www.wsb.poznan.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaE1ypXHolNqXCeW8HXMhKFClWet_W-2Di7nJPoVcg1Q4HD2xtRdH2Zw',
    id: 6793,
    acronym: 'PSB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsb.toi.tarnow.pl',
    name: 'Higher School o Business in Tarnow',
    web_page: 'http://wsb.toi.tarnow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKu6BptKjvnbim2xRUE2pb2v6Ndf7PgCjXGnDhTfPX5INfLRROs2vrKbU',
    id: 6794,
    acronym: 'HSOBIT',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsfiz.edu.pl',
    name: 'University of Finance and Management in Bialystok',
    web_page: 'http://www.wsfiz.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0nHx1wFdIjeQBKm3pCN0TN7bfxyyWtBznFA1kTrja_ZQWAp7cl6BtBA',
    id: 6795,
    acronym: 'UFMIB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsh.edu.pl',
    name: 'Aleksander Gieysztor School of Humanities in Pultusk',
    web_page: 'http://www.wsh.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6LOPXU6Opsxv27oeu-C4IqqXUh5-gSeowI5w9XJAWVyBDm4V67wteblw',
    id: 6796,
    acronym: 'AGSHIP',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wship.edu.pl',
    name: 'Ryszard Lazarski University of Commerce and Law in Warsaw',
    web_page: 'http://www.wship.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSr5GID3Js8JguUqmppgEYDMg8tPwDxeGd4TDW7Z9u-qVy3TBYPL5Lo5Jg',
    id: 6797,
    acronym: 'RLUCLIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsinf.edu.pl',
    name: 'College of Computer Science in Lodz',
    web_page: 'http://www.wsinf.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJO3A-aQaKaaGBM14o8OoczU6n_tECfWChDJcClOcKmVa9rzbdRMF8BME',
    id: 6798,
    acronym: 'CCSIL',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsisiz.edu.pl',
    name: 'Warsaw School of Information Technology',
    web_page: 'http://www.wsisiz.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbb8vQYdPFoDh-5H-4ukTJ63ZfC9mCOEP0alICz56oByxu7IE-Fa3l1BcWyw',
    id: 6799,
    acronym: 'WSIT',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsiz.rzeszow.pl',
    name: 'University of Information Technology and Management in Rzeszow',
    web_page: 'http://www.wsiz.rzeszow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNVPlpuSHDwlyC8FAdoIjJwWXHXTN2ZulcTAjnV3QxoVqYbZ54I3ggS-A',
    id: 6800,
    acronym: 'UITMIR',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsm.gdynia.pl',
    name: 'Gdynia Maritime Academy',
    web_page: 'http://www.wsm.gdynia.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPk_B-28eER9ZOmLLejBsRrYOHiJB5T_xo6eD7usBZUw6ksWHdHADoxVs',
    id: 6801,
    acronym: 'GMA',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsms.edu.pl',
    name: 'Academy of International Economic and Political Relations Gdynia',
    web_page: 'http://www.wsms.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCM6wZEOgGA1KSeJr9G5ic9odKOzgMdKwogBsQ5PNn_7DTTXnbYN0S2A',
    id: 6802,
    acronym: 'AIEPRG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsm.szczecin.pl',
    name: 'Maritime University in Szczecin',
    web_page: 'http://www.wsm.szczecin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS3mpqNlRDsA80fJtv8ouZj8DSWpZvcWs06ZthlGJ-UG_d0h3ggzPRK2s',
    id: 6803,
    acronym: 'MUIS',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsp.bydgoszcz.pl',
    name: 'Pedagogical University of Bydgoszcz',
    web_page: 'http://www.wsp.bydgoszcz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa_Q3CqqzwzBNYAHiRwPwb53i0Ip1FfZqhX2V4Kv-DgF7Buu2NWY8lalM',
    id: 6804,
    acronym: 'PUB',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsp.czest.pl',
    name: 'Pedagogical University of Czestochowa',
    web_page: 'http://www.wsp.czest.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWsI9Dhxzhb_GnsSnYka4O5iFBrODGVEELgPruac4uWkXbkfjRhIfv0Q',
    id: 6805,
    acronym: 'PUC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wspiz.edu.pl',
    name: 'Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw',
    web_page: 'http://www.wspiz.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQh_HkhFU-UyArcaSS0KzZCRJ9RQFwNJKyMDh5lwDKZtSlmIpb8W9btK5M',
    id: 6806,
    acronym: 'LKAEMIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsp.krakow.pl',
    name: 'Pedagogical University of Krakow',
    web_page: 'http://www.wsp.krakow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREGIkc7YTkVGc7-_x_a_kRRh_3LgwYvNjwYkM0-S4BcVSNagu61cQWyh4',
    id: 6807,
    acronym: 'PUK',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsp.slupsk.pl',
    name: 'Pedagogical University of Slupsk',
    web_page: 'http://www.wsp.slupsk.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa_Q3CqqzwzBNYAHiRwPwb53i0Ip1FfZqhX2V4Kv-DgF7Buu2NWY8lalM',
    id: 6808,
    acronym: 'PUS',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsps.waw.pl',
    name: 'Maria Grzegorzewska College for Special Education',
    web_page: 'http://www.wsps.waw.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzElkoVih-UWnw9ojnhwry83Q-iUsuc3QoLVpRPUr-1CEE2ekvcXrm6V0',
    id: 6809,
    acronym: 'MGCFSE',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsptwpwaw.edu.pl',
    name: 'Pedagogical University of the Polish Association for Adult Education in Warsaw',
    web_page: 'http://www.wsptwpwaw.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqJwn1_HbrU7N374oHJVNPHHVuUiKBDtsfPttQ_Wq7OT3IRMqvV7u48zQ',
    id: 6810,
    acronym: 'PUPAFAEIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsp.zgora.pl',
    name: 'Pedagogical University of Zielona Gora',
    web_page: 'http://www.wsp.zgora.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbljeyRe-OBVXDn4uTYLWOTQR4OqStCI2Bu_4UvTHFRE6mppGF6dpSgw',
    id: 6811,
    acronym: 'PUZG',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsub.waw.pl',
    name: 'University of Insurance and Banking in Warsaw',
    web_page: 'http://www.wsub.waw.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaE1ypXHolNqXCeW8HXMhKFClWet_W-2Di7nJPoVcg1Q4HD2xtRdH2Zw',
    id: 6812,
    acronym: 'UIBIW',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wszib.krakow.pl',
    name: 'School of Banking and Management in Cracow',
    web_page: 'http://www.wszib.krakow.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6yh81Y4PlHqk_Qxeu-SiAhxzAIa8sMIed_D6toYj0J3Zgb-tiLr-0aQ',
    id: 6813,
    acronym: 'SBMIC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wszim.edu.pl',
    name: 'University of Management and Marketing',
    web_page: 'http://www.wszim.edu.pl/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSOnPvKEXIBDcQGRQhK7oyDUWPxU_9WWBLQvNLghJAkr_t7peFRIfRjqg',
    id: 6814,
    acronym: 'UMM',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsz.pl',
    name: 'Gdansk Management College',
    web_page: 'http://www.wsz.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmMUZoluaQcqQ4SB1uHVgVoPL35xnCuL6SGY5xr7GalQIrh3e8jkF8l7A',
    id: 6815,
    acronym: 'GMC',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'wsz-pou.edu.pl',
    name: 'Polish Open University in Warsaw (WSZ/POU)',
    web_page: 'http://www.wsz-pou.edu.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaCh3fi2yz7kk_EVWESqTgHuMS706VX9XOO2PozvDylfdW__BJPGCVPcM',
    id: 6816,
    acronym: 'POUIW(',
  },
  {
    alpha_two_code: 'PL',
    country: 'Poland',
    domain: 'zpsb.szczecin.pl',
    name: 'West Pomeranian Business School in Szczecin',
    web_page: 'http://www.zpsb.szczecin.pl/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTljD-Uid77Sod7S51nnuL4h5bgwb9QQN2MOaxLS4Z9H2nbXnLj_ydYUqEMuQ',
    id: 6817,
    acronym: 'WPBSIS',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'cocite.pt',
    name: 'Instituto Superior de Informática e Gestão',
    web_page: 'http://www.cocite.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_I_TV-aqTBambnl-4BfFGJ0yuZdDEm1smQd9DSt7HTCMejspKssFwSRU',
    id: 6818,
    acronym: 'ISDIEG',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'enautica.pt',
    name: 'Escola Nautica Infante D. Henrique',
    web_page: 'http://www.enautica.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7GXPlnRom8xTP3Y3dHjP-J4rttEoL7O01JACEGFc2drJyr0Lt9eKNVg',
    id: 6819,
    acronym: 'ENIDH',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'esad.pt',
    name: 'Escola Superior de Artes e Design',
    web_page: 'http://www.esad.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_ThZtzJP0u5CH8eF6Si_m5nSG8fuNukbuieZ0FqXtTIwVIiA0R760DIQ',
    id: 6820,
    acronym: 'ESDAED',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'eshte.pt',
    name: 'Escola Superior de Hotelaria e Turismo do Estoril',
    web_page: 'http://www.eshte.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9Adu72Uw_5umdEYSVfgPChHReQC7iM8C5XLOu8miVTFnqh6qP4ah_PHAn',
    id: 6821,
    acronym: 'ESDHETDE',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'europeanuniversity.pt',
    name: 'European University Portugal',
    web_page: 'http://www.europeanuniversity.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeDJj3dQoaV5FsTy-0DnPX4u1BLMNnJrMxgbAUqL3f8Z0n3tGyvKLUf2Rw',
    id: 6822,
    acronym: 'EUP',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'iade.pt',
    name: 'Instituto de Artes Visuais Design e Marketing - IADE',
    web_page: 'http://www.iade.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGs48JR9tWPjMurTuMDAR48lHw6mnW5XCU85IuX0nfAoV2mYcdziKwlYg',
    id: 6823,
    acronym: 'IDAVDEM-I',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'inp.pt',
    name: 'Instituto Superior Novas Profissões - INP',
    web_page: 'http://www.inp.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbqmtvy-UihEOext-5xNsTbjiXm48DSZJ9zjnyYbs540_t0gyMhcS7lgI',
    id: 6824,
    acronym: 'ISNP-I',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'inuaf-studia.pt',
    name: 'Instituto Superior D. Afonso III - INUAF',
    web_page: 'http://www.inuaf-studia.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4N7cp9E5ln6fG9N1p98qOp1vliq50eiQe8oRMK1UhYOf5TTY6K9m76w',
    id: 6825,
    acronym: 'ISDAI-I',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipbeja.pt',
    name: 'Instituto Politécnico de Beja',
    web_page: 'http://www.ipbeja.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAoWSx2NhRMSD6ZXvGBY6xuvTXzC4CSXOLPtcb86E98kTrQW4CuNcf0SAz',
    id: 6826,
    acronym: 'IPDB',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipb.pt',
    name: 'Instituto Politécnico de Bragança',
    web_page: 'http://www.ipb.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZTlofGuUKcbRT08ZsRwMCp07a-QYHYpWJ5JYR4itapQJAMZx_PBzwIko',
    id: 6827,
    acronym: 'IPDB',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipca.pt',
    name: 'Instituto Politécnico do Cávado e do Ave',
    web_page: 'http://www.ipca.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxeV1JhHOeFwKyNY7sLXqsKqX-lwZuUtS5O9EPKW7GB9fl2Y48nqr1f_I',
    id: 6828,
    acronym: 'IPDCEDA',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipcb.pt',
    name: 'Instituto Politécnico de Castelo Branco',
    web_page: 'http://www.ipcb.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXhfeUJy9yV368PnYer9kkDe6VL66HE4yWwHXto-QUzHKrX6yhDR6-6g',
    id: 6829,
    acronym: 'IPDCB',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipc.pt',
    name: 'Instituto Politécnico de Coimbra',
    web_page: 'http://www.ipc.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLaJdJS1LXpOlHRmS5oARzdC_UcoPPxU8UDe7SwyKjiMWR_HkL7-cjbvE',
    id: 6830,
    acronym: 'IPDC',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipg.pt',
    name: 'Instituto Politécnico da Guarda',
    web_page: 'http://www.ipg.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxmTf3r2sxGu6YowQz8C83FMT3lcbIzXy17eO_Q4SeTTPCaKmdKPr7AnY',
    id: 6831,
    acronym: 'IPDG',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipiaget.org',
    name: 'Instituto Piaget',
    web_page: 'http://www.ipiaget.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxEnZ680j2jm0s9FlElJEGhRCOXhIl1SdFBCF7stCATZruoBlDJat3bCw',
    id: 6832,
    acronym: 'IP',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'iplei.pt',
    name: 'Instituto Politécnico de Leiria',
    web_page: 'http://www.iplei.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK5fOjfyvpv8BjB_Bk5sdx2X-cUmbfLEIiCjPZH0p6lPv-ZP8M_-GpUr6vyQ',
    id: 6833,
    acronym: 'IPDL',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipl.pt',
    name: 'Instituto Politécnico de Lisboa',
    web_page: 'http://www.ipl.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0zFj6aP01IMzslB6ifhMqtmCHF3q34KOoyTIQOG40AMs6BxDIbq7ORw',
    id: 6834,
    acronym: 'IPDL',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipportalegre.pt',
    name: 'Instituto Politécnico de Portalegre',
    web_page: 'http://www.ipportalegre.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDWWrwacEX4egXdQi_OPaM8qr0Pmo6qLAaS_fg8e-yx06V4eLEk3Cvv7I',
    id: 6835,
    acronym: 'IPDP',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipp.pt',
    name: 'Instituto Politecnico do Porto',
    web_page: 'http://www.ipp.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0OtrQ4u7llP0FTgdn22v7PjVqC29Ay-wbN3fSh9GXtji9HMa_tbPd1o4',
    id: 6836,
    acronym: 'IPDP',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipsantarem.pt',
    name: 'Instituto Politécnico de Santarém',
    web_page: 'http://www.ipsantarem.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzV-baVqI90JlxROMNwhiX5m0z23gkPWMXWXTKUdediZao6sa8WezyFQ',
    id: 6837,
    acronym: 'IPDS',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ips.pt',
    name: 'Instituto Politécnico de Setúbal',
    web_page: 'http://www.ips.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQhhdxfHIBRLIHZ0bczCqUC1phgT6peoiMu6tysQuajxLBGG6CvqqxTQ',
    id: 6838,
    acronym: 'IPDS',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipt.pt',
    name: 'Instituto Politécnico de Tomar',
    web_page: 'http://www.ipt.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjhYMjt332Sta0qpJmYRc2cxwIYfsA_orc_V_RO7pp4ks9gpgFVu5XgvQ',
    id: 6839,
    acronym: 'IPDT',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ipvc.pt',
    name: 'Instituto Politécnico de Viana do Castelo',
    web_page: 'http://www.ipvc.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDeSoTIfzHdCFDT84lHixsyZRKcAimwT0ohcT4pMnZSTOgU1kZp5x3oA',
    id: 6840,
    acronym: 'IPDVDC',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'isai.pt',
    name: 'Instituto Superior de Assistentes e Intérpretes',
    web_page: 'http://www.isai.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkXDmLeQJw5_6GdVN1hnIkeXM-onAoOrEeTlfhTYgG0_zXYe3sY0mlZgds',
    id: 6841,
    acronym: 'ISDAEI',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'isave.edu.pt',
    name: 'Instituto Superior de Saúde do Alto Ave',
    web_page: 'http://www.isave.edu.pt/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRtGmwGCtRnfxHkeuwdl99Hxjd8Jklb4vPKOBwVV88oyVr-CHka5CB33A',
    id: 6842,
    acronym: 'ISDSDAA',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'isbb.pt',
    name: 'Instituto Superior Bissaya Barreto',
    web_page: 'http://www.isbb.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYQ8h4_eHkxAk-AuWX5QyBL-iBp-2_sb60A2izS41HV9dw8alWKrM8SA',
    id: 6843,
    acronym: 'ISBB',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'iscet.pt',
    name: 'Instituto Superior de Ciências Empresariais e de Turismo',
    web_page: 'http://www.iscet.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZsDNzvf-Q_7O1nON-u2VUcCnwibAxgMMTU1eJsrT-g-1T0y4ppvxixA',
    id: 6844,
    acronym: 'ISDCEEDT',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'iscte.pt',
    name: 'Instituto Superior de Ciências do Trabalho e da Empresa',
    web_page: 'http://www.iscte.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC88XGROw9kArdyOYqEcvq5XViBJMWz_mFyHe3u43HKcQ3yA6l2fn3bA',
    id: 6845,
    acronym: 'ISDCDTEDE',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'isg.pt',
    name: 'Instituto Superior de Gestão - ISG',
    web_page: 'http://www.isg.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-NXjawjjxJfa5ECmK1wg6zcXerJuS8NTb9dsH5wXSUPZ27hJ5vnBLembx',
    id: 6846,
    acronym: 'ISDG-I',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'isla.pt',
    name: 'Instituto Superior de Línguas e Administração',
    web_page: 'http://www.isla.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR88q9O_UrpJsT7s65e2XNrIjrwb8bbaw2_pbWzUG6U0byz_US4vwXWK9Q',
    id: 6847,
    acronym: 'ISDLEA',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ispab.pt',
    name: 'Instituto Superior de Paços de Brandão',
    web_page: 'http://www.ispab.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdjGZjYfAI4kaJO8Fy6FN32AoMHeXDwJSaw6SKKLVZEuaTKFqLMeu9S_C3',
    id: 6848,
    acronym: 'ISDPDB',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ispa.pt',
    name: 'Instituto Superior de Psicologia Aplicada',
    web_page: 'http://www.ispa.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2uj7u9koJ9JhRJm6GlUp9bg6EPkHuUznFqmFhkf2PYTRVGTM4FtZKSA',
    id: 6849,
    acronym: 'ISDPA',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ispgaya.pt',
    name: 'Instituto Superior Politécnico Gaya',
    web_page: 'http://www.ispgaya.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkwsqFqGa86mIw9hGTmvAL-6g0Y0SeRnk-MZOL_Q8FA5bonAvLQe_crao',
    id: 6850,
    acronym: 'ISPG',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'istec.pt',
    name: 'Instituto Superior de Tecnologias Avançadas - ISTEC',
    web_page: 'http://www.istec.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMGiIR5BRB_EYUKHdopz-4ehqdn8bBDhpbfhfbRRLgIv_b7jStEiWdv6s',
    id: 6851,
    acronym: 'ISDTA-I',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'istp.pt',
    name: 'Instituto Superior de Transportes e Comunicações',
    web_page: 'http://www.istp.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaSNZHNRRXSEP5qIyfZ8KQMfJA9B8ySaLyNHPYx7aciBNIFhyi7Q6fAUs',
    id: 6852,
    acronym: 'ISDTEC',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'isvouga.com',
    name: 'Instituto Superior de Entre Douro e Vouga',
    web_page: 'http://www.isvouga.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlqiRaXflqbMk837W0LetWwWrLQOYSvqqqZkoreklBB9yLqlCpm9z1TA',
    id: 6853,
    acronym: 'ISDEDEV',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'oml.pt',
    name: 'Academia Nacional Superior de Orquesta',
    web_page: 'http://www.oml.pt/anso/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQliGNmwSqh4hYT84nEyU82atXxMeGtvM8-ngSRd_u-1lPzQwPCbNXLIIE',
    id: 6854,
    acronym: 'ANSDO',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'pedago.pt',
    name: 'Instituto Superior de Ciências Educativas',
    web_page: 'http://www.pedago.pt/isce.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2ckMa2PYthXTjm_XfNd8xhDEeYtIi2qHy4WMgsaf18sFKi2R5m-z4cbI',
    id: 6855,
    acronym: 'ISDCE',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uac.pt',
    name: 'Universidade dos Acores',
    web_page: 'http://www.uac.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ9-N_q9etRFqipDtYmUk2pVUKpKx8u3Z8tVGdVj1r12Fmabt61OKbrWQX',
    id: 6856,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ualg.pt',
    name: 'Universidade do Algarve',
    web_page: 'http://www.ualg.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdfN0PG8Ks_jAc0dstJmoxRcU_OvUG2RsftjSh0fv07jeBUxaMgDUROC4',
    id: 6857,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ua.pt',
    name: 'Universidade de Aveiro',
    web_page: 'http://www.ua.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqsHyK-sDmH8Wl8VccThanQLWZd5t4baDzUxrw7K-kJiI7ZmU-xVN-9g',
    id: 6858,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uatla.pt',
    name: 'Universidade Atlântica',
    web_page: 'http://www.uatla.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRllNHksywKsEUPZ3kZo_j3YJHjT5BqsgsxkEzJvPw4E8o96kIWUNYYftA',
    id: 6859,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ubi.pt',
    name: 'Universidade da Beira Interior',
    web_page: 'http://www.ubi.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJiLxSSWi7GE3JO0BP7JSGxlmsu5mL5_-UUKPxVZP76r7gHsjMFNW9MgsA',
    id: 6860,
    acronym: 'UDBI',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ucp.pt',
    name: 'Universidade Catolica Portuguesa',
    web_page: 'http://www.ucp.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlELNHxjTnSq-cWkOBTQ9cGTqoSDkT_aLfd9qlImqOTrx-PUHtcr8s3bo',
    id: 6861,
    acronym: 'UCP',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uc.pt',
    name: 'Universidade de Coimbra',
    web_page: 'http://www.uc.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs7UtjUPc8uua7izrqbuHgh9BWieu5PB_TdQ_Ru0Oyxhm-5Ee4tfnVwQ',
    id: 6862,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uevora.pt',
    name: 'Universidade de Evora',
    web_page: 'http://www.uevora.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-DEkw7nFs0y0YlRLoAnNWwyWMziaE9kGmck0VAwxngla2mzrh1ZcX2e0',
    id: 6863,
    acronym: 'UDE',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ufp.pt',
    name: 'Universidade Fernando Pessoa',
    web_page: 'http://www.ufp.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9_685EuaDDms6A5skTpc8tIRoWQVr4sKY9271HW-xwEfcA9IPK8EFCfM',
    id: 6864,
    acronym: 'UFP',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uinternacional.pt',
    name: 'Universidade Internacional Lisboa',
    web_page: 'http://www.uinternacional.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj5zW8Rs_sqXwYgRhdgH1li185lxWH2LvmpXKYAsBLQb_Wh3uqgGDQhkAl',
    id: 6865,
    acronym: 'UIL',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ul.pt',
    name: 'Universidade de Lisboa',
    web_page: 'http://www.ul.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUA0jeF4XvQ99j2HrimdjtCLI5W8byGvlpJRFvY-J_uHE5MVKY_h6PHQ',
    id: 6866,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ulusiada.pt',
    name: 'Universidade Lusiada',
    web_page: 'http://www.ulusiada.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSL4R28sHhXrTtd1-rwEOq82bD1v5vw5n4ARRgWdYCAKmbcQIhvifXEg',
    id: 6867,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'ulusofona.pt',
    name: 'Universidade Lusófona de Humanidades e Technologias',
    web_page: 'http://www.ulusofona.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGT-zqmVcnKYZQxkyMhySe0JeTFeX0pwiwlX7AzBL_Up2MH2P0b0VXf5E',
    id: 6868,
    acronym: 'ULDHET',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uma.pt',
    name: 'Universidade da Madeira',
    web_page: 'http://www.uma.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkLZunkSvlxNUlTAN7sKf_7AMBIXT9bjvGogGGAZUmIAI-FXKHgOGyYkU',
    id: 6869,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uminho.pt',
    name: 'Universidade do Minho',
    web_page: 'http://www.uminho.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUUvgya0pS1KsX8d0FoX3XtAeTovFl-TToLqskUhHPddZopvfOO-I4xJQ',
    id: 6870,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'umoderna.pt',
    name: 'Universidade Moderna de Lisboa',
    web_page: 'http://www.umoderna.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDua1pTGI5-bJPniMIa5hRSfiNhmWT7hcZsYWNEePdw8F36OWR4rjhAg',
    id: 6871,
    acronym: 'UMDL',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uni.pt',
    name: 'Universidade Independente',
    web_page: 'http://www.uni.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwkZGpqNVafume2xraPDc3A6JoP8RlS0aFGS-Kz6FuVQEgUiP5fjNrDg',
    id: 6872,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'univ-ab.pt',
    name: 'Universidade Aberta Lisboa',
    web_page: 'http://www.univ-ab.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUbvrZCaZkp6TFm_4FDQlo2EbS-lzGzoW4_Kl8FH7M4SwxWZDW-kKYtwE',
    id: 6873,
    acronym: 'UAL',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'universidade-autonoma.pt',
    name: 'Universidade Autónoma de Lisboa Luís de Camoes',
    web_page: 'http://www.universidade-autonoma.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4_uQYRXt0ABPIhsYBMp2yheSEwyPpva7fKoZCwHuxkES4e0JMvvJ4eg',
    id: 6874,
    acronym: 'UADLLDC',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'unl.pt',
    name: 'Universidade Nova de Lisboa',
    web_page: 'http://www.unl.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQavoS4MV66j2xBDZCe3j1FspSZxfhfdkxhgAJ54MZW0QHAquDc-N_KAw',
    id: 6875,
    acronym: 'UNDL',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'uportu.pt',
    name: 'Universidade Portucalense Infante D. Henrique',
    web_page: 'http://www.uportu.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAuvIL_rvFzyOXRZ73sjoEAZKEIUKHeW9ZfmbVqCP4VbK9j2zqRt0frg',
    id: 6876,
    acronym: 'UPIDH',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'up.pt',
    name: 'Universidade do Porto',
    web_page: 'http://www.up.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk9fZwgL5sux8qSOeHsmZBfAWqQtlv874pFDA05cC8EJq19zOZIVk2K70',
    id: 6877,
    acronym: 'UDP',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'utad.pt',
    name: 'Universidade de Tras-os-Montes e Alto Douro',
    web_page: 'http://www.utad.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWdB27eZUpKVyeMHmyWMPQJ9z2dnUXh1-vPrSChq79ZS81Nd7LmPOMGW0',
    id: 6878,
    acronym: 'UDTEAD',
  },
  {
    alpha_two_code: 'PT',
    country: 'Portugal',
    domain: 'utl.pt',
    name: 'Universidade Tecnica de Lisboa',
    web_page: 'http://www.utl.pt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz4cWpAmot5rIZAqbmVCJ3X-SfusNquFHnrrgo_Sbpg8enL3lGGDTXYQ',
    id: 6879,
    acronym: 'UTDL',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'bc.inter.edu',
    name: 'Universidad Interamericana de Puerto Rico Bayamón',
    web_page: 'http://www.bc.inter.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpEfBNtB9u6gYOXK3CHUysAPxeLFUItN0fbnPecXjui_5dOWP5BbjRp0w',
    id: 6880,
    acronym: 'UIDPRB',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'caribbean.edu',
    name: 'Caribbean University',
    web_page: 'http://www.caribbean.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMj_tM89W2sNHBB5jJzgQsptdbt48GfAWJzqTh1Qam2dEiYUZ4yyq3Nc8',
    id: 6881,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'cayey.upr.edu',
    name: 'Universidad de Puerto Rico Cayey',
    web_page: 'http://www.cayey.upr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVNbl5wCUJu4HzSZgfmtVBKcYljBgiYp4CfBE7FV-TnPU4D1_2IiCHPc0M',
    id: 6882,
    acronym: 'UDPRC',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'ceaprc.org',
    name: 'Centro de Estudios Avanzados de Puerto Rico y el Caribe',
    web_page: 'http://www.ceaprc.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYYeEwtyTIxb-rKfh-565qpMlfW9O5WM5sFb4H15nF_mLiVoGxYbc3CX0',
    id: 6883,
    acronym: 'CDEADPRYEC',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'cuhupr.clu.edu',
    name: 'Universidad de Puerto Rico Humacao',
    web_page: 'http://cuhwww.upr.clu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-a8jryEYk-9dEzqIcdWMCN0kEZEVIBqkejQCBpuHwiEO-ZdG-8SSSZiw-',
    id: 6884,
    acronym: 'UDPRH',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'inter.edu',
    name: 'Universidad Interamericana de Puerto Rico',
    web_page: 'http://www.inter.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpEfBNtB9u6gYOXK3CHUysAPxeLFUItN0fbnPecXjui_5dOWP5BbjRp0w',
    id: 6886,
    acronym: 'UIDPR',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'pucpr.edu',
    name: 'Pontificia Universidad Catolica de Puerto Rico',
    web_page: 'http://www.pucpr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnibTJXS7EcvpjV3qPVqzG2CnjU06jqn_jgIdUP_E-75IWeZkrUFK4QpQ',
    id: 6887,
    acronym: 'PUCDPR',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'pupr.edu',
    name: 'Universidad Politecnica de Puerto Rico',
    web_page: 'http://www.pupr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTu6u8iyzsb2YUnPL-OjhfbPVLBp8Jj3zbV3TMHpLKprnrhNRUqASTu0g',
    id: 6888,
    acronym: 'UPDPR',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'rcm.upr.edu',
    name: 'Universidad de Puerto Rico Ciencias Medicas',
    web_page: 'http://www.rcm.upr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEMab7fXZHiNI9u59bmpDDoDdQ0nZqUfVPGyq3OMHNsn10MXP-QUEnNw',
    id: 6889,
    acronym: 'UDPRCM',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'sagrado.edu',
    name: 'Universidad del Sagrado Corazon',
    web_page: 'http://www.sagrado.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEzbHjqMt7WyhQq5BiRz0_8488G53QrT90IyZRIiTj20yLKYWynfeM-JE',
    id: 6890,
    acronym: 'UDSC',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'sanjuanbautista.edu',
    name: 'San Juan Bautista School of Medicine',
    web_page: 'http://www.sanjuanbautista.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyVvLBLQ5qMsgQOqra16J9j9-yX7CG-VqWq7EFSW1I-TJXU_i02aed9g',
    id: 6891,
    acronym: 'SJBSM',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'sg.inter.edu',
    name: 'Universidad Interamericana de Puerto Rico San Germán',
    web_page: 'http://www.sg.inter.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2vfSqkOKi7Eu9UiBRiakLfstzhXWml2U_t17qd1_7BfReHQCVcBw3hw',
    id: 6892,
    acronym: 'UIDPRSG',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'suagm.edu',
    name: 'Sistema Universitario Ana G. Méndez',
    web_page: 'http://www.suagm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvFeiV7s-aaNW-VAxLoUut40EipHX9C-DRG7yTo-kFMXvl0g5biGVP62Q',
    id: 6893,
    acronym: 'SUAGM',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'ucb.edu.pr',
    name: 'Universidad Central de Bayamón',
    web_page: 'http://www.ucb.edu.pr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAuWjzGl5gKR2cGnuTVZeP2UzlEU43PblcLTexV5TmW-Ra2lhiX5eDGA',
    id: 6895,
    acronym: 'UCDB',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'uccaribe.edu',
    name: 'Universidad Central del Caribe',
    web_page: 'http://www.uccaribe.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfm4ZlEi_fxHErIyLDsBFQzz0jgVerVB4aXIHdMnKQRA5qTKC6Kkul-mo',
    id: 6896,
    acronym: 'UCDC',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'upra.edu',
    name: 'Universidad de Puerto Rico Arecibo',
    web_page: 'http://www.upra.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3LjEo18jzqS624F55mrq7o84WtSBuTS50oH-9_6ll1w1nYZZT-pQ-HQ',
    id: 6897,
    acronym: 'UDPRA',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'uprag.edu',
    name: 'Universidad de Puerto Rico Aguadilla',
    web_page: 'http://www.uprag.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3LjEo18jzqS624F55mrq7o84WtSBuTS50oH-9_6ll1w1nYZZT-pQ-HQ',
    id: 6898,
    acronym: 'UDPRA',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'uprb.edu',
    name: 'Universidad de Puerto Rico Bayamon',
    web_page: 'http://www.uprb.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCirFft0Ieg8wZdLMTO7MoEH2fncgv-jYHypfObBuXfcgvJwZLALT4dA',
    id: 6899,
    acronym: 'UDPRB',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'uprc.edu',
    name: 'Universidad de Puerto Rico Carolina',
    web_page: 'http://www.uprc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRW5aoda5cXdgXIv58NqFxLjRaVFsxbdrXbM3pesdgHGsG72IhLA0vlY9I',
    id: 6900,
    acronym: 'UDPRC',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'upr.clu.edu',
    name: 'Universidad de Puerto Rico',
    web_page: 'http://www.upr.clu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl2rmJwzCxXpqEPehrvxqlUJNcvrm5lg5nz4ivJ5G_qbU5lND9OrvrRLQ',
    id: 6901,
    acronym: 'UDPR',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'uprm.edu',
    name: 'Universidad de Puerto Rico Mayaguez',
    web_page: 'http://www.uprm.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS32NqJyXLf6B1EljPnlK7GPcikTHoU35_owHrzgZHWYHNjSg9Lnt8kjA',
    id: 6902,
    acronym: 'UDPRM',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'upr-ponce.upr.edu',
    name: 'Universidad de Puerto Rico Ponce',
    web_page: 'http://upr-ponce.upr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUlfTN9k0yVmhoUSd_kchx74JPVJw2NTwE8_NdiHHTgtw55IPZo9489Q',
    id: 6903,
    acronym: 'UDPRP',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'uprrp.edu',
    name: 'Universidad de Puerto Rico Rio Pedras',
    web_page: 'http://www.uprrp.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl2rmJwzCxXpqEPehrvxqlUJNcvrm5lg5nz4ivJ5G_qbU5lND9OrvrRLQ',
    id: 6904,
    acronym: 'UDPRRP',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'uprutuado.edu',
    name: 'Universidad de Puerto Rico Utuado',
    web_page: 'http://www.uprutuado.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzC3NBSXrwnhgdpqlb_cDt253OajhkbFDJgAMi1BX3VnAc7HN95WkAsg',
    id: 6905,
    acronym: 'UDPRU',
  },
  {
    alpha_two_code: 'PR',
    country: 'Puerto Rico',
    domain: 'ut.pr',
    name: 'Universidad del Turabo',
    web_page: 'http://www.ut.pr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHPCxFml4irm6ITKOlatrw-ZD0EAh9DAGwcDOOWZ-wllHIOmvbUZObIYk',
    id: 6906,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'QA',
    country: 'Qatar',
    domain: 'qatar.northwestern.edu',
    name: 'Northwestern University In Qatar',
    web_page: 'http://www.qatar.northwestern.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFD8-dHnWEjY7VB-UZum3GyW9I3Npuj4IP5W_zepX1QfNCO-SYQ4OlEg',
    id: 6907,
    acronym: 'NUIQ',
  },
  {
    alpha_two_code: 'QA',
    country: 'Qatar',
    domain: 'qu.edu.qa',
    name: 'University of Qatar',
    web_page: 'http://www.qu.edu.qa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVoyFcqBYg6d3ObJ7-OYtUNn_hEzRZTa8ySwIDmFAHjiKEGYea8Uk13zo',
    id: 6908,
    acronym: 'UQ',
  },
  {
    alpha_two_code: 'RE',
    country: 'Réunion',
    domain: 'univ-reunion.fr',
    name: 'Université de la Reunion',
    web_page: 'http://www.univ-reunion.fr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0xXqYmiRTbE1dvsrTqt2wrEw6CGMJ5hX49jAqPCQYB0MtoQc4pIHDS7w',
    id: 6909,
    acronym: 'UDLR',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'aistedaab.ro',
    name: 'AISTEDA',
    web_page: 'http://www.aistedaab.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFuzEhTZ8lhy4M42kuBUIu5kQ13XR5TzcQxqn1GvgtxQPexpev1GMEZA',
    id: 6910,
    acronym: 'A',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'amgd.eu',
    name: 'Academy of Music "Georghe Dima" Cluj-Napoca',
    web_page: 'http://www.amgd.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb6RmXM-fT1kHtX25hi3hcKhDtTIFE4KidK2DZtKgZfFLkLYFvdMCkoH8',
    id: 6911,
    acronym: 'AM"DC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'anefs-edu.ro',
    name: 'National Academy for Physical Education and Sports Bucharest',
    web_page: 'http://www.anefs-edu.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAmgj0ccGTXsUIBtNyKRD2xXVkdXT4-OR8qpHZqnhyMHoD6-567IGVtg',
    id: 6912,
    acronym: 'NAFPESB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'arteiasi.ro',
    name: 'Academy of Arts "George Enescu" Iasi',
    web_page: 'http://www.arteiasi.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkuhUCfDjalLTlqawVpk0Jq5x09GF_W3qxX5un99aFnFRnxM5Ncm3qADje',
    id: 6913,
    acronym: 'AA"EI',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'ase.ro',
    name: 'Academia de Studii Economice din Bucuresti',
    web_page: 'http://www.ase.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjXeqt32qXguq2qKZLfFzvAVA0Nkny84mlC10FmVPPzOi7pB2FWkvawg7K',
    id: 6914,
    acronym: 'ADSEDB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'central.ucv.ro',
    name: 'University of Craiova',
    web_page: 'http://www.central.ucv.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLeFWTzfHQ7NVFTwSRt7slPIe7turiVtu3p2oDjDKsPLB1_YcQ2jfpBNXR',
    id: 6915,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'emanuel.ro',
    name: 'Emanuel University',
    web_page: 'http://www.emanuel.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-vIjEdiybLMRqmzhqy36CpqGvyeq_xMVt7D6Znw-cvWTJPrU9Twd6',
    id: 6916,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'iaim.ro',
    name: 'Institute of Architecture "Ion Mincu" Bucharest',
    web_page: 'http://www.iaim.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkCKHKh2Fe9wvz8RCYvVcQMNCYVJHZPoRP0DsgfKaJIq-jxEXFu5KSYI4',
    id: 6917,
    acronym: 'IA"MB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'mta.ro',
    name: 'Academia Tehnica Militara',
    web_page: 'http://www.mta.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfpMkuu-OqHD1oM3bMfzMcuoNDYxu4O8TIPbJxWWEzxElGYRgY0hosdcw',
    id: 6918,
    acronym: 'ATM',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'oradeauniversity.com',
    name: 'University of Medicine and Pharmacology of Oradea',
    web_page: 'http://www.oradeauniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUS5_4Vip2GEoMUo57IQoZZpJipmZYS_TPDo0aQWPchioHiNhvjajtsNA',
    id: 6919,
    acronym: 'UMPO',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'oradeauniversity.co',
    name: 'Oradea University',
    web_page: 'http://www.oradeauniversity.com',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj9O2JlqkeOd9s2G9cIzWNHqOLIX6lNfA7wBrhMcXsT4tG8DXEPeLy6w',
    id: 6920,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'ovidunivconstanta-edu.org',
    name: 'University of Constanta Medical School',
    web_page: 'http://www.ovidunivconstanta-edu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkauhOxPCjxWNPu-23gDmGloDJ4QeBh73pXANnJgzjL90q1naiLkhbhh0',
    id: 6921,
    acronym: 'UCMS',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'pub.ro',
    name: 'University Politehnica of Bucharest',
    web_page: 'http://www.pub.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa9nlZlrpNKa4AV9-rrE61DHP-ZEkTQCIcgbLRb5bHGIvFS7iHTbeIxcQ',
    id: 6922,
    acronym: 'UPB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'rau.ro',
    name: 'Romanian-American University',
    web_page: 'http://www.rau.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnN67qOP_dLbCIcTeXt58A0Oy0AZcNKU0Xd9WKzFfnWQW4bck_HaFf6Hs',
    id: 6923,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'sibiu.ro',
    name: 'University of Sibiu',
    web_page: 'http://www.sibiu.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0GaRLVE-WD3uvsUYJqaDtHicSvNmnpC2L7MI53lPS3dXeykfT0DU001YZ',
    id: 6924,
    acronym: 'US',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'snspa.ro',
    name: 'National School of Political and Administrative Studies Bucharest',
    web_page: 'http://www.snspa.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ0CO_EecMQytfQLL6Logwy5TBHPROn5GoL_EZwD4YjUmag5NOelSQdgoU',
    id: 6925,
    acronym: 'NSPASB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'spiruharet.ro',
    name: 'Spiru Haret University',
    web_page: 'http://www.spiruharet.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM7D18ZVcFG3P13BoSmp-kqcTXK4NS6sbhFX-_hiZXXTjyRKMtzZ5v57A',
    id: 6926,
    acronym: 'SHU',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'tuiasi.ro',
    name: 'Technical University of Iasi',
    web_page: 'http://www.tuiasi.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUJDh06M9YwRcZlM1Y56lCb9P0iRVVdlSgSIg73wVARsXoinS7q9Yr6i-rGw',
    id: 6927,
    acronym: 'TUI',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uab.ro',
    name: '1 December University of Alba Iulia',
    web_page: 'http://www.uab.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtC6uedlOM5ytY9mFEiXr2pwG2uaRU19kFnEWEDawskT4jxYQNXL-D2Q',
    id: 6928,
    acronym: '1DUAI',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uaic.ro',
    name: 'University of Iasi',
    web_page: 'http://www.uaic.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYv9pFrQR5iZ3oCthItIR-zWVBw5DqUVWdyiyrwR3rsoO0iDTG14p67w',
    id: 6929,
    acronym: 'UI',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uartdcluj.ro',
    name: 'University of Art and Design Cluj-Napoca',
    web_page: 'http://www.uartdcluj.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4uAx0AQ6FHJTmMAshi6BP2du3T22gSOLIOJ5dcqE_PXMbaZ5sogv_7A',
    id: 6930,
    acronym: 'UADC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uat.ro',
    name: 'Targu-Mures University of Theatre',
    web_page: 'http://www.uat.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp87io7ObOQb4CEH5VYc97iz8DfuobOuDARfQCac1dtzSqYvwpjd5fCw',
    id: 6931,
    acronym: 'TUT',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uav.ro',
    name: 'University "Aurel Vlaicu" Arad',
    web_page: 'http://www.uav.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbiX5F3Dt6T1-CTL34dYojxnBE6A3OJJAeIs5D7T4wnQ4uPJhGjiwa-ag',
    id: 6932,
    acronym: 'U"VA',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'ubbcluj.ro',
    name: 'Babes-Bolyai University of Cluj-Napoca',
    web_page: 'http://www.ubbcluj.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREqcwEwywSYSrMXXXC8ljNeSuzeD8IBkrs_k6WuCfYzHHmGX-7O6bbMw',
    id: 6933,
    acronym: 'BUC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'ubm.ro',
    name: 'North University of Baia Mare',
    web_page: 'http://www.ubm.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKh3qyzihOqIF8GlaC_if5ny1KCS7hLtQRwO9DCEchTb5dTsJIxFYfTg',
    id: 6934,
    acronym: 'NUBM',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'ub.ro',
    name: 'University of Bacau',
    web_page: 'http://www.ub.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSFZrQfsLz23d9C7hQmPbvxV25JxQfsQG-LEEkdUTHWGokG8Z-508WOiI',
    id: 6935,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uem.ro',
    name: 'University of Resita',
    web_page: 'http://www.uem.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjgzbHpgbIbDkEE5wllizylkNL8jejJqv9eUpIiIHxphG1-mxWlWYOduUx',
    id: 6936,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'ugal.ro',
    name: 'University of Galatzi',
    web_page: 'http://www.ugal.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1Mw1M8c1IdccKc2oWYjnHsoogWS5X2MkcrV46cjSM1lD9g7-rEt4_hpY',
    id: 6937,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'ulbsibiu.ro',
    name: 'University Lucian Blaga of Sibiu',
    web_page: 'http://www.ulbsibiu.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaWs2LSCTgN3aar4JJTfCYP4IMDmorOtWUy0GuZysPjwCk36GCrXGGQac',
    id: 6938,
    acronym: 'ULBS',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'umfcluj.ro',
    name: 'University of Medicine and Pharmacy of Cluj-Napoca',
    web_page: 'http://www.umfcluj.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXOq3_68385PNawdDQx3Y0NNIQVAxXgAAS5xwgtvwp3heKZMFduM5kkJA',
    id: 6939,
    acronym: 'UMPC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'umfiasi.ro',
    name: 'University of Medicine and Pharmacy of Iasi',
    web_page: 'http://www.umfiasi.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVQnMBajlNRsUUrtE3WG0wVO7F6ToqHR_4Hyn7-jufUgH_YR2q_DXKi6E',
    id: 6940,
    acronym: 'UMPI',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'umftgm.ro',
    name: 'University of Medicine and Pharmacy of Targu Mures',
    web_page: 'http://www.umftgm.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtdkqCERbbYNsdmZmMTWlVIWyRzWT5uiBckRYFctrC5tP4ST2awpsIcvg',
    id: 6941,
    acronym: 'UMPTM',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'umft.ro',
    name: 'University of Medicine and Pharmacy of Timisoara',
    web_page: 'http://www.umft.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCogP-aGTOcDySBQJe-MoYcOZpOQR1NCS-kqJ_rPk1rtu8yFZCPMUKuws',
    id: 6942,
    acronym: 'UMPT',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'unarte.ro',
    name: 'National University of Arts',
    web_page: 'http://www.unarte.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiCgQhYBoEhUcoJ-QCYw-Is2k0hbXt01XQtgPE6c9EQlddMO_1GMqu5_c',
    id: 6943,
    acronym: 'NUA',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'unatc.ro',
    name: 'National University of Theater and Film Arts',
    web_page: 'http://www.unatc.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnzezISsQ0Nv5nzG7h9LOtfE2Xa9O2D-71-AyBrujrInApQfn5WNV50TY',
    id: 6944,
    acronym: 'NUTFA',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'unibuc.ro',
    name: 'University of Bucharest',
    web_page: 'http://www.unibuc.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvSW7iyqUEpcjfCcKdcQQirQYSApLyoUHkJl7HzDJuk2G6y_JSSRNzXpA',
    id: 6945,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'unitbv.ro',
    name: 'University "Transilvany" of Brasov',
    web_page: 'http://www.unitbv.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy5tNx59AW1xBq28b_bH27upmPjgl6YT6_GxQ5dUb9i5z_6va6m7Q-7fN-',
    id: 6946,
    acronym: 'U"B',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'univagro-iasi.ro',
    name: 'University of Agriculture and Veterinary Medicine Iasi',
    web_page: 'http://www.univagro-iasi.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROyFEIDPiNGlhzLPALpsGH5Vz8yn8DsHiC7UXSgS58Xjl2Qdw0e6-VX6U',
    id: 6947,
    acronym: 'UAVMI',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'univcb.ro',
    name: 'Constantin Brancoveanu University Pitesti',
    web_page: 'http://www.univcb.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6zmKwCpLHwyHLOLL1bmNdAt9ZZ5YbHxgnKB1W5YSthoPzIZeAxDuxw4ua',
    id: 6948,
    acronym: 'CBUP',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'univermed-cdgm.ro',
    name: 'University of Medicine and Pharmacy of Bucharest',
    web_page: 'http://www.univermed-cdgm.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyW6Iy15YzHPrGYw90b-oL2TNmdXWS-qtpE0weaTBFiQKCNxa-LpdOYZ0',
    id: 6949,
    acronym: 'UMPB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'universitateamaritima.ro',
    name: 'Maritime University Constanta',
    web_page: 'http://www.universitateamaritima.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzTU3ne19uNMz_m034hCHHnJGxEys6hGZfZEoZACVp0uMkbyff2NqRBXY',
    id: 6950,
    acronym: 'MUC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'univ-ovidius.ro',
    name: 'University of Constanta',
    web_page: 'http://www.univ-ovidius.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrrCK1S3FlsT4H4mmc206yhgtkREvPxA0UvbzSI2lHFOUgbc3oBytxWu4',
    id: 6951,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'unmb.ro',
    name: 'National University of Music',
    web_page: 'http://www.unmb.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHIZQ2k363ZAj_32iplq3Tr8FzQwspEUs8rFPzq1TihyIgSr1sjtMwS6I',
    id: 6952,
    acronym: 'NUM',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uoradea.ro',
    name: 'University of Oradea',
    web_page: 'http://www.uoradea.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUS5_4Vip2GEoMUo57IQoZZpJipmZYS_TPDo0aQWPchioHiNhvjajtsNA',
    id: 6953,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'upa.ro',
    name: 'University "Petre Andrei" Iasi',
    web_page: 'http://www.upa.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYv9pFrQR5iZ3oCthItIR-zWVBw5DqUVWdyiyrwR3rsoO0iDTG14p67w',
    id: 6954,
    acronym: 'U"AI',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'upet.ro',
    name: 'University of Petrosani',
    web_page: 'http://www.upet.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUvFuCoXwgO7E0ox6lhMxAxMHO3GqWXaEr_qvLZuFpy6y1l_t9KrUxXnQ',
    id: 6955,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'upg-ploiesti.ro',
    name: 'University Oil- Gas Ploiesti',
    web_page: 'http://www.upg-ploiesti.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZKqrtfqgkPs96UqP31g1IZn8oiJL_9TTDLUEKVSqtiyn-ef-cf21ciQ',
    id: 6956,
    acronym: 'UOGP',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'upit.ro',
    name: 'University of Pitesti',
    web_page: 'http://www.upit.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC-SeTGO5wQYvpYoZQBOCuGyrQZsOFA-2YEFLZVrNbx4nyYRDBhXxzczg',
    id: 6957,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'upm.ro',
    name: 'Petru Maior University of Targu Mures',
    web_page: 'http://www.upm.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIUeKZ_9mQrmRcQ97Y8C0sBFtpKIojovQGfx4jbkxlhfB1CqjVgykLbWjy',
    id: 6958,
    acronym: 'PMUTM',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'usab.ro',
    name: 'University of Agriculture and Veterinary Medicine Bucharest',
    web_page: 'http://www.usab.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX-mwTuW_GL1ICX6GjRz-RLoSdguJpxb4LDq5RjMhbGWVWKHFAYlVrmqxdFQ',
    id: 6959,
    acronym: 'UAVMB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'usab-tm.ro',
    name: "Banat's University of Agricultural Sciences",
    web_page: 'http://www.usab-tm.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX-mwTuW_GL1ICX6GjRz-RLoSdguJpxb4LDq5RjMhbGWVWKHFAYlVrmqxdFQ',
    id: 6960,
    acronym: 'BUAS',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'usab-tm.ro',
    name: 'University of Agriculture and Veterinary Medicine Timisoara',
    web_page: 'http://www.usab-tm.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX-mwTuW_GL1ICX6GjRz-RLoSdguJpxb4LDq5RjMhbGWVWKHFAYlVrmqxdFQ',
    id: 6961,
    acronym: 'UAVMT',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'usamvcluj.ro',
    name: 'University of Agriculture and Veterinary Medicine Cluj-Napoca',
    web_page: 'http://www.usamvcluj.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTS1bTQDm37sj5e4KJ-445e4lUK2kP7Y3NRSCGOlpjA9y3wotwnDmEzEu0',
    id: 6962,
    acronym: 'UAVMC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'usv.ro',
    name: 'University of Suceava',
    web_page: 'http://www.usv.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGXqJEVJDJl-0TwKrhxmx7HvItnX4A3PIGyhog9DkCIhQgAFdpMJwA5NQ',
    id: 6963,
    acronym: 'US',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'utcb.ro',
    name: 'Technical University of Civil Engineering Bucharest',
    web_page: 'http://www.utcb.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0T4UpemFdWfJX7NfwTHUSIHau8lvsR-Kw2ua1gx1JX32pvTfvdNriDuxW',
    id: 6964,
    acronym: 'TUCEB',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'utcluj.ro',
    name: 'Technical University of Cluj-Napoca',
    web_page: 'http://www.utcluj.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYLUDrXxQG-YL678fNmKeGNt0ZWLjAlKMdSuUU3o5XEp_Ihmz1r2Nczc9_vg',
    id: 6965,
    acronym: 'TUC',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'utgjiu.ro',
    name: 'University of Targu Jiu',
    web_page: 'http://www.utgjiu.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSThTjHAH4KO1QNh2w1HuxtgKoTINJZVjSs2S9xXWcgSDn-RBYi249flA',
    id: 6966,
    acronym: 'UTJ',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'utm.ro',
    name: 'University "Titu Maiorescu"',
    web_page: 'http://www.utm.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAXqTTlzbV5ASNanV4Cx3lXAPZ-0mfaJQrrZv0BF350W3I8aQckDvFYqY',
    id: 6967,
    acronym: 'U"M',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'utt.ro',
    name: 'Technical University of Timisoara',
    web_page: 'http://www.utt.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVBn1k2Y0vz0-objHJXToHvJpZEps7w1p3np2bW8a1l9kzsrtv6euH7Nw',
    id: 6968,
    acronym: 'TUT',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uvt.ro',
    name: 'West University of Timisoara',
    web_page: 'http://www.uvt.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmU-cVFfNdKHyT8k8knHjIqRsFvcGlGAKKygrn7WcRQy5XD3QPdh5mkw',
    id: 6969,
    acronym: 'WUT',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'uvvg.ro',
    name: 'Universitatea de Vest "Vasile Goldi&#351;"',
    web_page: 'http://www.uvvg.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSe0YVqKfokxnL2SoINvfCnFYOl3BM9r6-mmnuIPTbFW-n_nFOHtZNpgzl',
    id: 6970,
    acronym: 'UDV"G',
  },
  {
    alpha_two_code: 'RO',
    country: 'Romania',
    domain: 'valahia.ro',
    name: 'Valahia University of Targoviste',
    web_page: 'http://www.valahia.ro/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8kbHK9CkqDvVy5fYBoxUDWK0wsDbs5i2tIVdwu51McC6Eg2-YxWin3tja',
    id: 6971,
    acronym: 'VUT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'academiaga.ru',
    name: 'St. Petersburg State University of Civil Aviation',
    web_page: 'http://www.academiaga.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtSKXB4RFYtjvxhmaAIToSLDYsMT5s-N99L8vP2MGOsacj7JSWKa-mpmw',
    id: 6972,
    acronym: 'SPSUCA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'adygnet.ru',
    name: 'Adygeja State University',
    web_page: 'http://www.adygnet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkLRyR4jsauhV8-beEFec8qfn1GLGDTtxljH2VsaLOJNe73XisQOZ6tl8',
    id: 6973,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ael.ru',
    name: 'Khabarovsk State Academy of Economics and Law',
    web_page: 'http://www.ael.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWgqSYpI1aZHHsPinG5EyxyJluhnmji6XqEP3Ne1PXuiWNU0hTJwrqM9a_',
    id: 6974,
    acronym: 'KSAEL',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'agma.astranet.ru',
    name: 'Astrahan State Medical Academy',
    web_page: 'http://agma.astranet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMRNgtbZj3n34cx-mBYIwSxJ6RdEAOFahCGdtWpBjQf2jNHXT3stqEegM',
    id: 6975,
    acronym: 'ASMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'agtu.ru',
    name: 'Arhangelsk State Technical University',
    web_page: 'http://www.agtu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0LRfGj3747mCwStRjrKC4H53P9hyfQ8b08t5DbfhPU7Q18ODVgadCyWo',
    id: 6976,
    acronym: 'ASTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ame.ru',
    name: 'Academy of Management and Entrepreneurship',
    web_page: 'http://www.ame.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUAeGGZCBFYMNxOLftTJEXLr9qY3JspNJgOfVQARg1P3E3GATAxqkwfMI',
    id: 6977,
    acronym: 'AME',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'amursu.ru',
    name: 'Amur State University',
    web_page: 'http://www.amursu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc91X0FtruvlDDmVRbPkGd51NYvbzr8YY1xaIY6qIGSmFlGwrbpc8o3A',
    id: 6978,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'aqua.sci-nnov.ru',
    name: 'Volga State Academy of Water Transport',
    web_page: 'http://www.aqua.sci-nnov.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdnv_32LPL8MgKioS-bnbhEeLx3p90hBcrkDM5DiAZsQG139MkyFT1W5cW',
    id: 6979,
    acronym: 'VSAWT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'artacademy.spb.ru',
    name: 'St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture',
    web_page: 'http://artacademy.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk8-3BO9y9hmMDGhYh-pO-e168oJv_YrDSu24YL3zHzqitrJ2MIlLqyIo',
    id: 6980,
    acronym: 'SPRSAIPSA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'asmu.ru',
    name: 'Altai State Medical University',
    web_page: 'http://www.asmu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkLRyR4jsauhV8-beEFec8qfn1GLGDTtxljH2VsaLOJNe73XisQOZ6tl8',
    id: 6981,
    acronym: 'ASMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'aspu.ru',
    name: 'Astrakhan State University',
    web_page: 'http://www.aspu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCr0wy88eO2Qw5Bf4yHccc3LYDY6zet9czpxERKZmJFmhBDBJF1LRZcNkw',
    id: 6982,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'astu.org',
    name: 'Astrahan State Technical University',
    web_page: 'http://www.astu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuppQ-PJVtpRE1YFKHA_6agMLdtHD0sU8fUKUBmFD-yj5Iyy_JvETK5PM',
    id: 6983,
    acronym: 'ASTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'astu.secna.ru',
    name: 'Altai State Technical University',
    web_page: 'http://www.astu.secna.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoYacILYtH_plImNXo0Na67EX3n4-UE-VRE-VaJbJ5byYJOgUhT-Ox8XM',
    id: 6984,
    acronym: 'ASTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'asu.ru',
    name: 'Altai State University',
    web_page: 'http://www.asu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkLRyR4jsauhV8-beEFec8qfn1GLGDTtxljH2VsaLOJNe73XisQOZ6tl8',
    id: 6985,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'avtlg.ru',
    name: 'Volgograd Medical Academy',
    web_page: 'http://www.avtlg.ru/~vlgmed/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8-LRmLzIJHPowjqe43_MCpo-r-VcOO5X_ormS3IEMyo1k4Q_Y9OGfYRg',
    id: 6986,
    acronym: 'VMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bashedu.ru',
    name: 'Bashkir State Agrarian University',
    web_page: 'http://www.bashedu.ru/vuz/bgau_e.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStuzCXGt9Irc9JnIqdhJBNzk1e2_lDp-ueHrAOYKBi-T8XauCzDkhqLCru',
    id: 6987,
    acronym: 'BSAU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bashedu.ru',
    name: 'Bashkir State Medical University',
    web_page: 'http://www.bashedu.ru/vuz/bgmu_e.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStuzCXGt9Irc9JnIqdhJBNzk1e2_lDp-ueHrAOYKBi-T8XauCzDkhqLCru',
    id: 6988,
    acronym: 'BSMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bashedu.ru',
    name: 'Bashkir State University',
    web_page: 'http://www.bashedu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYBxG3k98-IcXSgwdI1vSVEJ3U1GZz72MZ7apC3lG17HbpX7eJgpsC6JeM',
    id: 6989,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bashedu.ru',
    name: 'Birsk State Pedagogical Institute',
    web_page: 'http://www.bashedu.ru/vuz/birskgpi_e.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS02bKta3Fm47EJ2bKv7luMfRfjgEzLHiU_3PH-UEh90qfFRM2Hko7sJwvr',
    id: 6990,
    acronym: 'BSPI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bashedu.ru',
    name: 'Sterlitamak State Pedagogical Institute',
    web_page: 'http://www.bashedu.ru/vuz/sgpi_e.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaZe3AZn0fu06wjlQkGiey7NuzdYcjYInf1wij8GVTalAQw9voEQr2bNQ',
    id: 6991,
    acronym: 'SSPI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bashedu.ru',
    name: 'Ufa State Institute of Arts',
    web_page: 'http://www.bashedu.ru/vuz/ugii_e.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqUdZHrlzdd_TPKkawLjlAfX50yHkw0GNjFjAxKx1vlfq2AjHeas7jO8k',
    id: 6992,
    acronym: 'USIA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bigpi.biysk.ru',
    name: 'Biysk State Padagogical University after V.M. Shukshin',
    web_page: 'http://www.bigpi.biysk.ru/wwwsite/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV3LE7mVkN_XGVApx2cjNVK6Z5rwEIUzqKe7d8_rJA5PL2OMOab3ulQbU',
    id: 6993,
    acronym: 'BSPUAVS',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bmstu.ru',
    name: 'Bauman Moscow State Technical University',
    web_page: 'http://www.bmstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrGw4p3l54ZH5PKo4cwkHZEMv1MN6JPmMqaT2tCO6zfuRMG2Vo2_HyZiM',
    id: 6994,
    acronym: 'BMSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'brstu.ru',
    name: 'Bratsk State Technical University',
    web_page: 'http://www.brstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYcp3GhVhE5FbK4VBJ5VSU13dGczohabSRKEHJXIgo9aND4BOKolO0NsI',
    id: 6995,
    acronym: 'BSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bsaa.ru',
    name: 'Belgorod State Agricultural Academy',
    web_page: 'http://www.bsaa.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtTyUy7MbFCF8oNzdl1m6rdW-NMPHHKPC7h0qvYOyqOiI3RgkBmy5RnpY',
    id: 6996,
    acronym: 'BSAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bspu.ru',
    name: 'Bashkir State Pedagogical University named After M. Akmullah',
    web_page: 'http://www.bspu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJaYyOOVo1bGOC3j8lhu9feU0FEz99CQuP213Lfp2HXa6nbQiylySMpo',
    id: 6997,
    acronym: 'BSPUNAMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bstu.ru',
    name: 'Belgorod State Technical University',
    web_page: 'http://www.bstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfCwwpKFhVuJHd96xhAXjsRuxSIZyf0X5szDd4NdrDpl7_Yz91y_bcTX8',
    id: 6998,
    acronym: 'BSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bsu.burnet.ru',
    name: 'Burjat State University',
    web_page: 'http://www.bsu.burnet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyi0JjhS82NV5_69owugvz5c_yZuNwL7DgqzcW5WV3xR0BIN7BCPj437j7',
    id: 6999,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'bsu.edu.ru',
    name: 'Belgorod State University',
    web_page: 'http://www.bsu.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9bqG4OvbAPJpoRr0aR2vW1J8D6SieXaJUp2lkk3HixzytKiDlW07_Xqw',
    id: 7000,
    acronym: 'BSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'chat.ru',
    name: 'Moscow External University of the Humanities',
    web_page: 'http://www.chat.ru/~megu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyHWJ8EcnhOafgp_cKYs8AXa_ivc_Hz3shmSw01E0C1y6vjK1uyFzu368',
    id: 7001,
    acronym: 'MEUH',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'chuvsu.ru',
    name: 'Chuvash State University',
    web_page: 'http://www.chuvsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_LtL6wlV7JYw114MQHnC47xHY-yrb31-Y8tDV2QsTb5rCSRlt0zLcIEke',
    id: 7002,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'cit.drbit.com.ru',
    name: 'Novosibirsk State Academy of Economics and Mangement',
    web_page: 'http://cit.drbit.com.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTp_khbV612zgAAuAVeLtC_IwL-GA5zkrjd66j7C64uQcKfwSDmYIVKVw',
    id: 7003,
    acronym: 'NSAEM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'conservatoire.ru',
    name: 'Novosibirsk State Music Academy M. Glinka',
    web_page: 'http://www.conservatoire.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRycBRsaJmuVBsbYwaBvz5_swtCmGMEKdlt3q0qE9kAzj0wLu4p5r2W83I',
    id: 7004,
    acronym: 'NSMAMG',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'conservatory.ru',
    name: 'St. Petersburg State Conservatory',
    web_page: 'http://www.conservatory.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKgO_kT1Vg3AT38ceg_RSzeaPz5DHkkQ0R_5SRFlWPoqz7Wq2Xpd8KBA',
    id: 7005,
    acronym: 'SPSC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'cspi.urc.ac.ru',
    name: 'Cheljabinsk State Institute of Teacher Training',
    web_page: 'http://cspi.urc.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP36XyknZHKMVfkszgH4KHHT2-kwWpI7NTmFbEi-AZebjX21KEe3eYRKU',
    id: 7006,
    acronym: 'CSITT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'csu.ac.ru',
    name: 'Cheljabinsk State University',
    web_page: 'http://www.csu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj2lOaZdiLr7AWLXvgbE4KRQ7B-cBN-yoPBpHc1cmM4LRH_Sl1G-DmPw',
    id: 7007,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'dgu.ru',
    name: 'Dagestan State University',
    web_page: 'http://www.dgu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOB9uk0PwO5rC7QsyNcD57cOmuAXgw9U9LbnRP2PAREvIuduiU02ruEn4',
    id: 7008,
    acronym: 'DSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'dongau.ru',
    name: 'Don State Agrarian University',
    web_page: 'http://www.dongau.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQErjwFFm_YHXF0VVGU4pPLaOqvw2U17hEjVoFSWRyVlwGTE1rsZJbjb3an7Q',
    id: 7009,
    acronym: 'DSAU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'dstu.edu.ru',
    name: 'Don State Technical University',
    web_page: 'http://www.dstu.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5zFClkrzn_Oi3UouOtMicE1M0uCKM6SKrEVTftTGeewGuuxj5GAPBK8A',
    id: 7010,
    acronym: 'DSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'dvgups.ru',
    name: 'Far East State Transport University',
    web_page: 'http://www.dvgups.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEvlcsVv9l3_AVbBNrnG96X2NhHM24_uKViLB-vmy5ggRKw8KbFmTZkA',
    id: 7011,
    acronym: 'FESTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'dvgu.ru',
    name: 'Far Eastern State University',
    web_page: 'http://www.dvgu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEvlcsVv9l3_AVbBNrnG96X2NhHM24_uKViLB-vmy5ggRKw8KbFmTZkA',
    id: 7012,
    acronym: 'FESU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'education.ivanovo.ru',
    name: 'Ivanovo State Textile Academy',
    web_page: 'http://education.ivanovo.ru:8101/IGTA/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKOaS_clYBLkkvzmTVyDmtacSBz2AP_S3ruPb82wTCuEHUa1gJC2l1QIo',
    id: 7013,
    acronym: 'ISTA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'elsu.ru',
    name: 'Yelets State University',
    web_page: 'http://www.elsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXSvIUkQUyQzI11I-Jyb4Rwr54TKK5soCwK3NWJP5R9pi0oqAvc-Kzpx0',
    id: 7014,
    acronym: 'YSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'eltech.ru',
    name: 'St. Petersburg State Electrotechnical University',
    web_page: 'http://www.eltech.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm3ubRG_PslcRNFu8PfuwsSiAcCpJQzvN2KHXnRjPSAvRC_apx31k1o8o',
    id: 7015,
    acronym: 'SPSEU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'engec.ru',
    name: 'St. Petersburg State Academy for Engineering Economics (ENGECON)',
    web_page: 'http://www.engec.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnuHPqc8T755lR1PT2F4E4e7JSsUzosW40eGhqMDmxYuLEwQQPRh4I1g',
    id: 7016,
    acronym: 'SPSAFEE(',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'engec.ru',
    name: 'St. Petersburg State University of Engineering and Economics',
    web_page: 'http://www.engec.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLh6Vfe3YOcpip4TewSztBan4Cnd_L4mwuKd7yxXU4GO5cnpHwH2I0NK4',
    id: 7017,
    acronym: 'SPSUEE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'esstu.ru',
    name: 'East-Siberian State University of Technology',
    web_page: 'http://www.esstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwF0VCK5Z45Origv9O0VPUotwVx2slj3b3NNi_XLBALLXtTuXEHf2OFkQ',
    id: 7018,
    acronym: 'ESUT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'eu.spb.ru',
    name: 'European University at St.Petersburg',
    web_page: 'http://www.eu.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdCOYgwIIB3veZdcwI7s_g5hK-5sbWhaylAtV5Ms5-qa0Z82rpLXOP-w',
    id: 7019,
    acronym: 'EUAS',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'fa.ru',
    name: 'Finance Academy',
    web_page: 'http://www.fa.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR66eVmBcwYlU9r9cZB0DDOpR2M5_Vx7iwZWZxnLhEuHm22Fnshc4ExFg',
    id: 7020,
    acronym: 'FA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'festu.ru',
    name: 'Far Eastern State Technical University',
    web_page: 'http://www.festu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEvlcsVv9l3_AVbBNrnG96X2NhHM24_uKViLB-vmy5ggRKw8KbFmTZkA',
    id: 7021,
    acronym: 'FESTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'filine.centro.ru',
    name: 'Maxim Gorky Institute of Literature',
    web_page: 'http://filine.centro.ru/Gorky/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3xHDJlajl9p-ZcQYty5NM3r9yiaUH1nNfIKBpSCpnPtcg7dR2jLpuqgoE',
    id: 7022,
    acronym: 'MGIL',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ftacademy.ru',
    name: 'St. Petersburg State Forest-Technical Academy',
    web_page: 'http://www.ftacademy.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZaJPsPYC5owF7QXQCBef6jNsodboef0qhdMuuOgJoRJRzSfg0Vsgq6zc',
    id: 7023,
    acronym: 'SPSFA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gasu.ru',
    name: 'Gorno-Altaisk State University',
    web_page: 'http://www.gasu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQJXMADQYeiqcZyqB4rLFpFTcZFYXE1mX0FVgt3EliPdQTeQAfVUbtQPw4',
    id: 7024,
    acronym: 'GSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gitis.net',
    name: 'Russian Academy of Theatre Arts',
    web_page: 'http://www.gitis.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTk8K9jq4-9s4jZRhBiB6ABXQAGyXzL8qObF8nSIg4jpSYUx_LOU7j6YA',
    id: 7025,
    acronym: 'RATA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gma.ru',
    name: 'State Maritine Academy',
    web_page: 'http://www.gma.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf9vwr6xm0b3N5dP3JfVQL_OfRGau4sJ_wsEor8zfVVqi3TGvpx46-a00',
    id: 7026,
    acronym: 'SMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gnesin-academy.ru',
    name: 'Gnesins Russian Academy of Music',
    web_page: 'http://www.gnesin-academy.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPGUhvfnYhGxZ5RzT5CuBNO9IgGLhZsEpNwbt4l7Ud7EydvLvSd97fcD8',
    id: 7027,
    acronym: 'GRAM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gorny-ins.ru',
    name: 'St. Petersburg State Mountain Institut',
    web_page: 'http://www.gorny-ins.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIdeknQGZUki0xKxVsPzselfJcV24QuaVdQ5u18b9HtixKg_4-MvkLpHE',
    id: 7028,
    acronym: 'SPSMI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gpma.ru',
    name: 'St. Petersburg State Pediatric Medical Academy',
    web_page: 'http://www.gpma.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRU8sHUzuQb-Fj93gl2JIMYR-RENHxjEOrfJrJt5U1o_NAGBfcwrd6kog',
    id: 7029,
    acronym: 'SPSPMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gti.spb.ru',
    name: 'St. Petersburg State Institute of Technology (Technological University)',
    web_page: 'http://www.gti.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLh6Vfe3YOcpip4TewSztBan4Cnd_L4mwuKd7yxXU4GO5cnpHwH2I0NK4',
    id: 7030,
    acronym: 'SPSIT(U',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gturp.spb.ru',
    name: 'St. Petersburg State Technological University of Plant Polymers',
    web_page: 'http://www.gturp.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWV_h2GtCrDlohTEcqB0PN9i57UnscZj1uZveqmTlfYaaCAkihvT28EAM',
    id: 7031,
    acronym: 'SPSTUPP',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gubkin.ru',
    name: 'Gubkin Russian State University of Oil and Gas',
    web_page: 'http://www.gubkin.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg5rTPBF0bdb8TFuT8ZkD-ThgPeRfA3AVCFEzXKnCTFFsKjX26BmRfkA',
    id: 7032,
    acronym: 'GRSUOG',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'gukit.ru',
    name: 'St. Petersburg State Cinema and TV University',
    web_page: 'http://www.gukit.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIdeknQGZUki0xKxVsPzselfJcV24QuaVdQ5u18b9HtixKg_4-MvkLpHE',
    id: 7033,
    acronym: 'SPSCTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'guu.ru',
    name: 'State University of Management',
    web_page: 'http://www.guu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR39m-8RrfQOmknWb5jeLGOuICmNzc3dJNx4s9g2GqJTkLCfHGZKbvt-g',
    id: 7034,
    acronym: 'SUM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'herzen.spb.ru',
    name: 'Herzen State Pedagogical University of Russia',
    web_page: 'http://www.herzen.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgblDs6-Br6ByCCxmVhuAohEXyyVvwJSVBWeDoMUmAP5FVQC57CSfyTS0jkw',
    id: 7035,
    acronym: 'HSPUR',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'hse.ru',
    name: 'Higher School of Economics',
    web_page: 'http://www.hse.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ0zpaYFzWFh387PlflHq-uqN2crFZYvXQXux4hgi-4d8kbKNBmQJdjw',
    id: 7036,
    acronym: 'HSE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'iate.obninsk.ru',
    name: 'Obninsk State Technical University for Nuclear Power Engineering',
    web_page: 'http://www.iate.obninsk.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI_fsFKB-6wXAwNAztim7MS9tlz336UC7HEpUPnHG2GgtCgmU_Jr0eRg_D',
    id: 7037,
    acronym: 'OSTUFNPE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ibi.spb.ru',
    name: 'International Banking Institute St. Petersburg',
    web_page: 'http://www.ibi.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGkSJajZ7kqFjADHyHUNwHZKyzrcsI_8owsnwP0dgIh22Kso-cOJV0WKyO',
    id: 7038,
    acronym: 'IBISP',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ibp-moscow.ru',
    name: 'Institute of Business and Politics',
    web_page: 'http://www.ibp-moscow.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNKEPxCJHKLEKJqagoFhN4vps5e2hderHVEhHKfCqTUZcFUl3RdN16Zsc',
    id: 7039,
    acronym: 'IBP',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ieeu.udm.ru',
    name: 'International East-European University',
    web_page: 'http://www.ieeu.udm.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ3012NXpSLqWdnxf1_J8PjOTWUiHMokXSLBz9ZSKRHoX7021-Ra8i2T0',
    id: 7040,
    acronym: 'IEU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'iet.ru',
    name: 'Institute for the Economy in Transition',
    web_page: 'http://www.iet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgppD7jWDlf_-1ko5inKOZKNm0pdwGHBTyMxuvBITezlzjIZFelkgT1g',
    id: 7041,
    acronym: 'IFEIT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ifmo.ru',
    name: 'St. Petersburg State University of Information Technologies Mechanics and Optics',
    web_page: 'http://www.ifmo.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPwB4H0khid929ZV-M0g6uMhc04lpFYIeFHdgc-AjUwprKfjKtV8Lj9A',
    id: 7042,
    acronym: 'SPSUITMO',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ime.ru',
    name: 'Institute of Management and Economy',
    web_page: 'http://www.ime.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmMUZoluaQcqQ4SB1uHVgVoPL35xnCuL6SGY5xr7GalQIrh3e8jkF8l7A',
    id: 7043,
    acronym: 'IME',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'intstudy.mai.ru',
    name: 'State University of Aerospace Technologies',
    web_page: 'http://www.intstudy.mai.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC7IUqOTGpw6UIw-pH-ki5t9_L8Cm_nK1PXhUrzu5LlqJInq-YZkHtnw',
    id: 7044,
    acronym: 'SUAT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'isea.irk.ru',
    name: 'Irkutsk State Academy of Economics',
    web_page: 'http://www.isea.irk.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9_vS1zoU97kktpRDKwWEnd7A2CHzE58SikH7h7oQNl1OqmijR9Scp-2M',
    id: 7045,
    acronym: 'ISAE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'isea.ru',
    name: 'Baikal National University of Economics and Law',
    web_page: 'http://www.isea.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXNktfIxiBoln_tjk34fPIlv-A8afkHLiZTHTXOh200Mh7bZSxvhDvGA',
    id: 7046,
    acronym: 'BNUEL',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'isma.indi.ru',
    name: 'Ivanovo State Academy of Medicine',
    web_page: 'http://isma.indi.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4-B9nZplPe78queYClmgeXPQhafk-rmq2uqkafZ_an6dIgmJN6W9OfQ',
    id: 7047,
    acronym: 'ISAM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ispu.ru',
    name: 'Ivanovo State Power University',
    web_page: 'http://www.ispu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEGPYXsmeD5_4XanP8W1t7e5THDgndUUZghDLBmsMccPzLBro5gzO1gdc',
    id: 7048,
    acronym: 'ISPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'istu.irk.ru',
    name: 'Irkutsk State Technical University',
    web_page: 'http://www.istu.irk.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFngEgPkpf5e_KL6B-ywObvAx_8uFx-xNCAjn_5vXWXMR2doJiKigythkB',
    id: 7049,
    acronym: 'ISTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'istu.ru',
    name: 'Izhevsk State Technical University',
    web_page: 'http://www.istu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk0vSyvTy7noCA828Y9dvES4QqjbQUnLbUpTw9ruDAwOz8vVS5ud61qg',
    id: 7050,
    acronym: 'ISTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'isuct.ru',
    name: 'Ivanovo State University of Chemistry and Technology',
    web_page: 'http://www.isuct.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsZMNhsnNKiD2AkoQ1pmV0Che1jtq2s5AHoqpYoggQxJ6YUosJbOE0YeY',
    id: 7051,
    acronym: 'ISUCT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'isu.ru',
    name: 'Irkutsk State University',
    web_page: 'http://www.isu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB1JXpmLU2w4oFhiZESilpY-zjhZnsC3wQ3eeTJIS5_RepEKAXxgm64QzB',
    id: 7052,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'iubnt.yar.ru',
    name: 'Yaroslavl International University of Business and New Technologies',
    web_page: 'http://www.iubnt.yar.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTJU8Yx8xtMBr1nPDpKyCXgOA8u_mhJSVnm-BZRrnVCCrBXO9nVs6j1LA',
    id: 7053,
    acronym: 'YIUBNT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'iufs.edu',
    name: 'International University of Fundamental Studies St. Petersburg',
    web_page: 'http://www.iufs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQB1_QP3Ch8Y8B-FLR4iu7Zd9_DNO8W3o0GvZCXNSDZzZyegJPmbZbyx8oS',
    id: 7054,
    acronym: 'IUFSSP',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ivanovo.ac.ru',
    name: 'Ivanovo State University',
    web_page: 'http://www.ivanovo.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTu3s-LPVb1H71QpGAu9wkguNYN-2urSyXxFMJ2FkSNXnq6xPxG6lK5CeHy',
    id: 7055,
    acronym: 'ISU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'jum.ru',
    name: 'Jewish University in Moscow',
    web_page: 'http://www.jum.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5Kv8VOuAjdk8e6exsTaLBWK40Wf1tRzW3jRoMLdvL7Y-9wxd7jbc46A',
    id: 7056,
    acronym: 'JUIM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kai.ru',
    name: 'Kazan State University of Technology',
    web_page: 'http://www.kai.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBMy-32trGqBfaiEeo9cc7wH_ca5yKUIxrVVHNge1VJ5h8mAPF02Zxjw',
    id: 7057,
    acronym: 'KSUT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kalmsu.ru',
    name: 'Kalmyk State University',
    web_page: 'http://www.kalmsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXs6qunOcBztx9SvOcWJR9kntQzLhIF0bUqfclTYzpqBhn3BqlFpyNVqc',
    id: 7058,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kamgu.ru',
    name: 'Kamchatka State University Vitus Bering',
    web_page: 'http://www.kamgu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjZE1SmHcOjXL8p35StbBTGQc0KPC_dmSN6RlJssKbm9Z3IE-_Zw6er4k',
    id: 7059,
    acronym: 'KSUVB',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kantiana.ru',
    name: 'Immanuel Kant State University of Russia',
    web_page: 'http://www.kantiana.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxgGo1twnw6boRuyRv4JwWicHCNFaTSROHbAeKYoA4etUAYWiOApuQyA4',
    id: 7060,
    acronym: 'IKSUR',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'karelia.ru',
    name: 'Petrozavodsk State University',
    web_page: 'http://www.karelia.ru/psu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjMWg_rsGwwmq_7YrCRmestSMgFLG_Y2nPyfvgx-8m50QKS7NE_h_jAz3FRw',
    id: 7061,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kazanconservatory.ru',
    name: 'Kazan State Music Conservatory',
    web_page: 'http://www.kazanconservatory.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7LJ9lwOVLkyQTzEv09PMuN5-9vj-B6y_skHQTgJTdXhSLIvdeGMxaVw',
    id: 7062,
    acronym: 'KSMC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kbsu.ru',
    name: 'Kabardino-Balkarian State University',
    web_page: 'http://www.kbsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCNWIuXkIeI_uIbiRQLlMr5KwLBS3HbPvao3kXEMp_aApFqgjcaLpncfbh',
    id: 7063,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kemsu.ru',
    name: 'Kemerovo State University',
    web_page: 'http://www.kemsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe3HxaDx9RWu5ogqvsdvHhT1fLPgHQbnOP8zhEAK_oCvbAg_H6aNVgeAk',
    id: 7064,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kgmu.kcn.ru',
    name: 'Kazan State University of Medicine',
    web_page: 'http://www.kgmu.kcn.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBLuyxOKzqvYWYvCGWD3nPLAnETcihgB8fFEEwUz72JvQ9li9PFVHpWuI',
    id: 7065,
    acronym: 'KSUM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kgpu.ru',
    name: 'Kazan State Pedagogical University',
    web_page: 'http://www.kgpu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRr7Osgw9nGn16fRRGX34mJlTRF--0C0ltMywkuK9_8VdLVr0EEL1gfcQ',
    id: 7066,
    acronym: 'KSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kgtu.runnet.ru',
    name: 'Krasnoyarsk State Technical University',
    web_page: 'http://www.kgtu.runnet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3D5mSrTOv_PEXgXflbPsi7QwHmquiRIW9tc3iRgIZlskK4tNpJvy1yEc',
    id: 7067,
    acronym: 'KSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'khspu.ru',
    name: 'Far Easten State University of Humanities',
    web_page: 'http://www.khspu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc91X0FtruvlDDmVRbPkGd51NYvbzr8YY1xaIY6qIGSmFlGwrbpc8o3A',
    id: 7068,
    acronym: 'FESUH',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'khstu.ru',
    name: 'Khabarovsk State University of Technology',
    web_page: 'http://www.khstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGtgVem2hyxG3SamLhp00dOVSlaEK4R3Vgbm45kJSeUtOiZeX-w_RaDxw',
    id: 7069,
    acronym: 'KSUT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kirovballet.com',
    name: 'Vaganova Academy of Russian Ballet',
    web_page: 'http://www.kirovballet.com/vaganova.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsKgLJ-Y5rc0OvipcGCxMoS86yX0W7qMWtebOGLqdAayWPXcI5ftScVMA0',
    id: 7070,
    acronym: 'VARB',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kiu.ru',
    name: 'Kurgan International University',
    web_page: 'http://www.kiu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ_xDkpMr26ARlI800lYE_ZE4WOfVD46qwKlAJa1TJgovvJglKCprxsg',
    id: 7071,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'klgtu.ru',
    name: 'Kaliningrad State Technical University',
    web_page: 'http://www.klgtu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgZ8_fZskUO6yZbhLqmXRHtCW_GEAha_fFylkxFf-UAThAWW6LjZTd8GI',
    id: 7072,
    acronym: 'KSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'knastu.ru',
    name: 'Komsomolsk-on-Amur State Technical University',
    web_page: 'http://www.knastu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3BsOU7b0bOiMb9VaGg9Wp9km0ykrvDeEaFezt7vmpth8eMZSkzvR34Q',
    id: 7073,
    acronym: 'KSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ksaba.ru',
    name: 'Kazan State Academy of Architecture and Civil Engineering',
    web_page: 'http://www.ksaba.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-cEWBfMG_2GY2ZvergfB1dvJueqmGwHDdVUXB0iKQ0Rs6ss_BBsyBwz7x',
    id: 7074,
    acronym: 'KSAACE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ksfei.ru',
    name: 'Kazan State Finance and Economics Institute',
    web_page: 'http://www.ksfei.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmzihTxuTbx2ZClNQs1wwLYgj35LYkvNm0-Pi2o0nlIG0iwR4Rsv4V_sw',
    id: 7075,
    acronym: 'KSFEI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ksmu.kursknet.ru',
    name: 'Kursk State Medical University',
    web_page: 'http://www.ksmu.kursknet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDgc8-2s3uy626oQfLceK09ogTipTTOCG-ZYQSK4-iyEYU93ub_JXe3g',
    id: 7076,
    acronym: 'KSMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kspu.kaluga.ru',
    name: 'Kaluga State Pedagogical University',
    web_page: 'http://kspu.kaluga.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAMlzen8LZfG_x2SdOqKFis38rRxL9coVn8vMXiaCADvqzFe0HPgOl6Rc',
    id: 7077,
    acronym: 'KSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kspu.ptz.ru',
    name: 'Karelian State Pedagogical University',
    web_page: 'http://kspu.ptz.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQZAI-v_k15uFWn99a-NCuBjEuihj33F2nAnwrESfLSfkq_dKyM-u7PA',
    id: 7078,
    acronym: 'KSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kstu.edu.ru',
    name: 'Kostroma State Technological University',
    web_page: 'http://www.kstu.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNw3CShP_8Tq9PULV5Yk3604ntZBpA_qUhedLRy7mSygLoMSigyilHAw',
    id: 7079,
    acronym: 'KSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kstu.kursk.ru',
    name: 'Kursk State Technical University',
    web_page: 'http://www.kstu.kursk.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsjb7o_6ZUa2EN4Hfzjsd6o0IowpG24P-H8Jnbf40wlQa-0tf1gHFwuuGy',
    id: 7080,
    acronym: 'KSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ksu.ru',
    name: 'Kazan State University',
    web_page: 'http://www.ksu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBMy-32trGqBfaiEeo9cc7wH_ca5yKUIxrVVHNge1VJ5h8mAPF02Zxjw',
    id: 7081,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kubagro.ru',
    name: 'Kuban State University of Agriculture',
    web_page: 'http://www.kubagro.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDdFS2aZb3ZGko8XvC5Wvgfk77JyuSd6pRQtHDVaKvyHnBQQk_tYIwgyE',
    id: 7082,
    acronym: 'KSUA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kubstu.ru',
    name: 'Kuban State University of Technology',
    web_page: 'http://www.kubstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScGUfZAGVC3qHBo3KsgaJXvUjTBxTQax9ja5GoZ1pSTq_ei52lUES5iJI',
    id: 7083,
    acronym: 'KSUT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kubsu.ru',
    name: 'Kuban State University',
    web_page: 'http://www.kubsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScGUfZAGVC3qHBo3KsgaJXvUjTBxTQax9ja5GoZ1pSTq_ei52lUES5iJI',
    id: 7084,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kursk-uni.ru',
    name: 'Kursk State University',
    web_page: 'http://www.kursk-uni.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlaZa4vwgxJjYYtOS1xapdLx7R67ZmJRjrjX1lXH1x1duDgJVrRIdwCA',
    id: 7085,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'kuzstu.ac.ru',
    name: 'Kuzbass State Technical University',
    web_page: 'http://www.kuzstu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgkXUX5PHdEPt9sGTC36HTOeqDLX0YGWboOsHvSJNfmerFA9I8WXnl9Q',
    id: 7086,
    acronym: 'KSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'lunn.sci-nnov.ru',
    name: 'Linguistic University of Nizhny Novgorod',
    web_page: 'http://www.lunn.sci-nnov.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMxYEeT19R3XKifmXxLlhjcmqmjmfayRoU49XfgNdIH7mOafXei-q90d4',
    id: 7087,
    acronym: 'LUNN',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'madi.ru',
    name: 'Moscow State Automobile and Road Technical University',
    web_page: 'http://www.madi.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-BAgXAbKDFT6m-_6Rybqo8acGKkFMwAH51oo7N-sjX4RuQhNsYsDcqQ',
    id: 7088,
    acronym: 'MSARTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'margu.mari.ru',
    name: 'Marij State University',
    web_page: 'http://margu.mari.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiPOkC3RrzOfvNlT8RCqEnyTaTHuv7ROG9Qs4zq0F1FV9buJe5J6O-B6E',
    id: 7089,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'marstu.mari.ru',
    name: 'Marij State Technical University',
    web_page: 'http://www.marstu.mari.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTCGwepKMPxasVGiFBvdf-nUcuCGMo_Db3hhQu839zUQ3R-wFbLqe4oQ',
    id: 7090,
    acronym: 'MSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'masu.ru',
    name: 'Magnitogorsk State University',
    web_page: 'http://www.masu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbKBp04idDkjs_ptK8OZzrHXa55mAI8KeYdsxEMzNC1p9-zVEZUwA-lRw',
    id: 7091,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mechnik.spb.ru',
    name: 'St. Petersburg State Medical Academy',
    web_page: 'http://www.mechnik.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEjLlcS79tD7jm8j2PvKblH1wxsKPNjnuUe7ymIM_0LYtLDmtZG4lmMQw',
    id: 7092,
    acronym: 'SPSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'medin.nsc.ru',
    name: 'Novosibirsk State Medical Academy',
    web_page: 'http://www.medin.nsc.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGMLDspq85Paf5qGU2vXxgyzCm_bpEp6wzL38iS5cKtbuOhgnfwtiJDE8',
    id: 7093,
    acronym: 'NSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'med.sgu.ru',
    name: 'Saratov State Medical University',
    web_page: 'http://www.med.sgu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDHnbt7YBXxRqSaHWuShRAabOdrE0Gr_FegRiNMtVMs1P362PYjIJTaCqE',
    id: 7094,
    acronym: 'SSMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mesi.ru',
    name: 'Moscow State University of Economics Statistics and Informatics',
    web_page: 'http://www.mesi.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYoXoH5dZGq2trrDG5Ncrbp46MAjrbw1HIBG6k_kmieCxFcYsW6zAAieg',
    id: 7095,
    acronym: 'MSUESI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mgavm.ru',
    name: 'Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology',
    web_page: 'http://www.mgavm.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJngc-BdfOftN3tE15CtRJ-brIjxzb6c5x-rfL0mj9B9bU4fUlyc6Nqw',
    id: 7096,
    acronym: 'SMSAVMB',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mgimo.ru',
    name: 'Moscow State Institute of International Relations MFA Russia (MGIMO-University)',
    web_page: 'http://www.mgimo.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSn2yqJ9lyfbs_vbk5wGvju_W7MhNApekZqMDNauqNOU3O_Y1XJgPE3NpFVeg',
    id: 7097,
    acronym: 'MSIIRMR(',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mgri-rggru.ru',
    name: 'Russian State Geological Prospecting University',
    web_page: 'http://www.mgri-rggru.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIhfRqXYqr0yyVSylZUAUJOdhkGKUI_-IqU8spQIGlmYDY0LmkP1U61w',
    id: 7098,
    acronym: 'RSGPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mgsu.norna.ru',
    name: 'Moscow State University of Civil Engineering',
    web_page: 'http://mgsu.norna.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEvlcsVv9l3_AVbBNrnG96X2NhHM24_uKViLB-vmy5ggRKw8KbFmTZkA',
    id: 7099,
    acronym: 'MSUCE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mgul.ac.ru',
    name: 'Moscow State University of Forestry Engineering',
    web_page: 'http://www.mgul.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkeMfJgKR2JM_NaB-nc7d5_Yh7NnyCsdJhaV1RHqRmOLangSC7YmjGqhE2',
    id: 7100,
    acronym: 'MSUFE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'miet.ru',
    name: 'National Research University of Electronic Technology',
    web_page: 'http://www.miet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6QS3bToFjgaK2q6wb5l-4oUpJko6fKtRcxdgZ6nl810O53JyZIJWJLw',
    id: 7101,
    acronym: 'NRUET',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'miigaik.ru',
    name: 'Moscow State University of Geodesy and Cartography',
    web_page: 'http://www.miigaik.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdAYH9JhKIE3mOCPpYrcCOTQfndTtfQaF9Qmb_dx64prrqe5CJSdeWcfc',
    id: 7102,
    acronym: 'MSUGC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'miit.ru',
    name: 'Moscow State University of Railway Transport',
    web_page: 'http://www.miit.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ53Go7Jq29lYBqFcloSySH2NE3bvlcdr3ft_nH085APToByKe0EtQuZU',
    id: 7103,
    acronym: 'MSURT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'miom.org',
    name: 'Modern Institute of Managament',
    web_page: 'http://miom.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmmR_CLid2IEGfmHGWpI834sIR68x0eFzW7wxHdfh1Xp7hKtPzOK1rYyCS',
    id: 7104,
    acronym: 'MIM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'phystech.edu',
    name: 'Moscow Institute of Physics and Technology',
    web_page: 'http://www.phystech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzZrYQXxcpNiZRCZoLiR-bJ5gl23vjKGRUF8WnpqpUFgBWhUR4rYNlcyCX',
    id: 7105,
    acronym: 'MIPT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mirbis.ru',
    name: 'Moscow International Higher Business School (MIRBIS)',
    web_page: 'http://www.mirbis.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVaSHL3dXkPeiyvCANYVYsfXqO-l_q2arPvvijMhAvM2e9jwmcPATqYi8t0A',
    id: 7107,
    acronym: 'MIHBS(',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mirea.ru',
    name: 'Moscow State Institute of Radiotechnics Electronics and Automation (Technical University)',
    web_page: 'http://www.mirea.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK3BKcu4VGCGgovWXV5_SUCgrjnf3cAvmXpcPy9oHShSVKQT5Zk_plsw',
    id: 7108,
    acronym: 'MSIREA(U',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'misis.ru',
    name: 'Moscow State Institute of Steel and Alloys',
    web_page: 'http://www.misis.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs0nVInIgPXbZUQczw8oLXRrG_-3oLEQc3pcej-u1MafmwzXDSsBEDnQ',
    id: 7109,
    acronym: 'MSISA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'miu.ru',
    name: 'International University of Engineering Moscow',
    web_page: 'http://www.miu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSveY-oYNzMjKTgYDvQa8vN0Qm-gDJhdXG_Bs4zpyfExAvmnAkvkGO1o3A',
    id: 7110,
    acronym: 'IUEM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mma.ru',
    name: 'I.M. Sechenov Moscow Medical Academy',
    web_page: 'http://www.mma.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRql2V5KlVnMaCfXaRu4FVEOFrvn2Anr2G_bDu7f2jCk-WVu3cds2eF4ds',
    id: 7111,
    acronym: 'ISMMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mosconsv.ru',
    name: 'Moscow P. I. Tchaikovsky Conservatory',
    web_page: 'http://www.mosconsv.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBIELSKVfykkzhmy7wRmKL-NmsQcNfnjg45pZkXLhn9ckVvhg6MD4DDnye',
    id: 7112,
    acronym: 'MPITC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mpei.ac.ru',
    name: 'Moscow Power Engineering Institute (Technical University)',
    web_page: 'http://www.mpei.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrAi9BTGWl5mhEs5b6ifkszWQWdRMJTgZkXOQRr7LTR13w4iaIxd4YIxc',
    id: 7113,
    acronym: 'MPEI(U',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mrsu.ru',
    name: 'Mordovian State University',
    web_page: 'http://www.mrsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt7jY7E6xGbiHQc01q1D_APRzHnRF5s3KXleJIh6NmC9_zXfl1ANmOL1Y',
    id: 7114,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'msaab.ru',
    name: 'Moscow State Academy of Applied Biotechnology',
    web_page: 'http://www.msaab.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4oJ2dSrZiYR-Suk9xrB2xhwgHeyuQ4vcKv3xhTTrTqTo1JBQk-XhTf2o',
    id: 7115,
    acronym: 'MSAAB',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'msiu.ru',
    name: 'Moscow State Industrial University',
    web_page: 'http://www.msiu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkeMfJgKR2JM_NaB-nc7d5_Yh7NnyCsdJhaV1RHqRmOLangSC7YmjGqhE2',
    id: 7116,
    acronym: 'MSIU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'msta.ac.ru',
    name: 'Moscow State Textile University A.N. Kosygin',
    web_page: 'http://www.msta.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-PTxgJtdl2lTfWfwl7LtfarcQQdu45P0utl5IDNYr1xlu_dQgamuXdOFZ',
    id: 7117,
    acronym: 'MSTUAK',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mstuca.ru',
    name: 'Moscow State Technical University of Civil Aviation',
    web_page: 'http://www.mstuca.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXb-TOGcW3ITqc5vt8iRX680QfxILH7fY9slMfNy6E4vZIvmigVEf0AnI',
    id: 7118,
    acronym: 'MSTUCA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mstu.edu.ru',
    name: 'Murmansk State Technical University',
    web_page: 'http://www.mstu.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqTLoJXZ0F8Y3BuaGNTS8ymvSxQ2ZOURr5PgcGwWKwTm1BIjGkTDwRx2g',
    id: 7119,
    acronym: 'MSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'msuc.edu.ru',
    name: 'Moscow State University of Culture',
    web_page: 'http://www.msuc.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgU_SsYTcx5zpY-1ELV2dLTy41T-VCOy-qBtwnBXnQFX-ERcTrPxzmLPL-',
    id: 7120,
    acronym: 'MSUC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'msuee.ru',
    name: 'Moscow State University of Environmental Engineering',
    web_page: 'http://www.msuee.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoEk9HlpwtnED1QqDnMZ9mCaq4sAXWHGe1S3j-P3i9nI_yUqWRBtl9QQ',
    id: 7121,
    acronym: 'MSUEE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'msuie.ru',
    name: 'Moscow State University of Ecological Engineering',
    web_page: 'http://www.msuie.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuV08FUmUp2kqGtNXr52UOVPdnHaxFPOGJ11qvZvoJmeeADMvHPlDWWA',
    id: 7122,
    acronym: 'MSUEE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'msu.ru',
    name: 'Moscow State University',
    web_page: 'http://www.msu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgU_SsYTcx5zpY-1ELV2dLTy41T-VCOy-qBtwnBXnQFX-ERcTrPxzmLPL-',
    id: 7123,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'mtuci.ru',
    name: 'Moscow Technical University of Informatics and Communication',
    web_page: 'http://www.mtuci.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmZGjDfPeYiL_wlyeonv2Y2CmayTJMyujq0x2bbQ1WbtWyvFLrIatH2xQ',
    id: 7124,
    acronym: 'MTUIC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'muctr.edu.ru',
    name: 'Mendeleev Russian University of Chemical Technology',
    web_page: 'http://www.muctr.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkzMU41gHvUDjAyz-BHYMA0BmaeNmRTVbTaI2YDu6Y9ggZFRpZBxxL-KU',
    id: 7125,
    acronym: 'MRUCT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'muh.ru',
    name: 'Modern University for the Humanities',
    web_page: 'http://www.muh.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEKRrcFukIQR-OaOAEZomzk6Pqqy0yZOuRmbpMuQ70PS9KWHIRUz4N4-U',
    id: 7126,
    acronym: 'MUFH',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nayanova.edu',
    name: 'Nayanova University',
    web_page: 'http://www.nayanova.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTlQLeaNrfBpNBDwIcLmaaoKjmZUVPTP7fa1pnF7Hp33ZM1AMDR86xKA',
    id: 7127,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ncstu.ru',
    name: 'Stavropol State Technical University',
    web_page: 'http://www.ncstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTrnb5DTO4NGPPHgWNZfR8MzHL6F_xFRLL3Vvc4ZIl0qF9-cpkXxZa8uF9',
    id: 7128,
    acronym: 'SSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'neic.nsk.su',
    name: 'Siberian State University of Telecommunications and Informatics',
    web_page: 'http://www.neic.nsk.su/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYRBcoqFcOBFgXISxx3byVIsDY9YAiDelKb2bCnbBhvIyWmJDhNwZS8X4',
    id: 7129,
    acronym: 'SSUTI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nes.ru',
    name: 'New Economic School',
    web_page: 'http://www.nes.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTkVQaDRpsQEMemRUoXl5pGGt5XmffB_hi4_ksvTOkN37ZzAnw7YqDVuw',
    id: 7130,
    acronym: 'NES',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ngasu.sci-nnov.ru',
    name: 'Nizhny Novgorod State Architectural - Building University',
    web_page: 'http://www.ngasu.sci-nnov.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIt7GWZb369Oaiavh0s39lVC91jyVjjukB6Xa2pfiWRUIzCQRoJN0EDtg',
    id: 7131,
    acronym: 'NNSA-BU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nilc.spb.ru',
    name: 'Nevsky Institute of Language and Culture',
    web_page: 'http://www.nilc.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWSJBtYesrr7LNWeZrowjGRbbfcjjx9doCdU8qUj-h6aImuVPBeOjsjoo',
    id: 7132,
    acronym: 'NILC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'n-nov.mednet.com',
    name: 'Nizhny Novgorod State Academy of Medicine',
    web_page: 'http://www.n-nov.mednet.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxcHjDKZ33orW0B4HLD3ZPCxsDhjB0vT5QjCaAgEpGYDn18qr58nDDkAS1',
    id: 7133,
    acronym: 'NNSAM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nntu.sci-nnov.ru',
    name: 'Nizhny Novgorod State Technical University',
    web_page: 'http://www.nntu.sci-nnov.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFrf04-ZjP1Rbf6ycIQSoa-jCZ6O3ME7lE12b0fk0YDUcimcCbzRQp6VU',
    id: 7134,
    acronym: 'NNSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nosu.ru',
    name: 'North Ossetian State University',
    web_page: 'http://www.nosu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQmRWgRlGItuUQiHmspR7Ylwvzj2UvzzI_IkW_rY5fJoMzCL9vBGBBD-UY',
    id: 7135,
    acronym: 'NOSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'novsu.ac.ru',
    name: 'Novgorod State University',
    web_page: 'http://www.novsu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC--S8JxLj8doERWS1kyue0guGZXsmaqJV8FavBYbQcTJfxyIXxTzhN2hB-A',
    id: 7136,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nsau.edu.ru',
    name: 'Novosibirsk State Agricultural University',
    web_page: 'http://www.nsau.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbbEDucZyRA1b4FIri058HCZRugVUiHnloBmJk6RXsYjUFgBApx-qOCMfV',
    id: 7137,
    acronym: 'NSAU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nsmu.ru',
    name: 'Northern State Medical University',
    web_page: 'http://www.nsmu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9PofVOakth6xhbUPqEjbMZp6rT-N6GHVIizWmydABE-klEn2DDtUznA',
    id: 7138,
    acronym: 'NSMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nstu.ru',
    name: 'Novosibirsk State Technical University',
    web_page: 'http://www.nstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6guBeFEjFBe4phNp8KUQPhiWhTjllIZdQFicYdMxHQf1_mI7Niql0Uqc',
    id: 7139,
    acronym: 'NSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nsu.ru',
    name: 'Novosibirsk State University',
    web_page: 'http://www.nsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbbEDucZyRA1b4FIri058HCZRugVUiHnloBmJk6RXsYjUFgBApx-qOCMfV',
    id: 7140,
    acronym: 'NSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'nwpi.ru',
    name: 'North-West State Technical University',
    web_page: 'http://www.nwpi.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgd1ZSpxhLvwzeeB2FNPZohX_e9jMclkzWFuZInUn20wByYpq59N0AtewT',
    id: 7141,
    acronym: 'NSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'omgau.ru',
    name: 'Omsk State Agrarian University',
    web_page: 'http://www.omgau.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYzQMzwQ2ZZe_hXrJ8yrC7f2AwfWjbZaXYRP9ZU2LSJC_wqoQBFOiQRFQ',
    id: 7142,
    acronym: 'OSAU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'omgtu.ru',
    name: 'Omsk State Technical University',
    web_page: 'http://www.omgtu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStM6g5kimn6ODrm3-wL6KbNe-5tPPEImy_jcAJX95xFKuaaQSgSrGQNw',
    id: 7143,
    acronym: 'OSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'omgups.ru',
    name: 'Omsk State Transport University',
    web_page: 'http://www.omgups.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQopvbB5_GoBL0soHM-HijeKMm5747sSja8nvqPuotd6_FFQsW3EDR70XKt',
    id: 7144,
    acronym: 'OSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'omsk.edu',
    name: 'Omsk State Pedagogical University',
    web_page: 'http://www.omsk.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJPfLMWCWdu4uC9aQSfSS5X1XSeLwrwu_KkOFLyzGeNFVH8eBOWt8O87I',
    id: 7145,
    acronym: 'OSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'omsk-osma.ru',
    name: 'Omsk State Medicical Academy',
    web_page: 'http://www.omsk-osma.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwUkUCs7MzfhjSxzAACuNt4U2Dm4xbglzowFgdLCg-aqZEA9jwkS6hCA',
    id: 7146,
    acronym: 'OSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'orun.ru',
    name: 'Oriental University',
    web_page: 'http://www.orun.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4FIhWMjqp5__Ce_cTA96eoYFmfrQQArSFepGi2Bhf8bAsh4O7a6ydN9c',
    id: 7147,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'osu.ru',
    name: 'Orenburg State University',
    web_page: 'http://www.osu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS79dqcf_kKyKtoGi_RqW30nTkbnIWdZk0OX03dTtUem17MZuxU10zQLLGp',
    id: 7148,
    acronym: 'OSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'pfu.edu.ru',
    name: "People' s Friendship University of Russia",
    web_page: 'http://www.pfu.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFZqjKJo3Tk07icOgcT-RKngBD-mOOLsKw93g42p7Cr13vWWFc5IJ2aWQ',
    id: 7149,
    acronym: 'PSFUR',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'pglu.ru',
    name: 'Pjatigorsk State Linguistic University',
    web_page: 'http://www.pglu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmSrLn9eo_kkRSlkb4xuHI_y6TMXFGOFj2c2ec7USevOEQCEPhaqSECQ',
    id: 7150,
    acronym: 'PSLU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'pnzgu.ru',
    name: 'Penza State University',
    web_page: 'http://www.pnzgu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQX_KllYMfeCMtq-9c6tmmlLXsAnfbXp-0LlnlFSYpphETQwRxJSCNHxUk',
    id: 7151,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'pomorsu.ru',
    name: 'Pomorsk State University',
    web_page: 'http://www.pomorsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiGUV5DyVHrZhrRCAgoq5i7msgyANokQza__-Nbuv75xUKYfiZRKbTAHs',
    id: 7152,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'pomorsu.ru',
    name: 'Pomor State University',
    web_page: 'http://www.pomorsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuSKEM38du-IxnqfVMnPbpoYltxLXY4yTYdaVKjF4yQX_r910INaBatw',
    id: 7153,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'psfa.ru',
    name: 'Perm State Pharmaceutical Academy',
    web_page: 'http://www.psfa.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjvoX5YMNFA73ZxIinyU3UmqJLL6IdtYqqut0r1dEM2w13wnU3OoOgjg8',
    id: 7154,
    acronym: 'PSPA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'psma.ru',
    name: 'Perm State Medical Academy',
    web_page: 'http://www.psma.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKfd0jD-ULK5xSDZohTDQe1900z4_SReY17WuFN0ua2De50vKdy1Smcw',
    id: 7155,
    acronym: 'PSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'pspu.ac.ru',
    name: 'Perm State Pedagogical University',
    web_page: 'http://www.pspu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW59ULR4q3v1fssZS1zTQQdCBQZlwTCuZv77IoDOh7O8_uikEx6dQE2A',
    id: 7156,
    acronym: 'PSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'pstu.ac.ru',
    name: 'Perm State Academy of Agriculture',
    web_page: 'http://www.pstu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqJLN6Le4WIt022KO077ljXzLvLUE6Cs_-Sb4b-Etd7xNiBJioAxt56w',
    id: 7157,
    acronym: 'PSAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'pstu.ac.ru',
    name: 'Perm State Technical University',
    web_page: 'http://www.pstu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDbEFYr9GRAVzMcQY1q9oD85dpm2il-apq76Hbo91A9YB6bSZjp5mconE',
    id: 7158,
    acronym: 'PSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'psu.ru',
    name: 'Perm State University',
    web_page: 'http://www.psu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDbEFYr9GRAVzMcQY1q9oD85dpm2il-apq76Hbo91A9YB6bSZjp5mconE',
    id: 7159,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rah.ru',
    name: 'Russian Academy of Arts',
    web_page: 'http://www.rah.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3OBj2unDc_1y9QNvnf0NIfcb-HUzoXnJ9Ncir1cMmRWiyq7-yGoFEY-k',
    id: 7160,
    acronym: 'RAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rea.ru',
    name: 'Plekhanov Russian Academy of Economics',
    web_page: 'http://www.rea.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg9VOfRc4QemzF2dFKEpZ0aFhvVJrWZp-7lOhP4y5ipBk0BXYMvZwLavs',
    id: 7161,
    acronym: 'PRAE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rgata.yaroslavl.ru',
    name: 'Rybinsk State Academy of Aviational Technology',
    web_page: 'http://www.rgata.yaroslavl.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_iOA5FbAFuFTEIEesJzFIzLpvk6PIk-thYDv5WowaY2z7TVIoNVUyF4A',
    id: 7162,
    acronym: 'RSAAT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rshu.ru',
    name: 'Russian State Hydrometeorological University',
    web_page: 'http://www.rshu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX3qsGs_cs6XHqLc0o2RLRKQYTl3WTqa0cPvj1hHKuTKpHP17XIadbCA',
    id: 7163,
    acronym: 'RSHU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rsmu.da.ru',
    name: 'Rostov State Medical University',
    web_page: 'http://www.rsmu.da.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0-sB6qMvDMiJRPxJnaVzsd_ZDTf_SaVZoQawjWRwoIZgm8DP5OZlvsaUi',
    id: 7164,
    acronym: 'RSMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rsmu.ru',
    name: 'Russian State Medical University',
    web_page: 'http://www.rsmu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZru6OQ_wnSUyO3SkC-GlvM1YnJ72WMAtBQTHfLht_FFgcAbgcEDrLREk',
    id: 7165,
    acronym: 'RSMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rsuh.ru',
    name: 'Russian State University for the Humanities',
    web_page: 'http://www.rsuh.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm2Hf2YNISLE1X7PqRvRpcJaQ9Q8w6RIqH6ky6MEy0Wx6al4VK0IkeszQ',
    id: 7166,
    acronym: 'RSUFH',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rsu.ru',
    name: 'Rostov State University',
    web_page: 'http://www.rsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYXOWdnou1BZWIWzhojLs7qZMr60NeHsVZAuy0DdBkUAc3ULTOdbSZNQ',
    id: 7167,
    acronym: 'RSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rsute.ru',
    name: 'Moscow State University of Commerce',
    web_page: 'http://www.rsute.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfn-zObB409MxL6blxISqLVeIiwCfgbqtbGo6sDLwaBgxDnvS9s6JsGg',
    id: 7168,
    acronym: 'MSUC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'rusoil.net',
    name: 'Ufa State Petroleum Technological University',
    web_page: 'http://www.rusoil.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUWOb34xijRyzaOwElKXPooTycrMxD30X034opF0jcXSPRhuIqheGr8heP',
    id: 7169,
    acronym: 'USPTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sakhgu.sakhalin.ru',
    name: 'Sakhalin State University',
    web_page: 'http://www.sakhgu.sakhalin.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQm-vZpf8bEaduL6nypj7hPiw70aUgM74kyMd8RFAhMlEHIsyCRJagy950',
    id: 7170,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'samiit.ru',
    name: 'Samara State Academy for Railway Transportation',
    web_page: 'http://www.samiit.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0qMc7QgbaAHm1eRQ9qEmYLZxpSccfn_JIzpFM62uSUvqsSDHEKfxLWI4_',
    id: 7171,
    acronym: 'SSAFRT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'samsmu.ru',
    name: 'Samara State Medical University',
    web_page: 'http://www.samsmu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5hW8Y6skFs9JlLQsTeUrqEw0RrBHwRRnmZoSAHULF5SVo16Xgxs4jQA',
    id: 7172,
    acronym: 'SSMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sapanet.ru',
    name: 'Siberian Academy of Public Services',
    web_page: 'http://www.sapanet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLF9BqX-9-GndpsIkHzCiXWGnru4XcRk87X7qgQCoigpCX0FUWVYFnWg',
    id: 7173,
    acronym: 'SAPS',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sgap.ru',
    name: 'Saratov State Academy of Law',
    web_page: 'http://www.sgap.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCNf7TysoFUKgqGMVfiWXJNikyhNO-uBH6CUhQePmLV73Gr9b30v8ZMtA',
    id: 7174,
    acronym: 'SSAL',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sgau.ru',
    name: 'Saratov State Agrarian University',
    web_page: 'http://www.sgau.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1VSUgt7VONZtUyySn37QVmjXa8_mEPKmNjcwvEQyN8xSydj9CPHdcMrGr',
    id: 7175,
    acronym: 'SSAU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sgma.info',
    name: 'Smolensk State Medical Academy',
    web_page: 'http://www.sgma.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC83RQgEYZlB3uK1-ArbK3UeZ52dRxKL1qXSlkk5l9KJFThkk_TfmpmSLrRw',
    id: 7176,
    acronym: 'SSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sgu.ru',
    name: 'Saratov State University',
    web_page: 'http://www.sgu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6TC4HeMBUbH2D5-AiGK3MgdvzSECQFgPAF7_uMg3ucF-TWYlsPHDHpi0g',
    id: 7177,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'shu.smolensk.su',
    name: 'Smolensk Humanitarian University',
    web_page: 'http://shu.smolensk.su/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDAfx3yqsFzyKtsuSZMKBMnLb_JIa6fM31ZtIZjGpz4-yklWIET-_XlTU',
    id: 7178,
    acronym: 'SHU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sibsiu.kemerovo.su',
    name: 'Siberian State Industrial University',
    web_page: 'http://sibsiu.kemerovo.su/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwF0VCK5Z45Origv9O0VPUotwVx2slj3b3NNi_XLBALLXtTuXEHf2OFkQ',
    id: 7179,
    acronym: 'SSIU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'smolny-un.spb.ru',
    name: 'Smolny University',
    web_page: 'http://www.smolny-un.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJruyud-9Qdish2c-VCbGx7gtIUVowbPHYS3Sj0ErJPXpQ3X0Op9IZU2M',
    id: 7180,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'smtu.ru',
    name: 'St. Petersburg State Marine Technical University',
    web_page: 'http://www.smtu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRk7g9pOosEL8Dwbus1bVYrufnHr43ci3Oj6xJziT2cv9bGJsiQHCnquQ',
    id: 7181,
    acronym: 'SPSMTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spbgasu.ru',
    name: 'St. Petersburg State University of Architecture and Civil Engineering',
    web_page: 'http://www.spbgasu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIMG1Q-L5vuOREujq4cBuOktZWUxOSxIOAbYGtCIlJgRnCWy5Z73mfCpI',
    id: 7182,
    acronym: 'SPSUACE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spbgau.spb.ru',
    name: 'St. Petersburg State Agrarian University',
    web_page: 'http://www.spbgau.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtSKXB4RFYtjvxhmaAIToSLDYsMT5s-N99L8vP2MGOsacj7JSWKa-mpmw',
    id: 7183,
    acronym: 'SPSAU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spbguki.ru',
    name: 'St. Petersburg State University of Culture and Arts',
    web_page: 'http://www.spbguki.ru/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTxMweXOl9lH7Y9zVL2qkqV841E2LPFdtofuXDEpL5TdWK8MlEdxsaOfg',
    id: 7184,
    acronym: 'SPSUCA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spbiir.ru',
    name: 'St. Petersburg Institute of Arts and Restoration',
    web_page: 'http://www.spbiir.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrGwTkrwpgvzjJ9EfWSGrDwgTjSk30jY53Zpiy9aYft9ax3q7r2SgON78',
    id: 7185,
    acronym: 'SPIAR',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spbstu.ru',
    name: 'St. Petersburg State Polytechnical University',
    web_page: 'http://www.spbstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv6jaT_PH2eFIJfY6QTJLtzkO9VNdKWDBGihPGVKe9RUAtma--b04rC78',
    id: 7186,
    acronym: 'SPSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spbtei.ru',
    name: 'St. Petersburg Institute of Sales Management and Economics',
    web_page: 'http://www.spbtei.ru/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQY-R6g0z8kS2f06DOys-zE1CSfWbLpT-6ftkZl2BL6S8rBa9JyWvl69rs',
    id: 7187,
    acronym: 'SPISME',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spbu.ru',
    name: 'St. Petersburg State University',
    web_page: 'http://www.spbu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLh6Vfe3YOcpip4TewSztBan4Cnd_L4mwuKd7yxXU4GO5cnpHwH2I0NK4',
    id: 7188,
    acronym: 'SPSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spbuwc.ru',
    name: 'St. Petersburg State University of Waterway Communications',
    web_page: 'http://www.spbuwc.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWAscPZ9dRKWrHWo9SZMfwSuuBBTDErS9lN5Bllm0y77qJKwCI9BXVs1U',
    id: 7189,
    acronym: 'SPSUWC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spcpa.ru',
    name: 'St. Petersburg State Chemical Pharmaceutical Academy',
    web_page: 'http://www.spcpa.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_vW0rMXIn0DTb5GvqCNZ1JXNxZPSUTc7U7VmUNdmfUWNw2KFRCHevNQ',
    id: 7190,
    acronym: 'SPSCPA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spmi.edu.ru',
    name: 'St. Petersburg State Mining Institute (Technical University)',
    web_page: 'http://www.spmi.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDMr3xWeiFtwwaDyFU5lQycNTwHrdjM72jH43yI_Z5VR9XGoMStD8YRCQ',
    id: 7191,
    acronym: 'SPSMI(U',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'spsmma.com',
    name: 'St. Petersburg StateMechnikov  Medical Academy',
    web_page: 'http://www.spsmma.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEjLlcS79tD7jm8j2PvKblH1wxsKPNjnuUe7ymIM_0LYtLDmtZG4lmMQw',
    id: 7192,
    acronym: 'SPSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ssaa.ru',
    name: 'Samara State Agricultural Academy',
    web_page: 'http://www.ssaa.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxvtIRUzsDvW72rWzvdO308piepMNSPgt0QkGhH3VLCS1S5NRZRdI3WVs',
    id: 7193,
    acronym: 'SSAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ssaba.smr.ru',
    name: 'Samara State Academy of Architecture and Civil Engineering',
    web_page: 'http://www.ssaba.smr.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEorHC4L9oweYz5hYOGtzneIzMUUBIwS2kiJWim68FZL3uXBoAZFXhv8k',
    id: 7194,
    acronym: 'SSAACE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ssau.ru',
    name: 'Samara State Aerospace University',
    web_page: 'http://www.ssau.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnfgZXVdMjrhi667LGvPGook9bZt5IdNPFJ6XsKgXC_j2p1fWr-I-rW4Y',
    id: 7195,
    acronym: 'SSAU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ssea.runnet.ru',
    name: 'Saratov State Socio-Economic University',
    web_page: 'http://www.ssea.runnet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXZ_u9yryRJcKgnKIAZi5O4YfGHgat4GBDXUf29J94uuY33w3CvyJDaU5-',
    id: 7196,
    acronym: 'SSSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sseu.ru',
    name: 'Samara State University of Economics',
    web_page: 'http://www.sseu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4r5bIVChY9AZg9ReB20hFy5_dYRDLJOzbKjAn0rgYgwkNu4Job_xRX7c',
    id: 7197,
    acronym: 'SSUE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ssmu.ru',
    name: 'Siberian State Medical University',
    web_page: 'http://www.ssmu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_4awBK-FkNMhdquyp6OshwxTWlYkWNSidPv4qABCQDnAN7t6zQi1-vpw',
    id: 7198,
    acronym: 'SSMU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ssttu.samara.ru',
    name: 'Samara State University of Teacher Training',
    web_page: 'http://www.ssttu.samara.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5aKmgAhykRZMn5Xze_r7kn0N8nM4ORajzq4pkat5wM2mlx9emj86zhQ',
    id: 7199,
    acronym: 'SSUTT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sstu-edu.ru',
    name: 'Saratov State Technical University',
    web_page: 'http://www.sstu-edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlMWECZJUKaqlA5q3EjFskmqHskN3EBoe_nCZ_Xksakt6eDWpH80q18ZU',
    id: 7200,
    acronym: 'SSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sstu.samara.ru',
    name: 'Samara State Technical University',
    web_page: 'http://www.sstu.samara.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnfgZXVdMjrhi667LGvPGook9bZt5IdNPFJ6XsKgXC_j2p1fWr-I-rW4Y',
    id: 7201,
    acronym: 'SSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ssu.komi.com',
    name: 'Syktyvkar State University',
    web_page: 'http://ssu.komi.com:8101/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHK89Pz20lMywAxNSjJn0bntgZgMwKRJv8gSp5RWavzICtbwihB5rd7bBa',
    id: 7202,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ssu.samara.ru',
    name: 'Samara State University',
    web_page: 'http://www.ssu.samara.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnfgZXVdMjrhi667LGvPGook9bZt5IdNPFJ6XsKgXC_j2p1fWr-I-rW4Y',
    id: 7203,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'stankin.ru',
    name: 'Moscow State University of Technology (Stankin)',
    web_page: 'http://www.stankin.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXvYF9uWZa3vbhW_GB4oWsZ0QwfTzoiMHFSXMb3S_T3fL27vWQ7HcU9nk',
    id: 7204,
    acronym: 'MSUT(',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'stavsu.ru',
    name: 'Stavropol State University',
    web_page: 'http://www.stavsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxHlELdzlfVL5PJJ-tPicTtfsTM5k46QkaVU1mQrdGSvygpZaWoIC2xw',
    id: 7205,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'stu.lipetsk.ru',
    name: 'Lipeck State Technical University',
    web_page: 'http://www.stu.lipetsk.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc10BSiLMqBPGPFaMBd_jBFxGdOxKgmhLqmfGKDsyZe1YMopS8AkJUF9E',
    id: 7206,
    acronym: 'LSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'stu.ru',
    name: 'Siberian State Transport University',
    web_page: 'http://www.stu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwF0VCK5Z45Origv9O0VPUotwVx2slj3b3NNi_XLBALLXtTuXEHf2OFkQ',
    id: 7207,
    acronym: 'SSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'suai.ru',
    name: 'St. Petersburg State University of Aerospace Instrumentation',
    web_page: 'http://www.suai.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRxOKFJtkALYoeRY5UZcOAcwTBj-YjkhbkNxdpTKD_RVhl8fk-oQCT-MY',
    id: 7208,
    acronym: 'SPSUAI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'surgu.wsnet.ru',
    name: 'Surgut State University',
    web_page: 'http://www.surgu.wsnet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKEa0TkEVXn2ohD-Ch7AJAhnAQAT4_ehzGMOM9MZQxtrPEfmd6d953r74',
    id: 7209,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sutd.ru',
    name: 'St. Petersburg State University of Technology and Design',
    web_page: 'http://www.sutd.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtSKXB4RFYtjvxhmaAIToSLDYsMT5s-N99L8vP2MGOsacj7JSWKa-mpmw',
    id: 7210,
    acronym: 'SPSUTD',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'sut.ru',
    name: 'St. Petersburg State University of Telecommunication',
    web_page: 'http://www.sut.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEjLlcS79tD7jm8j2PvKblH1wxsKPNjnuUe7ymIM_0LYtLDmtZG4lmMQw',
    id: 7211,
    acronym: 'SPSUT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tart.spb.ru',
    name: 'St. Petersburg State Theatre Arts Academy',
    web_page: 'http://www.tart.spb.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxZafJfuksYbjjNd2GxajJbQd351TOCI5Pvz5PmroRA53xhBuPRevgeA',
    id: 7212,
    acronym: 'SPSTAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tgpi.ttn.ru',
    name: 'Taganrog State Pedagogical Institute',
    web_page: 'http://www.tgpi.ttn.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxa3cW32qj864BPi2QMIH6nC08wQghrYERll2MDvPCfK3NJ34Qr8-pow',
    id: 7213,
    acronym: 'TSPI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tgsha.ru',
    name: 'Tyumen State Agricultural Academy',
    web_page: 'http://www.tgsha.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzIlLw96_7WBmLeJq6ZNLvOLwwvrJU4r5YIXUhCCG0BET83XkMDJMtb-4w',
    id: 7214,
    acronym: 'TSAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'theatrins-yar.ru',
    name: 'Yaroslavl State Theatre Institute',
    web_page: 'http://www.theatrins-yar.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPsju01MJvpR7v8vrc0ZWhlnnrVieVB1eeM0pnXMeHBhz6YGNZeDsRO_E',
    id: 7215,
    acronym: 'YSTI',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'timacad.ru',
    name: 'Timirjazev Moscow Academy of Agricultutre',
    web_page: 'http://www.timacad.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLWO3gPz65sbroTI1zw64pijNDoHKF3kd2gK2aVFWnDiL7DP4ttNuKb40',
    id: 7216,
    acronym: 'TMAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tltsu.ru',
    name: 'Togliatti State University',
    web_page: 'http://www.tltsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQq-HNGiEFPCtJ2jachXwMldn9ymfpWwe-w0rm4lNM3Bi7TOJA7EZ-5nU',
    id: 7217,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'touro.ru',
    name: 'Moscow University Touro',
    web_page: 'http://www.touro.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTheCNt2eygunu16-IklyhUqQUvMhsZ0a3iShvMLHQJPm2yB9yF2KM411E',
    id: 7218,
    acronym: 'MUT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tpu.ru',
    name: 'Tomsk Polytechnic University',
    web_page: 'http://www.tpu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOPmR49DtVl-tWkTBMQw0ichjf3A0nCzoWFMYHNVOurJSKVLK_RORQ-w',
    id: 7219,
    acronym: 'TPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tsma.ru',
    name: 'Tver State Medical Academy',
    web_page: 'http://www.tsma.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREDy9qC-nm9ke62CiCqki8sM2HfWKXFlezEFii6W6s5zLS3V_w103iOtI',
    id: 7220,
    acronym: 'TSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tsogu.ru',
    name: 'Tyumen State University of Gas and Oil',
    web_page: 'http://www.tsogu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJNbUb_5xlH6RbYp0CAeA480WhljUe4B99CsnLBKhBDiBoTAhg6zNIjw',
    id: 7221,
    acronym: 'TSUGO',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tspu.edu.ru',
    name: 'Tomsk State Pedagogical University',
    web_page: 'http://www.tspu.edu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQONrj5UD8w6KcTHb1ctzDAkoJvoQwwCauKMO3x8TIwiMowLEs_hkDvDA',
    id: 7222,
    acronym: 'TSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tstu.ru',
    name: 'Tambov State Technical University',
    web_page: 'http://www.tstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9H1jI87gamFp8Wy4vNlodZwSX_YbAX95srixp7_NY1FGesQBmvL2iL1M',
    id: 7223,
    acronym: 'TSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tstu.tver.ru',
    name: 'Tver State Technical University',
    web_page: 'http://www.tstu.tver.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSz5gTsYQ9MruHejhSXbuuPh6VJdMeXFEwsgYte5MOXfoGJrHmsDig3R7I',
    id: 7224,
    acronym: 'TSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tsuab.ru',
    name: 'Tomsk State University of Architecture and Building',
    web_page: 'http://www.tsuab.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPA_sv1lQxWCDgD6S4R93VHSh7qMRu7cX0xBHqLwqFQOC3Q7-dU7TDKcx5',
    id: 7225,
    acronym: 'TSUAB',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tsure.ru',
    name: 'Taganrog State University of Radioengineering',
    web_page: 'http://www.tsure.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR_FmoQCNveEgdryN6DkPCLwENLAPQ5tvyDNMB5pu9FWFKIGBon4hDiHA',
    id: 7226,
    acronym: 'TSUR',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tsu.ru',
    name: 'Tomsk State University',
    web_page: 'http://www.tsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqlOdTPKVJEMZAXG99POAIzw7oSrzuLFhTmirgR2Kw4wpblpfnL-4oiQ',
    id: 7227,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tsu.tmn.ru',
    name: 'Tyumen State University',
    web_page: 'http://www.tsu.tmn.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR48bx2rA6Nf5T0E73lj2OtBIm_Xs4zUtxlnQWj9ZZRKxJmlGzkTKmAb1Sj',
    id: 7228,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tsu.tula.ru',
    name: 'Tula State University',
    web_page: 'http://www.tsu.tula.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa_Q3CqqzwzBNYAHiRwPwb53i0Ip1FfZqhX2V4Kv-DgF7Buu2NWY8lalM',
    id: 7229,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ttc.ryazan.ru',
    name: 'Ryazan State Pedagogical University',
    web_page: 'http://www.ttc.ryazan.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa_Q3CqqzwzBNYAHiRwPwb53i0Ip1FfZqhX2V4Kv-DgF7Buu2NWY8lalM',
    id: 7230,
    acronym: 'RSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tu-bryansk.ru',
    name: 'Brjansk State Technical University',
    web_page: 'http://www.tu-bryansk.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7UAivkYTN1lpl8KOLC4L9foqD415Db49iNN5Af37iOuTSOhqDQHUHpvrb',
    id: 7231,
    acronym: 'BSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tu-chel.ac.ru',
    name: 'Southern Ural State University',
    web_page: 'http://www.tu-chel.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHuqWrxcSkPh324l6h2TyMb0rrBlliSkiBjHMK7wwFLLV7IbMyI_2Evno',
    id: 7232,
    acronym: 'SUSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tusur.ru',
    name: 'Tomsk State University of Control Systems and Radioelectronics',
    web_page: 'http://www.tusur.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1UnZQFRy2ayAJzhk1SGdjm_EUcOIpV3tipactXxV30nQnHwQG_MArZ9I',
    id: 7233,
    acronym: 'TSUCSR',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'tuvsu.ru',
    name: 'Tuva State University',
    web_page: 'http://tuvsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWPVpRG7Bsd0MgjvC2zXnNxjcyZhyMlPhwtjiqSBNF4KGDzI6qujYHS0Q',
    id: 7234,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uapa.ru',
    name: 'Ural Academy of Public Administration',
    web_page: 'http://www.uapa.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxQkcAvMq-FyrlfNKFPSwO4tcUUPDHKMfKLSthhIl-DeIHP2yhZUB9M3E',
    id: 7235,
    acronym: 'UAPA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uef.ru',
    name: 'St. Petersburg State University of Economics and Finance',
    web_page: 'http://www.uef.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEjLlcS79tD7jm8j2PvKblH1wxsKPNjnuUe7ymIM_0LYtLDmtZG4lmMQw',
    id: 7236,
    acronym: 'SPSUEF',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ugatu.ac.ru',
    name: 'Ufa State Aviation Technical University',
    web_page: 'http://www.ugatu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRajIVLU6HVeCO0QATw8EK31GGjvpxXugwRzwA6TsruohJnA58kMJ9T2y_a',
    id: 7237,
    acronym: 'USATU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ugrasu.ru',
    name: 'Yugra State University',
    web_page: 'http://www.ugrasu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbrPabMoltLSYcpAJv60g1NFLMr5A0D8ETsSFmij8GLVNs84j10upHnxx5',
    id: 7238,
    acronym: 'YSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ugsha.ru',
    name: 'Ulyanovsk State Agricultural Academy',
    web_page: 'http://www.ugsha.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqJLN6Le4WIt022KO077ljXzLvLUE6Cs_-Sb4b-Etd7xNiBJioAxt56w',
    id: 7239,
    acronym: 'USAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uii.sever.ru',
    name: 'Ukhta State Technical University',
    web_page: 'http://uii.sever.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7V0ILpWb8M5KMsKJE1SeyQZ-EX4jbZ5KffybDLmQWmMq9XBewaTGakWA',
    id: 7240,
    acronym: 'USTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ulspu.ru',
    name: 'Ulyanovsk State Pedagogical University',
    web_page: 'http://www.ulspu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTveRzQD-NWOMYVrZuXA86VC58CbRkrOFZTgZJmuvVyHng2sAqgGaiHu8w',
    id: 7241,
    acronym: 'USPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ulstu.ru',
    name: 'Ulyanovsk State Technical University',
    web_page: 'http://www.ulstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlZD7-tDATZ55prd2Mo8Lg4cBAzap3lO2NJk1t2kKSv_rO0ZbY5IAw0bY',
    id: 7242,
    acronym: 'USTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ulsu.ru',
    name: 'Ulyanovsk State University',
    web_page: 'http://www.ulsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTveRzQD-NWOMYVrZuXA86VC58CbRkrOFZTgZJmuvVyHng2sAqgGaiHu8w',
    id: 7243,
    acronym: 'USU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uni-dubna.ru',
    name: 'Dubna International University for Nature Society and Man',
    web_page: 'http://www.uni-dubna.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbPXzU8kaOl6CPEokKA5bxN4p3ZP4Jjq_vWVP-VzZQWWLU1ap2CcgdUt8',
    id: 7244,
    acronym: 'DIUFNSM',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'unilib.neva.ru',
    name: 'St. Petersburg State Technical University',
    web_page: 'http://www.unilib.neva.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZfyH8bxoMQV1Ap56jlLU7EsyOo9OVURcSFQrUdiZ8j48PoKZEAXgI2rzT',
    id: 7245,
    acronym: 'SPSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uni.udm.ru',
    name: 'Udmurt State University',
    web_page: 'http://www.uni.udm.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReoc4_LKA4wm-MX8pAeT2eRyeSltGvB1Rwo5kANsOV0ZHb5LnjllW7FRTWXQ',
    id: 7246,
    acronym: 'USU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'univer.omsk.su',
    name: 'Omsk State University',
    web_page: 'http://www.univer.omsk.su/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX0qtoWk4XGAA57EFK1Yq17BU4HYmS5k6hwpRn64pJ73phG1WnkAYDEm-2PQ',
    id: 7247,
    acronym: 'OSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'university.tversu.ru',
    name: 'Tver State University',
    web_page: 'http://university.tversu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTj_vzitsXWyXVhkQNw5NiviuZ0nMg64L0XxGSpzsW9us3btADOuumT9fK8',
    id: 7248,
    acronym: 'TSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uni-vologda.ac.ru',
    name: 'Vologda State Pedagogical University',
    web_page: 'http://www.uni-vologda.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFv-cbkqOgrHKf-akC3M-pwwCa5ksvRKIJnw0q0UE7kHihuZRUHImF_5nO',
    id: 7249,
    acronym: 'VSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uniyar.ac.ru',
    name: 'Yaroslavl State University',
    web_page: 'http://www.uniyar.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp58YpTbOS7wNlxzYqY1HIhVyR6xhDZC8l7xak7-_nWpA-qnZ3OnwV9Pg',
    id: 7250,
    acronym: 'YSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'unn.ac.ru',
    name: 'Nizhny Novgorod State University',
    web_page: 'http://www.unn.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC--S8JxLj8doERWS1kyue0guGZXsmaqJV8FavBYbQcTJfxyIXxTzhN2hB-A',
    id: 7251,
    acronym: 'NNSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'urc.ac.ru',
    name: 'Cheljabinsk University of Agricultural Engineering',
    web_page: 'http://www.urc.ac.ru/Universities/AGRO/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRTfZUjAuHbQeosdaXASBntqwu03x8pVOnSZPlYjp5y7H896Q5KvUaR8xI',
    id: 7252,
    acronym: 'CUAE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'usaaa.ru',
    name: 'Ural State Academy of Architecture and Arts',
    web_page: 'http://www.usaaa.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1go1qWOZ_5wayCwI3wj5hv_NXMxO1vzbpsWHWT3cNE9Aak1P7QtSHzQ',
    id: 7253,
    acronym: 'USAAA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'usart.ru',
    name: 'Ural State Academy of Railway Transport',
    web_page: 'http://www.usart.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6AEiwkoSnhF3ZCPTfur1vNp16-MjngqyISy3mWCB6dhbUm6Z7uP7YZU9L',
    id: 7254,
    acronym: 'USART',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uscon.ru',
    name: 'Ural State Conservatory',
    web_page: 'http://www.uscon.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi3WariW5qUW06J-S_Oinz1GLj8tbydEhDb9SuyL1lPjRXwXu4J1ehvjQ',
    id: 7255,
    acronym: 'USC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'usfea.ru',
    name: 'Ural State Forestry Technical Academy',
    web_page: 'http://www.usfea.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_FXgE-rNZO4qJRNRJNXUMB2DguuTJ3WD9zJfaKBhS7ea8SdUYx-Hc2rxo',
    id: 7256,
    acronym: 'USFTA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'usla.ru',
    name: 'Ural State Academy of Law',
    web_page: 'http://www.usla.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnk5RTOY_naw8by58BAvCD2fsqKYyO7OQDK5zukpBtgFNLNZ8ryDhtehg',
    id: 7257,
    acronym: 'USAL',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'usmga.ru',
    name: 'Ural State Academy of Mining and Geology',
    web_page: 'http://www.usmga.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUuddWzWmjAzCMrPinX-a5NuV6uVwQ1iZ5TEAZ4SXz19UG8IxMWShq_yg',
    id: 7258,
    acronym: 'USAMG',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ustu.ru',
    name: 'Ural State Technical University',
    web_page: 'http://www.ustu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYDDWCm2-j4tmNrRa50Fk6hVm7xs33oFa9aTneyL_KtG2fzFzIeSnveQ',
    id: 7259,
    acronym: 'USTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'usue.ru',
    name: 'Ural State University of Economics',
    web_page: 'http://www.usue.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX0qtoWk4XGAA57EFK1Yq17BU4HYmS5k6hwpRn64pJ73phG1WnkAYDEm-2PQ',
    id: 7260,
    acronym: 'USUE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'usu.ru',
    name: 'Ural Gorkij State University',
    web_page: 'http://www.usu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkEHvjsTZ-Pv966OHe16msgRRoGnTgecG_r2ud9JKy9nkY1_u8XY01IA',
    id: 7261,
    acronym: 'UGSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'uvauga.ru',
    name: 'Ulyanovsk Higher School of Civil Aviation',
    web_page: 'http://www.uvauga.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEkRjtlRsXwK3gXnbXxGJ6RDiG7f6h7-nu5hnihM4pQrAhT6lMcwHe8SM',
    id: 7262,
    acronym: 'UHSCA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vfrta.ru',
    name: 'Russian Customs Academy Vladivostok Branch',
    web_page: 'http://www.vfrta.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6PSIBSHvmocAOf9nNbOaySvzUJlGE7ne3npqMtk0iYFqrdXKJb2hrUVAO',
    id: 7263,
    acronym: 'RCAVB',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vgafk.ru',
    name: 'Volgograd State Academy of Physical Education',
    web_page: 'http://www.vgafk.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_Ro4Tc_Ro49jzRp_K2Ioq1bNTUM_PUo2z9EaLI2jDDljaCx63cDBhzA',
    id: 7264,
    acronym: 'VSAPE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vgasa.ru',
    name: 'Volgograd State University of Architecture and Civil Engineering (VolgGASU)',
    web_page: 'http://www.vgasa.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFoPvxppDmVmqIP2zdKL3XiZiARhP3SqrKvbhL3k7XRE-LJJoX3_CZH18',
    id: 7265,
    acronym: 'VSUACE(',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vgta.vrn.ru',
    name: 'Voronezh State Academy of Technology',
    web_page: 'http://www.vgta.vrn.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsK5HNXnOaAQPak-y7Ao-ZQLN9v6lYMUF5yJgAkmCzbICaIij9SmLO1Q',
    id: 7266,
    acronym: 'VSAT',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vieup.ru',
    name: 'NGO Vladikavkaz Institute of Economics Management and Law',
    web_page: 'http://www.vieup.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM9KA0FkvHgZ-HdssWhqmTgnpfBC-CnRk2awYmzkiiDGOBApG5VZl5lpQ',
    id: 7267,
    acronym: 'NVIEML',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vlsu.ru',
    name: 'Vladimir State University',
    web_page: 'http://www.vlsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHymRwJQwuzGf82yzEFxK44moI864flH5ePN0SafCkbNNENf0EbZtSDQ',
    id: 7268,
    acronym: 'VSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'voenmeh.ru',
    name: 'Baltic State Technical University',
    web_page: 'http://www.voenmeh.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAigfavlJXAmnDnxOPWmAQp6pF9grA5t-INS6fodalFyDQEJv_b0-auQ',
    id: 7269,
    acronym: 'BSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'volsu.ru',
    name: 'Volgograd State University',
    web_page: 'http://www.volsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK2IuLNXetf_XBdjZZAS91f-PLVTbyoVSrBWACW1EmjV9bBdCjM1wqL_k',
    id: 7270,
    acronym: 'VSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vorstu.ac.ru',
    name: 'Voronezh State Technical University',
    web_page: 'http://www.vorstu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjuFHE2Ge2iOJeHH0tnamIeWpHsUz6eV4Ynfd9pLLOb_R1b64UimUGyw',
    id: 7271,
    acronym: 'VSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vsau.ru',
    name: 'Voronezh State Agricultural University',
    web_page: 'http://www.vsau.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbGdelxY02hDACy1MLLHCRVEgcmwrUo_lzma-htl3KPaNMB6bYZ3_zxw',
    id: 7272,
    acronym: 'VSAU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vsgaki.burnet.ru',
    name: 'East-Siberian State Institute of Culture',
    web_page: 'http://www.vsgaki.burnet.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwF0VCK5Z45Origv9O0VPUotwVx2slj3b3NNi_XLBALLXtTuXEHf2OFkQ',
    id: 7273,
    acronym: 'ESIC',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vsgtu.eastsib.ru',
    name: 'East-Siberian State University',
    web_page: 'http://www.vsgtu.eastsib.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGYqOldtOdvtXynZRVgbdOO28xouDs8mSBosdC0b4U4LB3IBULC41qFw',
    id: 7274,
    acronym: 'ESU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vsma.info',
    name: 'Voronezh State Medical Academy',
    web_page: 'http://www.vsma.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGMLDspq85Paf5qGU2vXxgyzCm_bpEp6wzL38iS5cKtbuOhgnfwtiJDE8',
    id: 7275,
    acronym: 'VSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vspu.ac.ru',
    name: 'Voronezh State Pedagogical University',
    web_page: 'http://www.vspu.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxeIkgwv-inZZ1lUPmQ_uyHZLXybYSiFPxtR-00eyQBC7C_mfCF4QeRC8',
    id: 7276,
    acronym: 'VSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vspu.kirov.ru',
    name: 'Vyatka State Pedagogical University',
    web_page: 'http://www.vspu.kirov.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa_Q3CqqzwzBNYAHiRwPwb53i0Ip1FfZqhX2V4Kv-DgF7Buu2NWY8lalM',
    id: 7277,
    acronym: 'VSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vspu.ru',
    name: 'Volgograd State Pedagogical University',
    web_page: 'http://www.vspu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFoPvxppDmVmqIP2zdKL3XiZiARhP3SqrKvbhL3k7XRE-LJJoX3_CZH18',
    id: 7278,
    acronym: 'VSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vstu.ru',
    name: 'Volgograd State Technical University',
    web_page: 'http://www.vstu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK2IuLNXetf_XBdjZZAS91f-PLVTbyoVSrBWACW1EmjV9bBdCjM1wqL_k',
    id: 7279,
    acronym: 'VSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vsu.ru',
    name: 'Voronezh State University',
    web_page: 'http://www.vsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjuFHE2Ge2iOJeHH0tnamIeWpHsUz6eV4Ynfd9pLLOb_R1b64UimUGyw',
    id: 7280,
    acronym: 'VSU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'vvsu.ru',
    name: 'Vladivostock State University of Economics',
    web_page: 'http://www.vvsu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfdiJKDnRiwFR3_H4Inqz73gofZ4itwU1l8qfwj204X45K44uiy6BDRgc',
    id: 7281,
    acronym: 'VSUE',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'yma.ac.ru',
    name: 'Yaroslavl State Medical Academy',
    web_page: 'http://www.yma.ac.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0nyIpYKFvcCG1wKoolCV9QO49Iy0jWgtUTTzZJzyubWHEYaNpWNF9KcGY',
    id: 7282,
    acronym: 'YSMA',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'yspu.yar.ru',
    name: 'Yaroslavl State Pedagogical University',
    web_page: 'http://www.yspu.yar.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsFmpX3ATUblxGHXc0N99ohcEKqBMSLaw2goA0kNDxUDpOg2Qeg500T6-v',
    id: 7283,
    acronym: 'YSPU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ystu.yar.ru',
    name: 'Yaroslavl State Technical University',
    web_page: 'http://www.ystu.yar.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp58YpTbOS7wNlxzYqY1HIhVyR6xhDZC8l7xak7-_nWpA-qnZ3OnwV9Pg',
    id: 7284,
    acronym: 'YSTU',
  },
  {
    alpha_two_code: 'RU',
    country: 'Russian Federation',
    domain: 'ysu.ru',
    name: 'Yakutsk State University',
    web_page: 'http://www.ysu.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxHlELdzlfVL5PJJ-tPicTtfsTM5k46QkaVU1mQrdGSvygpZaWoIC2xw',
    id: 7285,
    acronym: 'YSU',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'auca.ac.rw',
    name: 'Adventist University of Central Africa',
    web_page: 'http://www.auca.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkKWDtMqoqf303Ay7zxIlBmLUjK2CI9Rz-zgZG4BCgW4A8RHmp7AX1aA',
    id: 7286,
    acronym: 'AUCA',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'inatek.ac.rw',
    name: "Institut d'Agriculture de Technologie et d'Education de Kibungo",
    web_page: 'http://www.inatek.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfTC71WM4h6DHYMjqfA4dKa8XThBrP9_K3PfAwu9PvYkNBYQvsrmXanhc',
    id: 7287,
    acronym: 'IDDTEDDK',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'isae.ac.rw',
    name: 'Higher Institute of Agriculture and Animal Husbandry',
    web_page: 'http://www.isae.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvnvK-r0QOk5BEJTUcKmvyTlG5SxDcePJK179ryrY3EZFfskZoC9zcLTE',
    id: 7288,
    acronym: 'HIAAH',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'khi.ac.rw',
    name: 'Kigali Health Institute',
    web_page: 'http://www.khi.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUcb8Nvd7_Tpxq7VTDANwsonjQRCAwgNZq2RCkdsr1zKCOX7LD1mCDHgA',
    id: 7289,
    acronym: 'KHI',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'kie.ac.rw',
    name: 'Kigali Institute of Education',
    web_page: 'http://www.kie.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs-lDp_uVjF05hKfyWKlxdberrHRNYnErOvj-h4Vqcu9U9vXUqhwY6mQ',
    id: 7290,
    acronym: 'KIE',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'kist.ac.rw',
    name: 'Kigali Institute of Science & Technology',
    web_page: 'http://www.kist.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTvUqvUU8bnrPmLakXToDKrGq8K08kRoqgs325C-ENT6pzKsSkSVTMwnA',
    id: 7291,
    acronym: 'KIS&T',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'nur.ac.rw',
    name: 'National University of Rwanda',
    web_page: 'http://www.nur.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQusjWOLvHeTnxuEnKMoJO5nhJm_CQNWDmAz7pEgTTfwPUoBZxOZ_ZI1Gs',
    id: 7292,
    acronym: 'NUR',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'sfb.ac.rw',
    name: 'School of Finance and Banking',
    web_page: 'http://www.sfb.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSq8Y-Zw9quS8qwpwNGzcNNss6L4_z1CzASRTgHnhg5rU-r9NEwd84Z808',
    id: 7293,
    acronym: 'SFB',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'uck.ac.rw',
    name: 'Université Catholique de Kabgayi',
    web_page: 'http://www.uck.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFdzaADuD5fD35dIQ9IhAoZ7k3zuM52mQD5cy-3nN3yjHhvrur5XlhqA',
    id: 7294,
    acronym: 'UCDK',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'ulk.ac.rw',
    name: 'Kigali Independent University',
    web_page: 'http://www.ulk.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg0Eu-XVfx_1WLiZezjOXgfgkdnqbv8mOlBllysjAdkVAXNeVCfkqqBdw',
    id: 7295,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'RW',
    country: 'Rwanda',
    domain: 'umutarapolytech.ac.rw',
    name: 'Umutara Polytechnic',
    web_page: 'http://www.umutarapolytech.ac.rw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDK3sK7CpPPm0MQoqtt4pGulQI1it09JFDy3kngMN9Kg6zd0XhUn6Ttvw',
    id: 7296,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'KN',
    country: 'Saint Kitts and Nevis',
    domain: 'iugrad.edu.kn',
    name: 'International University for Graduate Studies',
    web_page: 'http://www.iugrad.edu.kn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHQJR5Ep3XIJzZyuEIe3d2QXF-NJ8D6mv7DrKyRlg4pSi3YfhAE6tJpUyn',
    id: 7297,
    acronym: 'IUFGS',
  },
  {
    alpha_two_code: 'KN',
    country: 'Saint Kitts and Nevis',
    domain: 'rossu.edu',
    name: 'Ross University Caribbean School of Veterinary Medicine',
    web_page: 'http://www.rossu.edu/veterinary-school/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4mjkJn8CQ-X2ibDwJuxeSSjHwplFgBdgXKDuVLtLPUaDuN6Y_5tx20K4',
    id: 7298,
    acronym: 'RUCSVM',
  },
  {
    alpha_two_code: 'KN',
    country: 'Saint Kitts and Nevis',
    domain: 'stmu.org',
    name: "St Theresa's Medical University",
    web_page: 'http://www.stmu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTblxAc-UOrjP2TgRrK6VTpaMGiFM5saz-qPpiqVzqV1v3KcjCTy5boxil6',
    id: 7299,
    acronym: 'STMU',
  },
  {
    alpha_two_code: 'KN',
    country: 'Saint Kitts and Nevis',
    domain: 'unisilvaner.info',
    name: 'University of Silvaner',
    web_page: 'http://www.unisilvaner.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUV2fwJPwg1citTaqdLATCIVm9YUwRwm0O-5TzS-UmMfhuEaSxVHbb3aY',
    id: 7300,
    acronym: 'US',
  },
  {
    alpha_two_code: 'KN',
    country: 'Saint Kitts and Nevis',
    domain: 'windsor.edu',
    name: 'Windsor University School of Medicine',
    web_page: 'http://www.windsor.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzuQujRJVxlVv9cAau2-I0cFi1aS2OGhQCeNcERqCYIbXt_aPciQpukWHX',
    id: 7301,
    acronym: 'WUSM',
  },
  {
    alpha_two_code: 'LC',
    country: 'Saint Lucia',
    domain: 'spartanmed.org',
    name: 'Spartan University of Health Sciences',
    web_page: 'http://www.spartanmed.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTprhLQLXolhtO58wHKiChnHN9b09GbJZGRNIu62Q2HZkcTKMtql09i7c0n',
    id: 7302,
    acronym: 'SUHS',
  },
  {
    alpha_two_code: 'VC',
    country: 'Saint Vincent and the Grenadines',
    domain: 'tusom.org',
    name: 'Trinity University School of Medicine',
    web_page: 'http://www.tusom.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLZkccSge6PSoQyzjueRx8lIHMK6dewtnDjI7A2IzaqAYSgdUI2KSklKs',
    id: 7303,
    acronym: 'TUSM',
  },
  {
    alpha_two_code: 'WS',
    country: 'Samoa',
    domain: 'nus.edu.ws',
    name: 'National University of Samoa',
    web_page: 'http://www.nus.edu.ws/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ-OXPsaf8dBmqIcZ_7FA-IJGp1rTiytePmB8aNoZkZevluNrJEdLZig',
    id: 7304,
    acronym: 'NUS',
  },
  {
    alpha_two_code: 'WS',
    country: 'Samoa',
    domain: 'usp.ac.fj',
    name: 'University of the South Pacific School of Agriculture',
    web_page: 'http://www.usp.ac.fj/wwwsoa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbOEiSb7HvrfcT_97s8w8MXNl7gbwZ0D_rnO7sYYAnpDjgWBhkoBAWhgU',
    id: 7305,
    acronym: 'USPSA',
  },
  {
    alpha_two_code: 'SM',
    country: 'San Marino',
    domain: 'unirsm.sm',
    name: 'University of San Marino',
    web_page: 'http://www.unirsm.sm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHfaPHgmhF40TZSZ5DtuqdAffunk1yrmC9hPCKyRdckm1wQRgZu-11pX0',
    id: 7306,
    acronym: 'USM',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'act.gotevot.edu.sa',
    name: 'College of Technology at Abha',
    web_page: 'http://www.act.gotevot.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZlE4CDWz0WgVwmi4sv07m00-nT90MUn2i095Jg5SsgvjJWoqvzSab4MWJ',
    id: 7307,
    acronym: 'CTAA',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'alfaisal.edu',
    name: 'Alfaisal University',
    web_page: 'http://www.alfaisal.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzOAKTU3D6iel73PVx3jkzb0M4M624naNFYSjEL0mr7OvvqyWv2SM1raA',
    id: 7308,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'almaarifah.com',
    name: 'Knowledge International University',
    web_page: 'http://www.almaarifah.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPCt4-WsUwkVE0MvD-EUmuqhLQ7A5NzOP7acDq5eTikehdS4cqouimRYKT',
    id: 7309,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'alyamamah.edu.sa',
    name: 'Al-Yamamah College',
    web_page: 'http://www.alyamamah.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvz-bOU2OAWaYEIPfDqbBzDbYDWAqlmCehyY6WucItbGGveXNzyUZwBQ',
    id: 7310,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'bmc.edu.sa',
    name: 'Batterjee Medical College',
    web_page: 'http://www.bmc.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJULK3MGwuLhYHIgKbp9AKZhOFd-rUhZG6QeYNuHU__4YMSCWt5ngElekr',
    id: 7312,
    acronym: 'BMC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'bmcmedcollege.com',
    name: 'Al-Batterjee Medical College',
    web_page: 'http://www.bmcmedcollege.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAuQAuwfRSdrY16mDodrcWxQijHd2By8mMC85XgSaFYe6i2PZAIn_HP4Y',
    id: 7313,
    acronym: 'AMC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'bpc.edu.sa',
    name: 'Buraydah College for Applied Medical Sciences',
    web_page: 'http://www.bpc.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRtwxwVsu6yxD6AnxSaT2Y-ItU6tEIVDTPlCneAJQPxjxNNLQI9759cOg',
    id: 7314,
    acronym: 'BCFAMS',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'bu.edu.sa',
    name: 'Al-baha University',
    web_page: 'http://www.bu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVUIsozMq2H8h4ZFm0KVay5cQDe6qzh0D4KgiEhrNz6MQzmS6W8XYQsQ',
    id: 7315,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'cba.edu.sa',
    name: 'College of Busniess Admnistration',
    web_page: 'http://www.cba.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSeu-zPEEggZy38R-WfPgSn9ZcZWAD7X9ir9C9535zMHiXDDG64MMqZw',
    id: 7316,
    acronym: 'CBA',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'cti.edu.sa',
    name: 'College of Telecommunication & Information',
    web_page: 'http://www.cti.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0uwMH4CCT8JXe1x4vrgf6P3HwOtFVvYX_CMxH4uJh06p3sdss7gRFcA',
    id: 7317,
    acronym: 'CT&I',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'daralhekma.edu.sa',
    name: 'Dar al Hekma College',
    web_page: 'http://www.daralhekma.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTxyIE52_w-H52_p2s0vVJCelZVUpfZQEubCnQtm3RpnWwX4G8G2kX9A',
    id: 7318,
    acronym: 'DAHC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'dau.edu.sa',
    name: 'Dar Al-Uloom Colleges',
    web_page: 'http://www.dau.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIb5NopnoH5tTzOi0uGsZUMfX3IBgZ8TLD9tvHom-SqHyx-vdWtytjJQ',
    id: 7319,
    acronym: 'DAC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'dcc.edu.sa',
    name: 'Dammam Community College',
    web_page: 'http://www.dcc.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUDlST5z6lFBlqIVMoFrP2pdZM07nGQAiIW8Qxj9KEhRrhYVWE_3LE1w',
    id: 7320,
    acronym: 'DCC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'dct.gotevot.edu.sa',
    name: 'College of Technology at Dammam',
    web_page: 'http://www.dct.gotevot.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEn7MHBRJ0mMciD81IiT4MIreN-xa_BtVeHedpUeowLi-1KiB1yNdfOQ',
    id: 7321,
    acronym: 'CTAD',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'effatcollege.edu.sa',
    name: 'Effat College',
    web_page: 'http://www.effatcollege.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmeZPqqbUscMsrx_knPCIDLK_Cnms4LYXkXKel2kkrO2wmU-DbqhMS-ts',
    id: 7322,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'fbsc.edu.sa',
    name: 'Fahad Bin Sultan University',
    web_page: 'http://www.fbsc.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ69zTtXjLMb7WgLdw78eG_JYy8qz9CzbF0yXgvI0H9h1LZSVF1laYmPLY',
    id: 7323,
    acronym: 'FBSU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ibnsina.edu.sa',
    name: 'Ibn Sina National College for Medical Studies',
    web_page: 'http://www.ibnsina.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqFmZ8VgyEbEGzWD2DhXkxfbk4fO3r1Lt66zu1JKlncduPTJZApHP4PQ',
    id: 7324,
    acronym: 'ISNCFMS',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'imamu.edu.sa',
    name: 'Al-Imam Mohamed Ibn Saud Islamic University',
    web_page: 'http://www.imamu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZIstZ3M5etUXw-k3mnsQp2DfncZizNHo0DNLWostt9LPEFykg1vUKTag',
    id: 7325,
    acronym: 'AMISIU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ipa.edu.sa',
    name: 'Institute of Public Administration',
    web_page: 'http://www.ipa.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrheFBP7Un2-_Pa-8WwAAHuRfy1zRuXIvYJw7VgQdDxNf2u7FdirVAyw',
    id: 7326,
    acronym: 'IPA',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'iu.edu.sa',
    name: 'Islamic University of Medinah',
    web_page: 'http://www.iu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQngUK7ORVIXrLRd0_neW7G9XqZVemgPXYXkMX4zrWcYhMHzCNPP02C71E',
    id: 7327,
    acronym: 'IUM',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'jazanu.edu.sa',
    name: 'Jazan University',
    web_page: 'http://www.jazanu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7oabF1phwnYBY3ZP15IWlA7COSdMqDqSBIkZfzjHBNO7tVRRZwRelxmZJ',
    id: 7328,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'jct.edu.sa',
    name: 'College of Technology at Jeddah',
    web_page: 'http://www.jct.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc-OzPJipFYrUrgw6Lwtic4_S0BSNr2g9WST8GMnhlmVvRStxeywDjrA',
    id: 7329,
    acronym: 'CTAJ',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'jic.edu.sa',
    name: 'Jubail Industrial College',
    web_page: 'http://www.jic.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDz6iZm7WpAz-8BTvh0HtrhP-weI5_aQUNmlXEH6tAQfBrTxRHY2f0eg21Mg',
    id: 7330,
    acronym: 'JIC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ju.edu.sa',
    name: 'Aljouf University',
    web_page: 'http://www.ju.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSesFhEazIuSVwU64D3oB2nj7V44e2JN4vXLz0N7nXabS30MjLzOS70rqk',
    id: 7331,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'kau.edu.sa',
    name: 'King Abdul Aziz University',
    web_page: 'http://www.kau.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH9-1pECxaKswWoFqIlgtEN9lvNf49Vb4brZr0-DtPeC2u3Bs8lS7kIqc',
    id: 7332,
    acronym: 'KAAU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'kaust.edu.sa',
    name: 'King Abdullah University of Science and Technology',
    web_page: 'http://www.kaust.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1KpKbnvIR3s0C2Or2wmLXLusFXTD2pzEXC0UzgbetS8aXoH-b0KKbnA',
    id: 7333,
    acronym: 'KAUST',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'kct.gotevot.edu.sa',
    name: 'College of Technology at Kharj',
    web_page: 'http://kct.gotevot.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsanVCXICtz622_wGmoA-kusuMQnhfTQVqokG8rg1HvMret_CQ03NrmyzX',
    id: 7334,
    acronym: 'CTAK',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'kfsc.edu.sa',
    name: 'King Fahd Security College',
    web_page: 'http://www.kfsc.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmIUjJ82vMv-S_TbBwpvf9kXhasNL6ATg6-lVPz68FlC1fL4Z6p5Idohw',
    id: 7335,
    acronym: 'KFSC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'kfu.edu.sa',
    name: 'King Faisal University',
    web_page: 'http://www.kfu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNzR4QLgpfJ7ymiLc08VwOBUsoNSi4uc6KDLkramsCr_62GflMT4vZZA',
    id: 7336,
    acronym: 'KFU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'kfupm.edu.sa',
    name: 'King Fahad University of Petroleum and Minerals',
    web_page: 'http://www.kfupm.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5Qy63bzvp_OMuR-HpesdDkfC63o07_JJPZcnohcuBtKqVf9slWacgZgk',
    id: 7337,
    acronym: 'KFUPM',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'kku.edu.sa',
    name: 'King Khaled University',
    web_page: 'http://www.kku.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0VNa5gtzq_btT9L95kIlLUE7FTmxEbyCvM2bvlfp-Xo1BoAFj8OAREw',
    id: 7338,
    acronym: 'KKU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ksau-hs.edu.sa',
    name: 'King Saud bin Abdulaziz University for Health Sciences',
    web_page: 'http://www.ksau-hs.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQO8ymaxbzpD_43IH0qOH8M7FbPm_O36QNSkxOT3Dnx4DxX-1HAHg9y0vM',
    id: 7339,
    acronym: 'KSBAUFHS',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ksu.edu.sa',
    name: 'King Saud University',
    web_page: 'http://www.ksu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK-bQb-NUiRbVQytU6nk1nGyIo34HhClsFGjrv8wxTSfv0Brt0QM3kRA',
    id: 7340,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ku.edu.sa',
    name: 'Alkharj University',
    web_page: 'http://www.ku.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGtgVem2hyxG3SamLhp00dOVSlaEK4R3Vgbm45kJSeUtOiZeX-w_RaDxw',
    id: 7341,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'mcst.edu.sa',
    name: 'Knowledge College for Science and Technology',
    web_page: 'http://www.mcst.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT8iGZylM9Y6Vxsotu4CKV2bRXMwf5XheI4u1Yp1qpwbFPXiYgXYmVbTEB',
    id: 7342,
    acronym: 'KCFST',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'mu.edu.sa',
    name: 'Majmaah University',
    web_page: 'http://www.mu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8VdhBio7IR_QWBM2ydFceg8Pr4K7uuIDTulExc3Rrwa4UCMtxk9BwVrg',
    id: 7343,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'nauss.edu.sa',
    name: 'Naif Arab University for Security Sciences',
    web_page: 'http://www.nauss.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXzF8smSNJ6B9gQY3VEabnbh13RyOHbxmkxDgc8Cp0ASvkarJ0NspyeN4',
    id: 7344,
    acronym: 'NAUFSS',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'nbu.edu.sa',
    name: 'Northern Border University',
    web_page: 'http://www.nbu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVgr0MsB23MyKcY_K1ovGLixPEYuhc67rWnF-JSDbcllaxtnklkEAQfw',
    id: 7345,
    acronym: 'NBU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ngha.med.sa',
    name: 'College of Nursing and Allied Health Scinces',
    web_page: 'http://www.ngha.med.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwRrbS8DiNa-Frp5uyhlduPfOc367zwXcJe2TWARoCDe_8G1Nq80U40w',
    id: 7346,
    acronym: 'CNAHS',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'nu.edu.sa',
    name: 'Najran University',
    web_page: 'http://www.nu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUFrPdVXQtFe04KWOHj8VoYATAoh0FC3HANFPcHPh4h1l8NJkMwV0zRVg',
    id: 7347,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'pcdp.edu.sa',
    name: 'Private College of Dentistry and Pharmacy',
    web_page: 'http://www.pcdp.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBzdk6JkLf0WQR5oa0AeZDVUpcjNR_jr6ds4HvRUX_7NSd565GQWFm0Ak',
    id: 7348,
    acronym: 'PCDP',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'pmu.edu.sa',
    name: 'Prince Mohammad Bin Fahd University',
    web_page: 'http://www.pmu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTciWvdwC6AprauJ8KCOVIr7JcaXFtySsh3YyIT0nFahnautVs8LOQZo5E',
    id: 7349,
    acronym: 'PMBFU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'pscabha.edu.sa',
    name: 'Prince Sultan College for Tourism and Hotel Scinces',
    web_page: 'http://www.pscabha.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpPfgZWKyvSA5pGDnBRvcs9QNsJIZRMm648cj2Z4wmxDvfI8SAur0KwPU',
    id: 7350,
    acronym: 'PSCFTHS',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'psu.edu.sa',
    name: 'Prince Sultan University',
    web_page: 'http://www.psu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnV68uZKyWbDjQ_JBQTqoYTKt4gDruEHG6S74yjimT_DqfXNHs_PfcnmA',
    id: 7351,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'qc.edu.sa',
    name: 'Qassim Private College',
    web_page: 'http://www.qc.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc-OzPJipFYrUrgw6Lwtic4_S0BSNr2g9WST8GMnhlmVvRStxeywDjrA',
    id: 7352,
    acronym: 'QPC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'qu.edu.sa',
    name: 'Qassim University',
    web_page: 'http://www.qu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjChnFzL_u8IYARDglJrb6P6okk3Tn3J5IBqnWDxO75FSs7Kt3lGMPLg',
    id: 7353,
    acronym: 'QU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'rct.edu.sa',
    name: 'College of Technology at Riyadh',
    web_page: 'http://www.rct.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEn7MHBRJ0mMciD81IiT4MIreN-xa_BtVeHedpUeowLi-1KiB1yNdfOQ',
    id: 7354,
    acronym: 'CTAR',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'riyadh.edu.sa',
    name: 'Riyadh College of Dentistry and Pharmacy',
    web_page: 'http://www.riyadh.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoTL_E6-YQGfjoJ3ZpZNOmOOejzvzE7uTfylRwBE2Ll2QVlmSthGEluQ',
    id: 7355,
    acronym: 'RCDP',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'rug.edu.sa',
    name: 'Princess Nora Bint Abdulrahman University',
    web_page: 'http://www.rug.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfOTkdrjeJDPKPqTAW_DkbBmG6iRiozIy5PC7H0NRpTSNDZ176qG59Bk4',
    id: 7356,
    acronym: 'PNBAU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'saadcollege.com',
    name: 'Saad College of Nursing and Allied Health Sciences',
    web_page: 'http://www.saadcollege.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-rPF61iukkLx6plEo_y11RXnRsQxmDnEDIfUGieBUx8pvIvpIanOQMA',
    id: 7357,
    acronym: 'SCNAHS',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'su.edu.sa',
    name: 'Shaqra University',
    web_page: 'http://www.su.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUCMLHrq30xdRyFa-_SNJvwiIboXkJL2i5HvLILPfvdfON5Ud8lThNJVo',
    id: 7358,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'taibahu.edu.sa',
    name: 'Taibah University',
    web_page: 'http://www.taibahu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT0kzeZazrmrYliKdWlzBpYcy2PAu9HCH3KPQS02qeG6SFOYeXFKyGPw',
    id: 7359,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'tnmeyah.net',
    name: 'College of Technology at Jazan',
    web_page: 'http://www.tnmeyah.net/jct/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTQhpsUq1ahJEB-rBGNEFioKbeBOjBRtjHgsDBs1CkrOKyN1CkMWXE5A',
    id: 7360,
    acronym: 'CTAJ',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'tu.edu.sa',
    name: 'Taif University',
    web_page: 'http://www.tu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR90yF_Wi3lcqsvem-9Uxkh8BkwmpgOXJ-vfUzIRnD2Mbc-y6Dx9wZHtw',
    id: 7361,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ucj.edu.sa',
    name: 'Jubail University College',
    web_page: 'http://www.ucj.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHxQaZO0OuE9zyI_807bpvJNzu0MK1SdtANR_-CpwxPcicwPWb3dKvIQ',
    id: 7362,
    acronym: 'JUC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ud.edu.sa',
    name: 'University of Dammam',
    web_page: 'http://www.ud.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI1cXEopjZUj97VZJbw9INfFI4RscwntVBnyqCWvBS8-OXWkkg2e5SOKo',
    id: 7363,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'uoh.edu.sa',
    name: 'University of Hail',
    web_page: 'http://www.uoh.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQapBXT9N42Cbbd3lBtq9_2VBFTNcBBhKz9xRdqaP10N1b3awjVo4aFfaM',
    id: 7364,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'uqu.edu.sa',
    name: 'Umm Al-Qura University',
    web_page: 'http://www.uqu.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTC5aETULkH12pfLG-xIzOUj0OstW4zsGcr5UIjgWogc6MWOrcWDJLVZhQ',
    id: 7365,
    acronym: 'UAU',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'ut.edu.sa',
    name: 'University of Tabuk',
    web_page: 'http://www.ut.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWhfkf6LMG3g2w8OJ4EUVdtsi-srbGJi9fowvVajpkYhiLS7eRz2ynISw',
    id: 7366,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'yic.edu.sa',
    name: 'Yanbu Industrial College',
    web_page: 'http://www.yic.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9_6-EnoRrkbs70jayJg-oNTFsRatL0XOAnOl51cItVFp94cpKDTU8SA',
    id: 7367,
    acronym: 'YIC',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'yti.edu.sa',
    name: 'Yanbu Technical Institute',
    web_page: 'http://www.yti.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAJ44Ptp4jnlFVL_Ibmuvrj8V6QA9WPm-qjXmuAszWEph4heazlGt_VTo',
    id: 7368,
    acronym: 'YTI',
  },
  {
    alpha_two_code: 'SA',
    country: 'Saudi Arabia',
    domain: 'yuc.edu.sa',
    name: 'Yanbu University College',
    web_page: 'http://www.yuc.edu.sa/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdcaSh-sRAuKCPeqSPdNBo4X2Bywz46koUC-3f7_Hy0XIj9Zkpur-_9T8',
    id: 7369,
    acronym: 'YUC',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'afi-ue.sn',
    name: "L'Université de l'Entreprise",
    web_page: 'http://www.afi-ue.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOBrtMpUuwydjxEVi4dlJm2nAzHUzdOUI-Utz5a6mFnxQnbjLd4V1pL0Y',
    id: 7370,
    acronym: 'LDL',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'bambey.univ.sn',
    name: 'Université Alioune Diop de Bambey',
    web_page: 'http://www.bambey.univ.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVRGgMXFVucT13npTfXQ3Mcvahkh0zi8oPHsSUn-RWv3bGrjL61jtMt8s',
    id: 7371,
    acronym: 'UADDB',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'uahb.sn',
    name: 'Universite Amadou Hampaté Ba',
    web_page: 'http://www.uahb.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7RfI5KKGOUE3FlGmqIAcjXnXXYZ9r3N7BiB-NzVtjTU1piPdeDYaxt_14',
    id: 7372,
    acronym: 'UAHB',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'ucad.sn',
    name: 'Université Cheikh Anta Diop',
    web_page: 'http://www.ucad.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTitEhB3BjznHgdsekI9eNEIQ-QKx7dXndSPjj3Y1IixXo2cjEURCa8PnY',
    id: 7373,
    acronym: 'UCAD',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'udb.sn',
    name: 'Université Dakar Bourguiba',
    web_page: 'http://www.udb.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjwstIKJudEKJ9XykohcECRB2vPjD_XzMDCTqE6HNmj3ONxQkjkcZXp3Vb',
    id: 7374,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'uea.edu.sn',
    name: 'Université Euro-Afrique',
    web_page: 'http://www.uea.edu.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSz-ErNNkccuk5tNdcKfGfDuFAzbcAVjydz76i1XH9CrMvNu1NhOvEW1NM',
    id: 7375,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'ugb.sn',
    name: 'Université Gaston Berger Saint-Louis',
    web_page: 'http://www.ugb.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnxYvOjXY4tErqNTvDi0wcboLUNjntTmL46ivBQnkiwKn-RTR5TE6ESQ',
    id: 7376,
    acronym: 'UGBS',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'unis.sn',
    name: 'Université du Sahel',
    web_page: 'http://www.unis.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtwmd2-e3m9_y0s24n8Trjvjq5_c6_NDSP2hSvZEK8Xy7Dx0LRP1a3Jw',
    id: 7377,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'univ-thies.sn',
    name: 'Université de Thiès',
    web_page: 'http://www.univ-thies.sn/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSyPrqAr5B5_Ntvx653NgKzYE2pYitS5elBcKGi-MMUIMYZbyiWREOkwU-s',
    id: 7378,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'SN',
    country: 'Senegal',
    domain: 'univ-zig.sn',
    name: 'Université de Ziguinchor',
    web_page: 'http://www.univ-zig.sn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQChyMo1JShv2CcXTOez3mFxkXsle101BhqAkTGmC6eA0-c8fSbMOEKWw',
    id: 7379,
    acronym: 'UDZ',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'arts.bg.ac.rs',
    name: 'University of Fine Arts Belgrade',
    web_page: 'http://www.arts.bg.ac.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQppcvCD7D7129WVkDj75xl31uuYLLt85y6KL3UyKyXSwvfO1gYyMyG5Zs',
    id: 7380,
    acronym: 'UFAB',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'bg.ac.rs',
    name: 'University of Belgrade',
    web_page: 'http://www.bg.ac.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiYmDHZYytvo72xMC3bfo4Kv3BhpLsh_PIHeIQdc4PzSEWCTC_Qjeu1dA',
    id: 7381,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'eu.ac.rs',
    name: 'European University',
    web_page: 'http://www.eu.ac.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeDJj3dQoaV5FsTy-0DnPX4u1BLMNnJrMxgbAUqL3f8Z0n3tGyvKLUf2Rw',
    id: 7382,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'kg.ac.rs',
    name: 'University of Kragujevac',
    web_page: 'http://www.kg.ac.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5sS-YERvkNZSvH71rBxm1OTDKKOVuLk_kawUEI0v3znSzE9_2jtWx0Is',
    id: 7383,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'megatrend-edu.net',
    name: 'Megatrend University of Applied Sciences',
    web_page: 'http://www.megatrend-edu.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJQsgN5TCmp6lh4VjJ9ZUip88kYyfNGxo4EgY-zq-4zJLyaN8EFg7fd_I',
    id: 7384,
    acronym: 'MUAS',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'ni.ac.rs',
    name: 'University of Nis',
    web_page: 'http://www.ni.ac.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXOv0zEx3MNLlfEKbYuwWaGfQMCs-ByNiNRaAr0Jc3SVv7_cUQVW5B9C0',
    id: 7386,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'np.ac.rs',
    name: 'Drzavni Univerzitet u Novom Pazaru',
    web_page: 'http://www.np.ac.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDOeEP3Imt_orK_eeJdNnJZ0aX0l8xABGoLCHza2FBYBtaqpwHZNDViw',
    id: 7387,
    acronym: 'DUUNP',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'pr.ac.rs',
    name: 'University of Priština',
    web_page: 'http://www.pr.ac.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd7U8QkLq-o3h2gZvLesnUFj1t0PEThVzFCs55rY6q_WE5_ot8BTqmWg',
    id: 7388,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'privrednaakademija.edu.rs',
    name: 'Privredna Akademija (Business Academy)',
    web_page: 'http://www.privrednaakademija.edu.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLnZHSIocW-6cBFWm7hyAQ4ICJe_H0kry4JcAckGInfvoKlsov8nm5KmAQ',
    id: 7389,
    acronym: 'PA(A',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'singidunum.edu.rs',
    name: 'Singidunum University',
    web_page: 'http://www.singidunum.edu.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSesnHbeY2qNGv5o2NINvCyR2cw3mchB6xGNRA7T41qnir_NnqIxoQLJOo',
    id: 7390,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'uninp.edu.rs',
    name: 'International University of Novi Pazar',
    web_page: 'http://www.uninp.edu.rs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSer7AuqCoRLRbSvbcT1WJv45tAACqCFtlaBBbpzdAUEburY3YhL1K0DQ',
    id: 7391,
    acronym: 'IUNP',
  },
  {
    alpha_two_code: 'RS',
    country: 'Serbia',
    domain: 'uns.ac.rs',
    name: 'University of Novi Sad',
    web_page: 'http://www.uns.ac.rs/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRqZ996GLj4dVBfJjgwAydE8jxQ4Agk7VGhjKTQ3DgGJpbmNwdfFN0IJg',
    id: 7392,
    acronym: 'UNS',
  },
  {
    alpha_two_code: 'SC',
    country: 'Seychelles',
    domain: 'unisey.ac.sc',
    name: 'University of Seychelles',
    web_page: 'http://www.unisey.ac.sc/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOvUrz1_VYVWOTsgYXtNjuVHsKb4PyuKDWDZDRw-2OBzg5FGocs-kkAw',
    id: 7393,
    acronym: 'US',
  },
  {
    alpha_two_code: 'SC',
    country: 'Seychelles',
    domain: 'usaim.edu',
    name: 'University of Seychelles - American Institute of Medicine',
    web_page: 'http://www.usaim.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDp5VMd5GKMEaSonLT_DL9g4DwNPUfOzaz0gXkPPWlXf_YMFP-vSkWPi8',
    id: 7394,
    acronym: 'US-AIM',
  },
  {
    alpha_two_code: 'SL',
    country: 'Sierra Leone',
    domain: 'fbcusl.8k.com',
    name: 'Fourah Bay College University of Sierra Leone',
    web_page: 'http://fbcusl.8k.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHxGuCJCaT3TWxrItn5kfCIvmz-dskZB8j9gtIZxsZe02LrSmnGCNGXTY',
    id: 7395,
    acronym: 'FBCUSL',
  },
  {
    alpha_two_code: 'SL',
    country: 'Sierra Leone',
    domain: 'liccsalsl.org',
    name: 'LICCSAL Business College',
    web_page: 'http://www.liccsalsl.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS666WaKeh62oOlGh2V9vnhwNiffZsSgzqpI9oGp8I4WjQOSCtkI73RADm-',
    id: 7396,
    acronym: 'LBC',
  },
  {
    alpha_two_code: 'SL',
    country: 'Sierra Leone',
    domain: 'nu-online.com',
    name: 'Njala University',
    web_page: 'http://www.nu-online.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2d0S0Zgd18fJ4Gp2Cy-ElY2y4ybu2Zt1gYWIS6fVqpMJoArNbC1FZmkE',
    id: 7397,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'SL',
    country: 'Sierra Leone',
    domain: 'tusol.org',
    name: 'University of Sierra Leone',
    web_page: 'http://www.tusol.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfSQkgZOSjD2QX4HhBu7FWr6hK6smJRWTEBsdNC-5nLNzFuUIcghbynAI',
    id: 7398,
    acronym: 'USL',
  },
  {
    alpha_two_code: 'SG',
    country: 'Singapore',
    domain: 'aventis.edu.sg',
    name: 'Aventis School of Management',
    web_page: 'http://www.aventis.edu.sg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSslH9WyjbST07kUq5sw_T61Oeik_p9ohK025GDbP23GmvuipJ2kne8w',
    id: 7399,
    acronym: 'ASM',
  },
  {
    alpha_two_code: 'SG',
    country: 'Singapore',
    domain: 'ntu.edu.sg',
    name: 'Nanyang Technological University',
    web_page: 'http://www.ntu.edu.sg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTfgEx3SjVyp_CWC0IL435Ee65E7aKubmBUY3HFD_5nYQwp29xLQaKXWo',
    id: 7400,
    acronym: 'NTU',
  },
  {
    alpha_two_code: 'SG',
    country: 'Singapore',
    domain: 'nus.edu',
    name: 'National University of Singapore',
    web_page: 'http://www.nus.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ52TyiagflUH_2wnVs3hALYyzcnXD5TKDdBn9CAQoxdQoHXUPacxmaTfo',
    id: 7401,
    acronym: 'NUS',
  },
  {
    alpha_two_code: 'SG',
    country: 'Singapore',
    domain: 'yale-nus.edu.sg',
    name: 'yale-NUS',
    web_page: 'http://www.yale-nus.edu.sg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSx8fc0Vd1EMTR-w1d9X7lZY57FHIE8KxOqd8DcY4ZGm2MoJxUHsgHZEA',
    id: 7403,
    acronym: 'Y',
  },
  {
    alpha_two_code: 'SG',
    country: 'Singapore',
    domain: 'sim.edu.sg',
    name: 'Singapore Institute of Management (SIM)',
    web_page: 'http://www.sim.edu.sg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEer-TQl-agTXD2e4QaqjSRLVlZxKrF8N_BToLAQNm5fwqWK1hFGiWbw',
    id: 7404,
    acronym: 'SIM(',
  },
  {
    alpha_two_code: 'SG',
    country: 'Singapore',
    domain: 'smu.edu.sg',
    name: 'Singapore Management University',
    web_page: 'http://www.smu.edu.sg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDD7kpWOMyRJistaPuSDGbRiWPjkacgyz0zIQO0k0E7gumbGnWN6o7GA',
    id: 7405,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'SG',
    country: 'Singapore',
    domain: 'taoistcollege.org.sg',
    name: 'Taoist College Singapore',
    web_page: 'http://www.taoistcollege.org.sg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn7YFtcbqN2RcgByQ27Vqf7k3NOxOmSYmaJu43Fw4gtMMKa7cPYix8QZBX',
    id: 7406,
    acronym: 'TCS',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'akademiapz.sk',
    name: 'Police Academy in Bratislava',
    web_page: 'http://www.akademiapz.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR-yQkoE57wA2u2_ch1f4hvtWTAYtGcfOU83uoqu00B-AqFGZHOwcwOg',
    id: 7407,
    acronym: 'PAIB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'aku.sk',
    name: 'Academy of Arts in Banská Bystrica',
    web_page: 'http://www.aku.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1CmgT2EiLRExmx5PvVWFtWoyNS-hZhMTx84r-SKE1ELQrR4Dq2tE2kQ',
    id: 7408,
    acronym: 'AAIBB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'aos.sk',
    name: 'Armed Forces Academy of General Milan Rastislav Štefánik',
    web_page: 'http://www.aos.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIuUehSaPpDovhoGQPLCb9ilcdJ0kDnOIz3hxY6GCJFfCM8YE6kzTxdA',
    id: 7409,
    acronym: 'AFAGMRŠ',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'bisla.sk',
    name: 'Bratislava International School of Liberal Arts',
    web_page: 'http://www.bisla.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFTNaT220NJmbpSpSDil-0zG1zmex9ywnH8Wv95Kzr9DtsVynW_erKBA',
    id: 7410,
    acronym: 'BISLA',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'dti.sk',
    name: 'Technical Institute of Dubnica in Dubnica nad Váhom',
    web_page: 'http://www.dti.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2pdVgDxanaE_oiZdpRxkuzq2YwCH8dSr8fljQAU9ZQhP7l6SMzRe0aF0',
    id: 7411,
    acronym: 'TIDIDNV',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'euba.sk',
    name: 'University of Economics in Bratislava',
    web_page: 'http://www.euba.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkgicZrf_pmU20MRdgYwCBUSVsyhO6NxqEAe4_5XSH9HtRs1IfIQ8lh0mB',
    id: 7412,
    acronym: 'UEIB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'ismpo.sk',
    name: 'International School of Management in Prešov',
    web_page: 'http://www.ismpo.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlFeXwDGkIX8zA9g_sq2SKCsGbN0wDF33tr8J1CNzo2ga0drrvsIWVng',
    id: 7413,
    acronym: 'ISMIP',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'ku.sk',
    name: 'Catholic University in Ruzomberok',
    web_page: 'http://www.ku.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIuUehSaPpDovhoGQPLCb9ilcdJ0kDnOIz3hxY6GCJFfCM8YE6kzTxdA',
    id: 7414,
    acronym: 'CUIR',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'szu.sk',
    name: 'Slovak Medical University',
    web_page: 'http://www.szu.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfhDaFf4XL_7qY_el-MEwlI5evd3nj--HYzPyZk4sJ69jt41Ys_TKWZrAK',
    id: 7415,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'selyeuni.sk',
    name: 'Janos Selye University',
    web_page: 'http://www.selyeuni.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAt9xU5te0fVC-y1hh9MW9yjbnED0ihOaeLmrrgEab09gVRQigjdDhpA',
    id: 7416,
    acronym: 'JSU',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'sevs.sk',
    name: 'University of Central Europe in Skalica',
    web_page: 'http://www.sevs.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvRqjIUNM8_VySAtFYLUveEEayNLF4yxTIFm0hRp8HTvDJbmgwYOI9stg',
    id: 7417,
    acronym: 'UCEIS',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'stuba.sk',
    name: 'Slovak University of Technology in Bratislava',
    web_page: 'http://www.stuba.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9_zcF_NmYkX3Bme5ApB8kFDhc3bY5e6Ny7RhtS-yq5UL1OvikBvEkOg',
    id: 7418,
    acronym: 'SUTIB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'tnuni.sk',
    name: 'University of Alexandra Dubček in Trenčín',
    web_page: 'http://www.tnuni.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAZqAzvyG3pFCz0OFUxqgl6yoZQojwTU9gSGJ2jhtKnud2b5n3nRKUwy3C',
    id: 7419,
    acronym: 'UADIT',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'truni.sk',
    name: 'University of Trnava',
    web_page: 'http://www.truni.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSkIKdme1QxMR8Lmr6tqMH9ULunvp8Q2cSqxN6JfR8bYjXO5hXhf6qLQ',
    id: 7420,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'tuke.sk',
    name: 'Technical University in Kosice',
    web_page: 'http://www.tuke.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5XTFYt362GbdO8vDJFPoLQLqIozb-KLO_gDCFf9gjO-yYIMznDn-vNA',
    id: 7421,
    acronym: 'TUIK',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'tuzvo.sk',
    name: 'Technical University in Zvolen',
    web_page: 'http://www.tuzvo.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwnOlaO8EErAdW8gLk_Ta8nkenCtlYF4rgVezcPftPYe8oDpZ0E04oeMo',
    id: 7422,
    acronym: 'TUIZ',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'ucm.sk',
    name: 'University of SS. Cyril and Methodius in Trnava',
    web_page: 'http://www.ucm.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSkIKdme1QxMR8Lmr6tqMH9ULunvp8Q2cSqxN6JfR8bYjXO5hXhf6qLQ',
    id: 7423,
    acronym: 'USCMIT',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'ukf.sk',
    name: 'University Konstantina Filozov in Nitra',
    web_page: 'http://www.ukf.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2ZUhtf2Qe_NY-HPmRY36iCzt0Ng-9f5ZxJbjg9krYaydpfuDYNMSFZDM',
    id: 7424,
    acronym: 'UKFIN',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'umb.sk',
    name: 'Matej Bel University in Banská Bystrica',
    web_page: 'http://www.umb.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA-Zp0IuhdGv4xutfZBYRbJELru_aHRJ8IBO3wv5-A2toC2MhVR8QmGw',
    id: 7425,
    acronym: 'MBUIBB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'uniag.sk',
    name: 'Slovak University of Agriculture in Nitra',
    web_page: 'http://www.uniag.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3rxkek7j9y2EJD3ZeH1mi9L2UWtMNhWuvpNZgjkmwaVfSPNCA9hr8a0qw',
    id: 7426,
    acronym: 'SUAIN',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'uniba.sk',
    name: 'Comenius University in Bratislava',
    web_page: 'http://www.uniba.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOgpYossWI4isLeFTv62cYpoxr4WV060tc4W4q7ctOIeNuPvdTuvRfTaZJ',
    id: 7427,
    acronym: 'CUIB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'uninova.sk',
    name: 'Law University of Bratislava',
    web_page: 'http://www.uninova.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaGg9KL57X-oOjRFC4TIZjlKOsv1I-A52GYKh9kB7kFZAbZpAXnhoJ_nA',
    id: 7428,
    acronym: 'LUB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'unipo.sk',
    name: 'University of Presov',
    web_page: 'http://www.unipo.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlFeXwDGkIX8zA9g_sq2SKCsGbN0wDF33tr8J1CNzo2ga0drrvsIWVng',
    id: 7429,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'uniza.sk',
    name: 'University of Zilinska',
    web_page: 'http://www.uniza.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvUM-Ol3YCujGa65v2BxCyz8PVqMfCkzP7EGxpL1OsgVkV2rvRKSciN0M',
    id: 7430,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'upjs.sk',
    name: 'Pavol Jozef Safarik University in Kosice',
    web_page: 'http://www.upjs.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYPBjh3dO2_6xs2uC_rz_W9jvLLj6VoaVc2aOpwbWj7_KXpeFIVeqvoKw',
    id: 7431,
    acronym: 'PJSUIK',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'uvm.sk',
    name: 'University of Veterinary Medicine in Kosice',
    web_page: 'http://www.uvm.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVruBnhY9jBmUHqzKeBUlOW07BYNt1HL1hb2TcegsJrMy_qmanoye5yHIF',
    id: 7432,
    acronym: 'UVMIK',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'vsm.sk',
    name: 'School of Management',
    web_page: 'http://www.vsm.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiMP_rfIJ1Tq0Jl-mjWhTUcueXm5PTqonGeGie4iAqt4uKTm5vl1SZkA4',
    id: 7433,
    acronym: 'SM',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'vsmu.sk',
    name: 'University of Performing Arts in Bratislava',
    web_page: 'http://www.vsmu.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSx3oJfYyMOnVo9ejyxOofxR9FyQ98wm3mc5fj9ZMqVnn7hLmLEO5qUo-Nw',
    id: 7434,
    acronym: 'UPAIB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'vssladkovicovo.sk',
    name: 'College in Sládkovičovo',
    web_page: 'http://www.vssladkovicovo.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzQ2nUg_RpSy_5qkZlxLQmuvTlyqi49GO4vC0fGCIum6vC6rzZKa2bQ',
    id: 7435,
    acronym: 'CIS',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'vssvalzbety.sk',
    name: 'St. Elizabeth’s College of Health and Social Sciences in Bratislava',
    web_page: 'http://www.vssvalzbety.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTNwm1vHKtP3iw5Mn9H8Dg5eZsmwpnu5_5tLREiLHiB0CZmx8dqiOjZQU',
    id: 7436,
    acronym: 'SECHSSIB',
  },
  {
    alpha_two_code: 'SK',
    country: 'Slovakia',
    domain: 'vsvu.sk',
    name: 'Academy of Fine Arts and Design in Bratislava',
    web_page: 'http://www.vsvu.sk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAEU-AOFK-bL1yB6wTum8ud8rzsJUEHBCzko0Bq9ShtgFLdjBLCYNsUQU',
    id: 7437,
    acronym: 'AFADIB',
  },
  {
    alpha_two_code: 'SI',
    country: 'Slovenia',
    domain: 'ung.si',
    name: 'University of Nova Gorica',
    web_page: 'http://www.ung.si/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW0gz5yqzIdPNnHRlvDGkhuVL2xtXIN2XUCndn0gAMNzca08bHVGnRtw',
    id: 7438,
    acronym: 'UNG',
  },
  {
    alpha_two_code: 'SI',
    country: 'Slovenia',
    domain: 'uni-lj.si',
    name: 'University of Ljubljana',
    web_page: 'http://www.uni-lj.si/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxx3ejdlbLQ6av-fhP_HsNCujLelFmEzJcHJdWgcG8clBBu3D4UzQgjw',
    id: 7439,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'SI',
    country: 'Slovenia',
    domain: 'um.si',
    name: 'University of Maribor',
    web_page: 'http://www.um.si/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT47xwm0ZNT7jntcB4dIJk1eoGca_blEk7t3o_2UYbk05pivRIIi6NpKiw',
    id: 7440,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'SI',
    country: 'Slovenia',
    domain: 'upr.si',
    name: 'University of Primorska',
    web_page: 'http://www.upr.si/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaJXBQ9cbRxJ2yjlH9Wf6_zyC1l7chX6ZbdsiAypPrhBecMzoH_UpswA',
    id: 7441,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'amouduniversity.org',
    name: 'Amoud University',
    web_page: 'http://www.amouduniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_UUl5IWRXVID2IfUV8vRBSvSSdrqcmRfoin3xukw-s9Wb44-r1yMb6w',
    id: 7443,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'benadiruniversity.net',
    name: 'Benadir University',
    web_page: 'http://www.benadiruniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHFmBPNdve-cBEvMo6UIUvOF9q_RvpHCq71uoNXykQVX_AT-g4520s_g',
    id: 7444,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'bosasocollege.com',
    name: 'Bosaso College',
    web_page: 'http://www.bosasocollege.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzQ2nUg_RpSy_5qkZlxLQmuvTlyqi49GO4vC0fGCIum6vC6rzZKa2bQ',
    id: 7445,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'buraouniversity.com',
    name: 'University of Burao',
    web_page: 'http://www.buraouniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkZ94gv4rblu9g-O5DmUAirDbqs5d8KFl46f-BYxRLBgHVE1JWkGsUU84',
    id: 7446,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'eastafricauniversity.net',
    name: 'East Africa University Bosaso',
    web_page: 'http://www.eastafricauniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcEjjYhWqH8YqyPlJpEvqVBWkVcJZ9uP6w7XfsNR7xmNP5WDq6I4rhld0',
    id: 7447,
    acronym: 'EAUB',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'eastsomaliauniversity.com',
    name: 'East Somalia University',
    web_page: 'http://www.eastsomaliauniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNct81vH0DXj9LPAcfIdxSvCPK_X8OA3jCaZkUYRRgXIzgIL-ku1zAEg',
    id: 7448,
    acronym: 'ESU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'eelouniversity.org',
    name: 'Eelo American University',
    web_page: 'http://www.eelouniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSkE0I7eit-j42F_nOXn2A4bmb4ka65zQp3fWrU5vxboMIZoYTvpfw2jw',
    id: 7449,
    acronym: 'EAU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'gollisuniversity.com',
    name: 'Gollis University',
    web_page: 'http://www.gollisuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUuT7ugiJVUD6aanEBOkW9-5fWU67EwGLQlhUtJVmhXpkqc4aqWq3m_A',
    id: 7450,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'hargeisauniversity.net',
    name: 'University of Hargeisa',
    web_page: 'http://www.hargeisauniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2VZw9XZ5Ao94wEfUEh8-AObLpl0d5MeZSuZMsDe5JdVIeRe3exnVwpmU',
    id: 7451,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'hiiraanuniversity.info',
    name: 'Hiiraan University',
    web_page: 'http://www.hiiraanuniversity.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4R8_ao5GPxu4R085kaTGszThKRijd--zsxvdLm21LQRg_0XR7lLTt09M',
    id: 7452,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'maakhiruniversity.net',
    name: 'Maakhir University',
    web_page: 'http://maakhiruniversity.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLikuUIcTJGFJF0rzsnQOUNo3USH9hSSB4odZFfa-iRVLPyDW26E0gdgY',
    id: 7453,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'mogadishuuniversity.com',
    name: 'Mogadishu University',
    web_page: 'http://www.mogadishuuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFm_Mr6sgtNMR2grIXFl1WmqPeIWK47CMpUyInmPLhD7uZpO9as-O1ugg',
    id: 7454,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'nugaaluniversity.com',
    name: 'Nugaal University',
    web_page: 'http://www.nugaaluniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfnMrISM-k6nNYkBPfsEreBdEHnvFLbHJWqx78hZoAEUPLAjqvUYP1vvw',
    id: 7455,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'puntlandstateuniversity.com',
    name: 'Puntland State University',
    web_page: 'http://www.puntlandstateuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLHPqddx2adBgkY1LwHf5YyH0A_pv9nLaZwBx9YQ-yfeuKCmbpnRVozxXr',
    id: 7456,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'sanaaguniversity.com',
    name: 'Sanaag University of Science and Technology',
    web_page: 'http://www.sanaaguniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_QBU2D7U5LkJMX4Wnnai2XQpvirQold5l3vRQDvUdnAxHdnNtsGHta3Q',
    id: 7457,
    acronym: 'SUST',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'somtech.org',
    name: 'Somaliland University of Technology',
    web_page: 'http://www.somtech.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbf0dj7wEJBTBNLaH7oYMv5WB6hIqG0mENcyWFbI9p5hUI4rIINzB2bQ',
    id: 7458,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'SO',
    country: 'Somalia',
    domain: 'universityofsomalia.net',
    name: 'University of Somalia',
    web_page: 'http://www.universityofsomalia.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfnMrISM-k6nNYkBPfsEreBdEHnvFLbHJWqx78hZoAEUPLAjqvUYP1vvw',
    id: 7459,
    acronym: 'US',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'cput.ac.za',
    name: 'Cape Peninsula University of Technology',
    web_page: 'http://www.cput.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4qUwqQd-1LSc4dwoj5fk8WA2-MDjYfRTR0JLmEULF9wt6skoeCXH_p_c',
    id: 7460,
    acronym: 'CPUT',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'cut.ac.za',
    name: 'Central University of Technology Free State',
    web_page: 'http://www.cut.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKwPon1JGwMu-_1UD8fGh6nWocCfvU6CyiQcX3h0WW3yEC5Il6pMUWHw',
    id: 7461,
    acronym: 'CUTFS',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'dit.ac.za',
    name: 'Durban Institute of Technology',
    web_page: 'http://www.dit.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdcI818WfDbUPx-Kl_NQi7uj6sxtIhHoevHPKW1Oh58blKWwmQbfLtAA',
    id: 7462,
    acronym: 'DIT',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'nmmu.ac.za',
    name: 'Nelson Mandela Metropolitan University',
    web_page: 'http://www.nmmu.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6tKQuq8Vllf83S3nKA-6wnIYO5Gqi_7Kdz0TxWUojxRI6dqj_VMNmHmw',
    id: 7463,
    acronym: 'NMMU',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'ru.ac.za',
    name: 'Rhodes University',
    web_page: 'http://www.ru.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRH3676wysZ7oH_CDpQBEFR3WsrvAmyg8GxdHvZQHALwFvponYzw0UZGoc',
    id: 7464,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'sun.ac.za',
    name: 'University of Stellenbosch',
    web_page: 'http://www.sun.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDnqm6yryCKtmUYXbFDTZ1O81xjRZoT3k16ue3XDeQkUTbHWnLDkhIm46VZQ',
    id: 7465,
    acronym: 'US',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'tut.ac.za',
    name: 'Tshwane University of Technology',
    web_page: 'http://www.tut.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShZ57jI3GulVcplXr_Wqcw7se1DTfXOBVUlJj2Job34NsGkt6Jp4TFDT4',
    id: 7466,
    acronym: 'TUT',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'uct.ac.za',
    name: 'University of Cape Town',
    web_page: 'http://www.uct.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrFY3Ht8C_rarfwCr5buWJti6mfqIYs2O70sWcsbHW_AsKTk_9EXgfiEt3ig',
    id: 7467,
    acronym: 'UCT',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'ufh.ac.za',
    name: 'University of Fort Hare',
    web_page: 'http://www.ufh.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnt65vjdPBeCi9WKABXAdcxo474CIzly0MY4-lYDmV7iai8M1NzWYxqW7I',
    id: 7468,
    acronym: 'UFH',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'uj.ac.za',
    name: 'University of Johannesburg',
    web_page: 'http://www.uj.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP4YZ3Z4w2IK1k4ObJEw7PDxhJ9KoM0fgscob6cWPLtQUFGmqDVcaZX7yp',
    id: 7469,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'ukzn.ac.za',
    name: 'University of KwaZulu-Natal',
    web_page: 'http://www.ukzn.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLQ2DDX169vE0yT-MuqgoLwRtp0Wlh8UkkXzT9rd7HOKoXImbzKqKCNg',
    id: 7470,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'ul.ac.za',
    name: 'University of Limpopo',
    web_page: 'http://www.ul.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnGEpdYoF9iVsW8KtFPS4TqA9T9GslGHeP-f-JPdEOYj-BsorLui-rtEi8',
    id: 7471,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'unisa.ac.za',
    name: 'University of South Africa',
    web_page: 'http://www.unisa.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQe2HQzr_rlrhxDEecAyhuXMpWta0gL36twzFqW-SUxEkAkNfdYTXWiWggA',
    id: 7472,
    acronym: 'USA',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'univen.ac.za',
    name: 'University of Venda',
    web_page: 'http://www.univen.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYMb3SNdYRYtndFIAmj-sAQkMez0Xecs-hwhJj_AYSsQmwYkM11Jch4iY',
    id: 7473,
    acronym: 'UV',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'uniwest.ac.za',
    name: 'University of North West',
    web_page: 'http://www.uniwest.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-TZL5RqhXXGCMe9_ClIJP03At1N8qXnmuHzFcsau_Qf84E4xD1CrIkdaTQA',
    id: 7474,
    acronym: 'UNW',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'uovs.ac.za',
    name: 'University of the Free State',
    web_page: 'http://www.uovs.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTieHQTQ9z-dkBtZF0EjD01DZ5bcwMB8cNk5mqL5eydk-vtPGJVaWVoog',
    id: 7475,
    acronym: 'UFS',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'up.ac.za',
    name: 'University of Pretoria',
    web_page: 'http://www.up.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaXXvTlQef4UIS7C6A34WtQUPJv6qhK7P6ew7Iayf6eLSq3UPtbdTSEw',
    id: 7476,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'uwc.ac.za',
    name: 'University of the Western Cape',
    web_page: 'http://www.uwc.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1C871zfOtioFZeGnM99IfJj2w9e-kCWoJRwfrO8_G8Ir-WNGq1Vk30A',
    id: 7477,
    acronym: 'UWC',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'uzulu.ac.za',
    name: 'University of Zululand',
    web_page: 'http://www.uzulu.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPWySM-LG_SD7YCNbc2ovyNZSPKIe8E4dTwydKY1aHXFA3CsBgjZVKtaJxMw',
    id: 7478,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'vut.ac.za',
    name: 'Vaal University of Technology',
    web_page: 'http://www.vut.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFE_-z0U3Hs2urZ-tZYHJsNyNu7yRQ3TSusmuM2ygW4QKktIii38lrrA',
    id: 7479,
    acronym: 'VUT',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'wits.ac.za',
    name: 'University of Witwatersrand',
    web_page: 'http://www.wits.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIqqltnWxW82XFxI51l7NYpNK2uWS8ybQVKmEFlE2lBkSqdK3v5troo3BY3g',
    id: 7480,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'wsu.ac.za',
    name: 'Walter Sisulu University for Technology and Science',
    web_page: 'http://www.wsu.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVJmfiIf3Sq38P7rNnn3C28B0Uap92-Zz6uz6RvAmZbGaExnhT2LUW7Q',
    id: 7481,
    acronym: 'WSUFTS',
  },
  {
    alpha_two_code: 'ZA',
    country: 'South Africa',
    domain: 'msa.ac.za',
    name: 'Monash Univerisity South Africa',
    web_page: 'http://www.msa.ac.za/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXK03GtBlG2nUOZ4GQp1Au3Drg6poAdv4yWIc8Txs0ZeDBkty0Xv4HjX0',
    id: 7482,
    acronym: 'MUSA',
  },
  {
    alpha_two_code: 'SS',
    country: 'South Sudan',
    domain: 'juba.edu.sd',
    name: 'University of Juba',
    web_page: 'http://www.juba.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4NrSEMGqTf48kYzRGB2sQG-qpg-fw1rgoHrIATKH_xhz6MF8wBbHzg4c',
    id: 7483,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'SS',
    country: 'South Sudan',
    domain: 'unbeg.edu.sd',
    name: 'University of Northern Bahr El-Ghazal',
    web_page: 'http://www.unbeg.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScoECMg3G5aMS8I61drPqf2bGbtzUux8aW15Pe6at7TOaswHzI2_QMmF0',
    id: 7484,
    acronym: 'UNBE',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'barcelonagse.eu',
    name: 'Barcelona Graduate School of Economics',
    web_page: 'http://www.barcelonagse.eu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdkqary3mgLVvcAbSiSc2q_ALzhew4gF8AShf6InkSDmAWYBGCZ8AZKLg',
    id: 7485,
    acronym: 'BGSE',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'bircham.edu',
    name: 'Bircham International University',
    web_page: 'http://www.bircham.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQL-aRG_VTkJuWR8LW5fqWFiGfy_HbPdjNHBVuXQAtwJFtcSHDwpHK3cA',
    id: 7486,
    acronym: 'BIU',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ceu.es',
    name: 'Universidad de San Pablo CEU',
    web_page: 'http://www.ceu.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGk_fvziYNjTujn7r_VgNfHHlh7u-r7KP3wXmr9ik1dY6rzCXS9KQ1WQ',
    id: 7487,
    acronym: 'UDSPC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'deusto.es',
    name: 'Universidad de Deusto',
    web_page: 'http://www.deusto.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpirCikR5spuqxwOyM3Ytwg3zE9wqApzg6ZPw-HioL0bbdL-DXc5SYbw',
    id: 7488,
    acronym: 'UDD',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ehu.es',
    name: 'Universidad del País Vasco',
    web_page: 'http://www.ehu.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlF7p34T8nilEju2g_LE96J2h6cioLrnx_OHZu_RLRgx2MfaWVq5T3NaNKzQ',
    id: 7489,
    acronym: 'UDPV',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'esic.es',
    name: 'Escuela Superior de Gestion Comercial y Marketing (ESIC)',
    web_page: 'http://www.esic.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8HAuCb80lsYsnzRdqWTPY7itAWVB_ZavaOEtLMbZj9F2cyC-uQJ-1aCkj',
    id: 7490,
    acronym: 'ESDGCYM(',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'global-business-school.org',
    name: 'Global Business School Barcelona',
    web_page: 'http://www.global-business-school.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi48BpVI9_zjS2V0ndw5x4KcqPgYWXQpi7u9lPoImTnHFxWGnbkq1srx7Y',
    id: 7491,
    acronym: 'GBSB',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ie.edu',
    name: 'IE University',
    web_page: 'http://www.ie.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9BSfphYD8lc94Dx-8wt8Z7CI99UVTksWeHkkstNp2EUDzwBlIvD1KB-M',
    id: 7492,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'iuse.edu.es',
    name: 'International University of Southern Europe',
    web_page: 'http://www.iuse.edu.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBawhbCxGWlRwIbmPPObrg-Iwyn4w5cz84VUulyrEkP5pvmyk6nfcxMRA',
    id: 7493,
    acronym: 'IUSE',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'lasalleuniversities.net',
    name: 'La Salle Universities - International Programmes',
    web_page: 'http://www.lasalleuniversities.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuWCmB3BwWRo6dSC1dCdfo4GCMfefBfMS73ik8Gzd55kLDk5FfxUoUy6M',
    id: 7494,
    acronym: 'LSU-IP',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'muni.es',
    name: 'Mondragon Univertsitatea',
    web_page: 'http://www.muni.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5g1szJOzGal72wableLf1qfB2YD-bwOBqLx8LigJxkYE-YI4UkJZz9kE',
    id: 7495,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'schillermadrid.edu',
    name: 'Schiller International University Madrid',
    web_page: 'http://www.schillermadrid.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4kgYI0gTRHORdYhJG4aJyuXiajcuVksX3v920N9d6cpfS3uX3QZ3zNKg9',
    id: 7496,
    acronym: 'SIUM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uab.es',
    name: 'Universitat Autónoma de Barcelona',
    web_page: 'http://www.uab.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1rgebXlrTt1fFeNy8Uj3NsxMW8onqqSeYjwmo97ZKLqf079RyhQgr8P4',
    id: 7497,
    acronym: 'UADB',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ua.es',
    name: 'Universidad de Alicante',
    web_page: 'http://www.ua.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiWIDvzFZf2vq13Btwi_imsmkekx5sKQ52YbOrDVlhCJt-fsGtUvggbSE',
    id: 7498,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uah.es',
    name: 'Universidad de Alcalá de Henares',
    web_page: 'http://www.uah.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0IXzfxzSHsXP-tkAokmMG_MOjjvrQ45lqjhjhniUwQVRpXSCO06M6X70',
    id: 7499,
    acronym: 'UDADH',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ualm.es',
    name: 'Universidad de Almería',
    web_page: 'http://www.ualm.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbyNxKnGnfW6mpvefqKa0RY9EbNOGZ0GIH-GjN6S1yRUcbXNRY0X16J5Vw',
    id: 7500,
    acronym: 'UDA',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uam.es',
    name: 'Universidad Autónoma de Madrid',
    web_page: 'http://www.uam.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaCgS6sOqfFe_JMgjxvcCxVq3kkCR8WI1u5veHySVT3Z-sgXHQ--VHihLu',
    id: 7501,
    acronym: 'UADM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uax.es',
    name: 'Universidad Alfonso X El Sabio',
    web_page: 'http://www.uax.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJE_qqLlW6BbCZ7RQ7A_c2f9HY5ET-Jn9hvkyFe36gYJ4cZoP51IfnKEE',
    id: 7502,
    acronym: 'UAXES',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ub.es',
    name: 'Universitat de Barcelona',
    web_page: 'http://www.ub.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJpAbpHv5lCD4kg3cfUsgp3cDrCC_e-EtEJCBDToUBTtHKRaqhODR6DdAv',
    id: 7503,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ubu.es',
    name: 'Universidad de Burgos',
    web_page: 'http://www.ubu.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLdfvZ4RInS1uQs8w9T23lidDr3xCjl57t1Ha1GGu8-ybcxMXxWJp9_A',
    id: 7504,
    acronym: 'UDB',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uc3m.es',
    name: 'Universidad Carlos III de Madrid',
    web_page: 'http://www.uc3m.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi1yHVtSYLPzI0fphGHhy7KiHXCApCW6Y66tWnVoS9NoHVObFYRwP89WU',
    id: 7505,
    acronym: 'UCIDM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uca.es',
    name: 'Universidad de Cádiz',
    web_page: 'http://www.uca.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1pEgZ8DrM2RtuYadqQGFPR2I_39PaZZe-lAFal6xynnXubHW2aI6hBwU',
    id: 7506,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ucam.edu',
    name: 'Universidad Católica San Antonio',
    web_page: 'http://www.ucam.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTveMbN1BwWLDNexfRGIWto63enz5Ryov-Ccakmp1XYldwRnEkf_NpGPMin-Q',
    id: 7507,
    acronym: 'UCSA',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ucavila.es',
    name: 'Universidad Católica de Ávila',
    web_page: 'http://www.ucavila.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZgq08u6El4uDmm_ZlHQCGxb7rdepy04WDkS8dx1A2SDCGFTGdOCXCquM',
    id: 7508,
    acronym: 'UCDÁ',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uchceu.es',
    name: 'Universidad Cardenal Herrera-CEU',
    web_page: 'http://www.uchceu.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXeB7JscFKrWD26IXkhtf8j2k0qkcOtbPMKyfp6C4dADnHOImbPo46rBs',
    id: 7509,
    acronym: 'UCH',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uclm.es',
    name: 'Universidad de Castilla La Mancha',
    web_page: 'http://www.uclm.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTow-uz3a9SMJ07DiaW7-VUBb_X5SlL5itQvpcZwtZaO1qrWqOULq6l9es',
    id: 7510,
    acronym: 'UDCLM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ucm.es',
    name: 'Universidad Complutense de Madrid',
    web_page: 'http://www.ucm.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs9sRaOXhkbPCP0pLLhjeBSENiFbwIvYdwmVAh6tIV6fIq86WyqG1YF3yb',
    id: 7511,
    acronym: 'UCDM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ucv.es',
    name: 'Universidad Católica de Valencia',
    web_page: 'http://www.ucv.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSywLMlbxyRKxrf3O8kF0IsldPoz0WuqQgJr7bLX0IDl1B2q2yM5HFbgC0w',
    id: 7513,
    acronym: 'UCDV',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'udc.es',
    name: 'Universidad de La Coruña',
    web_page: 'http://www.udc.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuBqPj6LNCX077IYuofXdykrzJRDxzNIuRsiqhLonNtTT4R2EqXblget8',
    id: 7514,
    acronym: 'UDLC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'udg.es',
    name: 'Universidad de Gerona',
    web_page: 'http://www.udg.es/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTFEviU84_2K9-YTxHgSMe-tncHTT5nwwCADcTtc2RBNg2ukDS5LpeyRKc',
    id: 7515,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'udl.es',
    name: 'Universitat de Lleida',
    web_page: 'http://www.udl.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbeU6yYFjeSqBlan6DYnHdUDyxqiJzgEE2syVKpPb9_7jSAm-rziLKNaI',
    id: 7516,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uem.es',
    name: 'Universidad Europea de Madrid',
    web_page: 'http://www.uem.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3yESCUOijjjRo_qxxQvHV4MCO68bjWYAxcVpkkXHffbhUQemlGIK3Xzg',
    id: 7517,
    acronym: 'UEDM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ufv.es',
    name: 'Universidad Francisco de Vitoria',
    web_page: 'http://www.ufv.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkttuESngABh1orWwOi9HSDbCFUhDeAKMjPv6uX39AXf4arpx8zQgzSmo_',
    id: 7518,
    acronym: 'UFDV',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ugr.es',
    name: 'Universidad de Granada',
    web_page: 'http://www.ugr.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6y3rYqzcuxfTP_GUExklvPZ20a40kMmm14tmR1YSshI6_7rQ18K6wXA',
    id: 7519,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uhu.es',
    name: 'Universidad de Huelva',
    web_page: 'http://www.uhu.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBHzgXX-WqfkgsVFjTzE37HtRjpFtG8uNm2zw90LSx99BiViyxZ7j7EHE',
    id: 7520,
    acronym: 'UDH',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uia.es',
    name: 'Universidad Internacional de Andalucía',
    web_page: 'http://www.uia.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQGOAApgJeIapbG-8ccEERiYdjIDJRFg-jbf2F8MUAl4A56UzYMwNvgQ',
    id: 7521,
    acronym: 'UIDA',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uib.es',
    name: 'Universitat de les Illes Balears',
    web_page: 'http://www.uib.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMOIeI65xaiRT2EMu3spNaEgvqgM6fisA1pYuASsemXEsWZqEWoWcJr3I',
    id: 7522,
    acronym: 'UDLIB',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uic.es',
    name: 'Universitat Internacional de Catalunya',
    web_page: 'http://www.uic.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpfYaNkVaQHhl6DbvHxSBhp6bPKKpIny1nHcmhdBZqROBQzMwbDyVG8A',
    id: 7523,
    acronym: 'UIDC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uimp.es',
    name: 'Universidad Internacional Menéndez Pelayo',
    web_page: 'http://www.uimp.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTM56mdbrsklUFjP79rwxjSaHUsfneScH0Bw56XtQbaQBnUqhvR9kZvw',
    id: 7524,
    acronym: 'UIMP',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ujaen.es',
    name: 'Universidad de Jaén',
    web_page: 'http://www.ujaen.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5LUHBWb_7xPzTi-SqvLMxiP8Xy9P6kEol0jxbpTXzJ3YFayRMiG3Jpdc',
    id: 7525,
    acronym: 'UDJ',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uji.es',
    name: 'Universitat Jaume I de Castellón',
    web_page: 'http://www.uji.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTu_2y0JmzjtPWn0f_ekFatPg669zqj1kIWCAt_JgtBA1i_CM1dYGLXBLYw',
    id: 7526,
    acronym: 'UJIDC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ull.es',
    name: 'Universidad de La Laguna',
    web_page: 'http://www.ull.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7i50w48ZW-w8yoohvsxxPCapKOSToRvvlV5gF5gZS3FyIvJaOdJlxZfklvQ',
    id: 7527,
    acronym: 'UDLL',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'ulpgc.es',
    name: 'Universidad de Las Palmas de Gran Canaria',
    web_page: 'http://www.ulpgc.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTK2LDOvsdmoAGYm5uRqNlzR5QUjtWu91slOSemZrrjMeVtfKomP44DYA',
    id: 7528,
    acronym: 'UDLPDGC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uma.es',
    name: 'Universidad de Málaga',
    web_page: 'http://www.uma.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbVf4aJO6pkFnuW8pmA4pF3UsNdsNHsAroC7QrX97Gjbm9d_IUHNFOiMVV',
    id: 7529,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'um.es',
    name: 'Universidad de Murcia',
    web_page: 'http://www.um.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRafi3Q2xDZD1jh9CDmCv8wxxtr14dV7ZlnnO56QS4tlUyfr2m_tY48CQ',
    id: 7530,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'umh.es',
    name: 'Universidad Miguel Hernández de Elche',
    web_page: 'http://www.umh.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdwNoF91Ga7c7L8J68adji9ETKiKHDYo0DKn4EsGslqFJGC-zKbyd0PKEs',
    id: 7531,
    acronym: 'UMHDE',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'unavarra.es',
    name: 'Universidad Pública de Navarra',
    web_page: 'http://www.unavarra.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyeRb3T70jj0MFaTPMjwNIwwAtM8mnrsjd_R__rWJX3DHNRo3qLMPC2k8',
    id: 7532,
    acronym: 'UPDN',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'unav.es',
    name: 'Universidad de Navarra',
    web_page: 'http://www.unav.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMUyqnKeuCx3Sgt7mFQbHy1vlHPFWRQRGXQKJycKZtStfh6dXsctDhvz0',
    id: 7533,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uned.es',
    name: 'Universidad Nacional de Educación a Distancia',
    web_page: 'http://www.uned.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxAhNRcLYeDAQz4A2MI2hk3t14mEKzF5TUq0ld2z99XZO1VfTEPY_EQ4If',
    id: 7534,
    acronym: 'UNDEAD',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'unex.es',
    name: 'Universidad de Extremadura',
    web_page: 'http://www.unex.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT62qnep00C_SFedkMYPdFfAeuHQH2piL5BwYYa1Gdlgd33Hyn5d9NC0So',
    id: 7535,
    acronym: 'UDE',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uniaam.uia.es',
    name: 'Universidad Internacional de Andalucía Sede Antonio Machado de Baeza',
    web_page: 'http://www.uniaam.uia.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2ne4ps4UJLFfMvFXisg2agZjYy50vhRAz7tvzfQYXlpdxOwSCkw2dJ1wz',
    id: 7536,
    acronym: 'UIDASAMDB',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uniactiva.com',
    name: 'Universidad Abierta Interactiva',
    web_page: 'http://www.uniactiva.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvdSIptmEsnRuo94f0x2CLnLOtxDCRSkMcGfvtK7mjzwqDppMXGFAn6PRi',
    id: 7537,
    acronym: 'UAI',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uniara.uia.es',
    name: 'Universidad Internacional de Andalucía Sede La Rábida',
    web_page: 'http://www.uniara.uia.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZSOtx8I9nCb6CXQ6myk_My7Nz3v566Oek3jjkpzhS94Ygamd_QC_EYBY',
    id: 7538,
    acronym: 'UIDASLR',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'unican.es',
    name: 'Universidad de Cantabria',
    web_page: 'http://www.unican.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjGfM0Yl_gyfvhlY7_2k1UvN7L6z7BUfOhdyzEkjbPplJeMmd1z292Qw',
    id: 7539,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uniovi.es',
    name: 'Universidad de Oviedo',
    web_page: 'http://www.uniovi.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOFD5mQoVpBchGyTO5oGcrDg09dRRQf571tRt2Ab5HzNPlenyjXPSSNg',
    id: 7541,
    acronym: 'UDO',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'unirioja.es',
    name: 'Universidad de La Rioja',
    web_page: 'http://www.unirioja.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-WxtuONJS6zPk0tadlKFXyGh0YsWXznNTlVoRKsQDc45MZWXm09Y2v0c',
    id: 7542,
    acronym: 'UDLR',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'universidadatlantico.org',
    name: 'Iniciativa Universidad del Atlantico',
    web_page: 'http://www.universidadatlantico.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQR5Wa51l_af6QhGeQN08T4j1BMwLyhRvGkc7E6Iw8tcXzGERxnUMbVZWw',
    id: 7543,
    acronym: 'IUDA',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'unizar.es',
    name: 'Universidad de Zaragoza',
    web_page: 'http://www.unizar.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6HdccQz0ycER5CAOqEYJo2UBi5TU6jlMbsazpTyFYMCmSWYrn4fNABg',
    id: 7544,
    acronym: 'UDZ',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'unnet.es',
    name: 'Universidad Antonio de Nebrija',
    web_page: 'http://www.unnet.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIDen23MLNkHwOA0mBA4O4WthtyFRzjtN9KbLbl3ZREw322AKbtnjszbw',
    id: 7545,
    acronym: 'UADN',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uoc.es',
    name: 'Universitat Oberta de Catalunya',
    web_page: 'http://www.uoc.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrMxUEZfg70Zl-UUBuEhWG0aUr6MFHMyqVaGDklXmIRNDq7w5-pg0F90I',
    id: 7546,
    acronym: 'UODC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'upc.edu',
    name: 'Universidad Politécnica de Cataluna',
    web_page: 'http://www.upc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTowkdGDuVBAK9es8oAsKgwsqvstu3XxvKl-Bzqc4jksznyAwHcGklgbl8',
    id: 7547,
    acronym: 'UPDC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'upco.es',
    name: 'Universidad Pontificia Comillas',
    web_page: 'http://www.upco.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ0XjyWUUqYdM5zOaqivMiz5FXFVfOblNPDQHyLmvbJwIdkrWz9nPEEw',
    id: 7548,
    acronym: 'UPC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'upct.es',
    name: 'Universidad Politécnica de Cartagena',
    web_page: 'http://www.upct.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL2qFUZy4yfbESkevNQmeDmhda-8MBwkzkWRHTFYRSHcn7Gc9nSw-XxE0W',
    id: 7549,
    acronym: 'UPDC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'upf.es',
    name: 'Universitat Pompeu Fabra',
    web_page: 'http://www.upf.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBf85MOXcX73LLTvFwqyfZ9li7VRWZb8ba95XOH5d3gh6pm5su5IuXHw',
    id: 7550,
    acronym: 'UPF',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'upm.es',
    name: 'Universidad Politécnica de Madrid',
    web_page: 'http://www.upm.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTEwdPhfaXj7CQAAAqUR_YT33GRHTUeIZE0nV9onSxWhOKnCMT83QOAGA',
    id: 7551,
    acronym: 'UPDM',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'upo.es',
    name: 'Universidad Pablo de Olavide',
    web_page: 'http://www.upo.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfw6B5GqyBNUNAOjckgk7FBODiqMgHflhjCzi3Z8lUUIfzNCBY3UBIP9k',
    id: 7552,
    acronym: 'UPDO',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'upsa.es',
    name: 'Universidad Pontificia de Salamanca',
    web_page: 'http://www.upsa.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUX6XxDRw8sANQCmX2dQeXnDC6GoWjs5TX1GQ4t_nLPH4KDq5B_1dw7p5nGg',
    id: 7553,
    acronym: 'UPDS',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'upv.es',
    name: 'Universidad Politécnica de Valencia',
    web_page: 'http://www.upv.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBuDBLYH_-mn2ndQc_G03yWMtBojpILYljhyQyx6UIbkPvo9zZUZatfocA',
    id: 7554,
    acronym: 'UPDV',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'urjc.es',
    name: 'Universidad Rey Juan Carlos',
    web_page: 'http://www.urjc.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXrmpP1MUVVRmkxLwkxFq8St9NFQRaPTJYHrKd_dznLW8WRbVpU0KK9g',
    id: 7555,
    acronym: 'URJC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'url.es',
    name: 'Universitat Rámon Llull',
    web_page: 'http://www.url.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjmhzDlSMkVXN8OYh-TO7uZsDh7gGZRNU6BxALNK8IaBL92NJmmdyX5w',
    id: 7556,
    acronym: 'URL',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'urv.es',
    name: 'Universitat Rovira I Virgili Tarragona',
    web_page: 'http://www.urv.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5pvZ_LY3Ed5vmJ70s7VUyrWkLP5jCq7LiQmEPIfympWoGY1yT35iVKGAB',
    id: 7557,
    acronym: 'URIVT',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'usal.es',
    name: 'Universidad de Salamanca',
    web_page: 'http://www.usal.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4uAMzx2zEiRChGXPMHkNLnPBDQKge73HJKAy4Yted8Jtgv6EGWorrc8s',
    id: 7558,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'usc.es',
    name: 'Universidad de Santiago de Compostela',
    web_page: 'http://www.usc.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ88TswCjjVDWkgr6qr-kGERwoJrqfYNFJcc-NuNdKp-QZX7NxFGbeKgc',
    id: 7559,
    acronym: 'UDSDC',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'us.es',
    name: 'Universidad de Sevilla',
    web_page: 'http://www.us.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEHwVvaZkAxm-tmYAu7XyWdESZ3nRVdtTkJriap8TM5_pv48I53y0W7sQ',
    id: 7560,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uva.es',
    name: 'Universidad de Valladolid',
    web_page: 'http://www.uva.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbQnUmDZHupV0zGgg8EejsO_hWng1UUWNQsf5W_NzOn4_vgf5L4OpRnWI',
    id: 7561,
    acronym: 'UDV',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uv.es',
    name: 'Universidad de Valencia',
    web_page: 'http://www.uv.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAT-OKHe1CcIfMUhl5JrFVK0qooZ68Udj2UbXaACX-4d8_ksOnO-odcw',
    id: 7562,
    acronym: 'UDV',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uvic.es',
    name: 'Universitat de Vic',
    web_page: 'http://www.uvic.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaWWhpDCzb9OhLWXmEl_gPDvgXSVwJ3d_GFFC2MP4DWpCsy0mbmyKUFMyc',
    id: 7563,
    acronym: 'UDV',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'uvigo.es',
    name: 'Universidad de Vigo',
    web_page: 'http://www.uvigo.es/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpl_0MalbVsxW0TTi1Hp70xVeb1SjTBJSXROPZ9OtLP8juk2KuCuyYqv89',
    id: 7564,
    acronym: 'UDV',
  },
  {
    alpha_two_code: 'ES',
    country: 'Spain',
    domain: 'villanueva.edu',
    name: 'Centro Universitario Villanueva',
    web_page: 'http://www.villanueva.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbitztD6QjZ24sstmDFJVqJE3Aec-ZicA3F5JHmhA5gB0tQujJNi1bJA',
    id: 7565,
    acronym: 'CUV',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'accimt.ac.lk',
    name: 'Arthur C Clarke Institute of Modern Technologies',
    web_page: 'http://www.accimt.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSr6tlm7_k5xRmbMGBpjgPdXtJ5oqFISx4Zyu8J75jY9bfmhPSStCzMuM0',
    id: 7566,
    acronym: 'ACCIMT',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'bbu.ac.lk',
    name: 'Buddhasravaka Bhikshu University',
    web_page: 'http://www.bbu.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmc_Qvc-VHcw6D4uhrQYh4-umIEg6HFLlrNvrQcDCqeE5DXI0_AZKqZQ',
    id: 7567,
    acronym: 'BBU',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'bpu.ac.lk',
    name: 'Buddhist and Pali University of Sri Lanka',
    web_page: 'http://www.bpu.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRLTMMwg3gK_okVIxiKqzn7YTYeIGRgaiJzg4F1DRSnLhEytNfj7Q5dw',
    id: 7568,
    acronym: 'BPUSL',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'cmb.ac.lk',
    name: 'University of Colombo',
    web_page: 'http://www.cmb.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZF-KX3a0MzCQvxkz_i1YTt3k7vlBOhkmmE3uB1ehVpaE-XgPkhhDcJ4cG',
    id: 7569,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'esn.ac.lk',
    name: 'Eastern University of Sri Lanka',
    web_page: 'http://www.esn.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgwR8lBhmrOjSgaMF3laAKa0DiKAYgLvYKbBzs77fnLjF0SPatlueFvg',
    id: 7570,
    acronym: 'EUSL',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'jfn.ac.lk',
    name: 'University of Jaffna',
    web_page: 'http://www.jfn.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShbcDc4gQet7jwydkCN51P1_lzxWPq6oJjr1014DLi6va6HTvHhMuMZg',
    id: 7571,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'kdu.ac.lk',
    name: 'Kotelawala Defence University',
    web_page: 'http://www.kdu.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGHchgVUC6RMa-k6UMR7bQyv8nbXascVmo9FZTltHVtk4b1z5e3vhsmA',
    id: 7572,
    acronym: 'KDU',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'kln.ac.lk',
    name: 'Postgraduate Institute of Pali & Buddhist Studies (University of Kelaniya)',
    web_page: 'http://www.kln.ac.lk/institutes/pgipbs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpnr7pyLAWP5f1Dab6bMynuFm0X7JW-s5uBR_JZmSj2Oe25O4OaG1GKv5q',
    id: 7573,
    acronym: 'PIP&BS(K',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'kln.ac.lk',
    name: 'University of Kelaniya',
    web_page: 'http://www.kln.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAcNmGXWcCgsoqc5bGiurDY95aoB898h6nuSgvHI3jyN1Nsjc1riZRtg',
    id: 7574,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'mrt.ac.lk',
    name: 'University of Moratuwa',
    web_page: 'http://www.mrt.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR46MfwXoSywHImh2eWIldhY4L09zYJNHxY0fmByyNorKFdx-rkHeKj6sk',
    id: 7575,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'ou.ac.lk',
    name: 'Open University of Sri Lanka',
    web_page: 'http://www.ou.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnkBfyt50xHa6LpqCmRQsToU4tkFURKHhDRPyGQyr_MIL6BQ7iKfeJbkW4',
    id: 7576,
    acronym: 'OUSL',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'pdn.ac.lk',
    name: 'University of Peradeniya',
    web_page: 'http://www.pdn.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHZHIlaNRKeGfWwO90QX5oPkGVdSs6M3idSDyeGB5Adkswqn9eype2sQ',
    id: 7577,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'pgia.ac.lk',
    name: 'Postgraduate Institute of Agriculture (PGIA)',
    web_page: 'http://www.pgia.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAbcEgPBzJYROjmX7-_oL-TRuLaKQLA0m-AnyDaa-ME9o9Bf-NXZIt1dw',
    id: 7578,
    acronym: 'PIA(',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'rjt.ac.lk',
    name: 'Rajarata University of Sri Lanka',
    web_page: 'http://www.rjt.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbc7d9dLBYzOIwyY84Do0TkMkxc5tw3Qm8fm6AzveaJtEtLmb01xft-iK0',
    id: 7579,
    acronym: 'RUSL',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'ruh.ac.lk',
    name: 'University of Ruhuna',
    web_page: 'http://www.ruh.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXcX2rC7LEeqBm8zVWrEpbjvq5_z2UGsZrDEszq8DqEzjnmNBXY8MPy21E',
    id: 7580,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'sab.ac.lk',
    name: 'Sabaragamuwa University of Sri Lanka',
    web_page: 'http://www.sab.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqBjx0IRxXeWZ8PorB3Rrhia7svd0S-oJAumwbFoYUHvPZO6rItQ7Lobc',
    id: 7581,
    acronym: 'SUSL',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'saitm.edu.lk',
    name: 'South Asian Institute of Technology And Medicine (SAITM)',
    web_page: 'http://www.saitm.edu.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfUghGIGJCZZDGJLRnIGqDccWyihrKPQVH_h0Cqh7FIzRJMvAECJVqRYM',
    id: 7582,
    acronym: 'SAITM(',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'seu.ac.lk',
    name: 'South Eastern University of Sri Lanka',
    web_page: 'http://www.seu.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQte24YltTF8zo8_bvgNWJ7YjhekVXf9s6gEGbqSUaStIiNaxmf0ZQ5o3M',
    id: 7583,
    acronym: 'SEUSL',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'sjp.ac.lk',
    name: 'University of Sri Jayawardenapura',
    web_page: 'http://www.sjp.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdtnI5YhQORdipzrWeheaxMLAzIEAFgFTHDR72XJ35n6_EXgNcywN1Ek4',
    id: 7584,
    acronym: 'USJ',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'sliate.net',
    name: 'Sri Lanka Institute of Advanced Technical Education',
    web_page: 'http://www.sliate.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9E_FgBZb6JAEapXa6lO7zMgwDdCEzSdDv4SFx74owYxcqMYLgRCHsLQ',
    id: 7585,
    acronym: 'SLIATE',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'sliit.lk',
    name: 'Sri Lanka Institute of Information Technology (SLIT)',
    web_page: 'http://www.sliit.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgg9Z_PfQnsElJC0jXVLfGJYVCKiVyml1BOm3RuXJOBCMQHeh83cMp6V0',
    id: 7586,
    acronym: 'SLIIT(',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'uwu.ac.lk',
    name: 'Uva Wellassa University',
    web_page: 'http://www.uwu.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfCu565X87FZRCXQ7DC41-Sg5YkCGrd2wODiAGp3A_7tXGCHROvvP3tA',
    id: 7587,
    acronym: 'UWU',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'vpa.ac.lk',
    name: 'University of the Visual & Performing Arts',
    web_page: 'http://www.vpa.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEFUP4y_9SpwuZ_WcoWHaZ-F25Xbw3P3kPooawu1YgAl6GYlqhEsW-YLM',
    id: 7588,
    acronym: 'UV&PA',
  },
  {
    alpha_two_code: 'LK',
    country: 'Sri Lanka',
    domain: 'wyb.ac.lk',
    name: 'Wayamba University of Sri Lanka',
    web_page: 'http://www.wyb.ac.lk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbc7d9dLBYzOIwyY84Do0TkMkxc5tw3Qm8fm6AzveaJtEtLmb01xft-iK0',
    id: 7589,
    acronym: 'WUSL',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'aau.edu.sd',
    name: 'Al-Zaiem Al-Azhari University',
    web_page: 'http://www.aau.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQH7yGW3ggM4fWwN-NTrdWUKsYHW6juAkV4xk36JMj0hw0P229q4ZLhRiM',
    id: 7590,
    acronym: 'AAU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'ahfad.edu.sd',
    name: 'Ahfad University for Women',
    web_page: 'http://www.ahfad.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT6F0SuljyRbXAyvSEtpg_0dRviLamvXjevR2egaLBj6uNptkpiLJXSA',
    id: 7591,
    acronym: 'AUFW',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'bakhtalruda.edu.sd',
    name: 'Bakht Er-Ruda University',
    web_page: 'http://www.bakhtalruda.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgdjKv2J4akliFfhnRvOGVlSgO_M7SMAThFGRr_J7p3nj3nu1YjkLr2g',
    id: 7592,
    acronym: 'BEU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'bayantech.edu',
    name: 'Bayan College for Science & Technology',
    web_page: 'http://www.bayantech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSl9YASi2QretqI5cFGnp1e6C6DIWCCzN-iMUzRcML74o_wDtt9E61tzjO',
    id: 7593,
    acronym: 'BCFS&T',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'bnu.edu.sd',
    name: 'Blue Nile University',
    web_page: 'http://www.bnu.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGp9t6iFffgoBW_dXi8ITjalDPJu9R0VRFNYEQobA0S4AJVoHqUvhoaw',
    id: 7594,
    acronym: 'BNU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'ccs.edu.sd',
    name: 'Canadian Sudanese College',
    web_page: 'http://www.ccs.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbwuEu7SPIScODNiiwIHeG_51FXlURTBhkAgvybQRZqVqR7phU-5EpAktM',
    id: 7595,
    acronym: 'CSC',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'dalanjuniversity.edu.sd',
    name: 'Dalanj University',
    web_page: 'http://www.dalanjuniversity.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuWcfKd9BrIzF2FV1xfhW0EOMoyq_svL42uBhURtc1i-a5edMmy6BZv_p8lA',
    id: 7596,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'elmahdi.edu.sd',
    name: 'University of El Imam El Mahdi University',
    web_page: 'http://www.elmahdi.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEwjiAOUoBsM2oTKd8-PdnRZRUNIUVLLKubGssNmHzCKqkZy7qksXwBIo',
    id: 7597,
    acronym: 'UEIEMU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'elrazicollege.net',
    name: 'Elrazi College Of Medical & Technological Sciences',
    web_page: 'http://www.elrazicollege.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBHiEjK8IVeBPvCxuiVO-9MPm0gJad7FnxD1FqBJRoIy1-W-Cz7Ikqk_I',
    id: 7598,
    acronym: 'ECM&TS',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'fashir.edu.sd',
    name: 'Al Fashir University',
    web_page: 'http://www.fashir.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmEOCJ7xjUeIteKR5bCisR-ZdLtd8FW5VHI0JpbG7sQ8hKvjneiJFLlT90',
    id: 7599,
    acronym: 'AFU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'gaduniv.edu.sd',
    name: 'University of Gadarif',
    web_page: 'http://www.gaduniv.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7X5rq_U4dQ-cWlJ51Xby11llw0QH1cP5UaTYjbGKj1N4QI2a3G6H_KjJzHQ',
    id: 7600,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'geziracollege.edu.sd',
    name: 'Gezira College of Technology',
    web_page: 'http://www.geziracollege.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPaz_qN9KWfaqROaTtp10f0_6MX8P8UiebLX9hPLdLNywOoNn7GtF67UA',
    id: 7601,
    acronym: 'GCT',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'hibfs.edu.sd',
    name: 'High Institute for Banking & Financial Studies',
    web_page: 'http://www.hibfs.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpRCFGMG4VJU3_nUDrdfmlWB0eo29rJMxSFrsor468Az1DrfmwHbYSG9k',
    id: 7602,
    acronym: 'HIFB&FS',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'iua.edu.sd',
    name: 'International University of Africa (IUA)',
    web_page: 'http://www.iua.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIuuQhsiTYElEOHwup6OwlemkfY82B93o9pcWuVIY_mJOzdivieWiqO04',
    id: 7603,
    acronym: 'IUA(',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'kassalauni.edu.sd',
    name: 'Kassala University',
    web_page: 'http://www.kassalauni.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZbzUFSE9l_MrICf0g3g8MVXcyIKBLqwRuKlxZSeV0VzYXJXuTAE0uL8Q',
    id: 7604,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'kordofan.edu.sd',
    name: 'University of Kordofan',
    web_page: 'http://www.kordofan.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtp2TSsq3VRLyP5VLFY630DjxOX_rZwFBAn9FdDlpM78VNZLpPLuybo30',
    id: 7605,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'nc.edu.sd',
    name: 'National College for Medical and Technical Studies',
    web_page: 'http://www.nc.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1UrAhZ1LbVHP-ZjAdhuU4jga4YFk21EeK_qiZm6FmxS9XY1xqueR7ff4',
    id: 7606,
    acronym: 'NCFMTS',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'neelain.edu.sd',
    name: 'Neelain University',
    web_page: 'http://www.neelain.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmEpn6xNEWuNsAfCjA2E2BHkoWxM1ulBUXMkNzVFK11ZKA2CNr1xFVMDE',
    id: 7607,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'nilevalley.edu.sd',
    name: 'Nile Valley University',
    web_page: 'http://www.nilevalley.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0j6-8aBAyWt9GjS2E5ebhmnjNJCUtRYD_qh0753rAmo5dkYn7e7jcxew',
    id: 7608,
    acronym: 'NVU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'oau.edu.sd',
    name: 'Omdurman Ahlia University',
    web_page: 'http://www.oau.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVtpspl-SyFxXEsHxHHCQMieA2PFLa5E23qgM-FtrfRTSCRESedYhg2A',
    id: 7609,
    acronym: 'OAU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'oiu.edu.sd',
    name: 'Omdurman Islamic University',
    web_page: 'http://www.oiu.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9MLOhYMh44izzNtdSuZxUUqWFSu0e71mLSKkwLcLRURTL5PJuhan4zg',
    id: 7610,
    acronym: 'OIU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'ous.edu.sd',
    name: 'Open University of Sudan (OUS)',
    web_page: 'http://www.ous.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5wpYBFM7mCh-f-8K-JkIvf1wAObBOHNIsSlmoW1syJJ029Syc9udTmmA',
    id: 7611,
    acronym: 'OUS(',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'quran-unv.edu.sd',
    name: 'University of Holy Quran and Islamic Siences',
    web_page: 'http://www.quran-unv.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJROBKQkw4KOMkI4rH1pK86xvGuVmIkEmqaNk7ex_kqD6Fyov9CpPl5m06',
    id: 7612,
    acronym: 'UHQIS',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'ribat.edu.sd',
    name: 'National Ribat University',
    web_page: 'http://www.ribat.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjeui23Iimf4UhkIDG8k-6cE7_qglxGLM7SaJC5AS4dhekKWy3Ct3AjA',
    id: 7613,
    acronym: 'NRU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'rsu.edu.sd',
    name: 'Red Sea University',
    web_page: 'http://www.rsu.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuWcfKd9BrIzF2FV1xfhW0EOMoyq_svL42uBhURtc1i-a5edMmy6BZv_p8lA',
    id: 7614,
    acronym: 'RSU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'sas-sd.net',
    name: 'Sudan Academy of Sciences',
    web_page: 'http://www.sas-sd.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIDnkTb1K3J5Gq90BH-SV9ilavL_DfRECAM9FavDLlQtGijrr2J8vUGDM',
    id: 7615,
    acronym: 'SAS',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'sinnaruniv.edu.sd',
    name: 'Sinnar University',
    web_page: 'http://www.sinnaruniv.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSc4pgWB6L3pYzUFOMdXvmcux-9nLD0YuafX2WasLSOxH0XjD50cDmITos',
    id: 7616,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'sustech.edu',
    name: 'Sudan University for Science and Technology',
    web_page: 'http://www.sustech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4C8wct717B2pgCOcvxCBRo3cL03DNvLpZtM10knkzlojkAJ7c_huGrgEi',
    id: 7617,
    acronym: 'SUFST',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'umst-edu.com',
    name: 'University Of Medical Sciences & Technology (UMST)',
    web_page: 'http://www.umst-edu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN6AentTf_ls6w9oXSAzb91hBZhMiU_gn866tTVO-5ILvJ2GTEGfwxUw',
    id: 7618,
    acronym: 'UMS&T(',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'unu.edu.sd',
    name: 'Upper Nile University',
    web_page: 'http://www.unu.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRR9nYY0Vm-Wm-1fVtXO7ZI8z_JSY6IurFG_zkkBOCnD3zY4wSwIlPcom8',
    id: 7619,
    acronym: 'UNU',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'uofd.edu.sd',
    name: 'University of Dongola',
    web_page: 'http://www.uofd.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkROHveQ9Hc35FL8sFT11nlzCpJM4L6UtMlSd4354inwgYq2X7c13JUIE',
    id: 7620,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'uofg.edu.sd',
    name: 'University of Gezira',
    web_page: 'http://www.uofg.edu.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGGyUMNKjJ5UFnKIGmCAbo3mNVQP8svceZkt4YswbwF8AilfscugT8Jg',
    id: 7621,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'uofk.edu',
    name: 'University of Khartoum',
    web_page: 'http://www.uofk.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQc9hBah26PWYu_MuQQZbmaXWzn35esx7pVz2uJ1orfALMBoCwNx_JODQ',
    id: 7622,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'SD',
    country: 'Sudan',
    domain: 'ush.sd',
    name: 'Shendi University',
    web_page: 'http://www.ush.sd/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtMArbIR7vI_hmAGVfjIQwc4z2rQWBzUfZGmeDj1mS5ISshKpkJEp8Yw',
    id: 7623,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'SR',
    country: 'Suriname',
    domain: 'uvs.edu',
    name: 'Anton de Kom University',
    web_page: 'http://www.uvs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGtXZrQc5gjZa9JN57twwPIAqsll4pm3YwvI-4CP6bOeCPh_VRCtmPIQ',
    id: 7625,
    acronym: 'ADKU',
  },
  {
    alpha_two_code: 'SZ',
    country: 'Swaziland',
    domain: 'uniswa.sz',
    name: 'University of Swaziland',
    web_page: 'http://www.uniswa.sz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEsJ7bTACw_CGq4QgDBJJly915ZjS2-qUxLVKc7XoE3lk-nWR-XP9Yww',
    id: 7626,
    acronym: 'US',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'bth.se',
    name: 'Blekinge Institute of Technology',
    web_page: 'http://www.bth.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWzf0v6YbuBmS_V4g312BvlOcpz4GnA2Q8RazoPEcSXEHxOtN_Z6JYRuk',
    id: 7627,
    acronym: 'BIT',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'chalmers.se',
    name: 'Chalmers University of Technology',
    web_page: 'http://www.chalmers.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6YcnHBk0ZOWfPHV9D4jxsuAMUMxv1m0BogoLrLQbp_OfkObSD27lh6w',
    id: 7628,
    acronym: 'CUT',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'du.se',
    name: 'Dalarna University College',
    web_page: 'http://www.du.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQePiarhDkB1vryoze5NwBoxMEBvm0U-TOfull4izVHgdZD6QIwqOR2LA',
    id: 7629,
    acronym: 'DUC',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'gu.se',
    name: 'Göteborg University',
    web_page: 'http://www.gu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5njoCczttV_f6ne8iUqJWBAx83STB2531E0WwR1b6VCvOKixUenfLh8eA',
    id: 7630,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hb.se',
    name: 'University College of Borås',
    web_page: 'http://www.hb.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSodULSyv43rfxV_xec00_G9hBp238Hm6g7fst3Kf06d1DmPD2ULjRu93A',
    id: 7631,
    acronym: 'UCB',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hdk.gu.se',
    name: 'School of Design and Crafts',
    web_page: 'http://www.hdk.gu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-UfXcwc9dQoMZHmi_dTANrAGaZjw5pHQena7ESabKA24nvyXtnngOdTqJ',
    id: 7632,
    acronym: 'SDC',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hgo.se',
    name: 'Gotland University College',
    web_page: 'http://www.hgo.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTv31u_9pVdK2rqslRaTjHx1SpmjpDdf6rnDTrosE8E9aKMw6X7uiMVAI',
    id: 7633,
    acronym: 'GUC',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hh.se',
    name: 'Halmstad University College',
    web_page: 'http://www.hh.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsb-YQl7nhSxuPEO-_3sp7PHVE-1IbujJrDxGdypDowo6oUZ7EEA_YBbc',
    id: 7634,
    acronym: 'HUC',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hhs.se',
    name: 'Stockholm School of Economics',
    web_page: 'http://www.hhs.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtxBt3ascNPgn81tvjeo6NGdCqzNXD9AH_Ghe6BLy7-CYbjLrSW6Jo-A',
    id: 7635,
    acronym: 'SSE',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hig.se',
    name: 'University College of Gävle',
    web_page: 'http://www.hig.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo_QuVGMAWDV4N_vjvYClkt0_rrCY1AADgVKacwZI6Sncj6IyrNOAysTtE',
    id: 7636,
    acronym: 'UCG',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hik.se',
    name: 'Kalmar University College',
    web_page: 'http://www.hik.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBK5q6TMaLjaS7fvobpdKZJOP4th_Nl9KzVH8l6kxyaKvDcWhbiwtnsQ',
    id: 7637,
    acronym: 'KUC',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'his.se',
    name: 'University College of Skövde',
    web_page: 'http://www.his.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmrFxb1UJkgMFdtBBUl0BjKPF_OC3Y---Yi3oF1LYCRbDvMIo98qtDC8A',
    id: 7638,
    acronym: 'UCS',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hj.se',
    name: 'Jönköping University College',
    web_page: 'http://www.hj.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj7xKBP2X56vjMcyXpd1a3z-iixTGijjuEOxAnGWzL9VibFCEJkEODOio',
    id: 7639,
    acronym: 'JUC',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hj.se',
    name: 'Jönköping University College of Health Sciences',
    web_page: 'http://www.hj.se/hhj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIaYACDbIQgIUlBMyL2C_aaUyMn4cAVgEEwQTr1vMhCyIP-Ya6tnCT6cm0',
    id: 7640,
    acronym: 'JUCHS',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'hkr.se',
    name: 'University College of Kristianstad',
    web_page: 'http://www.hkr.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK3dEwNXOeu8_-sH07ywClSxk8vnHkLYBaShn1UavldBrlKxx7Hc6aqnA',
    id: 7641,
    acronym: 'UCK',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'htu.se',
    name: 'University College of Trollhättan/Uddevalla',
    web_page: 'http://www.htu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXLUZVHG2RjQzEhTHwPhOyUpuLykTsHP86Lb9u2yR_mxZKit75lc-q0SI',
    id: 7642,
    acronym: 'UCT',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'kau.se',
    name: 'Karlstad University',
    web_page: 'http://www.kau.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-UncwAOnu2JQ_quuVbQZy5E1uFTCtJzS2SVPMqax0fpzP74F-e-R_kg',
    id: 7643,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'ki.se',
    name: 'Karolinska Institute Stockholm',
    web_page: 'http://www.ki.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYzptUqeYFbWrxhgY-5_7k8qaFA8cw-ymakUEAgOQEtWrdHsMaua7GlGcP6g',
    id: 7644,
    acronym: 'KIS',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'konstfack.se',
    name: 'University College of Arts Crafts and Design',
    web_page: 'http://www.konstfack.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-EhOSN-w83g2P88nUOQdMsASWGRU_W08nheHimUbKwUQtpcMQytr_R8w7',
    id: 7645,
    acronym: 'UCACD',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'kth.se',
    name: 'Royal lnstitute of Technology',
    web_page: 'http://www.kth.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3hUz3_tbVwDvzxsoyD8KKcf3k74BsN8fxhD8l46CrEt3CLVTVbP7hFQ',
    id: 7646,
    acronym: 'RLT',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'lhs.se',
    name: 'Stockholm Institute of Education',
    web_page: 'http://www.lhs.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSic4mc0GKsEXRpMeV3qC6MdpIy_fesiG8g5nP7uBWOuVwmkn3L2MOfgmk',
    id: 7647,
    acronym: 'SIE',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'liu.se',
    name: 'Linköping University',
    web_page: 'http://www.liu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNaUXoQ-sErN0FQY-AZGsX7ifaW-nRxuiEnxU9N-kijmuuNRDK9nqo3w',
    id: 7648,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'lu.se',
    name: 'Lund University',
    web_page: 'http://www.lu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsn3ODLa9O3Fd9P-5TOPval2nmzvceifXnXQG4IwZgG3e3NyNHvHxfJXQ',
    id: 7649,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'luth.se',
    name: 'Lulea University of Technology',
    web_page: 'http://www.luth.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwrILSne_PDamo83D-Ns2DP49X7jfvcx3zIbiDKqrGgoUXPbHY4illzNs',
    id: 7650,
    acronym: 'LUT',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'mah.se',
    name: 'Malmö University College',
    web_page: 'http://www.mah.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWyZtlrjg3hrWt9-xPaurPlh78wTtGEsLUN9wEf3dzwHha7JLexS6DnK4',
    id: 7651,
    acronym: 'MUC',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'mdh.se',
    name: 'Mälardalen University',
    web_page: 'http://www.mdh.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrl1ADHuMGiut8yL6ncC6aDMR4UXdyhtHu8_j5JPAaa1lrgaz3zmUCm8o',
    id: 7652,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'miun.se',
    name: 'Mid-Sweden University',
    web_page: 'http://www.miun.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGRLMYeWvJwPwkRCQeFHYF0tH2RcJeVCIfYt-sJj8hWGFhEhpFdBDhnrZ0',
    id: 7653,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'nhv.se',
    name: 'Nordic School of Public Health',
    web_page: 'http://www.nhv.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtov8RvYuJ05EJQXoxHvwDujTMB4huOfwB5lHlXKs91uPlfVL_urydFQc',
    id: 7654,
    acronym: 'NSPH',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'oru.se',
    name: 'Örebro University',
    web_page: 'http://www.oru.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_ayrR6YHWUcCir9SRWyE9faJrwNZadNlGxJtEs7JPtXoCMJXyxm99fbQ',
    id: 7655,
    acronym: 'ÖU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'sh.se',
    name: 'Södertörn University College',
    web_page: 'http://www.sh.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRztWfoOYe_w6x6qzzdTnDvPcs6L_gvyiF-jLgBPlZnT_Qfy3O8035zAb0',
    id: 7656,
    acronym: 'SUC',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'slu.se',
    name: 'Swedish University of Agricultural Sciences',
    web_page: 'http://www.slu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2YNY9rxAfPy3WQIIR9cDTJrBW_U9b9paMxwYCVVAFJhQX9SHybMRWHQ',
    id: 7657,
    acronym: 'SUAS',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'stockholm-fu.com',
    name: 'Free University Stockholm',
    web_page: 'http://www.stockholm-fu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzGbao0NyL5R130BQfgDBvy8FodHEaHrBbIoXiWdzaxQYCUDdDp8znwjHg',
    id: 7658,
    acronym: 'FUS',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'su.se',
    name: 'Stockholm University',
    web_page: 'http://www.su.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVQLrnOfeb0f-q_GHe7swHikx24qFz0ipjUvyT9YUjjRfWCsPbmHEMAhI',
    id: 7659,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'umu.se',
    name: 'Umea University',
    web_page: 'http://www.umu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdTYlg9Zq5qXiAlkR8m5Sb8G3KgjBLRpJh3_NaxgvfAC3RLoMpgBqrSw',
    id: 7660,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'uu.se',
    name: 'Uppsala University',
    web_page: 'http://www.uu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTG3dS-YM9rSKyYyLJNhZQ6e4P6p0qCNsMtBhs96jP2FC_GXFZQS3vOQQ',
    id: 7661,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'vxu.se',
    name: 'Växjö University',
    web_page: 'http://www.vxu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBK5q6TMaLjaS7fvobpdKZJOP4th_Nl9KzVH8l6kxyaKvDcWhbiwtnsQ',
    id: 7662,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'SE',
    country: 'Sweden',
    domain: 'wmu.se',
    name: 'World Maritime University',
    web_page: 'http://www.wmu.se/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKbczSW2fLrljKodZWn_0sLjK9f4YMdOUdWyHdPKZvvmB7TaQ0RXqW2-c',
    id: 7663,
    acronym: 'WMU',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'american-college.com',
    name: 'Schiller International University American College of Switzerland',
    web_page: 'http://www.american-college.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7ClfD1j5rskIZbf1rlM4rqGnSgLqulnCy_480YsVn_06CxHD5f6imCwp5mg',
    id: 7664,
    acronym: 'SIUACS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'bhms.ch',
    name: 'Business and Hotel Management School',
    web_page: 'http://www.bhms.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUa-hTFK0AyE_Pu8vNQ3qJmbGGOgqK_OWBCI2vj8rTSDrlANG5Ka-vMg',
    id: 7665,
    acronym: 'BHMS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'bsl-lausanne.ch',
    name: 'Business School Lausanne (BSL)',
    web_page: 'http://www.bsl-lausanne.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6uBlQ0bwAbwGTuDJaqqAKdQWob56HrTEOkuhqxZtaPH9LcbqPgJXRf-E',
    id: 7666,
    acronym: 'BSL(',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'cubidor.com',
    name: 'Cubidor University Switzerland',
    web_page: 'http://www.cubidor.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeDJj3dQoaV5FsTy-0DnPX4u1BLMNnJrMxgbAUqL3f8Z0n3tGyvKLUf2Rw',
    id: 7667,
    acronym: 'CUS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'dct.ch',
    name: 'DCT International Hotel & Business Management School',
    web_page: 'http://www.dct.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLmptor6oBDuSlzURf3ckce534-UaJXEWDwI_y6c9JQtGRDUDFym_3104',
    id: 7668,
    acronym: 'DIH&BMS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'egs.edu',
    name: 'European Graduate School Media & Communications',
    web_page: 'http://www.egs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEW_fWe4uyHFIgON1p0A2h8G1BMK4aiDd3plmgy9uk9d1ClV5wtESVMPw',
    id: 7669,
    acronym: 'EGSM&C',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'ehl.ch',
    name: 'Lausanne Hotel School (EHL)',
    web_page: 'http://www.ehl.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmZdb5hgb5FHVSzuhgL25hx1bz7GgFc7qwYZUfR9yU-s-qV6Svy__F7is',
    id: 7670,
    acronym: 'LHS(',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'epfl.edu',
    name: 'EPFL - EPF Lausanne',
    web_page: 'http://www.epfl.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGSTQgqICRmZ7mZbwlS5JwAboLHVZH09M9DO35witiEI0DBOHajohVlaU',
    id: 7671,
    acronym: 'E-EL',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'eth.edu',
    name: 'ETHZ - ETH Zurich',
    web_page: 'http://www.eth.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6fSsKGjVLgxxOZNWVvn--R-BBKlJCgOD_xPGbYBh_TnEOtnb8gBRYzMA',
    id: 7672,
    acronym: 'E-EZ',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fc.edu',
    name: 'Franklin College Switzerland',
    web_page: 'http://www.fc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoXC0_vhxxBHJoSE_GxWSCUde2q7YqoPubgHHe80JdncMt7H2QUiDRrVam',
    id: 7674,
    acronym: 'FCS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fh-aargau.ch',
    name: 'University of Applied Sciences Aargau',
    web_page: 'http://www.fh-aargau.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrq0Y7-DgvPTYzC7xPDlvNbhiBnzU_PQiY3Pk5J-Symk7u6JfpeJdvpRA',
    id: 7675,
    acronym: 'UASA',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fhbb.ch',
    name: 'University of Applied Sciences Basel (FHBB )',
    web_page: 'http://www.fhbb.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTA0-nlJ1YJEI1EBuNprll3H6j_unyzfNGvSG627zoWducLnb3sxlM6wLs',
    id: 7676,
    acronym: 'UASB()',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fh-htachur.ch',
    name: 'University of Applied Sciences Chur',
    web_page: 'http://www.fh-htachur.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTjAzDD37DKrPx2RSokBpJYYRB-Ilu2wyXp-M00k4yIV6O5aS3Iv9SYI8',
    id: 7677,
    acronym: 'UASC',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fhso.ch',
    name: 'University of Applied Sciences Solothurn Northwestern Switzerland',
    web_page: 'http://www.fhso.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZso4WsS9rpGibPd7MwitmSKWGmBda-7q6V9pmoMBNFYujfVJQs5iGkd6v',
    id: 7678,
    acronym: 'UASSNS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'gbs-ge.ch',
    name: 'Geneva Business School',
    web_page: 'http://www.gbs-ge.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXr9xBitw1k9P3Y1dQxjrYLFa7E6WGc8y8WKLIqF2xmDRi9RT6W5vZ',
    id: 7679,
    acronym: 'GBS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'glion.edu',
    name: 'Glion Institute of Higher Education',
    web_page: 'http://www.glion.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEJtmcIDK4kSBV7qOXe7EF0rbuJudrZUgBjpro1Fo5XjE5GxqwmEwLfg',
    id: 7680,
    acronym: 'GIHE',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'gsba.ch',
    name: 'Graduate School of Business Administration Zurich (GSBA Zurich)',
    web_page: 'http://www.gsba.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8bfXURH22hJAhsi2t2ZsYCnjUcIJ-opI1kBac5z1nBVbtkYJDGZf__RQg',
    id: 7681,
    acronym: 'GSBAZ(Z',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hgkz.ch',
    name: 'Hochschule für Gestaltung und Kunst Zürich',
    web_page: 'http://www.hgkz.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk_A7-tRcvkTk2RfOBPy6KYgUrahKhkWgvDxQMJBK_r2KLjvzdUdZTLg',
    id: 7682,
    acronym: 'HFGUKZ',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'ici-luzern.com',
    name: 'International Culinary Institute (ICI)',
    web_page: 'http://www.ici-luzern.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF20PIR3lJUIoeTNmzHodP4bWtZgDP0SWMZ7rLQxpPPixQ1LBwm7FM5Rs',
    id: 7683,
    acronym: 'ICI(',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'imi-luzern.com',
    name: 'International Hotel Management Institute',
    web_page: 'http://www.imi-luzern.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKJVeNPg9x5PmM5BNcYIrZlxXfii0a-ZkKuO_i_xsQQ3jOlhQ_k8xmsw',
    id: 7684,
    acronym: 'IHMI',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'isbm-school.com',
    name: 'International School of Business Management',
    web_page: 'http://www.isbm-school.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6QWDFBk-i6wBjuEjUvzPzAXTizUclOmA_56XwJmJAdE0Emi353xK25g',
    id: 7685,
    acronym: 'ISBM',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'iun.ch',
    name: 'International University in Geneva',
    web_page: 'http://www.iun.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSO50LtpK0DZGYFFEjUUMVCBFXrxLFTf6keNI7aJnEW8lDXoPuBJFl9FX4',
    id: 7686,
    acronym: 'IUIG',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'lesroches.edu',
    name: 'Les Roches International School of Hotel Management',
    web_page: 'http://www.lesroches.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTem7f5EAq6g2Cg_GvwMoNQ6AhaQrGFreX0Lful1m-JHErvr-90SPfv5jU',
    id: 7687,
    acronym: 'LRISHM',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'lrguas.ch',
    name: 'Les Roches Gruyère University of Applied Sciences',
    web_page: 'http://www.lrguas.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVJaYyOOVo1bGOC3j8lhu9feU0FEz99CQuP213Lfp2HXa6nbQiylySMpo',
    id: 7688,
    acronym: 'LRGUAS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'phw.info',
    name: 'Fachhochschule Pur',
    web_page: 'http://www.phw.info/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmMk5G043cYVxTX03c52Uw2STXL-oua40Bab-_xKP7OssvE8gSwv-WsM',
    id: 7689,
    acronym: 'FP',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'sbs.edu',
    name: 'Swiss Business School Zurich (SBS)',
    web_page: 'http://www.sbs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQD-GvhlzD8RehA8GIAHLfamFDy1_ItcKpPKRXZB_dP6N2RC_dI6iWwv20',
    id: 7690,
    acronym: 'SBSZ(',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'swissmc.ch',
    name: 'Swiss Management Center',
    web_page: 'http://www.swissmc.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFsQ8f6nEjzVpvuX9MUyIzdKqOLn-VuNkYKks3Z583AGU175ivUZ4PlvXk',
    id: 7691,
    acronym: 'SMC',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'swissu.org',
    name: 'University of Switzerland',
    web_page: 'http://www.swissu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVXa6ZYAkl0Z1sON4_UBZQKS8MU3FxQj0iQ1DNtxQuTJyOmeCP3l0Svf13',
    id: 7692,
    acronym: 'US',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'uccr.edu',
    name: 'University Center "César Ritz"',
    web_page: 'http://www.uccr.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg-bBrQ2c2-i7DP80L-fgK5tKGd-7JhwGQuokhuLomGorxpW-i1ta-gQ',
    id: 7693,
    acronym: 'UC"R',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'uniludes.ch',
    name: 'Libera Università degli Studi di Scienze Umane e Tecnologiche',
    web_page: 'http://www.uniludes.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRixnuZbkDo8eUdC9jeHTAZKAixLe_Ph-6dCyCwocb-aD-iXCYe8SjCoMM',
    id: 7694,
    acronym: 'LUDSDSUET',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unizh.ch',
    name: 'University of Zürich',
    web_page: 'http://www.unizh.ch/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQfkFXQX7aidF0aHoTmYjJYWRZr_qSTpnfXExa8fNsUv2JfL9iKKDeRFEyTdQ',
    id: 7695,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'webster.ch',
    name: 'Webster University Geneva',
    web_page: 'http://www.webster.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrFDCnPwhjgzerCPTKZhLf17BW7w-lHNMW8NEYaoev4Rxk-fd2rQKkOxH-',
    id: 7697,
    acronym: 'WUG',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'zhwin.ch',
    name: 'Zurich University of Applied Sciences Winterthur',
    web_page: 'http://www.zhwin.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrPtP52eoPni9pRlHzjrKMoetcPFWisJcSuR532AhhUMiYXQXIMqTTBg',
    id: 7698,
    acronym: 'ZUASW',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'usi.ch',
    name: 'Universita della Svizzera Italiana',
    web_page: 'http://www.usi.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4KdGhYqAwgCTGBgia5nf07zjxIvpsrrEc5qiTmIqN4k2mexOakL2iRJl2',
    id: 7701,
    acronym: 'UDSI',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unibas.ch',
    name: 'University of Basel',
    web_page: 'http://www.unibas.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz0YdlXSGdOQw5M62YlMnXiD5jMu0-mlkJZsN9mUtCwNYOd-omhFqpEDc',
    id: 7702,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unifr.ch',
    name: 'University of Fribourg',
    web_page: 'http://www.unifr.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlSpY8M4HReccA5Mw221tsEtygUN44XE7mo5S7ts1NXfFJny21JE1uRBg',
    id: 7703,
    acronym: 'UF',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unige.ch',
    name: 'University of Geneva',
    web_page: 'http://www.unige.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHsqQGmYTG2mERB9XaLCwyFToO7ERCl7R4MWE4SjcShvInONfXCdqvi5k',
    id: 7704,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'uzh.ch',
    name: 'University of Zurich',
    web_page: 'http://www.uzh.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_K7sNjyilIGqnUry1l3bs8utLoY31uga_EeHq-VOiB_r3Hii6KV02ei2kGQ',
    id: 7705,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unibe.ch',
    name: 'Universität Bern',
    web_page: 'http://www.unibe.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTEd3WW8OPetPZM_OZexKgVJ1wtWj3lyTr9VPmciGJ1T6WC5A0mbudcQk',
    id: 7706,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unilu.ch',
    name: 'Universität Luzern',
    web_page: 'http://www.unilu.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSUb_TQouKq0uw-P4aGN8EijO9KPWxbd53DOtWpuR4sYDqGmDLsHRYy8s',
    id: 7707,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unisg.ch',
    name: 'Universität St. Gallen',
    web_page: 'http://www.unisg.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnbB7O3xYCjShoWPfbPkFk2JOY9IXM3mkLlMKQCY1p8ql2g0kAAJYsXA',
    id: 7708,
    acronym: 'USG',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unil.ch',
    name: 'Université de Lausanne',
    web_page: 'http://www.unil.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4kPF3KSviU1M9PcoRGkOSh1UACRB7unCVGD78jgaLV2foDN02NZW3SnslOw',
    id: 7709,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unine.ch',
    name: 'Université de Neuchâtel',
    web_page: 'http://www.unine.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSapsLs2lvPL49txlMQQhW55dWy6N2WDU7AyGvwsyVrwu-C2shHENu9bA',
    id: 7710,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'bfh.ch',
    name: 'BFH - Bern University of Applied Sciences',
    web_page: 'http://www.bfh.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2HbJtYZO-ZeivpNHNNBLgSbL8i--CmxP4iOm8IuHfuW8j5m3DF1My29w',
    id: 7711,
    acronym: 'B-BUAS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'ffhs.ch',
    name: 'FFHS - Fernfachhochschule Schweiz',
    web_page: 'http://www.ffhs.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRVFDoiuDKmzirJSItp4L43NFxp1yDuqeZluDIBi4Kvut-uW-2iWmXz7ep',
    id: 7712,
    acronym: 'F-FS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fhnw.ch',
    name: 'FHNW - Fachhochschule Nordwestschweiz',
    web_page: 'http://www.fhnw.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAr6R2DWJsILak9F8J1TkUh9jFFzS_3OQIppR11d5CczJQnsmnOubGrJ8-',
    id: 7713,
    acronym: 'F-FN',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fhsg.ch',
    name: 'FHS St.Gallen',
    web_page: 'http://www.fhsg.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3s6mNocJAo4nJGxG-diS1CZ9X6TvYLUq4nIENm-qtIkI37lpS9WWl9CKE',
    id: 7714,
    acronym: 'FS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hepl.ch',
    name: 'HEP Vaud - Haute école pédagogique du canton de Vaud',
    web_page: 'http://www.hepl.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMSYY5YfLxE5JRHxJEni-laPlmWDoZr_8sToYsSIixr03uY7-lhOPNbewU5w',
    id: 7715,
    acronym: 'HV-HÉPDCDV',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hep-bejune.ch',
    name: 'HEP-BEJUNE - Haute Ecole Pédagogique BEJUNE',
    web_page: 'http://www.hep-bejune.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-AFnJp_S68gTc_lZ4cm00WdjXBxL1L_4I0LakMZB2VWeEsVUBt3IEV5PX',
    id: 7716,
    acronym: 'H-HEPB',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'edufr.ch',
    name: 'HEP-PH FR - University of Teacher Education Fribourg',
    web_page: 'http://www.edufr.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToVpaIT2hjz7K-REpcJds8Tn2fwTzVEaEO1zgxM3SdJicCzoJYhBEFaQ',
    id: 7717,
    acronym: 'HF-UTEF',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hepvs.ch',
    name: 'HEPVS - Haute école pédagogique du Valais',
    web_page: 'http://www.hepvs.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRA4-ZgoAD2jw6j7xSXyjP2w-t3hZEYOvUic9PDp1IdiPheRpXXpB15Noim',
    id: 7718,
    acronym: 'H-HÉPDV',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hes-so.ch',
    name: 'HES-SO : UAS Western Switzerland',
    web_page: 'http://www.hes-so.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5uJIN4xqymHlJXm_CX8Ba8sk7f9yRRFgmiP0gxV3rPez1MWtKh84jz2I',
    id: 7719,
    acronym: 'H:UWS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hfh.ch',
    name: 'HfH – University of Applied Sciences of Special Needs Education',
    web_page: 'http://www.hfh.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpQJNVt6dcGXbRARKp9-4Uwcl5w3igcghNWb5Et1YPkShOCx0YwdZbFL0h',
    id: 7720,
    acronym: 'H–UASSNE',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hslu.ch',
    name: 'HSLU - Lucerne University of Applied Sciences and Arts',
    web_page: 'http://www.hslu.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGH45nCY2ZtpdC9JADJFl1EQNmOa-iAt3LeFZGnlWTmQXP6oU4f9LcmA',
    id: 7721,
    acronym: 'H-LUASA',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hsr.ch',
    name: 'HSR - Hochschule für Technik Rapperswil',
    web_page: 'http://www.hsr.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHPI30KmvP2lkpYZxd2OINsSPdx-m9vVKgbnSPnCsGBcS4YON8GrdHhVUm',
    id: 7722,
    acronym: 'H-HFTR',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fh-htwchur.ch',
    name: 'HTW Chur - University of Applied Sciences HTW Chur',
    web_page: 'http://www.fh-htwchur.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTjAzDD37DKrPx2RSokBpJYYRB-Ilu2wyXp-M00k4yIV6O5aS3Iv9SYI8',
    id: 7723,
    acronym: 'HC-UASHC',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'fh-hwz.ch',
    name: 'HWZ - University of Applied Sciences in Business Administration Zurich',
    web_page: 'http://www.fh-hwz.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn_Zfo3cVR_4vBD4d7h3xa0rZfd2unEy7YZx5Z97l8oXjMQtccVwbq2iw',
    id: 7724,
    acronym: 'H-UASIBAZ',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'ntb.ch',
    name: 'NTB - Hochschule für Technik Buchs',
    web_page: 'http://www.ntb.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR2sSHcMouo1LM8kTcasyL-cY3gVzfiHoN01OKuona0d65IkzsEtMNLw',
    id: 7725,
    acronym: 'N-HFTB',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'phzg.ch',
    name: 'PH Zug - Pädagogische Hochschule Zug',
    web_page: 'http://www.phzg.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-xawVXdi8GZ8_erRvsxboCNuNsFlKrj6pFnEyfvJqr1ExRD095dDHZz6k',
    id: 7726,
    acronym: 'PZ-PHZ',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'phzh.ch',
    name: 'PH Zürich - Pädagogische Hochschule Zürich',
    web_page: 'http://www.phzh.ch/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQt2Y1T0s4bKwVZGWaUuHjJ9vbkq9mX_5Rpc9ZO6Ms9DERqV3-eVOa_9w',
    id: 7727,
    acronym: 'PZ-PHZ',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'phbern.ch',
    name: 'PHBern - University of Teacher Education Bern',
    web_page: 'http://www.phbern.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd0YOi8_WYAk3xeHAW-ekdWkzx-rRemZDO8nPPwfpAhL8vOUpL4vLwFQhL',
    id: 7728,
    acronym: 'P-UTEB',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'ph-gr.ch',
    name: 'PHGR - University of Teacher Education Graubünden',
    web_page: 'http://www.ph-gr.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjtedU3Q-Vn37nKNeXRmD1DaJJc1vbt1rnIy9NiJ2iv0HL8N4W4GvobMg',
    id: 7729,
    acronym: 'P-UTEG',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'phlu.ch',
    name: 'PHLU - University of Teacher Education Lucerne',
    web_page: 'http://www.phlu.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWzvU5S_Ru2TZc-73BfuRwp_RhEjZRlu0IctUe_qb0Yg0GiT3CE-eynBk',
    id: 7730,
    acronym: 'P-UTEL',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'phsg.ch',
    name: 'PHSG - Pädagogische Hochschule St.Gallen',
    web_page: 'http://www.phsg.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwxA0kiruR-kyUoHdgwgRczELCvz7_WPadJMrGmagkpmzIQ5yOKdr6SbE',
    id: 7731,
    acronym: 'P-PHS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'phsz.ch',
    name: 'PHSZ - University of Teacher Education Schwyz',
    web_page: 'http://www.phsz.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJS3SmqIqA4nxpFIicBpJ_Qhzzfd4RsMv-eHV2WHOvHffrYhNaigP6',
    id: 7732,
    acronym: 'P-UTES',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'phtg.ch',
    name: 'PHTG - Pädagogische Hochschule Thurgau',
    web_page: 'http://www.phtg.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXy0IeEkWSOcRqibMmH3v4mHfgn1oBJEXLeJYdAik_qxf9ktXKmLDg0TYvVA',
    id: 7733,
    acronym: 'P-PHT',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'supsi.ch',
    name: 'SUPSI - University of Applied Sciences Southern Switzerland',
    web_page: 'http://www.supsi.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV03CDQypu8NYnhBJWNe2NpGJNBgJkXuIkKMfZsEsJksmu49hCtcxAP2AL',
    id: 7734,
    acronym: 'S-UASSS',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'zhaw.ch',
    name: 'ZHAW - Zürcher Hochschule für Angewandte Wissenschaften',
    web_page: 'http://www.zhaw.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwMPsXR2OgIuUqnw2fAZlELFZwg3l8Qcz7-EnWsZ0hc7UQAZbguMXaE6w',
    id: 7735,
    acronym: 'Z-ZHFAW',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'zhdk.ch',
    name: 'ZHdK - Zurich University of the Arts',
    web_page: 'http://www.zhdk.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRK0L0aQHsjJ-8YyJx-WjjkMVuci5Hek2MwMj2G71v6hy-D85dTsyP9cQ',
    id: 7736,
    acronym: 'Z-ZUA',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'chuv.ch',
    name: 'CHUV - University Hospital Lausanne',
    web_page: 'http://www.chuv.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQty8qO2MRjEP2WadPjXzPgV0zbITGAwU_Urb2VieWcSuGYb2Yfoo4nSA',
    id: 7737,
    acronym: 'C-UHL',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'hcuge.ch',
    name: 'HUG - Univ. Hospitals of Geneva',
    web_page: 'http://www.hcuge.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeOncWuvEF18GpzCE83lLptxpBB29zRNNgghPeAAa4Z8i4fO0WojPKVg',
    id: 7738,
    acronym: 'H-UHG',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'insel.ch',
    name: 'Insel Gruppe',
    web_page: 'http://www.insel.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPrjmoYg9aFzEU5__eXFRtyu_8laduugJ9G_oSfYEAoDbbLlUkxnVboQ',
    id: 7739,
    acronym: 'IG',
  },
  {
    alpha_two_code: 'CH',
    country: 'Switzerland',
    domain: 'unispital.ch',
    name: 'University Hospital Zurich',
    web_page: 'http://www.unispital.ch/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKKBMcYqxfppqvIg-BQhcnWwftEMYm38PNE-Rq1Wz5PTinP82rPNrx118',
    id: 7740,
    acronym: 'UHZ',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'aiu.edu.sy',
    name: 'Arab European University',
    web_page: 'http://www.aiu.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfFHaviDtKGmv8-GsxMShNS_bcqKyNr7cTq7odMsjPi0NsUFoF3PIMNnI',
    id: 7741,
    acronym: 'AEU',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'albaath-univ.edu.sy',
    name: 'Al-Baath University',
    web_page: 'http://www.albaath-univ.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRDB70a8q1JidLhU5FVgWjeARxZB-KfS1yW6T34f5aaS8WTnTmsTqLjw',
    id: 7742,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'alepuniv.edu.sy',
    name: 'University of Aleppo',
    web_page: 'http://www.alepuniv.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSb3KQ-HaJOm24jRrhpK3EAuRId_CkJPfQSHnaLiYagoXa5yC9C9euZnw',
    id: 7743,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'damascusuniversity.edu.sy',
    name: 'Damascus University',
    web_page: 'http://www.damascusuniversity.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzInAh7A3uellkq_3r6pa0qsJo5DvyRyV_7uN8YLesUnrEnn6ZiMEl9g',
    id: 7744,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'hiba.edu.sy',
    name: 'Higher Institute of Business Administration',
    web_page: 'http://www.hiba.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdIgzANYqBYtDAsX-II1kKoNgPqzSn-XnIpn5vIlynfHXEocG-CKeX-zw',
    id: 7745,
    acronym: 'HIBA',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'ipu.edu.sy',
    name: 'Ittihad Private University',
    web_page: 'http://www.ipu.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2VWQR9YEuEWyApy6bfCNZuTQiLV9j8YA7z-uKGLtuYkUZMQQwanQG6g',
    id: 7746,
    acronym: 'IPU',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'iust.edu.sy',
    name: 'International University for Science & Technology',
    web_page: 'http://www.iust.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQepu5oiBgPDaJk0MxIT9p-ikxPxSAfbrgOVpBwCBPC8FigUfHSiDtJbg',
    id: 7747,
    acronym: 'IUFS&T',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'must.edu.sy',
    name: 'Mamoun Private University for Science and Technology',
    web_page: 'http://www.must.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuPaS9qWn7nAapVNLUqK-_g2PcdZzWDVuZXeXo_1k37NniNtikXGEZRg',
    id: 7748,
    acronym: 'MPUFST',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'siust.edu.sy',
    name: 'Syrian International Private University for Science & Technology',
    web_page: 'http://www.siust.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-9OzXiIZ4GDMwwR_plnMIEhEL4jZRdwKnCrWORwNpYH328_VtDRB5Tg',
    id: 7749,
    acronym: 'SIPUFS&T',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'svuonline.org',
    name: 'Syrian Virtual University',
    web_page: 'http://www.svuonline.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT-ZmUYudwEwq1UqylC_Re_swkT0IixnSKmv73NgyXFwpe_8Qe3HqfRws',
    id: 7750,
    acronym: 'SVU',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'tishreen.edu.sy',
    name: 'Tishreen University',
    web_page: 'http://www.tishreen.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScu69cGVsYpy2OXXuE7AomUscaMq0quR8I8wL34zB7MQq9BKT3tHoE8w',
    id: 7751,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'uok.edu.sy',
    name: 'University of Kalamoon',
    web_page: 'http://www.uok.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyXJuPOyuLQpdibZZ5YMaku71os1GYdICWExJHmIu652tiaqI4zGufcg',
    id: 7752,
    acronym: 'UK',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'wiu.edu.sy',
    name: 'Wadi International University',
    web_page: 'http://www.wiu.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTlQLeaNrfBpNBDwIcLmaaoKjmZUVPTP7fa1pnF7Hp33ZM1AMDR86xKA',
    id: 7753,
    acronym: 'WIU',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'wpu.edu.sy',
    name: 'Al-Wataniya Private University',
    web_page: 'http://www.wpu.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt-2Xj-vZ_OG-InlYkn5SqxnEpn4vZaaDTSUtep0mfLA-NmRU1rYz24LY',
    id: 7754,
    acronym: 'APU',
  },
  {
    alpha_two_code: 'SY',
    country: 'Syrian Arab Republic',
    domain: 'ypu.edu.sy',
    name: 'Yarmouk Private University',
    web_page: 'http://www.ypu.edu.sy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS97BljxwfDwjvvxuSeMVi3Mhrl5npohzzVmBgW-hyex6Pz-ou5FMq2ZUM',
    id: 7755,
    acronym: 'YPU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'au.edu.tw',
    name: 'Aletheia University',
    web_page: 'http://www.au.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzaWDYu-SS5zbJ5Z295Fr2KPviJcG26V0vcOigtFbgH8oG9OrqlCSANr0',
    id: 7756,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ccu.edu.tw',
    name: 'National Chung Cheng University',
    web_page: 'http://www.ccu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShzEtyVkYW7wKn1XR41kZKq63vphvDtmN3c0_jTadqxCLsXHQ9Vyp6Pp4',
    id: 7757,
    acronym: 'NCCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'cgu.edu.tw',
    name: 'Chang Gung University',
    web_page: 'http://www.cgu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFLrL5hy95s5gPxloj7B_JPpcsv0laMXohG89Vl74YF0Bldi6RYlKvEg',
    id: 7758,
    acronym: 'CGU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'chna.edu.tw',
    name: 'Chia Nana College of Pharmacy and Science',
    web_page: 'http://www.chna.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhn9yqdtSgUjErIton__CReN2_KkCA5hHN6ZScndQSGmk5RDh9HP4so1sK',
    id: 7759,
    acronym: 'CNCPS',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'chu.edu.tw',
    name: 'Chung Hua University',
    web_page: 'http://www.chu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoIzWcj503ZOrMroRJO_zJtyOXmXW-uDLwLGpfYpXJUTYAwmcC-hnE4tdB',
    id: 7760,
    acronym: 'CHU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'cju.edu.tw',
    name: 'Chang Jung University',
    web_page: 'http://www.cju.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlSlD0kcUH3tiIWb-oIouKYKQM9JD4DtyektOQmeghX9m4-Vznx5T7kw',
    id: 7761,
    acronym: 'CJU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'cku.edu.tw',
    name: 'Ching Kuo Institue of Management & Health',
    web_page: 'http://www.cku.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH7LS9ds2vcRZQRgKgNuybK7aACAdF0OGEyGZ14wmWdGJtpSZsq5SFmw',
    id: 7762,
    acronym: 'CKIM&H',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'cmc.edu.tw',
    name: 'China Medical College',
    web_page: 'http://www.cmc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfPqrdnW1fE9gD9u8IrIOPGKJ7UAf7QZf-93k3jmY0o9FvBpWBIXdI6A',
    id: 7763,
    acronym: 'CMC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'cpu.edu.tw',
    name: 'Central Police University',
    web_page: 'http://www.cpu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzTcmRGaSXEvXFEXlwUZFWZFZbbD9fd0GJIz82qLwEuj3W31ogFRYQGr4',
    id: 7764,
    acronym: 'CPU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'csmc.edu.tw',
    name: 'Chung Shan Medical and Dental College',
    web_page: 'http://www.csmc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1BABXaiE11hfKmXKzzXcfR9ds0hkzKxcyjnUIl6d8RqEG6QsrT8TT2w',
    id: 7765,
    acronym: 'CSMDC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'cycu.edu.tw',
    name: 'Chung Yuan Christian University',
    web_page: 'http://www.cycu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_HQUSm7jZDYx4dbAMt9k5IBpBrJs1bCysR_ivNB9YDiziuS1l6kNWQ',
    id: 7766,
    acronym: 'CYCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'cyut.edu.tw',
    name: 'Chao Yang University of Science and Technology',
    web_page: 'http://www.cyut.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ78sQ30wANdksDrPve5P_sVIbfMiNikXBa3k-5E3b3SMgB4Xq2WYP1rpU',
    id: 7767,
    acronym: 'CYUST',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'dyu.edu.tw',
    name: 'Da-Yeh University',
    web_page: 'http://www.dyu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4KsnXsB5EzK_uRk97OI9HlNHvm6w6-TcIlhJqXPkaCwF1nWHldsBS2Z6w',
    id: 7768,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'english.web.ncku.edu.tw',
    name: 'National Cheng Kung University',
    web_page: 'http://english.web.ncku.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpICq7QcIn7xVvtNZx_ZC7FVpppSgjBlNZHqOTUSYJ30Td32QslWinVBY',
    id: 7769,
    acronym: 'NCKU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'fcu.edu.tw',
    name: 'Feng Chia University',
    web_page: 'http://www.fcu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS9Np40Pt9loqKkVi2Cyf5pfBGC3PNdE9_4TI97xR_no2Fa9NjF9PgCiw',
    id: 7770,
    acronym: 'FCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'fju.edu.tw',
    name: 'Fu Jen Catholic University',
    web_page: 'http://www.fju.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7JSnWY82qgSn8sI7z9T8Wqnu6NYAB9DZLxbHWw0UpnU1j7CVmdYmdoA',
    id: 7771,
    acronym: 'FJCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'hcu.edu.tw',
    name: 'Hsuan Chuang University',
    web_page: 'http://www.hcu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPLsBszTTgPYHUMs89_r0zqr3DRsjD0eDKBUT8A8kD140NCCiP0vT1XQ',
    id: 7772,
    acronym: 'HCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'hfu.edu.tw',
    name: 'Huafan University',
    web_page: 'http://www.hfu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRgfL6NfmYi-rHng_Pwo669bSmstBsJaQAsqvvW98nOErmDVJjdhYpB_c',
    id: 7773,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'isu.edu.tw',
    name: 'I-Shou University',
    web_page: 'http://www.isu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTznizrAgNXXJTf7HdWtmrnc5kyGxcc7ChjASbfHEsXooZaCeN2zU6wkwA',
    id: 7774,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'kmc.edu.tw',
    name: 'Kaohsiung Medical College',
    web_page: 'http://www.kmc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRN-yz8HruD0W-3ODxmB10qxEK4MV7HB2j2efjO1U6hWznJGI2sKcEHFA',
    id: 7775,
    acronym: 'KMC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ksit.edu.tw',
    name: 'Kung Shan Institute of Technology',
    web_page: 'http://www.ksit.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8Ih-TJ8pBB2webo10m_iV35YKiU80g8knYJLot7IDhoUUKTu4vND3mA',
    id: 7776,
    acronym: 'KSIT',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ksu.edu.tw',
    name: 'Kun Shan University',
    web_page: 'http://www.ksu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBHPX5VPhGkBY2OJ-aP5QEXVHbYp76GB1K_G-53w0UFWn4liIA7o4o7cTi',
    id: 7777,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'kuas.edu.tw',
    name: 'National Kaohsiung University of Applied Sciences',
    web_page: 'http://www.kuas.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhTvoAZm56NdFwJEc2gTQCgwA69UBh5YlfnxoPkNYI2p8rjbSOG4hMHQ',
    id: 7778,
    acronym: 'NKUAS',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'lhu.edu.tw',
    name: 'LungHwa University of Science and Technology',
    web_page: 'http://www.lhu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkdpZWiSxswzbdIscaw7iyDu7sDHsWB4HYJbQODfqUUevpgqiaHU7IcVU',
    id: 7779,
    acronym: 'LUST',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ltc.edu.tw',
    name: 'Ling Tung University',
    web_page: 'http://www.ltc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7e-YOnXTnUQjlEw7MCBO9T4Xc3vllB_-0a2wJIlIn698rrsQpB8vjoA',
    id: 7780,
    acronym: 'LTU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'mcu.edu.tw',
    name: 'Ming Chuan University',
    web_page: 'http://www.mcu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRayG1Q-sByDJRRIo1uEAOzBsUeCy63C3YBA4MQ0ygnZz2ggUsNMGDbpOk',
    id: 7781,
    acronym: 'MCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nccu.edu.tw',
    name: 'National Chengchi University',
    web_page: 'http://www.nccu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgGtrpLFYk_bBQlinmZ0p1AEMI7063Po0LB5s4z0KLwyAH5q1HcMx-hMXTxw',
    id: 7782,
    acronym: 'NCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nchu.edu.tw',
    name: 'National Chung Hsing University Taichung',
    web_page: 'http://www.nchu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIwSXY3txX1S4_C2HvF6ffXY-1K_8gsQS1HCX9iYNkrHslIX_ii2uG2A',
    id: 7783,
    acronym: 'NCHUT',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nchulc.edu.tw',
    name: 'National Chung Hsing University Taipei',
    web_page: 'http://www.nchulc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkcx_Sj9PCqdMJNa1Ozpl9UkPIiNYXjfWKnZpq9KCC1-9_eFARtDz9BNw',
    id: 7784,
    acronym: 'NCHUT',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ncnu.edu.tw',
    name: 'National Chi Nan University',
    web_page: 'http://www.ncnu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9o3PEU-t4sABXbhRT6rzT9B8c6BeY6Z3rovQV-qCdNFVs6YdFZSlxYgw',
    id: 7785,
    acronym: 'NCNU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ncpes.edu.tw',
    name: 'National College of Physical Education and Sports',
    web_page: 'http://www.ncpes.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzkix92F-YOpkAv6PTK-aoNp_makEDcbL_UJRqzjnSiz2RMeAKXGO3k_6Qaw',
    id: 7786,
    acronym: 'NCPES',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nctu.edu.tw',
    name: 'National Chiao Tung University',
    web_page: 'http://www.nctu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJC3GqQo1XB_uTRSB1tXZ1zVBhYSGXZAalEdtqPitSjn5G2r4tRhMzmw',
    id: 7787,
    acronym: 'NCTU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ncu.edu.tw',
    name: 'National Central University',
    web_page: 'http://www.ncu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdlKMc_019KbPLsE_OEGg_yhO6Nz0hkP95zZN8p98oecpYggXAResZlp4f',
    id: 7788,
    acronym: 'NCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ncue.edu.tw',
    name: 'National Changhua University of Education',
    web_page: 'http://www.ncue.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpH3L9bgFntPskMlI_HlyDbow7Xap_PTWJhjapkuqt5cUH2L7fc3gUv8A',
    id: 7789,
    acronym: 'NCUE',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ncyu.edu.tw',
    name: 'National Chiayi University',
    web_page: 'http://www.ncyu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHn3j7ad5ZgLu8d1JXOznNVLiX6qSSfd6dznR-dUXIkDQEZYKDIp9YjQ',
    id: 7790,
    acronym: 'NCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ndhu.edu.tw',
    name: 'National Dong Hwa University',
    web_page: 'http://www.ndhu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9M1keBMO413j7VKpx65Klp_618BYyO9lQWALHuL53v4-6NECY-XLUXd5X2w',
    id: 7791,
    acronym: 'NDHU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nfu.edu.tw',
    name: 'National Formosa University',
    web_page: 'http://www.nfu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwOPvYpjBiMr0wbxtV_IxASj1ebKjhZmwm8J6hdAme76Ks8zklORJLVA',
    id: 7792,
    acronym: 'NFU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nhctc.edu.tw',
    name: 'National Hsin-Chu Teachers College',
    web_page: 'http://www.nhctc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT67pUzl1cz-IiG9YctzboH2QTL7InxPO-zCZBxzwSBBr4ZceNBmlwIzg',
    id: 7793,
    acronym: 'NHTC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nhltc.edu.tw',
    name: 'National Hualien Teachers College',
    web_page: 'http://www.nhltc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiBMQFhYEbhat31JCgkjuflLFN5N1K37-3uWjXI_7GYmKM8T-z8w591g',
    id: 7794,
    acronym: 'NHTC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nhu.edu.tw',
    name: 'Nanhua University',
    web_page: 'http://www.nhu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIHbyXz4vE-BFlqtZvdP18Fd51X_5KKEfxbWVqdmXuMmphkchoeIDczGA',
    id: 7795,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nia.edu.tw',
    name: 'National Institute of the Arts',
    web_page: 'http://www.nia.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDcg5sVGChAtyQzwQaxzpD2g3vP3ThAAbNWp9du_7y97KHgLQicP2uvbs',
    id: 7796,
    acronym: 'NIA',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'niu.edu.tw',
    name: 'National Ilan University',
    web_page: 'http://www.niu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9o3PEU-t4sABXbhRT6rzT9B8c6BeY6Z3rovQV-qCdNFVs6YdFZSlxYgw',
    id: 7797,
    acronym: 'NIU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nkfu.edu.tw',
    name: 'National Kaohsiung First University of Science and Technology',
    web_page: 'http://www.nkfu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCdufUIyvmL1ph64Zn0mA3iQh3bcCh6MPdQwU8XGRa6GPJX57fIAlDNA',
    id: 7798,
    acronym: 'NKFUST',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nknu.edu.tw',
    name: 'National Kaohsiung Normal University',
    web_page: 'http://www.nknu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR81b50rsHL8PNksSGJCJ4-GiDfLCBCKebDpBjUFZHCeXacdtiqhrKvhQ',
    id: 7799,
    acronym: 'NKNU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nou.edu.tw',
    name: 'National Open University',
    web_page: 'http://www.nou.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0nCgQCCXqsk5n5oE6llrKcN2BDOF543tF8CWzhHoXGI2jgnfS5HSU0bU',
    id: 7800,
    acronym: 'NOU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'npttc.edu.tw',
    name: 'National Pingtung Teachers College',
    web_page: 'http://www.npttc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdvx0eXREvkqdIrKf2BKmL6D3Ahjo5z05zQAN6pLkoSblP63DXjoYOSQ',
    id: 7801,
    acronym: 'NPTC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'npust.edu.tw',
    name: 'National Pingtung University of Science and Technology',
    web_page: 'http://www.npust.edu.tw/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRHAQHbpUwYHew7Y1kvi3hvgu2m2dq1QQVQpeLpgOzgPt5NHqxGIPxTgg',
    id: 7802,
    acronym: 'NPUST',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nsysu.edu.tw',
    name: 'National Sun Yat-Sen University',
    web_page: 'http://www.nsysu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5e1PDj4_v2bQAtrEelpbcB4pBIZTR7gdKDOihJcGaqd5VHvbJtJT0Qg',
    id: 7803,
    acronym: 'NSYU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntca.edu.tw',
    name: 'National Taiwan College of the Arts',
    web_page: 'http://www.ntca.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzCrjjHyeo311YxnIXjN1i3Q0vPYE2lThukwbsGfm8Vliu913fA9V25pIP',
    id: 7804,
    acronym: 'NTCA',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntcn.edu.tw',
    name: 'National Taipei College of Nursing',
    web_page: 'http://www.ntcn.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP0_ayqLRjRsJluW0S5HAEzOdcx8nZZScmctp4R2e6uUxjeqMR-sMiHA',
    id: 7805,
    acronym: 'NTCN',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntcpe.edu.tw',
    name: 'National Taiwan College of Physical Education and Sports',
    web_page: 'http://www.ntcpe.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVnEOG45HgMK_6ddEE_nTtWT_oYhMbMVNtsTprs2IJnJQ6Fnx5mrJ-YP8D',
    id: 7806,
    acronym: 'NTCPES',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntctc.edu.tw',
    name: 'National Taichung Teachers College',
    web_page: 'http://www.ntctc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHjFrCdHy1SuntlJ6Ky5u7Vm1-t8jW2v23_1v8VbfyW9wBVteQW0ruqw',
    id: 7807,
    acronym: 'NTTC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nthu.edu.tw',
    name: 'National Tsinghua University',
    web_page: 'http://www.nthu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnYG_OEZH1Dv4hR7Kn0E7yQ4Z-GY_w8rAJ5SclFrpGTaJlVAUk3WkPGt0',
    id: 7808,
    acronym: 'NTU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntntc.edu.tw',
    name: 'National Tainan Teachers College',
    web_page: 'http://www.ntntc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5pNijLZTTCVi5u8b6qw7nUN8TJfYTamj6HXRpI8_BOCqRe2xU2nBlP1E',
    id: 7809,
    acronym: 'NTTC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntnu.edu.tw',
    name: 'National Taiwan Normal University',
    web_page: 'http://www.ntnu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTXOq0l2dKzzvMgHsIpEfEmXn3fliWohmVhI6KvToYVxFs6pU12WqPplQ',
    id: 7810,
    acronym: 'NTNU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntou.edu.tw',
    name: 'National Taiwan Ocean University',
    web_page: 'http://www.ntou.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxV48qJmI8o5HMMsQT9MUR8IDW67qANmd2uzHoMnIAOMJ-JEVYTriGK4dJJg',
    id: 7811,
    acronym: 'NTOU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntptc.edu.tw',
    name: 'National Taipei Teachers College',
    web_page: 'http://www.ntptc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlleAyNKPLqHsUcZDfHi0TjTsHXM-wIzjBuQkiM2greFgWXkwaHhNYww',
    id: 7812,
    acronym: 'NTTC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntpu.edu.tw',
    name: 'National Taipei University',
    web_page: 'http://www.ntpu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRqYsoodUBVk5UA9sNa3gFxq5O-UpwuX1hRCwNY9nrwo_cFmpxe3q2PgU',
    id: 7813,
    acronym: 'NTU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntttc.edu.tw',
    name: 'National Taitung Teachers College',
    web_page: 'http://www.ntttc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzQ2nUg_RpSy_5qkZlxLQmuvTlyqi49GO4vC0fGCIum6vC6rzZKa2bQ',
    id: 7814,
    acronym: 'NTTC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntu.edu.tw',
    name: 'National Taiwan University',
    web_page: 'http://www.ntu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStC8XRf-cQ-mWs5orD2udiBKxEmD1axD3Xwpdcwdv3re2YaSF2oS2uDvU',
    id: 7815,
    acronym: 'NTU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntust.edu.tw',
    name: 'National Taiwan University of Science and Technology',
    web_page: 'http://www.ntust.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJlAFNt0VIO3iZ_H9782h665Z6ZRVAMBon-f8myjm3yWcrTDN79FQul7kog',
    id: 7816,
    acronym: 'NTUST',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ntut.edu.tw',
    name: 'National Taipei University of Technology',
    web_page: 'http://www.ntut.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRqYsoodUBVk5UA9sNa3gFxq5O-UpwuX1hRCwNY9nrwo_cFmpxe3q2PgU',
    id: 7817,
    acronym: 'NTUT',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nuk.edu.tw',
    name: 'National University of Kaohsiung',
    web_page: 'http://www.nuk.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR81b50rsHL8PNksSGJCJ4-GiDfLCBCKebDpBjUFZHCeXacdtiqhrKvhQ',
    id: 7818,
    acronym: 'NUK',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'nuu.edu.tw',
    name: 'National United University',
    web_page: 'http://www.nuu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREEMTmfCgkqxG3q3jd5Ly7hXqvceUPSx3L4l8ZQtMgEydrjuXHlmyklWQ',
    id: 7819,
    acronym: 'NUU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ouk.edu.tw',
    name: 'Kaohsuing Open University',
    web_page: 'http://www.ouk.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_y5rn63g-rWR7SxePA8gF0SpIvYOGwUn7_Cx4p35XTmb1i6JINYm8vAUG',
    id: 7820,
    acronym: 'KOU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'pccu.edu.tw',
    name: 'Chinese Culture University',
    web_page: 'http://www.pccu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR94omDvWmjvlX_LcSfVBBGA5SUvi2j0Apit_DRJuSRW692VIPsItpBdw',
    id: 7821,
    acronym: 'CCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'pu.edu.tw',
    name: 'Providence University',
    web_page: 'http://www.pu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw04HDRrYkQsayJJzhehw5ASgPrKb3562CfGe8leES_xMiKs5DIXcoVg',
    id: 7822,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'scc.edu.tw',
    name: 'Shih Chien University',
    web_page: 'http://www.scc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6vb1sJX2_FwIpANzAkwhj4jcSX1ha11x-uFbC7Y7S5DfUh46t_Ytx0A',
    id: 7823,
    acronym: 'SCU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'scu.edu.tw',
    name: 'Soochow University',
    web_page: 'http://www.scu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpQAK4XIaJLFmo-Fzqaa5_vI6FsYfBMDQORbZqk7AF00Hg7Q2Zzq3c7ho',
    id: 7824,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'shu.edu.tw',
    name: 'Shih Hsin University',
    web_page: 'http://www.shu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvo9Z3BGFDDbMSCUG8TO4ZvCd_hJWiCMCX9krVqUH64-5TGiq3GAMhA1o',
    id: 7825,
    acronym: 'SHU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'sjsmit.edu.tw',
    name: "St. John's & St. Mary's Institute of Technology",
    web_page: 'http://www.sjsmit.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-eiX6ifTMVjXkP0vu1XEwVIS0-s_h3Xw3xxBL41VMHGaQ-n7K-mDkAc8',
    id: 7826,
    acronym: 'SJ&SMIT',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'stut.edu.tw',
    name: 'Southern Taiwan University of Technology',
    web_page: 'http://www.stut.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1vRrgsOvEpLGMQII7piSb9c3XWwlUpt1RT0ObyPWHgKmI5OR9hFPyyQ',
    id: 7827,
    acronym: 'STUT',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'tcu.edu.tw',
    name: 'Tzu Chi College of Medicine and Humanities',
    web_page: 'http://www.tcu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCj7ZA8aSPQctt0q6qlpvkkpSFWMYhSLGc6hEAQWzdVAgvZfSgH2sXUo4',
    id: 7828,
    acronym: 'TCCMH',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'thmu.edu.tw',
    name: 'TaiChung Healthcare and Management University',
    web_page: 'http://www.thmu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0QaZzEuXx9W89VYFMIvaFB3gpbWIbjbvuj2zihv6RLTeviXyK0B9NjA',
    id: 7829,
    acronym: 'THMU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'thu.edu.tw',
    name: 'Tunghai University',
    web_page: 'http://www.thu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsRLhsJmmYl1vHzdzyWD-l7LCct0LABO5Jc7QjWYVtHQ7t2yRRZq_JB44',
    id: 7830,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'tku.edu.tw',
    name: 'Tamkang University',
    web_page: 'http://www.tku.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkKWyPe1rv5EIi4JM0p4SMK38DK7OEWrKILjVREz1a_eD_1R1ANE4QY0JQ',
    id: 7831,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'tmc.edu.tw',
    name: 'Taipei Medical College',
    web_page: 'http://www.tmc.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyx-kEQm9yqSwErtT6ZRPky8ugTeSYjoIvnjRtCYtdkaD5EMYpXMSXUw',
    id: 7832,
    acronym: 'TMC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'tmtc.edu.tw',
    name: 'Taipei Municipal Teachers College',
    web_page: 'http://www.tmtc.edu.tw/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRrtAdDIKnySaAa6_2bXOqULInvlHT1iFRR2aoc_gSLXvvguVNgfzkvQA',
    id: 7833,
    acronym: 'TMTC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'tnca.edu.tw',
    name: 'Tainan National College of the Arts',
    web_page: 'http://www.tnca.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdVALlrqNyEJ-__b_jAaXhR2KPTqipvCdmdvsXyQkv3kZUlH971ivxPg',
    id: 7834,
    acronym: 'TNCA',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'tpec.edu.tw',
    name: 'Taipei Physical Education College',
    web_page: 'http://www.tpec.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFhzImlDeCs1xHZ3JjEnd7cx0kHvLa_Lz3l0BpyFQRWPStFkPOVqgWJuw',
    id: 7835,
    acronym: 'TPEC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ttit.edu.tw',
    name: 'Tatung Institute of Technology',
    web_page: 'http://www.ttit.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkYA6S5dSJhohucJvneqkfn6VM5zrtWj38DRZhVmnQ_niPP5cBTEZIVuM',
    id: 7836,
    acronym: 'TIT',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ttu.edu.tw',
    name: 'TaTung University',
    web_page: 'http://www.ttu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRgfL6NfmYi-rHng_Pwo669bSmstBsJaQAsqvvW98nOErmDVJjdhYpB_c',
    id: 7837,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'ym.edu.tw',
    name: 'National Yang Ming Medical College',
    web_page: 'http://www.ym.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFfY79lSySYGgx8dNuAD-D18lPr7xORLKN198LVRmW7b_eF61oWclVlQ',
    id: 7838,
    acronym: 'NYMMC',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'yuntech.edu.tw',
    name: 'National Yunlin University of Science and Technology',
    web_page: 'http://www.yuntech.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUwTCIqKYtuCyR07rcqoQ7ZCB35cJCABbdIsVOjdHdEJqhw_5rSmV07w',
    id: 7839,
    acronym: 'NYUST',
  },
  {
    alpha_two_code: 'TW',
    country: 'Taiwan, Province of China',
    domain: 'yzu.edu.tw',
    name: 'Yuan Ze University',
    web_page: 'http://www.yzu.edu.tw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5x2Xl1WdEKz1bjk3a3YI0FlZzbiAbNE3aKWBnUHMxlR91awYFhoiHAhg',
    id: 7840,
    acronym: 'YZU',
  },
  {
    alpha_two_code: 'TJ',
    country: 'Tajikistan',
    domain: 'tajagroun.tj',
    name: 'Tajik Agrarian University',
    web_page: 'http://www.tajagroun.tj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHde6cXYfqSa-9rFKUFlGauY1F0IG_UpKuE3OY4cNTnfamKC0jGvwFNQ',
    id: 7841,
    acronym: 'TAU',
  },
  {
    alpha_two_code: 'TJ',
    country: 'Tajikistan',
    domain: 'tgnu.tarena.tj',
    name: 'Tajik National University',
    web_page: 'http://tgnu.tarena.tj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSu6XW_M-HWMNHJmDwuBm6h9Md2nX848Jpw_q4zRZPB2QE_fulElI1DPY8',
    id: 7842,
    acronym: 'TNU',
  },
  {
    alpha_two_code: 'TJ',
    country: 'Tajikistan',
    domain: 'tiu.tj',
    name: 'Tajikistan International University',
    web_page: 'http://www.tiu.tj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrjPk6w7PK6bWfNDGxRaInL94jNwdQAUlfoh8YiJo2fn3wbmspguH1G-A',
    id: 7843,
    acronym: 'TIU',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'avu.org',
    name: 'African Virtual University',
    web_page: 'http://www.avu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV_B8xPYVFjBoqN-EuHGXjO-9vZQVo5D6GWZ5XPAZQDeDQG0fkUv4GJso',
    id: 7845,
    acronym: 'AVU',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'dit.ac.tz',
    name: 'Dares Salaam Institute of Technology',
    web_page: 'http://www.dit.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStzqfyGTqYTRM2xrpLgW0y04faabMyiLMfoXGEZOBhrbVyibOHymcfu4g',
    id: 7846,
    acronym: 'DSIT',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'hkmu.ac.tz',
    name: 'Hubert Kairuki Memorial University',
    web_page: 'http://www.hkmu.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1ujtorgtf419aVv6fOp8pmU70CZMTJjCTw9CEBKYEyaStDTTd9kTATA',
    id: 7847,
    acronym: 'HKMU',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'iaa.ac.tz',
    name: 'Institute of Accountancy Arusha',
    web_page: 'http://www.iaa.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1dmRVxnVQkbDCHfaFOTU_HgI4lWrD6SeioDvNVkG4CT_bUG2-icjLOw',
    id: 7848,
    acronym: 'IAA',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'ifm.ac.tz',
    name: 'Institute of Finance Management',
    web_page: 'http://www.ifm.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAA5s9CrzCyKWrEFdkyfIXwVkGSUqbqtVCt3oIhQIkNCPM_a0Q_ZeOKss',
    id: 7849,
    acronym: 'IFM',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'imtu.edu',
    name: 'International Medical & Technological University',
    web_page: 'http://www.imtu.edu/index.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdtjT-SySeZuUxMmLMdEtcMlgU8bEgysRxmncwl44NdegD_ZC947f__64',
    id: 7850,
    acronym: 'IM&TU',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'kcmc.ac.tz',
    name: 'Kilimanjaro Christian Medical College',
    web_page: 'http://www.kcmc.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpaC-XJbCKKlNqWcsB77ALpBCgm_A9Y_JBNhQyxu7P0bmmsJeRanY2HTw',
    id: 7851,
    acronym: 'KCMC',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'mmu.ac.tz',
    name: 'Mount Meru University',
    web_page: 'http://www.mmu.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuTlRCPis3YgFAWWuX0SfFu81OiKF3X7WV1QXYcx2AawACIH2nNbONXw',
    id: 7852,
    acronym: 'MMU',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'mnma.ac.tz',
    name: 'Mwalimu Nyerere Memorial Academy',
    web_page: 'http://www.mnma.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY1mEaIb23TpVOXmzgClLc1x59uQcbiUtJrs6F5YE0CwSXoW94W6zT6qc',
    id: 7853,
    acronym: 'MNMA',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'muccobs.ac.tz',
    name: 'Moshi University College of Cooperative and Business Studies',
    web_page: 'http://www.muccobs.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiFPm3XLUj9vsWC4sMEcmb9mUphdv5kQaj6wAajk0yY7cFOSRv45kLiw',
    id: 7854,
    acronym: 'MUCCBS',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'muchs.ac.tz',
    name: 'Muhimbili University of Health and Allied Sciences (MUHAS)',
    web_page: 'http://www.muchs.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKBnp60jUj0tu5dRw9ZxUyWIFEe6hMUzK-RYJXU7DvCBDHj5dXsnT9REQ',
    id: 7855,
    acronym: 'MUHAS(',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'mzumbe.ac.tz',
    name: 'Mzumbe University (Chuo Kikuu Mzumbe)',
    web_page: 'http://www.mzumbe.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnLJbsvaZl_U3akeT3kq_FTVZYM7gtYcknKuEX5CZlyYuNYY1Qaeo-Og',
    id: 7856,
    acronym: 'MU(KM',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'out.ac.tz',
    name: 'Open University of Tanzania',
    web_page: 'http://www.out.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCnV9BDsyyxdqF0ZMrOu-CtNqhCLmVQ9MLnu37Rsk0pRjrxQ8mviN_Wv4',
    id: 7857,
    acronym: 'OUT',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'saut.ac.tz',
    name: 'St. Augustine University of Tanzania',
    web_page: 'http://www.saut.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg7d9pyMCPomoE6skiTaX_qyk8odzEB5W-rTl4U2Q8HLOyvvdMN4wXx4o',
    id: 7858,
    acronym: 'SAUT',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'sekuco.org',
    name: 'Sebatian Kolowa University College',
    web_page: 'http://www.sekuco.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZbQHFJNuY1Jz8MqapAI_zkW8dDPRFtpjCRVkHqBokBcfLYroMGZzT7g',
    id: 7859,
    acronym: 'SKUC',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'stjosephtanzania.com',
    name: 'St. Joseph Group of Institutes',
    web_page: 'http://www.stjosephtanzania.com/sjcet/index.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_KJZE4A6eKquQ38HOTGahDSFrIaIl6QbJcU73_D4jNfmaJxWnvUhqKAY',
    id: 7860,
    acronym: 'SJGI',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'suanet.ac.tz',
    name: 'Sokoine University of Agriculture',
    web_page: 'http://www.suanet.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReY209y7POOjOqpkf-pYKFRxvcg7BXNuViKw0GO_F8bp9HzA_xrSjCwIo',
    id: 7861,
    acronym: 'SUA',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'tumaini.ac.tz',
    name: 'Tumaini University - Iringa University College',
    web_page: 'http://www.tumaini.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEJtGeZiuyi4h1Lmvzl7Yl7MD7FZZpsrTJTA2N1mphC9G3eCNVgnbkzhs',
    id: 7862,
    acronym: 'TU-IUC',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'udom.ac.tz',
    name: 'University of Dodoma',
    web_page: 'http://www.udom.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmdDgb9puXxCJnW8Jt4ZnP6S_ujRQmAsDNtvwT2FfBmPzlhd0IgN87Ldo',
    id: 7863,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'TZ',
    country: 'Tanzania, United Republic of',
    domain: 'udsm.ac.tz',
    name: 'University of Dar Es Salaam',
    web_page: 'http://www.udsm.ac.tz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCnV9BDsyyxdqF0ZMrOu-CtNqhCLmVQ9MLnu37Rsk0pRjrxQ8mviN_Wv4',
    id: 7864,
    acronym: 'UDES',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'ait.ac.th',
    name: 'Asian Institute of Technology',
    web_page: 'http://www.ait.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 7865,
    acronym: 'AIT',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'aru.ac.th',
    name: 'Phranakhon Si Ayutthaya Rajabhat University',
    web_page: 'http://www.aru.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw4-3-2q7wbnmLxb6BttuP1HZGoomOy_jK4DgwV1BGJ2tVgpAg6eHnIA',
    id: 7866,
    acronym: 'PSARU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'asianust.ac.th',
    name: 'Asian University of Science and Technology',
    web_page: 'http://www.asianust.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 7867,
    acronym: 'AUST',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'au.edu',
    name: 'Assumption University of Thailand',
    web_page: 'http://www.au.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGcgIZ9UHXYFpz339jsxeS100oxtZ5GRlsACBpmmI5Pc0EuXIfh10MXg',
    id: 7868,
    acronym: 'AUT',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'bu.ac.th',
    name: 'Bangkok University',
    web_page: 'http://www.bu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJgud_SoZUL-bir75TQ09tdr2Or0lJEmZpFttsr_wMBeBwceLxssk1Eow',
    id: 7869,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'buu.ac.th',
    name: 'Burapha University',
    web_page: 'http://www.buu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0ZYYPEkE9NhtcwuZckeSrYGV59nm_EDZ3bzBMGa5fToGPnN_SyiVV3hA',
    id: 7870,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'chiangmai.ac.th',
    name: 'Chiang Mai University',
    web_page: 'http://www.chiangmai.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuArkadon8CP9qn7klnHvl6c95VfNa5oec-gUK_yBsFG6RKMdmCGL0Ow',
    id: 7871,
    acronym: 'CMU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'chiangraicampus.rmutl.ac.th',
    name: 'Rajamangala University of Technology Lanna Chiang Rai',
    web_page: 'http://chiangraicampus.rmutl.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1-wriivA0cN8FdXqhMqQthi9KlQ-o_UsVBZhZUwDCrVEud8wYnEruqS8',
    id: 7872,
    acronym: 'RUTLCR',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'chula.ac.th',
    name: 'Chulalongkorn University',
    web_page: 'http://www.chula.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQ_qTnkR4WDJGZgAxDn8aCx6-W9kvQCguqh2ATtdDe1kpGSnoljIl98g4',
    id: 7873,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'cmru.ac.th',
    name: 'Chiang Mai Rajabhat University',
    web_page: 'http://www.cmru.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFdAM73eVDXOK7bJpmhgYEmYmHaPE4zD6DiUygTqiCItdOG0ssPjKGrfg',
    id: 7874,
    acronym: 'CMRU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'cmvc.ac.th',
    name: 'Chiang Mai Vocational College',
    web_page: 'http://www.cmvc.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ-_2dmOjbrSCNxnmo2Fh3qqn9tLDQLehIzSdBOjbuwh-zi1BrmsbB4w',
    id: 7875,
    acronym: 'CMVC',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'cpu.ac.th',
    name: 'Chaopraya University',
    web_page: 'http://www.cpu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRfWOkWFpRYG6V1qIW-GQZmbKJ-h4dbz2TXqIoP71HGL4n0gYpY0mx30V8',
    id: 7876,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'dpu.ac.th',
    name: 'Dhurakijpundit University',
    web_page: 'http://www.dpu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkJ9yycvCI9zXOUgzs6mVRoy0lGUcNgxCZRjmISQdN3WfqDadxW9i19wU',
    id: 7877,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'dusit.ac.th',
    name: 'Suan Dusit Rajabhat University',
    web_page: 'http://www.dusit.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYuWVxuPlwsLSwK8-Mlmv5K3FWVh6v0xHWngEs6RBGeIUiZLC6A7KYwA0',
    id: 7878,
    acronym: 'SDRU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'eau.ac.th',
    name: 'Eastern Asia University',
    web_page: 'http://www.eau.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF66iahccWdQh9p2c_6LO7hlDe2MJ2Oy8bhOZXExc7Wvj367t4A2Fm-sE',
    id: 7879,
    acronym: 'EAU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'efau.org',
    name: 'Engineering Faculty of Asian University',
    web_page: 'http://www.efau.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 7880,
    acronym: 'EFAU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'hcu.ac.th',
    name: 'Huachiew Chalermprakiet University',
    web_page: 'http://www.hcu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlxO_AcdfiRBujEDN5TrBHFfCk2_MuyQc3DDBlZBKCK26i9vfV1gLLuQ',
    id: 7881,
    acronym: 'HCU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'hu.ac.th',
    name: 'Hatyai University',
    web_page: 'http://www.hu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQveD4A0X31zlSHR5_90V-BpMGg9lvW60Get8-V3UIw7fFsrokAslBtOg',
    id: 7882,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'ibc.ac.th',
    name: 'International Buddhist College',
    web_page: 'http://www.ibc.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS362irjjIAUXuZM_pPwcOblskA3iNNe-XZGPWsjjV7Pu9UCy6X0MB0azg-',
    id: 7883,
    acronym: 'IBC',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'kbu.ac.th',
    name: 'Kasem Bundit University',
    web_page: 'http://www.kbu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQey8O9aitaYXp-6m4C2mvf6W28Ece7sQea2OWD2T1cg3eiKMNQq0FUa5M',
    id: 7884,
    acronym: 'KBU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'kku.ac.th',
    name: 'Khon Kaen University',
    web_page: 'http://www.kku.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ2t_BeQkAEJ8MWRrIeu_3K1zSJKV9kT3SRhtUsCLJbaF1JspzK5CXOw',
    id: 7885,
    acronym: 'KKU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'kmitl.ac.th',
    name: "King Mongkut's University of Technology Ladkrabang",
    web_page: 'http://www.kmitl.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsv6cw4RPc5MBIXQpWOkTBHsojv0FT8j_8uyO8Ub3gbM9BIT7aOCD2-0A',
    id: 7886,
    acronym: 'KMUTL',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'kmutnb.ac.th',
    name: "King Mongkut's University of Technology North Bangkok",
    web_page: 'http://www.kmutnb.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEshMnILjO7o2DL3KWYEYvvITwqyweoEJbTjk_erRXVlUUXUo_WSetygk',
    id: 7887,
    acronym: 'KMUTNB',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'kmutt.ac.th',
    name: "King Mongkut's University of Technology Thonburi",
    web_page: 'http://www.kmutt.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcKNYI_nft9rq37abAEMM7zfAs9j_hkEzVlSTOKYLcorG9TshZrcSDEyQ',
    id: 7888,
    acronym: 'KMUTT',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'krirk.ac.th',
    name: 'Krirk University',
    web_page: 'http://www.krirk.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAO_VoOOJRPGsNBWzQK4ZqpjOPTabnA60JvLGMHFpudmnaHOS1EEBnrg',
    id: 7889,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'ku.ac.th',
    name: 'Kasetsart University',
    web_page: 'http://www.ku.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx3sM_xfRtvVWUdYgq_Ls8ok-nJcZHjAyR9hOAs1eXRcP9kTF6gavFXA0',
    id: 7890,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'lampangvc.ac.th',
    name: 'Lampang Vocational College',
    web_page: 'http://www.lampangvc.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDBvPb4VaT4N8ZscDIolIaBA5W6k5BooDm0FMz9hxJLuOnJRg9TgXSTCw',
    id: 7891,
    acronym: 'LVC',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'lcat.ac.th',
    name: 'Lamphun College of Agriculture and Technology',
    web_page: 'http://www.lcat.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7c5Zsyt_qS-ScwECloIp73xuTRZMercFD5pCaRKR6O9kK7y_fBs-8YV8t',
    id: 7892,
    acronym: 'LCAT',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'lcct.ac.th',
    name: 'Lampang College of Commerce and Technology',
    web_page: 'http://www.lcct.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 7893,
    acronym: 'LCCT',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'lpru.ac.th',
    name: 'Lampang Rajabhat University',
    web_page: 'http://www.lpru.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRummK-awstNOj4EDZjjiMiHxDuw3wLFgrncFiR8mLLA8nLArFeTsXEVb0',
    id: 7894,
    acronym: 'LRU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'mahidol.ac.th',
    name: 'Mahidol University',
    web_page: 'http://www.mahidol.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQmxVilDldAmmAPZVFSRe6opxAR6kWf8ws1vSHz1NDRkLWUfbfBFAIGFQ',
    id: 7895,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'mbu.ac.th',
    name: 'Mahamakut Buddhist University',
    web_page: 'http://www.mbu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-ipHxAxTNLqWby23eNg-CLuSO6SnhFuRUNuzuO7ptNF9F4Fp1ST26kjc',
    id: 7896,
    acronym: 'MBU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'mcu.ac.th',
    name: 'Mahachulalongkorn Buddhist University',
    web_page: 'http://www.mcu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlyFXTFKjcVfEjF3SCXw7uNFosaw3oFk_n-lgb-HNEiLPa2KoiMgtnAbQk',
    id: 7897,
    acronym: 'MBU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'mfu.ac.th',
    name: 'Mae Fah Luang University',
    web_page: 'http://www.mfu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBYzl9BeKfnRT1uUd3MO9TMWiYm0yPxYY7yAHrjFe3SaXH6vPikADcBTY',
    id: 7898,
    acronym: 'MFLU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'mju.ac.th',
    name: 'Maejo University',
    web_page: 'http://www.mju.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyL7wbXNpNcBeV5n8dye0sni0D8MDazt-U-yXcWPR1IceHJ0zwzpthTSM',
    id: 7899,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'msu.ac.th',
    name: 'Mahasarakham University',
    web_page: 'http://www.msu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7ORStvAVg75UbhdwR87iYyBNJ5BT028pHZjyMMQJazQ_objU0jY0HGEHR',
    id: 7900,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'muic.mahidol.ac.th',
    name: 'Mahidol University International College',
    web_page: 'http://www.muic.mahidol.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVqzHAud5DzeuFEDj44TABD1ImppedUsvSr4Wm6Dv8jpU8RSBxPKT83cQ',
    id: 7901,
    acronym: 'MUIC',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'mut.ac.th',
    name: 'Mahanakorn University of Technology',
    web_page: 'http://www.mut.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT27SRO7JYSWWG7HK_fr092dAz3vRpzVZju9uvo7FjpePRbE4SxcdnpSA',
    id: 7902,
    acronym: 'MUT',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'nan.rmutl.ac.th',
    name: 'Rajamangala University of Technology Lanna Nan',
    web_page: 'http://www.nan.rmutl.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3tvLv-Hyoxk3CS6JqbLXPE7P6dbPDHnYfGT1Z7-PxSU2spGJsMLa2mw',
    id: 7903,
    acronym: 'RUTLN',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'nida.ac.th',
    name: 'National Institute of Development Administration',
    web_page: 'http://www.nida.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJLPAc7QambJNXbH5wCoTrqodEpGVUkAAYrmdJFhYmgY9SaaOgVwknRdZZ',
    id: 7904,
    acronym: 'NIDA',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'nivadhana.ac.th',
    name: 'Nivadhana University',
    web_page: 'http://www.nivadhana.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjFLmpOiY_eisCESqxZUxooLxttiCCVMJtdxD70VRUqsxDVGYg-IBvPaE',
    id: 7905,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'nu.ac.th',
    name: 'Naresuan University',
    web_page: 'http://www.nu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQKEllYlpugHQV17HjFkLWMnTYH-Sk7cO-qTg5O9buicuVVc7K2GCM5GY',
    id: 7906,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'payap.ac.th',
    name: 'Payap University Chaiang Mai',
    web_page: 'http://www.payap.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfNV8un3GWM8olSDL7W2qWyUBPJ2jg5BpxLZY-6zCbboBjEvjCTLh_pjQ',
    id: 7907,
    acronym: 'PUCM',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'psu.ac.th',
    name: 'Prince of Songkla University',
    web_page: 'http://www.psu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOnIAdT8wja37LUZSdWPz1WS3Butj-bCu6eaFRsuoIT5NBN4qiNH0N424',
    id: 7908,
    acronym: 'PSU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'ptu.ac.th',
    name: 'Pathumthani University',
    web_page: 'http://www.ptu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT73Klc7WH0CL4Jl1QCiLCCJ15lh36i2OtqF_L0eYshVFbVvMhobuGlK3M',
    id: 7909,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'rmutp.ac.th',
    name: 'Rajamangala University of Technology Phra Nakhon',
    web_page: 'http://www.rmutp.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgkNIHC7yYmHyvwWAA8ZwtxsceEXJBoxxqfwH6syS8sWQ-2sMNAHNtd7I2',
    id: 7910,
    acronym: 'RUTPN',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'rsu.ac.th',
    name: 'Rangsit University',
    web_page: 'http://www.rsu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGD5oIzcTpbGaoLAJIqQ13pUMoBdXvotHphECk7ECt7At-YFkBuq-UDx4',
    id: 7911,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'ru.ac.th',
    name: 'Ramkhamhaeng University',
    web_page: 'http://www.ru.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhapvi8NsFMwXOJE3RkaS5Nyd66msVmxyA2kaZg_ylbpuFZPHePUFg5pk',
    id: 7912,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'sau.ac.th',
    name: 'South-East Asia University',
    web_page: 'http://www.sau.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRF66iahccWdQh9p2c_6LO7hlDe2MJ2Oy8bhOZXExc7Wvj367t4A2Fm-sE',
    id: 7913,
    acronym: 'SAU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'shinawatra.ac.th',
    name: 'Shinawatra University',
    web_page: 'http://www.shinawatra.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2mzHJutsbZOA2Gfg1e_pfV4ogq24zmAF3ee0XgUO2cnQe1Ktg73WFQQ',
    id: 7914,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'siam.edu',
    name: 'Siam University',
    web_page: 'http://www.siam.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ3GnBQPg9kAo9kk9MeRN08w7aL_N0uFGCR5gyl9yXZGJQs9T5FGbxU2Y',
    id: 7915,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'spu.ac.th',
    name: 'Sripatum University',
    web_page: 'http://www.spu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1gHvGJePhwnXq3O6BR0eBjFIOmS14m6HUGfoxAsXQSuhfU818DPipHA',
    id: 7916,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'stamford.edu',
    name: 'Stamford International University',
    web_page: 'http://www.stamford.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQherb8owfzX2kEW2Xcd4vLX9zLq7tu360HlYU1Z-Tto2rCs8FN9tftgw',
    id: 7917,
    acronym: 'SIU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'stjohn.ac.th',
    name: "St. John's University",
    web_page: 'http://www.stjohn.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXTcM97XTgnATX3v2lCS8IreBVA0REGNwEAK55I5uSAydmqecMi8ggew',
    id: 7918,
    acronym: 'SJU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'stou.ac.th',
    name: 'Sukhothai Thammathirat Open University',
    web_page: 'http://www.stou.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQCRO0AhD98TgiVYpQisqbCe3BolDFIqSt-CFLIqZC7Lyk9C0mA0BttA',
    id: 7919,
    acronym: 'STOU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'su.ac.th',
    name: 'Silpakorn University',
    web_page: 'http://www.su.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6Sl4X2tUk3U8_H18-jcKBgI8ud5FBaEfSVbuMFzg3EvkTbF2zzf5ksw',
    id: 7920,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'sut.ac.th',
    name: 'Suranaree University of Technology',
    web_page: 'http://www.sut.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfUqaHvplxYuPZKhP06XYVQXxkmSfjnp2WCC515JPibSlQWRV5_98frMQ2',
    id: 7921,
    acronym: 'SUT',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'swu.ac.th',
    name: 'Srinakharinwirot University',
    web_page: 'http://www.swu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL6A_8zwwElOkNRs3IOFc4i1s0mtS0ibudVzqZW_jWI5EqHrBJ2QFckx1KoQ',
    id: 7922,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'tsu.ac.th',
    name: 'Thaksin University',
    web_page: 'http://www.tsu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbp2H0w2wJdwtZp7tf1FzgaLzgWJuh5gsGwotbNdvFVj7EG4ZcIvqkb4g',
    id: 7923,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'tu.ac.th',
    name: 'Thammasat University',
    web_page: 'http://www.tu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF4DRQDJgJCGrZgfx34fpgp1Pg5GdCeuru75StiXbvc1Mk08NVFMCxDQ',
    id: 7924,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'ubu.ac.th',
    name: 'Ubonratchathani University',
    web_page: 'http://www.ubu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5MkY9Xu5w_MpoVw7UPvL46U8A1EqokBqQr73YtdkowRyEvTML6kRFH8c',
    id: 7925,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'utcc.ac.th',
    name: 'University of the Thai Chamber of Commerce',
    web_page: 'http://www.utcc.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv6W2ER6T_rZlsEeY3AXzYqnUjwGVO7ZJAWB3BnT57MnrPBRdo7c_HRgg',
    id: 7926,
    acronym: 'UTCC',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'vu.ac.th',
    name: 'Vongchavalitkul University',
    web_page: 'http://www.vu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkRUroHVhZybXuxzCyaryjIxbvPW5IXtLvBAJD1iegOrUhO1aYDDHySA',
    id: 7927,
    acronym: 'VU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'webster.ac.th',
    name: 'Webster University Thailand',
    web_page: 'http://www.webster.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrFDCnPwhjgzerCPTKZhLf17BW7w-lHNMW8NEYaoev4Rxk-fd2rQKkOxH-',
    id: 7928,
    acronym: 'WUT',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'wu.ac.th',
    name: 'Walailak University',
    web_page: 'http://www.wu.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQl2oWZwNN1Tq-IK3IDHYiUAxRAgwoXgaGaxDb9qYq11BaeU8zmgEAt42U',
    id: 7929,
    acronym: 'WU',
  },
  {
    alpha_two_code: 'TH',
    country: 'Thailand',
    domain: 'yonok.ac.th',
    name: 'Yonok University',
    web_page: 'http://www.yonok.ac.th/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCH357fs523F_4VujPFFNUQ5Hwjveq5yen1vUB4CquJZtBnGKhItfXe0Y',
    id: 7930,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'TG',
    country: 'Togo',
    domain: 'univ-lome.tg',
    name: 'Université de Lomé',
    web_page: 'http://www.univ-lome.tg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfBfYMapIRW7MeNpV550rUyXPc8vS-xwVFTuLpk4ZR768MVwjs-iimTg',
    id: 7931,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'TO',
    country: 'Tonga',
    domain: 'usp.ac.fj',
    name: 'University of the South Pacific Centre',
    web_page: 'http://www.usp.ac.fj/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbOEiSb7HvrfcT_97s8w8MXNl7gbwZ0D_rnO7sYYAnpDjgWBhkoBAWhgU',
    id: 7932,
    acronym: 'USPC',
  },
  {
    alpha_two_code: 'TT',
    country: 'Trinidad and Tobago',
    domain: 'usc.edu.tt',
    name: 'University of the Southern Caribbean',
    web_page: 'http://www.usc.edu.tt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR21TgfcRblyDbfoD6hOU_3Ml8rgGrQJDDjkq1c1YT42JrXb7Mk3EXuCw',
    id: 7933,
    acronym: 'USC',
  },
  {
    alpha_two_code: 'TT',
    country: 'Trinidad and Tobago',
    domain: 'utt.edu.tt',
    name: 'University of Trinidad and Tobago',
    web_page: 'http://www.utt.edu.tt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkgRvy6rfzE5HtWxrbKInQZ5O7sca3EWeqNHa2MZpGGdHsCpTi0YiV7g',
    id: 7934,
    acronym: 'UTT',
  },
  {
    alpha_two_code: 'TT',
    country: 'Trinidad and Tobago',
    domain: 'uwi.tt',
    name: 'University of the West Indies St. Augustine',
    web_page: 'http://www.uwi.tt/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3WTGxlMtdyx97JhzLaqYswWZLRRXzbjlqMwJV72gc9VsjPk99NXHVHN4',
    id: 7935,
    acronym: 'UWISA',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'disfm.rnu.tn',
    name: 'Instituts Supérieurs de Formation des Maîtres',
    web_page: 'http://www.disfm.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN-chn8weY7DRtagIN5ZjinraQ1ie9xWIyFTtpmA1bIpjzQyyoNNWL33Q',
    id: 7936,
    acronym: 'ISDFDM',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'isetr.rnu.tn',
    name: 'Instituts Supérieurs des Etudes Technologiques',
    web_page: 'http://www.isetr.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-1wvXwKyK_vSp443g0Lc9cxc2K2WwyZ1OXQfKczJgrYTt2csLAMYs_g',
    id: 7937,
    acronym: 'ISDET',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'isg.rnu.tn',
    name: 'Institut Supérieur de Gestion de Tunis',
    web_page: 'http://www.isg.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDtySGeUYn2vL2y_Y7e5wVdRVeHQu68R98HLWsgAx1yqW3ogr5kES8utM',
    id: 7938,
    acronym: 'ISDGDT',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'u7nc.uvt.rnu.tn',
    name: 'Université de 7 Novembre à Carthage',
    web_page: 'http://u7nc.uvt.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt3hnW72iJYEGnCxgBqxuh08jACNzHN2rHGZqNskiEo21r6WO6wQfCGIE',
    id: 7939,
    acronym: 'UD7NÀC',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'ucaat.com',
    name: 'Université Centrale',
    web_page: 'http://www.ucaat.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS686A-sNnvXrF5Dvkrv9o11ZWZztm4_MODbNSCf7jOKiONaGuP5Mf7m44',
    id: 7940,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'uc.rnu.tn',
    name: 'Université du Centre Sousse',
    web_page: 'http://www.uc.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBkK6W8JQstZyyPrnR883RpH7Od3Xf5lKX70GyqFz5iWgcRnDeHONEiode',
    id: 7941,
    acronym: 'UDCS',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'ugaf.rnu.tn',
    name: 'Université de Gafsa',
    web_page: 'http://www.ugaf.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkvSddcudDUqeZePJ2Hc6i_BT6aVx9OY_sPl408LBFSKRC9HIkj9hX7r4',
    id: 7942,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'uj.rnu.tn',
    name: 'Université de Jendouba',
    web_page: 'http://www.uj.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsx-QJU9v-7WF8g_8FsCUYADcfvHjaKLuAKYza3DltiiAJyOmagxxVyA',
    id: 7943,
    acronym: 'UDJ',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'ult.ens.tn',
    name: 'Université Libre de Tunis',
    web_page: 'http://www.ult.ens.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtKQhPVOzDKmU1ccjCnCohJPGjD21ub5-JCe1FZicuWoqgCVJoL_f6Aqs',
    id: 7944,
    acronym: 'ULDT',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'uma.rnu.tn',
    name: 'Université de la Manouba',
    web_page: 'http://www.uma.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnj_W_Vy_6FAxqSa7pD0lrNQoMtJfq2Xo-aFOKN9PTgcmbcT7faUUUryY',
    id: 7945,
    acronym: 'UDLM',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'um.rnu.tn',
    name: 'Université de Monastir',
    web_page: 'http://www.um.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4PtP90kVQRbdRFcjblkd0rNNIzJC5lJ58kS3HaBCqGmyW_3BYq39KeRc',
    id: 7946,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'unat.ens.tn',
    name: 'University of Aviation and Technology',
    web_page: 'http://www.unat.ens.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4EjX2rgKARLCeOvfFn0Ps4DRmUrCHQQzziLCkdCuWUTJmxjDBSbfo2UM',
    id: 7947,
    acronym: 'UAT',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'univgb.rnu.tn',
    name: 'Université de Gabès',
    web_page: 'http://www.univgb.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpzv_2xgcjN0uqTjlwV3JbxJ_Q-6aVdP5MSWcX1dAeJSxagj6bc681TkE',
    id: 7948,
    acronym: 'UDG',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'univ-k.rnu.tn',
    name: 'Université de Kairouan',
    web_page: 'http://www.univ-k.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKhz2dOoSRsucZAhh-BApNQg8qmN3mg_usM5m5_7jN39XEphOFb6mX_Uc',
    id: 7949,
    acronym: 'UDK',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'uss.rnu.tn',
    name: 'Université de Sfax',
    web_page: 'http://www.uss.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtEX4nh7EbK4AMxl4Na8Wa6JP8hJjZyG5r9gxBEeA05NuY4mLj0eoleKE',
    id: 7950,
    acronym: 'UDS',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'utm.rnu.tn',
    name: 'Université de Tunis El Manar',
    web_page: 'http://www.utm.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTBNWvlR_0HnHOS9U-6Q1QhDUNrMu_ls_CbUY-5bqZGVHVaYRTswlaOBVU',
    id: 7951,
    acronym: 'UDTEM',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'utunis.rnu.tn',
    name: 'Université de Tunis',
    web_page: 'http://www.utunis.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtKQhPVOzDKmU1ccjCnCohJPGjD21ub5-JCe1FZicuWoqgCVJoL_f6Aqs',
    id: 7952,
    acronym: 'UDT',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'uvt.rnu.tn',
    name: 'Université Virtuelle de Tunis',
    web_page: 'http://www.uvt.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjnrQiQ5w64aENTH9ht5ieDw2906xRPW8b1fqujRhAk98GH7OJ27Rw5iY',
    id: 7953,
    acronym: 'UVDT',
  },
  {
    alpha_two_code: 'TN',
    country: 'Tunisia',
    domain: 'uz.rnu.tn',
    name: 'Université Ez-Zitouna',
    web_page: 'http://www.uz.rnu.tn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnHmgs8cUuXt-YhMC8pzgbvY9VcGn9bSujVBhdJ-Wk2pmggSbDlAr0f5k',
    id: 7954,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'adu.edu.tr',
    name: 'Adnan Menderes University',
    web_page: 'http://www.adu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOqdbaV_eNPEoF2rT3ARx83fepK521TXH3gbNbaLY8Tu7VCksy--h3Ng',
    id: 7955,
    acronym: 'AMU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ahievran.edu.tr',
    name: 'Ahi Evran University',
    web_page: 'http://www.ahievran.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ785TvCNfNTZvfPJVFphJj5fivk2zUGvo9P8SCRD0UMdiMzLckmbkZZ4M',
    id: 7956,
    acronym: 'AEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'akdeniz.edu.tr',
    name: 'Akdeniz University',
    web_page: 'http://www.akdeniz.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYhlYwPASwEuWpSGl_NKhcppSaj-PvNqEcDFOxoqLw9RVi0sP4bg9F6MsS',
    id: 7957,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'aku.edu.tr',
    name: 'Afyon Kocatepe University',
    web_page: 'http://www.aku.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP2Gstsdis2dCGQ7XOzy-EWqgpqyqriqWeHcRCOLDquvrFdP79huj4vmk6hA',
    id: 7958,
    acronym: 'AKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'anadolu.edu.tr',
    name: 'Anadolu University',
    web_page: 'http://www.anadolu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzijbztVjWpoi-Alvg37wUxG0LnEE8v3olzmMYN2b1nnU9wsJpdWtHv1c',
    id: 7959,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ankara.edu.tr',
    name: 'Ankara University',
    web_page: 'http://www.ankara.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwXCYLcaz0HSZH2v1k0Mc6XB97IyLMA8wUI2vQfZwsWINCi8VoS44PXQ',
    id: 7960,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'arel.edu.tr',
    name: 'Istanbul Arel University',
    web_page: 'http://www.arel.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-2XELfEhMtnenC-3GwVaidpSMrc2eMM_PvabOBgQFLPzX-uwqa8aqeFni',
    id: 7961,
    acronym: 'IAU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'atauni.edu.tr',
    name: 'Atatürk University',
    web_page: 'http://www.atauni.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7bSmfJ2pngUJqrC-4cQOXEgFKFF32aq7DmT_dSQYK0scg2u-pO12uyQAZZw',
    id: 7962,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'atilim.edu.tr',
    name: 'Atilim University',
    web_page: 'http://www.atilim.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyNBwZCF1OQwf1JWRUu1Mgz1y6iVT-AwQk4PcpEIHGQFLhpAcc23SuQg',
    id: 7963,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bahcesehir.edu.tr',
    name: 'Bahcesehir University',
    web_page: 'http://www.bahcesehir.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1Y9cXrirS03lT1-ipEuPztMIXSiKf2qdeFBxupb0pD8Rbggub-efvam8',
    id: 7964,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'balikesir.edu.tr',
    name: 'Balikesir University',
    web_page: 'http://www.balikesir.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlUNDMeMsIfc5wqRutIPGf17R1xmlUVu5ptVAD7GoViFahqxhvbji1yw',
    id: 7965,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'baskent.edu.tr',
    name: 'Baskent University',
    web_page: 'http://www.baskent.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV5JQtgBK0pCdeo1dBSwXkNsCCdk4fcGJAhgFz7dwWMddBsSSB4cC8SgA',
    id: 7966,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bayar.edu.tr',
    name: 'Celal Bayar University',
    web_page: 'http://www.bayar.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4hiJswwDPvp4S7w84ygv8QHAs9-_S8ElcTRYP-Zq7CSPDVLVw8EiODQ',
    id: 7967,
    acronym: 'CBU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'beykent.edu.tr',
    name: 'Beykent University',
    web_page: 'http://www.beykent.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR893MKLolqPJ0Vf9KbD53NTC3lzn0b7A_Xg6Yzn8gxAhaX0fUkBMInog',
    id: 7968,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bilecik.edu.tr',
    name: 'Bilecik University',
    web_page: 'http://www.bilecik.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcHktWmB3Ilae2P3ZbCNsyn--72dkekxQo1-xhls7s64LyHk1elpJaVPw',
    id: 7969,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bilgi.edu.tr',
    name: 'Istanbul Bilgi University',
    web_page: 'http://www.bilgi.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzl0ilNwUspxpjqMKEggHvCtlY1WhTwSm147CRJ-vFHvvs_iMudxN6fdM',
    id: 7970,
    acronym: 'IBU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bilkent.edu.tr',
    name: 'Bilkent University',
    web_page: 'http://www.bilkent.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV9Vci3NBHxOthNLfUwt372ncgAyR28YnLylW-jRWp-QISr-mmU4-W5BM',
    id: 7971,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'boun.edu.tr',
    name: 'Bogazici University',
    web_page: 'http://www.boun.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVYpZS6Tx4cGram_TT66miw0DjPtBPPK1yOFTZjLG4J5RSxSRjx_kQzvET',
    id: 7972,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'cag.edu.tr',
    name: 'Cag University',
    web_page: 'http://www.cag.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzRUmChN4rcwhCwMQwj5m3pMJfKNELVmXNRAyfw1cdA509fLCHSLVDSqqn',
    id: 7973,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'cankaya.edu.tr',
    name: 'Cankaya University',
    web_page: 'http://www.cankaya.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhjrM2XdQf6aXWHbgne-usMdZ7luVlp6NRwv-xHFZKbZqdEmlIqLYYbm-qhA',
    id: 7974,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'comu.edu.tr',
    name: 'Canakkale (18th March) University',
    web_page: 'http://www.comu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVeOg06QG4rB1jHZEnr8jzfQJMXYNz9XsAE0AWqbuSQamCsTFzzURhtfgk',
    id: 7975,
    acronym: 'C(MU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'cu.edu.tr',
    name: 'Cukurova University',
    web_page: 'http://www.cu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAwDR2rQmwdYJd7xHeo470GkDxYOP67f4OxNhoM2PyOvlJTDuVMfUEs00',
    id: 7976,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'cumhuriyet.edu.tr',
    name: 'Cumhuriyet (Republic) University',
    web_page: 'http://www.cumhuriyet.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSp-KRCiJCHH9UQC8wG4EDMXZIvrK14rs4mZpYDBM6MWk8rTLaYOH-PnQ',
    id: 7977,
    acronym: 'C(U',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'deu.edu.tr',
    name: 'Dokuz Eylul University',
    web_page: 'http://www.deu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzSJ1PPe_-wTX7J52KXuHkYystMlYKga-80QZeg-xqZh55LeN3Nf9SusNBvg',
    id: 7978,
    acronym: 'DEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'dho.edu.tr',
    name: 'Turkish Naval Academy',
    web_page: 'http://www.dho.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOJARfRRnjg8htvn7xPBBYrkcOQC-d-qkgOxaY6vbB4P36kI9d7ngcDu8',
    id: 7979,
    acronym: 'TNA',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'dicle.edu.tr',
    name: 'Dicle (Tirgris) University',
    web_page: 'http://www.dicle.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPC495VpcozmjXiz5nmXYmZZ4xhX0zHS_3X1et_pfN9aNqy3-xJ4rF1A',
    id: 7980,
    acronym: 'D(U',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'dogus.edu.tr',
    name: 'Dogus University',
    web_page: 'http://www.dogus.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaJcSfHcsey-inBTn4FIQbWv8JNo_CBSHnQZ-w207xf_xEYPh_JvQc4Q',
    id: 7981,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'dumlupinar.edu.tr',
    name: 'Dumlupinar University',
    web_page: 'http://www.dumlupinar.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcHktWmB3Ilae2P3ZbCNsyn--72dkekxQo1-xhls7s64LyHk1elpJaVPw',
    id: 7982,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ege.edu.tr',
    name: 'Ege University',
    web_page: 'http://www.ege.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPFWweyLCQs3snKWCugrj9rBBU0MdYKgeezTQ2dcqQ9wDD6wCsz1QMKdQ',
    id: 7983,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'erciyes.edu.tr',
    name: 'Erciyes University',
    web_page: 'http://www.erciyes.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRdS2OHy8YoFuPyLM6_mlj90pT5OSyyIWjihJDHPHLoYkvVw0bsrysbg',
    id: 7984,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'etu.edu.tr',
    name: 'Tobb Economics and Technology University',
    web_page: 'http://www.etu.edu.tr/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQC77Mc1uEYB4y1gyxlisrfC0gyrU44L1jUa23roEuXz-ZjXasOXWaTjH2h',
    id: 7985,
    acronym: 'TETU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'firat.edu.tr',
    name: 'Firat (Euphrates) University',
    web_page: 'http://www.firat.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvZfF-Bc3Q3om45_zVsr_cTQw4HrlFw_Z6RV16xVDD06U8Z_SX8WRpy3q6',
    id: 7986,
    acronym: 'F(U',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gantep.edu.tr',
    name: 'Gaziantep University',
    web_page: 'http://www.gantep.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnLq2TzJBOSiH65PJ9Wi0NzLcZDqUGRvd9JbVpl985PVCLFNItsE4LPa4',
    id: 7987,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gazi.edu.tr',
    name: 'Gazi University Ankara',
    web_page: 'http://www.gazi.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcHktWmB3Ilae2P3ZbCNsyn--72dkekxQo1-xhls7s64LyHk1elpJaVPw',
    id: 7988,
    acronym: 'GUA',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gop.edu.tr',
    name: 'Gaziosmanpasa University',
    web_page: 'http://www.gop.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgCxCcQ4KNM45a85jZX30141LbLf0JrZ0k6qdzBmbBA0i6gyF7WYoAQQ',
    id: 7989,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gsu.edu.tr',
    name: 'Galatasaray University',
    web_page: 'http://www.gsu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUAYqWFZwa5roM8Besi_mzhTJFmGBOPMm-PCHZ-KR6lRjzwGJJA_8VcHdh',
    id: 7990,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gyte.edu.tr',
    name: 'Gebze Institute of Technology',
    web_page: 'http://www.gyte.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPf3ZKO5J-i1imfJ0BMHqfDm-UNbEo8xC4CbfyOYdhTIv3bHwR3DjcYZEf',
    id: 7991,
    acronym: 'GIT',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'halic.edu.tr',
    name: 'Halic University',
    web_page: 'http://www.halic.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbwlx9EH9m2R1tCZ3K3wwtqGuFP2tcol0AUEwd4OaTXZq0QYqjS1EST_4',
    id: 7992,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'harran.edu.tr',
    name: 'Harran University',
    web_page: 'http://www.harran.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl-98ToYAGpFF9Vf1LW6zUlnfKOcx0WACYr3ziK1xQqVRcISl-ud8GVaw',
    id: 7993,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'hun.edu.tr',
    name: 'Hacettepe University',
    web_page: 'http://www.hun.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS_IksoQ_61jzTXHrYTPp1i-k5OWAAwtY9jW9QRlACAF0-5TmtayEjNw',
    id: 7994,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ibu.edu.tr',
    name: 'Abant Izzet Baysal University',
    web_page: 'http://www.ibu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT31iSYN62hz-Vbr4ShqKsUlMRILcRgA1wSppWw4iEiGox5h2jjSakl412omQ',
    id: 7995,
    acronym: 'AIBU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'iku.edu.tr',
    name: 'Istanbul Kultur University',
    web_page: 'http://www.iku.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP2-zodOpNTlL_MpCAM5sDhuLOra6oUVB5kUjs3yRlD7nFrZfurCEpVlE',
    id: 7996,
    acronym: 'IKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'inonu.edu.tr',
    name: 'Inönü University',
    web_page: 'http://www.inonu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBUIg2McmTIeuAXGnumh949eV8PUQBrLSHj70YEMjc9_KGZqKihxj44vc',
    id: 7997,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'isikun.edu.tr',
    name: 'Isik University',
    web_page: 'http://www.isikun.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_xOg7qBX17I1nd_ZOPcKogzii-04HqRVq0pBMRqTo1a9lWY1n8KdACg',
    id: 7998,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'iticu.edu.tr',
    name: 'Istanbul Ticaret University',
    web_page: 'http://www.iticu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQebrJ6JRxUpKzoZtW7bDxZjmHMR6sOrnlKe0FBdv9THW_JmtfVnCirGw',
    id: 7999,
    acronym: 'ITU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'itu.edu.tr',
    name: 'Istanbul Technical University',
    web_page: 'http://www.itu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDuMo_1k7TZZimKrPLvnwpAd6C6fA0pY6HOQzL6Kq6xHakhnJfl5ABDxxg',
    id: 8000,
    acronym: 'ITU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'iyte.edu.tr',
    name: 'Izmir Institute of Technology',
    web_page: 'http://www.iyte.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXfiaK07UrHOvDgwhZBvSEnkRqqnivVul3c6vf-XSmz8-RO_G5u9saNOQ',
    id: 8001,
    acronym: 'IIT',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'izmirekonomi.edu.tr',
    name: 'Izmir Economic University',
    web_page: 'http://www.izmirekonomi.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGxfNOT97DdAZy-enNC3vlbiKdlA72dTqgPxfwX9Ay4VKrh4ijbzmQ',
    id: 8002,
    acronym: 'IEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'kafkas.edu.tr',
    name: 'Kafkas University',
    web_page: 'http://www.kafkas.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHXbrniKfrp8-yxSVG7SBLWVGKTFBl4MVtjVyPJULhLGqDsxHc1bbmJA',
    id: 8003,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'karaelmas.edu.tr',
    name: 'Zonguldak Karaelmas University',
    web_page: 'http://www.karaelmas.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsnznwxFYYuRpU9nEFxmgj29m5rrVc6KYJgfdQnQQ3_2pHRDa_mGQJ_o0',
    id: 8004,
    acronym: 'ZKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'khas.edu.tr',
    name: 'Kadir Has University',
    web_page: 'http://www.khas.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxAkq5VdKW9oaxwpIxYgcTXw0ZcW-B_-klW1ruOE2Fx5jfeHjXG9g7TQ',
    id: 8005,
    acronym: 'KHU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'kilis.edu.tr',
    name: 'Kilis 7 Aralik University',
    web_page: 'http://www.kilis.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ5t01XNzF_Hm4oNYJ7rSw_zEvStgCnAdQuw4Uhx5VW5KiEMmX3CUsseM',
    id: 8006,
    acronym: 'K7AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'kku.edu.tr',
    name: 'Kirikkale University',
    web_page: 'http://www.kku.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZT4-dXRYJfcprtnX4IOi6I3hpcnDdMt9nWrn43tldiajHizZ747XCzANC8w',
    id: 8007,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'konya.edu.tr',
    name: 'Necmettin Erbakan University',
    web_page: 'http://www.konya.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCZhA9G_QtkQLht9qLzMPfyrcHLt_O53HvY47sAXey4aVPDU6VJdPBxg',
    id: 8008,
    acronym: 'NEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'kou.edu.tr',
    name: 'Kocaeli University',
    web_page: 'http://www.kou.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRjvcxTZqt4keWtJsgwyhnm0JjOHKIdiuKccpbvC5Pk4iIdvt9MnXEFHM',
    id: 8009,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ksu.edu.tr',
    name: 'Kahramanmaras Sutcu Imam University',
    web_page: 'http://www.ksu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwouTte4gH3IU5dSeBF7Rnvg1ajjPuqiigGmLCaK8cFx7qpOgh5nEprw',
    id: 8010,
    acronym: 'KSIU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ktu.edu.tr',
    name: 'Karadeniz Technical University',
    web_page: 'http://www.ktu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ02wdHu7wVnE3f1ygPyTkcc-VN-vqdaPEEH_VsWuvvxReAh6LDb0c127Y',
    id: 8011,
    acronym: 'KTU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ku.edu.tr',
    name: 'Koc University',
    web_page: 'http://www.ku.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQG87pZ0ohKASxMutmN-iNnYEYOKCanBQbEVK54n1qHWWXT50zDhEkBJQ',
    id: 8012,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'maltepe.edu.tr',
    name: 'Maltepe University',
    web_page: 'http://www.maltepe.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-RXBhsy79O2faZjlvllKkjd_hseM0w47E9msqo22BnpVzbBbPA_BDzg',
    id: 8013,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'marmara.edu.tr',
    name: 'Marmara University',
    web_page: 'http://www.marmara.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ9rKmDW1kf1zk-Vuf2SUkmONe0tdLmjQV5_hv8fh3bDDCMS1wN-4R1fA',
    id: 8014,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'mersin.edu.tr',
    name: 'Mersin University',
    web_page: 'http://www.mersin.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRe67lksAmocg4z-0yP9A2LYts5bo57tcGtIVL2_oPfF8LHMfJpSVouVe8',
    id: 8015,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'mku.edu.tr',
    name: 'Mustafa Kemal University',
    web_page: 'http://www.mku.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQYudtlzWqK_G5w6c81uKDG07oSI6eWT74QN0kkNGJMTvt64DyQ-oYwg',
    id: 8016,
    acronym: 'MKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'msu.edu.tr',
    name: 'Mimar Sinan University',
    web_page: 'http://www.msu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKhE57IPyAE1fD7WVIQstxDBZwgnmBFiUIXtiZp2izvh2YVnxcBHJkcX4',
    id: 8017,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'mu.edu.tr',
    name: 'Mugla Sitki Kocman University',
    web_page: 'http://www.mu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQKqDclFmoM5J7Haj5RaPY41WwSxlh_b8G4mNdlKVK_MCbRhpxkA2g1buA',
    id: 8018,
    acronym: 'MSKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ohu.edu.tr',
    name: 'Omer Halisdemir University',
    web_page: 'http://www.ohu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxBd-oa3cRaeTjs5UTHEtymqnJol7nlsGnhYvkzN81g0PFLYd2fpcAObw',
    id: 8019,
    acronym: 'OHU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'nku.edu.tr',
    name: 'Namik Kemal University',
    web_page: 'http://www.nku.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt6UPv6DzDxDpoNQdzAAShU65ZuQY7nrIpaZhyXyq9jTYqd2qjGLz6Dg',
    id: 8020,
    acronym: 'NKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'metu.edu.tr',
    name: 'Middle East Technical University',
    web_page: 'http://www.metu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJDaQsYoMYK3qMzs9q88xs9aMZJwXjZmYYFPchXhzx_tQ5FKkDIGGlSdY',
    id: 8021,
    acronym: 'METU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ogu.edu.tr',
    name: 'Osmangazi University',
    web_page: 'http://www.ogu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKu2RC-yeCu-J_d06tMrgNxVxDGZqy8MD_pKSokWtoARPynvuQ_33eWrc4',
    id: 8022,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'omu.edu.tr',
    name: 'Ondokuz Mayis University Samsun',
    web_page: 'http://www.omu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQf8rSq7kdGIqowv0FKQCsFmNa34F_YZCPWWtgt-iql-QFGtNZBU5P7WsQt',
    id: 8023,
    acronym: 'OMUS',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'odu.edu.tr',
    name: 'Ordu University',
    web_page: 'http://www.odu.edu.tr',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuQYeiDvuO477zu0EE1np6Yj1OZjhrTRcFoDWKoOQJC2739GnCV2siIQ',
    id: 8024,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ozyegin.edu.tr',
    name: 'Ozyegin University',
    web_page: 'http://www.ozyegin.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSecxyNCJ6nJKuOwIS7O4eugTtNvFpfyahy4Hnuo8V0d6ogsj8yViTt444',
    id: 8025,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'pamukkale.edu.tr',
    name: 'Pamukkale University',
    web_page: 'http://www.pamukkale.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD_ZpKurMgISZC8513l46B-aJ1w4NLK2D2sUWMUO5NIIuMjhgDR3i-7vU',
    id: 8026,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'sabanciuniv.edu.tr',
    name: 'Sabanci University',
    web_page: 'http://www.sabanciuniv.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0yntpfYo01UygJWvrYQYFc5YsuSBjcAPipPE7B2elUkKK7o6wvuelJA',
    id: 8027,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'sau.edu.tr',
    name: 'Sakarya University',
    web_page: 'http://www.sau.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQb_TpFhegFecVT7_AcXXT_3glBWACLLqANXVqTvTV8dBgpBPhZZq_jx3o',
    id: 8028,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'sehir.edu.tr',
    name: 'İstanbul Şehir University',
    web_page: 'http://www.sehir.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7pOqaTSE6-lXQXe1_YG6kuwx0vUHHDXOENddhPSpkBmrqrcA1UQxBxLs',
    id: 8030,
    acronym: 'IŞU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'selcuk.edu.tr',
    name: 'Selcuk University',
    web_page: 'http://www.selcuk.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqNbPd0ZJ-q9GmQ938c2P6t_vABDgjYgRQctqKPHMEkw20b7D0bxCEt_8',
    id: 8031,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'tau.edu.tr',
    name: 'Türkisch-Deutsche Universität',
    web_page: 'http://www.tau.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScSYUnj8YXSbb5vGN2-j-gDWKoGecfk_DuoeTRJCfn2WUViiVEpOFlzTk',
    id: 8032,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'trakya.edu.tr',
    name: 'Trakya University',
    web_page: 'http://www.trakya.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyb1MBqVD81XvNNlzlzkJF9zyZvG7Dg3OsejRIsoRZOZM1XjComlnQhGk',
    id: 8033,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ufuk.edu.tr',
    name: 'Ufuk University',
    web_page: 'http://www.ufuk.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFVA49DOtXN3SxAjPnMc7XBErqPuogT-B9ZJyaTvf37bB7pU7hMQJjug',
    id: 8034,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'uludag.edu.tr',
    name: 'Uludag University',
    web_page: 'http://www.uludag.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiuxf86ghbPRLVdGxj9F2GGFpaql27Hy5Bu-Ul329bmPDhrWYf6_mBCF4',
    id: 8035,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'yalova.edu.tr',
    name: 'Yalova University',
    web_page: 'http://www.yalova.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCG0x_ZQvjXI9k9g1OrAJIDYF4ecep5uc-Gq1bUoiKkiHxMIGk7aKi2iMW',
    id: 8036,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'yasar.edu.tr',
    name: 'Yasar University',
    web_page: 'http://www.yasar.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTymTa7Nen9lpbtqGM-94E_vEJi4zFysyg5qGIcQ36CqrlbwsjlQ20-qhV4',
    id: 8037,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ybu.edu.tr',
    name: 'Yildirim Beyazit University',
    web_page: 'http://www.ybu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZiu9DF7Bh_GOWZA4osTMrqLfvy7GGAbx0ICHhkPLHYtlwToWxqFc0TYU',
    id: 8038,
    acronym: 'YBU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'yeditepe.edu.tr',
    name: 'Yeditepe University',
    web_page: 'http://www.yeditepe.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTAjdhS4ONmPRy_-hSVJK1oe0u5lY79rxj9YExgBHLdGFWJIWCcNsJOj4',
    id: 8039,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'yildiz.edu.tr',
    name: 'Yildiz Technical University',
    web_page: 'http://www.yildiz.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-OcLURPCjjGIialfKhaRYyXhQywlcGvApu6TlnFLIPqT98-etX-TgIdg',
    id: 8040,
    acronym: 'YTU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'yyu.edu.tr',
    name: 'Yüzüncü Yil (Centennial) University',
    web_page: 'http://www.yyu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn2ZPDGORTm3s_PTZJ_5p7PW5Fu9Szx-_gQQDe-LGH6xtzQJXvZZXrHw',
    id: 8041,
    acronym: 'YY(U',
  },
  {
    alpha_two_code: 'TM',
    country: 'Turkmenistan',
    domain: 'ittu.edu.tm',
    name: 'International Turkmen Turkish University',
    web_page: 'http://www.ittu.edu.tm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsEeyIzeLm53CrrlvbJKO1JMHvgHcM_7pxgcmpGpxZ9bMS3AosyReo7g',
    id: 8042,
    acronym: 'ITTU',
  },
  {
    alpha_two_code: 'TC',
    country: 'Turks and Caicos Islands',
    domain: 'charismauniversity.org',
    name: 'Charisma University',
    web_page: 'http://www.charismauniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRZWCPp9dUlDsICwhYTYVtyPwHx2MOiLsKW98PwQobEGXIM33UN19Z3N5J',
    id: 8043,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'bugemauniv.ac.ug',
    name: 'Bugema University',
    web_page: 'http://www.bugemauniv.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaluhuqvp5UXV5Iu5oIsC92xjP10nYwEPvJHBGQmjNRFCtkkbGjDRZOVQ',
    id: 8045,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'busitema.ac.ug',
    name: 'Busitema University',
    web_page: 'http://www.busitema.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlGi5unGt4FAfm0XIG7aIscpMV5UpvAPx6H3DOti_Hv18cjOfONkhTOA',
    id: 8046,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'busogauniversity.ac.ug',
    name: 'Busoga University',
    web_page: 'http://www.busogauniversity.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_XgyaJ9OF-yuHNWz1KVjrDUER4UTxn1pwbWlZzRTOg8WT3XYw0ClTUg',
    id: 8047,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'cbu2000.com',
    name: 'Central Buganda University',
    web_page: 'http://www.cbu2000.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQa9saIKbBMvzKODzWYwe_5FNSFwJLWF4Nk5G7nB-ulqbMxIe5pW1iUBI',
    id: 8048,
    acronym: 'CBU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'gu.ac.ug',
    name: 'Gulu University',
    web_page: 'http://www.gu.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMr5IgJ-M2woeSltOACxEo7iVeRdL8wgi4Yf-alPCLIIrunlBSrBPzKJSqVg',
    id: 8049,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'iuiu.ac.ug',
    name: 'Islamic University in Uganda',
    web_page: 'http://www.iuiu.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR75GfNHCFtDacCm52RdvuVYNpUTzC-kbxFEk_TZlSjWvQ8f4Sz22hOnkQ',
    id: 8050,
    acronym: 'IUIU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'kiu.ac.ug',
    name: 'Kampala International University',
    web_page: 'http://www.kiu.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoE3RjBD_OmiBu3xF-vM45K1NPFSEipD_XoqiFs4cjR5zBYLZh1zgWwAI',
    id: 8051,
    acronym: 'KIU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'ku.ac.ug',
    name: 'Kampala University',
    web_page: 'http://www.ku.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoE3RjBD_OmiBu3xF-vM45K1NPFSEipD_XoqiFs4cjR5zBYLZh1zgWwAI',
    id: 8052,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'kyu.ac.ug',
    name: 'Kyambogo University',
    web_page: 'http://www.kyu.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDx0fd-K2kNWgwK1dxjsK7u7WhR5nRTbhVwcHxVBXBxiJkvy0Ko8zvuxk',
    id: 8053,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'mak.ac.ug',
    name: 'Makerere University',
    web_page: 'http://www.mak.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwJmkiuzFnDiAP9aT2Sst0q_lbmR0gXaD9PfYodHnHvTkrtgfWNY9Jb2CF',
    id: 8054,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'mmu.ac.ug',
    name: 'Mountains of the Moon University',
    web_page: 'http://www.mmu.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgTYnVBkEJjtX82Y60al-3Ckf-A2ti2wylTgG-HMNG2bnLJO347Jv_kGQ',
    id: 8055,
    acronym: 'MMU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'mru.ac.ug',
    name: 'Mutesa 1 Royal University',
    web_page: 'http://www.mru.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3BBI822okanIdKuID1nK80pOFvjLrqV6-imnnu2clSxOE7Gacpu7JsQ',
    id: 8056,
    acronym: 'M1RU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'must.ac.ug',
    name: 'Mbarara University of Science and Technology',
    web_page: 'http://www.must.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkTZL8uWc3E61jffRjwSmdzFEb4zoASHLMDb6cRh5dW8kCh32X8QMK-so',
    id: 8057,
    acronym: 'MUST',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'ndejjeuniversity.ac.ug',
    name: 'Ndejje University',
    web_page: 'http://www.ndejjeuniversity.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSiOo-BR13glqm6AFQATPu9Lg1q3uq44XW_aAIyqD_M-Ob67yhS14kS-c',
    id: 8058,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'nkumbauniversity.ac.ug',
    name: 'Nkumba University',
    web_page: 'http://www.nkumbauniversity.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfeTpcAQaFlWM2YfacQwVlFHnsMk6u36vBDxSCHXAMdXJ46sEzc1p9LiL5',
    id: 8059,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'saiu.ac.ug',
    name: 'St. Augustine International University',
    web_page: 'http://www.saiu.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFldc5eQaWFYNngKy3JgQmxkFFwms6-Ur52Ma7HZ2iqiyt8YYAGGDu_A',
    id: 8060,
    acronym: 'SAIU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'ucu.ac.ug',
    name: 'Uganda Christian University',
    web_page: 'http://www.ucu.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZaM9IPQIzGQ8iHxXWoY3zcEpnAHDgpcm-wCmdjEyvqzHy9HnOZrzgyDA',
    id: 8061,
    acronym: 'UCU',
  },
  {
    alpha_two_code: 'UG',
    country: 'Uganda',
    domain: 'umu.ac.ug',
    name: "Uganda Martyr's University",
    web_page: 'http://www.umu.ac.ug/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6LEnC_Mc1WTHLCR-Te69OIa0onF8SgfEKWMN1sTK9J9nzGnCJrQK0pUs',
    id: 8062,
    acronym: 'UMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'academy.sumy.ua',
    name: 'Ukrainian Academy of Banking',
    web_page: 'http://www.academy.sumy.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeZQVknN8EkVNxE46jDkgV2KmHpsVM8dVh5UPCw2on1ksGdjduWRPc6B4M',
    id: 8063,
    acronym: 'UAB',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'arosmu.org',
    name: 'Odessa State Medical University',
    web_page: 'http://www.arosmu.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8zpUNolCFY-RaFwD666Zktn7772Jt_JZCrxjyoQS4r52ySJj8UVQFyis',
    id: 8064,
    acronym: 'OSMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'btsau.kiev.ua',
    name: 'Bila Cerkva State Agrarian University',
    web_page: 'http://www.btsau.kiev.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXs6qunOcBztx9SvOcWJR9kntQzLhIF0bUqfclTYzpqBhn3BqlFpyNVqc',
    id: 8065,
    acronym: 'BCSAU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'chnu.cv.ua',
    name: 'Chernivci National University',
    web_page: 'http://www.chnu.cv.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzVfjBeIVYkPcDzZ1tpfT57UNFUPoyqLkZQ8bx5Lklv65NKjQ7-YJY3w',
    id: 8066,
    acronym: 'CNU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'crsmu.com',
    name: 'Crimea State Medical University',
    web_page: 'http://www.crsmu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-x4YZsyyaMiqD8YDpxXWb868KGHJqmL81vDbzKgld8uoNHQ1cqeemkXs',
    id: 8067,
    acronym: 'CSMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'diit.edu.ua',
    name: 'Dnepropetrovsk National University of Railway Transport',
    web_page: 'http://www.diit.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnKZ_zIPyIq2_JB1Ru9OH4-LkFs02K-PDZks83onmJ89xTB_yGdmMnViF4',
    id: 8068,
    acronym: 'DNURT',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'dnu.dp.ua',
    name: 'Dnepropetrovsk National University',
    web_page: 'http://www.dnu.dp.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvannDw0mKzK6UhifuMLn802CpdidAIgSZYnzBjWaZ4uJV2DPac0-1cA',
    id: 8069,
    acronym: 'DNU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'donmeduni.com',
    name: 'Donetsk State Medical University',
    web_page: 'http://www.donmeduni.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLXRYiF28HAPaC98Zw-2BZ3EgJlgql0Kr-pzfZ7QYuQJ8b2aLQkzzp6w',
    id: 8070,
    acronym: 'DSMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'donntu.edu.ua',
    name: 'Donetsk National Technical University',
    web_page: 'http://www.donntu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh1zW65sSWsi7BEoyrEHp5dbKAB5dTZQFTGJgSmgj1Pdj4XHvB4EObwAMV',
    id: 8071,
    acronym: 'DNTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'donnu.edu.ua',
    name: 'Donetsk National University',
    web_page: 'http://www.donnu.edu.ua/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTQM8uvy_pKYmv9NkjmhOrLVNczSa65xve7J5yrjyqnBzVwMa7ZHnlhJA',
    id: 8072,
    acronym: 'DNU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'dstu.dp.ua',
    name: 'Dneprodzerzhinsk State Technical University',
    web_page: 'http://www.dstu.dp.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEeq3w_vbxhPEdZyzWid4SGYa7vY-kiKUwo4lKFxvGtrJmToi4P1pV6w',
    id: 8073,
    acronym: 'DSTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'duep.edu',
    name: 'Alfred Nobel University of Economics and Law',
    web_page: 'http://www.duep.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4WtXc1QChkbJ8azTifvg3JSqPdTVyEr3F-pC3-P5oeu1ec5ISeSmlTw',
    id: 8074,
    acronym: 'ANUEL',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'forest.lviv.ua',
    name: 'Ukrainian National Forestry University',
    web_page: 'http://www.forest.lviv.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkes5NvBibodCm7bV9ryUcNT-519S7d2S5PNq0PvOd5rXzgcbgTpJ5b5Y',
    id: 8075,
    acronym: 'UNFU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'franko.lviv.ua',
    name: 'Lviv National University Ivan Franko',
    web_page: 'http://www.franko.lviv.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnqhxt40yTAwamKl7XPq4f0cySQzBNY1zSzvMrvNXtxHxVt0ueIliNUyhY',
    id: 8076,
    acronym: 'LNUIF',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'hneu.edu.ua',
    name: 'Kharkiv National University of Economics',
    web_page: 'http://www.hneu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHgkJmb6hkd5Eq9xWesVfd4o7QZ2haebRS-KD3QnwCgX92g_BZiwcmQ4g',
    id: 8077,
    acronym: 'KNUE',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'ifdtung.if.ua',
    name: 'Ivano-Frankivsk National University of Oli and Gas',
    web_page: 'http://www.ifdtung.if.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEzm-5YP7wCCUhjdCXGI89EDdG9HHu8w57i4UXT59uFEM0KU_b21O8OZI',
    id: 8078,
    acronym: 'INUOG',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'kdpu.edu.ua',
    name: 'State Pedagogical University in Kryvyi Rih',
    web_page: 'http://www.kdpu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSeEOi2mvHXrlQ_-ILs3SflrfsKB3e0Oi7Y-L5objCLmgWORFBmmFcF-E',
    id: 8079,
    acronym: 'SPUIKR',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'khadi.kharkov.ua',
    name: 'Kharkiv State Transport Technical University',
    web_page: 'http://www.khadi.kharkov.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRifVke6xcBPTJP5Q3hCDwK3jDc2LlfLQbhfLBDgxylou2IVBUHP8Jj0Hw',
    id: 8080,
    acronym: 'KSTTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'khai.edu',
    name: 'National Aerospace University Kharkov Aviation Institute',
    web_page: 'http://www.khai.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShP2jF9L_hzl98kcsLlOmJTgkOXpwu3IzQNt_m3BwYMebzp3x-LTeDIYZE',
    id: 8081,
    acronym: 'NAUKAI',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'kma.mk.ua',
    name: 'Petro Mohyla Black Sea State University',
    web_page: 'http://www.kma.mk.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAutbbeqvJ6Ur6-WlXu2eJW0UzemF7RwShFZtAMtN96qY9FU5JFS9Qg68',
    id: 8082,
    acronym: 'PMBSSU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'kneu.kiev.ua',
    name: 'Kiev National Economic University',
    web_page: 'http://www.kneu.kiev.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdUc95bhRbBCZxqtQ6MtRdztv0qNs4G4styAYYYVZRs1SufSsy3DxIDQ',
    id: 8083,
    acronym: 'KNEU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'knuba.edu.ua',
    name: 'Kiev National University of Construction and Architecture',
    web_page: 'http://www.knuba.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpRIrwc4kG_5xn4s9WdEKGp3vcJFcYA4vIrlQ0H4WrdLkSFzDQFZr5H64',
    id: 8084,
    acronym: 'KNUCA',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'kpi.kharkov.ua',
    name: 'National Technical University (Kharkiv Polytechnical Institute)',
    web_page: 'http://www.kpi.kharkov.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3XHSjfSCivYienlwwB7bO6QYAhqFxAc7MWfuhNFZvhQ8-TBIUaM_2lA',
    id: 8085,
    acronym: 'NTU(PI',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'kpi.ua',
    name: 'National Technical University (Kiev Politechnical Institute)',
    web_page: 'http://www.kpi.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRGJioswkZ_x-pO2Uzg57hdar42Saoo20u4SwgB-bwsbrspRHmuPrHBLU',
    id: 8086,
    acronym: 'NTU(PI',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'krok.edu.ua',
    name: 'KROK Economics and Law University',
    web_page: 'http://www.krok.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMHFnq-ZIiHvzxQVjFL2hDkF4UVwYgTVanMsAGhAKwOtLib7dBKx1Uw8M_Og',
    id: 8087,
    acronym: 'KELU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'kse.org.ua',
    name: 'Kiev School Of Economics',
    web_page: 'http://www.kse.org.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG3tZmayaeVb4914N796NPUoFuZFaj8uqynTy2pUH-jIwCOxoJP-NXbhg',
    id: 8088,
    acronym: 'KSE',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'ksmu.kharkov.ua',
    name: 'Kharkiv State Medical University',
    web_page: 'http://www.ksmu.kharkov.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMt_SrR5ePJu668-3Mp0zIDephJW8ty49kHGEGhttdXRpjEtmcnWaRPVs',
    id: 8089,
    acronym: 'KSMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'kstuca.kharkov.ua',
    name: 'Kharkiv State Technical University of Construction and Architecture',
    web_page: 'http://www.kstuca.kharkov.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRifVke6xcBPTJP5Q3hCDwK3jDc2LlfLQbhfLBDgxylou2IVBUHP8Jj0Hw',
    id: 8090,
    acronym: 'KSTUCA',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'ksu.edu.ua',
    name: 'Kiev Slavonic University',
    web_page: 'http://www.ksu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM-7R6XPOcfNeqX2PIVPGB5Zh7whQ4m-gueBp1w7MszUpvZB6_GFl0LWM',
    id: 8091,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'kture.kharkov.ua',
    name: 'Kharkiv National University of Radio Electronics',
    web_page: 'http://www.kture.kharkov.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxYTKkGzDpQxNhDURt7RtrVu919Qs_AuoWRMyG5szFBGZnk7tdF8Vz5w',
    id: 8092,
    acronym: 'KNURE',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'lgpu.lg.ua',
    name: 'Luhansk Taras Shevchenko National Pedagogical University',
    web_page: 'http://www.lgpu.lg.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKzNRd3exo95xufvDNbGOn1IlOa_KNIqFGhDSYx3f7zkActuY6HZM7EQ',
    id: 8093,
    acronym: 'LTSNPU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'lp.edu.ua',
    name: 'Lviv Polytechnic National University',
    web_page: 'http://www.lp.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_veSvgMUeKMRv5hTD0yWX9_Ca82KKZh31UWGeyo5vbW2n9mIo7YFVKgSk',
    id: 8094,
    acronym: 'LPNU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'lsmu.com',
    name: 'Luhansk State Medical University',
    web_page: 'http://www.lsmu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScIp_ogPpxSccMoa0j2pzJHCr_23PsCacb4lFsN5F6yy-xkF2yetjo7A',
    id: 8095,
    acronym: 'LSMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'ma.odessa.ua',
    name: 'Odessa National Maritime Academy',
    web_page: 'http://www.ma.odessa.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr4FGIp0VqXkvGWsJEOgzfYZ1paXUMP_f5krOEKxVCEVXZpbYOECvkOhA6',
    id: 8096,
    acronym: 'ONMA',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'meduniv.lviv.ua',
    name: 'Lviv National Medical Univeristy',
    web_page: 'http://www.meduniv.lviv.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5pOtHlbs3bO4fXvbWYywf6Xo2Vm1KnI9wPFCvYeT9cy_CumsbdSLJiw',
    id: 8097,
    acronym: 'LNMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'nau.edu.ua',
    name: 'National Aviation Univesity',
    web_page: 'http://www.nau.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB4V4zz4p5J4oW9idGBGi5eggfMPBlyVLvd073gfc-15Lbb7Jk5dMK6hs',
    id: 8098,
    acronym: 'NAU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'nauu.kiev.ua',
    name: 'National Agricultural University',
    web_page: 'http://www.nauu.kiev.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfOfypF20C3UGRHiHSjxT3_ZzMfOK4oq3rBNUYTaGzit6HHwyPQdvipA',
    id: 8099,
    acronym: 'NAU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'nmetau.edu.ua',
    name: 'National Metallurgical Academy of Ukraine',
    web_page: 'http://www.nmetau.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThag2r_7GdisdF_YVmVSDgsym41uu9yzdp2fg2lL12BpoZgVz2AOeT',
    id: 8100,
    acronym: 'NMAU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'nmu.edu.ua',
    name: 'National Medical University O.O. Bogomolets',
    web_page: 'http://www.nmu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSms-HqnWEUWhPpCqqDEol8Hbb04f37p_ImwTKxhZ3W1r5u9YqWcd7eY2w',
    id: 8101,
    acronym: 'NMUOB',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'nmu.org.ua',
    name: 'National Mining University',
    web_page: 'http://www.nmu.org.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzyHxgkBzh_zLg-vCuQs3fDGEMI99QRs_g7_UIg-x8isrMrj2e9FvvFA',
    id: 8102,
    acronym: 'NMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'npu.edu.ua',
    name: 'National Pedagogical University M. Dragomanov',
    web_page: 'http://www.npu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKzNRd3exo95xufvDNbGOn1IlOa_KNIqFGhDSYx3f7zkActuY6HZM7EQ',
    id: 8103,
    acronym: 'NPUMD',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'ntu.kar.net',
    name: 'National Transport University',
    web_page: 'http://www.ntu.kar.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRUdBpZ-g86onBCx-z-xizFs60lKoz4x-VEpunV7tCKw2Gbp0Ua98m-Q0',
    id: 8104,
    acronym: 'NTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'ogasa.odessa.ua',
    name: 'Odessa State Academy of Construction and Architecture',
    web_page: 'http://www.ogasa.odessa.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSumj_ZQQlINGUksn6l5PceBFSqMphUPTxv8jQketvXqMHSu5px1LGU-A',
    id: 8105,
    acronym: 'OSACA',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'onu.edu.ua',
    name: 'Odessa National I.I. Mecnikov University',
    web_page: 'http://www.onu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAdRfjVuP9DVYNEbaT0p6YQ2DCbsmLO2nz54D6OUH1km9m8NuPxiQ1Ylg',
    id: 8106,
    acronym: 'ONIMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'opu.odessa.ua',
    name: 'Odessa National Polytechnic University',
    web_page: 'http://www.opu.odessa.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUiYgqlfMRwf-oKRCUY1xiKX82HTktLWmJoMG4Gmdje1ZOp602gQBx2w',
    id: 8107,
    acronym: 'ONPU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'osaft.odessa.ua',
    name: 'Odessa National Academy of Food Technologies',
    web_page: 'http://www.osaft.odessa.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHx1BLOXYh09GJlnz8Ry07IMulnDxwHoH5A-G6Ypsir8kEz1WmWB5nO5g',
    id: 8108,
    acronym: 'ONAFT',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'osmu.odessa.ua',
    name: 'Odessa National Marine University',
    web_page: 'http://www.osmu.odessa.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr4FGIp0VqXkvGWsJEOgzfYZ1paXUMP_f5krOEKxVCEVXZpbYOECvkOhA6',
    id: 8109,
    acronym: 'ONMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'pntu.edu.ua',
    name: 'Poltava National Technical University',
    web_page: 'http://www.pntu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScH_ySV8RwGdVfKewk_8ICoQTAIIomi5IdicO2Pu8WbxGrh4TXhmN-dcVPjA',
    id: 8110,
    acronym: 'PNTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'pu.if.ua',
    name: 'Precarpathian University',
    web_page: 'http://www.pu.if.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUrHAMQDaOB-fq8DhOmG02EjXzQRcRrG5p2wSxV2jHJLgirr_e9Gdcqg',
    id: 8111,
    acronym: 'PU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'pusku.edu.ua',
    name: 'Poltava University of Consumer Cooperatives in Ukraine',
    web_page: 'http://www.pusku.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUONw3iD2hjreD9XOFnvQAl2tj9bf30Q13jPI0hz0xYd8gPPBt3680tbAS',
    id: 8112,
    acronym: 'PUCCIU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'sau.sumy.ua',
    name: 'Sumy National Agrarian University',
    web_page: 'http://www.sau.sumy.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHeft9ZYQJGbyQIbFeJiCreu9c2tFyK4aJNS_I1P3g_gPp3dtl6iCK63A',
    id: 8113,
    acronym: 'SNAU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'sevntu.com.ua',
    name: 'Sevastopol National Technical University',
    web_page: 'http://sevntu.com.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWC-lI7gLfFKgcrj6JO_CVhghX9w_kv_42Z2BRQgvDTdsRpfOC7edfOA',
    id: 8114,
    acronym: 'SNTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'snu.edu.ua',
    name: 'East Ukrainian National University',
    web_page: 'http://www.snu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXOnSvLFbaw1l8V8RtEVanAaQNmGE3GxHsuaTwasF6cAqwiTuaLgx8xw',
    id: 8115,
    acronym: 'EUNU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'sumdu.edu.ua',
    name: 'Sumy State University',
    web_page: 'http://www.sumdu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnuFxAZPUY2rKVLtXYEcBoN7lN9rWL8c65umI6d1PbScI6v-lnKqdbKQ',
    id: 8116,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'tane.edu.ua',
    name: 'Ternopil Academy of National Economy',
    web_page: 'http://www.tane.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdUc95bhRbBCZxqtQ6MtRdztv0qNs4G4styAYYYVZRs1SufSsy3DxIDQ',
    id: 8117,
    acronym: 'TANE',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'tnu.crimea.ua',
    name: 'Taurida National V.I.Vernadsky University',
    web_page: 'http://www.tnu.crimea.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxCjYr7qwNVoTcYnvXN0LQjCp4XcqaDzGB-PXn19npnQc6GuVsxGNTmDw',
    id: 8118,
    acronym: 'TNVU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'tu.edu.te.ua',
    name: "Ternopil State Ivan Pul'uj Technical University",
    web_page: 'http://www.tu.edu.te.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEI6jhdVYZJCftTJb9xgy_oIbvJ_5ber3qS0PYyKyZRCu6uc54xhOGY9Zn',
    id: 8119,
    acronym: 'TSIPTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'tup.km.ua',
    name: 'Khmelnitsky National University',
    web_page: 'http://www.tup.km.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi_sZnLIKstLi6ZyrsJaVhGVh3EOYHADk7nXD6GsEUnnkzXYb35xrEJbU',
    id: 8120,
    acronym: 'KNU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'tup.km.ua',
    name: 'Technological University of Podillya',
    web_page: 'http://www.tup.km.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGtgVem2hyxG3SamLhp00dOVSlaEK4R3Vgbm45kJSeUtOiZeX-w_RaDxw',
    id: 8121,
    acronym: 'TUP',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'uamsa.net',
    name: 'Ukrainian Medical Stomatological Academy',
    web_page: 'http://www.uamsa.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGb_6WBcwARVGyTVr227KbVVVsrkYZSPgnv3tVOLx2YDUriKIjspP1wWw',
    id: 8122,
    acronym: 'UMSA',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'ukma.kiev.ua',
    name: 'National University of Kiev-Mohyla Academy',
    web_page: 'http://www.ukma.kiev.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR358kaAAvvd3_rW_pipD18AIoFneV7gKnn_otumGBEXX0SgKrOXAK9WGU',
    id: 8123,
    acronym: 'NUKA',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'ukrfa.kharkov.ua',
    name: 'Ukrainian Academy of Pharmacy',
    web_page: 'http://www.ukrfa.kharkov.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDaGBNp9uwJ221C9DEZTHtCuYuGDdYiBZQjSUFyqbFtpam7IvSv7UFcw',
    id: 8124,
    acronym: 'UAP',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'univd.edu.ua',
    name: 'National University of Internal Affairs',
    web_page: 'http://www.univd.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvannDw0mKzK6UhifuMLn802CpdidAIgSZYnzBjWaZ4uJV2DPac0-1cA',
    id: 8125,
    acronym: 'NUIA',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'univer.kharkov.ua',
    name: 'Kharkiv National University',
    web_page: 'http://www.univer.kharkov.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaX2JYoH8oCjQaHJxrVNTBk019TPGtT6037a2QbjlSOe0-PuUpDTuCJg',
    id: 8126,
    acronym: 'KNU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'university.kherson.ua',
    name: 'Kherson State University',
    web_page: 'http://www.university.kherson.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf2eCKg7SI3GkoHMYWrk7cNcxo-b9Q5AAoBwYcP9h5hAjeImvPlUHUrI4',
    id: 8127,
    acronym: 'KSU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'univ.kiev.ua',
    name: 'National Taras Shevchenko University of Kiev',
    web_page: 'http://www.univ.kiev.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdPpPbYw9S15iel1WCqQyYq-IAahSpygbhNnUWMCWQJd550o82aBBkpps',
    id: 8128,
    acronym: 'NTSUK',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'univ.uzhgorod.ua',
    name: 'Uzhgorod National University',
    web_page: 'http://www.univ.uzhgorod.ua/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSId38EjkyfYr_uTO-7J971ABwtKrj2lzFtTjJSvMwVHfWckaIgS8AYpQ',
    id: 8129,
    acronym: 'UNU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'uosa.uar.net',
    name: 'National University of Ostroh Academy',
    web_page: 'http://www.uosa.uar.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaVFJnKVDHBDMdHsFeDfmB59oj5_EeL1G2gl12p6bJWX-RprE_u3MA5CM',
    id: 8130,
    acronym: 'NUOA',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'usmtu.edu.ua',
    name: 'National University of Shipbuilding',
    web_page: 'http://www.usmtu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpzlYLCX0CtUqfRa9v7dwfFnJqwMEg8HE60lNGqtrOJGCxMxUTZo7vHg',
    id: 8131,
    acronym: 'NUS',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'usuft.kiev.ua',
    name: 'National University of Food Technologies',
    web_page: 'http://www.usuft.kiev.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFyvl78GONjuiVa_CZ3xl5i4DXF9xgrwZfVemelUjfoyid0uWI1EyPSWE',
    id: 8132,
    acronym: 'NUFT',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'vdu.edu.ua',
    name: 'Volyn National University Lesja Ukrainka',
    web_page: 'http://www.vdu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3q2Z5DtA1m82ZwDQaeL80tjBWiI0lsjgztPDk-IQ7TG8enEqjNs8JyTOz',
    id: 8133,
    acronym: 'VNULU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'vnmu.vn.ua',
    name: 'National Pirogov Memorial Medical University',
    web_page: 'http://www.vnmu.vn.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT4jIPAInf7d39vEVyqXYqiyRZ7KxjdBy_nNTXCahKfF-pLPAGwvnBMpk',
    id: 8134,
    acronym: 'NPMMU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'vstu.vinnica.ua',
    name: 'Vinnica National Technical University',
    web_page: 'http://www.vstu.vinnica.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMmqTdpVQGjZlMabiKmAF6XqLv2r7V3gfed2SAlCNNqtRanetkaP9ZoJ8',
    id: 8135,
    acronym: 'VNTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'zntu.edu.ua',
    name: 'Zaporizhzhe National Technical University',
    web_page: 'http://www.zntu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4vxMGJ4EJ8ae2wo7g8v3V21tpSpJX4dz6lH0ibA9VQNzPF09GwNk6yqg',
    id: 8136,
    acronym: 'ZNTU',
  },
  {
    alpha_two_code: 'UA',
    country: 'Ukraine',
    domain: 'znu.edu.ua',
    name: 'Zaporizhzhe National University',
    web_page: 'http://www.znu.edu.ua/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0rwulcf2oAm_q416xUtf7_AabfCmQOMHAZWTiECjGH95MmZdNMk3HIw',
    id: 8137,
    acronym: 'ZNU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'acd.ac.ae',
    name: 'American College Of Dubai',
    web_page: 'http://www.acd.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQCMg12Kv5tv9_3dsEncIeJkQvMRbYjDWeYC87ugBMllHIq93lDA-LSVg',
    id: 8138,
    acronym: 'ACD',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'adu.ac.ae',
    name: 'Abu Dhabi University',
    web_page: 'http://www.adu.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxTyeAdr7bFMy6ofaQECyVgc09lpfGanLO6Gz2dFgSRW4bFLwmYzH6jrE',
    id: 8139,
    acronym: 'ADU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'agu.ae',
    name: 'Al Ghurair University',
    web_page: 'http://www.agu.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMqPGKEv-Klk7vguPJMyWpgx9G9C-Ia1pP2Op7c4Rx3sAHAgrbr4h28w',
    id: 8140,
    acronym: 'AGU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'ajman.ac.ae',
    name: 'Ajman University of Science & Technology',
    web_page: 'http://www.ajman.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg29v7zMHsh6KBpA7sxRK83wgIWfFZaKGDNn_fDTTaEDRdg83Nf3F3aCKp',
    id: 8141,
    acronym: 'AUS&T',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'alainuniversity.ac.ae',
    name: 'Alain University of Science and Technology',
    web_page: 'http://www.alainuniversity.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfat9NZ6MaVF_OyPV671jUFkZPkzhObs9hqyWKwjbVk5RBxjW2AgeKnKw',
    id: 8142,
    acronym: 'AUST',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'alhosnu.ae',
    name: 'Alhosn University',
    web_page: 'http://www.alhosnu.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcgq_Y_M2nhJe93YArvIMqyIgacC94PaX4flzWK3CnimQg_FcxJhw77mk',
    id: 8143,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'aud.edu',
    name: 'American University in Dubai',
    web_page: 'http://www.aud.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvRJxHYWG0xR9a4XOZAwFJOcosYqtS6TA8Gml1ebmjCBM67Q66hhMRZg',
    id: 8144,
    acronym: 'AUID',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'aue.ae',
    name: 'American University in the Emirates',
    web_page: 'http://www.aue.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpqzoafxSC3qDortdP6vmamqjBkDvXyGcB85rpJUNxLDct36N6Fo7Mxw',
    id: 8145,
    acronym: 'AUIE',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'aus.edu',
    name: 'American University of Sharjah',
    web_page: 'http://www.aus.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7_hvbNE9K0v9MLmRn9YZ8cWkIK7FBeqio3bP9KEbG6ijwMP7P5Y4PbGY',
    id: 8146,
    acronym: 'AUS',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'buid.ac.ae',
    name: 'British University in Dubai',
    web_page: 'http://www.buid.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEWroLB5mdNyC1blDMnYQlzBbqz_eYsXstAr5q8eonmxMfl05JMezQs-E',
    id: 8147,
    acronym: 'BUID',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'dmcg.edu',
    name: 'Dubai Medical College for Girls',
    web_page: 'http://www.dmcg.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-RhwlESoOKw1I-qEG6AXXb-_9JJP2te9i1aUbOdHRp5qQMHL6Y6lg6PQh',
    id: 8148,
    acronym: 'DMCFG',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'dpc.edu',
    name: 'Dubai Pharmacy College',
    web_page: 'http://www.dpc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJ9FIFsKiPJrwnNOVzGFYBR3KMcVRUxNw_zBTVyoww-AxibWD1DDuaYw',
    id: 8149,
    acronym: 'DPC',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'dubai.rit.edu',
    name: 'Rochester Institute of Technology Dubai',
    web_page: 'http://dubai.rit.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVCZ2d3lPZh-rNog9cumESK5HySbuL52uyamGdexFnIxQJS5RxQII3Kbo',
    id: 8150,
    acronym: 'RITD',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'ece.ac.ae',
    name: 'Etisalat University College',
    web_page: 'http://www.ece.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT56l-P46dYxNw6sdxSSH-JlXhjKRAPJbO0hWvFDL24EO8U305CffjHgaM',
    id: 8151,
    acronym: 'EUC',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'emiratesacademy.edu',
    name: 'The Emirates Academy of Hotel Managment',
    web_page: 'http://www.emiratesacademy.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE3bxfnT33GNMdrPFyXxdX1RO27FHGcm1erMp8DmqaJloQkzHt11L1FPIn',
    id: 8152,
    acronym: 'EAHM',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'gmu.ac.ae',
    name: 'Gulf Medical University',
    web_page: 'http://www.gmu.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQecyWn0ilhDm4qt847bDnfbfS7Q-a3SAtCITbkQ3sxRJ-sEu9TmjRK5S0',
    id: 8153,
    acronym: 'GMU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'hbmeu.ac.ae',
    name: 'Hamdan Bin Mohammed e-University',
    web_page: 'http://www.hbmeu.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxzdLw4L1bWaKjb4ORXRK1b9thWkBKN4decWstWUwknjvLeFiZ2KWYbw',
    id: 8154,
    acronym: 'HBME',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'hct.ac.ae',
    name: 'Higher Colleges of Technology',
    web_page: 'http://www.hct.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwHjvt7ICCjzRKGBhi1r_xcGtwOgTEdpWkJEZVzYlMpk4n07iaB3Qyig',
    id: 8155,
    acronym: 'HCT',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'ittihad.ac.ae',
    name: 'Ittihad University',
    web_page: 'http://www.ittihad.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2VWQR9YEuEWyApy6bfCNZuTQiLV9j8YA7z-uKGLtuYkUZMQQwanQG6g',
    id: 8156,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'ju.ac.ae',
    name: 'Jumeira University',
    web_page: 'http://www.ju.ac.ae/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSZfahFefGEA_dfyTqZ_JxqSSXmxPQuuKdAPbhJF5V3d21OmuoQEjj89A',
    id: 8157,
    acronym: 'JU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'khawarizmi.com',
    name: 'Al Khawarizmi International College',
    web_page: 'http://www.khawarizmi.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJVLH9dpZchqX8_vSHiD0_rVfUh1mfcQpeT5ooTy_K93BT9qjMthHM7ls',
    id: 8158,
    acronym: 'AKIC',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'ku.ac.ae',
    name: 'Khalifa University of Science Technology and Research',
    web_page: 'http://www.ku.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPuNjSLVSapmykF0e0V64mgcGZHqW6BlAML4ye-VLjhaeIp64HKIN50g',
    id: 8159,
    acronym: 'KUSTR',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'kustar.ac.ae',
    name: 'Khalifa University',
    web_page: 'http://www.kustar.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYOFH3NPy-A4rt0GO2dfJ73j9jWfHkdnFJ-7kqxykZy061aGEqAOPj7Hg',
    id: 8160,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'masdar.ac.ae',
    name: 'Masdar University Of Science And Technology',
    web_page: 'http://www.masdar.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMsyo36cl5-UoZq2bNOOy69xZmGJvlUEnoge2LAXsFu0WKBxITPrma_O0',
    id: 8161,
    acronym: 'MUST',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'nyuad.nyu.edu',
    name: 'New York University Abu Dhabi',
    web_page: 'http://nyuad.nyu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRggcjsYdGTabZp9ohSFKfxwM8sgrUAaONiG5yl2M4by_UpetiHeO48skg',
    id: 8162,
    acronym: 'NYUAD',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'pi.ac.ae',
    name: 'The Petroleum Institute',
    web_page: 'http://www.pi.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRanDPX_bl9Lh1T6dYOh4-u-sBTy0b6iORIX-Skf5X2gE8O-hxxpD9uTP8',
    id: 8163,
    acronym: 'PI',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'rakmhsu.com',
    name: 'Rak Medical & Health Sciences University',
    web_page: 'http://www.rakmhsu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSz9HNmm-kqx1tuS4U9e8aLnp7Fr3UEcqMqSqeAhGmLNtcoklpy7fkdOSQ',
    id: 8164,
    acronym: 'RM&HSU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'sharjah.ac.ae',
    name: 'University of Sharjah',
    web_page: 'http://www.sharjah.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7_hvbNE9K0v9MLmRn9YZ8cWkIK7FBeqio3bP9KEbG6ijwMP7P5Y4PbGY',
    id: 8165,
    acronym: 'US',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'skylineuniversity.com',
    name: 'Skyline University College Sharjah',
    web_page: 'http://www.skylineuniversity.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHEMG05yWB_fIX9Hf4749kX-tUVoqCvUypvPiVqJC6qghuBIW29ulg4evm',
    id: 8166,
    acronym: 'SUCS',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'sorbonne.ae',
    name: 'Paris-Sorbonne University Abu Dhabi',
    web_page: 'http://www.sorbonne.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4irlyuKHZcWA8nlrxYOSbiuJMr-fu29mubSp8yC_nPxHYwX2YM0zHExI',
    id: 8167,
    acronym: 'PUAD',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'uaeu.ac.ae',
    name: 'United Arab Emirates University',
    web_page: 'http://www.uaeu.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBGys1qBuRXxmM5KBCQKVOPz2qJg1aAYC9O4fmKf_5QzAK9AEmUfbT9A',
    id: 8168,
    acronym: 'UAEU',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'ud.ac.ae',
    name: 'University Of Dubai',
    web_page: 'http://www.ud.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThq00Gxb8nExwDITiZ-RyEDBAomISbB9UacdYv4bX1V78vU8gJ46kUmkA',
    id: 8169,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'uojazeera.com',
    name: 'University of Jazeera',
    web_page: 'http://www.uojazeera.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCjJ3kgznjImfzR-Uwswjn8O80AUf4IvK18zsdjo9Qrfzd9DT4LtFvu4A',
    id: 8170,
    acronym: 'UJ',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'uowdubai.ac.ae',
    name: 'University of Wollongong - Dubai Campus',
    web_page: 'http://www.uowdubai.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8CgYcnmlXTPw6aXUn_-f98zl2CU7iVNUcHkH6n2FO5zLRDN7NEdcJBQ4',
    id: 8171,
    acronym: 'UW-DC',
  },
  {
    alpha_two_code: 'AE',
    country: 'United Arab Emirates',
    domain: 'zu.ac.ae',
    name: 'Zayed University',
    web_page: 'http://www.zu.ac.ae/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3e27ZGZIN1hryTbIaL87uRlxr-2-wekY_pczTMlVaiK0nLIFXjCuQ9Q',
    id: 8172,
    acronym: 'ZU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'abdn.ac.uk',
    name: 'University of Aberdeen',
    web_page: 'http://www.abdn.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbH4qiRHVe8fQ_1JmIvGnfsfelZh02chNaT8G4oY6wCs_FDzWRDTosZiM',
    id: 8173,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'aber.ac.uk',
    name: 'University of Wales Aberystwyth',
    web_page: 'http://www.aber.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_JTvSxJXQM2mTQszSjzd4vr6TZvtHOC3URvm8Droh2pPggWDe31gvDg',
    id: 8175,
    acronym: 'UWA',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'abertay.ac.uk',
    name: 'University of Abertay Dundee',
    web_page: 'http://www.abertay.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhODqlIF3px-8FhQhDERNQMj-4eWiNpObeXvbpBsvvrpIhfR0RRyh-zA',
    id: 8176,
    acronym: 'UAD',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'aiuniv.edu',
    name: 'American InterContinental University - London',
    web_page: 'http://www.aiuniv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmVzbq5ZX3Q8y0qq_JMbHr40YHm1bq1dw1wyOnzQqyDz8e6Qsj7FZOrw',
    id: 8177,
    acronym: 'AIU-L',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'anglia.ac.uk',
    name: 'Anglia Ruskin University',
    web_page: 'http://www.anglia.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqHdj8JYzmVUrjEKSZHozP8DwhVH6eENgx6qK2umLoUvOi5sXubN5pOxI',
    id: 8179,
    acronym: 'ARU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'aston.ac.uk',
    name: 'Aston University',
    web_page: 'http://www.aston.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJAo5OTHUUP7pgxasqgCv0J77OeWuUqSuxVbJLW-5BBF_Mm0HTKeXjBA',
    id: 8180,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'aul.edu',
    name: 'The American University in London',
    web_page: 'http://www.aul.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCoTkW4ncrX1PkMhb31dCvvV6nHAjWuYx-cNxzbr8dOT6lDsargBbsZQzmxw',
    id: 8181,
    acronym: 'AUIL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bangor.ac.uk',
    name: 'University of Wales Bangor',
    web_page: 'http://www.bangor.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRq1LFZ3Om6-2XdMIuO3k-mWj0wv60iF53OoX41jXsb4jnd8_lKtQ1J--o',
    id: 8182,
    acronym: 'UWB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bath.ac.uk',
    name: 'University of Bath',
    web_page: 'http://www.bath.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo7bHltHppjGndC5qqmJGFKuCq4Ja9stImsepDgz3ZxQ8SIVbZeZkqakg',
    id: 8183,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bbk.ac.uk',
    name: 'Birkbeck College University of London',
    web_page: 'http://www.bbk.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpGoQ4IHmkA8DVVpL1GX2EyLXVnHqE15eManZd8uY7CgS1Pwwn0nBOHHI',
    id: 8184,
    acronym: 'BCUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bcom.ac.uk',
    name: 'British College of Osteopathic Medicine',
    web_page: 'http://www.bcom.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_bdm2mY2EXDSlp7nzopGLxiaBV1GjYWp7a_USSiKlc8hwTxYrz5jIId0',
    id: 8185,
    acronym: 'BCOM',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bcu.ac.uk',
    name: 'Birmingham City University',
    web_page: 'http://www.bcu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2A0NnF7Y1zptJXeFo5xHznMHZSwpxRtzK78II0TKe3Sa3WmGkSsldq48',
    id: 8186,
    acronym: 'BCU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'beds.ac.uk',
    name: 'University of Bedfordshire',
    web_page: 'http://www.beds.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDJ9_GUT5mLGKflZmPvmk-epsv8wK3-jYpz1HcAUrfUKxWsnvvucGn5xI',
    id: 8187,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bham.ac.uk',
    name: 'University of Birmingham',
    web_page: 'http://www.bham.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS48ZNLGma6KbRpQVj_YNc-banprZXpxJgT26ag0JXCmuSBe8tzMalYQw',
    id: 8188,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bolton.ac.uk',
    name: 'University of Bolton',
    web_page: 'http://www.bolton.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqX9Gp63xx1jEwPI6p6J_WP9Iuap-xqK63hIFoXqN6RbmirUtDV7GAgJg',
    id: 8189,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bournemouth.ac.uk',
    name: 'Bournemouth University',
    web_page: 'http://www.bournemouth.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWFqSjtVQtMtLcdFmMc6AWkEEQEPXLskHtYRRpz88mQ0RY0KcBe7PMwQ',
    id: 8190,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'brad.ac.uk',
    name: 'University of Bradford',
    web_page: 'http://www.brad.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGfMS3y-e4u9MOyOt3fukAxCunO7k-4n4AamKxyWSs8IlkDyBNPY1kcA',
    id: 8191,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'brijnet.org',
    name: 'London School of Jewish Studies',
    web_page: 'http://www.brijnet.org/lsjs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP_Y1dMDa-aPwWic0jQkn6qd40JQJVuV42RgdUbrhpi5I-X_n8sROkqw',
    id: 8192,
    acronym: 'LSJS',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bris.ac.uk',
    name: 'University of Bristol',
    web_page: 'http://www.bris.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPMMlf87Q8uuCL2ZFJ2E0XL5pAAXHhIK5TKCrTLWVtRxEYTUpac9LqRA',
    id: 8193,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'brookes.ac.uk',
    name: 'Oxford Brookes University',
    web_page: 'http://www.brookes.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ808TaPm53v6BCU2a4p4Iz11z3J0qsUEdig7sS_pCzlPKfhCeRo4Fzkto',
    id: 8194,
    acronym: 'OBU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'brunel.ac.uk',
    name: 'Brunel University Uxbridge',
    web_page: 'http://www.brunel.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDZHsxkr9EeQWaUJOH2FdChOHXHXhIDYS5NqrRWOn9U9qv_Wcoy-fg0oV_',
    id: 8195,
    acronym: 'BUU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bton.ac.uk',
    name: 'University of Brighton',
    web_page: 'http://www.bton.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4RLxSpbboP6NMXZG7ZPTORMr0LhRRq3_oqDeTxW6-0O9w3ZdfSnC-jOI',
    id: 8196,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'buck.ac.uk',
    name: 'University of Buckingham',
    web_page: 'http://www.buck.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3e8Axr2pLBXZaY0HJM0EubEtqCzaWs9oDePDrF_pbDaTekPQ0gC_32Q',
    id: 8197,
    acronym: 'UB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'bucks.ac.uk',
    name: 'Buckinghamshire New University',
    web_page: 'http://www.bucks.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7D1ki5FRnyQzF-ad8ZSDqMPp0YNZV9k_Lwcl-wxOar8sLlIle7gicjg',
    id: 8198,
    acronym: 'BNU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'cam.ac.uk',
    name: 'University of Cambridge',
    web_page: 'http://www.cam.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDQxujThTQXxagN1GzKQTXyAE4l7Fo8mK1wFOorCyTzQq_KxGZsHy7JM0',
    id: 8199,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'camb.linst.ac.uk',
    name: 'Camberwell College of Arts',
    web_page: 'http://www.camb.linst.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzyJlaEj0KpCDvEt1_b05gntjWJspNkDpldqTj0R7C3YILCOOdhViIGx0',
    id: 8200,
    acronym: 'CCA',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'canterbury.ac.uk',
    name: 'Canterbury Christ Church University',
    web_page: 'http://www.canterbury.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9tQwZU18yyBEDESwjToOFwJwrAyI7hZU3krbBAMKxcK1hA4jRei7gR4Ly',
    id: 8201,
    acronym: 'CCCU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'cardiff.ac.uk',
    name: 'Cardiff University',
    web_page: 'http://www.cardiff.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRpvWrZgbZpcZPWF-JqQrTLsG-GY6F1IS4Rug_8IIRzlKT7Dr-SeL0Y1Q',
    id: 8202,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'chelsea.linst.ac.uk',
    name: 'Chelsea College of Art and Design',
    web_page: 'http://www.chelsea.linst.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCJA3m76wxtFanavBPC-zofeaY8oEqgIGHtu0DqPKrbBJzpLerYtC9YlU',
    id: 8203,
    acronym: 'CCAD',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'chester.ac.uk',
    name: 'University of Chester',
    web_page: 'http://www.chester.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3ReNezYNeFXyPCwkA7-l3XQJPX080Jl41qlU6c-C4DSAPBnSW1kNdqQ',
    id: 8204,
    acronym: 'UC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'courtauld.ac.uk',
    name: 'Courtauld Institute of Art University of London',
    web_page: 'http://www.courtauld.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqgHNKG64xNovsxeeeHpV_vMTH876EhXedACRVX4HH_gI2npVvEvRXRdGcUg',
    id: 8206,
    acronym: 'CIAUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'coventry.ac.uk',
    name: 'Coventry University',
    web_page: 'http://www.coventry.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP3R9ks-_34BteH5ecPRDOdzKzAt4bmVZRPFOeKTLzxL08VYZzvkhP1yo',
    id: 8207,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'cranfield.ac.uk',
    name: 'Cranfield University',
    web_page: 'http://www.cranfield.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRh_5nsxbstjN3mRxjd2cAc88jzvCRNMBme2VLxgtANRHnXQLzZOAXTi4',
    id: 8208,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'csm.linst.ac.uk',
    name: 'Central Saint Martins College of Art & Design',
    web_page: 'http://www.csm.linst.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUanefVxFS2nzGxPXwjzjyhcT-OdvrRgS45nntTtFba7AS-udpMHyfariePA',
    id: 8209,
    acronym: 'CSMCA&D',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'derby.ac.uk',
    name: 'University of Derby',
    web_page: 'http://www.derby.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI_hIs6NmAwnVprcnHmNQkMFj11oS41ugXPDhm9O5DT7yDRr30vw_5Pg',
    id: 8210,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'dmu.ac.uk',
    name: 'De Montfort University Leicester',
    web_page: 'http://www.dmu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfvbVyRSC1F_GsWEPKH1_OAGOFuvdVjS5IafMAXhQLSbKh-RlyS3CAQA',
    id: 8211,
    acronym: 'DMUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'dundee.ac.uk',
    name: 'University of Dundee',
    web_page: 'http://www.dundee.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9bJkmbGoTElzVpUgU4loSOD4MhWUkXBScu_MbDcBxup_MX3T4kogB0Rmo',
    id: 8212,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'dur.ac.uk',
    name: 'University of Durham',
    web_page: 'http://www.dur.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiPhE_mblwYB7EkSs9x4GRXdATN1SckZVMC4Cv0O1jodAZnPGlSJugB2ka',
    id: 8213,
    acronym: 'UD',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ed.ac.uk',
    name: 'University of Edinburgh',
    web_page: 'http://www.ed.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGEh7ccyJKzvM718372Tv5gCxVLH8dBKYuCgy2bUuJi6nAD3DwSHoVSTk',
    id: 8214,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'essex.ac.uk',
    name: 'University of Essex',
    web_page: 'http://www.essex.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRykbP_hgQjp8t2SQv-MFhCNXToI8gpzor4sL0TgMMu23SCFcYytqricA',
    id: 8216,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ex.ac.uk',
    name: 'University of Exeter',
    web_page: 'http://www.ex.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC06eh_Rww_7jcVBp_raxAy_qgUFdzlcYoMvy1LN4PsgJ-e4D-etRdZA',
    id: 8217,
    acronym: 'UE',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'falmouth.ac.uk',
    name: 'Falmouth University',
    web_page: 'http://www.falmouth.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6EHt39bfa0Ba8An2Uu1NrUYaR02Z6te3-wcToIik11Zquuu9aRtVQ2g',
    id: 8218,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'gcal.ac.uk',
    name: 'Glasgow Caledonian University',
    web_page: 'http://www.gcal.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQX8f8e14AW-LRCTIIGnfhKgUK4guMvEYhYDbuU1ZzMeh2x6Badzph20Ne',
    id: 8219,
    acronym: 'GCU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'gla.ac.uk',
    name: 'University of Glasgow',
    web_page: 'http://www.gla.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCf92TFiDuKXcVTRh8UwJF3AMUZDogAGfvXUzGWWXI08agqq60MkewGPs',
    id: 8220,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'glam.ac.uk',
    name: 'University of Glamorgan',
    web_page: 'http://www.glam.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRuhTDJ_uW7FYL8xbfvUS2hXbo4AD2uG0CZiQ9e7MyqVVHpZq5qacUkA',
    id: 8221,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'glos.ac.uk',
    name: 'University of Gloucestershire',
    web_page: 'http://www.glos.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0pOjGvGF7Ei5lGPRCjzjL5fmwl61g8vQcQyHiG7lMI1ymowK2Tc7pMEM',
    id: 8222,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'gold.ac.uk',
    name: 'Goldsmiths College University of London',
    web_page: 'http://www.gold.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJsXbUmS5_h2tYfZ8ZWcJzS9wBUI8s_c83MULd6odEN8vnRGXjfqCrs3w',
    id: 8223,
    acronym: 'GCUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'gre.ac.uk',
    name: 'University of Greenwich',
    web_page: 'http://www.gre.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjDXC3cHV8cawJE5-qtPyaGkVqRJkmSsy6jILJ-HXJxRoDZHuk8-WQBA',
    id: 8224,
    acronym: 'UG',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'gsa.ac.uk',
    name: 'Glasgow School of Art',
    web_page: 'http://www.gsa.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCOs5uvLssGIfU3sMm886l7fYvOHxOyJ9a9jjAorn2Sc0i3gH4Q_jsUA',
    id: 8225,
    acronym: 'GSA',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'halifaxuni.ac',
    name: 'University of Halifax Birmingham Campus',
    web_page: 'http://www.halifaxuni.ac/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPbfAg2Qbh_Mt03_HrwmrGEbbKXwQXFg61aDiq82lSPSpS3hMosXIDnLQ',
    id: 8226,
    acronym: 'UHBC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'herts.ac.uk',
    name: 'University of Hertfordshire',
    web_page: 'http://www.herts.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSELsqqWZyVx50RCu2TvE5P5sQWIjnUN1K1bMEplshazBk-EPz8YlCG9A',
    id: 8227,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'heythrop.ac.uk',
    name: 'Heythrop College University of London',
    web_page: 'http://www.heythrop.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNkfIUPwsW8UVxZg-QU0uEmiGhK9QbjOG2m8pkyRHJZOJ-T5lUAFDm-A',
    id: 8228,
    acronym: 'HCUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'hope.ac.uk',
    name: 'Liverpool Hope University College',
    web_page: 'http://www.hope.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsnSnkGB6GtMA7I2-RiD01sR8iPe4THvBCVA3IX-d2lcHnPXbH9QQjZ0E',
    id: 8229,
    acronym: 'LHUC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'hud.ac.uk',
    name: 'University of Huddersfield',
    web_page: 'http://www.hud.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSh1QDGBKF7XVm9sULMH_j9jKtHs4fT-EMETQD3S6EE8_e7Nu7QZdIw1iI',
    id: 8230,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'hull.ac.uk',
    name: 'University of Hull',
    web_page: 'http://www.hull.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRY2Itfm1Q5aC6NXcORIdN-MZ1bGA5EUsNTfhWu_X_hV3cmgFtLoI_2mA',
    id: 8231,
    acronym: 'UH',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'huron.ac.uk',
    name: 'Huron University USA in London',
    web_page: 'http://www.huron.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBxuI4b5zC2af33Kwu4aJVTiurPESORamGfYt4ThGYVI1JsaQQukPkuQ',
    id: 8232,
    acronym: 'HUUIL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'hw.ac.uk',
    name: 'Heriot-Watt University',
    web_page: 'http://www.hw.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGRNJWk2UDXcJt8EUCEh_nFpL5UooD5159hdEZyqlPudUYys9TwSxlWn0',
    id: 8233,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ic.ac.uk',
    name: 'Imperial College London',
    web_page: 'http://www.ic.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxGh-XuFPo_B_s1cg7hog0bDC2O0oBaEUe7DV8hTP_pj_YsE30wbxJFA',
    id: 8234,
    acronym: 'ICL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ifslearning.ac.uk',
    name: 'ifs University College',
    web_page: 'http://www.ifslearning.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjSzJsM-m7JjzUo44eZdwDp3bDmoVQN0Xmu2y-cI4FE3gvwDy0U76lPwk',
    id: 8235,
    acronym: 'IUC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ihr.sas.ac.uk',
    name: 'Institue of Historical Research University of London',
    web_page: 'http://ihr.sas.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpEbDFk5vhXn5D_Kl9nOYruI7wWBN9fGdI588_DqcRDPgRIiQadU3jvO0',
    id: 8236,
    acronym: 'IHRUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ihr.sas.ac.uk',
    name: 'Institute of Commonwealth Studies University of London',
    web_page: 'http://www.ihr.sas.ac.uk/ics/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzbTAFF7JHHwRhWC846R28340y4C-p1f0hq97ygi3R4v2lW_85TlXhMeg',
    id: 8237,
    acronym: 'ICSUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ioe.ac.uk',
    name: 'Institute of Education University of London',
    web_page: 'http://www.ioe.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTokLUDkXxleX6yvzwqNCV0a1jq2jbP1FYTjwGVWFuVi2XrC4bzMZSe3rU',
    id: 8238,
    acronym: 'IEUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'islamiccolleges.com',
    name: 'International Colleges of Islamic Science',
    web_page: 'http://www.islamiccolleges.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvbkLtSr6Ypt1Ci7PPMfVUUwel5B-vPAbAhGG7UcQJpCL6ndtUBmyGoA',
    id: 8239,
    acronym: 'ICIS',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'kcl.ac.uk',
    name: "King's College London University of London",
    web_page: 'http://www.kcl.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRISScEHEjIf7iFCnjV4OFI-C5_AlZ0lL9yajh8OleCNJnFeDTKUOnpbw',
    id: 8240,
    acronym: 'KCLUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'keele.ac.uk',
    name: 'Keele University',
    web_page: 'http://www.keele.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtVCJcwu-AQN1Bsb6tsjfiZ8gIYOWHseHhDGmiHMnF8f7XHQ4TI44kTmw',
    id: 8241,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'kingston.ac.uk',
    name: 'Kingston University',
    web_page: 'http://www.kingston.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReKnqeHYddqJVlfnLRXJK3ubRbWPXAK4vNF2jmipTiz84CgDIqg06TBVje',
    id: 8242,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'kolieh.com',
    name: 'International Centre for Isclamic Science',
    web_page: 'http://www.kolieh.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbnYJ4I2F8PnCvemFQ2g_pb-iS_WHXw0VwDvapdNNzhnkE9V2C12L0KA',
    id: 8243,
    acronym: 'ICFIS',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lamp.ac.uk',
    name: 'University of Wales Lampeter',
    web_page: 'http://www.lamp.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWL_f_yJrH5Y4KhSeO5OM6in8BaZWt2UQH2pu1dkO24j2XaJdIAYxokQ',
    id: 8244,
    acronym: 'UWL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lancs.ac.uk',
    name: 'University of Lancaster',
    web_page: 'http://www.lancs.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnUSt0YDwKJn9NjVuVnWj_SJxgzaJF6WKMTesU1yHI2v7JIzXpFMq0hfY',
    id: 8245,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lboro.ac.uk',
    name: 'Loughborough University',
    web_page: 'http://www.lboro.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZQtWLTFNXYbXzCoZMLPj-mpzu0-2L4u9MddhXI0IL2yBPV-SY6oMrcw',
    id: 8246,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lbs.lon.ac.uk',
    name: 'London Business School',
    web_page: 'http://www.lbs.lon.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNmDpebHJShDdNsQhSL6mif5rJBL3swYOvi8ca5PwkOi3NtSFDGc-mBw',
    id: 8247,
    acronym: 'LBS',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lcst.ac',
    name: 'London College of Science & Technology',
    web_page: 'http://www.lcst.ac/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ukPoCyND8nD9S6nhf0nFrZ9tECvUKPa2vwF2i_YZ0VIR6iOYhEDY0A',
    id: 8248,
    acronym: 'LCS&T',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'le.ac.uk',
    name: 'University of Leicester',
    web_page: 'http://www.le.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwt0GR5jXq6ceNr-24DQ__fULGbg15DcUOHuKkQNgwKYM0NJOWR_YCMVY',
    id: 8249,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'leeds.ac.uk',
    name: 'University of Leeds',
    web_page: 'http://www.leeds.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9UA_NhYb0COqpjns7dJPpU8hybQD_shvSMnFOZFSuFbYXE23crzd8Npo',
    id: 8250,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lgu.ac.uk',
    name: 'London Guildhall University',
    web_page: 'http://www.lgu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBOsKv6Sju4XcUuk_Idy4vTLxpl4E1hrcmwcRkUk42ue8zJ7owq3KrN4fjKA',
    id: 8251,
    acronym: 'LGU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'limt.co.uk',
    name: 'London Institute of Management and Technology',
    web_page: 'http://www.limt.co.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUgugMovdK6OT48x1IRhADf9lBMdGqE79tUvTq-S9-G9FvwktzzoygLw',
    id: 8252,
    acronym: 'LIMT',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lincoln.ac.uk',
    name: 'University of Lincoln',
    web_page: 'http://www.lincoln.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGx37XXI-38kxE1kkUxRplovqGMus6hH1sPedZ7Y4MKXtyAKCIb2nHpFlh',
    id: 8253,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'liv.ac.uk',
    name: 'University of Liverpool',
    web_page: 'http://www.liv.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe7CloRC2lrytwIG_GODaGz8DPTMrX8JLMI3mjiSRnlQuQ-GMplPhOLg',
    id: 8254,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'livjm.ac.uk',
    name: 'Liverpool John Moores University',
    web_page: 'http://www.livjm.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSit4PEL1jAfcB0wpNGcA0uB_bSr8StyN430PZLCoQX71ZXgfeTVY-DQ',
    id: 8255,
    acronym: 'LJMU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lmu.ac.uk',
    name: 'Leeds Metropolitan University',
    web_page: 'http://www.lmu.ac.uk/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcS-3bqVC1Wu2VIp8cwW7ufapQeTlFmpqVk_oqzOYy762aboFmurYu1xWw',
    id: 8256,
    acronym: 'LMU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lon.ac.uk',
    name: 'University of London',
    web_page: 'http://www.lon.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJsXbUmS5_h2tYfZ8ZWcJzS9wBUI8s_c83MULd6odEN8vnRGXjfqCrs3w',
    id: 8257,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'londonmet.ac.uk',
    name: 'London Metropolitan University',
    web_page: 'http://www.londonmet.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBOsKv6Sju4XcUuk_Idy4vTLxpl4E1hrcmwcRkUk42ue8zJ7owq3KrN4fjKA',
    id: 8258,
    acronym: 'LMU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lsbf.org.uk',
    name: 'London School of Business & Finance',
    web_page: 'http://www.lsbf.org.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNmDpebHJShDdNsQhSL6mif5rJBL3swYOvi8ca5PwkOi3NtSFDGc-mBw',
    id: 8259,
    acronym: 'LSB&F',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lse.ac.uk',
    name: 'London School of Economics and Political Science University of London',
    web_page: 'http://www.lse.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqnR1yWNzwJDnwj3egQEEksU9700PdHUY6p8AaEXbvmhnrFAkiwBxcut0',
    id: 8260,
    acronym: 'LSEPSUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'lshtm.ac.uk',
    name: 'London School of Hygiene & Tropical Medicine University of London',
    web_page: 'http://www.lshtm.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7phQ_oXwDSlUGwdmV-lb353WKBWSRWNA7ZFmdPKe-NQoJbF13wK0U0A',
    id: 8261,
    acronym: 'LSH&TMUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'man.ac.uk',
    name: 'University of Manchester',
    web_page: 'http://www.man.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRT9PbggcslqzGdDchWy40-99g7nrOFLWedMkEwwOlkpXd4lKjimsldQAOX',
    id: 8262,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'mdx.ac.uk',
    name: 'Middlesex University',
    web_page: 'http://www.mdx.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDab7dh4t3mDTx4jLQ0V2XL7V-Zh4chFtoPFQnSDb0Sz_tqg3iSNoAHQ',
    id: 8263,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'med.ic.ac.uk',
    name: 'Imperial College School of Medicine',
    web_page: 'http://www.med.ic.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbp4OWnfUctAncv9Ml9ktayRvDy5JMAUe_hWgiWBI2X-Xpv0yYJyVzDw',
    id: 8264,
    acronym: 'ICSM',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'mmu.ac.uk',
    name: 'The Manchester Metropolitan University',
    web_page: 'http://www.mmu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKm5QZVSfPle__LaPOd8T4kBSWtkxjg9kPsxP6qqwo-paHFf_HeodvpA',
    id: 8265,
    acronym: 'MMU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'napier.ac.uk',
    name: 'Napier University',
    web_page: 'http://www.napier.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPSTkVVHBM8qW2figO0NN0ss86CKe6kZNbj3O09TEF60gnRUX5mWUkOxU',
    id: 8266,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ncl.ac.uk',
    name: 'University of Newcastle-upon-Tyne',
    web_page: 'http://www.ncl.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnj8jacilkjbGN-Vx1NaqtHNowrWRya6YQJPIqegSguwEQ8t5E55cpj2PP',
    id: 8267,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'newport.ac.uk',
    name: 'University of Wales Newport',
    web_page: 'http://www.newport.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNlJTJrO18tKxmYj2X5IKJ2V6e8qfC11qKY_gorxeq8lWN1wnxbsZ5CFM',
    id: 8268,
    acronym: 'UWN',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'niu.org.uk',
    name: 'Newport International University',
    web_page: 'http://www.niu.org.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSXGdMXC8mueE8W3OnSik9dkMp5vUznd-DplOi8nxPuS0F_OCZhsNnp7A',
    id: 8269,
    acronym: 'NIU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'northampton.ac.uk',
    name: 'University of Northampton',
    web_page: 'http://www.northampton.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIdQ94dQLfuUdi-CS8jTOUF1roVgRCj0YZsfbfKyB2toLlRp3GuDKFy4IK',
    id: 8270,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'nottingham.ac.uk',
    name: 'University of Nottingham',
    web_page: 'http://www.nottingham.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAQrjhphdEKI1qhUVrHNvNseMBfy6_vtufo7y2qSwTvvzc4dj3tZMx_WE',
    id: 8271,
    acronym: 'UN',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ntu.ac.uk',
    name: 'Nottingham Trent University',
    web_page: 'http://www.ntu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-9EjT4AQvkEIraaWRzunAlXcdTyHGL7tIrR9MAfRUsODfQfBj3ERx-w',
    id: 8272,
    acronym: 'NTU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'open.ac.uk',
    name: 'Open University',
    web_page: 'http://www.open.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjDM-C5cfujceHz8JG8cSsYWQPNYqGm36jVh_fbU17hAEVmeLODfh5-A',
    id: 8273,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ox.ac.uk',
    name: 'University of Oxford',
    web_page: 'http://www.ox.ac.uk/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14874college.jpg',
    id: 8274,
    acronym: 'UO',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'paisley.ac.uk',
    name: 'University of Paisley',
    web_page: 'http://www.paisley.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrfLvMae7gAZjEThkdKSK69AIv88v9dS6w4LSxMILF6d-GwVQ9BBzR1A',
    id: 8275,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'plymouth.ac.uk',
    name: 'University of Plymouth',
    web_page: 'http://www.plymouth.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT51K1e-ZGK7x6hrWuTdyfhk90xtbd2AHZDCeJW8il8kOa1YmcXl3qABw',
    id: 8276,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'port.ac.uk',
    name: 'University of Portsmouth',
    web_page: 'http://www.port.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu4y1E2R2dIxHkxE6P_vRaye7ZFpG1wrYYqYbzz6czW0zhG8YocV_7PRw',
    id: 8277,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'qmw.ac.uk',
    name: 'Queen Mary and Westfield College University of London',
    web_page: 'http://www.qmw.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5E4yTyQ9jzm2j_j61TcYpyOdBiItHZAcO5ClVn-8J3xzFBal9ATdgJQ',
    id: 8278,
    acronym: 'QMWCUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'qub.ac.uk',
    name: "The Queen's University Belfast",
    web_page: 'http://www.qub.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyj7YY78tUzhRH64vNocWVXR_ER1pPzDUF9_Hyi2i9cQ1YDz5UXiH39hI',
    id: 8279,
    acronym: 'QUB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ram.ac.uk',
    name: 'Royal Academy of Music University of London',
    web_page: 'http://www.ram.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxHUnWJ_fe2D7MMpiGC3DlWVZ5G2dFTalcHnE8u9lFGnlvh9-ovwn7Tnao',
    id: 8280,
    acronym: 'RAMUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'rca.ac.uk',
    name: 'Royal College of Art',
    web_page: 'http://www.rca.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzfnQ-Wb0YgDrTZ7ZHAyeNlGwByFEU9AMroZENiPoHQPXk6XX9KhwXG3Y',
    id: 8281,
    acronym: 'RCA',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'rcm.ac.uk',
    name: 'Royal College of Music University of London',
    web_page: 'http://www.rcm.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq7RFrNOx-RSMIANsCa4v0Rf1H9ExPRHMjXsy7MunsXZ5IxOT9v4scmw',
    id: 8282,
    acronym: 'RCMUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'rdg.ac.uk',
    name: 'University of Reading',
    web_page: 'http://www.rdg.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR939XIpvu7NHmp_D03tQllkViNAMhPmvcR4Yut0_dN9q_fi7a2np5i5Q',
    id: 8283,
    acronym: 'UR',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'rfhsm.ac.uk',
    name: 'Royal Free Hospital School of Medicine University of London',
    web_page: 'http://www.rfhsm.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgeL0OKywQNjbaOCUWuRq1rwNHQQ5-qKSWbPVBd6xcbEiCjnEcrkpAXQY',
    id: 8284,
    acronym: 'RFHSMUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'rgu.ac.uk',
    name: 'The Robert Gordon University',
    web_page: 'http://www.rgu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZjk2lCaOhoHNiA66ieTbhR2aV_UrM6NE8VIHo0vhP5AJ93stVHt1HVA',
    id: 8285,
    acronym: 'RGU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'rhbnc.ac.uk',
    name: 'Royal Holloway and Bedford New College',
    web_page: 'http://www.rhbnc.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfZNq0xUG7sJ33AbsGVhs_zmqfAjwvh-JYDPYGjZSpy3ykZDPhwdc6IY2PhA',
    id: 8286,
    acronym: 'RHBNC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'richmond.ac.uk',
    name: 'Richmond University - The American International University in London',
    web_page: 'http://www.richmond.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjNLuPcwQ9IvP8B_T-eKtpHcIlQCtUnvC3-KcvuDQOAK7wMeGeuaMgvpo0',
    id: 8287,
    acronym: 'RU-AIUIL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'roehampton.ac.uk',
    name: 'Roehampton University of Surrey',
    web_page: 'http://www.roehampton.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6gqdsivTk8jos0OIyEULN1_cKgvNMHsOhjg39vXQWHgD8Yj5VSQD7sJVp',
    id: 8288,
    acronym: 'RUS',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'salford.ac.uk',
    name: 'University of Salford',
    web_page: 'http://www.salford.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjgfwgT-hcd9Uq3dgF9egYgg73i4kx05KbgP-jd9EJqT74iYEZeECaDg',
    id: 8289,
    acronym: 'US',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sas.ac.uk',
    name: 'Institute of Advanced Legal Studies University of London',
    web_page: 'http://www.sas.ac.uk/ials/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRLsSAvE_NXGuPI6-gKMgN65lJHhfYXVFwMir5tROe8_I1GCOKI-DKVQ',
    id: 8290,
    acronym: 'IALSUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sas.ac.uk',
    name: 'Institute of Classical Studies University of London',
    web_page: 'http://www.sas.ac.uk/icls/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWyBGQYpjsEvGeRhgh28he4qLaIcUC_LDRjAMOxNb4nLTeo6SwfCEFQus',
    id: 8291,
    acronym: 'ICSUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sas.ac.uk',
    name: 'Institute of Germanic Studies University of London',
    web_page: 'http://www.sas.ac.uk/igs/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTokLUDkXxleX6yvzwqNCV0a1jq2jbP1FYTjwGVWFuVi2XrC4bzMZSe3rU',
    id: 8292,
    acronym: 'IGSUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sas.ac.uk',
    name: 'Institute of Latin American Studies University of London',
    web_page: 'http://www.sas.ac.uk/ilas/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTokLUDkXxleX6yvzwqNCV0a1jq2jbP1FYTjwGVWFuVi2XrC4bzMZSe3rU',
    id: 8293,
    acronym: 'ILASUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sas.ac.uk',
    name: 'Warburg Institute University of London',
    web_page: 'http://www.sas.ac.uk/warburg/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlZRrSKjDUrFvr4ZneCYmkRwgsNxvhBSgh59se14qgc79PifYEKF3Kdgs',
    id: 8294,
    acronym: 'WIUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sbu.ac.uk',
    name: 'South Bank University',
    web_page: 'http://www.sbu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzk94nYaUN2YYVjJKlvVDA1GTsYYpgK47vIcGWOnmhhT5_5wWBgRdq7w',
    id: 8295,
    acronym: 'SBU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'schillerlondon.ac.uk',
    name: 'Schiller International University London',
    web_page: 'http://www.schillerlondon.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvju9FinwVFBmedHQ0FhjpnfIKof7gxfdbns_agU94dVmvro8cytYH0rY',
    id: 8296,
    acronym: 'SIUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sclondon.ac',
    name: 'Stratford College London',
    web_page: 'http://www.sclondon.ac/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4yZx3La01wb3tNPnSwXoEVo0WvtOSSLfNBLL9Ezrck6VrVFhZYI9Gvw',
    id: 8297,
    acronym: 'SCL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sghms.ac.uk',
    name: "Saint George's Hospital Medical School University of London",
    web_page: 'http://www.sghms.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo2vCxMbKRXrUf9wsI76PTdS1b-4AwdJBxL2Jh3ea0HLn5bt6iHXf2a54',
    id: 8298,
    acronym: 'SGHMSUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'shef.ac.uk',
    name: 'University of Sheffield',
    web_page: 'http://www.shef.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRBVltfW_2gltNhugHjjxIGeStAjjcGRMXAceZ9X0Av8Cg0VfIDkxr0rQ',
    id: 8299,
    acronym: 'US',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'shu.ac.uk',
    name: 'Sheffield Hallam University',
    web_page: 'http://www.shu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvSh7n1IhB8X_3lF70d6U7bq3oZhlaeQSDYYli_lqs7IBr4bCYCjQkNg',
    id: 8300,
    acronym: 'SHU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'smu.ac.uk',
    name: 'Swansea Metropolitan University',
    web_page: 'http://www.smu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvlLQET5q6-e_rO69iRUVIFPYGNzCtNjFVE4WdVGmdvZ_fBtX3ClxTXw',
    id: 8301,
    acronym: 'SMU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'soas.ac.uk',
    name: 'School of Oriental and African Studies University of London',
    web_page: 'http://www.soas.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_r4DXyaz32t_wOz6eKLorTEyBaeblGQUO4QT8rAof_xYG_SXU0cUYXJsg',
    id: 8302,
    acronym: 'SOASUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'solent.ac.uk',
    name: 'Southampton Solent University',
    web_page: 'http://www.solent.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqu7hm8dIEFTa_KiHpE6kRx8gP_hym6qijUosEHExAeQI9wZB6n2ZVBb0',
    id: 8303,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sothebysinstitutelondon.com',
    name: 'Sotheby´s Institute of Art - London',
    web_page: 'http://www.sothebysinstitutelondon.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzHrRzzhrAyA_y2-d4IRdsgoCR4l4HQrUTY_2aw2aWRLeCNvUA-B6-SHe7',
    id: 8304,
    acronym: 'SIA-L',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'soton.ac.uk',
    name: 'University of Southampton',
    web_page: 'http://www.soton.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPIZ_EnXd-3eRKd-8BI0cieEJDnyEmle3XeOuR7sXpn2uthbnutPHi39Y',
    id: 8305,
    acronym: 'US',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ssees.ac.uk',
    name: 'School of Slavonic and East European Studies University of London',
    web_page: 'http://www.ssees.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZwREhsGeOr7N5c9OzYnCDF4KHLgFbitOKNeUIazvqAUx67Rq9It-X-yCB',
    id: 8306,
    acronym: 'SSEESUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'staffs.ac.uk',
    name: 'Staffordshire University',
    web_page: 'http://www.staffs.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZtIwaOZ1wbQZGrbqGW3lOmvbRS4W8-6nvnTb75zHaEPPVv_x9zStvig',
    id: 8307,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'st-and.ac.uk',
    name: 'University of St. Andrews',
    web_page: 'http://www.st-and.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh0cBHR3WlkBdvJU0xLln_9oUlCwgTP-vxhFUtq0jcI2EaVaWFa0tGEx90',
    id: 8308,
    acronym: 'USA',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'stir.ac.uk',
    name: 'University of Stirling',
    web_page: 'http://www.stir.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqmlSUoNLaAyO4s8bFRLjqt8yAmPUPeNZQ7CSe-YrCHS8IRh0SrSvPjus',
    id: 8309,
    acronym: 'US',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'st-patricks.ac.uk',
    name: "St.Patrick's International College London",
    web_page: 'http://www.st-patricks.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV2bt1ujtTcJ4iKT3Cilexxj0zJ3l77-YdFHk6WpMiese4KjMvd3zcJd4',
    id: 8310,
    acronym: 'SICL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'strath.ac.uk',
    name: 'University of Strathclyde',
    web_page: 'http://www.strath.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwcSf8pg48x9h1jpXzGZmWquB-slkqkQ9QFFgLUVhkxXvJKpAsBIlqKuXoYQ',
    id: 8311,
    acronym: 'US',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sunderland.ac.uk',
    name: 'University of Sunderland',
    web_page: 'http://www.sunderland.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0ZgVLXywRWMmCskIcHb4Hit37R3C8TCngjtCEfKlWAren0E40XF57_A',
    id: 8312,
    acronym: 'US',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'surrey.ac.uk',
    name: 'University of Surrey',
    web_page: 'http://www.surrey.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF1puliR6S3sIaJPbV_4IaMj7DU82SqOrDpw40NnOmXsSPe2WeuIMz5Gs',
    id: 8313,
    acronym: 'US',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'sussex.ac.uk',
    name: 'University of Sussex',
    web_page: 'http://www.sussex.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjSy-P9i3VovQ4Nf-NnUgmk70afYxjJOxjMKVMY9teomnhlNEYfBcJbGfF',
    id: 8314,
    acronym: 'US',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'swan.ac.uk',
    name: 'University of Wales Swansea',
    web_page: 'http://www.swan.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuFCWMYgVf54zSrPFJcyYaKtvzEm65_dbk1shfCpXK7YlsoScV4INfUg',
    id: 8315,
    acronym: 'UWS',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'tcm.ac.uk',
    name: 'Trinity College of Music',
    web_page: 'http://www.tcm.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzUmIgM3nI9y3bAOeLw4IFE9UtoLjIn36-TPLqW7HvSUHuHOMVtGl7BT4',
    id: 8316,
    acronym: 'TCM',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'tees.ac.uk',
    name: 'University of Teesside',
    web_page: 'http://www.tees.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS36Z7_lSrVym-mTsQ6lHD4AJzVxBFI8cPbkJZxySWjU4mmCj3dCTOL28Ch',
    id: 8317,
    acronym: 'UT',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'trinity-cm.ac.uk',
    name: 'Trinity College Carmarthen',
    web_page: 'http://www.trinity-cm.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3v3AtSmziI2NISfX9SDTIt9q35jV6AbvGi0oXygrXidpMKfF7qCR1_w',
    id: 8318,
    acronym: 'TCC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'tvu.ac.uk',
    name: 'Thames Valley University',
    web_page: 'http://www.tvu.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlDjsldfoO55s9r7mJoQFZqCyGYhclxww-H1LTVzQRf91WOP0zQ9rWXQ',
    id: 8319,
    acronym: 'TVU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ucl.ac.uk',
    name: 'University College London University of London',
    web_page: 'http://www.ucl.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCPV3t3N9uxbog-A66F6B9Z4rxs6Dk-xB3bjKvj6KVkQTyLnPlbpZEYw',
    id: 8320,
    acronym: 'UCLUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'uclan.ac.uk',
    name: 'University of Central Lancashire',
    web_page: 'http://www.uclan.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOEXdTE15WOIliM_CE9EAoc9BK8RVH864vttjDH1_XpYiAP40xFSQM7Q',
    id: 8321,
    acronym: 'UCL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ucs.ac.uk',
    name: 'University Campus Suffolk',
    web_page: 'http://www.ucs.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxeW-hpvsCGKUz1VGxB2TypwWsaayXPAkoTHMSWqmsnbYzov3QfKNH79U',
    id: 8322,
    acronym: 'UCS',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'uea.ac.uk',
    name: 'University of East Anglia',
    web_page: 'http://www.uea.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC9vtTJYbINozpGI7gGRKIt4LqLTeJBFYeWZvRwVTSqDESx1VVKWWhEAE',
    id: 8323,
    acronym: 'UEA',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'uel.ac.uk',
    name: 'University of East London',
    web_page: 'http://www.uel.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSuGTCOHOgLzJgNIz5SD7hYzBRJ6o5zrW-QnJAka6-s0-lOJZRhLx3Fjrv3g',
    id: 8324,
    acronym: 'UEL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ukc.ac.uk',
    name: 'University of Kent at Canterbury',
    web_page: 'http://www.ukc.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIa8rp0US1ZmjkmnfAon47BmeUT61Owp21y8yRZmagZKARQ8ROqjpmYhAV',
    id: 8325,
    acronym: 'UKAC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ulsop.ac.uk',
    name: 'School of Pharmacy University of London',
    web_page: 'http://www.ulsop.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpLBnnaD9KwoxIAnoJt4dGpzOUO4-gNaPPDufvpfyXPj_PsotGlCilHw',
    id: 8326,
    acronym: 'SPUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'ulst.ac.uk',
    name: 'University of Ulster',
    web_page: 'http://www.ulst.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXyIaHDWLMVklxIM53YCgYOxgw8VeflvhKAE5f7HyRJW020GwKDlFUezI',
    id: 8327,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'umds.ac.uk',
    name: 'United Medical and Dental Schools University of London',
    web_page: 'http://www.umds.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRM9272OfOz2zhIc2NUTFLmrLyM5m0xAH6Zoc6RMpkb2dvbe7fAvqtyDhES3Q',
    id: 8328,
    acronym: 'UMDSUL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'unl.ac.uk',
    name: 'University of North London',
    web_page: 'http://www.unl.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSuZaAhQHOMk63Z9UuWRsU_jCvykzfbT0jVdCxNnKuzbKlJL4WbGBVhRc',
    id: 8329,
    acronym: 'UNL',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'unn.ac.uk',
    name: 'University of Northumbria at Newcastle',
    web_page: 'http://www.unn.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgweZj49Gj2ZInrO_Ru_Dkfmv460A7EKYmzc5COxJDOs2LpRbLAxA40MY',
    id: 8330,
    acronym: 'UNAN',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'uwcm.ac.uk',
    name: 'University of Wales College of Medicine',
    web_page: 'http://www.uwcm.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVRZ_ZKRE-nnJwM5k7Wgou9Uf01bQ9Sa7UVN-RV_hiP8IaL43CoRHfwsk',
    id: 8331,
    acronym: 'UWCM',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'uwe.ac.uk',
    name: 'University of the West of England Bristol',
    web_page: 'http://www.uwe.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZukSoYs3LvFBo8znZN2EsIGshkmeqiKeULHyLRE6SOGd7wnQDiBp-Jf8',
    id: 8332,
    acronym: 'UWEB',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'uwic.ac.uk',
    name: 'University of Wales Institute Cardiff',
    web_page: 'http://www.uwic.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU8hllCrGukOX6No__wss_7Kg75s63Yti9wEbRq_tHKET9US5fNluNSA',
    id: 8333,
    acronym: 'UWIC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'wales.ac.uk',
    name: 'University of Wales',
    web_page: 'http://www.wales.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEuONzHGonvwDR6Pl-MZ70CV393_KbbMICUWrne8kvAYSBJ0n4pmFtS_w',
    id: 8334,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'warwick.ac.uk',
    name: 'University of Warwick',
    web_page: 'http://www.warwick.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7WnPFECkgfuf-0MapCQc4lEQ3EAe8cLzV56OwPuqGqVExrDWfh2dv7g',
    id: 8336,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'westminster.ac.uk',
    name: 'University of Westminster',
    web_page: 'http://www.westminster.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPggUCCWwnnWoyKqRNo7hVsmbwszCcdl5j1_KaomS5go0S57H4SAN_mx0',
    id: 8337,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'williamgilbert.co.uk',
    name: 'William Gilbert College',
    web_page: 'http://www.williamgilbert.co.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzCJ9RYdphrLJHFCsfKlLzzd2mnHepLyP0iim83g0B5APXGz8MNL5bLGU',
    id: 8338,
    acronym: 'WGC',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'wimbledon.ac.uk',
    name: 'Wimbledon School of Art',
    web_page: 'http://www.wimbledon.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ3yxvOhVMif77t1Dp6gcWJtMd5gTXnwQWXC9e3sh40t_XDtwanv33ZNg',
    id: 8339,
    acronym: 'WSA',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'wlv.ac.uk',
    name: 'University of Wolverhampton',
    web_page: 'http://www.wlv.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0YHWXGZbj1xsSG4azw7B5ru4u8hdXFPuP8htiIQSw7hv-cZpbSEDJeTjj',
    id: 8340,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'worc.ac.uk',
    name: 'University of Worcester',
    web_page: 'http://www.worc.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB5w9xhR7k03RmNbcQwZaIAPkj6aEVkcCU_Xw3SoATOZ4lSm_EAomGOOI',
    id: 8341,
    acronym: 'UW',
  },
  {
    alpha_two_code: 'GB',
    country: 'United Kingdom',
    domain: 'york.ac.uk',
    name: 'University of York',
    web_page: 'http://www.york.ac.uk/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeTOx3wpWlVIav8UQDHFEWjRt6RlsEFZRxv3tMfgnov4ORLR8Frw0zOW8',
    id: 8342,
    acronym: 'UY',
  },
  {
    alpha_two_code: 'UY',
    country: 'Uruguay',
    domain: 'claeh.edu.uy',
    name: 'Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh',
    web_page: 'http://www.claeh.edu.uy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQum05prmDFor5XsJxoxlM4px1TCLxJ5I8iT2DUfQ6vEFU2OEQIs_vtwX9u',
    id: 8343,
    acronym: 'IU-CLDEH-IC',
  },
  {
    alpha_two_code: 'UY',
    country: 'Uruguay',
    domain: 'ort.edu.uy',
    name: 'Universidad ORT Uruguay',
    web_page: 'http://www.ort.edu.uy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRl7Tc7tPfXQQk8PnVk1KkLOXvItRLOoiYpf2pGcUqQT6HQDql2u01COWA',
    id: 8344,
    acronym: 'UOU',
  },
  {
    alpha_two_code: 'UY',
    country: 'Uruguay',
    domain: 'ucu.edu.uy',
    name: 'Universidad Católica del Uruguay',
    web_page: 'http://www.ucu.edu.uy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzk2RENyYvyXW80MkR7Cm1pHgMRgVIQPVpcA4GctZGEZHpjKVw0gyhFw',
    id: 8345,
    acronym: 'UCDU',
  },
  {
    alpha_two_code: 'UY',
    country: 'Uruguay',
    domain: 'ude.edu.uy',
    name: 'Universidad de la Empresa (UDE)',
    web_page: 'http://www.ude.edu.uy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1ofdusXZm8snHwfP7o050AWfTT4NGA8cL2CJf7EuGG5r48blXtryfNg',
    id: 8346,
    acronym: 'UDLE(',
  },
  {
    alpha_two_code: 'UY',
    country: 'Uruguay',
    domain: 'um.edu.uy',
    name: 'Universidad de Montevideo',
    web_page: 'http://www.um.edu.uy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9x5kORMHN2Wg5Gzr547-yJnElfoUAmE0fUi0O7oafkRVANvTc1zvyPO4',
    id: 8347,
    acronym: 'UDM',
  },
  {
    alpha_two_code: 'UY',
    country: 'Uruguay',
    domain: 'universidad.edu.uy',
    name: 'Universidad de la República',
    web_page: 'http://www.universidad.edu.uy/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-OjMBrJGr-Yevy-KOOoEOQvyYmeQKiDabjBrtAyEt-TxbJg6cjTgQv8o',
    id: 8348,
    acronym: 'UDLR',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'agrar.uz',
    name: 'Tashkent State Agrarian University',
    web_page: 'http://www.agrar.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4AUQJICjOO13x8dtoIORuKmPBT8zyvLmmHRrRDcJeb07URK1g6Cs5lIY',
    id: 8349,
    acronym: 'TSAU',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'ferpi.dem.ru',
    name: 'Ferghana Politechnical Institute',
    web_page: 'http://ferpi.dem.ru/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnkJogMpc-O9jyoaBiPPyCWjL_mrmLN_D62-yP1guVYADo_7socjqSwQ',
    id: 8350,
    acronym: 'FPI',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'ibs.uz',
    name: 'International Business School Kelajak ILMI',
    web_page: 'http://www.ibs.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPY4GlNVzC3ohwLbEhJyCAkwfr-jH6TQH7vSLibwIFmb4qnY3nfKotTgw',
    id: 8351,
    acronym: 'IBSKI',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'mdis.uz',
    name: 'Management Development Institute of Singapore Tashkent',
    web_page: 'http://www.mdis.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbAkqCWMPZ0YniKJyd_rau2aigy2cfoJjUp6DLSTRROnH4jXIiNW2_esFQ',
    id: 8352,
    acronym: 'MDIST',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'ndpi.uz',
    name: 'Nukus State Teachers Training Institute',
    web_page: 'http://www.ndpi.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6kcImgZ0xsHTV-7X2jOYafjhqj-4Oj5yZ9kNwEsfhu0znRTt4lNbt4LY',
    id: 8353,
    acronym: 'NSTTI',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'nuu.uz',
    name: 'National University of Uzbekistan',
    web_page: 'http://www.nuu.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP0_ayqLRjRsJluW0S5HAEzOdcx8nZZScmctp4R2e6uUxjeqMR-sMiHA',
    id: 8354,
    acronym: 'NUU',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'pharmi.uz',
    name: 'Tashkent Pharmaceutical Institute',
    web_page: 'http://www.pharmi.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM1kBoBye0KhQbMgS2ll1K-ZaGnDwySFtFkIGKPswwz6lxklynOfKcag',
    id: 8355,
    acronym: 'TPI',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'polito.uz',
    name: 'Turin Polytechnic University In Tashkent',
    web_page: 'http://www.polito.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdWSV3YukP21maFvAIRtaPOXgtgdbugqrn_sMlMST8m0doM4e3J7Rj4ew',
    id: 8356,
    acronym: 'TPUIT',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'qdpi.uz',
    name: 'Kokand State Pedagogical Institute',
    web_page: 'http://www.qdpi.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcOwDd94eEzKuwP3ncEyKf-4oAcib62fPjk05sE4hOtThJ2hedeqG4290',
    id: 8357,
    acronym: 'KSPI',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'samdu.uz',
    name: 'Samarkand State University',
    web_page: 'http://www.samdu.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo4OFDom1arJMJ7WiPuvCKTfxXE4AhCCBVJvJ2Ue3ADkXNflmUamcFt9lT',
    id: 8358,
    acronym: 'SSU',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tashgiv.uz',
    name: 'Tashkent State University of Oriental Studies',
    web_page: 'http://www.tashgiv.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3BT6uhRKCR-uI3LmPISrHqzRjSxv0V5hV4T-PbpxdHOsisvPRDwKcJOky',
    id: 8359,
    acronym: 'TSUOS',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tayi.uz',
    name: 'Tashkent Automobile and Roads Institute',
    web_page: 'http://www.tayi.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVarLTCL-34jqQ9rJa7xwgm8kb2C0nRB_SoC587OZbMBv05DP7oYJvqA',
    id: 8360,
    acronym: 'TARI',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tdiu.uz',
    name: 'Tashkent State University of Economics',
    web_page: 'http://www.tdiu.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9rm3hK0yYZqAkGvOT6UP8ervazf47qRfMhjlWO1MkmCddgan28HYQg5Y',
    id: 8361,
    acronym: 'TSUE',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tdtu.uz',
    name: 'Tashkent State Technical University',
    web_page: 'http://www.tdtu.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhak3FlPcTMt6WioOaW1HGRuAnb_rfK0iOUNGObVIHyHXcK8RsfdfhH8E',
    id: 8362,
    acronym: 'TSTU',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tfi.uz',
    name: 'Tashkent School of Finance',
    web_page: 'http://www.tfi.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzy4SSTWvXhaZHuHgZ4UnJx_AM9pOXQdnTCCSB80a2wJsBXdQYl6J-nu0',
    id: 8363,
    acronym: 'TSF',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tiim.uz',
    name: 'Tashkent Institute of Irrigation and Melioration',
    web_page: 'http://www.tiim.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq2l5H7DVwoHY8hP-aJM7BKpBwuHLu810XHrVMuaJ0EstryLwlXvbVXg',
    id: 8364,
    acronym: 'TIIM',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tiu.uz',
    name: 'Tashkent Islam University',
    web_page: 'http://www.tiu.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHzIRr1R6codr-ftNTnMqlomHAu67aWXQ88Vmtevwjz8W7SAM2PhnVwBuq',
    id: 8365,
    acronym: 'TIU',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tma.uz',
    name: 'Tashkent Medical Academy',
    web_page: 'http://www.tma.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVVrb0cLPerdw3jWmQGCp8QGdJkaGZUrHpPYFgKDZ78JP3SHZVVViJJyP_',
    id: 8366,
    acronym: 'TMA',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'tuit.uz',
    name: 'Tashkent University of Information Technologies',
    web_page: 'http://www.tuit.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx5gFcTnkcAPjv3aU_OnCP2G2Q_5rt7BgkaWQ5MmI6jWSkG2HFXqoptdo',
    id: 8367,
    acronym: 'TUIT',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'uwed.uz',
    name: 'University of World Economy and Diplomacy',
    web_page: 'http://www.uwed.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqseZ-0Yy9Dh28i-FQh6rtUmsImriDl4DeV55h--lQjFqadecveD4Tqs0',
    id: 8368,
    acronym: 'UWED',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'uzswlu.uz',
    name: 'Uzbek State World Languages University',
    web_page: 'http://www.uzswlu.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDfC1Ptpqyk_NGUn4_bdEcS2SVRsT6whW3GHS7JRNeF_T4vXSPA7mj7Rw',
    id: 8369,
    acronym: 'USWLU',
  },
  {
    alpha_two_code: 'UZ',
    country: 'Uzbekistan',
    domain: 'wiut.uz',
    name: 'Westminster International University in Tashkent',
    web_page: 'http://www.wiut.uz/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-D8kjiLmu6bAKZnd08Thxm5fGtlsP5sn-Ox_U8TW7XhX1lttwgAH7P6z3',
    id: 8370,
    acronym: 'WIUIT',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'luz.ve',
    name: 'Universidad del Zulia',
    web_page: 'http://www.luz.ve/index2.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS05KrnwI05e3_ak8aruh4bEDt54p2aZdqzuxryOss8jZ0G1AUABanBCnE',
    id: 8371,
    acronym: 'UDZ',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'uba.edu.ve',
    name: 'Universidad Bicentenaria de Aragua',
    web_page: 'http://www.uba.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxxWoDX75JdrFFpSBxnFIcwkdmKWqNikK6KM4ftR9XdoVZ4i00_6zqGQ',
    id: 8372,
    acronym: 'UBDA',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'ubv.edu.ve',
    name: 'Universidad Bolivariana de Venezuela',
    web_page: 'http://www.ubv.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpQaYBbh-3_WL-ClDY1YkXHnzM_wXbQbOXg-R5zZeNoj16CpR0EZBbsdQ',
    id: 8373,
    acronym: 'UBDV',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'ucab.edu.ve',
    name: 'Universidad Católica Andres Bello',
    web_page: 'http://www.ucab.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTskw7yyhZrLETqUGH-wON3nOoHW8KKBR8O7EKwVdZOCCv-oG734gqlOcQ',
    id: 8374,
    acronym: 'UCAB',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'ucat.edu.ve',
    name: 'Universidad Católica del Táchira',
    web_page: 'http://www.ucat.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs80AVItYyTs5vkrxj36jVC41lRdeaBD5bO-yfR0uD2t6wosqNPeUCgA',
    id: 8375,
    acronym: 'UCDT',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'uc.edu.ve',
    name: 'Universidad de Carabobo',
    web_page: 'http://www.uc.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQagaI8OPWl8XYrPVBC6IpHBM3oVkluhAfUCZsl6vWa0n_jqV9YS68Qgh8',
    id: 8376,
    acronym: 'UDC',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'ucla.edu.ve',
    name: 'Universidad Centro Occidental Lisandro Alvarado',
    web_page: 'http://www.ucla.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYyCV3tu1lIdgjOCxOZ3T2HFNbPfzBW3sCBIIvqvd777_5tbl77tcnL7kw',
    id: 8377,
    acronym: 'UCOLA',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'ucv.ve',
    name: 'Universidad Central de Venezuela',
    web_page: 'http://www.ucv.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKIDAmBkWjgdu5MfPp_VpiBRLaH84NB89cbQDZVX95dZtKrVTF5fy8450',
    id: 8378,
    acronym: 'UCDV',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'uft.edu.ve',
    name: 'Universidad Fermin Toro',
    web_page: 'http://www.uft.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeVnmHHJy5qBPicYmn40e37qy3gT69od90t0dQ4fHTZnB-3BDCrJY0Vi0',
    id: 8380,
    acronym: 'UFT',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'ugma.edu.ve',
    name: 'Universidad Gran Mariscal de Ayacucho',
    web_page: 'http://www.ugma.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKc2mZ5mee7JbcLQ0D2Z1reIIQpJBls7fDOb8uYYwNBOk93JB4uheE9w0',
    id: 8381,
    acronym: 'UGMDA',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'ujap.edu.ve',
    name: 'Universidad José Antonio Páez',
    web_page: 'http://www.ujap.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5QKmh4fmKIUnP3WOOZhlFSvlXyuv_BE7BbSEhF6PGHZqO4e5ysrT8U88M',
    id: 8382,
    acronym: 'UJAP',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'ujmv.edu',
    name: 'Universidad José Maria Vargas',
    web_page: 'http://www.ujmv.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGtfoYVfaeOcg4HSBJJg11hT7jttpf1Bcmwm4xwZxNf33i6OevKRCUOg',
    id: 8383,
    acronym: 'UJMV',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'una.edu.ve',
    name: 'Universidad Nacional Abierta',
    web_page: 'http://www.una.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhFqnloMiXYqfh-TJ08nr23K2h0QHcAALjUCSHbKTEwlO6Omj4_uS-YKs',
    id: 8385,
    acronym: 'UNA',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'une.edu.ve',
    name: 'Universidad Nueva Esparta',
    web_page: 'http://www.une.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSK86FpdmRizEHVMDWHkJxGrdvXaXB4BHKK9pH9pglBdroPUZhhd0Oo9g',
    id: 8386,
    acronym: 'UNE',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unefa.edu.ve',
    name: 'Universidad Nacional Experimental de la Fuerza Armada',
    web_page: 'http://www.unefa.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSX26MinjPttGNVTAsQaxrh2s3niLKHobzYXa8jav-_Ri6T0YxXjinLA',
    id: 8387,
    acronym: 'UNEDLFA',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unefm.edu.ve',
    name: 'Universidad Nacional Experimental "Francisco de Miranda"',
    web_page: 'http://www.unefm.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOijtDvV1kLz_c6qu-9sBNXJc0yeDBM7lXoriSf5Urk24eHdij8phj2Vw',
    id: 8388,
    acronym: 'UNE"DM',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'uneg.edu.ve',
    name: 'Universidad Nacional Experimental de Guayana',
    web_page: 'http://www.uneg.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvKmfzVGYpOuT-0bUfHQM3a_jNeg3WbLl3FW6doc3GZMh48MrpNeQmPg',
    id: 8389,
    acronym: 'UNEDG',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unellez.edu.ve',
    name: 'Universidad Nacional Experimental de los Llanos Occidentales "Ezequiel Zamora"',
    web_page: 'http://www.unellez.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoa0f7kgnnXNP2AnyEIpEslc_qnxVQt2sD8e7bOF22ECjoJQfpbm1U38U',
    id: 8390,
    acronym: 'UNEDLLO"Z',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unerg.edu.ve',
    name: 'Universidad Nacional Experimental Romulo Gallegos',
    web_page: 'http://www.unerg.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBuGXrFsEoS51wWu4spDV-LMTx0piIXpA1IIPWRvF5EnEa657PkRdX5cl1',
    id: 8391,
    acronym: 'UNERG',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unermb.edu.ve',
    name: 'Universidad Nacional Experimental "Rafael Maria Baralt"',
    web_page: 'http://www.unermb.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaUybkpepf4_J8tmF1uBzw2dDMYqIlJ5aHd-U8uVLDgCdpKBifAqi6UA',
    id: 8392,
    acronym: 'UNE"MB',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unesr.edu.ve',
    name: 'Universidad Nacional Experimental "Simón Rodriguez"',
    web_page: 'http://www.unesr.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3YpAchvcVFDpnE7oxFY31tkFVV-OZ9CzUnmipxJivJu0nGHSr9PtrpXA',
    id: 8393,
    acronym: 'UNE"R',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unesur.edu.ve',
    name: 'Universidad Nacional Experimental Sur del Lago "Jesús Maria Semprum"',
    web_page: 'http://www.unesur.edu.ve/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTVJaYyOOVo1bGOC3j8lhu9feU0FEz99CQuP213Lfp2HXa6nbQiylySMpo',
    id: 8394,
    acronym: 'UNESDL"MS',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unet.edu.ve',
    name: 'Universidad Nacional Experimental del Táchira',
    web_page: 'http://www.unet.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI4dijXJYbS_4BnZ1eZV6xZ63ugkfGYrOAwVdNoVCIc436VuY5hcOJLQ',
    id: 8395,
    acronym: 'UNEDT',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unexpo.edu.ve',
    name: 'Universidad Nacional Experimental Politécnica "Antonio José de Sucre"',
    web_page: 'http://www.unexpo.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiKmA2XP97jc5XhOfbXyZiSeG-0ax0mIz6KmvraleIhOzI6RGataJLgQ',
    id: 8396,
    acronym: 'UNEP"JDS',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unica.edu.ve',
    name: 'Universidad Católica Cecilio Acosta',
    web_page: 'http://www.unica.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3V5MOdTjhAWrvi6LSaMTKqAVQRhWxzrBKbJnbP4rHKVasuIiIsY0oUDI',
    id: 8397,
    acronym: 'UCCA',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'unitec.edu.ve',
    name: 'Universidad Tecnológica del Centro',
    web_page: 'http://www.unitec.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbWRxW67wGdwKACowfMBDHgigIaGc0jUudSlKdrXHu28VxZTtY0ulTlEZc',
    id: 8399,
    acronym: 'UTDC',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'universidad-monteavila.edu.ve',
    name: 'Universidad Monteávila',
    web_page: 'http://www.universidad-monteavila.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrybiXyokuqut5b_vXto91Ymxcly4PUHK-q0l4i0d7YxZcn_jZNmjY-7hs',
    id: 8400,
    acronym: 'UM',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'upel.edu.ve',
    name: 'Universidad Pedagógica Experimental Libertador',
    web_page: 'http://www.upel.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgyBjqnr18_uE48llAnXkB6TiuzlDXJQyP4L-JaPjKqQxeJH1Ys87QUHA',
    id: 8401,
    acronym: 'UPEL',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'urbe.edu',
    name: 'Universidad Dr. Rafael Belloso Chacín',
    web_page: 'http://www.urbe.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1UVTzxo3ubYEMgwjSQHUlifrwNxy6zkiJBf0rKHce9VCzbBxJG49kivc',
    id: 8402,
    acronym: 'UDRBC',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'uru.edu',
    name: 'Universidad Rafael Urdaneta',
    web_page: 'http://www.uru.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCZd3VWWoDrqP2Pv-mxcA2qAsFQkXRhrYWiaJQdESweACWtgEezQcoAoc',
    id: 8403,
    acronym: 'URU',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'usm.trompo.com',
    name: 'Universidad Santa Maria Caracas',
    web_page: 'http://usm.trompo.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt_cmx0oQiYeAuthi0uUNzQ4FxVpNVbUlEspTaJEdPivTNFFUAAnmubRY',
    id: 8405,
    acronym: 'USMC',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'uvm.edu.ve',
    name: 'Universidad Valle del Momboy',
    web_page: 'http://www.uvm.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoiZoZAUu1DOu9xx2TNJZ0A-Y5qFGTEfrgpnJHL6JJIehXkFRjQ_lJUuA',
    id: 8406,
    acronym: 'UVDM',
  },
  {
    alpha_two_code: 'VE',
    country: 'Venezuela, Bolivarian Republic of',
    domain: 'yacambu.edu.ve',
    name: 'Universidad Yacambu',
    web_page: 'http://www.yacambu.edu.ve/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0kgzwRTyycw_UAcSjo8CMlpX5V8BFIfZiqEB6_8IZER8U9p_9ZRsOEA',
    id: 8407,
    acronym: 'UY',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'bdu.edu.vn',
    name: 'Binh Duong University',
    web_page: 'http://www.bdu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlj9eJD3EajuIM2TYWoECHC2j6yLqr9XPofdJcN6ReN1m1AqIcJgp5Ng',
    id: 8408,
    acronym: 'BDU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'buh.edu.vn',
    name: 'Banking University of Ho Chi Minh City',
    web_page: 'http://www.buh.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxhILqmIMNAvWPiRzKUxgTbnJfFKce_K4x1SUirKWpCzVSQ7qID1Ye3g',
    id: 8409,
    acronym: 'BUHCMC',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'ctu.edu.vn',
    name: 'Can-Tho University',
    web_page: 'http://www.ctu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSITvkTvX0kVWQ6EzYkuPcHVYJXKfA_NG7BwzeLqOyEJfCaAaGl7l0ZrQ',
    id: 8410,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'dct.udn.vn',
    name: 'Danang College Of Technology',
    web_page: 'http://www.dct.udn.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWNt-_cYPOIzVImea_RtduSzKpXTyWe4yK4XILEuq143nsJH8ck1cHpg',
    id: 8411,
    acronym: 'DCT',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'dhxd.edu.vn',
    name: 'Hanoi University of Civil Engineering',
    web_page: 'http://www.dhxd.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrNUjIP6Bd8LTOcbMxp5N2Go5AElmCZ-U51MFRQVNYonCYB_FhAdtNnQ',
    id: 8412,
    acronym: 'HUCE',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'dlu.edu.vn',
    name: 'University of Da Lat',
    web_page: 'http://www.dlu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjsKgiUSnPJSPNBgOgY5QXJyihhn3BGJVmmL2nCnCR-VtSIBGfxW3UOd7B',
    id: 8413,
    acronym: 'UDL',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'fpt.edu.vn',
    name: 'FPT University',
    web_page: 'http://www.fpt.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSGf51MzcGKRBKMtUkmGKlZCTvoLMzR9yZdjXgQCV4x_ppKSmHAVpw8QM',
    id: 8414,
    acronym: 'FU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'ftu.edu.vn',
    name: 'Foreign Trade University',
    web_page: 'http://www.ftu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVczIeO2m1LvPC59QKHXChj5fRced6WTdFwjkeuaQfLBn_PrCp8fEg6Q',
    id: 8415,
    acronym: 'FTU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hau.edu.vn',
    name: 'Hanoi University of Architecture',
    web_page: 'http://www.hau.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpATuFefNGtLjqPdc_vWNd6r_cdpMd6LfMIVGo7xSF-JxG481dDOX1V34',
    id: 8416,
    acronym: 'HUA',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmuaf.edu.vn',
    name: 'Ho Chi Minh City University of Agriculture and Forestry',
    web_page: 'http://www.hcmuaf.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREih33zUARrIgQ10dNIT4CcHNJtmOK6z5YN61_GhUlAItXIWWsYyYzWp4',
    id: 8417,
    acronym: 'HCMCUAF',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmuarc.edu.vn',
    name: 'Ho Chi Minh City University of Architecture',
    web_page: 'http://www.hcmuarc.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wGZWONCxFRCp7XSLgWexckydKhN9jFDl_NzUA8iLPpP5aeauAtSXNA',
    id: 8418,
    acronym: 'HCMCUA',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmulaw.edu.vn',
    name: 'Ho Chi Minh City University of Law',
    web_page: 'http://www.hcmulaw.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wGZWONCxFRCp7XSLgWexckydKhN9jFDl_NzUA8iLPpP5aeauAtSXNA',
    id: 8419,
    acronym: 'HCMCUL',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmuns.edu.vn',
    name: 'Ho Chi Minh City University of Natural Sciences',
    web_page: 'http://www.hcmuns.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS83Vy15fEdMMAevf-xu4aXeM_0OBJqZi_4w7w31sYNImvb7AomcN2m6A',
    id: 8420,
    acronym: 'HCMCUNS',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmupeda.edu.vn',
    name: 'Ho Chi Minh City University of Pedagogics',
    web_page: 'http://www.hcmupeda.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdUfc6JB_-OMyNLGZ8A3NRwUgL1eTXPFrQ5Vytm9OnQpsttbWWwo_9p1Rt',
    id: 8421,
    acronym: 'HCMCUP',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmussh.edu.vn',
    name: 'Ho Chi Minh City University of Social Sciences and Humanities',
    web_page: 'http://www.hcmussh.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6OoMygVxKHg92lCox3R12wknbfBqOpsCUkgmPMEPr_RS3tTI_TQOhzJM',
    id: 8422,
    acronym: 'HCMCUSSH',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmut.edu.vn',
    name: 'Ho Chi Minh City University of Technology',
    web_page: 'http://www.hcmut.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5PX1M4LJtJk_zT5xaRwqN0MH8Ic9Z_bDikOZ1bhpAeuy3zHn1pM0oCQ',
    id: 8423,
    acronym: 'HCMCUT',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmute.edu.vn',
    name: 'University of Technical Education Ho Chi Minh City',
    web_page: 'http://www.hcmute.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5PX1M4LJtJk_zT5xaRwqN0MH8Ic9Z_bDikOZ1bhpAeuy3zHn1pM0oCQ',
    id: 8424,
    acronym: 'UTEHCMC',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hcmutrans.edu.vn',
    name: 'Ho Chi Minh City University of Transport',
    web_page: 'http://www.hcmutrans.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5DI2EBnC7_kpTHudTC4eEiTQoxC2JjteZyikr17YxAg4HdEInwhuXEjSG',
    id: 8425,
    acronym: 'HCMCUT',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hmu.edu.vn',
    name: 'Hanoi Medical University',
    web_page: 'http://www.hmu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ_RqGDhvSKPxOAvG1cYGC3t567fXBWSxQHXuRycgMOFnceL9Jlc3ajCYw',
    id: 8426,
    acronym: 'HMU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hou.edu.vn',
    name: 'Hanoi Open University',
    web_page: 'http://www.hou.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRA7wqkSveJ7qaqxq-vMALL92AT6ZpZtFb2mYp74ECQTEXaZYfojFJag2MX',
    id: 8427,
    acronym: 'HOU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hua.edu.vn',
    name: 'Hanoi University of Agriculture',
    web_page: 'http://www.hua.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPmlSyZvGUqBXPPX9khKqA8s08svetzwMmkWA13dHbVEwEOpG0NNnlHw',
    id: 8428,
    acronym: 'HUA',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'huaf.edu.vn',
    name: 'Hue University of Agriculture and Forestry',
    web_page: 'http://www.huaf.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsZ2jGL5VmkOM5-rMR6y_68PFVC5xl5CVy37SAvaQblBnCZ8trv8IWgA',
    id: 8429,
    acronym: 'HUAF',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hueuni.edu.vn',
    name: 'Hue University',
    web_page: 'http://www.hueuni.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQj-i89lOKntqRQBuo6go0HDlVuFvOon7sA1FYP4BRYJqiF2tBKnfDgVuSt',
    id: 8430,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'huflit.vnn.vn',
    name: 'Ho Chi Minh City University of Foreign Languages and Information Technology',
    web_page: 'http://www.huflit.vnn.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfdsvpme7tvkOLUTVDFlzhNU8ac29sD5JJ8m702ZvUkgMMERDqKq-s0fQ',
    id: 8431,
    acronym: 'HCMCUFLIT',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'humg.edu.vn',
    name: 'Hanoi University of Mining and Geology',
    web_page: 'http://www.humg.edu.vn/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQ2hsFCZj3fAZNSpnefs9CtD30mRINnhFl2_AuJcLnabRZSjpCedbRwp7Y',
    id: 8432,
    acronym: 'HUMG',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hus.edu.vn',
    name: 'Hanoi University of Science',
    web_page: 'http://www.hus.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzFfpLSYyUvgB8Ask3jn3bj14gUvviNrOaQGva_wp5_BygW2VSH8bAhNE',
    id: 8433,
    acronym: 'HUS',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hut.edu.vn',
    name: 'Hanoi University of Technology',
    web_page: 'http://www.hut.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzFfpLSYyUvgB8Ask3jn3bj14gUvviNrOaQGva_wp5_BygW2VSH8bAhNE',
    id: 8434,
    acronym: 'HUT',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hvtc.edu.vn',
    name: 'Institute of Finance',
    web_page: 'http://www.hvtc.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRARbAC3F3a_SJ48m_5M3sDAIB169VFiZN_V49CBvnd6a0dndO4WwiDUw',
    id: 8435,
    acronym: 'IF',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'hwru.edu.vn',
    name: 'Water Resources University',
    web_page: 'http://www.hwru.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS10NQLISikdWD4iDLOP5s20ZgdGyKdTmcfNLym0rkcqtufG0GFNMq_Dmk',
    id: 8436,
    acronym: 'WRU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'ktkt-haiduong.edu.vn',
    name: 'Hai Duong University of  Economics and Technology',
    web_page: 'http://www.ktkt-haiduong.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQC76VzmwOWM6lKT5zR1em2Sre30SPyrQZNWZwnoKdpAMbBi6Po-6C2VA',
    id: 8437,
    acronym: 'HDUET',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'neu.edu.vn',
    name: 'Hanoi National Economics University',
    web_page: 'http://www.neu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPmlSyZvGUqBXPPX9khKqA8s08svetzwMmkWA13dHbVEwEOpG0NNnlHw',
    id: 8438,
    acronym: 'HNEU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'ou.edu.vn',
    name: 'Ho Chi Minh City Open University',
    web_page: 'http://www.ou.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wGZWONCxFRCp7XSLgWexckydKhN9jFDl_NzUA8iLPpP5aeauAtSXNA',
    id: 8439,
    acronym: 'HCMCOU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'ptit.edu.vn',
    name: 'Posts & Telecommunications Institute of Technology',
    web_page: 'http://www.ptit.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT98ss3QfjTwbXhl7oKjLDe62GQQow9i7RiERNOX-O094gNHjUmHHXv0QY',
    id: 8440,
    acronym: 'P&TIT',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'rmit.edu.vn',
    name: 'RMIT International University Vietnam',
    web_page: 'http://www.rmit.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHCk2DRTvQ_cZ0kACXokuwWw2jGkuyvLAyiYzhc2d_KiZLsKoibO6CFn0',
    id: 8441,
    acronym: 'RIUV',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'sgu.edu.vn',
    name: 'Saigon University',
    web_page: 'http://www.sgu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wGZWONCxFRCp7XSLgWexckydKhN9jFDl_NzUA8iLPpP5aeauAtSXNA',
    id: 8442,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'taynguyenuni.edu.vn',
    name: 'Tay Nguyen University',
    web_page: 'http://www.taynguyenuni.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShSF75r3WtB3d63XDjCcf7XeGUxXge8abABu2z8aTGTl5WOXGo1D7IoX4',
    id: 8443,
    acronym: 'TNU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'tuaf.edu.vn',
    name: 'Thai Nguyen University of Agriculture and Forestry',
    web_page: 'http://www.tuaf.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkxVES8VO6gfPYdo0S3277uUW4DlEjIr9r_Sx96j_TymbrPpa0qQcLdzumdg',
    id: 8444,
    acronym: 'TNUAF',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'uct.edu.vn',
    name: 'Hanoi Univerisity of Transportation',
    web_page: 'http://www.uct.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCGR86dEXR5cZlKXUEHNbmpTplz2R7xdTXmxIsxgs1aJW9o06uaQDulw',
    id: 8445,
    acronym: 'HUT',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'ud.edu.vn',
    name: 'University of Da Nang',
    web_page: 'http://www.ud.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWNt-_cYPOIzVImea_RtduSzKpXTyWe4yK4XILEuq143nsJH8ck1cHpg',
    id: 8446,
    acronym: 'UDN',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'ueh.edu.vn',
    name: 'Ho Chi Minh City University of Economics',
    web_page: 'http://www.ueh.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5DI2EBnC7_kpTHudTC4eEiTQoxC2JjteZyikr17YxAg4HdEInwhuXEjSG',
    id: 8447,
    acronym: 'HCMCUE',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'vimaru.edu.vn',
    name: 'Vietnam Maritime University',
    web_page: 'http://www.vimaru.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnN_dkOGA3XOu1RZLfe400FZldQq4sUhJC6gWkpfMzNF2_pib_AD8jK88',
    id: 8448,
    acronym: 'VMU',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'vnu.edu.vn',
    name: 'Vietnam National University Hanoi',
    web_page: 'http://www.vnu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPmlSyZvGUqBXPPX9khKqA8s08svetzwMmkWA13dHbVEwEOpG0NNnlHw',
    id: 8449,
    acronym: 'VNUH',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'vnuhcm.edu.vn',
    name: 'Vietnam National University Ho Chi Minh City',
    web_page: 'http://www.vnuhcm.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wGZWONCxFRCp7XSLgWexckydKhN9jFDl_NzUA8iLPpP5aeauAtSXNA',
    id: 8450,
    acronym: 'VNUHCMC',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'yds.edu.vn',
    name: 'Ho Chi Minh City University of Medicine and Pharmacy',
    web_page: 'http://www.yds.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7wGZWONCxFRCp7XSLgWexckydKhN9jFDl_NzUA8iLPpP5aeauAtSXNA',
    id: 8451,
    acronym: 'HCMCUMP',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'pnt.edu.vn',
    name: 'Pham Ngoc Thach University of Medicine',
    web_page: 'http://www.pnt.edu.vn',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2XAdsnHE_7Ok95XjJ3YRKZDsnw0DLSKonVLzq_DCgOeFTMTmh9rKkys3B',
    id: 8452,
    acronym: 'PNTUM',
  },
  {
    alpha_two_code: 'VN',
    country: 'Viet Nam',
    domain: 'vgu.edu.vn',
    name: 'Vietnamese - German University',
    web_page: 'http://www.vgu.edu.vn/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmbi34oQv7xY2llAPkN69NsmkJNlsYm2qYD7LB1GqBEjWy7ZSp7QCquhU',
    id: 8453,
    acronym: 'V-GU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'adenuniversity.edu.ye',
    name: 'University of Aden',
    web_page: 'http://www.adenuniversity.edu.ye/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvbVJvPYdjVaforPk7NRX6jSpvGotnHNcgZYi1yILzigdYzLazx_mhiQk',
    id: 8455,
    acronym: 'UA',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'ahgaff.edu',
    name: 'Alahgaff University',
    web_page: 'http://www.ahgaff.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOvkmm6feSBItILzb3oEISUBfzhR_cqSQtDmREcE9ZT98yVpMO23QUKOnR',
    id: 8456,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'al-edu.com',
    name: 'Al-Nasser University',
    web_page: 'http://www.al-edu.com/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffwQKoK_3TtsL5xaLuqEjHTPgnObb5KboZoQo7a4lnBkZAXjNxSOdFA',
    id: 8457,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'arwauniversity.org',
    name: 'Queen Arwa University',
    web_page: 'http://www.arwauniversity.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCbNLYranZzDARmS3eiQL2jFMrZ9d-j5KNStF3Cr2GJa9mgG0VfN2RmVU',
    id: 8458,
    acronym: 'QAU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'eman-univ.edu.ye',
    name: 'Al-Eman University',
    web_page: 'http://www.eman-univ.edu.ye/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnj26RFSGxc4H58fzqvdt8pCybJInNCjiLUb5bi_281WmHNKywUNe2TSQ',
    id: 8459,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'hoduniv.net.ye',
    name: 'Hodeidah University',
    web_page: 'http://www.hoduniv.net.ye/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXza95O8WD1r51SqfKcVH2YdDWw4UgIzR5zum7mqZ3rfo_U9o6sEE5aA',
    id: 8460,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'hust.edu.ye',
    name: 'Hadhramout University of Science and Technology',
    web_page: 'http://www.hust.edu.ye/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBZeFxA08bsa7m0W7awPVVBb55_KAIt19WU8ZdLmQHjpwJE9_y_FE8aHEe',
    id: 8461,
    acronym: 'HUST',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'sabauni.net',
    name: 'Saba University',
    web_page: 'http://www.sabauni.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz8HXqDz_QSVtipyUEKvdeqmM2fv7cBPwfEWDFwbklD3OLRS1LQRkuX_8',
    id: 8462,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'su.edu.ye',
    name: "Sana'a University",
    web_page: 'http://www.su.edu.ye/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-s1ddDAEIeiLxqAMtn1Ng_R2X8bgT8NArYHULoNDfEIziaxSW2XvuSWY',
    id: 8463,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'taizun.net',
    name: 'Taiz University',
    web_page: 'http://www.taizun.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcHktWmB3Ilae2P3ZbCNsyn--72dkekxQo1-xhls7s64LyHk1elpJaVPw',
    id: 8464,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'thuniv.net',
    name: 'Thamar University',
    web_page: 'http://www.thuniv.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQV97_yfM0xEj5F--6xDX-HZvevCyJ36kUFP5pIg1ZDZzW19K240xAPdg',
    id: 8465,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'ust.edu',
    name: "University of Science and Technology Sana'a",
    web_page: 'http://www.ust.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-s1ddDAEIeiLxqAMtn1Ng_R2X8bgT8NArYHULoNDfEIziaxSW2XvuSWY',
    id: 8466,
    acronym: 'USTS',
  },
  {
    alpha_two_code: 'YE',
    country: 'Yemen',
    domain: 'yuniv.net',
    name: 'Yemenia University',
    web_page: 'http://www.yuniv.net/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQe-UJ49zLdHIS1e9Svzb92ctMobwqUB5bLea24_UJgh2a-gM3FfsxQIy8',
    id: 8467,
    acronym: 'YU',
  },
  {
    alpha_two_code: 'ZM',
    country: 'Zambia',
    domain: 'cavendishza.org',
    name: 'Cavendish University',
    web_page: 'http://www.cavendishza.org/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjdokUPhiQDcqbbV1NRmgyZ60hSo4fU7ZTBMc8ZLx3iDP9vhxNuL0UlTE',
    id: 8468,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'ZM',
    country: 'Zambia',
    domain: 'cbu.edu.zm',
    name: 'Copperbelt University',
    web_page: 'http://www.cbu.edu.zm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm54GREwbkGHg-CKYqDnR0bbP_yWHs1ei1jxYhd1JKN7Au8OL0zyPRFw',
    id: 8469,
    acronym: 'CU',
  },
  {
    alpha_two_code: 'ZM',
    country: 'Zambia',
    domain: 'mu.ac.zm',
    name: 'Mulungushi University',
    web_page: 'http://www.mu.ac.zm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVBxV1LPd-o527DKIMwRC4mICIuJdos1i-lt777jDFdv86P5HQj6PwjoE',
    id: 8470,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'ZM',
    country: 'Zambia',
    domain: 'rusangu-university.edu.zm',
    name: 'Rusangu University',
    web_page: 'http://www.rusangu-university.edu.zm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRao1N1hyM-EdHLCETFCkxhwSCObkoIhFrjNZM2-sm2MmFvz7GEPArDTUM',
    id: 8471,
    acronym: 'RU',
  },
  {
    alpha_two_code: 'ZM',
    country: 'Zambia',
    domain: 'unilus.ac.zm',
    name: 'University of Lusaka',
    web_page: 'http://www.unilus.ac.zm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2Jn-xmhj8xQlzrZc4DonlpFe1fMg7lqXLKP9a-Nxms2Zr6TD--805XgM',
    id: 8472,
    acronym: 'UL',
  },
  {
    alpha_two_code: 'ZM',
    country: 'Zambia',
    domain: 'unza.zm',
    name: 'University of Zambia',
    web_page: 'http://www.unza.zm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9tPQlIwf2n2EObRGWlIbKEgi8iUq3O8PXEhPR4A05N5XJB2hkE0dYxh5DBg',
    id: 8473,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'ZM',
    country: 'Zambia',
    domain: 'zaou.ac.zm',
    name: 'Zambian Open University',
    web_page: 'http://www.zaou.ac.zm/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ77LKKT0doaSA_NIZcboQmQFwDmxuEKzypxNjaF88xsVtxgh05jcSnP7vl',
    id: 8474,
    acronym: 'ZOU',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'africau.edu',
    name: 'Africa University',
    web_page: 'http://www.africau.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9FReQDeyYYCnG1F6zE6XXs53a_RRj_AdZKMgAprwvr6QMPoYBlgEhgw',
    id: 8475,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'buse.ac.zw',
    name: 'Bindura University of Science Education',
    web_page: 'http://www.buse.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdjGCw41uAlIZJBHnwc8HYyiUKnbvWNnfCGT6G35t1Pt_4OiyZOZBv_Oo',
    id: 8476,
    acronym: 'BUSE',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'cut.ac.zw',
    name: 'Chinhoyi University of Technology',
    web_page: 'http://www.cut.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFplNDg9U5cMY6yi2HypeIore-wf6PV-0try9kQDg4_AX9j0PCJjp4xNOx',
    id: 8477,
    acronym: 'CUT',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'cuz.ac.zw',
    name: 'Catholic University in Zimbabwe',
    web_page: 'http://www.cuz.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNtAiIjrH9G-kQtno8Wr4akHhs4XzaH_OyTFMPlgCyqF1N6q37l0Q-qB0A',
    id: 8478,
    acronym: 'CUIZ',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'gzu.ac.zw',
    name: 'Great Zimbabwe University',
    web_page: 'http://www.gzu.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHv1iuF6y5z1pUvKcA3fwsuRW3c96oVwxi-gS2olQyF1RZaysMVR9FCxk',
    id: 8479,
    acronym: 'GZU',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'hit.ac.zw',
    name: 'Harare Institute of Technology',
    web_page: 'http://www.hit.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYNNBtAIrWeo1QplmvJvb7r6gwxuf3ea5TFG0XSWlErfjm6HFVLRcu1u8',
    id: 8480,
    acronym: 'HIT',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'lsu.ac.zw',
    name: 'Lupane State University',
    web_page: 'http://www.lsu.ac.zw/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcT2DnwW4hU0rUjKQIoC5ku8zQjfCYH5L7ob5oUjatLxbB6IW5z4iSq0vBw',
    id: 8481,
    acronym: 'LSU',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'msu.ac.zw',
    name: 'Midlands State University',
    web_page: 'http://www.msu.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMKA4-tQvZPALznDwY7q8lnW2LEC-tZ1gJJ8GRVmBxwAdZEZuqPOqzzd4',
    id: 8482,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'nust.ac.zw',
    name: 'National University of Science and Technology Bulawayo',
    web_page: 'http://www.nust.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLKh6NY_A7xgyCHlDTs8pP_PqzyEC93gZD1CAuDYKVdsgTnrMM-9LKdQ',
    id: 8483,
    acronym: 'NUSTB',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'rcu.ac.zw',
    name: 'Reformed Church University',
    web_page: 'http://www.rcu.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYbzS8NSLEi-TnpeEx0sroqnjoUe-UdDZKCY2ToU-qVkqjJy-_dJafb_4',
    id: 8484,
    acronym: 'RCU',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'solusi.ac.zw',
    name: 'Solusi University',
    web_page: 'http://www.solusi.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSagNJl97bXK4xdaNUk0nvY7PXRzdCTRU-m6qwYlux43-sBFtwGmOwTfaA',
    id: 8485,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'uz.ac.zw',
    name: 'University of Zimbabwe',
    web_page: 'http://www.uz.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSO32Yh9srszGUbhLhwGolvIwwgR2hmTQzR7iBby7z5geKHQFB_clOaUw',
    id: 8486,
    acronym: 'UZ',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'wua.ac.zw',
    name: "Women's University in Africa",
    web_page: 'http://www.wua.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXtg-FWyj97rMR_X3GJT1oz-nA7qlHJRKgOtHiwIRqdLVTgtfY-rgYw8no',
    id: 8487,
    acronym: 'WUIA',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'zegu.ac.zw',
    name: 'Zimbabwe Ezekiel Guti University',
    web_page: 'http://www.zegu.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQehAMqb_RRN-yPPfJddVmcxMUZiDmGzgtdqxuJyCo6vo05LswJ2dEMxA',
    id: 8488,
    acronym: 'ZEGU',
  },
  {
    alpha_two_code: 'ZW',
    country: 'Zimbabwe',
    domain: 'zou.ac.zw',
    name: 'Zimbabwe Open University',
    web_page: 'http://www.zou.ac.zw/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9mPxTKavrDRrMvNT9yW98HDXYCDcGa0YrUOG--Tmql_KJdgxNhk_5YA',
    id: 8489,
    acronym: 'ZOU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'agu.edu.tr',
    name: 'Abdullah Gul University',
    web_page: 'http://www.agu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRggS35POruhIV337rb-D_4H1RlbF7xgUzLZtqmzDwW-ktm3qqBIv9GQaw',
    id: 8490,
    acronym: 'AGU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'adanabtu.edu.tr',
    name: 'Adana Science and Technology University',
    web_page: 'http://www.agu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpQ1AvA3-ofPuRbDMfcE9V_uPaXkb76PVr_jK-7vkOtaqtVA8vhjsiCg',
    id: 8491,
    acronym: 'ASTU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'adiyaman.edu.tr',
    name: 'Adiyaman University',
    web_page: 'http://www.adiyaman.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr7ntYdQ1x_eueiE3wk6pa4wnXHuce_ZVR6Jzn7HOllPZzkEMHiLrH0ETxOg',
    id: 8492,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'agri.edu.tr',
    name: 'Agri İbrahim Cecen University',
    web_page: 'http://www.agri.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk-2wylQU7hI7Q3pWc0tmjeCP2bV9hxcbkN4UaIkIWq9LC-1b8FkqRta6FYw',
    id: 8493,
    acronym: 'AICU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'aksaray.edu.tr',
    name: 'Aksaray University',
    web_page: 'http://www.aksaray.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjhRF6zBVKIis7cPpdBzxEJbLfnGlhORsJXJE8AIheYAaFj8PeXh53ww',
    id: 8494,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'alanyaaku.edu.tr',
    name: 'Alanya Alaaddin Keykubat University',
    web_page: 'http://www.alanyaaku.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR70da1s8b18Vvy2_lLSuDh4bQInlnYFOgn7bLztbrvrDBvKMHcYjmi2MFc',
    id: 8495,
    acronym: 'AAKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'amasya.edu.tr',
    name: 'Amasya University',
    web_page: 'http://www.amasya.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtsrSR4n9ZWwjUBFGruxhJ-TcC28cT2bLqNI9pPnSAnSkeQadxDk489Fo',
    id: 8496,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'asbu.edu.tr',
    name: 'Ankara Social Science University',
    web_page: 'http://www.asbu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwXCYLcaz0HSZH2v1k0Mc6XB97IyLMA8wUI2vQfZwsWINCi8VoS44PXQ',
    id: 8497,
    acronym: 'ASSU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ardahan.edu.tr',
    name: 'Ardahan University',
    web_page: 'http://www.ardahan.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_559qtVS2vnUKkJjSijX7EkqzfpMRbMycE67TCOhn5_bl7xcuVvGZ5g',
    id: 8498,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'artvin.edu.tr',
    name: 'Artvin Coruh University',
    web_page: 'http://www.artvin.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgFMbd35zU_Zi7aYIn643FsKTlAzt2XL_hRtHY7kaXU5RQO7R_iflfR5m7',
    id: 8499,
    acronym: 'ACU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bandirma.edu.tr',
    name: 'Bandirma ONYEDİ Eylul University',
    web_page: 'http://www.bamdirma.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmelXKPL0ns733HGDXuhsCMkpvW3aKyVpvSlPRy8f-EkZ7fvDPql_dX9Y',
    id: 8500,
    acronym: 'BOEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bartin.edu.tr',
    name: 'Bartin University',
    web_page: 'http://www.bartin.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-bKgvraGO-tqqCI6tZ8rCWsduydCK29eygcnUnAZHTFDs5H6v9HwkKw',
    id: 8501,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'batman.edu.tr',
    name: 'Batman University',
    web_page: 'http://www.batman.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuYmGymMei0h7owohYIukd3CePW-OqCZ59QMZY4gt3HEjLgD8qlBao6zk',
    id: 8502,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bayburt.edu.tr',
    name: 'Bayburt University',
    web_page: 'http://www.bayburt.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKLBuXWLor-KmAeYNJG5LnP2NfSJB6iGgZfqa4KJUdfcX0bCMpOt2fqcQ',
    id: 8503,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bilecik..edu.tr',
    name: 'Bilecik Seyh Edebali University',
    web_page: 'http://www.bilecik.edu.tr/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcTb3LM0maIcgdLNw3cwHkzLlcrA1Wr0kUiIOAyPoT7N0pKoDn3lAgXI00W3',
    id: 8504,
    acronym: 'BSEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bingol.edu.tr',
    name: 'Bingol University',
    web_page: 'http://www.bingol.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYlTvMM0kPcZp9N25E26xdCuSggHZhtn9wH3HegJKmiaVwIgPLjmc4yg',
    id: 8505,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'beu.edu.tr',
    name: 'Bitlis Eren University',
    web_page: 'http://www.beu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQptJI8ct7soQOO1dbtm5VkrLquMflw9ExaDX4sSmQeyxX3-0Fch_eaFRk',
    id: 8506,
    acronym: 'BEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bozok.edu.tr',
    name: 'Bozok University',
    web_page: 'http://www.bozok.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV0C154968qisVfigXdTpTsFz5U9sUieejk74AY1jC-2NfgUA0CN-jBVE',
    id: 8507,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'btu.edu.tr',
    name: 'Bursa Tecnical University',
    web_page: 'http://www.btu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQofxomO69DM9BGCPDw1UZLNwGRUZsew8WjdS61kPh0OO-EiPJ8Qd6TOQY',
    id: 8508,
    acronym: 'BTU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'karatekin.edu.tr',
    name: 'Cankiri karatekin University',
    web_page: 'http://www.karatekin.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiM0K3cwx9n5QZ4n8Ymm266xk_rhwZ17wDaafZEH7y-HZcRMfBnC5S_SA',
    id: 8509,
    acronym: 'CKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'duzce.edu.tr',
    name: 'Duzce University',
    web_page: 'http://www.duzce.edu.tr/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcR3eaYCp3vIEnu7qYyN_2XI73tkm7FOQmUP3LRmtct687q7x1djfONRXIU',
    id: 8511,
    acronym: 'DU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'erzincan.edu.tr',
    name: 'Erzincan University',
    web_page: 'http://www.erzincan.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN5xq-4OlYL-pfIej-ctI_caNmFrN2Wwm7WxgjcFRaHEW5ilQ9Dt_BMA',
    id: 8512,
    acronym: 'EU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'erzurum.edu.tr',
    name: 'Erzurum Tecnical University',
    web_page: 'http://www.erzurum.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ74kN7GwQKfZVrzPDftRbTblK697toY6IA2TmbBlWzGWI4quPePzUUaQ',
    id: 8513,
    acronym: 'ETU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gtu.edu.tr',
    name: 'Gebze Tecnical University',
    web_page: 'http://www.gtu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThiKbL0w2crBzPYN3bw96v7JX9aiZFW8mKvoMjklT21Z8XLBELLVkpZg',
    id: 8514,
    acronym: 'GTU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gata.edu.tr',
    name: 'Gulhane military medical Academy',
    web_page: 'http://www.gata.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLGp-x2VBdFtx4GSy4u_FrKSjL3g2r2gJ7Bzur2aUmbVKZEqKzA91WDIFR',
    id: 8515,
    acronym: 'GMMA',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gumushane.edu.tr',
    name: 'Gumushane University',
    web_page: 'http://www.gumushane.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQAx352_10mWksJR-kw3uR3m6CKEGfS1ZANuZoEK6y17Un3s4n_DUGo1Q',
    id: 8516,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'hakkari.edu.tr',
    name: 'Hakkari University',
    web_page: 'http://www.hakkari.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZofXD3ApjRN7DIFbHmaAlEvkyr6UZ2eOsn6wG43rMLneyq4cmfxUGoqE',
    id: 8517,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'hitit.edu.tr',
    name: 'Hitit University',
    web_page: 'http://www.hitit.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFIUx6IAZkG9WqRV4awnto4zYrEOqrnFfglOOXVASCw5qSARFSYhWHjeeq',
    id: 8519,
    acronym: 'HU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'igdir.edu.tr',
    name: 'İgdir University',
    web_page: 'http://www.igdir.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyE04SryzIVhEww-yRcbwNbdFuSAsHh1tW_-bv8_NnW1dqSMYKcF3LmQ',
    id: 8520,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'iste.edu.tr',
    name: 'İskenderun Tecnical University',
    web_page: 'http://www.iste.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFAdsvxePVdRV0SSf52oUYuQgybzrFtOnGFWUSKJ4OGny7UuYOmjVAWg',
    id: 8521,
    acronym: 'ITU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'medeniyet.edu.tr',
    name: 'Istanbul Medeniyet University',
    web_page: 'http://www.medeniyet.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxhEf_Ilsc4gJ4ndRrdGRjTlBGBNT7y1wsB2NXAfQy_XNppzerV6Zfrw',
    id: 8522,
    acronym: 'IMU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'istanbul.edu.tr',
    name: 'İstanbul University',
    web_page: 'http://www.istanbul.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_QsFnWduD0gXZM-dIHSihvwMKgAthd9bOGzUf_OcK62XjgbQJHUZKxG-DQg',
    id: 8523,
    acronym: 'IU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ikc.edu.tr',
    name: 'İzmir Katip celebi University',
    web_page: 'http://www.ikc.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrh_KXTYDOUzQ63vgdhKjKlGT5qkWoKLamx5oC5MFAXJvDmivAM--AOGE',
    id: 8524,
    acronym: 'IKCU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'karabuk.edu.tr',
    name: 'Karabuk University',
    web_page: 'http://www.karabuk.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlruq5bJSUKld6ZnPYeA9vgKtKwMAUccJ2osY5Np4XfPYzFt2_koWP_g',
    id: 8525,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'kmu.edu.tr',
    name: 'karamanoglu mehmet bey University',
    web_page: 'http://www.kmu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsnKoADBeIZqXaca4l-HzKTg65DnnreADJyLKBh2gdLabSC-XRawJh-Jc',
    id: 8526,
    acronym: 'KMBU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'kastamonu.edu.tr',
    name: 'Kastamonu University',
    web_page: 'http://www.kastamonu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO1hRCUzlMUCwlW3knVqkHJDxByC4Rd95FZ3HWYxI42oDNiv97FCpw6qo',
    id: 8527,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'kirklareli.edu.tr',
    name: 'Kirklareli University',
    web_page: 'http://www.kirklareli.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-8d5CqLcSA3qkMemn5fQax7h0typ8Jl-j8fEGdeoLpFRTOhALvYPNHPI',
    id: 8528,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'artuklu.edu.tr',
    name: 'Mardin Artuklu University',
    web_page: 'http://www.artuklu.edu.tr/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQ-QdY_bFhdNljqMA5LCsGYmzLaSZqC9QUfaWaxYEl5OT4WPHer6bKhbw',
    id: 8529,
    acronym: 'MAU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'mehmetakif.edu.tr',
    name: 'Mehmet Akif Ersoy University',
    web_page: 'http://www.mehmetakif.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlyirV0ad6dUtG0V0LEIf9yLZfbUc-GV7Si6rLUIzQnzhWx76wZn-LQkI',
    id: 8530,
    acronym: 'MAEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'msgsu.edu.tr',
    name: 'Mimar Sinan Fine Arts University',
    web_page: 'http://www.msgsu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKhE57IPyAE1fD7WVIQstxDBZwgnmBFiUIXtiZp2izvh2YVnxcBHJkcX4',
    id: 8531,
    acronym: 'MSFAU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'alparslan.edu.tr',
    name: 'Mus Alparslan University',
    web_page: 'http://www.alparslan.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcHktWmB3Ilae2P3ZbCNsyn--72dkekxQo1-xhls7s64LyHk1elpJaVPw',
    id: 8532,
    acronym: 'MAU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'nevsehir.edu.tr',
    name: 'Nevsehir Haci Bektas Veli University',
    web_page: 'http://www.nevsehir.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRls19tBhl1f_w13S7ZonlHj4xeeaUspw2gTpfx2QnX-xANls9V5JfpRVo',
    id: 8533,
    acronym: 'NHBVU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'odtu.edu.tr',
    name: 'Ortadogu Tecnical University',
    web_page: 'http://www.odtu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScSYUnj8YXSbb5vGN2-j-gDWKoGecfk_DuoeTRJCfn2WUViiVEpOFlzTk',
    id: 8534,
    acronym: 'OTU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'osmaniye.edu.tr',
    name: 'Osmaniye Korkut Ata University',
    web_page: 'http://www.osmaniye.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQljMPa-wwhl8_TuXPQYFaeslucQTSzkSuIM3jpwluFKj8XMusc5L43wNY',
    id: 8535,
    acronym: 'OKAU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'erdogan.edu.tr',
    name: 'Recep Tayip Erdogan University',
    web_page: 'http://www.erdogan.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSml37xuzlxUlvWcKghWhDdAaq-J1MYutETzy_qWwZaTpbjAHIJ-15XnQ',
    id: 8536,
    acronym: 'RTEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'siirt.edu.tr',
    name: 'Siirt University',
    web_page: 'http://www.siirt.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnH60j1ht_DKMXyJSd6txm-QlNwf_9qrCrVxUw-nD5N1rgpKfvHcoeew',
    id: 8537,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'sinop.edu.tr',
    name: 'Sinop University',
    web_page: 'http://www.sinop.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQECKfMS1D0NnnzZsTB046sVWAHnOr5SZ6QbiG9n6M8OfDeNFwqI2TshA',
    id: 8538,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'sirnak.edu.tr',
    name: 'Sirnak University',
    web_page: 'http://www.sirnak.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZFeJc49jxAsP-9WTIfDV43kA__54a7RHuWnLuqWI_vrRS06JUWhRwCg',
    id: 8539,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'tunceli.edu.tr',
    name: 'Tunceli University',
    web_page: 'http://www.tunceli.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYQxWw4gTWVa3ipYKX_MZStFYgo4o18G6NSf3NjpwzugLp-hUAXNpE1RQ',
    id: 8540,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'sbu.edu.tr',
    name: 'Health Sciences University',
    web_page: 'http://www.sbu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSX8HozsHx-SP5PK1Cg6VfRRzeozc-hMo9oYGbo5I1k8XFdFRHEaPo_Q',
    id: 8541,
    acronym: 'HSU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'usak.edu.tr',
    name: 'Usak University',
    web_page: 'http://www.usak.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCfTyrP5337RY7QaHRfBqd2oB3CYhVd5kL7t1C9t_c480P4GC8CEdWW3M',
    id: 8542,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'beun.edu.tr',
    name: 'Bulent Ecevit University',
    web_page: 'http://www.beun.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsnznwxFYYuRpU9nEFxmgj29m5rrVc6KYJgfdQnQQ3_2pHRDa_mGQJ_o0',
    id: 8543,
    acronym: 'BEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'acibadem.edu.tr',
    name: 'Acibadem University',
    web_page: 'http://www.acibadem.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTcDl0OecQ2GS0U_NiQFWaUgzzd1loDxfexS1kU0fTEkdy4kMzyWbsV9k',
    id: 8544,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ahap.edu.tr',
    name: 'Alanya Hamdullah Emin Pasa University',
    web_page: 'http://www.ahap.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGMNhC639kfU4qiplTuD0OIVhdixK_YAp5YGsgS2DARqm2uHBgER92kPo',
    id: 8545,
    acronym: 'AHEPU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'avrasya.edu.tr',
    name: 'Avrasya University',
    web_page: 'http://www.avrasya.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMDWY-UlSI_TjYaFM8gUg2JbyYUQ1PpG2E3kBmKQQoVSCULBI4uZZstPcH',
    id: 8546,
    acronym: 'AU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'bezmialem.edu.tr',
    name: 'Bezmialem Vakif University',
    web_page: 'http://www.bezmialem.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4yHnpQ47TiBy20vYnBtbdAMMVPvnolPtmxynn_VCKoqZAN-jU8ZuWpw',
    id: 8547,
    acronym: 'BVU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'biruni.edu.tr',
    name: 'Biruni University',
    web_page: 'http://www.biruni.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkNWiPervbHUR1fMFr12hXZnac2WiYUolxpdO7W0upkch66H2y7bse3n8',
    id: 8548,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'fatihsultan.edu.tr',
    name: 'Fatih Sultan Mehmet University',
    web_page: 'http://www.fatihsultan.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOBH9Hq-OhSyb9ey13Zai83xxfjSWCMfCCbwI9QGq8P1-O54aTq-iC70seDw',
    id: 8549,
    acronym: 'FSMU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gedik.edu.tr',
    name: 'Gedik University',
    web_page: 'http://www.gedik.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzYWdTVPGfTyVBVM471qk8zRZs87W9bllWL9bcB4pUbJ_GUh8Md8KrEw',
    id: 8550,
    acronym: 'GU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'hku.edu.tr',
    name: 'Hasan Kalyoncu University',
    web_page: 'http://www.hku.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOpZ-ju_Y42K1HmmDIwBWgwGMuX80fklqKnwBlptG8IZiBoaVEvbPwJ7M',
    id: 8551,
    acronym: 'HKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: '29mayis.edu.tr',
    name: 'İstanbul 29Mayis  University',
    web_page: 'http://www.29mayis.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrMAfRHLDrWyolpdp6zbAmVP92g1q0GzYp_GcfIB_c2eXhubfpWy5GQMY',
    id: 8552,
    acronym: 'I2U',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'aydin.edu.tr',
    name: 'Istanbul Aydin University',
    web_page: 'http://www.aydin.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJXcxXLGGP27yGr0ZrUyCPbCfTKdZ2kUxNnihv-3VNKfm_F-bt-2kE3xY',
    id: 8553,
    acronym: 'IAU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'esenyurt.edu.tr',
    name: 'Istanbul Esenyurt University',
    web_page: 'http://www.esenyurt.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvWvHeMElwwuDZ3-ndJT9kZ7FDf22xURnVb-4tngUW3n7bqBzwnroJorcj_g',
    id: 8554,
    acronym: 'IEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gelisim.edu.tr',
    name: 'Istanbul Gelisim University',
    web_page: 'http://www.gelisim.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7pOqaTSE6-lXQXe1_YG6kuwx0vUHHDXOENddhPSpkBmrqrcA1UQxBxLs',
    id: 8555,
    acronym: 'IGU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'kemerburgaz.edu.tr',
    name: 'Istanbul Kemerburgaz  University',
    web_page: 'http://www.kemerburgaz.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTkMaaPDOgfYhCZXrd23Biy3zZdl53b4WJp6C3LXZfEg__CK9zp1BNh0waEw',
    id: 8556,
    acronym: 'IKU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'medipol.edu.tr',
    name: 'Istanbul Medipol University',
    web_page: 'http://www.medipol.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6AacQ9CT2gUIwevObbh5PFzrW6uxh4M-0UCvU252AqIw5DUtEzchXON4',
    id: 8557,
    acronym: 'IMU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'rumeli.edu.tr',
    name: 'Istanbul Rumeli University',
    web_page: 'http://www.rumeli.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlOiwkoDsUl3Bx5oarGMMr7HlQOZmicbSg6ebA-bKBT58FFDTfx0xrwjA',
    id: 8558,
    acronym: 'IRU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'iszu.edu.tr',
    name: 'Istanbul Sabahattin Zaim University',
    web_page: 'http://www.iszu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEdW34E_zN2MT1lm4tmQ6RBVjGz40G6TwSWNtHnJGunzLUHoOXzRkOBeM',
    id: 8559,
    acronym: 'ISZU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'ieu.edu.tr',
    name: 'Izmir Economy University',
    web_page: 'http://www.ieu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGxfNOT97DdAZy-enNC3vlbiKdlA72dTqgPxfwX9Ay4VKrh4ijbzmQ',
    id: 8560,
    acronym: 'IEU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'karatay.edu.tr',
    name: 'Karatay University',
    web_page: 'http://www.karatay.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHTZ4kXsWei52UaqI3Db_1Y8rWlYHfz5bKlbejys1spESk4NeEzUyrwF4',
    id: 8561,
    acronym: 'KU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'gidatarim.edu.tr',
    name: 'Konya Gida Tarim University',
    web_page: 'http://www.gidatarim.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCocpdwnG_H8Yd6BApcCwXrMCAKLErqtGJJ_QeRes6t34XLnU7BPJUc1M',
    id: 8562,
    acronym: 'KGTU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'mef.edu.tr',
    name: 'MEF University',
    web_page: 'http://www.mef.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWZJQ_3Qr13PVIE3Hi67HcBPYOrimku4fiwAWfXsUW6Bak3qImIZb10g',
    id: 8563,
    acronym: 'MU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'nisantasi.edu.tr',
    name: 'Nisantasi University',
    web_page: 'http://www.nisantasi.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3ffaD3ysyyl_PWXRdfynB2aDXdVCHRpGwagsfsDVEBjqeAlb_THU3RQ',
    id: 8564,
    acronym: 'NU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'nny.edu.tr',
    name: 'Nuh Naci Yazgan University',
    web_page: 'http://www.nny.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQk8J4J-T03LFH2yi-Oiw1GpqcDK9gLM3OmaRuR-KR_HLjnx_r0sbUMIntW',
    id: 8565,
    acronym: 'NNYU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'okan.edu.tr',
    name: 'Okan University',
    web_page: 'http://www.okan.edu.tr/',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQBaqD4tWWM0JKUp5aFLkTUSW5NM1kGoDhOVeKggFqRpa_R1ldT1EA38g',
    id: 8566,
    acronym: 'OU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'pirireis.edu.tr',
    name: 'Piri Reis University',
    web_page: 'http://www.pirireis.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYfM1mgXaf36x4OZ2yNWW9ThWJ7JJUeeB3E0z8HfgB1m2Sk6jprDoQog',
    id: 8567,
    acronym: 'PRU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'sanko.edu.tr',
    name: 'Sanko University',
    web_page: 'http://www.sanko.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB2MC7hbNS99MCzA35vPdPo9dKHhGaCxDFCa6Sir1C_HwXWEWCHLauzH0',
    id: 8568,
    acronym: 'SU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'tedu.edu.tr',
    name: 'TED University',
    web_page: 'http://www.tedu.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkaWFjgBA_gkvoDYKanRxO8R3Bb7D1QSQmBkxU9NQmg4bKQ3yZ4QFb7Q',
    id: 8569,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'toros.edu.tr',
    name: 'Toros University',
    web_page: 'http://www.toros.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAbpKSIXKzkLthfZJDsdIc4dzRvdSllf50t2maaMf8_ixL-XMYBCo6sQ',
    id: 8570,
    acronym: 'TU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'thk.edu.tr',
    name: 'Turkish Aeronautical Association University',
    web_page: 'http://www.thk.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcHktWmB3Ilae2P3ZbCNsyn--72dkekxQo1-xhls7s64LyHk1elpJaVPw',
    id: 8571,
    acronym: 'TAAU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'antalya.edu.tr',
    name: 'Antalya international University',
    web_page: 'http://www.antalya.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrm-IH0TUGBMVPzlMmgGc0hqGpR6ubuUFoaPjPV-Kgs3jIX2jc81EPQw',
    id: 8572,
    acronym: 'AIU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'uskudar.edu.tr',
    name: 'Uskudar University',
    web_page: 'http://www.uskudar.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShNz4MzY4V1snicYoZicdIhDxTuTSZDcDfZh2RAAE_Dh43cQHJUjNkyC9vaw',
    id: 8573,
    acronym: 'UU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'yeniyuzyil.edu.tr',
    name: 'Yeni Yuzyil University',
    web_page: 'http://www.yeniyuzyil.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIJ-vqIeAumuwyWg47EQOGontHpBXEas8Sbvj2o4d3grkS5TwTWjKQLls',
    id: 8574,
    acronym: 'YYU',
  },
  {
    alpha_two_code: 'TR',
    country: 'Turkey',
    domain: 'yuksekihtisasuniversitesi.edu.tr',
    name: 'Yuksek ihtisas University',
    web_page: 'http://www.yuksekihtisasuniversitesi.edu.tr/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRGMQsxtGyEYfrC8KQSG1eTHN_DXPnPIigB13xyCzuKRmzrOt_L3anCcw',
    id: 8575,
    acronym: 'YIU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ascc.edu',
    name: 'Alabama Southern Community College',
    web_page: 'http://www.ascc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSImL6evyy9O24kdiqvx08Bcg9mrM6cswMcbHvQjYv0-G2CD4e9fXVsyQ',
    id: 8576,
    acronym: 'ASCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bscc.edu',
    name: 'Bevill State Community College',
    web_page: 'http://www.bscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8AqoHfS1ETU1-TSp2OcFL2gVM2_yLOwatsRjYmT15ClpmkrFM--tmbg',
    id: 8577,
    acronym: 'BSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bishop.edu',
    name: 'Bishop State Community College',
    web_page: 'http://www.bishop.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSImL6evyy9O24kdiqvx08Bcg9mrM6cswMcbHvQjYv0-G2CD4e9fXVsyQ',
    id: 8578,
    acronym: 'BSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cacc.edu',
    name: 'Central Alabama Community College',
    web_page: 'http://www.cacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYDa2u5W8IOwaL8loZx36C-eIXIQRgSKwdXBjOZ6jVsL0T23rizdKXTQ',
    id: 8579,
    acronym: 'CACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cv.edu',
    name: 'Chattahoochee Valley Community College',
    web_page: 'http://www.cv.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6pGIaCwaG58RU2yWwu09K_8J9xuU1c01-gn-iKj8x069Kxbs1fDxq8A',
    id: 8580,
    acronym: 'CVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'escc.edu',
    name: 'Enterprise State Community College',
    web_page: 'http://www.escc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBMHUcxko5pJQgR3K_A60A7xhzBK9dYypjdWcL5igS3REzTMtcsLIT8ZY',
    id: 8581,
    acronym: 'ESCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gadsdenstate.edu',
    name: 'Gadsden State Community College',
    web_page: 'http://www.gadsdenstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7bnStWbj3fC-qri51vXXLpATvJ8u9JBpc8Qzd3dsmZY_ms62nV6uW66c',
    id: 8582,
    acronym: 'GSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wallace.edu',
    name: 'George C Wallace State Community College-​Dothan',
    web_page: 'http://www.wallace.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCO2UV9Fk5gVfgeMB3q-f5dg90yh8m_BGAyRFNYPaHZfURkrO4rxNAgQ',
    id: 8583,
    acronym: 'GCWSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wallacestate.edu',
    name: 'George C Wallace State Community College-​Hanceville',
    web_page: 'http://www.wallacestate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsS86qXWS7Ar8KxL6XaHRLSvzgw03wmFGMePBEYI05m6ZvQru_cADd32M',
    id: 8584,
    acronym: 'GCWSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wccs.edu',
    name: 'George C Wallace State Community College-​Selma',
    web_page: 'http://www.wccs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkREPZqD7qAJgHiHNIKc8MfBYH14KK1F9HOv7Cw1r-lTgDBBTtW1cTUw',
    id: 8585,
    acronym: 'GCWSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'trenholmstate.edu',
    name: 'H Councill Trenholm State Technical College',
    web_page: 'http://www.trenholmstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbuSUAh2YMaztSMcjzyJZ1fjEeDPIHCmOsIDl5EdlDNrgk7Al_oFSMtA',
    id: 8586,
    acronym: 'HCTSTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'faulknerstate.edu',
    name: 'James H Faulkner State Community College',
    web_page: 'http://www.faulknerstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEFD4xyDLIj3qztibkq7_QcSzQqmE45bhdOXFZQ_v5_NlnXS-owrSVxg',
    id: 8587,
    acronym: 'JHFSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jdcc.edu',
    name: 'Jefferson Davis Community College',
    web_page: 'http://www.jdcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVWOvN4oCzSt0oQT6bgMRATixQGRzkZqr7SF9xrFsKpfRYLZiY1CextWHR',
    id: 8588,
    acronym: 'JDCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jeffstateonline.com',
    name: 'Jefferson State Community College',
    web_page: 'http://www.jeffstateonline.com',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMofZpIn5K7aL0a_fjBUBuzugeVbalq6oLeM5o1WFKtO99zY3g12zYkB4',
    id: 8589,
    acronym: 'JSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'drakestate.edu',
    name: 'J F Drake State Community and Technical College',
    web_page: 'http://www.drakestate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlDtJnlftX1x2wrAE-mOIo_E5y8N8p5kBFqPdSs3oWy3e9zaktxy9qE30',
    id: 8590,
    acronym: 'JFDSCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'calhoun.edu',
    name: 'John C Calhoun State Community College',
    web_page: 'http://www.calhoun.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQW9PYUSqLefT2crHNmsXhm4u6BPLZwHjVJocMiT7UcUWUSQK75v27zcg',
    id: 8591,
    acronym: 'JCCSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lbwcc.edu',
    name: 'Lurleen B Wallace Community College',
    web_page: 'http://www.lbwcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBDqY8nBe5IJs882p4860D2LbNxnFdxnjlXND_QGDziNn5eitSFOQVcQ',
    id: 8592,
    acronym: 'LBWCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marionmilitary.edu',
    name: 'Marion Military Institute',
    web_page: 'http://www.marionmilitary.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5in_wipSuvJjkgDuWyH75LmvngWOfUnOoW7OzqERH9pNuRuFloLQH60w',
    id: 8593,
    acronym: 'MMI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nacc.edu',
    name: 'Northeast Alabama Community College',
    web_page: 'http://www.nacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQy9JWxAL15UKSuR0gtK23C_9023kfIyMPAY0BL10DWhF0jxl9A3-_bw',
    id: 8594,
    acronym: 'NACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwscc.edu',
    name: 'Northwest-​Shoals Community College',
    web_page: 'http://www.nwscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiZwiAljAMz9dtO7A4CDIenLPdzNErgrgBtBvFRHzDidBssQYs93KUow',
    id: 8595,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sheltonstate.edu',
    name: 'Shelton State Community College',
    web_page: 'http://www.sheltonstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBMHUcxko5pJQgR3K_A60A7xhzBK9dYypjdWcL5igS3REzTMtcsLIT8ZY',
    id: 8596,
    acronym: 'SSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'snead.edu',
    name: 'Snead State Community College',
    web_page: 'http://www.snead.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTorXzZSAX53dIdQ-36CX__Qn-8pJP-qBBSGujD7Q9ArNTJM7pC7HWQbn8',
    id: 8597,
    acronym: 'SSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'suscc.edu',
    name: 'Southern Union State Community College',
    web_page: 'http://www.suscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-qCbYJrckuzwhrv4ClyzeTeDaGTg_pt6gKDaHVpTH48pBtzvK1e43Cg',
    id: 8598,
    acronym: 'SUSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ilisagvik.edu',
    name: 'Ilisagvik College',
    web_page: 'http://www.ilisagvik.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4PrLZxb4OFX24OpXmnhGaEcF9vTQLMSGY-ha9ong_8lUQ8bRvVPZZAw',
    id: 8599,
    acronym: 'IC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pwscc.edu',
    name: 'Prince William Sound Community College',
    web_page: 'http://www.pwscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdr6CrJGZrsO6-cw6BWLNqUvM37U_HSMALA_e98eE8kbDC-lGwXd8vMN0',
    id: 8600,
    acronym: 'PWSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'centralaz.edu',
    name: 'Central Arizona College',
    web_page: 'http://www.centralaz.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxm1juUfwdNAMs3YlE-PcE42jJ71rt9xYZoWAvmxa9Q3jesXYU5BASaw',
    id: 8601,
    acronym: 'CAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cgc.maricopa.edu',
    name: 'Chandler-​Gilbert Community College',
    web_page: 'http://www.cgc.maricopa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTf8TIo7EHI5BmruWGVDUd0Jx3xBUtgyOLZH8HPVd42Hq8fX4ZSVhTSlA',
    id: 8602,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cochise.edu',
    name: 'Cochise College',
    web_page: 'http://www.cochise.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo43P4i6zwCvn0A291JXOKu3bYRdDw-w7yv2FMc5a0uRQRJPc3yyD98mQ',
    id: 8603,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coconino.edu',
    name: 'Coconino Community College',
    web_page: 'https://www.coconino.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS56Y13rZxjIAi_kkP5hasDQ9qux_lKyW4rv5lfYplkS_kcKyc6q3GfLQ0',
    id: 8604,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eac.edu',
    name: 'Eastern Arizona College',
    web_page: 'http://www.eac.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy9QQ5KUI9BD0R1ocwWHMfrqj3rRYTBN8rWKbGrdOC_n0h82Fzzunitg',
    id: 8605,
    acronym: 'EAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'emc.maricopa.edu',
    name: 'Estrella Mountain Community College',
    web_page: 'http://www.emc.maricopa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-AzAPn8N4yeaQ5wuJF3hC-Z5QL3rkCWkuJRPrOULCpBaVxE852Jm5ov41',
    id: 8606,
    acronym: 'EMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gatewaycc.edu',
    name: 'GateWay Community College',
    web_page: 'http://www.gatewaycc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvAiEbHwEd2oB_qnmgiYiIBNZl3p2kDS08cPnvAlQFMmdqnw-OzT4JKpc',
    id: 8607,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gccaz.edu',
    name: 'Glendale Community College',
    web_page: 'http://www.gccaz.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5XqoOo_mDUm5qsP_GALEOGmi3rxLtTF9JE657FOq5L6YsGLqZqXzCxg',
    id: 8608,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mesacc.edu',
    name: 'Mesa Community College',
    web_page: 'http://www.mesacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTx7kaEIBrO4F9xfmRX3yEYUPh0J-k91oSwLFsl0eBe0Tl5oo2GVlYmWNI',
    id: 8609,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mohave.edu',
    name: 'Mohave Community College',
    web_page: 'http://www.mohave.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2lpGfWXaj8ZRbDhTy9xQ33g_BCR2luSv7RiNidCjAc-r-84CCRIl1MNw',
    id: 8610,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'npc.edu',
    name: 'Northland Pioneer College',
    web_page: 'http://www.npc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvkoemv-e1envx08eMCmBQFOyMD6ozSG1kfU8egc2opyM39rUf2KN1S4g',
    id: 8611,
    acronym: 'NPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pvc.maricopa.edu',
    name: 'Paradise Valley Community College',
    web_page: 'http://www.pvc.maricopa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThWAr5ASd9-nCq1tAbj0aX7VIYc9EIaxNodvyweuQe9y-kI7Uw6MOqK68',
    id: 8612,
    acronym: 'PVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'phoenixcollege.edu',
    name: 'Phoenix College',
    web_page: 'http://www.phoenixcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYWnqseZe81R8xOztKwgGyrSwD0vItvlhg4jxU0Zp8CINHKp2ywDWKGGst',
    id: 8613,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pima.edu',
    name: 'Pima Community College',
    web_page: 'http://www.pima.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTfQxYmgxBYB9XMzaAUtGjYGVqk1BVY031Ijzeg6kpKWUf7mxZk7e_ng',
    id: 8614,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rio.maricopa.edu',
    name: 'Rio Salado College',
    web_page: 'http://www.rio.maricopa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtQt_5iguxfQIO-5iY6mmPA_hmOVQq__HV_2tws7NZca3rijBC-EwXAg',
    id: 8615,
    acronym: 'RSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'scottsdalecc.edu',
    name: 'Scottsdale Community College',
    web_page: 'http://www.scottsdalecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAP4jBIitpuDIqUGglhBB1aPy8zp0t1qzQlv_9baKP4ncwlnLmng9f3hs',
    id: 8616,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southmountaincc.edu',
    name: 'South Mountain Community College',
    web_page: 'http://www.southmountaincc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwe5ToihwkqWriC4tXKA9RaUNReRPdVKC_5YkFpUPvGDwBfsX98W_Nr1U',
    id: 8617,
    acronym: 'SMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tocc.edu',
    name: "Tohono O'Odham Community College",
    web_page: 'http://www.tocc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIhm8I4EXJeIT5MjLvFjmcKs429VYus4BMj6QH4k5w9aerkl3_pMgdAw',
    id: 8618,
    acronym: 'TOCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'yc.edu',
    name: 'Yavapai College',
    web_page: 'http://www.YC.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF0dovM7JewLBShFHreojcSUH_ofFmMEf23epkeawc_Pdt5FGOU8IbrQU',
    id: 8619,
    acronym: 'YC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'anc.edu',
    name: 'Arkansas Northeastern College',
    web_page: 'http://www.anc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzQ2nUg_RpSy_5qkZlxLQmuvTlyqi49GO4vC0fGCIum6vC6rzZKa2bQ',
    id: 8620,
    acronym: 'ANC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'asub.edu',
    name: 'Arkansas State University-​Beebe',
    web_page: 'http://www.asub.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNHwkPQ7VabY2hhUy9J3r_Gia_rMIfTlUxIXYsKqsU8t7zMy27RFFvOg',
    id: 8621,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'asumh.edu',
    name: 'Arkansas State University-​Mountain Home',
    web_page: 'http://www.asumh.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwL7n5lCx2TZaChN7O56vyqiYlwSQg12Yd9i80AddUszb-v8tBRG3Kuw',
    id: 8622,
    acronym: 'ASUH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'asun.edu',
    name: 'Arkansas State University-​Newport',
    web_page: 'http://www.asun.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDaIiMSPXK8677B-I9ZrFSPOHwmt8ZmV1WTe6e3vQbSpHTZL6qY7remHc',
    id: 8623,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'blackrivertech.edu',
    name: 'Black River Technical College',
    web_page: 'http://www.blackrivertech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRomHlQ0JCccp3op0NhuawA358QSk1DoKI4gCRlnhcLFw8zkkU9kflX4Uw',
    id: 8624,
    acronym: 'BRTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coto.edu',
    name: 'College of the Ouachitas',
    web_page: 'http://www.coto.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6l2NZey44Zv7JQ_7d8PVujwP1VbmGLe8kL_QG9citsoxVexlzMmZluw',
    id: 8625,
    acronym: 'CO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cccua.edu',
    name: 'Cossatot Community College of the University of Arkansas',
    web_page: 'http://www.cccua.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxW49EUp2rh_GJ9fZYb42WfPZIXK9Irre__uSchW0tuGs4Qf6I6j3pWA',
    id: 8626,
    acronym: 'CCCUA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.eacc.edu',
    name: 'East Arkansas Community College',
    web_page: 'http://WWW.EACC.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzQ2nUg_RpSy_5qkZlxLQmuvTlyqi49GO4vC0fGCIum6vC6rzZKa2bQ',
    id: 8627,
    acronym: 'EACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'midsouthcc.edu',
    name: 'Mid-​South Community College',
    web_page: 'http://www.midsouthcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtQBGxO3ru7YEIjsg7hGep7f3hc8t2i8JCZqUbwAT-FidmbUVbjY1lbXw',
    id: 8628,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'npcc.edu',
    name: 'National Park Community College',
    web_page: 'http://www.npcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZzQ2nUg_RpSy_5qkZlxLQmuvTlyqi49GO4vC0fGCIum6vC6rzZKa2bQ',
    id: 8629,
    acronym: 'NPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northark.edu',
    name: 'North Arkansas College',
    web_page: 'http://www.northark.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqbgo5lhP8W7SlsUT83F-s3RZB9CnT9MJT7o79-YYuiItrrSkjvsINXRgd',
    id: 8630,
    acronym: 'NAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwacc.edu',
    name: 'NorthWest Arkansas Community College',
    web_page: 'http://www.nwacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbcpW3WKmNQWxSpquJ4owBNtj-U_Gi4KJ2xoaoC79WmqsIJ0Y-gKIPGBY',
    id: 8631,
    acronym: 'NACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ozarka.edu',
    name: 'Ozarka College',
    web_page: 'http://www.ozarka.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw9bfgIhtSMv15L11vAzSoDh3P5ZS2jW9MV2pT2_n4g4qbnAg4Kq0T_fk',
    id: 8632,
    acronym: 'OC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pccua.edu',
    name: 'Phillips Community College of the University of Arkansas',
    web_page: 'http://www.pccua.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCrpwnRjcODrJuK_LRizxugilZkDv8f6etL1JHwvffoNcNFyztSu-5tQ',
    id: 8633,
    acronym: 'PCCUA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pulaskitech.edu',
    name: 'Pulaski Technical College',
    web_page: 'http://www.pulaskitech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaEEBfVTcu3yyB_OxHcpnK7-JB9mg6_1BBFiIltEQjdTtC44r5zSaSxBg',
    id: 8634,
    acronym: 'PTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rmcc.edu',
    name: 'Rich Mountain Community College',
    web_page: 'http://www.rmcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToN8lFXe3E4t9Cmx6Y7Eii_8a_PBXjna6jOrY1F9wafpuX3NOx1_O3pQ',
    id: 8635,
    acronym: 'RMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southark.edu',
    name: 'South Arkansas Community College',
    web_page: 'http://www.southark.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtQBGxO3ru7YEIjsg7hGep7f3hc8t2i8JCZqUbwAT-FidmbUVbjY1lbXw',
    id: 8636,
    acronym: 'SACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'seark.edu',
    name: 'Southeast Arkansas College',
    web_page: 'http://www.seark.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_5XYgIa2WdzK5I9q3_Wdhdr94pebiwq-0Hr9b1-w2gFMnLaJ7DlDAe34',
    id: 8637,
    acronym: 'SAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sautech.edu',
    name: 'Southern Arkansas University Tech',
    web_page: 'http://www.sautech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJjpG-TB64WcrtKg3g3XnJgNacQWOF-UwkZl_M0SrxTEkbiUzHP2OqsWw',
    id: 8638,
    acronym: 'SAUT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uaccb.edu',
    name: 'University of Arkansas Community College-​Batesville',
    web_page: 'http://www.uaccb.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAoJRk532HRklho1LsZx_9RR47Tv7h3vjXTJEEhnbzmhmOBSm5WOsleOM',
    id: 8639,
    acronym: 'UACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uacch.edu',
    name: 'University of Arkansas Community College-​Hope',
    web_page: 'http://www.uacch.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRWVj3F-4CTDOAPxicSj9c6ms3lV3vkQEZGlYA3TM_pjNII4hc5Vik3g',
    id: 8640,
    acronym: 'UACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uaccm.edu',
    name: 'University of Arkansas Community College-​Morrilton',
    web_page: 'http://www.uaccm.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoQRs_r9BBEoNvy3KSG_-pkpFG9qygmhdCkGFZbZiqiTNALeZ-K2yO8ohq',
    id: 8641,
    acronym: 'UACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hancockcollege.edu',
    name: 'Allan Hancock College',
    web_page: 'http://www.hancockcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkc1TGyU5_PY1NUgHv3hkYaLcVFkkTD0VpG0VIPwYkoy6tmuFTLdVE460',
    id: 8642,
    acronym: 'AHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'arc.losrios.edu',
    name: 'American River College',
    web_page: 'http://www.arc.losrios.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREXeOnWjpWdDgDjSAff2JWF7n-WGs2Uv3w5q6yjmhQaOEnXnhnq79hCQ',
    id: 8643,
    acronym: 'ARC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'avc.edu',
    name: 'Antelope Valley College',
    web_page: 'http://www.avc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUSnHGWw5UfuVhJyTh28tq4svPSHT2mJI5sfpOJjU7YkoxSXze6r7KDde1',
    id: 8644,
    acronym: 'AVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bakersfieldcollege.edu',
    name: 'Bakersfield College',
    web_page: 'http://www.bakersfieldcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKr6XScNsrXzlbyn49pryvqgrm5FqXZ-WnKVtfXRzTrVJVa0VhrvV_rQ',
    id: 8645,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'barstow.edu',
    name: 'Barstow Community College',
    web_page: 'http://www.barstow.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvm5MFnCKgCtIfGrOf0VVucPnitGEjwlrDvrxIl6v3BfP9EITd9Tw88xI',
    id: 8646,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'berkeleycitycollege.edu',
    name: 'Berkeley City College',
    web_page: 'http://www.berkeleycitycollege.edu/wp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwktZ4_tF8ZQsB4JvhjTARaV1MvTuesKcNRdx7LSt4LIXO3d_plw-tTlo',
    id: 8647,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'butte.edu',
    name: 'Butte College',
    web_page: 'http://www.butte.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSYmifuBOIY1bsnUi0Wia01k407BfbWV0rdPK8XsrZagMi8OUMAH9MgQ',
    id: 8648,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cabrillo.edu',
    name: 'Cabrillo College',
    web_page: 'http://www.cabrillo.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_iiGkPDU7_JYdz-JFFjap99OVNGcvoqNWoYd35Cb8cXKXmmn79zbwDQ',
    id: 8649,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'canadacollege.edu',
    name: 'Canada College',
    web_page: 'http://canadacollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR086ULSrBTzToKiPTbrva076oR5uuZmi1BdaX9w7w72eEt8N1VAdyQAQ',
    id: 8650,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carrington.edu',
    name: 'Carrington College California-​Sacramento',
    web_page: 'http://www.carrington.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUEiiwVdHrt5P3tqQ1jv3A_cY4uzLcJPOWyEfhE6xi9FwuNmbYrZ-LWw',
    id: 8651,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cerritos.edu',
    name: 'Cerritos College',
    web_page: 'http://www.cerritos.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTurhFTk9RuzTnCjcpOdsqpISY5a46AKqGioMfakL2qxGbAJIda0r5XeMg',
    id: 8652,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cerrocoso.edu',
    name: 'Cerro Coso Community College',
    web_page: 'http://cerrocoso.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR58wNcaIZL7-Zqnja-0IthoWoml74xpHqBWRM9jA9qb1nUthh1ATqnLw',
    id: 8653,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chabotcollege.edu',
    name: 'Chabot College',
    web_page: 'http://www.chabotcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZsPhFXsBeHIPP7rVL5LHVUjtMMpTsCIPmmkT169ZZYLpJmkV1prvluMA',
    id: 8654,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chaffey.edu',
    name: 'Chaffey College',
    web_page: 'http://www.chaffey.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBVimuUUNbhpQ83DDJuJDGgktA12JMvVI101OnllV5txBhfvsvuk0FrAc',
    id: 8655,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'citruscollege.edu',
    name: 'Citrus College',
    web_page: 'http://www.citruscollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6ct-pQfqt8OCXY0XnDzSkQ121O5OxxqgSxth5D8kPzgNmrvCctbjX',
    id: 8656,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccsf.edu',
    name: 'City College of San Francisco',
    web_page: 'http://www.ccsf.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4zzGCTcc_HPV63W-6UMG9FQA6Rm-JMqwMvMou_q_iMeLepEVLMeKm4g',
    id: 8657,
    acronym: 'CCSF',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coastline.edu',
    name: 'Coastline Community College',
    web_page: 'http://www.coastline.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjYJNu70qspu-8JY7voeMTGtvLyrGF9pd3o2zj0vAMW2GuJg5O7vF2Ew',
    id: 8658,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'peralta.edu',
    name: 'College of Alameda',
    web_page: 'http://www.peralta.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQE5ZujobiZadQl6_551e8JJPYxC9BcE3rnJldNtQFoyRhfMoLMBjvoO9c',
    id: 8659,
    acronym: 'CA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marin.edu',
    name: 'College of Marin',
    web_page: 'http://www.marin.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWQUraBzg9LPIn4bFllwMMjGRWqnwwbLRhSQwreeJrxHdSmxY8bFGExl8',
    id: 8660,
    acronym: 'CM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'collegeofsanmateo.edu',
    name: 'College of San Mateo',
    web_page: 'http://www.collegeofsanmateo.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaUvhv1cfkEIUkjj_1PDi0yzdYaRoIWsYFFEE0zNY7p6zqX_3FlNoA5Q',
    id: 8661,
    acronym: 'CSM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'canyons.edu',
    name: 'College of the Canyons',
    web_page: 'http://www.canyons.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS2hmcyr7Wb84ZkuPsg3YAvzg-_ZC5l-_J23arOxzln7StVDGbMuxBi5OzS',
    id: 8662,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'collegeofthedesert.edu',
    name: 'College of the Desert',
    web_page: 'http://collegeofthedesert.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU6iuubyGUTo2kmKEYq2ZMaBqG24mbWFU_Ej92qKc-mrt7O6JkUBu0-g',
    id: 8663,
    acronym: 'CD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'redwoods.edu',
    name: 'College of the Redwoods',
    web_page: 'http://www.redwoods.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHFjGxeNPKzPIxRVNqQJ5iuaO7IbCDofiCb2V-4zxXq6lenYaQ56JSnA',
    id: 8664,
    acronym: 'CR',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cos.edu',
    name: 'College of the Sequoias',
    web_page: 'http://www.cos.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkifuerOGHF0vEK7u9zOZ0oqY0PbpvCuPQgLOsPV3qF2JVxeqgztr9hrQ',
    id: 8665,
    acronym: 'CS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'siskiyous.edu',
    name: 'College of the Siskiyous',
    web_page: 'http://www.siskiyous.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnUNjBrIM1aUaYb7-IXvoe2kQ0n8ufqpDUgCJpzycKIYCf9qEozkECmQ',
    id: 8666,
    acronym: 'CS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'contracosta.edu',
    name: 'Contra Costa College',
    web_page: 'http://www.contracosta.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSyJN0hGuCn-SjiGWZgK6ZD1zdTxHt2OAUZM-Ks71HZy_irn7z4jI0C04',
    id: 8669,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cmccd.edu',
    name: 'Copper Mountain Community College',
    web_page: 'http://www.cmccd.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpiaNDYzEFUe60RH33eaopki5crcf5ecqzVuNBBJdReiD9gOE3cS4RiRw',
    id: 8670,
    acronym: 'CMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'crc.losrios.edu',
    name: 'Cosumnes River College',
    web_page: 'http://www.crc.losrios.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSK8LcQd6odV3BnN0JOBTjmAMe9EQvOMHY9M4wb8PMyFD0bMCaXTuBpNQ',
    id: 8671,
    acronym: 'CRC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'craftonhills.edu',
    name: 'Crafton Hills College',
    web_page: 'http://www.craftonhills.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI59OqvgSkrQ6RWrDW2YG6U9rZRziSXpoeX8tTZnh5s8M5CLrTxXbiSw',
    id: 8672,
    acronym: 'CHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.cuesta.edu',
    name: 'Cuesta College',
    web_page: 'http://WWW.CUESTA.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlddZpBcsb-5tt5GW7Zf752qW5VfBkxa2qJDswkFI2XupyxDIejP9pzQ',
    id: 8673,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cuyamaca.edu',
    name: 'Cuyamaca College',
    web_page: 'http://www.cuyamaca.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy5fG90-1EfAiDIEvGhao83XVac-QSG4ozhSbNV-V---LfU8Zxism9vw',
    id: 8674,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cypresscollege.edu',
    name: 'Cypress College',
    web_page: 'http://www.cypresscollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT00t8PYqUlW8b75_O5z94gdjDzBt2Wq0MvXTPXpyIQZ38ygz67r-g7454',
    id: 8675,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'deanza.edu',
    name: 'De Anza College',
    web_page: 'http://www.deanza.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuL4dAr9_b0HMmtZJ6LLP1qxrbHry8L6nPNLL7Mb32cc1oeKPHY-DTHw',
    id: 8676,
    acronym: 'DAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dvc.edu',
    name: 'Diablo Valley College',
    web_page: 'http://www.dvc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXAyJxV_iumxBxa9Dc51C5oJ7F_lY4uTR9nIKAa90VoFHHJr7U7w4RL6o',
    id: 8677,
    acronym: 'DVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'elac.edu',
    name: 'East Los Angeles College',
    web_page: 'http://www.elac.edu/index.htm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9lUjH37LL3kjNYWMpHJ1Z3QskH_zzQUqZeZfnaQg_cQJ6t1TUQoyUfA',
    id: 8678,
    acronym: 'ELAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'compton.edu',
    name: 'El Camino College-​Compton Center',
    web_page: 'http://www.compton.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqrK8r8LBhENHba9PN4AJu_8J6SRaxag8x4W5Tz6jjTizM0xfkGy4QNAg',
    id: 8679,
    acronym: 'ECCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'elcamino.edu',
    name: 'El Camino Community College District',
    web_page: 'http://www.elcamino.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_Ui9DMvIkj1LvXPMD4PCb291i7-jAYW7EboYUiCsXMB7vLW8XHhKWUd1A',
    id: 8680,
    acronym: 'ECCCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'evc.edu',
    name: 'Evergreen Valley College',
    web_page: 'http://www.evc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaLo_76ROzwlBEl1gJd4TzjlAnuX5EmD_R_-rvQBZwT6vB6Z-w4X0nF_w',
    id: 8681,
    acronym: 'EVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'frc.edu',
    name: 'Feather River Community College District',
    web_page: 'http://www.frc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9dWdI8AGf_HnnjzDoyhN-wTD85hu_Wen9ENxN1Mn9zxSbCsUDIlj2C-A',
    id: 8682,
    acronym: 'FRCCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'flc.losrios.edu',
    name: 'Folsom Lake College',
    web_page: 'http://www.flc.losrios.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVRB9x36crI93kOkdWrp1shKs6u6XJzKxS4MZykgipKnYIRj5aGMPBbA',
    id: 8683,
    acronym: 'FLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'foothill.edu',
    name: 'Foothill College',
    web_page: 'http://www.foothill.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZGC32aQp564KXrEXSuLmTOSjRp4VVLRZRqmfFk9qD28TfzGI8PhBQjQ',
    id: 8684,
    acronym: 'FC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fresnocitycollege.edu',
    name: 'Fresno City College',
    web_page: 'http://www.fresnocitycollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn3_jFNEsKQxzgq-zLwRLKrTRsU1p6AMbV5ASqy-XYetX-HrTOJ29FZ5SM',
    id: 8685,
    acronym: 'FCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fullcoll.edu',
    name: 'Fullerton College',
    web_page: 'http://www.fullcoll.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJMDq1Ivto4G5rCPKYKs2JSPqiJ8ctrPwKGIDrMdKcDkSj6wsi2NLGEg',
    id: 8686,
    acronym: 'FC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gavilan.edu',
    name: 'Gavilan College',
    web_page: 'http://www.gavilan.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTGkAcwB5-8hGqNJJlKOMySEsJxU8getcAXCOKjMWFrtvUQE_eRGa-Cw',
    id: 8687,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'goldenwestcollege.edu',
    name: 'Golden West College',
    web_page: 'http://www.goldenwestcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdfkWFMUsiVCh3NuL4E3vQ6HSrHQRz18mIVl2Bp_9tBeg38y55gE5c2Z8',
    id: 8689,
    acronym: 'GWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'grossmont.edu',
    name: 'Grossmont College',
    web_page: 'http://www.grossmont.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHhcAHkMpCGOPPCxNo-DkG_8V4QK3ZASEGxeRhW1FaLdxxVHMvZpRJmmE',
    id: 8690,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hartnell.edu',
    name: 'Hartnell College',
    web_page: 'http://www.hartnell.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcT9MvFd4KCJ-7MUmCJ6h2V-lO5WmzLh0cR4fGIjvQ1rmw4RwcfTQfYRuIk',
    id: 8691,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'imperial.edu',
    name: 'Imperial Valley College',
    web_page: 'http://www.imperial.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFjI2pbvwWn69yhgQLRifoysE22BtjaPmSFCj7RNWptLMyvTZzWk8pGTI',
    id: 8692,
    acronym: 'IVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ltcc.edu',
    name: 'Lake Tahoe Community College',
    web_page: 'http://www.ltcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL4LgpAO9_2IgcEKTpxLSVfYTm241aEeKcRHDK3ZYdkZqO7GiufAxP-q7x',
    id: 8693,
    acronym: 'LTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'laney.edu',
    name: 'Laney College',
    web_page: 'http://www.laney.edu/wp/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSUTewWCv0oEpSnzO0MvUaovU_m0YSvLndzuIZduwHm7kq4Ywf85SSEJE',
    id: 8694,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'laspositascollege.edu',
    name: 'Las Positas College',
    web_page: 'http://www.laspositascollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-vDep3geKFgGjewmVCrkNbx_mtv-j3vYiUtgib7tkPvJB-GQdbPLBsP0',
    id: 8695,
    acronym: 'LPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lassencollege.edu',
    name: 'Lassen Community College',
    web_page: 'http://www.lassencollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNzYqxjxJXw3BXOJYYk4o91oUTP2BOKqgIjWa9qRa-E5wv2Xpvu88B30JJaA',
    id: 8696,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lbcc.edu',
    name: 'Long Beach City College',
    web_page: 'http://www.lbcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfjI0N0udn9gzxHUFL_g4kstI9iAlzkE_a-TnOkJfiQqvFFJ90ZKtJxNhy',
    id: 8697,
    acronym: 'LBCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lacitycollege.edu',
    name: 'Los Angeles City College',
    web_page: 'http://www.lacitycollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoqFviB75yDqdvZwgrb82X2XOT9oqylFd6fHHUQ9ZW0RDYiCfUdcRYDSY9',
    id: 8698,
    acronym: 'LACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ladhs.org',
    name: 'Los Angeles County College of Nursing and Allied Health',
    web_page: 'http://ladhs.org/wps/portal/CollegeofNursing',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS34XkMXXGD27nqAaL4WUEbmKWm95fhLMVIcTpEpSglq9WAm03Msw_03uY',
    id: 8699,
    acronym: 'LACCNAH',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lahc.edu',
    name: 'Los Angeles Harbor College',
    web_page: 'http://www.lahc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbq4iHPOHwlW_UC8SGlhbC7SFF_VMSS9cWkQB172HWu6Z9NFFPOdWZaH8',
    id: 8700,
    acronym: 'LAHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lamission.edu',
    name: 'Los Angeles Mission College',
    web_page: 'http://www.lamission.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVtcDqyat2x3fIU8cFtU4AFkfiALPArG4DwKWYueyHhkFeQOu-v2NkEYQ',
    id: 8701,
    acronym: 'LAMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'piercecollege.edu',
    name: 'Los Angeles Pierce College',
    web_page: 'http://www.piercecollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4m1FpTJR_hlPMuusRZ1Y3rh_2-VlY379PAryU44uWXBux4Mrr44igx1g',
    id: 8702,
    acronym: 'LAPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lasc.edu',
    name: 'Los Angeles Southwest College',
    web_page: 'http://www.lasc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoqFviB75yDqdvZwgrb82X2XOT9oqylFd6fHHUQ9ZW0RDYiCfUdcRYDSY9',
    id: 8703,
    acronym: 'LASC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lattc.edu',
    name: 'Los Angeles Trade Technical College',
    web_page: 'http://www.lattc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThDHHJ4LEVexJmEcuHAqKlgxB-vdbTUltRiaIEWYDA3CUOFhw8fHsUoIw',
    id: 8704,
    acronym: 'LATTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lavc.edu',
    name: 'Los Angeles Valley College',
    web_page: 'http://www.lavc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStbLEtrn6dbZo1nuk4Fhu2jjsj04s-UsETWX4_wePqwNU3RE9BWQakEw',
    id: 8705,
    acronym: 'LAVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'losmedanos.edu',
    name: 'Los Medanos College',
    web_page: 'http://www.losmedanos.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR456POJx4u60nu2zt97krv4nmCSgW8jcr4gGVx-sUKCk6ME-ar2ZKJLQ',
    id: 8706,
    acronym: 'LMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mendocino.edu',
    name: 'Mendocino College',
    web_page: 'http://www.mendocino.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7QtAr6tVda8nuxocS-gZj4nkhto9RXvZvQ4mPwK6sXJbM5rYqc7faag',
    id: 8707,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mccd.edu',
    name: 'Merced College',
    web_page: 'http://www.mccd.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpiPK-EM1JXsk4J76hL5WVkqNihOUlX0ijLcUEW5Lwm-D6a1g8xgtaL_0',
    id: 8708,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'merritt.edu',
    name: 'Merritt College',
    web_page: 'http://www.merritt.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSUTewWCv0oEpSnzO0MvUaovU_m0YSvLndzuIZduwHm7kq4Ywf85SSEJE',
    id: 8709,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'miracosta.edu',
    name: 'MiraCosta College',
    web_page: 'http://www.miracosta.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY04CrhtZSpYrnlTt8xcLWnZaSdZwUW84y8jzpqesn7JzihtVWJ05uRA',
    id: 8710,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'missioncollege.edu',
    name: 'Mission College',
    web_page: 'http://www.missioncollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6FFKQGUSAERGoeOZCTXbYjpSebTPFL2f8nw6zy3Bhd76r4TuT_nbllQ',
    id: 8711,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mjc.edu',
    name: 'Modesto Junior College',
    web_page: 'http://mjc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV7zNeBk-fVdFTmsclv8IBIx1onC1DSuAytjwY5h_dLLiyVMMSalzt-w',
    id: 8712,
    acronym: 'MJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mpc.edu',
    name: 'Monterey Peninsula College',
    web_page: 'http://www.mpc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKHGY-_0Iq4qu9cOIHZ4tmZcKC4YlRdEObzqRQz22HPWOpvH7naJJ8Opg',
    id: 8713,
    acronym: 'MPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'moorparkcollege.edu',
    name: 'Moorpark College',
    web_page: 'http://www.moorparkcollege.edu/index.shtml',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlpApcM55ubgTnrBhn5DYWdkPgaqWtJcc0EVfrvOhUJr8TQZybyHcwjg',
    id: 8714,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mvc.edu',
    name: 'Moreno Valley College',
    web_page: 'http://www.mvc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBYVlREJdx-W6rYbLypkAqBx91yCQckvcaZSeK88vW_k-ShHpLZmrK860',
    id: 8715,
    acronym: 'MVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sacramento.mticollege.edu',
    name: 'MTI College',
    web_page: 'http://sacramento.mticollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIOBmSwyaENpoVoObcbxGwlHT03nnEaMPjS_0RYhE0-H365ImqUZCQVH4',
    id: 8716,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mtsac.edu',
    name: 'Mt. San Antonio College',
    web_page: 'http://www.mtsac.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTg2VhW9oZqShXeqetKFZbiTEvIKhjz13ArIsxWWcKNjfJwJXHcUq30CXE',
    id: 8717,
    acronym: 'MSAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msjc.edu',
    name: 'Mt. San Jacinto Community College District',
    web_page: 'http://www.msjc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7CZxlsSg3wOxa5r3zIYkHkTHxe2QqPeUOL2oxHC8JYTiTikYcqDmijz0',
    id: 8718,
    acronym: 'MSJCCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'napavalley.edu',
    name: 'Napa Valley College',
    web_page: 'http://www.napavalley.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw9SukUTjzJwKQ7-V-2kCEJ-T7-KVECq28rNBMKIcDRD9L0nBUjt-p9Uo',
    id: 8719,
    acronym: 'NVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rcc.edu',
    name: 'Norco College',
    web_page: 'http://www.rcc.edu/norco/index.cfm',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnDKhinxq_HAjG_ec6K_2o6MPYNHg9aKnp1PMzDxFYdT0My5kUn4peaQ',
    id: 8720,
    acronym: 'NC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ohlone.edu',
    name: 'Ohlone College',
    web_page: 'http://www.ohlone.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiSyPLl8QNr2GQRcB4-7a14Ce-gKg5wgTwNUQA5e65MTiNv9jxhCR3zw',
    id: 8721,
    acronym: 'OC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'orangecoastcollege.edu',
    name: 'Orange Coast College',
    web_page: 'http://www.orangecoastcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvtr_boiJhplCBImeJ4batamtkgnmgi2sQkOg0XKb0coYqEfcPcJOHdN4',
    id: 8722,
    acronym: 'OCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oxnardcollege.edu',
    name: 'Oxnard College',
    web_page: 'http://www.oxnardcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSjOvkY2PBkb5I7tcYlXucBtGzOmsNqNPqXiecVS4yCa_2BbnC-974qW6M',
    id: 8723,
    acronym: 'OC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'palomar.edu',
    name: 'Palomar College',
    web_page: 'http://www.palomar.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF9uEgOKl1i_ODNkRKWu06SYE9loVnGMCbC38n-HAK_bV5TSS49JdbPA',
    id: 8724,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'paloverde.edu',
    name: 'Palo Verde College',
    web_page: 'http://www.paloverde.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuKql2fouAqDj2dxJxtZakHGYiQdSxz3NPoj25d-oAcS0B0rPFEJdToEeS',
    id: 8725,
    acronym: 'PVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pasadena.edu',
    name: 'Pasadena City College',
    web_page: 'http://www.pasadena.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgYVhV4EuTiwWXaiyMux3YxDzNx-O_k3QnCcOyILH4_ZSmMoKvxKzpNg',
    id: 8726,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'portervillecollege.edu',
    name: 'Porterville College',
    web_page: 'http://www.portervillecollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXpPByfW1_g6Xoo8BUruLct36ti4Owl0w9tYvHhbmACVkNqkilCPZ-bBk',
    id: 8727,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'reedleycollege.edu',
    name: 'Reedley College',
    web_page: 'http://www.reedleycollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1qiH0ssgl_mZIcGdN-48Ezk0lJcYATrisMdJ9oyeq56XTMle-Db5qv6iS',
    id: 8728,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'riohondo.edu',
    name: 'Rio Hondo College',
    web_page: 'http://www.riohondo.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkAyH0mp2tAyK1gXRvMT2at0_oM_im8d-HJ03bfudLTq8nUuNu_8zJPQ',
    id: 8729,
    acronym: 'RHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rcc.edu',
    name: 'Riverside City College',
    web_page: 'http://www.rcc.edu/riverside/',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSGdMyUoCHlgUjNhElrafrQTQxIsD6G-HGSK-tvx0R4YyxgqR21-zUr2AI',
    id: 8730,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'scc.losrios.edu',
    name: 'Sacramento City College',
    web_page: 'http://www.scc.losrios.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVS2rDfgAMW8NZE-wxrz7mkb2hdKWScnLhvyKPq1BxuJqHZ3MkR6f9Jw',
    id: 8731,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'saddleback.edu',
    name: 'Saddleback College',
    web_page: 'http://www.saddleback.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmjRoYoFlxv-AokTA76aotDeWr5xkpevF4ZmvitN4IIBlgNN43x8I2tPEC',
    id: 8732,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'valleycollege.edu',
    name: 'San Bernardino Valley College',
    web_page: 'http://www.valleycollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBG2_vKS3sVWToU38Li0nQOk5fHKTtQY4vgaGOq7Ct7E9ykJ94Ap_r1w',
    id: 8733,
    acronym: 'SBVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sdcity.edu',
    name: 'San Diego City College',
    web_page: 'http://www.sdcity.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9Y9ChJUDShVNVhs_fPK1wCpgdGn01dXzk6gJaQ1YXRyuBDT-PJdofGAs',
    id: 8734,
    acronym: 'SDCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sdmesa.edu',
    name: 'San Diego Mesa College',
    web_page: 'http://www.sdmesa.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0hZsHX7KH4uN730B2XUiZMQ75NTFvF8IZLXRQV19JKiB6BlKGKADu_Q',
    id: 8735,
    acronym: 'SDMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sdmiramar.edu',
    name: 'San Diego Miramar College',
    web_page: 'http://www.sdmiramar.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYfNrfOCXQgnmKSc5HQcBnM8FAjpLTHSnEg6ah7dnMaPUCwnay-MwUNFQ',
    id: 8736,
    acronym: 'SDMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'deltacollege.edu',
    name: 'San Joaquin Delta College',
    web_page: 'http://www.deltacollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtJ_Yg_rS5JTr3mpUt1qDdX-kD_UFSM64fxBmBlDuKvOgotCPaJ9cLKA',
    id: 8737,
    acronym: 'SJDC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjvc.edu',
    name: 'San Joaquin Valley College-​Visalia',
    web_page: 'http://www.sjvc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSClVAqQMEtw1URgOWxb-PLMbgejGqsWhlp4V-bnxsMy8sUovur1SGGVI8',
    id: 8738,
    acronym: 'SJVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sjcc.edu',
    name: 'San Jose City College',
    web_page: 'http://www.sjcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmJdNrsu2tV-4vn5OqmEpz9IjHo9ZDgXQSt5GgoIJF8uuNwBVrfyU3vqA',
    id: 8739,
    acronym: 'SJCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sac.edu',
    name: 'Santa Ana College',
    web_page: 'http://www.sac.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMmOeJQr8N9dl5Vnfu2EgVTx2jOmBvXrgv_B2Ec-qKkhkmt9z3lTPCNw',
    id: 8740,
    acronym: 'SAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sbcc.edu',
    name: 'Santa Barbara City College',
    web_page: 'http://www.sbcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1P039xPwcgODGZJw1KWDTOkwal7yZcwsCa4aMl4u7KmDKripvX8b1ZA',
    id: 8741,
    acronym: 'SBCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smc.edu',
    name: 'Santa Monica College',
    web_page: 'http://www.smc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOrmQPZZ-BlCZiko58v3dvswSsZdbxWRwKIFOMi_xz5JDtUZjtvwIDKP0',
    id: 8742,
    acronym: 'SMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'santarosa.edu',
    name: 'Santa Rosa Junior College',
    web_page: 'http://www.santarosa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR88PsE92hDOqugUnINFRzAWUvd5VKuEQrTvWElUe0eOTwHNrpR_sh4vYM',
    id: 8743,
    acronym: 'SRJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sccollege.edu',
    name: 'Santiago Canyon College',
    web_page: 'http://www.sccollege.edu/Pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoPAIjslK_Voa1J4S38_rnf5auXe2-r8aK-cFp_dXGzvqYAYOrd4MMV6k8',
    id: 8744,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'shastacollege.edu',
    name: 'Shasta College',
    web_page: 'http://www.shastacollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSf6xUSj7TWkDzOXfqWfcs4DFi15L91-bfb2oc1vLv_kjLkFleyR6hi1wwq',
    id: 8745,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sierracollege.edu',
    name: 'Sierra College',
    web_page: 'http://www.sierracollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUXqAoNtL8bPFt_K9-hmrpK-Mxq482XtkpG2zhNM4Hd6UxFcOwI48Q3eU',
    id: 8746,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'skylinecollege.edu',
    name: 'Skyline College',
    web_page: 'http://skylinecollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrsYjPfldxnuIepFgcWNBVKmOi9mYh_d5iB0FoM0YqTRP3mEEfWXiUHA',
    id: 8747,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'solano.edu',
    name: 'Solano Community College',
    web_page: 'http://www.solano.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-s9ChN31AY-eMiO7ihm-A4Yy5Mb0qrSxzHOz4qLMlYxcUQyIHERM4Ykc',
    id: 8748,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'taftcollege.edu',
    name: 'Taft College',
    web_page: 'http://www.taftcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCSmP9LXypsVLeFZamAJug_xGO7nS-M7rDol5eD83a1TUdgs7M1iXyu4Ou',
    id: 8750,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'venturacollege.edu',
    name: 'Ventura College',
    web_page: 'http://www.venturacollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQggKwidxtuUdb5dgxg42zcPF2Bde71feVjGnV24AYk6H5P5Gl893C5AVI',
    id: 8751,
    acronym: 'VC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vvc.edu',
    name: 'Victor Valley College',
    web_page: 'http://www.vvc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6jTs_wbr0MsUvd81T2N9h9iCcu_KEJ0OdWkjP0AbvfhwDlfrANKggUV-puA',
    id: 8752,
    acronym: 'VVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westhillscollege.com',
    name: 'West Hills College-​Coalinga',
    web_page: 'http://www.westhillscollege.com',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-JblBwxInGfakeb20K_92Yf1uuY-lGsugRA3h5VBTPoKOO8Lr3EYtHpk',
    id: 8753,
    acronym: 'WHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wlac.edu',
    name: 'West Los Angeles College',
    web_page: 'http://www.wlac.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMZaejJK0YENt7iPqtbvw83c74P9f8jh_tHmuR5rJGd4wvzR_9Z-XQ-rk',
    id: 8754,
    acronym: 'WLAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westvalley.edu',
    name: 'West Valley College',
    web_page: 'http://www.westvalley.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtNIs4gnx6_1pv_X57arHvkrY5BOBlWlm8SoMNY0eGuUhv1vKlXwZILPUw',
    id: 8755,
    acronym: 'WVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wcc.yccd.edu',
    name: 'Woodland Community College',
    web_page: 'http://wcc.yccd.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7uVueK1dnym49LYEOgINzfUSK9CIRIhDvZshPIe5GOVwW65wbiWvI6I0',
    id: 8756,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'yc.yccd.edu',
    name: 'Yuba College',
    web_page: 'http://yc.yccd.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOLMZ_MdXz3mHstpu28nuopDzjoeFyQ5m6ZgmYnFIVKTMFBZRkTARnuw',
    id: 8757,
    acronym: 'YC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aims.edu',
    name: 'Aims Community College',
    web_page: 'http://www.aims.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_aRDnKX_zZg1RrfXtRkwT_vktd0mpR1MP9XL3hPNbD6aqQQSLU4OaDWE',
    id: 8758,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'arapahoe.edu',
    name: 'Arapahoe Community College',
    web_page: 'http://www.arapahoe.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS0uBwK5CTzhhwWH3uW5zhaV6r6mqBPQj1PP-8wZyF2M20T28EkTvfXg',
    id: 8759,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cncc.edu',
    name: 'Colorado Northwestern Community College',
    web_page: 'http://www.cncc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYC-RwCCag3Z0mb4p-Kpi2ViZWZ49ERBA3hi-NwO9P4PuXKtmDYvz5VD8',
    id: 8760,
    acronym: 'CNCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccaurora.edu',
    name: 'Community College of Aurora',
    web_page: 'http://www.ccaurora.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_GEr8TLNiWOThUz2Df4ImxoxqQeQCdJ0Erqn1S7_ETf6J2AwHDnpUcx4',
    id: 8761,
    acronym: 'CCA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccd.edu',
    name: 'Community College of Denver',
    web_page: 'http://www.ccd.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSReykltoS4eZXGklPBXt3sRhVUxcLnqkDcZ51irYYYWcYWwvDfM2kN7ac',
    id: 8762,
    acronym: 'CCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'frontrange.edu',
    name: 'Front Range Community College',
    web_page: 'http://www.frontrange.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTz0Ewq2nkzuEEW2mPzFkpQ7s9QBbVrZuMn9kTikr0VmEsRdhA9N8xoP8U',
    id: 8763,
    acronym: 'FRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lamarcc.edu',
    name: 'Lamar Community College',
    web_page: 'http://www.lamarcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDlcvx1EcMnZEuyslBtoe5ra9oJ-fBCe41E93mhMArndINFc3zAlzMSDk',
    id: 8764,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'morgancc.edu',
    name: 'Morgan Community College',
    web_page: 'http://www.morgancc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnATqRuy3wvRBhcgpSKEIPRx1K7C5UCMuKrTlZ3XzF2W_WdPaZccUnjA',
    id: 8765,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'njc.edu',
    name: 'Northeastern Junior College',
    web_page: 'http://www.njc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7-0DOqXubvdIvtOe8Y9cF_x47dEcMlJkCwYfNtB66Gkz1nbHOA4lKs_Q',
    id: 8766,
    acronym: 'NJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.ojc.edu',
    name: 'Otero Junior College',
    web_page: 'http://WWW.OJC.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjaoYkmw1A8TLaJfXTCIriAf8yUz4saStAekMM0n_ThsNto2KNVq0qky0',
    id: 8767,
    acronym: 'OJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ppcc.edu',
    name: 'Pikes Peak Community College',
    web_page: 'http://www.ppcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4edvQ4Eed2_2MeoWz3rsabCeXL3bVLemQa6XiDhUCcmYhTEHjpC3I_Q',
    id: 8768,
    acronym: 'PPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pueblocc.edu',
    name: 'Pueblo Community College',
    web_page: 'http://www.pueblocc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRVN5QYNcQowcj-SVsS7kNfwpbxJYx0hUeZtsCiw3wCwAVqbP5ns-InQ',
    id: 8769,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rrcc.edu',
    name: 'Red Rocks Community College',
    web_page: 'http://www.rrcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt4y25Bp8kW_xfC-7yjDKQ8XAbVJFVI1DBf5GaZRb8q8MHMMKFN-5RXw',
    id: 8770,
    acronym: 'RRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'trinidadstate.edu',
    name: 'Trinidad State Junior College',
    web_page: 'http://www.trinidadstate.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQK-jMLvOOItHy5wEbPzfH0DesDhcNsRG0KHP8c683CdJSKUiCZ3ej3CUcL',
    id: 8771,
    acronym: 'TSJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'asnuntuck.edu',
    name: 'Asnuntuck Community College',
    web_page: 'http://www.Asnuntuck.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREc8sQzIHCgtLoh5brEqL99LIeUxUB1J5jkCHkkReDTWuzMDKAJyfIxLY',
    id: 8772,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccc.commnet.edu',
    name: 'Capital Community College',
    web_page: 'http://www.ccc.commnet.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZg93xFJmZyRQviABybegRRVQTURYZRwQqceeYsRrQu9XLbsl8hqWqlw',
    id: 8773,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gwcc.commnet.edu',
    name: 'Gateway Community College',
    web_page: 'http://www.gwcc.commnet.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvAiEbHwEd2oB_qnmgiYiIBNZl3p2kDS08cPnvAlQFMmdqnw-OzT4JKpc',
    id: 8774,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hcc.commnet.edu',
    name: 'Housatonic Community College',
    web_page: 'http://www.hcc.commnet.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFMhegr76wL1CkQG8KK-JuFXs1ssZhKgyGimM-Th2ov8WqU-F5v3TdPA',
    id: 8775,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcc.commnet.edu',
    name: 'Manchester Community College',
    web_page: 'http://www.mcc.commnet.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNF8wIIfEOCjXRNDs8cxlYJLY442YOIBMMobWijwxjCi-OUGh5yizb3ec',
    id: 8776,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mxcc.commnet.edu',
    name: 'Middlesex Community College',
    web_page: 'http://www.mxcc.commnet.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQfGNwXGSTqHUjor2cPUzkJj6I3GEBky_HOhXewOdD5BU0mDE0m3PxEOA',
    id: 8777,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nv.edu',
    name: 'Naugatuck Valley Community College',
    web_page: 'http://www.nv.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTM47UoefyIESw3tatH5beXnX_p3YzR7VC8GBK2q1DnxTQEAOOeEo4L3Q',
    id: 8778,
    acronym: 'NVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwcc.commnet.edu',
    name: 'Northwestern Connecticut Community College',
    web_page: 'http://www.nwcc.commnet.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyat8X8jN45ygKJCqkv94hQBhl_vFzt68et_ldyHe4p7yUKOF8yDVnjQ',
    id: 8779,
    acronym: 'NCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncc.commnet.edu',
    name: 'Norwalk Community College',
    web_page: 'http://www.ncc.commnet.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSV35XY0UoeNAXzmGhKzpO2OhsqsXyJDqdJTiNkCPqr5JZZ-WwWMf99-lg',
    id: 8780,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'qvcc.edu',
    name: 'Quinebaug Valley Community College',
    web_page: 'http://www.qvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_66uLy1FGIRD6X-pTkR6dR3bpIcVuEF2f1vwLPv6wMdDiL1KBurPkiw',
    id: 8781,
    acronym: 'QVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'trcc.commnet.edu',
    name: 'Three Rivers Community College',
    web_page: 'http://www.trcc.commnet.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF5uE0RpkHG7BxwjBGLcpth8pa-Bv9p1J625UOCCt-vV-ag5ofsBUbNw',
    id: 8782,
    acronym: 'TRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tunxis.edu',
    name: 'Tunxis Community College',
    web_page: 'http://www.tunxis.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAwe23jM5LUwnJhqRkI54Ihx3DMcvbZ8Rj_258iIcHpeUGz2A2MflBiEfB-Q',
    id: 8783,
    acronym: 'TCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dcad.edu',
    name: 'Delaware College of Art and Design',
    web_page: 'http://www.dcad.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUhaloxwX_O0iPTs_5o1v18Qn6w3HC6nS88AXpEVdCMF-5vqvodUlGrA',
    id: 8784,
    acronym: 'DCAD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dtcc.edu',
    name: 'Delaware Technical Community College-​Owens',
    web_page: 'https://www.dtcc.edu/our-campuses/georgetown',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSVpk5kpT6DSORvgV48ZN770NTe0l3wZf6Uv3ng5_IaPlbNjeucZx0O28',
    id: 8785,
    acronym: 'DTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dtcc.edu',
    name: 'Delaware Technical Community College-​Stanton/​Wilmington',
    web_page: 'https://www.dtcc.edu/our-campuses/wilmington',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSVpk5kpT6DSORvgV48ZN770NTe0l3wZf6Uv3ng5_IaPlbNjeucZx0O28',
    id: 8786,
    acronym: 'DTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dtcc.edu',
    name: 'Delaware Technical Community College-​Terry',
    web_page: 'https://www.dtcc.edu/our-campuses/dover',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSVpk5kpT6DSORvgV48ZN770NTe0l3wZf6Uv3ng5_IaPlbNjeucZx0O28',
    id: 8787,
    acronym: 'DTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fkcc.edu',
    name: 'Florida Keys Community College',
    web_page: 'http://www.fkcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgf3GS5Il14euSEiYunrsVL3NtqTU9MYTnoTcfJwQEfmQ8b5cN7HmTgw',
    id: 8788,
    acronym: 'FKCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hccfl.edu',
    name: 'Hillsborough Community College',
    web_page: 'http://www.hccfl.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQogoMYHPw9tuWnDM-uhvdWDERIiIJWIfRPvANkQ-6-gy9T-nrLlo3Q3RI',
    id: 8789,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nfcc.edu',
    name: 'North Florida Community College',
    web_page: 'http://www.nfcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR8tI0m0CReRSpuQ_m40dwYxvimpOWX3cvmsAGoXNRLYfnDbGYT4TqSg',
    id: 8790,
    acronym: 'NFCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'phcc.edu',
    name: 'Pasco-​Hernando Community College',
    web_page: 'http://www.phcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTz9mUpPL5qIpTdUZ7-7nOSW8kpRwmvtRCPml0E3joVDwRsS_TzQcZa6Vo',
    id: 8791,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tcc.fl.edu',
    name: 'Tallahassee Community College',
    web_page: 'https://www.tcc.fl.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVWXM4t8aye6A6HpK4WUCz8IaCUH8HdH6kd7O7hhbu7kOAKUk9zA7tzg',
    id: 8792,
    acronym: 'TCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'albanytech.edu',
    name: 'Albany Technical College',
    web_page: 'http://www.albanytech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_wpHHxNXsZ_EiaBJ3mxYO7-vgYgUHMtWQxH7JsISPYrOMFdiO-Frw2KilXg',
    id: 8793,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coastalpines.edu',
    name: 'Altamaha Technical College',
    web_page: 'http://www.coastalpines.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIeCJVhVxUt3wh9XgeuzKkxWRRb5GAFpz1zbBApNQBqSHn-tH3bj3u-w',
    id: 8794,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'andrewcollege.edu',
    name: 'Andrew College',
    web_page: 'http://www.andrewcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjE-55Ca1OEOAFClXpTf78j12cZqrbWfTByxOpRvkxjXOwD2pgv8ruMw',
    id: 8795,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'athenstech.edu',
    name: 'Athens Technical College',
    web_page: 'http://www.athenstech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiQ-LeEUX6FzC3RvNSBo_MLzlZ1VOSKW2Gy_wedeY8W5_MhO-zgO2AWbs',
    id: 8796,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'atlantatech.edu',
    name: 'Atlanta Technical College',
    web_page: 'http://www.atlantatech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlpUqq4eEgNGk21Ie6nLM3b4xMJUuiQb64oERZz8lTwfAQo5eAWeFKiw',
    id: 8797,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'augustatech.edu',
    name: 'Augusta Technical College',
    web_page: 'http://www.augustatech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQWFOA6BUBluP9m-jVduQfMw8uM0CwXrU90bn71TVI_B3r-3Onx-ujSA',
    id: 8798,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bainbridge.edu',
    name: 'Bainbridge State College',
    web_page: 'http://www.bainbridge.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE6Dseca_kZxNH-z5UmQrJ2SjyioGjK0ocFu_v0CGqraqh_JIKIV8SdIM',
    id: 8799,
    acronym: 'BSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'centralgatech.edu',
    name: 'Central Georgia Technical College',
    web_page: 'http://www.centralgatech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmyOcDDCCEMGm3722fVjLA6loRD4HofI6k_wS3804AdT1v95V8h5KofWc',
    id: 8800,
    acronym: 'CGTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chattahoocheetech.edu',
    name: 'Chattahoochee Technical College',
    web_page: 'http://www.chattahoocheetech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4ZySzwu5NHQLLsrJYes_vN_C7jPZAwBleNxU3Pc3h9TGMm3CMB92atfo',
    id: 8801,
    acronym: 'CTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'columbustech.edu',
    name: 'Columbus Technical College',
    web_page: 'http://www.columbustech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKaU8NQJ-QoyvDqPtPoDwA2rTzlvb1XoKOdIenad5Gcei7oYyoGkloAVE',
    id: 8802,
    acronym: 'CTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'darton.edu',
    name: 'Darton State College',
    web_page: 'http://www.darton.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT00Vj3lhtv-iOwAf0tjnhuxLwNsGGdTtmBEGLaEweDs2a3MGebEWuB5C8',
    id: 8803,
    acronym: 'DSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'highlands.edu',
    name: 'Georgia Highlands College',
    web_page: 'http://www.highlands.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJB8uUfxHLbpu4u5yWHUDr7GXdBERGOfRW9Uno5ynBMZszPHqpmvsJkVU',
    id: 8804,
    acronym: 'GHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gmc.cc.ga.us',
    name: 'Georgia Military College',
    web_page: 'http://www.gmc.cc.ga.us/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBE4dAH7aI8-2V7WBgwHOzcZ5xJCdvIW9reAlqlbnlqUNdr9x7hvrs-Q',
    id: 8805,
    acronym: 'GMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gntc.edu',
    name: 'Georgia Northwestern Technical College',
    web_page: 'http://www.gntc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcrYdtilHW0vH4lqrrZjO1AuWqwRIbnNRmPPemMVO3euk2Gfxq1kBJew',
    id: 8806,
    acronym: 'GNTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gpc.edu',
    name: 'Georgia Perimeter College',
    web_page: 'http://www.gpc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoTr0cW1XbVJZAdYgoMVgOycIPtzy79HbBMkKbDR1m8p4nR40_iKVZyQ',
    id: 8807,
    acronym: 'GPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gptc.edu',
    name: 'Georgia Piedmont Technical College',
    web_page: 'http://www.gptc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoth0b7if13CoqFoeiJuG0k3vNFqUxilJkHwsRCB10d2pYQU3HgVETgQ',
    id: 8808,
    acronym: 'GPTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gwinnetttech.edu',
    name: 'Gwinnett Technical College',
    web_page: 'http://www.GwinnettTech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIeCJVhVxUt3wh9XgeuzKkxWRRb5GAFpz1zbBApNQBqSHn-tH3bj3u-w',
    id: 8809,
    acronym: 'GTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'laniertech.edu',
    name: 'Lanier Technical College',
    web_page: 'http://www.laniertech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-wxyXUK4K4z2ByoQvsDaiW8OvBrS7Qb5bazeLTU3e19ZNbGAKK4o81rdl',
    id: 8810,
    acronym: 'LTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'moultrietech.edu',
    name: 'Moultrie Technical College',
    web_page: 'http://www.moultrietech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGSmDDrEnqX90W9piKxStU6Aoqpmior43zxhUXNwX95ynp-xHuRMkDOfs',
    id: 8811,
    acronym: 'MTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northgatech.edu',
    name: 'North Georgia Technical College',
    web_page: 'http://www.northgatech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgscLWqL8LliVPPPGAW5NXT4EVMM3o8slpZzPBlmNZXypJ0XG94ICC520',
    id: 8812,
    acronym: 'NGTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ogeecheetech.edu',
    name: 'Ogeechee Technical College',
    web_page: 'http://www.ogeecheetech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXDRAiP5HX6k8TqP8GqD8KLJcL3p1BYjkErsKH_knY6qdzsDSpq47s1g',
    id: 8813,
    acronym: 'OTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coastalpines.edu',
    name: 'Okefenokee Technical College',
    web_page: 'http://www.coastalpines.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAQB6UHFpwCH5-gyrsNSoDZsbyULEu29LlU_sP13yirfjymMMcCyIGGog',
    id: 8814,
    acronym: 'OTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'savannahtech.edu',
    name: 'Savannah Technical College',
    web_page: 'http://www.savannahtech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLkcB5QSvJApuNNKpRuaGrmXbc4UcxxO5D2-zjEaEt5WlZhxx8FPBShWip',
    id: 8815,
    acronym: 'STC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southeasterntech.edu',
    name: 'Southeastern Technical College',
    web_page: 'http://www.southeasterntech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC7fOmWFr0AIyRzJsHmaS06yz-Og_dZR30mxBq5b3I_yENTv4QyNY7pq0',
    id: 8816,
    acronym: 'STC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sctech.edu',
    name: 'Southern Crescent Technical College',
    web_page: 'http://www.sctech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLWMQKz_WDd_nXzyzAsgUPRZ_nScEwhmUxnHv3R23weGubYvQojGnq7g4',
    id: 8817,
    acronym: 'SCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southgatech.edu',
    name: 'South Georgia Technical College',
    web_page: 'http://www.southgatech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFsUckAJOl0_ezyn3s8zYE44F9LW5xfC7OeVDfs4l9Kmpi0ye4hVFQypA',
    id: 8818,
    acronym: 'SGTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southwestgatech.edu',
    name: 'Southwest Georgia Technical College',
    web_page: 'http://www.southwestgatech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAgcB9SMFPWhM5VnYhL7OjMhdrnyuTk40pLKyqwH2zhqGhCIrIbVuw17WS',
    id: 8819,
    acronym: 'SGTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westgatech.edu',
    name: 'West Georgia Technical College',
    web_page: 'http://www.westgatech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5R_soMUAICVIkggLLZfSWNb8NrUPh2fUDvGbNgN4wPNlCBgkqtceH8I0',
    id: 8820,
    acronym: 'WGTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wiregrass.edu',
    name: 'Wiregrass Georgia Technical College',
    web_page: 'http://www.wiregrass.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYC-dD-W_aVKzKRJTG5rDz8PWg1zF7se2eTdCUM47yPGqj5M4T3YQrnQ',
    id: 8821,
    acronym: 'WGTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hawaii.hawaii.edu',
    name: 'Hawaii Community College',
    web_page: 'http://hawaii.hawaii.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHX501Jlr4E2E3ly-k2R6OKUSARTD2Af5ZsGXZteZqBbX01UGggIRtW-Gn',
    id: 8822,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'honolulu.hawaii.edu',
    name: 'Honolulu Community College',
    web_page: 'http://www.honolulu.hawaii.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT75Jk8qcxKO01lo7LUmtEzs9yE5DxfQi9FUKZlgE5uKgwTeZHJQBy3DzI',
    id: 8823,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kapiolani.hawaii.edu',
    name: 'Kapiolani Community College',
    web_page: 'http://kapiolani.hawaii.edu/page/home',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9BuObL80usllAregHVZGJJu4OFs6SMkL81h0T9YPSId7AFZvVK-ZGJDU',
    id: 8824,
    acronym: 'KCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kauai.hawaii.edu',
    name: 'Kauai Community College',
    web_page: 'http://kauai.hawaii.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPwQ6PJPlwYAuB5Ui0x1VMZd3muEeFS0_0nOef1EksyGIf9Z-4gjLZqDc',
    id: 8825,
    acronym: 'KCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'leeward.hawaii.edu',
    name: 'Leeward Community College',
    web_page: 'http://www.leeward.hawaii.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7_061T6S5LbUugPN0AhdattI68WgZldkKPoxf2qFvwW9o2lk-X-UILLg',
    id: 8826,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'windward.hawaii.edu',
    name: 'Windward Community College',
    web_page: 'http://windward.hawaii.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkVWEIfBFQyGC4U0UtxOwSYAhAdzfcsX3azuC75GhmK1YM4qVIYriqgw',
    id: 8827,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csi.edu',
    name: 'College of Southern Idaho',
    web_page: 'http://www.csi.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcST-V2o6J7lpiadRcIBZ7O0Cm96b9rpEcUIr0fM2FF7jpnA_JX2Q2PrcLY',
    id: 8828,
    acronym: 'CSI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cwidaho.cc',
    name: 'College of Western Idaho',
    web_page: 'http://cwidaho.cc/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqMDTeXMvceQmfABKfaVmYo8Bey9RWDw3z9MJC0yf_DrwU_FX0nSY8jA',
    id: 8829,
    acronym: 'CWI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eitc.edu',
    name: 'Eastern Idaho Technical College',
    web_page: 'http://www.eitc.edu',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQ8kvTuMi9-CMpdbPbaNt1yD18trrAkjT4UKlbH9WdjdQW9ZulHUQdtJw',
    id: 8830,
    acronym: 'EITC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nic.edu',
    name: 'North Idaho College',
    web_page: 'http://www.nic.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1hgC2UiP1ixY90e2NkfOha-glHoa5XHLNlnHdN6Az-Sc3DhGTE7zmBOY',
    id: 8831,
    acronym: 'NIC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bhc.edu',
    name: 'Black Hawk College',
    web_page: 'http://www.bhc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvx3anjH2l4hrQMPawUpR0xzcE8w8bAU9eJDlIkfsK8rtfrQ6deODHbk0',
    id: 8832,
    acronym: 'BHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sandburg.edu',
    name: 'Carl Sandburg College',
    web_page: 'http://www.sandburg.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpnoMaTzohkCcKhKENJjyM_jLwjZXMjOA1tHAEp1UDEj9pXA-nnPq9Ow',
    id: 8833,
    acronym: 'CSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccc.edu',
    name: 'City Colleges of Chicago-​Harold Washington College',
    web_page: 'http://www.ccc.edu/colleges/washington/Pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ9dD2K4bHLKuIjnLMTgJHeZB-ZYiH8Ea9EiVdEx4XQGnjAK-NKBc3t7Y',
    id: 8834,
    acronym: 'CCCWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccc.edu',
    name: 'City Colleges of Chicago-​Harry S Truman College',
    web_page: 'http://ccc.edu/colleges/truman/pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ9dD2K4bHLKuIjnLMTgJHeZB-ZYiH8Ea9EiVdEx4XQGnjAK-NKBc3t7Y',
    id: 8835,
    acronym: 'CCCSTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccc.edu',
    name: 'City Colleges of Chicago-​Kennedy-​King College',
    web_page: 'http://www.ccc.edu/colleges/kennedy/pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzbr_9XWRXDUQpxMFtfDSZTGH2N_nsxT5sVFZ3o4EIcEYk1nWYAuXBjEw',
    id: 8836,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccc.edu',
    name: 'City Colleges of Chicago-​Malcolm X College',
    web_page: 'http://www.ccc.edu/colleges/malcolm-x/pages/default.aspx',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQQxzlq4TMisRjcQOSDy1g6boMHwsIzxQXOaepHWleT1bfvnxAD-edMDQY',
    id: 8837,
    acronym: 'CCCXC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccc.edu',
    name: 'City Colleges of Chicago-​Olive-​Harvey College',
    web_page: 'http://ccc.edu/colleges/olive-harvey/pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ9dD2K4bHLKuIjnLMTgJHeZB-ZYiH8Ea9EiVdEx4XQGnjAK-NKBc3t7Y',
    id: 8838,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccc.edu',
    name: 'City Colleges of Chicago-​Richard J Daley College',
    web_page: 'http://ccc.edu/colleges/daley/pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJ9dD2K4bHLKuIjnLMTgJHeZB-ZYiH8Ea9EiVdEx4XQGnjAK-NKBc3t7Y',
    id: 8839,
    acronym: 'CCCJDC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccc.edu',
    name: 'City Colleges of Chicago-​Wilbur Wright College',
    web_page: 'http://ccc.edu/colleges/wright/pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSA-5R68EdrEjCruq0DeP_r6A9YylOrHDZr12dL0K71YHvRnBQCl47w_Q',
    id: 8840,
    acronym: 'CCCWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dupage.edu',
    name: 'College of DuPage',
    web_page: 'http://www.dupage.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbCX-EdFfHrZTkwY9iFaBEVNi6IXXmXyiV4jvpIwe3UUv6mpKVlXejaTM',
    id: 8841,
    acronym: 'CD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.clcillinois.edu',
    name: 'College of Lake County',
    web_page: 'http://WWW.CLCILLINOIS.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDvjAt3Tim_ZQRjhOIE-t5JrPuqynpARGmE8Y0naiYtiewMTFRipezg50',
    id: 8843,
    acronym: 'CLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dacc.edu',
    name: 'Danville Area Community College',
    web_page: 'http://www.dacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsBHUhSqhe3axzj7tqXgaWIOn3oN_hzXEESyE2rC3-dgw-213y6nINXw',
    id: 8844,
    acronym: 'DACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'elgin.edu',
    name: 'Elgin Community College',
    web_page: 'http://www.elgin.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMkuwFTrjZpmX5MWR7Sw0ErvnK817ytRc8NBjuZMzXH_1cDo3ngAPZsw',
    id: 8845,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'foxcollege.edu',
    name: 'Fox College',
    web_page: 'http://www.foxcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWPumwmRl-f6gFFFXSreT0fQ7opCdXbSkHhdKYWhFvjk7cUJbvrGJ4fQ',
    id: 8846,
    acronym: 'FC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'harpercollege.edu',
    name: 'Harper College',
    web_page: 'http://www.harpercollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF9M-uaYbZYyQg6kgHb3g8NkU7Au00CAqm4DM0pkwTj5JKVnr3K7jHTw',
    id: 8847,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'heartland.edu',
    name: 'Heartland Community College',
    web_page: 'http://www.heartland.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTweRHHZal7cLJ5nOPHSyOR9jN2DDdWRL8o9Hd4EmwO-yWXvRpVJ9xR7aQ',
    id: 8848,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'highland.edu',
    name: 'Highland Community College',
    web_page: 'http://www.highland.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ1do10LezIBeGSAYRhp4bZ5ktQQErWpZXqxr1PF2N4gzR_TL5rTGGgA',
    id: 8849,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'icc.edu',
    name: 'Illinois Central College',
    web_page: 'http://www.icc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3ueVWgKg3gd0l_7LXL86XurA3V-yRPSDU_DD1RSmcDzuOStxnSMNgaik',
    id: 8850,
    acronym: 'ICC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ivcc.edu',
    name: 'Illinois Valley Community College',
    web_page: 'http://www.ivcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXEg_jOkvCHil0tIXbCVGx9gtjiE8VZaNWu7GpLzse7-UYtZhqwv-NR-8',
    id: 8851,
    acronym: 'IVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jalc.edu',
    name: 'John A Logan College',
    web_page: 'http://www.jalc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvEL4KdsS4_cIIwbJJ1h9xkpOGANaD4GZXMiCVnJzKRCZeOy5E6ZpDC6s',
    id: 8852,
    acronym: 'JALC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jwcc.edu',
    name: 'John Wood Community College',
    web_page: 'http://www.jwcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHmmgVYtnZYWnyK_h0Xm70CdH2J_yW49szP85VM9FJJN-EBpcdkn-Lvg',
    id: 8853,
    acronym: 'JWCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jjc.edu',
    name: 'Joliet Junior College',
    web_page: 'http://www.jjc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS57S4OF2LXhykJ9SIKUDoDdVDvsaAhpP1ZiCTWO7k1qf_GOJYrm1NKh5U',
    id: 8854,
    acronym: 'JJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kcc.edu',
    name: 'Kankakee Community College',
    web_page: 'http://www.kcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRop9h_n3xuToXJpe2DTNEa1mkMj4KP6g4cqHMzlU8V4FNMRxU_orWOH3c',
    id: 8855,
    acronym: 'KCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kaskaskia.edu',
    name: 'Kaskaskia College',
    web_page: 'http://www.kaskaskia.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSzwhzTdhgH2fGpzzY565Wqxj6zHPwE0BTj7A3I7yT6Hb3LEqAkXY-7xo',
    id: 8856,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kishwaukeecollege.edu',
    name: 'Kishwaukee College',
    web_page: 'http://www.kishwaukeecollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxxna_NeI5FGNnHNEw3D5a5xXWSYgZVOjv9LqegHUjBUh3gLpuI_WiiA',
    id: 8857,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lakeland.cc.il.us',
    name: 'Lake Land College',
    web_page: 'http://www.lakeland.cc.il.us',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBEqwHAvOk6KwiFTG7CMs-urfdx3yxhUHyTMA6XVg0QMc8uiQ-MVlBgdw',
    id: 8858,
    acronym: 'LLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chefs.edu',
    name: 'Le Cordon Bleu College of Culinary Arts-​Chicago',
    web_page: 'http://www.chefs.edu/chicago',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2blMSYZXWdQ460kJ0GYDBxpPbPoVWjbsEN_otCLj0sSGKINTmBpVlPjQW',
    id: 8859,
    acronym: 'LCBCCA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lc.edu',
    name: 'Lewis and Clark Community College',
    web_page: 'http://www.lc.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQqOJoOTQdrrft7B5svA3TzLKjOSF9U4PRgfsAxduO07SPRs1H_rpfy9_U',
    id: 8860,
    acronym: 'LCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'llcc.edu',
    name: 'Lincoln Land Community College',
    web_page: 'http://www.llcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjschooezR-PVymUNItgOrwVH9vLKSYf9seznkpQwNrrEXE7ftx9dyBa14',
    id: 8861,
    acronym: 'LLCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'maccormac.edu',
    name: 'MacCormac College',
    web_page: 'http://www.maccormac.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThzYEqiTmC6j0QgqwaP3KPfdHcsqOH3i3wO0YPF8epsXWltNuWYPLi6f8',
    id: 8862,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mchenry.edu',
    name: 'McHenry County College',
    web_page: 'http://www.mchenry.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyMcYANiU_mnG1v68TdWthaJUoQUQM5ckXnwfZlw_vbi10Bg69-oKcY0Q',
    id: 8863,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'morainevalley.edu',
    name: 'Moraine Valley Community College',
    web_page: 'http://www.morainevalley.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBLnQG2rDZKESmZJKGLTIfsKcFhOInic91JqmO-VLptGKob9OsSedX4ho',
    id: 8864,
    acronym: 'MVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'morton.edu',
    name: 'Morton College',
    web_page: 'http://www.morton.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThELxzrY85U249pNHLWAkJoVXpKJQ7vZgwSrpt_viiol8rHgIWspw9n-c',
    id: 8865,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northwesterncollege.edu',
    name: 'Northwestern College-​Chicago Campus',
    web_page: 'http://www.NorthwesternCollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNLjgwsvnBF1xb7TZuj6ugK9Ue25AAtg1pH42RHrcJIfgcrbveMqh3g14r1w',
    id: 8866,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oakton.edu',
    name: 'Oakton Community College',
    web_page: 'http://www.oakton.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzC7K4Y6-nik3SP_95dYgdw3byP1ZkIM-m4xaWd6hYVTUhVpxa8XrwxQ',
    id: 8867,
    acronym: 'OCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'parkland.edu',
    name: 'Parkland College',
    web_page: 'http://www.parkland.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZZt7vwzUKuZSpUGIHK8_0PGkUNrigWJOWzxW9hLnMjI7GiOuGOePJSQ',
    id: 8868,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'prairiestate.edu',
    name: 'Prairie State College',
    web_page: 'http://www.prairiestate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9qgW8H17pSrrb0O0CQclSeIoy-WfPdNGduUY_u32yC5lQZuRxzi_foxwP',
    id: 8869,
    acronym: 'PSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rlc.edu',
    name: 'Rend Lake College',
    web_page: 'http://www.rlc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAxB1s7907bb0LjEAkGb2AZNhCKjE0YJADgpzYKjnxbv_TqG9PoZFpkA',
    id: 8870,
    acronym: 'RLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'richland.edu',
    name: 'Richland Community College',
    web_page: 'http://www.richland.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReToV6ZdNmQemXweei_0dX2wvVq2BAG8YobVJNaJ63ETZg5N3TEgUMGg',
    id: 8871,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rockvalleycollege.edu',
    name: 'Rock Valley College',
    web_page: 'http://www.rockvalleycollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7G0L5-VRyhZD1nOoukxEq2wiLwXuUyTgN8BtkegQuYnuJPgsld9BtO5I',
    id: 8872,
    acronym: 'RVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'svcc.edu',
    name: 'Sauk Valley Community College',
    web_page: 'http://www.svcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaCfAYue1B_NrRmS5Y8r8Yf0xTfUCVwKpIuJTYxGTks1-jdofkJ1DW0iw',
    id: 8873,
    acronym: 'SVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'shawneecc.edu',
    name: 'Shawnee Community College',
    web_page: 'http://www.shawneecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmiZwscKfc60bJp6Qz9frAXYmcWHywloaskBeh4E2A_lYV-zF26oIObpY',
    id: 8874,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sic.edu',
    name: 'Southeastern Illinois College',
    web_page: 'http://www.sic.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0BhhnKwxcqqTV3yLkzttquGsNGB-Epw_EIqn3EZKydQQ2jm9J5Axi9hc',
    id: 8875,
    acronym: 'SIC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ssc.edu',
    name: 'South Suburban College',
    web_page: 'http://www.ssc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiIYiCO07R9NfJa7Kef6AMMfbVaLoErLrDuNggZIVkrtENe5CPAOyPOQ',
    id: 8876,
    acronym: 'SSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swic.edu',
    name: 'Southwestern Illinois College',
    web_page: 'http://www.swic.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP6W6UPCeQkq0vfhTIuyrPhVeSx0fNsd-n5jUGx-kJLoRq4H9HpUeO5D4',
    id: 8877,
    acronym: 'SIC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'src.edu',
    name: 'Spoon River College',
    web_page: 'http://www.src.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRM2s0PtLwggF2ppkzefSVS--iRLSYUZmyqD6TyXBRTayRPXRZwbAiue4SE',
    id: 8878,
    acronym: 'SRC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'triton.edu',
    name: 'Triton College',
    web_page: 'http://www.triton.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ83XjrpTBF8Rh3YXUPLWEgOyoBodlxiqfvvAkzFcBiB0OTitnC4rdUAw',
    id: 8879,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ancilla.edu',
    name: 'Ancilla College',
    web_page: 'http://www.ancilla.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-d29Q4zv2hJ1ehKY4OYTJvZdhByyRgXZFn1h6IYRaBnbeWLWdlpAbcA',
    id: 8880,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ivytech.edu',
    name: 'Ivy Tech Community College',
    web_page: 'http://www.ivytech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTL1PhO6OU9Dt2iVpUqP1ZzYbEU3zQS0UlwKzrmv5vYfE_MKWLVwxuR8Q',
    id: 8881,
    acronym: 'ITCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dmacc.edu',
    name: 'Des Moines Area Community College',
    web_page: 'http://www.dmacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJHeZhGoYS-zhkqSZyxNZ_I0U7p8HjPJxM5mRSwgAmgBOyDQ98dbpuxl8I',
    id: 8882,
    acronym: 'DMACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eicc.edu',
    name: 'Eastern Iowa Community College District',
    web_page: 'http://www.eicc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3R45aAUSZ3vYwRd1BmaloZoz4TgLZ9DM0ikiDU4EaCoDq_aEysNbogFY',
    id: 8883,
    acronym: 'EICCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hawkeyecollege.edu',
    name: 'Hawkeye Community College',
    web_page: 'http://www.hawkeyecollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoOXVAgT-GnnzgnmgHUwUIcvDkSaCh0SzeC4owZl2t56P1ITIEGdf3aVxadg',
    id: 8884,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'indianhills.edu',
    name: 'Indian Hills Community College',
    web_page: 'http://www.indianhills.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcwSXlH_GUxgu6QWaJGu6qK4-w6slxP-wT0pHumizsIIRJxe7g5U7mJcg',
    id: 8885,
    acronym: 'IHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iowacentral.edu',
    name: 'Iowa Central Community College',
    web_page: 'http://www.iowacentral.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTac_vonvk_VB8VS1rgz36HTqHP-IkwQYWm1OmWCOrzRvByCHkUAN8Jyow',
    id: 8886,
    acronym: 'ICCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iowalakes.edu',
    name: 'Iowa Lakes Community College',
    web_page: 'http://www.iowalakes.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS98FdmuWQm7_OxNYByZSWuumxsA5VOT5ZXC4TzFqZPOFOX1GUOKsmd',
    id: 8887,
    acronym: 'ILCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iwcc.edu',
    name: 'Iowa Western Community College',
    web_page: 'http://www.iwcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnx0yMzPAViGejaV7TD35C3UXFBgSe-yBkm4JKAtZCxxcfmKd-cUHRjvw',
    id: 8888,
    acronym: 'IWCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kirkwood.edu',
    name: 'Kirkwood Community College',
    web_page: 'http://www.kirkwood.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTn-ypzMzBaSXpktpZY405INJElXEvaZC7YKWjUN2VwafuY1ChQlJol5w',
    id: 8889,
    acronym: 'KCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iavalley.edu',
    name: 'Marshalltown Community College',
    web_page: 'http://www.iavalley.edu/mcc/index.html',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu39fZKEB0WBATC_z3RvLlUb_vU6uMpxlULD7izgwXn6ruGnOnw4sdYg',
    id: 8890,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nicc.edu',
    name: 'Northeast Iowa Community College-​Calmar',
    web_page: 'http://www.nicc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbhiRicSvsOkgphZFY7S8nL2c_4N3AwPSVY4TqTQvKGRF9xIPu5UOTQomy',
    id: 8891,
    acronym: 'NICC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'niacc.edu',
    name: 'North Iowa Area Community College',
    web_page: 'http://www.niacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUqUHXEpGYxtEC-zn07wknXXkKnt9ZIFUg2ae3axp4X449BPMVgrdm2Yk',
    id: 8892,
    acronym: 'NIACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwicc.edu',
    name: 'Northwest Iowa Community College',
    web_page: 'http://www.nwicc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSVf887CRMBKu-8b-OWTxDw8KtgB5Y-odyBaoZNv0E5ejipu0lFfB20Dg',
    id: 8893,
    acronym: 'NICC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'scciowa.edu',
    name: 'Southeastern Community College',
    web_page: 'http://www.scciowa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQL-ZddW-me3R6R5U8YNwMp6Xa_ZU_sR6Rj9KZQOHylEVvd11FIjU6I05oC',
    id: 8894,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swcciowa.edu',
    name: 'Southwestern Community College',
    web_page: 'http://www.swcciowa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQunDeGc0gMVqyhUUeAquwtI_bSWsitmXYNjnZKynGvPynKFNQzjVIbhTbl',
    id: 8895,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'witcc.edu',
    name: 'Western Iowa Tech Community College',
    web_page: 'http://www.witcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnE1riN1Keo5wNf5mCF2nmOf8QMqdQ9v-JWsg_fciUfqDKRQdBqnxElRI',
    id: 8896,
    acronym: 'WITCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'allencc.edu',
    name: 'Allen County Community College',
    web_page: 'http://www.allencc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-kKasSx2gpEwZ6LFdUI5rQMHDlxdkwtstQuumzghj-xnNuXUTeur4HJ4',
    id: 8897,
    acronym: 'ACCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bartonccc.edu',
    name: 'Barton County Community College',
    web_page: 'http://www.bartonccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiIfRt5jR7Wbg1j0aK0KtYNnZennHPgMTCDsatKo8cup1uqDVXBq3JOjo',
    id: 8898,
    acronym: 'BCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'butlercc.edu',
    name: 'Butler Community College',
    web_page: 'http://www.butlercc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTb3R3alw8Q77SEAsuWpwZ2BkEsM04xZDI6HBPjG1ywsMX8Jmnf84WirE',
    id: 8899,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cloud.edu',
    name: 'Cloud County Community College',
    web_page: 'http://www.cloud.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJaIYEp5SVP21e67qZ94OPzWKI1NvvrIukhdQvh1ZpCnN4k-Nnnn20A8Y',
    id: 8900,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coffeyville.edu',
    name: 'Coffeyville Community College',
    web_page: 'http://www.coffeyville.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPUXfVYh4B5SSrCQhYsdGubaLhy63g2cJtlOyJu3lp_bpLLPg91fqmbNw',
    id: 8901,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'colbycc.edu',
    name: 'Colby Community College',
    web_page: 'http://www.colbycc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCAud-nbDYYbSDfijZS2e7WVuQtf-GyGpSnCU5ubPt4CozSRFf5-NlH9E',
    id: 8902,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cowley.edu',
    name: 'Cowley County Community College',
    web_page: 'http://www.cowley.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTt4cbu2vq-dZHwfh2AvBqGOU8dpaxuvjir-Y1cZp9xp23RU8vWsponDA',
    id: 8903,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dc3.edu',
    name: 'Dodge City Community College',
    web_page: 'http://www.dc3.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqGMja284oaaJFhrLAMrF_t84uYiyfEMKEVPs_D9_W5W5GGxy2eFbcFg',
    id: 8904,
    acronym: 'DCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fhtc.edu',
    name: 'Flint Hills Technical College',
    web_page: 'http://www.fhtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXBeAgDz_1i7hxb-jAopf6TT5bX52Ij-sysTVAlH1mu8ARQthJCUPN3Q',
    id: 8905,
    acronym: 'FHTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fortscott.edu',
    name: 'Fort Scott Community College',
    web_page: 'http://www.fortscott.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTktfOmtO4ps1ORGmAL7vXcFMgHanc0VOfyq0V9M87_9tlv1D3C-UTjQ1A',
    id: 8906,
    acronym: 'FSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gcccks.edu',
    name: 'Garden City Community College',
    web_page: 'http://www.gcccks.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTR6s3L6YRUbaPwfLctWPWrxzJO43Is5ydOP18z4OzxCh35c0-88spaSw',
    id: 8907,
    acronym: 'GCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hutchcc.edu',
    name: 'Hutchinson Community College',
    web_page: 'http://www.hutchcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_VvbMu2cR8i-Jj8bal97KrxMfXSUjWldEN1IqP-BnyVDB00Pt0IjQoQI',
    id: 8909,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'indycc.edu',
    name: 'Independence Community College',
    web_page: 'http://www.indycc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQphxNeXAPjJIeGp-LPwaKtTqAZ1yxfYPdHsinOoltnmPaEzV6f_tdxVvc',
    id: 8910,
    acronym: 'ICC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jccc.edu',
    name: 'Johnson County Community College',
    web_page: 'http://www.jccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSw4QDA2jWXxIwCQThn4fSVpMeVLcDvqAQJuau4uztNP-0WYomCwQTX8w',
    id: 8911,
    acronym: 'JCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kckcc.edu',
    name: 'Kansas City Kansas Community College',
    web_page: 'http://www.kckcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_FMzYLhnyusKJ9a7c6IgxAbF19vTSd03cuo49bGOMAl5mv03DKI4c6g',
    id: 8912,
    acronym: 'KCKCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'labette.edu',
    name: 'Labette Community College',
    web_page: 'http://www.labette.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1jfCFbqIj_uXf2G3KDO6qhYnnvhTS6GDm9JQCny4mqG_cpdayV_s_uQ',
    id: 8913,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'manhattantech.edu',
    name: 'Manhattan Area Technical College',
    web_page: 'http://www.manhattantech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1uzC1FBbm-rvNfXckExtwthMLE7Iz0DeSfvmaLZ_Ov4jPa7Jpt7y7BA',
    id: 8914,
    acronym: 'MATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'neosho.edu',
    name: 'Neosho County Community College',
    web_page: 'http://www.neosho.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOFHLFtyd1RGQf9xAqWpu_n1psB0vwlGOPIXgFDSNFYMcEaTNLT81m7w',
    id: 8915,
    acronym: 'NCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncktc.edu',
    name: 'North Central Kansas Technical College',
    web_page: 'http://www.ncktc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDo8hfCGrZumBVF4GOPBIyXGKtaMSef-VaAzWZsXR8piDD4JSC3oWWZ5w',
    id: 8916,
    acronym: 'NCKTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwktc.edu',
    name: 'Northwest Kansas Technical College',
    web_page: 'http://www.nwktc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSEqmfv19aArRri_yW04hMc-otwH2JyxJ7eAFoVfh2YsjnpmTSic1pn8g',
    id: 8917,
    acronym: 'NKTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'prattcc.edu',
    name: 'Pratt Community College',
    web_page: 'http://www.prattcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSrq5goz2UDwBiNXjJYQjiLL3b_kFujjm_EAjDg-BBUcMmcQ-xUr-Jl1io',
    id: 8918,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sccc.edu',
    name: 'Seward County Community College and Area Technical School',
    web_page: 'http://www.sccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7u7TmKISLozHL7A9va2OMO7m-iHrRD51AyBn7FkAlN9Kgvkm0RcUALg',
    id: 8919,
    acronym: 'SCCCATS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'watc.edu',
    name: 'Wichita Area Technical College',
    web_page: 'http://www.watc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe6PsWB1Y-cPKpwIHK63P4YMF_qDqMmIlUsNqUeR6K1kADAaLsG8aXk0lX9A',
    id: 8920,
    acronym: 'WATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ashland.kctcs.edu',
    name: 'Ashland Community and Technical College',
    web_page: 'http://www.ashland.kctcs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4PjGHemZeIgt5Zeq1t_MDEAALGnQkwv-RzHHttM5v9dyrwqr26mjLGfii',
    id: 8921,
    acronym: 'ACTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bigsandy.kctcs.edu',
    name: 'Big Sandy Community and Technical College',
    web_page: 'http://www.bigsandy.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuVL_jIpvYZogr9VOCRbxLOJCQfsspmlV965fE4sm3UXLszOR6T26wqCs',
    id: 8922,
    acronym: 'BSCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bluegrass.kctcs.edu',
    name: 'Bluegrass Community and Technical College',
    web_page: 'http://www.bluegrass.kctcs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-cPl2CUt2K9MQ6WBMlv9L9XTloDHxBjB5HGrShtlDxeeT-xdMFy8x6sA',
    id: 8923,
    acronym: 'BCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'elizabethtown.kctcs.edu',
    name: 'Elizabethtown Community and Technical College',
    web_page: 'http://www.elizabethtown.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRX1iH6D7qvzTiRFagfv5F46-KjY_MERs7brxbLw1TFz4Nd1vpLM6fxMA',
    id: 8924,
    acronym: 'ECTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'galencollege.edu',
    name: 'Galen College of Nursing-​Louisville',
    web_page: 'http://www.galencollege.edu/louisville',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgPltg1EQuDWIYStDNNw2ZIJX4Vu1xCrGjFxxNlmgtCxEVERPiPIlQEmJC',
    id: 8925,
    acronym: 'GCN',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gateway.kctcs.edu',
    name: 'Gateway Community and Technical College',
    web_page: 'http://www.gateway.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTi1IkqnG9cRWni3WvrIfF4cPc9pOjGgVWtOVdNthYu9DL1yxMdUDtbWXU',
    id: 8926,
    acronym: 'GCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hazard.kctcs.edu',
    name: 'Hazard Community and Technical College',
    web_page: 'http://www.hazard.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKzcL5XisSv_CZv3Yl5wcI_BHRD_D6EHQk7_gB7Kj7EW5nBRg83mTafsQ',
    id: 8927,
    acronym: 'HCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hencc.kctcs.edu',
    name: 'Henderson Community College',
    web_page: 'http://www.hencc.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSEpCSwR7yCMCb34uYMUdhRFqJRTjAIWSSVyPoi1RBkW7l33KJzx9_dCo',
    id: 8928,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hopkinsville.kctcs.edu',
    name: 'Hopkinsville Community College',
    web_page: 'http://hopkinsville.kctcs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqINGowdvnHTwpIxc5pXRCoAr9_C6Zg7v3HkIJdo3yrh60HrutH0F3nxw',
    id: 8929,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jefferson.kctcs.edu',
    name: 'Jefferson Community and Technical College',
    web_page: 'http://www.jefferson.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkkO7oiaGs8VwoC4MArH8MQ0Qe3np6qTz_8esSDjMWCJRkAsYVD330MQ',
    id: 8930,
    acronym: 'JCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'madisonville.kctcs.edu',
    name: 'Madisonville Community College',
    web_page: 'http://www.madisonville.kctcs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcfYmlwzUXQxiER0AKfxRzeXb-Itnk651LgPdlnOq84PtZZKuC9DwrUg',
    id: 8931,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'maysville.kctcs.edu',
    name: 'Maysville Community and Technical College',
    web_page: 'http://maysville.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTafGobF5Z92x9g4vTUdz5013aoP13fFppC2TmBI5uoFB9PHKb2yH5kbw',
    id: 8932,
    acronym: 'MCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'owensboro.kctcs.edu',
    name: 'Owensboro Community and Technical College',
    web_page: 'http://www.owensboro.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-wq8jb4bt8RAamY4E2oqGin41t4sKxB87flA_Fapmb177wkQbzHs2D_0',
    id: 8933,
    acronym: 'OCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'somerset.kctcs.edu',
    name: 'Somerset Community College',
    web_page: 'http://www.somerset.kctcs.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSO2o0PdBWCdKnPMjr0pHTjlMmdmgj2jwB0DeZWo22hIXiryPi3FanQT1p',
    id: 8934,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bowlinggreen.kctcs.edu',
    name: 'Southcentral Kentucky Community and Technical College',
    web_page: 'http://www.bowlinggreen.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkq59jpeSbQtklvQBLm-S9m1vUd4zG9QZrtU2EnQrhlSx2KOyZ1pZCyII',
    id: 8935,
    acronym: 'SKCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southeast.kctcs.edu',
    name: 'Southeast Kentucky Community and Technical College',
    web_page: 'http://www.southeast.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkq59jpeSbQtklvQBLm-S9m1vUd4zG9QZrtU2EnQrhlSx2KOyZ1pZCyII',
    id: 8936,
    acronym: 'SKCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westkentucky.kctcs.edu',
    name: 'West Kentucky Community and Technical College',
    web_page: 'http://www.westkentucky.kctcs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkq59jpeSbQtklvQBLm-S9m1vUd4zG9QZrtU2EnQrhlSx2KOyZ1pZCyII',
    id: 8937,
    acronym: 'WKCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mybrcc.edu',
    name: 'Baton Rouge Community College',
    web_page: 'http://www.mybrcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdgU2pTzqR0OFVctwC-lqafabcRHp1aYFWxgt0C1mfgzyozjBREF2KXw',
    id: 8938,
    acronym: 'BRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bpcc.edu',
    name: 'Bossier Parish Community College',
    web_page: 'http://www.bpcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdBPW2c_b-4Xv1W1DYUrvx6t6vVG2WHZr_hRRxAd-ZXqwORwY5pzMOi3Q',
    id: 8939,
    acronym: 'BPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.dcc.edu',
    name: 'Delgado Community College',
    web_page: 'http://WWW.DCC.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPdVsgiEFyy3BINXynIjaZRB0ExSBtYX51sNutSMLsV2Fbm6Z34NY34g',
    id: 8940,
    acronym: 'DCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fletcher.edu',
    name: 'L E Fletcher Technical Community College',
    web_page: 'http://www.fletcher.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtDsxwko8LgX4WelRn1L6Zb-w3cjPOYH_R8ns3DLlml85-Xe9jm8dgSyM',
    id: 8941,
    acronym: 'LEFTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ladelta.edu',
    name: 'Louisiana Delta Community College',
    web_page: 'http://www.ladelta.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn3gQ7aulmoZ9St0zWj3gfweKmAUJbtcQL_vhdR7YaBnePyiuTHHWSEw',
    id: 8942,
    acronym: 'LDCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lsue.edu',
    name: 'Louisiana State University-​Eunice',
    web_page: 'http://www.lsue.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM7RJ3eLjyA2UIiYeJTmvQ7UwTRbG0Wzl7vOK4uOmewCjkF37KyUmseTUS',
    id: 8943,
    acronym: 'LSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nunez.edu',
    name: 'Nunez Community College',
    web_page: 'http://www.nunez.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTbTwslUVziMR8LOxq19AnXE4jmg4jagMEKYqE-USZXfWK-nxxjZs_7v4',
    id: 8944,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rpcc.edu',
    name: 'River Parishes Community College',
    web_page: 'http://www.rpcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQorHDjOVVssSjIfE5hRvW4i041YEwUq4D6YNUrIKGWTOknAZi5NvX-_w',
    id: 8945,
    acronym: 'RPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'susla.edu',
    name: 'Southern University at Shreveport',
    web_page: 'http://www.susla.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiCp4-YOJZ6-R5HAqfJY-xeXIH3CLgkiPmvv4fgxdmeArN4uEcZPb16fQ',
    id: 8946,
    acronym: 'SUAS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'solacc.edu',
    name: 'South Louisiana Community College',
    web_page: 'http://www.solacc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS-ZQbpO_ELoyOhiUr0s9LRmqmf8rl-QDmH6pwPClvZYH8rdF1fPje1A',
    id: 8947,
    acronym: 'SLCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sowela.edu',
    name: 'SOWELA Technical Community College',
    web_page: 'http://www.sowela.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyjQaNF9iurEK2tYOgONX_ZlU1dye4M1knxkS6wqT4I9JjIcS_cB0A9XY',
    id: 8948,
    acronym: 'STCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cmcc.edu',
    name: 'Central Maine Community College',
    web_page: 'http://www.cmcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyZxHV9diDK3HVsgBfKR8Nx40Ju8L2oGzluRVwsm2VpsyCXlE8u3N88GE',
    id: 8949,
    acronym: 'CMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cmmccollege.edu',
    name: 'Central Maine Medical Center College of Nursing and Health Professions',
    web_page: 'http://www.cmmccollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm3S2A9ie2FqnzdM4AILUs8E3vfZBmnLdTk3kXxRRFfPwW5eWglYcOwA',
    id: 8950,
    acronym: 'CMMCCNHP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'emcc.edu',
    name: 'Eastern Maine Community College',
    web_page: 'http://www.emcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHN8vJPYm3x0OxhJmDp7F60ZsSZov1_F-3lBWkX3q_m34AsjlVxHxArQ',
    id: 8951,
    acronym: 'EMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kvcc.me.edu',
    name: 'Kennebec Valley Community College',
    web_page: 'http://www.kvcc.me.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5suqp2-UF4BX4M_ngQiKN9Fw7xCUgVReH5IZQEz-AMk9A-srQHw_ec0Q',
    id: 8952,
    acronym: 'KVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nmcc.edu',
    name: 'Northern Maine Community College',
    web_page: 'http://www.nmcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFHJZDxzfsyZTy7KE-5COT7JWjnMGRKSzw6euAUgq_eugn7lLq91UniSQ',
    id: 8953,
    acronym: 'NMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smccme.edu',
    name: 'Southern Maine Community College',
    web_page: 'http://www.smccME.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmhXBZch3sD6b3j0ujIVnkDxIdpineXDYAClWwkXTK08H21qI0P7ebDYsQSQ',
    id: 8954,
    acronym: 'SMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wccc.me.edu',
    name: 'Washington County Community College',
    web_page: 'http://www.wccc.me.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRIF8uTSMf__TGb2LteLsOTLz_d1YA5Yoq_KaQFX5kEwUiBw2Fn8p4Ev9U',
    id: 8955,
    acronym: 'WCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'yccc.edu',
    name: 'York County Community College',
    web_page: 'http://www.yccc.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRJVZR5JEFqpamnsvandiQQproCFuM8-u7evdebv_nhpmdeVsiPWKl_s8Q',
    id: 8956,
    acronym: 'YCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'allegany.edu',
    name: 'Allegany College of Maryland',
    web_page: 'http://www.allegany.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKV0_TXZmvTxN9c2tkqHzR6mEvhW-3sHF9VKwy4fNWxpSJC-9jnUOlWg',
    id: 8957,
    acronym: 'ACM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bccc.edu',
    name: 'Baltimore City Community College',
    web_page: 'http://www.bccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZbgw2phvQim-pImoZG6BEOHWrd2hkyNbj1ImhBaS9XRbBqzb2_DmWBm0',
    id: 8958,
    acronym: 'BCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carrollcc.edu',
    name: 'Carroll Community College',
    web_page: 'http://www.carrollcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnPPkSwipr43Po1OMuJIFZZOyuQN9F88I3pW9zx50cmDbfTWMsLEsVLg',
    id: 8959,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cecil.edu',
    name: 'Cecil College',
    web_page: 'http://www.cecil.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQpbHpT7Oxd4HH7lRt7a8Fm_-z6BypmV1YwXNOy_7N4hlHzeP2ZtZ1mg',
    id: 8960,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'csmd.edu',
    name: 'College of Southern Maryland',
    web_page: 'http://www.csmd.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTt743VJw3_P9XrIQHj_QEIMNr4YtAPK2589uP0rZwC3cOtQCLPgkTYg',
    id: 8961,
    acronym: 'CSM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'garrettcollege.edu',
    name: 'Garrett College',
    web_page: 'http://www.garrettcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQlxC8RrGm7XTCbABjOTc34DHJtHUvi8G6XzsdETX53GxtEGrO6xqYZQw',
    id: 8962,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pgcc.edu',
    name: "Prince George's Community College",
    web_page: 'http://www.pgcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTW1KZ6bbV9jzaEM2ifuvV8xO0bh_CxsERWDNxryzAjrb-Aq-ybw-UfnQ',
    id: 8963,
    acronym: 'PGCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'worwic.edu',
    name: 'Wor-​Wic Community College',
    web_page: 'http://www.worwic.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU2mV4i7kkYZ7oPrk3KBMyNmmAUjdrnEUC6Dc-t9MJv2np1vy1F64MH4I',
    id: 8964,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'berkshirecc.edu',
    name: 'Berkshire Community College',
    web_page: 'http://www.berkshirecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT41CdrUPeJFMaCBKSoXo3-4o3zHqA84iR-lnVhqjtwUfsbWQ3Q344wZw',
    id: 8965,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bristolcc.edu',
    name: 'Bristol Community College',
    web_page: 'http://bristolcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGgj93hcthxxP0tKyIzKv2NdS3EpTJPjsdRSPmgspKSvAcPgPU5Y-jkw',
    id: 8966,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bhcc.mass.edu',
    name: 'Bunker Hill Community College',
    web_page: 'http://www.bhcc.mass.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXYp2-pg3yrgfO6ZR7xGYFHAOcwy9XMrVdQksAon6yLfFshH8UcaWIA4I',
    id: 8967,
    acronym: 'BHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'capecod.edu',
    name: 'Cape Cod Community College',
    web_page: 'http://www.capecod.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE8Nzo9aZDqpYgHwdhwrFB1QBzbgX6-FW2aUg0sbtI07-mNez_bDw5YPE',
    id: 8968,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gcc.mass.edu',
    name: 'Greenfield Community College',
    web_page: 'http://www.gcc.mass.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3j7QPcUmXULcve_59HNqIxmWQ82D5HIkg4S3kC3FFEd9miUGDyrj2h-fQEw',
    id: 8969,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hcc.edu',
    name: 'Holyoke Community College',
    web_page: 'http://www.hcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSF-82gICZMKEt_gY4uO7QWGi5FfNwpB4oZouk6urc3pfVwxJl-UQ3FXx4',
    id: 8970,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'massbay.edu',
    name: 'Massachusetts Bay Community College',
    web_page: 'http://www.massbay.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGcdqfx7Af5WHRLYuBkhZB6ugnXthFL94PWZs71WfXBbg5PPq746uArbO-',
    id: 8971,
    acronym: 'MBCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'massasoit.mass.edu',
    name: 'Massasoit Community College',
    web_page: 'http://www.massasoit.mass.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQgh-JJ9If5XjpSz27EZC3peBx73HGsviPtrppJpVSLIISUoOicTkKiA',
    id: 8972,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mwcc.edu',
    name: 'Mount Wachusett Community College',
    web_page: 'http://MWCC.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSYdd4tHj_oMyV8XU-myabIqMXsb8OcSyKIuBG7fiEjWeGdqyTqelJmfuc',
    id: 8974,
    acronym: 'MWCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'necc.edu',
    name: 'Northern Essex Community College',
    web_page: 'http://www.necc.mass.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRsQfHndIRHUGdN-eCHL9kBb7Cncde0DjAYBBAIfwWDjmdTfBDV-kraeo',
    id: 8975,
    acronym: 'NECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northshore.edu',
    name: 'North Shore Community College',
    web_page: 'http://www.northshore.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTxnWBXl_cAbx-EwQcxBISfJzPshXzowtN_IIB8sxtQCG6FwtQapafeJ0',
    id: 8977,
    acronym: 'NSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'quincycollege.edu',
    name: 'Quincy College',
    web_page: 'http://www.quincycollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzrgLIlerPZNPNbe_D59SOpM_JvbZKccU5_c4sAu0-mWynCve6We262EA',
    id: 8978,
    acronym: 'QC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'qcc.edu',
    name: 'Quinsigamond Community College',
    web_page: 'http://www.qcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfjsAfYjAB0g5ZeS5cx6GY_DCUzSZO1ZF67RMaiaHY3wsLqzSpYQ2FGA',
    id: 8979,
    acronym: 'QCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rcc.mass.edu',
    name: 'Roxbury Community College',
    web_page: 'http://www.rcc.mass.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8itXS2X0r-Ex2iWizwCLh_bZsvxYKde_RGMrcM6RC1Kiy8QKol1hndw',
    id: 8980,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stcc.edu',
    name: 'Springfield Technical Community College',
    web_page: 'http://www.stcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUXqzDTEaq5kC6x5VAWhsUYmquRdM8XCi49KsQFUHC0Z1brIK3peQu-DM',
    id: 8981,
    acronym: 'STCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'urbancollege.edu',
    name: 'Urban College of Boston',
    web_page: 'http://www.urbancollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdfzRfGAjc3quJwMWUatBxQjQ89v-vkeu4Oou26VU1UmXZL6Xjr4FHUx8',
    id: 8982,
    acronym: 'UCB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alpenacc.edu',
    name: 'Alpena Community College',
    web_page: 'http://www.alpenacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZkKpQm_HtcmRU7d7Vw_8KKija5oZ24dmDjFA6eOCl5-AoBoQX8xT0gw',
    id: 8983,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'baycollege.edu',
    name: 'Bay de Noc Community College',
    web_page: 'http://www.baycollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTc1X8sDoVgacKne5WY-BDixGhQCSOhHU5ZnntXhrp0TlNde0La_-I7XmA',
    id: 8984,
    acronym: 'BDNCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bmcc.edu',
    name: 'Bay Mills Community College',
    web_page: 'http://www.bmcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDgnpSGFyBNMTSoBrGNkVQHs7q0HlQlFLsveDgrasJJGeTP1wsMydlmg',
    id: 8985,
    acronym: 'BMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'delta.edu',
    name: 'Delta College',
    web_page: 'http://www.delta.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeKLCasFpdmkktfXcsajqmYIWotZVTDfJsydD75N1W0RP3y_6MFhPYboty',
    id: 8986,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'glenoaks.cc.mi.us',
    name: 'Glen Oaks Community College',
    web_page: 'http://www.glenoaks.cc.mi.us/Pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRr4HVxYAwS6RPMwYJUoBs4_XBdm0jBwUHctlXQBcdSexL642CxdufPweY',
    id: 8987,
    acronym: 'GOCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gogebic.edu',
    name: 'Gogebic Community College',
    web_page: 'http://www.gogebic.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0OTmmNCqhtMs_FJf2pwg32lhfNol9f65iq_yLKvfwW_nJn71ZiYEEFaFR',
    id: 8988,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'grcc.edu',
    name: 'Grand Rapids Community College',
    web_page: 'http://www.grcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjzHf4w1uoyf2rgB0Ne--wGQN3kj_3It_g0h99Wu7krkNWVeV51dQ1HFg',
    id: 8989,
    acronym: 'GRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hfcc.edu',
    name: 'Henry Ford Community College',
    web_page: 'http://www.hfcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQaQuK9GUcKhG-wJGlKhm96SMJ8jYggVtfU7HS8HAnYUV3hpSCwVzQJ4A',
    id: 8990,
    acronym: 'HFCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jccmi.edu',
    name: 'Jackson College',
    web_page: 'http://www.jccmi.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyMw7uQ1WvGupAOuOxBrJJP4SSxbFQqYZ6TfX2u3bdXV_UlaAwLrGX1w',
    id: 8991,
    acronym: 'JC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kvcc.edu',
    name: 'Kalamazoo Valley Community College',
    web_page: 'http://www.kvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyNhUwsCQgkUQOJtU6rdRELjvVPEoG1IZUZrxMRmNxTcdgVCbYlCngve4',
    id: 8992,
    acronym: 'KVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kellogg.edu',
    name: 'Kellogg Community College',
    web_page: 'http://www.kellogg.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOPh3fZqOf_1V-gfmrjwq-m4tuxeravOkouBF-sCF8HBdABRZwD8YkdW4',
    id: 8993,
    acronym: 'KCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kbocc.org',
    name: 'Keweenaw Bay Ojibwa Community College',
    web_page: 'http://www.kbocc.org',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ471JomH-Jc_5wLYMZ7WY1AKdmpuSAhU9FDK7APJBC-2IMaM3gRI1vrDI',
    id: 8994,
    acronym: 'KBOCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kirtland.edu',
    name: 'Kirtland Community College',
    web_page: 'http://www.kirtland.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyePRyOM9JggcbrkTx0puzRMGOLqQSM7lQ1HHQgwUJCu3sBjXv45spew',
    id: 8995,
    acronym: 'KCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lakemichigancollege.edu',
    name: 'Lake Michigan College',
    web_page: 'http://www.lakemichigancollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoKtJtURkFEVUYLJDLx5tSnf90DsnTzHfGZ6AFPW0CEKcBh9M3vAGN0ac',
    id: 8996,
    acronym: 'LMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lcc.edu',
    name: 'Lansing Community College',
    web_page: 'http://www.lcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlG0FyW4xCVoD7dgXPurIog3goz1epz4V2yhLR3-pVqP8A2f8MhaCGPw',
    id: 8997,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'macomb.edu',
    name: 'Macomb Community College',
    web_page: 'http://www.macomb.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-vMRQE4zANTakGy79-fKBgIAbzVHpXHAWxDfuIP2PyvbkxEiS3iZjVsU',
    id: 8998,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'midmich.edu',
    name: 'Mid Michigan Community College',
    web_page: 'http://www.midmich.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7QEPecuV3wrrmxXFoRc1ilCNM2AMDtvEgw_2PbS6jKI6sH_ZeIUW_iw',
    id: 8999,
    acronym: 'MMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'monroeccc.edu',
    name: 'Monroe County Community College',
    web_page: 'http://www.monroeccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT3v7QRT18CoDGqhI5rnSpTnrUqzAoB2LVXqGK46-pA6G6AZxne3WLuy2E',
    id: 9000,
    acronym: 'MCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'montcalm.edu',
    name: 'Montcalm Community College',
    web_page: 'http://www.montcalm.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeaW8m0dFlcXbofJ92jAj9G-6EIV0TbqHz63OaJ3ubUYP8HoR0svpFYgs',
    id: 9001,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'muskegoncc.edu',
    name: 'Muskegon Community College',
    web_page: 'http://www.muskegoncc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjE8-RBxg2PWM0gshY4hCNAQGpffFXFipJunzXHlSHUpAckHxzTR09aks',
    id: 9002,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncmich.edu',
    name: 'North Central Michigan College',
    web_page: 'http://www.ncmich.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzh87MHIyaMl3pLdnucjLn13HUzjNILwEmIe8wszJol8LuUugkKz3lZg',
    id: 9003,
    acronym: 'NCMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'oaklandcc.edu',
    name: 'Oakland Community College',
    web_page: 'http://www.oaklandcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJ4-HtD5d11hFLpp8PMddyxAYNfXRDRW6dgallRVR7ASXm1NLR_Blrg8s',
    id: 9004,
    acronym: 'OCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sagchip.edu',
    name: 'Saginaw Chippewa Tribal College',
    web_page: 'http://www.sagchip.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS62zNlYvEzbkFM08cAOnpmOp22oisaa27cBdnqRjpzuXv74LpFwDfco2Y',
    id: 9005,
    acronym: 'SCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'schoolcraft.edu',
    name: 'Schoolcraft College',
    web_page: 'http://www.schoolcraft.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSneZkk4BDap1ub-JPYM1AbX0wM3O3jf8chZI-suly2-uzmqpNO2EsyZFgi',
    id: 9006,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swmich.edu',
    name: 'Southwestern Michigan College',
    web_page: 'http://www.swmich.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRRl9AjbrXqXy8nqGChHfst6UyHvGAHbpBkFH0jz3GPQNb2dlPBDMolQ4',
    id: 9007,
    acronym: 'SMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sc4.edu',
    name: 'St Clair County Community College',
    web_page: 'http://www.sc4.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZFaaBCYYz2iZbPbIhKplbwS3BzOaEXyCye35aLckKUKzGvtjP20F3IA',
    id: 9008,
    acronym: 'SCCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wccnet.edu',
    name: 'Washtenaw Community College',
    web_page: 'http://www.wccnet.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTDoyPHVwrzNSohJRM19vk97JEUg-wCkOLIvzPz6IUuTMgUrFqttwLX5SA',
    id: 9009,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wcccd.edu',
    name: 'Wayne County Community College District',
    web_page: 'http://www.wcccd.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFtaDDC0vLF9-2aetnAvNUC6BpkarY1Lh6m0hvQQ82-o4JKoK1jsRtlQ',
    id: 9010,
    acronym: 'WCCCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westshore.edu',
    name: 'West Shore Community College',
    web_page: 'http://www.westshore.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjDUzb9CpG7x2YUNin-TY_gxEtPHDPfQkmhIhIN9kxq-LHV58Pq5Bb6Ehh',
    id: 9011,
    acronym: 'WSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alextech.edu',
    name: 'Alexandria Technical & Community College',
    web_page: 'http://www.alextech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlefbm0fsMj-fgCIBuGyy6atAIoRPRrIXA9sDVmyRORbx3_TWm52RhZY0',
    id: 9012,
    acronym: 'AT&CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'anokaramsey.edu',
    name: 'Anoka-​Ramsey Community College',
    web_page: 'http://www.anokaramsey.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlGQuArYFkBNH5hOaHh8JR_LnZvVul7eR5eWUw5dkI3uG5jDXTr4O2nA',
    id: 9013,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'anokatech.edu',
    name: 'Anoka Technical College',
    web_page: 'http://www.anokatech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiSbkmHiM5a5fEw-xIaldFEnnToz4WWdUgowrbAxBwZgJRpdyK6MaVxVE',
    id: 9014,
    acronym: 'ATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.clcmn.edu',
    name: 'Central Lakes College-​Brainerd',
    web_page: 'http://WWW.CLCMN.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTF8zF0zkAA9jS3VGXAHm8sW5HzOs5z-n1d6FKdjlq5wZKJ380wzN7eplE',
    id: 9015,
    acronym: 'CLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'century.edu',
    name: 'Century College',
    web_page: 'http://www.century.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfnUJis2TRy84taUPnWTpfbRpCtHlu0sfnVHsqlJxIP3uiY94uHk0KPWw',
    id: 9016,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dctc.edu',
    name: 'Dakota County Technical College',
    web_page: 'http://www.dctc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZ_shJXQdGbSByYm09B8U88g7YTzKWfAOF46Y_q2SS1bpJO-9rG5oii6k',
    id: 9017,
    acronym: 'DCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fdltcc.edu',
    name: 'Fond du Lac Tribal and Community College',
    web_page: 'http://www.fdltcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpbrwfCzur5Y66H7LJt9TZeBo7E65ESuxxn_exlbOF8HcDzszwYPuNwA',
    id: 9018,
    acronym: 'FDLTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hennepintech.edu',
    name: 'Hennepin Technical College',
    web_page: 'http://www.hennepintech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo6Y1hTzy6xTeEyh16ss-Jm5apEb8Bh19mD_IlqCS3mYw7QMgEEH_O808',
    id: 9019,
    acronym: 'HTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hibbing.edu',
    name: 'Hibbing Community College',
    web_page: 'http://www.hibbing.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyVDCe61OPk39p9c1nM_wbDxlcSUqnjINqjUBz1L54N1tCn2ietR2svA',
    id: 9020,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'inverhills.edu',
    name: 'Inver Hills Community College',
    web_page: 'http://www.inverhills.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEQWaHEOVBSYEe5pmvnGNeJcPZnxYBcBmalkf6qkgXZfCB7k_lnGy3z5s',
    id: 9021,
    acronym: 'IHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'itascacc.edu',
    name: 'Itasca Community College',
    web_page: 'http://www.itascacc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeNahOqXMW8MLjJvdcwUU0WBT7i2WhfDJD6QqKJEflLtZADeQOvL9wRA',
    id: 9022,
    acronym: 'ICC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lltc.edu',
    name: 'Leech Lake Tribal College',
    web_page: 'http://www.lltc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5KCD6jjlbvw6SlmmS2K7577Eltt-pSK8Su9pnrd8GKBl3KaSPC7TUL84',
    id: 9023,
    acronym: 'LLTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mesabirange.edu',
    name: 'Mesabi Range Community and Technical College',
    web_page: 'http://www.mesabirange.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH1rE_rHQhaivhp9nKkVifFjC6qUwlUFCtxQau8VDnUOikMMQ4O6PyjQ',
    id: 9024,
    acronym: 'MRCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'minneapolis.edu',
    name: 'Minneapolis Community and Technical College',
    web_page: 'http://www.minneapolis.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHYNUowMXHPWqW5FmT6cC1SuolX4sgLJBbzl_YKJYwJUalZVFSrCFMUcTPMw',
    id: 9025,
    acronym: 'MCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southeastmn.edu',
    name: 'Minnesota State College-​Southeast Technical',
    web_page: 'http://www.southeastmn.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmmc5xk72zZj8fKJ4HTh8xbtpTKdHpF3S9UWPImFMqWnvf8eWO1EZv_Qk6',
    id: 9026,
    acronym: 'MSCT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'minnesota.edu',
    name: 'Minnesota State Community and Technical College',
    web_page: 'http://www.minnesota.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuUQzK1pTJ732vBVzX2OuZ6ZTICjLX4Zxnqqa2fWm2xRzyGtBC1OQReJ5s',
    id: 9027,
    acronym: 'MSCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mnwest.edu',
    name: 'Minnesota West Community and Technical College',
    web_page: 'http://www.mnwest.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ13MnIOlbKaJdy1TUtgMuRRqldn3JH3woJbki8g9zg-sRGCC7w_zesONvx',
    id: 9028,
    acronym: 'MWCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'normandale.edu',
    name: 'Normandale Community College',
    web_page: 'http://www.normandale.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTylo1flU2kGiHOS6VirkjoBbXUvDpyqBYTYDzJaGkuNJZ-Cm2JKfDusq0',
    id: 9029,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nhcc.edu',
    name: 'North Hennepin Community College',
    web_page: 'http://www.nhcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbJO4hRo2fx72ImrM2mU4Jqqv6ZQW4BpUcy8DRD1qJO0U3iw2CaNQCUQ',
    id: 9030,
    acronym: 'NHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northlandcollege.edu',
    name: 'Northland Community and Technical College',
    web_page: 'http://www.northlandcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTieZ50Y0FO2gUavNcGyttNNKwBxJUsd07H6G1Obf0qyHDPMr3_tM6TFFio',
    id: 9031,
    acronym: 'NCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ntcmn.edu',
    name: 'Northwest Technical College',
    web_page: 'http://www.ntcmn.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWcw80dqK4VcgY55UhVCx881FDZfDrV-2yF4jJxuIqwp-nxlNE_SD_gzdc',
    id: 9032,
    acronym: 'NTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pinetech.edu',
    name: 'Pine Technical College',
    web_page: 'http://www.pinetech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoYILvzH2RP8BAULdet5LT7STDAH-HpjGEjMXNIzP0I1LEQOY-wHndMrQ',
    id: 9033,
    acronym: 'PTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rrcc.mnscu.edu',
    name: 'Rainy River Community College',
    web_page: 'http://www.rrcc.mnscu.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvsl1-a217TTRDq1Vy12UrhRUvhSOdscSbwcyMN1iDpT5r6sdshAkijRz_',
    id: 9034,
    acronym: 'RRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ridgewater.edu',
    name: 'Ridgewater College',
    web_page: 'http://www.ridgewater.edu/Pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgoPi-uooFB_szwp13OhPbHtHkMSBI4cHxkv8e8DhUb9SlyJ_z4MqkQw',
    id: 9035,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'riverland.edu',
    name: 'Riverland Community College',
    web_page: 'http://www.riverland.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrubW1nnNmUY4fgqfxHqUUBTdDiZm5fkl3iGlQqwdpV8IZ2lmA-NqS_Q',
    id: 9036,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rctc.edu',
    name: 'Rochester Community and Technical College',
    web_page: 'http://www.rctc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI2TFfjnk_DAdzyy3XVbUYyR_hwhfYvTL4FMP_Vxvv-JUznAwcB6vWPx4',
    id: 9037,
    acronym: 'RCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'saintpaul.edu',
    name: 'Saint Paul College',
    web_page: 'http://www.saintpaul.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNOGOdUpst4z2i18uT3tmcm2MngFfFWOr7PDo-iLpg85QnrOAVX7zEfg',
    id: 9038,
    acronym: 'SPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southcentral.edu',
    name: 'South Central College',
    web_page: 'http://www.southcentral.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHpCI8MkuOqYbGMtkRXuAHF8VcqB7wy8izmkbTxVM6b9xQEY5Q2jbC5A',
    id: 9039,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sctcc.edu',
    name: 'St Cloud Technical and Community College',
    web_page: 'http://www.sctcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQghYxiEVv-ND94Sp9_9BTyt6gKNOhmbYfuEN7kDmHmLYaI4wOXL9al28c',
    id: 9040,
    acronym: 'SCTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vcc.edu',
    name: 'Vermilion Community College',
    web_page: 'http://www.vcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmCDzweBAHoZJat1v5lvtQAFl_E7Wby1yqvGkqUhvQJlPBIkzFC4kfc5o',
    id: 9041,
    acronym: 'VCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wetcc.edu',
    name: 'White Earth Tribal and Community College',
    web_page: 'http://www.wetcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-a-DsjiSYBgqXzZKC2lXNocGA4C69vz3kNwD9MYXzdrFLhbmaI3LZSwlT',
    id: 9042,
    acronym: 'WETCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coahomacc.edu',
    name: 'Coahoma Community College',
    web_page: 'http://www.coahomacc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlfHHdE-V5HGhPeypSfPW6dHm8Bud1Ub7oC8mCQ0lLnkKW6khN1lHUSA',
    id: 9043,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'colin.edu',
    name: 'Copiah-​Lincoln Community College',
    web_page: 'http://www.colin.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVpY8LNk1Ocfzs0UdKSvFJ9Aiscs64Z1AKxl8hq5a8e5l4iXMVEWo8JA',
    id: 9044,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eccc.edu',
    name: 'East Central Community College',
    web_page: 'http://www.eccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBAwTN8weQddvDfgTOVFgTTm8hEJ7jHVMdTo3EW_bXmk44zKXUU1rj-g',
    id: 9045,
    acronym: 'ECCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eastms.edu',
    name: 'East Mississippi Community College',
    web_page: 'http://www.eastms.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTH9uJceiqbEzDDFnNgUGm3GDFJDxikIGW2tx6seFTMfXWf3pqz1Vnlsf8',
    id: 9046,
    acronym: 'EMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hindscc.edu',
    name: 'Hinds Community College',
    web_page: 'http://www.hindscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXuHkt08L54QcO4juHLNNXMaBOsNshFX9Ztmoz0Ho0TNnsu6Z8Iyealm17',
    id: 9047,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'holmescc.edu',
    name: 'Holmes Community College',
    web_page: 'http://www.holmescc.edu',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcTDr_sGOx7V6XBrI56dNCiBnZ-4PEeAdMQGaweXd9tABlcuZEWgjbuitF8',
    id: 9048,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'iccms.edu',
    name: 'Itawamba Community College',
    web_page: 'http://www.iccms.edu/Default.aspx?tabid=55',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfwmJ6Aue2WsV3V2yl1ujCMWZXgRv-8vJsacbpb1ct4tHpNpU-X14vaw',
    id: 9049,
    acronym: 'ICC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jcjc.edu',
    name: 'Jones County Junior College',
    web_page: 'http://www.jcjc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSR_7fisX7P5tOdT6iRYPWxz778Jxfi6Eiihf0-ghl8vmnRgaSk1S_P9Pg',
    id: 9050,
    acronym: 'JCJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'meridiancc.edu',
    name: 'Meridian Community College',
    web_page: 'http://www.meridiancc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYLE4LOZIXF4DAfSWTp8yuVUbpw4v5zuVJ_OXfPZgYBpay9iLEAHKYsA',
    id: 9051,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'msdelta.edu',
    name: 'Mississippi Delta Community College',
    web_page: 'http://www.msdelta.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQT_ka_t8uShPVtcB8jkOMfYsw--nOlVbfQGnvwxBvueBr7xJTqx-2gqGw',
    id: 9052,
    acronym: 'MDCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mgccc.edu',
    name: 'Mississippi Gulf Coast Community College',
    web_page: 'https://www.mgccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRd6oDCDW1KTL5CYlu-BNhSV0StABCN6XwJgV4V-ymCsf_9zAgIPhY7fA',
    id: 9053,
    acronym: 'MGCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nemcc.edu',
    name: 'Northeast Mississippi Community College',
    web_page: 'http://www.nemcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRP6Mbjh0sbf8EHDibAxAhL9z4Ngr9D7mC9D6s6ALDGj0Xt-XeBC3CkvQ',
    id: 9054,
    acronym: 'NMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northwestms.edu',
    name: 'Northwest Mississippi Community College',
    web_page: 'http://www.northwestms.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSAT46uxHZLrYXEaWe8iLUSC4k00_ld73b6euHV71ExI96u0wyoaX2xwfM',
    id: 9055,
    acronym: 'NMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'prcc.edu',
    name: 'Pearl River Community College',
    web_page: 'http://www.prcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_rfkg9rR-WjaBmMd0HeDGt7Zr1B7ZuXLhfb78sw0xtRkewITWP0U1rWw',
    id: 9056,
    acronym: 'PRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smcc.edu',
    name: 'Southwest Mississippi Community College',
    web_page: 'http://www.smcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLGayAUP52TUDwXSGxbK-pdiYBaCalYoDNpBCnzcXX1Bew904_4SNrKw',
    id: 9057,
    acronym: 'SMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'crowder.edu',
    name: 'Crowder College',
    web_page: 'http://www.crowder.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDRv6QiTL2iX1dIOYMe646aq7PdaeEVln5DmC1_CfcpeRTzTHJ-t4o98FM',
    id: 9058,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eastcentral.edu',
    name: 'East Central College',
    web_page: 'http://www.eastcentral.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6J_NRTUyDj3v0IyhvrGAVSLZLwqmHYGVoPVuJfOeXBjAKMdFuWHMd7w',
    id: 9059,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jeffco.edu',
    name: 'Jefferson College',
    web_page: 'http://www.jeffco.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlqiBM_szneNcicZL_Fk3p3no8L1K8Ed6WaujUBzlt5LO0x8fxskIFwQ',
    id: 9060,
    acronym: 'JC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'linnstate.edu',
    name: 'Linn State Technical College',
    web_page: 'http://www.linnstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpDjnrV7hTadhFJkTmwvbc_rGFwYCRmPBcKJaGVjpYEuCBjWrPM1LGYqA',
    id: 9061,
    acronym: 'LSTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mineralarea.edu',
    name: 'Mineral Area College',
    web_page: 'http://www.mineralarea.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC9Jvvp-UH6OEsZ3Q0lFN8G-NVGtX4CqwfOnB-54sNpcuwcrt3FhU5ugI',
    id: 9062,
    acronym: 'MAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'missouristate.edu',
    name: 'Missouri State University',
    web_page: 'http://www.missouristate.edu',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15376college.jpg',
    id: 9063,
    acronym: 'MSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wp.missouristate.edu',
    name: 'Missouri State University-​West Plains',
    web_page: 'http://www.wp.missouristate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQADCz3AGVBltsTXHfYwPXfFLvEYFZlBsit9f2HIUXEjxfGsaSmNOJd2kE',
    id: 9064,
    acronym: 'MSUP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'macc.edu',
    name: 'Moberly Area Community College',
    web_page: 'http://www.macc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNdaR2fUndRWRJLSRQQBf-kkL7jBM6DimejstUBJna3LH5L0y3ksh3pr13',
    id: 9065,
    acronym: 'MACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncmissouri.edu',
    name: 'North Central Missouri College',
    web_page: 'http://www.ncmissouri.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1NclBFRXon3FohsxZgQ5uu5kg39F6fvCXx99UqDYB4QJAeMPUOX8gDRxh',
    id: 9066,
    acronym: 'NCMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'otc.edu',
    name: 'Ozarks Technical Community College',
    web_page: 'http://www.otc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgLvPoujQjHulBWO7sMUL0TBOXctFqSAxdQzUX-3Mhj-YVoUsB1uQC1uc',
    id: 9067,
    acronym: 'OTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southeastmissourihospitalcollege.edu',
    name: 'Southeast Missouri Hospital College of Nursing and Health Sciences',
    web_page: 'http://www.southeastmissourihospitalcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShd9dfboMv_EMm8P1meGIasSHiTzQn-QO1LcNo2BL8rVhVbhpBqOCo_38',
    id: 9068,
    acronym: 'SMHCNHS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sfccmo.edu',
    name: 'State Fair Community College',
    web_page: 'http://www.sfccmo.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQyZd4QX5ih1S65SM0K-Pp5gn5vjs6QC5Fw0GUDs5SMkYCubHwq_Yk_iXz',
    id: 9069,
    acronym: 'SFCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stchas.edu',
    name: 'St Charles Community College',
    web_page: 'http://www.stchas.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReyMtJDe3s5gDxH3GZ6EAZkezEss3668bEtFy1BR274R_EdWfO76PMf4Y',
    id: 9070,
    acronym: 'SCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wma.edu',
    name: 'Wentworth Military Academy & Junior College',
    web_page: 'http://www.wma.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSepaRq_-BJBvKrSTQ_oXERIfjQmuDLh2MjCGE4YkLqpzdjw3uRMvk2jmc',
    id: 9072,
    acronym: 'WMA&JC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ancollege.edu',
    name: 'Aaniiih Nakoda College',
    web_page: 'http://www.ancollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmUpXgcoOSak-q93p3Zej7yBqtNddYTDgxbvob7ue8RHOsEgFDXLux4xQ',
    id: 9073,
    acronym: 'ANC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bfcc.edu',
    name: 'Blackfeet Community College',
    web_page: 'http://bfcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEjURsNrroYfWAJfVB25jQfnk3CVLY55keDJyjrGmb-Bx3I8-DpyGBB18',
    id: 9074,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cdkc.edu',
    name: 'Chief Dull Knife College',
    web_page: 'http://cdkc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfff8zUYQzWSEH7KRWlSCma4hVrPeTcAtA3BvS7hZ-YLVllmby65MIGQ',
    id: 9075,
    acronym: 'CDKC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dawson.edu',
    name: 'Dawson Community College',
    web_page: 'http://www.dawson.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPuHcIehVVosn2IJveVuzYGWPNqCVWUgLBjHRgVAqaUjAuSJ3hPvvPsZfZ',
    id: 9076,
    acronym: 'DCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fvcc.edu',
    name: 'Flathead Valley Community College',
    web_page: 'http://www.fvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvYF9F2GyFC4itNyqv8XOAnBXZNLTKp30-vtjuoaHw10FDKDICHe5lFg',
    id: 9077,
    acronym: 'FVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fpcc.edu',
    name: 'Fort Peck Community College',
    web_page: 'http://www.fpcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnyXFDg2y54lXF-M2Akpe1RKZDcf5jCoOwgcEo_Nt3H77ETAPQbLgK3aHA',
    id: 9078,
    acronym: 'FPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gfcmsu.edu',
    name: 'Great Falls College Montana State University',
    web_page: 'http://www.gfcmsu.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwfb5XNQScILgJlpNcc-qS79asaGa1ZOLB2Wvt7V36YRA8HgrythuuS1Y',
    id: 9079,
    acronym: 'GFCMSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umhelena.edu',
    name: 'Helena College University of Montana',
    web_page: 'http://www.umhelena.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIWxfSPg0m585f7C_DSKMZ7qT6y6_cfhpN8Hi0Zf0U4nPchGdNRB7R4Q',
    id: 9080,
    acronym: 'HCUM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lbhc.edu',
    name: 'Little Big Horn College',
    web_page: 'http://www.lbhc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0jednSV0XafOW6dG9WcU428wV15j1n-eVmmrLcMx1VhLgA4lnJ4XWuhw',
    id: 9081,
    acronym: 'LBHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'milescc.edu',
    name: 'Miles Community College',
    web_page: 'http://milescc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtu8YD-lE9NlE8GnSVRf0HrGZ5XHBvvqyP5a_Chnlz8sjfYFr39YLirA',
    id: 9082,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stonechild.edu',
    name: 'Stone Child College',
    web_page: 'http://www.stonechild.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeLf_-CPf66E_dzZNB3M7UcZzRcPj_3SGnfmhq95-GvAZIONLWAGOnvEY',
    id: 9083,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cccneb.edu',
    name: 'Central Community College',
    web_page: 'http://www.cccneb.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRs2aOM-40dlmoHf8XZWjIfD9pI7zVvaGgl95gPlHQhVM3TbEIxcidWIyc',
    id: 9084,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'littlepriest.edu',
    name: 'Little Priest Tribal College',
    web_page: 'http://www.littlepriest.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTV8VE0yHFKdO-IC2Ao6n_kdfdwmRIb_4IFL77sJgkJZMTSJo94ZlLWTg',
    id: 9085,
    acronym: 'LPTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mpcc.edu',
    name: 'Mid-​Plains Community College',
    web_page: 'http://www.mpcc.edu',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcS62DTqxIKMpav09eTne9C3aoxJ60cQTRDncv5rk_LZAhQrMcTstGLHAVs',
    id: 9086,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncta.unl.edu',
    name: 'Nebraska College of Technical Agriculture',
    web_page: 'http://NCTA.UNL.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXfBYkcWGAiOliHYwU-tmyyFIkd6Op8v1XFrw5R42wU0SzkJ7vg7xfH4Tn',
    id: 9087,
    acronym: 'NCTA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'thenicc.edu',
    name: 'Nebraska Indian Community College',
    web_page: 'http://www.thenicc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8G004gNjjx7dbjNi8ZW0RhsY_PIqfzdzBZeK3-MDAdvxjRf3vi2FfMA',
    id: 9088,
    acronym: 'NICC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northeast.edu',
    name: 'Northeast Community College',
    web_page: 'http://www.northeast.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSOQa9nBEs3Nw4rCcItQGiuvbpW_YuaCSfVhPZMeaNlCqKA01dcWRmKQ',
    id: 9089,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southeast.edu',
    name: 'Southeast Community College Area',
    web_page: 'http://www.southeast.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQL-ZddW-me3R6R5U8YNwMp6Xa_ZU_sR6Rj9KZQOHylEVvd11FIjU6I05oC',
    id: 9090,
    acronym: 'SCCA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wncc.edu',
    name: 'Western Nebraska Community College',
    web_page: 'http://www.wncc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQPdJIWtWXR9r14MepipnUb1LUynzR7U92qW0bB1pnnumFLZ11g6-Tilo',
    id: 9091,
    acronym: 'WNCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tmcc.edu',
    name: 'Truckee Meadows Community College',
    web_page: 'http://www.tmcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfNhlFhnQyGOOulV04Ui5A9qB77_i1v1ZtP4hOmloRakm2X4rV67EqtFg',
    id: 9092,
    acronym: 'TMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'greatbay.edu',
    name: 'Great Bay Community College',
    web_page: 'http://www.greatbay.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZfQ4dZIR5jwOnQ-Zhly-QRj_uPNrxKXB1WUaqJsYxqd9uq8BX1VnHW_k',
    id: 9093,
    acronym: 'GBCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lrcc.edu',
    name: 'Lakes Region Community College',
    web_page: 'http://www.lrcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyGqgG9QE0sXyENPIb8-7jKJ0qV_l_ha5ul6H7X1kgdC0yzoRaePoTFA',
    id: 9094,
    acronym: 'LRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nashuacc.edu',
    name: 'Nashua Community College',
    web_page: 'http://www.nashuacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqrDYcq1JuqvjnN64s07ZClXnkWj__2dPtzdbjloLufF1NwwTo9yznbQ',
    id: 9096,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nhti.edu',
    name: "NHTI-​Concord's Community College",
    web_page: 'http://www.nhti.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpJtunu6wuTskEsNXA09NVITz8I_I-vCOmX7mUobg9lwkBNH2GMeOLV6s',
    id: 9097,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rivervalley.edu',
    name: 'River Valley Community College',
    web_page: 'http://www.rivervalley.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI4SCuZ-bocNeEE-fI0Xzvs6wjCzsKrwqb4fkXDb2TxICnckYYjq2lzA',
    id: 9098,
    acronym: 'RVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wmcc.edu',
    name: 'White Mountains Community College',
    web_page: 'http://www.wmcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-8UK270PMftrceYodac0xp9EQ6t_C49F91yrw1GONc7DN-BaBFY-fgPE',
    id: 9099,
    acronym: 'WMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'acs350.org',
    name: 'Assumption College for Sisters',
    web_page: 'http://www.acs350.org',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaqpHBRbKGbkOZbZMNkiVga26SjismszsOvWiqPMH3FLmSiXlZ0P0ArJ8',
    id: 9100,
    acronym: 'ACFS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'atlantic.edu',
    name: 'Atlantic Cape Community College',
    web_page: 'http://www.atlantic.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBkgB5EINrT2LTXZpYVsZ0PAPFbzzmIaAsHA3MLMw1onacTPl8NH9AIUw',
    id: 9101,
    acronym: 'ACCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bergen.edu',
    name: 'Bergen Community College',
    web_page: 'http://www.bergen.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLmurpkqczT0j5vhOSMaZHEtbbAks_XvC4D2AkGHi79pvZpt5D_RJsew',
    id: 9102,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bcc.edu',
    name: 'Burlington County College',
    web_page: 'http://www.bcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1CwUN4z1bKKF-n0NbUflTbLJN-VZL9s1wn1DTr33whmC3hdsxXRqayvxC',
    id: 9104,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'camdencc.edu',
    name: 'Camden County College',
    web_page: 'http://www.camdencc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw9OgFvUkWb90RAwg8V9FIg1Z3HPYkR3BZTsMWylQPJW0t1SIkIqdmiw',
    id: 9105,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.ccm.edu',
    name: 'County College of Morris',
    web_page: 'http://WWW.CCM.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7S1wgtpx4qXaUgSZSOkLHDxaM-VyJgRxHzn9xsNe96kXBPsAT0ueAhw',
    id: 9106,
    acronym: 'CCM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cccnj.edu',
    name: 'Cumberland County College',
    web_page: 'http://www.cccnj.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt8jR51NAyhcBxzTpBAnE0NadlqWdF55ylltU6RtSyo5GzgV9koKG-U-s',
    id: 9107,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'essex.edu',
    name: 'Essex County College',
    web_page: 'http://www.essex.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgASzSuEE1SACMLnAzqM2zRuwVgrcVGMWhDZ4ZmwSlGzj1SuyTLKgcsjE',
    id: 9108,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gccnj.edu',
    name: 'Gloucester County College',
    web_page: 'http://www.gccnj.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-3uBZGmSJ-sa99ym5-Cg3-6lzZnO9eC4BG9gKSQzkZ4Sm-8ZtOqndV8Q',
    id: 9109,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hccc.edu',
    name: 'Hudson County Community College',
    web_page: 'http://www.hccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXjlDaOuLIBBGoAmZPqp7gE3w13K4sI_xAmhJjOnWTwpTraYDtIbppQ98',
    id: 9110,
    acronym: 'HCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'middlesexcc.edu',
    name: 'Middlesex County College',
    web_page: 'http://www.middlesexcc.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSwpjCHmPXbJcR6N2nfE7peTtgo3MgJqGL0C-iTMypD78R8iAOtXDInrQ',
    id: 9111,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ocean.edu',
    name: 'Ocean County College',
    web_page: 'http://www.ocean.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzSwrpTO3d8CQ_K7O90XAZKVzULyZyYzCOIgcOsNuhmEbh33yYklCpQlA',
    id: 9112,
    acronym: 'OCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pccc.edu',
    name: 'Passaic County Community College',
    web_page: 'http://www.pccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdx3THsxOaMXJgmEd6NX5JXmhSmpXhWgA9gV6-r1KiaWsj5WWdJBTvnGM',
    id: 9113,
    acronym: 'PCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'raritanval.edu',
    name: 'Raritan Valley Community College',
    web_page: 'http://www.raritanval.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRj9nSh6wx6YYtZj_K08hu0Z-7ui3Fk0PPp7gxFm_AFLMZgTApDa56ko4s',
    id: 9114,
    acronym: 'RVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'salemcc.edu',
    name: 'Salem Community College',
    web_page: 'http://www.salemcc.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcR9OTDoDmw_wpgAvB1bQIqJsQVdwyz5ct0b6nY37U_WmUBko4nrKaSqDLs',
    id: 9115,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sussex.edu',
    name: 'Sussex County Community College',
    web_page: 'http://www.sussex.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQA6_iqfKH9wzmiHkshDV5sqj4q4xzpZgByPd03NkbBJjvSmkP5wpoGbFY',
    id: 9116,
    acronym: 'SCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ucc.edu',
    name: 'Union County College',
    web_page: 'http://www.ucc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUmjhsScYSJoel_FPWgYPM8kkMnCx7P_uVbNGXs_ISO9qaBpE14lxAsw',
    id: 9117,
    acronym: 'UCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'warren.edu',
    name: 'Warren County Community College',
    web_page: 'http://www.warren.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtaGfblfC-jTGj8ynaBVuBo_yQu--51VD3ry2rbOZ28FxaDBJgE-tqV_A',
    id: 9118,
    acronym: 'WCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cnm.edu',
    name: 'Central New Mexico Community College',
    web_page: 'http://www.cnm.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnp4JzQEibn2UehGs_QI7n6JtC2a0LNT0TQc1ZZ151k1HF6o1SdqrrMw',
    id: 9119,
    acronym: 'CNMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clovis.edu',
    name: 'Clovis Community College',
    web_page: 'http://www.clovis.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoJvaoPfe9MKGSt0TbciO1E7VA81FAbIlgIX1gODQ2DorNsU7sxI_nVDU',
    id: 9120,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'roswell.enmu.edu',
    name: 'Eastern New Mexico University-​Roswell Campus',
    web_page: 'http://www.roswell.enmu.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-z5UaAE-O3_qLcamvd-YdWR1AzsM3Epat-e9rPwSRRx7vlihZEg-6WKU',
    id: 9121,
    acronym: 'ENMUC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'luna.edu',
    name: 'Luna Community College',
    web_page: 'http://www.luna.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgcsmff0IRoxn58Uc6l5dZn6fVu-1RFVkNdvzXoGAtIFZ1Qyy91fse1BU',
    id: 9122,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mesalands.edu',
    name: 'Mesalands Community College',
    web_page: 'http://www.mesalands.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHJDECWaJR_rlNF2HzXKPUNLkucYkayBFPIGo7yh_9CAfQL7JDky0X-ws',
    id: 9123,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nmjc.edu',
    name: 'New Mexico Junior College',
    web_page: 'http://www.nmjc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLSGy2FX40C3XpV5wrqMv9ys49OXK5MuKye94VJezN-AWD0sVdL9WREgA',
    id: 9124,
    acronym: 'NMJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nmmi.edu',
    name: 'New Mexico Military Institute',
    web_page: 'http://www.nmmi.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSitTDVV0RQf1ewDbfJUdAMwuXm8oyPav8U8Ibr954C2-cs_sy1-YTfjg',
    id: 9125,
    acronym: 'NMMI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nmsua.edu',
    name: 'New Mexico State University-​Alamogordo',
    web_page: 'http://nmsua.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwdHyfXqwHWDsFXVV5DM41RWRL3r56ZdI3c8GqsFsjqA0q66tCKiaZTGxP',
    id: 9126,
    acronym: 'NMSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cavern.nmsu.edu',
    name: 'New Mexico State University-​Carlsbad',
    web_page: 'http://www.cavern.nmsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCzPoj4p143XvZXspOnPTziBSTmg9sKESZC4jREtIujDfDt70GqdUhUhxz',
    id: 9127,
    acronym: 'NMSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dabcc.nmsu.edu',
    name: 'New Mexico State University-​Dona Ana',
    web_page: 'http://dabcc.nmsu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVIzUrt6mETyfEWrMtovvluJUagb9MG9Ott359gpKfmxJSt4HrjDYW1aMALw',
    id: 9128,
    acronym: 'NMSUA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sanjuancollege.edu',
    name: 'San Juan College',
    web_page: 'http://www.sanjuancollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIf5IFxyRiWDSV95qgC_fb1My1QpBTb1CwuFK007X1osChl3gl_fd_hAA',
    id: 9129,
    acronym: 'SJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sfcc.edu',
    name: 'Santa Fe Community College',
    web_page: 'http://www.sfcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsjLwN2dj0qlDMDKhFVYFyDGPO1cBZSMT9OgWqHsxjbOSR8EGW7WyVNZQ',
    id: 9130,
    acronym: 'SFCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sipi.edu',
    name: 'Southwestern Indian Polytechnic Institute',
    web_page: 'http://www.sipi.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoK0GMMLPXD5Z7K8Y3MNV7OMuA-ptOyOKjooH9jJ4J2fN7X7vyvJbvvg',
    id: 9131,
    acronym: 'SIPI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.sunyacc.edu',
    name: 'Adirondack Community College',
    web_page: 'http://WWW.SUNYACC.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCG42J3XfyVvM4jEMQQ8sveazHX4eSY5uP8LWuy4etrjuMrE42FxCDj9E',
    id: 9132,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'aada.edu',
    name: 'American Academy of Dramatic Arts-​New York',
    web_page: 'http://www.aada.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ68_U0i2zT3A4tKEqjxyVfXEj8O8IuPf_Xp-Rs-3_CDyBv4dA1uz4hp78',
    id: 9133,
    acronym: 'AADAY',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'asa.edu',
    name: 'ASA College',
    web_page: 'http://www.asa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUnxoJBBwifpIUxLPDLHCiSxybyeO6uBx7rHXCjJjbJZXVOOVjoAvJsw',
    id: 9134,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cayuga-cc.edu',
    name: 'Cayuga County Community College',
    web_page: 'http://www.cayuga-cc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQynpkPxCUxeq7ZuMW59BDRhwXRXMIHk2aJiiowRLcAH63FTOdaub9VcyDQ',
    id: 9135,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clinton.edu',
    name: 'Clinton Community College',
    web_page: 'http://www.clinton.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4rQW077nd70Y3fwZpUmXllk5dGqHl1bdiwy_lM_-Nd_8JH6DwxNMH72Tr',
    id: 9136,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunycgcc.edu',
    name: 'Columbia-​Greene Community College',
    web_page: 'http://www.sunycgcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCuaat26waPxx4soLLCwRpx65L7t9MyT4lrwMs1kYoPX6suMGUsMYvJGw',
    id: 9137,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bmcc.cuny.edu',
    name: 'CUNY Borough of Manhattan Community College',
    web_page: 'http://www.bmcc.cuny.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxtMdG_pnul2SOvw4IcyeIyWOmEBCEHkp6umoqBh0U9H36hEFvZ2O_GA',
    id: 9138,
    acronym: 'CBMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bcc.cuny.edu',
    name: 'CUNY Bronx Community College',
    web_page: 'http://www.bcc.cuny.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiPmWvRSGc68fi3L7roNjMn2BqWMWri6bR83F3i475EyXextCPTGtlbg',
    id: 9139,
    acronym: 'CBCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hostos.cuny.edu',
    name: 'CUNY Hostos Community College',
    web_page: 'http://www.hostos.cuny.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbnnpdST8wfCMRxCq9U9jITYiv_T0MJ7bD3akqYhtT_ZX7sSQSLPbRKbhl',
    id: 9140,
    acronym: 'CHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kbcc.cuny.edu',
    name: 'CUNY Kingsborough Community College',
    web_page: 'http://www.kbcc.cuny.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsjEaFx4lKXxu6Ot0V3DHYnSpakIJbV1ltndpb8SI2gcUl-Ab3Nzkc9w',
    id: 9141,
    acronym: 'CKCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lagcc.cuny.edu',
    name: 'CUNY LaGuardia Community College',
    web_page: 'http://www.lagcc.cuny.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOrALomMmNaREXLCSXJH8NBbjPo-q4c4TcOu7APi1T58hh9D-5-NBs3g',
    id: 9142,
    acronym: 'CLCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'qcc.cuny.edu',
    name: 'CUNY Queensborough Community College',
    web_page: 'http://www.qcc.cuny.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB44zCrebP3QHZpyvrrzUFNobKeh_qu2hlYIa6tI8Kgh4n_2uebR1MBQ',
    id: 9143,
    acronym: 'CQCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunydutchess.edu',
    name: 'Dutchess Community College',
    web_page: 'http://www.sunydutchess.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKBO4U7hwm56q25RQ6T7a7MpqbXB3aP1QluF8Zf-KJhzHGYlmghSqzmA',
    id: 9144,
    acronym: 'DCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'flcc.edu',
    name: 'Finger Lakes Community College',
    web_page: 'http://www.flcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7SuDOiXGcl4tOBHYxjDA7bc8_Y_APpOqEyDcgaBQUrF9698Y5ytm7Ng',
    id: 9145,
    acronym: 'FLCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fmcc.edu',
    name: 'Fulton-​Montgomery Community College',
    web_page: 'http://fmcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQqbAOEUiqj1BFyuV0FIQRrVJQUQKxuR29XjfUqoYEbrlViTHNhPBdcEM',
    id: 9146,
    acronym: 'FCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'genesee.edu',
    name: 'Genesee Community College',
    web_page: 'http://www.genesee.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQw1237JL-UuTmHwv_KVETj33SPpMXsBwQ0rQEpJIbE1fyDLyxH_6s6bgtH',
    id: 9147,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'herkimer.edu',
    name: 'Herkimer County Community College',
    web_page: 'http://www.herkimer.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN7ecdZfFbcvo7w7fBxiKVyp0RmZCTDYMYZLz5-EzYAV-7QlTyV3qtn0E',
    id: 9148,
    acronym: 'HCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hvcc.edu',
    name: 'Hudson Valley Community College',
    web_page: 'http://www.hvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2lRp-KYw4gPQzwqjIzDub-w9HoCAfdRmGtyfjuNme4qrIZxmxh6CO1EBmRw',
    id: 9149,
    acronym: 'HVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunyjcc.edu',
    name: 'Jamestown Community College',
    web_page: 'http://www.sunyjcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5NWxeAdfMOsWwuYOtorM3mB3KvVQ4noetdOpfquln4xBsP0f7rPryeA',
    id: 9150,
    acronym: 'JCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunyjefferson.edu',
    name: 'Jefferson Community College',
    web_page: 'http://www.sunyjefferson.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDbM6E0pFMRVdWTWk23fPs7xPRI3z_QCPTCm7sKqHte05RH1umGCLebpU',
    id: 9151,
    acronym: 'JCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mvcc.edu',
    name: 'Mohawk Valley Community College',
    web_page: 'http://www.mvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0WG_l65LA-1C8i4QjwBQ13KmWEg6f3RgL-vbYHnLQxn57NIISBVthA2c',
    id: 9152,
    acronym: 'MVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'monroecc.edu',
    name: 'Monroe Community College',
    web_page: 'http://www.monroecc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSsK8sSoPjwiVukHdGho1rpFXAEABupz8Duq_riGZOJCwD67vCPG340g',
    id: 9153,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncc.edu',
    name: 'Nassau Community College',
    web_page: 'http://www.ncc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5pEwVphlbmc7nyOSVoGonr6O15lf3Wz08xeXe-7FdEoNMZS_iqD7k6A',
    id: 9154,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'niagaracc.suny.edu',
    name: 'Niagara County Community College',
    web_page: 'http://www.niagaracc.suny.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJVZR5JEFqpamnsvandiQQproCFuM8-u7evdebv_nhpmdeVsiPWKl_s8Q',
    id: 9155,
    acronym: 'NCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nccc.edu',
    name: 'North Country Community College',
    web_page: 'http://www.nccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7N5M0fziMdpMgDc01Y-4iLcXoy6JUhRSvGkQRL_qQ2JDd6kXkkrDKjFA',
    id: 9156,
    acronym: 'NCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunyocc.edu',
    name: 'Onondaga Community College',
    web_page: 'http://www.sunyocc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg8C_BI1fw1HAlJirrRrYnmk7oSj9BeyFriw9CXHos5gH1eIm2y_NoxBwwDg',
    id: 9157,
    acronym: 'OCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunyorange.edu',
    name: 'Orange County Community College',
    web_page: 'http://www.sunyorange.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuqF5cQH6Qtmm27WE2GAtGDu0ipqAL_NzMUp4Cw-eDDoA14Snj3xMuWJE4',
    id: 9158,
    acronym: 'OCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunyrockland.edu',
    name: 'Rockland Community College',
    web_page: 'http://www.sunyrockland.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHvtZA80Bra_4XtyazjCS5F3z-H-sUzpG_uaWDcEYXNK04530zjwS2xg',
    id: 9159,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'secon.edu',
    name: 'Saint Elizabeth College of Nursing',
    web_page: 'http://www.secon.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHyytg8stT_h_fPGxIjYNtYFVBnPjS_lrexl9kZOuEOI-ppYTKpm-xUtmNsg',
    id: 9160,
    acronym: 'SECN',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunysccc.edu',
    name: 'Schenectady County Community College',
    web_page: 'http://www.sunysccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4WMWn5ecZr84Iweq50rV3_sltkv9r411NKmzqKJv1RBUZCbImF7JKpw',
    id: 9161,
    acronym: 'SCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www3.sunysuffolk.edu',
    name: 'Suffolk County Community College',
    web_page: 'http://www3.sunysuffolk.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSF0Cx8eyOCedEUEatQYjmLH-vEMsxbi72Y55FtdvgRGnS7pxhjS8rbw',
    id: 9162,
    acronym: 'SCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunysullivan.edu',
    name: 'Sullivan County Community College',
    web_page: 'http://www.sunysullivan.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJ7pNDBQA3tjswdBiCX-u7kLFsfLIBOTlWfM1SkcU87e9K-cAKAK2E2HA',
    id: 9163,
    acronym: 'SCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.sunybroome.edu',
    name: 'SUNY Broome Community College',
    web_page: 'http://WWW.SUNYBROOME.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfTLoxAmSyaEX4xr_SAOZOhK5tbRsiTdYP9KTOIt8_5rri3qebQSVBJaQ',
    id: 9164,
    acronym: 'SBCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sunywcc.edu',
    name: 'SUNY Westchester Community College',
    web_page: 'http://www.sunywcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwwmyQfSLSTT6L51hWkc4ZV2XNXVkCokagysIz7ZkLGC2dwkyX2sP_Zw',
    id: 9165,
    acronym: 'SWCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tcicollege.edu',
    name: 'Technical Career Institutes',
    web_page: 'http://www.tcicollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7xRNNPa1BplQvL0wqOYg6ZwE-N9Rhe5jnzBPvohPHFYJe8t4rJCAtMVM',
    id: 9166,
    acronym: 'TCI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tc3.edu',
    name: 'Tompkins Cortland Community College',
    web_page: 'http://www.TC3.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3y4Di8QcscicThj0oYDS6dyRbETzm-zuQhQYXMHBH8m-BIclDPZ-_axa4',
    id: 9167,
    acronym: 'TCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.sunyulster.edu',
    name: 'Ulster County Community College',
    web_page: 'http://WWW.SUNYULSTER.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS93b9GkHOROPojQ5zO8hOEj6ROBOknXJVnhOIFhOh2gxfc3-BQI_jdkoE',
    id: 9168,
    acronym: 'UCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alamancecc.edu',
    name: 'Alamance Community College',
    web_page: 'http://www.alamancecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSny-S-vlXCgeeKdO8Ok2YOjrWW8IHb6FQ_2Fz9-_DxTQCbnPhEuxedir4',
    id: 9169,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'abtech.edu',
    name: 'Asheville-​Buncombe Technical Community College',
    web_page: 'http://www.abtech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHVilyEQZTrzJOvHMy978jjsxV3g_t8QCxxQAr8S8pcJZUjzbdSaMgXKA',
    id: 9170,
    acronym: 'ATCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'beaufortccc.edu',
    name: 'Beaufort County Community College',
    web_page: 'http://www.beaufortccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3rAG-Rt5TTT5yQ94ZOmpM-ezEoBKZL2jLE4ZPSVRXoUBlXenb22fm7_w',
    id: 9171,
    acronym: 'BCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bladencc.edu',
    name: 'Bladen Community College',
    web_page: 'http://www.bladencc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa9gN4hIs8cfaWxkYISEoH1M9c3IDU8Y_UOgrl_8rMI9X2eA803j3EyyKi',
    id: 9172,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'blueridge.edu',
    name: 'Blue Ridge Community College',
    web_page: 'http://www.blueridge.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxnxDAAH4Jd7SJ_UYMfJvgAlF-gSxATWcse_-r8RMqJ6SDxPHd4QJWaA',
    id: 9173,
    acronym: 'BRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'brunswickcc.edu',
    name: 'Brunswick Community College',
    web_page: 'http://www.brunswickcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuMiVQDvXy1IBFE2a_9sJLTDC6X5cYIYWQAoGK25cOb_rM0BVTMvBw3ITJ',
    id: 9174,
    acronym: 'BCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cccti.edu',
    name: 'Caldwell Community College and Technical Institute',
    web_page: 'http://www.cccti.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSr9mB4K30kHRGlVsmg4pAGfA9_YknoHOWknfuMimOifG6-bKd53kkxVMvu',
    id: 9175,
    acronym: 'CCCTI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cfcc.edu',
    name: 'Cape Fear Community College',
    web_page: 'http://www.cfcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxgfJVzwiscFbDvW_GcWXrMrc2aZhJsf0IUDsD6daJqyUN9xai5ACI53Lv',
    id: 9176,
    acronym: 'CFCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carolinascollege.edu',
    name: 'Carolinas College of Health Sciences',
    web_page: 'http://www.carolinascollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtHr513xm1tVm4SxHU61k_jCJExcdRgYABWC4EgltNCiUTF3D0JKx-JSQ',
    id: 9177,
    acronym: 'CCHS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carteret.edu',
    name: 'Carteret Community College',
    web_page: 'http://www.carteret.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXmCJKJ_nvpiEbbgijiPJTyr8mGIQy7pC9YjAxx95keUwo13E49wEkf7s',
    id: 9178,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cvcc.edu',
    name: 'Catawba Valley Community College',
    web_page: 'http://www.cvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYto2IbwrrhzZRqY-dUXXxRds9Mh-Xww5rdc37WD4-w8a6f4KHGRG5Xa3zoA',
    id: 9179,
    acronym: 'CVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cccc.edu',
    name: 'Central Carolina Community College',
    web_page: 'http://www.cccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQaXMrU3aC_9j4xedT68P37pbXmirG8nEpK7DEJiCM1hJOzhHASX4rEEQ',
    id: 9180,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cpcc.edu',
    name: 'Central Piedmont Community College',
    web_page: 'http://www.cpcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu54cSfkrHmxdIXDS3bbSE9YMwjA-uZbIMOYzBjvE1ibJtBnPG6cI5liSl',
    id: 9181,
    acronym: 'CPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clevelandcc.edu',
    name: 'Cleveland Community College',
    web_page: 'http://www.clevelandcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgGk3PS-smRo6QIJbwpAOMPdRYy6Hq_BE30phgLKJlIZybC8jpsUVIAhQ',
    id: 9182,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coastalcarolina.edu',
    name: 'Coastal Carolina Community College',
    web_page: 'http://www.coastalcarolina.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14192college.jpg',
    id: 9183,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'albemarle.edu',
    name: 'College of the Albemarle',
    web_page: 'http://www.albemarle.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS22AHYgXRfg80Z5Ang-TKYsqJ9YoMk1TsTKKLiAKnBPcoyKr_g908tpw',
    id: 9184,
    acronym: 'CA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cravencc.edu',
    name: 'Craven Community College',
    web_page: 'http://www.cravencc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-Fy2e_CSgjNEK7-KXNOSeMu3DyhsqQY_4Hm8xP1hHZsh5vDOC33rVnAU',
    id: 9185,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'davidsonccc.edu',
    name: 'Davidson County Community College',
    web_page: 'http://www.davidsonccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWXPa6deIXdD1SWNvaAOr0084E6sdvYxatL4NEDBb_OQk0s_WygDmsgA',
    id: 9186,
    acronym: 'DCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'durhamtech.edu',
    name: 'Durham Technical Community College',
    web_page: 'http://www.durhamtech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzmLxW-0-4JtLDalVtXPeJBPXEMx9B7A-DcLF8x7_BU2rHjhmlEe_MB4A',
    id: 9187,
    acronym: 'DTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'edgecombe.edu',
    name: 'Edgecombe Community College',
    web_page: 'http://www.edgecombe.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY2ZJLJqNYGUlm3ZCTobSUjCvTYoecc3NNGxfbLZq0luVoMFDu9xn8wDJF',
    id: 9188,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'faytechcc.edu',
    name: 'Fayetteville Technical Community College',
    web_page: 'http://www.faytechcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRuk3g9B3bCtWyvnEMXUV6DOv8ctpxJXmNs2Gqlwa6Si-HoLfL_OlsfQ',
    id: 9189,
    acronym: 'FTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'forsythtech.edu',
    name: 'Forsyth Technical Community College',
    web_page: 'http://www.forsythtech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQAzbAm-3uRJ3yS-G-msqDnRxu3ZIxOcMuTJg2bO6hsDSZqLAvpl_KHjL8',
    id: 9190,
    acronym: 'FTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gaston.edu',
    name: 'Gaston College',
    web_page: 'http://www.gaston.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSycHFNvnXtZKl3RUeIkGwgpwG--Yd5OfDPuBuyS1uYe9Hpsxayu7hGKA',
    id: 9191,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gtcc.edu',
    name: 'Guilford Technical Community College',
    web_page: 'http://www.gtcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTN2CB_x3ZcoPKNQgucBi7-5eVI95fi347MrIk2nqyW37tpXbiCJ8a1KLU',
    id: 9192,
    acronym: 'GTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'halifaxcc.edu',
    name: 'Halifax Community College',
    web_page: 'http://www.halifaxcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiNbPA_3sdsyyX6y_VSjg8kYD3sDfz-9KBWl_QJkILNsX8dbBjbdbzxtkW',
    id: 9193,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'haywood.edu',
    name: 'Haywood Community College',
    web_page: 'http://www.haywood.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyE9cH1IWGiTAewoGt-Vi7ULbX6e7eyIGIgppwScQ2V_y9j1aHdNBiWYQj',
    id: 9194,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'isothermal.edu',
    name: 'Isothermal Community College',
    web_page: 'http://www.isothermal.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKHmOur8C5Un__WiaMOWuJRP_KT5bbon2Qh5iIeynz79vlLquWiOqFrw',
    id: 9195,
    acronym: 'ICC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jamessprunt.edu',
    name: 'James Sprunt Community College',
    web_page: 'http://www.jamessprunt.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSC2PrEvB_Q5YFWiXTWYTDb0EJwqxYCKJJzG2ttwK_cyyRyiDJGDaTl1w',
    id: 9196,
    acronym: 'JSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'johnstoncc.edu',
    name: 'Johnston Community College',
    web_page: 'http://www.johnstoncc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoAQ8uEnEEO3DNCtort5stfgFUHqs8ArfTObJJeJuUd97w7UeO0yZdaA',
    id: 9197,
    acronym: 'JCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'louisburg.edu',
    name: 'Louisburg College',
    web_page: 'http://www.louisburg.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPGSlWNkd9tJgDFu3uWY-4B46WGYP1VESxVEM5ARDylIShK6VSOG3cjUE',
    id: 9198,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'martincc.edu',
    name: 'Martin Community College',
    web_page: 'http://www.martincc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_dhGvSPNMMGMlan8Z9nio7sUdv-orb57juJ39g9Ybadq2uYiMpfs0_g',
    id: 9199,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mayland.edu',
    name: 'Mayland Community College',
    web_page: 'http://www.mayland.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRywCzUCvpV8FwlyRdy8R17Rth8iy-QGwFcFAR_V3b61VEKTd4jmizrw9A',
    id: 9200,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mcdowelltech.edu',
    name: 'McDowell Technical Community College',
    web_page: 'http://www.mcdowelltech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTgUPrX-dkXRtETsnRUgNGMSalYu_LCAT4S-BWERBXUbSJtLvqscU3vQ',
    id: 9201,
    acronym: 'MTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mitchellcc.edu',
    name: 'Mitchell Community College',
    web_page: 'http://www.mitchellcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRx3lOMZ8OdsPNmSq7lwqk6YqkFHqtydLGaCATX4nUl21y16lYYkC9ypDc',
    id: 9202,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'montgomery.edu',
    name: 'Montgomery Community College',
    web_page: 'http://www.montgomery.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUMlhRXH4voyuAgJWKBJ0gId59QhNW0T4j7LUiay8GFG6viMrlx5hXD7w',
    id: 9203,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nashcc.edu',
    name: 'Nash Community College',
    web_page: 'http://www.nashcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3TTmSZaBrIcu2th-H8uZBSXPENdeU_P7Y9p5C4jE8jjWrDlVlCXQCP-WA',
    id: 9204,
    acronym: 'NCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pamlicocc.edu',
    name: 'Pamlico Community College',
    web_page: 'http://www.pamlicocc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpy0EpxoOz7gzXDu7M-oxGbhKpdlArZPnymfhNDGzugMgUU9PolDz7Nw',
    id: 9205,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'piedmontcc.edu',
    name: 'Piedmont Community College',
    web_page: 'http://www.piedmontcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKHmOur8C5Un__WiaMOWuJRP_KT5bbon2Qh5iIeynz79vlLquWiOqFrw',
    id: 9206,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pittcc.edu',
    name: 'Pitt Community College',
    web_page: 'http://www.pittcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8uXgNMsrAr7SdU6IcUKmV7houwzqxmNKF3tiLhbVKtC9EOh-CdjcjwA',
    id: 9207,
    acronym: 'PCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'randolph.edu',
    name: 'Randolph Community College',
    web_page: 'http://www.randolph.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYOWuicXRiQZZ7sH9XDG_jjpyrZgwbc5dc3gY3JCc8L4-F0jvqrXBh7w',
    id: 9208,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'richmondcc.edu',
    name: 'Richmond Community College',
    web_page: 'http://www.richmondcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLjz0oW8ADadcaiSK8kmaTtokD2Hy9IgNxKGMQLQgiKq3S8CSukTCmnGo',
    id: 9209,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'roanokechowan.edu',
    name: 'Roanoke-​Chowan Community College',
    web_page: 'http://www.roanokechowan.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQziNohfR1vD8euw_owBABw9l6EfQKNxKazvpJOpsJmcd0PYky_FWeHAA',
    id: 9210,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'robeson.edu',
    name: 'Robeson Community College',
    web_page: 'http://www.robeson.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-gxCVZ9dD-dZnmVn_JdQF9xXy_pAdUKu62Fj_VpLgBnst1wEk3R3aKDc',
    id: 9211,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rockinghamcc.edu',
    name: 'Rockingham Community College',
    web_page: 'http://www.rockinghamcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGGm4vRFS2EcWoSStIXJqj55Jr8C_M7elr-YmcR_xpwajSSSin1BRGRw',
    id: 9212,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rccc.edu',
    name: 'Rowan-​Cabarrus Community College',
    web_page: 'http://www.rccc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnYgQFpTzvVKeCI5Q86BjMpjMTTu1j7oOD5DfUf66dE4m1COGtVi7mKyw',
    id: 9213,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sampsoncc.edu',
    name: 'Sampson Community College',
    web_page: 'http://www.sampsoncc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFvwXoNpk49uCQlAZwEf0v98mzF3n37hg6WIjUQWr4YBSDM3kZcsawCA',
    id: 9214,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sandhills.edu',
    name: 'Sandhills Community College',
    web_page: 'http://www.sandhills.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvfnngZRJBCZBEvn5LWDwt48E2guiYXL5mDB8KxEL2t-hWOx5OoNglbkM',
    id: 9215,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'spcc.edu',
    name: 'South Piedmont Community College',
    web_page: 'http://www.spcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTapybvnRiW8ggiA3hdc3SK6tc3YxnP2XheddkoCSSh4UncxNFxkh8zWQ',
    id: 9217,
    acronym: 'SPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stanly.edu',
    name: 'Stanly Community College',
    web_page: 'http://www.stanly.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT5UbtN65sPMgMI8B2RydPd0RfOthmpkv9dGHDeGR4zsnOq2p-lLqwGzes',
    id: 9219,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'surry.edu',
    name: 'Surry Community College',
    web_page: 'http://www.surry.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4BAkyZnvR_dZNLS_WpqUSfa0Y76ICaSM8hVkFbqMNDb7_Hey3YztF1cQ',
    id: 9220,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tricountycc.edu',
    name: 'Tri-​County Community College',
    web_page: 'http://www.tricountycc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRlpZQ9Dc0ygizyIhOf5aFrLNx1p8ioGJe5jBT8OmKc6FXCYkhL64IDA',
    id: 9221,
    acronym: 'TCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'waketech.edu',
    name: 'Wake Technical Community College',
    web_page: 'http://www.waketech.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFQb7rp0JI2wuFlfKLfjlto6OcBbSTVWmDfYmxx4M84wzp7hmmRmzHqPU',
    id: 9222,
    acronym: 'WTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'waynecc.edu',
    name: 'Wayne Community College',
    web_page: 'http://www.waynecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8EJFmRZSr4Al49RvUF660HckLIy5kJtT1WCMUOW3ZFrqP_OXbnQ6aw90',
    id: 9223,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wpcc.edu',
    name: 'Western Piedmont Community College',
    web_page: 'http://www.wpcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKHmOur8C5Un__WiaMOWuJRP_KT5bbon2Qh5iIeynz79vlLquWiOqFrw',
    id: 9224,
    acronym: 'WPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wilkescc.edu',
    name: 'Wilkes Community College',
    web_page: 'http://www.wilkescc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVURxYl6WIFOtI_wWy-sH8ae4kNwiChDrgM7x6sh6mS__yOx3eee008w',
    id: 9225,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wilsoncc.edu',
    name: 'Wilson Community College',
    web_page: 'http://www.wilsoncc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDg6GlBVjA7s1bAcepD9iB8Eq_uf91TIlx7iP4TaSIHaf5AA1EmiVSbg',
    id: 9226,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'littlehoop.edu',
    name: 'Cankdeska Cikana Community College',
    web_page: 'http://www.littlehoop.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSOz7LmtgBzfARa0S8PRlz3iZuoD1nzXh7qTEODLuRUdyyX9_b3EgeSw',
    id: 9227,
    acronym: 'CCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dakotacollege.edu',
    name: 'Dakota College at Bottineau',
    web_page: 'http://www.dakotacollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIF1dxYlAVHT0FLd6J3QFHy5EKp5wsquLAq05aa25NFN-K8TfJKOkSRw',
    id: 9228,
    acronym: 'DCAB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lrsc.edu',
    name: 'Lake Region State College',
    web_page: 'http://www.lrsc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB5U5oKce4-PGmyHO8fqvfpV2mtrz5Uu7T6ukRE7mH_fZ05ytH-nweWzk',
    id: 9229,
    acronym: 'LRSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ndscs.edu',
    name: 'North Dakota State College of Science',
    web_page: 'http://www.ndscs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTNcl4kUPFT0m5Wd6ddFRE-pJ1oAZaw2o3YsXOqTLqhlOc87INTvlxRKTHL',
    id: 9230,
    acronym: 'NDSCS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'willistonstate.edu',
    name: 'Williston State College',
    web_page: 'http://www.willistonstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTtT5f9kPTuVkcN2obBC9Iib-oLpqqFTvKYODyONZnEeItzOl72WVB9_E',
    id: 9231,
    acronym: 'WSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'belmontcollege.edu',
    name: 'Belmont College',
    web_page: 'http://www.belmontcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEIamihr5oDkHAm0__LvM_917WEDROea-4Hej33g-K_CbrEMMCPQntwdQ',
    id: 9232,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cotc.edu',
    name: 'Central Ohio Technical College',
    web_page: 'http://www.cotc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtEkYwYPWRO35SGBQh4lCOYVBasvDQLXbCj3jXh5lxxTxgzt5ztMngLw',
    id: 9233,
    acronym: 'COTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chatfield.edu',
    name: 'Chatfield College',
    web_page: 'http://www.chatfield.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR59GFuO-wNBwtIl2OLJXGFXm2KOa4cYc6DTcoXG77ClUrtVXRAo0rikw',
    id: 9234,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cincinnatistate.edu',
    name: 'Cincinnati State Technical and Community College',
    web_page: 'http://www.cincinnatistate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBiYX_BA9jtJAz3gGRD5Tc6SuAKcjhKq29t0YKbZ9OEFRKVCbuMDEPqw',
    id: 9235,
    acronym: 'CSTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clarkstate.edu',
    name: 'Clark State Community College',
    web_page: 'http://www.clarkstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSOeoqISWpeYUFX9UKQC18iqPRnLzKwNbWPBez-w0YVmbC65vHeJuHh7KQ',
    id: 9236,
    acronym: 'CSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cscc.edu',
    name: 'Columbus State Community College',
    web_page: 'http://www.cscc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMLuQ4CufWqEv3JCEh4zgvTgkQnk4P2Isz-HXx7Nnpg0wOtuqqMw1W1Fg',
    id: 9237,
    acronym: 'CSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tri-c.edu',
    name: 'Cuyahoga Community College District',
    web_page: 'http://www.tri-c.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRuuSJLaRZgG5AfkHaAnq-52qPL4UiPkgu3vgY2dpY0ivZfsvTeQweIg',
    id: 9238,
    acronym: 'CCCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'daviscollege.edu',
    name: 'Davis College',
    web_page: 'http://www.daviscollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-Ssp1IZ7Vud_jyy3AGkYuQYmpe3a7ygBqlWpxdO9lBgzhf0tgn74PZT8',
    id: 9239,
    acronym: 'DC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'egcc.edu',
    name: 'Eastern Gateway Community College',
    web_page: 'http://www.egcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQKb2cfPWggeknEuTbZSssbc5DvZkLBrBEczsxG0uvfhX8miziCERuYrf_',
    id: 9240,
    acronym: 'EGCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'edisonohio.edu',
    name: 'Edison State Community College',
    web_page: 'http://www.edisonOHIO.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt6bCkJMJmrjBjvBj8tST6d_j2Bw8cV9F0pQUmquRuiAQxCcp5zqbnQG4',
    id: 9241,
    acronym: 'ESCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hocking.edu',
    name: 'Hocking College',
    web_page: 'http://www.hocking.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7GxEBn_FqawEij5sdH5nR6znPVIifaFO2TrtvdLQpejwXq8sKtC8Vq2Q',
    id: 9242,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rhodesstate.edu',
    name: 'James A Rhodes State College',
    web_page: 'http://www.rhodesstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQI-RoxQyIpMtqgRBi6FI9c7i5Yh1qZev1-SAFKAsl2FWzHzdQXMHQSSA',
    id: 9243,
    acronym: 'JARSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lakelandcc.edu',
    name: 'Lakeland Community College',
    web_page: 'http://www.lakelandcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuKAkj19KMKjBKK7hi05IX-5RESq3gDHJq4SbNCTmNKdWF1MfeKpbAg4PT',
    id: 9244,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lorainccc.edu',
    name: 'Lorain County Community College',
    web_page: 'http://www.lorainccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpv_ZDiWW3JL-WzLS08FZSJ8P7jA7tz4pRVNGHke8Q2iRAQzdXAr8c9g',
    id: 9245,
    acronym: 'LCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mtc.edu',
    name: 'Marion Technical College',
    web_page: 'http://www.mtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRegiuSVAIZvd8Dlmn6RUxg5RL1KtWfgyn0fM-Ww5jfM0yHtvQH1oSxik8',
    id: 9246,
    acronym: 'MTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ncstatecollege.edu',
    name: 'North Central State College',
    web_page: 'http://www.ncstatecollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcHO-JXEukalIe8v4uBAD5sTb2Gup6nfiZvH5F2FqvItNj_Bj-x3APrQ',
    id: 9247,
    acronym: 'NCSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northweststate.edu',
    name: 'Northwest State Community College',
    web_page: 'http://www.northweststate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJciFMTiOVX84yIIDd2CMKQnqvsuDU5eFQZwWlIW-5ZccPo_a_1ubhWus',
    id: 9248,
    acronym: 'NSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ati.osu.edu',
    name: 'Ohio State University Agricultural Technical Institute',
    web_page: 'http://www.ati.osu.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScz-ziX-XHMZlAkJS97KvFRs8IMWrD7WoD2gwaCRPiKc7yVZm5Yg8DVKVG',
    id: 9249,
    acronym: 'OSUATI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'owens.edu',
    name: 'Owens Community College',
    web_page: 'https://www.owens.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRONMpgANKgnFZxAMQckII98fgVe_nxzRnf6oR_klLJREvyJeZ1Q5vhmWs',
    id: 9250,
    acronym: 'OCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sscc.edu',
    name: 'Southern State Community College',
    web_page: 'http://www.sscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCgbCe1crYWr2Dja6jr_e9VLs3zvjgZw2vITmOwaJHSJdGa8Vm7_CZig',
    id: 9251,
    acronym: 'SSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'starkstate.edu',
    name: 'Stark State College',
    web_page: 'http://www.starkstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6ISm6OU8oHp2THz3hDyox-hkxWRjrR7iUri2qVl9gk-hJexJSYZ-AQw',
    id: 9252,
    acronym: 'SSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'terra.edu',
    name: 'Terra State Community College',
    web_page: 'http://www.terra.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVLLWLpSi3cosX-aX0rGSM_EmmaP56HWACo8cTP6zYROahbOyO8kZ1UfE',
    id: 9253,
    acronym: 'TSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wayne.uakron.edu',
    name: 'University of Akron Wayne College',
    web_page: 'http://www.wayne.uakron.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTubo83F6Fw4UQgR2pCvRghunlqxQYWqRb2ElV9BeB3ePwC3Xccy9gLdNk',
    id: 9254,
    acronym: 'UAWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wscc.edu',
    name: 'Washington State Community College',
    web_page: 'http://www.wscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSImL6evyy9O24kdiqvx08Bcg9mrM6cswMcbHvQjYv0-G2CD4e9fXVsyQ',
    id: 9255,
    acronym: 'WSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'zanestate.edu',
    name: 'Zane State College',
    web_page: 'http://www.zanestate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2PTQZUy-8VFfnAD9ykjxVQbMX58-wp8e6VIbIzWQ-syBj2l357IPW1Xn0',
    id: 9256,
    acronym: 'ZSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'carlalbert.edu',
    name: 'Carl Albert State College',
    web_page: 'http://www.carlalbert.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6qX2NHlABeoOj28yjNnitHLKUOd5QDa_q0HnJGyBDxu6-5BfYfd9xFvcf',
    id: 9257,
    acronym: 'CASC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'connorsstate.edu',
    name: 'Connors State College',
    web_page: 'http://www.connorsstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmrO5xmnbinbSmxBr7FEmgQjJSjyYyobkqLfYqW996Dl9RnYn10LBSAVs',
    id: 9258,
    acronym: 'CSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eosc.edu',
    name: 'Eastern Oklahoma State College',
    web_page: 'http://www.eosc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAUugpZ21_QZwXhqbVPmhxmxPq7RmP9rt-EH2KFODzuqZp5OgiMYKpOA',
    id: 9259,
    acronym: 'EOSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mscok.edu',
    name: 'Murray State College',
    web_page: 'http://www.mscok.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSWC-Ayo4mjxzgrDjc62Tp9z9CBDTIYXvcAN3zCv24xWEl64J0eEtrdg',
    id: 9260,
    acronym: 'MSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'neo.edu',
    name: 'Northeastern Oklahoma A&M College',
    web_page: 'http://www.neo.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSGvPB0BfygM4oTUZaOFt0Yb_Xt7UIXfgIZp1KEMO-C-fbywDtXyA4i7Oe-wA',
    id: 9261,
    acronym: 'NOAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'noc.edu',
    name: 'Northern Oklahoma College',
    web_page: 'http://www.noc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsmVMhZSQcBvNIvX4aVEJBAGG42MVuQ40p2kGgN6DgO2o4MgeLNFtKCXu4',
    id: 9262,
    acronym: 'NOC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'occc.edu',
    name: 'Oklahoma City Community College',
    web_page: 'http://www.occc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSe8EkigMzTePkl8SGz9c39GAy3HMHdGfhwASf8w5K-sTPbOyXXBYHRg0Q',
    id: 9263,
    acronym: 'OCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'redlandscc.edu',
    name: 'Redlands Community College',
    web_page: 'http://www.redlandscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmc301s-riUY5NuTfNnNkk9i7fGOhQ_xRQIxAqZG2BaR6i_BPkd3HqCOuyPg',
    id: 9264,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rose.edu',
    name: 'Rose State College',
    web_page: 'http://www.rose.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsUVCBASfRNafaQQQmsPFmwbPn7NkYH7kC_ktWMyqiHYcY-f739q01DQ0',
    id: 9265,
    acronym: 'RSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sscok.edu',
    name: 'Seminole State College',
    web_page: 'http://www.sscok.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTukL-47y9466gxqGOJ4zp4QiP3iRmGx5_WEkCYF40JFSdJABaHea1K5g',
    id: 9266,
    acronym: 'SSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tulsacc.edu',
    name: 'Tulsa Community College',
    web_page: 'http://www.tulsacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTB7F-FRatWhf1uOMWfcC5lnr6pmy040B9q3sos2cezaSLA-CgfY2iBrF8',
    id: 9267,
    acronym: 'TCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wosc.edu',
    name: 'Western Oklahoma State College',
    web_page: 'http://www.wosc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlk2ByvECXTmLRFAruclS8AMZLPzDrPlsDeL1AQ6uPxwBfLzlhRnzl_JE',
    id: 9268,
    acronym: 'WOSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bluecc.edu',
    name: 'Blue Mountain Community College',
    web_page: 'http://www.bluecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi-sUgW6st3MyhP4Sf6OZiZKR6eDXV9E1Dp8LOFosNrFxYHLIbQNJ7Yhg',
    id: 9269,
    acronym: 'BMCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cocc.edu',
    name: 'Central Oregon Community College',
    web_page: 'http://www.cocc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqWJX-_jKeyDTUMqiBND3cPOYCbO9BEwYw_CRNvCGq-oolCqoAnyu9GQ',
    id: 9270,
    acronym: 'COCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chemeketa.edu',
    name: 'Chemeketa Community College',
    web_page: 'http://www.chemeketa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFBA3syYrxmrrrBbnaxGih9ff_HGgaw0UJX1IbkvGnf0olPRfpiJobgw',
    id: 9271,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clackamas.edu',
    name: 'Clackamas Community College',
    web_page: 'http://www.clackamas.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQZLcSjOcSdN7gjvox695AvrDceiMJbd-rlUjgOPk1_AnE5KNxURqjRkKS',
    id: 9272,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clatsopcc.edu',
    name: 'Clatsop Community College',
    web_page: 'http://www.clatsopcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7xJmuEim4qe4GMxy8Cp1Hl7K6UzqomgN3tXIAZPJW5aLZJeEmpcpBDmg',
    id: 9273,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cgcc.cc.or.us',
    name: 'Columbia Gorge Community College',
    web_page: 'http://www.cgcc.cc.or.us',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPrd1qe_Cdq5jlrm4J50KuKx3GALb0p_zPAz724cd9CjkX45eYmEIfNg',
    id: 9274,
    acronym: 'CGCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'klamathcc.edu',
    name: 'Klamath Community College',
    web_page: 'https://www.klamathcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSENdscZ8jTi7SSLMPcxINuB_FEotsEVBtDpeEP2D1phU7ceF-G9hdR5Q',
    id: 9275,
    acronym: 'KCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lanecc.edu',
    name: 'Lane Community College',
    web_page: 'http://www.Lanecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgpldUWEgNI2lRRu6mMNTNegtUSe6NJjV1ESpYF13bVEpD98bBfckp1SI',
    id: 9276,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'linnbenton.edu',
    name: 'Linn-​Benton Community College',
    web_page: 'http://www.linnbenton.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLEYVN1uRymwMKqEkz63J0EvZVW_nKaL8zyLtiwhpDRCiKsj3p0s0fIkQ',
    id: 9277,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mhcc.edu',
    name: 'Mt. Hood Community College',
    web_page: 'http://www.mhcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbHGkeLvzduGVljPnrDhj7cvio6ChVFbau7L57i04fnjQA9Kuf_0_yxA',
    id: 9278,
    acronym: 'MHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'roguecc.edu',
    name: 'Rogue Community College',
    web_page: 'http://www.roguecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW3Nui6kgDozQXDI591YNfnAp_adgfHaNKzNcm6d6QqUuTQLoQ9JrOagk',
    id: 9279,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'socc.edu',
    name: 'Southwestern Oregon Community College',
    web_page: 'http://www.socc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyNl_UpI1FOdVsUGmwrCSB0xVBY3MEf-njRXwTpIRaHYQU--zDlD8F_A0',
    id: 9280,
    acronym: 'SOCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tbcc.cc.or.us',
    name: 'Tillamook Bay Community College',
    web_page: 'http://www.tbcc.cc.or.us/',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQKL4fTi7PhbF0rQhqQHMTdQ6E-9PBH_ZpGPiQYyjBPQX2pkqmZsHTELGI',
    id: 9281,
    acronym: 'TBCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tvcc.cc',
    name: 'Treasure Valley Community College',
    web_page: 'http://www.tvcc.cc',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSH-Hp5ErxUeSb7o8aE3a9Mz98j_bFeTDw8JjerwMnMfzoSkULz1TFa8J8',
    id: 9282,
    acronym: 'TVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umpqua.edu',
    name: 'Umpqua Community College',
    web_page: 'http://www.umpqua.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWk0wcgVgsps5s803uBAkz9K4DltszCiB-qGwFmwHaRKmUDsQ4FyIlHY8',
    id: 9283,
    acronym: 'UCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bucks.edu',
    name: 'Bucks County Community College',
    web_page: 'http://www.bucks.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBn8lrz_dwUT6iS6h_wIa-oZivhO1n76446YSTwHs_drRgFxcQfYYjuA',
    id: 9284,
    acronym: 'BCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bc3.edu',
    name: 'Butler County Community College',
    web_page: 'http://www.bc3.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRh1_eanESA7or87JUOPWJ4GvJWQCkIKdFHDJuKh__KhUBdy4v_pUUxiEhZ',
    id: 9285,
    acronym: 'BCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccac.edu',
    name: 'Community College of Allegheny County',
    web_page: 'http://www.ccac.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8_vfC6Ig2dck_KPK_a3y5Kx587Re0am4lPbqGH78Q4BC5QAPb-1CROA',
    id: 9286,
    acronym: 'CCAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccbc.edu',
    name: 'Community College of Beaver County',
    web_page: 'http://www.ccbc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKqR1aurSrK1zP0KthyA6bYlVXuTkRE54GCT7qnQR1QVhPzLBRDwsMC64',
    id: 9287,
    acronym: 'CCBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccp.edu',
    name: 'Community College of Philadelphia',
    web_page: 'http://www.ccp.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqQJlji-HPJVv0E9CZ_OF7-WdBWAlXs1Cgl4FFeuXY6sKvlGEhy6kpNA',
    id: 9288,
    acronym: 'CCP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dccc.edu',
    name: 'Delaware County Community College',
    web_page: 'http://www.dccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgySIJbufu8d2NTAfDEV9FSLTksJcehskv8Cujk2JX7P35Gw6th_U-1DUQ',
    id: 9289,
    acronym: 'DCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'harcum.edu',
    name: 'Harcum College',
    web_page: 'http://www.harcum.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7AT-uuebL9aQMdChFPrpH8D7TvG_9q3CnKPVSN3D1GR7YheAEkOnN1rR7',
    id: 9290,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hacc.edu',
    name: 'Harrisburg Area Community College-​Harrisburg',
    web_page: 'http://www.hacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSP6MTFBefrVrZ3kQ_-8HJI-6EiH2YqH1fDnBzN_qubzV7_rrPgGab4gw',
    id: 9291,
    acronym: 'HACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lackawanna.edu',
    name: 'Lackawanna College',
    web_page: 'http://www.lackawanna.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmLqNIUoMnkaM7HhIJf45uJ8O3YOVLlB8oOdUQRfROyD6REtcpnDwY6Us',
    id: 9292,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lccc.edu',
    name: 'Lehigh Carbon Community College',
    web_page: 'http://www.lccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQAlfS-urKkz7Gol3Y9xJXZB97eZ4S-yNV7kEOXBfRXoOypycPd7NnHbpb',
    id: 9293,
    acronym: 'LCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'luzerne.edu',
    name: 'Luzerne County Community College',
    web_page: 'http://www.luzerne.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxa3Iu9kX_0tuPrJnLRQ7B4eum524GjeJ0woNjCPSNvoZeAGuKYR2xkw',
    id: 9294,
    acronym: 'LCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'manor.edu',
    name: 'Manor College',
    web_page: 'http://www.manor.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxAqlLiDrI6k6aCUSZ21HGee70pdhgB3nYTPRF65sj2umAW2xpF1saJXM',
    id: 9295,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mc3.edu',
    name: 'Montgomery County Community College',
    web_page: 'http://www.mc3.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSUMlhRXH4voyuAgJWKBJ0gId59QhNW0T4j7LUiay8GFG6viMrlx5hXD7w',
    id: 9296,
    acronym: 'MCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pennhighlands.edu',
    name: 'Pennsylvania Highlands Community College',
    web_page: 'http://www.pennhighlands.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ1do10LezIBeGSAYRhp4bZ5ktQQErWpZXqxr1PF2N4gzR_TL5rTGGgA',
    id: 9297,
    acronym: 'PHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pit.edu',
    name: 'Pennsylvania Institute of Technology',
    web_page: 'http://www.pit.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMUOo12ZBimlPmoxh8DXaxBCwdAIhBbooa10i4ZAOuxQ_VnPuFZCdyNeA',
    id: 9298,
    acronym: 'PIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pti.edu',
    name: 'Pittsburgh Technical Institute',
    web_page: 'http://www.pti.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2IKrevOFQT1UNBO1fZ70xIiPZrZ5b_HgO78ngS8tD6BiYL4lD6Y5BKXM',
    id: 9299,
    acronym: 'PTI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'racc.edu',
    name: 'Reading Area Community College',
    web_page: 'http://www.racc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRa65SdRdo1gD2o1_EGYm4SHJdZoObg33FROD6F1rDBhFA3Gw_2uP1fhQ',
    id: 9300,
    acronym: 'RACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stevenscollege.edu',
    name: 'Thaddeus Stevens College of Technology',
    web_page: 'http://www.stevenscollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxq9TWWI07UP3atLnQk4_GO0dBUwR355UGvkFp1_L9leM6QQ-ndqqxZDE',
    id: 9301,
    acronym: 'TSCT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'upt.pitt.edu',
    name: 'University of Pittsburgh-​Titusville',
    web_page: 'http://www.upt.pitt.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsKUpKqpKZ0QBnyrCSH3CrrKI2PoO5CmIKbc0fswWMPHjXtkMgEeGlRAM',
    id: 9302,
    acronym: 'UP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vfmac.edu',
    name: 'Valley Forge Military College',
    web_page: 'http://www.vfmac.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLXLc5e6cPyecm1x_lL4V_MAUIK1wmBonGhmtk29NjQT6jjApSm-IRjTUG',
    id: 9303,
    acronym: 'VFMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wccc.edu',
    name: 'Westmoreland County Community College',
    web_page: 'http://wccc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxSI0sPG87DNWEfE6OzzqKnXwJhNqmkR3V5U618onJnE-eKdc6FoBuDZuY',
    id: 9304,
    acronym: 'WCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccri.edu',
    name: 'Community College of Rhode Island',
    web_page: 'http://www.ccri.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSENdscZ8jTi7SSLMPcxINuB_FEotsEVBtDpeEP2D1phU7ceF-G9hdR5Q',
    id: 9305,
    acronym: 'CCRI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cctech.edu',
    name: 'Central Carolina Technical College',
    web_page: 'http://www.cctech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0G859LJp_ChK4NmuvkrK_-zcdDwXJLtzBzla0WlRt-A5LB_m2OSju-4A',
    id: 9306,
    acronym: 'CCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'denmarktech.edu',
    name: 'Denmark Technical College',
    web_page: 'http://www.denmarktech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnyO5n3x8d-lQ5ssBNDSfq1zoV-yxY1hJz7uIht3HF3OV7UTDZG6mnj-0',
    id: 9307,
    acronym: 'DTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.fdtc.edu',
    name: 'Florence-​Darlington Technical College',
    web_page: 'http://WWW.FDTC.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyrjzP565IwwXs-xqNHc7Nn54pxSHGRt5BsJKOJdIKKFu0nsB7yhMKyYE',
    id: 9308,
    acronym: 'FTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gvltec.edu',
    name: 'Greenville Technical College',
    web_page: 'http://www.gvltec.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQiw6wfES8ciF1GxAWpE0DT-M1HA0toofy1M1CklPID7ya7WCkp1yRc4dU',
    id: 9309,
    acronym: 'GTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hgtc.edu',
    name: 'Horry-​Georgetown Technical College',
    web_page: 'http://www.hgtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpXJgmf2JNw02SE5owWzvkB4MBxT3sB6HRwyreoDf_dcpP-H_VSzIYaGae',
    id: 9310,
    acronym: 'HTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'midlandstech.edu',
    name: 'Midlands Technical College',
    web_page: 'http://www.midlandstech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_6mlFYDxSBDzufdOYCOlJdETRNN4Uofcw2e6-l-y5u_R0eaNCj6qGjCU',
    id: 9311,
    acronym: 'MTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'netc.edu',
    name: 'Northeastern Technical College',
    web_page: 'http://www.netc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTT7RyZ9TO1-NM-U2IAj1q-DG3PGVpEFPvz7SuQ1Zm1dLionUjcrj3S7g',
    id: 9312,
    acronym: 'NTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'octech.edu',
    name: 'Orangeburg Calhoun Technical College',
    web_page: 'http://www.octech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwAKxjnK1q-MbV3Malw-mCndnT4-fQMzg2mB9gIcXnOxQAT7I441F0ZQ',
    id: 9313,
    acronym: 'OCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ptc.edu',
    name: 'Piedmont Technical College',
    web_page: 'http://www.ptc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSoth0b7if13CoqFoeiJuG0k3vNFqUxilJkHwsRCB10d2pYQU3HgVETgQ',
    id: 9314,
    acronym: 'PTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sccsc.edu',
    name: 'Spartanburg Community College',
    web_page: 'http://www.sccsc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVln82-kFcQtTsROmw5LOkZCLX34Wuh0VFMdgNJLYGjPdDoSYBRSXyCHM',
    id: 9315,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'smcsc.edu',
    name: 'Spartanburg Methodist College',
    web_page: 'http://www.smcsc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaDLmucfscyScaoE9WUbDwdbMuQiFQwG6Q-dUvYq8dxMszPTwT-64Z_gY',
    id: 9316,
    acronym: 'SMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tcl.edu',
    name: 'Technical College of the Lowcountry',
    web_page: 'http://www.tcl.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8cgLRzODnxNiesngW8IBeb__rtQJBvyBROQ6FP6xx2aZo8WRlNedVjQ',
    id: 9317,
    acronym: 'TCL',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tctc.edu',
    name: 'Tri-​County Technical College',
    web_page: 'http://www.tctc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4EA5MdyKi2Jh9Q_4bKeFkDQVUu61h0CTo_q-ulWemquLHok-RNoJpiw',
    id: 9318,
    acronym: 'TTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tridenttech.edu',
    name: 'Trident Technical College',
    web_page: 'http://www.tridenttech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmI3G2OAiuv4ryF4KUGg_TJx2wlk4oftFcxIMvC6h-c4BB06pfT7M6RZI',
    id: 9319,
    acronym: 'TTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wiltech.edu',
    name: 'Williamsburg Technical College',
    web_page: 'http://www.wiltech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgmYHbHwDsc4X9R5WFXlY8GXhEoCRCsxbszTOIRHh6_2EkqBFKS2Gi07Q',
    id: 9320,
    acronym: 'WTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'yorktech.edu',
    name: 'York Technical College',
    web_page: 'http://www.yorktech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSL0RUpWmiNjQXsor-gZpfJKlEoldCWwwP3g1WQlLMq5Dtha48wrEBIpj4',
    id: 9321,
    acronym: 'YTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kilian.edu',
    name: 'Kilian Community College',
    web_page: 'http://www.kilian.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1iG859iQOm6wF5StUckhxO4miBRwDxqLE3Zh_7BsI7gkiU1eLySaTmhk',
    id: 9322,
    acronym: 'KCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lakeareatech.edu',
    name: 'Lake Area Technical Institute',
    web_page: 'http://www.lakeareatech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-YfvWVxxcLxQftDCh72X4GSfLk3DItZ9NBO1Y7OixeDUB-M_oWrQXkJQ',
    id: 9323,
    acronym: 'LATI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mitchelltech.edu',
    name: 'Mitchell Technical Institute',
    web_page: 'http://www.mitchelltech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxy7CGjrK2U8VT_HJS-2ZmlTyecNaW9Ne6kGpkrSQ5_jEziGHOak2wZpbx',
    id: 9324,
    acronym: 'MTI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swc.tc',
    name: 'Sisseton Wahpeton College',
    web_page: 'http://www.swc.tc',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrHLXiaFfZ30_UfeFEsZCcCIQUty5SRbfzY3VpxiTEVjjUUhqEmmMGjo3FKA',
    id: 9325,
    acronym: 'SWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southeasttech.edu',
    name: 'Southeast Technical Institute',
    web_page: 'http://www.southeasttech.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNvV0AvbVOmH0pnm8KmnJkzpuNKrJlYn1E-snvq1RFr8HvilphQvu5KuO_',
    id: 9326,
    acronym: 'STI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wdt.edu',
    name: 'Western Dakota Technical Institute',
    web_page: 'https://www.wdt.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQagqv9pIu2p89461pQgj5_qquAspP9dzkmDG4R2TkqjsyT0JBddI9_zg',
    id: 9327,
    acronym: 'WDTI',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'chattanoogastate.edu',
    name: 'Chattanooga State Community College',
    web_page: 'http://www.chattanoogastate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzu583qjqovrzYrNssl0Vdz3pHWUTmdBOQO__0s7DTI2vLeDolfeI7VA',
    id: 9328,
    acronym: 'CSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clevelandstatecc.edu',
    name: 'Cleveland State Community College',
    web_page: 'http://www.clevelandstatecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnUcn9MPJiI8GeKllamkmz7H1uOQRkhOHolI9xA3BGFOFIuDzpwF5PByE',
    id: 9329,
    acronym: 'CSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'columbiastate.edu',
    name: 'Columbia State Community College',
    web_page: 'http://www.columbiastate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4RCEgQ7PLo0fUn2a8hY_NVnNUi13VH7XMIyQhmd-K08zaZH7NKY5w9NQ',
    id: 9330,
    acronym: 'CSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dscc.edu',
    name: 'Dyersburg State Community College',
    web_page: 'http://www.dscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOxxqq-hPM0-3vlcQUsNPNT43APf3LbAFvq6gaRYLzXZzt5rVWgS3CoQ',
    id: 9331,
    acronym: 'DSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jscc.edu',
    name: 'Jackson State Community College',
    web_page: 'http://www.jscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLXggf6LXkQMBGABBqERv1PnHqFrJE9vaW1rqK41g6-Q9LHfSNRHR_zy4E',
    id: 9332,
    acronym: 'JSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'guptoncollege.edu',
    name: 'John A Gupton College',
    web_page: 'http://www.guptoncollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQroD5yo2zGIHOjt7LudUdiKrFrs3R6tpZX5ifF2s0W-bmfWxsnLWGWQUM',
    id: 9333,
    acronym: 'JAGC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mscc.edu',
    name: 'Motlow State Community College',
    web_page: 'http://www.mscc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7UcnSbH0o7N8Yl5GlwwAgR6mdET9mhIJipKWLGDNd0FRudOJGYCZHqfTsOg',
    id: 9334,
    acronym: 'MSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northeaststate.edu',
    name: 'Northeast State Community College',
    web_page: 'http://www.NortheastState.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStv_MMGFgkrowUYsOKqsyeA1AtyoqXrl8HP32QTdK5DY5ZeFE5TgB2Nsm8',
    id: 9335,
    acronym: 'NSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pstcc.edu',
    name: 'Pellissippi State Community College',
    web_page: 'http://www.pstcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXd_5wuv6hvH8vOqkgWi-N62teItsCNOghGen0BtobXu1nDtwkTQvFCw',
    id: 9336,
    acronym: 'PSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'roanestate.edu',
    name: 'Roane State Community College',
    web_page: 'http://www.roanestate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSVl4O2pUFOx2Fomsmv4o89CMteMHi46vLHzbFbDzpJtVD3jFzNMaLmtuh',
    id: 9337,
    acronym: 'RSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southwest.tn.edu',
    name: 'Southwest Tennessee Community College',
    web_page: 'http://www.southwest.tn.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy84FF7JYSjbNlQbDtiUTSh1KK3hUPiJeHVxsGkTak3kDJxK7c3J-XnaQ',
    id: 9338,
    acronym: 'STCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'volstate.edu',
    name: 'Volunteer State Community College',
    web_page: 'http://www.volstate.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa1TO9NenQ9kPc4t7OS1xehx9Edsu8pKwNIoWWYCCWfO0k_l1JzzwkWQ0',
    id: 9339,
    acronym: 'VSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ws.edu',
    name: 'Walters State Community College',
    web_page: 'http://www.ws.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTsGX01CsGyRq8rXR0k3M9PVxPOT2ERrLtMy7iHqxoyQn5jZRxEcPPk1M',
    id: 9340,
    acronym: 'WSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alvincollege.edu',
    name: 'Alvin Community College',
    web_page: 'http://www.alvincollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPXL5tpq5L8Y-Xog252ADVeBrCXV4kVnm37aoaFesxVEfSyTgxeDcEag',
    id: 9341,
    acronym: 'ACC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'actx.edu',
    name: 'Amarillo College',
    web_page: 'http://www.actx.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQi0aeSpcUkEw8xZqSlJWQ8N_QdMRSgOrC2ndH3M9ECGF-dcn8kLlld6sM',
    id: 9342,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'angelina.edu',
    name: 'Angelina College',
    web_page: 'http://www.angelina.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdmzrs5bWc5CgJtCSJEIxLelz4x2jYNpGtvW176ODu4OnBxV11y3OVeA',
    id: 9343,
    acronym: 'AC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'blinn.edu',
    name: 'Blinn College',
    web_page: 'http://www.blinn.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpB6gOCAZJhd27xfAYUpfMIT7py9AgUrTSdmRWqQIxL3rWJJc9_IG7e9o',
    id: 9344,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'brookhavencollege.edu',
    name: 'Brookhaven College',
    web_page: 'http://www.brookhavencollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2VLDLs3pYRe3eAZv_XHKPgk-moVj6vNBw9a5zRw77Pid291FNE1dRRPg',
    id: 9345,
    acronym: 'BC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cedarvalleycollege.edu',
    name: 'Cedar Valley College',
    web_page: 'http://www.cedarvalleycollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfOuFYdV_ZZWMC6Oq7Tfp0k6gs1eC_sLcIqz85dA4YRqZ-uFRI3tjo0LjA',
    id: 9346,
    acronym: 'CVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ctcd.edu',
    name: 'Central Texas College',
    web_page: 'http://www.ctcd.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzjuNJKVYwLnB7CuZSxZEFkCgEZrrm5V2FYFjPFV2RFCwGmls4O4Z0XR4',
    id: 9347,
    acronym: 'CTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cisco.edu',
    name: 'Cisco College',
    web_page: 'http://www.cisco.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtGzZ-N9SRhXWOfBtqSeA6UQfBGo6KdxtigT6nuvOzRHY6cz0foK5RFA',
    id: 9348,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clarendoncollege.edu',
    name: 'Clarendon College',
    web_page: 'http://www.clarendoncollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKjxxraGXeEcYZiAUq7MZyFtdtLp9BzBGRURO0H5NNix4AV6VT7fk1ACM',
    id: 9349,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'coastalbend.edu',
    name: 'Coastal Bend College',
    web_page: 'http://www.coastalbend.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdvbuKiF4An1_j9b5lA_28vC84FDquPFSw_R8ErCGKMxnzEP2blrjKUB6HDw',
    id: 9350,
    acronym: 'CBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'com.edu',
    name: 'College of the Mainland',
    web_page: 'http://www.com.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQeprKCFyzAmVoR4dqq1muslEIa1k9icN6veRhyYsNq6i4zdSP1QIrBg',
    id: 9351,
    acronym: 'CM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'collin.edu',
    name: 'Collin County Community College District',
    web_page: 'http://www.collin.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4bWpdm1YvLqtUvenTcLmLjhqwFQ3pwZLJ0ug9OLt9FlPb9G03KxNAWYw',
    id: 9352,
    acronym: 'CCCCD',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'delmar.edu',
    name: 'Del Mar College',
    web_page: 'http://www.delmar.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSi5LwT7gVo9j5r99A7hXupRD_7on2Vb8LEDf7LK1RQLKv9yWSFM-Vyjw',
    id: 9353,
    acronym: 'DMC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eastfieldcollege.edu',
    name: 'Eastfield College',
    web_page: 'http://www.eastfieldcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_rfIFlMt-OBT6oH70BujJcDDxOg0pLUWpFXBTsS2mTKXVea8MjdTZFg',
    id: 9354,
    acronym: 'EC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'elcentrocollege.edu',
    name: 'El Centro College',
    web_page: 'http://www.elcentrocollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-4cSxvi9sDowwLaMZa4ZYVmlZ92C0GPxEcdUhta_mAw8K7_vr01KnWgC2',
    id: 9355,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'epcc.edu',
    name: 'El Paso Community College',
    web_page: 'http://www.epcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRxnvfWPh9Eh4Y5nICaTDcbFOzbmXEDmrMPfkSavv_Ib21T4BvrZML9lQ',
    id: 9356,
    acronym: 'EPCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fpctx.edu',
    name: 'Frank Phillips College',
    web_page: 'http://www.fpctx.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2uoNPGBMRyndy5RysadeIrgU2KZbwzN4stDfUmSqojkpvl-_eSiyduw',
    id: 9357,
    acronym: 'FPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gc.edu',
    name: 'Galveston College',
    web_page: 'http://www.gc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXzCq4IzEeSYuI7ug4t-Mn2gQYwnepcwQ-BKQDSYt76faQoTOlCtqV0w',
    id: 9358,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'grayson.edu',
    name: 'Grayson College',
    web_page: 'http://www.grayson.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnAJLM4lLuehZH9REPZhD6qeev5nKs1_Ibo6CiAMtSNTNQUThyAnfG3LQu',
    id: 9359,
    acronym: 'GC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hillcollege.edu',
    name: 'Hill College',
    web_page: 'http://www.hillcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjdzqjpGlLIsgmlx64FSqbZSFUYr6iRhN4cUwMLl8PpL0CHxpQml9zCCE',
    id: 9360,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hccs.edu',
    name: 'Houston Community College',
    web_page: 'http://www.hccs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_V-1pGI8xkfTYo8gfaSngzGX5NwRyjjPzxy8eUO9jSTq_gO5XDUz9cjoW',
    id: 9361,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'howardcollege.edu',
    name: 'Howard College',
    web_page: 'http://www.howardcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLStiyK09Jq8WEOSBmb3tIfEO6A8cCmpI2v0umBX7ntOgUHUf3mAx7v_7C',
    id: 9362,
    acronym: 'HC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jacksonville-college.edu',
    name: 'Jacksonville College-​Main Campus',
    web_page: 'http://www.jacksonville-college.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRagnbD4-6qZk6BbGoDMtyuPnu6EPIiY3gc39_vYpsGB0T7gAH3H41NJg',
    id: 9363,
    acronym: 'JCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kilgore.edu',
    name: 'Kilgore College',
    web_page: 'http://www.kilgore.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSQXQRMJcr06exneBJ0bZOuVKk4H4m3u_0d3RvG70fNKdeSKrjpqF9TQE',
    id: 9364,
    acronym: 'KC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lit.edu',
    name: 'Lamar Institute of Technology',
    web_page: 'http://www.lit.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSaBcMte7HMn00wREl8ngGQMFi75UELQxAU5Ijfuk0spmfqjDNec2BGVPE',
    id: 9365,
    acronym: 'LIT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lsco.edu',
    name: 'Lamar State College-​Orange',
    web_page: 'http://www.lsco.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCL-a_3iC60J4ImJLfhL8Zs_F14BVLlH9oSxDnOYVWH9RfCMFjgk2OjOoUew',
    id: 9366,
    acronym: 'LSC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lamarpa.edu',
    name: 'Lamar State College-​Port Arthur',
    web_page: 'http://www.lamarpa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTs7oywXOJhRACOsgKwUn6YE-rBRzBYJa0bKJZU2_9KvqkPJpWNpJj34GA',
    id: 9367,
    acronym: 'LSCA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'laredo.edu',
    name: 'Laredo Community College',
    web_page: 'http://www.laredo.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJEQ-wXyHxgNrOaLdpvqbnRecWTPWx3Dku2LcWu3CPd4vFUn5XXGPZ2eY',
    id: 9368,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lee.edu',
    name: 'Lee College',
    web_page: 'http://www.lee.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRul6cCPbirlfcmaFu_tEW_x9K7sSkbuCgejw6qaUnk0tyXV4JOTKXB1cc',
    id: 9369,
    acronym: 'LC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lonestar.edu',
    name: 'Lone Star College System',
    web_page: 'http://www.lonestar.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiRaqPSQfhAX5Zb1gDBDA4scpz91UBEUJe2ViXLYTDLPmB_yy91ZtFPg',
    id: 9370,
    acronym: 'LSCS',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mclennan.edu',
    name: 'McLennan Community College',
    web_page: 'http://www.mclennan.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuSFtTZ8QujfU84z2DY5mK3PaKFZ1J4OYi66ayqDc8gyKazAJ5dHhPkQ',
    id: 9371,
    acronym: 'MCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'navarrocollege.edu',
    name: 'Navarro College',
    web_page: 'http://www.navarrocollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM37scrx-rcgD_KOoTO5OVH-E4pNGPyrZQG-8L2SdRYHjaxd7aUDHihchlRA',
    id: 9372,
    acronym: 'NC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nctc.edu',
    name: 'North Central Texas College',
    web_page: 'http://www.nctc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8E-D82wK-ZK1TUfMCFR8HUNHyjqS94XlDIK_VN5RWtV1rTg7pHrmKBdc',
    id: 9373,
    acronym: 'NCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ntcc.edu',
    name: 'Northeast Texas Community College',
    web_page: 'http://www.ntcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkiMGHme5GiZRckdcXj4kLS22n1-NPOFPIOzOFQWaGbCgl8YapZ6KTbFI',
    id: 9374,
    acronym: 'NTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northlakecollege.edu',
    name: 'North Lake College',
    web_page: 'http://www.northlakecollege.edu/Pages/default.aspx',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7QfuXiknnM_-3IJGgkJDggG-uRtgDmvPJxXYsLBv9doSbJAiylfCMM-U',
    id: 9375,
    acronym: 'NLC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alamo.edu',
    name: 'Northwest Vista College',
    web_page: 'http://www.alamo.edu/nvc/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPJWaCv7yJChBwAuGZpeZWrrJHh8ImNTX50BAQ4LfLbGINo9HnyuaiJRI',
    id: 9376,
    acronym: 'NVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'odessa.edu',
    name: 'Odessa College',
    web_page: 'http://www.odessa.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF68q6pfdkCSoiLnsLjgW7wBN5wWkbTHmqCSYLB70Kc48LEsl4qZI6HUQ',
    id: 9377,
    acronym: 'OC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alamo.edu',
    name: 'Palo Alto College',
    web_page: 'http://www.alamo.edu/pac/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSZYb9XVfMksLktPGmHsHjyr9Aee7_l-ULIQRig-DwVzT007YjEaBqSdAw',
    id: 9378,
    acronym: 'PAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'panola.edu',
    name: 'Panola College',
    web_page: 'http://www.panola.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQn1Zdv2tes68wWwe7Y1qOXR6W0ZkeiZ-XfSBRUPvRFh7nDxVI0O1Qsfck',
    id: 9379,
    acronym: 'PC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'parisjc.edu',
    name: 'Paris Junior College',
    web_page: 'http://www.parisjc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN--DGw7SoQP6kxz3mR6SeIceUR9ibfVozmmGrzAzJhKfE0i7DmoAKBhw',
    id: 9380,
    acronym: 'PJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rangercollege.edu',
    name: 'Ranger College',
    web_page: 'http://www.rangercollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqK_Y5u6qU_EKDAkkxPEbsIfels_HWA2ulWxEheaT53-aeAhYbmLeBZDM',
    id: 9381,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'richlandcollege.edu',
    name: 'Richland College',
    web_page: 'http://www.richlandcollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReToV6ZdNmQemXweei_0dX2wvVq2BAG8YobVJNaJ63ETZg5N3TEgUMGg',
    id: 9382,
    acronym: 'RC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alamo.edu',
    name: 'San Antonio College',
    web_page: 'http://www.alamo.edu/sac',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWWAZKXIjTUAuurhEzaAl70JfJiqKzk0WR0aaxUKGZLozhERDQOLbqTaY',
    id: 9383,
    acronym: 'SAC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southplainscollege.edu',
    name: 'South Plains College',
    web_page: 'http://www.southplainscollege.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTU2-iaJYTOtFdhSeFO8WnZ3Cs4srmr8enayCaEuzfhVwu1UWi3ILZu_Q',
    id: 9384,
    acronym: 'SPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swtjc.net',
    name: 'Southwest Texas Junior College',
    web_page: 'http://www.swtjc.net',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKuDXj66BkF666idrXrJeNWr9T1jintTzxBukns1ZxPJjIZdou6GLxmHg',
    id: 9385,
    acronym: 'STJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alamo.edu',
    name: "St Philip's College",
    web_page: 'http://www.alamo.edu/spc/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2j_V5DXg-212SqKiQF_R6euRi9dNeYbMsxPIUpIEsNvDDNpLdVrN_2A',
    id: 9386,
    acronym: 'SPC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'templejc.edu',
    name: 'Temple College',
    web_page: 'http://www.templejc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvzgsgrPkKi1FO4727NbPn4kcROD9gsrJDG7D7U3pm35n7_5nZINZDVA',
    id: 9387,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'texarkanacollege.edu',
    name: 'Texarkana College',
    web_page: 'http://www.texarkanacollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTglwuY0pnoNLaj6XFB3S1OMh_gMD69_EJE_rjKLghCev3gOp79pS46Urs',
    id: 9388,
    acronym: 'TC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'harlingen.tstc.edu',
    name: 'Texas State Technical College-​Harlingen',
    web_page: 'http://www.harlingen.tstc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIQ9w0wCWp7emDGBYLueT7Udqpx6q671YCLTy-gT8kY9LDMhGbLzb-lA',
    id: 9389,
    acronym: 'TSTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'marshall.tstc.edu',
    name: 'Texas State Technical College-​Marshall',
    web_page: 'http://www.marshall.tstc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRocpsZwe9crYtHCktNSz28nA6CCpBby1mNdqLxNyr1WJ86G-0x4mAtZRQ',
    id: 9390,
    acronym: 'TSTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'waco.tstc.edu',
    name: 'Texas State Technical College-​Waco',
    web_page: 'http://www.waco.tstc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRocpsZwe9crYtHCktNSz28nA6CCpBby1mNdqLxNyr1WJ86G-0x4mAtZRQ',
    id: 9391,
    acronym: 'TSTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westtexas.tstc.edu',
    name: 'Texas State Technical College-​West Texas',
    web_page: 'http://www.westtexas.tstc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeD1JWaLDu3h42xIbGNWyfo2skTTYQMfmziOyEOEojQwifMw0CcDvOEvA',
    id: 9392,
    acronym: 'TSTCT',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tvcc.edu',
    name: 'Trinity Valley Community College',
    web_page: 'http://www.tvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg5ip8FBr_kswvQX-tBkkySOya3LmweSDQ2BiPW4k3JZZtdR_BXKILsA',
    id: 9393,
    acronym: 'TVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'www.tjc.edu',
    name: 'Tyler Junior College',
    web_page: 'http://WWW.TJC.EDU',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkFU24gJOMgaFoxGtkQPoIWMHEIRstPbnCwH6V1sULDzSFACKSmCK9ZSb-',
    id: 9394,
    acronym: 'TJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vernoncollege.edu',
    name: 'Vernon College',
    web_page: 'http://www.vernoncollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQpo8JVuWe5xUbMxVYphjPOZv59KYkI7gdHWdxYCT47BMHVcM0J6Hdtj4',
    id: 9395,
    acronym: 'VC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'victoriacollege.edu',
    name: 'Victoria College',
    web_page: 'http://www.victoriacollege.edu',
    img: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcQ6tMij2vhda6a57Z8qbEiQBmmRhBdFxnfHpEq2ZcCc6qxxwXLTq9fsLg',
    id: 9396,
    acronym: 'VC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wc.edu',
    name: 'Weatherford College',
    web_page: 'http://www.wc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_MXI3bgxDeh9BGj7cU5aDCJx9dmtMXaD0Y-bDlyuTsSzjdsgOxj7EVgY',
    id: 9397,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wtc.edu',
    name: 'Western Texas College',
    web_page: 'http://www.wtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNEk7Ur3DVRMkkEPnvuuA3NVLG1H_7bkadgUL4PF6pqScr-rZ_n-7pxIKs',
    id: 9398,
    acronym: 'WTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wcjc.edu',
    name: 'Wharton County Junior College',
    web_page: 'http://www.wcjc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSB_EDvSWrxr0y4fQCxT6DVTdyVmDYCzigbD3sROY1GKSvLYNyfmW3xbw',
    id: 9399,
    acronym: 'WCJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ldsbc.edu',
    name: 'Latter-​day Saints Business College',
    web_page: 'http://www.ldsbc.edu',
    img: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcRylXSCLC6nzxfdcrHmHAuELgHDPTwpLWVM8A_aNt2wNuRWvNoUhH7KSas',
    id: 9400,
    acronym: 'LSBC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'slcc.edu',
    name: 'Salt Lake Community College',
    web_page: 'http://www.slcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6l6sLdLKS1-3M-0Dvv201y1VZo5rh7pCxhJUh7qKJs3CMCkayPI_PqAil',
    id: 9401,
    acronym: 'SLCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ccv.edu',
    name: 'Community College of Vermont',
    web_page: 'http://www.ccv.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFfIOy8bIB7k8JzwTJr9xsmByr9xN06PskGAMwGtM4m76EBYPP11ionA',
    id: 9402,
    acronym: 'CCV',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cvcc.vccs.edu',
    name: 'Central Virginia Community College',
    web_page: 'http://www.cvcc.vccs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0FQaZ-YQhUh2sjaPcqSAQSCqD8LHWlGOFxxANblcKRm45d6k52HTMjY9c',
    id: 9404,
    acronym: 'CVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dslcc.edu',
    name: 'Dabney S Lancaster Community College',
    web_page: 'http://www.dslcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgF3HS_J0rXQ3CGDOyHHtqVNQgDXjnTrafl_29rM5efGE-mBQ39BTEFQ',
    id: 9405,
    acronym: 'DSLCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'dcc.vccs.edu',
    name: 'Danville Community College',
    web_page: 'http://www.dcc.vccs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRsBHUhSqhe3axzj7tqXgaWIOn3oN_hzXEESyE2rC3-dgw-213y6nINXw',
    id: 9406,
    acronym: 'DCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'es.vccs.edu',
    name: 'Eastern Shore Community College',
    web_page: 'http://www.es.vccs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFZGfOOle7NLupxvCrrPMezqc9Ey-sSozXWHWrFKXqnwjphmpXgq4EdZ0',
    id: 9407,
    acronym: 'ESCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'germanna.edu',
    name: 'Germanna Community College',
    web_page: 'http://www.germanna.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaBDBUttpDlOyXG1A-JmZP_UB00SK7IU4E0KmeVhJARfxZ3Ycsp7YaMO4',
    id: 9408,
    acronym: 'GCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'jtcc.edu',
    name: 'John Tyler Community College',
    web_page: 'http://www.jtcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0XAKm6Zh3xq4_PeWtlWwkkIeOGHHuHNJmt9KP0ZFvkTsf3E7q6HFjkJo',
    id: 9409,
    acronym: 'JTCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'reynolds.edu',
    name: 'J Sargeant Reynolds Community College',
    web_page: 'http://www.reynolds.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLISUCkD4dM97oe_E3VdKHTEqD_k61V0LKD_Ie7OTgEo6OKqFl7ncTGQ',
    id: 9410,
    acronym: 'JSRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lfcc.edu',
    name: 'Lord Fairfax Community College',
    web_page: 'http://www.lfcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBhyMboamvOC4UQW4Xd5b8vyiIu-JEqRhMmJpGpMogfCpGOR7k36KW4zk',
    id: 9411,
    acronym: 'LFCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mecc.edu',
    name: 'Mountain Empire Community College',
    web_page: 'http://www.mecc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn1jmGFd2xgcyKC03NLj51uvIsNhkmKiUrSwT57dlm6F-AqajFpp2IF_k',
    id: 9412,
    acronym: 'MECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nr.edu',
    name: 'New River Community College',
    web_page: 'http://www.nr.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTREEIB-Hw-VtkOeVRJ0UYDJDyhElHRGI5nx52B6xny3lc0ZLaKwSNO8lU',
    id: 9413,
    acronym: 'NRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nvcc.edu',
    name: 'Northern Virginia Community College',
    web_page: 'http://www.nvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQComJs2AoCWKgB_ymMJkFzDDMnJ70IoDUvo1uVW-1sxiNYI4GcG0BHDS9R',
    id: 9414,
    acronym: 'NVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'patrickhenry.edu',
    name: 'Patrick Henry Community College',
    web_page: 'http://www.patrickhenry.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSbkYgXIyL2l1MtbEn4jIRZ-e2AClVNQHrHS08UlacMBVmuw1eFMKx6YE',
    id: 9415,
    acronym: 'PHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pdc.edu',
    name: 'Paul D Camp Community College',
    web_page: 'http://www.pdc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3b3f9SToJwhYPj2qVKmHoeyLnniL72jcfurAnDtPAcV3GnyuyDB4XxIk',
    id: 9416,
    acronym: 'PDCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pvcc.edu',
    name: 'Piedmont Virginia Community College',
    web_page: 'http://www.pvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYA-b0VKk_dCXuaU_xkJhz7O4RFVxDSyoZZebxdsv4A3q6Q3BbePlf6lU',
    id: 9417,
    acronym: 'PVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rappahannock.edu',
    name: 'Rappahannock Community College',
    web_page: 'http://www.rappahannock.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3UsFt7aqtYtDKNvNRm9ThkzN6YiOai0IIHXpGaSum4-_Iq-DT_aW7mHs',
    id: 9418,
    acronym: 'RCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rbc.edu',
    name: 'Richard Bland College of the College of William and Mary',
    web_page: 'http://www.rbc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM1Oaxm6c9NHQQIZskEDGlRp2i5KtnPqmvt-9opN-JK9S8P37bG8u35xE',
    id: 9419,
    acronym: 'RBCCWM',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southside.edu',
    name: 'Southside Virginia Community College',
    web_page: 'http://www.southside.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdU9o6aBygU-Lm6cfXZFkbHwrAwhsu_5kEN_ohMLnbw5sTKbNlSNINYqsw',
    id: 9420,
    acronym: 'SVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'sw.edu',
    name: 'Southwest Virginia Community College',
    web_page: 'http://www.sw.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZ7QgZTf-gALT4yA7YVF1OptwqPGz83rK092U_Z8jgp8v3UZnpXJyMAZk',
    id: 9421,
    acronym: 'SVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tncc.edu',
    name: 'Thomas Nelson Community College',
    web_page: 'http://www.tncc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhWaKkzs939FWgG97wvm18K9fEHH3tAq64VR-srpBBU07M3BmiPU0oI-8',
    id: 9422,
    acronym: 'TNCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tcc.edu',
    name: 'Tidewater Community College',
    web_page: 'http://www.tcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyL4_r4bfnUmJXN1tsRzyPC39N5JXZEPjaWxurnGv2LuJmz-E71VaXUON8',
    id: 9423,
    acronym: 'TCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'vhcc.edu',
    name: 'Virginia Highlands Community College',
    web_page: 'http://www.vhcc.edu',
    img: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcS2O3drhZWe4db3LpxNXaisqm7gCx4PA3eKIsZLeUZ_E7BL_C3lC1-4ruk',
    id: 9424,
    acronym: 'VHCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'virginiawestern.edu',
    name: 'Virginia Western Community College',
    web_page: 'http://virginiawestern.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2WOMo3lai5cUr7R_aogHjyLWM1kecvDUgPpVVm8JTNmUD3n1UtJ6LcU0',
    id: 9425,
    acronym: 'VWCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wcc.vccs.edu',
    name: 'Wytheville Community College',
    web_page: 'http://www.wcc.vccs.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsgZu5PUtljEk1NhipkpA_TYPiyZT9tKuzv2JdNb1xS0woHp2_xI73cfw',
    id: 9426,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bates.ctc.edu',
    name: 'Bates Technical College',
    web_page: 'http://www.bates.ctc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVN3VJcPgIa_JtC0nbq9Oisz7RT86JJZFpr2RVUrYIhXPEi-JFVWkwHw',
    id: 9427,
    acronym: 'BTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'btc.ctc.edu',
    name: 'Bellingham Technical College',
    web_page: 'http://www.btc.ctc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLYTDunbXpG4oI6wPN7p87er2BRf49kyeCTsnmDzn_r-XDJjXl6580F84',
    id: 9428,
    acronym: 'BTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bigbend.edu',
    name: 'Big Bend Community College',
    web_page: 'http://www.bigbend.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQSnYwgi75G7gFUiGQx9GJSKFdZtFkUkXPttnRiRzdWyayazFSZSU9q5Y',
    id: 9429,
    acronym: 'BBCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cascadia.edu',
    name: 'Cascadia Community College',
    web_page: 'http://www.cascadia.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbleP_kbx2N3ecFXyqHD8UqWYiULnOFxie1My1YaLfa8GSHE4LOrQ9qtKR',
    id: 9430,
    acronym: 'CCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'clark.edu',
    name: 'Clark College',
    web_page: 'http://www.clark.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj0-m1F1TxCb1nsqY2tg3uG3nSseuVMXoWN5m3zLjOMg1JzXxa5S2wdQA',
    id: 9431,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cptc.edu',
    name: 'Clover Park Technical College',
    web_page: 'http://www.cptc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqXgTp8drG0T85ieCeQUU75w7Kra49rCgA6q-pjo69CxPXYQL88ITjLEg',
    id: 9432,
    acronym: 'CPTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'edcc.edu',
    name: 'Edmonds Community College',
    web_page: 'http://www.edcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShY5yu2Ob8kQwFAt2Sz73kTVakOtfrlVEtm2snGQT2kaFUfwmIjNP_RyI',
    id: 9433,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'everettcc.edu',
    name: 'Everett Community College',
    web_page: 'http://www.everettcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_64E9mGhpDt1yUXynYe7B-sdtsmQx6xUHx3xF1Wvd1In9r5Ngc8Q1-Cif',
    id: 9434,
    acronym: 'ECC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ghc.edu',
    name: 'Grays Harbor College',
    web_page: 'http://www.ghc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBRbPuFGrAeMxK93bxKkmHOdZfmD_sOqxeqOT51B3mONgMl_yUdLhNTQ',
    id: 9435,
    acronym: 'GHC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'greenriver.edu',
    name: 'Green River Community College',
    web_page: 'http://www.greenriver.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgjLu8qw_9dqppvUWGeyuk9CYcfccYRKNj-jIAyNSPIwuQxWPdxKPtag',
    id: 9436,
    acronym: 'GRCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'highline.edu',
    name: 'Highline Community College',
    web_page: 'http://www.highline.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSpzRRHNZ0AosNHMHDTOpfTSg5xh-2Up192eT0ZqpenZ7kKLkYqpTRQSoe4',
    id: 9437,
    acronym: 'HCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lowercolumbia.edu',
    name: 'Lower Columbia College',
    web_page: 'http://lowercolumbia.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_XZJlUV1fli5_wc7PMNx0vvzsJIH2tXKlpKXnH59bFgmG9RncxnRe-Sc',
    id: 9438,
    acronym: 'LCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pierce.ctc.edu',
    name: 'Pierce College at Puyallup',
    web_page: 'http://www.pierce.ctc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIgZJuWyUnjHQN6qKQ9oqI2xaXBC2hxi1VecdIgn9adLJPZGDEpk7syhrH',
    id: 9439,
    acronym: 'PCAP',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'rtc.edu',
    name: 'Renton Technical College',
    web_page: 'http://www.rtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-RTDk9eECoIBYVcOKbmfGsC2VkkDbGQlhOEYtW8KsjjmWgJsf5SUJ-Bcl',
    id: 9440,
    acronym: 'RTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'shoreline.edu',
    name: 'Shoreline Community College',
    web_page: 'http://www.shoreline.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgF4UjpQl-WoVatJViMmaNTCINLHxFLgTp-5WA00KkYW0UX243e9bzQw',
    id: 9441,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'skagit.edu',
    name: 'Skagit Valley College',
    web_page: 'http://www.skagit.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9ALtknb38fCISYK1cS2sfbqPVTspL4lKzeXPcjJ-2-gjuzsiyyMe-PL4',
    id: 9442,
    acronym: 'SVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'spscc.ctc.edu',
    name: 'South Puget Sound Community College',
    web_page: 'http://www.spscc.ctc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIhwTuluUWSf5iH7k2MjTKrIQ-dZoqIHAcS_BkHHSzuf_fpTsE0L2bRDY',
    id: 9443,
    acronym: 'SPSCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'scc.spokane.edu',
    name: 'Spokane Community College',
    web_page: 'http://www.scc.spokane.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0i_tm7tG0wHX-i5zFzzUJTgAyhc5-dgo_sgWSfDYV7UNp68ycXTFudZY',
    id: 9444,
    acronym: 'SCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'spokanefalls.edu',
    name: 'Spokane Falls Community College',
    web_page: 'http://www.spokanefalls.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVBK2jPRnKhLvPwu6Y-ivqs4BQUB73xzPPf2XeP6uLllP1pE6qhaoECG0',
    id: 9445,
    acronym: 'SFCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'tacomacc.edu',
    name: 'Tacoma Community College',
    web_page: 'http://www.tacomacc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSM1X_jsXQL14CSD5xvriTsMIg0W-7AwTqu-TeHmZU7pN5fCinQghD6UV2O',
    id: 9446,
    acronym: 'TCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wwcc.edu',
    name: 'Walla Walla Community College',
    web_page: 'http://www.wwcc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkZC8BkuD589dyYfxDienImPokiwHOvSb0KpU_Ffi0zKn1IOW1wZWH3A',
    id: 9447,
    acronym: 'WWCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wvc.edu',
    name: 'Wenatchee Valley College',
    web_page: 'http://www.wvc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoLRVIdEOhFMKRT_9hQtHhkIPNCbZ3Yg_8Gq3rwJwko7YvqnQ9sWAsyaw',
    id: 9448,
    acronym: 'WVC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'whatcom.ctc.edu',
    name: 'Whatcom Community College',
    web_page: 'http://www.whatcom.ctc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSa9NELnCgsiQlgMUn7ysXtkF4ErAAJnwzIRI5OwHKddazUEOKT1C0E4Ls',
    id: 9449,
    acronym: 'WCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'yvcc.edu',
    name: 'Yakima Valley Community College',
    web_page: 'http://www.yvcc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrXB_MfnMYOU5ZYYUjyq_ZLpG3Ei0n7s0M2zzwaR1Nx_Xm0BEsDnqShGU',
    id: 9450,
    acronym: 'YVCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'blueridgectc.edu',
    name: 'Blue Ridge Community and Technical College',
    web_page: 'http://www.blueridgectc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxnxDAAH4Jd7SJ_UYMfJvgAlF-gSxATWcse_-r8RMqJ6SDxPHd4QJWaA',
    id: 9451,
    acronym: 'BRCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'bridgemont.edu',
    name: 'Bridgemont Community and Technical College',
    web_page: 'http://www.bridgemont.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMslKNPLGCsPz02PfKd9RZnOoLGXMzDWnWq85uwt7au_s8cljntKJ2Z8Y',
    id: 9452,
    acronym: 'BCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'eastern.wvnet.edu',
    name: 'Eastern West Virginia Community and Technical College',
    web_page: 'http://www.Eastern.wvnet.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzOoEjU45G8sMQhThVrODcoKgbCCT1rB5OdhuOTs1kvkzgqFtV_TplVLs',
    id: 9453,
    acronym: 'EWVCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'huntingtonjuniorcollege.edu',
    name: 'Huntington Junior College',
    web_page: 'http://www.huntingtonjuniorcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkFU24gJOMgaFoxGtkQPoIWMHEIRstPbnCwH6V1sULDzSFACKSmCK9ZSb-',
    id: 9454,
    acronym: 'HJC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'kvctc.edu',
    name: 'Kanawha Valley Community and Technical College',
    web_page: 'http://www.kvctc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt7_RAxSD48uZ31XW2WwGKuFIivZ1-Ktov-6KpTNCKCK6dBAwJMcTPqQ',
    id: 9455,
    acronym: 'KVCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mctc.edu',
    name: 'Mountwest Community and Technical College',
    web_page: 'http://www.mctc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0mOb_RTORv4O1Pxw3fPi0HaZCIWAkwt3DSZGuVo7wwn3J-YvYXpkNRV65',
    id: 9456,
    acronym: 'MCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'newriver.edu',
    name: 'New River Community and Technical College',
    web_page: 'http://www.newriver.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrFqvNTlRPORrev4xcnlm8h7rrs3omD6ZomoIpz1d3H_JOwhAqR1vrrg',
    id: 9457,
    acronym: 'NRCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'pierpont.edu',
    name: 'Pierpont Community and Technical College',
    web_page: 'http://www.pierpont.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrRxAjWBqxd7YRFgX2DnKoatj5DkwNmBtvTa67ERl4tnrC8DjSJU6wowc',
    id: 9458,
    acronym: 'PCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'southernwv.edu',
    name: 'Southern West Virginia Community and Technical College',
    web_page: 'http://www.southernwv.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6eYhvFwFkn2IhMuYKtDuwavlvm93761R_BAtgso0MiFMR0nV_HQCD8Q',
    id: 9459,
    acronym: 'SWVCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wvncc.edu',
    name: 'West Virginia Northern Community College',
    web_page: 'http://www.wvncc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM7ArIoulRDiFTVlUUgrAiZX3FMEklKMzT6piFUgElhgfUgVukdH_-_9Q',
    id: 9460,
    acronym: 'WVNCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'blackhawk.edu',
    name: 'Blackhawk Technical College',
    web_page: 'http://www.blackhawk.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQHEaRGXMs9B4IDWelgF7Hnx9LJhz2h0QdVWtgajVtGFWmaLvQWG6u85ds',
    id: 9461,
    acronym: 'BTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cvtc.edu',
    name: 'Chippewa Valley Technical College',
    web_page: 'http://www.cvtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTq15if5zxt27fsvVMAxxSo5Izw3gRoLWOpU4R_8afJ1LWvV7gTjG-3te34',
    id: 9462,
    acronym: 'CVTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'fvtc.edu',
    name: 'Fox Valley Technical College',
    web_page: 'http://www.fvtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWPumwmRl-f6gFFFXSreT0fQ7opCdXbSkHhdKYWhFvjk7cUJbvrGJ4fQ',
    id: 9463,
    acronym: 'FVTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gtc.edu',
    name: 'Gateway Technical College',
    web_page: 'http://www.gtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbKm8lJz6CvXwZu5-X7b44sS8B37mRZhKu7UtSAFqqrvJ02JfptyZvng',
    id: 9464,
    acronym: 'GTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lco.edu',
    name: 'Lac Courte Oreilles Ojibwa Community College',
    web_page: 'http://www.lco.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtF3DWGUvIQHg4rLpC7mEbS07hfGQRGrsr-pC0jC9EqYpCG38NNUZr0n4',
    id: 9465,
    acronym: 'LCOOCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gotoltc.edu',
    name: 'Lakeshore Technical College',
    web_page: 'http://www.gotoltc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQnyojBXb6bZeCWafeSy6OFZZQOnHPYvHP0IV7XojxLQxNG5Loc1sxmA',
    id: 9466,
    acronym: 'LTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'madisoncollege.edu',
    name: 'Madison Area Technical College',
    web_page: 'http://madisoncollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSFHP3vSuwjXXzx9iwH8DfDwWZEOjzxj-P2ZI1jZF_23ZlW_4f7jZuokKc',
    id: 9467,
    acronym: 'MATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mstc.edu',
    name: 'Mid-​State Technical College',
    web_page: 'http://www.mstc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI4lAjXtrRuaB8leXLxu5B4ZFiebZ1avEtNETFvdZK3VYTIe-aLSmO8A',
    id: 9468,
    acronym: 'MTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'matc.edu',
    name: 'Milwaukee Area Technical College',
    web_page: 'http://matc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShIprLxL4WSQpLDiexd5t6PXpRKesl8vTopSEi4F0cJhiwt4jWgHYzQw',
    id: 9469,
    acronym: 'MATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'morainepark.edu',
    name: 'Moraine Park Technical College',
    web_page: 'http://www.morainepark.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgyfS1zuB4i0xO3xFtd69XbVIAHMtNkbWw2EyXSVvXIg4wLXQ1che6MQ',
    id: 9470,
    acronym: 'MPTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nicoletcollege.edu',
    name: 'Nicolet Area Technical College',
    web_page: 'http://www.nicoletcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9cOF8ZIw6rswOq3hnftVVzyEAR8c32CYuHyjKO4ToBEYuVyF96XF1gA',
    id: 9471,
    acronym: 'NATC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ntc.edu',
    name: 'Northcentral Technical College',
    web_page: 'http://www.ntc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSt_PmYqyyziGc86t3Xcebo7M5Zv8PRzPsSIUOAmoe1VhUQdb22YpE032XW',
    id: 9472,
    acronym: 'NTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'nwtc.edu',
    name: 'Northeast Wisconsin Technical College',
    web_page: 'http://www.nwtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnYIrVBVG5-MAwTLbc3UGAy7tBspNwNOSAWYU-FGiWRkJ-UFE1nkf3fd4',
    id: 9473,
    acronym: 'NWTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'swtc.edu',
    name: 'Southwest Wisconsin Technical College',
    web_page: 'http://www.swtc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZQtKdtKRajr_5-LbE5R9Lc7x4GKfKFzEAD1CRGbEAsWIJzCyGO_aS8UU',
    id: 9474,
    acronym: 'SWTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uwc.edu',
    name: 'University of Wisconsin Colleges',
    web_page: 'http://www.uwc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSQB4osMDFymXpqXmIeTwfKeyYkth65FLInm6VCY-BaQPV2r7rjpoxinw',
    id: 9475,
    acronym: 'UWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wctc.edu',
    name: 'Waukesha County Technical College',
    web_page: 'http://www.wctc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvshFYYKHTmJxVb9kIvm3ZpmulQORo2sMR38ymx2I_95Q-3baa6lxxa5Q',
    id: 9476,
    acronym: 'WCTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'westerntc.edu',
    name: 'Western Technical College',
    web_page: 'http://www.westerntc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRbA1LIXPcs5C4Iikt1IhrmfSx9fGyNNj4uf2EpXyihUFh9wjAFz56kwhM',
    id: 9477,
    acronym: 'WTC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'witc.edu',
    name: 'Wisconsin Indianhead Technical College',
    web_page: 'http://www.witc.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQth7D2eHbw78tIkGHvx0S_km-Uyq8l2b2NkkbBAWgILm2N8I0oSdsWdFsf',
    id: 9478,
    acronym: 'WITC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'caspercollege.edu',
    name: 'Casper College',
    web_page: 'http://www.caspercollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4NbqRqC2ZvcDkalw5Fo1aHqW-_q5ZGuY4HL4pXn8NnOhGGPPrClBIG7o',
    id: 9479,
    acronym: 'CC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cwc.edu',
    name: 'Central Wyoming College',
    web_page: 'http://www.cwc.edu/',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6O_m1GL4e7xK7ClBL-9rS1ccU-yZrm97tj9XWCUJ3I0Rpf_QjYMAk-U-8',
    id: 9480,
    acronym: 'CWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'ewc.wy.edu',
    name: 'Eastern Wyoming College',
    web_page: 'http://ewc.wy.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTe7LvyUaiIQbZHXoxagnXdRbMJgdm9WQ7wpckaNXvC3KlWj1dlpGUKYhUbKA',
    id: 9481,
    acronym: 'EWC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lccc.wy.edu',
    name: 'Laramie County Community College',
    web_page: 'http://www.lccc.wy.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhgtAWBgzlcpck6IScVWRU9rQIfExXAuinMiYRwPp7deJgAwRVriuUQw',
    id: 9482,
    acronym: 'LCCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'northwestcollege.edu',
    name: 'Northwest College',
    web_page: 'http://www.northwestcollege.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEWqDY6cpiBy9BRuLAMnKjXOyaqvC0iWuG_pny61lpfCHG8c3gTADLDsc',
    id: 9483,
    acronym: 'NC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wwcc.wy.edu',
    name: 'Western Wyoming Community College',
    web_page: 'http://www.wwcc.wy.edu',
    img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJHQh4cJf58946E7c4Mn7X__o5_ejmR20VR6NNVqKsHIIKgkVCB9wFhil1',
    id: 9485,
    acronym: 'WWCC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'charlestonsouthern.edu',
    name: 'Charleston Southern University',
    web_page: 'https://www.charlestonsouthern.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14149college.jpg',
    id: 9486,
    acronym: 'CSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'utahtech.edu',
    name: 'Utah Tech University',
    web_page: 'https://utahtech.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14344college.jpg',
    id: 9487,
    acronym: 'UTU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gardner-webb.edu',
    name: 'Gardner Webb University',
    web_page: 'https://gardner-webb.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14479college.jpg',
    id: 9488,
    acronym: 'GWU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'alcorn.edu',
    name: 'Alcorn State University',
    web_page: 'https://www.alcorn.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/13870college.jpg',
    id: 9489,
    acronym: 'ASU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'stonehill.edu',
    name: 'Stonehill College',
    web_page: 'https://www.stonehill.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15457college.jpg',
    id: 9490,
    acronym: 'SC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'lindenwood.edu',
    name: 'Lindenwood University',
    web_page: 'https://www.lindenwood.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/16142college.jpg',
    id: 9491,
    acronym: 'LU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'umo.edu',
    name: 'University of Mount Olive',
    web_page: 'https://umo.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14959college.jpg',
    id: 9492,
    acronym: 'UMO',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'cookman.edu',
    name: 'Bethune-Cookman University',
    web_page: 'https://www.cookman.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/13989college.jpg',
    id: 9493,
    acronym: 'BU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'hbu.edu',
    name: 'Houston Baptist University',
    web_page: 'https://hbu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/Houston-Baptist-Huskies.png',
    id: 9494,
    acronym: 'HBU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'gram.edu',
    name: 'Grambling State University',
    web_page: 'https://www.gram.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/grambling-state-university.png',
    id: 9495,
    acronym: 'GSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'merrimack.edu',
    name: 'Merrimack College',
    web_page: 'https://www.merrimack.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14864college.jpg',
    id: 9496,
    acronym: 'MC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'wagner.edu',
    name: 'Wagner College',
    web_page: 'https://wagner.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15805college.jpg',
    id: 9497,
    acronym: 'WC',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'scsu.edu',
    name: 'South Carolina State University',
    web_page: 'https://scsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15335college.jpg',
    id: 9498,
    acronym: 'SCSU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'una.edu',
    name: 'University of North Alabama',
    web_page: 'https://www.una.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/university-of-north-alabama.png',
    id: 9499,
    acronym: 'UNA',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'francis.edu',
    name: 'Saint Francis University',
    web_page: 'https://www.francis.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15411college.jpg',
    id: 9500,
    acronym: 'SFU',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'uapb.edu',
    name: 'University of Arkansas Pine Bluff',
    web_page: 'http://www.uapb.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/15591college.jpg',
    id: 9501,
    acronym: 'UAPB',
  },
  {
    alpha_two_code: 'US',
    country: 'United States',
    domain: 'mvsu.edu',
    name: 'Mississippi Valley State University',
    web_page: 'https://www.mvsu.edu/',
    img: 'https://urpplus-files-storage.s3.us-west-1.amazonaws.com/logos/d1-football/14914college.jpg',
    id: 9502,
    acronym: 'MVSU',
  },
];

export const getUniversities = () => universities;
